export const regions_lad = {
  type: "FeatureCollection",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },

  features: [
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000001",
        LAD13CDO: "00EB",
        LAD13NM: "Hartlepool",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-1.241956526315958, 54.722452909315834],
              [-1.242505189342398, 54.722420389946741],
              [-1.24192061729046, 54.722713302903806],
              [-1.241956526315958, 54.722452909315834],
            ],
          ],
          [
            [
              [-1.270237428346303, 54.727158414465499],
              [-1.268210325997062, 54.72608036652354],
              [-1.267390512992676, 54.726854573664205],
              [-1.265882373803238, 54.726620785048247],
              [-1.264695411850965, 54.725971937199624],
              [-1.263208539118907, 54.726451796693183],
              [-1.260565163468531, 54.725719461950817],
              [-1.258546933685663, 54.726045045500641],
              [-1.25882250176557, 54.725408666041631],
              [-1.257430512310725, 54.725536751549427],
              [-1.255399410730049, 54.721564707750275],
              [-1.253721558021485, 54.720357350566282],
              [-1.251850550010507, 54.719612498494953],
              [-1.248751495556522, 54.720722986007928],
              [-1.247599540915856, 54.722399976361984],
              [-1.245782110752467, 54.722282643701391],
              [-1.244005303060669, 54.721567912904128],
              [-1.242825023970778, 54.722253434697549],
              [-1.2392641871449, 54.721384592823249],
              [-1.227282984173657, 54.715604899830346],
              [-1.219577460493303, 54.712686953327349],
              [-1.217702973805294, 54.711528152206469],
              [-1.215006511942233, 54.711064978840092],
              [-1.209951251982901, 54.708905855879479],
              [-1.202457277661393, 54.706169722930014],
              [-1.194412973675085, 54.704160101227252],
              [-1.187318827991314, 54.701778864778198],
              [-1.183144676891514, 54.701075899338058],
              [-1.177759430767014, 54.699265483757543],
              [-1.175231964218854, 54.696575609365198],
              [-1.177036354036534, 54.694893837233174],
              [-1.17396638930484, 54.691812913335795],
              [-1.177002770811895, 54.694636583474775],
              [-1.178009674056891, 54.694100604665216],
              [-1.181639933117572, 54.69318692945928],
              [-1.182898134364215, 54.693651951083424],
              [-1.184875949900315, 54.693280624906194],
              [-1.183089100729721, 54.693877012108842],
              [-1.183561610728138, 54.694529948711313],
              [-1.185256429483062, 54.695043718199848],
              [-1.186919164832876, 54.695146546964757],
              [-1.190210513571358, 54.696755662502142],
              [-1.18983432673777, 54.697024553823518],
              [-1.186518711495696, 54.696395736914006],
              [-1.185799643521427, 54.69751517211504],
              [-1.188328282995471, 54.698084821067013],
              [-1.192760329286392, 54.700480660383171],
              [-1.196314633364357, 54.698554115641585],
              [-1.195017425846665, 54.697692653520832],
              [-1.194706699653664, 54.697791243376258],
              [-1.193725119879861, 54.696664952147827],
              [-1.194271688838052, 54.697662536727599],
              [-1.192731358073722, 54.697327863617936],
              [-1.191092482295279, 54.696020130508863],
              [-1.190468406478865, 54.696037533738277],
              [-1.190693963177532, 54.694604729543819],
              [-1.187494887559017, 54.693898530250202],
              [-1.189365165525893, 54.694250754972536],
              [-1.195925223207175, 54.692350572177638],
              [-1.196064266337568, 54.691516610706344],
              [-1.194330456943083, 54.690387129658156],
              [-1.192626537683681, 54.68853797061972],
              [-1.194368642210008, 54.690260667991069],
              [-1.194819051970528, 54.68937215878578],
              [-1.194785252986287, 54.690531245661646],
              [-1.196159290771265, 54.691418384821375],
              [-1.19629611568473, 54.692031300252957],
              [-1.196957638983659, 54.692157007053964],
              [-1.197867710325623, 54.69179456864191],
              [-1.198410558112366, 54.689990892310185],
              [-1.197847938024954, 54.689807432837796],
              [-1.19795301334317, 54.68904154476111],
              [-1.196585751120776, 54.688677508566329],
              [-1.19544638958581, 54.689027631586242],
              [-1.19859191233108, 54.687242097137386],
              [-1.198664255090897, 54.686246825539705],
              [-1.197570354043596, 54.686026599895762],
              [-1.196097771420496, 54.686366434588514],
              [-1.194649857399017, 54.687496365075681],
              [-1.194662032313117, 54.688213601607451],
              [-1.194066815559175, 54.688189874896601],
              [-1.19433119492299, 54.687372924386381],
              [-1.195520162286094, 54.686394955787271],
              [-1.196836032448701, 54.685900413704971],
              [-1.198420772338638, 54.685771605525396],
              [-1.198839688154778, 54.685372659737851],
              [-1.199110447530051, 54.680757869711712],
              [-1.198474236087679, 54.679513469477584],
              [-1.198222750231849, 54.678344404970943],
              [-1.196512535283557, 54.675887737847248],
              [-1.190253888987142, 54.670411687846773],
              [-1.187386596598622, 54.664131290937405],
              [-1.184785999756196, 54.660679010721083],
              [-1.17609258699173, 54.653617571471223],
              [-1.16474643073838, 54.647594103094434],
              [-1.162188954405947, 54.647615090551682],
              [-1.157786096576368, 54.648369132028897],
              [-1.157709365880035, 54.648182567801314],
              [-1.159283770302724, 54.647888842697562],
              [-1.160968389609321, 54.646681882782715],
              [-1.16309162860799, 54.646221154694793],
              [-1.163773905032062, 54.646351683309327],
              [-1.162108075802195, 54.646800309401129],
              [-1.163875659444333, 54.647056966009551],
              [-1.167733146874415, 54.646436460062041],
              [-1.16878446997599, 54.644277811215865],
              [-1.167511678076309, 54.639526632742808],
              [-1.165564570357815, 54.637685280954329],
              [-1.162184499275254, 54.635922068227316],
              [-1.161784437425302, 54.635411534659163],
              [-1.164792641987028, 54.636568268824348],
              [-1.165346139918281, 54.637229932404288],
              [-1.166374437568703, 54.637252290196678],
              [-1.166081070520402, 54.637051658357912],
              [-1.166961106031239, 54.636595780439585],
              [-1.166685809264004, 54.635890204365317],
              [-1.164791329593285, 54.634363745801231],
              [-1.165910416309097, 54.633882562248459],
              [-1.165563987440323, 54.633702233749332],
              [-1.16418068518334, 54.634073747360695],
              [-1.164760081181938, 54.634603483618932],
              [-1.16405182360259, 54.635145008966504],
              [-1.163240152019261, 54.634383597711754],
              [-1.160410344178171, 54.635180939126109],
              [-1.160863405900353, 54.634771574092731],
              [-1.163892624225591, 54.633767099834756],
              [-1.164747787718096, 54.632103211332861],
              [-1.165576813319836, 54.631864477511932],
              [-1.166866847489704, 54.632262493949511],
              [-1.167552605852688, 54.632894501643484],
              [-1.169463053069687, 54.633182616655859],
              [-1.173994584624434, 54.633325936005143],
              [-1.175981675411347, 54.633121083830893],
              [-1.176807837836012, 54.632331350316818],
              [-1.176876554105435, 54.632917770074741],
              [-1.177605093928774, 54.633126722932303],
              [-1.181832602598246, 54.633290152208794],
              [-1.184153640869571, 54.633271654446496],
              [-1.185338654253981, 54.632966881304327],
              [-1.186514793117406, 54.633105994787833],
              [-1.186846150803667, 54.632803560078003],
              [-1.188595639658824, 54.632701155338978],
              [-1.190622675500376, 54.631883417563337],
              [-1.190378223782187, 54.631243707241609],
              [-1.19218927188983, 54.630375969943458],
              [-1.200729510892624, 54.623013849812075],
              [-1.20358775217638, 54.622896971772235],
              [-1.209949217558574, 54.621905124398253],
              [-1.211877213451122, 54.621994972002923],
              [-1.212355294242578, 54.62226050188039],
              [-1.212778133350299, 54.622740460038628],
              [-1.213719115112177, 54.622775329990496],
              [-1.213958209175286, 54.623387997302608],
              [-1.213548801679526, 54.623575864241516],
              [-1.213923503484458, 54.623661875390006],
              [-1.214578480865846, 54.623670617419258],
              [-1.213848039410087, 54.623077231077033],
              [-1.214095185378954, 54.622870336518261],
              [-1.215823256488833, 54.623694858517169],
              [-1.2182885412226, 54.623312668882988],
              [-1.219613614799747, 54.623510838095321],
              [-1.21963461016934, 54.624841946326129],
              [-1.220600787643751, 54.625344248209466],
              [-1.221692259429453, 54.625288356876624],
              [-1.22361970609185, 54.624033560416805],
              [-1.224415447150393, 54.625355250999903],
              [-1.222290242622471, 54.625769406288043],
              [-1.222286543497998, 54.626207048652518],
              [-1.223062275862166, 54.626546342101136],
              [-1.224349958642464, 54.626436864391565],
              [-1.225247292747885, 54.625676017078433],
              [-1.226102567680881, 54.625579032426053],
              [-1.225902318984755, 54.626581598768723],
              [-1.226531194630932, 54.626989131087413],
              [-1.227393896953752, 54.626991940145771],
              [-1.229158709394354, 54.626305793275584],
              [-1.229605193962587, 54.62513673144705],
              [-1.230124607147379, 54.626420678059361],
              [-1.230865870306757, 54.626455942402067],
              [-1.232035858452943, 54.625850453295669],
              [-1.233215998678441, 54.627668804500331],
              [-1.233058910121929, 54.628444284586237],
              [-1.23407169840349, 54.629034845292544],
              [-1.234260774671669, 54.629772073448208],
              [-1.233490409813815, 54.62996311763024],
              [-1.233100182360433, 54.631109182696051],
              [-1.236039195773708, 54.631915010039123],
              [-1.235935125295303, 54.633665913089295],
              [-1.236209797550596, 54.631904402669321],
              [-1.238286848563917, 54.632750571211382],
              [-1.236948404463304, 54.631921638742604],
              [-1.233418580883148, 54.631147145703125],
              [-1.233276064632931, 54.630900000881475],
              [-1.233770620084887, 54.630549043260515],
              [-1.233644277745126, 54.630100693021362],
              [-1.234474763213757, 54.629925305625569],
              [-1.23428306427109, 54.628997537475755],
              [-1.23903818181224, 54.627936481671348],
              [-1.237446569164023, 54.626489456767658],
              [-1.238889192076696, 54.626458087742385],
              [-1.240372384040721, 54.625495905703019],
              [-1.24072226900862, 54.624755775290431],
              [-1.241626825259597, 54.625253927571421],
              [-1.24565556891226, 54.626083415402199],
              [-1.247393339700293, 54.625937327736324],
              [-1.250369380285856, 54.625296660033897],
              [-1.254068924906659, 54.626858963273875],
              [-1.253817556745954, 54.627475718109729],
              [-1.254254774934888, 54.626860107491311],
              [-1.250768975230502, 54.625297334718887],
              [-1.252444815782108, 54.623947061355722],
              [-1.254018454548825, 54.624114034053676],
              [-1.255594749739892, 54.62337242050171],
              [-1.256675701899955, 54.623378159539612],
              [-1.257619063900953, 54.624050777150828],
              [-1.256190591033009, 54.625604847318201],
              [-1.258701288290702, 54.626458720257538],
              [-1.259828912252937, 54.624580151206139],
              [-1.262114122304027, 54.624885269821597],
              [-1.263890547031493, 54.624290356311931],
              [-1.266780118941227, 54.624147912547492],
              [-1.267430984539751, 54.624558959355276],
              [-1.267598071541292, 54.626035627513055],
              [-1.268502261167045, 54.625624096054601],
              [-1.268938403801567, 54.626351077186385],
              [-1.271902951505675, 54.62531296430511],
              [-1.276151884502454, 54.627297581421061],
              [-1.279181576892844, 54.628175696464602],
              [-1.285970051897543, 54.627682971816498],
              [-1.286257873681728, 54.628230175010067],
              [-1.291032963132551, 54.627005434532094],
              [-1.292401518980359, 54.628741623249702],
              [-1.296184288932306, 54.628015945656273],
              [-1.29901769447865, 54.627995526897301],
              [-1.29857440760478, 54.63053356576966],
              [-1.298796824739139, 54.633453806213367],
              [-1.298169849018626, 54.635498295166897],
              [-1.302796266995649, 54.635748780071403],
              [-1.304345829203214, 54.636684237821889],
              [-1.307001958114754, 54.63696547153836],
              [-1.308405287699396, 54.638264010107143],
              [-1.308245122031965, 54.638572245449524],
              [-1.31187422351208, 54.640929505508836],
              [-1.311939361332198, 54.641201279868675],
              [-1.311227891850097, 54.641217008873525],
              [-1.311484539357735, 54.641892485139188],
              [-1.315222211489741, 54.643442337113278],
              [-1.315561309825845, 54.643918761098789],
              [-1.31503634660712, 54.644082951017602],
              [-1.316349845651759, 54.645223616161289],
              [-1.317164466359973, 54.644789648965144],
              [-1.31798558938843, 54.645262490432671],
              [-1.320695865156802, 54.644983843046148],
              [-1.322014154239307, 54.644543678976589],
              [-1.326146716730865, 54.645117626818092],
              [-1.32665773031574, 54.644388934771868],
              [-1.327898752069116, 54.644494680252215],
              [-1.32864009699121, 54.644173464540899],
              [-1.329496656560166, 54.643064731791824],
              [-1.329039268449255, 54.638468999277322],
              [-1.329494350652631, 54.636516868349609],
              [-1.33195621455642, 54.633677126560869],
              [-1.33504217965347, 54.63130178943431],
              [-1.337494724135718, 54.63490369384661],
              [-1.33965202583211, 54.636194305597414],
              [-1.342807492259478, 54.636699450044219],
              [-1.34988215896957, 54.638798308603079],
              [-1.354665330289048, 54.638095940388354],
              [-1.356813115147779, 54.638365283765879],
              [-1.361918873002737, 54.640402642415324],
              [-1.365172813202398, 54.642383364767909],
              [-1.368001324085792, 54.643539482055779],
              [-1.37435710695105, 54.643771073358373],
              [-1.379389963930747, 54.643268526039797],
              [-1.380884877436341, 54.64390615171294],
              [-1.381295824777238, 54.644293786750254],
              [-1.382681320527783, 54.644384430460896],
              [-1.38375048398611, 54.646232177361945],
              [-1.378891755872705, 54.646168728458761],
              [-1.374500439920486, 54.646547854166243],
              [-1.368953374877482, 54.647802418822245],
              [-1.366512283355426, 54.648818678014635],
              [-1.36486657754328, 54.648412844820989],
              [-1.362655924741986, 54.649153438770533],
              [-1.359291278007288, 54.64945652750594],
              [-1.358022117558402, 54.648564604781221],
              [-1.3562819375344, 54.648651533059244],
              [-1.355824313830372, 54.648287829361408],
              [-1.352712650467376, 54.648958744021542],
              [-1.349783616745238, 54.648570108578724],
              [-1.349882720399715, 54.650712210677675],
              [-1.348728860410963, 54.650858791908945],
              [-1.347865202631775, 54.650593528305663],
              [-1.345826250416092, 54.650748796064796],
              [-1.344037836000608, 54.650647462788562],
              [-1.343125334054608, 54.650241705719473],
              [-1.34120838010049, 54.650232198549482],
              [-1.341883491271938, 54.652188713446144],
              [-1.341295956905279, 54.653251363876763],
              [-1.343443690328499, 54.655684079506536],
              [-1.343130477210692, 54.657517499242175],
              [-1.344913458472992, 54.658457289434274],
              [-1.345416759498219, 54.65829465062059],
              [-1.347498752674161, 54.658572787098052],
              [-1.346306213333408, 54.659279917202909],
              [-1.346384954063582, 54.659975924390949],
              [-1.34599551078481, 54.660094247395385],
              [-1.34643503202476, 54.660719407202663],
              [-1.347069445388292, 54.660889083312981],
              [-1.346335596380478, 54.661119684582971],
              [-1.346410173555014, 54.661495737173553],
              [-1.347837247621672, 54.662410197496847],
              [-1.346629538742242, 54.66299323048348],
              [-1.346571163165837, 54.664022808620324],
              [-1.346032376427877, 54.66446025833239],
              [-1.343514519091965, 54.665139533154651],
              [-1.343363088372512, 54.665781272474192],
              [-1.342257106565326, 54.666103295795537],
              [-1.343515935309062, 54.668610260478609],
              [-1.3406996037336, 54.668744156211716],
              [-1.340187287735009, 54.669262605499632],
              [-1.338073686968699, 54.669562925416535],
              [-1.335693315710218, 54.671291553766416],
              [-1.334309894416879, 54.671213866850444],
              [-1.333024308883326, 54.672641998894058],
              [-1.332378299213272, 54.675441438476113],
              [-1.333442813846254, 54.677198831146256],
              [-1.331903440792079, 54.677445583256997],
              [-1.331835981614256, 54.68117744360385],
              [-1.330885861269508, 54.681233313734538],
              [-1.330931720252356, 54.682123263109567],
              [-1.331331297551732, 54.683669404902943],
              [-1.332092444418662, 54.683884791063448],
              [-1.331636336824937, 54.685117966048388],
              [-1.332115240234218, 54.686843379400393],
              [-1.334426337119841, 54.689036291237322],
              [-1.338659116051401, 54.68945936685531],
              [-1.344644013997659, 54.690808432261477],
              [-1.344462195168178, 54.691116595601734],
              [-1.333009339216471, 54.69201122867922],
              [-1.332175113652136, 54.690189502380129],
              [-1.329810682490855, 54.689542885005864],
              [-1.327540376162015, 54.691371713723278],
              [-1.32596778023451, 54.691154487266381],
              [-1.319481005760326, 54.691278217486953],
              [-1.319729876528663, 54.692723796490299],
              [-1.319372858822309, 54.693291557351841],
              [-1.315362599819778, 54.694568473848285],
              [-1.312682352635666, 54.695902232147816],
              [-1.313494187755639, 54.697075117946497],
              [-1.3142320642323, 54.697943825274116],
              [-1.312820930903314, 54.698720385407071],
              [-1.313835485356069, 54.701217275856806],
              [-1.309337452482986, 54.701553908201433],
              [-1.309301593352503, 54.702477548473773],
              [-1.307768065757708, 54.702771659477484],
              [-1.306217382604125, 54.703797179657485],
              [-1.30639563607732, 54.703991415863712],
              [-1.304105622354182, 54.704323389375837],
              [-1.304202718326231, 54.70563242655529],
              [-1.303201259070796, 54.70565992742825],
              [-1.303797199158237, 54.707653928933667],
              [-1.301312705861656, 54.708090777833441],
              [-1.302004604252203, 54.709012315465557],
              [-1.301546624455813, 54.710194139911671],
              [-1.302724592616181, 54.711736763633134],
              [-1.304123237291003, 54.712634490657031],
              [-1.303610791092956, 54.7131006602336],
              [-1.305504208081987, 54.715206340612447],
              [-1.304605838292197, 54.716634586962634],
              [-1.305506736234514, 54.717511468188619],
              [-1.303659595557948, 54.71849751248488],
              [-1.303201350791994, 54.717880183915511],
              [-1.303223610391571, 54.718121157898544],
              [-1.301765831050483, 54.718216122321223],
              [-1.299305164890274, 54.719123077833288],
              [-1.294896110364071, 54.71939948180232],
              [-1.292179609122025, 54.718581137406936],
              [-1.290287488615679, 54.718387647985118],
              [-1.285520173115087, 54.72041134469093],
              [-1.282695648992371, 54.722113848290995],
              [-1.282344527690213, 54.72300685665671],
              [-1.281201944395496, 54.723000090737727],
              [-1.277924463519057, 54.723784941943457],
              [-1.276512793567409, 54.724414592661631],
              [-1.275616919193864, 54.725459802761613],
              [-1.274891711255064, 54.725204737724518],
              [-1.272495505675996, 54.72540247762462],
              [-1.270237428346303, 54.727158414465499],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000002",
        LAD13CDO: "00EC",
        LAD13NM: "Middlesbrough",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-1.197048037027911, 54.581780345899148],
              [-1.194355604859136, 54.57991112328115],
              [-1.197385426593587, 54.578468152044842],
              [-1.200826142783779, 54.57759939608065],
              [-1.202148315569937, 54.578975049750461],
              [-1.202459372443086, 54.580397055637732],
              [-1.198444458550602, 54.581659286486087],
              [-1.197373022117048, 54.581304388822609],
              [-1.197048037027911, 54.581780345899148],
            ],
          ],
          [
            [
              [-1.230015857384222, 54.584095668590074],
              [-1.230148168836763, 54.58372534518486],
              [-1.229823602716354, 54.58403423264069],
              [-1.229684320425522, 54.583625334942965],
              [-1.22933940178304, 54.583946673482814],
              [-1.228552317728959, 54.583592963910206],
              [-1.226674819946029, 54.583545038600839],
              [-1.221000115283009, 54.582451783995751],
              [-1.215696694428568, 54.581054209337402],
              [-1.215324003694152, 54.580641984775461],
              [-1.215906795792971, 54.580506461149398],
              [-1.219867516018949, 54.580184248156996],
              [-1.219768867178924, 54.578868806499379],
              [-1.212447301424188, 54.580186535412032],
              [-1.209198456331129, 54.579857102215939],
              [-1.204800703780301, 54.57997568503265],
              [-1.203713285821352, 54.577027070971482],
              [-1.218714839116404, 54.576141626927047],
              [-1.218306297268033, 54.57509738942813],
              [-1.21963614625582, 54.572614755169297],
              [-1.21812554905762, 54.575003655489397],
              [-1.218452035482165, 54.576131842303973],
              [-1.211365432671454, 54.576520860435288],
              [-1.211175795822296, 54.575926478700595],
              [-1.209085982845181, 54.574638477791012],
              [-1.208881776617772, 54.574638940929006],
              [-1.211128960651301, 54.576028626066979],
              [-1.211205464955299, 54.57647308554494],
              [-1.203614124865306, 54.576955420872657],
              [-1.203932185899227, 54.580084113116492],
              [-1.202801932697871, 54.580285176020006],
              [-1.202293444840164, 54.578990385387122],
              [-1.200988774224933, 54.577589685040152],
              [-1.201728964493704, 54.577554126068293],
              [-1.200235427288187, 54.576874732153733],
              [-1.194369791974834, 54.574327547779582],
              [-1.190557588990429, 54.574979744397325],
              [-1.190374595343001, 54.574846410627572],
              [-1.186642187867703, 54.572650111763785],
              [-1.184745558220751, 54.569789344771635],
              [-1.183534619210816, 54.569238362940411],
              [-1.179346487394675, 54.56867620903472],
              [-1.177860926474371, 54.568557384822604],
              [-1.176393859665747, 54.566523516614431],
              [-1.175753672069292, 54.561072072680815],
              [-1.172285038773071, 54.559313889130891],
              [-1.170715566686472, 54.557403268879725],
              [-1.168840975230867, 54.556437775192201],
              [-1.166625638450153, 54.554063420643608],
              [-1.173644822725219, 54.552785049631346],
              [-1.176840798127928, 54.551549530244024],
              [-1.179112184870213, 54.550230369740468],
              [-1.189594118659094, 54.548208811463276],
              [-1.191740957598991, 54.547451174797352],
              [-1.195722071593899, 54.545152730026537],
              [-1.198731482872698, 54.544538202348122],
              [-1.198744931542649, 54.544246209986909],
              [-1.191407804011211, 54.53927965090984],
              [-1.180011195959314, 54.532995539086144],
              [-1.167791147209198, 54.526952720021384],
              [-1.164348602417974, 54.525663598092102],
              [-1.16155377066554, 54.525047513574911],
              [-1.162702574818848, 54.523346108571438],
              [-1.164964445817747, 54.521908514813447],
              [-1.161837332335202, 54.520178427298326],
              [-1.161526492982808, 54.518458822457987],
              [-1.159726550205601, 54.515834656810547],
              [-1.156092453767414, 54.51625242835528],
              [-1.153255138082658, 54.516084313160945],
              [-1.148305851421904, 54.51444715154075],
              [-1.147248918358165, 54.515280907555812],
              [-1.142394917945583, 54.515969164280989],
              [-1.139865282991242, 54.51579123214168],
              [-1.137305760547795, 54.51490663928336],
              [-1.139615877777542, 54.509134428991658],
              [-1.141024348720653, 54.507813432491211],
              [-1.142811975463207, 54.504827079465798],
              [-1.144038808064955, 54.503835490103761],
              [-1.146261534519193, 54.503070210092524],
              [-1.146183588348026, 54.502809030517291],
              [-1.146680146326294, 54.50135930120117],
              [-1.148045701632181, 54.50111279188355],
              [-1.152654249406476, 54.501899193530626],
              [-1.158282680935207, 54.502359993723907],
              [-1.163099932437686, 54.503529404363327],
              [-1.164060030135146, 54.503399438079803],
              [-1.164179679504514, 54.503136041096134],
              [-1.16535600880575, 54.503534208778454],
              [-1.166019686426489, 54.503096616203244],
              [-1.166624888031909, 54.503711018697771],
              [-1.166963803989343, 54.503371839622147],
              [-1.168959045977526, 54.503521271526303],
              [-1.170640044792241, 54.502335714220884],
              [-1.171779212894916, 54.502664363067325],
              [-1.172295967712488, 54.502539383433295],
              [-1.172713985014641, 54.502868478636479],
              [-1.173527672143594, 54.502492085270468],
              [-1.174378411401159, 54.50288164875461],
              [-1.173894891955166, 54.503196494167078],
              [-1.174195670686253, 54.503826753209346],
              [-1.173457906979346, 54.504036508022445],
              [-1.173850587762052, 54.504704243310165],
              [-1.175536012572822, 54.504675300840432],
              [-1.176130377937967, 54.504300989771266],
              [-1.179365883996843, 54.505986513659586],
              [-1.17999824154562, 54.506182229316003],
              [-1.181149521292554, 54.505759542171646],
              [-1.185080675747698, 54.507272571342945],
              [-1.189711904119686, 54.506280043337895],
              [-1.195471903261041, 54.506619561688645],
              [-1.196666749447723, 54.506172751690933],
              [-1.202132737859166, 54.506940485232818],
              [-1.203898781068827, 54.506899096874676],
              [-1.206853304053686, 54.507930460903985],
              [-1.208487538747035, 54.50887762806876],
              [-1.214093546634853, 54.510024992960815],
              [-1.219052052874112, 54.510153268518373],
              [-1.220088245030884, 54.510892406677279],
              [-1.227598823223792, 54.511984003530515],
              [-1.230591283773219, 54.511869164897227],
              [-1.230428614677735, 54.510990984682827],
              [-1.231714997421907, 54.511414367526676],
              [-1.233391446229126, 54.510556844830575],
              [-1.234852661683684, 54.510303671394581],
              [-1.236508319037623, 54.510721249927208],
              [-1.237882481995338, 54.510663410177635],
              [-1.239201724396114, 54.511483253105347],
              [-1.240281927954147, 54.511453194496049],
              [-1.241484774416895, 54.512056589844157],
              [-1.242177076554606, 54.511537877281995],
              [-1.243648736902673, 54.511387116453967],
              [-1.245256689361245, 54.51155083921104],
              [-1.246779990289486, 54.511279036155194],
              [-1.248872544786869, 54.511969677050871],
              [-1.249845656534182, 54.511715088402617],
              [-1.250600580234495, 54.511988482605766],
              [-1.251324858468684, 54.513093890677062],
              [-1.256432808912483, 54.512333628469506],
              [-1.256356188739998, 54.511364345066895],
              [-1.258425736486149, 54.512566944902083],
              [-1.259311180836586, 54.512716163449568],
              [-1.263840131534343, 54.512534408001059],
              [-1.264190834159575, 54.512959835039851],
              [-1.266190235687098, 54.513525586062698],
              [-1.267301360296836, 54.51444541192572],
              [-1.267774376720409, 54.514339531695924],
              [-1.268257832108122, 54.514686663441772],
              [-1.26997248683441, 54.51459636676281],
              [-1.272773582504114, 54.515084161904461],
              [-1.273355414731849, 54.514765020654771],
              [-1.274293411904784, 54.514918039628313],
              [-1.276767063166905, 54.516545140212415],
              [-1.277662254901501, 54.516588234244047],
              [-1.278427716206041, 54.517243468619228],
              [-1.278935844994092, 54.517249194294827],
              [-1.278601420045443, 54.51746738473031],
              [-1.279411564140525, 54.517604323190589],
              [-1.279507205319567, 54.517875404647732],
              [-1.280792008623651, 54.51790192211071],
              [-1.28228640119173, 54.518397897468525],
              [-1.281988478213183, 54.519706449072871],
              [-1.280736285552805, 54.520546494836367],
              [-1.281996592073206, 54.522503282122109],
              [-1.282837893841955, 54.522984587825789],
              [-1.282042913929625, 54.524354898480262],
              [-1.28247149118713, 54.525378374502331],
              [-1.282047226954273, 54.526399487428641],
              [-1.282978082036733, 54.526536218736055],
              [-1.283329121568961, 54.527490031138832],
              [-1.283909849236081, 54.527769372963746],
              [-1.283474852666494, 54.527815328070105],
              [-1.284365291604846, 54.527967985976161],
              [-1.284245224538704, 54.528560423470672],
              [-1.285045579948159, 54.530332914569975],
              [-1.284655261407127, 54.530473502320696],
              [-1.28492771082072, 54.530976591855811],
              [-1.284532558597771, 54.53157009954738],
              [-1.28537371750974, 54.536231274769804],
              [-1.284588075286938, 54.536703846258263],
              [-1.284561744792928, 54.537502641363481],
              [-1.283971724636821, 54.53809948809991],
              [-1.283482349590735, 54.537978860876585],
              [-1.281907530181499, 54.538677707501293],
              [-1.278786296365083, 54.542400463877271],
              [-1.278920378626464, 54.545141419467349],
              [-1.278651556663788, 54.545746444324834],
              [-1.277863103483789, 54.546013151644566],
              [-1.278047699101549, 54.547027993674817],
              [-1.276989406127422, 54.547166367789032],
              [-1.277200559153053, 54.547814697508549],
              [-1.276284770894423, 54.548722310252764],
              [-1.276271263075039, 54.548874110808789],
              [-1.275686206633891, 54.551706028023453],
              [-1.274080431374267, 54.552108014980149],
              [-1.271341473893765, 54.553977041372221],
              [-1.27062601101614, 54.556381262841093],
              [-1.271426436806286, 54.557365672166711],
              [-1.27476397452373, 54.558462363887855],
              [-1.277974873841911, 54.560610590254647],
              [-1.281578896061898, 54.564695972198457],
              [-1.281759187754397, 54.565261427760568],
              [-1.278136075360214, 54.566194299824424],
              [-1.273833657307449, 54.566787774336689],
              [-1.269163129093516, 54.567028379897266],
              [-1.265839151683066, 54.567901578492581],
              [-1.262224595880222, 54.569612305064688],
              [-1.260394460072343, 54.571681637281948],
              [-1.257145318349561, 54.587125723430759],
              [-1.25553618590639, 54.589153197349212],
              [-1.252994072578725, 54.590399318359829],
              [-1.251222860213134, 54.590697532007894],
              [-1.246162636885531, 54.590263516909104],
              [-1.239800288417759, 54.587210390726099],
              [-1.234307569166402, 54.585195937397145],
              [-1.230015857384222, 54.584095668590074],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000003",
        LAD13CDO: "00EE",
        LAD13NM: "Redcar and Cleveland",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-1.200988774224933, 54.577589685040152],
              [-1.200235427288187, 54.576874732153733],
              [-1.201728964493704, 54.577554126068293],
              [-1.200988774224933, 54.577589685040152],
            ],
          ],
          [
            [
              [-1.150286223115081, 54.632195861076809],
              [-1.150956059012018, 54.631943528536951],
              [-1.151283461983049, 54.632215433532387],
              [-1.150555425074682, 54.633476602837476],
              [-1.15127931568352, 54.63457270114251],
              [-1.150930006532148, 54.635202939914194],
              [-1.150497308476488, 54.635150477943462],
              [-1.149621878585645, 54.635006834718233],
              [-1.149394951059036, 54.634670924319877],
              [-1.150286223115081, 54.632195861076809],
            ],
          ],
          [
            [
              [-1.137165982518246, 54.646934414212502],
              [-1.138731612734301, 54.640681600621797],
              [-1.12771236473201, 54.635732690951116],
              [-1.123414262447229, 54.633013642217989],
              [-1.118958383847489, 54.629378408038086],
              [-1.118797866838989, 54.628849700260751],
              [-1.116766540911399, 54.628446665524194],
              [-1.107543544462363, 54.625343235202614],
              [-1.10047839100883, 54.623478281430501],
              [-1.08811810548682, 54.62074006038975],
              [-1.084605835791149, 54.620385538038001],
              [-1.080129424461707, 54.620483734661384],
              [-1.075086018205624, 54.62113463794519],
              [-1.075033002929717, 54.621355315848632],
              [-1.073156625000582, 54.621324795380559],
              [-1.063796410721024, 54.619015016706051],
              [-1.063205365486954, 54.619126380507872],
              [-1.06317563442614, 54.618870017719807],
              [-1.059577059946934, 54.618043175054346],
              [-1.054102736569991, 54.616772903949567],
              [-1.051772006085521, 54.615846079816407],
              [-1.041016668892405, 54.608237357142464],
              [-1.036303373139924, 54.606026846943884],
              [-1.025208283053785, 54.601011275999291],
              [-1.020028504397717, 54.599152259024528],
              [-1.016378963806991, 54.598235632434715],
              [-1.002399420293237, 54.593578232572092],
              [-0.984416385801768, 54.58929909732511],
              [-0.967652701481507, 54.585779009542399],
              [-0.960272394894002, 54.585017540583138],
              [-0.956690749742224, 54.585299483785285],
              [-0.954280815592638, 54.585989583583],
              [-0.949381959047212, 54.586701200804697],
              [-0.943534448888336, 54.588783891488553],
              [-0.941018349591803, 54.589075564858156],
              [-0.938324959457324, 54.588744610959829],
              [-0.936295034656602, 54.588312479459667],
              [-0.933514605677515, 54.587051368645604],
              [-0.926201314002431, 54.586698230937202],
              [-0.919966486877765, 54.584656576785918],
              [-0.917548673555239, 54.582585877678724],
              [-0.917141718827486, 54.581477698606378],
              [-0.911904576193135, 54.579259403840808],
              [-0.908240993409838, 54.576824107552753],
              [-0.904008273079652, 54.574572279379787],
              [-0.901571528746469, 54.573890521370522],
              [-0.900347398312803, 54.5752877211551],
              [-0.899081024094921, 54.575551222226046],
              [-0.900277755201386, 54.575116329195431],
              [-0.900808842842935, 54.57394920532267],
              [-0.899145087926424, 54.572255251437454],
              [-0.898165024720533, 54.571943460625434],
              [-0.896014402516606, 54.571387320098054],
              [-0.893530078162431, 54.571160622596608],
              [-0.884269560364416, 54.571423423703223],
              [-0.877284059986516, 54.569896586385056],
              [-0.871960001965872, 54.569669133861147],
              [-0.869747025619665, 54.570042135474857],
              [-0.867345340699647, 54.570971446576465],
              [-0.866015460459586, 54.570900576411901],
              [-0.863148886820377, 54.57157649815894],
              [-0.852993901217645, 54.571730539720697],
              [-0.84691080126019, 54.570330923128509],
              [-0.84495003204758, 54.57041827833654],
              [-0.842112792997527, 54.569611062976492],
              [-0.840840178114325, 54.568506013961553],
              [-0.837867770527867, 54.568108127467546],
              [-0.834690339486465, 54.567308254200128],
              [-0.834117843478503, 54.566773382341523],
              [-0.829896632391354, 54.565809637933924],
              [-0.826091511324144, 54.56280514351289],
              [-0.823982142713577, 54.561832896040244],
              [-0.817794444565773, 54.561139893615078],
              [-0.812072710643903, 54.559426580137583],
              [-0.810140872209357, 54.559332106810274],
              [-0.808794773075165, 54.558560323715191],
              [-0.806165948485598, 54.558928967519115],
              [-0.803773256750029, 54.558677957120047],
              [-0.802346049083959, 54.559043111377981],
              [-0.801914859402088, 54.558823144369157],
              [-0.800476157934652, 54.559737296342789],
              [-0.799308564559855, 54.55984345310965],
              [-0.796798726882959, 54.558948539204735],
              [-0.792689750921564, 54.560628714705949],
              [-0.791718990033944, 54.560718787008447],
              [-0.791158040193483, 54.560338407781281],
              [-0.788418488531258, 54.560781055759001],
              [-0.789208680540173, 54.560417781977549],
              [-0.791081771173551, 54.560250467508162],
              [-0.790647584753626, 54.559610715612507],
              [-0.79276227764946, 54.559586892653741],
              [-0.794120065430845, 54.558852673316906],
              [-0.794235739602587, 54.558338844322655],
              [-0.794313813801292, 54.55742200220066],
              [-0.79547115970252, 54.557136941360888],
              [-0.796083832198442, 54.55660109418816],
              [-0.795533658529466, 54.554858342371794],
              [-0.798294661375248, 54.555668600564985],
              [-0.798799990746482, 54.555573856903834],
              [-0.798489914884277, 54.55507556937286],
              [-0.797368419583808, 54.554617751803846],
              [-0.797235816195352, 54.554132009489663],
              [-0.800564518803146, 54.551824713889964],
              [-0.800403261138462, 54.550997167213183],
              [-0.801831035191973, 54.551395078094131],
              [-0.804908950835248, 54.550880875871542],
              [-0.806448652435687, 54.5514182443343],
              [-0.808103951042847, 54.551226950075126],
              [-0.8095837636605, 54.551698978733526],
              [-0.810511909879474, 54.551204808531736],
              [-0.813049916783344, 54.552028715314776],
              [-0.814717731760955, 54.551672084283588],
              [-0.815660267244786, 54.552493778395068],
              [-0.818905234177491, 54.550689359385231],
              [-0.820383338842252, 54.549348470821741],
              [-0.821332960582703, 54.549390079496199],
              [-0.821827837533953, 54.549063262236658],
              [-0.82525862494761, 54.549058871226805],
              [-0.826968900832905, 54.548561381582793],
              [-0.827985322057726, 54.5473507362642],
              [-0.829755560597974, 54.54680705367754],
              [-0.830834261008164, 54.545522391236766],
              [-0.832481983960358, 54.545204867500388],
              [-0.83478432060502, 54.5433423950508],
              [-0.836645948308628, 54.542619743797204],
              [-0.838649365350536, 54.539786376560031],
              [-0.841855830980749, 54.53735904721853],
              [-0.842136772768642, 54.536006429783484],
              [-0.843885279292278, 54.534785580118502],
              [-0.844579365297761, 54.533466562823968],
              [-0.84863044043657, 54.52999019554634],
              [-0.850128304603893, 54.527609287594771],
              [-0.850056689957383, 54.526473492052467],
              [-0.850683546468596, 54.526325763952968],
              [-0.849910849683509, 54.524955023874021],
              [-0.85039016724598, 54.524173177921526],
              [-0.851411963945812, 54.523990552802992],
              [-0.850632503746096, 54.523411547221684],
              [-0.849931216676718, 54.519941115985695],
              [-0.851174473508363, 54.518359453735279],
              [-0.8521673406361, 54.518046229823845],
              [-0.852218572775938, 54.517596444236837],
              [-0.851273594451257, 54.513670736639],
              [-0.850189854800859, 54.51144772401905],
              [-0.852310110447693, 54.510363301911063],
              [-0.852330418246835, 54.505615412103914],
              [-0.853304696630094, 54.502700128200239],
              [-0.848598827907319, 54.495518449769179],
              [-0.845042752690642, 54.492622917271987],
              [-0.843926199618043, 54.491211095321141],
              [-0.844123204265057, 54.489535909747005],
              [-0.848514813686882, 54.48795916124088],
              [-0.856473967064697, 54.487859439652489],
              [-0.863478238583584, 54.489069586220289],
              [-0.866962794096606, 54.489227214343558],
              [-0.875765097814505, 54.491150908909582],
              [-0.876320820593791, 54.495375692000067],
              [-0.877157025912131, 54.494948465961414],
              [-0.878170637724344, 54.495315574699255],
              [-0.880628374232315, 54.496457289271447],
              [-0.88080873423539, 54.497033257433856],
              [-0.894196313132046, 54.496898478061738],
              [-0.89420169860064, 54.49635568542589],
              [-0.895769485974059, 54.495589002298253],
              [-0.901399422174136, 54.494188808033833],
              [-0.916349331719803, 54.492210757310858],
              [-0.92293016914338, 54.491004122314415],
              [-0.925380186142906, 54.491064566096284],
              [-0.929018261233348, 54.491624404156944],
              [-0.932593962923443, 54.491441220936075],
              [-0.943970792086799, 54.488675924157093],
              [-0.952572741783517, 54.488002090792413],
              [-0.953358542069429, 54.488181453169851],
              [-0.966174790805157, 54.49618622725243],
              [-0.967812309915715, 54.497255333237959],
              [-0.981254762217599, 54.499743780729183],
              [-0.987370174133265, 54.500844863568773],
              [-1.0033850586227, 54.50299914779724],
              [-1.023784718733176, 54.502032224921869],
              [-1.036836252928276, 54.494031208338555],
              [-1.039489759907339, 54.495106525366573],
              [-1.042865980151527, 54.495257318472355],
              [-1.042962817282074, 54.495540286499626],
              [-1.044591832470809, 54.495998937622723],
              [-1.046695878837249, 54.495950832890323],
              [-1.049120225230042, 54.496404904686131],
              [-1.051064055922056, 54.496408489152792],
              [-1.057724411126516, 54.49898240215569],
              [-1.060003722939377, 54.500319473865261],
              [-1.064785688662708, 54.498506062815892],
              [-1.067911813074933, 54.500435500981396],
              [-1.073798500954585, 54.502606216563763],
              [-1.07818334207616, 54.503808959870128],
              [-1.08723710112077, 54.503341983113728],
              [-1.09450946874436, 54.506747095845881],
              [-1.106172700440608, 54.505687972600334],
              [-1.111768870107726, 54.504361339337386],
              [-1.111623382587569, 54.502026283036052],
              [-1.112252538874197, 54.500784371267962],
              [-1.113187230914792, 54.500334676031414],
              [-1.114310388794198, 54.498951679952505],
              [-1.118366233445479, 54.498185941898498],
              [-1.122797754576723, 54.498977586920084],
              [-1.126640573702629, 54.498708818475997],
              [-1.130838843623861, 54.500024236682279],
              [-1.146183588348026, 54.502809030517291],
              [-1.146261534519193, 54.503070210092524],
              [-1.144038808064955, 54.503835490103761],
              [-1.142811975463207, 54.504827079465798],
              [-1.141024348720653, 54.507813432491211],
              [-1.139615877777542, 54.509134428991658],
              [-1.137305760547795, 54.51490663928336],
              [-1.139865282991242, 54.51579123214168],
              [-1.142394917945583, 54.515969164280989],
              [-1.147248918358165, 54.515280907555812],
              [-1.148305851421904, 54.51444715154075],
              [-1.153255138082658, 54.516084313160945],
              [-1.156092453767414, 54.51625242835528],
              [-1.159726550205601, 54.515834656810547],
              [-1.161526492982808, 54.518458822457987],
              [-1.161837332335202, 54.520178427298326],
              [-1.164964445817747, 54.521908514813447],
              [-1.162702574818848, 54.523346108571438],
              [-1.16155377066554, 54.525047513574911],
              [-1.164348602417974, 54.525663598092102],
              [-1.167791147209198, 54.526952720021384],
              [-1.180011195959314, 54.532995539086144],
              [-1.191407804011211, 54.53927965090984],
              [-1.198744931542649, 54.544246209986909],
              [-1.198731482872698, 54.544538202348122],
              [-1.195722071593899, 54.545152730026537],
              [-1.191740957598991, 54.547451174797352],
              [-1.189594118659094, 54.548208811463276],
              [-1.179112184870213, 54.550230369740468],
              [-1.176840798127928, 54.551549530244024],
              [-1.173644822725219, 54.552785049631346],
              [-1.166625638450153, 54.554063420643608],
              [-1.168840975230867, 54.556437775192201],
              [-1.170715566686472, 54.557403268879725],
              [-1.172285038773071, 54.559313889130891],
              [-1.175753672069292, 54.561072072680815],
              [-1.176393859665747, 54.566523516614431],
              [-1.177860926474371, 54.568557384822604],
              [-1.179346487394675, 54.56867620903472],
              [-1.183534619210816, 54.569238362940411],
              [-1.184745558220751, 54.569789344771635],
              [-1.186642187867703, 54.572650111763785],
              [-1.190374595343001, 54.574846410627572],
              [-1.190557588990429, 54.574979744397325],
              [-1.194294290584575, 54.574390853576524],
              [-1.199871834974211, 54.576797736992162],
              [-1.200826142783779, 54.57759939608065],
              [-1.197385426593587, 54.578468152044842],
              [-1.194355604859136, 54.57991112328115],
              [-1.197048037027911, 54.581780345899148],
              [-1.196658716922047, 54.581593528420527],
              [-1.19631211572229, 54.581909370314719],
              [-1.195483732458547, 54.581940715572408],
              [-1.196109408942927, 54.582460729158441],
              [-1.193418522752847, 54.584352584205398],
              [-1.191747586553072, 54.585360574495624],
              [-1.190480708591498, 54.585652278785766],
              [-1.184017582516494, 54.589900218542411],
              [-1.18340889207457, 54.589925772344209],
              [-1.181416256576826, 54.59105457348592],
              [-1.169393240543829, 54.598885808583404],
              [-1.163786037392671, 54.60209873793405],
              [-1.162587069523306, 54.601535052463845],
              [-1.16015657551327, 54.601317809374457],
              [-1.15138020829227, 54.59615106757068],
              [-1.160053827118736, 54.601421346705131],
              [-1.162488951560245, 54.601639522564646],
              [-1.163476574169752, 54.602092107115155],
              [-1.160281805009692, 54.604184652182603],
              [-1.160239141667204, 54.604527662067369],
              [-1.151883784656671, 54.59955171058175],
              [-1.147896842751092, 54.598640291800287],
              [-1.14675610914, 54.599082512051574],
              [-1.146859784264272, 54.599454408845851],
              [-1.149630504834765, 54.600644014636373],
              [-1.157577773899655, 54.605437541688694],
              [-1.157194240794956, 54.605717965589328],
              [-1.156848374150585, 54.605598726306027],
              [-1.156003887059098, 54.60683305833124],
              [-1.154854495852031, 54.607462225315238],
              [-1.15502798445734, 54.607906497923402],
              [-1.152882367882749, 54.609537039567385],
              [-1.151249193837101, 54.613246246829974],
              [-1.151005876367203, 54.614149537810178],
              [-1.151539446324124, 54.614404015573164],
              [-1.149647543251389, 54.614091478582651],
              [-1.149114763174854, 54.614319602810625],
              [-1.14937994585778, 54.614671960619717],
              [-1.150667041993403, 54.615028792102621],
              [-1.150708514570304, 54.62032604925232],
              [-1.150940400497562, 54.620866897566017],
              [-1.151775287504997, 54.621083942471358],
              [-1.151505984380879, 54.621447828941108],
              [-1.151877368978973, 54.621909667729184],
              [-1.152339219784758, 54.621968620306191],
              [-1.152313984152091, 54.621617949098564],
              [-1.152765692694383, 54.622612379978747],
              [-1.153309056776773, 54.625459677834421],
              [-1.153298721596678, 54.626106672084298],
              [-1.152914984552405, 54.626013220206943],
              [-1.152751865832464, 54.626334713957974],
              [-1.154609814108184, 54.627491743790834],
              [-1.152743465598335, 54.626664479350907],
              [-1.151972062428399, 54.627857951748773],
              [-1.152646231277161, 54.62813857129742],
              [-1.153623684479124, 54.627987232843516],
              [-1.153257581585638, 54.628460987014542],
              [-1.153819368272189, 54.62923150616367],
              [-1.153150751882878, 54.629353551036417],
              [-1.152111457148554, 54.630316880393217],
              [-1.152209415263509, 54.630746247238392],
              [-1.152999723945184, 54.631031272053789],
              [-1.152725439290768, 54.63133401422418],
              [-1.151869452242766, 54.631227369696632],
              [-1.151251525493241, 54.6289124765689],
              [-1.149391849307848, 54.628211025878521],
              [-1.14604407136164, 54.627712956540783],
              [-1.143683392325802, 54.627641470947395],
              [-1.140553449229222, 54.627984178751518],
              [-1.135431904593632, 54.629620222786272],
              [-1.135672736658435, 54.631047286147748],
              [-1.137423117731364, 54.632223583702256],
              [-1.138462756999292, 54.633608694354741],
              [-1.140082750038801, 54.634365229293273],
              [-1.139796817475957, 54.634994089002838],
              [-1.141058255489285, 54.635198057797979],
              [-1.14012921527331, 54.63605512091808],
              [-1.140143244823348, 54.636418296482653],
              [-1.14071873317923, 54.636605715019606],
              [-1.140901187013592, 54.63581435302909],
              [-1.14102954161604, 54.636781368739371],
              [-1.140161099022987, 54.638289522384909],
              [-1.141238055426278, 54.638282781704845],
              [-1.14161490427411, 54.637971805139436],
              [-1.141894517225145, 54.638158019950723],
              [-1.14049051495085, 54.639567118739059],
              [-1.140784665017592, 54.640387024318997],
              [-1.140299506656881, 54.640762835048875],
              [-1.140010592022879, 54.644321442069518],
              [-1.139162979995922, 54.644833973783378],
              [-1.138756411000291, 54.645594082267316],
              [-1.139159893353054, 54.645493598912076],
              [-1.138429773000263, 54.645871256264456],
              [-1.137460879436346, 54.647571895849204],
              [-1.137165982518246, 54.646934414212502],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000004",
        LAD13CDO: "00EF",
        LAD13NM: "Stockton-on-Tees",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-1.303874364736467, 54.565137187262913],
              [-1.301991923895367, 54.564608695292215],
              [-1.301474006961911, 54.564779157354671],
              [-1.29952032593131, 54.564259202687211],
              [-1.295867630581098, 54.563975590005995],
              [-1.287098832484675, 54.564060869419109],
              [-1.282850057937423, 54.564878756717611],
              [-1.281578896061898, 54.564695972198457],
              [-1.277974873841911, 54.560610590254647],
              [-1.27476397452373, 54.558462363887855],
              [-1.271426436806286, 54.557365672166711],
              [-1.27062601101614, 54.556381262841093],
              [-1.271341473893765, 54.553977041372221],
              [-1.274080431374267, 54.552108014980149],
              [-1.275686206633891, 54.551706028023453],
              [-1.276271263075039, 54.548874110808789],
              [-1.276284770894423, 54.548722310252764],
              [-1.277200559153053, 54.547814697508549],
              [-1.276989406127422, 54.547166367789032],
              [-1.278047699101549, 54.547027993674817],
              [-1.277863103483789, 54.546013151644566],
              [-1.278651556663788, 54.545746444324834],
              [-1.278920378626464, 54.545141419467349],
              [-1.278786296365083, 54.542400463877271],
              [-1.281907530181499, 54.538677707501293],
              [-1.283482349590735, 54.537978860876585],
              [-1.283971724636821, 54.53809948809991],
              [-1.284561744792928, 54.537502641363481],
              [-1.284588075286938, 54.536703846258263],
              [-1.28537371750974, 54.536231274769804],
              [-1.284532558597771, 54.53157009954738],
              [-1.28492771082072, 54.530976591855811],
              [-1.284655261407127, 54.530473502320696],
              [-1.285045579948159, 54.530332914569975],
              [-1.284245224538704, 54.528560423470672],
              [-1.284365291604846, 54.527967985976161],
              [-1.283474852666494, 54.527815328070105],
              [-1.283909849236081, 54.527769372963746],
              [-1.283329121568961, 54.527490031138832],
              [-1.282978082036733, 54.526536218736055],
              [-1.282047226954273, 54.526399487428641],
              [-1.28247149118713, 54.525378374502331],
              [-1.282042913929625, 54.524354898480262],
              [-1.282837893841955, 54.522984587825789],
              [-1.281996592073206, 54.522503282122109],
              [-1.280736285552805, 54.520546494836367],
              [-1.281988478213183, 54.519706449072871],
              [-1.28228640119173, 54.518397897468525],
              [-1.280792008623651, 54.51790192211071],
              [-1.279507205319567, 54.517875404647732],
              [-1.279411564140525, 54.517604323190589],
              [-1.278601420045443, 54.51746738473031],
              [-1.278935844994092, 54.517249194294827],
              [-1.278427716206041, 54.517243468619228],
              [-1.277662254901501, 54.516588234244047],
              [-1.276767063166905, 54.516545140212415],
              [-1.274293411904784, 54.514918039628313],
              [-1.273355414731849, 54.514765020654771],
              [-1.272773582504114, 54.515084161904461],
              [-1.26997248683441, 54.51459636676281],
              [-1.268257832108122, 54.514686663441772],
              [-1.267774376720409, 54.514339531695924],
              [-1.267301360296836, 54.51444541192572],
              [-1.266190235687098, 54.513525586062698],
              [-1.264190834159575, 54.512959835039851],
              [-1.263840131534343, 54.512534408001059],
              [-1.259311180836586, 54.512716163449568],
              [-1.258425736486149, 54.512566944902083],
              [-1.256356188739998, 54.511364345066895],
              [-1.256432808912483, 54.512333628469506],
              [-1.251324858468684, 54.513093890677062],
              [-1.250600580234495, 54.511988482605766],
              [-1.249845656534182, 54.511715088402617],
              [-1.248872544786869, 54.511969677050871],
              [-1.246779990289486, 54.511279036155194],
              [-1.245256689361245, 54.51155083921104],
              [-1.243648736902673, 54.511387116453967],
              [-1.242177076554606, 54.511537877281995],
              [-1.241484774416895, 54.512056589844157],
              [-1.240281927954147, 54.511453194496049],
              [-1.239201724396114, 54.511483253105347],
              [-1.237882481995338, 54.510663410177635],
              [-1.236508319037623, 54.510721249927208],
              [-1.234852661683684, 54.510303671394581],
              [-1.234366938032131, 54.506129662569229],
              [-1.23849950888317, 54.506219557711177],
              [-1.242307558257207, 54.501645644931187],
              [-1.244735905259586, 54.501346281762395],
              [-1.246082597803224, 54.502176108627062],
              [-1.248491529930555, 54.501167462996612],
              [-1.249199671096732, 54.501370476609956],
              [-1.251495465928745, 54.501138457342165],
              [-1.25162978015484, 54.501393624689449],
              [-1.256661476868045, 54.501095721911369],
              [-1.255164086012195, 54.499457140888723],
              [-1.253704669229945, 54.49879208317595],
              [-1.253249261643638, 54.49777012956546],
              [-1.25331552163258, 54.497269055583736],
              [-1.254539849140712, 54.496594482879345],
              [-1.25429883806114, 54.495838975095673],
              [-1.254763678451172, 54.495238802136626],
              [-1.257212347303969, 54.493195806851688],
              [-1.256653315586504, 54.491161274775649],
              [-1.257390019443178, 54.487309409770447],
              [-1.260176898056122, 54.487789329180067],
              [-1.26422549510985, 54.487555197974615],
              [-1.265624023479675, 54.488159546927811],
              [-1.26711683685841, 54.488155121758481],
              [-1.268009882431679, 54.488542484353353],
              [-1.269286278963593, 54.488295866558154],
              [-1.27094183376967, 54.489122790232763],
              [-1.273091258723943, 54.489264244583225],
              [-1.272814118956086, 54.490267341912684],
              [-1.274505757439594, 54.491070167723095],
              [-1.276254852404069, 54.49072116133636],
              [-1.27670799519827, 54.490062418171405],
              [-1.277686343561993, 54.489733943319585],
              [-1.278853612560973, 54.490248682800008],
              [-1.281047574181631, 54.490408235247294],
              [-1.284002125664951, 54.489270914711469],
              [-1.285483231309159, 54.487814769605187],
              [-1.287419557123996, 54.487515238775721],
              [-1.287704997639174, 54.48796717615155],
              [-1.289578879273169, 54.48797460324225],
              [-1.289347873908717, 54.487319880797621],
              [-1.287813330412054, 54.4863519255968],
              [-1.287688475536114, 54.484831463774086],
              [-1.286645017785196, 54.483945471412326],
              [-1.286697163034299, 54.482722627786018],
              [-1.288597870032772, 54.481792869151171],
              [-1.290734705942783, 54.481929443268726],
              [-1.291036158143649, 54.482169370378955],
              [-1.290380045328678, 54.483426419167202],
              [-1.29157767578664, 54.483433440719828],
              [-1.293531450154526, 54.479598370197088],
              [-1.294582513236608, 54.479326802985241],
              [-1.296146780963855, 54.479636088603343],
              [-1.296936415435635, 54.479488799169054],
              [-1.299137818021147, 54.476564576936013],
              [-1.30041941520717, 54.475799102968963],
              [-1.303078129902612, 54.475903427999022],
              [-1.302812235344315, 54.476202964845086],
              [-1.301568010866445, 54.476406982955396],
              [-1.302309768573598, 54.476801307237743],
              [-1.302049393651868, 54.477954653451775],
              [-1.303174298501893, 54.47841768790947],
              [-1.30664146352044, 54.477816605345325],
              [-1.307283710462637, 54.477528203831149],
              [-1.307342116237193, 54.47708547205773],
              [-1.308358345975924, 54.47694839305958],
              [-1.30879223863256, 54.476476352604855],
              [-1.310785780006671, 54.477505076030759],
              [-1.313103149873762, 54.477265722760457],
              [-1.315552481631639, 54.475313223874011],
              [-1.316900149408229, 54.472968908362596],
              [-1.318300377600986, 54.472187738838208],
              [-1.323503894939636, 54.471421599899358],
              [-1.324118678603659, 54.470913669229084],
              [-1.343616573044205, 54.464144121994458],
              [-1.344023151973277, 54.464188568073517],
              [-1.344105721729658, 54.46548945561868],
              [-1.344709869962803, 54.466012188108415],
              [-1.343922830251145, 54.466217320570706],
              [-1.343235238180483, 54.467245311501159],
              [-1.342296821669369, 54.467651821753705],
              [-1.342783055296238, 54.467830613514295],
              [-1.342496105650408, 54.468117540682144],
              [-1.343096594994501, 54.468195397715306],
              [-1.34268691271119, 54.468438520223501],
              [-1.343739604348256, 54.468219559708366],
              [-1.343914357007526, 54.468773217008348],
              [-1.344776023352844, 54.469018744523801],
              [-1.344115152161195, 54.469531921963522],
              [-1.344891412829369, 54.47010231958518],
              [-1.344096591881476, 54.470499735776386],
              [-1.344668851779119, 54.470705049044874],
              [-1.343813163820864, 54.47104821068028],
              [-1.344718314323538, 54.471568980622664],
              [-1.34490260315325, 54.472396795308633],
              [-1.345344994472957, 54.472232929221995],
              [-1.346335071044362, 54.472656186859481],
              [-1.347818661712247, 54.472321787850689],
              [-1.347889436845369, 54.472528873529754],
              [-1.349795255523656, 54.472716182580136],
              [-1.349990190898115, 54.473171080539082],
              [-1.35090463239263, 54.47340516425416],
              [-1.353032876284491, 54.472408213900309],
              [-1.354375109799393, 54.472423476113278],
              [-1.356281597541756, 54.472962981674691],
              [-1.357324425551114, 54.471406566211726],
              [-1.361251935842616, 54.468699783657883],
              [-1.361812166551057, 54.467797737975189],
              [-1.364405227063525, 54.465941174588529],
              [-1.374331551267833, 54.474049896640715],
              [-1.373836613690246, 54.474427489718991],
              [-1.374931016529466, 54.479874845086492],
              [-1.37437236806751, 54.484221713005532],
              [-1.371918450673123, 54.484808433604684],
              [-1.37086418270602, 54.485313421155077],
              [-1.370286739479041, 54.48612734313032],
              [-1.371963869097776, 54.487990101226075],
              [-1.372691400300297, 54.488463901753271],
              [-1.373719401137196, 54.488368573755821],
              [-1.375748945185555, 54.490624040596138],
              [-1.377491896987638, 54.491230664845418],
              [-1.378393877457979, 54.492119632757223],
              [-1.379738836723594, 54.492610042274677],
              [-1.380914056831065, 54.493914696206794],
              [-1.37695809840618, 54.495879630027595],
              [-1.377061307623183, 54.497215641316778],
              [-1.378273322698005, 54.500067184521583],
              [-1.377859450608138, 54.501290893128996],
              [-1.376666872504034, 54.502236484513247],
              [-1.371222177469892, 54.503636352003696],
              [-1.360526332573939, 54.503165980159807],
              [-1.358259647084814, 54.504431939033729],
              [-1.357650473616038, 54.505266301380829],
              [-1.357692975801883, 54.506593917968281],
              [-1.359604320790294, 54.511372589875442],
              [-1.358897446764526, 54.512227110799948],
              [-1.356935628710392, 54.512142106277473],
              [-1.354106909489612, 54.511299328514525],
              [-1.353104930339263, 54.510685550353465],
              [-1.352196173481492, 54.509648073169224],
              [-1.349943788235429, 54.504329122031692],
              [-1.348088944006473, 54.503175084709738],
              [-1.345757410620046, 54.503158902475072],
              [-1.344354982493629, 54.503464958744871],
              [-1.342539907176511, 54.50504132808107],
              [-1.338015822569283, 54.507098963294574],
              [-1.336636849075413, 54.508328930936187],
              [-1.335369411490179, 54.508105385575142],
              [-1.334206423847544, 54.508392868208311],
              [-1.332322440106767, 54.509611019345101],
              [-1.330268906534019, 54.51013979205932],
              [-1.325560776564395, 54.508669410919403],
              [-1.324472826082694, 54.507585788433438],
              [-1.324522052474673, 54.506379995505092],
              [-1.324085816863807, 54.50595606415223],
              [-1.32177548653838, 54.506049180933601],
              [-1.319317449323871, 54.507130901840448],
              [-1.317504287782503, 54.506509610962624],
              [-1.317074072484999, 54.505889721715064],
              [-1.315580139199177, 54.50586784284183],
              [-1.315283297761103, 54.505735508548462],
              [-1.31536410780227, 54.504434031599558],
              [-1.315998359033336, 54.504050540643512],
              [-1.31640114299205, 54.502996567847042],
              [-1.315967102709133, 54.502078328296896],
              [-1.315276594734061, 54.502185861696915],
              [-1.316144990089481, 54.502985234186404],
              [-1.3158996381066, 54.503691732227395],
              [-1.315817298497639, 54.503928824417585],
              [-1.315654935566498, 54.504030918848763],
              [-1.315111875759743, 54.504372390196998],
              [-1.315069052831646, 54.505825361957278],
              [-1.315818131898971, 54.50600574809809],
              [-1.317030701360427, 54.505990132360907],
              [-1.3173037500222, 54.50656415176455],
              [-1.317617084904665, 54.506672134572504],
              [-1.317944947855147, 54.506785122505228],
              [-1.31931074105226, 54.507255784718396],
              [-1.321560567721704, 54.506251084144864],
              [-1.323851115335832, 54.506047319610836],
              [-1.324228530700811, 54.506292080077444],
              [-1.32421582675301, 54.507623896241938],
              [-1.324953872971518, 54.50847819568537],
              [-1.329951652591728, 54.510272841579365],
              [-1.332608091104744, 54.509710554753681],
              [-1.334643849960407, 54.508466272797911],
              [-1.336466777259198, 54.508626369388026],
              [-1.336271099309815, 54.513268021276666],
              [-1.334929571488632, 54.517502552807095],
              [-1.335484166543683, 54.518929151927537],
              [-1.337625475700675, 54.520828177505059],
              [-1.337906348531284, 54.52151542776167],
              [-1.336058375668874, 54.523530985229044],
              [-1.335684082112386, 54.525174461514894],
              [-1.336276203153954, 54.526200438140137],
              [-1.339224414092357, 54.527435218568499],
              [-1.339943053499591, 54.528480290810791],
              [-1.338922671275897, 54.52994994493946],
              [-1.337241663471352, 54.530318205777434],
              [-1.335085895807622, 54.529850735819331],
              [-1.334072808105812, 54.530954167013888],
              [-1.334246242868059, 54.532261838446473],
              [-1.335634841328593, 54.534110916039467],
              [-1.335428707069914, 54.535099257847179],
              [-1.334616979347256, 54.535692435815982],
              [-1.332644911309667, 54.535885582498544],
              [-1.331522442817492, 54.535535183397791],
              [-1.331187559847996, 54.534938390556228],
              [-1.331973118212533, 54.532826279738188],
              [-1.331583049216183, 54.532203120570934],
              [-1.330726507444482, 54.531945850114084],
              [-1.325235440654563, 54.53244738917892],
              [-1.320682032890013, 54.534639019576019],
              [-1.317066148906525, 54.533503373439665],
              [-1.317638016903053, 54.533313377756777],
              [-1.319096046739134, 54.534011796593816],
              [-1.319648840162648, 54.533760572116009],
              [-1.319098424021353, 54.533868915780694],
              [-1.317743165124096, 54.533216910408427],
              [-1.313797074326155, 54.53353880219084],
              [-1.310737252035763, 54.536381094660683],
              [-1.310753611561268, 54.537153176025377],
              [-1.316240894564568, 54.538591677032151],
              [-1.316914267322287, 54.539269508522779],
              [-1.31630113115017, 54.540166548455119],
              [-1.315616555805174, 54.540158185491535],
              [-1.31045706101869, 54.538242515201084],
              [-1.308436255097765, 54.538195934375018],
              [-1.307334614604854, 54.538723465027253],
              [-1.307148218617253, 54.539417097411864],
              [-1.30760643414266, 54.54010543217548],
              [-1.310901154032921, 54.543075584739775],
              [-1.310265933371662, 54.54453775350342],
              [-1.308842585036549, 54.545524499331684],
              [-1.30802255716702, 54.546298990364029],
              [-1.307658975904766, 54.547595538867519],
              [-1.310882473556216, 54.550424182686143],
              [-1.309541277895678, 54.55221125017902],
              [-1.310199860113469, 54.554964138511565],
              [-1.306514782001997, 54.557181750923597],
              [-1.306013281421964, 54.558578157051215],
              [-1.309114158902889, 54.562301245006466],
              [-1.30943466458789, 54.564017797010024],
              [-1.308932401161873, 54.564636832360371],
              [-1.30738254143891, 54.56537928939315],
              [-1.306094767590459, 54.5655300860995],
              [-1.303908786558317, 54.565386325629397],
              [-1.303874364736467, 54.565137187262913],
            ],
          ],
          [
            [
              [-1.223182480739399, 54.625765245461807],
              [-1.22502462220221, 54.625656618806687],
              [-1.224678174906735, 54.626119028935442],
              [-1.223790354664289, 54.626297576573805],
              [-1.222828673594966, 54.625962487156947],
              [-1.223182480739399, 54.625765245461807],
            ],
          ],
          [
            [
              [-1.31798558938843, 54.645262490432671],
              [-1.317164466359973, 54.644789648965144],
              [-1.316349845651759, 54.645223616161289],
              [-1.31503634660712, 54.644082951017602],
              [-1.315561309825845, 54.643918761098789],
              [-1.315222211489741, 54.643442337113278],
              [-1.311484539357735, 54.641892485139188],
              [-1.311227891850097, 54.641217008873525],
              [-1.311939361332198, 54.641201279868675],
              [-1.31187422351208, 54.640929505508836],
              [-1.308245122031965, 54.638572245449524],
              [-1.308405287699396, 54.638264010107143],
              [-1.307001958114754, 54.63696547153836],
              [-1.304345829203214, 54.636684237821889],
              [-1.302796266995649, 54.635748780071403],
              [-1.298169849018626, 54.635498295166897],
              [-1.298796824739139, 54.633453806213367],
              [-1.29857440760478, 54.63053356576966],
              [-1.29901769447865, 54.627995526897301],
              [-1.296184288932306, 54.628015945656273],
              [-1.292401518980359, 54.628741623249702],
              [-1.291032963132551, 54.627005434532094],
              [-1.286257873681728, 54.628230175010067],
              [-1.285970051897543, 54.627682971816498],
              [-1.279181576892844, 54.628175696464602],
              [-1.276151884502454, 54.627297581421061],
              [-1.271902951505675, 54.62531296430511],
              [-1.268938403801567, 54.626351077186385],
              [-1.268502261167045, 54.625624096054601],
              [-1.267598071541292, 54.626035627513055],
              [-1.267430984539751, 54.624558959355276],
              [-1.266780118941227, 54.624147912547492],
              [-1.263890547031493, 54.624290356311931],
              [-1.262114122304027, 54.624885269821597],
              [-1.259828912252937, 54.624580151206139],
              [-1.258701288290702, 54.626458720257538],
              [-1.256190591033009, 54.625604847318201],
              [-1.257619063900953, 54.624050777150828],
              [-1.256675701899955, 54.623378159539612],
              [-1.255594749739892, 54.62337242050171],
              [-1.254018454548825, 54.624114034053676],
              [-1.252444815782108, 54.623947061355722],
              [-1.250768975230502, 54.625297334718887],
              [-1.249414291395971, 54.62518200377999],
              [-1.247770493807649, 54.625730451893553],
              [-1.247425700955735, 54.62584548606565],
              [-1.246657244057958, 54.6259162496344],
              [-1.245725224870194, 54.626002067723284],
              [-1.24220556593257, 54.62528271280658],
              [-1.240564831283656, 54.624643349929855],
              [-1.240145579949815, 54.625448649721562],
              [-1.238587726697599, 54.626427435000785],
              [-1.237184192672104, 54.626439275237509],
              [-1.238740613666246, 54.627945396498696],
              [-1.234108915651754, 54.628785242786762],
              [-1.233389165254233, 54.628344822133869],
              [-1.233551834215097, 54.627601730039373],
              [-1.232179225268848, 54.625557487921625],
              [-1.231443071282836, 54.625580679338185],
              [-1.23042590767172, 54.626131414436259],
              [-1.230291223606785, 54.625142889779369],
              [-1.229257006945176, 54.624956574402141],
              [-1.22860007838262, 54.626196190950729],
              [-1.226889742803373, 54.626704735191439],
              [-1.226476035304534, 54.626385752540536],
              [-1.22668739934087, 54.625367978353204],
              [-1.225409778514379, 54.625194455198098],
              [-1.22393898316074, 54.623615015345678],
              [-1.222395048244305, 54.623197105009474],
              [-1.223072973592865, 54.623545656530467],
              [-1.221675353314106, 54.624632198293199],
              [-1.22092963074417, 54.624751425041481],
              [-1.220733974701162, 54.623723852591219],
              [-1.219942596537185, 54.622991711642385],
              [-1.218802921046221, 54.622737224997991],
              [-1.215762412844997, 54.622831713355531],
              [-1.215199586396784, 54.622300531704425],
              [-1.215783581051734, 54.62124744332197],
              [-1.217493893004231, 54.620735460836897],
              [-1.212723455637403, 54.621481026512512],
              [-1.206744001254584, 54.621551657857943],
              [-1.204917997121334, 54.622269430698552],
              [-1.201417942505652, 54.622420754577874],
              [-1.200773701671721, 54.62273464557213],
              [-1.200876673348898, 54.621747653129965],
              [-1.173545044488843, 54.621493259692237],
              [-1.172649890408041, 54.621721710208647],
              [-1.17259390027988, 54.622267738922893],
              [-1.169019609322256, 54.626208346673465],
              [-1.169098126426108, 54.626687893522252],
              [-1.170958028874697, 54.627238959150766],
              [-1.170387479686329, 54.628255079721441],
              [-1.169778843201871, 54.628097231974394],
              [-1.168826517785302, 54.62876652533263],
              [-1.170716454760524, 54.629216246961079],
              [-1.170851719157309, 54.629724939255517],
              [-1.167115341625737, 54.62912234149973],
              [-1.164832059076317, 54.628216007711849],
              [-1.165606188512191, 54.627628198859796],
              [-1.166527172558542, 54.627584211736249],
              [-1.167437956809882, 54.6269775599525],
              [-1.16843162184367, 54.626786670749112],
              [-1.168640238022265, 54.625671015779027],
              [-1.166862494560922, 54.6271686218846],
              [-1.165417292689818, 54.627548710507291],
              [-1.167562270550254, 54.626199239421425],
              [-1.171799904258001, 54.622623591195868],
              [-1.172151744803202, 54.621465771262834],
              [-1.167225999184649, 54.619759519852202],
              [-1.166003389145505, 54.620021617642401],
              [-1.162030581945645, 54.62260672932495],
              [-1.160070447274335, 54.618741309379594],
              [-1.158814990857755, 54.617816817470207],
              [-1.15925562436559, 54.612892279875396],
              [-1.160524576207254, 54.61036125980975],
              [-1.165667201032631, 54.610165926985538],
              [-1.167381376391266, 54.607670320954156],
              [-1.167418808855692, 54.60477315256076],
              [-1.181447674796204, 54.595586062045044],
              [-1.183774600727539, 54.594159346551706],
              [-1.184200790495565, 54.594208052605232],
              [-1.183973825021154, 54.594025883424834],
              [-1.185221272503931, 54.593249713458775],
              [-1.186769839011716, 54.593437168576337],
              [-1.193272955874399, 54.589137224908427],
              [-1.197091711793635, 54.585698096598435],
              [-1.201091730169109, 54.583558674643918],
              [-1.204441322755612, 54.58262001380313],
              [-1.209129919171212, 54.582038712493244],
              [-1.213032823595732, 54.582238492823919],
              [-1.223633499870797, 54.584895200798726],
              [-1.230489637555488, 54.585639957727281],
              [-1.236377968103939, 54.587510591578805],
              [-1.236188989491275, 54.587851805480874],
              [-1.23810375475262, 54.588567553221075],
              [-1.240120197525525, 54.589976808118905],
              [-1.240441240542926, 54.589855700158473],
              [-1.242933202341278, 54.590916496800332],
              [-1.245374345074392, 54.593812972338782],
              [-1.246324429731759, 54.593655325653089],
              [-1.244995533124357, 54.591616880218282],
              [-1.246522228683662, 54.592413652331018],
              [-1.246705703865821, 54.591863889721672],
              [-1.248063257142146, 54.592687449652615],
              [-1.248716010754453, 54.592369766128286],
              [-1.248471203854887, 54.591964730036608],
              [-1.249650315195358, 54.592731444960194],
              [-1.249748046592233, 54.592213499797559],
              [-1.250601405597769, 54.592769687926236],
              [-1.250785581203283, 54.592347539258057],
              [-1.252504293446896, 54.592385122064663],
              [-1.252655853016504, 54.591968161453941],
              [-1.256943281416347, 54.590405642867957],
              [-1.258566973454155, 54.588853652909982],
              [-1.259992551434327, 54.583453986938522],
              [-1.261634925732087, 54.573912601838245],
              [-1.262214387376013, 54.572919472480322],
              [-1.264834879673304, 54.575322369365239],
              [-1.264206527970888, 54.576111208127088],
              [-1.267920542723461, 54.578201637409997],
              [-1.270256172503904, 54.578746870560785],
              [-1.271369621843793, 54.579553421273687],
              [-1.274798803322569, 54.580731537302903],
              [-1.279264724007558, 54.58296721124082],
              [-1.280149141395292, 54.583100518551596],
              [-1.281195221298813, 54.583258183175921],
              [-1.28317936417022, 54.584637768073044],
              [-1.282283119644152, 54.583776896031722],
              [-1.28331244099831, 54.583490915357423],
              [-1.284956936872342, 54.583859219545523],
              [-1.285774859448678, 54.583627688806708],
              [-1.286975208515914, 54.584090404403739],
              [-1.287247055754401, 54.584827141656298],
              [-1.288140297188446, 54.584979783203515],
              [-1.288386709518095, 54.585312851916861],
              [-1.294769138509864, 54.586588601111849],
              [-1.296329293980452, 54.586656093155639],
              [-1.297972083042791, 54.587948072270407],
              [-1.297664298854245, 54.589468683310763],
              [-1.298814758337637, 54.590241041801384],
              [-1.297806853305558, 54.589367957095277],
              [-1.298127522295876, 54.587907633199478],
              [-1.296405093985039, 54.586567562403879],
              [-1.294347928255048, 54.586427976076791],
              [-1.288496249129848, 54.585242498338978],
              [-1.288356215337603, 54.584931623911658],
              [-1.287404228888171, 54.584775942145242],
              [-1.287130565402292, 54.58405447245709],
              [-1.285834192471853, 54.583508511633674],
              [-1.284836381337388, 54.58376324530802],
              [-1.283159353776447, 54.583397442968632],
              [-1.282063160017447, 54.583702797260379],
              [-1.281172586584484, 54.583137622905376],
              [-1.280008587665544, 54.583033657092606],
              [-1.273665076716614, 54.580015665816681],
              [-1.271443538408574, 54.579399289359685],
              [-1.270308980511343, 54.578562056210018],
              [-1.267908485053503, 54.577838488757195],
              [-1.26474321298101, 54.576118063450494],
              [-1.26523909972382, 54.575210687991216],
              [-1.262205994183185, 54.572013528174104],
              [-1.263933372245786, 54.570063071759691],
              [-1.266853514663203, 54.568692294409786],
              [-1.270536801398578, 54.567707996932704],
              [-1.278000428874819, 54.56695649002792],
              [-1.28391545492699, 54.565242747579177],
              [-1.285590931197568, 54.565301177085317],
              [-1.286280217320892, 54.564886447368842],
              [-1.288517383392514, 54.564572488914273],
              [-1.297711803913643, 54.564744807856485],
              [-1.304772581667202, 54.56624865100904],
              [-1.30817671665698, 54.565881711311718],
              [-1.310226731645007, 54.564461777957092],
              [-1.310640277947445, 54.563049577142984],
              [-1.309915498025014, 54.561186033123356],
              [-1.307649810017859, 54.559004526637821],
              [-1.307673785070483, 54.557679975988698],
              [-1.310400576687645, 54.556081471266815],
              [-1.311277065535696, 54.554626069355677],
              [-1.310412387277808, 54.552813854071317],
              [-1.311636802861129, 54.550982975273037],
              [-1.311733993964489, 54.550076736057775],
              [-1.308997585115015, 54.547879091048166],
              [-1.308766370255123, 54.54701591338204],
              [-1.311458534304805, 54.544398954222537],
              [-1.311915052607066, 54.542806349918671],
              [-1.308753801031083, 54.540166812273569],
              [-1.308100409134917, 54.538954319592591],
              [-1.309251857694081, 54.538680501261403],
              [-1.310325188764029, 54.538822327452777],
              [-1.315929122402017, 54.540691985959072],
              [-1.316750041520425, 54.540681345462424],
              [-1.317369604804963, 54.540325359878501],
              [-1.317924788993133, 54.53947292324456],
              [-1.317500849519417, 54.538387593657447],
              [-1.315669716174684, 54.537534267275369],
              [-1.311853540926384, 54.536538430163787],
              [-1.313780263597958, 54.53426486124922],
              [-1.314481060914687, 54.534036967149085],
              [-1.316353010212583, 54.534008912833265],
              [-1.319776339081745, 54.535108448395832],
              [-1.32086869998274, 54.53512446949884],
              [-1.32182104234126, 54.534916817216882],
              [-1.325220592392347, 54.532973049044514],
              [-1.330487056827423, 54.53241364962448],
              [-1.331121470250042, 54.532835954563524],
              [-1.330267332877165, 54.535169660519131],
              [-1.331191467427317, 54.536023148652681],
              [-1.332237227179754, 54.536434239415456],
              [-1.333645767893515, 54.536492330980472],
              [-1.335729057205572, 54.535827958744598],
              [-1.336269045369769, 54.535218005271354],
              [-1.336360895719106, 54.533937855148501],
              [-1.335135816317966, 54.531914436988828],
              [-1.335189814197739, 54.531063660032721],
              [-1.33595376101886, 54.530456735266256],
              [-1.337690930769675, 54.530725082837399],
              [-1.338815806825155, 54.530538911529071],
              [-1.339926489867664, 54.529890718036114],
              [-1.340698880863406, 54.528849734965512],
              [-1.339908569152977, 54.526881755369352],
              [-1.339569745620716, 54.527004826592623],
              [-1.337407278096209, 54.526196751360736],
              [-1.336585914185629, 54.52539509883583],
              [-1.336513960896505, 54.524400735600899],
              [-1.338726560929459, 54.521332982378063],
              [-1.336251221194239, 54.518783278371927],
              [-1.335725525441194, 54.517573429868762],
              [-1.337078931170445, 54.513172693958396],
              [-1.337210163045868, 54.508881184082597],
              [-1.338544089155814, 54.50738045250268],
              [-1.342540014730768, 54.505612008512777],
              [-1.344741940728748, 54.50379508405193],
              [-1.347635760649992, 54.503511454672932],
              [-1.348807750464835, 54.504003071831683],
              [-1.349489982561202, 54.504802099161786],
              [-1.351575610603887, 54.509725632281295],
              [-1.352815932663748, 54.511087524138816],
              [-1.355702815009563, 54.512255973814696],
              [-1.357644306692348, 54.51255388384633],
              [-1.359383720489759, 54.512342923666985],
              [-1.36000450571865, 54.511857312916391],
              [-1.359727308772251, 54.510026079586197],
              [-1.360617487260063, 54.509594914755837],
              [-1.36114036729922, 54.509837631782943],
              [-1.360632873185178, 54.509499733154982],
              [-1.359595843441911, 54.509840250152521],
              [-1.358282444586169, 54.506427190612023],
              [-1.358329007092895, 54.505130602760225],
              [-1.359755862975631, 54.503851213607092],
              [-1.361813200465765, 54.503429806403076],
              [-1.368771446354566, 54.504037892828343],
              [-1.37218182834967, 54.503894771598539],
              [-1.376639021246291, 54.502646151346369],
              [-1.378122341312068, 54.501570844056623],
              [-1.378753876472928, 54.500452502684723],
              [-1.378703343949842, 54.499406148018068],
              [-1.377449518111243, 54.496053813432503],
              [-1.382251890527316, 54.493244809068443],
              [-1.383278796362591, 54.491279182565812],
              [-1.382368195476136, 54.488395074262677],
              [-1.383076967130769, 54.487456844830831],
              [-1.384642894853852, 54.487000189643481],
              [-1.389473059909362, 54.486884469511111],
              [-1.393682047727538, 54.485975479426884],
              [-1.395651623084329, 54.485872998489924],
              [-1.398013182814608, 54.487457514790741],
              [-1.398310162867739, 54.488363092668678],
              [-1.397683618556601, 54.491082159649643],
              [-1.398783574178348, 54.492817643527673],
              [-1.402301883714336, 54.494412310453299],
              [-1.405403040706036, 54.494236169849422],
              [-1.407476431376319, 54.493492329337961],
              [-1.408597228761848, 54.492639549627512],
              [-1.410384974047342, 54.48984970444657],
              [-1.408851103029329, 54.486490039421476],
              [-1.408341080614466, 54.482897207827214],
              [-1.408020075959139, 54.482894738336945],
              [-1.409455142038821, 54.47884678199928],
              [-1.411303955568465, 54.477561655243484],
              [-1.412458446083205, 54.477229355101215],
              [-1.417097751194899, 54.476991179628989],
              [-1.4231285577698, 54.478868739578246],
              [-1.431073422189802, 54.479209239157072],
              [-1.432952149234409, 54.479954103679177],
              [-1.433193321598768, 54.480929432770282],
              [-1.42798976639824, 54.482978251719949],
              [-1.426821143059601, 54.483987808728074],
              [-1.426572531947039, 54.484768055960338],
              [-1.426975211021052, 54.485222912655978],
              [-1.432240986930214, 54.486507732127954],
              [-1.434835107666399, 54.487451839569026],
              [-1.433084911296819, 54.489329134169566],
              [-1.436311844263738, 54.490969973949106],
              [-1.434794364566883, 54.49219861271078],
              [-1.435346858937601, 54.492772769615634],
              [-1.433219329697682, 54.493563565914513],
              [-1.433569696236932, 54.494128695222507],
              [-1.429345529147882, 54.49524480452164],
              [-1.425163163122398, 54.50051927284963],
              [-1.425510077618481, 54.505952688928787],
              [-1.428703373575571, 54.513067565024592],
              [-1.423455252282121, 54.514024952531024],
              [-1.423111901503058, 54.515262626829482],
              [-1.421932630121016, 54.516186256789439],
              [-1.421245211449018, 54.517717954251381],
              [-1.421474687494018, 54.519626097541035],
              [-1.422568844452086, 54.520911974086076],
              [-1.424880964292908, 54.522273734009971],
              [-1.426383886577718, 54.522185605579608],
              [-1.429903641576735, 54.52371205745245],
              [-1.431410542154697, 54.52367421247321],
              [-1.434615874701601, 54.527181581221399],
              [-1.43515006355123, 54.527100496729254],
              [-1.435552598368067, 54.527490613428633],
              [-1.436402679927612, 54.527584445541081],
              [-1.437996761880004, 54.527171267625242],
              [-1.438783984656737, 54.527679990429988],
              [-1.442253289168872, 54.527760736450894],
              [-1.444686029305461, 54.527027801072684],
              [-1.445563026949228, 54.528215409659374],
              [-1.446319802883438, 54.530640869015564],
              [-1.449631148119173, 54.532950351562718],
              [-1.449010698617372, 54.534040345778884],
              [-1.445930819103596, 54.533691968795324],
              [-1.443916537866677, 54.53453649395221],
              [-1.444802883185027, 54.535273002602814],
              [-1.445581222232117, 54.535428450727707],
              [-1.446977181215891, 54.537226841798791],
              [-1.445366877891325, 54.538814662015476],
              [-1.445095640816732, 54.54012461887924],
              [-1.44425048588142, 54.540671642802693],
              [-1.443827904726403, 54.542423060683262],
              [-1.442576860983288, 54.543032014595447],
              [-1.443714537898127, 54.545645267944252],
              [-1.443715520929911, 54.54750557493778],
              [-1.445660800262949, 54.553968036277823],
              [-1.446744987864037, 54.555657151586587],
              [-1.439335048087996, 54.557499535670374],
              [-1.435514608526379, 54.559262097157294],
              [-1.436069506350719, 54.559952185211671],
              [-1.435775608307943, 54.560182679176457],
              [-1.427535419437382, 54.562689998025242],
              [-1.422365583891062, 54.563344855141217],
              [-1.416145374424431, 54.563358154065547],
              [-1.411298282477008, 54.564886728638605],
              [-1.41462385882534, 54.56787753595296],
              [-1.41426799984154, 54.568317972861976],
              [-1.414681146656631, 54.569061394358442],
              [-1.416223623353887, 54.570441148595116],
              [-1.414057078261362, 54.5711631191027],
              [-1.41638184248909, 54.573226066934502],
              [-1.416902099410383, 54.574274653588859],
              [-1.416772499802969, 54.575659815564677],
              [-1.415960282456323, 54.576638172954468],
              [-1.416142234374772, 54.577979002768956],
              [-1.41489477474462, 54.580933277460488],
              [-1.414500754113824, 54.583596896508908],
              [-1.413147364783712, 54.586364609590809],
              [-1.413078217047969, 54.588161659254993],
              [-1.41120853852621, 54.588724151543218],
              [-1.408953901858623, 54.59000638668784],
              [-1.407608155940738, 54.592313044684929],
              [-1.407345842870224, 54.593752364306624],
              [-1.408100536319831, 54.594324926956673],
              [-1.410053539493477, 54.594443197894144],
              [-1.410314718290792, 54.594147901676301],
              [-1.411279525409433, 54.594427595131826],
              [-1.411466987735379, 54.595058488907853],
              [-1.41433014929567, 54.596251451283507],
              [-1.414791948886615, 54.597399514443275],
              [-1.416300578162561, 54.598081711273906],
              [-1.417097053936025, 54.599428190759738],
              [-1.418767099522775, 54.599870284117422],
              [-1.419445917319673, 54.599593148453188],
              [-1.421226257344678, 54.600446437771829],
              [-1.421937927265978, 54.60047230368815],
              [-1.42216674679047, 54.601585074936459],
              [-1.42179483755463, 54.602050618775095],
              [-1.423301418350026, 54.603117356499745],
              [-1.425063417893814, 54.602541584870195],
              [-1.426050226827978, 54.602708927765313],
              [-1.425975846103739, 54.602384148388722],
              [-1.427204111222101, 54.602112268132977],
              [-1.427376095587707, 54.601769781959483],
              [-1.427988755333256, 54.601793346728378],
              [-1.427953258266974, 54.601454373144918],
              [-1.429587256435208, 54.600502278991961],
              [-1.429803653340405, 54.598307798915066],
              [-1.429249924909706, 54.597393919143528],
              [-1.429651741048384, 54.596551043329235],
              [-1.431467882864491, 54.595283442232727],
              [-1.432114380632142, 54.595426671005292],
              [-1.43359222021743, 54.595108265604587],
              [-1.435794707902473, 54.595660456059008],
              [-1.437810017485979, 54.595198015381769],
              [-1.437988718537757, 54.594921149560761],
              [-1.43834421066684, 54.595068386438442],
              [-1.438589152377189, 54.595489210278025],
              [-1.439574003595704, 54.59567800287428],
              [-1.439748233957279, 54.596628723976451],
              [-1.440282886757928, 54.596692306872399],
              [-1.440577946574699, 54.597185253270482],
              [-1.442180794112438, 54.597459556788635],
              [-1.4431742489157, 54.598497620851099],
              [-1.445155281311234, 54.599536609779328],
              [-1.444119724768041, 54.600448524217946],
              [-1.445483786471692, 54.602017596409702],
              [-1.446510096015358, 54.602245165341387],
              [-1.447252778259494, 54.603070856679331],
              [-1.4486639053217, 54.603233659770929],
              [-1.44887775228855, 54.6036758888511],
              [-1.450018583575711, 54.604025271740156],
              [-1.45077440543708, 54.604687439465238],
              [-1.451321710567296, 54.606237460145188],
              [-1.452629905381125, 54.607043210603905],
              [-1.452482269751904, 54.607546707023623],
              [-1.45194039800321, 54.607662883301465],
              [-1.452207519629364, 54.608754199277271],
              [-1.450934352645445, 54.609829555800225],
              [-1.450902074708944, 54.610502526109954],
              [-1.449769154245113, 54.610356291563527],
              [-1.448509819275807, 54.610972456313455],
              [-1.447610886576838, 54.611040255468701],
              [-1.447034399366682, 54.610735666420126],
              [-1.444478728769508, 54.610466948758855],
              [-1.44443872979851, 54.609993157068416],
              [-1.443415451834888, 54.610324568814619],
              [-1.442878179071012, 54.610099224519232],
              [-1.441869949055946, 54.610800951671187],
              [-1.43981521361424, 54.611150043466054],
              [-1.439300247054738, 54.611555665074803],
              [-1.43927371903022, 54.612472203411016],
              [-1.437089241481022, 54.613014764944388],
              [-1.436641379377065, 54.614163901392764],
              [-1.435726595584359, 54.614363645789794],
              [-1.435040562762057, 54.613921888923777],
              [-1.432578407835202, 54.613711771268868],
              [-1.431449441877765, 54.614724690784399],
              [-1.429958231974423, 54.615282957303137],
              [-1.428370478409352, 54.614992389047423],
              [-1.427948300395784, 54.614400857139294],
              [-1.428382034669644, 54.613172601130643],
              [-1.42669088574797, 54.612423191749649],
              [-1.426115665988393, 54.611487629820303],
              [-1.417954478138153, 54.614308317191863],
              [-1.416936246421828, 54.615022371048319],
              [-1.412810408647174, 54.616580538973359],
              [-1.409813432097846, 54.618441537216363],
              [-1.408072309556282, 54.620077641264224],
              [-1.406438652286529, 54.620811065586551],
              [-1.406609515574472, 54.621414922075417],
              [-1.40817529479232, 54.622052561183295],
              [-1.406633956379563, 54.622609397598367],
              [-1.404767053906615, 54.624034544797254],
              [-1.402862390065631, 54.622422811985679],
              [-1.399145219131749, 54.624118230911485],
              [-1.401585944587626, 54.626348271625268],
              [-1.400422946766127, 54.627703134124843],
              [-1.399115630980772, 54.626967822153532],
              [-1.396735069845542, 54.62832109813629],
              [-1.394222222812707, 54.628871138413025],
              [-1.395496729815783, 54.631086476786606],
              [-1.393368204260352, 54.631509904173484],
              [-1.393106469605633, 54.632026237088354],
              [-1.389495007685317, 54.631334977122947],
              [-1.388303313314509, 54.631571612125363],
              [-1.388564601849614, 54.632122028815246],
              [-1.387184519378972, 54.63272885979228],
              [-1.388511051088868, 54.63352371025335],
              [-1.388956723914931, 54.634900050678581],
              [-1.390789173998235, 54.63657723985439],
              [-1.390088463432737, 54.637745601695485],
              [-1.390543701719418, 54.638074115330426],
              [-1.39041688461006, 54.639198632305316],
              [-1.387690197609479, 54.640112328510483],
              [-1.387674304522126, 54.641376699199213],
              [-1.38695521756574, 54.64200394078825],
              [-1.384513523267448, 54.6431131232818],
              [-1.380884877436341, 54.64390615171294],
              [-1.379389963930747, 54.643268526039797],
              [-1.37435710695105, 54.643771073358373],
              [-1.368001324085792, 54.643539482055779],
              [-1.365172813202398, 54.642383364767909],
              [-1.361918873002737, 54.640402642415324],
              [-1.356813115147779, 54.638365283765879],
              [-1.354665330289048, 54.638095940388354],
              [-1.34988215896957, 54.638798308603079],
              [-1.342807492259478, 54.636699450044219],
              [-1.33965202583211, 54.636194305597414],
              [-1.337494724135718, 54.63490369384661],
              [-1.33504217965347, 54.63130178943431],
              [-1.33195621455642, 54.633677126560869],
              [-1.329494350652631, 54.636516868349609],
              [-1.329039268449255, 54.638468999277322],
              [-1.329496656560166, 54.643064731791824],
              [-1.32864009699121, 54.644173464540899],
              [-1.327898752069116, 54.644494680252215],
              [-1.32665773031574, 54.644388934771868],
              [-1.326146716730865, 54.645117626818092],
              [-1.322014154239307, 54.644543678976589],
              [-1.320695865156802, 54.644983843046148],
              [-1.31798558938843, 54.645262490432671],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000005",
        LAD13CDO: "00EH",
        LAD13NM: "Darlington",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.637990666809223, 54.617194817277564],
            [-1.637662178744937, 54.616694170308918],
            [-1.633229336814128, 54.61611826159416],
            [-1.631490347660665, 54.61612287108241],
            [-1.631385734870987, 54.618447434367923],
            [-1.623850402581996, 54.618613000758089],
            [-1.623875476737523, 54.618916831957002],
            [-1.618494966552467, 54.619157932675328],
            [-1.616818151838727, 54.618794069068457],
            [-1.614035272274259, 54.618801413339241],
            [-1.613676333066963, 54.617948321823278],
            [-1.612115328876116, 54.618117678777097],
            [-1.611347620111286, 54.617102407405433],
            [-1.610306949642292, 54.617433373757486],
            [-1.606753672380251, 54.617530634461936],
            [-1.606550047403777, 54.614718903677513],
            [-1.605766232047583, 54.614590540802702],
            [-1.605240410041748, 54.612442780920993],
            [-1.606934065827749, 54.612467159371747],
            [-1.60565181237208, 54.606720423838368],
            [-1.608428616358172, 54.60532389302292],
            [-1.607717432485288, 54.60426024984158],
            [-1.606208361627179, 54.603430364632978],
            [-1.605224891029933, 54.604761705879781],
            [-1.603955220680959, 54.605587044877502],
            [-1.600478729038013, 54.606642365232624],
            [-1.597567186743071, 54.608006812687954],
            [-1.586518243630836, 54.610412224243454],
            [-1.584182801153436, 54.610476125913337],
            [-1.58393135293336, 54.607347851077797],
            [-1.59064337219915, 54.605572453918825],
            [-1.592996987902705, 54.604432767072886],
            [-1.591355996352334, 54.599644456165898],
            [-1.589865971542956, 54.597738706665012],
            [-1.59035830775276, 54.594947265391077],
            [-1.593399859332797, 54.595067152104072],
            [-1.590851556065054, 54.590203882648012],
            [-1.582855413974995, 54.589464851085431],
            [-1.584248090106299, 54.588405596918498],
            [-1.585153850776311, 54.588202903742655],
            [-1.585332958389054, 54.585040146713254],
            [-1.584357621220841, 54.582050472525914],
            [-1.583306447164417, 54.580898340353116],
            [-1.582580555830506, 54.580925497352283],
            [-1.581496755388831, 54.581909412703538],
            [-1.578850479727703, 54.582115924287827],
            [-1.577021715327112, 54.581366338166021],
            [-1.57625725350908, 54.580188184675457],
            [-1.57358441628823, 54.580427739486673],
            [-1.572489477519306, 54.580830085518706],
            [-1.571447821320757, 54.580723055179362],
            [-1.569799440481804, 54.581227664879627],
            [-1.566429890821011, 54.580627908091124],
            [-1.564670489796781, 54.580801333962775],
            [-1.561825834719704, 54.581759859378131],
            [-1.56130104149423, 54.582219884342962],
            [-1.555636103625001, 54.581708548855595],
            [-1.552814955955344, 54.582331738980088],
            [-1.55217253866359, 54.582784999342444],
            [-1.552640752918089, 54.583687213637425],
            [-1.553540154040397, 54.58336341061564],
            [-1.553887099798969, 54.583613625226228],
            [-1.553908746934286, 54.583331517420824],
            [-1.554472725150006, 54.583259901127647],
            [-1.556013059420219, 54.584180420354357],
            [-1.556582356092066, 54.584617470109983],
            [-1.556870323471812, 54.585885671396589],
            [-1.557995377017391, 54.585444034206837],
            [-1.560366414999289, 54.586256987792638],
            [-1.562126384149684, 54.585765491491351],
            [-1.562547264881677, 54.587065613145263],
            [-1.562121261671585, 54.587398384791733],
            [-1.562236228035434, 54.588221996253829],
            [-1.561721030821345, 54.588501421612044],
            [-1.561673339798472, 54.58962909907622],
            [-1.560809101126106, 54.590273920439557],
            [-1.558235850860425, 54.590822646012271],
            [-1.558295521641556, 54.591458234010368],
            [-1.558814712194936, 54.591531122351427],
            [-1.558635168421176, 54.592106525334131],
            [-1.556524625205659, 54.591503886853879],
            [-1.552495395510437, 54.591307533001505],
            [-1.548249009945298, 54.591719543976936],
            [-1.541879720190784, 54.593056230913731],
            [-1.537511976876436, 54.594455960692429],
            [-1.526393321076417, 54.59647547997146],
            [-1.521942268482195, 54.596056281860783],
            [-1.521760089388553, 54.596546245177862],
            [-1.509939093094658, 54.59481036340749],
            [-1.506457160197363, 54.596061576150333],
            [-1.501486827424502, 54.595600864812241],
            [-1.497307215499558, 54.595664471997004],
            [-1.492432229114164, 54.597257284414688],
            [-1.488161407226882, 54.599273040913253],
            [-1.484676787537503, 54.599672560526464],
            [-1.483401121166278, 54.599200707917632],
            [-1.480728832559354, 54.599377106673664],
            [-1.472117236931648, 54.598964230202618],
            [-1.470382973102835, 54.60044848096927],
            [-1.4689019551428, 54.600542649175502],
            [-1.463581005906819, 54.59942549806744],
            [-1.46023656467147, 54.596812532027862],
            [-1.45915789810686, 54.597270544767348],
            [-1.459037452027198, 54.598417632082779],
            [-1.45606900941345, 54.599209562540054],
            [-1.454131919302774, 54.59440095055826],
            [-1.453301038680167, 54.594037727138058],
            [-1.451694479930651, 54.590816750303915],
            [-1.446847848781184, 54.591140706277294],
            [-1.44492069392831, 54.590832624944973],
            [-1.44433744652149, 54.590478562397529],
            [-1.443390082070403, 54.590612610297413],
            [-1.442393577080983, 54.59275859195705],
            [-1.439624847488798, 54.5935519377392],
            [-1.439353853182892, 54.593788836427741],
            [-1.439991561685936, 54.594460412178414],
            [-1.43834421066684, 54.595068386438442],
            [-1.437988718537757, 54.594921149560761],
            [-1.437810017485979, 54.595198015381769],
            [-1.435794707902473, 54.595660456059008],
            [-1.43359222021743, 54.595108265604587],
            [-1.432114380632142, 54.595426671005292],
            [-1.431467882864491, 54.595283442232727],
            [-1.429651741048384, 54.596551043329235],
            [-1.429249924909706, 54.597393919143528],
            [-1.429803653340405, 54.598307798915066],
            [-1.429587256435208, 54.600502278991961],
            [-1.427953258266974, 54.601454373144918],
            [-1.427988755333256, 54.601793346728378],
            [-1.427376095587707, 54.601769781959483],
            [-1.427204111222101, 54.602112268132977],
            [-1.425975846103739, 54.602384148388722],
            [-1.426050226827978, 54.602708927765313],
            [-1.425063417893814, 54.602541584870195],
            [-1.423301418350026, 54.603117356499745],
            [-1.42179483755463, 54.602050618775095],
            [-1.42216674679047, 54.601585074936459],
            [-1.421937927265978, 54.60047230368815],
            [-1.421226257344678, 54.600446437771829],
            [-1.419445917319673, 54.599593148453188],
            [-1.418767099522775, 54.599870284117422],
            [-1.417097053936025, 54.599428190759738],
            [-1.416300578162561, 54.598081711273906],
            [-1.414791948886615, 54.597399514443275],
            [-1.41433014929567, 54.596251451283507],
            [-1.411466987735379, 54.595058488907853],
            [-1.411279525409433, 54.594427595131826],
            [-1.410314718290792, 54.594147901676301],
            [-1.410053539493477, 54.594443197894144],
            [-1.408100536319831, 54.594324926956673],
            [-1.407345842870224, 54.593752364306624],
            [-1.407608155940738, 54.592313044684929],
            [-1.408953901858623, 54.59000638668784],
            [-1.41120853852621, 54.588724151543218],
            [-1.413078217047969, 54.588161659254993],
            [-1.413147364783712, 54.586364609590809],
            [-1.414500754113824, 54.583596896508908],
            [-1.41489477474462, 54.580933277460488],
            [-1.416142234374772, 54.577979002768956],
            [-1.415960282456323, 54.576638172954468],
            [-1.416772499802969, 54.575659815564677],
            [-1.416902099410383, 54.574274653588859],
            [-1.41638184248909, 54.573226066934502],
            [-1.414057078261362, 54.5711631191027],
            [-1.416223623353887, 54.570441148595116],
            [-1.414681146656631, 54.569061394358442],
            [-1.41426799984154, 54.568317972861976],
            [-1.41462385882534, 54.56787753595296],
            [-1.411298282477008, 54.564886728638605],
            [-1.416145374424431, 54.563358154065547],
            [-1.422365583891062, 54.563344855141217],
            [-1.427535419437382, 54.562689998025242],
            [-1.435775608307943, 54.560182679176457],
            [-1.436069506350719, 54.559952185211671],
            [-1.435514608526379, 54.559262097157294],
            [-1.439335048087996, 54.557499535670374],
            [-1.446744987864037, 54.555657151586587],
            [-1.445660800262949, 54.553968036277823],
            [-1.443715520929911, 54.54750557493778],
            [-1.443714537898127, 54.545645267944252],
            [-1.442576860983288, 54.543032014595447],
            [-1.443827904726403, 54.542423060683262],
            [-1.44425048588142, 54.540671642802693],
            [-1.445095640816732, 54.54012461887924],
            [-1.445366877891325, 54.538814662015476],
            [-1.446977181215891, 54.537226841798791],
            [-1.445581222232117, 54.535428450727707],
            [-1.444802883185027, 54.535273002602814],
            [-1.443916537866677, 54.53453649395221],
            [-1.445930819103596, 54.533691968795324],
            [-1.449010698617372, 54.534040345778884],
            [-1.449631148119173, 54.532950351562718],
            [-1.446319802883438, 54.530640869015564],
            [-1.445563026949228, 54.528215409659374],
            [-1.444686029305461, 54.527027801072684],
            [-1.442253289168872, 54.527760736450894],
            [-1.438783984656737, 54.527679990429988],
            [-1.437996761880004, 54.527171267625242],
            [-1.436402679927612, 54.527584445541081],
            [-1.435552598368067, 54.527490613428633],
            [-1.43515006355123, 54.527100496729254],
            [-1.434615874701601, 54.527181581221399],
            [-1.431410542154697, 54.52367421247321],
            [-1.429903641576735, 54.52371205745245],
            [-1.426383886577718, 54.522185605579608],
            [-1.424880964292908, 54.522273734009971],
            [-1.422568844452086, 54.520911974086076],
            [-1.421474687494018, 54.519626097541035],
            [-1.421245211449018, 54.517717954251381],
            [-1.421932630121016, 54.516186256789439],
            [-1.423111901503058, 54.515262626829482],
            [-1.423455252282121, 54.514024952531024],
            [-1.428703373575571, 54.513067565024592],
            [-1.425510077618481, 54.505952688928787],
            [-1.425163163122398, 54.50051927284963],
            [-1.429345529147882, 54.49524480452164],
            [-1.433569696236932, 54.494128695222507],
            [-1.433219329697682, 54.493563565914513],
            [-1.435346858937601, 54.492772769615634],
            [-1.434794364566883, 54.49219861271078],
            [-1.436311844263738, 54.490969973949106],
            [-1.433084911296819, 54.489329134169566],
            [-1.434835107666399, 54.487451839569026],
            [-1.442881467005536, 54.490903350780805],
            [-1.446500496410422, 54.494414129092306],
            [-1.451888051095399, 54.497347765555205],
            [-1.456494094852505, 54.502798511872086],
            [-1.458107321974683, 54.503768259097981],
            [-1.459974032632658, 54.504258310489959],
            [-1.46210389566136, 54.504233648151242],
            [-1.467370780956791, 54.502497291833713],
            [-1.473155063184713, 54.501437886211129],
            [-1.474937727205361, 54.500297993704606],
            [-1.475551250885214, 54.498568855602919],
            [-1.475456316767273, 54.497903404167161],
            [-1.474020969314098, 54.496413413784865],
            [-1.472031029751141, 54.495915850825135],
            [-1.467056193511286, 54.496527621822878],
            [-1.462479125280503, 54.496348300146209],
            [-1.459893671726028, 54.495124433081799],
            [-1.458463462697438, 54.492828141315677],
            [-1.45839795794219, 54.491242533807785],
            [-1.461241445355418, 54.487611884059035],
            [-1.461817554570171, 54.485940161651861],
            [-1.461333782168254, 54.484695997750045],
            [-1.458103940097438, 54.482302696962719],
            [-1.457574729174907, 54.481471718029752],
            [-1.461362523565166, 54.478395300577461],
            [-1.462577750025464, 54.475871747316127],
            [-1.463788780830543, 54.473539587039191],
            [-1.463412754432204, 54.473030149903913],
            [-1.460181173578511, 54.471298339095121],
            [-1.459699886298038, 54.469062003188483],
            [-1.458705003386996, 54.468453622525026],
            [-1.454517322731092, 54.467192790830715],
            [-1.453474869875059, 54.466458332914087],
            [-1.453411970570375, 54.465968252605911],
            [-1.454772460165855, 54.465060408106069],
            [-1.461959500817658, 54.462886271217762],
            [-1.462790102319759, 54.461306439860401],
            [-1.461911919547013, 54.459573419528404],
            [-1.460955754745151, 54.458733361376979],
            [-1.457473513344334, 54.457153134265013],
            [-1.455776209087158, 54.45591607187518],
            [-1.454883505461468, 54.454726652192001],
            [-1.454906214229218, 54.453481142497324],
            [-1.457100482304183, 54.452002751269752],
            [-1.460348581400498, 54.451179694782269],
            [-1.461720970576093, 54.451138180989091],
            [-1.463720768839649, 54.451591030537898],
            [-1.466979262027338, 54.45328063467614],
            [-1.470716915499005, 54.454553449897112],
            [-1.472042401764482, 54.455588266813116],
            [-1.472195898211995, 54.45744566940256],
            [-1.46884272911462, 54.459335349688168],
            [-1.467834537986834, 54.460831759951525],
            [-1.467609321298242, 54.462139287858022],
            [-1.467971226656502, 54.463139345441633],
            [-1.472877663589279, 54.466575023048279],
            [-1.473953241107456, 54.467881933367387],
            [-1.474578669257122, 54.472778110196316],
            [-1.475362937088618, 54.47383389992445],
            [-1.477614909467239, 54.475001181331038],
            [-1.479080059699045, 54.475311263493765],
            [-1.483921674111635, 54.473416878684112],
            [-1.487016391567854, 54.472982489598749],
            [-1.490704539054061, 54.473962388221352],
            [-1.494603335646751, 54.474016489235943],
            [-1.496669820273102, 54.474373804686223],
            [-1.49834441030075, 54.474981097198373],
            [-1.499042799690432, 54.475667007660348],
            [-1.499578772402958, 54.477144899500693],
            [-1.499338899705849, 54.478084850353873],
            [-1.493229639592154, 54.481411574336725],
            [-1.491309747091685, 54.483270123497086],
            [-1.490522389450751, 54.485148695518156],
            [-1.491765934499253, 54.486189233550292],
            [-1.494304762357152, 54.486343666855831],
            [-1.496884414851003, 54.485814303398406],
            [-1.501344986938693, 54.484318459359116],
            [-1.505414775128898, 54.482307694073015],
            [-1.50622176891503, 54.481556978925575],
            [-1.506859220656739, 54.47847432949348],
            [-1.509732476463781, 54.475533767342554],
            [-1.511331382142351, 54.4750010155444],
            [-1.513119019302764, 54.475335353455925],
            [-1.514300447534082, 54.476562341166002],
            [-1.51436336900394, 54.477895371658114],
            [-1.512194751924598, 54.480128910510793],
            [-1.511991302409993, 54.481143624733186],
            [-1.512925630735463, 54.482914241329283],
            [-1.514122546369126, 54.483763835977875],
            [-1.515929809223782, 54.484561940489378],
            [-1.518065232909733, 54.485024312199904],
            [-1.523661966667669, 54.484901790194129],
            [-1.526399683277813, 54.485212706662161],
            [-1.5291498983252, 54.484707588213062],
            [-1.530603610727068, 54.483518862950405],
            [-1.531299328915112, 54.480581905084641],
            [-1.530263315094409, 54.47778831233466],
            [-1.530141715431734, 54.475739695281511],
            [-1.528588068192652, 54.474770239538742],
            [-1.524464839049672, 54.474631874550738],
            [-1.520629462018444, 54.473500543399446],
            [-1.519537142740095, 54.472561556551071],
            [-1.519450744960618, 54.471638243865158],
            [-1.520139159223583, 54.470827649672728],
            [-1.522407259093551, 54.470174282406532],
            [-1.527393605401805, 54.469953916627759],
            [-1.531331351402435, 54.469270048355675],
            [-1.533699138737106, 54.469189330440308],
            [-1.542740470267963, 54.471152470543224],
            [-1.545663645700208, 54.471227294912133],
            [-1.547839702121156, 54.472310298924981],
            [-1.551119547952023, 54.474488390148366],
            [-1.553705316637266, 54.478791958686379],
            [-1.554317526778933, 54.480540393696828],
            [-1.554271680377961, 54.482912796849945],
            [-1.555186275119341, 54.484966999600019],
            [-1.556653680410557, 54.485583503205511],
            [-1.56437266280087, 54.487212132300641],
            [-1.568074207803598, 54.488681299297525],
            [-1.573295061395308, 54.490148530911831],
            [-1.574182271977437, 54.491081810910693],
            [-1.57485048886467, 54.492443893008677],
            [-1.576990171979831, 54.494392582997698],
            [-1.580296191549547, 54.496297654745732],
            [-1.58292855296892, 54.497064360290565],
            [-1.583516117645901, 54.497740407870992],
            [-1.580210029170546, 54.500489782320038],
            [-1.578736153441591, 54.503871850820708],
            [-1.579106475634042, 54.505103456025523],
            [-1.579901747876406, 54.505408181932737],
            [-1.581119585342692, 54.505317142072521],
            [-1.589502330464226, 54.50358981718896],
            [-1.590807043078366, 54.503605919491768],
            [-1.591871927354832, 54.504289829456916],
            [-1.591919913215248, 54.505363933834808],
            [-1.591348390227589, 54.505841909018997],
            [-1.587504608998186, 54.506760815204885],
            [-1.58434358506072, 54.507953352923693],
            [-1.581296361736495, 54.509477820884101],
            [-1.579554781888055, 54.511078651247217],
            [-1.579687039087003, 54.511887038532279],
            [-1.581059715527712, 54.512834530598766],
            [-1.583853368500723, 54.513034693365519],
            [-1.590418670156005, 54.512026288443465],
            [-1.594644582265756, 54.512335289787586],
            [-1.601340429081587, 54.510570028108212],
            [-1.602628528672621, 54.510570669922252],
            [-1.603585939535896, 54.511227161511961],
            [-1.603678436624881, 54.512043479760145],
            [-1.60105519201262, 54.514801942419417],
            [-1.60043938842514, 54.51633308411877],
            [-1.600614315710187, 54.517086767603288],
            [-1.603946015328497, 54.519354337924085],
            [-1.609511469413804, 54.51989725979648],
            [-1.612098287376047, 54.519981068565215],
            [-1.614286932750606, 54.519531527047533],
            [-1.618712296319579, 54.516439663783238],
            [-1.623568436405536, 54.514637706211644],
            [-1.628548399820468, 54.513782258811936],
            [-1.633319943122651, 54.514100568251131],
            [-1.639317513986854, 54.516403968428016],
            [-1.640182795161756, 54.517079665209536],
            [-1.639619041337296, 54.517773576417483],
            [-1.635083952257002, 54.517563174064229],
            [-1.633745858347871, 54.517946469041931],
            [-1.632921981982907, 54.518693483269992],
            [-1.632670800962996, 54.520691414575055],
            [-1.631727241839276, 54.522541651853878],
            [-1.632284246621122, 54.523998328560317],
            [-1.63433069234873, 54.525631161265217],
            [-1.636752392077413, 54.525912554035393],
            [-1.640037866752471, 54.525661748349926],
            [-1.646304445307216, 54.524251301602582],
            [-1.652496279470534, 54.524133533506991],
            [-1.654665829329813, 54.524639415091954],
            [-1.656320862497191, 54.525522147030102],
            [-1.657024465122023, 54.52637250729164],
            [-1.656908738159466, 54.528198319789169],
            [-1.654969493002796, 54.530408081085191],
            [-1.655025594672797, 54.531819185296797],
            [-1.655570701866645, 54.532966567768298],
            [-1.657927777603272, 54.534578311644317],
            [-1.660631784872302, 54.535189841479237],
            [-1.665267943486611, 54.535579299802549],
            [-1.673234754094104, 54.535371898944511],
            [-1.677402034751392, 54.534483486676294],
            [-1.679284864042556, 54.533624848425859],
            [-1.682249610731247, 54.533328908875355],
            [-1.683620794765446, 54.533469100676442],
            [-1.689521938365329, 54.535376118464093],
            [-1.69691683390583, 54.535996078614062],
            [-1.698508872380203, 54.540791859481907],
            [-1.698004031717447, 54.540713314242304],
            [-1.69873159881619, 54.54329076583479],
            [-1.698330744143765, 54.544199239608005],
            [-1.700861648269123, 54.544342118866602],
            [-1.701710367502911, 54.544753115274538],
            [-1.70376190632283, 54.544926206051201],
            [-1.704512803156509, 54.546259894959931],
            [-1.704590340493093, 54.548367504733925],
            [-1.705264821937661, 54.549161789780705],
            [-1.703924131296759, 54.550706956074933],
            [-1.70115498155579, 54.549895829768211],
            [-1.699384603685418, 54.552033017627359],
            [-1.701196145958997, 54.552938883156465],
            [-1.70047680164336, 54.554051476790569],
            [-1.699137773009915, 54.554062536579998],
            [-1.697553238454218, 54.555702286167111],
            [-1.693987788361495, 54.555279933842989],
            [-1.69131892387101, 54.555301918513685],
            [-1.691197468132729, 54.556234444859221],
            [-1.694684330811472, 54.556627923229527],
            [-1.696578995050936, 54.557806368300795],
            [-1.698725445773988, 54.558195465569199],
            [-1.701785122243643, 54.558253364562248],
            [-1.703301986680743, 54.558685762909889],
            [-1.705870428089592, 54.560617010923991],
            [-1.705544647983759, 54.561835734496633],
            [-1.706312051613815, 54.562644616464453],
            [-1.707193270261568, 54.562680900032838],
            [-1.707428064719601, 54.563150580688749],
            [-1.709272872497424, 54.563169401800145],
            [-1.709657791218118, 54.565805266971772],
            [-1.70801898148734, 54.567893463502209],
            [-1.707680669263089, 54.568702361452146],
            [-1.708245791509295, 54.569054210509847],
            [-1.704471120719256, 54.57215180824938],
            [-1.705458802016355, 54.573756565625317],
            [-1.708975269840529, 54.574131727413146],
            [-1.705685698918655, 54.578337701855361],
            [-1.704063220829892, 54.578072232646072],
            [-1.703067983790372, 54.579843795829689],
            [-1.702838888536442, 54.581563311217124],
            [-1.700160423609082, 54.582238810989615],
            [-1.70002404963548, 54.584166145396701],
            [-1.699034018285317, 54.584556413724322],
            [-1.693855946646286, 54.585084445533106],
            [-1.694018865390646, 54.586884017165872],
            [-1.683901673632273, 54.585944981630817],
            [-1.679302843541288, 54.585963449996328],
            [-1.68060119698737, 54.597823176329733],
            [-1.680649303482972, 54.606319417398808],
            [-1.681971380245932, 54.608109467332632],
            [-1.681066551074002, 54.608396463690781],
            [-1.681046713347006, 54.60875588281214],
            [-1.682413392763691, 54.617761525838063],
            [-1.678738229841401, 54.617821037386349],
            [-1.672894061927574, 54.617428858519915],
            [-1.672186123414866, 54.617075562802228],
            [-1.663178784984455, 54.615526705575057],
            [-1.662273376880293, 54.615847709686662],
            [-1.657030380245064, 54.615463627806605],
            [-1.653217874627314, 54.616945481793287],
            [-1.651764261653115, 54.616893683407547],
            [-1.6497313132166, 54.61794736566759],
            [-1.641618463169311, 54.619356137253362],
            [-1.640766985899666, 54.617391801837542],
            [-1.637990666809223, 54.617194817277564],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000006",
        LAD13CDO: "00ET",
        LAD13NM: "Halton",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-2.626835747661862, 53.354624955503624],
              [-2.626934336652491, 53.354176796195119],
              [-2.628713091001785, 53.354375975272923],
              [-2.629107699125615, 53.354136591231644],
              [-2.6295177447097, 53.352702515103559],
              [-2.628568806751584, 53.352534933991507],
              [-2.630991400454485, 53.348320785528927],
              [-2.631468100426207, 53.346018030893653],
              [-2.628447773803475, 53.345636675984863],
              [-2.627713356011427, 53.346563691834888],
              [-2.624153564021381, 53.346065514784044],
              [-2.62313964943108, 53.347095537541435],
              [-2.622244270841524, 53.347397740208486],
              [-2.619177329059442, 53.346910326169684],
              [-2.615273918255234, 53.34674535369566],
              [-2.614626313837155, 53.346941052117856],
              [-2.614137735250748, 53.347645592509025],
              [-2.613638457559553, 53.347083666157985],
              [-2.612405194228112, 53.346663935301883],
              [-2.609573919472648, 53.346258660390887],
              [-2.607150324064106, 53.344413038579972],
              [-2.606607269190835, 53.342980292150187],
              [-2.60709061444935, 53.342325247176717],
              [-2.610377937917367, 53.341555210742882],
              [-2.611557971649607, 53.340891187077489],
              [-2.615699651226953, 53.334699070127904],
              [-2.619808236894188, 53.331898451471865],
              [-2.61912630714412, 53.331055247205164],
              [-2.617499701923925, 53.329572435715775],
              [-2.616260433586201, 53.329016145679716],
              [-2.614936597641095, 53.328841404523807],
              [-2.610392664518869, 53.330390146909139],
              [-2.609667601238626, 53.330087337004308],
              [-2.607580382436254, 53.327229658717535],
              [-2.604062301827399, 53.326364827804206],
              [-2.602561722760363, 53.325145445811771],
              [-2.601085643622733, 53.324594689110356],
              [-2.598640184982362, 53.325040249875329],
              [-2.596541915064046, 53.32465525601615],
              [-2.596304464144671, 53.323238907936101],
              [-2.59522440214707, 53.322438905758965],
              [-2.595255315747468, 53.32013042380305],
              [-2.596744248878183, 53.319359832049585],
              [-2.598765045949667, 53.319054869792268],
              [-2.599368977412507, 53.318659923442361],
              [-2.602364270119955, 53.318483931777401],
              [-2.603574242605808, 53.317956462252518],
              [-2.60471789592231, 53.315501213358381],
              [-2.606414780109956, 53.314811243230366],
              [-2.60653101108498, 53.314010646201815],
              [-2.607999649566425, 53.313847660554323],
              [-2.609143070003257, 53.312666085884288],
              [-2.609043085464786, 53.312074232372005],
              [-2.60942327401877, 53.312106447573179],
              [-2.61010004022928, 53.31272771104107],
              [-2.608284574921242, 53.31477924821867],
              [-2.609359388554323, 53.314996683897732],
              [-2.609717213081003, 53.31619576264211],
              [-2.610124108929098, 53.316308738584389],
              [-2.609906914761062, 53.316867156986184],
              [-2.610463902688303, 53.317399142330551],
              [-2.609832921300387, 53.317678327657752],
              [-2.610071897911296, 53.318015085260221],
              [-2.61351915848877, 53.318900777547931],
              [-2.616739779779364, 53.320611818105661],
              [-2.618538999090656, 53.320250137964578],
              [-2.619935917479168, 53.320305806414794],
              [-2.621028312765904, 53.319136069457919],
              [-2.617869704700457, 53.318188873452115],
              [-2.617472195392597, 53.317585085124129],
              [-2.619834861782668, 53.316855519608609],
              [-2.61780228672217, 53.316119993653444],
              [-2.622734772175603, 53.311999023540331],
              [-2.624715549175124, 53.311764840967477],
              [-2.624123963898022, 53.309383198183561],
              [-2.627519764041925, 53.308630088571448],
              [-2.629458032837662, 53.307763235271963],
              [-2.629967795804742, 53.307831555196628],
              [-2.629891715973637, 53.308066566127515],
              [-2.63250977506404, 53.307506185560726],
              [-2.635724734760845, 53.307934037254171],
              [-2.638866393911712, 53.31008176293134],
              [-2.639048126000206, 53.309588200008797],
              [-2.639847855594655, 53.309373572349685],
              [-2.645062442997616, 53.310120317519804],
              [-2.643210665700496, 53.307043546684042],
              [-2.641558027417811, 53.305020068441827],
              [-2.643999101559624, 53.30648198184646],
              [-2.648368268153269, 53.308105064583863],
              [-2.670318595289944, 53.312417005038853],
              [-2.678164314427719, 53.31414525077615],
              [-2.685380025281422, 53.315298619747495],
              [-2.687249264148494, 53.314019532669839],
              [-2.687579392978881, 53.313360543139297],
              [-2.693506874401857, 53.310225056803347],
              [-2.693303288437143, 53.310072530731262],
              [-2.69861082916996, 53.30689005178985],
              [-2.700776533419697, 53.305799576240503],
              [-2.70120353351681, 53.30603167616264],
              [-2.707456347631509, 53.309442882677907],
              [-2.715214842127217, 53.311731018040945],
              [-2.723524908586136, 53.313124505788387],
              [-2.724148318877399, 53.31244565596576],
              [-2.72815194258491, 53.312395209786175],
              [-2.731046719633172, 53.311838165606829],
              [-2.73288739268686, 53.310556735997309],
              [-2.734338976578166, 53.30724887779985],
              [-2.735454281249252, 53.306739524443699],
              [-2.737692945211585, 53.30662771746217],
              [-2.739413110790199, 53.306907405580539],
              [-2.740819054201019, 53.308193082813396],
              [-2.742919239257003, 53.309264079937407],
              [-2.747359416896927, 53.312399533136535],
              [-2.752431786116932, 53.314744304939566],
              [-2.752437548477655, 53.31549843787537],
              [-2.753640702402664, 53.317078281591613],
              [-2.757474030507534, 53.319933142542389],
              [-2.759905800280174, 53.322288945622127],
              [-2.761673347323462, 53.324847597234168],
              [-2.761965804149356, 53.32474595246326],
              [-2.763377329503411, 53.327534271325185],
              [-2.763236082722128, 53.330219258643652],
              [-2.762229801770498, 53.332563703268413],
              [-2.760443299727013, 53.334530188294842],
              [-2.756550523162028, 53.337310032776557],
              [-2.75143608961868, 53.341023302737391],
              [-2.756882374528165, 53.337476918499632],
              [-2.759530378603878, 53.335973326842812],
              [-2.75999961673563, 53.336687650732017],
              [-2.758755225248433, 53.338412448086864],
              [-2.755897774514264, 53.34200995784154],
              [-2.753772022512954, 53.343431065205721],
              [-2.743350923389239, 53.345325689199051],
              [-2.721977168556236, 53.344491555235905],
              [-2.718661748470856, 53.344915173318263],
              [-2.716272266493711, 53.346187981896144],
              [-2.714013258246358, 53.347905807429285],
              [-2.712924168401384, 53.347995018044124],
              [-2.708657613712851, 53.349964722735976],
              [-2.70465597789531, 53.353263953573297],
              [-2.702379818451967, 53.354629294233533],
              [-2.701478720655277, 53.35565662438475],
              [-2.699515044214247, 53.356666811205415],
              [-2.697853039396469, 53.356834747171391],
              [-2.697732693338321, 53.356457920845479],
              [-2.697591382014584, 53.356820996365101],
              [-2.693042502155913, 53.356675820317648],
              [-2.685180497922138, 53.355862799136652],
              [-2.680340024938403, 53.354663527647276],
              [-2.675187724765718, 53.354439313110376],
              [-2.674640380619607, 53.353648697951385],
              [-2.642179904766289, 53.361725385351136],
              [-2.630622537700603, 53.364034223801681],
              [-2.626880917124358, 53.358725398221445],
              [-2.627682363674997, 53.355290168339714],
              [-2.627516488552143, 53.354839804190746],
              [-2.626835747661862, 53.354624955503624],
            ],
          ],
          [
            [
              [-2.734448145940126, 53.400530777472831],
              [-2.728935944328053, 53.399654903067059],
              [-2.719614144580204, 53.39992090354994],
              [-2.715226864104526, 53.39901873364586],
              [-2.716589629681946, 53.399028537688707],
              [-2.716120133503641, 53.396864164721094],
              [-2.712803260875676, 53.390609859380994],
              [-2.710222071969729, 53.389925021735237],
              [-2.710343542858512, 53.389273511772437],
              [-2.705080950570527, 53.387915952278505],
              [-2.705041795873091, 53.386729665670011],
              [-2.701075608309462, 53.386830333362873],
              [-2.690632446708981, 53.385372120814836],
              [-2.690648275055629, 53.385235399955995],
              [-2.693960291971357, 53.384301130607454],
              [-2.696066461432235, 53.383185043811146],
              [-2.697354996741772, 53.38153617109549],
              [-2.697724279759403, 53.37929670571642],
              [-2.697201080325737, 53.368528499115442],
              [-2.69348808071739, 53.364591470059821],
              [-2.693378127150633, 53.361800187253529],
              [-2.700116924249222, 53.362310950752118],
              [-2.704971629177504, 53.36110033729662],
              [-2.705650701837731, 53.361456774401972],
              [-2.705267251769695, 53.360985331221571],
              [-2.708885944125074, 53.359648822772954],
              [-2.712413320587547, 53.357805779551683],
              [-2.715291953514847, 53.356448322856515],
              [-2.718870500036695, 53.355267256211661],
              [-2.721448294295853, 53.355422484074992],
              [-2.721502532551872, 53.355787102533171],
              [-2.721859119001616, 53.355550337167216],
              [-2.723131776252836, 53.355644204134911],
              [-2.723490656581221, 53.355366071339027],
              [-2.726447114929679, 53.355081138344005],
              [-2.728464051801906, 53.354504341757469],
              [-2.729046519970761, 53.353068862396768],
              [-2.730962444518491, 53.352391066382175],
              [-2.731090125222868, 53.352565566392194],
              [-2.731239289042907, 53.352152064524986],
              [-2.731655510716416, 53.352331986147185],
              [-2.73159474199392, 53.35202943491192],
              [-2.732010586290454, 53.352274976144173],
              [-2.731228721007977, 53.349955256551574],
              [-2.731561720256537, 53.349048937400468],
              [-2.732746515293104, 53.348059185440199],
              [-2.73430811951892, 53.347758345085076],
              [-2.736693145197948, 53.347820945105042],
              [-2.739823497844597, 53.349198440885942],
              [-2.741362842388862, 53.349519674680124],
              [-2.741185650504904, 53.349696957089421],
              [-2.747482818820628, 53.350628464627917],
              [-2.750389573583442, 53.350534710908427],
              [-2.758670408016572, 53.349537610118126],
              [-2.75860924454732, 53.350069240615078],
              [-2.759558709712338, 53.351169727956012],
              [-2.758449471886233, 53.351139031619176],
              [-2.759382529607133, 53.351400065342666],
              [-2.762050509760829, 53.353246443769677],
              [-2.762952793547405, 53.353715288807969],
              [-2.764111061238341, 53.355138902038703],
              [-2.76454737678377, 53.355172961192558],
              [-2.763536768650511, 53.353858071315102],
              [-2.75998218738488, 53.351666811579761],
              [-2.759132548739619, 53.349591300186162],
              [-2.764453977700245, 53.348108315974883],
              [-2.768978945920942, 53.345542576184627],
              [-2.770478439780213, 53.343982320527594],
              [-2.772658158524173, 53.340591101129689],
              [-2.776191577109108, 53.337250364972022],
              [-2.777242083423304, 53.335557209037027],
              [-2.77827845594418, 53.332597599988631],
              [-2.780413237903175, 53.334952226151842],
              [-2.784287510590858, 53.336415377168343],
              [-2.780237024629311, 53.334512923059833],
              [-2.779509696147974, 53.333497438702324],
              [-2.779613694537879, 53.332942144131927],
              [-2.778398155071865, 53.332408950988615],
              [-2.777910555529569, 53.331607625645461],
              [-2.777466352478283, 53.330301738175159],
              [-2.777707921528951, 53.329218798669658],
              [-2.779096574079279, 53.327392180118238],
              [-2.783126703322758, 53.324698778231905],
              [-2.785582419132755, 53.323444855607434],
              [-2.789806267140204, 53.322340986729998],
              [-2.794660802192475, 53.322226941824475],
              [-2.797646682556733, 53.324471319776073],
              [-2.803513124498543, 53.327620313574222],
              [-2.805349952039968, 53.327887480944412],
              [-2.819055117768825, 53.332962789131557],
              [-2.821525840115261, 53.333300860883753],
              [-2.823271905264301, 53.333125224296097],
              [-2.8244158636031, 53.332875521375371],
              [-2.826660993647305, 53.33164108472149],
              [-2.831530664329614, 53.335111121483358],
              [-2.832457310222015, 53.337273686016822],
              [-2.831082865836747, 53.337258999533901],
              [-2.831065502400532, 53.337756207909713],
              [-2.827326392589967, 53.33785680688019],
              [-2.823482870337711, 53.337371932045166],
              [-2.822328515273322, 53.340677927969622],
              [-2.820209797612338, 53.340391385669449],
              [-2.820377044499553, 53.339871575433783],
              [-2.818779805393874, 53.339755805367233],
              [-2.818709561752471, 53.340714505315205],
              [-2.819840531535915, 53.341184948122205],
              [-2.818000190326181, 53.342965699600683],
              [-2.819868537731775, 53.343662098642149],
              [-2.818977196076949, 53.345274538237234],
              [-2.818797122678649, 53.34779176768501],
              [-2.818806834459121, 53.347984962359348],
              [-2.816953603902706, 53.348794982389101],
              [-2.816219721416225, 53.350325420995723],
              [-2.811947625623978, 53.352873258507991],
              [-2.809064060219398, 53.355065460827369],
              [-2.800900112852794, 53.355881900154614],
              [-2.787301044634, 53.356274437085716],
              [-2.78482228850106, 53.360706995668188],
              [-2.783875089324877, 53.361741547464511],
              [-2.784563493282675, 53.361915002313438],
              [-2.780913661945498, 53.366196065292698],
              [-2.781305232049527, 53.366323840332385],
              [-2.78117598478689, 53.366816376502037],
              [-2.781893504599146, 53.367016622740898],
              [-2.781910916648223, 53.36747493984381],
              [-2.780246780321254, 53.369916416251556],
              [-2.78228907852095, 53.370396532450101],
              [-2.78131827264319, 53.371634364032779],
              [-2.779738083234615, 53.375554843587317],
              [-2.778888892350559, 53.375642187526601],
              [-2.778541837593013, 53.377381993259604],
              [-2.777051599278494, 53.379663182644521],
              [-2.776670820522372, 53.381042749237658],
              [-2.775621461519616, 53.380710695111915],
              [-2.773211245063063, 53.381022067286118],
              [-2.772140605674639, 53.382918448839028],
              [-2.770779125302407, 53.383118711385869],
              [-2.769053480879331, 53.38388490425065],
              [-2.7675929298785, 53.383762171368716],
              [-2.764524602833057, 53.382682534557617],
              [-2.760580908779184, 53.382330202723942],
              [-2.758658128632048, 53.380980636194202],
              [-2.757653810173087, 53.380721846457583],
              [-2.757774523839121, 53.382863113635452],
              [-2.758782231367038, 53.383732220987724],
              [-2.7602705804763, 53.384319605926834],
              [-2.752596376008588, 53.385908027924437],
              [-2.751491073713735, 53.386663759777527],
              [-2.75163980044249, 53.386912711846918],
              [-2.749460526425906, 53.38816057844808],
              [-2.747889083123309, 53.388750213196893],
              [-2.747106069125869, 53.392376701231669],
              [-2.750050232014573, 53.392367224851789],
              [-2.750647883927891, 53.393772010987362],
              [-2.745287017909528, 53.394555269940213],
              [-2.744123225240435, 53.394823205798296],
              [-2.744324602635545, 53.395246220739288],
              [-2.743395625650106, 53.395269088883317],
              [-2.74532084218515, 53.397178884316119],
              [-2.746264652375336, 53.398946473491485],
              [-2.745174885428848, 53.402079577790303],
              [-2.743863787338201, 53.402537193898176],
              [-2.740372639994638, 53.401403841386042],
              [-2.734448145940126, 53.400530777472831],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000007",
        LAD13CDO: "00EU",
        LAD13NM: "Warrington",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.478154091030088, 53.443401390492689],
            [-2.47410295984326, 53.439367289218801],
            [-2.470799439192129, 53.438894078821463],
            [-2.467700334566783, 53.437426737803982],
            [-2.466339588286266, 53.437019484408154],
            [-2.463321254795669, 53.436999775687951],
            [-2.461724642395712, 53.436352490773693],
            [-2.459260511672531, 53.433143181683761],
            [-2.455129528963516, 53.429968051648963],
            [-2.454068315594342, 53.428259765450257],
            [-2.451544392874111, 53.426692738980641],
            [-2.450315659044013, 53.424063720923897],
            [-2.448995253040528, 53.422858828915338],
            [-2.449188765599531, 53.420518367293802],
            [-2.451275653423486, 53.418657943945959],
            [-2.450751950721551, 53.417370057164909],
            [-2.449362564551695, 53.416738902102608],
            [-2.44937903006218, 53.415875034492352],
            [-2.450833344554396, 53.415251133781787],
            [-2.453682100704593, 53.413196328537076],
            [-2.456257719275636, 53.410807222952357],
            [-2.46210181645482, 53.403835630147206],
            [-2.46597709724819, 53.400731167465686],
            [-2.471732888142819, 53.39773421271336],
            [-2.478454712592616, 53.396201854566961],
            [-2.475713266921248, 53.394187670243127],
            [-2.473644883077715, 53.393720397582875],
            [-2.472647016364216, 53.392692456252981],
            [-2.471044448540978, 53.39232126912723],
            [-2.468172727572059, 53.393040875879294],
            [-2.467850891423164, 53.393311797661802],
            [-2.468189088848539, 53.393846195029923],
            [-2.46628161066287, 53.394057705688567],
            [-2.465906295373593, 53.394803431855898],
            [-2.464081119189792, 53.394153473210523],
            [-2.463258585338884, 53.394710369908786],
            [-2.461963441003769, 53.394537415264836],
            [-2.460863688087711, 53.393936731314106],
            [-2.459948797954679, 53.394299806982367],
            [-2.458503158559623, 53.392994824931023],
            [-2.458254040670329, 53.393881163817603],
            [-2.457522716129284, 53.393835431183689],
            [-2.456895174356444, 53.394212662108188],
            [-2.456043873005769, 53.393916596419935],
            [-2.455435452521475, 53.394255095385411],
            [-2.454496207192132, 53.393890141692907],
            [-2.452811365138913, 53.39454013372054],
            [-2.453032391780297, 53.394961761203305],
            [-2.452607177720858, 53.395137754385367],
            [-2.452009995686242, 53.395122040526701],
            [-2.451806099304329, 53.394613156462576],
            [-2.450828646045748, 53.394899096553885],
            [-2.448439378450439, 53.394783156673761],
            [-2.44819351639132, 53.394283412823036],
            [-2.446857655181956, 53.393661913973972],
            [-2.44674059857011, 53.393110449400943],
            [-2.445270165650995, 53.392844488007448],
            [-2.445482650082715, 53.392168647611626],
            [-2.44279328695278, 53.391953940925006],
            [-2.441655488021599, 53.390986484725964],
            [-2.440580033378698, 53.390954505690061],
            [-2.439860243577468, 53.391437154799888],
            [-2.438575787055398, 53.391553341700671],
            [-2.438787703920255, 53.390082020039088],
            [-2.436995760876186, 53.389240067465401],
            [-2.433871758986424, 53.389436633353746],
            [-2.433250718482577, 53.389135972429806],
            [-2.433340654599779, 53.388220601475922],
            [-2.431997188798017, 53.387698738024625],
            [-2.429778803877243, 53.388380897363682],
            [-2.428362719550041, 53.388409359838008],
            [-2.42783861897129, 53.388923592991105],
            [-2.426590877919129, 53.387444933542547],
            [-2.425382183041286, 53.386706786306149],
            [-2.426828054888429, 53.384702547175145],
            [-2.426220156985444, 53.384501576656739],
            [-2.426470530984168, 53.382777557178279],
            [-2.425507480214433, 53.379674515730436],
            [-2.428724535989006, 53.376389328438194],
            [-2.427490000662877, 53.375894887304653],
            [-2.431277973546096, 53.373373416126043],
            [-2.432778513749816, 53.37401516668605],
            [-2.434603699420836, 53.372141584801],
            [-2.435549885889609, 53.372661275079189],
            [-2.436483666815637, 53.370937430143165],
            [-2.435795880830517, 53.370699047976338],
            [-2.436433794621609, 53.370322787129815],
            [-2.437898213519349, 53.370230230841528],
            [-2.439664205805289, 53.3705257553341],
            [-2.439881221792377, 53.366646339673984],
            [-2.447158722577459, 53.36586962758755],
            [-2.450458349074332, 53.36653496132282],
            [-2.450923764856294, 53.367060839020915],
            [-2.458843137289235, 53.368269284643205],
            [-2.460953955706414, 53.364167697546925],
            [-2.463239721258162, 53.364455470722028],
            [-2.464996691868337, 53.363514709900905],
            [-2.467087181191036, 53.363672834278574],
            [-2.468755029280977, 53.364205614432635],
            [-2.469064460839104, 53.364056983563259],
            [-2.469840449567662, 53.364519544543626],
            [-2.471766766350032, 53.364637784361257],
            [-2.471713749883012, 53.364871700143439],
            [-2.47504140995911, 53.36600275752312],
            [-2.477180783142406, 53.366083207883221],
            [-2.479084612402386, 53.367135345369931],
            [-2.479842867666401, 53.367342633598689],
            [-2.480067228396773, 53.367114316973549],
            [-2.480726296260539, 53.367588064109732],
            [-2.483643686418517, 53.367487285104929],
            [-2.484240413739295, 53.367760817627605],
            [-2.486021263759142, 53.367625936703206],
            [-2.486879370594888, 53.367887604547938],
            [-2.487464371954951, 53.367395331660468],
            [-2.487235866712915, 53.366862336287269],
            [-2.489647640876942, 53.366839878210399],
            [-2.489784156860175, 53.365640224946027],
            [-2.492102040349625, 53.364779456393677],
            [-2.49203559301824, 53.363968949165027],
            [-2.492811172517751, 53.363593615461312],
            [-2.492830650921839, 53.362677585624006],
            [-2.493591519765594, 53.362722080216237],
            [-2.493553613564038, 53.36243280059896],
            [-2.494377619641357, 53.362090514418995],
            [-2.496595256423944, 53.361678609700753],
            [-2.49662601043431, 53.360963879037584],
            [-2.497695540380077, 53.360548637719127],
            [-2.497824812419491, 53.359393048469492],
            [-2.499694827491401, 53.359078715045626],
            [-2.501314030690022, 53.358392374674281],
            [-2.502752286713879, 53.357527899332332],
            [-2.502173213338989, 53.357092587643947],
            [-2.5030946743395, 53.356109833344149],
            [-2.505824540984042, 53.355469078808824],
            [-2.506986086121409, 53.35523044113306],
            [-2.506841642237027, 53.354832854359621],
            [-2.508238545235928, 53.354300171705368],
            [-2.508897439208188, 53.353615118209326],
            [-2.509903120765432, 53.353665654973881],
            [-2.510138310703371, 53.353231392667546],
            [-2.510977243375654, 53.353283532215073],
            [-2.511177524146461, 53.352820653396265],
            [-2.51309693141252, 53.35252297584104],
            [-2.51546164356544, 53.349665149835914],
            [-2.51669617420244, 53.348895766026303],
            [-2.519561231422578, 53.347938604748656],
            [-2.521050713788694, 53.347884467495213],
            [-2.518394118711245, 53.342374255840582],
            [-2.538235082147675, 53.335936713521953],
            [-2.539454012628187, 53.336374352911825],
            [-2.541266907914625, 53.333432204990828],
            [-2.543006865980265, 53.332450811788114],
            [-2.544530298794501, 53.330509481330779],
            [-2.548281653902289, 53.331015441797796],
            [-2.551665498209717, 53.332358042527467],
            [-2.553929210856329, 53.328710689104284],
            [-2.557368044262731, 53.32609511002908],
            [-2.558838702522172, 53.325696316525828],
            [-2.56016810267071, 53.323914792984318],
            [-2.56187638477615, 53.323326979453668],
            [-2.563088975262525, 53.323064180649929],
            [-2.565175212890978, 53.323590044387316],
            [-2.566493644989207, 53.324177948507113],
            [-2.567848910934602, 53.325388586313373],
            [-2.569403651030188, 53.325224768395678],
            [-2.575396978141384, 53.325661622978885],
            [-2.576817202810298, 53.326100606913236],
            [-2.578363813496909, 53.326002329796907],
            [-2.578984823409881, 53.327068983745129],
            [-2.578655934787815, 53.327617997734983],
            [-2.57975894702078, 53.327909271318617],
            [-2.580233666116719, 53.328591909096524],
            [-2.581556185343866, 53.328349961173394],
            [-2.586290971524486, 53.329381165988309],
            [-2.586346543787828, 53.328725609340943],
            [-2.588335986141964, 53.327414233692821],
            [-2.588109687643244, 53.326462536680324],
            [-2.588501858917123, 53.325939251526677],
            [-2.590092419704341, 53.325872965123892],
            [-2.592739987303906, 53.32472634746518],
            [-2.592586604120912, 53.322434064206846],
            [-2.59522440214707, 53.322438905758965],
            [-2.596304464144671, 53.323238907936101],
            [-2.596541915064046, 53.32465525601615],
            [-2.598640184982362, 53.325040249875329],
            [-2.601085643622733, 53.324594689110356],
            [-2.602561722760363, 53.325145445811771],
            [-2.604062301827399, 53.326364827804206],
            [-2.607580382436254, 53.327229658717535],
            [-2.609667601238626, 53.330087337004308],
            [-2.610392664518869, 53.330390146909139],
            [-2.614936597641095, 53.328841404523807],
            [-2.616260433586201, 53.329016145679716],
            [-2.617499701923925, 53.329572435715775],
            [-2.61912630714412, 53.331055247205164],
            [-2.619808236894188, 53.331898451471865],
            [-2.615699651226953, 53.334699070127904],
            [-2.611557971649607, 53.340891187077489],
            [-2.610377937917367, 53.341555210742882],
            [-2.60709061444935, 53.342325247176717],
            [-2.606607269190835, 53.342980292150187],
            [-2.607150324064106, 53.344413038579972],
            [-2.609573919472648, 53.346258660390887],
            [-2.612405194228112, 53.346663935301883],
            [-2.613638457559553, 53.347083666157985],
            [-2.614137735250748, 53.347645592509025],
            [-2.614626313837155, 53.346941052117856],
            [-2.615273918255234, 53.34674535369566],
            [-2.619177329059442, 53.346910326169684],
            [-2.622244270841524, 53.347397740208486],
            [-2.62313964943108, 53.347095537541435],
            [-2.624153564021381, 53.346065514784044],
            [-2.627713356011427, 53.346563691834888],
            [-2.628447773803475, 53.345636675984863],
            [-2.631468100426207, 53.346018030893653],
            [-2.630991400454485, 53.348320785528927],
            [-2.628568806751584, 53.352534933991507],
            [-2.6295177447097, 53.352702515103559],
            [-2.629107699125615, 53.354136591231644],
            [-2.628713091001785, 53.354375975272923],
            [-2.626934336652491, 53.354176796195119],
            [-2.626835747661862, 53.354624955503624],
            [-2.627516488552143, 53.354839804190746],
            [-2.627682363674997, 53.355290168339714],
            [-2.626880917124358, 53.358725398221445],
            [-2.630622537700603, 53.364034223801681],
            [-2.642179904766289, 53.361725385351136],
            [-2.674640380619607, 53.353648697951385],
            [-2.675187724765718, 53.354439313110376],
            [-2.669047293985369, 53.355966046812838],
            [-2.666141287288542, 53.35768298805241],
            [-2.664760700563944, 53.362040364523878],
            [-2.665192326448042, 53.362256386485861],
            [-2.665185519355799, 53.364134181969064],
            [-2.663977426127643, 53.367233052030116],
            [-2.65933100482542, 53.372803991590921],
            [-2.656768453352746, 53.37408284269749],
            [-2.654661505016971, 53.37440183618228],
            [-2.652065519404139, 53.37399987025487],
            [-2.646911404716942, 53.372213092287168],
            [-2.642327688223767, 53.372446393604712],
            [-2.639867471837061, 53.374267250834649],
            [-2.638840437920062, 53.377150952793301],
            [-2.638194783851111, 53.377601148499735],
            [-2.632836326214754, 53.376114182328109],
            [-2.624671806085845, 53.374412485559745],
            [-2.6231216516036, 53.374381039968959],
            [-2.621505814393734, 53.374999802809164],
            [-2.618978562849392, 53.376622823083416],
            [-2.614767369584582, 53.380636491396487],
            [-2.610419162040547, 53.385741938918905],
            [-2.609410318956553, 53.386373608907135],
            [-2.607693818781121, 53.386726629859837],
            [-2.606622940173149, 53.386278147534561],
            [-2.606505437557009, 53.385521894253216],
            [-2.60914234562061, 53.382769598780399],
            [-2.609502723226242, 53.381055405529018],
            [-2.607747473956322, 53.376140328631621],
            [-2.606007835571469, 53.374518618814022],
            [-2.602605630719407, 53.37351203130028],
            [-2.600620980775511, 53.373575969980827],
            [-2.60021270202038, 53.373294879076994],
            [-2.600778907033424, 53.373156299700057],
            [-2.597792624545309, 53.372640063370618],
            [-2.597315335228655, 53.37292341007624],
            [-2.598196221132205, 53.372910399518965],
            [-2.599635821244183, 53.373101824938061],
            [-2.600529896785074, 53.373725641481883],
            [-2.598753691385049, 53.374398835122335],
            [-2.597725317345202, 53.375239044433854],
            [-2.592649849115594, 53.383181630867888],
            [-2.592186404983074, 53.384994259587955],
            [-2.591164899039804, 53.385706736193669],
            [-2.590156522617555, 53.385849254426759],
            [-2.587715458902781, 53.384912092674021],
            [-2.581587284194284, 53.384467511318789],
            [-2.577971396660366, 53.383462163011707],
            [-2.577053026084893, 53.384033795153186],
            [-2.577461515765884, 53.384667321133989],
            [-2.577938897369071, 53.384947255713804],
            [-2.57820126058862, 53.384781491403679],
            [-2.578731239945807, 53.38395106198854],
            [-2.579782824514756, 53.384333371152856],
            [-2.57981236412422, 53.384626259676388],
            [-2.586272331574941, 53.385036044354493],
            [-2.590019691519201, 53.386174423628518],
            [-2.59145936568475, 53.386016286728733],
            [-2.593000079627922, 53.384907522956695],
            [-2.593242999945091, 53.383239806980676],
            [-2.598062028529241, 53.375668816597312],
            [-2.599279913023191, 53.374622709649721],
            [-2.600608731073156, 53.37420074896432],
            [-2.602351867462987, 53.374062524550183],
            [-2.604925006006358, 53.374688607071683],
            [-2.606143793413164, 53.375307139599421],
            [-2.60702819646051, 53.376297697213069],
            [-2.608648900725158, 53.381692570046248],
            [-2.608057893271011, 53.382945016294755],
            [-2.605640470447151, 53.385489433250164],
            [-2.606338996217237, 53.38682250933477],
            [-2.607584718260954, 53.387089430863057],
            [-2.6095030513939, 53.386865717394251],
            [-2.611028326005281, 53.386074100995515],
            [-2.62077848989905, 53.376975719442193],
            [-2.623297390692072, 53.37529877218283],
            [-2.625618401111224, 53.375413367453028],
            [-2.637356707758255, 53.37855483964259],
            [-2.638964186685725, 53.37838804130925],
            [-2.641627107594168, 53.37733375445297],
            [-2.644856343099488, 53.37427365340627],
            [-2.645686746870254, 53.373922196830705],
            [-2.64699758727375, 53.373932175292495],
            [-2.652065725513562, 53.375587284587532],
            [-2.65429967550819, 53.375801573372797],
            [-2.656540943416035, 53.375415330739976],
            [-2.660174502143258, 53.373891463372544],
            [-2.664668288561202, 53.370003142600773],
            [-2.668962780373205, 53.363710366456466],
            [-2.66916995084998, 53.363888980506012],
            [-2.669999538869142, 53.362649265381656],
            [-2.670611099555526, 53.362551447371985],
            [-2.670304252870785, 53.362149574912998],
            [-2.672045595711174, 53.358410328522936],
            [-2.674487997405505, 53.356747101242249],
            [-2.676799069775432, 53.356668395334324],
            [-2.677168651425831, 53.357519335553498],
            [-2.67801934019175, 53.356779220179703],
            [-2.679271622584604, 53.357012996916488],
            [-2.682963078317433, 53.358317771516724],
            [-2.683169138496969, 53.358706705856036],
            [-2.683138262651945, 53.35837339804965],
            [-2.685009577981083, 53.359257054720153],
            [-2.685441977469709, 53.359142211338892],
            [-2.686969205184484, 53.35974107081887],
            [-2.688935559232104, 53.360825468035557],
            [-2.688783066744497, 53.3605009537835],
            [-2.693378127150633, 53.361800187253529],
            [-2.69348808071739, 53.364591470059821],
            [-2.697201080325737, 53.368528499115442],
            [-2.697724279759403, 53.37929670571642],
            [-2.697354996741772, 53.38153617109549],
            [-2.696066461432235, 53.383185043811146],
            [-2.693960291971357, 53.384301130607454],
            [-2.690648275055629, 53.385235399955995],
            [-2.690632446708981, 53.385372120814836],
            [-2.676317005783245, 53.387603315816634],
            [-2.676033891090818, 53.389852108389036],
            [-2.67652527953586, 53.39381426155672],
            [-2.674815503321836, 53.397997413920024],
            [-2.673794231059148, 53.398371721715044],
            [-2.673108750687173, 53.399725697633734],
            [-2.671376938302105, 53.400157921662725],
            [-2.672355400458214, 53.407746092802533],
            [-2.67292727333843, 53.407857027203384],
            [-2.672996494195683, 53.40881123938469],
            [-2.673025304001044, 53.410064104757886],
            [-2.672136388893598, 53.411791351601046],
            [-2.672018424340526, 53.413469310192831],
            [-2.671876817701115, 53.415270546595728],
            [-2.670744906790877, 53.415239158985152],
            [-2.66685640617015, 53.416247903371087],
            [-2.667093815524986, 53.418630381977643],
            [-2.671799833844501, 53.418880845601578],
            [-2.672320154428422, 53.422008682467251],
            [-2.670063711856057, 53.424159779421664],
            [-2.666567479422992, 53.426342928722271],
            [-2.665674358072804, 53.427839135429458],
            [-2.663784502458723, 53.428444708125532],
            [-2.663852964080725, 53.429650609360785],
            [-2.664819144759395, 53.431285668748259],
            [-2.66644259358618, 53.431826726040057],
            [-2.670319321928504, 53.432274234743936],
            [-2.672725052685833, 53.432020707043115],
            [-2.675268404300645, 53.43294926400862],
            [-2.676046972369019, 53.43543921888579],
            [-2.674182347727957, 53.43527358299572],
            [-2.6716833566505, 53.435924064965846],
            [-2.671837212188811, 53.437852170142463],
            [-2.668846000657954, 53.439379059573668],
            [-2.668329123674716, 53.440400370275249],
            [-2.668772547326778, 53.441027993676791],
            [-2.670292579782717, 53.441284637036105],
            [-2.678845013978231, 53.439106906934057],
            [-2.678879746983232, 53.439680187262489],
            [-2.682067278039358, 53.438871019243379],
            [-2.682169186685436, 53.439218299119034],
            [-2.67982946123845, 53.442213186983018],
            [-2.681339011438724, 53.44256053531528],
            [-2.680278733825501, 53.444702289099574],
            [-2.678690762507741, 53.44425649763447],
            [-2.678477474675914, 53.444571414276389],
            [-2.678223667980916, 53.446312163764659],
            [-2.67735545557969, 53.448049207617544],
            [-2.677789189734364, 53.449000444198987],
            [-2.677504933088017, 53.449399356112821],
            [-2.678613628736525, 53.450561587651926],
            [-2.67716805152312, 53.4527369591152],
            [-2.674463065739654, 53.45289878085314],
            [-2.673510141933335, 53.452727081990993],
            [-2.673651004615527, 53.452398201530372],
            [-2.672517410269152, 53.450893596810488],
            [-2.670661816922562, 53.449909885861899],
            [-2.669507447196858, 53.449753668142655],
            [-2.668496196677418, 53.449984948315674],
            [-2.66787719580548, 53.449592910631921],
            [-2.666988874333577, 53.449405517892558],
            [-2.666062918415449, 53.449603043035886],
            [-2.665132750033814, 53.449146210159647],
            [-2.663874563131219, 53.449475004886253],
            [-2.659947178108374, 53.448964643916376],
            [-2.658787512502179, 53.447683871134416],
            [-2.657916308864777, 53.447429800581304],
            [-2.654743639396275, 53.448101611243047],
            [-2.652360822684251, 53.448147909423632],
            [-2.651096886393677, 53.447711666552749],
            [-2.649379135316432, 53.446345755555001],
            [-2.647983343992385, 53.446638277674722],
            [-2.646057538705177, 53.445343559270299],
            [-2.645657607855124, 53.444288656464643],
            [-2.641891981209893, 53.443875703693834],
            [-2.639540750499377, 53.44440606531203],
            [-2.632408481078057, 53.441644084707143],
            [-2.631410535349773, 53.440133878701666],
            [-2.632089780873359, 53.437923570022441],
            [-2.630043313543118, 53.43512902500688],
            [-2.627463072677716, 53.4343965530391],
            [-2.626323910984826, 53.434416016852254],
            [-2.626716974656906, 53.435145631336397],
            [-2.625433471110743, 53.436509648449963],
            [-2.620980233388988, 53.437088389666734],
            [-2.620934073042238, 53.43784547430247],
            [-2.619303569886014, 53.438244049442027],
            [-2.619575318358709, 53.438815216780618],
            [-2.617787795011797, 53.438770546400299],
            [-2.615944914355691, 53.440726108846036],
            [-2.612296241294597, 53.441598794760615],
            [-2.611187606620471, 53.442290307593943],
            [-2.606344251246008, 53.44280577867157],
            [-2.604126806883635, 53.442393652796106],
            [-2.602486111634723, 53.442414526158778],
            [-2.600676964178087, 53.443513961797478],
            [-2.598859482092192, 53.443385564213884],
            [-2.597051596508686, 53.442545191257651],
            [-2.596231060570808, 53.442649963750078],
            [-2.595890474563136, 53.445314096385907],
            [-2.596659112869379, 53.448162349849305],
            [-2.598685608928198, 53.450397566026538],
            [-2.591596871921571, 53.446531830688471],
            [-2.585296414821866, 53.439790845397475],
            [-2.584596921237921, 53.440376735367231],
            [-2.582314642576487, 53.441142931979151],
            [-2.576743094947111, 53.446040907135895],
            [-2.574631279703983, 53.447979146273205],
            [-2.573748465897974, 53.451182438267836],
            [-2.570551945393755, 53.456707767437813],
            [-2.570390193384721, 53.458459519159966],
            [-2.549076117633093, 53.461469916362972],
            [-2.55263908312996, 53.467797623060122],
            [-2.550485143538358, 53.468045766899458],
            [-2.536446754502982, 53.47148844483619],
            [-2.502706051579574, 53.479711423099751],
            [-2.496335740739638, 53.480913762705697],
            [-2.492743488643574, 53.479361034874636],
            [-2.489139033516649, 53.475808299891497],
            [-2.490440050269449, 53.47313157094186],
            [-2.491398728487728, 53.472131700083011],
            [-2.491678401098548, 53.469869929913614],
            [-2.491088476154899, 53.469009456376249],
            [-2.491125524452794, 53.467521699097112],
            [-2.492271405569271, 53.465138610539057],
            [-2.489863664493184, 53.462202061873093],
            [-2.489731317940363, 53.46012624738237],
            [-2.489608886498206, 53.459564964283992],
            [-2.488043664766246, 53.459005990526329],
            [-2.48689499147368, 53.458125305869309],
            [-2.486106748617884, 53.455174874559994],
            [-2.482826864108375, 53.453852479718073],
            [-2.478775171644644, 53.45003067392306],
            [-2.475987354109996, 53.448221633219958],
            [-2.476043398588744, 53.44744389811261],
            [-2.47826507677971, 53.444301601831114],
            [-2.478154091030088, 53.443401390492689],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000008",
        LAD13CDO: "00EX",
        LAD13NM: "Blackburn with Darwen",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.465809023362407, 53.780799054573492],
            [-2.462802315460153, 53.778557394182485],
            [-2.460793479957299, 53.778033041391502],
            [-2.459473922881959, 53.777258842136888],
            [-2.455548416392741, 53.774048907977914],
            [-2.453534001898552, 53.77297887863233],
            [-2.453126657544988, 53.771645690029743],
            [-2.4536045687626, 53.771081228051798],
            [-2.45233576277136, 53.769632651703738],
            [-2.446318083092179, 53.766575001540012],
            [-2.448061479217114, 53.765890793206211],
            [-2.448914665782392, 53.764384791446716],
            [-2.451126188372479, 53.762807164574504],
            [-2.452195082584808, 53.760922826026302],
            [-2.451146431185168, 53.757339629548802],
            [-2.451781372213735, 53.756439325655904],
            [-2.451043819263537, 53.755973824530216],
            [-2.450634697917091, 53.756292644073845],
            [-2.45024030170801, 53.756003811206782],
            [-2.448396977027506, 53.756093414910637],
            [-2.446801344864, 53.75537134007434],
            [-2.445473381699205, 53.754570951336156],
            [-2.445336706119872, 53.753694220635865],
            [-2.444642405801815, 53.753579055367418],
            [-2.444836149552769, 53.753112752360025],
            [-2.44192912755552, 53.751981123190149],
            [-2.442038856483579, 53.751454914205823],
            [-2.440684181160307, 53.750400207400759],
            [-2.441022238400327, 53.750244367913517],
            [-2.440068679850998, 53.749402992469648],
            [-2.439652107217772, 53.748284603681604],
            [-2.439371235370715, 53.747816454839715],
            [-2.440901774306184, 53.746707091367895],
            [-2.440770332862357, 53.746028972557731],
            [-2.441505726293347, 53.745882454618105],
            [-2.441988473284805, 53.745213756331296],
            [-2.441282568914289, 53.743398961744553],
            [-2.441831373470925, 53.742381281391246],
            [-2.440968368912946, 53.741629459431188],
            [-2.440830129628754, 53.740155916406636],
            [-2.439962952715171, 53.738998738032357],
            [-2.439867987342345, 53.73585863562699],
            [-2.439763046666633, 53.73335852317787],
            [-2.438587507864753, 53.732268078992952],
            [-2.433553994063424, 53.72760808095623],
            [-2.432297367237211, 53.720357394063868],
            [-2.4326705725278, 53.719660363512915],
            [-2.433870201140438, 53.719166169082335],
            [-2.432691628179072, 53.718907080422305],
            [-2.425873803180615, 53.714679225726442],
            [-2.418347212165547, 53.711408901910211],
            [-2.419655648815217, 53.709828699152105],
            [-2.419967717777766, 53.708305909297046],
            [-2.414016192778025, 53.704989330833868],
            [-2.413069387849411, 53.704677116382975],
            [-2.41099082703699, 53.705121094078393],
            [-2.408608310210262, 53.703123988951575],
            [-2.405508487845424, 53.698967612855839],
            [-2.403011055832029, 53.696926742432439],
            [-2.403136335953963, 53.696406804157498],
            [-2.40134404590697, 53.695070891446086],
            [-2.400151707472352, 53.692471007639107],
            [-2.39152924086916, 53.688606737854627],
            [-2.384796428211034, 53.688225000544371],
            [-2.383156566718565, 53.688564621029876],
            [-2.380894295510171, 53.68856644791915],
            [-2.374425751167199, 53.687238629074876],
            [-2.375708476147631, 53.681821026653466],
            [-2.375936387517499, 53.675625648598391],
            [-2.37406095362262, 53.671372915488021],
            [-2.371237973287006, 53.667064670267834],
            [-2.368974223923054, 53.663302914304495],
            [-2.362642990277198, 53.655972602576888],
            [-2.362971188587937, 53.654900211177385],
            [-2.365457847934846, 53.652012814078795],
            [-2.366141842825243, 53.650633725998112],
            [-2.36707006195745, 53.64759465632536],
            [-2.366680183737901, 53.646771630435566],
            [-2.367056987380064, 53.645045631902711],
            [-2.369209878308952, 53.645083948897629],
            [-2.370477137655461, 53.64469623364522],
            [-2.371517394796336, 53.643267472541694],
            [-2.372506172693218, 53.642012335419771],
            [-2.370107468378877, 53.638069444277249],
            [-2.368499161724533, 53.638002502943515],
            [-2.369002659211055, 53.637120102264639],
            [-2.368356143152675, 53.636864131777131],
            [-2.368561236468399, 53.635062253836274],
            [-2.370222507823532, 53.633246889079849],
            [-2.369835464716162, 53.632566776155045],
            [-2.370528600547972, 53.631764676052406],
            [-2.370329852246488, 53.631518114277512],
            [-2.371001526410304, 53.631374917374238],
            [-2.370922320028074, 53.631998948375951],
            [-2.376290423527622, 53.631797025292038],
            [-2.376645857349176, 53.631125382253238],
            [-2.379134003420554, 53.63085416635758],
            [-2.383833101667635, 53.631269719420807],
            [-2.385017468762245, 53.631802513027523],
            [-2.385950842603534, 53.632998540062253],
            [-2.397847752585748, 53.632191969669442],
            [-2.398730680924457, 53.631688383537913],
            [-2.40079551125469, 53.629047020853285],
            [-2.40025915749496, 53.628934665494249],
            [-2.40001389723487, 53.6282640616378],
            [-2.401211312001907, 53.624582946638796],
            [-2.403543933911883, 53.624389941843589],
            [-2.40444524885481, 53.625042143772589],
            [-2.405377439993249, 53.625127073647327],
            [-2.409823585595528, 53.625378876418004],
            [-2.413834000384925, 53.62467027569889],
            [-2.416244888620259, 53.623678592571828],
            [-2.419930125120618, 53.623593806546879],
            [-2.421698303757275, 53.623819484462423],
            [-2.424610838227678, 53.625530439544164],
            [-2.425855336717615, 53.625843302479154],
            [-2.426356379874579, 53.626499460536849],
            [-2.425884999020639, 53.628187342082704],
            [-2.426145657037688, 53.634994137160895],
            [-2.430655821238388, 53.638445663841196],
            [-2.432653427107585, 53.641365936531081],
            [-2.438726381244793, 53.646029406283013],
            [-2.442287855468908, 53.643766532350035],
            [-2.443033556227364, 53.643042015746666],
            [-2.44307969997328, 53.642393790673566],
            [-2.44526580223864, 53.642000977623432],
            [-2.445621650087141, 53.641607764253024],
            [-2.445984813893164, 53.641621691252794],
            [-2.445865250178615, 53.641901672090484],
            [-2.446745142718072, 53.641431898814552],
            [-2.448848175461927, 53.642055005130509],
            [-2.449941511693942, 53.641888211098767],
            [-2.449698697584586, 53.641528694156598],
            [-2.450272272066156, 53.641415981347564],
            [-2.448879694869706, 53.639042017238765],
            [-2.448616171608456, 53.637869136510382],
            [-2.449172410763562, 53.637837388327483],
            [-2.447969213654102, 53.63661949317553],
            [-2.449243526192614, 53.635563985187737],
            [-2.44968136463412, 53.633362008476709],
            [-2.448393458115501, 53.631846024086812],
            [-2.449252073632903, 53.631103965781939],
            [-2.450645758446516, 53.627789235281718],
            [-2.452117046388483, 53.628216920019],
            [-2.45190212776411, 53.627773710263384],
            [-2.453405437766395, 53.627943294159998],
            [-2.454918508507777, 53.626911086847663],
            [-2.45622210821036, 53.626791072578669],
            [-2.45681829827164, 53.625712898184439],
            [-2.458291185343045, 53.624615186401449],
            [-2.456484551348427, 53.623929994354597],
            [-2.455266392996587, 53.624113503515666],
            [-2.454264379499231, 53.623447684039185],
            [-2.453558331429278, 53.623449464128242],
            [-2.453180536848127, 53.622761493069],
            [-2.454349847854458, 53.622396626835837],
            [-2.453782723533638, 53.621958352506596],
            [-2.455024878942404, 53.621340631858018],
            [-2.455730745797394, 53.621465578860068],
            [-2.457525985100464, 53.62096886365989],
            [-2.459701851983658, 53.62002663689919],
            [-2.463410624642759, 53.621987957890681],
            [-2.463468517423353, 53.62230412204886],
            [-2.466021286208361, 53.621683000043042],
            [-2.467085125874208, 53.620952595665564],
            [-2.470444526969013, 53.619875215785385],
            [-2.472994751690867, 53.616615883147169],
            [-2.479197211404846, 53.617019941012977],
            [-2.494828925453383, 53.622127285658905],
            [-2.507932225530618, 53.625388163419771],
            [-2.510412870873879, 53.626345648322086],
            [-2.511324724074385, 53.626979026613256],
            [-2.514823694790641, 53.629220988688751],
            [-2.518332550840315, 53.630265561810269],
            [-2.520063422173932, 53.630465681072373],
            [-2.520290790793092, 53.631738337080044],
            [-2.523761636280485, 53.639084621492515],
            [-2.524550331337734, 53.641577218978867],
            [-2.524022732724619, 53.64257633325407],
            [-2.521759301503389, 53.644735325811155],
            [-2.521495430369788, 53.645416890056417],
            [-2.522014646848544, 53.647404634707506],
            [-2.523248796197585, 53.650313249001719],
            [-2.525065714420528, 53.652499332421691],
            [-2.525298391099711, 53.65369015809754],
            [-2.526615452813986, 53.655084744757616],
            [-2.52862413231897, 53.656240771010751],
            [-2.528939329476894, 53.660265228965635],
            [-2.532346395824989, 53.664886275859409],
            [-2.52916080790163, 53.667191511021166],
            [-2.528343862720986, 53.669395446949288],
            [-2.526474052921559, 53.671171676344613],
            [-2.526226822719582, 53.673559145828833],
            [-2.525551794174679, 53.674660476063515],
            [-2.524589704981193, 53.675079054528496],
            [-2.520920454442085, 53.675212823974],
            [-2.519172949263054, 53.676271142038424],
            [-2.519243094870752, 53.676804739749322],
            [-2.518348070059965, 53.677639132609407],
            [-2.517767477807747, 53.680166442953634],
            [-2.517821883227784, 53.681392203757653],
            [-2.518467070844725, 53.682396094178259],
            [-2.51740296745061, 53.684886844168744],
            [-2.517931898601524, 53.686654340342166],
            [-2.516998213995048, 53.687442149716631],
            [-2.516589883468865, 53.688967417239176],
            [-2.515436934357282, 53.68978671983151],
            [-2.516010819101414, 53.690897889046227],
            [-2.515377964376906, 53.691652929290832],
            [-2.515700455156969, 53.692390372662828],
            [-2.517507970611985, 53.693199603184269],
            [-2.517607143462949, 53.693629710343181],
            [-2.518473412375673, 53.69350731774194],
            [-2.520155305984733, 53.694437494450739],
            [-2.521163084020794, 53.694490636750423],
            [-2.521360752415304, 53.695044349486835],
            [-2.522355651858759, 53.695034620303929],
            [-2.523386360293599, 53.69546964332433],
            [-2.525298741658708, 53.694948041095287],
            [-2.526467188499348, 53.695010320856589],
            [-2.529060363637353, 53.696005571804044],
            [-2.529993994302179, 53.695939424120176],
            [-2.530860270804557, 53.696418254162403],
            [-2.531934189636851, 53.696426970397141],
            [-2.532719747916171, 53.697347467344848],
            [-2.535295378729452, 53.698250082886716],
            [-2.534946387400823, 53.698797227626855],
            [-2.536416521326339, 53.699347920460468],
            [-2.537466765132014, 53.701058163487723],
            [-2.53958534857471, 53.702084977246933],
            [-2.541038704987936, 53.704501642921336],
            [-2.542213714963844, 53.705169545681855],
            [-2.542780725727499, 53.706616775977601],
            [-2.54157995821848, 53.707956961988053],
            [-2.5420207076461, 53.708891539426006],
            [-2.543263618135, 53.709880003408706],
            [-2.541662937232736, 53.71099550004886],
            [-2.540075827985955, 53.711284897708133],
            [-2.540912780504585, 53.712042421306251],
            [-2.538975134920654, 53.713358940893187],
            [-2.53947047259509, 53.714651910376752],
            [-2.53989926719564, 53.716543791567183],
            [-2.539599240521495, 53.717608447351729],
            [-2.539902636699952, 53.71810772251029],
            [-2.541427338249288, 53.718837871504448],
            [-2.540802884487153, 53.719654124346718],
            [-2.543774606542492, 53.721303484706411],
            [-2.54454088267661, 53.72102766103648],
            [-2.546339305849183, 53.72123159231495],
            [-2.547279521099444, 53.721981409202151],
            [-2.546177515329454, 53.724883333149421],
            [-2.550747235124485, 53.726528818253811],
            [-2.555184834126398, 53.725252681616219],
            [-2.556432423587371, 53.726649948927133],
            [-2.55689425944796, 53.727419888043372],
            [-2.555859957421575, 53.728980547539187],
            [-2.556538954082721, 53.730812782013146],
            [-2.556496321809111, 53.732289738439114],
            [-2.55707495914705, 53.732602534012081],
            [-2.557157425832212, 53.734596627515664],
            [-2.559362826452154, 53.735148110792245],
            [-2.560731686405182, 53.737764461112462],
            [-2.561607482939506, 53.737843049707699],
            [-2.563618394333939, 53.739251040720497],
            [-2.563261012813209, 53.739848637496657],
            [-2.563539967774131, 53.740754232189445],
            [-2.56286805853078, 53.741528576074593],
            [-2.564593593710525, 53.742443541473122],
            [-2.564662593146314, 53.743631452204902],
            [-2.561753833757898, 53.744721908975741],
            [-2.562469771578546, 53.746102727146976],
            [-2.561603784426354, 53.746886063376607],
            [-2.559168081874817, 53.747538312017525],
            [-2.558137543148342, 53.749070205400159],
            [-2.556253605136443, 53.749030430982373],
            [-2.555123428987347, 53.749917410876272],
            [-2.55454078852687, 53.749547999460475],
            [-2.55398918630898, 53.749692564130591],
            [-2.553801850573397, 53.750437649310946],
            [-2.552651759313997, 53.751200661067294],
            [-2.552827326425957, 53.752097767349163],
            [-2.552011132181917, 53.753000345457394],
            [-2.552080244165061, 53.754222415058265],
            [-2.551640625659232, 53.754584864558609],
            [-2.552163832636224, 53.755160392290087],
            [-2.551300063386402, 53.756373275109404],
            [-2.549807170326689, 53.757150418549926],
            [-2.549957254547993, 53.757493076752347],
            [-2.549256430215999, 53.758067038278611],
            [-2.547698966005896, 53.758785129303931],
            [-2.546788096823354, 53.758723675998034],
            [-2.546574140348486, 53.758950254410088],
            [-2.546433272565217, 53.758731586365222],
            [-2.545726183206967, 53.75941251437677],
            [-2.543852125696597, 53.759233183065064],
            [-2.542435683023987, 53.759605420792937],
            [-2.540565502952401, 53.759609378483738],
            [-2.539462361044949, 53.759931631220063],
            [-2.539079675873441, 53.759660114314933],
            [-2.538457705595693, 53.75989120951251],
            [-2.536569425547866, 53.759547345490965],
            [-2.536341900054743, 53.759071094357722],
            [-2.532347681486298, 53.758671857523112],
            [-2.531502193747648, 53.75958161446362],
            [-2.529069652477258, 53.759856631044457],
            [-2.528781029830475, 53.75946062985836],
            [-2.525746761429525, 53.759651048141905],
            [-2.524206225552402, 53.760425383709645],
            [-2.523251736907329, 53.760393603212876],
            [-2.52077268559777, 53.76218854568706],
            [-2.519811346128888, 53.762341922782035],
            [-2.518697423771673, 53.763899896831774],
            [-2.515598974176576, 53.764700628217078],
            [-2.515224816996356, 53.765240176226733],
            [-2.515512884167883, 53.76559801240073],
            [-2.514837463067886, 53.766189637630148],
            [-2.511256034750998, 53.766715496323734],
            [-2.509414080819754, 53.769201362175615],
            [-2.507664872073689, 53.769584488231324],
            [-2.504692630449447, 53.76970220405471],
            [-2.50199138530386, 53.768883049794539],
            [-2.498959870200917, 53.768605395395582],
            [-2.494763365485678, 53.770143582228975],
            [-2.491214465638895, 53.771036319529728],
            [-2.486695113472923, 53.772779574955671],
            [-2.484975273742505, 53.772977095136248],
            [-2.484343328998209, 53.772780115411322],
            [-2.480827813048269, 53.773977105243922],
            [-2.480184152836343, 53.775942683889625],
            [-2.478886520172351, 53.777652016723074],
            [-2.477954886605456, 53.779928820967228],
            [-2.477004793360105, 53.780453914769467],
            [-2.477088764387924, 53.781033311346434],
            [-2.473708306630196, 53.779884563121819],
            [-2.469717076393857, 53.781776982634753],
            [-2.467048727943796, 53.781468334868478],
            [-2.465809023362407, 53.780799054573492],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000009",
        LAD13CDO: "00EY",
        LAD13NM: "Blackpool",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-3.01936466966735, 53.838927254546704],
            [-3.019116038312707, 53.837075075722851],
            [-3.016494296471631, 53.837500898784938],
            [-3.017361785120101, 53.839308286434793],
            [-3.017340372963456, 53.840368191137401],
            [-3.015561044873471, 53.840696062816932],
            [-3.013283214264992, 53.839011141682604],
            [-3.012611827815777, 53.837765637473289],
            [-3.011541431174332, 53.83717604723266],
            [-3.010195966290091, 53.836458433882932],
            [-3.009854652637481, 53.835031266166794],
            [-3.007645369309771, 53.834026976184802],
            [-3.00720771639461, 53.832886438815777],
            [-3.0088124517127, 53.830363411245791],
            [-3.010836787444741, 53.83050905551621],
            [-3.012119064347988, 53.829496042399519],
            [-3.013778482765985, 53.829191703893486],
            [-3.010048774670433, 53.826428699975111],
            [-3.010658072057928, 53.826179084175074],
            [-3.011672070231994, 53.825575506871623],
            [-3.010101745918867, 53.824841812523736],
            [-3.006141812940846, 53.824362765138005],
            [-3.002769525486553, 53.823168596813879],
            [-3.002235037708708, 53.821906608558592],
            [-2.99998499031691, 53.820046829901294],
            [-2.998546989883005, 53.820388684239454],
            [-2.998227983924401, 53.818982868007332],
            [-2.995652280555447, 53.818749012262117],
            [-2.994514519168162, 53.817657382463622],
            [-2.993307637374838, 53.815888593949531],
            [-2.994818802592192, 53.814985322467628],
            [-2.993548233205282, 53.812897079274578],
            [-2.993985033097678, 53.81216540358885],
            [-2.992313250133077, 53.808195616037324],
            [-2.986678897725443, 53.802170484608332],
            [-2.989541506358991, 53.800448093792831],
            [-2.986468447555972, 53.798122064072615],
            [-2.984829483169825, 53.795109158309977],
            [-2.983360916028239, 53.793789138176258],
            [-2.984614241252532, 53.793762672150898],
            [-2.99056404606893, 53.793650740345619],
            [-2.999349823726924, 53.794250155285006],
            [-2.999503554531838, 53.793501041020022],
            [-3.002207303409066, 53.792738725438873],
            [-3.001333520824565, 53.792063809068779],
            [-3.003083753865704, 53.791107195096551],
            [-3.003890527702989, 53.791327847930006],
            [-3.003991499042336, 53.787801758261153],
            [-3.006125282249297, 53.787977118849277],
            [-3.001436756099997, 53.781614847330843],
            [-3.001028525219459, 53.781679378239438],
            [-3.000149222344967, 53.780825630059837],
            [-3.000693318746014, 53.780603569885365],
            [-2.996481211879469, 53.774489698785906],
            [-3.015470297531807, 53.774566719491304],
            [-3.015799635901836, 53.77513469319063],
            [-3.028804324649077, 53.773102049169367],
            [-3.032768434665631, 53.780734186406427],
            [-3.039774388834993, 53.779591412538629],
            [-3.056853159997164, 53.776564845713992],
            [-3.057395216853606, 53.776456697907136],
            [-3.057923662557439, 53.777979172181759],
            [-3.059042780309614, 53.783489991603709],
            [-3.058790362198885, 53.784013550851746],
            [-3.058955399277834, 53.790625782604238],
            [-3.058519032414162, 53.794033553356726],
            [-3.05810062770412, 53.794660144580654],
            [-3.056695050843781, 53.795091402131192],
            [-3.061013759248913, 53.795314822109873],
            [-3.060969755844102, 53.795676546164529],
            [-3.056920456962765, 53.795482209146542],
            [-3.057150265835159, 53.795806462601625],
            [-3.058081223910228, 53.795758700942997],
            [-3.057318034924215, 53.796440464391615],
            [-3.057336239902313, 53.79849146072911],
            [-3.056557691638078, 53.799591315492393],
            [-3.056454663293489, 53.801713488652574],
            [-3.056914261858916, 53.802660411874804],
            [-3.0562492647776, 53.80369005178197],
            [-3.055842323767679, 53.80665082129449],
            [-3.05634068733186, 53.807325955016481],
            [-3.055942048175774, 53.807839108696783],
            [-3.056308704702948, 53.811368319967187],
            [-3.056629029377779, 53.819150343187459],
            [-3.056994212557411, 53.821045471713241],
            [-3.056092201809852, 53.822447516547918],
            [-3.056478121053809, 53.828727890938687],
            [-3.056542971958633, 53.832149191663831],
            [-3.055940819817625, 53.833762513023579],
            [-3.05582817710286, 53.837438845350391],
            [-3.056553806404701, 53.837951083962722],
            [-3.056636767851852, 53.838650546200363],
            [-3.055753709671047, 53.839250654694517],
            [-3.055735765317873, 53.841194993575357],
            [-3.054270445432488, 53.849895116909856],
            [-3.051406576432526, 53.857173834486161],
            [-3.049231140627805, 53.865993341139209],
            [-3.047956614746288, 53.875718138301387],
            [-3.042879425767669, 53.875418119766863],
            [-3.031327734418669, 53.874706317415438],
            [-3.027148861528229, 53.870151845502079],
            [-3.026902639670991, 53.869227260729929],
            [-3.019755849309903, 53.868585329692444],
            [-3.021427841357762, 53.865490822563743],
            [-3.022778231549738, 53.86430724592001],
            [-3.022844213591563, 53.862711261697669],
            [-3.022624956444829, 53.861701947734034],
            [-3.021730403255713, 53.86043502903415],
            [-3.020636853397055, 53.859423266626052],
            [-3.020062562364033, 53.85931669597894],
            [-3.014019701260791, 53.850305022994739],
            [-3.014349892795001, 53.850192574166904],
            [-3.015088300622427, 53.849925667350405],
            [-3.015230187750637, 53.849004962986186],
            [-3.018022686145367, 53.848256839594512],
            [-3.017380656234537, 53.847607038656179],
            [-3.017943038568152, 53.847355089143107],
            [-3.0186354250314, 53.847011251126048],
            [-3.01781897173277, 53.845186363281968],
            [-3.015235596170921, 53.842759838885513],
            [-3.020469875754556, 53.840754171439919],
            [-3.020004594366602, 53.838994619859292],
            [-3.01936466966735, 53.838927254546704],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000010",
        LAD13CDO: "00FA",
        LAD13NM: "Kingston upon Hull, City of",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-0.347764504602435, 53.780257236474824],
              [-0.349653393370232, 53.778418012069245],
              [-0.348398796462136, 53.775428936110579],
              [-0.34697816324888, 53.774970688338904],
              [-0.343828177725996, 53.775422515789948],
              [-0.342206470184574, 53.77521582541884],
              [-0.34157225100261, 53.77467399902087],
              [-0.342197948944516, 53.772736507524122],
              [-0.340661064748191, 53.771228443742352],
              [-0.339407442035385, 53.770619600763979],
              [-0.337780001895508, 53.770332766313437],
              [-0.335618151176243, 53.770322548064364],
              [-0.333353190321954, 53.770770202416855],
              [-0.332399957402989, 53.770548398478724],
              [-0.330995913764258, 53.769603876797632],
              [-0.330177021929504, 53.767412605191822],
              [-0.328338467642833, 53.765990067140848],
              [-0.328223131428411, 53.764921446189938],
              [-0.329524409733569, 53.764436194148615],
              [-0.332352730778158, 53.764504339216757],
              [-0.333500224314569, 53.763954879732879],
              [-0.336530036210233, 53.759999488350019],
              [-0.336154184791698, 53.758570390343209],
              [-0.33412720310976, 53.756969134814589],
              [-0.331056288722061, 53.756719665395742],
              [-0.330057183337842, 53.756285956147089],
              [-0.328910103855542, 53.753886941326961],
              [-0.329232969155299, 53.753089608399762],
              [-0.332373591536007, 53.752376457804765],
              [-0.333190033543348, 53.751752280404546],
              [-0.332787975857747, 53.75037673938683],
              [-0.329792040514715, 53.747331744865065],
              [-0.328641573182015, 53.745177181023692],
              [-0.328853269303237, 53.744237167909723],
              [-0.330763585452806, 53.741568833386673],
              [-0.33108312809587, 53.739742186873535],
              [-0.331690405076742, 53.739722775800402],
              [-0.332024067170881, 53.738916595767144],
              [-0.332744325350371, 53.738877177835164],
              [-0.332628877812633, 53.738269700583594],
              [-0.335159777007355, 53.737724185873631],
              [-0.335598247952165, 53.738215692235109],
              [-0.336572350771972, 53.738121346970502],
              [-0.336932741357441, 53.73794117169583],
              [-0.336655300608742, 53.737210995406137],
              [-0.339708729722487, 53.737253337637227],
              [-0.339714983764485, 53.736979254378355],
              [-0.340831605822809, 53.736614477089894],
              [-0.339240865359615, 53.736583453418497],
              [-0.339445597688951, 53.735928282614374],
              [-0.35396398381801, 53.731933338083174],
              [-0.363264816995605, 53.729350504235178],
              [-0.365428628614556, 53.729099578272844],
              [-0.365157262709958, 53.728865753968222],
              [-0.365930108414285, 53.728598530660058],
              [-0.383141495666759, 53.724599916113533],
              [-0.396181866204552, 53.722191693782015],
              [-0.396699186745946, 53.723174835547816],
              [-0.400383279336585, 53.722708098828221],
              [-0.401074600076659, 53.722862949530715],
              [-0.402878620979196, 53.722412383744349],
              [-0.403847475648987, 53.722642830254465],
              [-0.404337921504929, 53.72213878092375],
              [-0.414317384907056, 53.72059119791907],
              [-0.41603347439492, 53.719892970257767],
              [-0.419201377246919, 53.719558163550218],
              [-0.420724366536923, 53.723120847867456],
              [-0.420170919893624, 53.723248388548107],
              [-0.420169968331907, 53.723880308967345],
              [-0.421088999858205, 53.72483717538276],
              [-0.421744733423048, 53.727402309294071],
              [-0.422091714313962, 53.727365529025604],
              [-0.422575175418255, 53.730445260164451],
              [-0.422122884790407, 53.731755306365223],
              [-0.419802583246548, 53.734325272305711],
              [-0.419728302851427, 53.735335563643517],
              [-0.417140926528506, 53.735844357569334],
              [-0.415659407590138, 53.738754320329093],
              [-0.413906500320469, 53.740449845303864],
              [-0.413585487631482, 53.740397954396649],
              [-0.411496156756672, 53.742445857214349],
              [-0.410671750046571, 53.744160829761562],
              [-0.409987796025337, 53.744242547687321],
              [-0.410930902288042, 53.746335126657691],
              [-0.407642696868184, 53.746547674104711],
              [-0.402049157063445, 53.7481245231968],
              [-0.401933749800067, 53.749966644277471],
              [-0.403638986421979, 53.749992064567849],
              [-0.422336566528694, 53.750540764267313],
              [-0.42118498919129, 53.752211047831103],
              [-0.421181748582981, 53.753753525566594],
              [-0.418198461064881, 53.760611487723331],
              [-0.408567145230485, 53.760567608387198],
              [-0.407930786009009, 53.762672484556006],
              [-0.403619071858887, 53.764876789518084],
              [-0.400935214914695, 53.765755206604759],
              [-0.398522756843873, 53.767193612667207],
              [-0.398260900225263, 53.767575744823034],
              [-0.394203884318159, 53.767492705638169],
              [-0.390107842813564, 53.768469714792623],
              [-0.388104129789175, 53.769324618053247],
              [-0.388290964672081, 53.770943364056031],
              [-0.387401195051897, 53.770721054858612],
              [-0.388080259458549, 53.776324080121547],
              [-0.389727104726004, 53.77655475604741],
              [-0.390535789438632, 53.779653353955361],
              [-0.389928396506805, 53.779582275661788],
              [-0.390520675661298, 53.782467621960052],
              [-0.392213384233798, 53.791112616638856],
              [-0.370443432426169, 53.791980136294399],
              [-0.365106881761348, 53.792221215206283],
              [-0.359597103321431, 53.79335949348107],
              [-0.359960667377821, 53.792796357026631],
              [-0.359646267964858, 53.792021693041526],
              [-0.356431190667594, 53.789954227294778],
              [-0.353787715529463, 53.788881523311915],
              [-0.350734343667095, 53.788432359158236],
              [-0.348096692134901, 53.786947008440706],
              [-0.347927207923872, 53.78623813032555],
              [-0.34839970817873, 53.785531804144192],
              [-0.351264987898325, 53.784577942316673],
              [-0.3519514674049, 53.783560825154446],
              [-0.351762118298518, 53.783047641693891],
              [-0.348484714287387, 53.781821375307572],
              [-0.347620345709576, 53.781029211848441],
              [-0.347764504602435, 53.780257236474824],
            ],
          ],
          [
            [
              [-0.316126597078834, 53.813250334435523],
              [-0.303877052063617, 53.798449717801752],
              [-0.294381535675168, 53.792973679133354],
              [-0.288884745721839, 53.789527123278894],
              [-0.278242989031609, 53.782039603986036],
              [-0.274041709436318, 53.783407582703376],
              [-0.272724828437203, 53.782616445614003],
              [-0.268318009327145, 53.785025810285696],
              [-0.26565125526636, 53.782333648357771],
              [-0.262907407602781, 53.782949239965248],
              [-0.257728282217558, 53.783248042954412],
              [-0.257498851563099, 53.781788453530304],
              [-0.258095156147692, 53.781719811166141],
              [-0.258112717359148, 53.781149250176398],
              [-0.252317316848999, 53.781307620032003],
              [-0.25278487438348, 53.775314115445312],
              [-0.253244126510768, 53.773939160518786],
              [-0.254270405932148, 53.77378241488762],
              [-0.254495984365086, 53.772588331844041],
              [-0.252112812924403, 53.772350451512267],
              [-0.252135337283807, 53.769982111735175],
              [-0.254357625381897, 53.769998312236595],
              [-0.25434939092382, 53.7689167852851],
              [-0.254719417359588, 53.766361134542869],
              [-0.254427406371973, 53.765500206500469],
              [-0.253358480894973, 53.76453267347415],
              [-0.254194137620593, 53.763479619646077],
              [-0.250926361563762, 53.76197931695318],
              [-0.251526397282562, 53.760536304322791],
              [-0.249885523157845, 53.759610733741212],
              [-0.245473182556744, 53.759450043307417],
              [-0.244575599505318, 53.756310429048987],
              [-0.243377122039825, 53.755550355430188],
              [-0.241428267625817, 53.755034557943766],
              [-0.242622319178039, 53.750994305097166],
              [-0.244737048723507, 53.7502558110682],
              [-0.245604978065971, 53.749627582558858],
              [-0.244637098143351, 53.748105905951306],
              [-0.246234677602475, 53.746671230778162],
              [-0.244875119677171, 53.744942462470604],
              [-0.245561094913782, 53.743765020218348],
              [-0.247038240448176, 53.742705223176813],
              [-0.24730098022318, 53.74112874711961],
              [-0.246644054316215, 53.740080873022166],
              [-0.244186482230034, 53.739635877388785],
              [-0.243063646792882, 53.738891286610709],
              [-0.243303262044652, 53.73816306953556],
              [-0.244067346437923, 53.737838067829948],
              [-0.245235694807855, 53.736470828210891],
              [-0.250174805770063, 53.733272742437272],
              [-0.255913911974457, 53.736255459693183],
              [-0.259076005413206, 53.736380545679268],
              [-0.25996444135077, 53.736087813597088],
              [-0.273245507602103, 53.740093171849182],
              [-0.273237121003911, 53.740889499885071],
              [-0.278476442447619, 53.740916335934685],
              [-0.286000721844111, 53.741952775655186],
              [-0.286741279840571, 53.742535080794759],
              [-0.287286247428437, 53.742110487751823],
              [-0.295266154623516, 53.742390566254919],
              [-0.297194406903585, 53.742854855978436],
              [-0.299518220404408, 53.74231164199535],
              [-0.307198536083151, 53.741936753248865],
              [-0.307558096709581, 53.742347245114686],
              [-0.308087335522348, 53.742233363491579],
              [-0.307966821223727, 53.741836136633594],
              [-0.309279487922222, 53.741735106062016],
              [-0.309872955850997, 53.742023041575841],
              [-0.310396793303168, 53.741589956641754],
              [-0.313018704011945, 53.741396769092255],
              [-0.317642442304646, 53.740474751866415],
              [-0.318045939966771, 53.741230114962391],
              [-0.319283698185632, 53.741143205332726],
              [-0.319876653563485, 53.74091330588238],
              [-0.319605511109314, 53.740298238724918],
              [-0.320046243144818, 53.740006875232012],
              [-0.320979413174908, 53.740941345704407],
              [-0.320452609025003, 53.739930769311691],
              [-0.320848848691157, 53.740146669108753],
              [-0.321439885422366, 53.739736052258046],
              [-0.331282670008799, 53.738303100080778],
              [-0.330366758197055, 53.740142115448172],
              [-0.32980624533089, 53.740320381275041],
              [-0.329468106015754, 53.742384978762139],
              [-0.328384148382739, 53.744136239183582],
              [-0.326953504398862, 53.744610681374112],
              [-0.3278591035731, 53.744832767829678],
              [-0.328898984684191, 53.747135917028963],
              [-0.332306302160947, 53.750169579807498],
              [-0.332723742283751, 53.751462636242323],
              [-0.332002456124316, 53.752249939771417],
              [-0.329210386812764, 53.75281782068987],
              [-0.328530583646221, 53.753742317346344],
              [-0.329031022168798, 53.755617243999026],
              [-0.32972560477441, 53.756423364929034],
              [-0.331045150799517, 53.756885809808992],
              [-0.33373850655604, 53.757131829360944],
              [-0.335886877293484, 53.758732080024835],
              [-0.336160001767292, 53.759689620460122],
              [-0.332756998937288, 53.764103652998962],
              [-0.331638337519397, 53.764384731120458],
              [-0.32851912484556, 53.764344874362791],
              [-0.327798216629365, 53.764962263190334],
              [-0.328003668092348, 53.766053715508789],
              [-0.32995800325432, 53.767693611310527],
              [-0.330436906021491, 53.769466649240499],
              [-0.332146798073493, 53.770733648669783],
              [-0.333348016030685, 53.770939126300043],
              [-0.336424977320288, 53.770486562148655],
              [-0.339036836990201, 53.770782563431069],
              [-0.340935476007685, 53.771734733854814],
              [-0.341773210495216, 53.772578717224683],
              [-0.341904370501808, 53.773332022133033],
              [-0.34124382003161, 53.774536416349676],
              [-0.341795114192111, 53.77525688005489],
              [-0.343671331245252, 53.775628896548234],
              [-0.347073885199464, 53.775199432701477],
              [-0.348111321589272, 53.775561610041478],
              [-0.349277713137153, 53.778554868127962],
              [-0.34730353238831, 53.780555613889504],
              [-0.348030118925336, 53.781841181141488],
              [-0.350987058635819, 53.782878776545374],
              [-0.351671562225106, 53.783615406648032],
              [-0.350973178753712, 53.784510106824371],
              [-0.347962853582235, 53.785601294378033],
              [-0.347627625860809, 53.786367939810546],
              [-0.348047961449621, 53.787298709670239],
              [-0.35070305709844, 53.788649464827486],
              [-0.354679596854781, 53.789404344552899],
              [-0.357928491001768, 53.791146916763971],
              [-0.359527924158039, 53.792331096056664],
              [-0.35882781111743, 53.793892806215489],
              [-0.359010224673047, 53.794509256211093],
              [-0.360495150777622, 53.795285548101155],
              [-0.363336240272394, 53.79578190098524],
              [-0.364935441924459, 53.798463579262588],
              [-0.365534404455069, 53.802271417602022],
              [-0.365184333217762, 53.803346231129261],
              [-0.361817228930922, 53.80495516976525],
              [-0.358461586491143, 53.80513670976859],
              [-0.356744815568872, 53.805582428241841],
              [-0.354916387714923, 53.80674571564608],
              [-0.353656819623156, 53.808504670232118],
              [-0.348586124762277, 53.808880815305109],
              [-0.348756657567412, 53.809488127369434],
              [-0.34573702327589, 53.809726974810516],
              [-0.346076065551867, 53.810605388047634],
              [-0.338623264498426, 53.810949139971285],
              [-0.338553782917971, 53.810555354828821],
              [-0.337592506232432, 53.810666982908742],
              [-0.333870900996058, 53.811329973238671],
              [-0.324289249959372, 53.812147531682399],
              [-0.316126597078834, 53.813250334435523],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000011",
        LAD13CDO: "00FB",
        LAD13NM: "East Riding of Yorkshire",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-0.726499832257298, 53.704139426742387],
              [-0.726426636122586, 53.703753929825858],
              [-0.725997727587491, 53.704061279905119],
              [-0.7196433477651, 53.703440735212865],
              [-0.712486567991683, 53.70231415311104],
              [-0.708136848054241, 53.701245306834046],
              [-0.704934773379893, 53.700793657605892],
              [-0.703140765513319, 53.699824137004867],
              [-0.699970222088512, 53.698845952110808],
              [-0.699201963323775, 53.698265925786934],
              [-0.699574935145527, 53.698057839198505],
              [-0.69849138550683, 53.697633487105335],
              [-0.699891628842205, 53.696136774888089],
              [-0.705585397254197, 53.696769217022677],
              [-0.709972842787887, 53.695843953784454],
              [-0.705617806688789, 53.696601476782625],
              [-0.699719625052848, 53.695672883900727],
              [-0.700325843045988, 53.695021481906593],
              [-0.700731701741127, 53.690005637904697],
              [-0.700155913897377, 53.687409715831343],
              [-0.700601906241977, 53.687433430929474],
              [-0.69995418279309, 53.686977861519786],
              [-0.701441664129456, 53.686372868611151],
              [-0.699777989402975, 53.686749431165808],
              [-0.698487749070803, 53.684590688505352],
              [-0.701434619901516, 53.683697721595493],
              [-0.704620619782106, 53.681126360712845],
              [-0.704811280053672, 53.68053785727902],
              [-0.703620532228417, 53.679672836076676],
              [-0.702449735076433, 53.677470482155506],
              [-0.704811614549181, 53.677812451425645],
              [-0.70900650548418, 53.677228520942641],
              [-0.713836277260195, 53.677418008583409],
              [-0.715169331858182, 53.678288049857706],
              [-0.717687591409777, 53.678221552160231],
              [-0.71799038463934, 53.679966823526513],
              [-0.719430717318168, 53.679451891227949],
              [-0.72176266886375, 53.679340169349921],
              [-0.72362478067287, 53.677892155402532],
              [-0.722697164134173, 53.676576194242287],
              [-0.723185062659528, 53.673708574387035],
              [-0.73053756236772, 53.673460434165889],
              [-0.746601839681086, 53.669591855885159],
              [-0.746207816881743, 53.668893797273455],
              [-0.754927650043602, 53.666386105569593],
              [-0.758972752658944, 53.664346287767493],
              [-0.753237462578534, 53.662678625021798],
              [-0.774493962764128, 53.656188805053667],
              [-0.774464737549376, 53.657874800707631],
              [-0.771141299504525, 53.660020522581917],
              [-0.769890148947374, 53.661619365540396],
              [-0.772130289683504, 53.664054940329684],
              [-0.778299726079362, 53.661418706567808],
              [-0.781212703236377, 53.660588162041137],
              [-0.788737765147398, 53.659189452031121],
              [-0.793496073951945, 53.657262654017195],
              [-0.799783584292293, 53.653955949255703],
              [-0.801536392260845, 53.652706089280372],
              [-0.803244125638835, 53.650960472835941],
              [-0.804220152659571, 53.647692921391808],
              [-0.805800124804572, 53.646335206003741],
              [-0.814174615426755, 53.643386524842676],
              [-0.816573097623616, 53.644816095336893],
              [-0.820088747096581, 53.645142025188569],
              [-0.820878095253856, 53.643851825080141],
              [-0.8271541287836, 53.642717060734618],
              [-0.829147660151985, 53.643215670967038],
              [-0.82961582989456, 53.641877329441087],
              [-0.833521903688662, 53.640766684479154],
              [-0.841211539041099, 53.635486757422306],
              [-0.848258673483347, 53.633016334490776],
              [-0.865333161626515, 53.637688111132221],
              [-0.922168920287228, 53.652500690776435],
              [-0.939871492659419, 53.657560206394386],
              [-0.946840219279835, 53.659121889388409],
              [-0.947620894635693, 53.658642467596422],
              [-0.957791732944798, 53.658505792224929],
              [-0.989979379724998, 53.6589631311194],
              [-0.991007778785221, 53.673563840143458],
              [-0.946076139105117, 53.679318846817097],
              [-0.894360078713455, 53.692145061649491],
              [-0.885446656644267, 53.694357267143545],
              [-0.868539616671189, 53.696515163050307],
              [-0.86674184896658, 53.696230310822855],
              [-0.865592827067823, 53.695307989552532],
              [-0.863507845523662, 53.692305799561659],
              [-0.860431034917209, 53.689087401319348],
              [-0.861337114022012, 53.688844334497162],
              [-0.860305527145127, 53.688968456104895],
              [-0.858291929181106, 53.687047284581574],
              [-0.856423597292537, 53.685751277453406],
              [-0.852961995765333, 53.684806732851733],
              [-0.850331624487096, 53.684759046571308],
              [-0.848099152898189, 53.685056698510529],
              [-0.846378899284086, 53.685559689931004],
              [-0.843812419432753, 53.686937179831318],
              [-0.843066207258416, 53.686704360120757],
              [-0.842317730795486, 53.687824308445599],
              [-0.83748069597378, 53.690750919872436],
              [-0.833870314141848, 53.693437600720998],
              [-0.82917195905178, 53.697708135911853],
              [-0.825089762342603, 53.700162529343096],
              [-0.819507130857102, 53.702355721755033],
              [-0.811880540279638, 53.704325261528155],
              [-0.805937687764983, 53.704374948113475],
              [-0.799005087942083, 53.702898930795691],
              [-0.793647791467359, 53.700542207996619],
              [-0.790655727698488, 53.69875924813303],
              [-0.788957680324528, 53.698128167891326],
              [-0.789044500711061, 53.697794665641425],
              [-0.788632786677168, 53.697943311578101],
              [-0.78871403467749, 53.697645708026933],
              [-0.788331641531158, 53.69772273972152],
              [-0.784361879001456, 53.696033996933913],
              [-0.781266102570205, 53.695296930977612],
              [-0.77814203946041, 53.69517432434246],
              [-0.775037801726814, 53.694682402328347],
              [-0.773673323601756, 53.694938104936703],
              [-0.771657225729936, 53.694841046932467],
              [-0.766856211984103, 53.695975542189132],
              [-0.753889127182242, 53.700249449540301],
              [-0.749260983266494, 53.702464588977463],
              [-0.746251839403121, 53.703302349764414],
              [-0.73801394648791, 53.704061705575086],
              [-0.730581993337762, 53.704307689821206],
              [-0.726499832257298, 53.704139426742387],
            ],
          ],
          [
            [
              [-0.860697860777134, 53.723740183214375],
              [-0.86430536938668, 53.723500270688156],
              [-0.86173217443273, 53.726240751001406],
              [-0.859305893901041, 53.725276577076663],
              [-0.859690850925666, 53.724156665659294],
              [-0.860697860777134, 53.723740183214375],
            ],
          ],
          [
            [
              [-0.84698005859924, 53.725297952357828],
              [-0.84485493248823, 53.719773759603925],
              [-0.844126233604117, 53.71672498389897],
              [-0.844038325204949, 53.713298570397349],
              [-0.844101984182222, 53.711694713993317],
              [-0.844928200051839, 53.710184501033787],
              [-0.847179833470665, 53.708570253210155],
              [-0.851200592524718, 53.707297427252108],
              [-0.859476599920826, 53.705745050523035],
              [-0.864077384495602, 53.703779796924266],
              [-0.866922217431605, 53.701358231639155],
              [-0.866583649841823, 53.701274131817861],
              [-0.866925529355773, 53.697794272161602],
              [-0.867755549576708, 53.697456056544162],
              [-0.866381273532225, 53.696628691583392],
              [-0.869594314722751, 53.697060840896107],
              [-0.885912498944033, 53.694668111677544],
              [-0.894112599880776, 53.692746812333517],
              [-0.946105960748184, 53.67982246999523],
              [-0.99160820042708, 53.673967089120282],
              [-0.992232654591959, 53.672729226862984],
              [-0.991669228689554, 53.671347438238534],
              [-0.990998142020821, 53.661166728559508],
              [-0.998805536474678, 53.658669604909441],
              [-1.009971140991192, 53.658757927530409],
              [-1.018994991855491, 53.657957593015198],
              [-1.023447319472923, 53.656954901478365],
              [-1.029358416147326, 53.654949967603379],
              [-1.033335405614244, 53.655189768583725],
              [-1.037219166651754, 53.654475901855676],
              [-1.038782528463508, 53.65479317105838],
              [-1.041283188992499, 53.656014982989205],
              [-1.044486750382953, 53.656739892364122],
              [-1.046342808667636, 53.656698956630223],
              [-1.048659204673737, 53.656037845119847],
              [-1.050999633669205, 53.657537723080971],
              [-1.057541044591214, 53.657610048852376],
              [-1.059621400945493, 53.658370650709273],
              [-1.059201096930294, 53.659101712462061],
              [-1.060226824828708, 53.659249087127542],
              [-1.061872935690028, 53.659194577740486],
              [-1.063280851508479, 53.657460924848586],
              [-1.067899046668771, 53.657256960826345],
              [-1.071186979412012, 53.657885633074116],
              [-1.071057225757483, 53.660002321016606],
              [-1.073295629468147, 53.660265945815404],
              [-1.074263404405239, 53.660091861681487],
              [-1.075379338564843, 53.660546311594452],
              [-1.078798698549655, 53.660438730097802],
              [-1.082688231373463, 53.662024491421171],
              [-1.084466506172499, 53.662020120000754],
              [-1.085636225789626, 53.662374213513864],
              [-1.088769331483695, 53.66445825312217],
              [-1.08700564962473, 53.666584084567319],
              [-1.091515083177403, 53.66866325133639],
              [-1.103567332094658, 53.669537845858038],
              [-1.101987209638742, 53.674375292052581],
              [-1.100210271516728, 53.674363756254635],
              [-1.097479641933982, 53.675273522101826],
              [-1.093144596842239, 53.678174621846871],
              [-1.087315025498668, 53.68329782365447],
              [-1.081064287342248, 53.684573145351443],
              [-1.080681498048405, 53.685255130122776],
              [-1.08501491058594, 53.686939483974705],
              [-1.076354214035861, 53.696732475059129],
              [-1.078157315515898, 53.697738685772336],
              [-1.075892331090398, 53.701432571094514],
              [-1.074930719601518, 53.703976971854829],
              [-1.071894322211252, 53.703523845579383],
              [-1.069704940946503, 53.702857003688969],
              [-1.068933754299683, 53.703961986812203],
              [-1.06708376496957, 53.704320619380503],
              [-1.063125799618908, 53.706554830862693],
              [-1.062308660078294, 53.706717422586095],
              [-1.061454411140554, 53.706504899653382],
              [-1.059494745784046, 53.704708023733517],
              [-1.054210389166951, 53.70134969007178],
              [-1.053938469633944, 53.696790389513183],
              [-1.052226774071665, 53.696597093035521],
              [-1.048034772428023, 53.69839301651654],
              [-1.045966291066903, 53.698503302726579],
              [-1.044534709911876, 53.698045168634891],
              [-1.042163597794048, 53.696607855709331],
              [-1.040886616347893, 53.695549583639902],
              [-1.040377327744832, 53.694234984609828],
              [-1.039019555404125, 53.693670411622797],
              [-1.037673825401493, 53.693907693114426],
              [-1.036234554187609, 53.695256083336261],
              [-1.033930682425572, 53.696259521302096],
              [-1.022119338944314, 53.697856242259633],
              [-1.018923422883155, 53.697792365664576],
              [-1.01377838098766, 53.696812627569166],
              [-1.011537750286156, 53.696770794176246],
              [-1.00682754937217, 53.695088744521918],
              [-1.004028478216208, 53.696188175565567],
              [-1.00252762785681, 53.698362595792766],
              [-1.000945054788717, 53.698672098551405],
              [-0.998808363059071, 53.69858685023533],
              [-0.994540792749065, 53.697219916101858],
              [-0.985938532168768, 53.6973766291161],
              [-0.9805751406448, 53.696691133782629],
              [-0.979698730942113, 53.69691917353299],
              [-0.979619389635085, 53.69744432699914],
              [-0.982014782485478, 53.699107805227577],
              [-0.983084375197014, 53.702743756808964],
              [-0.982319468390184, 53.703996554650516],
              [-0.98041681086938, 53.704810919842153],
              [-0.974722833423045, 53.705911968536853],
              [-0.967948368299165, 53.70651806615205],
              [-0.966659186538593, 53.705650349109753],
              [-0.966671424400024, 53.704532281361914],
              [-0.967694253963653, 53.703715950109995],
              [-0.97031033200688, 53.703098475664689],
              [-0.970412224109437, 53.702151061113724],
              [-0.968476812819724, 53.700953322065359],
              [-0.966652324004158, 53.700426153850387],
              [-0.964039537519967, 53.700235506945447],
              [-0.962529774699218, 53.700526256960103],
              [-0.962027319423059, 53.701247280716203],
              [-0.96226750734872, 53.702998530853144],
              [-0.961877768288427, 53.703508400448335],
              [-0.960978228515401, 53.703927559390451],
              [-0.958229893073769, 53.704371999817511],
              [-0.957268369322816, 53.70496766638658],
              [-0.957461763801276, 53.705540121346168],
              [-0.960219513543706, 53.70686831915387],
              [-0.960885092131283, 53.707770249321889],
              [-0.958550880675012, 53.710024084281628],
              [-0.958483604871754, 53.713791490596158],
              [-0.95820228707324, 53.714384085722138],
              [-0.957364270600795, 53.714694092668921],
              [-0.954288395495408, 53.714446174691389],
              [-0.951012396930837, 53.713046792765887],
              [-0.949281357186563, 53.712648716235897],
              [-0.944212125421884, 53.712323720171604],
              [-0.940439997737092, 53.713202764553415],
              [-0.934643078325183, 53.716192194734546],
              [-0.928631600150053, 53.71715610699178],
              [-0.923614910808404, 53.718437863587937],
              [-0.919412693301359, 53.718616671465156],
              [-0.91275327674736, 53.718410826155264],
              [-0.910343349232497, 53.717959285228893],
              [-0.907398796365335, 53.716621044314508],
              [-0.905438190914729, 53.71667056742595],
              [-0.90369620053242, 53.717869898727209],
              [-0.902993378338606, 53.719547025867691],
              [-0.903417925827728, 53.722160291181225],
              [-0.905735898370019, 53.726335988449122],
              [-0.905137953102739, 53.72706579135243],
              [-0.897988817379431, 53.726353094715556],
              [-0.896203494067107, 53.726347452173123],
              [-0.893669022265044, 53.726876880548218],
              [-0.887974211191474, 53.726487106715048],
              [-0.884143239880131, 53.725487925552081],
              [-0.877843572041113, 53.722778374898084],
              [-0.866075435194345, 53.721702233772156],
              [-0.860738107548168, 53.722138797405073],
              [-0.85758696780599, 53.724332580996105],
              [-0.857130624915588, 53.726462123619321],
              [-0.857893298405044, 53.729024851423603],
              [-0.857627576646596, 53.730160274853837],
              [-0.85635956216486, 53.730691992264752],
              [-0.854216130763407, 53.730693093625348],
              [-0.850228889363356, 53.728880561163578],
              [-0.84698005859924, 53.725297952357828],
            ],
          ],
          [
            [
              [-0.248645541645724, 53.733444570389963],
              [-0.249346766003492, 53.73316985594213],
              [-0.250174805770063, 53.733272742437272],
              [-0.245235694807855, 53.736470828210891],
              [-0.248645541645724, 53.733444570389963],
            ],
          ],
          [
            [
              [-0.387985607663504, 54.174532112959483],
              [-0.382854733883973, 54.171895311437211],
              [-0.380695467745701, 54.170342744498306],
              [-0.374511726917251, 54.162545480399622],
              [-0.373303317785132, 54.156256056226951],
              [-0.37499297138644, 54.156205164230236],
              [-0.374913326360842, 54.154094476622738],
              [-0.363931228656846, 54.153139483989079],
              [-0.360023216167029, 54.153101600415766],
              [-0.355262202950911, 54.149588640091146],
              [-0.350738551529004, 54.149754172179186],
              [-0.346382979661542, 54.146854033878348],
              [-0.342478226842582, 54.148512670219027],
              [-0.336932298512848, 54.149544489261835],
              [-0.335268012570511, 54.149655419966955],
              [-0.328792422896697, 54.149048865557162],
              [-0.323827632032431, 54.150277808310946],
              [-0.323086927293108, 54.149244588145251],
              [-0.32328407940281, 54.147472977742247],
              [-0.322638700208293, 54.145257277947508],
              [-0.31375106997212, 54.146383447409988],
              [-0.311658408051813, 54.142724500966402],
              [-0.307647256152996, 54.13788171759235],
              [-0.304069066175408, 54.1362511531675],
              [-0.299993632376203, 54.136715013274205],
              [-0.291549399286092, 54.138738374114347],
              [-0.29055153171739, 54.139736335458842],
              [-0.288208314775134, 54.139389334089969],
              [-0.283102800925559, 54.139762468735633],
              [-0.276437369423778, 54.139579134592374],
              [-0.272766336453834, 54.139248768645061],
              [-0.269054753546802, 54.138313664886908],
              [-0.265405673117544, 54.140538884734532],
              [-0.252082828948758, 54.138780342600924],
              [-0.245189718220988, 54.1371088236275],
              [-0.237210769098085, 54.137229551268319],
              [-0.229732860718811, 54.132420476085514],
              [-0.229829518780624, 54.134281678525198],
              [-0.229052331926461, 54.135707554727858],
              [-0.226540685341489, 54.136417546666031],
              [-0.222671109818131, 54.137021152795263],
              [-0.221677510484276, 54.137952999159026],
              [-0.221294875737446, 54.138844428047932],
              [-0.221396038576853, 54.14202076505218],
              [-0.216913366919044, 54.148327509235266],
              [-0.212527746566732, 54.157573965372812],
              [-0.203255787685404, 54.156442700810445],
              [-0.199359591282444, 54.155562020683377],
              [-0.190148359040001, 54.154671548787121],
              [-0.188335573811446, 54.154100461686561],
              [-0.181348380429879, 54.153107025388863],
              [-0.178990444754305, 54.152295647929165],
              [-0.172654696026685, 54.15147335360497],
              [-0.171797798119881, 54.150972249817649],
              [-0.170798904925213, 54.150983145179026],
              [-0.168065737491112, 54.149556404361171],
              [-0.165333710062765, 54.148835243520978],
              [-0.162494639772863, 54.146418881747692],
              [-0.159573331042204, 54.145656945060651],
              [-0.157176596604444, 54.145738035392064],
              [-0.157600038142778, 54.145201587685897],
              [-0.156746482396905, 54.144425365104055],
              [-0.14945833468338, 54.143435337664307],
              [-0.147652444287522, 54.142835869457471],
              [-0.145702398656804, 54.141420656872839],
              [-0.144746161762108, 54.141372664572785],
              [-0.144322626779087, 54.141052423386022],
              [-0.140678520010306, 54.139524707287976],
              [-0.139045025948343, 54.139218112322247],
              [-0.136662374607032, 54.137594708287267],
              [-0.135289157747775, 54.137357709364501],
              [-0.134133802260073, 54.136701591832974],
              [-0.131470779028897, 54.136145217402678],
              [-0.129073896720354, 54.13518751889093],
              [-0.123469298448296, 54.134285876525588],
              [-0.121308958627013, 54.133045859358432],
              [-0.118871187598749, 54.133539932754893],
              [-0.118091761588743, 54.133175381762975],
              [-0.117974347779735, 54.132557801716487],
              [-0.117253607374425, 54.132688555926904],
              [-0.117129018720273, 54.133073132730459],
              [-0.116667861150929, 54.132542760065753],
              [-0.114972644024186, 54.133099607954144],
              [-0.115110608318778, 54.131780389525517],
              [-0.11363306700514, 54.131083968224253],
              [-0.112443449178437, 54.131293638530735],
              [-0.111981652430497, 54.13111560514286],
              [-0.112439438435009, 54.13151650229392],
              [-0.11096622040404, 54.132441727649841],
              [-0.108695432334303, 54.132426742953662],
              [-0.107740567333125, 54.131715987974559],
              [-0.107817122485096, 54.132084840963842],
              [-0.107567505315016, 54.131953273051224],
              [-0.10726368773497, 54.131029821499901],
              [-0.106712782599981, 54.130725419477756],
              [-0.107285476207467, 54.129880472579572],
              [-0.106500649878536, 54.129940040427762],
              [-0.106639815476692, 54.129606940333758],
              [-0.106231303209151, 54.129838721760009],
              [-0.106027453998828, 54.129475954601943],
              [-0.10482168218341, 54.129570236227053],
              [-0.104882942017433, 54.129904692419046],
              [-0.104215651183867, 54.129904071885747],
              [-0.104894132232647, 54.130095435247235],
              [-0.104619145003417, 54.130955846298811],
              [-0.102900252227919, 54.131191245026749],
              [-0.102474341968461, 54.130496873214419],
              [-0.101784982118072, 54.130543533031037],
              [-0.10155127778915, 54.130165005920873],
              [-0.100703644996024, 54.130058147820129],
              [-0.101199662777084, 54.129553600960591],
              [-0.099685211815739, 54.130104995793587],
              [-0.099714280055575, 54.129637127779858],
              [-0.098895692043548, 54.129397678133962],
              [-0.098619152889082, 54.128818912540993],
              [-0.097775388430513, 54.12886221102459],
              [-0.097591242180779, 54.128238160234197],
              [-0.096740400384852, 54.128402691121927],
              [-0.097348808363693, 54.127984433667542],
              [-0.096780599820752, 54.128059946691067],
              [-0.09715449991861, 54.127782704787059],
              [-0.09609549762585, 54.128147990432559],
              [-0.096648998581212, 54.127625494314671],
              [-0.096116264786582, 54.127227844341249],
              [-0.095674151187762, 54.127157027970185],
              [-0.094607209027121, 54.128128933209481],
              [-0.093738593287651, 54.128380354985829],
              [-0.093555052613, 54.127811140073838],
              [-0.094238818379672, 54.127252065352607],
              [-0.092627808582123, 54.125707401754028],
              [-0.091498444473974, 54.125506122501662],
              [-0.091157732234, 54.125160934273616],
              [-0.0909618316265, 54.12542749774579],
              [-0.090497329941273, 54.124812471148452],
              [-0.089657027142552, 54.124582500748019],
              [-0.088922494136769, 54.124781182668443],
              [-0.087762382333342, 54.124583874258178],
              [-0.087432736643305, 54.124397956773862],
              [-0.087789855937188, 54.12378788288634],
              [-0.087306892220141, 54.123408961478681],
              [-0.085467257499553, 54.123546008160019],
              [-0.083183034864386, 54.122506473072463],
              [-0.082852793428183, 54.121605005699536],
              [-0.08343712879625, 54.120416073703055],
              [-0.082912648343812, 54.120175802833771],
              [-0.083182613203182, 54.119465474175747],
              [-0.082425077822258, 54.119434529151022],
              [-0.081814942330113, 54.118995129494614],
              [-0.082903314838439, 54.119052023886603],
              [-0.082345133164269, 54.118813011285354],
              [-0.082901618038536, 54.118525238922487],
              [-0.082376958290394, 54.117527186071342],
              [-0.081795711428583, 54.117224879821073],
              [-0.079999536526422, 54.117651981183137],
              [-0.07980755357388, 54.117435877589138],
              [-0.078658708588406, 54.117628783907293],
              [-0.078719469203652, 54.11740682547854],
              [-0.077460650987459, 54.117330989714276],
              [-0.076345493546698, 54.11626774206448],
              [-0.077005544664893, 54.115957381824813],
              [-0.07657920272034, 54.115483137323494],
              [-0.077368134010947, 54.114834149741192],
              [-0.077167628041389, 54.114604421882007],
              [-0.076149198933471, 54.114592639199373],
              [-0.077365578304754, 54.114228246398007],
              [-0.078815387635275, 54.112388868378773],
              [-0.08093051436336, 54.111610007431274],
              [-0.081854180712259, 54.110856170016767],
              [-0.085322795550481, 54.109883961711752],
              [-0.09193245663163, 54.107333586394041],
              [-0.100460515282204, 54.105562838383513],
              [-0.104713648342016, 54.104105365734803],
              [-0.112767803427662, 54.103379800224396],
              [-0.114853184555084, 54.103603057096045],
              [-0.116301054940323, 54.104528228622428],
              [-0.122097618150839, 54.103594985765334],
              [-0.13021646246127, 54.10407378849235],
              [-0.137988742060624, 54.104029813354586],
              [-0.14059638000812, 54.104344300485693],
              [-0.147369160481929, 54.102428948357279],
              [-0.149569035373365, 54.102282121083739],
              [-0.153691385647801, 54.101242501413189],
              [-0.162099441868858, 54.100158615581421],
              [-0.164793222993504, 54.099559719557924],
              [-0.167963008432243, 54.098326202435203],
              [-0.172094321340108, 54.095811010524351],
              [-0.182277022650826, 54.087293665124655],
              [-0.184714879715732, 54.084580761917749],
              [-0.186986983490377, 54.081629802639149],
              [-0.186354962584082, 54.079576193258681],
              [-0.187180839001346, 54.081110467366365],
              [-0.189143926867712, 54.081105875830993],
              [-0.192505835873499, 54.081698467430307],
              [-0.191248283113957, 54.080875956652278],
              [-0.192470259857882, 54.080863761178669],
              [-0.193089658130557, 54.080189009497488],
              [-0.186905086850068, 54.080001572280779],
              [-0.188388732840377, 54.079807291205384],
              [-0.193864734002933, 54.080117950840361],
              [-0.198410884805006, 54.077898441836808],
              [-0.200990381933661, 54.074134735505041],
              [-0.206953207753224, 54.067744558314118],
              [-0.208502378577193, 54.063850272518053],
              [-0.212966825827774, 54.053721455761888],
              [-0.215770438712699, 54.038277850779053],
              [-0.216115016052397, 54.026770815798727],
              [-0.215463770661159, 54.021228444759757],
              [-0.214095081664995, 54.017259264977262],
              [-0.214903669362627, 54.016626765581371],
              [-0.21504529091209, 54.015814467558826],
              [-0.212933483330046, 54.010564988192257],
              [-0.213467442322986, 54.009322557753684],
              [-0.213163339515378, 54.007978678535864],
              [-0.209831634233479, 53.999845298398597],
              [-0.205424798416086, 53.990457069495328],
              [-0.195886128414147, 53.9735263007016],
              [-0.189255721548751, 53.960037324842084],
              [-0.183269123322371, 53.949115966481671],
              [-0.176422152348158, 53.938395150318954],
              [-0.172689625702162, 53.931595658893272],
              [-0.162555115960797, 53.919396427055446],
              [-0.162154465202483, 53.918567784913293],
              [-0.162546246203739, 53.918523441431653],
              [-0.162425056504399, 53.918023585907797],
              [-0.160605885444195, 53.915205486774695],
              [-0.159495701671943, 53.911573917849253],
              [-0.157882071975662, 53.90976930872143],
              [-0.158299423467385, 53.909557273809632],
              [-0.156716671783374, 53.907607496005198],
              [-0.157212250092488, 53.907347225278954],
              [-0.15634192849579, 53.906437639072038],
              [-0.15663799569614, 53.906386452168903],
              [-0.155915003467862, 53.904724929465807],
              [-0.156778039909412, 53.904519745067297],
              [-0.156803106339213, 53.90370840386985],
              [-0.153956061992157, 53.899299492569419],
              [-0.145758811038015, 53.889693100453705],
              [-0.137465438141526, 53.881237040773684],
              [-0.132747150017913, 53.877010678093484],
              [-0.1333570455454, 53.876706439429213],
              [-0.132697767646902, 53.876002210487357],
              [-0.13296344945776, 53.87557036340344],
              [-0.13019524344842, 53.872219205744756],
              [-0.121975102163954, 53.863970799450726],
              [-0.105608295323566, 53.849660468811628],
              [-0.10152220324704, 53.846526009386494],
              [-0.095845965429064, 53.842034847233165],
              [-0.08761450290456, 53.834308757530863],
              [-0.080762521205356, 53.828546769574892],
              [-0.077258263250411, 53.824735757311899],
              [-0.069400322147583, 53.818481415276864],
              [-0.063280782689813, 53.812327436564274],
              [-0.04914259522137, 53.800546749949625],
              [-0.044324848747887, 53.795411656829678],
              [-0.027053114605841, 53.780703122959423],
              [-0.013993092818889, 53.77110681134404],
              [-0.007137427558072, 53.76507784712495],
              [-0.006067300128463, 53.76371070427269],
              [-0.002970845482373, 53.761884594759252],
              [0.002990491833941, 53.756379632312203],
              [0.014442367645158, 53.747417344894288],
              [0.029093633830764, 53.736760047863996],
              [0.041115665723125, 53.726364158717629],
              [0.043709332144961, 53.723426132805919],
              [0.044518182306314, 53.721414802204428],
              [0.046470774544067, 53.719455849906517],
              [0.057641380371226, 53.711329190914512],
              [0.069517020425698, 53.70067570354675],
              [0.096422704551638, 53.681011606711252],
              [0.102724556868393, 53.674618039366358],
              [0.115244788660185, 53.663389283388241],
              [0.117007850725347, 53.66223527028567],
              [0.120431434226473, 53.658372811677005],
              [0.120654552690204, 53.657842944611133],
              [0.119481218810076, 53.658618884050597],
              [0.124686725541243, 53.651922547869248],
              [0.126357446384971, 53.648918084854387],
              [0.129459188680039, 53.644861483274049],
              [0.131445851340609, 53.64030413423739],
              [0.132028803481689, 53.6374052512666],
              [0.13406217655034, 53.633275842005865],
              [0.136546382462317, 53.629934863610309],
              [0.137106539927782, 53.628449592440582],
              [0.136404154850016, 53.62933506879331],
              [0.138974278012817, 53.62512945407579],
              [0.141783949654336, 53.621961440154834],
              [0.141933141920303, 53.619473012333891],
              [0.145708756753963, 53.61202000467209],
              [0.147333120712748, 53.60732679654258],
              [0.147583617633099, 53.6022842418989],
              [0.146632879200168, 53.599601573617768],
              [0.144097839972324, 53.596131001334385],
              [0.135961480042835, 53.589095257178315],
              [0.131708266844763, 53.586578382759086],
              [0.131002486416207, 53.58651454282078],
              [0.133529952049583, 53.588158729434355],
              [0.129907400138775, 53.586070157991081],
              [0.110058593062909, 53.572111537152132],
              [0.108353524167618, 53.571446673916341],
              [0.105959902689241, 53.572607213194381],
              [0.106504996012084, 53.574092664185677],
              [0.1077381163023, 53.575106596409398],
              [0.111452595737927, 53.57683819643902],
              [0.114620816698855, 53.57745558095619],
              [0.117217296187149, 53.579114168121272],
              [0.11892249238327, 53.580799281173292],
              [0.120526043130537, 53.581326450468474],
              [0.12327232116201, 53.584095227158841],
              [0.125496211251664, 53.585178592810031],
              [0.126602500048888, 53.586689943178577],
              [0.130394267843403, 53.588750427782955],
              [0.132068381127337, 53.590159915725714],
              [0.134267833300841, 53.590207896925385],
              [0.135130897498403, 53.590482875356628],
              [0.139907955353121, 53.593695086445877],
              [0.141769380635096, 53.595391471496868],
              [0.144349390945672, 53.598382112446437],
              [0.145830718193049, 53.601039998852535],
              [0.146329913108934, 53.604297151512874],
              [0.14589975671615, 53.607648288673232],
              [0.144173987906323, 53.61001575685237],
              [0.138923591027315, 53.614469027995632],
              [0.133335998305346, 53.616845069847336],
              [0.124571734183106, 53.623391136895044],
              [0.123899287919186, 53.623611643094421],
              [0.122552566218342, 53.623263350586008],
              [0.122331928193977, 53.623507298164142],
              [0.121380078720529, 53.623524176237567],
              [0.114261809821031, 53.627783770295224],
              [0.105474315906928, 53.632174791131206],
              [0.095015877575912, 53.63548414757858],
              [0.089247512460055, 53.637888214052488],
              [0.07958704997662, 53.640988981461916],
              [0.071280735756047, 53.643008314484327],
              [0.07015474639538, 53.642876763735771],
              [0.064687315632812, 53.64421181060041],
              [0.056853750668839, 53.64502901455257],
              [0.045818515984488, 53.646692476401526],
              [0.034491126181235, 53.649187845293902],
              [0.029992342982779, 53.649244445541498],
              [0.026861924876182, 53.648239386974502],
              [0.025760203471514, 53.648077341024155],
              [0.025491104740429, 53.648303045059521],
              [0.024824324215136, 53.647871126475344],
              [0.024322687555291, 53.648169982976611],
              [0.023931227400345, 53.64761024222566],
              [0.023168845961515, 53.647361525863346],
              [0.022981862830558, 53.647532796186951],
              [0.022752856849415, 53.647079083172542],
              [0.022110128986538, 53.647243668913411],
              [0.01948393727556, 53.645226634222986],
              [0.018788771881674, 53.645718418167206],
              [0.016680232959689, 53.646269078987665],
              [0.013377303306993, 53.648617986031923],
              [0.008531582411419, 53.651242611379345],
              [0.008640433783158, 53.651495196718066],
              [0.008338107946771, 53.651374411856857],
              [0.007469405502785, 53.651849261534238],
              [0.00750734063573, 53.652138097344064],
              [0.007277644325582, 53.651921698742342],
              [0.002593462590616, 53.653628205847994],
              [0.000583579302408, 53.654836778461998],
              [0.000992526238176, 53.654492826101325],
              [0.00046975180405, 53.654414362188412],
              [0.001427441679255, 53.654313850436374],
              [0.002500612318842, 53.653457154864469],
              [0.007332734710442, 53.651679847971984],
              [0.011418169863795, 53.649546308673166],
              [0.015579199176264, 53.647007709661132],
              [0.016446264268501, 53.646121991400932],
              [0.017464797911978, 53.645779396775907],
              [0.017334664299281, 53.644891596083035],
              [0.015177067131364, 53.644286065663501],
              [0.004190535028605, 53.643176891641431],
              [-0.008301047652776, 53.64122514915622],
              [-0.022725706376547, 53.637538466594386],
              [-0.027609731804744, 53.63647734910392],
              [-0.030421224838504, 53.636358235660708],
              [-0.034981896981101, 53.634957078600415],
              [-0.04265092036534, 53.633187701438246],
              [-0.049674849303776, 53.632077062646097],
              [-0.052131428977206, 53.630888170673423],
              [-0.054085211866457, 53.628988057090865],
              [-0.057694651117092, 53.629494378284718],
              [-0.064937862454456, 53.629782513376902],
              [-0.078884609312152, 53.630925044092002],
              [-0.087604844427574, 53.632505745878213],
              [-0.092882337925878, 53.633032246911036],
              [-0.103541195045169, 53.635318675716789],
              [-0.109048865460843, 53.637846505008284],
              [-0.113520774855209, 53.639429126115047],
              [-0.113393351645269, 53.639674332863592],
              [-0.116166123034381, 53.641644481720093],
              [-0.124473075625034, 53.645970380483483],
              [-0.12492136927549, 53.645789520466927],
              [-0.124736251979861, 53.646142609302856],
              [-0.130534678821041, 53.649305949267408],
              [-0.131757724955508, 53.650492791175246],
              [-0.131416476403777, 53.651058304490959],
              [-0.130201812915468, 53.651528373013036],
              [-0.130925028386385, 53.651393136193207],
              [-0.13034183293029, 53.652193992050464],
              [-0.130833869210932, 53.652154030581222],
              [-0.131201734280452, 53.652458228168811],
              [-0.131867277759953, 53.651539990662556],
              [-0.132593771266617, 53.651604363908504],
              [-0.132419567883172, 53.651266334587966],
              [-0.133323936984632, 53.650729377603028],
              [-0.13390181716275, 53.651246298934183],
              [-0.133094691107213, 53.651020661434842],
              [-0.137750139214335, 53.654292563984079],
              [-0.13875285010274, 53.654614701853951],
              [-0.139361519933664, 53.654469542425936],
              [-0.139169906170379, 53.654937618178614],
              [-0.142966276350577, 53.65759814765368],
              [-0.146645870514641, 53.659791984345119],
              [-0.147422387996578, 53.659651179759237],
              [-0.146919248691336, 53.660046125672771],
              [-0.150795487490743, 53.662770560952367],
              [-0.151483726104244, 53.662780289686978],
              [-0.151131392602837, 53.663084089993141],
              [-0.154980555679398, 53.666128893036415],
              [-0.155591072866361, 53.666394500892117],
              [-0.156346635700659, 53.666146339609497],
              [-0.155826656604398, 53.666719056087487],
              [-0.162972575893695, 53.672186659714434],
              [-0.166107312257633, 53.673836641026668],
              [-0.165937418976468, 53.674053383107683],
              [-0.166505821225694, 53.673898478453935],
              [-0.167198322559822, 53.674407098571962],
              [-0.169424404296981, 53.673905374936425],
              [-0.167601315402213, 53.674575077131379],
              [-0.168650149211115, 53.675979101857251],
              [-0.168436216275174, 53.676443284212276],
              [-0.169243240446136, 53.676686655156594],
              [-0.169612113999562, 53.676454971116222],
              [-0.171057191476594, 53.677487470468712],
              [-0.171008835909409, 53.677869684610911],
              [-0.171809733310732, 53.678115641176291],
              [-0.171811208509346, 53.67874582746196],
              [-0.172292974423172, 53.678634519966799],
              [-0.172657983597725, 53.679401500570236],
              [-0.173104716258893, 53.679330107920826],
              [-0.173806775466079, 53.679831642418478],
              [-0.17369135133827, 53.68029463895293],
              [-0.17420191931683, 53.680182864002518],
              [-0.174335051697586, 53.68060560186219],
              [-0.175083489207572, 53.680770729707255],
              [-0.174987403400211, 53.681136935553766],
              [-0.175412308412933, 53.68101036568649],
              [-0.179760706536964, 53.684038572097137],
              [-0.181482598987335, 53.683824711865071],
              [-0.180661884131697, 53.684280598459807],
              [-0.181110084358166, 53.684949031818789],
              [-0.183248978511577, 53.685989217114553],
              [-0.184044720297729, 53.686782470341484],
              [-0.191434108197376, 53.689948003716999],
              [-0.193694274411052, 53.6903605678703],
              [-0.193369970311523, 53.690718850150382],
              [-0.198942910184098, 53.694102806538616],
              [-0.199213828670527, 53.694568937023156],
              [-0.200539167633461, 53.695279244229908],
              [-0.201490671020798, 53.695201843223636],
              [-0.200678528938543, 53.69559866478204],
              [-0.201350990461532, 53.696239822812153],
              [-0.210106754761846, 53.702009143925864],
              [-0.213319212693225, 53.703814531202831],
              [-0.218231220845754, 53.705814121697635],
              [-0.220165465572397, 53.706041541512192],
              [-0.224146472883739, 53.707639606439699],
              [-0.227247815001026, 53.708472111772359],
              [-0.227025115088102, 53.708939859241305],
              [-0.227758553902249, 53.709081956755199],
              [-0.228574635874989, 53.71039478899479],
              [-0.229773646223358, 53.71106963536792],
              [-0.233098078852117, 53.716964493374604],
              [-0.23569757561633, 53.720176042581357],
              [-0.235321107050351, 53.722724371242101],
              [-0.234804420398052, 53.722973855369169],
              [-0.234752338113402, 53.723493571048316],
              [-0.236933367557138, 53.726683638952821],
              [-0.235920478097713, 53.72719820260248],
              [-0.234532183296394, 53.727337765285874],
              [-0.231843746483378, 53.72883889595974],
              [-0.232026141803815, 53.730525290519232],
              [-0.232980746299004, 53.731512017752223],
              [-0.230332562596607, 53.733562957772776],
              [-0.231119545028896, 53.733487381078049],
              [-0.233497854905738, 53.731761455306419],
              [-0.232459017558847, 53.729421493465502],
              [-0.234396974820018, 53.72821313240442],
              [-0.235027425470049, 53.728108256042546],
              [-0.234989771585775, 53.728500535398766],
              [-0.236282935584823, 53.728389231748473],
              [-0.243187687461509, 53.729555003083092],
              [-0.243640530072908, 53.731019709560641],
              [-0.244384464256751, 53.730811272168822],
              [-0.243711172493015, 53.731107042463577],
              [-0.245111945400509, 53.735437942012211],
              [-0.242763224971126, 53.738787100348404],
              [-0.243928438418119, 53.739678840739828],
              [-0.246580616091242, 53.74018422075752],
              [-0.24730098022318, 53.74112874711961],
              [-0.247038240448176, 53.742705223176813],
              [-0.245561094913782, 53.743765020218348],
              [-0.244875119677171, 53.744942462470604],
              [-0.246234677602475, 53.746671230778162],
              [-0.244637098143351, 53.748105905951306],
              [-0.245604978065971, 53.749627582558858],
              [-0.244737048723507, 53.7502558110682],
              [-0.242622319178039, 53.750994305097166],
              [-0.241428267625817, 53.755034557943766],
              [-0.243377122039825, 53.755550355430188],
              [-0.244575599505318, 53.756310429048987],
              [-0.245473182556744, 53.759450043307417],
              [-0.249885523157845, 53.759610733741212],
              [-0.251526397282562, 53.760536304322791],
              [-0.250926361563762, 53.76197931695318],
              [-0.254194137620593, 53.763479619646077],
              [-0.253358480894973, 53.76453267347415],
              [-0.254427406371973, 53.765500206500469],
              [-0.254719417359588, 53.766361134542869],
              [-0.25434939092382, 53.7689167852851],
              [-0.254357625381897, 53.769998312236595],
              [-0.252135337283807, 53.769982111735175],
              [-0.252112812924403, 53.772350451512267],
              [-0.254495984365086, 53.772588331844041],
              [-0.254270405932148, 53.77378241488762],
              [-0.253244126510768, 53.773939160518786],
              [-0.25278487438348, 53.775314115445312],
              [-0.252317316848999, 53.781307620032003],
              [-0.258112717359148, 53.781149250176398],
              [-0.258095156147692, 53.781719811166141],
              [-0.257498851563099, 53.781788453530304],
              [-0.257728282217558, 53.783248042954412],
              [-0.262907407602781, 53.782949239965248],
              [-0.26565125526636, 53.782333648357771],
              [-0.268318009327145, 53.785025810285696],
              [-0.272724828437203, 53.782616445614003],
              [-0.274041709436318, 53.783407582703376],
              [-0.278242989031609, 53.782039603986036],
              [-0.288884745721839, 53.789527123278894],
              [-0.294381535675168, 53.792973679133354],
              [-0.303877052063617, 53.798449717801752],
              [-0.316126597078834, 53.813250334435523],
              [-0.324289249959372, 53.812147531682399],
              [-0.333870900996058, 53.811329973238671],
              [-0.337592506232432, 53.810666982908742],
              [-0.338553782917971, 53.810555354828821],
              [-0.338623264498426, 53.810949139971285],
              [-0.346076065551867, 53.810605388047634],
              [-0.34573702327589, 53.809726974810516],
              [-0.348756657567412, 53.809488127369434],
              [-0.348586124762277, 53.808880815305109],
              [-0.353656819623156, 53.808504670232118],
              [-0.353514870928878, 53.811545517564689],
              [-0.35414999166886, 53.814408258785825],
              [-0.357968848547135, 53.817434175593185],
              [-0.359246004436706, 53.817832787834291],
              [-0.362115377920763, 53.81791515075642],
              [-0.363890218889261, 53.818680968125115],
              [-0.366382113422242, 53.821244443712438],
              [-0.367922285825479, 53.823636712850409],
              [-0.368956210755372, 53.824359110150688],
              [-0.37001401891666, 53.824741139110884],
              [-0.376939733268962, 53.825157751046333],
              [-0.381353022372652, 53.827296537479619],
              [-0.381773336108416, 53.828415045573273],
              [-0.380275517066846, 53.831556260612771],
              [-0.380925184742907, 53.83476600898711],
              [-0.381348424925835, 53.835376678764021],
              [-0.383186543266125, 53.836342610666122],
              [-0.383513951368867, 53.83786436220354],
              [-0.38460277029515, 53.839032312992437],
              [-0.386194727171247, 53.839686560457515],
              [-0.390662964167303, 53.839572192535222],
              [-0.39419139444227, 53.83990801498944],
              [-0.39958157105075, 53.84363584272743],
              [-0.399243990496068, 53.845711381936859],
              [-0.396126008903024, 53.848263954860457],
              [-0.395405872639481, 53.85039908949237],
              [-0.393012428992851, 53.853866456207889],
              [-0.39299245538144, 53.855657684880853],
              [-0.396874867450594, 53.860929486838501],
              [-0.399853138327814, 53.866485735612237],
              [-0.399123963640932, 53.869331789914476],
              [-0.399191805575644, 53.871856781235437],
              [-0.398240055385333, 53.873676922198648],
              [-0.399130721256287, 53.875766141222677],
              [-0.399156484669897, 53.877799774749384],
              [-0.401743001447085, 53.878627063890988],
              [-0.403486081999748, 53.880232306104517],
              [-0.406067232721127, 53.880726840011896],
              [-0.407728573766015, 53.881927332172609],
              [-0.407869976543041, 53.882410113080013],
              [-0.407050318965892, 53.884561065110951],
              [-0.403252369573468, 53.883890372458524],
              [-0.400846071466928, 53.884072275608005],
              [-0.401043973766682, 53.883072650507735],
              [-0.400390787268124, 53.884161491731263],
              [-0.397410871292538, 53.884303320910782],
              [-0.394988621099854, 53.885297490250636],
              [-0.394100451903233, 53.888105428376164],
              [-0.394624359390946, 53.889562344194438],
              [-0.394304144105553, 53.889817840513082],
              [-0.394665074829477, 53.889770532063054],
              [-0.396021776804069, 53.891972966747723],
              [-0.395444837709055, 53.896069581447215],
              [-0.393657603773417, 53.898503241871289],
              [-0.392279005071532, 53.899327046972637],
              [-0.382537951891704, 53.901456016338116],
              [-0.381420363130956, 53.90390660791612],
              [-0.384205217872563, 53.906056505532618],
              [-0.384241821965329, 53.909131195232995],
              [-0.383750785761401, 53.909783471382063],
              [-0.380177852446091, 53.911903442842444],
              [-0.376056481935656, 53.91343341410068],
              [-0.372934718571953, 53.912938114528323],
              [-0.371068329116277, 53.912071449852398],
              [-0.368259445137226, 53.912966359363494],
              [-0.366927363025831, 53.913314098201319],
              [-0.366482790092053, 53.913859969335427],
              [-0.368103654359854, 53.91782094031138],
              [-0.365899379074588, 53.921197753540511],
              [-0.363816903615008, 53.922035028103267],
              [-0.361502467066275, 53.923504616836865],
              [-0.358166333328356, 53.924343559963305],
              [-0.356279160478432, 53.925473716510069],
              [-0.35603240121521, 53.926908561761074],
              [-0.356804288896802, 53.928467012412071],
              [-0.358419810417841, 53.930187105895556],
              [-0.35728893839683, 53.931639528184974],
              [-0.356483838547596, 53.93383168894411],
              [-0.356906068010699, 53.933320605302661],
              [-0.357071636504752, 53.933681526640655],
              [-0.357404410640814, 53.932034820616074],
              [-0.358800297758771, 53.930233654836918],
              [-0.357010745605243, 53.92813545034852],
              [-0.356539606874746, 53.925589642134241],
              [-0.357785393117427, 53.924697907062203],
              [-0.36255354840031, 53.9233032196816],
              [-0.363955105047398, 53.922200508745071],
              [-0.366341621834631, 53.921220849442449],
              [-0.368671802112072, 53.917699219936182],
              [-0.36686129650552, 53.914187819201842],
              [-0.36696704975526, 53.913545652564544],
              [-0.370684985940994, 53.912258611157775],
              [-0.372833036520115, 53.913125503331386],
              [-0.376098774840168, 53.913677584801206],
              [-0.380280974819331, 53.912110679260962],
              [-0.382379970920551, 53.910942554835358],
              [-0.387617689283852, 53.912234589292353],
              [-0.388672336220109, 53.914104947349038],
              [-0.39342242629907, 53.918992013952838],
              [-0.411811113861729, 53.924808789808118],
              [-0.421501830973086, 53.927347349987983],
              [-0.41202383740173, 53.924646320847593],
              [-0.41156613331806, 53.924541111331834],
              [-0.405036682299507, 53.922584009791329],
              [-0.393453853031975, 53.918847713869908],
              [-0.389660753620242, 53.915047655178064],
              [-0.388847986103842, 53.913831720907936],
              [-0.388735901366476, 53.913664031714276],
              [-0.387705761170196, 53.91214040986582],
              [-0.38263158939856, 53.910774256745668],
              [-0.384272875961808, 53.909786003413139],
              [-0.384837584872926, 53.909275841590222],
              [-0.384515470599404, 53.905985173684854],
              [-0.381707101897891, 53.903773841938644],
              [-0.382591939819568, 53.901831580397975],
              [-0.385075838716242, 53.900929273021283],
              [-0.392442138490346, 53.899478446807372],
              [-0.393925722770003, 53.898536492218255],
              [-0.395853278496323, 53.895776608768763],
              [-0.397540514521596, 53.89626836083751],
              [-0.399391825069326, 53.896330810454053],
              [-0.401114664065272, 53.896130843249161],
              [-0.402628272454762, 53.89555053508235],
              [-0.403715785422762, 53.895607247575811],
              [-0.405720182879445, 53.896485127599441],
              [-0.407190339433223, 53.89656576571015],
              [-0.409197854616288, 53.897284525299376],
              [-0.410020965242968, 53.898053189828047],
              [-0.417634098006753, 53.902196954561695],
              [-0.434845285004109, 53.906703924484766],
              [-0.436530212354384, 53.905895309659002],
              [-0.434802282142532, 53.906591902627817],
              [-0.417629145970775, 53.902086326523637],
              [-0.410143870002046, 53.897982906562866],
              [-0.409220013892973, 53.897141896018582],
              [-0.406904667588767, 53.896393883530358],
              [-0.405703572304074, 53.896361759538998],
              [-0.4037201685256, 53.895492248305324],
              [-0.402452275860577, 53.895456506756176],
              [-0.399645275459136, 53.896229918281961],
              [-0.397302701576953, 53.89611956652508],
              [-0.395855728147504, 53.895553717241945],
              [-0.396305107840015, 53.892045067516719],
              [-0.394369061542588, 53.887925647975749],
              [-0.395407953941691, 53.885233881733235],
              [-0.397458185605564, 53.884458561565246],
              [-0.402707877886623, 53.88404492844645],
              [-0.407289092571225, 53.884683785553825],
              [-0.40809044110345, 53.882133482752316],
              [-0.406996794105659, 53.881046599899825],
              [-0.407663353739416, 53.88080105855105],
              [-0.412548523238927, 53.88192285584369],
              [-0.413936016856715, 53.88264682374993],
              [-0.412223777312095, 53.881700130983468],
              [-0.40765974517586, 53.880695840710487],
              [-0.40687294142796, 53.880982932853492],
              [-0.406025511526198, 53.880543811551988],
              [-0.4036297925878, 53.880093990715984],
              [-0.401865836267128, 53.878477685934051],
              [-0.399426181046272, 53.877748539074915],
              [-0.39946570027469, 53.875797575241322],
              [-0.398522811087679, 53.873721145388259],
              [-0.399466046011387, 53.871884708674159],
              [-0.399411854727128, 53.869559453539608],
              [-0.400089570992477, 53.866467314300351],
              [-0.396020771151123, 53.859326134731162],
              [-0.393502778840855, 53.856346778406305],
              [-0.393189817160051, 53.854196928034675],
              [-0.395766052933369, 53.850203451944068],
              [-0.396290103067004, 53.848425252494792],
              [-0.399701243822832, 53.845559612042059],
              [-0.400056903011856, 53.844045785778469],
              [-0.399717638295307, 53.843376079179436],
              [-0.394697851321334, 53.839779957958875],
              [-0.390262018859196, 53.839405911261558],
              [-0.386607574855203, 53.839542895942877],
              [-0.385192469983211, 53.839150815738712],
              [-0.383879367046138, 53.838005018608825],
              [-0.383352155380594, 53.83622528896111],
              [-0.381547286591491, 53.835224751080382],
              [-0.381179447177246, 53.834635504223833],
              [-0.380550272135931, 53.831603117029616],
              [-0.381958744730296, 53.829044978189025],
              [-0.38172139672615, 53.827279034917353],
              [-0.376882527478481, 53.824947533212161],
              [-0.370485158475422, 53.824619896040367],
              [-0.369164245999167, 53.824283734163423],
              [-0.364017562684613, 53.818531689306127],
              [-0.361988310108086, 53.81770666777507],
              [-0.358459148485841, 53.817482236200057],
              [-0.354529753384269, 53.814524034570717],
              [-0.353812125191445, 53.811556789055281],
              [-0.353761290756451, 53.808978029850493],
              [-0.355087406643941, 53.806954809718604],
              [-0.357763921259873, 53.805463349289695],
              [-0.361977568693777, 53.805127252906573],
              [-0.364867247971374, 53.803767989353737],
              [-0.365664397439046, 53.803341088436376],
              [-0.365929813883391, 53.802607602995408],
              [-0.365336631620272, 53.798611075678018],
              [-0.364636917714646, 53.797082384802486],
              [-0.363370882471641, 53.795438092629738],
              [-0.360458552102188, 53.795017173475955],
              [-0.359341189951071, 53.794470637290949],
              [-0.359111970835569, 53.79396411294848],
              [-0.359597103321431, 53.79335949348107],
              [-0.365106881761348, 53.792221215206283],
              [-0.370443432426169, 53.791980136294399],
              [-0.392213384233798, 53.791112616638856],
              [-0.390520675661298, 53.782467621960052],
              [-0.389928396506805, 53.779582275661788],
              [-0.390535789438632, 53.779653353955361],
              [-0.389727104726004, 53.77655475604741],
              [-0.388080259458549, 53.776324080121547],
              [-0.387401195051897, 53.770721054858612],
              [-0.388290964672081, 53.770943364056031],
              [-0.388104129789175, 53.769324618053247],
              [-0.390107842813564, 53.768469714792623],
              [-0.394203884318159, 53.767492705638169],
              [-0.398260900225263, 53.767575744823034],
              [-0.398522756843873, 53.767193612667207],
              [-0.400935214914695, 53.765755206604759],
              [-0.403619071858887, 53.764876789518084],
              [-0.407930786009009, 53.762672484556006],
              [-0.408567145230485, 53.760567608387198],
              [-0.418198461064881, 53.760611487723331],
              [-0.421181748582981, 53.753753525566594],
              [-0.42118498919129, 53.752211047831103],
              [-0.422336566528694, 53.750540764267313],
              [-0.403638986421979, 53.749992064567849],
              [-0.401933749800067, 53.749966644277471],
              [-0.402049157063445, 53.7481245231968],
              [-0.407642696868184, 53.746547674104711],
              [-0.410930902288042, 53.746335126657691],
              [-0.409987796025337, 53.744242547687321],
              [-0.410671750046571, 53.744160829761562],
              [-0.411496156756672, 53.742445857214349],
              [-0.413585487631482, 53.740397954396649],
              [-0.413906500320469, 53.740449845303864],
              [-0.415659407590138, 53.738754320329093],
              [-0.417140926528506, 53.735844357569334],
              [-0.419728302851427, 53.735335563643517],
              [-0.419802583246548, 53.734325272305711],
              [-0.422122884790407, 53.731755306365223],
              [-0.422575175418255, 53.730445260164451],
              [-0.422091714313962, 53.727365529025604],
              [-0.421744733423048, 53.727402309294071],
              [-0.421088999858205, 53.72483717538276],
              [-0.420169968331907, 53.723880308967345],
              [-0.420170919893624, 53.723248388548107],
              [-0.420724366536923, 53.723120847867456],
              [-0.419201377246919, 53.719558163550218],
              [-0.429361231241625, 53.717164042494467],
              [-0.431978052109949, 53.716806392890213],
              [-0.433456677394171, 53.716899444785362],
              [-0.433879965624914, 53.717171954010226],
              [-0.434117774614087, 53.718970181196873],
              [-0.434472579230535, 53.717781966855121],
              [-0.43368475698489, 53.716470950690784],
              [-0.434171543920452, 53.716176179077898],
              [-0.447454968576468, 53.713845583387098],
              [-0.451728757729804, 53.714282922099329],
              [-0.458918798372363, 53.716195001774054],
              [-0.463259058625989, 53.716730762247238],
              [-0.473382637277664, 53.717257490566354],
              [-0.486742495675052, 53.716459745215154],
              [-0.514817224110279, 53.71222933975011],
              [-0.525887438757465, 53.710282406421079],
              [-0.53528815981647, 53.709130254847743],
              [-0.541733181646245, 53.708920327005139],
              [-0.542402230403311, 53.708807117418161],
              [-0.542547311124628, 53.708423256050942],
              [-0.542622104750975, 53.709021032653773],
              [-0.545430943957064, 53.708763025109135],
              [-0.553157478553224, 53.709528952567155],
              [-0.556170787907559, 53.710466893540342],
              [-0.556549093826151, 53.710348303274351],
              [-0.55919506987, 53.711619725289793],
              [-0.568295253803915, 53.715016058720288],
              [-0.568837094540187, 53.715302986011288],
              [-0.568623834751409, 53.716540909136192],
              [-0.569519763642419, 53.715682380197244],
              [-0.571277022138131, 53.716548303975387],
              [-0.573619182556788, 53.718000051210836],
              [-0.575081757901824, 53.719384665034887],
              [-0.582786258146287, 53.723989357471147],
              [-0.582848349128302, 53.724937520578699],
              [-0.58223650749684, 53.726094344367347],
              [-0.582620990804362, 53.725839113691002],
              [-0.583245870182082, 53.726548534841868],
              [-0.585092921181575, 53.726793281328483],
              [-0.585349038577961, 53.727155860266215],
              [-0.585394120003186, 53.726715937230416],
              [-0.5835930720265, 53.726229938120852],
              [-0.583296716733312, 53.725712270743607],
              [-0.583724795944004, 53.725467439722081],
              [-0.585990725111611, 53.726553088435786],
              [-0.587519562209392, 53.726705052362078],
              [-0.587531064780434, 53.727221149599053],
              [-0.589498520046918, 53.727991298365403],
              [-0.601662091529369, 53.729883976088338],
              [-0.616644010308159, 53.731155387364595],
              [-0.617972637719395, 53.730995433838658],
              [-0.625541403829454, 53.731370118796519],
              [-0.627477239273086, 53.731276348549557],
              [-0.628452961619574, 53.73087313405577],
              [-0.627873060616465, 53.731969425114237],
              [-0.629988330950019, 53.733118125952679],
              [-0.630212196677201, 53.73397282504056],
              [-0.630320048570931, 53.733266637457518],
              [-0.629463864535285, 53.732696845895894],
              [-0.631128608130153, 53.732736546051292],
              [-0.629352957723098, 53.732611082613133],
              [-0.628120029749161, 53.732019893192671],
              [-0.628769016051064, 53.730943267985197],
              [-0.632088630480487, 53.730155131091941],
              [-0.634665077296787, 53.729141807803003],
              [-0.637243101813395, 53.728872721857861],
              [-0.634714639808933, 53.72901563004708],
              [-0.632967948576909, 53.729450553005385],
              [-0.631895140367012, 53.730091798423153],
              [-0.629114963225096, 53.730793516776124],
              [-0.628237784766383, 53.730637860681611],
              [-0.627433841037565, 53.731119445927497],
              [-0.620527339845791, 53.730982619407563],
              [-0.618454445557227, 53.730771773614919],
              [-0.618389942673724, 53.730566084502094],
              [-0.627288642961642, 53.729620244021959],
              [-0.636983542314643, 53.72771200993391],
              [-0.646737127157698, 53.725694893319705],
              [-0.651574000969066, 53.724975478739658],
              [-0.65212908089378, 53.725154308357602],
              [-0.652101805861738, 53.72482231453391],
              [-0.652938037094277, 53.7246456489124],
              [-0.665566958584323, 53.722812102260903],
              [-0.666658792771252, 53.722936615316286],
              [-0.666477804215836, 53.722717072572287],
              [-0.670568529256097, 53.722087465472697],
              [-0.672990321311544, 53.721319698755792],
              [-0.675520306096112, 53.719758470245885],
              [-0.676317846689814, 53.719600989982034],
              [-0.676014052008021, 53.719092463808735],
              [-0.679517598249233, 53.715905045581977],
              [-0.680542143584726, 53.713720369541079],
              [-0.681612053918987, 53.7131829308628],
              [-0.687273512618707, 53.707860802266914],
              [-0.691863824711474, 53.705525380601507],
              [-0.695974168251184, 53.704341419176664],
              [-0.700117856366515, 53.703739253951348],
              [-0.70225195781273, 53.703850478492704],
              [-0.708514449893628, 53.704992305456926],
              [-0.708570983637955, 53.706164152291969],
              [-0.709149155347841, 53.705390157012779],
              [-0.709769940406376, 53.705302461399747],
              [-0.716002522763543, 53.706494808372348],
              [-0.72180272803181, 53.706668285281715],
              [-0.728427886970189, 53.707369788634772],
              [-0.732164450200085, 53.707352755286152],
              [-0.732623211884193, 53.707715360835472],
              [-0.732989093092143, 53.707387545562369],
              [-0.738015379820393, 53.70695070333074],
              [-0.742593424690633, 53.706961081066922],
              [-0.742595631712412, 53.70673458802009],
              [-0.748673644692554, 53.706025186694966],
              [-0.756983474055684, 53.703087874643387],
              [-0.757197907386721, 53.70408784791438],
              [-0.757818355953438, 53.702824174732477],
              [-0.758969458444229, 53.702676103956307],
              [-0.764158659403089, 53.700734244311427],
              [-0.764277085601778, 53.701086924942047],
              [-0.765002975716819, 53.700610816071418],
              [-0.765352253417945, 53.701150143786911],
              [-0.765606753237047, 53.700321309143504],
              [-0.771191824244815, 53.698496482739486],
              [-0.774919626867593, 53.697657359494187],
              [-0.777342020325769, 53.697433117298168],
              [-0.777940053551121, 53.697703487054746],
              [-0.777950733675549, 53.697439327743623],
              [-0.779047153976997, 53.697446011892417],
              [-0.780754806328602, 53.697529019800065],
              [-0.786946275588785, 53.700021514898566],
              [-0.794206512987622, 53.704207129117087],
              [-0.794088645098614, 53.705203687442953],
              [-0.794883143937039, 53.704596854666875],
              [-0.799708990810733, 53.705951350689304],
              [-0.802179030952757, 53.70608842707118],
              [-0.80520216407066, 53.707023770098907],
              [-0.809667088619336, 53.707176971348268],
              [-0.813157842172223, 53.706739692931393],
              [-0.81618765710885, 53.705719788769585],
              [-0.819713749642411, 53.705366263753071],
              [-0.824963832325114, 53.703541930828806],
              [-0.825558403027598, 53.702916756159631],
              [-0.83186943540068, 53.699409053693493],
              [-0.838069857239998, 53.693994352543847],
              [-0.843561670091218, 53.690009781988856],
              [-0.848566137785012, 53.687143861402468],
              [-0.851688252619987, 53.686432264228593],
              [-0.852563677426061, 53.686481101325235],
              [-0.854210215786496, 53.687186290795637],
              [-0.8561552697536, 53.68855677169438],
              [-0.859086471620071, 53.691489853878934],
              [-0.862717100362234, 53.696247005005439],
              [-0.86374290422854, 53.698875126743644],
              [-0.863765673767994, 53.700560710839049],
              [-0.863105353794179, 53.701680722331048],
              [-0.861309807700327, 53.702597590404388],
              [-0.85743988152099, 53.703642981670221],
              [-0.854192314920644, 53.704077581372751],
              [-0.850250263456555, 53.704756214285148],
              [-0.847993693885469, 53.7054536310754],
              [-0.842741216579932, 53.708387245454183],
              [-0.841773597800868, 53.709472712505431],
              [-0.840854718805664, 53.711704694238236],
              [-0.84014822175729, 53.715797123692397],
              [-0.841732241112381, 53.723500862211566],
              [-0.843554979880973, 53.725895053677824],
              [-0.844846408199915, 53.726721875908943],
              [-0.845582856024359, 53.728333438067331],
              [-0.848202958317101, 53.729910078855724],
              [-0.848840546046594, 53.730874389051429],
              [-0.851089455620485, 53.731675278878448],
              [-0.852811475703317, 53.732121427942509],
              [-0.85316564220927, 53.731807519878963],
              [-0.853732410881456, 53.732203945330618],
              [-0.854551780769045, 53.732007737554611],
              [-0.855972923319314, 53.732426695982419],
              [-0.858246692705992, 53.732094226691608],
              [-0.862609885117595, 53.730448552851172],
              [-0.86847661553078, 53.726253380634645],
              [-0.872274816617087, 53.723990798200596],
              [-0.887154400436166, 53.72826013289724],
              [-0.891616032268926, 53.728230466162671],
              [-0.893998379727732, 53.727841696465404],
              [-0.894510059450627, 53.728097197931348],
              [-0.894639905695977, 53.727885366614238],
              [-0.898656519439401, 53.727946611589509],
              [-0.901248983850834, 53.728274218743934],
              [-0.901705366116663, 53.728734121335286],
              [-0.901614332510983, 53.728272174311343],
              [-0.903192411749855, 53.72826505328716],
              [-0.903824372117195, 53.728677117275446],
              [-0.9035741765266, 53.728214614919892],
              [-0.907973376125039, 53.727785575269991],
              [-0.909611316795399, 53.727924529645463],
              [-0.91096688690146, 53.728433022034785],
              [-0.909014579212374, 53.731010260207157],
              [-0.90901246951765, 53.731618765368928],
              [-0.909873394289185, 53.732794210012379],
              [-0.911195462190001, 53.733373403496799],
              [-0.915776222772177, 53.733682784699923],
              [-0.925805269402165, 53.732390647221379],
              [-0.935866943273293, 53.732483092946872],
              [-0.945334530612316, 53.733843139381044],
              [-0.952696258230709, 53.736514351929912],
              [-0.961061842897559, 53.736695012229497],
              [-0.960911715016631, 53.742905653178305],
              [-0.962546384308876, 53.746717442494159],
              [-0.963624117025452, 53.747597747153534],
              [-0.966054388317258, 53.748671281350454],
              [-0.969025636234338, 53.749333253034926],
              [-0.969011457583581, 53.74954076463198],
              [-0.96947541326562, 53.74935869237656],
              [-0.973298486046903, 53.750168096630063],
              [-0.971383369617639, 53.750468074492112],
              [-0.964186848771795, 53.750613747627838],
              [-0.956695371465245, 53.752868713163153],
              [-0.95157455954404, 53.753748955927115],
              [-0.944560805225887, 53.756682377759702],
              [-0.939741897107616, 53.758133745164905],
              [-0.936327278710315, 53.759945240142315],
              [-0.934261510889157, 53.761614033450854],
              [-0.932088796502131, 53.762169964329019],
              [-0.930083299086491, 53.763068011561629],
              [-0.928363602727064, 53.765309660940623],
              [-0.92829080241606, 53.769896726944346],
              [-0.929433577005324, 53.773762087989731],
              [-0.930464906432444, 53.77431868807632],
              [-0.932451970660007, 53.774407402758207],
              [-0.933152236920585, 53.774737219176821],
              [-0.935429478173224, 53.776972212014442],
              [-0.936615670622315, 53.779333215947759],
              [-0.936447043849441, 53.780840880981955],
              [-0.934273943066217, 53.784668644367621],
              [-0.934187699288795, 53.786155465112316],
              [-0.934104496995692, 53.788360488707433],
              [-0.933490224001925, 53.789957666368622],
              [-0.931170423031766, 53.791285281674583],
              [-0.928038465603791, 53.797072848220587],
              [-0.928457021186545, 53.797627578780912],
              [-0.93106618493716, 53.798252184491787],
              [-0.934227085876345, 53.801072114632163],
              [-0.934471906377113, 53.801836507437926],
              [-0.932880345542215, 53.803821383637448],
              [-0.929930451683881, 53.804995059592265],
              [-0.927587676363905, 53.809863836704857],
              [-0.924839288419289, 53.812339812975111],
              [-0.921767638466444, 53.814023639322834],
              [-0.9200806574523, 53.815956254591732],
              [-0.92051880289922, 53.816520175557713],
              [-0.922341420601781, 53.816997666127662],
              [-0.923327679391044, 53.816031278902628],
              [-0.924215968991505, 53.816041943831081],
              [-0.927260861089307, 53.819512664493146],
              [-0.928923121548034, 53.820448825956831],
              [-0.930256611614625, 53.820816663155938],
              [-0.933202905076795, 53.820665824656317],
              [-0.939078543019768, 53.822536273593101],
              [-0.940605331266198, 53.823498038314099],
              [-0.940297641828193, 53.832682338670189],
              [-0.943493502091765, 53.835265910475265],
              [-0.947020308052229, 53.843634504372972],
              [-0.946947001472071, 53.844981214069307],
              [-0.944982222982473, 53.848104485418858],
              [-0.946346671835825, 53.85000402288874],
              [-0.946308646309067, 53.85115509494598],
              [-0.945268836182497, 53.852546344491657],
              [-0.941990830575799, 53.854792441532517],
              [-0.944965865594412, 53.857521419438292],
              [-0.948049834926199, 53.861075504459762],
              [-0.948126132870127, 53.862095448423382],
              [-0.947404578462578, 53.863520962349313],
              [-0.946132881470173, 53.864749293295695],
              [-0.944640567606633, 53.865495695405336],
              [-0.940591518534648, 53.866436160932096],
              [-0.939046033404637, 53.867715031923012],
              [-0.938719110643607, 53.869035222410815],
              [-0.940777347948903, 53.872926464199864],
              [-0.940739753285634, 53.874053266299669],
              [-0.938167907625553, 53.87572753926581],
              [-0.929752760570483, 53.876524682697841],
              [-0.924891225749139, 53.878011057208212],
              [-0.922680417553137, 53.879453638277134],
              [-0.923511262335481, 53.880746419578593],
              [-0.924652446815617, 53.882693627024786],
              [-0.924396718587053, 53.883707015169584],
              [-0.922927375093974, 53.885353084692433],
              [-0.923589593458693, 53.887706768365902],
              [-0.920530281007081, 53.888398367642175],
              [-0.919672145847422, 53.889283188372424],
              [-0.920837233358846, 53.890599665556103],
              [-0.922915825828908, 53.890715402390079],
              [-0.925827528956418, 53.890385548807288],
              [-0.930276932559181, 53.890614923095598],
              [-0.934127061883156, 53.890238396473066],
              [-0.944687531402331, 53.892027762489064],
              [-0.948268171780509, 53.893051483162978],
              [-0.94950870922166, 53.893826345205177],
              [-0.948714133150643, 53.894987866597496],
              [-0.94720078978032, 53.895681985027451],
              [-0.946021423610393, 53.895801969343175],
              [-0.941335817529424, 53.897848727843879],
              [-0.94028450608742, 53.898618738171074],
              [-0.9399793947909, 53.89936926244205],
              [-0.940666552717711, 53.901083995994391],
              [-0.939968150301204, 53.902579772699504],
              [-0.941036643211662, 53.904144169041594],
              [-0.938927233988146, 53.905877362774334],
              [-0.938307422864777, 53.907869975542695],
              [-0.936870544564025, 53.908285105955343],
              [-0.933196972275695, 53.908239947452728],
              [-0.932043952631964, 53.908743828750843],
              [-0.93218089011548, 53.912029357191123],
              [-0.932687386542502, 53.912584839505541],
              [-0.934818100884783, 53.913568194964164],
              [-0.934128124544949, 53.914118446814975],
              [-0.931725269706833, 53.913776218268332],
              [-0.930808622162323, 53.914019732978431],
              [-0.930775773784131, 53.914709738711409],
              [-0.931888185891482, 53.916281795106833],
              [-0.931673548177757, 53.91735398240521],
              [-0.93097157294685, 53.917773779833112],
              [-0.928894602673962, 53.917838867065193],
              [-0.927789791370283, 53.918291006056137],
              [-0.928352982922778, 53.920438831479018],
              [-0.927594505503421, 53.920921022950282],
              [-0.926732042101693, 53.921063423518724],
              [-0.92336462152129, 53.920320519005031],
              [-0.921671180904972, 53.921214939663628],
              [-0.921921056740873, 53.922746084650768],
              [-0.923236517740446, 53.925240438521165],
              [-0.92525411536557, 53.926968071369942],
              [-0.928703977407388, 53.929033824879006],
              [-0.928825512955829, 53.929997548845044],
              [-0.927719518601342, 53.930839766548495],
              [-0.926412724065067, 53.933094927964731],
              [-0.923879036604977, 53.934246122031574],
              [-0.923412122985768, 53.934987962124872],
              [-0.923776762946414, 53.935379520955465],
              [-0.925571902732407, 53.935883650041227],
              [-0.92617689665467, 53.936941580860598],
              [-0.925830256818509, 53.939228682285339],
              [-0.925067506882456, 53.940751656098435],
              [-0.927852201081928, 53.941886583979809],
              [-0.928451134267821, 53.943424418433708],
              [-0.926521110146706, 53.944659248465733],
              [-0.927089921369264, 53.946539270882205],
              [-0.925494478422988, 53.947791452897576],
              [-0.924952095506215, 53.948737554187588],
              [-0.925699689089962, 53.950360321027389],
              [-0.927601101935543, 53.951677899744034],
              [-0.927649380334292, 53.952647259116823],
              [-0.922149081937518, 53.956631953407317],
              [-0.921204392771008, 53.960403014825431],
              [-0.922275287125842, 53.962021510960369],
              [-0.923858226191183, 53.963351568083013],
              [-0.925146377195497, 53.963938340587752],
              [-0.92829241682517, 53.964298101909847],
              [-0.931313596297725, 53.965846705711158],
              [-0.933412679341262, 53.967337617249271],
              [-0.934103206139267, 53.968653320905702],
              [-0.933780420221228, 53.970489438117845],
              [-0.931287787283946, 53.972397958494604],
              [-0.930740820054054, 53.974468462328048],
              [-0.928769179860761, 53.976083157331601],
              [-0.92821560793239, 53.978523003937454],
              [-0.927038097408631, 53.980495287641517],
              [-0.92781714925195, 53.984005824646886],
              [-0.924968881417375, 53.986238659995585],
              [-0.923629687620545, 53.988374396007728],
              [-0.920305032869872, 53.990075699563704],
              [-0.925286399214759, 53.99150001861819],
              [-0.920606274008195, 53.991933560029395],
              [-0.915238175801624, 53.993128302135936],
              [-0.91323787010242, 53.992037049376862],
              [-0.911927126667077, 53.992875472702053],
              [-0.911888001302412, 53.995429553174418],
              [-0.909546385789622, 53.996308050544599],
              [-0.907482482542527, 53.997529677845129],
              [-0.90369316688921, 53.99868880980609],
              [-0.901336439221175, 53.998794927444322],
              [-0.899950043728656, 53.999708030332066],
              [-0.899200540610766, 53.999551067655482],
              [-0.898803449815556, 53.998758270365499],
              [-0.897407925108182, 53.998513584947041],
              [-0.894875886024373, 53.999075465660191],
              [-0.893526183306508, 53.999749747091762],
              [-0.892464161272543, 54.001596923299616],
              [-0.890870096639914, 54.003079656334748],
              [-0.88991151763838, 54.007052565500267],
              [-0.88854637166331, 54.009496417543204],
              [-0.886692771472994, 54.010509311625334],
              [-0.880502079801529, 54.011978020840161],
              [-0.877659786208465, 54.013538833301183],
              [-0.877233765620772, 54.014908247112928],
              [-0.878709386088332, 54.016844583647341],
              [-0.878435165430903, 54.017166498664814],
              [-0.877884010163494, 54.017003165058718],
              [-0.876161549825838, 54.017601868929802],
              [-0.873959003144347, 54.017438351661013],
              [-0.870391227416996, 54.016684037514864],
              [-0.868808189829103, 54.017090700590501],
              [-0.86815996730821, 54.016850009618473],
              [-0.866599582752379, 54.017208320842037],
              [-0.863507005761124, 54.017335525336343],
              [-0.861297987204981, 54.016964087819034],
              [-0.859721106882098, 54.016360419130173],
              [-0.854870918625856, 54.017690416735427],
              [-0.852067438774077, 54.017902771282785],
              [-0.850948335274498, 54.018358569049845],
              [-0.848493389090497, 54.018659558270606],
              [-0.845402046942112, 54.020059036439321],
              [-0.843147061333427, 54.02167501061075],
              [-0.842394123417658, 54.021085330308587],
              [-0.836992774471382, 54.021887089651884],
              [-0.836543487767984, 54.021741628916409],
              [-0.836531286308547, 54.021248958025659],
              [-0.826983571934751, 54.021253272475299],
              [-0.823505154016477, 54.020812124764134],
              [-0.82035654718398, 54.020854981093663],
              [-0.81566027264534, 54.021754378185818],
              [-0.808466012631952, 54.022351130511559],
              [-0.803311930103637, 54.023337189591999],
              [-0.80324390255566, 54.023952205697348],
              [-0.798978458637273, 54.024072366542832],
              [-0.800291893739715, 54.019965274694471],
              [-0.7991815087401, 54.019036492718378],
              [-0.795537977431037, 54.01994201829234],
              [-0.793961452673236, 54.019935204577024],
              [-0.792959856938783, 54.02057859931093],
              [-0.779949276625379, 54.023021527465417],
              [-0.778827572513446, 54.023534156089262],
              [-0.775082661715081, 54.023808857959615],
              [-0.774873830988469, 54.02405840150923],
              [-0.772263246660515, 54.023652471812674],
              [-0.771640974912738, 54.023893293792668],
              [-0.769696576140559, 54.023467146712512],
              [-0.766333724997544, 54.023673578566651],
              [-0.761554941887481, 54.02498707727618],
              [-0.752598063917487, 54.026080050448357],
              [-0.749427484858184, 54.026940553571592],
              [-0.744641547802364, 54.027548628454753],
              [-0.734247352377809, 54.030240420358183],
              [-0.732262195915907, 54.026538805396129],
              [-0.730581847776046, 54.021711441638857],
              [-0.730479726039054, 54.012682509178795],
              [-0.72782845987437, 54.012696730451466],
              [-0.726187738777528, 54.012252413714101],
              [-0.719688097286375, 54.012303819656317],
              [-0.719252487980903, 54.010685775630193],
              [-0.718050044411436, 54.010087820233814],
              [-0.715051900561081, 54.010469277827653],
              [-0.713280676644507, 54.011047159995236],
              [-0.710736548250597, 54.011152932491505],
              [-0.70963515583961, 54.010449014757626],
              [-0.709360728768487, 54.008961195446467],
              [-0.708654800130704, 54.008054780943382],
              [-0.704892932061776, 54.006053956621436],
              [-0.698590524034096, 54.007100453552709],
              [-0.688232005221537, 54.007996370577835],
              [-0.687945534416565, 54.008274580352577],
              [-0.683671758859023, 54.008745592761599],
              [-0.681788798359858, 54.010336576281759],
              [-0.6815822547512, 54.011222362273017],
              [-0.683061936896919, 54.012449312218443],
              [-0.679408453302179, 54.015967732073364],
              [-0.67840607495884, 54.018219988037352],
              [-0.679102134132771, 54.01898895091508],
              [-0.681791805844724, 54.019801354540803],
              [-0.683813744675958, 54.020977608910918],
              [-0.684135634147649, 54.022627797657812],
              [-0.687499168121981, 54.023186781757204],
              [-0.688473688488877, 54.023991984464992],
              [-0.688216314305474, 54.025136988182773],
              [-0.68720872481924, 54.026059880710292],
              [-0.687742998613882, 54.028825132089793],
              [-0.687291074777788, 54.030425516260593],
              [-0.684325334451655, 54.032868492910879],
              [-0.682664056473925, 54.033740151405382],
              [-0.682594267410201, 54.034112402775165],
              [-0.680888163992501, 54.033503167045254],
              [-0.680683434702572, 54.033651925253707],
              [-0.680233490924801, 54.034504475539535],
              [-0.680464594500257, 54.035885822094507],
              [-0.679858460208901, 54.036654863145088],
              [-0.678491851177732, 54.037231286278313],
              [-0.674237083018107, 54.038159692245813],
              [-0.670563023767303, 54.037968129999854],
              [-0.670607113222915, 54.037543468781394],
              [-0.669721162139423, 54.037408734202799],
              [-0.664250224100642, 54.037635720499381],
              [-0.659474010129086, 54.037151135535296],
              [-0.658918708567523, 54.03836825965314],
              [-0.656308089663323, 54.040621263427866],
              [-0.657963164702033, 54.042148887738094],
              [-0.658007208994043, 54.043626164988297],
              [-0.655727925977236, 54.044213716441703],
              [-0.656288798985513, 54.0454334110362],
              [-0.653643994236568, 54.045937741793644],
              [-0.644643293433156, 54.055094654550309],
              [-0.652420286517948, 54.059826304972198],
              [-0.656220004679291, 54.061294359676985],
              [-0.656482871066202, 54.061055509843207],
              [-0.660306129263902, 54.063081871261474],
              [-0.653665383150633, 54.064978872916434],
              [-0.648102466428312, 54.065994187682669],
              [-0.642912166383121, 54.066539765189255],
              [-0.632186135056637, 54.068736383585055],
              [-0.627452333911062, 54.068814597636525],
              [-0.627737531881836, 54.070175990247186],
              [-0.621504481566577, 54.069650820060737],
              [-0.618445782142378, 54.069811679756313],
              [-0.61551245173071, 54.070475455075581],
              [-0.609688815854403, 54.073251314930268],
              [-0.607973541672882, 54.07481882572425],
              [-0.605846102352591, 54.07778102492157],
              [-0.60272638471022, 54.079056237007862],
              [-0.598736800478252, 54.082189023456813],
              [-0.594093622796105, 54.084196790697355],
              [-0.592280011912059, 54.086244708042138],
              [-0.590993183843938, 54.087043992291598],
              [-0.583947389652782, 54.088460541993861],
              [-0.566454857954687, 54.090287488089857],
              [-0.563125536063563, 54.093981556548719],
              [-0.55666377144192, 54.092696185434349],
              [-0.550617365371757, 54.092665755755476],
              [-0.545508971014311, 54.091041931809222],
              [-0.541804573608804, 54.091510336905749],
              [-0.535821264260499, 54.08765175506845],
              [-0.534019169223198, 54.087161515435923],
              [-0.527915939664926, 54.089320629364984],
              [-0.526298914463351, 54.087362028793663],
              [-0.525094480970594, 54.086533816872382],
              [-0.524600664635062, 54.085456339735813],
              [-0.519913012497869, 54.086969050910227],
              [-0.513263584493391, 54.090273025933257],
              [-0.502871711315789, 54.094076632705402],
              [-0.496877268907159, 54.097213483510799],
              [-0.493998816390022, 54.099166642925361],
              [-0.489838490945353, 54.100866359583975],
              [-0.465929838002272, 54.107614532620538],
              [-0.468312749178136, 54.111232188692256],
              [-0.458666810175742, 54.114015024629751],
              [-0.459426494735492, 54.115311902912488],
              [-0.454338233768381, 54.116744150834137],
              [-0.45469019868586, 54.119950371367423],
              [-0.453590884598408, 54.120092652737078],
              [-0.453691093216851, 54.120688978256567],
              [-0.458526683377951, 54.125955332093689],
              [-0.452108502162758, 54.127968188205351],
              [-0.443711768259884, 54.131670146418152],
              [-0.438515707767442, 54.133092253917738],
              [-0.430639058437339, 54.136622083075537],
              [-0.427080370644403, 54.137424154986114],
              [-0.432645664318643, 54.157502401932724],
              [-0.431358870954167, 54.157633057154698],
              [-0.431547001957734, 54.15804448304722],
              [-0.430917288735182, 54.158132456028838],
              [-0.433099711599419, 54.16060483714319],
              [-0.433154714220232, 54.164054425088047],
              [-0.422912568007432, 54.166296384472773],
              [-0.424542600546006, 54.169557161985196],
              [-0.422630688957833, 54.170082230304899],
              [-0.422523575937998, 54.171246631058544],
              [-0.418094748657596, 54.171202023784218],
              [-0.418235989417029, 54.174045133076618],
              [-0.411903687087454, 54.174728520252557],
              [-0.408538392930333, 54.175346550365937],
              [-0.408256816557196, 54.175816522107013],
              [-0.404062747107779, 54.175830232492359],
              [-0.403243488907757, 54.174704802117752],
              [-0.396401216176861, 54.175253876252903],
              [-0.390732826195784, 54.176502277800765],
              [-0.387985607663504, 54.174532112959483],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000012",
        LAD13CDO: "00FC",
        LAD13NM: "North East Lincolnshire",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.187994717736501, 53.630956375139],
            [-0.188016459374617, 53.630554871928368],
            [-0.187488632934127, 53.63069610636424],
            [-0.175017075158893, 53.625237812703801],
            [-0.175078932438574, 53.624259792244338],
            [-0.174372629785711, 53.624437801220097],
            [-0.170860928889935, 53.623433984029127],
            [-0.164844481575666, 53.621265285681211],
            [-0.16361001929912, 53.619503268510222],
            [-0.161986687398324, 53.619803770830913],
            [-0.160101451026891, 53.618651102281888],
            [-0.152833018874973, 53.615474575821651],
            [-0.15229856697714, 53.614765135207804],
            [-0.143185742668601, 53.608621706505353],
            [-0.142286945929891, 53.60860775598907],
            [-0.141606633187501, 53.608024552217806],
            [-0.138394356800388, 53.607237468822817],
            [-0.137120244957117, 53.606616232216297],
            [-0.134096348104685, 53.604440366214966],
            [-0.130620750639147, 53.600555621102956],
            [-0.130219200917332, 53.600374048123086],
            [-0.129331410157352, 53.60062806301071],
            [-0.12860706986191, 53.600637412128151],
            [-0.111510350582175, 53.590010848102764],
            [-0.099542714156567, 53.584238708541385],
            [-0.094550194204219, 53.582581588993477],
            [-0.093551918774933, 53.581937300150983],
            [-0.093209741892267, 53.581048154078161],
            [-0.092816176256179, 53.58130078460524],
            [-0.092273358013559, 53.581647223968154],
            [-0.091682085694908, 53.581727690326943],
            [-0.090991723720843, 53.580688247401582],
            [-0.090294753950001, 53.580767918935599],
            [-0.089287114533105, 53.580233119047122],
            [-0.086273581083425, 53.580029421995938],
            [-0.085450025019104, 53.579400444728115],
            [-0.08115923039176, 53.578549581015501],
            [-0.079452801492363, 53.576905833558619],
            [-0.078544307078033, 53.577553789617575],
            [-0.077992920825675, 53.577554819797392],
            [-0.077852770402726, 53.578047005429326],
            [-0.077284155458257, 53.577828404973275],
            [-0.077259537845701, 53.578603826985457],
            [-0.072179664944768, 53.583779287452117],
            [-0.071081682520343, 53.584004311496855],
            [-0.070593582469551, 53.583343781878312],
            [-0.069846550857837, 53.583160924667254],
            [-0.067770033725325, 53.583476204637947],
            [-0.066742562063091, 53.582685583049077],
            [-0.064145303567262, 53.582891697332364],
            [-0.061840674946288, 53.58251276654309],
            [-0.051862698829519, 53.576595401474478],
            [-0.044293528812457, 53.571983257719992],
            [-0.042433179906757, 53.570307683292114],
            [-0.036702720388375, 53.567677717084251],
            [-0.035316687899575, 53.567280079483467],
            [-0.03420373285901, 53.567507214644806],
            [-0.03233194972509, 53.566188187088287],
            [-0.030119676468816, 53.563912393147532],
            [-0.029200250112975, 53.563583498901259],
            [-0.028065106909038, 53.561692196454679],
            [-0.02740420477607, 53.561325297907239],
            [-0.027065451362314, 53.561433881295478],
            [-0.026369249055792, 53.559976821062456],
            [-0.019662045310848, 53.554873851998124],
            [-0.008600770671637, 53.546794311334232],
            [-0.003387182882548, 53.543907901127497],
            [-0.001383730853576, 53.542382098653682],
            [0.001301451628082, 53.539506248146928],
            [0.004066083152235, 53.53790467689295],
            [0.005921362739578, 53.53635246215741],
            [0.008858615409823, 53.534835082683166],
            [0.014994722291567, 53.528837986680699],
            [0.017159804660956, 53.526170102573808],
            [0.01727998200555, 53.525333802965342],
            [0.012459010656652, 53.523345564271054],
            [0.011506412633217, 53.522440123490746],
            [0.01195078585071, 53.52204427737982],
            [0.009479882255879, 53.52093152516467],
            [0.007624483271445, 53.520710082485401],
            [0.004752094066464, 53.51949250030939],
            [-0.005618037696068, 53.517161364801673],
            [-0.00877316463002, 53.516655689063562],
            [-0.01350607472108, 53.516598690213698],
            [-0.014573691800006, 53.515379409799763],
            [-0.017425133772855, 53.515041069682148],
            [-0.019542903876497, 53.51794936499288],
            [-0.022469255382471, 53.517527639228547],
            [-0.02396261593431, 53.516683907188671],
            [-0.024786379086088, 53.517068806818493],
            [-0.027359022280499, 53.516985431298131],
            [-0.028633516042438, 53.51731211504304],
            [-0.032779672368495, 53.516474237153716],
            [-0.033247853827822, 53.516135829109153],
            [-0.033101143737847, 53.515362978147671],
            [-0.035434500286178, 53.51418950868603],
            [-0.037925781909375, 53.513904094456876],
            [-0.039595262212139, 53.513090929428593],
            [-0.041629660938826, 53.516202426149697],
            [-0.042364365372228, 53.51626480442939],
            [-0.043855734682677, 53.51836858602465],
            [-0.046608617248342, 53.520073139922111],
            [-0.048910493422408, 53.51945087434963],
            [-0.049880511157588, 53.519481985815453],
            [-0.050428866262785, 53.519826253480311],
            [-0.054909550066332, 53.519814740666007],
            [-0.056110377183552, 53.519549294618521],
            [-0.056638777647296, 53.519902198789708],
            [-0.057699604907779, 53.519564339636688],
            [-0.058033393226929, 53.519950933797951],
            [-0.060540294519812, 53.519723734825583],
            [-0.065040793343422, 53.518714287527359],
            [-0.06709132724313, 53.516812841154227],
            [-0.067621492978137, 53.515935011214218],
            [-0.067480727868655, 53.51408981081773],
            [-0.068409901549838, 53.513292144638534],
            [-0.068667593785672, 53.511373373605529],
            [-0.072981052161493, 53.498506552525974],
            [-0.073389714459394, 53.495279477003784],
            [-0.075258101177815, 53.489310625938757],
            [-0.079061920495147, 53.489395205550025],
            [-0.07837597795417, 53.488896924967172],
            [-0.079902911868864, 53.4887974085918],
            [-0.081900791773267, 53.487748009512913],
            [-0.082691688306533, 53.488158953848362],
            [-0.087363241713044, 53.485873970756167],
            [-0.089815293419986, 53.485275809939679],
            [-0.092904231839734, 53.486001186473601],
            [-0.095484810735952, 53.485964131039587],
            [-0.092196578490309, 53.482827250943245],
            [-0.093678195678395, 53.482186544835066],
            [-0.093231479709604, 53.481494388314857],
            [-0.094122996540891, 53.481240712803405],
            [-0.092710984305502, 53.479625175385863],
            [-0.091092346831777, 53.478754278087557],
            [-0.091283257175424, 53.478328510160217],
            [-0.089800384015711, 53.477657540932086],
            [-0.10781743522194, 53.469838049420567],
            [-0.102918492683963, 53.465631308656071],
            [-0.094980006875475, 53.461337270319355],
            [-0.085825412649965, 53.455517396780245],
            [-0.082975668325932, 53.452743253570262],
            [-0.082206630774806, 53.451126205304639],
            [-0.086944592681872, 53.449618152705881],
            [-0.089749653713746, 53.447080236729228],
            [-0.091711775315939, 53.446980346737547],
            [-0.110568068356413, 53.439996482507865],
            [-0.11207667077774, 53.437925688564803],
            [-0.120341476964078, 53.433540415064179],
            [-0.120775722282033, 53.435640098271996],
            [-0.122783678357181, 53.437321321749664],
            [-0.127648146609926, 53.435743538890868],
            [-0.131877017931093, 53.435914940766686],
            [-0.134022301103095, 53.43900773289635],
            [-0.134245652074215, 53.440713904753537],
            [-0.137915758094327, 53.439936917846794],
            [-0.13902306431205, 53.441471665513561],
            [-0.144497905181937, 53.440879005341465],
            [-0.147201495224008, 53.444109675577998],
            [-0.148667781151696, 53.446783517132012],
            [-0.149225135103441, 53.446675286170816],
            [-0.150237180640484, 53.448959998940452],
            [-0.150674287893105, 53.448916423777447],
            [-0.152545985130454, 53.452216787174152],
            [-0.151963393539204, 53.452349815097783],
            [-0.154611555343991, 53.45570710095987],
            [-0.155054884059765, 53.457189178550834],
            [-0.156460212152842, 53.457432023167179],
            [-0.156965420645315, 53.459121813390269],
            [-0.158504803174503, 53.461559316806493],
            [-0.156848833568968, 53.460944048426171],
            [-0.156638995204629, 53.461093637726897],
            [-0.156053490089589, 53.461708495338016],
            [-0.157206780446042, 53.46290485462594],
            [-0.153676962152631, 53.465653380701106],
            [-0.158041306831906, 53.46509775265551],
            [-0.159222724355801, 53.466831215759505],
            [-0.159467712078608, 53.467993775575614],
            [-0.166378376248746, 53.466808199973819],
            [-0.1669695033514, 53.46763444267868],
            [-0.168735161984929, 53.467390925803691],
            [-0.172373785839265, 53.467630931174611],
            [-0.175134531820374, 53.467896076289414],
            [-0.17543101951762, 53.46835998201869],
            [-0.17697690719923, 53.468006008757804],
            [-0.177518971977766, 53.468646259604697],
            [-0.181801690240993, 53.468625207499535],
            [-0.181951526688778, 53.46931430579766],
            [-0.183272734382263, 53.469352381877464],
            [-0.182286771523287, 53.471658543334705],
            [-0.18250074258333, 53.476100941778924],
            [-0.185158941528679, 53.477158984859422],
            [-0.184428250340405, 53.479308122017059],
            [-0.187808802364716, 53.480395955408504],
            [-0.186922987591891, 53.482014156841849],
            [-0.187307015743011, 53.482586336632082],
            [-0.188584113881908, 53.482926636109731],
            [-0.188943237239719, 53.483730368055284],
            [-0.188561701337832, 53.484513884060334],
            [-0.189347031854643, 53.484759518975459],
            [-0.191108104724603, 53.483893508919415],
            [-0.194330330094576, 53.483753456602251],
            [-0.196925538340566, 53.485142000294466],
            [-0.199556485577121, 53.485619467122007],
            [-0.202392831378272, 53.48662316678228],
            [-0.210601352329287, 53.486264511178504],
            [-0.208226078191483, 53.491911311976189],
            [-0.207540348726721, 53.494945839625601],
            [-0.206466508394234, 53.496742951128113],
            [-0.205548358307267, 53.496707594105906],
            [-0.205262221955973, 53.497021532886514],
            [-0.205981108900575, 53.501098363498514],
            [-0.2075045635913, 53.502906572173167],
            [-0.205472722652038, 53.504745938430666],
            [-0.204288474893623, 53.506471252789986],
            [-0.204082170269947, 53.511743297933471],
            [-0.204619564578787, 53.511731593268458],
            [-0.205317278613952, 53.514136020693165],
            [-0.206737189565011, 53.515548930588281],
            [-0.206850371010895, 53.516690518191993],
            [-0.210030520002302, 53.520647781020934],
            [-0.211389818965538, 53.52164620012357],
            [-0.212704662741593, 53.52449041240439],
            [-0.212490786820058, 53.524840507957109],
            [-0.213238452002419, 53.525284985276514],
            [-0.212854752707891, 53.525512082029827],
            [-0.213619686266476, 53.526335278947357],
            [-0.21361758294872, 53.527460750793196],
            [-0.212639283600568, 53.5281572101199],
            [-0.212363664986931, 53.529328035588691],
            [-0.210975722802022, 53.530470534940598],
            [-0.210585131280296, 53.531682786175928],
            [-0.215368752209626, 53.532351195443361],
            [-0.219878110394365, 53.532582928131859],
            [-0.216761193595234, 53.535984003858978],
            [-0.216252031380854, 53.536034841713686],
            [-0.214220103557731, 53.538922544735577],
            [-0.212139235514797, 53.54103098055699],
            [-0.209192192746364, 53.543166865356191],
            [-0.208490619847106, 53.544412207323191],
            [-0.204565901513135, 53.547883570881389],
            [-0.20205703939883, 53.549526037967993],
            [-0.202713158764515, 53.54985862843801],
            [-0.19486647663959, 53.557490403475704],
            [-0.19206468225544, 53.5596775095281],
            [-0.192359004276671, 53.559892315681772],
            [-0.18987982028052, 53.56098841131768],
            [-0.190513087607251, 53.561858301553727],
            [-0.189224537387315, 53.562225353666996],
            [-0.189743367620297, 53.562698870475522],
            [-0.187524908155934, 53.56358940593956],
            [-0.191687816892958, 53.568442088814038],
            [-0.195180916919672, 53.571596263849706],
            [-0.198491886179958, 53.570093671735684],
            [-0.200192754717137, 53.57090317028738],
            [-0.200784716500681, 53.570370902031101],
            [-0.202937047951037, 53.571433466129449],
            [-0.203900055355179, 53.5712681384585],
            [-0.204635425150731, 53.571592016315002],
            [-0.206296241570935, 53.570211859665427],
            [-0.207512047844014, 53.570817106058172],
            [-0.209448364687852, 53.569597453493074],
            [-0.210322368229625, 53.569785830871993],
            [-0.210705949760475, 53.569428390170643],
            [-0.213261382577958, 53.570068892316129],
            [-0.217481932809929, 53.568429236952539],
            [-0.219863810279168, 53.569427493223934],
            [-0.222554679927103, 53.567994119098188],
            [-0.225098332586795, 53.570252314942451],
            [-0.223967491368984, 53.572154802034447],
            [-0.228334447787033, 53.573474504462133],
            [-0.227150760746735, 53.574728090535203],
            [-0.228134614994313, 53.575327888074696],
            [-0.22724080496622, 53.576113816784684],
            [-0.228637532773248, 53.576846480481557],
            [-0.229277535969878, 53.57739892485219],
            [-0.228981703111523, 53.577574336434928],
            [-0.231472676618245, 53.579560123019128],
            [-0.230838370427737, 53.579846499912144],
            [-0.231344679545587, 53.58107027201477],
            [-0.232643202934828, 53.58217090494032],
            [-0.232413048627255, 53.58243719070601],
            [-0.233638252716032, 53.582653057278698],
            [-0.233393630316744, 53.58286789089717],
            [-0.234890726766996, 53.584643847687325],
            [-0.235230748633005, 53.585547817253961],
            [-0.234420146907828, 53.585966455567316],
            [-0.234664565690542, 53.586228062045812],
            [-0.236041004451745, 53.585971487428331],
            [-0.236873840528491, 53.586179736078279],
            [-0.236452273402062, 53.586435117738361],
            [-0.239159757651404, 53.588139858079025],
            [-0.240845383784088, 53.586623851588456],
            [-0.24206609204619, 53.58702474857094],
            [-0.243322948201704, 53.585753214269666],
            [-0.247181224362011, 53.586817530281827],
            [-0.250472776948497, 53.584452026984387],
            [-0.251391467316896, 53.584630865467673],
            [-0.252076351595189, 53.584984273278806],
            [-0.249040038917486, 53.586878011003911],
            [-0.25114491092646, 53.588260820499407],
            [-0.250568848301976, 53.589075837005836],
            [-0.250791406303731, 53.589430580792147],
            [-0.249951708195327, 53.589860584559531],
            [-0.250635984397944, 53.590267029030322],
            [-0.25016600050945, 53.590488489483285],
            [-0.250424515920785, 53.590704422449797],
            [-0.249474963586621, 53.591122928026344],
            [-0.249816494723891, 53.591231303901424],
            [-0.248866337074561, 53.591956337950286],
            [-0.249574153581126, 53.592524943783275],
            [-0.248530688503278, 53.592984318729151],
            [-0.248897902015589, 53.593202744995573],
            [-0.248471775122056, 53.593641487839363],
            [-0.249720881637115, 53.593983389449505],
            [-0.250716165335397, 53.593812761529392],
            [-0.254469229691871, 53.59246611249543],
            [-0.259355959677932, 53.59694754833167],
            [-0.261581572609509, 53.595704298280275],
            [-0.268291533478941, 53.59938480373097],
            [-0.269807822624312, 53.598595889222196],
            [-0.275227047124567, 53.602305868801686],
            [-0.278599864467917, 53.603470927050665],
            [-0.28303437969537, 53.604474934324507],
            [-0.282949917415818, 53.605178494317464],
            [-0.281189761490147, 53.606240946697589],
            [-0.285471923431216, 53.609260799413946],
            [-0.287073602292744, 53.611778301938678],
            [-0.29041326731435, 53.61060978978152],
            [-0.292110294244875, 53.613245446529298],
            [-0.288565813120834, 53.613867218042309],
            [-0.285001815239224, 53.615863087911002],
            [-0.282427374937348, 53.616453629286283],
            [-0.282617260166353, 53.616841101596577],
            [-0.280081658231705, 53.617366527532567],
            [-0.279237050058529, 53.617167406403617],
            [-0.274048654260177, 53.617417211766963],
            [-0.271604598020526, 53.617881751986907],
            [-0.267359937470007, 53.617936338768338],
            [-0.263840611540312, 53.619326343897747],
            [-0.261963697942309, 53.619708121074588],
            [-0.261078562273561, 53.620311939621288],
            [-0.253923701759707, 53.621449211519383],
            [-0.252987198549944, 53.622267968561594],
            [-0.250953125141922, 53.622970900655389],
            [-0.243251704496968, 53.62330578002188],
            [-0.24106260051444, 53.623768048600063],
            [-0.24099870781461, 53.623268193922932],
            [-0.240060532893157, 53.623356881751732],
            [-0.23795878438293, 53.623645083529546],
            [-0.238321350931526, 53.624379467243948],
            [-0.237598483791956, 53.624634913385421],
            [-0.235992892573956, 53.624554626013328],
            [-0.233479898035561, 53.623825371066204],
            [-0.232611493786412, 53.623980653667857],
            [-0.222155574091081, 53.629112513908439],
            [-0.220391877706101, 53.630342135489954],
            [-0.214201357589741, 53.632965660331287],
            [-0.204417339064664, 53.637877682195118],
            [-0.202802525787555, 53.63768354639565],
            [-0.199042430722979, 53.635723053543067],
            [-0.187994717736501, 53.630956375139],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000013",
        LAD13CDO: "00FD",
        LAD13NM: "North Lincolnshire",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-0.691047610448508, 53.678317214510294],
              [-0.689537150575741, 53.677130352109252],
              [-0.689740276284161, 53.673023775822109],
              [-0.693536837220621, 53.664510565489671],
              [-0.695155895458841, 53.66221987999532],
              [-0.695136634975908, 53.660834487880578],
              [-0.697227477651952, 53.656192035684484],
              [-0.698846868432997, 53.654020855271384],
              [-0.700774117121699, 53.652116367301275],
              [-0.709210084538542, 53.648228234090851],
              [-0.711687856990539, 53.646502990668544],
              [-0.712902355943787, 53.645072441417312],
              [-0.714563772587324, 53.641975649775404],
              [-0.715039317237694, 53.633196833936488],
              [-0.714652999886223, 53.627595313129859],
              [-0.712535407954738, 53.623688460279674],
              [-0.709338976632041, 53.620714671277021],
              [-0.702988522092185, 53.61727429278168],
              [-0.700890963335453, 53.615080723812106],
              [-0.700830620796078, 53.613057562975726],
              [-0.701896515313057, 53.611474493800749],
              [-0.703581287461896, 53.61021273224317],
              [-0.70852981277219, 53.608141250492096],
              [-0.71276259923432, 53.607771561199996],
              [-0.716784564619511, 53.608146440586609],
              [-0.722965754430186, 53.609197735027479],
              [-0.725127650166976, 53.609233366428811],
              [-0.728509554851657, 53.608154718072093],
              [-0.733187643828122, 53.604818207759621],
              [-0.738017819813067, 53.598990499099024],
              [-0.739752887810665, 53.592633854010067],
              [-0.739311050994235, 53.591640420398008],
              [-0.737110240075353, 53.589645946488865],
              [-0.732089802226808, 53.586113246458531],
              [-0.731610895112253, 53.584095550753283],
              [-0.733214524394725, 53.580984392245128],
              [-0.735066948809602, 53.579296093389175],
              [-0.740263193081362, 53.576337826263057],
              [-0.743899382805419, 53.574781419991446],
              [-0.746327444254123, 53.57275471452477],
              [-0.747349287515195, 53.57118965844851],
              [-0.748484418244877, 53.567830689160729],
              [-0.748977420424614, 53.563200251438303],
              [-0.748282413863854, 53.558970875197105],
              [-0.747153519785842, 53.55624978838938],
              [-0.744814927618357, 53.553348806869593],
              [-0.740040037701658, 53.549089973723078],
              [-0.738846034796532, 53.546199121825843],
              [-0.739537494416204, 53.542787903333796],
              [-0.7422619670298, 53.539513135398167],
              [-0.74350071330316, 53.537224977032629],
              [-0.743622335753769, 53.535359243002517],
              [-0.742844593621274, 53.533089440993123],
              [-0.739539954752445, 53.528579934985821],
              [-0.738573119573418, 53.526186756267805],
              [-0.739187426193846, 53.523076747022806],
              [-0.74172817522757, 53.517204023230939],
              [-0.743448568294616, 53.511204868371721],
              [-0.745123454028686, 53.508976102992442],
              [-0.749002593003222, 53.505571263419178],
              [-0.75289205305121, 53.50081715420442],
              [-0.753790240021609, 53.500675504810438],
              [-0.762541547129454, 53.501263501870312],
              [-0.765714157734369, 53.50072729453953],
              [-0.768331631597811, 53.499419421558208],
              [-0.769207941896156, 53.497947956396203],
              [-0.769259246981353, 53.494405913672324],
              [-0.774486434774929, 53.491182220422537],
              [-0.776241138538042, 53.488713834546033],
              [-0.775232963541655, 53.485778481139796],
              [-0.773079383855616, 53.484052075714935],
              [-0.772085035696377, 53.482632384950392],
              [-0.771721686110479, 53.481020513578216],
              [-0.77207423131315, 53.479405210598898],
              [-0.773548364846479, 53.478764146397502],
              [-0.777143057081819, 53.478944802782394],
              [-0.780706770731982, 53.478577602976998],
              [-0.782742053451817, 53.478014960304343],
              [-0.784518367459513, 53.47696873685593],
              [-0.785732873808109, 53.475278559777067],
              [-0.786202067638161, 53.473250905899476],
              [-0.784759835929001, 53.464401821783007],
              [-0.785104363839713, 53.463308661090515],
              [-0.787426546803947, 53.4613717352034],
              [-0.794286929747592, 53.457137195685206],
              [-0.797951514619774, 53.455314281549384],
              [-0.805436863509035, 53.457178164275597],
              [-0.813538990493602, 53.458443637146068],
              [-0.818442713395288, 53.45953764700289],
              [-0.871916429187322, 53.466290041316391],
              [-0.873732997415024, 53.461457628153973],
              [-0.886348445873425, 53.462118787457257],
              [-0.889985571643516, 53.461790370536946],
              [-0.896586827807888, 53.461871278052492],
              [-0.900609178642532, 53.461028331087803],
              [-0.910039883704135, 53.461015837372699],
              [-0.91264228542538, 53.460439083300052],
              [-0.914814257440279, 53.460850743195671],
              [-0.916246254669013, 53.460274962853809],
              [-0.920456768228896, 53.464462359873821],
              [-0.92024398760993, 53.465486972506568],
              [-0.912089349309453, 53.469932651610584],
              [-0.902172331843527, 53.47371080532676],
              [-0.900467646337624, 53.475135161291355],
              [-0.935561236081607, 53.502495865213191],
              [-0.935357044271985, 53.50270258856095],
              [-0.935770889265864, 53.502793464809805],
              [-0.93209067814997, 53.510651063397475],
              [-0.950005404423241, 53.513636704698321],
              [-0.947913394095183, 53.519495208927253],
              [-0.948469846513685, 53.520352243099069],
              [-0.946054837555885, 53.527167874985437],
              [-0.946539800034683, 53.527321362032751],
              [-0.943712110075872, 53.530622279598525],
              [-0.934325068294885, 53.530023946170978],
              [-0.925256709310374, 53.532580118293239],
              [-0.908190982254374, 53.533647127481522],
              [-0.900432696764564, 53.532602560645778],
              [-0.898071000279669, 53.532738129107365],
              [-0.897309785554628, 53.532943249890522],
              [-0.892264354468802, 53.537573520429177],
              [-0.895590014447671, 53.542039365835436],
              [-0.899829746605755, 53.54445688075046],
              [-0.901262158592806, 53.54788759622673],
              [-0.900500700460483, 53.549606452339169],
              [-0.900299074995032, 53.555637888934143],
              [-0.894305617120869, 53.565557519884322],
              [-0.893870009461708, 53.56581776397131],
              [-0.891856046001851, 53.565286765608846],
              [-0.890991354048371, 53.566538084366293],
              [-0.901113867657122, 53.568703400843354],
              [-0.896233861313169, 53.582895811028045],
              [-0.897922328415393, 53.583256542044573],
              [-0.887050411177116, 53.600812552702962],
              [-0.886991460647041, 53.609666818514498],
              [-0.865333161626515, 53.637688111132221],
              [-0.848258673483347, 53.633016334490776],
              [-0.841211539041099, 53.635486757422306],
              [-0.833521903688662, 53.640766684479154],
              [-0.82961582989456, 53.641877329441087],
              [-0.829147660151985, 53.643215670967038],
              [-0.8271541287836, 53.642717060734618],
              [-0.820878095253856, 53.643851825080141],
              [-0.820088747096581, 53.645142025188569],
              [-0.816573097623616, 53.644816095336893],
              [-0.814174615426755, 53.643386524842676],
              [-0.805800124804572, 53.646335206003741],
              [-0.804220152659571, 53.647692921391808],
              [-0.803244125638835, 53.650960472835941],
              [-0.801536392260845, 53.652706089280372],
              [-0.799783584292293, 53.653955949255703],
              [-0.793496073951945, 53.657262654017195],
              [-0.788737765147398, 53.659189452031121],
              [-0.781212703236377, 53.660588162041137],
              [-0.778299726079362, 53.661418706567808],
              [-0.772130289683504, 53.664054940329684],
              [-0.769890148947374, 53.661619365540396],
              [-0.771141299504525, 53.660020522581917],
              [-0.774464737549376, 53.657874800707631],
              [-0.774493962764128, 53.656188805053667],
              [-0.753237462578534, 53.662678625021798],
              [-0.758972752658944, 53.664346287767493],
              [-0.754927650043602, 53.666386105569593],
              [-0.746207816881743, 53.668893797273455],
              [-0.746601839681086, 53.669591855885159],
              [-0.73053756236772, 53.673460434165889],
              [-0.723185062659528, 53.673708574387035],
              [-0.722697164134173, 53.676576194242287],
              [-0.72362478067287, 53.677892155402532],
              [-0.72176266886375, 53.679340169349921],
              [-0.719430717318168, 53.679451891227949],
              [-0.71799038463934, 53.679966823526513],
              [-0.717687591409777, 53.678221552160231],
              [-0.715169331858182, 53.678288049857706],
              [-0.713836277260195, 53.677418008583409],
              [-0.70900650548418, 53.677228520942641],
              [-0.704811614549181, 53.677812451425645],
              [-0.702449735076433, 53.677470482155506],
              [-0.703620532228417, 53.679672836076676],
              [-0.704811280053672, 53.68053785727902],
              [-0.704620619782106, 53.681126360712845],
              [-0.701434619901516, 53.683697721595493],
              [-0.698487749070803, 53.684590688505352],
              [-0.695559407144143, 53.680567798517195],
              [-0.691047610448508, 53.678317214510294],
            ],
          ],
          [
            [
              [-0.555482023506901, 53.690496821167457],
              [-0.546403262773818, 53.689914176120396],
              [-0.539264142426488, 53.688341450920227],
              [-0.535894035020532, 53.688315587243892],
              [-0.53471400393939, 53.68801980247094],
              [-0.534525141182918, 53.68752759167095],
              [-0.532403420382598, 53.687661619041371],
              [-0.532384873580609, 53.687454644805726],
              [-0.534370991996231, 53.687431321171466],
              [-0.534406442805563, 53.687020957728492],
              [-0.525068664380124, 53.685255958910453],
              [-0.522766451162847, 53.684881510877723],
              [-0.522030775320152, 53.686041906407077],
              [-0.524702947710708, 53.686881162744577],
              [-0.522758576130346, 53.686274710956987],
              [-0.523324528572677, 53.686892043365376],
              [-0.522944905009763, 53.687138156171002],
              [-0.521108821684393, 53.68681257118164],
              [-0.520599608864091, 53.685657486825377],
              [-0.520765580866363, 53.685199298969486],
              [-0.52332170158949, 53.683553488702806],
              [-0.524336548733431, 53.683154299084471],
              [-0.525171616217037, 53.683176270025172],
              [-0.530117205092212, 53.682126022023553],
              [-0.530438421623272, 53.681249939427843],
              [-0.530926931199762, 53.681265820064837],
              [-0.530871639433249, 53.681636388005465],
              [-0.531223104193693, 53.681282935870996],
              [-0.532221907545713, 53.681297876577894],
              [-0.532293184317148, 53.681643029045539],
              [-0.532630689318314, 53.681255243551483],
              [-0.532935544874325, 53.681676067937872],
              [-0.53284898849375, 53.681291177085662],
              [-0.533450106440809, 53.681247301485058],
              [-0.533587346124994, 53.681742478285607],
              [-0.533884693733657, 53.681246329585065],
              [-0.534786088053643, 53.681232190595772],
              [-0.534943310510136, 53.682155486828158],
              [-0.535245766730907, 53.681293544835412],
              [-0.535825600208645, 53.681252093728112],
              [-0.536266172863197, 53.681689849736678],
              [-0.536032146041156, 53.682063628792591],
              [-0.534488764827096, 53.682687468595866],
              [-0.53431537063029, 53.683364016724752],
              [-0.534878068727254, 53.683293595563605],
              [-0.534942886999379, 53.682691226288718],
              [-0.53673645246239, 53.681969760523636],
              [-0.543567943615929, 53.682857538725393],
              [-0.547120661510049, 53.683856214730568],
              [-0.547120324804974, 53.684525890181632],
              [-0.557049242707145, 53.688289142580579],
              [-0.558594551203132, 53.689467324365403],
              [-0.557933521037851, 53.690198261329698],
              [-0.5568052929429, 53.690507377725517],
              [-0.556555871403071, 53.690272457106708],
              [-0.555482023506901, 53.690496821167457],
            ],
          ],
          [
            [
              [-0.299791313331691, 53.714431779702721],
              [-0.29578962809189, 53.713822083675893],
              [-0.29421201789777, 53.714125043724778],
              [-0.289357371636703, 53.713177572544595],
              [-0.283867398588243, 53.711017137947039],
              [-0.279382440732833, 53.708265007286776],
              [-0.275402194453437, 53.704929373026587],
              [-0.273484021596841, 53.702823413015132],
              [-0.273641898390421, 53.702445440285331],
              [-0.273190772583169, 53.702585463851001],
              [-0.272317117286722, 53.701723375954685],
              [-0.268719157677965, 53.697479705732583],
              [-0.268879500768584, 53.697226726166711],
              [-0.267169028709111, 53.695648636847395],
              [-0.267616952278364, 53.695253292756824],
              [-0.266705657014341, 53.695534959353573],
              [-0.266411093730603, 53.695367989470057],
              [-0.263917461016384, 53.691477242506188],
              [-0.26558137707841, 53.690680621688372],
              [-0.267494335531333, 53.690677743889403],
              [-0.26816605597421, 53.690031236450956],
              [-0.267080482575649, 53.690392188301097],
              [-0.265589578472221, 53.690186326498811],
              [-0.26320042507862, 53.690536449759286],
              [-0.2627619101782, 53.690188494904902],
              [-0.252156030813972, 53.677422101889107],
              [-0.24181365616533, 53.668330745318528],
              [-0.237481674499092, 53.666038583774785],
              [-0.236460214873551, 53.665017627771313],
              [-0.235937239033992, 53.665108807703497],
              [-0.23548900847628, 53.664787573434225],
              [-0.235682477461547, 53.664431746366795],
              [-0.237805692546429, 53.663678236549792],
              [-0.234780098155105, 53.664148763734559],
              [-0.233936733809752, 53.663902602371444],
              [-0.231758198411496, 53.66203480826502],
              [-0.231004140777441, 53.660741776612305],
              [-0.228467632358392, 53.6583589288391],
              [-0.225340265419312, 53.654485806381473],
              [-0.219174906841937, 53.649541745845113],
              [-0.218814621670918, 53.648828015851478],
              [-0.204281739459376, 53.638434795205647],
              [-0.204417339064664, 53.637877682195118],
              [-0.214201357589741, 53.632965660331287],
              [-0.220391877706101, 53.630342135489954],
              [-0.222155574091081, 53.629112513908439],
              [-0.232611493786412, 53.623980653667857],
              [-0.233479898035561, 53.623825371066204],
              [-0.235992892573956, 53.624554626013328],
              [-0.237598483791956, 53.624634913385421],
              [-0.238321350931526, 53.624379467243948],
              [-0.23795878438293, 53.623645083529546],
              [-0.240060532893157, 53.623356881751732],
              [-0.24099870781461, 53.623268193922932],
              [-0.24106260051444, 53.623768048600063],
              [-0.243251704496968, 53.62330578002188],
              [-0.250953125141922, 53.622970900655389],
              [-0.252987198549944, 53.622267968561594],
              [-0.253923701759707, 53.621449211519383],
              [-0.261078562273561, 53.620311939621288],
              [-0.261963697942309, 53.619708121074588],
              [-0.263840611540312, 53.619326343897747],
              [-0.267359937470007, 53.617936338768338],
              [-0.271604598020526, 53.617881751986907],
              [-0.274048654260177, 53.617417211766963],
              [-0.279237050058529, 53.617167406403617],
              [-0.280081658231705, 53.617366527532567],
              [-0.282617260166353, 53.616841101596577],
              [-0.282427374937348, 53.616453629286283],
              [-0.285001815239224, 53.615863087911002],
              [-0.288565813120834, 53.613867218042309],
              [-0.292110294244875, 53.613245446529298],
              [-0.29228539923862, 53.613514032012695],
              [-0.29823249729289, 53.612539796111299],
              [-0.300925417428584, 53.616366192823612],
              [-0.304417690495114, 53.614821902582719],
              [-0.30439492991666, 53.614446722340418],
              [-0.304939117616438, 53.614259361514122],
              [-0.306294946982679, 53.614038540846479],
              [-0.308550358350386, 53.61430686041453],
              [-0.306805472799481, 53.609553764111695],
              [-0.305750206784875, 53.607971975879131],
              [-0.306186895668702, 53.607371373121318],
              [-0.305003087421921, 53.605793151356266],
              [-0.305088986645295, 53.604704851949535],
              [-0.303126561110973, 53.602871965987511],
              [-0.303419607068039, 53.602725099125877],
              [-0.303074476782979, 53.602361527647723],
              [-0.303833229683325, 53.601869777177392],
              [-0.303160552758697, 53.601420657687633],
              [-0.304048838581291, 53.600524421170832],
              [-0.300738768953389, 53.595261796991913],
              [-0.304573467139018, 53.593839233609366],
              [-0.303419668206101, 53.590768271292127],
              [-0.306976385017282, 53.590239734480967],
              [-0.310863699660584, 53.590132869387183],
              [-0.310327154735309, 53.588319318366139],
              [-0.310913708815237, 53.588272765253109],
              [-0.310790028163661, 53.587699291778371],
              [-0.312292918668753, 53.587708815470272],
              [-0.312089294338051, 53.587093763240865],
              [-0.312870613521451, 53.587049948284353],
              [-0.312437964423066, 53.585736784309859],
              [-0.31439094707131, 53.585557559597284],
              [-0.314281951073919, 53.583930736265003],
              [-0.315676855870563, 53.583765200829717],
              [-0.315400273670432, 53.583198568699892],
              [-0.316934278365624, 53.582687086756458],
              [-0.316294726471442, 53.5822447973663],
              [-0.318143408447222, 53.581301741495913],
              [-0.317617117146924, 53.580936562418756],
              [-0.320942497450267, 53.579549468466567],
              [-0.320010482208508, 53.57894038493815],
              [-0.323603193151331, 53.576020679970306],
              [-0.322828567544353, 53.575328423813886],
              [-0.326804142870604, 53.571114120982052],
              [-0.328779207146273, 53.568517718364994],
              [-0.329863143239559, 53.566303480132639],
              [-0.330400346726458, 53.566236367533079],
              [-0.335935265395139, 53.558605066022544],
              [-0.348655361290421, 53.559491528840951],
              [-0.345851890055246, 53.55793717406231],
              [-0.347930850582023, 53.556037678847083],
              [-0.349376283244576, 53.555787953618477],
              [-0.356320399890585, 53.556031913425777],
              [-0.35988995054711, 53.557810476843613],
              [-0.362798803956451, 53.558603641278978],
              [-0.368368229197296, 53.558960216669483],
              [-0.368751890848182, 53.559308844376389],
              [-0.373228966860595, 53.558941027500452],
              [-0.388345124906815, 53.559729975381721],
              [-0.400103949339288, 53.561398949169266],
              [-0.408839465708897, 53.562097983346433],
              [-0.419665629223288, 53.563751714508783],
              [-0.424324231661078, 53.569631049385329],
              [-0.428189530849697, 53.572581846324368],
              [-0.428997265717979, 53.57450357918237],
              [-0.434091230925475, 53.572796780725291],
              [-0.439403678143518, 53.57027189657375],
              [-0.44035042524026, 53.568756965419624],
              [-0.441958570427304, 53.567610214450362],
              [-0.442522227660069, 53.566243093770595],
              [-0.445087696455461, 53.563816103655839],
              [-0.446418253103559, 53.562983905434116],
              [-0.447671115746629, 53.562663972203254],
              [-0.449416472574757, 53.561262708557095],
              [-0.450772743776892, 53.557945259854272],
              [-0.452379607100334, 53.559420527188955],
              [-0.453260547933589, 53.5595245117972],
              [-0.455273460367523, 53.559141512632152],
              [-0.456905396248394, 53.559269549656705],
              [-0.457654524573482, 53.558846827790937],
              [-0.458478129450722, 53.558991385259752],
              [-0.459035777231268, 53.55851944270016],
              [-0.460851868942824, 53.558024145195809],
              [-0.460902200489325, 53.557302954485515],
              [-0.462450653612459, 53.556484170809952],
              [-0.462727112907509, 53.555937581055161],
              [-0.465668604698779, 53.556002318778802],
              [-0.466503688711548, 53.55536939696519],
              [-0.467152564666931, 53.553664360355796],
              [-0.466709816499279, 53.549709698479624],
              [-0.467015382694768, 53.548939639029314],
              [-0.473041338010212, 53.548802782031373],
              [-0.474856092468324, 53.548118482749551],
              [-0.475072672659109, 53.547384124610616],
              [-0.47652846093366, 53.545891582285662],
              [-0.481100140692829, 53.545806802506661],
              [-0.483267794942224, 53.545587108271846],
              [-0.483638789183957, 53.545257412309034],
              [-0.490905894806955, 53.545852730602824],
              [-0.491870367251283, 53.543941197271721],
              [-0.49406757043115, 53.541779103645169],
              [-0.49657971478931, 53.540091966713284],
              [-0.499757791721872, 53.538898553307],
              [-0.501226297722874, 53.537440033556528],
              [-0.481364015104876, 53.538224111321668],
              [-0.473927438887023, 53.539066124177182],
              [-0.463415049496495, 53.540824654431738],
              [-0.454176062068607, 53.542941223993523],
              [-0.450676994571101, 53.543126968669171],
              [-0.450337952920737, 53.54402150607288],
              [-0.446402020732948, 53.544552946393239],
              [-0.442584338795363, 53.546218450658898],
              [-0.439320397500967, 53.546404229628912],
              [-0.437040530937984, 53.545933979417171],
              [-0.433724922450816, 53.545842958249501],
              [-0.430661641006598, 53.546316090256482],
              [-0.429297827792951, 53.545076536619774],
              [-0.427683816845564, 53.544237298408582],
              [-0.427395993642013, 53.540927237239579],
              [-0.42185928477101, 53.534426915818578],
              [-0.418283351084791, 53.534786886025465],
              [-0.414397001343766, 53.535820431557106],
              [-0.412347076712915, 53.535235891646138],
              [-0.411999203419658, 53.534531899903151],
              [-0.408142069307393, 53.532087643747843],
              [-0.407821812016017, 53.530086839853936],
              [-0.40948678326143, 53.529098593050513],
              [-0.408509776753803, 53.527270637686051],
              [-0.406168901989137, 53.524483311759305],
              [-0.405823960708919, 53.522219680034127],
              [-0.406853643914647, 53.52014516998284],
              [-0.405071459394894, 53.517595388647578],
              [-0.413478492487806, 53.515503050105352],
              [-0.415527586539788, 53.514669940848265],
              [-0.417665930067137, 53.514677586690574],
              [-0.419467674025202, 53.5141656531028],
              [-0.421599711401618, 53.514017629052631],
              [-0.425011543830137, 53.512707027981477],
              [-0.427318664260601, 53.512226804142024],
              [-0.435557328847589, 53.511329889929215],
              [-0.437704778802276, 53.51083928656336],
              [-0.439005664532289, 53.510895833206369],
              [-0.444033623802486, 53.509370263834988],
              [-0.447546350999527, 53.509148049879514],
              [-0.449969713612707, 53.508651820821946],
              [-0.45236604073856, 53.50868916027617],
              [-0.461556515098081, 53.509956623900571],
              [-0.466964571866375, 53.509697986696565],
              [-0.470686917284541, 53.509095734584662],
              [-0.475835635782225, 53.508957431536146],
              [-0.478561899153922, 53.508372795025196],
              [-0.481316709952349, 53.506945261554684],
              [-0.488380833098445, 53.504838706499207],
              [-0.488056416152194, 53.504590993449661],
              [-0.486492665307606, 53.504510077836755],
              [-0.485143577986093, 53.503567988944667],
              [-0.483784990510823, 53.50154165184523],
              [-0.482400696806251, 53.50036536167196],
              [-0.480683769335037, 53.496223742110672],
              [-0.483180541365816, 53.493427408172735],
              [-0.485005004009228, 53.490326753780991],
              [-0.48605721085693, 53.489223610839979],
              [-0.487219012168184, 53.488724131402748],
              [-0.487769139114785, 53.487792606549782],
              [-0.487208299433763, 53.487246149012826],
              [-0.487445701185951, 53.486038289897024],
              [-0.488640769997798, 53.485066378818502],
              [-0.48768653953497, 53.483801194172941],
              [-0.487777249655628, 53.482945657710516],
              [-0.486627328837977, 53.480448244917213],
              [-0.48615758307645, 53.479885854921193],
              [-0.481504677096314, 53.478350773371233],
              [-0.473560617627824, 53.47662069652074],
              [-0.471841223902195, 53.475323130515456],
              [-0.47174668945244, 53.474850878482769],
              [-0.483549348956996, 53.472397015097229],
              [-0.500030165254584, 53.469599880633901],
              [-0.505317212963231, 53.468172099847912],
              [-0.50528704773419, 53.467958675120137],
              [-0.508912515776664, 53.467506858926626],
              [-0.508776393261133, 53.467737085219106],
              [-0.512695583708205, 53.467758961788938],
              [-0.529710117217544, 53.463793084695411],
              [-0.551707374424999, 53.460791419170235],
              [-0.551790552217539, 53.459497968269204],
              [-0.566050192891255, 53.458103114480863],
              [-0.584480860769852, 53.456842346177119],
              [-0.597168107512822, 53.456713308655182],
              [-0.599477399047125, 53.456379929819349],
              [-0.613399939166429, 53.456928845202725],
              [-0.621670431479736, 53.457799405878923],
              [-0.629750132401361, 53.458197068354522],
              [-0.627158344387175, 53.460382261488732],
              [-0.626646975007497, 53.462039389765565],
              [-0.629025396328655, 53.465032240994248],
              [-0.634804243962589, 53.4695300741799],
              [-0.633344180215796, 53.470380842901946],
              [-0.633469302559912, 53.471222763212424],
              [-0.634797693705842, 53.472363392247523],
              [-0.634121863086942, 53.472624446705993],
              [-0.634434982132206, 53.47300826765737],
              [-0.632545589697665, 53.473805578724424],
              [-0.633539660037321, 53.474942395196663],
              [-0.632596010495031, 53.47537746567626],
              [-0.634334358522166, 53.479990814291718],
              [-0.632408469939356, 53.480085651952827],
              [-0.631991640669026, 53.481332175436876],
              [-0.634055857661015, 53.485377540828715],
              [-0.630131741503211, 53.485972660165707],
              [-0.631291468006095, 53.488290769624619],
              [-0.628448789457589, 53.488759780264239],
              [-0.628641013951735, 53.49074409792258],
              [-0.625723957448802, 53.495559353102422],
              [-0.625768709099698, 53.49865124079917],
              [-0.628190635055445, 53.506801656613064],
              [-0.624488528987901, 53.512817803731174],
              [-0.644248816208508, 53.513226886772038],
              [-0.658347590535716, 53.51383897781453],
              [-0.675703948635319, 53.51250433457183],
              [-0.676125736144872, 53.513003405420818],
              [-0.677591005788088, 53.513555370331758],
              [-0.678037827365971, 53.513251087032444],
              [-0.678642574140012, 53.513687450139464],
              [-0.680744023093066, 53.513520986327954],
              [-0.681662962416773, 53.514573855491406],
              [-0.683590434527127, 53.514523179965558],
              [-0.683860605619638, 53.51417558029064],
              [-0.684709050063621, 53.514098621680979],
              [-0.686452711557496, 53.515992012083643],
              [-0.687352049413681, 53.516367743887336],
              [-0.688879718893517, 53.515737292488524],
              [-0.691016317099498, 53.516193975535472],
              [-0.6921921533845, 53.515918293476382],
              [-0.695438983152529, 53.516271975622821],
              [-0.697719281110595, 53.516127839170906],
              [-0.698118280073206, 53.515510141368601],
              [-0.699754589285986, 53.515364346026416],
              [-0.700162943973898, 53.515129677120527],
              [-0.699935177790021, 53.514588756188907],
              [-0.701575520347556, 53.51416162245409],
              [-0.713884133501143, 53.516825070466744],
              [-0.726353211402611, 53.518916350697168],
              [-0.73011533843655, 53.519405815550229],
              [-0.738576527566451, 53.519826170765931],
              [-0.737269036464893, 53.523572473517014],
              [-0.736907960378518, 53.527140890835582],
              [-0.737992953438483, 53.529312402826008],
              [-0.741198438271432, 53.533549440478026],
              [-0.741800551052591, 53.536447533673581],
              [-0.740951806263559, 53.538506966686803],
              [-0.737482169353116, 53.542479476853579],
              [-0.736923081484518, 53.545393194154563],
              [-0.738221806298207, 53.549325194990992],
              [-0.739548529356166, 53.551205292628929],
              [-0.742426628620606, 53.553815417946907],
              [-0.745125621364768, 53.557082482626747],
              [-0.746119141282686, 53.560446677291999],
              [-0.7461227159249, 53.566605937228232],
              [-0.745115200040043, 53.571158156485261],
              [-0.74404762381028, 53.572480012682561],
              [-0.74204542733485, 53.573944848140407],
              [-0.734878744316997, 53.577353393318511],
              [-0.732462836933435, 53.579047407006662],
              [-0.730171158555798, 53.581629898406739],
              [-0.729355073925824, 53.583607788590768],
              [-0.729251864267263, 53.584631430415861],
              [-0.730294986138718, 53.58692389288597],
              [-0.730923418269807, 53.587176855931077],
              [-0.731243936007161, 53.587975774345097],
              [-0.735925130225411, 53.591135480799053],
              [-0.737107507711991, 53.592980803359076],
              [-0.736721296701642, 53.596000594973972],
              [-0.735246315475171, 53.598436289865269],
              [-0.729358862127036, 53.605339426422944],
              [-0.727518633035444, 53.606827313683695],
              [-0.726277038582418, 53.607353440800964],
              [-0.723111718618768, 53.60751567132418],
              [-0.71465383115766, 53.606066015597577],
              [-0.70575451099919, 53.606863609761668],
              [-0.701799423911206, 53.608578109666759],
              [-0.69922726515013, 53.610434261226416],
              [-0.698102737336833, 53.614166819020596],
              [-0.698527113434648, 53.615851462562453],
              [-0.700649502782472, 53.618316807723616],
              [-0.707592088494262, 53.622665290719489],
              [-0.710302051998802, 53.62562579537245],
              [-0.711158322311514, 53.628788318333342],
              [-0.711637828710444, 53.640847559748174],
              [-0.710308470189583, 53.64331866892536],
              [-0.708047400329216, 53.645283528433026],
              [-0.699765888163723, 53.649330561586993],
              [-0.696673573886904, 53.651435394589754],
              [-0.695030397871568, 53.653053472161972],
              [-0.69233213381156, 53.657419591479112],
              [-0.691050100368699, 53.658699981150932],
              [-0.689552853854179, 53.663257138098274],
              [-0.685107377732203, 53.672218839521271],
              [-0.684564146478146, 53.675176497274713],
              [-0.685226254121091, 53.679777963646579],
              [-0.687505763234049, 53.683840746459225],
              [-0.693130488754359, 53.689480705645764],
              [-0.694936171065825, 53.692304001054801],
              [-0.695151717975004, 53.693708603050382],
              [-0.694315351615521, 53.69464960737443],
              [-0.694903018647428, 53.694502302235286],
              [-0.693886982269684, 53.695424267394422],
              [-0.691390506500098, 53.696756131694059],
              [-0.686222032220255, 53.698328337405265],
              [-0.670528377088696, 53.701973665023353],
              [-0.670023124749834, 53.702335700188407],
              [-0.668023387421118, 53.702435732854021],
              [-0.667224878728029, 53.701578307706583],
              [-0.667296236471591, 53.702484276164995],
              [-0.658388800588432, 53.704152966115828],
              [-0.65392553380961, 53.706013930933018],
              [-0.646715532931944, 53.707718793901243],
              [-0.646555469362384, 53.707560580103546],
              [-0.639498222702093, 53.710148542151295],
              [-0.631498336102091, 53.711127111848604],
              [-0.618394091721941, 53.713792937149798],
              [-0.611417518306005, 53.714582413183145],
              [-0.607836355352, 53.713082863350152],
              [-0.606592119109471, 53.712034679487253],
              [-0.605843534474859, 53.711990915024337],
              [-0.601281070128003, 53.707244643011343],
              [-0.600506646663833, 53.707023461841352],
              [-0.595626912786277, 53.702103367729613],
              [-0.592204910574434, 53.699423162534764],
              [-0.587666729552629, 53.696932851930192],
              [-0.584320935656728, 53.696161662086567],
              [-0.585104823421412, 53.694375834278688],
              [-0.586248916296914, 53.693318760432284],
              [-0.58545804541628, 53.693914379192684],
              [-0.584516753144271, 53.694117197115311],
              [-0.584840434149459, 53.694265743031721],
              [-0.584076365462226, 53.695370442739161],
              [-0.583497376358968, 53.695382474742388],
              [-0.579872438801272, 53.693595704262556],
              [-0.57855708319716, 53.693347290262814],
              [-0.577199164096109, 53.691936086254998],
              [-0.574994263165927, 53.690763787146203],
              [-0.568593420194226, 53.688240678608167],
              [-0.567994999555847, 53.688247908474153],
              [-0.567259531426739, 53.687385162858192],
              [-0.566116306367784, 53.687917113152352],
              [-0.56094169736869, 53.68405452301171],
              [-0.557879756659363, 53.682597438907173],
              [-0.55594323037783, 53.682063533479315],
              [-0.55373239892614, 53.680919528174314],
              [-0.553164980107842, 53.6810412178097],
              [-0.54870533422117, 53.6796047573294],
              [-0.542789394026472, 53.678522563988437],
              [-0.536239378010488, 53.678314150562379],
              [-0.530617130440105, 53.678682173218604],
              [-0.52578946455482, 53.678231826381968],
              [-0.525533976517889, 53.677960808556747],
              [-0.525434072355385, 53.677049889547213],
              [-0.524625894460811, 53.677258369986617],
              [-0.52482330187521, 53.677978125620314],
              [-0.523973030126164, 53.677831016847875],
              [-0.523507921157074, 53.676974023341934],
              [-0.523429627885051, 53.677697573543441],
              [-0.524151976996896, 53.678255706123196],
              [-0.517640948957552, 53.678406316042384],
              [-0.509010710682431, 53.679572039011966],
              [-0.503397123767236, 53.680836899003516],
              [-0.497895579412277, 53.683453956624483],
              [-0.491900880402213, 53.687817420874929],
              [-0.491415294050449, 53.688311097257838],
              [-0.491712708166167, 53.688802947949036],
              [-0.491249601447921, 53.689091057531456],
              [-0.490735400594135, 53.688902102017153],
              [-0.488630779209672, 53.689947958924066],
              [-0.485169928711187, 53.691391929968752],
              [-0.483864763000792, 53.692605119899071],
              [-0.480745068417053, 53.69339708113646],
              [-0.478832609917419, 53.695023201057055],
              [-0.478148153447767, 53.695144848757167],
              [-0.475250605363034, 53.697116157796792],
              [-0.473522690296332, 53.697231684600148],
              [-0.470486953201573, 53.698199737615596],
              [-0.463212527526692, 53.697842358920084],
              [-0.462538026404657, 53.697646731370789],
              [-0.462537800116479, 53.697320425168257],
              [-0.46195929265418, 53.697732788646604],
              [-0.443910375971968, 53.697345981003053],
              [-0.443193483815428, 53.696906088703102],
              [-0.443003515992088, 53.695445588007765],
              [-0.442541448054717, 53.697376731925701],
              [-0.425301166240394, 53.697733836803387],
              [-0.42496646717332, 53.697083118894703],
              [-0.424542717698079, 53.697681615433119],
              [-0.418565746932572, 53.697838373772797],
              [-0.422314086252146, 53.697612732168572],
              [-0.422569592112386, 53.697220575609528],
              [-0.416393608523701, 53.697394380447307],
              [-0.417004252807156, 53.697752128654798],
              [-0.418286666710215, 53.697848172821111],
              [-0.414485708206083, 53.698021754104445],
              [-0.413842265363384, 53.697529620585996],
              [-0.413349297883631, 53.698030978184711],
              [-0.406515451008482, 53.698613554216642],
              [-0.405152611475911, 53.698481255580333],
              [-0.402053267145784, 53.698844463664358],
              [-0.395482944009016, 53.699739153587601],
              [-0.396253536250357, 53.699790822669357],
              [-0.395155888276825, 53.700053887488068],
              [-0.394181021487099, 53.698762565269064],
              [-0.393529619328684, 53.696854421854141],
              [-0.393906255490674, 53.698419990394733],
              [-0.393336402645691, 53.700336927400159],
              [-0.388530185593296, 53.70135826055818],
              [-0.387645153734382, 53.702159870976573],
              [-0.387023105827311, 53.701867439495722],
              [-0.384979705929067, 53.701901933852483],
              [-0.372855435813606, 53.703172538346401],
              [-0.366144617843393, 53.704452949268763],
              [-0.364984629224617, 53.704244754439856],
              [-0.365047133576317, 53.704820915542854],
              [-0.362486661836989, 53.705487100555963],
              [-0.361551019707308, 53.7038715345954],
              [-0.360791786587241, 53.704072394215565],
              [-0.362053843730475, 53.705591750646931],
              [-0.358689173842817, 53.705858523693003],
              [-0.356195699642526, 53.706861688959258],
              [-0.355335991126956, 53.706723141859662],
              [-0.352619958808354, 53.707175735466159],
              [-0.335973825506974, 53.710472146481656],
              [-0.335500262674358, 53.710915028891058],
              [-0.335148029517322, 53.710702483624566],
              [-0.332434081670714, 53.711320052779001],
              [-0.329462359070699, 53.71167238030236],
              [-0.327193150652177, 53.711426773574317],
              [-0.325938477790564, 53.711618697264186],
              [-0.325808561314974, 53.711952180155279],
              [-0.325671920339245, 53.711653625972332],
              [-0.324013074208047, 53.711682568769902],
              [-0.319997765133752, 53.712211529162083],
              [-0.318645576656018, 53.711997493471344],
              [-0.311908542788996, 53.712492449942509],
              [-0.307494314484227, 53.713063013985675],
              [-0.306353333048041, 53.713589840785069],
              [-0.304649136313778, 53.713312240019661],
              [-0.303844436518863, 53.71384110457371],
              [-0.299791313331691, 53.714431779702721],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000014",
        LAD13CDO: "00FF",
        LAD13NM: "York",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.024451772957325, 54.052018900983747],
            [-1.014367331079953, 54.04930501918188],
            [-1.012424736954675, 54.051291611043801],
            [-1.008010902695543, 54.053256920882767],
            [-1.007101704289826, 54.054167093948649],
            [-1.006199169300417, 54.053836074129677],
            [-1.004419818811146, 54.055550651131377],
            [-1.002330790946888, 54.055327520419176],
            [-1.001551795891289, 54.054750320422059],
            [-1.002093747701749, 54.0538083815755],
            [-1.000660703587061, 54.051970125535064],
            [-1.000899423543121, 54.051442715388184],
            [-1.000393142806666, 54.05096214575898],
            [-0.999781828953002, 54.050975939375469],
            [-0.998745744128935, 54.050423546487416],
            [-0.998167922260741, 54.04936264570884],
            [-0.996496705789084, 54.049055707263484],
            [-0.99596848614811, 54.048157892053695],
            [-0.993777132817013, 54.047256060938885],
            [-0.99488080975984, 54.046203804049824],
            [-0.993958865761061, 54.045675691267071],
            [-0.992916604268723, 54.043681517837072],
            [-0.993266606340229, 54.042279620847196],
            [-0.992312309432062, 54.041704486203678],
            [-0.990575401208376, 54.041917319673352],
            [-0.98925622998023, 54.041717493680181],
            [-0.988702586418466, 54.040933578740237],
            [-0.987261328256461, 54.040292290977767],
            [-0.985556515229526, 54.039627191940099],
            [-0.984598108520796, 54.038851527461873],
            [-0.982511701497252, 54.039560128951038],
            [-0.98224339014431, 54.039416746890026],
            [-0.981350109613933, 54.033855478491283],
            [-0.98230464537078, 54.030905595050434],
            [-0.975773895565592, 54.030136505055985],
            [-0.973307790733417, 54.029288577286778],
            [-0.971124801350299, 54.02767454199725],
            [-0.970134173251916, 54.025938558134953],
            [-0.970609887015339, 54.023055665533533],
            [-0.966835884165585, 54.022958587491708],
            [-0.971756746716767, 54.020373551553561],
            [-0.979412310602004, 54.017402599380269],
            [-0.976931360148799, 54.015756474995086],
            [-0.982744213356343, 54.010012979823543],
            [-0.979915384283148, 54.007379775641915],
            [-0.97933433664629, 54.005371401523142],
            [-0.980870730232425, 54.004000269472108],
            [-0.98477257264338, 54.002661698210268],
            [-0.984505801372696, 54.002213636450442],
            [-0.980038706509103, 54.00283465005564],
            [-0.980159249119319, 54.003208678114134],
            [-0.975410443664432, 54.004732230778593],
            [-0.973741354597944, 54.003487526445547],
            [-0.972747620243345, 54.001710183694946],
            [-0.971921115121012, 53.996677873822122],
            [-0.979093716992236, 53.9959363591501],
            [-0.9786192934968, 53.99506407930209],
            [-0.978855346232886, 53.994518709388728],
            [-0.98318119556528, 53.993335690652685],
            [-0.981253251202947, 53.991144248359817],
            [-0.987928161018497, 53.989054270943448],
            [-0.990496784691958, 53.987721362484123],
            [-0.992830079211391, 53.985831862330102],
            [-0.994768774267564, 53.985311500641274],
            [-0.994060274461052, 53.984461591245065],
            [-0.995809266229769, 53.983962994348509],
            [-0.994827885182886, 53.983046095001448],
            [-0.993171237468625, 53.982751801621546],
            [-0.991050645236795, 53.981905317547799],
            [-0.984648972491223, 53.981529638840364],
            [-0.980344850865888, 53.982312022044717],
            [-0.979234095863443, 53.983022542661494],
            [-0.97676170394872, 53.981981370456772],
            [-0.976303662701942, 53.982066452825585],
            [-0.973375521259177, 53.984868243298436],
            [-0.972684407083238, 53.984569326188819],
            [-0.970072341339279, 53.984804034513324],
            [-0.967036311056093, 53.985900599790895],
            [-0.96223360904697, 53.98449843096062],
            [-0.957677094259218, 53.983937695167391],
            [-0.956568004905596, 53.985052488174688],
            [-0.953254665421399, 53.986488747117896],
            [-0.944353462074174, 53.989110045127994],
            [-0.935106456675094, 53.989756524964768],
            [-0.934149022188578, 53.989262679847236],
            [-0.93278919737498, 53.990072131711493],
            [-0.925286399214759, 53.99150001861819],
            [-0.920305032869872, 53.990075699563704],
            [-0.923629687620545, 53.988374396007728],
            [-0.924968881417375, 53.986238659995585],
            [-0.92781714925195, 53.984005824646886],
            [-0.927038097408631, 53.980495287641517],
            [-0.92821560793239, 53.978523003937454],
            [-0.928769179860761, 53.976083157331601],
            [-0.930740820054054, 53.974468462328048],
            [-0.931287787283946, 53.972397958494604],
            [-0.933780420221228, 53.970489438117845],
            [-0.934103206139267, 53.968653320905702],
            [-0.933412679341262, 53.967337617249271],
            [-0.931313596297725, 53.965846705711158],
            [-0.92829241682517, 53.964298101909847],
            [-0.925146377195497, 53.963938340587752],
            [-0.923858226191183, 53.963351568083013],
            [-0.922275287125842, 53.962021510960369],
            [-0.921204392771008, 53.960403014825431],
            [-0.922149081937518, 53.956631953407317],
            [-0.927649380334292, 53.952647259116823],
            [-0.927601101935543, 53.951677899744034],
            [-0.925699689089962, 53.950360321027389],
            [-0.924952095506215, 53.948737554187588],
            [-0.925494478422988, 53.947791452897576],
            [-0.927089921369264, 53.946539270882205],
            [-0.926521110146706, 53.944659248465733],
            [-0.928451134267821, 53.943424418433708],
            [-0.927852201081928, 53.941886583979809],
            [-0.925067506882456, 53.940751656098435],
            [-0.925830256818509, 53.939228682285339],
            [-0.92617689665467, 53.936941580860598],
            [-0.925571902732407, 53.935883650041227],
            [-0.923776762946414, 53.935379520955465],
            [-0.923412122985768, 53.934987962124872],
            [-0.923879036604977, 53.934246122031574],
            [-0.926412724065067, 53.933094927964731],
            [-0.927719518601342, 53.930839766548495],
            [-0.928825512955829, 53.929997548845044],
            [-0.928703977407388, 53.929033824879006],
            [-0.92525411536557, 53.926968071369942],
            [-0.923236517740446, 53.925240438521165],
            [-0.921921056740873, 53.922746084650768],
            [-0.921671180904972, 53.921214939663628],
            [-0.92336462152129, 53.920320519005031],
            [-0.926732042101693, 53.921063423518724],
            [-0.927594505503421, 53.920921022950282],
            [-0.928352982922778, 53.920438831479018],
            [-0.927789791370283, 53.918291006056137],
            [-0.928894602673962, 53.917838867065193],
            [-0.93097157294685, 53.917773779833112],
            [-0.931673548177757, 53.91735398240521],
            [-0.931888185891482, 53.916281795106833],
            [-0.930775773784131, 53.914709738711409],
            [-0.930808622162323, 53.914019732978431],
            [-0.931725269706833, 53.913776218268332],
            [-0.934128124544949, 53.914118446814975],
            [-0.934818100884783, 53.913568194964164],
            [-0.932687386542502, 53.912584839505541],
            [-0.93218089011548, 53.912029357191123],
            [-0.932043952631964, 53.908743828750843],
            [-0.933196972275695, 53.908239947452728],
            [-0.936870544564025, 53.908285105955343],
            [-0.938307422864777, 53.907869975542695],
            [-0.938927233988146, 53.905877362774334],
            [-0.941036643211662, 53.904144169041594],
            [-0.939968150301204, 53.902579772699504],
            [-0.940666552717711, 53.901083995994391],
            [-0.9399793947909, 53.89936926244205],
            [-0.94028450608742, 53.898618738171074],
            [-0.941335817529424, 53.897848727843879],
            [-0.946021423610393, 53.895801969343175],
            [-0.94720078978032, 53.895681985027451],
            [-0.948714133150643, 53.894987866597496],
            [-0.94950870922166, 53.893826345205177],
            [-0.948268171780509, 53.893051483162978],
            [-0.944687531402331, 53.892027762489064],
            [-0.934127061883156, 53.890238396473066],
            [-0.930276932559181, 53.890614923095598],
            [-0.925827528956418, 53.890385548807288],
            [-0.922915825828908, 53.890715402390079],
            [-0.920837233358846, 53.890599665556103],
            [-0.919672145847422, 53.889283188372424],
            [-0.920530281007081, 53.888398367642175],
            [-0.923589593458693, 53.887706768365902],
            [-0.922927375093974, 53.885353084692433],
            [-0.924396718587053, 53.883707015169584],
            [-0.924652446815617, 53.882693627024786],
            [-0.923511262335481, 53.880746419578593],
            [-0.924472803663584, 53.880386516257396],
            [-0.925378243737721, 53.88073078677008],
            [-0.925744540595758, 53.881633794614366],
            [-0.926828774809708, 53.882542321616327],
            [-0.927665166903606, 53.882079706208692],
            [-0.928237113883073, 53.882379629577564],
            [-0.928576900279837, 53.882239748172978],
            [-0.929793956320923, 53.881286157568852],
            [-0.93046305491291, 53.879507940954596],
            [-0.93267111129478, 53.879477256251313],
            [-0.934427592823289, 53.879796663116053],
            [-0.934967043316693, 53.879999192356912],
            [-0.934446458653403, 53.881328437406808],
            [-0.934513941345192, 53.882922662024811],
            [-0.935596008257387, 53.884224776885773],
            [-0.938163428303171, 53.885812371721713],
            [-0.938605481286972, 53.887521358941306],
            [-0.939583912947939, 53.887877850740566],
            [-0.941577532642596, 53.887499057410992],
            [-0.944065213220419, 53.888101603509575],
            [-0.945148801387346, 53.886942650988061],
            [-0.948242497149809, 53.886072759365383],
            [-0.948020717393054, 53.884846612188859],
            [-0.957496146933274, 53.884290190636335],
            [-0.963662257122169, 53.882876683958528],
            [-0.967702824845768, 53.882818013534497],
            [-0.980932553905861, 53.878363194459901],
            [-0.986901158711869, 53.877122093669989],
            [-0.992826662785934, 53.875190936077146],
            [-0.998537566646538, 53.880947147959432],
            [-1.00299800739936, 53.886185763740023],
            [-1.008198353104352, 53.88997602366166],
            [-1.018201560313817, 53.888467314549729],
            [-1.021536374249935, 53.888352521153585],
            [-1.023740974164181, 53.886396652866942],
            [-1.027073419272153, 53.886376063288949],
            [-1.029394880085586, 53.885007969287358],
            [-1.036058313882074, 53.883167831588878],
            [-1.046513322445071, 53.88080741662661],
            [-1.05114377724381, 53.878800180546328],
            [-1.053468518381691, 53.877300421812976],
            [-1.05680147625035, 53.876412550527562],
            [-1.061149042047406, 53.875786891495267],
            [-1.064953026423431, 53.874567217075366],
            [-1.068544512723303, 53.879068559868017],
            [-1.071104483089658, 53.879762506595434],
            [-1.07322519975434, 53.879823835490555],
            [-1.075077138942372, 53.881173760318795],
            [-1.076161138925343, 53.881065259249475],
            [-1.076296048042319, 53.882191617824162],
            [-1.077274574875399, 53.882452608302565],
            [-1.077663696487528, 53.883573727681068],
            [-1.079294806642145, 53.883493667569944],
            [-1.080938113600668, 53.882579575709904],
            [-1.083656015940579, 53.882192285074105],
            [-1.085774281192134, 53.882442124691906],
            [-1.087465509474705, 53.884279588764507],
            [-1.090044174507298, 53.884912151213364],
            [-1.091193405172634, 53.885797201036617],
            [-1.093316830723806, 53.884553112516045],
            [-1.095312275076262, 53.884439632561673],
            [-1.09557163142075, 53.886591555087918],
            [-1.093389722975572, 53.888778998602092],
            [-1.093004649656705, 53.890190827051207],
            [-1.093464512753125, 53.890948405964217],
            [-1.096566310617298, 53.89253572048402],
            [-1.097273298460198, 53.893269077445815],
            [-1.097534049348256, 53.893031952462643],
            [-1.098596078344584, 53.893814707463115],
            [-1.099017412181752, 53.893752255822186],
            [-1.099041347568652, 53.893276063486717],
            [-1.098291973818277, 53.892813840951213],
            [-1.094531895287057, 53.891125436358493],
            [-1.09360102401524, 53.890124323545777],
            [-1.093613143687154, 53.88942333863416],
            [-1.096339186685422, 53.886556887086108],
            [-1.095819982934851, 53.884177407011762],
            [-1.096325726402662, 53.882877031346929],
            [-1.097222398596902, 53.882071246042344],
            [-1.099574881772556, 53.88105257470319],
            [-1.100227592898994, 53.880339313083262],
            [-1.100627105692852, 53.876749734652869],
            [-1.101922150838942, 53.87615362559022],
            [-1.10533180408578, 53.875824048322997],
            [-1.108983541936142, 53.877341441826204],
            [-1.111959280024992, 53.879043375305727],
            [-1.115598483375132, 53.879665251841104],
            [-1.117430939223906, 53.880342959785175],
            [-1.11864552012145, 53.881397203444131],
            [-1.121046047821165, 53.882630896289413],
            [-1.124978037711011, 53.882657816508043],
            [-1.125234796415986, 53.885589822146578],
            [-1.129108133849996, 53.888965158370425],
            [-1.133349881789105, 53.891037948920655],
            [-1.134310811145519, 53.892545896943858],
            [-1.134809034397831, 53.894725511966008],
            [-1.136812802181385, 53.895924565178724],
            [-1.138810661025829, 53.894159260996219],
            [-1.141562902841131, 53.892625819149714],
            [-1.141986076871734, 53.892915564358923],
            [-1.143554853767944, 53.892471963822068],
            [-1.146760394778203, 53.894592607826084],
            [-1.150000073014776, 53.893621496326439],
            [-1.150231292110205, 53.894975843316509],
            [-1.1523223350908, 53.895719556585298],
            [-1.154389611979619, 53.895910297300418],
            [-1.154316699661323, 53.896657594221054],
            [-1.155776248456836, 53.897330286234343],
            [-1.160117068053305, 53.900413978045655],
            [-1.161513751366245, 53.902565599412448],
            [-1.162195889585533, 53.905519351326483],
            [-1.164522278972758, 53.90915236017625],
            [-1.164909820515777, 53.910558094067468],
            [-1.170589287493153, 53.908803425919224],
            [-1.17306306229953, 53.911263440174309],
            [-1.176401945052327, 53.913951341346298],
            [-1.176909658146067, 53.913913476256475],
            [-1.177376769180737, 53.914314847235993],
            [-1.179298052586387, 53.91687969922036],
            [-1.185707371629374, 53.91604698162255],
            [-1.186704092915107, 53.918424781979532],
            [-1.191126408201529, 53.922563974240276],
            [-1.195631502689666, 53.922362331544832],
            [-1.196048450165109, 53.923486829526325],
            [-1.197603482265085, 53.923869330410149],
            [-1.199488098047249, 53.925333470236616],
            [-1.20010778139165, 53.926454809834169],
            [-1.200066634245765, 53.928448080360475],
            [-1.197985629490399, 53.928640931229999],
            [-1.197727627920333, 53.929308816657333],
            [-1.200143978725795, 53.932369170959902],
            [-1.199752853981296, 53.93291034171839],
            [-1.199889440120065, 53.934529094665287],
            [-1.20179204844095, 53.938272673680856],
            [-1.20267445208174, 53.93897060902669],
            [-1.20334331355691, 53.940884098166848],
            [-1.203873036024409, 53.941226456822847],
            [-1.203436246370036, 53.942083714069334],
            [-1.204125867598027, 53.943471087782882],
            [-1.205415943352551, 53.944129907137594],
            [-1.20681440332944, 53.945502625454971],
            [-1.207375263894008, 53.945492844235233],
            [-1.208991348518714, 53.951234235948711],
            [-1.211824819549679, 53.954184732731235],
            [-1.213029560916588, 53.956648143269263],
            [-1.216039526697415, 53.959649125386719],
            [-1.218344191453787, 53.961103109759364],
            [-1.219913185803864, 53.962619686255728],
            [-1.219812782150067, 53.963752416507326],
            [-1.217478622710656, 53.965979738623396],
            [-1.219179765932444, 53.96720327711926],
            [-1.220885826889122, 53.969149454242711],
            [-1.221469706251743, 53.970457389071115],
            [-1.221601099433855, 53.972651299332497],
            [-1.223692311143679, 53.974646664554058],
            [-1.22350744507196, 53.975487640662656],
            [-1.222380209033528, 53.976497788872415],
            [-1.222098863245345, 53.979324472745411],
            [-1.220735401679115, 53.981464657471427],
            [-1.218998191100387, 53.982788991042334],
            [-1.217345637898511, 53.983251907947782],
            [-1.217450705573081, 53.984399452118595],
            [-1.216512159040235, 53.985573454600441],
            [-1.214630530421074, 53.985559379704043],
            [-1.213282612250274, 53.985224304680635],
            [-1.210827822424347, 53.985440104414828],
            [-1.207169971415879, 53.986527845165682],
            [-1.204863178647072, 53.986814605153555],
            [-1.203386985047847, 53.986650240237076],
            [-1.202248084612767, 53.987111858695066],
            [-1.201984877897432, 53.988195854695618],
            [-1.200309927013369, 53.989010716616178],
            [-1.197531249620558, 53.98836709077613],
            [-1.197286035523499, 53.986842447267925],
            [-1.19503280036725, 53.985295835805239],
            [-1.192211831049681, 53.984174991681094],
            [-1.187409077413513, 53.98396915691648],
            [-1.186441815374736, 53.98438235239518],
            [-1.185743072001392, 53.98377183366334],
            [-1.18209535636127, 53.985328052952084],
            [-1.180613721552466, 53.989800255254359],
            [-1.181623636895445, 53.992667098364294],
            [-1.183153719686625, 53.994839103393666],
            [-1.183275076064705, 53.99619890409604],
            [-1.183954314929075, 53.996489332476294],
            [-1.184155467435394, 53.997046151989437],
            [-1.185552776808431, 53.997203027263254],
            [-1.185904554164564, 53.997859731162407],
            [-1.186625126497844, 53.998145031316533],
            [-1.189396665932183, 53.998002864156909],
            [-1.189515133251129, 53.998422500312444],
            [-1.190356458326964, 53.998618712563371],
            [-1.190460790675039, 53.999059823555527],
            [-1.191921095883634, 53.999907321323136],
            [-1.191927881532223, 54.000502367596496],
            [-1.191360866780372, 54.000555179600688],
            [-1.190985674997066, 54.001258207916358],
            [-1.189285031002507, 54.001629639201859],
            [-1.18884790338266, 54.001906215620643],
            [-1.188999219727618, 54.002362924080671],
            [-1.188562404051438, 54.002466034621932],
            [-1.18438570689506, 54.000954768277488],
            [-1.183432117532497, 54.001037276654934],
            [-1.182247906543447, 54.00050972630941],
            [-1.180758562990225, 53.998974335702314],
            [-1.179507148903205, 53.998843566756328],
            [-1.178814672692192, 53.999148024371806],
            [-1.178618629082501, 54.000040086098927],
            [-1.177955494930805, 54.000247669528839],
            [-1.178197827090881, 54.000492000278456],
            [-1.177597416902876, 54.000612827461325],
            [-1.177479247308783, 54.000416082179171],
            [-1.176011105616384, 54.000829361924609],
            [-1.176321842320702, 54.001472330711536],
            [-1.175069781572239, 54.001371171571989],
            [-1.175131943883006, 54.001622361421077],
            [-1.176093614080994, 54.001750291841354],
            [-1.175502344124483, 54.00217945738266],
            [-1.172958976164311, 54.001492389407204],
            [-1.171360541801901, 54.000549335211971],
            [-1.167184445778993, 53.996296148515697],
            [-1.161337440539056, 53.994626888877193],
            [-1.158926721816905, 53.992841218738334],
            [-1.151904234724699, 53.989160748047823],
            [-1.150011136701717, 53.988878638172594],
            [-1.147208514352319, 53.988997205320153],
            [-1.143561632138902, 53.989527636646073],
            [-1.139373811798913, 53.990768630606368],
            [-1.136989840873857, 53.991065221693759],
            [-1.138907341653109, 53.992605129032519],
            [-1.139577882212745, 53.994039916164645],
            [-1.139392095842578, 53.995208820220519],
            [-1.140986605935192, 53.996001277574877],
            [-1.140562353871531, 53.997343744326152],
            [-1.14187538019455, 53.997957113992278],
            [-1.142255906416551, 53.998517981770981],
            [-1.143779217727616, 53.99877421061867],
            [-1.145084710281978, 53.999908792755249],
            [-1.146856211470534, 54.00056491323766],
            [-1.147362856916417, 54.001603020722804],
            [-1.148385635706733, 54.001648018460173],
            [-1.149082641913095, 54.002930141126519],
            [-1.148551101338555, 54.004829127496777],
            [-1.147174955083158, 54.006616964871569],
            [-1.144092283366513, 54.008204803649441],
            [-1.142867691210832, 54.010771127598439],
            [-1.143740377476958, 54.01263784885473],
            [-1.145915271470179, 54.01329415533344],
            [-1.148060712065442, 54.015694771639922],
            [-1.147907120779982, 54.016116116332448],
            [-1.142373278003165, 54.018935841277674],
            [-1.145090044298673, 54.022881111433612],
            [-1.145025967443052, 54.024517356871193],
            [-1.144805994322069, 54.025492780071822],
            [-1.143880799378836, 54.025647980525022],
            [-1.142041667580777, 54.027190684516],
            [-1.140628260552504, 54.02777379671695],
            [-1.140788669952947, 54.029842164206727],
            [-1.127576812695192, 54.031014356425409],
            [-1.120273720312229, 54.029028757248447],
            [-1.116963874220326, 54.033573967942473],
            [-1.115435699521775, 54.034573881458321],
            [-1.115491944628904, 54.035233110277709],
            [-1.114894613556839, 54.035823717195619],
            [-1.111574881876322, 54.036051804229452],
            [-1.107066398486472, 54.037385484560566],
            [-1.09977208872456, 54.03767265632893],
            [-1.099768425116876, 54.039397413766203],
            [-1.101068071666813, 54.040947664788433],
            [-1.099447425464025, 54.041532341489287],
            [-1.09842980931789, 54.04092342619068],
            [-1.09747912818992, 54.041103246170749],
            [-1.09609779373103, 54.040438552653228],
            [-1.094268630805946, 54.046609390147545],
            [-1.093070332780507, 54.046159953605823],
            [-1.09174870196657, 54.047305830279008],
            [-1.088997838624177, 54.048082259401163],
            [-1.083780610939104, 54.047588746777528],
            [-1.071131705255907, 54.048804883690082],
            [-1.062160857154678, 54.050121224473138],
            [-1.058724042439312, 54.047828519994518],
            [-1.058955813279738, 54.049671065953703],
            [-1.058410859229526, 54.05242700117477],
            [-1.059702311915697, 54.056590430789129],
            [-1.059702192490938, 54.05686635962217],
            [-1.047448082020446, 54.054425904279974],
            [-1.035850011579379, 54.053598299285603],
            [-1.028453621257886, 54.052746955110486],
            [-1.024451772957325, 54.052018900983747],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000015",
        LAD13CDO: "00FK",
        LAD13NM: "Derby",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.480468757807326, 52.967296137355241],
            [-1.4784967896723, 52.964624871287214],
            [-1.477643546561485, 52.96438920245388],
            [-1.476669206112398, 52.964319297120873],
            [-1.474634063323327, 52.96590952482984],
            [-1.47346444357862, 52.965243637527344],
            [-1.471644555787478, 52.964795988901457],
            [-1.468255656031153, 52.965062251425131],
            [-1.46764543821247, 52.964802426952566],
            [-1.466512558620925, 52.962250674544826],
            [-1.46784685034507, 52.960643966724589],
            [-1.467830832767386, 52.959765637433172],
            [-1.466181126065927, 52.957851606305908],
            [-1.467598256446777, 52.955670853489423],
            [-1.466011281971569, 52.95399441780809],
            [-1.467357079212241, 52.953007131060261],
            [-1.468555948936434, 52.950643802883214],
            [-1.46257250322479, 52.949822253510071],
            [-1.459350866896699, 52.949415714171096],
            [-1.459763748554973, 52.948768559521518],
            [-1.457799940654681, 52.948232845415014],
            [-1.456893338778013, 52.948727617233722],
            [-1.452992960749963, 52.948338478778062],
            [-1.452752738078693, 52.94744293261482],
            [-1.450474159242518, 52.94885813620872],
            [-1.449942046710682, 52.94809877222157],
            [-1.445067480191237, 52.949182713404142],
            [-1.441083830819907, 52.949485874914437],
            [-1.434036891069403, 52.951290867509151],
            [-1.431616892849042, 52.950600628296009],
            [-1.42843145813602, 52.950287811769172],
            [-1.424061644001142, 52.95028920051363],
            [-1.420256401809746, 52.950699501797168],
            [-1.409283609033419, 52.950303901854767],
            [-1.416433997065365, 52.945742044321847],
            [-1.422901101817, 52.938513787401483],
            [-1.423967517712972, 52.935632467627364],
            [-1.423571190812042, 52.935674594770617],
            [-1.420227734924559, 52.931907065056805],
            [-1.419256945114882, 52.931410607069907],
            [-1.417458900224519, 52.931699359335745],
            [-1.416165012295254, 52.931336133331122],
            [-1.41450221653769, 52.931192216094956],
            [-1.410361361811631, 52.931017133264454],
            [-1.408801535836298, 52.931288957093194],
            [-1.40787475411152, 52.931066801301327],
            [-1.404844991570101, 52.931669244430594],
            [-1.40419555250977, 52.932269180262836],
            [-1.403577377357235, 52.932216638959076],
            [-1.401005950805701, 52.930537075777991],
            [-1.397166170772248, 52.929900993955094],
            [-1.396252067365827, 52.929308447970833],
            [-1.396468181128458, 52.928530163618554],
            [-1.390833498667746, 52.928079820790686],
            [-1.387710697508069, 52.927049772019444],
            [-1.386648558477764, 52.92705148823768],
            [-1.386551449763356, 52.926659947580902],
            [-1.38530316695711, 52.926361343465771],
            [-1.383078240411988, 52.925912932207211],
            [-1.38393680269447, 52.923797679424602],
            [-1.3863025311733, 52.92385396429971],
            [-1.387098725691775, 52.922349647131142],
            [-1.389611660722109, 52.92272125492218],
            [-1.38937764740209, 52.920006146345294],
            [-1.388158838457382, 52.918050072559929],
            [-1.387797298803583, 52.911413110204748],
            [-1.388916172659528, 52.9096767144637],
            [-1.391774911808674, 52.907389181757111],
            [-1.391763862561522, 52.906691544413427],
            [-1.390649269339937, 52.906228272575689],
            [-1.391303916306842, 52.905029738170271],
            [-1.391114423816626, 52.904228706747396],
            [-1.388267719645834, 52.901449832598786],
            [-1.393644106350751, 52.901836063066725],
            [-1.398249312066732, 52.901554726651341],
            [-1.400339084621155, 52.901375611678617],
            [-1.402776655622557, 52.900443993512553],
            [-1.403495464279144, 52.896922839795273],
            [-1.402953732832391, 52.896211750528693],
            [-1.403107843429273, 52.895597645923992],
            [-1.405830109997851, 52.895450376116109],
            [-1.405924674782072, 52.894513248692355],
            [-1.406509285745115, 52.894589883173623],
            [-1.406512149078536, 52.893618137255714],
            [-1.407902773331104, 52.89148378494437],
            [-1.407588597947635, 52.891415697194773],
            [-1.407593029146514, 52.889672662775283],
            [-1.409287706473037, 52.889320621310254],
            [-1.40986817364476, 52.888274434974974],
            [-1.409738768861548, 52.88642106355141],
            [-1.41038091755879, 52.886521337693623],
            [-1.411010892327697, 52.884777808353412],
            [-1.411666295735096, 52.884226404859319],
            [-1.41378380533071, 52.884238667715934],
            [-1.416741072262664, 52.884796183428222],
            [-1.418917342076929, 52.884528171787707],
            [-1.420382018331217, 52.881898714406859],
            [-1.420560566167473, 52.879874257585456],
            [-1.419595207957734, 52.878611918975714],
            [-1.420097756674704, 52.878253894725795],
            [-1.416469582856993, 52.875680424124333],
            [-1.425412126853098, 52.873915321461489],
            [-1.425187169974231, 52.872949662275879],
            [-1.425825963121826, 52.87216617133295],
            [-1.429782931061184, 52.870192259115498],
            [-1.427532460808307, 52.868820429514351],
            [-1.426145473020122, 52.869118482228082],
            [-1.425000562567184, 52.868858544518019],
            [-1.423572756978986, 52.865059859295584],
            [-1.427960501690157, 52.864737667696204],
            [-1.427686018566254, 52.863922796782965],
            [-1.437912381807165, 52.862816468654039],
            [-1.438216792024125, 52.861672647326841],
            [-1.439085024568795, 52.862416584704143],
            [-1.440814043702564, 52.862063357663487],
            [-1.440887065336969, 52.862275853285489],
            [-1.441565617331923, 52.861716302572681],
            [-1.443566803160367, 52.861099124515775],
            [-1.446231947061724, 52.861291366614076],
            [-1.444882934530768, 52.862134577815674],
            [-1.444854665761552, 52.86318621574911],
            [-1.447104800083501, 52.864487598327763],
            [-1.449912537640817, 52.86693048772603],
            [-1.450656352119075, 52.866963594388515],
            [-1.45290782097832, 52.868306223214717],
            [-1.463761885244256, 52.872467487529853],
            [-1.467169134351875, 52.872905320086993],
            [-1.467666340160577, 52.870518148961295],
            [-1.484426226914642, 52.871214123429397],
            [-1.483830708037829, 52.870318884649571],
            [-1.487540650006053, 52.869782091050126],
            [-1.487532016375965, 52.869506077394284],
            [-1.490361269096754, 52.86931867997324],
            [-1.493670877180592, 52.869722971500437],
            [-1.49519513015448, 52.870359617754389],
            [-1.493598813374578, 52.873616896131438],
            [-1.495373950087634, 52.874667224073413],
            [-1.494950611167211, 52.874914433296482],
            [-1.496662136100672, 52.875819741125511],
            [-1.496717293166459, 52.876831287463069],
            [-1.499893689539179, 52.877245627000832],
            [-1.499998324082227, 52.879000806473748],
            [-1.499146913451172, 52.881193341230329],
            [-1.499498255952674, 52.882686169055262],
            [-1.502007408061349, 52.88158381791542],
            [-1.501649291560698, 52.882620596525975],
            [-1.502782576396497, 52.882539044945055],
            [-1.503116048453428, 52.884827351429777],
            [-1.51461557517161, 52.884687894529868],
            [-1.515833981204714, 52.886440408818018],
            [-1.523201580591945, 52.887323291121255],
            [-1.524264080182704, 52.888828781839919],
            [-1.524746873435811, 52.890617809285274],
            [-1.526375830753879, 52.890893095271736],
            [-1.528100924372107, 52.890666232426796],
            [-1.528421096008507, 52.890198255828828],
            [-1.530837227762392, 52.889148864944282],
            [-1.530663837365921, 52.888964796300996],
            [-1.533949647475574, 52.888019454701485],
            [-1.534357045757873, 52.888419282616063],
            [-1.53589270317144, 52.888104367092438],
            [-1.536261518081243, 52.888361106085441],
            [-1.536909864268309, 52.888188340481328],
            [-1.537122878105023, 52.888421996090372],
            [-1.5386748108609, 52.888532307262892],
            [-1.539907983574102, 52.888991052742739],
            [-1.539666024588766, 52.88924361725735],
            [-1.540374821479918, 52.889538516266605],
            [-1.541694795023962, 52.889522038889645],
            [-1.541670448849148, 52.892113591462262],
            [-1.542063368301027, 52.89234613370671],
            [-1.539641730912111, 52.893924307896071],
            [-1.541086431674057, 52.894073725125132],
            [-1.54243682603732, 52.895134287155017],
            [-1.543076313340739, 52.896377284459149],
            [-1.542437603228605, 52.896755079043267],
            [-1.544222713175908, 52.898757583581073],
            [-1.544979459543561, 52.900312052270458],
            [-1.54748147727958, 52.899596159602531],
            [-1.548627942057486, 52.900856336637318],
            [-1.549998079832565, 52.902201849445376],
            [-1.551157122819528, 52.901825980408042],
            [-1.555114718907447, 52.906032607832913],
            [-1.553894813789129, 52.906246478103334],
            [-1.55465641235394, 52.908534433857987],
            [-1.554041526664249, 52.909191050108419],
            [-1.556443444317284, 52.913509541416822],
            [-1.555674712515151, 52.913791633808643],
            [-1.556857005466739, 52.914660823870221],
            [-1.551072854833658, 52.917034789304893],
            [-1.551708264150753, 52.918439527776691],
            [-1.551452790415172, 52.918983319592812],
            [-1.551880073629035, 52.920526606315775],
            [-1.552880927810323, 52.922560171517567],
            [-1.549613547276193, 52.922806732692798],
            [-1.544073337021888, 52.924189754320309],
            [-1.539650079252355, 52.924431603693179],
            [-1.537802270067343, 52.924887386747308],
            [-1.527010640262184, 52.932399599918192],
            [-1.525461574099981, 52.932975038686791],
            [-1.52126537991008, 52.932367617251579],
            [-1.519877673478161, 52.933278036829932],
            [-1.511888863527672, 52.935221369697608],
            [-1.510976785265006, 52.938130171386668],
            [-1.50803262945423, 52.93743393713563],
            [-1.506609543017865, 52.939847074337862],
            [-1.507819225504524, 52.940901144170475],
            [-1.507510317334897, 52.941372704679551],
            [-1.50806810385897, 52.942442947502123],
            [-1.507773981933486, 52.943709227503959],
            [-1.5082285324225, 52.944174958128343],
            [-1.507888294966493, 52.944389294727159],
            [-1.508842747227517, 52.94473483565703],
            [-1.509153364251336, 52.945691684795825],
            [-1.510673198365834, 52.946316414784363],
            [-1.507924635550006, 52.946958597740235],
            [-1.508337759138735, 52.947799910373675],
            [-1.505907582191079, 52.948309421592143],
            [-1.507607890397278, 52.951264075748156],
            [-1.507647709587359, 52.954312521309532],
            [-1.506612415591396, 52.954186425334676],
            [-1.503430753512199, 52.955620014826707],
            [-1.497804338932825, 52.955986525748287],
            [-1.497979347306081, 52.956813383590998],
            [-1.496869360690943, 52.958567903472854],
            [-1.49531725261353, 52.960057150242001],
            [-1.494415398159028, 52.960160292722307],
            [-1.496435920339678, 52.963204557453146],
            [-1.496576515967387, 52.963918005291163],
            [-1.496050745162052, 52.964065001294585],
            [-1.496475197549575, 52.964950448492743],
            [-1.495403931062946, 52.96501782291552],
            [-1.495409006269601, 52.965861041930516],
            [-1.492269069145938, 52.966360070163347],
            [-1.49196922167058, 52.965901233772421],
            [-1.490694496480371, 52.966175349700734],
            [-1.4851881312607, 52.966212770580398],
            [-1.483330752047513, 52.966773740163276],
            [-1.483625779664539, 52.967255050794137],
            [-1.481161491743736, 52.968135167486885],
            [-1.480088417920405, 52.96809632225267],
            [-1.479663526042076, 52.967520945905015],
            [-1.480468757807326, 52.967296137355241],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000016",
        LAD13CDO: "00FN",
        LAD13NM: "Leicester",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.154629219252153, 52.68985446814257],
            [-1.155052891196376, 52.689596780746363],
            [-1.154245741026317, 52.689639572187879],
            [-1.152383016636834, 52.688418928938212],
            [-1.151322228796342, 52.688877917473221],
            [-1.151133084822453, 52.688484604023003],
            [-1.147525567227123, 52.690643270397821],
            [-1.14326949502132, 52.687137999998683],
            [-1.140786672523176, 52.684367325189498],
            [-1.136694238011096, 52.67989747974071],
            [-1.137719851961166, 52.675361435053624],
            [-1.135473967559293, 52.675399019888538],
            [-1.135200837404255, 52.673201686937439],
            [-1.133036905794814, 52.673139135752457],
            [-1.132987204124913, 52.669746869305101],
            [-1.129867186763172, 52.669508253116248],
            [-1.12989990638676, 52.669201037253025],
            [-1.129365960456689, 52.669131490822359],
            [-1.129096147765752, 52.665370813086867],
            [-1.128258843091152, 52.665092263765004],
            [-1.125903503562432, 52.667455459501319],
            [-1.121423363143324, 52.669570940821465],
            [-1.120759735163684, 52.670584582017725],
            [-1.121017664819444, 52.671807328999101],
            [-1.119777363652235, 52.671782844250153],
            [-1.119461813102903, 52.672692980386707],
            [-1.118787791260135, 52.672748205217296],
            [-1.118819291101415, 52.673167370685064],
            [-1.11465982883581, 52.672688684181459],
            [-1.11013456882144, 52.67143846564781],
            [-1.109154896801674, 52.672779601084351],
            [-1.105495114920195, 52.671520422017736],
            [-1.105241496621679, 52.67184574255792],
            [-1.090558514799817, 52.668520108748098],
            [-1.089320040022463, 52.670262746500747],
            [-1.088751604550871, 52.669720778732362],
            [-1.08810774504066, 52.669890233498379],
            [-1.085742188145293, 52.669224737018439],
            [-1.075086672689917, 52.669606867897883],
            [-1.074933451865663, 52.667968597459868],
            [-1.069765209967307, 52.667129846556364],
            [-1.06935267766115, 52.667336974463616],
            [-1.068792623270687, 52.666901057388415],
            [-1.067874093647549, 52.666842595969563],
            [-1.066629065154388, 52.665728829791952],
            [-1.0644623031132, 52.664712063649731],
            [-1.062202440866137, 52.663835663837112],
            [-1.0597715963725, 52.663834389135182],
            [-1.059672533265019, 52.663558509529956],
            [-1.058901919074704, 52.663707921369955],
            [-1.058685414284472, 52.663461674521123],
            [-1.057645327641002, 52.663902911066884],
            [-1.056752686413666, 52.663813103957231],
            [-1.05912227041923, 52.657232374784748],
            [-1.058953698995003, 52.656407552082612],
            [-1.048621275505904, 52.65497747204126],
            [-1.048845543072757, 52.653566940116697],
            [-1.048203435067097, 52.652025391561573],
            [-1.04802696303905, 52.648170850154372],
            [-1.051027872120835, 52.648086134885006],
            [-1.051802985552832, 52.644026148751863],
            [-1.050976110861648, 52.643921539508334],
            [-1.05132395593151, 52.643214111722386],
            [-1.049194957727702, 52.643385842502617],
            [-1.049497256240013, 52.642598942246721],
            [-1.047442728286273, 52.642337019964025],
            [-1.047662570082478, 52.638134145475817],
            [-1.046212887781558, 52.63806585050893],
            [-1.046554548823273, 52.637644269559708],
            [-1.048048865659873, 52.637289487911318],
            [-1.048145780144395, 52.635343022719077],
            [-1.046784606597883, 52.635217908943112],
            [-1.046970879204908, 52.634601790759028],
            [-1.053131110862225, 52.634468670474327],
            [-1.053140695398798, 52.633134624428962],
            [-1.056049932495546, 52.632592358500602],
            [-1.056191902874668, 52.632174553136949],
            [-1.055703420589574, 52.630293540160309],
            [-1.056046114980848, 52.628992713228648],
            [-1.055392467084332, 52.628334827197797],
            [-1.053276754426872, 52.627514233332668],
            [-1.055253925652819, 52.624819510734916],
            [-1.055763585263863, 52.624957524670847],
            [-1.057294477336019, 52.623632891702172],
            [-1.058063525231056, 52.623661482304712],
            [-1.060144971112038, 52.62152489035477],
            [-1.063495816338199, 52.61935335057106],
            [-1.064317411817974, 52.619685281070787],
            [-1.065654772593694, 52.619382080646439],
            [-1.066363151798365, 52.619551283119165],
            [-1.070498471598081, 52.618048291610009],
            [-1.071548707989624, 52.617488353656434],
            [-1.074338941059697, 52.613423303059584],
            [-1.077030222980945, 52.614321726738545],
            [-1.082432350076622, 52.614915671682454],
            [-1.082181254970597, 52.615693164590624],
            [-1.084017182854323, 52.615905152061195],
            [-1.083922898607512, 52.615044075277694],
            [-1.086233748517868, 52.615003482733293],
            [-1.093242030328946, 52.616147812590746],
            [-1.096642182184678, 52.616360775633446],
            [-1.09710994558792, 52.613725763018635],
            [-1.098312084874491, 52.611069368214125],
            [-1.094855478075824, 52.610247393914349],
            [-1.093390032841381, 52.610268554339676],
            [-1.093601728569582, 52.608669944338111],
            [-1.095376929229838, 52.607373659983125],
            [-1.096982965729398, 52.60625586105121],
            [-1.098828594994734, 52.603244758649822],
            [-1.099952036916139, 52.603472655741761],
            [-1.100214138251557, 52.60242730460746],
            [-1.10163580918652, 52.602223230441751],
            [-1.101930534204585, 52.599291108728387],
            [-1.103910774022732, 52.597972876319439],
            [-1.10479825370999, 52.596371262850703],
            [-1.103659400185539, 52.595893370494721],
            [-1.107451417879769, 52.595637014413967],
            [-1.108793516046407, 52.596359131011667],
            [-1.109448844514937, 52.596367654884446],
            [-1.109860112747891, 52.597346167385986],
            [-1.112895051681053, 52.596932913144094],
            [-1.117948853081743, 52.596997617071992],
            [-1.120179412562744, 52.597525736869386],
            [-1.120794139534136, 52.59550663878187],
            [-1.12193528852009, 52.595804581735756],
            [-1.126485249877174, 52.595661112262178],
            [-1.126806316694165, 52.595625720853349],
            [-1.126449987916108, 52.592905400209588],
            [-1.126614091115524, 52.591036677849786],
            [-1.127756408451448, 52.591346260856533],
            [-1.128031050725377, 52.590970700140545],
            [-1.129678062601308, 52.591512325705096],
            [-1.130860491713224, 52.589945049204346],
            [-1.136075927335194, 52.589406934934551],
            [-1.138215498604896, 52.589447690639247],
            [-1.141273848639853, 52.589985029023744],
            [-1.14086878673654, 52.587675244122643],
            [-1.143626976723244, 52.588007169758278],
            [-1.145996871471432, 52.588117774215625],
            [-1.146295409603138, 52.586579030735457],
            [-1.147384759178136, 52.585283318026363],
            [-1.149504384388658, 52.581005801911715],
            [-1.153107838427865, 52.58065044027979],
            [-1.159337393090268, 52.581647759137184],
            [-1.158908068613673, 52.582642607594821],
            [-1.159729897873293, 52.582963990709885],
            [-1.160419433645817, 52.58240790046478],
            [-1.160950232497795, 52.582512349854731],
            [-1.160669548859848, 52.584303876627956],
            [-1.15822359753182, 52.584166054671762],
            [-1.158016390439835, 52.585736941898261],
            [-1.155942480895339, 52.585974803854775],
            [-1.155614659737543, 52.58713488105419],
            [-1.156951830560704, 52.587218122815635],
            [-1.157159802023249, 52.588611261809625],
            [-1.158878710948254, 52.589035222570232],
            [-1.158000523155237, 52.590648090931744],
            [-1.159034184570643, 52.590783990537005],
            [-1.158911664758943, 52.591092378162763],
            [-1.159344926726626, 52.591207828843636],
            [-1.159559973573782, 52.591695715741366],
            [-1.160910575070522, 52.59177900758592],
            [-1.160851103511024, 52.592342261733087],
            [-1.168429917981259, 52.592813737221448],
            [-1.169195971053787, 52.591570396175428],
            [-1.172133542215099, 52.591807619759585],
            [-1.17241302000911, 52.592801172764361],
            [-1.174166244921467, 52.592829588874459],
            [-1.174058661269453, 52.593683790588763],
            [-1.17145963156539, 52.593413922163528],
            [-1.170406913289748, 52.593891117966272],
            [-1.170869112907019, 52.594823023470497],
            [-1.170099047695641, 52.595887445061237],
            [-1.170384802058269, 52.596547516263534],
            [-1.171553684503384, 52.596646494622021],
            [-1.172648872284952, 52.597287944244947],
            [-1.171510435846532, 52.59870490750577],
            [-1.170091205887256, 52.598334474895758],
            [-1.167992590453159, 52.601407828743],
            [-1.166110282797028, 52.602172232623808],
            [-1.165377874286489, 52.603029218514138],
            [-1.171053199405935, 52.605800226424002],
            [-1.167391919888213, 52.608247707556025],
            [-1.164055491397574, 52.609435170633844],
            [-1.164986834599653, 52.610160938202945],
            [-1.165901688422587, 52.611678601741851],
            [-1.16556670631278, 52.613063401008972],
            [-1.166686663352526, 52.613272658379593],
            [-1.16792052390699, 52.614252249097028],
            [-1.172073230402094, 52.615765584774671],
            [-1.174313903951449, 52.617803967037723],
            [-1.176111525675221, 52.618038531458069],
            [-1.17859568657769, 52.618290415272774],
            [-1.184604089804553, 52.620696275756018],
            [-1.18600436119635, 52.621736153884981],
            [-1.187260675052492, 52.621618916839395],
            [-1.188117752800472, 52.622640659088312],
            [-1.18896360308276, 52.623033019798221],
            [-1.196108757477499, 52.625669016495394],
            [-1.200147479943201, 52.626658269200632],
            [-1.200415117862775, 52.627212957889299],
            [-1.201250957108269, 52.627313887721463],
            [-1.205402129872165, 52.628798165730934],
            [-1.205472029115846, 52.628854372084419],
            [-1.208753622016785, 52.630563740528146],
            [-1.210554230861756, 52.630835557531888],
            [-1.212055400695815, 52.63157463208627],
            [-1.214862545907681, 52.633317532921446],
            [-1.215987750957362, 52.634515249850025],
            [-1.215676167091264, 52.634669613367585],
            [-1.213090671946608, 52.636132220360359],
            [-1.214354790796002, 52.636918236135926],
            [-1.211025017022408, 52.639410604234683],
            [-1.209748758058048, 52.641676556088164],
            [-1.209011531987603, 52.642073488798829],
            [-1.205897464684017, 52.642654093767867],
            [-1.202963883721884, 52.641349741826566],
            [-1.201515367746267, 52.641117941176567],
            [-1.200906392111115, 52.639825578537625],
            [-1.198861751741886, 52.640275657492182],
            [-1.198785503698059, 52.641456420560608],
            [-1.198074373353629, 52.641714117796511],
            [-1.196000987475926, 52.641065382047202],
            [-1.190662420096389, 52.640375480563293],
            [-1.189702642978768, 52.642491449607149],
            [-1.186997146998564, 52.645571757435206],
            [-1.187581598373915, 52.647132822381678],
            [-1.184068827875975, 52.649564746215738],
            [-1.182925821070049, 52.652997321695629],
            [-1.184603401950993, 52.653328015420492],
            [-1.184130367300992, 52.657537441581091],
            [-1.184544268104236, 52.659527062698125],
            [-1.185295616449054, 52.660625402991322],
            [-1.176731306466308, 52.664287167413612],
            [-1.177518186364352, 52.665219489714858],
            [-1.177106741620209, 52.665318220691432],
            [-1.177094318363031, 52.665979792438208],
            [-1.177507398550945, 52.667606237777235],
            [-1.179019106512994, 52.669082970284187],
            [-1.180237154289136, 52.669345812946183],
            [-1.177962983284251, 52.671461574700707],
            [-1.178955487791685, 52.673188221107232],
            [-1.177899518653667, 52.674055621837923],
            [-1.177985717151686, 52.675373240424996],
            [-1.178471072758347, 52.675360422506181],
            [-1.179092400002483, 52.676378786815086],
            [-1.178198271081124, 52.676814896121243],
            [-1.177993930522385, 52.678088247484105],
            [-1.176964505419022, 52.678480257947989],
            [-1.175058180453147, 52.678309687057272],
            [-1.174182545109835, 52.678623632178635],
            [-1.174150713388658, 52.67905672366399],
            [-1.17483685717588, 52.679065995595337],
            [-1.173574299935895, 52.679424889419458],
            [-1.17371745082343, 52.679755816371717],
            [-1.173033864118087, 52.679767232594649],
            [-1.173721360006292, 52.681195126282034],
            [-1.172786002023292, 52.681456503540502],
            [-1.173329493180363, 52.681737182675242],
            [-1.172981029706131, 52.682643631248347],
            [-1.171935949304768, 52.683307882811889],
            [-1.170743633163437, 52.683543176600118],
            [-1.166582151463468, 52.686587645845755],
            [-1.165031108180633, 52.688793641954518],
            [-1.163071063389915, 52.687929380744329],
            [-1.161040282417323, 52.690529291167053],
            [-1.157259092069086, 52.691503953683252],
            [-1.156771799326642, 52.691223600999116],
            [-1.157016809438766, 52.691023969370114],
            [-1.154629219252153, 52.68985446814257],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000017",
        LAD13CDO: "00FP",
        LAD13NM: "Rutland",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.608603678305768, 52.759693753835158],
            [-0.605454136571767, 52.750473235374976],
            [-0.597720264954843, 52.750973622541309],
            [-0.593919543778953, 52.75188025191148],
            [-0.589576210816596, 52.753754854693668],
            [-0.583457735761322, 52.753857260911595],
            [-0.580519301058505, 52.752858378581159],
            [-0.572646812347145, 52.75308936252609],
            [-0.57150979609147, 52.750478382084907],
            [-0.571460791724998, 52.747854425131671],
            [-0.569517852407584, 52.747511854750407],
            [-0.569021821752885, 52.746598750931881],
            [-0.566121236757248, 52.744967944886149],
            [-0.560874742956555, 52.7455732855435],
            [-0.560044652762154, 52.745452630650142],
            [-0.553272148643351, 52.742522088671876],
            [-0.547963976397468, 52.741019672079574],
            [-0.544724461750217, 52.739592761148216],
            [-0.539984863925688, 52.738381951951176],
            [-0.53882229686821, 52.73403518876917],
            [-0.540325775673478, 52.728752992530481],
            [-0.54283332381618, 52.726031882259271],
            [-0.542444427952356, 52.723261669601428],
            [-0.538758724253282, 52.723090446924573],
            [-0.533055911322667, 52.721563600162298],
            [-0.525014182998418, 52.720956791811226],
            [-0.51919696231363, 52.72099397619872],
            [-0.51650432093407, 52.72023299940583],
            [-0.512651975054022, 52.719660603696603],
            [-0.51066577018376, 52.71889936121746],
            [-0.511014820040642, 52.717692737848168],
            [-0.509872261591827, 52.715334587588892],
            [-0.50697223050322, 52.715936427423216],
            [-0.505731994524495, 52.713585096647172],
            [-0.504547545833412, 52.71332922150998],
            [-0.502481930603784, 52.712132600195488],
            [-0.499854220899676, 52.712092192385157],
            [-0.495754175527333, 52.710786085147795],
            [-0.494541516170303, 52.709633405564887],
            [-0.493252675224287, 52.710264345510275],
            [-0.489605216181237, 52.711036967771911],
            [-0.48309086817174, 52.710638243302405],
            [-0.480227452368633, 52.710951304685949],
            [-0.470291978817881, 52.709755475790814],
            [-0.467907048031844, 52.709761543895404],
            [-0.465556343031017, 52.710268775601541],
            [-0.463655044909929, 52.708473932903665],
            [-0.460885782842683, 52.708088288606035],
            [-0.459474996741169, 52.708386431450187],
            [-0.457094209003807, 52.709661789977744],
            [-0.453239337098287, 52.709250147789028],
            [-0.451102703200323, 52.709560297446203],
            [-0.450543177117666, 52.708013816821612],
            [-0.44396836539006, 52.708005031508307],
            [-0.444732875483614, 52.706153132139072],
            [-0.436345174271736, 52.706764746648609],
            [-0.435751428535726, 52.70701853823256],
            [-0.43350864869962, 52.705828253807674],
            [-0.43045411450459, 52.705402164388289],
            [-0.429737332490476, 52.703269115135782],
            [-0.428707522910975, 52.702642318491257],
            [-0.428393117211045, 52.701094479917572],
            [-0.428931822406791, 52.698044844097545],
            [-0.430555635708234, 52.693908236082343],
            [-0.430379139328918, 52.69288817206656],
            [-0.430867183020891, 52.691987488991323],
            [-0.432531690280996, 52.69071037852018],
            [-0.43230065725032, 52.687494108864435],
            [-0.4331885704167, 52.685463199514544],
            [-0.434376786027481, 52.685664103814894],
            [-0.434923264714975, 52.684655390259643],
            [-0.434065856240986, 52.683685675972235],
            [-0.438630896113021, 52.682866620297624],
            [-0.441339063280146, 52.680472121522371],
            [-0.44636278236951, 52.678594322363956],
            [-0.449326793317008, 52.675262587203484],
            [-0.451316079826341, 52.673927429868044],
            [-0.454182413190335, 52.672672908245204],
            [-0.456202432946927, 52.670374286134049],
            [-0.459495655005403, 52.669544155366388],
            [-0.459461640442182, 52.669081600262381],
            [-0.461639952794394, 52.669042464704738],
            [-0.462511188246018, 52.668784953060019],
            [-0.462607455127504, 52.669160207531434],
            [-0.466080640722034, 52.668843789468468],
            [-0.473676633885094, 52.667515958218551],
            [-0.476389903421515, 52.667357572792149],
            [-0.476572435276313, 52.667041655099872],
            [-0.480421165009407, 52.66654893531188],
            [-0.481606084932118, 52.666025590254705],
            [-0.481466914284945, 52.665817025588041],
            [-0.483890570629469, 52.665434490683708],
            [-0.490278913048616, 52.664403014899648],
            [-0.490620145205822, 52.665014219685084],
            [-0.494722678697267, 52.664613274793716],
            [-0.493876873403356, 52.660697956694193],
            [-0.496878326336439, 52.660399817564731],
            [-0.501991060214993, 52.660666838207156],
            [-0.504577925808545, 52.660383941363683],
            [-0.505305006855856, 52.660915460766908],
            [-0.506389997761125, 52.660404095123447],
            [-0.506685241295045, 52.659506968857151],
            [-0.519028270062245, 52.666507195062586],
            [-0.519889810711404, 52.666218574688521],
            [-0.52064093972115, 52.666484180621893],
            [-0.521839325588996, 52.665766409786364],
            [-0.52183945497949, 52.665368133912061],
            [-0.523596957273736, 52.66521472589546],
            [-0.514088148658816, 52.656768015000608],
            [-0.51185601758583, 52.656321949525683],
            [-0.512034808558476, 52.654931568022],
            [-0.512752322838962, 52.655004407222535],
            [-0.51256172823507, 52.654739492931661],
            [-0.50744022648788, 52.648887917372996],
            [-0.506472442843351, 52.648180758032211],
            [-0.505134661225125, 52.648268179581713],
            [-0.504385317935298, 52.647962037692373],
            [-0.503886040783928, 52.646782473927217],
            [-0.504857138684813, 52.646653579598862],
            [-0.504410691820253, 52.646221795683999],
            [-0.49934424256471, 52.642492309048606],
            [-0.495037100698806, 52.64021792249202],
            [-0.497093995646547, 52.640056127379871],
            [-0.499174992399991, 52.639276053860598],
            [-0.502275807592864, 52.64048135932974],
            [-0.503233734850106, 52.640056282508816],
            [-0.504023015639753, 52.640082674735581],
            [-0.506949832438982, 52.641021338727469],
            [-0.509045973025131, 52.642399905019325],
            [-0.509647573897205, 52.642441637058781],
            [-0.509555115331877, 52.643161512882777],
            [-0.510432804703896, 52.642951382823838],
            [-0.5105459989301, 52.64336097469738],
            [-0.511339110697563, 52.643160563392129],
            [-0.511958237897254, 52.643600783162555],
            [-0.512637314430552, 52.642840620780731],
            [-0.513463732195924, 52.642616339026539],
            [-0.514685357046143, 52.643116246282013],
            [-0.515897354645144, 52.642023802402079],
            [-0.516882454717201, 52.642438011760404],
            [-0.517408267430545, 52.64236098017426],
            [-0.518289957696821, 52.641724692504894],
            [-0.520064458563909, 52.641433999117531],
            [-0.521079156466625, 52.640708547994002],
            [-0.520739976186424, 52.640073181103233],
            [-0.521444497185866, 52.639516471831342],
            [-0.520841408762337, 52.638862527841695],
            [-0.52076514846726, 52.637486927973633],
            [-0.523641935452432, 52.634868810840558],
            [-0.528863745930368, 52.631975885383341],
            [-0.530903845718646, 52.632079415826709],
            [-0.533523320648871, 52.631427689171545],
            [-0.533854938128316, 52.63052465181012],
            [-0.534995629578539, 52.630436270010485],
            [-0.535650024228846, 52.629334932102275],
            [-0.534097797803026, 52.628828452172208],
            [-0.534698236381396, 52.628590438920931],
            [-0.53456416323588, 52.628092504976152],
            [-0.535648941405086, 52.627065716220926],
            [-0.537325203377596, 52.626155006420483],
            [-0.536959812369599, 52.627365111680284],
            [-0.537433435875857, 52.627827676709849],
            [-0.538125265946961, 52.627905441831359],
            [-0.538521503113846, 52.627010381229688],
            [-0.540272198259405, 52.625947709682272],
            [-0.541169418367821, 52.624602094327706],
            [-0.539129550413635, 52.623125891870174],
            [-0.538074070115839, 52.621106191075128],
            [-0.539565292033495, 52.619710376264635],
            [-0.538896167866895, 52.618777002231148],
            [-0.539068585525477, 52.617857601121436],
            [-0.540644476044052, 52.617509312425732],
            [-0.541552733755168, 52.616625941580899],
            [-0.543035125346565, 52.616022038766943],
            [-0.542947961459138, 52.614954692054802],
            [-0.543792341966576, 52.614702551521496],
            [-0.54463163069229, 52.615049110484321],
            [-0.544944189722484, 52.614576452869244],
            [-0.550142529922227, 52.612232530259043],
            [-0.550066871026086, 52.611616653404369],
            [-0.549342181217135, 52.611199611992795],
            [-0.548481666080249, 52.609888143860587],
            [-0.548487218263755, 52.608201591254847],
            [-0.547666329704028, 52.607614338448585],
            [-0.547570561430216, 52.606937976298077],
            [-0.549578411968334, 52.605703905736334],
            [-0.550217084149246, 52.60483245152912],
            [-0.551416244806439, 52.604293306340594],
            [-0.552114612253346, 52.602236722385079],
            [-0.552950705435873, 52.601330805359588],
            [-0.552347708768786, 52.600003628481439],
            [-0.549239925071414, 52.599232876896316],
            [-0.549019859352001, 52.598341017948293],
            [-0.544948153241904, 52.596633214277361],
            [-0.544597584578657, 52.596118244600348],
            [-0.543579878687524, 52.596131807476375],
            [-0.542941294650716, 52.59566543598087],
            [-0.542693080566726, 52.59473635652293],
            [-0.544185541285989, 52.592870292868511],
            [-0.544676953994086, 52.592876332101056],
            [-0.545188476949689, 52.593478689196971],
            [-0.546728631580626, 52.592576066657784],
            [-0.54717363185535, 52.593271100429043],
            [-0.553188657678869, 52.594556653482179],
            [-0.555045059574644, 52.594717769668449],
            [-0.558130093857916, 52.594465870864042],
            [-0.559936868322105, 52.593619367361697],
            [-0.561768808770459, 52.592182464617842],
            [-0.560574548229422, 52.591279692409181],
            [-0.561069438578162, 52.591043860747945],
            [-0.560654619517973, 52.588926045799965],
            [-0.561636943786313, 52.587057160622209],
            [-0.562531077711663, 52.586257070323505],
            [-0.564487713907268, 52.58591578702935],
            [-0.565295066796199, 52.586369702327595],
            [-0.567781485218534, 52.586303598097153],
            [-0.568577951951299, 52.585688385456052],
            [-0.56995592823325, 52.586071845629448],
            [-0.571916620921422, 52.585785331047482],
            [-0.575581266572472, 52.586436323257814],
            [-0.57701640446618, 52.587153936446398],
            [-0.576521643400578, 52.587526491685821],
            [-0.576795275379147, 52.587874116948797],
            [-0.577968613331843, 52.587880119308906],
            [-0.578779742451847, 52.588815878479359],
            [-0.578472946604851, 52.589264419388797],
            [-0.579013896585693, 52.589608954072332],
            [-0.577874109935212, 52.590389135936775],
            [-0.579197072836904, 52.590426590963204],
            [-0.578988966265262, 52.591064218621888],
            [-0.578329076177864, 52.591246898371793],
            [-0.577967379607631, 52.591794573558303],
            [-0.577560417589005, 52.593283909307104],
            [-0.579024650055628, 52.59539177746182],
            [-0.581485265386873, 52.595865402587172],
            [-0.582845776515447, 52.595156155168439],
            [-0.583709886468983, 52.595051412478121],
            [-0.584507508333402, 52.593390512530384],
            [-0.583808386229435, 52.593141209885182],
            [-0.582803653876448, 52.593258655435392],
            [-0.583310668127572, 52.591270627278604],
            [-0.585299915277986, 52.588451606831967],
            [-0.586985211232839, 52.587410845617121],
            [-0.58894724783079, 52.586759050800978],
            [-0.590667922368902, 52.587233559101527],
            [-0.591938109254448, 52.587210906845826],
            [-0.594126735061098, 52.588286098046503],
            [-0.596778904215505, 52.588347218800266],
            [-0.597982462916541, 52.588748063453231],
            [-0.60303208874622, 52.588573079397953],
            [-0.603821206075094, 52.587710306966954],
            [-0.605150829151085, 52.587489523769086],
            [-0.605021705646414, 52.586907217407862],
            [-0.605505022533595, 52.586468778926722],
            [-0.607367796831371, 52.585864959166351],
            [-0.607362259686082, 52.585434249645616],
            [-0.608493759569675, 52.584708531082882],
            [-0.610750393167286, 52.584155136897145],
            [-0.611885814630355, 52.584423780031052],
            [-0.612727146640302, 52.583909492667686],
            [-0.614131616304472, 52.584071581538232],
            [-0.617217324654854, 52.583294010706446],
            [-0.616511607989283, 52.58226894514285],
            [-0.617896640264989, 52.582015402183643],
            [-0.617603228003606, 52.581442880013917],
            [-0.618862185258453, 52.581302033323055],
            [-0.620001810690192, 52.579697031128489],
            [-0.621691410051892, 52.579799419706468],
            [-0.624847877652342, 52.579151937961278],
            [-0.625515026545042, 52.578866593153116],
            [-0.625171146476331, 52.578583896634157],
            [-0.626040663168607, 52.578056356931739],
            [-0.627822949053191, 52.578213676420049],
            [-0.628406748655354, 52.577476926641246],
            [-0.627781959462569, 52.577163113409931],
            [-0.628116102554735, 52.576528660551169],
            [-0.629577241842476, 52.576524000767847],
            [-0.630595474754712, 52.576098842119031],
            [-0.631307067065939, 52.576516135236503],
            [-0.632521759637547, 52.576321588640745],
            [-0.633030667702152, 52.575569566750652],
            [-0.633990320682366, 52.575318118161057],
            [-0.63293306964251, 52.575243883872972],
            [-0.633465146436029, 52.574695312357875],
            [-0.633025604247179, 52.574261393619793],
            [-0.633595077330174, 52.574170868584048],
            [-0.633439803006524, 52.573659316659359],
            [-0.636286015490447, 52.572718458119034],
            [-0.637861101433125, 52.571794383864976],
            [-0.638963808380811, 52.571494194053898],
            [-0.64025324235528, 52.57183536115236],
            [-0.640633914721877, 52.571693186792196],
            [-0.641668741364411, 52.572920573315137],
            [-0.642246681333317, 52.572984740055723],
            [-0.643006566458356, 52.573656047736534],
            [-0.643726009545135, 52.57339097925999],
            [-0.6449361491897, 52.573866043670698],
            [-0.646909583340664, 52.573754650390363],
            [-0.647322555688395, 52.573281974447696],
            [-0.646753473587233, 52.573169385240071],
            [-0.646710277602432, 52.572559337848446],
            [-0.647746014931521, 52.572420129557145],
            [-0.6474732314802, 52.572125723149007],
            [-0.647969320609866, 52.571156821241807],
            [-0.649359811077851, 52.570564033868905],
            [-0.651600505919587, 52.570056434266164],
            [-0.654526420388783, 52.57034774084142],
            [-0.658629313767238, 52.568384019081122],
            [-0.660926291592087, 52.568927866258171],
            [-0.66194451891772, 52.567711279072036],
            [-0.66305663425797, 52.567238355335121],
            [-0.663216016922741, 52.566557778666223],
            [-0.664128684680138, 52.566772161621365],
            [-0.666290144340243, 52.566335315117016],
            [-0.66855214927356, 52.565353839063739],
            [-0.668954196368312, 52.564744310651477],
            [-0.669604415197242, 52.564562818090316],
            [-0.669875704492597, 52.563788191047827],
            [-0.670372512040915, 52.564239697360591],
            [-0.671300645074206, 52.564088287189627],
            [-0.671894629613819, 52.562983730101934],
            [-0.671593013209044, 52.562858975603746],
            [-0.671590794685341, 52.563127765441727],
            [-0.670777030340132, 52.563254389365532],
            [-0.670482318901585, 52.562559714361157],
            [-0.671560135676119, 52.562579902517449],
            [-0.671393202203167, 52.562050289586324],
            [-0.671873910301593, 52.562155476400157],
            [-0.672114448504641, 52.561811142326022],
            [-0.673322575166729, 52.561472258347386],
            [-0.673096856561953, 52.561081341292159],
            [-0.673843136779786, 52.560793915620373],
            [-0.673590253929275, 52.560325377267922],
            [-0.672657302983367, 52.560394937870711],
            [-0.672676872749935, 52.559013323107479],
            [-0.673119374234322, 52.558868141877682],
            [-0.674332849702373, 52.559327660833532],
            [-0.674877830679337, 52.558620817423609],
            [-0.677575960330927, 52.558274281721708],
            [-0.682319047911741, 52.553578399048384],
            [-0.679747042577836, 52.553419385847214],
            [-0.681842890003118, 52.551650038646862],
            [-0.683612770335374, 52.552340418243254],
            [-0.685989925712502, 52.549540222171977],
            [-0.684768400057618, 52.548960259647011],
            [-0.685883221618227, 52.548619313242938],
            [-0.68638995454029, 52.547849059262248],
            [-0.687214700526088, 52.547837528929833],
            [-0.687631015198547, 52.546982655732904],
            [-0.688502273737718, 52.546745971358291],
            [-0.68846194852786, 52.546119788137524],
            [-0.689621134531103, 52.546316029138268],
            [-0.691996784148002, 52.5455268636575],
            [-0.692567935178767, 52.546852073347019],
            [-0.69362285997658, 52.546438469285434],
            [-0.693185207093254, 52.545880725885084],
            [-0.693978158200825, 52.545349148862435],
            [-0.696635121962938, 52.546309840247488],
            [-0.697846671161201, 52.545684848969557],
            [-0.698041228920756, 52.545289610330819],
            [-0.696765677163844, 52.544192225705679],
            [-0.697727265713614, 52.543839592074455],
            [-0.697494414370946, 52.542937984231436],
            [-0.696410595033994, 52.542339877026045],
            [-0.696980332333793, 52.542122286423485],
            [-0.699250563049667, 52.542243449150568],
            [-0.69828425637946, 52.540962475699729],
            [-0.696250464192074, 52.540079708274625],
            [-0.696191781030573, 52.539721241322397],
            [-0.697053537864133, 52.539799056351775],
            [-0.697964860990547, 52.53934787165425],
            [-0.696704045224009, 52.538953701418222],
            [-0.696443839305742, 52.538529183479341],
            [-0.69717276965389, 52.538311546730988],
            [-0.698684748788764, 52.538638347505952],
            [-0.699201498689343, 52.537717109185174],
            [-0.700288972970121, 52.537589700934873],
            [-0.700595776585379, 52.537885259065249],
            [-0.701143538263351, 52.53766021503445],
            [-0.70122043234061, 52.537252891384092],
            [-0.700557663940695, 52.53732833071426],
            [-0.700609768353361, 52.536811950626323],
            [-0.699994235579784, 52.536735966281142],
            [-0.700591657105177, 52.536376613075859],
            [-0.700288029559214, 52.535724167965789],
            [-0.699639423206394, 52.53567029475559],
            [-0.700692619769416, 52.53445465929687],
            [-0.701746694465117, 52.534557027471813],
            [-0.701452484114249, 52.533535179989066],
            [-0.702894899044007, 52.533166201203073],
            [-0.702830958505777, 52.532234087494295],
            [-0.703755449287287, 52.532179482817661],
            [-0.704877095676152, 52.53163704728324],
            [-0.704667053445752, 52.531109705439562],
            [-0.705720688124784, 52.531224620437094],
            [-0.705518900739874, 52.530917636976227],
            [-0.706111454314272, 52.530619338114917],
            [-0.706361350428245, 52.531195661453438],
            [-0.707642984849678, 52.53082576709874],
            [-0.7076094804793, 52.530510734785892],
            [-0.706750491073857, 52.530143532756831],
            [-0.708908547292496, 52.529796682052549],
            [-0.708921281004403, 52.5292618878008],
            [-0.708222260664641, 52.529170650399436],
            [-0.70836638994373, 52.528729891794242],
            [-0.707845382450878, 52.528550689589707],
            [-0.708000210839383, 52.527896074841379],
            [-0.709573797110232, 52.527720843150725],
            [-0.71003516854298, 52.526564302364697],
            [-0.711930247760437, 52.526029327385402],
            [-0.711928528177493, 52.525684973465644],
            [-0.713671758077424, 52.524946931690245],
            [-0.714399180425951, 52.524871222854614],
            [-0.716843339093382, 52.525874108199865],
            [-0.71769605610412, 52.525494960240877],
            [-0.71907451158168, 52.525740040770479],
            [-0.719740255741377, 52.526163501680053],
            [-0.720918468388275, 52.525843591450375],
            [-0.721686310206197, 52.526609782185368],
            [-0.722567798943112, 52.526762246457189],
            [-0.724782346484115, 52.53061336267487],
            [-0.72558044805941, 52.531154192421887],
            [-0.72764815756214, 52.531736539686513],
            [-0.729040295933763, 52.532895079575262],
            [-0.729965666758932, 52.532962549766665],
            [-0.729860983462895, 52.533473882203076],
            [-0.729031593178142, 52.533809314802966],
            [-0.729820356520467, 52.534473184050647],
            [-0.728553815984681, 52.535803659832098],
            [-0.730518630185067, 52.536435196711651],
            [-0.730832876000638, 52.5362236944625],
            [-0.731306910885777, 52.536760110633146],
            [-0.732059481434707, 52.536636013763037],
            [-0.732620937056476, 52.53603157480083],
            [-0.733371007110663, 52.536454960732087],
            [-0.73583459353894, 52.536767189430826],
            [-0.735959873057152, 52.537128144644335],
            [-0.737188542569587, 52.537214079617073],
            [-0.738026132137356, 52.538649793019346],
            [-0.739704850910206, 52.539395001526358],
            [-0.742219702294854, 52.539468496567004],
            [-0.742518544196032, 52.540104597217955],
            [-0.741643868332527, 52.540733623936497],
            [-0.743409210325759, 52.541135366436578],
            [-0.743737215387119, 52.543075384518986],
            [-0.742381199440804, 52.543363066363113],
            [-0.743005089223998, 52.543874953587007],
            [-0.742679922020382, 52.544417219385728],
            [-0.74210610490974, 52.544474956203317],
            [-0.74193432573541, 52.544087441916389],
            [-0.74154803623075, 52.544961700729907],
            [-0.740835995344934, 52.544541469775403],
            [-0.740731308091957, 52.545880828153379],
            [-0.740285444691619, 52.545589290664303],
            [-0.74005061321491, 52.54585920162009],
            [-0.740436869575396, 52.54637846252789],
            [-0.738949719880036, 52.547481040453178],
            [-0.739402059018508, 52.548370514601366],
            [-0.738877588821699, 52.548865695669598],
            [-0.739585872054449, 52.548852559804502],
            [-0.739446087168213, 52.549407578444431],
            [-0.740166416434232, 52.549283086161822],
            [-0.739566731002516, 52.549829614285173],
            [-0.740912238001634, 52.550226022808545],
            [-0.741132858364917, 52.550711153885942],
            [-0.740777608706364, 52.55075592451135],
            [-0.740735925210115, 52.551336261855347],
            [-0.741174896896353, 52.551355314363249],
            [-0.741095868001395, 52.55179770141541],
            [-0.74240949565326, 52.551764011919133],
            [-0.743465517639839, 52.553313482325905],
            [-0.744931322080386, 52.554306285656082],
            [-0.745100233531775, 52.555365347374924],
            [-0.746469927511767, 52.556887545805729],
            [-0.747154509190771, 52.557037738442268],
            [-0.747291403645389, 52.557928337106098],
            [-0.749978993738755, 52.559227981297376],
            [-0.749696974583329, 52.559548657943871],
            [-0.750101400464071, 52.560371053162399],
            [-0.75174981599774, 52.561752283277919],
            [-0.751520472897656, 52.562140048278579],
            [-0.752306505090365, 52.562981741738199],
            [-0.75354959994236, 52.563782390668791],
            [-0.754294819416011, 52.563619415185343],
            [-0.754613648990633, 52.564342898289141],
            [-0.755760116961873, 52.564546445090777],
            [-0.755734152508588, 52.565099080601598],
            [-0.759906476154832, 52.564888419435299],
            [-0.760040703831943, 52.566877596682865],
            [-0.761439306638285, 52.568085255016292],
            [-0.761100506955922, 52.568903430826182],
            [-0.761802065174651, 52.56908787842535],
            [-0.762285076207357, 52.568807033957384],
            [-0.762550140178837, 52.5692449377771],
            [-0.762005505954716, 52.569722927201518],
            [-0.763561570356582, 52.569848866348174],
            [-0.763841751383221, 52.570379525701469],
            [-0.76248863933362, 52.571587184281242],
            [-0.763019846373878, 52.572111477922796],
            [-0.762514447053472, 52.57444459079808],
            [-0.763165529980531, 52.576006724105113],
            [-0.762962216540153, 52.576781367396478],
            [-0.763705441151865, 52.577377996329538],
            [-0.763415872146985, 52.578753194379352],
            [-0.763866263369414, 52.579163360941251],
            [-0.764555021404318, 52.579074350907327],
            [-0.764764643950009, 52.579544934626483],
            [-0.764347806290779, 52.57988311877785],
            [-0.765208688776261, 52.580654478850938],
            [-0.764802895305771, 52.581177081842604],
            [-0.765961143369053, 52.581714191880252],
            [-0.765385293108114, 52.582511028397583],
            [-0.765864910503464, 52.582302955815763],
            [-0.767111857942647, 52.582836483354129],
            [-0.767624990548891, 52.582590993103388],
            [-0.767351715621714, 52.582283376687023],
            [-0.768041397789729, 52.582162889652032],
            [-0.768892942225621, 52.582902658852809],
            [-0.769534331590778, 52.582820319590702],
            [-0.769683914709806, 52.583223741729988],
            [-0.771342141127595, 52.583356031563831],
            [-0.77057580672171, 52.583736462036754],
            [-0.771234709974144, 52.58440049335254],
            [-0.773919272668534, 52.584357290144276],
            [-0.774306799247429, 52.585068840381169],
            [-0.773844158387103, 52.585462323641615],
            [-0.774393484011491, 52.585453624622829],
            [-0.774682116741148, 52.586059862229177],
            [-0.776080372879091, 52.586468095627581],
            [-0.775161573634936, 52.587022291520967],
            [-0.77553474762849, 52.587455887668632],
            [-0.776727619868828, 52.587131076067237],
            [-0.777034005238315, 52.587472276277168],
            [-0.776274254447233, 52.587668509733952],
            [-0.775970976441823, 52.588116690467182],
            [-0.776543571604932, 52.588068664364179],
            [-0.77708985079636, 52.588541802721814],
            [-0.77736846104789, 52.589616325655172],
            [-0.778446560203016, 52.58981085161129],
            [-0.778096825446545, 52.589435044888731],
            [-0.778900121560267, 52.589266219593796],
            [-0.779116367050627, 52.590196249991202],
            [-0.780576365635175, 52.590088122247373],
            [-0.780801704576582, 52.590691894299525],
            [-0.782340646324981, 52.591144654102209],
            [-0.782333954648151, 52.592131722097982],
            [-0.783791270141982, 52.592442475587418],
            [-0.783680596683956, 52.59324057808989],
            [-0.784192690366211, 52.593353719144979],
            [-0.784250265955827, 52.592980313076218],
            [-0.784786062471772, 52.593037055882384],
            [-0.784628125795364, 52.593676444751168],
            [-0.784321572660762, 52.593551030748692],
            [-0.783947053033645, 52.59390769798415],
            [-0.78514355871447, 52.59495026498611],
            [-0.786027816233401, 52.595220049512434],
            [-0.786268557974234, 52.595911174059523],
            [-0.788258272580306, 52.596408929809421],
            [-0.79001581996612, 52.596010644500737],
            [-0.789856006702546, 52.596774086767631],
            [-0.791246070308902, 52.596799966811624],
            [-0.79156927704333, 52.596536252483837],
            [-0.792446646756124, 52.597596165800333],
            [-0.793059349669971, 52.59754307412561],
            [-0.793664416289045, 52.596800345591312],
            [-0.794825504179211, 52.596508292061117],
            [-0.796245414473025, 52.596899423168203],
            [-0.797189985261609, 52.596588064511408],
            [-0.798528815858713, 52.597137473042757],
            [-0.800843486605515, 52.59704225545952],
            [-0.801309386796839, 52.597336458316512],
            [-0.802390770821502, 52.596446568827957],
            [-0.803538537074816, 52.596370062444187],
            [-0.804150463713813, 52.595747820200202],
            [-0.80456320577074, 52.596313879440288],
            [-0.805296006023942, 52.596023693811453],
            [-0.807594539291274, 52.596357018550506],
            [-0.807823377493062, 52.595976335573049],
            [-0.808623218693366, 52.595826153617523],
            [-0.809349958594854, 52.596193971105805],
            [-0.809554080523938, 52.595253840511205],
            [-0.810109087637067, 52.594821589852451],
            [-0.811840518649601, 52.595324445003392],
            [-0.811396031030511, 52.594673584152979],
            [-0.812122375670554, 52.594946982705025],
            [-0.812071395282076, 52.594157124263432],
            [-0.813308692262107, 52.594479690981018],
            [-0.814386534785099, 52.594092214342844],
            [-0.815973071703305, 52.594110780464348],
            [-0.816540051980795, 52.593614788562512],
            [-0.816969102740387, 52.593690097619486],
            [-0.817485064907827, 52.595582309778074],
            [-0.819232735834556, 52.595704929617398],
            [-0.819237255925142, 52.59619584389894],
            [-0.819864049969265, 52.596330650996123],
            [-0.819687556481416, 52.596745142396053],
            [-0.820961108053732, 52.596436872324595],
            [-0.818993679378757, 52.600093397381855],
            [-0.818555794031948, 52.602868822962357],
            [-0.818836201111526, 52.604576173896902],
            [-0.820002788603397, 52.605839244363501],
            [-0.81936250677785, 52.609660918476365],
            [-0.818634986549185, 52.610902413033891],
            [-0.817463840455677, 52.611778966610593],
            [-0.815693773401702, 52.611696555900593],
            [-0.814790593546908, 52.61194464543464],
            [-0.811331432757788, 52.614080238772196],
            [-0.810926038217421, 52.614923952200961],
            [-0.809537034620493, 52.615654397118988],
            [-0.809852187273589, 52.616996212589406],
            [-0.808941691068588, 52.619794719628331],
            [-0.807934417606129, 52.620386936669242],
            [-0.806027444580023, 52.620437859302235],
            [-0.805835009535487, 52.620669666576276],
            [-0.805646831503822, 52.621776268093953],
            [-0.810968091241952, 52.625966199348397],
            [-0.809862210032889, 52.627302734141523],
            [-0.809933250666238, 52.629422447067896],
            [-0.80880326794737, 52.630336188517227],
            [-0.805151775909981, 52.631710892596182],
            [-0.804473558105818, 52.6322533555227],
            [-0.804446713851762, 52.634864748376884],
            [-0.802027068967527, 52.637756743913059],
            [-0.801761541340084, 52.638821199875323],
            [-0.804140086163351, 52.640420314083315],
            [-0.807993426013919, 52.639204999401983],
            [-0.809910446589739, 52.640284213776376],
            [-0.80875779134605, 52.642022129927241],
            [-0.809717819539446, 52.644277532595169],
            [-0.808433904067834, 52.645891859638461],
            [-0.808702960386264, 52.646107632586414],
            [-0.807408571609763, 52.646799447196791],
            [-0.807095078204209, 52.647673737050802],
            [-0.805507784644431, 52.647611899560872],
            [-0.804384719922448, 52.649443569076965],
            [-0.801589482403118, 52.651876857989762],
            [-0.79882448252787, 52.657133313347941],
            [-0.798156727137232, 52.657600328144902],
            [-0.797418221311162, 52.657627899281039],
            [-0.79489816429315, 52.65695680657894],
            [-0.793885776009431, 52.659093370944802],
            [-0.792928239382348, 52.65966439085696],
            [-0.794528844292761, 52.66087008457793],
            [-0.794126086034897, 52.662342179327169],
            [-0.793602803319586, 52.663357242626866],
            [-0.791134520771959, 52.665140924183426],
            [-0.790149988549386, 52.667165361954616],
            [-0.7845466756594, 52.667279751926117],
            [-0.78337567624265, 52.667858395397083],
            [-0.782204877486904, 52.669120284967093],
            [-0.782126327234819, 52.670456321143831],
            [-0.783323919385281, 52.672171362891405],
            [-0.784244882369236, 52.67506396745808],
            [-0.784396069355345, 52.676544404490038],
            [-0.784002343384476, 52.67698088586863],
            [-0.783856025735486, 52.681930281103568],
            [-0.785064844547631, 52.684693670511507],
            [-0.786791830235099, 52.687182754773453],
            [-0.785558140452722, 52.692829436442352],
            [-0.785813762574069, 52.694229126094029],
            [-0.786054612036381, 52.6947215561664],
            [-0.790084857746084, 52.694364486678474],
            [-0.793116272951044, 52.696152957181269],
            [-0.795753791636053, 52.696830661179263],
            [-0.79638080125617, 52.69996649839878],
            [-0.796155139220292, 52.701176079201588],
            [-0.795547544334377, 52.701528615829588],
            [-0.796351691171089, 52.70360721115086],
            [-0.796615504174957, 52.703970393357707],
            [-0.797492688140959, 52.704041324033398],
            [-0.799507428515044, 52.703486369404622],
            [-0.799306870284476, 52.703298242911352],
            [-0.802437035476269, 52.703245389389735],
            [-0.803377460179566, 52.704629474852503],
            [-0.800243694588713, 52.707778519309421],
            [-0.813175120187984, 52.712276044430986],
            [-0.813221682221945, 52.713451518174864],
            [-0.814581454754099, 52.713475906159317],
            [-0.815195733303857, 52.714953725684765],
            [-0.821761604500388, 52.715655639371413],
            [-0.821217238136073, 52.715969378435084],
            [-0.821599845578602, 52.716604285858793],
            [-0.820978109305962, 52.716767120356863],
            [-0.821480122572084, 52.716926739800542],
            [-0.821289455950916, 52.717354571708931],
            [-0.820910735714779, 52.717618712026635],
            [-0.82046692456707, 52.717441688894183],
            [-0.820284832016697, 52.717715873771567],
            [-0.819614206697214, 52.717659755412058],
            [-0.819349974682786, 52.718014931225099],
            [-0.819798934168717, 52.718549815702147],
            [-0.818335395948041, 52.719346149529606],
            [-0.81860781075901, 52.719565524824141],
            [-0.817745995835861, 52.721541950533968],
            [-0.817961540978781, 52.721784134530878],
            [-0.818372124435017, 52.721657869514154],
            [-0.818553985767114, 52.722215269148634],
            [-0.818103236651551, 52.722348326625259],
            [-0.818405296719907, 52.72262193818878],
            [-0.816631919580436, 52.72462972559498],
            [-0.817407294366071, 52.725086963908709],
            [-0.817280552204608, 52.72539316123958],
            [-0.816519158770111, 52.725185087206384],
            [-0.815180581235214, 52.725785746211329],
            [-0.813723976064609, 52.727460424774463],
            [-0.813581531672254, 52.728944162917223],
            [-0.812914564293528, 52.729456217689417],
            [-0.810146499323233, 52.730011039563855],
            [-0.805639539011655, 52.731603736766303],
            [-0.80329317239785, 52.733948976224291],
            [-0.802219555788632, 52.735619286524781],
            [-0.801266055559942, 52.735985440061789],
            [-0.801199912878618, 52.736722857926047],
            [-0.800431320693789, 52.736772624283631],
            [-0.800215751250627, 52.736532206681034],
            [-0.799984265775603, 52.736979369517627],
            [-0.799026634599088, 52.736739531312338],
            [-0.799025810747389, 52.73714677405534],
            [-0.798335902374379, 52.7367586070675],
            [-0.79741760529705, 52.736812231269404],
            [-0.797541995250477, 52.737082296044051],
            [-0.796900625320773, 52.73729874685727],
            [-0.796551803959133, 52.736788167177167],
            [-0.795638202616207, 52.736993757825324],
            [-0.795299772812324, 52.737987322431117],
            [-0.794253321786019, 52.737960507816076],
            [-0.793262161110802, 52.739471552299094],
            [-0.792599720629267, 52.739322767912284],
            [-0.791752137735198, 52.739548779006327],
            [-0.791856418088946, 52.740170157504842],
            [-0.791003359211115, 52.740165062156876],
            [-0.791284235597102, 52.739864059416263],
            [-0.789583278703758, 52.740202722604657],
            [-0.78915181277947, 52.740004133077598],
            [-0.787093701418216, 52.740935157694459],
            [-0.783597382648098, 52.740368041495351],
            [-0.78236081085727, 52.740358051604311],
            [-0.782369589568961, 52.740680886293617],
            [-0.781508763607905, 52.740582145906338],
            [-0.78152802519206, 52.740847551714808],
            [-0.780681475313194, 52.740555664802628],
            [-0.78037826102487, 52.74101733586285],
            [-0.780019366855258, 52.740979482920835],
            [-0.779752611847558, 52.741779555129639],
            [-0.778170880807289, 52.74245730938793],
            [-0.77649320803599, 52.743914393884445],
            [-0.775551838249822, 52.743516308399379],
            [-0.774848685690719, 52.744026878168043],
            [-0.773742567786168, 52.744168280802477],
            [-0.773633191087893, 52.743895649005971],
            [-0.772723981670672, 52.743777465657068],
            [-0.771421250726793, 52.744167636254126],
            [-0.771300269653667, 52.743623380892849],
            [-0.770220767090629, 52.74360859852456],
            [-0.770420145123146, 52.742893254833319],
            [-0.768606132983475, 52.743343721589603],
            [-0.768612051035168, 52.743659335798178],
            [-0.76766241504383, 52.743662060355732],
            [-0.767835949924651, 52.742971624658374],
            [-0.766756170063788, 52.74218006223709],
            [-0.766919826816984, 52.741734955032747],
            [-0.765329316194913, 52.741353412294799],
            [-0.764133779954352, 52.740520164972011],
            [-0.764280144610726, 52.740058698829067],
            [-0.764819146359595, 52.739960027309763],
            [-0.764734708821782, 52.739174310840255],
            [-0.763575065777601, 52.739064236633794],
            [-0.762717088510549, 52.738292029180158],
            [-0.761491139592456, 52.738066169245805],
            [-0.761605052504622, 52.738802750499474],
            [-0.760032394681855, 52.738632590122783],
            [-0.760020532426969, 52.738946221383607],
            [-0.759135134126365, 52.739141937320881],
            [-0.759166209203995, 52.73956030307108],
            [-0.757987162899979, 52.739716078867055],
            [-0.757667377945782, 52.739033076758105],
            [-0.756686847939576, 52.738511265110525],
            [-0.756157955854539, 52.738618995825036],
            [-0.756354928435342, 52.738984262215489],
            [-0.755849254760967, 52.739212702684],
            [-0.755620330177751, 52.738721238493255],
            [-0.754866142374181, 52.738938077317748],
            [-0.754277052925196, 52.738663087361054],
            [-0.754006326826832, 52.738443581797512],
            [-0.754629792105749, 52.737720130841936],
            [-0.753328500713508, 52.73655482552337],
            [-0.751524303868437, 52.736716550929152],
            [-0.745150337269674, 52.738388882369492],
            [-0.74297992654833, 52.738353315462561],
            [-0.733820094675534, 52.739737467703428],
            [-0.729078315782995, 52.739391880026218],
            [-0.728889752022343, 52.740018270947544],
            [-0.7237452063376, 52.740378375346111],
            [-0.720219554417037, 52.740942729553161],
            [-0.720373306141226, 52.741610558770567],
            [-0.719531249051533, 52.741481902864663],
            [-0.719374703873889, 52.741718452005109],
            [-0.717711629689378, 52.741955806072767],
            [-0.718206553471976, 52.744777770443342],
            [-0.707553804100721, 52.745574683511059],
            [-0.704289691774147, 52.746140516705964],
            [-0.701717797656816, 52.746043172071403],
            [-0.699947386997514, 52.746592860027484],
            [-0.696253179920842, 52.7464732025079],
            [-0.696976263772739, 52.749200673099409],
            [-0.690050889577759, 52.751984174062258],
            [-0.684360577788578, 52.753089925619996],
            [-0.684086801217341, 52.754848962565212],
            [-0.684438335132351, 52.756460303437308],
            [-0.674524628810733, 52.757935820949072],
            [-0.674095996518814, 52.756923232540537],
            [-0.671563510220156, 52.75677802917879],
            [-0.671330767845754, 52.756391542099166],
            [-0.669544071570535, 52.756622344154231],
            [-0.664909499100472, 52.756457900496621],
            [-0.664111082794406, 52.756690748533146],
            [-0.6636904354598, 52.756781307023701],
            [-0.662604294426078, 52.755473581423395],
            [-0.656938705480691, 52.756652919210971],
            [-0.645087622852378, 52.757254457347855],
            [-0.639581235095398, 52.757153682218664],
            [-0.628157684482141, 52.757954418860123],
            [-0.627748269719834, 52.757967664246848],
            [-0.627696742573443, 52.75767398701791],
            [-0.623060257309464, 52.758933735109551],
            [-0.615505396973512, 52.759309704249347],
            [-0.611783358389548, 52.759804723726994],
            [-0.608603678305768, 52.759693753835158],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000018",
        LAD13CDO: "00FY",
        LAD13NM: "Nottingham",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.183159763803982, 53.016741321195546],
            [-1.184485357046953, 53.01442665762589],
            [-1.170794661489777, 53.013946375382503],
            [-1.167776330300513, 53.014253412251811],
            [-1.166786858512296, 53.010601281878465],
            [-1.166659450309228, 53.010174291409442],
            [-1.165291294870989, 53.010402918808687],
            [-1.162279548430533, 53.011612398827005],
            [-1.16215222629171, 53.010644241481572],
            [-1.157987272860589, 53.010600451659691],
            [-1.158510060518138, 53.009622506656243],
            [-1.15464037889361, 53.009498883882884],
            [-1.154160902913182, 53.009019036399209],
            [-1.154256742388731, 53.008233143529843],
            [-1.151539556613274, 53.007637572396057],
            [-1.150741616585491, 53.008351931253578],
            [-1.14751250146266, 53.007266284160373],
            [-1.146399623084443, 53.008228262789117],
            [-1.143516050509743, 53.006481565874509],
            [-1.147319282015068, 53.002776476840296],
            [-1.147866638569643, 53.001087688711962],
            [-1.14970305165942, 53.000223461439191],
            [-1.150152078348897, 52.999058942659005],
            [-1.153445894944886, 52.99741758698179],
            [-1.153659419902531, 52.997165604345511],
            [-1.152387208434961, 52.996620774672017],
            [-1.149079957494106, 52.997657914730475],
            [-1.148206452541446, 52.997221967290521],
            [-1.146096565631414, 52.998358386589068],
            [-1.141323961917503, 52.99655760259693],
            [-1.140749236635491, 52.996086899831099],
            [-1.140492040584087, 52.994782470414741],
            [-1.138257849545345, 52.994823836901631],
            [-1.139179904351237, 52.993484792824468],
            [-1.140041287178565, 52.992572301865707],
            [-1.14017344952583, 52.989517741418069],
            [-1.142491734170228, 52.986268608249226],
            [-1.139823165147686, 52.985927514777842],
            [-1.136203864214282, 52.986503576879521],
            [-1.132819615061318, 52.986495129911852],
            [-1.131192850995834, 52.986054459617698],
            [-1.126000158369791, 52.984648214727564],
            [-1.123727585418489, 52.983445779928381],
            [-1.122789166129545, 52.983446952304014],
            [-1.125671691119089, 52.981586681418378],
            [-1.125276498304174, 52.981463316290494],
            [-1.125840728487245, 52.980590990756966],
            [-1.121324304263252, 52.974888036534267],
            [-1.120498685623835, 52.974550220853601],
            [-1.120461246482679, 52.973753474817912],
            [-1.119754291346455, 52.969408114236835],
            [-1.120109954278975, 52.967805221494054],
            [-1.115192903354029, 52.964836356894772],
            [-1.114645276710607, 52.964603946943384],
            [-1.112204927093475, 52.965262661335167],
            [-1.109473685269978, 52.964132931016209],
            [-1.104061663548443, 52.964062608722323],
            [-1.103889259959866, 52.962103391837459],
            [-1.102077184515942, 52.962473573604989],
            [-1.101498796942647, 52.958917442463729],
            [-1.09694133845647, 52.95801812106653],
            [-1.094583972809083, 52.956703901309666],
            [-1.096640412745007, 52.95545651429974],
            [-1.092165828669533, 52.95481563725744],
            [-1.092041144477208, 52.954514434428127],
            [-1.092855100803527, 52.954384905732184],
            [-1.091876335374539, 52.953144068288481],
            [-1.089732554388019, 52.952523576265691],
            [-1.088110989811436, 52.951562752274114],
            [-1.086118974698574, 52.948549440536169],
            [-1.090071109155892, 52.94710007551965],
            [-1.095334562345632, 52.944415512704381],
            [-1.097597780001591, 52.942570078652835],
            [-1.099680517283199, 52.941954803979783],
            [-1.103943717455321, 52.942324123672854],
            [-1.109165075657621, 52.944445345213857],
            [-1.111960434681552, 52.944563346806284],
            [-1.115108810495851, 52.943864064644778],
            [-1.117617230003467, 52.943162647756672],
            [-1.121391672501645, 52.942695280371751],
            [-1.126248375749072, 52.94338551460271],
            [-1.129578666995934, 52.943245436934767],
            [-1.131594269536141, 52.942840366771357],
            [-1.131603250932337, 52.94283773552025],
            [-1.134616694375309, 52.940748968061037],
            [-1.137595897312027, 52.937298860180533],
            [-1.139256620732693, 52.933238635702509],
            [-1.140064763209766, 52.93247408111376],
            [-1.141886837976755, 52.931969451256577],
            [-1.143805283276771, 52.931856532809185],
            [-1.146397016054299, 52.932154747536494],
            [-1.148002768310406, 52.932964538838782],
            [-1.150355554896042, 52.935821173012926],
            [-1.152291936752631, 52.937006333384844],
            [-1.15416232867671, 52.934178066677994],
            [-1.155497588401271, 52.930473085341681],
            [-1.158896337877393, 52.912770696093531],
            [-1.159018744768847, 52.909828379634284],
            [-1.1600136272462, 52.909670007816175],
            [-1.158666634764054, 52.905513591629202],
            [-1.158607567465928, 52.900042117892625],
            [-1.162545470847701, 52.900285685543992],
            [-1.163381483200999, 52.899564317672464],
            [-1.16411863095961, 52.899807729076819],
            [-1.164218104715272, 52.899273548954255],
            [-1.166305113474524, 52.898897163062493],
            [-1.167030384771828, 52.89813184262789],
            [-1.168721723625389, 52.89784163696671],
            [-1.170233189843998, 52.896771654040336],
            [-1.170061883234438, 52.895856217608554],
            [-1.171115239431906, 52.895496795368125],
            [-1.170906825629183, 52.894889443727529],
            [-1.171939279850917, 52.893363020853641],
            [-1.173816967099161, 52.892172386584221],
            [-1.17355818783153, 52.891711219309741],
            [-1.17493081503733, 52.889637872107727],
            [-1.176036979227933, 52.889540370009861],
            [-1.17681693875976, 52.889009098041029],
            [-1.178813142155562, 52.889867033075376],
            [-1.178208042980237, 52.891291294009207],
            [-1.192050623277755, 52.895037789361616],
            [-1.191025880264201, 52.896585113165038],
            [-1.195460772175265, 52.897838702335491],
            [-1.195888320526455, 52.897227609339637],
            [-1.198926339605066, 52.897738059734209],
            [-1.202817667434193, 52.899143226689802],
            [-1.204313310529129, 52.90011153712161],
            [-1.205009519670863, 52.900086531298477],
            [-1.211306069881217, 52.902592522896398],
            [-1.213236964395309, 52.900724709664544],
            [-1.216577548028319, 52.90014537925807],
            [-1.217503895133876, 52.90063152432252],
            [-1.214153958964311, 52.903288302231807],
            [-1.213924298773596, 52.904395193622236],
            [-1.214427578063856, 52.904600786217628],
            [-1.213829186561592, 52.905625232278048],
            [-1.213360490725966, 52.910160047778618],
            [-1.212083268515667, 52.911465856335425],
            [-1.210509111520435, 52.912250084168988],
            [-1.208301363550732, 52.912628240262158],
            [-1.205713156479203, 52.912422190613334],
            [-1.199013526602526, 52.90945922200634],
            [-1.197568176758353, 52.90994299754724],
            [-1.193352183390164, 52.912445911184264],
            [-1.190957228507362, 52.913684571491032],
            [-1.187535930237285, 52.914767844480295],
            [-1.182305213769884, 52.917316464455055],
            [-1.19577799799764, 52.927841633386073],
            [-1.197423808190738, 52.929100501753155],
            [-1.196634498794255, 52.929629146804686],
            [-1.199000454566147, 52.931210187224721],
            [-1.200468960901262, 52.930233924671541],
            [-1.203109472808739, 52.932266204220049],
            [-1.205112464193141, 52.933377233324464],
            [-1.206053860551473, 52.932747067959191],
            [-1.208893872583848, 52.933978686020801],
            [-1.209351315669764, 52.933287740174713],
            [-1.215296039015686, 52.933971678138029],
            [-1.214719586983516, 52.936064221290017],
            [-1.216226195464949, 52.937169084108142],
            [-1.217129165500486, 52.93891180302753],
            [-1.21808873238195, 52.940573082346098],
            [-1.221034214569446, 52.940784793883083],
            [-1.222795701038898, 52.941386032711371],
            [-1.224263361524005, 52.94244379164698],
            [-1.226344522079897, 52.94239892173038],
            [-1.226901064180944, 52.9410631102702],
            [-1.231405115174554, 52.942254653904413],
            [-1.232431419327763, 52.942029356213538],
            [-1.233167856219478, 52.942291207590721],
            [-1.234285057802927, 52.942058389667181],
            [-1.234196830708593, 52.943515913461582],
            [-1.23566160740948, 52.94526390271286],
            [-1.237873656073869, 52.945738364066571],
            [-1.241844989408948, 52.947651542190393],
            [-1.244722315571434, 52.949503704632228],
            [-1.24507479724777, 52.950373425517597],
            [-1.24579457631477, 52.950920956483323],
            [-1.246825751355301, 52.953079567362984],
            [-1.245726034297337, 52.955888989512907],
            [-1.24335368855077, 52.959448118902408],
            [-1.242955182623597, 52.960785907789834],
            [-1.243292723174371, 52.960894131694033],
            [-1.241923789911794, 52.962131348236213],
            [-1.240997574819618, 52.962397819701771],
            [-1.240764642027919, 52.963031885830347],
            [-1.239544811252006, 52.963994051160611],
            [-1.239169143743524, 52.9653787178855],
            [-1.237826773299169, 52.966445257758394],
            [-1.231981246957369, 52.967623018957184],
            [-1.235077640562011, 52.971456293013361],
            [-1.236801546050377, 52.971482651164507],
            [-1.237116476983569, 52.971864025653893],
            [-1.239820114934862, 52.976481225278455],
            [-1.239855879552126, 52.977583556266005],
            [-1.240866842697459, 52.978024202419547],
            [-1.242610163379691, 52.977806986414812],
            [-1.243759252302756, 52.978783357411366],
            [-1.244889832784394, 52.981166441818743],
            [-1.246508141129689, 52.983090549812012],
            [-1.246840701325629, 52.984523768995857],
            [-1.245462996738419, 52.985200928025868],
            [-1.242132582211177, 52.983568852034047],
            [-1.23938297162851, 52.982634379809511],
            [-1.23505943214208, 52.982526644968999],
            [-1.226081566764678, 52.981154310229954],
            [-1.224864859800201, 52.982227813431514],
            [-1.223698322719179, 52.981661961974417],
            [-1.222766143610797, 52.982231196450776],
            [-1.22201253717662, 52.983305898191226],
            [-1.224094779240349, 52.98414203221072],
            [-1.222531542454141, 52.986007914299591],
            [-1.217938781144019, 52.983964173997855],
            [-1.215053892457025, 52.983124434935419],
            [-1.213877186939673, 52.983699177414842],
            [-1.213165935020104, 52.984724661646389],
            [-1.210528888299946, 52.986143695783497],
            [-1.205491361462674, 52.987101684068307],
            [-1.205297237129108, 52.987370968699842],
            [-1.203614717377953, 52.987004632467638],
            [-1.203598963505628, 52.987051271974835],
            [-1.203355166176059, 52.987509897645573],
            [-1.208420086079052, 52.988807627025842],
            [-1.212129835359989, 52.990340682454168],
            [-1.219109604396995, 52.991479823924159],
            [-1.217925039093124, 52.994047505559671],
            [-1.213940275455482, 52.997376982792311],
            [-1.216393256797082, 53.000397424098587],
            [-1.220599600995234, 53.003387063080517],
            [-1.220003868723431, 53.005352732950627],
            [-1.218703638361424, 53.006466075678375],
            [-1.22118139773846, 53.007410926201118],
            [-1.222880009422448, 53.007348321623333],
            [-1.22515015050907, 53.008076886344597],
            [-1.224742950279608, 53.008594718370595],
            [-1.222387892095963, 53.008360905764221],
            [-1.212978380045022, 53.012668765642729],
            [-1.210974781621543, 53.015140158047501],
            [-1.210163391601138, 53.018025756740499],
            [-1.207224273039223, 53.018647150658531],
            [-1.203025048297782, 53.018316153353808],
            [-1.201035812297973, 53.017865927573389],
            [-1.199949534233905, 53.016794285214928],
            [-1.198024235163614, 53.015956097291749],
            [-1.195188378833298, 53.015431743651781],
            [-1.189208871563173, 53.017296892873006],
            [-1.188315769577605, 53.017942533484813],
            [-1.186815193517159, 53.017754296527492],
            [-1.184529281787697, 53.016366869367644],
            [-1.184038192745966, 53.017587857040311],
            [-1.183210567780517, 53.017919279993237],
            [-1.182749956009648, 53.018549868682058],
            [-1.183159763803982, 53.016741321195546],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000019",
        LAD13CDO: "00GA",
        LAD13NM: "Herefordshire, County of",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.828462652097989, 52.391188329613897],
            [-2.826203238847855, 52.391066595874612],
            [-2.823254587071477, 52.391336206819645],
            [-2.81822375731792, 52.391216513733525],
            [-2.817284957595149, 52.390511875401081],
            [-2.815733175122271, 52.390430896183524],
            [-2.814588089949414, 52.39001175464243],
            [-2.813937309034438, 52.389223292061459],
            [-2.812309936599033, 52.389024116343776],
            [-2.810380105392869, 52.387954931147632],
            [-2.8076551162179, 52.388637972358609],
            [-2.805433452448989, 52.38823868207642],
            [-2.80543837870058, 52.386088162563013],
            [-2.807496458676439, 52.385178679637896],
            [-2.809076869787101, 52.383863371969269],
            [-2.809214623019149, 52.37966663884778],
            [-2.808005466154364, 52.378213983416266],
            [-2.806995512564835, 52.377772267562996],
            [-2.807389838504123, 52.375954423585306],
            [-2.803471939319634, 52.374248691834012],
            [-2.801127072283166, 52.373898707346413],
            [-2.798651395710921, 52.372646930245693],
            [-2.796930632974269, 52.373586352668163],
            [-2.793652014143891, 52.374322254274425],
            [-2.792702071214922, 52.375413765088339],
            [-2.792113186759704, 52.374520478881564],
            [-2.792804004413996, 52.372575730314566],
            [-2.793653031786553, 52.372167262901101],
            [-2.794228637114581, 52.371010833006267],
            [-2.793396058764577, 52.368402034458562],
            [-2.793684197215814, 52.367925408783861],
            [-2.792126679196542, 52.365096709334821],
            [-2.793602987131977, 52.363722965087184],
            [-2.793662539357788, 52.362703058750441],
            [-2.79544530753944, 52.361229236456339],
            [-2.795624717668755, 52.360426088217807],
            [-2.795399504938416, 52.359251665498057],
            [-2.79242114935981, 52.356850576376125],
            [-2.790907150763111, 52.357829883430021],
            [-2.788868585480799, 52.357969379032433],
            [-2.786119263270673, 52.357527399831163],
            [-2.783040851481816, 52.358650062713899],
            [-2.781831755966684, 52.358200461716294],
            [-2.77853976124585, 52.358041496623478],
            [-2.776773897943041, 52.358410937055311],
            [-2.774804675726415, 52.358321378907988],
            [-2.770187054977162, 52.358771423430589],
            [-2.766851442159538, 52.359485379780836],
            [-2.761444140870969, 52.359454726889332],
            [-2.759622597582464, 52.359243493156583],
            [-2.758849493546864, 52.358859179941632],
            [-2.752119011398017, 52.359247451229166],
            [-2.749279230642249, 52.359256506576045],
            [-2.748618674913657, 52.359009863952849],
            [-2.742693282687103, 52.359340349556497],
            [-2.743094814941873, 52.359116662279099],
            [-2.742567823005468, 52.357463057202871],
            [-2.741679813975327, 52.356782673106459],
            [-2.73825663782728, 52.356049826463519],
            [-2.735900456018884, 52.356078013411661],
            [-2.732768099723143, 52.355519406573841],
            [-2.733779044113946, 52.354235604533933],
            [-2.735527176161936, 52.353317607560882],
            [-2.736060330203755, 52.352268710063903],
            [-2.738546352082326, 52.350882165823023],
            [-2.738860077232295, 52.349622454568554],
            [-2.741181124804677, 52.349708605578101],
            [-2.738620987750599, 52.347522006456288],
            [-2.739767758576143, 52.346569045136583],
            [-2.739047512377041, 52.34528164010603],
            [-2.738041451256767, 52.344722437344842],
            [-2.737881445426659, 52.342783318416167],
            [-2.739904560123338, 52.340960004265803],
            [-2.74097815385674, 52.340909221291597],
            [-2.743209705544725, 52.33971206904036],
            [-2.744104506083164, 52.338805600681923],
            [-2.745515180452511, 52.338641171686945],
            [-2.745554161398284, 52.337642994865043],
            [-2.746569477775118, 52.336490309387635],
            [-2.748345995982165, 52.335832658949151],
            [-2.748381434897652, 52.334889344278423],
            [-2.752148502323, 52.335130630564855],
            [-2.753021061327933, 52.335349830018266],
            [-2.753338505270566, 52.335979829041747],
            [-2.755635970043275, 52.336530647352923],
            [-2.75706533983058, 52.336092653042947],
            [-2.758505052192997, 52.334459753587709],
            [-2.766988302747532, 52.336099655518751],
            [-2.765232573167966, 52.333440900274788],
            [-2.763221187436431, 52.331689102664875],
            [-2.759848456772401, 52.330211253562517],
            [-2.758518746929678, 52.329256930705412],
            [-2.756454723925371, 52.328779293884061],
            [-2.755973621732959, 52.329480028397427],
            [-2.755688199409491, 52.328661931820321],
            [-2.753389054369189, 52.327383803255749],
            [-2.752595413056879, 52.327482363453299],
            [-2.751449225085949, 52.326952935915713],
            [-2.750551406219447, 52.327398275090637],
            [-2.749490878145542, 52.327333986183376],
            [-2.745916464354225, 52.326641880696499],
            [-2.745492510029389, 52.326386533603845],
            [-2.746701538783707, 52.325250601966694],
            [-2.746177706806443, 52.324901489689339],
            [-2.745320298447657, 52.32583111570743],
            [-2.74401263886841, 52.32502752933415],
            [-2.743308106035902, 52.323613282738002],
            [-2.742792585306041, 52.323582362895159],
            [-2.741803751433098, 52.322798323198356],
            [-2.738723765100753, 52.322075922841115],
            [-2.738000551319242, 52.321289290463753],
            [-2.738109910645358, 52.320278986234428],
            [-2.737173520734372, 52.319463113563884],
            [-2.734271509897109, 52.319635828236947],
            [-2.733757021101713, 52.319398083701728],
            [-2.731098723484523, 52.319670797224568],
            [-2.730810856158191, 52.32018593016992],
            [-2.728119944068327, 52.320352691745612],
            [-2.726157478141244, 52.320297347616183],
            [-2.724669591536458, 52.319835391334884],
            [-2.721739316842257, 52.317464591606708],
            [-2.719828914513864, 52.316721958933272],
            [-2.719348265597656, 52.312684606892319],
            [-2.715397887719063, 52.31067947471783],
            [-2.714124483351651, 52.30884415205486],
            [-2.712395345277783, 52.3081236779108],
            [-2.710851524849014, 52.308896266645192],
            [-2.710597839618619, 52.308717086686393],
            [-2.709128711506408, 52.309118799007699],
            [-2.709321973464899, 52.309372066310623],
            [-2.706332255572319, 52.310265652346118],
            [-2.702949962393661, 52.309997244811875],
            [-2.692912921631132, 52.310292119125414],
            [-2.692480824255052, 52.310049216522003],
            [-2.691600658275122, 52.310513783597628],
            [-2.689045795631798, 52.310722912294146],
            [-2.688498993681387, 52.310459986606247],
            [-2.68453214486342, 52.311276001454118],
            [-2.681465413674943, 52.311207432545295],
            [-2.680647285386536, 52.31151782411775],
            [-2.680349043580126, 52.31206076347339],
            [-2.679812861309328, 52.312009909365656],
            [-2.680357470425379, 52.313088314641092],
            [-2.681028276394605, 52.313498903895024],
            [-2.679946631885813, 52.315186336617089],
            [-2.684389122794432, 52.31560120056379],
            [-2.68622481272816, 52.314780514476404],
            [-2.687238247135572, 52.314671231394399],
            [-2.688126583957834, 52.314914192303064],
            [-2.689287678091341, 52.316188548592265],
            [-2.686070421808634, 52.319498658506205],
            [-2.685277535480862, 52.324393120209614],
            [-2.678535900093375, 52.329503505479394],
            [-2.676226734260881, 52.33300410447584],
            [-2.675660004090602, 52.33452761449233],
            [-2.676016283789343, 52.334697291187013],
            [-2.674380765668781, 52.335911346488771],
            [-2.673499353325355, 52.337691966999699],
            [-2.670208732532428, 52.33972811562348],
            [-2.66967844353861, 52.341545371402383],
            [-2.668086944262296, 52.341618215590593],
            [-2.667251472399524, 52.342184833611576],
            [-2.666068461574098, 52.342287707322747],
            [-2.662879052485553, 52.341518997139147],
            [-2.662327508182555, 52.340953902943482],
            [-2.661173891151691, 52.34095946740549],
            [-2.66029470168357, 52.341355464746798],
            [-2.659407942227357, 52.339665743145936],
            [-2.659620345573043, 52.339242910922984],
            [-2.658346422047223, 52.338952439037385],
            [-2.658516632187117, 52.338554117821367],
            [-2.655969608488769, 52.337825690699816],
            [-2.6560528702794, 52.337203996783614],
            [-2.656685473552253, 52.337106981734465],
            [-2.659064922845447, 52.335254306555079],
            [-2.659750433131181, 52.335263065627338],
            [-2.659851385765059, 52.334749153780749],
            [-2.65934080347387, 52.334265628425953],
            [-2.65814724424752, 52.334238122489509],
            [-2.655597732072596, 52.333125814125331],
            [-2.65317756225588, 52.333014258424114],
            [-2.653080369310242, 52.332593149417534],
            [-2.651762630653713, 52.332500636297311],
            [-2.651254315363332, 52.33216360594966],
            [-2.649442103347743, 52.332375867759048],
            [-2.649567429850796, 52.331815080947173],
            [-2.649171056127549, 52.33170488126791],
            [-2.648264450134764, 52.332027219869524],
            [-2.647651877238347, 52.33168355403987],
            [-2.646812680244087, 52.33260336864339],
            [-2.646189289636902, 52.33252407016483],
            [-2.64586568347091, 52.333278330730877],
            [-2.643559114333829, 52.333813260149228],
            [-2.641017796829846, 52.332834549129771],
            [-2.640658473652589, 52.331530204522565],
            [-2.641458465174082, 52.331341560340711],
            [-2.640926826841134, 52.33090751682991],
            [-2.641594737930737, 52.330516406983271],
            [-2.641044922145708, 52.330448369108673],
            [-2.640861081229023, 52.32991264421954],
            [-2.641785075399602, 52.329675675985342],
            [-2.641017155601163, 52.328629776029011],
            [-2.638947479257636, 52.328076399183637],
            [-2.637099860030891, 52.32847206635153],
            [-2.636227425769713, 52.328314044757136],
            [-2.636935942409373, 52.327072256960527],
            [-2.636460610633538, 52.326465275157275],
            [-2.637208932178072, 52.325136963505138],
            [-2.636902929197159, 52.324467935232377],
            [-2.635095769927569, 52.32419627049606],
            [-2.633576483253962, 52.324559549723055],
            [-2.632823439749869, 52.324124859197667],
            [-2.632159030145891, 52.324142802303946],
            [-2.632200223123355, 52.323738915752052],
            [-2.631366410649143, 52.323503334720769],
            [-2.631929509480167, 52.323172175523027],
            [-2.630691277377416, 52.322663648929009],
            [-2.631016387988462, 52.322105410286191],
            [-2.630274813082099, 52.321543878435747],
            [-2.630787999400875, 52.321108703000043],
            [-2.629537543215121, 52.320974227662269],
            [-2.629403084969466, 52.320700739084906],
            [-2.627974759405166, 52.320747900934741],
            [-2.627956723096783, 52.321131884259117],
            [-2.627297910828168, 52.321023905643223],
            [-2.627254428131067, 52.321476350559017],
            [-2.626543573223728, 52.321215808445977],
            [-2.625870995521478, 52.321483690960214],
            [-2.625290065649878, 52.321072314024441],
            [-2.624824306450355, 52.321128721400129],
            [-2.625048937693408, 52.321452083728268],
            [-2.623937289557908, 52.321380645703329],
            [-2.623839084827727, 52.320553154634538],
            [-2.624414010665171, 52.319914499120337],
            [-2.623923929702683, 52.319701321465061],
            [-2.623682203399095, 52.319934549125513],
            [-2.623528183209381, 52.319519109880396],
            [-2.624539894138203, 52.318331533784715],
            [-2.626137682823831, 52.317615532481902],
            [-2.625570321110172, 52.31691459578996],
            [-2.626324993424027, 52.31602414743692],
            [-2.625631195847247, 52.31499033965509],
            [-2.626763499674775, 52.314666076612902],
            [-2.628076396363765, 52.313422028623677],
            [-2.625495588735597, 52.312240011739206],
            [-2.62573520025713, 52.311340606188324],
            [-2.624846361163358, 52.311032448968021],
            [-2.623842184306846, 52.309927450302943],
            [-2.622825711253587, 52.310136897415063],
            [-2.622615488606749, 52.309058264804776],
            [-2.623093967212354, 52.308454286088427],
            [-2.622725650696975, 52.308128980296289],
            [-2.621700830158685, 52.307844891445555],
            [-2.620697908467902, 52.307028449581409],
            [-2.619106912370332, 52.307369445825167],
            [-2.618037622686072, 52.306943510808857],
            [-2.618269711839794, 52.306021683345115],
            [-2.619076608529402, 52.305618283462522],
            [-2.62311714039432, 52.306449314451712],
            [-2.626931611033151, 52.306114459424876],
            [-2.629719914987825, 52.306644436984314],
            [-2.631658227678817, 52.305673013660702],
            [-2.632448098177767, 52.305627429663694],
            [-2.63334006719877, 52.306055983909566],
            [-2.633835689164916, 52.307382998793237],
            [-2.636327934145245, 52.308939310566807],
            [-2.63725948838349, 52.308134144471538],
            [-2.638762248804012, 52.307682799444073],
            [-2.64322803334501, 52.3081647130712],
            [-2.64538026666785, 52.306185876706706],
            [-2.647556589572606, 52.30526773165905],
            [-2.652910186809235, 52.305792954587204],
            [-2.654019902495895, 52.305259078038233],
            [-2.656435553977268, 52.305167456304353],
            [-2.658763991732953, 52.304339060964892],
            [-2.658373180781972, 52.304984052669028],
            [-2.65867865795148, 52.305308699523415],
            [-2.659402342526934, 52.305351410079147],
            [-2.661622225125104, 52.304819347931158],
            [-2.661608407271619, 52.304581180369425],
            [-2.663207453603383, 52.303924003028087],
            [-2.662104498603252, 52.302051203859925],
            [-2.661590791208083, 52.3022195065274],
            [-2.661196165839312, 52.302014039340243],
            [-2.661613760489823, 52.301399455036496],
            [-2.661112692813254, 52.301136146497846],
            [-2.661430202770009, 52.300792733647256],
            [-2.661054532817491, 52.30038217903936],
            [-2.659441330055867, 52.300179027332],
            [-2.657209575977, 52.299202529319992],
            [-2.655975080446125, 52.299119490879754],
            [-2.652420524583007, 52.295233763841331],
            [-2.648200366780534, 52.293918341226487],
            [-2.644222715804927, 52.293736930817779],
            [-2.644533322901123, 52.292205970639181],
            [-2.644161076095494, 52.290196852197901],
            [-2.642220774549581, 52.288127945857994],
            [-2.639878945786263, 52.286362362685423],
            [-2.635376970988647, 52.28606392431761],
            [-2.633698715479733, 52.286496392758885],
            [-2.632349835505174, 52.287309164964867],
            [-2.631690934610053, 52.287057365519985],
            [-2.629456724577426, 52.287265290222606],
            [-2.626258674687142, 52.288883487113246],
            [-2.624889881735474, 52.287567355789847],
            [-2.623438335791505, 52.287033809254218],
            [-2.623204116762524, 52.284551900626482],
            [-2.62258663785708, 52.284424798206196],
            [-2.620638267163614, 52.282841960731069],
            [-2.618451227212051, 52.28221512136966],
            [-2.616551020780292, 52.282816629278912],
            [-2.616299138474119, 52.283151488069016],
            [-2.616596426788947, 52.283236243326854],
            [-2.616096646311715, 52.283982357988741],
            [-2.616686237644696, 52.284108740954224],
            [-2.616613389777302, 52.285302145917328],
            [-2.614443452076203, 52.286445349982749],
            [-2.612609243691089, 52.28674707027082],
            [-2.604309692330397, 52.286607344197215],
            [-2.602001602093037, 52.285482753261597],
            [-2.600530844274504, 52.285670951159581],
            [-2.600528129678962, 52.284928359592698],
            [-2.598969989979261, 52.283528382324221],
            [-2.600442003887233, 52.281811832004955],
            [-2.599726803927332, 52.281496309155145],
            [-2.597934067670664, 52.281604298000246],
            [-2.597509449145633, 52.280332510535892],
            [-2.599556320469972, 52.2798348527336],
            [-2.598384375912426, 52.278906698220673],
            [-2.598255993286772, 52.278189915915995],
            [-2.596936633634718, 52.278175018555295],
            [-2.596272646396372, 52.277300912550878],
            [-2.596452038285538, 52.276579875895806],
            [-2.599108338348973, 52.2760728508363],
            [-2.601664030642917, 52.276474308562186],
            [-2.601880637608201, 52.276991949959466],
            [-2.602957486378203, 52.27642005929799],
            [-2.603615772957117, 52.276434675576752],
            [-2.603332074775274, 52.276158322816038],
            [-2.604388401229504, 52.275481336205942],
            [-2.606732829000423, 52.275651813617955],
            [-2.607816496433845, 52.273014748404712],
            [-2.610629303039047, 52.271230918737523],
            [-2.610614886984866, 52.270288798758827],
            [-2.609953455412915, 52.269291586402346],
            [-2.612495652907572, 52.267778821008292],
            [-2.614396863747401, 52.265702942446637],
            [-2.616265588575061, 52.264678181727049],
            [-2.623008103832659, 52.262221662452092],
            [-2.628298492236346, 52.261151626919577],
            [-2.627798898441044, 52.260738927704764],
            [-2.627879065901428, 52.260190984110395],
            [-2.630881646361751, 52.257440082315078],
            [-2.631361355553484, 52.254184776677363],
            [-2.630653576411588, 52.253653629890323],
            [-2.631462074830125, 52.253231251076109],
            [-2.631107609432769, 52.252924775057828],
            [-2.634234797303121, 52.250742211975272],
            [-2.633246947005793, 52.2496749566074],
            [-2.632844979129334, 52.247676738001182],
            [-2.634268020090568, 52.247218676391086],
            [-2.631462494091333, 52.243773296825665],
            [-2.630741076602959, 52.243921002378244],
            [-2.630516700048277, 52.243693843915906],
            [-2.628872807577684, 52.240336588906437],
            [-2.621596875197229, 52.241987130113024],
            [-2.621101238850751, 52.242676610609607],
            [-2.620474168943728, 52.242029899758016],
            [-2.616274372398153, 52.243334845881087],
            [-2.614909950092318, 52.241787514893467],
            [-2.610657662134642, 52.242586372224991],
            [-2.610728039426808, 52.243338508519223],
            [-2.610253916603765, 52.243687992700075],
            [-2.608530922151606, 52.243749035292751],
            [-2.604376265633422, 52.242809309018405],
            [-2.602643915220566, 52.24325870088061],
            [-2.601916631777207, 52.243844993716465],
            [-2.599844882377532, 52.243785417933545],
            [-2.596470751572335, 52.244244851393645],
            [-2.592834592249326, 52.245269205399069],
            [-2.589666136815671, 52.245789444411102],
            [-2.588680814289876, 52.246261866616386],
            [-2.587306101889323, 52.247750339916358],
            [-2.585441214891197, 52.24828464782145],
            [-2.5806574520116, 52.248292101133963],
            [-2.576143270481908, 52.247739739209287],
            [-2.574575309545556, 52.249611998379294],
            [-2.574208221135302, 52.252718177569221],
            [-2.569987485440009, 52.252802471367623],
            [-2.56916519241008, 52.250819557823945],
            [-2.567373667173094, 52.250127831395595],
            [-2.55965195820346, 52.251932220760715],
            [-2.554594426962786, 52.251983968257434],
            [-2.553182449244661, 52.252467087484568],
            [-2.552703438855599, 52.252349759513983],
            [-2.552503956216683, 52.251268248085879],
            [-2.54795221391961, 52.24968468315187],
            [-2.541090574998552, 52.253007734399482],
            [-2.539715668200452, 52.253059881779045],
            [-2.538980497680913, 52.252593942735494],
            [-2.539018694452936, 52.251515818553145],
            [-2.535816134855653, 52.250753632588875],
            [-2.534558617573266, 52.251426424018646],
            [-2.534130566418443, 52.251278222544435],
            [-2.531675802567732, 52.253263600795393],
            [-2.528996736194078, 52.251684339810815],
            [-2.52941868166218, 52.250706089638285],
            [-2.528870145818524, 52.24966296521675],
            [-2.527435042055244, 52.249201887362979],
            [-2.525822281197502, 52.248083486080134],
            [-2.52514125197109, 52.248833621676802],
            [-2.523397072866215, 52.249361016401828],
            [-2.523180147223726, 52.249971527789796],
            [-2.522806981599301, 52.250001950989912],
            [-2.522443342025491, 52.251715334103743],
            [-2.521000576381175, 52.252100208787873],
            [-2.515854699441309, 52.255904176505688],
            [-2.513640500349659, 52.255725935700788],
            [-2.510181140513321, 52.256445788954515],
            [-2.506765161773322, 52.256611546414717],
            [-2.501362550221798, 52.257423080447083],
            [-2.495778969663479, 52.256870387164788],
            [-2.494641204889428, 52.26139552477504],
            [-2.493873951275783, 52.261835670492061],
            [-2.493357800734774, 52.262986799298943],
            [-2.493600801294823, 52.26402147411946],
            [-2.496304329945802, 52.266656001593098],
            [-2.497811685425638, 52.267528011992816],
            [-2.498606983060191, 52.267492289327869],
            [-2.499338745589175, 52.26858332324263],
            [-2.500982708525447, 52.269212874167323],
            [-2.504069841352516, 52.272265443820103],
            [-2.504037962108566, 52.27423446915752],
            [-2.504469964966824, 52.274461877642615],
            [-2.504232927154834, 52.275141662687368],
            [-2.503564620459755, 52.275531101343276],
            [-2.503115734082994, 52.277436274115757],
            [-2.500776990128606, 52.277742002850992],
            [-2.497234640945595, 52.277373990298202],
            [-2.495670249846273, 52.277592742796436],
            [-2.493702995043898, 52.27835887465978],
            [-2.49200936757517, 52.278162764892116],
            [-2.489170092107162, 52.280941795262812],
            [-2.488323049327708, 52.281359757595276],
            [-2.487035654780252, 52.281323721248143],
            [-2.486423031814847, 52.281867466187542],
            [-2.485019139722135, 52.28230567889036],
            [-2.483608134425843, 52.283573712314436],
            [-2.483385106559104, 52.284362179532195],
            [-2.475963442029582, 52.285624016085031],
            [-2.473321761867455, 52.285761402566315],
            [-2.47221442687631, 52.285574343757233],
            [-2.467458471269745, 52.283313326703805],
            [-2.465311752636651, 52.284090482676348],
            [-2.463578673489664, 52.285178839024908],
            [-2.455839251240125, 52.284830489632114],
            [-2.451405738027493, 52.285069589847431],
            [-2.451621801401047, 52.283540407631911],
            [-2.451265373363751, 52.282798270694009],
            [-2.448156395853553, 52.280634456585808],
            [-2.44410313243868, 52.278551434172321],
            [-2.441765101936881, 52.278385796264601],
            [-2.440241344068844, 52.278742112957474],
            [-2.440191698590233, 52.277430608468293],
            [-2.43845143890932, 52.277085564370971],
            [-2.438183727116407, 52.276245962300735],
            [-2.434105982392627, 52.275767462449757],
            [-2.43438753378258, 52.275333092302539],
            [-2.433191467098889, 52.273819020729356],
            [-2.432158197264972, 52.273062226343768],
            [-2.430623714089087, 52.272754971718619],
            [-2.429840152903965, 52.271444339656668],
            [-2.427998645679455, 52.270596950301744],
            [-2.430769591417613, 52.270418748836576],
            [-2.434340157133181, 52.268990581224401],
            [-2.437068534061754, 52.268817782587739],
            [-2.436930151718935, 52.268157504505112],
            [-2.437879429530565, 52.266640910246004],
            [-2.440479993461098, 52.266303084203393],
            [-2.441673806148839, 52.26537531291482],
            [-2.446614814069505, 52.264064802429232],
            [-2.446517163374548, 52.263825129429549],
            [-2.449833760500729, 52.263318962739071],
            [-2.452902306464383, 52.263209230852475],
            [-2.455128348890797, 52.262672033245934],
            [-2.456929894014455, 52.261182560397032],
            [-2.459766829560485, 52.260652797289517],
            [-2.461836065282712, 52.258722576357798],
            [-2.465089644062342, 52.257707371406561],
            [-2.467683221406622, 52.255939502525727],
            [-2.468101645047942, 52.256025050060671],
            [-2.472592805682593, 52.252808371465555],
            [-2.473651553359629, 52.250712063969495],
            [-2.474704345908604, 52.241346136810897],
            [-2.473302926472823, 52.241219608057371],
            [-2.471221727031652, 52.240515898798591],
            [-2.466505091742231, 52.237628944736535],
            [-2.464860105164923, 52.234361132033719],
            [-2.462216686090752, 52.235027817810078],
            [-2.456013769038908, 52.235728934130826],
            [-2.450904350103763, 52.23670155518225],
            [-2.450533374934214, 52.236219288913155],
            [-2.445189993499114, 52.236365438875474],
            [-2.444085770554835, 52.235621599344498],
            [-2.441800837512302, 52.236004178754975],
            [-2.437146785238835, 52.237602027185936],
            [-2.434621586315926, 52.236941572565634],
            [-2.433234233482057, 52.237030286059834],
            [-2.431631048048562, 52.23660821968214],
            [-2.430277126170036, 52.236670703974845],
            [-2.426294569685608, 52.236110675806451],
            [-2.423067741403065, 52.237247888765943],
            [-2.420029410880593, 52.235258383319426],
            [-2.416732337777031, 52.233754295958292],
            [-2.414210886522402, 52.231710668945958],
            [-2.411345899990683, 52.231441092516455],
            [-2.409760600522413, 52.231008777482167],
            [-2.409374973028064, 52.230630721613871],
            [-2.406904791004417, 52.230774123995694],
            [-2.40531319982644, 52.231250699968811],
            [-2.404161431602718, 52.230987636957515],
            [-2.402094571615276, 52.231047743511198],
            [-2.399405981914781, 52.23182106065785],
            [-2.399045865891489, 52.231503120459344],
            [-2.396481716976084, 52.231462319382643],
            [-2.395243493599117, 52.231021450208239],
            [-2.39329000218231, 52.230988421635224],
            [-2.392070252538159, 52.23031280835955],
            [-2.390294479021219, 52.230339375063402],
            [-2.38941408911764, 52.229954798791262],
            [-2.388928458988312, 52.229337861933779],
            [-2.389399875107792, 52.227327852428644],
            [-2.387432268103558, 52.226327407010658],
            [-2.385913586493412, 52.226438472094266],
            [-2.385561590562565, 52.225858843515788],
            [-2.38440246188449, 52.225222510338199],
            [-2.384249052064908, 52.224580196665251],
            [-2.385303887843362, 52.222980959538894],
            [-2.38645816178968, 52.222221090444954],
            [-2.388055170142199, 52.221876009908172],
            [-2.389587999157798, 52.222061553606167],
            [-2.393774897777242, 52.224236813706071],
            [-2.396511207851721, 52.224751787741489],
            [-2.397488560314086, 52.224523738248692],
            [-2.398359170986415, 52.223820448806336],
            [-2.398223178771365, 52.223019862924907],
            [-2.397336999419472, 52.22230631605386],
            [-2.397650386629996, 52.221664243955892],
            [-2.40073053372399, 52.221359833455729],
            [-2.40297093237347, 52.221761264366002],
            [-2.40404355879976, 52.221235252262396],
            [-2.403561545318318, 52.219576373482589],
            [-2.401555331450164, 52.217376973060496],
            [-2.401581303310365, 52.215389103541696],
            [-2.399855037366555, 52.214505816057915],
            [-2.397012381983059, 52.212339737263882],
            [-2.392896562287498, 52.210315391243242],
            [-2.392444871209751, 52.209379193878327],
            [-2.392680953006278, 52.208602534362306],
            [-2.393478871208222, 52.207648687790375],
            [-2.395197399012442, 52.206880553339388],
            [-2.396096878998957, 52.205673717186421],
            [-2.397323950766262, 52.205763090079842],
            [-2.398041927911206, 52.206523057615847],
            [-2.399172286194108, 52.206921109453695],
            [-2.402661468088259, 52.207137605337103],
            [-2.402219000730431, 52.205297874643051],
            [-2.400386189568105, 52.202287820070012],
            [-2.400481908687206, 52.201704914667218],
            [-2.402005258225521, 52.201884035753309],
            [-2.40196944263885, 52.201320457296944],
            [-2.403221544726603, 52.201762108700621],
            [-2.403316424918531, 52.202383023584666],
            [-2.404154479085797, 52.202180566842706],
            [-2.404925221101502, 52.202461121845353],
            [-2.404907352893399, 52.202748877225396],
            [-2.406930683546936, 52.203193233134257],
            [-2.407865656134214, 52.202709014871623],
            [-2.407627248968658, 52.202240538199739],
            [-2.408545467100905, 52.201528015143857],
            [-2.408321702097882, 52.200582096577932],
            [-2.409063801992602, 52.199497075448541],
            [-2.408600155726705, 52.198877443035855],
            [-2.408902524909882, 52.19866422050508],
            [-2.408713050008283, 52.196352534275483],
            [-2.410344752154285, 52.195446024764323],
            [-2.41110303390591, 52.193275785620934],
            [-2.413739716024152, 52.191910803549391],
            [-2.414638111699816, 52.190202158056188],
            [-2.415988893863006, 52.189639093098968],
            [-2.417695559920111, 52.189437068159911],
            [-2.419148853321134, 52.187960172561283],
            [-2.417898265153401, 52.186563896607325],
            [-2.418130695991313, 52.185925648539339],
            [-2.416112632038829, 52.185190174557761],
            [-2.41507069320436, 52.183250107321818],
            [-2.416651792449057, 52.182158482108619],
            [-2.418957873697398, 52.181651339703478],
            [-2.420305085407486, 52.180432831053345],
            [-2.423248036192747, 52.179857702100385],
            [-2.425464256711591, 52.177005137941237],
            [-2.425069659875468, 52.176877997221347],
            [-2.424991335070501, 52.17617881990995],
            [-2.424459479722208, 52.176225688145564],
            [-2.42383031583773, 52.175728080447691],
            [-2.423562336118495, 52.174919899732245],
            [-2.422800144375637, 52.174732037003622],
            [-2.422903708712741, 52.173625835701799],
            [-2.422350319786688, 52.173557693482458],
            [-2.422013278177966, 52.17240092539781],
            [-2.426294390224303, 52.172518583730771],
            [-2.42801899439155, 52.172125744605566],
            [-2.429069335839003, 52.172325112108545],
            [-2.430665951445418, 52.171855381686903],
            [-2.432058093027967, 52.172167652892917],
            [-2.432998351002917, 52.172021254807007],
            [-2.435425835419934, 52.170991894011358],
            [-2.434623314197672, 52.169815301914312],
            [-2.435177834567582, 52.168204856359608],
            [-2.433541265786604, 52.167254296396543],
            [-2.432886165973784, 52.167245015830829],
            [-2.431722425711881, 52.166044555755995],
            [-2.431799271003027, 52.165223441461478],
            [-2.431075132971071, 52.165177542646489],
            [-2.429094897740444, 52.163588052953095],
            [-2.428170088148025, 52.163346873521348],
            [-2.427554059890987, 52.162527376982574],
            [-2.427848900248195, 52.162478657676026],
            [-2.426466464080675, 52.161465042125471],
            [-2.4262933852236, 52.160946016745868],
            [-2.427269405502555, 52.1608930356984],
            [-2.427281935440386, 52.158996891753212],
            [-2.42699515823612, 52.1583587054063],
            [-2.424920831422497, 52.157125509555136],
            [-2.424201357943117, 52.156018671194317],
            [-2.423220270449189, 52.155526820027248],
            [-2.42510140658856, 52.154293745151037],
            [-2.424322862132586, 52.153118792109737],
            [-2.422312888821952, 52.151393536587108],
            [-2.421319696317354, 52.149607077458697],
            [-2.417674425042242, 52.147545935784272],
            [-2.415086316137014, 52.145198660637625],
            [-2.41239750891411, 52.145365432640226],
            [-2.409648312685004, 52.145960302264875],
            [-2.406798357710789, 52.145930613931831],
            [-2.401859232687829, 52.1480756127307],
            [-2.400191788812645, 52.148245812087197],
            [-2.396960164770814, 52.147877339385623],
            [-2.395341572523654, 52.146964847032635],
            [-2.393974608609007, 52.14673746841909],
            [-2.392383710958113, 52.147632836518476],
            [-2.391745361355878, 52.149164246826302],
            [-2.390425583264657, 52.149992156819778],
            [-2.389888692086469, 52.150936139754393],
            [-2.388628844126816, 52.150600458709974],
            [-2.38805819456628, 52.152037224481752],
            [-2.386363228955036, 52.152596607661181],
            [-2.384491058299544, 52.153798466787421],
            [-2.381293631669526, 52.154213425488678],
            [-2.379323565501362, 52.155099951929884],
            [-2.3779296272121, 52.154641418304919],
            [-2.376932681938059, 52.153592721313771],
            [-2.375424973621553, 52.152806368325294],
            [-2.372674155709794, 52.152620902484912],
            [-2.371586634584796, 52.152083107307924],
            [-2.369697015871763, 52.152107030041911],
            [-2.366529311024955, 52.151516356867731],
            [-2.365232998929466, 52.150991738910115],
            [-2.362527370057902, 52.151132251920465],
            [-2.358724638666975, 52.151884704013575],
            [-2.356456668499814, 52.148458110226827],
            [-2.351404811963663, 52.144612754901637],
            [-2.35177481136038, 52.14446330768876],
            [-2.351837844457481, 52.142820552457657],
            [-2.351210544427939, 52.141839759939742],
            [-2.350175788211466, 52.140883550638698],
            [-2.349688845673843, 52.141210453200898],
            [-2.349568468826962, 52.13964735899291],
            [-2.348550047562047, 52.138152554443153],
            [-2.348366212894459, 52.136689440738081],
            [-2.344611670907292, 52.13258012072771],
            [-2.350539201475101, 52.129111172173729],
            [-2.35277386514014, 52.128506633024287],
            [-2.354001296515085, 52.128735807197891],
            [-2.355188500837596, 52.128497581849729],
            [-2.356093049136726, 52.127071649527906],
            [-2.356207042214957, 52.124149378357018],
            [-2.355396463211618, 52.123059474386167],
            [-2.35560587760521, 52.12184421954997],
            [-2.354571109233922, 52.120104069987441],
            [-2.352753716013437, 52.115049640193853],
            [-2.352673564930083, 52.113591611288889],
            [-2.353803174291808, 52.113506410728597],
            [-2.35366155121018, 52.112956613506107],
            [-2.351591616377372, 52.109714517372218],
            [-2.349993413553474, 52.106077196058585],
            [-2.350133042031174, 52.104145606017148],
            [-2.350606664450268, 52.103661403648523],
            [-2.352151327369715, 52.103475187216276],
            [-2.351818351579186, 52.102720973529813],
            [-2.351389801263211, 52.102621557995832],
            [-2.35150410410322, 52.10193343780405],
            [-2.350441461197306, 52.100096231723263],
            [-2.349589527355156, 52.099430762545559],
            [-2.349121918616532, 52.095635425194821],
            [-2.342991747720881, 52.095345055227469],
            [-2.341109878858376, 52.095689464311427],
            [-2.34046264869835, 52.095997915553042],
            [-2.34078408054282, 52.096418644608825],
            [-2.340374542289256, 52.096504340070346],
            [-2.3383023779527, 52.09543863114753],
            [-2.338505762739538, 52.092419007497433],
            [-2.337966895378367, 52.089874419036732],
            [-2.338508016798592, 52.087898530525621],
            [-2.338092741936389, 52.086444145302302],
            [-2.33873293846097, 52.082526898761699],
            [-2.338477090342768, 52.080537113862647],
            [-2.34050766130378, 52.078530850599137],
            [-2.340054197008052, 52.075888920152522],
            [-2.339198986063807, 52.074517619860785],
            [-2.339915132570242, 52.071606190620614],
            [-2.339264589666458, 52.06970205406401],
            [-2.339429299048137, 52.068914000682426],
            [-2.341467236005855, 52.063933593918236],
            [-2.343217524166178, 52.062583507086494],
            [-2.344380467344831, 52.061119133527448],
            [-2.347458910351704, 52.061596485124205],
            [-2.349690580871431, 52.057861460437913],
            [-2.350857836266059, 52.057361705067244],
            [-2.351524379234941, 52.05643547884732],
            [-2.35017478692522, 52.05469081821019],
            [-2.350085439428808, 52.05370930472219],
            [-2.347396643562576, 52.053012400037552],
            [-2.346954101584963, 52.049916424102506],
            [-2.347225608817575, 52.049387872251387],
            [-2.348598378716522, 52.048901018143169],
            [-2.348955983618311, 52.048009883671853],
            [-2.348240739216884, 52.041166508187068],
            [-2.348517555185793, 52.037956917869991],
            [-2.349921798548333, 52.036507052239607],
            [-2.347987714405896, 52.035810612110161],
            [-2.347536520205642, 52.033272976443911],
            [-2.348166126709881, 52.031944990500705],
            [-2.350270733685395, 52.03079513301666],
            [-2.35065185643066, 52.029951571129686],
            [-2.352053346258316, 52.024853262356864],
            [-2.35082641678791, 52.022461802728117],
            [-2.351381689505897, 52.021349795203392],
            [-2.35262420718555, 52.020351708483268],
            [-2.35397423477164, 52.020057257433706],
            [-2.354412740317217, 52.019672035090117],
            [-2.356251831184433, 52.019676374164597],
            [-2.35702629814274, 52.019211006728725],
            [-2.361475627773792, 52.018173388134088],
            [-2.363426123115294, 52.018086466875005],
            [-2.36520037222261, 52.017473204509564],
            [-2.368675668243066, 52.017285255867236],
            [-2.373269360795915, 52.016490387185023],
            [-2.375335711188945, 52.015981236674051],
            [-2.379955618430977, 52.013839213467833],
            [-2.386984562487979, 52.012157530492694],
            [-2.392893864913699, 52.012949820597896],
            [-2.394076909468134, 52.011781567565279],
            [-2.394703976926389, 52.009859047275235],
            [-2.394883441702201, 52.006745853820753],
            [-2.395566535518928, 52.005736598134611],
            [-2.39490020885013, 52.004679726309625],
            [-2.395103013514208, 52.003723330282106],
            [-2.39677951043133, 52.001065422723173],
            [-2.399425233145152, 51.999315867052104],
            [-2.399019695436807, 51.99613721412743],
            [-2.402975433758415, 51.99612643618218],
            [-2.413197199741761, 51.994081566350332],
            [-2.416230148997015, 51.994153596272326],
            [-2.41807218267882, 51.994584918698578],
            [-2.421013806572419, 51.994232785891008],
            [-2.422822177780684, 51.994332395393783],
            [-2.42713984474141, 51.995792191920437],
            [-2.432405642840609, 51.996006727395304],
            [-2.435681057252021, 51.996582646653309],
            [-2.437294655862565, 51.997158366077393],
            [-2.437110669245668, 51.997559138234251],
            [-2.435643187312342, 51.997932299980882],
            [-2.435006371162182, 51.998952408720733],
            [-2.435719860313936, 51.999377730202291],
            [-2.435912431567964, 52.000457706262949],
            [-2.436593406715716, 52.001280534154475],
            [-2.435784888358433, 52.001573032075179],
            [-2.435949075014807, 52.002429243011733],
            [-2.435291132647196, 52.002329182960409],
            [-2.435381072986271, 52.002890772405529],
            [-2.434879133553206, 52.003255853959395],
            [-2.435538233925646, 52.003623836414249],
            [-2.435659233516039, 52.005582473026436],
            [-2.43491705889181, 52.005664335646237],
            [-2.434994143478133, 52.006252045727145],
            [-2.433824148340201, 52.006837164980873],
            [-2.434070318351589, 52.007284895818053],
            [-2.433635927964471, 52.007861904211168],
            [-2.434226974892739, 52.008568196377411],
            [-2.434105303088617, 52.009544140594741],
            [-2.43459503901842, 52.009715855168984],
            [-2.434650431367526, 52.010169683109204],
            [-2.435585710920749, 52.010461122888984],
            [-2.433725082480708, 52.01225894702187],
            [-2.434396817844244, 52.012564852845962],
            [-2.434545117751375, 52.013133419223678],
            [-2.435163624647675, 52.013057410811641],
            [-2.436116629556841, 52.014565227298974],
            [-2.437029503970999, 52.014939453285521],
            [-2.437824938058789, 52.014327825384896],
            [-2.440581519753618, 52.014453302773852],
            [-2.443452606128727, 52.015105141613475],
            [-2.444080941392231, 52.015725831409441],
            [-2.44602014581703, 52.015262671233359],
            [-2.447737221025464, 52.015759635540199],
            [-2.456089837373334, 52.014789843370117],
            [-2.460273118266528, 52.015635776886839],
            [-2.461982030711785, 52.014475572155149],
            [-2.463543271917783, 52.01440200555917],
            [-2.4638514934827, 52.014758623289929],
            [-2.4632601269518, 52.015343551531124],
            [-2.463506077442148, 52.015596122503773],
            [-2.462482515475035, 52.016238489854011],
            [-2.462938513939986, 52.017076432947171],
            [-2.46250411543616, 52.017343366814984],
            [-2.463532591342878, 52.018160176896878],
            [-2.463245091309803, 52.018541616488868],
            [-2.463634178441325, 52.018824192375966],
            [-2.462572617981803, 52.020310040519291],
            [-2.462982733875139, 52.021216492728364],
            [-2.462555515589295, 52.021900568241044],
            [-2.463496493927503, 52.02227897419602],
            [-2.464080895800726, 52.023264753683719],
            [-2.466206052750679, 52.023713987192451],
            [-2.468488919640965, 52.023770559241953],
            [-2.47075439141335, 52.023280520160171],
            [-2.47118830604453, 52.023798448944021],
            [-2.473865654778637, 52.023809283098871],
            [-2.478933825393734, 52.022521085540191],
            [-2.477417497835751, 52.01985789324867],
            [-2.480367019358501, 52.016051804310734],
            [-2.482861785370757, 52.01390000407779],
            [-2.483393471568158, 52.01415675609833],
            [-2.484623165536145, 52.012531568009635],
            [-2.487132451282116, 52.012289251991419],
            [-2.487634983666985, 52.011472609526685],
            [-2.489563431726688, 52.011282993885608],
            [-2.49028484419931, 52.010635354919671],
            [-2.491253505439444, 52.010482070872072],
            [-2.492112328080935, 52.008286537385587],
            [-2.491571181663119, 52.006500535279919],
            [-2.490874592119586, 52.005947812312726],
            [-2.489387991322217, 52.005888368239383],
            [-2.489770289310649, 52.005144141421198],
            [-2.487287920030108, 52.003401242158837],
            [-2.485497787443764, 52.000323904792992],
            [-2.479653739602532, 52.000184227059982],
            [-2.478844678958573, 52.000397904485439],
            [-2.477647513458515, 52.000142035267196],
            [-2.476938860397923, 51.999251225722645],
            [-2.47514480443921, 51.998195770910066],
            [-2.475417067937672, 51.997497886954044],
            [-2.470980924694285, 51.994769036504103],
            [-2.472208933515514, 51.994374816643777],
            [-2.473430227285287, 51.993484320012506],
            [-2.4740636818618, 51.993476376392863],
            [-2.474032467999647, 51.993141146055208],
            [-2.474872175799912, 51.992806002111678],
            [-2.477117505213275, 51.992766354881574],
            [-2.478922509765725, 51.991998405549083],
            [-2.480028148522293, 51.991342070656884],
            [-2.480249406415885, 51.990515814289068],
            [-2.481643763643071, 51.989492360785846],
            [-2.482604280837264, 51.988215331467309],
            [-2.486769883579894, 51.988016566545085],
            [-2.488638790434134, 51.987399248179024],
            [-2.489686249515057, 51.986238679665817],
            [-2.49032325686621, 51.983500128804884],
            [-2.491150630872516, 51.983395982685067],
            [-2.49130321157745, 51.98190467132715],
            [-2.493128990593927, 51.981763973076582],
            [-2.494913678633193, 51.981066888552149],
            [-2.494904082968128, 51.98019661904371],
            [-2.495572032871328, 51.979901607696426],
            [-2.49635941849073, 51.97750493716174],
            [-2.496501175486415, 51.976105369186541],
            [-2.495131466432927, 51.971111346463147],
            [-2.493917928482418, 51.970230849385295],
            [-2.493013528188104, 51.968600109852581],
            [-2.49243690745509, 51.966723441674716],
            [-2.49294580433315, 51.964684889237766],
            [-2.49224535551786, 51.964120499565574],
            [-2.493382389592582, 51.963884674303735],
            [-2.495762075936772, 51.964414124381911],
            [-2.497261441949237, 51.964172239469946],
            [-2.49756717774764, 51.963786139812051],
            [-2.499030511295057, 51.963709815923046],
            [-2.499946212604641, 51.96255330507006],
            [-2.499873701829731, 51.961530456122368],
            [-2.500906840154006, 51.960600906593442],
            [-2.496624984225051, 51.960316960361197],
            [-2.496614400225224, 51.959622912456794],
            [-2.497520459486594, 51.958780240586918],
            [-2.493116994850948, 51.957343156483518],
            [-2.490187907458179, 51.954971022117576],
            [-2.487520781680602, 51.956865681916341],
            [-2.486107848385711, 51.957411875807644],
            [-2.483884553852467, 51.956765606059875],
            [-2.482439310447335, 51.9556620701089],
            [-2.481096470316257, 51.956097329284688],
            [-2.479238513105996, 51.955719202952807],
            [-2.477298581298454, 51.956251252479959],
            [-2.47575972725705, 51.956322217549449],
            [-2.475185545355887, 51.955693380691194],
            [-2.474598102824516, 51.955736209939843],
            [-2.473728995682581, 51.956530006029915],
            [-2.472524526849126, 51.955327379174008],
            [-2.472224556586852, 51.955446363170942],
            [-2.47052661550401, 51.953108353904902],
            [-2.467819615855489, 51.951941346287605],
            [-2.465487434711934, 51.951688061892369],
            [-2.466027940567752, 51.950350782871659],
            [-2.464480965413868, 51.948492198304599],
            [-2.465458185408885, 51.946757599336735],
            [-2.465273855537418, 51.945535573035244],
            [-2.464862436813338, 51.944580572696722],
            [-2.463729542615299, 51.943633810764169],
            [-2.464942489517466, 51.942756012708763],
            [-2.464992206207163, 51.942218163244505],
            [-2.46546477546118, 51.942196514986541],
            [-2.464779685904832, 51.941783845539639],
            [-2.465042760321858, 51.941339557010984],
            [-2.464650434113048, 51.941242207473735],
            [-2.464270615251046, 51.940243024468671],
            [-2.464629949872351, 51.940104945112665],
            [-2.464032042254719, 51.939960753562971],
            [-2.464381161589541, 51.939678861529089],
            [-2.463384772350701, 51.939261118415807],
            [-2.463584111136811, 51.938560844470942],
            [-2.464099060008395, 51.938702668276164],
            [-2.464236792765763, 51.938376655860857],
            [-2.465432927421564, 51.937997011735128],
            [-2.46583116407853, 51.937263579755054],
            [-2.466958690177478, 51.937564799818873],
            [-2.467878893211856, 51.937371437197342],
            [-2.466205457023597, 51.931417135453387],
            [-2.466043156377175, 51.927966182319771],
            [-2.461294034383512, 51.925045780684954],
            [-2.459926510464634, 51.923424684491877],
            [-2.455022533276881, 51.9193394155551],
            [-2.452225371414569, 51.919229723182838],
            [-2.45157780697236, 51.918742207402985],
            [-2.44932491178196, 51.919112267139667],
            [-2.448258054559945, 51.918712646312834],
            [-2.447689650854501, 51.915655212038381],
            [-2.446449590524691, 51.915080012309147],
            [-2.444475370637874, 51.913769423581982],
            [-2.44326325170214, 51.912479308752751],
            [-2.443131632392064, 51.911957433757834],
            [-2.444431915962854, 51.909811796290512],
            [-2.442590661078101, 51.908984383074483],
            [-2.443040864228709, 51.908497179839074],
            [-2.4417545768047, 51.907184850652826],
            [-2.442898360476995, 51.906720215577778],
            [-2.442935168607946, 51.906177026528319],
            [-2.444056829238084, 51.905831143282178],
            [-2.442468855270261, 51.905023450501552],
            [-2.442207295233144, 51.903995874631121],
            [-2.440154193367867, 51.902433762971597],
            [-2.440505490358362, 51.902091692513658],
            [-2.442379918238721, 51.901890454273286],
            [-2.443785309391653, 51.900849407542673],
            [-2.445388342188465, 51.90054754758583],
            [-2.444196047683397, 51.899759956884495],
            [-2.444302500860733, 51.898912610835055],
            [-2.44334761640113, 51.898761570181961],
            [-2.443171550442291, 51.898444854433414],
            [-2.440835283299497, 51.898515671021542],
            [-2.439314584099129, 51.899505865476947],
            [-2.440188436497372, 51.897761058644534],
            [-2.439357855808589, 51.897387444800003],
            [-2.439655543193465, 51.897065356791451],
            [-2.443668476698405, 51.895778972659528],
            [-2.446009338709396, 51.893986320941991],
            [-2.448920940762005, 51.894801509801383],
            [-2.449829510638738, 51.894396144331552],
            [-2.456161279078777, 51.893407931274844],
            [-2.462522503323494, 51.892909266740915],
            [-2.465213059008962, 51.892983170458244],
            [-2.467818576555791, 51.892004513004025],
            [-2.468649979612213, 51.890768546433563],
            [-2.471438027992358, 51.889201073509398],
            [-2.473738937355561, 51.887338795942341],
            [-2.47340769704977, 51.885545537799949],
            [-2.477877863260006, 51.884953837669407],
            [-2.480012658590113, 51.885817266006562],
            [-2.480733696063427, 51.885717218867626],
            [-2.482393202020356, 51.885877655722403],
            [-2.485043233331088, 51.884922707478957],
            [-2.486727730301022, 51.884708057618617],
            [-2.487794094419402, 51.880409561286825],
            [-2.488812264410952, 51.880524014564664],
            [-2.493860609899058, 51.879994935190886],
            [-2.494655295199372, 51.884231729673296],
            [-2.498187024196137, 51.881978974665294],
            [-2.500253055898567, 51.881730146621216],
            [-2.501178349457876, 51.881978853233925],
            [-2.500696314233475, 51.884358114787929],
            [-2.501946484725767, 51.886245381487178],
            [-2.503831415995958, 51.886546610928328],
            [-2.505430556067476, 51.886108184261353],
            [-2.506684815433432, 51.885366428684954],
            [-2.50786039285094, 51.885252567973659],
            [-2.50825704485751, 51.884866940839174],
            [-2.508623592072392, 51.885161158715157],
            [-2.513273309006514, 51.880097031107738],
            [-2.517942289318393, 51.873387280546368],
            [-2.519345428500126, 51.870866316169455],
            [-2.520111912445008, 51.868062237865402],
            [-2.521848798524646, 51.866169133487844],
            [-2.522232476234412, 51.864688412576797],
            [-2.522565041755684, 51.864813708197438],
            [-2.525178096760574, 51.863481290902008],
            [-2.527301584818219, 51.862890970165537],
            [-2.531120837472983, 51.86301314307952],
            [-2.531622912293391, 51.860764024083544],
            [-2.5340826246594, 51.860380651044643],
            [-2.543998806862874, 51.860236295389086],
            [-2.546704915365291, 51.860783885339465],
            [-2.548275284811508, 51.8620290210864],
            [-2.550087888294501, 51.862767708586233],
            [-2.553795650829119, 51.863148607878436],
            [-2.556396434188775, 51.86290436363425],
            [-2.55877835628447, 51.863867694867778],
            [-2.561812807382649, 51.864277612947433],
            [-2.563513814649011, 51.86384779628515],
            [-2.563999125392573, 51.863404910470372],
            [-2.568133625773119, 51.8626027913621],
            [-2.569346970531754, 51.863008711583547],
            [-2.573068076118773, 51.862482645318934],
            [-2.576098091469968, 51.862542466348074],
            [-2.577701528259746, 51.861426907594613],
            [-2.58063937527452, 51.861664187301798],
            [-2.584910232690242, 51.860214349927631],
            [-2.585217206216248, 51.859923311867256],
            [-2.58310134340665, 51.858227326536664],
            [-2.578804131948114, 51.853202793061783],
            [-2.578773759830747, 51.851967575195722],
            [-2.579230699079964, 51.851371917259229],
            [-2.582108687530857, 51.849721337372955],
            [-2.583035797521241, 51.849564795644781],
            [-2.586465988473238, 51.849874112294032],
            [-2.58894449709138, 51.851344346512363],
            [-2.591616818955154, 51.854184685939565],
            [-2.593063244130492, 51.855202377537502],
            [-2.596109508837489, 51.856196667589217],
            [-2.598366827659563, 51.856486402746548],
            [-2.601214842026438, 51.856209338494793],
            [-2.603991029634737, 51.854908495360874],
            [-2.60469353550266, 51.854458030576211],
            [-2.606398691334889, 51.852018972272319],
            [-2.609432084385367, 51.846790282382521],
            [-2.615396353278192, 51.84546360867332],
            [-2.621591407170803, 51.843318124526157],
            [-2.6244647014145, 51.839484395454306],
            [-2.625464701273788, 51.838957598338276],
            [-2.628814201798725, 51.839137536536306],
            [-2.629685520101617, 51.838754349513096],
            [-2.630221449226594, 51.838883646913942],
            [-2.632926500145377, 51.840303192549335],
            [-2.635723219894337, 51.842859550358604],
            [-2.636707707194825, 51.843088890412965],
            [-2.635640777905709, 51.841129215610387],
            [-2.635619173316758, 51.840419937049823],
            [-2.636304144023366, 51.839484757357312],
            [-2.638381357497783, 51.837549408162992],
            [-2.640472849575225, 51.83622353862507],
            [-2.641045605844223, 51.83659534525534],
            [-2.64341243696206, 51.834638545791812],
            [-2.647237797371169, 51.827841865914088],
            [-2.648380822954326, 51.826802491062928],
            [-2.650398073280797, 51.826118814782554],
            [-2.653874130914213, 51.8260788540505],
            [-2.656854494682106, 51.826875035986305],
            [-2.657910760776317, 51.828162949022307],
            [-2.657607111388606, 51.831523720852061],
            [-2.658191748302026, 51.832704576227506],
            [-2.660139250311436, 51.833693467224649],
            [-2.666194741648612, 51.835617572181555],
            [-2.671526033557503, 51.836241796053685],
            [-2.676817722437649, 51.836344523113937],
            [-2.676841097407905, 51.836741794869418],
            [-2.678268109691683, 51.836956550868258],
            [-2.679593389277383, 51.837546806188051],
            [-2.682612587306709, 51.837759489194951],
            [-2.68285961979162, 51.838450368987743],
            [-2.686768597268414, 51.836373137252423],
            [-2.686196125595352, 51.836044708065117],
            [-2.686999574797354, 51.833050475505644],
            [-2.693362760217916, 51.833867278524579],
            [-2.693057963701521, 51.835849815442721],
            [-2.693421580849741, 51.837695342642846],
            [-2.695524081917486, 51.842084066298121],
            [-2.697333909211375, 51.844792253390075],
            [-2.699605548771312, 51.843472354512109],
            [-2.703393793838095, 51.844565549641352],
            [-2.70510975648044, 51.845689948535252],
            [-2.707141141345284, 51.843037964835666],
            [-2.706734744268246, 51.839704710355328],
            [-2.707245185946824, 51.839396842925261],
            [-2.708749212786178, 51.839510065934277],
            [-2.710219758431642, 51.839063326201007],
            [-2.712658265988427, 51.839528697150712],
            [-2.71281333559452, 51.839789398342347],
            [-2.715125371706006, 51.840238401751243],
            [-2.716098400120154, 51.840729688123758],
            [-2.718596851155135, 51.844493406511106],
            [-2.718197560225909, 51.845490261431031],
            [-2.718586902145719, 51.84687610825646],
            [-2.717733787938699, 51.847544861202316],
            [-2.719602199142316, 51.848913575496702],
            [-2.725779788769279, 51.843793840689457],
            [-2.727577395718674, 51.840136837713004],
            [-2.729752865748483, 51.840208772218951],
            [-2.730441959921477, 51.840904002073351],
            [-2.731233281695412, 51.84091976334566],
            [-2.731539854596607, 51.841386293354837],
            [-2.73259860735542, 51.840708065825453],
            [-2.732952405660113, 51.839166582432696],
            [-2.733446433465652, 51.840003272005966],
            [-2.735094251595107, 51.839226040091432],
            [-2.735534565060445, 51.839436376059794],
            [-2.738845512320741, 51.836611288488136],
            [-2.740896201997582, 51.838639369295734],
            [-2.741818208115232, 51.838572416583034],
            [-2.740259314616951, 51.84131104489051],
            [-2.741141955077562, 51.842467137007411],
            [-2.741481726678522, 51.842562099029983],
            [-2.742832766245413, 51.841747069052559],
            [-2.743446713815543, 51.842008426916216],
            [-2.743906982473807, 51.843160874225553],
            [-2.746322895767856, 51.844594933232493],
            [-2.751032643914979, 51.844985731589063],
            [-2.751450898218966, 51.845345401464833],
            [-2.754465189261907, 51.845906926681323],
            [-2.756876288587253, 51.845900423745768],
            [-2.757638201701791, 51.847443787322646],
            [-2.757695557082303, 51.849389094511281],
            [-2.760407895323451, 51.851567216440543],
            [-2.761004711044302, 51.853122414007771],
            [-2.762363486416857, 51.85362610652583],
            [-2.763414044523637, 51.85564768496014],
            [-2.764516760450586, 51.856115251914133],
            [-2.764273932962116, 51.857122934685194],
            [-2.764907859342162, 51.858028712847883],
            [-2.766374327512132, 51.858791501443115],
            [-2.767203468021904, 51.860157238312439],
            [-2.768299746237198, 51.86023819453932],
            [-2.768713357222743, 51.860564566067559],
            [-2.769504800879329, 51.862353114536347],
            [-2.77230777856664, 51.862198077053883],
            [-2.776095874241502, 51.863678251005879],
            [-2.778795395253978, 51.865853335353144],
            [-2.779098519846458, 51.866922167317036],
            [-2.77764133774821, 51.867567481171385],
            [-2.778764279847749, 51.868003312181521],
            [-2.779083228212762, 51.868810398282108],
            [-2.777474666861984, 51.86960866175388],
            [-2.776652873860652, 51.870636377889241],
            [-2.777290197228225, 51.871881929027793],
            [-2.776907854989078, 51.873210640212932],
            [-2.774143090564111, 51.874445362783398],
            [-2.772719072515252, 51.876788810477144],
            [-2.770817733814251, 51.878243454047741],
            [-2.77023257069989, 51.879619324880885],
            [-2.769656364168894, 51.879565553632439],
            [-2.768506019480909, 51.880090061413476],
            [-2.7684249852103, 51.880534749643907],
            [-2.76934008457585, 51.880270724387394],
            [-2.775680815477701, 51.882092032643016],
            [-2.776772994307868, 51.881650556783654],
            [-2.779448825611835, 51.884071235006367],
            [-2.781724925420744, 51.884372616979363],
            [-2.782142754296886, 51.884086620860508],
            [-2.78476594664455, 51.884294825904412],
            [-2.78506256014608, 51.884724418172645],
            [-2.78632885073833, 51.884687188978369],
            [-2.789986758715, 51.885817134425807],
            [-2.791731490139394, 51.886628986854085],
            [-2.792569707996347, 51.887611461123534],
            [-2.793679309099238, 51.888160527332516],
            [-2.796961586015612, 51.888400875927438],
            [-2.79905615435271, 51.890408759171848],
            [-2.801081984998594, 51.890175599378125],
            [-2.802102639268718, 51.890616400050021],
            [-2.80180756031163, 51.892324916637641],
            [-2.802887023415038, 51.894104978689192],
            [-2.805272320737966, 51.895518255093641],
            [-2.808666692496885, 51.89622145015359],
            [-2.813896165901352, 51.897779504349302],
            [-2.815801846854334, 51.899082597445627],
            [-2.816735458122186, 51.900955252434599],
            [-2.816652301803798, 51.902059932139451],
            [-2.817836183833001, 51.903861609138083],
            [-2.819403464296367, 51.903802152526801],
            [-2.821129348172136, 51.90542559378455],
            [-2.823721591643764, 51.905294186423397],
            [-2.825534692538537, 51.905003654937978],
            [-2.832955807786541, 51.905195008323368],
            [-2.834352667921919, 51.906134567379915],
            [-2.836096668493829, 51.906038570145306],
            [-2.836096173364766, 51.907264055840116],
            [-2.836898372623104, 51.908347170394002],
            [-2.836047151517217, 51.909633549125957],
            [-2.836986989637709, 51.910142055060085],
            [-2.837379524848844, 51.91147173712146],
            [-2.837097597489358, 51.913822207195587],
            [-2.840153226046247, 51.914736397950932],
            [-2.840409641098635, 51.916010397441859],
            [-2.842013947168144, 51.917753082582728],
            [-2.842914393021589, 51.917853629269139],
            [-2.844277774335833, 51.917131763476981],
            [-2.844261359031231, 51.916334374729018],
            [-2.84514613738289, 51.915214930376052],
            [-2.846516614197883, 51.915649237126893],
            [-2.846377349650852, 51.91651607768447],
            [-2.847482110492106, 51.916966669898827],
            [-2.848134893409582, 51.916810017705885],
            [-2.848563429663984, 51.915864665287657],
            [-2.853433355901907, 51.915826747512114],
            [-2.853855322090376, 51.915614194537788],
            [-2.853635813619012, 51.91461957752724],
            [-2.85406848957407, 51.913286659415085],
            [-2.855332265609396, 51.913383574104472],
            [-2.855481873929503, 51.914447028556467],
            [-2.858154541751793, 51.91492747001093],
            [-2.859142338120978, 51.914730550282457],
            [-2.860110943046094, 51.913834257500554],
            [-2.861915672576188, 51.914011662110354],
            [-2.863783397243657, 51.915955321474378],
            [-2.863809178113802, 51.916692399624367],
            [-2.863244692286443, 51.917204537336417],
            [-2.860664074312092, 51.917745821719294],
            [-2.851094462510987, 51.918849398543749],
            [-2.848379909707141, 51.919934440969023],
            [-2.846452499005264, 51.919892576781237],
            [-2.84645627613849, 51.920711634290186],
            [-2.845371314801963, 51.92131913672177],
            [-2.844930781712536, 51.922019106892897],
            [-2.846339153284427, 51.923142756944458],
            [-2.84807329641272, 51.923647257057844],
            [-2.854366541826991, 51.923777958630268],
            [-2.855430891338311, 51.92499570625754],
            [-2.85712322990666, 51.925586691306883],
            [-2.857872227209592, 51.927115106188914],
            [-2.859098763038084, 51.927677989006071],
            [-2.860624014140669, 51.927661449682951],
            [-2.862973378212721, 51.926862023425187],
            [-2.865243228597564, 51.926620579797266],
            [-2.86669847608775, 51.926897584627284],
            [-2.868613237306484, 51.926644304953562],
            [-2.870017206733013, 51.926825443020782],
            [-2.871451639189048, 51.928052897402814],
            [-2.8720405072188, 51.930446448568908],
            [-2.873841140286793, 51.931049877965663],
            [-2.875418011569364, 51.933081823991103],
            [-2.877953252011001, 51.93383077352523],
            [-2.879905184505606, 51.93412728599484],
            [-2.881268432573303, 51.933997504586884],
            [-2.882511600753309, 51.934419759240285],
            [-2.888435269606662, 51.93357858244395],
            [-2.889115356023332, 51.932664450704081],
            [-2.889108921050485, 51.931897562204433],
            [-2.887044001125147, 51.92815668029143],
            [-2.891018224929238, 51.926519054198927],
            [-2.892268944623745, 51.925421658415601],
            [-2.893320980940821, 51.92516012871242],
            [-2.896942115484991, 51.925457161763525],
            [-2.898214613623783, 51.925240661251706],
            [-2.899883572416788, 51.925433806132432],
            [-2.90088184464869, 51.925902695007089],
            [-2.902898219111656, 51.925144578735086],
            [-2.904130573873373, 51.925175576733579],
            [-2.905092627336811, 51.924492951374766],
            [-2.90632902374479, 51.922632172072319],
            [-2.908239466561863, 51.921680571611653],
            [-2.908243481441424, 51.921087129076923],
            [-2.911811202429871, 51.919549035468165],
            [-2.912921727194684, 51.920104166362528],
            [-2.915565900200943, 51.918779914578955],
            [-2.915569160162271, 51.918154109156845],
            [-2.916238644964131, 51.917685856813158],
            [-2.918335985654684, 51.917505869285222],
            [-2.920116400229592, 51.91697317459046],
            [-2.920259409975693, 51.917425207555745],
            [-2.928043718401725, 51.913885413308684],
            [-2.930885275558239, 51.914903238707467],
            [-2.932090382673697, 51.914754336605263],
            [-2.932972696985725, 51.915095300055697],
            [-2.933919592715585, 51.915044631127969],
            [-2.935636859218149, 51.912606985065445],
            [-2.93887529879775, 51.911877185125043],
            [-2.94129276526079, 51.90999041521259],
            [-2.942626128902335, 51.909379133900664],
            [-2.943634923223874, 51.909236180989261],
            [-2.94482171144297, 51.909470316590642],
            [-2.946800113243316, 51.90863532196024],
            [-2.94872345262275, 51.909398461114584],
            [-2.950361215292388, 51.908544573796505],
            [-2.950795675915544, 51.907434255732056],
            [-2.952329256588706, 51.906264694351513],
            [-2.953751759700304, 51.906099423673723],
            [-2.957894550646881, 51.906849796150638],
            [-2.960776234920188, 51.906739985536532],
            [-2.964939388902763, 51.904770123885143],
            [-2.966531620503065, 51.905067249334898],
            [-2.96842141682297, 51.904532920865513],
            [-2.970814662020795, 51.905507618958296],
            [-2.971792724698248, 51.904959176846369],
            [-2.972821367913869, 51.9060125308294],
            [-2.972491863199114, 51.906841541057489],
            [-2.972833519939274, 51.90744921542283],
            [-2.972443777259175, 51.90791278378844],
            [-2.972889475032363, 51.90896196220725],
            [-2.975362520000795, 51.909511526362643],
            [-2.973915153477212, 51.910477475464347],
            [-2.972354395971228, 51.910773604551558],
            [-2.972334032507481, 51.911311442933062],
            [-2.97356083977493, 51.911168229827304],
            [-2.973866515117384, 51.911521749379943],
            [-2.972180515388493, 51.91302102748174],
            [-2.971166948239521, 51.913414217957737],
            [-2.971397171964313, 51.913909527302586],
            [-2.972772467475354, 51.914646227949987],
            [-2.972837329401339, 51.915494454682673],
            [-2.973770302267583, 51.916616023771986],
            [-2.973121312896994, 51.916927990205821],
            [-2.973348974402985, 51.917907938643474],
            [-2.974947310972888, 51.918596913977183],
            [-2.976200104802727, 51.921184064463198],
            [-2.974101645085468, 51.921242813210661],
            [-2.973982388873128, 51.922585275992233],
            [-2.975025609852807, 51.924222911296383],
            [-2.974484917727467, 51.924436883087317],
            [-2.97450807169855, 51.92510293332051],
            [-2.97616083960407, 51.926009026543063],
            [-2.976659681714147, 51.927483926496159],
            [-2.977295574329442, 51.927768161357918],
            [-2.977787246606457, 51.927238096813262],
            [-2.979285479505495, 51.926984676774048],
            [-2.980597820745496, 51.927677753436733],
            [-2.981220163034159, 51.926606219147224],
            [-2.983655693963874, 51.925723637725689],
            [-2.9865558787841, 51.925542914427446],
            [-2.987123442467145, 51.925760235757565],
            [-2.999237024331864, 51.923451576016951],
            [-3.002349063140936, 51.924042800785756],
            [-3.008117141878571, 51.927124243545244],
            [-3.010031149356468, 51.930433653113973],
            [-3.010268146257605, 51.932049123719651],
            [-3.01011245833261, 51.933471058791248],
            [-3.00857817515797, 51.936269668594775],
            [-3.008516838148331, 51.938074712664225],
            [-3.010828379726807, 51.940861011147227],
            [-3.013156434923005, 51.944948136197205],
            [-3.021617100969773, 51.950028710836492],
            [-3.022144993524204, 51.950847713730262],
            [-3.02236545660418, 51.952863404268079],
            [-3.025938149642762, 51.957275724670026],
            [-3.030201231747083, 51.958823624086762],
            [-3.035328977928451, 51.959734390525256],
            [-3.036739074645415, 51.961620002693572],
            [-3.036800276730516, 51.962443048907673],
            [-3.037720283401379, 51.963395192305256],
            [-3.038317948652713, 51.965135095299509],
            [-3.039602158747371, 51.966474224757626],
            [-3.042204806511271, 51.968108271929047],
            [-3.051408735726666, 51.972085913020486],
            [-3.052142044741813, 51.972938910840242],
            [-3.056953304080329, 51.975221812111982],
            [-3.057437117459816, 51.976478019654358],
            [-3.059360488913555, 51.977964040397815],
            [-3.059552044330327, 51.978615969374808],
            [-3.062639276476137, 51.980054593313028],
            [-3.067361626507497, 51.983136282824582],
            [-3.06853732551126, 51.984446410523439],
            [-3.068605958099011, 51.987084673147301],
            [-3.069418883354758, 51.989482408721614],
            [-3.072495949329631, 51.992157142074333],
            [-3.07562413810947, 51.995855412486343],
            [-3.078895779196801, 51.997622799303777],
            [-3.082212410731687, 52.002391803527765],
            [-3.084081957286875, 52.004101793370772],
            [-3.086107799864877, 52.009974077460853],
            [-3.08689077248616, 52.013904940894825],
            [-3.094340277228567, 52.018709112307036],
            [-3.09919273195219, 52.022679333159076],
            [-3.092755701346998, 52.028970927958802],
            [-3.092826588105163, 52.031519231097931],
            [-3.092348193003797, 52.033182515447201],
            [-3.090862047347863, 52.035369421391323],
            [-3.089523647787042, 52.036263828753931],
            [-3.086925964202527, 52.03915686409168],
            [-3.086280307075071, 52.040187796666729],
            [-3.086485557754764, 52.041136254938891],
            [-3.092806057030284, 52.047472205564404],
            [-3.092935202489143, 52.04870097863536],
            [-3.090743735389318, 52.050149961201228],
            [-3.090774605153835, 52.050818606675918],
            [-3.094934264920167, 52.052927073705852],
            [-3.09610871713741, 52.053779289693097],
            [-3.096801416475381, 52.054908407546264],
            [-3.09770665242092, 52.054829846792707],
            [-3.099859873237023, 52.057167207843221],
            [-3.100568844989115, 52.057112934838301],
            [-3.100906177438136, 52.057366926063558],
            [-3.101409676366584, 52.058268513862117],
            [-3.1026005426468, 52.058651181279281],
            [-3.104253493872804, 52.0587256105999],
            [-3.107010165003912, 52.059962079229152],
            [-3.108130812560303, 52.061216579540037],
            [-3.107999292653991, 52.06209084332334],
            [-3.109809897374269, 52.063282193834958],
            [-3.110594294823258, 52.063383593919632],
            [-3.110821461575419, 52.063896637144197],
            [-3.111691940863996, 52.063998117462361],
            [-3.111570796569225, 52.064935224203225],
            [-3.116407026680024, 52.065826362422449],
            [-3.116477652758517, 52.06637684198639],
            [-3.116994137449551, 52.066496022728245],
            [-3.11860094417807, 52.068447112493928],
            [-3.118327094363472, 52.070157103702385],
            [-3.12014942089698, 52.071850781765072],
            [-3.120192785576227, 52.072300818701358],
            [-3.119559132609134, 52.072691655762121],
            [-3.120203507230318, 52.073195321342965],
            [-3.120714273025202, 52.074480672962579],
            [-3.121717683296026, 52.07481188419154],
            [-3.121193007667882, 52.076116975855243],
            [-3.122262616821132, 52.076409790429267],
            [-3.12381505310362, 52.07779938867678],
            [-3.125889609249313, 52.078303760587609],
            [-3.125325510666002, 52.079836719013691],
            [-3.122909470956164, 52.082299022168165],
            [-3.118854020020077, 52.088720293608645],
            [-3.119536438943907, 52.091667347893804],
            [-3.121080289667227, 52.094093720807486],
            [-3.122709065966776, 52.103150099083145],
            [-3.121394200088991, 52.103989784350638],
            [-3.119759568148663, 52.104364963369001],
            [-3.117195941973521, 52.104025157119054],
            [-3.113008526780374, 52.104554780124523],
            [-3.107965103044104, 52.104719179508159],
            [-3.105459839564678, 52.105378362431125],
            [-3.10466019206318, 52.106343394646039],
            [-3.104702157843574, 52.10739134479153],
            [-3.10741289224209, 52.112190444346538],
            [-3.107900815925622, 52.114082046880569],
            [-3.107323256537091, 52.115114237983661],
            [-3.104979546413964, 52.116719533178411],
            [-3.105853046139975, 52.116901949492373],
            [-3.10671186333748, 52.118731634462868],
            [-3.108173929877974, 52.11896964482208],
            [-3.108500164129713, 52.119344196302805],
            [-3.109714860654092, 52.119677120029102],
            [-3.112620812819574, 52.12018109191915],
            [-3.115026022597699, 52.121569941430323],
            [-3.117467844243779, 52.122022439752797],
            [-3.121818718391614, 52.121599001516017],
            [-3.123542824618867, 52.121853208833109],
            [-3.128055995543121, 52.124562232206578],
            [-3.130938099963017, 52.124276578506404],
            [-3.133860917832456, 52.123064396199624],
            [-3.136809047855607, 52.123312025100368],
            [-3.137039598843593, 52.123933772721244],
            [-3.13944180749273, 52.125968604200764],
            [-3.139764960532635, 52.126725214477084],
            [-3.141915042972999, 52.12785884395678],
            [-3.141223985824067, 52.129913670377682],
            [-3.13719969704379, 52.131311086777465],
            [-3.135770774499739, 52.133259708366495],
            [-3.135230415871476, 52.133153425304215],
            [-3.134132071448859, 52.133569486785134],
            [-3.13586837418841, 52.137899880885634],
            [-3.13190462443527, 52.138031506760726],
            [-3.128842171300752, 52.13780732416496],
            [-3.124041114394922, 52.138749587408896],
            [-3.119149934531179, 52.138786226856695],
            [-3.115350222280568, 52.139171979167209],
            [-3.106709185428246, 52.141847277915673],
            [-3.097989266310688, 52.14385285256386],
            [-3.095384022886197, 52.143927433448184],
            [-3.093686213948413, 52.144297434666591],
            [-3.08329595426898, 52.148336747452177],
            [-3.078275538315839, 52.152202079837359],
            [-3.07263138471112, 52.15563501415167],
            [-3.071568456940152, 52.158180994100576],
            [-3.073635435764059, 52.15921142423587],
            [-3.074523828527019, 52.160104214561272],
            [-3.079424016981827, 52.161369428024805],
            [-3.080203884617695, 52.162241591120328],
            [-3.082540437409121, 52.16303833176724],
            [-3.084352732027277, 52.163134966169309],
            [-3.084172393532403, 52.162933431252782],
            [-3.084890933685056, 52.162782070608372],
            [-3.084796084988641, 52.162488942622538],
            [-3.085675343862755, 52.161913528996514],
            [-3.085355241871501, 52.161495704061672],
            [-3.085736109468587, 52.160566141070078],
            [-3.087052259861645, 52.160403868829867],
            [-3.088835877906333, 52.159269851194757],
            [-3.088977454336365, 52.15824268785304],
            [-3.089821714499454, 52.158573843484803],
            [-3.090403079947325, 52.158376064968181],
            [-3.089890525511718, 52.157979811311229],
            [-3.090075539825109, 52.15729569545497],
            [-3.093119745112864, 52.156907881157998],
            [-3.092789206493358, 52.156304061945953],
            [-3.094376197687701, 52.155628516299501],
            [-3.094381843219158, 52.155203196259514],
            [-3.096085131673239, 52.155158605431374],
            [-3.096201782558461, 52.155444329542725],
            [-3.096684375181816, 52.155096391402658],
            [-3.097897002266779, 52.155130960804314],
            [-3.098892680162228, 52.154725189214382],
            [-3.099780655135798, 52.156236366690351],
            [-3.101680037158048, 52.157382953649432],
            [-3.104075026816358, 52.156932589145534],
            [-3.105255804398862, 52.157154391261656],
            [-3.10638752872514, 52.158462738272185],
            [-3.107398621674776, 52.158560238873456],
            [-3.108098111300214, 52.159001413058448],
            [-3.109643450544743, 52.159183781739664],
            [-3.112063929775733, 52.158992851400924],
            [-3.11298774419441, 52.159341073320171],
            [-3.113383210612778, 52.16019147254783],
            [-3.116927197997529, 52.160438474016892],
            [-3.11775136636335, 52.161532945523646],
            [-3.119440980437572, 52.162682135131817],
            [-3.122427145771011, 52.163410781997662],
            [-3.121991727130361, 52.166907876730669],
            [-3.112456574641184, 52.170222327453878],
            [-3.112290948219493, 52.172726941991797],
            [-3.111615832472577, 52.172745894342377],
            [-3.1124377625313, 52.173219154768205],
            [-3.112487105240783, 52.173614286574995],
            [-3.109060073722291, 52.174994290228],
            [-3.106885026153189, 52.176388523443947],
            [-3.101407743554457, 52.177257067098132],
            [-3.10056385721832, 52.178321367297322],
            [-3.096695626178783, 52.180463949572832],
            [-3.096401204821342, 52.181456576421169],
            [-3.094614756050704, 52.183813479349013],
            [-3.095856665637119, 52.183698553089279],
            [-3.097376170633586, 52.184343452858137],
            [-3.097103122884254, 52.186739348319961],
            [-3.095640941958045, 52.189737888724906],
            [-3.096333864593477, 52.194712357182894],
            [-3.097843290801752, 52.197663485337756],
            [-3.098867384240049, 52.19807471958552],
            [-3.099899613166349, 52.199229408225008],
            [-3.100615944610361, 52.20139490380479],
            [-3.102081034561179, 52.202710964596243],
            [-3.100050760235378, 52.20220934699929],
            [-3.094143101571404, 52.203741473654887],
            [-3.090930989130759, 52.205768074176035],
            [-3.08846701208771, 52.206502004118647],
            [-3.087071252487886, 52.207295273056467],
            [-3.085699750222528, 52.208845325174835],
            [-3.083651698174219, 52.210142642573807],
            [-3.080934701531551, 52.210717802367235],
            [-3.076793953981284, 52.213305447894484],
            [-3.073525940001771, 52.213499759956321],
            [-3.07222171230591, 52.212947899506595],
            [-3.072211120288737, 52.214999686376785],
            [-3.078012016472656, 52.216038304450031],
            [-3.077225145416254, 52.219087962777976],
            [-3.075805950255672, 52.219662833371025],
            [-3.07213364015344, 52.222843020842184],
            [-3.072227688256293, 52.223343849691595],
            [-3.073181431480036, 52.223737065144199],
            [-3.071665554352023, 52.225837593512722],
            [-3.07129125903027, 52.227439545407854],
            [-3.072079690287299, 52.228685693148407],
            [-3.071036177298501, 52.228703261086785],
            [-3.070373830389768, 52.229228036443274],
            [-3.074003218188995, 52.234190308670023],
            [-3.073709273737876, 52.234994956455076],
            [-3.073037667149502, 52.23586687222479],
            [-3.07090615201102, 52.235953665424177],
            [-3.067728672893538, 52.236756559274809],
            [-3.067264265017148, 52.236504524831346],
            [-3.067440696480191, 52.236228712082756],
            [-3.066054124391919, 52.235809691811802],
            [-3.063345133665354, 52.236733205163489],
            [-3.063259764529666, 52.236286236700259],
            [-3.05995941899973, 52.236364477122166],
            [-3.059980882334998, 52.236036123249136],
            [-3.058684290980019, 52.236072037579596],
            [-3.055247328005082, 52.237613261308312],
            [-3.054577407785314, 52.237955502444976],
            [-3.054362575052268, 52.237665224081276],
            [-3.053724326340137, 52.237737456139335],
            [-3.053567135620577, 52.23802566320051],
            [-3.049159095027159, 52.238892979230449],
            [-3.047805733787079, 52.23845007397631],
            [-3.047039729856939, 52.238695126475797],
            [-3.046067683335181, 52.238087883952815],
            [-3.045958038842391, 52.238594133245186],
            [-3.045428928493903, 52.238762452515424],
            [-3.044215539641182, 52.237917280901449],
            [-3.044146841585138, 52.2392952639565],
            [-3.045524577807125, 52.240775521689081],
            [-3.043822821963279, 52.241526025086522],
            [-3.043070811134547, 52.242745518841048],
            [-3.042857017583421, 52.244121185953787],
            [-3.043117714993231, 52.244807568844138],
            [-3.043824270930111, 52.245206801729019],
            [-3.043679570310388, 52.245531746298681],
            [-3.045599177239616, 52.245505763920363],
            [-3.049751763816862, 52.244830560568559],
            [-3.049936528960834, 52.245025813141638],
            [-3.045285684394624, 52.246812190927812],
            [-3.045166040944974, 52.247268179358819],
            [-3.047261249059544, 52.248600007005948],
            [-3.048319002824727, 52.250222425789488],
            [-3.047472189186872, 52.250403464742242],
            [-3.045599626890832, 52.25306153150192],
            [-3.044154175100794, 52.253579604256153],
            [-3.038845743804036, 52.253604870345761],
            [-3.037139104447085, 52.256055452359007],
            [-3.036171367779065, 52.256649244603537],
            [-3.033684047061145, 52.256916499759036],
            [-3.031911218347802, 52.25748044318847],
            [-3.030439101862149, 52.257493304496428],
            [-3.028930132519797, 52.258002753174907],
            [-3.026802301552986, 52.258044659176754],
            [-3.025008506382136, 52.258276925535718],
            [-3.023565342647518, 52.259293705766019],
            [-3.019545287027584, 52.260392992259355],
            [-3.017008035045979, 52.261500951594925],
            [-3.013664183701527, 52.261876754604863],
            [-3.011373746959996, 52.262443932524228],
            [-3.009270824166947, 52.263463489792684],
            [-3.005502571046291, 52.264260753386168],
            [-3.001614800552131, 52.263987227098816],
            [-2.998720281689089, 52.264158300804759],
            [-2.996113351796455, 52.261471452720919],
            [-2.995586416466347, 52.261495677877427],
            [-2.995022786893471, 52.261058990610429],
            [-2.9945917653407, 52.261571493094046],
            [-2.992926605728485, 52.261607090092546],
            [-2.992758450898454, 52.26109783674341],
            [-2.989868858487802, 52.260496357567362],
            [-2.989090730884921, 52.260491192898186],
            [-2.988751646526703, 52.260858154333306],
            [-2.988077841910132, 52.260866494018131],
            [-2.987283808798637, 52.261464721615205],
            [-2.986696374447877, 52.26140400215391],
            [-2.986505690953742, 52.261724763222681],
            [-2.984771836853096, 52.262297559503978],
            [-2.982798534031268, 52.262049682206573],
            [-2.978575035835704, 52.262350895571565],
            [-2.977057848861316, 52.260921368929417],
            [-2.978038446928362, 52.260539237756767],
            [-2.97750268517332, 52.259762390955409],
            [-2.972706557173891, 52.260736138955544],
            [-2.97177112374395, 52.261242823391086],
            [-2.969944169307481, 52.261115798954663],
            [-2.970043746726749, 52.261717351814106],
            [-2.969071997770703, 52.261568897344333],
            [-2.967500170517652, 52.261854207404255],
            [-2.967185363054941, 52.261392871800659],
            [-2.966763389182922, 52.261797310092064],
            [-2.96559706290344, 52.261999256309494],
            [-2.965825287163212, 52.262387581563758],
            [-2.96489633149778, 52.262387986693774],
            [-2.964436440949946, 52.263207193512933],
            [-2.964665023293541, 52.264017177491219],
            [-2.963821153330259, 52.264226359776636],
            [-2.965038261643721, 52.26811384220813],
            [-2.960698070784101, 52.268810953684223],
            [-2.957376344931276, 52.269920409014802],
            [-2.9559084961138, 52.270900591586788],
            [-2.955639172607532, 52.270234771294369],
            [-2.954658271403774, 52.269871396890224],
            [-2.951958738005342, 52.269456255653779],
            [-2.949621087673366, 52.269514643337487],
            [-2.950880540795244, 52.270022362532231],
            [-2.951456181501237, 52.270829574287049],
            [-2.954493361843528, 52.271608813516544],
            [-2.955129323024833, 52.271332153656104],
            [-2.954958864148945, 52.271651799649931],
            [-2.956922058374111, 52.271962259797426],
            [-2.957127890897981, 52.271720542272185],
            [-2.958187957785968, 52.271742510259365],
            [-2.958767221588304, 52.272099228618579],
            [-2.959895368883191, 52.271948008960777],
            [-2.959787833940414, 52.272261755951661],
            [-2.960356409358787, 52.272462117153538],
            [-2.961310454257844, 52.272595503947201],
            [-2.961433689086688, 52.272262747440266],
            [-2.963407971725864, 52.272608072944905],
            [-2.963297177069481, 52.273716618157906],
            [-2.964927407760419, 52.274345231979133],
            [-2.966761046939777, 52.273744941736311],
            [-2.969288721906185, 52.273654087187928],
            [-2.970091371620822, 52.274100619877061],
            [-2.970352170277978, 52.273761329020779],
            [-2.972877239140004, 52.273551743146484],
            [-2.972807043981998, 52.273224164784324],
            [-2.974230392360801, 52.273502822863698],
            [-2.974164767335921, 52.273116768655939],
            [-2.974881969314254, 52.273341007182928],
            [-2.974831840385648, 52.272993484924939],
            [-2.975585878959915, 52.273159875133871],
            [-2.975461144391511, 52.273486365675836],
            [-2.977339037690494, 52.273639856283104],
            [-2.97784613187052, 52.273373131239531],
            [-2.977591349516043, 52.272984150281403],
            [-2.978910502807316, 52.273322053783275],
            [-2.97865165179793, 52.272748801448564],
            [-2.979085860174008, 52.272633717368173],
            [-2.979368832845318, 52.273104275775125],
            [-2.9802267031129, 52.273060293843244],
            [-2.980583765321499, 52.27343403464873],
            [-2.981098382548069, 52.273177122727219],
            [-2.983323790281173, 52.273205357172344],
            [-2.983809950501189, 52.272921698584277],
            [-2.985883834614978, 52.27325503001132],
            [-2.987204519574605, 52.27306507955128],
            [-2.986744592753468, 52.273539133786848],
            [-2.987071476518188, 52.273607426728361],
            [-2.987878127170232, 52.273500883223186],
            [-2.988524182700794, 52.272961432687048],
            [-2.990294123086891, 52.273662250398658],
            [-2.991334625945718, 52.273067329156945],
            [-2.992218841092372, 52.273547191479992],
            [-2.992122909263864, 52.272861115019943],
            [-2.992726819512299, 52.272799397004611],
            [-2.992752222287042, 52.273411444091707],
            [-2.993573342989484, 52.27316628448142],
            [-2.994630579315347, 52.273386641809275],
            [-2.994960480069562, 52.273981737460772],
            [-2.994664965719775, 52.274398695068072],
            [-2.995545404771196, 52.274576479832291],
            [-2.995527803104893, 52.275360609617323],
            [-2.996101959428913, 52.275800801488479],
            [-2.996970185141289, 52.275432948629678],
            [-2.997084869527473, 52.274926707592329],
            [-2.999495793074436, 52.275313594062681],
            [-2.999454623757848, 52.274851825056516],
            [-3.001082477046886, 52.275220132728862],
            [-3.00137602635023, 52.27497849260827],
            [-3.004249796678852, 52.275738960914381],
            [-3.005160803303673, 52.276357853386152],
            [-3.005610526792491, 52.276152633434585],
            [-3.006178197211579, 52.276495733210325],
            [-3.006511637316741, 52.276139559453682],
            [-3.007346706246075, 52.27625201175389],
            [-3.008076381054972, 52.276564050874597],
            [-3.007158843392126, 52.276757988326985],
            [-3.007902149067288, 52.277218257408997],
            [-3.009544761981917, 52.277003730736951],
            [-3.010199201219005, 52.2773577570144],
            [-3.011788019551854, 52.277484403921903],
            [-3.01261870584724, 52.279395879320191],
            [-3.011301879783469, 52.27995199858826],
            [-3.010455652593937, 52.281478658418621],
            [-3.010733878959421, 52.281723518538115],
            [-3.009560643469009, 52.282409651331633],
            [-3.009326927112669, 52.283993996918085],
            [-3.009454402587714, 52.284958498219254],
            [-3.009993822812234, 52.285148980874339],
            [-3.009750294801365, 52.285850533140326],
            [-3.010716654739917, 52.286119174691741],
            [-3.010969085682447, 52.287096989305432],
            [-3.01172064442428, 52.287395332740537],
            [-3.011963432743554, 52.288077436902967],
            [-3.009901734364337, 52.288770284811342],
            [-3.008169267736625, 52.290276631830913],
            [-3.005604295016702, 52.290636553009676],
            [-3.004706022209501, 52.291954130037901],
            [-3.005376786435732, 52.294250910648742],
            [-3.004388982408879, 52.29654022987696],
            [-3.003792300590265, 52.297713181043015],
            [-3.001321603717602, 52.29993235664729],
            [-3.001109638692193, 52.300742406951798],
            [-3.00055262907984, 52.303225829794179],
            [-3.000775593390094, 52.305178490599019],
            [-3.001272726097015, 52.306410479535266],
            [-3.003470571613459, 52.309166311946107],
            [-3.003751151940907, 52.311324360041993],
            [-3.00158568649288, 52.317804260133727],
            [-3.002117658938479, 52.319986249158681],
            [-3.001532119021672, 52.320174623535578],
            [-3.000867922426849, 52.32175360017623],
            [-2.997149034660655, 52.324515485965009],
            [-2.990464231718565, 52.324656248489966],
            [-2.989584142769824, 52.32394618145932],
            [-2.986028301839155, 52.323456269982472],
            [-2.983169612399948, 52.325155937535122],
            [-2.981531568366067, 52.32678965529233],
            [-2.979147401720816, 52.32733539099867],
            [-2.977939148570796, 52.327973839293726],
            [-2.974298000936161, 52.328151377191034],
            [-2.972132319962509, 52.329299330721739],
            [-2.968915479276332, 52.328955355465567],
            [-2.966802584303688, 52.329436580636873],
            [-2.967268848212406, 52.330629399937862],
            [-2.96681954362891, 52.332367347786366],
            [-2.961651642044805, 52.335923025603748],
            [-2.960675694614872, 52.339175637167656],
            [-2.956331151213822, 52.344280627477602],
            [-2.956136389557662, 52.345867225906275],
            [-2.954652337319661, 52.349141872795698],
            [-2.95386466391554, 52.349242635712713],
            [-2.952913383598971, 52.348767524897454],
            [-2.948910128350199, 52.349395818684485],
            [-2.947484056134917, 52.34923374947163],
            [-2.944368610416082, 52.34998602550521],
            [-2.933150235220389, 52.350039214938192],
            [-2.935631289988087, 52.351424798750216],
            [-2.936172390723131, 52.352921918184428],
            [-2.934556689983836, 52.353100135589862],
            [-2.934243856107213, 52.35406998287452],
            [-2.931387585335915, 52.353278900918895],
            [-2.930818441148856, 52.35386327096392],
            [-2.930656910005262, 52.354980258508462],
            [-2.93189553851948, 52.356276806449046],
            [-2.934650322598724, 52.357676433662512],
            [-2.935361076814733, 52.35872179169597],
            [-2.938890496750904, 52.361044796853157],
            [-2.938138654729634, 52.361046274272525],
            [-2.938274905308755, 52.361516291571562],
            [-2.936668574734579, 52.362221302166745],
            [-2.936690824732999, 52.36195410960844],
            [-2.936251882979815, 52.362238989797596],
            [-2.93464726993904, 52.362193257228114],
            [-2.933929559977467, 52.361725136741178],
            [-2.931156000461423, 52.362865440206868],
            [-2.930553927781031, 52.36279736142275],
            [-2.928896394188712, 52.365059812212536],
            [-2.928366741545741, 52.365224904069485],
            [-2.928093993985239, 52.366085633204847],
            [-2.926144729330617, 52.366981991550958],
            [-2.924069656809219, 52.367132196953762],
            [-2.920386519288747, 52.368280254299755],
            [-2.919927892404378, 52.367837901155404],
            [-2.918647614518822, 52.368142748587616],
            [-2.917465863080508, 52.3677455641538],
            [-2.915185407742665, 52.368834009742677],
            [-2.914492643708431, 52.368502233162928],
            [-2.914036952516594, 52.368697255324335],
            [-2.911706626920559, 52.368583101301084],
            [-2.910648405771162, 52.368048241237197],
            [-2.907154200970957, 52.368330449509116],
            [-2.906471122898752, 52.368324903761312],
            [-2.90616984351207, 52.36802873312962],
            [-2.905025811292948, 52.368177752500792],
            [-2.904713948507208, 52.367722528927374],
            [-2.9030832844648, 52.367559691373778],
            [-2.902382963446493, 52.367067872481037],
            [-2.900806492472215, 52.36725701383331],
            [-2.902114363803334, 52.369598926413595],
            [-2.903120718026245, 52.37004255685293],
            [-2.906051528476894, 52.37021341176704],
            [-2.907276641179208, 52.369861475805315],
            [-2.908491833855303, 52.370172196791366],
            [-2.910347138771641, 52.372110633877],
            [-2.913516189179731, 52.372500632428896],
            [-2.914694691137536, 52.373802316923559],
            [-2.916776975097519, 52.374409214144386],
            [-2.922312368310175, 52.375133916387789],
            [-2.922500039865603, 52.376455840124386],
            [-2.922124990293472, 52.376998192368205],
            [-2.920362947445316, 52.376895055129566],
            [-2.919732518036451, 52.378368996791643],
            [-2.916401308435043, 52.380883422999482],
            [-2.91538122539494, 52.381001911706086],
            [-2.914008601601358, 52.382119255292793],
            [-2.914115158091653, 52.382441186315127],
            [-2.914457617696243, 52.38230547866835],
            [-2.915522493978501, 52.382859132839528],
            [-2.916255204571899, 52.383760584797422],
            [-2.917467688225908, 52.383848273300757],
            [-2.917862875571622, 52.384200325032204],
            [-2.919557873417938, 52.384527882995656],
            [-2.917686984994546, 52.386274876465997],
            [-2.915984418243643, 52.38579721069047],
            [-2.913289964663461, 52.386237917453421],
            [-2.905426785795286, 52.385769793334859],
            [-2.905342197412092, 52.385082676762849],
            [-2.90409478930213, 52.384292981738291],
            [-2.903337591634953, 52.384195383199568],
            [-2.902465627861391, 52.382937098120891],
            [-2.899166257824311, 52.381588519127511],
            [-2.898206693834783, 52.381506812213232],
            [-2.897827720942961, 52.379772748922022],
            [-2.896899475784494, 52.379639548292907],
            [-2.894441494897366, 52.380068141767509],
            [-2.893062226079853, 52.37964343951041],
            [-2.892809738980353, 52.379143683832147],
            [-2.895324843439839, 52.374351652636918],
            [-2.893136419354147, 52.374075128298458],
            [-2.892621681747022, 52.374481787867559],
            [-2.891301863484019, 52.3745196214945],
            [-2.891070177125869, 52.374758715420683],
            [-2.891104545930767, 52.375517245509805],
            [-2.892726975221794, 52.375623671636056],
            [-2.892591539949273, 52.375973522134878],
            [-2.891756239871329, 52.376219872788575],
            [-2.89056496307265, 52.376004996823632],
            [-2.890410903688821, 52.376379259174939],
            [-2.889156087358405, 52.376876886803323],
            [-2.889286133948568, 52.377646384541244],
            [-2.888424513595528, 52.377462269883921],
            [-2.8882363010496, 52.378039069768285],
            [-2.88765394436627, 52.377789018097452],
            [-2.886507594955778, 52.37849797550701],
            [-2.886938661661036, 52.37874916852541],
            [-2.886599065426779, 52.379397226831891],
            [-2.887910896742293, 52.379978943799138],
            [-2.887265822548533, 52.38004582304287],
            [-2.886984408203854, 52.381178027058326],
            [-2.887844612410298, 52.381654350386107],
            [-2.888129572838013, 52.382529670225921],
            [-2.887824352009909, 52.382841233004619],
            [-2.888731590530084, 52.383901571188858],
            [-2.888458214812531, 52.385140703065304],
            [-2.88559704572913, 52.385778920396334],
            [-2.883809294855284, 52.386902616115769],
            [-2.883280182964159, 52.386674620672707],
            [-2.879595624656274, 52.386703003445511],
            [-2.879546343212283, 52.386219688341001],
            [-2.877860822394525, 52.386209746056082],
            [-2.874652344903768, 52.386906017533335],
            [-2.874093519034899, 52.386584701223988],
            [-2.863786247373968, 52.388700407392804],
            [-2.858075943092163, 52.393691197709806],
            [-2.856805176088697, 52.394167915906039],
            [-2.855442416191641, 52.395284499655801],
            [-2.852244241390284, 52.395423582190617],
            [-2.849781895228369, 52.395080801306563],
            [-2.840742955441712, 52.392335073376287],
            [-2.832463423412377, 52.391934281746735],
            [-2.828462652097989, 52.391188329613897],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000020",
        LAD13CDO: "00GF",
        LAD13NM: "Telford and Wrekin",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.428225268020756, 52.825051214350054],
            [-2.427433470433233, 52.822854339125065],
            [-2.426324552771328, 52.822512233528045],
            [-2.426738091886094, 52.8235220655719],
            [-2.426509247298284, 52.825914095106405],
            [-2.42575464525053, 52.825843990632293],
            [-2.42636005079924, 52.824470019109292],
            [-2.426038915358811, 52.823197360694678],
            [-2.423805442644868, 52.8239532868769],
            [-2.424009289765032, 52.824617781361177],
            [-2.423245846990254, 52.824704109818171],
            [-2.422391645940927, 52.825378669608533],
            [-2.420094732124241, 52.825730224031304],
            [-2.418443758823712, 52.826431845532348],
            [-2.416967167691981, 52.826441539186746],
            [-2.416347617012193, 52.826991175161041],
            [-2.397754446571128, 52.819999118518638],
            [-2.377655336852768, 52.811628166424285],
            [-2.37834234072, 52.811468660715839],
            [-2.378707678386544, 52.810829239969557],
            [-2.377118597478606, 52.810294932724325],
            [-2.375904705592208, 52.80922364113075],
            [-2.376491458742349, 52.808282376515017],
            [-2.375980681962028, 52.80805746114013],
            [-2.377653440990863, 52.807132519888597],
            [-2.377498211613512, 52.805995840850187],
            [-2.37691261423322, 52.80586465847496],
            [-2.377005225268941, 52.805253077256303],
            [-2.37661060226902, 52.80524803832504],
            [-2.376452139585122, 52.8047631076643],
            [-2.377036742469286, 52.804438527104267],
            [-2.376845390955916, 52.804095735788124],
            [-2.376452594233779, 52.803614246230744],
            [-2.375062593013034, 52.803082874937793],
            [-2.375368304692658, 52.802928187036159],
            [-2.374819848221611, 52.80245347723654],
            [-2.373674225050471, 52.802046271367317],
            [-2.37387497445613, 52.801759772033549],
            [-2.372393512343155, 52.80127630217045],
            [-2.37216411883358, 52.800641463275078],
            [-2.371598447138775, 52.800749313350089],
            [-2.371297909791557, 52.800292688120692],
            [-2.370919899730678, 52.800317243725175],
            [-2.371393114165781, 52.799455465713237],
            [-2.371810230572692, 52.799495510540908],
            [-2.371328102182245, 52.79913654129691],
            [-2.372124643209788, 52.798277343178633],
            [-2.37181864028767, 52.797700276630273],
            [-2.37259954093941, 52.797444321193289],
            [-2.372908114809751, 52.79762763655188],
            [-2.373302631780286, 52.796935999900043],
            [-2.373069863693889, 52.796598726222761],
            [-2.371708219541158, 52.796578730153236],
            [-2.371415276161855, 52.795269874039796],
            [-2.373131541899795, 52.793929541485369],
            [-2.374656521808139, 52.793810566456102],
            [-2.374515348668217, 52.794122050104669],
            [-2.375326076259149, 52.793894748762689],
            [-2.375764836358861, 52.793354886435878],
            [-2.377502384305208, 52.794153032168516],
            [-2.378963545694439, 52.794028811204555],
            [-2.378626299253667, 52.794300473282583],
            [-2.379079350142834, 52.794555227580041],
            [-2.379875414244998, 52.79417871581331],
            [-2.38065122735977, 52.794711105254926],
            [-2.382171558218517, 52.79321575125217],
            [-2.381755812567268, 52.792987857212758],
            [-2.382111368186425, 52.792778154930716],
            [-2.380902033585417, 52.792355941480544],
            [-2.380876213240938, 52.791098388472228],
            [-2.381683471120961, 52.790654406772397],
            [-2.383590262711433, 52.791137285120357],
            [-2.385623869052423, 52.790696495081619],
            [-2.384746507144024, 52.790417972288409],
            [-2.38500094045606, 52.789508304163746],
            [-2.38405620890955, 52.788985479467485],
            [-2.383409801166688, 52.788997459668998],
            [-2.383269203076635, 52.788522367491019],
            [-2.381099003381654, 52.788764887674127],
            [-2.380735792796674, 52.787755630055031],
            [-2.379902147316346, 52.787548847390418],
            [-2.379601740051069, 52.78710662502759],
            [-2.380218186192466, 52.786552692205099],
            [-2.379491296875805, 52.786342867437142],
            [-2.379437160364771, 52.785915138425359],
            [-2.378813138528561, 52.785938708427167],
            [-2.378556818476977, 52.785107095224234],
            [-2.378935226730196, 52.784120632436995],
            [-2.377298798761259, 52.783656596256165],
            [-2.376278121139105, 52.783756926594563],
            [-2.371930617868157, 52.784234503081841],
            [-2.370107324657835, 52.784105364829969],
            [-2.368403809841678, 52.783586579261055],
            [-2.367192901467103, 52.783811477995755],
            [-2.365859198777813, 52.78318633250143],
            [-2.36723744271693, 52.781706887614924],
            [-2.36682787540899, 52.779547967009499],
            [-2.367237290225878, 52.778344796770625],
            [-2.368868009626528, 52.777294249899867],
            [-2.368093531006892, 52.776866057141561],
            [-2.366350736989268, 52.777105183180254],
            [-2.365069691843436, 52.776723482575804],
            [-2.363243614559511, 52.775152323203784],
            [-2.360901063501587, 52.774563469319595],
            [-2.359859498022613, 52.773907700749028],
            [-2.359033440010743, 52.773858065666637],
            [-2.35758652255057, 52.772209258860741],
            [-2.354393093449745, 52.771582386735716],
            [-2.352731989612754, 52.76889316926858],
            [-2.349968275274947, 52.766182915800933],
            [-2.348698028514818, 52.766166881748816],
            [-2.348246497981289, 52.766414523059062],
            [-2.345233997652097, 52.766293884305767],
            [-2.343968595028386, 52.765012953745497],
            [-2.344027946065771, 52.763894479440275],
            [-2.34244277759183, 52.762075984551913],
            [-2.334943225648912, 52.758847681781852],
            [-2.330135499235923, 52.757964009635728],
            [-2.33046340134596, 52.757629582778996],
            [-2.329931949620597, 52.757499815419948],
            [-2.33005986214426, 52.756192375158712],
            [-2.32931880030038, 52.755968797968769],
            [-2.32928264597084, 52.75471215556901],
            [-2.32905543144701, 52.754446695040592],
            [-2.328654555153746, 52.754533208212365],
            [-2.32974453095953, 52.753679766891878],
            [-2.330411221760789, 52.753674314523067],
            [-2.330856603367288, 52.753010540115312],
            [-2.330477016811869, 52.751602032628966],
            [-2.326819056974899, 52.751206744923671],
            [-2.324634471099978, 52.751498617065735],
            [-2.323706682540748, 52.75065433591265],
            [-2.321634247255959, 52.749879682008611],
            [-2.321326773225608, 52.748160808456461],
            [-2.320774996434653, 52.747866545706408],
            [-2.320998067468119, 52.747177339424383],
            [-2.320126897167276, 52.747567146835785],
            [-2.319577927217042, 52.74745176369872],
            [-2.318619032020563, 52.746785520086654],
            [-2.317799288131686, 52.746712208429983],
            [-2.316922510052326, 52.745924371412109],
            [-2.315209712847252, 52.745466874248002],
            [-2.31599154746774, 52.745218477583165],
            [-2.3157022680744, 52.744955853536801],
            [-2.316085841213863, 52.74453411837095],
            [-2.312209566256153, 52.740852367568024],
            [-2.31301073729317, 52.739384950077351],
            [-2.313049010260865, 52.737906060045965],
            [-2.314071041318984, 52.737299254750369],
            [-2.315682192498946, 52.738288324780711],
            [-2.3167195534185, 52.737961032411143],
            [-2.316263579307813, 52.73717116525863],
            [-2.31555252516346, 52.737151485661641],
            [-2.315006063877995, 52.736337582579601],
            [-2.315636775038272, 52.735079157842812],
            [-2.315578312016158, 52.732932595617235],
            [-2.317575842663689, 52.731678602637814],
            [-2.319078985003005, 52.731279920946122],
            [-2.319691407364436, 52.730583373389138],
            [-2.321457541948184, 52.730513870425739],
            [-2.323166944913599, 52.731183442040404],
            [-2.325172408767489, 52.73123369168998],
            [-2.328667892398898, 52.730334975902963],
            [-2.330072420229634, 52.729447392970421],
            [-2.333607020984726, 52.730599852705353],
            [-2.335856757972775, 52.730258185464827],
            [-2.335512957759394, 52.730611552275647],
            [-2.336386040371845, 52.733030879321099],
            [-2.34361892049348, 52.731152873953896],
            [-2.352455738108367, 52.730848244222287],
            [-2.3579303569231, 52.731197713845468],
            [-2.362949257525167, 52.733722017424519],
            [-2.367296951208853, 52.733148570009234],
            [-2.368455032289609, 52.735253939966164],
            [-2.371875764434245, 52.738512779577128],
            [-2.370046429581752, 52.739018327506173],
            [-2.368832661350214, 52.73998489812103],
            [-2.368823249626976, 52.740448791278382],
            [-2.370967642603254, 52.74023264119991],
            [-2.375073833372244, 52.740794154646856],
            [-2.376811822788289, 52.739313446805497],
            [-2.379071646661004, 52.735343617822352],
            [-2.379487890778964, 52.732029609848702],
            [-2.378864787844875, 52.730619333065661],
            [-2.379667556650733, 52.729614420594672],
            [-2.379497498581082, 52.728618914287196],
            [-2.380495280758527, 52.72688880678497],
            [-2.381007389671883, 52.724317024753738],
            [-2.382598584282788, 52.722063589598953],
            [-2.385157413042681, 52.720096457626269],
            [-2.387152532832914, 52.717691518609946],
            [-2.392760980603625, 52.714305539077628],
            [-2.393106294206131, 52.713527687739123],
            [-2.392760705307823, 52.711474696673179],
            [-2.401034284497482, 52.704618433675641],
            [-2.402503112415237, 52.703890683556217],
            [-2.402421372930578, 52.703049527596086],
            [-2.403579383601257, 52.701363620416167],
            [-2.403594078277398, 52.69878712789324],
            [-2.401526981631323, 52.694934879235554],
            [-2.405242988140829, 52.694843118318303],
            [-2.403549468249881, 52.690425071192891],
            [-2.402835244182931, 52.689822495303268],
            [-2.401394321027074, 52.689347336165625],
            [-2.40232680037738, 52.686658950255591],
            [-2.401212461803927, 52.684520483320448],
            [-2.401965911431872, 52.682480862021237],
            [-2.403423920878408, 52.682747395070265],
            [-2.405439721077371, 52.681588032772588],
            [-2.40689116793124, 52.681149752413646],
            [-2.40777049557754, 52.679637356345204],
            [-2.408031997211641, 52.678970318161419],
            [-2.409940820459084, 52.671632611322615],
            [-2.419030339855119, 52.662877167581065],
            [-2.417575356360631, 52.650309240428832],
            [-2.419925764930901, 52.647551860328988],
            [-2.418755979159634, 52.6457823214784],
            [-2.416468411735966, 52.644886017555713],
            [-2.418467631254019, 52.639886949255249],
            [-2.418229568150735, 52.639407736189455],
            [-2.41891973928258, 52.639413387467677],
            [-2.419452016304301, 52.636200353904627],
            [-2.418296840853476, 52.633760117867411],
            [-2.420661743974398, 52.633379563051477],
            [-2.424065761100306, 52.632127729534361],
            [-2.428700513801314, 52.62968649340656],
            [-2.436636471002221, 52.627079221299397],
            [-2.43559420190997, 52.622246545093738],
            [-2.437061574549392, 52.61933473171063],
            [-2.434044565980727, 52.618210416843006],
            [-2.435771264675681, 52.615536795600057],
            [-2.43650500169369, 52.615653657298516],
            [-2.437679015995454, 52.61534007330205],
            [-2.438042276385296, 52.61459347375316],
            [-2.442004462634641, 52.615724051261473],
            [-2.442370662392372, 52.615275889480053],
            [-2.443750277502967, 52.615289605337274],
            [-2.444252673197078, 52.615020723576563],
            [-2.451019347667176, 52.616963911267725],
            [-2.454827225841774, 52.618544139042967],
            [-2.457817234995526, 52.620566112753835],
            [-2.464564862954288, 52.623780659929245],
            [-2.466355464352889, 52.624075678987403],
            [-2.46971803999631, 52.622453210347665],
            [-2.470851919827545, 52.622534110177924],
            [-2.472030014481069, 52.621919010811716],
            [-2.471997856132855, 52.624009271997977],
            [-2.47239218405506, 52.623588773975079],
            [-2.474521472602314, 52.623779834099224],
            [-2.475021357909025, 52.623151239362819],
            [-2.476046323676326, 52.622994296149614],
            [-2.478640608400637, 52.623427036040795],
            [-2.478986719324475, 52.624008176853188],
            [-2.480288080635139, 52.62399930962291],
            [-2.48180591276379, 52.623524773723915],
            [-2.481321782111235, 52.624772731779046],
            [-2.483620877446536, 52.625081603761835],
            [-2.486465846412896, 52.623857223222672],
            [-2.486149118617611, 52.624602881280197],
            [-2.486454725190835, 52.625118540935517],
            [-2.4893709784669, 52.626317453672463],
            [-2.492063408549177, 52.628094371307981],
            [-2.496210607075414, 52.629022794732414],
            [-2.501130612579859, 52.628910380122186],
            [-2.501775836266196, 52.62900383897005],
            [-2.501286985504469, 52.629406855330778],
            [-2.500861641283356, 52.629679248668715],
            [-2.505626478752486, 52.632376622101766],
            [-2.508659929495918, 52.63505698040143],
            [-2.510080449717301, 52.635736793374207],
            [-2.509888902440252, 52.636037877987455],
            [-2.510731258561936, 52.636552960462261],
            [-2.510078331298712, 52.638348342713932],
            [-2.512738550773173, 52.640647236220723],
            [-2.514912792586622, 52.641056728317878],
            [-2.516666528707687, 52.642021787832164],
            [-2.517831785931774, 52.643206949067306],
            [-2.519171284989033, 52.643500445461164],
            [-2.523807818053614, 52.646247991429874],
            [-2.527099688843313, 52.647137761610409],
            [-2.530055843579738, 52.649466412517747],
            [-2.535056267570005, 52.650553271144922],
            [-2.541677762377336, 52.652655556155182],
            [-2.544984114253129, 52.65285884216663],
            [-2.546296435561087, 52.653660980097719],
            [-2.54674763059456, 52.654397859878806],
            [-2.548460272056096, 52.654697393559047],
            [-2.548319484163366, 52.655259907773555],
            [-2.546322521873071, 52.656463878774893],
            [-2.545493486803639, 52.658388818404198],
            [-2.5466913066882, 52.658057865912227],
            [-2.554211485169337, 52.660007867750636],
            [-2.554221975720071, 52.661537881338134],
            [-2.554787756879554, 52.662666148653138],
            [-2.553741521057455, 52.664466304428394],
            [-2.552963929227751, 52.664830426988622],
            [-2.551612584301572, 52.664840322890129],
            [-2.549415565399376, 52.666272716239362],
            [-2.555650098108041, 52.67108283743751],
            [-2.562688507297478, 52.67412052578284],
            [-2.568178206730845, 52.676957569771318],
            [-2.57434439037426, 52.679103364722067],
            [-2.575300507001979, 52.680049844078951],
            [-2.576316780246696, 52.67972037445012],
            [-2.576997892898798, 52.680110811568426],
            [-2.577192386379822, 52.680726562632316],
            [-2.578842068510864, 52.68098101623935],
            [-2.581951896870962, 52.68213804930361],
            [-2.582736197267526, 52.682050589750709],
            [-2.586795214635624, 52.684150345833622],
            [-2.58856475139795, 52.683733433810175],
            [-2.59183194869026, 52.686210922558622],
            [-2.594895138734486, 52.687802055284337],
            [-2.596673907564452, 52.690552071334601],
            [-2.597739945866124, 52.689960566004032],
            [-2.60125474266308, 52.689389014484206],
            [-2.603334228650779, 52.68934248590439],
            [-2.608382423480795, 52.691808639709464],
            [-2.608279216486804, 52.692898729336171],
            [-2.608885001986828, 52.694113731539375],
            [-2.612719118429405, 52.696783707031365],
            [-2.61716286344297, 52.698368017885883],
            [-2.620214589619673, 52.69798170779454],
            [-2.622552540124583, 52.699016756682191],
            [-2.623381342875575, 52.700686293798057],
            [-2.623491442304057, 52.703011365494177],
            [-2.624325773020802, 52.703401623641071],
            [-2.619331213900502, 52.704463448942036],
            [-2.613944246757892, 52.704368317635172],
            [-2.610885446338337, 52.70470775458778],
            [-2.609208763985759, 52.705673793316294],
            [-2.60542466848359, 52.706764761790403],
            [-2.605993709274253, 52.707562839793738],
            [-2.60413186000479, 52.708426370386938],
            [-2.60397694701316, 52.708891030554241],
            [-2.604953425282804, 52.709717601408798],
            [-2.602255488063344, 52.712323981461211],
            [-2.602076758799503, 52.71299642263196],
            [-2.600899642099874, 52.71372786764713],
            [-2.599505847648498, 52.714098110817204],
            [-2.598761907019854, 52.713821391744126],
            [-2.597845357869328, 52.713911420812472],
            [-2.596940937098516, 52.714456262012746],
            [-2.596423617969461, 52.715382111368299],
            [-2.597064120705422, 52.715346523642147],
            [-2.597729123242456, 52.717319113829284],
            [-2.59742188495777, 52.718235816722043],
            [-2.598936564345804, 52.718357624747988],
            [-2.601967370573103, 52.719671851602882],
            [-2.603270426735135, 52.719151913624572],
            [-2.604611527826097, 52.719136989747007],
            [-2.604661485948879, 52.719430698921926],
            [-2.606235104736826, 52.719527840889434],
            [-2.60746933623601, 52.720024946639526],
            [-2.609981968866567, 52.719404337559119],
            [-2.613437535623276, 52.719518653548377],
            [-2.615124533552854, 52.71893906309662],
            [-2.618920395076745, 52.721862541309051],
            [-2.619919147674711, 52.721296363207628],
            [-2.621873040389928, 52.720850126724933],
            [-2.623218324469499, 52.720087024893189],
            [-2.62340314647776, 52.719551163916208],
            [-2.62428609610062, 52.719488083686947],
            [-2.626190829242482, 52.720579279060033],
            [-2.62698932274735, 52.721542353359894],
            [-2.627647973221499, 52.721425597030056],
            [-2.627258815977748, 52.722782409434387],
            [-2.627853311870973, 52.724691377418608],
            [-2.630235903461231, 52.726245643465845],
            [-2.632026542555683, 52.72678897810632],
            [-2.63603393702769, 52.726520326951146],
            [-2.641467168751815, 52.727559929764944],
            [-2.644610225313925, 52.729225757799547],
            [-2.654034600416217, 52.730641261431145],
            [-2.658279570473499, 52.730695077618279],
            [-2.658868387447078, 52.731639324342055],
            [-2.660260274816503, 52.731628883539592],
            [-2.660398401043818, 52.73332088050401],
            [-2.660964840118402, 52.73415107169339],
            [-2.661430012451032, 52.73484518127443],
            [-2.664123513743509, 52.736276570507457],
            [-2.664392393543882, 52.738277075893386],
            [-2.663734728378473, 52.740028361630394],
            [-2.6626976479577, 52.740499830486606],
            [-2.662030648011984, 52.741446578335243],
            [-2.660697077132421, 52.742199264830035],
            [-2.659392747740801, 52.743518125500927],
            [-2.659583081542927, 52.743860473869539],
            [-2.662708655643295, 52.743763036408687],
            [-2.663777947810605, 52.744236206667885],
            [-2.664138161625732, 52.744642322538262],
            [-2.663875635755782, 52.745302738790997],
            [-2.665512789525197, 52.745902164366498],
            [-2.667364761557801, 52.750182543851757],
            [-2.6656512719957, 52.751487592185498],
            [-2.664729603217505, 52.751187112733056],
            [-2.66336414192373, 52.749955079409915],
            [-2.661912495185682, 52.750557411436091],
            [-2.663728699710026, 52.75269040419991],
            [-2.661658115825343, 52.754692295804126],
            [-2.662131483053304, 52.755325225115712],
            [-2.660956737854864, 52.756417734273718],
            [-2.661565528272536, 52.756697514728927],
            [-2.661034444610028, 52.757439429366968],
            [-2.662443891102306, 52.758238838426401],
            [-2.66341808139401, 52.760425978063097],
            [-2.658120593417627, 52.762307371967701],
            [-2.656544634813748, 52.761278698025066],
            [-2.654735884704394, 52.762408813027193],
            [-2.648981323500301, 52.763891379780851],
            [-2.649465218690656, 52.764838939552327],
            [-2.643131322497755, 52.767069635864708],
            [-2.641235403941897, 52.766544118187788],
            [-2.640464457645052, 52.765112633831059],
            [-2.639133805020681, 52.764722469392396],
            [-2.638158874337322, 52.764840090726011],
            [-2.637918709657808, 52.765138941008431],
            [-2.637310910040019, 52.764924658945461],
            [-2.637491335693606, 52.764289917315651],
            [-2.636521092515763, 52.763915764958753],
            [-2.63582052880579, 52.762622313416927],
            [-2.636704236755716, 52.761943346305451],
            [-2.635258225992344, 52.759015072985711],
            [-2.632333813012808, 52.759575478001388],
            [-2.632515550017855, 52.760459092895005],
            [-2.628589594552521, 52.762895493780192],
            [-2.628706717985765, 52.765264549468782],
            [-2.629247846950011, 52.766099516262415],
            [-2.627538320720426, 52.768626579523229],
            [-2.626384292329679, 52.769388710970844],
            [-2.625617997283935, 52.771971888544492],
            [-2.622650710834543, 52.77344023246026],
            [-2.62100601911784, 52.776507134353309],
            [-2.619452511489248, 52.776303102924338],
            [-2.617603947605012, 52.775256460300604],
            [-2.615133298937986, 52.775184797872967],
            [-2.60703172976516, 52.777712177128876],
            [-2.605854445321901, 52.777803598526674],
            [-2.603027874608048, 52.777088045506453],
            [-2.598880810169607, 52.778441322893208],
            [-2.597751831790428, 52.778382293000689],
            [-2.592247123238958, 52.776834925776747],
            [-2.591252010482087, 52.778117317549608],
            [-2.5903194165293, 52.778108478670795],
            [-2.589840864482164, 52.779444920584034],
            [-2.592698716917527, 52.78170325099434],
            [-2.593283396340444, 52.783159343861605],
            [-2.594228324400238, 52.784174036730938],
            [-2.593375816387396, 52.788316226482195],
            [-2.593699515171991, 52.790090954576343],
            [-2.594371962867924, 52.791012618051212],
            [-2.593363906527444, 52.791798862296908],
            [-2.592567605462051, 52.793546827205802],
            [-2.593691747160644, 52.795726579495245],
            [-2.598009867190234, 52.797954088113165],
            [-2.595348872450951, 52.797611484736649],
            [-2.591841524707435, 52.798504622812523],
            [-2.592511241058037, 52.799218649609919],
            [-2.590635448749504, 52.799579497362252],
            [-2.590288317886431, 52.800669865408281],
            [-2.589010903779065, 52.801631808922998],
            [-2.586564479968364, 52.802695713048465],
            [-2.582788406616953, 52.805610769114942],
            [-2.582011129116207, 52.805612787108082],
            [-2.581245097460663, 52.806012983499933],
            [-2.577175387294715, 52.805400885912867],
            [-2.57701492486175, 52.806389621619417],
            [-2.573838879331998, 52.806275577181871],
            [-2.569423062155632, 52.805053596181537],
            [-2.571069780996987, 52.804500012882933],
            [-2.570539724016034, 52.802989616528642],
            [-2.567752166526899, 52.801819961360088],
            [-2.565815645485949, 52.799698682140168],
            [-2.562766617134585, 52.799502830109375],
            [-2.561317314219097, 52.79902515833254],
            [-2.5594029045191, 52.799169944597246],
            [-2.557010039163185, 52.800756179826969],
            [-2.556152219608124, 52.800600190162029],
            [-2.55497497952068, 52.800868199454435],
            [-2.554900739568176, 52.802143269191497],
            [-2.552945222006504, 52.802918312559939],
            [-2.551397979131051, 52.802320516058828],
            [-2.551646929517751, 52.80194988697265],
            [-2.551128499772134, 52.801889369684204],
            [-2.550992755684081, 52.801359615163037],
            [-2.549118511858306, 52.801171434245347],
            [-2.548595219406217, 52.800252423660794],
            [-2.54819358397785, 52.800277653341581],
            [-2.548376649109482, 52.799738331379544],
            [-2.547304370034152, 52.797382619539498],
            [-2.544063341013662, 52.797112559579851],
            [-2.543923419334934, 52.796477637783703],
            [-2.542323818575023, 52.795815238093702],
            [-2.540028350726618, 52.793806650382791],
            [-2.537896051804698, 52.79394758715101],
            [-2.536295496323034, 52.794879862748871],
            [-2.535493063106791, 52.794870902680827],
            [-2.533812293237371, 52.79410447569925],
            [-2.53345229025288, 52.793407604718688],
            [-2.534081820097439, 52.792379060179094],
            [-2.534632301072095, 52.792283088516626],
            [-2.535004769550594, 52.79157842340058],
            [-2.535851775114335, 52.791357950822274],
            [-2.535947160711722, 52.790793872683416],
            [-2.537482764114191, 52.789896954625377],
            [-2.537322469327323, 52.78928369184441],
            [-2.53824298130056, 52.788776100988315],
            [-2.53785261862114, 52.787543600662509],
            [-2.538630594331817, 52.787024067422713],
            [-2.538890114471451, 52.786067294501308],
            [-2.538280283230499, 52.785312240713246],
            [-2.537516968176509, 52.785094558445131],
            [-2.536373142829291, 52.785884528601024],
            [-2.534710993857675, 52.785883943189518],
            [-2.533672807080001, 52.786709369148191],
            [-2.533408090106035, 52.786160396809791],
            [-2.532342270145868, 52.785696827546374],
            [-2.529417558662215, 52.787097911918032],
            [-2.529162112858621, 52.788407936248902],
            [-2.528598803146672, 52.788665752444281],
            [-2.528785695091821, 52.789037088339306],
            [-2.527284853147761, 52.789264913898883],
            [-2.528267070983428, 52.78980171641301],
            [-2.528168472984695, 52.790108700424767],
            [-2.526543156197703, 52.790101543566919],
            [-2.526515948049236, 52.790427087350416],
            [-2.527114481693529, 52.790626694559776],
            [-2.5264155840736, 52.792553569390165],
            [-2.525353152531025, 52.793608262935038],
            [-2.525547384973202, 52.795080794501686],
            [-2.524495417966989, 52.795656288979721],
            [-2.524160486976463, 52.796662804097828],
            [-2.522869856369324, 52.796758396100351],
            [-2.523256275107784, 52.79769070882103],
            [-2.522292025023737, 52.798167810028175],
            [-2.521991259064079, 52.798936843366121],
            [-2.521127613689775, 52.799024243790882],
            [-2.518001972797544, 52.79585562626675],
            [-2.516142937681947, 52.79554190360907],
            [-2.514508683727986, 52.796176475928846],
            [-2.51172400291058, 52.798496144723352],
            [-2.509969609135492, 52.798385038112272],
            [-2.507310197882604, 52.796718988730902],
            [-2.503029969310806, 52.796589789123608],
            [-2.501600130251141, 52.797350967900677],
            [-2.501255893592959, 52.799271695024999],
            [-2.498800815353181, 52.800402143719822],
            [-2.498951775045049, 52.800761990161078],
            [-2.492406234022145, 52.802334647356197],
            [-2.490248389611947, 52.802233013795153],
            [-2.490003941417151, 52.804501188906507],
            [-2.486508393042623, 52.805231145034135],
            [-2.488574678433468, 52.802050239840511],
            [-2.488876228584043, 52.800900132320905],
            [-2.488375338256975, 52.800410466975812],
            [-2.486769839939703, 52.799821058840145],
            [-2.483582965666358, 52.800002198940923],
            [-2.478479526131672, 52.801513363206986],
            [-2.47712253953119, 52.799364033702531],
            [-2.478730033783466, 52.797853604054175],
            [-2.48440240924227, 52.787791026685348],
            [-2.48691532840732, 52.785364338372524],
            [-2.489957784331875, 52.783907182713804],
            [-2.489403614007589, 52.783277503246531],
            [-2.489651683570608, 52.782923188691569],
            [-2.488064419308139, 52.782847027324543],
            [-2.487122793283515, 52.782434681056721],
            [-2.488412802925403, 52.781520528449278],
            [-2.488214176929342, 52.780597216925315],
            [-2.485422999928438, 52.780239202643628],
            [-2.482619919190837, 52.78067674943383],
            [-2.479818373872796, 52.78220196301703],
            [-2.476359099534335, 52.780947478679707],
            [-2.475558185400936, 52.781188916976362],
            [-2.472633857137318, 52.78044008911278],
            [-2.472204368193392, 52.780892178000705],
            [-2.473509985902864, 52.781800313432385],
            [-2.47346351636992, 52.782436062165878],
            [-2.472567061503719, 52.782621227192244],
            [-2.47095255972039, 52.784274539881778],
            [-2.46751626592532, 52.783633695020086],
            [-2.464320433987222, 52.784401374561689],
            [-2.462918300179, 52.784446410025495],
            [-2.461840245103669, 52.783872583744525],
            [-2.460659013818066, 52.78391493415495],
            [-2.460292115685122, 52.783429123968659],
            [-2.458881353424752, 52.783215245606165],
            [-2.457606657780574, 52.783391871886316],
            [-2.456270851719976, 52.784105396914839],
            [-2.45695980470989, 52.784341866810564],
            [-2.457426972302496, 52.785625574140639],
            [-2.456839064049182, 52.786402740951011],
            [-2.457459775006313, 52.787621131318204],
            [-2.458268439802501, 52.788096254172387],
            [-2.458774360661548, 52.789814001617138],
            [-2.458204258348071, 52.790031056067164],
            [-2.459564252316988, 52.791175558858676],
            [-2.461753719041174, 52.791521243471927],
            [-2.462893689754421, 52.7920426890282],
            [-2.463471947487388, 52.792731727875491],
            [-2.462972147486106, 52.793984127407946],
            [-2.463199604539957, 52.794455191043248],
            [-2.461361846446295, 52.794987343960955],
            [-2.461365340702668, 52.795457484070099],
            [-2.460477027617202, 52.795601173207771],
            [-2.460926851338834, 52.795924847806944],
            [-2.459136810718849, 52.796490041884091],
            [-2.459372716192318, 52.796780389922269],
            [-2.458334490499632, 52.797197925797313],
            [-2.458932548210356, 52.797232470394455],
            [-2.457747669763439, 52.798515363040757],
            [-2.461400222482343, 52.800152590667196],
            [-2.461179786610648, 52.803849049569422],
            [-2.461638516499451, 52.803744784372775],
            [-2.466412829866086, 52.808682040582035],
            [-2.468634606425319, 52.811530158049962],
            [-2.470774227054066, 52.819240993198463],
            [-2.472987092130653, 52.820509602379076],
            [-2.471703930333833, 52.821643794751566],
            [-2.470255091628011, 52.821734047905281],
            [-2.46910266738652, 52.823029504739239],
            [-2.46270815417237, 52.821912948142163],
            [-2.459650555114615, 52.821991356312772],
            [-2.456139226553242, 52.821680387293569],
            [-2.446463623006774, 52.821797200697979],
            [-2.443334849641476, 52.82144575789458],
            [-2.440474272796326, 52.820757029577692],
            [-2.438665289145731, 52.824282224874878],
            [-2.436503640461494, 52.823600703094137],
            [-2.434882930897538, 52.824623367858187],
            [-2.432661581348634, 52.82703887752438],
            [-2.43021029106791, 52.828139116437988],
            [-2.42757810536494, 52.828363480890054],
            [-2.42727105654418, 52.827927697709363],
            [-2.428087150639123, 52.82745550420286],
            [-2.428225268020756, 52.825051214350054],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000021",
        LAD13CDO: "00GL",
        LAD13NM: "Stoke-on-Trent",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.188967690637145, 53.090334067486026],
            [-2.188911277329212, 53.090068081551379],
            [-2.184950158721336, 53.090116559626125],
            [-2.181788962150584, 53.089362761591083],
            [-2.181206904594221, 53.08977984300433],
            [-2.179089097637087, 53.091446924046359],
            [-2.178532484956023, 53.091531359485991],
            [-2.177626124653062, 53.091172257081091],
            [-2.175552865900364, 53.089897995142366],
            [-2.174879268670723, 53.089851346295745],
            [-2.174270133704644, 53.090236971909434],
            [-2.171700182290353, 53.089429901207339],
            [-2.171466296762139, 53.089189332615504],
            [-2.172104101809978, 53.088876491002409],
            [-2.169298277704126, 53.086907426517897],
            [-2.168627745307275, 53.085341589460988],
            [-2.168994569967472, 53.085226907500186],
            [-2.167346032911301, 53.083231874186957],
            [-2.166305316823173, 53.082418028481818],
            [-2.165511104628636, 53.079309824325485],
            [-2.163671336984883, 53.076776560030602],
            [-2.163863316447165, 53.075839635535196],
            [-2.162880323827777, 53.07325213752047],
            [-2.16189812143941, 53.073227411649057],
            [-2.161718604427788, 53.072720672954581],
            [-2.162612223213091, 53.072214267880327],
            [-2.162193928075289, 53.07171504762546],
            [-2.16146305772227, 53.071838294389359],
            [-2.161369564284153, 53.071184017440075],
            [-2.161769250913149, 53.071103471227659],
            [-2.161147617640808, 53.070102933946529],
            [-2.157502428432427, 53.070754136150839],
            [-2.156635581450804, 53.070421787818077],
            [-2.155352423306897, 53.070523251286986],
            [-2.15505911554942, 53.069890804170903],
            [-2.153828089614948, 53.069959822525142],
            [-2.153411176740234, 53.069394949296317],
            [-2.151664842031696, 53.070190027309017],
            [-2.151249059618177, 53.069514579571795],
            [-2.149491787070237, 53.070181095936199],
            [-2.149137213602692, 53.06993254446806],
            [-2.148023785891338, 53.070350130838946],
            [-2.146610658160325, 53.069104200867955],
            [-2.145031085215737, 53.068485894227088],
            [-2.143654121685333, 53.068643078322403],
            [-2.143032529396869, 53.069316210658492],
            [-2.142434089473574, 53.068420719743628],
            [-2.140462260422881, 53.068765551330564],
            [-2.139917302464952, 53.068235839529422],
            [-2.140513261598187, 53.067471964087311],
            [-2.139158407942244, 53.065228089262824],
            [-2.137408500220675, 53.06540002010037],
            [-2.134641690965875, 53.064892609613651],
            [-2.12784042839732, 53.065577901651892],
            [-2.125400543224561, 53.064562041422079],
            [-2.127367010984675, 53.059398420964008],
            [-2.127438259323196, 53.053721737666024],
            [-2.12399178447206, 53.052613436377115],
            [-2.121429477047343, 53.051272215532855],
            [-2.121749376892669, 53.0509752478699],
            [-2.119819696835624, 53.049618959072717],
            [-2.116584229988499, 53.048015829235872],
            [-2.117255777169579, 53.047572005339759],
            [-2.119584389749936, 53.047608335591399],
            [-2.117968189059866, 53.046803630567325],
            [-2.118209256971979, 53.046592146946061],
            [-2.123897700983676, 53.048427313826252],
            [-2.125690598913681, 53.04838048383197],
            [-2.127182913200555, 53.048080456635702],
            [-2.127097354114795, 53.047899867394548],
            [-2.129323920540101, 53.048221070619974],
            [-2.131779481991011, 53.04731676419253],
            [-2.129042947189494, 53.043556931875194],
            [-2.126509172869319, 53.040118628681569],
            [-2.126931875635142, 53.037798986807204],
            [-2.125952098583056, 53.037300235634568],
            [-2.127412515330009, 53.035924241585207],
            [-2.128184924555075, 53.036401631044711],
            [-2.129168268482804, 53.03559963500723],
            [-2.127021231410763, 53.033705247770037],
            [-2.125572687885784, 53.032991254937784],
            [-2.125021048057549, 53.03302419801966],
            [-2.123731451536283, 53.0315594235528],
            [-2.123706581749814, 53.030686605648071],
            [-2.124355953029096, 53.02991016572453],
            [-2.123251143876918, 53.027848313546947],
            [-2.122198357462155, 53.028324926878405],
            [-2.121293801307133, 53.027438627785493],
            [-2.120483917800847, 53.027872728604173],
            [-2.11967965269834, 53.027650611917622],
            [-2.118804055643784, 53.026428071181819],
            [-2.119538285025885, 53.026136086165074],
            [-2.122250691260872, 53.022588908311633],
            [-2.123738733728685, 53.021621034156794],
            [-2.122905816838919, 53.020725682520279],
            [-2.122417174959789, 53.020840349571948],
            [-2.120969561949975, 53.02027731593796],
            [-2.121130704169569, 53.01926587335393],
            [-2.122489859226099, 53.01913683262233],
            [-2.122494605005867, 53.018708045686608],
            [-2.121968390019519, 53.018706789791302],
            [-2.12150427500376, 53.017995325788419],
            [-2.120489552989969, 53.01867863616981],
            [-2.118521022567774, 53.018376785398594],
            [-2.116183987967911, 53.016841952984798],
            [-2.114898110366213, 53.016480043546942],
            [-2.114639030137342, 53.016026342312983],
            [-2.115960723113326, 53.014745003734099],
            [-2.114953776824292, 53.013874935045742],
            [-2.113743442352875, 53.013296301291049],
            [-2.113278125828775, 53.013756990824731],
            [-2.111926543741843, 53.013877829092628],
            [-2.10948138958948, 53.013599649000035],
            [-2.109909315549869, 53.013073388177908],
            [-2.10927967486016, 53.012815082266307],
            [-2.108948308278593, 53.013215404137156],
            [-2.108236784913514, 53.012993124468608],
            [-2.108361154902162, 53.012662210251548],
            [-2.103468028490348, 53.010840882072614],
            [-2.104020603913312, 53.010070028466515],
            [-2.102628918297841, 53.00895748444389],
            [-2.104108231131811, 53.007472085769493],
            [-2.103430476778986, 53.0069881630506],
            [-2.107253656955229, 53.004835460059645],
            [-2.107186341810152, 53.003532092358355],
            [-2.107866500321831, 53.002597501728005],
            [-2.104948786632925, 52.997676751364438],
            [-2.101272703571178, 52.995620527364856],
            [-2.098456726964564, 52.99754208695613],
            [-2.096988486200746, 52.997259238939414],
            [-2.096817898901212, 52.997596472269862],
            [-2.095709486913747, 52.997648610267767],
            [-2.095078659550885, 52.998740403012334],
            [-2.091653352053561, 52.99883119376738],
            [-2.091169579181906, 52.998351545051875],
            [-2.09013664756574, 52.998172551361357],
            [-2.089985215575852, 52.997719612186408],
            [-2.088460878837263, 52.996940499193052],
            [-2.088040269768145, 52.995990656717773],
            [-2.088599952028448, 52.995011318723655],
            [-2.087734306906325, 52.993529647342299],
            [-2.089219882786044, 52.991478107018366],
            [-2.088765276132822, 52.990639757673485],
            [-2.088292852723599, 52.990550218630233],
            [-2.088031763887816, 52.989629021594432],
            [-2.088244365475215, 52.989422112321051],
            [-2.090020280766614, 52.99024328740019],
            [-2.090231299739614, 52.989995025555146],
            [-2.089761601788901, 52.989781439540955],
            [-2.09030436330162, 52.988603444285658],
            [-2.091522025469854, 52.988193502660437],
            [-2.091772129692146, 52.985317668190127],
            [-2.089825744964018, 52.984811269607306],
            [-2.089194436341431, 52.984209470468031],
            [-2.089912764764352, 52.983682161203049],
            [-2.084694765410825, 52.981221167209235],
            [-2.084437378558357, 52.981363380136528],
            [-2.083363816162074, 52.979972612476871],
            [-2.084938071949394, 52.978464905068812],
            [-2.08225174888672, 52.977095946678993],
            [-2.080999307072662, 52.977899544977411],
            [-2.079655103611144, 52.975811365592449],
            [-2.080777323438799, 52.97546811726793],
            [-2.079240153808525, 52.974482140443733],
            [-2.079459664360406, 52.974001070931031],
            [-2.082594495162736, 52.974874476124135],
            [-2.084211999583203, 52.975008175906687],
            [-2.085875359187314, 52.973483311915984],
            [-2.087877772413212, 52.973987936594838],
            [-2.088129123777037, 52.973834034542953],
            [-2.08446165293754, 52.970169107170392],
            [-2.081448750856982, 52.968256513678092],
            [-2.081957852443249, 52.968194137075905],
            [-2.081219856710177, 52.966887613156999],
            [-2.084507517358969, 52.966212920744269],
            [-2.084432953145958, 52.96538956142119],
            [-2.08681129371608, 52.96497164225066],
            [-2.099994037165845, 52.960727330460088],
            [-2.100741902106168, 52.960960417277839],
            [-2.103009032438887, 52.963394549521446],
            [-2.106114530090716, 52.962141409838772],
            [-2.108729269253534, 52.962346692333682],
            [-2.109597196157758, 52.96234229769582],
            [-2.109143260401659, 52.959445488039506],
            [-2.110184204915185, 52.957842644292292],
            [-2.115118169774142, 52.956457211045752],
            [-2.117719146418548, 52.9560914927441],
            [-2.117974189396449, 52.956276417592541],
            [-2.121657734590222, 52.956069539730436],
            [-2.127922534607005, 52.964988355931645],
            [-2.128158985157952, 52.966403004394074],
            [-2.128955113099511, 52.969256218910708],
            [-2.130403676431708, 52.97115945047554],
            [-2.131357777894801, 52.971525157939126],
            [-2.133981083374563, 52.973792899801502],
            [-2.136651613261371, 52.973481524376716],
            [-2.139363956237252, 52.973680627078032],
            [-2.145711333001549, 52.976093797396615],
            [-2.148012312009508, 52.974393787070689],
            [-2.14902844654362, 52.972818504245467],
            [-2.15149312061387, 52.971176649679052],
            [-2.151482791105418, 52.968652490226873],
            [-2.14984212813681, 52.965263838168241],
            [-2.151371336483215, 52.964885248843522],
            [-2.150985978786045, 52.96410367701705],
            [-2.152498860944189, 52.96375385938763],
            [-2.153772517946358, 52.963542766817255],
            [-2.154169776865043, 52.964309032562582],
            [-2.159500099788114, 52.963602626407791],
            [-2.159397140614145, 52.963135324792447],
            [-2.158167404181118, 52.963132475468711],
            [-2.158325325185286, 52.961128565208419],
            [-2.159589075698335, 52.961085522042694],
            [-2.159856959156595, 52.959048200912726],
            [-2.161813098033004, 52.95787425222575],
            [-2.161365004414892, 52.957467649844652],
            [-2.162426863291386, 52.95641176343338],
            [-2.161854799496818, 52.954695599829932],
            [-2.162595470280805, 52.953348897463769],
            [-2.16689390082883, 52.952917738970577],
            [-2.17112071974571, 52.951712553950095],
            [-2.172214083519775, 52.954930021612221],
            [-2.175647997924006, 52.953821109951271],
            [-2.176899253930487, 52.953690705357772],
            [-2.176163017006374, 52.953093116567345],
            [-2.174345955516387, 52.949474030914672],
            [-2.174539260562291, 52.947215646070518],
            [-2.175727529447281, 52.947746056482245],
            [-2.17743441378271, 52.947713852300836],
            [-2.178607133503722, 52.948070764529682],
            [-2.181609986964117, 52.946190149556351],
            [-2.182925153127858, 52.947153576112811],
            [-2.18209965902714, 52.947265413414613],
            [-2.182093586365747, 52.947590833829153],
            [-2.184603029735936, 52.949400090039539],
            [-2.185155145394948, 52.950433893099785],
            [-2.185998444246124, 52.950989908229495],
            [-2.191254231226769, 52.950070045588042],
            [-2.191799519095047, 52.95154070644228],
            [-2.191774150350818, 52.953877952471906],
            [-2.193208111355839, 52.955675274396725],
            [-2.194291918420043, 52.958034080936194],
            [-2.194237298918714, 52.960790271401436],
            [-2.195376684672467, 52.964207008030094],
            [-2.198722519311275, 52.966580904234185],
            [-2.200438691587342, 52.96839743917085],
            [-2.202225084479164, 52.968640714698225],
            [-2.2046893497189, 52.969906669586329],
            [-2.205417964687147, 52.972205753727067],
            [-2.207169961999413, 52.971757743185961],
            [-2.208746420032207, 52.973821604276708],
            [-2.209846333186893, 52.977390182908174],
            [-2.212213377554033, 52.979638673263409],
            [-2.212514293425612, 52.980562226073665],
            [-2.208037515421355, 52.980619594151648],
            [-2.204341506780356, 52.981381105518103],
            [-2.206414310564053, 52.982849053733133],
            [-2.208419251437781, 52.983187141553309],
            [-2.208853782679578, 52.984031363441979],
            [-2.210346829052463, 52.984762247260349],
            [-2.21007998451487, 52.98502340654035],
            [-2.210673702442383, 52.985817000304735],
            [-2.210313971268017, 52.986888251871669],
            [-2.210677313554397, 52.987169868745326],
            [-2.211535183699412, 52.987141376147918],
            [-2.212495197958469, 52.988195893253845],
            [-2.211891040274648, 52.988629353215451],
            [-2.213196983345132, 52.992460009071316],
            [-2.212497158682565, 52.992535873595543],
            [-2.212779544799711, 52.993004603845016],
            [-2.212402732869446, 52.993329788174563],
            [-2.213446691664815, 52.994456059979107],
            [-2.213218229908616, 52.996466452881513],
            [-2.216103608511889, 53.001414278073263],
            [-2.21719091314834, 53.001619045976206],
            [-2.215959734115834, 53.002442002917157],
            [-2.217782407993599, 53.00397043080774],
            [-2.214537048645561, 53.00612205270275],
            [-2.211952501852918, 53.006942010567691],
            [-2.211496845148715, 53.007017432890613],
            [-2.212575998916346, 53.00826769782811],
            [-2.21223966055939, 53.008364483379474],
            [-2.213025875788497, 53.009738418632999],
            [-2.214586334920164, 53.009445259788741],
            [-2.215036084555879, 53.009975706232417],
            [-2.214421421931699, 53.010150308270674],
            [-2.215219373882123, 53.010269320028307],
            [-2.215651573584869, 53.01086361895387],
            [-2.215170550561594, 53.012436696057343],
            [-2.215560266373261, 53.013772677885505],
            [-2.214874503787903, 53.014047190792674],
            [-2.215487333540697, 53.015287483635433],
            [-2.2136557014021, 53.015946101686779],
            [-2.208330470488497, 53.016698963466901],
            [-2.20255344685042, 53.020007074661457],
            [-2.204629960505343, 53.022508791267555],
            [-2.205742818217255, 53.025787007601537],
            [-2.206573934293803, 53.031536815109654],
            [-2.208325114878154, 53.03444174110232],
            [-2.209238670571617, 53.035863114623723],
            [-2.211463826128854, 53.037724418650591],
            [-2.215011880789213, 53.040449845403479],
            [-2.219165730848691, 53.043720587938239],
            [-2.221990967872459, 53.049857603735383],
            [-2.227917828484996, 53.063713865728673],
            [-2.230542519034979, 53.063404972635482],
            [-2.232789930090673, 53.063702625669229],
            [-2.234139585080983, 53.064375954124294],
            [-2.23351077806865, 53.066201076603036],
            [-2.236641843739619, 53.067863258357086],
            [-2.237031581923008, 53.069533552034272],
            [-2.23850761398708, 53.071154027306257],
            [-2.238295436637775, 53.071920322199901],
            [-2.237614468985744, 53.072393611808209],
            [-2.238761405940762, 53.073322581906169],
            [-2.237441496431036, 53.07432031603566],
            [-2.236066168569337, 53.074749137146036],
            [-2.234894683579935, 53.07588587845126],
            [-2.234066226113161, 53.075605255749181],
            [-2.231419228767449, 53.078009620421533],
            [-2.230071280795152, 53.077728182338447],
            [-2.227387869595705, 53.079522169303964],
            [-2.226593738198032, 53.078960071385971],
            [-2.22484537073244, 53.079467677593044],
            [-2.221645294253483, 53.081007221086267],
            [-2.220716605375321, 53.082145166046402],
            [-2.216794469117096, 53.082669254358557],
            [-2.21664010255406, 53.084629149769654],
            [-2.215879031410171, 53.085293027499034],
            [-2.21584919448564, 53.085297576204717],
            [-2.214636312564745, 53.085481351139855],
            [-2.211517574929915, 53.086722935702845],
            [-2.210051926674364, 53.085602802160899],
            [-2.20713699840326, 53.087030883710696],
            [-2.205124410953434, 53.086725150366973],
            [-2.201370725036436, 53.086683027659738],
            [-2.201991968456419, 53.087987183250057],
            [-2.201544362829026, 53.08901269416085],
            [-2.199585939442151, 53.089451967135957],
            [-2.200387672937359, 53.091682597420046],
            [-2.198615458669454, 53.092702232001876],
            [-2.196778788424162, 53.092378984459707],
            [-2.196051676742592, 53.091742863022006],
            [-2.194053697119795, 53.09139646785502],
            [-2.192177986628013, 53.090669605135105],
            [-2.188967690637145, 53.090334067486026],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000022",
        LAD13CDO: "00HA",
        LAD13NM: "Bath and North East Somerset",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.342039063742268, 51.439405606582575],
            [-2.339399854378115, 51.438159854359697],
            [-2.336073691660449, 51.437383578327406],
            [-2.332529923897901, 51.43753036344458],
            [-2.331855680562688, 51.437795729099037],
            [-2.328617600756954, 51.437043269384766],
            [-2.325030715906421, 51.436993032890364],
            [-2.324158616184226, 51.434718777613725],
            [-2.323489373931118, 51.434471560440137],
            [-2.322151193871338, 51.432791121984721],
            [-2.321105508848238, 51.43260157291499],
            [-2.31815275706378, 51.431016318489313],
            [-2.317685843375942, 51.430271282818445],
            [-2.313851754472587, 51.428913096808692],
            [-2.314235697721945, 51.42764065454076],
            [-2.313162590535483, 51.426574426556087],
            [-2.313786557872988, 51.423179323149071],
            [-2.312618062330571, 51.422448732169343],
            [-2.309868255920082, 51.42308545221222],
            [-2.307652722423204, 51.424692698446812],
            [-2.30363263428569, 51.42509971886377],
            [-2.301469016082609, 51.425901964119753],
            [-2.301655892873838, 51.42632318926718],
            [-2.29882135052843, 51.4288004485785],
            [-2.294618427530402, 51.428803902661436],
            [-2.295352812283584, 51.426254732196895],
            [-2.292987088647933, 51.426073648643744],
            [-2.290719269619694, 51.425452584937105],
            [-2.287258786508611, 51.424078214637319],
            [-2.286499523080991, 51.423408401431665],
            [-2.284745022953395, 51.423427066813836],
            [-2.282395463441889, 51.421406047313823],
            [-2.280733343484041, 51.418887896480136],
            [-2.280502072727265, 51.417756406469856],
            [-2.278543589103623, 51.415884530854555],
            [-2.279774028649468, 51.41439618238585],
            [-2.27937236527932, 51.414081535434022],
            [-2.280262387672249, 51.41360375206213],
            [-2.279668043022072, 51.413523349208184],
            [-2.280533811411767, 51.413313571964004],
            [-2.280818783280306, 51.412888484401329],
            [-2.282364943256756, 51.412726513969176],
            [-2.282883385543886, 51.412391673547667],
            [-2.282696399908866, 51.411915569031088],
            [-2.282111408872568, 51.411950248764505],
            [-2.28237861235799, 51.411212291551031],
            [-2.281696063487884, 51.410812908930012],
            [-2.282050779231491, 51.410277053371956],
            [-2.284089892764255, 51.409400835624623],
            [-2.283975468187929, 51.409033355558265],
            [-2.284751467254298, 51.408739243250935],
            [-2.285392233676114, 51.407971596525179],
            [-2.286385965035644, 51.407553757131019],
            [-2.288121099054008, 51.407524324290023],
            [-2.288805293902358, 51.406587509118133],
            [-2.290864263741021, 51.405714720385014],
            [-2.287358978922189, 51.402690495468818],
            [-2.286588178053011, 51.40057934895151],
            [-2.2845334532915, 51.397945312327593],
            [-2.281815225810488, 51.397230756025706],
            [-2.282913800980359, 51.396831575199975],
            [-2.284439370239968, 51.396914199042115],
            [-2.287358978573703, 51.396043872122732],
            [-2.290171775291666, 51.396027943777185],
            [-2.291653130750334, 51.395489260235301],
            [-2.29402254129405, 51.395595730263771],
            [-2.296790596182976, 51.394890095653416],
            [-2.295823922909609, 51.390536084541985],
            [-2.296285305819344, 51.387430996346083],
            [-2.297496290325967, 51.384921948356947],
            [-2.293702545004245, 51.381100175489024],
            [-2.292544844632451, 51.378464916408298],
            [-2.291520391046121, 51.378010694541615],
            [-2.291664982892611, 51.377024846450695],
            [-2.290798015488161, 51.37557035086337],
            [-2.290290883634086, 51.372624135537784],
            [-2.291093490788838, 51.371872235981421],
            [-2.292058609429751, 51.369573354875286],
            [-2.294308231094432, 51.366890893930652],
            [-2.294044634972471, 51.366777361783576],
            [-2.296507940579493, 51.36430472594207],
            [-2.300070181055943, 51.3621196573365],
            [-2.302976365974974, 51.362858483349129],
            [-2.30752085334257, 51.365000146502773],
            [-2.309326754384321, 51.363563909851507],
            [-2.310409913381315, 51.361889484956755],
            [-2.310874040961876, 51.360648296983967],
            [-2.310216756909545, 51.357538009634389],
            [-2.311730882841579, 51.356966610340265],
            [-2.312620762350643, 51.357522622707464],
            [-2.314782139013603, 51.35689100753217],
            [-2.316724081692917, 51.356934324904891],
            [-2.318727080452625, 51.356463117746344],
            [-2.320766819541889, 51.356528581086415],
            [-2.321328214742341, 51.35712588977384],
            [-2.322985107736841, 51.356660055146328],
            [-2.323712636498581, 51.355559257780449],
            [-2.324744719166467, 51.355087930758486],
            [-2.325406977212312, 51.354310106331631],
            [-2.32729619967033, 51.354022499589462],
            [-2.328315500611982, 51.35418329469546],
            [-2.332681694425354, 51.351693748239349],
            [-2.333846992833758, 51.351991653664271],
            [-2.33556954040645, 51.351513763843741],
            [-2.336372568015026, 51.351754235836303],
            [-2.337431385366041, 51.351432881556171],
            [-2.337411527149323, 51.350684827027138],
            [-2.338113016149116, 51.350787107116098],
            [-2.338585465931543, 51.350206674267987],
            [-2.339152083692102, 51.350129503553532],
            [-2.339917419400149, 51.34915887439746],
            [-2.340598143022235, 51.348980657882663],
            [-2.340592115940836, 51.348555366488043],
            [-2.341642702463381, 51.348685382798678],
            [-2.342319414279074, 51.348356106847099],
            [-2.34271484772266, 51.347278639292448],
            [-2.343686600885819, 51.347046501117013],
            [-2.343450394686297, 51.34675676129492],
            [-2.344020245549345, 51.346351359075847],
            [-2.344281640779305, 51.345404659838557],
            [-2.343590538020456, 51.344954405904481],
            [-2.342453632884929, 51.344982914209083],
            [-2.341139696534757, 51.344566836467031],
            [-2.339307367457165, 51.345494714207874],
            [-2.338599228595576, 51.346232288662634],
            [-2.333453128519905, 51.346203001152467],
            [-2.329074030547274, 51.347414890583032],
            [-2.327571705081188, 51.347151150606422],
            [-2.324049645837845, 51.347763399525697],
            [-2.320456272041857, 51.346812075812686],
            [-2.31646359348322, 51.34373337877134],
            [-2.311468850229617, 51.341210207263948],
            [-2.310637540279457, 51.340350108704762],
            [-2.308530367344021, 51.33975413467946],
            [-2.306558951144513, 51.341206984518884],
            [-2.306246703794388, 51.341099901061604],
            [-2.305918660779595, 51.341638464765822],
            [-2.303160411655429, 51.340930798261923],
            [-2.302140603557415, 51.341725607175213],
            [-2.299865896625428, 51.342727741115716],
            [-2.299047759454778, 51.342318012861114],
            [-2.297945117241504, 51.343195720515645],
            [-2.296411239583475, 51.342665506074312],
            [-2.295019666495501, 51.340145941103216],
            [-2.29520050139944, 51.339691402424201],
            [-2.296556793701169, 51.339194327356836],
            [-2.29690395159337, 51.338711490024146],
            [-2.295556384628809, 51.337884963110618],
            [-2.294341742463563, 51.337632658652367],
            [-2.294442752016374, 51.337266440732002],
            [-2.294862107098969, 51.336848168186165],
            [-2.296123380040337, 51.337210947716578],
            [-2.298595860703588, 51.336188599091159],
            [-2.299554299322244, 51.336117812729093],
            [-2.300974239096466, 51.333966041882263],
            [-2.300929199516547, 51.333226136844935],
            [-2.300118956605321, 51.332242721642153],
            [-2.295081399163236, 51.330986795240527],
            [-2.290587996974664, 51.330623981565004],
            [-2.288441190072758, 51.329775076014968],
            [-2.287244351550183, 51.328427458490069],
            [-2.288626560578769, 51.326840609705563],
            [-2.288485229082233, 51.325817696251711],
            [-2.289093195113625, 51.325275791300044],
            [-2.291162028320804, 51.323816694749809],
            [-2.294095741285472, 51.322755524340479],
            [-2.297939213720448, 51.322371744952314],
            [-2.299220897292138, 51.321298454787879],
            [-2.303930002521126, 51.320120986429501],
            [-2.305357622436205, 51.319445584855806],
            [-2.310731538753202, 51.318809208838225],
            [-2.312282946916563, 51.317577702174766],
            [-2.316382593128294, 51.316347405814831],
            [-2.319540278410249, 51.314426275008501],
            [-2.320185299146228, 51.314952329531486],
            [-2.324819990285906, 51.313563814715337],
            [-2.325110105296253, 51.313402056193716],
            [-2.324732849125716, 51.313008364322279],
            [-2.326158142115349, 51.311876830802767],
            [-2.32857944806635, 51.310802732311458],
            [-2.330387545204926, 51.309260045626338],
            [-2.332523585183667, 51.308447431894251],
            [-2.337351251534828, 51.30769089249047],
            [-2.338098957157516, 51.308704807415829],
            [-2.337493741672249, 51.308926853295517],
            [-2.339053160120218, 51.309896156821402],
            [-2.34055775836404, 51.309460182007058],
            [-2.341606825111988, 51.309699003691179],
            [-2.341782930864451, 51.309265085088164],
            [-2.348672168852041, 51.308142368175076],
            [-2.350485611475225, 51.30702646421404],
            [-2.353868719734868, 51.306704267902447],
            [-2.354480023139155, 51.306913725153798],
            [-2.357609492484403, 51.304971866035409],
            [-2.35961795124875, 51.304440590094188],
            [-2.362888249695803, 51.302943250308097],
            [-2.369258929178779, 51.301017058512677],
            [-2.374272880988453, 51.301112628975766],
            [-2.37762497741976, 51.300580335309967],
            [-2.379717921492377, 51.300604135304532],
            [-2.381576897968928, 51.300089152057829],
            [-2.383146099756219, 51.299058058771053],
            [-2.38518543132463, 51.296969757994674],
            [-2.384158822523555, 51.296321225430702],
            [-2.384882780939423, 51.295595904093396],
            [-2.387182454129391, 51.294647773268586],
            [-2.389627468038517, 51.29461538036221],
            [-2.391892788132846, 51.295375718177766],
            [-2.39231766170991, 51.296425438932843],
            [-2.393665413490031, 51.297547588516657],
            [-2.397467958261944, 51.303056611869174],
            [-2.399827820390457, 51.304893693899317],
            [-2.400918702504432, 51.304804539858011],
            [-2.404367202669975, 51.302025894156472],
            [-2.406960716116693, 51.30090101975847],
            [-2.407044285592374, 51.30061928514148],
            [-2.40747341209613, 51.300971170416005],
            [-2.410146179576956, 51.300867420142502],
            [-2.41164620011563, 51.301309946455007],
            [-2.412583944630201, 51.300631358570499],
            [-2.412738624494283, 51.299806262998302],
            [-2.41403600641575, 51.299568792157409],
            [-2.415367287712963, 51.298480559893193],
            [-2.416198682591309, 51.298741965909684],
            [-2.417459635633183, 51.298288782730296],
            [-2.417369959520976, 51.298048121597134],
            [-2.418655967752565, 51.297672165812777],
            [-2.418435894562178, 51.297445459596695],
            [-2.419542765124327, 51.297245475024461],
            [-2.419388056453188, 51.296794639676897],
            [-2.420002734798724, 51.296884152022592],
            [-2.420259977449478, 51.296626062212198],
            [-2.417766225447443, 51.29367038358302],
            [-2.417390920403468, 51.29213861477821],
            [-2.421369322885712, 51.291062421739419],
            [-2.42099065617568, 51.289017241591026],
            [-2.422055175069253, 51.287506374212491],
            [-2.426226095317501, 51.2869895122491],
            [-2.427610545065232, 51.28706358989114],
            [-2.427709201431971, 51.286724236714505],
            [-2.429955281705, 51.286780747363331],
            [-2.430686077591083, 51.286419285351045],
            [-2.430232768907055, 51.286399372346281],
            [-2.430064399624791, 51.284647479653422],
            [-2.432599737976739, 51.286046258600791],
            [-2.434544308208422, 51.285457281312098],
            [-2.435342495829148, 51.285404859519396],
            [-2.43561489393522, 51.285703274508215],
            [-2.439047083994243, 51.285190501192517],
            [-2.438729301393578, 51.284194496441266],
            [-2.439072955383943, 51.283844323599638],
            [-2.442409142304829, 51.282759929395169],
            [-2.442498331954485, 51.282044739463778],
            [-2.446015238516218, 51.280840865824857],
            [-2.444311849550471, 51.277416050565989],
            [-2.447460208674688, 51.27712710461833],
            [-2.449970589316907, 51.277427697876909],
            [-2.452404755463403, 51.277117984155247],
            [-2.451722928497572, 51.274255808118056],
            [-2.453287237595827, 51.273849620543338],
            [-2.455875099330088, 51.273737059971623],
            [-2.461583774228502, 51.274198443841598],
            [-2.472303108618956, 51.277057348739639],
            [-2.473753325421321, 51.277971355376259],
            [-2.478000901827533, 51.273101165844899],
            [-2.48266369890377, 51.274152959577556],
            [-2.482832936030518, 51.273887000901453],
            [-2.485000979038758, 51.274208936644953],
            [-2.485177498834533, 51.274635319311649],
            [-2.487161158202718, 51.275139615415917],
            [-2.487907365367042, 51.274127617375363],
            [-2.489944446991752, 51.274801590217841],
            [-2.490608524135558, 51.274429241595961],
            [-2.49350836421911, 51.275475392958867],
            [-2.49276986787058, 51.276126819703869],
            [-2.493865596540648, 51.276703076169618],
            [-2.494831308600791, 51.276651339725099],
            [-2.495193564415653, 51.276076125317388],
            [-2.495947261650592, 51.275907482937072],
            [-2.499807535120928, 51.27679204617764],
            [-2.506354814444838, 51.278995685424825],
            [-2.500123125109944, 51.282747817191478],
            [-2.499848796725922, 51.284024935911269],
            [-2.50043443357412, 51.284609602360149],
            [-2.498662188892527, 51.285671014226629],
            [-2.498344933203334, 51.286432178426857],
            [-2.496969868597002, 51.28630674428728],
            [-2.496536295836712, 51.287454148357689],
            [-2.496967550141474, 51.290208324025244],
            [-2.496669819359701, 51.290525202199085],
            [-2.497173142594843, 51.290522165988527],
            [-2.497416585796124, 51.290887999507085],
            [-2.497972608593372, 51.290586206102503],
            [-2.498288803507269, 51.290915760805802],
            [-2.499551499442258, 51.290584872703391],
            [-2.499699653209384, 51.29088996352899],
            [-2.500489482127028, 51.290989995060194],
            [-2.502110873189313, 51.290413865438772],
            [-2.502762514809587, 51.290466817532703],
            [-2.503298547512232, 51.290045492843987],
            [-2.505278807626539, 51.289900284039142],
            [-2.506222274847038, 51.290018496435167],
            [-2.506376577579503, 51.291139114307697],
            [-2.508061530650702, 51.291520260615343],
            [-2.509374985686208, 51.292022591863471],
            [-2.512554179596291, 51.292259585408033],
            [-2.513616512564269, 51.292742287301046],
            [-2.52163219191005, 51.293528693885349],
            [-2.523210329354038, 51.293578298619337],
            [-2.523467918229227, 51.293150932479335],
            [-2.524899672658751, 51.292941304122031],
            [-2.528001422450131, 51.293165637136482],
            [-2.531722879031113, 51.294021000970112],
            [-2.534562420206551, 51.295491724607714],
            [-2.539233943435824, 51.296248991620772],
            [-2.540460692884334, 51.297876256118222],
            [-2.541504753897873, 51.298476582987888],
            [-2.550010281962699, 51.300520339843665],
            [-2.55047631741776, 51.299430133671471],
            [-2.551128775851049, 51.299535864981628],
            [-2.551397714978589, 51.298279334053817],
            [-2.55416227730656, 51.298690694274235],
            [-2.5528011711686, 51.301321855345066],
            [-2.557822097155155, 51.30266569788057],
            [-2.558440710462342, 51.302105252823466],
            [-2.559396695845018, 51.302041341831988],
            [-2.562666433424996, 51.298813775643588],
            [-2.567220405387454, 51.297649828548465],
            [-2.571248282188209, 51.295140390194959],
            [-2.574496508283302, 51.294889804404363],
            [-2.580182625253952, 51.293144308443878],
            [-2.582279814998686, 51.29193437685354],
            [-2.582177264251525, 51.293123614172238],
            [-2.585894448842887, 51.293338855530578],
            [-2.587752858331785, 51.29421703743855],
            [-2.588460938168595, 51.295194494271662],
            [-2.589506700374713, 51.295435609724493],
            [-2.589374213028782, 51.295729412281119],
            [-2.591632049917351, 51.29653808158146],
            [-2.5954641830247, 51.296190445819562],
            [-2.594589865218892, 51.29512485610627],
            [-2.595318058289906, 51.293099778555721],
            [-2.594875418905988, 51.292362895721425],
            [-2.596539877910507, 51.290770952499912],
            [-2.598612882617439, 51.290609305086527],
            [-2.600335611566036, 51.288991831745136],
            [-2.603924637614043, 51.287764857117025],
            [-2.606955379910138, 51.287277105105545],
            [-2.608784643231253, 51.285831597834928],
            [-2.612992351081661, 51.284152427846799],
            [-2.61845032901642, 51.286229620297632],
            [-2.621040393387842, 51.284344689534954],
            [-2.624696614061784, 51.285388975353733],
            [-2.624982665968384, 51.282483959394902],
            [-2.629978499768686, 51.283561383177449],
            [-2.630200296262228, 51.284261558560715],
            [-2.630996009860977, 51.284673597125696],
            [-2.631974332061602, 51.284599081041698],
            [-2.633445692973285, 51.285863483144659],
            [-2.633608270979594, 51.286528004743175],
            [-2.635042912470742, 51.286266657775926],
            [-2.63572256617267, 51.287302433426753],
            [-2.638292920739485, 51.288282050997374],
            [-2.637232945509367, 51.289092602565759],
            [-2.638579039652409, 51.289989852575857],
            [-2.639698794626236, 51.291313641531673],
            [-2.640347549321009, 51.293310793377501],
            [-2.638696365932697, 51.294826857695071],
            [-2.641042115246785, 51.295503713651009],
            [-2.643889846278493, 51.297097633971134],
            [-2.644666344767419, 51.297034912752693],
            [-2.646820976052367, 51.298386198796443],
            [-2.648975449873748, 51.298903896961271],
            [-2.650730868844675, 51.299700725074032],
            [-2.652219582024657, 51.299791350732086],
            [-2.653660407054045, 51.300650133106473],
            [-2.655759524184297, 51.301296599867342],
            [-2.659988158750286, 51.3025964427734],
            [-2.662882265995623, 51.302952354833884],
            [-2.666792478968419, 51.302449080720351],
            [-2.665811713798009, 51.30336824016522],
            [-2.664886460708493, 51.30535171649035],
            [-2.662482428022364, 51.306563965369918],
            [-2.663413795956719, 51.307183627574418],
            [-2.664863730908581, 51.307550363772485],
            [-2.665883717104022, 51.307450151588746],
            [-2.666978721125758, 51.307873730516732],
            [-2.666604670677692, 51.309186878879849],
            [-2.670456511236396, 51.311239311822369],
            [-2.673005592231062, 51.311799267397426],
            [-2.674491037540796, 51.310463515482887],
            [-2.676419878394422, 51.310992799450723],
            [-2.676455719364431, 51.311576166131069],
            [-2.678911394671223, 51.312800134533845],
            [-2.683071954107981, 51.314424146828436],
            [-2.685883365816763, 51.31513785131235],
            [-2.685497302815572, 51.316094151822988],
            [-2.686394233212623, 51.316103283740375],
            [-2.691070598513885, 51.317543250021217],
            [-2.69487577144969, 51.318098006772104],
            [-2.695124751620105, 51.318526341049719],
            [-2.693356869014062, 51.321664201730044],
            [-2.693544824967617, 51.321947231993946],
            [-2.68717843851236, 51.330361600106848],
            [-2.687715755922681, 51.330779264810651],
            [-2.689011804971195, 51.330871455128602],
            [-2.692663106174359, 51.332192398028489],
            [-2.694134059299086, 51.333407480219066],
            [-2.697577003029333, 51.335163812118097],
            [-2.697753925163812, 51.335565593483764],
            [-2.698355801476694, 51.33549816318699],
            [-2.698841743657213, 51.335830660519569],
            [-2.699940457135025, 51.335679329045981],
            [-2.701612657287027, 51.336516355048786],
            [-2.701917459043958, 51.33701717350435],
            [-2.703159485836287, 51.336755252004608],
            [-2.705765726512288, 51.337740367052128],
            [-2.707888487725354, 51.337806681930275],
            [-2.707271450692319, 51.339967558272811],
            [-2.707750055210154, 51.340375594582873],
            [-2.707532044720306, 51.340761763642327],
            [-2.705849932581688, 51.341819466571003],
            [-2.705129207243299, 51.342005444190185],
            [-2.705153943162314, 51.341746329758976],
            [-2.704778824889424, 51.341813329407046],
            [-2.70483396414304, 51.342412754359422],
            [-2.704051362360632, 51.343527057119324],
            [-2.701731128440493, 51.343990576664254],
            [-2.699279730418496, 51.343103358142386],
            [-2.694869883462715, 51.344381274296815],
            [-2.69193996835119, 51.3488909486816],
            [-2.690497911090616, 51.349531586713731],
            [-2.689927685478589, 51.352865523735453],
            [-2.691786095500917, 51.356214808254386],
            [-2.691624801172652, 51.356947695775617],
            [-2.690873670165224, 51.356850521558634],
            [-2.692067508887457, 51.358020503095318],
            [-2.692236437825275, 51.357032202041964],
            [-2.693009766428722, 51.357172396958745],
            [-2.693103831318088, 51.358266145743812],
            [-2.693909968178736, 51.358389955282398],
            [-2.694325082493959, 51.359058285271217],
            [-2.693824754362805, 51.359023484733342],
            [-2.694698182232308, 51.359511958332604],
            [-2.694728385034581, 51.359989245101893],
            [-2.6957105447787, 51.360068837244349],
            [-2.696455876228422, 51.361388903152118],
            [-2.695170191246354, 51.361944143532241],
            [-2.696690151730113, 51.362440452237486],
            [-2.69416506330316, 51.364052392799792],
            [-2.694569860544723, 51.364604788095285],
            [-2.694046316039003, 51.364843477338553],
            [-2.694255686046539, 51.366055232498411],
            [-2.694986567884655, 51.367081359862922],
            [-2.695579005610475, 51.367121902863865],
            [-2.696456638957134, 51.366645516343702],
            [-2.696750347243635, 51.367446738573044],
            [-2.696196709751144, 51.367786324593823],
            [-2.696141647954691, 51.368417877160866],
            [-2.694991450519563, 51.368447187325167],
            [-2.695174093686108, 51.36940912644264],
            [-2.695568754198083, 51.36957313186435],
            [-2.694963898350066, 51.375449270829442],
            [-2.695280248794047, 51.376130770325744],
            [-2.693983766445791, 51.377899952255206],
            [-2.694190150648192, 51.379859840017893],
            [-2.689554929457044, 51.380153375421784],
            [-2.687819905064678, 51.377709715928113],
            [-2.686606399798114, 51.374772921179009],
            [-2.687088937828022, 51.374663988370145],
            [-2.686603621844597, 51.374395281888489],
            [-2.686237134986168, 51.373233890643519],
            [-2.685410930650096, 51.368801272864594],
            [-2.682339354540276, 51.369553837882684],
            [-2.682178895695494, 51.369293111021342],
            [-2.681471899866343, 51.367149983176503],
            [-2.682222001349068, 51.366588124192646],
            [-2.680791503843271, 51.366311407528976],
            [-2.680862968209395, 51.364350777284628],
            [-2.680092010185723, 51.363019073138069],
            [-2.680840058055782, 51.361934809924719],
            [-2.680225231975905, 51.361638055796085],
            [-2.679700166158725, 51.360127781346257],
            [-2.67718795799972, 51.360600017806462],
            [-2.677822601750231, 51.361560269193248],
            [-2.676314444629143, 51.361946644203869],
            [-2.673597578549468, 51.361597233981385],
            [-2.672363925390942, 51.361017157353594],
            [-2.671721359052734, 51.361666456745731],
            [-2.6695404610288, 51.361821914672646],
            [-2.668748345910771, 51.36078698686746],
            [-2.668403363965832, 51.358992391733267],
            [-2.669489532711327, 51.356951339316574],
            [-2.670918430700351, 51.356946758880184],
            [-2.674665857963038, 51.35590735638749],
            [-2.674259069833363, 51.354904413249699],
            [-2.673474824283241, 51.354894538933785],
            [-2.673110424224641, 51.353841892896043],
            [-2.67797574769355, 51.353549467903029],
            [-2.677523914711162, 51.352222191317594],
            [-2.676792079984806, 51.351186068238228],
            [-2.676344482854285, 51.351409853175092],
            [-2.675502780995388, 51.350028170926862],
            [-2.675614154530053, 51.349595021293112],
            [-2.676318204310544, 51.34972313730114],
            [-2.677131616275321, 51.348600751837921],
            [-2.676542409989251, 51.347592575773767],
            [-2.674076864393137, 51.346699517626128],
            [-2.673647373618984, 51.347176758041627],
            [-2.670989472328235, 51.346576076880879],
            [-2.671202608683557, 51.34524406359381],
            [-2.673109099843145, 51.345522657711768],
            [-2.674155919022926, 51.343477286542416],
            [-2.676448584505056, 51.343562076175857],
            [-2.675429981991528, 51.345761964893342],
            [-2.676959509368657, 51.345691988342466],
            [-2.67653141919947, 51.341972738852661],
            [-2.673370218661768, 51.343159000117424],
            [-2.673026353666495, 51.341247512734007],
            [-2.670917342540837, 51.340950295894821],
            [-2.66974376259362, 51.343169906408534],
            [-2.663420319799852, 51.342733830562302],
            [-2.660737808472525, 51.343089788090026],
            [-2.660336364609356, 51.342429354153353],
            [-2.661102320759619, 51.341582497035461],
            [-2.658969331632437, 51.341510895745174],
            [-2.657224404869406, 51.342372229312559],
            [-2.655163075571362, 51.342595987838806],
            [-2.654436959320286, 51.343426397772561],
            [-2.65377423553183, 51.343366257656527],
            [-2.652524052999825, 51.343894757141705],
            [-2.651175002553403, 51.345046926872911],
            [-2.651881206448711, 51.346247003659904],
            [-2.651865191549589, 51.350581152609521],
            [-2.651325921145778, 51.350930338317411],
            [-2.651486112810304, 51.352302498205695],
            [-2.650550105546696, 51.353740098171052],
            [-2.649528390682459, 51.354300564461958],
            [-2.650124206975006, 51.355709872420427],
            [-2.650154705876726, 51.357559318855692],
            [-2.650171216679346, 51.360347587032464],
            [-2.649606491675633, 51.362357691579419],
            [-2.650524262320722, 51.365800953080694],
            [-2.649877946848618, 51.368950770427652],
            [-2.649210923561678, 51.369323133119188],
            [-2.647788541749186, 51.371546585775427],
            [-2.64630785108148, 51.373006040084093],
            [-2.643368030276831, 51.374613770820204],
            [-2.644402614537408, 51.375136800214484],
            [-2.642957670323177, 51.374857003898974],
            [-2.642518999811811, 51.375335975481995],
            [-2.641236722158043, 51.374461807983053],
            [-2.640222595558552, 51.375504107868018],
            [-2.639605720979045, 51.375672926797954],
            [-2.640156480677134, 51.374987441891008],
            [-2.63940088359651, 51.374789254065092],
            [-2.64014687799278, 51.373990305862293],
            [-2.638623722386279, 51.373247806829013],
            [-2.637956820142267, 51.373639886135301],
            [-2.637666813978452, 51.374377892201458],
            [-2.636489445573694, 51.374843776049858],
            [-2.634555410239956, 51.374307569757441],
            [-2.63403314886432, 51.37560521404621],
            [-2.634054728649307, 51.375191475475866],
            [-2.632289179679862, 51.374057265564701],
            [-2.632157400530075, 51.374398765275487],
            [-2.630020771808831, 51.375215940756974],
            [-2.629792699214252, 51.375451852664341],
            [-2.632362486078692, 51.377186907130657],
            [-2.634754609904958, 51.377905898504601],
            [-2.633819916186899, 51.378161830537955],
            [-2.631462518120069, 51.377773530152751],
            [-2.631157986239153, 51.377987377348987],
            [-2.630616860087317, 51.378863392953271],
            [-2.631111834782369, 51.378914677666465],
            [-2.631645164089906, 51.379768718551432],
            [-2.631232543201264, 51.380690800865246],
            [-2.629929545474328, 51.380395692100336],
            [-2.627324791792343, 51.380522967545552],
            [-2.628181340467013, 51.382109020422838],
            [-2.629546757377772, 51.382342669154134],
            [-2.631133275161988, 51.381830592684615],
            [-2.630289233761558, 51.382521209148926],
            [-2.631790801158363, 51.383248646134788],
            [-2.63084604848411, 51.384036021432884],
            [-2.630203784790788, 51.383727464461771],
            [-2.630629726314585, 51.38345721752539],
            [-2.629560780313966, 51.3830502456453],
            [-2.629922505894298, 51.382812716716892],
            [-2.629192063488342, 51.382464165411754],
            [-2.628903517213002, 51.382694105805122],
            [-2.628617841749961, 51.38292403006691],
            [-2.629116015276901, 51.383205495238279],
            [-2.628925589020291, 51.383674988687922],
            [-2.629746271378396, 51.384105780717491],
            [-2.629046118228322, 51.384395480806496],
            [-2.629657393543904, 51.385168185539044],
            [-2.628366436197991, 51.38544576979244],
            [-2.627848276332083, 51.384860487134901],
            [-2.627271953855539, 51.384959786614914],
            [-2.627002940125414, 51.38567427340746],
            [-2.627455712796168, 51.385786943487922],
            [-2.627628681425609, 51.386560207238041],
            [-2.626951079247199, 51.387136610804014],
            [-2.622997567719705, 51.385543683836239],
            [-2.622358042046275, 51.384905071919178],
            [-2.622165657358636, 51.383964656756731],
            [-2.621448675576401, 51.383864158401082],
            [-2.621384647744343, 51.383592946931365],
            [-2.620856839908611, 51.383666780585443],
            [-2.619324795644575, 51.382905196302119],
            [-2.619344230351019, 51.383812362082338],
            [-2.618787939185022, 51.38379911694517],
            [-2.618340900525592, 51.382190154310415],
            [-2.619122419966659, 51.382603243822963],
            [-2.618904635545776, 51.381478626211376],
            [-2.617077005318245, 51.381060260877419],
            [-2.616616373020726, 51.381853961679141],
            [-2.615693740780851, 51.381951430127053],
            [-2.615464111505241, 51.381115503974897],
            [-2.614557746364873, 51.38071023857438],
            [-2.613909176489082, 51.380992384238951],
            [-2.614084649188123, 51.381219855157084],
            [-2.613341804986091, 51.381329850048445],
            [-2.613434770823159, 51.381619797069625],
            [-2.612458209595009, 51.381767876312729],
            [-2.613020065915837, 51.382522043274236],
            [-2.614656444598907, 51.38217807521972],
            [-2.615474243595609, 51.383262683000012],
            [-2.614158107152191, 51.38370119550283],
            [-2.612057630153326, 51.383730173352035],
            [-2.613619015530516, 51.384114044913424],
            [-2.613735607200989, 51.385417238605072],
            [-2.615653645745697, 51.385387391492856],
            [-2.615457448769265, 51.385544879048396],
            [-2.61654255564808, 51.385769361601369],
            [-2.619177707098348, 51.386166385250704],
            [-2.620647919002534, 51.386067787891363],
            [-2.622384828298582, 51.386561211249763],
            [-2.621794389559372, 51.387953572988636],
            [-2.623143808893956, 51.388374409757667],
            [-2.622702325754859, 51.38941170816215],
            [-2.620801942051218, 51.389377735320934],
            [-2.620810976215392, 51.38972656736793],
            [-2.618824380544589, 51.390440233653187],
            [-2.617119860310375, 51.390439334818417],
            [-2.614981611792925, 51.391318283483791],
            [-2.614085719634705, 51.390100105878524],
            [-2.614504276735061, 51.388731165162312],
            [-2.612160383033744, 51.388629242378109],
            [-2.611659165391413, 51.389624549595048],
            [-2.610594408432267, 51.389530296100759],
            [-2.609676257885654, 51.389004565308056],
            [-2.607401104025164, 51.388993006178318],
            [-2.608352421061167, 51.390504077794645],
            [-2.607761278040412, 51.39079488212387],
            [-2.605330050211847, 51.390071946183802],
            [-2.60513585063917, 51.389404862941483],
            [-2.603887764757945, 51.389247654076087],
            [-2.603114783770152, 51.38882632693781],
            [-2.602712948528981, 51.388978557324968],
            [-2.603112854363237, 51.389553769015031],
            [-2.604187847198731, 51.389989723940445],
            [-2.603718436907112, 51.39101360612726],
            [-2.604400106610727, 51.391700656743339],
            [-2.604056328299445, 51.391897550765805],
            [-2.60443024798449, 51.393114901960992],
            [-2.603447322049416, 51.393455361351258],
            [-2.60336048372519, 51.393955749279463],
            [-2.599913156334088, 51.393683920464873],
            [-2.599690567834148, 51.393149152649336],
            [-2.597789738434189, 51.393091430154506],
            [-2.597761566964675, 51.392804737503155],
            [-2.597178100144485, 51.392809511753093],
            [-2.597081983443866, 51.3935958794629],
            [-2.594897300041837, 51.393484706915842],
            [-2.591855237901559, 51.393870575667648],
            [-2.593950782315825, 51.395643029975325],
            [-2.592658029303132, 51.396043415211459],
            [-2.590575300610189, 51.394259184104129],
            [-2.5872708264771, 51.39514889401098],
            [-2.587201085575743, 51.395427088064515],
            [-2.589867841271167, 51.396693213311885],
            [-2.58945941173926, 51.396900280587609],
            [-2.590131811958493, 51.397546997149966],
            [-2.589928533139384, 51.398170248490231],
            [-2.587774976921062, 51.39859649130063],
            [-2.586116868099, 51.39943833176379],
            [-2.585094452681883, 51.399521670672144],
            [-2.585185545955235, 51.400126358560215],
            [-2.58441265290837, 51.400051089664807],
            [-2.584397321029863, 51.400427020090092],
            [-2.583850692776192, 51.400397375487458],
            [-2.583764999663341, 51.399763886201796],
            [-2.583003579778446, 51.399685853334731],
            [-2.582009820371093, 51.400894786049079],
            [-2.578102031617418, 51.399363962940356],
            [-2.576346039360103, 51.401126000390043],
            [-2.574047035613864, 51.400290268746161],
            [-2.573444796014633, 51.400870486511096],
            [-2.571990270348441, 51.400428910822448],
            [-2.571826416678017, 51.399624052905331],
            [-2.570753786455555, 51.399371221521363],
            [-2.56823287354432, 51.401375141578598],
            [-2.567565598807704, 51.401354098705042],
            [-2.567099799156016, 51.402512688735889],
            [-2.566500806789622, 51.402783539824945],
            [-2.567569902854103, 51.403326859464158],
            [-2.567165623570582, 51.403649821478353],
            [-2.567588517202309, 51.404017332604788],
            [-2.566594819423196, 51.404341348475377],
            [-2.566851292877275, 51.404736642110521],
            [-2.564725640868781, 51.405136249002339],
            [-2.56537622696149, 51.406014298275103],
            [-2.564319295092723, 51.406461786143439],
            [-2.564346607824465, 51.407045216174645],
            [-2.563178712029162, 51.407474345522424],
            [-2.562253158704037, 51.408322212797685],
            [-2.563136511379572, 51.40860300695271],
            [-2.561838729149974, 51.409934614429019],
            [-2.560874293470866, 51.411723188200199],
            [-2.559554847545453, 51.411646773850634],
            [-2.559670721621798, 51.411245190752155],
            [-2.558810779125535, 51.411235808099242],
            [-2.556085142679782, 51.411277550511954],
            [-2.556199470547001, 51.408851048130678],
            [-2.555859607956628, 51.409279766808361],
            [-2.553146249259335, 51.409614512127526],
            [-2.552907048070013, 51.409094122889314],
            [-2.549015871821005, 51.409286861547024],
            [-2.548847876343675, 51.408825475557386],
            [-2.54748333659307, 51.40905755184717],
            [-2.54542668995382, 51.40864183780009],
            [-2.545071526602174, 51.408155241742982],
            [-2.543344596044097, 51.407410658943618],
            [-2.542321640440177, 51.408077187680483],
            [-2.542315234243822, 51.408750695233309],
            [-2.540940151180932, 51.409553716573846],
            [-2.538598619447385, 51.410707348744793],
            [-2.5360069652907, 51.411139146669868],
            [-2.536926070314116, 51.412026014201579],
            [-2.535512003153059, 51.412708661164622],
            [-2.534501553911037, 51.413850723564806],
            [-2.534838513581871, 51.414138717978005],
            [-2.534299987603915, 51.414445094059467],
            [-2.534344489739436, 51.416657743717565],
            [-2.533522833821456, 51.416610235703367],
            [-2.533270380888563, 51.417158978266741],
            [-2.534454880104632, 51.417491667949413],
            [-2.533764445818314, 51.418216847161673],
            [-2.532605810861356, 51.419367659391362],
            [-2.529461362411606, 51.420917689150272],
            [-2.529254153816829, 51.421280989669732],
            [-2.531220359660018, 51.422418529296813],
            [-2.531312679635088, 51.423310084202228],
            [-2.529296682725636, 51.424712017932798],
            [-2.526837557670587, 51.42573825997227],
            [-2.523032061527648, 51.425632123682995],
            [-2.523210451751194, 51.426139356077861],
            [-2.523838958330212, 51.428273865256671],
            [-2.523747020113621, 51.429918850241002],
            [-2.52738862669666, 51.431500333053357],
            [-2.525463226599797, 51.432941353579984],
            [-2.526722172267569, 51.433837563703705],
            [-2.527954269090616, 51.433773573005098],
            [-2.526289924474741, 51.434779133298349],
            [-2.524001543332535, 51.432551367531858],
            [-2.521940544626941, 51.431449198103913],
            [-2.510462037646163, 51.428784288293407],
            [-2.508997838016655, 51.428532597724455],
            [-2.50828746755727, 51.42790177],
            [-2.508055789943865, 51.426596287423806],
            [-2.508468244616719, 51.425011067446945],
            [-2.507985224767164, 51.424643605498225],
            [-2.505721171295775, 51.42566406205485],
            [-2.503974794764895, 51.426029454841604],
            [-2.502492458939839, 51.427128307530722],
            [-2.498605232604798, 51.428635729976421],
            [-2.497675442031572, 51.428845591782562],
            [-2.496138803173888, 51.428672275719883],
            [-2.494251040007272, 51.429415770955778],
            [-2.491510111883251, 51.428942651324107],
            [-2.488434471442417, 51.42648191088302],
            [-2.488642692143921, 51.425237497367959],
            [-2.490318453032872, 51.424044497358416],
            [-2.492564506635492, 51.421459865700079],
            [-2.492896300983348, 51.418347355543439],
            [-2.492314352024225, 51.417989236738116],
            [-2.490008639644548, 51.41767160658344],
            [-2.486422929592592, 51.420586359597358],
            [-2.484863951295894, 51.420717805921242],
            [-2.483059427279763, 51.421431105172182],
            [-2.481040445831034, 51.421585976288924],
            [-2.476360207961705, 51.418499381452698],
            [-2.475324961319396, 51.418098961642578],
            [-2.473501255182609, 51.418073976728934],
            [-2.47218145040666, 51.416989514516892],
            [-2.469645484155222, 51.415935095730838],
            [-2.464308804154933, 51.417321301970283],
            [-2.460934341519065, 51.416664749000894],
            [-2.458456704734365, 51.41681294265463],
            [-2.457513706003552, 51.417854266153469],
            [-2.455837597833912, 51.418497408931856],
            [-2.454696669533716, 51.418442497814411],
            [-2.45063905500132, 51.417333320552352],
            [-2.446654944664708, 51.417990582332003],
            [-2.445910503950932, 51.418481664883558],
            [-2.445874773910152, 51.418947568325095],
            [-2.443428459938953, 51.418338227968526],
            [-2.441525050462069, 51.419293136888932],
            [-2.444106162276737, 51.42104664256815],
            [-2.444456792364448, 51.422351798826803],
            [-2.444007338092044, 51.422572000027849],
            [-2.44279670157334, 51.422753717374277],
            [-2.441298468398628, 51.422493217915303],
            [-2.438444414092016, 51.423172012854934],
            [-2.431383709777955, 51.4237251346375],
            [-2.430146720012711, 51.424172071526733],
            [-2.427634513135055, 51.42421453759497],
            [-2.424037197350711, 51.42371868678439],
            [-2.420874455735704, 51.424335228810136],
            [-2.416364115270663, 51.426074142286751],
            [-2.414764183256133, 51.426331583187576],
            [-2.413455402286715, 51.425862350545302],
            [-2.411197783322275, 51.426078003048595],
            [-2.404167404914523, 51.429664950163129],
            [-2.403052671620773, 51.430812524715975],
            [-2.403099858894104, 51.431597330737745],
            [-2.399070418127581, 51.432059807321671],
            [-2.3971890663416, 51.431252459355946],
            [-2.396699513348199, 51.432027397137674],
            [-2.38604289388961, 51.431988366739617],
            [-2.380341072878071, 51.434104759566928],
            [-2.377254976668702, 51.432833433576775],
            [-2.375900313509091, 51.433759428047246],
            [-2.373232932203101, 51.434424343721531],
            [-2.367220735114497, 51.437056303701482],
            [-2.361476125881382, 51.437405959550908],
            [-2.361044994923372, 51.436553086944521],
            [-2.360357337341737, 51.436188346840446],
            [-2.358836463981666, 51.435788393085346],
            [-2.357671604457815, 51.43584050994258],
            [-2.354994875866931, 51.437204584969336],
            [-2.353839330478486, 51.437350148093714],
            [-2.351795637596341, 51.438741908083315],
            [-2.350634711807141, 51.438379430516491],
            [-2.346951584495965, 51.43855584162732],
            [-2.343536577841395, 51.43918902318152],
            [-2.34329172266701, 51.439535917288993],
            [-2.342039063742268, 51.439405606582575],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000023",
        LAD13CDO: "00HB",
        LAD13NM: "Bristol, City of",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.664472907869071, 51.536014166135175],
            [-2.663733932716403, 51.534870130209363],
            [-2.665723477398114, 51.533680961140362],
            [-2.664609479671686, 51.531533803103464],
            [-2.665420063302284, 51.530967234773129],
            [-2.660126013760481, 51.527648698742013],
            [-2.658090967503807, 51.526774467950773],
            [-2.653650722576825, 51.523118157320503],
            [-2.651399467032871, 51.52070566008608],
            [-2.651662263037079, 51.51993182472772],
            [-2.652714666206204, 51.519649031516032],
            [-2.652756360424915, 51.5192387840724],
            [-2.653685664119093, 51.518714794101896],
            [-2.653201323265256, 51.516090156766637],
            [-2.654490846220062, 51.514963515860018],
            [-2.653955098046996, 51.514484556587604],
            [-2.654483183345425, 51.513725418967532],
            [-2.651970669019204, 51.512967938433007],
            [-2.647202489652891, 51.512171623850428],
            [-2.638511631797214, 51.512063716278746],
            [-2.63087672501958, 51.513702843794889],
            [-2.627509363255153, 51.514571514336488],
            [-2.622724247845662, 51.514521494847997],
            [-2.621091872163654, 51.514867335943855],
            [-2.61244630967352, 51.513549690292677],
            [-2.607585712659299, 51.513449094996353],
            [-2.602441233966875, 51.513784049280602],
            [-2.59576073316245, 51.515352140351965],
            [-2.58798778011282, 51.517063802569233],
            [-2.580986654348829, 51.517409807448963],
            [-2.581400033396199, 51.515580677137592],
            [-2.582974093365485, 51.513012968672335],
            [-2.585104281895561, 51.512476352751307],
            [-2.585850365047131, 51.512672239823367],
            [-2.589226458233337, 51.511536773986414],
            [-2.591221293837858, 51.509683463832815],
            [-2.590501603014757, 51.508406692784483],
            [-2.588018883868519, 51.507731315805295],
            [-2.588543411657021, 51.50561117172979],
            [-2.587861939133627, 51.504942021654443],
            [-2.587727098143103, 51.50353282051136],
            [-2.588148470976902, 51.501236059790216],
            [-2.585243065539017, 51.501157086257585],
            [-2.585626469806581, 51.501845724137283],
            [-2.584517251477953, 51.502642517067322],
            [-2.58171386861684, 51.501191733679121],
            [-2.579832036391336, 51.500712813409656],
            [-2.578116447737673, 51.500958662696917],
            [-2.575476006068439, 51.500753157733236],
            [-2.573094535266371, 51.500403361907296],
            [-2.571313158482609, 51.499653167948992],
            [-2.569926537555808, 51.499247203886839],
            [-2.569086123113532, 51.499550705339132],
            [-2.567345983111403, 51.498748999108003],
            [-2.56597173166738, 51.498289878249871],
            [-2.565064055478726, 51.497582124567458],
            [-2.565337770196885, 51.496186213018902],
            [-2.564719172011589, 51.496136144107886],
            [-2.564787865976528, 51.495517193312459],
            [-2.562239771738763, 51.495293861949619],
            [-2.561589825850391, 51.495856252626638],
            [-2.561223770125507, 51.495608039972645],
            [-2.560511572327956, 51.495911765492956],
            [-2.560075857427611, 51.49644345107226],
            [-2.554661248976269, 51.496349605327062],
            [-2.556154583818042, 51.494062269533984],
            [-2.555347069670226, 51.492687690075861],
            [-2.551600875441739, 51.49223421812426],
            [-2.542170545065086, 51.492951678547307],
            [-2.542536682067375, 51.494551382186785],
            [-2.541742704700421, 51.495741939960382],
            [-2.538950960505886, 51.495004012075583],
            [-2.537703392936589, 51.497074202626287],
            [-2.534328697952659, 51.496746154648505],
            [-2.531740842152569, 51.495698715514457],
            [-2.53027690791116, 51.496409385203229],
            [-2.529228491546064, 51.496184838050219],
            [-2.527753244032046, 51.494923679026144],
            [-2.528554541674916, 51.494086551632833],
            [-2.527937889985238, 51.493824978424541],
            [-2.527472089395293, 51.49265547495348],
            [-2.525329589398738, 51.49322706785626],
            [-2.523340014014996, 51.49244111273493],
            [-2.520685554572422, 51.49258331033095],
            [-2.518036915689291, 51.492986177255681],
            [-2.515864666002297, 51.493868836276363],
            [-2.515318443991931, 51.493076385116005],
            [-2.515965281202772, 51.492574508590138],
            [-2.510998675019111, 51.489987812257233],
            [-2.511392722960279, 51.489291046332141],
            [-2.516711050581713, 51.485156781157244],
            [-2.515127460642002, 51.481759542213545],
            [-2.515230511997701, 51.481447980861198],
            [-2.511477179921656, 51.474834029078139],
            [-2.511071976450611, 51.472847757324779],
            [-2.510834826905049, 51.471596263134899],
            [-2.511502077264017, 51.470239219896619],
            [-2.512608141113221, 51.470401631329011],
            [-2.51275722205291, 51.470087172737813],
            [-2.512922694500101, 51.469052415734431],
            [-2.512284261012858, 51.468607426630591],
            [-2.512864277233442, 51.467181520786319],
            [-2.513251757304929, 51.465662942358563],
            [-2.5113464903813, 51.465210001382609],
            [-2.512148694186827, 51.463059304798264],
            [-2.512245662539098, 51.462335057104745],
            [-2.513289976413725, 51.462142563602946],
            [-2.513033626223875, 51.461617677257692],
            [-2.514257999966547, 51.461438773308352],
            [-2.515362337751554, 51.459933223451912],
            [-2.516228166054381, 51.460001345766088],
            [-2.518463134130048, 51.459079730966316],
            [-2.518602500838613, 51.458169163447565],
            [-2.519214657732925, 51.457574805022659],
            [-2.518463224411777, 51.455659321518759],
            [-2.518594300589875, 51.454272234491],
            [-2.519176827643909, 51.453734654087384],
            [-2.518936710846181, 51.452612664474124],
            [-2.522277994675833, 51.449805023486],
            [-2.525129285712065, 51.448974043532274],
            [-2.526124196850777, 51.449146716120914],
            [-2.527229588195382, 51.448666091146805],
            [-2.529931367216304, 51.448233995733581],
            [-2.531666147647394, 51.446443996877157],
            [-2.534768093246206, 51.446531488116371],
            [-2.535606417016154, 51.447342301515363],
            [-2.537680440469456, 51.447014500097772],
            [-2.539118584229135, 51.44633441940006],
            [-2.539253474685105, 51.445570408887932],
            [-2.538045745960432, 51.444795486145836],
            [-2.536631791192453, 51.444590666136733],
            [-2.53077997977546, 51.445594708010937],
            [-2.528322137798464, 51.444956618781269],
            [-2.526751267154528, 51.443866711046397],
            [-2.526448449763359, 51.442558889664745],
            [-2.527043435260266, 51.43625217508206],
            [-2.525354547560835, 51.434126041238869],
            [-2.523541872009158, 51.432608271360188],
            [-2.521663130339595, 51.431720183073054],
            [-2.516990832397017, 51.430818348086568],
            [-2.513436078276099, 51.429633593248845],
            [-2.51070049072095, 51.429271495928688],
            [-2.510462037646163, 51.428784288293407],
            [-2.521940544626941, 51.431449198103913],
            [-2.524001543332535, 51.432551367531858],
            [-2.526289924474741, 51.434779133298349],
            [-2.527954269090616, 51.433773573005098],
            [-2.526722172267569, 51.433837563703705],
            [-2.525463226599797, 51.432941353579984],
            [-2.52738862669666, 51.431500333053357],
            [-2.523747020113621, 51.429918850241002],
            [-2.523838958330212, 51.428273865256671],
            [-2.523210451751194, 51.426139356077861],
            [-2.523032061527648, 51.425632123682995],
            [-2.526837557670587, 51.42573825997227],
            [-2.529296682725636, 51.424712017932798],
            [-2.531312679635088, 51.423310084202228],
            [-2.531220359660018, 51.422418529296813],
            [-2.529254153816829, 51.421280989669732],
            [-2.529461362411606, 51.420917689150272],
            [-2.532605810861356, 51.419367659391362],
            [-2.533764445818314, 51.418216847161673],
            [-2.534454880104632, 51.417491667949413],
            [-2.533270380888563, 51.417158978266741],
            [-2.533522833821456, 51.416610235703367],
            [-2.534344489739436, 51.416657743717565],
            [-2.534299987603915, 51.414445094059467],
            [-2.534838513581871, 51.414138717978005],
            [-2.534501553911037, 51.413850723564806],
            [-2.535512003153059, 51.412708661164622],
            [-2.536926070314116, 51.412026014201579],
            [-2.5360069652907, 51.411139146669868],
            [-2.538598619447385, 51.410707348744793],
            [-2.540940151180932, 51.409553716573846],
            [-2.542315234243822, 51.408750695233309],
            [-2.542321640440177, 51.408077187680483],
            [-2.543344596044097, 51.407410658943618],
            [-2.545071526602174, 51.408155241742982],
            [-2.54542668995382, 51.40864183780009],
            [-2.54748333659307, 51.40905755184717],
            [-2.548847876343675, 51.408825475557386],
            [-2.549015871821005, 51.409286861547024],
            [-2.552907048070013, 51.409094122889314],
            [-2.553146249259335, 51.409614512127526],
            [-2.555859607956628, 51.409279766808361],
            [-2.556199470547001, 51.408851048130678],
            [-2.556085142679782, 51.411277550511954],
            [-2.558810779125535, 51.411235808099242],
            [-2.559670721621798, 51.411245190752155],
            [-2.559554847545453, 51.411646773850634],
            [-2.560874293470866, 51.411723188200199],
            [-2.561838729149974, 51.409934614429019],
            [-2.563136511379572, 51.40860300695271],
            [-2.562253158704037, 51.408322212797685],
            [-2.563178712029162, 51.407474345522424],
            [-2.564346607824465, 51.407045216174645],
            [-2.564319295092723, 51.406461786143439],
            [-2.56537622696149, 51.406014298275103],
            [-2.564725640868781, 51.405136249002339],
            [-2.566851292877275, 51.404736642110521],
            [-2.566594819423196, 51.404341348475377],
            [-2.567588517202309, 51.404017332604788],
            [-2.567165623570582, 51.403649821478353],
            [-2.567569902854103, 51.403326859464158],
            [-2.566500806789622, 51.402783539824945],
            [-2.567099799156016, 51.402512688735889],
            [-2.567565598807704, 51.401354098705042],
            [-2.56823287354432, 51.401375141578598],
            [-2.570753786455555, 51.399371221521363],
            [-2.571826416678017, 51.399624052905331],
            [-2.571990270348441, 51.400428910822448],
            [-2.573444796014633, 51.400870486511096],
            [-2.574047035613864, 51.400290268746161],
            [-2.576346039360103, 51.401126000390043],
            [-2.578102031617418, 51.399363962940356],
            [-2.582009820371093, 51.400894786049079],
            [-2.583003579778446, 51.399685853334731],
            [-2.583764999663341, 51.399763886201796],
            [-2.583850692776192, 51.400397375487458],
            [-2.584397321029863, 51.400427020090092],
            [-2.58441265290837, 51.400051089664807],
            [-2.585185545955235, 51.400126358560215],
            [-2.585094452681883, 51.399521670672144],
            [-2.586116868099, 51.39943833176379],
            [-2.587774976921062, 51.39859649130063],
            [-2.589928533139384, 51.398170248490231],
            [-2.590131811958493, 51.397546997149966],
            [-2.592196201945422, 51.398235236680769],
            [-2.591905801532083, 51.397675620561756],
            [-2.592579196471019, 51.397283773505031],
            [-2.593511075661491, 51.397984007421066],
            [-2.594067906180884, 51.397693449677277],
            [-2.595734077378617, 51.39793046262438],
            [-2.597509216041707, 51.398916806311618],
            [-2.597096099932144, 51.400197536196508],
            [-2.597922183789437, 51.399940655322936],
            [-2.598314721342865, 51.40016883936844],
            [-2.60093599382135, 51.400223759585977],
            [-2.600995935189844, 51.399532887415219],
            [-2.602787746832858, 51.398822326493317],
            [-2.604292902435038, 51.39937835350046],
            [-2.604870447411157, 51.400443590305485],
            [-2.606500234132952, 51.401395480581186],
            [-2.607219257742841, 51.400980833968063],
            [-2.608420312397589, 51.40136034760841],
            [-2.608888911947999, 51.401141212361104],
            [-2.610394243543439, 51.401267355529768],
            [-2.611073307700083, 51.401092972567476],
            [-2.612492666478108, 51.401347219765348],
            [-2.612342524857001, 51.402267858496494],
            [-2.613708213330308, 51.402697710217765],
            [-2.614065818928097, 51.402349654802705],
            [-2.617466325064362, 51.402399219349554],
            [-2.617362744919414, 51.401753259830706],
            [-2.619156519245605, 51.401945208128005],
            [-2.619096869169253, 51.403914712145038],
            [-2.619658142434051, 51.404384709662864],
            [-2.621924386588676, 51.404318750690273],
            [-2.622182406522959, 51.403733817472535],
            [-2.622993505517291, 51.403860786336665],
            [-2.6232285999395, 51.404128388648395],
            [-2.625928551124377, 51.4038199650048],
            [-2.625803040634582, 51.403156146570318],
            [-2.627369896304255, 51.402517445923522],
            [-2.627953200774737, 51.401862420090914],
            [-2.629585037823666, 51.402296954415199],
            [-2.630465175082618, 51.402113285703876],
            [-2.633268336409361, 51.403460414723],
            [-2.633149801566259, 51.405286377129762],
            [-2.634893649853257, 51.406010665137714],
            [-2.635462017198773, 51.406978690563243],
            [-2.633266319429659, 51.407476132723239],
            [-2.631688893204068, 51.408501612711113],
            [-2.630880063834906, 51.410113691196742],
            [-2.632350885139706, 51.410317966656002],
            [-2.63111855627729, 51.41166617629338],
            [-2.629783123986126, 51.414724252163481],
            [-2.629118740047886, 51.414720628089711],
            [-2.628735324650927, 51.416015694722205],
            [-2.631278090767208, 51.416044283250656],
            [-2.632521423757807, 51.4175679673696],
            [-2.630608722931056, 51.418709434527628],
            [-2.632460577015398, 51.420347633447882],
            [-2.634601628374229, 51.421050897789343],
            [-2.634820136223949, 51.421563140643059],
            [-2.636313845589028, 51.422144894420654],
            [-2.637083593649364, 51.423201733063379],
            [-2.634033752464332, 51.425843852724391],
            [-2.6339273526503, 51.427508791880967],
            [-2.633466477718684, 51.430272633960463],
            [-2.63349563006642, 51.435810457676723],
            [-2.629522096882856, 51.437373944467595],
            [-2.630519335615089, 51.438227288598497],
            [-2.630006435349613, 51.438488108309947],
            [-2.630290620403745, 51.439483757351169],
            [-2.634231344015281, 51.439279065935679],
            [-2.640083087623596, 51.440443143522302],
            [-2.639893046274526, 51.441360433509089],
            [-2.63896495828219, 51.441870829748751],
            [-2.637969370332057, 51.441978758907503],
            [-2.636754554819062, 51.44308324947955],
            [-2.636841579762608, 51.44427057676257],
            [-2.635591669327911, 51.444926560979361],
            [-2.635113938057013, 51.445651182124067],
            [-2.633082551147476, 51.447238412715755],
            [-2.629657045476122, 51.44613830358815],
            [-2.627002299219534, 51.44667045675741],
            [-2.62642241210948, 51.448353200652811],
            [-2.625799083121788, 51.448756659700884],
            [-2.626545067466138, 51.450374769668798],
            [-2.627818946259168, 51.452389277512005],
            [-2.628806994640179, 51.455065292327653],
            [-2.631811362039677, 51.457618050347357],
            [-2.632104006602675, 51.458485065879223],
            [-2.631599121388276, 51.459446297437772],
            [-2.631709319140461, 51.461276402837392],
            [-2.634037181955571, 51.466491574342484],
            [-2.636842593868157, 51.468121835511418],
            [-2.641472798384269, 51.469070382307287],
            [-2.646099989750282, 51.470505210685054],
            [-2.648730629128699, 51.472048032831907],
            [-2.650430344169392, 51.47352133446622],
            [-2.651100075323059, 51.47363810431581],
            [-2.651585078505017, 51.47463348073115],
            [-2.651810398137049, 51.477510449054236],
            [-2.652620835918002, 51.479208055075219],
            [-2.661050919575865, 51.48610774975522],
            [-2.662845618909884, 51.486718037999665],
            [-2.664423024622621, 51.486148930863983],
            [-2.665476689810008, 51.484033519914632],
            [-2.667205373621887, 51.482984262334973],
            [-2.667140816734667, 51.482113341075681],
            [-2.668219576002592, 51.481433724118254],
            [-2.668766512189397, 51.48121750366554],
            [-2.66783916535665, 51.48230448315131],
            [-2.668221470580785, 51.482449767323288],
            [-2.670507025292155, 51.481183284218488],
            [-2.673135777947873, 51.480251072963036],
            [-2.67624205882892, 51.47967841164067],
            [-2.680354697515423, 51.480355963256564],
            [-2.683561835250136, 51.48181463233712],
            [-2.684607607051268, 51.480487658552086],
            [-2.68493437295475, 51.480671876731122],
            [-2.684611175671892, 51.482295376453095],
            [-2.687561529955689, 51.483771200539877],
            [-2.689922399534149, 51.485521489033964],
            [-2.691187624348447, 51.486522897544184],
            [-2.69231689508392, 51.486152073229498],
            [-2.69160484580421, 51.486784591342207],
            [-2.693552747181442, 51.488345020702425],
            [-2.694488509324369, 51.488602036823714],
            [-2.694527249422352, 51.488971363280896],
            [-2.695834152904621, 51.489291808337022],
            [-2.696141005769511, 51.489958064704147],
            [-2.700248423837727, 51.492752479246427],
            [-2.701168905346802, 51.492662456667041],
            [-2.700940728137692, 51.493187133648568],
            [-2.701429879550964, 51.493450510496295],
            [-2.701909468380958, 51.493667330870636],
            [-2.703483675223175, 51.494164256605195],
            [-2.703654230862349, 51.494146481727469],
            [-2.703751769982849, 51.494248881869879],
            [-2.704527369064183, 51.494493699160998],
            [-2.70507416261387, 51.494269218220175],
            [-2.705948286911838, 51.494813346539942],
            [-2.706759477906606, 51.495055966518635],
            [-2.708867874233496, 51.495539338434462],
            [-2.710836038676939, 51.496275104842979],
            [-2.711703878373288, 51.496534614735431],
            [-2.712791880430937, 51.497668143339688],
            [-2.717511872381309, 51.500371908294525],
            [-2.712701484017222, 51.49769117191267],
            [-2.711632446887655, 51.496572813133504],
            [-2.708586543222783, 51.495602395167168],
            [-2.705910133981102, 51.494861231867489],
            [-2.704992743903309, 51.49431376678163],
            [-2.704844688466203, 51.494614078047675],
            [-2.703678265177734, 51.494356730704887],
            [-2.703606270631351, 51.494202954620896],
            [-2.703182433334028, 51.494242814417859],
            [-2.70245284590867, 51.493912985406084],
            [-2.701723591558557, 51.493608653711036],
            [-2.700881910616964, 51.493202771036749],
            [-2.701119461502428, 51.49272569373467],
            [-2.700249870776509, 51.492846882669639],
            [-2.696084260985822, 51.490015048880458],
            [-2.695738400665612, 51.489340931395994],
            [-2.694522718702552, 51.489052314778007],
            [-2.69442430376823, 51.488641980308998],
            [-2.693567318781729, 51.488451035723109],
            [-2.691553780017565, 51.486830949509077],
            [-2.691525708609484, 51.486794118128905],
            [-2.692186990186579, 51.486220621904124],
            [-2.69115390539215, 51.48657884444625],
            [-2.687458282723271, 51.483800579253121],
            [-2.684601706898661, 51.482401113126258],
            [-2.684530393936901, 51.482254965907714],
            [-2.684696731675999, 51.481339934546178],
            [-2.684750981258581, 51.480734091206493],
            [-2.683712341358075, 51.481960318309504],
            [-2.680303583935121, 51.480404814719719],
            [-2.6762308172844, 51.479758745712502],
            [-2.672983271422497, 51.48035804982608],
            [-2.670479242392886, 51.481252678675006],
            [-2.668176285556163, 51.482511167936693],
            [-2.667730555960943, 51.4823617490757],
            [-2.668622657598676, 51.481334952854823],
            [-2.668501411038481, 51.481363591202829],
            [-2.66725511127985, 51.482149556265128],
            [-2.667326515853637, 51.482996161090341],
            [-2.665889056033371, 51.483869329142756],
            [-2.664907100367343, 51.486259480810325],
            [-2.663855844132119, 51.486860683583416],
            [-2.662845641528135, 51.487017458773316],
            [-2.660529105616094, 51.486175430598486],
            [-2.658939877129717, 51.484638138204048],
            [-2.654332048805354, 51.481255799939561],
            [-2.652270236876355, 51.479361964856963],
            [-2.651439148783583, 51.47742709224962],
            [-2.651157275341663, 51.473913829750856],
            [-2.650545159488707, 51.473900144279355],
            [-2.649502139026052, 51.473148832497792],
            [-2.648676841178021, 51.472113070272201],
            [-2.647992742042184, 51.471996366036791],
            [-2.645591462390729, 51.470381230457569],
            [-2.641515920319248, 51.469168155159785],
            [-2.636802725913987, 51.468256926661248],
            [-2.633906383288016, 51.466610072246191],
            [-2.631547509925101, 51.461441821915919],
            [-2.631252832832983, 51.459593827673942],
            [-2.631569509564157, 51.458448383982372],
            [-2.63115639259353, 51.457611688387857],
            [-2.628358103845695, 51.454656781230909],
            [-2.627773481519141, 51.452536984227436],
            [-2.626218495786792, 51.450381910064955],
            [-2.624582918970269, 51.446950428085671],
            [-2.621584597520246, 51.445608627743226],
            [-2.619209993056192, 51.445367633422052],
            [-2.605543402522742, 51.446471288842112],
            [-2.597890390147288, 51.445537685274374],
            [-2.592642765592542, 51.445768450703085],
            [-2.587521898136517, 51.445004768076295],
            [-2.584795729904326, 51.445723349225069],
            [-2.581671483527071, 51.447507555280879],
            [-2.580102021030216, 51.447998186044686],
            [-2.578402676753359, 51.447779096780614],
            [-2.577392163282894, 51.447181639487965],
            [-2.577814506846242, 51.44473203106601],
            [-2.577168170290894, 51.444025774834522],
            [-2.573231439989657, 51.443108167507361],
            [-2.570843960735842, 51.442841979673929],
            [-2.566693530076726, 51.444168610355682],
            [-2.5606362805599, 51.445024077379891],
            [-2.557842624926498, 51.446378962114714],
            [-2.557600509815281, 51.447174975404522],
            [-2.558590854804445, 51.448734804305218],
            [-2.557500083829596, 51.449561833963273],
            [-2.553946557338705, 51.451063203437364],
            [-2.555261852131173, 51.450941886075874],
            [-2.558920918977179, 51.449249350607168],
            [-2.559185187007951, 51.44826530389949],
            [-2.558323352219502, 51.446742632584218],
            [-2.560593921329132, 51.445442391467239],
            [-2.562427972892174, 51.444928273189547],
            [-2.566798610477077, 51.444520574748076],
            [-2.570897756961837, 51.443232854423989],
            [-2.574816854501501, 51.44366597653525],
            [-2.576693644783389, 51.444163884895289],
            [-2.577190216741861, 51.44497518458391],
            [-2.576865363939933, 51.447284940226375],
            [-2.578355994744645, 51.448298145136647],
            [-2.581317484279139, 51.448073088062891],
            [-2.585102875666344, 51.445999658170535],
            [-2.586825616488714, 51.445465032712228],
            [-2.588450503591675, 51.445380455905017],
            [-2.591083501879971, 51.445809594691006],
            [-2.593291448423114, 51.446191372918399],
            [-2.596293399222981, 51.445995404709066],
            [-2.596530827467634, 51.446326887640744],
            [-2.598682836816192, 51.445947255900585],
            [-2.602901975504121, 51.446617084794383],
            [-2.607377772070932, 51.44677470595132],
            [-2.619196418995682, 51.445853255023579],
            [-2.621643605747355, 51.446351026269873],
            [-2.623740376562191, 51.447423381003951],
            [-2.624249041644978, 51.448318939804011],
            [-2.623056429192067, 51.448448471969371],
            [-2.62428319359311, 51.449029099164555],
            [-2.62443323329381, 51.449478782051962],
            [-2.624075170370439, 51.44949777343151],
            [-2.625341437114507, 51.450543947143728],
            [-2.626580681711976, 51.452555056417509],
            [-2.627075744129571, 51.454759860009567],
            [-2.629838029447802, 51.457614284569381],
            [-2.630268477145473, 51.459188206748316],
            [-2.630031992750678, 51.460724352270702],
            [-2.632908074965386, 51.467393243539171],
            [-2.635829243407989, 51.46896086136978],
            [-2.643892354866091, 51.47077182146672],
            [-2.649305408379374, 51.474199245986917],
            [-2.649806948214152, 51.475043478590536],
            [-2.649409140851464, 51.477041824892545],
            [-2.650775996328432, 51.479799169984332],
            [-2.648341811334141, 51.480874568411636],
            [-2.64948482171614, 51.481050771291116],
            [-2.650132856739502, 51.480647051984207],
            [-2.651251315137664, 51.480514961129785],
            [-2.652213875469653, 51.48156792384605],
            [-2.656132250561614, 51.48407450247359],
            [-2.656488103690338, 51.484886251865397],
            [-2.657141465128851, 51.484953625118095],
            [-2.658615293203352, 51.486490114540757],
            [-2.660986818374976, 51.4876501736533],
            [-2.662876043692168, 51.487921843623539],
            [-2.664682412608336, 51.487644565134502],
            [-2.666044700203386, 51.487038890931686],
            [-2.667894342610168, 51.484010779492287],
            [-2.671041780618901, 51.482198076939838],
            [-2.675185316032099, 51.48076529712241],
            [-2.678744266233963, 51.480904796535093],
            [-2.680928003438258, 51.481721161475974],
            [-2.685777829604234, 51.4845801050825],
            [-2.692383377787117, 51.489969526330164],
            [-2.696626160445152, 51.492776755187109],
            [-2.697459986930792, 51.494180785180603],
            [-2.69888806785047, 51.494313449630873],
            [-2.702481131877302, 51.495848426441661],
            [-2.708721849348217, 51.497733278077867],
            [-2.709556595095068, 51.498702921104652],
            [-2.710085228192143, 51.502219939184009],
            [-2.710624746372073, 51.502820007971302],
            [-2.714553598492399, 51.505213076933458],
            [-2.718353067458513, 51.506224796231372],
            [-2.714668965119596, 51.505679937674728],
            [-2.713695990300743, 51.505929536525969],
            [-2.717744327540054, 51.507738215527624],
            [-2.718213327863745, 51.508241571456104],
            [-2.716281066372342, 51.507138431106107],
            [-2.714138291137911, 51.507585795629844],
            [-2.714129186673823, 51.508293490649699],
            [-2.711697642212714, 51.510002292497987],
            [-2.710801435220724, 51.511209905791681],
            [-2.707946443926704, 51.512672127146686],
            [-2.707172232240181, 51.512648025606502],
            [-2.706480749779537, 51.513500101283839],
            [-2.706112491773517, 51.513449268945301],
            [-2.704454574502457, 51.514711769688446],
            [-2.702881191524377, 51.515386588933339],
            [-2.702336203025639, 51.516026458971545],
            [-2.702476255020991, 51.516792602732231],
            [-2.701140551871066, 51.516809588161053],
            [-2.70028840400404, 51.517346082971244],
            [-2.698868293997847, 51.519193334251113],
            [-2.687978234528711, 51.529422700912434],
            [-2.687028149594871, 51.529416581448665],
            [-2.687152421205435, 51.529914885163976],
            [-2.685843609623892, 51.531938454414835],
            [-2.68299296995721, 51.534889933997142],
            [-2.682039011941278, 51.535693036208492],
            [-2.681188073603703, 51.535576595634247],
            [-2.680424260973359, 51.535018158245407],
            [-2.680859004798996, 51.535553330076908],
            [-2.681537710882613, 51.535722025855819],
            [-2.681047373446309, 51.536775087355295],
            [-2.673808765808285, 51.544432732687589],
            [-2.663684151620759, 51.53699781130652],
            [-2.664472907869071, 51.536014166135175],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000024",
        LAD13CDO: "00HC",
        LAD13NM: "North Somerset",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-3.104179070124176, 51.3388139141789],
              [-3.109489759717618, 51.3384121291284],
              [-3.11094814930335, 51.3385826404771],
              [-3.111668417998898, 51.338209827443983],
              [-3.113046527194614, 51.338562714842809],
              [-3.11505184839594, 51.339618191820037],
              [-3.113563950782402, 51.34048300070792],
              [-3.108252417672194, 51.341393929219898],
              [-3.106010759637166, 51.34143130751837],
              [-3.103737473651858, 51.340690227377237],
              [-3.103798635370091, 51.340248140024435],
              [-3.103019193617517, 51.339475871191212],
              [-3.104179070124176, 51.3388139141789],
            ],
          ],
          [
            [
              [-2.730516544075092, 51.501806694920376],
              [-2.727429553580467, 51.49966250202521],
              [-2.726660799208699, 51.500511582545798],
              [-2.722453256104652, 51.501643552624074],
              [-2.719367302555562, 51.501186869433816],
              [-2.717747596262379, 51.500704054702368],
              [-2.717511872381309, 51.500371908294525],
              [-2.712791880430937, 51.497668143339688],
              [-2.711703878373288, 51.496534614735431],
              [-2.710836038676939, 51.496275104842979],
              [-2.708867874233496, 51.495539338434462],
              [-2.706759477906606, 51.495055966518635],
              [-2.705948286911838, 51.494813346539942],
              [-2.70507416261387, 51.494269218220175],
              [-2.704527369064183, 51.494493699160998],
              [-2.703751769982849, 51.494248881869879],
              [-2.703654230862349, 51.494146481727469],
              [-2.703483675223175, 51.494164256605195],
              [-2.701909468380958, 51.493667330870636],
              [-2.701429879550964, 51.493450510496295],
              [-2.700940728137692, 51.493187133648568],
              [-2.701168905346802, 51.492662456667041],
              [-2.700248423837727, 51.492752479246427],
              [-2.696141005769511, 51.489958064704147],
              [-2.695834152904621, 51.489291808337022],
              [-2.694527249422352, 51.488971363280896],
              [-2.694488509324369, 51.488602036823714],
              [-2.693552747181442, 51.488345020702425],
              [-2.69160484580421, 51.486784591342207],
              [-2.69231689508392, 51.486152073229498],
              [-2.691187624348447, 51.486522897544184],
              [-2.689922399534149, 51.485521489033964],
              [-2.687561529955689, 51.483771200539877],
              [-2.684611175671892, 51.482295376453095],
              [-2.68493437295475, 51.480671876731122],
              [-2.684607607051268, 51.480487658552086],
              [-2.683561835250136, 51.48181463233712],
              [-2.680354697515423, 51.480355963256564],
              [-2.67624205882892, 51.47967841164067],
              [-2.673135777947873, 51.480251072963036],
              [-2.670507025292155, 51.481183284218488],
              [-2.668221470580785, 51.482449767323288],
              [-2.66783916535665, 51.48230448315131],
              [-2.668766512189397, 51.48121750366554],
              [-2.668219576002592, 51.481433724118254],
              [-2.667140816734667, 51.482113341075681],
              [-2.667205373621887, 51.482984262334973],
              [-2.665476689810008, 51.484033519914632],
              [-2.664423024622621, 51.486148930863983],
              [-2.662845618909884, 51.486718037999665],
              [-2.661050919575865, 51.48610774975522],
              [-2.652620835918002, 51.479208055075219],
              [-2.651810398137049, 51.477510449054236],
              [-2.651585078505017, 51.47463348073115],
              [-2.651100075323059, 51.47363810431581],
              [-2.650430344169392, 51.47352133446622],
              [-2.648730629128699, 51.472048032831907],
              [-2.646099989750282, 51.470505210685054],
              [-2.641472798384269, 51.469070382307287],
              [-2.636842593868157, 51.468121835511418],
              [-2.634037181955571, 51.466491574342484],
              [-2.631709319140461, 51.461276402837392],
              [-2.631599121388276, 51.459446297437772],
              [-2.632104006602675, 51.458485065879223],
              [-2.631811362039677, 51.457618050347357],
              [-2.628806994640179, 51.455065292327653],
              [-2.627818946259168, 51.452389277512005],
              [-2.626545067466138, 51.450374769668798],
              [-2.625799083121788, 51.448756659700884],
              [-2.62642241210948, 51.448353200652811],
              [-2.627002299219534, 51.44667045675741],
              [-2.629657045476122, 51.44613830358815],
              [-2.633082551147476, 51.447238412715755],
              [-2.635113938057013, 51.445651182124067],
              [-2.635591669327911, 51.444926560979361],
              [-2.636841579762608, 51.44427057676257],
              [-2.636754554819062, 51.44308324947955],
              [-2.637969370332057, 51.441978758907503],
              [-2.63896495828219, 51.441870829748751],
              [-2.639893046274526, 51.441360433509089],
              [-2.640083087623596, 51.440443143522302],
              [-2.634231344015281, 51.439279065935679],
              [-2.630290620403745, 51.439483757351169],
              [-2.630006435349613, 51.438488108309947],
              [-2.630519335615089, 51.438227288598497],
              [-2.629522096882856, 51.437373944467595],
              [-2.63349563006642, 51.435810457676723],
              [-2.633466477718684, 51.430272633960463],
              [-2.6339273526503, 51.427508791880967],
              [-2.634033752464332, 51.425843852724391],
              [-2.637083593649364, 51.423201733063379],
              [-2.636313845589028, 51.422144894420654],
              [-2.634820136223949, 51.421563140643059],
              [-2.634601628374229, 51.421050897789343],
              [-2.632460577015398, 51.420347633447882],
              [-2.630608722931056, 51.418709434527628],
              [-2.632521423757807, 51.4175679673696],
              [-2.631278090767208, 51.416044283250656],
              [-2.628735324650927, 51.416015694722205],
              [-2.629118740047886, 51.414720628089711],
              [-2.629783123986126, 51.414724252163481],
              [-2.63111855627729, 51.41166617629338],
              [-2.632350885139706, 51.410317966656002],
              [-2.630880063834906, 51.410113691196742],
              [-2.631688893204068, 51.408501612711113],
              [-2.633266319429659, 51.407476132723239],
              [-2.635462017198773, 51.406978690563243],
              [-2.634893649853257, 51.406010665137714],
              [-2.633149801566259, 51.405286377129762],
              [-2.633268336409361, 51.403460414723],
              [-2.630465175082618, 51.402113285703876],
              [-2.629585037823666, 51.402296954415199],
              [-2.627953200774737, 51.401862420090914],
              [-2.627369896304255, 51.402517445923522],
              [-2.625803040634582, 51.403156146570318],
              [-2.625928551124377, 51.4038199650048],
              [-2.6232285999395, 51.404128388648395],
              [-2.622993505517291, 51.403860786336665],
              [-2.622182406522959, 51.403733817472535],
              [-2.621924386588676, 51.404318750690273],
              [-2.619658142434051, 51.404384709662864],
              [-2.619096869169253, 51.403914712145038],
              [-2.619156519245605, 51.401945208128005],
              [-2.617362744919414, 51.401753259830706],
              [-2.617466325064362, 51.402399219349554],
              [-2.614065818928097, 51.402349654802705],
              [-2.613708213330308, 51.402697710217765],
              [-2.612342524857001, 51.402267858496494],
              [-2.612492666478108, 51.401347219765348],
              [-2.611073307700083, 51.401092972567476],
              [-2.610394243543439, 51.401267355529768],
              [-2.608888911947999, 51.401141212361104],
              [-2.608420312397589, 51.40136034760841],
              [-2.607219257742841, 51.400980833968063],
              [-2.606500234132952, 51.401395480581186],
              [-2.604870447411157, 51.400443590305485],
              [-2.604292902435038, 51.39937835350046],
              [-2.602787746832858, 51.398822326493317],
              [-2.600995935189844, 51.399532887415219],
              [-2.60093599382135, 51.400223759585977],
              [-2.598314721342865, 51.40016883936844],
              [-2.597922183789437, 51.399940655322936],
              [-2.597096099932144, 51.400197536196508],
              [-2.597509216041707, 51.398916806311618],
              [-2.595734077378617, 51.39793046262438],
              [-2.594067906180884, 51.397693449677277],
              [-2.593511075661491, 51.397984007421066],
              [-2.592579196471019, 51.397283773505031],
              [-2.591905801532083, 51.397675620561756],
              [-2.592196201945422, 51.398235236680769],
              [-2.590131811958493, 51.397546997149966],
              [-2.58945941173926, 51.396900280587609],
              [-2.589867841271167, 51.396693213311885],
              [-2.587201085575743, 51.395427088064515],
              [-2.5872708264771, 51.39514889401098],
              [-2.590575300610189, 51.394259184104129],
              [-2.592658029303132, 51.396043415211459],
              [-2.593950782315825, 51.395643029975325],
              [-2.591855237901559, 51.393870575667648],
              [-2.594897300041837, 51.393484706915842],
              [-2.597081983443866, 51.3935958794629],
              [-2.597178100144485, 51.392809511753093],
              [-2.597761566964675, 51.392804737503155],
              [-2.597789738434189, 51.393091430154506],
              [-2.599690567834148, 51.393149152649336],
              [-2.599913156334088, 51.393683920464873],
              [-2.60336048372519, 51.393955749279463],
              [-2.603447322049416, 51.393455361351258],
              [-2.60443024798449, 51.393114901960992],
              [-2.604056328299445, 51.391897550765805],
              [-2.604400106610727, 51.391700656743339],
              [-2.603718436907112, 51.39101360612726],
              [-2.604187847198731, 51.389989723940445],
              [-2.603112854363237, 51.389553769015031],
              [-2.602712948528981, 51.388978557324968],
              [-2.603114783770152, 51.38882632693781],
              [-2.603887764757945, 51.389247654076087],
              [-2.60513585063917, 51.389404862941483],
              [-2.605330050211847, 51.390071946183802],
              [-2.607761278040412, 51.39079488212387],
              [-2.608352421061167, 51.390504077794645],
              [-2.607401104025164, 51.388993006178318],
              [-2.609676257885654, 51.389004565308056],
              [-2.610594408432267, 51.389530296100759],
              [-2.611659165391413, 51.389624549595048],
              [-2.612160383033744, 51.388629242378109],
              [-2.614504276735061, 51.388731165162312],
              [-2.614085719634705, 51.390100105878524],
              [-2.614981611792925, 51.391318283483791],
              [-2.617119860310375, 51.390439334818417],
              [-2.618824380544589, 51.390440233653187],
              [-2.620810976215392, 51.38972656736793],
              [-2.620801942051218, 51.389377735320934],
              [-2.622702325754859, 51.38941170816215],
              [-2.623143808893956, 51.388374409757667],
              [-2.621794389559372, 51.387953572988636],
              [-2.622384828298582, 51.386561211249763],
              [-2.620647919002534, 51.386067787891363],
              [-2.619177707098348, 51.386166385250704],
              [-2.61654255564808, 51.385769361601369],
              [-2.615457448769265, 51.385544879048396],
              [-2.615653645745697, 51.385387391492856],
              [-2.613735607200989, 51.385417238605072],
              [-2.613619015530516, 51.384114044913424],
              [-2.612057630153326, 51.383730173352035],
              [-2.614158107152191, 51.38370119550283],
              [-2.615474243595609, 51.383262683000012],
              [-2.614656444598907, 51.38217807521972],
              [-2.613020065915837, 51.382522043274236],
              [-2.612458209595009, 51.381767876312729],
              [-2.613434770823159, 51.381619797069625],
              [-2.613341804986091, 51.381329850048445],
              [-2.614084649188123, 51.381219855157084],
              [-2.613909176489082, 51.380992384238951],
              [-2.614557746364873, 51.38071023857438],
              [-2.615464111505241, 51.381115503974897],
              [-2.615693740780851, 51.381951430127053],
              [-2.616616373020726, 51.381853961679141],
              [-2.617077005318245, 51.381060260877419],
              [-2.618904635545776, 51.381478626211376],
              [-2.619122419966659, 51.382603243822963],
              [-2.618340900525592, 51.382190154310415],
              [-2.618787939185022, 51.38379911694517],
              [-2.619344230351019, 51.383812362082338],
              [-2.619324795644575, 51.382905196302119],
              [-2.620856839908611, 51.383666780585443],
              [-2.621384647744343, 51.383592946931365],
              [-2.621448675576401, 51.383864158401082],
              [-2.622165657358636, 51.383964656756731],
              [-2.622358042046275, 51.384905071919178],
              [-2.622997567719705, 51.385543683836239],
              [-2.626951079247199, 51.387136610804014],
              [-2.627628681425609, 51.386560207238041],
              [-2.627455712796168, 51.385786943487922],
              [-2.627002940125414, 51.38567427340746],
              [-2.627271953855539, 51.384959786614914],
              [-2.627848276332083, 51.384860487134901],
              [-2.628366436197991, 51.38544576979244],
              [-2.629657393543904, 51.385168185539044],
              [-2.629046118228322, 51.384395480806496],
              [-2.629746271378396, 51.384105780717491],
              [-2.628925589020291, 51.383674988687922],
              [-2.629116015276901, 51.383205495238279],
              [-2.628617841749961, 51.38292403006691],
              [-2.628903517213002, 51.382694105805122],
              [-2.629192063488342, 51.382464165411754],
              [-2.629922505894298, 51.382812716716892],
              [-2.629560780313966, 51.3830502456453],
              [-2.630629726314585, 51.38345721752539],
              [-2.630203784790788, 51.383727464461771],
              [-2.63084604848411, 51.384036021432884],
              [-2.631790801158363, 51.383248646134788],
              [-2.630289233761558, 51.382521209148926],
              [-2.631133275161988, 51.381830592684615],
              [-2.629546757377772, 51.382342669154134],
              [-2.628181340467013, 51.382109020422838],
              [-2.627324791792343, 51.380522967545552],
              [-2.629929545474328, 51.380395692100336],
              [-2.631232543201264, 51.380690800865246],
              [-2.631645164089906, 51.379768718551432],
              [-2.631111834782369, 51.378914677666465],
              [-2.630616860087317, 51.378863392953271],
              [-2.631157986239153, 51.377987377348987],
              [-2.631462518120069, 51.377773530152751],
              [-2.633819916186899, 51.378161830537955],
              [-2.634754609904958, 51.377905898504601],
              [-2.632362486078692, 51.377186907130657],
              [-2.629792699214252, 51.375451852664341],
              [-2.630020771808831, 51.375215940756974],
              [-2.632157400530075, 51.374398765275487],
              [-2.632289179679862, 51.374057265564701],
              [-2.634054728649307, 51.375191475475866],
              [-2.63403314886432, 51.37560521404621],
              [-2.634555410239956, 51.374307569757441],
              [-2.636489445573694, 51.374843776049858],
              [-2.637666813978452, 51.374377892201458],
              [-2.637956820142267, 51.373639886135301],
              [-2.638623722386279, 51.373247806829013],
              [-2.64014687799278, 51.373990305862293],
              [-2.63940088359651, 51.374789254065092],
              [-2.640156480677134, 51.374987441891008],
              [-2.639605720979045, 51.375672926797954],
              [-2.640222595558552, 51.375504107868018],
              [-2.641236722158043, 51.374461807983053],
              [-2.642518999811811, 51.375335975481995],
              [-2.642957670323177, 51.374857003898974],
              [-2.644402614537408, 51.375136800214484],
              [-2.643368030276831, 51.374613770820204],
              [-2.64630785108148, 51.373006040084093],
              [-2.647788541749186, 51.371546585775427],
              [-2.649210923561678, 51.369323133119188],
              [-2.649877946848618, 51.368950770427652],
              [-2.650524262320722, 51.365800953080694],
              [-2.649606491675633, 51.362357691579419],
              [-2.650171216679346, 51.360347587032464],
              [-2.650154705876726, 51.357559318855692],
              [-2.650124206975006, 51.355709872420427],
              [-2.649528390682459, 51.354300564461958],
              [-2.650550105546696, 51.353740098171052],
              [-2.651486112810304, 51.352302498205695],
              [-2.651325921145778, 51.350930338317411],
              [-2.651865191549589, 51.350581152609521],
              [-2.651881206448711, 51.346247003659904],
              [-2.651175002553403, 51.345046926872911],
              [-2.652524052999825, 51.343894757141705],
              [-2.65377423553183, 51.343366257656527],
              [-2.654436959320286, 51.343426397772561],
              [-2.655163075571362, 51.342595987838806],
              [-2.657224404869406, 51.342372229312559],
              [-2.658969331632437, 51.341510895745174],
              [-2.661102320759619, 51.341582497035461],
              [-2.660336364609356, 51.342429354153353],
              [-2.660737808472525, 51.343089788090026],
              [-2.663420319799852, 51.342733830562302],
              [-2.66974376259362, 51.343169906408534],
              [-2.670917342540837, 51.340950295894821],
              [-2.673026353666495, 51.341247512734007],
              [-2.673370218661768, 51.343159000117424],
              [-2.67653141919947, 51.341972738852661],
              [-2.676959509368657, 51.345691988342466],
              [-2.675429981991528, 51.345761964893342],
              [-2.676448584505056, 51.343562076175857],
              [-2.674155919022926, 51.343477286542416],
              [-2.673109099843145, 51.345522657711768],
              [-2.671202608683557, 51.34524406359381],
              [-2.670989472328235, 51.346576076880879],
              [-2.673647373618984, 51.347176758041627],
              [-2.674076864393137, 51.346699517626128],
              [-2.676542409989251, 51.347592575773767],
              [-2.677131616275321, 51.348600751837921],
              [-2.676318204310544, 51.34972313730114],
              [-2.675614154530053, 51.349595021293112],
              [-2.675502780995388, 51.350028170926862],
              [-2.676344482854285, 51.351409853175092],
              [-2.676792079984806, 51.351186068238228],
              [-2.677523914711162, 51.352222191317594],
              [-2.67797574769355, 51.353549467903029],
              [-2.673110424224641, 51.353841892896043],
              [-2.673474824283241, 51.354894538933785],
              [-2.674259069833363, 51.354904413249699],
              [-2.674665857963038, 51.35590735638749],
              [-2.670918430700351, 51.356946758880184],
              [-2.669489532711327, 51.356951339316574],
              [-2.668403363965832, 51.358992391733267],
              [-2.668748345910771, 51.36078698686746],
              [-2.6695404610288, 51.361821914672646],
              [-2.671721359052734, 51.361666456745731],
              [-2.672363925390942, 51.361017157353594],
              [-2.673597578549468, 51.361597233981385],
              [-2.676314444629143, 51.361946644203869],
              [-2.677822601750231, 51.361560269193248],
              [-2.67718795799972, 51.360600017806462],
              [-2.679700166158725, 51.360127781346257],
              [-2.680225231975905, 51.361638055796085],
              [-2.680840058055782, 51.361934809924719],
              [-2.680092010185723, 51.363019073138069],
              [-2.680862968209395, 51.364350777284628],
              [-2.680791503843271, 51.366311407528976],
              [-2.682222001349068, 51.366588124192646],
              [-2.681471899866343, 51.367149983176503],
              [-2.682178895695494, 51.369293111021342],
              [-2.682339354540276, 51.369553837882684],
              [-2.685410930650096, 51.368801272864594],
              [-2.686237134986168, 51.373233890643519],
              [-2.686603621844597, 51.374395281888489],
              [-2.687088937828022, 51.374663988370145],
              [-2.686606399798114, 51.374772921179009],
              [-2.687819905064678, 51.377709715928113],
              [-2.689554929457044, 51.380153375421784],
              [-2.694190150648192, 51.379859840017893],
              [-2.693983766445791, 51.377899952255206],
              [-2.695280248794047, 51.376130770325744],
              [-2.694963898350066, 51.375449270829442],
              [-2.695568754198083, 51.36957313186435],
              [-2.695174093686108, 51.36940912644264],
              [-2.694991450519563, 51.368447187325167],
              [-2.696141647954691, 51.368417877160866],
              [-2.696196709751144, 51.367786324593823],
              [-2.696750347243635, 51.367446738573044],
              [-2.696456638957134, 51.366645516343702],
              [-2.695579005610475, 51.367121902863865],
              [-2.694986567884655, 51.367081359862922],
              [-2.694255686046539, 51.366055232498411],
              [-2.694046316039003, 51.364843477338553],
              [-2.694569860544723, 51.364604788095285],
              [-2.69416506330316, 51.364052392799792],
              [-2.696690151730113, 51.362440452237486],
              [-2.695170191246354, 51.361944143532241],
              [-2.696455876228422, 51.361388903152118],
              [-2.6957105447787, 51.360068837244349],
              [-2.694728385034581, 51.359989245101893],
              [-2.694698182232308, 51.359511958332604],
              [-2.693824754362805, 51.359023484733342],
              [-2.694325082493959, 51.359058285271217],
              [-2.693909968178736, 51.358389955282398],
              [-2.693103831318088, 51.358266145743812],
              [-2.693009766428722, 51.357172396958745],
              [-2.692236437825275, 51.357032202041964],
              [-2.692067508887457, 51.358020503095318],
              [-2.690873670165224, 51.356850521558634],
              [-2.691624801172652, 51.356947695775617],
              [-2.691786095500917, 51.356214808254386],
              [-2.689927685478589, 51.352865523735453],
              [-2.690497911090616, 51.349531586713731],
              [-2.69193996835119, 51.3488909486816],
              [-2.694869883462715, 51.344381274296815],
              [-2.699279730418496, 51.343103358142386],
              [-2.701731128440493, 51.343990576664254],
              [-2.704051362360632, 51.343527057119324],
              [-2.70483396414304, 51.342412754359422],
              [-2.704778824889424, 51.341813329407046],
              [-2.705153943162314, 51.341746329758976],
              [-2.705129207243299, 51.342005444190185],
              [-2.705849932581688, 51.341819466571003],
              [-2.707532044720306, 51.340761763642327],
              [-2.707750055210154, 51.340375594582873],
              [-2.707271450692319, 51.339967558272811],
              [-2.707888487725354, 51.337806681930275],
              [-2.705765726512288, 51.337740367052128],
              [-2.703159485836287, 51.336755252004608],
              [-2.701917459043958, 51.33701717350435],
              [-2.701612657287027, 51.336516355048786],
              [-2.699940457135025, 51.335679329045981],
              [-2.698841743657213, 51.335830660519569],
              [-2.698355801476694, 51.33549816318699],
              [-2.697753925163812, 51.335565593483764],
              [-2.697577003029333, 51.335163812118097],
              [-2.694134059299086, 51.333407480219066],
              [-2.692663106174359, 51.332192398028489],
              [-2.689011804971195, 51.330871455128602],
              [-2.687715755922681, 51.330779264810651],
              [-2.68717843851236, 51.330361600106848],
              [-2.693544824967617, 51.321947231993946],
              [-2.693356869014062, 51.321664201730044],
              [-2.695124751620105, 51.318526341049719],
              [-2.69487577144969, 51.318098006772104],
              [-2.696388860520559, 51.317573781302102],
              [-2.697494275586154, 51.318081537103055],
              [-2.696850756798793, 51.316144019800831],
              [-2.699542397017691, 51.316603639109914],
              [-2.699528928202605, 51.316850097450704],
              [-2.703138325676449, 51.317079349742791],
              [-2.708619774198127, 51.317180280508829],
              [-2.716837366391266, 51.316804748029185],
              [-2.720926326919203, 51.321531859228152],
              [-2.721445101684679, 51.319474914622759],
              [-2.725402438517655, 51.318362442869393],
              [-2.728059241605296, 51.317230056501955],
              [-2.729810117455031, 51.318061696960726],
              [-2.732555559473055, 51.318807963883437],
              [-2.733038703889031, 51.318870580820246],
              [-2.733019512828994, 51.318386936522849],
              [-2.733802885382775, 51.318200392848766],
              [-2.73386121121557, 51.318528231809466],
              [-2.736260102671727, 51.319223531461525],
              [-2.735996616568663, 51.319616335930313],
              [-2.73938787095984, 51.319818867169559],
              [-2.744581066722185, 51.31928510694754],
              [-2.747754656531695, 51.31977921866077],
              [-2.748983870549917, 51.319127540956515],
              [-2.7528473311314, 51.320123344160535],
              [-2.753936577082352, 51.319778239301179],
              [-2.754930408662988, 51.32052715600777],
              [-2.75534793460817, 51.321396678462143],
              [-2.754785157610931, 51.322867787268656],
              [-2.754801330508936, 51.32455186704842],
              [-2.756278982315994, 51.324606174543888],
              [-2.75649616573185, 51.323937572089008],
              [-2.761477427805999, 51.322115891944286],
              [-2.764639431956225, 51.322580847974557],
              [-2.765155298815431, 51.322448893551901],
              [-2.768316177100296, 51.323701449364862],
              [-2.773311339725192, 51.323417690329684],
              [-2.778662529488715, 51.323568358126799],
              [-2.785412576400303, 51.324198566843307],
              [-2.786519551540854, 51.324814276141495],
              [-2.789322752789357, 51.324667727263943],
              [-2.791209620942571, 51.325147749434336],
              [-2.795231084068599, 51.325321924942578],
              [-2.795642427432547, 51.324958554388374],
              [-2.797207899374042, 51.325852493670133],
              [-2.797617366312861, 51.326787562557584],
              [-2.798461596747118, 51.326971538169005],
              [-2.799780753544843, 51.326157758062713],
              [-2.801958968956667, 51.326545697704489],
              [-2.806464336649108, 51.326151479592411],
              [-2.807991358277091, 51.326231770631985],
              [-2.812305605522641, 51.327209016248524],
              [-2.814915451163081, 51.327081180112351],
              [-2.815456779853533, 51.326771685771639],
              [-2.818667353720594, 51.326368923571259],
              [-2.818906415671295, 51.32407880464028],
              [-2.818466747085157, 51.322760064145626],
              [-2.818992983610004, 51.322654775541771],
              [-2.818361118737173, 51.32134097535846],
              [-2.818900845647006, 51.321268862990948],
              [-2.818671119913486, 51.320375771383127],
              [-2.818185569603088, 51.320346794416359],
              [-2.81792154717479, 51.319057396236801],
              [-2.814897941748755, 51.318807822048633],
              [-2.814331528369587, 51.318344181930946],
              [-2.81271375976474, 51.31831136211315],
              [-2.811876325603252, 51.317287594708922],
              [-2.81050955662304, 51.3171648827309],
              [-2.81014329816593, 51.315597424337319],
              [-2.807803437198896, 51.314885244333098],
              [-2.807667130188075, 51.312843214628714],
              [-2.807100824882785, 51.311968606660237],
              [-2.807434694458583, 51.311617416944344],
              [-2.808304534596227, 51.311721116511393],
              [-2.810310611543168, 51.311331385682884],
              [-2.812062963457298, 51.310712287364758],
              [-2.812186581178698, 51.308938216504401],
              [-2.81076740577614, 51.307293533355008],
              [-2.80995817444753, 51.305576275581437],
              [-2.810230834832637, 51.301428194822812],
              [-2.820645811556691, 51.300844904628796],
              [-2.821747843689715, 51.301108731678333],
              [-2.82957139322923, 51.300884503979177],
              [-2.853154250655998, 51.299479514437458],
              [-2.856542857141693, 51.300130068488713],
              [-2.864371885310508, 51.300138165668663],
              [-2.867939555201226, 51.302209601664828],
              [-2.87713942992654, 51.300066578761992],
              [-2.879183395750561, 51.303067163206009],
              [-2.880299672656618, 51.303904921785325],
              [-2.881427294216691, 51.303535858215056],
              [-2.882224969270529, 51.304662841547383],
              [-2.884913127827283, 51.306668450308635],
              [-2.886194396043317, 51.30593310453974],
              [-2.886790036638364, 51.305055471216384],
              [-2.888116971181058, 51.304906036518368],
              [-2.893518863237087, 51.295053750223722],
              [-2.89709685484029, 51.290618495732311],
              [-2.913162961775684, 51.293652270376327],
              [-2.914644731562149, 51.294242269043899],
              [-2.917949941223831, 51.294641720891711],
              [-2.919203815121894, 51.295737002573922],
              [-2.924158852242271, 51.298435164340361],
              [-2.925287232471427, 51.298555736354018],
              [-2.927913560212779, 51.297989132257058],
              [-2.931642288343055, 51.299097009680779],
              [-2.93375835122914, 51.301942763745956],
              [-2.936422493470049, 51.303018062088796],
              [-2.937609404596818, 51.303047227982333],
              [-2.939016015306244, 51.30259534462062],
              [-2.950701088157793, 51.302728534790205],
              [-2.952115957705149, 51.30323677626059],
              [-2.953282361540122, 51.30442142690562],
              [-2.958216654402255, 51.304742624578729],
              [-2.961771726608853, 51.304824974380502],
              [-2.962777413297402, 51.304485801063464],
              [-2.962972382693148, 51.302149862978354],
              [-2.962289293090454, 51.299941637895515],
              [-2.964300019632944, 51.29787490503174],
              [-2.969792019563017, 51.29617230139565],
              [-2.972094116258355, 51.295769246725236],
              [-2.973926644113512, 51.295747720794239],
              [-2.975314794935306, 51.296346724746812],
              [-2.977204348006913, 51.297388433355039],
              [-2.981297746211988, 51.297584389159518],
              [-2.983343899925212, 51.298293778329807],
              [-2.986632766859119, 51.298290384774049],
              [-2.98889433873131, 51.298739789207623],
              [-2.991964347438116, 51.299893575073611],
              [-2.993179787322241, 51.301046843190775],
              [-2.992774987692222, 51.301166274304137],
              [-2.994146401802315, 51.303029481690373],
              [-2.994324176404774, 51.306210261967806],
              [-2.992570529024164, 51.307579346281763],
              [-2.989774368678863, 51.307976188972432],
              [-2.98823037127178, 51.307760833807407],
              [-2.98731240481722, 51.308237963757726],
              [-2.985929005577425, 51.307684021934243],
              [-2.987166232055566, 51.308446013505502],
              [-2.988256257272247, 51.308164358410629],
              [-2.989100771420663, 51.3083415637646],
              [-2.987063164873343, 51.309659909921706],
              [-2.985611940175091, 51.311633301166083],
              [-2.984357397650171, 51.311874951095682],
              [-2.985315078713846, 51.311907359590954],
              [-2.985158549919193, 51.312972435466612],
              [-2.985884826832536, 51.314591183923376],
              [-2.985217304887326, 51.315246028083656],
              [-2.986957570840164, 51.316906588096643],
              [-2.985814887718852, 51.318289298539426],
              [-2.986049084922457, 51.319507545431136],
              [-2.985159129169983, 51.319294732037442],
              [-2.985966483149298, 51.320540526304377],
              [-2.985865039525716, 51.321029647762131],
              [-2.985172992579531, 51.321414936301245],
              [-2.986148213707041, 51.321051541618253],
              [-2.985926114929969, 51.320063389354026],
              [-2.986329720053424, 51.319612186910895],
              [-2.986383067352831, 51.317951808015536],
              [-2.987412944751557, 51.317063706126049],
              [-2.987278475701266, 51.316414715748067],
              [-2.986336288971937, 51.315503670379691],
              [-2.986510866329288, 51.315147014013604],
              [-2.988261177459883, 51.316004481112401],
              [-2.988789820924858, 51.31663305738406],
              [-2.990406407324275, 51.317140033030547],
              [-2.991627000234021, 51.318113430859341],
              [-2.992430498757745, 51.320503000894291],
              [-2.991098458391003, 51.321952109735669],
              [-2.991323064794635, 51.32211925854741],
              [-2.991923940820799, 51.321767077297601],
              [-2.988008638362352, 51.329091813590779],
              [-2.984467152865058, 51.340118868963252],
              [-2.982840275768541, 51.347016805440624],
              [-2.982768596251645, 51.349572030671588],
              [-2.983398287004686, 51.350679952710024],
              [-2.985072992532789, 51.351700856006644],
              [-2.986518840796192, 51.351958445545471],
              [-2.987172263757655, 51.35196013329498],
              [-2.988550368465166, 51.351337953375385],
              [-2.988759718448956, 51.35085691321062],
              [-2.990011874149753, 51.351055848431372],
              [-2.990124370891896, 51.35174458284645],
              [-2.988636849071007, 51.35195317443192],
              [-2.988784496006749, 51.353208108263111],
              [-2.989205756477378, 51.353431148959935],
              [-2.989937553686401, 51.353203762952674],
              [-2.993924488637021, 51.354020637580867],
              [-2.99392261971417, 51.35453139820752],
              [-2.994651678777742, 51.354840827532819],
              [-2.99493521444007, 51.355466059760566],
              [-2.99412593146524, 51.357156230908267],
              [-2.992405973135538, 51.357648291329738],
              [-2.992585296591292, 51.357838300902031],
              [-2.991634676944356, 51.358443420662098],
              [-2.988149864347527, 51.359085226833059],
              [-2.987490991710645, 51.35950261616626],
              [-2.986215858102065, 51.359447718665407],
              [-2.984611728741124, 51.360258802743253],
              [-2.982000999925525, 51.360546883068977],
              [-2.981641105606177, 51.360911377073514],
              [-2.980366384499407, 51.36094453363706],
              [-2.979758210094825, 51.361319192445642],
              [-2.978677988877936, 51.361111517149389],
              [-2.973295400250665, 51.362362191412927],
              [-2.971126662156929, 51.362504282087535],
              [-2.970298366008286, 51.362730550560343],
              [-2.969574263543227, 51.363401951775963],
              [-2.969187964963477, 51.362997813224247],
              [-2.968319956304209, 51.363317013389228],
              [-2.966187585075438, 51.364575512497076],
              [-2.965112270357593, 51.365763222679028],
              [-2.963291707509122, 51.369841670242941],
              [-2.962206509489123, 51.374746700877473],
              [-2.96249531073635, 51.38234970297264],
              [-2.963694933020712, 51.383227343405281],
              [-2.966894937994817, 51.383628091542434],
              [-2.967431030505946, 51.384722478773824],
              [-2.966843238002467, 51.385197609010717],
              [-2.968790529490673, 51.385269642344255],
              [-2.971878023317208, 51.386252063529533],
              [-2.972867201159219, 51.388170822255056],
              [-2.979183219522539, 51.388092124703284],
              [-2.980791180353469, 51.388418568658963],
              [-2.977741602565789, 51.38855645912399],
              [-2.974652696035281, 51.389279072284175],
              [-2.973583550737573, 51.389216926050118],
              [-2.971868288631123, 51.389658284252405],
              [-2.972101095791182, 51.390132025221078],
              [-2.969102546386166, 51.390718862762128],
              [-2.965056893201233, 51.392296170291623],
              [-2.960638603636985, 51.392291119676251],
              [-2.958646003579138, 51.392677908129741],
              [-2.957824414507688, 51.392015630199793],
              [-2.956821381445644, 51.392093064134187],
              [-2.955836187286337, 51.392612745693313],
              [-2.954384245116721, 51.392806219183797],
              [-2.95400561686167, 51.393329035038242],
              [-2.952868684501432, 51.393742923245135],
              [-2.951564321815557, 51.393847040579288],
              [-2.951450992226716, 51.394480990811111],
              [-2.948317446994661, 51.395864173508201],
              [-2.944269281905267, 51.397166526141291],
              [-2.939809559942088, 51.398178005299258],
              [-2.937373769821098, 51.39807163248927],
              [-2.937308860551524, 51.39686544048898],
              [-2.936757633772297, 51.396479601234439],
              [-2.937384064565534, 51.395903605849377],
              [-2.93707808506834, 51.395490628282509],
              [-2.93737370254623, 51.394553106734286],
              [-2.938034427790781, 51.394390460264333],
              [-2.937751543919358, 51.393841521854853],
              [-2.93812267603223, 51.392872821174002],
              [-2.939169617878604, 51.391919384773232],
              [-2.939170401412199, 51.391045366044686],
              [-2.937504264158362, 51.392854392573632],
              [-2.936468193622477, 51.392724203732094],
              [-2.93603798812889, 51.393335493759935],
              [-2.935125164519294, 51.392483158420106],
              [-2.933856868015215, 51.39273515699346],
              [-2.93400952695657, 51.39169177956115],
              [-2.93271450008961, 51.391547436446579],
              [-2.932564665631634, 51.390822983381241],
              [-2.930705488142523, 51.389719176424919],
              [-2.932076064478894, 51.390967145295882],
              [-2.932168216645042, 51.391753202802491],
              [-2.933346637538769, 51.39195872668801],
              [-2.933534692261224, 51.393147754944138],
              [-2.93487982589268, 51.392927517603837],
              [-2.93587763119986, 51.393718031105841],
              [-2.937285637311618, 51.393409143363641],
              [-2.9357796747347, 51.394974980798594],
              [-2.93454817326367, 51.395131378631412],
              [-2.933753852341636, 51.394663839575678],
              [-2.93159446108661, 51.394356423137843],
              [-2.917336577231699, 51.395889647413632],
              [-2.914705351481778, 51.395416566140369],
              [-2.914278477776594, 51.395561969853951],
              [-2.913942126195307, 51.394912683070736],
              [-2.911680483192206, 51.394516678043004],
              [-2.909288765833192, 51.393647767139662],
              [-2.908716646777946, 51.39284653392329],
              [-2.907762251133097, 51.39248166910896],
              [-2.906224685699188, 51.392352400279705],
              [-2.904236897221465, 51.391621433794818],
              [-2.899913483602238, 51.391393060323743],
              [-2.8986184252189, 51.390214275167061],
              [-2.898254247070772, 51.389231558265472],
              [-2.895352737181782, 51.387840246473068],
              [-2.894890106353868, 51.387993945733704],
              [-2.892060919919925, 51.387430152128395],
              [-2.891658869730774, 51.387743432713727],
              [-2.890576255768606, 51.387571830278794],
              [-2.891235727231998, 51.387859051071615],
              [-2.890408214963909, 51.388169269002688],
              [-2.89242396767268, 51.387919242747827],
              [-2.892828783499616, 51.38833877645606],
              [-2.89308839921149, 51.388091319329696],
              [-2.895491090539333, 51.388522571739401],
              [-2.897269989219315, 51.389315531886488],
              [-2.89768330477299, 51.390533462425566],
              [-2.899319675025956, 51.392260840171659],
              [-2.898991829202671, 51.392839735494235],
              [-2.898251784025358, 51.393141244555537],
              [-2.896798914956842, 51.392638939146678],
              [-2.895820224133733, 51.39271746259444],
              [-2.898106465928729, 51.393353667649556],
              [-2.89925386059956, 51.393082303436593],
              [-2.900188576662782, 51.392081522910793],
              [-2.903558868045875, 51.39222282659442],
              [-2.905562429933318, 51.393169487664998],
              [-2.907096702224636, 51.393276311043245],
              [-2.909915653059648, 51.394890073290853],
              [-2.912573277493906, 51.395393628690883],
              [-2.913017745785005, 51.395987226349902],
              [-2.909462043911699, 51.398832934974877],
              [-2.906827444855456, 51.39993777581833],
              [-2.899023831276905, 51.404880495285731],
              [-2.898199800627411, 51.405014499593413],
              [-2.895630975905955, 51.406868501943777],
              [-2.89550306732872, 51.407606811283664],
              [-2.893843999317232, 51.408717384172981],
              [-2.893938271803786, 51.409643724274687],
              [-2.88828069893536, 51.414847167574145],
              [-2.887713819077401, 51.41483078239451],
              [-2.887013715677293, 51.414097854682005],
              [-2.887334770345323, 51.413449810060371],
              [-2.8888959602571, 51.413428985204767],
              [-2.88892437326061, 51.41281822390664],
              [-2.887570424655873, 51.412179277055031],
              [-2.886594148131703, 51.412553535154295],
              [-2.887670590469252, 51.412302605627914],
              [-2.888671328419308, 51.412745511087778],
              [-2.888874138053516, 51.413193564621352],
              [-2.887004522784674, 51.413400157854852],
              [-2.886728840500226, 51.414163852879426],
              [-2.887510801979474, 51.415188396883728],
              [-2.887071547271577, 51.417159135838915],
              [-2.886143210845522, 51.418158857908573],
              [-2.88513186584056, 51.420744458129278],
              [-2.883790043696576, 51.422681533368284],
              [-2.882906183621244, 51.425550291614144],
              [-2.881550879387023, 51.426500139592214],
              [-2.881716570201677, 51.426813606490612],
              [-2.879701280435158, 51.42792845081371],
              [-2.878058860791674, 51.427789706244155],
              [-2.87720320564087, 51.428324832715546],
              [-2.875798868696995, 51.427774247626672],
              [-2.875949610023766, 51.428059959636023],
              [-2.874932185964878, 51.428712272797938],
              [-2.874284551134917, 51.428769258771524],
              [-2.874677683597395, 51.429169158027335],
              [-2.875655191393584, 51.429362375225445],
              [-2.875193558852629, 51.429668847562887],
              [-2.875688008562983, 51.42995019406694],
              [-2.875693241799152, 51.430524731260675],
              [-2.876225829297061, 51.429415657472241],
              [-2.875661286814227, 51.428703230287198],
              [-2.876174585045762, 51.428466505843375],
              [-2.877262988658909, 51.429042830618897],
              [-2.876703807887553, 51.429662954005387],
              [-2.876812606301004, 51.4303868795254],
              [-2.879040912991556, 51.431067950217475],
              [-2.879347413778098, 51.431672597569431],
              [-2.878383245556956, 51.432328137307898],
              [-2.872181632877638, 51.434586438383086],
              [-2.870216880243266, 51.435816734642195],
              [-2.867264700008505, 51.436570550405804],
              [-2.865995789005849, 51.437406279124112],
              [-2.865978972283949, 51.438190487284459],
              [-2.864962715409181, 51.439228450784441],
              [-2.861982407899529, 51.440567704852882],
              [-2.861573151912763, 51.442521031215726],
              [-2.862053626789719, 51.442744990124481],
              [-2.862026839066749, 51.443385401733437],
              [-2.861631492071388, 51.443938605612708],
              [-2.860167531340798, 51.445590357848751],
              [-2.855358918914895, 51.449456942247082],
              [-2.853835548583574, 51.450182894856511],
              [-2.852008194219187, 51.451821003677857],
              [-2.85200274448864, 51.452301203222014],
              [-2.852690358466456, 51.452748488364215],
              [-2.850747237098545, 51.454437772396396],
              [-2.845842582758879, 51.456861477718178],
              [-2.83856797372801, 51.460528427577017],
              [-2.837362177451009, 51.461731150377382],
              [-2.83066524773796, 51.464866559817011],
              [-2.829807323345536, 51.465582982686833],
              [-2.82506730014677, 51.466965199825154],
              [-2.823891016077668, 51.467038213534217],
              [-2.820823887919309, 51.469803103707264],
              [-2.819302701396626, 51.470558259320491],
              [-2.819501051584874, 51.470780766554242],
              [-2.818646194421907, 51.471443134595411],
              [-2.819077786348527, 51.471906789692952],
              [-2.817232562660941, 51.47330708878183],
              [-2.815583510379451, 51.474894819420115],
              [-2.810176573063163, 51.477804260699436],
              [-2.809311347051564, 51.479278581106243],
              [-2.808131214655616, 51.479641894021569],
              [-2.805591934920784, 51.481408254683039],
              [-2.802972169312481, 51.482703047028927],
              [-2.800310235311215, 51.484970069085591],
              [-2.797458054973533, 51.486006463317622],
              [-2.795050976855073, 51.485729682593316],
              [-2.791079850556537, 51.486532526843995],
              [-2.789183388650323, 51.486467080284726],
              [-2.784537770420176, 51.487169038175786],
              [-2.778245331027358, 51.489450800577764],
              [-2.778859925224201, 51.490017690126784],
              [-2.777443081617753, 51.49097481514346],
              [-2.77544669022873, 51.491650721589501],
              [-2.774092534150429, 51.491469046816562],
              [-2.774325526967971, 51.491958452940239],
              [-2.772535141680544, 51.494004176501647],
              [-2.773564725908197, 51.494664568990508],
              [-2.76667603370923, 51.494730496891691],
              [-2.761434919468014, 51.495209737760668],
              [-2.759065508026468, 51.494961646028905],
              [-2.758672612434948, 51.495156610257112],
              [-2.756699534949612, 51.494049900659547],
              [-2.755650661203516, 51.493775226712984],
              [-2.756894971199805, 51.492630656004152],
              [-2.755850196849688, 51.492340671579676],
              [-2.754360934660237, 51.492434784544038],
              [-2.753024024494398, 51.49198480454082],
              [-2.752562244633445, 51.492282695599862],
              [-2.752613523824902, 51.493122186433176],
              [-2.751232283548667, 51.492954808347911],
              [-2.748543366369811, 51.492800265895561],
              [-2.741639629523048, 51.493166039908054],
              [-2.737146294777381, 51.494018910002218],
              [-2.73678293127648, 51.493996017380418],
              [-2.736889542399313, 51.493729194841052],
              [-2.736058181596525, 51.493983485413516],
              [-2.735617743868605, 51.493557348414953],
              [-2.735962981512501, 51.492902388738955],
              [-2.734927114602096, 51.492352303889284],
              [-2.73258720406155, 51.492488335046218],
              [-2.732054942714069, 51.492890888400062],
              [-2.731192535263429, 51.49291605155441],
              [-2.731547402927093, 51.493128738060307],
              [-2.732681615566558, 51.492627115507013],
              [-2.734608939072432, 51.49245140761343],
              [-2.7355897844422, 51.49298385719537],
              [-2.73505121666825, 51.493530329960365],
              [-2.735446984088554, 51.494313717344738],
              [-2.733193256512519, 51.494171378096411],
              [-2.733877264892099, 51.494248023420262],
              [-2.732557321418784, 51.495565460143098],
              [-2.730130496734879, 51.49694372934276],
              [-2.72808302382003, 51.497638027110234],
              [-2.727321669600156, 51.498406141748163],
              [-2.727242079582582, 51.49874561981958],
              [-2.727937561660468, 51.499086582557013],
              [-2.727287646467853, 51.498983615321556],
              [-2.726940851379547, 51.499279792145131],
              [-2.730606800352693, 51.501594829377339],
              [-2.730521025789961, 51.502267038173109],
              [-2.728762682799563, 51.502676308839575],
              [-2.730296745526741, 51.502206393078225],
              [-2.730516544075092, 51.501806694920376],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000025",
        LAD13CDO: "00HD",
        LAD13NM: "South Gloucestershire",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.532088734815307, 51.675830337300816],
            [-2.509861327316301, 51.667911441858607],
            [-2.509005562629944, 51.665998212720574],
            [-2.505351331803874, 51.666215404548367],
            [-2.50136428426336, 51.66536484041319],
            [-2.501673949480123, 51.664733228192489],
            [-2.500844878934441, 51.664416678306793],
            [-2.500076285041128, 51.664864124518367],
            [-2.498637568851098, 51.664987131037662],
            [-2.496423529930705, 51.664418374545861],
            [-2.495195533580992, 51.664639351822608],
            [-2.492540156657049, 51.664164993283876],
            [-2.490835682984524, 51.664313295442923],
            [-2.489640491843093, 51.66395863513992],
            [-2.489816449063796, 51.663382458740379],
            [-2.491142021706421, 51.663227664980134],
            [-2.491678723906049, 51.662855876370251],
            [-2.49180200474804, 51.660355781163879],
            [-2.4927050469481, 51.657775988455874],
            [-2.49170195390413, 51.655922592263664],
            [-2.491403794806978, 51.653750643384633],
            [-2.493034448998221, 51.651898789955034],
            [-2.49184520741262, 51.649662246173385],
            [-2.489350736303924, 51.64887873949187],
            [-2.484970755822538, 51.648416782096852],
            [-2.486435393260526, 51.647385712151156],
            [-2.487569064065113, 51.647033943867413],
            [-2.487947861937001, 51.646508176270608],
            [-2.490050639944627, 51.645696491576643],
            [-2.490835128120656, 51.645135752122435],
            [-2.490675759243774, 51.644831612855654],
            [-2.487262057441959, 51.644686687694588],
            [-2.485572346160849, 51.645067728818816],
            [-2.482152709006294, 51.646406246186466],
            [-2.474718466222161, 51.646562427533631],
            [-2.473060180530847, 51.646785813253665],
            [-2.470962790556042, 51.647593574890294],
            [-2.470335717090147, 51.647472009309986],
            [-2.465790742710869, 51.651042596980197],
            [-2.463714438825745, 51.650974392011506],
            [-2.462748987062245, 51.65197533707618],
            [-2.460442611962514, 51.652599411651643],
            [-2.458780003975429, 51.651278806604374],
            [-2.455800758680173, 51.651830790364407],
            [-2.455951498494997, 51.652304046135498],
            [-2.452520802250564, 51.651368741020427],
            [-2.449208160976914, 51.6497873089615],
            [-2.445656754617732, 51.649331501408057],
            [-2.445043610375186, 51.64988948991666],
            [-2.444804064464102, 51.651109617397324],
            [-2.442984742446165, 51.652489468006841],
            [-2.441935559879224, 51.652214693016411],
            [-2.439557049898301, 51.649896678131391],
            [-2.439076200442759, 51.650542254467162],
            [-2.43867257623459, 51.650205691081609],
            [-2.437707510496759, 51.650102297599688],
            [-2.437064653763026, 51.649536443489836],
            [-2.435507022402144, 51.649886599900555],
            [-2.434305080184872, 51.649194228374661],
            [-2.432177917325935, 51.649083395124244],
            [-2.431703231663715, 51.648562747922398],
            [-2.432012026419674, 51.646989933454172],
            [-2.430835827392041, 51.64655997734878],
            [-2.427230517287452, 51.647241209592316],
            [-2.424117235899184, 51.645833674710779],
            [-2.423482168859838, 51.646078732848743],
            [-2.422765065857475, 51.64558499863135],
            [-2.42128559881417, 51.645477927331861],
            [-2.420216817381592, 51.64461499799264],
            [-2.416737789782168, 51.643308379759887],
            [-2.415768404146931, 51.643658881961969],
            [-2.415322001256734, 51.644624329425589],
            [-2.413919341220958, 51.644059236629367],
            [-2.412382436714933, 51.642711456931288],
            [-2.411536286484186, 51.642789054836911],
            [-2.410185493408664, 51.641874873171474],
            [-2.4072183717126, 51.641196472691888],
            [-2.407017986761955, 51.641573004071319],
            [-2.406423450196345, 51.641667674862163],
            [-2.404784850049083, 51.641360440888128],
            [-2.404133417498237, 51.642857039325321],
            [-2.402739985186169, 51.64249588370329],
            [-2.401101541514589, 51.644662102556644],
            [-2.398838298413389, 51.645808113038655],
            [-2.395836169229922, 51.643610016222055],
            [-2.396567620322366, 51.642804624461732],
            [-2.394785573902634, 51.642118303117449],
            [-2.397144704693817, 51.639552319679439],
            [-2.395785082514528, 51.638741402175555],
            [-2.394817301958292, 51.638618783905876],
            [-2.392571361350143, 51.639074984270799],
            [-2.390166463891418, 51.640714025527139],
            [-2.389992173759503, 51.64044306775429],
            [-2.388443395620466, 51.640144298655215],
            [-2.385990848656005, 51.63994109556424],
            [-2.38536709832814, 51.639320048756566],
            [-2.385352907310758, 51.63866912552772],
            [-2.386118434464651, 51.637610131796315],
            [-2.388183906389999, 51.637172634431096],
            [-2.389993948042855, 51.636441035138311],
            [-2.39135271646522, 51.636334010830254],
            [-2.391550233443347, 51.635947625117566],
            [-2.392637089642459, 51.63614629800847],
            [-2.396317579790936, 51.636008946927802],
            [-2.397000766219636, 51.635647884682925],
            [-2.39744442535364, 51.635986254229032],
            [-2.3983512873128, 51.635758396793669],
            [-2.39874392638027, 51.635221181566493],
            [-2.399361593958873, 51.635464543821328],
            [-2.399875755161441, 51.635110333781469],
            [-2.400477108607765, 51.635141550959545],
            [-2.400459242789649, 51.634425903957215],
            [-2.399697087327195, 51.63434578231135],
            [-2.399253994832109, 51.633743074819527],
            [-2.398355531236215, 51.633934945624802],
            [-2.397502415841545, 51.633035111237696],
            [-2.396664553107954, 51.633382313464004],
            [-2.39657158706232, 51.632828763113189],
            [-2.395308428594547, 51.632619031940614],
            [-2.395011033858776, 51.631815311258499],
            [-2.393658059843445, 51.631740734279319],
            [-2.393077906758066, 51.630145822360063],
            [-2.38891207894799, 51.627538735375751],
            [-2.389548923896877, 51.6271724746735],
            [-2.38916705512505, 51.626609986291761],
            [-2.389581339603848, 51.626403609560576],
            [-2.388973182339298, 51.626069351878982],
            [-2.389435923245193, 51.625786388802112],
            [-2.389511171537387, 51.624789901003112],
            [-2.388936848402798, 51.624861938362052],
            [-2.388443609907349, 51.624449072435233],
            [-2.388618163268989, 51.623407299613667],
            [-2.389537022347953, 51.623254996869072],
            [-2.388887171783061, 51.62293885966335],
            [-2.389627144762681, 51.622477847446831],
            [-2.388852314548774, 51.622241248596815],
            [-2.389581922319735, 51.621246186311055],
            [-2.389242927801106, 51.621130424143878],
            [-2.389543654789275, 51.619645857429717],
            [-2.388933639578647, 51.619590336569644],
            [-2.38993075454079, 51.618466707715967],
            [-2.389303670938494, 51.618102842931307],
            [-2.389359812891996, 51.617574865605505],
            [-2.390020491002762, 51.617646400236112],
            [-2.389877885667749, 51.617017481055512],
            [-2.390731024587057, 51.616964291640038],
            [-2.390005407899239, 51.61639305885447],
            [-2.391596685729307, 51.615346562360131],
            [-2.391237924070085, 51.614943149165455],
            [-2.39169949657172, 51.614706935310181],
            [-2.391251570149702, 51.614346978165948],
            [-2.391842747050382, 51.613397318492247],
            [-2.390773736701008, 51.613026844182393],
            [-2.39101009487008, 51.612465896394944],
            [-2.390261832906679, 51.612108734164842],
            [-2.390572104817436, 51.61157811184566],
            [-2.391809285108264, 51.611357295558307],
            [-2.391671379064666, 51.611106897620857],
            [-2.39318928952291, 51.610619884685711],
            [-2.394166837860039, 51.60960597772192],
            [-2.393853934404783, 51.609159259628967],
            [-2.394453069492222, 51.608974722550563],
            [-2.394550772513568, 51.607752470210578],
            [-2.395188389500091, 51.60751205354142],
            [-2.394449630991855, 51.606913018508216],
            [-2.395436245894842, 51.606788314086991],
            [-2.395066833341162, 51.60648475190969],
            [-2.395430442420552, 51.605124034921772],
            [-2.395034280907444, 51.60489788834316],
            [-2.394910862393008, 51.603821139938042],
            [-2.393889823838904, 51.603131339130584],
            [-2.394492015231152, 51.603141903671542],
            [-2.394908840818712, 51.601758530165156],
            [-2.39636116069001, 51.600743003991184],
            [-2.396372854051012, 51.600096485853435],
            [-2.394491662483413, 51.599104785867979],
            [-2.394709258075177, 51.597392998008303],
            [-2.393102554990633, 51.597074703831211],
            [-2.39187227899556, 51.597380929645254],
            [-2.390676210259628, 51.597133160774248],
            [-2.388451679059839, 51.597156732303816],
            [-2.388091720456115, 51.596931341370613],
            [-2.386829569824632, 51.597227729253227],
            [-2.385479991111263, 51.597090108158412],
            [-2.385159815266778, 51.59678725181837],
            [-2.380002817103129, 51.596421937350492],
            [-2.375761031761662, 51.597961434582452],
            [-2.375379938837328, 51.597617386551079],
            [-2.373575620139648, 51.597480183739208],
            [-2.370579570605317, 51.59761286808488],
            [-2.370085046468716, 51.597872481154106],
            [-2.370081964207425, 51.598558531262356],
            [-2.367784631468266, 51.599766101887951],
            [-2.36761686032805, 51.600444575656816],
            [-2.366764964305755, 51.600975034410425],
            [-2.366583923859904, 51.603055299148807],
            [-2.365584434331172, 51.603733668204924],
            [-2.362235093480004, 51.602710053215702],
            [-2.36294670465565, 51.601235071022408],
            [-2.35986086077019, 51.601002710449208],
            [-2.357542184305197, 51.601565465606775],
            [-2.35683222582281, 51.600512942222856],
            [-2.355099317218046, 51.60009830465868],
            [-2.35481854390992, 51.598715385524834],
            [-2.354055710353582, 51.59938844511283],
            [-2.352788855778063, 51.596694856490018],
            [-2.349868316562792, 51.597261067443505],
            [-2.34719574210813, 51.597411970668219],
            [-2.344022794100399, 51.596497907346453],
            [-2.34411534809654, 51.594228218224458],
            [-2.343369092167317, 51.595196076566772],
            [-2.339482124309685, 51.594132923634412],
            [-2.338259565006591, 51.595320616802837],
            [-2.335256156890259, 51.594252974509217],
            [-2.335051749193063, 51.596488808583885],
            [-2.334179382980398, 51.596632461341535],
            [-2.333296043839739, 51.59547598955853],
            [-2.331273572975676, 51.595485315422323],
            [-2.331147969838718, 51.595286062097806],
            [-2.330379276099003, 51.593204034233224],
            [-2.331182572718386, 51.592503140614461],
            [-2.331066304660133, 51.591601635842395],
            [-2.329198584723795, 51.592825221347219],
            [-2.328590589898218, 51.591989831035811],
            [-2.328405764986834, 51.593182602281018],
            [-2.326753089906521, 51.592809581026437],
            [-2.324993485715326, 51.593226273671],
            [-2.323828248699618, 51.593187237137037],
            [-2.322653757774026, 51.593675107728934],
            [-2.319850562697129, 51.593914762228685],
            [-2.319387316201375, 51.592907194927839],
            [-2.319671391789435, 51.592658259711342],
            [-2.317740319517162, 51.591061247665877],
            [-2.318736260876161, 51.590638649111582],
            [-2.317593757280268, 51.590744249785864],
            [-2.316574092300699, 51.589833484657078],
            [-2.308257197424602, 51.592864144888281],
            [-2.307878466699148, 51.592360724899855],
            [-2.308613734690196, 51.591796833061714],
            [-2.308152102512576, 51.591627211145891],
            [-2.306909359809294, 51.591645754971168],
            [-2.306106099058797, 51.591976038480276],
            [-2.305601898599503, 51.592989779344634],
            [-2.304005249007027, 51.59276285101361],
            [-2.301791278621077, 51.591958448112379],
            [-2.301340724716687, 51.592150223209138],
            [-2.301016625694924, 51.591600784832707],
            [-2.300102089355279, 51.591480846107061],
            [-2.29962529182608, 51.59207189772237],
            [-2.297889340949548, 51.592386521247782],
            [-2.296874389252258, 51.593028379589413],
            [-2.296442023640527, 51.59380093079001],
            [-2.296553736629821, 51.594993799935772],
            [-2.294394234883243, 51.594795136772746],
            [-2.29158801073204, 51.593920096133125],
            [-2.291641425419677, 51.593695179682804],
            [-2.289858355537045, 51.593203277709591],
            [-2.290233843586859, 51.592549577329642],
            [-2.289304440866319, 51.592360357924044],
            [-2.290451824491562, 51.590967459491388],
            [-2.292704438549443, 51.58996381918157],
            [-2.294089835747182, 51.588572987596585],
            [-2.293032947368798, 51.588301396798869],
            [-2.293428904660147, 51.587701582556804],
            [-2.291877266092507, 51.587702757085189],
            [-2.292110474860854, 51.587381185065027],
            [-2.291009175520906, 51.58692985951793],
            [-2.29053161844779, 51.587412980213827],
            [-2.28767568536724, 51.586582026206081],
            [-2.286012121219706, 51.585533207302596],
            [-2.283507290451501, 51.584962943487888],
            [-2.284597676383864, 51.584150180983222],
            [-2.286555338669529, 51.584470900949491],
            [-2.287281675718064, 51.583156385311568],
            [-2.285759912140728, 51.583063895774167],
            [-2.285288869797814, 51.582746748456529],
            [-2.284936929641759, 51.583241229829767],
            [-2.284415160127032, 51.58312021392922],
            [-2.282076144332763, 51.582267188457955],
            [-2.28089258554096, 51.581312449337474],
            [-2.280720628219689, 51.581043120212676],
            [-2.282053449873537, 51.58070094764058],
            [-2.28128189739864, 51.579621139684704],
            [-2.282749918379788, 51.578516166787139],
            [-2.27867312342272, 51.577803020023588],
            [-2.276565956774164, 51.579895803918298],
            [-2.274333200622781, 51.579142177445476],
            [-2.274477560362916, 51.578910761541863],
            [-2.272563299632423, 51.57758989929718],
            [-2.273955319073891, 51.574382131161848],
            [-2.273996533378365, 51.57235897573208],
            [-2.268806930959197, 51.572448313584481],
            [-2.266433582838755, 51.571748806777563],
            [-2.263426927914126, 51.571305132449034],
            [-2.260303737706694, 51.569900461769855],
            [-2.264139139694231, 51.567974022878715],
            [-2.264441235622569, 51.566827839912904],
            [-2.266503162385498, 51.564654449345056],
            [-2.2660655199568, 51.564319165462628],
            [-2.267410303606552, 51.562884677069228],
            [-2.267645171980774, 51.560628201212566],
            [-2.267956972839197, 51.559928857764127],
            [-2.268377453243148, 51.560049279808112],
            [-2.268627885720177, 51.559708831591266],
            [-2.267902425499826, 51.559483908421299],
            [-2.268106046321936, 51.558787508998201],
            [-2.267689826395128, 51.558162658929895],
            [-2.268408343893111, 51.555744128308646],
            [-2.267804535175019, 51.553874398501449],
            [-2.267386679527006, 51.553945485220851],
            [-2.267033984892408, 51.55332228573004],
            [-2.267397888625733, 51.552660588067056],
            [-2.267125652092859, 51.552475086614322],
            [-2.267558234863341, 51.552212450253393],
            [-2.266813327966765, 51.551354569557688],
            [-2.268372693891528, 51.549692095380649],
            [-2.264249129017851, 51.54565263006797],
            [-2.261726057641131, 51.544741166068036],
            [-2.262217375411872, 51.541661406413354],
            [-2.264819459995778, 51.537586038181551],
            [-2.266505842355194, 51.536262274446145],
            [-2.265663305684424, 51.53542618570745],
            [-2.264072908369088, 51.535398309448034],
            [-2.263911784709014, 51.534960789638554],
            [-2.263247188966919, 51.534717717166764],
            [-2.263366416386236, 51.534392858647642],
            [-2.261866246477198, 51.534505016075826],
            [-2.260261357492444, 51.533952920070142],
            [-2.260543334647032, 51.532082974536138],
            [-2.260106141227538, 51.532016509775438],
            [-2.260010251322318, 51.530369491356701],
            [-2.257668179087216, 51.528415428960649],
            [-2.2535371846675, 51.528744533130357],
            [-2.252112491893488, 51.528138885922715],
            [-2.252392351193344, 51.526874086987576],
            [-2.253357282959384, 51.526720948831581],
            [-2.25384771938804, 51.526005067910447],
            [-2.254929783627259, 51.525665540131847],
            [-2.254753499055133, 51.524040270102262],
            [-2.255788104296772, 51.522958143798775],
            [-2.255813440036151, 51.522078724767589],
            [-2.262694591935327, 51.519630416170976],
            [-2.263605966097319, 51.519220157790905],
            [-2.263723212016141, 51.518559921147634],
            [-2.26752231335449, 51.518574688653317],
            [-2.271558426938161, 51.518214739558729],
            [-2.279858685928489, 51.516169437956769],
            [-2.286935919977581, 51.512936977011421],
            [-2.296388514509565, 51.509746657547396],
            [-2.296640641318636, 51.509076154703152],
            [-2.296272946983211, 51.508813634483289],
            [-2.29712071715263, 51.508008549388904],
            [-2.296288473344382, 51.506766235517915],
            [-2.297456282772084, 51.506215696244354],
            [-2.306047520289466, 51.503800043741897],
            [-2.311891975390477, 51.502864807249615],
            [-2.321643373762269, 51.498256386341048],
            [-2.324327613647206, 51.497510786958507],
            [-2.319216186791275, 51.494571112200141],
            [-2.317033501140374, 51.492437958062439],
            [-2.313648105591096, 51.490477927331199],
            [-2.311426135455219, 51.487374588759309],
            [-2.310427628952888, 51.488158600459194],
            [-2.30971896089941, 51.488148786598813],
            [-2.30843138274917, 51.487509289427329],
            [-2.304405484999655, 51.486359012130869],
            [-2.304227781544067, 51.485842461578301],
            [-2.301784324482047, 51.48635680023645],
            [-2.300514377674181, 51.487278097238033],
            [-2.29746758758501, 51.487829857438143],
            [-2.295583533449036, 51.487795062305977],
            [-2.293695423803073, 51.486905154494949],
            [-2.293024248140473, 51.487123530164304],
            [-2.292322882491074, 51.486901393312053],
            [-2.291323485198567, 51.487369642389531],
            [-2.290561239999229, 51.486859018891259],
            [-2.290594036715441, 51.486581099898878],
            [-2.291340367963949, 51.486629599152913],
            [-2.290589108689356, 51.485353770822741],
            [-2.289636152041779, 51.478834582804822],
            [-2.288854257961864, 51.47815135894006],
            [-2.290343517715527, 51.477076787574973],
            [-2.291248278056227, 51.47557475553841],
            [-2.291618967548494, 51.472968987424501],
            [-2.293403919942525, 51.468652190968001],
            [-2.292823305330111, 51.467459567101834],
            [-2.294008048973945, 51.467229114108498],
            [-2.294583914219255, 51.466787082092559],
            [-2.294596651842311, 51.46384951269205],
            [-2.295745891831637, 51.458591955269135],
            [-2.293338503405247, 51.457786070401518],
            [-2.289247200223963, 51.457653277484717],
            [-2.287756939821552, 51.456871984716607],
            [-2.286751977305216, 51.457044389303327],
            [-2.28526642286627, 51.457930087606776],
            [-2.288043211919869, 51.454564946706327],
            [-2.288947385240998, 51.446422655645826],
            [-2.294618427530402, 51.428803902661436],
            [-2.29882135052843, 51.4288004485785],
            [-2.301655892873838, 51.42632318926718],
            [-2.301469016082609, 51.425901964119753],
            [-2.30363263428569, 51.42509971886377],
            [-2.307652722423204, 51.424692698446812],
            [-2.309868255920082, 51.42308545221222],
            [-2.312618062330571, 51.422448732169343],
            [-2.313786557872988, 51.423179323149071],
            [-2.313162590535483, 51.426574426556087],
            [-2.314235697721945, 51.42764065454076],
            [-2.313851754472587, 51.428913096808692],
            [-2.317685843375942, 51.430271282818445],
            [-2.31815275706378, 51.431016318489313],
            [-2.321105508848238, 51.43260157291499],
            [-2.322151193871338, 51.432791121984721],
            [-2.323489373931118, 51.434471560440137],
            [-2.324158616184226, 51.434718777613725],
            [-2.325030715906421, 51.436993032890364],
            [-2.328617600756954, 51.437043269384766],
            [-2.331855680562688, 51.437795729099037],
            [-2.332529923897901, 51.43753036344458],
            [-2.336073691660449, 51.437383578327406],
            [-2.339399854378115, 51.438159854359697],
            [-2.342039063742268, 51.439405606582575],
            [-2.34329172266701, 51.439535917288993],
            [-2.343536577841395, 51.43918902318152],
            [-2.346951584495965, 51.43855584162732],
            [-2.350634711807141, 51.438379430516491],
            [-2.351795637596341, 51.438741908083315],
            [-2.353839330478486, 51.437350148093714],
            [-2.354994875866931, 51.437204584969336],
            [-2.357671604457815, 51.43584050994258],
            [-2.358836463981666, 51.435788393085346],
            [-2.360357337341737, 51.436188346840446],
            [-2.361044994923372, 51.436553086944521],
            [-2.361476125881382, 51.437405959550908],
            [-2.367220735114497, 51.437056303701482],
            [-2.373232932203101, 51.434424343721531],
            [-2.375900313509091, 51.433759428047246],
            [-2.377254976668702, 51.432833433576775],
            [-2.380341072878071, 51.434104759566928],
            [-2.38604289388961, 51.431988366739617],
            [-2.396699513348199, 51.432027397137674],
            [-2.3971890663416, 51.431252459355946],
            [-2.399070418127581, 51.432059807321671],
            [-2.403099858894104, 51.431597330737745],
            [-2.403052671620773, 51.430812524715975],
            [-2.404167404914523, 51.429664950163129],
            [-2.411197783322275, 51.426078003048595],
            [-2.413455402286715, 51.425862350545302],
            [-2.414764183256133, 51.426331583187576],
            [-2.416364115270663, 51.426074142286751],
            [-2.420874455735704, 51.424335228810136],
            [-2.424037197350711, 51.42371868678439],
            [-2.427634513135055, 51.42421453759497],
            [-2.430146720012711, 51.424172071526733],
            [-2.431383709777955, 51.4237251346375],
            [-2.438444414092016, 51.423172012854934],
            [-2.441298468398628, 51.422493217915303],
            [-2.44279670157334, 51.422753717374277],
            [-2.444007338092044, 51.422572000027849],
            [-2.444456792364448, 51.422351798826803],
            [-2.444106162276737, 51.42104664256815],
            [-2.441525050462069, 51.419293136888932],
            [-2.443428459938953, 51.418338227968526],
            [-2.445874773910152, 51.418947568325095],
            [-2.445910503950932, 51.418481664883558],
            [-2.446654944664708, 51.417990582332003],
            [-2.45063905500132, 51.417333320552352],
            [-2.454696669533716, 51.418442497814411],
            [-2.455837597833912, 51.418497408931856],
            [-2.457513706003552, 51.417854266153469],
            [-2.458456704734365, 51.41681294265463],
            [-2.460934341519065, 51.416664749000894],
            [-2.464308804154933, 51.417321301970283],
            [-2.469645484155222, 51.415935095730838],
            [-2.47218145040666, 51.416989514516892],
            [-2.473501255182609, 51.418073976728934],
            [-2.475324961319396, 51.418098961642578],
            [-2.476360207961705, 51.418499381452698],
            [-2.481040445831034, 51.421585976288924],
            [-2.483059427279763, 51.421431105172182],
            [-2.484863951295894, 51.420717805921242],
            [-2.486422929592592, 51.420586359597358],
            [-2.490008639644548, 51.41767160658344],
            [-2.492314352024225, 51.417989236738116],
            [-2.492896300983348, 51.418347355543439],
            [-2.492564506635492, 51.421459865700079],
            [-2.490318453032872, 51.424044497358416],
            [-2.488642692143921, 51.425237497367959],
            [-2.488434471442417, 51.42648191088302],
            [-2.491510111883251, 51.428942651324107],
            [-2.494251040007272, 51.429415770955778],
            [-2.496138803173888, 51.428672275719883],
            [-2.497675442031572, 51.428845591782562],
            [-2.498605232604798, 51.428635729976421],
            [-2.502492458939839, 51.427128307530722],
            [-2.503974794764895, 51.426029454841604],
            [-2.505721171295775, 51.42566406205485],
            [-2.507985224767164, 51.424643605498225],
            [-2.508468244616719, 51.425011067446945],
            [-2.508055789943865, 51.426596287423806],
            [-2.50828746755727, 51.42790177],
            [-2.508997838016655, 51.428532597724455],
            [-2.510462037646163, 51.428784288293407],
            [-2.51070049072095, 51.429271495928688],
            [-2.513436078276099, 51.429633593248845],
            [-2.516990832397017, 51.430818348086568],
            [-2.521663130339595, 51.431720183073054],
            [-2.523541872009158, 51.432608271360188],
            [-2.525354547560835, 51.434126041238869],
            [-2.527043435260266, 51.43625217508206],
            [-2.526448449763359, 51.442558889664745],
            [-2.526751267154528, 51.443866711046397],
            [-2.528322137798464, 51.444956618781269],
            [-2.53077997977546, 51.445594708010937],
            [-2.536631791192453, 51.444590666136733],
            [-2.538045745960432, 51.444795486145836],
            [-2.539253474685105, 51.445570408887932],
            [-2.539118584229135, 51.44633441940006],
            [-2.537680440469456, 51.447014500097772],
            [-2.535606417016154, 51.447342301515363],
            [-2.534768093246206, 51.446531488116371],
            [-2.531666147647394, 51.446443996877157],
            [-2.529931367216304, 51.448233995733581],
            [-2.527229588195382, 51.448666091146805],
            [-2.526124196850777, 51.449146716120914],
            [-2.525129285712065, 51.448974043532274],
            [-2.522277994675833, 51.449805023486],
            [-2.518936710846181, 51.452612664474124],
            [-2.519176827643909, 51.453734654087384],
            [-2.518594300589875, 51.454272234491],
            [-2.518463224411777, 51.455659321518759],
            [-2.519214657732925, 51.457574805022659],
            [-2.518602500838613, 51.458169163447565],
            [-2.518463134130048, 51.459079730966316],
            [-2.516228166054381, 51.460001345766088],
            [-2.515362337751554, 51.459933223451912],
            [-2.514257999966547, 51.461438773308352],
            [-2.513033626223875, 51.461617677257692],
            [-2.513289976413725, 51.462142563602946],
            [-2.512245662539098, 51.462335057104745],
            [-2.512148694186827, 51.463059304798264],
            [-2.5113464903813, 51.465210001382609],
            [-2.513251757304929, 51.465662942358563],
            [-2.512864277233442, 51.467181520786319],
            [-2.512284261012858, 51.468607426630591],
            [-2.512922694500101, 51.469052415734431],
            [-2.51275722205291, 51.470087172737813],
            [-2.512608141113221, 51.470401631329011],
            [-2.511502077264017, 51.470239219896619],
            [-2.510834826905049, 51.471596263134899],
            [-2.511071976450611, 51.472847757324779],
            [-2.511477179921656, 51.474834029078139],
            [-2.515230511997701, 51.481447980861198],
            [-2.515127460642002, 51.481759542213545],
            [-2.516711050581713, 51.485156781157244],
            [-2.511392722960279, 51.489291046332141],
            [-2.510998675019111, 51.489987812257233],
            [-2.515965281202772, 51.492574508590138],
            [-2.515318443991931, 51.493076385116005],
            [-2.515864666002297, 51.493868836276363],
            [-2.518036915689291, 51.492986177255681],
            [-2.520685554572422, 51.49258331033095],
            [-2.523340014014996, 51.49244111273493],
            [-2.525329589398738, 51.49322706785626],
            [-2.527472089395293, 51.49265547495348],
            [-2.527937889985238, 51.493824978424541],
            [-2.528554541674916, 51.494086551632833],
            [-2.527753244032046, 51.494923679026144],
            [-2.529228491546064, 51.496184838050219],
            [-2.53027690791116, 51.496409385203229],
            [-2.531740842152569, 51.495698715514457],
            [-2.534328697952659, 51.496746154648505],
            [-2.537703392936589, 51.497074202626287],
            [-2.538950960505886, 51.495004012075583],
            [-2.541742704700421, 51.495741939960382],
            [-2.542536682067375, 51.494551382186785],
            [-2.542170545065086, 51.492951678547307],
            [-2.551600875441739, 51.49223421812426],
            [-2.555347069670226, 51.492687690075861],
            [-2.556154583818042, 51.494062269533984],
            [-2.554661248976269, 51.496349605327062],
            [-2.560075857427611, 51.49644345107226],
            [-2.560511572327956, 51.495911765492956],
            [-2.561223770125507, 51.495608039972645],
            [-2.561589825850391, 51.495856252626638],
            [-2.562239771738763, 51.495293861949619],
            [-2.564787865976528, 51.495517193312459],
            [-2.564719172011589, 51.496136144107886],
            [-2.565337770196885, 51.496186213018902],
            [-2.565064055478726, 51.497582124567458],
            [-2.56597173166738, 51.498289878249871],
            [-2.567345983111403, 51.498748999108003],
            [-2.569086123113532, 51.499550705339132],
            [-2.569926537555808, 51.499247203886839],
            [-2.571313158482609, 51.499653167948992],
            [-2.573094535266371, 51.500403361907296],
            [-2.575476006068439, 51.500753157733236],
            [-2.578116447737673, 51.500958662696917],
            [-2.579832036391336, 51.500712813409656],
            [-2.58171386861684, 51.501191733679121],
            [-2.584517251477953, 51.502642517067322],
            [-2.585626469806581, 51.501845724137283],
            [-2.585243065539017, 51.501157086257585],
            [-2.588148470976902, 51.501236059790216],
            [-2.587727098143103, 51.50353282051136],
            [-2.587861939133627, 51.504942021654443],
            [-2.588543411657021, 51.50561117172979],
            [-2.588018883868519, 51.507731315805295],
            [-2.590501603014757, 51.508406692784483],
            [-2.591221293837858, 51.509683463832815],
            [-2.589226458233337, 51.511536773986414],
            [-2.585850365047131, 51.512672239823367],
            [-2.585104281895561, 51.512476352751307],
            [-2.582974093365485, 51.513012968672335],
            [-2.581400033396199, 51.515580677137592],
            [-2.580986654348829, 51.517409807448963],
            [-2.58798778011282, 51.517063802569233],
            [-2.59576073316245, 51.515352140351965],
            [-2.602441233966875, 51.513784049280602],
            [-2.607585712659299, 51.513449094996353],
            [-2.61244630967352, 51.513549690292677],
            [-2.621091872163654, 51.514867335943855],
            [-2.622724247845662, 51.514521494847997],
            [-2.627509363255153, 51.514571514336488],
            [-2.63087672501958, 51.513702843794889],
            [-2.638511631797214, 51.512063716278746],
            [-2.647202489652891, 51.512171623850428],
            [-2.651970669019204, 51.512967938433007],
            [-2.654483183345425, 51.513725418967532],
            [-2.653955098046996, 51.514484556587604],
            [-2.654490846220062, 51.514963515860018],
            [-2.653201323265256, 51.516090156766637],
            [-2.653685664119093, 51.518714794101896],
            [-2.652756360424915, 51.5192387840724],
            [-2.652714666206204, 51.519649031516032],
            [-2.651662263037079, 51.51993182472772],
            [-2.651399467032871, 51.52070566008608],
            [-2.653650722576825, 51.523118157320503],
            [-2.658090967503807, 51.526774467950773],
            [-2.660126013760481, 51.527648698742013],
            [-2.665420063302284, 51.530967234773129],
            [-2.664609479671686, 51.531533803103464],
            [-2.665723477398114, 51.533680961140362],
            [-2.663733932716403, 51.534870130209363],
            [-2.664472907869071, 51.536014166135175],
            [-2.663684151620759, 51.53699781130652],
            [-2.673808765808285, 51.544432732687589],
            [-2.672655443666911, 51.545529126499055],
            [-2.671207719024905, 51.54494937378432],
            [-2.672519363161747, 51.545689056960043],
            [-2.672321557210596, 51.546357362662761],
            [-2.67107397104744, 51.547717732487371],
            [-2.670751019263524, 51.548998175062536],
            [-2.668588390635956, 51.552582854400804],
            [-2.667278317445629, 51.556886461217601],
            [-2.667194241119767, 51.562868990592484],
            [-2.666096649936264, 51.566537466564775],
            [-2.666711614422115, 51.567449307727955],
            [-2.666680284486604, 51.568660640375107],
            [-2.666384545129366, 51.568959039990318],
            [-2.665453822158645, 51.56895173500142],
            [-2.663890436718342, 51.573153324920334],
            [-2.663287460924541, 51.573760064306143],
            [-2.661651903582507, 51.574110976302357],
            [-2.660807955307322, 51.574820665299264],
            [-2.658850199030848, 51.575346888022878],
            [-2.657211722178194, 51.575501739959833],
            [-2.657005445410897, 51.575306881011855],
            [-2.657674260846368, 51.57535528427259],
            [-2.658033681565832, 51.574663622176956],
            [-2.656544954402225, 51.574394126018724],
            [-2.657769410378742, 51.574849429542859],
            [-2.657542675876693, 51.575136629676948],
            [-2.656758808277826, 51.575114945087307],
            [-2.656735305630622, 51.57608705575722],
            [-2.654707175160838, 51.577346408430529],
            [-2.652825439248318, 51.579463596853209],
            [-2.642637839661754, 51.587001638330349],
            [-2.63749836063803, 51.592288775666496],
            [-2.635798659913272, 51.594000078737515],
            [-2.63463933086499, 51.595959296249681],
            [-2.633782362310353, 51.596518699985275],
            [-2.633213165052941, 51.598047620122678],
            [-2.62994899630518, 51.601393818035511],
            [-2.628475949977549, 51.604924558088506],
            [-2.627799860732713, 51.605634003867785],
            [-2.624870499764427, 51.606193620114787],
            [-2.613622645162953, 51.610325186553091],
            [-2.610361355845267, 51.611797903328309],
            [-2.608134529578342, 51.61320852681407],
            [-2.605131219529931, 51.61434348129599],
            [-2.599019636539774, 51.617779878011014],
            [-2.598128220851899, 51.617651351962472],
            [-2.597478070841405, 51.616757322551692],
            [-2.59621125764099, 51.616526392383683],
            [-2.597207826626885, 51.616965500169542],
            [-2.597781020642782, 51.618598116426234],
            [-2.591391123642008, 51.62293466710117],
            [-2.585317577623836, 51.62814864367612],
            [-2.584636861096719, 51.62812775977072],
            [-2.58568456506699, 51.627030084219918],
            [-2.585204814164603, 51.62601015788082],
            [-2.585416499590808, 51.626967582859777],
            [-2.584115350224939, 51.628241849419773],
            [-2.584225738408215, 51.628962408041552],
            [-2.580749109172127, 51.633095910226871],
            [-2.579940011522172, 51.632870631029782],
            [-2.579461805694863, 51.63185337213546],
            [-2.578537434740494, 51.631196167631259],
            [-2.576514060393231, 51.631370665779642],
            [-2.575617134885774, 51.630822998176072],
            [-2.574199314756337, 51.63112665812389],
            [-2.572986275404497, 51.630370118600041],
            [-2.571893924651964, 51.630361060131634],
            [-2.571113426018696, 51.629874831224676],
            [-2.566705485523043, 51.629060899814426],
            [-2.570912947276231, 51.630016971022918],
            [-2.57179604355179, 51.630619589202688],
            [-2.572937043587004, 51.630590647386867],
            [-2.574123002119836, 51.631376092237574],
            [-2.575584194011247, 51.63107401882327],
            [-2.576399055718777, 51.63187204964072],
            [-2.577606167984604, 51.631472293572557],
            [-2.57863363300449, 51.632170357435584],
            [-2.578975409621358, 51.633140638456666],
            [-2.580186825595771, 51.634209124371303],
            [-2.579433881105236, 51.634763115173129],
            [-2.578736835597506, 51.638460204077134],
            [-2.575824379484181, 51.641936198412651],
            [-2.573788314938108, 51.646408573348452],
            [-2.573620375524249, 51.647988273447893],
            [-2.574269643069202, 51.648242251216516],
            [-2.574025501879256, 51.648709196657684],
            [-2.573584492290989, 51.648809357967785],
            [-2.569453788924768, 51.655042479063972],
            [-2.565956905187424, 51.657817028719577],
            [-2.558269244852834, 51.666417164439139],
            [-2.553475414964629, 51.669327868069892],
            [-2.545811773782096, 51.671984708608186],
            [-2.542687106344268, 51.673703941861547],
            [-2.542063305719345, 51.672813089402638],
            [-2.540773514263281, 51.672703049578729],
            [-2.541861487610077, 51.672987552902335],
            [-2.542149306729412, 51.673838598397545],
            [-2.539147003781667, 51.674924184911447],
            [-2.534741799979227, 51.677242502916172],
            [-2.532088734815307, 51.675830337300816],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000026",
        LAD13CDO: "00HG",
        LAD13NM: "Plymouth",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-4.154462521146446, 50.333761971476505],
              [-4.149107098865243, 50.333511242681105],
              [-4.149440953397597, 50.333684957399029],
              [-4.148794062396911, 50.333736495170356],
              [-4.148937841682237, 50.333497282999893],
              [-4.147735642577429, 50.333418768191422],
              [-4.141702289893124, 50.33318382238587],
              [-4.137216622464444, 50.333617180697566],
              [-4.141588246692002, 50.333085186301929],
              [-4.154564105437489, 50.333646758270781],
              [-4.158696860673631, 50.334420943185485],
              [-4.158683503805101, 50.334653248032915],
              [-4.158197950404498, 50.334512957939175],
              [-4.154462521146446, 50.333761971476505],
            ],
          ],
          [
            [
              [-4.154705710208246, 50.356117614880262],
              [-4.153443726376226, 50.355631917908234],
              [-4.151939835077062, 50.355702943084111],
              [-4.151397899618416, 50.355283941483421],
              [-4.151709456236961, 50.354774487392568],
              [-4.15230541281181, 50.354367699532858],
              [-4.152924665438308, 50.354755583327361],
              [-4.153229145131398, 50.354525082959981],
              [-4.153844323974917, 50.354667489822752],
              [-4.153929711847124, 50.355031980262716],
              [-4.154820437964867, 50.355453499131805],
              [-4.155269554622032, 50.355281474025865],
              [-4.15548508287397, 50.356035706733486],
              [-4.154705710208246, 50.356117614880262],
            ],
          ],
          [
            [
              [-4.112714678615647, 50.44341278035585],
              [-4.110189340850417, 50.442211123132402],
              [-4.109084110260604, 50.43848236637151],
              [-4.108719851505508, 50.438335169643672],
              [-4.106487015353625, 50.438574405155187],
              [-4.106671275247855, 50.436983582124356],
              [-4.107684409999761, 50.435570216984992],
              [-4.106680730287146, 50.434881453451901],
              [-4.108737062301719, 50.433811645695108],
              [-4.108235842082531, 50.433370120274681],
              [-4.108793883775963, 50.433189111989968],
              [-4.108265694205115, 50.432615860072367],
              [-4.109033758246192, 50.432344698164407],
              [-4.109939983827163, 50.432520737783889],
              [-4.109513249312015, 50.43210754749019],
              [-4.107273576641655, 50.431266713266361],
              [-4.106175166498883, 50.430344911694704],
              [-4.104122792909331, 50.430136519361298],
              [-4.101216088758246, 50.430413029998846],
              [-4.098853624779749, 50.429751439939658],
              [-4.097904149795987, 50.428658680562513],
              [-4.094305984804882, 50.427910452944616],
              [-4.093152381620772, 50.427070471933341],
              [-4.090531041575519, 50.427141907367016],
              [-4.08855908106378, 50.426676359129573],
              [-4.087205916502302, 50.425841702345089],
              [-4.085569638882567, 50.426028457394793],
              [-4.084703907904763, 50.425839810404142],
              [-4.082557169063889, 50.424565113633939],
              [-4.082368266991491, 50.422281259611708],
              [-4.081212381920384, 50.420771130775705],
              [-4.080539533111575, 50.419841472806688],
              [-4.079083634830726, 50.418903327773378],
              [-4.080993472567213, 50.416516270611289],
              [-4.080522416680526, 50.415371635993544],
              [-4.0772600237428, 50.413581640013966],
              [-4.077735443856672, 50.412994815806904],
              [-4.076942976108729, 50.411707503979066],
              [-4.077662759975844, 50.411301596660479],
              [-4.077576896143005, 50.410497245305933],
              [-4.077985630789352, 50.410027636980125],
              [-4.078934864328259, 50.409655396835355],
              [-4.077757074578355, 50.408599834928559],
              [-4.074577085881828, 50.408199704399273],
              [-4.072511662419571, 50.407150928652889],
              [-4.072156294913785, 50.403844676364578],
              [-4.071483088946413, 50.401864442444662],
              [-4.063516492791652, 50.401081486404827],
              [-4.059788333732803, 50.401168276023171],
              [-4.05672047319917, 50.40259510374532],
              [-4.05544053840918, 50.401992636190137],
              [-4.055312194034419, 50.401140451454353],
              [-4.05258167389954, 50.401486393844401],
              [-4.052484312774197, 50.402522450423774],
              [-4.048901551037233, 50.401643939882376],
              [-4.048671605554819, 50.402120189054294],
              [-4.046577759666171, 50.401992466813518],
              [-4.044226060467161, 50.401104730439293],
              [-4.03962317928701, 50.401434701366028],
              [-4.039450848962631, 50.400764056225213],
              [-4.041620127320789, 50.397639165968357],
              [-4.041113176505976, 50.397793772896122],
              [-4.037564075995763, 50.397358639607113],
              [-4.034667338383826, 50.397501106556533],
              [-4.030384976325927, 50.397092974189924],
              [-4.031523682173582, 50.394304660557559],
              [-4.027788445177496, 50.394016390145843],
              [-4.028016105503348, 50.393284786397025],
              [-4.028875522974331, 50.392224663513502],
              [-4.021142847154926, 50.391791803103878],
              [-4.021492431906903, 50.390683033666711],
              [-4.020904299106728, 50.388763096554328],
              [-4.020195631040863, 50.387514421275732],
              [-4.019607555969348, 50.387480566165159],
              [-4.025707743349212, 50.383328834909527],
              [-4.025290926562601, 50.381799880163115],
              [-4.024523665342316, 50.380992069055182],
              [-4.02703450930513, 50.379046935604848],
              [-4.030853582189712, 50.377835317572121],
              [-4.034972252336019, 50.377220044895957],
              [-4.040530413932155, 50.377146906927329],
              [-4.047526350423132, 50.377669693987926],
              [-4.050172498805116, 50.377485447737307],
              [-4.050165048814493, 50.376595145942197],
              [-4.05055836608881, 50.37469221567779],
              [-4.054292335702168, 50.369807156466493],
              [-4.055059870841067, 50.368270857375521],
              [-4.049733604178266, 50.363096037580569],
              [-4.050920112393603, 50.362275517444857],
              [-4.049675319568185, 50.361156096582604],
              [-4.051017007877372, 50.360903077117172],
              [-4.051564742982834, 50.360497662498823],
              [-4.050167745223351, 50.358064167497673],
              [-4.05204138522588, 50.357564302745111],
              [-4.052916450355573, 50.356535193664165],
              [-4.053800079449456, 50.357463986769979],
              [-4.057772155329536, 50.358349833949902],
              [-4.05755174573057, 50.357219553237684],
              [-4.058146200169351, 50.356141404838425],
              [-4.059659872343039, 50.356747793658741],
              [-4.060911885277488, 50.355889131495992],
              [-4.061896774785628, 50.356685651000227],
              [-4.064109456096898, 50.356065348246013],
              [-4.064279936427674, 50.356300670277413],
              [-4.065423760102989, 50.355898992731774],
              [-4.065335652971743, 50.355391479757643],
              [-4.071658502158625, 50.353502584323309],
              [-4.072327066745493, 50.353198351163947],
              [-4.072313423230344, 50.352723693760282],
              [-4.079389538303584, 50.35016885152104],
              [-4.081362459359718, 50.346617650303827],
              [-4.082886478103127, 50.344987556452985],
              [-4.085755207575053, 50.343383686816416],
              [-4.088615764310562, 50.342975242430931],
              [-4.089559111219762, 50.341348295834337],
              [-4.090559140081905, 50.340917471511617],
              [-4.090731319551056, 50.341733761133462],
              [-4.093248493126924, 50.34339558530484],
              [-4.094226587880842, 50.343264637038118],
              [-4.099930452981219, 50.344291417425978],
              [-4.101923659449371, 50.345122446488375],
              [-4.104633954235242, 50.345703007799671],
              [-4.107221257741022, 50.345599465092192],
              [-4.114646505462204, 50.344387982303857],
              [-4.11631956623115, 50.345599655959795],
              [-4.119320376518409, 50.345155521265845],
              [-4.122984846146886, 50.346703139532849],
              [-4.121377690177787, 50.348898332552679],
              [-4.121737803178646, 50.349030273093803],
              [-4.1213957822582, 50.349524911171542],
              [-4.121826742569355, 50.350079194020282],
              [-4.121644434954691, 50.350593403031752],
              [-4.122093465753003, 50.351174338186169],
              [-4.122689011606457, 50.351257904806253],
              [-4.122814137212396, 50.352298068984481],
              [-4.124409470276216, 50.353080215914197],
              [-4.124108669559789, 50.353364539127568],
              [-4.124442455036531, 50.353693030136519],
              [-4.125744309820413, 50.353946255978911],
              [-4.125582867268667, 50.354832456981647],
              [-4.12449309366141, 50.355456802709789],
              [-4.125049809672916, 50.356023166115072],
              [-4.125552625141289, 50.355949213042045],
              [-4.125252294047868, 50.356118402346659],
              [-4.125880089578641, 50.355946822238238],
              [-4.125297007669058, 50.356332550551222],
              [-4.125841544845213, 50.357003467710022],
              [-4.127209368396251, 50.358132423375707],
              [-4.128566974265491, 50.358685909754875],
              [-4.129561486455049, 50.358508493529811],
              [-4.130566032686009, 50.358712246756895],
              [-4.131661103172867, 50.359492670277575],
              [-4.135274739566961, 50.359435340114615],
              [-4.131694278896319, 50.359636871479957],
              [-4.130988711538257, 50.360426923238705],
              [-4.12821138710502, 50.360699966502402],
              [-4.126050930696834, 50.359549545025217],
              [-4.125786118041276, 50.358752089559694],
              [-4.124745665991014, 50.358058757783326],
              [-4.124030901057606, 50.358235518488783],
              [-4.124074373618135, 50.357918121983751],
              [-4.121336854518107, 50.358072447918978],
              [-4.120430815064742, 50.357869516575853],
              [-4.118175850104472, 50.358346856608165],
              [-4.117571299942083, 50.358884042111825],
              [-4.117975403846487, 50.359781513441149],
              [-4.117236295464372, 50.360265383522318],
              [-4.116118519197159, 50.360391870979882],
              [-4.115430165861524, 50.359172171767895],
              [-4.113846479871976, 50.359317908752608],
              [-4.113164799117004, 50.35853160346911],
              [-4.112642753187078, 50.356368953037141],
              [-4.113831824680171, 50.354937917602967],
              [-4.113500282785242, 50.354215402240222],
              [-4.112145746242546, 50.354262509384498],
              [-4.112020063809225, 50.354946566367239],
              [-4.105182605825287, 50.356580753960287],
              [-4.10335253936131, 50.356176757654445],
              [-4.102002960250477, 50.355446543920252],
              [-4.101490835922022, 50.354705674323085],
              [-4.101125823238311, 50.354844489967398],
              [-4.10102707516267, 50.355441701392998],
              [-4.102346108486318, 50.356658174154447],
              [-4.105273024198023, 50.357289670815788],
              [-4.106423255400652, 50.35856402055343],
              [-4.107648404096674, 50.358386210455208],
              [-4.107667308040591, 50.358051277468824],
              [-4.107999234271383, 50.358307893855411],
              [-4.109553665228944, 50.357851562432501],
              [-4.110527545913102, 50.357334696859439],
              [-4.111944726149049, 50.357208219967681],
              [-4.112300508651807, 50.35778009301788],
              [-4.112225003915959, 50.359499388800906],
              [-4.111282568754488, 50.360155109765209],
              [-4.111133726626163, 50.360888155218255],
              [-4.110082112247263, 50.361397441940767],
              [-4.110314704987015, 50.36334409585946],
              [-4.108996241759189, 50.364844896929135],
              [-4.108403327791187, 50.364728832051114],
              [-4.108285311564051, 50.364953133021359],
              [-4.108832370052322, 50.365113203286846],
              [-4.10881560273067, 50.36568644771058],
              [-4.108070435430956, 50.365974291072156],
              [-4.108838917444531, 50.365958553617446],
              [-4.108804830449718, 50.366585178831322],
              [-4.107107445220652, 50.366900179693047],
              [-4.106039646368162, 50.366762128734784],
              [-4.101614428985656, 50.365555125192067],
              [-4.101608796050099, 50.36600044706455],
              [-4.098998546031457, 50.366374102679252],
              [-4.098011845657531, 50.366862318638795],
              [-4.101563058954135, 50.366111004924051],
              [-4.107812734045889, 50.367520595573865],
              [-4.108125333004072, 50.367910678412656],
              [-4.107507734618943, 50.36869808890804],
              [-4.103992334656058, 50.370356464307008],
              [-4.100154064211292, 50.377955923318474],
              [-4.099263107677046, 50.378292212905812],
              [-4.099820301842579, 50.379061058742067],
              [-4.099281061497388, 50.379749868594516],
              [-4.092667388905356, 50.382275982845201],
              [-4.090983738387725, 50.382374632979953],
              [-4.089359770415036, 50.382839153891247],
              [-4.088664888307588, 50.383191624610483],
              [-4.086885446997861, 50.385626857105386],
              [-4.085928569095422, 50.386037079802144],
              [-4.084241431500364, 50.385995382872963],
              [-4.083152304704321, 50.386694871306595],
              [-4.083317717211077, 50.388221837050914],
              [-4.085049854854328, 50.389672146861706],
              [-4.085149629714189, 50.390406990173446],
              [-4.084021909552761, 50.391864497204395],
              [-4.083357831898126, 50.392730855899693],
              [-4.083769326073461, 50.393708356344121],
              [-4.084731386917115, 50.394342296809306],
              [-4.0870277516021, 50.394985565389035],
              [-4.086562195389686, 50.394572988392667],
              [-4.084370129762167, 50.393933237562464],
              [-4.083552640452925, 50.392970210839415],
              [-4.085498666578743, 50.39060220211897],
              [-4.085475946787107, 50.389635723036605],
              [-4.083853524102198, 50.387992774041642],
              [-4.08395364910965, 50.386747068265834],
              [-4.084747253169963, 50.386494491801578],
              [-4.088387308700398, 50.387342985102457],
              [-4.088868344442043, 50.387052821878022],
              [-4.088682707813721, 50.388206528072054],
              [-4.089009393966693, 50.386997221224462],
              [-4.090510625102826, 50.386169744534037],
              [-4.100280561628941, 50.383334041906423],
              [-4.104292312550347, 50.379899420071816],
              [-4.107324878736783, 50.376471615085102],
              [-4.108578020438018, 50.373616575473164],
              [-4.109770109829295, 50.372748579636678],
              [-4.110683578335389, 50.372669937156388],
              [-4.110502685374981, 50.371065931805866],
              [-4.109486328382205, 50.369268420549496],
              [-4.110038837478462, 50.367902045353297],
              [-4.112381747780885, 50.365150395405841],
              [-4.112365771150293, 50.36424205958015],
              [-4.113116635504223, 50.363059064107823],
              [-4.113873649599198, 50.362538830618881],
              [-4.115064350237059, 50.36277117795975],
              [-4.115658144426369, 50.36199045474978],
              [-4.118693336552435, 50.361632063425027],
              [-4.118778689220883, 50.361843674235956],
              [-4.120537120849557, 50.361358293379674],
              [-4.122972265985007, 50.362174606404828],
              [-4.124014138405512, 50.363968839110804],
              [-4.125402362870485, 50.364505136691719],
              [-4.12555888115928, 50.365371607765468],
              [-4.125806327842904, 50.3640808877661],
              [-4.12633390333841, 50.36474761538566],
              [-4.129894383477096, 50.364261278407263],
              [-4.130554286832695, 50.365167728914663],
              [-4.129540979808896, 50.365236357453774],
              [-4.130097778080674, 50.365492697004321],
              [-4.12963471051921, 50.365734135726171],
              [-4.129835104795204, 50.366064155582087],
              [-4.131715431038419, 50.366509089497676],
              [-4.132734433329189, 50.366404052539536],
              [-4.132409683983551, 50.367459692523724],
              [-4.129709840167369, 50.367539047125923],
              [-4.129818233528948, 50.368073807575335],
              [-4.131737262301977, 50.368533322801007],
              [-4.131718888657399, 50.368876345332637],
              [-4.13111205398919, 50.368985510376831],
              [-4.13113851998157, 50.369889858912771],
              [-4.131163056413967, 50.369935280382236],
              [-4.131992415277235, 50.370429154325244],
              [-4.134102003213475, 50.370024374527539],
              [-4.133792933548031, 50.368934533316768],
              [-4.133749172294279, 50.368422993592752],
              [-4.133988468015995, 50.368906413642726],
              [-4.134667612443859, 50.368808743390666],
              [-4.13450217270182, 50.368463560409879],
              [-4.135562928707592, 50.368203167422237],
              [-4.135791708629477, 50.368162087350065],
              [-4.135873748173583, 50.367922228998452],
              [-4.134075593828904, 50.367712405539336],
              [-4.13390336641439, 50.366287260075119],
              [-4.133215647894056, 50.366441993561267],
              [-4.133189855980557, 50.366337232648874],
              [-4.134015482723274, 50.366059443014876],
              [-4.134217731286416, 50.364518590452349],
              [-4.134163153215346, 50.364399067776176],
              [-4.133984720846395, 50.364402343539581],
              [-4.133710348974332, 50.364398782351877],
              [-4.133930003736121, 50.364185683992972],
              [-4.133661446175181, 50.364154636166546],
              [-4.133450096517319, 50.363298652463556],
              [-4.135866613126433, 50.363129254230643],
              [-4.136811038394399, 50.362743127542728],
              [-4.13657067372829, 50.363057852066554],
              [-4.137224607060808, 50.363028741444111],
              [-4.137126194705018, 50.362778707982038],
              [-4.137448166926728, 50.363372713635712],
              [-4.139108135777802, 50.36323334658541],
              [-4.13957324027685, 50.363570171285346],
              [-4.139798911338531, 50.363304280654027],
              [-4.13999275469884, 50.363674878792303],
              [-4.140832363248704, 50.36380332911061],
              [-4.142027602507222, 50.362867480303024],
              [-4.143590081783221, 50.363841552405688],
              [-4.145605993171205, 50.364267571643225],
              [-4.146400730289665, 50.363837358721526],
              [-4.146167474598085, 50.363591621316651],
              [-4.146592026970635, 50.36327797265664],
              [-4.146365357362193, 50.36364553181042],
              [-4.146659466499242, 50.363712955467911],
              [-4.14695128731648, 50.36301319863901],
              [-4.147890030883322, 50.362939191907238],
              [-4.148527710405943, 50.362301397122678],
              [-4.149425971610443, 50.362296483561337],
              [-4.150629447874523, 50.362853463292282],
              [-4.152772216239262, 50.362322705069921],
              [-4.152874576815487, 50.362596038091425],
              [-4.151073182357959, 50.363026939689568],
              [-4.151615180719832, 50.364035974357897],
              [-4.152255783628564, 50.363929674046837],
              [-4.152439174198794, 50.364377797342982],
              [-4.151880500985816, 50.364492475823006],
              [-4.153183814113421, 50.36604146243117],
              [-4.154545986647051, 50.36556650298769],
              [-4.15494187164408, 50.365117539546347],
              [-4.155538706084796, 50.365472546019525],
              [-4.155539539025551, 50.365211692882212],
              [-4.157281108265359, 50.364762944435356],
              [-4.155309097663739, 50.363012336675745],
              [-4.156105418742179, 50.362836569015009],
              [-4.155926320540443, 50.362669896577543],
              [-4.154909144999654, 50.362843461386134],
              [-4.156379115061989, 50.362081358426991],
              [-4.15661346674643, 50.361326877027089],
              [-4.1573989804853, 50.361068552192364],
              [-4.160612355247702, 50.361359650268007],
              [-4.162121225353822, 50.361085147437564],
              [-4.163871459665702, 50.358946086036148],
              [-4.164233512982563, 50.358961831612227],
              [-4.16573096292321, 50.359612120515763],
              [-4.167158631403251, 50.359655668310964],
              [-4.167360636595695, 50.3600467571395],
              [-4.167124676446735, 50.361811364891082],
              [-4.165601560119319, 50.362359628442256],
              [-4.165369281360603, 50.361674083733156],
              [-4.164536799432595, 50.361981007653853],
              [-4.165357313054167, 50.36246131828733],
              [-4.163938869179363, 50.362993218787707],
              [-4.163196319420994, 50.362619380342039],
              [-4.163453107273075, 50.363658852917325],
              [-4.162568742357479, 50.363598856492061],
              [-4.163027382579314, 50.363727937571134],
              [-4.162501620532962, 50.3641946358902],
              [-4.16270859944357, 50.364602729288478],
              [-4.163692783463485, 50.364816470148995],
              [-4.163620456350066, 50.36526663757072],
              [-4.164323824999451, 50.365489197360958],
              [-4.16376733989117, 50.365558920274275],
              [-4.163976985540501, 50.365870721584223],
              [-4.163262953790312, 50.366310346756677],
              [-4.16351168668109, 50.366801309668986],
              [-4.164053353476931, 50.366900059421972],
              [-4.164195158363514, 50.366678855261576],
              [-4.164449445636619, 50.36698262905594],
              [-4.164865537729347, 50.366358079997013],
              [-4.166259709714002, 50.366764271832068],
              [-4.165296383877766, 50.36807226928164],
              [-4.16331618652616, 50.369057147926732],
              [-4.163734301999613, 50.369437924528569],
              [-4.165174379302806, 50.369376932246681],
              [-4.165158463558508, 50.368842061369072],
              [-4.166519750199154, 50.367916357988854],
              [-4.167219368361975, 50.366234853018135],
              [-4.166654843809752, 50.364986158375217],
              [-4.168399762783882, 50.364119839116832],
              [-4.169535687562918, 50.364577146998151],
              [-4.169190686538461, 50.364812943224038],
              [-4.169410779991842, 50.365229774684508],
              [-4.170653112237093, 50.365183197263335],
              [-4.172077183497471, 50.365913926523945],
              [-4.173712059848319, 50.365256455128034],
              [-4.175477515587327, 50.365087612323997],
              [-4.177265947367371, 50.365052329237422],
              [-4.176836333203174, 50.365189895019498],
              [-4.17699905562033, 50.365611384603604],
              [-4.1775882988578, 50.365489716310073],
              [-4.177918294003583, 50.364865616805766],
              [-4.179174119757231, 50.364807002583646],
              [-4.180585895877414, 50.365021578446616],
              [-4.180079966311746, 50.365764114111897],
              [-4.180426398112772, 50.365897929547501],
              [-4.180900896986384, 50.365328675141605],
              [-4.182095478481509, 50.365804548252193],
              [-4.182241799471834, 50.365407845309392],
              [-4.181496927778234, 50.364987395448821],
              [-4.18303376693818, 50.365628629030006],
              [-4.182364190545099, 50.365470307492991],
              [-4.182624068516467, 50.365924144263033],
              [-4.181948333672918, 50.366152696507953],
              [-4.182121871071929, 50.366349115363086],
              [-4.182751158188684, 50.366150216489217],
              [-4.183749493264918, 50.366608170956574],
              [-4.183589682341069, 50.366802754461567],
              [-4.184617803448856, 50.367020890115334],
              [-4.183384577098585, 50.36760351372395],
              [-4.185389933640141, 50.367176371386208],
              [-4.18528422717558, 50.368487048585152],
              [-4.182137448770718, 50.369324180122419],
              [-4.183551259526022, 50.369120441540282],
              [-4.183577803707842, 50.369545379415747],
              [-4.185205158636693, 50.368908574915132],
              [-4.185160760483021, 50.369350136625421],
              [-4.184612770333013, 50.369574503766692],
              [-4.185170128762192, 50.369737620468747],
              [-4.185024583220111, 50.370763022918503],
              [-4.183313023919626, 50.370761902149603],
              [-4.184882654516624, 50.371071501143945],
              [-4.184581358633027, 50.371523287258668],
              [-4.183704411972902, 50.371751133001013],
              [-4.184476458521726, 50.371750119218568],
              [-4.184045103675102, 50.372034353139952],
              [-4.184334008797689, 50.372445366789705],
              [-4.184060035893292, 50.374104589088319],
              [-4.182773581851723, 50.374328427647718],
              [-4.184082803565906, 50.374202200543905],
              [-4.184319922568909, 50.37489391365196],
              [-4.183063784158987, 50.375135174491817],
              [-4.183959884735995, 50.375016706191808],
              [-4.183608310235027, 50.375262562198706],
              [-4.183801888437003, 50.376261803814018],
              [-4.184367372429645, 50.376661325258915],
              [-4.183526009397775, 50.376747287920125],
              [-4.182859763270878, 50.376358648903533],
              [-4.18291387691475, 50.376771376042775],
              [-4.18142713838922, 50.376923406716507],
              [-4.181452655389859, 50.37717297266498],
              [-4.184486315309727, 50.376923526202177],
              [-4.18454412045259, 50.37738565257326],
              [-4.182280797463868, 50.377626038834791],
              [-4.18213915899973, 50.377972284267585],
              [-4.184498602059858, 50.377650044396866],
              [-4.185413493291246, 50.380295196775691],
              [-4.186409799616045, 50.381741653792893],
              [-4.185781157858132, 50.381346927382872],
              [-4.186050913415079, 50.382104581628752],
              [-4.186493423818122, 50.381847114588723],
              [-4.186874301351231, 50.381992855608239],
              [-4.186914967179082, 50.381195185361598],
              [-4.187185291265004, 50.382729044457498],
              [-4.18683214174996, 50.382360620808718],
              [-4.183698102014472, 50.382820682216334],
              [-4.184283787393063, 50.38442327678144],
              [-4.187028309987201, 50.383903070432162],
              [-4.187129823139055, 50.383113250061136],
              [-4.18752763927436, 50.383993513956206],
              [-4.188289251283234, 50.383792096902965],
              [-4.19031512347553, 50.388161209416268],
              [-4.189782564937329, 50.388297163229019],
              [-4.189942337175567, 50.388619751439592],
              [-4.190409457915809, 50.388529102435854],
              [-4.192940037990335, 50.390045525733896],
              [-4.191839872697361, 50.390991796763679],
              [-4.184626768353432, 50.392527086776617],
              [-4.185420100546168, 50.393715627766454],
              [-4.186580418379894, 50.393522018515036],
              [-4.186881426612342, 50.393703440602891],
              [-4.185114413192455, 50.394227757940932],
              [-4.184690350387147, 50.395959952769509],
              [-4.183549775660997, 50.396064127403868],
              [-4.182960115806071, 50.395755899092649],
              [-4.180781955233313, 50.396031528303567],
              [-4.179470936697568, 50.395763793665239],
              [-4.179953008124343, 50.396211670988649],
              [-4.179673133473518, 50.396705313462789],
              [-4.180582173922835, 50.396246643532109],
              [-4.183000236574798, 50.396230949170544],
              [-4.184697411092647, 50.396358271765628],
              [-4.185688808816545, 50.394785407125099],
              [-4.187354928338483, 50.394972637504452],
              [-4.187127502731882, 50.394525397110584],
              [-4.187889814417158, 50.394152177786196],
              [-4.187683876560442, 50.393377137854976],
              [-4.194513573226236, 50.392099840128203],
              [-4.194678546989331, 50.39280818392983],
              [-4.195524258722096, 50.39290194837379],
              [-4.196059382389135, 50.39397746722851],
              [-4.196900602095563, 50.39403442954157],
              [-4.197588514738919, 50.394792249932408],
              [-4.202818378789294, 50.396397721388446],
              [-4.205400958474255, 50.399501304734628],
              [-4.205161337533093, 50.399735207189806],
              [-4.204815800937451, 50.39932441941955],
              [-4.204282250384625, 50.399774362749682],
              [-4.205008346290279, 50.399864929950851],
              [-4.204765575980958, 50.400091695752451],
              [-4.20312598200735, 50.401179618295025],
              [-4.201245277531998, 50.401439200114091],
              [-4.199558689859412, 50.402912921443665],
              [-4.200558136188127, 50.402858032536713],
              [-4.201931088894361, 50.402299571023818],
              [-4.201880794999834, 50.403248533749633],
              [-4.202481612775007, 50.404096119877074],
              [-4.200772743576304, 50.405636838535472],
              [-4.201306117094379, 50.406729455249256],
              [-4.20053984064766, 50.407930320884645],
              [-4.199095298641595, 50.410563325930205],
              [-4.197683354522066, 50.411896905441061],
              [-4.193483544507282, 50.421622675711916],
              [-4.192236147436275, 50.422627483962643],
              [-4.192748613199258, 50.424090199784601],
              [-4.192203652748662, 50.42469770020363],
              [-4.190664225603582, 50.42480765957535],
              [-4.188487268048372, 50.423946514517596],
              [-4.187295854314963, 50.424214476221621],
              [-4.186205484201115, 50.423742089209],
              [-4.185596360476836, 50.421245911407851],
              [-4.184579890280559, 50.419960829018301],
              [-4.184141658295967, 50.420013133348029],
              [-4.18416874985899, 50.420295946892274],
              [-4.184900371919309, 50.422549681089663],
              [-4.184224962556429, 50.423108326795941],
              [-4.185764072539152, 50.42363435962038],
              [-4.18383676089362, 50.423208259581962],
              [-4.179750878103448, 50.423173388772732],
              [-4.178150333173661, 50.422319234216616],
              [-4.175838985354425, 50.421989238911543],
              [-4.174386991589448, 50.422408551477133],
              [-4.174442839478621, 50.422613477765836],
              [-4.173549592214536, 50.422353151077751],
              [-4.170715178272178, 50.422582735674794],
              [-4.170234391814796, 50.422654230849943],
              [-4.17090749515595, 50.4228864004644],
              [-4.170672643027157, 50.423230770677783],
              [-4.169528951781453, 50.422788904877564],
              [-4.169890712056678, 50.422705335369962],
              [-4.170185273728891, 50.422637288341043],
              [-4.170685269372318, 50.422521780756583],
              [-4.16974100654727, 50.421699331309433],
              [-4.165882834331819, 50.419925615986351],
              [-4.166360609125747, 50.420677637544429],
              [-4.169671614985592, 50.422244783786049],
              [-4.168880925788323, 50.423057331658285],
              [-4.165616096596769, 50.424631924370708],
              [-4.164053828682609, 50.426465270293058],
              [-4.162634261909914, 50.427446875855374],
              [-4.160693514097634, 50.427806746539247],
              [-4.159826236875141, 50.427993749284539],
              [-4.160689870344415, 50.427974108688673],
              [-4.160280361095069, 50.428133720623045],
              [-4.160778304714624, 50.42827737318639],
              [-4.16348580882137, 50.427594733701333],
              [-4.164129513223981, 50.428526261639817],
              [-4.164690478507766, 50.4286003678261],
              [-4.16527439706034, 50.430010601148027],
              [-4.159925845483455, 50.431357574573049],
              [-4.156261394926171, 50.433763197828938],
              [-4.155826786228773, 50.436439866876825],
              [-4.151093154478967, 50.436648040522371],
              [-4.150715760051606, 50.433014125604622],
              [-4.150205472677248, 50.433313178029898],
              [-4.149359547588996, 50.433197498253925],
              [-4.149198360802464, 50.432872184971806],
              [-4.148619732717891, 50.432968322601738],
              [-4.148169816013051, 50.432237302169888],
              [-4.146546144239942, 50.431115111987175],
              [-4.145909115373879, 50.431196124417717],
              [-4.144586060952062, 50.432330426932971],
              [-4.142820396086069, 50.432863955882326],
              [-4.142508949958837, 50.432733880055544],
              [-4.14159454006256, 50.433284084150714],
              [-4.139341572287968, 50.433380415291658],
              [-4.13932456873382, 50.433159468435072],
              [-4.138045311487641, 50.432926656240916],
              [-4.135635185019913, 50.432941258403396],
              [-4.131936900385341, 50.433683684405871],
              [-4.124850688056683, 50.435722854580241],
              [-4.124367949470146, 50.436106734614988],
              [-4.125090981873634, 50.438414947313561],
              [-4.122648586280459, 50.440830438169165],
              [-4.118452163932211, 50.442357724416389],
              [-4.116894492762374, 50.443400638269544],
              [-4.116107844788105, 50.44300571469573],
              [-4.113460950944898, 50.444166431643005],
              [-4.112714678615647, 50.44341278035585],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000027",
        LAD13CDO: "00HH",
        LAD13NM: "Torbay",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-3.505226821969612, 50.379239281585612],
              [-3.505793879990231, 50.379158190533488],
              [-3.506364662846541, 50.379461082791273],
              [-3.506090136370837, 50.37958605495453],
              [-3.505224283950708, 50.379514524029837],
              [-3.505226821969612, 50.379239281585612],
            ],
          ],
          [
            [
              [-3.516530385909284, 50.518515217319987],
              [-3.513801503002916, 50.516552368432585],
              [-3.509171880187286, 50.516591807517507],
              [-3.508540206222863, 50.515228475422887],
              [-3.509902632497731, 50.514693677007912],
              [-3.510197748022199, 50.514261753300765],
              [-3.509593769494726, 50.512219051153885],
              [-3.509826220247462, 50.511945328783],
              [-3.510139996374033, 50.512098645545322],
              [-3.511124582484667, 50.511748606058916],
              [-3.511584697629637, 50.510963785928396],
              [-3.511365802206716, 50.510115834964097],
              [-3.511830300395172, 50.509822006882565],
              [-3.51098324496643, 50.507675444103519],
              [-3.511769101208248, 50.506979026942879],
              [-3.513076530238141, 50.506629272718932],
              [-3.513311303264317, 50.505988573867747],
              [-3.51269439988222, 50.5043363753934],
              [-3.513047095590531, 50.50337037421447],
              [-3.512792066387918, 50.502891632513766],
              [-3.512200254413943, 50.502773413706862],
              [-3.513098354600447, 50.502635829636844],
              [-3.512646878761551, 50.502275659120478],
              [-3.512988346632897, 50.501973531828874],
              [-3.512444609013978, 50.500181878395757],
              [-3.512838050840711, 50.499653336305158],
              [-3.512724270350567, 50.498696096602416],
              [-3.514298170417492, 50.497779862859069],
              [-3.51312968063746, 50.496866022036883],
              [-3.515209906873909, 50.496132955923066],
              [-3.515075352831319, 50.495585197875343],
              [-3.513961476290939, 50.495540336908682],
              [-3.513383033471283, 50.494778906860233],
              [-3.513448878682305, 50.493530635763065],
              [-3.513922006697373, 50.493115271719027],
              [-3.513446532378606, 50.4922220946574],
              [-3.514343341988734, 50.489493453864029],
              [-3.513821815756976, 50.489055055330851],
              [-3.514580866999668, 50.488633269965653],
              [-3.51457532550959, 50.487710595954255],
              [-3.514403301642866, 50.487444824782976],
              [-3.513309489977695, 50.48736012521487],
              [-3.5128290194643, 50.485999341383042],
              [-3.512976690933832, 50.485951339833591],
              [-3.513164540947891, 50.48551471891885],
              [-3.514603991864391, 50.485256760686809],
              [-3.514787797293299, 50.48170368132044],
              [-3.511920200120854, 50.479652655191238],
              [-3.511483892155064, 50.479848988819434],
              [-3.509697533599629, 50.479167978465803],
              [-3.507084494081934, 50.479480655544315],
              [-3.507014482979472, 50.479140703603562],
              [-3.505321039602307, 50.478941381008383],
              [-3.505403571808481, 50.478877357807008],
              [-3.504554819930384, 50.478691375264688],
              [-3.504210232049453, 50.478048288920739],
              [-3.503357453587371, 50.47760153438395],
              [-3.50146383050761, 50.477487483547939],
              [-3.501049854179865, 50.477054834955901],
              [-3.497281182117943, 50.475851478994343],
              [-3.500889579533927, 50.474931706780936],
              [-3.501583691003259, 50.474420906104633],
              [-3.502080072200657, 50.473319073880823],
              [-3.501742532134569, 50.472718159087549],
              [-3.501045677209024, 50.47278560997799],
              [-3.500784994839952, 50.472475094762693],
              [-3.501091518082016, 50.471874862258701],
              [-3.500382465387458, 50.471823750558976],
              [-3.499486611519954, 50.471168870833033],
              [-3.498687242387681, 50.471291590661615],
              [-3.497890280776028, 50.470955598412061],
              [-3.496398288586125, 50.471457755177759],
              [-3.496101740980015, 50.470858095692677],
              [-3.495485308904221, 50.470794071474032],
              [-3.495097491655961, 50.47029361317604],
              [-3.494698758834518, 50.470385075173553],
              [-3.494745556981643, 50.47012635656526],
              [-3.493092584176147, 50.469991990816986],
              [-3.49197241763987, 50.470488419270517],
              [-3.492059005016368, 50.47019501575916],
              [-3.49038281050903, 50.469187627353882],
              [-3.490286659521463, 50.467877586758185],
              [-3.491082455288856, 50.466253931811707],
              [-3.490029372252345, 50.464534348689625],
              [-3.488998022335899, 50.463818168302659],
              [-3.487340827566854, 50.463541676147635],
              [-3.486818770658567, 50.463158924286233],
              [-3.485410195186121, 50.463441329766631],
              [-3.484430954873494, 50.463261364137907],
              [-3.484072170225672, 50.463502474041128],
              [-3.482985729612472, 50.46322853108493],
              [-3.482396836814722, 50.463365544366496],
              [-3.481624390577564, 50.464301728534849],
              [-3.480810363169484, 50.464043182461019],
              [-3.480587345364342, 50.463534282888787],
              [-3.480996325295912, 50.463501197234635],
              [-3.481227893643499, 50.462885782552881],
              [-3.482870964575273, 50.461721760513853],
              [-3.483418055377518, 50.460384626464077],
              [-3.484485097451167, 50.459816108315195],
              [-3.484332628455646, 50.460076171285245],
              [-3.484636966233315, 50.460031816605209],
              [-3.485070976731004, 50.460753863186554],
              [-3.486711269851769, 50.460899296123536],
              [-3.487787483044596, 50.460668791521975],
              [-3.488666340959117, 50.459492871898135],
              [-3.490042622489259, 50.458725182751387],
              [-3.490649947182699, 50.458908068151928],
              [-3.490867365671658, 50.458250544362265],
              [-3.491252069023359, 50.45820873985349],
              [-3.492853900168652, 50.458700834600087],
              [-3.49358743107291, 50.458641055272864],
              [-3.493657405458432, 50.459028682731891],
              [-3.494399696453531, 50.459246689725603],
              [-3.497944883319029, 50.458995142727318],
              [-3.498177508452167, 50.459223284278011],
              [-3.499466797063326, 50.459229158369716],
              [-3.500749135704559, 50.459950103610787],
              [-3.50412731371489, 50.459629472614182],
              [-3.509823630992377, 50.45680632324548],
              [-3.510485439827533, 50.456177169248711],
              [-3.510123651081015, 50.455698905936067],
              [-3.510554418074642, 50.455070052097362],
              [-3.51286435411343, 50.455587750829814],
              [-3.513480844997766, 50.454335007718505],
              [-3.51596187498476, 50.45381524676322],
              [-3.517183044809744, 50.454558393785376],
              [-3.518762340572595, 50.454166347884382],
              [-3.519106422733811, 50.454749142646961],
              [-3.520631933648457, 50.455403738628547],
              [-3.520918456992671, 50.455201232816108],
              [-3.520582001340978, 50.455823496719653],
              [-3.523221277137108, 50.457423121671013],
              [-3.523796861614811, 50.457579269038675],
              [-3.524466025421797, 50.457271912255791],
              [-3.525241035133061, 50.457583727864403],
              [-3.527837774981383, 50.457068492551507],
              [-3.528940211730542, 50.457223090509139],
              [-3.525272398546748, 50.457770385209329],
              [-3.525335025508094, 50.459060156992592],
              [-3.526510520703966, 50.459426070996045],
              [-3.525197109323536, 50.459240039954963],
              [-3.523965112803709, 50.46092900832938],
              [-3.52520504029873, 50.461369638404861],
              [-3.526158058847563, 50.459856096115182],
              [-3.52654952383981, 50.459937299486576],
              [-3.526385634818611, 50.460273115249045],
              [-3.527894849566814, 50.460631938375244],
              [-3.52858948439366, 50.460502298407526],
              [-3.529021190777386, 50.460869863090096],
              [-3.530381337492774, 50.461148770651761],
              [-3.530718735352244, 50.458900408639082],
              [-3.529005762920568, 50.457726774609675],
              [-3.531028608262309, 50.458985368562942],
              [-3.530645186493254, 50.46116058886129],
              [-3.533512630074807, 50.463086148123075],
              [-3.535502017984141, 50.462692067396681],
              [-3.540168135015177, 50.460512376549936],
              [-3.541307926546191, 50.459212083658151],
              [-3.541050955680546, 50.458718136757454],
              [-3.540302047601917, 50.458649809461811],
              [-3.540334068169942, 50.458161028116386],
              [-3.541951282388706, 50.457984013865897],
              [-3.543098640056982, 50.457482242237916],
              [-3.545174400200734, 50.455814240585191],
              [-3.545172269471752, 50.454279044429192],
              [-3.543884684085087, 50.453759215248525],
              [-3.544278125470432, 50.453339383597843],
              [-3.544480553459113, 50.453542652047631],
              [-3.544852452586017, 50.452634746384639],
              [-3.546785191684584, 50.452031668719904],
              [-3.549142432720717, 50.452351057133306],
              [-3.550454480904016, 50.452110534029245],
              [-3.550832584696366, 50.451525399967963],
              [-3.551937783460375, 50.45112033428974],
              [-3.553153527529543, 50.449317058554804],
              [-3.553675523922639, 50.44727840773303],
              [-3.554175148550009, 50.447266337522613],
              [-3.555442218415766, 50.445659312725958],
              [-3.557148631880453, 50.442550736532652],
              [-3.557134811861558, 50.441057063325573],
              [-3.557812276679203, 50.439986732528546],
              [-3.559070624377764, 50.435466710545178],
              [-3.559054459349054, 50.433088982278406],
              [-3.556079415177209, 50.432581101132364],
              [-3.55766011199707, 50.432606711514637],
              [-3.557848601369045, 50.432086147671946],
              [-3.55735182793433, 50.431622427225228],
              [-3.556379599724556, 50.431687603912472],
              [-3.555713307451321, 50.432623771999083],
              [-3.555175841996258, 50.431347552430267],
              [-3.553481550212475, 50.431420558038631],
              [-3.553395367135052, 50.430852405275097],
              [-3.553936566446759, 50.430567269730929],
              [-3.553136781882007, 50.429232488006903],
              [-3.554423445915754, 50.428279950821654],
              [-3.55774350717595, 50.428112319760451],
              [-3.558296256892911, 50.428352243787451],
              [-3.559072447098583, 50.427679003015385],
              [-3.559505018884477, 50.426232405058585],
              [-3.559189556187661, 50.425115113347388],
              [-3.559402062346854, 50.421255741638603],
              [-3.558821968265968, 50.420180669119397],
              [-3.558206944933807, 50.420142142833605],
              [-3.55772872954165, 50.419510891049505],
              [-3.5574685278875, 50.419603413924605],
              [-3.557495008479616, 50.419209132611726],
              [-3.556629925852607, 50.419185638262071],
              [-3.555709076878338, 50.418534219604602],
              [-3.556104072945042, 50.418001901092474],
              [-3.555592742007085, 50.417475411216572],
              [-3.555923207182879, 50.417078862725475],
              [-3.556546830863803, 50.417252192276706],
              [-3.556857625591986, 50.416256020266005],
              [-3.556249676607739, 50.414585020734052],
              [-3.555125550932608, 50.414428276249872],
              [-3.55446501083955, 50.414716812745219],
              [-3.554510045599928, 50.414372648993364],
              [-3.55383649495645, 50.414220661335598],
              [-3.55436704200415, 50.414174898701717],
              [-3.554777452106112, 50.413125236625298],
              [-3.553934533802326, 50.411969111035454],
              [-3.554558690422259, 50.411860039616727],
              [-3.555349370403861, 50.410905122293478],
              [-3.556170054807422, 50.408676279443519],
              [-3.555171811254294, 50.406772159533766],
              [-3.553816525719443, 50.405781173324648],
              [-3.552204433752145, 50.405456440644329],
              [-3.551176491961257, 50.406471163163594],
              [-3.548748788150304, 50.406510713676752],
              [-3.549028571009385, 50.40641615032299],
              [-3.547119896945361, 50.405669891805928],
              [-3.547615515172192, 50.405418666808714],
              [-3.547111332137032, 50.404888446906995],
              [-3.546852652487091, 50.405025895109887],
              [-3.54656253926855, 50.404111489300298],
              [-3.546188220454871, 50.404370990392664],
              [-3.546207863878127, 50.402942517878984],
              [-3.544730168548504, 50.402115841189257],
              [-3.54139021252464, 50.402873363964495],
              [-3.540299626740695, 50.403480503151968],
              [-3.5394840919715, 50.403326715881803],
              [-3.538787728827537, 50.403810803064452],
              [-3.53738805063476, 50.403869786757454],
              [-3.537258559137003, 50.404347268770735],
              [-3.536592024174041, 50.404580022233496],
              [-3.535047434177993, 50.404336003627463],
              [-3.533225838503076, 50.404839401080864],
              [-3.531956319154763, 50.404659167864203],
              [-3.530149377237465, 50.404963561789593],
              [-3.528324220945241, 50.404833768373955],
              [-3.525763050401213, 50.403782751026895],
              [-3.524625425376529, 50.403895702716106],
              [-3.524634860649923, 50.40366354008718],
              [-3.523597138443666, 50.403600693075326],
              [-3.522930414587758, 50.403258669479222],
              [-3.524199297605207, 50.403114334681497],
              [-3.524165210220921, 50.402841371196743],
              [-3.522784783753596, 50.402534780296214],
              [-3.522741435253887, 50.402279925186143],
              [-3.521377296031662, 50.402129595761551],
              [-3.52053328456754, 50.403149733535024],
              [-3.520015584471925, 50.402978424856521],
              [-3.519526413392629, 50.403170988170217],
              [-3.516473975841433, 50.401268180954197],
              [-3.513143392203197, 50.398532494239376],
              [-3.512775672776702, 50.398517492021924],
              [-3.512096502316775, 50.39923863035191],
              [-3.511462134875492, 50.399552641243744],
              [-3.511841454597016, 50.399094448781263],
              [-3.512497701955525, 50.398581365822068],
              [-3.511654577034084, 50.3979528725215],
              [-3.511074778790139, 50.397905545890112],
              [-3.510833538220393, 50.398767127840003],
              [-3.51065613554284, 50.397749097350626],
              [-3.512240939896107, 50.397808543898343],
              [-3.511837173979574, 50.397014248796744],
              [-3.512622910131742, 50.397299026403751],
              [-3.512909195479138, 50.396922061322897],
              [-3.512879288895811, 50.396247918517901],
              [-3.511776242407322, 50.395939387504257],
              [-3.510879755054991, 50.396684029677587],
              [-3.510904779529712, 50.397161273011946],
              [-3.508567254101035, 50.39767818185976],
              [-3.507841837100867, 50.398451159522338],
              [-3.505322629115151, 50.399454221872148],
              [-3.505130005459482, 50.399991843082439],
              [-3.50758776865937, 50.402017776857868],
              [-3.513061854027382, 50.405515403776313],
              [-3.512915430182505, 50.405689988613567],
              [-3.507197386218183, 50.402087591908192],
              [-3.505000390204881, 50.400164401761771],
              [-3.504025823740352, 50.399915291942655],
              [-3.501114119344567, 50.400391807483395],
              [-3.5004379920916, 50.400770175797796],
              [-3.500729698807401, 50.40091750667051],
              [-3.498576571631123, 50.401490292738558],
              [-3.495490204749155, 50.401027272932851],
              [-3.492652811291539, 50.401320946793184],
              [-3.48860131030106, 50.400411447622247],
              [-3.486778090704112, 50.400918633998856],
              [-3.483690490883377, 50.400456216696249],
              [-3.482594589141347, 50.399832537716243],
              [-3.482353206421627, 50.400078445505798],
              [-3.482567560901412, 50.39878601197514],
              [-3.484674332954619, 50.397988371462866],
              [-3.487938648430548, 50.397988021978477],
              [-3.488563301952542, 50.397536636653882],
              [-3.489560976015732, 50.395923875464504],
              [-3.488569788645079, 50.394375253784155],
              [-3.490076338422553, 50.394276814311709],
              [-3.491809524934603, 50.393330935325324],
              [-3.492440875766661, 50.393723056029103],
              [-3.492140680609723, 50.39325833412061],
              [-3.492554431540496, 50.392859098212277],
              [-3.492103932504548, 50.392625645806305],
              [-3.492902999532961, 50.392400439499369],
              [-3.492670881754084, 50.391549912376917],
              [-3.496611584246164, 50.391777169160349],
              [-3.497392843328298, 50.391568349766523],
              [-3.497243885995323, 50.391037837519882],
              [-3.499489094461048, 50.3897416872477],
              [-3.50134946881037, 50.389715884189968],
              [-3.503282684102007, 50.388879671181087],
              [-3.503472761404063, 50.388440116909656],
              [-3.503778366094377, 50.386494411472356],
              [-3.503115793054459, 50.384581010079728],
              [-3.501848600245335, 50.384097330172615],
              [-3.499008356889729, 50.384312055847623],
              [-3.498934691801859, 50.384027903090214],
              [-3.497985350107615, 50.384032042475255],
              [-3.497569070659016, 50.38238255150619],
              [-3.496297119384918, 50.382146201254841],
              [-3.496506324105573, 50.381821532132292],
              [-3.496141441786894, 50.381534829529706],
              [-3.496544516057067, 50.381426214202314],
              [-3.49595356077141, 50.381464397301507],
              [-3.498790041354106, 50.380644513289482],
              [-3.501459334413097, 50.380635258761714],
              [-3.501987049982646, 50.380109502608441],
              [-3.506229961815017, 50.379915214783296],
              [-3.506585867202152, 50.37938446789888],
              [-3.507813692647171, 50.379338873137186],
              [-3.509389907561029, 50.379545965592165],
              [-3.514844391978906, 50.37884276983597],
              [-3.515499273499921, 50.378958350450176],
              [-3.515507752055151, 50.379312595099563],
              [-3.52426229571325, 50.377662921164308],
              [-3.526257058350304, 50.377676327183856],
              [-3.5294171925522, 50.378284155672418],
              [-3.533906630519092, 50.377325637383223],
              [-3.535422522641685, 50.376098661093408],
              [-3.536549499301038, 50.375982143594683],
              [-3.539073514864264, 50.375359666912651],
              [-3.541199848443706, 50.375399854708093],
              [-3.541654193563448, 50.374534922411115],
              [-3.543713872319255, 50.373995846796682],
              [-3.544240572870242, 50.373498689751187],
              [-3.548490763099762, 50.376142107815511],
              [-3.555059418007884, 50.377971925268845],
              [-3.557147690164586, 50.379594343640889],
              [-3.558886947960529, 50.381978683635104],
              [-3.559493718290113, 50.382295224076657],
              [-3.55997836577173, 50.384933803792983],
              [-3.562196997267921, 50.387683105977757],
              [-3.563829742355321, 50.391473634747946],
              [-3.564648118619863, 50.392187526891966],
              [-3.565785041487644, 50.391594821375939],
              [-3.566896703956649, 50.392284961966247],
              [-3.56723000862242, 50.391811892971795],
              [-3.569504054462294, 50.392212025805698],
              [-3.569820478989969, 50.392974926498638],
              [-3.569169824890845, 50.393892082814489],
              [-3.571863089948592, 50.394755089578297],
              [-3.571899032774355, 50.395161123818674],
              [-3.573024948914605, 50.395086539847384],
              [-3.574366458780212, 50.393960348089799],
              [-3.574703886051707, 50.394123064164582],
              [-3.574729756203194, 50.395031088656268],
              [-3.572959389930148, 50.395911259397089],
              [-3.573768835917799, 50.396731335445573],
              [-3.575510709095702, 50.397197911640014],
              [-3.575331217728425, 50.399125917023028],
              [-3.576026345644285, 50.400568997667094],
              [-3.573600540690392, 50.40218565575983],
              [-3.575356662806576, 50.402778854454873],
              [-3.576371676673051, 50.403724738603891],
              [-3.578861724164746, 50.404168530056637],
              [-3.577504789997437, 50.407717009322994],
              [-3.578336536030275, 50.408733714787296],
              [-3.580124653760774, 50.409433434040167],
              [-3.579834180819992, 50.410220739573035],
              [-3.58196911082558, 50.411189130245511],
              [-3.582531565710661, 50.410202054024268],
              [-3.585328087487649, 50.410623549930072],
              [-3.587792704185643, 50.410545857950069],
              [-3.589423951234971, 50.409201487089234],
              [-3.591397667923186, 50.408630370882562],
              [-3.594165243870913, 50.409992802310541],
              [-3.596299013564947, 50.409829526378303],
              [-3.597515909322984, 50.410149185418042],
              [-3.599029327902654, 50.409284767591821],
              [-3.600257505954285, 50.410189739812012],
              [-3.602350297722413, 50.411064802253058],
              [-3.603737983273407, 50.411230047813632],
              [-3.603788565544044, 50.411602593381168],
              [-3.604583533735175, 50.411970267337999],
              [-3.604464443507841, 50.412236328342743],
              [-3.607345693707391, 50.412739769435994],
              [-3.607232954506602, 50.413317830728346],
              [-3.60919219722223, 50.413566848284667],
              [-3.609839854048731, 50.413946417869766],
              [-3.61159489873447, 50.413776411610527],
              [-3.611995074956923, 50.414369853562569],
              [-3.613210393684543, 50.414512192830742],
              [-3.613056743317224, 50.415002688007263],
              [-3.613892788140679, 50.415498340472119],
              [-3.613820562636199, 50.417680341709371],
              [-3.614443149158095, 50.41909991788652],
              [-3.614023998130308, 50.422836365391639],
              [-3.616674325489543, 50.42310984253001],
              [-3.618732103447303, 50.422684594641126],
              [-3.61925675053522, 50.422753738972915],
              [-3.619394929117052, 50.423172725265488],
              [-3.621520446299548, 50.422794151950093],
              [-3.622750822714532, 50.42299913911517],
              [-3.623823245233554, 50.422701765943813],
              [-3.623457279830655, 50.424350043555975],
              [-3.624120630861806, 50.424974845963114],
              [-3.628024714580984, 50.426004018820265],
              [-3.628043297478418, 50.433605321230317],
              [-3.628393742043921, 50.434338806431491],
              [-3.627937729160935, 50.434919893118511],
              [-3.627108355957616, 50.434797490208865],
              [-3.625426918447854, 50.436644040605472],
              [-3.625267345358915, 50.438892019597979],
              [-3.624570432651312, 50.440254420439949],
              [-3.625122085082007, 50.44044817546613],
              [-3.625056934546754, 50.441300797090385],
              [-3.623545101003027, 50.441321907942367],
              [-3.618872993078985, 50.442582296910331],
              [-3.615621947575173, 50.444386678111094],
              [-3.615056093871485, 50.445554732188299],
              [-3.612758107418605, 50.446981547585544],
              [-3.607139540296386, 50.446731034993086],
              [-3.606035291887334, 50.447313791922646],
              [-3.602939316997742, 50.447978861984893],
              [-3.601256937866192, 50.448086573292485],
              [-3.600578545771043, 50.447809907941028],
              [-3.599576267074144, 50.447994575413162],
              [-3.597061464002069, 50.448981566176528],
              [-3.595554182420905, 50.448517486444302],
              [-3.594244562883131, 50.448527347236677],
              [-3.591945773494692, 50.449113742595756],
              [-3.592165029292044, 50.450232261929514],
              [-3.590614036911735, 50.450270567419395],
              [-3.589215256207782, 50.450815818683886],
              [-3.58864544190007, 50.450625739297024],
              [-3.588409013784091, 50.451978028714329],
              [-3.586868387003348, 50.452243684000777],
              [-3.586766700888234, 50.453709251231672],
              [-3.587971402209883, 50.455357553384388],
              [-3.587481242956057, 50.455444287249257],
              [-3.586928197679883, 50.456177626831597],
              [-3.586967054103782, 50.457001821901791],
              [-3.588263708673145, 50.457228756218555],
              [-3.588102518814714, 50.458304808093686],
              [-3.588683388359636, 50.458485745599603],
              [-3.58890538096777, 50.459812886372589],
              [-3.591467388816954, 50.459664546228865],
              [-3.590491372874161, 50.462320250800751],
              [-3.586622704701359, 50.464771700339249],
              [-3.582785630072637, 50.469024928358756],
              [-3.582589682729258, 50.47193436007295],
              [-3.58355199199564, 50.473362058118354],
              [-3.584115077003356, 50.475830359312496],
              [-3.58363669379183, 50.475980770688068],
              [-3.583558343815312, 50.476714823586512],
              [-3.584233800358642, 50.477737205885155],
              [-3.584155533892398, 50.478009881194268],
              [-3.584455070772963, 50.479254127745058],
              [-3.582060371883117, 50.479844321996843],
              [-3.581946038157921, 50.480303655494183],
              [-3.580829357024378, 50.480335021023677],
              [-3.579200522923989, 50.482211638043111],
              [-3.576692573045999, 50.483132426432782],
              [-3.575619201996102, 50.484552678671477],
              [-3.575276674735519, 50.485824525882109],
              [-3.574830506079568, 50.486014935676202],
              [-3.574905460973443, 50.486870119346918],
              [-3.574087998616627, 50.487680718409131],
              [-3.57297578703237, 50.487470017430013],
              [-3.572818812960947, 50.487879552767915],
              [-3.571513926015909, 50.487979924993638],
              [-3.570331217163975, 50.489256804703523],
              [-3.570177927372252, 50.490458628750353],
              [-3.56976637015931, 50.490716903537816],
              [-3.568952569199893, 50.490408603831355],
              [-3.568973561696708, 50.489978423463953],
              [-3.567491969045017, 50.489427293121558],
              [-3.567235144852568, 50.487928809809823],
              [-3.564757967869145, 50.486980938539361],
              [-3.562373236634303, 50.488490642017609],
              [-3.562080836691649, 50.491507444570146],
              [-3.562818250759872, 50.493346639555575],
              [-3.561059342895296, 50.494477367810163],
              [-3.558393392377146, 50.495040128991313],
              [-3.556352984470105, 50.494818312151793],
              [-3.553675140806981, 50.495106817665381],
              [-3.55155635930756, 50.494902151730621],
              [-3.543622904197825, 50.497459325844105],
              [-3.543260797419312, 50.498981354851807],
              [-3.542244137219159, 50.498850934787974],
              [-3.541270456010202, 50.500776780175443],
              [-3.539009362190222, 50.502186327227804],
              [-3.539128464891925, 50.504548276617967],
              [-3.538466651557286, 50.504405035357998],
              [-3.536618680651366, 50.505950271755168],
              [-3.535112134583744, 50.505155332387965],
              [-3.533165061148362, 50.504646778989951],
              [-3.532757744951041, 50.505263710345808],
              [-3.529936992759505, 50.504600224879148],
              [-3.529571707516612, 50.506082677343315],
              [-3.528130516717659, 50.506085421862139],
              [-3.52899880164937, 50.50681419155633],
              [-3.526886976978476, 50.508018282166276],
              [-3.52485045776828, 50.508717705219958],
              [-3.522911678437349, 50.509958127579033],
              [-3.519980932498433, 50.51105588738789],
              [-3.520891106208002, 50.517696558228387],
              [-3.517254268974699, 50.517378886495905],
              [-3.516530385909284, 50.518515217319987],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000028",
        LAD13CDO: "00HN",
        LAD13NM: "Bournemouth",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.893901259191028, 50.780101268245431],
            [-1.892839355737143, 50.779274780986412],
            [-1.890195231634735, 50.778190520245509],
            [-1.886738896369774, 50.775784404333855],
            [-1.886710112245866, 50.775369818119039],
            [-1.887830506865375, 50.774781883743515],
            [-1.887365411835461, 50.773563839863897],
            [-1.885561458181505, 50.772407439216757],
            [-1.883520096841406, 50.77209427950423],
            [-1.882070124611979, 50.771780782841034],
            [-1.880948459398568, 50.770630392253885],
            [-1.87784655466871, 50.769741421278233],
            [-1.874216058056809, 50.765590183180649],
            [-1.874899950294391, 50.764358030891984],
            [-1.874755161491701, 50.763886663230956],
            [-1.873391065995517, 50.763390600168258],
            [-1.873455100277081, 50.763305239734862],
            [-1.871378071649879, 50.763765192565145],
            [-1.869816141115267, 50.763042256044187],
            [-1.868723093885251, 50.762996070363343],
            [-1.864758294272171, 50.764136308616763],
            [-1.859100217295211, 50.768610638605765],
            [-1.857259753067552, 50.769495974725764],
            [-1.856856733457689, 50.77005661996548],
            [-1.856957781325946, 50.771334591888881],
            [-1.855989195189217, 50.772726355416935],
            [-1.8551704122521, 50.772889908018463],
            [-1.854190097343897, 50.772549668106542],
            [-1.853275048005072, 50.772658232421705],
            [-1.852323994359044, 50.773391729400466],
            [-1.851356073305403, 50.773619813327819],
            [-1.847927386022517, 50.77127732650176],
            [-1.848002530048392, 50.769967202608413],
            [-1.847257384136674, 50.769296282180505],
            [-1.843765671492149, 50.768580350656748],
            [-1.845096715899567, 50.767869009479249],
            [-1.845323949274155, 50.767331552804677],
            [-1.845083269244133, 50.766786282094024],
            [-1.844038421216689, 50.766297492839897],
            [-1.842781342648137, 50.764405561945942],
            [-1.843617787720272, 50.762737656043676],
            [-1.842140342267224, 50.761482098937215],
            [-1.839716599107665, 50.76085201364711],
            [-1.837770507653268, 50.759870930866029],
            [-1.836786187916617, 50.759165437913623],
            [-1.832462790252917, 50.757763663076268],
            [-1.82415204972171, 50.757419620139082],
            [-1.821205644126458, 50.757532051361032],
            [-1.819280855004524, 50.758152277856787],
            [-1.818753876020154, 50.75767755018191],
            [-1.81714545653081, 50.757139082982846],
            [-1.816185277435911, 50.756154682099684],
            [-1.815757328996866, 50.755384239131196],
            [-1.814338847358384, 50.754521398592075],
            [-1.816954430680153, 50.753770146337523],
            [-1.817310674674088, 50.753294994064099],
            [-1.816521295589376, 50.750336080358856],
            [-1.815017573721127, 50.748476729294282],
            [-1.815278729986694, 50.746584192392405],
            [-1.814849416843793, 50.744386610393818],
            [-1.815001108073468, 50.744016353890267],
            [-1.815445983851067, 50.744055726115505],
            [-1.815567660358895, 50.743386865644567],
            [-1.811120955525578, 50.742153161754231],
            [-1.810749279310184, 50.74153836223995],
            [-1.809487878633486, 50.741556994351903],
            [-1.808007543335677, 50.742089632512361],
            [-1.807392795967276, 50.741662368734715],
            [-1.806352732958875, 50.741274864093214],
            [-1.806212272934093, 50.740968881038235],
            [-1.804281978332178, 50.74098454638645],
            [-1.80355560318159, 50.74015060734618],
            [-1.804595979074358, 50.739769265959964],
            [-1.804439770582187, 50.739505519789645],
            [-1.801191455632843, 50.739214967618267],
            [-1.798710064122046, 50.738875290627128],
            [-1.796309615072269, 50.739159792152144],
            [-1.795416395764662, 50.737301247498998],
            [-1.7934276649789, 50.736781567655989],
            [-1.793312789814366, 50.736480110360667],
            [-1.794048870585177, 50.735367220669936],
            [-1.791926403110584, 50.733389568675932],
            [-1.791782459154852, 50.732616842338928],
            [-1.792300750927764, 50.732045832042388],
            [-1.793755235888302, 50.732206681185772],
            [-1.794500285689622, 50.73194181200094],
            [-1.794675882280463, 50.731316231464483],
            [-1.794247481452465, 50.730781313000769],
            [-1.792000754814516, 50.730034537933911],
            [-1.788475759514462, 50.729363646825163],
            [-1.788054345903495, 50.72949867188656],
            [-1.788395375853015, 50.729279869345987],
            [-1.787951153563118, 50.729131583622483],
            [-1.7842224367287, 50.72996646572139],
            [-1.781728663778666, 50.729678561075367],
            [-1.780495447556492, 50.728884894038842],
            [-1.779181712546376, 50.728954357991384],
            [-1.776252768940438, 50.729918191212228],
            [-1.774007099970792, 50.72960901422136],
            [-1.772438084721043, 50.728258864231378],
            [-1.770113824524005, 50.727527702498591],
            [-1.768548294520947, 50.726657715314659],
            [-1.76800242616181, 50.725305032504593],
            [-1.76816097155874, 50.725043660558015],
            [-1.768440607703542, 50.725221371325517],
            [-1.768909447032569, 50.72610628049749],
            [-1.768541815113277, 50.723655946654084],
            [-1.767004115913543, 50.721775215736493],
            [-1.763514597819414, 50.720722349434986],
            [-1.761878455176713, 50.720718124043877],
            [-1.760879933627409, 50.720126162661053],
            [-1.76096115274348, 50.7197540317785],
            [-1.761202305205052, 50.719966752771008],
            [-1.761423216591117, 50.719701920225084],
            [-1.761998242295936, 50.720281323811626],
            [-1.762385341380426, 50.720207473130934],
            [-1.762205522042612, 50.719069532043612],
            [-1.763344636083127, 50.718745412438551],
            [-1.763681954033548, 50.719836908802129],
            [-1.764046575992625, 50.719724338545845],
            [-1.764333691201193, 50.720096316496559],
            [-1.764271243823753, 50.719837201041749],
            [-1.76571596492579, 50.719872483249482],
            [-1.764140712877071, 50.719595933369774],
            [-1.763435948928881, 50.71861520354674],
            [-1.762700773521802, 50.71861461059575],
            [-1.762830077316132, 50.718816309605813],
            [-1.761242007085222, 50.718005528690625],
            [-1.759243558376152, 50.717680384857537],
            [-1.756904051774766, 50.718395850490872],
            [-1.75151178769576, 50.718581435690439],
            [-1.749299152425546, 50.718053329694996],
            [-1.747058275655999, 50.718044898022967],
            [-1.746879998076061, 50.717480669759823],
            [-1.7500107235853, 50.71769604748372],
            [-1.750122556409868, 50.717442693038052],
            [-1.746836871226971, 50.71733489460761],
            [-1.746722418387448, 50.715966857589635],
            [-1.749516813169542, 50.716813704748269],
            [-1.748690050805832, 50.715667157626349],
            [-1.746013985980323, 50.715480611878839],
            [-1.745781737072724, 50.715996286967872],
            [-1.746529604281528, 50.715999711998279],
            [-1.746691233891544, 50.7180755764617],
            [-1.744368395429695, 50.718030944422537],
            [-1.743690684350026, 50.718404453417556],
            [-1.742792204239628, 50.720032851694249],
            [-1.742697540169287, 50.721541616077005],
            [-1.741125084325582, 50.721543534746061],
            [-1.741876788558367, 50.719573102972845],
            [-1.745188788172689, 50.715634386910615],
            [-1.74541997457233, 50.714268900788774],
            [-1.748836818826053, 50.712850061524584],
            [-1.748852295298125, 50.711011986793018],
            [-1.748950099276471, 50.712058947643122],
            [-1.755106283757214, 50.713808516711453],
            [-1.76928392388687, 50.71648215194525],
            [-1.803665796637511, 50.720545882416999],
            [-1.809194107984589, 50.720699829581498],
            [-1.816282342678212, 50.720798426175946],
            [-1.829988361740971, 50.720666309218167],
            [-1.837886352638995, 50.720108303015806],
            [-1.84143124271176, 50.719922524861708],
            [-1.841440453966575, 50.719711209426798],
            [-1.84380833643158, 50.719467101312361],
            [-1.857872173977593, 50.718429319899542],
            [-1.865196937168997, 50.717464094439684],
            [-1.869362943392544, 50.716916676407124],
            [-1.876204131516692, 50.715448426025894],
            [-1.8823351847284, 50.714586067313903],
            [-1.88809856299931, 50.713383111218349],
            [-1.900908004011262, 50.709436990517624],
            [-1.907574381938057, 50.715892903434707],
            [-1.90632367163828, 50.720163429574043],
            [-1.905809431715126, 50.723718718443408],
            [-1.905714871450424, 50.724956033204272],
            [-1.9053369646184, 50.726188622256949],
            [-1.901610900277519, 50.732292461402558],
            [-1.900835115072753, 50.733307076671238],
            [-1.899667621382992, 50.73393017077511],
            [-1.898303894032566, 50.73616906185287],
            [-1.897053517499668, 50.737060937557274],
            [-1.89638338508189, 50.738925420398843],
            [-1.891147139555105, 50.741952981367817],
            [-1.892408826793203, 50.742419068818855],
            [-1.893472681641149, 50.743264451826349],
            [-1.907623191485727, 50.745907732054889],
            [-1.918531691926826, 50.748033602304623],
            [-1.925511977865712, 50.751025604267234],
            [-1.935073164690655, 50.755054595846126],
            [-1.937616966092824, 50.755687260157025],
            [-1.936285849335967, 50.756697313669349],
            [-1.930475520589888, 50.76100956270291],
            [-1.926840916773247, 50.764241089132703],
            [-1.928122583791095, 50.765247256111081],
            [-1.927819706154571, 50.766731748233653],
            [-1.927068672408155, 50.768242938653984],
            [-1.924791538320744, 50.769884445213904],
            [-1.924361801140794, 50.771687183408311],
            [-1.922904684313506, 50.772979367432768],
            [-1.921831844072472, 50.773404005385473],
            [-1.922078032501892, 50.7737512835077],
            [-1.920022760744886, 50.774769656683787],
            [-1.919231520360317, 50.774695374077723],
            [-1.920035384083834, 50.774017884906137],
            [-1.919604877324294, 50.773651591576211],
            [-1.91869594561658, 50.773578123670411],
            [-1.917623468903043, 50.774575551045217],
            [-1.916990827868859, 50.774641649149814],
            [-1.914986279616033, 50.773407325996729],
            [-1.914231765770966, 50.773430156104979],
            [-1.913152929940578, 50.773981505339663],
            [-1.912963433676043, 50.775221442492423],
            [-1.912482038407514, 50.775551111357437],
            [-1.911363064455625, 50.775539477893538],
            [-1.910797935732498, 50.775143374536803],
            [-1.909281529069403, 50.775306772867722],
            [-1.907629465883343, 50.776657065871696],
            [-1.905994169180683, 50.777376968517515],
            [-1.901944246018427, 50.777700074477629],
            [-1.900857072873789, 50.778727010306916],
            [-1.898612365438888, 50.778482286262786],
            [-1.898082783788638, 50.778732719043063],
            [-1.898628662196201, 50.778817723735145],
            [-1.898400856868073, 50.77922039375764],
            [-1.897691521293528, 50.779292615321182],
            [-1.897503508151279, 50.779654851746848],
            [-1.897137801582035, 50.779548418211569],
            [-1.896222622279229, 50.78034165461802],
            [-1.893901259191028, 50.780101268245431],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000029",
        LAD13CDO: "00HP",
        LAD13NM: "Poole",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-2.001706259758487, 50.731367917040515],
              [-2.002203567156695, 50.730829247591636],
              [-2.003471695866784, 50.730929931568006],
              [-2.003766436135636, 50.731227578597881],
              [-2.003315872988515, 50.731400252259633],
              [-2.002356740862558, 50.733499162740642],
              [-1.99980902043155, 50.734087311512724],
              [-1.999369760351728, 50.733090925329137],
              [-2.000435296751866, 50.731773500275231],
              [-2.001706259758487, 50.731367917040515],
            ],
          ],
          [
            [
              [-1.935619357369499, 50.79210346567087],
              [-1.936337245915076, 50.788954662478332],
              [-1.937447125886169, 50.787514651213051],
              [-1.939031548390049, 50.786544289057503],
              [-1.938066683861294, 50.785641828255905],
              [-1.936980533347143, 50.78529863208685],
              [-1.937509099574594, 50.783575039116791],
              [-1.937132820601881, 50.782796980124559],
              [-1.935060078624214, 50.782033278982581],
              [-1.93259631944068, 50.780000463700013],
              [-1.927006650030466, 50.780807344039665],
              [-1.925518949346589, 50.781573473918378],
              [-1.925064787158931, 50.782630711075214],
              [-1.924242412387285, 50.783305524165179],
              [-1.922411070404451, 50.783356481582295],
              [-1.920933362445527, 50.78399216698358],
              [-1.919513409211946, 50.784011882073635],
              [-1.919146826159976, 50.783541317784326],
              [-1.919707471663233, 50.782509356113941],
              [-1.91861993483818, 50.780627357759982],
              [-1.918901532612157, 50.779366793527061],
              [-1.920923835260131, 50.777845733216786],
              [-1.921603054230231, 50.776235618269354],
              [-1.920746111269312, 50.77557768308904],
              [-1.919352378917167, 50.775342024474156],
              [-1.919231520360317, 50.774695374077723],
              [-1.920022760744886, 50.774769656683787],
              [-1.922078032501892, 50.7737512835077],
              [-1.921831844072472, 50.773404005385473],
              [-1.922904684313506, 50.772979367432768],
              [-1.924361801140794, 50.771687183408311],
              [-1.924791538320744, 50.769884445213904],
              [-1.927068672408155, 50.768242938653984],
              [-1.927819706154571, 50.766731748233653],
              [-1.928122583791095, 50.765247256111081],
              [-1.926840916773247, 50.764241089132703],
              [-1.930475520589888, 50.76100956270291],
              [-1.936285849335967, 50.756697313669349],
              [-1.937616966092824, 50.755687260157025],
              [-1.935073164690655, 50.755054595846126],
              [-1.925511977865712, 50.751025604267234],
              [-1.918531691926826, 50.748033602304623],
              [-1.907623191485727, 50.745907732054889],
              [-1.893472681641149, 50.743264451826349],
              [-1.892408826793203, 50.742419068818855],
              [-1.891147139555105, 50.741952981367817],
              [-1.89638338508189, 50.738925420398843],
              [-1.897053517499668, 50.737060937557274],
              [-1.898303894032566, 50.73616906185287],
              [-1.899667621382992, 50.73393017077511],
              [-1.900835115072753, 50.733307076671238],
              [-1.901610900277519, 50.732292461402558],
              [-1.9053369646184, 50.726188622256949],
              [-1.905714871450424, 50.724956033204272],
              [-1.905809431715126, 50.723718718443408],
              [-1.90632367163828, 50.720163429574043],
              [-1.907574381938057, 50.715892903434707],
              [-1.900908004011262, 50.709436990517624],
              [-1.908481959355579, 50.706596078066262],
              [-1.915466361346149, 50.703503351507692],
              [-1.92055050842289, 50.700712883303588],
              [-1.925458923474844, 50.697641507879638],
              [-1.932041247302078, 50.692841609516186],
              [-1.941231472725663, 50.685536401604658],
              [-1.941006717856183, 50.685284492267542],
              [-1.946932053151669, 50.68279993825842],
              [-1.947816831699475, 50.682687926551232],
              [-1.948746335910357, 50.683096606746801],
              [-1.951396976491432, 50.684970921190427],
              [-1.95180965306007, 50.685612272926967],
              [-1.951017401361867, 50.686548087157746],
              [-1.951284861644949, 50.686626435321436],
              [-1.948620795560069, 50.687907659931611],
              [-1.947020568000425, 50.688441114962515],
              [-1.945912112172812, 50.688464888809527],
              [-1.946002482649847, 50.688665468173269],
              [-1.945378672664845, 50.688249715265499],
              [-1.940756037395992, 50.68877803874814],
              [-1.937504498310059, 50.690798810919723],
              [-1.933590458642744, 50.692570918808428],
              [-1.932175694463454, 50.693861461260717],
              [-1.932225949668189, 50.696330888858419],
              [-1.933215423054503, 50.696478938059698],
              [-1.93358106114784, 50.696232745880742],
              [-1.934049220548231, 50.696581927706262],
              [-1.933609444907333, 50.697192283991399],
              [-1.932790744973417, 50.697417533716944],
              [-1.933735198119054, 50.698386586730138],
              [-1.933505524644499, 50.698595986529035],
              [-1.93557044357643, 50.700303057287421],
              [-1.938323078211022, 50.70086073838506],
              [-1.94082268465387, 50.70112146770127],
              [-1.94432851871886, 50.703678012423353],
              [-1.946613230369858, 50.705670957922877],
              [-1.947042778137855, 50.706525458736699],
              [-1.947834029172284, 50.706872931584812],
              [-1.950919931005741, 50.704085629887331],
              [-1.951534570365412, 50.704030130892008],
              [-1.947830909899387, 50.707132819000073],
              [-1.948146385426275, 50.707436012726895],
              [-1.948580631620417, 50.706616070538047],
              [-1.949662933532419, 50.706285609270694],
              [-1.951008032706613, 50.705158495348599],
              [-1.95168628266571, 50.705228919858342],
              [-1.95240076668546, 50.704435229953333],
              [-1.952357218624774, 50.704346873414231],
              [-1.952127811302402, 50.70412839503291],
              [-1.95166507004584, 50.703815259315242],
              [-1.952211438742423, 50.704051091959947],
              [-1.95242349074012, 50.704409986787013],
              [-1.952467228848276, 50.704570074515431],
              [-1.950720729519317, 50.706354403227479],
              [-1.950925649260275, 50.706761858177181],
              [-1.951161983000474, 50.70688328268335],
              [-1.952174109568525, 50.707355891810934],
              [-1.952860853023578, 50.70775610098331],
              [-1.953255480414446, 50.707958838211347],
              [-1.952997597942686, 50.708092726209664],
              [-1.952298490031581, 50.70746718567235],
              [-1.951814284669998, 50.707218418879123],
              [-1.951457799917091, 50.707082219581544],
              [-1.951841268170045, 50.70739262559691],
              [-1.951293298996543, 50.707299774492071],
              [-1.950448368382579, 50.706792232548551],
              [-1.948959203059786, 50.707505613264935],
              [-1.947969207522407, 50.70886217489231],
              [-1.94846876371492, 50.709205017068655],
              [-1.949246982519797, 50.711189143444685],
              [-1.95087936184488, 50.711783356086549],
              [-1.952964366447779, 50.711601659593754],
              [-1.95370733857168, 50.71076113916066],
              [-1.953824428501927, 50.708334058815886],
              [-1.960333164822315, 50.711946104620864],
              [-1.959266993685618, 50.713217304861026],
              [-1.958847596047235, 50.713394314382597],
              [-1.957434315130381, 50.713110540731776],
              [-1.956412577480192, 50.713698286980424],
              [-1.957602352538699, 50.715258953010682],
              [-1.958374002528716, 50.715601850352549],
              [-1.963101953861722, 50.715743717405125],
              [-1.966569738595892, 50.715261848494237],
              [-1.969105745408946, 50.712159167933414],
              [-1.972691474528354, 50.710432559950171],
              [-1.976510538613692, 50.711732826215908],
              [-1.977572904057539, 50.711465050983996],
              [-1.977993381971055, 50.711824837521299],
              [-1.979595202000149, 50.711917749845391],
              [-1.983939122776415, 50.711758349456005],
              [-1.989971156061388, 50.712106128168912],
              [-1.991885965490276, 50.712519036938396],
              [-1.992965089552617, 50.71341477572782],
              [-1.992945150620771, 50.714174656106159],
              [-1.989536516602146, 50.717832629022041],
              [-1.98911722265688, 50.717873058633671],
              [-1.989592958587506, 50.71884341048861],
              [-1.986375838183319, 50.719453688777236],
              [-1.98434884624337, 50.719168371224214],
              [-1.984085213230978, 50.719636854365277],
              [-1.984559540599778, 50.720315863747544],
              [-1.986821368655077, 50.721940212286917],
              [-1.990534233963116, 50.722516994346329],
              [-1.992260979900083, 50.723416384465317],
              [-1.992691420235114, 50.724935272341938],
              [-1.992336505982778, 50.729603340171472],
              [-1.998916387459869, 50.729918314909298],
              [-2.005068393188746, 50.729641221950651],
              [-1.999249348913096, 50.730144032426324],
              [-1.991959589850548, 50.729796657760296],
              [-1.991987772744514, 50.730732795057705],
              [-1.992863464832024, 50.730370445947891],
              [-1.992544485520686, 50.731553860018906],
              [-1.991603692204128, 50.731327183351539],
              [-1.992140388094884, 50.733262438288577],
              [-1.994537718220483, 50.734887541507355],
              [-1.997118069881385, 50.735541393422587],
              [-1.997038676649131, 50.736223933492575],
              [-1.995967279060124, 50.737498161618042],
              [-1.997161958425679, 50.736264403184009],
              [-1.997299449472213, 50.735562979703353],
              [-2.000428273486021, 50.735923608186006],
              [-2.00247726143838, 50.734829172796417],
              [-2.00353292881926, 50.734717634344065],
              [-2.005869663384638, 50.735344322367837],
              [-2.007666448157146, 50.735283063982621],
              [-2.010337088945562, 50.733250513025268],
              [-2.011833063469252, 50.731917656412023],
              [-2.011819520724472, 50.728845766802579],
              [-2.008385237412131, 50.729520521447164],
              [-2.005531702239923, 50.729610624803811],
              [-2.008572176845469, 50.729066378256469],
              [-2.008488421473898, 50.728198593088251],
              [-2.008926292355509, 50.728581646634453],
              [-2.009256319672332, 50.728121196044363],
              [-2.010470502866499, 50.728009581601349],
              [-2.010732469675947, 50.727398955617183],
              [-2.010179945924528, 50.727510515403779],
              [-2.010056603784987, 50.727141827330975],
              [-2.010668609415968, 50.726949328614829],
              [-2.010365350226771, 50.726645404725119],
              [-2.010640115618235, 50.726267687576225],
              [-2.011406662276232, 50.726594046967222],
              [-2.010223528814961, 50.725997945942261],
              [-2.009262951903601, 50.725893713517443],
              [-2.010419090886115, 50.726220946198957],
              [-2.010118859159318, 50.726765928594055],
              [-2.008815416173924, 50.726700390969427],
              [-2.008767297001758, 50.726965678197324],
              [-2.009753365724304, 50.726924231399892],
              [-2.009264730603111, 50.727677857266961],
              [-2.007302331506673, 50.726860568355782],
              [-2.008429751264808, 50.725061058055886],
              [-2.008269568051424, 50.724544890987481],
              [-2.007325967334651, 50.724080035483439],
              [-2.00757951359036, 50.723831821141424],
              [-2.006013990116412, 50.723172753560732],
              [-2.006438932124266, 50.722772556232925],
              [-2.005851048148361, 50.722975822826129],
              [-2.003101270464936, 50.722349145759765],
              [-2.003557312204975, 50.72092379370357],
              [-2.006071850856765, 50.721523495185515],
              [-2.006190818751235, 50.721322052871024],
              [-2.003948277837696, 50.720758315100767],
              [-2.00397653447007, 50.719925592765037],
              [-2.00344955961045, 50.719769138264354],
              [-2.004177624737384, 50.719269120225917],
              [-2.003581256132509, 50.719235868540821],
              [-2.003261138757618, 50.719526341977243],
              [-2.003033039693691, 50.719043442147196],
              [-2.002225628018272, 50.719339321123094],
              [-2.0014252657206, 50.718989520516779],
              [-2.002627892304777, 50.718764680044494],
              [-2.003398436338328, 50.718139667553039],
              [-2.003167516827693, 50.717755687382315],
              [-2.002018722660533, 50.717502122119065],
              [-2.002724167418451, 50.717994904738831],
              [-2.001107949568886, 50.718588451465259],
              [-1.999612100601638, 50.717824082202746],
              [-1.998657369546033, 50.718190079143199],
              [-1.996161510205047, 50.717697230825692],
              [-1.996211055905942, 50.718120787303086],
              [-1.995723772754594, 50.718158540793823],
              [-1.99584277529757, 50.717971497200892],
              [-1.993795949961314, 50.717756486005705],
              [-1.993576500088953, 50.716934544091558],
              [-1.994148820486338, 50.716394113055458],
              [-1.993661622485772, 50.715898592183201],
              [-1.994996088475353, 50.71415946992709],
              [-1.994571278339939, 50.71318104818679],
              [-1.992434183503566, 50.71191746203278],
              [-1.991408798524858, 50.711725849157332],
              [-1.991459825572111, 50.71150103568187],
              [-1.991226089346015, 50.711748317678492],
              [-1.989608704175671, 50.711508983037483],
              [-1.989947303509118, 50.71102520550523],
              [-1.98934953693511, 50.711428026081883],
              [-1.98934676631752, 50.711147454068488],
              [-1.989131433964163, 50.711389337908592],
              [-1.988117355725744, 50.711394636893523],
              [-1.987123171208072, 50.711155328183715],
              [-1.986429398571341, 50.710395369144223],
              [-1.986510741922912, 50.708207457424798],
              [-1.986786922460566, 50.708138244710284],
              [-1.986645078562068, 50.708938578203785],
              [-1.987986262021253, 50.70890185103169],
              [-1.988994710931006, 50.708505370703186],
              [-1.988189084000917, 50.707677966252028],
              [-1.988595587427819, 50.707445994377672],
              [-1.994969867298339, 50.707349308028739],
              [-1.994632764578892, 50.7078124173308],
              [-1.995394644658004, 50.70820183164841],
              [-1.995796874543708, 50.707896993739496],
              [-1.995513682638278, 50.707353825327068],
              [-1.996589988163427, 50.707302600979901],
              [-1.996395921146446, 50.708001327633042],
              [-1.997850356190997, 50.708226177444942],
              [-1.997234294006852, 50.708403321846163],
              [-1.997272444749173, 50.710067869204266],
              [-1.999811814868355, 50.710231562056734],
              [-1.998845956502304, 50.708099392354193],
              [-1.997319309940648, 50.707569701281656],
              [-1.998149200984257, 50.707520255716858],
              [-1.999139111263204, 50.708146156204812],
              [-2.000252282157882, 50.710791805268464],
              [-2.002128864662026, 50.710743221320932],
              [-2.003918975119863, 50.709856491934701],
              [-2.010567036187676, 50.710401020576661],
              [-2.013153167842527, 50.710424132590141],
              [-2.016858436865456, 50.711197917486217],
              [-2.023432646521149, 50.713475512511472],
              [-2.024917072529681, 50.713603796687174],
              [-2.027453259635176, 50.71508791282794],
              [-2.0298711776508, 50.715226699451719],
              [-2.036281813446764, 50.716919086714285],
              [-2.040232802977281, 50.719073325481837],
              [-2.03861849081761, 50.719730340111226],
              [-2.039330142514581, 50.720369479565541],
              [-2.040256378027492, 50.720129057023478],
              [-2.040348707018032, 50.720420387449508],
              [-2.036402959539701, 50.721505311836772],
              [-2.035049667531442, 50.722823155467296],
              [-2.036497473206034, 50.724615845552115],
              [-2.036772027699199, 50.726049189206513],
              [-2.034455494244765, 50.725851164172376],
              [-2.032501260265886, 50.727103508190645],
              [-2.030474019329696, 50.727370242984378],
              [-2.027910274827788, 50.728447311968225],
              [-2.026552008596076, 50.729265064847532],
              [-2.02687509082663, 50.729336032086565],
              [-2.025798394524363, 50.729533216906447],
              [-2.024779587745342, 50.729368874561992],
              [-2.024554433491308, 50.729605429575756],
              [-2.022195043998385, 50.734859415452455],
              [-2.022527458509307, 50.736575148001151],
              [-2.016621035997211, 50.736344142635694],
              [-2.01624564163055, 50.736704801485189],
              [-2.013534888350955, 50.737063061075986],
              [-2.012743020871238, 50.74831024206685],
              [-2.012711893024775, 50.748510781121674],
              [-2.011190557964784, 50.764151795594891],
              [-2.01026257982007, 50.773797340742782],
              [-2.010074318116411, 50.7754205194517],
              [-2.009326954991907, 50.782598460419308],
              [-2.007966623562291, 50.782429503958603],
              [-2.006081546058575, 50.782777634382015],
              [-2.001175142369345, 50.784834399444776],
              [-2.000472980130749, 50.785356874335889],
              [-1.999139515371164, 50.788137377081661],
              [-1.999572191252921, 50.789613957551246],
              [-2.004873829784045, 50.790211849859872],
              [-2.005881193869814, 50.790935702347042],
              [-1.997173042113394, 50.792848553637761],
              [-1.993997896085235, 50.792370935868369],
              [-1.991574888562675, 50.791079459960493],
              [-1.991102394632571, 50.791414848471732],
              [-1.988245209216494, 50.790894832065533],
              [-1.984838706055001, 50.791465474467827],
              [-1.983631545923966, 50.790951838869319],
              [-1.98143647941692, 50.791771636761425],
              [-1.980921531638443, 50.791638464969694],
              [-1.980574206594722, 50.790991843331732],
              [-1.979862028565992, 50.790954854691712],
              [-1.978370250973065, 50.792516598722116],
              [-1.969673288244743, 50.792472416880777],
              [-1.968799415144584, 50.792348990372659],
              [-1.966143182446965, 50.790824016794176],
              [-1.963860488061881, 50.790816143138237],
              [-1.958334541206882, 50.789302663169082],
              [-1.955199726235769, 50.790346444447181],
              [-1.952316772865067, 50.790513469190103],
              [-1.950364311616755, 50.790829197449597],
              [-1.945422541817034, 50.794856567148642],
              [-1.942412660181094, 50.796461197375592],
              [-1.941737921039452, 50.79708494708148],
              [-1.941560447362898, 50.79831773754325],
              [-1.940518234167107, 50.798883743871109],
              [-1.937998863752521, 50.798361769268887],
              [-1.936128914614098, 50.797162956899363],
              [-1.935210832988685, 50.794110379589888],
              [-1.935619357369499, 50.79210346567087],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000030",
        LAD13CDO: "00HX",
        LAD13NM: "Swindon",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.693660091187499, 51.692708746575939],
            [-1.691447350479992, 51.692171581398824],
            [-1.68856249095598, 51.692188252254994],
            [-1.686944133288431, 51.691274045414971],
            [-1.684159868058728, 51.691187477085229],
            [-1.683072272949797, 51.690106505633707],
            [-1.683853589695251, 51.689672535652257],
            [-1.683924755852352, 51.688800580247865],
            [-1.686520931907727, 51.689289458928926],
            [-1.688740332270266, 51.687559153516219],
            [-1.691117335771251, 51.687302878633353],
            [-1.691186665452122, 51.686679970203294],
            [-1.693025957861899, 51.685713736190785],
            [-1.692795950211144, 51.685287851367001],
            [-1.693294398310534, 51.683875734597883],
            [-1.695046137096621, 51.683215840515601],
            [-1.696531752738675, 51.68211286612749],
            [-1.696861628938983, 51.681014091757298],
            [-1.696369984732669, 51.680130785057365],
            [-1.695209478462189, 51.679781620649116],
            [-1.694899413871918, 51.678571497819924],
            [-1.695576873853061, 51.678049964955107],
            [-1.697781988165749, 51.677463132425665],
            [-1.698756118581203, 51.676468506092434],
            [-1.698493393100896, 51.674215531566354],
            [-1.699035713749385, 51.67312089081318],
            [-1.700170749564511, 51.672267826167541],
            [-1.700202527932982, 51.670738499409431],
            [-1.699035039955396, 51.669510011926079],
            [-1.697747460786561, 51.669607410539513],
            [-1.695850196967137, 51.668760938252198],
            [-1.694112169234647, 51.668757328129985],
            [-1.694221898323709, 51.666417195201554],
            [-1.692343763988129, 51.664909859339467],
            [-1.6915888454301, 51.664937552381701],
            [-1.690984834189617, 51.664460329669616],
            [-1.691439817465332, 51.664314068410192],
            [-1.690636677144723, 51.663780575325511],
            [-1.690993497285363, 51.662334822902579],
            [-1.689585011641778, 51.661110101169804],
            [-1.689168862984772, 51.659597572986556],
            [-1.689854917954523, 51.659267607961404],
            [-1.689604514436629, 51.658464927646492],
            [-1.689103845643469, 51.658323341085712],
            [-1.68923190670933, 51.657353523764357],
            [-1.688508661089839, 51.65678516065153],
            [-1.688942597911212, 51.65569027595572],
            [-1.690557701151084, 51.654778329856256],
            [-1.690842592073806, 51.653909625044975],
            [-1.69146741253234, 51.65406861365566],
            [-1.692710077577033, 51.653388533229254],
            [-1.691630215829312, 51.652655615251895],
            [-1.691951559354631, 51.65216193859446],
            [-1.689098409234111, 51.652563529207185],
            [-1.686247956357495, 51.652357248611814],
            [-1.686544579838806, 51.651893191841644],
            [-1.685860984663236, 51.651879678295998],
            [-1.685617702270212, 51.651526570455481],
            [-1.685864323676557, 51.65076836732797],
            [-1.684918978875448, 51.650985223904364],
            [-1.684463324580297, 51.650202660903119],
            [-1.684105676773425, 51.650502907594792],
            [-1.683223847146986, 51.650108515320177],
            [-1.683357371680582, 51.649198959140797],
            [-1.682964719321454, 51.649337265340762],
            [-1.682630102258936, 51.648822061956558],
            [-1.681827958925525, 51.648613094260632],
            [-1.681444776862052, 51.647399134451327],
            [-1.680780487257016, 51.647321805568936],
            [-1.680895752768761, 51.64696066955517],
            [-1.680012131752037, 51.646629186853218],
            [-1.679763291184025, 51.644817667150235],
            [-1.67807017112128, 51.644315824523005],
            [-1.677303887031107, 51.643140361835975],
            [-1.67488147014861, 51.64217701414794],
            [-1.675717054380437, 51.64172885955206],
            [-1.675650023652543, 51.641401392059187],
            [-1.674262883521586, 51.641178168612406],
            [-1.672409442806134, 51.641494905001238],
            [-1.670605154592015, 51.640807423968596],
            [-1.664604061040905, 51.640372357102137],
            [-1.663183037904305, 51.63985758837336],
            [-1.662765289960906, 51.639485050396289],
            [-1.663233916730708, 51.639231041681789],
            [-1.662341043500873, 51.638626063702773],
            [-1.661533016202058, 51.636913596608665],
            [-1.659999852761201, 51.635181944292363],
            [-1.660134161540579, 51.634610486626386],
            [-1.662838120355523, 51.631215969489155],
            [-1.663719031804312, 51.631074632781363],
            [-1.663494674011559, 51.630740413438978],
            [-1.663897353437206, 51.630404392132967],
            [-1.665184313192509, 51.630053808662069],
            [-1.666900266721738, 51.628923083956487],
            [-1.667443797507854, 51.629076576278884],
            [-1.667558806740704, 51.628560801086515],
            [-1.668493687219835, 51.628339558653821],
            [-1.668523455665882, 51.627822642847043],
            [-1.669132052304196, 51.627569904105741],
            [-1.668671976681375, 51.626471669010144],
            [-1.669276021588898, 51.625649767131648],
            [-1.67081718912143, 51.625080449142651],
            [-1.672687746962269, 51.623094998962323],
            [-1.673749791282816, 51.622847993949129],
            [-1.673124643271834, 51.622601693128559],
            [-1.672943178556771, 51.621721838237328],
            [-1.673355245159878, 51.621465832917373],
            [-1.672133937636856, 51.620765605173276],
            [-1.67231644831416, 51.62029317127827],
            [-1.670690560790781, 51.619420065355392],
            [-1.669724486296234, 51.619388581837143],
            [-1.669705009076821, 51.618887710903813],
            [-1.668457392137672, 51.618644129343139],
            [-1.667087746659414, 51.617905666461319],
            [-1.66702320917481, 51.616262769859617],
            [-1.669405537519508, 51.615174353859658],
            [-1.670033159452903, 51.614065689845376],
            [-1.671830490060751, 51.613566310092132],
            [-1.672467917960792, 51.612890142880993],
            [-1.674968730326682, 51.61174979421709],
            [-1.676455510467329, 51.611848306364777],
            [-1.676611538213764, 51.612450255410558],
            [-1.677420844975372, 51.612777965821877],
            [-1.677411272169292, 51.612498309415379],
            [-1.677828549107014, 51.612511142956571],
            [-1.678222055483274, 51.613435629943851],
            [-1.678716415625592, 51.61337314428215],
            [-1.679236219585428, 51.613798954707669],
            [-1.680881038449252, 51.613407815053876],
            [-1.6813334542088, 51.613560097014677],
            [-1.682673728799278, 51.612517134323141],
            [-1.682337329717315, 51.611882337185783],
            [-1.681772134560335, 51.611751332835659],
            [-1.682510223265389, 51.609454247735265],
            [-1.68381421650698, 51.608831967519748],
            [-1.685269845012313, 51.608620982700522],
            [-1.686549222390078, 51.607999505276553],
            [-1.686957579534768, 51.607413460175522],
            [-1.688460290327467, 51.607065893035603],
            [-1.688290291674228, 51.606586204342833],
            [-1.690630061496262, 51.605448690796351],
            [-1.690932250353959, 51.603899380406176],
            [-1.690362864889081, 51.603758515672247],
            [-1.689811938461253, 51.602603474358993],
            [-1.690377139263103, 51.602507858664957],
            [-1.690886940588231, 51.601851934293968],
            [-1.690176422626315, 51.601449049303604],
            [-1.689681295210906, 51.601645552099129],
            [-1.689554145796948, 51.601235211244116],
            [-1.689270435255586, 51.601552754636849],
            [-1.688588940754997, 51.601553647805773],
            [-1.688771946568372, 51.601183689591657],
            [-1.687312184882335, 51.600140415105017],
            [-1.687508138774485, 51.599567288933237],
            [-1.688203860582692, 51.599803811203699],
            [-1.688411633443034, 51.599400651205947],
            [-1.687843115225057, 51.599353286324309],
            [-1.688458020532438, 51.598528614112297],
            [-1.687979798701445, 51.598368198354372],
            [-1.688105270376103, 51.597965719347911],
            [-1.687525508452176, 51.597455268866966],
            [-1.687832214264406, 51.597361675045875],
            [-1.687504449413889, 51.5956839178736],
            [-1.687929018168056, 51.595453069604694],
            [-1.687593589068952, 51.594684317117554],
            [-1.687057079827368, 51.594819557804506],
            [-1.686860318556273, 51.594251679472293],
            [-1.686332403823945, 51.594187331816364],
            [-1.686511386319822, 51.593561112462986],
            [-1.685445207678568, 51.593679646025464],
            [-1.685681641338036, 51.592469143839558],
            [-1.684978109908769, 51.592754083668822],
            [-1.684853808719919, 51.592359029968279],
            [-1.685454744569474, 51.592297700909015],
            [-1.685058128615994, 51.59182818892544],
            [-1.685517316813774, 51.590969845565944],
            [-1.684931560402942, 51.590926016806435],
            [-1.685442341456341, 51.590538958972715],
            [-1.684769708892554, 51.590325859285109],
            [-1.6857565597835, 51.589558842963704],
            [-1.685699983996797, 51.588971556032156],
            [-1.684965891241556, 51.588673774189758],
            [-1.684969726789436, 51.588327617191808],
            [-1.685741355439821, 51.588414202535716],
            [-1.685178273084522, 51.588017974354393],
            [-1.685671059354063, 51.587932976806087],
            [-1.685386148790306, 51.587595937346812],
            [-1.686984260974645, 51.587344851545232],
            [-1.687379787590002, 51.586707518154455],
            [-1.689031773809489, 51.586169723424078],
            [-1.690442484991766, 51.58438596625934],
            [-1.691123790817938, 51.583522790293244],
            [-1.689149997647833, 51.581954884846247],
            [-1.688322859695648, 51.578813806103391],
            [-1.685431601999393, 51.57598730354492],
            [-1.685226825710334, 51.574713575861708],
            [-1.684635115649963, 51.574934975024568],
            [-1.684716438491501, 51.573816665675778],
            [-1.683532616290234, 51.573279394314575],
            [-1.684063361471395, 51.57289958838696],
            [-1.683159396218325, 51.572999655399613],
            [-1.683319407378285, 51.572813965267763],
            [-1.682614038779393, 51.572782389985662],
            [-1.682536557033771, 51.572512439126136],
            [-1.681697983899635, 51.572753836306376],
            [-1.681452633501662, 51.572141757151748],
            [-1.680397988802011, 51.572537210711189],
            [-1.680417823255224, 51.572177609326324],
            [-1.67990169738281, 51.572115961729196],
            [-1.680322259936621, 51.571813198231006],
            [-1.679306109198158, 51.571453470437682],
            [-1.678185250949814, 51.570189812616974],
            [-1.676552959600937, 51.569438144902804],
            [-1.673254984442301, 51.570605090829112],
            [-1.662436241769598, 51.573562335884127],
            [-1.65832774450671, 51.574197836724835],
            [-1.655057847341297, 51.576505359446173],
            [-1.651383553367524, 51.573904093869835],
            [-1.647704671541925, 51.571950977228489],
            [-1.646885146458449, 51.571200432505641],
            [-1.646111750988539, 51.570086768609791],
            [-1.646573269958575, 51.569180027361476],
            [-1.645991629145976, 51.567717173722961],
            [-1.643799664110312, 51.564074423834114],
            [-1.6386813059973, 51.559319417449451],
            [-1.638925370042341, 51.558919151064742],
            [-1.633867434686029, 51.553419625314419],
            [-1.63408365253357, 51.553255756189813],
            [-1.633049524789459, 51.552537711208835],
            [-1.631993307374281, 51.55313233575427],
            [-1.630790330881402, 51.552074764828738],
            [-1.631199219261263, 51.551806306893617],
            [-1.630018015433614, 51.550916036677734],
            [-1.628984489583811, 51.54978345246721],
            [-1.629237874923941, 51.549660171025103],
            [-1.626901339140751, 51.547565859348666],
            [-1.627161249809413, 51.547349092176113],
            [-1.624816945832319, 51.546050455400795],
            [-1.625397966654471, 51.54553979848783],
            [-1.623016507299698, 51.544217628893229],
            [-1.6236054832871, 51.543616192694969],
            [-1.622274874026203, 51.542353118155518],
            [-1.617799609503271, 51.539183538328899],
            [-1.615357551696333, 51.538102883951829],
            [-1.615455696653388, 51.536721219320647],
            [-1.613763391680725, 51.536509760385201],
            [-1.612836319236547, 51.534130265441348],
            [-1.608286711418919, 51.528873152413354],
            [-1.607905023318057, 51.527492587207277],
            [-1.604811066676563, 51.522720329425098],
            [-1.60450462017921, 51.520491209104563],
            [-1.60339763530307, 51.520335518566299],
            [-1.602825901206881, 51.518294317471891],
            [-1.606943690152099, 51.517088931483045],
            [-1.607158516726483, 51.517413344005618],
            [-1.610904225970325, 51.516893522776549],
            [-1.615268470228347, 51.517356581532596],
            [-1.619952333872344, 51.51665611606645],
            [-1.619511601288414, 51.515240328090954],
            [-1.62358696572134, 51.514199663957669],
            [-1.624310007988517, 51.515121808934943],
            [-1.628478173100123, 51.517364061965225],
            [-1.631898844835586, 51.516373186372149],
            [-1.633570210129628, 51.515518829462749],
            [-1.632737136129842, 51.514994720344248],
            [-1.634305631791772, 51.512940567022376],
            [-1.637240179785242, 51.51358986519201],
            [-1.642133556904854, 51.510884060618054],
            [-1.649891337132771, 51.508299022396208],
            [-1.658165713957757, 51.507610382250476],
            [-1.662261677811867, 51.506884935279139],
            [-1.667752858650946, 51.505278539710837],
            [-1.67528948867439, 51.504484998132867],
            [-1.677779997277872, 51.503952370762839],
            [-1.69616619084736, 51.503174176610365],
            [-1.696650294803206, 51.503605221496301],
            [-1.705122211068218, 51.501978665744076],
            [-1.711764451914555, 51.501182329870169],
            [-1.719093119430727, 51.500705560906162],
            [-1.715748170971941, 51.488383655414147],
            [-1.726310251106689, 51.488506760889429],
            [-1.731086640236205, 51.488177020426271],
            [-1.740073069388016, 51.486780198563629],
            [-1.743698577354627, 51.486687464201623],
            [-1.746318303741956, 51.485647441785517],
            [-1.750785720684589, 51.485643522078185],
            [-1.758169204865695, 51.486235317094568],
            [-1.762693345527342, 51.485857014093341],
            [-1.766525967491183, 51.486075997701171],
            [-1.774728150775413, 51.484539187502975],
            [-1.77425468250938, 51.483887293351216],
            [-1.774340948856765, 51.482455112379526],
            [-1.779697521111488, 51.484348105264353],
            [-1.783614988697348, 51.485192498169873],
            [-1.788765294772666, 51.485121851099152],
            [-1.793201990256241, 51.486167366746137],
            [-1.795594523854086, 51.485757047348514],
            [-1.79780665137583, 51.484446319577984],
            [-1.802570673454559, 51.487766892507274],
            [-1.803799554458155, 51.489362244433046],
            [-1.806495817922642, 51.491052622044656],
            [-1.809486505162259, 51.494034592239814],
            [-1.81305575654523, 51.496535463343783],
            [-1.811404137541253, 51.499525186910091],
            [-1.813591146161182, 51.501250545111432],
            [-1.812927852257406, 51.505271374937671],
            [-1.813459486788629, 51.507041740647779],
            [-1.823031175084711, 51.504756532808585],
            [-1.824560951397517, 51.503712220521777],
            [-1.828359392069838, 51.5050710470097],
            [-1.831867324238667, 51.501247552569005],
            [-1.833967257169118, 51.501794522870746],
            [-1.834373285948277, 51.501064988598031],
            [-1.837593946654958, 51.500837504676419],
            [-1.839829003502578, 51.504777037192369],
            [-1.839233246092527, 51.504982128091086],
            [-1.839576969486836, 51.506803369047709],
            [-1.841352692904999, 51.509533788605388],
            [-1.841003280168662, 51.50973832154493],
            [-1.841161663208108, 51.510602614647006],
            [-1.844020694287699, 51.512239292449379],
            [-1.844152152405055, 51.513835449432442],
            [-1.844697192663931, 51.515014050810208],
            [-1.84151431650166, 51.515703941049459],
            [-1.842640165519968, 51.516861754039837],
            [-1.843312559961758, 51.516622580903011],
            [-1.844273214500704, 51.517218192369455],
            [-1.842892093629738, 51.518200018025354],
            [-1.846914279644378, 51.524281740275512],
            [-1.8421854679202, 51.526264390269169],
            [-1.841861536894403, 51.524899954606589],
            [-1.84055689981688, 51.524121331934424],
            [-1.842734751114889, 51.522884347618437],
            [-1.84383436654775, 51.52165488626126],
            [-1.842143682228199, 51.520413607146914],
            [-1.842592599892042, 51.519795598208226],
            [-1.840330255972137, 51.518441132370242],
            [-1.840847497770979, 51.518069588273796],
            [-1.839373000800694, 51.51729521696921],
            [-1.837794664962243, 51.515743822699662],
            [-1.837160508013499, 51.514143366859749],
            [-1.837584161614136, 51.512931006465934],
            [-1.836983277158897, 51.510902601612599],
            [-1.835533299894734, 51.511005781040183],
            [-1.835451076669052, 51.511430961731001],
            [-1.834725878985642, 51.511537841366419],
            [-1.830804990142073, 51.511142930744811],
            [-1.829864676212613, 51.513683456798717],
            [-1.830088723323676, 51.515438912071239],
            [-1.83108760234739, 51.516947318875566],
            [-1.833052608700991, 51.518725038398415],
            [-1.835387685269231, 51.519436858451428],
            [-1.836669638866917, 51.521662230452691],
            [-1.836363138901258, 51.521926152332469],
            [-1.836916363074661, 51.524013833806272],
            [-1.838799766719635, 51.526192359219344],
            [-1.839964851356586, 51.529373320785382],
            [-1.837310923637396, 51.529791374713881],
            [-1.838173424799816, 51.531700545794564],
            [-1.840894456666467, 51.531499256602409],
            [-1.843936946116819, 51.538476180116007],
            [-1.844586634583515, 51.54076625395853],
            [-1.844601528368444, 51.541474796579678],
            [-1.844045318683474, 51.541787860009386],
            [-1.844316240114145, 51.543105460098104],
            [-1.844385745530107, 51.543443628943933],
            [-1.849061804926529, 51.544410008256406],
            [-1.853833165539085, 51.546300635388292],
            [-1.850533614144183, 51.546764938345824],
            [-1.851606502266545, 51.548994365682006],
            [-1.853798130015943, 51.550490582199842],
            [-1.84928625433748, 51.553308181092561],
            [-1.849625588029625, 51.553626910782839],
            [-1.860156810708637, 51.558981689731901],
            [-1.86263036034973, 51.559561852047644],
            [-1.865137505158887, 51.559533284452698],
            [-1.86220053797771, 51.560026203323801],
            [-1.860573939407655, 51.563625331237041],
            [-1.861035785113741, 51.563794915743102],
            [-1.860994096241624, 51.564457530493762],
            [-1.860113117383497, 51.56524233144556],
            [-1.857594460712269, 51.563308860759768],
            [-1.856092790315881, 51.562821498289694],
            [-1.855619001132323, 51.563472791969907],
            [-1.854787719215901, 51.562669737770911],
            [-1.853563356356718, 51.562994606078789],
            [-1.852209665310419, 51.562711482273585],
            [-1.851794110187978, 51.5622937594161],
            [-1.845199128228688, 51.561717005738274],
            [-1.845689342344646, 51.562647358406466],
            [-1.845407443787811, 51.564100893122955],
            [-1.846595134902414, 51.564841539634223],
            [-1.847037540313427, 51.566280735242181],
            [-1.849854520705257, 51.567350742691779],
            [-1.850087239972761, 51.568096423912564],
            [-1.846804112425414, 51.569634210882249],
            [-1.844359455189069, 51.570211842079011],
            [-1.844040160317744, 51.572026774847181],
            [-1.844711572905002, 51.574010258139801],
            [-1.844125671317268, 51.575718739553807],
            [-1.842978198682142, 51.577058722611042],
            [-1.843155972084737, 51.578657622148953],
            [-1.840941586900698, 51.578848860443365],
            [-1.839873023310379, 51.578215318021385],
            [-1.838690208682127, 51.578477147686101],
            [-1.837585054986282, 51.57881010440542],
            [-1.838487016674509, 51.580027876520255],
            [-1.832633711431821, 51.580789348972623],
            [-1.830847839929458, 51.581748867144618],
            [-1.826346644238424, 51.57943512433846],
            [-1.824904260340992, 51.581874135995058],
            [-1.824139018024419, 51.582337846329288],
            [-1.8237658029425, 51.58218803106687],
            [-1.823772069495995, 51.582807544207093],
            [-1.82317666262713, 51.583012551941906],
            [-1.823065258033923, 51.583455657629216],
            [-1.822614859938139, 51.583853295428646],
            [-1.823635780837578, 51.584462646315295],
            [-1.826493241790622, 51.583768271006221],
            [-1.832184557168788, 51.585195366598022],
            [-1.835572338447022, 51.585503161571808],
            [-1.836934459656907, 51.586414982175619],
            [-1.836782557456185, 51.586916488079787],
            [-1.837572367848371, 51.587240370856719],
            [-1.836971495105182, 51.589784087162094],
            [-1.836392584445939, 51.59021037136926],
            [-1.835169943744778, 51.590216755916643],
            [-1.835366065304629, 51.59106940899936],
            [-1.834439721169283, 51.590964707943556],
            [-1.834071427720077, 51.591417351880615],
            [-1.833139782412718, 51.591182258785132],
            [-1.832623784706259, 51.59175786969405],
            [-1.832403693894213, 51.59233479958835],
            [-1.833041691446346, 51.593135935592464],
            [-1.832812357848389, 51.594264920496421],
            [-1.831863687997977, 51.595109651534472],
            [-1.83216340098138, 51.59526203314708],
            [-1.831814606429073, 51.595500703996599],
            [-1.832470995017336, 51.595627519623761],
            [-1.831867830575365, 51.596727196745256],
            [-1.832697887894659, 51.597529507597102],
            [-1.833627176738703, 51.597654007286998],
            [-1.83359321059602, 51.5978625578812],
            [-1.834073010826445, 51.597728366112626],
            [-1.834675534799601, 51.598785694999222],
            [-1.836858864209535, 51.59867545385427],
            [-1.839866137073502, 51.599917692231692],
            [-1.839250871405287, 51.600388896917451],
            [-1.839947711033217, 51.600534607641954],
            [-1.840963774351833, 51.601468385638455],
            [-1.841025694439459, 51.601929723808787],
            [-1.840348753018997, 51.602279467963022],
            [-1.840858217543362, 51.603168500269653],
            [-1.841700596216074, 51.603412402664979],
            [-1.842088360840256, 51.604014442741594],
            [-1.841750199753984, 51.604929303564745],
            [-1.842201181583152, 51.605209539297903],
            [-1.841272397309202, 51.606133495001174],
            [-1.841351996474439, 51.605251555045157],
            [-1.841004823213135, 51.606673511120931],
            [-1.839860807113126, 51.607191656358779],
            [-1.839847925493053, 51.607570172802454],
            [-1.841620442198742, 51.606951274077765],
            [-1.84104868611007, 51.609831316597038],
            [-1.842389571181032, 51.612115113293363],
            [-1.841673686297622, 51.613241660451685],
            [-1.841110203909338, 51.613311931763441],
            [-1.841097458638282, 51.614068082556713],
            [-1.839731379697859, 51.614433972836565],
            [-1.839914253930043, 51.615822477723775],
            [-1.839021510642391, 51.616690717116313],
            [-1.836978246338119, 51.616973825621457],
            [-1.83667615119393, 51.617441852747],
            [-1.837172372538042, 51.617617870996952],
            [-1.835962338606842, 51.617929985475037],
            [-1.83536247188568, 51.619657272967693],
            [-1.834275920664879, 51.619756449280104],
            [-1.83337630299234, 51.620478976732187],
            [-1.833630711545881, 51.620822804043939],
            [-1.832916149654647, 51.620704005163951],
            [-1.831878511040594, 51.621240205264705],
            [-1.830945136067941, 51.621309898385533],
            [-1.83089224087703, 51.622329434026724],
            [-1.830260380343541, 51.622495761618751],
            [-1.83099196709048, 51.622703614787021],
            [-1.830595328087059, 51.623708269505933],
            [-1.82923462650848, 51.624081238020835],
            [-1.828294738818253, 51.625095884218375],
            [-1.821275474833562, 51.626955612069644],
            [-1.820200432517704, 51.627747003964402],
            [-1.814328106045887, 51.624504614381109],
            [-1.807453147910868, 51.630388239903333],
            [-1.806388584795371, 51.63271702972979],
            [-1.788595679197951, 51.632711561205021],
            [-1.789951369040593, 51.634806259754235],
            [-1.788986957530975, 51.636822173499567],
            [-1.790496204906353, 51.641375350505889],
            [-1.793580643985227, 51.647932732342682],
            [-1.793424770602589, 51.648839675574074],
            [-1.794949661379685, 51.650749387646329],
            [-1.794683819386045, 51.651365723145211],
            [-1.795930399531114, 51.654124607496946],
            [-1.796239016960535, 51.656544684546361],
            [-1.795101924902589, 51.657058805819787],
            [-1.797308131133596, 51.660900086469503],
            [-1.798704734809015, 51.661543559650951],
            [-1.799092296715619, 51.66250178795849],
            [-1.796187930558509, 51.662462624116706],
            [-1.79416310295076, 51.663269207208891],
            [-1.793328656686771, 51.66300070442562],
            [-1.791991001994648, 51.663384069761044],
            [-1.791692919389507, 51.663122796634781],
            [-1.791078789580323, 51.664625040053572],
            [-1.788628637758397, 51.666998840266615],
            [-1.784508367753464, 51.667794278890021],
            [-1.781494691699529, 51.669070866111163],
            [-1.778034282844955, 51.669110241149063],
            [-1.777000494464885, 51.669387912327359],
            [-1.774560880837546, 51.668269248951923],
            [-1.773519225375107, 51.668384133369173],
            [-1.772948959200642, 51.667917288515589],
            [-1.770286736718429, 51.667966964834207],
            [-1.768481157071044, 51.665616714704854],
            [-1.768199826381429, 51.663794537983463],
            [-1.766331780694597, 51.662681323157962],
            [-1.761469417221978, 51.662450376107664],
            [-1.761446214942938, 51.663014978321897],
            [-1.760959236975461, 51.663242366617268],
            [-1.760113161303482, 51.663023055848441],
            [-1.757906141357315, 51.663165089710887],
            [-1.754573780967953, 51.66259622963986],
            [-1.753805263555615, 51.662212495679071],
            [-1.752854165071039, 51.662972057798257],
            [-1.751685415689794, 51.662809551501432],
            [-1.751010784646785, 51.6632379057436],
            [-1.750096929880785, 51.662995901162041],
            [-1.749579113406659, 51.663299602242553],
            [-1.749187662886612, 51.662972386183753],
            [-1.744894593324361, 51.664100541099835],
            [-1.744692909405537, 51.664742976340072],
            [-1.742096623107177, 51.665430534119125],
            [-1.741666575204954, 51.666049984867563],
            [-1.740456297205795, 51.666063503792714],
            [-1.740569810653143, 51.66669493852789],
            [-1.739225286147886, 51.667422951087225],
            [-1.737357925691385, 51.667289321106878],
            [-1.735486106962503, 51.667675344348481],
            [-1.731658622242033, 51.667448184665027],
            [-1.73035414006366, 51.667735616954019],
            [-1.729323524448647, 51.667436538351694],
            [-1.728589911212511, 51.667767522648887],
            [-1.727566657994372, 51.667206800855723],
            [-1.726570631155463, 51.667893216889226],
            [-1.724750109707199, 51.668146118212199],
            [-1.724639647654328, 51.668717701652021],
            [-1.723138615957845, 51.668974922290424],
            [-1.722243240165834, 51.669737064777394],
            [-1.721673806331784, 51.669689862942022],
            [-1.721478316219489, 51.669027645714756],
            [-1.720869250085493, 51.66884278029017],
            [-1.717476817243335, 51.670967396446024],
            [-1.712917463804201, 51.671883347608563],
            [-1.711068540438969, 51.671789805418278],
            [-1.711287536954625, 51.674876130988807],
            [-1.710591322325488, 51.675880536836303],
            [-1.709590913953893, 51.676277280264003],
            [-1.71025818582531, 51.677321907708745],
            [-1.709393927060061, 51.677907800353857],
            [-1.709477989328431, 51.678784651575995],
            [-1.708857347977717, 51.679490724986969],
            [-1.70980662588759, 51.680091886445751],
            [-1.709380630710982, 51.681117630699489],
            [-1.70967371167046, 51.681653331744478],
            [-1.707446759469189, 51.683377720699568],
            [-1.706145044048134, 51.683366382692206],
            [-1.706025095593143, 51.684691386799926],
            [-1.703738687188493, 51.685950710915741],
            [-1.703968379098154, 51.686441310038163],
            [-1.702716142202985, 51.685929248591485],
            [-1.700976280996831, 51.686324046407627],
            [-1.700665769890525, 51.686468913865973],
            [-1.701117953025212, 51.686996948661466],
            [-1.700589031612661, 51.687796719230427],
            [-1.699374626323298, 51.687469937396173],
            [-1.699006085668432, 51.68763802920364],
            [-1.699077497699056, 51.688868208409438],
            [-1.698246025679381, 51.689254497478906],
            [-1.697948384570687, 51.690287721269591],
            [-1.69734989693004, 51.690440831012211],
            [-1.697695734877975, 51.690862508721445],
            [-1.696844479041158, 51.692038164034933],
            [-1.693660091187499, 51.692708746575939],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000031",
        LAD13CDO: "00JA",
        LAD13NM: "Peterborough",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.058039893490924, 52.674072525602803],
            [-0.044941937573333, 52.669171033235735],
            [-0.040325960457982, 52.667123048716384],
            [-0.032119609607987, 52.665341684850034],
            [-0.031287512593998, 52.661513848522304],
            [-0.025064972307583, 52.632350139727883],
            [-0.016267944932248, 52.598368503686395],
            [-0.01285829564264, 52.594326439684558],
            [-0.024163335679351, 52.592298388232408],
            [-0.027878501480116, 52.591957516898439],
            [-0.081292273512301, 52.583649734079415],
            [-0.083407759713612, 52.582798371199956],
            [-0.085209981697828, 52.582799657300413],
            [-0.086206540424324, 52.582862522819205],
            [-0.117335948395837, 52.577783516276916],
            [-0.120619552885946, 52.577375339908016],
            [-0.120727614209783, 52.57757485865239],
            [-0.121368591748175, 52.577115696838142],
            [-0.12440908781064, 52.576661300216813],
            [-0.166030896433774, 52.569554069375918],
            [-0.176983413282094, 52.56978885552693],
            [-0.186995957442942, 52.568487860037855],
            [-0.181712195721831, 52.56648455362496],
            [-0.189894704099881, 52.565424027889925],
            [-0.189204145954376, 52.562917566881239],
            [-0.187651339357569, 52.560429371894038],
            [-0.188531746788162, 52.559864725218219],
            [-0.195071806990153, 52.55443335508992],
            [-0.197889642859706, 52.550787277272057],
            [-0.191047285017136, 52.550320574587381],
            [-0.190635067817443, 52.547648438116823],
            [-0.185644618491764, 52.544918798760754],
            [-0.186647817985266, 52.542691809217096],
            [-0.187866892168435, 52.541844643104213],
            [-0.195717523740047, 52.544841700219244],
            [-0.200126197120126, 52.545115612896858],
            [-0.200660069103064, 52.545151601736599],
            [-0.200930054700416, 52.544283577663251],
            [-0.202772621284282, 52.542535844421131],
            [-0.20523614153394, 52.542878019310493],
            [-0.208533421470425, 52.542302195765402],
            [-0.212374307226708, 52.542282043741203],
            [-0.214160153144533, 52.541773137798067],
            [-0.216491386744922, 52.54032297519985],
            [-0.217680528992117, 52.537905226627977],
            [-0.21968407107181, 52.5398819297138],
            [-0.219480767984459, 52.540278071667935],
            [-0.223048417474543, 52.540994320948244],
            [-0.22276191637537, 52.542063543184796],
            [-0.22313968013334, 52.542422560303798],
            [-0.228665844380554, 52.542848842743958],
            [-0.228691199950827, 52.543647620831656],
            [-0.234852534064944, 52.543648852410456],
            [-0.235575517666274, 52.542426070847547],
            [-0.234990993792589, 52.541116362908575],
            [-0.235011385576268, 52.539688900660423],
            [-0.235532946207599, 52.538817359660555],
            [-0.236673256435589, 52.538216673280374],
            [-0.235724949725507, 52.537301646222772],
            [-0.234747833008621, 52.536922043763255],
            [-0.235100425403256, 52.536549680840537],
            [-0.234379108828463, 52.53629976289043],
            [-0.235196518026382, 52.535991874876409],
            [-0.231743000172155, 52.534458616687452],
            [-0.237292392710676, 52.530416332452646],
            [-0.238283452671626, 52.529081542272642],
            [-0.240491440314882, 52.529276226069832],
            [-0.240484944390593, 52.529660044345988],
            [-0.242687602997942, 52.529656806177613],
            [-0.243091376890615, 52.529624139552581],
            [-0.242463707883689, 52.524703946635668],
            [-0.243223464675285, 52.524430212911888],
            [-0.244798065422881, 52.522980853275548],
            [-0.246732263242777, 52.523751279773222],
            [-0.252932021609369, 52.5222677462327],
            [-0.255899142031346, 52.521077949746513],
            [-0.256813668775638, 52.522587521568425],
            [-0.263223052215158, 52.521904936470484],
            [-0.269312514390095, 52.520806440369753],
            [-0.269349146035947, 52.520361923716671],
            [-0.270337850992813, 52.520444709586599],
            [-0.270504264496892, 52.518609390141769],
            [-0.274065200094796, 52.518946390649312],
            [-0.2743634620168, 52.517089612699635],
            [-0.271026141379323, 52.516766676618687],
            [-0.271134600090293, 52.516369062549764],
            [-0.27173085647902, 52.516422726776298],
            [-0.272526463233593, 52.515715066674822],
            [-0.271980929326499, 52.515307903233257],
            [-0.270846923585601, 52.515588040375697],
            [-0.271787154355728, 52.51404903612611],
            [-0.274557119436566, 52.5116574017811],
            [-0.279628087514158, 52.509275802559152],
            [-0.282368968174696, 52.507157789240907],
            [-0.284655944302312, 52.506094966327062],
            [-0.285322337259908, 52.506766359199162],
            [-0.285778901692392, 52.50654460397898],
            [-0.28782743536644, 52.508732990697538],
            [-0.29330745755163, 52.50687101045046],
            [-0.294134787125216, 52.51160947861657],
            [-0.29631477445482, 52.514302200763417],
            [-0.305123724035763, 52.521450598632988],
            [-0.314444468773311, 52.530090900524726],
            [-0.317612199794354, 52.533458101706579],
            [-0.317839811042324, 52.534330754794347],
            [-0.320699892677059, 52.535766751339601],
            [-0.332751227216097, 52.54652744069277],
            [-0.325940191291514, 52.548631377180008],
            [-0.326752413998155, 52.549612073850433],
            [-0.326691967639839, 52.550774634923641],
            [-0.323692318168097, 52.551500015490234],
            [-0.325473502214877, 52.552871153425407],
            [-0.326858483717879, 52.552329707240631],
            [-0.327258883437766, 52.552893696821712],
            [-0.332377348253675, 52.552383303060999],
            [-0.336010545000013, 52.553584354123096],
            [-0.33733045401749, 52.553629871594907],
            [-0.34064451887395, 52.555632773062115],
            [-0.340646397132316, 52.557146853604351],
            [-0.341168899712788, 52.557999312844366],
            [-0.343962619816901, 52.56078781479502],
            [-0.345222707336301, 52.561213616405517],
            [-0.346080953452134, 52.562753146928038],
            [-0.348492087673828, 52.564352103059385],
            [-0.356851728700665, 52.564981789061584],
            [-0.361177027928033, 52.56432517135184],
            [-0.36293059962143, 52.563312793230892],
            [-0.367398328002434, 52.562746927429664],
            [-0.369655104867718, 52.563078317141134],
            [-0.370819882076615, 52.564133684165931],
            [-0.372755308394835, 52.564659287932855],
            [-0.372915734142503, 52.566025392539458],
            [-0.373397022922785, 52.56637635196028],
            [-0.375757541874906, 52.566262207916125],
            [-0.379139316111406, 52.56718155414373],
            [-0.380039064278253, 52.566949319104786],
            [-0.381305186764951, 52.567666116209551],
            [-0.382650054751009, 52.567206186120636],
            [-0.383912659823192, 52.567458084970802],
            [-0.384022119900865, 52.566888666006882],
            [-0.385465061058005, 52.567171779845658],
            [-0.38859347795007, 52.569012547174097],
            [-0.389579061149459, 52.571310504303071],
            [-0.388939583276113, 52.57277448886213],
            [-0.389331894493882, 52.572901200953822],
            [-0.389252025480365, 52.573429669120479],
            [-0.387701993442852, 52.57445060054372],
            [-0.387864711026382, 52.575043507624301],
            [-0.389397703070038, 52.576219678560108],
            [-0.389898103839245, 52.577465406959533],
            [-0.391388963979678, 52.578506117044384],
            [-0.393035816848862, 52.580852580073959],
            [-0.396853188553614, 52.582980278162509],
            [-0.398696218316002, 52.583277632842083],
            [-0.401355849391808, 52.583038450862432],
            [-0.403883693609729, 52.583216400165185],
            [-0.40889349387067, 52.582640082981086],
            [-0.413695175724758, 52.581078982250041],
            [-0.414717701617477, 52.580269126990629],
            [-0.414780077403666, 52.579358304821156],
            [-0.415394757927695, 52.578724595593933],
            [-0.418189779713942, 52.579715877454603],
            [-0.419995556019196, 52.581336732320956],
            [-0.424645737487575, 52.581475106071856],
            [-0.425040802425812, 52.582394713879488],
            [-0.435408115862931, 52.580487660654391],
            [-0.447706333255686, 52.578666167302337],
            [-0.461018672945698, 52.576006968354513],
            [-0.468921283007803, 52.574908258985978],
            [-0.475399799622763, 52.574352612303564],
            [-0.478827882406485, 52.573627100601122],
            [-0.481467495238468, 52.576286245424441],
            [-0.480748408642736, 52.576937833572089],
            [-0.478847118300218, 52.577420497454995],
            [-0.485344548596375, 52.579962717992061],
            [-0.489435183598776, 52.580536423656547],
            [-0.49173746188984, 52.581271531676691],
            [-0.497663586529066, 52.586832926033978],
            [-0.494552979597861, 52.588654480663195],
            [-0.494161888140698, 52.589685225918345],
            [-0.492671910807894, 52.590129288023192],
            [-0.491440407565127, 52.59013878176934],
            [-0.490718849351505, 52.590516184612],
            [-0.48864091801276, 52.596890993611375],
            [-0.485860440299779, 52.598912529708471],
            [-0.485341297957183, 52.600136704615551],
            [-0.485451588914456, 52.602161897522087],
            [-0.481036117342025, 52.602768861926023],
            [-0.479648797185992, 52.614431636312638],
            [-0.477231850359689, 52.614658621814485],
            [-0.473741167197243, 52.614415007095054],
            [-0.47480275369763, 52.616892100036516],
            [-0.470973130015204, 52.617935088568068],
            [-0.472106830152561, 52.621266341451616],
            [-0.471074401197374, 52.621345624636916],
            [-0.470971434860428, 52.623749275560719],
            [-0.47351516430245, 52.628216228119705],
            [-0.475322624936218, 52.628883226665877],
            [-0.476771998130355, 52.630545344477412],
            [-0.485164562275046, 52.632817870474177],
            [-0.486119083459401, 52.63440341614006],
            [-0.488070744162848, 52.632486378947426],
            [-0.488835275186454, 52.632812599714057],
            [-0.488945398304595, 52.633433452700615],
            [-0.490816645778169, 52.635351609387101],
            [-0.491125138656382, 52.636349891838456],
            [-0.492207261807196, 52.636854550505781],
            [-0.493915157813898, 52.640051727382598],
            [-0.494784665075286, 52.640295630773188],
            [-0.491499173530071, 52.641457681022089],
            [-0.491841647971375, 52.641985289266302],
            [-0.490749717194061, 52.64232021886847],
            [-0.489111741677297, 52.643488791766302],
            [-0.488353786818269, 52.643738052152379],
            [-0.486160307309362, 52.643568898127626],
            [-0.485742518621971, 52.644196493362898],
            [-0.485058993191499, 52.644429601903923],
            [-0.485568570780632, 52.645295610427922],
            [-0.484331319115221, 52.646110516497046],
            [-0.483461328206885, 52.645408912608268],
            [-0.475255972291402, 52.645039292815774],
            [-0.475972479839698, 52.645432406416226],
            [-0.476012293327981, 52.645945379269655],
            [-0.475178636847873, 52.646115364544841],
            [-0.474525356736554, 52.646666167970949],
            [-0.475842751374648, 52.648696101486223],
            [-0.467647551251222, 52.649880553565623],
            [-0.458980959659545, 52.650206007316505],
            [-0.456058655903438, 52.649989955745809],
            [-0.453047771043488, 52.649226948550726],
            [-0.452706053505389, 52.654258997474336],
            [-0.451340077362208, 52.653880633070798],
            [-0.450489430197281, 52.654105965106119],
            [-0.449029527510945, 52.653040366056956],
            [-0.448230622505384, 52.652848297988378],
            [-0.447423418828588, 52.653099350463954],
            [-0.443250259385079, 52.65267598894831],
            [-0.442383331912339, 52.652235747007303],
            [-0.44084331692475, 52.65226584193335],
            [-0.440293495594522, 52.651390117354836],
            [-0.437383452326218, 52.649793712862369],
            [-0.436322893538438, 52.649853445563004],
            [-0.436087478129754, 52.650319647067398],
            [-0.435284380445457, 52.650206552778435],
            [-0.432101589995724, 52.648266570821853],
            [-0.430247694745021, 52.648974759316999],
            [-0.428228325887657, 52.6490603737059],
            [-0.427279629032156, 52.648556900034713],
            [-0.426665916400808, 52.648769921670329],
            [-0.425382453408266, 52.648492146524468],
            [-0.425047423509662, 52.647572451626722],
            [-0.424243110821244, 52.647824284014959],
            [-0.42371323383382, 52.647555610213388],
            [-0.423326509174, 52.648033260217069],
            [-0.420826768606407, 52.646846479210332],
            [-0.419753218240449, 52.647021868920419],
            [-0.417511627178023, 52.646754601980916],
            [-0.416889546460953, 52.646871261915443],
            [-0.417109287212276, 52.647171786490858],
            [-0.416663345210239, 52.64752725093922],
            [-0.413197046876421, 52.648343082833875],
            [-0.410423949680008, 52.647771873109598],
            [-0.407662443554176, 52.648104310507897],
            [-0.405693319130371, 52.648027485736677],
            [-0.40459938130822, 52.648598051391311],
            [-0.403343624737252, 52.648699813723873],
            [-0.401163881729672, 52.649533522605971],
            [-0.399973494428015, 52.649584884834333],
            [-0.399188551372729, 52.650515601402603],
            [-0.398485170363179, 52.650710184310171],
            [-0.398471609466231, 52.651081313654451],
            [-0.395975774329978, 52.652548090567436],
            [-0.39572433228813, 52.653318779213066],
            [-0.393189716112322, 52.654063026214295],
            [-0.391505457676851, 52.653710220849185],
            [-0.39105366855244, 52.654016062247258],
            [-0.389115047370364, 52.653947468975957],
            [-0.387501606268434, 52.654443385403887],
            [-0.38702986228093, 52.654326380545712],
            [-0.387065253277806, 52.654730541478735],
            [-0.386068296734027, 52.654984888460127],
            [-0.384708235701312, 52.654420626157183],
            [-0.384536593482069, 52.655347018563589],
            [-0.383816260818181, 52.655436093546321],
            [-0.383474875319255, 52.655151828480356],
            [-0.383646592349203, 52.655707094611287],
            [-0.382566679341915, 52.656044792726533],
            [-0.380335165459413, 52.655578268410764],
            [-0.378880146043972, 52.656143663093026],
            [-0.377272281904952, 52.655645152894465],
            [-0.376595505964117, 52.656912554113028],
            [-0.375755903793015, 52.657106934941211],
            [-0.375579767242357, 52.65783006511861],
            [-0.374936987966163, 52.657899470979245],
            [-0.374393343772333, 52.657209625238309],
            [-0.373746386870594, 52.65707308163234],
            [-0.373893088056822, 52.657582166837805],
            [-0.373109452399306, 52.657383506923424],
            [-0.371134631475968, 52.658404688041294],
            [-0.370279875809787, 52.657931716322715],
            [-0.370054752728212, 52.658896013324792],
            [-0.367656381857687, 52.659574161618536],
            [-0.367373897584576, 52.660012609964873],
            [-0.366308374921371, 52.660077043591514],
            [-0.365551392773807, 52.659797785425468],
            [-0.363828246469608, 52.660248713475866],
            [-0.362819944357791, 52.660087341429467],
            [-0.360966771517937, 52.659546560960067],
            [-0.359436783258411, 52.660010885492326],
            [-0.356780894463479, 52.659467015597308],
            [-0.356768039469559, 52.659928058043278],
            [-0.356324181901328, 52.659935388602328],
            [-0.356410551115293, 52.660313295016515],
            [-0.355583172089837, 52.660847549991281],
            [-0.355111480170256, 52.660532624291548],
            [-0.35242717676474, 52.661727955069999],
            [-0.350895945437906, 52.661435139163352],
            [-0.350159801634041, 52.661625384808474],
            [-0.350601877219743, 52.661940817182604],
            [-0.349973881751397, 52.66212537433546],
            [-0.349934926712422, 52.662806324058714],
            [-0.34945766450902, 52.66311075482416],
            [-0.346556623285114, 52.663105378766645],
            [-0.344815370784238, 52.664183327695298],
            [-0.344272310147759, 52.665405664704771],
            [-0.34310373055458, 52.665101631508406],
            [-0.343262043703387, 52.665490443350784],
            [-0.342725531515782, 52.665912695787206],
            [-0.341324946759234, 52.665369845025488],
            [-0.339963911849627, 52.666047565211848],
            [-0.339768666630715, 52.667102132703604],
            [-0.338777738435186, 52.667182648929632],
            [-0.337549194652495, 52.668224522716137],
            [-0.33625641137775, 52.668619952162381],
            [-0.337103146693048, 52.670468628921924],
            [-0.336056187588531, 52.671515732272873],
            [-0.337369843583911, 52.67236490526836],
            [-0.33686217602599, 52.672685941671581],
            [-0.336150203283706, 52.67266156370686],
            [-0.336258361477919, 52.674097092654648],
            [-0.335049534402151, 52.674847021156026],
            [-0.334412092954854, 52.674651059190126],
            [-0.333126300710531, 52.6748181907438],
            [-0.331528535768998, 52.674321901270282],
            [-0.331707801982892, 52.673770599652364],
            [-0.330689934189887, 52.673893825116295],
            [-0.330249553714138, 52.67345696873172],
            [-0.327562068334785, 52.67419317316957],
            [-0.326304023935991, 52.673866135278423],
            [-0.325479251675192, 52.674277047077979],
            [-0.324122686999921, 52.674360366917526],
            [-0.322887245686543, 52.674177463066009],
            [-0.322379900022955, 52.673791775337321],
            [-0.320749400663035, 52.673651806137897],
            [-0.319529885787621, 52.674288143805803],
            [-0.320033596099639, 52.674729534512252],
            [-0.320530214103827, 52.67427624989697],
            [-0.321042857563576, 52.674484904254989],
            [-0.32118573173049, 52.675002093977312],
            [-0.320536189995067, 52.67508459665099],
            [-0.309984690633096, 52.673459238782726],
            [-0.29732179032057, 52.670581682610624],
            [-0.294566518860904, 52.669326521446841],
            [-0.293408621798706, 52.669437521747724],
            [-0.291676532002625, 52.670353895746068],
            [-0.289515384317805, 52.670325421666696],
            [-0.28843256456743, 52.669763152698515],
            [-0.286862430968345, 52.668010650453951],
            [-0.282274583935674, 52.667151259714039],
            [-0.279096476368886, 52.665984906503439],
            [-0.278889067057175, 52.664601815338322],
            [-0.277800835197881, 52.663281448405435],
            [-0.276220452771855, 52.662632714688527],
            [-0.276036677264075, 52.661854139504641],
            [-0.274892373828665, 52.660684872382774],
            [-0.273087899612708, 52.65946012201676],
            [-0.270996812474848, 52.659618436405594],
            [-0.270349505924677, 52.659422881327131],
            [-0.269749798823048, 52.657572817917355],
            [-0.270010220179784, 52.657268236865718],
            [-0.268973842643572, 52.656330649301381],
            [-0.267495740497606, 52.655834335188224],
            [-0.266210666924934, 52.654682702208049],
            [-0.263807545827068, 52.65387790567695],
            [-0.263590610491387, 52.652487449647182],
            [-0.261250988067948, 52.651465054373823],
            [-0.260257677615452, 52.651424401122995],
            [-0.258946264079108, 52.651911320548862],
            [-0.258678192332797, 52.651625969713756],
            [-0.256115895506568, 52.653380151739086],
            [-0.249111938467442, 52.656395773808015],
            [-0.238863338199308, 52.660504640847833],
            [-0.238362872766151, 52.660190615665833],
            [-0.230088602219512, 52.66362588357805],
            [-0.224512446442964, 52.665444027141952],
            [-0.218184520678307, 52.66704472005749],
            [-0.212515417104075, 52.666659952346187],
            [-0.209667746375492, 52.666970305211471],
            [-0.206051879221739, 52.668053863198857],
            [-0.205533357664524, 52.666812470401403],
            [-0.203088658056498, 52.665085148990173],
            [-0.200605422927421, 52.661420561811674],
            [-0.19983209209487, 52.660844191091044],
            [-0.196492237764749, 52.655480708853851],
            [-0.195580735561715, 52.655355345267374],
            [-0.192882347888933, 52.652419163487579],
            [-0.190432820963095, 52.652451009456023],
            [-0.180034863434977, 52.660541789143586],
            [-0.141077508027959, 52.651488266614486],
            [-0.140149916780534, 52.653454414248152],
            [-0.140268309338774, 52.654252869708571],
            [-0.139517346765813, 52.654842574460865],
            [-0.136856164617485, 52.655188269136538],
            [-0.137032743371621, 52.655521013079067],
            [-0.133039501041433, 52.656938970436883],
            [-0.129835376269233, 52.659192822390153],
            [-0.126550367509743, 52.659976189417456],
            [-0.117430593532816, 52.664745209259863],
            [-0.115526149912813, 52.666457385897068],
            [-0.113011054920142, 52.667956614706945],
            [-0.102211964898918, 52.672187039531245],
            [-0.099450852725008, 52.670373367646704],
            [-0.096698077560785, 52.670040577153323],
            [-0.094338054537994, 52.668160397423122],
            [-0.091483585436748, 52.66694113592478],
            [-0.09044702260257, 52.666666395621945],
            [-0.087853341168325, 52.666761234885058],
            [-0.080330758388574, 52.67052731845515],
            [-0.074627026095219, 52.672054964437251],
            [-0.07202393022759, 52.672053102613248],
            [-0.06968826300779, 52.671386608748271],
            [-0.066989341416796, 52.672015143402859],
            [-0.065461671239875, 52.671976728268284],
            [-0.06380138236644, 52.675205260381119],
            [-0.059505989188227, 52.674667471112379],
            [-0.058039893490924, 52.674072525602803],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000032",
        LAD13CDO: "00KA",
        LAD13NM: "Luton",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.426066082266221, 51.926680171970602],
            [-0.421709248547253, 51.925672321581644],
            [-0.4223919399891, 51.920511289063938],
            [-0.421203107939689, 51.917507430233861],
            [-0.420950970871576, 51.915244449984591],
            [-0.41909164703546, 51.912295409529868],
            [-0.418365457804779, 51.91277030284116],
            [-0.414784775001296, 51.91152264552408],
            [-0.407585767798559, 51.910683653493351],
            [-0.402462571709291, 51.909695277926183],
            [-0.401245973201194, 51.913045236778494],
            [-0.393759494346374, 51.915454678813191],
            [-0.393033634370232, 51.914404435070864],
            [-0.387244261859517, 51.915936557306743],
            [-0.386637621618573, 51.915620728279833],
            [-0.385641211007062, 51.915672705129197],
            [-0.38577124942609, 51.914560420236015],
            [-0.384946675745548, 51.911201506728901],
            [-0.378663884090363, 51.90403236894474],
            [-0.378414314211343, 51.901638037676982],
            [-0.377623953112518, 51.900497786855816],
            [-0.377514012234199, 51.898425481555606],
            [-0.373933377810074, 51.895306290317528],
            [-0.37196072865385, 51.894715235442533],
            [-0.365158530837886, 51.894370063560025],
            [-0.359114265147614, 51.889099505084609],
            [-0.357717483939882, 51.886436447002815],
            [-0.355156426937155, 51.885103165793325],
            [-0.354669889268765, 51.884539774302525],
            [-0.352002853978351, 51.879593851186399],
            [-0.353018977263357, 51.878902225699584],
            [-0.349923102105808, 51.878700594953933],
            [-0.350816784553188, 51.876114504634764],
            [-0.354150289921218, 51.875532657898049],
            [-0.354639064027897, 51.875156444947194],
            [-0.354858254376357, 51.874006765811593],
            [-0.358033093904593, 51.873188805428711],
            [-0.362895565183796, 51.874016367427629],
            [-0.368322792565362, 51.873052311376448],
            [-0.365808771790999, 51.870754194543622],
            [-0.369779939612845, 51.868107263830872],
            [-0.374084816863279, 51.869404100084353],
            [-0.375204825591145, 51.8694816159067],
            [-0.374607722954381, 51.870048840355203],
            [-0.375378688824628, 51.870420957075311],
            [-0.376049408763901, 51.869866434194741],
            [-0.37746871900962, 51.870830149487013],
            [-0.378684220369979, 51.870151843908083],
            [-0.380350824823677, 51.870177496094833],
            [-0.387017572939052, 51.871205122655979],
            [-0.388547006725854, 51.871859098311582],
            [-0.389321908037607, 51.87155589760971],
            [-0.391492440751451, 51.870120838705937],
            [-0.393209145693795, 51.869718987204557],
            [-0.395884833716126, 51.870572846382245],
            [-0.397621145008889, 51.869659568739969],
            [-0.400918114221638, 51.868643401879943],
            [-0.403530675318259, 51.866441740749977],
            [-0.405317930656207, 51.864366411773346],
            [-0.409146259929991, 51.861486938819567],
            [-0.41084396768421, 51.861775139363495],
            [-0.412567377884272, 51.862860328541693],
            [-0.416150395747067, 51.861986968821],
            [-0.4149502224357, 51.860764116324582],
            [-0.414442003939723, 51.859027260568446],
            [-0.416042845830785, 51.859301492503796],
            [-0.421094168839107, 51.854887022332463],
            [-0.42209458074862, 51.854473338267653],
            [-0.423190191305306, 51.854658871287306],
            [-0.425690414290147, 51.857119221160097],
            [-0.432724899327163, 51.862414847634376],
            [-0.4416742433099, 51.872113467436357],
            [-0.449427624472282, 51.878339224780973],
            [-0.455299952980782, 51.882278376879931],
            [-0.466646362066265, 51.888156247036946],
            [-0.479055740233265, 51.888507012529089],
            [-0.483965827267631, 51.888291631361199],
            [-0.484868852764398, 51.891297467314502],
            [-0.499833269865478, 51.897822855199699],
            [-0.505948566215707, 51.900604414267974],
            [-0.497684139457688, 51.903996034546381],
            [-0.494072424098452, 51.906066494600218],
            [-0.491546200064473, 51.906318278219011],
            [-0.490773047554005, 51.905227578624611],
            [-0.482302073837736, 51.907786452718135],
            [-0.482098754251703, 51.908987803997427],
            [-0.481588202854174, 51.912875472706141],
            [-0.47852280230159, 51.917322685764631],
            [-0.486598064949949, 51.922139268074105],
            [-0.485727274655109, 51.922692739341443],
            [-0.482304276861618, 51.922340244974158],
            [-0.475777569242998, 51.923676581441356],
            [-0.470467542617716, 51.924162484318799],
            [-0.465855410806356, 51.92409527204925],
            [-0.462414437183775, 51.923589107668583],
            [-0.458717231048567, 51.923942679808277],
            [-0.456487886125573, 51.924577047738474],
            [-0.451883540323052, 51.924747667170109],
            [-0.450326845876924, 51.924722688057329],
            [-0.446530803413, 51.925574509707459],
            [-0.441388093889256, 51.926168310765291],
            [-0.438509834659947, 51.926045644446923],
            [-0.438742270754512, 51.927017123132892],
            [-0.437843131330268, 51.927738909134661],
            [-0.431582162715596, 51.926939923576285],
            [-0.426066082266221, 51.926680171970602],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000033",
        LAD13CDO: "00KF",
        LAD13NM: "Southend-on-Sea",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [0.645210616447699, 51.538627194624446],
              [0.644639186840108, 51.538223681075543],
              [0.645698355866339, 51.538607197893093],
              [0.645458310266949, 51.538223191964107],
              [0.646134295959433, 51.538315862864266],
              [0.645745479063817, 51.537853373007742],
              [0.646367577202268, 51.537912183407578],
              [0.646505026192887, 51.537646469195899],
              [0.643696530601095, 51.536789777634922],
              [0.642400938815548, 51.535834183941859],
              [0.640236062728241, 51.535754339179341],
              [0.640668539074689, 51.534606015753958],
              [0.639932798503538, 51.534177400451988],
              [0.64012930085024, 51.533461585413036],
              [0.639786256839767, 51.533414447796211],
              [0.637125452287543, 51.532561471315525],
              [0.63679608575487, 51.531827808679694],
              [0.635110712611647, 51.531458286987551],
              [0.634642719379216, 51.531744005919585],
              [0.634083966602808, 51.531656730401167],
              [0.633678762743118, 51.532155980910353],
              [0.633432967476731, 51.531395250239058],
              [0.63215613541852, 51.531557922555329],
              [0.632237072242646, 51.531286299071716],
              [0.631612903958838, 51.531189688008887],
              [0.630808667118376, 51.53194610511661],
              [0.630352873989255, 51.531443697812442],
              [0.629566110147464, 51.531428067605788],
              [0.628116296852849, 51.53174206894505],
              [0.627085177997202, 51.532365943416075],
              [0.626709241471543, 51.532172909806143],
              [0.627859273618221, 51.533913475139187],
              [0.627398745394051, 51.534580323400441],
              [0.624218580838645, 51.536502404987694],
              [0.627700374492765, 51.537863402236788],
              [0.629865777966304, 51.538276228550444],
              [0.633143567328601, 51.53814691350702],
              [0.633922513205844, 51.537500770972443],
              [0.636033236032208, 51.537266276589435],
              [0.638971610343674, 51.537603098532813],
              [0.639565771858842, 51.537079790982631],
              [0.639295879364868, 51.537599398049309],
              [0.640042839135329, 51.537872176396711],
              [0.642886650378807, 51.538691285879594],
              [0.643067260017659, 51.538995694468923],
              [0.645162717747245, 51.539318081663723],
              [0.645792478456469, 51.539210341423363],
              [0.64558644941004, 51.538990150544194],
              [0.646192382079404, 51.538670697452218],
              [0.645210616447699, 51.538627194624446],
            ],
          ],
          [
            [
              [0.646552689217093, 51.574293204145214],
              [0.651280535542947, 51.573492136928365],
              [0.660090364805696, 51.573096619464415],
              [0.662921247400589, 51.572440610355414],
              [0.667688464558569, 51.572689330653851],
              [0.667804296319439, 51.572202842508908],
              [0.67530468825114, 51.572863018970011],
              [0.675390074983135, 51.570139614913401],
              [0.680734481016015, 51.56950675511959],
              [0.683759302746042, 51.56859938068505],
              [0.685006208133069, 51.568893713703851],
              [0.687137709984068, 51.568208153862557],
              [0.704148345509097, 51.566756856051292],
              [0.704989244098467, 51.567114298916273],
              [0.705630691214507, 51.567490726770252],
              [0.70720489346524, 51.566661163789824],
              [0.708557768332608, 51.56643027067372],
              [0.707910633264091, 51.564283142404463],
              [0.715782092447761, 51.563166700136705],
              [0.715776208188572, 51.562801693977626],
              [0.719026604863648, 51.56267062348595],
              [0.718362114001972, 51.560941256417806],
              [0.722810328351024, 51.56040547577426],
              [0.730441394059247, 51.560495176103153],
              [0.730507943452413, 51.559438667594719],
              [0.735334104212464, 51.560132040329961],
              [0.737348713137506, 51.560017583409127],
              [0.737543093767085, 51.559482418265638],
              [0.738996515371913, 51.55934595521525],
              [0.741047585102325, 51.559238677095948],
              [0.741717488244185, 51.559893049097205],
              [0.743760744280657, 51.559871346520794],
              [0.749042369354815, 51.559356436604247],
              [0.752062554156801, 51.55835745147975],
              [0.75155262646114, 51.557988982032562],
              [0.75335221096824, 51.55751774563057],
              [0.753123997703856, 51.554862758792119],
              [0.757657008453017, 51.554123094838332],
              [0.760998327114221, 51.553289901320426],
              [0.761129896375964, 51.55296123008349],
              [0.769524804218236, 51.552391830480282],
              [0.769830931706337, 51.551779320298493],
              [0.775239091451501, 51.55196444146317],
              [0.786659487581453, 51.552939254832062],
              [0.786768331090958, 51.547263384174542],
              [0.786773999399334, 51.546931378401098],
              [0.787440228132632, 51.546928134900185],
              [0.78762637378263, 51.545160026044165],
              [0.788960102711658, 51.542440064334642],
              [0.794345152071798, 51.542335243378439],
              [0.798396473339921, 51.541673815927489],
              [0.807928929575722, 51.540950372050922],
              [0.810837824291202, 51.540127892070707],
              [0.814838807015464, 51.54372748582027],
              [0.819138013029516, 51.542202274020084],
              [0.821226228044985, 51.540659068520654],
              [0.817374939405237, 51.53939269735136],
              [0.81587146741489, 51.538121106243587],
              [0.813890941549365, 51.537250379887666],
              [0.813961541788763, 51.536735136442744],
              [0.81269574832894, 51.536484899465485],
              [0.809512098976678, 51.535090732818091],
              [0.808670986578388, 51.534358998430555],
              [0.807771235479676, 51.534313993603945],
              [0.806599632222277, 51.533671103045045],
              [0.805550612985624, 51.532723074663679],
              [0.804336063519466, 51.53231862706307],
              [0.79722261923875, 51.52820478633943],
              [0.794893774573564, 51.526389607232161],
              [0.787610098457287, 51.522031922856875],
              [0.787740327926776, 51.521827364588894],
              [0.782690725581381, 51.521246707308208],
              [0.776525223514945, 51.522723967307108],
              [0.768238104949764, 51.525477764771985],
              [0.759513267676652, 51.52785273805145],
              [0.750546954414343, 51.529561783661414],
              [0.744194255570045, 51.53011985380347],
              [0.735612324311758, 51.529671047831506],
              [0.731540718700468, 51.530158998443781],
              [0.728976827296731, 51.530542473641916],
              [0.72316098806835, 51.532201223261985],
              [0.720487511019861, 51.53256367232062],
              [0.719463889958578, 51.532663863860094],
              [0.718154547494145, 51.531986419928849],
              [0.714021384025592, 51.532294411836801],
              [0.712878820322992, 51.532989083383917],
              [0.711386625298603, 51.533139616507412],
              [0.702700775440696, 51.5331548734259],
              [0.691919144280152, 51.535042430215306],
              [0.687903796550078, 51.5353503892362],
              [0.680471000725473, 51.535811926406154],
              [0.67069312808042, 51.538144033221606],
              [0.663774877119682, 51.5387339850666],
              [0.657980775056141, 51.538719764101913],
              [0.652921492094465, 51.53933797131463],
              [0.652255971467443, 51.539128186142271],
              [0.652206538016441, 51.539418897406684],
              [0.651812841779612, 51.53922094870542],
              [0.651231005399214, 51.539408580117573],
              [0.651372897412758, 51.539666185752111],
              [0.64988284164083, 51.539835669188072],
              [0.64862247908697, 51.540409153359157],
              [0.643996820728421, 51.541073837116805],
              [0.643031143759829, 51.541084813599653],
              [0.64287811801863, 51.54080856271073],
              [0.6422103636064, 51.540609563330079],
              [0.641840567009857, 51.540798665336261],
              [0.640175242411919, 51.540159180335728],
              [0.63666004403521, 51.539202886533502],
              [0.633715235951278, 51.539255571094728],
              [0.633642974042443, 51.538853383711718],
              [0.634892910688942, 51.538621151627275],
              [0.63454218127473, 51.538291773621118],
              [0.633356366877746, 51.538785173708057],
              [0.633433876815774, 51.539303260156693],
              [0.631931038310393, 51.538977257536253],
              [0.631935669760687, 51.538632700798736],
              [0.631391050637319, 51.538941709960881],
              [0.63154119045167, 51.538669433837072],
              [0.629211445118476, 51.53863355500399],
              [0.627175235760749, 51.53803525336734],
              [0.626728670281458, 51.53829437814165],
              [0.625400423226866, 51.538068706456293],
              [0.624306640993866, 51.538559054353705],
              [0.623968855896367, 51.539029780049169],
              [0.624711435901684, 51.540405360605931],
              [0.624236883574043, 51.540604845233297],
              [0.622932213072692, 51.540464056105307],
              [0.622950740047863, 51.540911519432498],
              [0.623729059546943, 51.541253849423292],
              [0.623153433185455, 51.541351265936875],
              [0.623184699693828, 51.542120412506996],
              [0.623884691218447, 51.542078671300388],
              [0.624757317450588, 51.543783202783175],
              [0.624150058027396, 51.547717069979704],
              [0.625131995724824, 51.549263564460318],
              [0.627163180479696, 51.551193058630872],
              [0.625502073837713, 51.553660305891974],
              [0.626987116156206, 51.555396976190565],
              [0.628454425216656, 51.555398276556303],
              [0.629336365708315, 51.556381285383402],
              [0.631656867247327, 51.557640495663456],
              [0.633108377229366, 51.559090050125441],
              [0.635547237749706, 51.560645112700833],
              [0.634648588734624, 51.561797587117717],
              [0.634363861206125, 51.565258393328016],
              [0.633086476258216, 51.56547773876423],
              [0.635112545495657, 51.567948624124924],
              [0.634091330360648, 51.569479774080804],
              [0.631561278653829, 51.569925982342042],
              [0.632049034227376, 51.570524798291046],
              [0.632192535081452, 51.570710441818001],
              [0.634617612577753, 51.574936007835184],
              [0.635651197255035, 51.574464912032916],
              [0.637774202421052, 51.576257262909763],
              [0.638922602989595, 51.576796225749185],
              [0.643926793679535, 51.57507999669717],
              [0.646552689217093, 51.574293204145214],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000034",
        LAD13CDO: "00KG",
        LAD13NM: "Thurrock",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [0.540138533914034, 51.517650059162357],
              [0.539527572631761, 51.517239703077976],
              [0.538657677030389, 51.517279225526202],
              [0.536787428068966, 51.516463530234773],
              [0.535644136636188, 51.516303895169109],
              [0.533486252801014, 51.516381118636794],
              [0.532353267672593, 51.516069240166338],
              [0.5319148843775, 51.51624778356107],
              [0.53418072298403, 51.517647672757626],
              [0.535829415118383, 51.51818580805562],
              [0.537378640600562, 51.518541707838232],
              [0.539662057663802, 51.518542635317402],
              [0.540138533914034, 51.517650059162357],
            ],
          ],
          [
            [
              [0.509209567060692, 51.535548198829431],
              [0.511204774284958, 51.533939747628814],
              [0.511531852942588, 51.534013683385766],
              [0.513191588235838, 51.531867383254429],
              [0.512189896154138, 51.531587568766668],
              [0.512617371697851, 51.530971358198315],
              [0.511619528879294, 51.530682463141993],
              [0.509105179606325, 51.530766865867044],
              [0.507949405884765, 51.531224170179684],
              [0.507428881597495, 51.532037508601277],
              [0.507458340528055, 51.533098094587864],
              [0.509209567060692, 51.535548198829431],
            ],
          ],
          [
            [
              [0.391779672233333, 51.567209862266552],
              [0.392095794101932, 51.562566554228738],
              [0.393502794458233, 51.557652776715528],
              [0.398595313164322, 51.557784374370186],
              [0.39915703551421, 51.558232437103058],
              [0.402279154448906, 51.558506681469758],
              [0.408488578672015, 51.557520501931087],
              [0.410884696694531, 51.557543223706524],
              [0.411340524672695, 51.557296434180316],
              [0.414550497133523, 51.557485808414484],
              [0.417366627195942, 51.557131030505843],
              [0.418649196261472, 51.556614462623962],
              [0.421052149109021, 51.552964014475798],
              [0.417980523516676, 51.551080348753779],
              [0.418217476222569, 51.550815560392046],
              [0.41884865680075, 51.550558829987253],
              [0.423808620089742, 51.551981685595088],
              [0.424419589596237, 51.552809923063045],
              [0.424883331436315, 51.552687022407177],
              [0.426589882616501, 51.551873817025765],
              [0.424908118134039, 51.550061393426596],
              [0.4247959432063, 51.548087904760841],
              [0.426418523466404, 51.548437459651808],
              [0.427660291106609, 51.545129240584941],
              [0.430369410798742, 51.546003033236971],
              [0.429904771062404, 51.548814047665019],
              [0.430643104855447, 51.548864399650746],
              [0.43097005589185, 51.549720972456726],
              [0.435319845380541, 51.549518322258379],
              [0.435779688747642, 51.549188614735868],
              [0.436525107694538, 51.546537217234196],
              [0.437303608586972, 51.5465300352555],
              [0.437502917202455, 51.546157170866266],
              [0.439032971166117, 51.546421239930531],
              [0.43990620718963, 51.545111648793082],
              [0.443770664828232, 51.546411654511289],
              [0.442901894876979, 51.547372244250639],
              [0.444190211936652, 51.547699737258164],
              [0.443440096031678, 51.548963628019266],
              [0.445796479398093, 51.549302123160025],
              [0.445983468152591, 51.551174213298864],
              [0.446910065366991, 51.550887787175377],
              [0.450075585521819, 51.551167945280433],
              [0.452323716940127, 51.551931254099053],
              [0.45769640277537, 51.552772083558871],
              [0.459127328116399, 51.552261851751005],
              [0.461587838846307, 51.553695025754671],
              [0.465226508929894, 51.549861218769877],
              [0.466919788924686, 51.547351567071786],
              [0.466734341503623, 51.546723240355107],
              [0.469811719102118, 51.547109939304484],
              [0.474306676921795, 51.546400056792265],
              [0.475037948900865, 51.54709060016225],
              [0.477563145831097, 51.5471883715703],
              [0.480280797987418, 51.547907052792787],
              [0.480770195565365, 51.548418313349678],
              [0.481638565414633, 51.54860409062276],
              [0.484812521621608, 51.54787318475109],
              [0.485698825516118, 51.547117852607975],
              [0.485463750946808, 51.545848483391232],
              [0.487431594076359, 51.545486602579111],
              [0.487912788652333, 51.545000649965637],
              [0.487026747156961, 51.543220780492213],
              [0.487834511813279, 51.543037272149995],
              [0.489961097782379, 51.544000292659113],
              [0.490936460742431, 51.543980480271451],
              [0.49166803723727, 51.543088990945343],
              [0.490137576439362, 51.541447840176161],
              [0.490682168483892, 51.540618783127492],
              [0.494069928840713, 51.539274231179185],
              [0.497743340091835, 51.53848827724881],
              [0.4962457873424, 51.53855613606386],
              [0.496213943504786, 51.538211469985924],
              [0.49747756835716, 51.53771691345851],
              [0.498265659062906, 51.538229834996741],
              [0.501070527167848, 51.537912840839276],
              [0.503881224716528, 51.538832239317856],
              [0.506190768152754, 51.538240607939727],
              [0.506872231656754, 51.537362681516107],
              [0.505361560741023, 51.537322118189195],
              [0.503867949362006, 51.53672538252723],
              [0.501943161995424, 51.534313977790774],
              [0.501589405274297, 51.533174873154849],
              [0.502281745436432, 51.530733696975027],
              [0.502720478209587, 51.530688360967197],
              [0.502799212222802, 51.530993353829572],
              [0.502427387836065, 51.533888153416576],
              [0.503683369677574, 51.535884848256202],
              [0.50571651618328, 51.536984478785129],
              [0.507443706036382, 51.536635492851779],
              [0.507723671361099, 51.535406410760238],
              [0.505991448446853, 51.533248162773845],
              [0.506839736927749, 51.531261386948152],
              [0.508483365071224, 51.530262151390311],
              [0.515491490328526, 51.528389798157107],
              [0.517652636554015, 51.527106829156608],
              [0.519007060539731, 51.524897746071694],
              [0.518423425082081, 51.521297640064731],
              [0.518855857925186, 51.519181200767925],
              [0.519627928901715, 51.517969391033397],
              [0.522165146685422, 51.515964174355631],
              [0.526587571039676, 51.51482033046144],
              [0.529145394673011, 51.514496281969699],
              [0.5292950822584, 51.514232244098494],
              [0.529742155538555, 51.514444736336401],
              [0.532043741119985, 51.514205294488328],
              [0.539054972168141, 51.51444488876156],
              [0.540916873086682, 51.513739040353535],
              [0.541243192747685, 51.512558325308156],
              [0.536693820360181, 51.510551479647603],
              [0.534937426486634, 51.509989605768453],
              [0.533782476302562, 51.509980395983732],
              [0.533406769273375, 51.50894977426676],
              [0.532901811750961, 51.508758332202554],
              [0.532071814649333, 51.508968719443125],
              [0.53128601025866, 51.508494646592297],
              [0.522662612295404, 51.507881892111868],
              [0.520042854993028, 51.509923973903],
              [0.518691138838414, 51.510423374148573],
              [0.519107377114346, 51.509686865649975],
              [0.518356273488277, 51.508872910270114],
              [0.515678773587484, 51.508119186145429],
              [0.508136408694734, 51.505195145258263],
              [0.501555604403005, 51.505969774740436],
              [0.501767959006082, 51.507004878251543],
              [0.497606482622226, 51.505987417076589],
              [0.494213153907421, 51.507130764738264],
              [0.493290262201467, 51.50606489497919],
              [0.483924266002899, 51.505849101713821],
              [0.47685508516156, 51.504855600750915],
              [0.475851338513995, 51.505187071452539],
              [0.467255293346188, 51.503746781106763],
              [0.465944487723867, 51.505331102878124],
              [0.464027754319964, 51.505703254144912],
              [0.462282363800213, 51.505565451686486],
              [0.461890758788481, 51.505812897411609],
              [0.462446538659375, 51.505990985069097],
              [0.461139415461419, 51.506444713642189],
              [0.460865396946822, 51.506975674917918],
              [0.455566375237973, 51.506842156428533],
              [0.459452248596031, 51.506505298255682],
              [0.45755725109918, 51.505248198108866],
              [0.453236502416141, 51.504650696395338],
              [0.452951063797879, 51.504192613913105],
              [0.453411501884342, 51.50361640151516],
              [0.44759455390352, 51.500156847249173],
              [0.446155279313559, 51.500079865120696],
              [0.444036425778161, 51.501439773647817],
              [0.441721010565347, 51.501012272395016],
              [0.440172103895772, 51.50168843962885],
              [0.437951775964833, 51.500450388333277],
              [0.43561059597322, 51.500132121570367],
              [0.435477982359473, 51.500363865269946],
              [0.436353626824291, 51.501554709851959],
              [0.433659349464861, 51.501452384863065],
              [0.431738670101349, 51.502942852828809],
              [0.431252818850848, 51.506348788855419],
              [0.427717512863601, 51.509894342397338],
              [0.424025331689488, 51.511581423108623],
              [0.423347789685573, 51.514511040230708],
              [0.423893821636968, 51.511493309911991],
              [0.427574770343098, 51.509811861820687],
              [0.431044471402999, 51.506360303554764],
              [0.431562009789247, 51.502926731529456],
              [0.433592529666841, 51.5013341611971],
              [0.436226776504824, 51.501472808884223],
              [0.435440573742321, 51.500429239323253],
              [0.435335133749644, 51.500289278977498],
              [0.435280841699363, 51.500217213055087],
              [0.435905907088289, 51.499968603844259],
              [0.43773415617408, 51.500260658295666],
              [0.440212971160436, 51.501239723151855],
              [0.442077415526601, 51.500644218170066],
              [0.44360084801259, 51.501135886122462],
              [0.44502189330648, 51.499499157045406],
              [0.445572190339853, 51.498178254636883],
              [0.444744035749613, 51.496084798302967],
              [0.442392857684477, 51.494175062148294],
              [0.441914772363292, 51.493155287892819],
              [0.44067175762387, 51.492325009824881],
              [0.440226209270019, 51.491535679378025],
              [0.440271529005763, 51.490954667910046],
              [0.441010219408877, 51.490705463400118],
              [0.439884787846627, 51.487165743493172],
              [0.439954359181923, 51.483154177884465],
              [0.438661887801168, 51.481877042046087],
              [0.439668720066567, 51.480825458265087],
              [0.438290742166114, 51.473840239322151],
              [0.437977353543659, 51.473634515433012],
              [0.436276598959156, 51.474120442173813],
              [0.436678053442975, 51.473871076559206],
              [0.436361773209443, 51.473340749626551],
              [0.436927829344749, 51.473775051475563],
              [0.437667485986415, 51.473089669212428],
              [0.437162523005118, 51.4711000542557],
              [0.43644956516897, 51.470988969598096],
              [0.437010743283238, 51.470819020937853],
              [0.436920289691502, 51.469851421014518],
              [0.436400960588865, 51.469671558452788],
              [0.436707613595656, 51.469103101027358],
              [0.435278676047707, 51.465578635308184],
              [0.435642634955916, 51.465194252175294],
              [0.433949947926095, 51.461498081865322],
              [0.430296960596866, 51.459240115880363],
              [0.429877837364327, 51.459588753772628],
              [0.43019704835352, 51.459527276134089],
              [0.430656820741321, 51.460268684979695],
              [0.429427190348402, 51.459642163043014],
              [0.429638921469003, 51.459427330162306],
              [0.429015853443199, 51.459487010021647],
              [0.416778017331909, 51.456116590147374],
              [0.415145723744353, 51.455764424732088],
              [0.413024501798345, 51.455830600405378],
              [0.403579326857905, 51.453737687714643],
              [0.39837172496894, 51.453660907970018],
              [0.394758465184758, 51.45346319622336],
              [0.39362392507254, 51.453155419980014],
              [0.393580614282151, 51.452767794666997],
              [0.388339775425495, 51.452934066964929],
              [0.381629745041081, 51.452611956811019],
              [0.381369594806235, 51.453310623837375],
              [0.380019758326867, 51.454479287967516],
              [0.379119492947008, 51.454276553157101],
              [0.378518911680382, 51.454489071446964],
              [0.378864144747525, 51.454955110214904],
              [0.378089454307678, 51.455227139398446],
              [0.378301330662367, 51.456616795104964],
              [0.377338313313106, 51.455204606203118],
              [0.378587693573296, 51.454981405511177],
              [0.378260979387081, 51.45453927196769],
              [0.378860489140918, 51.454220435055383],
              [0.379083087138271, 51.454216199142969],
              [0.379858991645847, 51.454389039700501],
              [0.380560472990338, 51.45355638954949],
              [0.380396940685944, 51.452792585617431],
              [0.378729018735762, 51.452134868057065],
              [0.373989557619894, 51.451671585035228],
              [0.370372132680302, 51.451749299885797],
              [0.370123790346064, 51.452234561931974],
              [0.369316027393969, 51.452257189620134],
              [0.366278477860989, 51.451831122988445],
              [0.365728661209608, 51.45133050684538],
              [0.359018929282468, 51.451117714644639],
              [0.35843319400325, 51.451342642572264],
              [0.356636666297391, 51.451254673773875],
              [0.356089713673173, 51.451673963155613],
              [0.356028777779429, 51.451245311682996],
              [0.353192774840434, 51.451179980212281],
              [0.346257934498528, 51.451571723896038],
              [0.34081550557691, 51.45232716095623],
              [0.338528800373217, 51.453997881473491],
              [0.338673787424231, 51.454750417529176],
              [0.339485797131707, 51.454809755031967],
              [0.338588223053577, 51.455075880082745],
              [0.338562425907905, 51.455810240005839],
              [0.335613127629042, 51.456829522184655],
              [0.334702214360799, 51.456722665200758],
              [0.332265489796043, 51.461925173445088],
              [0.333140078931333, 51.462334044741503],
              [0.333791296073613, 51.463951544628678],
              [0.328117734481352, 51.469334368999156],
              [0.325239858135842, 51.471581326647922],
              [0.324430326569488, 51.471321291874133],
              [0.324226696518478, 51.471791175219487],
              [0.323321733292646, 51.472175136428049],
              [0.322914031786218, 51.472058211370182],
              [0.322899729583702, 51.473278866106128],
              [0.322367297922275, 51.472492621973522],
              [0.318495304081988, 51.473376852900266],
              [0.318707011158417, 51.474115499607841],
              [0.318320842315013, 51.474139325060122],
              [0.318137788457, 51.473482848047105],
              [0.315470449601833, 51.474072455658771],
              [0.314617836099315, 51.473928316435],
              [0.314180344122378, 51.474218439295534],
              [0.313443389502664, 51.474282447714579],
              [0.313357196337588, 51.473945107293552],
              [0.312790334346428, 51.473955393256034],
              [0.312728117048422, 51.47429116556517],
              [0.312611006598478, 51.4739688236196],
              [0.31002681563661, 51.473982904064641],
              [0.309999073831847, 51.474345874123948],
              [0.309055296019219, 51.474392346741894],
              [0.305758473495376, 51.473896967564407],
              [0.305850930068567, 51.473617261216802],
              [0.305135822873207, 51.473686183487644],
              [0.297777922210114, 51.472182200239196],
              [0.297844504196002, 51.471988442305943],
              [0.291419873171156, 51.469500803647279],
              [0.28947019038323, 51.467774450170594],
              [0.287070835223579, 51.466381490740154],
              [0.284768715900458, 51.463732947679524],
              [0.283193373802511, 51.463035201221963],
              [0.281374024559476, 51.461398805787582],
              [0.279807160574888, 51.462254858905894],
              [0.280018179419627, 51.462531339481046],
              [0.278867104987868, 51.462718297705337],
              [0.279115785123533, 51.462999443528716],
              [0.276840727963468, 51.463253196102158],
              [0.274502556948016, 51.464351681720018],
              [0.271858691126818, 51.464602600176327],
              [0.271571101907514, 51.46524758269792],
              [0.270845599502005, 51.465540425708923],
              [0.269020857960823, 51.465674685443659],
              [0.266340378170368, 51.466409115940493],
              [0.264221058463905, 51.467690217522851],
              [0.262232795591673, 51.468246606520744],
              [0.26066965882738, 51.468257876986051],
              [0.257007082487615, 51.469433701552973],
              [0.255625373799092, 51.470409064938416],
              [0.249248333909673, 51.47237877061972],
              [0.247013715972428, 51.472735482502337],
              [0.23044069135831, 51.482504543752754],
              [0.228170025087285, 51.484705143943032],
              [0.228492791128059, 51.4863618164241],
              [0.227469508647177, 51.485965785286638],
              [0.227292368282843, 51.48552309754573],
              [0.226350693791655, 51.486032900591646],
              [0.226176218029701, 51.485555987224032],
              [0.222176205766239, 51.487184961836448],
              [0.219117374708734, 51.48802978450049],
              [0.217924921249351, 51.488747506486618],
              [0.216937118811112, 51.488785073891414],
              [0.216639675513152, 51.489186389181008],
              [0.216233958720113, 51.489048372579838],
              [0.216286806440529, 51.489306371617772],
              [0.21554451131098, 51.489624363735395],
              [0.213019954720584, 51.489698121644039],
              [0.212965767024529, 51.489887997026237],
              [0.212748598982512, 51.489687055806606],
              [0.211803281910526, 51.490126663399764],
              [0.211538300685277, 51.489741366569397],
              [0.210525360356444, 51.490242492215749],
              [0.21190386205651, 51.491043848362871],
              [0.213045858251874, 51.495021475541229],
              [0.213515097063884, 51.495012617994092],
              [0.214128145987508, 51.49603883231709],
              [0.214993946638832, 51.496482920107169],
              [0.216997101484581, 51.496582654291586],
              [0.221625399387386, 51.497809825517486],
              [0.2235780235917, 51.497905908581281],
              [0.224269537714279, 51.497312745131204],
              [0.227879186519702, 51.49885935595173],
              [0.229936884096718, 51.499365215966336],
              [0.226585963660038, 51.504488348274556],
              [0.225992333974502, 51.505879141853292],
              [0.226367935205407, 51.50592776757626],
              [0.226604616453897, 51.506585156778755],
              [0.228928192334248, 51.506803607801324],
              [0.233413474684843, 51.506669702661874],
              [0.23981860238309, 51.507277763392757],
              [0.239970773499867, 51.507706520409187],
              [0.241891212664021, 51.507959394306354],
              [0.241474207169496, 51.510620299541635],
              [0.240573624822056, 51.510506212769101],
              [0.240278840356879, 51.510754655615564],
              [0.239946235225123, 51.513052422984529],
              [0.241114385555141, 51.513742348690407],
              [0.237148080481766, 51.519333476178836],
              [0.240167358960483, 51.519350481984517],
              [0.243908587862597, 51.518336491701184],
              [0.244877816199149, 51.518758598571033],
              [0.244200507809394, 51.519758991760469],
              [0.244637719330641, 51.52014901345698],
              [0.244271542893294, 51.520470776021611],
              [0.24069667554376, 51.521206412842027],
              [0.241010413342411, 51.522605121083259],
              [0.242828091149623, 51.524391451213155],
              [0.245809514938548, 51.523515135584702],
              [0.247062015575696, 51.52509099548935],
              [0.246296738788239, 51.526531043063052],
              [0.248262965898683, 51.527550041892376],
              [0.248854300589776, 51.527408306285359],
              [0.248936812479415, 51.528672935716166],
              [0.250824011889098, 51.528817491301702],
              [0.251508192795692, 51.525969769930391],
              [0.252304833516344, 51.52478898203448],
              [0.253806308799203, 51.51788499130177],
              [0.25890003964057, 51.517762703739692],
              [0.260274973815821, 51.518370218343136],
              [0.26227980971173, 51.517929572347541],
              [0.263654809876295, 51.517868865441258],
              [0.2640073993406, 51.521083355988836],
              [0.264981214031776, 51.522900025493307],
              [0.266610053994949, 51.522954888543957],
              [0.266723436229318, 51.524512085179232],
              [0.265642711159276, 51.524609409097856],
              [0.26535855425361, 51.524954834016683],
              [0.26531923949042, 51.532149096150931],
              [0.270678083455984, 51.533092253880532],
              [0.274931469961636, 51.532942381486428],
              [0.276196233597353, 51.533177740328718],
              [0.276312067719493, 51.53292728507914],
              [0.281733872037584, 51.533940661581042],
              [0.28804710786326, 51.534552399535286],
              [0.290496451842224, 51.534483906906473],
              [0.290411521818511, 51.534659130990313],
              [0.297726621649182, 51.535317328180696],
              [0.297176997712912, 51.537660888420234],
              [0.300830509627718, 51.538133352907067],
              [0.300694571099539, 51.538754740668963],
              [0.310011753463539, 51.539291050274429],
              [0.310481765845061, 51.539345642996878],
              [0.310498634788642, 51.53965017516245],
              [0.322412943712313, 51.540516515983086],
              [0.33156186145412, 51.540795447518427],
              [0.331400870422155, 51.540044128234129],
              [0.333995675456271, 51.540502428644004],
              [0.333813176573862, 51.54116345463617],
              [0.333304739332969, 51.541260803378108],
              [0.333873969482338, 51.542492321916654],
              [0.33141679671763, 51.54594147210431],
              [0.328871435337901, 51.545835558848715],
              [0.329439355079814, 51.546562612448589],
              [0.327950406235863, 51.548559856393588],
              [0.327787589117016, 51.550993013541181],
              [0.32512901392156, 51.553056590541949],
              [0.322277427973077, 51.552780337163696],
              [0.32202502501832, 51.55419724286854],
              [0.318868615908104, 51.555189563405484],
              [0.318407016345853, 51.556778765048982],
              [0.316472223516777, 51.557683917282056],
              [0.31526651413674, 51.557833623196693],
              [0.31300694726663, 51.565816343565736],
              [0.3129800399729, 51.565940078477809],
              [0.34375565466105, 51.56781943532232],
              [0.379202406176746, 51.565733545158537],
              [0.382575631240661, 51.565821562922821],
              [0.385861823481223, 51.565721498826129],
              [0.389593002568368, 51.566457633414167],
              [0.391779672233333, 51.567209862266552],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000035",
        LAD13CDO: "00LC",
        LAD13NM: "Medway",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [0.58902707152497, 51.391886545371015],
              [0.585985777667625, 51.391896171037672],
              [0.585772505315248, 51.392218355355112],
              [0.58648153823346, 51.392937481915908],
              [0.588780080778352, 51.393622383867843],
              [0.589606675024307, 51.392349496887114],
              [0.58902707152497, 51.391886545371015],
            ],
          ],
          [
            [
              [0.6135416528674, 51.400209885004493],
              [0.614512914147458, 51.398660182578119],
              [0.615295496803946, 51.39864271221068],
              [0.615174025749122, 51.398126488354833],
              [0.615985800606919, 51.396983250613602],
              [0.616171144020817, 51.396200257203589],
              [0.615294995396542, 51.395383409439567],
              [0.61482112283182, 51.397585751770421],
              [0.612511411386508, 51.398329803579252],
              [0.611714052837084, 51.398895300192152],
              [0.611531899375881, 51.399735774000476],
              [0.607381332782139, 51.397290221251033],
              [0.607529103443366, 51.39665287737612],
              [0.605332609702425, 51.395317626574361],
              [0.604579757610418, 51.394238040336326],
              [0.603359194723356, 51.39383257916883],
              [0.601074169002759, 51.39420082080418],
              [0.598678824043574, 51.395032840285594],
              [0.598211124878588, 51.395734828669134],
              [0.596985633693158, 51.396079482181314],
              [0.598521129430795, 51.396334124934441],
              [0.598265043280025, 51.39668606188075],
              [0.596652295041942, 51.396692145776349],
              [0.596038032272083, 51.398868736681685],
              [0.595007688116391, 51.399411400686233],
              [0.595099260004155, 51.400216102986604],
              [0.596570969329139, 51.400154709205623],
              [0.598972572483423, 51.401025097324236],
              [0.599818898352246, 51.402130524264479],
              [0.6019977729242, 51.40234206207024],
              [0.605991248226808, 51.402022149526807],
              [0.608084827410396, 51.401542958790877],
              [0.612175054255657, 51.401307918748991],
              [0.6139060376107, 51.400951827623572],
              [0.614614779859286, 51.400445853954153],
              [0.6135416528674, 51.400209885004493],
            ],
          ],
          [
            [
              [0.616565348901246, 51.406329137855664],
              [0.615657144086205, 51.40538259994748],
              [0.614634037283286, 51.405622188345959],
              [0.614071288085658, 51.406268802168746],
              [0.616115005089972, 51.4065262648136],
              [0.616565348901246, 51.406329137855664],
            ],
          ],
          [
            [
              [0.610438422153629, 51.406437053574535],
              [0.610937151748799, 51.405877324510598],
              [0.613402625221516, 51.405865515783191],
              [0.614749714303391, 51.405230179949982],
              [0.614821073612735, 51.404867940459596],
              [0.613658465154838, 51.405081854517661],
              [0.612518959828118, 51.40491930595271],
              [0.612815234445983, 51.405273344413757],
              [0.612465659258872, 51.405598617948208],
              [0.611499330444545, 51.40564894467861],
              [0.611083258300801, 51.405211234048636],
              [0.609809972820456, 51.405527406368556],
              [0.609338328730661, 51.405350844889931],
              [0.608169880422398, 51.405867021493336],
              [0.607941086924297, 51.406319100741413],
              [0.608316729670912, 51.406425856141155],
              [0.607813872193932, 51.4069137162397],
              [0.608542408748039, 51.406752697296064],
              [0.610438422153629, 51.406437053574535],
            ],
          ],
          [
            [
              [0.570638533649246, 51.407839725121903],
              [0.572748786128887, 51.405469449259193],
              [0.574582465333207, 51.405013657801454],
              [0.576851552690594, 51.405397258042889],
              [0.576145061918243, 51.405517122898907],
              [0.576769188373443, 51.405799285939501],
              [0.576590517958428, 51.405588268434485],
              [0.577497194405575, 51.405473889367116],
              [0.578578009791071, 51.406460986464104],
              [0.578412913741284, 51.406874729606372],
              [0.579435792220007, 51.406882789340628],
              [0.57963039180083, 51.406635676394153],
              [0.579118694009841, 51.406512039020882],
              [0.579737258849456, 51.406541588060833],
              [0.580008510116504, 51.40620015206634],
              [0.579165801947839, 51.406046031513092],
              [0.578601055089623, 51.406257223317127],
              [0.57838461168482, 51.405937317749952],
              [0.579054731312081, 51.405886591688081],
              [0.579116038418858, 51.405494019267699],
              [0.579154416890645, 51.405792661981486],
              [0.579578347371158, 51.405787824316704],
              [0.579432388588821, 51.404880883472423],
              [0.58100318659234, 51.405120590214594],
              [0.5814067763645, 51.40467820431661],
              [0.581155472283226, 51.404454404380758],
              [0.582116181125383, 51.404125649603969],
              [0.582543445932862, 51.403134126125742],
              [0.582096848365308, 51.4025575863608],
              [0.581271262942451, 51.402425588015426],
              [0.577498775800951, 51.403022192809033],
              [0.569114300418526, 51.402865521334533],
              [0.563532281359391, 51.402168494274903],
              [0.561491299411562, 51.402233826918682],
              [0.560216946834099, 51.403230296405468],
              [0.560854569636927, 51.404708396905093],
              [0.560797686010416, 51.406057780457644],
              [0.562823337374989, 51.407052242514617],
              [0.563649694552469, 51.40796859599633],
              [0.564406765330232, 51.407954723780527],
              [0.566151846451133, 51.407634110772626],
              [0.570638533649246, 51.407839725121903],
            ],
          ],
          [
            [
              [0.597215488030994, 51.407366822256485],
              [0.597712424790278, 51.406848561557958],
              [0.598608844771044, 51.407035533551863],
              [0.598519796745983, 51.406783887863533],
              [0.598994190391927, 51.406679829681863],
              [0.599745476455413, 51.406944660250289],
              [0.601519657761692, 51.406736191781299],
              [0.603211185724741, 51.406895575079311],
              [0.601242011670863, 51.406582270336266],
              [0.600937948215256, 51.405988245557914],
              [0.600231192681105, 51.40572243423339],
              [0.599543410606112, 51.405765579432526],
              [0.599021135311201, 51.406368950783438],
              [0.596866265849636, 51.405975152323379],
              [0.595398231131798, 51.406561695963283],
              [0.596027955456298, 51.407346378273111],
              [0.596558398526628, 51.407419162200377],
              [0.596008430963767, 51.407534778211406],
              [0.596415585849345, 51.407563527930634],
              [0.597215488030994, 51.407366822256485],
            ],
          ],
          [
            [
              [0.617807771128582, 51.407511929345304],
              [0.617141514900778, 51.406748862887277],
              [0.614324905756719, 51.406681348353871],
              [0.612747154088989, 51.4060968850264],
              [0.611269550207006, 51.406057883082461],
              [0.61083561415575, 51.4065433207276],
              [0.6089623780635, 51.406829683835319],
              [0.608405332012593, 51.407173955261094],
              [0.613726889914053, 51.4075167151871],
              [0.61475973249471, 51.407421716071525],
              [0.615046942007179, 51.406810030253993],
              [0.616189733685446, 51.407029141409097],
              [0.615599096402935, 51.407187129149669],
              [0.617807771128582, 51.407511929345304],
            ],
          ],
          [
            [
              [0.553646297242891, 51.399342790473817],
              [0.553539593177988, 51.398511410540635],
              [0.553825349581686, 51.399330792737182],
              [0.555177466899187, 51.399109742926512],
              [0.55551010658735, 51.398912721624072],
              [0.554262339743881, 51.397497363836052],
              [0.554708102771732, 51.397601861470513],
              [0.554927613384653, 51.397284096675847],
              [0.556281908565637, 51.398624274693269],
              [0.557555584784324, 51.397487561686411],
              [0.557474241618966, 51.396545009567319],
              [0.558657466874876, 51.39626195273074],
              [0.559682048950221, 51.39800411296612],
              [0.558303592216718, 51.398293242555063],
              [0.559107201627351, 51.398420485844774],
              [0.561902523416411, 51.397912400811968],
              [0.56210065652901, 51.39754742361778],
              [0.560940131384076, 51.394814462257834],
              [0.561534930284276, 51.395064969258655],
              [0.561671158151916, 51.396062079483698],
              [0.563663881778292, 51.396115613994333],
              [0.565177534399833, 51.395084184603192],
              [0.567859899895939, 51.394643182588787],
              [0.568819587844821, 51.393528515410566],
              [0.56907505664635, 51.393674904711496],
              [0.572127641906187, 51.39217342852956],
              [0.572222439065256, 51.392658801539916],
              [0.574719107384589, 51.392677695509796],
              [0.575286152357364, 51.393328959833077],
              [0.575999873046599, 51.393289882193038],
              [0.576098894427127, 51.392826330960204],
              [0.575526537573059, 51.392259727459759],
              [0.576346852449804, 51.391866652475564],
              [0.577354990997764, 51.390570074884963],
              [0.580216832016791, 51.391026000089084],
              [0.582350843150013, 51.390299051188386],
              [0.585453319321883, 51.39005073620735],
              [0.586364022356402, 51.390854448802131],
              [0.587530825638432, 51.390948293995201],
              [0.592641027721038, 51.390310061207188],
              [0.593065655397158, 51.39006592658555],
              [0.592613654384668, 51.388478657454037],
              [0.593049353553182, 51.387820568902868],
              [0.592656843107043, 51.387107066521224],
              [0.593329352365789, 51.386925795148095],
              [0.593585086766876, 51.38722861537282],
              [0.594608939431355, 51.38644867178958],
              [0.596177382206488, 51.387240434783521],
              [0.59675013675461, 51.388597474278114],
              [0.597791325777202, 51.389316364012224],
              [0.598359917255915, 51.389381097246797],
              [0.598432668651267, 51.389142949645617],
              [0.600201347712642, 51.389635224714738],
              [0.602115259373024, 51.390634188512074],
              [0.602660094959461, 51.390330687901411],
              [0.602934140927792, 51.389384758378746],
              [0.601286254640105, 51.389299054201324],
              [0.600784875340187, 51.389532332227461],
              [0.598342703985364, 51.38872224273549],
              [0.598321996128869, 51.388458288061351],
              [0.598013831923676, 51.388626110762637],
              [0.596965914679089, 51.388194270420861],
              [0.596669972353334, 51.386826598406074],
              [0.598769523407155, 51.385727771645037],
              [0.598778646030296, 51.384722075768664],
              [0.599598343554077, 51.384653520332584],
              [0.599752895891081, 51.384438738301832],
              [0.59928460605415, 51.384468918085453],
              [0.600195772302538, 51.384365951158138],
              [0.601446518477337, 51.383408229772208],
              [0.602265686528902, 51.383305491161643],
              [0.606328209224764, 51.380840820931425],
              [0.60826663839946, 51.380303913163694],
              [0.608871668479579, 51.380707745037959],
              [0.609805472231125, 51.380751694912412],
              [0.61195894638151, 51.379423883351478],
              [0.611771198464589, 51.378827290687369],
              [0.61393870214684, 51.378429078217394],
              [0.615323236998852, 51.378716545585426],
              [0.61626045376938, 51.378241430034493],
              [0.616268356925013, 51.377926473399441],
              [0.617037866071988, 51.378441708610573],
              [0.616220804075718, 51.379108410612538],
              [0.617101574356761, 51.379306379928359],
              [0.616560619042536, 51.378943429046281],
              [0.617253824768295, 51.378750763373915],
              [0.617904739536797, 51.379227272159255],
              [0.617469836968963, 51.379513999267971],
              [0.618493738102144, 51.379449740143208],
              [0.621906751809809, 51.382764014903906],
              [0.621456849292799, 51.383945068022115],
              [0.621869664336998, 51.386690602826896],
              [0.621472690018052, 51.386208431876753],
              [0.621288505262371, 51.387084944500323],
              [0.619193787501429, 51.387756869723908],
              [0.619202432572148, 51.388486965572014],
              [0.620163711104196, 51.389033867424523],
              [0.620692319132394, 51.388901525185091],
              [0.620793910509687, 51.389169962695206],
              [0.621474117528109, 51.388898419589509],
              [0.622181690917234, 51.389055259883939],
              [0.624534446247073, 51.388546581426112],
              [0.624257159657738, 51.387820706012327],
              [0.624991353969365, 51.388392442591865],
              [0.624701241669186, 51.387969943918002],
              [0.625121590035898, 51.388131403942978],
              [0.625209703649756, 51.387712120443659],
              [0.624870016430373, 51.387603714753837],
              [0.625548758209972, 51.387432910020117],
              [0.625445369309665, 51.38813763712929],
              [0.62609636244123, 51.388410841681875],
              [0.626296803428301, 51.387217377439612],
              [0.626482152600645, 51.388269983350973],
              [0.63137159263852, 51.388257368385091],
              [0.633787846240877, 51.389081745834545],
              [0.634610460032037, 51.389088427129437],
              [0.635147588394702, 51.388304681262589],
              [0.635664160689498, 51.388338925838795],
              [0.635293062916744, 51.387959646231991],
              [0.635521716441869, 51.386636019543168],
              [0.634527419350525, 51.385741927752008],
              [0.634763052232088, 51.385615211717216],
              [0.63327607077643, 51.384450688075162],
              [0.63041650131052, 51.384287405209655],
              [0.628288614935691, 51.382771167006368],
              [0.628140413759726, 51.382141334133031],
              [0.624781694858545, 51.381063660331549],
              [0.625313046821671, 51.380202742755827],
              [0.623948388289725, 51.379460764268892],
              [0.624127463281699, 51.378873059061561],
              [0.623208126223581, 51.377850382114865],
              [0.622501879905312, 51.376029679751177],
              [0.623581322107084, 51.376460585565852],
              [0.623849127341469, 51.376318780505869],
              [0.623470398899333, 51.376053855703177],
              [0.623873783207903, 51.375945887632191],
              [0.624211177424912, 51.376417693802843],
              [0.626898150965305, 51.374730487425204],
              [0.625709143821447, 51.37346475187406],
              [0.625805605216112, 51.371409318531995],
              [0.623263680324205, 51.369222348735832],
              [0.626238370796403, 51.368250904933262],
              [0.626711739501572, 51.368689075911121],
              [0.628352881044991, 51.368368050609625],
              [0.634879075185478, 51.366369576631818],
              [0.630594942872187, 51.360910449956876],
              [0.629269808542866, 51.359219705352807],
              [0.628540097753368, 51.359370994324124],
              [0.625297716859068, 51.355174396988325],
              [0.622055586448177, 51.349688900777075],
              [0.620004350588054, 51.349708736079897],
              [0.61813385779004, 51.348641645947531],
              [0.617904739339868, 51.347393938713616],
              [0.613694361752621, 51.341118625335369],
              [0.61129305603904, 51.33931859427431],
              [0.612023159272689, 51.337941552345846],
              [0.610920290398481, 51.336474087847016],
              [0.60798070164968, 51.3346446223619],
              [0.603849569448282, 51.336257418277029],
              [0.602001107881499, 51.334624778271198],
              [0.601374838890726, 51.333301325215039],
              [0.599737507682231, 51.332529157349249],
              [0.596912134537667, 51.332707882863581],
              [0.596782725855264, 51.333339415840058],
              [0.594332872276372, 51.333228244711833],
              [0.5936945720174, 51.333620124427377],
              [0.59178882551448, 51.333763058931488],
              [0.586442153935992, 51.335088258092981],
              [0.586082107378531, 51.334762544542173],
              [0.584633653468701, 51.334715385513718],
              [0.583368699246516, 51.334663260300438],
              [0.581108244960318, 51.335214966132135],
              [0.580259029846038, 51.335944188327311],
              [0.577749121005546, 51.33642757733913],
              [0.575406268062739, 51.337561079610218],
              [0.573868809381187, 51.336798047166042],
              [0.573416054110888, 51.336917722817589],
              [0.571915622867205, 51.335609732379176],
              [0.569612115690639, 51.337012064412747],
              [0.565630976215179, 51.337790088182771],
              [0.563428454519626, 51.338743096673205],
              [0.556681851221578, 51.33653427220284],
              [0.554422932931023, 51.333764061969482],
              [0.554734927828112, 51.33222293318925],
              [0.553943911654712, 51.331629520770043],
              [0.548895052155635, 51.330910333701887],
              [0.545891769889379, 51.329563675278031],
              [0.545318568804851, 51.328571546107099],
              [0.543974851826294, 51.327896548432605],
              [0.5401101537052, 51.330254960225147],
              [0.538652697558766, 51.331756135794933],
              [0.538635399029288, 51.33274221522192],
              [0.537303944559161, 51.333875498179488],
              [0.532901052574119, 51.337164475226217],
              [0.525611865160395, 51.334717384200538],
              [0.524463461032974, 51.335138770487873],
              [0.523761931215109, 51.335880579559685],
              [0.522227893471435, 51.336418178844326],
              [0.521706760462063, 51.337465469282527],
              [0.520327292013435, 51.3382308497346],
              [0.519929056843767, 51.339190046627294],
              [0.519522844291194, 51.339166411517446],
              [0.519302267439261, 51.339935615060881],
              [0.516641160082161, 51.339666375153385],
              [0.514776666288846, 51.340466395572456],
              [0.514107813944442, 51.341127399431222],
              [0.511833427989336, 51.340945977039553],
              [0.511130914047491, 51.340913379679016],
              [0.507545024751738, 51.343299784932832],
              [0.505816419264978, 51.342937475970594],
              [0.502976900345273, 51.34764339504747],
              [0.502188526639254, 51.348804227585596],
              [0.503067418446969, 51.349251310671541],
              [0.501959671042028, 51.351430744797881],
              [0.503116822498736, 51.353134579701731],
              [0.503062502974471, 51.354667346088497],
              [0.498867827248171, 51.353681303164933],
              [0.494040597049978, 51.352654623930654],
              [0.491217818973915, 51.35402237377],
              [0.489455794462931, 51.354346740393659],
              [0.488971178439841, 51.355210529708479],
              [0.487618400301233, 51.356010921142143],
              [0.481779399531425, 51.357683500478288],
              [0.479628985359401, 51.357443059794555],
              [0.479097516461292, 51.358427412193713],
              [0.475964236001445, 51.360551419390127],
              [0.475652010061142, 51.36022256186817],
              [0.472736898339738, 51.361814847700501],
              [0.470362904184183, 51.36222738863772],
              [0.466650887419903, 51.364180778588725],
              [0.456944182173135, 51.368057762271256],
              [0.463906316508804, 51.370752542740746],
              [0.467681257602211, 51.373430471081825],
              [0.476633966484145, 51.374613007688751],
              [0.479484244480513, 51.375584256556138],
              [0.480324668826754, 51.376483799582566],
              [0.480769608751707, 51.37626122995546],
              [0.480483707633154, 51.376714262714295],
              [0.481296826568631, 51.376311217603693],
              [0.48054447771468, 51.377014258228577],
              [0.481284428822502, 51.37780889965174],
              [0.482290021540142, 51.376580662428495],
              [0.482717616817056, 51.376675024580969],
              [0.482249599134109, 51.377267724062079],
              [0.482587517226148, 51.377510581506876],
              [0.48194650761849, 51.377695043538928],
              [0.482106609650308, 51.378715112126571],
              [0.485102956877521, 51.378317016176233],
              [0.485192385037442, 51.377686472007717],
              [0.482888221182711, 51.37698618098451],
              [0.483115216412457, 51.376772719180387],
              [0.485318993310864, 51.37763611985325],
              [0.485318711670138, 51.378373594039303],
              [0.488944732598295, 51.378881173848711],
              [0.490583201749765, 51.380234044299819],
              [0.492046126915405, 51.380576158231349],
              [0.49512908236494, 51.38298464100729],
              [0.494890361080123, 51.383087755568646],
              [0.496712858218317, 51.384673151559014],
              [0.496804037515111, 51.386849439059361],
              [0.496120925517558, 51.387476457198879],
              [0.500603754943849, 51.390474612651687],
              [0.501682080727233, 51.391637833045593],
              [0.505360960715696, 51.393510799788963],
              [0.508614924790866, 51.394338711848555],
              [0.509676182730501, 51.393477781941336],
              [0.510177356367231, 51.391708807182958],
              [0.509956727083192, 51.391197306109305],
              [0.509222123887302, 51.390855102239613],
              [0.510138342776165, 51.390628620231027],
              [0.510537189223946, 51.388855540201064],
              [0.509113207987988, 51.388181858322156],
              [0.5111022591127, 51.388753492056317],
              [0.511327151562267, 51.388240535473876],
              [0.510850888583635, 51.38796924788906],
              [0.511473347181673, 51.387078133318717],
              [0.510531135918991, 51.386565914481395],
              [0.51144056772037, 51.386374641720742],
              [0.510991201506774, 51.385832971761289],
              [0.51191555215377, 51.386281715736665],
              [0.513773868323289, 51.38512484950315],
              [0.513562966501182, 51.384345138155147],
              [0.514195461647758, 51.384189464961487],
              [0.514729908912047, 51.384499960702058],
              [0.517274041799797, 51.383742913779571],
              [0.517902206289395, 51.384084657137514],
              [0.518488956061937, 51.383855297450246],
              [0.522515555400959, 51.385071814415177],
              [0.523411848816135, 51.386523854841776],
              [0.522825699428399, 51.387652582131047],
              [0.522997624984389, 51.388321597195215],
              [0.523907915011884, 51.389739156774226],
              [0.525897730863811, 51.395773211196776],
              [0.528161990958698, 51.398405342839744],
              [0.528549020865576, 51.398332235554207],
              [0.528528837939568, 51.398852498672582],
              [0.528874175247029, 51.398753309625114],
              [0.529159024545503, 51.399121291712518],
              [0.528862857639628, 51.399225715572172],
              [0.531104264772487, 51.402635328103024],
              [0.532655254962067, 51.407023935900462],
              [0.534414621758401, 51.409064296139739],
              [0.536981010308902, 51.410222876407211],
              [0.539405006915504, 51.410107402864995],
              [0.544380426339773, 51.409064116465309],
              [0.548234300561307, 51.407509900606229],
              [0.553216725031442, 51.400458366117142],
              [0.552777774239966, 51.400011957773991],
              [0.55347844161752, 51.399788034990102],
              [0.552631808613836, 51.399430555279693],
              [0.553646297242891, 51.399342790473817],
            ],
          ],
          [
            [
              [0.633822061881707, 51.423825476674487],
              [0.635065346201749, 51.42288196178901],
              [0.638884977891896, 51.421265260742523],
              [0.638239895503143, 51.420979399844882],
              [0.638133727503018, 51.420210131216812],
              [0.63850033118686, 51.420031894260816],
              [0.638335785187596, 51.418648771262198],
              [0.638827289207632, 51.41844163848122],
              [0.637999005267589, 51.417871205093732],
              [0.63846299508278, 51.416487417417507],
              [0.637356420031378, 51.416878378386734],
              [0.637555150611599, 51.418156406231859],
              [0.636886457617855, 51.418184050535899],
              [0.637225155939123, 51.418595532947172],
              [0.636929815295422, 51.419661638848744],
              [0.636870106657935, 51.419349102693708],
              [0.636058846475629, 51.419799057243381],
              [0.635339412343053, 51.41964526218257],
              [0.63465175883418, 51.420418897326435],
              [0.63403223405939, 51.420576727199965],
              [0.633182787473817, 51.420713640062118],
              [0.632284734205929, 51.420380369481656],
              [0.631877215795903, 51.420572996434998],
              [0.632435996846556, 51.420834749558459],
              [0.631588579717898, 51.420631643726423],
              [0.629854817646988, 51.421050109338744],
              [0.631069388167721, 51.422160540045041],
              [0.630228377830604, 51.422820674100358],
              [0.630492762492384, 51.423416415673991],
              [0.63012476494488, 51.423922028337515],
              [0.631187866492915, 51.423872075662324],
              [0.631309262036578, 51.424157147122401],
              [0.633822061881707, 51.423825476674487],
            ],
          ],
          [
            [
              [0.63828746092216, 51.427597688466236],
              [0.638900052991346, 51.42696872352505],
              [0.637373454899852, 51.426990507513992],
              [0.637369460317069, 51.426571491442893],
              [0.636705696265652, 51.426312124004639],
              [0.636301805695025, 51.426543357148908],
              [0.635458826158421, 51.426444505423504],
              [0.635003091913554, 51.426899943652842],
              [0.634220278205249, 51.426746667045492],
              [0.633555416939862, 51.427193310785761],
              [0.633123050032043, 51.426954803185772],
              [0.633653640896594, 51.428525765476977],
              [0.633835430600756, 51.427684366967611],
              [0.63494549206058, 51.427698979076077],
              [0.635309848707561, 51.428580259631275],
              [0.636593042303506, 51.429427374776559],
              [0.637910480996104, 51.429045167315131],
              [0.63828746092216, 51.427597688466236],
            ],
          ],
          [
            [
              [0.654502532124069, 51.4420584508659],
              [0.655461726155137, 51.442021427453824],
              [0.655712759044154, 51.442280152899272],
              [0.656504858664391, 51.441942920871163],
              [0.657021389150059, 51.442040032891583],
              [0.657258558554978, 51.44161374918059],
              [0.655910636906257, 51.441176637385858],
              [0.65552878915504, 51.441465895556306],
              [0.654152807516885, 51.441388248263642],
              [0.652926712718807, 51.440246834194909],
              [0.653723942314935, 51.43979978146556],
              [0.655379004814368, 51.439529348313627],
              [0.6567538107661, 51.439785982182514],
              [0.656674868780918, 51.440582815694043],
              [0.657104291801213, 51.440245707492871],
              [0.65846532038506, 51.440066439060224],
              [0.658115766288263, 51.439622885359803],
              [0.656966900207691, 51.439812628309937],
              [0.656245004170873, 51.43939999611063],
              [0.656213413357472, 51.438855693672032],
              [0.654778898980801, 51.439082469094245],
              [0.654151248243341, 51.438582248604042],
              [0.653494778296182, 51.438651978877019],
              [0.653620136316252, 51.439101523099879],
              [0.651966301838668, 51.439169545412504],
              [0.651482910067684, 51.438799152424785],
              [0.650250926092085, 51.439044672476129],
              [0.650111099708988, 51.439438161518986],
              [0.65094562643026, 51.439411189243962],
              [0.651914809977486, 51.440441520432429],
              [0.650780306733935, 51.440880002548404],
              [0.650928633105489, 51.441055621401766],
              [0.652535759438695, 51.441471633299948],
              [0.653858809977102, 51.442572182650096],
              [0.654057511704482, 51.442325751583809],
              [0.654502532124069, 51.4420584508659],
            ],
          ],
          [
            [
              [0.6603967265139, 51.447446007818762],
              [0.661057382239115, 51.446728598093323],
              [0.660050164631177, 51.446782945425888],
              [0.66122417553517, 51.446577313366042],
              [0.661275551338747, 51.445802687838693],
              [0.660201046707256, 51.446110388561792],
              [0.66111496002049, 51.445788347639521],
              [0.660857788695195, 51.444830961893942],
              [0.661182659344293, 51.443735345460674],
              [0.659401748852976, 51.443773980164423],
              [0.659055277337441, 51.444176666882875],
              [0.656966707046709, 51.443824724133528],
              [0.65655398323906, 51.444102993726844],
              [0.657122484209233, 51.444251990039568],
              [0.657417152766687, 51.444989086697866],
              [0.657171233850519, 51.445686279185026],
              [0.657011416595567, 51.445338249554041],
              [0.656777531380841, 51.445672722176276],
              [0.655500204996501, 51.445437261847438],
              [0.656048591965254, 51.445983341366727],
              [0.657003918370646, 51.446175733000921],
              [0.657509205940627, 51.44771388869929],
              [0.65853375139654, 51.447932578763002],
              [0.6603967265139, 51.447446007818762],
            ],
          ],
          [
            [
              [0.553933022911301, 51.484555727411042],
              [0.55363541434385, 51.48335438582437],
              [0.552964850466796, 51.482438166305421],
              [0.553911953865263, 51.481421056139936],
              [0.554083787274395, 51.481634954606697],
              [0.554534196468511, 51.48136972443946],
              [0.555090682858359, 51.482151720854432],
              [0.558433275123731, 51.48278570056636],
              [0.558357370263553, 51.484157066632903],
              [0.559925833136436, 51.48469391080139],
              [0.565381118205301, 51.484108783546269],
              [0.574517908565203, 51.484263708161023],
              [0.576258129484326, 51.483219098651894],
              [0.579244590897363, 51.480471551705826],
              [0.58017822138517, 51.480425821982905],
              [0.581010192392982, 51.481002877245849],
              [0.581985840270773, 51.480833895886114],
              [0.582287497398501, 51.480717529480238],
              [0.581937816918561, 51.47993290201002],
              [0.582461730082784, 51.479646157998609],
              [0.582082073074787, 51.480013364195415],
              [0.582610862546644, 51.480934344215626],
              [0.583699897380577, 51.481064133723095],
              [0.584463136569884, 51.480832361935605],
              [0.584844832807218, 51.481036192828],
              [0.584925622967542, 51.483839495943997],
              [0.585753181734641, 51.484438200014189],
              [0.587983870100929, 51.484021135189913],
              [0.590101572145803, 51.483034534679582],
              [0.591771746146018, 51.483098344950029],
              [0.599497603395698, 51.481114160505612],
              [0.601563865789494, 51.481406475921212],
              [0.609961794252813, 51.479298752971467],
              [0.610472235591072, 51.479409698494919],
              [0.611806239500639, 51.478759423147906],
              [0.61149213751925, 51.477925521563499],
              [0.613125759191955, 51.475941999847969],
              [0.615209404047955, 51.475080706993893],
              [0.617143844799602, 51.475689564136914],
              [0.621403785271537, 51.476114195814219],
              [0.624420818084131, 51.477339960320748],
              [0.628041689172215, 51.477892878781475],
              [0.629044939112466, 51.477606866748062],
              [0.629809735027596, 51.477982730485309],
              [0.637904737838004, 51.478419584284545],
              [0.642272965032144, 51.479064964552457],
              [0.645999580683936, 51.478875669288477],
              [0.650654741115121, 51.476667770033224],
              [0.651495895526433, 51.476941937059422],
              [0.656821591133097, 51.477184679064592],
              [0.659955228920088, 51.477673909215575],
              [0.660778314405861, 51.477339548948287],
              [0.661358598701791, 51.476051071760367],
              [0.663305066624768, 51.475272973428694],
              [0.665925141329559, 51.475526386179325],
              [0.669664802791837, 51.474999673834908],
              [0.670012525019436, 51.47387653579672],
              [0.671170455950097, 51.473483208369544],
              [0.671125369682625, 51.472834891567196],
              [0.67000954239458, 51.473159806887708],
              [0.671077605228324, 51.472756835689097],
              [0.670507801661578, 51.472543179522106],
              [0.670465261440323, 51.47107547927385],
              [0.670136140603061, 51.470963361981191],
              [0.670483098655218, 51.470961752334311],
              [0.670340277187268, 51.470267096130975],
              [0.671299806053542, 51.469092237985251],
              [0.674631275558538, 51.466890144664681],
              [0.679770519922691, 51.466229523297343],
              [0.683475786551552, 51.465377575306576],
              [0.685030122074734, 51.464457890988584],
              [0.685962080927614, 51.463606413991968],
              [0.6857599744059, 51.463425775269016],
              [0.686097242729452, 51.463505284460417],
              [0.687471524748497, 51.462267717864641],
              [0.687679374260268, 51.461639685639774],
              [0.687151946454847, 51.460161519646647],
              [0.68771818869152, 51.459997439442951],
              [0.688325297197421, 51.460944943753667],
              [0.689082653425718, 51.461019303461136],
              [0.688718814765962, 51.462152767685794],
              [0.689943680922396, 51.461118259865657],
              [0.688890324671461, 51.460662168527634],
              [0.689572516499716, 51.460533261766315],
              [0.690071890518691, 51.461046065109194],
              [0.690674988622364, 51.460749816037094],
              [0.689978183930251, 51.460211798722398],
              [0.689908873579667, 51.45959641938169],
              [0.689437177543501, 51.459507415278445],
              [0.690036709233091, 51.45951793758141],
              [0.690281616113818, 51.459838788115235],
              [0.690572392161181, 51.459299683091565],
              [0.690897310392191, 51.459438820540313],
              [0.69024902513223, 51.460190292035584],
              [0.690975686518976, 51.460893106929092],
              [0.689000759557626, 51.462319877609808],
              [0.688385110847277, 51.462305222567245],
              [0.687570844466216, 51.462879711854939],
              [0.686509151733957, 51.463923943848059],
              [0.687048304880639, 51.464425124753035],
              [0.686402550124531, 51.464192601822923],
              [0.684467893440377, 51.465574300662105],
              [0.68140201668334, 51.466795684128222],
              [0.674873090404023, 51.467873033594671],
              [0.672106807988867, 51.469792437303795],
              [0.671698265613983, 51.471746187800484],
              [0.67120747413467, 51.472194468288357],
              [0.675904034273943, 51.473161178251331],
              [0.678514808055196, 51.474731198987911],
              [0.680092286381304, 51.474469395020797],
              [0.686010508984604, 51.475092081157221],
              [0.687005666112059, 51.474356967992669],
              [0.693162569784501, 51.472967320971314],
              [0.694407741214784, 51.47239637933373],
              [0.698713095508581, 51.472435823222071],
              [0.699708323243519, 51.4727303809427],
              [0.703145796874314, 51.47126790940397],
              [0.71163389840963, 51.464572930775134],
              [0.716292950710824, 51.462046606940547],
              [0.718165748703095, 51.460490428221476],
              [0.719319242315502, 51.460422295431506],
              [0.720356630509349, 51.459733577180238],
              [0.721702171406265, 51.457788455172299],
              [0.722678903589733, 51.453760828276664],
              [0.723366688613626, 51.447324169518843],
              [0.722946673336587, 51.446917517648885],
              [0.723453456182823, 51.446678196206214],
              [0.723216412534439, 51.44349360148275],
              [0.722546553954604, 51.44345880289778],
              [0.721620070311314, 51.442908320766605],
              [0.721432798303252, 51.441574391731407],
              [0.717263298022877, 51.437545702950509],
              [0.713399857676958, 51.435681785835826],
              [0.710256158197703, 51.434813797951712],
              [0.709601506074889, 51.435519663625151],
              [0.708809244172232, 51.435651305289106],
              [0.707133194087506, 51.435170202316726],
              [0.705275986463643, 51.433838848395382],
              [0.699850751651708, 51.433218407403771],
              [0.698153775861824, 51.433697295963796],
              [0.694137023552059, 51.433609854509342],
              [0.690730944317813, 51.433018091818809],
              [0.687654822713626, 51.433461938738212],
              [0.677859379565312, 51.433173588333958],
              [0.675472678766226, 51.433549190896969],
              [0.673598973990296, 51.434307864540273],
              [0.673289892475891, 51.434780792863179],
              [0.67498083742716, 51.436015712860822],
              [0.675979559258503, 51.436214153044702],
              [0.67681579472006, 51.436832707407596],
              [0.676624963615846, 51.438786010733686],
              [0.675354441513736, 51.439945540812218],
              [0.672556094671312, 51.440055270727719],
              [0.671524904787391, 51.441520481696301],
              [0.669619348423933, 51.442800550391659],
              [0.669122245319334, 51.443579939071398],
              [0.669601135455917, 51.4436724571122],
              [0.668969442833501, 51.443723721762751],
              [0.668986578620591, 51.444239571036313],
              [0.669734618035532, 51.44450493264587],
              [0.668877224597644, 51.444708835941434],
              [0.66928373441715, 51.445139369088402],
              [0.670628414394132, 51.445541327039251],
              [0.671361142687992, 51.446480657237615],
              [0.670924273813699, 51.44698527077388],
              [0.671445786368869, 51.447264778780244],
              [0.671642902207778, 51.446918523244889],
              [0.672066096173737, 51.447929661001965],
              [0.670342417895708, 51.448507678212948],
              [0.670197082637208, 51.448212398712187],
              [0.670894849913797, 51.447861929797526],
              [0.670206222546736, 51.447234573223128],
              [0.670060043199749, 51.446061525721092],
              [0.668746577829756, 51.445627372236885],
              [0.667350524742057, 51.444640171880941],
              [0.667806629585215, 51.444687348030655],
              [0.667133028513818, 51.44446434903687],
              [0.667193807763556, 51.443877474513883],
              [0.665757650961528, 51.444053156690508],
              [0.663486005703778, 51.443622752634504],
              [0.662533136412396, 51.443942100375907],
              [0.663661879508843, 51.446050654261825],
              [0.662192568398533, 51.447711007194464],
              [0.658559285875045, 51.449139853159991],
              [0.655981826649467, 51.449221685762652],
              [0.654844362301891, 51.44881846284261],
              [0.653819002017613, 51.447642830180065],
              [0.653848990619772, 51.447167284045264],
              [0.653176439088046, 51.447061099293123],
              [0.65288959384622, 51.446532469153432],
              [0.651579266671975, 51.44657202070772],
              [0.65131953847597, 51.446809035570972],
              [0.649873500508959, 51.445946873614339],
              [0.650489529141796, 51.445748570283691],
              [0.651093646350436, 51.446338380370506],
              [0.650904319237886, 51.44607645049259],
              [0.651719331626827, 51.446083191848516],
              [0.650530946194782, 51.445668488973531],
              [0.650088249951941, 51.445156867991095],
              [0.649482624628197, 51.445186752083998],
              [0.650047794798896, 51.44582511461897],
              [0.649620454965991, 51.445454345510186],
              [0.649770453437709, 51.445857468298421],
              [0.64932058480624, 51.445768709974935],
              [0.649301460842958, 51.445388709391125],
              [0.648707823991993, 51.44560089022243],
              [0.649324639965369, 51.444943897144718],
              [0.648754075699687, 51.444410879296171],
              [0.648847850274095, 51.445160631747441],
              [0.648635518521723, 51.444724740810933],
              [0.648473671155164, 51.44516099542259],
              [0.648340716846112, 51.444952648823282],
              [0.6480535644754, 51.445113829921866],
              [0.648313613702021, 51.445379562111171],
              [0.648638743405469, 51.445203132057301],
              [0.648431412719909, 51.44550011315954],
              [0.646178307209319, 51.444965527652577],
              [0.645149843482016, 51.444378077893177],
              [0.645083639293543, 51.443382173002767],
              [0.643794261988731, 51.444206313494064],
              [0.641555414441664, 51.444340447716435],
              [0.641573029441668, 51.443997391539042],
              [0.640880409387474, 51.444040884143163],
              [0.640774862103921, 51.444306777583655],
              [0.641505717191148, 51.44477596260095],
              [0.641109096702348, 51.445210303772747],
              [0.638564174398548, 51.44492947553946],
              [0.638723720625706, 51.444726223292214],
              [0.639546547490745, 51.444893859951193],
              [0.639059035592833, 51.4445262071849],
              [0.637668180353104, 51.444516153121747],
              [0.636627329992307, 51.444960481984317],
              [0.636896228953333, 51.446055255965604],
              [0.635786008589998, 51.446220535611559],
              [0.636543797148976, 51.446033507255109],
              [0.635754744156601, 51.445877680873252],
              [0.636047890525542, 51.445469969073599],
              [0.635285652829269, 51.445679578514934],
              [0.635428679730858, 51.445114257729962],
              [0.634961043494375, 51.445291157213802],
              [0.634471175373253, 51.444981997427647],
              [0.634538195519263, 51.444321254995771],
              [0.633814952445384, 51.444958076618178],
              [0.633300160090049, 51.44494086689668],
              [0.633774097091996, 51.444723360956964],
              [0.633453496567418, 51.444554290856182],
              [0.634110984962183, 51.444425292909187],
              [0.633437471752118, 51.444275847447997],
              [0.633448044301657, 51.44388438547918],
              [0.63408484227513, 51.443771141821017],
              [0.634620714820282, 51.442929878261054],
              [0.634094539378088, 51.44263952070758],
              [0.633618546226267, 51.442920929432063],
              [0.632501087918599, 51.442784154110541],
              [0.631589000010838, 51.442314487329725],
              [0.631776120531668, 51.441789551590254],
              [0.632657408100063, 51.441874080686993],
              [0.633632321221627, 51.441559878164085],
              [0.63340593220221, 51.441251986742017],
              [0.632712549813595, 51.44123159341526],
              [0.631223832768084, 51.441895488996025],
              [0.631401047025019, 51.441073086033207],
              [0.63047204807703, 51.440859210609979],
              [0.629535942653504, 51.44169864502458],
              [0.629555185061378, 51.44233406493808],
              [0.632244764916057, 51.443557071915123],
              [0.632730051142621, 51.444312429425388],
              [0.632198553606242, 51.444354948416695],
              [0.632699117271857, 51.444500192613141],
              [0.633269690364706, 51.443961242402516],
              [0.63344406689769, 51.444465465518007],
              [0.632831586150059, 51.444701372373622],
              [0.633293867355775, 51.444606444073663],
              [0.633107605299922, 51.444946093365068],
              [0.628667149369906, 51.442357587745299],
              [0.6285767135627, 51.44098358676915],
              [0.629569758030107, 51.440508924198063],
              [0.630091665441809, 51.439348719712619],
              [0.629590531650643, 51.439142320677696],
              [0.628828476026376, 51.437800477965887],
              [0.628419175357002, 51.438090262582229],
              [0.627120826504104, 51.437582458910228],
              [0.625735310054766, 51.437561352700271],
              [0.6255574405991, 51.437922386783583],
              [0.624534879922111, 51.437806793515364],
              [0.624062120076937, 51.438144751100232],
              [0.623187511844774, 51.438200309433185],
              [0.621784163679018, 51.437792828484277],
              [0.622256568305244, 51.437046576258496],
              [0.6207786130432, 51.437320673301215],
              [0.620328748708456, 51.436953001323268],
              [0.619515942979381, 51.436881239148995],
              [0.618002117325338, 51.435647868203958],
              [0.618252493858303, 51.43504419586327],
              [0.618820656804054, 51.435141220822089],
              [0.619339954119149, 51.434535132504529],
              [0.617783013254512, 51.433577928592918],
              [0.618738806617626, 51.432352319859959],
              [0.617316268479641, 51.431680802324273],
              [0.617999104453277, 51.431091751550689],
              [0.616576585954444, 51.431451796813498],
              [0.616125327989217, 51.431284698679768],
              [0.615816329471492, 51.430610778364837],
              [0.614510079937245, 51.429656029119869],
              [0.615326701900046, 51.429721444278123],
              [0.614327964424166, 51.429263472898967],
              [0.613532569907367, 51.429771372162342],
              [0.61242020791923, 51.429641489225162],
              [0.612053201460065, 51.428756602675875],
              [0.612565855396987, 51.428058956910043],
              [0.612372186718245, 51.427387852220711],
              [0.612884004118448, 51.427432199653062],
              [0.613074103484154, 51.426981874877988],
              [0.612795396661872, 51.426434081675382],
              [0.61110992410295, 51.425940132507044],
              [0.611902051054374, 51.42540084418507],
              [0.610989165985976, 51.425613656153217],
              [0.610293930531385, 51.425102120030253],
              [0.610109057820831, 51.422615896904723],
              [0.608264727155555, 51.42162809807153],
              [0.605336190969288, 51.42105021398752],
              [0.605155906551311, 51.420561371205558],
              [0.605790384628115, 51.420083188521502],
              [0.609930501982188, 51.42142641703402],
              [0.612035057868117, 51.422940812783359],
              [0.612827305623635, 51.422732481344177],
              [0.61375729174699, 51.422971653895708],
              [0.613558118364319, 51.423691092974366],
              [0.614288269244737, 51.423932021109003],
              [0.613945548864927, 51.424178000032313],
              [0.613233351121534, 51.423999624893845],
              [0.613209938551991, 51.424244774540021],
              [0.615206896309316, 51.424148052316966],
              [0.615586905308593, 51.425033538811228],
              [0.615321820046258, 51.425329052331868],
              [0.616143348675505, 51.426287421922524],
              [0.61653900583559, 51.427848878917807],
              [0.617331435080139, 51.428120774903384],
              [0.617280129202743, 51.428431302831413],
              [0.61779473216993, 51.428448585717348],
              [0.618305768762744, 51.428000973898705],
              [0.618238731960012, 51.428614940135191],
              [0.619055686613107, 51.428510341582481],
              [0.618442678722505, 51.427979926865959],
              [0.619861188950254, 51.427778235831362],
              [0.619868136432047, 51.428000223915113],
              [0.620605517054989, 51.427712124305145],
              [0.620132367651874, 51.427514053552841],
              [0.621078587021447, 51.427632290747169],
              [0.621135484402721, 51.427093196736976],
              [0.622246370925811, 51.426871377259154],
              [0.623273469851254, 51.426141482203242],
              [0.623074457207787, 51.425706147631558],
              [0.622480357767037, 51.426108873218944],
              [0.622682897903473, 51.425450499721848],
              [0.622073227049312, 51.425304958094898],
              [0.622826089840751, 51.424961636037594],
              [0.622152728575425, 51.425060349738992],
              [0.623033195368628, 51.424684491179306],
              [0.623312647951668, 51.424088249405841],
              [0.623392725467626, 51.424280719645886],
              [0.624429408223694, 51.42389505667186],
              [0.6226767460192, 51.423409074310619],
              [0.622816580971007, 51.422811462165811],
              [0.621280700214365, 51.422693843253533],
              [0.620544722203633, 51.421798349759818],
              [0.615598691947554, 51.420479785155557],
              [0.613041748999344, 51.419305611414607],
              [0.610837537953199, 51.417403130207859],
              [0.606990193843791, 51.416548072026217],
              [0.603005518227878, 51.416159112841889],
              [0.602294846470851, 51.415726118145393],
              [0.600012077689406, 51.415900016226331],
              [0.59962393639901, 51.415574066265059],
              [0.598037557373966, 51.416234315843163],
              [0.597824047433299, 51.415944059161127],
              [0.597826381514478, 51.41626418101788],
              [0.597163388129696, 51.416392200763106],
              [0.594372622036488, 51.415241694250838],
              [0.591822967561188, 51.414740524570959],
              [0.587166357015324, 51.416137634498554],
              [0.586182634789854, 51.41579421533303],
              [0.585137603945463, 51.415932398466047],
              [0.585229288576259, 51.415620095339996],
              [0.584646338619122, 51.41576516421808],
              [0.584715446626192, 51.415537000276551],
              [0.584089861712578, 51.415538209742003],
              [0.583362112201597, 51.415997645228018],
              [0.581840187613058, 51.416019503935608],
              [0.580728610343491, 51.415742709759606],
              [0.58155299986908, 51.415670584875592],
              [0.579963415425065, 51.415563502786064],
              [0.579015999575147, 51.414753353397678],
              [0.578497892642579, 51.415948317264828],
              [0.576631144161774, 51.415746561453162],
              [0.575482781647316, 51.415454338296485],
              [0.573688364362833, 51.4134162502137],
              [0.572799034828129, 51.4130229780368],
              [0.572393836575762, 51.413055259453095],
              [0.572324454689477, 51.413561324115165],
              [0.571576682790877, 51.413050715646676],
              [0.571109945994164, 51.413267812915677],
              [0.571014077581103, 51.41284092206098],
              [0.570525718172569, 51.413185301317],
              [0.570985206407832, 51.413607810579343],
              [0.569613226394378, 51.413250287652183],
              [0.568983834489535, 51.413388202824855],
              [0.568910054754377, 51.413149691610549],
              [0.568335872707766, 51.413374529890312],
              [0.567257623046497, 51.412432251360364],
              [0.567735328268239, 51.412282381085937],
              [0.567229846097717, 51.412167548940751],
              [0.567241145730588, 51.411676250870748],
              [0.563886616888365, 51.411148942499047],
              [0.563504713438901, 51.409541151206255],
              [0.56216181506369, 51.409726118793415],
              [0.563072955445349, 51.40971518118333],
              [0.563154835615444, 51.410279086767645],
              [0.562495441666868, 51.410471585252232],
              [0.56199433629703, 51.409765755990442],
              [0.561207562790739, 51.409840518807357],
              [0.562570528164632, 51.410657009519831],
              [0.562516651108583, 51.411236475991338],
              [0.559502921075119, 51.411247498244045],
              [0.555096224416059, 51.412300610050409],
              [0.548934714791952, 51.412319714164262],
              [0.547044184908328, 51.412073038132696],
              [0.545707684040076, 51.412039133038633],
              [0.537892423727261, 51.413539743465279],
              [0.534956700626636, 51.413403646418026],
              [0.532356651799443, 51.4126531563846],
              [0.530221708333896, 51.411442887852033],
              [0.528817088416164, 51.4098354924513],
              [0.526325570471635, 51.405387981202139],
              [0.526044872066367, 51.404156522745353],
              [0.526492770136292, 51.40359555940514],
              [0.526090896575438, 51.402565471551185],
              [0.523519330799235, 51.400490326157545],
              [0.522706304584235, 51.400504240440618],
              [0.522883051803372, 51.399797138836604],
              [0.521204110484155, 51.399621038668542],
              [0.519039768431937, 51.400187427745152],
              [0.517410735090464, 51.401024670697225],
              [0.516291162002549, 51.401033436043214],
              [0.515566315599519, 51.400244082730495],
              [0.515818500961683, 51.400123549693333],
              [0.517786902041977, 51.40022965186801],
              [0.518218014255372, 51.400016231705074],
              [0.518115331050611, 51.399638911789943],
              [0.520709837466292, 51.399391548179629],
              [0.522957180950579, 51.398009424115934],
              [0.522188640358014, 51.396296515401218],
              [0.521049480136768, 51.395894743860822],
              [0.520666363064561, 51.395280635301205],
              [0.520542268809191, 51.394278726392905],
              [0.52091319613856, 51.393364194455984],
              [0.521294100467984, 51.393337109807646],
              [0.521121143023529, 51.39108075309796],
              [0.518929113675612, 51.386975589852952],
              [0.517478319964383, 51.387097615749063],
              [0.515022744212969, 51.388712546957294],
              [0.510912308752037, 51.394655525203653],
              [0.508681565283474, 51.396475945675604],
              [0.507087870248243, 51.396856316452237],
              [0.506621293920493, 51.397262915811254],
              [0.504908086556621, 51.396877767205353],
              [0.503646714480009, 51.397236594304886],
              [0.503618280658717, 51.396768639701826],
              [0.502297489643181, 51.396091769628839],
              [0.501501920139253, 51.395370393922263],
              [0.501566733762784, 51.394924730027512],
              [0.500301205928419, 51.394126144564325],
              [0.500651414496644, 51.393957684869122],
              [0.500433784912661, 51.393473083256964],
              [0.499662935295379, 51.392940031554261],
              [0.49824366314968, 51.39229939356553],
              [0.495824159901224, 51.392745786224445],
              [0.493831594628395, 51.393826974981984],
              [0.493522438294948, 51.393614114852845],
              [0.496081791935687, 51.392276231324487],
              [0.495016546300638, 51.391562357431035],
              [0.49401756718485, 51.391828255171667],
              [0.493045245177179, 51.39131563839107],
              [0.491924523130836, 51.391695628761504],
              [0.492584731893218, 51.391200429021154],
              [0.492544422658142, 51.390617608013493],
              [0.493133428708715, 51.390505244929315],
              [0.492308432351982, 51.39045445085317],
              [0.492680493235617, 51.390132660296011],
              [0.491592344898455, 51.389974142730509],
              [0.49026250387513, 51.388514896330229],
              [0.490467163794297, 51.386169534195666],
              [0.491664654246594, 51.384374142880731],
              [0.490915134848355, 51.382985297675809],
              [0.48975774046758, 51.381945073593073],
              [0.487224659864188, 51.381746174004832],
              [0.485669584041535, 51.382196485079916],
              [0.481322697771052, 51.381544890923116],
              [0.476253692144117, 51.379414585124955],
              [0.474502133921803, 51.377795001031217],
              [0.473934065404851, 51.376774547725049],
              [0.473225200640879, 51.376473846808679],
              [0.470329136383995, 51.376118566979351],
              [0.469942986687202, 51.376900151394985],
              [0.469587826512425, 51.376922929551753],
              [0.470156117069721, 51.376027784453505],
              [0.4666203870009, 51.375560892766742],
              [0.463198910005342, 51.373928634204518],
              [0.462813178772497, 51.374023982789872],
              [0.462734647155609, 51.373524696933366],
              [0.459302458018302, 51.371204549896078],
              [0.449850379577099, 51.366648696261116],
              [0.448531388015168, 51.36505835373255],
              [0.448143514881075, 51.363819966640463],
              [0.448546623610275, 51.361573961632139],
              [0.448191272100479, 51.359436445454335],
              [0.449515437677207, 51.357842989918979],
              [0.451598537836385, 51.356664420748388],
              [0.449485756236562, 51.35599454963566],
              [0.450589899924671, 51.356201682410649],
              [0.451580917451557, 51.355957901171728],
              [0.452105876818636, 51.356347120441029],
              [0.45345222720335, 51.355817983330226],
              [0.454785777528785, 51.353438236675075],
              [0.453819100009264, 51.353329889859197],
              [0.453829097419799, 51.353113836390889],
              [0.455132051014214, 51.353171964494273],
              [0.455893745012149, 51.351918486518215],
              [0.455703757010264, 51.351185902910409],
              [0.454793743168494, 51.350444132846036],
              [0.449745510160118, 51.349368106008072],
              [0.448554163530511, 51.348572816404229],
              [0.44793233985313, 51.347441844441661],
              [0.448506808430624, 51.34682277250694],
              [0.451088906525328, 51.345854112579993],
              [0.451914946210754, 51.345133526499005],
              [0.452083808870401, 51.344154194919184],
              [0.45158305658523, 51.342924475447269],
              [0.451368531997972, 51.342671752430483],
              [0.450810999777354, 51.34271849789814],
              [0.450713099106685, 51.342148559857208],
              [0.451763837560175, 51.342329816708158],
              [0.453456905560577, 51.340610767998882],
              [0.449614730990934, 51.34093943278743],
              [0.444392911436877, 51.342137672645983],
              [0.431261988832807, 51.345001174722007],
              [0.426625585327789, 51.34691402898175],
              [0.420856519105447, 51.347262772359706],
              [0.418565697394212, 51.347912670992443],
              [0.416610554213368, 51.347519551707876],
              [0.410974243518036, 51.348211314387036],
              [0.408686652951168, 51.347776342811834],
              [0.406685425982817, 51.347762629947376],
              [0.40706255863404, 51.346655880207159],
              [0.405401315513039, 51.34545521763075],
              [0.403934392831975, 51.344892674183463],
              [0.399890792710526, 51.344457595032893],
              [0.39731738313233, 51.345776582789213],
              [0.400936725631114, 51.349933837549926],
              [0.401254151274325, 51.352955414995805],
              [0.403428076177245, 51.356456025777163],
              [0.404138935017562, 51.356288546402112],
              [0.404704687766578, 51.356504464178869],
              [0.407165511279937, 51.359824643183615],
              [0.412509990841031, 51.363992841325569],
              [0.418403472346959, 51.372643479517812],
              [0.417876185790112, 51.373326174555068],
              [0.419165803691146, 51.374565818550394],
              [0.419648132988599, 51.375591897310166],
              [0.420907886371567, 51.376100075008203],
              [0.423384835214083, 51.37614598092383],
              [0.424936833319322, 51.376581517245739],
              [0.430157793863905, 51.38366625302546],
              [0.431116583648844, 51.38638035824323],
              [0.431080595085185, 51.388033196882638],
              [0.434612684857216, 51.38888076433048],
              [0.44711511632303, 51.388958394550556],
              [0.447333700823312, 51.390010557472749],
              [0.447921671759914, 51.390361606694697],
              [0.451973165548777, 51.391450513969815],
              [0.453293261018626, 51.391378805949998],
              [0.456680493726835, 51.394285548363172],
              [0.453886314809184, 51.397034054275927],
              [0.451824523158385, 51.398321018701083],
              [0.456688317601988, 51.398589648827027],
              [0.467658430583383, 51.398301517201944],
              [0.467686946521452, 51.398537444570138],
              [0.467928229458152, 51.399520743085269],
              [0.469424745606746, 51.399704149669304],
              [0.468182315520869, 51.403171231249615],
              [0.474607910056578, 51.406229253995228],
              [0.477587340418366, 51.407186139795591],
              [0.475974320585967, 51.408790500432382],
              [0.475477595585274, 51.408646309900554],
              [0.475111716155778, 51.412947517410657],
              [0.480875403105872, 51.414796976145588],
              [0.483650948305885, 51.412749714261281],
              [0.488254584122046, 51.414207000706504],
              [0.489207094356382, 51.41532624007214],
              [0.488531850619919, 51.419443405790567],
              [0.491167863292266, 51.424775373847105],
              [0.490491258041567, 51.425083843911118],
              [0.490127133380621, 51.425847933308667],
              [0.489590854778559, 51.425933075771951],
              [0.489182938801215, 51.427029951083362],
              [0.489357598213714, 51.430030954554155],
              [0.48845295575537, 51.430221945428237],
              [0.488335761763581, 51.430527513665673],
              [0.488827507576996, 51.434941847739154],
              [0.486328058213934, 51.436025557844175],
              [0.489730679991969, 51.437579325561302],
              [0.490944445390584, 51.438676811787381],
              [0.487888341647917, 51.440376744243949],
              [0.487931274092749, 51.443284310767822],
              [0.486061913261832, 51.443497557191925],
              [0.484335304053291, 51.444750085921108],
              [0.482759949287481, 51.444919273351317],
              [0.481096315580573, 51.445690169937109],
              [0.480164883826669, 51.445552501631823],
              [0.479858902641254, 51.446041024424957],
              [0.47888257467878, 51.446376450475825],
              [0.477292432854489, 51.446247295066001],
              [0.476819300389165, 51.446653006152879],
              [0.475735952389834, 51.446766731506656],
              [0.469771755949455, 51.450579874218654],
              [0.469598732019225, 51.451240937962588],
              [0.4687229679771, 51.451202731099521],
              [0.467566738925906, 51.454344192091725],
              [0.466024495570639, 51.454787651646001],
              [0.465578026053201, 51.454329389049043],
              [0.462086187146148, 51.454549390190579],
              [0.460705214692402, 51.455984958307283],
              [0.459358674038449, 51.456616678164458],
              [0.459037480093879, 51.45647952376833],
              [0.459272008010665, 51.455540190830867],
              [0.458815102424882, 51.455690074263472],
              [0.458904857862421, 51.455163877254662],
              [0.458489825461033, 51.455262517995095],
              [0.458595986052715, 51.456353885589543],
              [0.457187545317965, 51.457173940809064],
              [0.458605824702904, 51.459188390727974],
              [0.45742514456757, 51.460819368338825],
              [0.456203101512565, 51.461038342130763],
              [0.455192118432985, 51.46277545844179],
              [0.454281124129876, 51.463452852208533],
              [0.455865614681187, 51.465102320456538],
              [0.45832026390009, 51.466008655396607],
              [0.460105909914599, 51.465424393059095],
              [0.460486455733584, 51.464977528711678],
              [0.462565816378998, 51.465009403317083],
              [0.465916095302729, 51.46457922965439],
              [0.467017770206669, 51.464049719027287],
              [0.467588907939227, 51.464201375504892],
              [0.466767283380577, 51.465865358022121],
              [0.464288875044101, 51.46620438125327],
              [0.460136827404432, 51.466079360528731],
              [0.459462582840165, 51.466419969747299],
              [0.458309834936426, 51.466430662859608],
              [0.45784514444154, 51.466946734519475],
              [0.459199718106879, 51.470233274664352],
              [0.461462266917593, 51.472299222800523],
              [0.461126565470115, 51.473713732593573],
              [0.461939808358768, 51.475471043960724],
              [0.465947413682948, 51.479122644629463],
              [0.46686528582065, 51.479237332943249],
              [0.467227156337101, 51.480764883342985],
              [0.468713874158475, 51.482770578052282],
              [0.481461964858802, 51.487161777365031],
              [0.488076170698323, 51.487282388976453],
              [0.492731065521864, 51.486580042230401],
              [0.504233776352646, 51.485744911421378],
              [0.509376799539904, 51.485439772018296],
              [0.513725838084078, 51.485654167450797],
              [0.51497596580282, 51.485467270220227],
              [0.515964932947434, 51.484932523462518],
              [0.52184876028641, 51.484768317746905],
              [0.526037442429117, 51.484218564909476],
              [0.53063726286916, 51.485138309544176],
              [0.536482360218012, 51.485574068227947],
              [0.543883545199541, 51.486867929158606],
              [0.546977539142889, 51.48704446771513],
              [0.553607380353821, 51.484940548344653],
              [0.553933022911301, 51.484555727411042],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000036",
        LAD13CDO: "00MA",
        LAD13NM: "Bracknell Forest",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.760762262762476, 51.459103201092169],
            [-0.757345859411212, 51.459022987438338],
            [-0.752769101039853, 51.462225006945033],
            [-0.745910082142935, 51.458803234859957],
            [-0.740787997629426, 51.458829273851997],
            [-0.724543123074159, 51.456137741665295],
            [-0.723323352987609, 51.457709762368552],
            [-0.72041641727735, 51.459902705443319],
            [-0.714435142863573, 51.467142407069517],
            [-0.710492870284816, 51.466393251348585],
            [-0.710150103132988, 51.467470322927888],
            [-0.705726383350283, 51.467736305370849],
            [-0.688883520316413, 51.466958434116364],
            [-0.682305034203062, 51.463383197942484],
            [-0.67979375139064, 51.463008758775132],
            [-0.672763037558427, 51.457847981614165],
            [-0.668918383478749, 51.459681946429235],
            [-0.66503277734673, 51.462464881808479],
            [-0.662662349257015, 51.462547575986484],
            [-0.660988377401004, 51.46185227004348],
            [-0.656772548445449, 51.461492929569488],
            [-0.658480180498152, 51.452002454943283],
            [-0.658240903434435, 51.449314679723841],
            [-0.661999085527394, 51.444318457732017],
            [-0.65388527759874, 51.441366995677093],
            [-0.650815946402767, 51.441766034873865],
            [-0.641966441471265, 51.441004744905179],
            [-0.6305698031746, 51.442191329372051],
            [-0.637163404006663, 51.428003944899103],
            [-0.631246841273774, 51.422860746443888],
            [-0.632089350702529, 51.419209875307132],
            [-0.631309181694843, 51.416825040703117],
            [-0.631326777693496, 51.414454013284136],
            [-0.635339458037445, 51.414167637810266],
            [-0.635464259761568, 51.414395243493708],
            [-0.638436381164373, 51.4146941732645],
            [-0.640458691827369, 51.41579400673551],
            [-0.641088168183825, 51.417068299968854],
            [-0.643292039396358, 51.418939016569688],
            [-0.648923338757739, 51.421102821267276],
            [-0.653879226613865, 51.423950093505788],
            [-0.658004996682488, 51.423926358419727],
            [-0.663113335390941, 51.426015146167266],
            [-0.66796547698157, 51.430747176154547],
            [-0.676277580820068, 51.431582272113666],
            [-0.678994639592725, 51.429948480999606],
            [-0.67878999728634, 51.428465175648284],
            [-0.680820837195535, 51.425126796515933],
            [-0.684798279367643, 51.421629495482549],
            [-0.690100057333353, 51.418871629757824],
            [-0.6936845614387, 51.41501444869683],
            [-0.696134058940009, 51.413023890251395],
            [-0.696557969802892, 51.410910062731837],
            [-0.691770502433092, 51.406652013392232],
            [-0.68662842577638, 51.402959890312097],
            [-0.679450911025212, 51.393051726872834],
            [-0.673180897786309, 51.388740208536333],
            [-0.671405425639686, 51.385780554182773],
            [-0.667652329068866, 51.384570761926668],
            [-0.681499358218212, 51.379640497912291],
            [-0.696527515750422, 51.374549998587717],
            [-0.707917976655945, 51.371339151873535],
            [-0.71050264138892, 51.370669830654393],
            [-0.72431116450181, 51.368460528247304],
            [-0.731870375732386, 51.366431220609584],
            [-0.735275843504218, 51.365040071935184],
            [-0.735352819459911, 51.365009430192771],
            [-0.737742395383516, 51.362316867667289],
            [-0.739628322987913, 51.359125170432677],
            [-0.744343190786711, 51.354569108812804],
            [-0.748070140176413, 51.352348361003372],
            [-0.749768636115655, 51.349934117353058],
            [-0.75237404283911, 51.348539329811075],
            [-0.75410267139728, 51.34627101716552],
            [-0.756990432354224, 51.344245181914935],
            [-0.759452218000377, 51.343470072518187],
            [-0.758209705148056, 51.340568624740897],
            [-0.76039299771233, 51.340263531871848],
            [-0.761448590786943, 51.340409578695855],
            [-0.764679251293713, 51.337030260593913],
            [-0.768985704892314, 51.335485775164969],
            [-0.77281189291433, 51.332777910487472],
            [-0.775483533362038, 51.331958804010299],
            [-0.776605349844433, 51.333170979207239],
            [-0.778019624356017, 51.333373676174752],
            [-0.780156888346087, 51.334785239614583],
            [-0.782798650019292, 51.33569035205106],
            [-0.782682485420063, 51.335957111435427],
            [-0.783350768063686, 51.336362406192876],
            [-0.783336947215487, 51.337426934191676],
            [-0.784667980028989, 51.338232059269693],
            [-0.786300918746982, 51.33858799166795],
            [-0.787096284454024, 51.339185214712217],
            [-0.789080175945346, 51.339191349425889],
            [-0.789222200289731, 51.339576782107784],
            [-0.789994384261821, 51.339802369845607],
            [-0.793202905288804, 51.339973043540724],
            [-0.794242213431593, 51.341071794013629],
            [-0.795770844061137, 51.341031764932936],
            [-0.796540000248438, 51.341484779319316],
            [-0.798331175346104, 51.34178192097027],
            [-0.798918427471511, 51.341505589578176],
            [-0.801392902216968, 51.341476978211531],
            [-0.804168495696662, 51.341352474941907],
            [-0.804547232020627, 51.341639592337138],
            [-0.807052610498493, 51.341084237615242],
            [-0.809484419973981, 51.34103883510133],
            [-0.813131477773157, 51.341301540433506],
            [-0.814251480842746, 51.341629404054274],
            [-0.815114630720178, 51.342342220218029],
            [-0.81539915929828, 51.344170498931092],
            [-0.81398743659906, 51.345240667060651],
            [-0.814255356670345, 51.345708271609418],
            [-0.815793563646519, 51.346360470971085],
            [-0.816135063473233, 51.347149833376854],
            [-0.818784935771033, 51.347850087892368],
            [-0.818520280062654, 51.348202608054059],
            [-0.81972960569387, 51.34914188340619],
            [-0.820464144615663, 51.349000011307268],
            [-0.821353876236497, 51.349469361130147],
            [-0.824619879606609, 51.350005728029885],
            [-0.82555087561769, 51.350997901050413],
            [-0.826405398289503, 51.352286939089851],
            [-0.827848428292593, 51.352350840334459],
            [-0.828141226209662, 51.352863621293487],
            [-0.830442464957773, 51.352893807638644],
            [-0.830843482852151, 51.353331228985539],
            [-0.83207191013238, 51.352969408634351],
            [-0.836042899025753, 51.352618665852638],
            [-0.836545064238375, 51.352296340847303],
            [-0.83736629879719, 51.352870994527798],
            [-0.834392540196246, 51.354499592374715],
            [-0.83422633400225, 51.355389954720792],
            [-0.832489315469617, 51.357848183531907],
            [-0.829149448899343, 51.360972881132994],
            [-0.828293785656216, 51.362815799355388],
            [-0.826999124222439, 51.364015879307253],
            [-0.822108843975915, 51.366172584292769],
            [-0.819732220868468, 51.36720524142622],
            [-0.814549518122647, 51.369533133082719],
            [-0.813588265459389, 51.370318305681501],
            [-0.809606511774269, 51.370907372547769],
            [-0.788856575149175, 51.371750428286184],
            [-0.793141719331661, 51.384607466621702],
            [-0.795782373800443, 51.392353424411255],
            [-0.796847159619482, 51.394007209838961],
            [-0.797848981031784, 51.395653145435631],
            [-0.798972117231341, 51.400134610795824],
            [-0.800771188134591, 51.403846065745959],
            [-0.801491897378857, 51.405094339188246],
            [-0.801819047011547, 51.407539922355284],
            [-0.801989162758053, 51.409063114106466],
            [-0.801538730650079, 51.409133143760805],
            [-0.798099544461028, 51.409184255324178],
            [-0.794630808039704, 51.408554264412103],
            [-0.792522402539303, 51.408849085114689],
            [-0.793247676339395, 51.411393209043538],
            [-0.795756128931278, 51.413225505533887],
            [-0.796692957514055, 51.414458042394173],
            [-0.798708432336037, 51.419528636664012],
            [-0.798729329388666, 51.421419873353983],
            [-0.797901433079533, 51.424689877800482],
            [-0.798549613385167, 51.432827098928854],
            [-0.798067904308797, 51.435724780483746],
            [-0.7997271601407, 51.438945626999484],
            [-0.800036923856539, 51.441180610411664],
            [-0.800080300635333, 51.444851584351625],
            [-0.799364619222888, 51.446133709204773],
            [-0.797052136678361, 51.447613468230792],
            [-0.795968689513902, 51.449523036050664],
            [-0.792798796419398, 51.451416527652079],
            [-0.789939087565862, 51.452400448964696],
            [-0.788099710252865, 51.454306637165367],
            [-0.785721873759671, 51.457786213602027],
            [-0.783890826048343, 51.460772358091646],
            [-0.783784030642593, 51.464573955643765],
            [-0.782581933242694, 51.46868789672083],
            [-0.775669058557907, 51.468720239951125],
            [-0.775177062591852, 51.468383298999264],
            [-0.769658303152509, 51.467756340274853],
            [-0.770156243468993, 51.465192555286869],
            [-0.76981985967503, 51.463407715881239],
            [-0.764644938724307, 51.459720553857927],
            [-0.762958538737569, 51.459698280538667],
            [-0.760762262762476, 51.459103201092169],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000037",
        LAD13CDO: "00MB",
        LAD13NM: "West Berkshire",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.311782532316021, 51.557229855500275],
            [-1.310831819672938, 51.556952734206213],
            [-1.309400408207274, 51.557177202788132],
            [-1.307881555141446, 51.556794210590887],
            [-1.306101499407945, 51.556895197367162],
            [-1.30339297092006, 51.556587830979815],
            [-1.302763472866488, 51.556249609087772],
            [-1.302150111646242, 51.555140006558993],
            [-1.297470333735609, 51.553405500955222],
            [-1.297156827431937, 51.552877619578624],
            [-1.294209639938785, 51.553845409227165],
            [-1.289522850890497, 51.552311054482665],
            [-1.287578956057219, 51.552845059669792],
            [-1.286554619222202, 51.551572829716662],
            [-1.285539567367372, 51.548329700222475],
            [-1.283775536596824, 51.548621965318297],
            [-1.28034305232901, 51.546754107341151],
            [-1.270218201209698, 51.5446190422143],
            [-1.267911319562325, 51.54216706451804],
            [-1.266362630843078, 51.541180911013562],
            [-1.260674324450728, 51.537788665791354],
            [-1.256790801874561, 51.537032224964818],
            [-1.242291380628559, 51.537847666649121],
            [-1.235679402509205, 51.536796838723269],
            [-1.232536095179419, 51.53586368080677],
            [-1.229960565501796, 51.534612265725656],
            [-1.22681123348921, 51.535157132086248],
            [-1.22145691794734, 51.536150375398265],
            [-1.218878533059325, 51.5369361931713],
            [-1.216596785371329, 51.536226834032043],
            [-1.214964164452455, 51.536089143748278],
            [-1.210154051737351, 51.536440813005797],
            [-1.206148563923373, 51.537528747430038],
            [-1.204872585060579, 51.537609126902041],
            [-1.20325158262321, 51.536722351946104],
            [-1.20422134246149, 51.534274223262358],
            [-1.205457878684442, 51.533307011682957],
            [-1.205991325751205, 51.53164807357885],
            [-1.20502363782489, 51.530343134197594],
            [-1.204669813910289, 51.528393153544734],
            [-1.200118201788658, 51.528842371787341],
            [-1.190589407970629, 51.52998641257912],
            [-1.180090049657594, 51.532005881036497],
            [-1.175003790871224, 51.533446661514482],
            [-1.172780736774593, 51.533761905213758],
            [-1.165776107003015, 51.533962291645956],
            [-1.160818060248354, 51.534587829077751],
            [-1.15939464088546, 51.534923820155811],
            [-1.16017694989125, 51.536600064896156],
            [-1.156057386885911, 51.537865308304703],
            [-1.154671748076644, 51.538723028148524],
            [-1.15269408710326, 51.539390332521577],
            [-1.146957057548953, 51.540610299937235],
            [-1.140394774765581, 51.542909235396614],
            [-1.13633479946843, 51.539976032788182],
            [-1.134698928537919, 51.536516590375797],
            [-1.134466474593165, 51.53372027446688],
            [-1.134684329958677, 51.532799338382759],
            [-1.137752519366376, 51.528891672725202],
            [-1.13856649424648, 51.526855646930713],
            [-1.138915831724976, 51.526982295499707],
            [-1.143555919812801, 51.523452859401097],
            [-1.14405828985296, 51.522027750900712],
            [-1.143036575711086, 51.521402567321353],
            [-1.143268433968615, 51.52095197995726],
            [-1.142555919609701, 51.52082538850852],
            [-1.14328484636749, 51.519769696761621],
            [-1.1420736028769, 51.516193855192689],
            [-1.141083726256067, 51.515186740506891],
            [-1.138694694615651, 51.514173851313871],
            [-1.133956415830992, 51.511282320829949],
            [-1.127490707093586, 51.511880922572793],
            [-1.119306465746368, 51.511839561970184],
            [-1.116255275377909, 51.51104783204083],
            [-1.113207019341996, 51.509590659473282],
            [-1.109396199076225, 51.505424717135156],
            [-1.107290934993261, 51.50182192804688],
            [-1.106989625133978, 51.501094903585617],
            [-1.108051580180468, 51.500717237455341],
            [-1.104385174619776, 51.492329709830855],
            [-1.102797021891852, 51.490169452237637],
            [-1.100248600782349, 51.488874907887769],
            [-1.089921488616153, 51.486203830273674],
            [-1.089692139599838, 51.485787531648612],
            [-1.088382272787431, 51.485751284005929],
            [-1.082663976833434, 51.487077049440792],
            [-1.077290368907015, 51.487863945391233],
            [-1.073065792838521, 51.489510299039082],
            [-1.071168557625501, 51.489738969251349],
            [-1.059729068609978, 51.491786976044821],
            [-1.05544305880447, 51.492035785768493],
            [-1.051370389083018, 51.491858156900882],
            [-1.048697792831837, 51.490781782524948],
            [-1.046166816921752, 51.49017227128077],
            [-1.042218958653286, 51.488652868764873],
            [-1.03982424073761, 51.486639818463182],
            [-1.03968187818367, 51.48518918021324],
            [-1.037600972515802, 51.484017583408757],
            [-1.037458558133451, 51.481957298866448],
            [-1.038619150718844, 51.480181948954986],
            [-1.039094362618604, 51.476960491616268],
            [-1.03827943749047, 51.47599798652832],
            [-1.036577909454445, 51.475225116878867],
            [-1.036808911138999, 51.475058867589802],
            [-1.035939759228415, 51.474418705550825],
            [-1.036713004548241, 51.473391002740073],
            [-1.038561557114148, 51.472097864146683],
            [-1.040437159921118, 51.470539659935461],
            [-1.041491498631718, 51.470311796526033],
            [-1.042739435843825, 51.469431800694174],
            [-1.042478273514157, 51.469046618687017],
            [-1.043299965016234, 51.468444579467651],
            [-1.043026180758459, 51.468249921656721],
            [-1.043485601523164, 51.467208822842011],
            [-1.043877956199606, 51.467378370040457],
            [-1.044230676652926, 51.467033262403405],
            [-1.044336531023563, 51.465273531582731],
            [-1.044658362029037, 51.46502797984148],
            [-1.045534055885369, 51.465144809195607],
            [-1.047905649298557, 51.464629972051164],
            [-1.04786315191144, 51.464043362026906],
            [-1.048473896550761, 51.462432492615818],
            [-1.049795047865792, 51.4620997168212],
            [-1.052994814452528, 51.460368599287627],
            [-1.046740791284925, 51.456211377558233],
            [-1.047889098834877, 51.455254086063547],
            [-1.047095955781991, 51.454844811333352],
            [-1.048010586703186, 51.4537723228432],
            [-1.049220434756473, 51.453728186522902],
            [-1.049205256442083, 51.451409974333693],
            [-1.046811887561854, 51.450170356614336],
            [-1.038099398779799, 51.452784176656955],
            [-1.034003292717286, 51.452726230967961],
            [-1.03299532883397, 51.451798060718424],
            [-1.030634401380467, 51.449861512497939],
            [-1.030248287853749, 51.449202816063895],
            [-1.030130657461348, 51.447282986846453],
            [-1.02673799585348, 51.445905204992194],
            [-1.026002213460923, 51.445183345994288],
            [-1.026581847907698, 51.443976958351982],
            [-1.023470911106522, 51.444316162394699],
            [-1.021875758919806, 51.440515503751868],
            [-1.021829157723213, 51.436701671861321],
            [-1.021643224797581, 51.435707420142904],
            [-1.019583606695893, 51.435817901906375],
            [-1.015833116889002, 51.435346781897373],
            [-1.013580418004476, 51.435538262565224],
            [-1.01304044916129, 51.43455900134424],
            [-1.011940783487162, 51.434652247510833],
            [-1.011423276367508, 51.434300799805776],
            [-1.010298854455969, 51.434607828162498],
            [-1.009735139354174, 51.434198435015567],
            [-1.008120566539572, 51.43508397477504],
            [-1.007881609883974, 51.434426446387008],
            [-1.008500666671746, 51.434072907254112],
            [-1.007844936750097, 51.433794907226719],
            [-1.006979928572676, 51.434079820477848],
            [-1.007108989654897, 51.434430696915761],
            [-1.00643565105134, 51.433903465241734],
            [-1.003738511125549, 51.433894068057306],
            [-1.003838089095492, 51.43262256477302],
            [-1.002018079767425, 51.431577519281802],
            [-1.00074365023808, 51.429792568705487],
            [-1.001169002764392, 51.426381077938338],
            [-1.002788252527653, 51.426065758134847],
            [-0.999576023969016, 51.424899122452167],
            [-1.000336088837686, 51.422430139527556],
            [-0.999262554074878, 51.422524388098601],
            [-0.999225280680638, 51.421926109454482],
            [-0.99997974471435, 51.420173733560738],
            [-0.998037975779048, 51.419462082077331],
            [-0.998265012690215, 51.41749659586376],
            [-0.997902685343368, 51.417033114445644],
            [-1.000256729922718, 51.415782656898152],
            [-1.001417323266955, 51.414325070440846],
            [-1.000220246802369, 51.414290590023725],
            [-0.999790926966642, 51.412745716457181],
            [-0.99916332536041, 51.412249402524289],
            [-1.001125729688781, 51.409360855137038],
            [-1.002662416136245, 51.408649190702938],
            [-1.003884053410507, 51.407215477144135],
            [-1.003525387599975, 51.405129003790044],
            [-1.004042290896274, 51.403769323556681],
            [-1.005380093223125, 51.40240671756915],
            [-1.005449874066961, 51.401774278894479],
            [-1.006428905097454, 51.401307807865713],
            [-1.009120496456837, 51.398823644308116],
            [-1.009844836822927, 51.396566499200084],
            [-1.011905302577056, 51.392865726318114],
            [-1.007062366788211, 51.391436448989332],
            [-1.00104392255736, 51.389024929769342],
            [-0.996828017146499, 51.385965833473939],
            [-0.992655433868215, 51.38403544414502],
            [-0.990260588553492, 51.381523167528876],
            [-0.988751393958131, 51.380563303147404],
            [-0.985402696062187, 51.378579510757],
            [-0.983737251451225, 51.377157839427838],
            [-0.984256768478762, 51.376730728749692],
            [-0.981720907572478, 51.375626091143957],
            [-0.983627617984221, 51.373348790729693],
            [-0.983823444448179, 51.372225593304037],
            [-0.983455813436897, 51.371440103550732],
            [-0.985284021133979, 51.370688032420396],
            [-0.987696465913209, 51.371061380454243],
            [-0.988300747634517, 51.370172796550293],
            [-0.9891166187338, 51.37043880552946],
            [-0.989572874864718, 51.369812402944198],
            [-0.990111276464603, 51.369957318405049],
            [-0.990547149580267, 51.369734475898639],
            [-0.990252512376865, 51.369348879711254],
            [-0.990899645569951, 51.368533486551115],
            [-0.990485843631804, 51.367949041310041],
            [-0.991811216686287, 51.366664707217055],
            [-0.991765310050799, 51.365942256655259],
            [-0.990958552346038, 51.365460536743917],
            [-0.991458008961685, 51.365152814492539],
            [-0.990548088317548, 51.364006595716845],
            [-0.988028339317242, 51.36441198309231],
            [-0.986249009947969, 51.363679044389812],
            [-0.986143842847215, 51.362847275327738],
            [-0.987164181384551, 51.362562959655449],
            [-0.986895490214156, 51.362046295601218],
            [-0.987588117252861, 51.361966857543429],
            [-0.988865708935754, 51.362913953382304],
            [-0.99019033986028, 51.363226606491295],
            [-0.990787994662143, 51.362567247498525],
            [-0.992925303213569, 51.361452644555463],
            [-0.992892203111149, 51.362569164159154],
            [-0.994238935054272, 51.362266909984051],
            [-0.996337170361972, 51.362278604717559],
            [-0.999281558499118, 51.361558349976441],
            [-1.000242735374611, 51.362667171845338],
            [-1.00232542343844, 51.360287660129039],
            [-1.004705904507707, 51.36052640720817],
            [-1.008587447535312, 51.359429018259362],
            [-1.011142382995779, 51.359169156618115],
            [-1.022665954795122, 51.359072501887951],
            [-1.050035305993165, 51.358147492004292],
            [-1.050264717575604, 51.359972020113659],
            [-1.051544072904104, 51.36263230836348],
            [-1.053101665216745, 51.362324790611957],
            [-1.055415754178551, 51.363574462340999],
            [-1.055817978166829, 51.365039793176678],
            [-1.058547154171594, 51.365741539539258],
            [-1.059176429691702, 51.366296899437842],
            [-1.059170106153108, 51.367517054257654],
            [-1.060170637697218, 51.368183291498681],
            [-1.062137798383085, 51.367663130829364],
            [-1.063081664067748, 51.369067127048467],
            [-1.062613754418602, 51.36982140516151],
            [-1.065786442183911, 51.372326710918976],
            [-1.068955075707669, 51.373490302249905],
            [-1.069230578336919, 51.373223632076261],
            [-1.072539303437297, 51.37528293391145],
            [-1.077882545709442, 51.374567075601028],
            [-1.080010396565932, 51.374635051177691],
            [-1.075366726631376, 51.37949550369153],
            [-1.082799622650911, 51.381726347309503],
            [-1.086638673924011, 51.383915279703253],
            [-1.097536884128271, 51.381725645345462],
            [-1.097460590423431, 51.38136718061601],
            [-1.098818764013212, 51.380108874723291],
            [-1.100238870312079, 51.379425610487729],
            [-1.11377243978065, 51.374825060888526],
            [-1.115476654466693, 51.374214796233098],
            [-1.117533926239849, 51.368089737535534],
            [-1.11615761858637, 51.36645452963176],
            [-1.116437016873691, 51.3647742508007],
            [-1.115409734344149, 51.362549995962723],
            [-1.115810372236982, 51.360465996474879],
            [-1.117379058436313, 51.360003048169943],
            [-1.119262593358212, 51.359896726871057],
            [-1.119297343713603, 51.360256664520108],
            [-1.120190048392549, 51.360215712584086],
            [-1.120163709700544, 51.360541022045787],
            [-1.123763719646838, 51.361256774013484],
            [-1.124679358861552, 51.359711612927931],
            [-1.12478618263818, 51.358100161048959],
            [-1.131335159216191, 51.358028395353152],
            [-1.138609383689219, 51.357190990188812],
            [-1.144034029312463, 51.35725501511476],
            [-1.144150209154834, 51.357263057148131],
            [-1.146877177350095, 51.359368162028886],
            [-1.169641355629279, 51.360725784926437],
            [-1.176913841168781, 51.357324201919383],
            [-1.182338833039372, 51.360115491602699],
            [-1.188887913939465, 51.363220035639465],
            [-1.194816641065507, 51.363741981427388],
            [-1.199949476841891, 51.364691604491497],
            [-1.200623401723792, 51.365667324161578],
            [-1.202102461047402, 51.366189938681131],
            [-1.20496798198928, 51.36644949394568],
            [-1.206379625577977, 51.366222575063702],
            [-1.206644355480206, 51.366946413557372],
            [-1.209899605294097, 51.366683360947803],
            [-1.210790829164825, 51.367148849268695],
            [-1.21256310506704, 51.366079049227771],
            [-1.21357976982871, 51.366350235772238],
            [-1.213954972433124, 51.365745802400177],
            [-1.214846366528151, 51.36611864491892],
            [-1.216070945820788, 51.365907440635254],
            [-1.2167493970895, 51.36621769954899],
            [-1.218328336442049, 51.365863171196757],
            [-1.219676155114448, 51.366344224651201],
            [-1.220028096664136, 51.366851010159849],
            [-1.220776442252551, 51.366938712795275],
            [-1.220643094451962, 51.367516901397281],
            [-1.221045511383945, 51.367589712963706],
            [-1.221815024081645, 51.367785451791669],
            [-1.22103579600284, 51.36858594533237],
            [-1.222224829291398, 51.368694550282719],
            [-1.222006574520373, 51.369452013723041],
            [-1.222607674965816, 51.369754531376678],
            [-1.22391450768478, 51.369185015940886],
            [-1.225092831600548, 51.369503929998963],
            [-1.227044210362549, 51.368788475428225],
            [-1.226939920168861, 51.368498250179925],
            [-1.227417184271099, 51.368307173165597],
            [-1.228572567634715, 51.36828151337005],
            [-1.228947639309032, 51.367845179280209],
            [-1.229753596673268, 51.368011432521605],
            [-1.231474907613854, 51.36747332988174],
            [-1.232173963124851, 51.367675732046564],
            [-1.233332213333936, 51.36713301304929],
            [-1.234446331316265, 51.367598879367186],
            [-1.234846090311159, 51.367056583965741],
            [-1.235932109051508, 51.367571707828908],
            [-1.236400957706892, 51.367019966501289],
            [-1.236896594091076, 51.36718954358512],
            [-1.2380588705185, 51.366832935353877],
            [-1.238704753700122, 51.367211192976406],
            [-1.238728153822958, 51.366752760964943],
            [-1.240054292658628, 51.366561747885612],
            [-1.240160292594241, 51.366232434463491],
            [-1.241598343906716, 51.366221966260341],
            [-1.242052469189545, 51.366905586290187],
            [-1.243195325878713, 51.367114388429769],
            [-1.243206469314583, 51.367832908422457],
            [-1.243949971940149, 51.36743307390087],
            [-1.24477043995728, 51.367418581363978],
            [-1.245233138907968, 51.368108538320051],
            [-1.246166263434852, 51.368407677330559],
            [-1.246468160293619, 51.369531799787119],
            [-1.247262110613729, 51.369472160063125],
            [-1.247855622786628, 51.369809567210666],
            [-1.247103629389561, 51.370201279177444],
            [-1.247008432687474, 51.371100750213841],
            [-1.247846082661139, 51.370742858356067],
            [-1.248575372393155, 51.371826555802741],
            [-1.250390161525709, 51.371629565414409],
            [-1.251159222878616, 51.372040911955999],
            [-1.251300642615693, 51.371826011437932],
            [-1.252996970246034, 51.372022066090018],
            [-1.253892828577255, 51.371439706341057],
            [-1.254543308128629, 51.372077769001237],
            [-1.256590242146718, 51.371827308637158],
            [-1.257400154325113, 51.372114785936482],
            [-1.259211418606091, 51.371422186997584],
            [-1.259090867965978, 51.370167964218183],
            [-1.260595733323823, 51.369584006205201],
            [-1.262206779126239, 51.368379361847957],
            [-1.26283112137884, 51.368591900600514],
            [-1.263014621179811, 51.370136951490281],
            [-1.263814403248986, 51.370160860289197],
            [-1.264259701563631, 51.370611449207573],
            [-1.265463170310512, 51.370815018905539],
            [-1.267827031751648, 51.370321770447298],
            [-1.26982297700087, 51.370377381857381],
            [-1.269682870659361, 51.369431468201867],
            [-1.27069660780684, 51.368827235151436],
            [-1.270948465980027, 51.368435859157145],
            [-1.273429417032835, 51.367521509447322],
            [-1.276036491611865, 51.367347913681584],
            [-1.276686081943955, 51.368149498997788],
            [-1.27735423652172, 51.368046617555699],
            [-1.278261509047828, 51.368815613433966],
            [-1.280008466074083, 51.368347992357251],
            [-1.281453536157654, 51.368351464861654],
            [-1.282210869285674, 51.367964960821872],
            [-1.282777700504194, 51.368094316139036],
            [-1.282960067462863, 51.367638647335319],
            [-1.283977028538504, 51.36764306577237],
            [-1.284613585476038, 51.367999432028554],
            [-1.287026293083158, 51.367853179141029],
            [-1.286839183428581, 51.368246781758096],
            [-1.287823986982721, 51.368287837571259],
            [-1.288208395469304, 51.368788319335344],
            [-1.289923390610438, 51.36954504209104],
            [-1.290021608888937, 51.37006806156684],
            [-1.291661207141961, 51.370036618897011],
            [-1.292061658110752, 51.370431980465263],
            [-1.292661981489856, 51.370349283245339],
            [-1.293306189348209, 51.371055429580416],
            [-1.296050261037804, 51.370574694197117],
            [-1.296088883899587, 51.370304272584356],
            [-1.296792327876056, 51.370428985127489],
            [-1.2967635163327, 51.370715651082463],
            [-1.298214587404306, 51.370519334480235],
            [-1.300889415390564, 51.370995699118168],
            [-1.303392946682252, 51.370835263807955],
            [-1.303942197765362, 51.371182912926734],
            [-1.307223744944387, 51.371253606731713],
            [-1.309341531878862, 51.371714795188502],
            [-1.310923170741848, 51.372197971736639],
            [-1.311356564497903, 51.372803868653037],
            [-1.312364088702891, 51.373062452994688],
            [-1.31459448665597, 51.37272573471207],
            [-1.31515939256638, 51.373090506864429],
            [-1.318714312067624, 51.372950268856897],
            [-1.3212730466688, 51.372459773268332],
            [-1.322417141355698, 51.371644541785315],
            [-1.324229180312896, 51.372100096273549],
            [-1.324742515938241, 51.372557140318719],
            [-1.32646639249995, 51.371681366141566],
            [-1.327619188282677, 51.37144160908371],
            [-1.328251986954976, 51.371786925472669],
            [-1.329266748611418, 51.371161512177764],
            [-1.330028996361051, 51.371299849094456],
            [-1.332723267775275, 51.370463694150608],
            [-1.333358688222582, 51.37053115136527],
            [-1.334219224236602, 51.369439042893703],
            [-1.336551367628963, 51.369294909453934],
            [-1.336774592746334, 51.369056990863285],
            [-1.337336797091865, 51.369418943219522],
            [-1.338410640583997, 51.368568989881751],
            [-1.340815916606283, 51.369030332328869],
            [-1.341219226106976, 51.368554236091661],
            [-1.342944950960816, 51.369123211909105],
            [-1.343946437376119, 51.369107241042521],
            [-1.345418190795257, 51.368852908566168],
            [-1.345844277567432, 51.368287005731354],
            [-1.347130719372516, 51.368131426396801],
            [-1.348763858772757, 51.367230541433521],
            [-1.350470551514437, 51.367218430750974],
            [-1.351851092903877, 51.367822228498426],
            [-1.35289753282084, 51.367264224562028],
            [-1.354779201896858, 51.367476013424934],
            [-1.355479356514505, 51.36794564016347],
            [-1.357066843055039, 51.36793367701452],
            [-1.358463447911487, 51.367498033617309],
            [-1.359143719785719, 51.367748129859791],
            [-1.359604322217649, 51.367475498342813],
            [-1.360579801015161, 51.367880059096549],
            [-1.361772718342807, 51.3678344079174],
            [-1.362686136545566, 51.368365397362894],
            [-1.363291041715878, 51.36825179137255],
            [-1.363802699152544, 51.368647510612],
            [-1.36522244584394, 51.368405235823033],
            [-1.366290190181809, 51.368582756227632],
            [-1.368656098694849, 51.367957105948271],
            [-1.371919217544694, 51.368419729020687],
            [-1.373595274200307, 51.367812760258381],
            [-1.376164069677046, 51.368314713211227],
            [-1.37788648084724, 51.367780763303685],
            [-1.378783504003862, 51.368156881219171],
            [-1.380481031833379, 51.36829354835028],
            [-1.380842609698944, 51.368644339886451],
            [-1.38275383211805, 51.369129185284045],
            [-1.385265348869483, 51.369415733121805],
            [-1.387852589117224, 51.370274499525912],
            [-1.390806359229402, 51.370583918065883],
            [-1.393531556523621, 51.370473063079068],
            [-1.396625579739684, 51.371264120148339],
            [-1.399581783210214, 51.371616490203571],
            [-1.401440343473861, 51.371536976269617],
            [-1.401956857075443, 51.372139363456562],
            [-1.406131826050553, 51.372237917246672],
            [-1.407119865523763, 51.372710490285634],
            [-1.409483856419646, 51.372318692060418],
            [-1.411084563983938, 51.372854561019395],
            [-1.415646726473742, 51.371885599441207],
            [-1.417584065534215, 51.370690344170569],
            [-1.419088720027351, 51.366670397242764],
            [-1.421602246186284, 51.364408807212698],
            [-1.42236577792858, 51.362858793548547],
            [-1.426745185288719, 51.360091942614481],
            [-1.427283210718872, 51.358984987068162],
            [-1.427649635580655, 51.359084787225228],
            [-1.427770740008898, 51.357663777986005],
            [-1.428454946457051, 51.356692406986724],
            [-1.432247307496271, 51.355799969744538],
            [-1.440528156741081, 51.355541259904122],
            [-1.444125779072173, 51.354930749969249],
            [-1.443628785070941, 51.351974591373441],
            [-1.444238993135195, 51.349131585679878],
            [-1.444951916976132, 51.348120690028296],
            [-1.441184446331559, 51.346566091014637],
            [-1.436747882713368, 51.343159442492095],
            [-1.433477902354384, 51.341133114834328],
            [-1.433136893616513, 51.340146863240918],
            [-1.429708971490475, 51.336532609813567],
            [-1.450819557670596, 51.332677037919481],
            [-1.453598345507539, 51.332806919989622],
            [-1.4556287207864, 51.33250165524511],
            [-1.45822392679741, 51.332984852000024],
            [-1.460651845405507, 51.33243765930051],
            [-1.465537054505941, 51.332382710089192],
            [-1.484414304874589, 51.331039378292168],
            [-1.488456787774182, 51.330469028611176],
            [-1.490718218096618, 51.329746944634628],
            [-1.495408718027054, 51.328963366093433],
            [-1.497013628544291, 51.329199555996674],
            [-1.498050322401199, 51.329028660511021],
            [-1.498313464094772, 51.329378669173103],
            [-1.497652080967329, 51.331193079149209],
            [-1.497552329464234, 51.333229293838542],
            [-1.493038614928116, 51.337992591823209],
            [-1.490170194635408, 51.342024656071573],
            [-1.486570024256749, 51.345323312452607],
            [-1.485726192567769, 51.347725813401873],
            [-1.486827137960854, 51.349429185174742],
            [-1.489421090058322, 51.350399035931083],
            [-1.491372875386501, 51.352053914630211],
            [-1.495035428193743, 51.352765712559204],
            [-1.497423595549865, 51.354595909394533],
            [-1.500072466730806, 51.355581044100738],
            [-1.500623616427944, 51.356270363861015],
            [-1.499673204971667, 51.358485477333815],
            [-1.500038306773828, 51.360700805240839],
            [-1.499352187264683, 51.36145408347447],
            [-1.499327978844769, 51.363280204798968],
            [-1.495166911950261, 51.367724964065992],
            [-1.495464481766803, 51.369666663203304],
            [-1.499400362242362, 51.369028037700026],
            [-1.502553895157066, 51.368859827822114],
            [-1.504025857617706, 51.369833575457413],
            [-1.507020821144263, 51.37052328528867],
            [-1.507115906230002, 51.370899539655866],
            [-1.509449737481408, 51.37109006201446],
            [-1.510034337259824, 51.371906258588517],
            [-1.512336752770232, 51.371814251607489],
            [-1.51342035539768, 51.372047143891962],
            [-1.513772014132998, 51.37519750665232],
            [-1.514616323917328, 51.376873466136431],
            [-1.515450780067942, 51.377582768066794],
            [-1.516066535258489, 51.377641959079838],
            [-1.516975518192228, 51.378782261043369],
            [-1.518153522035507, 51.379073944050134],
            [-1.520685959149611, 51.379277646097279],
            [-1.523250360286519, 51.378619117514788],
            [-1.524253251452288, 51.378064805365959],
            [-1.525611189312051, 51.378048727385],
            [-1.525785955277406, 51.378377632122785],
            [-1.527880828980875, 51.378935480179663],
            [-1.528263101140577, 51.379207669523623],
            [-1.526904828349246, 51.379950307659534],
            [-1.52888583145295, 51.381391563476022],
            [-1.533315877947872, 51.383362279219156],
            [-1.534135587447528, 51.384148716707692],
            [-1.534682671237982, 51.384330723203099],
            [-1.535679611896581, 51.384079310760598],
            [-1.53834634163526, 51.385539303437128],
            [-1.538694716801543, 51.387051280676388],
            [-1.537731989864418, 51.388324310861108],
            [-1.542345084713656, 51.389626420678333],
            [-1.542638597824338, 51.390458398611436],
            [-1.544283019808305, 51.390140200672079],
            [-1.546483667333164, 51.39054256867248],
            [-1.546521620143574, 51.391065132886403],
            [-1.54734547842881, 51.391176216329612],
            [-1.547125123524401, 51.393262337213066],
            [-1.548768872877665, 51.393019603984939],
            [-1.549023807235331, 51.394720013879535],
            [-1.55333124793067, 51.394740096627821],
            [-1.555617015247533, 51.395585906638068],
            [-1.556248315294193, 51.396446103829092],
            [-1.556267124409785, 51.397473924274038],
            [-1.556145966601273, 51.39840860056286],
            [-1.555499774732972, 51.39908322652488],
            [-1.553575511469478, 51.401545031837799],
            [-1.554166191208275, 51.403483186333254],
            [-1.553703827169402, 51.406678865700215],
            [-1.554312734280084, 51.407636096308572],
            [-1.553362850374589, 51.40847590097308],
            [-1.55302664950499, 51.410075136360369],
            [-1.554345708755224, 51.410309443393594],
            [-1.555642697039226, 51.40940980331181],
            [-1.55784848823381, 51.409086347495787],
            [-1.559951081229318, 51.408126752418809],
            [-1.560569698195816, 51.408236072685426],
            [-1.560559460603179, 51.408554339213623],
            [-1.562289207388041, 51.408238906060568],
            [-1.562491766521726, 51.411270750741309],
            [-1.563505484271535, 51.413230213183958],
            [-1.565431600552101, 51.41330030717598],
            [-1.565806187209023, 51.413830403746651],
            [-1.566872522157079, 51.413893691331303],
            [-1.566810523430715, 51.414368221641794],
            [-1.569630056214972, 51.414701401599288],
            [-1.569702054902812, 51.415150349149108],
            [-1.570934331858671, 51.415309519881703],
            [-1.571179476722752, 51.416465843323493],
            [-1.567504731208037, 51.420500389828263],
            [-1.565133001747548, 51.422044477299536],
            [-1.563405511302847, 51.421013012731635],
            [-1.56033237456848, 51.420395496707407],
            [-1.558245953340591, 51.420514439483625],
            [-1.558476354557236, 51.421677926643184],
            [-1.556301183473072, 51.421598684582008],
            [-1.553677304282073, 51.422406969222237],
            [-1.553127422706599, 51.421277323279504],
            [-1.549456480135817, 51.422381840634095],
            [-1.545978150037504, 51.422753270575164],
            [-1.544430021831355, 51.423540330901389],
            [-1.543537672179409, 51.424761523497878],
            [-1.543048723771013, 51.424611257575002],
            [-1.541918349394314, 51.425465550348456],
            [-1.539869175087883, 51.425714690596251],
            [-1.539639363620118, 51.426113017769325],
            [-1.532859906402674, 51.425621345633452],
            [-1.5321175667872, 51.424793852906355],
            [-1.531701349451145, 51.425130277756807],
            [-1.526638082927893, 51.423930248781645],
            [-1.525303633198523, 51.425019139251596],
            [-1.526969857753732, 51.425786564160767],
            [-1.527341568818022, 51.426562243310308],
            [-1.527907124295222, 51.426812690353543],
            [-1.528280726399036, 51.427966022955424],
            [-1.532077581512429, 51.429556566412401],
            [-1.52400080224712, 51.441339072818295],
            [-1.524453772649709, 51.442887468909092],
            [-1.524006047271336, 51.447481268592782],
            [-1.524918186576781, 51.450272365506969],
            [-1.528864674154921, 51.45399282208863],
            [-1.529043337402388, 51.455512220846899],
            [-1.534612657581128, 51.456126092339417],
            [-1.538755364514875, 51.457356317500114],
            [-1.542195631214601, 51.457750136330127],
            [-1.545770041614695, 51.458991381348945],
            [-1.548404876970078, 51.46065600461435],
            [-1.549625838285154, 51.462228830108316],
            [-1.550955612755575, 51.465484383406171],
            [-1.557393232975754, 51.468014795680098],
            [-1.559540391727686, 51.46973846699305],
            [-1.56072887314813, 51.47221020827093],
            [-1.56115802122088, 51.475803044102349],
            [-1.561597427151423, 51.476378348021804],
            [-1.562743735389888, 51.476813320628004],
            [-1.566844888113488, 51.476930135140037],
            [-1.566895964594739, 51.478531719670976],
            [-1.567656820200367, 51.479843695413187],
            [-1.567838329871402, 51.481513195777126],
            [-1.570463470606177, 51.486273966079722],
            [-1.573619979223527, 51.487243075625535],
            [-1.576789516242289, 51.489309114234487],
            [-1.579345925603932, 51.491199333569384],
            [-1.582305168579057, 51.494111468948127],
            [-1.583420126182804, 51.495844501819462],
            [-1.582281324581014, 51.495775715352337],
            [-1.583535819868931, 51.497692670127343],
            [-1.582659017210996, 51.498112157164499],
            [-1.582076229006408, 51.499460609639193],
            [-1.582285199605306, 51.500240018934853],
            [-1.582629385089803, 51.500885035900815],
            [-1.58577901172676, 51.50211724100761],
            [-1.58567797132922, 51.502933313718131],
            [-1.586093445913984, 51.503031887876176],
            [-1.587026088255087, 51.507123611722967],
            [-1.587288095298037, 51.511138339673025],
            [-1.588078058511777, 51.513502281498532],
            [-1.587101083711969, 51.516047937468841],
            [-1.585432534104965, 51.523641660609755],
            [-1.584721927058816, 51.524911443368516],
            [-1.584504244399608, 51.52523166890952],
            [-1.585154700616433, 51.525666459277566],
            [-1.583354694471035, 51.528315267552479],
            [-1.581531039488917, 51.534466139446749],
            [-1.581200769285474, 51.536994260231403],
            [-1.576696191264404, 51.539061427103221],
            [-1.569373558422307, 51.543282362269061],
            [-1.570271966401198, 51.544655951285883],
            [-1.569883087476666, 51.547513808360435],
            [-1.566114627406866, 51.545561386674621],
            [-1.561065160904634, 51.550293700218859],
            [-1.556384784481739, 51.553005013979075],
            [-1.555022576952867, 51.553370307031756],
            [-1.547379514766552, 51.55310551777734],
            [-1.54708927095536, 51.551396023768056],
            [-1.542969567707118, 51.552324162521856],
            [-1.542119486970228, 51.552238129275167],
            [-1.541041774639416, 51.551840995338502],
            [-1.540257492519027, 51.550951370149193],
            [-1.540287811455477, 51.549516453210458],
            [-1.534798840533987, 51.548384388964188],
            [-1.532327668078409, 51.547192195123024],
            [-1.530973524630318, 51.546069154982511],
            [-1.530198927058277, 51.546929238207085],
            [-1.5307334922619, 51.548095769855998],
            [-1.521321532613672, 51.550895478756438],
            [-1.516694665440648, 51.551184931118399],
            [-1.513755253364476, 51.550925529568339],
            [-1.508958217151681, 51.549064113821728],
            [-1.504755852388924, 51.546659228696534],
            [-1.501243949490925, 51.546694714283511],
            [-1.496669219143518, 51.545879445698475],
            [-1.489352484935771, 51.542689143845301],
            [-1.486046864950205, 51.540566216658178],
            [-1.487598390500459, 51.53889338976245],
            [-1.488031824175226, 51.53624369142323],
            [-1.485871914577162, 51.534078991481998],
            [-1.477086705295166, 51.532647403698221],
            [-1.476378899596101, 51.531763081744799],
            [-1.476423075614023, 51.530802087458426],
            [-1.470726404054221, 51.530009565094112],
            [-1.470759956877936, 51.528607041644825],
            [-1.463003185264052, 51.530191183081115],
            [-1.464814720721238, 51.534787819685647],
            [-1.464802172808167, 51.537949172413349],
            [-1.464437942243452, 51.538516672804285],
            [-1.463221958802168, 51.537210948941905],
            [-1.461856556739718, 51.536478184704471],
            [-1.456771992883516, 51.53545850754054],
            [-1.454943373194664, 51.535378099127556],
            [-1.4478473908477, 51.536426614121702],
            [-1.439664249142239, 51.539565434851518],
            [-1.437149916854693, 51.540008376206458],
            [-1.431008500703443, 51.542584505940248],
            [-1.426607217198319, 51.545598620094921],
            [-1.425663115521902, 51.54200999395259],
            [-1.422784632160388, 51.535764761691198],
            [-1.419352831257046, 51.537724170406598],
            [-1.418039249395005, 51.538974678406539],
            [-1.417506302649066, 51.538819179411036],
            [-1.416226855810298, 51.539649033874305],
            [-1.413803553372551, 51.542079950025041],
            [-1.409900883840093, 51.544701201562752],
            [-1.404874047906695, 51.547234806753238],
            [-1.404343897200468, 51.546308690810989],
            [-1.400303242901027, 51.544764957951713],
            [-1.39823141533476, 51.543422713400709],
            [-1.395866896743873, 51.540344462981565],
            [-1.392475925835506, 51.540423171427214],
            [-1.388920111367194, 51.539889500730638],
            [-1.386577553833997, 51.539958206197397],
            [-1.383795596019372, 51.540425580857267],
            [-1.382174631212273, 51.541190331115921],
            [-1.376977278036622, 51.545778206604901],
            [-1.377968082214821, 51.549685776029705],
            [-1.372843240363766, 51.550575642966244],
            [-1.369750201652049, 51.551997719335525],
            [-1.367337993298329, 51.552465789910627],
            [-1.365527570677242, 51.551142359949871],
            [-1.363193493210192, 51.550042648199138],
            [-1.361188809641082, 51.547982590429505],
            [-1.358798337880504, 51.546604645306992],
            [-1.35045588841641, 51.546898660075826],
            [-1.349372213970944, 51.546336982697738],
            [-1.346721229294428, 51.543947597574217],
            [-1.344005450835164, 51.543376775635309],
            [-1.342621677248656, 51.543638780246575],
            [-1.3379690183776, 51.545775994213713],
            [-1.338317379531906, 51.547509726443984],
            [-1.338088011017387, 51.549304040727549],
            [-1.337019357978212, 51.550891307882097],
            [-1.336460374231314, 51.552833015322584],
            [-1.334802297026896, 51.554871000150897],
            [-1.333151082820674, 51.560376126667585],
            [-1.328738303903391, 51.563711104747476],
            [-1.326315069331563, 51.559580911830565],
            [-1.320424738511753, 51.558938237069789],
            [-1.315324666549735, 51.557264054750341],
            [-1.311782532316021, 51.557229855500275],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000038",
        LAD13CDO: "00MC",
        LAD13NM: "Reading",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.956019624012198, 51.4910429652872],
            [-0.953923439549989, 51.488606392799937],
            [-0.949831737755129, 51.486464848511496],
            [-0.94919454910832, 51.486992357362361],
            [-0.947031140275642, 51.486060287599059],
            [-0.948055986239532, 51.485209864204577],
            [-0.946085008940149, 51.483431572489621],
            [-0.945511034846576, 51.483590964558296],
            [-0.944520620035673, 51.481950938802633],
            [-0.945587931567155, 51.481692581220479],
            [-0.944932083374898, 51.479222022177453],
            [-0.941156736700879, 51.474976224047268],
            [-0.947343241194033, 51.472940406249506],
            [-0.946775160359774, 51.471724104956841],
            [-0.946549604764426, 51.47151526685564],
            [-0.949242111957417, 51.470130396832516],
            [-0.947201433971118, 51.466652032976874],
            [-0.94926610905891, 51.466139129735218],
            [-0.948900484810322, 51.465510919780634],
            [-0.949460871081266, 51.464929671758576],
            [-0.951580966456756, 51.465700361738001],
            [-0.955204171654776, 51.465332556766107],
            [-0.95482606913917, 51.464554090373049],
            [-0.954383352477155, 51.464524967064186],
            [-0.954413700960461, 51.462629754971474],
            [-0.954566039627771, 51.462199503969558],
            [-0.955182339411334, 51.46219060903482],
            [-0.955472868668262, 51.461255346397785],
            [-0.949187951866246, 51.459512329595164],
            [-0.95003037697219, 51.457864472667303],
            [-0.943624488355568, 51.456465359137553],
            [-0.937869325726761, 51.454020638851851],
            [-0.935005350728995, 51.45191394355227],
            [-0.931567116484567, 51.447662775334209],
            [-0.928494345609075, 51.445161067957471],
            [-0.93073571187557, 51.442224991141124],
            [-0.932408396769064, 51.440958886136698],
            [-0.933933112524687, 51.441904318797],
            [-0.934831332017967, 51.443001400225235],
            [-0.937410030639932, 51.444417640305986],
            [-0.941807371440367, 51.445974336323957],
            [-0.944248712569569, 51.446311956642582],
            [-0.945234831729918, 51.44616886777122],
            [-0.944818961928608, 51.445544686289431],
            [-0.944646690172009, 51.442904915973095],
            [-0.946323375943042, 51.442324730983103],
            [-0.946642095436017, 51.441041754074341],
            [-0.947907164296343, 51.441089079008627],
            [-0.94815431169656, 51.439286623474771],
            [-0.94643351648283, 51.439163272069663],
            [-0.94641819142966, 51.438140755853333],
            [-0.943726669499814, 51.438438451055369],
            [-0.943537661274807, 51.436153705037981],
            [-0.943974148638742, 51.430075512165104],
            [-0.9434528089482, 51.429291216825789],
            [-0.946227668166386, 51.427097884103418],
            [-0.949165502487476, 51.423807134536361],
            [-0.951232782855732, 51.423157545601981],
            [-0.957171627025758, 51.419857434391581],
            [-0.956938701118582, 51.419475903437764],
            [-0.96031017170692, 51.415080027792875],
            [-0.959899909364671, 51.414275208424051],
            [-0.962147920444863, 51.413937252415238],
            [-0.962055941793743, 51.412283722536763],
            [-0.964750948047947, 51.411758145545406],
            [-0.97048457028207, 51.410252135244164],
            [-0.973721668590305, 51.409779668156311],
            [-0.973659704584899, 51.412547737673961],
            [-0.978790256497919, 51.412480142178929],
            [-0.979360136906099, 51.412523770129795],
            [-0.979914085986482, 51.41302494506089],
            [-0.980163698505825, 51.414150206489367],
            [-0.979813903477403, 51.414554496677859],
            [-0.981017432182701, 51.417209483567284],
            [-0.981961719333127, 51.418139357199067],
            [-0.983389632106304, 51.418857611869569],
            [-0.981362922413747, 51.420623114687153],
            [-0.984479453023543, 51.42133623229558],
            [-0.984813269690466, 51.421978447075027],
            [-0.987727333600541, 51.420795132176643],
            [-0.991463624620656, 51.420472151703592],
            [-0.993181432304826, 51.419460940704454],
            [-0.996290660344564, 51.419592805072348],
            [-0.996636336226557, 51.421671092343196],
            [-0.998668797858126, 51.424817644542941],
            [-0.999408892966403, 51.4248464425458],
            [-1.001290762831582, 51.425678050912111],
            [-1.001169002764392, 51.426381077938338],
            [-1.00074365023808, 51.429792568705487],
            [-1.002018079767425, 51.431577519281802],
            [-1.003838089095492, 51.43262256477302],
            [-1.003738511125549, 51.433894068057306],
            [-1.00643565105134, 51.433903465241734],
            [-1.007108989654897, 51.434430696915761],
            [-1.006979928572676, 51.434079820477848],
            [-1.007844936750097, 51.433794907226719],
            [-1.008500666671746, 51.434072907254112],
            [-1.007881609883974, 51.434426446387008],
            [-1.008120566539572, 51.43508397477504],
            [-1.009735139354174, 51.434198435015567],
            [-1.010298854455969, 51.434607828162498],
            [-1.011423276367508, 51.434300799805776],
            [-1.011940783487162, 51.434652247510833],
            [-1.01304044916129, 51.43455900134424],
            [-1.013580418004476, 51.435538262565224],
            [-1.015833116889002, 51.435346781897373],
            [-1.019583606695893, 51.435817901906375],
            [-1.021643224797581, 51.435707420142904],
            [-1.021829157723213, 51.436701671861321],
            [-1.021875758919806, 51.440515503751868],
            [-1.023470911106522, 51.444316162394699],
            [-1.026581847907698, 51.443976958351982],
            [-1.026002213460923, 51.445183345994288],
            [-1.02673799585348, 51.445905204992194],
            [-1.030130657461348, 51.447282986846453],
            [-1.030248287853749, 51.449202816063895],
            [-1.030634401380467, 51.449861512497939],
            [-1.03299532883397, 51.451798060718424],
            [-1.034003292717286, 51.452726230967961],
            [-1.038099398779799, 51.452784176656955],
            [-1.046811887561854, 51.450170356614336],
            [-1.049205256442083, 51.451409974333693],
            [-1.049220434756473, 51.453728186522902],
            [-1.048010586703186, 51.4537723228432],
            [-1.047095955781991, 51.454844811333352],
            [-1.047889098834877, 51.455254086063547],
            [-1.046740791284925, 51.456211377558233],
            [-1.052994814452528, 51.460368599287627],
            [-1.049795047865792, 51.4620997168212],
            [-1.048473896550761, 51.462432492615818],
            [-1.04786315191144, 51.464043362026906],
            [-1.047905649298557, 51.464629972051164],
            [-1.045534055885369, 51.465144809195607],
            [-1.044658362029037, 51.46502797984148],
            [-1.044336531023563, 51.465273531582731],
            [-1.044230676652926, 51.467033262403405],
            [-1.043877956199606, 51.467378370040457],
            [-1.043485601523164, 51.467208822842011],
            [-1.043026180758459, 51.468249921656721],
            [-1.043299965016234, 51.468444579467651],
            [-1.042478273514157, 51.469046618687017],
            [-1.042739435843825, 51.469431800694174],
            [-1.041491498631718, 51.470311796526033],
            [-1.040437159921118, 51.470539659935461],
            [-1.038561557114148, 51.472097864146683],
            [-1.036713004548241, 51.473391002740073],
            [-1.035939759228415, 51.474418705550825],
            [-1.036808911138999, 51.475058867589802],
            [-1.036577909454445, 51.475225116878867],
            [-1.02661471212989, 51.470722494840743],
            [-1.021792316773593, 51.467994725888097],
            [-1.018224914911578, 51.466582878309268],
            [-1.013584158986978, 51.466290371458037],
            [-1.011227439120348, 51.467160716794261],
            [-1.00752031534245, 51.467828070197541],
            [-1.007316249820146, 51.468209394516414],
            [-1.007876810893737, 51.46957909456593],
            [-1.007958883469483, 51.47157057537148],
            [-1.006833962326442, 51.471392908769005],
            [-1.007119236633517, 51.471980690680873],
            [-1.006759430830221, 51.472305845374613],
            [-1.005982935682374, 51.472591501196234],
            [-1.004857606295921, 51.472431795872978],
            [-1.004826876079869, 51.472719273014327],
            [-1.006949779984597, 51.473419743576549],
            [-1.003827714992837, 51.473311444212491],
            [-1.00328498963376, 51.474430809542099],
            [-1.002462408536232, 51.474252073265397],
            [-1.000823032277175, 51.474619372357367],
            [-0.999836321702319, 51.475230498497595],
            [-0.998201690638581, 51.477548124323064],
            [-0.998731897396379, 51.480296949565627],
            [-0.999696369639777, 51.4803132708739],
            [-1.000445727775267, 51.479893447465308],
            [-1.000639664941029, 51.480837440467006],
            [-1.000027261810073, 51.480854700929861],
            [-0.999996695616299, 51.481462285928977],
            [-0.998071439728609, 51.481719207909407],
            [-0.997854024141282, 51.482374651128019],
            [-0.998369933957487, 51.483279136418297],
            [-0.997547792640841, 51.483468134604209],
            [-0.998419519489799, 51.484302826132847],
            [-0.997399621642872, 51.484642995148789],
            [-0.99710483206393, 51.4843599312075],
            [-0.993857723432604, 51.48518904328445],
            [-0.994351794725824, 51.485971070258273],
            [-0.993395583850087, 51.486293766936349],
            [-0.992080933954702, 51.485366214493531],
            [-0.991171421251973, 51.48526937737256],
            [-0.989049894139711, 51.485730375801751],
            [-0.986957102635245, 51.485737498244376],
            [-0.984141458477094, 51.484960534809339],
            [-0.982283347980726, 51.485037044365399],
            [-0.980103915071941, 51.485701492759887],
            [-0.978633746323768, 51.485623960288578],
            [-0.978646491833859, 51.487310034457458],
            [-0.97939588759793, 51.487547646156301],
            [-0.97766999643946, 51.488404822443179],
            [-0.97610426129493, 51.488346207622449],
            [-0.975367140505514, 51.487432498477361],
            [-0.974341153867305, 51.486749146900465],
            [-0.971541519181879, 51.487700245959282],
            [-0.970038172334535, 51.489157216340075],
            [-0.970453362296735, 51.490419713523941],
            [-0.969610864695679, 51.490861906570295],
            [-0.969208034632067, 51.490201965944138],
            [-0.967543254297979, 51.490891383963017],
            [-0.967165530888932, 51.490651574487863],
            [-0.966181410094222, 51.490921651712966],
            [-0.964620603859428, 51.491468974938677],
            [-0.965115307441997, 51.492076690244573],
            [-0.962446591718779, 51.492758074531018],
            [-0.962109766638515, 51.493128254881753],
            [-0.957495819884196, 51.49307295600525],
            [-0.956667461844734, 51.492067500964247],
            [-0.955490299383835, 51.491388934038753],
            [-0.956019624012198, 51.4910429652872],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000039",
        LAD13CDO: "00MD",
        LAD13NM: "Slough",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.624788077245412, 51.538003274610958],
            [-0.620810249212354, 51.537751568781708],
            [-0.619733669525545, 51.537925043820735],
            [-0.618935039048917, 51.536823119255487],
            [-0.619612279082578, 51.535455313252442],
            [-0.618238193306194, 51.535265594097964],
            [-0.617757280221242, 51.534625094607726],
            [-0.616845969906638, 51.53477081619225],
            [-0.616509054000474, 51.53399443120059],
            [-0.614566519303804, 51.534294323218887],
            [-0.614340219264116, 51.533722457114514],
            [-0.613812352649841, 51.533823226822307],
            [-0.613393675897928, 51.532797687181976],
            [-0.611828436913364, 51.5330183663268],
            [-0.610554020988354, 51.531499817644402],
            [-0.608330536601599, 51.532079527163333],
            [-0.606426079114696, 51.532172020464124],
            [-0.605985831064534, 51.530017699186281],
            [-0.602681800540021, 51.52892633009585],
            [-0.596239613292413, 51.527927746491244],
            [-0.596489520842887, 51.525894940961443],
            [-0.59550978858868, 51.526018996288776],
            [-0.595695898315885, 51.525592304187271],
            [-0.594719631422439, 51.5256507525445],
            [-0.5949825951676, 51.524272725726881],
            [-0.589841944814784, 51.524143609766256],
            [-0.589844138179493, 51.522672538446272],
            [-0.58843046445403, 51.523173479587456],
            [-0.581010166435132, 51.523072276259875],
            [-0.58075530194916, 51.526031173315182],
            [-0.575997571474451, 51.533908101166894],
            [-0.572772910567833, 51.533934562056281],
            [-0.569009115825476, 51.532992211483403],
            [-0.567220043363394, 51.532987462544348],
            [-0.567919737541526, 51.530262422589402],
            [-0.569950471771162, 51.529728785844881],
            [-0.57285623886258, 51.529702122131106],
            [-0.575113510000193, 51.529297939484756],
            [-0.57425666117441, 51.528345163846708],
            [-0.572455072308243, 51.527409664848371],
            [-0.571793344668315, 51.524277772560943],
            [-0.57108114806665, 51.523360003674426],
            [-0.567556763592392, 51.521431389796476],
            [-0.565383213440418, 51.521291530796596],
            [-0.565037891869437, 51.520675847125474],
            [-0.568987665398034, 51.517718944333467],
            [-0.566691391949947, 51.514560772008288],
            [-0.568704995474432, 51.511378783558285],
            [-0.568767094870066, 51.511236567221893],
            [-0.564859051812257, 51.510314791437587],
            [-0.553967638157139, 51.509131662553621],
            [-0.542488453995647, 51.509496750541359],
            [-0.542367036960478, 51.508966506279833],
            [-0.541818903906723, 51.509480336624272],
            [-0.540906622502419, 51.509574200434308],
            [-0.528412502586563, 51.510124907651836],
            [-0.528105552182332, 51.509722705931694],
            [-0.526483839156587, 51.509159222306288],
            [-0.52614919659763, 51.507829580067465],
            [-0.527493977374568, 51.505628113050662],
            [-0.528926283564389, 51.501509708108941],
            [-0.527967045351229, 51.501589393646334],
            [-0.527991040637529, 51.498384900238769],
            [-0.529047704733642, 51.496350648067072],
            [-0.531838096854996, 51.493393027355324],
            [-0.514163549580291, 51.493331414291852],
            [-0.508828727134018, 51.493923734353004],
            [-0.500732906206227, 51.495373473331185],
            [-0.500558086672819, 51.494818220690611],
            [-0.496096523188996, 51.494997592466468],
            [-0.490044351706669, 51.494746285269009],
            [-0.49013636118066, 51.49457751772308],
            [-0.491464148596695, 51.493062337034083],
            [-0.494849752733743, 51.492656225005156],
            [-0.495368282369306, 51.492045116162664],
            [-0.49496158836088, 51.491316028377881],
            [-0.496005467946672, 51.490541704909099],
            [-0.496780809851071, 51.490486002037677],
            [-0.496670015951168, 51.489825455820181],
            [-0.497401337169944, 51.489881586776882],
            [-0.498648980040023, 51.489260019557314],
            [-0.497997409222034, 51.488881199522702],
            [-0.498326892527847, 51.488453794380092],
            [-0.499188409038387, 51.488579921719854],
            [-0.499043627265007, 51.487856896765535],
            [-0.499695141570975, 51.486832931509809],
            [-0.499016026357253, 51.486723529728664],
            [-0.498718898820966, 51.486078584624337],
            [-0.501974373020993, 51.482856951810668],
            [-0.503514128545482, 51.480518860655209],
            [-0.505961504371417, 51.472477782665102],
            [-0.50769008040393, 51.470115963527896],
            [-0.50875889651583, 51.469349937429989],
            [-0.509720591528107, 51.469175125642252],
            [-0.511201701174506, 51.469275768054999],
            [-0.513602327369914, 51.468416894532808],
            [-0.513869778038784, 51.467984163252822],
            [-0.515680371924963, 51.468267868912818],
            [-0.517155544641816, 51.469883543803526],
            [-0.518747396237517, 51.470789391798519],
            [-0.521240040537421, 51.471446706402951],
            [-0.524377684203822, 51.471527594371558],
            [-0.523731790061232, 51.472401597234551],
            [-0.525541418622305, 51.474553709756989],
            [-0.524713166063714, 51.474920064976487],
            [-0.52537941465168, 51.47689862799902],
            [-0.524304867583497, 51.47949643564727],
            [-0.527152033899528, 51.480207538363558],
            [-0.532688893089092, 51.483448474997175],
            [-0.533874956060441, 51.48463678309983],
            [-0.533491135760344, 51.484793842973723],
            [-0.534396288919286, 51.486839180439354],
            [-0.536121724361264, 51.487290553517134],
            [-0.536912688246283, 51.487193416814961],
            [-0.538247181229984, 51.488115564885803],
            [-0.538134402939831, 51.488354249279077],
            [-0.541312484939538, 51.489281341981211],
            [-0.541902130792805, 51.488768029510062],
            [-0.545427995564987, 51.489904353704709],
            [-0.545212773352755, 51.490194827702631],
            [-0.547002182394987, 51.49045350213359],
            [-0.547170022905181, 51.489931340378789],
            [-0.549003767807305, 51.49024538539696],
            [-0.549334860295692, 51.490297139226861],
            [-0.554174494431736, 51.491303768210415],
            [-0.55255045251701, 51.49487249087889],
            [-0.560537136727066, 51.497243083940077],
            [-0.564778649697918, 51.491683088147433],
            [-0.565486226838898, 51.491397702432209],
            [-0.568525191005096, 51.49232954563665],
            [-0.570225730823021, 51.493363697410572],
            [-0.571933993523755, 51.493507700334824],
            [-0.575325842978927, 51.494958009436232],
            [-0.576096984998949, 51.495637284281699],
            [-0.589198185739876, 51.499351063287634],
            [-0.589847281037418, 51.499372356597007],
            [-0.591062073697914, 51.497307087564309],
            [-0.591855286088844, 51.498026986733272],
            [-0.597498469194332, 51.500556625311091],
            [-0.600570603508937, 51.50232788607088],
            [-0.604341615004839, 51.497595332267714],
            [-0.606714815000408, 51.498241300413554],
            [-0.607743845173742, 51.49728237627631],
            [-0.613267025078678, 51.49975677523382],
            [-0.617104787557524, 51.501717389466208],
            [-0.62261577113979, 51.503770370509734],
            [-0.627222596828413, 51.504425041395692],
            [-0.631186941874841, 51.503492135402077],
            [-0.633422914187087, 51.502101061193805],
            [-0.63460134112585, 51.500514201969338],
            [-0.636784633072319, 51.500252749505471],
            [-0.640830721738959, 51.500863501517969],
            [-0.642229550409867, 51.500625226902187],
            [-0.643023143134395, 51.502671103952387],
            [-0.644950065564867, 51.502214104016709],
            [-0.647059925588194, 51.501027231960336],
            [-0.648408390694456, 51.500688491404048],
            [-0.653547367047637, 51.500447258180216],
            [-0.655075262766596, 51.501009702413143],
            [-0.65482872941913, 51.501651603144332],
            [-0.65442325071104, 51.501575016503409],
            [-0.653942978931727, 51.504232941239486],
            [-0.659337891333546, 51.504906178995967],
            [-0.659007349335224, 51.507135116055707],
            [-0.65561959136739, 51.50679690678264],
            [-0.655428287630086, 51.5082541170026],
            [-0.653961510201202, 51.508249884848539],
            [-0.653035602180049, 51.510769607666475],
            [-0.654018007202871, 51.511027264307877],
            [-0.653959293132735, 51.512188357348506],
            [-0.653258837552999, 51.515074843126122],
            [-0.654342469709589, 51.517525007667302],
            [-0.654924658047053, 51.517576643949404],
            [-0.655023926109414, 51.518806088125153],
            [-0.656861331803312, 51.518814549283128],
            [-0.657893573173654, 51.519393756814424],
            [-0.658928735300509, 51.519334557436395],
            [-0.659211803166155, 51.520247781788775],
            [-0.660156582030766, 51.520420430942927],
            [-0.659630192114217, 51.520676085010507],
            [-0.659729884721683, 51.522386599925255],
            [-0.660012488614537, 51.52754043291516],
            [-0.659901202274531, 51.528335850748618],
            [-0.659228925579008, 51.528254436102891],
            [-0.658232441906782, 51.528756486351412],
            [-0.653990462299055, 51.529759960122227],
            [-0.654124195042332, 51.530015967132009],
            [-0.653456779236623, 51.530306843038431],
            [-0.653081552615879, 51.529782799938445],
            [-0.652235551245837, 51.530165135237475],
            [-0.647937681371932, 51.532559707118097],
            [-0.645175157439975, 51.534265091291282],
            [-0.644847315432415, 51.536177495329305],
            [-0.64323246937565, 51.536807155236467],
            [-0.639322218356648, 51.537190793013181],
            [-0.636739097233981, 51.537938615992267],
            [-0.635233270799878, 51.537858167329304],
            [-0.630573362670683, 51.538904493392707],
            [-0.624788077245412, 51.538003274610958],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000040",
        LAD13CDO: "00ME",
        LAD13NM: "Windsor and Maidenhead",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.711220673416326, 51.564320895427123],
            [-0.708503500479715, 51.563116687450915],
            [-0.705487120412052, 51.562242714874664],
            [-0.703386723103445, 51.562847162721951],
            [-0.701542438176358, 51.565652023865887],
            [-0.698831856725292, 51.565587835709529],
            [-0.696205839558373, 51.565038962293549],
            [-0.694547941202259, 51.563180792216208],
            [-0.694195948335567, 51.562172481800438],
            [-0.692208968544745, 51.559320606852928],
            [-0.691222001668628, 51.556816149611983],
            [-0.691288355220452, 51.548672915096262],
            [-0.692696584075953, 51.54402180497997],
            [-0.693652865690885, 51.542605436334682],
            [-0.696420458947369, 51.540020463285728],
            [-0.69782521560735, 51.537787179716695],
            [-0.698110775070769, 51.536824615185978],
            [-0.697710611725532, 51.534779905435315],
            [-0.699740335049385, 51.531248802868248],
            [-0.700012837338677, 51.529071277427889],
            [-0.702070164220001, 51.523945267981439],
            [-0.70152602606253, 51.521283925070684],
            [-0.701604500024422, 51.518676228442899],
            [-0.703583711089639, 51.512623149957207],
            [-0.703182971335285, 51.511104474451535],
            [-0.701856604945541, 51.51036505272976],
            [-0.699043098957674, 51.509628016527131],
            [-0.695276113255928, 51.510341507228283],
            [-0.693058489272859, 51.510287149084327],
            [-0.690902395337133, 51.509192164901101],
            [-0.688729229502978, 51.508595105256163],
            [-0.686301811086814, 51.507170593360755],
            [-0.682742577801042, 51.503277614345016],
            [-0.682424999101361, 51.501249950189262],
            [-0.679382987090328, 51.497133374654517],
            [-0.678516627544685, 51.494561795296228],
            [-0.673518259609156, 51.492715966335432],
            [-0.669996735883758, 51.490077395596913],
            [-0.668208267258233, 51.489170488802692],
            [-0.665797977517032, 51.488350898931564],
            [-0.662301799139312, 51.487924420055634],
            [-0.659075097153741, 51.487102570640559],
            [-0.654776940197705, 51.487064143221303],
            [-0.65238250705003, 51.485571852977095],
            [-0.651747131359888, 51.485481824111346],
            [-0.650703284371049, 51.485860974046155],
            [-0.646490698228689, 51.489990139491738],
            [-0.640377087520197, 51.490300693708555],
            [-0.638121275161103, 51.491852627077868],
            [-0.636145516845614, 51.492365596034567],
            [-0.633899401859672, 51.492379921584146],
            [-0.633845386250309, 51.492693114898231],
            [-0.635723895648478, 51.495005252846966],
            [-0.637690735343, 51.495423762491534],
            [-0.638151479537182, 51.496011796880623],
            [-0.64109172759175, 51.496495498756708],
            [-0.642829422944918, 51.496484145152863],
            [-0.643134466981825, 51.496059653397545],
            [-0.643696181055567, 51.496063452197056],
            [-0.644464524149134, 51.496483269872066],
            [-0.642229550409867, 51.500625226902187],
            [-0.640830721738959, 51.500863501517969],
            [-0.636784633072319, 51.500252749505471],
            [-0.63460134112585, 51.500514201969338],
            [-0.633422914187087, 51.502101061193805],
            [-0.631186941874841, 51.503492135402077],
            [-0.627222596828413, 51.504425041395692],
            [-0.62261577113979, 51.503770370509734],
            [-0.617104787557524, 51.501717389466208],
            [-0.613267025078678, 51.49975677523382],
            [-0.607743845173742, 51.49728237627631],
            [-0.606714815000408, 51.498241300413554],
            [-0.604341615004839, 51.497595332267714],
            [-0.600570603508937, 51.50232788607088],
            [-0.597498469194332, 51.500556625311091],
            [-0.591855286088844, 51.498026986733272],
            [-0.591062073697914, 51.497307087564309],
            [-0.589847281037418, 51.499372356597007],
            [-0.589198185739876, 51.499351063287634],
            [-0.576096984998949, 51.495637284281699],
            [-0.575325842978927, 51.494958009436232],
            [-0.571933993523755, 51.493507700334824],
            [-0.570225730823021, 51.493363697410572],
            [-0.568525191005096, 51.49232954563665],
            [-0.565486226838898, 51.491397702432209],
            [-0.564778649697918, 51.491683088147433],
            [-0.560537136727066, 51.497243083940077],
            [-0.55255045251701, 51.49487249087889],
            [-0.554174494431736, 51.491303768210415],
            [-0.549334860295692, 51.490297139226861],
            [-0.549003767807305, 51.49024538539696],
            [-0.547170022905181, 51.489931340378789],
            [-0.547002182394987, 51.49045350213359],
            [-0.545212773352755, 51.490194827702631],
            [-0.545427995564987, 51.489904353704709],
            [-0.541902130792805, 51.488768029510062],
            [-0.541312484939538, 51.489281341981211],
            [-0.538134402939831, 51.488354249279077],
            [-0.538247181229984, 51.488115564885803],
            [-0.536912688246283, 51.487193416814961],
            [-0.536121724361264, 51.487290553517134],
            [-0.534396288919286, 51.486839180439354],
            [-0.533491135760344, 51.484793842973723],
            [-0.533874956060441, 51.48463678309983],
            [-0.532688893089092, 51.483448474997175],
            [-0.527152033899528, 51.480207538363558],
            [-0.524304867583497, 51.47949643564727],
            [-0.52537941465168, 51.47689862799902],
            [-0.524713166063714, 51.474920064976487],
            [-0.525541418622305, 51.474553709756989],
            [-0.523731790061232, 51.472401597234551],
            [-0.524377684203822, 51.471527594371558],
            [-0.529979125470944, 51.47016729116681],
            [-0.530396525978204, 51.469320065116925],
            [-0.529664563864147, 51.469292905406995],
            [-0.532090616128035, 51.464490006532124],
            [-0.535320313905016, 51.460511788712758],
            [-0.536619773501299, 51.459353634641374],
            [-0.536970090552959, 51.459607089456107],
            [-0.540496881214763, 51.458384042773893],
            [-0.540631893142866, 51.457851587864717],
            [-0.540595599007129, 51.457093994722257],
            [-0.526750808902865, 51.447509624288564],
            [-0.529424534495648, 51.445495665624208],
            [-0.531081564930739, 51.444889681212388],
            [-0.530659806546148, 51.44377295962876],
            [-0.531455428664762, 51.441712921851646],
            [-0.530149515933379, 51.440977179894119],
            [-0.527747995042483, 51.440889496832483],
            [-0.526510494377084, 51.440579005506386],
            [-0.526102419386524, 51.440159334840018],
            [-0.524558912412875, 51.439880044919065],
            [-0.524087810465683, 51.438827420828098],
            [-0.525250750572162, 51.437564261005662],
            [-0.525178661351728, 51.436898828989506],
            [-0.524550973555997, 51.43592876311326],
            [-0.523222722458828, 51.435805031442321],
            [-0.522784368367517, 51.43530044021518],
            [-0.527601447500774, 51.435691953068201],
            [-0.531424839952559, 51.436430512700994],
            [-0.534619045192484, 51.437411966893094],
            [-0.538382135807919, 51.439010997895871],
            [-0.548203316726947, 51.441872050865918],
            [-0.55046349711966, 51.443472751075596],
            [-0.551836833588521, 51.443499613742404],
            [-0.55287920801993, 51.442889325691731],
            [-0.55407087430872, 51.441514735227315],
            [-0.555585486570354, 51.441485746483039],
            [-0.55663837387807, 51.441999578167419],
            [-0.559309945099921, 51.444346125958447],
            [-0.563159716176002, 51.445750287955434],
            [-0.564963050699289, 51.44803750204656],
            [-0.567837761586128, 51.450079702930495],
            [-0.571693073810149, 51.451007063205424],
            [-0.579693380653416, 51.444746791138435],
            [-0.584520327043672, 51.443655967215818],
            [-0.585396224331145, 51.442365372975658],
            [-0.588383321189233, 51.442367205584588],
            [-0.590114219600392, 51.441342244930411],
            [-0.591070047194855, 51.441141520278393],
            [-0.593039038437425, 51.441823391335703],
            [-0.594237047529821, 51.441467284976298],
            [-0.594488434091926, 51.440871421352171],
            [-0.593889243403153, 51.440563898662845],
            [-0.594159280732293, 51.43931363027923],
            [-0.605142220153093, 51.431311408718798],
            [-0.604974240722899, 51.421380258540502],
            [-0.605172851781576, 51.417359521706217],
            [-0.606701095870134, 51.414110836998233],
            [-0.608519234016734, 51.412010278642086],
            [-0.611313970345128, 51.408708220311162],
            [-0.614402510124136, 51.40309228103002],
            [-0.618386603804957, 51.399025383819833],
            [-0.618117814575724, 51.398642744303928],
            [-0.617230231604755, 51.398759065939863],
            [-0.616492638953272, 51.398287263287202],
            [-0.615810300574313, 51.398700041657506],
            [-0.614538742188891, 51.397393737475774],
            [-0.615035323034681, 51.397039019278644],
            [-0.615626441724827, 51.397647581207792],
            [-0.616604374490819, 51.397013488307628],
            [-0.615602115605743, 51.396739081567794],
            [-0.615437716500262, 51.39637565303223],
            [-0.619652837738309, 51.392823995976158],
            [-0.618513224781698, 51.39238793456348],
            [-0.618024480500543, 51.392055755312448],
            [-0.618474026069853, 51.391780498587977],
            [-0.621836469765697, 51.390038731140599],
            [-0.623512800990796, 51.389543178597975],
            [-0.620533913098496, 51.388474948189412],
            [-0.618859870492036, 51.38746249128679],
            [-0.617212157311829, 51.385817268662962],
            [-0.61636252884235, 51.38353940296367],
            [-0.61806305766109, 51.383379624122242],
            [-0.619267649455148, 51.38499173982521],
            [-0.61962629547348, 51.384821515828222],
            [-0.62094077152043, 51.385171501851922],
            [-0.625320067300024, 51.382805847172058],
            [-0.625948922875114, 51.382872570841229],
            [-0.628236184399967, 51.384234710019378],
            [-0.632525213327757, 51.384303719923395],
            [-0.636458237419147, 51.385375557671793],
            [-0.638539917947421, 51.386657768262062],
            [-0.639470202364558, 51.38664249450126],
            [-0.640479023694405, 51.387371779008213],
            [-0.646617583415031, 51.385262222636463],
            [-0.647054837285153, 51.385931794371587],
            [-0.650631749056334, 51.385641221904613],
            [-0.652394394903447, 51.386790023277122],
            [-0.655318575107424, 51.387245345031808],
            [-0.658186604680472, 51.386280984505682],
            [-0.664364808381709, 51.385738292406835],
            [-0.667652329068866, 51.384570761926668],
            [-0.671405425639686, 51.385780554182773],
            [-0.673180897786309, 51.388740208536333],
            [-0.679450911025212, 51.393051726872834],
            [-0.68662842577638, 51.402959890312097],
            [-0.691770502433092, 51.406652013392232],
            [-0.696557969802892, 51.410910062731837],
            [-0.696134058940009, 51.413023890251395],
            [-0.6936845614387, 51.41501444869683],
            [-0.690100057333353, 51.418871629757824],
            [-0.684798279367643, 51.421629495482549],
            [-0.680820837195535, 51.425126796515933],
            [-0.67878999728634, 51.428465175648284],
            [-0.678994639592725, 51.429948480999606],
            [-0.676277580820068, 51.431582272113666],
            [-0.66796547698157, 51.430747176154547],
            [-0.663113335390941, 51.426015146167266],
            [-0.658004996682488, 51.423926358419727],
            [-0.653879226613865, 51.423950093505788],
            [-0.648923338757739, 51.421102821267276],
            [-0.643292039396358, 51.418939016569688],
            [-0.641088168183825, 51.417068299968854],
            [-0.640458691827369, 51.41579400673551],
            [-0.638436381164373, 51.4146941732645],
            [-0.635464259761568, 51.414395243493708],
            [-0.635339458037445, 51.414167637810266],
            [-0.631326777693496, 51.414454013284136],
            [-0.631309181694843, 51.416825040703117],
            [-0.632089350702529, 51.419209875307132],
            [-0.631246841273774, 51.422860746443888],
            [-0.637163404006663, 51.428003944899103],
            [-0.6305698031746, 51.442191329372051],
            [-0.641966441471265, 51.441004744905179],
            [-0.650815946402767, 51.441766034873865],
            [-0.65388527759874, 51.441366995677093],
            [-0.661999085527394, 51.444318457732017],
            [-0.658240903434435, 51.449314679723841],
            [-0.658480180498152, 51.452002454943283],
            [-0.656772548445449, 51.461492929569488],
            [-0.660988377401004, 51.46185227004348],
            [-0.662662349257015, 51.462547575986484],
            [-0.66503277734673, 51.462464881808479],
            [-0.668918383478749, 51.459681946429235],
            [-0.672763037558427, 51.457847981614165],
            [-0.67979375139064, 51.463008758775132],
            [-0.682305034203062, 51.463383197942484],
            [-0.688883520316413, 51.466958434116364],
            [-0.705726383350283, 51.467736305370849],
            [-0.710150103132988, 51.467470322927888],
            [-0.710492870284816, 51.466393251348585],
            [-0.714435142863573, 51.467142407069517],
            [-0.72041641727735, 51.459902705443319],
            [-0.723323352987609, 51.457709762368552],
            [-0.724543123074159, 51.456137741665295],
            [-0.740787997629426, 51.458829273851997],
            [-0.745910082142935, 51.458803234859957],
            [-0.752769101039853, 51.462225006945033],
            [-0.757345859411212, 51.459022987438338],
            [-0.760762262762476, 51.459103201092169],
            [-0.762958538737569, 51.459698280538667],
            [-0.764644938724307, 51.459720553857927],
            [-0.76981985967503, 51.463407715881239],
            [-0.770156243468993, 51.465192555286869],
            [-0.769658303152509, 51.467756340274853],
            [-0.775177062591852, 51.468383298999264],
            [-0.775669058557907, 51.468720239951125],
            [-0.782581933242694, 51.46868789672083],
            [-0.783784030642593, 51.464573955643765],
            [-0.783890826048343, 51.460772358091646],
            [-0.785721873759671, 51.457786213602027],
            [-0.788099710252865, 51.454306637165367],
            [-0.789939087565862, 51.452400448964696],
            [-0.792798796419398, 51.451416527652079],
            [-0.795968689513902, 51.449523036050664],
            [-0.797052136678361, 51.447613468230792],
            [-0.799364619222888, 51.446133709204773],
            [-0.800080300635333, 51.444851584351625],
            [-0.800036923856539, 51.441180610411664],
            [-0.806086433581481, 51.441376345359764],
            [-0.808546514825889, 51.44255054454181],
            [-0.812850635873641, 51.442422453886458],
            [-0.818102864121817, 51.443189475449167],
            [-0.826627935762123, 51.455444814760931],
            [-0.828308525291071, 51.462041924370439],
            [-0.833772261211588, 51.464097091031334],
            [-0.83007072199562, 51.464508921125422],
            [-0.825196785632639, 51.465669614074237],
            [-0.824643338725588, 51.470065620808292],
            [-0.821802228849274, 51.472600713485214],
            [-0.822007949606706, 51.474117020679806],
            [-0.823705989263365, 51.475947734404315],
            [-0.82417268513323, 51.477901862678181],
            [-0.825095780140601, 51.478403870274207],
            [-0.826225049891262, 51.479794536932985],
            [-0.826721407278156, 51.481496277359362],
            [-0.826417607507857, 51.482503929034756],
            [-0.828187983773124, 51.484228293562985],
            [-0.829375051932143, 51.487195785846644],
            [-0.829167336547397, 51.487826742619028],
            [-0.827177679916969, 51.48947846036662],
            [-0.825314487820559, 51.491976649470139],
            [-0.822164314369942, 51.494266769244824],
            [-0.822479353743435, 51.494563067921092],
            [-0.822156994315932, 51.495557032904493],
            [-0.821561879379508, 51.495563644630224],
            [-0.820958493846404, 51.497175223517353],
            [-0.820007946915292, 51.497109916092676],
            [-0.819747361760752, 51.495764804498585],
            [-0.820699151912688, 51.49566917170722],
            [-0.820460316767791, 51.49498788216723],
            [-0.818561521545324, 51.495217849438319],
            [-0.818623513565564, 51.495773273896397],
            [-0.817085285376934, 51.496430366471202],
            [-0.817292853546198, 51.497204863374193],
            [-0.817633119350202, 51.497138156692046],
            [-0.818298951907658, 51.497963128371147],
            [-0.818759992568463, 51.498017227171715],
            [-0.817110842374148, 51.502845440826789],
            [-0.817678443377118, 51.506745548181534],
            [-0.817409279174388, 51.507235590363159],
            [-0.818842879479332, 51.50891173207016],
            [-0.822040330583645, 51.510646042114402],
            [-0.824075459276182, 51.512477420967492],
            [-0.82833785562, 51.512377094951084],
            [-0.833971042367236, 51.512940357527455],
            [-0.836205296504013, 51.512748535344507],
            [-0.839283310866848, 51.515873125014899],
            [-0.847660065915137, 51.522246390626009],
            [-0.850091586375287, 51.524482236952842],
            [-0.853926335714994, 51.526070846078873],
            [-0.852311357955339, 51.52947375325315],
            [-0.850484496227939, 51.530689549001117],
            [-0.849553428231583, 51.531824184139573],
            [-0.846943328094038, 51.533048428576095],
            [-0.846305002637272, 51.533661690985056],
            [-0.84597331724917, 51.534577393623181],
            [-0.846211621216415, 51.535986956750001],
            [-0.843135195991259, 51.541829223191002],
            [-0.842762473749349, 51.54475597920289],
            [-0.840941425828264, 51.545140840650049],
            [-0.838482151295995, 51.546802489957422],
            [-0.837616543327597, 51.546984545849938],
            [-0.835765284681673, 51.546739601376458],
            [-0.834319301990794, 51.545760426100827],
            [-0.832748224057564, 51.545332985040659],
            [-0.831381409188393, 51.545715022068087],
            [-0.828904643395675, 51.545627389540968],
            [-0.827901482579152, 51.546391570008936],
            [-0.826371785440118, 51.549178131663787],
            [-0.824909914773243, 51.550164292069411],
            [-0.823337563975891, 51.550622406453414],
            [-0.81693884246244, 51.550985188503802],
            [-0.810230066513254, 51.55272472351367],
            [-0.807146888683192, 51.552758167617469],
            [-0.80328624648857, 51.551913196810823],
            [-0.794489343869999, 51.551155071529855],
            [-0.792982582240154, 51.551356287498812],
            [-0.790993368464347, 51.552835649843004],
            [-0.78680884207787, 51.553821133382371],
            [-0.782535917272864, 51.556166016052615],
            [-0.779371170859372, 51.56015339305506],
            [-0.77777559892005, 51.563923247708878],
            [-0.776609386272078, 51.565608754568146],
            [-0.77567258245284, 51.566377678411939],
            [-0.773246770130995, 51.567307281222703],
            [-0.770942579504915, 51.567896418309665],
            [-0.77022863637422, 51.566710111474833],
            [-0.768536630988018, 51.565840844764878],
            [-0.76308166840947, 51.566420106062751],
            [-0.760330958145923, 51.565864163724612],
            [-0.757479796917209, 51.564814340305297],
            [-0.75388220831783, 51.566534104958905],
            [-0.748850289643855, 51.56840662662816],
            [-0.745150728692851, 51.570715801988776],
            [-0.740653055784573, 51.571867143361573],
            [-0.737749924849059, 51.573842938959878],
            [-0.733779050875288, 51.574921452996676],
            [-0.727882924470526, 51.577239640690628],
            [-0.722942528183974, 51.577825315160858],
            [-0.718964708138202, 51.577688455121084],
            [-0.716925097969246, 51.577191408000495],
            [-0.715067311280295, 51.576030918250282],
            [-0.712281318365208, 51.572229199459663],
            [-0.711464145841487, 51.568672039633846],
            [-0.712210496540582, 51.565491712448328],
            [-0.711220673416326, 51.564320895427123],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000041",
        LAD13CDO: "00MF",
        LAD13NM: "Wokingham",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.862544146621539, 51.549017265347324],
            [-0.859779822769734, 51.548365511331255],
            [-0.857549122687185, 51.548810491325305],
            [-0.856279760643192, 51.548703713812486],
            [-0.847066038302899, 51.545198473256342],
            [-0.842762473749349, 51.54475597920289],
            [-0.843135195991259, 51.541829223191002],
            [-0.846211621216415, 51.535986956750001],
            [-0.84597331724917, 51.534577393623181],
            [-0.846305002637272, 51.533661690985056],
            [-0.846943328094038, 51.533048428576095],
            [-0.849553428231583, 51.531824184139573],
            [-0.850484496227939, 51.530689549001117],
            [-0.852311357955339, 51.52947375325315],
            [-0.853926335714994, 51.526070846078873],
            [-0.850091586375287, 51.524482236952842],
            [-0.847660065915137, 51.522246390626009],
            [-0.839283310866848, 51.515873125014899],
            [-0.836205296504013, 51.512748535344507],
            [-0.833971042367236, 51.512940357527455],
            [-0.82833785562, 51.512377094951084],
            [-0.824075459276182, 51.512477420967492],
            [-0.822040330583645, 51.510646042114402],
            [-0.818842879479332, 51.50891173207016],
            [-0.817409279174388, 51.507235590363159],
            [-0.817678443377118, 51.506745548181534],
            [-0.817110842374148, 51.502845440826789],
            [-0.818759992568463, 51.498017227171715],
            [-0.818298951907658, 51.497963128371147],
            [-0.817633119350202, 51.497138156692046],
            [-0.817292853546198, 51.497204863374193],
            [-0.817085285376934, 51.496430366471202],
            [-0.818623513565564, 51.495773273896397],
            [-0.818561521545324, 51.495217849438319],
            [-0.820460316767791, 51.49498788216723],
            [-0.820699151912688, 51.49566917170722],
            [-0.819747361760752, 51.495764804498585],
            [-0.820007946915292, 51.497109916092676],
            [-0.820958493846404, 51.497175223517353],
            [-0.821561879379508, 51.495563644630224],
            [-0.822156994315932, 51.495557032904493],
            [-0.822479353743435, 51.494563067921092],
            [-0.822164314369942, 51.494266769244824],
            [-0.825314487820559, 51.491976649470139],
            [-0.827177679916969, 51.48947846036662],
            [-0.829167336547397, 51.487826742619028],
            [-0.829375051932143, 51.487195785846644],
            [-0.828187983773124, 51.484228293562985],
            [-0.826417607507857, 51.482503929034756],
            [-0.826721407278156, 51.481496277359362],
            [-0.826225049891262, 51.479794536932985],
            [-0.825095780140601, 51.478403870274207],
            [-0.82417268513323, 51.477901862678181],
            [-0.823705989263365, 51.475947734404315],
            [-0.822007949606706, 51.474117020679806],
            [-0.821802228849274, 51.472600713485214],
            [-0.824643338725588, 51.470065620808292],
            [-0.825196785632639, 51.465669614074237],
            [-0.83007072199562, 51.464508921125422],
            [-0.833772261211588, 51.464097091031334],
            [-0.828308525291071, 51.462041924370439],
            [-0.826627935762123, 51.455444814760931],
            [-0.818102864121817, 51.443189475449167],
            [-0.812850635873641, 51.442422453886458],
            [-0.808546514825889, 51.44255054454181],
            [-0.806086433581481, 51.441376345359764],
            [-0.800036923856539, 51.441180610411664],
            [-0.7997271601407, 51.438945626999484],
            [-0.798067904308797, 51.435724780483746],
            [-0.798549613385167, 51.432827098928854],
            [-0.797901433079533, 51.424689877800482],
            [-0.798729329388666, 51.421419873353983],
            [-0.798708432336037, 51.419528636664012],
            [-0.796692957514055, 51.414458042394173],
            [-0.795756128931278, 51.413225505533887],
            [-0.793247676339395, 51.411393209043538],
            [-0.792522402539303, 51.408849085114689],
            [-0.794630808039704, 51.408554264412103],
            [-0.798099544461028, 51.409184255324178],
            [-0.801538730650079, 51.409133143760805],
            [-0.801989162758053, 51.409063114106466],
            [-0.801819047011547, 51.407539922355284],
            [-0.801491897378857, 51.405094339188246],
            [-0.800771188134591, 51.403846065745959],
            [-0.798972117231341, 51.400134610795824],
            [-0.797848981031784, 51.395653145435631],
            [-0.796847159619482, 51.394007209838961],
            [-0.795782373800443, 51.392353424411255],
            [-0.793141719331661, 51.384607466621702],
            [-0.788856575149175, 51.371750428286184],
            [-0.809606511774269, 51.370907372547769],
            [-0.813588265459389, 51.370318305681501],
            [-0.814549518122647, 51.369533133082719],
            [-0.819732220868468, 51.36720524142622],
            [-0.822108843975915, 51.366172584292769],
            [-0.826999124222439, 51.364015879307253],
            [-0.828293785656216, 51.362815799355388],
            [-0.829149448899343, 51.360972881132994],
            [-0.832489315469617, 51.357848183531907],
            [-0.83422633400225, 51.355389954720792],
            [-0.834392540196246, 51.354499592374715],
            [-0.83736629879719, 51.352870994527798],
            [-0.841146617710891, 51.352733097838012],
            [-0.841862391944079, 51.352081953559868],
            [-0.844595518716628, 51.352239321647012],
            [-0.845866135471144, 51.352707743056818],
            [-0.847718797707058, 51.352529949427982],
            [-0.848598226900419, 51.352965718732143],
            [-0.8501067711684, 51.353053368735075],
            [-0.850375707898963, 51.353554169271376],
            [-0.851423565801714, 51.354032933104385],
            [-0.853613862081817, 51.354253107728113],
            [-0.854225936519756, 51.353895814309112],
            [-0.857456956164966, 51.353845536144888],
            [-0.859132910328739, 51.354190976247146],
            [-0.864783531889525, 51.354266539092222],
            [-0.86582627386135, 51.353854009303859],
            [-0.868120780794116, 51.354743032080052],
            [-0.871736771918474, 51.352702535753274],
            [-0.873251651901431, 51.352298981415764],
            [-0.877874318898524, 51.352590624163788],
            [-0.879188842954153, 51.353157116835398],
            [-0.879349844648238, 51.353915788745958],
            [-0.881800542714291, 51.353826800582638],
            [-0.882409180323734, 51.354605025463378],
            [-0.882004422353509, 51.355067852333988],
            [-0.883019166026542, 51.355564899492848],
            [-0.884544823960906, 51.355545263007926],
            [-0.885148030621564, 51.3547156412654],
            [-0.88587097050636, 51.35469194433395],
            [-0.888165996896989, 51.356289151843647],
            [-0.889670658277414, 51.356901390750572],
            [-0.892771959427652, 51.356340857626776],
            [-0.893750614061828, 51.356732263195511],
            [-0.894958777848548, 51.356777832727879],
            [-0.895392943954931, 51.357177576516875],
            [-0.897192860733354, 51.357281752821073],
            [-0.897719160721127, 51.357553768648152],
            [-0.89747356662245, 51.357912938464253],
            [-0.899785956977482, 51.358988540337521],
            [-0.901511017144939, 51.358983143401552],
            [-0.903631217673577, 51.358128073895983],
            [-0.906898175600019, 51.359413883719689],
            [-0.907274925856802, 51.359817543273074],
            [-0.908952982787983, 51.360159590153941],
            [-0.910080855583646, 51.360929912999261],
            [-0.915141500944835, 51.360785342483396],
            [-0.917829292627006, 51.3622282376786],
            [-0.918347828789021, 51.36289843509045],
            [-0.917642335798193, 51.36357891189666],
            [-0.917767265196769, 51.364555700446971],
            [-0.918322087324441, 51.364906116695202],
            [-0.92378624101096, 51.366010289942714],
            [-0.92507710199956, 51.36590973738506],
            [-0.926986201801653, 51.365170105411778],
            [-0.928531277367071, 51.365254380221046],
            [-0.930714851455759, 51.364444361597322],
            [-0.931924898967922, 51.364542619074179],
            [-0.935756116462197, 51.36545959146212],
            [-0.936359126554055, 51.365782484112813],
            [-0.936009195212386, 51.366316130536852],
            [-0.93669501921376, 51.365978860514772],
            [-0.937069595496173, 51.36388352311792],
            [-0.939173144509936, 51.363170634398223],
            [-0.941400002794208, 51.363897543828742],
            [-0.952261281406074, 51.362733576284192],
            [-0.972384004113654, 51.35993720816743],
            [-0.975522868991333, 51.360167011177239],
            [-0.977442739704554, 51.360637875249822],
            [-0.982727712197916, 51.359820641203306],
            [-0.986759988507346, 51.35985467619107],
            [-0.987558614394124, 51.36011245517642],
            [-0.987941717541518, 51.360716429105118],
            [-0.986704593207204, 51.361389129386417],
            [-0.985733063987698, 51.361480535794648],
            [-0.985502712262198, 51.362025254606287],
            [-0.986143842847215, 51.362847275327738],
            [-0.986249009947969, 51.363679044389812],
            [-0.988028339317242, 51.36441198309231],
            [-0.990548088317548, 51.364006595716845],
            [-0.991458008961685, 51.365152814492539],
            [-0.990958552346038, 51.365460536743917],
            [-0.991765310050799, 51.365942256655259],
            [-0.991811216686287, 51.366664707217055],
            [-0.990485843631804, 51.367949041310041],
            [-0.990899645569951, 51.368533486551115],
            [-0.990252512376865, 51.369348879711254],
            [-0.990547149580267, 51.369734475898639],
            [-0.990111276464603, 51.369957318405049],
            [-0.989572874864718, 51.369812402944198],
            [-0.9891166187338, 51.37043880552946],
            [-0.988300747634517, 51.370172796550293],
            [-0.987696465913209, 51.371061380454243],
            [-0.985284021133979, 51.370688032420396],
            [-0.983455813436897, 51.371440103550732],
            [-0.983823444448179, 51.372225593304037],
            [-0.983627617984221, 51.373348790729693],
            [-0.981720907572478, 51.375626091143957],
            [-0.984256768478762, 51.376730728749692],
            [-0.983737251451225, 51.377157839427838],
            [-0.985402696062187, 51.378579510757],
            [-0.988751393958131, 51.380563303147404],
            [-0.990260588553492, 51.381523167528876],
            [-0.992655433868215, 51.38403544414502],
            [-0.996828017146499, 51.385965833473939],
            [-1.00104392255736, 51.389024929769342],
            [-1.007062366788211, 51.391436448989332],
            [-1.011905302577056, 51.392865726318114],
            [-1.009844836822927, 51.396566499200084],
            [-1.009120496456837, 51.398823644308116],
            [-1.006428905097454, 51.401307807865713],
            [-1.005449874066961, 51.401774278894479],
            [-1.005380093223125, 51.40240671756915],
            [-1.004042290896274, 51.403769323556681],
            [-1.003525387599975, 51.405129003790044],
            [-1.003884053410507, 51.407215477144135],
            [-1.002662416136245, 51.408649190702938],
            [-1.001125729688781, 51.409360855137038],
            [-0.99916332536041, 51.412249402524289],
            [-0.999790926966642, 51.412745716457181],
            [-1.000220246802369, 51.414290590023725],
            [-1.001417323266955, 51.414325070440846],
            [-1.000256729922718, 51.415782656898152],
            [-0.997902685343368, 51.417033114445644],
            [-0.998265012690215, 51.41749659586376],
            [-0.998037975779048, 51.419462082077331],
            [-0.99997974471435, 51.420173733560738],
            [-0.999225280680638, 51.421926109454482],
            [-0.999262554074878, 51.422524388098601],
            [-1.000336088837686, 51.422430139527556],
            [-0.999576023969016, 51.424899122452167],
            [-1.002788252527653, 51.426065758134847],
            [-1.001169002764392, 51.426381077938338],
            [-1.001290762831582, 51.425678050912111],
            [-0.999408892966403, 51.4248464425458],
            [-0.998668797858126, 51.424817644542941],
            [-0.996636336226557, 51.421671092343196],
            [-0.996290660344564, 51.419592805072348],
            [-0.993181432304826, 51.419460940704454],
            [-0.991463624620656, 51.420472151703592],
            [-0.987727333600541, 51.420795132176643],
            [-0.984813269690466, 51.421978447075027],
            [-0.984479453023543, 51.42133623229558],
            [-0.981362922413747, 51.420623114687153],
            [-0.983389632106304, 51.418857611869569],
            [-0.981961719333127, 51.418139357199067],
            [-0.981017432182701, 51.417209483567284],
            [-0.979813903477403, 51.414554496677859],
            [-0.980163698505825, 51.414150206489367],
            [-0.979914085986482, 51.41302494506089],
            [-0.979360136906099, 51.412523770129795],
            [-0.978790256497919, 51.412480142178929],
            [-0.973659704584899, 51.412547737673961],
            [-0.973721668590305, 51.409779668156311],
            [-0.97048457028207, 51.410252135244164],
            [-0.964750948047947, 51.411758145545406],
            [-0.962055941793743, 51.412283722536763],
            [-0.962147920444863, 51.413937252415238],
            [-0.959899909364671, 51.414275208424051],
            [-0.96031017170692, 51.415080027792875],
            [-0.956938701118582, 51.419475903437764],
            [-0.957171627025758, 51.419857434391581],
            [-0.951232782855732, 51.423157545601981],
            [-0.949165502487476, 51.423807134536361],
            [-0.946227668166386, 51.427097884103418],
            [-0.9434528089482, 51.429291216825789],
            [-0.943974148638742, 51.430075512165104],
            [-0.943537661274807, 51.436153705037981],
            [-0.943726669499814, 51.438438451055369],
            [-0.94641819142966, 51.438140755853333],
            [-0.94643351648283, 51.439163272069663],
            [-0.94815431169656, 51.439286623474771],
            [-0.947907164296343, 51.441089079008627],
            [-0.946642095436017, 51.441041754074341],
            [-0.946323375943042, 51.442324730983103],
            [-0.944646690172009, 51.442904915973095],
            [-0.944818961928608, 51.445544686289431],
            [-0.945234831729918, 51.44616886777122],
            [-0.944248712569569, 51.446311956642582],
            [-0.941807371440367, 51.445974336323957],
            [-0.937410030639932, 51.444417640305986],
            [-0.934831332017967, 51.443001400225235],
            [-0.933933112524687, 51.441904318797],
            [-0.932408396769064, 51.440958886136698],
            [-0.93073571187557, 51.442224991141124],
            [-0.928494345609075, 51.445161067957471],
            [-0.931567116484567, 51.447662775334209],
            [-0.935005350728995, 51.45191394355227],
            [-0.937869325726761, 51.454020638851851],
            [-0.943624488355568, 51.456465359137553],
            [-0.95003037697219, 51.457864472667303],
            [-0.949187951866246, 51.459512329595164],
            [-0.946008551657395, 51.459502681952294],
            [-0.942367596664521, 51.460592987767626],
            [-0.932679884200164, 51.465792440879817],
            [-0.930661607511422, 51.467638969009741],
            [-0.929826217635614, 51.467778815961381],
            [-0.926886539290906, 51.467283479645495],
            [-0.923687010729883, 51.467188520693028],
            [-0.921717380641611, 51.467930241780394],
            [-0.920470183751043, 51.47130510558069],
            [-0.919296516450586, 51.472717709123053],
            [-0.916888821753179, 51.474373381448238],
            [-0.914592534668104, 51.474785561840605],
            [-0.913359409115226, 51.475961969046175],
            [-0.910627384877316, 51.476829518565935],
            [-0.910098507775512, 51.477753464655819],
            [-0.910604741507747, 51.479472915113533],
            [-0.910229991457495, 51.482146309601553],
            [-0.90820511695128, 51.483959120467148],
            [-0.9058283748527, 51.485061855104448],
            [-0.901887108333654, 51.485337941435574],
            [-0.897670807924353, 51.48704371387872],
            [-0.898281167360339, 51.488311904395275],
            [-0.896646096567545, 51.489936650273307],
            [-0.896403433942007, 51.491814563531584],
            [-0.894286283323725, 51.492041902506166],
            [-0.893466246910278, 51.492535915340298],
            [-0.895302511600898, 51.493919083823322],
            [-0.893878637698911, 51.49483991939028],
            [-0.894020707315402, 51.496469682056087],
            [-0.889758162495425, 51.49877898730599],
            [-0.887472780373037, 51.499866030628873],
            [-0.885949732356988, 51.500053893592742],
            [-0.882409849059841, 51.501355513247269],
            [-0.880191176190583, 51.501824411796278],
            [-0.871710017329576, 51.502448110737873],
            [-0.870688264797297, 51.502983185623656],
            [-0.870075196440721, 51.504120146572134],
            [-0.871044613924663, 51.505587957051759],
            [-0.871078825321158, 51.506885809523439],
            [-0.871984941177391, 51.507886325514008],
            [-0.874519068023841, 51.509308894345565],
            [-0.879576245488035, 51.511097219114639],
            [-0.880256100754669, 51.511756516596542],
            [-0.880868443455817, 51.513584103722046],
            [-0.880293455054625, 51.51505327730824],
            [-0.880561703457829, 51.516338970376978],
            [-0.879974967031874, 51.517168711234078],
            [-0.878554973543347, 51.518065119920763],
            [-0.878237361794274, 51.523015676842263],
            [-0.878815216088162, 51.523726158144456],
            [-0.883926281969089, 51.527155804488999],
            [-0.888017021586468, 51.530128666613585],
            [-0.892638340426343, 51.532706256214922],
            [-0.895812563494583, 51.533796299531083],
            [-0.899371554906986, 51.535797136541817],
            [-0.900153878694581, 51.536658690622225],
            [-0.900079768212, 51.540451626842824],
            [-0.896901839556156, 51.544860137217938],
            [-0.893655961002801, 51.549309282004586],
            [-0.890401509505113, 51.554855249639168],
            [-0.889600043463029, 51.556967027250664],
            [-0.887389732844045, 51.559468282889995],
            [-0.883498576275796, 51.561436493958794],
            [-0.88141863117667, 51.562011944346864],
            [-0.879742406894634, 51.562124539823728],
            [-0.875618818277283, 51.5617362297955],
            [-0.872767427198164, 51.560307963300588],
            [-0.87117391944855, 51.557663455755062],
            [-0.867948776833647, 51.556199104096379],
            [-0.864984710566832, 51.55317801337943],
            [-0.863653360003268, 51.549937979444316],
            [-0.862544146621539, 51.549017265347324],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000042",
        LAD13CDO: "00MG",
        LAD13NM: "Milton Keynes",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.684671175539636, 52.196323250345969],
            [-0.681253887311917, 52.19571148718498],
            [-0.680040332492999, 52.196128587580588],
            [-0.675273316961594, 52.195791055769696],
            [-0.67344247847728, 52.196053705285941],
            [-0.668153476023584, 52.195021306322921],
            [-0.667962753709326, 52.194059814325954],
            [-0.666008768275927, 52.192718762134113],
            [-0.661325210556755, 52.191761228350622],
            [-0.659353683841282, 52.191013271714247],
            [-0.657466117589309, 52.18976274030215],
            [-0.655601805553806, 52.189587768368071],
            [-0.651017746072368, 52.189952635754473],
            [-0.643178464382775, 52.188832385156374],
            [-0.640875868149971, 52.187154233816756],
            [-0.639158492572424, 52.186675897423015],
            [-0.639796998586454, 52.184494841540022],
            [-0.639528393431916, 52.183422710450614],
            [-0.630311554968142, 52.181768720041859],
            [-0.627465181835903, 52.181533345176938],
            [-0.62933516912965, 52.178702222054163],
            [-0.627741166293281, 52.176177885850443],
            [-0.628273261664853, 52.172524711070317],
            [-0.631192021457164, 52.171812352163975],
            [-0.633283777713167, 52.170121107422261],
            [-0.634925673841483, 52.168121622640712],
            [-0.634508457300496, 52.1665613399188],
            [-0.633573739619004, 52.166145020217158],
            [-0.631320645700899, 52.164046460269155],
            [-0.630153555304604, 52.161915515673854],
            [-0.62962370003977, 52.159507844277947],
            [-0.630690084704691, 52.154913195414004],
            [-0.634334593126972, 52.153469218085462],
            [-0.636654696724928, 52.153026721020424],
            [-0.640752353126223, 52.152913967650832],
            [-0.640810411018167, 52.152399447635247],
            [-0.636051832451093, 52.147499222692232],
            [-0.637854971138713, 52.146362006359901],
            [-0.63789926566591, 52.145820354895328],
            [-0.636410082105543, 52.143761270480866],
            [-0.63438147143281, 52.142381942831165],
            [-0.634948104189644, 52.141151326158948],
            [-0.635617593104127, 52.141192339549548],
            [-0.635471113521362, 52.13924317172598],
            [-0.634961368219198, 52.139187822251145],
            [-0.634642324751407, 52.137961338711015],
            [-0.629871176883264, 52.137304504643389],
            [-0.628949097614483, 52.136926052816513],
            [-0.627160311997551, 52.135272458113675],
            [-0.622905095799465, 52.134291370092527],
            [-0.619018529936534, 52.133017737371077],
            [-0.617650483094922, 52.133034078619531],
            [-0.614170127801763, 52.133886053922907],
            [-0.610107389414425, 52.133728553408041],
            [-0.607516447331836, 52.133977615552155],
            [-0.60708433611654, 52.133731550349985],
            [-0.604035264276279, 52.130219522849558],
            [-0.602141583003754, 52.12892124828786],
            [-0.598931547704172, 52.125720067027117],
            [-0.599029207805784, 52.123584028207475],
            [-0.597827496514733, 52.121939647625112],
            [-0.596946197839287, 52.119211135020798],
            [-0.597178307223047, 52.117447131117444],
            [-0.598035985529356, 52.11650516591569],
            [-0.597849625937226, 52.115602034409342],
            [-0.595750605875047, 52.113566629156182],
            [-0.593074136356856, 52.112252582418144],
            [-0.591829775122043, 52.110680454965753],
            [-0.593271718642965, 52.110475584051663],
            [-0.597156540768665, 52.108555494430178],
            [-0.598639154732931, 52.10686389987756],
            [-0.6003719885208, 52.106131020455535],
            [-0.600984604093691, 52.105358754947652],
            [-0.601891847384006, 52.10501616248969],
            [-0.596770932081498, 52.101673543608392],
            [-0.598125807259966, 52.10102251400955],
            [-0.6025558070949, 52.100123835122858],
            [-0.603978720602504, 52.099112998244365],
            [-0.604550343241227, 52.095812803510974],
            [-0.60599892735813, 52.095471191308029],
            [-0.607293291332481, 52.09454960577218],
            [-0.605807337474273, 52.092110712834632],
            [-0.608415756155922, 52.091128222147667],
            [-0.609731075484748, 52.091029552194186],
            [-0.6112284125021, 52.090098628582055],
            [-0.615192313374746, 52.088935940386619],
            [-0.616525608236494, 52.088206224033115],
            [-0.61860117617591, 52.085605133208659],
            [-0.621175602044694, 52.084330648928002],
            [-0.623403671579282, 52.083730900270822],
            [-0.624329858566377, 52.084223640089675],
            [-0.625839420639808, 52.084445346140342],
            [-0.628179773556819, 52.082133094576271],
            [-0.63137802660905, 52.080963650700902],
            [-0.632445782635405, 52.07973795490517],
            [-0.635387347843236, 52.078171610594524],
            [-0.636467001724921, 52.077076388675913],
            [-0.638557455856433, 52.073114753209232],
            [-0.638606728067571, 52.071686620560953],
            [-0.641604622527674, 52.06771023153167],
            [-0.643721163672621, 52.066661953897608],
            [-0.648348733064411, 52.065791714147196],
            [-0.651029011412499, 52.064132972959975],
            [-0.653438283852541, 52.061729301297504],
            [-0.654404280473242, 52.059485339706924],
            [-0.65687324314969, 52.05799488672352],
            [-0.658072590568347, 52.056765064558171],
            [-0.659153621676115, 52.056951797301743],
            [-0.66125912176109, 52.05394839326361],
            [-0.662151651041351, 52.054340654335569],
            [-0.663348018465376, 52.052518224485041],
            [-0.664098357218685, 52.052005241994912],
            [-0.665783036537631, 52.051556783198585],
            [-0.667665685072891, 52.050384049196957],
            [-0.668997423018111, 52.048706052769852],
            [-0.643079231277898, 52.03724606420267],
            [-0.64309759343422, 52.036880331871608],
            [-0.643892351059401, 52.035524609307913],
            [-0.643286058302236, 52.034651772964658],
            [-0.643426976710075, 52.032548543219818],
            [-0.644839178331802, 52.03121071003433],
            [-0.642269589789008, 52.030283802462307],
            [-0.643915375367162, 52.0282581426371],
            [-0.642557922382311, 52.028078871157668],
            [-0.643186297214738, 52.026821937263421],
            [-0.642589439494579, 52.026700873790837],
            [-0.643033829309886, 52.025887787494725],
            [-0.640850590181554, 52.025305165337372],
            [-0.641510074867168, 52.02475351399103],
            [-0.640223694634637, 52.024060733850732],
            [-0.640351278303969, 52.023650406052852],
            [-0.64587358796951, 52.021673883446944],
            [-0.647541616016097, 52.018991166134178],
            [-0.651045955174898, 52.018475679953532],
            [-0.6503805123968, 52.017149939221746],
            [-0.650652102408332, 52.016986711500799],
            [-0.64913718950753, 52.014836621911037],
            [-0.648358465588233, 52.015194534605911],
            [-0.645122439002528, 52.013636939835585],
            [-0.644089007340938, 52.012731317407649],
            [-0.644520375493397, 52.012632881471887],
            [-0.643499102289348, 52.010844449315265],
            [-0.645374329506196, 52.010313961195514],
            [-0.649435440579932, 52.010254489805511],
            [-0.65067869082258, 52.009660025945834],
            [-0.656307000132547, 52.005384263959769],
            [-0.656585485610189, 52.004063024129088],
            [-0.658775010148525, 52.001726861501758],
            [-0.659407048654964, 52.000026610672521],
            [-0.661711769341249, 51.999726432487989],
            [-0.660261499427149, 51.996680782535705],
            [-0.660140827873803, 51.994672553608488],
            [-0.659346450669869, 51.993033393434963],
            [-0.660648462823703, 51.990790490124532],
            [-0.657813141804369, 51.989806038032654],
            [-0.655487649625281, 51.98818985386869],
            [-0.65548399047345, 51.987242128237959],
            [-0.65599305578646, 51.986728240344704],
            [-0.655123864468652, 51.985165519870762],
            [-0.656307368239304, 51.984351826505069],
            [-0.655813932461395, 51.983760861863068],
            [-0.65637375670027, 51.982717063961566],
            [-0.656195231121685, 51.982016402086167],
            [-0.651311279234243, 51.977607879123028],
            [-0.649099558485996, 51.976206858202133],
            [-0.648099982468455, 51.974395323955349],
            [-0.645703710668897, 51.972218863650639],
            [-0.64749478451889, 51.97157588542597],
            [-0.649880125457669, 51.971259785878296],
            [-0.651081242891997, 51.970529066646861],
            [-0.65225916415533, 51.970327659515064],
            [-0.653001337811739, 51.969219426519324],
            [-0.666070093764856, 51.976054122443841],
            [-0.668662770839791, 51.977637157088296],
            [-0.66908629304419, 51.97847813554521],
            [-0.67047093177955, 51.977888658826949],
            [-0.670813148486924, 51.976558211026052],
            [-0.677348695557816, 51.976712708742866],
            [-0.678272489896059, 51.978172477396384],
            [-0.682259582460364, 51.979591922577548],
            [-0.687980786106415, 51.980624154576141],
            [-0.693158018196078, 51.982444902444833],
            [-0.696172129789038, 51.982196878886775],
            [-0.700063068348291, 51.984404085729579],
            [-0.700053058432166, 51.985403804841454],
            [-0.701590317410026, 51.985472010731378],
            [-0.701592665907286, 51.986951999664868],
            [-0.703772646526167, 51.988013601734963],
            [-0.709341113202933, 51.988333696361671],
            [-0.711233096905147, 51.988883997002425],
            [-0.712541915695364, 51.989660768062066],
            [-0.713405721037282, 51.989806873207222],
            [-0.713545330986023, 51.989610590406336],
            [-0.713838634808332, 51.987709445278163],
            [-0.715509688020775, 51.985598549864321],
            [-0.715250433681164, 51.984938461319032],
            [-0.717891475515734, 51.982406527447708],
            [-0.71711428751579, 51.981475561538026],
            [-0.714511593365924, 51.980989529358077],
            [-0.713861764906941, 51.980444757581502],
            [-0.714419041696632, 51.980051629155454],
            [-0.713213832083849, 51.979834366546775],
            [-0.712755913000705, 51.978894270728631],
            [-0.713485456457077, 51.978891452154315],
            [-0.713993088395832, 51.978553529315789],
            [-0.713963416500556, 51.977860877457225],
            [-0.723751069828064, 51.975278952453593],
            [-0.729778647391497, 51.973007283399951],
            [-0.73049191223253, 51.972340628894862],
            [-0.733101966284057, 51.971934455675438],
            [-0.74040139371976, 51.969853047704753],
            [-0.74757698497598, 51.973547652298961],
            [-0.743668843811465, 51.975237744361209],
            [-0.748214379326548, 51.977005269002191],
            [-0.755457496159931, 51.980638958871744],
            [-0.757826811730525, 51.982997193569851],
            [-0.761801825127143, 51.985330009673675],
            [-0.762018589482539, 51.985548976478839],
            [-0.765933500637791, 51.98441490471378],
            [-0.771227327090066, 51.982903079169084],
            [-0.776299184689286, 51.988615845203114],
            [-0.782845295497081, 51.988510136509809],
            [-0.789154492575616, 51.987953867465166],
            [-0.794138579351108, 51.987116664874527],
            [-0.799494578403659, 51.986959180291471],
            [-0.803525231697668, 51.985494166048497],
            [-0.804532924969864, 51.986150863557903],
            [-0.80631384629838, 51.995739133135487],
            [-0.807809691318516, 51.999908199322441],
            [-0.812263087093805, 52.006006027597927],
            [-0.81381724327956, 52.005522676569413],
            [-0.816348960703909, 52.005424061377134],
            [-0.818016285891536, 52.006062088917595],
            [-0.819357337857829, 52.007308221188673],
            [-0.81927912732691, 52.010612580626074],
            [-0.819634042517183, 52.010690763272692],
            [-0.819320197165992, 52.011654165879612],
            [-0.819837120865806, 52.01227613670266],
            [-0.8249519344105, 52.014817800890839],
            [-0.826597207040403, 52.014924108689158],
            [-0.829072259629536, 52.015954838056466],
            [-0.831832330756612, 52.018132011087729],
            [-0.835340049633766, 52.018859050559094],
            [-0.839149738109421, 52.021155211440067],
            [-0.843511117397392, 52.022362445590019],
            [-0.845145605187306, 52.022843315955221],
            [-0.847471702056967, 52.025563419132446],
            [-0.849527418735388, 52.025993501307973],
            [-0.852367690362977, 52.027434596317505],
            [-0.851896922948858, 52.028002742877746],
            [-0.856905874228848, 52.030549160500044],
            [-0.85592881316863, 52.0316509762417],
            [-0.858032146036492, 52.033029928961817],
            [-0.857802737719642, 52.033222812016241],
            [-0.859129518042425, 52.034417091115756],
            [-0.861330585906162, 52.035573051957343],
            [-0.861163100762788, 52.035738667451902],
            [-0.865428293293745, 52.037714684169842],
            [-0.865111145909568, 52.038091053347607],
            [-0.867108628237031, 52.038604780179412],
            [-0.866969104500964, 52.040086963622215],
            [-0.869526406733914, 52.040375858555883],
            [-0.871343557390969, 52.040240427029623],
            [-0.87180789362107, 52.041834491105782],
            [-0.870471789527623, 52.043360062371285],
            [-0.870624204850169, 52.043792194302505],
            [-0.86775191248733, 52.046209301906494],
            [-0.866133128047455, 52.046678357102458],
            [-0.865620923793503, 52.046635665953069],
            [-0.865025296395437, 52.045856701329811],
            [-0.864219924364495, 52.045587303310015],
            [-0.864034392791039, 52.045888513685505],
            [-0.863248331308433, 52.046007708585257],
            [-0.862612868378444, 52.045249026699508],
            [-0.859359738333016, 52.044618783264326],
            [-0.857901031812532, 52.046014454461513],
            [-0.855604113848626, 52.047339034386347],
            [-0.855722994088602, 52.047937194698711],
            [-0.852953552444131, 52.049940454808244],
            [-0.852462668295283, 52.050829387227971],
            [-0.8533016506341, 52.052236560278303],
            [-0.855646370328825, 52.053163847861676],
            [-0.858017949804544, 52.053212923444271],
            [-0.860420401295769, 52.05451380287726],
            [-0.860990509352973, 52.055437298685021],
            [-0.859887081855377, 52.056214245834134],
            [-0.860217623745948, 52.058069596388684],
            [-0.86064338640311, 52.058705782417384],
            [-0.86202474955062, 52.059334113290653],
            [-0.863033939057698, 52.060260038056462],
            [-0.862696225504438, 52.060922116417444],
            [-0.859287579924708, 52.063145928040143],
            [-0.859255301579041, 52.063611350998045],
            [-0.857376179239922, 52.064078666686932],
            [-0.855258166890127, 52.063960114928584],
            [-0.853093961108293, 52.064335582436328],
            [-0.850828137663574, 52.064690239428202],
            [-0.849803315306288, 52.064435688034791],
            [-0.847936483338508, 52.063626246328859],
            [-0.846901795050354, 52.06358645889997],
            [-0.845250557710519, 52.062961601133104],
            [-0.842779709739776, 52.063121686451787],
            [-0.838786273886839, 52.064111888847179],
            [-0.835639183404249, 52.066376258391827],
            [-0.834211839433525, 52.06830421631026],
            [-0.832895987743029, 52.069196593736557],
            [-0.832768107141878, 52.070855972659032],
            [-0.831437060196028, 52.071935196964475],
            [-0.832553568133104, 52.072033472391979],
            [-0.834089191808251, 52.072733786966303],
            [-0.834194565160193, 52.07342174410627],
            [-0.834771152510265, 52.073608164457397],
            [-0.834955594187958, 52.074313985234234],
            [-0.836043881357525, 52.075051210663609],
            [-0.83604796970384, 52.076801804327886],
            [-0.835263821590012, 52.07760864677185],
            [-0.835266674468419, 52.0797979896115],
            [-0.836797538445316, 52.080580937180017],
            [-0.837461128004372, 52.082281390692152],
            [-0.839224580880792, 52.082822942650509],
            [-0.840669580283091, 52.083772226299523],
            [-0.841717547874779, 52.083711497667892],
            [-0.841309665184984, 52.084077020366557],
            [-0.843822296578193, 52.084886602622731],
            [-0.843728461068699, 52.085693974098113],
            [-0.845113532841772, 52.086427729307978],
            [-0.845594396455366, 52.087758611804638],
            [-0.846353112133988, 52.088107696221542],
            [-0.845945721138323, 52.089019892455816],
            [-0.846802517949123, 52.09077432793697],
            [-0.846295428849394, 52.091023812428922],
            [-0.846729894434575, 52.091496493852297],
            [-0.850095937368581, 52.092402395409231],
            [-0.850514437731788, 52.092078304869929],
            [-0.851698119457967, 52.092026002683376],
            [-0.854349495298763, 52.092572373061508],
            [-0.854796219219511, 52.09234204796828],
            [-0.856051222140391, 52.093096886439383],
            [-0.856391460489621, 52.095033249007372],
            [-0.857934341592277, 52.097495548516939],
            [-0.858469626120531, 52.097400032906457],
            [-0.86030986542326, 52.09799775218822],
            [-0.861348983915283, 52.097837861692078],
            [-0.862164156426794, 52.098274600263281],
            [-0.863539317728886, 52.098391258331375],
            [-0.864862232303291, 52.099243758041453],
            [-0.865399028565207, 52.099088885103484],
            [-0.866670101400446, 52.099454453887169],
            [-0.867471802230051, 52.099273346222631],
            [-0.868641159833071, 52.099620833843439],
            [-0.868932796809839, 52.100154098752888],
            [-0.869547771390721, 52.100025131534352],
            [-0.868794138972597, 52.101076140987182],
            [-0.86800149973282, 52.101071231667035],
            [-0.866039176168733, 52.101939785718528],
            [-0.865287093729632, 52.102925153700959],
            [-0.866836747567919, 52.103767224263656],
            [-0.867461087292366, 52.104711878775149],
            [-0.868434107137323, 52.104284259088864],
            [-0.869879788088205, 52.105084831977713],
            [-0.869811150762464, 52.106413936982491],
            [-0.869037035579567, 52.107058359691713],
            [-0.870259607879759, 52.107735409332918],
            [-0.869002164161507, 52.107975102851498],
            [-0.868634407968242, 52.108428315760172],
            [-0.869323571723764, 52.109469784395095],
            [-0.86916906123617, 52.109863005712619],
            [-0.869820256275838, 52.11044377025069],
            [-0.870451423112514, 52.110257411101806],
            [-0.87146683179112, 52.111740749352045],
            [-0.872983018890737, 52.112652545421241],
            [-0.87391567688416, 52.112266752307541],
            [-0.874953060460394, 52.112477151574446],
            [-0.875278598272423, 52.112191647409162],
            [-0.876517366576102, 52.112930822667487],
            [-0.878209769119089, 52.112878602296412],
            [-0.878477819886484, 52.112497238543895],
            [-0.880394941638511, 52.113034232168104],
            [-0.880611919829543, 52.112650580567653],
            [-0.880913343987226, 52.112860232907494],
            [-0.881486774474553, 52.11264629407605],
            [-0.883692512312785, 52.113557296625075],
            [-0.885776324460811, 52.113387294273188],
            [-0.887032535832471, 52.114196652200306],
            [-0.887348092663447, 52.114720204807512],
            [-0.886531484183623, 52.114964243708215],
            [-0.88632134771372, 52.115602413898259],
            [-0.885229043803976, 52.116183697277165],
            [-0.88372131113462, 52.116556045937848],
            [-0.882904692245176, 52.11749955426663],
            [-0.883029793852476, 52.118045589602367],
            [-0.881973431459532, 52.118178535899247],
            [-0.882442207920489, 52.11882313318668],
            [-0.88156160854855, 52.119052145999788],
            [-0.881806227272636, 52.119841171891402],
            [-0.880699440951856, 52.120177722915976],
            [-0.880783088242628, 52.121736644596822],
            [-0.880038608383997, 52.122359841332369],
            [-0.880832985911793, 52.123476862222262],
            [-0.880436645971363, 52.123846223991933],
            [-0.879836124920779, 52.123794668313735],
            [-0.879670535711796, 52.124165319777163],
            [-0.880631708492762, 52.125389119637312],
            [-0.880736964939521, 52.126318880682682],
            [-0.879818318216866, 52.126660805311538],
            [-0.878880755964456, 52.126125928189218],
            [-0.869000333286616, 52.130613371906854],
            [-0.868653091897105, 52.1300759808748],
            [-0.866009818085838, 52.130152194681735],
            [-0.853545966162366, 52.131660861639162],
            [-0.852956423696622, 52.130325370646482],
            [-0.851281476739857, 52.12933085583655],
            [-0.846755368439739, 52.129397276303159],
            [-0.838245329161974, 52.130701063610672],
            [-0.835785130882919, 52.132830137271931],
            [-0.832057094631786, 52.131247736697368],
            [-0.828221718736573, 52.132597898607393],
            [-0.828649212986432, 52.135684242100034],
            [-0.831379680310938, 52.141281213545987],
            [-0.831983526113747, 52.143670690940759],
            [-0.816561518030277, 52.143512481959185],
            [-0.814058398302255, 52.142459677385759],
            [-0.813569367779193, 52.14376024530997],
            [-0.812701983863722, 52.144339525972477],
            [-0.812823707106216, 52.145697484746115],
            [-0.811548813985082, 52.146540585190003],
            [-0.810714432039668, 52.147633566652118],
            [-0.809199673719192, 52.148479617880284],
            [-0.809296503560205, 52.150000064501143],
            [-0.807228521177629, 52.151690149132946],
            [-0.806964789095782, 52.152807753311173],
            [-0.807604993969715, 52.156966239872645],
            [-0.806588049240907, 52.156893919478769],
            [-0.805569802629897, 52.15736193222461],
            [-0.802689712039001, 52.157536831717991],
            [-0.801973802956722, 52.158666018004098],
            [-0.799725019955006, 52.157051764504217],
            [-0.798934522632657, 52.155355220666834],
            [-0.796870844897754, 52.153391249212142],
            [-0.794114252546238, 52.15403472721227],
            [-0.793891758562094, 52.153234955579372],
            [-0.79427670867978, 52.152349685808289],
            [-0.793580173510623, 52.152270637025879],
            [-0.792109247984003, 52.151316929753442],
            [-0.790487855466274, 52.152088823407162],
            [-0.787733008729235, 52.154228264945033],
            [-0.786420826005996, 52.156523649905637],
            [-0.78420493196745, 52.158663157682213],
            [-0.783430366987814, 52.160263653388192],
            [-0.780330227071149, 52.163344309842074],
            [-0.783113182993731, 52.16417503929145],
            [-0.783212010776593, 52.165912209118126],
            [-0.779532282733633, 52.165577500298454],
            [-0.777896760993236, 52.168759547090033],
            [-0.773607861923028, 52.168875981874848],
            [-0.765349564974681, 52.171087038815095],
            [-0.764158566698207, 52.167787478685511],
            [-0.762734735148892, 52.16629983752685],
            [-0.762764343401457, 52.164968587419651],
            [-0.762065635221472, 52.163434593300479],
            [-0.760994486861342, 52.163995174055216],
            [-0.759865397272924, 52.165692492461588],
            [-0.756828227589919, 52.167438041322043],
            [-0.750674555458289, 52.167627484098389],
            [-0.749752824616594, 52.166865176436396],
            [-0.746083811205982, 52.168859995529793],
            [-0.74481301806968, 52.170267954435666],
            [-0.742556733389749, 52.170943426360743],
            [-0.738967876217054, 52.171165861091858],
            [-0.738768312967121, 52.172224662151393],
            [-0.737448262196955, 52.173656291367834],
            [-0.734749127499524, 52.173488017851909],
            [-0.729277889875998, 52.179461260480153],
            [-0.72550683108462, 52.180863638225375],
            [-0.720756127867365, 52.181889347054337],
            [-0.719025778554615, 52.182552989446997],
            [-0.716045452487278, 52.185428276672127],
            [-0.711636337913026, 52.188174611677077],
            [-0.708679982849475, 52.188778857313153],
            [-0.705061417977029, 52.190773859878398],
            [-0.705473136739614, 52.191557894133595],
            [-0.701689057114379, 52.193295634669298],
            [-0.696356507788162, 52.194291486545126],
            [-0.694097193791187, 52.195252815762785],
            [-0.686647130614417, 52.195801303579138],
            [-0.684671175539636, 52.196323250345969],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000043",
        LAD13CDO: "00ML",
        LAD13NM: "Brighton and Hove",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.165892243625686, 50.892338158881408],
            [-0.168200161672792, 50.885593420362397],
            [-0.168204262580387, 50.885053883845494],
            [-0.167658023306012, 50.884807885336613],
            [-0.168493506272795, 50.881919743722712],
            [-0.16815395453922, 50.880754272328346],
            [-0.166456043928608, 50.880912874092573],
            [-0.161688589302548, 50.880970982995045],
            [-0.159464396155264, 50.880306393979318],
            [-0.155486176200386, 50.879783130144055],
            [-0.153000785266086, 50.879867933262709],
            [-0.153203804834336, 50.884908331001625],
            [-0.152553971618582, 50.885450239513055],
            [-0.144639331850179, 50.886985770425845],
            [-0.142122074774914, 50.885824255065351],
            [-0.142433093280746, 50.884295834978886],
            [-0.137168385542091, 50.884585151917797],
            [-0.135037641736283, 50.886640287432186],
            [-0.135565026823803, 50.885146818453116],
            [-0.135851233263897, 50.880754524285109],
            [-0.135311366014438, 50.878110836905137],
            [-0.129530894914472, 50.87872971606064],
            [-0.1270690278464, 50.878156926045477],
            [-0.1242373311027, 50.877981044809047],
            [-0.124327646171783, 50.876191909452864],
            [-0.122261960988962, 50.876129014163538],
            [-0.121827315139331, 50.873433889822856],
            [-0.120117688199162, 50.873568245185169],
            [-0.115340601071982, 50.874234054576569],
            [-0.111388433385313, 50.873161102586032],
            [-0.104942808090079, 50.872348832676231],
            [-0.103522449185168, 50.872779928248384],
            [-0.104490070187599, 50.874609623302028],
            [-0.097990653528624, 50.876548087217266],
            [-0.0985032236402, 50.877505246697034],
            [-0.097546881787781, 50.877951024300458],
            [-0.094828084527344, 50.876543268662019],
            [-0.094387438111898, 50.876786991390333],
            [-0.084955374682227, 50.87312515567848],
            [-0.0861293923959, 50.871078619001928],
            [-0.084667254682238, 50.868988834241215],
            [-0.082892477806657, 50.867455985515811],
            [-0.08416221607935, 50.866921041918843],
            [-0.083264041575351, 50.863178501931039],
            [-0.082831902997178, 50.863077871070644],
            [-0.081697722328693, 50.860693049974159],
            [-0.080042180822517, 50.860937425666293],
            [-0.078860096371448, 50.853810423454213],
            [-0.075289625032972, 50.85070548854808],
            [-0.072526001386406, 50.848336848899017],
            [-0.07080700051725, 50.845824434301221],
            [-0.072417244931709, 50.844047849797647],
            [-0.073469996732933, 50.841250269815603],
            [-0.062649074267626, 50.841532460602124],
            [-0.060382251794168, 50.841974153257624],
            [-0.058388544581335, 50.842884413067978],
            [-0.053616858089471, 50.844293337742414],
            [-0.048852710691373, 50.844390937984869],
            [-0.045613781468461, 50.844973471364632],
            [-0.043484561970981, 50.844291151334211],
            [-0.043404171660602, 50.843326595483859],
            [-0.04261952146181, 50.842728854560654],
            [-0.036870765075021, 50.841244565078242],
            [-0.036260032278563, 50.839585769802191],
            [-0.039020371425594, 50.837877556082908],
            [-0.03810438633372, 50.836418691691499],
            [-0.038884489346532, 50.834681661848009],
            [-0.039132075609391, 50.82863855502675],
            [-0.042195654728005, 50.824822776212876],
            [-0.042486165985352, 50.824259258034125],
            [-0.045142942572823, 50.82340535113206],
            [-0.045848579768696, 50.823542183962374],
            [-0.045512328276556, 50.822379978710153],
            [-0.045996711983674, 50.821830492167727],
            [-0.042181559587956, 50.821459845221348],
            [-0.039713622047074, 50.821535316658249],
            [-0.033298624418258, 50.818805714206512],
            [-0.031031354658628, 50.817421127794162],
            [-0.026367874023345, 50.818023134653039],
            [-0.020456845030956, 50.8218360895807],
            [-0.019330938912834, 50.819654918389595],
            [-0.016614544291975, 50.816280233075673],
            [-0.016030748322282, 50.814880793466315],
            [-0.020651790478541, 50.812894365315984],
            [-0.028107444487754, 50.808372746800288],
            [-0.031793848824376, 50.804551571070633],
            [-0.034418908097865, 50.802469749305395],
            [-0.037124474769349, 50.800933336897444],
            [-0.038230787537412, 50.799525564164426],
            [-0.038283245111527, 50.799458994433742],
            [-0.038486987430324, 50.799202505857387],
            [-0.038439806492445, 50.799549764829877],
            [-0.039353799552937, 50.799446416844603],
            [-0.039157869733998, 50.799787578100769],
            [-0.039772281825381, 50.799857263361702],
            [-0.039816132934982, 50.799623267334596],
            [-0.039872647839364, 50.799934496325342],
            [-0.040555435206198, 50.799998131601242],
            [-0.040864100353849, 50.799777577219196],
            [-0.040622723756529, 50.800119776221798],
            [-0.040930724397535, 50.800254457673894],
            [-0.042864814022678, 50.800531552632719],
            [-0.043006737778607, 50.800259629255905],
            [-0.043301648907425, 50.800537982315696],
            [-0.043460100152073, 50.800278027336454],
            [-0.043799698671486, 50.800505865218859],
            [-0.043977274512584, 50.800161690496878],
            [-0.04435713107581, 50.800445062798602],
            [-0.044583648037978, 50.800186247541426],
            [-0.044460102370985, 50.800426103971638],
            [-0.045147076320816, 50.800525756838432],
            [-0.045266214250577, 50.800289423270911],
            [-0.045361056909221, 50.800601291684877],
            [-0.04621429391341, 50.800525651218514],
            [-0.047051058757401, 50.800742919818617],
            [-0.046979852978243, 50.8009530769666],
            [-0.050157295048485, 50.801535060051179],
            [-0.050297312810243, 50.801273888501612],
            [-0.050574769236199, 50.801563622734811],
            [-0.050738339204465, 50.801247983418612],
            [-0.051062302784819, 50.801511512927355],
            [-0.051211388168999, 50.801202825808936],
            [-0.051572748556216, 50.801488563304062],
            [-0.051836590400775, 50.801218666019594],
            [-0.051685574412162, 50.801437385807887],
            [-0.052444906722043, 50.801572379728853],
            [-0.052782528079979, 50.80130281285961],
            [-0.052697686404559, 50.801534330305181],
            [-0.054396366616762, 50.801844176485993],
            [-0.0545798795394, 50.801423639667362],
            [-0.054990414630831, 50.801619351419831],
            [-0.055219566975956, 50.801432508439085],
            [-0.056388753885396, 50.802087842968355],
            [-0.056590469492255, 50.801843880581217],
            [-0.056986396455488, 50.802255187731248],
            [-0.057314073050749, 50.802053793421983],
            [-0.057178642984474, 50.802273679116375],
            [-0.057706910799317, 50.802471342119183],
            [-0.061515284557482, 50.803382790417267],
            [-0.061860777256168, 50.803093542499816],
            [-0.061640023294351, 50.803420837684868],
            [-0.063456930287314, 50.803963650405088],
            [-0.06376761587472, 50.803795232210383],
            [-0.063628637290951, 50.804067228999017],
            [-0.06466904390632, 50.80413396063642],
            [-0.064560954036881, 50.804311139175461],
            [-0.065444307881843, 50.804538041360829],
            [-0.065597409644696, 50.804370602074734],
            [-0.066400829461966, 50.804780539426076],
            [-0.066724563195617, 50.804570959297045],
            [-0.066540649662028, 50.804797246845986],
            [-0.067803764707718, 50.805177020331847],
            [-0.068429205802966, 50.805291704357991],
            [-0.068609719706351, 50.805078847939406],
            [-0.068535554050114, 50.805328540280627],
            [-0.069663140627263, 50.805484807582133],
            [-0.073014060794801, 50.806797512723996],
            [-0.073480028024824, 50.806683796577175],
            [-0.075514197577819, 50.807437758788055],
            [-0.077275776517422, 50.80767635856337],
            [-0.077253564450617, 50.807906227310575],
            [-0.078842152191243, 50.808348803224547],
            [-0.079689499252086, 50.808038088752099],
            [-0.079448950917725, 50.80829674003278],
            [-0.079929542790564, 50.808449447896116],
            [-0.08023861635344, 50.808181131182614],
            [-0.080018222880579, 50.80850217079098],
            [-0.081976413403588, 50.808966975019857],
            [-0.08187876753291, 50.809167724126887],
            [-0.082826245345293, 50.80946210007707],
            [-0.092096936834134, 50.81175186182125],
            [-0.092558333025717, 50.811750413825905],
            [-0.093468287421117, 50.810518745207247],
            [-0.094348278646236, 50.809274971171696],
            [-0.094637432908493, 50.808936141787285],
            [-0.095564380383766, 50.808716364477391],
            [-0.096361433869085, 50.808496625749335],
            [-0.097007982595279, 50.808374071072002],
            [-0.102678344063294, 50.80890262485331],
            [-0.106135301106171, 50.80955237391467],
            [-0.104663591414446, 50.809546457736218],
            [-0.102838008334453, 50.80902573490625],
            [-0.098649556170947, 50.808530346822955],
            [-0.096483532654404, 50.808498426711054],
            [-0.09615577927995, 50.808576140266389],
            [-0.094402663553747, 50.809253945092266],
            [-0.093557282838184, 50.810434802563606],
            [-0.093980967372592, 50.81066206764627],
            [-0.103655805109032, 50.812175072385088],
            [-0.104420909507205, 50.810492229895097],
            [-0.104202043826119, 50.811305284477776],
            [-0.107771974364776, 50.81162316034326],
            [-0.107915375306122, 50.810182027480558],
            [-0.1064155760015, 50.808272649639285],
            [-0.108117947510446, 50.810402956094521],
            [-0.1075022215792, 50.812818518827278],
            [-0.113139466639547, 50.814431538496109],
            [-0.119954848899155, 50.816057879962976],
            [-0.120225854669062, 50.815601780331917],
            [-0.120923081033394, 50.816021317854755],
            [-0.123795606165493, 50.816714184527868],
            [-0.130016261231527, 50.818141503892519],
            [-0.134211652667799, 50.818884088765195],
            [-0.134535423896307, 50.818704000966584],
            [-0.135801542584007, 50.819173913942684],
            [-0.136719467934087, 50.819225451852319],
            [-0.136999809064533, 50.818745180958196],
            [-0.138929206283007, 50.819137514186963],
            [-0.13925545656808, 50.818537457865055],
            [-0.139275514266665, 50.818854347941794],
            [-0.140021035596276, 50.81910006686396],
            [-0.146389264052265, 50.820298627886643],
            [-0.147633622821452, 50.820461388692792],
            [-0.14774598757246, 50.820205060502978],
            [-0.150490783899334, 50.820661407599871],
            [-0.153968429284916, 50.821436864527897],
            [-0.154079982246814, 50.821200303191894],
            [-0.156954863857021, 50.821925661033909],
            [-0.164350630963704, 50.823046832110428],
            [-0.164629827525037, 50.822842577008792],
            [-0.166928557538505, 50.823095459965145],
            [-0.172225656648833, 50.823793720002357],
            [-0.174753973490492, 50.823768550571778],
            [-0.181330225905317, 50.824365889443044],
            [-0.191765727616855, 50.825628890750416],
            [-0.198445695213753, 50.826273634712948],
            [-0.199249064758624, 50.826207794686937],
            [-0.199274527699879, 50.825878129937614],
            [-0.208456214938281, 50.826791116251471],
            [-0.21606545488359, 50.827578619122001],
            [-0.215322641884501, 50.830375891321751],
            [-0.216612897687877, 50.830688804473809],
            [-0.216693570206611, 50.832968958550126],
            [-0.21759850271291, 50.832923430066593],
            [-0.222933820830317, 50.839095118099571],
            [-0.225993735863937, 50.842937774700978],
            [-0.227672210936759, 50.844412109367646],
            [-0.228431092809567, 50.845640432916412],
            [-0.229352044631144, 50.845740747471488],
            [-0.230643562963141, 50.848324339207785],
            [-0.234360948438955, 50.851116417993602],
            [-0.235409998894595, 50.852418332084106],
            [-0.236409247405504, 50.857528155139185],
            [-0.239021048578609, 50.85872863370605],
            [-0.239780382757103, 50.861958795720696],
            [-0.241531822565982, 50.862882731705938],
            [-0.244743697643995, 50.863441004148754],
            [-0.244998219391931, 50.863815356001815],
            [-0.240603019102145, 50.865208790906109],
            [-0.239825106618547, 50.866054122626053],
            [-0.239265578834035, 50.867892007377797],
            [-0.23878149129354, 50.86972563163895],
            [-0.237577765443626, 50.871796603601496],
            [-0.233847490104495, 50.875163962345432],
            [-0.232697874290679, 50.87594516489883],
            [-0.226746558159894, 50.878186859511523],
            [-0.225549671238248, 50.878074239356387],
            [-0.22467426201118, 50.877245234453341],
            [-0.224582302750833, 50.876298639454681],
            [-0.214174417363347, 50.875185661363624],
            [-0.213909748494344, 50.870470910610585],
            [-0.209284819228799, 50.870511556275851],
            [-0.205429345792222, 50.870093501594042],
            [-0.202664792093817, 50.875863348410917],
            [-0.20094847597841, 50.874920484644818],
            [-0.19901536071326, 50.870126900971798],
            [-0.197381559690133, 50.869557580037323],
            [-0.194399038840229, 50.869650871209863],
            [-0.189246772586946, 50.869034141693106],
            [-0.187762277679839, 50.868576720480476],
            [-0.189775664254082, 50.874422165605118],
            [-0.187753127038526, 50.87559228807153],
            [-0.186298436381451, 50.875680309108979],
            [-0.185100344006621, 50.876590696791943],
            [-0.181888890365286, 50.880910578855797],
            [-0.181329947008694, 50.883655631000465],
            [-0.182450263282844, 50.888330729143384],
            [-0.180809841951359, 50.888358234084237],
            [-0.177840830440832, 50.890478415724409],
            [-0.169363430087171, 50.889938368638326],
            [-0.168639438091537, 50.892374099136219],
            [-0.165892243625686, 50.892338158881408],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000044",
        LAD13CDO: "00MR",
        LAD13NM: "Portsmouth",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-1.071842258805227, 50.750428873170918],
              [-1.072092658692399, 50.750167374556348],
              [-1.072500653740551, 50.750323497902826],
              [-1.072247385123923, 50.75058677313843],
              [-1.071842258805227, 50.750428873170918],
            ],
          ],
          [
            [
              [-1.098627389658817, 50.770601203180838],
              [-1.09885207224795, 50.770347542939213],
              [-1.099250510363799, 50.770498101970162],
              [-1.099031777169911, 50.770737420412075],
              [-1.098627389658817, 50.770601203180838],
            ],
          ],
          [
            [
              [-1.09627434145465, 50.814189000431597],
              [-1.096345117098271, 50.813684155954675],
              [-1.096638397498713, 50.814080308918385],
              [-1.09871980750215, 50.813685433091166],
              [-1.101656701564326, 50.813681098835424],
              [-1.100178399578367, 50.815711063543525],
              [-1.099415447659083, 50.818847242946461],
              [-1.098050009882593, 50.820310608636355],
              [-1.096205022200281, 50.820576909204441],
              [-1.09435261611602, 50.819317054893027],
              [-1.092612357047159, 50.815058062100604],
              [-1.093867890733661, 50.815021054885051],
              [-1.09627434145465, 50.814189000431597],
            ],
          ],
          [
            [
              [-1.054848297516904, 50.833428190645385],
              [-1.048572889093186, 50.832323255733854],
              [-1.049100961422934, 50.833240322778131],
              [-1.047694320552681, 50.831814295445838],
              [-1.044934282710892, 50.830888871274396],
              [-1.044727398067008, 50.831702820581675],
              [-1.044186896101328, 50.83167771223733],
              [-1.044706099221556, 50.826344763970617],
              [-1.044021253713028, 50.825951568598448],
              [-1.042330884117412, 50.825782135879486],
              [-1.041232540891155, 50.824297406235353],
              [-1.041891142595811, 50.823819903439308],
              [-1.041387949321601, 50.822462360530594],
              [-1.041753985912547, 50.821017531218764],
              [-1.043022477157565, 50.819731182500476],
              [-1.042307639978173, 50.815117422429701],
              [-1.042439743689235, 50.81386311487887],
              [-1.041335953388483, 50.812241647530328],
              [-1.040954375502245, 50.811883297486617],
              [-1.039460770363261, 50.811903387360147],
              [-1.040857574206609, 50.811689157130978],
              [-1.040578788572813, 50.810193164299129],
              [-1.041364297129478, 50.809469405596964],
              [-1.041174927661652, 50.808666592678719],
              [-1.042312159836144, 50.807050934598337],
              [-1.04177577399332, 50.804062723655392],
              [-1.039258158827736, 50.798389150291037],
              [-1.038281760419369, 50.796183265704947],
              [-1.038656057544494, 50.795782573045152],
              [-1.03832004927019, 50.794662897586925],
              [-1.041147352509217, 50.793160984892985],
              [-1.042537797505454, 50.791702078318799],
              [-1.042825494418934, 50.790814150429782],
              [-1.044303211793508, 50.79063561563791],
              [-1.044017495519239, 50.790175540971177],
              [-1.043653548513019, 50.789725615755316],
              [-1.041857824496148, 50.789568797231723],
              [-1.040977919497316, 50.789791784466665],
              [-1.040969603575385, 50.790128946640415],
              [-1.040619770773638, 50.790099991589209],
              [-1.04078810302637, 50.789846878901351],
              [-1.039796735658439, 50.790108509261195],
              [-1.034524670701251, 50.792316805611122],
              [-1.032008262551495, 50.792016280166308],
              [-1.030416189864478, 50.7931919117108],
              [-1.030347568368864, 50.795891883123019],
              [-1.031121671533725, 50.79653950067582],
              [-1.030403151223678, 50.796565906479337],
              [-1.029719010088488, 50.795891154523211],
              [-1.029184840543291, 50.793799479618606],
              [-1.029887269726521, 50.790915030642466],
              [-1.029205631035085, 50.789986698070052],
              [-1.0292614758674, 50.788934103364156],
              [-1.030338782473974, 50.78739989297349],
              [-1.032111788551917, 50.786795907977172],
              [-1.036371343256213, 50.78571965213991],
              [-1.054615570996673, 50.783130590973144],
              [-1.061985205197734, 50.781263810859883],
              [-1.066410965966697, 50.780471069920701],
              [-1.081831881427935, 50.778166284334183],
              [-1.089236039601956, 50.777711726847023],
              [-1.092315108811362, 50.779112520240098],
              [-1.093413063650279, 50.780280229030282],
              [-1.099232431267885, 50.784363965825406],
              [-1.100459038906874, 50.785126125473461],
              [-1.100916940490664, 50.785067604492539],
              [-1.101756581777362, 50.785593854627734],
              [-1.102226475147895, 50.786316894181525],
              [-1.101990179206001, 50.786581262358183],
              [-1.105626929164872, 50.788470701470281],
              [-1.107237078390244, 50.789813963777604],
              [-1.108820248972314, 50.790342251545518],
              [-1.109685379340121, 50.791552988691912],
              [-1.109376298543565, 50.792662137126882],
              [-1.108685357935278, 50.792885277914046],
              [-1.108253678667068, 50.792755181598551],
              [-1.107988607477842, 50.791847582973936],
              [-1.107780202522426, 50.792361275747382],
              [-1.106561366818139, 50.792366338255029],
              [-1.105861388512958, 50.791872668768868],
              [-1.106089542954874, 50.791514705705261],
              [-1.107607027602332, 50.791322184962191],
              [-1.106899417932136, 50.790335658918451],
              [-1.106497161761146, 50.790896426669029],
              [-1.105688357469468, 50.791125841377308],
              [-1.105318712582521, 50.792281277625584],
              [-1.106904644354863, 50.792816806410158],
              [-1.105094425497142, 50.792882073804961],
              [-1.107902747421886, 50.793153576972401],
              [-1.10872047602381, 50.794023132121026],
              [-1.108543109622032, 50.794396775886931],
              [-1.108316217092514, 50.794017345805067],
              [-1.106331077204764, 50.794845681479494],
              [-1.10729611157865, 50.794685802693039],
              [-1.10819561070937, 50.795434584378356],
              [-1.1085789433386, 50.795378375124635],
              [-1.108862536763544, 50.796518124904246],
              [-1.105857612300245, 50.79705427637289],
              [-1.106525410955524, 50.797900215411879],
              [-1.107318466735924, 50.798131104139323],
              [-1.107352877989827, 50.798559423118839],
              [-1.107938744241225, 50.798568399676597],
              [-1.108965899155893, 50.799324445452065],
              [-1.111262358152485, 50.798548796133183],
              [-1.111671811637951, 50.800528523321404],
              [-1.110894216245057, 50.800751017476038],
              [-1.109687879273559, 50.79948912800446],
              [-1.109425197221393, 50.79957255483302],
              [-1.110813885663384, 50.801095727463618],
              [-1.11171275762282, 50.800913725225492],
              [-1.111764715644151, 50.802514832077229],
              [-1.111086625626916, 50.802577114478467],
              [-1.111640004760151, 50.80277013862996],
              [-1.111539423262698, 50.806975264075241],
              [-1.107777307995976, 50.806966377929427],
              [-1.10555055411876, 50.805782997603302],
              [-1.103750943296025, 50.805712535947777],
              [-1.103876095340681, 50.807331294042129],
              [-1.105748379660524, 50.807389720641567],
              [-1.105371538008695, 50.807948879502327],
              [-1.106445225509631, 50.808059622912175],
              [-1.106397007369169, 50.808429755132444],
              [-1.105366249206369, 50.808448836064827],
              [-1.105352309222587, 50.808732900197953],
              [-1.106241414101454, 50.80884852515976],
              [-1.104006045986086, 50.810480654787717],
              [-1.0937898128776, 50.808564550541391],
              [-1.091351902806477, 50.809055483381179],
              [-1.090504125837201, 50.811637894322836],
              [-1.090959443407937, 50.811799714365435],
              [-1.091988591308272, 50.811208808769472],
              [-1.09263533866508, 50.811380205042319],
              [-1.095355735336716, 50.810819493368825],
              [-1.094781444931903, 50.811378885898172],
              [-1.096872206837538, 50.811303358413738],
              [-1.094540327637299, 50.811590143655451],
              [-1.093889545930197, 50.812800912860716],
              [-1.091223193905795, 50.814812542227486],
              [-1.091263787254668, 50.815573644979494],
              [-1.091745613379844, 50.815834588386721],
              [-1.090941843469281, 50.816071134556452],
              [-1.090668240440622, 50.816856767824035],
              [-1.093049905976885, 50.82068462679986],
              [-1.093777963081686, 50.823043681979385],
              [-1.094764125917135, 50.823218599871588],
              [-1.097471885986845, 50.822979680171635],
              [-1.099838060520087, 50.825220957108122],
              [-1.100228506681824, 50.826919996510846],
              [-1.09967378804787, 50.827266435204329],
              [-1.099508341381872, 50.8269360250153],
              [-1.098444003034376, 50.826760543750765],
              [-1.094883731377629, 50.827019856576591],
              [-1.093236038412586, 50.828007958150067],
              [-1.094102272531272, 50.829102804825069],
              [-1.093091444755586, 50.82902211382671],
              [-1.092078873702194, 50.828299319620818],
              [-1.092071211371407, 50.827742610010326],
              [-1.090955213650999, 50.827083748853454],
              [-1.090315134382187, 50.827873719303952],
              [-1.089474219223512, 50.827672919158545],
              [-1.090148446466476, 50.826514517406558],
              [-1.088580857718147, 50.826228008301769],
              [-1.088447257044598, 50.826964369151959],
              [-1.087892826182459, 50.826344035376124],
              [-1.08621044745573, 50.825974763335516],
              [-1.086076978980668, 50.8250294808958],
              [-1.085624369333474, 50.82494140411751],
              [-1.084568049600172, 50.824796434084647],
              [-1.083987567984749, 50.825366515670723],
              [-1.083339508128419, 50.827792164417922],
              [-1.083400589224906, 50.82880612527012],
              [-1.083879819851551, 50.829131827634583],
              [-1.083553636433377, 50.830054619075547],
              [-1.081558423287964, 50.830933712727706],
              [-1.081007913092789, 50.831707250741957],
              [-1.078904169032622, 50.832972131878101],
              [-1.07846896218558, 50.836036115919775],
              [-1.07662181402925, 50.836806582834136],
              [-1.072401932840469, 50.836767727936959],
              [-1.071425556916868, 50.836661943817546],
              [-1.071346795296757, 50.83640862155476],
              [-1.061661307285341, 50.835640518750232],
              [-1.057615930619836, 50.834698761412383],
              [-1.054848297516904, 50.833428190645385],
            ],
          ],
          [
            [
              [-1.084815933252379, 50.857360996206523],
              [-1.075646780703392, 50.856403873575985],
              [-1.071487915891084, 50.856566010728123],
              [-1.071055601981745, 50.85873250900795],
              [-1.067988178287886, 50.858228749549113],
              [-1.065752667845608, 50.858472568178478],
              [-1.06524858984001, 50.857675377063288],
              [-1.062176096010383, 50.857643540593337],
              [-1.062155461466503, 50.855484226977751],
              [-1.054354691926632, 50.855116443826901],
              [-1.054029975847078, 50.856560739640287],
              [-1.052288471145166, 50.856544816097788],
              [-1.051265379632113, 50.85592230388685],
              [-1.050596482276977, 50.856189344882239],
              [-1.04989439541343, 50.85585180186127],
              [-1.049782412796752, 50.855279853292117],
              [-1.046810403717795, 50.855065877590526],
              [-1.046985439506294, 50.85393602332767],
              [-1.037558248421724, 50.852954012469922],
              [-1.02311967458452, 50.852782818454415],
              [-1.022644184539928, 50.852082801088663],
              [-1.022534592149386, 50.850393048504188],
              [-1.024534313890214, 50.849800069086911],
              [-1.023864424824487, 50.847932973214967],
              [-1.025152755764432, 50.847730611811151],
              [-1.024999110566694, 50.84671944429514],
              [-1.024836039001803, 50.845956397215048],
              [-1.023456107031429, 50.846058169351309],
              [-1.022560886041704, 50.842811490945031],
              [-1.021719437031014, 50.842637176664759],
              [-1.021653976148251, 50.842099761120309],
              [-1.022367024344996, 50.841825161837662],
              [-1.020640821253073, 50.839068801993108],
              [-1.021881650287316, 50.837322922306257],
              [-1.0223797825796, 50.836251562321067],
              [-1.022077640316709, 50.835827271046689],
              [-1.021992268381344, 50.834763147078469],
              [-1.024066559619752, 50.832930265221556],
              [-1.024370523360471, 50.831495763541092],
              [-1.022923945145293, 50.830320898425953],
              [-1.02450850699933, 50.827122836761042],
              [-1.024144451929627, 50.826332926212324],
              [-1.024472807112973, 50.82658746872773],
              [-1.02751354275506, 50.826818779380105],
              [-1.028269009063126, 50.827771110602299],
              [-1.029157895519461, 50.827700270931388],
              [-1.029873902896081, 50.827270075650041],
              [-1.03151891011221, 50.828740564970452],
              [-1.03491049715176, 50.829318116435367],
              [-1.036138462429071, 50.833261694512238],
              [-1.036300663501837, 50.835384423484442],
              [-1.038786193012378, 50.834606362993568],
              [-1.041302098835056, 50.83442112122593],
              [-1.04336480672411, 50.833758193956335],
              [-1.04413330007408, 50.832146694816224],
              [-1.04452425662049, 50.832402592173906],
              [-1.044148499265534, 50.833905800670401],
              [-1.052325960247214, 50.833445507763663],
              [-1.054316108929368, 50.833904092299868],
              [-1.058721017257172, 50.835320983184197],
              [-1.06167550754638, 50.835923904029713],
              [-1.071022350453041, 50.836870963818903],
              [-1.074500170256369, 50.83694805146321],
              [-1.074864213161667, 50.837210827170367],
              [-1.078806238089557, 50.837226717089926],
              [-1.081816139847778, 50.835820589890695],
              [-1.086992617412334, 50.831006023279471],
              [-1.087910619558841, 50.829876519657446],
              [-1.087972879033485, 50.82864859918439],
              [-1.089182053662451, 50.828604984351934],
              [-1.089739667574809, 50.828917785112857],
              [-1.087978082423379, 50.831369839744937],
              [-1.088429340427514, 50.831530737654091],
              [-1.089723739045668, 50.830759371731261],
              [-1.094988976550482, 50.831344375881244],
              [-1.099547961294256, 50.834620611987511],
              [-1.100664364095863, 50.834904396228971],
              [-1.106289433975599, 50.837872030724171],
              [-1.106305104289642, 50.839133827548565],
              [-1.105156741696778, 50.838880428029562],
              [-1.106012682886657, 50.839646870374395],
              [-1.102630048276052, 50.845924799036645],
              [-1.103294022972435, 50.846410111444278],
              [-1.105628436528473, 50.846821900457456],
              [-1.111494252293812, 50.846703927324505],
              [-1.113188559878245, 50.847018066271595],
              [-1.113951770460729, 50.846770267913236],
              [-1.114938458589101, 50.845746291352405],
              [-1.114686547893738, 50.845547441139864],
              [-1.115978703090948, 50.845418746395424],
              [-1.115581256054966, 50.845029050044403],
              [-1.114725183024771, 50.845382268601419],
              [-1.112509271548741, 50.844693345621778],
              [-1.112159018002765, 50.844354718673628],
              [-1.113903402532153, 50.844974060083111],
              [-1.114118003167277, 50.844722103915117],
              [-1.112364186974246, 50.844125146015728],
              [-1.112465498208502, 50.84333265621229],
              [-1.113016130298844, 50.843193658007877],
              [-1.11454194812749, 50.843926720648511],
              [-1.116945840410688, 50.843921589066596],
              [-1.117718509181906, 50.844449007838421],
              [-1.117645305105285, 50.851494225167642],
              [-1.116739675326811, 50.851532339918741],
              [-1.116838019518897, 50.854524948676961],
              [-1.115938484026198, 50.854763640169011],
              [-1.115862051623278, 50.858280995947908],
              [-1.11302496480882, 50.858344006359879],
              [-1.099896869752573, 50.857162604806014],
              [-1.099169232374085, 50.858870098093362],
              [-1.094077081686336, 50.859309104792217],
              [-1.090791895168919, 50.858591128889323],
              [-1.084815933252379, 50.857360996206523],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000045",
        LAD13CDO: "00MS",
        LAD13NM: "Southampton",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.40136687420316, 50.95039827309833],
            [-1.400157883865116, 50.949763502976126],
            [-1.400138514156089, 50.950494486892175],
            [-1.398114671950599, 50.949464339309564],
            [-1.392871285766434, 50.949390451302222],
            [-1.392880794867857, 50.948878832060508],
            [-1.391297775416775, 50.948119728774635],
            [-1.390841491059891, 50.946543676851427],
            [-1.389831518225256, 50.945947604599418],
            [-1.387654957858881, 50.946390335236025],
            [-1.385224920493428, 50.946081723587547],
            [-1.383136642736823, 50.946195711754342],
            [-1.383082778039036, 50.945963422736291],
            [-1.381563874329743, 50.946077689987099],
            [-1.380495768994739, 50.947616930514741],
            [-1.379901951384165, 50.947526553968707],
            [-1.376828468516441, 50.947219746444183],
            [-1.370983636354875, 50.946060775622264],
            [-1.369829540994512, 50.947090484733195],
            [-1.368928217270559, 50.947215110969211],
            [-1.3669285483005, 50.94687696871496],
            [-1.35470070860768, 50.941030632289447],
            [-1.354579229814616, 50.940552462411119],
            [-1.356963807580394, 50.939477525383531],
            [-1.359008429839019, 50.937966339766298],
            [-1.356638274678275, 50.937695228488749],
            [-1.354587551954769, 50.93686560445672],
            [-1.352781009732524, 50.935366460099488],
            [-1.355963600068422, 50.933269022803998],
            [-1.357258605658137, 50.932544168767819],
            [-1.357943716912634, 50.930740451960418],
            [-1.354917068792254, 50.928783205099748],
            [-1.353143431690245, 50.926163783969955],
            [-1.351148615137799, 50.926675183573103],
            [-1.350143425358294, 50.925802722071758],
            [-1.344514826140104, 50.922857713096967],
            [-1.339498817359856, 50.923722412150148],
            [-1.337743625971763, 50.922484102158613],
            [-1.338508874615407, 50.921492970524007],
            [-1.336645086771151, 50.920495926122705],
            [-1.335844881705805, 50.92104261839274],
            [-1.335119473913314, 50.920931481204363],
            [-1.333367355437898, 50.919891855829547],
            [-1.332418039501434, 50.918130201644885],
            [-1.329832188027326, 50.915623575346352],
            [-1.329826210898015, 50.914950003286037],
            [-1.328583943750332, 50.914584068721496],
            [-1.329016588254022, 50.91437163416181],
            [-1.328598700218692, 50.913459192298582],
            [-1.329053096325936, 50.913316124835994],
            [-1.324649930416516, 50.908447387797857],
            [-1.328730322053895, 50.906679602320551],
            [-1.32771393622785, 50.905556887022072],
            [-1.328030667056608, 50.905190916034542],
            [-1.327540603874378, 50.904655738957707],
            [-1.327886130258247, 50.904462590311603],
            [-1.324034070352669, 50.904419677347654],
            [-1.321987971900872, 50.900962784857242],
            [-1.335060995295357, 50.897372594247116],
            [-1.335103254638794, 50.896301825725949],
            [-1.336153609468001, 50.895333011406855],
            [-1.3415931826759, 50.894165999746697],
            [-1.343334428635421, 50.89137193436747],
            [-1.346952934035313, 50.889433652053768],
            [-1.349392836545058, 50.888600173962885],
            [-1.349395717273718, 50.888597492252977],
            [-1.351039454087448, 50.886838706767151],
            [-1.354943367203916, 50.885699399609493],
            [-1.358630173531413, 50.885035373959539],
            [-1.360303605768066, 50.884084150184179],
            [-1.362704873212595, 50.881667496809065],
            [-1.365120641396595, 50.880048510211992],
            [-1.368746220912287, 50.883452953030542],
            [-1.371163409370407, 50.884994723135634],
            [-1.375412989948661, 50.886661360937495],
            [-1.378969445315171, 50.887445590351163],
            [-1.383134769900875, 50.88939386233163],
            [-1.384136469041872, 50.890734536970974],
            [-1.384425965924696, 50.89293383260523],
            [-1.382796920295766, 50.897856724897672],
            [-1.382676037191394, 50.897857884542084],
            [-1.382461067934241, 50.897984441658316],
            [-1.382335435271465, 50.898452286631795],
            [-1.383139818477792, 50.899456503631257],
            [-1.382886848232123, 50.900090936950384],
            [-1.382228025015187, 50.90012252492086],
            [-1.382270239711245, 50.900478851101703],
            [-1.382750903184332, 50.901231366464486],
            [-1.383579148976477, 50.901078371837414],
            [-1.383950490934743, 50.901603694264658],
            [-1.383619201046834, 50.901707158645401],
            [-1.384040429823389, 50.901793010993373],
            [-1.384058258210994, 50.902382113389116],
            [-1.384630974711879, 50.902735839393628],
            [-1.384828498758222, 50.903503040237702],
            [-1.383792372360968, 50.903456213140764],
            [-1.383783963688897, 50.903877916653535],
            [-1.384844701573345, 50.904000410352616],
            [-1.383621016178348, 50.904047914218218],
            [-1.383354626379482, 50.905050969372745],
            [-1.382206181349893, 50.906181551382879],
            [-1.381643719684413, 50.906340439896788],
            [-1.38102048944308, 50.905611444998563],
            [-1.380296280120124, 50.906384556052096],
            [-1.381303588591788, 50.906571544223617],
            [-1.373082146796443, 50.909816260233562],
            [-1.372112925837706, 50.910707605121665],
            [-1.371797239587012, 50.911970251061156],
            [-1.372850268000991, 50.913842748736322],
            [-1.376757263577789, 50.916331195515454],
            [-1.377814401624149, 50.916530169179318],
            [-1.377529145638907, 50.914821241798059],
            [-1.379018758631068, 50.915146193665322],
            [-1.379035335081332, 50.91548667576965],
            [-1.38089627952519, 50.915680092669334],
            [-1.381400664990029, 50.916205703248075],
            [-1.383986777175623, 50.916425297316181],
            [-1.385544482118173, 50.914694357127743],
            [-1.38608581576155, 50.914958884389634],
            [-1.385467737895448, 50.915661543590168],
            [-1.387257601463632, 50.916502749649595],
            [-1.387661810953031, 50.917029130246185],
            [-1.387076610205112, 50.918152817605723],
            [-1.386396736795624, 50.918035044016477],
            [-1.385835379175689, 50.918208346381839],
            [-1.385967831089272, 50.91841317172311],
            [-1.383195097881988, 50.918957895719181],
            [-1.378038327597755, 50.920970045283347],
            [-1.376916808703648, 50.921856121406044],
            [-1.377235288359913, 50.922191440174345],
            [-1.376763244506427, 50.922169139296912],
            [-1.376592845522508, 50.922677203635416],
            [-1.377390861028516, 50.923113997112146],
            [-1.377126614213066, 50.924151218013805],
            [-1.379845584205354, 50.927894856466843],
            [-1.380138577243515, 50.929403546899927],
            [-1.379170369577267, 50.930518865335252],
            [-1.379512252023673, 50.930919047278479],
            [-1.379222780573605, 50.931499321075314],
            [-1.380253601620777, 50.932099194927147],
            [-1.379761573021495, 50.93271616352331],
            [-1.378827870018915, 50.933051117315912],
            [-1.378860679544732, 50.933900178851481],
            [-1.377413616735275, 50.93431152436515],
            [-1.376059148495881, 50.934182001176353],
            [-1.375887158667372, 50.934485926487724],
            [-1.376653890845624, 50.934615914209836],
            [-1.376562820042891, 50.935147781103126],
            [-1.375570483523928, 50.934967128756746],
            [-1.375790544550562, 50.935431415727166],
            [-1.37460561816902, 50.935710139300873],
            [-1.374115972087568, 50.936459285631443],
            [-1.377554463555709, 50.934954335555013],
            [-1.379929956827842, 50.934394148815599],
            [-1.380226901817944, 50.934001855322286],
            [-1.379646088447823, 50.933588717080042],
            [-1.38084546037454, 50.932429658284917],
            [-1.3804584424918, 50.930818857190381],
            [-1.381317603268019, 50.929018625027105],
            [-1.380957541547664, 50.926878311666641],
            [-1.379557368083493, 50.925065197925711],
            [-1.379476897048219, 50.924161927389022],
            [-1.380003906166863, 50.922725931485409],
            [-1.380958308777421, 50.922208530751917],
            [-1.380031649663406, 50.921280090325034],
            [-1.38039603303293, 50.921159726054078],
            [-1.381205951647403, 50.921879819338798],
            [-1.383687521071225, 50.920882184970701],
            [-1.384046611162959, 50.921161946104597],
            [-1.38616569405387, 50.920783726595992],
            [-1.387084058143894, 50.920510681491272],
            [-1.387342904547946, 50.919866380296313],
            [-1.38804914404008, 50.919603005860196],
            [-1.388187673301291, 50.919887893391092],
            [-1.389202816406913, 50.919174707623888],
            [-1.39015938061916, 50.918260675180768],
            [-1.390947713391623, 50.916390757578469],
            [-1.38969329730206, 50.915173823850772],
            [-1.386630112684955, 50.913699200256616],
            [-1.383194979984828, 50.913583993308059],
            [-1.383196741556139, 50.913343004221488],
            [-1.383031314229958, 50.913590322960381],
            [-1.381047747282746, 50.913426058005186],
            [-1.380920458379969, 50.912083705588678],
            [-1.37990866692482, 50.912760868032265],
            [-1.378024422720158, 50.91219151794909],
            [-1.377525454735742, 50.911315690046898],
            [-1.377732571632968, 50.910289852017236],
            [-1.383865257771737, 50.90871090852324],
            [-1.385587894720461, 50.90772091738522],
            [-1.3864272245224, 50.908247792670451],
            [-1.386694409661826, 50.908046864965051],
            [-1.385907724352649, 50.907518469867611],
            [-1.387098027766551, 50.906770252123231],
            [-1.387430819818555, 50.907313345505585],
            [-1.387342908443802, 50.906750854085864],
            [-1.387942555430391, 50.906900575272068],
            [-1.387691103135063, 50.906661855774409],
            [-1.38825531321768, 50.906042532014368],
            [-1.387919185054689, 50.905753910781819],
            [-1.388291998557418, 50.905087721964911],
            [-1.389141735255113, 50.902317086529571],
            [-1.389755350984841, 50.902264540593428],
            [-1.389988942883282, 50.901583230602654],
            [-1.387840710208562, 50.901107978700345],
            [-1.387123738051422, 50.899826385160878],
            [-1.387205009609039, 50.897317005217616],
            [-1.388002981560935, 50.895452547314378],
            [-1.388977232825775, 50.895542177739493],
            [-1.38844218431006, 50.896659843136383],
            [-1.391160227120913, 50.896713610373148],
            [-1.392456736156238, 50.895971286332319],
            [-1.392476745840782, 50.895198035293362],
            [-1.391297358203692, 50.895137939651633],
            [-1.392870256290424, 50.894897034181831],
            [-1.392645231448623, 50.894269086786153],
            [-1.390721547544395, 50.894370574512983],
            [-1.390288054847979, 50.894030194172132],
            [-1.389618324476137, 50.894468227981278],
            [-1.388811355669798, 50.894526955434031],
            [-1.388401974879041, 50.894192089754839],
            [-1.389347981923892, 50.892959670938687],
            [-1.389860450429103, 50.893021699776327],
            [-1.391089094863746, 50.890934656106481],
            [-1.390729410523992, 50.890823970932168],
            [-1.391161736580107, 50.890815434439588],
            [-1.392673023188566, 50.88841241093612],
            [-1.393516677537081, 50.889023789549853],
            [-1.393479574410222, 50.891224963184989],
            [-1.396399587631766, 50.892569183029664],
            [-1.396693659302984, 50.890275814813656],
            [-1.394600851329745, 50.888630147030185],
            [-1.394739513168772, 50.888242389217339],
            [-1.393595394130068, 50.888216670738352],
            [-1.392902446539172, 50.887386658755588],
            [-1.393279851199157, 50.883065017791914],
            [-1.395350312719126, 50.883118919896773],
            [-1.399091262323987, 50.888685710312302],
            [-1.399225484023432, 50.888198107528247],
            [-1.399498024098331, 50.888341590825661],
            [-1.397980500479737, 50.893021572278521],
            [-1.399031377538001, 50.89455750813611],
            [-1.400830690481882, 50.890294414148663],
            [-1.401397166321168, 50.891133621953735],
            [-1.401026884740913, 50.892180249591632],
            [-1.401372894014106, 50.892251265845303],
            [-1.400895798298207, 50.892534781138593],
            [-1.401365414384729, 50.892502118352034],
            [-1.401612854548589, 50.892831612705088],
            [-1.401884962615489, 50.892678335461255],
            [-1.403550520556098, 50.895637292237197],
            [-1.407126838643352, 50.896403676269266],
            [-1.411435980871672, 50.89792811559191],
            [-1.410878321891745, 50.89862402074661],
            [-1.41673535475286, 50.900573311594798],
            [-1.439737984876589, 50.908203617494003],
            [-1.44097244642076, 50.908091730454046],
            [-1.441275425082742, 50.907851282672006],
            [-1.442850590580991, 50.908268862668983],
            [-1.44703902446986, 50.903841989393015],
            [-1.450891073995612, 50.904131733548638],
            [-1.4618330741278, 50.90883086157011],
            [-1.461605738964793, 50.909057323227891],
            [-1.464358413668806, 50.910253384977338],
            [-1.467150962040439, 50.911457656457131],
            [-1.471181724914074, 50.918137577311441],
            [-1.471657567383954, 50.9180758839533],
            [-1.473507469802051, 50.919793698363208],
            [-1.473965934031598, 50.920517856355623],
            [-1.473439749061992, 50.920500198786911],
            [-1.474437772787133, 50.921192614205189],
            [-1.475107640884428, 50.922350254428196],
            [-1.476529330744281, 50.923202821149843],
            [-1.477018877860749, 50.924334462998097],
            [-1.478733153080136, 50.925241367136998],
            [-1.478247715393657, 50.925646557269864],
            [-1.476932383576932, 50.925704522052939],
            [-1.476913265811866, 50.92677543460092],
            [-1.475706212072841, 50.927704337230693],
            [-1.475647010677076, 50.928287679955638],
            [-1.477329956630707, 50.928572187583718],
            [-1.476397294732406, 50.92913813117989],
            [-1.474129874510798, 50.928948099093375],
            [-1.474368131308083, 50.929523787125881],
            [-1.47188366390941, 50.930028748379605],
            [-1.471569629601469, 50.931623483411542],
            [-1.470158332430411, 50.931595510023683],
            [-1.466219338541071, 50.934716834516827],
            [-1.464117130026069, 50.935114568120859],
            [-1.464855298466054, 50.936019890836455],
            [-1.465123487293484, 50.937949991831751],
            [-1.464760598318531, 50.938692002565368],
            [-1.463668858458099, 50.939941437729829],
            [-1.45943243741384, 50.943205043530334],
            [-1.455964390934246, 50.946076409061881],
            [-1.452444781535128, 50.943604142645626],
            [-1.451017084945169, 50.944334820943034],
            [-1.449968582496611, 50.947185878056963],
            [-1.451781907913439, 50.949138556999777],
            [-1.449554822816143, 50.949919418233591],
            [-1.449269540550318, 50.949003546886729],
            [-1.447000566920793, 50.948152041123173],
            [-1.446616278408648, 50.948267123030128],
            [-1.441302504639863, 50.94706383133439],
            [-1.440748741232805, 50.947066577659534],
            [-1.439663159873926, 50.948325709078752],
            [-1.435726110327705, 50.949000077211245],
            [-1.434626483095706, 50.948937210367056],
            [-1.429779920000209, 50.947378655215743],
            [-1.423793061051549, 50.947238678439383],
            [-1.421959111067074, 50.950954276143399],
            [-1.421047475846352, 50.951685341603991],
            [-1.417183128492427, 50.951738070066682],
            [-1.417193349302478, 50.951148219112596],
            [-1.416473869380526, 50.95142069522344],
            [-1.416375609232824, 50.9508545819996],
            [-1.415137221125323, 50.950843893752321],
            [-1.414276273332215, 50.949345042570435],
            [-1.411935784060706, 50.950375507196405],
            [-1.409620208024633, 50.949767633448865],
            [-1.409256632203473, 50.95172254453459],
            [-1.407375707422158, 50.953540277483683],
            [-1.407125996278118, 50.954704426659184],
            [-1.406505002577356, 50.954724654443446],
            [-1.406528981864045, 50.955860517004652],
            [-1.406149610019607, 50.956135555644067],
            [-1.404696872381592, 50.955283776748665],
            [-1.402541724697818, 50.955254792405576],
            [-1.403415038703769, 50.954208941312288],
            [-1.403928418259879, 50.952360923520239],
            [-1.40136687420316, 50.95039827309833],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000046",
        LAD13CDO: "00MW",
        LAD13NM: "Isle of Wight",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-1.588803130596351, 50.662714413329454],
              [-1.589295393567784, 50.662564169484128],
              [-1.589934446313622, 50.662616777896567],
              [-1.588803130596351, 50.662714413329454],
            ],
          ],
          [
            [
              [-1.08347981401799, 50.704974619212315],
              [-1.083660481955449, 50.704860030370213],
              [-1.083698176771752, 50.705105833305161],
              [-1.08347981401799, 50.704974619212315],
            ],
          ],
          [
            [
              [-1.094414780278062, 50.740144906558754],
              [-1.094790253272698, 50.73978181156361],
              [-1.095372556605868, 50.740011150690961],
              [-1.094997172864669, 50.740369751802234],
              [-1.094414780278062, 50.740144906558754],
            ],
          ],
          [
            [
              [-1.297785363946156, 50.764945606565618],
              [-1.298035776499416, 50.76469891389376],
              [-1.297632894240415, 50.764806200700285],
              [-1.29797429110602, 50.764354122165912],
              [-1.29754127408684, 50.764201337247364],
              [-1.297463919305469, 50.763401417139526],
              [-1.297082121318135, 50.763426995083286],
              [-1.297141617940125, 50.762862609526671],
              [-1.296514286126718, 50.76252609737665],
              [-1.297170361485955, 50.762458109407582],
              [-1.297190952133598, 50.762124602791694],
              [-1.296293486971301, 50.762121891104201],
              [-1.295756115411712, 50.761177108743325],
              [-1.296476020925361, 50.761011489076267],
              [-1.296491527098842, 50.760733706838202],
              [-1.295503460439948, 50.76006228184167],
              [-1.294882413670249, 50.759971300530083],
              [-1.294446200130883, 50.760223158083896],
              [-1.294554879877873, 50.759878493872499],
              [-1.294078718289377, 50.759958347089423],
              [-1.294021498566071, 50.759426529635803],
              [-1.292792203555488, 50.758961353916987],
              [-1.29219827460372, 50.758013513774451],
              [-1.293121499125943, 50.755923800385702],
              [-1.294194022524422, 50.754835875856969],
              [-1.293570249079625, 50.75445980056692],
              [-1.294242039770023, 50.754282212763727],
              [-1.2943490676572, 50.753480706520548],
              [-1.294937750645478, 50.753361064915403],
              [-1.294444823387398, 50.753334703755733],
              [-1.293869351834633, 50.750596524715192],
              [-1.292340149381964, 50.747285116651618],
              [-1.292940041138152, 50.746039658902951],
              [-1.291683941295918, 50.746059916681631],
              [-1.290147476745006, 50.74381137821765],
              [-1.290128810123966, 50.743170979313433],
              [-1.291745984300359, 50.742885845838778],
              [-1.290459615448406, 50.742757525756197],
              [-1.290739833852595, 50.742596460603124],
              [-1.290048672664169, 50.742096754586306],
              [-1.290349490955972, 50.742541928634367],
              [-1.290004578938398, 50.742482276202232],
              [-1.289946883926168, 50.742829945348056],
              [-1.289384548754372, 50.741684439014485],
              [-1.28971488184507, 50.741114510311874],
              [-1.289532690797659, 50.739667362430239],
              [-1.290275658384255, 50.739268109290528],
              [-1.29009469592155, 50.738489132844393],
              [-1.289065220693428, 50.737781426508626],
              [-1.289394028765537, 50.737123359833561],
              [-1.288618031749341, 50.737272406711767],
              [-1.287582420615924, 50.736505297253061],
              [-1.286614897979461, 50.734895970875606],
              [-1.287771169412494, 50.734150340235622],
              [-1.287948186513573, 50.733783615937703],
              [-1.28650171876553, 50.733857511083336],
              [-1.286925535982802, 50.733568736318958],
              [-1.285660622699097, 50.733910816520186],
              [-1.284750891616561, 50.73353923546258],
              [-1.285043568413422, 50.73274336928786],
              [-1.283910908218212, 50.730823654754545],
              [-1.284183576631643, 50.730596911389014],
              [-1.282943197639865, 50.729892351484118],
              [-1.283635022511322, 50.730053076909009],
              [-1.283575563776315, 50.729306309754136],
              [-1.282931486376335, 50.729731308460117],
              [-1.283610985141461, 50.728284046363193],
              [-1.285065685223858, 50.727658080065211],
              [-1.285109817994904, 50.727271660251368],
              [-1.283769787558772, 50.727160921826922],
              [-1.283517615153479, 50.726787970528868],
              [-1.284670722654724, 50.724378682222969],
              [-1.284836482131015, 50.722330238596754],
              [-1.286909481221397, 50.71931415306711],
              [-1.287477851888347, 50.717143164862634],
              [-1.288342088286546, 50.715822001285396],
              [-1.289477770519032, 50.714999787743501],
              [-1.289256704134961, 50.713507431866361],
              [-1.289898663800451, 50.710484359047577],
              [-1.290559390105815, 50.709027945586939],
              [-1.291403327394643, 50.708380197080437],
              [-1.290788007869696, 50.708049118146093],
              [-1.291996130355137, 50.707103218931437],
              [-1.292269757857914, 50.705483472928314],
              [-1.29090891671366, 50.703317826793608],
              [-1.292034595186243, 50.701969228067142],
              [-1.291602881308032, 50.701902075752791],
              [-1.289935330439916, 50.701302903994765],
              [-1.289506857980757, 50.700514320495763],
              [-1.289562382126286, 50.699556921505938],
              [-1.290316547943492, 50.698748560681004],
              [-1.29000285325754, 50.697673805412059],
              [-1.290149427013475, 50.698751140696977],
              [-1.289440429446975, 50.699568768499063],
              [-1.289435714381348, 50.700630793930834],
              [-1.289889727177317, 50.701415936058545],
              [-1.291613043956976, 50.701979475857613],
              [-1.290356286551685, 50.703341443916138],
              [-1.291324322700792, 50.705916581886292],
              [-1.291095910329961, 50.706789296580816],
              [-1.289115944371809, 50.708390557876903],
              [-1.287443157962762, 50.712983775596953],
              [-1.28650955227166, 50.713837781516709],
              [-1.286614334734685, 50.71458662487823],
              [-1.285878704035991, 50.714619892917142],
              [-1.285667183947696, 50.71493064837999],
              [-1.285816581716817, 50.716005305494264],
              [-1.284919201995312, 50.717358621392393],
              [-1.284779410263395, 50.718632945052484],
              [-1.282738536461792, 50.718701347769247],
              [-1.284208358725765, 50.719017032108468],
              [-1.282397239246951, 50.72296363845043],
              [-1.281176231897355, 50.723786156020864],
              [-1.280111614454369, 50.725124913740984],
              [-1.278265565312129, 50.725772678184448],
              [-1.278435481441947, 50.726424808330421],
              [-1.277127954793293, 50.731479658098472],
              [-1.276783607903515, 50.731573746540583],
              [-1.276878300050764, 50.731129190244964],
              [-1.276089497734849, 50.731193542111122],
              [-1.276087614471011, 50.73168363776184],
              [-1.275617989349644, 50.731729084665993],
              [-1.27616285143264, 50.731858564875942],
              [-1.276238227357549, 50.731380615900058],
              [-1.276735269457592, 50.731308159378834],
              [-1.276593483930556, 50.731682279702177],
              [-1.277484877150022, 50.731673415958802],
              [-1.28079407236994, 50.733205554568805],
              [-1.28226411192476, 50.736040142916245],
              [-1.280492777316426, 50.736502251253249],
              [-1.282268750943739, 50.736849522121545],
              [-1.284418148338211, 50.7380812491268],
              [-1.284136351486455, 50.738439231429396],
              [-1.284578939079628, 50.738410471876449],
              [-1.284006893039448, 50.738657860773998],
              [-1.28498578760803, 50.73877447611271],
              [-1.285558702126023, 50.739960536809413],
              [-1.286392795663831, 50.740561863327123],
              [-1.28618982186027, 50.74375305659477],
              [-1.287150100161001, 50.744636622918463],
              [-1.287778736692305, 50.744773555550573],
              [-1.289177685198474, 50.746363016679368],
              [-1.289330051253808, 50.746878331225318],
              [-1.28856719336208, 50.747085011588126],
              [-1.289230703634039, 50.747448766624501],
              [-1.288886634238117, 50.747799186246965],
              [-1.289547194663682, 50.749014536159756],
              [-1.290162131228345, 50.749124393989845],
              [-1.290461159721423, 50.749972431954696],
              [-1.289635987271444, 50.750172445349804],
              [-1.289477570641082, 50.750805470284597],
              [-1.290740146913013, 50.750740311605739],
              [-1.290926592256628, 50.751816978942713],
              [-1.290523325003487, 50.751862188861395],
              [-1.291158149513923, 50.753192479378377],
              [-1.29100853283306, 50.753806674727556],
              [-1.290401131302376, 50.753850643525212],
              [-1.290742626634677, 50.753954338229128],
              [-1.290475585574134, 50.754364582661459],
              [-1.290290259929055, 50.754995646051341],
              [-1.291001941397249, 50.755369575319982],
              [-1.29029141153149, 50.756513629681081],
              [-1.290486652094665, 50.757102943090565],
              [-1.290929892564223, 50.757136213123495],
              [-1.290444698166746, 50.757439916074304],
              [-1.290853046364186, 50.757718476240697],
              [-1.290360421399377, 50.758138140120295],
              [-1.290509761637091, 50.758763145110507],
              [-1.290025955711742, 50.7587862809876],
              [-1.290457597355253, 50.759118940685013],
              [-1.290320855581626, 50.760286266767011],
              [-1.289299777300556, 50.761146052771487],
              [-1.288392214483183, 50.762932774715928],
              [-1.291846889518692, 50.764671407244379],
              [-1.2882776214289, 50.76300941332763],
              [-1.278981207997194, 50.766010765931128],
              [-1.277324669819254, 50.766308968345477],
              [-1.272969096553224, 50.765911408158502],
              [-1.271132420343018, 50.765389161037533],
              [-1.267260996985339, 50.7635978369687],
              [-1.265010936092657, 50.762048621544295],
              [-1.261546749091334, 50.760629272769954],
              [-1.2600036793604, 50.759670759891087],
              [-1.256637052315942, 50.755065746337173],
              [-1.255020104422335, 50.753956516008941],
              [-1.248176712098163, 50.752176560515544],
              [-1.246876401614971, 50.750920425770595],
              [-1.24630437330523, 50.749555224888567],
              [-1.242132364179484, 50.746327664706172],
              [-1.240177715254761, 50.743966932678887],
              [-1.239275018523997, 50.743258712511228],
              [-1.238435655135914, 50.743103955464413],
              [-1.240089282332593, 50.741811683634829],
              [-1.240821836462392, 50.742347927485476],
              [-1.241383392770757, 50.742328199477832],
              [-1.241167230053777, 50.74263704486917],
              [-1.241716479049155, 50.742238638638845],
              [-1.242374831388362, 50.741050471914782],
              [-1.242093374639538, 50.74040026309266],
              [-1.242664981622041, 50.738788869336531],
              [-1.242419658656228, 50.738622708158886],
              [-1.241452502165749, 50.738926674511482],
              [-1.240589853677132, 50.740304153911758],
              [-1.240699597304619, 50.741054867428232],
              [-1.241601594164641, 50.741103001263106],
              [-1.241444272294138, 50.741893344200136],
              [-1.2409011802812, 50.742173983647071],
              [-1.240444394557311, 50.741674608098648],
              [-1.23983874105553, 50.741704835574112],
              [-1.238038951981399, 50.742833379714554],
              [-1.238031922329808, 50.743354016496461],
              [-1.239239619827756, 50.743869092206594],
              [-1.237506995025718, 50.743473787812235],
              [-1.231019165573051, 50.74300584122485],
              [-1.221440308547397, 50.739293923544587],
              [-1.21588719948991, 50.738651511817487],
              [-1.213846405889108, 50.738064034407266],
              [-1.213442888196899, 50.737534335447322],
              [-1.215346334550586, 50.735737801142321],
              [-1.216057556943311, 50.735661647908167],
              [-1.215390459703834, 50.736065436354856],
              [-1.216178457407197, 50.735804546829165],
              [-1.216784649065156, 50.736078403033204],
              [-1.217264263880542, 50.735954823745132],
              [-1.219779949347092, 50.734567000440748],
              [-1.219729629341149, 50.733160187668808],
              [-1.220387852035443, 50.73234714192062],
              [-1.220142450009473, 50.73202176005644],
              [-1.220641178526669, 50.731938762369865],
              [-1.220358133213622, 50.73183255415875],
              [-1.222238944339825, 50.731202123929066],
              [-1.22301138598452, 50.731531912625968],
              [-1.223587621067525, 50.731388267465341],
              [-1.222874292133812, 50.731339452328889],
              [-1.223226738720723, 50.731276152390272],
              [-1.222963365486762, 50.730922779021355],
              [-1.222522608641489, 50.731099698348658],
              [-1.222729268450599, 50.730512945365042],
              [-1.223330929179077, 50.730287636254232],
              [-1.223227860052906, 50.729922740757182],
              [-1.224241371942284, 50.729038297433057],
              [-1.226441652202426, 50.727409922114475],
              [-1.22686195711155, 50.727439687298684],
              [-1.226717355094965, 50.726756173548402],
              [-1.227185139992635, 50.726827619333271],
              [-1.226463856803583, 50.726319240031565],
              [-1.22647737865276, 50.725325622669438],
              [-1.225471449349723, 50.724639988846107],
              [-1.225126960660963, 50.724567556717744],
              [-1.224541763305777, 50.725259712256694],
              [-1.224541612825183, 50.727243528234965],
              [-1.224189357253226, 50.727640467099356],
              [-1.22289298795558, 50.727981658080253],
              [-1.220732152219333, 50.729871021920232],
              [-1.220030462124411, 50.729122625597853],
              [-1.2195143033245, 50.729062518151231],
              [-1.219784056587902, 50.729968101375064],
              [-1.218078901083531, 50.731027727981129],
              [-1.217437281277685, 50.732118750399316],
              [-1.217366461179802, 50.733903349365931],
              [-1.21706386332897, 50.734119844085761],
              [-1.216524834846182, 50.733728634441761],
              [-1.215662035781619, 50.733973735577564],
              [-1.216346728437368, 50.73421215076867],
              [-1.216140756168349, 50.734412205865937],
              [-1.217113758110909, 50.734612085945109],
              [-1.217028087447222, 50.73482104349074],
              [-1.214554155626407, 50.733875453278635],
              [-1.214168979166622, 50.734199298224141],
              [-1.213231508998696, 50.733909705007136],
              [-1.21216163254764, 50.734080541533046],
              [-1.210776995601399, 50.73502082269291],
              [-1.207207515607087, 50.734849134622216],
              [-1.197602332899624, 50.733797842412891],
              [-1.194950238673507, 50.732938739034942],
              [-1.192999319812209, 50.732757991111498],
              [-1.190132062621471, 50.732881104359251],
              [-1.186626041050934, 50.733477213852431],
              [-1.183769098405183, 50.733003118703124],
              [-1.182663727028468, 50.733254371866821],
              [-1.178779989437756, 50.734131771809132],
              [-1.172075177430457, 50.73321656504497],
              [-1.164296969066514, 50.732756417487131],
              [-1.162857026890936, 50.733249690757987],
              [-1.162837763366512, 50.733535524813789],
              [-1.161266537120551, 50.733522438515877],
              [-1.160614734808059, 50.733124762576779],
              [-1.160186908237763, 50.733198122144806],
              [-1.159522634172309, 50.733339920772195],
              [-1.158641600798444, 50.732846156892457],
              [-1.152249167796524, 50.73121982778671],
              [-1.150420855908038, 50.731088718534984],
              [-1.150016013969413, 50.731379835165292],
              [-1.149834030008661, 50.731100629794788],
              [-1.148431694445338, 50.731155148324603],
              [-1.14817209514461, 50.730780948697713],
              [-1.146462051562558, 50.730380855409727],
              [-1.143766031405471, 50.729039145393919],
              [-1.141091546667683, 50.727690336661716],
              [-1.139285231035644, 50.727373062078449],
              [-1.138904720486335, 50.726875646096801],
              [-1.137146503640469, 50.726487649709505],
              [-1.136868049090802, 50.726683432335186],
              [-1.130166269319485, 50.726776630381032],
              [-1.120806126512135, 50.723332322043071],
              [-1.117378764548637, 50.722823517474254],
              [-1.114646098400923, 50.721989848920082],
              [-1.111419526426702, 50.721959015448213],
              [-1.10960202776582, 50.72150630116262],
              [-1.108198191829008, 50.72015923235729],
              [-1.107860257546347, 50.718110765900761],
              [-1.106929590418455, 50.717141403614036],
              [-1.106269932938804, 50.714212760609811],
              [-1.105234311438319, 50.713107686032416],
              [-1.103141061083428, 50.712652753288928],
              [-1.102149030198353, 50.70852906810623],
              [-1.101404582754426, 50.707391127011164],
              [-1.100411147052033, 50.706463493043586],
              [-1.098148366974362, 50.706374072145252],
              [-1.097780447982167, 50.706063668616729],
              [-1.098281599205122, 50.705482106274467],
              [-1.098138729567476, 50.702962985316248],
              [-1.099294714180701, 50.701386468582584],
              [-1.099286671122283, 50.700771290802599],
              [-1.097918708256509, 50.69649357423927],
              [-1.096938896760782, 50.695461695645676],
              [-1.096987959274711, 50.694971061688165],
              [-1.099109126341796, 50.694737465824929],
              [-1.099569912744091, 50.69529588863486],
              [-1.100719610263834, 50.695516994093069],
              [-1.101110622820528, 50.695875229590072],
              [-1.101101721647469, 50.696711503024339],
              [-1.102405670046103, 50.697235942915761],
              [-1.103940695510381, 50.696334967182949],
              [-1.102560059877668, 50.697307276051454],
              [-1.10294575105959, 50.697946043425958],
              [-1.101777622260323, 50.698464036426117],
              [-1.102163919592667, 50.699590227207523],
              [-1.102841101922099, 50.699795082073045],
              [-1.103989713591746, 50.698518825358477],
              [-1.106049193755487, 50.69729091127104],
              [-1.107063969951596, 50.695971331239477],
              [-1.107425996116488, 50.694800526448972],
              [-1.106510151132045, 50.694640632603402],
              [-1.105319827893007, 50.695289787720995],
              [-1.106473041088003, 50.694507252944653],
              [-1.107449159173305, 50.69454980115426],
              [-1.107115979525543, 50.694126381441784],
              [-1.108351264546843, 50.693940687488627],
              [-1.108272752411639, 50.69352641251168],
              [-1.101769419448827, 50.692071801066852],
              [-1.09932967528073, 50.690686954623999],
              [-1.098319151430605, 50.690523566777088],
              [-1.096888302035938, 50.690869512679818],
              [-1.095026845862095, 50.691888368527437],
              [-1.09251777506238, 50.694181850728157],
              [-1.092603535401048, 50.694433420953999],
              [-1.094474517931987, 50.694840057524992],
              [-1.093631770541804, 50.695811940677025],
              [-1.092278440549538, 50.695358961196575],
              [-1.088142174717692, 50.695167513551276],
              [-1.082358674857209, 50.694427887570953],
              [-1.075591785630175, 50.691145071159234],
              [-1.070694899532935, 50.687840805183598],
              [-1.069778713822986, 50.68656549277204],
              [-1.06984712352136, 50.683892432300155],
              [-1.073435284660135, 50.681384987932177],
              [-1.077967238726731, 50.680243737250919],
              [-1.083220717119721, 50.67808998329356],
              [-1.085608640830339, 50.676911772689948],
              [-1.087751529905708, 50.674568805976413],
              [-1.091383466041895, 50.673592653922675],
              [-1.094717270833077, 50.671995358329305],
              [-1.096512440666627, 50.6704813791726],
              [-1.097249995022822, 50.669064405219601],
              [-1.097216611502336, 50.668222404807551],
              [-1.095368358749774, 50.667349248957962],
              [-1.097831387038837, 50.665751398872217],
              [-1.097938909325493, 50.665235134921893],
              [-1.103191801071216, 50.664981591813152],
              [-1.108872273467718, 50.665533254048427],
              [-1.111517908356241, 50.665776472523177],
              [-1.114614594963936, 50.665586898474118],
              [-1.124881382864801, 50.663921582388227],
              [-1.133565183868867, 50.661895581207503],
              [-1.143233135949097, 50.65845798094935],
              [-1.151997425242744, 50.654453647516355],
              [-1.159114505775621, 50.650564485357961],
              [-1.160622860358023, 50.64934601739143],
              [-1.166047505426576, 50.6439828745197],
              [-1.167480774054899, 50.642429241405878],
              [-1.168544026399253, 50.640360351934724],
              [-1.169019872569012, 50.636872669832471],
              [-1.169249615123798, 50.636273576121965],
              [-1.168766369554067, 50.636012032238703],
              [-1.169999108927367, 50.633942537685407],
              [-1.169192897329489, 50.633714667111548],
              [-1.169172119388667, 50.633369188227881],
              [-1.170695804130879, 50.63139348763292],
              [-1.174195584821077, 50.625079174125673],
              [-1.174651743652367, 50.623167795365909],
              [-1.174450365158573, 50.620619550263584],
              [-1.173328671454983, 50.619449702879407],
              [-1.172307444439265, 50.615376713950347],
              [-1.175732214781957, 50.612303791681043],
              [-1.177013655763919, 50.61054841761198],
              [-1.175305655212238, 50.608163983893576],
              [-1.175185112118375, 50.606894214821118],
              [-1.176343828796232, 50.605604716309095],
              [-1.177451739531422, 50.603253669912242],
              [-1.178367063607026, 50.602462445193964],
              [-1.178953445903404, 50.601002512296333],
              [-1.181270801929874, 50.599373091307108],
              [-1.183911752184688, 50.598386195008011],
              [-1.184529434313724, 50.597718739338092],
              [-1.185282988422156, 50.597527962059374],
              [-1.185144941856562, 50.597258105012301],
              [-1.185566747631853, 50.597459799935883],
              [-1.187189464455358, 50.596845214599163],
              [-1.190015376829234, 50.596508767358117],
              [-1.196069068966218, 50.595128890099183],
              [-1.196697450476586, 50.594650297598285],
              [-1.19743874107448, 50.594593353498247],
              [-1.197781588042342, 50.593872674147988],
              [-1.198807387977109, 50.593949877325755],
              [-1.201872838976319, 50.592883655689782],
              [-1.204724777409509, 50.593078518787316],
              [-1.206216952459745, 50.592809908970921],
              [-1.209951687132394, 50.593016949455446],
              [-1.213254041134716, 50.591703827038003],
              [-1.215598174339181, 50.591254690407759],
              [-1.219893270521016, 50.589776291546542],
              [-1.221412083905337, 50.58959399878956],
              [-1.222604162175224, 50.588577651237735],
              [-1.223659131975171, 50.588766344006643],
              [-1.225963809335685, 50.588382385334072],
              [-1.229001404822498, 50.587145297448508],
              [-1.231962525673429, 50.586779051413551],
              [-1.233225729576272, 50.586061632841087],
              [-1.235496070812709, 50.586127810856297],
              [-1.236736050468455, 50.58535714300794],
              [-1.240181796613915, 50.584269922512803],
              [-1.242038238040788, 50.582924964294868],
              [-1.244384097859712, 50.582323278101839],
              [-1.246819790509311, 50.582373221163763],
              [-1.249077423355534, 50.58109639261675],
              [-1.251753742102611, 50.581020981323583],
              [-1.255355427546051, 50.580034136469905],
              [-1.25994341362281, 50.57945813544255],
              [-1.263562351934572, 50.580063812794315],
              [-1.264400382064516, 50.579760645356849],
              [-1.265245069291542, 50.57993684244547],
              [-1.269003998642434, 50.579625944129631],
              [-1.271218386242446, 50.579731544017868],
              [-1.27769969623649, 50.578721515567032],
              [-1.280197255653206, 50.577203659622562],
              [-1.2825417113489, 50.577108404031463],
              [-1.286548792436354, 50.577456757298101],
              [-1.287977819781753, 50.576909729407596],
              [-1.287960361428178, 50.576568786991167],
              [-1.294948881591921, 50.574923292493182],
              [-1.299605355560861, 50.575090767476631],
              [-1.302727622771409, 50.575677852611257],
              [-1.304030291708407, 50.576214430408001],
              [-1.304697501621703, 50.57713210464582],
              [-1.30601467887289, 50.57706981146346],
              [-1.30803614631936, 50.577519793527536],
              [-1.308650274665951, 50.578693430476335],
              [-1.308810003084042, 50.580711511321397],
              [-1.318173595710738, 50.589465609131352],
              [-1.323716789656127, 50.592235377129938],
              [-1.339452353572356, 50.601904051863968],
              [-1.353396479988705, 50.607867242813008],
              [-1.35791348846372, 50.609086479097584],
              [-1.362112770247287, 50.609676093213409],
              [-1.363583873121069, 50.61062749554786],
              [-1.365939549422278, 50.61317364715692],
              [-1.370940693183384, 50.616625257125499],
              [-1.374898437924166, 50.618544957796757],
              [-1.389304211687242, 50.626809378061218],
              [-1.397581933424791, 50.628910028120359],
              [-1.407265571606854, 50.63350468146897],
              [-1.409806717724503, 50.634390784555755],
              [-1.417037582148909, 50.635678978350782],
              [-1.422390683890965, 50.637103118927087],
              [-1.426166557806608, 50.637779145587515],
              [-1.431952378716746, 50.639833577090599],
              [-1.4472786047543, 50.643498998684763],
              [-1.452988423664624, 50.64663566114141],
              [-1.458001027238665, 50.650811973545444],
              [-1.461530678594608, 50.652008190241489],
              [-1.463972915352364, 50.652315308178423],
              [-1.46503150151323, 50.652519811228402],
              [-1.467887210679097, 50.655549979449177],
              [-1.475914446377386, 50.661731142768737],
              [-1.480135248316798, 50.664389432570594],
              [-1.485133965083712, 50.666991652176762],
              [-1.49386875091394, 50.66856316621886],
              [-1.505735840399482, 50.668366764648255],
              [-1.508816987906519, 50.669797949778967],
              [-1.510833557169774, 50.670060922456926],
              [-1.512759311151789, 50.66939272470492],
              [-1.513391251720487, 50.66835399541656],
              [-1.514257333251851, 50.668208325339421],
              [-1.516771823052151, 50.668484059660614],
              [-1.51692900571008, 50.668196940821311],
              [-1.519166751930308, 50.667999354064321],
              [-1.523847827833249, 50.667978093020679],
              [-1.532443559973599, 50.666884274192071],
              [-1.539484520292915, 50.665868224975014],
              [-1.548751916511669, 50.663705709681444],
              [-1.553859536599155, 50.663268526286124],
              [-1.561948347253081, 50.663115736629322],
              [-1.569066382441242, 50.661120672027771],
              [-1.578291647650791, 50.660247021091308],
              [-1.579445338293622, 50.660333021734012],
              [-1.578939620511005, 50.66087975841662],
              [-1.579243096137577, 50.661275638063501],
              [-1.586425247901404, 50.663143055559217],
              [-1.577244974170008, 50.664002225694887],
              [-1.5763308801567, 50.663853225143079],
              [-1.572791626161285, 50.664546249488176],
              [-1.569306496872717, 50.665653035347063],
              [-1.569000639346886, 50.667379423058328],
              [-1.570317827872866, 50.672672048709352],
              [-1.569579769715901, 50.673529937632026],
              [-1.567445260113698, 50.674038227160501],
              [-1.563397295376545, 50.675620264009034],
              [-1.560584011285245, 50.67620412228289],
              [-1.557322595922512, 50.676274522841496],
              [-1.55352644262238, 50.677364367487442],
              [-1.550121138896732, 50.677523943656098],
              [-1.548986947101444, 50.677875677799534],
              [-1.546823504222069, 50.679434735095036],
              [-1.544667479041643, 50.682260864560895],
              [-1.542532639538453, 50.688123897747104],
              [-1.541593457455248, 50.688915171865524],
              [-1.539523121883709, 50.689128239132621],
              [-1.538259931890325, 50.689605258181793],
              [-1.53494764531951, 50.692290830419367],
              [-1.533591215947754, 50.695734141711206],
              [-1.533471758424854, 50.696929701931431],
              [-1.533938712795227, 50.697387501794239],
              [-1.534641829572208, 50.70073921355344],
              [-1.533400022702876, 50.700736950693432],
              [-1.529877990982752, 50.703615779516369],
              [-1.527136865016102, 50.704279156757977],
              [-1.522019208557332, 50.707191735714289],
              [-1.516804959048754, 50.706899571836551],
              [-1.515874196208141, 50.707069272848834],
              [-1.510016355075955, 50.706300214165033],
              [-1.508048961981925, 50.705791036945797],
              [-1.506662745798582, 50.705907484034618],
              [-1.504983891273986, 50.706782561235492],
              [-1.50178489395859, 50.706768042699181],
              [-1.501197635070461, 50.706992150510615],
              [-1.501713710418396, 50.706669717577576],
              [-1.504935254719244, 50.70669422580324],
              [-1.506063002521038, 50.706109980919393],
              [-1.505390556952993, 50.705414689121511],
              [-1.505942356941293, 50.704922425752834],
              [-1.506740693570967, 50.705631735974301],
              [-1.509783444839569, 50.705819921619671],
              [-1.50875341160009, 50.70532458374376],
              [-1.509573759428844, 50.705421560313255],
              [-1.506968506658438, 50.704703747006796],
              [-1.506092903626421, 50.704611014500827],
              [-1.505123936938693, 50.705047552963222],
              [-1.504522796183217, 50.704576478241385],
              [-1.504457913975027, 50.70428303860168],
              [-1.505044007028691, 50.704165023974738],
              [-1.506164244334636, 50.704290274981133],
              [-1.506177951044326, 50.702714801026417],
              [-1.504929006784965, 50.70123289487622],
              [-1.504469448132401, 50.701440474932198],
              [-1.504557706692506, 50.701799661194464],
              [-1.503992960250608, 50.701641686251939],
              [-1.504232180480392, 50.701918781144833],
              [-1.503457822725108, 50.702435269205751],
              [-1.50324443040791, 50.701723932161052],
              [-1.50292834736418, 50.701753161031895],
              [-1.503201396383487, 50.70244676722475],
              [-1.502802981180419, 50.703030498643137],
              [-1.502470266886415, 50.701953545294316],
              [-1.502964599671685, 50.701002419502359],
              [-1.502488011797096, 50.701614593699468],
              [-1.502084378483106, 50.699747771361864],
              [-1.502705969742212, 50.699883516085002],
              [-1.502621878600072, 50.70020330010901],
              [-1.503306380122106, 50.699745787122957],
              [-1.503294400551525, 50.699136925616337],
              [-1.502701275966005, 50.699657777568234],
              [-1.502255664981797, 50.698949045165293],
              [-1.503199144684678, 50.698905405750821],
              [-1.503390719091295, 50.696853171726467],
              [-1.502849858606933, 50.697656620162398],
              [-1.503035642298263, 50.698566581073656],
              [-1.501902849415502, 50.698704734259032],
              [-1.502171702016055, 50.699524224128567],
              [-1.499847043494948, 50.697892882652141],
              [-1.501014109475541, 50.697191948469467],
              [-1.501493766330146, 50.69735676864083],
              [-1.501749264636081, 50.698098864781556],
              [-1.502632150701175, 50.697892201950694],
              [-1.501240088351428, 50.696977987692414],
              [-1.50203422689916, 50.69605782283697],
              [-1.503442361006498, 50.695313828570569],
              [-1.508079781560153, 50.696072684795084],
              [-1.503839485105004, 50.695114080353484],
              [-1.506172145766477, 50.693824523508866],
              [-1.50660445221004, 50.692967542728681],
              [-1.506300161966153, 50.693093053316083],
              [-1.505987141266518, 50.692566549664242],
              [-1.507234081041859, 50.692598802692125],
              [-1.5067655327463, 50.692457434168738],
              [-1.507141364572375, 50.692259383171937],
              [-1.506365761431673, 50.692373009365639],
              [-1.506858669218596, 50.692081030520548],
              [-1.506488752631211, 50.691985041737375],
              [-1.505989596114746, 50.692062964395475],
              [-1.50648215636284, 50.692074042349859],
              [-1.505853274330313, 50.692439184408727],
              [-1.504965733052418, 50.6916863222553],
              [-1.506231552390878, 50.691671004435904],
              [-1.506060531022558, 50.691509309533444],
              [-1.506733145467813, 50.691360178023324],
              [-1.507112527054797, 50.692038938210651],
              [-1.50727387440372, 50.691232068238477],
              [-1.506802611943995, 50.691080796286812],
              [-1.505934745217408, 50.691488992662094],
              [-1.504891141527553, 50.691509747136116],
              [-1.505051919831119, 50.690760431507911],
              [-1.505451115772114, 50.69103280756692],
              [-1.505791571239793, 50.690563028851422],
              [-1.505085295498666, 50.690548343465707],
              [-1.504990750882453, 50.69011538938453],
              [-1.505640436897821, 50.689721563138917],
              [-1.50610236736168, 50.689951037547686],
              [-1.505643647121646, 50.689550713849968],
              [-1.504765444724615, 50.690001123876769],
              [-1.504006770491148, 50.689586030550259],
              [-1.503837025294946, 50.688901856914477],
              [-1.505809647462962, 50.688167427692356],
              [-1.503795316865621, 50.688426860379906],
              [-1.503570640521704, 50.687314395799703],
              [-1.50408178533508, 50.687169088564254],
              [-1.503511555950488, 50.687145079881155],
              [-1.503244235550767, 50.686721280556682],
              [-1.504676377801004, 50.685685107184177],
              [-1.507266781904407, 50.685553993536701],
              [-1.507879914265668, 50.684451368263701],
              [-1.508676909694773, 50.684716418649501],
              [-1.507950728008485, 50.684312278770562],
              [-1.508620209645208, 50.683232369114009],
              [-1.507799558170237, 50.682795456045234],
              [-1.505937367217079, 50.684361321162143],
              [-1.505585356592538, 50.684318462623118],
              [-1.505177262655828, 50.685032558158873],
              [-1.50232805064522, 50.686348670397784],
              [-1.502919197806656, 50.688680323429004],
              [-1.502569435626272, 50.689225593634283],
              [-1.504026898572457, 50.69049708514072],
              [-1.503846500539303, 50.69149451672957],
              [-1.504894078081357, 50.692576303364298],
              [-1.503318908194173, 50.693193705290447],
              [-1.505070355391046, 50.692776691202461],
              [-1.505600647002524, 50.693644045480184],
              [-1.503635956195447, 50.694543971651036],
              [-1.502723275076145, 50.693557173472342],
              [-1.503392104159244, 50.694441315286163],
              [-1.503112890936303, 50.694737787075304],
              [-1.501007172030451, 50.693707217994124],
              [-1.500247444902721, 50.693655404288876],
              [-1.500137481244285, 50.693877954000961],
              [-1.500853501418558, 50.693779401977118],
              [-1.502724794030899, 50.694619227016901],
              [-1.502293441166126, 50.695110191366176],
              [-1.499089161676268, 50.694203491596561],
              [-1.501949779128553, 50.695207644971674],
              [-1.500754248718342, 50.696195336375041],
              [-1.500081565849582, 50.695676269863604],
              [-1.498621033132677, 50.695750935824123],
              [-1.498453128457158, 50.696227730367617],
              [-1.499870101983258, 50.696123203570536],
              [-1.499945317806937, 50.695840253623487],
              [-1.50050393246842, 50.696301278717627],
              [-1.499472044173532, 50.696940737891069],
              [-1.497963327526314, 50.696881308040879],
              [-1.497804464716558, 50.697838243100534],
              [-1.498383938834722, 50.69780386621057],
              [-1.498227292529408, 50.697224057810708],
              [-1.498826339143984, 50.697345337916872],
              [-1.498500598250183, 50.698017496571723],
              [-1.497702110702863, 50.698141758106246],
              [-1.497687020098001, 50.698759496591734],
              [-1.49826127761736, 50.698153157512067],
              [-1.499336341059734, 50.698782774664942],
              [-1.498831759296755, 50.699096254023928],
              [-1.499635646324183, 50.698998086952408],
              [-1.50018076474721, 50.699266610113696],
              [-1.501333653594656, 50.700974775054924],
              [-1.498898217704071, 50.701630701925666],
              [-1.498937181110293, 50.701163245388365],
              [-1.499600179183154, 50.701267709801058],
              [-1.500117159631109, 50.700988452861409],
              [-1.499710820021432, 50.700450741760875],
              [-1.499261912002033, 50.700986583066296],
              [-1.498473134184873, 50.7011252799985],
              [-1.498562826792474, 50.701611275071599],
              [-1.498052889416195, 50.701627067336887],
              [-1.497583156727831, 50.701325585845652],
              [-1.498000255263424, 50.700853462767796],
              [-1.497545207197145, 50.70076877000654],
              [-1.498058450989766, 50.700574937374505],
              [-1.497444890912847, 50.700217980348924],
              [-1.497486050943295, 50.700606645324306],
              [-1.496790762395515, 50.700609942790678],
              [-1.496585359345463, 50.700882436666163],
              [-1.49674855529867, 50.702702377771963],
              [-1.498690183791067, 50.703479619978289],
              [-1.500317609370177, 50.703046856659704],
              [-1.500562419515987, 50.702395929639657],
              [-1.499911659679354, 50.702337386320764],
              [-1.499541811477073, 50.702761157910011],
              [-1.499350424039739, 50.702249547518527],
              [-1.50094778485809, 50.701709632413213],
              [-1.5014660303206, 50.702914181280924],
              [-1.501024587015642, 50.702745927598869],
              [-1.500442268116658, 50.703175987136532],
              [-1.501392814822317, 50.703275377467719],
              [-1.501540451937565, 50.70390909900982],
              [-1.502410837840527, 50.704626840468585],
              [-1.503173681226528, 50.704668760961056],
              [-1.50391919410992, 50.705682720161661],
              [-1.503393222986694, 50.705201168006511],
              [-1.501268372377523, 50.705663323127879],
              [-1.500522517156933, 50.706954189922939],
              [-1.500351178503318, 50.706552379091093],
              [-1.500273659856471, 50.706784060209927],
              [-1.497071181465147, 50.706962741748995],
              [-1.492626029963301, 50.706676406727567],
              [-1.486453611558479, 50.706742020990568],
              [-1.479385812784275, 50.708501149376175],
              [-1.475699106400439, 50.708777800819725],
              [-1.472647548460815, 50.709748752366664],
              [-1.470127697600344, 50.709650103312057],
              [-1.45956200092066, 50.714485598740445],
              [-1.456769573627692, 50.716260388277668],
              [-1.453128155649993, 50.717328799481173],
              [-1.449326127600636, 50.719275830361916],
              [-1.445429324528019, 50.72010358808059],
              [-1.441576792060974, 50.722320807908524],
              [-1.433532084604214, 50.724398914368066],
              [-1.429345936897216, 50.726012494487065],
              [-1.425563925714415, 50.726762812584013],
              [-1.412066707030943, 50.724570580381197],
              [-1.411771256767071, 50.723953087003956],
              [-1.413140639153134, 50.724679404783309],
              [-1.420596063264562, 50.725892925395343],
              [-1.415263969910608, 50.724820462481354],
              [-1.411840206314372, 50.723198042903675],
              [-1.412041543373035, 50.722958051911966],
              [-1.413998911440697, 50.723564121110535],
              [-1.413947712524572, 50.723920876401337],
              [-1.414263446126082, 50.723595125799257],
              [-1.414469193004273, 50.724137522737294],
              [-1.42118053819314, 50.724680006637129],
              [-1.419242873787226, 50.723614626595108],
              [-1.41796770501121, 50.723512048682807],
              [-1.416913531341178, 50.723067937585448],
              [-1.414440804383822, 50.722890983930647],
              [-1.413980533090543, 50.722083820561373],
              [-1.411970650913873, 50.720811122366612],
              [-1.413227378612502, 50.721135794532941],
              [-1.416324212115321, 50.720214285256446],
              [-1.418111065917018, 50.720171054355681],
              [-1.418271372297531, 50.718893082970972],
              [-1.415839225795086, 50.719571572779749],
              [-1.414020388396821, 50.719341228122744],
              [-1.413328121705354, 50.719647099609482],
              [-1.412802470470487, 50.719541937204177],
              [-1.41318111312445, 50.719167945052995],
              [-1.412472462137077, 50.718971033452533],
              [-1.413409184314455, 50.719053984780629],
              [-1.412967378611767, 50.718814353213148],
              [-1.413151318916206, 50.718377331028677],
              [-1.415003531613773, 50.717174409702316],
              [-1.41464800176662, 50.717173525419362],
              [-1.415247374472908, 50.71693012977628],
              [-1.415222327598216, 50.716552307935387],
              [-1.415630066155645, 50.716682947906513],
              [-1.415969929132991, 50.715208037284768],
              [-1.416895619961241, 50.715261229393263],
              [-1.415650823463754, 50.714898886356401],
              [-1.416647637333008, 50.714927256967179],
              [-1.416258983666309, 50.714629450459171],
              [-1.417347422350527, 50.713892988723615],
              [-1.4172646899811, 50.71313988035071],
              [-1.419025860309596, 50.712402265395902],
              [-1.420358353916412, 50.712541088393735],
              [-1.420429051822242, 50.712780647459738],
              [-1.421265950825476, 50.712679587330662],
              [-1.420847233169866, 50.712521934117483],
              [-1.422512654295754, 50.712424076554797],
              [-1.423396905478386, 50.711694640715898],
              [-1.423389737317788, 50.711470685211452],
              [-1.42285092238464, 50.711751292786005],
              [-1.423368671529889, 50.711225078336362],
              [-1.425169006460991, 50.710745656856631],
              [-1.426047742946238, 50.710926242450356],
              [-1.427971095922951, 50.71069288572712],
              [-1.425297316531853, 50.71032902435968],
              [-1.426349457615382, 50.709181329706844],
              [-1.429474111459611, 50.708448456093024],
              [-1.427074813097184, 50.708813493115983],
              [-1.426742942745812, 50.70908434368485],
              [-1.425943027132374, 50.708946417223927],
              [-1.426224764249693, 50.708606077930753],
              [-1.426810306652052, 50.708670107719072],
              [-1.426513767643845, 50.70848249961599],
              [-1.425879793656442, 50.708673624844941],
              [-1.425894975911701, 50.708358053070974],
              [-1.42692247435915, 50.707484511429278],
              [-1.427311714203469, 50.706571857502773],
              [-1.426529934909879, 50.70599517626853],
              [-1.426606943473549, 50.705486563411526],
              [-1.42862571003545, 50.705540534787104],
              [-1.430748465760216, 50.704725376786833],
              [-1.43195853103731, 50.704902139063435],
              [-1.43388468975514, 50.704648015247294],
              [-1.435468882434441, 50.70374112761543],
              [-1.433692803958398, 50.70447172451577],
              [-1.432009402082188, 50.704676667979328],
              [-1.431663139672376, 50.704385413695029],
              [-1.429950865982699, 50.704752056844448],
              [-1.42969476583744, 50.703215736753521],
              [-1.429793710843523, 50.704398771588878],
              [-1.429322398164605, 50.704611394048385],
              [-1.429690062765215, 50.704888371492125],
              [-1.428476184465925, 50.705259227422566],
              [-1.428081214753043, 50.705015385573184],
              [-1.425810207118076, 50.704861242640163],
              [-1.426565764147214, 50.706657221102574],
              [-1.424783026009083, 50.707911928601298],
              [-1.425050127437606, 50.70911647922641],
              [-1.424522386140727, 50.709652544021004],
              [-1.420682725544251, 50.711734249481921],
              [-1.417178212121522, 50.712232077378019],
              [-1.416083688398398, 50.712891169365221],
              [-1.414225125535094, 50.715301805794581],
              [-1.413799719075568, 50.715112619050018],
              [-1.413538997243715, 50.715461127304096],
              [-1.413608159448678, 50.714572089892982],
              [-1.412892802440523, 50.713102669366116],
              [-1.414150518956517, 50.711967809923031],
              [-1.414368281964455, 50.709949125591372],
              [-1.413682688595514, 50.707118350800997],
              [-1.412689083019842, 50.705598966877965],
              [-1.412938419982271, 50.704797166237846],
              [-1.412487757329474, 50.704257128333182],
              [-1.413232724170307, 50.704022569562945],
              [-1.413045981497096, 50.703554004921514],
              [-1.41309360487693, 50.703936438110176],
              [-1.41233062372484, 50.704139430469397],
              [-1.41276500125728, 50.704849350694346],
              [-1.412248036357105, 50.705422285148508],
              [-1.413083908099435, 50.708685479192013],
              [-1.412947758066514, 50.710289106043788],
              [-1.412281166570388, 50.71081722243207],
              [-1.412903403878808, 50.710892298252539],
              [-1.412311357106206, 50.711008021450624],
              [-1.412986291020365, 50.711173290095758],
              [-1.412909729910284, 50.711520025980761],
              [-1.411577808629869, 50.711113135840357],
              [-1.411561902010199, 50.710460179852269],
              [-1.410613573928852, 50.710413125745681],
              [-1.410280739448128, 50.709961804450501],
              [-1.409501604162039, 50.709863438441261],
              [-1.408573135874019, 50.710152798258122],
              [-1.40749782430074, 50.709730975139905],
              [-1.407222009380571, 50.709590185622702],
              [-1.407658360795435, 50.709129274110389],
              [-1.407128935019271, 50.708547449034818],
              [-1.407384862096412, 50.708130585168199],
              [-1.406958713886947, 50.708569065643069],
              [-1.407467495797722, 50.709103124874765],
              [-1.406958125215453, 50.709514204140589],
              [-1.407400605767333, 50.709915732555139],
              [-1.40798495955963, 50.7099609660171],
              [-1.408536593585465, 50.710468259560621],
              [-1.409739934696089, 50.710170399299543],
              [-1.410554314063136, 50.710847177535015],
              [-1.411041045167057, 50.710772298311433],
              [-1.410931462955277, 50.711379656776565],
              [-1.411701933997027, 50.711833184168022],
              [-1.412198632269935, 50.711641444276516],
              [-1.411943226579949, 50.711452207865378],
              [-1.412488051780865, 50.711715743900868],
              [-1.411853853075351, 50.712029992827944],
              [-1.412086380950786, 50.714389070660189],
              [-1.410884354617363, 50.714574552056675],
              [-1.40949886473582, 50.71369075087285],
              [-1.408907140910801, 50.713551962896453],
              [-1.409301901217677, 50.713810256775041],
              [-1.40890693267654, 50.713793867495077],
              [-1.408662034068797, 50.713219786406519],
              [-1.408431477519086, 50.713420055521389],
              [-1.407500292643587, 50.713244466450469],
              [-1.407051502706085, 50.712891467619166],
              [-1.406968132404394, 50.713100575481164],
              [-1.406526617346035, 50.71295714330838],
              [-1.406673755697966, 50.71274566230786],
              [-1.405791140561365, 50.712875161746126],
              [-1.403929141854487, 50.711716384192655],
              [-1.403078811585221, 50.711311856929207],
              [-1.402864424561337, 50.71146453613521],
              [-1.402467636928999, 50.710796868360099],
              [-1.402699868333634, 50.710258660857782],
              [-1.402452850302418, 50.709301462781347],
              [-1.402378238071871, 50.710646436825662],
              [-1.402355473192013, 50.711056807394534],
              [-1.402340784749274, 50.711321566840041],
              [-1.399733072402626, 50.710787493853644],
              [-1.402004337915854, 50.711382791892319],
              [-1.404037641754227, 50.712877906365065],
              [-1.407147151194505, 50.714404538701224],
              [-1.407670476977199, 50.714351442173687],
              [-1.407599355358995, 50.714712590517507],
              [-1.4093589669853, 50.715130684953031],
              [-1.409911161269235, 50.716502179571222],
              [-1.409700626533724, 50.716686365399532],
              [-1.409530719327078, 50.716787200186189],
              [-1.409078934962793, 50.717125661902685],
              [-1.409386630500668, 50.717889807084696],
              [-1.407998989176098, 50.718521259417038],
              [-1.408430353132366, 50.720038729048937],
              [-1.408452316450887, 50.720769952228878],
              [-1.407827319120788, 50.721466418141951],
              [-1.406388262643095, 50.722008561754912],
              [-1.404877648055601, 50.723612367762271],
              [-1.403438130848225, 50.724517780109693],
              [-1.402209059589697, 50.723576240931266],
              [-1.400725894693864, 50.723011978823919],
              [-1.402210889407033, 50.723432366111808],
              [-1.403513244778036, 50.724403956163499],
              [-1.406224737672603, 50.721944780147773],
              [-1.407845404437429, 50.721267769871595],
              [-1.40834222866429, 50.72022389188384],
              [-1.408231682100285, 50.719675539493963],
              [-1.407145388726961, 50.717689589410355],
              [-1.407879583427118, 50.717767059339316],
              [-1.409690689225592, 50.716667353854],
              [-1.409296722126525, 50.715913640218851],
              [-1.409605587815663, 50.716584266101393],
              [-1.407936465177045, 50.717524543292676],
              [-1.406760867101518, 50.717179542938318],
              [-1.405041042061085, 50.717195064048603],
              [-1.406651549692109, 50.717423590275011],
              [-1.40608459418069, 50.717563689994812],
              [-1.406314947367031, 50.718054968579629],
              [-1.405162465463816, 50.718345858612459],
              [-1.405827582943114, 50.719518308318293],
              [-1.403952040090343, 50.720859453318923],
              [-1.403123957009903, 50.720363314750287],
              [-1.403085766642843, 50.72002409200941],
              [-1.403908088225069, 50.719633514620618],
              [-1.402988558195047, 50.718085652841289],
              [-1.402334326052556, 50.718069712685953],
              [-1.402069231319709, 50.717640298209332],
              [-1.400658438042161, 50.717747266112163],
              [-1.399582462223262, 50.717369431879781],
              [-1.399903886279598, 50.716602203828813],
              [-1.399417628748059, 50.716633873736804],
              [-1.399123864116555, 50.717231279536428],
              [-1.396916321231819, 50.717170426656644],
              [-1.397385227835785, 50.717498388468044],
              [-1.397418960982507, 50.719176613948108],
              [-1.396898405711476, 50.717573210277081],
              [-1.394855926797671, 50.716792850506039],
              [-1.393148744631641, 50.716707542675479],
              [-1.394065134594715, 50.716603494078029],
              [-1.393246031651346, 50.716193661988918],
              [-1.392526590693775, 50.716622469952107],
              [-1.392888792699809, 50.715886948037749],
              [-1.392272830340614, 50.715542914161624],
              [-1.39253480398649, 50.716096435306333],
              [-1.392185497293803, 50.716600908960473],
              [-1.391511336077787, 50.716484984358758],
              [-1.391601035042197, 50.716011532908766],
              [-1.39119817589358, 50.716494141630051],
              [-1.389940921239369, 50.716231278692824],
              [-1.390055926864724, 50.715664435845589],
              [-1.389539640098462, 50.71615453920429],
              [-1.3875693963536, 50.716258419466122],
              [-1.38794069766232, 50.715700118272714],
              [-1.387184705157912, 50.716213233694134],
              [-1.387674738646328, 50.715674441903374],
              [-1.387355523723986, 50.715387694619963],
              [-1.386335033838531, 50.715871537427418],
              [-1.385976026968167, 50.715595368345774],
              [-1.385696902999623, 50.716252170110657],
              [-1.385215671643407, 50.716115642299414],
              [-1.385726880529793, 50.716017616452071],
              [-1.385750397183296, 50.715518641029654],
              [-1.384969629878084, 50.715433591810857],
              [-1.384521435553622, 50.715695615459673],
              [-1.384490076276829, 50.715386098341853],
              [-1.383313936267279, 50.716071430857241],
              [-1.383395829630486, 50.716415387975836],
              [-1.382951228602727, 50.716402245524463],
              [-1.383138283095819, 50.716072300610215],
              [-1.381971166475538, 50.716391661154347],
              [-1.381753584377639, 50.716026300502683],
              [-1.381638932935181, 50.716343137898164],
              [-1.379531141649575, 50.716349031064453],
              [-1.379149993643434, 50.715177941337075],
              [-1.378347043658202, 50.714843629561599],
              [-1.378368661318407, 50.714279897225175],
              [-1.378783804672266, 50.714162504938557],
              [-1.378293714289837, 50.714163490856293],
              [-1.378200673011311, 50.714772705660053],
              [-1.377891091389419, 50.714512062734798],
              [-1.377823528851124, 50.712018002801187],
              [-1.377467614727731, 50.711193263572589],
              [-1.376682992905284, 50.710657599288176],
              [-1.377336453355202, 50.711258210814592],
              [-1.377742185884709, 50.71324148718827],
              [-1.376706143514813, 50.713398724879518],
              [-1.377647164001619, 50.713464900591781],
              [-1.377269644584329, 50.713629252201102],
              [-1.377649638696695, 50.714562033558934],
              [-1.378324218803823, 50.715070126050236],
              [-1.377440578844345, 50.715267750705124],
              [-1.378527053913711, 50.715155739103018],
              [-1.378932191827043, 50.715367428829097],
              [-1.378784889938046, 50.715795600125965],
              [-1.378549184359846, 50.715410352763293],
              [-1.378382106159795, 50.715619893583721],
              [-1.379329281628498, 50.717476550998285],
              [-1.378006467020445, 50.718103495611082],
              [-1.376722161037416, 50.718066963927988],
              [-1.376172892330002, 50.718574817497597],
              [-1.372837825587263, 50.718826716861841],
              [-1.372071619121832, 50.719345974414658],
              [-1.371270613283218, 50.719182487449793],
              [-1.369934557989815, 50.719834448980819],
              [-1.37144047774534, 50.719297611212582],
              [-1.37217605245628, 50.719481428237394],
              [-1.37282601496617, 50.718968739268341],
              [-1.373840608287332, 50.719155844249613],
              [-1.37606424844254, 50.718755890529607],
              [-1.376969187757096, 50.718239146431657],
              [-1.377130431847726, 50.718471121978837],
              [-1.378500617093136, 50.718548574251031],
              [-1.380347507691399, 50.717172613019514],
              [-1.383530820266699, 50.717139120901052],
              [-1.384946072187112, 50.716801269322232],
              [-1.386840812875862, 50.716949733531841],
              [-1.388575039419928, 50.716698046359689],
              [-1.388570792942695, 50.716915649304319],
              [-1.389163272711673, 50.716667855005348],
              [-1.389637100810554, 50.716830406793321],
              [-1.389266893997098, 50.717196273585536],
              [-1.388851324850465, 50.71712844971848],
              [-1.389138120808697, 50.717295419092359],
              [-1.389609340395374, 50.717332058443489],
              [-1.38982783525967, 50.716868275075562],
              [-1.390151729412316, 50.717560614120259],
              [-1.392644803607541, 50.717778657132108],
              [-1.392987740340974, 50.717438716825505],
              [-1.392888509108964, 50.717883342718416],
              [-1.393059420312803, 50.717701678832583],
              [-1.394230819376001, 50.718036004326237],
              [-1.39526839671552, 50.719414584365865],
              [-1.394372274796963, 50.719590689860304],
              [-1.395220101644712, 50.719755159984786],
              [-1.39446352517568, 50.720094758737737],
              [-1.394161570625231, 50.72066153389418],
              [-1.393740561080078, 50.720353592352211],
              [-1.391807194337192, 50.721098021312457],
              [-1.39077150753233, 50.719906470281153],
              [-1.392523821036699, 50.719467769164304],
              [-1.390875263943685, 50.71955179765358],
              [-1.389677494682271, 50.720047333468422],
              [-1.389907053418818, 50.720478388811941],
              [-1.390797799120049, 50.720282505432898],
              [-1.391312460313937, 50.720686269732354],
              [-1.390932420155456, 50.721153708586733],
              [-1.392310976103084, 50.721139315807953],
              [-1.392287796698364, 50.721398186796016],
              [-1.393242384693555, 50.720756575999566],
              [-1.393865272919602, 50.721118625844142],
              [-1.393888061111494, 50.721548598581229],
              [-1.393002842040417, 50.722193273410227],
              [-1.39186844064384, 50.722057869040221],
              [-1.392782123524964, 50.72272000008644],
              [-1.391975884076208, 50.722294039644176],
              [-1.390399260746983, 50.722613147697906],
              [-1.38978749744984, 50.721831175321086],
              [-1.387375506422654, 50.72246746984348],
              [-1.387778181342487, 50.722415068884644],
              [-1.388670612727348, 50.723182336036807],
              [-1.389616069493909, 50.723141423987059],
              [-1.3898958960782, 50.723411771604283],
              [-1.392476669423588, 50.723445025147583],
              [-1.39276003646459, 50.723113768272519],
              [-1.39381749305106, 50.723175922669292],
              [-1.394481421143009, 50.723654188933999],
              [-1.39562003131982, 50.723465850058751],
              [-1.400092192843065, 50.724157097369527],
              [-1.400173405404773, 50.725009129490211],
              [-1.400533398436308, 50.72488687992746],
              [-1.400426995895695, 50.724343170309801],
              [-1.400814727817961, 50.724600556973847],
              [-1.400497306030707, 50.725161872916843],
              [-1.398247767057376, 50.725585539933206],
              [-1.397370904806051, 50.725356194312923],
              [-1.396819747069315, 50.725801185198378],
              [-1.396514907254832, 50.724826591771141],
              [-1.396887822159443, 50.724581219453121],
              [-1.396536548147383, 50.724465194678785],
              [-1.396240220982421, 50.725365635368455],
              [-1.396570355053326, 50.725473458169638],
              [-1.395992610873965, 50.7263328738686],
              [-1.400154657943995, 50.726589060919807],
              [-1.401165214260338, 50.727225544507817],
              [-1.402426956184745, 50.726940649421969],
              [-1.40215220957245, 50.726820537283245],
              [-1.402918322202364, 50.726403600034182],
              [-1.402503897679007, 50.726237809905662],
              [-1.403874321837571, 50.726429172664368],
              [-1.403568811846658, 50.726611962563751],
              [-1.406041502446651, 50.726355695379908],
              [-1.407344483069689, 50.725492722807637],
              [-1.407314724996005, 50.725043832997393],
              [-1.407764900971268, 50.725183708563293],
              [-1.407258437035977, 50.724564232961292],
              [-1.406868398596962, 50.724599120733444],
              [-1.407817458878588, 50.723823370954072],
              [-1.408734950979079, 50.724090613565295],
              [-1.410077513564681, 50.724015576663632],
              [-1.409926486848325, 50.725550775847623],
              [-1.405752496781725, 50.726690552603706],
              [-1.402601500203575, 50.728144774159702],
              [-1.397506483787542, 50.728926120379057],
              [-1.391457119067073, 50.730740033579821],
              [-1.389609297344355, 50.731626058026343],
              [-1.381322564419065, 50.732847684999612],
              [-1.37915005904332, 50.734156279464131],
              [-1.365710129201463, 50.737168493056124],
              [-1.363914228015589, 50.737932990754231],
              [-1.359114767428276, 50.738115354808144],
              [-1.354234470332975, 50.738711639957593],
              [-1.351275714776748, 50.740745572306601],
              [-1.349808597258174, 50.741074633004949],
              [-1.34861482931474, 50.741925882223669],
              [-1.347327091559591, 50.743416877352018],
              [-1.345972699445084, 50.746634991113439],
              [-1.345074650535823, 50.748626342485501],
              [-1.34527326454175, 50.750443991079742],
              [-1.342543423568891, 50.752651644841706],
              [-1.340571505742986, 50.753363532180423],
              [-1.337837372089216, 50.755244614856458],
              [-1.334957229520715, 50.755507907323356],
              [-1.333731841729044, 50.756344434660534],
              [-1.333501298286615, 50.755707331460677],
              [-1.333368662476744, 50.756062686043499],
              [-1.332029817580514, 50.756699807734407],
              [-1.328088973970469, 50.757963140350164],
              [-1.324657300101651, 50.760775139079819],
              [-1.323974925277708, 50.762575129134376],
              [-1.322965666054346, 50.763539592576343],
              [-1.319712562932896, 50.765526049025794],
              [-1.313918388915746, 50.767297862196919],
              [-1.308167932805287, 50.767228835883117],
              [-1.300722690172692, 50.766797765336513],
              [-1.30044809319607, 50.766288929204578],
              [-1.299255426630679, 50.765918465949319],
              [-1.297785363946156, 50.764945606565618],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000047",
        LAD13CDO: "00EJ",
        LAD13NM: "County Durham",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.734149138022719, 54.918162026871883],
            [-1.733747571739066, 54.917548282477],
            [-1.73308264001405, 54.917602540661477],
            [-1.732310749432909, 54.916612350629983],
            [-1.730911104966577, 54.91642773954662],
            [-1.730588567633706, 54.915669479590498],
            [-1.730074239732379, 54.915600942216429],
            [-1.73013365286076, 54.915114015809245],
            [-1.728753217022586, 54.914169180087541],
            [-1.727701882499032, 54.912775749621183],
            [-1.728020906668996, 54.911496811411567],
            [-1.727624375059251, 54.910391505402927],
            [-1.725850907018074, 54.909706358485067],
            [-1.724896337224385, 54.908792990275728],
            [-1.723048324263473, 54.910125063481701],
            [-1.722220344556006, 54.908955852123263],
            [-1.721016914188091, 54.908634982244486],
            [-1.721192828514476, 54.908231000866706],
            [-1.719194924706188, 54.908001747194881],
            [-1.717006844713744, 54.90842891894215],
            [-1.71640494215757, 54.907743657630299],
            [-1.713285780628763, 54.908821894263035],
            [-1.710377864273494, 54.909129527070384],
            [-1.70056620902331, 54.909278308014791],
            [-1.69840190284747, 54.908992587490502],
            [-1.698753794522649, 54.908864056300381],
            [-1.698475933975513, 54.908059989727064],
            [-1.696688338224226, 54.906635704509348],
            [-1.697558547701087, 54.906226297187715],
            [-1.695645361347592, 54.904719908024092],
            [-1.693985950377269, 54.904110963754242],
            [-1.693379868789397, 54.903214397818275],
            [-1.691678566107565, 54.902590039369422],
            [-1.690561474775647, 54.902658194553879],
            [-1.690757080069393, 54.904606930946542],
            [-1.689146611342045, 54.90575397582527],
            [-1.689538492365745, 54.906509829152526],
            [-1.689132642373126, 54.90696080534488],
            [-1.687643661587818, 54.907909542443186],
            [-1.683465136774515, 54.908531378286135],
            [-1.681939369775747, 54.907789619790599],
            [-1.67634121528941, 54.909414858805718],
            [-1.67476634924416, 54.909552642097424],
            [-1.674826134860383, 54.909294894047122],
            [-1.676832080404087, 54.903429463132504],
            [-1.676524114640316, 54.90332799736337],
            [-1.677961927585197, 54.901767289904043],
            [-1.678122334005002, 54.899837446624581],
            [-1.676578282103308, 54.899095570792177],
            [-1.675243483027257, 54.897934571379366],
            [-1.669220057423697, 54.895642991372199],
            [-1.665547134228403, 54.895203397354187],
            [-1.663024559809529, 54.895931517840026],
            [-1.661368610814425, 54.894267136632322],
            [-1.659296499703107, 54.893160518957792],
            [-1.659939651116144, 54.890656919704938],
            [-1.656485627928839, 54.890756844912026],
            [-1.655842490909331, 54.892135332845051],
            [-1.653498075710793, 54.89299765689865],
            [-1.650000345256571, 54.893618482896834],
            [-1.648468601422548, 54.891727825207333],
            [-1.647337595320931, 54.888593697198829],
            [-1.646609825370563, 54.888387593443703],
            [-1.646581354926403, 54.88558286149339],
            [-1.647968047771062, 54.885477250425879],
            [-1.647741088886053, 54.884153797545629],
            [-1.646707704731971, 54.882720166691243],
            [-1.646966804229548, 54.882143993275569],
            [-1.646334372655907, 54.88138729868745],
            [-1.64760853209328, 54.880943476256903],
            [-1.650643403646346, 54.880735662728924],
            [-1.650970657137277, 54.879659134632675],
            [-1.650485874971733, 54.879306374113582],
            [-1.648998445533052, 54.879551011971067],
            [-1.645418747042507, 54.878802841759629],
            [-1.643677920795509, 54.878795954850894],
            [-1.639961291180891, 54.879791481192441],
            [-1.639183400643568, 54.880682422461547],
            [-1.636586919486482, 54.881010780012879],
            [-1.634902027329355, 54.882565769535468],
            [-1.633240372829887, 54.883263504046646],
            [-1.632297027093955, 54.883299296167223],
            [-1.631338138280523, 54.884013506280937],
            [-1.631570103466337, 54.884557885966025],
            [-1.631104711464641, 54.884825167817127],
            [-1.629516598503734, 54.884795177027577],
            [-1.628406952632883, 54.885112604749516],
            [-1.627605707443275, 54.886299951059321],
            [-1.623797204177495, 54.887335140648574],
            [-1.622265807720194, 54.886731000511965],
            [-1.620989662379248, 54.887337204873674],
            [-1.618732006350772, 54.887050669461324],
            [-1.618508934171276, 54.887727543457387],
            [-1.618973600714221, 54.888706719506999],
            [-1.618307001270614, 54.890126274945352],
            [-1.616478729873194, 54.891068585407076],
            [-1.611903105860448, 54.895279463724663],
            [-1.610249814520132, 54.895337978896038],
            [-1.608397626936676, 54.896290874629024],
            [-1.608002018128441, 54.896888991389467],
            [-1.606882294942151, 54.897068698615975],
            [-1.60731599345362, 54.898328192856411],
            [-1.605144747287595, 54.898241182942357],
            [-1.604781250063493, 54.898737847343959],
            [-1.603901432723532, 54.898298246610345],
            [-1.602334864275013, 54.898403665917016],
            [-1.600280419609187, 54.89896217300408],
            [-1.600736855018223, 54.899795811123766],
            [-1.597690870350486, 54.901574959219616],
            [-1.594152844221641, 54.902010723901149],
            [-1.593757922212406, 54.901738915259365],
            [-1.59363741948697, 54.900851557176672],
            [-1.591809667073895, 54.89978594124581],
            [-1.589695457822662, 54.896229204049135],
            [-1.58852454156458, 54.895458708343043],
            [-1.588182458052471, 54.894449277376438],
            [-1.587550632216004, 54.894333008589875],
            [-1.586281752821036, 54.894155260330912],
            [-1.583958437380439, 54.892812853378338],
            [-1.584029738089361, 54.891642170138176],
            [-1.582704019044229, 54.888826681957902],
            [-1.580700439256541, 54.881050141392556],
            [-1.579897638389008, 54.877761936410074],
            [-1.575864929487614, 54.878289813837888],
            [-1.575900124328783, 54.878945945127903],
            [-1.571467861429148, 54.8793635465303],
            [-1.568058429135413, 54.880463998315939],
            [-1.561999257869345, 54.880831429475997],
            [-1.559398889411976, 54.882028908473153],
            [-1.560354185369316, 54.879895396352623],
            [-1.562416029521678, 54.877751486284183],
            [-1.56193092555529, 54.877357930904772],
            [-1.561797636653243, 54.875708441224432],
            [-1.55315194385131, 54.875950153339097],
            [-1.541555930817928, 54.873450977665591],
            [-1.53894634295485, 54.875445082858207],
            [-1.537468707522917, 54.874378170571241],
            [-1.536141601069914, 54.874730773544528],
            [-1.533361939486022, 54.876441935715775],
            [-1.532929459291981, 54.87704955514404],
            [-1.532840777331182, 54.87784541268077],
            [-1.531769321532754, 54.878180078729876],
            [-1.530776840954449, 54.877885989644049],
            [-1.530000332914553, 54.877113750516905],
            [-1.529009253388733, 54.877371419658189],
            [-1.527357140694072, 54.876981285773091],
            [-1.525627794388815, 54.877597309312783],
            [-1.522899666437489, 54.876209010112881],
            [-1.519948673175986, 54.87601856081983],
            [-1.519122054026739, 54.872405437125835],
            [-1.524421663851761, 54.871889798293495],
            [-1.527161685383288, 54.871175282350464],
            [-1.529300900773334, 54.869592085654808],
            [-1.535380382632503, 54.866252748551261],
            [-1.540787393581057, 54.86551933556283],
            [-1.542772746432147, 54.86575777265621],
            [-1.541848775486351, 54.865349902789717],
            [-1.540500369363937, 54.865275616521231],
            [-1.535134430741117, 54.865962444260049],
            [-1.526616079451312, 54.871060827912274],
            [-1.524256888721725, 54.871590803318547],
            [-1.519010063091646, 54.871995211818692],
            [-1.518404441518286, 54.872864499354364],
            [-1.519196784339953, 54.875948190576864],
            [-1.517140882196838, 54.875714480879111],
            [-1.516654267128303, 54.874964874343505],
            [-1.515215250356279, 54.875146061466815],
            [-1.513799859358051, 54.874787240481034],
            [-1.506657166400836, 54.871132423729172],
            [-1.508303009918252, 54.868927525748376],
            [-1.509576946154901, 54.866026451172885],
            [-1.51029792781685, 54.863100669130404],
            [-1.510257976947204, 54.860096331012954],
            [-1.507788976214833, 54.851380253450266],
            [-1.507041728968192, 54.847006195229191],
            [-1.506210270904886, 54.8462281831358],
            [-1.503556653110152, 54.8374699076507],
            [-1.503305497310552, 54.836621453163609],
            [-1.50287927589528, 54.83443598725583],
            [-1.504266873045482, 54.83122089029429],
            [-1.49779980664431, 54.82360430608567],
            [-1.497496080024683, 54.822619024530539],
            [-1.493726856325827, 54.823463383761869],
            [-1.490277239003115, 54.820172464381194],
            [-1.492380724519575, 54.818607730485056],
            [-1.491991483201359, 54.818374250242414],
            [-1.492287864285938, 54.817821918485706],
            [-1.494293169689084, 54.816003320224418],
            [-1.487491435768726, 54.812674048171928],
            [-1.485325063892871, 54.811972023259024],
            [-1.484317393073408, 54.81045800704284],
            [-1.482216802127297, 54.809979072029478],
            [-1.481843026214108, 54.809642279104445],
            [-1.485011600426323, 54.808478517887686],
            [-1.491086675465678, 54.804305607102414],
            [-1.494318181420216, 54.80116032930799],
            [-1.493495934160204, 54.799988648442536],
            [-1.490714058457527, 54.799294931929083],
            [-1.485079944805062, 54.799672870404883],
            [-1.481892915269367, 54.799049125124675],
            [-1.480037508771397, 54.799754728288079],
            [-1.477865154110388, 54.800063531314777],
            [-1.472336906367102, 54.799938984047188],
            [-1.468015646856259, 54.800297565081806],
            [-1.460161972571118, 54.801684580139501],
            [-1.456020576447902, 54.800905832435973],
            [-1.456408944864252, 54.800480708856888],
            [-1.455048762922255, 54.800528531476722],
            [-1.454589047216083, 54.800012434575471],
            [-1.448254007296486, 54.800997500680118],
            [-1.444009035966236, 54.802460924867553],
            [-1.442864675405818, 54.801966809048722],
            [-1.440246573812678, 54.801518918331766],
            [-1.441299410218925, 54.800524458091637],
            [-1.440695076335327, 54.799679631904667],
            [-1.43751798615471, 54.800517783568885],
            [-1.43021577530488, 54.8015495472129],
            [-1.429313287695186, 54.801015099507218],
            [-1.424317064407343, 54.803079101475269],
            [-1.422529954075982, 54.803165873407892],
            [-1.422667250616033, 54.804011266616179],
            [-1.423881141380881, 54.805400968086367],
            [-1.422899377267684, 54.808247752467679],
            [-1.421549682325768, 54.812116348226233],
            [-1.421712046777225, 54.813277289810046],
            [-1.419204563534843, 54.814195435046614],
            [-1.417835155296919, 54.818205880736002],
            [-1.415825786655827, 54.818441565659356],
            [-1.414360267344318, 54.819378997129235],
            [-1.414355360797145, 54.821224816797759],
            [-1.413246390381315, 54.822143284356606],
            [-1.412999323805152, 54.823296865524156],
            [-1.413111909939634, 54.824549239420733],
            [-1.414200689740937, 54.825891697549082],
            [-1.415652728304881, 54.826336338103701],
            [-1.415495434588476, 54.827420260089809],
            [-1.416808910277483, 54.828735916545284],
            [-1.416037843588131, 54.8294205827737],
            [-1.416482767302209, 54.830627819200167],
            [-1.420689168435452, 54.833659349473336],
            [-1.421594298365759, 54.838082350513524],
            [-1.421523126844301, 54.839466838649976],
            [-1.419167322713005, 54.839792593135051],
            [-1.417337494566921, 54.841002406218109],
            [-1.416690978336005, 54.84091662735996],
            [-1.413908970631836, 54.841418166475592],
            [-1.413762309809772, 54.841760744947642],
            [-1.411993056905, 54.8418923842883],
            [-1.411897390681755, 54.843117686290235],
            [-1.413566450742323, 54.844852968592249],
            [-1.409173361069133, 54.84517946232711],
            [-1.407496056815753, 54.845720371432485],
            [-1.404736733336375, 54.845496597414694],
            [-1.399990862858736, 54.846604657223814],
            [-1.39948517952562, 54.846364913799221],
            [-1.397280495204987, 54.846438469547074],
            [-1.396449774449602, 54.845857408799837],
            [-1.393398289341327, 54.846219645723679],
            [-1.392521891138143, 54.845676972028613],
            [-1.387143827502183, 54.846550413293436],
            [-1.38390534944382, 54.848138133363619],
            [-1.381649774841556, 54.847151632489805],
            [-1.378554614619483, 54.849986824396353],
            [-1.376719399533831, 54.851083218740165],
            [-1.37380291631816, 54.853955601396855],
            [-1.368851774534862, 54.857618063111616],
            [-1.366232396994687, 54.857868632919434],
            [-1.365324105796806, 54.857677870153395],
            [-1.363913655977306, 54.858216876672614],
            [-1.360893894038068, 54.857873919545554],
            [-1.360406559322835, 54.858055579503123],
            [-1.360167194669358, 54.857825162102955],
            [-1.359509390762701, 54.858245861646253],
            [-1.357329701030142, 54.858465306412931],
            [-1.355773183950318, 54.858972891700027],
            [-1.354063803837924, 54.86027046137135],
            [-1.35234316180263, 54.860610878962255],
            [-1.349426282905263, 54.860065089211851],
            [-1.349127735141806, 54.860417560668495],
            [-1.347434173989304, 54.860621458219214],
            [-1.34729311043518, 54.858454939707357],
            [-1.34543819145822, 54.854966261659875],
            [-1.335925644093991, 54.844153209017406],
            [-1.333496119834454, 54.842277881590462],
            [-1.332551099440077, 54.841984223141644],
            [-1.332009734883291, 54.842235567989],
            [-1.331891084684495, 54.841405452771099],
            [-1.329809747791601, 54.838928963506952],
            [-1.328674133627298, 54.838680054371046],
            [-1.327721968188607, 54.839292167802398],
            [-1.324756051658596, 54.83918405326903],
            [-1.322471559169633, 54.838552155274876],
            [-1.320652758089859, 54.837544483280119],
            [-1.320860793051457, 54.837394671601068],
            [-1.323468809357253, 54.838785973425907],
            [-1.325760502279718, 54.839174355596775],
            [-1.326541584002006, 54.839207445755939],
            [-1.326970693984031, 54.838871029201236],
            [-1.324065343750849, 54.838215050387021],
            [-1.324942763534999, 54.838352032897745],
            [-1.324697838838464, 54.837447517680545],
            [-1.325086713829809, 54.837562910261873],
            [-1.325122117267292, 54.838334157652625],
            [-1.326915005659902, 54.838287490121836],
            [-1.326553413113887, 54.837561165640913],
            [-1.327321797361521, 54.83835624443396],
            [-1.329358649917759, 54.838338750090649],
            [-1.329332344991406, 54.83795307963851],
            [-1.326766382967672, 54.837487757402108],
            [-1.326222804936868, 54.83684489710263],
            [-1.326200339424122, 54.837259054989083],
            [-1.325545995394994, 54.837377641278046],
            [-1.326028368788222, 54.836740472433192],
            [-1.325509970496953, 54.836643235545743],
            [-1.325774177232373, 54.836391280080903],
            [-1.32526489469565, 54.83565424627281],
            [-1.32439760193175, 54.836872502640503],
            [-1.325250106208519, 54.835607433759904],
            [-1.324594810596789, 54.83485161255372],
            [-1.324105683489375, 54.834867763610049],
            [-1.321539381832469, 54.836759515971266],
            [-1.325283975348422, 54.833856131748689],
            [-1.325870591147245, 54.832285833323056],
            [-1.322917364916577, 54.830054205352496],
            [-1.321242674794097, 54.827808088013889],
            [-1.318343084012103, 54.822702721233611],
            [-1.318616608232076, 54.821616873738932],
            [-1.318503566471224, 54.820565701969173],
            [-1.316900621373384, 54.817517415174528],
            [-1.314497107463194, 54.813791488455934],
            [-1.312614323496345, 54.811771430208111],
            [-1.312957376295782, 54.809893364092211],
            [-1.311395976659331, 54.802253047518178],
            [-1.31167219719418, 54.801194187199982],
            [-1.310260046932949, 54.798616883962254],
            [-1.31163596767719, 54.794810748799712],
            [-1.31064850441938, 54.792514441245615],
            [-1.310731427764776, 54.790107379929026],
            [-1.308365112709233, 54.786424656174674],
            [-1.308778921993289, 54.785137423006056],
            [-1.309810005676601, 54.782571298298819],
            [-1.308615429794207, 54.780408591079492],
            [-1.305198601893425, 54.77665420593938],
            [-1.302681372707092, 54.773053173271379],
            [-1.301680824556291, 54.772660093025564],
            [-1.302329996099197, 54.772243248234304],
            [-1.301668763645992, 54.772096556548334],
            [-1.3007400533349, 54.769792406634195],
            [-1.292312581399284, 54.760950937855981],
            [-1.291313952932051, 54.760114735075618],
            [-1.286865078290802, 54.756652139526565],
            [-1.283152759364655, 54.754723276079012],
            [-1.27976156891649, 54.753454933376076],
            [-1.279232975754642, 54.752422810635352],
            [-1.277000487720016, 54.750405480211761],
            [-1.272822834436276, 54.747626069231885],
            [-1.270544109312451, 54.746571721742491],
            [-1.269757656852146, 54.745643150191448],
            [-1.266543729286, 54.743484010704435],
            [-1.266813148292813, 54.743101903666286],
            [-1.266346926956306, 54.742425975169425],
            [-1.266961840600137, 54.741576846516139],
            [-1.266654831543435, 54.741116665068063],
            [-1.258624310792777, 54.735334280387846],
            [-1.256699292887573, 54.733170161231094],
            [-1.254720653703437, 54.731654528138542],
            [-1.243542597699852, 54.724239507564832],
            [-1.24192061729046, 54.722713302903806],
            [-1.242505189342398, 54.722420389946741],
            [-1.244081940583912, 54.722706122967082],
            [-1.243832601964446, 54.723339937340207],
            [-1.244284717728383, 54.723657294122127],
            [-1.245906757698287, 54.72343013767842],
            [-1.24574530146276, 54.723178401317057],
            [-1.24474484360939, 54.72320992024175],
            [-1.244183286766222, 54.722432656343962],
            [-1.242825023970778, 54.722253434697549],
            [-1.244005303060669, 54.721567912904128],
            [-1.245782110752467, 54.722282643701391],
            [-1.247599540915856, 54.722399976361984],
            [-1.248751495556522, 54.720722986007928],
            [-1.251850550010507, 54.719612498494953],
            [-1.253721558021485, 54.720357350566282],
            [-1.255399410730049, 54.721564707750275],
            [-1.257430512310725, 54.725536751549427],
            [-1.25882250176557, 54.725408666041631],
            [-1.258546933685663, 54.726045045500641],
            [-1.260565163468531, 54.725719461950817],
            [-1.263208539118907, 54.726451796693183],
            [-1.264695411850965, 54.725971937199624],
            [-1.265882373803238, 54.726620785048247],
            [-1.267390512992676, 54.726854573664205],
            [-1.268210325997062, 54.72608036652354],
            [-1.270237428346303, 54.727158414465499],
            [-1.272495505675996, 54.72540247762462],
            [-1.274891711255064, 54.725204737724518],
            [-1.275616919193864, 54.725459802761613],
            [-1.276512793567409, 54.724414592661631],
            [-1.277924463519057, 54.723784941943457],
            [-1.281201944395496, 54.723000090737727],
            [-1.282344527690213, 54.72300685665671],
            [-1.282695648992371, 54.722113848290995],
            [-1.285520173115087, 54.72041134469093],
            [-1.290287488615679, 54.718387647985118],
            [-1.292179609122025, 54.718581137406936],
            [-1.294896110364071, 54.71939948180232],
            [-1.299305164890274, 54.719123077833288],
            [-1.301765831050483, 54.718216122321223],
            [-1.303223610391571, 54.718121157898544],
            [-1.303201350791994, 54.717880183915511],
            [-1.303659595557948, 54.71849751248488],
            [-1.305506736234514, 54.717511468188619],
            [-1.304605838292197, 54.716634586962634],
            [-1.305504208081987, 54.715206340612447],
            [-1.303610791092956, 54.7131006602336],
            [-1.304123237291003, 54.712634490657031],
            [-1.302724592616181, 54.711736763633134],
            [-1.301546624455813, 54.710194139911671],
            [-1.302004604252203, 54.709012315465557],
            [-1.301312705861656, 54.708090777833441],
            [-1.303797199158237, 54.707653928933667],
            [-1.303201259070796, 54.70565992742825],
            [-1.304202718326231, 54.70563242655529],
            [-1.304105622354182, 54.704323389375837],
            [-1.30639563607732, 54.703991415863712],
            [-1.306217382604125, 54.703797179657485],
            [-1.307768065757708, 54.702771659477484],
            [-1.309301593352503, 54.702477548473773],
            [-1.309337452482986, 54.701553908201433],
            [-1.313835485356069, 54.701217275856806],
            [-1.312820930903314, 54.698720385407071],
            [-1.3142320642323, 54.697943825274116],
            [-1.313494187755639, 54.697075117946497],
            [-1.312682352635666, 54.695902232147816],
            [-1.315362599819778, 54.694568473848285],
            [-1.319372858822309, 54.693291557351841],
            [-1.319729876528663, 54.692723796490299],
            [-1.319481005760326, 54.691278217486953],
            [-1.32596778023451, 54.691154487266381],
            [-1.327540376162015, 54.691371713723278],
            [-1.329810682490855, 54.689542885005864],
            [-1.332175113652136, 54.690189502380129],
            [-1.333009339216471, 54.69201122867922],
            [-1.344462195168178, 54.691116595601734],
            [-1.344644013997659, 54.690808432261477],
            [-1.338659116051401, 54.68945936685531],
            [-1.334426337119841, 54.689036291237322],
            [-1.332115240234218, 54.686843379400393],
            [-1.331636336824937, 54.685117966048388],
            [-1.332092444418662, 54.683884791063448],
            [-1.331331297551732, 54.683669404902943],
            [-1.330931720252356, 54.682123263109567],
            [-1.330885861269508, 54.681233313734538],
            [-1.331835981614256, 54.68117744360385],
            [-1.331903440792079, 54.677445583256997],
            [-1.333442813846254, 54.677198831146256],
            [-1.332378299213272, 54.675441438476113],
            [-1.333024308883326, 54.672641998894058],
            [-1.334309894416879, 54.671213866850444],
            [-1.335693315710218, 54.671291553766416],
            [-1.338073686968699, 54.669562925416535],
            [-1.340187287735009, 54.669262605499632],
            [-1.3406996037336, 54.668744156211716],
            [-1.343515935309062, 54.668610260478609],
            [-1.342257106565326, 54.666103295795537],
            [-1.343363088372512, 54.665781272474192],
            [-1.343514519091965, 54.665139533154651],
            [-1.346032376427877, 54.66446025833239],
            [-1.346571163165837, 54.664022808620324],
            [-1.346629538742242, 54.66299323048348],
            [-1.347837247621672, 54.662410197496847],
            [-1.346410173555014, 54.661495737173553],
            [-1.346335596380478, 54.661119684582971],
            [-1.347069445388292, 54.660889083312981],
            [-1.34643503202476, 54.660719407202663],
            [-1.34599551078481, 54.660094247395385],
            [-1.346384954063582, 54.659975924390949],
            [-1.346306213333408, 54.659279917202909],
            [-1.347498752674161, 54.658572787098052],
            [-1.345416759498219, 54.65829465062059],
            [-1.344913458472992, 54.658457289434274],
            [-1.343130477210692, 54.657517499242175],
            [-1.343443690328499, 54.655684079506536],
            [-1.341295956905279, 54.653251363876763],
            [-1.341883491271938, 54.652188713446144],
            [-1.34120838010049, 54.650232198549482],
            [-1.343125334054608, 54.650241705719473],
            [-1.344037836000608, 54.650647462788562],
            [-1.345826250416092, 54.650748796064796],
            [-1.347865202631775, 54.650593528305663],
            [-1.348728860410963, 54.650858791908945],
            [-1.349882720399715, 54.650712210677675],
            [-1.349783616745238, 54.648570108578724],
            [-1.352712650467376, 54.648958744021542],
            [-1.355824313830372, 54.648287829361408],
            [-1.3562819375344, 54.648651533059244],
            [-1.358022117558402, 54.648564604781221],
            [-1.359291278007288, 54.64945652750594],
            [-1.362655924741986, 54.649153438770533],
            [-1.36486657754328, 54.648412844820989],
            [-1.366512283355426, 54.648818678014635],
            [-1.368953374877482, 54.647802418822245],
            [-1.374500439920486, 54.646547854166243],
            [-1.378891755872705, 54.646168728458761],
            [-1.38375048398611, 54.646232177361945],
            [-1.382681320527783, 54.644384430460896],
            [-1.381295824777238, 54.644293786750254],
            [-1.380884877436341, 54.64390615171294],
            [-1.384513523267448, 54.6431131232818],
            [-1.38695521756574, 54.64200394078825],
            [-1.387674304522126, 54.641376699199213],
            [-1.387690197609479, 54.640112328510483],
            [-1.39041688461006, 54.639198632305316],
            [-1.390543701719418, 54.638074115330426],
            [-1.390088463432737, 54.637745601695485],
            [-1.390789173998235, 54.63657723985439],
            [-1.388956723914931, 54.634900050678581],
            [-1.388511051088868, 54.63352371025335],
            [-1.387184519378972, 54.63272885979228],
            [-1.388564601849614, 54.632122028815246],
            [-1.388303313314509, 54.631571612125363],
            [-1.389495007685317, 54.631334977122947],
            [-1.393106469605633, 54.632026237088354],
            [-1.393368204260352, 54.631509904173484],
            [-1.395496729815783, 54.631086476786606],
            [-1.394222222812707, 54.628871138413025],
            [-1.396735069845542, 54.62832109813629],
            [-1.399115630980772, 54.626967822153532],
            [-1.400422946766127, 54.627703134124843],
            [-1.401585944587626, 54.626348271625268],
            [-1.399145219131749, 54.624118230911485],
            [-1.402862390065631, 54.622422811985679],
            [-1.404767053906615, 54.624034544797254],
            [-1.406633956379563, 54.622609397598367],
            [-1.40817529479232, 54.622052561183295],
            [-1.406609515574472, 54.621414922075417],
            [-1.406438652286529, 54.620811065586551],
            [-1.408072309556282, 54.620077641264224],
            [-1.409813432097846, 54.618441537216363],
            [-1.412810408647174, 54.616580538973359],
            [-1.416936246421828, 54.615022371048319],
            [-1.417954478138153, 54.614308317191863],
            [-1.426115665988393, 54.611487629820303],
            [-1.42669088574797, 54.612423191749649],
            [-1.428382034669644, 54.613172601130643],
            [-1.427948300395784, 54.614400857139294],
            [-1.428370478409352, 54.614992389047423],
            [-1.429958231974423, 54.615282957303137],
            [-1.431449441877765, 54.614724690784399],
            [-1.432578407835202, 54.613711771268868],
            [-1.435040562762057, 54.613921888923777],
            [-1.435726595584359, 54.614363645789794],
            [-1.436641379377065, 54.614163901392764],
            [-1.437089241481022, 54.613014764944388],
            [-1.43927371903022, 54.612472203411016],
            [-1.439300247054738, 54.611555665074803],
            [-1.43981521361424, 54.611150043466054],
            [-1.441869949055946, 54.610800951671187],
            [-1.442878179071012, 54.610099224519232],
            [-1.443415451834888, 54.610324568814619],
            [-1.44443872979851, 54.609993157068416],
            [-1.444478728769508, 54.610466948758855],
            [-1.447034399366682, 54.610735666420126],
            [-1.447610886576838, 54.611040255468701],
            [-1.448509819275807, 54.610972456313455],
            [-1.449769154245113, 54.610356291563527],
            [-1.450902074708944, 54.610502526109954],
            [-1.450934352645445, 54.609829555800225],
            [-1.452207519629364, 54.608754199277271],
            [-1.45194039800321, 54.607662883301465],
            [-1.452482269751904, 54.607546707023623],
            [-1.452629905381125, 54.607043210603905],
            [-1.451321710567296, 54.606237460145188],
            [-1.45077440543708, 54.604687439465238],
            [-1.450018583575711, 54.604025271740156],
            [-1.44887775228855, 54.6036758888511],
            [-1.4486639053217, 54.603233659770929],
            [-1.447252778259494, 54.603070856679331],
            [-1.446510096015358, 54.602245165341387],
            [-1.445483786471692, 54.602017596409702],
            [-1.444119724768041, 54.600448524217946],
            [-1.445155281311234, 54.599536609779328],
            [-1.4431742489157, 54.598497620851099],
            [-1.442180794112438, 54.597459556788635],
            [-1.440577946574699, 54.597185253270482],
            [-1.440282886757928, 54.596692306872399],
            [-1.439748233957279, 54.596628723976451],
            [-1.439574003595704, 54.59567800287428],
            [-1.438589152377189, 54.595489210278025],
            [-1.43834421066684, 54.595068386438442],
            [-1.439991561685936, 54.594460412178414],
            [-1.439353853182892, 54.593788836427741],
            [-1.439624847488798, 54.5935519377392],
            [-1.442393577080983, 54.59275859195705],
            [-1.443390082070403, 54.590612610297413],
            [-1.44433744652149, 54.590478562397529],
            [-1.44492069392831, 54.590832624944973],
            [-1.446847848781184, 54.591140706277294],
            [-1.451694479930651, 54.590816750303915],
            [-1.453301038680167, 54.594037727138058],
            [-1.454131919302774, 54.59440095055826],
            [-1.45606900941345, 54.599209562540054],
            [-1.459037452027198, 54.598417632082779],
            [-1.45915789810686, 54.597270544767348],
            [-1.46023656467147, 54.596812532027862],
            [-1.463581005906819, 54.59942549806744],
            [-1.4689019551428, 54.600542649175502],
            [-1.470382973102835, 54.60044848096927],
            [-1.472117236931648, 54.598964230202618],
            [-1.480728832559354, 54.599377106673664],
            [-1.483401121166278, 54.599200707917632],
            [-1.484676787537503, 54.599672560526464],
            [-1.488161407226882, 54.599273040913253],
            [-1.492432229114164, 54.597257284414688],
            [-1.497307215499558, 54.595664471997004],
            [-1.501486827424502, 54.595600864812241],
            [-1.506457160197363, 54.596061576150333],
            [-1.509939093094658, 54.59481036340749],
            [-1.521760089388553, 54.596546245177862],
            [-1.521942268482195, 54.596056281860783],
            [-1.526393321076417, 54.59647547997146],
            [-1.537511976876436, 54.594455960692429],
            [-1.541879720190784, 54.593056230913731],
            [-1.548249009945298, 54.591719543976936],
            [-1.552495395510437, 54.591307533001505],
            [-1.556524625205659, 54.591503886853879],
            [-1.558635168421176, 54.592106525334131],
            [-1.558814712194936, 54.591531122351427],
            [-1.558295521641556, 54.591458234010368],
            [-1.558235850860425, 54.590822646012271],
            [-1.560809101126106, 54.590273920439557],
            [-1.561673339798472, 54.58962909907622],
            [-1.561721030821345, 54.588501421612044],
            [-1.562236228035434, 54.588221996253829],
            [-1.562121261671585, 54.587398384791733],
            [-1.562547264881677, 54.587065613145263],
            [-1.562126384149684, 54.585765491491351],
            [-1.560366414999289, 54.586256987792638],
            [-1.557995377017391, 54.585444034206837],
            [-1.556870323471812, 54.585885671396589],
            [-1.556582356092066, 54.584617470109983],
            [-1.556013059420219, 54.584180420354357],
            [-1.554472725150006, 54.583259901127647],
            [-1.553908746934286, 54.583331517420824],
            [-1.553887099798969, 54.583613625226228],
            [-1.553540154040397, 54.58336341061564],
            [-1.552640752918089, 54.583687213637425],
            [-1.55217253866359, 54.582784999342444],
            [-1.552814955955344, 54.582331738980088],
            [-1.555636103625001, 54.581708548855595],
            [-1.56130104149423, 54.582219884342962],
            [-1.561825834719704, 54.581759859378131],
            [-1.564670489796781, 54.580801333962775],
            [-1.566429890821011, 54.580627908091124],
            [-1.569799440481804, 54.581227664879627],
            [-1.571447821320757, 54.580723055179362],
            [-1.572489477519306, 54.580830085518706],
            [-1.57358441628823, 54.580427739486673],
            [-1.57625725350908, 54.580188184675457],
            [-1.577021715327112, 54.581366338166021],
            [-1.578850479727703, 54.582115924287827],
            [-1.581496755388831, 54.581909412703538],
            [-1.582580555830506, 54.580925497352283],
            [-1.583306447164417, 54.580898340353116],
            [-1.584357621220841, 54.582050472525914],
            [-1.585332958389054, 54.585040146713254],
            [-1.585153850776311, 54.588202903742655],
            [-1.584248090106299, 54.588405596918498],
            [-1.582855413974995, 54.589464851085431],
            [-1.590851556065054, 54.590203882648012],
            [-1.593399859332797, 54.595067152104072],
            [-1.59035830775276, 54.594947265391077],
            [-1.589865971542956, 54.597738706665012],
            [-1.591355996352334, 54.599644456165898],
            [-1.592996987902705, 54.604432767072886],
            [-1.59064337219915, 54.605572453918825],
            [-1.58393135293336, 54.607347851077797],
            [-1.584182801153436, 54.610476125913337],
            [-1.586518243630836, 54.610412224243454],
            [-1.597567186743071, 54.608006812687954],
            [-1.600478729038013, 54.606642365232624],
            [-1.603955220680959, 54.605587044877502],
            [-1.605224891029933, 54.604761705879781],
            [-1.606208361627179, 54.603430364632978],
            [-1.607717432485288, 54.60426024984158],
            [-1.608428616358172, 54.60532389302292],
            [-1.60565181237208, 54.606720423838368],
            [-1.606934065827749, 54.612467159371747],
            [-1.605240410041748, 54.612442780920993],
            [-1.605766232047583, 54.614590540802702],
            [-1.606550047403777, 54.614718903677513],
            [-1.606753672380251, 54.617530634461936],
            [-1.610306949642292, 54.617433373757486],
            [-1.611347620111286, 54.617102407405433],
            [-1.612115328876116, 54.618117678777097],
            [-1.613676333066963, 54.617948321823278],
            [-1.614035272274259, 54.618801413339241],
            [-1.616818151838727, 54.618794069068457],
            [-1.618494966552467, 54.619157932675328],
            [-1.623875476737523, 54.618916831957002],
            [-1.623850402581996, 54.618613000758089],
            [-1.631385734870987, 54.618447434367923],
            [-1.631490347660665, 54.61612287108241],
            [-1.633229336814128, 54.61611826159416],
            [-1.637662178744937, 54.616694170308918],
            [-1.637990666809223, 54.617194817277564],
            [-1.640766985899666, 54.617391801837542],
            [-1.641618463169311, 54.619356137253362],
            [-1.6497313132166, 54.61794736566759],
            [-1.651764261653115, 54.616893683407547],
            [-1.653217874627314, 54.616945481793287],
            [-1.657030380245064, 54.615463627806605],
            [-1.662273376880293, 54.615847709686662],
            [-1.663178784984455, 54.615526705575057],
            [-1.672186123414866, 54.617075562802228],
            [-1.672894061927574, 54.617428858519915],
            [-1.678738229841401, 54.617821037386349],
            [-1.682413392763691, 54.617761525838063],
            [-1.681046713347006, 54.60875588281214],
            [-1.681066551074002, 54.608396463690781],
            [-1.681971380245932, 54.608109467332632],
            [-1.680649303482972, 54.606319417398808],
            [-1.68060119698737, 54.597823176329733],
            [-1.679302843541288, 54.585963449996328],
            [-1.683901673632273, 54.585944981630817],
            [-1.694018865390646, 54.586884017165872],
            [-1.693855946646286, 54.585084445533106],
            [-1.699034018285317, 54.584556413724322],
            [-1.70002404963548, 54.584166145396701],
            [-1.700160423609082, 54.582238810989615],
            [-1.702838888536442, 54.581563311217124],
            [-1.703067983790372, 54.579843795829689],
            [-1.704063220829892, 54.578072232646072],
            [-1.705685698918655, 54.578337701855361],
            [-1.708975269840529, 54.574131727413146],
            [-1.705458802016355, 54.573756565625317],
            [-1.704471120719256, 54.57215180824938],
            [-1.708245791509295, 54.569054210509847],
            [-1.707680669263089, 54.568702361452146],
            [-1.70801898148734, 54.567893463502209],
            [-1.709657791218118, 54.565805266971772],
            [-1.709272872497424, 54.563169401800145],
            [-1.707428064719601, 54.563150580688749],
            [-1.707193270261568, 54.562680900032838],
            [-1.706312051613815, 54.562644616464453],
            [-1.705544647983759, 54.561835734496633],
            [-1.705870428089592, 54.560617010923991],
            [-1.703301986680743, 54.558685762909889],
            [-1.701785122243643, 54.558253364562248],
            [-1.698725445773988, 54.558195465569199],
            [-1.696578995050936, 54.557806368300795],
            [-1.694684330811472, 54.556627923229527],
            [-1.691197468132729, 54.556234444859221],
            [-1.69131892387101, 54.555301918513685],
            [-1.693987788361495, 54.555279933842989],
            [-1.697553238454218, 54.555702286167111],
            [-1.699137773009915, 54.554062536579998],
            [-1.70047680164336, 54.554051476790569],
            [-1.701196145958997, 54.552938883156465],
            [-1.699384603685418, 54.552033017627359],
            [-1.70115498155579, 54.549895829768211],
            [-1.703924131296759, 54.550706956074933],
            [-1.705264821937661, 54.549161789780705],
            [-1.704590340493093, 54.548367504733925],
            [-1.704512803156509, 54.546259894959931],
            [-1.70376190632283, 54.544926206051201],
            [-1.701710367502911, 54.544753115274538],
            [-1.700861648269123, 54.544342118866602],
            [-1.698330744143765, 54.544199239608005],
            [-1.69873159881619, 54.54329076583479],
            [-1.698004031717447, 54.540713314242304],
            [-1.698508872380203, 54.540791859481907],
            [-1.69691683390583, 54.535996078614062],
            [-1.698606899025588, 54.53608477418225],
            [-1.704103034744559, 54.53665461883584],
            [-1.710645551717194, 54.538327611529652],
            [-1.715836591656447, 54.539944961419593],
            [-1.718710170660085, 54.541473148637884],
            [-1.719409808780274, 54.542228770433063],
            [-1.721301294421423, 54.542291553121551],
            [-1.723874998808713, 54.541582992828644],
            [-1.722842059540283, 54.540963234928959],
            [-1.722821351366592, 54.539684355361921],
            [-1.724467957916712, 54.537764023089494],
            [-1.724789015999248, 54.535985351238914],
            [-1.727295335223805, 54.530474872648476],
            [-1.731356469522508, 54.530671777161778],
            [-1.732549658952955, 54.52944860721334],
            [-1.733081616010926, 54.52772609574744],
            [-1.738990367795634, 54.528427372952621],
            [-1.743124636397629, 54.529750995694343],
            [-1.747526739629033, 54.529281253388575],
            [-1.750822111364541, 54.529620594188614],
            [-1.751237679602474, 54.530144485213292],
            [-1.751732041253404, 54.530162574868122],
            [-1.754004935798353, 54.528640342104026],
            [-1.761203653084535, 54.52894143365819],
            [-1.764598004951767, 54.529818009695063],
            [-1.767195437295268, 54.532276448582671],
            [-1.769714231750691, 54.532834855911403],
            [-1.773280955792993, 54.532813726267207],
            [-1.779686795845678, 54.53186305457313],
            [-1.780313574700344, 54.52880685785766],
            [-1.781549469263597, 54.526823891732882],
            [-1.776849581022487, 54.525351359363732],
            [-1.776341395951269, 54.519100040505933],
            [-1.777340979103814, 54.519366996016373],
            [-1.777580391833052, 54.515672923122565],
            [-1.776123242946015, 54.506718381060494],
            [-1.777870094677561, 54.506395373703469],
            [-1.782166270785663, 54.507002606558238],
            [-1.782478466317075, 54.505793529555561],
            [-1.783903275203167, 54.505025003870287],
            [-1.783925915616762, 54.503366059856241],
            [-1.785124142460173, 54.502792131067949],
            [-1.785048181037048, 54.501375658035684],
            [-1.787168535224006, 54.501028914212327],
            [-1.786878595763582, 54.498880530221747],
            [-1.790259825793715, 54.49288496613633],
            [-1.789013459584492, 54.491782799957043],
            [-1.791081803175653, 54.489419228818754],
            [-1.792968397980253, 54.484482349842999],
            [-1.801561092034665, 54.487667332421857],
            [-1.802725759129894, 54.487826507264671],
            [-1.813070286985418, 54.491400847411569],
            [-1.817511034595, 54.49362919003336],
            [-1.820268660231681, 54.494260601936972],
            [-1.822435410626948, 54.495167882998935],
            [-1.822711280691742, 54.496352763316274],
            [-1.8245420194263, 54.497885901928456],
            [-1.824379496337186, 54.500144078138142],
            [-1.826440218724652, 54.500659303082926],
            [-1.827218614247847, 54.501353307040333],
            [-1.827778735523342, 54.502919624601056],
            [-1.831845747406096, 54.502638657308573],
            [-1.83155595697734, 54.503641193540872],
            [-1.832294050102099, 54.505162802317834],
            [-1.836464594267564, 54.508011961043835],
            [-1.839425097443174, 54.5084275218654],
            [-1.844274810364042, 54.505095220004478],
            [-1.84596570655321, 54.504706451697736],
            [-1.848208779957661, 54.503653322000979],
            [-1.850968654564185, 54.503556095532907],
            [-1.852759979100854, 54.503977075795156],
            [-1.85536521950896, 54.503504807044358],
            [-1.857792636737377, 54.503530148048064],
            [-1.858662129912526, 54.499017940846386],
            [-1.858594677709192, 54.496636330397216],
            [-1.857711084241254, 54.493648943456932],
            [-1.857815448972325, 54.493381255824865],
            [-1.858642540779965, 54.493498155077987],
            [-1.859307825971345, 54.492628995817],
            [-1.859208869039482, 54.489072755585894],
            [-1.860037040888376, 54.487037277777304],
            [-1.860823455978369, 54.486367759053238],
            [-1.858522023859849, 54.482775712130582],
            [-1.859122514991176, 54.48184806164835],
            [-1.867247669644168, 54.482107076081363],
            [-1.877595991933761, 54.478039702380968],
            [-1.885462418658779, 54.472892484218953],
            [-1.893423205247362, 54.466888376952568],
            [-1.913175665597036, 54.461984676615977],
            [-1.928318639544309, 54.456482868677817],
            [-1.934018183056704, 54.457310207230663],
            [-1.94253656844921, 54.453385436578728],
            [-1.946989636147873, 54.454296044969226],
            [-1.949224561790304, 54.454259254502809],
            [-1.951613106474132, 54.453429833201561],
            [-1.953221461357185, 54.453688389024421],
            [-1.953775426086764, 54.453451344956136],
            [-1.955417575452232, 54.453850081130902],
            [-1.956548757121919, 54.453238477988648],
            [-1.958507766465055, 54.452977641353307],
            [-1.960496593499736, 54.45215329949751],
            [-1.963476615670584, 54.451771385371721],
            [-1.965233037990394, 54.452030724441677],
            [-1.965964424665112, 54.451607644638131],
            [-1.968434978933898, 54.451797934954769],
            [-1.970053023546139, 54.451511658802573],
            [-1.971744753062231, 54.453955619982473],
            [-1.971104086893763, 54.454803839736257],
            [-1.976118617637016, 54.459718995888757],
            [-1.97785995533762, 54.460126432948925],
            [-1.98004421022852, 54.460204995150946],
            [-1.985532818901286, 54.459915486059266],
            [-1.986263865262251, 54.460319084269109],
            [-1.986533421613959, 54.461550327355418],
            [-1.996757605172801, 54.466893755872526],
            [-2.014639199517013, 54.47154721357424],
            [-2.029561114947106, 54.472945517325421],
            [-2.036950510770755, 54.474285218560759],
            [-2.044722602374179, 54.475176776554179],
            [-2.0431914601106, 54.483590003504332],
            [-2.048606268912587, 54.482582298930808],
            [-2.050459996457294, 54.481395260876468],
            [-2.053065867054417, 54.480704841955927],
            [-2.059199795886592, 54.48114593488836],
            [-2.06128244879873, 54.480548160397909],
            [-2.06309750824909, 54.479602696222997],
            [-2.06316891192877, 54.478871122434761],
            [-2.067698507282113, 54.477041616961273],
            [-2.069912191617745, 54.475654566398575],
            [-2.0711674312129, 54.474254564669394],
            [-2.075734495063049, 54.473127513260231],
            [-2.081617872891774, 54.469864106034542],
            [-2.086390162742621, 54.468383324607359],
            [-2.094747008596736, 54.467288724925162],
            [-2.101793006689002, 54.465349887137791],
            [-2.10442538310199, 54.464901882767499],
            [-2.108584782669895, 54.464365282004231],
            [-2.114298586881691, 54.464184755699833],
            [-2.116590215860774, 54.462253960044414],
            [-2.123003910298719, 54.461511556746686],
            [-2.126556842785292, 54.462091134958349],
            [-2.136043548238962, 54.461440037916212],
            [-2.138881135597655, 54.461643504693626],
            [-2.14148414709878, 54.460918826520562],
            [-2.150114603417449, 54.459953980532831],
            [-2.155366657220925, 54.456998705914906],
            [-2.159261619421903, 54.455473929768338],
            [-2.159867167385838, 54.456501239157696],
            [-2.164786074421144, 54.456513494358873],
            [-2.167320509424381, 54.457694490146068],
            [-2.170207021876254, 54.458189230371929],
            [-2.161690401668516, 54.464722786572061],
            [-2.159361112721051, 54.471134485923166],
            [-2.158991147644566, 54.472020188869074],
            [-2.166464217978663, 54.489898618343283],
            [-2.162387441514113, 54.503933646763798],
            [-2.16869867142214, 54.51964307290654],
            [-2.174486716159757, 54.521718008147793],
            [-2.174019218601731, 54.52440036741659],
            [-2.171166300045235, 54.528994040641287],
            [-2.172392239094217, 54.532435165945827],
            [-2.18228042640683, 54.535846441104084],
            [-2.19752482714369, 54.532695047885134],
            [-2.21013687204774, 54.543649424725103],
            [-2.209482608250418, 54.55170457434798],
            [-2.245956720519417, 54.565054099887192],
            [-2.262275968664018, 54.574220509485606],
            [-2.270931142428269, 54.58219070451382],
            [-2.30450915224456, 54.596189548824128],
            [-2.308752605638746, 54.611875114512927],
            [-2.324932228065347, 54.631642269616876],
            [-2.32380883498284, 54.63199216932685],
            [-2.319969243691709, 54.634433297368787],
            [-2.317058174972431, 54.635407034121862],
            [-2.314179678694268, 54.637006093138815],
            [-2.312295399770077, 54.637161048777948],
            [-2.311140035299706, 54.637618753678034],
            [-2.309076730060458, 54.639707166194121],
            [-2.308253554196646, 54.640056153609855],
            [-2.308365830245966, 54.640764920829191],
            [-2.30691915648662, 54.642267585015034],
            [-2.305782396405129, 54.642753948514823],
            [-2.302854003301892, 54.642773893346074],
            [-2.302185334144313, 54.643704792374081],
            [-2.30059437541623, 54.644179658742537],
            [-2.298963955538393, 54.645616181034555],
            [-2.296806327484839, 54.646811333493808],
            [-2.294085118555372, 54.649380988205344],
            [-2.292895134522606, 54.649842196271344],
            [-2.287972900240425, 54.650472293798053],
            [-2.289307824905248, 54.651640977493983],
            [-2.289243871266648, 54.653109558786802],
            [-2.289828641090774, 54.653392139935931],
            [-2.289966975420585, 54.654969874473998],
            [-2.292090598777607, 54.655858945692607],
            [-2.291338122665872, 54.658354569461132],
            [-2.292761108310071, 54.661155885270134],
            [-2.292869064363092, 54.663877694349488],
            [-2.298190796243115, 54.665510166119347],
            [-2.302210122597044, 54.666243398558755],
            [-2.303391140819815, 54.666816491567879],
            [-2.303828791779114, 54.667497483911824],
            [-2.302747334857158, 54.669055789253711],
            [-2.303311288347085, 54.669622336382091],
            [-2.306148031228823, 54.670147208123389],
            [-2.308349536146355, 54.66949997147011],
            [-2.313432404976535, 54.669336841402817],
            [-2.316358354678761, 54.668757677470154],
            [-2.318969941958131, 54.669423928454947],
            [-2.322494061584605, 54.669009292707969],
            [-2.325011955031625, 54.670160045930039],
            [-2.327563621104993, 54.670865814132519],
            [-2.327772889463728, 54.671628218005182],
            [-2.326407554218051, 54.672686045355015],
            [-2.327100761837257, 54.673659230421734],
            [-2.329256804397329, 54.674692247830734],
            [-2.330510249157693, 54.674559424534841],
            [-2.335610592547393, 54.675320047681701],
            [-2.339858240110671, 54.678125526747792],
            [-2.341658607279557, 54.678844787243733],
            [-2.343503109536167, 54.682210474024565],
            [-2.346790405893346, 54.683932838103495],
            [-2.349072218017507, 54.683955938303001],
            [-2.350198348946976, 54.685403137104345],
            [-2.351587867335411, 54.685728922124035],
            [-2.35177656006845, 54.68745830956],
            [-2.350477048540339, 54.6900223806269],
            [-2.351167123622396, 54.690711456714595],
            [-2.353897404418198, 54.691418848434175],
            [-2.354196398656472, 54.691727115863941],
            [-2.354162907319505, 54.692868520851015],
            [-2.35251212183603, 54.693752232903144],
            [-2.352251583014489, 54.694480012236539],
            [-2.353922173385941, 54.695866277839997],
            [-2.354300592925286, 54.696738675189422],
            [-2.355464897462189, 54.697166623628121],
            [-2.355476108788786, 54.697916078359142],
            [-2.353459178129733, 54.697932764931359],
            [-2.352250766148423, 54.699934019623271],
            [-2.351148011378938, 54.700708276736385],
            [-2.349556851320659, 54.701172097196974],
            [-2.349183751616332, 54.700911661682973],
            [-2.348237466787858, 54.701122876344982],
            [-2.346357603920735, 54.700855072086554],
            [-2.34437602501847, 54.701764779524304],
            [-2.344524324087003, 54.702051930675019],
            [-2.343372562683762, 54.703294460344893],
            [-2.343353586174989, 54.704354040065574],
            [-2.342645209445934, 54.704634632041689],
            [-2.342766251571275, 54.705186069727972],
            [-2.341453921090864, 54.705990486301516],
            [-2.342539889849951, 54.707051438982703],
            [-2.344013345165064, 54.7076790244828],
            [-2.344193931609475, 54.708477424062011],
            [-2.34502702079, 54.709168822850209],
            [-2.344080023087263, 54.70987067707712],
            [-2.341143553600773, 54.71061139330974],
            [-2.34224576449888, 54.711559969271335],
            [-2.341699142529889, 54.712449392034799],
            [-2.341958399686729, 54.713929657883391],
            [-2.341382126994869, 54.714804783895708],
            [-2.342201540574746, 54.715521402595868],
            [-2.342088787460961, 54.716140899902051],
            [-2.34138375893057, 54.716660518550334],
            [-2.339037340485377, 54.716555673655947],
            [-2.337963419047222, 54.716775252081035],
            [-2.33744731141926, 54.717246691393683],
            [-2.337567390479296, 54.71955322366005],
            [-2.336089801420829, 54.719591480885754],
            [-2.335892599238268, 54.719961378001301],
            [-2.334412781432395, 54.720484898526749],
            [-2.330206886008085, 54.723121426258032],
            [-2.325740324209742, 54.726622120571086],
            [-2.329603162946765, 54.727546281070801],
            [-2.33065831625776, 54.728984858123937],
            [-2.330628970720916, 54.730338321183829],
            [-2.331941018268473, 54.731275631436638],
            [-2.332299362115922, 54.73198908425789],
            [-2.331612047003398, 54.732444790710041],
            [-2.331912221110125, 54.73345046828215],
            [-2.330921356756531, 54.735154341798875],
            [-2.329448664906232, 54.735675084139878],
            [-2.32858040677388, 54.737003863277728],
            [-2.327723164104933, 54.737011576724186],
            [-2.326885434093475, 54.737899019030856],
            [-2.327876362623083, 54.739056510971295],
            [-2.327453448380631, 54.740154917297339],
            [-2.326392996621052, 54.740975557863749],
            [-2.327588935093112, 54.743276491751637],
            [-2.326705200684742, 54.745411393799174],
            [-2.326920732817262, 54.747289906611819],
            [-2.322807822223731, 54.752062917740766],
            [-2.322584586452559, 54.753296469551522],
            [-2.320815934004767, 54.754996929448048],
            [-2.32112115606396, 54.756652345075736],
            [-2.322426172791149, 54.757798261910786],
            [-2.32222459150279, 54.759415480349894],
            [-2.323217465230442, 54.75979296825129],
            [-2.322472112057344, 54.763963817077098],
            [-2.320898850397723, 54.765668247877208],
            [-2.32092546892804, 54.766281958763038],
            [-2.320009526437325, 54.766413786599202],
            [-2.317550011621128, 54.76810343498164],
            [-2.317562740678961, 54.768736054421964],
            [-2.314662519842033, 54.770351310056704],
            [-2.313238951565364, 54.770381057935658],
            [-2.313145923376681, 54.770813550898723],
            [-2.309897797051995, 54.771899384156129],
            [-2.309622655412419, 54.77250847580946],
            [-2.310010826051332, 54.772653964288921],
            [-2.308480186860115, 54.773535853564859],
            [-2.308922670725432, 54.774483704804069],
            [-2.31167510844027, 54.775413056289906],
            [-2.311670631583924, 54.778064094503875],
            [-2.313917374471643, 54.781106527796148],
            [-2.313778907295271, 54.782098099908268],
            [-2.312992778424041, 54.782579113639365],
            [-2.314003267438029, 54.785166416165083],
            [-2.313108811603385, 54.786325294278605],
            [-2.312984406351926, 54.787330308038491],
            [-2.313664495944745, 54.79021142420774],
            [-2.312082852581402, 54.791008115704116],
            [-2.30879421139322, 54.790871852796784],
            [-2.307932843620642, 54.792123168066716],
            [-2.305866465022981, 54.791775228549454],
            [-2.305144986022264, 54.792204804121681],
            [-2.3029514952459, 54.792532920062143],
            [-2.302542498610389, 54.792328150300115],
            [-2.300548329000967, 54.793322523847543],
            [-2.298763596549014, 54.792994433962633],
            [-2.295532949491109, 54.793574791541744],
            [-2.292979319752415, 54.793158623295831],
            [-2.290087758578917, 54.795928025611587],
            [-2.286073945415205, 54.796223332621061],
            [-2.285972722499297, 54.796866105772544],
            [-2.282670948423946, 54.798245186857564],
            [-2.280664101390247, 54.797972166182369],
            [-2.277082563942011, 54.796242416175765],
            [-2.272190727132791, 54.795743962674734],
            [-2.268719131964728, 54.794560097788754],
            [-2.26690527108075, 54.794736645145832],
            [-2.263168918058649, 54.792502682958194],
            [-2.260203591313563, 54.792574685053609],
            [-2.260576313373281, 54.791526059190005],
            [-2.256275655829384, 54.791185652117122],
            [-2.251287235396077, 54.789769039471494],
            [-2.250712496560737, 54.790125196066953],
            [-2.250426306529994, 54.789866077885549],
            [-2.250966314411523, 54.789426420117238],
            [-2.248655724908173, 54.787952902828671],
            [-2.235513565136948, 54.786790235577264],
            [-2.234056870499557, 54.784158215707592],
            [-2.22669077252467, 54.782943761767889],
            [-2.219529413333259, 54.782990194808391],
            [-2.218899459259423, 54.782370367096291],
            [-2.21504978313425, 54.783845653011056],
            [-2.214455177121786, 54.786659479596132],
            [-2.212430488028411, 54.788122451497408],
            [-2.209394553810351, 54.792235457786752],
            [-2.206851238912671, 54.793950851174479],
            [-2.203789600049834, 54.797791466106659],
            [-2.201513743019094, 54.799919673267475],
            [-2.198616803306964, 54.80454799208357],
            [-2.199302356382726, 54.805709715100114],
            [-2.198593287280042, 54.806729044881962],
            [-2.186823302651813, 54.806242718160966],
            [-2.182289368471844, 54.807163544574877],
            [-2.177408933251741, 54.806814024226171],
            [-2.173844389222043, 54.806020293614381],
            [-2.171667248228183, 54.804028403336382],
            [-2.169462662335631, 54.803602852040214],
            [-2.166342342156095, 54.813882284185553],
            [-2.164257359000127, 54.815820807552726],
            [-2.160861812036416, 54.818525786673618],
            [-2.15715314495817, 54.820469475577177],
            [-2.151283818082824, 54.822807574990563],
            [-2.143122875421443, 54.826952141757509],
            [-2.143434399165754, 54.830081743189879],
            [-2.141823288939767, 54.831862050844443],
            [-2.141462119726921, 54.833192462049304],
            [-2.139732310591984, 54.834474133689447],
            [-2.139079513510675, 54.838400147014319],
            [-2.139781243295505, 54.841863597405599],
            [-2.138154217815049, 54.842362410708823],
            [-2.134809568686901, 54.842845152027877],
            [-2.13342913135112, 54.842129565177423],
            [-2.130998756610328, 54.842696562292069],
            [-2.129145107917561, 54.842491864690508],
            [-2.128636968949364, 54.841841790003024],
            [-2.12494137357404, 54.84165244906827],
            [-2.122929560583567, 54.841127005176922],
            [-2.121908772634638, 54.841343710011991],
            [-2.120723767736862, 54.840802118037921],
            [-2.119710116022008, 54.841326132077135],
            [-2.118126810053448, 54.840879264812486],
            [-2.116298252849642, 54.841225212804083],
            [-2.113908667251281, 54.840310870502343],
            [-2.111409067482659, 54.840114595554006],
            [-2.10866861657369, 54.840668849641602],
            [-2.104971909530432, 54.839974765136525],
            [-2.103910689333466, 54.840237184340197],
            [-2.102864917193533, 54.839846270068108],
            [-2.101400483740329, 54.837658418142723],
            [-2.100418511200687, 54.837217105393606],
            [-2.098445986266879, 54.83725826506781],
            [-2.097717683587571, 54.836755616456806],
            [-2.093551702678251, 54.836887412133223],
            [-2.09222661814904, 54.837502199829103],
            [-2.09058456506277, 54.837731694013321],
            [-2.088507822707132, 54.83712395208542],
            [-2.087472529779986, 54.837869677072845],
            [-2.082467637879033, 54.838214673268588],
            [-2.081088916736736, 54.839361370431661],
            [-2.079376556559382, 54.839538638914853],
            [-2.078664294887537, 54.839176952190741],
            [-2.076410250320302, 54.840258561101813],
            [-2.074323002304076, 54.840663350176705],
            [-2.069797793847747, 54.843131012606953],
            [-2.068487203600558, 54.84344448802041],
            [-2.067883738126355, 54.844792786428421],
            [-2.06687487109889, 54.844919157795609],
            [-2.066151233028705, 54.844257258937745],
            [-2.065049711091016, 54.84389570491296],
            [-2.064411853035784, 54.844262690937306],
            [-2.061407034172348, 54.84463718913225],
            [-2.059317355885943, 54.845761530635684],
            [-2.057190579184895, 54.846066295252278],
            [-2.05329763091178, 54.847406147129377],
            [-2.049611613460757, 54.847530830283475],
            [-2.047406895960396, 54.847913636936092],
            [-2.045352630068263, 54.847791312479387],
            [-2.043719444323277, 54.848210680307361],
            [-2.0415794768723, 54.848046087046072],
            [-2.039748277602315, 54.848283944613677],
            [-2.035980577392554, 54.851238051428858],
            [-2.036207753750543, 54.852727924425075],
            [-2.034820686063768, 54.853517336608206],
            [-2.030251805018287, 54.854815303886895],
            [-2.028746136388836, 54.855558845649043],
            [-2.027606515773539, 54.856379567975793],
            [-2.027479816076851, 54.857878523037662],
            [-2.026094082594757, 54.858857446830498],
            [-2.025129910182278, 54.858985258599333],
            [-2.023154415074016, 54.858552512743245],
            [-2.02242239961537, 54.858776412148288],
            [-2.021958773883034, 54.859841381970554],
            [-2.019026663849184, 54.862400300001482],
            [-2.016638483723436, 54.862575889764855],
            [-2.014512252313565, 54.863410101037715],
            [-2.014168141377116, 54.863949323838177],
            [-2.012066617729537, 54.864466270806076],
            [-2.011929723600478, 54.865154639455312],
            [-2.012373881301952, 54.865561675932682],
            [-2.011760362341927, 54.866703903738426],
            [-2.008913899400393, 54.86721008373646],
            [-2.008293909229373, 54.86780502545286],
            [-2.005992705130801, 54.868548336915701],
            [-2.004227291665843, 54.868102692012073],
            [-2.001929084271483, 54.86833280576861],
            [-2.001410238187827, 54.868555675498321],
            [-2.001042554069152, 54.8696457241426],
            [-2.000029737948517, 54.869287175488203],
            [-1.997581839301914, 54.869811060805084],
            [-1.996587739767519, 54.869554928165371],
            [-1.99535361651735, 54.870031166332474],
            [-1.990354945169169, 54.870028187147334],
            [-1.988190809396325, 54.869365706834451],
            [-1.986438243075145, 54.868247625701962],
            [-1.982779623316022, 54.868610218565344],
            [-1.980227608348162, 54.868175794753192],
            [-1.977763468846292, 54.866698916378169],
            [-1.973447008296287, 54.865274620325813],
            [-1.970684394739518, 54.863481212832156],
            [-1.972216852084817, 54.862005110961],
            [-1.97319180452843, 54.862432180039349],
            [-1.97475772313542, 54.862055983721511],
            [-1.974499356056014, 54.861669516824143],
            [-1.972781418985082, 54.86108413560509],
            [-1.972456402739343, 54.860234851830484],
            [-1.970812679925038, 54.860557980546581],
            [-1.969205623919009, 54.859738929543504],
            [-1.967824077686755, 54.859465388933756],
            [-1.964192917327249, 54.859431130990664],
            [-1.962765949162989, 54.857772720597914],
            [-1.959402899037037, 54.856150509526906],
            [-1.953832309701349, 54.855145652853672],
            [-1.952403485911766, 54.85423388631223],
            [-1.949048497651484, 54.852959163280893],
            [-1.946903155265882, 54.853434526741047],
            [-1.944467110697295, 54.852321827996477],
            [-1.940371603728335, 54.852880644880983],
            [-1.940033399095097, 54.851975551072755],
            [-1.939320369581552, 54.851773004977012],
            [-1.933746693090389, 54.852380279220242],
            [-1.932982930978251, 54.852733026376953],
            [-1.934980886018269, 54.853922106845161],
            [-1.932672141602695, 54.854179661433072],
            [-1.932162402328935, 54.853493718552784],
            [-1.931257535239497, 54.853451874381015],
            [-1.930908197390367, 54.85279926607322],
            [-1.930743281389455, 54.851780117425733],
            [-1.932019286032939, 54.851527419795737],
            [-1.931828774663188, 54.850897368331353],
            [-1.929416091823445, 54.850110585722859],
            [-1.928982764070517, 54.850318818036008],
            [-1.92615720171195, 54.848818210659651],
            [-1.922400605754352, 54.849744169654109],
            [-1.921308229647297, 54.848464706984359],
            [-1.921792905201001, 54.845866156628887],
            [-1.920603772756652, 54.843961171948386],
            [-1.920967992053095, 54.842445404675551],
            [-1.925058876925033, 54.8422763493448],
            [-1.926974894511061, 54.84185425350406],
            [-1.927184423204619, 54.841365519127592],
            [-1.924150357144626, 54.840161288894606],
            [-1.919865432484842, 54.840925984080272],
            [-1.918128067209052, 54.840765769692709],
            [-1.918264736673998, 54.839394537540059],
            [-1.92074625153751, 54.837983515542277],
            [-1.91974012013221, 54.837367288529514],
            [-1.917030606474621, 54.836827187842083],
            [-1.916810463120711, 54.836369629900346],
            [-1.917691509562901, 54.834910836743774],
            [-1.915191638456875, 54.833970042579828],
            [-1.913538094332363, 54.834074020864236],
            [-1.913013246045892, 54.834895903094726],
            [-1.913657488964827, 54.835762650762803],
            [-1.911598648359417, 54.836064914110381],
            [-1.910946147025298, 54.836850748827167],
            [-1.911372858215371, 54.837509763246693],
            [-1.914731805805808, 54.837948900211643],
            [-1.914962872555527, 54.838387597998178],
            [-1.914248816120732, 54.838843606380593],
            [-1.914119785140513, 54.839485144216141],
            [-1.912558999847192, 54.839790469800079],
            [-1.910982463679888, 54.839457729662485],
            [-1.908805649105134, 54.839507341507399],
            [-1.907168932048918, 54.840316677767419],
            [-1.906037463551907, 54.841443602531228],
            [-1.904769421725466, 54.841687047364282],
            [-1.904288659383644, 54.842185415269689],
            [-1.902236051260228, 54.84232127632535],
            [-1.899321009681021, 54.842991981592768],
            [-1.898208238492847, 54.843997532204398],
            [-1.898478234616523, 54.845000638814192],
            [-1.897459479670071, 54.845746553993557],
            [-1.896216438992999, 54.845883891087375],
            [-1.894960678418707, 54.845517966707909],
            [-1.894513378151189, 54.845061969994681],
            [-1.895156116701638, 54.844613205715895],
            [-1.894014134675354, 54.843630902916026],
            [-1.889069190016535, 54.843967081300704],
            [-1.8891694172188, 54.843759586939505],
            [-1.88763920817725, 54.843580253434055],
            [-1.886249727067336, 54.844307759247542],
            [-1.884750741760337, 54.84574057710936],
            [-1.881829357906626, 54.846799966987604],
            [-1.881372148305728, 54.846582950236865],
            [-1.877970917716992, 54.847138540833697],
            [-1.877007132029821, 54.848102708692295],
            [-1.870400462344554, 54.850665059931572],
            [-1.869449644533603, 54.851872712168841],
            [-1.869462184754682, 54.852814498436167],
            [-1.867228093544992, 54.853947061271391],
            [-1.866591007219971, 54.855377894626479],
            [-1.867403780477765, 54.856899277833811],
            [-1.868714410685234, 54.857669932459302],
            [-1.870549625774434, 54.859548252931717],
            [-1.869715359565375, 54.860296826005978],
            [-1.869661475128367, 54.861550366789174],
            [-1.866443988207942, 54.864063061129443],
            [-1.864928597635438, 54.864378607386961],
            [-1.861268144260225, 54.866423380517354],
            [-1.859769037046889, 54.869197548948208],
            [-1.860818796934699, 54.871138009711288],
            [-1.859898698952941, 54.872702376853887],
            [-1.860039231985992, 54.87802695016245],
            [-1.857756068165762, 54.879346194491092],
            [-1.853255001383339, 54.880198147918534],
            [-1.852259447395542, 54.883081558752991],
            [-1.853042824341384, 54.883259538003848],
            [-1.852946747672688, 54.883533505261013],
            [-1.852336241602428, 54.884248078539578],
            [-1.85095360180172, 54.884681331907878],
            [-1.851334137841899, 54.886323600679404],
            [-1.855149773054277, 54.889034884222987],
            [-1.855068910281395, 54.890283887575634],
            [-1.854426515921459, 54.891177260514802],
            [-1.854019130238034, 54.891311567523353],
            [-1.848610487095672, 54.889760211043338],
            [-1.84746460123646, 54.889774955781284],
            [-1.845628013574014, 54.891037016626633],
            [-1.844496054378746, 54.892690856964052],
            [-1.845864514420808, 54.89606786029217],
            [-1.845438806970629, 54.896895859111609],
            [-1.843553947008185, 54.897234931232497],
            [-1.839108264772674, 54.897306418250004],
            [-1.834676126615306, 54.899018662753484],
            [-1.830501976219228, 54.901591993868699],
            [-1.827686231357589, 54.905486301880003],
            [-1.825397061238826, 54.905785878085737],
            [-1.822703556514806, 54.905458477121009],
            [-1.821002767559196, 54.905654585076199],
            [-1.81924554042427, 54.905912590962011],
            [-1.816638962121885, 54.905340756988195],
            [-1.814806521838057, 54.905332590574417],
            [-1.810485092980743, 54.90664153366555],
            [-1.807452026098814, 54.907219085470842],
            [-1.806276441879421, 54.906490227298086],
            [-1.805559846465957, 54.905023416982083],
            [-1.804606143854553, 54.904548310688611],
            [-1.802759535749883, 54.904574986470642],
            [-1.800061841511134, 54.906092865471358],
            [-1.798814785475881, 54.905656771217203],
            [-1.796210856869944, 54.903692522913254],
            [-1.794915696013796, 54.903539376801348],
            [-1.794090237935787, 54.903941468388005],
            [-1.793939936148143, 54.905280175001685],
            [-1.791613610502095, 54.907596479449246],
            [-1.791241566742882, 54.908666111398112],
            [-1.785802144106465, 54.909111365445021],
            [-1.783164387718383, 54.90974831618874],
            [-1.782453199046108, 54.910029216923455],
            [-1.782366011979238, 54.91115774144378],
            [-1.781746295297383, 54.911535853693678],
            [-1.776408014014684, 54.909964340979776],
            [-1.772692159411524, 54.907847469603347],
            [-1.770846227698186, 54.907174526482258],
            [-1.769240917690512, 54.906707798473512],
            [-1.768096544347899, 54.906913206587618],
            [-1.768242700274179, 54.908894069137141],
            [-1.767176281306141, 54.909634302726403],
            [-1.766320668317046, 54.909517637650794],
            [-1.766098206580408, 54.90887918185566],
            [-1.765348363734629, 54.908547041617361],
            [-1.762058914022109, 54.908296225251242],
            [-1.760814624943962, 54.9085040652744],
            [-1.76020927182853, 54.909062721217211],
            [-1.759863702789112, 54.910783817585703],
            [-1.757074799003268, 54.911568178169084],
            [-1.755421102969152, 54.912649513081753],
            [-1.751850164424457, 54.916532455889275],
            [-1.750560856696743, 54.917412274858314],
            [-1.748300379086875, 54.91766283085137],
            [-1.747353566287399, 54.917393076619454],
            [-1.746206321708096, 54.916500145946699],
            [-1.742391293785817, 54.91736739476783],
            [-1.739614790136381, 54.91683129224571],
            [-1.737579369072039, 54.918692476788543],
            [-1.734149138022719, 54.918162026871883],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000049",
        LAD13CDO: "00EQ",
        LAD13NM: "Cheshire East",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.425154038414433, 53.387198379601031],
            [-2.424509278518423, 53.386620008533598],
            [-2.423274649297627, 53.38720146573651],
            [-2.422448153591691, 53.386336091391954],
            [-2.42034673913886, 53.386690474592065],
            [-2.419748384986919, 53.386236856589029],
            [-2.418564349583918, 53.386157421530868],
            [-2.41714735567009, 53.384702627441328],
            [-2.416429688895165, 53.384803110583697],
            [-2.415549598627927, 53.384435846616867],
            [-2.414434836490536, 53.384517026194466],
            [-2.412621040424375, 53.383983993551674],
            [-2.41059763799509, 53.383848953122026],
            [-2.407896465566105, 53.382640256233522],
            [-2.408486350547788, 53.382071054366698],
            [-2.407122253755362, 53.379807880086403],
            [-2.400096382647505, 53.377289656832382],
            [-2.39945895086737, 53.376478319526406],
            [-2.400865776200978, 53.375984617961514],
            [-2.400699112242192, 53.375204063537282],
            [-2.39793893620114, 53.374832183072925],
            [-2.397801597254435, 53.37428882730034],
            [-2.396166438307857, 53.37447134497117],
            [-2.39304854667113, 53.372460108734302],
            [-2.39167062183414, 53.372333406171684],
            [-2.390638108599163, 53.371846909656789],
            [-2.390280288923569, 53.370684948144699],
            [-2.387126120737192, 53.371074546932732],
            [-2.386382502099317, 53.37028325905316],
            [-2.384698405293105, 53.370027131790096],
            [-2.383414270750126, 53.36995666009075],
            [-2.38272731004966, 53.369440220332585],
            [-2.382240089763874, 53.370248065307223],
            [-2.380745192992102, 53.369801615204018],
            [-2.380966453999917, 53.369165410518747],
            [-2.379923140675426, 53.368793910705662],
            [-2.380268123644084, 53.368380231930033],
            [-2.37982330801581, 53.368046371975474],
            [-2.380031210800048, 53.367597175565471],
            [-2.379275926098065, 53.36750070406665],
            [-2.378172662976932, 53.366802192181026],
            [-2.377950546275648, 53.365987624311373],
            [-2.376915864236378, 53.365901011485875],
            [-2.375658818979455, 53.365307230623806],
            [-2.373354823521127, 53.365620977666481],
            [-2.370369326787892, 53.364468050483339],
            [-2.370386666969592, 53.364041933918514],
            [-2.369454697740074, 53.363661907004968],
            [-2.368895571419245, 53.363821837998124],
            [-2.36858412046228, 53.364472681250362],
            [-2.36758146135996, 53.36425914844979],
            [-2.366912786009988, 53.364438283848727],
            [-2.365028846280167, 53.363089472274794],
            [-2.364007293108362, 53.363297535676864],
            [-2.364555982556765, 53.363849561710531],
            [-2.363614818385603, 53.363971084812974],
            [-2.363308508477274, 53.364529315276336],
            [-2.361924719467769, 53.364024763696392],
            [-2.359276567595262, 53.36433838390812],
            [-2.359117892486057, 53.364768519749305],
            [-2.360417133671277, 53.365583468072487],
            [-2.360617572632641, 53.366540154507],
            [-2.36010926391784, 53.366853596585315],
            [-2.358266881739553, 53.366682966682937],
            [-2.357084291720196, 53.366877072263065],
            [-2.357338575575902, 53.368349551530727],
            [-2.356588662345237, 53.368531568758122],
            [-2.353967855743741, 53.36819421024213],
            [-2.353232303947482, 53.367933921966056],
            [-2.352756964263995, 53.368244538337777],
            [-2.352080941096881, 53.368094627365231],
            [-2.351168281174921, 53.367312608179994],
            [-2.3509767985074, 53.366505990485948],
            [-2.349007528396024, 53.365881663883748],
            [-2.34807546735257, 53.366208877889839],
            [-2.347764042354934, 53.367825946337859],
            [-2.343694486058068, 53.366539780129237],
            [-2.340953231246397, 53.367462692193854],
            [-2.340085247434601, 53.367356407417994],
            [-2.338592819983946, 53.366205611405015],
            [-2.337787006994652, 53.366165649468279],
            [-2.337761042692739, 53.366682570523942],
            [-2.337227013597329, 53.366805428345785],
            [-2.335145955221537, 53.365891754724188],
            [-2.332906570129763, 53.365475559324331],
            [-2.331756876710664, 53.363919231249312],
            [-2.329898041594404, 53.363921690508072],
            [-2.32959722691895, 53.363108150020182],
            [-2.330922157665962, 53.361889214925668],
            [-2.32904872401716, 53.360766321578176],
            [-2.327940312629233, 53.360441288370026],
            [-2.326539485766487, 53.361369165937219],
            [-2.32439970823903, 53.361363319900128],
            [-2.32342496015141, 53.358250497970893],
            [-2.322514910179779, 53.358511835853427],
            [-2.322722768679477, 53.359175535568866],
            [-2.32211691658974, 53.35953042774139],
            [-2.321267400667645, 53.359454517804551],
            [-2.319855973429863, 53.358581917200105],
            [-2.317953152796458, 53.359102954309137],
            [-2.315196994833769, 53.359035664493419],
            [-2.313941776174757, 53.358341455420025],
            [-2.313999519326579, 53.357408280545911],
            [-2.314302425782562, 53.356715355754631],
            [-2.312745761142853, 53.356083949771879],
            [-2.312222221076786, 53.354756797215593],
            [-2.309234062565501, 53.354354700023663],
            [-2.30790592963195, 53.353516797618902],
            [-2.305622600396076, 53.353129866607098],
            [-2.304525554623916, 53.352239199809567],
            [-2.303583234912686, 53.350707237064611],
            [-2.301437328495294, 53.350395380892301],
            [-2.301451774136971, 53.349037156517433],
            [-2.299170805092257, 53.350016373819557],
            [-2.298652352388481, 53.349774979388009],
            [-2.298885807359977, 53.349004065557708],
            [-2.297753411109608, 53.349040156615203],
            [-2.296931432835086, 53.348567604545593],
            [-2.298318518915847, 53.346716071646163],
            [-2.296712511843574, 53.34603064756493],
            [-2.296455538561874, 53.344931971897616],
            [-2.298146317293258, 53.34442708699288],
            [-2.29745998320989, 53.343793301730159],
            [-2.302836707461392, 53.340977096830741],
            [-2.301662793661717, 53.340207944749686],
            [-2.301328660051678, 53.340106318653206],
            [-2.297861224730049, 53.341884902530971],
            [-2.296728338643917, 53.342039635905735],
            [-2.29581749885162, 53.343027957169177],
            [-2.294482106266623, 53.342790366184509],
            [-2.294039307818903, 53.34304943401218],
            [-2.291279868316798, 53.343004072700609],
            [-2.290055315608214, 53.342275379615572],
            [-2.287933930246564, 53.343072423810014],
            [-2.285868117063172, 53.345002768569671],
            [-2.2867066683986, 53.345511311749796],
            [-2.284637352611522, 53.346491540325495],
            [-2.28064123791436, 53.345952704036797],
            [-2.278364823411518, 53.348599809646714],
            [-2.27334532244295, 53.350359717381927],
            [-2.271809096384847, 53.350683225573626],
            [-2.267077743500219, 53.350557289702941],
            [-2.26684900310408, 53.351694867208764],
            [-2.264194812217255, 53.352962785174249],
            [-2.263921193407512, 53.353660011893709],
            [-2.264612645154362, 53.353962295931446],
            [-2.261104344457755, 53.356124609886812],
            [-2.258041831855069, 53.357027445091227],
            [-2.258938444041731, 53.359444346164089],
            [-2.256448512543652, 53.360660497452081],
            [-2.254099513270334, 53.360838108158184],
            [-2.24959318078502, 53.360575270158755],
            [-2.245205757243447, 53.361122792933095],
            [-2.244193957109719, 53.361027792065784],
            [-2.244144643101297, 53.359762292485556],
            [-2.240791273900709, 53.359557878676846],
            [-2.239540737518649, 53.359217031509068],
            [-2.237593549054754, 53.359236208295073],
            [-2.232812005602714, 53.360255065012232],
            [-2.232982751493474, 53.358778796860413],
            [-2.228763658289429, 53.359339768181435],
            [-2.224625771607264, 53.359363815566773],
            [-2.224186086236923, 53.359756547774509],
            [-2.223047287624869, 53.35978385191104],
            [-2.220310701555342, 53.359411417512483],
            [-2.219553098179308, 53.358473502424786],
            [-2.216142792309141, 53.358850066005139],
            [-2.216218699520037, 53.358114657753909],
            [-2.214632569287048, 53.357917976155044],
            [-2.214540812680311, 53.356701978365273],
            [-2.210658271604404, 53.357269793502908],
            [-2.207410558125195, 53.356782012078348],
            [-2.208292717842902, 53.354980049190154],
            [-2.201938210975408, 53.354792334447389],
            [-2.201872027608455, 53.355410865048441],
            [-2.192573450124906, 53.354040201891394],
            [-2.189082364921845, 53.352773898391455],
            [-2.185441687683415, 53.352642991120163],
            [-2.185492949092764, 53.351993931348446],
            [-2.188015885764284, 53.348719908678177],
            [-2.185302709407544, 53.34693182129098],
            [-2.184829731171024, 53.346974802417989],
            [-2.180977835464517, 53.344027042906632],
            [-2.182753795505745, 53.343111984093113],
            [-2.190732318357637, 53.342110729564723],
            [-2.192699420948178, 53.341015442971205],
            [-2.192809711477409, 53.339829660097926],
            [-2.194163455419096, 53.338633767692755],
            [-2.190494530200504, 53.337627565137666],
            [-2.189108376020818, 53.335252270360002],
            [-2.184877440654083, 53.332674666788243],
            [-2.184269859738317, 53.332806842523837],
            [-2.184143067465968, 53.332306369475646],
            [-2.183912013645681, 53.332699531301493],
            [-2.183050680158598, 53.332486927193699],
            [-2.182681251868187, 53.332127947235598],
            [-2.183015302645547, 53.331940470229824],
            [-2.18216903139671, 53.331734128680409],
            [-2.181994780570923, 53.33136585933326],
            [-2.181472029981029, 53.331670473792954],
            [-2.179982841430339, 53.331046221965053],
            [-2.17968069853527, 53.330253876867879],
            [-2.178474600006188, 53.331241747147196],
            [-2.176051794225304, 53.331049398534468],
            [-2.174925204189878, 53.331311729361751],
            [-2.174277118393748, 53.331087962248688],
            [-2.173853411391329, 53.331390600666168],
            [-2.172246389785538, 53.330931815807048],
            [-2.172117161899362, 53.33128076307068],
            [-2.171052271012283, 53.331209488410799],
            [-2.170454045445621, 53.331809890199231],
            [-2.170291122460857, 53.331244735701134],
            [-2.169789358385287, 53.331183429975582],
            [-2.169924013696223, 53.330684366656335],
            [-2.169146632135571, 53.331168164578393],
            [-2.168890342671413, 53.330529433514918],
            [-2.167351975630554, 53.329942846215154],
            [-2.167279749768233, 53.329520479951441],
            [-2.166137662336165, 53.329662301395658],
            [-2.165988565999597, 53.329160941170464],
            [-2.164136673727684, 53.328624186470634],
            [-2.163502963606178, 53.327816077029766],
            [-2.163265422130038, 53.328126512149801],
            [-2.161246579959776, 53.327889264165655],
            [-2.161316553315183, 53.327334568072089],
            [-2.160648282671346, 53.327297718689962],
            [-2.160874899863729, 53.327670442966358],
            [-2.160112065025782, 53.327641808054736],
            [-2.160291986364077, 53.327975045991764],
            [-2.159858488927786, 53.328087088077879],
            [-2.158442707805322, 53.328918634246165],
            [-2.156874190483091, 53.32865284762002],
            [-2.156939359253014, 53.328409168692168],
            [-2.156021536021892, 53.328290824964071],
            [-2.155914007630972, 53.328037485107075],
            [-2.154165389598804, 53.327778190525592],
            [-2.152536037478602, 53.328536235677284],
            [-2.151968239483303, 53.330998059922663],
            [-2.151410447027037, 53.331218992644189],
            [-2.15176527063637, 53.332192014013408],
            [-2.151323338337336, 53.332908074213123],
            [-2.151034317309491, 53.336102113731897],
            [-2.15000878521135, 53.338280463227449],
            [-2.149044045909152, 53.338544142998217],
            [-2.148324732208713, 53.339424131548455],
            [-2.146738189166235, 53.340124515032237],
            [-2.146664711849206, 53.340594712214397],
            [-2.144099611935293, 53.342853994738377],
            [-2.146127355469518, 53.343757580686578],
            [-2.147177821025422, 53.344793577722527],
            [-2.147718619128799, 53.34481268341149],
            [-2.151191011788109, 53.348116149397903],
            [-2.149661074926979, 53.34961558904579],
            [-2.147092200657267, 53.350455630819567],
            [-2.146546727640389, 53.350845511148584],
            [-2.147166571405938, 53.351111709696525],
            [-2.14632644628021, 53.351881273119943],
            [-2.145733965989248, 53.351711215203437],
            [-2.142533329605919, 53.354172580554412],
            [-2.140327692577844, 53.355034512325929],
            [-2.13819725207545, 53.356874278959637],
            [-2.139169194007102, 53.357749538874735],
            [-2.139871178429347, 53.360137893791496],
            [-2.13922822753427, 53.361564242632923],
            [-2.139693857526342, 53.362884126134496],
            [-2.139290573094926, 53.363629754915543],
            [-2.13948157970348, 53.364598504578701],
            [-2.137714806544918, 53.366594234984447],
            [-2.139265845582724, 53.366640071779401],
            [-2.139026047384844, 53.367300116136995],
            [-2.1365137947544, 53.366977633266977],
            [-2.133377148218313, 53.364968635015558],
            [-2.129221997721691, 53.364946248724955],
            [-2.128147989746088, 53.364596852855378],
            [-2.12760210631819, 53.363963741307892],
            [-2.127160408430358, 53.363996572651537],
            [-2.124471705286853, 53.362330225982916],
            [-2.124825608180738, 53.362083567443854],
            [-2.123196301057098, 53.361929760603132],
            [-2.120705894195902, 53.363861268034213],
            [-2.119414484851618, 53.364187957706463],
            [-2.117238624241256, 53.364213489760481],
            [-2.116944978350781, 53.364536469775203],
            [-2.115927109187304, 53.364344209627234],
            [-2.115551102268599, 53.364777826312093],
            [-2.114404098227203, 53.364622531728358],
            [-2.11187804154601, 53.364634819020353],
            [-2.111331720363778, 53.364317134015302],
            [-2.110561622677569, 53.3646243632625],
            [-2.107439285842916, 53.364757556169103],
            [-2.106630314865716, 53.365158276671906],
            [-2.105623500016679, 53.365169061480294],
            [-2.103929084172481, 53.366065818352055],
            [-2.101464593406636, 53.366094909851995],
            [-2.101014691360247, 53.365854397593189],
            [-2.098941841598366, 53.366275007583461],
            [-2.094954377602317, 53.36602028164139],
            [-2.093680280500862, 53.365455008560289],
            [-2.092743436313321, 53.364477781033877],
            [-2.090792008173636, 53.364059517246119],
            [-2.0870001331738, 53.361589581154249],
            [-2.083867272557672, 53.36011859568729],
            [-2.083757943677597, 53.359537108945901],
            [-2.081368169971607, 53.359161245121662],
            [-2.078553521315159, 53.358132141907788],
            [-2.07802673050097, 53.358452483555716],
            [-2.075625983455601, 53.357757415813978],
            [-2.073483520254792, 53.357806398452986],
            [-2.072607166477881, 53.357570535898688],
            [-2.072119459089159, 53.357924085532332],
            [-2.071178269112546, 53.357548029272614],
            [-2.071012306777134, 53.358035311849285],
            [-2.070392821944557, 53.357759729445],
            [-2.06892981937074, 53.358019458834917],
            [-2.068078378659542, 53.357377262839762],
            [-2.065902057959365, 53.357871065610226],
            [-2.065040880790238, 53.357707946947194],
            [-2.063463426518626, 53.358819793214572],
            [-2.062302986932409, 53.358485131555369],
            [-2.061732366222864, 53.358715537995018],
            [-2.061163990865043, 53.358421903937909],
            [-2.059677166769814, 53.35889366357749],
            [-2.058260511613156, 53.361253878989508],
            [-2.057459183285965, 53.362042570832557],
            [-2.056337181154434, 53.362396360712729],
            [-2.056380372296024, 53.363238573402612],
            [-2.056927475251988, 53.363333591839577],
            [-2.056961043694809, 53.363713794100576],
            [-2.056254039718107, 53.364292997594383],
            [-2.055321206984857, 53.366874964475684],
            [-2.053909120824039, 53.36731156112603],
            [-2.051988599873609, 53.367383427218499],
            [-2.050113555678254, 53.369066900278881],
            [-2.0486306199727, 53.369427061046338],
            [-2.046669256600622, 53.36935324285011],
            [-2.043953326318987, 53.370613583016045],
            [-2.043022604598382, 53.371699747159312],
            [-2.041107260801029, 53.372683779086991],
            [-2.040564497433062, 53.374071805455849],
            [-2.038854507104505, 53.372970375945791],
            [-2.038422147685238, 53.371875705445824],
            [-2.032632761188847, 53.372017663296305],
            [-2.030958051462989, 53.371498571945594],
            [-2.031059334963302, 53.370246433238741],
            [-2.03408721706083, 53.367736880906428],
            [-2.032135541973823, 53.36643138193336],
            [-2.031729249996376, 53.365713301678504],
            [-2.030034446146814, 53.36616137658833],
            [-2.0285877117188, 53.364596818071256],
            [-2.027129523100179, 53.36375133325982],
            [-2.025994940621687, 53.363677881611395],
            [-2.024929776471904, 53.364096980480376],
            [-2.023579621099103, 53.365496784442307],
            [-2.023483911796457, 53.366337238438611],
            [-2.021817527172406, 53.366741146795604],
            [-2.02015809083743, 53.36602774673802],
            [-2.017395781553249, 53.365538308150633],
            [-2.016335522327907, 53.363447709418701],
            [-2.015571924058337, 53.362819509119944],
            [-2.014112754116531, 53.362577001207761],
            [-2.01187352107311, 53.361612771561902],
            [-2.01046880739288, 53.362563901523153],
            [-2.008999085860515, 53.361995045342404],
            [-2.010682580029565, 53.358243053952606],
            [-2.010912030343398, 53.356588227479364],
            [-2.010432412168532, 53.355239078997478],
            [-2.008939032833935, 53.355263472762218],
            [-2.008066077556959, 53.354950732724461],
            [-2.006785977172878, 53.354556214532089],
            [-2.006710690873077, 53.353529717802871],
            [-2.007268045279741, 53.353454179465828],
            [-2.006567717198835, 53.351956716080409],
            [-2.006704373261949, 53.351639409543751],
            [-2.008425865190896, 53.3510873947126],
            [-2.007705947222042, 53.349270842341653],
            [-2.007839353686026, 53.347749956326936],
            [-2.00466063224855, 53.345993750047619],
            [-2.010139198356257, 53.341886487795527],
            [-2.014319941315013, 53.340042478765859],
            [-2.013394890715039, 53.335648935532049],
            [-2.013650627210259, 53.332404000826017],
            [-2.009283754214723, 53.33137253191439],
            [-2.01081042597431, 53.329769721540444],
            [-2.011797798292509, 53.327580885884821],
            [-2.011114398814606, 53.326640738392335],
            [-2.011554113073567, 53.325937781764331],
            [-2.010866167759981, 53.32471538877661],
            [-2.009936791534812, 53.324484462992999],
            [-2.01078042875689, 53.324082594267082],
            [-2.010266686814644, 53.32288085514152],
            [-2.008975622321531, 53.322978039770909],
            [-2.008169315119937, 53.322330914939982],
            [-2.006052462335983, 53.321651501925643],
            [-2.004332037417636, 53.321391807855974],
            [-2.003731577288902, 53.321647107865125],
            [-2.001551691155102, 53.318905614225905],
            [-2.003178749254448, 53.316930765096529],
            [-2.004202223835664, 53.314594572055263],
            [-2.004333938283215, 53.311141112745361],
            [-2.008667005848227, 53.304489231551649],
            [-2.009329787431585, 53.302297734210413],
            [-2.008700614620501, 53.300165661927586],
            [-2.008654449525951, 53.294639404962254],
            [-2.009641921201236, 53.289419576981651],
            [-2.008171833509356, 53.28941699338737],
            [-2.008135449917853, 53.287471835636254],
            [-2.008275652084376, 53.283460155225086],
            [-2.009279812298716, 53.28005065145971],
            [-2.008133337357691, 53.27669524054437],
            [-2.007399868808722, 53.275896190544671],
            [-2.006637365405368, 53.271557368259522],
            [-2.006162009966369, 53.271387507409713],
            [-2.005199056818301, 53.269023513177508],
            [-2.005408777314285, 53.26757271806936],
            [-2.00773365728196, 53.264507417645305],
            [-2.009483862418509, 53.260635826199135],
            [-2.000334894576662, 53.255421836339266],
            [-2.001034833539964, 53.253868570052049],
            [-2.001861929968864, 53.2485004572235],
            [-1.999209332652977, 53.246358446752268],
            [-1.999733836649566, 53.245031702380743],
            [-1.990987704183008, 53.240993614636864],
            [-1.987730421260116, 53.240178047046449],
            [-1.983695900926607, 53.238623408833931],
            [-1.981885965043656, 53.238562027702024],
            [-1.97909414290647, 53.233310316565479],
            [-1.978014454387606, 53.232399558196185],
            [-1.974861750135326, 53.231043429092132],
            [-1.97493214091089, 53.228481631390871],
            [-1.976068128080245, 53.227511968907848],
            [-1.977688849048396, 53.227576999202405],
            [-1.9783451707467, 53.227115992382529],
            [-1.978953261520328, 53.227241943429703],
            [-1.979326486838165, 53.226727847447521],
            [-1.982244580473034, 53.226261791628829],
            [-1.983359192095469, 53.225768463720307],
            [-1.98407963171343, 53.225858448743296],
            [-1.984952998559476, 53.225537659343125],
            [-1.985937022349693, 53.225747216867894],
            [-1.985874220383248, 53.225411926537873],
            [-1.986602156264845, 53.225476728490719],
            [-1.986648689206764, 53.225150439373706],
            [-1.987770658871876, 53.224842240681248],
            [-1.988149814244623, 53.224086318111524],
            [-1.990267954333095, 53.22298087977245],
            [-1.990133602933853, 53.22097995614547],
            [-1.989115527264529, 53.219532668210306],
            [-1.989976710556969, 53.219370942887224],
            [-1.989457344582416, 53.218054035350796],
            [-1.989643582979686, 53.215770887267659],
            [-1.988004133007449, 53.214567131838834],
            [-1.987411420612017, 53.21356751312188],
            [-1.987217225337654, 53.211949501505273],
            [-1.988550092148192, 53.211192773505893],
            [-1.989004112229554, 53.209906511636405],
            [-1.990413150816325, 53.209586627299039],
            [-1.990804222783075, 53.208255409045307],
            [-1.992913961903565, 53.207564306068299],
            [-1.993035416372738, 53.206417335739239],
            [-1.993778181501739, 53.205267701093653],
            [-1.992802115384125, 53.204803823496022],
            [-1.994122668443202, 53.204175572164552],
            [-1.994095802952157, 53.203547249970683],
            [-1.994538973103353, 53.203283896669447],
            [-1.994274324299866, 53.200654644069083],
            [-1.994683113885382, 53.199703640715327],
            [-1.99644510621919, 53.198705938785473],
            [-1.996778999037983, 53.197549080854877],
            [-1.998759383029908, 53.197022366098594],
            [-1.998808800925639, 53.196028197961823],
            [-2.000338538037595, 53.194605262344396],
            [-2.000081086170254, 53.19383491728744],
            [-2.001669102515201, 53.19303669043618],
            [-2.004990372611027, 53.193384459577622],
            [-2.005409380274914, 53.192792972667746],
            [-2.007013784494414, 53.1923596218735],
            [-2.009167599930516, 53.192657901408147],
            [-2.009110769280955, 53.192861054384565],
            [-2.010077623063713, 53.192756703491575],
            [-2.011158014469432, 53.191871200937435],
            [-2.016386423105838, 53.189293474687574],
            [-2.018182222699453, 53.189034330560894],
            [-2.021325437020428, 53.189809542548701],
            [-2.023860146465796, 53.19149896477542],
            [-2.028557925653586, 53.193335246531092],
            [-2.03156628342272, 53.193232906655105],
            [-2.033688962907501, 53.193634123244699],
            [-2.0344878240084, 53.193139505045373],
            [-2.036369187828152, 53.193114671548983],
            [-2.038339355151906, 53.19364529183467],
            [-2.039319519308623, 53.193424742929487],
            [-2.04044118404819, 53.192487724615845],
            [-2.042777891410956, 53.192840166134452],
            [-2.04385673898191, 53.192562914287244],
            [-2.046420706132998, 53.192663511584975],
            [-2.047560626368258, 53.192148896000553],
            [-2.048283657258398, 53.190937802275826],
            [-2.049583550955478, 53.190338606456642],
            [-2.052206978496078, 53.187753177649647],
            [-2.054988890447928, 53.186469206759263],
            [-2.054820259240992, 53.185670173174508],
            [-2.053414559586062, 53.185255528347589],
            [-2.052292110029744, 53.183940954911051],
            [-2.054254355376175, 53.18312118563356],
            [-2.056332449913777, 53.182837066659573],
            [-2.057022119769167, 53.181597169319375],
            [-2.059217735457495, 53.180882375514045],
            [-2.059599491386738, 53.179950935158871],
            [-2.058414028935531, 53.179644104793645],
            [-2.058228684788354, 53.17868508102331],
            [-2.056865744324722, 53.177648423929625],
            [-2.058244894568819, 53.176303015677945],
            [-2.063262290808665, 53.174844244298107],
            [-2.067482859599354, 53.174907537957871],
            [-2.069320181934773, 53.174057926730036],
            [-2.070404747687768, 53.173099071320465],
            [-2.069810235980138, 53.172738068587016],
            [-2.06914326566077, 53.172894865958391],
            [-2.069409799579751, 53.172120765747223],
            [-2.070178018910281, 53.171679857635915],
            [-2.071578655387125, 53.171919024966968],
            [-2.075580675462917, 53.171213611862754],
            [-2.078052066531717, 53.170424584544598],
            [-2.079535683070038, 53.171041137887379],
            [-2.081372701247563, 53.17103180316402],
            [-2.08209806227398, 53.170938718138665],
            [-2.082934214929533, 53.170121045402205],
            [-2.085567361343562, 53.170280078787222],
            [-2.087238908400828, 53.169835709515958],
            [-2.0879371984624, 53.170417675834457],
            [-2.08914511386208, 53.170752061653452],
            [-2.090704115439876, 53.172272703293359],
            [-2.092731057256869, 53.172900361643144],
            [-2.093333198168233, 53.172552918347407],
            [-2.094612348196951, 53.172580670990421],
            [-2.095771605790794, 53.171172978234999],
            [-2.096908470097811, 53.171143291824912],
            [-2.09767529326348, 53.171534580233704],
            [-2.098627051227698, 53.170371528140677],
            [-2.100382848404536, 53.169548471314137],
            [-2.1017380656191, 53.168886633660314],
            [-2.103752343172054, 53.169844008702803],
            [-2.108741907518376, 53.168342893741055],
            [-2.109942419040353, 53.168666288913961],
            [-2.110198566198656, 53.169386063523895],
            [-2.110777536266221, 53.169407997534357],
            [-2.111789668408476, 53.168606137719387],
            [-2.115365709571583, 53.169498914869706],
            [-2.116036650703315, 53.170892441794969],
            [-2.115852092148801, 53.171240492624342],
            [-2.113925607199681, 53.171923714072463],
            [-2.114414896860431, 53.172522804201378],
            [-2.115098776384625, 53.172598549624105],
            [-2.11632471844117, 53.17175599437936],
            [-2.117870148534046, 53.171785034175493],
            [-2.11930775689882, 53.172849684375201],
            [-2.119410713828453, 53.173828472888609],
            [-2.118855290809899, 53.174229035828048],
            [-2.119769187367941, 53.174692844897379],
            [-2.121650377903526, 53.174380817430176],
            [-2.123513397703304, 53.173482702436928],
            [-2.124488465442993, 53.173361234005007],
            [-2.124983894921058, 53.17395757755019],
            [-2.124616465236851, 53.174679772370922],
            [-2.125250548008342, 53.175104281192873],
            [-2.127871739827199, 53.174610696568742],
            [-2.129349508187338, 53.175483718384733],
            [-2.13003394391453, 53.176706361494105],
            [-2.129856543320758, 53.178401862094006],
            [-2.133465615500842, 53.182797031666652],
            [-2.134609009082818, 53.18332788526898],
            [-2.138221949252467, 53.183998806395543],
            [-2.140166886253007, 53.183877876472046],
            [-2.140732983879305, 53.183560798867305],
            [-2.142175577668347, 53.182226928839825],
            [-2.142272185068776, 53.18112657243892],
            [-2.141362509838436, 53.179788311514365],
            [-2.141601912474691, 53.179334986170822],
            [-2.142386639831366, 53.179093146650665],
            [-2.142648589557213, 53.177775059843469],
            [-2.144298950648398, 53.176902942906501],
            [-2.144456734265392, 53.176217796861266],
            [-2.143521971867217, 53.175419815057673],
            [-2.144509936046075, 53.174688719372519],
            [-2.144867324263973, 53.173749842579078],
            [-2.144462641520066, 53.173527409965075],
            [-2.144497099940753, 53.171319691743975],
            [-2.141411918161904, 53.156634602180908],
            [-2.145423502943415, 53.157303034200446],
            [-2.14705160714151, 53.158474986305706],
            [-2.148634816573467, 53.158316610297305],
            [-2.151011352587309, 53.159638584249151],
            [-2.152157339682386, 53.159747687176079],
            [-2.154757841041742, 53.159252636605807],
            [-2.155645964239319, 53.159601146864539],
            [-2.155076785783576, 53.157704326886396],
            [-2.155655671634122, 53.156501749940098],
            [-2.158612357017301, 53.154118470986582],
            [-2.159543891713749, 53.152888436581762],
            [-2.163861034978622, 53.151354445535567],
            [-2.168059166909941, 53.148142228962229],
            [-2.16928986690524, 53.148188124700901],
            [-2.171669957769556, 53.14738649371953],
            [-2.172409532992325, 53.148002964703551],
            [-2.173281029785118, 53.147587307352808],
            [-2.174368091748088, 53.146504347408055],
            [-2.17658126299669, 53.145513195618996],
            [-2.180094209050291, 53.142545166994282],
            [-2.186534371325432, 53.135959811094075],
            [-2.193510127000708, 53.129603345797022],
            [-2.202645865401123, 53.121614895461775],
            [-2.211315340540571, 53.115807293470993],
            [-2.21290864280395, 53.115033194657087],
            [-2.212860129027914, 53.114286295003382],
            [-2.216500579056601, 53.111532681013294],
            [-2.217153860340355, 53.110733265398025],
            [-2.217043999444476, 53.109980186560165],
            [-2.218724094863432, 53.109004496475279],
            [-2.222430469764774, 53.107605222169575],
            [-2.221920041729087, 53.10682053552231],
            [-2.222867435890883, 53.106025931915617],
            [-2.223154823507357, 53.104988059675634],
            [-2.222076189163762, 53.10465838411811],
            [-2.223073873604659, 53.103778288858223],
            [-2.224696453218969, 53.103588261981749],
            [-2.227015814570702, 53.104111512304236],
            [-2.230353654132124, 53.103750026422787],
            [-2.23195277733287, 53.1030934155119],
            [-2.233999580620816, 53.102923108243587],
            [-2.237951634632933, 53.101030265739539],
            [-2.237769396093007, 53.100754666981764],
            [-2.238921205561184, 53.100001773976835],
            [-2.238995155190355, 53.098255954697969],
            [-2.240519324885683, 53.096538671862845],
            [-2.242570780795592, 53.095134011967254],
            [-2.243460828663461, 53.093817095882066],
            [-2.246377936958004, 53.091594388377651],
            [-2.246580323412223, 53.090169204527818],
            [-2.247605561702651, 53.08981200644034],
            [-2.249672733154443, 53.090699397562162],
            [-2.249088241823267, 53.091618405376856],
            [-2.249736763067884, 53.092214816760844],
            [-2.249844727515752, 53.092807867085675],
            [-2.24942607215617, 53.09297144777193],
            [-2.249853217949714, 53.093242020199334],
            [-2.250915550109807, 53.092836175024694],
            [-2.250675017079528, 53.093072195381254],
            [-2.253248636313258, 53.093960253180427],
            [-2.253284880616148, 53.093267120503199],
            [-2.254796513805629, 53.091575747683564],
            [-2.257125465914026, 53.089999448174467],
            [-2.258101739669833, 53.090207676887694],
            [-2.260977665828676, 53.088992371833719],
            [-2.266195613282792, 53.088187970265807],
            [-2.267037357425678, 53.08813035074872],
            [-2.267976325580312, 53.088581286855955],
            [-2.268732582807733, 53.088461823488394],
            [-2.269367314477909, 53.087532716516534],
            [-2.271112038497666, 53.086714342532495],
            [-2.277105751522231, 53.085888813577206],
            [-2.276268068902757, 53.084940618739836],
            [-2.2754700565337, 53.084821117776002],
            [-2.274555153952129, 53.083928821844573],
            [-2.275288914799072, 53.082172459471387],
            [-2.278496335048342, 53.080896632648447],
            [-2.281690086293667, 53.080299426309033],
            [-2.285025669013293, 53.079717974551166],
            [-2.288486134003773, 53.079742888812696],
            [-2.290656862036364, 53.078887235918643],
            [-2.291949983917679, 53.078944294781337],
            [-2.293375738423898, 53.078309752374857],
            [-2.294460756388986, 53.078279204248751],
            [-2.295442080389753, 53.079236803565827],
            [-2.29526319617147, 53.079709174413601],
            [-2.295766393395986, 53.080380306738384],
            [-2.296737775060058, 53.080966671070193],
            [-2.299803920004234, 53.081375174319355],
            [-2.301468707308297, 53.081198375153257],
            [-2.301892619643448, 53.082043171642916],
            [-2.303396317136532, 53.082944544388482],
            [-2.306250732894759, 53.082521033402294],
            [-2.309646509617018, 53.082443922682131],
            [-2.314958270914751, 53.081021384462467],
            [-2.316727853828347, 53.081098485098963],
            [-2.31775089302848, 53.081360933759626],
            [-2.318419989073379, 53.081199138438834],
            [-2.322407170395098, 53.078974384525068],
            [-2.322905173864585, 53.078099294278793],
            [-2.323709295242417, 53.077639563581883],
            [-2.329009212883921, 53.076563406950349],
            [-2.329500855412539, 53.075267616703989],
            [-2.328824145634492, 53.074021803918853],
            [-2.32996088514325, 53.073160196656957],
            [-2.33051479020904, 53.070843966062377],
            [-2.33179909164688, 53.069584612165521],
            [-2.335874296473814, 53.067390616799692],
            [-2.336252502541407, 53.066323439959113],
            [-2.338847526452361, 53.064404988726984],
            [-2.339094363830382, 53.063153899702939],
            [-2.341447848477393, 53.059979400478284],
            [-2.344481202364218, 53.059030388966363],
            [-2.346552017267397, 53.05696226672881],
            [-2.348594219096296, 53.05580928442776],
            [-2.349041423903686, 53.056314959082279],
            [-2.350083115901267, 53.05653392784609],
            [-2.350000766462053, 53.057050146031884],
            [-2.351645294412302, 53.05727361740967],
            [-2.351649899164382, 53.057655641555094],
            [-2.3527273419412, 53.057861896665152],
            [-2.352790843161955, 53.058329142672406],
            [-2.353734964936593, 53.058832425212501],
            [-2.354471190499345, 53.058727756635641],
            [-2.354644576631685, 53.058401833352548],
            [-2.35667956212118, 53.058198884387259],
            [-2.3575786475945, 53.054702110243433],
            [-2.359428822615147, 53.054723498900373],
            [-2.361500279398009, 53.054088878543091],
            [-2.36171894991548, 53.055080613542238],
            [-2.366600450211537, 53.054536202750228],
            [-2.370292202786049, 53.054794436684347],
            [-2.370747769348339, 53.054334571223386],
            [-2.372298318532628, 53.054558949170939],
            [-2.373618441121599, 53.054187152687746],
            [-2.375059490808907, 53.054514314837071],
            [-2.376200649699001, 53.052954690202739],
            [-2.377173049389078, 53.052920147535403],
            [-2.377461079635267, 53.052590231037584],
            [-2.381248426969118, 53.05253499940229],
            [-2.382192393860934, 53.051144045494809],
            [-2.380727632081485, 53.049643962416567],
            [-2.382270746070235, 53.049210228859344],
            [-2.383277275494271, 53.048493250257089],
            [-2.38512327433889, 53.048074683201598],
            [-2.383579444733219, 53.047412679206133],
            [-2.382158809564809, 53.045833366021341],
            [-2.383528481058436, 53.045537707053313],
            [-2.382475529766533, 53.044205311166237],
            [-2.382535313916328, 53.042872027350143],
            [-2.383227687448923, 53.041729972612153],
            [-2.38445922758785, 53.041681949536368],
            [-2.383866736153323, 53.040801128815609],
            [-2.384551099255208, 53.038432071277924],
            [-2.383844125141647, 53.036578992771567],
            [-2.384286891601368, 53.033879913424158],
            [-2.386170307558078, 53.033547503271791],
            [-2.3841609894048, 53.031795731776967],
            [-2.382636091356023, 53.031520187773566],
            [-2.381307866966082, 53.030549130511822],
            [-2.379784657566773, 53.027574994263887],
            [-2.384227098118354, 53.026151214444972],
            [-2.381291531422923, 53.024136303523171],
            [-2.381439225719497, 53.0238095223242],
            [-2.377518733191208, 53.019940508607789],
            [-2.370467646713371, 53.014559293087757],
            [-2.373094597076127, 53.013247642299206],
            [-2.373300095718646, 53.011672089531423],
            [-2.374705113170964, 53.01058536837207],
            [-2.376357315779853, 53.010166644492223],
            [-2.378323453736755, 53.010371648268787],
            [-2.379283759879657, 53.009577537583603],
            [-2.380780806867467, 53.009150257919103],
            [-2.380703235659279, 53.008803523101484],
            [-2.3811818070285, 53.008822664294215],
            [-2.380997422887609, 53.008532005263064],
            [-2.381728840704335, 53.008324704323933],
            [-2.38168989960806, 53.007965261118024],
            [-2.382276827244673, 53.008104505220686],
            [-2.383044365787592, 53.007770332136872],
            [-2.382951432065167, 53.007205210428921],
            [-2.381790711267578, 53.00689252478557],
            [-2.381808729207378, 53.00623265893465],
            [-2.38080549829744, 53.005185039092567],
            [-2.38097717176615, 53.004034768651671],
            [-2.380513202105902, 53.003806131278424],
            [-2.380666329681477, 53.00274940823784],
            [-2.380147801079601, 53.002075978003056],
            [-2.380512205628178, 53.00199930234956],
            [-2.380502539375706, 53.001408741786442],
            [-2.379991977202008, 53.000791917308746],
            [-2.380804930323947, 52.999705215264342],
            [-2.380244345631013, 52.998989670577693],
            [-2.380793391616861, 52.998394623898612],
            [-2.380949133348922, 52.998145123294968],
            [-2.382740787789489, 52.997886769074746],
            [-2.384249054463139, 52.997933140241187],
            [-2.385524932314005, 52.996647144444644],
            [-2.389081424879593, 52.996152831574598],
            [-2.38921111522877, 52.995497991091533],
            [-2.389734456902616, 52.995375820175887],
            [-2.391196915909862, 52.996461408127601],
            [-2.397484217399164, 52.994981592206912],
            [-2.400801945425175, 52.995439686389616],
            [-2.402696183669014, 52.995022474517832],
            [-2.40332849188699, 52.995086850443265],
            [-2.404088833992702, 52.995938244485572],
            [-2.404800273842511, 52.995700305283428],
            [-2.406986351884266, 52.995900489824535],
            [-2.407541171316923, 52.995645092018471],
            [-2.407253665334028, 52.995175041806675],
            [-2.405056074933137, 52.994218906838782],
            [-2.405069180644394, 52.99307273460029],
            [-2.405580322219868, 52.992451632698128],
            [-2.404474735323999, 52.99163738033986],
            [-2.405226533171056, 52.99078533585736],
            [-2.408321346250739, 52.989748170372437],
            [-2.408309390529637, 52.989112671961138],
            [-2.409381785055585, 52.989094599440406],
            [-2.410861692835764, 52.987909203797962],
            [-2.412229371523321, 52.988242462651407],
            [-2.416201662397831, 52.988180972867809],
            [-2.417537952214959, 52.987880538676123],
            [-2.418624246056664, 52.988070884468186],
            [-2.418888770125977, 52.988467277047228],
            [-2.420552297133586, 52.987811478550519],
            [-2.421021079611999, 52.987152704352226],
            [-2.423786630486069, 52.986325750501194],
            [-2.42547487430764, 52.986381743398077],
            [-2.428882868714561, 52.985763616106048],
            [-2.430005212759705, 52.986134411889282],
            [-2.431638408352035, 52.986047587300256],
            [-2.432357467314918, 52.987050870827133],
            [-2.433597125762447, 52.986635544950268],
            [-2.435367336194386, 52.986692896154082],
            [-2.438168132750063, 52.98555175300281],
            [-2.438490645695992, 52.984598600764343],
            [-2.435684018640782, 52.982934225269815],
            [-2.436680816764575, 52.982082878763407],
            [-2.436354673621998, 52.98149617905343],
            [-2.434989350127047, 52.980801820326981],
            [-2.434090068283717, 52.979644594898922],
            [-2.434223697368769, 52.978861141497028],
            [-2.436029983702267, 52.977800085536153],
            [-2.435919880545617, 52.977514630751877],
            [-2.434531015172578, 52.977132280554422],
            [-2.434208931628472, 52.976201270642115],
            [-2.434920103691696, 52.975392331858643],
            [-2.435660710502655, 52.975143312508528],
            [-2.436275804713466, 52.973375561942326],
            [-2.435393659082922, 52.973021923574883],
            [-2.434468936820263, 52.971688603106529],
            [-2.434901892746876, 52.970317951819389],
            [-2.434613800376749, 52.96950547609196],
            [-2.438755305984423, 52.96927001650279],
            [-2.440392046256969, 52.969888716053745],
            [-2.441822583782835, 52.969994872009117],
            [-2.44295722219187, 52.970723273773686],
            [-2.446689984541091, 52.968098809962918],
            [-2.449227255795629, 52.967103118378475],
            [-2.450256913803245, 52.966035787502996],
            [-2.451171136294111, 52.966183339363504],
            [-2.452875017951563, 52.965385798841091],
            [-2.459813496029894, 52.964580674674316],
            [-2.460168563322843, 52.963948251206389],
            [-2.460899737257573, 52.963824061380748],
            [-2.461955519792701, 52.963704897818552],
            [-2.463304593857921, 52.964142817009353],
            [-2.463857313853345, 52.964038182291233],
            [-2.470432367305413, 52.961474648879005],
            [-2.471418116796499, 52.961494113693171],
            [-2.472550783627665, 52.962153024655457],
            [-2.473289639228617, 52.962056594765109],
            [-2.473593429342348, 52.96233674943494],
            [-2.475083689128551, 52.962473729466382],
            [-2.475950119424816, 52.961929105343508],
            [-2.477827830393997, 52.961695936534667],
            [-2.477676296019103, 52.960779636389823],
            [-2.47834226206304, 52.960287039472121],
            [-2.480080401871799, 52.95996000897533],
            [-2.481152788564515, 52.958802341820054],
            [-2.484055437067382, 52.960117373321751],
            [-2.48467840070917, 52.961243891116325],
            [-2.486875262659664, 52.962922202203423],
            [-2.48771575141943, 52.965110348287823],
            [-2.490787848050467, 52.965031176828766],
            [-2.490678444525089, 52.966282041301916],
            [-2.491700677078998, 52.967668460774313],
            [-2.495677911237663, 52.968472658443041],
            [-2.499023008807423, 52.968419103296497],
            [-2.500813974383264, 52.967237564270398],
            [-2.506538719950086, 52.965821750750159],
            [-2.506897139375868, 52.964897019668832],
            [-2.508066783776521, 52.965735221452334],
            [-2.509415144633442, 52.965940696877794],
            [-2.509918682245638, 52.966471603180523],
            [-2.5110978212806, 52.966844087969733],
            [-2.511231949055777, 52.967863797853532],
            [-2.510744484080583, 52.968067254830643],
            [-2.511637253722671, 52.96902437112535],
            [-2.511768499497364, 52.969924534675087],
            [-2.51305921070872, 52.971158594001196],
            [-2.514315949370927, 52.971908262650828],
            [-2.517014540602199, 52.972312756206385],
            [-2.51786291353205, 52.97290055817318],
            [-2.519621452892062, 52.973263240447267],
            [-2.519846657505518, 52.973788129815972],
            [-2.521319468013653, 52.97428597735815],
            [-2.522197289082627, 52.973726583752509],
            [-2.521549327840193, 52.972969833119762],
            [-2.522296337875944, 52.972678894092759],
            [-2.521870017205367, 52.972395806167711],
            [-2.522567074912944, 52.972038563879096],
            [-2.522352030736215, 52.971123498726392],
            [-2.52298310992774, 52.971100047353879],
            [-2.523229722387184, 52.970313295815075],
            [-2.52249061028235, 52.970020799833556],
            [-2.523172398805284, 52.969635754042564],
            [-2.522690695172789, 52.969449997389276],
            [-2.522315197267107, 52.968193144631215],
            [-2.52337090385078, 52.967826229722213],
            [-2.52279140643247, 52.967308299473501],
            [-2.52398621543132, 52.966011271347874],
            [-2.523714598368731, 52.964973303522441],
            [-2.524468053505953, 52.964735358755654],
            [-2.523335596569602, 52.964288189374962],
            [-2.523824294648425, 52.96370262881485],
            [-2.523057814180505, 52.963107316718968],
            [-2.523048229468314, 52.961573780195629],
            [-2.522606837381412, 52.961143335988481],
            [-2.523495198047356, 52.96086345273423],
            [-2.52290190053343, 52.9600579249189],
            [-2.523531677332067, 52.960063241866749],
            [-2.523790704061657, 52.959693537722572],
            [-2.523252344611801, 52.959738159812737],
            [-2.523458685629684, 52.959196992214196],
            [-2.522763789878514, 52.958852164807162],
            [-2.523258538596065, 52.957416188588077],
            [-2.523881941445846, 52.957143761317091],
            [-2.523589056261515, 52.956188587422453],
            [-2.524267327557331, 52.955900634340729],
            [-2.52328092815327, 52.954339939843251],
            [-2.524349185108242, 52.953931608505371],
            [-2.524546872978609, 52.953539699618567],
            [-2.525356675534767, 52.953669163516103],
            [-2.526471952906818, 52.952478532472007],
            [-2.527287314228486, 52.952209730774818],
            [-2.527481619109682, 52.951787268080821],
            [-2.526865148517526, 52.951152659317792],
            [-2.529302536034183, 52.949399689584524],
            [-2.529516151578999, 52.947149602739884],
            [-2.532034248448721, 52.947270495267887],
            [-2.535060805146308, 52.948071337160762],
            [-2.539150762298642, 52.951914669895814],
            [-2.541015498787304, 52.952730515641406],
            [-2.546355488538408, 52.956822298792424],
            [-2.547946613198155, 52.957045098240187],
            [-2.549257450107751, 52.956645312135123],
            [-2.550473663221759, 52.956649571515477],
            [-2.553697718912921, 52.957450822155621],
            [-2.553929717968302, 52.957899207903971],
            [-2.555873234068152, 52.959012000714246],
            [-2.55761528905264, 52.962160894007745],
            [-2.559110594655261, 52.962206903996893],
            [-2.560616293427723, 52.963630911751245],
            [-2.561262978542703, 52.964936706160778],
            [-2.561670670109259, 52.964228217788104],
            [-2.56435619460269, 52.963517919808133],
            [-2.566512954531361, 52.963933750764106],
            [-2.568117127584211, 52.963080194372978],
            [-2.568031488050212, 52.962453147752676],
            [-2.568843660492254, 52.961946757877698],
            [-2.571256390490794, 52.962132048766897],
            [-2.572102456166913, 52.962842630798505],
            [-2.572398419801188, 52.962481631601904],
            [-2.572870546965634, 52.962833536348072],
            [-2.573492592681846, 52.962701088987664],
            [-2.573797047136465, 52.962307683648419],
            [-2.573246730342405, 52.9614473628382],
            [-2.57152094980207, 52.960744619415522],
            [-2.570292774267414, 52.959727533195945],
            [-2.57180130289253, 52.959201597373273],
            [-2.573089288820219, 52.959224157688311],
            [-2.573564920855274, 52.958493725161276],
            [-2.574933620380199, 52.958318110044736],
            [-2.574891206902134, 52.957365443926946],
            [-2.576658515200311, 52.956161295066899],
            [-2.578144070640379, 52.955713596920958],
            [-2.583624845352001, 52.956264815871492],
            [-2.584429768669126, 52.955574072086129],
            [-2.586203358367116, 52.955299249345593],
            [-2.588478696323291, 52.957007665747355],
            [-2.58828811202063, 52.957552465794727],
            [-2.58911116846037, 52.958636998906087],
            [-2.589074913302929, 52.958926635964652],
            [-2.590653074607036, 52.959268486977066],
            [-2.591327940076583, 52.96039599118825],
            [-2.592649109760526, 52.960882025254655],
            [-2.59491773652961, 52.960977661114917],
            [-2.595882132239167, 52.962146838317658],
            [-2.597693686604414, 52.96299082919829],
            [-2.595802043973924, 52.965524539678405],
            [-2.596332297813589, 52.966513404911787],
            [-2.596027876495057, 52.967653882667037],
            [-2.597033475647611, 52.968558556157319],
            [-2.596890721099246, 52.969544505563761],
            [-2.598446788249333, 52.971703110700084],
            [-2.596623045852303, 52.973460707999806],
            [-2.592756288505898, 52.974154259387596],
            [-2.595399946965761, 52.975914638783451],
            [-2.598894303022069, 52.975097927480093],
            [-2.599629932503468, 52.975417833922137],
            [-2.599156051324151, 52.976686821549357],
            [-2.596702937592435, 52.977853394922548],
            [-2.594889032639454, 52.97959203611363],
            [-2.59592540170552, 52.97968392842246],
            [-2.598109009028143, 52.979051795783136],
            [-2.600625142571725, 52.978775718082126],
            [-2.604205197875011, 52.978967920418135],
            [-2.605679452949262, 52.97938021766867],
            [-2.603699346236513, 52.98178235400939],
            [-2.604141982157163, 52.983082658320619],
            [-2.603774568603467, 52.983544779037175],
            [-2.604786316457374, 52.98410506327717],
            [-2.605466008351625, 52.985416740759185],
            [-2.607142241193079, 52.986725125301781],
            [-2.606699846730276, 52.987257754511802],
            [-2.607565922128573, 52.987725269614593],
            [-2.607734049031608, 52.988347369994798],
            [-2.614984691325159, 52.987750937012699],
            [-2.618967869810947, 52.986596705162725],
            [-2.621940017402051, 52.986727715023839],
            [-2.625755919936811, 52.989168064648631],
            [-2.627591772853523, 52.990952654506387],
            [-2.627633786919979, 52.992000588006583],
            [-2.628236898827466, 52.99249630917619],
            [-2.628028172914544, 52.995154655889635],
            [-2.63092303257231, 52.995869257679047],
            [-2.632650933235927, 52.996656527068097],
            [-2.634068352476277, 52.99597747197533],
            [-2.635074521453344, 52.996720011829673],
            [-2.636371960114845, 52.996496429914863],
            [-2.637371106731753, 52.994837040800398],
            [-2.642805221705943, 52.993466568932526],
            [-2.64452944263482, 52.994998901561239],
            [-2.648611001651196, 52.99524727774299],
            [-2.648713409065242, 52.995717759744352],
            [-2.651680597957204, 52.994878105839376],
            [-2.652357060870225, 52.994980469168034],
            [-2.654419117867764, 52.993308803781382],
            [-2.655427922125928, 52.993124357378036],
            [-2.657685680285571, 52.991599879095936],
            [-2.658185697703259, 52.991661834483949],
            [-2.658298750275899, 52.992042356394307],
            [-2.660807253665414, 52.991436937789601],
            [-2.664379595443546, 52.989002484865445],
            [-2.665891079319414, 52.988869973251425],
            [-2.668101806200486, 52.988196849689743],
            [-2.668118381802864, 52.987726613480618],
            [-2.671575205915762, 52.987109339073442],
            [-2.674662777412872, 52.98564906096783],
            [-2.676186213659626, 52.986481807189016],
            [-2.679675946500103, 52.986550896393346],
            [-2.680903689912464, 52.986097097822373],
            [-2.683735771106336, 52.986403557077303],
            [-2.684569875621593, 52.986116489738393],
            [-2.684668239483312, 52.986590561414921],
            [-2.685556508296846, 52.986431722994695],
            [-2.687079032648221, 52.986816665167893],
            [-2.686443035888069, 52.988229871847778],
            [-2.688775971965934, 52.98992255550619],
            [-2.691838985995208, 52.990490872043516],
            [-2.694415612381102, 52.993329967413814],
            [-2.694469910630078, 52.993928341255213],
            [-2.69669718028555, 52.994849301218778],
            [-2.699292930578085, 52.995424658944046],
            [-2.696097888965099, 53.00075432071948],
            [-2.69372051494515, 53.002756661427824],
            [-2.690342226091956, 53.007793276991229],
            [-2.688555281397373, 53.012471798477165],
            [-2.680133586609834, 53.015195498350046],
            [-2.678320885954803, 53.014996403711287],
            [-2.682542814392248, 53.020227392591494],
            [-2.685018428308912, 53.021698188693186],
            [-2.683189026697809, 53.023479608157828],
            [-2.682480702836117, 53.024886007472169],
            [-2.679618987007064, 53.02560446375864],
            [-2.679321352466713, 53.025924385847084],
            [-2.679275726348202, 53.026721098376669],
            [-2.678374045161907, 53.027979354195288],
            [-2.678960152594173, 53.030929890744751],
            [-2.678279324285005, 53.031444368101766],
            [-2.678806472413676, 53.03196813231753],
            [-2.678251571028917, 53.03233626395442],
            [-2.678770853792956, 53.032643431282011],
            [-2.67721331582651, 53.03326268475346],
            [-2.672314372002407, 53.034009627365784],
            [-2.671447929593504, 53.036406570854886],
            [-2.668817483873891, 53.0386381504523],
            [-2.682260378667948, 53.04530643733677],
            [-2.689195957577998, 53.049294538701425],
            [-2.69140963356802, 53.050033192681397],
            [-2.693859617693152, 53.051825767291419],
            [-2.700141606404261, 53.053100486129793],
            [-2.702410989312941, 53.054305151023819],
            [-2.704894769539632, 53.053920997259091],
            [-2.70455530853581, 53.053140942092313],
            [-2.707237129736577, 53.052920968410959],
            [-2.709581464083582, 53.052229213385324],
            [-2.709228043857029, 53.050149404883228],
            [-2.710460950485031, 53.048195866894304],
            [-2.71620185386349, 53.047609490730103],
            [-2.718250008454894, 53.045548479814222],
            [-2.718263353208672, 53.044198207647511],
            [-2.719215125986562, 53.044833391953922],
            [-2.719121773036331, 53.04612481866949],
            [-2.722129110971617, 53.048988562054902],
            [-2.723263082390872, 53.048824358725206],
            [-2.726492388742471, 53.05110952950885],
            [-2.729005796397622, 53.051620023215719],
            [-2.730481796701091, 53.053505009512726],
            [-2.734699193103405, 53.055068337967157],
            [-2.734534098672287, 53.056589445592905],
            [-2.732423272491328, 53.057667692516233],
            [-2.734724572072556, 53.05866389420131],
            [-2.735925033912523, 53.059762154064558],
            [-2.735711145331461, 53.060169792631172],
            [-2.737554145278879, 53.060443338829621],
            [-2.737255973276702, 53.060982746214087],
            [-2.740168880657556, 53.061341306214857],
            [-2.739678334458932, 53.062408688256184],
            [-2.741879641479439, 53.063466500735373],
            [-2.74107076357365, 53.06450530965089],
            [-2.74325802066063, 53.065778926751385],
            [-2.752928573311195, 53.069210441719633],
            [-2.751263822999765, 53.071401764468455],
            [-2.749927036998883, 53.071689765551334],
            [-2.75110120890175, 53.074407004615807],
            [-2.750742143002776, 53.076503769607257],
            [-2.748650334343331, 53.077936356084201],
            [-2.748367810424029, 53.078516142978422],
            [-2.746746579212533, 53.078857135081819],
            [-2.745159306200378, 53.080646960041804],
            [-2.74546578038085, 53.082140851560027],
            [-2.744438003171323, 53.082275833420212],
            [-2.741779192293881, 53.081845675098457],
            [-2.734929102790916, 53.086537424128764],
            [-2.735614124610115, 53.088708586031053],
            [-2.732883879569364, 53.090067527645722],
            [-2.731738031443784, 53.091793321011885],
            [-2.730332614753292, 53.091512502889252],
            [-2.724921002374018, 53.092303389111109],
            [-2.722293757935433, 53.092178236306076],
            [-2.717579354754685, 53.09351199692567],
            [-2.713141734484591, 53.092998425273649],
            [-2.711363148162776, 53.093626631330224],
            [-2.711735953849194, 53.096399371845905],
            [-2.709057736682388, 53.098635696153231],
            [-2.708215228862418, 53.105532733556792],
            [-2.7064506982505, 53.106598553411388],
            [-2.707565301745916, 53.107797381895693],
            [-2.708421261450451, 53.110251731743794],
            [-2.709999816924373, 53.111764193715999],
            [-2.709698636588918, 53.111890939907546],
            [-2.710048043924236, 53.113421511795309],
            [-2.709204043171193, 53.114335350893747],
            [-2.70856691173068, 53.116201704287455],
            [-2.707108331517154, 53.117090419988138],
            [-2.706054072482331, 53.118493597101562],
            [-2.703671097455257, 53.117409233341164],
            [-2.699652904706387, 53.119771006931018],
            [-2.696628572825607, 53.119952350565889],
            [-2.692588604239853, 53.119139936768356],
            [-2.692404796502251, 53.118859645368609],
            [-2.690487434819564, 53.118652348199696],
            [-2.68747920991168, 53.117218911991209],
            [-2.683639017567921, 53.117105296972007],
            [-2.681978096528151, 53.116006462309223],
            [-2.678501887195516, 53.115943631576613],
            [-2.674437206773892, 53.115128946124194],
            [-2.673796808525235, 53.115928114492341],
            [-2.671145171480527, 53.115854105192504],
            [-2.671375113337413, 53.116420024204132],
            [-2.670041467779932, 53.117033409607714],
            [-2.670728117402269, 53.117951829249947],
            [-2.670092879439927, 53.118417450292149],
            [-2.668767715242796, 53.118812336820987],
            [-2.666940433001463, 53.120169165974481],
            [-2.663763866765394, 53.120566266914601],
            [-2.662436830618982, 53.121333243690053],
            [-2.660517328195745, 53.125076204678479],
            [-2.660638043507964, 53.127490017470059],
            [-2.659693371179582, 53.129332636478715],
            [-2.659730770502038, 53.1307005752479],
            [-2.658308830862068, 53.130759686267425],
            [-2.656243908334282, 53.130014204237469],
            [-2.652428614075967, 53.130330921109909],
            [-2.649962079425106, 53.129209088793296],
            [-2.644598892424592, 53.128655759801532],
            [-2.641263892616869, 53.12840499983561],
            [-2.641147674908333, 53.128930590887144],
            [-2.640429058178987, 53.129047722720863],
            [-2.640528131718527, 53.132090906553522],
            [-2.639620178915624, 53.133552026410108],
            [-2.640293760930974, 53.134120112771619],
            [-2.642178361522986, 53.134506380096553],
            [-2.643344267094648, 53.13520842665995],
            [-2.640788515953986, 53.139844414352368],
            [-2.64032812383425, 53.139846891823638],
            [-2.640207591619859, 53.140788699743112],
            [-2.638259256095653, 53.141867967026101],
            [-2.636591674748605, 53.14202341897861],
            [-2.634710423943522, 53.142694163659584],
            [-2.633085360640274, 53.143913649100263],
            [-2.632050096888112, 53.145494939152748],
            [-2.630919799709578, 53.146208376840171],
            [-2.630002788709169, 53.146276157059695],
            [-2.629068754464416, 53.148656913478732],
            [-2.62841836447888, 53.148658550707822],
            [-2.626859258232054, 53.150019628426435],
            [-2.626421840702051, 53.149964400743279],
            [-2.625284595884988, 53.150831533839636],
            [-2.623180232674251, 53.151416071636042],
            [-2.622793418370541, 53.151138535362868],
            [-2.622436604785904, 53.151384005617501],
            [-2.620851948307092, 53.151201711768024],
            [-2.620350859569729, 53.150256874628475],
            [-2.619152827432757, 53.150130073068539],
            [-2.615041755471861, 53.150296107601548],
            [-2.614307174762053, 53.150686430904599],
            [-2.614072929744033, 53.150408079032637],
            [-2.612749690027713, 53.150307927211081],
            [-2.611686905957379, 53.150542614270002],
            [-2.609373476419686, 53.149997155788569],
            [-2.608720107015446, 53.149155525034125],
            [-2.608201481010274, 53.149170760532634],
            [-2.607915989564959, 53.148754226566439],
            [-2.608272017582776, 53.148554648170858],
            [-2.607835209665951, 53.148539800039934],
            [-2.607686800450006, 53.147983234727995],
            [-2.605836948600733, 53.14668654989454],
            [-2.605624343799298, 53.145815690701241],
            [-2.604334287734189, 53.145086041003445],
            [-2.60347920155294, 53.145093075704786],
            [-2.603549796114367, 53.145436100774013],
            [-2.600217243708558, 53.146508268659368],
            [-2.598610127864604, 53.146196349817551],
            [-2.597765197761016, 53.145429332615741],
            [-2.597087432943808, 53.145394081076049],
            [-2.596824072903877, 53.144731108730618],
            [-2.596002303475401, 53.144876354550263],
            [-2.594340591314189, 53.14450172798572],
            [-2.592496037655323, 53.144961276115311],
            [-2.592090350469428, 53.144488671311926],
            [-2.591360354515802, 53.144785343992929],
            [-2.591392588215998, 53.145063845276297],
            [-2.59064529823661, 53.145081938036085],
            [-2.590784822497921, 53.145988243516392],
            [-2.589924704509147, 53.145847784975295],
            [-2.589768019823678, 53.1463061052985],
            [-2.588798280463485, 53.146567993313788],
            [-2.592252812597768, 53.149014761623228],
            [-2.593414275563851, 53.150834657992924],
            [-2.595792286600695, 53.152707788407433],
            [-2.597495528434073, 53.155187425975939],
            [-2.597127655557396, 53.155295342650604],
            [-2.596505587788089, 53.158856331892451],
            [-2.593547083235016, 53.158293121628908],
            [-2.591645070540517, 53.157228396339903],
            [-2.589012278185311, 53.15706706330981],
            [-2.587529396467443, 53.157442941938662],
            [-2.586684209868008, 53.157316768596061],
            [-2.586185831324102, 53.156520995194434],
            [-2.583581574704828, 53.155482069671407],
            [-2.582856609380882, 53.157713109429871],
            [-2.580868513762955, 53.158004183418413],
            [-2.580345259442709, 53.159125872284086],
            [-2.580766526595058, 53.159311689219273],
            [-2.578830315798578, 53.160019567729591],
            [-2.576671341761862, 53.161965386570607],
            [-2.574146455674857, 53.161389705777971],
            [-2.572403125206808, 53.163456590991423],
            [-2.568108543067311, 53.162408363632196],
            [-2.566760364742312, 53.162591873175316],
            [-2.566573544805652, 53.162261965660583],
            [-2.565000187336606, 53.162041120932336],
            [-2.56182093227309, 53.162653937042926],
            [-2.559680841868237, 53.16186758787687],
            [-2.557059872943471, 53.160322079690623],
            [-2.558499734010016, 53.15959980619926],
            [-2.555963116928112, 53.157971178726193],
            [-2.553846320591157, 53.157109108059274],
            [-2.55356327891977, 53.155567904495491],
            [-2.551940500102833, 53.154501248238972],
            [-2.550745046441659, 53.153167416760731],
            [-2.548012835873644, 53.15139120499861],
            [-2.542251652956925, 53.149744725280577],
            [-2.532755749032448, 53.152186778011583],
            [-2.528603155944722, 53.152422824621055],
            [-2.526865993492719, 53.152822447833486],
            [-2.523939192216099, 53.154384176374968],
            [-2.522836708281145, 53.155337366592065],
            [-2.522607870257577, 53.157672822810817],
            [-2.522999620337068, 53.159142606396877],
            [-2.521392929056286, 53.15959820633126],
            [-2.520566531270418, 53.160393755860355],
            [-2.519988391388856, 53.160077172069059],
            [-2.51818357602769, 53.160107513981508],
            [-2.516891214230243, 53.159471315818443],
            [-2.516819223194127, 53.158956557245389],
            [-2.514118577093551, 53.158858584621541],
            [-2.513369480952862, 53.157996174200029],
            [-2.512417022554365, 53.158011963405919],
            [-2.512040539885092, 53.158295838674469],
            [-2.512639390267784, 53.158474839732222],
            [-2.512366139014709, 53.158887713370774],
            [-2.510755700539563, 53.159414199040185],
            [-2.510102335631398, 53.159178791286919],
            [-2.508331749385639, 53.159445248568751],
            [-2.507556053177666, 53.158981129113499],
            [-2.506519225209658, 53.159200382656955],
            [-2.506280218862672, 53.158970381487528],
            [-2.505675189176056, 53.159663309736722],
            [-2.504026892648322, 53.160031658226004],
            [-2.503175148716471, 53.159711656598766],
            [-2.502418471003867, 53.159846990959544],
            [-2.50136445785919, 53.160648760341353],
            [-2.500836426124704, 53.161412352556511],
            [-2.50169611274358, 53.163693740148965],
            [-2.500942075823489, 53.163548596603761],
            [-2.500697076729717, 53.163959526169698],
            [-2.50028834525669, 53.163668202672277],
            [-2.499680583720121, 53.164139981445508],
            [-2.499100720553603, 53.163665996200827],
            [-2.498031734293233, 53.164221499194134],
            [-2.496452024086149, 53.164073486076902],
            [-2.496195484490259, 53.164266021467434],
            [-2.49486774792869, 53.163397816142016],
            [-2.493811995423764, 53.163546922983898],
            [-2.492569182340461, 53.162904862828817],
            [-2.49137958371004, 53.162989780969156],
            [-2.490568808843466, 53.163504599233086],
            [-2.489173826258893, 53.163549890896633],
            [-2.48703321482221, 53.163395966216875],
            [-2.485025852018667, 53.162721895698141],
            [-2.484539358638947, 53.162155771460611],
            [-2.4856301072142, 53.161265010761191],
            [-2.486181252155252, 53.160140932680093],
            [-2.483801968207608, 53.159494427925409],
            [-2.48033063904669, 53.15720819654706],
            [-2.477688901105985, 53.156471839462327],
            [-2.476352812340189, 53.155198955540577],
            [-2.474610119471665, 53.154609045765696],
            [-2.472059468473113, 53.154518510953189],
            [-2.469074766453974, 53.15274599016503],
            [-2.464301438215477, 53.153141344028263],
            [-2.461969435127144, 53.156551858890083],
            [-2.459106315173099, 53.156197083054288],
            [-2.457324595246489, 53.15641877867801],
            [-2.456466308794943, 53.156851747446588],
            [-2.456943176909585, 53.157256220961408],
            [-2.456299629640499, 53.159606617207935],
            [-2.455828554834844, 53.160456084715747],
            [-2.454660925309755, 53.160921685610589],
            [-2.452818491967549, 53.160937697090439],
            [-2.452312769524157, 53.160633993731842],
            [-2.451863654570245, 53.160877503378074],
            [-2.446470287359544, 53.159852432925547],
            [-2.443848533346993, 53.159852349153816],
            [-2.443724901233361, 53.160190796328912],
            [-2.443255154988311, 53.160178163974642],
            [-2.443269131558231, 53.160663517709835],
            [-2.444035501157099, 53.161013030193985],
            [-2.44451754603514, 53.160912352217366],
            [-2.444498726995801, 53.161262094405409],
            [-2.44363219793475, 53.16222265467303],
            [-2.442419192910776, 53.162511221067916],
            [-2.442446600418046, 53.162849105313683],
            [-2.443450870971572, 53.162913684911032],
            [-2.442194118771587, 53.163749841445821],
            [-2.442429323429621, 53.164658653326939],
            [-2.443093095689773, 53.164915966407825],
            [-2.442173299678619, 53.165216022944762],
            [-2.442573441531285, 53.166162874247085],
            [-2.442006536492622, 53.166163182612834],
            [-2.442359911037783, 53.167797865467151],
            [-2.441864765224983, 53.167941730210906],
            [-2.441985683613217, 53.168790740290596],
            [-2.443365756472334, 53.169454389039487],
            [-2.442828932173139, 53.169910330595826],
            [-2.443478596603224, 53.170822091710093],
            [-2.445194304559007, 53.171673237062379],
            [-2.445294420814601, 53.172095344674837],
            [-2.446439432600024, 53.171861837633394],
            [-2.446841548162154, 53.172263934278654],
            [-2.447887170514171, 53.172111687125707],
            [-2.448242595514808, 53.173055990117817],
            [-2.449779880926087, 53.172994460800922],
            [-2.450988209893714, 53.173805190227995],
            [-2.452011393391908, 53.173793220015803],
            [-2.453586053580136, 53.174294209804536],
            [-2.454817226371397, 53.175132679197191],
            [-2.455955073559223, 53.175067202123614],
            [-2.456351216459961, 53.175318274694909],
            [-2.45623160641874, 53.175889533153601],
            [-2.457334349143153, 53.17660082505973],
            [-2.456540569711639, 53.177651087004136],
            [-2.457301793445515, 53.178462566808562],
            [-2.456241386776485, 53.178783945572647],
            [-2.45657640022208, 53.181023611879617],
            [-2.456134378194238, 53.1812482324451],
            [-2.456788051239068, 53.181790458049001],
            [-2.456751861276355, 53.182749719717947],
            [-2.456116003723544, 53.183605210008224],
            [-2.456936836412491, 53.184384102875413],
            [-2.456775589462387, 53.184700234182536],
            [-2.457486968524263, 53.185034589305474],
            [-2.457576090467847, 53.186377197886088],
            [-2.456417251834762, 53.187312002760741],
            [-2.456785444189307, 53.188723655265449],
            [-2.456355981738137, 53.189856112675265],
            [-2.458355262211188, 53.191519480787285],
            [-2.458300634212208, 53.191870260242581],
            [-2.460463759001766, 53.192884858561207],
            [-2.460195931873532, 53.193591526616807],
            [-2.460907978555599, 53.193832373432819],
            [-2.460171088399985, 53.194206466883472],
            [-2.460361247780962, 53.194631808173803],
            [-2.459491695116292, 53.196178568982546],
            [-2.45839664356781, 53.196799430848799],
            [-2.458825143734957, 53.197957354667317],
            [-2.458516718246983, 53.198652489027992],
            [-2.459120841034375, 53.198732859828475],
            [-2.459332043674592, 53.199165312481469],
            [-2.46025758637398, 53.199065557421832],
            [-2.460397712427587, 53.199988180218],
            [-2.459754813766846, 53.201461254549628],
            [-2.458130449915582, 53.20145313187713],
            [-2.456932382797501, 53.201833472170982],
            [-2.456437719458747, 53.202618305462615],
            [-2.455621169965684, 53.20185197909791],
            [-2.4543899690318, 53.201650836276279],
            [-2.454583160239592, 53.200950759640179],
            [-2.452763709189635, 53.200611616514777],
            [-2.451680759494342, 53.200125831569942],
            [-2.451196053617768, 53.199443610655301],
            [-2.447939118891596, 53.199917038082383],
            [-2.445843234265787, 53.201203129633207],
            [-2.444513512131954, 53.201022928191058],
            [-2.443124636158895, 53.201347211262473],
            [-2.440607207668116, 53.200381252303039],
            [-2.440105337987927, 53.199760174850091],
            [-2.438657960617674, 53.20007203761336],
            [-2.437842716667205, 53.198223316974705],
            [-2.436711342195273, 53.197369027752735],
            [-2.435356129617874, 53.197759615250497],
            [-2.434518703065057, 53.196658832251067],
            [-2.430665252490495, 53.197949259315244],
            [-2.429428194713597, 53.196540665923685],
            [-2.428939233454079, 53.194943296131967],
            [-2.426384963792958, 53.195020785260482],
            [-2.425900624787777, 53.193725414630521],
            [-2.427033294031779, 53.192934825846585],
            [-2.421909926506564, 53.191100467941517],
            [-2.420706621473165, 53.190185156555202],
            [-2.417391803593067, 53.188822412645912],
            [-2.416323047778139, 53.187876022747894],
            [-2.41375428950672, 53.18392174946311],
            [-2.412702242954027, 53.183759105089408],
            [-2.413011073205279, 53.181950355975758],
            [-2.412642978242955, 53.180860373235099],
            [-2.410358664878158, 53.178097871921231],
            [-2.405563276144583, 53.17476591629709],
            [-2.406580502158082, 53.174120634769508],
            [-2.397943095213109, 53.1722819131788],
            [-2.399302751384401, 53.174512915382785],
            [-2.398759001226269, 53.174601930803703],
            [-2.398057430221682, 53.177196694070489],
            [-2.396828503585902, 53.177945980982422],
            [-2.396256223673636, 53.178840490180903],
            [-2.393291458676074, 53.179906522669413],
            [-2.392110826239583, 53.179898731254191],
            [-2.391252400102562, 53.178303319872697],
            [-2.390028739205146, 53.17800081165548],
            [-2.387001814021901, 53.175498277127048],
            [-2.384616066505395, 53.174558572778409],
            [-2.383177573233591, 53.173467457710316],
            [-2.381988528378698, 53.17333464680231],
            [-2.378202830335203, 53.172064908639904],
            [-2.376866576813571, 53.173051639353076],
            [-2.377814163307356, 53.173800112469578],
            [-2.374729140755879, 53.176529021329848],
            [-2.37366362960656, 53.178737359395377],
            [-2.37232139633329, 53.178724485646605],
            [-2.368693986870414, 53.183020803255054],
            [-2.369161722771385, 53.183469699656591],
            [-2.370162939774123, 53.183658055340523],
            [-2.370849730521492, 53.184517957152501],
            [-2.37361018950491, 53.185152934652947],
            [-2.373780024916428, 53.18540948517203],
            [-2.372823684790275, 53.186432727834479],
            [-2.376923744296949, 53.187245002774134],
            [-2.375630870509503, 53.189142157874791],
            [-2.373340671606535, 53.189439703499858],
            [-2.371153652504965, 53.190423637590015],
            [-2.372426127630095, 53.192360373531322],
            [-2.371910794877973, 53.192652326567163],
            [-2.372678669160059, 53.194211301715065],
            [-2.37256142900297, 53.195534837870007],
            [-2.376167369847091, 53.195717661526835],
            [-2.376384821154691, 53.195935405161705],
            [-2.377254295293881, 53.195743886286046],
            [-2.379538549694035, 53.196432369877108],
            [-2.381934596811004, 53.196217963448483],
            [-2.382304497441938, 53.196742628356439],
            [-2.382869755918728, 53.196512493584621],
            [-2.384426534704716, 53.196852652459185],
            [-2.384492925717637, 53.196577377081034],
            [-2.386751907142212, 53.196768716704462],
            [-2.387152212555027, 53.196175944709395],
            [-2.388049424271204, 53.195911446533515],
            [-2.389863519877456, 53.196069119327674],
            [-2.390522449835069, 53.198079580127022],
            [-2.391921148484113, 53.200111874356836],
            [-2.392376538920847, 53.202766599849994],
            [-2.392998538107426, 53.203171746662683],
            [-2.393470903198033, 53.204890664998423],
            [-2.395034421603492, 53.206083710841803],
            [-2.396031484607184, 53.206076803745496],
            [-2.396773530200857, 53.204882403949881],
            [-2.397696924001615, 53.204845165992516],
            [-2.398549650440153, 53.205259400879648],
            [-2.399534086856651, 53.203897877213457],
            [-2.400890565176363, 53.20326679108198],
            [-2.402973743672624, 53.203344256006197],
            [-2.403529916257857, 53.204220589951703],
            [-2.406400879476862, 53.204007677680181],
            [-2.407437617744833, 53.204716057526937],
            [-2.410162641767323, 53.205680203173578],
            [-2.408649927068951, 53.209080516130008],
            [-2.40883294180671, 53.211789145205152],
            [-2.409195380351012, 53.212738926810673],
            [-2.412323321596322, 53.212565439882212],
            [-2.414183556761015, 53.219283583244163],
            [-2.413644767974473, 53.219321411155299],
            [-2.413737175744933, 53.219896379597529],
            [-2.412901494383697, 53.221143342530944],
            [-2.411473285475509, 53.220738391285103],
            [-2.409726742987166, 53.22102846099942],
            [-2.407999788757459, 53.220860903337346],
            [-2.406826527243421, 53.221275708805663],
            [-2.403080917473157, 53.221135632000745],
            [-2.401174013370948, 53.22174252774554],
            [-2.399649467405075, 53.224009256843054],
            [-2.398957683464228, 53.224031351460084],
            [-2.397879719654517, 53.225211602057342],
            [-2.399541570067713, 53.225933240025348],
            [-2.399039865647731, 53.226747516730832],
            [-2.401505620699922, 53.227888908870874],
            [-2.40094164670243, 53.228289914758278],
            [-2.402823393147924, 53.229279533982876],
            [-2.402493373898773, 53.229709419027934],
            [-2.398860699747137, 53.228260046440163],
            [-2.396714803480319, 53.231087022986685],
            [-2.398827367045077, 53.232085823714051],
            [-2.396291005054364, 53.234346000645509],
            [-2.385741053294672, 53.230368897821663],
            [-2.380933493908653, 53.227797371717074],
            [-2.377648667221528, 53.22713546373096],
            [-2.367964691754838, 53.223303254167455],
            [-2.363801476609917, 53.223554248462094],
            [-2.35951063870114, 53.230565112095235],
            [-2.359132440893868, 53.233344710151307],
            [-2.353709745794945, 53.23595511996016],
            [-2.353389405929744, 53.236898102582956],
            [-2.352646597474865, 53.236938057124497],
            [-2.346354608224271, 53.240920600012238],
            [-2.350146202804493, 53.243750888943588],
            [-2.350751341130189, 53.245917216947888],
            [-2.349012830280539, 53.248657625651482],
            [-2.349027571783991, 53.248995562602033],
            [-2.349630658428277, 53.249071098168351],
            [-2.350958031763323, 53.248831687789981],
            [-2.351125157872873, 53.248377259197476],
            [-2.351728009646219, 53.248607392803784],
            [-2.353089373462085, 53.248313026389745],
            [-2.35422945022682, 53.248626947857538],
            [-2.354288089679734, 53.249193968960888],
            [-2.355940375784088, 53.248740501006424],
            [-2.356506339130704, 53.249396791460818],
            [-2.357151467919442, 53.249119800855375],
            [-2.357650699910235, 53.249319653092918],
            [-2.359244019777687, 53.249165644565899],
            [-2.359470403255402, 53.248817992639459],
            [-2.360185625875588, 53.249216734536589],
            [-2.360642149710968, 53.248791979257696],
            [-2.361681437152386, 53.249053100453722],
            [-2.362478819045898, 53.248531122258179],
            [-2.364153934081767, 53.248484665194056],
            [-2.365080175665041, 53.249020261831106],
            [-2.36455797419044, 53.249295121556457],
            [-2.365063425048963, 53.249868859370189],
            [-2.364630186696113, 53.250560528691686],
            [-2.366188937941751, 53.251094179107014],
            [-2.366717014854081, 53.250804013231807],
            [-2.367262096518701, 53.251099864652879],
            [-2.367143068433207, 53.252076418916928],
            [-2.368020431193204, 53.252317309425578],
            [-2.367668327948056, 53.252505364256095],
            [-2.36802133195218, 53.252771242716179],
            [-2.368837383250019, 53.252677931996452],
            [-2.369041499027933, 53.253406294257196],
            [-2.369990144768188, 53.253392565500882],
            [-2.370401091476279, 53.254463656259666],
            [-2.372666453139326, 53.254355917222291],
            [-2.373251272016997, 53.254729819739005],
            [-2.374384184208426, 53.254530308730018],
            [-2.374662327797219, 53.254799099370892],
            [-2.373991966860298, 53.254929745757515],
            [-2.374287650154801, 53.25531893253082],
            [-2.375491418717377, 53.255335819259116],
            [-2.375762323285782, 53.255803283283726],
            [-2.377267233715108, 53.255632234312536],
            [-2.37721120356665, 53.256078257718364],
            [-2.377695129591015, 53.256222343735701],
            [-2.37881513452107, 53.256086652314437],
            [-2.379400425898162, 53.256674457328387],
            [-2.379713540169216, 53.256486492217505],
            [-2.380569793279222, 53.256863987915949],
            [-2.381094970176273, 53.257767484482102],
            [-2.381539171066859, 53.257823591427574],
            [-2.381736219487842, 53.258236446416873],
            [-2.381242346436744, 53.258663199214148],
            [-2.38177542120068, 53.258766661676923],
            [-2.381610319375076, 53.259248092822851],
            [-2.379315664373707, 53.260476102259034],
            [-2.37899338785509, 53.263359843016403],
            [-2.379301227463485, 53.263078412314847],
            [-2.382954476957492, 53.262548072363941],
            [-2.383210622004528, 53.263027251979111],
            [-2.384074202514765, 53.262866268333646],
            [-2.384991911965172, 53.26339095068127],
            [-2.38526462861621, 53.264042657675517],
            [-2.385930309235404, 53.263713310470585],
            [-2.386086504109556, 53.264076851752435],
            [-2.386863154168769, 53.264083321656386],
            [-2.387463229270136, 53.263629234228958],
            [-2.387443377906416, 53.262594685265576],
            [-2.385567016135195, 53.262826390916075],
            [-2.386222181065691, 53.261000436722405],
            [-2.388238367298946, 53.261319280122557],
            [-2.388227775189768, 53.261804711206679],
            [-2.389236030582185, 53.262391987755706],
            [-2.388269790036374, 53.262305251847074],
            [-2.388250290938699, 53.264950725790442],
            [-2.389413484879233, 53.26509165024359],
            [-2.390346506400077, 53.264158254532518],
            [-2.391090138264463, 53.264324806099303],
            [-2.391785601834846, 53.264633534798634],
            [-2.391437522869481, 53.265425694977907],
            [-2.394116781340283, 53.266738220422425],
            [-2.395658979426639, 53.266674682595514],
            [-2.395387121822929, 53.266459853237876],
            [-2.395867973840658, 53.266414211595126],
            [-2.396697155305794, 53.266907637048206],
            [-2.397223274690337, 53.266084306251976],
            [-2.39910842572195, 53.265660926066573],
            [-2.399640676173659, 53.265822738832995],
            [-2.400003252557003, 53.265314552705135],
            [-2.401059784621163, 53.266067860445453],
            [-2.401333765759371, 53.265866487282693],
            [-2.402471294763168, 53.266300406493997],
            [-2.403115869514306, 53.266128339018543],
            [-2.403417146635227, 53.266438332752834],
            [-2.403036851470697, 53.266653553539832],
            [-2.403538042543656, 53.266857701429828],
            [-2.404112851707791, 53.266759573160293],
            [-2.40492602006664, 53.267134343364205],
            [-2.406331985343, 53.266934500229624],
            [-2.406624050818436, 53.267376652961481],
            [-2.407644335181155, 53.267139457812867],
            [-2.408842415781734, 53.268409069610399],
            [-2.409312191834897, 53.268144983406842],
            [-2.411575706298606, 53.26824774996706],
            [-2.412451317059449, 53.267779101637664],
            [-2.413074589643327, 53.267887505374667],
            [-2.412804091363283, 53.26813473644318],
            [-2.413579342560524, 53.267988227294914],
            [-2.41367697577014, 53.268316879357421],
            [-2.414241263864642, 53.268061434390809],
            [-2.414456513106027, 53.268303384221092],
            [-2.414678905919585, 53.267889124674056],
            [-2.413974102124506, 53.266946849635453],
            [-2.415012499147852, 53.266882113882758],
            [-2.414405989319634, 53.266025791633375],
            [-2.414982116291543, 53.265603109646776],
            [-2.414449661281084, 53.26511597040777],
            [-2.414797055397394, 53.264606892880437],
            [-2.415951212560787, 53.265044221395279],
            [-2.416363288795341, 53.264559183320138],
            [-2.417180978069683, 53.264775650993677],
            [-2.422886929613089, 53.260052580525084],
            [-2.424878153780853, 53.261005505333145],
            [-2.427220680489673, 53.26154904471155],
            [-2.427462591212819, 53.26115356718951],
            [-2.428053993006189, 53.261378861878995],
            [-2.426412011912292, 53.26329217983978],
            [-2.428524805723624, 53.264685061382671],
            [-2.430867960911721, 53.265415494636692],
            [-2.432567181064613, 53.266955418089893],
            [-2.433670952536048, 53.268611643559531],
            [-2.43546084173321, 53.269313436557212],
            [-2.439616055652773, 53.269204697430865],
            [-2.444091221208457, 53.270455530691365],
            [-2.445223093841158, 53.272599637754311],
            [-2.446497292995236, 53.272274868675396],
            [-2.446838310018925, 53.274347312935689],
            [-2.448255757669468, 53.275097051590052],
            [-2.448838925315006, 53.276224752026046],
            [-2.45008131160789, 53.277145915651253],
            [-2.449856751093908, 53.277468563252484],
            [-2.45048483910247, 53.278154734684122],
            [-2.44928396963268, 53.278764214995434],
            [-2.448582743416481, 53.278396516838981],
            [-2.448037296710009, 53.278582839243285],
            [-2.447871717746854, 53.278954699765109],
            [-2.448424976735417, 53.279370599592731],
            [-2.447176552974295, 53.279597313747082],
            [-2.446892327289748, 53.280098157980362],
            [-2.447625784918299, 53.280534958836711],
            [-2.447336724580846, 53.280716719255302],
            [-2.447999503746938, 53.281707494029767],
            [-2.448924183774306, 53.281782217629718],
            [-2.449700518827777, 53.282583790035801],
            [-2.452308918546855, 53.283732578560596],
            [-2.452118875219088, 53.284058694574604],
            [-2.453172354398121, 53.28453469660441],
            [-2.464458946687973, 53.287263402690968],
            [-2.470000933476656, 53.285704592798361],
            [-2.472427651195286, 53.286634331075334],
            [-2.471990963280435, 53.287297637764624],
            [-2.478923730826495, 53.289812022655482],
            [-2.48507800401122, 53.290808268061355],
            [-2.48969889832789, 53.290976342927017],
            [-2.495650176958293, 53.290470845643611],
            [-2.498007676234049, 53.289892926948383],
            [-2.498138645724322, 53.291736885446511],
            [-2.497492202012467, 53.293297346800109],
            [-2.49777984847452, 53.294810761269432],
            [-2.497055405673377, 53.29599041941767],
            [-2.497592290394362, 53.299324825557335],
            [-2.497820129686048, 53.299820955379438],
            [-2.498253440753487, 53.299793077738023],
            [-2.501696860453323, 53.298628069882319],
            [-2.500658071144717, 53.301663462139956],
            [-2.500918060076218, 53.304388673713873],
            [-2.50203844248266, 53.304594299275735],
            [-2.502603347079369, 53.305161809191638],
            [-2.502689346118046, 53.306861228362074],
            [-2.50521989774433, 53.309685598699204],
            [-2.50553463793462, 53.310664941992393],
            [-2.505686312274499, 53.3118148641367],
            [-2.504922662408371, 53.312730462454056],
            [-2.502909909253642, 53.313367284797636],
            [-2.504277750472347, 53.31429903838486],
            [-2.506750990591838, 53.318320921876541],
            [-2.510954041873267, 53.320951980243962],
            [-2.512282641189977, 53.321330101039564],
            [-2.509681020097414, 53.324478331451296],
            [-2.504457074941433, 53.326827736010841],
            [-2.503155504955672, 53.331560425227167],
            [-2.497884110557048, 53.337675125063157],
            [-2.49435382192596, 53.34069834271687],
            [-2.495050030731, 53.341677025744964],
            [-2.493969174559655, 53.343282401512226],
            [-2.495643096886221, 53.344378375631401],
            [-2.49708108821349, 53.344683401931199],
            [-2.501611305824575, 53.343408709693996],
            [-2.503348515860904, 53.34208274275084],
            [-2.508453795913149, 53.34297794114164],
            [-2.512428029038936, 53.342099811747246],
            [-2.514076729506905, 53.342686873586494],
            [-2.51598954521263, 53.342753220811943],
            [-2.518394118711245, 53.342374255840582],
            [-2.521050713788694, 53.347884467495213],
            [-2.519561231422578, 53.347938604748656],
            [-2.51669617420244, 53.348895766026303],
            [-2.51546164356544, 53.349665149835914],
            [-2.51309693141252, 53.35252297584104],
            [-2.511177524146461, 53.352820653396265],
            [-2.510977243375654, 53.353283532215073],
            [-2.510138310703371, 53.353231392667546],
            [-2.509903120765432, 53.353665654973881],
            [-2.508897439208188, 53.353615118209326],
            [-2.508238545235928, 53.354300171705368],
            [-2.506841642237027, 53.354832854359621],
            [-2.506986086121409, 53.35523044113306],
            [-2.505824540984042, 53.355469078808824],
            [-2.5030946743395, 53.356109833344149],
            [-2.502173213338989, 53.357092587643947],
            [-2.502752286713879, 53.357527899332332],
            [-2.501314030690022, 53.358392374674281],
            [-2.499694827491401, 53.359078715045626],
            [-2.497824812419491, 53.359393048469492],
            [-2.497695540380077, 53.360548637719127],
            [-2.49662601043431, 53.360963879037584],
            [-2.496595256423944, 53.361678609700753],
            [-2.494377619641357, 53.362090514418995],
            [-2.493553613564038, 53.36243280059896],
            [-2.493591519765594, 53.362722080216237],
            [-2.492830650921839, 53.362677585624006],
            [-2.492811172517751, 53.363593615461312],
            [-2.49203559301824, 53.363968949165027],
            [-2.492102040349625, 53.364779456393677],
            [-2.489784156860175, 53.365640224946027],
            [-2.489647640876942, 53.366839878210399],
            [-2.487235866712915, 53.366862336287269],
            [-2.487464371954951, 53.367395331660468],
            [-2.486879370594888, 53.367887604547938],
            [-2.486021263759142, 53.367625936703206],
            [-2.484240413739295, 53.367760817627605],
            [-2.483643686418517, 53.367487285104929],
            [-2.480726296260539, 53.367588064109732],
            [-2.480067228396773, 53.367114316973549],
            [-2.479842867666401, 53.367342633598689],
            [-2.479084612402386, 53.367135345369931],
            [-2.477180783142406, 53.366083207883221],
            [-2.47504140995911, 53.36600275752312],
            [-2.471713749883012, 53.364871700143439],
            [-2.471766766350032, 53.364637784361257],
            [-2.469840449567662, 53.364519544543626],
            [-2.469064460839104, 53.364056983563259],
            [-2.468755029280977, 53.364205614432635],
            [-2.467087181191036, 53.363672834278574],
            [-2.464996691868337, 53.363514709900905],
            [-2.463239721258162, 53.364455470722028],
            [-2.460953955706414, 53.364167697546925],
            [-2.458843137289235, 53.368269284643205],
            [-2.450923764856294, 53.367060839020915],
            [-2.450458349074332, 53.36653496132282],
            [-2.447158722577459, 53.36586962758755],
            [-2.439881221792377, 53.366646339673984],
            [-2.439664205805289, 53.3705257553341],
            [-2.437898213519349, 53.370230230841528],
            [-2.436433794621609, 53.370322787129815],
            [-2.435795880830517, 53.370699047976338],
            [-2.436483666815637, 53.370937430143165],
            [-2.435549885889609, 53.372661275079189],
            [-2.434603699420836, 53.372141584801],
            [-2.432778513749816, 53.37401516668605],
            [-2.431277973546096, 53.373373416126043],
            [-2.427490000662877, 53.375894887304653],
            [-2.428724535989006, 53.376389328438194],
            [-2.425507480214433, 53.379674515730436],
            [-2.426470530984168, 53.382777557178279],
            [-2.426220156985444, 53.384501576656739],
            [-2.426828054888429, 53.384702547175145],
            [-2.425382183041286, 53.386706786306149],
            [-2.426590877919129, 53.387444933542547],
            [-2.425154038414433, 53.387198379601031],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000050",
        LAD13CDO: "00EW",
        LAD13NM: "Cheshire West and Chester",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-3.109448873273005, 53.297002152261186],
              [-3.109582402686475, 53.296249413186793],
              [-3.110717370403323, 53.296274803228769],
              [-3.109448873273005, 53.297002152261186],
            ],
          ],
          [
            [
              [-2.493969174559655, 53.343282401512226],
              [-2.495050030731, 53.341677025744964],
              [-2.49435382192596, 53.34069834271687],
              [-2.497884110557048, 53.337675125063157],
              [-2.503155504955672, 53.331560425227167],
              [-2.504457074941433, 53.326827736010841],
              [-2.509681020097414, 53.324478331451296],
              [-2.512282641189977, 53.321330101039564],
              [-2.510954041873267, 53.320951980243962],
              [-2.506750990591838, 53.318320921876541],
              [-2.504277750472347, 53.31429903838486],
              [-2.502909909253642, 53.313367284797636],
              [-2.504922662408371, 53.312730462454056],
              [-2.505686312274499, 53.3118148641367],
              [-2.50553463793462, 53.310664941992393],
              [-2.50521989774433, 53.309685598699204],
              [-2.502689346118046, 53.306861228362074],
              [-2.502603347079369, 53.305161809191638],
              [-2.50203844248266, 53.304594299275735],
              [-2.500918060076218, 53.304388673713873],
              [-2.500658071144717, 53.301663462139956],
              [-2.501696860453323, 53.298628069882319],
              [-2.498253440753487, 53.299793077738023],
              [-2.497820129686048, 53.299820955379438],
              [-2.497592290394362, 53.299324825557335],
              [-2.497055405673377, 53.29599041941767],
              [-2.49777984847452, 53.294810761269432],
              [-2.497492202012467, 53.293297346800109],
              [-2.498138645724322, 53.291736885446511],
              [-2.498007676234049, 53.289892926948383],
              [-2.495650176958293, 53.290470845643611],
              [-2.48969889832789, 53.290976342927017],
              [-2.48507800401122, 53.290808268061355],
              [-2.478923730826495, 53.289812022655482],
              [-2.471990963280435, 53.287297637764624],
              [-2.472427651195286, 53.286634331075334],
              [-2.470000933476656, 53.285704592798361],
              [-2.464458946687973, 53.287263402690968],
              [-2.453172354398121, 53.28453469660441],
              [-2.452118875219088, 53.284058694574604],
              [-2.452308918546855, 53.283732578560596],
              [-2.449700518827777, 53.282583790035801],
              [-2.448924183774306, 53.281782217629718],
              [-2.447999503746938, 53.281707494029767],
              [-2.447336724580846, 53.280716719255302],
              [-2.447625784918299, 53.280534958836711],
              [-2.446892327289748, 53.280098157980362],
              [-2.447176552974295, 53.279597313747082],
              [-2.448424976735417, 53.279370599592731],
              [-2.447871717746854, 53.278954699765109],
              [-2.448037296710009, 53.278582839243285],
              [-2.448582743416481, 53.278396516838981],
              [-2.44928396963268, 53.278764214995434],
              [-2.45048483910247, 53.278154734684122],
              [-2.449856751093908, 53.277468563252484],
              [-2.45008131160789, 53.277145915651253],
              [-2.448838925315006, 53.276224752026046],
              [-2.448255757669468, 53.275097051590052],
              [-2.446838310018925, 53.274347312935689],
              [-2.446497292995236, 53.272274868675396],
              [-2.445223093841158, 53.272599637754311],
              [-2.444091221208457, 53.270455530691365],
              [-2.439616055652773, 53.269204697430865],
              [-2.43546084173321, 53.269313436557212],
              [-2.433670952536048, 53.268611643559531],
              [-2.432567181064613, 53.266955418089893],
              [-2.430867960911721, 53.265415494636692],
              [-2.428524805723624, 53.264685061382671],
              [-2.426412011912292, 53.26329217983978],
              [-2.428053993006189, 53.261378861878995],
              [-2.427462591212819, 53.26115356718951],
              [-2.427220680489673, 53.26154904471155],
              [-2.424878153780853, 53.261005505333145],
              [-2.422886929613089, 53.260052580525084],
              [-2.417180978069683, 53.264775650993677],
              [-2.416363288795341, 53.264559183320138],
              [-2.415951212560787, 53.265044221395279],
              [-2.414797055397394, 53.264606892880437],
              [-2.414449661281084, 53.26511597040777],
              [-2.414982116291543, 53.265603109646776],
              [-2.414405989319634, 53.266025791633375],
              [-2.415012499147852, 53.266882113882758],
              [-2.413974102124506, 53.266946849635453],
              [-2.414678905919585, 53.267889124674056],
              [-2.414456513106027, 53.268303384221092],
              [-2.414241263864642, 53.268061434390809],
              [-2.41367697577014, 53.268316879357421],
              [-2.413579342560524, 53.267988227294914],
              [-2.412804091363283, 53.26813473644318],
              [-2.413074589643327, 53.267887505374667],
              [-2.412451317059449, 53.267779101637664],
              [-2.411575706298606, 53.26824774996706],
              [-2.409312191834897, 53.268144983406842],
              [-2.408842415781734, 53.268409069610399],
              [-2.407644335181155, 53.267139457812867],
              [-2.406624050818436, 53.267376652961481],
              [-2.406331985343, 53.266934500229624],
              [-2.40492602006664, 53.267134343364205],
              [-2.404112851707791, 53.266759573160293],
              [-2.403538042543656, 53.266857701429828],
              [-2.403036851470697, 53.266653553539832],
              [-2.403417146635227, 53.266438332752834],
              [-2.403115869514306, 53.266128339018543],
              [-2.402471294763168, 53.266300406493997],
              [-2.401333765759371, 53.265866487282693],
              [-2.401059784621163, 53.266067860445453],
              [-2.400003252557003, 53.265314552705135],
              [-2.399640676173659, 53.265822738832995],
              [-2.39910842572195, 53.265660926066573],
              [-2.397223274690337, 53.266084306251976],
              [-2.396697155305794, 53.266907637048206],
              [-2.395867973840658, 53.266414211595126],
              [-2.395387121822929, 53.266459853237876],
              [-2.395658979426639, 53.266674682595514],
              [-2.394116781340283, 53.266738220422425],
              [-2.391437522869481, 53.265425694977907],
              [-2.391785601834846, 53.264633534798634],
              [-2.391090138264463, 53.264324806099303],
              [-2.390346506400077, 53.264158254532518],
              [-2.389413484879233, 53.26509165024359],
              [-2.388250290938699, 53.264950725790442],
              [-2.388269790036374, 53.262305251847074],
              [-2.389236030582185, 53.262391987755706],
              [-2.388227775189768, 53.261804711206679],
              [-2.388238367298946, 53.261319280122557],
              [-2.386222181065691, 53.261000436722405],
              [-2.385567016135195, 53.262826390916075],
              [-2.387443377906416, 53.262594685265576],
              [-2.387463229270136, 53.263629234228958],
              [-2.386863154168769, 53.264083321656386],
              [-2.386086504109556, 53.264076851752435],
              [-2.385930309235404, 53.263713310470585],
              [-2.38526462861621, 53.264042657675517],
              [-2.384991911965172, 53.26339095068127],
              [-2.384074202514765, 53.262866268333646],
              [-2.383210622004528, 53.263027251979111],
              [-2.382954476957492, 53.262548072363941],
              [-2.379301227463485, 53.263078412314847],
              [-2.37899338785509, 53.263359843016403],
              [-2.379315664373707, 53.260476102259034],
              [-2.381610319375076, 53.259248092822851],
              [-2.38177542120068, 53.258766661676923],
              [-2.381242346436744, 53.258663199214148],
              [-2.381736219487842, 53.258236446416873],
              [-2.381539171066859, 53.257823591427574],
              [-2.381094970176273, 53.257767484482102],
              [-2.380569793279222, 53.256863987915949],
              [-2.379713540169216, 53.256486492217505],
              [-2.379400425898162, 53.256674457328387],
              [-2.37881513452107, 53.256086652314437],
              [-2.377695129591015, 53.256222343735701],
              [-2.37721120356665, 53.256078257718364],
              [-2.377267233715108, 53.255632234312536],
              [-2.375762323285782, 53.255803283283726],
              [-2.375491418717377, 53.255335819259116],
              [-2.374287650154801, 53.25531893253082],
              [-2.373991966860298, 53.254929745757515],
              [-2.374662327797219, 53.254799099370892],
              [-2.374384184208426, 53.254530308730018],
              [-2.373251272016997, 53.254729819739005],
              [-2.372666453139326, 53.254355917222291],
              [-2.370401091476279, 53.254463656259666],
              [-2.369990144768188, 53.253392565500882],
              [-2.369041499027933, 53.253406294257196],
              [-2.368837383250019, 53.252677931996452],
              [-2.36802133195218, 53.252771242716179],
              [-2.367668327948056, 53.252505364256095],
              [-2.368020431193204, 53.252317309425578],
              [-2.367143068433207, 53.252076418916928],
              [-2.367262096518701, 53.251099864652879],
              [-2.366717014854081, 53.250804013231807],
              [-2.366188937941751, 53.251094179107014],
              [-2.364630186696113, 53.250560528691686],
              [-2.365063425048963, 53.249868859370189],
              [-2.36455797419044, 53.249295121556457],
              [-2.365080175665041, 53.249020261831106],
              [-2.364153934081767, 53.248484665194056],
              [-2.362478819045898, 53.248531122258179],
              [-2.361681437152386, 53.249053100453722],
              [-2.360642149710968, 53.248791979257696],
              [-2.360185625875588, 53.249216734536589],
              [-2.359470403255402, 53.248817992639459],
              [-2.359244019777687, 53.249165644565899],
              [-2.357650699910235, 53.249319653092918],
              [-2.357151467919442, 53.249119800855375],
              [-2.356506339130704, 53.249396791460818],
              [-2.355940375784088, 53.248740501006424],
              [-2.354288089679734, 53.249193968960888],
              [-2.35422945022682, 53.248626947857538],
              [-2.353089373462085, 53.248313026389745],
              [-2.351728009646219, 53.248607392803784],
              [-2.351125157872873, 53.248377259197476],
              [-2.350958031763323, 53.248831687789981],
              [-2.349630658428277, 53.249071098168351],
              [-2.349027571783991, 53.248995562602033],
              [-2.349012830280539, 53.248657625651482],
              [-2.350751341130189, 53.245917216947888],
              [-2.350146202804493, 53.243750888943588],
              [-2.346354608224271, 53.240920600012238],
              [-2.352646597474865, 53.236938057124497],
              [-2.353389405929744, 53.236898102582956],
              [-2.353709745794945, 53.23595511996016],
              [-2.359132440893868, 53.233344710151307],
              [-2.35951063870114, 53.230565112095235],
              [-2.363801476609917, 53.223554248462094],
              [-2.367964691754838, 53.223303254167455],
              [-2.377648667221528, 53.22713546373096],
              [-2.380933493908653, 53.227797371717074],
              [-2.385741053294672, 53.230368897821663],
              [-2.396291005054364, 53.234346000645509],
              [-2.398827367045077, 53.232085823714051],
              [-2.396714803480319, 53.231087022986685],
              [-2.398860699747137, 53.228260046440163],
              [-2.402493373898773, 53.229709419027934],
              [-2.402823393147924, 53.229279533982876],
              [-2.40094164670243, 53.228289914758278],
              [-2.401505620699922, 53.227888908870874],
              [-2.399039865647731, 53.226747516730832],
              [-2.399541570067713, 53.225933240025348],
              [-2.397879719654517, 53.225211602057342],
              [-2.398957683464228, 53.224031351460084],
              [-2.399649467405075, 53.224009256843054],
              [-2.401174013370948, 53.22174252774554],
              [-2.403080917473157, 53.221135632000745],
              [-2.406826527243421, 53.221275708805663],
              [-2.407999788757459, 53.220860903337346],
              [-2.409726742987166, 53.22102846099942],
              [-2.411473285475509, 53.220738391285103],
              [-2.412901494383697, 53.221143342530944],
              [-2.413737175744933, 53.219896379597529],
              [-2.413644767974473, 53.219321411155299],
              [-2.414183556761015, 53.219283583244163],
              [-2.412323321596322, 53.212565439882212],
              [-2.409195380351012, 53.212738926810673],
              [-2.40883294180671, 53.211789145205152],
              [-2.408649927068951, 53.209080516130008],
              [-2.410162641767323, 53.205680203173578],
              [-2.407437617744833, 53.204716057526937],
              [-2.406400879476862, 53.204007677680181],
              [-2.403529916257857, 53.204220589951703],
              [-2.402973743672624, 53.203344256006197],
              [-2.400890565176363, 53.20326679108198],
              [-2.399534086856651, 53.203897877213457],
              [-2.398549650440153, 53.205259400879648],
              [-2.397696924001615, 53.204845165992516],
              [-2.396773530200857, 53.204882403949881],
              [-2.396031484607184, 53.206076803745496],
              [-2.395034421603492, 53.206083710841803],
              [-2.393470903198033, 53.204890664998423],
              [-2.392998538107426, 53.203171746662683],
              [-2.392376538920847, 53.202766599849994],
              [-2.391921148484113, 53.200111874356836],
              [-2.390522449835069, 53.198079580127022],
              [-2.389863519877456, 53.196069119327674],
              [-2.388049424271204, 53.195911446533515],
              [-2.387152212555027, 53.196175944709395],
              [-2.386751907142212, 53.196768716704462],
              [-2.384492925717637, 53.196577377081034],
              [-2.384426534704716, 53.196852652459185],
              [-2.382869755918728, 53.196512493584621],
              [-2.382304497441938, 53.196742628356439],
              [-2.381934596811004, 53.196217963448483],
              [-2.379538549694035, 53.196432369877108],
              [-2.377254295293881, 53.195743886286046],
              [-2.376384821154691, 53.195935405161705],
              [-2.376167369847091, 53.195717661526835],
              [-2.37256142900297, 53.195534837870007],
              [-2.372678669160059, 53.194211301715065],
              [-2.371910794877973, 53.192652326567163],
              [-2.372426127630095, 53.192360373531322],
              [-2.371153652504965, 53.190423637590015],
              [-2.373340671606535, 53.189439703499858],
              [-2.375630870509503, 53.189142157874791],
              [-2.376923744296949, 53.187245002774134],
              [-2.372823684790275, 53.186432727834479],
              [-2.373780024916428, 53.18540948517203],
              [-2.37361018950491, 53.185152934652947],
              [-2.370849730521492, 53.184517957152501],
              [-2.370162939774123, 53.183658055340523],
              [-2.369161722771385, 53.183469699656591],
              [-2.368693986870414, 53.183020803255054],
              [-2.37232139633329, 53.178724485646605],
              [-2.37366362960656, 53.178737359395377],
              [-2.374729140755879, 53.176529021329848],
              [-2.377814163307356, 53.173800112469578],
              [-2.376866576813571, 53.173051639353076],
              [-2.378202830335203, 53.172064908639904],
              [-2.381988528378698, 53.17333464680231],
              [-2.383177573233591, 53.173467457710316],
              [-2.384616066505395, 53.174558572778409],
              [-2.387001814021901, 53.175498277127048],
              [-2.390028739205146, 53.17800081165548],
              [-2.391252400102562, 53.178303319872697],
              [-2.392110826239583, 53.179898731254191],
              [-2.393291458676074, 53.179906522669413],
              [-2.396256223673636, 53.178840490180903],
              [-2.396828503585902, 53.177945980982422],
              [-2.398057430221682, 53.177196694070489],
              [-2.398759001226269, 53.174601930803703],
              [-2.399302751384401, 53.174512915382785],
              [-2.397943095213109, 53.1722819131788],
              [-2.406580502158082, 53.174120634769508],
              [-2.405563276144583, 53.17476591629709],
              [-2.410358664878158, 53.178097871921231],
              [-2.412642978242955, 53.180860373235099],
              [-2.413011073205279, 53.181950355975758],
              [-2.412702242954027, 53.183759105089408],
              [-2.41375428950672, 53.18392174946311],
              [-2.416323047778139, 53.187876022747894],
              [-2.417391803593067, 53.188822412645912],
              [-2.420706621473165, 53.190185156555202],
              [-2.421909926506564, 53.191100467941517],
              [-2.427033294031779, 53.192934825846585],
              [-2.425900624787777, 53.193725414630521],
              [-2.426384963792958, 53.195020785260482],
              [-2.428939233454079, 53.194943296131967],
              [-2.429428194713597, 53.196540665923685],
              [-2.430665252490495, 53.197949259315244],
              [-2.434518703065057, 53.196658832251067],
              [-2.435356129617874, 53.197759615250497],
              [-2.436711342195273, 53.197369027752735],
              [-2.437842716667205, 53.198223316974705],
              [-2.438657960617674, 53.20007203761336],
              [-2.440105337987927, 53.199760174850091],
              [-2.440607207668116, 53.200381252303039],
              [-2.443124636158895, 53.201347211262473],
              [-2.444513512131954, 53.201022928191058],
              [-2.445843234265787, 53.201203129633207],
              [-2.447939118891596, 53.199917038082383],
              [-2.451196053617768, 53.199443610655301],
              [-2.451680759494342, 53.200125831569942],
              [-2.452763709189635, 53.200611616514777],
              [-2.454583160239592, 53.200950759640179],
              [-2.4543899690318, 53.201650836276279],
              [-2.455621169965684, 53.20185197909791],
              [-2.456437719458747, 53.202618305462615],
              [-2.456932382797501, 53.201833472170982],
              [-2.458130449915582, 53.20145313187713],
              [-2.459754813766846, 53.201461254549628],
              [-2.460397712427587, 53.199988180218],
              [-2.46025758637398, 53.199065557421832],
              [-2.459332043674592, 53.199165312481469],
              [-2.459120841034375, 53.198732859828475],
              [-2.458516718246983, 53.198652489027992],
              [-2.458825143734957, 53.197957354667317],
              [-2.45839664356781, 53.196799430848799],
              [-2.459491695116292, 53.196178568982546],
              [-2.460361247780962, 53.194631808173803],
              [-2.460171088399985, 53.194206466883472],
              [-2.460907978555599, 53.193832373432819],
              [-2.460195931873532, 53.193591526616807],
              [-2.460463759001766, 53.192884858561207],
              [-2.458300634212208, 53.191870260242581],
              [-2.458355262211188, 53.191519480787285],
              [-2.456355981738137, 53.189856112675265],
              [-2.456785444189307, 53.188723655265449],
              [-2.456417251834762, 53.187312002760741],
              [-2.457576090467847, 53.186377197886088],
              [-2.457486968524263, 53.185034589305474],
              [-2.456775589462387, 53.184700234182536],
              [-2.456936836412491, 53.184384102875413],
              [-2.456116003723544, 53.183605210008224],
              [-2.456751861276355, 53.182749719717947],
              [-2.456788051239068, 53.181790458049001],
              [-2.456134378194238, 53.1812482324451],
              [-2.45657640022208, 53.181023611879617],
              [-2.456241386776485, 53.178783945572647],
              [-2.457301793445515, 53.178462566808562],
              [-2.456540569711639, 53.177651087004136],
              [-2.457334349143153, 53.17660082505973],
              [-2.45623160641874, 53.175889533153601],
              [-2.456351216459961, 53.175318274694909],
              [-2.455955073559223, 53.175067202123614],
              [-2.454817226371397, 53.175132679197191],
              [-2.453586053580136, 53.174294209804536],
              [-2.452011393391908, 53.173793220015803],
              [-2.450988209893714, 53.173805190227995],
              [-2.449779880926087, 53.172994460800922],
              [-2.448242595514808, 53.173055990117817],
              [-2.447887170514171, 53.172111687125707],
              [-2.446841548162154, 53.172263934278654],
              [-2.446439432600024, 53.171861837633394],
              [-2.445294420814601, 53.172095344674837],
              [-2.445194304559007, 53.171673237062379],
              [-2.443478596603224, 53.170822091710093],
              [-2.442828932173139, 53.169910330595826],
              [-2.443365756472334, 53.169454389039487],
              [-2.441985683613217, 53.168790740290596],
              [-2.441864765224983, 53.167941730210906],
              [-2.442359911037783, 53.167797865467151],
              [-2.442006536492622, 53.166163182612834],
              [-2.442573441531285, 53.166162874247085],
              [-2.442173299678619, 53.165216022944762],
              [-2.443093095689773, 53.164915966407825],
              [-2.442429323429621, 53.164658653326939],
              [-2.442194118771587, 53.163749841445821],
              [-2.443450870971572, 53.162913684911032],
              [-2.442446600418046, 53.162849105313683],
              [-2.442419192910776, 53.162511221067916],
              [-2.44363219793475, 53.16222265467303],
              [-2.444498726995801, 53.161262094405409],
              [-2.44451754603514, 53.160912352217366],
              [-2.444035501157099, 53.161013030193985],
              [-2.443269131558231, 53.160663517709835],
              [-2.443255154988311, 53.160178163974642],
              [-2.443724901233361, 53.160190796328912],
              [-2.443848533346993, 53.159852349153816],
              [-2.446470287359544, 53.159852432925547],
              [-2.451863654570245, 53.160877503378074],
              [-2.452312769524157, 53.160633993731842],
              [-2.452818491967549, 53.160937697090439],
              [-2.454660925309755, 53.160921685610589],
              [-2.455828554834844, 53.160456084715747],
              [-2.456299629640499, 53.159606617207935],
              [-2.456943176909585, 53.157256220961408],
              [-2.456466308794943, 53.156851747446588],
              [-2.457324595246489, 53.15641877867801],
              [-2.459106315173099, 53.156197083054288],
              [-2.461969435127144, 53.156551858890083],
              [-2.464301438215477, 53.153141344028263],
              [-2.469074766453974, 53.15274599016503],
              [-2.472059468473113, 53.154518510953189],
              [-2.474610119471665, 53.154609045765696],
              [-2.476352812340189, 53.155198955540577],
              [-2.477688901105985, 53.156471839462327],
              [-2.48033063904669, 53.15720819654706],
              [-2.483801968207608, 53.159494427925409],
              [-2.486181252155252, 53.160140932680093],
              [-2.4856301072142, 53.161265010761191],
              [-2.484539358638947, 53.162155771460611],
              [-2.485025852018667, 53.162721895698141],
              [-2.48703321482221, 53.163395966216875],
              [-2.489173826258893, 53.163549890896633],
              [-2.490568808843466, 53.163504599233086],
              [-2.49137958371004, 53.162989780969156],
              [-2.492569182340461, 53.162904862828817],
              [-2.493811995423764, 53.163546922983898],
              [-2.49486774792869, 53.163397816142016],
              [-2.496195484490259, 53.164266021467434],
              [-2.496452024086149, 53.164073486076902],
              [-2.498031734293233, 53.164221499194134],
              [-2.499100720553603, 53.163665996200827],
              [-2.499680583720121, 53.164139981445508],
              [-2.50028834525669, 53.163668202672277],
              [-2.500697076729717, 53.163959526169698],
              [-2.500942075823489, 53.163548596603761],
              [-2.50169611274358, 53.163693740148965],
              [-2.500836426124704, 53.161412352556511],
              [-2.50136445785919, 53.160648760341353],
              [-2.502418471003867, 53.159846990959544],
              [-2.503175148716471, 53.159711656598766],
              [-2.504026892648322, 53.160031658226004],
              [-2.505675189176056, 53.159663309736722],
              [-2.506280218862672, 53.158970381487528],
              [-2.506519225209658, 53.159200382656955],
              [-2.507556053177666, 53.158981129113499],
              [-2.508331749385639, 53.159445248568751],
              [-2.510102335631398, 53.159178791286919],
              [-2.510755700539563, 53.159414199040185],
              [-2.512366139014709, 53.158887713370774],
              [-2.512639390267784, 53.158474839732222],
              [-2.512040539885092, 53.158295838674469],
              [-2.512417022554365, 53.158011963405919],
              [-2.513369480952862, 53.157996174200029],
              [-2.514118577093551, 53.158858584621541],
              [-2.516819223194127, 53.158956557245389],
              [-2.516891214230243, 53.159471315818443],
              [-2.51818357602769, 53.160107513981508],
              [-2.519988391388856, 53.160077172069059],
              [-2.520566531270418, 53.160393755860355],
              [-2.521392929056286, 53.15959820633126],
              [-2.522999620337068, 53.159142606396877],
              [-2.522607870257577, 53.157672822810817],
              [-2.522836708281145, 53.155337366592065],
              [-2.523939192216099, 53.154384176374968],
              [-2.526865993492719, 53.152822447833486],
              [-2.528603155944722, 53.152422824621055],
              [-2.532755749032448, 53.152186778011583],
              [-2.542251652956925, 53.149744725280577],
              [-2.548012835873644, 53.15139120499861],
              [-2.550745046441659, 53.153167416760731],
              [-2.551940500102833, 53.154501248238972],
              [-2.55356327891977, 53.155567904495491],
              [-2.553846320591157, 53.157109108059274],
              [-2.555963116928112, 53.157971178726193],
              [-2.558499734010016, 53.15959980619926],
              [-2.557059872943471, 53.160322079690623],
              [-2.559680841868237, 53.16186758787687],
              [-2.56182093227309, 53.162653937042926],
              [-2.565000187336606, 53.162041120932336],
              [-2.566573544805652, 53.162261965660583],
              [-2.566760364742312, 53.162591873175316],
              [-2.568108543067311, 53.162408363632196],
              [-2.572403125206808, 53.163456590991423],
              [-2.574146455674857, 53.161389705777971],
              [-2.576671341761862, 53.161965386570607],
              [-2.578830315798578, 53.160019567729591],
              [-2.580766526595058, 53.159311689219273],
              [-2.580345259442709, 53.159125872284086],
              [-2.580868513762955, 53.158004183418413],
              [-2.582856609380882, 53.157713109429871],
              [-2.583581574704828, 53.155482069671407],
              [-2.586185831324102, 53.156520995194434],
              [-2.586684209868008, 53.157316768596061],
              [-2.587529396467443, 53.157442941938662],
              [-2.589012278185311, 53.15706706330981],
              [-2.591645070540517, 53.157228396339903],
              [-2.593547083235016, 53.158293121628908],
              [-2.596505587788089, 53.158856331892451],
              [-2.597127655557396, 53.155295342650604],
              [-2.597495528434073, 53.155187425975939],
              [-2.595792286600695, 53.152707788407433],
              [-2.593414275563851, 53.150834657992924],
              [-2.592252812597768, 53.149014761623228],
              [-2.588798280463485, 53.146567993313788],
              [-2.589768019823678, 53.1463061052985],
              [-2.589924704509147, 53.145847784975295],
              [-2.590784822497921, 53.145988243516392],
              [-2.59064529823661, 53.145081938036085],
              [-2.591392588215998, 53.145063845276297],
              [-2.591360354515802, 53.144785343992929],
              [-2.592090350469428, 53.144488671311926],
              [-2.592496037655323, 53.144961276115311],
              [-2.594340591314189, 53.14450172798572],
              [-2.596002303475401, 53.144876354550263],
              [-2.596824072903877, 53.144731108730618],
              [-2.597087432943808, 53.145394081076049],
              [-2.597765197761016, 53.145429332615741],
              [-2.598610127864604, 53.146196349817551],
              [-2.600217243708558, 53.146508268659368],
              [-2.603549796114367, 53.145436100774013],
              [-2.60347920155294, 53.145093075704786],
              [-2.604334287734189, 53.145086041003445],
              [-2.605624343799298, 53.145815690701241],
              [-2.605836948600733, 53.14668654989454],
              [-2.607686800450006, 53.147983234727995],
              [-2.607835209665951, 53.148539800039934],
              [-2.608272017582776, 53.148554648170858],
              [-2.607915989564959, 53.148754226566439],
              [-2.608201481010274, 53.149170760532634],
              [-2.608720107015446, 53.149155525034125],
              [-2.609373476419686, 53.149997155788569],
              [-2.611686905957379, 53.150542614270002],
              [-2.612749690027713, 53.150307927211081],
              [-2.614072929744033, 53.150408079032637],
              [-2.614307174762053, 53.150686430904599],
              [-2.615041755471861, 53.150296107601548],
              [-2.619152827432757, 53.150130073068539],
              [-2.620350859569729, 53.150256874628475],
              [-2.620851948307092, 53.151201711768024],
              [-2.622436604785904, 53.151384005617501],
              [-2.622793418370541, 53.151138535362868],
              [-2.623180232674251, 53.151416071636042],
              [-2.625284595884988, 53.150831533839636],
              [-2.626421840702051, 53.149964400743279],
              [-2.626859258232054, 53.150019628426435],
              [-2.62841836447888, 53.148658550707822],
              [-2.629068754464416, 53.148656913478732],
              [-2.630002788709169, 53.146276157059695],
              [-2.630919799709578, 53.146208376840171],
              [-2.632050096888112, 53.145494939152748],
              [-2.633085360640274, 53.143913649100263],
              [-2.634710423943522, 53.142694163659584],
              [-2.636591674748605, 53.14202341897861],
              [-2.638259256095653, 53.141867967026101],
              [-2.640207591619859, 53.140788699743112],
              [-2.64032812383425, 53.139846891823638],
              [-2.640788515953986, 53.139844414352368],
              [-2.643344267094648, 53.13520842665995],
              [-2.642178361522986, 53.134506380096553],
              [-2.640293760930974, 53.134120112771619],
              [-2.639620178915624, 53.133552026410108],
              [-2.640528131718527, 53.132090906553522],
              [-2.640429058178987, 53.129047722720863],
              [-2.641147674908333, 53.128930590887144],
              [-2.641263892616869, 53.12840499983561],
              [-2.644598892424592, 53.128655759801532],
              [-2.649962079425106, 53.129209088793296],
              [-2.652428614075967, 53.130330921109909],
              [-2.656243908334282, 53.130014204237469],
              [-2.658308830862068, 53.130759686267425],
              [-2.659730770502038, 53.1307005752479],
              [-2.659693371179582, 53.129332636478715],
              [-2.660638043507964, 53.127490017470059],
              [-2.660517328195745, 53.125076204678479],
              [-2.662436830618982, 53.121333243690053],
              [-2.663763866765394, 53.120566266914601],
              [-2.666940433001463, 53.120169165974481],
              [-2.668767715242796, 53.118812336820987],
              [-2.670092879439927, 53.118417450292149],
              [-2.670728117402269, 53.117951829249947],
              [-2.670041467779932, 53.117033409607714],
              [-2.671375113337413, 53.116420024204132],
              [-2.671145171480527, 53.115854105192504],
              [-2.673796808525235, 53.115928114492341],
              [-2.674437206773892, 53.115128946124194],
              [-2.678501887195516, 53.115943631576613],
              [-2.681978096528151, 53.116006462309223],
              [-2.683639017567921, 53.117105296972007],
              [-2.68747920991168, 53.117218911991209],
              [-2.690487434819564, 53.118652348199696],
              [-2.692404796502251, 53.118859645368609],
              [-2.692588604239853, 53.119139936768356],
              [-2.696628572825607, 53.119952350565889],
              [-2.699652904706387, 53.119771006931018],
              [-2.703671097455257, 53.117409233341164],
              [-2.706054072482331, 53.118493597101562],
              [-2.707108331517154, 53.117090419988138],
              [-2.70856691173068, 53.116201704287455],
              [-2.709204043171193, 53.114335350893747],
              [-2.710048043924236, 53.113421511795309],
              [-2.709698636588918, 53.111890939907546],
              [-2.709999816924373, 53.111764193715999],
              [-2.708421261450451, 53.110251731743794],
              [-2.707565301745916, 53.107797381895693],
              [-2.7064506982505, 53.106598553411388],
              [-2.708215228862418, 53.105532733556792],
              [-2.709057736682388, 53.098635696153231],
              [-2.711735953849194, 53.096399371845905],
              [-2.711363148162776, 53.093626631330224],
              [-2.713141734484591, 53.092998425273649],
              [-2.717579354754685, 53.09351199692567],
              [-2.722293757935433, 53.092178236306076],
              [-2.724921002374018, 53.092303389111109],
              [-2.730332614753292, 53.091512502889252],
              [-2.731738031443784, 53.091793321011885],
              [-2.732883879569364, 53.090067527645722],
              [-2.735614124610115, 53.088708586031053],
              [-2.734929102790916, 53.086537424128764],
              [-2.741779192293881, 53.081845675098457],
              [-2.744438003171323, 53.082275833420212],
              [-2.74546578038085, 53.082140851560027],
              [-2.745159306200378, 53.080646960041804],
              [-2.746746579212533, 53.078857135081819],
              [-2.748367810424029, 53.078516142978422],
              [-2.748650334343331, 53.077936356084201],
              [-2.750742143002776, 53.076503769607257],
              [-2.75110120890175, 53.074407004615807],
              [-2.749927036998883, 53.071689765551334],
              [-2.751263822999765, 53.071401764468455],
              [-2.752928573311195, 53.069210441719633],
              [-2.74325802066063, 53.065778926751385],
              [-2.74107076357365, 53.06450530965089],
              [-2.741879641479439, 53.063466500735373],
              [-2.739678334458932, 53.062408688256184],
              [-2.740168880657556, 53.061341306214857],
              [-2.737255973276702, 53.060982746214087],
              [-2.737554145278879, 53.060443338829621],
              [-2.735711145331461, 53.060169792631172],
              [-2.735925033912523, 53.059762154064558],
              [-2.734724572072556, 53.05866389420131],
              [-2.732423272491328, 53.057667692516233],
              [-2.734534098672287, 53.056589445592905],
              [-2.734699193103405, 53.055068337967157],
              [-2.730481796701091, 53.053505009512726],
              [-2.729005796397622, 53.051620023215719],
              [-2.726492388742471, 53.05110952950885],
              [-2.723263082390872, 53.048824358725206],
              [-2.722129110971617, 53.048988562054902],
              [-2.719121773036331, 53.04612481866949],
              [-2.719215125986562, 53.044833391953922],
              [-2.718263353208672, 53.044198207647511],
              [-2.718250008454894, 53.045548479814222],
              [-2.71620185386349, 53.047609490730103],
              [-2.710460950485031, 53.048195866894304],
              [-2.709228043857029, 53.050149404883228],
              [-2.709581464083582, 53.052229213385324],
              [-2.707237129736577, 53.052920968410959],
              [-2.70455530853581, 53.053140942092313],
              [-2.704894769539632, 53.053920997259091],
              [-2.702410989312941, 53.054305151023819],
              [-2.700141606404261, 53.053100486129793],
              [-2.693859617693152, 53.051825767291419],
              [-2.69140963356802, 53.050033192681397],
              [-2.689195957577998, 53.049294538701425],
              [-2.682260378667948, 53.04530643733677],
              [-2.668817483873891, 53.0386381504523],
              [-2.671447929593504, 53.036406570854886],
              [-2.672314372002407, 53.034009627365784],
              [-2.67721331582651, 53.03326268475346],
              [-2.678770853792956, 53.032643431282011],
              [-2.678251571028917, 53.03233626395442],
              [-2.678806472413676, 53.03196813231753],
              [-2.678279324285005, 53.031444368101766],
              [-2.678960152594173, 53.030929890744751],
              [-2.678374045161907, 53.027979354195288],
              [-2.679275726348202, 53.026721098376669],
              [-2.679321352466713, 53.025924385847084],
              [-2.679618987007064, 53.02560446375864],
              [-2.682480702836117, 53.024886007472169],
              [-2.683189026697809, 53.023479608157828],
              [-2.685018428308912, 53.021698188693186],
              [-2.682542814392248, 53.020227392591494],
              [-2.678320885954803, 53.014996403711287],
              [-2.680133586609834, 53.015195498350046],
              [-2.688555281397373, 53.012471798477165],
              [-2.690342226091956, 53.007793276991229],
              [-2.69372051494515, 53.002756661427824],
              [-2.696097888965099, 53.00075432071948],
              [-2.699292930578085, 52.995424658944046],
              [-2.700767159677482, 52.993260328695861],
              [-2.704565902485331, 52.990402626565867],
              [-2.711879279692309, 52.986070219570585],
              [-2.715143697479718, 52.985032125664489],
              [-2.716573618425211, 52.984039175878067],
              [-2.717993498640324, 52.984151064394347],
              [-2.719535506616102, 52.983813626437005],
              [-2.720301067115422, 52.983989675793673],
              [-2.72510488355508, 52.982929382272438],
              [-2.726841191168296, 52.983258575233215],
              [-2.727486683981781, 52.98399984617992],
              [-2.728000191224029, 52.983888829242559],
              [-2.728908681421734, 52.984412735944183],
              [-2.731173972028966, 52.984219033718411],
              [-2.73157134348237, 52.984551892647723],
              [-2.733383931165612, 52.984277332647679],
              [-2.734680968189776, 52.98504690438731],
              [-2.735333153663885, 52.98459430055668],
              [-2.736365517444964, 52.984951080295488],
              [-2.736330827784981, 52.984575538656806],
              [-2.736747870747621, 52.984573853137896],
              [-2.736854136833341, 52.984866248530999],
              [-2.737270191861082, 52.98471893916507],
              [-2.739382297240299, 52.985499581861333],
              [-2.739520137774078, 52.985807959357828],
              [-2.740229670012927, 52.985662409005691],
              [-2.740959470429708, 52.986527135710283],
              [-2.741832221669243, 52.986430898777797],
              [-2.743742038891273, 52.987132721057058],
              [-2.745733335779686, 52.987113052268555],
              [-2.746325887288365, 52.986748858272591],
              [-2.748167751316278, 52.986616820517831],
              [-2.750775862571178, 52.986862864776448],
              [-2.753718706833876, 52.986141268294389],
              [-2.758532627373632, 52.986115137995164],
              [-2.759940909233077, 52.986404590149114],
              [-2.761601217703586, 52.98747089321558],
              [-2.761686469006321, 52.989437227655124],
              [-2.761071908448519, 52.989730622983949],
              [-2.761014346230345, 52.990947256945738],
              [-2.762539052637003, 52.991408529507666],
              [-2.762393509462369, 52.991772634720988],
              [-2.762877256729623, 52.991747956375924],
              [-2.762926461188492, 52.992172838971761],
              [-2.763791792489208, 52.99274979444624],
              [-2.764182619771915, 52.992693346049855],
              [-2.764411975146718, 52.993532379681398],
              [-2.765112158753951, 52.993866774915077],
              [-2.765579790460765, 52.993772072100057],
              [-2.765617244243638, 52.994120619354419],
              [-2.766459069592532, 52.994123284565951],
              [-2.766857439236381, 52.994660078798397],
              [-2.76791884743135, 52.994358374269169],
              [-2.768276120965285, 52.994845088665336],
              [-2.769146256669278, 52.994344145775152],
              [-2.768551569329318, 52.994163709753849],
              [-2.768736705868477, 52.99393418190936],
              [-2.769354391531694, 52.994151324673823],
              [-2.770428037809105, 52.993952896489283],
              [-2.770758964809293, 52.993293626144663],
              [-2.771227474903008, 52.993332837396267],
              [-2.771501708091386, 52.994007060493097],
              [-2.772611352852097, 52.993906363335384],
              [-2.773698556043974, 52.994717326872234],
              [-2.774754924061914, 52.994469530389082],
              [-2.775889660310568, 52.994687762780337],
              [-2.776477407187834, 52.994396264058302],
              [-2.776875187322374, 52.994727171662056],
              [-2.776765733006247, 52.994356624767732],
              [-2.778419006808653, 52.994080622069426],
              [-2.778217018904006, 52.993701692983159],
              [-2.779736592167636, 52.99336631811174],
              [-2.780505902310042, 52.993727137752821],
              [-2.780387494151455, 52.99401827291252],
              [-2.781026648435014, 52.993853164627346],
              [-2.781587257509555, 52.994206359019337],
              [-2.78147661967671, 52.994679929032905],
              [-2.781142517477851, 52.994659651591519],
              [-2.781622513721602, 52.995333398227601],
              [-2.780848451889855, 52.995454448913797],
              [-2.781238332992359, 52.995593020699971],
              [-2.780701087431483, 52.995709816907194],
              [-2.780945890695732, 52.996147790489552],
              [-2.782031144422872, 52.996262012460512],
              [-2.783666003334735, 52.99537837457283],
              [-2.783841491784087, 52.995771852244019],
              [-2.783913364880316, 52.995215833199765],
              [-2.78441648535131, 52.995025534519108],
              [-2.784092991905401, 52.994604267948013],
              [-2.78500294245961, 52.994173063179403],
              [-2.784902417323402, 52.993887863931043],
              [-2.78653370836185, 52.994291492453115],
              [-2.787790778978288, 52.995334029312176],
              [-2.789998257796088, 52.995710417914744],
              [-2.791776039535759, 52.99458659968257],
              [-2.79149426796847, 52.994332278106327],
              [-2.792105366898781, 52.994264382595631],
              [-2.794254853826122, 52.992862074141435],
              [-2.793931814531684, 52.992388692330834],
              [-2.792063668806861, 52.992067648462736],
              [-2.791938175011577, 52.991071558732337],
              [-2.793346067125743, 52.990668433696953],
              [-2.794455795207851, 52.990741926335083],
              [-2.794791116206114, 52.991397709838019],
              [-2.796193188217118, 52.991650817349026],
              [-2.796330765672404, 52.991355042878638],
              [-2.797226161715707, 52.991276226436646],
              [-2.797617102725427, 52.990582317497378],
              [-2.797168411027682, 52.990567348748328],
              [-2.797666123223801, 52.990412987475949],
              [-2.797584620489906, 52.989951478291417],
              [-2.798202619471865, 52.989453810624774],
              [-2.799383449079284, 52.989667913617495],
              [-2.800518414269975, 52.990703946811095],
              [-2.80100115372158, 52.990546076621492],
              [-2.801043990434871, 52.990928737222887],
              [-2.801776629341956, 52.990750301703542],
              [-2.801810536610417, 52.99040937394247],
              [-2.802150526203262, 52.99058776732754],
              [-2.802724724350208, 52.989738885268153],
              [-2.803420815280023, 52.98959934084305],
              [-2.803900126308969, 52.989979948401555],
              [-2.80350308735468, 52.99058042961704],
              [-2.804181743496704, 52.99102531105607],
              [-2.803840422869032, 52.991497766527772],
              [-2.805700796394566, 52.991798002576942],
              [-2.806702812383984, 52.991525119417823],
              [-2.807156196426864, 52.99187083108783],
              [-2.810237635756659, 52.99174019529277],
              [-2.809839866944183, 52.992536672091497],
              [-2.810146098807995, 52.992739544126067],
              [-2.812816848234138, 52.99279142857619],
              [-2.812646776485876, 52.992461780630734],
              [-2.813089963265528, 52.992340988378572],
              [-2.813388120786773, 52.992906181142246],
              [-2.814091472783939, 52.992755737367084],
              [-2.81410099576306, 52.99310356287107],
              [-2.815222770147465, 52.993099470369792],
              [-2.815368505109925, 52.993480521322972],
              [-2.815931055392648, 52.993447894970075],
              [-2.816214337299317, 52.993774963039769],
              [-2.81719106232385, 52.993583968950844],
              [-2.819488302138003, 52.994285509655036],
              [-2.82082118578989, 52.994412952320623],
              [-2.822517145428553, 52.993496892499358],
              [-2.823671819359658, 52.993576993285515],
              [-2.823870820944688, 52.99330862809073],
              [-2.826300363349582, 52.993755618472001],
              [-2.827862817666698, 52.99435692704359],
              [-2.828304494661003, 52.995403815572551],
              [-2.829310996954083, 52.995368031565384],
              [-2.830953192584146, 52.996082010129079],
              [-2.832137526932702, 52.995995517101704],
              [-2.832204474628177, 52.996532616373692],
              [-2.833378818224314, 52.99685520006642],
              [-2.834058257875447, 52.996701210127206],
              [-2.835996136125815, 52.997134371861762],
              [-2.835542007413375, 52.997459386444319],
              [-2.835968033207477, 52.997762031805614],
              [-2.834899959042585, 52.998238786529804],
              [-2.834898807600394, 52.998642419985345],
              [-2.836172199442408, 53.000616537358688],
              [-2.836197924900293, 53.001562044017646],
              [-2.837063500233718, 53.001937103868229],
              [-2.837358897577637, 53.002878913072045],
              [-2.838042742839245, 53.003025116507494],
              [-2.838469597029243, 53.005211930004997],
              [-2.838812083817396, 53.005197827683645],
              [-2.839868067245062, 53.006931622868784],
              [-2.840621555961821, 53.00720676721096],
              [-2.840856882930197, 53.008425866068549],
              [-2.84075971687838, 53.010404224903361],
              [-2.838735495474139, 53.010163223508926],
              [-2.837770148487114, 53.010648266836007],
              [-2.838522320737205, 53.01092972633284],
              [-2.841251321142845, 53.014299455458328],
              [-2.843527623522238, 53.015379136100726],
              [-2.844279473786308, 53.016477698873381],
              [-2.844062409834306, 53.017652357710126],
              [-2.846223143173767, 53.018831690761793],
              [-2.847765311768574, 53.019022067415122],
              [-2.850280298171535, 53.018042241591019],
              [-2.851627682140421, 53.016971847646552],
              [-2.858702094764184, 53.01988387514416],
              [-2.858735754822247, 53.02075290816088],
              [-2.861044681698488, 53.022832540489659],
              [-2.859714625119314, 53.025671131077118],
              [-2.860388388401955, 53.026412378258797],
              [-2.860496302200876, 53.028537591001104],
              [-2.860097674890467, 53.02933603692658],
              [-2.859184925043082, 53.029926047462745],
              [-2.859284897554021, 53.030529412577224],
              [-2.85829326225855, 53.030903341624963],
              [-2.858243040204109, 53.031452956165715],
              [-2.856134675659042, 53.032965787248656],
              [-2.855512345427116, 53.034522733755935],
              [-2.856643017768681, 53.035966380232459],
              [-2.855715278768716, 53.03723966415901],
              [-2.858199529037516, 53.038046987738142],
              [-2.858229761239193, 53.03851781332083],
              [-2.858946264702164, 53.038924354104203],
              [-2.861873183972986, 53.038587650054929],
              [-2.862350009721, 53.039164008764836],
              [-2.863899800502551, 53.039322659919804],
              [-2.866704396202346, 53.040546384353398],
              [-2.861286950831393, 53.042547225959893],
              [-2.861026168829043, 53.042783737133107],
              [-2.861601476048961, 53.043808854462],
              [-2.862509968604083, 53.044187913532795],
              [-2.864185310027503, 53.043306478427169],
              [-2.866261915963762, 53.043620381970925],
              [-2.866395724962502, 53.044416765377434],
              [-2.865088668580122, 53.044992611059826],
              [-2.864998285809691, 53.045544317387503],
              [-2.869298936122899, 53.04451693662859],
              [-2.869880488742529, 53.044730227454586],
              [-2.870118093959801, 53.045344261999091],
              [-2.869661805699473, 53.045722456651234],
              [-2.868485742906363, 53.045913535088019],
              [-2.865338831149499, 53.047949195148696],
              [-2.863118829861822, 53.048606269200747],
              [-2.863475722280461, 53.049594307437182],
              [-2.863007452993269, 53.051617617527228],
              [-2.863420064279948, 53.052108139197536],
              [-2.864827145069908, 53.051821942629694],
              [-2.865908704230995, 53.050257114357322],
              [-2.866658031858546, 53.049832751744447],
              [-2.867673978466112, 53.04961139727218],
              [-2.86834060144911, 53.050046112525493],
              [-2.868539623036435, 53.050595707909608],
              [-2.867669074823576, 53.051297839383203],
              [-2.866046223742383, 53.051535300570912],
              [-2.86569371038793, 53.051885755502212],
              [-2.866229262100314, 53.052330425776013],
              [-2.866090865921018, 53.052719774368207],
              [-2.862923630648397, 53.053320811765353],
              [-2.86103602703243, 53.052032831624764],
              [-2.85978888509054, 53.052710662398354],
              [-2.859235653046551, 53.054148463733029],
              [-2.860201049200298, 53.054752762292892],
              [-2.866879623129898, 53.055149247690188],
              [-2.86749467085677, 53.05598347120911],
              [-2.867093278572181, 53.056724424806283],
              [-2.865598461447529, 53.057255796646452],
              [-2.862422827870265, 53.056557018950457],
              [-2.860886102252148, 53.05730887457041],
              [-2.860959634045261, 53.057629262421962],
              [-2.86240809920724, 53.058508715544576],
              [-2.865675810240667, 53.058732185619526],
              [-2.867979798886125, 53.056885160948575],
              [-2.870287113449611, 53.056841333029055],
              [-2.871763461723617, 53.057467867709583],
              [-2.872562580472725, 53.058691753227706],
              [-2.871979976278109, 53.059175158724429],
              [-2.869679274951132, 53.059923736584985],
              [-2.868292756001588, 53.059537435261447],
              [-2.865800179635865, 53.060692758278584],
              [-2.864678046774229, 53.060766543081236],
              [-2.862418956982633, 53.060097954996699],
              [-2.861712434499078, 53.060285559391488],
              [-2.861512960664852, 53.060758046448704],
              [-2.862660357260829, 53.061649564869533],
              [-2.862883949639845, 53.062533394296132],
              [-2.86392188815487, 53.063245907472222],
              [-2.866682395929161, 53.063236613543047],
              [-2.869626554245759, 53.063750891386832],
              [-2.870433490948867, 53.064175579418396],
              [-2.870201003023138, 53.064783162856493],
              [-2.865508276135908, 53.066004892983671],
              [-2.867685767215264, 53.066902347561189],
              [-2.869357457284365, 53.069728079132993],
              [-2.87053114738495, 53.070201324893254],
              [-2.872318484846607, 53.070314981741738],
              [-2.876213377881566, 53.068964023791082],
              [-2.877652386541241, 53.068903974308633],
              [-2.878198295826213, 53.0694743650212],
              [-2.878047895582458, 53.070664766310742],
              [-2.875632312512706, 53.07232312703627],
              [-2.875362267945786, 53.07328517642803],
              [-2.875829182197092, 53.07356580311351],
              [-2.880798287959592, 53.073939030255289],
              [-2.881750221711055, 53.074578312471068],
              [-2.881254460130524, 53.075518675720126],
              [-2.879519633603574, 53.0762353795214],
              [-2.879272071132611, 53.077857987662973],
              [-2.877249410433168, 53.079181767005757],
              [-2.875721666935843, 53.081505977826922],
              [-2.87991242081841, 53.0835641784073],
              [-2.891050578531487, 53.087495021156563],
              [-2.891819294873209, 53.088205711616141],
              [-2.891914323753888, 53.089984886954873],
              [-2.893575553165499, 53.091536566112438],
              [-2.895788877798313, 53.091839943791314],
              [-2.900943156715294, 53.090817594982262],
              [-2.901853633453419, 53.091655694978698],
              [-2.902159638975101, 53.092792323836427],
              [-2.901445917760116, 53.093438672484012],
              [-2.899148049690772, 53.094099694744479],
              [-2.899399252019811, 53.097909270675679],
              [-2.897138240294982, 53.098990674394081],
              [-2.894837237792397, 53.098798548930553],
              [-2.89395337589949, 53.098092339254194],
              [-2.894222065726067, 53.097365781171717],
              [-2.895301699552087, 53.096999887913306],
              [-2.897554312463131, 53.097024268804809],
              [-2.897819077390072, 53.096399311229668],
              [-2.896790531215546, 53.095198902102084],
              [-2.893486069320408, 53.096226070314259],
              [-2.890350467909559, 53.099652030279096],
              [-2.887302965934123, 53.104406765678391],
              [-2.887243387623491, 53.105358278363333],
              [-2.887950344063482, 53.105741343393696],
              [-2.890958406500606, 53.104930498305663],
              [-2.892813618490231, 53.104066205386125],
              [-2.893378698652168, 53.104160846911761],
              [-2.893079050824295, 53.105774879156073],
              [-2.891628945512433, 53.107595300020925],
              [-2.891715149814761, 53.109379030703955],
              [-2.891008609190889, 53.109526353611002],
              [-2.889901130334918, 53.108922468766487],
              [-2.88862957727201, 53.108908598103575],
              [-2.887804704068022, 53.109258145826814],
              [-2.886586949359878, 53.110843047882561],
              [-2.883877966913271, 53.111327944693329],
              [-2.884024882459251, 53.113755760027381],
              [-2.881618652025677, 53.11824038469225],
              [-2.880941323864696, 53.121359286130101],
              [-2.881411539007754, 53.121773806257295],
              [-2.88211664747564, 53.121764087914258],
              [-2.884092853279861, 53.120635653562481],
              [-2.885236166333793, 53.12042759349341],
              [-2.886498743138631, 53.121368363179592],
              [-2.887191112445625, 53.12081306028518],
              [-2.887971725388046, 53.120697569770101],
              [-2.888032527785717, 53.11980537928423],
              [-2.891702574898421, 53.118733384706388],
              [-2.892345860909812, 53.118338428300085],
              [-2.892061496464025, 53.117954919509906],
              [-2.895120711235715, 53.116476584474952],
              [-2.895179660148695, 53.114999200052395],
              [-2.897000592090629, 53.113978688503956],
              [-2.899414529219108, 53.11403058679101],
              [-2.903390801711252, 53.111781910432619],
              [-2.906058114749148, 53.112237167787825],
              [-2.906783919718803, 53.112792572636224],
              [-2.908021415550286, 53.11309866209109],
              [-2.909404520762426, 53.113082707890349],
              [-2.909665744506236, 53.112647426855872],
              [-2.910231454637033, 53.112623325037475],
              [-2.911951697338133, 53.113078498846839],
              [-2.912690255496114, 53.113596914012355],
              [-2.913794070166503, 53.113654964839832],
              [-2.914378864060991, 53.114318379051994],
              [-2.917296067143062, 53.114778656613339],
              [-2.917096843665996, 53.115245838231033],
              [-2.919724269328495, 53.116055276508263],
              [-2.919961001602861, 53.116786075676245],
              [-2.921304286893319, 53.117278187433634],
              [-2.920835372816613, 53.118408176737319],
              [-2.923137012411972, 53.118087407265705],
              [-2.923966092376862, 53.11856639661282],
              [-2.928557663150261, 53.119810741082446],
              [-2.929042188005432, 53.120313957182482],
              [-2.931923983423289, 53.120078381265529],
              [-2.934409216223244, 53.121470218572995],
              [-2.93554148096583, 53.121451435926865],
              [-2.937644062783931, 53.120838899064488],
              [-2.940401711347453, 53.122377688189367],
              [-2.94138129008082, 53.12220903581148],
              [-2.94237023440929, 53.121375990547328],
              [-2.943669419772144, 53.121610206607009],
              [-2.944979282082683, 53.12226232713725],
              [-2.945009814589499, 53.123177197396863],
              [-2.946904642129463, 53.127254974089148],
              [-2.947997079922552, 53.127796424589135],
              [-2.952348408855608, 53.128576121894824],
              [-2.955584181761417, 53.129927352604646],
              [-2.958169734490374, 53.130393789129975],
              [-2.960157471603206, 53.132467788438703],
              [-2.96381211591608, 53.132742092756466],
              [-2.97949724460025, 53.150606178137487],
              [-2.979857384479849, 53.15046477945986],
              [-2.980602910219729, 53.150765176715218],
              [-2.980363073888831, 53.149361224575642],
              [-2.982649554815766, 53.148847064094731],
              [-2.984564115036935, 53.147764215560947],
              [-2.984983558180984, 53.148778336107206],
              [-2.98568774698141, 53.148775204924846],
              [-2.986402964306544, 53.149448873294283],
              [-2.986395883427841, 53.150379325484259],
              [-2.987314422969638, 53.151049505806085],
              [-2.987698453382361, 53.152080091000677],
              [-2.990425656202485, 53.153039967092731],
              [-2.992928333670955, 53.153272620701578],
              [-2.994262267818224, 53.153597689037746],
              [-2.99508775717507, 53.154180491499233],
              [-2.993058827855106, 53.1549093843443],
              [-2.992273459870802, 53.15664907015659],
              [-2.990506466677586, 53.156939754301106],
              [-2.990691898258247, 53.157198901334894],
              [-2.989956162294607, 53.157526837672172],
              [-2.988123469446278, 53.156912809965227],
              [-2.986665368268052, 53.157957772031907],
              [-2.98463965020774, 53.15857952059222],
              [-2.983613154558944, 53.158033365247533],
              [-2.98375560216913, 53.157591712924173],
              [-2.981491238247865, 53.156276388437746],
              [-2.978912375043217, 53.157169580158381],
              [-2.975196880423671, 53.157407721708559],
              [-2.970925154142718, 53.160766868994678],
              [-2.968369210123859, 53.160573738160913],
              [-2.966262011794713, 53.159849241131823],
              [-2.962371515303811, 53.162306055465173],
              [-2.956356710358013, 53.163761336532964],
              [-2.953477875418389, 53.165335970018674],
              [-2.951024569311795, 53.165937194029453],
              [-2.948906173771208, 53.165991848788103],
              [-2.946552103904973, 53.167096486621865],
              [-2.944039990071848, 53.165232281074204],
              [-2.940303208700485, 53.166726196654608],
              [-2.933906052873659, 53.169373532400911],
              [-2.92784019463806, 53.17139498595062],
              [-2.926588091954308, 53.173341914630988],
              [-2.922848979167745, 53.175275766307855],
              [-2.920954386391645, 53.177070298968353],
              [-2.920317803599831, 53.179414214906579],
              [-2.920317861331466, 53.17941691122568],
              [-2.92045439828486, 53.182159364610705],
              [-2.919659223193591, 53.182341696097815],
              [-2.905229983657134, 53.192883304427014],
              [-2.904000070826006, 53.192121373099873],
              [-2.903618339107068, 53.191050960687981],
              [-2.903805895049059, 53.189507891597557],
              [-2.905183941695742, 53.187074839002257],
              [-2.905065878613893, 53.185874779599409],
              [-2.90416459152289, 53.184505379006445],
              [-2.902242352072633, 53.183399902351788],
              [-2.900272122895641, 53.182927597161616],
              [-2.892701826303999, 53.183364823873177],
              [-2.88827681250034, 53.185172375003944],
              [-2.886682396634992, 53.186301611456585],
              [-2.887633528938515, 53.186269357023569],
              [-2.889678728200778, 53.185808233542126],
              [-2.892713657599018, 53.184078477020577],
              [-2.900021160672032, 53.18366301245392],
              [-2.902318164541742, 53.18415981418508],
              [-2.903369824441944, 53.18485479728686],
              [-2.90411790198349, 53.185700397814138],
              [-2.904248461504734, 53.186784402609526],
              [-2.902483630312903, 53.189829842785429],
              [-2.902524336548507, 53.192551460323891],
              [-2.905745798415565, 53.193637171600933],
              [-2.910268642428866, 53.190435800957125],
              [-2.920352812221034, 53.182935918750246],
              [-2.922298079453968, 53.189277140150587],
              [-2.925161496955567, 53.191030300895726],
              [-2.929236868050628, 53.194275141162237],
              [-2.935652258497318, 53.196474917795321],
              [-2.937565224103939, 53.197843314507409],
              [-2.943675027444461, 53.204010202420065],
              [-2.949509663214089, 53.211263965827797],
              [-2.966162980743964, 53.21867380846092],
              [-2.9778373513115, 53.22537799443932],
              [-2.984912218135511, 53.228455154531666],
              [-2.992989962778465, 53.233198230352677],
              [-3.016423894271365, 53.244105908035486],
              [-3.02546315269135, 53.248299239027126],
              [-3.036064035182857, 53.251790650005809],
              [-3.048671666798665, 53.252193669908841],
              [-3.061117324368377, 53.252703771068468],
              [-3.069137437637665, 53.252988970121621],
              [-3.073914972686116, 53.253610322374932],
              [-3.073822417884371, 53.254702448283972],
              [-3.072288329645006, 53.255574730468631],
              [-3.070393920312689, 53.254626316048096],
              [-3.070788369130903, 53.254395345645626],
              [-3.069799922585275, 53.254789859367662],
              [-3.069477200603691, 53.255432788090047],
              [-3.07043401642783, 53.254791357636833],
              [-3.072553592611424, 53.25593191263156],
              [-3.072417082440954, 53.256287316296486],
              [-3.071092019610699, 53.256349570808432],
              [-3.070998572409978, 53.257109100653537],
              [-3.071463375087938, 53.256333648183045],
              [-3.072526366242693, 53.256401394999877],
              [-3.073036394183919, 53.256775250696698],
              [-3.072747357997854, 53.255708135050789],
              [-3.074278355824152, 53.254712722429545],
              [-3.074525961138669, 53.253707292799163],
              [-3.082425274183596, 53.255489394996104],
              [-3.084596712980952, 53.257539875173357],
              [-3.086753834728558, 53.257894178815945],
              [-3.088064032837553, 53.25771772276925],
              [-3.091828689891074, 53.259739134987299],
              [-3.090064219296211, 53.25962134497405],
              [-3.08919649812859, 53.260103908683028],
              [-3.088346333735114, 53.259684685973689],
              [-3.089872050173339, 53.260553487604888],
              [-3.089671312970745, 53.261207940918752],
              [-3.089039311623845, 53.261410580955761],
              [-3.089861948288371, 53.261276314003872],
              [-3.089898089969466, 53.259808941232166],
              [-3.092190735378378, 53.259945267216352],
              [-3.093140074606272, 53.260489402433976],
              [-3.091820312045193, 53.261822915500971],
              [-3.09050293444899, 53.261957228581025],
              [-3.089586723243282, 53.262539119878099],
              [-3.088385856285167, 53.261890281693852],
              [-3.085862737879614, 53.261595973785894],
              [-3.08601540900521, 53.260876343764828],
              [-3.084820986873835, 53.260418881689972],
              [-3.084162525762474, 53.259338974140171],
              [-3.083111185574194, 53.259379994516983],
              [-3.082775187244912, 53.259787562369063],
              [-3.082068949325722, 53.259425417578854],
              [-3.081560780650559, 53.259961292879858],
              [-3.08217613262522, 53.259514336750961],
              [-3.082732266901104, 53.259928184159214],
              [-3.083237673658513, 53.259520874830351],
              [-3.084097831927827, 53.259448332203384],
              [-3.084580131833125, 53.260379723071139],
              [-3.085852675168899, 53.260902996177755],
              [-3.08576593167103, 53.26168045542137],
              [-3.088480162630082, 53.262002684806419],
              [-3.089622966308652, 53.26266733451687],
              [-3.090453056698033, 53.262177022051659],
              [-3.092224268682269, 53.261790449137038],
              [-3.09332461671719, 53.260612660198817],
              [-3.0950924836677, 53.261800089681195],
              [-3.093448107146979, 53.262632306609078],
              [-3.094174748926686, 53.262554623746468],
              [-3.09520269752564, 53.261888969396971],
              [-3.097356343971181, 53.263559137771473],
              [-3.099529075299117, 53.265334660958217],
              [-3.101358248386715, 53.266898584756298],
              [-3.100416581992333, 53.267830475155534],
              [-3.099379695788378, 53.26791645087529],
              [-3.099186851230312, 53.26735460468899],
              [-3.098868586262045, 53.267865430324811],
              [-3.0977811353856, 53.267558130135477],
              [-3.098960112799738, 53.268569341913846],
              [-3.099296668747614, 53.268479043234969],
              [-3.099895299904889, 53.269836287877894],
              [-3.099143537276343, 53.271043283114004],
              [-3.096680644165268, 53.272305579628124],
              [-3.096175550140292, 53.27196414027928],
              [-3.095521234553655, 53.272413323547916],
              [-3.094511292444053, 53.272208657065228],
              [-3.093855729727479, 53.271082931787262],
              [-3.093958351664432, 53.270286444257884],
              [-3.093242373140648, 53.270199524682702],
              [-3.093610934605691, 53.269834777470997],
              [-3.094430239921389, 53.269857821690977],
              [-3.094780696811507, 53.269371882521789],
              [-3.093212868446185, 53.269924725170206],
              [-3.092502055442127, 53.269746063774321],
              [-3.092000946988247, 53.269264338515192],
              [-3.092400387685894, 53.268228716295972],
              [-3.092046821821079, 53.267652151107619],
              [-3.09247896944927, 53.267194235669614],
              [-3.091739068411788, 53.264518630064593],
              [-3.090620525587291, 53.264339196699481],
              [-3.088818827824577, 53.263412698020105],
              [-3.087870117207086, 53.263484284733863],
              [-3.08660333135512, 53.263089921911636],
              [-3.087887616072261, 53.263583006491288],
              [-3.088785571376322, 53.263519973566801],
              [-3.089814752969583, 53.264195547381682],
              [-3.091592768359591, 53.264661100747752],
              [-3.091520288642014, 53.266642090452883],
              [-3.09094309588591, 53.267115712716915],
              [-3.089587181461331, 53.267333068392063],
              [-3.088298109462281, 53.267112922331293],
              [-3.087352213576231, 53.265585296765302],
              [-3.084639578455921, 53.265216282550448],
              [-3.084741021524753, 53.263952374109003],
              [-3.084140057649741, 53.262954644430444],
              [-3.083271969018395, 53.262775560927132],
              [-3.082458398539474, 53.263086208568588],
              [-3.083222256761728, 53.262884782271449],
              [-3.084058281070682, 53.263100114640217],
              [-3.084468404344592, 53.265324811671142],
              [-3.087204408371707, 53.265846433397009],
              [-3.087413554210114, 53.266820754880229],
              [-3.088238360289413, 53.26753506215627],
              [-3.090857013511163, 53.268681523684009],
              [-3.089309048555699, 53.269780677528558],
              [-3.089943748265454, 53.269502502738774],
              [-3.090236427791377, 53.269690397727707],
              [-3.088964380146615, 53.271322780088866],
              [-3.087849636158946, 53.271063281559705],
              [-3.086483456471796, 53.271354406435265],
              [-3.085969335986653, 53.271185599288479],
              [-3.085676521538934, 53.269216932182488],
              [-3.084485763399971, 53.268439417080224],
              [-3.084299579183835, 53.267066659857839],
              [-3.084208163523057, 53.268549812807791],
              [-3.085493371570787, 53.269445128255086],
              [-3.08438868276592, 53.270172521461213],
              [-3.085407706681566, 53.270384382050821],
              [-3.084933011736175, 53.271120425019802],
              [-3.082683400890435, 53.271285603946943],
              [-3.081700166919158, 53.270175373424124],
              [-3.079335593559405, 53.270001740456074],
              [-3.078060583101191, 53.269090086361089],
              [-3.079115531405918, 53.270075646403058],
              [-3.081759809500212, 53.270399562426533],
              [-3.082448390704363, 53.271480107169232],
              [-3.084026081588411, 53.271309358725937],
              [-3.085509049643336, 53.272016798818825],
              [-3.087950199537342, 53.272602215778697],
              [-3.089876104636909, 53.272914527996761],
              [-3.091775230641182, 53.274293175009262],
              [-3.092489735894921, 53.275201731006483],
              [-3.092364864881527, 53.276126966037651],
              [-3.091023849367144, 53.275880356956762],
              [-3.089931169815987, 53.274548263038234],
              [-3.088244262514171, 53.274614912451256],
              [-3.088217952541427, 53.274936966251296],
              [-3.086520356606198, 53.275587987114861],
              [-3.08492268977252, 53.274502258887587],
              [-3.083713971724821, 53.274027835869219],
              [-3.083735990830891, 53.273357040979015],
              [-3.083509048629724, 53.274107904644126],
              [-3.084662886988697, 53.274665529752973],
              [-3.08618534054108, 53.275801387358243],
              [-3.088629193026681, 53.275245137123761],
              [-3.089984228134167, 53.275572547718937],
              [-3.091318612670418, 53.277148732965017],
              [-3.092711011058984, 53.277291492008899],
              [-3.092634964113601, 53.277543886820389],
              [-3.091398075670415, 53.277795228384704],
              [-3.091817909806568, 53.278320848422482],
              [-3.089926416022319, 53.278545806605734],
              [-3.084494035447526, 53.277414166236703],
              [-3.079010068405513, 53.278211827722679],
              [-3.078489342342246, 53.277901917725842],
              [-3.078810257288545, 53.276709743547549],
              [-3.080006585781234, 53.275859320597668],
              [-3.080111296820703, 53.27537295516786],
              [-3.07707792174794, 53.274019658808712],
              [-3.07989572145354, 53.275566378087014],
              [-3.079237967372414, 53.276126069102006],
              [-3.078229132172549, 53.2760848581513],
              [-3.078328444162511, 53.277289411138511],
              [-3.077666694742045, 53.277811374662555],
              [-3.078338281638443, 53.278633206748729],
              [-3.078425831188852, 53.278953329032234],
              [-3.077521795682853, 53.279145781733376],
              [-3.074297722390511, 53.278807219957429],
              [-3.073475988406532, 53.276958354025474],
              [-3.07177542215836, 53.275045472847289],
              [-3.069707217299178, 53.274918423873117],
              [-3.067432488113239, 53.275349619296946],
              [-3.065247747204561, 53.273370864498837],
              [-3.064176562122257, 53.273231210976178],
              [-3.065189538124126, 53.27356285437758],
              [-3.066909579067502, 53.275489138049785],
              [-3.068109221510022, 53.275846053675721],
              [-3.070818554457015, 53.275641964742128],
              [-3.071660022831987, 53.275827671116765],
              [-3.073092190962082, 53.277262947197499],
              [-3.072587036118438, 53.278590701859038],
              [-3.074268835228584, 53.279329752173851],
              [-3.074103698942072, 53.279622489796459],
              [-3.075209465306063, 53.279279922307886],
              [-3.076910509107759, 53.279528851108061],
              [-3.07899784574369, 53.2798102169698],
              [-3.081836014373935, 53.279414141569568],
              [-3.080745287111319, 53.28041014874114],
              [-3.080887362791023, 53.280750450060019],
              [-3.081729763784459, 53.280136039095979],
              [-3.081597439090372, 53.281310329376197],
              [-3.082175606783988, 53.280804384691194],
              [-3.082231146192739, 53.279559776010188],
              [-3.082865604929278, 53.279323891523276],
              [-3.08388079288162, 53.279435121527825],
              [-3.085675705370401, 53.280722223289835],
              [-3.085939839445498, 53.281793127709776],
              [-3.087119484956264, 53.282412519691469],
              [-3.085551996793078, 53.28306054044392],
              [-3.088048626015243, 53.282734846252424],
              [-3.089649615455992, 53.283700047108177],
              [-3.089539305795949, 53.284139727958426],
              [-3.090639427172776, 53.284518002698391],
              [-3.089134385193935, 53.286088689045435],
              [-3.08923614303014, 53.287140393735918],
              [-3.086544913970122, 53.287964987167939],
              [-3.08374124547116, 53.290040936660972],
              [-3.087178484196322, 53.288044609039815],
              [-3.088753150602517, 53.287554712254639],
              [-3.088891231646159, 53.287793462633495],
              [-3.086969552936362, 53.288619125147918],
              [-3.084870457134632, 53.290260790651914],
              [-3.086865413054393, 53.288889750072968],
              [-3.089463147185092, 53.287690255964769],
              [-3.09038180830542, 53.285652098599691],
              [-3.091378435885699, 53.284851930719391],
              [-3.092741508479668, 53.284834950984056],
              [-3.09225322809774, 53.284510420613273],
              [-3.093279436616745, 53.284401234792782],
              [-3.094496288055736, 53.284826046396866],
              [-3.094767768017011, 53.284414545360995],
              [-3.096314755036403, 53.284316730849675],
              [-3.096759047357092, 53.284972451935111],
              [-3.095129322956346, 53.285824326882349],
              [-3.094338540744056, 53.285696749804508],
              [-3.094512249728417, 53.287386921911207],
              [-3.093675974392549, 53.287534827304206],
              [-3.093353709141665, 53.288073538855308],
              [-3.093639360834434, 53.287687080517955],
              [-3.09461067936556, 53.287599062206304],
              [-3.095111327314215, 53.286059110219306],
              [-3.097339947360224, 53.286509653449301],
              [-3.097591443617044, 53.286957697316922],
              [-3.098900844549179, 53.286775740254051],
              [-3.097475058477301, 53.288854590163275],
              [-3.098111133666555, 53.288796596324438],
              [-3.097711058702203, 53.290160345390447],
              [-3.099264838566738, 53.287047454355516],
              [-3.099695742882338, 53.286828638867568],
              [-3.101195813276313, 53.286886707278796],
              [-3.101812813218145, 53.287487778151323],
              [-3.103078849153504, 53.287718780306314],
              [-3.103366007504651, 53.288092770794115],
              [-3.104026198198296, 53.288039915668399],
              [-3.103855491360793, 53.288575454772996],
              [-3.104539637716492, 53.288694967714619],
              [-3.102906954980165, 53.289839124850644],
              [-3.101682655243438, 53.290183043675938],
              [-3.101310791334121, 53.290715043738736],
              [-3.104476936586948, 53.289171976192854],
              [-3.106738220096874, 53.290063411821009],
              [-3.108450229104505, 53.290306397417837],
              [-3.108749294967924, 53.29050048030939],
              [-3.10826247652117, 53.290761198476105],
              [-3.105578845757486, 53.291119611557384],
              [-3.104494944302802, 53.290916612938169],
              [-3.103362043903696, 53.29143139583288],
              [-3.108568989578782, 53.291126906337581],
              [-3.108014938473254, 53.291688487782672],
              [-3.108865484230494, 53.291858567043541],
              [-3.108503292088042, 53.29258736321114],
              [-3.10958549085233, 53.293127436411552],
              [-3.108421681327235, 53.293896949106056],
              [-3.104469461658736, 53.294173634511829],
              [-3.10568029420052, 53.294350286902976],
              [-3.10432647004401, 53.294853642951388],
              [-3.102840663995001, 53.294370292168757],
              [-3.102336092671194, 53.293769985324722],
              [-3.101165115460344, 53.293508432627092],
              [-3.100238908590821, 53.293663506428523],
              [-3.098701509986264, 53.294498395443135],
              [-3.096397034753622, 53.294207682605276],
              [-3.096381506333872, 53.294538627428494],
              [-3.096963279101383, 53.294407428080255],
              [-3.098661561554952, 53.294754056457919],
              [-3.100907163618754, 53.293920721650956],
              [-3.102614662171985, 53.294508119042852],
              [-3.102622455420205, 53.294985373002298],
              [-3.103356398092081, 53.294762843269908],
              [-3.103762560941884, 53.295269670279815],
              [-3.102655024410743, 53.295900171337401],
              [-3.101521553617319, 53.295582542173769],
              [-3.100462720823681, 53.295657939901361],
              [-3.098699783121295, 53.296710650043146],
              [-3.097455371339727, 53.296747279392484],
              [-3.096657034659704, 53.297152845113786],
              [-3.098889921464309, 53.296986663736504],
              [-3.100455944548953, 53.295919587789491],
              [-3.1020786493302, 53.29635765604948],
              [-3.103355704633056, 53.296365622283936],
              [-3.104870174957338, 53.295282784188274],
              [-3.105984165484278, 53.29519425078869],
              [-3.106437016946932, 53.295648498422032],
              [-3.105978362007642, 53.295840627554334],
              [-3.106877065420249, 53.296709633643651],
              [-3.103881666388532, 53.297968021162035],
              [-3.103538125308182, 53.299134401631008],
              [-3.103873708656446, 53.299230176561835],
              [-3.103992238940811, 53.298589948130903],
              [-3.105157329107279, 53.297745857970639],
              [-3.107038494883685, 53.297323894632171],
              [-3.10896059534408, 53.29634059507822],
              [-3.10929676956294, 53.296633212601243],
              [-3.109013953035339, 53.297241714908381],
              [-3.095908086793995, 53.30470254825525],
              [-3.095133406189998, 53.304103794917957],
              [-3.095232570534262, 53.302994519361121],
              [-3.094710497452859, 53.303880251928526],
              [-3.093534502833066, 53.303313936988872],
              [-3.092269953424987, 53.301863887283432],
              [-3.08784630646131, 53.298976551822221],
              [-3.084737059444146, 53.296185872395846],
              [-3.087683398374851, 53.299120066809792],
              [-3.091538431310429, 53.301600011499424],
              [-3.092751984050666, 53.303052334049518],
              [-3.095523173213636, 53.304908341278022],
              [-3.089133341014436, 53.30833511020834],
              [-3.082080833310038, 53.311178755990923],
              [-3.08097647402709, 53.311904307260086],
              [-3.080340519973589, 53.313289004791322],
              [-3.078140958162535, 53.315051903861388],
              [-3.076391633748861, 53.314432172679709],
              [-3.074166100832532, 53.316365126275855],
              [-3.07265028365223, 53.315838521875307],
              [-3.068272181761396, 53.314178871467895],
              [-3.064227843706483, 53.312165507215461],
              [-3.059659761094912, 53.308988074271582],
              [-3.051210467558391, 53.310656623446533],
              [-3.048391326162295, 53.310944820831999],
              [-3.048164652315214, 53.309595748209105],
              [-3.045861225563562, 53.307340823995027],
              [-3.042315628462625, 53.306509809328638],
              [-3.041621472583241, 53.306106869386454],
              [-3.042067278060812, 53.30586386395273],
              [-3.04165751789968, 53.305183371537581],
              [-3.03924577500996, 53.305426444912712],
              [-3.038474827882509, 53.305134722155792],
              [-3.036577331011335, 53.302384379108439],
              [-3.036597073806617, 53.300969319150667],
              [-3.031354953163119, 53.29851399885009],
              [-3.030509021870894, 53.299213475043125],
              [-3.029760567647982, 53.298976335587845],
              [-3.02614007428947, 53.297732888822026],
              [-3.023984075079766, 53.299369464358293],
              [-3.022001851086194, 53.29842463418391],
              [-3.021268215557675, 53.29899453738863],
              [-3.018553540109922, 53.29999127747606],
              [-3.01283751631574, 53.298954979384838],
              [-3.011238473053078, 53.300118257628171],
              [-3.005374446034029, 53.301397551432501],
              [-3.00218107438194, 53.302544473672008],
              [-2.995334437624571, 53.30560507201978],
              [-2.992738194777204, 53.307092832809516],
              [-2.984738547238289, 53.305309205987292],
              [-2.98016413348827, 53.304643047537176],
              [-2.979528056197957, 53.30425904698005],
              [-2.974134406712379, 53.303193977107405],
              [-2.97126831613102, 53.302009224152812],
              [-2.969073256338395, 53.301823929665112],
              [-2.968033626199104, 53.301239095044302],
              [-2.967239521011602, 53.301557459677824],
              [-2.967970216247345, 53.302018063401754],
              [-2.96532976026149, 53.302975229669158],
              [-2.961498967415861, 53.303790025251352],
              [-2.960026444740986, 53.30330479381535],
              [-2.95827656598326, 53.304102714736921],
              [-2.954685487122882, 53.304237589019429],
              [-2.951623236722734, 53.303860254525368],
              [-2.949382067908451, 53.303152701491477],
              [-2.946587530409784, 53.305510272769055],
              [-2.939551511846886, 53.310399322815023],
              [-2.931602739665276, 53.306052559412912],
              [-2.9285720624631, 53.308232666093723],
              [-2.920016067339569, 53.305305633565951],
              [-2.917708001264807, 53.306075799593927],
              [-2.914778361172279, 53.305644362974924],
              [-2.9113566055677, 53.304158608182007],
              [-2.907696564156724, 53.301473635700212],
              [-2.905855539637768, 53.301127175353571],
              [-2.903626583651321, 53.298823120115919],
              [-2.901575970096555, 53.296497898962215],
              [-2.89903973454139, 53.294899023146982],
              [-2.89393858752191, 53.291953018248009],
              [-2.892569208865031, 53.291537197021839],
              [-2.8903665824086, 53.29000755193244],
              [-2.887810808992687, 53.289163662925048],
              [-2.884947107341715, 53.288875729589478],
              [-2.888171050404714, 53.28946301143317],
              [-2.89066549289132, 53.290458366665462],
              [-2.895525185330416, 53.293499810579981],
              [-2.896777016538268, 53.29395783171239],
              [-2.895750346622161, 53.294437472978295],
              [-2.897747825962765, 53.294678637839247],
              [-2.900795740142246, 53.296496604545858],
              [-2.900534632566039, 53.296993872343549],
              [-2.896259490787966, 53.296612587556666],
              [-2.888319980828172, 53.295194204985968],
              [-2.887576592036698, 53.293640145209899],
              [-2.885850635608217, 53.292812506724665],
              [-2.882739852422075, 53.291757793661617],
              [-2.878389882136273, 53.290766968443364],
              [-2.871132370752231, 53.290859765880406],
              [-2.861045561475759, 53.292747017771177],
              [-2.855568331491282, 53.29200619999564],
              [-2.854926863909278, 53.291511911354725],
              [-2.858536575806186, 53.288978939063611],
              [-2.858345377718848, 53.288121864929686],
              [-2.857237848997424, 53.286900135997165],
              [-2.857114644299765, 53.287339685673636],
              [-2.857982923918221, 53.288524484834944],
              [-2.857798017828377, 53.289103808786592],
              [-2.857237038788254, 53.289481786307647],
              [-2.856137189639912, 53.289394405408167],
              [-2.856662158221911, 53.289615359941045],
              [-2.855544063258644, 53.290791959294658],
              [-2.851969299677189, 53.29306211063539],
              [-2.854190431515194, 53.293099254319046],
              [-2.855070808822042, 53.293836334669997],
              [-2.853962046501857, 53.295262745165708],
              [-2.84898103365759, 53.29678149888592],
              [-2.841177026417242, 53.298132121351465],
              [-2.839601117951947, 53.298721219934677],
              [-2.840026231091739, 53.299357342722978],
              [-2.847842955238047, 53.301740732358162],
              [-2.848288221779016, 53.302098922897699],
              [-2.847194965582722, 53.30410314844498],
              [-2.847946600652909, 53.305607955060935],
              [-2.847746014594092, 53.306239508706589],
              [-2.846383484864554, 53.306928753832338],
              [-2.842997455535071, 53.307520841231835],
              [-2.835655687796269, 53.308288935327084],
              [-2.832179286345887, 53.308260210379444],
              [-2.821770198471339, 53.307266302965289],
              [-2.815955934831747, 53.306147551074964],
              [-2.814716662908932, 53.305770397920547],
              [-2.813391164952879, 53.304882348336861],
              [-2.807090602588907, 53.303093208668749],
              [-2.800416522793592, 53.298955632431088],
              [-2.78949003577693, 53.296835172447572],
              [-2.78972409824615, 53.296510020002998],
              [-2.788966103567915, 53.295435464120743],
              [-2.788749681515686, 53.296717822357394],
              [-2.785846459529336, 53.297617909211368],
              [-2.761381405953362, 53.310054616303752],
              [-2.760151888622243, 53.310374375418959],
              [-2.759447859148336, 53.310113687920577],
              [-2.759068416878287, 53.310636562097862],
              [-2.755891758595914, 53.311269783762725],
              [-2.754652257787694, 53.311793597526275],
              [-2.752391941854765, 53.313588582708554],
              [-2.752431786116932, 53.314744304939566],
              [-2.747359416896927, 53.312399533136535],
              [-2.742919239257003, 53.309264079937407],
              [-2.740819054201019, 53.308193082813396],
              [-2.739413110790199, 53.306907405580539],
              [-2.737692945211585, 53.30662771746217],
              [-2.735454281249252, 53.306739524443699],
              [-2.734338976578166, 53.30724887779985],
              [-2.73288739268686, 53.310556735997309],
              [-2.731046719633172, 53.311838165606829],
              [-2.72815194258491, 53.312395209786175],
              [-2.724148318877399, 53.31244565596576],
              [-2.723524908586136, 53.313124505788387],
              [-2.715214842127217, 53.311731018040945],
              [-2.707456347631509, 53.309442882677907],
              [-2.70120353351681, 53.30603167616264],
              [-2.700776533419697, 53.305799576240503],
              [-2.69861082916996, 53.30689005178985],
              [-2.693303288437143, 53.310072530731262],
              [-2.693506874401857, 53.310225056803347],
              [-2.687579392978881, 53.313360543139297],
              [-2.687249264148494, 53.314019532669839],
              [-2.685380025281422, 53.315298619747495],
              [-2.678164314427719, 53.31414525077615],
              [-2.670318595289944, 53.312417005038853],
              [-2.648368268153269, 53.308105064583863],
              [-2.643999101559624, 53.30648198184646],
              [-2.641558027417811, 53.305020068441827],
              [-2.643210665700496, 53.307043546684042],
              [-2.645062442997616, 53.310120317519804],
              [-2.639847855594655, 53.309373572349685],
              [-2.639048126000206, 53.309588200008797],
              [-2.638866393911712, 53.31008176293134],
              [-2.635724734760845, 53.307934037254171],
              [-2.63250977506404, 53.307506185560726],
              [-2.629891715973637, 53.308066566127515],
              [-2.629967795804742, 53.307831555196628],
              [-2.629458032837662, 53.307763235271963],
              [-2.627519764041925, 53.308630088571448],
              [-2.624123963898022, 53.309383198183561],
              [-2.624715549175124, 53.311764840967477],
              [-2.622734772175603, 53.311999023540331],
              [-2.61780228672217, 53.316119993653444],
              [-2.619834861782668, 53.316855519608609],
              [-2.617472195392597, 53.317585085124129],
              [-2.617869704700457, 53.318188873452115],
              [-2.621028312765904, 53.319136069457919],
              [-2.619935917479168, 53.320305806414794],
              [-2.618538999090656, 53.320250137964578],
              [-2.616739779779364, 53.320611818105661],
              [-2.61351915848877, 53.318900777547931],
              [-2.610071897911296, 53.318015085260221],
              [-2.609832921300387, 53.317678327657752],
              [-2.610463902688303, 53.317399142330551],
              [-2.609906914761062, 53.316867156986184],
              [-2.610124108929098, 53.316308738584389],
              [-2.609717213081003, 53.31619576264211],
              [-2.609359388554323, 53.314996683897732],
              [-2.608284574921242, 53.31477924821867],
              [-2.61010004022928, 53.31272771104107],
              [-2.60942327401877, 53.312106447573179],
              [-2.609043085464786, 53.312074232372005],
              [-2.609143070003257, 53.312666085884288],
              [-2.607999649566425, 53.313847660554323],
              [-2.60653101108498, 53.314010646201815],
              [-2.606414780109956, 53.314811243230366],
              [-2.60471789592231, 53.315501213358381],
              [-2.603574242605808, 53.317956462252518],
              [-2.602364270119955, 53.318483931777401],
              [-2.599368977412507, 53.318659923442361],
              [-2.598765045949667, 53.319054869792268],
              [-2.596744248878183, 53.319359832049585],
              [-2.595255315747468, 53.32013042380305],
              [-2.59522440214707, 53.322438905758965],
              [-2.592586604120912, 53.322434064206846],
              [-2.592739987303906, 53.32472634746518],
              [-2.590092419704341, 53.325872965123892],
              [-2.588501858917123, 53.325939251526677],
              [-2.588109687643244, 53.326462536680324],
              [-2.588335986141964, 53.327414233692821],
              [-2.586346543787828, 53.328725609340943],
              [-2.586290971524486, 53.329381165988309],
              [-2.581556185343866, 53.328349961173394],
              [-2.580233666116719, 53.328591909096524],
              [-2.57975894702078, 53.327909271318617],
              [-2.578655934787815, 53.327617997734983],
              [-2.578984823409881, 53.327068983745129],
              [-2.578363813496909, 53.326002329796907],
              [-2.576817202810298, 53.326100606913236],
              [-2.575396978141384, 53.325661622978885],
              [-2.569403651030188, 53.325224768395678],
              [-2.567848910934602, 53.325388586313373],
              [-2.566493644989207, 53.324177948507113],
              [-2.565175212890978, 53.323590044387316],
              [-2.563088975262525, 53.323064180649929],
              [-2.56187638477615, 53.323326979453668],
              [-2.56016810267071, 53.323914792984318],
              [-2.558838702522172, 53.325696316525828],
              [-2.557368044262731, 53.32609511002908],
              [-2.553929210856329, 53.328710689104284],
              [-2.551665498209717, 53.332358042527467],
              [-2.548281653902289, 53.331015441797796],
              [-2.544530298794501, 53.330509481330779],
              [-2.543006865980265, 53.332450811788114],
              [-2.541266907914625, 53.333432204990828],
              [-2.539454012628187, 53.336374352911825],
              [-2.538235082147675, 53.335936713521953],
              [-2.518394118711245, 53.342374255840582],
              [-2.51598954521263, 53.342753220811943],
              [-2.514076729506905, 53.342686873586494],
              [-2.512428029038936, 53.342099811747246],
              [-2.508453795913149, 53.34297794114164],
              [-2.503348515860904, 53.34208274275084],
              [-2.501611305824575, 53.343408709693996],
              [-2.49708108821349, 53.344683401931199],
              [-2.495643096886221, 53.344378375631401],
              [-2.493969174559655, 53.343282401512226],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000051",
        LAD13CDO: "00GG",
        LAD13NM: "Shropshire",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.380793391616861, 52.998394623898612],
            [-2.379875325204106, 52.998029004142481],
            [-2.377990862352684, 52.998240866449059],
            [-2.376608775493937, 52.997627695154691],
            [-2.376524014491646, 52.996791067183715],
            [-2.377284184383826, 52.995629945165611],
            [-2.376474233799355, 52.995351151265744],
            [-2.376250653396411, 52.994483500226515],
            [-2.376697885206246, 52.993659569043551],
            [-2.378620866628706, 52.992934319114738],
            [-2.3788165735405, 52.991975443463922],
            [-2.37984471995455, 52.991659336045075],
            [-2.379806587946583, 52.991049089093153],
            [-2.378660738617253, 52.989830210442278],
            [-2.377572236926414, 52.989371627631968],
            [-2.37598323573586, 52.986513594618373],
            [-2.375076574540494, 52.986227006686278],
            [-2.373507302443042, 52.983551358779224],
            [-2.371535907456456, 52.982936383137073],
            [-2.370269059232441, 52.982145688854906],
            [-2.368730428492549, 52.979343132778624],
            [-2.370736732176816, 52.977450045799237],
            [-2.373621633875697, 52.976436010080661],
            [-2.374377144298122, 52.975629093559441],
            [-2.376382910489296, 52.97474989940622],
            [-2.379067364218503, 52.975198917677425],
            [-2.379763718291031, 52.975980556913662],
            [-2.381807558209605, 52.971492860587389],
            [-2.382831957109578, 52.970641876399341],
            [-2.379131505755097, 52.969259508518434],
            [-2.379096846894998, 52.967851900040252],
            [-2.377131490917011, 52.964951025952729],
            [-2.380822934688235, 52.962310785673445],
            [-2.382579944695412, 52.961668699187207],
            [-2.384750344302025, 52.959806301988813],
            [-2.386275208853245, 52.954350250433315],
            [-2.386164252131332, 52.953257513476345],
            [-2.387109046829602, 52.952716878467641],
            [-2.391775774924849, 52.952116374783877],
            [-2.391793816549158, 52.951642579422952],
            [-2.392457030496979, 52.951575667044878],
            [-2.392434107903575, 52.951181112683173],
            [-2.393039778628603, 52.951334624902714],
            [-2.393766540866547, 52.950563632940614],
            [-2.397951838187904, 52.957376154207253],
            [-2.399153201416321, 52.96061545802587],
            [-2.400347005650651, 52.960601552521602],
            [-2.4004361394644, 52.960903292045089],
            [-2.402874709946399, 52.961231246145054],
            [-2.40282006273124, 52.96159819424723],
            [-2.405531333171627, 52.962265862734768],
            [-2.408771519126588, 52.962493872522671],
            [-2.409363924054993, 52.96248104547103],
            [-2.409460575927776, 52.961996189929458],
            [-2.410584774597076, 52.962331205819943],
            [-2.410989549898607, 52.961686173298581],
            [-2.41076807301145, 52.96109454534718],
            [-2.412898469187013, 52.961724500239193],
            [-2.413452104932781, 52.961394465707095],
            [-2.41379340941395, 52.960811670434559],
            [-2.410362065377616, 52.959252245549237],
            [-2.408284608131784, 52.958852189048073],
            [-2.407175197944503, 52.957861781425464],
            [-2.407478593139129, 52.957357340793983],
            [-2.407027156923829, 52.955730927584476],
            [-2.405306583775077, 52.953202728362172],
            [-2.405603630765935, 52.950590323010374],
            [-2.404661240323943, 52.948968271435405],
            [-2.404893446860076, 52.948334633124134],
            [-2.405435547749192, 52.94837413477056],
            [-2.405947869841185, 52.947935504700006],
            [-2.405602398891611, 52.947278667733855],
            [-2.407510484623514, 52.947454619592548],
            [-2.408583823570626, 52.946856739059285],
            [-2.408768361790743, 52.94638326651139],
            [-2.410355382968385, 52.947231778511366],
            [-2.411015797680392, 52.946879810984996],
            [-2.413541494646235, 52.94658698211844],
            [-2.415001360382692, 52.947045738821345],
            [-2.414042954626244, 52.947670244760289],
            [-2.414603403559164, 52.947916394724096],
            [-2.41416858482859, 52.948201973794447],
            [-2.414975456810286, 52.948070610510477],
            [-2.41517392494684, 52.948281165700379],
            [-2.414968165813847, 52.948707977877127],
            [-2.41607117206042, 52.948417360428955],
            [-2.416788192061874, 52.948692616258796],
            [-2.416686002067157, 52.949054344936428],
            [-2.418177413297002, 52.948914264090121],
            [-2.418951907972913, 52.949590227617918],
            [-2.420662306947656, 52.948856947637736],
            [-2.421636881521219, 52.949289473678739],
            [-2.425269203281864, 52.94998308436692],
            [-2.426050713409369, 52.949391484110038],
            [-2.427681535344341, 52.948901998622247],
            [-2.427615392463133, 52.948533675006374],
            [-2.428416764076423, 52.947849397537077],
            [-2.428355760715779, 52.947098110977471],
            [-2.429279084897366, 52.946710034995562],
            [-2.429379542625481, 52.946334817468056],
            [-2.430532684461613, 52.946013320533602],
            [-2.431239905735776, 52.946195934932391],
            [-2.431482526043184, 52.945751880543675],
            [-2.43227847899994, 52.945579987324216],
            [-2.433908874903548, 52.944474648949694],
            [-2.434721136145458, 52.944448306686013],
            [-2.435135046825272, 52.944024293041458],
            [-2.437123204776879, 52.943583707670101],
            [-2.437709975239246, 52.942597215978438],
            [-2.437286310695942, 52.941755577900388],
            [-2.438065065333489, 52.941658320373612],
            [-2.438163420571278, 52.941230964630719],
            [-2.439423493577468, 52.940468509304644],
            [-2.441015150121878, 52.936908228849497],
            [-2.443562775534103, 52.934181265522035],
            [-2.444104967952226, 52.932645658052301],
            [-2.444974550166348, 52.932573185300555],
            [-2.445492209492439, 52.931986937485668],
            [-2.445901715742236, 52.930432941115356],
            [-2.445354760283279, 52.930192281110102],
            [-2.446589743521291, 52.92934803869845],
            [-2.446882673241884, 52.928613406097412],
            [-2.448114817667091, 52.92807749284669],
            [-2.448883682775873, 52.928344269717485],
            [-2.450477807692701, 52.927573240047437],
            [-2.450218943544735, 52.92585096296817],
            [-2.450648182878957, 52.925072654778774],
            [-2.451231165816942, 52.925348212166973],
            [-2.451862039704599, 52.925222659291336],
            [-2.453383321530807, 52.925894658583502],
            [-2.455835321033751, 52.925667730002459],
            [-2.456242593164703, 52.924366305161563],
            [-2.45677487169521, 52.924344482486795],
            [-2.456182722297601, 52.923765147130872],
            [-2.456255507485072, 52.922907282692258],
            [-2.456724086868611, 52.922911774064502],
            [-2.456772814422715, 52.922312895275034],
            [-2.457436537704396, 52.922064032040062],
            [-2.45704494820898, 52.921883954670271],
            [-2.457507108889291, 52.921421919766011],
            [-2.456884210657335, 52.921026988061733],
            [-2.457309941457457, 52.920919274901919],
            [-2.457578837539017, 52.920184707050851],
            [-2.456458564580316, 52.919870794909251],
            [-2.456816199871959, 52.919088243902344],
            [-2.455993174335391, 52.918751609063428],
            [-2.456748141317819, 52.917850669951036],
            [-2.45559273214234, 52.916592102189696],
            [-2.455713473334926, 52.915485946427019],
            [-2.456824568112759, 52.914091920323514],
            [-2.457449665555329, 52.914140753310868],
            [-2.457286157994552, 52.914571075099431],
            [-2.4580625320108, 52.914439534763389],
            [-2.458316653899278, 52.91357827116915],
            [-2.459585583259292, 52.913339643022063],
            [-2.459288806967196, 52.912978519455812],
            [-2.459853984085863, 52.912706650810996],
            [-2.459463004447673, 52.912436685124895],
            [-2.459977339979651, 52.912138943375041],
            [-2.459436377388547, 52.911536951510072],
            [-2.460200690402732, 52.911605906829834],
            [-2.460855010092446, 52.909368609340234],
            [-2.462198228572205, 52.908858185187704],
            [-2.46243293681186, 52.908557924898012],
            [-2.462042954824551, 52.908379655374915],
            [-2.463414991851328, 52.907787301762525],
            [-2.463921677098127, 52.908033430215347],
            [-2.466053757675402, 52.907052429379661],
            [-2.467139362122627, 52.907070636857902],
            [-2.468784439045486, 52.906300954394844],
            [-2.470841832625254, 52.905854131669329],
            [-2.469788376340399, 52.903858164766156],
            [-2.468231098495106, 52.89704406017011],
            [-2.465244235382361, 52.892146687804726],
            [-2.462389539935821, 52.888990871173945],
            [-2.457193594131893, 52.886163149052059],
            [-2.456346973726404, 52.88521892216005],
            [-2.456174029728088, 52.884317049379135],
            [-2.457684316053582, 52.880831431283575],
            [-2.457713261990533, 52.880052835540688],
            [-2.457321151756635, 52.879928494870235],
            [-2.457594643338288, 52.879089626447474],
            [-2.455903719589271, 52.879517736801048],
            [-2.454826480853002, 52.878103337836116],
            [-2.45485260946594, 52.877476674638849],
            [-2.451436299367243, 52.878158529675687],
            [-2.449070125123951, 52.874931311078022],
            [-2.446142456962771, 52.873993067449035],
            [-2.4454753995626, 52.873996471765452],
            [-2.444861553604631, 52.874843780301418],
            [-2.442020456322618, 52.880653471124113],
            [-2.44041237301105, 52.881212297464991],
            [-2.438350233720616, 52.881251393371407],
            [-2.435608344035319, 52.881817931741992],
            [-2.431547411462024, 52.881697923445721],
            [-2.428038330949203, 52.882234711581319],
            [-2.42585147109159, 52.881987277836885],
            [-2.424083072584825, 52.88365575068439],
            [-2.420339853793571, 52.885208050949181],
            [-2.419291951355537, 52.886727370359466],
            [-2.416577144265057, 52.885840685016738],
            [-2.415897920569501, 52.885058291793392],
            [-2.414810343124407, 52.884929054099047],
            [-2.411858648104855, 52.88787527017827],
            [-2.407443298207417, 52.887574979389576],
            [-2.405410115798892, 52.888204006796627],
            [-2.405081132448541, 52.887828474201896],
            [-2.404738682358727, 52.8880804464482],
            [-2.403080177340644, 52.887931472048223],
            [-2.400554363724044, 52.888816487771003],
            [-2.398982340523061, 52.888699526119844],
            [-2.396032360116123, 52.889534630748528],
            [-2.394921773435276, 52.889332473667899],
            [-2.39399492866012, 52.888576848934179],
            [-2.392235461616124, 52.888601553464753],
            [-2.390473878864311, 52.889052335990428],
            [-2.388921524167636, 52.88897203301908],
            [-2.386603773564862, 52.888558900798131],
            [-2.385555247122278, 52.887973506057484],
            [-2.384914487683095, 52.888621023724546],
            [-2.385270001084446, 52.889325537393212],
            [-2.384428890741129, 52.89034496401414],
            [-2.382637111739108, 52.891096872155366],
            [-2.380266182652702, 52.891066735523019],
            [-2.378938538745721, 52.891705631576613],
            [-2.375379608071731, 52.891458045022972],
            [-2.377586151314336, 52.88762336643056],
            [-2.376871275801109, 52.88453868155009],
            [-2.379167082959214, 52.883494892855843],
            [-2.378945952127878, 52.88165457062933],
            [-2.379481560817621, 52.880878872201606],
            [-2.381179784069983, 52.880005953438712],
            [-2.382512353512607, 52.876595581260226],
            [-2.382404783802639, 52.8756844019927],
            [-2.380545873437627, 52.873117607772912],
            [-2.381156736231726, 52.867047791689416],
            [-2.382223707084827, 52.865740893914356],
            [-2.388496452588153, 52.862219143437684],
            [-2.388202223319285, 52.861206098487123],
            [-2.386739981641933, 52.859956847021508],
            [-2.389333864795713, 52.8572092842521],
            [-2.388371999988316, 52.855770530140781],
            [-2.389725723412638, 52.854533639534729],
            [-2.389779860118085, 52.851792580179179],
            [-2.391433512585949, 52.849911038883668],
            [-2.392760516197638, 52.846896086368574],
            [-2.392529122282886, 52.845605966264095],
            [-2.393191939884138, 52.842389141120123],
            [-2.394269327577768, 52.841027258076764],
            [-2.393522148228715, 52.838682589144639],
            [-2.394068774204378, 52.837898691139713],
            [-2.393757200299596, 52.836605241979541],
            [-2.394503324356377, 52.835561782590936],
            [-2.395594716047851, 52.834946864386986],
            [-2.395735268747356, 52.834406128265648],
            [-2.397091264071916, 52.833838856389846],
            [-2.400875244381906, 52.833716459542224],
            [-2.403609441937619, 52.83483896472341],
            [-2.407656531369274, 52.83459050276084],
            [-2.409345113073151, 52.833575170375759],
            [-2.409705080672389, 52.832551823921186],
            [-2.412718665186503, 52.831032044647884],
            [-2.413782553784005, 52.829592717996633],
            [-2.415337999297154, 52.829125223153497],
            [-2.41554253118121, 52.827696077071785],
            [-2.416347617012193, 52.826991175161041],
            [-2.416967167691981, 52.826441539186746],
            [-2.418443758823712, 52.826431845532348],
            [-2.420094732124241, 52.825730224031304],
            [-2.422391645940927, 52.825378669608533],
            [-2.423245846990254, 52.824704109818171],
            [-2.424009289765032, 52.824617781361177],
            [-2.423805442644868, 52.8239532868769],
            [-2.426038915358811, 52.823197360694678],
            [-2.42636005079924, 52.824470019109292],
            [-2.42575464525053, 52.825843990632293],
            [-2.426509247298284, 52.825914095106405],
            [-2.426738091886094, 52.8235220655719],
            [-2.426324552771328, 52.822512233528045],
            [-2.427433470433233, 52.822854339125065],
            [-2.428225268020756, 52.825051214350054],
            [-2.428087150639123, 52.82745550420286],
            [-2.42727105654418, 52.827927697709363],
            [-2.42757810536494, 52.828363480890054],
            [-2.43021029106791, 52.828139116437988],
            [-2.432661581348634, 52.82703887752438],
            [-2.434882930897538, 52.824623367858187],
            [-2.436503640461494, 52.823600703094137],
            [-2.438665289145731, 52.824282224874878],
            [-2.440474272796326, 52.820757029577692],
            [-2.443334849641476, 52.82144575789458],
            [-2.446463623006774, 52.821797200697979],
            [-2.456139226553242, 52.821680387293569],
            [-2.459650555114615, 52.821991356312772],
            [-2.46270815417237, 52.821912948142163],
            [-2.46910266738652, 52.823029504739239],
            [-2.470255091628011, 52.821734047905281],
            [-2.471703930333833, 52.821643794751566],
            [-2.472987092130653, 52.820509602379076],
            [-2.470774227054066, 52.819240993198463],
            [-2.468634606425319, 52.811530158049962],
            [-2.466412829866086, 52.808682040582035],
            [-2.461638516499451, 52.803744784372775],
            [-2.461179786610648, 52.803849049569422],
            [-2.461400222482343, 52.800152590667196],
            [-2.457747669763439, 52.798515363040757],
            [-2.458932548210356, 52.797232470394455],
            [-2.458334490499632, 52.797197925797313],
            [-2.459372716192318, 52.796780389922269],
            [-2.459136810718849, 52.796490041884091],
            [-2.460926851338834, 52.795924847806944],
            [-2.460477027617202, 52.795601173207771],
            [-2.461365340702668, 52.795457484070099],
            [-2.461361846446295, 52.794987343960955],
            [-2.463199604539957, 52.794455191043248],
            [-2.462972147486106, 52.793984127407946],
            [-2.463471947487388, 52.792731727875491],
            [-2.462893689754421, 52.7920426890282],
            [-2.461753719041174, 52.791521243471927],
            [-2.459564252316988, 52.791175558858676],
            [-2.458204258348071, 52.790031056067164],
            [-2.458774360661548, 52.789814001617138],
            [-2.458268439802501, 52.788096254172387],
            [-2.457459775006313, 52.787621131318204],
            [-2.456839064049182, 52.786402740951011],
            [-2.457426972302496, 52.785625574140639],
            [-2.45695980470989, 52.784341866810564],
            [-2.456270851719976, 52.784105396914839],
            [-2.457606657780574, 52.783391871886316],
            [-2.458881353424752, 52.783215245606165],
            [-2.460292115685122, 52.783429123968659],
            [-2.460659013818066, 52.78391493415495],
            [-2.461840245103669, 52.783872583744525],
            [-2.462918300179, 52.784446410025495],
            [-2.464320433987222, 52.784401374561689],
            [-2.46751626592532, 52.783633695020086],
            [-2.47095255972039, 52.784274539881778],
            [-2.472567061503719, 52.782621227192244],
            [-2.47346351636992, 52.782436062165878],
            [-2.473509985902864, 52.781800313432385],
            [-2.472204368193392, 52.780892178000705],
            [-2.472633857137318, 52.78044008911278],
            [-2.475558185400936, 52.781188916976362],
            [-2.476359099534335, 52.780947478679707],
            [-2.479818373872796, 52.78220196301703],
            [-2.482619919190837, 52.78067674943383],
            [-2.485422999928438, 52.780239202643628],
            [-2.488214176929342, 52.780597216925315],
            [-2.488412802925403, 52.781520528449278],
            [-2.487122793283515, 52.782434681056721],
            [-2.488064419308139, 52.782847027324543],
            [-2.489651683570608, 52.782923188691569],
            [-2.489403614007589, 52.783277503246531],
            [-2.489957784331875, 52.783907182713804],
            [-2.48691532840732, 52.785364338372524],
            [-2.48440240924227, 52.787791026685348],
            [-2.478730033783466, 52.797853604054175],
            [-2.47712253953119, 52.799364033702531],
            [-2.478479526131672, 52.801513363206986],
            [-2.483582965666358, 52.800002198940923],
            [-2.486769839939703, 52.799821058840145],
            [-2.488375338256975, 52.800410466975812],
            [-2.488876228584043, 52.800900132320905],
            [-2.488574678433468, 52.802050239840511],
            [-2.486508393042623, 52.805231145034135],
            [-2.490003941417151, 52.804501188906507],
            [-2.490248389611947, 52.802233013795153],
            [-2.492406234022145, 52.802334647356197],
            [-2.498951775045049, 52.800761990161078],
            [-2.498800815353181, 52.800402143719822],
            [-2.501255893592959, 52.799271695024999],
            [-2.501600130251141, 52.797350967900677],
            [-2.503029969310806, 52.796589789123608],
            [-2.507310197882604, 52.796718988730902],
            [-2.509969609135492, 52.798385038112272],
            [-2.51172400291058, 52.798496144723352],
            [-2.514508683727986, 52.796176475928846],
            [-2.516142937681947, 52.79554190360907],
            [-2.518001972797544, 52.79585562626675],
            [-2.521127613689775, 52.799024243790882],
            [-2.521991259064079, 52.798936843366121],
            [-2.522292025023737, 52.798167810028175],
            [-2.523256275107784, 52.79769070882103],
            [-2.522869856369324, 52.796758396100351],
            [-2.524160486976463, 52.796662804097828],
            [-2.524495417966989, 52.795656288979721],
            [-2.525547384973202, 52.795080794501686],
            [-2.525353152531025, 52.793608262935038],
            [-2.5264155840736, 52.792553569390165],
            [-2.527114481693529, 52.790626694559776],
            [-2.526515948049236, 52.790427087350416],
            [-2.526543156197703, 52.790101543566919],
            [-2.528168472984695, 52.790108700424767],
            [-2.528267070983428, 52.78980171641301],
            [-2.527284853147761, 52.789264913898883],
            [-2.528785695091821, 52.789037088339306],
            [-2.528598803146672, 52.788665752444281],
            [-2.529162112858621, 52.788407936248902],
            [-2.529417558662215, 52.787097911918032],
            [-2.532342270145868, 52.785696827546374],
            [-2.533408090106035, 52.786160396809791],
            [-2.533672807080001, 52.786709369148191],
            [-2.534710993857675, 52.785883943189518],
            [-2.536373142829291, 52.785884528601024],
            [-2.537516968176509, 52.785094558445131],
            [-2.538280283230499, 52.785312240713246],
            [-2.538890114471451, 52.786067294501308],
            [-2.538630594331817, 52.787024067422713],
            [-2.53785261862114, 52.787543600662509],
            [-2.53824298130056, 52.788776100988315],
            [-2.537322469327323, 52.78928369184441],
            [-2.537482764114191, 52.789896954625377],
            [-2.535947160711722, 52.790793872683416],
            [-2.535851775114335, 52.791357950822274],
            [-2.535004769550594, 52.79157842340058],
            [-2.534632301072095, 52.792283088516626],
            [-2.534081820097439, 52.792379060179094],
            [-2.53345229025288, 52.793407604718688],
            [-2.533812293237371, 52.79410447569925],
            [-2.535493063106791, 52.794870902680827],
            [-2.536295496323034, 52.794879862748871],
            [-2.537896051804698, 52.79394758715101],
            [-2.540028350726618, 52.793806650382791],
            [-2.542323818575023, 52.795815238093702],
            [-2.543923419334934, 52.796477637783703],
            [-2.544063341013662, 52.797112559579851],
            [-2.547304370034152, 52.797382619539498],
            [-2.548376649109482, 52.799738331379544],
            [-2.54819358397785, 52.800277653341581],
            [-2.548595219406217, 52.800252423660794],
            [-2.549118511858306, 52.801171434245347],
            [-2.550992755684081, 52.801359615163037],
            [-2.551128499772134, 52.801889369684204],
            [-2.551646929517751, 52.80194988697265],
            [-2.551397979131051, 52.802320516058828],
            [-2.552945222006504, 52.802918312559939],
            [-2.554900739568176, 52.802143269191497],
            [-2.55497497952068, 52.800868199454435],
            [-2.556152219608124, 52.800600190162029],
            [-2.557010039163185, 52.800756179826969],
            [-2.5594029045191, 52.799169944597246],
            [-2.561317314219097, 52.79902515833254],
            [-2.562766617134585, 52.799502830109375],
            [-2.565815645485949, 52.799698682140168],
            [-2.567752166526899, 52.801819961360088],
            [-2.570539724016034, 52.802989616528642],
            [-2.571069780996987, 52.804500012882933],
            [-2.569423062155632, 52.805053596181537],
            [-2.573838879331998, 52.806275577181871],
            [-2.57701492486175, 52.806389621619417],
            [-2.577175387294715, 52.805400885912867],
            [-2.581245097460663, 52.806012983499933],
            [-2.582011129116207, 52.805612787108082],
            [-2.582788406616953, 52.805610769114942],
            [-2.586564479968364, 52.802695713048465],
            [-2.589010903779065, 52.801631808922998],
            [-2.590288317886431, 52.800669865408281],
            [-2.590635448749504, 52.799579497362252],
            [-2.592511241058037, 52.799218649609919],
            [-2.591841524707435, 52.798504622812523],
            [-2.595348872450951, 52.797611484736649],
            [-2.598009867190234, 52.797954088113165],
            [-2.593691747160644, 52.795726579495245],
            [-2.592567605462051, 52.793546827205802],
            [-2.593363906527444, 52.791798862296908],
            [-2.594371962867924, 52.791012618051212],
            [-2.593699515171991, 52.790090954576343],
            [-2.593375816387396, 52.788316226482195],
            [-2.594228324400238, 52.784174036730938],
            [-2.593283396340444, 52.783159343861605],
            [-2.592698716917527, 52.78170325099434],
            [-2.589840864482164, 52.779444920584034],
            [-2.5903194165293, 52.778108478670795],
            [-2.591252010482087, 52.778117317549608],
            [-2.592247123238958, 52.776834925776747],
            [-2.597751831790428, 52.778382293000689],
            [-2.598880810169607, 52.778441322893208],
            [-2.603027874608048, 52.777088045506453],
            [-2.605854445321901, 52.777803598526674],
            [-2.60703172976516, 52.777712177128876],
            [-2.615133298937986, 52.775184797872967],
            [-2.617603947605012, 52.775256460300604],
            [-2.619452511489248, 52.776303102924338],
            [-2.62100601911784, 52.776507134353309],
            [-2.622650710834543, 52.77344023246026],
            [-2.625617997283935, 52.771971888544492],
            [-2.626384292329679, 52.769388710970844],
            [-2.627538320720426, 52.768626579523229],
            [-2.629247846950011, 52.766099516262415],
            [-2.628706717985765, 52.765264549468782],
            [-2.628589594552521, 52.762895493780192],
            [-2.632515550017855, 52.760459092895005],
            [-2.632333813012808, 52.759575478001388],
            [-2.635258225992344, 52.759015072985711],
            [-2.636704236755716, 52.761943346305451],
            [-2.63582052880579, 52.762622313416927],
            [-2.636521092515763, 52.763915764958753],
            [-2.637491335693606, 52.764289917315651],
            [-2.637310910040019, 52.764924658945461],
            [-2.637918709657808, 52.765138941008431],
            [-2.638158874337322, 52.764840090726011],
            [-2.639133805020681, 52.764722469392396],
            [-2.640464457645052, 52.765112633831059],
            [-2.641235403941897, 52.766544118187788],
            [-2.643131322497755, 52.767069635864708],
            [-2.649465218690656, 52.764838939552327],
            [-2.648981323500301, 52.763891379780851],
            [-2.654735884704394, 52.762408813027193],
            [-2.656544634813748, 52.761278698025066],
            [-2.658120593417627, 52.762307371967701],
            [-2.66341808139401, 52.760425978063097],
            [-2.662443891102306, 52.758238838426401],
            [-2.661034444610028, 52.757439429366968],
            [-2.661565528272536, 52.756697514728927],
            [-2.660956737854864, 52.756417734273718],
            [-2.662131483053304, 52.755325225115712],
            [-2.661658115825343, 52.754692295804126],
            [-2.663728699710026, 52.75269040419991],
            [-2.661912495185682, 52.750557411436091],
            [-2.66336414192373, 52.749955079409915],
            [-2.664729603217505, 52.751187112733056],
            [-2.6656512719957, 52.751487592185498],
            [-2.667364761557801, 52.750182543851757],
            [-2.665512789525197, 52.745902164366498],
            [-2.663875635755782, 52.745302738790997],
            [-2.664138161625732, 52.744642322538262],
            [-2.663777947810605, 52.744236206667885],
            [-2.662708655643295, 52.743763036408687],
            [-2.659583081542927, 52.743860473869539],
            [-2.659392747740801, 52.743518125500927],
            [-2.660697077132421, 52.742199264830035],
            [-2.662030648011984, 52.741446578335243],
            [-2.6626976479577, 52.740499830486606],
            [-2.663734728378473, 52.740028361630394],
            [-2.664392393543882, 52.738277075893386],
            [-2.664123513743509, 52.736276570507457],
            [-2.661430012451032, 52.73484518127443],
            [-2.660964840118402, 52.73415107169339],
            [-2.660398401043818, 52.73332088050401],
            [-2.660260274816503, 52.731628883539592],
            [-2.658868387447078, 52.731639324342055],
            [-2.658279570473499, 52.730695077618279],
            [-2.654034600416217, 52.730641261431145],
            [-2.644610225313925, 52.729225757799547],
            [-2.641467168751815, 52.727559929764944],
            [-2.63603393702769, 52.726520326951146],
            [-2.632026542555683, 52.72678897810632],
            [-2.630235903461231, 52.726245643465845],
            [-2.627853311870973, 52.724691377418608],
            [-2.627258815977748, 52.722782409434387],
            [-2.627647973221499, 52.721425597030056],
            [-2.62698932274735, 52.721542353359894],
            [-2.626190829242482, 52.720579279060033],
            [-2.62428609610062, 52.719488083686947],
            [-2.62340314647776, 52.719551163916208],
            [-2.623218324469499, 52.720087024893189],
            [-2.621873040389928, 52.720850126724933],
            [-2.619919147674711, 52.721296363207628],
            [-2.618920395076745, 52.721862541309051],
            [-2.615124533552854, 52.71893906309662],
            [-2.613437535623276, 52.719518653548377],
            [-2.609981968866567, 52.719404337559119],
            [-2.60746933623601, 52.720024946639526],
            [-2.606235104736826, 52.719527840889434],
            [-2.604661485948879, 52.719430698921926],
            [-2.604611527826097, 52.719136989747007],
            [-2.603270426735135, 52.719151913624572],
            [-2.601967370573103, 52.719671851602882],
            [-2.598936564345804, 52.718357624747988],
            [-2.59742188495777, 52.718235816722043],
            [-2.597729123242456, 52.717319113829284],
            [-2.597064120705422, 52.715346523642147],
            [-2.596423617969461, 52.715382111368299],
            [-2.596940937098516, 52.714456262012746],
            [-2.597845357869328, 52.713911420812472],
            [-2.598761907019854, 52.713821391744126],
            [-2.599505847648498, 52.714098110817204],
            [-2.600899642099874, 52.71372786764713],
            [-2.602076758799503, 52.71299642263196],
            [-2.602255488063344, 52.712323981461211],
            [-2.604953425282804, 52.709717601408798],
            [-2.60397694701316, 52.708891030554241],
            [-2.60413186000479, 52.708426370386938],
            [-2.605993709274253, 52.707562839793738],
            [-2.60542466848359, 52.706764761790403],
            [-2.609208763985759, 52.705673793316294],
            [-2.610885446338337, 52.70470775458778],
            [-2.613944246757892, 52.704368317635172],
            [-2.619331213900502, 52.704463448942036],
            [-2.624325773020802, 52.703401623641071],
            [-2.623491442304057, 52.703011365494177],
            [-2.623381342875575, 52.700686293798057],
            [-2.622552540124583, 52.699016756682191],
            [-2.620214589619673, 52.69798170779454],
            [-2.61716286344297, 52.698368017885883],
            [-2.612719118429405, 52.696783707031365],
            [-2.608885001986828, 52.694113731539375],
            [-2.608279216486804, 52.692898729336171],
            [-2.608382423480795, 52.691808639709464],
            [-2.603334228650779, 52.68934248590439],
            [-2.60125474266308, 52.689389014484206],
            [-2.597739945866124, 52.689960566004032],
            [-2.596673907564452, 52.690552071334601],
            [-2.594895138734486, 52.687802055284337],
            [-2.59183194869026, 52.686210922558622],
            [-2.58856475139795, 52.683733433810175],
            [-2.586795214635624, 52.684150345833622],
            [-2.582736197267526, 52.682050589750709],
            [-2.581951896870962, 52.68213804930361],
            [-2.578842068510864, 52.68098101623935],
            [-2.577192386379822, 52.680726562632316],
            [-2.576997892898798, 52.680110811568426],
            [-2.576316780246696, 52.67972037445012],
            [-2.575300507001979, 52.680049844078951],
            [-2.57434439037426, 52.679103364722067],
            [-2.568178206730845, 52.676957569771318],
            [-2.562688507297478, 52.67412052578284],
            [-2.555650098108041, 52.67108283743751],
            [-2.549415565399376, 52.666272716239362],
            [-2.551612584301572, 52.664840322890129],
            [-2.552963929227751, 52.664830426988622],
            [-2.553741521057455, 52.664466304428394],
            [-2.554787756879554, 52.662666148653138],
            [-2.554221975720071, 52.661537881338134],
            [-2.554211485169337, 52.660007867750636],
            [-2.5466913066882, 52.658057865912227],
            [-2.545493486803639, 52.658388818404198],
            [-2.546322521873071, 52.656463878774893],
            [-2.548319484163366, 52.655259907773555],
            [-2.548460272056096, 52.654697393559047],
            [-2.54674763059456, 52.654397859878806],
            [-2.546296435561087, 52.653660980097719],
            [-2.544984114253129, 52.65285884216663],
            [-2.541677762377336, 52.652655556155182],
            [-2.535056267570005, 52.650553271144922],
            [-2.530055843579738, 52.649466412517747],
            [-2.527099688843313, 52.647137761610409],
            [-2.523807818053614, 52.646247991429874],
            [-2.519171284989033, 52.643500445461164],
            [-2.517831785931774, 52.643206949067306],
            [-2.516666528707687, 52.642021787832164],
            [-2.514912792586622, 52.641056728317878],
            [-2.512738550773173, 52.640647236220723],
            [-2.510078331298712, 52.638348342713932],
            [-2.510731258561936, 52.636552960462261],
            [-2.509888902440252, 52.636037877987455],
            [-2.510080449717301, 52.635736793374207],
            [-2.508659929495918, 52.63505698040143],
            [-2.505626478752486, 52.632376622101766],
            [-2.500861641283356, 52.629679248668715],
            [-2.501286985504469, 52.629406855330778],
            [-2.501775836266196, 52.62900383897005],
            [-2.501130612579859, 52.628910380122186],
            [-2.496210607075414, 52.629022794732414],
            [-2.492063408549177, 52.628094371307981],
            [-2.4893709784669, 52.626317453672463],
            [-2.486454725190835, 52.625118540935517],
            [-2.486149118617611, 52.624602881280197],
            [-2.486465846412896, 52.623857223222672],
            [-2.483620877446536, 52.625081603761835],
            [-2.481321782111235, 52.624772731779046],
            [-2.48180591276379, 52.623524773723915],
            [-2.480288080635139, 52.62399930962291],
            [-2.478986719324475, 52.624008176853188],
            [-2.478640608400637, 52.623427036040795],
            [-2.476046323676326, 52.622994296149614],
            [-2.475021357909025, 52.623151239362819],
            [-2.474521472602314, 52.623779834099224],
            [-2.47239218405506, 52.623588773975079],
            [-2.471997856132855, 52.624009271997977],
            [-2.472030014481069, 52.621919010811716],
            [-2.470851919827545, 52.622534110177924],
            [-2.46971803999631, 52.622453210347665],
            [-2.466355464352889, 52.624075678987403],
            [-2.464564862954288, 52.623780659929245],
            [-2.457817234995526, 52.620566112753835],
            [-2.454827225841774, 52.618544139042967],
            [-2.451019347667176, 52.616963911267725],
            [-2.444252673197078, 52.615020723576563],
            [-2.443750277502967, 52.615289605337274],
            [-2.442370662392372, 52.615275889480053],
            [-2.442004462634641, 52.615724051261473],
            [-2.438042276385296, 52.61459347375316],
            [-2.437679015995454, 52.61534007330205],
            [-2.43650500169369, 52.615653657298516],
            [-2.435771264675681, 52.615536795600057],
            [-2.434044565980727, 52.618210416843006],
            [-2.437061574549392, 52.61933473171063],
            [-2.43559420190997, 52.622246545093738],
            [-2.436636471002221, 52.627079221299397],
            [-2.428700513801314, 52.62968649340656],
            [-2.424065761100306, 52.632127729534361],
            [-2.420661743974398, 52.633379563051477],
            [-2.418296840853476, 52.633760117867411],
            [-2.419452016304301, 52.636200353904627],
            [-2.41891973928258, 52.639413387467677],
            [-2.418229568150735, 52.639407736189455],
            [-2.418467631254019, 52.639886949255249],
            [-2.416468411735966, 52.644886017555713],
            [-2.418755979159634, 52.6457823214784],
            [-2.419925764930901, 52.647551860328988],
            [-2.417575356360631, 52.650309240428832],
            [-2.419030339855119, 52.662877167581065],
            [-2.409940820459084, 52.671632611322615],
            [-2.408031997211641, 52.678970318161419],
            [-2.40777049557754, 52.679637356345204],
            [-2.40689116793124, 52.681149752413646],
            [-2.405439721077371, 52.681588032772588],
            [-2.403423920878408, 52.682747395070265],
            [-2.401965911431872, 52.682480862021237],
            [-2.401212461803927, 52.684520483320448],
            [-2.40232680037738, 52.686658950255591],
            [-2.401394321027074, 52.689347336165625],
            [-2.402835244182931, 52.689822495303268],
            [-2.403549468249881, 52.690425071192891],
            [-2.405242988140829, 52.694843118318303],
            [-2.401526981631323, 52.694934879235554],
            [-2.403594078277398, 52.69878712789324],
            [-2.403579383601257, 52.701363620416167],
            [-2.402421372930578, 52.703049527596086],
            [-2.402503112415237, 52.703890683556217],
            [-2.401034284497482, 52.704618433675641],
            [-2.392760705307823, 52.711474696673179],
            [-2.393106294206131, 52.713527687739123],
            [-2.392760980603625, 52.714305539077628],
            [-2.387152532832914, 52.717691518609946],
            [-2.385157413042681, 52.720096457626269],
            [-2.382598584282788, 52.722063589598953],
            [-2.381007389671883, 52.724317024753738],
            [-2.380495280758527, 52.72688880678497],
            [-2.379497498581082, 52.728618914287196],
            [-2.379667556650733, 52.729614420594672],
            [-2.378864787844875, 52.730619333065661],
            [-2.379487890778964, 52.732029609848702],
            [-2.379071646661004, 52.735343617822352],
            [-2.376811822788289, 52.739313446805497],
            [-2.375073833372244, 52.740794154646856],
            [-2.370967642603254, 52.74023264119991],
            [-2.368823249626976, 52.740448791278382],
            [-2.368832661350214, 52.73998489812103],
            [-2.370046429581752, 52.739018327506173],
            [-2.371875764434245, 52.738512779577128],
            [-2.368455032289609, 52.735253939966164],
            [-2.367296951208853, 52.733148570009234],
            [-2.362949257525167, 52.733722017424519],
            [-2.3579303569231, 52.731197713845468],
            [-2.352455738108367, 52.730848244222287],
            [-2.34361892049348, 52.731152873953896],
            [-2.336386040371845, 52.733030879321099],
            [-2.335512957759394, 52.730611552275647],
            [-2.335856757972775, 52.730258185464827],
            [-2.333607020984726, 52.730599852705353],
            [-2.330072420229634, 52.729447392970421],
            [-2.328667892398898, 52.730334975902963],
            [-2.325172408767489, 52.73123369168998],
            [-2.323166944913599, 52.731183442040404],
            [-2.321457541948184, 52.730513870425739],
            [-2.319691407364436, 52.730583373389138],
            [-2.319078985003005, 52.731279920946122],
            [-2.317575842663689, 52.731678602637814],
            [-2.315578312016158, 52.732932595617235],
            [-2.314823525638736, 52.732383540256862],
            [-2.314878154873192, 52.73153927082766],
            [-2.315780342023895, 52.730561497402434],
            [-2.316454808866919, 52.728203520536432],
            [-2.314681116671341, 52.724540480046592],
            [-2.308605797239339, 52.720731374401275],
            [-2.317060331985013, 52.71329710108688],
            [-2.319008019052043, 52.711021089949284],
            [-2.321847153483783, 52.709031195065208],
            [-2.323635848823947, 52.706129857945989],
            [-2.324836051245941, 52.705321100567183],
            [-2.323331981386749, 52.702465606072153],
            [-2.322976180861019, 52.700778318997656],
            [-2.320659060597388, 52.697792854529794],
            [-2.319910439853732, 52.695382951671569],
            [-2.313582051530876, 52.695394478974876],
            [-2.313208801859951, 52.693689227912202],
            [-2.30799800957892, 52.693317234308218],
            [-2.30769743738283, 52.690552793480435],
            [-2.304186541129333, 52.685862959361998],
            [-2.303881916558079, 52.684176378734087],
            [-2.303043465032791, 52.682937951069398],
            [-2.29571304361591, 52.683252240267521],
            [-2.292065387132042, 52.682167247419727],
            [-2.287180817799116, 52.681229881892385],
            [-2.280566403552664, 52.681529809819295],
            [-2.277582364672969, 52.68098038035081],
            [-2.273680100815898, 52.67976147226188],
            [-2.266694882934714, 52.681328128181036],
            [-2.264206132592822, 52.68266867332256],
            [-2.263114366167847, 52.682897643738549],
            [-2.261460406964641, 52.682867146115228],
            [-2.257441342441826, 52.681861017203296],
            [-2.25387683770501, 52.681666444879163],
            [-2.251791526604709, 52.681725734119475],
            [-2.250074877503938, 52.682880948611341],
            [-2.247732792537239, 52.683059371991114],
            [-2.24668744793053, 52.681045171001678],
            [-2.244688711983061, 52.680976501587239],
            [-2.243708044582759, 52.679917742515968],
            [-2.244224647856464, 52.677867931131281],
            [-2.243848355384681, 52.675900866019873],
            [-2.24254758140354, 52.673099655870686],
            [-2.24022787862899, 52.671608501410169],
            [-2.240346376134458, 52.668950909182591],
            [-2.239490227040933, 52.666798713965903],
            [-2.23977717906839, 52.664925579804411],
            [-2.238719119648329, 52.663686240775903],
            [-2.238920748855301, 52.661889692175407],
            [-2.246378114730786, 52.657900059622278],
            [-2.247827600366565, 52.656973791189579],
            [-2.247691210318421, 52.65664505324159],
            [-2.24220276045456, 52.6564918972458],
            [-2.237374190956086, 52.655886781382179],
            [-2.234797331622495, 52.655857759415376],
            [-2.235582617850394, 52.652617206212028],
            [-2.237473748185197, 52.651075287300785],
            [-2.236569374285071, 52.650575471878355],
            [-2.235487950104864, 52.648483023648161],
            [-2.232898537320636, 52.647679071783479],
            [-2.234770945599784, 52.643218267419229],
            [-2.235561181710828, 52.639840154486258],
            [-2.236481669613946, 52.638714602194277],
            [-2.236891380612548, 52.636051921644359],
            [-2.238807244027975, 52.634036167548388],
            [-2.240863091436839, 52.633327205820002],
            [-2.242458589360158, 52.632211916702651],
            [-2.243679858068205, 52.631034448648457],
            [-2.243610724642663, 52.630026840089734],
            [-2.246004586331301, 52.628256301476775],
            [-2.246531180342125, 52.626795269697176],
            [-2.247796983630685, 52.625440566904075],
            [-2.248960639807397, 52.622760974370514],
            [-2.250122380954923, 52.621841569067442],
            [-2.250115630709328, 52.620917435573915],
            [-2.250905525796002, 52.619819911151211],
            [-2.250515412316787, 52.61824123535596],
            [-2.252061757786984, 52.616935334263999],
            [-2.253155466535019, 52.613991545788608],
            [-2.255570486265297, 52.610999051594604],
            [-2.255808146158414, 52.609968309105675],
            [-2.25732669342037, 52.609538002129021],
            [-2.257322210674295, 52.609780736027695],
            [-2.263063231595051, 52.610659900074168],
            [-2.268004828701307, 52.610217302877153],
            [-2.270863213997899, 52.609632752122131],
            [-2.274126041909746, 52.607613324869163],
            [-2.279309484163604, 52.605798751646539],
            [-2.283390627025593, 52.605986824845665],
            [-2.290660174252959, 52.607779738930034],
            [-2.297737075144577, 52.606993519971716],
            [-2.309604897237648, 52.60738648962819],
            [-2.311007127190283, 52.611047952754234],
            [-2.313465246975577, 52.614040462461865],
            [-2.315812194074736, 52.6146751958156],
            [-2.320974938273898, 52.61459298649234],
            [-2.321080441657914, 52.614277158754092],
            [-2.322037376766648, 52.614061501156364],
            [-2.323802852225389, 52.612938354784092],
            [-2.323353926429253, 52.611942613956835],
            [-2.323631452030804, 52.610125017903648],
            [-2.324163631237147, 52.609598557495275],
            [-2.323927075145428, 52.602962033991695],
            [-2.325478240454051, 52.600084635242986],
            [-2.325074046935281, 52.599135522878619],
            [-2.323352326580282, 52.597701869025627],
            [-2.322027616439355, 52.593621406527767],
            [-2.318006634417995, 52.593451584216893],
            [-2.308549555288912, 52.591079033859636],
            [-2.306991710097466, 52.591426496608712],
            [-2.302344761524907, 52.59334969586623],
            [-2.301019765584979, 52.593228115209193],
            [-2.29884219429244, 52.59190314126041],
            [-2.299955047140395, 52.591229685159625],
            [-2.300687658313977, 52.590217370029897],
            [-2.299950811847011, 52.589313964945717],
            [-2.300133253574747, 52.588795688533132],
            [-2.296058933103388, 52.588298926216474],
            [-2.294886823288395, 52.587839776019109],
            [-2.294961825587022, 52.587359532460184],
            [-2.294102079138447, 52.587253794527307],
            [-2.294376678929007, 52.586162645852475],
            [-2.294913917019271, 52.586160409120453],
            [-2.29505996823398, 52.584612895651901],
            [-2.294424552782586, 52.584938112242618],
            [-2.289505018965728, 52.585569649993971],
            [-2.281055811594947, 52.584281102331047],
            [-2.2786592753537, 52.583403969696327],
            [-2.278096999211709, 52.582943215450214],
            [-2.278125049675024, 52.581779865646034],
            [-2.273995110162945, 52.579793759323593],
            [-2.272812929306017, 52.578590055593679],
            [-2.272190621150741, 52.578661609623822],
            [-2.271954777781372, 52.577275919498284],
            [-2.270291424715027, 52.575553682681239],
            [-2.262806632819536, 52.570295307904438],
            [-2.262010619586898, 52.568685194316288],
            [-2.263384521899849, 52.56800071289338],
            [-2.261773860890066, 52.566340266999717],
            [-2.261789759573006, 52.563809588420916],
            [-2.260840185390408, 52.563142840948302],
            [-2.262194488177648, 52.562660687527966],
            [-2.263813706841358, 52.56257258273255],
            [-2.267216173026255, 52.561476273795179],
            [-2.268710134965287, 52.560453439436131],
            [-2.269953759461775, 52.560231255251999],
            [-2.271668664850877, 52.558697254573282],
            [-2.274218247656088, 52.55739143710624],
            [-2.278593148263683, 52.554373204018489],
            [-2.279048674467606, 52.55317737618391],
            [-2.282480516257062, 52.553381391412863],
            [-2.282111826030983, 52.552696343877543],
            [-2.281413019685828, 52.552508322094816],
            [-2.276510489098432, 52.552013754199777],
            [-2.267934745967647, 52.550136627476505],
            [-2.265733759570698, 52.549298342501821],
            [-2.262544568074362, 52.546692107131754],
            [-2.260441185820862, 52.544361186338435],
            [-2.256409421616557, 52.541781808304101],
            [-2.261544982956313, 52.539046625785708],
            [-2.264232250807872, 52.536993653381856],
            [-2.269219517834158, 52.530674191531837],
            [-2.263301240291733, 52.527517675619194],
            [-2.261139059545452, 52.526803277474365],
            [-2.260947547463716, 52.524087847683759],
            [-2.261595789730039, 52.523547020443409],
            [-2.270664396356032, 52.519935137380379],
            [-2.27323142915814, 52.519184867885599],
            [-2.281421073167328, 52.51552294145835],
            [-2.286257329256256, 52.514147559465691],
            [-2.288304638831697, 52.512880398433559],
            [-2.29139392077782, 52.511665477032345],
            [-2.293246585626327, 52.509972585973152],
            [-2.29307102058159, 52.5094956552893],
            [-2.296090996328143, 52.506793395276802],
            [-2.296298336332098, 52.505639914948105],
            [-2.298424423030553, 52.503272008652338],
            [-2.302083446900561, 52.501283128561319],
            [-2.306447126561623, 52.499841596681904],
            [-2.307840481142918, 52.498341148441767],
            [-2.30769876375569, 52.495134796567335],
            [-2.308670731091223, 52.494481389262361],
            [-2.312752890617396, 52.489194445420381],
            [-2.311667480873589, 52.486694501336338],
            [-2.310413566272455, 52.486177280393193],
            [-2.310178356669701, 52.484980431973867],
            [-2.309024214095371, 52.483142305505183],
            [-2.307251060542982, 52.482474476259931],
            [-2.306280904082741, 52.48271792541837],
            [-2.30397225189439, 52.481471579029645],
            [-2.295634275130066, 52.473877302781105],
            [-2.294957896479638, 52.472022557662633],
            [-2.295445552945903, 52.471639264715037],
            [-2.294589590080347, 52.47066059190567],
            [-2.294774863924554, 52.469528288544865],
            [-2.292695697070386, 52.467662635383853],
            [-2.291243177784333, 52.465487045448192],
            [-2.291155644397151, 52.464045263346669],
            [-2.289888185894435, 52.46267650289974],
            [-2.28963286990319, 52.462795797034545],
            [-2.289364420313063, 52.46136254724167],
            [-2.288389417162609, 52.461034097798624],
            [-2.288903342131667, 52.460626493892597],
            [-2.289065443039112, 52.459310857911099],
            [-2.290734797633549, 52.458481479265288],
            [-2.291001400209014, 52.456731364858491],
            [-2.287386576601721, 52.455302705657736],
            [-2.293781297322591, 52.451203711024029],
            [-2.295939902445621, 52.450542951750919],
            [-2.300973634482831, 52.447949199608196],
            [-2.303332167697109, 52.447516142450439],
            [-2.304114773368696, 52.4464281341535],
            [-2.306188799241095, 52.445931922315772],
            [-2.306973534522512, 52.444952668729279],
            [-2.30681750880229, 52.443884157531159],
            [-2.309601218950645, 52.441812782243915],
            [-2.31017253665385, 52.44083945956919],
            [-2.310147249329157, 52.437869212167769],
            [-2.311754047700867, 52.437517068807118],
            [-2.321554584305306, 52.436205228099844],
            [-2.325189901359023, 52.437482656796661],
            [-2.325959289167145, 52.438286945409303],
            [-2.329118568818766, 52.437433123241384],
            [-2.333123011952588, 52.435340703153507],
            [-2.3338242277652, 52.435869138195429],
            [-2.335372229651886, 52.435943867707479],
            [-2.337071996541217, 52.436629468561556],
            [-2.338779402228162, 52.435811884274251],
            [-2.340620127195904, 52.437030140862063],
            [-2.342745545349847, 52.437578682606478],
            [-2.343942088069624, 52.438790662813688],
            [-2.345112752512396, 52.439318562647728],
            [-2.350784360333675, 52.438292279989746],
            [-2.352779251213912, 52.438682803743696],
            [-2.355355265232971, 52.439806935193118],
            [-2.359729618589298, 52.439549171575855],
            [-2.36094031383586, 52.439729776569713],
            [-2.363531198540515, 52.4394116781937],
            [-2.363300702247548, 52.437144192818167],
            [-2.364159360220324, 52.431924610216782],
            [-2.362768826654979, 52.425763488585972],
            [-2.363832142783121, 52.425949910343739],
            [-2.363930730652239, 52.424529172852473],
            [-2.366562381372948, 52.423031380586686],
            [-2.366204779314509, 52.422289907714045],
            [-2.365182830365218, 52.4217617558892],
            [-2.365931629242603, 52.419138826455487],
            [-2.36468370013616, 52.417766300444889],
            [-2.366496221333072, 52.416476007042412],
            [-2.36766304979017, 52.414285089812836],
            [-2.366674623421146, 52.412107161336685],
            [-2.367225773994583, 52.410496219929598],
            [-2.365938798774414, 52.409176868429981],
            [-2.365797513930491, 52.408447308715097],
            [-2.366710765297629, 52.406542169228324],
            [-2.368897078699459, 52.404132753625333],
            [-2.372948789398302, 52.401950266749502],
            [-2.369964156121473, 52.40149126517273],
            [-2.364269156592501, 52.399231762868375],
            [-2.361315813360745, 52.398971128428911],
            [-2.359507672993291, 52.39838149818722],
            [-2.357333089386124, 52.398270325045871],
            [-2.357157725350594, 52.39784831934066],
            [-2.351575469270961, 52.395949267860026],
            [-2.347394237127944, 52.39368353864635],
            [-2.345975585119017, 52.393801876761593],
            [-2.343434969496274, 52.39307479663951],
            [-2.342185095242324, 52.393163830059464],
            [-2.334229537347888, 52.38894686086352],
            [-2.333586330770062, 52.38821868001412],
            [-2.335536320661269, 52.385333615139956],
            [-2.337239042971344, 52.385505871748862],
            [-2.338596343314797, 52.385082149057752],
            [-2.339548997139397, 52.384391668342992],
            [-2.342073504586352, 52.384818608231789],
            [-2.342458054711819, 52.385526816289676],
            [-2.343527857337163, 52.385927367250567],
            [-2.346579999493062, 52.385983180356014],
            [-2.349232958059551, 52.385538445481629],
            [-2.350149681164552, 52.385711039400704],
            [-2.350640142264976, 52.387714386491368],
            [-2.352636334252354, 52.388923012233633],
            [-2.354536015674745, 52.388893951550507],
            [-2.359373051988132, 52.38688352308592],
            [-2.360036186155919, 52.38676103581389],
            [-2.361337538792995, 52.38706452521204],
            [-2.36305518283994, 52.388138975025328],
            [-2.367355251819855, 52.388049264637331],
            [-2.370050315903907, 52.386676148426886],
            [-2.370848868705911, 52.386586439706555],
            [-2.371671288190833, 52.385840369576492],
            [-2.372480705422977, 52.385821637800063],
            [-2.372837470758977, 52.385266719007916],
            [-2.374488773053973, 52.384728379510157],
            [-2.374965354243602, 52.384966005723662],
            [-2.375642046869845, 52.384718424612529],
            [-2.377914680984008, 52.383136999701897],
            [-2.382646663501232, 52.384122370455287],
            [-2.387445303250969, 52.384474426313758],
            [-2.389300025079872, 52.383200716272995],
            [-2.388853123109476, 52.38266907180477],
            [-2.389182984208134, 52.382088120177919],
            [-2.390676456950076, 52.381514113078865],
            [-2.392043424277505, 52.38258390848582],
            [-2.392464736277145, 52.383704481407214],
            [-2.393597429351133, 52.384356994356459],
            [-2.401378398896738, 52.38256514495982],
            [-2.402236255494365, 52.383034210577307],
            [-2.402878606602517, 52.382746134416749],
            [-2.405381951068419, 52.383051327002057],
            [-2.406841710884493, 52.383942627867505],
            [-2.407428248568206, 52.385253169994989],
            [-2.407248408357662, 52.386146512989747],
            [-2.408627182171559, 52.387003908088403],
            [-2.410550121955505, 52.387118608179577],
            [-2.411116720086547, 52.38610973987403],
            [-2.411003956484016, 52.385043000111772],
            [-2.411769358239344, 52.38440293214871],
            [-2.411487535910994, 52.384116228919105],
            [-2.412394186246954, 52.383658165025061],
            [-2.413377616381655, 52.381985253981291],
            [-2.414730956330279, 52.381228033360237],
            [-2.415162257044705, 52.378969985505812],
            [-2.414800905781565, 52.377877150856698],
            [-2.413076307232164, 52.37677920080499],
            [-2.412944860902163, 52.376073931601816],
            [-2.411658916592167, 52.375535413444936],
            [-2.410323429586366, 52.374086348387856],
            [-2.410678724704043, 52.373592451839706],
            [-2.409626600095573, 52.372596397157103],
            [-2.410400349201921, 52.371451958267123],
            [-2.410021919934863, 52.371349885408975],
            [-2.410447895088413, 52.371035547593209],
            [-2.409923953283539, 52.370604939257383],
            [-2.410400153090216, 52.370005438400518],
            [-2.412093680972637, 52.369537446515402],
            [-2.413351283320314, 52.36863223547202],
            [-2.413954352880201, 52.368728116511903],
            [-2.414025630165868, 52.368340389585136],
            [-2.414926483101428, 52.368241033410357],
            [-2.416128967216367, 52.368512801903542],
            [-2.418130597320907, 52.368022959927686],
            [-2.420223688912604, 52.368044300453825],
            [-2.421926069539412, 52.367291146688004],
            [-2.422843003117844, 52.366265682740973],
            [-2.424487419327897, 52.366371262811121],
            [-2.425141297187132, 52.365947270927286],
            [-2.426339927553429, 52.365818885809638],
            [-2.429215089021043, 52.366837850257092],
            [-2.431187621221726, 52.367152514943392],
            [-2.435345507404424, 52.366266113296938],
            [-2.436856528321223, 52.366542826652257],
            [-2.437853925824724, 52.366413270796244],
            [-2.440692630790721, 52.366985278668928],
            [-2.445621059659647, 52.367091752005585],
            [-2.446605310387779, 52.367407186774955],
            [-2.446996476097679, 52.367165668813236],
            [-2.449102983509395, 52.367643149253389],
            [-2.451395574300984, 52.367496860330192],
            [-2.454990491183984, 52.367885828771342],
            [-2.456183827218868, 52.367389462092888],
            [-2.457655390134876, 52.367810804376781],
            [-2.458222949507916, 52.367731288297378],
            [-2.458368799631732, 52.367207492599803],
            [-2.460879338697008, 52.367545644689301],
            [-2.462860581352369, 52.366983202174161],
            [-2.463905841707771, 52.365120825896518],
            [-2.467751824966894, 52.364538376246841],
            [-2.468970320924675, 52.364775381404208],
            [-2.469828384107641, 52.365505572690743],
            [-2.469051000066547, 52.366013911950411],
            [-2.46954983654393, 52.366382326649045],
            [-2.472738112116609, 52.365956060169474],
            [-2.473655042084585, 52.366277832545975],
            [-2.473920763456365, 52.367091279701135],
            [-2.474773025495225, 52.367131909297257],
            [-2.476225379718232, 52.365615707549793],
            [-2.473427186028798, 52.362824706400318],
            [-2.473452047934868, 52.361991213757683],
            [-2.474515922037886, 52.361506867035644],
            [-2.473952841714078, 52.361034444291079],
            [-2.474183135307062, 52.360599292447944],
            [-2.475580521647714, 52.359725219070555],
            [-2.477930790711876, 52.360070846274262],
            [-2.478382145189271, 52.360800823033792],
            [-2.479175977327072, 52.360066700353059],
            [-2.479887323640783, 52.360259799227222],
            [-2.482517660020254, 52.360023428751845],
            [-2.483892676545579, 52.360478103055172],
            [-2.486745916838051, 52.358879605099816],
            [-2.486101242641565, 52.35834014939006],
            [-2.48515584755752, 52.356217847606231],
            [-2.488329938866004, 52.355383959104969],
            [-2.487726268609435, 52.354298635353722],
            [-2.486641350285329, 52.353774484745379],
            [-2.485882785658214, 52.353820760894472],
            [-2.483795202915548, 52.353170346332682],
            [-2.483483418519594, 52.352180899280995],
            [-2.481795075036117, 52.35110627333227],
            [-2.481633782424297, 52.34950487099389],
            [-2.48284580826034, 52.34891016244093],
            [-2.48341898633076, 52.347623112361838],
            [-2.482945265613489, 52.347529753887976],
            [-2.483297540647087, 52.346583438378175],
            [-2.48380360518571, 52.346413248821051],
            [-2.484357172619124, 52.344546403015109],
            [-2.482105726477212, 52.341610410107656],
            [-2.47966118090133, 52.340197207582797],
            [-2.47822400429454, 52.338408581817134],
            [-2.478384627001157, 52.337924255579246],
            [-2.481072860898846, 52.335841979854045],
            [-2.481014639051711, 52.33439928034668],
            [-2.480114550508252, 52.333814980232198],
            [-2.479860534749879, 52.332583447776656],
            [-2.481410696807285, 52.331679909905496],
            [-2.481798759992591, 52.331064292066877],
            [-2.482383229424555, 52.331506924083776],
            [-2.482879519932797, 52.331397911508418],
            [-2.482794759109557, 52.331160915061062],
            [-2.488054053789742, 52.330615162500806],
            [-2.490198252612527, 52.329442939898108],
            [-2.491066938205116, 52.329308072585668],
            [-2.494375875813686, 52.329300558787871],
            [-2.494732273503487, 52.329678455656413],
            [-2.495238421927179, 52.329668242746379],
            [-2.496223174163324, 52.329284719127173],
            [-2.497916164525739, 52.329379182681919],
            [-2.49804321289874, 52.329063088269599],
            [-2.50294788810808, 52.32898656034255],
            [-2.50377677285397, 52.328452600689559],
            [-2.505737119017045, 52.328059437184287],
            [-2.507001415802369, 52.328265287178809],
            [-2.507837887539668, 52.329039351890714],
            [-2.509984096888383, 52.329496697865835],
            [-2.513207528851625, 52.329200437927987],
            [-2.513913795211215, 52.330757179724898],
            [-2.513098477769757, 52.331188662324166],
            [-2.51259485425002, 52.332306542722051],
            [-2.511731217105487, 52.331988436547768],
            [-2.510631071006204, 52.332539807505889],
            [-2.509077104091649, 52.332424249079196],
            [-2.508412910167625, 52.33324342726484],
            [-2.508622307295645, 52.334737607601632],
            [-2.511345919119135, 52.336242504050354],
            [-2.512132176731471, 52.337096768503336],
            [-2.513499934711035, 52.337720147849943],
            [-2.513606527569804, 52.337546172163229],
            [-2.516394206048706, 52.337970043418167],
            [-2.517784243962257, 52.338608559259107],
            [-2.521909850648818, 52.341834086928145],
            [-2.523966334571489, 52.341838466440485],
            [-2.524994443204172, 52.342386801926153],
            [-2.525468057335326, 52.342097007175532],
            [-2.526818592409751, 52.342477571917115],
            [-2.529717860824271, 52.342621933206644],
            [-2.531469130283416, 52.343365651553611],
            [-2.533702416325836, 52.343662148646771],
            [-2.534147952069993, 52.34409076816204],
            [-2.53561255948983, 52.344303497616153],
            [-2.539152471199698, 52.344125566092302],
            [-2.538996505467611, 52.343130156371146],
            [-2.540968705846959, 52.341876885970038],
            [-2.546780733752368, 52.339806631712783],
            [-2.54838140871635, 52.338567542098332],
            [-2.549328641650507, 52.337191223246712],
            [-2.551221941872515, 52.337072720053179],
            [-2.554315735649613, 52.334720765300276],
            [-2.555507250221893, 52.334355555111728],
            [-2.557011196513096, 52.335029934976184],
            [-2.559357217991637, 52.334278046738206],
            [-2.560605833244355, 52.333442322337781],
            [-2.562221606785261, 52.333094808082052],
            [-2.562360016808772, 52.331858882803502],
            [-2.561683597801652, 52.331403596964613],
            [-2.560872590565749, 52.329820664438309],
            [-2.561907725481601, 52.327204960760966],
            [-2.561222572544135, 52.325937889368248],
            [-2.561618992954523, 52.325146656044545],
            [-2.562647392358198, 52.324555592630922],
            [-2.562703871464085, 52.323687758996485],
            [-2.563950968390796, 52.322876278124028],
            [-2.563952540800095, 52.322423159063348],
            [-2.562779202402566, 52.321415549107947],
            [-2.563383030086887, 52.32055679213552],
            [-2.564155434527595, 52.320265414377602],
            [-2.563994625716288, 52.319387828996867],
            [-2.564738849699286, 52.318843954081309],
            [-2.563930767500337, 52.317481288190976],
            [-2.562768382892342, 52.317210831210232],
            [-2.562288664078937, 52.316516367071898],
            [-2.562383565019034, 52.315670825228828],
            [-2.561944097045581, 52.315372640908663],
            [-2.562280244760218, 52.314463917390192],
            [-2.563469796084171, 52.313876572890095],
            [-2.563490293772645, 52.313411675420539],
            [-2.561923304662164, 52.312458077787234],
            [-2.562495034536112, 52.312315106415781],
            [-2.562647135835663, 52.311123163696045],
            [-2.563879930953635, 52.311521847253601],
            [-2.564841962780094, 52.312657222120947],
            [-2.56674416708682, 52.313320583228254],
            [-2.567634453380413, 52.31434300150746],
            [-2.570430007402005, 52.315109889119121],
            [-2.573383274028092, 52.317175046375688],
            [-2.57485531626492, 52.317562564134036],
            [-2.576486010603544, 52.316723009253927],
            [-2.578511131818295, 52.316885718989347],
            [-2.579993775295602, 52.316510741420714],
            [-2.582427486783049, 52.31505581474503],
            [-2.584503152685548, 52.313158487871142],
            [-2.587242094427925, 52.313510804140193],
            [-2.589806219668072, 52.313178866976443],
            [-2.595091921705026, 52.313872471610956],
            [-2.600528229388225, 52.310184082005449],
            [-2.602794976516179, 52.309361602413354],
            [-2.605814797445384, 52.3076253943144],
            [-2.610004108326907, 52.30626785871258],
            [-2.613031760446559, 52.30661988303283],
            [-2.614641521622066, 52.307631919052376],
            [-2.616058651244693, 52.307969762953753],
            [-2.617681795052079, 52.307616952265306],
            [-2.618037622686072, 52.306943510808857],
            [-2.619106912370332, 52.307369445825167],
            [-2.620697908467902, 52.307028449581409],
            [-2.621700830158685, 52.307844891445555],
            [-2.622725650696975, 52.308128980296289],
            [-2.623093967212354, 52.308454286088427],
            [-2.622615488606749, 52.309058264804776],
            [-2.622825711253587, 52.310136897415063],
            [-2.623842184306846, 52.309927450302943],
            [-2.624846361163358, 52.311032448968021],
            [-2.62573520025713, 52.311340606188324],
            [-2.625495588735597, 52.312240011739206],
            [-2.628076396363765, 52.313422028623677],
            [-2.626763499674775, 52.314666076612902],
            [-2.625631195847247, 52.31499033965509],
            [-2.626324993424027, 52.31602414743692],
            [-2.625570321110172, 52.31691459578996],
            [-2.626137682823831, 52.317615532481902],
            [-2.624539894138203, 52.318331533784715],
            [-2.623528183209381, 52.319519109880396],
            [-2.623682203399095, 52.319934549125513],
            [-2.623923929702683, 52.319701321465061],
            [-2.624414010665171, 52.319914499120337],
            [-2.623839084827727, 52.320553154634538],
            [-2.623937289557908, 52.321380645703329],
            [-2.625048937693408, 52.321452083728268],
            [-2.624824306450355, 52.321128721400129],
            [-2.625290065649878, 52.321072314024441],
            [-2.625870995521478, 52.321483690960214],
            [-2.626543573223728, 52.321215808445977],
            [-2.627254428131067, 52.321476350559017],
            [-2.627297910828168, 52.321023905643223],
            [-2.627956723096783, 52.321131884259117],
            [-2.627974759405166, 52.320747900934741],
            [-2.629403084969466, 52.320700739084906],
            [-2.629537543215121, 52.320974227662269],
            [-2.630787999400875, 52.321108703000043],
            [-2.630274813082099, 52.321543878435747],
            [-2.631016387988462, 52.322105410286191],
            [-2.630691277377416, 52.322663648929009],
            [-2.631929509480167, 52.323172175523027],
            [-2.631366410649143, 52.323503334720769],
            [-2.632200223123355, 52.323738915752052],
            [-2.632159030145891, 52.324142802303946],
            [-2.632823439749869, 52.324124859197667],
            [-2.633576483253962, 52.324559549723055],
            [-2.635095769927569, 52.32419627049606],
            [-2.636902929197159, 52.324467935232377],
            [-2.637208932178072, 52.325136963505138],
            [-2.636460610633538, 52.326465275157275],
            [-2.636935942409373, 52.327072256960527],
            [-2.636227425769713, 52.328314044757136],
            [-2.637099860030891, 52.32847206635153],
            [-2.638947479257636, 52.328076399183637],
            [-2.641017155601163, 52.328629776029011],
            [-2.641785075399602, 52.329675675985342],
            [-2.640861081229023, 52.32991264421954],
            [-2.641044922145708, 52.330448369108673],
            [-2.641594737930737, 52.330516406983271],
            [-2.640926826841134, 52.33090751682991],
            [-2.641458465174082, 52.331341560340711],
            [-2.640658473652589, 52.331530204522565],
            [-2.641017796829846, 52.332834549129771],
            [-2.643559114333829, 52.333813260149228],
            [-2.64586568347091, 52.333278330730877],
            [-2.646189289636902, 52.33252407016483],
            [-2.646812680244087, 52.33260336864339],
            [-2.647651877238347, 52.33168355403987],
            [-2.648264450134764, 52.332027219869524],
            [-2.649171056127549, 52.33170488126791],
            [-2.649567429850796, 52.331815080947173],
            [-2.649442103347743, 52.332375867759048],
            [-2.651254315363332, 52.33216360594966],
            [-2.651762630653713, 52.332500636297311],
            [-2.653080369310242, 52.332593149417534],
            [-2.65317756225588, 52.333014258424114],
            [-2.655597732072596, 52.333125814125331],
            [-2.65814724424752, 52.334238122489509],
            [-2.65934080347387, 52.334265628425953],
            [-2.659851385765059, 52.334749153780749],
            [-2.659750433131181, 52.335263065627338],
            [-2.659064922845447, 52.335254306555079],
            [-2.656685473552253, 52.337106981734465],
            [-2.6560528702794, 52.337203996783614],
            [-2.655969608488769, 52.337825690699816],
            [-2.658516632187117, 52.338554117821367],
            [-2.658346422047223, 52.338952439037385],
            [-2.659620345573043, 52.339242910922984],
            [-2.659407942227357, 52.339665743145936],
            [-2.66029470168357, 52.341355464746798],
            [-2.661173891151691, 52.34095946740549],
            [-2.662327508182555, 52.340953902943482],
            [-2.662879052485553, 52.341518997139147],
            [-2.666068461574098, 52.342287707322747],
            [-2.667251472399524, 52.342184833611576],
            [-2.668086944262296, 52.341618215590593],
            [-2.66967844353861, 52.341545371402383],
            [-2.670208732532428, 52.33972811562348],
            [-2.673499353325355, 52.337691966999699],
            [-2.674380765668781, 52.335911346488771],
            [-2.676016283789343, 52.334697291187013],
            [-2.675660004090602, 52.33452761449233],
            [-2.676226734260881, 52.33300410447584],
            [-2.678535900093375, 52.329503505479394],
            [-2.685277535480862, 52.324393120209614],
            [-2.686070421808634, 52.319498658506205],
            [-2.689287678091341, 52.316188548592265],
            [-2.688126583957834, 52.314914192303064],
            [-2.687238247135572, 52.314671231394399],
            [-2.68622481272816, 52.314780514476404],
            [-2.684389122794432, 52.31560120056379],
            [-2.679946631885813, 52.315186336617089],
            [-2.681028276394605, 52.313498903895024],
            [-2.680357470425379, 52.313088314641092],
            [-2.679812861309328, 52.312009909365656],
            [-2.680349043580126, 52.31206076347339],
            [-2.680647285386536, 52.31151782411775],
            [-2.681465413674943, 52.311207432545295],
            [-2.68453214486342, 52.311276001454118],
            [-2.688498993681387, 52.310459986606247],
            [-2.689045795631798, 52.310722912294146],
            [-2.691600658275122, 52.310513783597628],
            [-2.692480824255052, 52.310049216522003],
            [-2.692912921631132, 52.310292119125414],
            [-2.702949962393661, 52.309997244811875],
            [-2.706332255572319, 52.310265652346118],
            [-2.709321973464899, 52.309372066310623],
            [-2.709128711506408, 52.309118799007699],
            [-2.710597839618619, 52.308717086686393],
            [-2.710851524849014, 52.308896266645192],
            [-2.712395345277783, 52.3081236779108],
            [-2.714124483351651, 52.30884415205486],
            [-2.715397887719063, 52.31067947471783],
            [-2.719348265597656, 52.312684606892319],
            [-2.719828914513864, 52.316721958933272],
            [-2.721739316842257, 52.317464591606708],
            [-2.724669591536458, 52.319835391334884],
            [-2.726157478141244, 52.320297347616183],
            [-2.728119944068327, 52.320352691745612],
            [-2.730810856158191, 52.32018593016992],
            [-2.731098723484523, 52.319670797224568],
            [-2.733757021101713, 52.319398083701728],
            [-2.734271509897109, 52.319635828236947],
            [-2.737173520734372, 52.319463113563884],
            [-2.738109910645358, 52.320278986234428],
            [-2.738000551319242, 52.321289290463753],
            [-2.738723765100753, 52.322075922841115],
            [-2.741803751433098, 52.322798323198356],
            [-2.742792585306041, 52.323582362895159],
            [-2.743308106035902, 52.323613282738002],
            [-2.74401263886841, 52.32502752933415],
            [-2.745320298447657, 52.32583111570743],
            [-2.746177706806443, 52.324901489689339],
            [-2.746701538783707, 52.325250601966694],
            [-2.745492510029389, 52.326386533603845],
            [-2.745916464354225, 52.326641880696499],
            [-2.749490878145542, 52.327333986183376],
            [-2.750551406219447, 52.327398275090637],
            [-2.751449225085949, 52.326952935915713],
            [-2.752595413056879, 52.327482363453299],
            [-2.753389054369189, 52.327383803255749],
            [-2.755688199409491, 52.328661931820321],
            [-2.755973621732959, 52.329480028397427],
            [-2.756454723925371, 52.328779293884061],
            [-2.758518746929678, 52.329256930705412],
            [-2.759848456772401, 52.330211253562517],
            [-2.763221187436431, 52.331689102664875],
            [-2.765232573167966, 52.333440900274788],
            [-2.766988302747532, 52.336099655518751],
            [-2.758505052192997, 52.334459753587709],
            [-2.75706533983058, 52.336092653042947],
            [-2.755635970043275, 52.336530647352923],
            [-2.753338505270566, 52.335979829041747],
            [-2.753021061327933, 52.335349830018266],
            [-2.752148502323, 52.335130630564855],
            [-2.748381434897652, 52.334889344278423],
            [-2.748345995982165, 52.335832658949151],
            [-2.746569477775118, 52.336490309387635],
            [-2.745554161398284, 52.337642994865043],
            [-2.745515180452511, 52.338641171686945],
            [-2.744104506083164, 52.338805600681923],
            [-2.743209705544725, 52.33971206904036],
            [-2.74097815385674, 52.340909221291597],
            [-2.739904560123338, 52.340960004265803],
            [-2.737881445426659, 52.342783318416167],
            [-2.738041451256767, 52.344722437344842],
            [-2.739047512377041, 52.34528164010603],
            [-2.739767758576143, 52.346569045136583],
            [-2.738620987750599, 52.347522006456288],
            [-2.741181124804677, 52.349708605578101],
            [-2.738860077232295, 52.349622454568554],
            [-2.738546352082326, 52.350882165823023],
            [-2.736060330203755, 52.352268710063903],
            [-2.735527176161936, 52.353317607560882],
            [-2.733779044113946, 52.354235604533933],
            [-2.732768099723143, 52.355519406573841],
            [-2.735900456018884, 52.356078013411661],
            [-2.73825663782728, 52.356049826463519],
            [-2.741679813975327, 52.356782673106459],
            [-2.742567823005468, 52.357463057202871],
            [-2.743094814941873, 52.359116662279099],
            [-2.742693282687103, 52.359340349556497],
            [-2.748618674913657, 52.359009863952849],
            [-2.749279230642249, 52.359256506576045],
            [-2.752119011398017, 52.359247451229166],
            [-2.758849493546864, 52.358859179941632],
            [-2.759622597582464, 52.359243493156583],
            [-2.761444140870969, 52.359454726889332],
            [-2.766851442159538, 52.359485379780836],
            [-2.770187054977162, 52.358771423430589],
            [-2.774804675726415, 52.358321378907988],
            [-2.776773897943041, 52.358410937055311],
            [-2.77853976124585, 52.358041496623478],
            [-2.781831755966684, 52.358200461716294],
            [-2.783040851481816, 52.358650062713899],
            [-2.786119263270673, 52.357527399831163],
            [-2.788868585480799, 52.357969379032433],
            [-2.790907150763111, 52.357829883430021],
            [-2.79242114935981, 52.356850576376125],
            [-2.795399504938416, 52.359251665498057],
            [-2.795624717668755, 52.360426088217807],
            [-2.79544530753944, 52.361229236456339],
            [-2.793662539357788, 52.362703058750441],
            [-2.793602987131977, 52.363722965087184],
            [-2.792126679196542, 52.365096709334821],
            [-2.793684197215814, 52.367925408783861],
            [-2.793396058764577, 52.368402034458562],
            [-2.794228637114581, 52.371010833006267],
            [-2.793653031786553, 52.372167262901101],
            [-2.792804004413996, 52.372575730314566],
            [-2.792113186759704, 52.374520478881564],
            [-2.792702071214922, 52.375413765088339],
            [-2.793652014143891, 52.374322254274425],
            [-2.796930632974269, 52.373586352668163],
            [-2.798651395710921, 52.372646930245693],
            [-2.801127072283166, 52.373898707346413],
            [-2.803471939319634, 52.374248691834012],
            [-2.807389838504123, 52.375954423585306],
            [-2.806995512564835, 52.377772267562996],
            [-2.808005466154364, 52.378213983416266],
            [-2.809214623019149, 52.37966663884778],
            [-2.809076869787101, 52.383863371969269],
            [-2.807496458676439, 52.385178679637896],
            [-2.80543837870058, 52.386088162563013],
            [-2.805433452448989, 52.38823868207642],
            [-2.8076551162179, 52.388637972358609],
            [-2.810380105392869, 52.387954931147632],
            [-2.812309936599033, 52.389024116343776],
            [-2.813937309034438, 52.389223292061459],
            [-2.814588089949414, 52.39001175464243],
            [-2.815733175122271, 52.390430896183524],
            [-2.817284957595149, 52.390511875401081],
            [-2.81822375731792, 52.391216513733525],
            [-2.823254587071477, 52.391336206819645],
            [-2.826203238847855, 52.391066595874612],
            [-2.828462652097989, 52.391188329613897],
            [-2.832463423412377, 52.391934281746735],
            [-2.840742955441712, 52.392335073376287],
            [-2.849781895228369, 52.395080801306563],
            [-2.852244241390284, 52.395423582190617],
            [-2.855442416191641, 52.395284499655801],
            [-2.856805176088697, 52.394167915906039],
            [-2.858075943092163, 52.393691197709806],
            [-2.863786247373968, 52.388700407392804],
            [-2.874093519034899, 52.386584701223988],
            [-2.874652344903768, 52.386906017533335],
            [-2.877860822394525, 52.386209746056082],
            [-2.879546343212283, 52.386219688341001],
            [-2.879595624656274, 52.386703003445511],
            [-2.883280182964159, 52.386674620672707],
            [-2.883809294855284, 52.386902616115769],
            [-2.88559704572913, 52.385778920396334],
            [-2.888458214812531, 52.385140703065304],
            [-2.888731590530084, 52.383901571188858],
            [-2.887824352009909, 52.382841233004619],
            [-2.888129572838013, 52.382529670225921],
            [-2.887844612410298, 52.381654350386107],
            [-2.886984408203854, 52.381178027058326],
            [-2.887265822548533, 52.38004582304287],
            [-2.887910896742293, 52.379978943799138],
            [-2.886599065426779, 52.379397226831891],
            [-2.886938661661036, 52.37874916852541],
            [-2.886507594955778, 52.37849797550701],
            [-2.88765394436627, 52.377789018097452],
            [-2.8882363010496, 52.378039069768285],
            [-2.888424513595528, 52.377462269883921],
            [-2.889286133948568, 52.377646384541244],
            [-2.889156087358405, 52.376876886803323],
            [-2.890410903688821, 52.376379259174939],
            [-2.89056496307265, 52.376004996823632],
            [-2.891756239871329, 52.376219872788575],
            [-2.892591539949273, 52.375973522134878],
            [-2.892726975221794, 52.375623671636056],
            [-2.891104545930767, 52.375517245509805],
            [-2.891070177125869, 52.374758715420683],
            [-2.891301863484019, 52.3745196214945],
            [-2.892621681747022, 52.374481787867559],
            [-2.893136419354147, 52.374075128298458],
            [-2.895324843439839, 52.374351652636918],
            [-2.892809738980353, 52.379143683832147],
            [-2.893062226079853, 52.37964343951041],
            [-2.894441494897366, 52.380068141767509],
            [-2.896899475784494, 52.379639548292907],
            [-2.897827720942961, 52.379772748922022],
            [-2.898206693834783, 52.381506812213232],
            [-2.899166257824311, 52.381588519127511],
            [-2.902465627861391, 52.382937098120891],
            [-2.903337591634953, 52.384195383199568],
            [-2.90409478930213, 52.384292981738291],
            [-2.905342197412092, 52.385082676762849],
            [-2.905426785795286, 52.385769793334859],
            [-2.913289964663461, 52.386237917453421],
            [-2.915984418243643, 52.38579721069047],
            [-2.917686984994546, 52.386274876465997],
            [-2.919557873417938, 52.384527882995656],
            [-2.917862875571622, 52.384200325032204],
            [-2.917467688225908, 52.383848273300757],
            [-2.916255204571899, 52.383760584797422],
            [-2.915522493978501, 52.382859132839528],
            [-2.914457617696243, 52.38230547866835],
            [-2.914115158091653, 52.382441186315127],
            [-2.914008601601358, 52.382119255292793],
            [-2.91538122539494, 52.381001911706086],
            [-2.916401308435043, 52.380883422999482],
            [-2.919732518036451, 52.378368996791643],
            [-2.920362947445316, 52.376895055129566],
            [-2.922124990293472, 52.376998192368205],
            [-2.922500039865603, 52.376455840124386],
            [-2.922312368310175, 52.375133916387789],
            [-2.916776975097519, 52.374409214144386],
            [-2.914694691137536, 52.373802316923559],
            [-2.913516189179731, 52.372500632428896],
            [-2.910347138771641, 52.372110633877],
            [-2.908491833855303, 52.370172196791366],
            [-2.907276641179208, 52.369861475805315],
            [-2.906051528476894, 52.37021341176704],
            [-2.903120718026245, 52.37004255685293],
            [-2.902114363803334, 52.369598926413595],
            [-2.900806492472215, 52.36725701383331],
            [-2.902382963446493, 52.367067872481037],
            [-2.9030832844648, 52.367559691373778],
            [-2.904713948507208, 52.367722528927374],
            [-2.905025811292948, 52.368177752500792],
            [-2.90616984351207, 52.36802873312962],
            [-2.906471122898752, 52.368324903761312],
            [-2.907154200970957, 52.368330449509116],
            [-2.910648405771162, 52.368048241237197],
            [-2.911706626920559, 52.368583101301084],
            [-2.914036952516594, 52.368697255324335],
            [-2.914492643708431, 52.368502233162928],
            [-2.915185407742665, 52.368834009742677],
            [-2.917465863080508, 52.3677455641538],
            [-2.918647614518822, 52.368142748587616],
            [-2.919927892404378, 52.367837901155404],
            [-2.920386519288747, 52.368280254299755],
            [-2.924069656809219, 52.367132196953762],
            [-2.926144729330617, 52.366981991550958],
            [-2.928093993985239, 52.366085633204847],
            [-2.928366741545741, 52.365224904069485],
            [-2.928896394188712, 52.365059812212536],
            [-2.930553927781031, 52.36279736142275],
            [-2.931156000461423, 52.362865440206868],
            [-2.933929559977467, 52.361725136741178],
            [-2.93464726993904, 52.362193257228114],
            [-2.936251882979815, 52.362238989797596],
            [-2.936690824732999, 52.36195410960844],
            [-2.936668574734579, 52.362221302166745],
            [-2.938274905308755, 52.361516291571562],
            [-2.938138654729634, 52.361046274272525],
            [-2.938890496750904, 52.361044796853157],
            [-2.935361076814733, 52.35872179169597],
            [-2.934650322598724, 52.357676433662512],
            [-2.93189553851948, 52.356276806449046],
            [-2.930656910005262, 52.354980258508462],
            [-2.930818441148856, 52.35386327096392],
            [-2.931387585335915, 52.353278900918895],
            [-2.934243856107213, 52.35406998287452],
            [-2.934556689983836, 52.353100135589862],
            [-2.936172390723131, 52.352921918184428],
            [-2.935631289988087, 52.351424798750216],
            [-2.933150235220389, 52.350039214938192],
            [-2.944368610416082, 52.34998602550521],
            [-2.947484056134917, 52.34923374947163],
            [-2.948910128350199, 52.349395818684485],
            [-2.952913383598971, 52.348767524897454],
            [-2.95386466391554, 52.349242635712713],
            [-2.954652337319661, 52.349141872795698],
            [-2.957553196493089, 52.349679399584403],
            [-2.959020258064767, 52.349087612740014],
            [-2.960357787984801, 52.349226887454314],
            [-2.960703829481692, 52.349680789961106],
            [-2.961123547752304, 52.349605451965203],
            [-2.961629739622795, 52.350605568677281],
            [-2.96339002334841, 52.351413854717634],
            [-2.968890426269498, 52.351910096224422],
            [-2.970531213370327, 52.35134371285654],
            [-2.971704344400861, 52.352349993284406],
            [-2.97265399171415, 52.354142072120538],
            [-2.974504282921269, 52.354687829195775],
            [-2.976426843454321, 52.353914056619317],
            [-2.978372520771857, 52.35385750155195],
            [-2.978932507702013, 52.352754225324034],
            [-2.979909851235218, 52.352333458998565],
            [-2.980602661900036, 52.352590232276484],
            [-2.981938635409084, 52.352121519151453],
            [-2.982814201671571, 52.353269515644314],
            [-2.983435735323476, 52.353290415540734],
            [-2.985343879911685, 52.351086229924157],
            [-2.985926240322105, 52.350865599131083],
            [-2.986581322964931, 52.350878110928946],
            [-2.987476659366991, 52.351393876717026],
            [-2.989426555295323, 52.350677201607603],
            [-2.990866794537328, 52.351660377612134],
            [-2.992411244667023, 52.351525142178261],
            [-2.992535651443945, 52.350852506900544],
            [-2.993454501564979, 52.350646093125427],
            [-2.99547941033017, 52.351705209317309],
            [-2.998213479935878, 52.351913193843131],
            [-3.000005919574674, 52.351707435508715],
            [-3.003391183511754, 52.350976571753101],
            [-3.002725968720737, 52.349933028273121],
            [-3.004245148984377, 52.349205377861502],
            [-3.005849059565791, 52.349043388297218],
            [-3.010224982044469, 52.349535587993863],
            [-3.010464456604044, 52.349156837361875],
            [-3.013227189436588, 52.348246708123149],
            [-3.021806614912531, 52.346524844777186],
            [-3.026477296966219, 52.346816100803871],
            [-3.031901067100353, 52.345654019678982],
            [-3.035986650341468, 52.345282911759845],
            [-3.036832359270156, 52.344787305485518],
            [-3.041058819538808, 52.344294311793853],
            [-3.046554634986951, 52.346522153196915],
            [-3.0493517021962, 52.346913601218453],
            [-3.052038323798988, 52.347721329981233],
            [-3.057189948634, 52.347543176407257],
            [-3.0606650847367, 52.348226772957752],
            [-3.065214574163202, 52.350053173210739],
            [-3.065300748395845, 52.353331248808452],
            [-3.066008145302232, 52.353318574169656],
            [-3.06936647118864, 52.354491156954147],
            [-3.069452189973919, 52.3549399082967],
            [-3.070558076660007, 52.35501080670069],
            [-3.070544406655408, 52.355963928435315],
            [-3.072097985100623, 52.356269903239209],
            [-3.072149849603423, 52.356653328850264],
            [-3.074188503342626, 52.357402602185573],
            [-3.077081898669414, 52.358153940448553],
            [-3.077518360366502, 52.358650733836193],
            [-3.078694145958259, 52.358512338183878],
            [-3.082796316846776, 52.359327115401427],
            [-3.084096342313903, 52.360004768223575],
            [-3.085339941717424, 52.360715291177236],
            [-3.0878675377192, 52.361205404151754],
            [-3.09207244304736, 52.362655445541478],
            [-3.094025279057775, 52.363948200664097],
            [-3.094100804376366, 52.364567849263139],
            [-3.095054015462773, 52.364867394006865],
            [-3.095231248988084, 52.365560720676548],
            [-3.097589042607326, 52.36682808515323],
            [-3.097609378581905, 52.367944522368305],
            [-3.098491186711051, 52.368381359217082],
            [-3.099152495935659, 52.368455224775751],
            [-3.099754498022912, 52.367856246568564],
            [-3.100758859528192, 52.367786656855728],
            [-3.102212604975797, 52.370546688373047],
            [-3.101687536780451, 52.371108999317279],
            [-3.103059920135575, 52.372247883917545],
            [-3.103877224244791, 52.373626593012155],
            [-3.105629258683477, 52.373904202381652],
            [-3.106201023811337, 52.374505712584472],
            [-3.107272806467431, 52.374659301467311],
            [-3.110605984514645, 52.377179535528512],
            [-3.118707384167777, 52.377626361194523],
            [-3.120212098127601, 52.377467357538244],
            [-3.122463323267389, 52.37905530219841],
            [-3.126153036374564, 52.380527910251566],
            [-3.127107918598256, 52.381389990347188],
            [-3.12797172048161, 52.38340372252555],
            [-3.129110830532635, 52.383765951325465],
            [-3.129540692328797, 52.384489178507849],
            [-3.130532482220837, 52.384489583638725],
            [-3.133620003475462, 52.385369848151086],
            [-3.134644242934576, 52.385145143774814],
            [-3.141246007765039, 52.386857204369008],
            [-3.147711257364096, 52.387365488936922],
            [-3.149098979573854, 52.388124291815316],
            [-3.15009322585078, 52.388102931876361],
            [-3.152229316850586, 52.387352089702645],
            [-3.154445482552089, 52.38770177038036],
            [-3.1556818096332, 52.389635243696645],
            [-3.156768744755986, 52.389785540024938],
            [-3.158236944036533, 52.391532410174079],
            [-3.160806883124533, 52.391353455633769],
            [-3.161528559170668, 52.392142029401811],
            [-3.161983406845572, 52.393736978829388],
            [-3.160313207217273, 52.395110064472334],
            [-3.160423016288256, 52.396102442773497],
            [-3.159208378409764, 52.398039240611212],
            [-3.159958966173719, 52.398582097781848],
            [-3.16058040441881, 52.399915588906687],
            [-3.164498163411129, 52.400667320926011],
            [-3.165068342336687, 52.401666845212063],
            [-3.166915255957853, 52.40247485588214],
            [-3.170140669925858, 52.40299138975417],
            [-3.171637876975763, 52.403965517850814],
            [-3.171256911103607, 52.404233616098381],
            [-3.171721849416331, 52.404638077789052],
            [-3.17157304436, 52.405401951715142],
            [-3.172746064995518, 52.405732858268678],
            [-3.172943286612138, 52.406517574406848],
            [-3.177195068194484, 52.408355227562282],
            [-3.178835194304699, 52.409383582887038],
            [-3.186335054001447, 52.411709921395541],
            [-3.18738233144368, 52.411618490356361],
            [-3.188402547372379, 52.412161156274557],
            [-3.188064689718239, 52.412992582765739],
            [-3.190151973918593, 52.413466064728567],
            [-3.191579340437418, 52.414546733534451],
            [-3.194607778931912, 52.414477505678526],
            [-3.195858826927838, 52.414905392512139],
            [-3.198006981428886, 52.415773705042739],
            [-3.1989837367313, 52.416928078571623],
            [-3.205433821945689, 52.41787930921604],
            [-3.207525266669784, 52.418704873334129],
            [-3.208549466589391, 52.418679122217434],
            [-3.210030224678963, 52.419162949673769],
            [-3.211532028979643, 52.419286920419552],
            [-3.211712867414769, 52.418964103654211],
            [-3.213745442899578, 52.419728126820168],
            [-3.214075804166315, 52.420459263359476],
            [-3.215679649941074, 52.420334892969194],
            [-3.219542628439695, 52.421239111356662],
            [-3.219236245715424, 52.422019955950702],
            [-3.219992533833201, 52.423260043579639],
            [-3.220806927414798, 52.423674192379487],
            [-3.220514544778589, 52.424056613412276],
            [-3.220862699767475, 52.425000623717914],
            [-3.222252771718046, 52.426765491763639],
            [-3.223884217131015, 52.427417510349791],
            [-3.225568088821745, 52.42916221543549],
            [-3.230684656307801, 52.4304693329805],
            [-3.230833952352402, 52.431815464095578],
            [-3.230185917502939, 52.432154860064387],
            [-3.230376723217245, 52.433460101882602],
            [-3.229511189823854, 52.433966284546209],
            [-3.23019829129817, 52.434658602085534],
            [-3.230134828797688, 52.435231960930075],
            [-3.228812197800889, 52.436493604727517],
            [-3.229643029861605, 52.437644747649301],
            [-3.229663503361558, 52.438379961874887],
            [-3.22847323039345, 52.439062131766391],
            [-3.22831764610888, 52.439497991827132],
            [-3.2307193976369, 52.439950412908672],
            [-3.231522310128648, 52.439727177954822],
            [-3.232540250912988, 52.439987185821138],
            [-3.233246291860446, 52.439666951954614],
            [-3.233586775699853, 52.440637975736777],
            [-3.234826204213646, 52.44129393430989],
            [-3.235540826840674, 52.442489404235424],
            [-3.234865329814941, 52.443166257336777],
            [-3.2352997711469, 52.443654401142425],
            [-3.235070068259062, 52.444910083215781],
            [-3.234284523425746, 52.444757355619835],
            [-3.233454565996151, 52.446121791054232],
            [-3.234266107976912, 52.448624380615676],
            [-3.23342434751652, 52.451148717161949],
            [-3.231656615277358, 52.452926591351492],
            [-3.231948325774867, 52.453044025571657],
            [-3.230869304280212, 52.453190120707532],
            [-3.230291679232741, 52.453743655453508],
            [-3.227739115749408, 52.454562251987333],
            [-3.227894344854939, 52.454904977290745],
            [-3.226730512384755, 52.455178681435484],
            [-3.22693489966541, 52.455331197878095],
            [-3.225545555106045, 52.455858065580848],
            [-3.22569725194239, 52.456074063888813],
            [-3.225085151718998, 52.456078608997124],
            [-3.224783120519785, 52.456490806505919],
            [-3.22298640731303, 52.457136941154701],
            [-3.222670547673137, 52.457847761281492],
            [-3.221719999458128, 52.458121008106538],
            [-3.220694836989527, 52.459155614997023],
            [-3.219609341184722, 52.459553407628725],
            [-3.219681215822646, 52.460023769124049],
            [-3.218312342436805, 52.460873997638586],
            [-3.217600264682921, 52.46237825127924],
            [-3.216879008273313, 52.462697646050181],
            [-3.215885710538239, 52.465537174343588],
            [-3.213669420538589, 52.466360095131222],
            [-3.211975689797915, 52.468149492002816],
            [-3.211241920516218, 52.468232529822821],
            [-3.208163438061203, 52.46994074621356],
            [-3.206167865028539, 52.470288369785727],
            [-3.203999478705013, 52.471317399465669],
            [-3.202000627028202, 52.47279059353783],
            [-3.196849676496259, 52.474597773681502],
            [-3.197238943304727, 52.475974769906323],
            [-3.194429121921452, 52.476388871437493],
            [-3.180590781088975, 52.473821690993319],
            [-3.17869815201184, 52.474399775315845],
            [-3.17101538195999, 52.478713292603707],
            [-3.157527772770052, 52.485162738851209],
            [-3.148290783503888, 52.487405978919838],
            [-3.145328955257061, 52.488533323445466],
            [-3.1448962251954, 52.488827003865019],
            [-3.142552010784051, 52.490832953913674],
            [-3.140386031057443, 52.491652203329863],
            [-3.128258492730772, 52.492058891135102],
            [-3.124872659409854, 52.492822056122584],
            [-3.120768355423447, 52.49447119239143],
            [-3.117839321747932, 52.494730859748863],
            [-3.117176539199455, 52.495895081504955],
            [-3.114890118575657, 52.496989204336224],
            [-3.1135472099843, 52.498233533971444],
            [-3.111547216134716, 52.498835818526146],
            [-3.108124717588879, 52.499285980109327],
            [-3.098541429875544, 52.499734077988784],
            [-3.09366282822012, 52.499039376138427],
            [-3.089958157021152, 52.499167068236204],
            [-3.085023550223569, 52.498522872761733],
            [-3.08247119372348, 52.498843836389064],
            [-3.080286136251392, 52.498765816129712],
            [-3.07576567891503, 52.499389585175138],
            [-3.063173879015689, 52.499836065577298],
            [-3.059527753865682, 52.499586482435703],
            [-3.055790488203299, 52.499957932733508],
            [-3.046365806671643, 52.499047343650091],
            [-3.04466360440025, 52.499263771098661],
            [-3.044270111697678, 52.500126719307701],
            [-3.040262517670937, 52.500162028831511],
            [-3.04039582184236, 52.50062925162775],
            [-3.02920405942087, 52.501253084082258],
            [-3.029724139318253, 52.502263562968984],
            [-3.03052476873336, 52.502544280781933],
            [-3.030776864119095, 52.503055430509917],
            [-3.033479704839914, 52.503785236103262],
            [-3.03457787025797, 52.505001914134972],
            [-3.034281194718575, 52.506359346011735],
            [-3.032112816245647, 52.509268662419103],
            [-3.032184316514055, 52.509741826892039],
            [-3.036365435996893, 52.511408035413147],
            [-3.035969128758795, 52.51285175057032],
            [-3.036712633521633, 52.513262387184902],
            [-3.03538817922366, 52.514841891219774],
            [-3.034353161929606, 52.51513323932447],
            [-3.033474301985347, 52.517306678136485],
            [-3.032673235975277, 52.517585179071979],
            [-3.032016140663575, 52.51910217793484],
            [-3.031680634437811, 52.519438643090893],
            [-3.03079795096413, 52.519506571260727],
            [-3.032380776844765, 52.523716400679547],
            [-3.030822515703869, 52.52382887940918],
            [-3.030431400319141, 52.523176895628758],
            [-3.029234728261245, 52.523498374288806],
            [-3.028792068938657, 52.52398230417748],
            [-3.027806628361099, 52.523630358632133],
            [-3.026041573686585, 52.523850655620613],
            [-3.024376492048316, 52.523554019653218],
            [-3.024478958502772, 52.523080244636297],
            [-3.023115730239469, 52.52302281515145],
            [-3.023014966633001, 52.522619124551397],
            [-3.02237474414035, 52.522658821233392],
            [-3.02235511506403, 52.522384787809614],
            [-3.022166515475672, 52.522704672572601],
            [-3.021408422977862, 52.522555687690165],
            [-3.020326789308561, 52.523136800577291],
            [-3.019732567324533, 52.523126639807614],
            [-3.018985521874705, 52.522690754903415],
            [-3.016636719913505, 52.522269537790997],
            [-3.01611523945689, 52.521770562774869],
            [-3.013962598932111, 52.521735987194887],
            [-3.013107668802664, 52.522116406242368],
            [-3.012390318524982, 52.521942741165198],
            [-3.011990712992539, 52.521293466983074],
            [-3.011293628898598, 52.521359661825464],
            [-3.01063120057342, 52.520046451046674],
            [-3.009051440353951, 52.520439323076907],
            [-3.008416000662696, 52.520300896454458],
            [-3.008317288373592, 52.520559758082534],
            [-3.007511315824706, 52.520311300600476],
            [-3.00677756499663, 52.520578263790753],
            [-3.006280492971094, 52.520367625253066],
            [-3.006430384948609, 52.519703768519257],
            [-3.005732982716569, 52.51994883946773],
            [-3.003875955902687, 52.519818068932537],
            [-3.003742630205963, 52.5214041816281],
            [-3.001476177681789, 52.522520197319189],
            [-3.000907702242057, 52.524095603669352],
            [-3.001129104456748, 52.524627751165561],
            [-3.0023196909611, 52.524999757108574],
            [-3.002112837684526, 52.525884351183123],
            [-3.005077320951543, 52.529446314399088],
            [-3.00421136441134, 52.529547165103615],
            [-3.003396918585281, 52.530937671846836],
            [-3.001816675917667, 52.531976846990219],
            [-3.002281329942263, 52.532312743103546],
            [-3.003761447410649, 52.532377513163297],
            [-3.003879835942844, 52.533560522752403],
            [-3.003205440452635, 52.533984287881438],
            [-3.002692157866882, 52.535267948153681],
            [-3.002963594503309, 52.535857205012285],
            [-3.002029902569392, 52.536614899932623],
            [-3.00165888318614, 52.538515877734021],
            [-2.999969341606544, 52.54040372877752],
            [-2.999784196275856, 52.542118828893543],
            [-2.998839449067704, 52.543371951501094],
            [-2.998203994521689, 52.545325492388869],
            [-2.99837493439235, 52.546359722828647],
            [-2.997713380824802, 52.547360518001767],
            [-2.99776739675216, 52.548051409641971],
            [-2.996241287837724, 52.549299520199597],
            [-2.995644343616583, 52.550682741763964],
            [-2.994724431847724, 52.551286527312442],
            [-2.994309670229038, 52.553033212173212],
            [-2.996505614543765, 52.553219720097545],
            [-2.997769676322748, 52.55393727663192],
            [-2.99846051080029, 52.553962017410946],
            [-2.998667500828593, 52.554438549657185],
            [-2.999722578795998, 52.554654397449355],
            [-2.999377613403522, 52.55557071491841],
            [-2.998020061211573, 52.556456016923271],
            [-2.997911584072809, 52.558045498897449],
            [-2.998643339153072, 52.558180473185566],
            [-2.998657951597378, 52.558628960531877],
            [-2.999447430140525, 52.55896662119369],
            [-2.999467711466406, 52.559664088861972],
            [-2.998472995626559, 52.561396802569398],
            [-2.998848869288675, 52.562032833659899],
            [-2.998549934741107, 52.562510936803712],
            [-2.998091348720151, 52.562520198712683],
            [-2.99760619064462, 52.563828764573906],
            [-2.998543639524232, 52.564506811083014],
            [-2.998580687003484, 52.565228410281172],
            [-3.000162505476599, 52.565629502713271],
            [-3.000918740133308, 52.567416651948363],
            [-3.000568304373261, 52.5679662176072],
            [-3.001628816271059, 52.568858963732282],
            [-3.002396820045293, 52.568828189202542],
            [-3.003143489173664, 52.569284858272717],
            [-3.002949095236811, 52.570076742254173],
            [-3.006486311182212, 52.573834282009237],
            [-3.008013777358392, 52.574357998806732],
            [-3.009820513471193, 52.574446886533671],
            [-3.014442493145145, 52.575482594685916],
            [-3.016846035875806, 52.575150014288461],
            [-3.01862208041847, 52.574485654151836],
            [-3.020453030974992, 52.574599343874191],
            [-3.020947740411529, 52.574170741612583],
            [-3.022835395554289, 52.574058251720118],
            [-3.028338057653506, 52.572863430497307],
            [-3.034776926489709, 52.572150138022771],
            [-3.036453503308221, 52.571789347877726],
            [-3.039401826698112, 52.570299891758715],
            [-3.041269285439103, 52.568907977908324],
            [-3.043879647191312, 52.568112728021298],
            [-3.043870629266193, 52.567360326784957],
            [-3.044782283709639, 52.566795789541672],
            [-3.045601774094641, 52.565582965679212],
            [-3.045119012760851, 52.565507216169166],
            [-3.045384966219191, 52.56515155216934],
            [-3.046483219651068, 52.564681548609755],
            [-3.046650140613425, 52.563575174775103],
            [-3.047285618140918, 52.563683729741953],
            [-3.048840411695278, 52.562997494490197],
            [-3.049756060886257, 52.56310085621768],
            [-3.049916517975435, 52.560677466358797],
            [-3.051999543216282, 52.559571153369674],
            [-3.053808672696166, 52.55928894930112],
            [-3.053544451799341, 52.558973946227205],
            [-3.054073041153933, 52.558810112944769],
            [-3.0570058425452, 52.558576284133906],
            [-3.058071745226778, 52.557877209345826],
            [-3.05981809361707, 52.555545699294917],
            [-3.060548302795431, 52.555304513185803],
            [-3.062593573534555, 52.555953244988338],
            [-3.062908790166391, 52.556423301452732],
            [-3.06500543526121, 52.556814409437678],
            [-3.069239344793951, 52.554955711496127],
            [-3.070962233354619, 52.555262884478232],
            [-3.070760112873574, 52.554473571060832],
            [-3.07130779063661, 52.554008314093402],
            [-3.074570469914712, 52.553709928284768],
            [-3.080672790916922, 52.551868901278404],
            [-3.087276935695731, 52.551316648010044],
            [-3.086100876722782, 52.548724762957569],
            [-3.090557697357172, 52.548548025795562],
            [-3.090638156404251, 52.547926955286599],
            [-3.092312176394629, 52.547815320755646],
            [-3.092197965195238, 52.547313818234123],
            [-3.09296690612489, 52.547219513771836],
            [-3.093944162114607, 52.544887395718462],
            [-3.090560992363781, 52.542733082134085],
            [-3.08792004703472, 52.542555108071809],
            [-3.086267087322368, 52.541899616937869],
            [-3.083516460535741, 52.541456439245458],
            [-3.083199086618014, 52.539585769816938],
            [-3.080752039473663, 52.539128960893912],
            [-3.0813316819076, 52.538716406007133],
            [-3.081337269565894, 52.53804388171487],
            [-3.082036105882732, 52.537922418287657],
            [-3.081924723686345, 52.537230285650914],
            [-3.082550978102001, 52.537215568065953],
            [-3.082619096233461, 52.536208931751332],
            [-3.083757552807122, 52.535500864490153],
            [-3.084332677029691, 52.535625955133902],
            [-3.084599955964375, 52.534985195055178],
            [-3.084943492761184, 52.535285018699227],
            [-3.085371171908658, 52.535170516224781],
            [-3.085413303474875, 52.534487766604897],
            [-3.086776615816862, 52.534590329671687],
            [-3.086823551604476, 52.534341766497313],
            [-3.088774765086989, 52.533655850830492],
            [-3.090162995946163, 52.53387232233591],
            [-3.091118972174676, 52.53318474391655],
            [-3.092112747820114, 52.533308632847557],
            [-3.093221251196266, 52.532946876390589],
            [-3.094280398406123, 52.533328164826372],
            [-3.094650495839629, 52.532856347065021],
            [-3.095306222823453, 52.53272531417042],
            [-3.09522641572672, 52.532361945569463],
            [-3.09612406738956, 52.532118986997347],
            [-3.096957969585498, 52.532630899144984],
            [-3.097927570634683, 52.532612916550775],
            [-3.100148473269974, 52.530753771420073],
            [-3.102994976719386, 52.530807286311763],
            [-3.10309342943486, 52.530382924857506],
            [-3.103802046478523, 52.530658612714355],
            [-3.103951201224177, 52.530316488420162],
            [-3.104561324225161, 52.530602979943865],
            [-3.105820504286014, 52.530260375783051],
            [-3.106941571287846, 52.530346989258781],
            [-3.106878214908997, 52.530113833711944],
            [-3.107341669590576, 52.530136468516339],
            [-3.107171173763809, 52.529629211996131],
            [-3.10769698537049, 52.529493931367071],
            [-3.108028796508618, 52.529850437466429],
            [-3.108440308467958, 52.529742295700174],
            [-3.108683414965278, 52.529152051354366],
            [-3.110252125011351, 52.529453802154428],
            [-3.110932597416655, 52.528905299171434],
            [-3.111599606375737, 52.529050072443745],
            [-3.112102808820431, 52.52877923067836],
            [-3.113107111536407, 52.529320894290969],
            [-3.113473407479998, 52.528766341238516],
            [-3.113999451399384, 52.5290490803241],
            [-3.115450082902973, 52.528936520960869],
            [-3.1156766650816, 52.528628713772889],
            [-3.116522117882591, 52.528892247091157],
            [-3.116989890990451, 52.528446407351467],
            [-3.117859681457023, 52.52873936947212],
            [-3.118853485645103, 52.528111442169283],
            [-3.119808947077384, 52.528121283579445],
            [-3.119867424237635, 52.528449776194996],
            [-3.122553953400207, 52.527784208530363],
            [-3.12605223054616, 52.528256225917339],
            [-3.126408379596514, 52.528694261519206],
            [-3.126867320632481, 52.52836803784573],
            [-3.12886764099443, 52.528136799424736],
            [-3.129611313865076, 52.527418566618927],
            [-3.130011937957255, 52.527520827356838],
            [-3.130695777008796, 52.527056686520162],
            [-3.131491752203584, 52.526998731807616],
            [-3.131935032205704, 52.527441312026717],
            [-3.133064539864112, 52.5274556745185],
            [-3.134936055502016, 52.528405998377586],
            [-3.135134818942571, 52.530511427325806],
            [-3.136418470707005, 52.531582442104813],
            [-3.137160714964887, 52.533378773005147],
            [-3.137168848332789, 52.533921711068977],
            [-3.135481221582827, 52.534909771745617],
            [-3.132988687591715, 52.53527439599894],
            [-3.132566504565905, 52.535826847410085],
            [-3.130079981871745, 52.536715488462441],
            [-3.128034978812873, 52.536138941427545],
            [-3.126114064222583, 52.53660675291173],
            [-3.125815538543049, 52.536982691676293],
            [-3.12146430117188, 52.537433067005971],
            [-3.118777843191702, 52.538531039919121],
            [-3.116974939621076, 52.538370062635565],
            [-3.116385127395769, 52.539182059129814],
            [-3.116721147335826, 52.539872940658228],
            [-3.115388380971849, 52.539763241586883],
            [-3.113918813728889, 52.540597898467745],
            [-3.113299809600768, 52.540376268638077],
            [-3.113186688720177, 52.540742339175374],
            [-3.112552590825849, 52.540681773887172],
            [-3.111496001386349, 52.541348893548566],
            [-3.117208202193914, 52.551503591324057],
            [-3.126151902896796, 52.563829925179697],
            [-3.127360669307043, 52.566035422087381],
            [-3.128369322718231, 52.566188535428061],
            [-3.128988577260336, 52.567321699175643],
            [-3.130447645958399, 52.567546915536568],
            [-3.131537884392208, 52.568116371850273],
            [-3.134527014909427, 52.571941893388022],
            [-3.137708236870952, 52.573819098671571],
            [-3.138129341232994, 52.576290073459134],
            [-3.13777106828988, 52.577613289258935],
            [-3.138271807846911, 52.578257573184793],
            [-3.138585889339151, 52.582916905342096],
            [-3.136283761131167, 52.582758318942254],
            [-3.135107083412362, 52.583201139201222],
            [-3.134978724647293, 52.583659974051507],
            [-3.136313248475071, 52.584240529948829],
            [-3.137435163018065, 52.583905208099338],
            [-3.139506602622995, 52.58523922210027],
            [-3.139221566385559, 52.586029511979916],
            [-3.13835059664007, 52.58585448829362],
            [-3.136028100876639, 52.58605570251207],
            [-3.135931598595196, 52.585691624011758],
            [-3.134442633228435, 52.584904868829092],
            [-3.133814806844062, 52.585113163844611],
            [-3.133761826928859, 52.585576669401668],
            [-3.133120996990491, 52.585481664076667],
            [-3.133072566336152, 52.58580712531505],
            [-3.131617855626135, 52.58577518833436],
            [-3.13108051664075, 52.586116558560775],
            [-3.130441362835042, 52.585828681801409],
            [-3.129087982478763, 52.586523057492556],
            [-3.128485522092126, 52.586284266658296],
            [-3.127935535773738, 52.58647920194268],
            [-3.127152562417706, 52.585993094050458],
            [-3.12600633958774, 52.586997423469626],
            [-3.125504098566547, 52.586752270178124],
            [-3.125005823594375, 52.587237984149034],
            [-3.12521695438828, 52.587585698332504],
            [-3.124515494520796, 52.587227358917133],
            [-3.12393897366512, 52.587597837292584],
            [-3.123223952779799, 52.587112856549304],
            [-3.122143171513875, 52.587621164659971],
            [-3.121653187045631, 52.586988399444671],
            [-3.120985202707375, 52.586956966952854],
            [-3.120899657346102, 52.586612551531083],
            [-3.120389897765152, 52.586765715632446],
            [-3.119236660229844, 52.585821859576434],
            [-3.117414846735363, 52.58574826679191],
            [-3.116848683053065, 52.586121309894089],
            [-3.117338926333013, 52.586361218792462],
            [-3.117176088786311, 52.586806873366122],
            [-3.116906141767095, 52.586639504758473],
            [-3.11660715182821, 52.58683921126444],
            [-3.116886610295855, 52.587264510707506],
            [-3.115737154191931, 52.587396716872263],
            [-3.11540171394689, 52.588253950133634],
            [-3.114797803338024, 52.588188615153499],
            [-3.113809329545437, 52.588732837396023],
            [-3.113576423891926, 52.589851615301377],
            [-3.113217425065222, 52.589956580923598],
            [-3.113681007991948, 52.590599517992722],
            [-3.113113492322201, 52.590864672512311],
            [-3.112352058407024, 52.59070101423395],
            [-3.111574519821536, 52.591183899828572],
            [-3.110977044769383, 52.590499058003807],
            [-3.110103586981237, 52.590983732939165],
            [-3.109804094893251, 52.590231361342205],
            [-3.105534473297047, 52.594901270533391],
            [-3.102057694239922, 52.594900418515032],
            [-3.101703477556069, 52.595316365841519],
            [-3.102069110411988, 52.595766969089404],
            [-3.10130383596392, 52.595746219893201],
            [-3.100977448147299, 52.596389358502137],
            [-3.101627935594275, 52.59671864318917],
            [-3.100186486103816, 52.597111432455684],
            [-3.100422144740889, 52.597618987567323],
            [-3.09953413453817, 52.597532839855553],
            [-3.099201834276763, 52.597941383657876],
            [-3.098264847042064, 52.59790422820506],
            [-3.09688216317504, 52.598878997250665],
            [-3.095670432949096, 52.599015179910594],
            [-3.095358979130838, 52.598485841314449],
            [-3.094634211179053, 52.598966327585778],
            [-3.094764420913679, 52.598503926239623],
            [-3.094058887431633, 52.597979126185315],
            [-3.092580806939732, 52.598802791593954],
            [-3.089338381642296, 52.599630995799515],
            [-3.089727877912278, 52.601581881046286],
            [-3.089286636911615, 52.603317452617127],
            [-3.091235238455162, 52.605612688211345],
            [-3.090809417908059, 52.605769444785629],
            [-3.091611483567974, 52.607084510846008],
            [-3.093623974816242, 52.609324277707081],
            [-3.088142098598777, 52.612125772712751],
            [-3.08811783317838, 52.612755308274281],
            [-3.086277282013107, 52.613936433582474],
            [-3.082602720683927, 52.615617980873566],
            [-3.08204430075419, 52.616332408795039],
            [-3.082450634363417, 52.616877095856132],
            [-3.08309365693664, 52.616897288277023],
            [-3.08267859088006, 52.618213648126542],
            [-3.083342515055022, 52.61854111191137],
            [-3.081639504489452, 52.621481178290864],
            [-3.079619615198004, 52.623380349695267],
            [-3.0721854691447, 52.625960630434385],
            [-3.072052915551147, 52.626158714554961],
            [-3.074948998715118, 52.627019792807623],
            [-3.073336403948174, 52.627834540646603],
            [-3.073189313674826, 52.628947954443852],
            [-3.07228957515112, 52.628707975504319],
            [-3.06988897321722, 52.629143231110604],
            [-3.06782598491444, 52.630521157110927],
            [-3.064559113177534, 52.629970700733395],
            [-3.059801599437642, 52.630706504614089],
            [-3.063733979963829, 52.634145040389001],
            [-3.068023595291582, 52.636025832288396],
            [-3.069578199166051, 52.637481690953962],
            [-3.072424842314648, 52.637380435403365],
            [-3.07424428829239, 52.638720559533382],
            [-3.076323854395826, 52.639394820939309],
            [-3.079360683689894, 52.63969083281269],
            [-3.081279565325904, 52.63935777857612],
            [-3.083568885704318, 52.641277829102748],
            [-3.081715858630571, 52.641168004201752],
            [-3.077191511887905, 52.643190657400957],
            [-3.074578243814687, 52.643903934648826],
            [-3.073369163966416, 52.644141447497937],
            [-3.06988715778505, 52.645781279176674],
            [-3.06763299660831, 52.645471679076742],
            [-3.061717558534053, 52.645395386960821],
            [-3.061903699888109, 52.645696685237581],
            [-3.060468536729562, 52.645819220922832],
            [-3.05968904176726, 52.646834886554522],
            [-3.058176693475143, 52.647435459325266],
            [-3.056281352998116, 52.647540477415035],
            [-3.05506817708802, 52.647186290362356],
            [-3.051115293195271, 52.64735088757088],
            [-3.051528784433968, 52.648074517034495],
            [-3.051322092338688, 52.649440149416819],
            [-3.052223745138612, 52.651162737827704],
            [-3.050600864808027, 52.652835813228869],
            [-3.050652121928878, 52.653555464955872],
            [-3.049879174473846, 52.654674391353367],
            [-3.049225730356921, 52.654426659823727],
            [-3.047315532946166, 52.654418389654474],
            [-3.046953154465736, 52.654967290849868],
            [-3.04575608460499, 52.655231383898879],
            [-3.04493807466518, 52.655893079746058],
            [-3.045447819492425, 52.656748034544663],
            [-3.047615778164885, 52.657021061665695],
            [-3.048636602434689, 52.657545142488587],
            [-3.048648957786128, 52.660161146754348],
            [-3.049387910438058, 52.661197453119435],
            [-3.048889215482073, 52.661859043764913],
            [-3.049086262432438, 52.662307702004576],
            [-3.048713600290502, 52.66304638935673],
            [-3.046746134227039, 52.664245982286303],
            [-3.046161521614032, 52.66508991927941],
            [-3.04471278544875, 52.665601651028787],
            [-3.043035196550052, 52.667495354754656],
            [-3.043322568027126, 52.66795041892599],
            [-3.042827477586528, 52.668584081774092],
            [-3.042638296826836, 52.670700208919556],
            [-3.043079762096421, 52.672097873575837],
            [-3.038582796919787, 52.675358510597732],
            [-3.041911087510008, 52.675750925492771],
            [-3.045659179617807, 52.675449107196748],
            [-3.042579803736972, 52.679360841392267],
            [-3.042694388500806, 52.680880049857691],
            [-3.042084497171381, 52.681482353605027],
            [-3.04331695863641, 52.683913204547984],
            [-3.042675771014868, 52.686804649885353],
            [-3.04326098148574, 52.687088079695144],
            [-3.043493950149449, 52.68792389984133],
            [-3.046640265161701, 52.690068146758968],
            [-3.047050294488271, 52.691251209223978],
            [-3.041358046156859, 52.692728979118172],
            [-3.04042235732174, 52.692331746930989],
            [-3.038947560241502, 52.69234738181202],
            [-3.037778050037853, 52.692882645814208],
            [-3.036983797436071, 52.692521007115637],
            [-3.036026600791466, 52.692525782558768],
            [-3.034226479755349, 52.693426123079405],
            [-3.033525717903147, 52.694202680761563],
            [-3.032988188573495, 52.696002673245161],
            [-3.03212150183339, 52.696585584833151],
            [-3.031961288253593, 52.698387679257969],
            [-3.032470022907162, 52.69881297112353],
            [-3.032125126097398, 52.699874998148751],
            [-3.030656339053701, 52.700596192086131],
            [-3.030193048063598, 52.701974791561042],
            [-3.029379370551793, 52.702678586237568],
            [-3.027095411288343, 52.702914160008348],
            [-3.02591494734047, 52.703442207398943],
            [-3.025395415274554, 52.705144014444976],
            [-3.022488389022114, 52.706653375173104],
            [-3.022990789359968, 52.70756691827436],
            [-3.022861816801159, 52.708191037570927],
            [-3.022210223480899, 52.708620087063103],
            [-3.021752197474371, 52.710618914021005],
            [-3.023560251761455, 52.712039914734135],
            [-3.025375905539056, 52.714726611711242],
            [-3.025340451343558, 52.716497045417725],
            [-3.023682294223127, 52.718453210968057],
            [-3.023552405097287, 52.719734504753013],
            [-3.022152107224752, 52.71987334556502],
            [-3.020434551153662, 52.721135950272583],
            [-3.020307290230825, 52.721709706572646],
            [-3.021479612402119, 52.724230286425417],
            [-3.02035950435236, 52.725087685880254],
            [-3.019042627539763, 52.725319266762639],
            [-3.019514635381703, 52.724860316524932],
            [-3.018874117095412, 52.724315637422556],
            [-3.018122910477296, 52.72219686390099],
            [-3.018382201288365, 52.721251589374525],
            [-3.011561209070054, 52.720614165741338],
            [-3.008369461006226, 52.722104923264737],
            [-3.005436133383507, 52.720485574650752],
            [-3.003051430660731, 52.720893243262985],
            [-3.000556998156556, 52.720260753242556],
            [-2.997576585111344, 52.720841450138479],
            [-2.996866010384844, 52.721754513519137],
            [-2.99625606975241, 52.721887298194673],
            [-2.993014129206618, 52.722043054728765],
            [-2.990768952231876, 52.722540105119919],
            [-2.989208695107026, 52.722619660226172],
            [-2.987403248042189, 52.722267023040928],
            [-2.985744519962354, 52.722510070638428],
            [-2.984937561946192, 52.722837718672132],
            [-2.984686612548804, 52.723462806129689],
            [-2.982160098319226, 52.725745632303948],
            [-2.977594358201959, 52.726577180451521],
            [-2.977493803157623, 52.725656541818353],
            [-2.976122576638791, 52.724592644362083],
            [-2.975410687271721, 52.722550597301478],
            [-2.975797789507579, 52.721850691733145],
            [-2.975419852529102, 52.721634447620964],
            [-2.977136559502265, 52.720407567531794],
            [-2.976825503196888, 52.720003785866574],
            [-2.977412830595709, 52.719652832157806],
            [-2.976823200308374, 52.718907032302653],
            [-2.977230128285937, 52.718765233388318],
            [-2.977254370628714, 52.717002106016672],
            [-2.978105502171049, 52.715342734478064],
            [-2.967412618356016, 52.714267183556792],
            [-2.965601989418831, 52.714393421726129],
            [-2.962925429013679, 52.715464319834396],
            [-2.961551493650987, 52.715659766232605],
            [-2.961102326807207, 52.716372714533478],
            [-2.96143521146209, 52.718719084058378],
            [-2.962074818419866, 52.718922461260902],
            [-2.962345915083308, 52.719871395264811],
            [-2.961102398190113, 52.720685182967948],
            [-2.961817541410144, 52.723243309354174],
            [-2.961557426914837, 52.723668844766799],
            [-2.962039765544433, 52.723787194484309],
            [-2.961795872019386, 52.724142477357944],
            [-2.962222573945926, 52.724625369514236],
            [-2.961660040720927, 52.724833106191561],
            [-2.961724680179517, 52.725549977426375],
            [-2.966700236330837, 52.725631773021405],
            [-2.966744063668502, 52.727010469601545],
            [-2.965502170113031, 52.728305249456611],
            [-2.965591950380628, 52.728883468940452],
            [-2.96663499905833, 52.729108706992577],
            [-2.965068129624905, 52.732253557920664],
            [-2.969051245581341, 52.731957664290888],
            [-2.969711604035998, 52.732885418126571],
            [-2.969430097498124, 52.733347104814662],
            [-2.970760261755895, 52.734824051692222],
            [-2.970509802989161, 52.735016688235596],
            [-2.971216310242839, 52.735085519220988],
            [-2.971124009656295, 52.735463851546257],
            [-2.971830605054809, 52.735536274137438],
            [-2.971681114139237, 52.735074518920179],
            [-2.973654944805642, 52.73572088262344],
            [-2.977097596176814, 52.734359356856963],
            [-2.981485434352314, 52.733549082436809],
            [-2.987070293336974, 52.733353485156179],
            [-2.991032473941994, 52.733703403195534],
            [-2.994013526450549, 52.735516888193821],
            [-2.99656380049503, 52.736096007099171],
            [-2.997385204904904, 52.738340177961497],
            [-2.996803410404483, 52.73893750661783],
            [-2.995983188448302, 52.73908284422582],
            [-2.995029412576282, 52.739022528778001],
            [-2.993542084275123, 52.738361656000244],
            [-2.992539519182917, 52.738565134123526],
            [-2.99232109858766, 52.739382348179518],
            [-2.993049621747842, 52.739958795650864],
            [-2.993107906206182, 52.740567824044177],
            [-2.99058490135125, 52.742180144351686],
            [-2.990817327315284, 52.742957627964451],
            [-2.991995005165115, 52.74374159063283],
            [-2.994895938366031, 52.744412206183519],
            [-2.997672655523617, 52.743921399976998],
            [-2.999797474510432, 52.74472248442342],
            [-3.002410021372967, 52.746309622641895],
            [-3.003779651026718, 52.74634388488915],
            [-3.004677883481669, 52.746686884571339],
            [-3.005622179911276, 52.747678558938347],
            [-3.008116719798598, 52.747731094680589],
            [-3.008077457835293, 52.748918097736393],
            [-3.009770945449899, 52.750332186828373],
            [-3.013617187646527, 52.749634117934789],
            [-3.014538997912326, 52.748498898522236],
            [-3.015385003659133, 52.748119474397008],
            [-3.016644636136887, 52.748516824125112],
            [-3.017971831443018, 52.75060368623646],
            [-3.021131740294001, 52.751541136579284],
            [-3.02189329588292, 52.752364346226628],
            [-3.02037744065579, 52.752992310013944],
            [-3.017206519846144, 52.753366563114838],
            [-3.015375724257423, 52.752880615952421],
            [-3.014361599314006, 52.752979194205103],
            [-3.014487754824017, 52.754199844551209],
            [-3.013304047045964, 52.755611492531592],
            [-3.012962739362844, 52.7567390462684],
            [-3.010838600055131, 52.758434687888688],
            [-3.011256783525872, 52.759982780252848],
            [-3.010615530225945, 52.761536307740379],
            [-3.010838811897881, 52.762090879668655],
            [-3.012276393881217, 52.762860739170414],
            [-3.013122627968476, 52.764393482949345],
            [-3.016454677791106, 52.766192618687214],
            [-3.017201540341747, 52.767649770769971],
            [-3.018733200763906, 52.767484695110944],
            [-3.019790547609772, 52.76617746668795],
            [-3.021500172348818, 52.765576612352717],
            [-3.022042527377748, 52.764433817882981],
            [-3.022680094856891, 52.764129856913179],
            [-3.02373591476343, 52.764155808386654],
            [-3.026236974219589, 52.765011607852671],
            [-3.026439878967749, 52.765446761434951],
            [-3.02572763469015, 52.766734884096614],
            [-3.026352828572556, 52.767038728126437],
            [-3.027162249916328, 52.766983175271712],
            [-3.028845051931969, 52.765813386029997],
            [-3.030586204015552, 52.766176744010764],
            [-3.032991771126352, 52.765868139021862],
            [-3.033548216394146, 52.764206450157012],
            [-3.035576582742218, 52.764058402324082],
            [-3.036788272469602, 52.764531473333577],
            [-3.036945027470739, 52.765265477982403],
            [-3.03486692929386, 52.766193408402927],
            [-3.033119943310235, 52.766524185075617],
            [-3.032647833597778, 52.767242095477862],
            [-3.035367468881517, 52.767675072173965],
            [-3.036174000150494, 52.768433968959485],
            [-3.036105644616502, 52.768988344556107],
            [-3.037579120358734, 52.769182231193156],
            [-3.038036560319082, 52.769968441548109],
            [-3.038978839374714, 52.770386312597516],
            [-3.039579402989698, 52.770210241848581],
            [-3.040337932227073, 52.769075356972593],
            [-3.043426429383284, 52.769491428533357],
            [-3.044371474299043, 52.769341968995064],
            [-3.04514120307611, 52.768927945416678],
            [-3.045806250258701, 52.767486393247374],
            [-3.046536068742991, 52.767016974732279],
            [-3.04759433614533, 52.767264743572113],
            [-3.048934136132132, 52.76892682480787],
            [-3.049850978097127, 52.769454511841538],
            [-3.051544140900642, 52.76855130811267],
            [-3.052581698550145, 52.768675155326584],
            [-3.053129812038804, 52.769338240850267],
            [-3.05274423683624, 52.770435737227388],
            [-3.050778403439333, 52.770974585022287],
            [-3.049644038454582, 52.771634600363633],
            [-3.047671341990141, 52.771953204074492],
            [-3.047791315032796, 52.772628185741688],
            [-3.050102619333658, 52.772273320072266],
            [-3.053908911738298, 52.773549390393107],
            [-3.057927578396809, 52.773670937592499],
            [-3.058501759639063, 52.773508491213875],
            [-3.058254579841573, 52.772912867200915],
            [-3.059004785995922, 52.772063815616605],
            [-3.058935156081776, 52.771271526122327],
            [-3.06131854616044, 52.770718019237073],
            [-3.06117131168856, 52.769296231229035],
            [-3.062612212678935, 52.769060380186446],
            [-3.063550460074732, 52.769182324344641],
            [-3.0641173050128, 52.769633928377417],
            [-3.062851822464988, 52.771169068281431],
            [-3.063851319810856, 52.771187077140553],
            [-3.064873357522629, 52.771642684685631],
            [-3.066797530289965, 52.773770390079257],
            [-3.069558048776357, 52.774207611428146],
            [-3.070662279445702, 52.772752068296043],
            [-3.070807651060354, 52.772031561480212],
            [-3.070358398832548, 52.771604099871062],
            [-3.071576667948436, 52.770148417041774],
            [-3.072701079514346, 52.769641109341443],
            [-3.074247587007922, 52.769602839206939],
            [-3.079517897648344, 52.771499510727885],
            [-3.079961058499708, 52.772934765506655],
            [-3.079101716262537, 52.773490973075226],
            [-3.077859754171795, 52.773741398220196],
            [-3.080513944756735, 52.776045641630077],
            [-3.080650435623244, 52.77742615074218],
            [-3.083934751523785, 52.777867255304919],
            [-3.085232656423242, 52.777299809511945],
            [-3.088821463132541, 52.777524931137648],
            [-3.087781957254827, 52.779832296215282],
            [-3.09169535941274, 52.786737469333971],
            [-3.091179213286647, 52.787443432477538],
            [-3.089023628395984, 52.788115010594673],
            [-3.085739850557079, 52.790289206771028],
            [-3.085589108042883, 52.793347157080674],
            [-3.086633924403988, 52.795578776231729],
            [-3.089038757618128, 52.795903735281684],
            [-3.090013312121862, 52.796324502846772],
            [-3.090959261262531, 52.796016443681609],
            [-3.093463280500041, 52.793671295171549],
            [-3.096464422127032, 52.7924110767309],
            [-3.097732601817738, 52.79135921441469],
            [-3.102392286252844, 52.792597968281825],
            [-3.104549265077967, 52.792683086816872],
            [-3.105374874559536, 52.793075448662378],
            [-3.106102598316426, 52.792941008708894],
            [-3.10846746201229, 52.791402333932282],
            [-3.108443360535606, 52.791096002411628],
            [-3.107528759808645, 52.790702694709793],
            [-3.107272956284162, 52.789681129185531],
            [-3.109709180007429, 52.788216378360126],
            [-3.10931511597421, 52.787357029921168],
            [-3.10974995710933, 52.786672424226865],
            [-3.112190905150739, 52.785751468182767],
            [-3.112978877119729, 52.78653069778138],
            [-3.113763394520287, 52.786535021432343],
            [-3.115927077079685, 52.785386448424759],
            [-3.11617231284636, 52.784250508582581],
            [-3.117205638729538, 52.783666323062164],
            [-3.118288464458859, 52.783568018174179],
            [-3.119067913113851, 52.784763522997281],
            [-3.119534725186931, 52.785504385162696],
            [-3.118911226905272, 52.78720936831639],
            [-3.122530978714942, 52.787560839075802],
            [-3.12579061022869, 52.786942005892719],
            [-3.127129170636202, 52.787384186563401],
            [-3.131291280036677, 52.787546840702625],
            [-3.134627525119107, 52.788509266479302],
            [-3.141623253488659, 52.789905713854623],
            [-3.142455446760579, 52.790312139537996],
            [-3.14363809297684, 52.789888104740285],
            [-3.14455355631013, 52.788494820839162],
            [-3.146275865228558, 52.789254024994328],
            [-3.146771269705942, 52.791231524511986],
            [-3.147999176832045, 52.792076391841597],
            [-3.151075261450178, 52.792538335462424],
            [-3.151831278101054, 52.79297510177463],
            [-3.153383147607981, 52.793222531032399],
            [-3.156459233828435, 52.79356746591597],
            [-3.158426239660015, 52.793473657861675],
            [-3.161083850363134, 52.796034091702872],
            [-3.16188395393497, 52.799342657644296],
            [-3.158442081423466, 52.799929198316484],
            [-3.156280835903098, 52.800931086499197],
            [-3.154121330358179, 52.802506476946348],
            [-3.154564359937728, 52.803495556388079],
            [-3.153567324163409, 52.803656285939077],
            [-3.153190737899441, 52.806354234942617],
            [-3.154517379995827, 52.807545087284389],
            [-3.155315194896519, 52.807532826410657],
            [-3.156266692870366, 52.808000923828843],
            [-3.157632993384199, 52.807552487377833],
            [-3.158880692803445, 52.806773464295638],
            [-3.161418304354465, 52.803576095354053],
            [-3.162492678517854, 52.803646482922488],
            [-3.163185664518366, 52.804099976854701],
            [-3.164403139511352, 52.804140176263125],
            [-3.164189797412424, 52.804594464876416],
            [-3.164780334433606, 52.804987823055264],
            [-3.164871950872354, 52.805806807900652],
            [-3.166221990305836, 52.805809727401979],
            [-3.16616630169418, 52.806444965923198],
            [-3.166918299193987, 52.807104627218472],
            [-3.168205673127135, 52.807314010704268],
            [-3.168357299621667, 52.808210614606352],
            [-3.167317940695019, 52.810282241729055],
            [-3.165464223886628, 52.812031929212495],
            [-3.166039798489771, 52.815298615517264],
            [-3.166655772278301, 52.816469344664398],
            [-3.167693064461187, 52.817247559624434],
            [-3.167887786044256, 52.819252198622223],
            [-3.165368562501573, 52.821377915012192],
            [-3.164899881105619, 52.822885634023997],
            [-3.163805912478538, 52.824043483407515],
            [-3.161409363877591, 52.825555694961565],
            [-3.159542084719217, 52.827609274024439],
            [-3.159164827661788, 52.828937175003041],
            [-3.158197171461247, 52.829944503811014],
            [-3.158819712853659, 52.830525468162882],
            [-3.157769994583859, 52.830011601477821],
            [-3.15667658954689, 52.830031253449583],
            [-3.1551123124354, 52.830709046283552],
            [-3.155586724218424, 52.831470368379364],
            [-3.154065945072461, 52.832111763776723],
            [-3.154168913581592, 52.833140107184008],
            [-3.156478760384949, 52.836999463682488],
            [-3.155479821332941, 52.837396658338015],
            [-3.155329897488597, 52.838739411496462],
            [-3.152254608147012, 52.840283206848248],
            [-3.151738008504141, 52.841856058961291],
            [-3.151260483650419, 52.842550216721015],
            [-3.154609259855109, 52.844088221766107],
            [-3.155099271731689, 52.844928504025333],
            [-3.15850120683552, 52.84661061994175],
            [-3.163014575664505, 52.84747625940016],
            [-3.162640281655302, 52.847972572559485],
            [-3.16143406046394, 52.848010452234703],
            [-3.160876910174926, 52.848611033572034],
            [-3.1612782757605, 52.849294834245313],
            [-3.160136821841041, 52.84975818773303],
            [-3.159737118551897, 52.850305982049598],
            [-3.158191595832651, 52.850430749086655],
            [-3.157793587273948, 52.85093177272406],
            [-3.158357717790331, 52.85114831905544],
            [-3.158268202635123, 52.851523171865701],
            [-3.157391358898361, 52.851330351394665],
            [-3.155788203115644, 52.853088211677218],
            [-3.154985050059711, 52.853378311464191],
            [-3.154121413708946, 52.853292317869467],
            [-3.154001807657541, 52.854269781634891],
            [-3.153060315672704, 52.854440748656984],
            [-3.152390744904641, 52.855065754366379],
            [-3.151056204623061, 52.854920480918729],
            [-3.150726032617002, 52.855576346989473],
            [-3.147643581310656, 52.855717662749406],
            [-3.146276109274293, 52.85680246807906],
            [-3.146324514593339, 52.857117545024558],
            [-3.14456253906262, 52.857549878818823],
            [-3.142341933696679, 52.860087531372258],
            [-3.138878449358331, 52.861346127280349],
            [-3.134418890629197, 52.861782582540997],
            [-3.127713362990418, 52.867088448241084],
            [-3.12905590865633, 52.869246733430828],
            [-3.129719191719042, 52.869216151078952],
            [-3.130636680142691, 52.869648817913735],
            [-3.134424887814044, 52.869812255076596],
            [-3.136179934925981, 52.870683668234271],
            [-3.137212427478826, 52.872158005230389],
            [-3.139106489151886, 52.873339092184274],
            [-3.139757209655088, 52.873563885949139],
            [-3.141826164866962, 52.873422643296365],
            [-3.143136987228853, 52.87434047795233],
            [-3.1449618867832, 52.874860485755647],
            [-3.145385039699658, 52.875356239431731],
            [-3.149823874650573, 52.876952182806583],
            [-3.152416445110364, 52.8787331065286],
            [-3.152486190855466, 52.879794129766815],
            [-3.151091998161405, 52.880735405651514],
            [-3.148305102390788, 52.881219074636952],
            [-3.147041256960014, 52.884682486847318],
            [-3.144169371501043, 52.885166878212459],
            [-3.142754360382765, 52.885047455370632],
            [-3.140127231133959, 52.885573442991223],
            [-3.136606344524647, 52.884888012403096],
            [-3.135524343690536, 52.8849990506598],
            [-3.136187421435271, 52.886201838182572],
            [-3.137048970119595, 52.886581951323315],
            [-3.138312822319319, 52.887057090258665],
            [-3.140272778827776, 52.887268424416952],
            [-3.141404615696946, 52.888034270442908],
            [-3.142891464766165, 52.888451480290229],
            [-3.14378496781093, 52.888404220842382],
            [-3.144071554450679, 52.888845557087734],
            [-3.14575565999502, 52.889244648881586],
            [-3.147503827584524, 52.890154618661732],
            [-3.144694818670766, 52.892982058223716],
            [-3.13751800172715, 52.894902024383008],
            [-3.13322633105541, 52.895125549743099],
            [-3.130281509632237, 52.894324755693724],
            [-3.12665615416579, 52.894332237536972],
            [-3.124624178614448, 52.894736261932401],
            [-3.123330766447494, 52.89536250694718],
            [-3.121869845686902, 52.895432953213593],
            [-3.115335773751184, 52.894591927431428],
            [-3.114163164334423, 52.893963761234602],
            [-3.113814295668277, 52.895314602855699],
            [-3.116895121490092, 52.899678974284946],
            [-3.114697144028237, 52.904517246448421],
            [-3.112756275491243, 52.906352279785231],
            [-3.113304733631073, 52.90918520897462],
            [-3.112913087065948, 52.911483066820573],
            [-3.109578189557439, 52.918429186487181],
            [-3.108279342336656, 52.92027611956086],
            [-3.105859537097715, 52.921507792837396],
            [-3.103141631240849, 52.924007939518326],
            [-3.10179423485644, 52.926127637916679],
            [-3.099735097740951, 52.92799231002197],
            [-3.095954470762301, 52.930267488382192],
            [-3.094232902423403, 52.929666663985103],
            [-3.090845040920999, 52.929237548808999],
            [-3.088289569461204, 52.929370662253632],
            [-3.085743274439064, 52.928503980772163],
            [-3.084357694252985, 52.926576656641465],
            [-3.082554213316343, 52.926674912323271],
            [-3.080776133230611, 52.926121155359617],
            [-3.078375145739929, 52.925939808626246],
            [-3.076514317525554, 52.925464949787937],
            [-3.073018044056163, 52.925984724473359],
            [-3.07019480436324, 52.926775217152901],
            [-3.066015436520253, 52.926261727778297],
            [-3.063875120990958, 52.926334854023537],
            [-3.063049635699866, 52.926886120950783],
            [-3.061514893155969, 52.929093330972691],
            [-3.060873612236622, 52.929332791678362],
            [-3.05992281244263, 52.929264866621537],
            [-3.057586961846351, 52.928219508037373],
            [-3.056418559251286, 52.928011455035346],
            [-3.054846735547916, 52.929015187756804],
            [-3.052182346731986, 52.92932289117892],
            [-3.047965004375803, 52.927854398882907],
            [-3.046711696078702, 52.928009283852745],
            [-3.045640855536972, 52.92913073820224],
            [-3.043386951955669, 52.928261477075552],
            [-3.040839391942482, 52.927921541971202],
            [-3.034875819348445, 52.929556726342831],
            [-3.033848436307856, 52.93039362069549],
            [-3.031406737362145, 52.931379436752316],
            [-3.031077072077872, 52.933783437787604],
            [-3.028997028638436, 52.934345347333654],
            [-3.02923647946798, 52.935532604927623],
            [-3.028450267461912, 52.936699079512501],
            [-3.026503034580106, 52.936027313056776],
            [-3.023874788983128, 52.936442841444602],
            [-3.025630011175002, 52.937301495511456],
            [-3.025232107448892, 52.937652829547375],
            [-3.025595311564254, 52.938225032345713],
            [-3.0230240489777, 52.940683396151385],
            [-3.024029068070222, 52.942345912843841],
            [-3.021983227291596, 52.943243614747793],
            [-3.020908166893002, 52.943280725368325],
            [-3.021156237538302, 52.944588383960522],
            [-3.019227226307917, 52.945018471173583],
            [-3.020070506203118, 52.945944358302434],
            [-3.018373119280612, 52.947051159763582],
            [-3.016931705811835, 52.947082383921703],
            [-3.016684509348691, 52.947837830519823],
            [-3.017049613806279, 52.948682427900636],
            [-3.014520047679324, 52.948199735788734],
            [-3.013250560527136, 52.948711290990808],
            [-3.011395124006796, 52.951986736806383],
            [-3.009856292043744, 52.956020880803287],
            [-3.008614336064398, 52.956576199896901],
            [-3.005066133160565, 52.957053048587376],
            [-3.003544389711698, 52.9579370031704],
            [-2.997540494205955, 52.958318374676615],
            [-2.993799051305669, 52.958105207900914],
            [-2.990395378405231, 52.959772428722189],
            [-2.988383122577617, 52.959010684536288],
            [-2.985925472082408, 52.958691294876822],
            [-2.982022880298782, 52.959182979202396],
            [-2.979863749461547, 52.96192464725808],
            [-2.979494037131736, 52.965195407416324],
            [-2.977403813458238, 52.967249655176715],
            [-2.975323315106317, 52.968884871089323],
            [-2.974770550955246, 52.968936152152807],
            [-2.968487272789745, 52.964160126888714],
            [-2.969466931849805, 52.962828873621099],
            [-2.971186937091484, 52.961984192065565],
            [-2.971712966852389, 52.961142962135753],
            [-2.972828799221396, 52.96044702851524],
            [-2.971549240429142, 52.959020063409461],
            [-2.970829857850869, 52.95920393670032],
            [-2.970519733427919, 52.95871743690725],
            [-2.968935541504267, 52.959039606782355],
            [-2.967270223179032, 52.95832951339375],
            [-2.966224623178077, 52.95736984262836],
            [-2.965661839665477, 52.956096998325854],
            [-2.964789232765301, 52.956016887753485],
            [-2.96405880661248, 52.956372510849029],
            [-2.962709180015685, 52.955726315001343],
            [-2.96182461949368, 52.955643582676004],
            [-2.962358075100108, 52.954930884522106],
            [-2.962016927371639, 52.95465047528036],
            [-2.9624526643862, 52.954431196691189],
            [-2.962401539643645, 52.953603670368786],
            [-2.961412094806258, 52.953353679405218],
            [-2.960920691605313, 52.952404758826951],
            [-2.960123034908837, 52.952344684104666],
            [-2.960022748677412, 52.951513059091269],
            [-2.959562937688357, 52.951383727547068],
            [-2.959813865174651, 52.951154264353015],
            [-2.958256888388156, 52.950746119022696],
            [-2.958303230096718, 52.950485946313762],
            [-2.956818851878923, 52.949993593547255],
            [-2.955731977850716, 52.949099786981527],
            [-2.953295790891432, 52.948366031365197],
            [-2.953074941601067, 52.947933606262673],
            [-2.951465738091045, 52.947578829826803],
            [-2.950863080308797, 52.946904940925798],
            [-2.949523923184144, 52.94685002679352],
            [-2.949434689094125, 52.94658105242155],
            [-2.948834398128468, 52.946761144729194],
            [-2.948606875107445, 52.946021320951516],
            [-2.946641874625333, 52.945929118583436],
            [-2.946166332185562, 52.945551748306563],
            [-2.943286629279497, 52.944799744509488],
            [-2.94070611449363, 52.943360296564329],
            [-2.937236542208276, 52.942570564747378],
            [-2.93679451577776, 52.94208591674689],
            [-2.936333451377218, 52.942235182567785],
            [-2.936246857522837, 52.941946400513402],
            [-2.934965232378532, 52.941791085123434],
            [-2.934326138648345, 52.941067956900007],
            [-2.932465514284738, 52.940558482464958],
            [-2.931439663526807, 52.939561494642234],
            [-2.930470524422601, 52.939194223423527],
            [-2.930010046910581, 52.939370428953204],
            [-2.928889315244577, 52.938663626402047],
            [-2.927286239767956, 52.938778630896273],
            [-2.92744613878805, 52.9418715950315],
            [-2.926502637330645, 52.942287083261029],
            [-2.924637434774613, 52.942328582965132],
            [-2.920278759905382, 52.943380044442875],
            [-2.918850809098637, 52.94355200993396],
            [-2.914158109667042, 52.942396194096567],
            [-2.910912025927483, 52.942371684207252],
            [-2.909089811320173, 52.941909194762914],
            [-2.908188415040111, 52.942069810230564],
            [-2.904937737232583, 52.943962644921882],
            [-2.903661995004475, 52.944015505007791],
            [-2.902937496690175, 52.944384191363561],
            [-2.901388799887099, 52.946555241469042],
            [-2.900864508962175, 52.948181831394784],
            [-2.89829261832599, 52.950259909620748],
            [-2.896476737179865, 52.950904690256245],
            [-2.893169165455843, 52.951176812344585],
            [-2.890308182441293, 52.950439567685244],
            [-2.887431941664876, 52.952514294423096],
            [-2.886047678618026, 52.948364269035174],
            [-2.883662754853741, 52.946653342807316],
            [-2.871564657051433, 52.945242354438271],
            [-2.869801784183386, 52.944755455593523],
            [-2.867694494232499, 52.944977623652584],
            [-2.86685703379706, 52.945369387707196],
            [-2.86428236438935, 52.944992605959946],
            [-2.863785064762749, 52.944606976025661],
            [-2.861283002105536, 52.944660199876736],
            [-2.860473269053206, 52.944577070859843],
            [-2.860392441504563, 52.944328646366515],
            [-2.854839779273672, 52.943709796358711],
            [-2.853078055398846, 52.943873483230369],
            [-2.849074260991443, 52.944903596852591],
            [-2.848252096835312, 52.9447926059579],
            [-2.846879244458764, 52.943764110504411],
            [-2.84319795544177, 52.942177575117114],
            [-2.84232476307478, 52.942040835918952],
            [-2.841015835623483, 52.94260835798584],
            [-2.84021800032002, 52.941906528214467],
            [-2.840613672897855, 52.939058546454902],
            [-2.841852600962148, 52.938033058548484],
            [-2.839794664530097, 52.933725460079671],
            [-2.839048987258052, 52.933479921313122],
            [-2.838496955562197, 52.932168650933697],
            [-2.835873539935804, 52.932142191669151],
            [-2.835534633640339, 52.931076619807136],
            [-2.834446484532134, 52.930889195674887],
            [-2.834769426246539, 52.929812677837205],
            [-2.824038477609847, 52.922822731058602],
            [-2.822248855287193, 52.920298285954964],
            [-2.822511542148205, 52.91987575680875],
            [-2.821230095391707, 52.919123209757593],
            [-2.819896940455405, 52.919173771668852],
            [-2.819096901965501, 52.918865556642238],
            [-2.817958144244681, 52.917630151062461],
            [-2.813027806593592, 52.914925788807473],
            [-2.812387037308933, 52.914141790649893],
            [-2.812313233892496, 52.912661717866953],
            [-2.812977626084865, 52.909547688017959],
            [-2.810244745958715, 52.907645297439132],
            [-2.807255340902447, 52.906567131856271],
            [-2.804286992861003, 52.90324675473493],
            [-2.801993304951272, 52.899886689294313],
            [-2.801441820529994, 52.896748561896921],
            [-2.800470649655019, 52.896642742863563],
            [-2.800830721975676, 52.895854627519476],
            [-2.800468914483298, 52.895500181381287],
            [-2.79885014911991, 52.895743911896176],
            [-2.794263557739708, 52.897328972786099],
            [-2.794029451482676, 52.898102741116134],
            [-2.792734267331972, 52.899385213860803],
            [-2.793189731431362, 52.901206152262624],
            [-2.781662823192589, 52.908685467020113],
            [-2.781388494530074, 52.909223048257473],
            [-2.778804668631379, 52.910245948186002],
            [-2.778520003761084, 52.909961048633448],
            [-2.772111584695031, 52.914251331174242],
            [-2.755328253833203, 52.92461357497416],
            [-2.744574930198325, 52.924422571348941],
            [-2.72854346226614, 52.925163831423895],
            [-2.72668963028033, 52.927714713692914],
            [-2.725920956558882, 52.929961387427646],
            [-2.727055802045773, 52.934295459498323],
            [-2.726517503554956, 52.937953866845035],
            [-2.72701544331258, 52.939786467268796],
            [-2.72560680653374, 52.940527719783958],
            [-2.726059723842528, 52.945714560942889],
            [-2.726356786224386, 52.94594647066986],
            [-2.727045659020389, 52.945758872354446],
            [-2.727195850004917, 52.946372831133758],
            [-2.728164437760794, 52.946978180777698],
            [-2.727178029786877, 52.94903651181636],
            [-2.727875504540285, 52.953612358875468],
            [-2.725124283683355, 52.955538536398798],
            [-2.72417633140422, 52.956444159565351],
            [-2.724178487156625, 52.957107566108718],
            [-2.724697757624274, 52.957804676391206],
            [-2.726679542874841, 52.958540492912228],
            [-2.727396517021898, 52.95930560069116],
            [-2.728088325436916, 52.961937961349079],
            [-2.726823287277223, 52.963382215218068],
            [-2.72669158132991, 52.964761098378219],
            [-2.72748190063073, 52.966341096469392],
            [-2.728065181991442, 52.966761824509952],
            [-2.7328845398154, 52.967535852749833],
            [-2.735294143877032, 52.969931928325622],
            [-2.733287100187575, 52.971525567382628],
            [-2.732540261175151, 52.9727985789333],
            [-2.731201599799692, 52.973949376611735],
            [-2.731383727135547, 52.974240411516419],
            [-2.730728125705462, 52.974836846220683],
            [-2.731750415898634, 52.975582968981904],
            [-2.731629778082831, 52.976463774171812],
            [-2.732080158058587, 52.976941034656733],
            [-2.731654937649599, 52.976895110520118],
            [-2.731894442482263, 52.977231637224314],
            [-2.731162527405846, 52.977544478864409],
            [-2.731534826013295, 52.977807375063826],
            [-2.73098199492922, 52.978054389391716],
            [-2.730395741436353, 52.977909668780505],
            [-2.729305750336071, 52.978716419034924],
            [-2.729577152807509, 52.979708980420583],
            [-2.727991962791005, 52.980122324869583],
            [-2.7270484579374, 52.980788820906582],
            [-2.727315208746175, 52.981152158076],
            [-2.726612885192131, 52.981370401538356],
            [-2.726588202780592, 52.981763388815367],
            [-2.727262411461844, 52.982176372801881],
            [-2.726968726445519, 52.982338180701944],
            [-2.727336477679002, 52.983215991914193],
            [-2.726841191168296, 52.983258575233215],
            [-2.72510488355508, 52.982929382272438],
            [-2.720301067115422, 52.983989675793673],
            [-2.719535506616102, 52.983813626437005],
            [-2.717993498640324, 52.984151064394347],
            [-2.716573618425211, 52.984039175878067],
            [-2.715143697479718, 52.985032125664489],
            [-2.711879279692309, 52.986070219570585],
            [-2.704565902485331, 52.990402626565867],
            [-2.700767159677482, 52.993260328695861],
            [-2.699292930578085, 52.995424658944046],
            [-2.69669718028555, 52.994849301218778],
            [-2.694469910630078, 52.993928341255213],
            [-2.694415612381102, 52.993329967413814],
            [-2.691838985995208, 52.990490872043516],
            [-2.688775971965934, 52.98992255550619],
            [-2.686443035888069, 52.988229871847778],
            [-2.687079032648221, 52.986816665167893],
            [-2.685556508296846, 52.986431722994695],
            [-2.684668239483312, 52.986590561414921],
            [-2.684569875621593, 52.986116489738393],
            [-2.683735771106336, 52.986403557077303],
            [-2.680903689912464, 52.986097097822373],
            [-2.679675946500103, 52.986550896393346],
            [-2.676186213659626, 52.986481807189016],
            [-2.674662777412872, 52.98564906096783],
            [-2.671575205915762, 52.987109339073442],
            [-2.668118381802864, 52.987726613480618],
            [-2.668101806200486, 52.988196849689743],
            [-2.665891079319414, 52.988869973251425],
            [-2.664379595443546, 52.989002484865445],
            [-2.660807253665414, 52.991436937789601],
            [-2.658298750275899, 52.992042356394307],
            [-2.658185697703259, 52.991661834483949],
            [-2.657685680285571, 52.991599879095936],
            [-2.655427922125928, 52.993124357378036],
            [-2.654419117867764, 52.993308803781382],
            [-2.652357060870225, 52.994980469168034],
            [-2.651680597957204, 52.994878105839376],
            [-2.648713409065242, 52.995717759744352],
            [-2.648611001651196, 52.99524727774299],
            [-2.64452944263482, 52.994998901561239],
            [-2.642805221705943, 52.993466568932526],
            [-2.637371106731753, 52.994837040800398],
            [-2.636371960114845, 52.996496429914863],
            [-2.635074521453344, 52.996720011829673],
            [-2.634068352476277, 52.99597747197533],
            [-2.632650933235927, 52.996656527068097],
            [-2.63092303257231, 52.995869257679047],
            [-2.628028172914544, 52.995154655889635],
            [-2.628236898827466, 52.99249630917619],
            [-2.627633786919979, 52.992000588006583],
            [-2.627591772853523, 52.990952654506387],
            [-2.625755919936811, 52.989168064648631],
            [-2.621940017402051, 52.986727715023839],
            [-2.618967869810947, 52.986596705162725],
            [-2.614984691325159, 52.987750937012699],
            [-2.607734049031608, 52.988347369994798],
            [-2.607565922128573, 52.987725269614593],
            [-2.606699846730276, 52.987257754511802],
            [-2.607142241193079, 52.986725125301781],
            [-2.605466008351625, 52.985416740759185],
            [-2.604786316457374, 52.98410506327717],
            [-2.603774568603467, 52.983544779037175],
            [-2.604141982157163, 52.983082658320619],
            [-2.603699346236513, 52.98178235400939],
            [-2.605679452949262, 52.97938021766867],
            [-2.604205197875011, 52.978967920418135],
            [-2.600625142571725, 52.978775718082126],
            [-2.598109009028143, 52.979051795783136],
            [-2.59592540170552, 52.97968392842246],
            [-2.594889032639454, 52.97959203611363],
            [-2.596702937592435, 52.977853394922548],
            [-2.599156051324151, 52.976686821549357],
            [-2.599629932503468, 52.975417833922137],
            [-2.598894303022069, 52.975097927480093],
            [-2.595399946965761, 52.975914638783451],
            [-2.592756288505898, 52.974154259387596],
            [-2.596623045852303, 52.973460707999806],
            [-2.598446788249333, 52.971703110700084],
            [-2.596890721099246, 52.969544505563761],
            [-2.597033475647611, 52.968558556157319],
            [-2.596027876495057, 52.967653882667037],
            [-2.596332297813589, 52.966513404911787],
            [-2.595802043973924, 52.965524539678405],
            [-2.597693686604414, 52.96299082919829],
            [-2.595882132239167, 52.962146838317658],
            [-2.59491773652961, 52.960977661114917],
            [-2.592649109760526, 52.960882025254655],
            [-2.591327940076583, 52.96039599118825],
            [-2.590653074607036, 52.959268486977066],
            [-2.589074913302929, 52.958926635964652],
            [-2.58911116846037, 52.958636998906087],
            [-2.58828811202063, 52.957552465794727],
            [-2.588478696323291, 52.957007665747355],
            [-2.586203358367116, 52.955299249345593],
            [-2.584429768669126, 52.955574072086129],
            [-2.583624845352001, 52.956264815871492],
            [-2.578144070640379, 52.955713596920958],
            [-2.576658515200311, 52.956161295066899],
            [-2.574891206902134, 52.957365443926946],
            [-2.574933620380199, 52.958318110044736],
            [-2.573564920855274, 52.958493725161276],
            [-2.573089288820219, 52.959224157688311],
            [-2.57180130289253, 52.959201597373273],
            [-2.570292774267414, 52.959727533195945],
            [-2.57152094980207, 52.960744619415522],
            [-2.573246730342405, 52.9614473628382],
            [-2.573797047136465, 52.962307683648419],
            [-2.573492592681846, 52.962701088987664],
            [-2.572870546965634, 52.962833536348072],
            [-2.572398419801188, 52.962481631601904],
            [-2.572102456166913, 52.962842630798505],
            [-2.571256390490794, 52.962132048766897],
            [-2.568843660492254, 52.961946757877698],
            [-2.568031488050212, 52.962453147752676],
            [-2.568117127584211, 52.963080194372978],
            [-2.566512954531361, 52.963933750764106],
            [-2.56435619460269, 52.963517919808133],
            [-2.561670670109259, 52.964228217788104],
            [-2.561262978542703, 52.964936706160778],
            [-2.560616293427723, 52.963630911751245],
            [-2.559110594655261, 52.962206903996893],
            [-2.55761528905264, 52.962160894007745],
            [-2.555873234068152, 52.959012000714246],
            [-2.553929717968302, 52.957899207903971],
            [-2.553697718912921, 52.957450822155621],
            [-2.550473663221759, 52.956649571515477],
            [-2.549257450107751, 52.956645312135123],
            [-2.547946613198155, 52.957045098240187],
            [-2.546355488538408, 52.956822298792424],
            [-2.541015498787304, 52.952730515641406],
            [-2.539150762298642, 52.951914669895814],
            [-2.535060805146308, 52.948071337160762],
            [-2.532034248448721, 52.947270495267887],
            [-2.529516151578999, 52.947149602739884],
            [-2.529302536034183, 52.949399689584524],
            [-2.526865148517526, 52.951152659317792],
            [-2.527481619109682, 52.951787268080821],
            [-2.527287314228486, 52.952209730774818],
            [-2.526471952906818, 52.952478532472007],
            [-2.525356675534767, 52.953669163516103],
            [-2.524546872978609, 52.953539699618567],
            [-2.524349185108242, 52.953931608505371],
            [-2.52328092815327, 52.954339939843251],
            [-2.524267327557331, 52.955900634340729],
            [-2.523589056261515, 52.956188587422453],
            [-2.523881941445846, 52.957143761317091],
            [-2.523258538596065, 52.957416188588077],
            [-2.522763789878514, 52.958852164807162],
            [-2.523458685629684, 52.959196992214196],
            [-2.523252344611801, 52.959738159812737],
            [-2.523790704061657, 52.959693537722572],
            [-2.523531677332067, 52.960063241866749],
            [-2.52290190053343, 52.9600579249189],
            [-2.523495198047356, 52.96086345273423],
            [-2.522606837381412, 52.961143335988481],
            [-2.523048229468314, 52.961573780195629],
            [-2.523057814180505, 52.963107316718968],
            [-2.523824294648425, 52.96370262881485],
            [-2.523335596569602, 52.964288189374962],
            [-2.524468053505953, 52.964735358755654],
            [-2.523714598368731, 52.964973303522441],
            [-2.52398621543132, 52.966011271347874],
            [-2.52279140643247, 52.967308299473501],
            [-2.52337090385078, 52.967826229722213],
            [-2.522315197267107, 52.968193144631215],
            [-2.522690695172789, 52.969449997389276],
            [-2.523172398805284, 52.969635754042564],
            [-2.52249061028235, 52.970020799833556],
            [-2.523229722387184, 52.970313295815075],
            [-2.52298310992774, 52.971100047353879],
            [-2.522352030736215, 52.971123498726392],
            [-2.522567074912944, 52.972038563879096],
            [-2.521870017205367, 52.972395806167711],
            [-2.522296337875944, 52.972678894092759],
            [-2.521549327840193, 52.972969833119762],
            [-2.522197289082627, 52.973726583752509],
            [-2.521319468013653, 52.97428597735815],
            [-2.519846657505518, 52.973788129815972],
            [-2.519621452892062, 52.973263240447267],
            [-2.51786291353205, 52.97290055817318],
            [-2.517014540602199, 52.972312756206385],
            [-2.514315949370927, 52.971908262650828],
            [-2.51305921070872, 52.971158594001196],
            [-2.511768499497364, 52.969924534675087],
            [-2.511637253722671, 52.96902437112535],
            [-2.510744484080583, 52.968067254830643],
            [-2.511231949055777, 52.967863797853532],
            [-2.5110978212806, 52.966844087969733],
            [-2.509918682245638, 52.966471603180523],
            [-2.509415144633442, 52.965940696877794],
            [-2.508066783776521, 52.965735221452334],
            [-2.506897139375868, 52.964897019668832],
            [-2.506538719950086, 52.965821750750159],
            [-2.500813974383264, 52.967237564270398],
            [-2.499023008807423, 52.968419103296497],
            [-2.495677911237663, 52.968472658443041],
            [-2.491700677078998, 52.967668460774313],
            [-2.490678444525089, 52.966282041301916],
            [-2.490787848050467, 52.965031176828766],
            [-2.48771575141943, 52.965110348287823],
            [-2.486875262659664, 52.962922202203423],
            [-2.48467840070917, 52.961243891116325],
            [-2.484055437067382, 52.960117373321751],
            [-2.481152788564515, 52.958802341820054],
            [-2.480080401871799, 52.95996000897533],
            [-2.47834226206304, 52.960287039472121],
            [-2.477676296019103, 52.960779636389823],
            [-2.477827830393997, 52.961695936534667],
            [-2.475950119424816, 52.961929105343508],
            [-2.475083689128551, 52.962473729466382],
            [-2.473593429342348, 52.96233674943494],
            [-2.473289639228617, 52.962056594765109],
            [-2.472550783627665, 52.962153024655457],
            [-2.471418116796499, 52.961494113693171],
            [-2.470432367305413, 52.961474648879005],
            [-2.463857313853345, 52.964038182291233],
            [-2.463304593857921, 52.964142817009353],
            [-2.461955519792701, 52.963704897818552],
            [-2.460899737257573, 52.963824061380748],
            [-2.460168563322843, 52.963948251206389],
            [-2.459813496029894, 52.964580674674316],
            [-2.452875017951563, 52.965385798841091],
            [-2.451171136294111, 52.966183339363504],
            [-2.450256913803245, 52.966035787502996],
            [-2.449227255795629, 52.967103118378475],
            [-2.446689984541091, 52.968098809962918],
            [-2.44295722219187, 52.970723273773686],
            [-2.441822583782835, 52.969994872009117],
            [-2.440392046256969, 52.969888716053745],
            [-2.438755305984423, 52.96927001650279],
            [-2.434613800376749, 52.96950547609196],
            [-2.434901892746876, 52.970317951819389],
            [-2.434468936820263, 52.971688603106529],
            [-2.435393659082922, 52.973021923574883],
            [-2.436275804713466, 52.973375561942326],
            [-2.435660710502655, 52.975143312508528],
            [-2.434920103691696, 52.975392331858643],
            [-2.434208931628472, 52.976201270642115],
            [-2.434531015172578, 52.977132280554422],
            [-2.435919880545617, 52.977514630751877],
            [-2.436029983702267, 52.977800085536153],
            [-2.434223697368769, 52.978861141497028],
            [-2.434090068283717, 52.979644594898922],
            [-2.434989350127047, 52.980801820326981],
            [-2.436354673621998, 52.98149617905343],
            [-2.436680816764575, 52.982082878763407],
            [-2.435684018640782, 52.982934225269815],
            [-2.438490645695992, 52.984598600764343],
            [-2.438168132750063, 52.98555175300281],
            [-2.435367336194386, 52.986692896154082],
            [-2.433597125762447, 52.986635544950268],
            [-2.432357467314918, 52.987050870827133],
            [-2.431638408352035, 52.986047587300256],
            [-2.430005212759705, 52.986134411889282],
            [-2.428882868714561, 52.985763616106048],
            [-2.42547487430764, 52.986381743398077],
            [-2.423786630486069, 52.986325750501194],
            [-2.421021079611999, 52.987152704352226],
            [-2.420552297133586, 52.987811478550519],
            [-2.418888770125977, 52.988467277047228],
            [-2.418624246056664, 52.988070884468186],
            [-2.417537952214959, 52.987880538676123],
            [-2.416201662397831, 52.988180972867809],
            [-2.412229371523321, 52.988242462651407],
            [-2.410861692835764, 52.987909203797962],
            [-2.409381785055585, 52.989094599440406],
            [-2.408309390529637, 52.989112671961138],
            [-2.408321346250739, 52.989748170372437],
            [-2.405226533171056, 52.99078533585736],
            [-2.404474735323999, 52.99163738033986],
            [-2.405580322219868, 52.992451632698128],
            [-2.405069180644394, 52.99307273460029],
            [-2.405056074933137, 52.994218906838782],
            [-2.407253665334028, 52.995175041806675],
            [-2.407541171316923, 52.995645092018471],
            [-2.406986351884266, 52.995900489824535],
            [-2.404800273842511, 52.995700305283428],
            [-2.404088833992702, 52.995938244485572],
            [-2.40332849188699, 52.995086850443265],
            [-2.402696183669014, 52.995022474517832],
            [-2.400801945425175, 52.995439686389616],
            [-2.397484217399164, 52.994981592206912],
            [-2.391196915909862, 52.996461408127601],
            [-2.389734456902616, 52.995375820175887],
            [-2.38921111522877, 52.995497991091533],
            [-2.389081424879593, 52.996152831574598],
            [-2.385524932314005, 52.996647144444644],
            [-2.384249054463139, 52.997933140241187],
            [-2.382740787789489, 52.997886769074746],
            [-2.380949133348922, 52.998145123294968],
            [-2.380793391616861, 52.998394623898612],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000052",
        LAD13CDO: "00HE",
        LAD13NM: "Cornwall",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-5.266168566674784, 50.012445020466842],
              [-5.265034395749467, 50.011777960502833],
              [-5.265395712964065, 50.011465506042128],
              [-5.266684098870064, 50.011915903352921],
              [-5.267162980503095, 50.011566843567707],
              [-5.268683484318497, 50.0118991215111],
              [-5.268517737895378, 50.012192577232611],
              [-5.267119232387616, 50.012444317741718],
              [-5.267352132207048, 50.012934349428264],
              [-5.266966179287393, 50.013295184777398],
              [-5.266263383292735, 50.013081086851066],
              [-5.266098330324626, 50.012706093385532],
              [-5.265839826544031, 50.012558640553159],
              [-5.266168566674784, 50.012445020466842],
            ],
          ],
          [
            [
              [-5.742349993983914, 50.064717083934994],
              [-5.742172840762396, 50.064344926690779],
              [-5.742691869101129, 50.064781620851505],
              [-5.742349993983914, 50.064717083934994],
            ],
          ],
          [
            [
              [-5.743352527832402, 50.065098576424752],
              [-5.7435320760155, 50.064999208263956],
              [-5.743983117127589, 50.065298637965682],
              [-5.743352527832402, 50.065098576424752],
            ],
          ],
          [
            [
              [-5.74406375395519, 50.066225430454324],
              [-5.744116409319981, 50.066058183576324],
              [-5.74429444262184, 50.066262064389015],
              [-5.74406375395519, 50.066225430454324],
            ],
          ],
          [
            [
              [-5.746496028754768, 50.066890897837752],
              [-5.746661552097506, 50.066755090153379],
              [-5.746848089330993, 50.0671215392777],
              [-5.746496028754768, 50.066890897837752],
            ],
          ],
          [
            [
              [-5.531827261059417, 50.081540727366992],
              [-5.532387405351529, 50.080697767527163],
              [-5.532418482348816, 50.081671161094008],
              [-5.533167528817491, 50.081666330529416],
              [-5.533480470193487, 50.082004965116575],
              [-5.532795146457862, 50.082115816159586],
              [-5.531827261059417, 50.081540727366992],
            ],
          ],
          [
            [
              [-5.478661225981737, 50.118565751256007],
              [-5.478383019136014, 50.118511120031734],
              [-5.477011016604366, 50.118751086281364],
              [-5.477234201390822, 50.119571177478903],
              [-5.476840964896559, 50.118875838603174],
              [-5.475613206022578, 50.11805616880747],
              [-5.475440678547595, 50.117258847255172],
              [-5.476364764634862, 50.116129073329326],
              [-5.478824191154271, 50.1154507545643],
              [-5.480018372188888, 50.116309183567559],
              [-5.480033916818329, 50.116947472538612],
              [-5.478307840988829, 50.119692822538852],
              [-5.477970604834312, 50.119673247913852],
              [-5.478661225981737, 50.118565751256007],
            ],
          ],
          [
            [
              [-5.721337319763534, 50.120169147045935],
              [-5.721488436851338, 50.119484125553676],
              [-5.722515592411598, 50.119683276774701],
              [-5.722042787022636, 50.120045737953028],
              [-5.722788572482782, 50.120783840461655],
              [-5.722748030956327, 50.121396936661014],
              [-5.72254679576453, 50.121598631626604],
              [-5.722254036034845, 50.121378606919336],
              [-5.721609988607072, 50.121338100236677],
              [-5.721205613540439, 50.120761776156726],
              [-5.721820967184517, 50.120234596995331],
              [-5.721337319763534, 50.120169147045935],
            ],
          ],
          [
            [
              [-4.904361741054129, 50.195270377190987],
              [-4.905675547965078, 50.194821900232697],
              [-4.906218510976805, 50.19529406744175],
              [-4.906014649658612, 50.195605920467116],
              [-4.903992068388674, 50.196267339692959],
              [-4.903848019118374, 50.196087434013698],
              [-4.904361741054129, 50.195270377190987],
            ],
          ],
          [
            [
              [-5.55132331661579, 50.213830182467525],
              [-5.551013668395501, 50.213474397449623],
              [-5.551773447151913, 50.213604985709516],
              [-5.55221988791465, 50.213217061896387],
              [-5.551708448102435, 50.213711335614008],
              [-5.55132331661579, 50.213830182467525],
            ],
          ],
          [
            [
              [-5.551597239118217, 50.213969342805008],
              [-5.552067109083753, 50.213915375525936],
              [-5.551698140506066, 50.214383695991451],
              [-5.551377586423436, 50.214239665439742],
              [-5.551597239118217, 50.213969342805008],
            ],
          ],
          [
            [
              [-5.399462331184225, 50.242538647621622],
              [-5.40088045087418, 50.241690138144051],
              [-5.402154820704668, 50.242092711207412],
              [-5.401232476884843, 50.242771973571891],
              [-5.40087633198315, 50.242439650208425],
              [-5.399805854578133, 50.242831761247075],
              [-5.399462331184225, 50.242538647621622],
            ],
          ],
          [
            [
              [-4.220221364504266, 50.324252050727445],
              [-4.220037583969535, 50.324167415401021],
              [-4.220190714309606, 50.324167188276924],
              [-4.220221364504266, 50.324252050727445],
            ],
          ],
          [
            [
              [-4.446805034672411, 50.336807281494892],
              [-4.445907844404781, 50.336468176575011],
              [-4.445891667398508, 50.33618068408898],
              [-4.446778774184471, 50.336132326336227],
              [-4.446922356354244, 50.336519676599558],
              [-4.448035406024955, 50.33651962070094],
              [-4.450435133972091, 50.335551574637321],
              [-4.451994079684936, 50.335668903718926],
              [-4.451490672729062, 50.336318154638704],
              [-4.45290279311626, 50.336203805015515],
              [-4.45249500556459, 50.336499345687457],
              [-4.453241353918905, 50.336964813143709],
              [-4.45265007872592, 50.337681585878165],
              [-4.452933634110137, 50.337811422176053],
              [-4.450662155581075, 50.338869460905421],
              [-4.450226499144703, 50.338296684945163],
              [-4.449423258597379, 50.338200284051332],
              [-4.448638755280839, 50.337129347890141],
              [-4.446805034672411, 50.336807281494892],
            ],
          ],
          [
            [
              [-5.232460155132851, 50.334226443926468],
              [-5.232270530930585, 50.333897072648995],
              [-5.233244819101236, 50.333984205065228],
              [-5.232460155132851, 50.334226443926468],
            ],
          ],
          [
            [
              [-4.208851348421643, 50.362178129167866],
              [-4.208927418033357, 50.362090336735321],
              [-4.209266806503859, 50.362162138585433],
              [-4.208851348421643, 50.362178129167866],
            ],
          ],
          [
            [
              [-4.834383548217241, 50.517170021325043],
              [-4.834189104348382, 50.516985862046688],
              [-4.834917548465254, 50.517087746709372],
              [-4.835783394608876, 50.517975146741513],
              [-4.834383548217241, 50.517170021325043],
            ],
          ],
          [
            [
              [-5.026577305169983, 50.526302328788319],
              [-5.027658232878014, 50.525575258502776],
              [-5.028438403670986, 50.525594528987192],
              [-5.028078553578064, 50.526167007715969],
              [-5.026577305169983, 50.526302328788319],
            ],
          ],
          [
            [
              [-4.850805231937877, 50.530246102704005],
              [-4.850973523506529, 50.529854289935948],
              [-4.850612507529592, 50.530209447752867],
              [-4.850222786224901, 50.52992485680317],
              [-4.848881056673234, 50.530375093463505],
              [-4.848869090586976, 50.529403916259163],
              [-4.848081202247244, 50.527921026675912],
              [-4.848212165093871, 50.526932857334209],
              [-4.849250804650927, 50.527472315918395],
              [-4.848724532864863, 50.528082999321377],
              [-4.848489796434509, 50.528355380651028],
              [-4.84892522033238, 50.528860137459482],
              [-4.848735371029467, 50.528124968602164],
              [-4.849343254915687, 50.527554605532089],
              [-4.850696027227653, 50.528318430612678],
              [-4.849871705744535, 50.528619273990152],
              [-4.84971683830173, 50.529233834760916],
              [-4.849949351695015, 50.528970879212615],
              [-4.850235870253337, 50.529346151505095],
              [-4.849649398728422, 50.529613280758504],
              [-4.850768882760115, 50.529620035126612],
              [-4.849956297377905, 50.528781812011687],
              [-4.85097415225553, 50.528484320991126],
              [-4.852740875984341, 50.529465553482495],
              [-4.85497516290746, 50.530172629221191],
              [-4.854783977822039, 50.530558713232892],
              [-4.852008139888751, 50.530746430883795],
              [-4.851971069064164, 50.530412722001358],
              [-4.850805231937877, 50.530246102704005],
            ],
          ],
          [
            [
              [-4.997099170350489, 50.572651956264934],
              [-4.996879591350258, 50.572197053648701],
              [-4.99645868038262, 50.572297396389246],
              [-4.996531284706442, 50.571894787249065],
              [-4.995874792413653, 50.572300287164744],
              [-4.995319126424641, 50.571646243416026],
              [-4.996962411846633, 50.571321479360542],
              [-4.998016174656218, 50.571411272688479],
              [-4.99933495518821, 50.572270518215461],
              [-4.998927794985119, 50.572576057586183],
              [-4.998714606785686, 50.57224422888293],
              [-4.997622080379942, 50.572167134197237],
              [-4.997099170350489, 50.572651956264934],
            ],
          ],
          [
            [
              [-4.947006642251864, 50.592340012450578],
              [-4.947815555239504, 50.592033479781271],
              [-4.948796188655835, 50.592077899999019],
              [-4.949165512851235, 50.592379774775239],
              [-4.948225847170501, 50.592855140414478],
              [-4.947522500491328, 50.592861262032429],
              [-4.947006642251864, 50.592340012450578],
            ],
          ],
          [
            [
              [-4.913734830955237, 50.596401605819004],
              [-4.914915288642689, 50.596157063800241],
              [-4.915730813402224, 50.596684438768136],
              [-4.915229929488579, 50.596720373175707],
              [-4.915329403557677, 50.59752924116632],
              [-4.914612928144824, 50.597598457731692],
              [-4.913924833530487, 50.597349431745826],
              [-4.914030926102564, 50.596985171862954],
              [-4.913558985926533, 50.596757716983696],
              [-4.913734830955237, 50.596401605819004],
            ],
          ],
          [
            [
              [-4.726794613110024, 50.682722551517131],
              [-4.728389951395851, 50.682669899352511],
              [-4.728131358252121, 50.68333706970769],
              [-4.727302621920472, 50.683377166508691],
              [-4.726794613110024, 50.682722551517131],
            ],
          ],
          [
            [
              [-4.704028374792111, 50.693168175595076],
              [-4.704557905245054, 50.692674665817968],
              [-4.706101533430718, 50.692726062198425],
              [-4.70585306563907, 50.693009770336815],
              [-4.704312720744725, 50.693310886057162],
              [-4.704028374792111, 50.693168175595076],
            ],
          ],
          [
            [
              [-4.460050971364513, 50.930182785964867],
              [-4.458224554492546, 50.929338059424531],
              [-4.456164283446822, 50.928996496895465],
              [-4.458185370476818, 50.926760292294418],
              [-4.458248113731567, 50.924145300247403],
              [-4.458703680891273, 50.923015942817649],
              [-4.460497398134313, 50.920576721484331],
              [-4.462149848618442, 50.919399619958824],
              [-4.463212613476959, 50.91759276779964],
              [-4.463417603370269, 50.915251781794097],
              [-4.462653196161034, 50.913493388028733],
              [-4.459938552927579, 50.910766987815812],
              [-4.459639542414985, 50.909251494967194],
              [-4.458564804819662, 50.908428703788758],
              [-4.458489312438734, 50.907617230562316],
              [-4.4569298306794, 50.907528660281038],
              [-4.449970758027059, 50.9058474020934],
              [-4.450696164003422, 50.903517994898756],
              [-4.448654147722215, 50.902092138860162],
              [-4.44790461134479, 50.902011630961255],
              [-4.446797124239494, 50.900909704893287],
              [-4.446368748371635, 50.898246551439712],
              [-4.446848201594094, 50.896025755045684],
              [-4.446317794836159, 50.895701399324807],
              [-4.445850301527097, 50.894488007627388],
              [-4.446841709330062, 50.892538889608211],
              [-4.446179991183449, 50.892231677899275],
              [-4.446115257287047, 50.891105178333618],
              [-4.444947922076178, 50.8902392353449],
              [-4.4425978735007, 50.889487990804064],
              [-4.442372497184545, 50.889121254258001],
              [-4.441107133422395, 50.888852733191605],
              [-4.439990765902383, 50.888170053273214],
              [-4.439904602276634, 50.886929774217052],
              [-4.439552969811146, 50.886948820335306],
              [-4.439761356965414, 50.886555918489321],
              [-4.439428437940744, 50.886552087704828],
              [-4.439762928096898, 50.886313945094109],
              [-4.437834072033106, 50.882568663929973],
              [-4.436728911682951, 50.882505411009738],
              [-4.435959975728261, 50.881855908912449],
              [-4.434750852120207, 50.881489009843101],
              [-4.434865054526187, 50.881007242176267],
              [-4.43403128406959, 50.881185635989077],
              [-4.43221303436582, 50.880123585622258],
              [-4.432403678877737, 50.879853386061725],
              [-4.431760503687886, 50.879787647717116],
              [-4.431609205975489, 50.878711513180328],
              [-4.429919002036915, 50.877730403372979],
              [-4.429021309339093, 50.875859445373017],
              [-4.427446348838416, 50.874713107944622],
              [-4.426464753200634, 50.873111899881089],
              [-4.427066714813083, 50.872222454460527],
              [-4.42498683651561, 50.871395085863774],
              [-4.424137046189768, 50.87109795682494],
              [-4.423713694645839, 50.870554518250238],
              [-4.424092425692431, 50.870046577820197],
              [-4.423475249199104, 50.868945036764764],
              [-4.424025930426073, 50.869011842576924],
              [-4.424010450151393, 50.868713561053426],
              [-4.422451971295987, 50.868179311336803],
              [-4.423047527025507, 50.868042823108986],
              [-4.422089940283539, 50.867419635112739],
              [-4.421733956998942, 50.86754394949746],
              [-4.421787359443194, 50.867175882612358],
              [-4.420894604735654, 50.866651173061285],
              [-4.420929530053506, 50.866091016905187],
              [-4.423395306969075, 50.866445445783121],
              [-4.433626978044076, 50.865518288727742],
              [-4.432883342231399, 50.861937064601186],
              [-4.433622319500232, 50.859563396244518],
              [-4.43724988742918, 50.856150836888652],
              [-4.437349572478972, 50.85408460582677],
              [-4.436354072812318, 50.852705022719014],
              [-4.439164233999389, 50.851420387538489],
              [-4.439572725557165, 50.850726491929869],
              [-4.440051172350204, 50.85066521650414],
              [-4.44001608798709, 50.849993190478592],
              [-4.440419262259089, 50.849823760015802],
              [-4.439971359111786, 50.849626266805551],
              [-4.440872654616342, 50.849038079278294],
              [-4.440708733827068, 50.847857883466396],
              [-4.442240140831257, 50.846985773115023],
              [-4.44130371777423, 50.846655507635198],
              [-4.441970174403572, 50.846305174412535],
              [-4.441352788997166, 50.844602920258396],
              [-4.440497396625907, 50.844599237866539],
              [-4.440382832377418, 50.844282343757541],
              [-4.440832562025757, 50.844080457718078],
              [-4.44040997752473, 50.843550553205183],
              [-4.439661161606031, 50.843465486239488],
              [-4.439879040521964, 50.843121849528579],
              [-4.44098001061188, 50.84306823042521],
              [-4.440984430827381, 50.842146239642787],
              [-4.441513190104276, 50.842016448422854],
              [-4.441157026327849, 50.841370930492594],
              [-4.442313252672817, 50.839901364568128],
              [-4.441919097761832, 50.838392308029398],
              [-4.442414865826811, 50.837985284318719],
              [-4.442081830292139, 50.837727831306516],
              [-4.442554917365186, 50.837484975181951],
              [-4.442540199612167, 50.837040073602289],
              [-4.443363456969848, 50.836866329368583],
              [-4.443092121969837, 50.83653923097242],
              [-4.443488941759414, 50.836087505774195],
              [-4.442988075930264, 50.835825477125937],
              [-4.443635062987667, 50.835377504392639],
              [-4.442557728712509, 50.835445045495632],
              [-4.442218360787906, 50.834875628856331],
              [-4.443450878965508, 50.83459797399145],
              [-4.442561335755717, 50.833665029287367],
              [-4.442953970140636, 50.832752893070946],
              [-4.442516230509955, 50.832366320288742],
              [-4.442734036048553, 50.831913849568103],
              [-4.443319373598896, 50.832026605280753],
              [-4.443145618230866, 50.831473508142892],
              [-4.444048122081905, 50.831432110690294],
              [-4.443743215852932, 50.830924035576572],
              [-4.444229759045873, 50.829771581362166],
              [-4.443779996133626, 50.829589432506438],
              [-4.444000761401433, 50.8292484245029],
              [-4.443425098817942, 50.828423132970492],
              [-4.443614539076642, 50.827890307164303],
              [-4.444116815569699, 50.827827614377689],
              [-4.443323488586426, 50.826613839917286],
              [-4.442754747880063, 50.826899177869258],
              [-4.442930384933107, 50.826129196594231],
              [-4.442266524101358, 50.826278914429267],
              [-4.441979739729618, 50.82598091830436],
              [-4.441547689214972, 50.826382110526566],
              [-4.440524230267309, 50.825709187166304],
              [-4.439783824173722, 50.825643733894012],
              [-4.440090241415849, 50.824766688484395],
              [-4.438892060371835, 50.824636156730108],
              [-4.439351930027168, 50.824114770131736],
              [-4.438516820957955, 50.82315817099407],
              [-4.438829323156618, 50.822806259867363],
              [-4.439215709499534, 50.823054511087278],
              [-4.439098869703964, 50.822692692023168],
              [-4.439628928560945, 50.822564679134075],
              [-4.439877727890235, 50.822026120066255],
              [-4.44009625334199, 50.822622356781309],
              [-4.440993754363407, 50.822188941281077],
              [-4.441127240958187, 50.821508886823281],
              [-4.44182894697926, 50.821378169546406],
              [-4.442015107306404, 50.820537814197955],
              [-4.442384747753289, 50.820465314201833],
              [-4.442334054979387, 50.819820595349398],
              [-4.441334690822776, 50.819062629614137],
              [-4.441980718064329, 50.818709122275941],
              [-4.441251875631839, 50.818400594519353],
              [-4.442193610461961, 50.817456273715592],
              [-4.442691133543794, 50.817875772931444],
              [-4.443189822074325, 50.817773584149933],
              [-4.443087569160821, 50.816494957641652],
              [-4.442666980889491, 50.816161991214834],
              [-4.443932694708206, 50.815686659329565],
              [-4.443988994871614, 50.815323913690115],
              [-4.444625240868137, 50.815273699527587],
              [-4.444606401718404, 50.814586939967434],
              [-4.445348708885862, 50.814284460047872],
              [-4.445968043749191, 50.813476384350857],
              [-4.445060992457072, 50.8117937116265],
              [-4.445275480365209, 50.811442937743024],
              [-4.444742864930541, 50.811329087837471],
              [-4.445411253805208, 50.810862664082109],
              [-4.445185460532414, 50.810344838008056],
              [-4.44719063810611, 50.808621755926737],
              [-4.446855555010471, 50.80826722259868],
              [-4.447458820852681, 50.808022510606492],
              [-4.447798155725963, 50.80824114081534],
              [-4.448413153356421, 50.807786613059491],
              [-4.449262968355334, 50.807913570126843],
              [-4.449184368152054, 50.807415144604882],
              [-4.449659615694776, 50.807192897421793],
              [-4.450162834970357, 50.807342419767714],
              [-4.450985938663652, 50.806715313213815],
              [-4.451654438506232, 50.806849661972841],
              [-4.452895334418026, 50.805880071001823],
              [-4.452656089449582, 50.805458779497293],
              [-4.453081174542007, 50.80514673194056],
              [-4.452079438744004, 50.805099448887653],
              [-4.451722212117764, 50.804810153201799],
              [-4.454074591329571, 50.804168843238855],
              [-4.458439799897596, 50.803802553757855],
              [-4.463918928091525, 50.801652396886958],
              [-4.469949504081238, 50.801140736073371],
              [-4.47071411218049, 50.801375473329557],
              [-4.471227292585608, 50.801982499654294],
              [-4.472381522935371, 50.802257535463511],
              [-4.4745754948741, 50.801795454461988],
              [-4.47271114829325, 50.800275412915603],
              [-4.4768074173321, 50.799449133456392],
              [-4.475718979016898, 50.79888762741313],
              [-4.474411532867349, 50.797323406010094],
              [-4.483795671606249, 50.796076919494588],
              [-4.484556142529159, 50.796394401189922],
              [-4.489922391481199, 50.796323083194309],
              [-4.489665519873063, 50.795228571870737],
              [-4.493439395764302, 50.793158383909478],
              [-4.492731159147384, 50.792516049622975],
              [-4.492924424646877, 50.792254680916017],
              [-4.4891102374409, 50.791429562488986],
              [-4.488690084320843, 50.791106644715605],
              [-4.486366157828173, 50.790979946551204],
              [-4.483932702086241, 50.791436564725849],
              [-4.480124325602615, 50.790797210697448],
              [-4.477667044291904, 50.790026490054039],
              [-4.475664492968603, 50.789132734386499],
              [-4.476326348572218, 50.787641821469641],
              [-4.474824263985161, 50.785395477798929],
              [-4.47307295844751, 50.784982933167939],
              [-4.467858280024521, 50.785106039636524],
              [-4.462945160346666, 50.784029906148376],
              [-4.456401518862958, 50.783699380684318],
              [-4.453844612855765, 50.784289288308365],
              [-4.451298731767957, 50.785660504855755],
              [-4.450057162210092, 50.784546139867011],
              [-4.448585799761656, 50.783838630373928],
              [-4.448662755668945, 50.782623694235866],
              [-4.447306798986675, 50.782005485664868],
              [-4.44726158865016, 50.78133006983338],
              [-4.446205006084023, 50.780765824406764],
              [-4.446497901173489, 50.779120931308903],
              [-4.445972477234486, 50.778947576291792],
              [-4.446326578169741, 50.778094688959058],
              [-4.445877587296294, 50.777428645134421],
              [-4.444867377983536, 50.777153028754277],
              [-4.445768893178353, 50.776460449542668],
              [-4.444819458648165, 50.776152977309167],
              [-4.444563136712529, 50.776487541538884],
              [-4.443976306896341, 50.775734438998164],
              [-4.44353197904871, 50.775782429461515],
              [-4.443801544819065, 50.776082581634654],
              [-4.443427488751523, 50.776254117998803],
              [-4.442067999514442, 50.775345410522796],
              [-4.441554830391457, 50.775651170842735],
              [-4.440959203229047, 50.775436091759531],
              [-4.44088660704113, 50.775049961311062],
              [-4.440058871260192, 50.775171624419698],
              [-4.440081206263055, 50.775464368240783],
              [-4.438720156536444, 50.774278633103336],
              [-4.437411154368341, 50.774310509755047],
              [-4.43698750629698, 50.773883148529336],
              [-4.437530428406536, 50.773685616113241],
              [-4.436268361086528, 50.772955588262661],
              [-4.436181097263439, 50.77228644392585],
              [-4.434848120704278, 50.771992303378006],
              [-4.434512957616332, 50.771137658713286],
              [-4.433479439811579, 50.770846241977679],
              [-4.434014371007685, 50.770112837521111],
              [-4.433416801903811, 50.769994898468674],
              [-4.43290211124657, 50.770243989150977],
              [-4.433068044667797, 50.76969727521027],
              [-4.432275026125541, 50.769775886126254],
              [-4.432305289186949, 50.769156452907289],
              [-4.431571623406236, 50.768847968900793],
              [-4.429972349759216, 50.76937870070828],
              [-4.429510268435323, 50.768836988144344],
              [-4.427739479314426, 50.768959329413157],
              [-4.427197899055912, 50.768360801335611],
              [-4.428043895386548, 50.767470743217544],
              [-4.42689733256858, 50.767321933210638],
              [-4.426858871740393, 50.7669899483733],
              [-4.423915921544637, 50.765067964931326],
              [-4.42410059477095, 50.764744829976429],
              [-4.423735865463215, 50.764575229139687],
              [-4.423360616301498, 50.764778205993828],
              [-4.422098327745199, 50.764281880778178],
              [-4.419251672534823, 50.765098313115551],
              [-4.418694549610611, 50.76491020430219],
              [-4.41754282308538, 50.76515445635539],
              [-4.416483502175171, 50.764880514367263],
              [-4.416563833427438, 50.764265444698381],
              [-4.415314918605789, 50.764384871609252],
              [-4.415074814955675, 50.764101133247436],
              [-4.414877449904318, 50.764536943893624],
              [-4.414176594829688, 50.764586539916266],
              [-4.413578246996321, 50.764423544988432],
              [-4.413094127684817, 50.763696944545828],
              [-4.41219094480113, 50.764003454185612],
              [-4.411473219618162, 50.763394108948532],
              [-4.410844981397005, 50.763752482140475],
              [-4.409963225205108, 50.763317410058541],
              [-4.409518191737037, 50.762577395062088],
              [-4.408814286867853, 50.762429149892967],
              [-4.409003801036133, 50.763412794947236],
              [-4.409744419360469, 50.764247435988374],
              [-4.409281632753522, 50.76443149023072],
              [-4.409805557991, 50.765933481363561],
              [-4.409239052431189, 50.76636252258745],
              [-4.410028680840709, 50.76679770640694],
              [-4.409268806159036, 50.768183229655996],
              [-4.410125330792183, 50.768870666106594],
              [-4.409693421979023, 50.769187196974428],
              [-4.410107663266976, 50.769740768782867],
              [-4.411466022892147, 50.76995371826451],
              [-4.412190288628915, 50.770936186888278],
              [-4.413131777055334, 50.771316041069447],
              [-4.412723309951569, 50.77139914841996],
              [-4.413400024728009, 50.771760196477608],
              [-4.413039509496084, 50.772306411849605],
              [-4.41390281636667, 50.772957703887265],
              [-4.414387884896473, 50.774416407446537],
              [-4.412935293575647, 50.77521368928042],
              [-4.403216481152809, 50.776222142607423],
              [-4.393475806152571, 50.778254664491591],
              [-4.393185753880259, 50.777991694691892],
              [-4.389009497678036, 50.774791798738221],
              [-4.387758008956877, 50.774032252315287],
              [-4.386747385054914, 50.773935126587567],
              [-4.385168316911257, 50.772925027116813],
              [-4.383974992918876, 50.771494186674758],
              [-4.381789463195099, 50.770548610037274],
              [-4.381033268429047, 50.77054427224477],
              [-4.380179587650293, 50.769486008019982],
              [-4.380775812850636, 50.769329025916981],
              [-4.3812866036001, 50.768635036899028],
              [-4.384151624577687, 50.767521456832625],
              [-4.384883857824546, 50.766275177836086],
              [-4.384881475021092, 50.765392000769424],
              [-4.387356581833937, 50.765065210893134],
              [-4.386585621474644, 50.763933344897787],
              [-4.387854134916314, 50.757876777785697],
              [-4.387570692108164, 50.757712594738123],
              [-4.38541427420793, 50.758247830632506],
              [-4.384502099492903, 50.758100104119428],
              [-4.382064826153299, 50.757294597531612],
              [-4.38159697550003, 50.757379709424661],
              [-4.38112445719536, 50.756954046545381],
              [-4.381417507914568, 50.756581996866132],
              [-4.380787791847657, 50.756495026641197],
              [-4.381753008883525, 50.756096651901522],
              [-4.381717213773854, 50.755698942024708],
              [-4.38105961419584, 50.755900356286233],
              [-4.380799421747969, 50.755690711157143],
              [-4.381408295435746, 50.755116136812376],
              [-4.381965529170174, 50.755200087871323],
              [-4.381547360972427, 50.754699563776377],
              [-4.382504176472899, 50.754248288518866],
              [-4.382089674079419, 50.753624471324635],
              [-4.383322552960987, 50.753305157131763],
              [-4.384070759632416, 50.752711529389721],
              [-4.383591293502295, 50.751758060802906],
              [-4.384172329339387, 50.749940146499853],
              [-4.38449290503924, 50.749748308043856],
              [-4.38486089571722, 50.750238156198058],
              [-4.385231724517365, 50.750086660569963],
              [-4.384196426483674, 50.748852258000333],
              [-4.385157484241702, 50.748766036323673],
              [-4.384903257645918, 50.747725216360159],
              [-4.383458069232466, 50.746597230969009],
              [-4.383749815399027, 50.745922097224877],
              [-4.382986993650014, 50.745695754192276],
              [-4.382206009320756, 50.746004930897115],
              [-4.381547322275166, 50.744925600882219],
              [-4.380491881203671, 50.74488240629335],
              [-4.379374035439985, 50.744167712106261],
              [-4.378930735538688, 50.742665740762384],
              [-4.379445875999498, 50.741000295457951],
              [-4.377433610478573, 50.73955552624782],
              [-4.37542073828368, 50.738964282453232],
              [-4.373853207440078, 50.737761323650481],
              [-4.373383354007243, 50.734804614479899],
              [-4.371223326097204, 50.733427507166788],
              [-4.372361887744853, 50.73172781873906],
              [-4.371341126865023, 50.731271004316156],
              [-4.371751707591628, 50.730702310964261],
              [-4.372394106120445, 50.730682938372247],
              [-4.372328844633773, 50.729419684907924],
              [-4.37174270159399, 50.729205862367046],
              [-4.371386515261896, 50.729644832168582],
              [-4.371210286670727, 50.728484571112091],
              [-4.370392590309995, 50.728567764419758],
              [-4.371008431070413, 50.727097278227085],
              [-4.369939354250764, 50.726186329349204],
              [-4.369142806976511, 50.723290209819673],
              [-4.368124902527904, 50.723335185612925],
              [-4.366610901007538, 50.722392774286597],
              [-4.366002504025639, 50.722381744536555],
              [-4.36423247816166, 50.721249327210337],
              [-4.364709958207213, 50.72046164116631],
              [-4.36147522372444, 50.718796762044299],
              [-4.362575664321952, 50.718225809258442],
              [-4.36344547487454, 50.717098286818292],
              [-4.36384386050239, 50.715443369224815],
              [-4.363563069302917, 50.714564936487562],
              [-4.365429509594392, 50.713341625465503],
              [-4.3649241844094, 50.712899475198711],
              [-4.365677048766532, 50.712349936457429],
              [-4.364891728402256, 50.712198586694541],
              [-4.366684941639169, 50.71112694369053],
              [-4.367861270027986, 50.709312297767561],
              [-4.368776114362499, 50.709167782975193],
              [-4.367227104876747, 50.70732487313613],
              [-4.368708263030569, 50.705855696533177],
              [-4.367042206615789, 50.704020560254563],
              [-4.367316524932098, 50.703646224083272],
              [-4.368428604218653, 50.703398767038919],
              [-4.368663894940554, 50.702643866301919],
              [-4.369307541310939, 50.702291696789779],
              [-4.368376557117926, 50.701804251688699],
              [-4.367987355600194, 50.70270888593577],
              [-4.367175038178742, 50.702440276934212],
              [-4.366960283839886, 50.701297878077071],
              [-4.366177589538979, 50.700463823875296],
              [-4.366708184975748, 50.69786720949859],
              [-4.364320235243896, 50.697804167213555],
              [-4.364225232082633, 50.697407651500654],
              [-4.362155257031321, 50.696795760756899],
              [-4.362222712886147, 50.695996606315241],
              [-4.360947973586482, 50.695793993501709],
              [-4.35958694024521, 50.696241597184589],
              [-4.358966999680241, 50.695570591720276],
              [-4.355937859656139, 50.694663211806045],
              [-4.354797048983227, 50.694617021005499],
              [-4.353860527747726, 50.69412597554529],
              [-4.353954018636371, 50.693617712748917],
              [-4.35355364687339, 50.693370366415259],
              [-4.352611077201724, 50.693776155484002],
              [-4.352354021274459, 50.69336491457387],
              [-4.353512961209501, 50.69264265763335],
              [-4.352775823499697, 50.69206033170915],
              [-4.352796367457271, 50.691169490282235],
              [-4.351724846584817, 50.690780090672661],
              [-4.350314576989618, 50.691006423717482],
              [-4.348910637397386, 50.689546198709905],
              [-4.348622051627376, 50.688927819267228],
              [-4.349107871768678, 50.68595623113346],
              [-4.348408683357641, 50.685138363910887],
              [-4.348453402498828, 50.683085882753687],
              [-4.34754596518395, 50.682434104327356],
              [-4.346573838646427, 50.682442897769015],
              [-4.346172982445446, 50.682069617345277],
              [-4.346772188793015, 50.68023352103372],
              [-4.346545375781804, 50.679971861431056],
              [-4.345393091819516, 50.680000463889982],
              [-4.345206917622082, 50.677828511800968],
              [-4.344376168687424, 50.676781218512332],
              [-4.34465472353373, 50.675497533790107],
              [-4.347032950831616, 50.672921363562857],
              [-4.346736521367163, 50.672455139244398],
              [-4.344382279930147, 50.671281130357677],
              [-4.344486278681287, 50.670329247137872],
              [-4.34551760111998, 50.669185105528925],
              [-4.345841472945733, 50.668045310595055],
              [-4.345181894244281, 50.664807178200348],
              [-4.344429204230932, 50.66285079499022],
              [-4.343329362513007, 50.661878165539214],
              [-4.342153877274617, 50.661460192343746],
              [-4.339459284813529, 50.661509849408617],
              [-4.338819040400232, 50.661269070198991],
              [-4.339555741306885, 50.658582987902818],
              [-4.338027584160175, 50.657016298309934],
              [-4.335573916863509, 50.655700203318801],
              [-4.336635210608817, 50.654335177528814],
              [-4.336824104093377, 50.652668353368163],
              [-4.335356031027891, 50.652249887132818],
              [-4.335718879701042, 50.651322500309234],
              [-4.334616300425694, 50.649115835226432],
              [-4.335341206013634, 50.647331234848323],
              [-4.335097824410594, 50.646445684931045],
              [-4.33329350903103, 50.64357670772867],
              [-4.333646258086839, 50.641446997511757],
              [-4.332794517563492, 50.64081377804203],
              [-4.33108382570663, 50.640428901206356],
              [-4.329825518747246, 50.640239119206207],
              [-4.328009823676727, 50.640762018328097],
              [-4.325870236084688, 50.640570020025713],
              [-4.325207496966851, 50.640238774977753],
              [-4.325991283712296, 50.638496228683103],
              [-4.325868120010969, 50.637892476645483],
              [-4.319542488159627, 50.63601022912912],
              [-4.3138784026942, 50.636126507304873],
              [-4.311571955510821, 50.637456687967976],
              [-4.310503024487959, 50.636424677017033],
              [-4.308977878145565, 50.635720997885336],
              [-4.304039225663463, 50.636182180273536],
              [-4.30229674316292, 50.635601437694824],
              [-4.300764057723938, 50.634450788304136],
              [-4.300077200176915, 50.633417429751944],
              [-4.300088678928407, 50.632551959302241],
              [-4.301916561464743, 50.631236863303776],
              [-4.302604265673761, 50.629654674506796],
              [-4.301736341764197, 50.626737916697849],
              [-4.302079013488853, 50.625329841899529],
              [-4.30613655118129, 50.620928723372266],
              [-4.307435751042646, 50.620233813690646],
              [-4.307457753132158, 50.618430931102949],
              [-4.306395721208142, 50.616864488018663],
              [-4.305732954103961, 50.615713758672342],
              [-4.306358450726925, 50.614109388645765],
              [-4.303698658831821, 50.612212989726657],
              [-4.303398562992562, 50.611538961600935],
              [-4.307124393539393, 50.604255396349572],
              [-4.308423608184672, 50.602704216418203],
              [-4.306895903938594, 50.600018227932011],
              [-4.306249473625159, 50.594919590382091],
              [-4.307466043696818, 50.59350317004435],
              [-4.311136535096825, 50.591907629508221],
              [-4.312142158363056, 50.589506873506515],
              [-4.311187842559551, 50.584879372674578],
              [-4.308302293884275, 50.581860577236313],
              [-4.308031570843651, 50.578953592120889],
              [-4.306575484451119, 50.578583106393353],
              [-4.305209802468438, 50.579050879448275],
              [-4.298342882599336, 50.583710786057367],
              [-4.294719785137999, 50.58438847544727],
              [-4.293032826276744, 50.584297595587373],
              [-4.290992100453793, 50.583728862790792],
              [-4.289678729344343, 50.581990926163229],
              [-4.289593292353115, 50.578104381983344],
              [-4.289157041428219, 50.576769216740963],
              [-4.288110282743115, 50.575834604553727],
              [-4.286529873717848, 50.575108340831186],
              [-4.285441648459809, 50.575007389448629],
              [-4.282667530867612, 50.576231976726433],
              [-4.28179732661376, 50.577544212411794],
              [-4.282297058344983, 50.57894021968157],
              [-4.286130592472101, 50.582757715948851],
              [-4.28652392279849, 50.58399479730646],
              [-4.285974699514933, 50.584672058581106],
              [-4.284688203551036, 50.584798949248579],
              [-4.271622241969358, 50.582604610670899],
              [-4.26977376783228, 50.579887527023274],
              [-4.269693731534981, 50.5789869642106],
              [-4.277828156130633, 50.57479235199137],
              [-4.279313533708527, 50.570862461209373],
              [-4.279485269130528, 50.569183464867812],
              [-4.274400241169875, 50.565939763399975],
              [-4.27353654145761, 50.564807168538138],
              [-4.273396036907169, 50.562504679969855],
              [-4.272462296827243, 50.56164597171427],
              [-4.265960825338683, 50.558869372123588],
              [-4.263440891922404, 50.556991830446421],
              [-4.261425137692767, 50.554670915999544],
              [-4.259922634272123, 50.552155619703179],
              [-4.259431673161905, 50.550037106136749],
              [-4.259924167540229, 50.547731304718148],
              [-4.259265993780247, 50.545775234998381],
              [-4.260002327569671, 50.544554805162825],
              [-4.260997115219203, 50.543928368889603],
              [-4.26396142243732, 50.543548746300381],
              [-4.267006094394601, 50.542432648472506],
              [-4.27106247560992, 50.539081434272028],
              [-4.271703024624174, 50.537755763537746],
              [-4.271630858882575, 50.536753407411773],
              [-4.269667289703311, 50.53550913138276],
              [-4.268739062695457, 50.535281686524065],
              [-4.263470769295713, 50.536015673946935],
              [-4.257800555297045, 50.538945531007627],
              [-4.255534684585944, 50.540380884479809],
              [-4.25470665745304, 50.542550160822799],
              [-4.253441675120158, 50.543638684435201],
              [-4.246741603333737, 50.543851868826494],
              [-4.24283408874921, 50.543216690495576],
              [-4.24071883760956, 50.542156537174556],
              [-4.23911641692189, 50.539585337264263],
              [-4.237379375375122, 50.535001087821307],
              [-4.235904426457055, 50.533206296691162],
              [-4.234415309587685, 50.532064743223636],
              [-4.232518131444333, 50.531095595191218],
              [-4.229256931542588, 50.530823526627032],
              [-4.225983600689828, 50.529693546110742],
              [-4.218894962208271, 50.532050504438324],
              [-4.214475208452341, 50.534579328057973],
              [-4.213265385261101, 50.534540282818995],
              [-4.209892089025564, 50.533533174186111],
              [-4.208308568354481, 50.533771000415221],
              [-4.207197155064576, 50.533542045203944],
              [-4.20678623427207, 50.533143298569037],
              [-4.206995904896544, 50.532767860421984],
              [-4.211369284781214, 50.530027039341952],
              [-4.212088787298635, 50.528847710108117],
              [-4.211122700308637, 50.525508516673348],
              [-4.206981299367887, 50.521632361934259],
              [-4.206308837147819, 50.520493849482314],
              [-4.206157240256327, 50.519058542412843],
              [-4.206868974957288, 50.519164669935428],
              [-4.206637835503542, 50.518806584811294],
              [-4.207184985893388, 50.517350830471401],
              [-4.20849679170293, 50.516260120470832],
              [-4.208817624321701, 50.516152395566841],
              [-4.208820095017764, 50.516416779975494],
              [-4.209896334640963, 50.51418015851219],
              [-4.20949462410328, 50.513461949811791],
              [-4.20873166062648, 50.513072592384084],
              [-4.20348955532944, 50.513517359958875],
              [-4.202031709557812, 50.513320101529011],
              [-4.202737492160564, 50.508028909533508],
              [-4.203380282896284, 50.508112078178357],
              [-4.202767659568517, 50.507919507802654],
              [-4.203469651033759, 50.505245711203656],
              [-4.203178296064208, 50.50419799934523],
              [-4.203239086211192, 50.503898238407508],
              [-4.203833508964108, 50.504003906406489],
              [-4.203246775653463, 50.503760480433534],
              [-4.202940138978125, 50.502990080827239],
              [-4.20182019636591, 50.502561566387534],
              [-4.199750195468597, 50.502384858237264],
              [-4.193983471822234, 50.502623259673229],
              [-4.193236162942164, 50.503051984467369],
              [-4.190913970145619, 50.506931785321392],
              [-4.187562195247671, 50.50795363672399],
              [-4.18541522733604, 50.50816037491451],
              [-4.183961207672469, 50.507551782384589],
              [-4.180940675084629, 50.503677053614354],
              [-4.180539717538409, 50.502596264671915],
              [-4.181049541044138, 50.501204292825506],
              [-4.182474777519452, 50.50021518222627],
              [-4.186049569279741, 50.499848562786248],
              [-4.189423999918613, 50.498718393014727],
              [-4.193121832754541, 50.49918480706485],
              [-4.19491351356795, 50.49880654019254],
              [-4.195464047379581, 50.497967780687354],
              [-4.194664143573502, 50.497199471103841],
              [-4.194130526011074, 50.495695799686388],
              [-4.194826703032565, 50.493953968977564],
              [-4.195606174016397, 50.49352282662656],
              [-4.200336106611779, 50.49250975533657],
              [-4.203025302490469, 50.492670230338987],
              [-4.204039072146013, 50.493003605569243],
              [-4.206593396160127, 50.495714635221475],
              [-4.210670076302453, 50.497229234888692],
              [-4.218018111641151, 50.498463712215873],
              [-4.220101575152888, 50.498568782786919],
              [-4.22062863756483, 50.498892412778602],
              [-4.220445937400013, 50.498635065040595],
              [-4.221263961167181, 50.498067200320513],
              [-4.221732465527806, 50.4967226008729],
              [-4.220876055699399, 50.494097324779048],
              [-4.22104675971799, 50.493167652519077],
              [-4.222072984644795, 50.491870864793917],
              [-4.222881967679641, 50.491353528113557],
              [-4.223224138591636, 50.491434234681186],
              [-4.22297538402646, 50.491241113165415],
              [-4.223822543377151, 50.490816581162669],
              [-4.223610277278886, 50.49055980332097],
              [-4.223937861497343, 50.490690255290353],
              [-4.224700748757305, 50.490002894782705],
              [-4.225490082808586, 50.489878667311032],
              [-4.225305690735232, 50.490065078587882],
              [-4.225399780678437, 50.490297131039313],
              [-4.226281747290795, 50.490373797897625],
              [-4.226960168774448, 50.490319439737348],
              [-4.227237351377061, 50.489887802919959],
              [-4.227480275226547, 50.49034635944647],
              [-4.230803877406523, 50.491104757947902],
              [-4.227597104394395, 50.490252380484762],
              [-4.227336192626226, 50.489831045282614],
              [-4.226552146929276, 50.489857745970355],
              [-4.226858873517774, 50.490144190621763],
              [-4.226418567357751, 50.490255152930331],
              [-4.225487499522466, 50.490123661641356],
              [-4.225788216962242, 50.489772526986222],
              [-4.224380974425276, 50.489798542257816],
              [-4.22424556991746, 50.488836940436087],
              [-4.222431915555355, 50.485760467081633],
              [-4.221152758338392, 50.48475774542726],
              [-4.221248457284552, 50.485175053459294],
              [-4.219333597656951, 50.484353541201756],
              [-4.218866662268327, 50.483714857238965],
              [-4.219543715410087, 50.483392537194035],
              [-4.218843614054141, 50.48358397981039],
              [-4.21863904357014, 50.483399900171683],
              [-4.218645533596651, 50.478452900189005],
              [-4.21916474188358, 50.478072430551926],
              [-4.218572596364828, 50.478069334160622],
              [-4.218752733724531, 50.477461479937553],
              [-4.219330603220631, 50.47722020205893],
              [-4.218574759725016, 50.477302976447405],
              [-4.218531792830178, 50.476836990395732],
              [-4.219247619522453, 50.475388741309665],
              [-4.229618611775631, 50.471411902115129],
              [-4.235763907446568, 50.468408562003205],
              [-4.235726923320414, 50.468819415505394],
              [-4.239360658071092, 50.46519584492156],
              [-4.241340793683592, 50.464933746882487],
              [-4.242625525831493, 50.465461238539916],
              [-4.243210731004408, 50.466362882746132],
              [-4.244740576135076, 50.466560027890907],
              [-4.245568240515712, 50.467134982772102],
              [-4.247085979676044, 50.466986947079782],
              [-4.248107757174182, 50.466424843014934],
              [-4.250321773959218, 50.466517846645523],
              [-4.248096626164418, 50.466309030721888],
              [-4.246941438922657, 50.466910588780202],
              [-4.245584291671056, 50.467027639900273],
              [-4.244822243651741, 50.466469407579147],
              [-4.24332226468683, 50.466277983589194],
              [-4.242753310557681, 50.465392216453907],
              [-4.241310782583895, 50.464805705701991],
              [-4.239584602326272, 50.464954981534419],
              [-4.239733217919475, 50.46344826186678],
              [-4.240420529672209, 50.462576962572768],
              [-4.24002509926616, 50.462647539022207],
              [-4.239381806067389, 50.460066848824326],
              [-4.237828506005606, 50.457756408276637],
              [-4.234780682603875, 50.455866826820007],
              [-4.233831110989305, 50.456136010705471],
              [-4.230659935578938, 50.455092363175474],
              [-4.228336131394969, 50.45537885516147],
              [-4.223891621251083, 50.458841300983025],
              [-4.224463125248009, 50.461081659640833],
              [-4.222506937132282, 50.462964694423043],
              [-4.221312996969471, 50.463315794793687],
              [-4.219970543127812, 50.46284313696232],
              [-4.219651522855125, 50.463252171812584],
              [-4.220148381684243, 50.462999842067653],
              [-4.221033835055182, 50.463612992112608],
              [-4.21762119478314, 50.46446016240386],
              [-4.215203928050429, 50.461190908478912],
              [-4.212929419840353, 50.458859697123465],
              [-4.211568849607526, 50.456212455628197],
              [-4.211320451290856, 50.453998273197485],
              [-4.212530770384152, 50.452914822349626],
              [-4.211959129064379, 50.452680149325815],
              [-4.211311254705844, 50.452834579361394],
              [-4.210732580570813, 50.452448928992574],
              [-4.210818741095188, 50.450187910412829],
              [-4.209681103672976, 50.449505271668265],
              [-4.209296110912846, 50.44877055132492],
              [-4.209730174395836, 50.447535475495457],
              [-4.20961868949719, 50.445807979028793],
              [-4.210370012978128, 50.446061734411188],
              [-4.21128857726001, 50.445672806507858],
              [-4.209119537542871, 50.445403720717742],
              [-4.206965345707502, 50.443758176475605],
              [-4.204918919329784, 50.443367061210942],
              [-4.203030260732192, 50.442551086577716],
              [-4.203351045627702, 50.442179838977914],
              [-4.205598093381818, 50.44161647352805],
              [-4.205826426501101, 50.440681226994023],
              [-4.200608247116671, 50.43807008156157],
              [-4.199625868886871, 50.436990455063203],
              [-4.199264805185451, 50.435702995522561],
              [-4.201429505192221, 50.43446309092662],
              [-4.201986282657355, 50.433382220794392],
              [-4.203067113334966, 50.432609821833132],
              [-4.202561326488976, 50.431421352621655],
              [-4.203725713131161, 50.430811961397673],
              [-4.203720299078785, 50.429998073483716],
              [-4.205558122514841, 50.429246380990442],
              [-4.206482033934828, 50.429584132474183],
              [-4.214152491419244, 50.429748649042146],
              [-4.216493108203779, 50.428671505441166],
              [-4.217118523578582, 50.429098509511576],
              [-4.216463479784217, 50.429785575180631],
              [-4.217132650897736, 50.429732344443202],
              [-4.217482658267167, 50.429538587681073],
              [-4.217057040790261, 50.42947025042087],
              [-4.217887862498449, 50.429109923063415],
              [-4.217038172242388, 50.428493819678927],
              [-4.218158615160567, 50.428666732261817],
              [-4.218470916546518, 50.429232818070638],
              [-4.218026824791306, 50.429311445322774],
              [-4.218750895134196, 50.42925805754453],
              [-4.218670986448521, 50.428723516521138],
              [-4.217963402485386, 50.428376339111651],
              [-4.218765801935841, 50.428462669105031],
              [-4.218236295829662, 50.428159765849408],
              [-4.21717660645224, 50.428292404420283],
              [-4.217982505539876, 50.428001808164154],
              [-4.217406586204991, 50.427579261572632],
              [-4.218097250573762, 50.427835921700165],
              [-4.219319928031335, 50.42727472950277],
              [-4.220251323054272, 50.427530380187527],
              [-4.218591155806066, 50.426955842922013],
              [-4.219753951343439, 50.426078286231125],
              [-4.220978211611603, 50.425943380214477],
              [-4.222152675687731, 50.426158395431052],
              [-4.223297070255259, 50.427294100578074],
              [-4.225364126040143, 50.428316801333921],
              [-4.22487061438834, 50.428842522650733],
              [-4.225509170522435, 50.428677399293598],
              [-4.224804972296465, 50.429214347616977],
              [-4.225590627078597, 50.42885303001853],
              [-4.225945689655564, 50.430148622884943],
              [-4.225606615810171, 50.429884381313897],
              [-4.225383138878102, 50.43013330559976],
              [-4.224961236703466, 50.430024451316399],
              [-4.224827774179221, 50.429610564232178],
              [-4.224675881406163, 50.429764575910276],
              [-4.225858111525996, 50.431223330533513],
              [-4.225587723365321, 50.431492940678062],
              [-4.225051384726827, 50.431226176473785],
              [-4.22551148182714, 50.431998985216858],
              [-4.224775491479247, 50.432039151336788],
              [-4.225786361723483, 50.432995699835175],
              [-4.224950834126997, 50.433375961093518],
              [-4.225809166096361, 50.433811954121694],
              [-4.226324285547475, 50.435606368604063],
              [-4.227514709469166, 50.436155616702912],
              [-4.230073054847096, 50.436174943905847],
              [-4.232509677867935, 50.436959276245041],
              [-4.23298515573963, 50.437785726929718],
              [-4.232520294823957, 50.438292041572751],
              [-4.232912988426964, 50.438646077129036],
              [-4.236731031460684, 50.438735506836053],
              [-4.23435241745294, 50.438169610985739],
              [-4.233370585328297, 50.437596639019091],
              [-4.234227776967999, 50.436480159668946],
              [-4.23279597577213, 50.436309781223073],
              [-4.230619371044948, 50.435334284007766],
              [-4.228758474811698, 50.4354715988717],
              [-4.228214178913625, 50.435756357736508],
              [-4.226872649070799, 50.435138055191118],
              [-4.226901062184829, 50.434933338455359],
              [-4.22797213671754, 50.435130488149632],
              [-4.227060437076781, 50.433138603620371],
              [-4.226810632771557, 50.42997646065858],
              [-4.228221235772832, 50.42964003378308],
              [-4.228097925770986, 50.428273448743646],
              [-4.227729394485528, 50.428101520586601],
              [-4.228080581989723, 50.428083999184722],
              [-4.224395289684954, 50.427023054649702],
              [-4.224020346614172, 50.426473472753159],
              [-4.224410790635738, 50.425761743379198],
              [-4.223814887366868, 50.425424157842237],
              [-4.22958757106598, 50.425245263877265],
              [-4.228418909014334, 50.424824240624858],
              [-4.2258009091908, 50.424870782221774],
              [-4.224546186034662, 50.424294864892765],
              [-4.223328514956544, 50.424479152068251],
              [-4.222719988676761, 50.424262327301975],
              [-4.222810906386332, 50.423197451275421],
              [-4.223816475845349, 50.422002656927134],
              [-4.221640516494792, 50.423398805935818],
              [-4.220904211594073, 50.423399379030279],
              [-4.221864274185378, 50.424058317558163],
              [-4.221686476866944, 50.424891897538743],
              [-4.2224663535587, 50.425340206832658],
              [-4.218795420806368, 50.425091005773844],
              [-4.218005685424822, 50.425997415730322],
              [-4.217399472450164, 50.425769725790353],
              [-4.215889446722903, 50.426056648265877],
              [-4.215818788132871, 50.426262167292734],
              [-4.216593060284322, 50.426108897795309],
              [-4.215413068056617, 50.426589198146743],
              [-4.215049285737533, 50.426246246240161],
              [-4.212869344077668, 50.426147436438022],
              [-4.214977074527803, 50.426358252660663],
              [-4.215022496883732, 50.426607431480569],
              [-4.214067765676945, 50.426816293817964],
              [-4.212370450311664, 50.426227984485109],
              [-4.211356839154564, 50.426291336234875],
              [-4.209767842381109, 50.42536723768486],
              [-4.211214051626187, 50.424042756647523],
              [-4.211344842804769, 50.423465528192885],
              [-4.210528628395079, 50.42220384357789],
              [-4.211421682835064, 50.421549161432999],
              [-4.21069314356007, 50.419327907647371],
              [-4.210991851639057, 50.418154756123862],
              [-4.211681186881533, 50.417449978058926],
              [-4.211428662744579, 50.416743324002205],
              [-4.212593839161597, 50.416885756485534],
              [-4.215591285937501, 50.415145829996106],
              [-4.212518717255604, 50.416814331245433],
              [-4.211584836918885, 50.416620728313028],
              [-4.21166361561272, 50.414716015182755],
              [-4.212288919740447, 50.41484353333577],
              [-4.2123748674524, 50.414029703669257],
              [-4.210517935123756, 50.413479484036486],
              [-4.209157427779227, 50.411980789137552],
              [-4.207566090052314, 50.410843538847764],
              [-4.207105371905382, 50.410863977560709],
              [-4.206929463673753, 50.409865340197832],
              [-4.206370705976631, 50.409926312729205],
              [-4.206211893988737, 50.409295220862198],
              [-4.206711404812221, 50.408837820892181],
              [-4.205533704538413, 50.407815016175391],
              [-4.206435417974228, 50.407260043389265],
              [-4.206238102525171, 50.40679967624655],
              [-4.208787580295843, 50.405270793669047],
              [-4.213431048358649, 50.404800228065291],
              [-4.212716561191074, 50.404266067441043],
              [-4.21047865182378, 50.404230383152012],
              [-4.210059230149903, 50.403927149476687],
              [-4.210185039750642, 50.402336344579716],
              [-4.211959720985603, 50.400102561349918],
              [-4.211954144242274, 50.399287772961706],
              [-4.215264265240298, 50.398844287764106],
              [-4.218059201839452, 50.397343797983154],
              [-4.221437358868851, 50.397516755316147],
              [-4.22357171570548, 50.39657921023511],
              [-4.224427910344828, 50.396503469064406],
              [-4.225446967981684, 50.396269899925024],
              [-4.225815988190443, 50.395166413453047],
              [-4.225097193940311, 50.394810500638386],
              [-4.22656439011766, 50.393481814996164],
              [-4.227748936295096, 50.393358385718777],
              [-4.229828710309049, 50.393692684840438],
              [-4.230192469676282, 50.394218179007296],
              [-4.229540091987277, 50.395337907686155],
              [-4.230454527155546, 50.396203623042737],
              [-4.22873824470433, 50.397231321467508],
              [-4.229570753055131, 50.397340382573518],
              [-4.230117080856599, 50.396958434057488],
              [-4.232308677410693, 50.397213192471057],
              [-4.232992456803633, 50.398528541588249],
              [-4.232685857103867, 50.398921190834372],
              [-4.233192190961785, 50.400651891520191],
              [-4.235592603031006, 50.40154208308315],
              [-4.2335278839516, 50.400069798864671],
              [-4.234597920087237, 50.397868084844667],
              [-4.23389731307939, 50.397691765372691],
              [-4.232187502624008, 50.396131688069701],
              [-4.23311186640786, 50.395441152490775],
              [-4.231976117284562, 50.394627377240234],
              [-4.232780145831313, 50.393652231071584],
              [-4.233408840618358, 50.394006229725584],
              [-4.23404617740678, 50.393616217688574],
              [-4.233438436194668, 50.393707944811453],
              [-4.233352851992385, 50.393503615910497],
              [-4.236552419115036, 50.393414226615498],
              [-4.239925372172306, 50.392555980133601],
              [-4.240318778625797, 50.392126559690965],
              [-4.24207568777779, 50.391491860931296],
              [-4.242344854127783, 50.390666374421649],
              [-4.241470266243036, 50.389812575280807],
              [-4.243712089309724, 50.389199981419019],
              [-4.247188073745855, 50.390678095726109],
              [-4.248188529717726, 50.391453864953107],
              [-4.24929869520797, 50.39169323146254],
              [-4.249015830375403, 50.392078270497038],
              [-4.24873139247195, 50.391776161530601],
              [-4.248598551045428, 50.392120520855478],
              [-4.24904200636874, 50.392393470116694],
              [-4.247882803597792, 50.393021216763017],
              [-4.247161792088253, 50.393900422999963],
              [-4.248903439178316, 50.39362749372426],
              [-4.249997250884325, 50.39310443892267],
              [-4.252533422700584, 50.39353563381237],
              [-4.25109956315131, 50.392345553809598],
              [-4.251576946444977, 50.392265253122787],
              [-4.251850976008471, 50.391605147895604],
              [-4.250634681657243, 50.391739332095561],
              [-4.249390374390853, 50.390776719662583],
              [-4.247731852972308, 50.390603722943254],
              [-4.248230582357259, 50.390320647545131],
              [-4.248868005972933, 50.390471119229254],
              [-4.249386333421044, 50.389978088429736],
              [-4.252015364445822, 50.389552122144337],
              [-4.252789467255145, 50.389024439181462],
              [-4.252696564362309, 50.388311178093467],
              [-4.252113093819013, 50.388138118043308],
              [-4.251455642251272, 50.389022394850578],
              [-4.250055928188552, 50.388936162900919],
              [-4.249732667799325, 50.389240136036449],
              [-4.249229863259668, 50.388961142095567],
              [-4.249508525642643, 50.388725491887499],
              [-4.245937164560487, 50.387579386851385],
              [-4.245185865183577, 50.387072219029307],
              [-4.248381383353215, 50.38503347551854],
              [-4.250212316278701, 50.384603192845098],
              [-4.25326826171188, 50.384375805115091],
              [-4.253877494788158, 50.383992522824478],
              [-4.253973770785368, 50.38341231055454],
              [-4.255672604474847, 50.383579937543971],
              [-4.257756001198456, 50.385031681000463],
              [-4.260048062641622, 50.385484540256186],
              [-4.26197556752567, 50.386257456728941],
              [-4.263180363324601, 50.386151259433518],
              [-4.263200729536383, 50.386372127576401],
              [-4.26625821853262, 50.387208417816503],
              [-4.269531087393299, 50.386660667038726],
              [-4.270789191860358, 50.386757525367123],
              [-4.271049257507658, 50.387194974912816],
              [-4.27185139493775, 50.386788041408515],
              [-4.271789695554633, 50.386409678951082],
              [-4.271190601364005, 50.386502335124263],
              [-4.271330004407528, 50.385651429665621],
              [-4.272483164747562, 50.385701749485115],
              [-4.272628343454324, 50.385972344563811],
              [-4.273020152018439, 50.385222637548459],
              [-4.272456618831688, 50.384764141113521],
              [-4.271730090502687, 50.385103941788557],
              [-4.272485637303835, 50.384900290417427],
              [-4.27271242112054, 50.385082942905534],
              [-4.271163467031453, 50.385522464033393],
              [-4.270492572483342, 50.384936534667332],
              [-4.270606923027714, 50.381352687621202],
              [-4.270279949987013, 50.38057385268209],
              [-4.270601290599819, 50.379526911491801],
              [-4.271426343454576, 50.379013396819779],
              [-4.271655096657003, 50.377707420313193],
              [-4.27330616933737, 50.376790982892885],
              [-4.274386520020653, 50.3767644540616],
              [-4.275147107616462, 50.377168715380115],
              [-4.276291831356367, 50.377017683998652],
              [-4.277813789045124, 50.378722016263183],
              [-4.28267374650104, 50.381596679915205],
              [-4.283655367353513, 50.383084883370501],
              [-4.283570545024947, 50.383690981621889],
              [-4.284132077784115, 50.384076608461356],
              [-4.283564379605991, 50.385202182567276],
              [-4.282128697714681, 50.385654916106105],
              [-4.281524695966272, 50.387139174008837],
              [-4.279868543880903, 50.387777894738718],
              [-4.280021065989697, 50.387995269152327],
              [-4.280670985057812, 50.38799421392045],
              [-4.28126153221999, 50.387576974578359],
              [-4.281592511122041, 50.388053484875606],
              [-4.282310300458609, 50.387678715462037],
              [-4.281477231352508, 50.387527769166233],
              [-4.282012204279405, 50.386187879505847],
              [-4.282433721494956, 50.385708291748102],
              [-4.282877996524736, 50.385995488079821],
              [-4.284008820683416, 50.385287894051615],
              [-4.284953165897464, 50.385530178022648],
              [-4.285040421045949, 50.385326086225028],
              [-4.284083729144249, 50.38514880612265],
              [-4.284756020154229, 50.384472698961964],
              [-4.285893854905971, 50.385345286365641],
              [-4.285234160502752, 50.385815177860948],
              [-4.286128109029705, 50.385477396758617],
              [-4.287304847673168, 50.386075771914776],
              [-4.287206250981226, 50.386716323012337],
              [-4.287703771668868, 50.386559922900126],
              [-4.288712193690747, 50.387605925605399],
              [-4.287726658605044, 50.388525675387491],
              [-4.285513098501156, 50.389128672668853],
              [-4.28325344664869, 50.389681263874557],
              [-4.283202700170964, 50.389327876587842],
              [-4.282614427642701, 50.389177527094439],
              [-4.283505166240625, 50.39011795061819],
              [-4.282822572780571, 50.390288759993751],
              [-4.281893062651478, 50.391294605541226],
              [-4.284022253193221, 50.390104195130213],
              [-4.283754482552547, 50.389684914366299],
              [-4.287153002720182, 50.388912933584798],
              [-4.286284486286082, 50.389751216638693],
              [-4.287226569462485, 50.389213702162188],
              [-4.286851606581145, 50.39001709358152],
              [-4.287707452608383, 50.39081515888148],
              [-4.287867215833719, 50.391766333420655],
              [-4.288100163575958, 50.390964834580863],
              [-4.28776862507316, 50.390595388293974],
              [-4.288514213142909, 50.390272201807129],
              [-4.287656131261214, 50.390479773737901],
              [-4.287429888733178, 50.390047091748933],
              [-4.288570420408655, 50.389803380246477],
              [-4.287577840579615, 50.38984720061481],
              [-4.287571733258234, 50.389194318572571],
              [-4.287858592712556, 50.388899050093841],
              [-4.288362007327629, 50.389185961311533],
              [-4.287992310739166, 50.388695840669747],
              [-4.289088198242758, 50.387993382242819],
              [-4.290288072260269, 50.390499910036624],
              [-4.289591303009868, 50.390492947019034],
              [-4.289575771613914, 50.391163344748314],
              [-4.289744497343728, 50.390577176746532],
              [-4.290109387851966, 50.390820936651529],
              [-4.289821257578749, 50.39252028029982],
              [-4.287751227976787, 50.392279504517546],
              [-4.288669135468306, 50.392612224435901],
              [-4.288953210858609, 50.393105827549881],
              [-4.286858985483198, 50.394584363859821],
              [-4.283124863664123, 50.395906192109429],
              [-4.282650368440651, 50.396866228468561],
              [-4.280616220916633, 50.398607008540168],
              [-4.278398433366548, 50.3979012493498],
              [-4.276593062658374, 50.397725244162821],
              [-4.280761616910105, 50.398880287562264],
              [-4.279852071461661, 50.40071681415656],
              [-4.28052425059631, 50.402934266608632],
              [-4.280336184071238, 50.404205279482099],
              [-4.279944333668392, 50.404720253421878],
              [-4.279777850474572, 50.404477068828712],
              [-4.278765863711943, 50.404592248882253],
              [-4.279599656626683, 50.404605586122649],
              [-4.279914982803692, 50.405017647195159],
              [-4.27790633302756, 50.407357807255558],
              [-4.27682226242294, 50.407379940030239],
              [-4.277203595234722, 50.407965208704944],
              [-4.277172459826748, 50.407433344989364],
              [-4.277801776351963, 50.407760110230782],
              [-4.277692748608846, 50.411120786100504],
              [-4.278523280453528, 50.412618285000207],
              [-4.278388526912877, 50.413887348189462],
              [-4.275756460449097, 50.414831144553702],
              [-4.274333058948937, 50.416236047953547],
              [-4.272694306068838, 50.416790667994185],
              [-4.272682899173564, 50.418816444769341],
              [-4.27176272816664, 50.419709587007837],
              [-4.273105320476347, 50.420141167506138],
              [-4.273117743234407, 50.421017884826526],
              [-4.275254018773243, 50.422292894215744],
              [-4.27579613767843, 50.424496722284751],
              [-4.275127567417462, 50.425440731463837],
              [-4.275029625733106, 50.426481507597757],
              [-4.27599413025836, 50.428390146269031],
              [-4.277459018877162, 50.429750210315689],
              [-4.27605010210736, 50.428147999334321],
              [-4.275258914856353, 50.426477021553389],
              [-4.275291217412324, 50.425623714842388],
              [-4.275978264437475, 50.424800768334222],
              [-4.275405967767612, 50.422202674706227],
              [-4.273259864044799, 50.420781250482719],
              [-4.273572575872169, 50.420314620139479],
              [-4.272859892752138, 50.419455190111165],
              [-4.273319663100359, 50.418208563910525],
              [-4.272773243936013, 50.416998696206697],
              [-4.274599051735435, 50.416352270344888],
              [-4.276157331195856, 50.415030170994072],
              [-4.278789651012769, 50.414003611429187],
              [-4.279300981569393, 50.412809016219853],
              [-4.278548084960499, 50.411873955390817],
              [-4.279333219737572, 50.412190463411008],
              [-4.278312752713832, 50.410783038943137],
              [-4.278699566351855, 50.409839133222256],
              [-4.279052292866982, 50.410326017181305],
              [-4.279941734126267, 50.410235724686999],
              [-4.279212254519229, 50.410169076019464],
              [-4.278852118814341, 50.409469168466288],
              [-4.280008437428291, 50.409690250361969],
              [-4.278995463084265, 50.409317949946896],
              [-4.278634205306497, 50.408125165867816],
              [-4.279725351974883, 50.408513925609974],
              [-4.280145311518686, 50.407850892065973],
              [-4.279625172372199, 50.407570569157329],
              [-4.279713237249664, 50.408202953891177],
              [-4.278675746286388, 50.407817639779168],
              [-4.279128676708702, 50.407049627667128],
              [-4.27979582722607, 50.407284796223131],
              [-4.280341959281479, 50.406934094898261],
              [-4.278992941663458, 50.406860705657913],
              [-4.279833816259089, 50.406317143757398],
              [-4.280506616418737, 50.406464950880654],
              [-4.280570857120368, 50.406161475969853],
              [-4.280095629106933, 50.406142013954273],
              [-4.281289510916772, 50.404949311359928],
              [-4.281465886568226, 50.403347528915866],
              [-4.280826008614278, 50.401364204467612],
              [-4.282087635731237, 50.398713953192811],
              [-4.282978938647948, 50.398227841206783],
              [-4.28292808639676, 50.397726046947803],
              [-4.286072409776467, 50.395899540149635],
              [-4.288558711869094, 50.394818054727715],
              [-4.290345505380253, 50.394814349283756],
              [-4.291563123205401, 50.394124761305413],
              [-4.291695617573957, 50.392146053419516],
              [-4.292216746598272, 50.391953190900828],
              [-4.292372353670656, 50.390949931691019],
              [-4.29321234416379, 50.391067384103231],
              [-4.292492403117285, 50.390667834761075],
              [-4.293485920334187, 50.389040017721776],
              [-4.299293043096036, 50.390627975513482],
              [-4.300174557347625, 50.391573852405259],
              [-4.299831264919853, 50.390963619027652],
              [-4.300383671311985, 50.391039936838148],
              [-4.301691373024926, 50.392708624232753],
              [-4.301539700733692, 50.39399244834496],
              [-4.300927810698902, 50.394722323573809],
              [-4.29904795821991, 50.394605706495184],
              [-4.300694543661233, 50.394932015246006],
              [-4.299846145480638, 50.395689050877458],
              [-4.29930797169945, 50.397041678893622],
              [-4.298035071443477, 50.396558655424926],
              [-4.297201340069211, 50.397358554921169],
              [-4.298397181034405, 50.396830327629978],
              [-4.298925828350857, 50.396937703794144],
              [-4.300679722805723, 50.399594171436235],
              [-4.297744153467752, 50.399387785740323],
              [-4.296828516564037, 50.39995004639168],
              [-4.298199259123833, 50.399632435986227],
              [-4.301059937433191, 50.399744950578054],
              [-4.303085382837527, 50.400160868392952],
              [-4.304357570484735, 50.401061197320978],
              [-4.305253449572537, 50.400760110044857],
              [-4.305482562095333, 50.401015508889621],
              [-4.30487974446326, 50.40233886273942],
              [-4.302315364608418, 50.404364867851776],
              [-4.304766316850196, 50.406758320779431],
              [-4.304787240834935, 50.40721842534505],
              [-4.30389813873661, 50.407168587524687],
              [-4.304348126049972, 50.406812481716187],
              [-4.303250562082013, 50.40681713960911],
              [-4.30364458673239, 50.407106152534681],
              [-4.302912032977282, 50.406975852561231],
              [-4.301902032004217, 50.407509439686585],
              [-4.302678042689136, 50.407113605722365],
              [-4.303551490336551, 50.407188947660352],
              [-4.304893050209557, 50.407455582040285],
              [-4.305661423737831, 50.408236362397567],
              [-4.307456921511418, 50.408256509544842],
              [-4.308275883445075, 50.409034469999455],
              [-4.307367867430019, 50.410073370675846],
              [-4.307960448817112, 50.411058202151459],
              [-4.309043277748716, 50.411298443890097],
              [-4.309701190086668, 50.410992155331911],
              [-4.309949576072648, 50.411266051077135],
              [-4.310021189093154, 50.410973205924797],
              [-4.30899721195995, 50.410698524889888],
              [-4.309132264225436, 50.411131177556875],
              [-4.3082136674261, 50.410997409590379],
              [-4.30759876733199, 50.410422272863372],
              [-4.308480075481727, 50.410599959646746],
              [-4.309989610291424, 50.410381993914683],
              [-4.31175918176787, 50.411400082599904],
              [-4.311303711204722, 50.412568531034474],
              [-4.313072544083149, 50.413424713411871],
              [-4.313445995481289, 50.414155733713741],
              [-4.315382707726506, 50.414411304867834],
              [-4.316033313545862, 50.413839787689362],
              [-4.316983999310843, 50.413764181885313],
              [-4.318864918189562, 50.414462438010098],
              [-4.318895755493807, 50.41541074537141],
              [-4.319984177565812, 50.415877436453755],
              [-4.321815735585571, 50.415477499090173],
              [-4.322479051964883, 50.414016132730048],
              [-4.325021295830783, 50.412407472399153],
              [-4.326605909025459, 50.412228264842305],
              [-4.324975807164249, 50.412312140465765],
              [-4.3223657590986, 50.413973422953639],
              [-4.321861176521925, 50.41531289130296],
              [-4.320210510528561, 50.415784773996442],
              [-4.319045882823992, 50.41534299069275],
              [-4.318896217980038, 50.414296314527412],
              [-4.317068518157198, 50.413650066106293],
              [-4.316050030787826, 50.413691944528559],
              [-4.315346825046118, 50.41425281636856],
              [-4.313702040504581, 50.414066090580874],
              [-4.313269124008377, 50.413356941539085],
              [-4.31152053999367, 50.412540834929082],
              [-4.312031803377924, 50.411419846615843],
              [-4.310930803070852, 50.410633126935835],
              [-4.309623497119541, 50.410147314372686],
              [-4.30834652955085, 50.41045869871818],
              [-4.307622776554241, 50.410221218625395],
              [-4.308714314940455, 50.408933121627925],
              [-4.307170444590289, 50.407922201656532],
              [-4.305443364979484, 50.407803552770453],
              [-4.305289016156917, 50.406392674802824],
              [-4.30457191787242, 50.405594685541004],
              [-4.303332988175661, 50.405266650787254],
              [-4.302851262280155, 50.404590810698323],
              [-4.303946336747872, 50.403723631023361],
              [-4.304834186336068, 50.403777990187983],
              [-4.304690539892515, 50.404065964045984],
              [-4.305273960235955, 50.403618163450972],
              [-4.304894051985918, 50.403590616797572],
              [-4.305756425911522, 50.403236223093323],
              [-4.306520599602291, 50.402453832712247],
              [-4.306889896598715, 50.401162087357392],
              [-4.306443804171772, 50.398872835476531],
              [-4.305191226089234, 50.398840112560315],
              [-4.304127730715311, 50.398237873176178],
              [-4.304732014969595, 50.398337427889096],
              [-4.304520236783897, 50.398177925442234],
              [-4.302130672699883, 50.397564168290216],
              [-4.301368174675678, 50.396347913360195],
              [-4.30183261827434, 50.395391594931304],
              [-4.302330413649815, 50.395094812526352],
              [-4.302251927853078, 50.395681910704702],
              [-4.303568286707899, 50.392417803912274],
              [-4.302257472661564, 50.390395713552884],
              [-4.303232273529936, 50.38815655287177],
              [-4.30476433299583, 50.388243118760684],
              [-4.306797386687466, 50.390274238888978],
              [-4.308806732350038, 50.39074254314081],
              [-4.308251132638135, 50.391469541421792],
              [-4.309154366219655, 50.390687068215328],
              [-4.311049557740462, 50.390022489392067],
              [-4.313951042199643, 50.390435189575904],
              [-4.315333752203779, 50.390284434628192],
              [-4.316599111474469, 50.389602624555955],
              [-4.318968540542503, 50.390355900770714],
              [-4.320933054696845, 50.389932630045934],
              [-4.322801386962045, 50.38993129368707],
              [-4.322767081658643, 50.389661242791291],
              [-4.32366838528058, 50.389564079152962],
              [-4.323946233995613, 50.389232922047803],
              [-4.325028054276113, 50.38923107931317],
              [-4.324966060512589, 50.388855446838335],
              [-4.323511836812885, 50.389239805982839],
              [-4.320111365707882, 50.389297825341394],
              [-4.319871839162451, 50.389549955726892],
              [-4.320658640225222, 50.389441608466377],
              [-4.320569376720612, 50.389716824907644],
              [-4.319761784435358, 50.390004578110286],
              [-4.318961878894955, 50.389585200091958],
              [-4.319278884286122, 50.390055587863586],
              [-4.317431093159436, 50.389324301234282],
              [-4.316280615572651, 50.389331938446112],
              [-4.315890278108348, 50.388972737089937],
              [-4.313948900720938, 50.38940895388442],
              [-4.314428362887296, 50.389171847475964],
              [-4.313567828038275, 50.389152997776399],
              [-4.313872400635207, 50.38957147899832],
              [-4.315779685583796, 50.389243877313582],
              [-4.314839611725811, 50.389989359482001],
              [-4.312897002324594, 50.389967760260639],
              [-4.313067724976222, 50.389573100674845],
              [-4.312514281978294, 50.389822466469106],
              [-4.311357082770345, 50.389402947482147],
              [-4.309817578111706, 50.390206176835882],
              [-4.308617463400908, 50.390437788922043],
              [-4.307612300308396, 50.390164522979056],
              [-4.306711542233725, 50.389404371000239],
              [-4.307893478815877, 50.38839331222006],
              [-4.308427186255664, 50.388809057323705],
              [-4.308902262919885, 50.388626028199234],
              [-4.308428198241136, 50.388656129939882],
              [-4.308061164114232, 50.388282948101093],
              [-4.308688343774892, 50.387784789126378],
              [-4.308090751432828, 50.387762475871618],
              [-4.306855760721214, 50.389216221764769],
              [-4.305080626342798, 50.387860875865748],
              [-4.305282992678081, 50.387450309798062],
              [-4.304550781981205, 50.387785032737959],
              [-4.30322961067906, 50.387579156068533],
              [-4.302228892874567, 50.388005531187595],
              [-4.302523098853713, 50.387138027535279],
              [-4.302032395833155, 50.387172930059045],
              [-4.301991896520901, 50.387585681968261],
              [-4.301517780739568, 50.387149839390432],
              [-4.301266969832292, 50.387780824458424],
              [-4.302149997240094, 50.387829001662112],
              [-4.301231985609778, 50.3882204504662],
              [-4.301844184450582, 50.388195738203549],
              [-4.301662422163734, 50.388422401517417],
              [-4.300879362626693, 50.388637580926677],
              [-4.295556786348399, 50.387378330756512],
              [-4.296410445258663, 50.386265936975434],
              [-4.296236242360094, 50.384873425934472],
              [-4.296846206859331, 50.383639018429228],
              [-4.297559445504764, 50.383290327481816],
              [-4.299259336621621, 50.383709144319681],
              [-4.30071653002992, 50.383443757828019],
              [-4.302416708954867, 50.382038428858237],
              [-4.303848749376442, 50.382010057421454],
              [-4.30372917610283, 50.381545609927308],
              [-4.302373671834403, 50.38193404501903],
              [-4.302574396234946, 50.381460553980837],
              [-4.305013019073828, 50.380956202367074],
              [-4.304847578086453, 50.378791795192207],
              [-4.306073267665361, 50.37700545298329],
              [-4.305905863238793, 50.37630899710561],
              [-4.307164138881026, 50.375254153119215],
              [-4.307081235883567, 50.37473411386712],
              [-4.306667886055696, 50.375577911648662],
              [-4.304925631579929, 50.377007526504457],
              [-4.304236382504133, 50.378254344517181],
              [-4.304448538461864, 50.380367457009868],
              [-4.30411445261916, 50.380875075805015],
              [-4.302527907239884, 50.381110687367972],
              [-4.301481419985826, 50.382622706151828],
              [-4.299873270045723, 50.383430761649045],
              [-4.297296430635459, 50.383029286939554],
              [-4.296644480063608, 50.382462920236904],
              [-4.296264898133577, 50.382554967304451],
              [-4.296553243487147, 50.383136614821012],
              [-4.296585576749745, 50.382641276363195],
              [-4.297048678223264, 50.383054870079945],
              [-4.29582279188574, 50.384090072459976],
              [-4.295681955566815, 50.386154401388232],
              [-4.295180791334848, 50.386817300043631],
              [-4.293883640334161, 50.386000114496021],
              [-4.293890669947401, 50.385417130033566],
              [-4.292803647605568, 50.385221805692005],
              [-4.291516735758441, 50.384323441316745],
              [-4.286806916558984, 50.38283224740406],
              [-4.284606064172107, 50.380943495511161],
              [-4.284005676120447, 50.379836373730683],
              [-4.279667535490395, 50.377692682820474],
              [-4.279165315255726, 50.376253512106381],
              [-4.27723529463643, 50.374456453031442],
              [-4.275723749188504, 50.373965271113732],
              [-4.274290705771038, 50.373938450297821],
              [-4.272626511762099, 50.374254325198692],
              [-4.270657708970418, 50.375230927592682],
              [-4.268124774291188, 50.375263286632602],
              [-4.268630161425008, 50.373445526477923],
              [-4.270635684193977, 50.373503510314592],
              [-4.272128412148064, 50.373219797916541],
              [-4.272859401958765, 50.372682925684124],
              [-4.273405140351441, 50.372889921406824],
              [-4.273307631043386, 50.372586014475885],
              [-4.274074832416602, 50.372336251122476],
              [-4.276344209965774, 50.372471723765344],
              [-4.279075306482722, 50.371882135731958],
              [-4.27921178160854, 50.371443224982485],
              [-4.279705231672349, 50.37184999567296],
              [-4.281761606500178, 50.371679228025201],
              [-4.282315821468835, 50.371300471256681],
              [-4.278870283877942, 50.37124304604378],
              [-4.280003118756628, 50.369703453555907],
              [-4.278462163440552, 50.37050360045577],
              [-4.277962440843203, 50.371315706880232],
              [-4.273534704830119, 50.371805345820661],
              [-4.271925007812714, 50.372259560456705],
              [-4.272099279078433, 50.372463028368585],
              [-4.271294739828531, 50.372755782407275],
              [-4.270157930690681, 50.372894018277627],
              [-4.270614024720444, 50.372402104340992],
              [-4.272290918861518, 50.372029344098557],
              [-4.26975753392552, 50.372225448923501],
              [-4.269188757232294, 50.372684480548941],
              [-4.26832743693518, 50.372583462846613],
              [-4.269208424365338, 50.372300929997543],
              [-4.267663374058045, 50.372165581592114],
              [-4.266845569518937, 50.371590592316302],
              [-4.266553050684826, 50.370971177142223],
              [-4.267077936454729, 50.369797949813922],
              [-4.266620844614834, 50.369531631215075],
              [-4.265399022773599, 50.371101607324967],
              [-4.26560456240731, 50.371961974766997],
              [-4.267047474175657, 50.373583438099423],
              [-4.266111637613905, 50.374279871830012],
              [-4.266188326529754, 50.37473799657122],
              [-4.262761551221725, 50.376172815183722],
              [-4.26051248067601, 50.376644710208993],
              [-4.257920016844994, 50.376728373029174],
              [-4.257289849033094, 50.376431200365253],
              [-4.258230304661175, 50.3758876558163],
              [-4.257625470329725, 50.375886811496805],
              [-4.258121925600056, 50.375441834804107],
              [-4.259457105784802, 50.37539160580662],
              [-4.259707806690914, 50.375842754227669],
              [-4.259506604156615, 50.374923828790592],
              [-4.258750288845809, 50.374665093973199],
              [-4.257884509410707, 50.374825825555348],
              [-4.258023463689735, 50.374553291348235],
              [-4.257675324740837, 50.37456724884764],
              [-4.257133854837536, 50.374893470853827],
              [-4.257546453224128, 50.375614913126981],
              [-4.257306378553769, 50.376068400918136],
              [-4.256906334780735, 50.375759561810128],
              [-4.256833604127089, 50.37624217973822],
              [-4.256539051832842, 50.376111181369822],
              [-4.256802982295591, 50.3756860144941],
              [-4.25624755205784, 50.375777746711201],
              [-4.256009874319378, 50.375985635309434],
              [-4.256404982814743, 50.376398010038564],
              [-4.255610763745457, 50.376170571820843],
              [-4.256560333265364, 50.375463164054437],
              [-4.255614742716466, 50.375780133246828],
              [-4.255326618883498, 50.376288516136462],
              [-4.253141035435783, 50.376202276806161],
              [-4.251180388717689, 50.375786939321095],
              [-4.250451482985921, 50.374650656462308],
              [-4.24917057559825, 50.37448026626069],
              [-4.24885289308089, 50.374840795553546],
              [-4.249452087465937, 50.376179276021212],
              [-4.248980251421836, 50.377027591514739],
              [-4.249294367126925, 50.377304840637969],
              [-4.245020924552094, 50.377237240707963],
              [-4.244459013508913, 50.378116059018346],
              [-4.243484966639701, 50.378220308673583],
              [-4.243633463799254, 50.378863246740778],
              [-4.239711870588749, 50.380116244570907],
              [-4.238226705859793, 50.38131592907461],
              [-4.236945836691506, 50.381507879964502],
              [-4.236946923527943, 50.381829861172271],
              [-4.237468227183004, 50.381997020163638],
              [-4.23738078958213, 50.383217453943466],
              [-4.233833627148029, 50.383828033414396],
              [-4.23338474396689, 50.384124480731252],
              [-4.235028743612965, 50.384712604831989],
              [-4.23540575827793, 50.387434276752401],
              [-4.23350790034546, 50.388660720464657],
              [-4.232847284679055, 50.388733672917844],
              [-4.2317775571906, 50.389981056909235],
              [-4.230752472209904, 50.390442358127444],
              [-4.230294551258995, 50.390488020891944],
              [-4.228302130023643, 50.389433399839824],
              [-4.226153336628386, 50.389333352251867],
              [-4.223319881062172, 50.390296012216382],
              [-4.221645259479184, 50.391401062058428],
              [-4.220026454334745, 50.391112682010252],
              [-4.2186543342013, 50.391978955364941],
              [-4.211733810671041, 50.392651380516909],
              [-4.210154943880057, 50.392253270736369],
              [-4.209175668829274, 50.391133188118289],
              [-4.206901222641863, 50.389994516669837],
              [-4.205938056333136, 50.389036900485323],
              [-4.205354862072521, 50.388899556596783],
              [-4.205296657795776, 50.389221761709656],
              [-4.204897329066121, 50.38922663740923],
              [-4.204948811887781, 50.388941437139408],
              [-4.202192425373045, 50.388357775341376],
              [-4.201567350538673, 50.38765005989999],
              [-4.202209858344936, 50.38709462782397],
              [-4.204240214974534, 50.386919435103884],
              [-4.205798689959517, 50.387337798861608],
              [-4.207961890665616, 50.387296735913992],
              [-4.209505467760683, 50.385911049541356],
              [-4.209393403248935, 50.385588480166795],
              [-4.20855691374057, 50.385539614162084],
              [-4.205264575132285, 50.385104720486268],
              [-4.20336264431447, 50.383860874885158],
              [-4.199584934888011, 50.383512371290479],
              [-4.198749440998832, 50.383149510593249],
              [-4.197912704590603, 50.381877330809644],
              [-4.198934502045729, 50.381250884216193],
              [-4.202595032389438, 50.38071387747965],
              [-4.202530833407759, 50.379115883092261],
              [-4.20201768685667, 50.378787413266352],
              [-4.196540483126213, 50.378330678723302],
              [-4.197738966680736, 50.377541701928997],
              [-4.19764476637544, 50.377177409293772],
              [-4.194220683997025, 50.37631116503772],
              [-4.193801164085723, 50.375754232394797],
              [-4.192954588302477, 50.375848456901096],
              [-4.192998623858177, 50.374455288192529],
              [-4.19471257669709, 50.373682700464727],
              [-4.194090848756341, 50.373101703425185],
              [-4.194650281678229, 50.372881572861139],
              [-4.194547316903413, 50.372296179973731],
              [-4.196262014136042, 50.371420118417284],
              [-4.197914386328251, 50.371144239381913],
              [-4.199627066831098, 50.37022679107929],
              [-4.201306948198421, 50.370242662390254],
              [-4.202563865227061, 50.370539056103937],
              [-4.206372192792986, 50.372326888027054],
              [-4.207114676055685, 50.372476492300407],
              [-4.20828878220224, 50.37222933298623],
              [-4.209107349798541, 50.372805615156132],
              [-4.210898458445476, 50.373154727722635],
              [-4.211234347707889, 50.373572877240896],
              [-4.21101111718973, 50.373067137703899],
              [-4.208806045190729, 50.372586479301177],
              [-4.20838022742018, 50.372108869028914],
              [-4.208620599076959, 50.37150077451885],
              [-4.207154002234082, 50.370899917626836],
              [-4.206967553529401, 50.370458232136698],
              [-4.207788923911836, 50.370005504681401],
              [-4.207656127971756, 50.369689623027192],
              [-4.207937764868217, 50.369970297680027],
              [-4.211519397488198, 50.369783486682934],
              [-4.214051962472811, 50.369265766859513],
              [-4.216845838389879, 50.368140379066034],
              [-4.218071322017845, 50.368153879022735],
              [-4.218188343023558, 50.367889007544143],
              [-4.218585871113964, 50.368120673992649],
              [-4.218976218620654, 50.367384671143846],
              [-4.218306572105372, 50.367016986120341],
              [-4.219299199390604, 50.366832540809952],
              [-4.220050132376691, 50.367374959393715],
              [-4.219743850990973, 50.368164226952814],
              [-4.220424709421967, 50.368861786714611],
              [-4.224538431214948, 50.370612611754929],
              [-4.222446383092191, 50.369541810523884],
              [-4.221909156761807, 50.368879293896292],
              [-4.222609184853777, 50.36869231793257],
              [-4.222891368539117, 50.367899905645366],
              [-4.225320489787262, 50.366889220460784],
              [-4.226797139853403, 50.366651370846611],
              [-4.22716759525576, 50.366304282173495],
              [-4.228805522871331, 50.366327754496488],
              [-4.229289093967529, 50.365694264280414],
              [-4.230285835538183, 50.365389119613639],
              [-4.232516856609093, 50.366005587971131],
              [-4.234364546431562, 50.365345868938007],
              [-4.235892952716679, 50.365463090148928],
              [-4.23674860436494, 50.365896353124256],
              [-4.236855525143727, 50.365090187905217],
              [-4.235839467066466, 50.364445042829253],
              [-4.237018637496673, 50.363655125251611],
              [-4.237707110664418, 50.362179368100016],
              [-4.238180115738858, 50.362280893590956],
              [-4.238951561106239, 50.36188197149977],
              [-4.237768388370416, 50.361987505029028],
              [-4.239274295583668, 50.361688668937575],
              [-4.237903716538447, 50.361725858054967],
              [-4.237339724976689, 50.362200831314304],
              [-4.236828001082737, 50.362172902947783],
              [-4.23663200378405, 50.363536642439783],
              [-4.23514965009154, 50.36382959414199],
              [-4.231377739619329, 50.363815715518186],
              [-4.226920185307835, 50.363002650034524],
              [-4.222004613462106, 50.363398034376011],
              [-4.220736462069866, 50.362769269999035],
              [-4.219878412957586, 50.362822537170779],
              [-4.217372308676629, 50.362210176058845],
              [-4.218619224249471, 50.360757160497926],
              [-4.221821377157707, 50.359926968575401],
              [-4.223864091316828, 50.35868893966731],
              [-4.22157461772079, 50.359339847966098],
              [-4.218073359966367, 50.35972511793144],
              [-4.215640792201407, 50.358997087140509],
              [-4.20932085971893, 50.359360233572325],
              [-4.20842139440733, 50.359550020122342],
              [-4.207640684362414, 50.360541649687569],
              [-4.207725617486437, 50.36097806712057],
              [-4.208508887833725, 50.361434497809697],
              [-4.205921901478638, 50.361892869401245],
              [-4.205282851143085, 50.361332047903453],
              [-4.202802071202983, 50.360805240292336],
              [-4.202088112023198, 50.360083021239909],
              [-4.197978977326636, 50.359823502768066],
              [-4.196765783667844, 50.359462373866045],
              [-4.196487900605186, 50.358498922218338],
              [-4.197177165543902, 50.357653907886203],
              [-4.197045316417735, 50.357203978799106],
              [-4.197938073833166, 50.357112446741759],
              [-4.198316932715557, 50.356761693705302],
              [-4.197299529666295, 50.356437342640213],
              [-4.197396456425444, 50.355491991235283],
              [-4.196369532876528, 50.355112945927836],
              [-4.196722589667284, 50.354873317411439],
              [-4.196210936833908, 50.354052797196111],
              [-4.196628207203926, 50.353712117008477],
              [-4.197133896989661, 50.353855465020992],
              [-4.197353508480061, 50.35325318159596],
              [-4.198226638384247, 50.353286141518034],
              [-4.198026438947728, 50.352908562202416],
              [-4.199105846511018, 50.352507678872797],
              [-4.2004765223584, 50.352720990494113],
              [-4.201355489818993, 50.352546044064056],
              [-4.202680586120622, 50.351410122571203],
              [-4.203270452571587, 50.350426639779592],
              [-4.203155936487099, 50.349410635765658],
              [-4.203906502892123, 50.348801871127229],
              [-4.205455316077653, 50.348857040611122],
              [-4.205780171034355, 50.349951800354539],
              [-4.206500347430881, 50.350567744343842],
              [-4.208053466673628, 50.351077918509063],
              [-4.208999039883408, 50.350885458442136],
              [-4.208977068195482, 50.350533292327732],
              [-4.209494692588199, 50.350842757722646],
              [-4.210097537342143, 50.350444533494262],
              [-4.209684001942422, 50.349409935426195],
              [-4.206615613547239, 50.34835471266004],
              [-4.208498853171119, 50.347044425124281],
              [-4.206415599352938, 50.347102879963074],
              [-4.205869398774515, 50.347934443820925],
              [-4.205219421779307, 50.348013336962367],
              [-4.204990875865199, 50.347358377493961],
              [-4.203875511091646, 50.34697927768957],
              [-4.200180071598131, 50.348894057943973],
              [-4.197682504382358, 50.349418915730254],
              [-4.197014723323758, 50.349874068228175],
              [-4.195151364884295, 50.349857107175197],
              [-4.193472062599951, 50.350356523012508],
              [-4.193588955055134, 50.350574681381829],
              [-4.192358018512312, 50.350585313752532],
              [-4.192140781875552, 50.351087704345851],
              [-4.191174762601573, 50.351234540447116],
              [-4.190971415490391, 50.351519900369823],
              [-4.192846271784111, 50.352151033707301],
              [-4.193438119824632, 50.352700221310187],
              [-4.193441835636043, 50.353634675720407],
              [-4.19107413950124, 50.354422274764516],
              [-4.189880246544996, 50.355239884055955],
              [-4.186883238855577, 50.355552651495771],
              [-4.186829033124452, 50.35584149374894],
              [-4.187408090909665, 50.355868374684135],
              [-4.186473968754039, 50.356650478232105],
              [-4.184459914495833, 50.35666406153932],
              [-4.18298332301445, 50.357146021725477],
              [-4.180491459373179, 50.360262595857769],
              [-4.178173498825368, 50.360827731935892],
              [-4.178612483395665, 50.360496604890287],
              [-4.178018201327495, 50.360291875289484],
              [-4.177658525905826, 50.360512681661376],
              [-4.177913040308011, 50.360758857941889],
              [-4.177167675768594, 50.360663088968209],
              [-4.177215098802241, 50.361115520145823],
              [-4.174681387303622, 50.360653865233367],
              [-4.174152499679786, 50.359326286946249],
              [-4.171760989848518, 50.358896987350846],
              [-4.171436418352291, 50.358100747967057],
              [-4.172528221036106, 50.35694794778977],
              [-4.172994209570568, 50.355576580424199],
              [-4.171963640960785, 50.354774650198372],
              [-4.171432781545879, 50.354725205354292],
              [-4.17105659229889, 50.353967705530913],
              [-4.168532009222709, 50.353048830852437],
              [-4.167894221936439, 50.352071340365754],
              [-4.16683719341675, 50.351888675757159],
              [-4.166608077988263, 50.351426135646449],
              [-4.167260269053551, 50.350097191409802],
              [-4.167203106126735, 50.349490233335146],
              [-4.16638190027829, 50.348619590336298],
              [-4.166641411078529, 50.346600898192044],
              [-4.168028041072848, 50.345135031155884],
              [-4.171062545772042, 50.343714829562813],
              [-4.171270605258864, 50.34331518711889],
              [-4.17011117815391, 50.342978862067689],
              [-4.173664828128973, 50.343482703908535],
              [-4.176684555988619, 50.343159054148906],
              [-4.179216268200025, 50.342246345356664],
              [-4.180317734146264, 50.342144737464928],
              [-4.180643918055898, 50.341441547126095],
              [-4.185951343408089, 50.341266292124487],
              [-4.186556006109115, 50.340814188943767],
              [-4.188077246454677, 50.340887196788664],
              [-4.189726980308366, 50.340056517601603],
              [-4.189646883736581, 50.339690152639328],
              [-4.190243967406249, 50.339868685381333],
              [-4.190912353439687, 50.339642018991917],
              [-4.192537446468633, 50.338247809813595],
              [-4.195846920455899, 50.336568120201676],
              [-4.195951936735802, 50.336072338670633],
              [-4.196393914242529, 50.336179116135234],
              [-4.198636938231719, 50.334660708260301],
              [-4.199771236174871, 50.334301050868717],
              [-4.201196696959173, 50.33294467443438],
              [-4.200240385527541, 50.331894237318473],
              [-4.201279633100164, 50.331243143416955],
              [-4.200844820919896, 50.329557714241936],
              [-4.199690276174535, 50.329202703591008],
              [-4.199332097660718, 50.328414368812837],
              [-4.197075039820871, 50.327459571767072],
              [-4.197377834743234, 50.327353108427204],
              [-4.194551282335921, 50.326325380725635],
              [-4.1923916863515, 50.325316484902622],
              [-4.192343889651209, 50.325010674247054],
              [-4.191862970410607, 50.324940594336709],
              [-4.192186735523147, 50.325320351246127],
              [-4.191771795284757, 50.325310188883634],
              [-4.191395337497255, 50.324731746651139],
              [-4.190355752141625, 50.324306118021646],
              [-4.191002835101447, 50.324262439121064],
              [-4.189610180476598, 50.323735526090438],
              [-4.190235492806166, 50.323554645812024],
              [-4.189372319962827, 50.323298377815505],
              [-4.188926590976845, 50.322801283738691],
              [-4.18967955702573, 50.322922015820041],
              [-4.188798518076429, 50.322337781122194],
              [-4.190123135811982, 50.322088861303428],
              [-4.188858825293821, 50.321571213456167],
              [-4.189997217484097, 50.320969620253102],
              [-4.189162909932961, 50.320759578296105],
              [-4.189527807283515, 50.320445991184656],
              [-4.188677201465383, 50.319911551419253],
              [-4.18963441786493, 50.319586806459199],
              [-4.188549593340216, 50.319366189678362],
              [-4.188729044122069, 50.318930174377087],
              [-4.189439502923483, 50.318738699646467],
              [-4.188795873955246, 50.318336177935592],
              [-4.189191181923535, 50.318134450160848],
              [-4.18842632158366, 50.318058911789016],
              [-4.188340332883269, 50.317685460369042],
              [-4.191731294477004, 50.318018211045],
              [-4.191332942682664, 50.317542718639537],
              [-4.194093140044414, 50.318576272665823],
              [-4.193522036252541, 50.318071669046937],
              [-4.194113288107626, 50.317915695320004],
              [-4.195687071762995, 50.318351885085058],
              [-4.196656389039861, 50.318047540441398],
              [-4.197426050230851, 50.318561866092416],
              [-4.197465167242756, 50.318252614627127],
              [-4.197874989632263, 50.318366289997954],
              [-4.19821744334658, 50.318085479768619],
              [-4.200265981451937, 50.318093481577641],
              [-4.202621743282361, 50.318548048596703],
              [-4.20430547880045, 50.318261576974791],
              [-4.205138609976367, 50.318476029946886],
              [-4.204885301174913, 50.318102167216473],
              [-4.206460653461552, 50.318600244027984],
              [-4.210563211084872, 50.318339684747883],
              [-4.210522296633653, 50.318063431630264],
              [-4.213374016568278, 50.317489265341173],
              [-4.21326549950222, 50.317270066788247],
              [-4.214750630858907, 50.316581572110756],
              [-4.215653070453006, 50.316624632119286],
              [-4.21476504764648, 50.316226912698255],
              [-4.216174008006315, 50.315895135341542],
              [-4.216670445459796, 50.315363983247927],
              [-4.218875825662461, 50.315152795133166],
              [-4.218607798879744, 50.314947440561255],
              [-4.219185233792309, 50.314618007563176],
              [-4.221280933136976, 50.314646324043537],
              [-4.221422109852203, 50.313998716436402],
              [-4.220994741399901, 50.313298115578867],
              [-4.220378696040895, 50.313190261285463],
              [-4.220848978823501, 50.313033763532168],
              [-4.220111263050615, 50.312876964270423],
              [-4.22064662409338, 50.312579808459716],
              [-4.22026484945599, 50.312314569047096],
              [-4.22106151965035, 50.312320031523711],
              [-4.222705224599962, 50.311363062699336],
              [-4.22423587855796, 50.311596414416798],
              [-4.224742448562384, 50.312518552206363],
              [-4.226224757567525, 50.312888621272641],
              [-4.225840112401356, 50.313374499994715],
              [-4.226808763022804, 50.313693236904768],
              [-4.226266038820667, 50.313832258215655],
              [-4.226711113561459, 50.314068382025006],
              [-4.225432293194474, 50.314326738821052],
              [-4.225687396069519, 50.314705920144156],
              [-4.224646135317212, 50.314727658419692],
              [-4.223684659599813, 50.315134626048469],
              [-4.223222072829516, 50.316178749449122],
              [-4.224011389235706, 50.316476655459752],
              [-4.223212203451181, 50.316569301458564],
              [-4.223432681426204, 50.31707957089013],
              [-4.224286978620132, 50.317144172503546],
              [-4.225797314488536, 50.318171212330434],
              [-4.226413012933016, 50.318059578012836],
              [-4.226010369897771, 50.318281361930353],
              [-4.226583466386432, 50.318220012791585],
              [-4.226268034529352, 50.318474305777215],
              [-4.226781082265556, 50.318480665722291],
              [-4.227257902767112, 50.319064268089598],
              [-4.226198540551481, 50.31954238997745],
              [-4.226259624107952, 50.319887509636068],
              [-4.225307244211891, 50.320160296937736],
              [-4.224614877868763, 50.31992440476526],
              [-4.225071931735534, 50.320265541731686],
              [-4.224652840484732, 50.32049663020635],
              [-4.223101136735774, 50.321173033027634],
              [-4.222413647701694, 50.320951425696727],
              [-4.222667439662846, 50.321211910542829],
              [-4.221863705722682, 50.321118446815916],
              [-4.222421939160546, 50.321460357871366],
              [-4.222085345593763, 50.321683562952153],
              [-4.219682715895945, 50.322461635643805],
              [-4.220404004555077, 50.323016310460886],
              [-4.220022744406296, 50.323276340956255],
              [-4.220420720468539, 50.323314609505843],
              [-4.220240856001197, 50.323645447004452],
              [-4.219453673011231, 50.323727043525281],
              [-4.219371643073059, 50.323986753166494],
              [-4.219874520027961, 50.324316241813072],
              [-4.219535901364912, 50.324647412245703],
              [-4.22021175997179, 50.325010473033032],
              [-4.22031709358237, 50.326064418154061],
              [-4.220979694371557, 50.326323391333283],
              [-4.220711175133066, 50.32659116308082],
              [-4.221338779340805, 50.327214181903727],
              [-4.222366007954453, 50.327277291863368],
              [-4.221854111836463, 50.327689135359734],
              [-4.222192761994619, 50.328201644398476],
              [-4.223129537801414, 50.328644247473591],
              [-4.223440847853668, 50.328510569426093],
              [-4.223260453910379, 50.329070781920855],
              [-4.225797893960194, 50.330449642118666],
              [-4.225839796590559, 50.331165703389061],
              [-4.227642801803229, 50.332430865824172],
              [-4.228178589291663, 50.332439482953433],
              [-4.228956516173709, 50.333448142497097],
              [-4.232550166145611, 50.33510611229184],
              [-4.234756367995302, 50.336810447510672],
              [-4.241374823285438, 50.339959701527874],
              [-4.24483546812677, 50.342145140941213],
              [-4.248061114289171, 50.342859920352694],
              [-4.248595531445665, 50.343640201761538],
              [-4.249729434936581, 50.343781059358015],
              [-4.250597941722065, 50.344521584445907],
              [-4.25171618454041, 50.34430114559909],
              [-4.252044345969663, 50.344671658482945],
              [-4.25273325541277, 50.344616033183435],
              [-4.252415578768282, 50.345001759763583],
              [-4.253044311278032, 50.345604799669701],
              [-4.254555610295337, 50.34581834531847],
              [-4.254887510950243, 50.346356076190744],
              [-4.256192166942637, 50.346924396728397],
              [-4.258851270572875, 50.347212740659067],
              [-4.260314002301404, 50.346816426099515],
              [-4.259651572683891, 50.347459824570535],
              [-4.260414613027815, 50.348372324494996],
              [-4.261648418003167, 50.348275466482832],
              [-4.262209439863658, 50.348692688490424],
              [-4.263408450468715, 50.348455274662406],
              [-4.263109789530512, 50.3489144156027],
              [-4.263957746391461, 50.34927567236042],
              [-4.263854709168323, 50.349650953021275],
              [-4.266609283168348, 50.350050592144527],
              [-4.267100419770267, 50.350828935587508],
              [-4.268018417767501, 50.350800232264618],
              [-4.269628307006549, 50.352044233474949],
              [-4.270495185182189, 50.352181109207358],
              [-4.270317069550334, 50.352543471052243],
              [-4.277096918825371, 50.354826778448697],
              [-4.27706169431896, 50.355118892085102],
              [-4.279740957249513, 50.355962229629],
              [-4.280254470758542, 50.355852316900105],
              [-4.282674068024745, 50.35684909140479],
              [-4.286632275662481, 50.357210223052071],
              [-4.288613055398044, 50.35752742280112],
              [-4.288662393867216, 50.357763907797057],
              [-4.290139747505565, 50.357757294950972],
              [-4.29177426359256, 50.358765749550102],
              [-4.296496012958976, 50.358774192579503],
              [-4.296980279201943, 50.358903139921402],
              [-4.297348743813894, 50.359603730550845],
              [-4.298454519299019, 50.359529701022367],
              [-4.299139897023025, 50.359892119090766],
              [-4.300256011661512, 50.35950845455298],
              [-4.30128729865138, 50.360161734397465],
              [-4.303868146278753, 50.360670969921195],
              [-4.30570914921322, 50.360390711536553],
              [-4.308087572479035, 50.361392274765407],
              [-4.314670716102123, 50.360956447914702],
              [-4.31499847278049, 50.361074945288102],
              [-4.314667623547805, 50.36129740445385],
              [-4.316375161920121, 50.362001843913042],
              [-4.317397676747983, 50.36197876373361],
              [-4.319992669080866, 50.361129176654565],
              [-4.320812286937546, 50.36158772899671],
              [-4.322851098337408, 50.36173139167235],
              [-4.323626809847305, 50.36120409420213],
              [-4.323430238471098, 50.361468867123357],
              [-4.324369865027173, 50.361821557718635],
              [-4.32639631632196, 50.361970802223048],
              [-4.327367375438475, 50.361413485171795],
              [-4.327246109509028, 50.36169384652603],
              [-4.327809286108891, 50.361881349845305],
              [-4.329791383503129, 50.361813753990184],
              [-4.332154916338738, 50.362327618486574],
              [-4.332963106568381, 50.362034365782719],
              [-4.333477662215651, 50.361344943603662],
              [-4.336006087336473, 50.360348821361733],
              [-4.336671098762817, 50.359660857828992],
              [-4.338116133203776, 50.35951037091295],
              [-4.344948710279203, 50.360836150309623],
              [-4.351527833240458, 50.361179946689809],
              [-4.35199748507516, 50.36155001754797],
              [-4.355424055493159, 50.361775652890088],
              [-4.357785082336461, 50.361439054873735],
              [-4.358488664873187, 50.360967852545507],
              [-4.358339691431362, 50.360742411424944],
              [-4.359041031865553, 50.360935055770206],
              [-4.361978168833948, 50.360110860912847],
              [-4.36487908861102, 50.360059069041419],
              [-4.3677575425399, 50.36026221105692],
              [-4.380142295989326, 50.362542154564032],
              [-4.387390970449292, 50.364448774967023],
              [-4.386100112316238, 50.364813481474727],
              [-4.386938075535566, 50.365707436320584],
              [-4.386550640904042, 50.36493645349487],
              [-4.392916807477181, 50.363237792448786],
              [-4.401605727767486, 50.363609936800508],
              [-4.410332522736656, 50.364136296268036],
              [-4.414729345025533, 50.362801065829665],
              [-4.416221101425671, 50.362797947765657],
              [-4.418107835568542, 50.362128180859919],
              [-4.422196441583893, 50.361761511706675],
              [-4.427231740799044, 50.361844456481805],
              [-4.43011140990994, 50.361485658540929],
              [-4.432207624904001, 50.360875143351535],
              [-4.434149195870305, 50.360815608125684],
              [-4.43447003544741, 50.360518358355598],
              [-4.435315190774033, 50.361042135846546],
              [-4.435200590009525, 50.360616387601837],
              [-4.434720180027711, 50.360625552775716],
              [-4.435350013351981, 50.3604315630812],
              [-4.43551366804285, 50.361183691682108],
              [-4.436470655789033, 50.361062893185974],
              [-4.437929504616219, 50.359431238331382],
              [-4.438209540097011, 50.359792349450522],
              [-4.439529915494883, 50.359465117870108],
              [-4.441445428978641, 50.358625263196153],
              [-4.441789923061149, 50.35805045759588],
              [-4.442396273041672, 50.358057505463776],
              [-4.442427134574048, 50.357672781148857],
              [-4.442961837166613, 50.357763183891421],
              [-4.446379141753408, 50.354879543118301],
              [-4.448383755325599, 50.354192405795722],
              [-4.449255119210608, 50.352957953887703],
              [-4.450059333842318, 50.353095712761565],
              [-4.451771861547853, 50.35266292870336],
              [-4.452539258002339, 50.351568264368126],
              [-4.451093941074039, 50.350910655883681],
              [-4.452646687044805, 50.351306947897207],
              [-4.454630415706821, 50.352753701428327],
              [-4.455664273563065, 50.358102637229848],
              [-4.456932370645697, 50.360060082136556],
              [-4.456568551275907, 50.362217520670818],
              [-4.457512915109837, 50.364873497933338],
              [-4.457398117090147, 50.366570537431933],
              [-4.459375214706717, 50.369640905596043],
              [-4.460995762509682, 50.373018315386553],
              [-4.462923417617898, 50.374391461247605],
              [-4.463853260350031, 50.374530062746253],
              [-4.46266600810951, 50.374173845339541],
              [-4.462744444731667, 50.373896044556091],
              [-4.463845476628133, 50.374244194545497],
              [-4.464367757851515, 50.374009153174526],
              [-4.465256519087558, 50.375416874965993],
              [-4.465591124865548, 50.376742800460981],
              [-4.46519979876267, 50.376682740352393],
              [-4.464754648043111, 50.379083884721808],
              [-4.465950006050568, 50.380949228499539],
              [-4.463917930022552, 50.381828830815166],
              [-4.463336455374624, 50.383148990306154],
              [-4.464485887332957, 50.384941564720627],
              [-4.464692715959444, 50.386433904502184],
              [-4.465084765565704, 50.386723317307286],
              [-4.464842526631338, 50.387477715747551],
              [-4.4668488952866, 50.389455960270759],
              [-4.466802630466439, 50.39087122878648],
              [-4.467224685806729, 50.389732532817959],
              [-4.465022975797874, 50.387457698891168],
              [-4.465271433612047, 50.386552056522049],
              [-4.464860481131141, 50.386359288884414],
              [-4.464915493407868, 50.385575579915574],
              [-4.463539722017592, 50.383053835734593],
              [-4.464130250248292, 50.381827028594685],
              [-4.46611011942507, 50.381052869232576],
              [-4.466015020130487, 50.380358693187823],
              [-4.46496932141162, 50.379344677681367],
              [-4.464957042010012, 50.378673929727228],
              [-4.465261230900534, 50.377270593784104],
              [-4.465792958601829, 50.377379844983622],
              [-4.465382338701735, 50.37711061700216],
              [-4.466222102204278, 50.376406502976863],
              [-4.465719192923333, 50.375903571698124],
              [-4.466630531965169, 50.375820373224961],
              [-4.466681233663128, 50.375496385530553],
              [-4.465623221874598, 50.374297345144122],
              [-4.462135019595952, 50.372665880216381],
              [-4.460064695498978, 50.368017152741139],
              [-4.460579542218003, 50.367367626831012],
              [-4.459057007187365, 50.366288996036282],
              [-4.459301275705805, 50.365247631501809],
              [-4.458254208074991, 50.362402252048796],
              [-4.459289518623414, 50.361023040065724],
              [-4.459618586966583, 50.359637178850392],
              [-4.459352490707053, 50.35914090180038],
              [-4.45966698942961, 50.359295252801218],
              [-4.462027200689348, 50.358741572147345],
              [-4.464236006700402, 50.358957408241622],
              [-4.467164951881668, 50.360803961949237],
              [-4.467708055534696, 50.362843240717154],
              [-4.468999205953226, 50.363580375875308],
              [-4.477368997137087, 50.363234072979935],
              [-4.479516627328768, 50.362864463896287],
              [-4.481883278555222, 50.364752327986324],
              [-4.483145118438465, 50.364734370959951],
              [-4.483343102905937, 50.364944216966897],
              [-4.483595799629725, 50.364532252927695],
              [-4.484401952957237, 50.364405287374929],
              [-4.485719391689294, 50.365478081846312],
              [-4.484859231365717, 50.367531995802082],
              [-4.485064963393937, 50.367755165469582],
              [-4.485744891045129, 50.367687556897955],
              [-4.485123979470346, 50.368184754066846],
              [-4.486412186686432, 50.369018904509872],
              [-4.487333399975083, 50.369073850929631],
              [-4.487118449440803, 50.369212473057047],
              [-4.48766655931204, 50.369044233853572],
              [-4.486533400124936, 50.368916469267241],
              [-4.485292556905318, 50.368235118305343],
              [-4.485870086406803, 50.367607524132872],
              [-4.485076838697013, 50.367632582426019],
              [-4.484999871913192, 50.367397665037608],
              [-4.485912488050465, 50.365486544713534],
              [-4.484215856887589, 50.364180797371354],
              [-4.482155011240992, 50.364516258545777],
              [-4.480391731054536, 50.362765737860663],
              [-4.479244640656205, 50.362367457335125],
              [-4.469373146495736, 50.362657663000604],
              [-4.468908210860548, 50.362260976332408],
              [-4.469080234706231, 50.361240912209965],
              [-4.468510753459335, 50.36002162374961],
              [-4.466234517176439, 50.359049039742629],
              [-4.464985216871255, 50.358085217993803],
              [-4.463902090469505, 50.35780416166974],
              [-4.461595924297474, 50.35823260844392],
              [-4.458234971344679, 50.357731677120711],
              [-4.457639823133842, 50.358349611429091],
              [-4.457206848778402, 50.358296702318498],
              [-4.456539287526574, 50.357621814936593],
              [-4.456800665648459, 50.356775279284768],
              [-4.455872455016194, 50.353093549297085],
              [-4.45381947331798, 50.351231821025742],
              [-4.451194433777615, 50.350432713133031],
              [-4.451065742021551, 50.349735634292308],
              [-4.450560885850021, 50.349486331971391],
              [-4.450815725831124, 50.347692797645955],
              [-4.452404340295406, 50.344914079640219],
              [-4.457583012249043, 50.343914196950855],
              [-4.458431944009823, 50.343369146680459],
              [-4.458632207769107, 50.342375483298575],
              [-4.460080265876759, 50.341376099262497],
              [-4.462900494052307, 50.341553825536394],
              [-4.464891821431345, 50.341141019591717],
              [-4.466352454742996, 50.340604521557509],
              [-4.466690965020407, 50.340166485755645],
              [-4.466251819377816, 50.340154218860434],
              [-4.466709765726915, 50.339851268622887],
              [-4.46692947389128, 50.340239677613731],
              [-4.467863679017836, 50.34031248864985],
              [-4.470520576672458, 50.339510365738526],
              [-4.47088955339244, 50.339064473771408],
              [-4.473031255989624, 50.33857367252488],
              [-4.473074618112399, 50.337974594726354],
              [-4.473426461719705, 50.338065149836908],
              [-4.472953851776031, 50.337516630374346],
              [-4.473308796063001, 50.337477594529489],
              [-4.473092484941561, 50.336938911515546],
              [-4.471855185171035, 50.336089142330842],
              [-4.472172334194985, 50.335890806156392],
              [-4.471923151317205, 50.335638855379322],
              [-4.471026749071174, 50.33556167574968],
              [-4.471917610480096, 50.335153253281796],
              [-4.471780993583483, 50.334741498378968],
              [-4.471187911437087, 50.334686651190651],
              [-4.471887081182073, 50.334375851212414],
              [-4.471476345260258, 50.334070668588701],
              [-4.472397575312054, 50.334085252106625],
              [-4.47217829156783, 50.333894729950686],
              [-4.472632058075722, 50.33367549616387],
              [-4.472299228727223, 50.33338215089411],
              [-4.473007587872248, 50.333086439616601],
              [-4.47655501420121, 50.333069356212796],
              [-4.476434462121238, 50.333399336936864],
              [-4.477724251284892, 50.333394329804655],
              [-4.478403879545597, 50.333843069994565],
              [-4.479424348360101, 50.33349658757831],
              [-4.479115527384235, 50.333853974667953],
              [-4.480224854105861, 50.333864483011098],
              [-4.481673306219951, 50.334449704151162],
              [-4.483234683051265, 50.334235552433093],
              [-4.483507495010781, 50.334427608457602],
              [-4.482984969872141, 50.334405493842993],
              [-4.483604037979049, 50.334554171165131],
              [-4.483657928869677, 50.334913712021574],
              [-4.484636316950553, 50.334999835028455],
              [-4.485589011305157, 50.335724232940748],
              [-4.487788723912646, 50.33532726165469],
              [-4.488081218185947, 50.335545870194515],
              [-4.488330798825205, 50.335239199118291],
              [-4.491567750080113, 50.335382117766002],
              [-4.491525424725379, 50.335647473527089],
              [-4.492517979207565, 50.335762010051937],
              [-4.492182480434231, 50.335957197977784],
              [-4.492457362762816, 50.336697873990687],
              [-4.493427467066165, 50.337080930622236],
              [-4.494251464534474, 50.3372233552289],
              [-4.496543347797287, 50.33681524489463],
              [-4.497032866037761, 50.337332724368146],
              [-4.497590297869062, 50.33737651288024],
              [-4.498941389000989, 50.336127764895082],
              [-4.499072498932077, 50.335332499230077],
              [-4.499617724489501, 50.335224527433446],
              [-4.499302713872921, 50.334658331219458],
              [-4.499909128794932, 50.33475232552275],
              [-4.499253355817587, 50.333450484931312],
              [-4.498824990218095, 50.33345519993599],
              [-4.499492688334601, 50.332896651205274],
              [-4.499133101621014, 50.332658825901326],
              [-4.499636176978676, 50.332310698230401],
              [-4.500359479945095, 50.332383286351423],
              [-4.499670975175424, 50.332170529447687],
              [-4.501175716604543, 50.331656925488936],
              [-4.501110774904935, 50.331249956861186],
              [-4.50200383602788, 50.330782787121443],
              [-4.501706028560766, 50.330677662873789],
              [-4.502843984076524, 50.330833055001726],
              [-4.502529887231086, 50.330524999904419],
              [-4.503831111536328, 50.330551837445206],
              [-4.503206084898919, 50.33066424710583],
              [-4.503771469492411, 50.330940801052115],
              [-4.506801405295147, 50.330439224148037],
              [-4.507328893761683, 50.330609539548412],
              [-4.507014499764892, 50.330803416241231],
              [-4.507487487376641, 50.330819295632473],
              [-4.507333469192115, 50.331230086468935],
              [-4.508212584234879, 50.330552689621456],
              [-4.510226428977876, 50.33050290598301],
              [-4.511647268994373, 50.329946902684931],
              [-4.512468914111839, 50.330312321353126],
              [-4.512247746797885, 50.330035563774828],
              [-4.512728846274046, 50.329885740739904],
              [-4.512717019263396, 50.330167535999472],
              [-4.513278702312693, 50.33010681631152],
              [-4.51305939228953, 50.330344526798619],
              [-4.513726879296493, 50.33037146539121],
              [-4.513277131786416, 50.330529609301735],
              [-4.514074112796863, 50.330533956283972],
              [-4.513839973540688, 50.330836752172864],
              [-4.515363188855948, 50.330889240398342],
              [-4.516638954946258, 50.331550624354129],
              [-4.518270313367557, 50.331362368146578],
              [-4.517056051257477, 50.331226769500155],
              [-4.516817772344903, 50.330945893679313],
              [-4.516053234321967, 50.331156740010542],
              [-4.516680175779789, 50.330655640901647],
              [-4.514759319006753, 50.330098169031146],
              [-4.514818685895643, 50.329838731284056],
              [-4.516629915169291, 50.329864280779596],
              [-4.516489627325231, 50.32941667568543],
              [-4.517485243807254, 50.328952561337161],
              [-4.519138401183723, 50.328646886480463],
              [-4.518955647028003, 50.328353117817983],
              [-4.51994816872031, 50.327990691250008],
              [-4.521042435867146, 50.328276378842766],
              [-4.52105430865017, 50.327996380308882],
              [-4.522610635628753, 50.327614500907075],
              [-4.522523469504146, 50.327348345578486],
              [-4.523520642541611, 50.327313201106577],
              [-4.523465062483923, 50.327616636453904],
              [-4.524589285120066, 50.327459991035745],
              [-4.52467404782011, 50.32789260258361],
              [-4.52559990147612, 50.327731262443059],
              [-4.525447858297037, 50.327301912807037],
              [-4.527337139506975, 50.326787703507193],
              [-4.527092896967339, 50.326474596431154],
              [-4.527680043428863, 50.326074145230898],
              [-4.530350526534337, 50.325481724153725],
              [-4.5309030623107, 50.325038834511709],
              [-4.531968349349455, 50.324885199692737],
              [-4.53189998551886, 50.325211405391933],
              [-4.532818132589241, 50.324880171341945],
              [-4.532734880223499, 50.325190511288064],
              [-4.533748189809628, 50.325037093602496],
              [-4.533773991122689, 50.324729804680814],
              [-4.535350623550252, 50.324812346865968],
              [-4.535411005712533, 50.324442238066723],
              [-4.536303217371136, 50.324521709965182],
              [-4.536371837237881, 50.324200892787196],
              [-4.536798585337803, 50.324483911102483],
              [-4.53664286762789, 50.324224659350527],
              [-4.538875521182971, 50.323725250882852],
              [-4.538677409320908, 50.324331339278075],
              [-4.539878603541729, 50.324144978864254],
              [-4.539477907691048, 50.324614276660377],
              [-4.543104081827176, 50.32413649248587],
              [-4.543814111649282, 50.324303546738399],
              [-4.544081117738125, 50.325014595906318],
              [-4.544883578057839, 50.324991626055713],
              [-4.544911286969387, 50.324695086291435],
              [-4.546839213701618, 50.32477787324995],
              [-4.546515567682977, 50.324269557766407],
              [-4.54749524353833, 50.324460362867661],
              [-4.54727108758876, 50.324078495500167],
              [-4.548880075143676, 50.324345440118812],
              [-4.550277530161214, 50.324072812495551],
              [-4.551469103508854, 50.324337188933747],
              [-4.551209297190098, 50.324968940776088],
              [-4.551947644690753, 50.324719758049518],
              [-4.552218924441434, 50.32493147687277],
              [-4.551510043718326, 50.325704418537256],
              [-4.553549378764801, 50.325557975671217],
              [-4.553801558672882, 50.325753919729848],
              [-4.553495877431288, 50.325870376032043],
              [-4.553928438399328, 50.325997592071325],
              [-4.554477221040366, 50.325567264208267],
              [-4.555545110060335, 50.325594153571828],
              [-4.555764172698622, 50.326167709482931],
              [-4.556997074520978, 50.326048835916176],
              [-4.556455026369622, 50.326264048706911],
              [-4.557993684988331, 50.326448762232978],
              [-4.558966674597279, 50.327794567992626],
              [-4.5595105150056, 50.327612585344284],
              [-4.560081460563093, 50.327830277580418],
              [-4.560152102593646, 50.327497707461141],
              [-4.560791413787294, 50.327366685291018],
              [-4.561010798270592, 50.327840380754338],
              [-4.56196721806402, 50.328170998657427],
              [-4.563619069872178, 50.327924983144833],
              [-4.566387460425251, 50.328607752276653],
              [-4.567283702363119, 50.328472818228491],
              [-4.567680512914867, 50.328665536890639],
              [-4.567473197136505, 50.328912082002994],
              [-4.568093382551704, 50.328686995321704],
              [-4.570057608737434, 50.329282207256632],
              [-4.569849723715504, 50.329544060658208],
              [-4.571469799828048, 50.329776268082547],
              [-4.57187792821934, 50.330386087692098],
              [-4.572810700246228, 50.330094689937432],
              [-4.572671069658608, 50.3306572674149],
              [-4.574039335454094, 50.330415583442836],
              [-4.573406207824483, 50.331025072856406],
              [-4.57433739642912, 50.33054840195885],
              [-4.575914709710521, 50.331054042190218],
              [-4.575450556867083, 50.331435822451368],
              [-4.575933012494415, 50.33191175487184],
              [-4.577255924287678, 50.331402988978681],
              [-4.577352151140675, 50.331623029966465],
              [-4.578253490133887, 50.331633616679014],
              [-4.577699800835992, 50.332152316964837],
              [-4.5784202494367, 50.332559095181537],
              [-4.579974782993394, 50.332175585203792],
              [-4.580286598780305, 50.331809762574721],
              [-4.58225630933999, 50.331568116756422],
              [-4.58156770310577, 50.331334257439067],
              [-4.582525500103751, 50.331038627407708],
              [-4.582959204504328, 50.331055072601828],
              [-4.58252796600615, 50.331291331009325],
              [-4.583339071042232, 50.331270602319925],
              [-4.583151454739924, 50.332086118891723],
              [-4.583672828185898, 50.331879336431101],
              [-4.584022767253116, 50.332268232669144],
              [-4.584431267858665, 50.332053162902618],
              [-4.585059240426724, 50.332256871964532],
              [-4.58648621534453, 50.33185452420458],
              [-4.587421062565592, 50.332093665543624],
              [-4.588823157453688, 50.330588160149858],
              [-4.589847433053882, 50.33074073667251],
              [-4.589562362467642, 50.330356708468031],
              [-4.590545660508321, 50.330453523726135],
              [-4.590274132428955, 50.330060200324013],
              [-4.591004524607285, 50.329408870503769],
              [-4.592220806951609, 50.329371847763127],
              [-4.591671115581545, 50.329108863547084],
              [-4.592175128709504, 50.329049047668853],
              [-4.591861663745873, 50.328788889590072],
              [-4.592281333725045, 50.328754341532559],
              [-4.592390807025232, 50.328339029660413],
              [-4.593073633668181, 50.32836337093282],
              [-4.592869322536289, 50.328224010711118],
              [-4.593760027789405, 50.327885708693827],
              [-4.595001041352324, 50.327993823154529],
              [-4.594504568362614, 50.327469708774736],
              [-4.595375126840459, 50.327148934484853],
              [-4.595716453441842, 50.32730142047086],
              [-4.595564843594946, 50.326865852139356],
              [-4.596062728580224, 50.326951874803861],
              [-4.596158214475701, 50.326589941947567],
              [-4.59699531767774, 50.32678981297915],
              [-4.597145361733855, 50.325393135816455],
              [-4.596616340902645, 50.325328502173825],
              [-4.597085673021654, 50.32478820781833],
              [-4.596715478291637, 50.32464716506113],
              [-4.598384759212666, 50.323901940649499],
              [-4.598528681449504, 50.324119099245429],
              [-4.599193350531126, 50.323940523406471],
              [-4.600013892306587, 50.324172225467009],
              [-4.600584407151613, 50.323890507097559],
              [-4.601115939090901, 50.324027026335614],
              [-4.600925736308211, 50.324248963094234],
              [-4.601804934028991, 50.32434531411549],
              [-4.601737691395231, 50.324811861066763],
              [-4.602260314108709, 50.324553694357952],
              [-4.602395608896959, 50.325898114309844],
              [-4.601844607461531, 50.326022891574929],
              [-4.601128795928198, 50.326937518119863],
              [-4.60334201262328, 50.327916977776923],
              [-4.603099596156115, 50.328571847404611],
              [-4.604291534212953, 50.328809590651481],
              [-4.606233595983541, 50.328683291847867],
              [-4.606607213595328, 50.328962750734654],
              [-4.606841225385342, 50.32848886148949],
              [-4.608184918676438, 50.328854487031165],
              [-4.60876549037553, 50.328294557471928],
              [-4.609455507588623, 50.328757596987579],
              [-4.609831728863832, 50.328340774146433],
              [-4.610404033537074, 50.328322520964122],
              [-4.610305716463629, 50.328041387204017],
              [-4.610757772531493, 50.328341558208159],
              [-4.611797305185532, 50.327952099246708],
              [-4.612166393584365, 50.328225345764707],
              [-4.612637023857141, 50.327634586504892],
              [-4.613405890206087, 50.327562425928008],
              [-4.613224324926562, 50.327096070782098],
              [-4.614205328315991, 50.326358000385461],
              [-4.61408777212601, 50.325880311792005],
              [-4.614969824192608, 50.32592522770338],
              [-4.614934522107215, 50.325026521122972],
              [-4.617289391371958, 50.32425929461624],
              [-4.618480449624486, 50.324226161864566],
              [-4.61836630343883, 50.323887823120707],
              [-4.620028839428436, 50.323996963732561],
              [-4.620073818561607, 50.324227121444487],
              [-4.620392797833535, 50.324049022555137],
              [-4.620368518147749, 50.324475934060921],
              [-4.620885259746198, 50.324163845269233],
              [-4.623744594791408, 50.324649806627441],
              [-4.624435412011001, 50.324054925030246],
              [-4.628311293374183, 50.324298329344813],
              [-4.62918773555272, 50.324827196683458],
              [-4.63063212370144, 50.324267394073765],
              [-4.633045305386975, 50.32435665203802],
              [-4.632963380394203, 50.324628359678897],
              [-4.633827627682738, 50.324783275196999],
              [-4.633880062128595, 50.325604232887947],
              [-4.634495856683242, 50.325077554655394],
              [-4.634921132616797, 50.325348556154907],
              [-4.635149811524031, 50.32480906466143],
              [-4.636355508294786, 50.3249894984625],
              [-4.636328607634072, 50.325315729285784],
              [-4.635352060150241, 50.325543869614357],
              [-4.636005030273276, 50.325587525016573],
              [-4.63591129698377, 50.325848708648849],
              [-4.636731889184585, 50.326204282983291],
              [-4.637541715373133, 50.325934041197982],
              [-4.638566181863603, 50.326215712607883],
              [-4.638959329994359, 50.326717702163172],
              [-4.638210909823022, 50.327081906915176],
              [-4.639204151000555, 50.327027867320382],
              [-4.639602163615923, 50.327845470180328],
              [-4.639683333823866, 50.328346448891743],
              [-4.638874033165838, 50.32847457177354],
              [-4.639112149106019, 50.328917116464488],
              [-4.63833534345798, 50.328972537115767],
              [-4.638481059356038, 50.329877727030635],
              [-4.637695587290664, 50.329878470356917],
              [-4.636141675744382, 50.329070901063098],
              [-4.635030935909809, 50.32916086048246],
              [-4.634871000989468, 50.329597148513372],
              [-4.634690346751615, 50.329405153367723],
              [-4.633276728619051, 50.329761020298768],
              [-4.632577559711836, 50.330483872867589],
              [-4.632040328531156, 50.330501440741408],
              [-4.630265543908973, 50.332460268994581],
              [-4.630884476905428, 50.331454105477164],
              [-4.629660139685453, 50.332439790793529],
              [-4.628200778612705, 50.332453020339443],
              [-4.625866100965641, 50.333131858254475],
              [-4.623223335314037, 50.333142978585109],
              [-4.623258120482582, 50.333799728646916],
              [-4.622406007170471, 50.334301095619409],
              [-4.617285757848144, 50.334136796262392],
              [-4.61599976651315, 50.334697360367777],
              [-4.615482590276184, 50.335643583175958],
              [-4.613869941161914, 50.336091841940629],
              [-4.611873573385791, 50.337180152327981],
              [-4.610295456435799, 50.33733704636262],
              [-4.610107678098781, 50.337116389724812],
              [-4.609094504472603, 50.337657255013063],
              [-4.608792329388407, 50.338298186157914],
              [-4.60910695949594, 50.338551077112584],
              [-4.609125431991382, 50.338170174376145],
              [-4.610332520572999, 50.337423465149421],
              [-4.611411042992614, 50.337741041486666],
              [-4.613316299073115, 50.337501217502435],
              [-4.617093104544774, 50.335438214894218],
              [-4.622440343866311, 50.335791693167671],
              [-4.623951686826412, 50.335419361522185],
              [-4.625930031950591, 50.334443685689372],
              [-4.629666044480268, 50.334530999437746],
              [-4.629603400540765, 50.3382050825509],
              [-4.630234060132917, 50.339774830881183],
              [-4.631352077634582, 50.340752462724055],
              [-4.631407805892947, 50.34137905269106],
              [-4.628765485368718, 50.3435185048383],
              [-4.629091451056489, 50.343923101623787],
              [-4.628858563205648, 50.344898032132704],
              [-4.629834993716963, 50.345855494533772],
              [-4.629247818913316, 50.345837298965094],
              [-4.628292381242746, 50.346583010585782],
              [-4.62718628817254, 50.346408333606071],
              [-4.627822456687372, 50.346855392815407],
              [-4.627381085562582, 50.347924984030158],
              [-4.627547664654253, 50.348166781105306],
              [-4.627957625838635, 50.348081053214358],
              [-4.628496464867145, 50.347529166238822],
              [-4.630811979560976, 50.346593458160712],
              [-4.632290745112575, 50.346948554638992],
              [-4.635276323337389, 50.346945650588637],
              [-4.636048220963116, 50.347354506617279],
              [-4.634700653172029, 50.349014063785319],
              [-4.634460109655826, 50.350052144107536],
              [-4.636472886007399, 50.355981878556449],
              [-4.635912839073665, 50.35812999585265],
              [-4.635616481837981, 50.358389476787416],
              [-4.633991728819219, 50.358401127274306],
              [-4.627770243089136, 50.357235898470364],
              [-4.625500447101027, 50.35760922050968],
              [-4.624659854945577, 50.357052529120089],
              [-4.62446746118375, 50.357770176386836],
              [-4.622414987327687, 50.358923791480244],
              [-4.620447834070997, 50.358692917756386],
              [-4.617550288536906, 50.357600563174572],
              [-4.614523076196838, 50.357395260013767],
              [-4.609085360762538, 50.358613953323008],
              [-4.607623095302879, 50.359409545168276],
              [-4.606909668307452, 50.359886991982748],
              [-4.606380835952821, 50.361013328356044],
              [-4.607158282462362, 50.360138670726485],
              [-4.608022957764382, 50.360034717119589],
              [-4.608483690739461, 50.35941092059953],
              [-4.610831766936807, 50.358974109036033],
              [-4.613075687876913, 50.358075451276243],
              [-4.616791656900616, 50.358071893895193],
              [-4.620522306524135, 50.359457610529923],
              [-4.62251137730675, 50.359881380646371],
              [-4.623480713399577, 50.361012658968725],
              [-4.624059516685895, 50.361156104204994],
              [-4.623722701311872, 50.35974070294688],
              [-4.627854118072948, 50.358245038572498],
              [-4.630502525229293, 50.358641168104363],
              [-4.635918418651739, 50.360082683235028],
              [-4.635852770968269, 50.361435220488815],
              [-4.633939453704937, 50.36362660366639],
              [-4.633524164605951, 50.365471891898885],
              [-4.63374927319067, 50.36730176778336],
              [-4.63438191722578, 50.368237299715304],
              [-4.633457498811846, 50.368711596862305],
              [-4.634856161779319, 50.369308645674622],
              [-4.635414229603677, 50.370318722102589],
              [-4.633627295878519, 50.374391676572436],
              [-4.633939716498793, 50.37690768718727],
              [-4.633209537701329, 50.376955714439241],
              [-4.634149833018276, 50.378211693858752],
              [-4.633600173922518, 50.379258571259598],
              [-4.631559591789847, 50.380563185561584],
              [-4.625918461903733, 50.380508169997249],
              [-4.625909370112432, 50.38077642513192],
              [-4.624368209910757, 50.381475060457291],
              [-4.622352184369532, 50.381777817632418],
              [-4.620725511620959, 50.382508915966447],
              [-4.619804977013728, 50.382040342556031],
              [-4.620354532877179, 50.38259283742233],
              [-4.618552470150478, 50.383129070365783],
              [-4.617787975513765, 50.383935147088422],
              [-4.615776027094516, 50.385030151258555],
              [-4.616209579360605, 50.384983514510942],
              [-4.615808531330996, 50.38554393024048],
              [-4.618378101932531, 50.383921855019587],
              [-4.621271212577333, 50.383144247013391],
              [-4.622341477776008, 50.382552524448435],
              [-4.623493607556887, 50.382501339665907],
              [-4.625836223915716, 50.381260207317553],
              [-4.627708279226516, 50.380898578994334],
              [-4.630269445315071, 50.380991764587613],
              [-4.630425064517429, 50.381948005393411],
              [-4.631029535594412, 50.382397564765895],
              [-4.630711309195323, 50.382087245503136],
              [-4.63098391193684, 50.380989086788041],
              [-4.633416792804638, 50.380721710121442],
              [-4.634689108346832, 50.379642264210929],
              [-4.635847349186165, 50.379905641738318],
              [-4.635173529455241, 50.37919502631771],
              [-4.636261773345536, 50.376294654991419],
              [-4.636116829396077, 50.373960147726017],
              [-4.636920127616956, 50.373177351968764],
              [-4.637926774632633, 50.37311942833842],
              [-4.640085432380767, 50.37453209889653],
              [-4.642741437204717, 50.377070388610882],
              [-4.644813652091713, 50.378314033533748],
              [-4.646455724911521, 50.379864271375169],
              [-4.650854031365774, 50.381899453156279],
              [-4.652321310629763, 50.382000904913689],
              [-4.653437749255434, 50.382419775776917],
              [-4.653241494583956, 50.382860512998498],
              [-4.653936658259372, 50.383796312469514],
              [-4.656005389155284, 50.384916608881667],
              [-4.659349743616479, 50.387963202522208],
              [-4.659219514947187, 50.388401539622663],
              [-4.658290583032468, 50.38867194908147],
              [-4.660159313736233, 50.388425909766404],
              [-4.660782703178644, 50.388804722549438],
              [-4.661926121667997, 50.39100660292798],
              [-4.661837030962924, 50.394539177189472],
              [-4.662392579151388, 50.396693346878024],
              [-4.663895630667845, 50.397072680983598],
              [-4.664020183225623, 50.39741163615161],
              [-4.663275936762951, 50.398016962379678],
              [-4.663927654765047, 50.398471567219929],
              [-4.665244620557251, 50.398517836523219],
              [-4.664938589615287, 50.399383874181602],
              [-4.666386172236201, 50.399550367565801],
              [-4.668551451829717, 50.401269095863299],
              [-4.667885002554687, 50.402006690672216],
              [-4.668492474387885, 50.402874256278537],
              [-4.66798225854238, 50.404213627382418],
              [-4.668918854628881, 50.405302109257704],
              [-4.666271703509414, 50.407462282752711],
              [-4.667477753724119, 50.410139405316961],
              [-4.666619738901036, 50.40789505383384],
              [-4.669137957060811, 50.405369038625693],
              [-4.668258812788805, 50.404050767145975],
              [-4.66884510040943, 50.403113523240499],
              [-4.668195650384967, 50.402075118690313],
              [-4.668798392910168, 50.401330889202661],
              [-4.666801269205537, 50.399474282487212],
              [-4.665202522204473, 50.399197023876489],
              [-4.66544801269724, 50.398330574662062],
              [-4.663673391756207, 50.398103202374656],
              [-4.664396770399324, 50.397125959340684],
              [-4.662682466664497, 50.396291827011865],
              [-4.662305750940734, 50.394491563382921],
              [-4.662345177856716, 50.392684466186864],
              [-4.662773190921257, 50.391910987249034],
              [-4.663825528791484, 50.393363853700635],
              [-4.663911218071513, 50.395092526468133],
              [-4.664139007927797, 50.394738300264926],
              [-4.664264286348486, 50.393610155314278],
              [-4.662930073196565, 50.391095145322517],
              [-4.664851841332379, 50.391166238934218],
              [-4.666553882474234, 50.390430992643623],
              [-4.665422826764999, 50.390584663281004],
              [-4.664849695858943, 50.391002579168301],
              [-4.663106543372828, 50.390904905973827],
              [-4.662598069286383, 50.390719564070679],
              [-4.662591258331371, 50.389970436983731],
              [-4.660625775250502, 50.386982330617023],
              [-4.65751145578372, 50.384250735844446],
              [-4.655888766109644, 50.380438866489754],
              [-4.653896556096276, 50.378947127153175],
              [-4.649629873261676, 50.377201397577096],
              [-4.650878319084653, 50.37643354150066],
              [-4.652722843050869, 50.375912923390622],
              [-4.654461619883491, 50.373877233770102],
              [-4.652366153587822, 50.375343584876788],
              [-4.648995793243308, 50.376348733547488],
              [-4.649845234713275, 50.37697700994638],
              [-4.649400243222709, 50.377119380767596],
              [-4.647916702757459, 50.376369724701192],
              [-4.643545715966589, 50.373094303722688],
              [-4.643817805268266, 50.372751702034307],
              [-4.643220214160735, 50.372931701528998],
              [-4.640432141712573, 50.371885993406941],
              [-4.63955265516995, 50.371447226339676],
              [-4.638698771917576, 50.370405207292862],
              [-4.638798114980592, 50.367076604694027],
              [-4.639724591956412, 50.36428510691718],
              [-4.639487139314311, 50.362287314219273],
              [-4.639888713850313, 50.362226022048901],
              [-4.640158004520261, 50.363834506918046],
              [-4.639621979045558, 50.365805792745064],
              [-4.640947843430013, 50.363851641678444],
              [-4.640516337372696, 50.363829963992011],
              [-4.640951259604109, 50.36315355214402],
              [-4.639840644330987, 50.361816042492514],
              [-4.639386304859848, 50.362041342354459],
              [-4.638739281520809, 50.360593448328004],
              [-4.63922040679112, 50.356442123399439],
              [-4.638832070916266, 50.35418150200335],
              [-4.639220492318667, 50.354112414761268],
              [-4.63951604517376, 50.354776729751457],
              [-4.641724950097371, 50.35460240627836],
              [-4.640351852601295, 50.354054333165827],
              [-4.639117725771453, 50.353856591891969],
              [-4.63876953433471, 50.354042599914422],
              [-4.63730923935944, 50.352149019041669],
              [-4.636932363624649, 50.350802923241631],
              [-4.637823714406248, 50.347761269145536],
              [-4.637587161504781, 50.346663849832311],
              [-4.636276209048938, 50.345760815257712],
              [-4.633139846444593, 50.345425351973773],
              [-4.631197546086157, 50.344668791876977],
              [-4.631195868350707, 50.344130028816998],
              [-4.632949413707497, 50.342085321388872],
              [-4.633186235493152, 50.340649747635254],
              [-4.63419008251876, 50.340401220358864],
              [-4.633589632645442, 50.340395941163138],
              [-4.633648381137903, 50.339984436849839],
              [-4.632470274460795, 50.339623447312917],
              [-4.631900055760615, 50.33874135995363],
              [-4.6341643981348, 50.335620049446611],
              [-4.634235101461668, 50.334916834672526],
              [-4.637198055196169, 50.333682069704459],
              [-4.637805601467132, 50.333132175099635],
              [-4.637059762746673, 50.333164396101154],
              [-4.637559053816672, 50.332689819317814],
              [-4.641156388148583, 50.331824637374844],
              [-4.640876981516264, 50.331550343004828],
              [-4.641569033454016, 50.331435666235755],
              [-4.642595379521881, 50.330027091936898],
              [-4.643311952989807, 50.329772423687551],
              [-4.64317627842262, 50.329201626388837],
              [-4.644866501158302, 50.329349361268704],
              [-4.64498790415473, 50.32910642995143],
              [-4.643564271026906, 50.328268108813845],
              [-4.643585260012032, 50.327937513087861],
              [-4.644912683336896, 50.327802960935287],
              [-4.645391321825422, 50.327414272261244],
              [-4.64654383442516, 50.327874658356265],
              [-4.646546678447717, 50.326965193961009],
              [-4.647012076048713, 50.326768394616806],
              [-4.647769340781586, 50.327246767499282],
              [-4.648230953929286, 50.327184075794563],
              [-4.64802950124591, 50.32702315825594],
              [-4.649140823483286, 50.327197515228093],
              [-4.64953950860229, 50.326963548010156],
              [-4.649292172187114, 50.326609385758154],
              [-4.650646498612244, 50.326933801605321],
              [-4.650994117930097, 50.32656516891921],
              [-4.651969403795228, 50.326870332434417],
              [-4.652360163076236, 50.326519600532905],
              [-4.652078889946089, 50.326035790410501],
              [-4.653067612618312, 50.325497800753588],
              [-4.652050223048981, 50.324940846403159],
              [-4.653251479897055, 50.32423339381338],
              [-4.653090094353506, 50.323682083711965],
              [-4.653535414499605, 50.323832026157469],
              [-4.653385683691642, 50.323590779972811],
              [-4.654493950084559, 50.323434136707149],
              [-4.654360452522477, 50.323206912963656],
              [-4.654819402760739, 50.323374529230378],
              [-4.654731962844796, 50.32306619738565],
              [-4.654970504688708, 50.323262236163536],
              [-4.656505889632072, 50.322979776232593],
              [-4.656210508732744, 50.323376915565362],
              [-4.65691893918452, 50.323130443586024],
              [-4.657731212316838, 50.323360129634949],
              [-4.657786982639791, 50.323050322947203],
              [-4.65911824788815, 50.323188969330083],
              [-4.659650254232391, 50.322928528081462],
              [-4.659429176819863, 50.322592675389608],
              [-4.661477492080818, 50.322413544325677],
              [-4.661979188982459, 50.321988277096104],
              [-4.661883607962948, 50.322314289784003],
              [-4.662227768771344, 50.3221121105503],
              [-4.662301970296431, 50.322383860654178],
              [-4.662421248991421, 50.322029419818406],
              [-4.662710137035164, 50.32227106359386],
              [-4.663383350358083, 50.322124304747746],
              [-4.663873596712174, 50.321670507402288],
              [-4.664553177327013, 50.321913982232083],
              [-4.664316662136442, 50.322357466667803],
              [-4.66523363470606, 50.323629028307543],
              [-4.665956051877624, 50.323633142797689],
              [-4.666414003844963, 50.323129703307863],
              [-4.667555314188578, 50.323087313661944],
              [-4.667927032974704, 50.322524681287227],
              [-4.667500877881851, 50.322165667803361],
              [-4.66817231967064, 50.321812034748916],
              [-4.668118323514274, 50.321299655312025],
              [-4.667507238807261, 50.321275907777697],
              [-4.666974381336319, 50.320792511482111],
              [-4.667534375620391, 50.320731981490169],
              [-4.668627305028076, 50.321231296483127],
              [-4.669417007543493, 50.320005114413959],
              [-4.669102625332622, 50.318732335873591],
              [-4.668640853744015, 50.31871505876358],
              [-4.668549930574071, 50.318421208710944],
              [-4.66913323927376, 50.317949959051603],
              [-4.668785699879335, 50.317238335519257],
              [-4.669615000139831, 50.317421672168365],
              [-4.668772479184377, 50.316275264694845],
              [-4.670017366784061, 50.316128825685276],
              [-4.669395738466159, 50.315965906880074],
              [-4.670000137913885, 50.315796403078885],
              [-4.670019843299654, 50.315120418440003],
              [-4.671188415070028, 50.315241975406877],
              [-4.670790222535854, 50.314403791750962],
              [-4.671212861250047, 50.314224967111073],
              [-4.671749152211644, 50.314419522483753],
              [-4.672770408511304, 50.313968764655449],
              [-4.673516267701887, 50.314518294496509],
              [-4.675511454499259, 50.314700839042949],
              [-4.675136651439053, 50.314805717595888],
              [-4.67563127675596, 50.315383506524277],
              [-4.675260200588357, 50.315679895307539],
              [-4.676570238394418, 50.317943472912027],
              [-4.678854970184754, 50.318575341907078],
              [-4.681026976033431, 50.318351634400685],
              [-4.681525331142167, 50.318569508305181],
              [-4.681012408023668, 50.319066182131849],
              [-4.681678268337213, 50.3198135997809],
              [-4.682728425853947, 50.31967601702469],
              [-4.683266353800601, 50.320097158768696],
              [-4.683599282145794, 50.319896973782647],
              [-4.684692343368934, 50.320121784707766],
              [-4.684439614470191, 50.320523409306922],
              [-4.683957650757145, 50.320372632388981],
              [-4.68455125195781, 50.32075920022276],
              [-4.683395457101462, 50.321017075139324],
              [-4.683440614696112, 50.321294880496694],
              [-4.68265970837858, 50.321573772444744],
              [-4.68309422085056, 50.321630302491712],
              [-4.682820252755349, 50.321904683311239],
              [-4.683111108764165, 50.322079667820951],
              [-4.682242138234027, 50.322267041440291],
              [-4.682431362649206, 50.322533425443964],
              [-4.68200607766181, 50.322690763342678],
              [-4.682329962313749, 50.323052985038451],
              [-4.681685411070482, 50.323383592133361],
              [-4.682452372772143, 50.324028826174107],
              [-4.682097347270631, 50.324111681783997],
              [-4.68183162181616, 50.325031718043327],
              [-4.682324245668489, 50.325171463756533],
              [-4.682026516235911, 50.325573221880084],
              [-4.682846620169562, 50.325814246129539],
              [-4.682743257382914, 50.326049605961195],
              [-4.682147816005248, 50.325880752705807],
              [-4.682715179004457, 50.326299418586899],
              [-4.682079750352556, 50.326892473691757],
              [-4.682444693009883, 50.327035166356538],
              [-4.682273781975007, 50.327393519024227],
              [-4.681665555245021, 50.327621642198714],
              [-4.682349458463427, 50.328213024431832],
              [-4.682100312871756, 50.330250768308112],
              [-4.682814455121825, 50.330879229265292],
              [-4.682066058966234, 50.33233662176567],
              [-4.682542990070388, 50.332420959623363],
              [-4.682064456073924, 50.332707256569456],
              [-4.68271895789688, 50.333822829804376],
              [-4.682143591067599, 50.334336239887648],
              [-4.682542752627421, 50.335334475184965],
              [-4.682156480141807, 50.335537686318013],
              [-4.682938903031054, 50.336029644122476],
              [-4.68193246669003, 50.336749997911056],
              [-4.682378658792042, 50.337460204762166],
              [-4.681865847773722, 50.336939532868854],
              [-4.681402521724872, 50.337820950190341],
              [-4.681861543522237, 50.338334770024851],
              [-4.683399316459926, 50.338961305455697],
              [-4.685205544416334, 50.339437695861882],
              [-4.685741738509103, 50.339250798841022],
              [-4.685553605146819, 50.340474881139201],
              [-4.686013968221692, 50.340936482370601],
              [-4.687900850221899, 50.3413956753676],
              [-4.688605600634698, 50.341057346557122],
              [-4.689501367524518, 50.342233863218041],
              [-4.688921907458063, 50.343670296946236],
              [-4.689676291412287, 50.34378686439149],
              [-4.690218005498108, 50.344741296087953],
              [-4.689752567701899, 50.346404468272048],
              [-4.691524717167677, 50.346843771829121],
              [-4.694831199213187, 50.347666647237268],
              [-4.697351833538198, 50.347867222683675],
              [-4.699347178434707, 50.347703952039801],
              [-4.702406675490693, 50.346971700001319],
              [-4.703890779573792, 50.349117588731815],
              [-4.703975779877329, 50.348733318995635],
              [-4.705406888402301, 50.34865953089615],
              [-4.70387801513637, 50.348546696745174],
              [-4.702111278226024, 50.34617620632698],
              [-4.700194280919809, 50.344523392529133],
              [-4.701007793142518, 50.343774083109714],
              [-4.705637904566782, 50.345478879425684],
              [-4.70582167133841, 50.345299195748851],
              [-4.704243768114382, 50.344643304835934],
              [-4.704342542475612, 50.344353162600711],
              [-4.705537203802176, 50.344498958206394],
              [-4.705658074847988, 50.344251476820695],
              [-4.704424036514915, 50.344080513154708],
              [-4.704538684002476, 50.343772910570586],
              [-4.706763346017405, 50.343838945735314],
              [-4.705556698418848, 50.343581003030501],
              [-4.70441746085387, 50.342307730031678],
              [-4.701610545621309, 50.343373281153433],
              [-4.700766137898712, 50.343404604226386],
              [-4.704790669144201, 50.34187537347011],
              [-4.705054477293745, 50.341150522813301],
              [-4.706099646961856, 50.340522614264451],
              [-4.705921087996488, 50.340249723062804],
              [-4.706498725447541, 50.340496228651276],
              [-4.708747292741784, 50.340466319647447],
              [-4.70973979315079, 50.340222797778623],
              [-4.709788731693055, 50.339699939461411],
              [-4.710114115879311, 50.340033265807065],
              [-4.711181644067213, 50.339528022446295],
              [-4.713002068909267, 50.339483732940195],
              [-4.714377140876553, 50.338802170202079],
              [-4.716817814848963, 50.338495063801169],
              [-4.717593297608195, 50.337849068616258],
              [-4.718511254504736, 50.338369102375381],
              [-4.724783854806952, 50.3375088610765],
              [-4.733173341912829, 50.336315155126997],
              [-4.735598469879761, 50.335741760810372],
              [-4.737089927178215, 50.334989721503483],
              [-4.737816237386465, 50.335473640177227],
              [-4.738812344219986, 50.335004004035575],
              [-4.739471194295889, 50.335341082358248],
              [-4.740545554281464, 50.335083671181437],
              [-4.743247849940182, 50.333786662537491],
              [-4.743047727890871, 50.333362317478709],
              [-4.743417735934686, 50.33302795609692],
              [-4.745407520694262, 50.33214800265489],
              [-4.745606680256244, 50.331678245655482],
              [-4.746076711958036, 50.331933394724231],
              [-4.747464680289121, 50.331730572728709],
              [-4.747712918921102, 50.332111495237456],
              [-4.74879927095033, 50.332282793398811],
              [-4.749716132168251, 50.331928276348236],
              [-4.749480822819531, 50.331528162147904],
              [-4.750028448959622, 50.331496311677597],
              [-4.75249547678878, 50.332189890771225],
              [-4.75588148402434, 50.330987025257116],
              [-4.755852420730515, 50.330679179399553],
              [-4.756314914330857, 50.331118867702841],
              [-4.756794179828322, 50.331047229063358],
              [-4.756759182819583, 50.330685552998979],
              [-4.755773516810218, 50.330481357577959],
              [-4.756840620919014, 50.330538797976452],
              [-4.756883998484848, 50.329055359937165],
              [-4.757803251613865, 50.329011055631071],
              [-4.757839146133017, 50.328733151412507],
              [-4.759730603224392, 50.328709827034565],
              [-4.763039607813126, 50.32655058806035],
              [-4.763243818802475, 50.326074382204162],
              [-4.762015483304252, 50.324253275380258],
              [-4.762395183513094, 50.324380075608808],
              [-4.762686707153655, 50.323955765792107],
              [-4.763091536439996, 50.324249277168974],
              [-4.765014252192405, 50.324235016641758],
              [-4.76625068108223, 50.32317023389723],
              [-4.76679294182324, 50.322080593223383],
              [-4.766047322287089, 50.321797907519688],
              [-4.766869079462263, 50.320876118682598],
              [-4.766151263250708, 50.319644675024001],
              [-4.766515663175261, 50.319145758320438],
              [-4.765585443978747, 50.318513953360117],
              [-4.765371702194741, 50.316260340225604],
              [-4.763620453369525, 50.315640864477871],
              [-4.764256906855317, 50.315424229153138],
              [-4.763832890280866, 50.315234623580707],
              [-4.764454602730555, 50.315127180060131],
              [-4.764719416527098, 50.314268130608589],
              [-4.764269088166344, 50.31364918093427],
              [-4.764862628231033, 50.313589180590448],
              [-4.765060982679941, 50.313013262497627],
              [-4.763219697666163, 50.311662813176206],
              [-4.763646091784044, 50.311094965880038],
              [-4.763219738868889, 50.310040972160031],
              [-4.761002346582447, 50.308305442950754],
              [-4.757950178376191, 50.307286824085814],
              [-4.755409157376505, 50.307308443955293],
              [-4.754846084817501, 50.306339519925125],
              [-4.753872474839857, 50.305897551679138],
              [-4.754236435611068, 50.30548773592357],
              [-4.753881807553817, 50.304892563924078],
              [-4.754458735908582, 50.304789832737363],
              [-4.754264784759092, 50.304058622014949],
              [-4.755058320850972, 50.303670100672548],
              [-4.755524642444298, 50.301606328635323],
              [-4.754883868891567, 50.301405641327023],
              [-4.755033991171563, 50.300965812276907],
              [-4.754419398745068, 50.301096425506636],
              [-4.754870425879598, 50.300784389715069],
              [-4.754213251301574, 50.300615571051353],
              [-4.752443740977609, 50.298840476709742],
              [-4.753153448539142, 50.29807165656279],
              [-4.753517019332435, 50.298239345399899],
              [-4.753894999069088, 50.297927245612904],
              [-4.755551920995664, 50.298920604683886],
              [-4.756111638690753, 50.299517202626852],
              [-4.755489223151858, 50.299997921880049],
              [-4.756424805079551, 50.300318443340011],
              [-4.757396462921395, 50.299611744660154],
              [-4.758116167518343, 50.299696304432288],
              [-4.758147931842331, 50.299225998848733],
              [-4.759332234268299, 50.29848185132721],
              [-4.761195440291381, 50.29947023036658],
              [-4.761849020781294, 50.299504166404034],
              [-4.761807743374019, 50.300439753399644],
              [-4.762652264156578, 50.300739002379011],
              [-4.765606671886301, 50.299836640106456],
              [-4.765882192455392, 50.299212106534192],
              [-4.767053707653646, 50.299168913774345],
              [-4.76756101463073, 50.298546050535343],
              [-4.766721996303644, 50.297736675481538],
              [-4.766973429552375, 50.297495910396393],
              [-4.767560781409796, 50.29697008792315],
              [-4.768060869554783, 50.297069712990492],
              [-4.770261967912204, 50.296379475981091],
              [-4.770974756762143, 50.295548403417222],
              [-4.771822880257752, 50.295692781209354],
              [-4.772630816772672, 50.295266912969772],
              [-4.772382291891403, 50.294639578479995],
              [-4.773691400382608, 50.294405032651277],
              [-4.773630087258992, 50.293906360007256],
              [-4.774158140247577, 50.293714743837597],
              [-4.773440546806104, 50.293399954750825],
              [-4.773351698106579, 50.292717536434381],
              [-4.774731404273975, 50.291887605003701],
              [-4.776267669536495, 50.291625114548779],
              [-4.777225002412363, 50.291066110410412],
              [-4.778159857272422, 50.291133706955826],
              [-4.777931906955802, 50.29086030535715],
              [-4.778672571485271, 50.290896560775764],
              [-4.778809457674287, 50.290667504594389],
              [-4.778281941560323, 50.290337403569154],
              [-4.779736558782, 50.290505894317413],
              [-4.780707100625532, 50.290005913628328],
              [-4.781147661158498, 50.29016897457128],
              [-4.780629403014615, 50.289179308402971],
              [-4.78223151482619, 50.287905890794477],
              [-4.784328665100054, 50.284604739701358],
              [-4.783425373501421, 50.283945446708891],
              [-4.783769541709147, 50.283251757101681],
              [-4.78255179395931, 50.283073146142208],
              [-4.782771677472321, 50.28267928155087],
              [-4.782008014009546, 50.28270387251532],
              [-4.782898196323882, 50.282369510741404],
              [-4.783175229819741, 50.281680129872178],
              [-4.782713410038959, 50.281247727539913],
              [-4.780775553870672, 50.280924433882916],
              [-4.780994500122345, 50.280514403498316],
              [-4.780255828887954, 50.280509599835547],
              [-4.780334801868826, 50.28020276900812],
              [-4.777768465822212, 50.279066002721237],
              [-4.778682548001358, 50.279070223678239],
              [-4.779679436377015, 50.278423895119211],
              [-4.780719849763869, 50.278380099885268],
              [-4.780509819713751, 50.277714978894323],
              [-4.779716418921296, 50.277709683298305],
              [-4.781293433385967, 50.277167292764865],
              [-4.782107173192942, 50.276486648641843],
              [-4.781732263971445, 50.276340010141261],
              [-4.782100862135278, 50.275945282871547],
              [-4.783272568001207, 50.275722910838311],
              [-4.783937954808682, 50.275074593466243],
              [-4.784222212308677, 50.274221321319281],
              [-4.783895552439799, 50.273818966068106],
              [-4.784559830605576, 50.27346481775546],
              [-4.78386372330407, 50.272984964810497],
              [-4.784195361693494, 50.272847482515857],
              [-4.784017227803859, 50.27244066734967],
              [-4.783292028617949, 50.272351904659097],
              [-4.783839908285521, 50.271999650228786],
              [-4.782781836993206, 50.272147343629619],
              [-4.783067873365017, 50.271781576914968],
              [-4.784084045954714, 50.27163758383864],
              [-4.783545784935871, 50.271264587313546],
              [-4.784107547229269, 50.270885912334812],
              [-4.783415697465409, 50.270983453466812],
              [-4.783954299186983, 50.270592740848521],
              [-4.783305700559179, 50.270421184427079],
              [-4.783851574134186, 50.270323544973969],
              [-4.783818203678509, 50.269968130687118],
              [-4.782848484826215, 50.26984924519239],
              [-4.782546413549932, 50.269530852906861],
              [-4.784303375975391, 50.269936710289343],
              [-4.785733321753434, 50.26945265376505],
              [-4.784461512934316, 50.269976995949627],
              [-4.784630703003852, 50.270278779862743],
              [-4.786721248276, 50.270219640253096],
              [-4.786526053453823, 50.26985731512007],
              [-4.786964496113656, 50.269482484296852],
              [-4.786717395218705, 50.269120505533145],
              [-4.7861330781515, 50.269472748707763],
              [-4.786634856883754, 50.269028934788139],
              [-4.785222906841077, 50.268835224122498],
              [-4.785238927690376, 50.268628847268424],
              [-4.783070305008047, 50.268455054201318],
              [-4.781814847595918, 50.269187666680601],
              [-4.783173684249935, 50.268230392637406],
              [-4.782567151510819, 50.268033536842211],
              [-4.782972931775514, 50.267796232404827],
              [-4.782209938310888, 50.267852294036551],
              [-4.782761050190429, 50.267411812700942],
              [-4.782201389497613, 50.267489088375385],
              [-4.782628647118656, 50.267235079017496],
              [-4.781969781177937, 50.266886553846184],
              [-4.782854001568044, 50.266334645819882],
              [-4.782278730573209, 50.266216198271479],
              [-4.784240697004401, 50.265712215793776],
              [-4.784387151948278, 50.265408261017683],
              [-4.784328342067925, 50.264857358713186],
              [-4.783923869460254, 50.264779800876894],
              [-4.784178209547826, 50.263799510917003],
              [-4.783392148241137, 50.263410865780635],
              [-4.783440496790584, 50.26308497608219],
              [-4.78542042539445, 50.262650705366468],
              [-4.785911441216479, 50.262191859799231],
              [-4.785654054940165, 50.261821130181204],
              [-4.78476371714676, 50.261376526090331],
              [-4.782778230553475, 50.261522169544264],
              [-4.781949313743013, 50.260662287699986],
              [-4.78093746172441, 50.260587574349451],
              [-4.780799474369976, 50.259977398130317],
              [-4.779404404742306, 50.259634791673058],
              [-4.778795691552612, 50.259157315256054],
              [-4.778174582017987, 50.259262129034809],
              [-4.778395728929359, 50.258939303701545],
              [-4.777147342870868, 50.259042926466648],
              [-4.776250988074755, 50.258540831749116],
              [-4.774656998958052, 50.258564535478413],
              [-4.774674464477348, 50.258213301118388],
              [-4.773920057719821, 50.257931780129589],
              [-4.771101833035922, 50.258208654823619],
              [-4.770454509573422, 50.257934450963923],
              [-4.77124483136167, 50.257578269692466],
              [-4.770213910851314, 50.257512014201041],
              [-4.770623915811665, 50.257251263847394],
              [-4.770397448899056, 50.257072263037699],
              [-4.768305883934533, 50.257104151095433],
              [-4.767937258115431, 50.256796303389017],
              [-4.767015443982931, 50.256748108171564],
              [-4.769012878893901, 50.256158080396652],
              [-4.770374754467052, 50.256246135900149],
              [-4.770829348002263, 50.255883573517977],
              [-4.770617264624563, 50.254961217580899],
              [-4.76893538633329, 50.254798039045561],
              [-4.76872851287439, 50.254448556584158],
              [-4.769063567104141, 50.2543955910145],
              [-4.767950970702738, 50.254250305742275],
              [-4.76844264511861, 50.254019099742564],
              [-4.768029002110258, 50.253950701171867],
              [-4.769222924155954, 50.25332134943509],
              [-4.768522655030183, 50.252858596791107],
              [-4.769365967731632, 50.252401316265704],
              [-4.77048217354684, 50.25241606960423],
              [-4.769925185852017, 50.252004778261615],
              [-4.771743630386768, 50.25176708937699],
              [-4.771804238013978, 50.251071204520954],
              [-4.772823811067116, 50.250968603961624],
              [-4.772391044931726, 50.25090877347791],
              [-4.772697446169643, 50.25074943700043],
              [-4.774127334856673, 50.250634328321375],
              [-4.7749324699497, 50.251042368689362],
              [-4.777600723733631, 50.250558494197911],
              [-4.778077154328994, 50.249946209529291],
              [-4.780679863730845, 50.248988878181365],
              [-4.785394038908746, 50.245632175669847],
              [-4.785679363811739, 50.244872421069992],
              [-4.785249964575484, 50.244821553367657],
              [-4.785094725889836, 50.244348523886593],
              [-4.785732322034967, 50.244048976401743],
              [-4.785321566448146, 50.24383664512785],
              [-4.786402553649761, 50.243419412647079],
              [-4.786271397409322, 50.242974591797406],
              [-4.788083438100181, 50.242153898537374],
              [-4.787906797088643, 50.241747052445433],
              [-4.787297687573705, 50.241644736045316],
              [-4.788201262973272, 50.241265027576169],
              [-4.787027818831205, 50.240361281101706],
              [-4.786239780670479, 50.240653651931567],
              [-4.786354987660094, 50.240897359883157],
              [-4.785956153380608, 50.240624476056418],
              [-4.786397362693025, 50.240445676153016],
              [-4.786278292932124, 50.240159782796603],
              [-4.784830233198168, 50.239813119020582],
              [-4.784870162365585, 50.239415466619455],
              [-4.78423289269179, 50.23926253628801],
              [-4.78478014885718, 50.239026327931604],
              [-4.784262629981249, 50.238906507078369],
              [-4.784126062391968, 50.238464512137988],
              [-4.783433711790996, 50.238497302794286],
              [-4.783624442351189, 50.238280440310675],
              [-4.783304871230563, 50.238428428518041],
              [-4.782893658256362, 50.23791835397688],
              [-4.781130911311949, 50.237874233641385],
              [-4.780843347131194, 50.237488024602193],
              [-4.783281532114956, 50.236559755079909],
              [-4.782955378742632, 50.236498308641856],
              [-4.783549936348142, 50.235317360034301],
              [-4.783179798320552, 50.235343324076545],
              [-4.784247409090547, 50.234867960790083],
              [-4.783688457381716, 50.234974914459578],
              [-4.784021564530561, 50.234674578968878],
              [-4.784888251755557, 50.234650196524278],
              [-4.784584885949243, 50.234787005430015],
              [-4.785725988304374, 50.235067274467198],
              [-4.786973494468247, 50.235007652641372],
              [-4.792310758323236, 50.233608374736995],
              [-4.794705242204906, 50.232659323586432],
              [-4.79818927097284, 50.230178143295078],
              [-4.798380451429896, 50.229252409067449],
              [-4.798093652050274, 50.229263818922682],
              [-4.798818930425222, 50.228598670378027],
              [-4.798539690243043, 50.228403904989797],
              [-4.798888382930468, 50.228300148634233],
              [-4.799054447864905, 50.226706661606478],
              [-4.798784664287417, 50.226026817818834],
              [-4.797738093738696, 50.225682331908175],
              [-4.798090593785972, 50.224997384414131],
              [-4.79755281209914, 50.224170175352043],
              [-4.798019778497488, 50.223955626766802],
              [-4.797606812297166, 50.223104720417538],
              [-4.797196918048858, 50.223024642920514],
              [-4.797720385876687, 50.222793441976926],
              [-4.797013654389477, 50.221857859295476],
              [-4.798482583242271, 50.219480062224847],
              [-4.799195376162445, 50.219250587166101],
              [-4.799996275158287, 50.219400387350682],
              [-4.802433142695413, 50.218734400745618],
              [-4.804117988424835, 50.218896114590663],
              [-4.804465719346686, 50.218466730559513],
              [-4.805293080660057, 50.218900109570463],
              [-4.804685280266968, 50.219460815225425],
              [-4.804923081299054, 50.220216080723986],
              [-4.8055331862749, 50.220363256038993],
              [-4.805548096009562, 50.220975482271861],
              [-4.806784828995227, 50.220810658512463],
              [-4.807513392203963, 50.221017027833732],
              [-4.807777838789471, 50.221914670198494],
              [-4.808266415639407, 50.22185427801805],
              [-4.808510445039524, 50.223096036833525],
              [-4.809416945221704, 50.223275601407813],
              [-4.810444948149542, 50.223017739899056],
              [-4.810509133926851, 50.22477658590644],
              [-4.811446056206404, 50.22509122896377],
              [-4.812473683349485, 50.224659747587708],
              [-4.812150456211191, 50.227077444845591],
              [-4.812270858277167, 50.227336294573952],
              [-4.812813379992798, 50.227286270981843],
              [-4.812261970638399, 50.227780882523511],
              [-4.813139851954454, 50.229830355528676],
              [-4.815812685794772, 50.230735267355371],
              [-4.81651075930737, 50.230492550723945],
              [-4.816865813382091, 50.230734908794076],
              [-4.817178416373276, 50.230447567166003],
              [-4.817310458017764, 50.230736713752897],
              [-4.817771725633667, 50.230592388064103],
              [-4.817760680166494, 50.230928184627714],
              [-4.818327131532893, 50.23078400258246],
              [-4.81789743484655, 50.231249599628946],
              [-4.818955001207071, 50.231633218157192],
              [-4.819276887030657, 50.231432001348175],
              [-4.819259870872582, 50.231713970657729],
              [-4.820696440762565, 50.231414614040176],
              [-4.82076909801956, 50.231647628930659],
              [-4.821223340014577, 50.231408108901796],
              [-4.821418794915656, 50.231583267507261],
              [-4.822129999775139, 50.23108832644396],
              [-4.8225585405399, 50.231339675551347],
              [-4.823675809799023, 50.230597373660714],
              [-4.825339065465649, 50.231073247574855],
              [-4.827944750333805, 50.230364838753857],
              [-4.830043188054728, 50.230563780485461],
              [-4.830623591450873, 50.230277070334864],
              [-4.830357823144134, 50.231164201133581],
              [-4.830804615648626, 50.23117849561838],
              [-4.830068275857966, 50.231626427488358],
              [-4.830383135132752, 50.231544090309221],
              [-4.830783702175936, 50.232198186529814],
              [-4.831312391287427, 50.232103436860378],
              [-4.831218613880169, 50.232816361771704],
              [-4.831628133686386, 50.23293500940629],
              [-4.831877468111119, 50.232669860162588],
              [-4.832084781100988, 50.23337544161587],
              [-4.833415363419032, 50.232755576903195],
              [-4.8333614662, 50.233312809235429],
              [-4.834321345479632, 50.232732564281939],
              [-4.834031067180837, 50.233205613003008],
              [-4.834688851062558, 50.23278116295014],
              [-4.834796670042629, 50.232967434402518],
              [-4.835145248625226, 50.232791608156106],
              [-4.83514611870518, 50.233042559239834],
              [-4.83577386045107, 50.23293907365732],
              [-4.836796545078447, 50.232283511414238],
              [-4.838388318236738, 50.232025119907114],
              [-4.838887944718814, 50.232361927171318],
              [-4.838583949314622, 50.232792145005902],
              [-4.839317745122155, 50.232656362321912],
              [-4.839594729785892, 50.233305355229064],
              [-4.840081400920559, 50.233423885353972],
              [-4.839344909786416, 50.235095258307247],
              [-4.840466148267621, 50.235318805774433],
              [-4.840708784409259, 50.235672686737409],
              [-4.840981030394977, 50.235510404616662],
              [-4.840812237716423, 50.236256657496426],
              [-4.841432061574634, 50.236891859978499],
              [-4.843123879854466, 50.236992572517359],
              [-4.844465631952699, 50.236725828000566],
              [-4.844758439592664, 50.234860197530637],
              [-4.84619701107486, 50.235183859449016],
              [-4.847352019399969, 50.234891074132477],
              [-4.847429556926577, 50.235109560844379],
              [-4.848355400147573, 50.235066161830147],
              [-4.849264826741862, 50.234465440886581],
              [-4.849329040528878, 50.235118733207528],
              [-4.849862514878735, 50.23484567178334],
              [-4.850701459344054, 50.235226273228584],
              [-4.85172809823522, 50.234686522499956],
              [-4.851764839930904, 50.235067026938147],
              [-4.853522697871575, 50.235405244307913],
              [-4.855222928084535, 50.235271691737744],
              [-4.855907901716076, 50.234882433406092],
              [-4.85664499498662, 50.23487239621992],
              [-4.856829787277928, 50.234562004054247],
              [-4.85886560507234, 50.235185653187671],
              [-4.861415482519724, 50.234884459591278],
              [-4.861844334082178, 50.235792326061997],
              [-4.862890548240205, 50.235978826379878],
              [-4.864492140316745, 50.235019087917422],
              [-4.865990336038259, 50.234768023174361],
              [-4.865951701415324, 50.234311107636202],
              [-4.865274399228769, 50.233985097202293],
              [-4.865508520592013, 50.233656381951221],
              [-4.866259045292655, 50.233403074381222],
              [-4.866929714667599, 50.233618598601304],
              [-4.867612579883736, 50.233312980084172],
              [-4.867525847748257, 50.233036262674979],
              [-4.867879715820532, 50.233230819875068],
              [-4.868585308611573, 50.233046972537274],
              [-4.869166109307415, 50.232373252926905],
              [-4.870677114187498, 50.231660342283341],
              [-4.871888409448312, 50.231934433138626],
              [-4.872688494541933, 50.231177010219248],
              [-4.87364548207016, 50.231209999061562],
              [-4.87408626268813, 50.230494742128222],
              [-4.873449312246682, 50.230233447358827],
              [-4.873874700202221, 50.229868496341119],
              [-4.873050682139985, 50.229690990095705],
              [-4.873352620441882, 50.229255332867503],
              [-4.873453704731745, 50.229537088335718],
              [-4.873824096630133, 50.229423572992843],
              [-4.873903819958739, 50.228697463133827],
              [-4.875699049038886, 50.227118380158572],
              [-4.875855631524164, 50.22680775539218],
              [-4.875406603393151, 50.226755908989446],
              [-4.876027374795378, 50.226399603526666],
              [-4.876129425971606, 50.225742203715328],
              [-4.875689321662921, 50.225349208314654],
              [-4.876205257177723, 50.225115138443826],
              [-4.875957591225592, 50.224844212799638],
              [-4.879417197539078, 50.224107185029375],
              [-4.879901125845959, 50.22373806025675],
              [-4.879433067264364, 50.223532878671676],
              [-4.879602359439838, 50.223340673776207],
              [-4.882130764744785, 50.223881530018744],
              [-4.882301086384278, 50.224171454451465],
              [-4.882949175683036, 50.224083398468203],
              [-4.883326499715702, 50.223272528535617],
              [-4.884270180391618, 50.222878472562741],
              [-4.883779037256398, 50.222871783047928],
              [-4.885004741432419, 50.222063218666399],
              [-4.885021121495029, 50.221707489044356],
              [-4.884448392910856, 50.221649756406158],
              [-4.885147028688571, 50.221238392718746],
              [-4.885022692692767, 50.220967119640768],
              [-4.885627225887526, 50.22050681701824],
              [-4.886007101482583, 50.220876984628461],
              [-4.886249774585802, 50.220623575071635],
              [-4.886493351913629, 50.220826214933282],
              [-4.887065528856411, 50.219946618726055],
              [-4.888028268482363, 50.219637514679221],
              [-4.887570058539879, 50.219293589937557],
              [-4.88842336134525, 50.218960217920774],
              [-4.888591927349021, 50.21838570798618],
              [-4.889884127578928, 50.21847138919545],
              [-4.889951782384799, 50.218732374167637],
              [-4.890331723739962, 50.218686033673599],
              [-4.890266290839439, 50.21906457499955],
              [-4.89156916285865, 50.2187469725637],
              [-4.890660715830353, 50.218214572928893],
              [-4.890767883948403, 50.217459878827206],
              [-4.891181710948027, 50.217371312257356],
              [-4.891557416325102, 50.217764954188851],
              [-4.892657294257767, 50.217221210167828],
              [-4.893478301285688, 50.216031331370466],
              [-4.893123441461828, 50.215565211835191],
              [-4.893433327718449, 50.214732598579609],
              [-4.894486062823365, 50.214453580355297],
              [-4.895032675982148, 50.213595269530344],
              [-4.895518663378673, 50.213518380816531],
              [-4.895291705794568, 50.213289258158973],
              [-4.895961648350488, 50.212963100877623],
              [-4.896854887563227, 50.212875146613619],
              [-4.896748399921307, 50.213199844063851],
              [-4.899731542235776, 50.214286689860991],
              [-4.900822716524573, 50.213856428034056],
              [-4.901298401070552, 50.213194162772403],
              [-4.902488877901292, 50.212714626184756],
              [-4.90351174080222, 50.21182997230062],
              [-4.903014658472387, 50.211792928794573],
              [-4.903596785857, 50.211545384954746],
              [-4.9029976553345, 50.211259018580158],
              [-4.904732977939961, 50.209619798589593],
              [-4.903710430761483, 50.208869964879895],
              [-4.902496303745764, 50.208981288956295],
              [-4.90250299247001, 50.20872204992304],
              [-4.903139289183712, 50.208605388135751],
              [-4.902425652875583, 50.208186949435643],
              [-4.902431597158308, 50.207730726465059],
              [-4.903246131583232, 50.206348430962649],
              [-4.904746925692273, 50.20505060808113],
              [-4.905867072952651, 50.205193487868925],
              [-4.906800777234905, 50.205842977997541],
              [-4.90786926893544, 50.205919665438259],
              [-4.908354168013355, 50.206241252662807],
              [-4.910658240230071, 50.205293840498427],
              [-4.910068686780777, 50.203575176703289],
              [-4.910348743491294, 50.202834117146892],
              [-4.909773342802778, 50.202637146678825],
              [-4.910248873856319, 50.201538560750237],
              [-4.91107472105994, 50.201360429793354],
              [-4.910889021431847, 50.20026672775888],
              [-4.911562042946316, 50.199972784422748],
              [-4.9115321346805, 50.199596620164648],
              [-4.913095444288773, 50.199746303735701],
              [-4.916032970425759, 50.19728871548795],
              [-4.917945721574231, 50.196758483287347],
              [-4.918730874789122, 50.196236788888406],
              [-4.921753507854092, 50.195662386714055],
              [-4.922493322321549, 50.196532526122681],
              [-4.921980038318881, 50.197466597066807],
              [-4.92223470847538, 50.198928265911889],
              [-4.923192697295942, 50.200317350869909],
              [-4.922694663292117, 50.20079046623696],
              [-4.923016287112081, 50.2007787692568],
              [-4.923143453924234, 50.201483519545626],
              [-4.9234407976549, 50.201418459348652],
              [-4.922859068547113, 50.201898180965351],
              [-4.923445671211611, 50.201887906237395],
              [-4.923908631334815, 50.202353910999982],
              [-4.924422943030332, 50.201940650883927],
              [-4.925458172422138, 50.202070188242317],
              [-4.925714078458894, 50.202908424169763],
              [-4.927518678996092, 50.203608659426408],
              [-4.92831634644534, 50.204458412712249],
              [-4.929065853729433, 50.204407118096455],
              [-4.929050572457374, 50.204775423395439],
              [-4.931379969379086, 50.20557480059145],
              [-4.931642573340248, 50.205445828523104],
              [-4.93198958092271, 50.206441871910748],
              [-4.932721361236068, 50.206512442174549],
              [-4.933581725578085, 50.207102402381729],
              [-4.938007649662072, 50.207093913870771],
              [-4.948241279852515, 50.205731580628864],
              [-4.95040220802571, 50.204703348647158],
              [-4.953172490348486, 50.20396632487973],
              [-4.957549301571223, 50.202239271767944],
              [-4.957656791155319, 50.201711187726843],
              [-4.958611397407991, 50.201306344000827],
              [-4.95862508183856, 50.200870606277732],
              [-4.959960654338606, 50.200190668496745],
              [-4.959641515099253, 50.200061173147532],
              [-4.960445018515641, 50.199366908669376],
              [-4.959989884603893, 50.198828882367941],
              [-4.960445750662601, 50.198812758342484],
              [-4.960231197317261, 50.199014335468981],
              [-4.961027601124759, 50.198794318224259],
              [-4.962718448999105, 50.197720270166933],
              [-4.962838490306426, 50.197124392734082],
              [-4.96198485223574, 50.196510187584451],
              [-4.962294724548484, 50.196083082207856],
              [-4.963644757956087, 50.195933474534733],
              [-4.965159139931129, 50.194931362365665],
              [-4.965623491566126, 50.193764456389474],
              [-4.966030373262589, 50.193637115867013],
              [-4.966209601848746, 50.193883514483453],
              [-4.968606330482795, 50.192131054099725],
              [-4.968763803890266, 50.191665548714511],
              [-4.968349462552524, 50.191514224251883],
              [-4.968962448724145, 50.191479654644958],
              [-4.968420450368225, 50.191167874778252],
              [-4.968975007851827, 50.190914405599919],
              [-4.968533673780107, 50.190869921158132],
              [-4.968864653476221, 50.190242553525565],
              [-4.968376500290264, 50.189714399749043],
              [-4.968755238811922, 50.189405157005432],
              [-4.967990337179272, 50.188661887243931],
              [-4.968487363577466, 50.188339725091758],
              [-4.967717322805542, 50.187852061990021],
              [-4.968142155528014, 50.187766534151898],
              [-4.967702898603311, 50.187393651775672],
              [-4.968132805877191, 50.186080085335782],
              [-4.96752660991593, 50.185884188369151],
              [-4.968265812139296, 50.185626900243058],
              [-4.96857355514117, 50.18604812274171],
              [-4.969160009027697, 50.18576774875055],
              [-4.969876235803943, 50.185862768855721],
              [-4.970688541230853, 50.1864671791826],
              [-4.972132211096809, 50.186363650049152],
              [-4.973579376315226, 50.185662700311596],
              [-4.973511033359239, 50.184720804605391],
              [-4.974654797880862, 50.182475858441933],
              [-4.974689812277376, 50.179974160543296],
              [-4.973717052540679, 50.179086027754593],
              [-4.972958381921253, 50.17911805890526],
              [-4.97273444001982, 50.179414355967602],
              [-4.972659694109391, 50.179113115984343],
              [-4.971535231256402, 50.178984488303563],
              [-4.971597149043387, 50.178199378876975],
              [-4.970963612338689, 50.178080663726575],
              [-4.972468576483764, 50.176374326996935],
              [-4.972566416991675, 50.175446164669587],
              [-4.973134616288152, 50.175370438721629],
              [-4.97281827648931, 50.175035806820198],
              [-4.973935518317587, 50.172772651904303],
              [-4.974072333444547, 50.171345127304647],
              [-4.973615658685986, 50.170757716633844],
              [-4.97415019847607, 50.169848047037092],
              [-4.973894597739301, 50.169385921628006],
              [-4.973473199610462, 50.169366134446086],
              [-4.973503396943896, 50.166063939265946],
              [-4.972466171840161, 50.16467368939562],
              [-4.973321999600345, 50.163593869634575],
              [-4.973581663534577, 50.163941646814799],
              [-4.974642225086653, 50.163430496466816],
              [-4.974932689095377, 50.163552588529754],
              [-4.974983746348798, 50.163179756941297],
              [-4.976410270776605, 50.162496385014919],
              [-4.976954514045493, 50.161587351079568],
              [-4.977677698749364, 50.161236854324066],
              [-4.977218869977496, 50.160974257982424],
              [-4.97767053542563, 50.160648718536798],
              [-4.978302913322269, 50.16063968622683],
              [-4.978788353826498, 50.160181938012997],
              [-4.979661687495898, 50.160332233769054],
              [-4.981250286942533, 50.159780474342362],
              [-4.983815345569289, 50.15782094560852],
              [-4.984596330438863, 50.15599541821782],
              [-4.983727902465525, 50.15513617057875],
              [-4.984087555598839, 50.153762269847384],
              [-4.983703071477503, 50.153343960276175],
              [-4.982688226455839, 50.153392540695904],
              [-4.982232634754335, 50.152866306266148],
              [-4.982634315855607, 50.15248175954207],
              [-4.981913703579344, 50.152623521929208],
              [-4.982392880810114, 50.152224390448062],
              [-4.981743842808835, 50.152121427467954],
              [-4.981892014040438, 50.151825258608717],
              [-4.982776208476118, 50.151185425680104],
              [-4.983045593426487, 50.151284650140191],
              [-4.983747422618992, 50.150212305125905],
              [-4.984414422481294, 50.149884798632364],
              [-4.985256542518123, 50.149873930671369],
              [-4.984802745947077, 50.150387567005808],
              [-4.985110485795789, 50.150495695342897],
              [-4.98713498936609, 50.149621383825526],
              [-4.987832927272004, 50.149745546655595],
              [-4.987504826913849, 50.150074243127229],
              [-4.987808415068215, 50.150025945358557],
              [-4.987577082121235, 50.150245100932047],
              [-4.988071983947865, 50.150548107717015],
              [-4.990311102892059, 50.149860722225078],
              [-4.991058246736761, 50.150257068233593],
              [-4.99594289630942, 50.14857838260275],
              [-4.997065368117158, 50.147656116083525],
              [-4.997588114691015, 50.14663598702704],
              [-4.999564220545666, 50.145039445938558],
              [-5.003595063579419, 50.142950702988244],
              [-5.004724001055854, 50.143073500909303],
              [-5.005714331817578, 50.141276581942442],
              [-5.006392235399469, 50.140725568675393],
              [-5.007151135495485, 50.140751780182505],
              [-5.007251150666283, 50.140338080800518],
              [-5.008748862289286, 50.139446451334905],
              [-5.00896343363211, 50.139921261587943],
              [-5.009610561902877, 50.139215400020696],
              [-5.010731652205722, 50.13952635437316],
              [-5.010832036701239, 50.140656318734855],
              [-5.011714132705479, 50.140304179080452],
              [-5.011751445418775, 50.140719715403179],
              [-5.01235324037165, 50.140606937898589],
              [-5.012583111140501, 50.141346720173779],
              [-5.014039309249507, 50.140508275392015],
              [-5.014025526001604, 50.140800996595445],
              [-5.01493111947034, 50.140511192414976],
              [-5.016233593813911, 50.141278864617576],
              [-5.014909180585554, 50.14321049921071],
              [-5.014811448168167, 50.144349206599536],
              [-5.014286006342342, 50.144675012176506],
              [-5.01514873385476, 50.145037615539295],
              [-5.015236666765889, 50.145767587359359],
              [-5.016379000444904, 50.145968187657644],
              [-5.016032023053455, 50.14648277072304],
              [-5.016208740438594, 50.146799326526065],
              [-5.016535955276384, 50.146637891572588],
              [-5.016444114379018, 50.147914082431683],
              [-5.017511916645513, 50.147952886244433],
              [-5.017992460157098, 50.148580887927267],
              [-5.017442362498378, 50.149471384444958],
              [-5.013001737643481, 50.151588341307132],
              [-5.010947340826499, 50.153673814380852],
              [-5.009390189565912, 50.154071342345432],
              [-5.008378728465012, 50.15372784466804],
              [-5.007813193516792, 50.152860021187976],
              [-5.008965346123802, 50.152167161179392],
              [-5.008517283792501, 50.151185646678357],
              [-5.007052936457326, 50.150696453663706],
              [-5.005881954429183, 50.151289929646211],
              [-5.004360201191364, 50.150420769551587],
              [-5.003679100477781, 50.150923274645713],
              [-5.004398005617006, 50.151648611929687],
              [-5.003956807269272, 50.153253184182496],
              [-5.000726010329006, 50.155453481100047],
              [-5.000280907339168, 50.156752282809229],
              [-4.999371817717191, 50.157283145237997],
              [-4.99925072890379, 50.157987943477686],
              [-4.999875602657579, 50.158664463119344],
              [-4.999994105485828, 50.159572668674748],
              [-4.998775829221306, 50.161696568913506],
              [-4.997136881907263, 50.161732614877096],
              [-4.993327503510031, 50.160762249014567],
              [-4.990615927345328, 50.161425882467903],
              [-4.987898994510913, 50.161039788437598],
              [-4.987004167254629, 50.162072146083091],
              [-4.98533915100538, 50.163127911362288],
              [-4.986891947822843, 50.162787496026461],
              [-4.988049131097026, 50.16176277727179],
              [-4.991036626786992, 50.162220157313293],
              [-4.992719373025012, 50.16162983059764],
              [-4.993603047740892, 50.161720383269788],
              [-4.995682610290201, 50.163176200987337],
              [-4.997097268772239, 50.163068605748805],
              [-4.997059392887898, 50.163895392758469],
              [-4.995970417920407, 50.165470779445414],
              [-4.993561279399287, 50.167368981224492],
              [-4.993801284022775, 50.168049163764792],
              [-4.994579790998211, 50.16804616868432],
              [-4.995727246634547, 50.167000934496933],
              [-4.996573883867872, 50.166721797184827],
              [-4.999026515372077, 50.16688423063939],
              [-4.999537368381862, 50.167303722089763],
              [-5.001498567540426, 50.16757955740669],
              [-5.001699166049613, 50.16963979046011],
              [-5.001249979514502, 50.16980253683969],
              [-4.998927362141662, 50.169370515711897],
              [-4.997487237624905, 50.169633516155152],
              [-4.995638151433103, 50.170971250910931],
              [-4.995788261133577, 50.172206087515228],
              [-4.994574567849526, 50.17244071602353],
              [-4.993383459291238, 50.173775825503938],
              [-4.993403125499037, 50.175658122431813],
              [-4.98923435179202, 50.177674466059337],
              [-4.989382044529094, 50.178020594906535],
              [-4.98853430386685, 50.178510205385571],
              [-4.988903861155397, 50.179829354321591],
              [-4.989145852504525, 50.178679767189905],
              [-4.990630239195495, 50.177707770040207],
              [-4.993854993555315, 50.176858189479979],
              [-4.996436131485545, 50.175261401226784],
              [-4.996571966533802, 50.176692711735932],
              [-4.995398426385748, 50.177995902163985],
              [-4.995046201862226, 50.179306673168995],
              [-4.996166338567934, 50.180595632637186],
              [-4.995421842589355, 50.181995693125145],
              [-4.995713180354641, 50.182777997025248],
              [-4.99687613124777, 50.183630452111281],
              [-4.998476811597786, 50.183843686312976],
              [-4.997117916372817, 50.185186763977434],
              [-4.996894265697025, 50.187297536013276],
              [-4.997484886622697, 50.188651430697462],
              [-4.997880575212023, 50.185619550771897],
              [-4.999418913385092, 50.183898504417485],
              [-4.999446530315883, 50.183266291205548],
              [-4.997755841420753, 50.183203824797516],
              [-4.996443327047256, 50.181976520474009],
              [-4.997277525129674, 50.180890785837001],
              [-4.996897447663292, 50.179273275665182],
              [-4.997398098320713, 50.177649212331602],
              [-4.998628620712156, 50.175847964679825],
              [-4.997975348222345, 50.174408434600032],
              [-4.996597778827295, 50.17363709728609],
              [-4.998496049118572, 50.171831199027551],
              [-4.999003885156197, 50.171821674515222],
              [-4.998989508818095, 50.171212135416638],
              [-4.999822198572916, 50.170888356732888],
              [-5.002007874016312, 50.171100808828321],
              [-5.003789573424852, 50.170767740459453],
              [-5.005895133097019, 50.170819370113996],
              [-5.005882884100403, 50.170601990411768],
              [-5.004144339727205, 50.170127056033309],
              [-5.004336886176476, 50.16982521185858],
              [-5.003982781803504, 50.169605886965236],
              [-5.004922141201113, 50.169308995717564],
              [-5.005460766870105, 50.168741808836018],
              [-5.004673342709061, 50.168826069498216],
              [-5.004443166114321, 50.167858689610128],
              [-5.003568020314124, 50.167504422584223],
              [-5.001651770011916, 50.165734166457938],
              [-5.000570213852974, 50.165280865553441],
              [-5.002561666985947, 50.164320784313738],
              [-5.002718229541014, 50.163355987497972],
              [-5.003494383511441, 50.162780863236016],
              [-5.005586120813681, 50.161948571131134],
              [-5.005972778395053, 50.161504960240144],
              [-5.004047694651659, 50.158542135508419],
              [-5.004700171560954, 50.157135397681614],
              [-5.005774187212953, 50.156444594232774],
              [-5.007258194497497, 50.156575263532226],
              [-5.00820265246077, 50.15689891850537],
              [-5.01024445739832, 50.158440916248502],
              [-5.011776447128241, 50.158999381467844],
              [-5.012836329902351, 50.158863917906913],
              [-5.013735990780247, 50.15914191347192],
              [-5.014962632657058, 50.158207772728638],
              [-5.01402371959739, 50.15808463396835],
              [-5.015106651854213, 50.158004324490854],
              [-5.017114616179867, 50.15727115169291],
              [-5.019510124219751, 50.157377960468558],
              [-5.020856309106949, 50.156698823947806],
              [-5.022081993844673, 50.155464171699542],
              [-5.023754674563237, 50.154768400405473],
              [-5.024306585875467, 50.154927635347534],
              [-5.024870056362791, 50.155691083339697],
              [-5.026062478157813, 50.156125974058106],
              [-5.028161836279185, 50.158525257596537],
              [-5.028307654495051, 50.159081889822247],
              [-5.027424101522119, 50.161474435603338],
              [-5.025357101831949, 50.162913695342326],
              [-5.024174081699513, 50.164248920630023],
              [-5.023667482746049, 50.166583032245704],
              [-5.022977403300068, 50.167520379277029],
              [-5.023237078759588, 50.168217083681178],
              [-5.021448771082961, 50.171260150035778],
              [-5.021789286719416, 50.171906175381089],
              [-5.022973561661819, 50.17269394615635],
              [-5.02309305635458, 50.173543631911173],
              [-5.022312949979955, 50.175164297721651],
              [-5.020705843331477, 50.175800760918982],
              [-5.019888323278165, 50.176541698276544],
              [-5.019806146401551, 50.180478579867447],
              [-5.02047277567979, 50.181341016291981],
              [-5.017228728635292, 50.182815245090971],
              [-5.015798090057134, 50.184589508393678],
              [-5.016887763162828, 50.182940154227587],
              [-5.01607566344377, 50.182925279201825],
              [-5.014585151821909, 50.182059933373559],
              [-5.014182479678006, 50.182272796528153],
              [-5.014811973148501, 50.183258571287276],
              [-5.013927416090173, 50.183650371725285],
              [-5.01428694893566, 50.183975675776196],
              [-5.015113256298241, 50.183726619121529],
              [-5.015240126381658, 50.184317041945562],
              [-5.013889227732771, 50.187803796081631],
              [-5.014457397351918, 50.188771374462377],
              [-5.014336944582711, 50.190462102697772],
              [-5.015232074174071, 50.189591452652046],
              [-5.015417275672504, 50.187728121744044],
              [-5.016579455894363, 50.187827458997027],
              [-5.015688534584504, 50.187185827174368],
              [-5.017163240885607, 50.184398399647364],
              [-5.01906018252898, 50.183998234295885],
              [-5.019966350966575, 50.184149175300995],
              [-5.020366311146225, 50.183782535283932],
              [-5.021519008668254, 50.183821797634458],
              [-5.021826013590581, 50.183247971490616],
              [-5.024250975219329, 50.181937993267873],
              [-5.025647884747793, 50.182206538946957],
              [-5.027816416592303, 50.186211568542234],
              [-5.028402486409348, 50.191840214264083],
              [-5.029330700238797, 50.192911673240808],
              [-5.029162064274594, 50.194364390736062],
              [-5.030690150191476, 50.198510221555473],
              [-5.031470330726265, 50.200294394202686],
              [-5.034301554840379, 50.202528722626887],
              [-5.034861169502324, 50.203374085347122],
              [-5.03388653284753, 50.204911750152171],
              [-5.03177144095744, 50.205463580786265],
              [-5.030118746229642, 50.205306132331195],
              [-5.027864979306596, 50.204222488002578],
              [-5.026695387921113, 50.204012806444858],
              [-5.029496252094513, 50.206055534034213],
              [-5.02550983740487, 50.208357168865106],
              [-5.024390535789193, 50.21000736857286],
              [-5.024294040003958, 50.211340350554472],
              [-5.023014610192978, 50.211612964528165],
              [-5.024551603511702, 50.21246440214513],
              [-5.025053077948285, 50.214385414933197],
              [-5.023842421332581, 50.21470482193282],
              [-5.024894699573707, 50.215272022523521],
              [-5.024596792064792, 50.216390756713665],
              [-5.025391798444586, 50.21736676492457],
              [-5.024561320405843, 50.219020229707951],
              [-5.024558428889384, 50.220635935535633],
              [-5.022827034512853, 50.222655592812693],
              [-5.021891508330707, 50.22262867445729],
              [-5.021176499176028, 50.222230783959127],
              [-5.01916064012832, 50.222148294943295],
              [-5.016620106260656, 50.222616449848573],
              [-5.013153920046196, 50.224971586992851],
              [-5.009164701871341, 50.22708739714426],
              [-5.008529848808593, 50.226848374149668],
              [-5.006007482039966, 50.224134050259181],
              [-5.00614998654635, 50.222142313920074],
              [-5.00502545044595, 50.221829593490803],
              [-5.005386938158658, 50.222628047740635],
              [-5.004419241035036, 50.223382646527419],
              [-4.998841160559333, 50.223400038819435],
              [-4.997061707888991, 50.223666387196005],
              [-4.994449044600986, 50.224755717313961],
              [-4.99232981782738, 50.222754853623321],
              [-4.992142635789624, 50.221556958088883],
              [-4.991235745341936, 50.222837923526356],
              [-4.992742651642875, 50.224644995088319],
              [-4.98850077937866, 50.225223845681612],
              [-4.986595266063054, 50.225920570966579],
              [-4.984535599892503, 50.225789130143816],
              [-4.982039050191502, 50.226700676472561],
              [-4.980891308287627, 50.226262379638818],
              [-4.980280160618376, 50.225411790321552],
              [-4.980132962865218, 50.223951078356706],
              [-4.980846882269017, 50.222785804106266],
              [-4.979778305263222, 50.221861498319313],
              [-4.979731823792341, 50.221340943925817],
              [-4.979294924953443, 50.221770929104224],
              [-4.979402923658915, 50.222991990561482],
              [-4.978244746695458, 50.223734164326736],
              [-4.979046396367307, 50.224312705072286],
              [-4.978727652835841, 50.225205155702056],
              [-4.979123399721805, 50.227099377672403],
              [-4.97653871863819, 50.227544393075277],
              [-4.975313740440274, 50.226878632941286],
              [-4.972496479009939, 50.226763693546012],
              [-4.971864255477993, 50.225865024293434],
              [-4.970871868904655, 50.22697619695564],
              [-4.970828366400795, 50.228238497032621],
              [-4.971491898329925, 50.229211933653033],
              [-4.972341405409195, 50.22968044840416],
              [-4.97534313419987, 50.229219442763501],
              [-4.977317408910209, 50.229486373151225],
              [-4.979466597137768, 50.229207243803344],
              [-4.981745048720339, 50.229516667608571],
              [-4.98320179645664, 50.231780328123236],
              [-4.982667319959956, 50.232339196711997],
              [-4.978750034057962, 50.232670959823089],
              [-4.977228772455227, 50.23240682933222],
              [-4.97609154529988, 50.231486049181363],
              [-4.97227644653426, 50.230867734970168],
              [-4.970836817387659, 50.230945064076892],
              [-4.969130589194869, 50.232170737964907],
              [-4.965650614836097, 50.232319056708228],
              [-4.964602507966206, 50.231904141138386],
              [-4.964727259490299, 50.231247873940156],
              [-4.964333858585002, 50.231484609006841],
              [-4.964797932035135, 50.229742902019318],
              [-4.964033010237228, 50.22889615633558],
              [-4.962143671852876, 50.228301192201236],
              [-4.962071456081719, 50.227905428692452],
              [-4.960729354983499, 50.227790337074836],
              [-4.960107784379089, 50.227377096781879],
              [-4.960385727620074, 50.227789204968715],
              [-4.961557649014673, 50.227873559122692],
              [-4.961936683304559, 50.228468394404423],
              [-4.963191518176684, 50.228690948330538],
              [-4.963878121483171, 50.229202363414053],
              [-4.964255030696352, 50.230101297332631],
              [-4.963487761258466, 50.230821643809335],
              [-4.96388204294129, 50.23224907727645],
              [-4.96705337245715, 50.233453544482167],
              [-4.967049920642684, 50.234752599273065],
              [-4.96548947061375, 50.236432367999015],
              [-4.965159177584288, 50.236398524766464],
              [-4.965255004145235, 50.237038363717687],
              [-4.963325468225543, 50.239191700826069],
              [-4.962499342905049, 50.239168854477541],
              [-4.959893134546515, 50.238465154021888],
              [-4.958183002359682, 50.238753419926809],
              [-4.957736734945103, 50.237843640753233],
              [-4.957649458277376, 50.238631179571918],
              [-4.955825231158748, 50.239745878656031],
              [-4.955360565032462, 50.239947949003358],
              [-4.953665401781977, 50.239186881101865],
              [-4.951619665664826, 50.238879983305878],
              [-4.953689872567935, 50.239310397666749],
              [-4.955277596768678, 50.240080496956459],
              [-4.9562891953796, 50.239544110343125],
              [-4.957783169129292, 50.238751912447711],
              [-4.958461603822887, 50.238926232918281],
              [-4.959945658036101, 50.238656320761748],
              [-4.962303928546596, 50.239163449785494],
              [-4.963165494561844, 50.239348709053154],
              [-4.964163005036321, 50.239013797322215],
              [-4.964462083993789, 50.239154588650273],
              [-4.964364343235691, 50.238754980773813],
              [-4.965577805758092, 50.240362091158012],
              [-4.964925153931029, 50.24116857382591],
              [-4.963846685281872, 50.241600911778107],
              [-4.963637843653866, 50.242168467805435],
              [-4.964263619341073, 50.243346208714193],
              [-4.963828942431024, 50.242106017857942],
              [-4.965843800916501, 50.240466842069246],
              [-4.96467818090621, 50.238410531618946],
              [-4.964808189885608, 50.238019501119318],
              [-4.965959975022889, 50.237481830867942],
              [-4.965751785914036, 50.239120751384824],
              [-4.966908801481756, 50.239254010632706],
              [-4.966720974028896, 50.240204248563344],
              [-4.967272488947418, 50.24029900136739],
              [-4.966998587163686, 50.239750972363339],
              [-4.968443683765914, 50.238674141108746],
              [-4.968664006065774, 50.238044212328738],
              [-4.969376978018587, 50.237893744024987],
              [-4.971111425173413, 50.236171688322429],
              [-4.97224615105721, 50.235834993474704],
              [-4.975608466359719, 50.236315589818389],
              [-4.976277207304252, 50.235928727820905],
              [-4.976351879456599, 50.236946917327472],
              [-4.975844871575453, 50.236665757189527],
              [-4.97599793674802, 50.236961387976109],
              [-4.975490740004184, 50.237037357370127],
              [-4.975135830722723, 50.23645094257887],
              [-4.975082398081913, 50.237191751974905],
              [-4.976514978602012, 50.237155932228696],
              [-4.976794354658682, 50.237565266710327],
              [-4.978659365974378, 50.23798610474131],
              [-4.978813176814347, 50.237663713266898],
              [-4.977124451636721, 50.237145702472247],
              [-4.977298899479095, 50.236186793086993],
              [-4.976952880444479, 50.235608251743656],
              [-4.974718926982636, 50.235161757903022],
              [-4.974862484799049, 50.234404246181171],
              [-4.976039208848448, 50.233977380725165],
              [-4.979523129592972, 50.23420556663001],
              [-4.984125965910537, 50.232880132168304],
              [-4.985652602737965, 50.231636364578826],
              [-4.984773110586019, 50.228693115733805],
              [-4.986419491679865, 50.228469962303471],
              [-4.990159558802247, 50.227127791637493],
              [-4.990498336298159, 50.227253100461027],
              [-4.990549711883041, 50.226886553364047],
              [-4.991775588027205, 50.22637639853415],
              [-4.993904322352189, 50.22608763158204],
              [-4.995148862222499, 50.226457634794635],
              [-4.996443261639721, 50.226370257599754],
              [-4.998874262434724, 50.225714669563764],
              [-4.999688209547873, 50.225211472302838],
              [-5.001493229621258, 50.224927331173504],
              [-5.001900535509482, 50.225116503413496],
              [-5.001289380579702, 50.22657071378751],
              [-5.003553824933844, 50.225675550292209],
              [-5.005546599059897, 50.227907976978322],
              [-5.007199867447157, 50.228575821044053],
              [-5.011142117575051, 50.228826264821713],
              [-5.013966166801134, 50.22742251773375],
              [-5.014807684055405, 50.227869342275405],
              [-5.014670703447559, 50.228987465642582],
              [-5.013415813190004, 50.230235363712403],
              [-5.015156127129194, 50.230235155159583],
              [-5.014935971718517, 50.230968624128622],
              [-5.015948448608591, 50.231723151185427],
              [-5.016277141017667, 50.234352147375446],
              [-5.015303047717888, 50.236472546299289],
              [-5.014657483020606, 50.236866230526665],
              [-5.012967482489044, 50.237067526695554],
              [-5.014180357978288, 50.237439949462669],
              [-5.012611957529385, 50.239391340451064],
              [-5.012065158330979, 50.240840337926208],
              [-5.013237472844033, 50.242613550177424],
              [-5.015398573469477, 50.243805141632677],
              [-5.017335861544939, 50.244649879784781],
              [-5.02059794596011, 50.244726079186734],
              [-5.021766135745087, 50.2449646516082],
              [-5.021747282094936, 50.245176540852327],
              [-5.019705779496868, 50.246611392648603],
              [-5.01656224099671, 50.247392073496407],
              [-5.012117465234593, 50.249096104815976],
              [-5.010210972822539, 50.249030404658484],
              [-5.010499543742297, 50.249579754083598],
              [-5.00907341072215, 50.250814045393525],
              [-5.008619450939102, 50.251825226858386],
              [-5.008993655232842, 50.253777482071072],
              [-5.009987202938965, 50.254025197762076],
              [-5.010560276326812, 50.254813652177859],
              [-5.010535191895654, 50.256306681568411],
              [-5.010043992385145, 50.257706545840705],
              [-5.008379043359154, 50.259519139099218],
              [-5.008398361611771, 50.260448788351766],
              [-5.006136369532246, 50.263857348264693],
              [-5.005764793862506, 50.266379448436503],
              [-5.004604041730275, 50.266711742135264],
              [-5.001947642494056, 50.268745990803595],
              [-5.002434018332187, 50.271032697963392],
              [-5.001041740867386, 50.273688212535795],
              [-4.994777239843645, 50.277375316327209],
              [-4.994137579007901, 50.277158824295242],
              [-4.994656676367578, 50.277579027411775],
              [-4.99458648274163, 50.278160154442901],
              [-4.993253099899862, 50.278521070698709],
              [-4.992655603025964, 50.279356868502056],
              [-4.991509027390421, 50.279899159010732],
              [-4.992625505787623, 50.279548350817706],
              [-4.993746285310339, 50.278573126056074],
              [-4.994151459726947, 50.278788469830204],
              [-4.995260539296719, 50.277404231539407],
              [-4.997251184087557, 50.276691693097355],
              [-4.999952391388075, 50.274779641569793],
              [-5.002889713821194, 50.273500108615004],
              [-5.00409717355241, 50.271700338576949],
              [-5.003885872798842, 50.271532189129758],
              [-5.004416592726773, 50.27126748122388],
              [-5.004255631625742, 50.270516912493349],
              [-5.003460761381311, 50.27019743951945],
              [-5.003661304450055, 50.269682200303556],
              [-5.004868061327771, 50.268519329231566],
              [-5.005542646776619, 50.268489277093607],
              [-5.00705238824797, 50.267614496335518],
              [-5.008018204944317, 50.265882109460762],
              [-5.009405421899396, 50.264846749995982],
              [-5.010479240779477, 50.261870142579752],
              [-5.011104833536526, 50.261087492172003],
              [-5.012238580083761, 50.26055253058864],
              [-5.011899799587167, 50.260365213560362],
              [-5.013513252107137, 50.258027659792191],
              [-5.013708808812178, 50.256902625011783],
              [-5.015390090699911, 50.255708429628662],
              [-5.013796449733208, 50.255201969939343],
              [-5.011918613896183, 50.25277059366222],
              [-5.011466160240601, 50.251403295121911],
              [-5.013503902032317, 50.250479644227305],
              [-5.016180981945431, 50.25017264497432],
              [-5.019831535670346, 50.248067220663629],
              [-5.020228825854855, 50.248027200866005],
              [-5.020996310991647, 50.249403362807229],
              [-5.021306679688176, 50.248521806457589],
              [-5.021005716662823, 50.247778498696],
              [-5.02315699394395, 50.246481998206249],
              [-5.024839371999205, 50.244384463836838],
              [-5.025753185859269, 50.244061995284738],
              [-5.025667455322534, 50.243815948472346],
              [-5.026726692183987, 50.243176632073116],
              [-5.027968512295106, 50.242961638581804],
              [-5.031673153265348, 50.246297718020102],
              [-5.033292745950351, 50.249002701634922],
              [-5.034403209351629, 50.249654659010517],
              [-5.036485821091564, 50.249664980031767],
              [-5.038288337665346, 50.250013635550602],
              [-5.040335185324531, 50.251841056158121],
              [-5.040906702251544, 50.252751746957372],
              [-5.040192840461155, 50.254474227077893],
              [-5.043296670450337, 50.256282011884203],
              [-5.044746181016419, 50.257580771781086],
              [-5.045564708452811, 50.258973429330837],
              [-5.045770485488615, 50.260900308552671],
              [-5.046771992158333, 50.261926530628521],
              [-5.046948004210882, 50.263034680257938],
              [-5.047722864071684, 50.26358737073506],
              [-5.050112684259797, 50.26411293805652],
              [-5.048072623108145, 50.263284929972031],
              [-5.047100642588573, 50.262062737274334],
              [-5.049182286310273, 50.262005392266424],
              [-5.047049887882217, 50.26157560255777],
              [-5.046218346853566, 50.260859773475545],
              [-5.046223711923423, 50.259954665034179],
              [-5.047025449379003, 50.25945685606743],
              [-5.047085441470047, 50.25874821927188],
              [-5.04312419181347, 50.253290963033734],
              [-5.042538901152801, 50.252671205070136],
              [-5.041375279968884, 50.252332867808406],
              [-5.039560717951582, 50.249769832702206],
              [-5.039487961047413, 50.249133941963834],
              [-5.040581094457401, 50.248168866455742],
              [-5.04446390430474, 50.248250628288865],
              [-5.045793756947212, 50.248886829400206],
              [-5.046824713176753, 50.248993815366077],
              [-5.049009583432425, 50.248321151249812],
              [-5.050436822619506, 50.24826297102986],
              [-5.052493028409841, 50.248234116675214],
              [-5.051649794904656, 50.247969319817841],
              [-5.052416982524576, 50.247681079708919],
              [-5.050963245870153, 50.24776336966201],
              [-5.05224778878588, 50.24747747108465],
              [-5.04732631270632, 50.247687066871009],
              [-5.044399280141077, 50.247061290300223],
              [-5.044085791424235, 50.247315093116441],
              [-5.044064686997934, 50.246962114883146],
              [-5.042606909108379, 50.246870788848462],
              [-5.042160100774375, 50.246265391374038],
              [-5.041792295722295, 50.246459440338143],
              [-5.040121612198604, 50.245689987101152],
              [-5.037317622978265, 50.246223056547827],
              [-5.035400316772675, 50.246074394026451],
              [-5.03512432752259, 50.245771257533399],
              [-5.03578321745064, 50.244396579226986],
              [-5.035525725099521, 50.243722336984895],
              [-5.03195000246677, 50.242704803022164],
              [-5.029852790111864, 50.241707955917455],
              [-5.030380997171349, 50.240394291284176],
              [-5.031806997689323, 50.239811921406499],
              [-5.031259568382056, 50.238192596069233],
              [-5.030548936292576, 50.238244432567235],
              [-5.029322170546889, 50.239004201584031],
              [-5.027259477946472, 50.241203478908155],
              [-5.022549294029489, 50.243020988305588],
              [-5.021503944047294, 50.242971734881202],
              [-5.020050312497454, 50.242322286292833],
              [-5.017006798608512, 50.241684460187692],
              [-5.015964789447686, 50.24064374651887],
              [-5.016005279601026, 50.240063372924034],
              [-5.019404385250612, 50.237054121611152],
              [-5.02083288596025, 50.235135961472871],
              [-5.022282944798151, 50.234867931336431],
              [-5.024164600254984, 50.235081605162726],
              [-5.021142872439638, 50.234227426129657],
              [-5.021780283040312, 50.232841691924264],
              [-5.020736398625738, 50.232612481257625],
              [-5.019033612228766, 50.230922405699829],
              [-5.017789385656069, 50.226344462172761],
              [-5.018216591066444, 50.22564878317322],
              [-5.020597746543978, 50.225585050580811],
              [-5.024164999921267, 50.224765322043091],
              [-5.025011205393493, 50.224975363752883],
              [-5.027725216340897, 50.223593034163535],
              [-5.029307409368458, 50.223468996054663],
              [-5.029161458597379, 50.224351686264413],
              [-5.030558279081838, 50.225328146836759],
              [-5.03422031364856, 50.226555758341703],
              [-5.032792902125303, 50.227551102662531],
              [-5.032776134535979, 50.227927561764815],
              [-5.036243721578317, 50.227768552320299],
              [-5.037408496139276, 50.228245440994627],
              [-5.038273594371815, 50.22932387957519],
              [-5.038994909149134, 50.229552381550945],
              [-5.040805057942517, 50.229195527898717],
              [-5.044284209706015, 50.227544482617823],
              [-5.043516975463459, 50.227145398887551],
              [-5.039515257431007, 50.228385506311312],
              [-5.035787730206801, 50.226749567796716],
              [-5.033991106515897, 50.224801286016124],
              [-5.032162632474438, 50.224845461970759],
              [-5.031098221340824, 50.224312826188516],
              [-5.030888811571778, 50.223553657657988],
              [-5.031945443951749, 50.223146445448741],
              [-5.032646006426066, 50.223204609373809],
              [-5.041605421444765, 50.221018127382592],
              [-5.039456387117512, 50.22106811982092],
              [-5.037253675912955, 50.22049427335827],
              [-5.033420066142278, 50.222057220450836],
              [-5.030512109622177, 50.222389550887407],
              [-5.029553952789233, 50.222138392161845],
              [-5.028161287853092, 50.221115033868173],
              [-5.027703941855982, 50.218411155426253],
              [-5.027890608846386, 50.216975966573479],
              [-5.028475470599481, 50.216824873819697],
              [-5.028103672256915, 50.216510727213738],
              [-5.028524135684475, 50.214274215352077],
              [-5.028296879546939, 50.212833631263813],
              [-5.0273638287711, 50.211227044053643],
              [-5.028363204221019, 50.209964061992935],
              [-5.029607834442714, 50.209032909271329],
              [-5.030708979862415, 50.208868327752988],
              [-5.032604211400412, 50.209908211358588],
              [-5.032424540112186, 50.211804708948577],
              [-5.033110234787325, 50.212382311259361],
              [-5.038860302996233, 50.212664612583588],
              [-5.038960721085668, 50.211796592868723],
              [-5.038044272347574, 50.212000497624004],
              [-5.036472016135237, 50.211524382415938],
              [-5.035988650779251, 50.209652417843664],
              [-5.03673375495809, 50.208450885749087],
              [-5.037820207715592, 50.207790955009301],
              [-5.038427829224156, 50.206324036293466],
              [-5.040383302226575, 50.204717464722798],
              [-5.042888059314686, 50.204344171953849],
              [-5.043679349460446, 50.204598695002844],
              [-5.043866602626731, 50.205118237417821],
              [-5.04508497092987, 50.205850020238572],
              [-5.045774644229144, 50.206995076823695],
              [-5.047205175948077, 50.207799533489393],
              [-5.04748481611353, 50.208734048206438],
              [-5.048021175809621, 50.209033916955953],
              [-5.047652698411317, 50.206332269203187],
              [-5.045530362867322, 50.205118673553962],
              [-5.043825219657021, 50.203345358390884],
              [-5.04436781484627, 50.203018075315725],
              [-5.048228596590605, 50.202943733400147],
              [-5.050190705522794, 50.2023272524277],
              [-5.051663077560447, 50.200845627284082],
              [-5.053178237504061, 50.199991661977862],
              [-5.053723714077764, 50.198657630277893],
              [-5.05489600142367, 50.197922410751531],
              [-5.055780130189043, 50.195331738286441],
              [-5.058978870531893, 50.19326390165493],
              [-5.05969760434016, 50.193393386501789],
              [-5.059936089974769, 50.193923248613096],
              [-5.059063676828655, 50.194743274074334],
              [-5.057912054480382, 50.196917319485763],
              [-5.057802566142393, 50.198317250400059],
              [-5.058630402254268, 50.199406417489527],
              [-5.061168555332662, 50.200502658188014],
              [-5.063263461891273, 50.202160186323297],
              [-5.064889193616462, 50.202684003173303],
              [-5.064957866135402, 50.206772577052973],
              [-5.065313431787679, 50.207749126089446],
              [-5.066280613363946, 50.208164373475157],
              [-5.066486363705486, 50.208924479771355],
              [-5.067104984253677, 50.209390309221156],
              [-5.066544785415765, 50.209376324176908],
              [-5.066568948100852, 50.210474071783352],
              [-5.067536933427064, 50.209371697843565],
              [-5.068053986561521, 50.206725862547934],
              [-5.070087415363869, 50.205671762324911],
              [-5.074751772645631, 50.208412557706751],
              [-5.076141163297661, 50.208046497289459],
              [-5.079552092840772, 50.208185442137996],
              [-5.079510634724096, 50.20784650040008],
              [-5.079989585117247, 50.208122557676198],
              [-5.079735741959758, 50.208207556867755],
              [-5.080931490782521, 50.208382727027555],
              [-5.08112606416426, 50.208857039026952],
              [-5.081264190375617, 50.20860778661357],
              [-5.081721634062847, 50.209158780113555],
              [-5.08246139417557, 50.209348739270098],
              [-5.081472875196821, 50.209213961903295],
              [-5.081878089305852, 50.20949826614968],
              [-5.082923286912146, 50.209772769001027],
              [-5.085462247806477, 50.20962614130589],
              [-5.085443194303892, 50.209439534753912],
              [-5.084106849898022, 50.209497540010062],
              [-5.084926647851479, 50.209320126598975],
              [-5.084924903264241, 50.209010716793024],
              [-5.085663827727068, 50.209122414175276],
              [-5.08485461495676, 50.208790388169952],
              [-5.084918034863417, 50.208535020494232],
              [-5.088599666433584, 50.209413157972506],
              [-5.088811958279389, 50.209227592241355],
              [-5.091818880482515, 50.209338245620835],
              [-5.091911168768775, 50.209854846594418],
              [-5.092489018900904, 50.209968092577682],
              [-5.09210148104793, 50.20937299787925],
              [-5.093065508672833, 50.209388692975672],
              [-5.093147936562526, 50.209731036714565],
              [-5.093782378904922, 50.209397474437012],
              [-5.095242708529502, 50.209739064633631],
              [-5.09735984403378, 50.211282047234569],
              [-5.095577369804305, 50.209495348424738],
              [-5.093783035851344, 50.209039422791321],
              [-5.095518692480102, 50.20893557321704],
              [-5.098081761550726, 50.210741022005067],
              [-5.098414466736433, 50.212348693085886],
              [-5.098032205163379, 50.211706698434803],
              [-5.097116819808243, 50.212117948078493],
              [-5.097316892641403, 50.212912339068012],
              [-5.09857124789572, 50.212474947492275],
              [-5.098601911817161, 50.211974859972102],
              [-5.097940426697934, 50.210225734518325],
              [-5.096810442294862, 50.209790803856556],
              [-5.096059372964763, 50.208842888450306],
              [-5.090570719824974, 50.207442781249014],
              [-5.087481401799143, 50.207725606636153],
              [-5.087408856777699, 50.207427075801185],
              [-5.091956731420145, 50.207373482431905],
              [-5.097048125879199, 50.208331634279219],
              [-5.09656330946266, 50.208031514410536],
              [-5.097258456654018, 50.208138008818999],
              [-5.099536089137278, 50.209799912055601],
              [-5.100621303167525, 50.213347673459509],
              [-5.099710544450978, 50.209766466557653],
              [-5.097519897587174, 50.20810584368796],
              [-5.099741968874395, 50.209083742560352],
              [-5.101327811083846, 50.209241987493975],
              [-5.104740128070221, 50.208471574529398],
              [-5.105676884080424, 50.207895067473075],
              [-5.107221922614977, 50.207706183844934],
              [-5.108409802449771, 50.20713372621757],
              [-5.109858368949967, 50.207380091582749],
              [-5.111120892606682, 50.207179833658515],
              [-5.111422597015082, 50.207098879725073],
              [-5.11404747478838, 50.20642396213848],
              [-5.114656421104849, 50.206605531616503],
              [-5.114245408311259, 50.206239953013736],
              [-5.115416718602935, 50.205323905486807],
              [-5.115982401271917, 50.205074979440504],
              [-5.116919074706457, 50.205148631437069],
              [-5.117192164221949, 50.205188075841761],
              [-5.117731436317348, 50.204967585661379],
              [-5.11822164133805, 50.204252738449419],
              [-5.117200939070251, 50.205086186805758],
              [-5.116805180526204, 50.205021105020727],
              [-5.116155029578174, 50.204845295952097],
              [-5.115834589305318, 50.204982539788674],
              [-5.114219619490063, 50.206150191522511],
              [-5.11417774820235, 50.206179771611666],
              [-5.113785182981211, 50.206464283828041],
              [-5.109997697676753, 50.207259412200074],
              [-5.108278903480589, 50.207039176928681],
              [-5.107045024627372, 50.207637153406715],
              [-5.105888750691563, 50.207682493654204],
              [-5.10325324217857, 50.20862828411552],
              [-5.100830417020879, 50.209051070989666],
              [-5.099797430345299, 50.208923034988011],
              [-5.0979560369143, 50.207914286806123],
              [-5.09560887232042, 50.20766565477885],
              [-5.094951541408506, 50.207103852455624],
              [-5.09300481717285, 50.206500848807046],
              [-5.088026911078621, 50.207143457970524],
              [-5.084499088732699, 50.20647615785569],
              [-5.083616998575684, 50.205663043128403],
              [-5.081170247647245, 50.20641080364603],
              [-5.079248049291209, 50.206446521832085],
              [-5.076263961820606, 50.205598182045513],
              [-5.075424550582402, 50.2046857621865],
              [-5.075613204154926, 50.20262881883729],
              [-5.073876133581235, 50.201639418491837],
              [-5.073753436619005, 50.199861497304973],
              [-5.072276274490663, 50.199730587190182],
              [-5.07054109046163, 50.198876924693366],
              [-5.069139400479948, 50.198742179045567],
              [-5.066068572788992, 50.196814579468189],
              [-5.065933697282931, 50.196219021908171],
              [-5.064265809944493, 50.19652124902111],
              [-5.063808650870428, 50.196363297382405],
              [-5.063982788225545, 50.196128407847098],
              [-5.06342780730156, 50.196193434274235],
              [-5.064054996865292, 50.195658719844417],
              [-5.064150747186777, 50.194987803824795],
              [-5.063318187917762, 50.194394468564631],
              [-5.063311071210633, 50.193517565613682],
              [-5.062678704687622, 50.192723732881234],
              [-5.060334839753924, 50.191263471772444],
              [-5.05824965512368, 50.190471932455317],
              [-5.056431903188432, 50.190180676384593],
              [-5.05599224770882, 50.188825792527453],
              [-5.054915823401984, 50.187833108889805],
              [-5.054345466035629, 50.186580613074],
              [-5.054815313614223, 50.184679128770767],
              [-5.054051929425508, 50.181784580875409],
              [-5.054350166673832, 50.180245646159598],
              [-5.055349498246269, 50.179704780133065],
              [-5.057986874204332, 50.178885962466119],
              [-5.060756633738597, 50.178473801691261],
              [-5.063427533380878, 50.179319100971234],
              [-5.065560034249166, 50.179609127401307],
              [-5.066763651764124, 50.1803563646653],
              [-5.066652274991156, 50.180673262113139],
              [-5.066952835285376, 50.180532181141473],
              [-5.069023896946937, 50.182112869895704],
              [-5.071627542309561, 50.182537856843815],
              [-5.077291571100012, 50.184257137924511],
              [-5.075568142645102, 50.183273701149588],
              [-5.075208559049068, 50.182474504789418],
              [-5.0720521807991, 50.181012618604761],
              [-5.071442795923413, 50.180314479637751],
              [-5.069519419288734, 50.179245388515263],
              [-5.064962476882783, 50.178350202962285],
              [-5.065011987130199, 50.177745275587483],
              [-5.063295636805727, 50.178352833284038],
              [-5.061653770931, 50.177672897726886],
              [-5.058430259379423, 50.177318901944425],
              [-5.057841149830066, 50.177460367749788],
              [-5.057653170027772, 50.177938500565382],
              [-5.055154012674874, 50.177959342545215],
              [-5.055067517257717, 50.178206305618694],
              [-5.05582031360536, 50.178412280080778],
              [-5.054579204315473, 50.178390979693397],
              [-5.053806329710356, 50.178221508797307],
              [-5.054642879054591, 50.178071751660987],
              [-5.053264195400202, 50.177145482013401],
              [-5.052796731456199, 50.177129893200089],
              [-5.052680074394202, 50.177826538546029],
              [-5.052531774060367, 50.1773023857628],
              [-5.050243893963049, 50.177296874147487],
              [-5.04857899656163, 50.176515707879112],
              [-5.045566065850231, 50.175769903827657],
              [-5.044787582004289, 50.175092707863271],
              [-5.044539981746912, 50.174129007381183],
              [-5.045681008867236, 50.172271119833951],
              [-5.045463677816517, 50.170792516448842],
              [-5.046352014030587, 50.168773030481383],
              [-5.052510822693059, 50.163465577304272],
              [-5.053535059886602, 50.163188545166847],
              [-5.05314093099391, 50.162981217120254],
              [-5.053471884285806, 50.162417466428558],
              [-5.056151640291543, 50.161311498814527],
              [-5.05748295247415, 50.161436552903005],
              [-5.058531579802071, 50.162222140604136],
              [-5.059798627804388, 50.162151853462539],
              [-5.060035747181465, 50.162531521797732],
              [-5.06086641761528, 50.16225053293924],
              [-5.062354133931696, 50.162346217206625],
              [-5.063851464541245, 50.162044912055563],
              [-5.064694049470163, 50.16230063258984],
              [-5.069261502768, 50.161745409570777],
              [-5.069666625851841, 50.162975220340229],
              [-5.070552978708704, 50.163104698476197],
              [-5.070008606116406, 50.163372785272024],
              [-5.070693982052933, 50.164167874730452],
              [-5.070484527346788, 50.164461284445828],
              [-5.071821429452159, 50.165086196015743],
              [-5.072637366546194, 50.164925157499702],
              [-5.072302426001662, 50.165161621105682],
              [-5.074757361964528, 50.165705615568882],
              [-5.07506721393164, 50.166888455501336],
              [-5.075494717249367, 50.167024657705767],
              [-5.074696390272345, 50.167482112519323],
              [-5.075268055032766, 50.167289745442446],
              [-5.076449875405376, 50.167482415560883],
              [-5.078601663962431, 50.166837917626331],
              [-5.079292279732206, 50.166927543240867],
              [-5.079884716577671, 50.167167302726547],
              [-5.079913904285712, 50.168034628277439],
              [-5.080718693537674, 50.16885347791564],
              [-5.0810298681005, 50.167551611736982],
              [-5.082785332656274, 50.167060592302633],
              [-5.084708595889437, 50.166957305127916],
              [-5.08698720567253, 50.168194812983323],
              [-5.090319416833048, 50.169197317499844],
              [-5.090625944548987, 50.170018573131102],
              [-5.091436088741212, 50.17059161997512],
              [-5.092118711961573, 50.169732318049107],
              [-5.091932559299377, 50.168563316749768],
              [-5.093751449321871, 50.167559474727092],
              [-5.096498264849131, 50.168301234828625],
              [-5.097820174520084, 50.169228511523507],
              [-5.099157752263893, 50.16968307106832],
              [-5.100111536210084, 50.169747552505378],
              [-5.100666298580002, 50.169403480656179],
              [-5.102164119124502, 50.169801547663326],
              [-5.099744725300935, 50.168949521119067],
              [-5.097197887326351, 50.167468435759588],
              [-5.097860101575866, 50.167269941225506],
              [-5.097108224525948, 50.167186559006211],
              [-5.097114164524938, 50.16686704657036],
              [-5.100866767536813, 50.166335923111163],
              [-5.09940957747084, 50.166195836476319],
              [-5.094927123092307, 50.166637531303031],
              [-5.09277268048118, 50.166348632853079],
              [-5.091286471369219, 50.166105765131284],
              [-5.089522333248288, 50.16522347536835],
              [-5.088349103523495, 50.165142258723634],
              [-5.086793975176328, 50.164177000475547],
              [-5.085977688096588, 50.163223560065987],
              [-5.0853156595606, 50.16340309058441],
              [-5.085698716394345, 50.162697523844855],
              [-5.083269294692865, 50.162984301471788],
              [-5.082702571465794, 50.164100452969322],
              [-5.081736845137148, 50.164413972763569],
              [-5.081911250586808, 50.164882523153736],
              [-5.0808002281688, 50.165071253049781],
              [-5.080611308216067, 50.164529320352962],
              [-5.077072516943674, 50.163267540470095],
              [-5.076735297778663, 50.162705245468317],
              [-5.074030942464343, 50.161131579171681],
              [-5.073519269696138, 50.161428502884313],
              [-5.07400381698537, 50.160403633437049],
              [-5.073452270141959, 50.159555541227043],
              [-5.0735720919913, 50.159022511381984],
              [-5.07325562191347, 50.158716938432853],
              [-5.072390567825444, 50.158721855374125],
              [-5.072792665580352, 50.158419741929173],
              [-5.070602906661103, 50.157004189740704],
              [-5.070517487579665, 50.156501782707899],
              [-5.069808940848326, 50.156697749010107],
              [-5.07033299343101, 50.15624488539931],
              [-5.068271151907443, 50.155315279643304],
              [-5.067757656397954, 50.155517770089887],
              [-5.067136915123631, 50.154526644852439],
              [-5.067632156959908, 50.154345330127953],
              [-5.067012585210039, 50.153656433987102],
              [-5.065978746874481, 50.153013565857663],
              [-5.06565330071464, 50.153441371761623],
              [-5.065007082302998, 50.152904299487076],
              [-5.065368335802184, 50.153035990979795],
              [-5.065778513585808, 50.152750780287171],
              [-5.065218602825616, 50.152358960681632],
              [-5.064657584670139, 50.152649952802463],
              [-5.064739464617464, 50.152988732843284],
              [-5.064374054924178, 50.152616959962224],
              [-5.064884852750946, 50.152350685013708],
              [-5.06447776427119, 50.151962021692526],
              [-5.063770904348859, 50.152468263804366],
              [-5.063241264582176, 50.152111618868162],
              [-5.063687003093908, 50.151856962224677],
              [-5.063402675556127, 50.151680054509306],
              [-5.062808165998163, 50.151972821501019],
              [-5.062326884419392, 50.151605900091091],
              [-5.061135875708148, 50.151810048429233],
              [-5.060532438829697, 50.15137167686872],
              [-5.060102120284923, 50.151867902851514],
              [-5.059012226842087, 50.151507122182934],
              [-5.0586496694853, 50.151836932211125],
              [-5.059948987958617, 50.153525377533143],
              [-5.056477577653395, 50.153780597997184],
              [-5.056583146385587, 50.15554459938275],
              [-5.056061918027974, 50.155626697097645],
              [-5.055868683934812, 50.153673392943475],
              [-5.054765048360067, 50.153733939349195],
              [-5.0541955748787, 50.153476356853474],
              [-5.054064889274909, 50.153688500090603],
              [-5.052227391056443, 50.153789926136206],
              [-5.052165843325779, 50.153263489854297],
              [-5.050474378569086, 50.153349352142314],
              [-5.049211217086682, 50.155720569256133],
              [-5.050087899414477, 50.153106729364829],
              [-5.047484095857284, 50.152078555149977],
              [-5.047326071338025, 50.150254753512833],
              [-5.044901200379014, 50.149681509674096],
              [-5.044419912324518, 50.148540874291768],
              [-5.044805216255631, 50.148061181597164],
              [-5.04413372454613, 50.147892587508991],
              [-5.044257812117069, 50.147223640682988],
              [-5.043429745165351, 50.146881932907299],
              [-5.043669635474302, 50.145788044751924],
              [-5.043169023596741, 50.145752598668324],
              [-5.042909680723684, 50.145129692694425],
              [-5.043373778054293, 50.144898921560667],
              [-5.042651509188222, 50.144899873068169],
              [-5.043145467198646, 50.144632336446207],
              [-5.042564466012947, 50.144675460836538],
              [-5.041813300792067, 50.14400067877709],
              [-5.042622807406877, 50.143544054957353],
              [-5.043712368422038, 50.143485790764927],
              [-5.043491765008716, 50.143737163917116],
              [-5.044107835009129, 50.143761482991458],
              [-5.044497347967079, 50.144141681753233],
              [-5.044147775526086, 50.144434222728499],
              [-5.045963486845146, 50.144259728107194],
              [-5.046001296775571, 50.144612271691052],
              [-5.046800689112007, 50.144723521109725],
              [-5.047552168183537, 50.144499579360165],
              [-5.048567852193808, 50.145018044024766],
              [-5.049132228407731, 50.145483591172166],
              [-5.048900791983081, 50.145608418126571],
              [-5.049872441390636, 50.145874346132956],
              [-5.050566378308546, 50.147209076826762],
              [-5.051588255048312, 50.148043106611119],
              [-5.05314806053062, 50.148464458062932],
              [-5.055012927040678, 50.148334407982588],
              [-5.057032003041884, 50.147900703198893],
              [-5.061080949888813, 50.146152208381025],
              [-5.067322338504248, 50.144674876491692],
              [-5.068323474239137, 50.144132042195203],
              [-5.069719989223478, 50.142397581043397],
              [-5.071380463518112, 50.141507124083041],
              [-5.074344995905228, 50.140631571725272],
              [-5.075634614493503, 50.140922142789023],
              [-5.076087590145487, 50.140739211923865],
              [-5.076828183615877, 50.140087163172794],
              [-5.076488554308844, 50.139658969758919],
              [-5.076724863632264, 50.139306361711981],
              [-5.076854396325252, 50.137181696707941],
              [-5.076320190538174, 50.13707981612793],
              [-5.075552172265737, 50.136104334020132],
              [-5.074958332133981, 50.136054405101156],
              [-5.075319652874887, 50.135839721661348],
              [-5.075002092825965, 50.13555847153107],
              [-5.073734080502325, 50.135471528551882],
              [-5.07374273571412, 50.134995416964927],
              [-5.073278878179694, 50.134986115510962],
              [-5.073741270613187, 50.134841626156117],
              [-5.072886263534514, 50.134341615015394],
              [-5.075023599258088, 50.133603095286261],
              [-5.077006253706961, 50.13342098596285],
              [-5.07642134264536, 50.133204403984081],
              [-5.076952470064354, 50.133040986653143],
              [-5.079264862071069, 50.132872582249398],
              [-5.079964008939137, 50.132449207773902],
              [-5.080361842116043, 50.131496774719331],
              [-5.080843862213013, 50.131549645136808],
              [-5.082060156949947, 50.130615949208412],
              [-5.081430923588533, 50.13003623603165],
              [-5.081663191371699, 50.129469621692252],
              [-5.082345762171172, 50.129333641752091],
              [-5.081918316385369, 50.129258637353821],
              [-5.082048156739923, 50.12897091740215],
              [-5.08365140499996, 50.128702515416386],
              [-5.085353038139385, 50.12785393712155],
              [-5.085423761401, 50.128104841224129],
              [-5.085969666146366, 50.127628830618676],
              [-5.087744409550363, 50.127286540761872],
              [-5.08822390157303, 50.126583790637227],
              [-5.090303158980293, 50.126776707761827],
              [-5.093603959067511, 50.12607880785032],
              [-5.093052074598279, 50.1246820633298],
              [-5.092629073518846, 50.124436957122221],
              [-5.091313296044452, 50.124581777524625],
              [-5.090569163656097, 50.12460790180635],
              [-5.090566499331032, 50.124240939187693],
              [-5.089756099954539, 50.124367778842227],
              [-5.089418987980412, 50.124065498305541],
              [-5.089660113927199, 50.123768508248702],
              [-5.089229170265964, 50.123660339335721],
              [-5.090146616351306, 50.12330575376388],
              [-5.090420092783213, 50.122360193796233],
              [-5.089601768121055, 50.121820645919613],
              [-5.089846156661785, 50.121639615813692],
              [-5.089223846414091, 50.121449283576816],
              [-5.089696980516311, 50.120735900410423],
              [-5.08883749776263, 50.120469120428758],
              [-5.090180159414604, 50.119570652063359],
              [-5.090819053835764, 50.119541036542579],
              [-5.090685869559469, 50.119298096679195],
              [-5.091294628150423, 50.118887854045887],
              [-5.090938144240341, 50.118154289608825],
              [-5.090094979757413, 50.118248720676284],
              [-5.090408025988171, 50.118047868332724],
              [-5.089614183496501, 50.117908886982072],
              [-5.090846864138379, 50.117910233339344],
              [-5.089861534411619, 50.117535264834771],
              [-5.091201917619667, 50.116711510405899],
              [-5.091235717879986, 50.114392357900357],
              [-5.090449866557414, 50.114202792501324],
              [-5.090306054016607, 50.113794610170885],
              [-5.089158768400875, 50.11383596443364],
              [-5.089429860952628, 50.113570561387348],
              [-5.089058739291707, 50.113542662137348],
              [-5.088805454046922, 50.112781154372492],
              [-5.087603655793917, 50.112714194637192],
              [-5.087687980912568, 50.112415983944331],
              [-5.086643288035809, 50.111985894715588],
              [-5.086581841923836, 50.111465765840613],
              [-5.085481303170803, 50.11131962449204],
              [-5.084565489938556, 50.11169662160183],
              [-5.082665404598503, 50.110994173791518],
              [-5.082701469509155, 50.110684655122931],
              [-5.081632085448135, 50.110543946608914],
              [-5.081453580066073, 50.109899183074219],
              [-5.081918794643576, 50.109323678714908],
              [-5.083370230125586, 50.109053910619359],
              [-5.084629688818096, 50.1095187994501],
              [-5.085376667594205, 50.109388282913329],
              [-5.091277914874218, 50.10297718460167],
              [-5.093515143016019, 50.103011095953818],
              [-5.095356234584713, 50.102454606631014],
              [-5.099408010656826, 50.099681804155004],
              [-5.09988375193827, 50.099642102517471],
              [-5.100350925342769, 50.100032634999863],
              [-5.10142601811406, 50.099636857277567],
              [-5.102456673907151, 50.099960136209148],
              [-5.102711984578384, 50.100512855925942],
              [-5.103272364506938, 50.100549141182576],
              [-5.103103778676911, 50.101056526152171],
              [-5.104002653395124, 50.101374321191727],
              [-5.104733708058292, 50.102405483871955],
              [-5.105806808840822, 50.102518890913949],
              [-5.106211430825455, 50.102309244792693],
              [-5.107685934047514, 50.103027208500365],
              [-5.111259236365692, 50.104015429107889],
              [-5.113050459189576, 50.103404219852756],
              [-5.11313513591308, 50.103114075832352],
              [-5.114243153765965, 50.103053744982084],
              [-5.115241554245099, 50.103739415817842],
              [-5.115821107877289, 50.103704057496877],
              [-5.118056126245812, 50.102433136543318],
              [-5.120045258818045, 50.102200626109244],
              [-5.120637758704808, 50.101461408668207],
              [-5.121569084081298, 50.101093600141553],
              [-5.121440318194965, 50.100124598554309],
              [-5.124146514454231, 50.099092763452582],
              [-5.125073536016886, 50.099110970463549],
              [-5.125270037422247, 50.099443925469537],
              [-5.127382617348733, 50.099798998757954],
              [-5.128043337399236, 50.100237278916758],
              [-5.130077166577776, 50.099598569172819],
              [-5.131292273854673, 50.099637738824718],
              [-5.133626705072903, 50.100091062786362],
              [-5.135057911783882, 50.100718991007788],
              [-5.136225184159207, 50.10047512798355],
              [-5.137189645736123, 50.100701833124965],
              [-5.13800026166756, 50.100286778501399],
              [-5.138985361481385, 50.100486821895807],
              [-5.140332412103986, 50.100148091401209],
              [-5.138917338539771, 50.101007733249432],
              [-5.137984233803892, 50.102415670235757],
              [-5.138117450052771, 50.103725484553252],
              [-5.139438385217234, 50.103606075210287],
              [-5.140042386397389, 50.104158273478667],
              [-5.139383882458222, 50.10595820977489],
              [-5.135014770410096, 50.108407248463116],
              [-5.131860286967189, 50.108962056409318],
              [-5.130154727128578, 50.110263936545444],
              [-5.134416102992569, 50.109520940387178],
              [-5.13743949738759, 50.111239301794683],
              [-5.137779196451373, 50.111727592151468],
              [-5.137650815869748, 50.110748700916183],
              [-5.136154927356306, 50.109735723355328],
              [-5.139836665848169, 50.107512160115547],
              [-5.141043331176431, 50.106137283674578],
              [-5.142817812337707, 50.106235851270561],
              [-5.143271228199898, 50.106986427295006],
              [-5.144928040411978, 50.107231188343164],
              [-5.144117914745591, 50.106971575702701],
              [-5.143027953541496, 50.105749769565172],
              [-5.141399786662935, 50.105364767664987],
              [-5.1415216905593, 50.104299935551801],
              [-5.141026193589907, 50.103183454789118],
              [-5.141661398765449, 50.102908964851657],
              [-5.140650889301096, 50.102471237243506],
              [-5.143557481826903, 50.098887643963998],
              [-5.145501061429287, 50.097964133699506],
              [-5.14685117472276, 50.097823156074924],
              [-5.148239097877825, 50.098045475892526],
              [-5.149789289787202, 50.098971360833261],
              [-5.151699896886512, 50.099165949590251],
              [-5.15377707254147, 50.098383505222145],
              [-5.154204768335941, 50.097805123582027],
              [-5.155818605242885, 50.096974968073646],
              [-5.158870156246897, 50.096593226718511],
              [-5.163102020017591, 50.096763964162022],
              [-5.164428629245512, 50.09775242797555],
              [-5.164438093416694, 50.100038069172278],
              [-5.163261263633853, 50.100834836469105],
              [-5.163149290865929, 50.101915617990819],
              [-5.161211661995969, 50.103733470867255],
              [-5.161354745120981, 50.104971026136695],
              [-5.159226921056481, 50.108109249117511],
              [-5.159607324527073, 50.109424904230025],
              [-5.163602607804402, 50.112778581128197],
              [-5.165861246228229, 50.112344519907452],
              [-5.163993659107559, 50.112111203407864],
              [-5.161491872272454, 50.109429328635528],
              [-5.161306634342989, 50.10865621943524],
              [-5.161832264791976, 50.107993277039128],
              [-5.161540761824769, 50.107560416630996],
              [-5.162419522873187, 50.106737611319531],
              [-5.16283263804498, 50.104007739813383],
              [-5.164164599730934, 50.103271517467768],
              [-5.164553236729716, 50.10254752708628],
              [-5.165800834866085, 50.102441512068751],
              [-5.167406803169059, 50.103632825268242],
              [-5.169165835965506, 50.10567905847558],
              [-5.170113474426164, 50.105795299531799],
              [-5.169204373132874, 50.103954359793285],
              [-5.167803274621818, 50.102413826200376],
              [-5.164958031721288, 50.10147494680028],
              [-5.166499718919956, 50.100209402422038],
              [-5.169231328480791, 50.099788444510899],
              [-5.166513288853102, 50.099355305422328],
              [-5.166369798988814, 50.097434964392058],
              [-5.167229399689052, 50.096855536331269],
              [-5.16713395413442, 50.096574766589605],
              [-5.166171512329873, 50.096422022827468],
              [-5.164083679556314, 50.095171872155028],
              [-5.15894028258505, 50.094790306053461],
              [-5.158430411843933, 50.094288718238978],
              [-5.15909433086262, 50.09381633495375],
              [-5.159530790851991, 50.093648813712605],
              [-5.161756512121475, 50.093938105114923],
              [-5.165714105709279, 50.093494593632258],
              [-5.171381370540994, 50.091752853352723],
              [-5.17287759956677, 50.090991342792783],
              [-5.173662658527381, 50.091000449014082],
              [-5.174585416896487, 50.092097905581134],
              [-5.174264490253911, 50.090522479108294],
              [-5.174678384849361, 50.090289846215086],
              [-5.175789664712966, 50.090812680291826],
              [-5.178364655063236, 50.090865386122104],
              [-5.181358725163107, 50.08989897659329],
              [-5.183932464867882, 50.089553964864059],
              [-5.184640213820218, 50.08978912191008],
              [-5.184816582585751, 50.090951073234123],
              [-5.185522181451321, 50.089931327616348],
              [-5.18696590843005, 50.089499432576027],
              [-5.19052410935625, 50.089998040092723],
              [-5.192063492591529, 50.090626790248344],
              [-5.195925727708145, 50.089851110146398],
              [-5.197209816593408, 50.089983944188972],
              [-5.199387903989384, 50.091865240619548],
              [-5.199006537530347, 50.093906185570567],
              [-5.200727937923411, 50.091871446621809],
              [-5.20347547780448, 50.091532898535164],
              [-5.206793790803513, 50.095573115149726],
              [-5.208274718167868, 50.096492944436811],
              [-5.20699460649417, 50.094706632479372],
              [-5.207573549098822, 50.094413541007818],
              [-5.208506152154539, 50.094641434918451],
              [-5.20954188737236, 50.094508421081379],
              [-5.211000651525282, 50.094135184366273],
              [-5.211041854711261, 50.093873155511403],
              [-5.208938941088301, 50.093689364589366],
              [-5.206121611756674, 50.092921647303008],
              [-5.204009630922852, 50.09086232466283],
              [-5.202834695422732, 50.090773331115294],
              [-5.203855135535627, 50.090202678270401],
              [-5.206933668279275, 50.090215647710046],
              [-5.208539326858281, 50.088569023277756],
              [-5.206277691628378, 50.089982793087138],
              [-5.202309569224489, 50.089902613165449],
              [-5.202934974875494, 50.090452998230532],
              [-5.202411167655431, 50.091006331724117],
              [-5.20154168181853, 50.091157183292808],
              [-5.199778729647273, 50.090658873177581],
              [-5.199369354823945, 50.089488976063485],
              [-5.198721517563426, 50.089230665308193],
              [-5.196168881228175, 50.089302842671252],
              [-5.192502557178539, 50.090437481129484],
              [-5.189717174004531, 50.089280756397713],
              [-5.188294191445654, 50.087813936488871],
              [-5.185933798611134, 50.088169378486441],
              [-5.18676648012294, 50.086368869277031],
              [-5.187712018318869, 50.085911069582494],
              [-5.189537938752836, 50.085951726384053],
              [-5.190430674878629, 50.084552563287822],
              [-5.193483477517802, 50.083721859167788],
              [-5.194459398355828, 50.082755785109654],
              [-5.192907168781369, 50.081530978667423],
              [-5.192074846073422, 50.080051539151228],
              [-5.192519161511763, 50.081372533847571],
              [-5.193446667823435, 50.082327573940908],
              [-5.192854597787847, 50.083200307482464],
              [-5.190900358472569, 50.083793862604857],
              [-5.187495720496266, 50.085896323757375],
              [-5.186325613006944, 50.085898791541098],
              [-5.185714303088102, 50.087432329702771],
              [-5.184758214028262, 50.087942579751676],
              [-5.17869613853351, 50.089684110138101],
              [-5.179742325240408, 50.087492769057476],
              [-5.178825952581002, 50.085921982928078],
              [-5.178702752919071, 50.087186612711236],
              [-5.177343312556723, 50.089356839695853],
              [-5.176804289568459, 50.089388698666113],
              [-5.175387900898461, 50.088586353893],
              [-5.173615570975238, 50.088596184088068],
              [-5.170467164988747, 50.090550783521699],
              [-5.168244504772974, 50.091047833775818],
              [-5.16722680495766, 50.090225501858853],
              [-5.166352388955749, 50.091256938727213],
              [-5.163191760545947, 50.091823576393629],
              [-5.162832622447397, 50.091572485144738],
              [-5.16042674219971, 50.091369988949538],
              [-5.159223462381178, 50.091826487281544],
              [-5.156268744685814, 50.092187613326793],
              [-5.155512158762063, 50.092952174748099],
              [-5.154661883630166, 50.093206504347748],
              [-5.152225811239149, 50.092672704429326],
              [-5.152040347463092, 50.091850108452661],
              [-5.150808870783679, 50.090962375550603],
              [-5.15052026891298, 50.090549200668548],
              [-5.150855118098897, 50.090239635870319],
              [-5.149570061588789, 50.08898180892885],
              [-5.149342923886914, 50.088183730229609],
              [-5.149925082529881, 50.087914231371741],
              [-5.148855453303683, 50.087978362627865],
              [-5.148533999537638, 50.085827138209687],
              [-5.14747235833889, 50.085351277164719],
              [-5.148218483876653, 50.086580576686096],
              [-5.148339647103973, 50.088748034348981],
              [-5.149409237378474, 50.090345479391402],
              [-5.149540489740335, 50.091429537301295],
              [-5.150519369267937, 50.092197301298292],
              [-5.15037292722245, 50.093353672941447],
              [-5.150885664779315, 50.094113404173157],
              [-5.147662826346684, 50.095336216661984],
              [-5.1429623054754, 50.095907195912559],
              [-5.141280648383372, 50.096430443938488],
              [-5.139810000689594, 50.096524242203188],
              [-5.138627535274407, 50.096191908325089],
              [-5.137919757210257, 50.095141432247082],
              [-5.138544207777164, 50.094639649900088],
              [-5.138140632705428, 50.094220560243514],
              [-5.134700318948348, 50.095565841669199],
              [-5.13375084545368, 50.095416976990158],
              [-5.133848645078989, 50.094901561349296],
              [-5.135573712574142, 50.093308520154096],
              [-5.1355551533605, 50.091097798886999],
              [-5.135112343320552, 50.092432185434006],
              [-5.134323094189221, 50.093130018398163],
              [-5.132036525474982, 50.092933604019002],
              [-5.131168518805209, 50.09323951626979],
              [-5.131413705363061, 50.093418214555747],
              [-5.130356415803011, 50.093587993542251],
              [-5.128569898306562, 50.094863254687063],
              [-5.124978978614432, 50.09628427515635],
              [-5.124280193505235, 50.096054811052454],
              [-5.120488606096369, 50.096307117961921],
              [-5.117268153452931, 50.096051870713353],
              [-5.115915951378512, 50.095019482016703],
              [-5.115049209436881, 50.095433190159341],
              [-5.114190294332452, 50.09471768483921],
              [-5.113241003816263, 50.095130897033549],
              [-5.11314483147469, 50.094877092815587],
              [-5.112485582198198, 50.094975750067036],
              [-5.112250093014344, 50.094535868404819],
              [-5.111565607142829, 50.094719759908664],
              [-5.111308261138173, 50.094352430657523],
              [-5.110065376880205, 50.094378568474298],
              [-5.110014822308695, 50.094029980291701],
              [-5.109547930729208, 50.094140555090434],
              [-5.109845266220696, 50.093830317902302],
              [-5.108930735700646, 50.09344192012707],
              [-5.106987592906614, 50.094358504120791],
              [-5.104854173459098, 50.094518189491609],
              [-5.103490131525946, 50.093384320468715],
              [-5.10253523247327, 50.093452149140745],
              [-5.10229285007091, 50.093098793691375],
              [-5.102661652323816, 50.092790261996569],
              [-5.100874961386491, 50.092147153834077],
              [-5.100303917024207, 50.091164766504093],
              [-5.099337009114004, 50.090981002949249],
              [-5.098957701848478, 50.090585420203226],
              [-5.097215681213533, 50.090697623910515],
              [-5.095907961333006, 50.090324121774131],
              [-5.095921064178603, 50.090050294599969],
              [-5.094217715737909, 50.090588729347751],
              [-5.092998973715622, 50.090315374695621],
              [-5.09247771741209, 50.089516939089791],
              [-5.093090629847073, 50.0896328355051],
              [-5.092519512341922, 50.088906797935088],
              [-5.092943690059126, 50.088155118816431],
              [-5.094849777889768, 50.087964833768758],
              [-5.101739601745844, 50.089167955940518],
              [-5.102648012152398, 50.088620091896551],
              [-5.103262979870248, 50.087231751584888],
              [-5.103942184768872, 50.087356611926786],
              [-5.107225546492018, 50.086606587023368],
              [-5.108836716879, 50.085836533561398],
              [-5.110400715399489, 50.08444699865121],
              [-5.111510356922428, 50.084677217523407],
              [-5.112841011309321, 50.084348239987634],
              [-5.114640098698644, 50.084796516570847],
              [-5.115734874669855, 50.084560201841043],
              [-5.117841851690335, 50.082321145786402],
              [-5.117009096613859, 50.082113232964048],
              [-5.116134764488476, 50.082686385155576],
              [-5.11514641716167, 50.082644567621301],
              [-5.11521802829856, 50.083338934815444],
              [-5.11401289193404, 50.084050498329951],
              [-5.109282535838866, 50.08360976849486],
              [-5.107291508008699, 50.08486049739259],
              [-5.102909830303063, 50.086483738764059],
              [-5.103118108182877, 50.086785828795826],
              [-5.101878550892705, 50.086817188896092],
              [-5.101022813057106, 50.087270980300943],
              [-5.0993389471765, 50.086592714915973],
              [-5.099637604374782, 50.086475881992342],
              [-5.098966188030685, 50.085388218939002],
              [-5.096740643837953, 50.085631183383569],
              [-5.095342253869902, 50.08517193622194],
              [-5.094582543714756, 50.085467486630129],
              [-5.095072288747249, 50.085994176115946],
              [-5.094754004864343, 50.08617539232111],
              [-5.094235837478975, 50.086162230096136],
              [-5.094120959347517, 50.085854934000267],
              [-5.093367586432972, 50.085923608796278],
              [-5.093271835536816, 50.085652685201794],
              [-5.092462724986179, 50.085963033040422],
              [-5.091133855517843, 50.085823037555102],
              [-5.090240964760046, 50.086178783309123],
              [-5.08965102952266, 50.085745602166739],
              [-5.088869160598468, 50.085936452282574],
              [-5.087229490237299, 50.085724625302191],
              [-5.085641919180516, 50.084256452956367],
              [-5.080977667859266, 50.083076941294379],
              [-5.079185999115415, 50.083697566275298],
              [-5.078904010320271, 50.084619940741163],
              [-5.07733926229422, 50.085815650036892],
              [-5.076000822782357, 50.085612763636988],
              [-5.077348108760844, 50.084055808351216],
              [-5.076813680920909, 50.083701153908912],
              [-5.077043385247823, 50.083358611317607],
              [-5.075082522998452, 50.082211460127915],
              [-5.074892513754387, 50.081602074978775],
              [-5.075519542597166, 50.081045691844878],
              [-5.076315670282736, 50.08114332324147],
              [-5.075832185858795, 50.080841288824722],
              [-5.076441863929587, 50.080625407529958],
              [-5.076164329716912, 50.080352095712023],
              [-5.076722104400459, 50.080089911035834],
              [-5.076224593034311, 50.080070723221425],
              [-5.077134878307046, 50.079353886003716],
              [-5.07774087837012, 50.079386383511654],
              [-5.076641734007341, 50.077809770369534],
              [-5.077613331191364, 50.077156073101449],
              [-5.078404431839223, 50.077110787416188],
              [-5.078487811234205, 50.076754133454301],
              [-5.07904595882248, 50.076825677154083],
              [-5.079356694516473, 50.076352335677605],
              [-5.078982917835521, 50.075842293520466],
              [-5.079316285004593, 50.075155146952767],
              [-5.078701204211377, 50.074500378803677],
              [-5.079421103044938, 50.074348124886178],
              [-5.079318780736814, 50.074060272351595],
              [-5.078723435150719, 50.074018504793557],
              [-5.079384237276756, 50.072791902908101],
              [-5.078788504499572, 50.072743848911031],
              [-5.079023363622469, 50.072242835613892],
              [-5.078544414587981, 50.071966780635968],
              [-5.079046590233593, 50.072016420276029],
              [-5.078837364917056, 50.070998234634139],
              [-5.079657980844982, 50.070542839525963],
              [-5.078802669055967, 50.070260587159503],
              [-5.078918382535657, 50.069688970061279],
              [-5.079641339095844, 50.069367508757104],
              [-5.079505502178916, 50.068774683444417],
              [-5.078921390012618, 50.068274724408589],
              [-5.077349252764248, 50.067841467656784],
              [-5.07422733127904, 50.067825316014726],
              [-5.073804477483251, 50.067572943443686],
              [-5.071753978742138, 50.068072576234826],
              [-5.070664418712712, 50.067644437416746],
              [-5.069462771245187, 50.06793353404148],
              [-5.06826084635077, 50.067321231104906],
              [-5.067693211201102, 50.067668199035168],
              [-5.067782983338128, 50.067257408047311],
              [-5.067350668014484, 50.067097021196908],
              [-5.06769913341826, 50.066819724198268],
              [-5.067398737530252, 50.066908633942511],
              [-5.067093185952181, 50.066391352183729],
              [-5.066300834110969, 50.066392514910397],
              [-5.066079808395733, 50.066016930721148],
              [-5.066568832908113, 50.065423761128407],
              [-5.066371543698223, 50.063755731046271],
              [-5.065490603168024, 50.063178092574091],
              [-5.065300858275232, 50.062569584420388],
              [-5.062604792873006, 50.06205968161899],
              [-5.062458494456729, 50.061408640224677],
              [-5.061728652668199, 50.061753565873822],
              [-5.061475695499562, 50.061511956247756],
              [-5.061649436803023, 50.061168221946012],
              [-5.06208743358085, 50.061155757372646],
              [-5.061823905963241, 50.060792082713064],
              [-5.062139715226007, 50.060550748569419],
              [-5.061414552898134, 50.06042056268501],
              [-5.062060654882751, 50.059924019331319],
              [-5.061564842485254, 50.059642943180883],
              [-5.061980989322508, 50.059265820158025],
              [-5.061456382426539, 50.058159675572739],
              [-5.062731037085102, 50.057440524435108],
              [-5.062717688619702, 50.057011769956887],
              [-5.06321083203391, 50.057207450030759],
              [-5.063275444207317, 50.056576925863531],
              [-5.06374769145352, 50.056861316035707],
              [-5.064197146043743, 50.056678516836541],
              [-5.064239410416389, 50.055763410642385],
              [-5.063925135646477, 50.05530482488647],
              [-5.062928371699715, 50.054967721412609],
              [-5.061815438805275, 50.055263393859313],
              [-5.060490568273623, 50.054907944797456],
              [-5.06052541156325, 50.055213787188528],
              [-5.060002057922498, 50.055091760325169],
              [-5.059586354735536, 50.054795068049117],
              [-5.059820284747993, 50.054409266193979],
              [-5.058324013138819, 50.053884693498333],
              [-5.057777441245224, 50.052804300625986],
              [-5.058382528994251, 50.052718174256647],
              [-5.059291195863034, 50.051672263573295],
              [-5.059039695970458, 50.051057278215865],
              [-5.059813093376572, 50.05115741807014],
              [-5.059993809278657, 50.050791911262237],
              [-5.061034032280069, 50.050979458743562],
              [-5.062121078311534, 50.050388509405174],
              [-5.062294268668289, 50.050607035471678],
              [-5.064071958782518, 50.050440427224565],
              [-5.064882841998346, 50.049200043298498],
              [-5.06618951127998, 50.048242511183155],
              [-5.066785891340108, 50.046422147174304],
              [-5.065524802343372, 50.043798984455165],
              [-5.066041274281307, 50.043705264254214],
              [-5.065709567782898, 50.043563802398204],
              [-5.066150205176852, 50.042916135472112],
              [-5.066550527451935, 50.043060279328621],
              [-5.067078440536728, 50.042708067274347],
              [-5.066892688166749, 50.042511470823406],
              [-5.06737418019375, 50.04259229215031],
              [-5.06689560083489, 50.041746735670337],
              [-5.067438912025689, 50.041439094382142],
              [-5.067271526741338, 50.041048599061497],
              [-5.067764932870115, 50.041688653435223],
              [-5.068448966025927, 50.041572496760139],
              [-5.069584765382844, 50.039889870812097],
              [-5.069425826909861, 50.037662174578251],
              [-5.068066244216984, 50.036471107107779],
              [-5.068587835487985, 50.035364291171653],
              [-5.069943042785368, 50.034670808580977],
              [-5.077236012370022, 50.033966499206919],
              [-5.078837695999174, 50.032871444286734],
              [-5.083362410040844, 50.03210438589997],
              [-5.083376121005385, 50.03190430976084],
              [-5.083858179250979, 50.032169465255564],
              [-5.085701411033009, 50.032165402319066],
              [-5.089177807092071, 50.031300067325859],
              [-5.089270282201013, 50.030935063536965],
              [-5.090385227899627, 50.030594986351751],
              [-5.091371189848193, 50.029595330401051],
              [-5.093925725722178, 50.028319019688034],
              [-5.096567897434726, 50.026475359396926],
              [-5.097405662773777, 50.02486877687128],
              [-5.097138279511032, 50.023469838911453],
              [-5.096648879538559, 50.023155456407252],
              [-5.095837582753983, 50.023263486240396],
              [-5.09434093973797, 50.022572065081647],
              [-5.093856962488752, 50.022732517102241],
              [-5.094189296237865, 50.023036709775113],
              [-5.092723657600418, 50.022588233046598],
              [-5.093590322369942, 50.02166910395912],
              [-5.095051418438133, 50.021439389314736],
              [-5.095956041291108, 50.020915954032183],
              [-5.094915954417873, 50.018442825174901],
              [-5.094160156151357, 50.017262927824163],
              [-5.092540495891154, 50.016435329222134],
              [-5.091420032003866, 50.016358161261834],
              [-5.091097259159835, 50.01572355119049],
              [-5.091910538645756, 50.015191786977816],
              [-5.094204956665224, 50.01596180334046],
              [-5.095093428562145, 50.016013652310257],
              [-5.095708592732822, 50.015636484866071],
              [-5.097030170255393, 50.014000787738453],
              [-5.096845000844035, 50.013249166457143],
              [-5.097129276351415, 50.013327933947096],
              [-5.09757184750547, 50.012655798467769],
              [-5.09735837389453, 50.010878484071775],
              [-5.098124641062933, 50.00920633082513],
              [-5.098025693688855, 50.008471298292541],
              [-5.099028015397839, 50.008081965037569],
              [-5.098968925033987, 50.007619347894909],
              [-5.099660707814984, 50.007675519163051],
              [-5.099899467077324, 50.006115513996413],
              [-5.100476185424417, 50.004927002610287],
              [-5.101329573131324, 50.004220472292481],
              [-5.102529182989781, 50.004317014589866],
              [-5.102801971782529, 50.00469474494485],
              [-5.104296963316338, 50.004956086677566],
              [-5.106245433902048, 50.004998348407227],
              [-5.107199690280764, 50.004839631149885],
              [-5.109060291052654, 50.003737200364625],
              [-5.109126907767874, 50.003407056540759],
              [-5.109608831529886, 50.003369839793244],
              [-5.10998744756397, 50.002813619879682],
              [-5.109707878422352, 50.002461276946171],
              [-5.11095457589122, 50.003071044637501],
              [-5.11256555508269, 50.002881161417932],
              [-5.112340993094247, 50.003250633139572],
              [-5.113820665514842, 50.003879303762133],
              [-5.114968827836738, 50.004863214238092],
              [-5.115413870203579, 50.006564109491045],
              [-5.116262970202269, 50.006290294922564],
              [-5.116661121517988, 50.006791468105469],
              [-5.117399535728727, 50.006295718825207],
              [-5.118913820542076, 50.006658909370536],
              [-5.120219357219597, 50.007483801499411],
              [-5.120371718289365, 50.008283951113462],
              [-5.122343505477564, 50.009264507911276],
              [-5.12244121034768, 50.00861865520141],
              [-5.123182511430028, 50.009007107608355],
              [-5.123025563559715, 50.008438284681034],
              [-5.124128987858442, 50.00853449679262],
              [-5.124073344911944, 50.007956647569699],
              [-5.125090651456025, 50.008038078759689],
              [-5.125620019980845, 50.007484039844655],
              [-5.127059613773165, 50.007834744757368],
              [-5.127886250217138, 50.008655377100617],
              [-5.129370505552322, 50.008725969172623],
              [-5.129031680490593, 50.008108986660133],
              [-5.133137234938229, 50.007983696402739],
              [-5.132474571380434, 50.005243396988817],
              [-5.132937388586988, 50.005620420818346],
              [-5.133275810263311, 50.005351285765109],
              [-5.133577938076394, 50.005747044303952],
              [-5.134584930188555, 50.005820574156871],
              [-5.134985325233392, 50.006760635733983],
              [-5.135510814092481, 50.006749121390932],
              [-5.135337780846972, 50.007457298224452],
              [-5.135882506004479, 50.007226656259419],
              [-5.137864599484215, 50.008211170225835],
              [-5.138806566725886, 50.007757451995339],
              [-5.140353687221535, 50.007935953588159],
              [-5.14174777543213, 50.007544629493665],
              [-5.142730272816668, 50.007926420362693],
              [-5.142912870588047, 50.007644388856953],
              [-5.143334316802397, 50.007899246583762],
              [-5.144708295340282, 50.007691953035469],
              [-5.145431391067572, 50.007970105794293],
              [-5.148606209035219, 50.007210101703791],
              [-5.148987078424049, 50.006822817334147],
              [-5.150975924452192, 50.007160102856659],
              [-5.151114376843631, 50.006844173035979],
              [-5.152295832262517, 50.0070018681701],
              [-5.152609183963095, 50.006799029474124],
              [-5.154960158288413, 50.00765627025325],
              [-5.156678027030391, 50.007649084001535],
              [-5.158256513291575, 50.007049225079065],
              [-5.159479653760613, 50.006161257744424],
              [-5.160673200245201, 50.006035158945522],
              [-5.161164626665181, 50.006291642711552],
              [-5.162664787824253, 50.006009624040217],
              [-5.163529445730304, 50.005020740695507],
              [-5.164255848175607, 50.004965828188226],
              [-5.166580752922616, 50.003748129827443],
              [-5.16661138309646, 50.003258801051039],
              [-5.167314290763047, 50.002783490155153],
              [-5.167215905634055, 50.002178936964377],
              [-5.167966471597874, 50.002025282223727],
              [-5.168270276822383, 50.001127256882029],
              [-5.168930555791755, 50.000725969893885],
              [-5.169430798232523, 49.999398850447037],
              [-5.16913425472482, 49.999176656683495],
              [-5.1698932088722, 49.998599939188821],
              [-5.171260846525633, 49.998513949018047],
              [-5.171144908628072, 49.998067311959964],
              [-5.171766470346109, 49.997672465095469],
              [-5.171833854774087, 49.99532621537054],
              [-5.171212137642901, 49.994552462740941],
              [-5.17140762891167, 49.993685279152359],
              [-5.170690824903395, 49.992484107822236],
              [-5.171043026665407, 49.992004871310648],
              [-5.170138760465695, 49.991813699525636],
              [-5.170857185848328, 49.991619521092446],
              [-5.170200798469988, 49.991314513488305],
              [-5.170312676890766, 49.99100018518768],
              [-5.171023718861841, 49.990757628134219],
              [-5.171775792931279, 49.99109599731559],
              [-5.172442400552538, 49.990878037581631],
              [-5.172810325520075, 49.989048935951296],
              [-5.173531118491409, 49.988933842141854],
              [-5.173520116459589, 49.988533812683549],
              [-5.174511912694172, 49.988635297205377],
              [-5.174666774767137, 49.987872675933858],
              [-5.175597399879295, 49.988252909568907],
              [-5.175138507342036, 49.987387453039091],
              [-5.175657281238181, 49.988018267265112],
              [-5.176416975962628, 49.987266057935251],
              [-5.177615237339921, 49.986753713625404],
              [-5.179059586895916, 49.987349236742304],
              [-5.179445301754273, 49.987020190400081],
              [-5.178706789173547, 49.986443099568135],
              [-5.179809053018593, 49.986844680840761],
              [-5.180043332033299, 49.986208518332873],
              [-5.178982903009981, 49.985277714498693],
              [-5.179348100742358, 49.984637962212318],
              [-5.180290607096417, 49.984691269687744],
              [-5.179779514821647, 49.984261780128918],
              [-5.179783637219926, 49.983372841425073],
              [-5.180609941225059, 49.981454665427151],
              [-5.180977055002947, 49.981309646554521],
              [-5.181339555611727, 49.981601969204227],
              [-5.181900507651059, 49.980652762270978],
              [-5.18244177011481, 49.980440883314785],
              [-5.183998560903984, 49.980839837783002],
              [-5.18460844002178, 49.980653052148931],
              [-5.184578013227552, 49.980361511039021],
              [-5.185238947638694, 49.980292968830661],
              [-5.184713508061408, 49.980047418111994],
              [-5.185322908572626, 49.979938009262135],
              [-5.185141726166957, 49.979645213936131],
              [-5.186105401088227, 49.979365930866635],
              [-5.185569151408069, 49.979104488037592],
              [-5.185911782154696, 49.978906148822638],
              [-5.18553195304489, 49.978436191395936],
              [-5.184856885175201, 49.978227140583435],
              [-5.185392847530386, 49.978146737978641],
              [-5.185297208080957, 49.977860587848092],
              [-5.185932656136338, 49.977533650541019],
              [-5.18553653446443, 49.976162719083206],
              [-5.18604810592983, 49.975966932323558],
              [-5.186126157950397, 49.975353942549525],
              [-5.187245407487346, 49.974728515022015],
              [-5.18696890971017, 49.9745130125362],
              [-5.188467994918669, 49.974126322357179],
              [-5.188764968901618, 49.973660242922115],
              [-5.188151766827486, 49.97316252944939],
              [-5.188301970852962, 49.972101339473298],
              [-5.187307340194886, 49.97148816764085],
              [-5.188309324876285, 49.971411125810782],
              [-5.18740910000376, 49.970875424052146],
              [-5.187467993766909, 49.970268357473351],
              [-5.186839707253782, 49.969731468763918],
              [-5.187152766017435, 49.969551031141776],
              [-5.184743913297812, 49.969254707528322],
              [-5.184586313377149, 49.968790334772116],
              [-5.185291649603134, 49.968805135297266],
              [-5.185116101903106, 49.968090261560917],
              [-5.18441015388964, 49.967685939895304],
              [-5.183793647119631, 49.967791042457286],
              [-5.183222626420973, 49.967127513561095],
              [-5.183892396253441, 49.967195477548344],
              [-5.183853929437649, 49.966908654895796],
              [-5.184484203322651, 49.966864347994694],
              [-5.184026323272884, 49.966456805424777],
              [-5.184476703165377, 49.966011710884736],
              [-5.185631425037741, 49.965968278177982],
              [-5.186033986963935, 49.965327474252767],
              [-5.186590886883319, 49.965186214330117],
              [-5.18519439714844, 49.963912955519945],
              [-5.185564531389627, 49.963119208573438],
              [-5.188674084492617, 49.962395851349051],
              [-5.189971685011079, 49.963164402659771],
              [-5.189641217167428, 49.962111042398298],
              [-5.190208800396684, 49.961710378557747],
              [-5.191290806516889, 49.961749856612222],
              [-5.192146351922077, 49.962539553214206],
              [-5.192900482895556, 49.962618635140075],
              [-5.192673719977364, 49.962964041754347],
              [-5.193580820041333, 49.962973232027942],
              [-5.194253765433891, 49.963552935097404],
              [-5.194710972778846, 49.963381097546808],
              [-5.196910772194997, 49.963956465791149],
              [-5.197566416050388, 49.963685581196501],
              [-5.196885149390476, 49.962875822401408],
              [-5.198170274807868, 49.962823268460184],
              [-5.198082626394783, 49.962384870640356],
              [-5.198475690224556, 49.962634014034087],
              [-5.198793233659216, 49.962249205094921],
              [-5.199336445414996, 49.962449219424059],
              [-5.199198475372265, 49.961799000232851],
              [-5.199877507829316, 49.961460884050908],
              [-5.199293066592245, 49.960996620817163],
              [-5.20003439282678, 49.960861898560161],
              [-5.199484778329582, 49.960607187157109],
              [-5.199770106843393, 49.960220565866116],
              [-5.198961153147919, 49.959819176817867],
              [-5.198732524607466, 49.958958248191784],
              [-5.199246000971876, 49.9592571428539],
              [-5.199658515391265, 49.959001054566905],
              [-5.200130608774722, 49.959411742125205],
              [-5.201626837034788, 49.95930563863373],
              [-5.202406597587608, 49.958826181383522],
              [-5.203086470077855, 49.959173538498327],
              [-5.20565391268215, 49.959511870912962],
              [-5.205521555142217, 49.958863302397305],
              [-5.206455873492762, 49.958800567589094],
              [-5.207752355371908, 49.959801056298581],
              [-5.208867351379893, 49.9594355235226],
              [-5.208947119461016, 49.959712199257638],
              [-5.210167231960998, 49.959773765158921],
              [-5.210040136566743, 49.960001293475145],
              [-5.212869809540901, 49.959271539677147],
              [-5.215665457853858, 49.95953854962918],
              [-5.216127854200872, 49.959822594433355],
              [-5.215477057005652, 49.96031026170786],
              [-5.216426311727982, 49.962624751570488],
              [-5.216009255663432, 49.963230082015571],
              [-5.216590348814085, 49.963202258186399],
              [-5.216826981958611, 49.963655399782859],
              [-5.216282110049701, 49.963808167449315],
              [-5.215850198705345, 49.964692794317607],
              [-5.215111842166357, 49.964976873651565],
              [-5.216516918213968, 49.965237457425367],
              [-5.216127502782592, 49.966362005967234],
              [-5.216598276260703, 49.966624225637176],
              [-5.21592727006864, 49.966810180160699],
              [-5.216377235042457, 49.967408539607348],
              [-5.215841383764324, 49.967927203100892],
              [-5.216633541552216, 49.967800860376201],
              [-5.217575426945873, 49.968058094846292],
              [-5.217140884813231, 49.969237877100547],
              [-5.217862880160277, 49.96933478254347],
              [-5.218827211372848, 49.970993004912174],
              [-5.220614692536624, 49.971942828984915],
              [-5.223809993543773, 49.97250081582019],
              [-5.22580802385109, 49.972509088931687],
              [-5.22598402156797, 49.973075454256417],
              [-5.227197641058749, 49.973409611884087],
              [-5.227427893648848, 49.974119304532955],
              [-5.227877289927167, 49.974164364233765],
              [-5.228164922892009, 49.973689444465442],
              [-5.228750264497869, 49.973764899635746],
              [-5.230164212420737, 49.975360123425354],
              [-5.231369163412883, 49.974981073090184],
              [-5.231575686198054, 49.974565982616113],
              [-5.232419470001839, 49.974646807006671],
              [-5.232580023412027, 49.974960797604204],
              [-5.233875728706098, 49.975207131247537],
              [-5.235333063576663, 49.975928441196153],
              [-5.235763950085482, 49.975572752030409],
              [-5.236220672923189, 49.975685047214448],
              [-5.235877503118987, 49.976058980335104],
              [-5.236095330136331, 49.976456831869136],
              [-5.237041199051657, 49.976623835571743],
              [-5.237569233895651, 49.976302310852006],
              [-5.237149933849181, 49.975875004478631],
              [-5.237709999674163, 49.975739707460377],
              [-5.238455344029707, 49.975704487029589],
              [-5.238873836022754, 49.975995067451386],
              [-5.239505921056639, 49.975644536292307],
              [-5.239924881354505, 49.976045754493505],
              [-5.240228888661268, 49.975795253953422],
              [-5.240846743058905, 49.976083851432286],
              [-5.241023088403987, 49.975739758689151],
              [-5.242255880070287, 49.975671987868026],
              [-5.241785292477948, 49.975104890740511],
              [-5.242039061318511, 49.974709150712762],
              [-5.243590054738253, 49.975203729053383],
              [-5.244670919503868, 49.975987637833718],
              [-5.244641645594533, 49.975718567921241],
              [-5.245101417235836, 49.97581364986425],
              [-5.24555401627568, 49.975532884711463],
              [-5.2468136505793, 49.977044067436928],
              [-5.245269619612875, 49.977627995176917],
              [-5.245878155894029, 49.977736001121343],
              [-5.246693640777974, 49.97859659587499],
              [-5.246271809662739, 49.979313724344458],
              [-5.246624873300779, 49.979605210388442],
              [-5.247844620676158, 49.979342526116611],
              [-5.24886171890638, 49.979612694623995],
              [-5.2485223759058, 49.980000952480211],
              [-5.247196064299744, 49.980173068036535],
              [-5.247643499857577, 49.981347144344269],
              [-5.246128859571764, 49.981437260543629],
              [-5.247119107056223, 49.981826048420558],
              [-5.246784711839717, 49.981956867599216],
              [-5.246932120131579, 49.982384560823661],
              [-5.245481099501162, 49.98219759862485],
              [-5.246926531915567, 49.982798547856461],
              [-5.246853119334223, 49.983489722279437],
              [-5.243811673637245, 49.983968031239563],
              [-5.244918581268998, 49.984244717392357],
              [-5.244829990623996, 49.985063163085378],
              [-5.245592726003387, 49.985056199133346],
              [-5.245047454489942, 49.985412428391264],
              [-5.245389053264142, 49.98569884153892],
              [-5.245935715411805, 49.985383954766341],
              [-5.246254966195959, 49.985856315310279],
              [-5.246931070855917, 49.985743813469675],
              [-5.247947877078434, 49.986028393162115],
              [-5.24788532377665, 49.986280243705259],
              [-5.249318305271633, 49.985887420251856],
              [-5.250794977439106, 49.986948957349753],
              [-5.251891691767352, 49.987135000717515],
              [-5.252113947003935, 49.987595673051381],
              [-5.252820187626739, 49.986420725620995],
              [-5.253565445954956, 49.987683580578576],
              [-5.255000298614728, 49.98824784286542],
              [-5.255087880982214, 49.988883222138028],
              [-5.254580585009876, 49.989057603750261],
              [-5.25495971214842, 49.989526460243297],
              [-5.253973046754418, 49.989586553683822],
              [-5.254061612597483, 49.990443215333741],
              [-5.253165363773804, 49.990148107427274],
              [-5.251297912320305, 49.991403318299561],
              [-5.251766838678198, 49.991941636305036],
              [-5.252239229044078, 49.991704373110359],
              [-5.252603251336518, 49.991866886741512],
              [-5.251976954171734, 49.992532196312901],
              [-5.252539239616892, 49.994018801800813],
              [-5.253772623186477, 49.994738072912753],
              [-5.254192132353644, 49.994771276316769],
              [-5.254121424532975, 49.994488978133148],
              [-5.254830436919873, 49.995171680912854],
              [-5.254436304771976, 49.995183648405408],
              [-5.254487838422079, 49.996586525651409],
              [-5.255312790721471, 49.996733388277242],
              [-5.25531950237107, 49.997493388136121],
              [-5.255699781347966, 49.997069775824031],
              [-5.25624860476486, 49.997405214026486],
              [-5.256682660775455, 49.997013373877692],
              [-5.257425279048821, 49.997263293756554],
              [-5.258207814863762, 49.996617852896186],
              [-5.258699165366177, 49.996908114421572],
              [-5.25904913420913, 49.996511432323643],
              [-5.260321363982627, 49.996688863594756],
              [-5.265107938085553, 49.998680847354564],
              [-5.264978344138624, 49.999424895695626],
              [-5.265342346261424, 49.998906348684116],
              [-5.26563567661017, 49.99993895904197],
              [-5.266113256772024, 49.99979865361427],
              [-5.26615035370209, 50.000366176005393],
              [-5.266818642910795, 50.000485884936317],
              [-5.265688483606223, 50.001581999238297],
              [-5.26694007549102, 50.001247149898361],
              [-5.266406817862951, 50.00169850131482],
              [-5.266752995621022, 50.001760715330377],
              [-5.266296913916549, 50.002897210019334],
              [-5.266703623076693, 50.002678832352188],
              [-5.266954487923386, 50.002940751637986],
              [-5.267814719453509, 50.002803147597071],
              [-5.267090417554368, 50.003421822005166],
              [-5.267702489924809, 50.003267823244059],
              [-5.267812290571324, 50.004556599739253],
              [-5.268210716983655, 50.004360041579581],
              [-5.268491230008194, 50.00464721096251],
              [-5.267758299017703, 50.005241842522764],
              [-5.26815821121955, 50.005252158161753],
              [-5.267836636764424, 50.005573397472325],
              [-5.268141710084657, 50.005913853186208],
              [-5.267558534544716, 50.006596922643482],
              [-5.266631824332475, 50.006372946437814],
              [-5.265817983051391, 50.006825004743689],
              [-5.265415082400078, 50.006462109453146],
              [-5.265466675618475, 50.007078702937953],
              [-5.26519783568311, 50.00690185219851],
              [-5.264332168161467, 50.007145745666527],
              [-5.264797724011604, 50.007568054813895],
              [-5.263724866699381, 50.007988709344573],
              [-5.264225390990362, 50.008267894371706],
              [-5.264080637658326, 50.008706492807221],
              [-5.262281173514562, 50.00954972824541],
              [-5.262441379928029, 50.010059808011469],
              [-5.261554575459614, 50.010569666025894],
              [-5.261870450941336, 50.011213909199398],
              [-5.261406492886281, 50.011124406774691],
              [-5.261451041686031, 50.011411035093396],
              [-5.262170465025651, 50.011665177328453],
              [-5.262385933388416, 50.012187196261472],
              [-5.26081845977276, 50.011676231147796],
              [-5.261081248329097, 50.012484802919694],
              [-5.260978741828308, 50.012702698499659],
              [-5.260368696183542, 50.012352810342655],
              [-5.2603777575215, 50.01316672091945],
              [-5.259133430167671, 50.012686226715509],
              [-5.259213126757482, 50.013059131595831],
              [-5.25842104767306, 50.01303102131547],
              [-5.258514154917809, 50.013519601897677],
              [-5.257728256013172, 50.014387344361282],
              [-5.258812642985976, 50.015020789469951],
              [-5.258341007621991, 50.014880211480886],
              [-5.25797409472381, 50.01542040678531],
              [-5.258980931758773, 50.015444281850272],
              [-5.259204189256875, 50.014927014830953],
              [-5.259345175095159, 50.015916241961008],
              [-5.260149361674787, 50.016184206975375],
              [-5.260541184835565, 50.015970769303337],
              [-5.261387596180838, 50.016614483043973],
              [-5.260713239843569, 50.016799886454891],
              [-5.260372992303339, 50.016414519697371],
              [-5.260016332944674, 50.01696163010439],
              [-5.259416667330317, 50.016642032192493],
              [-5.258541801452007, 50.019371815273516],
              [-5.257357091532112, 50.020145507497652],
              [-5.257281930692746, 50.02089161275908],
              [-5.256733684269425, 50.020919611967571],
              [-5.255870777549427, 50.021641964194565],
              [-5.256724705620362, 50.022086682832196],
              [-5.255951777049772, 50.022880276978043],
              [-5.256656711742302, 50.023887850443501],
              [-5.258302479590286, 50.023631988960361],
              [-5.25825379063363, 50.02402649531448],
              [-5.25890846661908, 50.024188017735092],
              [-5.257862491441896, 50.024475612405674],
              [-5.259255946352106, 50.024473326654203],
              [-5.261035289570966, 50.025096206868213],
              [-5.26137932515545, 50.025351019040528],
              [-5.260615669088349, 50.026260435487721],
              [-5.261108306468841, 50.026317648310062],
              [-5.261552972671208, 50.025852624626673],
              [-5.262351755250095, 50.026470683327872],
              [-5.262190699178471, 50.02691693369578],
              [-5.263000125762511, 50.026847371800777],
              [-5.262891217123927, 50.026456399730414],
              [-5.263533081181013, 50.026757699526783],
              [-5.263549738599765, 50.027085595277484],
              [-5.263034965605741, 50.027011023265395],
              [-5.262581176373152, 50.027362056473912],
              [-5.262903362795258, 50.027809100195213],
              [-5.263499646439462, 50.027685258984121],
              [-5.263890753651045, 50.027974723857646],
              [-5.265114186559847, 50.029066606631687],
              [-5.264777022919989, 50.029325300077915],
              [-5.265888708120513, 50.029078974612659],
              [-5.266273498088268, 50.02941898882974],
              [-5.265623705180546, 50.029433697167519],
              [-5.265885672108463, 50.029980488831157],
              [-5.265493142822097, 50.030781421288019],
              [-5.266012174691023, 50.030938628612439],
              [-5.265662649385293, 50.031529635957952],
              [-5.264895999144081, 50.031921877518521],
              [-5.263786645702105, 50.031936022886541],
              [-5.263507626176481, 50.0323316181343],
              [-5.262806133702616, 50.032284791728685],
              [-5.262489669786124, 50.032746211567662],
              [-5.261416999844386, 50.033075071230208],
              [-5.261463654835326, 50.033618933417991],
              [-5.262026673994403, 50.034424452828532],
              [-5.263071556747281, 50.034117961017543],
              [-5.264754934567065, 50.034411524903263],
              [-5.264996753546524, 50.034208595429888],
              [-5.266175596756493, 50.034291440274281],
              [-5.266038881093992, 50.034643448479692],
              [-5.267078508758807, 50.035000095009252],
              [-5.266417695105892, 50.035367772285099],
              [-5.26718164482021, 50.035366935900214],
              [-5.266458381077134, 50.035843429198501],
              [-5.266885401637438, 50.036002325900043],
              [-5.266310847623517, 50.03657088764578],
              [-5.267145784038669, 50.036545560847401],
              [-5.267043524075681, 50.037240257309115],
              [-5.266338015974136, 50.03762898514519],
              [-5.266766385095909, 50.037643004165545],
              [-5.266785172942054, 50.03835138213617],
              [-5.267747599000101, 50.038701202368131],
              [-5.268560155977889, 50.038572139540982],
              [-5.269099586387122, 50.038082824925958],
              [-5.270062297442319, 50.038128543327538],
              [-5.269982404388356, 50.038494247047318],
              [-5.27088192657085, 50.038350119999023],
              [-5.270707600906597, 50.039196191675508],
              [-5.270021155687136, 50.039269533900971],
              [-5.269840951617577, 50.03965965795598],
              [-5.271535896315267, 50.041105223711348],
              [-5.272397596227571, 50.040999937052632],
              [-5.272247889260059, 50.041263255952614],
              [-5.273362991234953, 50.041720274757907],
              [-5.274123643038509, 50.041402816955468],
              [-5.274476855247671, 50.041892135402968],
              [-5.275195699140397, 50.041514676562677],
              [-5.275487659420796, 50.041842889471397],
              [-5.276134787108838, 50.041766128030339],
              [-5.276759774719299, 50.041098032272181],
              [-5.276911386776712, 50.041580446680925],
              [-5.277607998110733, 50.041819847624751],
              [-5.278091581788231, 50.041577668724806],
              [-5.27778928122251, 50.041958663055382],
              [-5.27813810435175, 50.042075647917372],
              [-5.279993783015987, 50.041879220103716],
              [-5.280134650003387, 50.042633622896403],
              [-5.278911306725189, 50.043048772781567],
              [-5.27939080093506, 50.043197143677169],
              [-5.278925649173099, 50.043545862538217],
              [-5.279982985543196, 50.043420592230149],
              [-5.279429113126663, 50.043984135875789],
              [-5.280307539015526, 50.044082533573565],
              [-5.280808790541836, 50.043362139866367],
              [-5.281629276431131, 50.043409988551794],
              [-5.281059161052789, 50.044451703611173],
              [-5.280373610597444, 50.04449809245753],
              [-5.280484611782601, 50.044753145432523],
              [-5.278912021863881, 50.045517337058534],
              [-5.278447867503386, 50.046393208454042],
              [-5.278727256203887, 50.046700177832932],
              [-5.277881073784827, 50.046747495776479],
              [-5.276964119032415, 50.047703634292006],
              [-5.276427503565166, 50.047599149979902],
              [-5.27570142138075, 50.048261102795884],
              [-5.275584993967072, 50.050284059127257],
              [-5.276624780105511, 50.050247480397324],
              [-5.277429640827407, 50.050699734800574],
              [-5.277084847520407, 50.051093622740993],
              [-5.277840623234496, 50.051194607239161],
              [-5.276947797136709, 50.052177950726445],
              [-5.277533534954386, 50.052858606070707],
              [-5.278643930598506, 50.052791225440153],
              [-5.278556706286714, 50.053274093874215],
              [-5.278901661658174, 50.053374093411968],
              [-5.278331151504882, 50.054883610432199],
              [-5.279626708056951, 50.05668941003286],
              [-5.283094341385837, 50.060137607934614],
              [-5.285943914450212, 50.062551555962401],
              [-5.286536281826333, 50.062671510225691],
              [-5.286849980544586, 50.063416507166387],
              [-5.288222796949278, 50.063912861946918],
              [-5.288672626943415, 50.064726863398178],
              [-5.289627986684468, 50.06508570316614],
              [-5.290216860712006, 50.066092772672619],
              [-5.296904937303143, 50.070335272921028],
              [-5.307944334608544, 50.077620942466616],
              [-5.309140848558082, 50.078775215274774],
              [-5.310520758488757, 50.079182044537561],
              [-5.312530314664882, 50.080548771630049],
              [-5.316334291145676, 50.081842567945166],
              [-5.316808144280405, 50.081739951465721],
              [-5.317043712357047, 50.08209396559824],
              [-5.31838138866684, 50.081685044419956],
              [-5.315717015188193, 50.083006350956495],
              [-5.316232497625416, 50.085206503416501],
              [-5.317560292083517, 50.085138835741667],
              [-5.317651260276313, 50.084715205451545],
              [-5.31704343323818, 50.083870746690138],
              [-5.31636846835409, 50.083997108406066],
              [-5.316478854987914, 50.083287740409574],
              [-5.318375508260431, 50.08263162583097],
              [-5.320488883766016, 50.082704273790853],
              [-5.3214530502224, 50.082211550561887],
              [-5.321234869692469, 50.082695504790301],
              [-5.322219852466614, 50.082847215632889],
              [-5.322611504837488, 50.083940743168014],
              [-5.323543166497913, 50.084594166670882],
              [-5.325038496837843, 50.084664652740308],
              [-5.3270862380167, 50.085463267952008],
              [-5.327492244765788, 50.087407420965562],
              [-5.32807799262849, 50.087224179371262],
              [-5.328540445369082, 50.08766252201297],
              [-5.328289212657082, 50.088818564716057],
              [-5.328659172625764, 50.089132713309539],
              [-5.330117125585265, 50.088682420532656],
              [-5.331834578827602, 50.089432861510069],
              [-5.332208267031138, 50.090505283038773],
              [-5.334841857463201, 50.09141827446895],
              [-5.336729102768652, 50.091048214709396],
              [-5.337322573965038, 50.091337911957865],
              [-5.337801803354777, 50.091231456827636],
              [-5.338257921249373, 50.091777900028418],
              [-5.339103599151775, 50.091973962799372],
              [-5.340587715735371, 50.091658629711759],
              [-5.34174180589538, 50.091925855884469],
              [-5.343634559488449, 50.091655384087922],
              [-5.346208113510832, 50.092545560159145],
              [-5.346323848690077, 50.092239942883701],
              [-5.34777906077317, 50.091711237998261],
              [-5.348798652946544, 50.092114523491261],
              [-5.35103677020733, 50.090922624545058],
              [-5.352072194947817, 50.091212069580259],
              [-5.353283198628576, 50.090311606432479],
              [-5.354295335622062, 50.090527934771373],
              [-5.354618678428148, 50.090152428952791],
              [-5.35624311428955, 50.090466179226517],
              [-5.35614416042719, 50.090030919701562],
              [-5.356936370056118, 50.09018161892736],
              [-5.357840804976609, 50.089218010397914],
              [-5.359533613611787, 50.089230155054032],
              [-5.359798373346611, 50.089516665979119],
              [-5.360226251346745, 50.089157007602275],
              [-5.360477575312567, 50.089431311405171],
              [-5.361259853284142, 50.08944012602818],
              [-5.362029542309546, 50.088869933092106],
              [-5.363179796359783, 50.089041695610192],
              [-5.362294789064816, 50.089422717350963],
              [-5.363625251478672, 50.089392229323309],
              [-5.363700020287959, 50.08964105973611],
              [-5.363164569322545, 50.089702472101749],
              [-5.363544665926826, 50.090217736320362],
              [-5.364185843541289, 50.090188340923376],
              [-5.363799362755521, 50.090739335917362],
              [-5.364221448020905, 50.09117511139975],
              [-5.364650297787443, 50.091088898600802],
              [-5.364429743518669, 50.091433562029415],
              [-5.366213643615923, 50.093680368867794],
              [-5.367753798027061, 50.093806581886334],
              [-5.368820681362671, 50.093207020892883],
              [-5.369250299122497, 50.093828785021536],
              [-5.369664423709269, 50.093791562163915],
              [-5.369767359394219, 50.093405324601022],
              [-5.371587664625963, 50.093159890894995],
              [-5.372224981391059, 50.093353674226321],
              [-5.372725208060372, 50.093745763609633],
              [-5.372412652131919, 50.093934781725082],
              [-5.372563369413117, 50.095283456715691],
              [-5.374932122434825, 50.096830295644459],
              [-5.374524512601423, 50.097238898820798],
              [-5.37489930591133, 50.09765622048748],
              [-5.384115411469756, 50.101556577054289],
              [-5.391660451669464, 50.103503392375529],
              [-5.393708740441792, 50.103530734359161],
              [-5.395104994811641, 50.103100320478298],
              [-5.395612722450358, 50.102724698239854],
              [-5.395270896264718, 50.102341562797292],
              [-5.395654532132743, 50.101536845131236],
              [-5.396467538207394, 50.101186465942135],
              [-5.397200575490662, 50.101622912468748],
              [-5.396784694931451, 50.10116188255045],
              [-5.397637068873016, 50.101130614867287],
              [-5.397490079300655, 50.100773264771156],
              [-5.398743377779808, 50.100912869774568],
              [-5.398824447274635, 50.10120827573251],
              [-5.399658356838743, 50.101173036113174],
              [-5.400063511699803, 50.101798105559062],
              [-5.401303558606574, 50.101829214763725],
              [-5.40259233255576, 50.102862883644818],
              [-5.403668681776825, 50.102774619835529],
              [-5.404009181247752, 50.103157770028503],
              [-5.404455584212148, 50.103121275042142],
              [-5.404467961567093, 50.103571633402076],
              [-5.405846828915861, 50.103646300066558],
              [-5.405762721272546, 50.104116585242991],
              [-5.406861272543485, 50.103769441953176],
              [-5.406800822808026, 50.104001526806542],
              [-5.40887793788941, 50.10453245457343],
              [-5.413029489250934, 50.103942529251697],
              [-5.415519903125002, 50.102045624394641],
              [-5.41474351291028, 50.101022200407996],
              [-5.414914601518899, 50.100733773797558],
              [-5.415413102301658, 50.100781168483664],
              [-5.414964464904819, 50.100353554366791],
              [-5.415172140860639, 50.09991111000496],
              [-5.416086588324822, 50.099887774187152],
              [-5.417187665199068, 50.101090547884191],
              [-5.418669822404954, 50.101162917292655],
              [-5.418325826928449, 50.10006469309856],
              [-5.418554410306675, 50.099955395050841],
              [-5.41853325243054, 50.100227712115874],
              [-5.41896448671864, 50.099821853115458],
              [-5.419211609271686, 50.099953112536205],
              [-5.419108520816317, 50.099428958957716],
              [-5.41978340341389, 50.098889962049391],
              [-5.420111311335879, 50.098859599566772],
              [-5.420398728951138, 50.099418799741784],
              [-5.420742177674159, 50.09907669915647],
              [-5.421026420905567, 50.099218555124089],
              [-5.421074993405123, 50.099880162237881],
              [-5.421987434926811, 50.099514972928382],
              [-5.421963582219675, 50.099808062222067],
              [-5.422465714109171, 50.099788745454845],
              [-5.423175982790355, 50.098530765244732],
              [-5.423940956200852, 50.098725895654695],
              [-5.424058028495159, 50.099033717040889],
              [-5.424896545024216, 50.09878944075038],
              [-5.426015097157891, 50.099000204009656],
              [-5.426141026276031, 50.098570050201928],
              [-5.426844127861381, 50.098642838076607],
              [-5.429190993536708, 50.096999075476354],
              [-5.429294682074145, 50.09758887777474],
              [-5.426781132308741, 50.100913612519641],
              [-5.427303401852612, 50.100999838266468],
              [-5.426961575925543, 50.101344609524674],
              [-5.427967764469543, 50.102193826620635],
              [-5.427674914997618, 50.103028359983199],
              [-5.428649856628918, 50.103459258958928],
              [-5.427952896568635, 50.104354321162006],
              [-5.429048421437707, 50.105415004738042],
              [-5.429484827112767, 50.105571233577862],
              [-5.431135952319454, 50.105246201041147],
              [-5.431983327485868, 50.106063197395727],
              [-5.432220454207599, 50.105643240832954],
              [-5.433281423281485, 50.105887127388229],
              [-5.433934956961274, 50.106653170584202],
              [-5.434390823178159, 50.106553304636343],
              [-5.434718413614028, 50.106889067682609],
              [-5.43396540818348, 50.107899181958892],
              [-5.43440752549686, 50.108427677961316],
              [-5.435369915829076, 50.108759032297847],
              [-5.435917064273945, 50.108546699239447],
              [-5.436687705804149, 50.109462197972405],
              [-5.439861210136536, 50.110701389629277],
              [-5.440311908810528, 50.111640748939749],
              [-5.441898619205348, 50.112190135299599],
              [-5.444380767760113, 50.112739898325813],
              [-5.448352592544047, 50.110560765778885],
              [-5.448735092410896, 50.112261426855881],
              [-5.449535471431505, 50.112555199071124],
              [-5.45030264580695, 50.112173416242683],
              [-5.450894969937492, 50.113678001538453],
              [-5.453834276732595, 50.113948561978859],
              [-5.453230714348177, 50.115009231348822],
              [-5.453951163834034, 50.118115863642956],
              [-5.456643564868613, 50.118936198093941],
              [-5.457416581348631, 50.118887065730647],
              [-5.459345318179619, 50.119710309488426],
              [-5.459909363459251, 50.120235973589054],
              [-5.45970260196188, 50.120725254714124],
              [-5.460526275798933, 50.122036663401794],
              [-5.462858083398452, 50.123090728494162],
              [-5.466413987772353, 50.123538687303132],
              [-5.469368375011251, 50.123350485183444],
              [-5.470538440836991, 50.123063574644583],
              [-5.470349908467464, 50.122411569671215],
              [-5.47116540613125, 50.121904950420365],
              [-5.470918394200525, 50.122435941551544],
              [-5.471321632304163, 50.122477851997701],
              [-5.471300441134238, 50.12218519923956],
              [-5.471664757691888, 50.122849034372727],
              [-5.472380660846698, 50.122744834001807],
              [-5.475113622992719, 50.123325120971494],
              [-5.477386560992631, 50.124333878690017],
              [-5.479769463419714, 50.126046421754658],
              [-5.484115632776574, 50.127657671524062],
              [-5.496123661107736, 50.128701580102494],
              [-5.502398219489658, 50.127886104199369],
              [-5.511478059536731, 50.127370437583536],
              [-5.517588546100605, 50.126594188675277],
              [-5.523993846778568, 50.126156826780374],
              [-5.527915261955572, 50.125094757828506],
              [-5.529593178481356, 50.124144939818024],
              [-5.531211462193095, 50.122519465771582],
              [-5.531538543921389, 50.121906731230077],
              [-5.529567724560485, 50.118344683722277],
              [-5.530771096502966, 50.120188349822946],
              [-5.533158804848401, 50.119398597336712],
              [-5.533119304073493, 50.118520828642147],
              [-5.533696382192963, 50.118225249424903],
              [-5.533555371301634, 50.117508015119228],
              [-5.533094462112125, 50.117196379643211],
              [-5.532303305196861, 50.117738687284444],
              [-5.531685567547536, 50.117292375090855],
              [-5.530366215854796, 50.117692412897014],
              [-5.529305328930522, 50.117308159845251],
              [-5.527904191221486, 50.117699862442493],
              [-5.529090420258555, 50.117277813859396],
              [-5.52943799302531, 50.116468334490179],
              [-5.530791965535858, 50.115721781792985],
              [-5.531085169940835, 50.114155535137478],
              [-5.532623873645385, 50.115253045623078],
              [-5.537345276372494, 50.113935958921864],
              [-5.545088292827191, 50.110044161150377],
              [-5.5477502453814, 50.108016769162177],
              [-5.548526958252728, 50.106640801841074],
              [-5.549140548870846, 50.106407002487494],
              [-5.543499128170296, 50.103096019767214],
              [-5.548690094289027, 50.105952961199684],
              [-5.548941498837856, 50.105002414557305],
              [-5.546521692882173, 50.103642378066738],
              [-5.548096305535277, 50.104336434211703],
              [-5.549335932307039, 50.103621945962992],
              [-5.549256600976642, 50.103192533235408],
              [-5.54782773393682, 50.102709954209068],
              [-5.547541476084181, 50.102243687377978],
              [-5.54659474159588, 50.102227664612442],
              [-5.546907903066666, 50.102717401805833],
              [-5.546097118619276, 50.101626611024855],
              [-5.545501501786975, 50.101440599818993],
              [-5.54256893203407, 50.103038973492922],
              [-5.543823701358471, 50.101885044070933],
              [-5.542935100039659, 50.099417419100483],
              [-5.541137797256799, 50.098336940784129],
              [-5.540802838699146, 50.098536100004104],
              [-5.5408432652687, 50.098136312692965],
              [-5.539609357397184, 50.097544218686281],
              [-5.538444609492752, 50.095917182951929],
              [-5.538599298378283, 50.094961513549187],
              [-5.538215115835514, 50.095156769684479],
              [-5.538614186612923, 50.093987618690313],
              [-5.538110210659521, 50.093032256720299],
              [-5.533505944402571, 50.089188935172416],
              [-5.53316484613719, 50.088525478913098],
              [-5.535605907458626, 50.085444377548143],
              [-5.535579592633781, 50.084517622145441],
              [-5.537688904921405, 50.083560809905265],
              [-5.537691177182114, 50.082870694701256],
              [-5.538006518315477, 50.083753543711396],
              [-5.539120617892777, 50.083086174340572],
              [-5.538581281267905, 50.082142689675045],
              [-5.537504884763789, 50.082733331526271],
              [-5.538778742153181, 50.081559075390182],
              [-5.538504283010123, 50.080358297043851],
              [-5.538101146786624, 50.080124090855215],
              [-5.538601065917685, 50.079452075336413],
              [-5.538337401484929, 50.078644122461839],
              [-5.539361290674338, 50.078290788029683],
              [-5.539821787298555, 50.076875943487678],
              [-5.539396778365506, 50.075851600144013],
              [-5.540095484841629, 50.075222090508433],
              [-5.54066775587023, 50.074944610155022],
              [-5.541604485681927, 50.073145447576536],
              [-5.541761591776941, 50.0715986130134],
              [-5.542592032093067, 50.070993852047465],
              [-5.542194459986796, 50.070512080962146],
              [-5.542540678658181, 50.070165025182838],
              [-5.543683605001163, 50.069950160753599],
              [-5.544169514518969, 50.069374811800799],
              [-5.545179365869019, 50.069326841066712],
              [-5.545485854511898, 50.068896421990161],
              [-5.545235043656527, 50.068376884806185],
              [-5.546645791415244, 50.068287851138173],
              [-5.546734628205372, 50.067938759718089],
              [-5.547349196957981, 50.067762513486166],
              [-5.54746376143312, 50.06713733457967],
              [-5.54778755067451, 50.067263276537609],
              [-5.547854190796569, 50.066973342091806],
              [-5.548290754509881, 50.06696088046246],
              [-5.54925078157877, 50.064177595322903],
              [-5.549850193414204, 50.064345474194582],
              [-5.550139745086526, 50.063894868049665],
              [-5.550641223748765, 50.063853419123369],
              [-5.550437473759921, 50.062193465431598],
              [-5.552124460216556, 50.061873683175541],
              [-5.552722746727495, 50.060798232929429],
              [-5.553335142139976, 50.060820848188591],
              [-5.553395832690379, 50.060451021382171],
              [-5.554640853893865, 50.059844257288631],
              [-5.555301310709539, 50.059912171331248],
              [-5.555113888018019, 50.060495509978729],
              [-5.556490499787634, 50.061232401912598],
              [-5.557878761847403, 50.061088139154116],
              [-5.560782428863267, 50.062251331821876],
              [-5.56227417874343, 50.062597759200202],
              [-5.563225751133581, 50.062501038517873],
              [-5.564256799234678, 50.06179908396232],
              [-5.564109265340306, 50.061524720687196],
              [-5.563596936842525, 50.061570160557515],
              [-5.564074103414442, 50.061050773172219],
              [-5.56347722576616, 50.059654833121108],
              [-5.563966987056677, 50.059117065230673],
              [-5.563559595005261, 50.058862386004691],
              [-5.563671010701772, 50.058235485767376],
              [-5.566187593230167, 50.05753551724149],
              [-5.568185375056312, 50.056173107811908],
              [-5.567940126518745, 50.055956637258767],
              [-5.568986282117622, 50.054971674628483],
              [-5.570303983422801, 50.055328753885433],
              [-5.571801052997434, 50.054865183980922],
              [-5.572889797876504, 50.054905404711576],
              [-5.572365580717787, 50.054207217389482],
              [-5.572863085775302, 50.054339431226218],
              [-5.573285278715224, 50.053571587897338],
              [-5.57426770821302, 50.053140942438567],
              [-5.575647083816388, 50.053087605226821],
              [-5.576348639297632, 50.052600828699632],
              [-5.576161479179096, 50.052132472539505],
              [-5.576901297761916, 50.051878428353476],
              [-5.57745150685601, 50.052024290575659],
              [-5.577177787416807, 50.051725947578632],
              [-5.577772847150515, 50.051685990039985],
              [-5.578461252059824, 50.052317906804397],
              [-5.580682977548177, 50.052637059603185],
              [-5.580995002332248, 50.053242801076202],
              [-5.58179871035887, 50.052589993236417],
              [-5.582956903169649, 50.052943760352719],
              [-5.583270893120877, 50.05258227981173],
              [-5.584370325349861, 50.053328310271773],
              [-5.584641855455125, 50.052397741958373],
              [-5.585696235291227, 50.052652128637483],
              [-5.585614289344148, 50.052209321953875],
              [-5.586203361822089, 50.051658487980887],
              [-5.586527012732184, 50.051988553381833],
              [-5.587585359682933, 50.051266646980238],
              [-5.589004738309008, 50.051282086844125],
              [-5.588934361763307, 50.05099456871411],
              [-5.590314331240704, 50.050875360816086],
              [-5.589699443679198, 50.050462557982044],
              [-5.58989784335996, 50.05025668445515],
              [-5.592339716558988, 50.051726684696582],
              [-5.592378148500712, 50.052053877242066],
              [-5.59401300908044, 50.05259608859344],
              [-5.596119886110612, 50.052648602419822],
              [-5.597129560840219, 50.052286190973192],
              [-5.597146539360929, 50.05197167508576],
              [-5.599160635841577, 50.052222243594741],
              [-5.600940718427132, 50.051904250408668],
              [-5.605428997386841, 50.048908236032801],
              [-5.607663652398074, 50.048263806885103],
              [-5.611489994241524, 50.049502274232736],
              [-5.612161476630094, 50.04947595245433],
              [-5.612532145294912, 50.048979470435562],
              [-5.614211832247365, 50.049930257011098],
              [-5.614593657972402, 50.049452313746393],
              [-5.615323415984074, 50.049736347105224],
              [-5.615204286694015, 50.049285721667481],
              [-5.615738759684671, 50.04884979696206],
              [-5.619109857882765, 50.051238517941385],
              [-5.619823737054949, 50.050940022633156],
              [-5.619705965801367, 50.050155575533033],
              [-5.6200194765578, 50.05029243348968],
              [-5.620013232120382, 50.049949848100262],
              [-5.620394089312184, 50.05001622556582],
              [-5.620191347390504, 50.049717564303457],
              [-5.620514952313468, 50.049858604072462],
              [-5.620836905219562, 50.049476976820181],
              [-5.62232714752083, 50.049561768720693],
              [-5.623377587149933, 50.050039958475061],
              [-5.624108761560916, 50.049934328293254],
              [-5.623775793184207, 50.049706321590733],
              [-5.624239018522114, 50.049808797802413],
              [-5.62408857426513, 50.049518404459931],
              [-5.625039175717858, 50.04958044666656],
              [-5.625317547538462, 50.049066116177215],
              [-5.624662581811888, 50.048755514983128],
              [-5.625520906362885, 50.048687285003837],
              [-5.625057833244776, 50.048457053652555],
              [-5.625547337018379, 50.048426448665403],
              [-5.625158139123184, 50.048101239156772],
              [-5.625849057949284, 50.048444001266375],
              [-5.625542330245388, 50.047063579003364],
              [-5.627587711270479, 50.047470118990432],
              [-5.627941794686967, 50.047311487851921],
              [-5.628640338675096, 50.047644098163367],
              [-5.62924446498554, 50.047484832346079],
              [-5.6285572118092, 50.046598564125325],
              [-5.628925300957685, 50.046403503890389],
              [-5.627819449487277, 50.045653593810329],
              [-5.627647330914868, 50.045039095946663],
              [-5.628170258362027, 50.045100101270855],
              [-5.627928389437178, 50.044579555304217],
              [-5.628522425023506, 50.044786781780942],
              [-5.62826779704325, 50.044300824066781],
              [-5.628996739157458, 50.043889337881417],
              [-5.630149711186482, 50.04406106055135],
              [-5.630090281192775, 50.043200120620845],
              [-5.630740155949034, 50.043425379942313],
              [-5.630877929816002, 50.042955925020649],
              [-5.632942279145786, 50.044169695786657],
              [-5.63293971997778, 50.043821596375686],
              [-5.633866676304152, 50.044218990619243],
              [-5.634267559694639, 50.04405167484434],
              [-5.634160716046992, 50.043100454692272],
              [-5.634694829884917, 50.043032424090491],
              [-5.634219989336851, 50.042701833196602],
              [-5.634381775602626, 50.042365674519793],
              [-5.635745245478214, 50.041626552457096],
              [-5.635025994907711, 50.040871780505292],
              [-5.635711398400114, 50.040643353654566],
              [-5.636136067155723, 50.04086485057681],
              [-5.636395649508676, 50.039975010462349],
              [-5.637701713202739, 50.041100023943059],
              [-5.637034276552956, 50.041547423142305],
              [-5.637872292679536, 50.041988062246354],
              [-5.637479154954044, 50.042165044329273],
              [-5.637810841543508, 50.042375958010915],
              [-5.637623672347774, 50.042911749816781],
              [-5.638726459361413, 50.042660308413609],
              [-5.639127957667971, 50.04335036088861],
              [-5.63979451384868, 50.042928168722696],
              [-5.639941215521842, 50.043500263117508],
              [-5.640548580431721, 50.04342180778319],
              [-5.641210844150518, 50.043717695171708],
              [-5.640946304394125, 50.043914040870618],
              [-5.642652040321513, 50.044613486351707],
              [-5.643169798160717, 50.044384886075079],
              [-5.643423257985558, 50.043692254213013],
              [-5.643972315415528, 50.044097848075381],
              [-5.644295579791357, 50.043828573340143],
              [-5.644995490157926, 50.043975706278331],
              [-5.645000824220398, 50.043530191437043],
              [-5.645855768620111, 50.043253187322016],
              [-5.646721037210484, 50.043554353552203],
              [-5.647012240425643, 50.043396741836062],
              [-5.64792520436935, 50.043884433904708],
              [-5.648468912445503, 50.043740463539855],
              [-5.648322515454162, 50.042934449567817],
              [-5.649657097285676, 50.043197428249876],
              [-5.650310457862546, 50.042897048545996],
              [-5.650915212646026, 50.042490233084024],
              [-5.650010003265043, 50.040191239845363],
              [-5.650907997745426, 50.040611003048163],
              [-5.65068856957779, 50.040370499470605],
              [-5.651656261075732, 50.040034119201451],
              [-5.651750780089849, 50.039678461649224],
              [-5.652340814245965, 50.039924379180043],
              [-5.652329852843677, 50.03955944934247],
              [-5.653185756773381, 50.039774491674351],
              [-5.652640176524342, 50.038219923670077],
              [-5.65304860486736, 50.038392387802013],
              [-5.652942906537395, 50.038012450057749],
              [-5.65340511732863, 50.038230901516343],
              [-5.653159408605489, 50.037828386526463],
              [-5.654352037666418, 50.037860964446438],
              [-5.656328820301882, 50.038708208644152],
              [-5.656789549567788, 50.039219093933347],
              [-5.658219871816122, 50.039437566694836],
              [-5.65888747281513, 50.038958548552841],
              [-5.659399709322172, 50.039062935317943],
              [-5.659741894753063, 50.038548301914282],
              [-5.660662922155399, 50.038646071787134],
              [-5.660626594549522, 50.038242356734251],
              [-5.661156749225547, 50.038288589302354],
              [-5.660891648262572, 50.037999164518247],
              [-5.66134752966273, 50.038280763121399],
              [-5.661621504845531, 50.037842953335598],
              [-5.662019769188846, 50.038028302912068],
              [-5.661839155386722, 50.0375643690139],
              [-5.662962138918147, 50.038057902970564],
              [-5.663084167857731, 50.037697767023012],
              [-5.663474981594813, 50.037785280373676],
              [-5.663960517851783, 50.038290855170231],
              [-5.665838368010181, 50.037948070228083],
              [-5.665982603015713, 50.037586328936875],
              [-5.66643723853389, 50.037777977603071],
              [-5.6664571274059, 50.03739767693142],
              [-5.668987790344441, 50.037610891526299],
              [-5.669643376377143, 50.037159181207201],
              [-5.669260942472484, 50.036467727296454],
              [-5.669759646726114, 50.036632776241831],
              [-5.669929574002603, 50.03615055654145],
              [-5.671550974556825, 50.037133853295792],
              [-5.672299289659651, 50.037219907578809],
              [-5.67193542659321, 50.035967362759564],
              [-5.672895758510804, 50.034678256655482],
              [-5.673405027507542, 50.03474398837853],
              [-5.673308960836768, 50.03502234052921],
              [-5.674229195767313, 50.03487261046164],
              [-5.674694699626706, 50.035242921963651],
              [-5.675407405741383, 50.035137549769068],
              [-5.676671023577998, 50.035660679990507],
              [-5.676554795921223, 50.035255004816833],
              [-5.677054561690522, 50.035232851685592],
              [-5.676767468261867, 50.034857789253138],
              [-5.678102239384245, 50.035306670745463],
              [-5.679159512984906, 50.034958217388663],
              [-5.679969843635063, 50.03552629068357],
              [-5.679407221787039, 50.035877042300186],
              [-5.681422622911029, 50.036494111834706],
              [-5.681746402644219, 50.037274661242272],
              [-5.682432317971817, 50.036946971815674],
              [-5.684618725556319, 50.037898638865485],
              [-5.682536306583301, 50.038395780718496],
              [-5.681770837872296, 50.039290108443815],
              [-5.682007873608398, 50.039758518424634],
              [-5.682808678693978, 50.03967189502027],
              [-5.683392415221018, 50.040161673998647],
              [-5.684810161276506, 50.040122905277833],
              [-5.685236055246773, 50.040448550121397],
              [-5.685385636484986, 50.040176583278033],
              [-5.685680846951117, 50.040619744017711],
              [-5.686100143812582, 50.040567722123427],
              [-5.686027041093186, 50.040944321743417],
              [-5.686423180919218, 50.040699599966764],
              [-5.687343668724914, 50.041845324564058],
              [-5.687891864731089, 50.041961937420425],
              [-5.686904090987393, 50.042561973638946],
              [-5.687130850454774, 50.042913739671761],
              [-5.68769483194428, 50.042926386019658],
              [-5.687338394184836, 50.043251721448776],
              [-5.688050333601011, 50.04293486748206],
              [-5.688681738527378, 50.043443796696124],
              [-5.690222608711899, 50.043751926513423],
              [-5.690523321464688, 50.044520591250944],
              [-5.691709866243227, 50.0450352183368],
              [-5.690676479977816, 50.045096020161886],
              [-5.690997600370379, 50.045402488238743],
              [-5.689144872605926, 50.045528053259105],
              [-5.68895444290923, 50.046214286697094],
              [-5.689549430282363, 50.046593914289772],
              [-5.688878109109602, 50.046402953861715],
              [-5.68936156603845, 50.046822120058117],
              [-5.688592571085547, 50.046833100413735],
              [-5.688813629256027, 50.047364984453758],
              [-5.688115289648828, 50.047604935281754],
              [-5.688529658002324, 50.047906643488439],
              [-5.688326408211499, 50.048571690780228],
              [-5.690580525164962, 50.049504900895862],
              [-5.690920940915968, 50.049279932692372],
              [-5.691539662838565, 50.049676788595541],
              [-5.69231580711145, 50.049412745920087],
              [-5.692395012779539, 50.049879865515599],
              [-5.692941442153296, 50.049808474518315],
              [-5.693398241160008, 50.050098017365457],
              [-5.695342137989043, 50.049611398776705],
              [-5.695824242883011, 50.049811954788751],
              [-5.69496438794171, 50.05038638698084],
              [-5.695538879367645, 50.050534515493993],
              [-5.69468778448898, 50.050659716197366],
              [-5.695174493243148, 50.050865526409332],
              [-5.694323845436136, 50.051305604819156],
              [-5.693655063125433, 50.051057909434512],
              [-5.694207641856799, 50.051429868280387],
              [-5.694068567005607, 50.051838265765831],
              [-5.69461054451582, 50.051963143388122],
              [-5.694076414040189, 50.052503792947263],
              [-5.694821952968758, 50.052659069361475],
              [-5.694061806182899, 50.053277099920443],
              [-5.693197937065363, 50.053273139632466],
              [-5.693609003335967, 50.052912757108444],
              [-5.693034710767567, 50.053185671517511],
              [-5.692562726071269, 50.054062620284242],
              [-5.695683070028391, 50.054952839532419],
              [-5.698459716524801, 50.055207059095864],
              [-5.700154106753279, 50.056123756978188],
              [-5.701027387756018, 50.055868251708304],
              [-5.70084943721177, 50.055662504190401],
              [-5.701212326640491, 50.055711196724928],
              [-5.701501643355991, 50.055149541952318],
              [-5.705555265936083, 50.055001124607926],
              [-5.706148329248655, 50.055787395082362],
              [-5.705495191309092, 50.056559527462738],
              [-5.706696072702912, 50.057054652520684],
              [-5.706810884712769, 50.057437852187142],
              [-5.708884975578244, 50.057730483913232],
              [-5.709711421885165, 50.057574479034486],
              [-5.71070496443371, 50.058007821560352],
              [-5.70945080921774, 50.060533839564833],
              [-5.710165700413822, 50.061031885860302],
              [-5.711788025753023, 50.061408203675811],
              [-5.711859013388825, 50.060988468991432],
              [-5.712550066621613, 50.061014920910331],
              [-5.71249188165672, 50.061328980947728],
              [-5.713149097049908, 50.061640818822923],
              [-5.712371978653771, 50.06153165526203],
              [-5.712433981411089, 50.061990316573848],
              [-5.714589826820376, 50.062993693105952],
              [-5.714012925008793, 50.063775126372448],
              [-5.714224242015338, 50.064103943949448],
              [-5.714967597473046, 50.064209679066956],
              [-5.71476985769062, 50.064544407485052],
              [-5.715820110862895, 50.065256597076349],
              [-5.716062150838752, 50.065946106641263],
              [-5.715676645586385, 50.066148301872957],
              [-5.716372897287899, 50.066295125069956],
              [-5.715186301613457, 50.067398409440237],
              [-5.716118967892154, 50.067800369626127],
              [-5.716015346717837, 50.068574735747887],
              [-5.716448603280488, 50.068447483268251],
              [-5.716975707689657, 50.068774268270865],
              [-5.716709342913657, 50.069179577632468],
              [-5.715963272162197, 50.069256579412915],
              [-5.715858504106775, 50.068968434691328],
              [-5.715451039757702, 50.069085861169512],
              [-5.714348676746137, 50.068640749387278],
              [-5.71349669502351, 50.068956850666162],
              [-5.713223779877241, 50.069657463437423],
              [-5.712377800201211, 50.069888792067914],
              [-5.711762311831346, 50.069738458715563],
              [-5.711584272465861, 50.070039261324169],
              [-5.71042730287039, 50.070334514777969],
              [-5.709421049677035, 50.071278115876488],
              [-5.709048212039113, 50.072948193068072],
              [-5.708197090766054, 50.073729371276407],
              [-5.708679603656051, 50.073840792738551],
              [-5.708338669528433, 50.074259263760325],
              [-5.70930821658186, 50.074377593765639],
              [-5.708883030251277, 50.074537849411136],
              [-5.709332553776835, 50.074692609607737],
              [-5.709494003394743, 50.075262351416903],
              [-5.708753000552391, 50.07593114573784],
              [-5.709343249564159, 50.076405295450641],
              [-5.708928701337878, 50.076594900737859],
              [-5.709111965479167, 50.077356480711295],
              [-5.708633367911053, 50.077766761090594],
              [-5.70480430216405, 50.078096128220622],
              [-5.704139256247113, 50.078917212357347],
              [-5.70457413882494, 50.078193453618134],
              [-5.70343880867882, 50.07801110299539],
              [-5.701659339611051, 50.07790599258135],
              [-5.698546062356414, 50.078344548997663],
              [-5.694755773201328, 50.080487033387151],
              [-5.692546877671541, 50.082979394873902],
              [-5.691677224336057, 50.085470458429491],
              [-5.690915286708074, 50.086344931091176],
              [-5.69091149010426, 50.087077403931779],
              [-5.688964760888559, 50.088511400053847],
              [-5.688523272018747, 50.090390514229284],
              [-5.689251406643973, 50.092458236053112],
              [-5.691470575781613, 50.094145601107961],
              [-5.694060075720214, 50.094337528572495],
              [-5.694067048793696, 50.094627907924284],
              [-5.694540587579509, 50.094475165363171],
              [-5.694006598293577, 50.095259620590639],
              [-5.694166479795426, 50.096028264080644],
              [-5.695782999960947, 50.097695163070092],
              [-5.696371405902086, 50.097617025121195],
              [-5.696454135899678, 50.098037244270841],
              [-5.696935088080194, 50.097998517163965],
              [-5.697251659441421, 50.098422075453215],
              [-5.696950131473657, 50.098665611401579],
              [-5.697461169948244, 50.098926421060028],
              [-5.696987597919128, 50.099079176231278],
              [-5.697475957920998, 50.099463067566546],
              [-5.696893741418609, 50.100275164811762],
              [-5.697285076037547, 50.10150516505017],
              [-5.696595691984568, 50.102705747172983],
              [-5.696996620582516, 50.104967392213631],
              [-5.698323422450068, 50.106663288806978],
              [-5.698813454647546, 50.108446147112232],
              [-5.69783140969961, 50.109480630449305],
              [-5.69732479310467, 50.110947993366558],
              [-5.698005672042858, 50.111540771032736],
              [-5.700653116591511, 50.112634005350593],
              [-5.699358434936296, 50.115020822253918],
              [-5.700282520240904, 50.115391700269527],
              [-5.700672828775443, 50.11600723069666],
              [-5.700262822336313, 50.116514247669727],
              [-5.700559364219164, 50.117220041050935],
              [-5.700256807041288, 50.117995336257017],
              [-5.701144951709018, 50.118733530355442],
              [-5.700487227170865, 50.11889577435182],
              [-5.700424938894789, 50.11944837490968],
              [-5.703008413094217, 50.12077572179652],
              [-5.703612138853618, 50.121580560536273],
              [-5.70550075425113, 50.122370434731508],
              [-5.705509184308557, 50.122859599230459],
              [-5.70518573399865, 50.122893326974129],
              [-5.706152364214037, 50.124879551537113],
              [-5.705465073330481, 50.125601478256378],
              [-5.705791408937539, 50.126021103691997],
              [-5.709029966306143, 50.1265113602499],
              [-5.710183348944043, 50.12732737385663],
              [-5.70766191616343, 50.1289437991081],
              [-5.704289195048654, 50.128132099562954],
              [-5.703679272460945, 50.12837110675617],
              [-5.702218038305808, 50.13016317303601],
              [-5.702423235822992, 50.131043719388451],
              [-5.703382128435678, 50.131588904178464],
              [-5.702799282803745, 50.131889120295888],
              [-5.701917342394997, 50.131832706649575],
              [-5.703686277845265, 50.132608439868584],
              [-5.703091111281167, 50.132676929961995],
              [-5.704200007667372, 50.13335137245393],
              [-5.703298664309321, 50.13337026338192],
              [-5.703204973077106, 50.133661157171758],
              [-5.70522794423421, 50.134354080428089],
              [-5.704808648301513, 50.134506929109627],
              [-5.704865284520536, 50.135076425037887],
              [-5.704291511259015, 50.13516852925445],
              [-5.703583501309319, 50.134791679149473],
              [-5.703836092441283, 50.135506065570084],
              [-5.701980821844334, 50.134737588375529],
              [-5.702314443733268, 50.13517858356505],
              [-5.701626762163372, 50.135336389858814],
              [-5.7011625612905, 50.135868529385405],
              [-5.701650632354498, 50.136279405615205],
              [-5.701062417993482, 50.136311675164286],
              [-5.700616720732536, 50.136811732524507],
              [-5.698874667270644, 50.137038251052232],
              [-5.696884802813596, 50.136611369809884],
              [-5.695750707456414, 50.136825656411951],
              [-5.694899997161488, 50.137447460261363],
              [-5.694271743182511, 50.137396399834898],
              [-5.694537707125833, 50.137977224843581],
              [-5.693503994396119, 50.137950772529479],
              [-5.694010953296829, 50.138861307150741],
              [-5.693629268108929, 50.139847829713013],
              [-5.694032814205893, 50.140288420449906],
              [-5.693370792213601, 50.140852919226248],
              [-5.694106767002888, 50.140993221874723],
              [-5.694208398544764, 50.141348961173293],
              [-5.693465886546563, 50.141668609286029],
              [-5.692380525839714, 50.141499840564727],
              [-5.692946008217481, 50.14202524818797],
              [-5.692538066529838, 50.142163294662431],
              [-5.693010166433979, 50.14224003059357],
              [-5.692588238026681, 50.142505378974263],
              [-5.694226522445778, 50.14305419762151],
              [-5.693855102123631, 50.143284653139162],
              [-5.694199490107516, 50.143484211178212],
              [-5.693705718964477, 50.143526029661238],
              [-5.694181887365207, 50.143909425885624],
              [-5.693856115934985, 50.144259885457053],
              [-5.694613769443118, 50.145805711300035],
              [-5.694212971016002, 50.145873359940232],
              [-5.693943225514389, 50.146587309221388],
              [-5.694169238861937, 50.14704884893704],
              [-5.693674783655551, 50.147118579104159],
              [-5.692978851643806, 50.146699893270153],
              [-5.69334234302966, 50.147382879014998],
              [-5.69250505077598, 50.147091949503753],
              [-5.692696293322503, 50.14742954208424],
              [-5.692053722354953, 50.147394219007246],
              [-5.691449587053836, 50.147803015456027],
              [-5.693079323299865, 50.148946818850568],
              [-5.691637367392196, 50.149258134577664],
              [-5.691982119695553, 50.150369973173774],
              [-5.690896989216803, 50.150479185545912],
              [-5.690872696702553, 50.151035966683892],
              [-5.690565287314045, 50.150826224927897],
              [-5.69015615584539, 50.151513110388784],
              [-5.689635950594687, 50.151394707344259],
              [-5.689610899201085, 50.152196227586792],
              [-5.688951214090534, 50.151903220418532],
              [-5.688799677916661, 50.152335392425179],
              [-5.687028415290194, 50.15215419668408],
              [-5.687407694894685, 50.152697246624363],
              [-5.687171643987222, 50.15303223783701],
              [-5.686106967293671, 50.152644126363889],
              [-5.685657576398206, 50.153773121763301],
              [-5.685134961296915, 50.153514423622717],
              [-5.685032361390443, 50.154091684518143],
              [-5.684376049474781, 50.154097240572902],
              [-5.684087253760507, 50.155057384346918],
              [-5.68305638114628, 50.155818871358626],
              [-5.682756059050698, 50.155573796130554],
              [-5.682402566382327, 50.155893612625341],
              [-5.680874487297888, 50.155980804523139],
              [-5.681157332442389, 50.156235436118642],
              [-5.680573150308477, 50.156378132501217],
              [-5.68092178343559, 50.157014843347987],
              [-5.679230050759704, 50.158289395064756],
              [-5.679675231875017, 50.158979727096501],
              [-5.679325228262134, 50.159545937056592],
              [-5.678389322238251, 50.159030412100662],
              [-5.677633257807059, 50.160089926247117],
              [-5.675545515104702, 50.160587053373604],
              [-5.67594022571602, 50.161149443898047],
              [-5.673961336803816, 50.161240932555778],
              [-5.674968744439657, 50.161848694705832],
              [-5.674288855276045, 50.162570189393378],
              [-5.673543181976441, 50.162544325589636],
              [-5.673583031539253, 50.161804421924266],
              [-5.673017717169151, 50.162303656639722],
              [-5.671181850231563, 50.162690163141029],
              [-5.672342186527604, 50.163283211062868],
              [-5.672106251185099, 50.163877275235208],
              [-5.672915959616693, 50.164390549384485],
              [-5.672830621657726, 50.164683849839506],
              [-5.673316171765329, 50.164714357925988],
              [-5.673526715580339, 50.165304181156806],
              [-5.673274483389139, 50.166050810495435],
              [-5.672775537627352, 50.165991936357202],
              [-5.673001951950086, 50.166314051568826],
              [-5.672387504217999, 50.166210250183887],
              [-5.67234107710513, 50.166499619161478],
              [-5.67107283356752, 50.166801573134386],
              [-5.670301317113115, 50.166530891960306],
              [-5.67045382404819, 50.166163493395707],
              [-5.668739044078678, 50.166213244594879],
              [-5.668119587118105, 50.165513988355976],
              [-5.66663502743919, 50.166008968781433],
              [-5.666380670101154, 50.165778592826051],
              [-5.665342947561377, 50.165853674427062],
              [-5.664670337176273, 50.165465570922997],
              [-5.662172941407457, 50.165244852415071],
              [-5.662247793265668, 50.164795346725285],
              [-5.660729734488205, 50.16455986677115],
              [-5.660324585291346, 50.164023846240561],
              [-5.659988004455657, 50.164182914785435],
              [-5.658963969531333, 50.163923725388955],
              [-5.656832578810736, 50.164294133389468],
              [-5.65618292076919, 50.164956985873602],
              [-5.656453342299406, 50.166223310774321],
              [-5.655218199068679, 50.166464667777063],
              [-5.653549276386225, 50.167684133503066],
              [-5.653256379858101, 50.167425253680634],
              [-5.652559543720633, 50.167764785791455],
              [-5.651619743564786, 50.167673818025499],
              [-5.6511056909816, 50.168261296857892],
              [-5.649323042459555, 50.167863055626057],
              [-5.64840597665879, 50.168235581513308],
              [-5.646689881839329, 50.168122204492562],
              [-5.645919652535611, 50.168363239261588],
              [-5.646050386733142, 50.168902536535839],
              [-5.645130692131805, 50.168854968335509],
              [-5.643688671208748, 50.168036582011744],
              [-5.641906648038296, 50.16795849331983],
              [-5.639855150560375, 50.167339129780288],
              [-5.637809036974672, 50.167434805762092],
              [-5.638600192807503, 50.167652904417132],
              [-5.638218045032784, 50.16822719003185],
              [-5.637597497024061, 50.167508015960529],
              [-5.636332583542355, 50.167377637107769],
              [-5.636280876653267, 50.16813498753212],
              [-5.635713573067842, 50.167913455733405],
              [-5.635563382267379, 50.168278034226638],
              [-5.634904436505846, 50.168380555617986],
              [-5.633961583555359, 50.168212168122302],
              [-5.633834737961886, 50.167537785031904],
              [-5.633547351121295, 50.168032613503584],
              [-5.632672877277058, 50.167879167522926],
              [-5.632066943615716, 50.168347980535223],
              [-5.631725634289193, 50.167892634244751],
              [-5.631267116491585, 50.168070730195225],
              [-5.631162596207174, 50.16743163272249],
              [-5.630809630614546, 50.168077853374513],
              [-5.629053991502685, 50.167737834966069],
              [-5.628930202447071, 50.168358885890655],
              [-5.628052016949455, 50.168488919291875],
              [-5.627617439020046, 50.168909164430083],
              [-5.627821237880317, 50.16952806586842],
              [-5.626739822178443, 50.169231700647138],
              [-5.625607667806957, 50.1693192735938],
              [-5.625687733800183, 50.16985837594067],
              [-5.624804838295282, 50.170518440532128],
              [-5.625420879922773, 50.171956663169198],
              [-5.625012845749048, 50.172321188066675],
              [-5.62443137694115, 50.172190011311486],
              [-5.624660155804649, 50.1728801113778],
              [-5.62412984976, 50.173369010002936],
              [-5.623578425432384, 50.173431220060003],
              [-5.623331810687912, 50.172986386773381],
              [-5.622616768700243, 50.173366789698775],
              [-5.621796400385579, 50.173040633162437],
              [-5.622256789019661, 50.17362634120105],
              [-5.621613141340977, 50.174524520359839],
              [-5.620237302435706, 50.173892710063178],
              [-5.619620723845413, 50.173964131890529],
              [-5.621468299112808, 50.176756636476071],
              [-5.621258476616918, 50.177427146296772],
              [-5.620571055282351, 50.177729301422197],
              [-5.620184199425924, 50.177412093955191],
              [-5.619612654898796, 50.177894160600331],
              [-5.619029451195268, 50.17757407889372],
              [-5.618951191223307, 50.177002528364291],
              [-5.61766663659952, 50.176967022284089],
              [-5.618701056919585, 50.178474100807939],
              [-5.617585516657495, 50.179432858414529],
              [-5.617047870882829, 50.178863036153295],
              [-5.616365367571197, 50.178915802959168],
              [-5.616172455006862, 50.17964425425491],
              [-5.615511578337755, 50.180076003809432],
              [-5.614557509575353, 50.179816939547784],
              [-5.615440339635086, 50.180579341911937],
              [-5.614845000926666, 50.181099911859796],
              [-5.614849622565445, 50.181828502842393],
              [-5.614401780647086, 50.181927925685301],
              [-5.614691530843095, 50.180644961087772],
              [-5.613909615637079, 50.181700347027878],
              [-5.613302882313939, 50.181847899569611],
              [-5.611402038738125, 50.181686669334269],
              [-5.61141858586254, 50.181367670252584],
              [-5.610188303670652, 50.181792822020704],
              [-5.610154183231534, 50.180950891174177],
              [-5.608247110888756, 50.180725027046513],
              [-5.606974490045641, 50.180997617625316],
              [-5.606737972382581, 50.181521382624851],
              [-5.607213945844548, 50.18266805938395],
              [-5.607935937241471, 50.182469271954332],
              [-5.608174896717031, 50.183872524887754],
              [-5.608543745609833, 50.184025692236389],
              [-5.60890678939388, 50.183804775661031],
              [-5.609201328976429, 50.184198665514359],
              [-5.608861027644972, 50.185073836337907],
              [-5.608437917403063, 50.185650191792128],
              [-5.608079243298038, 50.185446325982852],
              [-5.607763720997519, 50.185777321176886],
              [-5.607700496108572, 50.186369471584207],
              [-5.606667981151554, 50.186000322279845],
              [-5.606415030301489, 50.186176424335464],
              [-5.60675249314916, 50.18565312091124],
              [-5.604848245356207, 50.185541369647829],
              [-5.604635833358897, 50.186162444726705],
              [-5.603903718025399, 50.186079028825866],
              [-5.604185695440192, 50.186622666615449],
              [-5.603040532513499, 50.18708288203554],
              [-5.603470515977533, 50.187415000619843],
              [-5.603091730859335, 50.1874276649772],
              [-5.603561197679403, 50.187930394002343],
              [-5.603151307499758, 50.188274203605694],
              [-5.603478097248517, 50.188650012292129],
              [-5.602546158798226, 50.18874193186462],
              [-5.601652692352195, 50.188246964518015],
              [-5.602267706221626, 50.188710093],
              [-5.601787895914239, 50.188646718832487],
              [-5.601213697872939, 50.189711759358637],
              [-5.600478884350149, 50.189462865942701],
              [-5.599537490050775, 50.189634225445026],
              [-5.600040310267557, 50.190207907196232],
              [-5.598526675685515, 50.190447395204941],
              [-5.599771896724051, 50.191314732032581],
              [-5.599656828536841, 50.192020044233949],
              [-5.600563679957491, 50.19276651924293],
              [-5.600234212803667, 50.192801934337353],
              [-5.600613370279661, 50.193258895507626],
              [-5.599360902403157, 50.193431809445009],
              [-5.598365812233422, 50.192909380454473],
              [-5.597500580084277, 50.190126547164418],
              [-5.596452208908725, 50.190012404827762],
              [-5.596446522532842, 50.189470980510201],
              [-5.596122078436594, 50.18951702441646],
              [-5.595524565573468, 50.188670962230212],
              [-5.594673237342643, 50.188903367314346],
              [-5.594181707067387, 50.188628003090443],
              [-5.594012432630111, 50.189060589603109],
              [-5.593208071121944, 50.189340110885674],
              [-5.593351878823202, 50.189687427123474],
              [-5.592619567618399, 50.189508580835977],
              [-5.592581374341433, 50.188832313443861],
              [-5.592036823769863, 50.188727719663447],
              [-5.591697635241028, 50.189025215063737],
              [-5.590799728174257, 50.188730928418309],
              [-5.590914876029749, 50.189499279432411],
              [-5.589513139955399, 50.190576375020363],
              [-5.589872791712687, 50.191316469394614],
              [-5.589252462647448, 50.191976225278893],
              [-5.588771626800243, 50.191675316642915],
              [-5.5885168425648, 50.191921609335139],
              [-5.58840654624644, 50.191721696033106],
              [-5.587586578684974, 50.191906295444369],
              [-5.587627293182032, 50.191477694619707],
              [-5.586842398576948, 50.191644110545553],
              [-5.58541027561886, 50.19089757034476],
              [-5.58526238741563, 50.191373565882095],
              [-5.58496570019034, 50.191192004565046],
              [-5.584641500515612, 50.191409844832094],
              [-5.585585160853909, 50.192276752550207],
              [-5.585239465295871, 50.192843429680913],
              [-5.585718859190899, 50.192938373775839],
              [-5.585715958537415, 50.193628507634834],
              [-5.586073071403545, 50.193587781171928],
              [-5.584903263984889, 50.195555516308211],
              [-5.583932150674763, 50.195332712081608],
              [-5.583320553426706, 50.195830225064945],
              [-5.582855670664891, 50.19488193954389],
              [-5.581370309696776, 50.194229658909741],
              [-5.580726338019528, 50.194351197623277],
              [-5.579692357491028, 50.195496890042712],
              [-5.577367971342245, 50.195018886997133],
              [-5.576836964846782, 50.195657826583869],
              [-5.578205765074792, 50.197486922883819],
              [-5.577262827820845, 50.196985197367248],
              [-5.578124987019349, 50.197980631017515],
              [-5.578675070756124, 50.197877297585237],
              [-5.578333055250096, 50.198550899196569],
              [-5.578688844610574, 50.198754045897125],
              [-5.578024260274407, 50.199125411757521],
              [-5.57673633535709, 50.19890242012341],
              [-5.5771097530837, 50.199603442268042],
              [-5.575623920434433, 50.200594788451667],
              [-5.575631747604123, 50.200980503093291],
              [-5.574828730428675, 50.201132099761622],
              [-5.573862448001146, 50.200749821289982],
              [-5.57310759891122, 50.199989462781751],
              [-5.572726647852546, 50.200143340662123],
              [-5.572321374226969, 50.199409098595403],
              [-5.572511189229899, 50.19861065057259],
              [-5.571601419157014, 50.198308483088937],
              [-5.570430194175565, 50.198727787603218],
              [-5.569637256139796, 50.19833834080255],
              [-5.568172673747627, 50.199615929080224],
              [-5.565399561940284, 50.199624706511095],
              [-5.56490076478168, 50.200754714476197],
              [-5.564064484393248, 50.201268922312934],
              [-5.564344337903625, 50.200811394296743],
              [-5.563821487603176, 50.201092855252917],
              [-5.563890156324825, 50.201487496935634],
              [-5.562353233181563, 50.201666045119772],
              [-5.561737248023364, 50.202729462626266],
              [-5.560604537709717, 50.203216757361588],
              [-5.560341528525188, 50.204051616507179],
              [-5.559476145769754, 50.204232908865279],
              [-5.559493397391829, 50.204483384994525],
              [-5.557108629032778, 50.203781011174513],
              [-5.555119590335948, 50.204233323627399],
              [-5.555014433631678, 50.205326033730962],
              [-5.554549568423666, 50.205314185357508],
              [-5.55440373353295, 50.20580356943583],
              [-5.555075314062599, 50.206087979676134],
              [-5.553433637273241, 50.206236329770483],
              [-5.554660634471989, 50.206317502180688],
              [-5.554785091557999, 50.206577289669333],
              [-5.554139369951797, 50.20682918244075],
              [-5.554648425195103, 50.20694493903224],
              [-5.553753249569587, 50.207461773904392],
              [-5.555094503520249, 50.207552037846312],
              [-5.554952193349681, 50.208013425045571],
              [-5.555379692363196, 50.207880672115309],
              [-5.555489930248528, 50.208269545905409],
              [-5.555093350267857, 50.20849761548061],
              [-5.555822909581452, 50.208411384821133],
              [-5.55491147766417, 50.20933177405584],
              [-5.553261618817356, 50.209842933926055],
              [-5.551170151636765, 50.209392355686838],
              [-5.550766297964034, 50.209787069608453],
              [-5.550101088578151, 50.209569913720415],
              [-5.550249219476608, 50.210318395816927],
              [-5.549276048821028, 50.210541593568522],
              [-5.55002707650964, 50.210970247477221],
              [-5.548974637587921, 50.211334415030201],
              [-5.549226294585432, 50.211513846764333],
              [-5.548801092466044, 50.21190470811716],
              [-5.548111832606556, 50.212043641133754],
              [-5.547864570678776, 50.211810093140564],
              [-5.546808429325782, 50.212011515790337],
              [-5.545167723805791, 50.211703590426914],
              [-5.5450015929605, 50.21222327002102],
              [-5.544459594107604, 50.212211937680664],
              [-5.544389846110849, 50.212481266398115],
              [-5.543218563628764, 50.211621878540093],
              [-5.54264705138723, 50.211798567655201],
              [-5.542909527977826, 50.212256576283828],
              [-5.542217949655357, 50.21191062968856],
              [-5.541983149081257, 50.212790464295672],
              [-5.541549336865797, 50.212574292790734],
              [-5.541954675045469, 50.213181784516543],
              [-5.541524986700085, 50.213740091477717],
              [-5.541795266394883, 50.214095303518157],
              [-5.54130598951536, 50.214228091393643],
              [-5.540569149172033, 50.213403999234508],
              [-5.540787672172495, 50.214309583230055],
              [-5.540098454102316, 50.214714764993957],
              [-5.539667282501214, 50.214610063223603],
              [-5.53964932915765, 50.215408605965841],
              [-5.539084018734651, 50.21559408426409],
              [-5.539381149478466, 50.21585761760474],
              [-5.53863648068776, 50.216215000497279],
              [-5.537319183849056, 50.215750456645281],
              [-5.537034811855375, 50.215356079142708],
              [-5.536251871283258, 50.215499595498549],
              [-5.536099916497755, 50.21520823940962],
              [-5.536025909445672, 50.215534370607607],
              [-5.535851436814408, 50.215260793829692],
              [-5.53551178463981, 50.215521247027148],
              [-5.53499874071808, 50.215143727802037],
              [-5.534431506294675, 50.215227580821789],
              [-5.534075399149803, 50.2146401560153],
              [-5.532747839955914, 50.214016634508944],
              [-5.5307147777538, 50.213787023502746],
              [-5.530439353193307, 50.213531804593345],
              [-5.529918404742718, 50.213672704954519],
              [-5.530007030024509, 50.213980389813251],
              [-5.528778637921199, 50.213787440125749],
              [-5.528698184609145, 50.214121859164869],
              [-5.528212621682009, 50.214191502650394],
              [-5.528014895701202, 50.213887133610029],
              [-5.527170921077099, 50.214058534173823],
              [-5.526855462363146, 50.213678572210192],
              [-5.52644026472831, 50.214188699408091],
              [-5.524593427107381, 50.214050484316189],
              [-5.524557575222668, 50.214533787632284],
              [-5.522510523346602, 50.214874837895714],
              [-5.52084326061959, 50.214985713419395],
              [-5.519995316190249, 50.214647078568774],
              [-5.520318702402198, 50.215439758880819],
              [-5.520938051498295, 50.215758338133121],
              [-5.520371144631854, 50.216037336709142],
              [-5.520724248051029, 50.216338803629199],
              [-5.520361360503582, 50.216399293930678],
              [-5.520217608254115, 50.2170172179367],
              [-5.519325794792624, 50.217168420045425],
              [-5.519412936908566, 50.216790622104277],
              [-5.518375856060663, 50.217253903650409],
              [-5.517976040747514, 50.216413154787247],
              [-5.517771770937706, 50.216799900180312],
              [-5.516831216664705, 50.21712709308072],
              [-5.515504157530336, 50.215517341927558],
              [-5.514898385326186, 50.215897352225539],
              [-5.514816690801867, 50.215625432892963],
              [-5.513791799395872, 50.215358686595067],
              [-5.512831222867463, 50.215642370431695],
              [-5.512466638847463, 50.215355621867971],
              [-5.512007639877402, 50.215863422868544],
              [-5.511138520336313, 50.215941903005536],
              [-5.510597176698115, 50.215615514206398],
              [-5.510574439627744, 50.215916685515722],
              [-5.510086412013808, 50.216010617729133],
              [-5.509898104210967, 50.2174170699084],
              [-5.508695341245692, 50.218432262392042],
              [-5.508492372011498, 50.217978677716431],
              [-5.507928967537153, 50.218249411668779],
              [-5.507975209083755, 50.217561580565331],
              [-5.507153910784106, 50.217356094626965],
              [-5.506743605971688, 50.217611399885463],
              [-5.506137909142877, 50.217458768252698],
              [-5.50597081617, 50.217799386104417],
              [-5.5051848601177, 50.217636001678322],
              [-5.505125284972356, 50.217894201381533],
              [-5.504061534070063, 50.217498993287585],
              [-5.503222232010776, 50.218233257444773],
              [-5.502728279330503, 50.218055643012256],
              [-5.502382468055928, 50.218712928401395],
              [-5.500657651313947, 50.217830236805398],
              [-5.50058332168913, 50.219016417193842],
              [-5.499883265381974, 50.219030335350098],
              [-5.498436970612324, 50.219882729669663],
              [-5.495715040852541, 50.219418668377031],
              [-5.494859287146798, 50.218109365619576],
              [-5.493059073619651, 50.216749320639266],
              [-5.490797945238445, 50.216294665168959],
              [-5.490490713260876, 50.216464943629077],
              [-5.490053438963065, 50.216352144807061],
              [-5.48860494998156, 50.21700296079176],
              [-5.488108684788109, 50.216696704610378],
              [-5.488160286123095, 50.215943948230063],
              [-5.486915683671459, 50.215142881667148],
              [-5.485923913547543, 50.214828113463824],
              [-5.484420743578136, 50.214940731781603],
              [-5.479634882291989, 50.216470689773629],
              [-5.479401127791193, 50.216995894481315],
              [-5.479890713357191, 50.21732667820045],
              [-5.479911852651423, 50.218329149052224],
              [-5.479516713993192, 50.218679264048532],
              [-5.478467255434946, 50.218862765738088],
              [-5.478282136008641, 50.219228172476427],
              [-5.477399116378811, 50.218731941864043],
              [-5.477279331916682, 50.219012621812482],
              [-5.476790998219656, 50.218737567603512],
              [-5.47661365125261, 50.21901727244645],
              [-5.476483498337485, 50.218770170632332],
              [-5.475623927452133, 50.218884982648355],
              [-5.475752655902802, 50.218553656508668],
              [-5.475159554657878, 50.218360902111449],
              [-5.476577502939816, 50.217383700588449],
              [-5.476158873667241, 50.216833063536136],
              [-5.474885090787764, 50.216688584592049],
              [-5.476252647149952, 50.215886526890003],
              [-5.476193551640235, 50.215478958614348],
              [-5.475586824143969, 50.215291113078983],
              [-5.476286977409464, 50.215182875654868],
              [-5.476609457372192, 50.213391017572484],
              [-5.476837856920067, 50.214140776407206],
              [-5.476472660883803, 50.215210599994812],
              [-5.477293555266813, 50.215355138162685],
              [-5.479377495439564, 50.214823059228408],
              [-5.479629244798544, 50.212985631065479],
              [-5.478748567349735, 50.213078609111832],
              [-5.479499669441523, 50.212804189319961],
              [-5.478832437378541, 50.21165015611998],
              [-5.478479954769392, 50.211353043741816],
              [-5.477498286168692, 50.211425649026999],
              [-5.476925446752473, 50.210912922857766],
              [-5.477502299306989, 50.210344154523845],
              [-5.477034789852935, 50.209178728097079],
              [-5.474275042223852, 50.207816557362079],
              [-5.472132539054228, 50.207481242304873],
              [-5.470910491043805, 50.207640153544837],
              [-5.470996623587706, 50.207166163192014],
              [-5.470309267784125, 50.20733336354715],
              [-5.469959467101426, 50.206994762011256],
              [-5.470218701212987, 50.206568674894164],
              [-5.469359825558244, 50.206458510469986],
              [-5.468897351264562, 50.204798997294688],
              [-5.469544239087169, 50.204306443831967],
              [-5.468955966531595, 50.204177389640662],
              [-5.46883211972126, 50.203706979082298],
              [-5.469469976577263, 50.203148122076875],
              [-5.46897438379426, 50.202673532168525],
              [-5.469675865169537, 50.201716927767315],
              [-5.469068994771252, 50.201659502484368],
              [-5.469314827642891, 50.201396653401645],
              [-5.468830773076593, 50.201621641986875],
              [-5.46953064903977, 50.200952073117897],
              [-5.469123321928658, 50.200186958166235],
              [-5.469112645383839, 50.199788734445413],
              [-5.467820928359799, 50.199216486962129],
              [-5.461793872861562, 50.197760003065483],
              [-5.460748523277331, 50.197646343074204],
              [-5.455661279926651, 50.198270273318272],
              [-5.454869589309552, 50.197458079745623],
              [-5.454943253109037, 50.196305242636441],
              [-5.452840333514779, 50.19522799142598],
              [-5.449719993576824, 50.195004129029151],
              [-5.449157973099865, 50.194653782786226],
              [-5.440071194439933, 50.194245209418277],
              [-5.43670741511832, 50.193401165298184],
              [-5.434871707263378, 50.190612658355903],
              [-5.433322904007353, 50.189085026773093],
              [-5.432786561732832, 50.189128784851839],
              [-5.431350298364849, 50.187859593327232],
              [-5.43070848236062, 50.18676481777311],
              [-5.434304426230406, 50.186010695418858],
              [-5.435649566724091, 50.185308736564522],
              [-5.436981686254365, 50.181462000352326],
              [-5.438306059550126, 50.180219939081134],
              [-5.440370975392068, 50.179068360231916],
              [-5.441123990208447, 50.178162579441221],
              [-5.440246140480263, 50.17688413093714],
              [-5.439237940851615, 50.177046269498199],
              [-5.441171724268894, 50.176219151306242],
              [-5.441158768098952, 50.176216393138745],
              [-5.439531258279848, 50.176861239681017],
              [-5.438669029077268, 50.176693379630379],
              [-5.438962505993935, 50.176897896251909],
              [-5.438550527319888, 50.177052250597541],
              [-5.436988220472147, 50.17607295102426],
              [-5.433958934640802, 50.173936937996068],
              [-5.433302951497045, 50.171842193639257],
              [-5.43233750901823, 50.170372856335327],
              [-5.432217211234605, 50.169671995554751],
              [-5.433547963267946, 50.166217575378624],
              [-5.433546871664941, 50.16528467815403],
              [-5.432989414309159, 50.164424923677402],
              [-5.433393588659345, 50.166154671028004],
              [-5.432050303187575, 50.169668836525645],
              [-5.432164787322665, 50.170405855129644],
              [-5.433253376097549, 50.17208836337533],
              [-5.433802176880963, 50.173997355502848],
              [-5.436695311488922, 50.175973670825414],
              [-5.437000191656263, 50.176278614469815],
              [-5.433727838474865, 50.177177990151797],
              [-5.43104180311731, 50.179085525502472],
              [-5.430508486262824, 50.18057670668744],
              [-5.43067848409127, 50.182165843463871],
              [-5.433017596363479, 50.183133041742373],
              [-5.43319955071162, 50.183462324232714],
              [-5.432515916382045, 50.184322815563597],
              [-5.429963748174391, 50.185543533109133],
              [-5.428876861943855, 50.186448309108492],
              [-5.429350715360566, 50.18881655098739],
              [-5.433209154035729, 50.190077097294989],
              [-5.433758553894004, 50.190624914266614],
              [-5.426582446176096, 50.18786645037418],
              [-5.425115541895209, 50.186812202225234],
              [-5.424275434990435, 50.185685460834968],
              [-5.42444869630669, 50.186970429826331],
              [-5.424949985888492, 50.187533202032441],
              [-5.424688685942731, 50.187993433268097],
              [-5.421626829909246, 50.184602164320061],
              [-5.421302955044668, 50.184775449199492],
              [-5.421872602565231, 50.185981259623055],
              [-5.424798502016349, 50.188763881440025],
              [-5.424550344836867, 50.188898954659777],
              [-5.422823469279316, 50.188225700450381],
              [-5.42230235772648, 50.188325637918055],
              [-5.419668692644175, 50.188491452799205],
              [-5.415235486946144, 50.19063451340908],
              [-5.409137622931, 50.191507454519893],
              [-5.406957562330907, 50.192811183770097],
              [-5.409689100622258, 50.191498447432195],
              [-5.412265034999298, 50.191384938700565],
              [-5.410248596373457, 50.191759092923903],
              [-5.409980480062814, 50.192263571784672],
              [-5.410824909786109, 50.192078617394692],
              [-5.41104165308152, 50.192661507713197],
              [-5.410115025181454, 50.192597880874281],
              [-5.412112123493191, 50.193523373017143],
              [-5.413681698270842, 50.193170431181819],
              [-5.41951678513863, 50.190678451503643],
              [-5.422406669589372, 50.188843451908781],
              [-5.422140577925274, 50.188551720603201],
              [-5.422464919996333, 50.188462985905048],
              [-5.423259178174687, 50.188812005752013],
              [-5.423383934036091, 50.189204165997609],
              [-5.425709649163008, 50.189472888632039],
              [-5.430180248751315, 50.190555324345866],
              [-5.432230597097331, 50.191827958656795],
              [-5.433055192390977, 50.191485091412289],
              [-5.433866648801028, 50.191681491516213],
              [-5.434358697559762, 50.192249895474404],
              [-5.433174284181376, 50.193456277550403],
              [-5.433581394150297, 50.193680836547038],
              [-5.430545744277812, 50.196295014172662],
              [-5.429865841654301, 50.197718550194374],
              [-5.428491546702973, 50.198430294793823],
              [-5.427770646757176, 50.199459187784754],
              [-5.427330792252342, 50.19940380784314],
              [-5.426206237555076, 50.200237695622647],
              [-5.423703542145156, 50.201047468108364],
              [-5.417827334796971, 50.204115725052546],
              [-5.408368417565478, 50.210421485917379],
              [-5.401175819766411, 50.216789597239384],
              [-5.398136346600321, 50.22087299916339],
              [-5.396862244767775, 50.221629103184249],
              [-5.397018343506901, 50.222167911283996],
              [-5.396579595105857, 50.222169957165278],
              [-5.396460621299095, 50.223060474948461],
              [-5.396093932566208, 50.223070305984855],
              [-5.395816267863196, 50.223780142527133],
              [-5.396662598058721, 50.224658605295211],
              [-5.395414365579275, 50.22447831931423],
              [-5.394125681252896, 50.225208729033106],
              [-5.391602641493333, 50.228719971252261],
              [-5.391117599359202, 50.230579283559919],
              [-5.391712915463311, 50.230529501614988],
              [-5.392128998669278, 50.231095803284269],
              [-5.391574722426506, 50.23122985282167],
              [-5.391825160825709, 50.231478029012727],
              [-5.391257768932133, 50.233264178838297],
              [-5.391721658179288, 50.233871367693794],
              [-5.392197708027884, 50.233721611491845],
              [-5.392939569522877, 50.234292782654336],
              [-5.393094242634415, 50.234673302228607],
              [-5.39241948803536, 50.235145539309187],
              [-5.393342516452797, 50.235423531498277],
              [-5.392940679230356, 50.235788833030128],
              [-5.393384868871565, 50.235960271347587],
              [-5.392831234930973, 50.236064619817881],
              [-5.393533055780105, 50.236624363057189],
              [-5.39478333425529, 50.23714467176859],
              [-5.395212330607397, 50.236490688115559],
              [-5.395740120574044, 50.236594898724228],
              [-5.395428395118739, 50.237260747532943],
              [-5.394711349052662, 50.237592093499877],
              [-5.395333826551004, 50.237962525716192],
              [-5.394608701919759, 50.238910352964005],
              [-5.395027595145739, 50.239317328179624],
              [-5.395464370081431, 50.239186700400445],
              [-5.395265080648572, 50.239421935511125],
              [-5.395773049445318, 50.239266733276217],
              [-5.395349063466702, 50.239656081211919],
              [-5.395809308038216, 50.239480683792756],
              [-5.395936350582697, 50.239886298056859],
              [-5.39620402237551, 50.239586986553967],
              [-5.395648371198857, 50.240412909458335],
              [-5.395142324399639, 50.240674210977829],
              [-5.394907549970792, 50.240489457111153],
              [-5.394140684707724, 50.241067854654531],
              [-5.39355549499761, 50.240551485533267],
              [-5.393345841485613, 50.240897674470204],
              [-5.39271002411457, 50.240774119555283],
              [-5.391971352004161, 50.241196942342391],
              [-5.391659513933289, 50.240914575289878],
              [-5.391956920517791, 50.240539735921843],
              [-5.390349034628199, 50.240377098759851],
              [-5.390507584285699, 50.239824594126326],
              [-5.390080742097688, 50.239680526423442],
              [-5.388459331850115, 50.240097618541974],
              [-5.387666349902489, 50.241218310717109],
              [-5.388043291063239, 50.240441725789601],
              [-5.387497690888516, 50.240759025341688],
              [-5.387288630792376, 50.240303617630843],
              [-5.386299022154207, 50.240729228509977],
              [-5.385821819357937, 50.239855217529744],
              [-5.384662622418446, 50.239768475702434],
              [-5.384543212285598, 50.240933376003376],
              [-5.382891296569372, 50.241059799839164],
              [-5.383425474974985, 50.241550719950936],
              [-5.382602779842919, 50.241759121330368],
              [-5.38229765163894, 50.242283498886273],
              [-5.381373522045082, 50.242188974145805],
              [-5.38158381381187, 50.242504014430779],
              [-5.38105434960641, 50.243267927028],
              [-5.379584223105216, 50.243981860875977],
              [-5.378024835844601, 50.244128910443962],
              [-5.378053442929551, 50.243264437464816],
              [-5.377245425344046, 50.243224976165905],
              [-5.376788285994893, 50.242731786896186],
              [-5.376956492890709, 50.24209535631865],
              [-5.376356686336588, 50.24131033921897],
              [-5.376306617368951, 50.240423865291454],
              [-5.374948583355351, 50.239945175533144],
              [-5.37500330245487, 50.239608024235061],
              [-5.374622051423415, 50.239532745145162],
              [-5.374918648512249, 50.239065312688545],
              [-5.373508476050612, 50.238661891699422],
              [-5.373804843363271, 50.237496359503986],
              [-5.373510867358875, 50.237306086893462],
              [-5.372839827950906, 50.2374767253269],
              [-5.373149138031307, 50.237089894187847],
              [-5.371734667394313, 50.23686290361163],
              [-5.370023223797075, 50.237237371438603],
              [-5.370055555854462, 50.236761430800406],
              [-5.36826640999828, 50.236173731122406],
              [-5.367990037188394, 50.236709830527431],
              [-5.367178241500223, 50.237072541778168],
              [-5.366719281972264, 50.236789878786183],
              [-5.366122575276723, 50.236861162669221],
              [-5.366438127859234, 50.237994533675874],
              [-5.364999885739975, 50.237868008661003],
              [-5.364634590081295, 50.238217756455285],
              [-5.364435975844372, 50.237827679175517],
              [-5.363734291243698, 50.237842618276687],
              [-5.363594477599444, 50.237450835565845],
              [-5.362858089374971, 50.237829323351008],
              [-5.362683377393871, 50.237380075158171],
              [-5.361940653030313, 50.237130803497664],
              [-5.361631532539517, 50.238418121321914],
              [-5.36008782619055, 50.23886585029711],
              [-5.358437025326282, 50.238429629193845],
              [-5.358139341938386, 50.238963621594813],
              [-5.358565781563317, 50.239083527874925],
              [-5.357441490016251, 50.239492095960301],
              [-5.358138182127176, 50.239465644543941],
              [-5.357931009630214, 50.239810795792053],
              [-5.355727646800349, 50.240553731376778],
              [-5.355891263933208, 50.240187352982353],
              [-5.354879905696641, 50.240427100163458],
              [-5.351572548124775, 50.240271650809767],
              [-5.351255619487896, 50.239593490790071],
              [-5.350348179763415, 50.239435264275471],
              [-5.350347586862225, 50.238827137409451],
              [-5.348110904626216, 50.239175955778407],
              [-5.347289931713012, 50.238630173630582],
              [-5.346450000953342, 50.238674183922427],
              [-5.346173722037845, 50.238274620690028],
              [-5.345726965199582, 50.238464724252417],
              [-5.34573143282201, 50.238128137377956],
              [-5.345146457321452, 50.237947080928372],
              [-5.341872304958452, 50.238162842217804],
              [-5.341547527282526, 50.238833413044546],
              [-5.340768207048485, 50.238260297307733],
              [-5.339831177240554, 50.239342516341083],
              [-5.338095506302442, 50.239455417445221],
              [-5.336599536499541, 50.240067887904146],
              [-5.336439628177981, 50.239686547886826],
              [-5.335738906085111, 50.239775957849751],
              [-5.334850653319891, 50.240433912702251],
              [-5.335303583368531, 50.240933681747521],
              [-5.334795758116737, 50.240913187256396],
              [-5.333650485672502, 50.241727852851099],
              [-5.33217964271684, 50.241636941724877],
              [-5.331597345579968, 50.242097167682303],
              [-5.330573099797442, 50.242154448760552],
              [-5.329440585454818, 50.242570175163564],
              [-5.328370263276664, 50.242510008774907],
              [-5.327702227044183, 50.243874089386523],
              [-5.324749136714211, 50.2443356490515],
              [-5.3244835421289, 50.244933404259697],
              [-5.323087052691402, 50.245508662331467],
              [-5.322923062861226, 50.246558710592005],
              [-5.322124336938495, 50.246793881359629],
              [-5.319975941896743, 50.247633513412318],
              [-5.319820368255028, 50.24866442373451],
              [-5.320248757084737, 50.248994025389251],
              [-5.319955583024271, 50.24911576197966],
              [-5.317274296036469, 50.249113248939672],
              [-5.316767638377158, 50.24947407800628],
              [-5.315716727236956, 50.249311582538375],
              [-5.315116001383801, 50.249530253185824],
              [-5.313833348300119, 50.250738173410546],
              [-5.314243834955326, 50.251638663288006],
              [-5.312491116704912, 50.252766427137431],
              [-5.312505676880408, 50.253724100040749],
              [-5.312056114271593, 50.254241610323554],
              [-5.312016562844068, 50.254985819304736],
              [-5.313605491969473, 50.254852313974247],
              [-5.313637677133943, 50.255174356528187],
              [-5.311815435282375, 50.255363097562324],
              [-5.31144863424414, 50.254667364876241],
              [-5.31059171523547, 50.254491191829977],
              [-5.309925488225979, 50.254737791371213],
              [-5.309547268516782, 50.255900078957417],
              [-5.309981024128746, 50.255882314698923],
              [-5.310378368541582, 50.255865587114201],
              [-5.310966188995988, 50.256632386317904],
              [-5.310788263768886, 50.2570206958056],
              [-5.30996433682245, 50.257138649868601],
              [-5.308423572398823, 50.258413239540964],
              [-5.306186076064496, 50.259516934361145],
              [-5.305274624047991, 50.258696353342792],
              [-5.305763104813911, 50.259812357038008],
              [-5.304667748639392, 50.25903119312111],
              [-5.303420011746947, 50.259413054723751],
              [-5.302105326337241, 50.260977096646606],
              [-5.30311878846781, 50.261548306494277],
              [-5.302438546641183, 50.261756577072603],
              [-5.303060459167686, 50.261901713986269],
              [-5.301416651029765, 50.262401874904256],
              [-5.300917346920073, 50.262852383777137],
              [-5.299768638695975, 50.262699719338002],
              [-5.299159921292023, 50.261852493728064],
              [-5.297028469288954, 50.26179519642227],
              [-5.297088244813706, 50.261015336089784],
              [-5.295092888875059, 50.261018010612133],
              [-5.291923209931504, 50.261817723500045],
              [-5.292677857698105, 50.263703494269095],
              [-5.291413657402372, 50.261856460371575],
              [-5.291186715492084, 50.261984341392299],
              [-5.289318191434175, 50.261140387098756],
              [-5.28914142857496, 50.261324417335942],
              [-5.291814862830723, 50.262484707213687],
              [-5.291357109961164, 50.263396394941395],
              [-5.292006791654811, 50.264167831225812],
              [-5.290929671519219, 50.264625682809637],
              [-5.289656599248263, 50.264053627271153],
              [-5.288230811552766, 50.263973471209496],
              [-5.288126416824633, 50.264352464219726],
              [-5.287019012172511, 50.264778750917863],
              [-5.285783188911395, 50.264726471577369],
              [-5.285208023393513, 50.265015329530677],
              [-5.284674196768099, 50.266067681449876],
              [-5.285121607221379, 50.266898854358118],
              [-5.286264456681518, 50.266924985490554],
              [-5.28791687469643, 50.267705840394569],
              [-5.283147917078612, 50.268912229533633],
              [-5.282683021152872, 50.26947863329935],
              [-5.283203417506053, 50.269473814029986],
              [-5.283022419381881, 50.269739818275532],
              [-5.2793390241092, 50.271857233846482],
              [-5.278106477942578, 50.272059367073581],
              [-5.27866228152494, 50.272242485983561],
              [-5.278538711081143, 50.272466378856535],
              [-5.279185093339939, 50.27231318371431],
              [-5.279464376489069, 50.272577876080668],
              [-5.278077839284022, 50.27395024729676],
              [-5.27348992452204, 50.275045865269675],
              [-5.272982004184311, 50.27488566104492],
              [-5.27298569483637, 50.274469040024918],
              [-5.270982953829243, 50.275149804487988],
              [-5.270353968666425, 50.274697929210987],
              [-5.269968497101078, 50.27503354317917],
              [-5.268219425507237, 50.274951447596443],
              [-5.264879339160808, 50.275516809364888],
              [-5.264034298739232, 50.274901850035945],
              [-5.263458152020181, 50.274892856469009],
              [-5.262770775585973, 50.275309799198702],
              [-5.262678315543122, 50.276174216985645],
              [-5.264015046198744, 50.276609837994933],
              [-5.262662237770718, 50.277026592637597],
              [-5.262512510150248, 50.276725834546816],
              [-5.260826634458362, 50.276336886020786],
              [-5.259774859420589, 50.277337990056552],
              [-5.26020653772895, 50.27769740564338],
              [-5.260013745077623, 50.278429697435755],
              [-5.260649513224972, 50.278776184244798],
              [-5.260342563330613, 50.278947631684701],
              [-5.261949232506644, 50.279162491032551],
              [-5.261743743351711, 50.27981147942738],
              [-5.260483422433126, 50.279878362975104],
              [-5.258755183191598, 50.280616874696456],
              [-5.256827289439131, 50.280796910302143],
              [-5.256533456081476, 50.281201875825353],
              [-5.257657173732062, 50.2811487638184],
              [-5.256176328956181, 50.281769644130968],
              [-5.25448173188372, 50.281830647516365],
              [-5.253275282120211, 50.281474029457257],
              [-5.250680508462369, 50.281839970786244],
              [-5.249616552045202, 50.282749560934207],
              [-5.25070916474549, 50.283372087299007],
              [-5.24878038303973, 50.283520526674295],
              [-5.246002089903823, 50.284760509357426],
              [-5.245372115954588, 50.285715500630765],
              [-5.245894025053685, 50.286678776710325],
              [-5.245148101222343, 50.286941617554518],
              [-5.243476970314477, 50.286834477196855],
              [-5.242581515252041, 50.28716984755453],
              [-5.241877190271087, 50.288292420960659],
              [-5.243422695118925, 50.290204989220491],
              [-5.241204131222925, 50.291423818499972],
              [-5.241412253756086, 50.291985655340284],
              [-5.242055740158375, 50.292238470334972],
              [-5.241162939896916, 50.29230477475101],
              [-5.241786366648167, 50.293253540143724],
              [-5.240242654634727, 50.293866970061579],
              [-5.240342447420136, 50.294611750807235],
              [-5.238876392871145, 50.295418207451114],
              [-5.238537185540494, 50.296088871550289],
              [-5.237697324096565, 50.29693542165338],
              [-5.238892351487961, 50.297202562446913],
              [-5.239207690398479, 50.297874761609137],
              [-5.23824929042747, 50.298258630777418],
              [-5.236706560894777, 50.300543433626125],
              [-5.234780684625578, 50.300414476961819],
              [-5.235865993840228, 50.301691353307625],
              [-5.233882106663622, 50.304888002290099],
              [-5.234320506705048, 50.306069556283738],
              [-5.234008670922454, 50.308800414484629],
              [-5.235292801521092, 50.309134381935706],
              [-5.234973199495844, 50.309578688792087],
              [-5.234083597510073, 50.309531497651683],
              [-5.234136700578592, 50.310085968331698],
              [-5.233629257549329, 50.310577782990322],
              [-5.234138787420933, 50.311254483499752],
              [-5.233606703536084, 50.311900814691448],
              [-5.234560005295314, 50.312368145561607],
              [-5.234293731802022, 50.31345327519093],
              [-5.235279555520762, 50.315163833612523],
              [-5.234120074397742, 50.315654020348262],
              [-5.234213807287266, 50.316909942093709],
              [-5.235644728962455, 50.318346320083755],
              [-5.234101291988592, 50.318852599377465],
              [-5.233585223464176, 50.319384234815473],
              [-5.231264222219728, 50.319034118552636],
              [-5.229781623280361, 50.31958902432072],
              [-5.22695007918459, 50.319915118131711],
              [-5.225624549824224, 50.320363052441728],
              [-5.224924008917375, 50.321076990948143],
              [-5.220791049128092, 50.322563550074911],
              [-5.218337820368489, 50.321276769017722],
              [-5.209942387472774, 50.321339073865708],
              [-5.209173557377583, 50.321293749745806],
              [-5.208656913268137, 50.320799755660431],
              [-5.207859961421521, 50.321025076540302],
              [-5.208478650561543, 50.321094347915292],
              [-5.207764098359051, 50.321500008403724],
              [-5.207024295865891, 50.321447571221405],
              [-5.207355570998765, 50.321129864663114],
              [-5.206305915839795, 50.321254202100256],
              [-5.205931711499254, 50.321560496294111],
              [-5.206395859679967, 50.321901222252222],
              [-5.205465747289907, 50.321839635239783],
              [-5.204525752959562, 50.322194822896215],
              [-5.203196821918434, 50.320319855214592],
              [-5.202474071704724, 50.3201445749051],
              [-5.200601585470151, 50.320590208702903],
              [-5.199141445725366, 50.321464353496118],
              [-5.198361536721634, 50.322404311221433],
              [-5.196998603936395, 50.322739597292376],
              [-5.19627732528401, 50.323402652903916],
              [-5.196208883314386, 50.323827342175363],
              [-5.197425853067258, 50.324247239218487],
              [-5.19664188488697, 50.324730305994159],
              [-5.195876175354024, 50.324690205022499],
              [-5.195419634161068, 50.325278501270439],
              [-5.196088315391838, 50.325338367408598],
              [-5.19562440681042, 50.325440190742846],
              [-5.196035901527332, 50.325771611269452],
              [-5.194773755038105, 50.325683989931782],
              [-5.195178233688337, 50.326245900583181],
              [-5.193847558972561, 50.326645031168781],
              [-5.191398104854836, 50.325993588856633],
              [-5.191248383052857, 50.326422307046037],
              [-5.19178280945536, 50.326743168184834],
              [-5.191670314078888, 50.327496513998774],
              [-5.191170036798864, 50.327727059733569],
              [-5.191799472952936, 50.327788029030799],
              [-5.190615070277431, 50.328210989485463],
              [-5.190873977633725, 50.327838645551651],
              [-5.189755776889177, 50.328012392501968],
              [-5.189430145986973, 50.328647445746235],
              [-5.189936253677887, 50.329006875587268],
              [-5.190800806197212, 50.328864384001022],
              [-5.189957710612055, 50.329222186798994],
              [-5.190789699913466, 50.32949529850859],
              [-5.188074399936927, 50.330163582378354],
              [-5.188815707067742, 50.331495309880538],
              [-5.18890873872179, 50.331205788277558],
              [-5.189734318161141, 50.331382828110399],
              [-5.18975840817006, 50.331993884402493],
              [-5.189090679524173, 50.332264102583437],
              [-5.188996200944753, 50.331735041788683],
              [-5.188933065665868, 50.33234489446852],
              [-5.188121171932214, 50.332519210826547],
              [-5.188345775441555, 50.332746038456186],
              [-5.187924930053706, 50.333009472931877],
              [-5.18749332701654, 50.332776630377602],
              [-5.186765230598112, 50.332943240104463],
              [-5.185787268856157, 50.332463600185562],
              [-5.186722043989777, 50.333137835556016],
              [-5.186250021271783, 50.333455743311553],
              [-5.185355698939582, 50.333196902325817],
              [-5.185553832613492, 50.332671509145058],
              [-5.184084979308635, 50.332962766146466],
              [-5.183323926670385, 50.332720946865557],
              [-5.183181586621896, 50.333177338292209],
              [-5.181706411409881, 50.333647755263286],
              [-5.182506060806594, 50.334256457416949],
              [-5.180832756277884, 50.334370658512796],
              [-5.180152929770468, 50.334082514564486],
              [-5.180600818653303, 50.335022010678415],
              [-5.180928617697582, 50.335573475019899],
              [-5.180125309767972, 50.335793378242421],
              [-5.180168079558344, 50.336727773632099],
              [-5.18074421137381, 50.33817652038438],
              [-5.180530728230575, 50.33855749179093],
              [-5.181472638675978, 50.338938309972406],
              [-5.181813065185776, 50.339699028845956],
              [-5.181768422626471, 50.339977323153789],
              [-5.181186030028641, 50.339991474478886],
              [-5.181382873895708, 50.340454766287905],
              [-5.177582966545202, 50.340974378834019],
              [-5.175958071989931, 50.340743542928237],
              [-5.17477152606557, 50.341141207294235],
              [-5.173471848547337, 50.340707138461795],
              [-5.172224109555307, 50.341093854159659],
              [-5.171608578993686, 50.340864176122665],
              [-5.170525451885492, 50.341780729973379],
              [-5.169834601579852, 50.341348893391334],
              [-5.169896679430063, 50.341879751245834],
              [-5.168235451554822, 50.343403980430153],
              [-5.168476245638623, 50.344359963886696],
              [-5.167192434386525, 50.344523612573127],
              [-5.166201090635327, 50.344329339512875],
              [-5.165004397810704, 50.344703789829438],
              [-5.16270456264308, 50.344809605633344],
              [-5.162911818160934, 50.345389588989114],
              [-5.162140629579524, 50.346540454628659],
              [-5.161350187092676, 50.346661820935047],
              [-5.160376748690407, 50.346229521939755],
              [-5.159984264717012, 50.346437206415459],
              [-5.160683988679023, 50.346558505180269],
              [-5.159791612971531, 50.346626860243376],
              [-5.157369364816386, 50.347770413710528],
              [-5.155074244611778, 50.346021019277131],
              [-5.154658623037809, 50.346261698749906],
              [-5.153820587315557, 50.34615311458024],
              [-5.154476135588197, 50.346753325677881],
              [-5.154133283423819, 50.34722231905377],
              [-5.155237404194478, 50.346954847607577],
              [-5.155616664823829, 50.347818935804725],
              [-5.153532346812815, 50.35192003331678],
              [-5.153983925595632, 50.352093537145919],
              [-5.154289977822502, 50.353019438633581],
              [-5.153606166153438, 50.353414925485538],
              [-5.153731493298052, 50.354350681595889],
              [-5.153030508374872, 50.354932843324256],
              [-5.153496461509619, 50.355343897114956],
              [-5.152898423336733, 50.355803619764529],
              [-5.153146854672467, 50.356093737689363],
              [-5.152380990430332, 50.35673702796219],
              [-5.151439589295506, 50.35891165505889],
              [-5.146578964390494, 50.375322094052031],
              [-5.149619998829816, 50.37720796892485],
              [-5.151109236746014, 50.377285435792601],
              [-5.151529211376687, 50.377424274524664],
              [-5.151398589100699, 50.377703086882541],
              [-5.152475105584736, 50.377729658674426],
              [-5.152603240852407, 50.378070719033708],
              [-5.153495864146104, 50.378145450210205],
              [-5.154243495127186, 50.378790842345893],
              [-5.155402008347648, 50.378907817460231],
              [-5.155917489343169, 50.379361596683133],
              [-5.155557618207975, 50.37953509282773],
              [-5.155948946382496, 50.380131677517994],
              [-5.155524228221226, 50.380345616432656],
              [-5.154386100748799, 50.379942923872129],
              [-5.15462285884084, 50.380521219536142],
              [-5.153256028551293, 50.380382002859243],
              [-5.151183548687324, 50.38126943125458],
              [-5.150329364679936, 50.380523457576537],
              [-5.150709983487349, 50.381320055003094],
              [-5.149710438947896, 50.381076381767656],
              [-5.149512015653176, 50.381648491208999],
              [-5.151093540203568, 50.381863791439521],
              [-5.152702706353673, 50.382770991884946],
              [-5.151929476889278, 50.383479242492079],
              [-5.151074808265177, 50.383469138082404],
              [-5.152437914450708, 50.384144627844137],
              [-5.151687635864181, 50.384287321926145],
              [-5.154407770990248, 50.384803632492201],
              [-5.154809126368542, 50.385296497735098],
              [-5.153681646534063, 50.385225451050829],
              [-5.153912350769742, 50.385669875814742],
              [-5.153004672498358, 50.385603651357464],
              [-5.152948635345769, 50.385819270278965],
              [-5.155763068997024, 50.387012169217698],
              [-5.155819842629463, 50.387294892608892],
              [-5.154266018344783, 50.387542183524772],
              [-5.155434340499785, 50.38812127447801],
              [-5.156118860496877, 50.388279900670049],
              [-5.156597558239179, 50.388030310258415],
              [-5.157438895337332, 50.388346591989212],
              [-5.156406275546024, 50.388603137071257],
              [-5.157020634478899, 50.388806843295207],
              [-5.156377011356372, 50.388967359631813],
              [-5.157371267124709, 50.389086079899265],
              [-5.157562912197232, 50.389471287491517],
              [-5.156945571226681, 50.389730945403137],
              [-5.155961847049892, 50.389389740763775],
              [-5.154942104246224, 50.389438121407593],
              [-5.154711543600886, 50.389038673413346],
              [-5.153701187159747, 50.389291891021536],
              [-5.152902149540594, 50.389103973106643],
              [-5.151501251707259, 50.389663726497581],
              [-5.15021838906768, 50.389479015295755],
              [-5.14908795622474, 50.389831701768941],
              [-5.14911264054312, 50.390182765537915],
              [-5.147312215246528, 50.390150580818954],
              [-5.148188646774854, 50.39046597882394],
              [-5.147485785979798, 50.390561481876546],
              [-5.145847860779156, 50.388691545814552],
              [-5.145129382416762, 50.388550870253383],
              [-5.14356405242084, 50.38892336883184],
              [-5.144574079563194, 50.389153318680627],
              [-5.145571982640538, 50.388753887844331],
              [-5.146884205264529, 50.39025302901404],
              [-5.142765346460479, 50.395544224441558],
              [-5.143220008632343, 50.396332545505352],
              [-5.144062756758856, 50.396477967811954],
              [-5.14441079587176, 50.397207098619717],
              [-5.143810250964067, 50.397404160111805],
              [-5.144440476551433, 50.397549031910643],
              [-5.144664279149434, 50.398187068575695],
              [-5.145712941394106, 50.39778626503427],
              [-5.146159532648149, 50.398133104204426],
              [-5.145862910410927, 50.398499163308394],
              [-5.147500631572885, 50.39885332852478],
              [-5.147203920860949, 50.399133034671785],
              [-5.147504679601798, 50.399445136521003],
              [-5.146540827675704, 50.399745606527603],
              [-5.147361417747305, 50.399959073172468],
              [-5.146966952412519, 50.400250421726106],
              [-5.14880745385388, 50.400647654546802],
              [-5.148296063577591, 50.401595264015377],
              [-5.149530513581238, 50.401703048305144],
              [-5.147912202051053, 50.402110321481473],
              [-5.14751491338126, 50.402507897493727],
              [-5.148765443399151, 50.402475821133628],
              [-5.148972480099137, 50.402711296389342],
              [-5.148011580722951, 50.402717538432256],
              [-5.147585664365876, 50.403065514130027],
              [-5.146911386112864, 50.402809405380552],
              [-5.148291243398647, 50.404026933927497],
              [-5.147284309248418, 50.404252112799419],
              [-5.147930546895841, 50.404594438245383],
              [-5.147198924446705, 50.405023557633143],
              [-5.147639653002257, 50.405132164131487],
              [-5.146593697162074, 50.405299921560626],
              [-5.146232835595591, 50.404930972373926],
              [-5.146140368146215, 50.405340980826921],
              [-5.145254128236401, 50.405136478234034],
              [-5.144039171210768, 50.405387948338259],
              [-5.142795160283139, 50.404477947545203],
              [-5.142341031657841, 50.403656326490534],
              [-5.142182199743015, 50.404063628124959],
              [-5.141198356121128, 50.404106416634576],
              [-5.140831054921996, 50.403745721213909],
              [-5.14113624409905, 50.40350808268515],
              [-5.139388140505909, 50.403061454414747],
              [-5.139222103809218, 50.402359777727881],
              [-5.13814750347766, 50.402349218872558],
              [-5.138347957177286, 50.402741415396406],
              [-5.137463082100896, 50.40259888734235],
              [-5.135629952456168, 50.401226143535496],
              [-5.136003450148126, 50.402661672394728],
              [-5.136575708472528, 50.40320666031532],
              [-5.138364735515732, 50.403931991575625],
              [-5.137084733205286, 50.404519784877309],
              [-5.137055890510106, 50.40487139517554],
              [-5.13782430645603, 50.405082739556683],
              [-5.137019105689122, 50.405443614108712],
              [-5.13748860978115, 50.406711925606849],
              [-5.137189566370719, 50.407383879724726],
              [-5.136483716119076, 50.407587342499269],
              [-5.136819050138611, 50.40808205038001],
              [-5.136006787579404, 50.408400828181243],
              [-5.135432427058127, 50.4082723939933],
              [-5.135219680111756, 50.40884486109821],
              [-5.133415574352067, 50.408255721867803],
              [-5.131878725653874, 50.407065638743148],
              [-5.132459917677832, 50.406167499551948],
              [-5.131826400107154, 50.406485928187358],
              [-5.130879507636244, 50.40585205782773],
              [-5.131089443191328, 50.405684479931324],
              [-5.130328291435458, 50.405454904140562],
              [-5.130769605086408, 50.405295487532143],
              [-5.129638959146572, 50.405353826676716],
              [-5.129518384497091, 50.404912687304183],
              [-5.12925698328853, 50.405239072385143],
              [-5.127245345989493, 50.404786763312053],
              [-5.125993454865945, 50.403944264799165],
              [-5.12626585475019, 50.404468581574569],
              [-5.125198976162877, 50.404618717170017],
              [-5.124930252173149, 50.404428038386946],
              [-5.124382152585746, 50.404889859214776],
              [-5.123065179633941, 50.404790321835819],
              [-5.119076264212565, 50.406954751017146],
              [-5.116359912040003, 50.407924485640535],
              [-5.111045122205736, 50.408274673587478],
              [-5.108951096735655, 50.40886324935191],
              [-5.106068535986563, 50.408093832806429],
              [-5.101347409425368, 50.407771927612252],
              [-5.100698305175006, 50.407079492097736],
              [-5.100252428566699, 50.405274270577102],
              [-5.099493553175366, 50.404667516459199],
              [-5.09970613991881, 50.405896946084816],
              [-5.098142866126645, 50.406552130617214],
              [-5.094481349033624, 50.406179207735967],
              [-5.092649975327872, 50.405161974616981],
              [-5.089288986093396, 50.404408473447518],
              [-5.089540051824595, 50.404200297879392],
              [-5.090393088638484, 50.404439390395012],
              [-5.089436219808525, 50.403967373059785],
              [-5.089130693972065, 50.404290340650853],
              [-5.088370985450624, 50.404057754527564],
              [-5.088826557376871, 50.403791071810183],
              [-5.087474785278204, 50.403652475658689],
              [-5.085928963109424, 50.403733111886339],
              [-5.085804386920221, 50.404003589066981],
              [-5.081868012379743, 50.403966807029015],
              [-5.080517926872644, 50.40448296463709],
              [-5.079844388742087, 50.405686434458033],
              [-5.078917766525886, 50.405853116193803],
              [-5.077507730454157, 50.405163618037918],
              [-5.07447734507456, 50.404794941897578],
              [-5.07503437138832, 50.404050663190432],
              [-5.073896478731779, 50.403541029289585],
              [-5.073029790793818, 50.403654804212032],
              [-5.072836010027129, 50.402770264521571],
              [-5.07007477745845, 50.399247703346326],
              [-5.070498669318337, 50.399796030219463],
              [-5.071509612681852, 50.401652092634635],
              [-5.072652818335181, 50.402788601284513],
              [-5.072918457932326, 50.40373960774933],
              [-5.073907215990587, 50.403619906459127],
              [-5.07488323309649, 50.404129325341522],
              [-5.074411857973971, 50.404870438040078],
              [-5.077339913973672, 50.405223834272725],
              [-5.077900460904912, 50.405489653136016],
              [-5.076803769911328, 50.405464717924154],
              [-5.076788339440266, 50.405704409068846],
              [-5.077992171829945, 50.405579878912576],
              [-5.079270285433693, 50.406014693128625],
              [-5.080271280231798, 50.405605855157802],
              [-5.080887262769378, 50.404578423053707],
              [-5.083131497932499, 50.404089819327396],
              [-5.083610120622708, 50.40426422983726],
              [-5.082469357647684, 50.404297189959436],
              [-5.081387391902797, 50.404672211770958],
              [-5.08116463110372, 50.405056832219643],
              [-5.083084837518754, 50.404324942729716],
              [-5.083171894554702, 50.404918300278638],
              [-5.082910176093066, 50.405103197484593],
              [-5.083193432424502, 50.405065098414461],
              [-5.08319884652737, 50.404899258548632],
              [-5.083218385380968, 50.404300710048503],
              [-5.083829751836156, 50.404395135443202],
              [-5.084180958061865, 50.404948041014329],
              [-5.083775224902148, 50.404985794081249],
              [-5.084186500733129, 50.405033352134438],
              [-5.084432287581537, 50.404721878517499],
              [-5.08376439442131, 50.404234049254903],
              [-5.086076587200624, 50.404813162363865],
              [-5.085880724330767, 50.405264544959202],
              [-5.085490027164822, 50.405269413559331],
              [-5.087034219482608, 50.406249515718464],
              [-5.090231476854078, 50.406518974830718],
              [-5.093897132021454, 50.407862548242825],
              [-5.099919447786668, 50.408258005339441],
              [-5.101344027426782, 50.408711161051492],
              [-5.103577795766108, 50.4087945633337],
              [-5.105241182758411, 50.409454490789074],
              [-5.107821649091387, 50.409457482708198],
              [-5.109844693170849, 50.409782984595253],
              [-5.113786556292665, 50.408779678205448],
              [-5.116480918425276, 50.409424413738506],
              [-5.119021525319411, 50.409249211996467],
              [-5.121749835563721, 50.409916303658868],
              [-5.123230271952705, 50.410636662662718],
              [-5.123083777487339, 50.410934757237335],
              [-5.124320397147437, 50.410730603478562],
              [-5.125643105047529, 50.412090265688477],
              [-5.12487405335855, 50.412725348824068],
              [-5.123678846481438, 50.412746682766581],
              [-5.12383085050321, 50.413024161397878],
              [-5.1214617863903, 50.412749575022822],
              [-5.121335870302434, 50.412397628444324],
              [-5.12050062270549, 50.412669236383913],
              [-5.120341459110571, 50.412432426197519],
              [-5.118047575795267, 50.412294285060035],
              [-5.117527344849843, 50.41198259810286],
              [-5.116539334789294, 50.411921837800357],
              [-5.116663630513453, 50.412142496329608],
              [-5.114680448851492, 50.411847528630332],
              [-5.11511663768677, 50.412165978568069],
              [-5.114075670761456, 50.412478136599823],
              [-5.113913663556148, 50.412798224169769],
              [-5.114493225550783, 50.412898738874986],
              [-5.113941548725285, 50.413115922704058],
              [-5.114107220576895, 50.413302191436024],
              [-5.113083413765706, 50.413404282124638],
              [-5.113315142488791, 50.413610372160747],
              [-5.112657277495031, 50.413797110269918],
              [-5.110447596956226, 50.413805891033107],
              [-5.108305423262372, 50.413190326795629],
              [-5.107753295790173, 50.413379606538093],
              [-5.106958892301144, 50.413029325747871],
              [-5.106325054065346, 50.413131726222062],
              [-5.105721915795284, 50.41282130319351],
              [-5.105470883680902, 50.413051102360939],
              [-5.104961153805688, 50.412769664285264],
              [-5.104535844187255, 50.412939348558787],
              [-5.104015406989391, 50.412666288448946],
              [-5.1034554612119, 50.41275770417861],
              [-5.103707759918741, 50.412977656770551],
              [-5.102362762251733, 50.413119714060613],
              [-5.101965749854466, 50.413464048068569],
              [-5.102352405763074, 50.413521195171427],
              [-5.101334707668967, 50.413587936007559],
              [-5.097756294026897, 50.418874731450785],
              [-5.09752079218546, 50.419429739694088],
              [-5.098443419584965, 50.419608449226082],
              [-5.097767890725443, 50.419677730044313],
              [-5.099491440920015, 50.4203271424945],
              [-5.09854223949019, 50.42049637999002],
              [-5.100022497999607, 50.42121433993168],
              [-5.099045185764593, 50.420975031022778],
              [-5.098391259596391, 50.42115978263201],
              [-5.100030865033763, 50.421622517371041],
              [-5.098387363136855, 50.421466636638847],
              [-5.099134003469889, 50.421838939797112],
              [-5.097670182160496, 50.422515748587543],
              [-5.098013549874481, 50.423763282958326],
              [-5.099620463768066, 50.423896756718165],
              [-5.100154897305968, 50.424727191173197],
              [-5.101013637985804, 50.425025427462657],
              [-5.10052997044985, 50.425079709785408],
              [-5.101077265042606, 50.425546374630343],
              [-5.099759134706898, 50.425652598809222],
              [-5.099446557323809, 50.425803964158511],
              [-5.099775634883757, 50.425991293003086],
              [-5.09776396213896, 50.425655412805185],
              [-5.098044680272431, 50.42549050820579],
              [-5.097651770052623, 50.425295880057824],
              [-5.097521309971508, 50.424203692591199],
              [-5.096615105908328, 50.423911193021837],
              [-5.097426505316398, 50.42365748731153],
              [-5.096959765368826, 50.423582666679451],
              [-5.097126197147332, 50.423263386139709],
              [-5.095801172797241, 50.422400023942842],
              [-5.096228895729683, 50.422524464706456],
              [-5.096638385540031, 50.422261679253175],
              [-5.09427174119635, 50.421359478006934],
              [-5.093992851253378, 50.420818170522082],
              [-5.091425378154163, 50.420181230504525],
              [-5.091378951512381, 50.420916505840644],
              [-5.089614105951815, 50.420604506574946],
              [-5.090267716978892, 50.421041408473386],
              [-5.089011284438494, 50.420687995598385],
              [-5.088095877181887, 50.420922816536397],
              [-5.087634583778804, 50.420714679319111],
              [-5.087996031456521, 50.420405524122948],
              [-5.087334062410437, 50.420056088865323],
              [-5.086838240585775, 50.419014075033076],
              [-5.087358912379455, 50.419118088375377],
              [-5.086724093784551, 50.418687867854693],
              [-5.087488525597467, 50.418621687162315],
              [-5.086398595729044, 50.418012846702908],
              [-5.087856016331945, 50.418124363802995],
              [-5.087733189337477, 50.417859558168331],
              [-5.088364125311775, 50.417473076511854],
              [-5.086971242688316, 50.416708571055381],
              [-5.086474253942175, 50.41768249862303],
              [-5.086568299380786, 50.415924961367715],
              [-5.085219655560101, 50.415103487901519],
              [-5.083551807802523, 50.415023608490834],
              [-5.082726824338316, 50.415305464711125],
              [-5.082634544503489, 50.415033546782013],
              [-5.082085962601404, 50.415279281504311],
              [-5.082427794314532, 50.415881916449983],
              [-5.081335672669159, 50.416039511232349],
              [-5.08085491796582, 50.415964098599758],
              [-5.081355590088121, 50.415587404881961],
              [-5.080341269366455, 50.415837381368938],
              [-5.079186099370245, 50.415544149054675],
              [-5.079000577673253, 50.415201834346391],
              [-5.078586109604667, 50.415518662838863],
              [-5.077995901632048, 50.415318400248744],
              [-5.077817777855674, 50.415784590364979],
              [-5.077105789095067, 50.415682001550884],
              [-5.07614015118132, 50.416422710255489],
              [-5.076821347185643, 50.417157609619771],
              [-5.075245370934891, 50.416801712958168],
              [-5.074644446534338, 50.41694669708216],
              [-5.072694999588765, 50.418052079739049],
              [-5.072698862709934, 50.418829194648872],
              [-5.071439329535944, 50.418968632015947],
              [-5.071890647699803, 50.419285043313252],
              [-5.070925204645209, 50.419791817221707],
              [-5.07166614188169, 50.420210323149043],
              [-5.070146734478765, 50.420424979660652],
              [-5.070655636074641, 50.42060853956233],
              [-5.069339808818032, 50.420444491340014],
              [-5.067634644446915, 50.420704499832368],
              [-5.06802607336869, 50.420856988313801],
              [-5.067183946212233, 50.421072617639233],
              [-5.067381784519791, 50.421693487661173],
              [-5.065967085887668, 50.421808173663521],
              [-5.065378539003919, 50.421525043971506],
              [-5.06485431593667, 50.421888794570663],
              [-5.06447098868685, 50.422363071701952],
              [-5.065877051611182, 50.423312805673426],
              [-5.06549961081481, 50.423834607261824],
              [-5.0658719155492, 50.424170217133749],
              [-5.065526033726896, 50.424308875450144],
              [-5.066392429668776, 50.424772682210353],
              [-5.063405651130127, 50.424879321746246],
              [-5.062815555964934, 50.424419907097416],
              [-5.062265775180539, 50.424563028488848],
              [-5.060206667767523, 50.424141392585071],
              [-5.057819199395473, 50.424173638054704],
              [-5.056005901129706, 50.425730775828839],
              [-5.054621893339116, 50.425449611596605],
              [-5.054649036689513, 50.425914866803225],
              [-5.056342728334331, 50.426128518085953],
              [-5.057524308704568, 50.425759212250121],
              [-5.058756031272409, 50.425752894042006],
              [-5.060646421079174, 50.426548715274251],
              [-5.061496486965564, 50.426388697845908],
              [-5.061916685688762, 50.425961136424995],
              [-5.064963573442376, 50.426238859841028],
              [-5.065124038873416, 50.426760868111671],
              [-5.063758655665965, 50.426725798940907],
              [-5.065342623999752, 50.427070847734129],
              [-5.066045119443828, 50.426937177276017],
              [-5.066081782983328, 50.427242958369412],
              [-5.065360019704081, 50.427754050484054],
              [-5.064063629837513, 50.42787188858334],
              [-5.061966797437907, 50.427391906582798],
              [-5.060643433052615, 50.426633352019898],
              [-5.06012887603374, 50.427606723224784],
              [-5.058353807047783, 50.427639046728963],
              [-5.057293332156441, 50.427309820738905],
              [-5.057025065495223, 50.427757657292169],
              [-5.055704965782001, 50.42768262151823],
              [-5.055146780115315, 50.428002241958708],
              [-5.055241470772637, 50.428969471187138],
              [-5.056131357939721, 50.429492107192623],
              [-5.055447123206937, 50.429560469288106],
              [-5.054412479443633, 50.430332489822185],
              [-5.053154193706392, 50.430365545651654],
              [-5.05404164955705, 50.431069071502307],
              [-5.053375991505104, 50.431098252355554],
              [-5.053795979176119, 50.431474926881862],
              [-5.052464460667671, 50.432493109184804],
              [-5.052687015265489, 50.434047088008619],
              [-5.051617668518849, 50.433691055996945],
              [-5.05034818953402, 50.434076998662725],
              [-5.049691482386852, 50.434640256627304],
              [-5.049690025663587, 50.435011809523807],
              [-5.049314648006259, 50.435044145510169],
              [-5.049473582958498, 50.435741626275892],
              [-5.048186890188123, 50.436715402881831],
              [-5.048537358180686, 50.437018356828894],
              [-5.047387096293122, 50.437354362957748],
              [-5.048049288112702, 50.437554693547682],
              [-5.048386752339911, 50.438663086787962],
              [-5.046512247840849, 50.439872645758072],
              [-5.046823459646877, 50.44030976704353],
              [-5.046191804021363, 50.44030023328439],
              [-5.045719265633134, 50.441123108946101],
              [-5.045419054144141, 50.440879099852822],
              [-5.044251758786285, 50.441653600707454],
              [-5.042417890738785, 50.444158277489059],
              [-5.041697946882986, 50.445978912662596],
              [-5.041818668104349, 50.446674703481733],
              [-5.04073599160891, 50.447958795782256],
              [-5.040420417196155, 50.449715770983552],
              [-5.039886717684081, 50.45024696713817],
              [-5.039650822003749, 50.451621347261231],
              [-5.040024667421671, 50.451826564434469],
              [-5.039401045145302, 50.451988596043051],
              [-5.03939690128769, 50.453066362491214],
              [-5.03981310819954, 50.453448583387669],
              [-5.039603174537931, 50.454393199769683],
              [-5.040216002752626, 50.45447972477757],
              [-5.040056678744022, 50.454873394963215],
              [-5.040654634286905, 50.455167202542754],
              [-5.041620234420304, 50.455261585078652],
              [-5.04196921221991, 50.455956800735812],
              [-5.04036096079779, 50.457331997291604],
              [-5.041221433732669, 50.457500196757081],
              [-5.041294544878019, 50.457805029999484],
              [-5.042401633106714, 50.457731987680049],
              [-5.043175926092807, 50.45866434469999],
              [-5.041950524168637, 50.459462826296374],
              [-5.03898181755888, 50.459051106122438],
              [-5.038080924152321, 50.460274645287981],
              [-5.038370827330628, 50.460642180738986],
              [-5.039415611586156, 50.460629266174088],
              [-5.040944820872391, 50.461511316219983],
              [-5.041592269490716, 50.461542956376718],
              [-5.041694980331318, 50.462023326167717],
              [-5.042272161698596, 50.461992033423407],
              [-5.041800277254093, 50.462829264185928],
              [-5.042447284651115, 50.462963459625676],
              [-5.042418842840521, 50.464144409416797],
              [-5.042136470599085, 50.464531406522447],
              [-5.041831580209013, 50.464370268502179],
              [-5.041835353971445, 50.464955774678316],
              [-5.041152390484815, 50.465579034740493],
              [-5.038810658886034, 50.465126619123545],
              [-5.038975594244815, 50.465566684184232],
              [-5.03858696512294, 50.465484187738177],
              [-5.037785294402459, 50.466213077904712],
              [-5.036763706946349, 50.466435864621197],
              [-5.03574209224497, 50.466328509765127],
              [-5.03515510576876, 50.466713546864568],
              [-5.033534190121702, 50.465698881484343],
              [-5.032597528745452, 50.465927511671893],
              [-5.033277050572303, 50.467273497082886],
              [-5.032687265388259, 50.469398314094406],
              [-5.033917331748997, 50.470580610834169],
              [-5.034290737457585, 50.470578963181687],
              [-5.03459989707734, 50.471246453325698],
              [-5.035808817665347, 50.471305750778491],
              [-5.035577366808787, 50.471587054093156],
              [-5.036252495072802, 50.471828496020009],
              [-5.036404022517611, 50.472455120278745],
              [-5.03736922210294, 50.472384034981943],
              [-5.038046000751639, 50.472738766214242],
              [-5.037918475372884, 50.473101916104007],
              [-5.038649964301047, 50.473254615940547],
              [-5.039702624372993, 50.474062780228579],
              [-5.039517017100832, 50.474838542376105],
              [-5.038538038834031, 50.475025146928729],
              [-5.038042669321789, 50.475828790411384],
              [-5.036217389505184, 50.475611998566386],
              [-5.036264972561645, 50.475936391248794],
              [-5.037136479007846, 50.476227572807069],
              [-5.035486807986287, 50.476991183641523],
              [-5.036156605639079, 50.476994386486211],
              [-5.036286886761686, 50.477553199992116],
              [-5.037285675904946, 50.477938207845973],
              [-5.037086320030795, 50.478896032347265],
              [-5.03607593254651, 50.479935303897307],
              [-5.035549066845245, 50.480271095413833],
              [-5.035792821268754, 50.480730695855755],
              [-5.036284404091306, 50.480482180778907],
              [-5.037386747005467, 50.480722356249082],
              [-5.036435263155167, 50.481207771846854],
              [-5.035853105240123, 50.480879345876609],
              [-5.033909857774826, 50.48227847769077],
              [-5.034666631314601, 50.482581667158229],
              [-5.033691294294028, 50.483289869301288],
              [-5.033700253994054, 50.483781685744859],
              [-5.034985958992084, 50.484241986622777],
              [-5.034472437320363, 50.484896762212323],
              [-5.033839804231127, 50.484815218436594],
              [-5.034178682490584, 50.485768888172018],
              [-5.033635516027495, 50.48565352482332],
              [-5.032878222473197, 50.486069076756856],
              [-5.033629743703171, 50.486685451216452],
              [-5.034558159534344, 50.48667382172718],
              [-5.034362424404984, 50.487183572861959],
              [-5.033813584269263, 50.487331924341277],
              [-5.033851736679995, 50.487839170725536],
              [-5.033068984649454, 50.48823199957247],
              [-5.03315467022442, 50.489239051546249],
              [-5.034809439425121, 50.489405473158307],
              [-5.034388583017382, 50.489724998410274],
              [-5.035581393534829, 50.490471073581567],
              [-5.036052862100949, 50.491819774205325],
              [-5.034617236385088, 50.492653339896528],
              [-5.035127297212068, 50.494252009142279],
              [-5.035297812160549, 50.494404079230748],
              [-5.035398097332895, 50.494120804550946],
              [-5.036450514386848, 50.493929614236563],
              [-5.036624958103057, 50.494494469862474],
              [-5.038238263587761, 50.494934488265088],
              [-5.038606703575049, 50.495884674297045],
              [-5.039657428468587, 50.495491102249211],
              [-5.038839422987002, 50.496082789768529],
              [-5.040074749839435, 50.496674786896598],
              [-5.041201436722137, 50.496343982349764],
              [-5.043489534591282, 50.496398367435198],
              [-5.043190527647381, 50.497231073273177],
              [-5.043964079754399, 50.496650413415367],
              [-5.04526339727065, 50.496416699095441],
              [-5.04530139039453, 50.49698511537116],
              [-5.046747379946708, 50.497802708622785],
              [-5.044491060726405, 50.497454304054898],
              [-5.043581675951492, 50.498883193252375],
              [-5.043394966119981, 50.498633508650116],
              [-5.043137593133384, 50.498885818757287],
              [-5.041778078580059, 50.498710891478822],
              [-5.041299403474445, 50.49892130956006],
              [-5.042863397628821, 50.499644111563178],
              [-5.041879986130294, 50.499440453946384],
              [-5.042164481969912, 50.499807222273184],
              [-5.04186052610279, 50.499993282199803],
              [-5.042410897895341, 50.499954598106754],
              [-5.042171107695943, 50.500612139741271],
              [-5.041567944830593, 50.500465997940992],
              [-5.041278456671276, 50.499824098685124],
              [-5.040964086645173, 50.499979844119338],
              [-5.042481653819221, 50.501274175450639],
              [-5.042473987720791, 50.50275592269552],
              [-5.040519291054854, 50.502363566906041],
              [-5.040069209675814, 50.503063482398936],
              [-5.040771396393376, 50.503301495137045],
              [-5.040595344583683, 50.503678508384574],
              [-5.039804500119404, 50.503772943751336],
              [-5.03802696498326, 50.503218495496021],
              [-5.03812233669623, 50.503451684133616],
              [-5.037552740383673, 50.503433278950993],
              [-5.037650483007696, 50.504010930482686],
              [-5.037302153937606, 50.50370428862346],
              [-5.036895954210818, 50.503836334067991],
              [-5.037064548496846, 50.504375254721616],
              [-5.036628582692501, 50.50457104434723],
              [-5.035496940834087, 50.504476448621105],
              [-5.035201176441547, 50.503779826815546],
              [-5.034358915371042, 50.503930438828263],
              [-5.034900774741315, 50.505890793169222],
              [-5.034073463762848, 50.506560947221466],
              [-5.035485670313486, 50.507312096238863],
              [-5.034847947343125, 50.507309848095524],
              [-5.033990608886859, 50.508193975860799],
              [-5.033409292671299, 50.508213636332009],
              [-5.033620827860599, 50.507860896441635],
              [-5.033007435190442, 50.50806219920716],
              [-5.032836990353417, 50.508395875652013],
              [-5.033376884994432, 50.508522124389494],
              [-5.031859728195929, 50.509082516697006],
              [-5.032234777435675, 50.509519810959866],
              [-5.031467664782943, 50.509215973138296],
              [-5.031602819064975, 50.508771675731985],
              [-5.030607528324014, 50.508891166218092],
              [-5.030718768677949, 50.50871195776471],
              [-5.029850698912313, 50.508637424151281],
              [-5.028415011929884, 50.509589647074215],
              [-5.027871380210399, 50.509405002523479],
              [-5.02842146451954, 50.509007476963362],
              [-5.027513301270115, 50.509298283288658],
              [-5.024300106453115, 50.508507526490575],
              [-5.023348553923086, 50.509179033733687],
              [-5.021212640927579, 50.509241731686132],
              [-5.022777232289242, 50.50937738925262],
              [-5.026063261691863, 50.510269740422295],
              [-5.026450918202364, 50.510463849357727],
              [-5.02620819171224, 50.510793099533764],
              [-5.026910689234192, 50.510748732827089],
              [-5.026715099561446, 50.511197295580622],
              [-5.028085468807479, 50.511030293888965],
              [-5.028218874339132, 50.511769840107497],
              [-5.027061234038311, 50.511602075665117],
              [-5.028489581020748, 50.512296218552144],
              [-5.028943728683455, 50.512052311466746],
              [-5.029405471063042, 50.512653771778787],
              [-5.029260949499021, 50.513194560815037],
              [-5.026801246900694, 50.514042094486079],
              [-5.027408454293134, 50.514557918971477],
              [-5.028214650049962, 50.514281463299398],
              [-5.028436103478574, 50.514743457781591],
              [-5.029285558965157, 50.514547726356113],
              [-5.029714316517409, 50.514854096179057],
              [-5.027010267854844, 50.516073210543908],
              [-5.027714060588152, 50.516708857177299],
              [-5.027632659988398, 50.517068092860441],
              [-5.028066594137579, 50.516926363788201],
              [-5.028592412481763, 50.517294078163076],
              [-5.027360016913233, 50.517635601827024],
              [-5.02826833498555, 50.518203853672219],
              [-5.027829535179475, 50.518512124653697],
              [-5.027736443397732, 50.51941588534573],
              [-5.026053946845914, 50.520243166351754],
              [-5.02663837598119, 50.520335905236479],
              [-5.026598933905398, 50.520557318348459],
              [-5.028765079425693, 50.520011585868538],
              [-5.030043269421788, 50.520207678539492],
              [-5.029816753737944, 50.520834260603039],
              [-5.028845766640242, 50.521204971321481],
              [-5.029035431612901, 50.5215445555841],
              [-5.02665524629116, 50.522340536314005],
              [-5.030247376347659, 50.52180713816459],
              [-5.029760930046603, 50.522693261427577],
              [-5.026240999195989, 50.523518014784777],
              [-5.026961567579647, 50.523532548769829],
              [-5.026955991650185, 50.523753980150083],
              [-5.029497398567885, 50.52329292099909],
              [-5.029141594356651, 50.52370787890689],
              [-5.02942224798428, 50.523771633448682],
              [-5.02773786911512, 50.524285047686966],
              [-5.029181191269148, 50.524413884630079],
              [-5.029313054059481, 50.524709996780075],
              [-5.026216137541951, 50.526259552248291],
              [-5.025617585453365, 50.525704815903531],
              [-5.025514135048632, 50.525984564150981],
              [-5.024453943054988, 50.525602845323782],
              [-5.024490816561618, 50.525252866173403],
              [-5.023648655377498, 50.525411489879595],
              [-5.022951338640876, 50.525871286323614],
              [-5.023360179010466, 50.526196188017103],
              [-5.022825862149378, 50.525983391061708],
              [-5.022589888377636, 50.526530144566713],
              [-5.023510466914942, 50.526979376491397],
              [-5.023358409257961, 50.527293668911469],
              [-5.024082923638125, 50.527170489518014],
              [-5.0240597829972, 50.527646946095992],
              [-5.025252536629954, 50.528004531950288],
              [-5.025034229046754, 50.528782911492414],
              [-5.025839783108025, 50.528823128642117],
              [-5.025255814684529, 50.529136064113914],
              [-5.025658208899212, 50.529006859473618],
              [-5.026493037874852, 50.529460097698497],
              [-5.025982585063239, 50.530061674455709],
              [-5.025133101515459, 50.530107157004821],
              [-5.025346179770128, 50.530635940348382],
              [-5.025013983600358, 50.530891952039482],
              [-5.024576189125445, 50.53066675777945],
              [-5.023440432687911, 50.531487874857682],
              [-5.022628961088258, 50.532657670520543],
              [-5.023028911515901, 50.535644531287325],
              [-5.024886077728793, 50.537448399390023],
              [-5.024179799753064, 50.538674838500093],
              [-5.024826532172625, 50.539274206153756],
              [-5.024647010953736, 50.539600907570097],
              [-5.025290551311664, 50.539488823032912],
              [-5.025448939224544, 50.541036402910805],
              [-5.026363590036884, 50.541322052682837],
              [-5.026368425678046, 50.541706028596273],
              [-5.029882205524063, 50.541754894444921],
              [-5.030702866699061, 50.54248912822068],
              [-5.0314849205394, 50.542445363960944],
              [-5.032437452153827, 50.542924279164531],
              [-5.033235262205449, 50.542818024441395],
              [-5.03356599510629, 50.543593796586507],
              [-5.034243817835923, 50.543465476719838],
              [-5.034575503704455, 50.543838228832385],
              [-5.03385649841885, 50.544182613803571],
              [-5.035035518846949, 50.54445050708344],
              [-5.034823142386689, 50.544902217625996],
              [-5.035665495305587, 50.544895532612998],
              [-5.035157484102735, 50.545535764462983],
              [-5.037274994280379, 50.545029832751098],
              [-5.0379610874497, 50.544481191553793],
              [-5.039651304983604, 50.545080210731633],
              [-5.039594545783217, 50.546019011578409],
              [-5.038287654142469, 50.546661236946647],
              [-5.037224669124614, 50.546596340773959],
              [-5.037481381543959, 50.546834312533022],
              [-5.037159134569588, 50.547135974635388],
              [-5.036638834677133, 50.546399342967199],
              [-5.035003756985657, 50.547757132284495],
              [-5.036693507521537, 50.549072238124701],
              [-5.036585754734968, 50.549438463327128],
              [-5.032629483878198, 50.550488869200926],
              [-5.030122948106223, 50.550525393989751],
              [-5.029247898317457, 50.550860320073497],
              [-5.029266358534496, 50.551126101780262],
              [-5.027751591289127, 50.551481269449297],
              [-5.026404096995475, 50.551406586233966],
              [-5.02579571722885, 50.550659605126192],
              [-5.025940468748828, 50.550935595603541],
              [-5.025236566340896, 50.551452243273779],
              [-5.025042655686963, 50.551224305949724],
              [-5.024278333422276, 50.551591396908002],
              [-5.024633377396516, 50.55096238806626],
              [-5.024411002801216, 50.550333997415827],
              [-5.023721699851869, 50.550218783361103],
              [-5.02358766821254, 50.549845361927879],
              [-5.023333078282143, 50.55023517972122],
              [-5.022900603765602, 50.549985552587884],
              [-5.022219284974251, 50.550171466126777],
              [-5.022241888719257, 50.549840749965604],
              [-5.021301089857286, 50.550207907982141],
              [-5.021464546460221, 50.549739503553006],
              [-5.022096418415285, 50.549509002613647],
              [-5.02194447404567, 50.548480284326082],
              [-5.02114079895933, 50.547547650911213],
              [-5.021912220912657, 50.547115629179352],
              [-5.02108704472401, 50.547192831291731],
              [-5.020700228390845, 50.54695010635794],
              [-5.020457983369008, 50.545899443297131],
              [-5.019893050489578, 50.545917707224675],
              [-5.019195872502344, 50.545258453225486],
              [-5.018781627426164, 50.545315079173726],
              [-5.018333625389462, 50.544931807823055],
              [-5.016983870455209, 50.544929038345529],
              [-5.015961012276581, 50.544164869499184],
              [-5.015246990116495, 50.544080830974501],
              [-5.013928305141619, 50.544830130304284],
              [-5.014278806091196, 50.545168269327945],
              [-5.013618327996137, 50.545304118546213],
              [-5.012107430114228, 50.545010419648229],
              [-5.009042155099131, 50.545445956824985],
              [-5.00894591635033, 50.545685922497874],
              [-5.006036194049887, 50.546348536176303],
              [-5.003542855268211, 50.546522676133819],
              [-5.00361178257734, 50.546008163690509],
              [-5.004388432889247, 50.545456486648078],
              [-5.003432738761215, 50.545323744903513],
              [-5.00336171215591, 50.544983756529092],
              [-5.004710073204915, 50.544387632063163],
              [-5.004287974199498, 50.544232119609596],
              [-5.004710452446227, 50.543150765881627],
              [-5.003915884778281, 50.543042651854186],
              [-5.004154790656254, 50.542759427047052],
              [-5.003593552821389, 50.542502259827394],
              [-5.003326779634146, 50.541570035791366],
              [-5.002073015302825, 50.54085488520429],
              [-4.998247132901898, 50.540268107257205],
              [-4.99641302150307, 50.540304567899838],
              [-4.995080211936723, 50.540700500295131],
              [-4.995078752994702, 50.541790769037362],
              [-4.994744523986053, 50.541706721591432],
              [-4.994368287288314, 50.542340680221947],
              [-4.993792673132409, 50.542457141259426],
              [-4.994186710590808, 50.543083868054666],
              [-4.993515921595752, 50.543191083208583],
              [-4.992817964033203, 50.542824941011226],
              [-4.992537445712162, 50.543410554899417],
              [-4.991176812662548, 50.543146002650133],
              [-4.990836559916101, 50.543501968893082],
              [-4.989559451003998, 50.543731790802902],
              [-4.989541468987013, 50.543514566738224],
              [-4.989362300256449, 50.543828608826423],
              [-4.988905616211662, 50.543058651461102],
              [-4.9886660797982, 50.543511871390578],
              [-4.987537542197575, 50.542251831814141],
              [-4.986904730718816, 50.542825788726375],
              [-4.986334034914893, 50.542395172616764],
              [-4.985454277850973, 50.542680414650548],
              [-4.985392096162746, 50.542277222276425],
              [-4.984494542779155, 50.542615985897491],
              [-4.984486508981374, 50.542265375733272],
              [-4.98427051875642, 50.542488602435867],
              [-4.983761589936766, 50.542230898043179],
              [-4.983299293693317, 50.543628027882065],
              [-4.980901017612005, 50.543898192915279],
              [-4.979909846641934, 50.544700777020822],
              [-4.980604695130037, 50.545600495782594],
              [-4.979683191532641, 50.545740139725744],
              [-4.979180456178581, 50.545110751722163],
              [-4.978590340586954, 50.54544609337453],
              [-4.977598586427112, 50.545367135090565],
              [-4.977169052225021, 50.546279454064432],
              [-4.977443386729778, 50.54648832011344],
              [-4.981346138551092, 50.547819523352842],
              [-4.981507364855896, 50.548248964110968],
              [-4.981176748503692, 50.548580366734534],
              [-4.976496252640525, 50.549425223860169],
              [-4.977374874321139, 50.549499891219789],
              [-4.977450572633747, 50.550275145251135],
              [-4.977149257404764, 50.549817800609482],
              [-4.976512612761711, 50.549931238894196],
              [-4.976383644754066, 50.551962071303372],
              [-4.975119041141569, 50.55223818724297],
              [-4.974695176561676, 50.552166268762207],
              [-4.974699827011706, 50.551746970629843],
              [-4.97439676256347, 50.552337611845317],
              [-4.973986228260921, 50.552387685541852],
              [-4.974303751083875, 50.553035323316301],
              [-4.974861705326078, 50.553084025269293],
              [-4.975281499414133, 50.553584221757923],
              [-4.974320270705661, 50.553880455464615],
              [-4.974060350140638, 50.553721587005619],
              [-4.972968829140047, 50.554527572839724],
              [-4.973600932069765, 50.554475438447312],
              [-4.972775563935359, 50.55641431847728],
              [-4.972247561927302, 50.556662583963693],
              [-4.972094011202895, 50.557767525307298],
              [-4.970919737626088, 50.557619409206339],
              [-4.970495866374971, 50.558311171928814],
              [-4.969559793271311, 50.558784828694897],
              [-4.968879098761857, 50.558491863491355],
              [-4.965652109420629, 50.558216148929347],
              [-4.963780749512365, 50.559177748300129],
              [-4.962198886238931, 50.559375447823115],
              [-4.961414312781208, 50.560419976338999],
              [-4.956673841352419, 50.562924266753548],
              [-4.954528994256944, 50.562951762820859],
              [-4.954997034883565, 50.563570442227473],
              [-4.956877912529079, 50.564536421784418],
              [-4.95673540093543, 50.56514722051265],
              [-4.955953689032098, 50.56509062228664],
              [-4.955788346715452, 50.565562573815825],
              [-4.956440819460169, 50.566464409371093],
              [-4.955917442903861, 50.566857304172224],
              [-4.955897190781827, 50.567503675120157],
              [-4.955178476217263, 50.567280858627406],
              [-4.953940091259338, 50.567961757353231],
              [-4.953471431755227, 50.56866538549653],
              [-4.95214334311722, 50.569177632318414],
              [-4.950710322974627, 50.569051166471404],
              [-4.949571443965009, 50.569359791436959],
              [-4.948373408507615, 50.568744297782956],
              [-4.946229782204963, 50.568840870558219],
              [-4.946065771470887, 50.569086993942406],
              [-4.945196299481712, 50.568733904304445],
              [-4.94501700699052, 50.56923317797817],
              [-4.944599531105818, 50.568586194295605],
              [-4.946935445257975, 50.564353285378111],
              [-4.947131973758636, 50.561981669031979],
              [-4.947850136874898, 50.561202482116947],
              [-4.947272523180205, 50.561043506870348],
              [-4.947721121650336, 50.560515818008767],
              [-4.949108427767801, 50.560276472646223],
              [-4.947880136895802, 50.559783177338161],
              [-4.948676708292304, 50.55881309869762],
              [-4.948631704064915, 50.557662851748226],
              [-4.949820186818966, 50.556208783973673],
              [-4.94806246034531, 50.555330424468622],
              [-4.943812434704709, 50.554787678660809],
              [-4.942439035930288, 50.554160371738099],
              [-4.9420192083248, 50.554424653075053],
              [-4.939799132113862, 50.553986031943957],
              [-4.939733115111189, 50.553537939673319],
              [-4.93893893655819, 50.553459055632331],
              [-4.938346447716819, 50.552900126028149],
              [-4.937998659164794, 50.551508355319683],
              [-4.938552177554591, 50.551237113813009],
              [-4.93845468031402, 50.550850083753637],
              [-4.937470281207243, 50.550912721166043],
              [-4.935669490712814, 50.549516241085954],
              [-4.934566587656414, 50.547852966019832],
              [-4.934943416476628, 50.547623977727127],
              [-4.933927083829831, 50.54687602379704],
              [-4.934328595288616, 50.54665810876147],
              [-4.93387573231252, 50.546435654183298],
              [-4.934106277520119, 50.546079027255395],
              [-4.935075019338044, 50.545402439820208],
              [-4.935118928220225, 50.544359686918241],
              [-4.935623570318275, 50.543802744396615],
              [-4.935233380142408, 50.543907038959389],
              [-4.936123776989023, 50.543106485693272],
              [-4.935930874231135, 50.541910492919826],
              [-4.936337824124153, 50.54284831810088],
              [-4.936839105550471, 50.542578402099949],
              [-4.936961097076449, 50.541929466156638],
              [-4.937397671760599, 50.542094750477311],
              [-4.938106575845405, 50.541804294711014],
              [-4.938061260473883, 50.541302606471319],
              [-4.937180219196744, 50.54127627879955],
              [-4.936823685635399, 50.541603710081205],
              [-4.935365405415684, 50.538966235499977],
              [-4.936106223290047, 50.541052421922323],
              [-4.935749183906298, 50.541099211960017],
              [-4.933776272931752, 50.536696349221167],
              [-4.933807710081787, 50.535384053808848],
              [-4.934616613173659, 50.534114215703951],
              [-4.934525837839376, 50.53287966265124],
              [-4.934876253325929, 50.532613559510345],
              [-4.934280246184775, 50.532651981699182],
              [-4.932910377171171, 50.531795999033328],
              [-4.932355776355444, 50.531000410137864],
              [-4.930800411024274, 50.529973671943807],
              [-4.93146705250369, 50.529641142763182],
              [-4.932381099510566, 50.528201358217672],
              [-4.9366130403777, 50.527948882059114],
              [-4.938644157059932, 50.527365972656028],
              [-4.938293382070086, 50.527194029022226],
              [-4.936546416697635, 50.52751249614564],
              [-4.935674708715752, 50.527132410971973],
              [-4.937856600155021, 50.526001498341742],
              [-4.938674117861574, 50.52516947876763],
              [-4.940735374581693, 50.521307903582468],
              [-4.941203215502164, 50.520935366016218],
              [-4.942356794934637, 50.520812641856942],
              [-4.94205143078557, 50.520394890318528],
              [-4.940183082895386, 50.52041334484344],
              [-4.940023673690902, 50.516783294338339],
              [-4.937367314040428, 50.512630733688887],
              [-4.936217545264161, 50.51158483103179],
              [-4.937534997652565, 50.513331726578286],
              [-4.938443149599208, 50.515868835992421],
              [-4.938053572652522, 50.516092763608789],
              [-4.93852194245143, 50.516205966338298],
              [-4.938825768943585, 50.517031250430492],
              [-4.938458158627928, 50.517540673130284],
              [-4.939036436270571, 50.518105357307221],
              [-4.938597584433238, 50.518830667136193],
              [-4.938924692336841, 50.521323980215122],
              [-4.937889023928092, 50.522300039015555],
              [-4.937468464111691, 50.523343313154825],
              [-4.937713188898538, 50.524670227183236],
              [-4.935371279327558, 50.525063968645796],
              [-4.934857699042645, 50.525360276821189],
              [-4.934870051611789, 50.525854702855661],
              [-4.930932370868384, 50.525092238300466],
              [-4.929542584943256, 50.524342862830117],
              [-4.929004017169371, 50.524486855412306],
              [-4.929152952770404, 50.526366704747147],
              [-4.927706464856851, 50.527071462922699],
              [-4.926815371621929, 50.52936247922041],
              [-4.928822111740006, 50.529271495248189],
              [-4.929494693589914, 50.529353507386176],
              [-4.929453383817212, 50.529643294145394],
              [-4.926843440066518, 50.529496701241747],
              [-4.926322520136298, 50.530291490121442],
              [-4.92481673972811, 50.531135328822835],
              [-4.919696370468376, 50.531472639684786],
              [-4.910314061457826, 50.530575240209494],
              [-4.909520905106484, 50.530093148503695],
              [-4.909721240630548, 50.529404503668466],
              [-4.908526591191019, 50.528612218194702],
              [-4.908804688305946, 50.528453246230171],
              [-4.910031442671714, 50.529262716148864],
              [-4.910801238797375, 50.527830312932409],
              [-4.908256585474372, 50.528512825351477],
              [-4.905876841527729, 50.527540546185875],
              [-4.899736613139289, 50.526269826294296],
              [-4.898254142803061, 50.523540753507582],
              [-4.899388456883526, 50.522433965256056],
              [-4.897853760701305, 50.522602636461848],
              [-4.896764728063251, 50.520902686441694],
              [-4.895521017672657, 50.520085405553324],
              [-4.895123954784776, 50.520329162913491],
              [-4.896746288526019, 50.522098604625349],
              [-4.896696545681041, 50.523428430686572],
              [-4.897474008659239, 50.525546321953065],
              [-4.897167207487068, 50.526089175902861],
              [-4.899299723874102, 50.526002764747467],
              [-4.899436601647292, 50.526209840377781],
              [-4.896583406101346, 50.526441032676416],
              [-4.894514423274532, 50.526943188741832],
              [-4.891255259642166, 50.528889832551805],
              [-4.888481467961026, 50.529684646015276],
              [-4.888152883547788, 50.52954978107546],
              [-4.885240737695223, 50.530183339887287],
              [-4.877694828511893, 50.53027399416758],
              [-4.875836267444938, 50.529648016430933],
              [-4.87313012366122, 50.529355076333005],
              [-4.870456392275049, 50.529967083084308],
              [-4.864091673444133, 50.529219042422021],
              [-4.862252350050746, 50.529862485807399],
              [-4.860091705856193, 50.529301258581761],
              [-4.855213868974199, 50.528867877864698],
              [-4.852291474212279, 50.527934812264903],
              [-4.849861349042818, 50.52650477993506],
              [-4.849208791740512, 50.525955871687458],
              [-4.850444723664431, 50.525414673251817],
              [-4.849306886457526, 50.525850924999951],
              [-4.848882536288075, 50.525435849426088],
              [-4.848929879309217, 50.525729729310456],
              [-4.847852963306139, 50.524671288361375],
              [-4.847823997825233, 50.524963438650801],
              [-4.845458569319666, 50.523689146356524],
              [-4.840881549091008, 50.521369635879118],
              [-4.835096795404056, 50.516618332170523],
              [-4.835831012790975, 50.515404988001343],
              [-4.836486462170553, 50.515325142248543],
              [-4.839119430274514, 50.513570731240151],
              [-4.840826419527112, 50.512960565877862],
              [-4.839101306586686, 50.513480323078703],
              [-4.836434749360339, 50.515239150794706],
              [-4.835780171174445, 50.515309979935189],
              [-4.834963303832671, 50.516438985199891],
              [-4.83506180444861, 50.516881841571418],
              [-4.832306733054806, 50.515519634724754],
              [-4.831314531692475, 50.51369799679415],
              [-4.830212250490516, 50.512935052171407],
              [-4.829160231586894, 50.512705182954157],
              [-4.827542858576272, 50.513636820520645],
              [-4.826664163004097, 50.513781408460417],
              [-4.825608867586378, 50.513685613402203],
              [-4.823774530433092, 50.512899010918005],
              [-4.822975323237864, 50.512342723960955],
              [-4.822747710389034, 50.510916231170349],
              [-4.821224585925795, 50.509503175573414],
              [-4.820216832280522, 50.509043677676772],
              [-4.819152851289497, 50.509060473871074],
              [-4.816868556046218, 50.507866397499868],
              [-4.81613320818659, 50.505868406685138],
              [-4.816265815885489, 50.504755203859673],
              [-4.816759884769587, 50.504548946938883],
              [-4.817009458880422, 50.503742341092817],
              [-4.816725603850732, 50.502006868453464],
              [-4.814046348749474, 50.501231578384356],
              [-4.812018991829202, 50.499582338644316],
              [-4.809728043044745, 50.499310281211315],
              [-4.808618992910972, 50.498754185779433],
              [-4.807158542310638, 50.498623488149057],
              [-4.805065776878343, 50.497504162687932],
              [-4.805154638637694, 50.496457692152788],
              [-4.802664793822013, 50.495371742447595],
              [-4.803139249608424, 50.49483499698232],
              [-4.802902322934245, 50.492596436955445],
              [-4.802916682951399, 50.49481517449258],
              [-4.80247355122489, 50.495380848021036],
              [-4.803035731146785, 50.495872824028247],
              [-4.804815794327898, 50.496427184886471],
              [-4.804603355179649, 50.497292236043911],
              [-4.806624165192738, 50.498575661590472],
              [-4.807271028486781, 50.498683649764139],
              [-4.808568429889371, 50.498900227354468],
              [-4.809310364524871, 50.499401325652904],
              [-4.81183308459681, 50.499728955165409],
              [-4.814096407745305, 50.50148043042195],
              [-4.816348241477441, 50.502064579492924],
              [-4.816760320527497, 50.503348093537717],
              [-4.816653115021288, 50.504342846636135],
              [-4.81601192870404, 50.504683992955144],
              [-4.815979319479268, 50.506410937194346],
              [-4.816484961829635, 50.507796529460165],
              [-4.818357221580122, 50.509035677978879],
              [-4.816093463764124, 50.509181354526717],
              [-4.812956902798676, 50.508289377307051],
              [-4.812165519141876, 50.508383171327793],
              [-4.810639175098128, 50.507740034950523],
              [-4.808249953499943, 50.507862504809395],
              [-4.80840985073746, 50.50863132011051],
              [-4.807014544631877, 50.508817015550228],
              [-4.806796426545652, 50.509443837236319],
              [-4.805516027851425, 50.509973950823685],
              [-4.804847077296157, 50.511467070311966],
              [-4.805731640673398, 50.510303366436553],
              [-4.807045955618746, 50.509441414025034],
              [-4.807137126758019, 50.508886017880016],
              [-4.808576920991967, 50.508689352269137],
              [-4.808363368454494, 50.507943420741867],
              [-4.809454210082324, 50.508094282085722],
              [-4.810523593384799, 50.507836376320924],
              [-4.812113890084439, 50.508511249907322],
              [-4.81277378864396, 50.508364866354704],
              [-4.815895178218351, 50.509262612827591],
              [-4.820662745662879, 50.509425051040651],
              [-4.822323863924389, 50.511047049665045],
              [-4.822766292432999, 50.512622146386619],
              [-4.825535817795831, 50.513903269693671],
              [-4.827128186515574, 50.51401569764046],
              [-4.829126505963575, 50.513084696290271],
              [-4.830142653916937, 50.513115747682242],
              [-4.831581171880283, 50.515701008933341],
              [-4.836903120546046, 50.519213454455262],
              [-4.840178499522779, 50.521879733774753],
              [-4.842538189895449, 50.523320681450386],
              [-4.843020702539535, 50.523272910018044],
              [-4.842628481714098, 50.523789818025229],
              [-4.842805421197423, 50.5243755049055],
              [-4.842808176427642, 50.524384624263973],
              [-4.842887447735986, 50.524647018670763],
              [-4.842421377126781, 50.524780740570094],
              [-4.842784778124991, 50.524397287245193],
              [-4.841713542293906, 50.524977035550556],
              [-4.843934156016266, 50.524595349259272],
              [-4.843240514633297, 50.524581720982845],
              [-4.842913241858404, 50.523832332457992],
              [-4.843305181276292, 50.523639253730174],
              [-4.848163674589437, 50.526783826390691],
              [-4.847886863803737, 50.527880808760749],
              [-4.848521868300726, 50.530667234199022],
              [-4.849192425151038, 50.531209409031334],
              [-4.848357144390467, 50.532289484410924],
              [-4.849931928652079, 50.53324577512614],
              [-4.850772520523784, 50.533540908157214],
              [-4.850137523908911, 50.532934005876363],
              [-4.851281749813413, 50.532788132677076],
              [-4.850604898231345, 50.532774135917222],
              [-4.851103110039254, 50.53256403530208],
              [-4.850801599051699, 50.532432000037026],
              [-4.849958123736309, 50.532861042974488],
              [-4.849502038143382, 50.532669825215955],
              [-4.849419288000383, 50.53242088891281],
              [-4.850100225566878, 50.531777251066906],
              [-4.850229091708876, 50.530848498698461],
              [-4.852717765118411, 50.531631240698324],
              [-4.853769469292237, 50.532431197307574],
              [-4.851878656345638, 50.53281038182638],
              [-4.851786026308623, 50.533403630239995],
              [-4.854886011103559, 50.533743173726727],
              [-4.856502739680871, 50.534456379154001],
              [-4.858778750743768, 50.534565084264877],
              [-4.859028492735206, 50.53549713652707],
              [-4.860813665606901, 50.536029839167561],
              [-4.859199739849017, 50.535621558052213],
              [-4.859435417260093, 50.53697402635995],
              [-4.860148574260485, 50.537190368984291],
              [-4.860962778470939, 50.536581170406883],
              [-4.860917635818812, 50.536068659991599],
              [-4.861289068816788, 50.536212442376957],
              [-4.862517556547001, 50.535779241674938],
              [-4.865045959147559, 50.533950363576125],
              [-4.866529568062616, 50.533815770971842],
              [-4.866843974034392, 50.534112958216227],
              [-4.866427925356009, 50.535700053501166],
              [-4.866777667924523, 50.53560238428377],
              [-4.867492198047989, 50.536003947532116],
              [-4.866862462373314, 50.536348688348973],
              [-4.86755797574836, 50.53722746092636],
              [-4.867055986427545, 50.536397890073886],
              [-4.867652334921042, 50.536223978848177],
              [-4.867586074733351, 50.535829826966925],
              [-4.866811119013922, 50.535455839135601],
              [-4.867119311065545, 50.534766414578044],
              [-4.867596990367659, 50.534916553591202],
              [-4.866996235528897, 50.534482503418573],
              [-4.86719697360339, 50.534069178061927],
              [-4.870131009056232, 50.533672107269389],
              [-4.873453388126708, 50.534811633185996],
              [-4.87770549196278, 50.534528429370177],
              [-4.878874187979369, 50.534622748475385],
              [-4.879525209126843, 50.534999724143923],
              [-4.879662285904291, 50.53426142867437],
              [-4.880412711518209, 50.534185282493183],
              [-4.883475217454728, 50.534553777731432],
              [-4.886322600452048, 50.534495762965634],
              [-4.890193274530056, 50.53621578890926],
              [-4.890504977950365, 50.535727703870165],
              [-4.889850835895034, 50.534216577959086],
              [-4.890028912039809, 50.533643660759793],
              [-4.891921453181703, 50.532328314154377],
              [-4.895742392117064, 50.531071093964741],
              [-4.89996255275653, 50.530941684382093],
              [-4.90197034351627, 50.531509585624512],
              [-4.903151964252858, 50.532412004167476],
              [-4.904800685096524, 50.533037391164278],
              [-4.905839155919695, 50.534043203617067],
              [-4.906162182214066, 50.535073177982412],
              [-4.906911237401506, 50.535434060262645],
              [-4.913615513036989, 50.536856696757823],
              [-4.915422151946241, 50.538046475812337],
              [-4.916751552427868, 50.540306926545689],
              [-4.915852644634405, 50.54099690198538],
              [-4.913336065517969, 50.541778662218761],
              [-4.914109273303793, 50.542940364735323],
              [-4.914926673531019, 50.543353455665859],
              [-4.917357465907824, 50.543330307730798],
              [-4.919298518013618, 50.543651320953224],
              [-4.919757347584096, 50.543378047129885],
              [-4.919964787036704, 50.543696667758454],
              [-4.921905114327267, 50.544165176988429],
              [-4.923600470296537, 50.544050625182969],
              [-4.926078738740633, 50.544861755686526],
              [-4.927084954469906, 50.546132652457963],
              [-4.926522067347824, 50.547711076674247],
              [-4.927367514166094, 50.548117978805074],
              [-4.927466092013945, 50.550209579171565],
              [-4.929303269690151, 50.553243292678296],
              [-4.928406435896083, 50.555561440207939],
              [-4.929182793823514, 50.556490890130121],
              [-4.92907685028656, 50.55699099097604],
              [-4.927427808036638, 50.558588658612429],
              [-4.926483102783185, 50.561436009400289],
              [-4.926816460768872, 50.561938550727788],
              [-4.928784372239382, 50.562292018029268],
              [-4.930277459316758, 50.563231303348608],
              [-4.931134987494117, 50.563986888557196],
              [-4.931102246445459, 50.564505835697865],
              [-4.929519650429789, 50.565242816789947],
              [-4.928236457811131, 50.566481368550839],
              [-4.928321899811475, 50.566788652328754],
              [-4.926716280652522, 50.56795344399454],
              [-4.927348262385078, 50.569011570124687],
              [-4.925362655606458, 50.569949327737284],
              [-4.925083257462065, 50.571662733875591],
              [-4.923918866142357, 50.571905182607225],
              [-4.923440494934524, 50.573098265970934],
              [-4.921760829930409, 50.573300565100077],
              [-4.922024121514719, 50.574039652434259],
              [-4.920653297112619, 50.574097453439613],
              [-4.919442220717841, 50.574522730325427],
              [-4.917968730963016, 50.574496721717516],
              [-4.916878258510312, 50.573880007937007],
              [-4.915532843904656, 50.575995200521483],
              [-4.917228643447758, 50.57762849696244],
              [-4.91761736381022, 50.577563881370281],
              [-4.919002708928153, 50.578424157190462],
              [-4.918673424650379, 50.578513372608185],
              [-4.91901388555844, 50.578833155937708],
              [-4.91819613818834, 50.57920177704036],
              [-4.919062222948973, 50.579203442930172],
              [-4.918454426827601, 50.579609076401134],
              [-4.917518630816923, 50.579691009423868],
              [-4.917557374653907, 50.580340386794624],
              [-4.920390957007564, 50.579923898844498],
              [-4.920671943155305, 50.581016953406397],
              [-4.921922266644965, 50.580470144937344],
              [-4.922866036327308, 50.580699208411652],
              [-4.923868669106622, 50.581786721656357],
              [-4.925091213801406, 50.582204857123251],
              [-4.924825151141912, 50.5825848430622],
              [-4.925587212046358, 50.582776174339983],
              [-4.926200869499412, 50.583626978796943],
              [-4.927561233157285, 50.583578372943769],
              [-4.92724340088771, 50.584011838640841],
              [-4.928574914549855, 50.583999027780102],
              [-4.930366279794107, 50.584633968323175],
              [-4.930206509339807, 50.584836784191182],
              [-4.934711274640506, 50.586140010414013],
              [-4.93497173077013, 50.586715429029134],
              [-4.935407780856377, 50.586752105630055],
              [-4.934507153779288, 50.58801435171997],
              [-4.93299116123242, 50.588925097951048],
              [-4.93159568155305, 50.588865809331388],
              [-4.930863894113505, 50.588458770064193],
              [-4.929934651070013, 50.589035369927217],
              [-4.926636257377328, 50.589801993311347],
              [-4.926431724111592, 50.590194827238342],
              [-4.925085711084791, 50.590819646099213],
              [-4.923755228225976, 50.590783815760233],
              [-4.922204992305224, 50.591465902895692],
              [-4.921228513309098, 50.591350992347095],
              [-4.921704877715906, 50.592106319402248],
              [-4.921132048870104, 50.592508388662608],
              [-4.923565091496818, 50.593278446058882],
              [-4.923553797345015, 50.593688907919756],
              [-4.92458342759418, 50.594158671112332],
              [-4.922311339106652, 50.594618725438018],
              [-4.921330520243904, 50.593978608603337],
              [-4.920627839706039, 50.593904493956202],
              [-4.920534491958367, 50.594405166623126],
              [-4.918550118036804, 50.59451431301445],
              [-4.918016503858701, 50.594043755336621],
              [-4.918998860838755, 50.593179875910401],
              [-4.918891832047348, 50.591770325007808],
              [-4.919246700855894, 50.591636391994925],
              [-4.918651057729832, 50.59144174459739],
              [-4.918699410696957, 50.591035751138321],
              [-4.918426032653768, 50.591230604968523],
              [-4.916883008858946, 50.590568567503233],
              [-4.91611097442738, 50.590605904739007],
              [-4.916038414866676, 50.58963804809823],
              [-4.916673287085137, 50.588822472052179],
              [-4.915973554570358, 50.588817518515697],
              [-4.915755861259178, 50.588244585974401],
              [-4.914601872414309, 50.588571230711977],
              [-4.913920749720538, 50.588044081486586],
              [-4.913585531575116, 50.58833582065273],
              [-4.91327907813367, 50.587789392622206],
              [-4.912920817383987, 50.588096998386675],
              [-4.911429171440694, 50.588287240882813],
              [-4.911273310727518, 50.587982607187243],
              [-4.91012039289101, 50.587937681991264],
              [-4.909612705643095, 50.586350143816048],
              [-4.909869177848465, 50.585995620186473],
              [-4.909342063226211, 50.585971019191177],
              [-4.909261037407873, 50.585184172539286],
              [-4.908534502039767, 50.585224820298137],
              [-4.908323189855119, 50.584547371553278],
              [-4.906887955053814, 50.58487197317168],
              [-4.904342208828767, 50.584898653047013],
              [-4.904246187472894, 50.585289638281168],
              [-4.902697117240715, 50.585372361401781],
              [-4.899871258135748, 50.584589173518864],
              [-4.899790745639313, 50.584083855548847],
              [-4.899038319917525, 50.584369756069819],
              [-4.899169273334924, 50.584707408444999],
              [-4.898744857221756, 50.584652316290786],
              [-4.896458117300005, 50.585859725478919],
              [-4.895850476587043, 50.585213706975658],
              [-4.890176746956692, 50.583835804722916],
              [-4.889133040112074, 50.582296245230452],
              [-4.886152189032823, 50.582852345431562],
              [-4.885287953218654, 50.58281260860155],
              [-4.885158869584194, 50.58229589273531],
              [-4.884343167258793, 50.582726288302062],
              [-4.884347052645643, 50.583737239694344],
              [-4.882998575432971, 50.583194967478313],
              [-4.881205863235784, 50.583127824998918],
              [-4.881515976396448, 50.584388453475157],
              [-4.88218820054745, 50.584799973292753],
              [-4.881848942542259, 50.586115359774276],
              [-4.881389795334393, 50.586046672221542],
              [-4.881108088172995, 50.586477314654431],
              [-4.879145883733586, 50.586204750024329],
              [-4.878761442257821, 50.586642425622337],
              [-4.876923618108152, 50.586949627433171],
              [-4.876028783364854, 50.586362777911148],
              [-4.875445085069458, 50.587240727476299],
              [-4.874414156092335, 50.587759123697793],
              [-4.874438477969775, 50.588506012921179],
              [-4.873635312609975, 50.588704848594681],
              [-4.873927828303789, 50.589401039630481],
              [-4.873531058676286, 50.590820362774913],
              [-4.872764926110556, 50.590491167188368],
              [-4.871974573623469, 50.589415974343972],
              [-4.871171334127794, 50.589985390999146],
              [-4.867498067163218, 50.589041520429269],
              [-4.868809972766169, 50.59026040202663],
              [-4.869977800915735, 50.590573422023702],
              [-4.871763262666506, 50.590563561126558],
              [-4.872122587963321, 50.590964868984756],
              [-4.870903996851219, 50.591489662810226],
              [-4.871553976790052, 50.591883802395266],
              [-4.871378286167071, 50.592061741613151],
              [-4.872121767726024, 50.591972335711148],
              [-4.871637364998971, 50.5921857778606],
              [-4.872069512889194, 50.59243687065667],
              [-4.871703594111682, 50.592809300141141],
              [-4.872057919570042, 50.593499472865041],
              [-4.869971492701054, 50.595415614520753],
              [-4.868821301808582, 50.595394498038416],
              [-4.86766934760352, 50.594321892618503],
              [-4.864927681679656, 50.594249089142437],
              [-4.864092170770828, 50.593498777313663],
              [-4.864525062603072, 50.593274041754945],
              [-4.863327713463988, 50.593080427683766],
              [-4.862266500171231, 50.593360190665756],
              [-4.860794524268573, 50.593041976453748],
              [-4.856897636997831, 50.594238688241262],
              [-4.856814267383059, 50.594868587778763],
              [-4.857481300589023, 50.595405407713393],
              [-4.855932102334719, 50.595772649809696],
              [-4.855705359025642, 50.596228864210985],
              [-4.853983174138457, 50.596844089314587],
              [-4.853448341468314, 50.59662333193041],
              [-4.850596443483238, 50.597089894663284],
              [-4.850906532997777, 50.597730840542432],
              [-4.850370846403205, 50.59798682590116],
              [-4.850558378164788, 50.598400501511918],
              [-4.849393353604445, 50.598836502374553],
              [-4.847446705103832, 50.598335445894648],
              [-4.846726659511075, 50.597808860778031],
              [-4.847609806631051, 50.596730343236935],
              [-4.846826538439306, 50.596537221595497],
              [-4.846726896318841, 50.595495337595807],
              [-4.844246132360988, 50.595765570503502],
              [-4.843614516594594, 50.5943606914893],
              [-4.842631273419482, 50.594834460262682],
              [-4.842287921465723, 50.594671040885544],
              [-4.842818100280381, 50.59427670408131],
              [-4.842017073483315, 50.594538232154335],
              [-4.841808359781801, 50.593114920920065],
              [-4.841177219014701, 50.5933345161774],
              [-4.840094882049551, 50.594861299158765],
              [-4.839286927521581, 50.594914293160983],
              [-4.838993753417095, 50.595396380806669],
              [-4.837318357410666, 50.596064186361026],
              [-4.835169007103183, 50.59578374716699],
              [-4.835294063136637, 50.595019724089738],
              [-4.834737795706855, 50.594605111651973],
              [-4.83509547862112, 50.594137654664735],
              [-4.834233546425977, 50.594138857932094],
              [-4.835006714879873, 50.593883459332183],
              [-4.834853045030312, 50.593278240881048],
              [-4.834468181718208, 50.593387458711305],
              [-4.834206682033496, 50.592798357908578],
              [-4.832400338684731, 50.592156012225729],
              [-4.832004683213768, 50.592838465808377],
              [-4.832802304891819, 50.593696067283147],
              [-4.833576373632209, 50.593831938634629],
              [-4.832386904159859, 50.593885177351488],
              [-4.832856524603086, 50.594457523216356],
              [-4.832143265987213, 50.595263742131202],
              [-4.831291261796446, 50.595218807387425],
              [-4.83025777395731, 50.595705386051662],
              [-4.828610949672206, 50.595508851553006],
              [-4.828339762439203, 50.594992833034162],
              [-4.827978277522274, 50.595303848220652],
              [-4.827107751728048, 50.594949905370335],
              [-4.826996005918028, 50.594264503179069],
              [-4.826455885924686, 50.594400852131201],
              [-4.826189742602666, 50.594062806980361],
              [-4.825268597935371, 50.594066281074639],
              [-4.823556219878905, 50.593009547517944],
              [-4.822937761186735, 50.593489589898361],
              [-4.824901475530697, 50.594755212746001],
              [-4.825194028998284, 50.595652107607613],
              [-4.824408211711519, 50.59503703303902],
              [-4.82435522309193, 50.595379228658032],
              [-4.823287715244398, 50.594826750672958],
              [-4.822996877437048, 50.595397790319005],
              [-4.822585262646831, 50.595226074445804],
              [-4.822228346954597, 50.595543257109775],
              [-4.821048061487891, 50.595656423017516],
              [-4.821223769256246, 50.594341596076795],
              [-4.818385866940798, 50.595565304338514],
              [-4.818200452697839, 50.59518211063785],
              [-4.817518871553771, 50.595221998713058],
              [-4.817471075340637, 50.596077677887358],
              [-4.815462424367545, 50.595832147736303],
              [-4.816070022848035, 50.596280689558924],
              [-4.814995949700365, 50.596421806838684],
              [-4.815134160118312, 50.596606458885951],
              [-4.812594019116402, 50.595908693202048],
              [-4.812259106198211, 50.596311664607434],
              [-4.810531132204121, 50.596246362571819],
              [-4.808660651458033, 50.597110054738692],
              [-4.807492323763602, 50.597116653452268],
              [-4.80678684669121, 50.596708206554943],
              [-4.806633216210251, 50.597284889655775],
              [-4.804882787095097, 50.596652463591283],
              [-4.80405095142279, 50.597017912383812],
              [-4.803140106965158, 50.596910324705036],
              [-4.803427639554352, 50.597539342435851],
              [-4.802561740044476, 50.597308336369721],
              [-4.802726152082877, 50.59762549709518],
              [-4.802057176840582, 50.59754535783096],
              [-4.801549471915628, 50.597824728910986],
              [-4.799991460566944, 50.597171407193208],
              [-4.799871814728775, 50.597607841532344],
              [-4.798163666394161, 50.597543677554157],
              [-4.795917511889719, 50.598225500399963],
              [-4.796388743036348, 50.599093884760542],
              [-4.794942183630993, 50.599056669446099],
              [-4.792853327097832, 50.600773569056997],
              [-4.79286642009879, 50.601400201318121],
              [-4.793527810584119, 50.601733333216671],
              [-4.79243924381734, 50.602205601620405],
              [-4.791431859147167, 50.602235161557111],
              [-4.790545438117312, 50.602374249377398],
              [-4.789562820787707, 50.602129753722053],
              [-4.788271914899435, 50.60250339010048],
              [-4.788304546959841, 50.602959550268402],
              [-4.789884625617819, 50.603794196777692],
              [-4.790127591357174, 50.604579926032514],
              [-4.788271934538296, 50.604845666053635],
              [-4.78825054084179, 50.605439842927964],
              [-4.787416200541985, 50.605217862207695],
              [-4.787407249632637, 50.605711897313675],
              [-4.786039518920941, 50.606007292767195],
              [-4.785604161576845, 50.60672471193044],
              [-4.784773704936693, 50.606951465194037],
              [-4.785144032198619, 50.60752547515758],
              [-4.784736400920536, 50.607635970604825],
              [-4.785286599643722, 50.607880960752013],
              [-4.784160004604656, 50.608287497135727],
              [-4.78522435994418, 50.608382566834464],
              [-4.784518884382533, 50.608739452096415],
              [-4.785166015113523, 50.608974028712318],
              [-4.784789415408709, 50.60913145474666],
              [-4.78389576368801, 50.608695889181419],
              [-4.783954088460006, 50.609037200158816],
              [-4.783017858509883, 50.60881402644182],
              [-4.782663362815164, 50.609154413092682],
              [-4.784032107194531, 50.609783710248394],
              [-4.782760627971693, 50.609556858535967],
              [-4.782126026799665, 50.609869758593092],
              [-4.782118759517235, 50.611015882535945],
              [-4.78330867716051, 50.611632371893144],
              [-4.782760083227855, 50.611534850240197],
              [-4.783127334760467, 50.611793218915757],
              [-4.782348724807086, 50.612181521997321],
              [-4.782084856183555, 50.611878404113718],
              [-4.780968408937919, 50.613033940680943],
              [-4.780437981154756, 50.612980948489437],
              [-4.780474490595046, 50.612401704713385],
              [-4.780197629598923, 50.612453291829951],
              [-4.779910466120506, 50.613983884777682],
              [-4.777580548236585, 50.614029606445527],
              [-4.778219089362995, 50.614189769218648],
              [-4.77792924754817, 50.614524999623619],
              [-4.778785477048028, 50.614972298298419],
              [-4.778355629462019, 50.615618496833406],
              [-4.777859710559262, 50.615599749137296],
              [-4.776183659185218, 50.616954783892318],
              [-4.776774016850416, 50.617544260339891],
              [-4.775228898424435, 50.617883333522911],
              [-4.775428958108609, 50.618255449542424],
              [-4.774990413068357, 50.618755225589304],
              [-4.774279492819828, 50.618663331794814],
              [-4.773889961920188, 50.619611679323945],
              [-4.774192216204738, 50.620086602320299],
              [-4.773604288274378, 50.619778594709878],
              [-4.773328219476806, 50.620084701131212],
              [-4.773615961654129, 50.620361184545125],
              [-4.773124931807361, 50.620786646616978],
              [-4.773337329320852, 50.621320379893206],
              [-4.772479614272247, 50.621281443600054],
              [-4.770394105885367, 50.622387988917232],
              [-4.771134754113048, 50.622960424662267],
              [-4.770803924462881, 50.622961103171122],
              [-4.770715744283759, 50.623745755361639],
              [-4.771118564382959, 50.62465634259496],
              [-4.770278640441703, 50.625185441624616],
              [-4.770521566604877, 50.625445009297337],
              [-4.770133926592955, 50.625994824923325],
              [-4.7710630540012, 50.626453039056905],
              [-4.772043186369981, 50.627993912635155],
              [-4.773013843731674, 50.628363872474061],
              [-4.770506563910983, 50.629806075543883],
              [-4.770647275561581, 50.630443160958293],
              [-4.770882697297268, 50.630262158401109],
              [-4.771287025815256, 50.630452220567477],
              [-4.771377313963235, 50.63140982288094],
              [-4.77086724983574, 50.631802445862554],
              [-4.77128835714954, 50.632037083048886],
              [-4.770491967533059, 50.632539060749636],
              [-4.770862937662084, 50.633243524571483],
              [-4.770366709996101, 50.633102420863565],
              [-4.770503784597475, 50.6323552844745],
              [-4.770047942479454, 50.633189160255348],
              [-4.770017061245238, 50.632976716924439],
              [-4.769016809350999, 50.633595970013346],
              [-4.769111163891074, 50.634503105491895],
              [-4.767897930834777, 50.635253341719391],
              [-4.768602738381336, 50.638027685381708],
              [-4.767965355461254, 50.639480216286877],
              [-4.768193574499621, 50.639801303381219],
              [-4.76626259820686, 50.640303235286929],
              [-4.766927127047616, 50.640418769809173],
              [-4.766977293992488, 50.641007639212241],
              [-4.767521184541054, 50.641597366969563],
              [-4.767894139368488, 50.64156511494614],
              [-4.767690756221383, 50.642292235407844],
              [-4.76662803843705, 50.643248457696714],
              [-4.765795171397913, 50.64347062848325],
              [-4.765031980963219, 50.642928376576457],
              [-4.763806646174988, 50.642657940852224],
              [-4.763314407086267, 50.64318862600485],
              [-4.76340963631011, 50.644015689485293],
              [-4.761716386799097, 50.644257347941306],
              [-4.761458921089045, 50.644522505509116],
              [-4.762064103485234, 50.644734820789047],
              [-4.761701833480903, 50.644682396575753],
              [-4.761737341624522, 50.64507268189692],
              [-4.761218681428478, 50.645853141010846],
              [-4.761581999189017, 50.645704205319923],
              [-4.761844281687051, 50.645930950396163],
              [-4.761070591543858, 50.646198456159141],
              [-4.760850270627967, 50.645963518555924],
              [-4.760243307290749, 50.646854904128169],
              [-4.760247495711692, 50.647939580543685],
              [-4.760757335908388, 50.648431203475056],
              [-4.760296607983141, 50.648825305779809],
              [-4.761199951130914, 50.649440721210844],
              [-4.760672257994093, 50.649489212754936],
              [-4.759723403021646, 50.65123421310394],
              [-4.759931703759057, 50.651456844985432],
              [-4.759334249232956, 50.651691377491765],
              [-4.758680183782285, 50.652842920264035],
              [-4.760402194520681, 50.653447938739525],
              [-4.760327056274822, 50.654000202678446],
              [-4.761423785385801, 50.653665677042547],
              [-4.762376721592478, 50.653991174009064],
              [-4.762187452696649, 50.654985093816229],
              [-4.764413087758101, 50.654406081038445],
              [-4.764269153692354, 50.654798074246244],
              [-4.766026081905613, 50.654646616396249],
              [-4.766647064115761, 50.654860332372436],
              [-4.766468300361176, 50.655959249532508],
              [-4.7669315468323, 50.656425868022033],
              [-4.765591370937592, 50.657241153883099],
              [-4.764734013934804, 50.657339767837435],
              [-4.764971607576866, 50.658060008333493],
              [-4.764102687103289, 50.658853291851592],
              [-4.76502661684001, 50.659116492650512],
              [-4.765710540806683, 50.658979720450496],
              [-4.766106994294561, 50.659681793822479],
              [-4.765857979043784, 50.659899086859035],
              [-4.765621942915528, 50.659711304347759],
              [-4.76568069830466, 50.660181237288455],
              [-4.764783448642783, 50.66099858478993],
              [-4.763642919394091, 50.66143312305595],
              [-4.762842416985023, 50.663344626110622],
              [-4.761779720154939, 50.664069629968736],
              [-4.761954615027092, 50.664348818409557],
              [-4.760619035434905, 50.665127055999321],
              [-4.760365808476412, 50.666575824856302],
              [-4.75889467008266, 50.667360852756225],
              [-4.759575127888596, 50.667645156531229],
              [-4.760196678779834, 50.667480213158875],
              [-4.760130023437176, 50.667694969733347],
              [-4.761734056592323, 50.667148734801287],
              [-4.763461602483066, 50.667187806764204],
              [-4.764751318064067, 50.668879699945826],
              [-4.764345272304263, 50.669869772632389],
              [-4.763678012915944, 50.670052013359971],
              [-4.763431563903437, 50.669856378119107],
              [-4.762533277234279, 50.670828440622948],
              [-4.761113645179069, 50.670587766271758],
              [-4.760194065417605, 50.669558073460884],
              [-4.759633594675589, 50.669630721744369],
              [-4.759232254715483, 50.668049051998345],
              [-4.757884401599674, 50.669030829792007],
              [-4.758639156110296, 50.66930258587233],
              [-4.757360996477919, 50.669711536845263],
              [-4.757598079765988, 50.669844442970899],
              [-4.757027983651406, 50.670115191976123],
              [-4.75777068230676, 50.670012156312701],
              [-4.757954969844775, 50.670644623451885],
              [-4.757768617652501, 50.671714919271139],
              [-4.757014808508437, 50.672015203602214],
              [-4.755622142587493, 50.671993296638142],
              [-4.755226572525984, 50.67164376385162],
              [-4.755583971026755, 50.6711244038761],
              [-4.753438606203858, 50.671397317947793],
              [-4.753833594601793, 50.671011996507254],
              [-4.753307910156964, 50.670688020970559],
              [-4.751089127645461, 50.671568875762624],
              [-4.750662468286286, 50.671074346693757],
              [-4.750885912351968, 50.67162764479027],
              [-4.750544000074631, 50.671081642154206],
              [-4.750441621623845, 50.671702900982737],
              [-4.749888991013387, 50.671377747585808],
              [-4.74974728371512, 50.671980145837786],
              [-4.748923944909772, 50.67196360385433],
              [-4.747173074054771, 50.671019091129885],
              [-4.746440484697517, 50.671077746021673],
              [-4.745271434930719, 50.672194576693137],
              [-4.744022751702649, 50.672481255235866],
              [-4.743638737989107, 50.673250360624856],
              [-4.743994500907922, 50.673598171663983],
              [-4.745441303183138, 50.673698095917608],
              [-4.745808521313728, 50.674290289217765],
              [-4.746708540365327, 50.674673830702133],
              [-4.746671307477401, 50.675030002252846],
              [-4.744032017781151, 50.676424342763518],
              [-4.743338865244455, 50.676408291149038],
              [-4.741882902199857, 50.675254369714438],
              [-4.742811261039919, 50.676293891712348],
              [-4.742133254182783, 50.676731711705855],
              [-4.74001630291214, 50.676331798034475],
              [-4.740645371520917, 50.675831279303708],
              [-4.740696997229703, 50.675162653167156],
              [-4.741101179811153, 50.67483562578812],
              [-4.741458138205644, 50.674961245687221],
              [-4.741267981916506, 50.674685984593665],
              [-4.741713623457585, 50.674487503734845],
              [-4.741532680988013, 50.674127475371506],
              [-4.74023639149678, 50.673938510908954],
              [-4.739715775325673, 50.674210707976634],
              [-4.738925501320765, 50.673838923583908],
              [-4.739049818403999, 50.6731299111228],
              [-4.738186103668339, 50.672104131011224],
              [-4.738285980698619, 50.671388498072858],
              [-4.737684237859547, 50.671628413662447],
              [-4.737398398235475, 50.672310639191458],
              [-4.736936256007192, 50.672030970195657],
              [-4.735809049128903, 50.67216809080449],
              [-4.734986523486935, 50.671508305990692],
              [-4.732980193199669, 50.672495357850124],
              [-4.733049581065679, 50.673616274613494],
              [-4.732636321696504, 50.673787877509717],
              [-4.732145712648125, 50.672944886701799],
              [-4.731506335723431, 50.67497470833743],
              [-4.730916388637704, 50.675004733930756],
              [-4.730290375697956, 50.674535494144266],
              [-4.729795472291939, 50.673617938049837],
              [-4.73045735042769, 50.675412165219136],
              [-4.730067776894129, 50.675382620628795],
              [-4.729976254267705, 50.675854291695572],
              [-4.728946670151905, 50.67645588365648],
              [-4.7281299273995, 50.675578242651497],
              [-4.72888054412098, 50.676658015826483],
              [-4.728554936763243, 50.677852950464455],
              [-4.728936112604632, 50.678249682436743],
              [-4.728289610248402, 50.679330705500107],
              [-4.726954645741072, 50.679818902601376],
              [-4.727850578159807, 50.680254854459768],
              [-4.726853694437128, 50.680885343321201],
              [-4.727987672569696, 50.681568472698061],
              [-4.726101146865519, 50.682409577782465],
              [-4.725935607313597, 50.682802024666408],
              [-4.726416463129516, 50.682817753593064],
              [-4.726072040899522, 50.684080580382712],
              [-4.724662437351814, 50.684309649536964],
              [-4.723300558585994, 50.684018590015349],
              [-4.723743884880605, 50.68475478558323],
              [-4.723091000741039, 50.685264761558294],
              [-4.722179072839098, 50.685286978146898],
              [-4.721766884571585, 50.684648091642728],
              [-4.721298701007059, 50.68497384669157],
              [-4.719976391415028, 50.684608067083552],
              [-4.720768298651597, 50.684053485512088],
              [-4.719262796386881, 50.684142612659201],
              [-4.718792465861469, 50.683648089374309],
              [-4.717992931252676, 50.683801671012596],
              [-4.717547049190366, 50.683460381925023],
              [-4.716871418565634, 50.683700112497306],
              [-4.716347220404533, 50.683130380982689],
              [-4.716184026343432, 50.683785404282894],
              [-4.715673600298335, 50.68372354998089],
              [-4.714767125674751, 50.684158438838921],
              [-4.715103111884266, 50.684533781797469],
              [-4.713298781145907, 50.685431225817815],
              [-4.712700126124179, 50.685239191486318],
              [-4.712113574573449, 50.68547771873704],
              [-4.711904421938065, 50.685214546492858],
              [-4.710630928912756, 50.685424085151674],
              [-4.709378040846133, 50.68478762838842],
              [-4.710502333397177, 50.685723003607585],
              [-4.709994540797278, 50.686100004533877],
              [-4.709038744498056, 50.685901868988218],
              [-4.708166906795642, 50.68615330748154],
              [-4.707022249438062, 50.685847112814983],
              [-4.70604814816606, 50.686068531165368],
              [-4.705397475842156, 50.685784123116363],
              [-4.705017462397478, 50.686660037929911],
              [-4.702458246572183, 50.687632406533957],
              [-4.703565007601948, 50.687599526427924],
              [-4.703981760975255, 50.687878581811304],
              [-4.703643724147839, 50.688598808393685],
              [-4.706069776334497, 50.689223367762906],
              [-4.705371666885775, 50.690624767187984],
              [-4.702416998970752, 50.690658277156196],
              [-4.701606555442803, 50.689344065116742],
              [-4.701541614092076, 50.690677684335178],
              [-4.700276841537486, 50.690993043037153],
              [-4.699112427219532, 50.691995954300658],
              [-4.698051633934123, 50.69186942012616],
              [-4.69857533769863, 50.691742156277932],
              [-4.698312192283121, 50.691524285011383],
              [-4.695457549851109, 50.690562288408401],
              [-4.698919709454155, 50.692388090575555],
              [-4.700224961060615, 50.692258898027156],
              [-4.701139568637371, 50.691590974255398],
              [-4.701419593757192, 50.691806648813099],
              [-4.701496266547436, 50.692573915963443],
              [-4.699967249932737, 50.692899896445411],
              [-4.698447488687164, 50.694398548078858],
              [-4.696489896074938, 50.694487955894203],
              [-4.695210523737507, 50.693440004101937],
              [-4.694161690093813, 50.69493039925883],
              [-4.692940631403874, 50.694528690193998],
              [-4.690723164103548, 50.695273423378467],
              [-4.690308555224831, 50.694512157389788],
              [-4.689556215702479, 50.695470372977148],
              [-4.686378554105293, 50.695869307692782],
              [-4.685326576141142, 50.695724464569906],
              [-4.684441832632179, 50.694887666980371],
              [-4.682552158777078, 50.695671460742695],
              [-4.680249846097179, 50.696024880410725],
              [-4.680087484161205, 50.696505332484236],
              [-4.680544992567817, 50.696779935440979],
              [-4.680055042733099, 50.696929726741629],
              [-4.68458105752123, 50.697324707865533],
              [-4.684303311898924, 50.697916660466156],
              [-4.685015796022205, 50.698241136937092],
              [-4.684977766693838, 50.699360048631931],
              [-4.685373975743708, 50.699601866173495],
              [-4.683810468056978, 50.700445618827061],
              [-4.684308831727527, 50.700864079829394],
              [-4.683973927250744, 50.701397983647894],
              [-4.682782277742876, 50.7021575981693],
              [-4.682102375808774, 50.702181349816051],
              [-4.681044226855431, 50.704437278592536],
              [-4.67982227903565, 50.704866556260377],
              [-4.679404234960638, 50.705459024789221],
              [-4.676514129040643, 50.706194664332109],
              [-4.676164843109629, 50.706577764997888],
              [-4.675525806378295, 50.706474614112949],
              [-4.672979590743378, 50.706982797317082],
              [-4.672026673555089, 50.706516250482387],
              [-4.671987588077721, 50.707099099057139],
              [-4.671336310973338, 50.706407058236181],
              [-4.671432369970902, 50.70707495474349],
              [-4.670951797304427, 50.707167825331965],
              [-4.670431201085076, 50.706083316150668],
              [-4.669938241010386, 50.70665587983067],
              [-4.668125044687685, 50.706703719143327],
              [-4.667395868332311, 50.70730776609814],
              [-4.667707659219134, 50.708058872546303],
              [-4.66684863830058, 50.708721655703656],
              [-4.665538070914752, 50.708941436657732],
              [-4.665986817269265, 50.709684916343946],
              [-4.666637715668295, 50.70962234736934],
              [-4.667193176602381, 50.709949627613447],
              [-4.667425755543656, 50.71097688382153],
              [-4.666104938416786, 50.711715894215473],
              [-4.660627700879962, 50.712630840556066],
              [-4.660833167393171, 50.713406879030039],
              [-4.659896564129232, 50.713353612071487],
              [-4.658780549064343, 50.713909768564385],
              [-4.66002630903336, 50.714343653126036],
              [-4.659917530686682, 50.71477427953846],
              [-4.65624257581149, 50.715049712254036],
              [-4.655163706824938, 50.715387317352729],
              [-4.654568311357712, 50.716232884643688],
              [-4.65511690602374, 50.716589161308477],
              [-4.654669332400062, 50.716791844905295],
              [-4.65444175394637, 50.720008995912053],
              [-4.653023472389962, 50.720861606832003],
              [-4.652925396399143, 50.721558221499642],
              [-4.653572454658059, 50.722151519197247],
              [-4.653535920232533, 50.722758585765305],
              [-4.654503280405994, 50.722801310400712],
              [-4.655849192668871, 50.723499198251716],
              [-4.654083499507204, 50.723396420867495],
              [-4.652816764155937, 50.724149333776715],
              [-4.652893555761725, 50.724630593847166],
              [-4.653421941273721, 50.72467792556526],
              [-4.652094038422696, 50.724925828810171],
              [-4.649848450626709, 50.726790228052103],
              [-4.649312888577985, 50.72890083904759],
              [-4.650126474534517, 50.730610192066834],
              [-4.649780527796152, 50.732565382387676],
              [-4.650775150122797, 50.733413431999629],
              [-4.651649149692547, 50.735411924126652],
              [-4.652188234669885, 50.7357216598989],
              [-4.651909799821194, 50.736663431271189],
              [-4.650723254459578, 50.73688561217493],
              [-4.650377078416554, 50.737532100224335],
              [-4.651767128650683, 50.738377442778308],
              [-4.652062794778655, 50.738191722004679],
              [-4.653938717131028, 50.738529479724725],
              [-4.653860419833567, 50.739851663952052],
              [-4.652336283557875, 50.739719967969819],
              [-4.652194583609415, 50.739169128824692],
              [-4.65133495460268, 50.739458528660563],
              [-4.646984071292268, 50.739445915110196],
              [-4.646671356605685, 50.738978106484055],
              [-4.646352915349404, 50.739187716420311],
              [-4.646195133865954, 50.738853104812684],
              [-4.644207200394926, 50.738337873694249],
              [-4.641508279680255, 50.73813374999947],
              [-4.640609115209527, 50.738325926105595],
              [-4.637642538133909, 50.739941076187073],
              [-4.637309459807879, 50.739840680989381],
              [-4.637938734973962, 50.740469545686061],
              [-4.637484681103861, 50.740839605263844],
              [-4.634539266617653, 50.74096829907019],
              [-4.633962446183622, 50.741500323358053],
              [-4.633927327511464, 50.742414960875941],
              [-4.635651652982334, 50.74333388849184],
              [-4.638284235974128, 50.744067630679041],
              [-4.639725733542117, 50.745726855542877],
              [-4.636096167268051, 50.745871082629378],
              [-4.636013594219885, 50.746394632867506],
              [-4.636853321012045, 50.746781287153333],
              [-4.634216855746625, 50.746485632772924],
              [-4.634996445016515, 50.747316190154962],
              [-4.6338805808211, 50.747263164821796],
              [-4.636110257332223, 50.74826600491528],
              [-4.634340436211512, 50.748080265563509],
              [-4.634616920819886, 50.749090395316891],
              [-4.633752739562151, 50.749101835153361],
              [-4.634043277503532, 50.749352511380899],
              [-4.632557613036427, 50.74983490981797],
              [-4.633130708504274, 50.74994158864083],
              [-4.628777008808965, 50.75035109287932],
              [-4.624358272718534, 50.749929007647509],
              [-4.621328831420828, 50.751101716900081],
              [-4.61990119241637, 50.75133170073137],
              [-4.617767778237893, 50.753091295780663],
              [-4.618591074520622, 50.75407298593489],
              [-4.617273120365732, 50.755152249694142],
              [-4.617343676122841, 50.756086091400512],
              [-4.619242815628477, 50.756501241970398],
              [-4.61503444653542, 50.757368376810348],
              [-4.614861815904426, 50.757756314584967],
              [-4.615357304955253, 50.758301054661636],
              [-4.616439729311396, 50.758258769086247],
              [-4.615046402040461, 50.758472630408704],
              [-4.614958992927503, 50.759244518948236],
              [-4.613831363229389, 50.760307777793066],
              [-4.610428545718094, 50.762202728986054],
              [-4.606051845915816, 50.763293667966465],
              [-4.603629758341741, 50.764368673771301],
              [-4.599169348965555, 50.764493418658155],
              [-4.596632134518887, 50.764927741916615],
              [-4.593877259456672, 50.765914615284963],
              [-4.591696793084468, 50.765928944574561],
              [-4.59022316145075, 50.766261219451657],
              [-4.587673401311572, 50.768809315168731],
              [-4.58448369683172, 50.770071850837773],
              [-4.583689853973835, 50.77108333447768],
              [-4.584131001062844, 50.771624012755588],
              [-4.583506390444426, 50.77176917804541],
              [-4.582569006002316, 50.771283566798367],
              [-4.581129211342679, 50.771719310050457],
              [-4.577523897525354, 50.771595822434712],
              [-4.576428654656805, 50.7718754793176],
              [-4.574975121200817, 50.772862806474755],
              [-4.572913909463011, 50.77663110659558],
              [-4.571990752651441, 50.777024748239242],
              [-4.567595775374035, 50.777564169718715],
              [-4.566945532832038, 50.777968848017302],
              [-4.566510923405236, 50.77920255407188],
              [-4.565910931489388, 50.779230154655636],
              [-4.564969306385564, 50.78035089209957],
              [-4.563257961841303, 50.78073212351778],
              [-4.562357645569636, 50.781416602833488],
              [-4.561326479311011, 50.783782299832929],
              [-4.560262528670532, 50.784485647948976],
              [-4.558466966065816, 50.788264028795425],
              [-4.557176603970135, 50.795114560276602],
              [-4.557510586732464, 50.795617213144972],
              [-4.55710212979826, 50.795673843573866],
              [-4.556712803726297, 50.7966007058372],
              [-4.557111785183466, 50.797747728317745],
              [-4.556908571243389, 50.798626436038575],
              [-4.557708716966325, 50.799400385895304],
              [-4.559594375432511, 50.799832101947516],
              [-4.559741026404373, 50.800230029848422],
              [-4.560743169711126, 50.80044007533256],
              [-4.560278506940279, 50.801026818779938],
              [-4.558343899247768, 50.801388589388949],
              [-4.55829135061254, 50.802452873871211],
              [-4.55899049673488, 50.803247020176208],
              [-4.559414028483352, 50.803205343083221],
              [-4.558443191221592, 50.803517170050384],
              [-4.558309528625191, 50.804317901265456],
              [-4.560135526754967, 50.80518624507701],
              [-4.559126021443527, 50.805258777655403],
              [-4.559813308576839, 50.805731182074048],
              [-4.559671693455089, 50.806152528233447],
              [-4.557389234293308, 50.807478015770762],
              [-4.557238082719286, 50.808011097598865],
              [-4.557659337636833, 50.808082805793525],
              [-4.556932663387792, 50.808240855903485],
              [-4.556820823004885, 50.808584193927807],
              [-4.556818020713659, 50.809702250806531],
              [-4.557657065852744, 50.810612961381985],
              [-4.556835090271897, 50.811391911047814],
              [-4.556754042597539, 50.812195083088561],
              [-4.557823320515294, 50.812408180029024],
              [-4.557171726007666, 50.812538500306175],
              [-4.556656647085465, 50.813166807358272],
              [-4.556484639419978, 50.814176144740223],
              [-4.556950614496563, 50.815506980130962],
              [-4.557588157027101, 50.815975991720713],
              [-4.556828445537754, 50.816103284685163],
              [-4.556192841800096, 50.817255003245712],
              [-4.556427075066696, 50.817748153015664],
              [-4.556988777695165, 50.81789413578587],
              [-4.556544061046797, 50.818537987511164],
              [-4.55675115922855, 50.8194697549336],
              [-4.557419754699691, 50.819700636594824],
              [-4.556853033508351, 50.819749043105901],
              [-4.557203257516799, 50.81989606466248],
              [-4.557217110769241, 50.821058726155663],
              [-4.558111254082995, 50.821596759824608],
              [-4.556738768533306, 50.821919179200812],
              [-4.556561047650209, 50.822616537821951],
              [-4.557643296569959, 50.823737779278552],
              [-4.558161482160719, 50.823813656300864],
              [-4.557857268491654, 50.823963339950168],
              [-4.558640335541534, 50.824987700596729],
              [-4.557979046856082, 50.825177599086587],
              [-4.55735217818943, 50.826620545867755],
              [-4.556685293035078, 50.827072293907875],
              [-4.556954799290213, 50.828001792319789],
              [-4.556644646935871, 50.828199272546691],
              [-4.558632006093731, 50.828729517231061],
              [-4.557034209886609, 50.828778957973057],
              [-4.557065867348221, 50.829046294096088],
              [-4.557733822174844, 50.829081112802427],
              [-4.556614010376812, 50.829249579911021],
              [-4.55595217139846, 50.829716502938894],
              [-4.556442732840745, 50.830922678199514],
              [-4.557451426215545, 50.830851090472045],
              [-4.556798211911303, 50.831008424870362],
              [-4.556587435615129, 50.831439376398023],
              [-4.55589572713619, 50.831178415125528],
              [-4.556133617941573, 50.831035587630879],
              [-4.555689486800534, 50.831094791429884],
              [-4.555966747096807, 50.830918721892068],
              [-4.555208459525147, 50.830036810234859],
              [-4.552497449004801, 50.82938833534061],
              [-4.551648883235126, 50.82958319599679],
              [-4.549834699225183, 50.828868260397968],
              [-4.5478407195129, 50.829778896937391],
              [-4.546677808082784, 50.82991313214184],
              [-4.548465346580289, 50.829904658465502],
              [-4.550090791305117, 50.829345653742479],
              [-4.55207715209624, 50.829884126669675],
              [-4.552211364451401, 50.831823058076615],
              [-4.553728137405508, 50.832186498244859],
              [-4.553759162150962, 50.832884675750115],
              [-4.554366577541371, 50.833006285805617],
              [-4.554043810625987, 50.833207632699093],
              [-4.554431464678379, 50.834558179568006],
              [-4.553924391748842, 50.835233964955471],
              [-4.55400415107043, 50.836252171058767],
              [-4.554830743110534, 50.836744939809655],
              [-4.55610831255813, 50.836808683959404],
              [-4.555590084439448, 50.837228383506186],
              [-4.555888398242204, 50.837436809009354],
              [-4.555606014031289, 50.838351421749053],
              [-4.555738542424674, 50.839060865250232],
              [-4.556231702144001, 50.839143596409791],
              [-4.555773705136286, 50.839236382732231],
              [-4.556638238548265, 50.839304676190807],
              [-4.556009152723145, 50.840996801695653],
              [-4.556685299636288, 50.841022453755087],
              [-4.556069743021026, 50.841325564086162],
              [-4.556725072373893, 50.841438017448532],
              [-4.556350857539425, 50.84150468432918],
              [-4.556575108279383, 50.842488240694237],
              [-4.557313002354073, 50.842837229436142],
              [-4.556878858984869, 50.842977166435588],
              [-4.557687861710299, 50.84296392266419],
              [-4.556256666167775, 50.843471101906239],
              [-4.556510477818271, 50.843800125482453],
              [-4.557448620960144, 50.843810135753223],
              [-4.55627856714631, 50.844053451672451],
              [-4.556966816014967, 50.844377447499554],
              [-4.555608001003846, 50.844728329524671],
              [-4.555298000766994, 50.848672819650751],
              [-4.555403702374457, 50.848943030224056],
              [-4.555862198832031, 50.848909597494526],
              [-4.555648506485209, 50.849366692918011],
              [-4.556270291002344, 50.849954782645916],
              [-4.558107029916989, 50.850857108445901],
              [-4.556753480394145, 50.851255556367704],
              [-4.556165891557074, 50.852695828700313],
              [-4.556542430136461, 50.853397223465052],
              [-4.556178326118497, 50.855312890148895],
              [-4.556455919578434, 50.860957905486998],
              [-4.55703246739779, 50.862975272379266],
              [-4.557825688796161, 50.863056816026329],
              [-4.557181628324278, 50.863233735192473],
              [-4.557044036683598, 50.863628002340128],
              [-4.557208749001885, 50.866299284806104],
              [-4.557593784940653, 50.866426655618689],
              [-4.55731666609544, 50.866972387662898],
              [-4.557589037138928, 50.867897321325124],
              [-4.558439334150527, 50.86852625968281],
              [-4.558306431374657, 50.86923972134484],
              [-4.55906390270974, 50.869730380819774],
              [-4.558655715569879, 50.869725844479468],
              [-4.558426267301971, 50.87127159394246],
              [-4.55966773376361, 50.871359482743415],
              [-4.55899376399124, 50.871900435264941],
              [-4.558769176222706, 50.873975839870859],
              [-4.558012050400806, 50.875127521162682],
              [-4.558899369140412, 50.875962512433759],
              [-4.560665509401479, 50.875687204718275],
              [-4.561349176857497, 50.876307188473497],
              [-4.562258027032459, 50.876367276462908],
              [-4.561485421303684, 50.876536248384994],
              [-4.560640535065027, 50.877694370830547],
              [-4.561004833945066, 50.878011964885978],
              [-4.560572968695892, 50.877991764941441],
              [-4.559720267142147, 50.878748908177947],
              [-4.559663334947883, 50.879707145550988],
              [-4.560260070679993, 50.880426181143008],
              [-4.561395130520843, 50.880772724820758],
              [-4.561114040621938, 50.881402198213678],
              [-4.563048885071178, 50.882538846774025],
              [-4.564461893778402, 50.884216698450693],
              [-4.565748453607498, 50.885014978816521],
              [-4.567383791451396, 50.885328874098569],
              [-4.566833961027273, 50.885569428116938],
              [-4.568079781858782, 50.885548303809003],
              [-4.566718163279305, 50.885713185861412],
              [-4.568101522576863, 50.885736704485993],
              [-4.566416767576206, 50.885972555638887],
              [-4.566613072589834, 50.886542969157894],
              [-4.562739704086188, 50.887809971713367],
              [-4.56280953654127, 50.888667387635913],
              [-4.562043773606772, 50.889327296626192],
              [-4.561906459721638, 50.890012075628128],
              [-4.562764388690299, 50.890667799037175],
              [-4.561872845087821, 50.890617226633232],
              [-4.56130406146343, 50.891913193793613],
              [-4.561532990781376, 50.892456815540889],
              [-4.563475322836297, 50.893488061795431],
              [-4.564215051616492, 50.894372127483379],
              [-4.565206098813364, 50.89442049455149],
              [-4.564850355733614, 50.894649574689133],
              [-4.565220331620436, 50.894808732636669],
              [-4.5641538244661, 50.894940110125155],
              [-4.565543164515939, 50.894912262501222],
              [-4.563954809318575, 50.89504521972804],
              [-4.563385116946194, 50.895780875542947],
              [-4.563658191689925, 50.896480022778121],
              [-4.564860945231742, 50.897093075582568],
              [-4.56434226904202, 50.897566781423784],
              [-4.565127566206208, 50.897751887310456],
              [-4.564400897802744, 50.897779555412392],
              [-4.565683093656327, 50.898801890107258],
              [-4.564886578344614, 50.898878766622957],
              [-4.564916465000382, 50.89965521111057],
              [-4.563968091973843, 50.899997151886019],
              [-4.564101733499846, 50.901031249232325],
              [-4.565626094490691, 50.902110322250991],
              [-4.565377229445237, 50.902318165009731],
              [-4.566425719096313, 50.90247678823696],
              [-4.566324532376015, 50.902731751953965],
              [-4.567308837262323, 50.902654331517653],
              [-4.566971288680768, 50.902827252868569],
              [-4.567476929030769, 50.903253245833199],
              [-4.569813264845784, 50.903705483459674],
              [-4.569211601661964, 50.904222411750219],
              [-4.568519378459535, 50.904257441023496],
              [-4.568662670259297, 50.904612256596266],
              [-4.566585731017543, 50.904712830955248],
              [-4.565662793303703, 50.905053325623228],
              [-4.565065838576715, 50.906898575089095],
              [-4.565499577573759, 50.907206534221181],
              [-4.566664002006064, 50.907169230810204],
              [-4.565533391757381, 50.907433344475855],
              [-4.566618618570433, 50.907637029410537],
              [-4.564902195189818, 50.90775122727684],
              [-4.563911550792335, 50.908155255963891],
              [-4.563481969901481, 50.908751118504554],
              [-4.56407520759159, 50.909191390999958],
              [-4.56397221288361, 50.909517446282855],
              [-4.564847158379179, 50.909440652192018],
              [-4.564634091711318, 50.909808702354177],
              [-4.565311420753698, 50.910432401074765],
              [-4.564937940474627, 50.910599809960992],
              [-4.56539309064934, 50.910727414526932],
              [-4.563960614761899, 50.911790539440126],
              [-4.564205663513348, 50.912133230198663],
              [-4.562887429000369, 50.913179439671666],
              [-4.562525179156506, 50.914043645101053],
              [-4.563133647390924, 50.914319893377666],
              [-4.562815905411542, 50.914518449765666],
              [-4.564424064576577, 50.914992187370707],
              [-4.563370910856201, 50.915116065009052],
              [-4.563209366745253, 50.915386742011307],
              [-4.563900001099214, 50.915475001171473],
              [-4.562734438962092, 50.91552129456354],
              [-4.561838792014074, 50.916828933722506],
              [-4.56215626719179, 50.917013539215034],
              [-4.560325352377956, 50.917122086188755],
              [-4.560012586837268, 50.917619132914972],
              [-4.561132749139346, 50.917755546607012],
              [-4.558223932093502, 50.917876023134269],
              [-4.558530631481551, 50.918097750895583],
              [-4.556894677312427, 50.918441213966879],
              [-4.556870056439847, 50.919548938908044],
              [-4.557287833500349, 50.920005682515182],
              [-4.558035235675544, 50.920116117506474],
              [-4.557638066640471, 50.920242648047676],
              [-4.558482708155621, 50.920361741720008],
              [-4.555476436786789, 50.921407089194176],
              [-4.554961433658154, 50.922317786094048],
              [-4.552780562373755, 50.923419637739087],
              [-4.551925757664223, 50.92420733832089],
              [-4.552154199461995, 50.925442639578179],
              [-4.555052883844686, 50.92593324522894],
              [-4.553828173687936, 50.926151632089997],
              [-4.552938755705101, 50.925892275119288],
              [-4.552496371031078, 50.92620775518126],
              [-4.553013107979672, 50.926340357398175],
              [-4.551403061862098, 50.926227168894869],
              [-4.549878097028332, 50.926680530926689],
              [-4.549060089832982, 50.927440422703114],
              [-4.54592515645975, 50.928357013600895],
              [-4.545560117067264, 50.928135626222392],
              [-4.543650991262736, 50.929088366945884],
              [-4.54303297783358, 50.928508222285657],
              [-4.540754756328999, 50.928852858278994],
              [-4.54046949031633, 50.928580250267103],
              [-4.539874760531538, 50.9286876354154],
              [-4.539600339660977, 50.928326646504722],
              [-4.537885457807453, 50.928170581897618],
              [-4.534723867239491, 50.926900970926049],
              [-4.533264602652091, 50.926812138604205],
              [-4.53303277994377, 50.927181434287199],
              [-4.531811474217454, 50.926705166622106],
              [-4.530714229389136, 50.926862082037751],
              [-4.530613508045798, 50.926658300435555],
              [-4.528510082823296, 50.926945840875426],
              [-4.528147462984529, 50.927265794668912],
              [-4.526684562153279, 50.927057336703115],
              [-4.524465865945482, 50.927319417950628],
              [-4.520031289572767, 50.927238986739326],
              [-4.517147517684476, 50.926834433984688],
              [-4.516657161191008, 50.927452114237887],
              [-4.516372696025987, 50.927323337079144],
              [-4.514323243169488, 50.927953931710235],
              [-4.51080822680677, 50.927851533199714],
              [-4.509546431014454, 50.927385798646171],
              [-4.504729027863907, 50.927675480709631],
              [-4.501846436615242, 50.927370359558118],
              [-4.49930163583149, 50.927498644048306],
              [-4.49792606088593, 50.928439200582112],
              [-4.496767468648855, 50.928702339509492],
              [-4.492414277153244, 50.928947400325306],
              [-4.492068314024356, 50.929211120768507],
              [-4.490348800285039, 50.928992397675522],
              [-4.489119682026434, 50.929321722681088],
              [-4.486782789655217, 50.929348161815085],
              [-4.481072507778156, 50.930275579820439],
              [-4.471508562695173, 50.931270006938391],
              [-4.464625512527048, 50.931023440202004],
              [-4.460050971364513, 50.930182785964867],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000053",
        LAD13CDO: "00HF",
        LAD13NM: "Isles of Scilly",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-6.399012360621021, 49.865252378680495],
              [-6.398885466998945, 49.864882865617197],
              [-6.399226170494434, 49.86507777743487],
              [-6.399364738517143, 49.864749461938459],
              [-6.400394194055311, 49.864787702979449],
              [-6.400212872763515, 49.865212129011731],
              [-6.399421617142574, 49.86526741323447],
              [-6.399012360621021, 49.865252378680495],
            ],
          ],
          [
            [
              [-6.398086502968098, 49.866398889175926],
              [-6.398191921767398, 49.866088022665295],
              [-6.398694556776066, 49.866426143066782],
              [-6.398086502968098, 49.866398889175926],
            ],
          ],
          [
            [
              [-6.385820838637859, 49.866803275219183],
              [-6.385438845933289, 49.866551393788058],
              [-6.385983794149551, 49.8667584247627],
              [-6.386113313197707, 49.866559128469575],
              [-6.386174309533034, 49.867031935354881],
              [-6.385820838637859, 49.866803275219183],
            ],
          ],
          [
            [
              [-6.397307703561721, 49.866621956957339],
              [-6.397297804276292, 49.8663748809754],
              [-6.397888527264164, 49.866395609750889],
              [-6.397816209319335, 49.866595431742475],
              [-6.397307703561721, 49.866621956957339],
            ],
          ],
          [
            [
              [-6.392639539302389, 49.86692524499496],
              [-6.392715708766869, 49.866828790702911],
              [-6.392924661470383, 49.86701427025011],
              [-6.392639539302389, 49.86692524499496],
            ],
          ],
          [
            [
              [-6.398311568509303, 49.866883451411567],
              [-6.398219342681983, 49.8666655942178],
              [-6.398417393562545, 49.866669763780223],
              [-6.398311568509303, 49.866883451411567],
            ],
          ],
          [
            [
              [-6.392236009202282, 49.867371586394491],
              [-6.392447170001491, 49.867075629086486],
              [-6.392479261203133, 49.867520735461376],
              [-6.392236009202282, 49.867371586394491],
            ],
          ],
          [
            [
              [-6.392726423189622, 49.867789405483251],
              [-6.392647521474968, 49.867671821250561],
              [-6.392770750749195, 49.867695038270426],
              [-6.392726423189622, 49.867789405483251],
            ],
          ],
          [
            [
              [-6.38697353468393, 49.867600934061358],
              [-6.387097644861136, 49.866942983686499],
              [-6.388255832962549, 49.867096080798426],
              [-6.388703877752913, 49.867476826410702],
              [-6.389037745349644, 49.86698365581033],
              [-6.389223142828142, 49.867982640745858],
              [-6.387797356115753, 49.868118603265444],
              [-6.38697353468393, 49.867600934061358],
            ],
          ],
          [
            [
              [-6.397628642716107, 49.8674259226459],
              [-6.399060822700782, 49.866962949698184],
              [-6.399155569967172, 49.867285092928725],
              [-6.398217367171454, 49.867944355032321],
              [-6.397628642716107, 49.8674259226459],
            ],
          ],
          [
            [
              [-6.393109621247829, 49.868160891926102],
              [-6.393063301834352, 49.867880998086058],
              [-6.393588917066859, 49.868241676656787],
              [-6.393109621247829, 49.868160891926102],
            ],
          ],
          [
            [
              [-6.387725529909674, 49.868492963783474],
              [-6.387978451735121, 49.868472574414021],
              [-6.388145837089902, 49.868737076046045],
              [-6.387725529909674, 49.868492963783474],
            ],
          ],
          [
            [
              [-6.399491646678584, 49.868882145422383],
              [-6.399175154947946, 49.86777566607303],
              [-6.400089942844278, 49.868557045711086],
              [-6.399491646678584, 49.868882145422383],
            ],
          ],
          [
            [
              [-6.399587004326539, 49.871138025974389],
              [-6.399133202818562, 49.869900005574628],
              [-6.399439548243117, 49.870022454609511],
              [-6.399514384694811, 49.869651586098541],
              [-6.400770521364376, 49.869120557120908],
              [-6.401726792413194, 49.869057191767702],
              [-6.401295055010126, 49.869177985024642],
              [-6.401424342320171, 49.869634715217835],
              [-6.402148385743994, 49.869574794377264],
              [-6.402780333370778, 49.870367759728111],
              [-6.400641067622868, 49.87118524338814],
              [-6.399587004326539, 49.871138025974389],
            ],
          ],
          [
            [
              [-6.401640216808189, 49.872157784093332],
              [-6.401993662908418, 49.871850082076598],
              [-6.402934464683621, 49.872044671775846],
              [-6.40212386710733, 49.872347236610793],
              [-6.401640216808189, 49.872157784093332],
            ],
          ],
          [
            [
              [-6.369385763201487, 49.874541922357864],
              [-6.36955492493094, 49.874210691416067],
              [-6.370375369029764, 49.874676420586866],
              [-6.369662560042091, 49.875739959078203],
              [-6.36900742675193, 49.87548574522804],
              [-6.369315779805663, 49.874861309568118],
              [-6.369883254343794, 49.874685107731551],
              [-6.369385763201487, 49.874541922357864],
            ],
          ],
          [
            [
              [-6.405184078217244, 49.874362516861645],
              [-6.405155071132864, 49.874120661624296],
              [-6.405373043973148, 49.874449806935246],
              [-6.405184078217244, 49.874362516861645],
            ],
          ],
          [
            [
              [-6.418063056465498, 49.877722878125873],
              [-6.418556211736409, 49.877633836609732],
              [-6.418283898647656, 49.877960068698137],
              [-6.418063056465498, 49.877722878125873],
            ],
          ],
          [
            [
              [-6.417330760556083, 49.878288025444576],
              [-6.417272138845428, 49.878042803895156],
              [-6.417707245050845, 49.878126076680061],
              [-6.417330760556083, 49.878288025444576],
            ],
          ],
          [
            [
              [-6.416827481951102, 49.87838822376613],
              [-6.416836877493377, 49.878261875903767],
              [-6.416967301292795, 49.87836398977803],
              [-6.416827481951102, 49.87838822376613],
            ],
          ],
          [
            [
              [-6.411972248961914, 49.878682331092634],
              [-6.411718467554188, 49.878388757241837],
              [-6.412054148058766, 49.878481241077331],
              [-6.411972248961914, 49.878682331092634],
            ],
          ],
          [
            [
              [-6.411097898945635, 49.878897435369375],
              [-6.411224351303011, 49.87861995992386],
              [-6.41157076163252, 49.878845214110235],
              [-6.411097898945635, 49.878897435369375],
            ],
          ],
          [
            [
              [-6.417082611085535, 49.878711430407066],
              [-6.417277023288436, 49.878431353690551],
              [-6.417782196005922, 49.87858034024088],
              [-6.417082611085535, 49.878711430407066],
            ],
          ],
          [
            [
              [-6.413357505995133, 49.879548253427679],
              [-6.413436765363755, 49.879272575394396],
              [-6.413672893159874, 49.879448938417468],
              [-6.413357505995133, 49.879548253427679],
            ],
          ],
          [
            [
              [-6.411528420150598, 49.879845663184852],
              [-6.411576976043706, 49.879187820498466],
              [-6.412424128041931, 49.879193313496735],
              [-6.41236258551024, 49.879785062872067],
              [-6.411528420150598, 49.879845663184852],
            ],
          ],
          [
            [
              [-6.412208148059456, 49.880594521606362],
              [-6.412782479960775, 49.880570818429483],
              [-6.412427193634149, 49.880767036573417],
              [-6.412208148059456, 49.880594521606362],
            ],
          ],
          [
            [
              [-6.367995659748195, 49.886397633431969],
              [-6.368108543054112, 49.886182809598658],
              [-6.368335617493207, 49.886429773444519],
              [-6.367995659748195, 49.886397633431969],
            ],
          ],
          [
            [
              [-6.36890981101079, 49.888313082275957],
              [-6.369021521899891, 49.888146893305091],
              [-6.369150452746875, 49.888402079908914],
              [-6.36890981101079, 49.888313082275957],
            ],
          ],
          [
            [
              [-6.370581272799546, 49.88905352695356],
              [-6.37048666546117, 49.888869932800972],
              [-6.370878529468526, 49.888967614068513],
              [-6.370581272799546, 49.88905352695356],
            ],
          ],
          [
            [
              [-6.369649552901791, 49.889670009333607],
              [-6.36994866244545, 49.889620021722706],
              [-6.370622684098054, 49.889603566160289],
              [-6.36995176379917, 49.889649376233848],
              [-6.370016469168857, 49.890261748037275],
              [-6.369649552901791, 49.889670009333607],
            ],
          ],
          [
            [
              [-6.348946998307738, 49.89831802968753],
              [-6.348402704394965, 49.897672602127336],
              [-6.347331510434982, 49.897920707044101],
              [-6.347654287417518, 49.897405573240682],
              [-6.346974841385796, 49.897022569605028],
              [-6.346052011801437, 49.897139112908441],
              [-6.345583070064661, 49.897779408629084],
              [-6.345551714563354, 49.897167797384533],
              [-6.346481052763282, 49.895761547738893],
              [-6.345497871460815, 49.895145187022045],
              [-6.344126481722493, 49.894997808059998],
              [-6.343405202930404, 49.895419008074157],
              [-6.342794092404316, 49.896182506309742],
              [-6.342834190259976, 49.897480364663437],
              [-6.342736298569021, 49.897243781785576],
              [-6.342252040476962, 49.897311442848149],
              [-6.342470893329484, 49.896956794635578],
              [-6.341831147696478, 49.897169762486847],
              [-6.341454335717922, 49.896340752310515],
              [-6.340933674885105, 49.896468262792872],
              [-6.340579191274744, 49.896181913071295],
              [-6.340529201799752, 49.895362238133266],
              [-6.336922349635797, 49.894131507087529],
              [-6.335877344206116, 49.894407319290494],
              [-6.336260518807148, 49.894703406067364],
              [-6.336065358366686, 49.895725731610227],
              [-6.337275151997815, 49.896586532627936],
              [-6.337962642475108, 49.896672345432968],
              [-6.338426945849101, 49.89729742348289],
              [-6.338113125627292, 49.897634003983839],
              [-6.33696968715358, 49.897342143216896],
              [-6.336065534477203, 49.897449807930904],
              [-6.335029999763088, 49.89811128559974],
              [-6.335211727695743, 49.89853729880825],
              [-6.334528956432881, 49.898767994641872],
              [-6.334766330470209, 49.898153555476853],
              [-6.333493112536547, 49.897889893380807],
              [-6.332966186919598, 49.89807158849127],
              [-6.333047571751524, 49.897628526388822],
              [-6.331807748973403, 49.897084647225441],
              [-6.331175372691679, 49.896293065465748],
              [-6.327866704861194, 49.894626172611432],
              [-6.328344903136478, 49.894397728334575],
              [-6.329394736920642, 49.894610413662349],
              [-6.329915197468264, 49.894169820801686],
              [-6.329234824527673, 49.893012892693747],
              [-6.32857387006924, 49.892802758971698],
              [-6.327815665819568, 49.893046174786974],
              [-6.326907667028481, 49.892472738774352],
              [-6.327571935556807, 49.891911601055824],
              [-6.327436184355476, 49.891266997677022],
              [-6.328951344666812, 49.8897256071754],
              [-6.328869288284786, 49.888809946492046],
              [-6.329218264308456, 49.888662847798578],
              [-6.329693105525115, 49.889175055266648],
              [-6.330725214297441, 49.889020358457785],
              [-6.331348832439688, 49.889451436339499],
              [-6.33145277448179, 49.890222303183812],
              [-6.333940682917549, 49.892151088444358],
              [-6.334356585908259, 49.893743516831528],
              [-6.335806590529159, 49.894053635532451],
              [-6.338272136276841, 49.893418638727518],
              [-6.337801396363558, 49.891697802339039],
              [-6.338919761302114, 49.891805250931846],
              [-6.339286133625392, 49.891139133657518],
              [-6.337599910526644, 49.890555364901424],
              [-6.336832108773379, 49.88993085974159],
              [-6.336700108352223, 49.888941488454869],
              [-6.335798689249326, 49.888441661815705],
              [-6.336125120633216, 49.887485504426628],
              [-6.335759696143531, 49.88664165292419],
              [-6.336018365166007, 49.88631162013403],
              [-6.337417041801761, 49.88636268729563],
              [-6.339834582875071, 49.885724952241141],
              [-6.339786988220142, 49.884776510234552],
              [-6.338089283643993, 49.884543217391183],
              [-6.339688438025067, 49.883369262925015],
              [-6.341148312310376, 49.882893384382626],
              [-6.341195248903314, 49.882346322717822],
              [-6.341514427508766, 49.883062316485073],
              [-6.342694724122706, 49.883241189614722],
              [-6.34349273951123, 49.883969802648352],
              [-6.343326817741074, 49.884928057451752],
              [-6.342204416198269, 49.884913487776863],
              [-6.341931659467245, 49.885535610588647],
              [-6.342928928488033, 49.885753743010646],
              [-6.3428476433435, 49.886367782498027],
              [-6.343750696361083, 49.886312293926345],
              [-6.343565710059073, 49.886655776159429],
              [-6.344289452019648, 49.886913857779433],
              [-6.344371022396031, 49.887495689585975],
              [-6.345264090562594, 49.887778003092002],
              [-6.34600553223374, 49.887428042957239],
              [-6.34518777818706, 49.889097329838194],
              [-6.345936989703779, 49.890009526604636],
              [-6.346733300962293, 49.890036309678415],
              [-6.347925525641795, 49.888891022455169],
              [-6.348780093958453, 49.888698741583418],
              [-6.348534473001437, 49.88832194093694],
              [-6.349025313846532, 49.888266602726652],
              [-6.349021068491387, 49.887461407487109],
              [-6.349330091617273, 49.88827944432343],
              [-6.351691915461131, 49.888276140611602],
              [-6.351971336828731, 49.887651943340543],
              [-6.352581502913082, 49.887715380355992],
              [-6.352330595527841, 49.888068555288804],
              [-6.353080870619539, 49.888311187191633],
              [-6.352901758453958, 49.889600194242512],
              [-6.353983589885241, 49.890708591544232],
              [-6.351021333365592, 49.892412714565538],
              [-6.350685336077039, 49.893491654156414],
              [-6.349520600504647, 49.893643415166608],
              [-6.349499831878309, 49.894572827941083],
              [-6.350343634696841, 49.895296075995738],
              [-6.351907349064428, 49.895536931280233],
              [-6.353461907257171, 49.895088833949671],
              [-6.354881249881305, 49.895057029712547],
              [-6.354606825850245, 49.896308232923616],
              [-6.355240750107973, 49.896973654884384],
              [-6.356032975536552, 49.897139103993801],
              [-6.35604022119306, 49.897451075253301],
              [-6.356958938137127, 49.89782951801164],
              [-6.356078281417087, 49.897826675048236],
              [-6.355214968564828, 49.89741374623172],
              [-6.353374411830762, 49.895756205786242],
              [-6.3510450653792, 49.895830305899935],
              [-6.350248457663695, 49.8955075161265],
              [-6.349271017410572, 49.896378402286402],
              [-6.35019381057632, 49.897359635972641],
              [-6.348946998307738, 49.89831802968753],
            ],
          ],
          [
            [
              [-6.37532907642848, 49.899952080523036],
              [-6.373396413428379, 49.898809424538321],
              [-6.37355640142616, 49.89946838257508],
              [-6.372902639935568, 49.899202437712894],
              [-6.373018121947586, 49.898862433145915],
              [-6.372257827837242, 49.898542920254179],
              [-6.371895975741081, 49.89683879224912],
              [-6.369539395047683, 49.896843206734609],
              [-6.369027185390014, 49.896201161341466],
              [-6.368232389774179, 49.896331046006495],
              [-6.368472055662838, 49.895392463606385],
              [-6.367895368834824, 49.895146077859188],
              [-6.367526118267566, 49.894082004009633],
              [-6.366877772167569, 49.893813122188526],
              [-6.366195452468896, 49.893938749022816],
              [-6.366327951047713, 49.893355152351944],
              [-6.365679773198191, 49.893103354594935],
              [-6.365881674020692, 49.892532437608082],
              [-6.366761954088367, 49.891946722838718],
              [-6.367311373776568, 49.891984479826768],
              [-6.368119765070365, 49.891543642245423],
              [-6.368435978084727, 49.891990625468388],
              [-6.369342482922455, 49.892003195153897],
              [-6.369176167106984, 49.892674457319401],
              [-6.369447173515558, 49.89296027331271],
              [-6.36990016317752, 49.892871181876821],
              [-6.369076432006275, 49.894805447916703],
              [-6.370108227550892, 49.89572482157071],
              [-6.370722388052785, 49.895723224583001],
              [-6.371394691681566, 49.89496356097758],
              [-6.372470843025391, 49.895156865729611],
              [-6.374020823728811, 49.894768051748855],
              [-6.375054110534458, 49.89513392265841],
              [-6.376218852749072, 49.895013397719346],
              [-6.376707520641406, 49.895517726676331],
              [-6.37775354942056, 49.895562747759129],
              [-6.377465947980983, 49.895955160173386],
              [-6.378522878494596, 49.895920575825627],
              [-6.378180940872801, 49.896405030130069],
              [-6.375963398277873, 49.896175755921284],
              [-6.37500975643314, 49.89656634909641],
              [-6.374681756016769, 49.897543379323473],
              [-6.375400516681965, 49.898326965566334],
              [-6.37456435717515, 49.898861586787248],
              [-6.375205500266958, 49.899003821980045],
              [-6.374768667993359, 49.89924079598611],
              [-6.375732091159932, 49.899956634703017],
              [-6.37532907642848, 49.899952080523036],
            ],
          ],
          [
            [
              [-6.34100093125328, 49.933221271523855],
              [-6.341414726007129, 49.932831422778882],
              [-6.341172794934789, 49.933442484236288],
              [-6.34100093125328, 49.933221271523855],
            ],
          ],
          [
            [
              [-6.361537812702429, 49.931274115173387],
              [-6.362274232349423, 49.931093393025726],
              [-6.362810942121755, 49.931494289792653],
              [-6.361812127020298, 49.932719716001046],
              [-6.360831001213735, 49.932214079624345],
              [-6.361537812702429, 49.931274115173387],
            ],
          ],
          [
            [
              [-6.299353426596869, 49.937378727657808],
              [-6.297709713503568, 49.936799985636306],
              [-6.296205726717218, 49.93697548645293],
              [-6.295482655616959, 49.936214072678808],
              [-6.292967128731816, 49.935440853970732],
              [-6.292043031287507, 49.935505712779111],
              [-6.29149560231561, 49.936153183974739],
              [-6.290309469128244, 49.936250237741618],
              [-6.2915799532615, 49.935503084468635],
              [-6.290391001275366, 49.934910079856287],
              [-6.289926570307082, 49.934218231082752],
              [-6.288802884263007, 49.93399801793641],
              [-6.288772517503063, 49.933499741555217],
              [-6.287721618486706, 49.933376701779416],
              [-6.288137651396039, 49.930890379848705],
              [-6.286687733020072, 49.930888290242542],
              [-6.285112596884621, 49.930214151588004],
              [-6.283518039929053, 49.930498120600454],
              [-6.283878770002089, 49.930635942156741],
              [-6.283292442100248, 49.930798910015838],
              [-6.281668412841274, 49.930813997301932],
              [-6.281885494569008, 49.929239379362635],
              [-6.281588349417897, 49.928828353822652],
              [-6.277920183197306, 49.929542551163308],
              [-6.277464152558669, 49.929052803634605],
              [-6.276735509188756, 49.92893125458761],
              [-6.276738467192187, 49.928492932856862],
              [-6.276243639195611, 49.928665981850038],
              [-6.277260775060804, 49.92814250156782],
              [-6.277890726959301, 49.928282991244608],
              [-6.277750640314964, 49.928023620727352],
              [-6.278693475429143, 49.928407192873053],
              [-6.280548164202957, 49.928510492414027],
              [-6.281306703006309, 49.927775181846378],
              [-6.280896013639328, 49.926492827871293],
              [-6.279279422192418, 49.926464416668061],
              [-6.27817218432664, 49.92592405803336],
              [-6.277509797103876, 49.924992926588821],
              [-6.278513747973495, 49.924007413393575],
              [-6.278401201705838, 49.922911092114354],
              [-6.275656147232351, 49.922886541293074],
              [-6.276193599514845, 49.922691256076014],
              [-6.276286799089495, 49.921777185746045],
              [-6.276062898072779, 49.921578466597232],
              [-6.275594935045742, 49.921724394969296],
              [-6.275518156788695, 49.921470821564156],
              [-6.276331711911843, 49.921010655627839],
              [-6.27643780636979, 49.920367863489616],
              [-6.275788902988108, 49.920138084932319],
              [-6.275901581462502, 49.919773994514536],
              [-6.277127097159202, 49.920125539859022],
              [-6.277134227483629, 49.919718557342151],
              [-6.278416782838075, 49.919565881256723],
              [-6.277476445164794, 49.918139323576646],
              [-6.278303867631205, 49.917490567714161],
              [-6.278307203654597, 49.916459249133482],
              [-6.279687183041101, 49.915677580941335],
              [-6.279110376887549, 49.915081627894018],
              [-6.281897096684761, 49.915847803647658],
              [-6.282839857322505, 49.915824623116805],
              [-6.283768813370241, 49.916430921704588],
              [-6.283489215541821, 49.917001858051762],
              [-6.284077222166285, 49.917346324960064],
              [-6.285187478676059, 49.917294430799835],
              [-6.286388857668902, 49.916762245482509],
              [-6.286496315165501, 49.916104994688084],
              [-6.285983607132037, 49.915557091443084],
              [-6.283791225911849, 49.914736626187178],
              [-6.284178445068636, 49.913478740123637],
              [-6.28316428901835, 49.912649742496129],
              [-6.284056135702247, 49.912713018668697],
              [-6.283163644301824, 49.911715790904971],
              [-6.284384181826191, 49.912232968915163],
              [-6.284238093672546, 49.911576106484318],
              [-6.284717203800271, 49.911259630923333],
              [-6.287189126149105, 49.91125988264595],
              [-6.287296700355458, 49.910290387451901],
              [-6.287763891683681, 49.910028329753992],
              [-6.291191009505176, 49.910695869785208],
              [-6.292671065477842, 49.91041693304868],
              [-6.294548744763716, 49.911302895597309],
              [-6.296744766163176, 49.911631717225212],
              [-6.297376268618903, 49.911134065102068],
              [-6.296667896044234, 49.91040810857762],
              [-6.297050376662459, 49.909883708716855],
              [-6.297583766685016, 49.910412799343845],
              [-6.298100442436292, 49.910331526542343],
              [-6.297887248756677, 49.910566199072619],
              [-6.299587360176133, 49.910959270493741],
              [-6.300744023237583, 49.912133763950216],
              [-6.301647085968333, 49.91210380549677],
              [-6.302938639287684, 49.911539298612482],
              [-6.303452255042324, 49.910673466618313],
              [-6.303289770942736, 49.910085621891326],
              [-6.302279749257975, 49.909524783854522],
              [-6.301146567843342, 49.90970817132461],
              [-6.300200496161449, 49.909004566113261],
              [-6.301992337371797, 49.908879492910579],
              [-6.30239946428178, 49.908163395159967],
              [-6.300080280123353, 49.906059421976941],
              [-6.300870578668222, 49.905171602975528],
              [-6.301994365115566, 49.904977768275153],
              [-6.302053399781733, 49.904530159653831],
              [-6.301361136767548, 49.904281437573502],
              [-6.302142672771537, 49.904156112668765],
              [-6.301974997605944, 49.903509101170457],
              [-6.303127818165614, 49.903673157388496],
              [-6.303275415757693, 49.90307830178979],
              [-6.303896803623775, 49.902984999540287],
              [-6.304532557097549, 49.903630839554765],
              [-6.304561105412205, 49.904247959368625],
              [-6.304970046332906, 49.904069000013337],
              [-6.305916485139134, 49.904401804484451],
              [-6.305936840469268, 49.90386473721194],
              [-6.306438814395087, 49.90393159975612],
              [-6.306562485389112, 49.90517771678995],
              [-6.306031210930611, 49.906109908620785],
              [-6.307016029690176, 49.907028883189881],
              [-6.308052560326908, 49.907387131764104],
              [-6.3082131725615, 49.908531133841159],
              [-6.310607428940024, 49.909800731842736],
              [-6.310997824689251, 49.911909787110211],
              [-6.312812936069962, 49.913245001912621],
              [-6.31499392627096, 49.913590235621363],
              [-6.317358148205749, 49.913257321279978],
              [-6.317984929971439, 49.912475384267559],
              [-6.318268514744548, 49.909882292887239],
              [-6.317656207869845, 49.908629176731402],
              [-6.32110673474802, 49.908542700412404],
              [-6.322479974542435, 49.907758062249094],
              [-6.323045382686536, 49.908128371921315],
              [-6.323428066602781, 49.909149783522615],
              [-6.325492529928758, 49.91021723587955],
              [-6.327157644931755, 49.911616323417462],
              [-6.327578514804911, 49.91258050509601],
              [-6.326190229430509, 49.913399958979909],
              [-6.326071680162391, 49.91380301513658],
              [-6.326496179722627, 49.913952717540894],
              [-6.325744570480993, 49.91386923036093],
              [-6.324917901629322, 49.914549800023622],
              [-6.324070563885792, 49.915979794818625],
              [-6.322543822898613, 49.91646873840773],
              [-6.321781528365535, 49.91719997046625],
              [-6.319272660157474, 49.916230018770044],
              [-6.31828498246855, 49.916109400306311],
              [-6.317672576859525, 49.916367101307891],
              [-6.317409304280439, 49.917396428183125],
              [-6.318250629013892, 49.91750091850956],
              [-6.318156092652209, 49.918064139196389],
              [-6.317267408118902, 49.918037899997572],
              [-6.316545250939685, 49.918517426934251],
              [-6.31730422639967, 49.916751588550106],
              [-6.317253828342604, 49.916452922625609],
              [-6.316680411769453, 49.916351893139705],
              [-6.317646441796598, 49.916261896320876],
              [-6.316634322101932, 49.915260342836632],
              [-6.314233254749934, 49.915244581095884],
              [-6.312129920599509, 49.915816063888826],
              [-6.311680922650996, 49.916187322259788],
              [-6.311999176051056, 49.916817044439632],
              [-6.311546884093383, 49.917011158720314],
              [-6.30961519482765, 49.916384828886194],
              [-6.309028994152592, 49.916624411092108],
              [-6.307910387074218, 49.917878115314458],
              [-6.308936201639315, 49.918831543058005],
              [-6.30816665919113, 49.919234515261962],
              [-6.308352141394963, 49.920078848305145],
              [-6.308932361253238, 49.920099542845776],
              [-6.308992304478599, 49.920567921144858],
              [-6.309486159590981, 49.920776292542968],
              [-6.311224417118961, 49.920592787095437],
              [-6.312731553685761, 49.920910972482211],
              [-6.308805871527317, 49.921102159870038],
              [-6.308279678522786, 49.921874001102104],
              [-6.308358080551392, 49.922705222745705],
              [-6.3089307330424, 49.923452359813986],
              [-6.311577854900362, 49.924579357090316],
              [-6.313791507587561, 49.927544622551061],
              [-6.309309927512237, 49.930366011842864],
              [-6.30977691256675, 49.931177367353627],
              [-6.308856807217678, 49.93172272067833],
              [-6.309147281753392, 49.933538556220327],
              [-6.30815363957031, 49.934182022153593],
              [-6.305935086404427, 49.934925036832702],
              [-6.304110645872383, 49.934367505276725],
              [-6.301219439706267, 49.93504095417164],
              [-6.299959808364442, 49.9359236856968],
              [-6.299353426596869, 49.937378727657808],
            ],
          ],
          [
            [
              [-6.386109174248494, 49.93307309803977],
              [-6.38649676539568, 49.932245853187965],
              [-6.386487868680928, 49.933098341202943],
              [-6.386905235322641, 49.933502750930202],
              [-6.385989759357872, 49.934044053483831],
              [-6.386559509328124, 49.934192534360093],
              [-6.386623329331532, 49.935170044278372],
              [-6.386276935441798, 49.934948311653848],
              [-6.385409428656185, 49.934515062122394],
              [-6.385675570762446, 49.934268320496386],
              [-6.385287640663286, 49.934202931559362],
              [-6.385294321840153, 49.93349450263544],
              [-6.386109174248494, 49.93307309803977],
            ],
          ],
          [
            [
              [-6.348572545451638, 49.929871348301369],
              [-6.348920737114718, 49.92852291227981],
              [-6.350547647020274, 49.928138736862628],
              [-6.352977415569444, 49.928634983109745],
              [-6.354384980140738, 49.92843087033269],
              [-6.354169487835999, 49.929301919188632],
              [-6.354686044037645, 49.929758500901485],
              [-6.357590000977047, 49.930333094127796],
              [-6.355564743188589, 49.93195613513825],
              [-6.354316073154013, 49.932207374619196],
              [-6.35354353716678, 49.933177659830918],
              [-6.353528143161723, 49.933686645917035],
              [-6.354668842514041, 49.934771234248998],
              [-6.355792830449635, 49.936673491618613],
              [-6.354979139505727, 49.937852296402902],
              [-6.353788105648379, 49.938481090554731],
              [-6.352818178514219, 49.938208022049487],
              [-6.352195309672342, 49.937640249574081],
              [-6.350097570660729, 49.937228687801742],
              [-6.351519830698728, 49.935608620276625],
              [-6.35194497679049, 49.933414136757307],
              [-6.349405973946374, 49.931863764953498],
              [-6.348572545451638, 49.929871348301369],
            ],
          ],
          [
            [
              [-6.324542550852726, 49.939749168690547],
              [-6.324655740222586, 49.939534380282105],
              [-6.324752372882815, 49.939632451039024],
              [-6.324542550852726, 49.939749168690547],
            ],
          ],
          [
            [
              [-6.347120499681472, 49.939496524514666],
              [-6.347106329796775, 49.939030042815865],
              [-6.34847029703184, 49.939034604979526],
              [-6.348233047016625, 49.939774182039073],
              [-6.347928274587204, 49.939672250236121],
              [-6.347120499681472, 49.939496524514666],
            ],
          ],
          [
            [
              [-6.343411158831236, 49.940092872661914],
              [-6.34345669262441, 49.939855407968821],
              [-6.343556756487133, 49.939913742015598],
              [-6.343411158831236, 49.940092872661914],
            ],
          ],
          [
            [
              [-6.326543638479395, 49.940654313079534],
              [-6.32666845187867, 49.940538069467657],
              [-6.326701844108617, 49.940739283116038],
              [-6.326543638479395, 49.940654313079534],
            ],
          ],
          [
            [
              [-6.352663343774133, 49.941342570384997],
              [-6.352466102664629, 49.941165520228147],
              [-6.353011450700813, 49.941167510914497],
              [-6.352663343774133, 49.941342570384997],
            ],
          ],
          [
            [
              [-6.275852865254723, 49.944353472396713],
              [-6.274716576750611, 49.943867239545547],
              [-6.273712394775525, 49.943974498520582],
              [-6.272802330680218, 49.943712049118538],
              [-6.274323869940686, 49.943212273712092],
              [-6.276043527465735, 49.94420785841762],
              [-6.275852865254723, 49.944353472396713],
            ],
          ],
          [
            [
              [-6.244766186034215, 49.945420014744528],
              [-6.244826050013796, 49.944674574706951],
              [-6.245471059767492, 49.944430470267861],
              [-6.246084204130949, 49.944663530944901],
              [-6.246528172090414, 49.944290031834356],
              [-6.24705154005068, 49.944548878276755],
              [-6.246480683015436, 49.94481189885888],
              [-6.248075492349978, 49.945440808293569],
              [-6.246475345942738, 49.945970111558566],
              [-6.246435694888234, 49.946264005046842],
              [-6.245312654698798, 49.945956962256915],
              [-6.244199371200707, 49.945776424584906],
              [-6.244766186034215, 49.945420014744528],
            ],
          ],
          [
            [
              [-6.258980223203332, 49.946837109757013],
              [-6.257007790205171, 49.946386858290396],
              [-6.256995377011072, 49.945693557338011],
              [-6.258213458632083, 49.945860219787548],
              [-6.258916938325767, 49.946578491284718],
              [-6.259576910264589, 49.946520019005],
              [-6.258980223203332, 49.946837109757013],
            ],
          ],
          [
            [
              [-6.276099276141608, 49.945308077602135],
              [-6.277080632259128, 49.945116158569157],
              [-6.27789163147084, 49.945393033112325],
              [-6.276764255153287, 49.946563957333645],
              [-6.275707557488884, 49.94603250202686],
              [-6.276099276141608, 49.945308077602135],
            ],
          ],
          [
            [
              [-6.37961534497172, 49.942802397263215],
              [-6.379255112270773, 49.942060159412989],
              [-6.379825293786697, 49.94212047357253],
              [-6.380005345581509, 49.942444802522559],
              [-6.380954175437494, 49.942099353159115],
              [-6.381000442501684, 49.94266990090658],
              [-6.380149266532749, 49.942785795606909],
              [-6.379806775704538, 49.943344057409831],
              [-6.37961534497172, 49.942802397263215],
            ],
          ],
          [
            [
              [-6.26383899725651, 49.946749153947913],
              [-6.264332864432475, 49.946168579389941],
              [-6.266214318691784, 49.946173050260086],
              [-6.266094490198229, 49.944661274266743],
              [-6.265033613442781, 49.944191064463205],
              [-6.262872387737519, 49.944317452201858],
              [-6.262292088827936, 49.943415606069244],
              [-6.261456227076703, 49.943155735091779],
              [-6.261613087268577, 49.942832326123757],
              [-6.262284360399474, 49.943044266471489],
              [-6.262727697742172, 49.942791292220321],
              [-6.262454117008448, 49.942188590030462],
              [-6.263049497460079, 49.94184183450519],
              [-6.2638649426723, 49.942028660784359],
              [-6.265077235667669, 49.943044890459362],
              [-6.267296974046475, 49.945596859563075],
              [-6.267014675992251, 49.946440494846691],
              [-6.26625890151191, 49.946377465489171],
              [-6.265858233008956, 49.946750356179813],
              [-6.266081378135762, 49.947255029606623],
              [-6.266548066625837, 49.947297220290061],
              [-6.265956062288105, 49.947651067385479],
              [-6.26540487892464, 49.947410428307613],
              [-6.26383899725651, 49.946749153947913],
            ],
          ],
          [
            [
              [-6.372886807353028, 49.943454489445301],
              [-6.373104927570572, 49.943184396724895],
              [-6.373696644422509, 49.943174640070175],
              [-6.374129623348098, 49.943551510438773],
              [-6.373577626576025, 49.943753234041964],
              [-6.372886807353028, 49.943454489445301],
            ],
          ],
          [
            [
              [-6.386298096529382, 49.944392251779377],
              [-6.385984185629025, 49.944068504631474],
              [-6.385720640520653, 49.944145077753092],
              [-6.385676617823563, 49.943048038915542],
              [-6.386681799012687, 49.942801189550231],
              [-6.386008704554524, 49.943617654810794],
              [-6.386518906484685, 49.943723401105146],
              [-6.386730275967818, 49.944299388944565],
              [-6.386298096529382, 49.944392251779377],
            ],
          ],
          [
            [
              [-6.248098784700863, 49.949677173549489],
              [-6.247382032009416, 49.949487514633788],
              [-6.247151076231298, 49.94865466201022],
              [-6.248417600487787, 49.948579401073822],
              [-6.249679660874251, 49.948928102131795],
              [-6.2493961737246, 49.949841019823552],
              [-6.248447407524668, 49.949645485621367],
              [-6.248196497588739, 49.949915638009543],
              [-6.248098784700863, 49.949677173549489],
            ],
          ],
          [
            [
              [-6.267705192906821, 49.94838114360499],
              [-6.26876345170322, 49.948300738382514],
              [-6.269862860918907, 49.948936860120668],
              [-6.269933806803607, 49.949863754999186],
              [-6.269362604328492, 49.949791238683531],
              [-6.267347937691595, 49.94959106841543],
              [-6.267705192906821, 49.94838114360499],
            ],
          ],
          [
            [
              [-6.244517325846551, 49.950163951105566],
              [-6.245292500704305, 49.949702725443544],
              [-6.246640342040648, 49.950075304313259],
              [-6.245924533052064, 49.950402993916541],
              [-6.246234743208738, 49.95074254434904],
              [-6.245157410147993, 49.950876530383717],
              [-6.245635124280448, 49.950584571109239],
              [-6.244517325846551, 49.950163951105566],
            ],
          ],
          [
            [
              [-6.345986757958509, 49.948450142909842],
              [-6.346086737899122, 49.948368104145814],
              [-6.346183340607529, 49.948557941678715],
              [-6.345986757958509, 49.948450142909842],
            ],
          ],
          [
            [
              [-6.296395075330244, 49.950210257576444],
              [-6.296308551871572, 49.950021806150879],
              [-6.297228650642864, 49.950128031030594],
              [-6.2978309272335, 49.950830032395437],
              [-6.296395075330244, 49.950210257576444],
            ],
          ],
          [
            [
              [-6.392203207059302, 49.946609626959074],
              [-6.392870494950604, 49.946342249613878],
              [-6.393737054520462, 49.947220904125089],
              [-6.393059543889405, 49.947391491279099],
              [-6.392203207059302, 49.946609626959074],
            ],
          ],
          [
            [
              [-6.391150553395654, 49.947070687343519],
              [-6.391726869536602, 49.946845460692209],
              [-6.392006245045675, 49.947478249259078],
              [-6.391354380972179, 49.947563224886423],
              [-6.391150553395654, 49.947070687343519],
            ],
          ],
          [
            [
              [-6.362084387789681, 49.94864466880265],
              [-6.362398404752555, 49.948493361726733],
              [-6.362315069950985, 49.948666571027594],
              [-6.362084387789681, 49.94864466880265],
            ],
          ],
          [
            [
              [-6.362542888139331, 49.949442643677543],
              [-6.363186519851718, 49.94917363218299],
              [-6.363488724798969, 49.949600462456459],
              [-6.363119230084068, 49.949570296657477],
              [-6.362542888139331, 49.949442643677543],
            ],
          ],
          [
            [
              [-6.345129739855203, 49.951628127558891],
              [-6.345060300773992, 49.951414773867668],
              [-6.345250733976416, 49.951469714977677],
              [-6.345129739855203, 49.951628127558891],
            ],
          ],
          [
            [
              [-6.255725631792318, 49.951165219784784],
              [-6.254855627126075, 49.950914653417755],
              [-6.253966059343368, 49.95115339840256],
              [-6.253975891231747, 49.950695931624217],
              [-6.252336319039947, 49.949973322715017],
              [-6.253281301316007, 49.94928804630262],
              [-6.253554253641777, 49.948541071037191],
              [-6.254095692901354, 49.948434795893021],
              [-6.254830374960285, 49.949127650419854],
              [-6.255279550829616, 49.949066151834181],
              [-6.257480300361623, 49.95007942065191],
              [-6.257290626378655, 49.951231859404629],
              [-6.260508499917467, 49.9521383818199],
              [-6.26123720838437, 49.952619960193424],
              [-6.261537285493384, 49.954610105139018],
              [-6.26297138843186, 49.955166487233441],
              [-6.261526291563799, 49.955637199030825],
              [-6.260028164731622, 49.95552406403678],
              [-6.260302893530884, 49.955318695727115],
              [-6.259955222271563, 49.95496706288624],
              [-6.260844033838453, 49.954719301953503],
              [-6.26041008182721, 49.953991123621549],
              [-6.258436305809984, 49.953734403076247],
              [-6.256482738534551, 49.952332342143016],
              [-6.255725631792318, 49.951165219784784],
            ],
          ],
          [
            [
              [-6.257405171895233, 49.956286404864386],
              [-6.25694874805412, 49.955998149705565],
              [-6.257732591791646, 49.956045812240205],
              [-6.257405171895233, 49.956286404864386],
            ],
          ],
          [
            [
              [-6.268992031814482, 49.956377166072699],
              [-6.269444469624965, 49.956273201299062],
              [-6.269384258129516, 49.956412193708964],
              [-6.268992031814482, 49.956377166072699],
            ],
          ],
          [
            [
              [-6.300183384771605, 49.956252298759367],
              [-6.300336779253363, 49.955955058730325],
              [-6.300571650264822, 49.956102004226288],
              [-6.300183384771605, 49.956252298759367],
            ],
          ],
          [
            [
              [-6.295115767728044, 49.957199853603555],
              [-6.295938707168786, 49.956777899535979],
              [-6.295648218804796, 49.957086518604072],
              [-6.295115767728044, 49.957199853603555],
            ],
          ],
          [
            [
              [-6.368214942967319, 49.95443342239431],
              [-6.36874407764617, 49.952562490583375],
              [-6.368235204823123, 49.952302742837539],
              [-6.368832768749427, 49.951802380277876],
              [-6.369293694582852, 49.95213322246326],
              [-6.369084735627741, 49.952597326298353],
              [-6.370515934358187, 49.952462319627863],
              [-6.370628907112183, 49.953338995961261],
              [-6.371456100774238, 49.953740586481395],
              [-6.370275366071601, 49.954730893896318],
              [-6.370610421921939, 49.954888314698593],
              [-6.370359937067265, 49.955340490862589],
              [-6.369712158701976, 49.95548732036405],
              [-6.368605723207126, 49.955113351529192],
              [-6.368214942967319, 49.95443342239431],
            ],
          ],
          [
            [
              [-6.31239028218423, 49.961508567855525],
              [-6.312513967600387, 49.961238512588039],
              [-6.31274288399595, 49.9613649597662],
              [-6.31239028218423, 49.961508567855525],
            ],
          ],
          [
            [
              [-6.313857351065895, 49.963312056258012],
              [-6.313865192341958, 49.962324655570725],
              [-6.314306711492175, 49.962363993690353],
              [-6.313857351065895, 49.963312056258012],
            ],
          ],
          [
            [
              [-6.32534705892397, 49.963433195510504],
              [-6.325165240276424, 49.962759717857509],
              [-6.325633388833421, 49.963002278967522],
              [-6.32534705892397, 49.963433195510504],
            ],
          ],
          [
            [
              [-6.323815322763707, 49.964333545496032],
              [-6.323370060245895, 49.963926353852457],
              [-6.324811845084057, 49.964059672368379],
              [-6.323815322763707, 49.964333545496032],
            ],
          ],
          [
            [
              [-6.327037443381197, 49.964393139123231],
              [-6.326658885713786, 49.963688323344932],
              [-6.327179224023819, 49.964170082013993],
              [-6.327037443381197, 49.964393139123231],
            ],
          ],
          [
            [
              [-6.329784625260954, 49.967363353357243],
              [-6.330060475732423, 49.966738454697442],
              [-6.329606010376116, 49.966245246974736],
              [-6.328892422648098, 49.966283626274823],
              [-6.329338868080648, 49.965290620013342],
              [-6.330412527838596, 49.965052506744897],
              [-6.330994695962896, 49.965507640861077],
              [-6.332544377633527, 49.965536946492463],
              [-6.333519453277709, 49.966513663410403],
              [-6.332367304107911, 49.966918511145622],
              [-6.330791138038466, 49.966781314000372],
              [-6.330551006715764, 49.967555149930618],
              [-6.329784625260954, 49.967363353357243],
            ],
          ],
          [
            [
              [-6.358370592011319, 49.966700223070283],
              [-6.358902933423374, 49.96606203163487],
              [-6.358325483633576, 49.96609364829358],
              [-6.358813728560506, 49.965924115941156],
              [-6.358610420423402, 49.965588932757612],
              [-6.359630745829844, 49.96514829154443],
              [-6.359373773009716, 49.964019676404007],
              [-6.358573713239351, 49.96408849066755],
              [-6.358031712704538, 49.963170360384623],
              [-6.357002488904336, 49.963173112361595],
              [-6.356598514925945, 49.962777985964678],
              [-6.35540470652996, 49.963212529271509],
              [-6.355161866455246, 49.962841034426425],
              [-6.355627159662239, 49.962525685859397],
              [-6.353897885746941, 49.961943748089418],
              [-6.351866255515167, 49.959944214633175],
              [-6.351355184195151, 49.959212062845751],
              [-6.351366227941865, 49.958083256558531],
              [-6.3492824904001, 49.957744031044939],
              [-6.349051954898259, 49.956812365583438],
              [-6.348009168695975, 49.955658354161066],
              [-6.350491046577099, 49.953564788438065],
              [-6.349935014457158, 49.952750636004751],
              [-6.349415150744044, 49.952986127798759],
              [-6.34937809899662, 49.952560098869377],
              [-6.351998224135516, 49.95059447940961],
              [-6.352059624992202, 49.949838110168066],
              [-6.351546522294097, 49.949221214978039],
              [-6.349996216994297, 49.948576707123955],
              [-6.349562562367426, 49.94669165177978],
              [-6.350069807560015, 49.945791655221726],
              [-6.351423349406633, 49.945323263470414],
              [-6.352235087838634, 49.944553085096871],
              [-6.354596392945647, 49.945146362569389],
              [-6.355309148596449, 49.945611759659947],
              [-6.356517593692948, 49.945590585437422],
              [-6.357129762978191, 49.944973655212749],
              [-6.358912078845056, 49.94644167984773],
              [-6.362985381411357, 49.945688046755564],
              [-6.363063876773872, 49.945908247921352],
              [-6.362231479923185, 49.946263557970262],
              [-6.362957644405698, 49.946860679536513],
              [-6.361656308098026, 49.946764842590355],
              [-6.360860590500394, 49.947710852751349],
              [-6.359326404864891, 49.947970197140911],
              [-6.357257204947206, 49.947417311118095],
              [-6.356259063940127, 49.949087175811989],
              [-6.356737613607135, 49.949953705570387],
              [-6.357947608338456, 49.950627132224781],
              [-6.358588057555905, 49.950630916824053],
              [-6.358541289307141, 49.950082878057373],
              [-6.359277783098168, 49.949868881880342],
              [-6.360421889205391, 49.950229821396519],
              [-6.359713375190593, 49.950645234239737],
              [-6.360799117612264, 49.951501478210915],
              [-6.362547673908492, 49.95112875672362],
              [-6.362960457443853, 49.950604793511943],
              [-6.364979058325239, 49.951613004986278],
              [-6.364232407563772, 49.952392517779977],
              [-6.364236262979643, 49.954237037344853],
              [-6.363665564817642, 49.954141570714768],
              [-6.363862035131421, 49.953739137019461],
              [-6.360938464042318, 49.953241910946318],
              [-6.360075370183651, 49.952750714762331],
              [-6.359345451126885, 49.952976165823841],
              [-6.359349818529886, 49.953271147425667],
              [-6.358699775291682, 49.953146250089894],
              [-6.358358551744613, 49.953660304603588],
              [-6.358438911444576, 49.954564313316034],
              [-6.359315702669988, 49.954790454475351],
              [-6.35882220538118, 49.955008799019886],
              [-6.359194945613657, 49.955244019255097],
              [-6.360245750208231, 49.955219745848424],
              [-6.361306775420021, 49.954584089318296],
              [-6.362222141819808, 49.954727770132173],
              [-6.361900120716949, 49.954929768581209],
              [-6.36249749223031, 49.95588988117278],
              [-6.362096134376998, 49.956124564148261],
              [-6.361829751728422, 49.95550831479374],
              [-6.361298599460205, 49.955557119828711],
              [-6.360984501319532, 49.955877595452215],
              [-6.361263468766877, 49.956247717416453],
              [-6.360708345100518, 49.95618584303385],
              [-6.361134856263396, 49.956491018710338],
              [-6.360368690873669, 49.956917604454645],
              [-6.360474113358218, 49.957589410371057],
              [-6.361656318564775, 49.957614184658539],
              [-6.362132955188776, 49.958087518473874],
              [-6.360584745479252, 49.957702222147518],
              [-6.360434146978463, 49.958041733194662],
              [-6.359901820479313, 49.957738740077595],
              [-6.359006805471539, 49.957990201563071],
              [-6.35914223198137, 49.958531302529693],
              [-6.360170955559457, 49.958616742070546],
              [-6.359372560463302, 49.958887963129747],
              [-6.359751435127928, 49.959051863899695],
              [-6.359546282432452, 49.959281854700883],
              [-6.358471784384825, 49.959045154431529],
              [-6.357676200934796, 49.959471029299586],
              [-6.356791220785472, 49.959371159905331],
              [-6.356897157186047, 49.959740604714121],
              [-6.356545808863332, 49.95972683104992],
              [-6.357001196183386, 49.960351276504106],
              [-6.356522634534437, 49.96051955741514],
              [-6.35715513111453, 49.960727013284078],
              [-6.356441444358219, 49.961364857457234],
              [-6.356814699951121, 49.962052683011585],
              [-6.357449385398103, 49.962314945021326],
              [-6.359735440836327, 49.962759786799062],
              [-6.359915851146416, 49.963088631618014],
              [-6.360329627659635, 49.962728412331579],
              [-6.359868051897321, 49.963269498758606],
              [-6.360046495370969, 49.964085228175328],
              [-6.36260264440043, 49.963814371780593],
              [-6.362760485295982, 49.964309632546218],
              [-6.362210578122992, 49.964891849925728],
              [-6.362945498922762, 49.965428354992468],
              [-6.362405121750744, 49.965669176425386],
              [-6.3612410209967, 49.964906779580943],
              [-6.36076883289645, 49.965284499177884],
              [-6.360215989905908, 49.965202739046873],
              [-6.360820542317434, 49.966102301265593],
              [-6.360089754644905, 49.965858078929067],
              [-6.360382660033492, 49.966520119375005],
              [-6.358657683713124, 49.96702055029462],
              [-6.358370592011319, 49.966700223070283],
            ],
          ],
          [
            [
              [-6.348383337142886, 49.96791891893443],
              [-6.346608756552079, 49.967640016280377],
              [-6.346016280520254, 49.967938505173542],
              [-6.345083515901194, 49.967805244819147],
              [-6.344107606762603, 49.968044326270331],
              [-6.343587336081847, 49.967612129871092],
              [-6.343145940493391, 49.96802371372641],
              [-6.342578037892571, 49.967867748058687],
              [-6.341903309520434, 49.966805156719182],
              [-6.340848570816133, 49.966509967813053],
              [-6.340347796405476, 49.966014938014489],
              [-6.341305783938069, 49.965159275993649],
              [-6.341439931087837, 49.964062750944535],
              [-6.341065917598723, 49.963503582313585],
              [-6.339572054438581, 49.962684047860364],
              [-6.337676926685305, 49.962550802075491],
              [-6.33705126259999, 49.963472271221804],
              [-6.336128497063939, 49.963479838473447],
              [-6.334976541603708, 49.963870308854396],
              [-6.335047032269419, 49.964142117677341],
              [-6.334683916794343, 49.964028836685785],
              [-6.333823320766815, 49.962882276034001],
              [-6.333111288105305, 49.962766752964605],
              [-6.333357963090751, 49.962205925401555],
              [-6.332044903889521, 49.962092193013248],
              [-6.332224094399976, 49.961556388658046],
              [-6.333332111813275, 49.96136015332695],
              [-6.33349565675849, 49.960898718382666],
              [-6.332783879284721, 49.959823965765345],
              [-6.331665553052575, 49.960122259024438],
              [-6.332686386420127, 49.959453285081857],
              [-6.332356473316759, 49.958917634454934],
              [-6.331288241571361, 49.958547279839678],
              [-6.329304081624556, 49.958476618025493],
              [-6.328603198044943, 49.958577509801451],
              [-6.327906696352, 49.959301814727716],
              [-6.327119720897803, 49.959205253721237],
              [-6.324221157722613, 49.956716644358764],
              [-6.32273383856232, 49.956617414736968],
              [-6.322757889778572, 49.955330662870765],
              [-6.321304414854664, 49.953741836889513],
              [-6.320245937723719, 49.953322427401858],
              [-6.319464496926474, 49.953737610600598],
              [-6.31911437509276, 49.953332253042035],
              [-6.321065464923959, 49.950905500305659],
              [-6.322694752246501, 49.946882734694157],
              [-6.322466742914332, 49.945739467660772],
              [-6.321878896478758, 49.945201721173873],
              [-6.321116274434322, 49.944891853214379],
              [-6.31934721726391, 49.945270110170497],
              [-6.319673731478566, 49.944727928940161],
              [-6.320437850358948, 49.944214407768207],
              [-6.3235328082417, 49.944394907862659],
              [-6.328191940434295, 49.943515346301297],
              [-6.328547616098151, 49.942786683465442],
              [-6.329461731116115, 49.942842484036021],
              [-6.330886401736471, 49.942031538285534],
              [-6.330881897291914, 49.940615373700588],
              [-6.332229475992216, 49.939180112219674],
              [-6.332937347681121, 49.939268797864244],
              [-6.332987585884784, 49.940696748879255],
              [-6.333660270777885, 49.941417528888152],
              [-6.335146539611018, 49.944921613455094],
              [-6.336624163033201, 49.946001858901624],
              [-6.337489946427427, 49.94592263274297],
              [-6.338917807136135, 49.946557497736727],
              [-6.33915425026934, 49.947030945649161],
              [-6.338133164418288, 49.948979554699463],
              [-6.339742566881685, 49.950746794530247],
              [-6.339518388141382, 49.95126180288193],
              [-6.340472788584028, 49.951855907148996],
              [-6.339708203621225, 49.952223806415233],
              [-6.338399235336947, 49.953686499979831],
              [-6.339031544081239, 49.955861091424431],
              [-6.340152090183079, 49.956533556336005],
              [-6.341267850096418, 49.956672590057643],
              [-6.34154990571164, 49.956397462947102],
              [-6.341230122918425, 49.955851564060971],
              [-6.34246333322429, 49.956814021870535],
              [-6.344143689151585, 49.95742945717361],
              [-6.344992117407522, 49.958532307877284],
              [-6.346131023377222, 49.958973670121324],
              [-6.345807855698617, 49.959906329418885],
              [-6.346894160369888, 49.960413547049463],
              [-6.34795832751336, 49.962050875978157],
              [-6.348811527129396, 49.962246489561032],
              [-6.349835100059867, 49.962042469106549],
              [-6.349587893406931, 49.962502572710484],
              [-6.350690870710491, 49.963502237776467],
              [-6.350351502358129, 49.964517403688816],
              [-6.351381429881481, 49.964583991824476],
              [-6.352025382581147, 49.965103276857441],
              [-6.351420418261716, 49.965355472352613],
              [-6.351500453214908, 49.965670105439258],
              [-6.349955288725338, 49.966077303220573],
              [-6.349672158292226, 49.966634138563421],
              [-6.35038915447979, 49.967158797949097],
              [-6.348969655421997, 49.967629647346158],
              [-6.34900972460315, 49.967903491005004],
              [-6.348383337142886, 49.96791891893443],
            ],
          ],
          [
            [
              [-6.314127668835067, 49.969929197517096],
              [-6.313131826026214, 49.96888830687336],
              [-6.312449871853606, 49.969000091062554],
              [-6.311837256478388, 49.969605992277366],
              [-6.31047585414078, 49.969733166507595],
              [-6.309545457075324, 49.969391672282484],
              [-6.309079892774292, 49.969598862115113],
              [-6.310091991819018, 49.967610373272706],
              [-6.309469237217584, 49.967073862505195],
              [-6.308524432131805, 49.967100924933689],
              [-6.308311596288332, 49.966795705767723],
              [-6.308988448868525, 49.966315207690776],
              [-6.307776376049799, 49.965474881821791],
              [-6.309335090280026, 49.964544949932986],
              [-6.310621586459817, 49.964954172300395],
              [-6.309914829401471, 49.96572373736538],
              [-6.31080451795072, 49.96678030447567],
              [-6.312315123467188, 49.967120876498079],
              [-6.313559462818676, 49.96696474734113],
              [-6.314536628837147, 49.967953240371095],
              [-6.31599192136649, 49.967784725143851],
              [-6.316411066092813, 49.967371377649634],
              [-6.317185100738674, 49.967867118170325],
              [-6.317857326166949, 49.967787162415924],
              [-6.318177383485729, 49.968976494213067],
              [-6.317381921399352, 49.968880181875299],
              [-6.317268085753913, 49.968248249606035],
              [-6.316212716435664, 49.968209310821237],
              [-6.315279411894045, 49.96875429902029],
              [-6.315908123102458, 49.969916835806444],
              [-6.315394342436205, 49.970115050645369],
              [-6.314746788568863, 49.969785551660543],
              [-6.314347555845579, 49.970406010305098],
              [-6.314127668835067, 49.969929197517096],
            ],
          ],
          [
            [
              [-6.324751938610001, 49.974828321649646],
              [-6.324511581811062, 49.974311821358178],
              [-6.32416516865103, 49.974493926024401],
              [-6.323658236769038, 49.973898274476561],
              [-6.322339970723325, 49.973387799493324],
              [-6.321308330458148, 49.973210346795248],
              [-6.320472090176136, 49.973531296443909],
              [-6.320177944955025, 49.973269624186599],
              [-6.321774630653024, 49.972265222378695],
              [-6.323121564738045, 49.970795014213337],
              [-6.32430801985153, 49.970832594283301],
              [-6.324637739162956, 49.970464848128408],
              [-6.324796090288745, 49.970690189238304],
              [-6.325870304700246, 49.970627565514953],
              [-6.327040982531146, 49.970116812044523],
              [-6.32820645113956, 49.970728326565535],
              [-6.328213062841326, 49.971593713822259],
              [-6.329588628820018, 49.972011996600628],
              [-6.329387470415147, 49.972350654915722],
              [-6.328851317243213, 49.972051261768904],
              [-6.327937906958462, 49.972335558909919],
              [-6.327674154277346, 49.97292490613814],
              [-6.328088188443458, 49.974023417343112],
              [-6.327235141533313, 49.974700476340509],
              [-6.326577470352136, 49.974788040371138],
              [-6.324751938610001, 49.974828321649646],
            ],
          ],
          [
            [
              [-6.292243120966676, 49.980033153379452],
              [-6.29193717254725, 49.979716059250158],
              [-6.290236539324343, 49.979359763308651],
              [-6.290296929986797, 49.978925612493427],
              [-6.291083058769601, 49.978461870765273],
              [-6.290571555191084, 49.978030014794584],
              [-6.289690291245475, 49.978224632107924],
              [-6.289927884898792, 49.977821709269172],
              [-6.288048454678128, 49.978355623619244],
              [-6.288661975808476, 49.978099839776249],
              [-6.288184318212274, 49.97790700407738],
              [-6.290158941310774, 49.976844064052784],
              [-6.28867187553928, 49.977129456260457],
              [-6.287981839520919, 49.976917505184986],
              [-6.288091677213129, 49.976599422482259],
              [-6.28893249836061, 49.976499834179506],
              [-6.288367818516784, 49.975752333675537],
              [-6.292791616834766, 49.973272268167044],
              [-6.29192254982151, 49.971786487003079],
              [-6.292580166908693, 49.970915378913034],
              [-6.292424134339586, 49.970354276276474],
              [-6.288684511513329, 49.966178208829511],
              [-6.286551929723633, 49.965225984581323],
              [-6.285691744266149, 49.96512195336507],
              [-6.284665530091969, 49.965425379116382],
              [-6.283974479810549, 49.965027134111573],
              [-6.283370193801673, 49.965102579520163],
              [-6.282573463302318, 49.965931989364684],
              [-6.281095870849165, 49.966226881870391],
              [-6.27988482180006, 49.965533800950119],
              [-6.279912111501357, 49.964883123403823],
              [-6.279273051635901, 49.964755573936252],
              [-6.279672117454078, 49.964348503859959],
              [-6.278105164574509, 49.963856621081192],
              [-6.276909568909216, 49.964122144785179],
              [-6.276589286736995, 49.964474105845532],
              [-6.274812566823472, 49.964526217327851],
              [-6.274301734023973, 49.965733734235798],
              [-6.273516643954088, 49.966318799639822],
              [-6.272914980939277, 49.965983776687722],
              [-6.272272732462857, 49.966087544700521],
              [-6.27268544214534, 49.965613424113307],
              [-6.272744352082558, 49.964358705190406],
              [-6.271117944114847, 49.964312531919873],
              [-6.270595337922435, 49.964003375195951],
              [-6.268653132202856, 49.964454715114378],
              [-6.268672709494981, 49.966627948869387],
              [-6.267757903300918, 49.966741753612268],
              [-6.26745174995791, 49.967490874399623],
              [-6.266702047868135, 49.967310656329687],
              [-6.265948750925402, 49.967610155765584],
              [-6.264106240592766, 49.966920377956654],
              [-6.263899580854838, 49.965909397632039],
              [-6.262918037383695, 49.965600009186353],
              [-6.262821988564117, 49.965129342846772],
              [-6.26181755928953, 49.965333682170098],
              [-6.262540655573005, 49.964346063137903],
              [-6.261467255238518, 49.964417066581376],
              [-6.261938232595511, 49.963575501676473],
              [-6.261655325227426, 49.962868762176718],
              [-6.262268840053534, 49.96247185680938],
              [-6.264096648605414, 49.962256989277719],
              [-6.265519870549678, 49.961001514231533],
              [-6.268031590811627, 49.96096564490432],
              [-6.268908858016331, 49.960051484335075],
              [-6.268869724735804, 49.959436552860005],
              [-6.270701635724248, 49.958451184637937],
              [-6.271419814297616, 49.958260925714335],
              [-6.272167560996791, 49.9588605106035],
              [-6.273961963258926, 49.959245995979657],
              [-6.277504980072576, 49.959233034241457],
              [-6.28017643589444, 49.9586097153311],
              [-6.281105484938687, 49.958093064378538],
              [-6.281467773749268, 49.957508282932558],
              [-6.281277722215289, 49.957237266475275],
              [-6.280729843948324, 49.957287218043639],
              [-6.281564353039044, 49.956413230793665],
              [-6.284248324519298, 49.956089892265553],
              [-6.285045757105463, 49.956682157790006],
              [-6.285287099261342, 49.958188522369312],
              [-6.286714564500978, 49.959026502964505],
              [-6.287249495768947, 49.96036722479684],
              [-6.290092479679975, 49.961780817212329],
              [-6.294120561336659, 49.962606903704149],
              [-6.294394977127448, 49.963416463461428],
              [-6.296052377988175, 49.963935364914917],
              [-6.304243465752026, 49.963919999621922],
              [-6.304638749564688, 49.965049879920088],
              [-6.304305979747251, 49.965853195723973],
              [-6.304942979561756, 49.966565569327116],
              [-6.304380126162158, 49.967291956199325],
              [-6.303667728201109, 49.967281544540675],
              [-6.302805759843728, 49.967876868654507],
              [-6.302651692983258, 49.969135147134949],
              [-6.303213354771476, 49.969909718096559],
              [-6.302780830372483, 49.969706234938378],
              [-6.302340288419787, 49.970115827304163],
              [-6.299793208841697, 49.969448290449236],
              [-6.298577321006626, 49.969424149248873],
              [-6.297875549703895, 49.969939702717362],
              [-6.297720002991331, 49.970885789769142],
              [-6.297970767439796, 49.971178822119775],
              [-6.299293234441022, 49.971349283223766],
              [-6.299387009068242, 49.971665238122306],
              [-6.298001614762565, 49.972712772721557],
              [-6.297932183258637, 49.973498194569721],
              [-6.297097998868728, 49.974015957712091],
              [-6.295066048343205, 49.974010361956644],
              [-6.293293030062733, 49.973622604583518],
              [-6.291187887884095, 49.974549168587764],
              [-6.291081928749535, 49.976664072747582],
              [-6.291582518616663, 49.977693811275167],
              [-6.292556251134084, 49.978067137284739],
              [-6.29414551492699, 49.977863341452561],
              [-6.29478146994087, 49.977406042648511],
              [-6.295982375339932, 49.979543560872038],
              [-6.295758970757196, 49.980277104167776],
              [-6.295282514806653, 49.980489139537923],
              [-6.294567494955363, 49.980060350435984],
              [-6.293930543897926, 49.979865314871098],
              [-6.293378899943081, 49.980079230575249],
              [-6.293184401726605, 49.979822794460411],
              [-6.292791704146724, 49.979957029070704],
              [-6.292668898273579, 49.980645445625754],
              [-6.292243120966676, 49.980033153379452],
            ],
          ],
          [
            [
              [-6.323305220749543, 49.97977292529545],
              [-6.322548843214896, 49.979445731612188],
              [-6.322571456469165, 49.979930793572393],
              [-6.321762116703503, 49.979573178084024],
              [-6.321982849061251, 49.979218506533655],
              [-6.321195658930829, 49.978330063045334],
              [-6.322834151988534, 49.977416770518275],
              [-6.324112850292138, 49.977935917199915],
              [-6.323581634390937, 49.978453357417294],
              [-6.324436847308452, 49.978483504333276],
              [-6.323814302761582, 49.980049959955423],
              [-6.323305220749543, 49.97977292529545],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000054",
        LAD13CDO: "00HY",
        LAD13NM: "Wiltshire",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.803672674605903, 51.68824594923634],
            [-1.802473001053157, 51.685355072335781],
            [-1.801189826062709, 51.685386178143112],
            [-1.799368523708326, 51.680836239146764],
            [-1.797842461230005, 51.678596615961567],
            [-1.796622801032527, 51.677080394995777],
            [-1.795219208404163, 51.67724699439259],
            [-1.794388940946711, 51.67663594234412],
            [-1.795448963099229, 51.672790452861911],
            [-1.792074784579624, 51.670357808116464],
            [-1.790700483489754, 51.668600285142034],
            [-1.788628637758397, 51.666998840266615],
            [-1.791078789580323, 51.664625040053572],
            [-1.791692919389507, 51.663122796634781],
            [-1.791991001994648, 51.663384069761044],
            [-1.793328656686771, 51.66300070442562],
            [-1.79416310295076, 51.663269207208891],
            [-1.796187930558509, 51.662462624116706],
            [-1.799092296715619, 51.66250178795849],
            [-1.798704734809015, 51.661543559650951],
            [-1.797308131133596, 51.660900086469503],
            [-1.795101924902589, 51.657058805819787],
            [-1.796239016960535, 51.656544684546361],
            [-1.795930399531114, 51.654124607496946],
            [-1.794683819386045, 51.651365723145211],
            [-1.794949661379685, 51.650749387646329],
            [-1.793424770602589, 51.648839675574074],
            [-1.793580643985227, 51.647932732342682],
            [-1.790496204906353, 51.641375350505889],
            [-1.788986957530975, 51.636822173499567],
            [-1.789951369040593, 51.634806259754235],
            [-1.788595679197951, 51.632711561205021],
            [-1.806388584795371, 51.63271702972979],
            [-1.807453147910868, 51.630388239903333],
            [-1.814328106045887, 51.624504614381109],
            [-1.820200432517704, 51.627747003964402],
            [-1.821275474833562, 51.626955612069644],
            [-1.828294738818253, 51.625095884218375],
            [-1.82923462650848, 51.624081238020835],
            [-1.830595328087059, 51.623708269505933],
            [-1.83099196709048, 51.622703614787021],
            [-1.830260380343541, 51.622495761618751],
            [-1.83089224087703, 51.622329434026724],
            [-1.830945136067941, 51.621309898385533],
            [-1.831878511040594, 51.621240205264705],
            [-1.832916149654647, 51.620704005163951],
            [-1.833630711545881, 51.620822804043939],
            [-1.83337630299234, 51.620478976732187],
            [-1.834275920664879, 51.619756449280104],
            [-1.83536247188568, 51.619657272967693],
            [-1.835962338606842, 51.617929985475037],
            [-1.837172372538042, 51.617617870996952],
            [-1.83667615119393, 51.617441852747],
            [-1.836978246338119, 51.616973825621457],
            [-1.839021510642391, 51.616690717116313],
            [-1.839914253930043, 51.615822477723775],
            [-1.839731379697859, 51.614433972836565],
            [-1.841097458638282, 51.614068082556713],
            [-1.841110203909338, 51.613311931763441],
            [-1.841673686297622, 51.613241660451685],
            [-1.842389571181032, 51.612115113293363],
            [-1.84104868611007, 51.609831316597038],
            [-1.841620442198742, 51.606951274077765],
            [-1.839847925493053, 51.607570172802454],
            [-1.839860807113126, 51.607191656358779],
            [-1.841004823213135, 51.606673511120931],
            [-1.841351996474439, 51.605251555045157],
            [-1.841272397309202, 51.606133495001174],
            [-1.842201181583152, 51.605209539297903],
            [-1.841750199753984, 51.604929303564745],
            [-1.842088360840256, 51.604014442741594],
            [-1.841700596216074, 51.603412402664979],
            [-1.840858217543362, 51.603168500269653],
            [-1.840348753018997, 51.602279467963022],
            [-1.841025694439459, 51.601929723808787],
            [-1.840963774351833, 51.601468385638455],
            [-1.839947711033217, 51.600534607641954],
            [-1.839250871405287, 51.600388896917451],
            [-1.839866137073502, 51.599917692231692],
            [-1.836858864209535, 51.59867545385427],
            [-1.834675534799601, 51.598785694999222],
            [-1.834073010826445, 51.597728366112626],
            [-1.83359321059602, 51.5978625578812],
            [-1.833627176738703, 51.597654007286998],
            [-1.832697887894659, 51.597529507597102],
            [-1.831867830575365, 51.596727196745256],
            [-1.832470995017336, 51.595627519623761],
            [-1.831814606429073, 51.595500703996599],
            [-1.83216340098138, 51.59526203314708],
            [-1.831863687997977, 51.595109651534472],
            [-1.832812357848389, 51.594264920496421],
            [-1.833041691446346, 51.593135935592464],
            [-1.832403693894213, 51.59233479958835],
            [-1.832623784706259, 51.59175786969405],
            [-1.833139782412718, 51.591182258785132],
            [-1.834071427720077, 51.591417351880615],
            [-1.834439721169283, 51.590964707943556],
            [-1.835366065304629, 51.59106940899936],
            [-1.835169943744778, 51.590216755916643],
            [-1.836392584445939, 51.59021037136926],
            [-1.836971495105182, 51.589784087162094],
            [-1.837572367848371, 51.587240370856719],
            [-1.836782557456185, 51.586916488079787],
            [-1.836934459656907, 51.586414982175619],
            [-1.835572338447022, 51.585503161571808],
            [-1.832184557168788, 51.585195366598022],
            [-1.826493241790622, 51.583768271006221],
            [-1.823635780837578, 51.584462646315295],
            [-1.822614859938139, 51.583853295428646],
            [-1.823065258033923, 51.583455657629216],
            [-1.82317666262713, 51.583012551941906],
            [-1.823772069495995, 51.582807544207093],
            [-1.8237658029425, 51.58218803106687],
            [-1.824139018024419, 51.582337846329288],
            [-1.824904260340992, 51.581874135995058],
            [-1.826346644238424, 51.57943512433846],
            [-1.830847839929458, 51.581748867144618],
            [-1.832633711431821, 51.580789348972623],
            [-1.838487016674509, 51.580027876520255],
            [-1.837585054986282, 51.57881010440542],
            [-1.838690208682127, 51.578477147686101],
            [-1.839873023310379, 51.578215318021385],
            [-1.840941586900698, 51.578848860443365],
            [-1.843155972084737, 51.578657622148953],
            [-1.842978198682142, 51.577058722611042],
            [-1.844125671317268, 51.575718739553807],
            [-1.844711572905002, 51.574010258139801],
            [-1.844040160317744, 51.572026774847181],
            [-1.844359455189069, 51.570211842079011],
            [-1.846804112425414, 51.569634210882249],
            [-1.850087239972761, 51.568096423912564],
            [-1.849854520705257, 51.567350742691779],
            [-1.847037540313427, 51.566280735242181],
            [-1.846595134902414, 51.564841539634223],
            [-1.845407443787811, 51.564100893122955],
            [-1.845689342344646, 51.562647358406466],
            [-1.845199128228688, 51.561717005738274],
            [-1.851794110187978, 51.5622937594161],
            [-1.852209665310419, 51.562711482273585],
            [-1.853563356356718, 51.562994606078789],
            [-1.854787719215901, 51.562669737770911],
            [-1.855619001132323, 51.563472791969907],
            [-1.856092790315881, 51.562821498289694],
            [-1.857594460712269, 51.563308860759768],
            [-1.860113117383497, 51.56524233144556],
            [-1.860994096241624, 51.564457530493762],
            [-1.861035785113741, 51.563794915743102],
            [-1.860573939407655, 51.563625331237041],
            [-1.86220053797771, 51.560026203323801],
            [-1.865137505158887, 51.559533284452698],
            [-1.86263036034973, 51.559561852047644],
            [-1.860156810708637, 51.558981689731901],
            [-1.849625588029625, 51.553626910782839],
            [-1.84928625433748, 51.553308181092561],
            [-1.853798130015943, 51.550490582199842],
            [-1.851606502266545, 51.548994365682006],
            [-1.850533614144183, 51.546764938345824],
            [-1.853833165539085, 51.546300635388292],
            [-1.849061804926529, 51.544410008256406],
            [-1.844385745530107, 51.543443628943933],
            [-1.844316240114145, 51.543105460098104],
            [-1.844045318683474, 51.541787860009386],
            [-1.844601528368444, 51.541474796579678],
            [-1.844586634583515, 51.54076625395853],
            [-1.843936946116819, 51.538476180116007],
            [-1.840894456666467, 51.531499256602409],
            [-1.838173424799816, 51.531700545794564],
            [-1.837310923637396, 51.529791374713881],
            [-1.839964851356586, 51.529373320785382],
            [-1.838799766719635, 51.526192359219344],
            [-1.836916363074661, 51.524013833806272],
            [-1.836363138901258, 51.521926152332469],
            [-1.836669638866917, 51.521662230452691],
            [-1.835387685269231, 51.519436858451428],
            [-1.833052608700991, 51.518725038398415],
            [-1.83108760234739, 51.516947318875566],
            [-1.830088723323676, 51.515438912071239],
            [-1.829864676212613, 51.513683456798717],
            [-1.830804990142073, 51.511142930744811],
            [-1.834725878985642, 51.511537841366419],
            [-1.835451076669052, 51.511430961731001],
            [-1.835533299894734, 51.511005781040183],
            [-1.836983277158897, 51.510902601612599],
            [-1.837584161614136, 51.512931006465934],
            [-1.837160508013499, 51.514143366859749],
            [-1.837794664962243, 51.515743822699662],
            [-1.839373000800694, 51.51729521696921],
            [-1.840847497770979, 51.518069588273796],
            [-1.840330255972137, 51.518441132370242],
            [-1.842592599892042, 51.519795598208226],
            [-1.842143682228199, 51.520413607146914],
            [-1.84383436654775, 51.52165488626126],
            [-1.842734751114889, 51.522884347618437],
            [-1.84055689981688, 51.524121331934424],
            [-1.841861536894403, 51.524899954606589],
            [-1.8421854679202, 51.526264390269169],
            [-1.846914279644378, 51.524281740275512],
            [-1.842892093629738, 51.518200018025354],
            [-1.844273214500704, 51.517218192369455],
            [-1.843312559961758, 51.516622580903011],
            [-1.842640165519968, 51.516861754039837],
            [-1.84151431650166, 51.515703941049459],
            [-1.844697192663931, 51.515014050810208],
            [-1.844152152405055, 51.513835449432442],
            [-1.844020694287699, 51.512239292449379],
            [-1.841161663208108, 51.510602614647006],
            [-1.841003280168662, 51.50973832154493],
            [-1.841352692904999, 51.509533788605388],
            [-1.839576969486836, 51.506803369047709],
            [-1.839233246092527, 51.504982128091086],
            [-1.839829003502578, 51.504777037192369],
            [-1.837593946654958, 51.500837504676419],
            [-1.834373285948277, 51.501064988598031],
            [-1.833967257169118, 51.501794522870746],
            [-1.831867324238667, 51.501247552569005],
            [-1.828359392069838, 51.5050710470097],
            [-1.824560951397517, 51.503712220521777],
            [-1.823031175084711, 51.504756532808585],
            [-1.813459486788629, 51.507041740647779],
            [-1.812927852257406, 51.505271374937671],
            [-1.813591146161182, 51.501250545111432],
            [-1.811404137541253, 51.499525186910091],
            [-1.81305575654523, 51.496535463343783],
            [-1.809486505162259, 51.494034592239814],
            [-1.806495817922642, 51.491052622044656],
            [-1.803799554458155, 51.489362244433046],
            [-1.802570673454559, 51.487766892507274],
            [-1.79780665137583, 51.484446319577984],
            [-1.795594523854086, 51.485757047348514],
            [-1.793201990256241, 51.486167366746137],
            [-1.788765294772666, 51.485121851099152],
            [-1.783614988697348, 51.485192498169873],
            [-1.779697521111488, 51.484348105264353],
            [-1.774340948856765, 51.482455112379526],
            [-1.77425468250938, 51.483887293351216],
            [-1.774728150775413, 51.484539187502975],
            [-1.766525967491183, 51.486075997701171],
            [-1.762693345527342, 51.485857014093341],
            [-1.758169204865695, 51.486235317094568],
            [-1.750785720684589, 51.485643522078185],
            [-1.746318303741956, 51.485647441785517],
            [-1.743698577354627, 51.486687464201623],
            [-1.740073069388016, 51.486780198563629],
            [-1.731086640236205, 51.488177020426271],
            [-1.726310251106689, 51.488506760889429],
            [-1.715748170971941, 51.488383655414147],
            [-1.719093119430727, 51.500705560906162],
            [-1.711764451914555, 51.501182329870169],
            [-1.705122211068218, 51.501978665744076],
            [-1.696650294803206, 51.503605221496301],
            [-1.69616619084736, 51.503174176610365],
            [-1.677779997277872, 51.503952370762839],
            [-1.67528948867439, 51.504484998132867],
            [-1.667752858650946, 51.505278539710837],
            [-1.662261677811867, 51.506884935279139],
            [-1.658165713957757, 51.507610382250476],
            [-1.649891337132771, 51.508299022396208],
            [-1.642133556904854, 51.510884060618054],
            [-1.637240179785242, 51.51358986519201],
            [-1.634305631791772, 51.512940567022376],
            [-1.632737136129842, 51.514994720344248],
            [-1.633570210129628, 51.515518829462749],
            [-1.631898844835586, 51.516373186372149],
            [-1.628478173100123, 51.517364061965225],
            [-1.624310007988517, 51.515121808934943],
            [-1.62358696572134, 51.514199663957669],
            [-1.619511601288414, 51.515240328090954],
            [-1.619952333872344, 51.51665611606645],
            [-1.615268470228347, 51.517356581532596],
            [-1.610904225970325, 51.516893522776549],
            [-1.607158516726483, 51.517413344005618],
            [-1.606943690152099, 51.517088931483045],
            [-1.602825901206881, 51.518294317471891],
            [-1.598073610618758, 51.518696247089977],
            [-1.594055981790224, 51.519778484939813],
            [-1.594136432801749, 51.520132128411603],
            [-1.592112361577435, 51.521484625389157],
            [-1.59004614279272, 51.521738181135795],
            [-1.589113369902513, 51.524489909561325],
            [-1.587928096429621, 51.524858901497964],
            [-1.584721927058816, 51.524911443368516],
            [-1.585432534104965, 51.523641660609755],
            [-1.587101083711969, 51.516047937468841],
            [-1.588078058511777, 51.513502281498532],
            [-1.587288095298037, 51.511138339673025],
            [-1.587026088255087, 51.507123611722967],
            [-1.586093445913984, 51.503031887876176],
            [-1.58567797132922, 51.502933313718131],
            [-1.58577901172676, 51.50211724100761],
            [-1.582629385089803, 51.500885035900815],
            [-1.582285199605306, 51.500240018934853],
            [-1.582076229006408, 51.499460609639193],
            [-1.582659017210996, 51.498112157164499],
            [-1.583535819868931, 51.497692670127343],
            [-1.582281324581014, 51.495775715352337],
            [-1.583420126182804, 51.495844501819462],
            [-1.582305168579057, 51.494111468948127],
            [-1.579345925603932, 51.491199333569384],
            [-1.576789516242289, 51.489309114234487],
            [-1.573619979223527, 51.487243075625535],
            [-1.570463470606177, 51.486273966079722],
            [-1.567838329871402, 51.481513195777126],
            [-1.567656820200367, 51.479843695413187],
            [-1.566895964594739, 51.478531719670976],
            [-1.566844888113488, 51.476930135140037],
            [-1.562743735389888, 51.476813320628004],
            [-1.561597427151423, 51.476378348021804],
            [-1.56115802122088, 51.475803044102349],
            [-1.56072887314813, 51.47221020827093],
            [-1.559540391727686, 51.46973846699305],
            [-1.557393232975754, 51.468014795680098],
            [-1.550955612755575, 51.465484383406171],
            [-1.549625838285154, 51.462228830108316],
            [-1.548404876970078, 51.46065600461435],
            [-1.545770041614695, 51.458991381348945],
            [-1.542195631214601, 51.457750136330127],
            [-1.538755364514875, 51.457356317500114],
            [-1.534612657581128, 51.456126092339417],
            [-1.529043337402388, 51.455512220846899],
            [-1.528864674154921, 51.45399282208863],
            [-1.524918186576781, 51.450272365506969],
            [-1.524006047271336, 51.447481268592782],
            [-1.524453772649709, 51.442887468909092],
            [-1.52400080224712, 51.441339072818295],
            [-1.532077581512429, 51.429556566412401],
            [-1.528280726399036, 51.427966022955424],
            [-1.527907124295222, 51.426812690353543],
            [-1.527341568818022, 51.426562243310308],
            [-1.526969857753732, 51.425786564160767],
            [-1.525303633198523, 51.425019139251596],
            [-1.526638082927893, 51.423930248781645],
            [-1.531701349451145, 51.425130277756807],
            [-1.5321175667872, 51.424793852906355],
            [-1.532859906402674, 51.425621345633452],
            [-1.539639363620118, 51.426113017769325],
            [-1.539869175087883, 51.425714690596251],
            [-1.541918349394314, 51.425465550348456],
            [-1.543048723771013, 51.424611257575002],
            [-1.543537672179409, 51.424761523497878],
            [-1.544430021831355, 51.423540330901389],
            [-1.545978150037504, 51.422753270575164],
            [-1.549456480135817, 51.422381840634095],
            [-1.553127422706599, 51.421277323279504],
            [-1.553677304282073, 51.422406969222237],
            [-1.556301183473072, 51.421598684582008],
            [-1.558476354557236, 51.421677926643184],
            [-1.558245953340591, 51.420514439483625],
            [-1.56033237456848, 51.420395496707407],
            [-1.563405511302847, 51.421013012731635],
            [-1.565133001747548, 51.422044477299536],
            [-1.567504731208037, 51.420500389828263],
            [-1.571179476722752, 51.416465843323493],
            [-1.570934331858671, 51.415309519881703],
            [-1.569702054902812, 51.415150349149108],
            [-1.569630056214972, 51.414701401599288],
            [-1.566810523430715, 51.414368221641794],
            [-1.566872522157079, 51.413893691331303],
            [-1.565806187209023, 51.413830403746651],
            [-1.565431600552101, 51.41330030717598],
            [-1.563505484271535, 51.413230213183958],
            [-1.562491766521726, 51.411270750741309],
            [-1.562289207388041, 51.408238906060568],
            [-1.560559460603179, 51.408554339213623],
            [-1.560569698195816, 51.408236072685426],
            [-1.559951081229318, 51.408126752418809],
            [-1.55784848823381, 51.409086347495787],
            [-1.555642697039226, 51.40940980331181],
            [-1.554345708755224, 51.410309443393594],
            [-1.55302664950499, 51.410075136360369],
            [-1.553362850374589, 51.40847590097308],
            [-1.554312734280084, 51.407636096308572],
            [-1.553703827169402, 51.406678865700215],
            [-1.554166191208275, 51.403483186333254],
            [-1.553575511469478, 51.401545031837799],
            [-1.555499774732972, 51.39908322652488],
            [-1.556145966601273, 51.39840860056286],
            [-1.556267124409785, 51.397473924274038],
            [-1.556248315294193, 51.396446103829092],
            [-1.555617015247533, 51.395585906638068],
            [-1.55333124793067, 51.394740096627821],
            [-1.549023807235331, 51.394720013879535],
            [-1.548768872877665, 51.393019603984939],
            [-1.547125123524401, 51.393262337213066],
            [-1.54734547842881, 51.391176216329612],
            [-1.546521620143574, 51.391065132886403],
            [-1.546483667333164, 51.39054256867248],
            [-1.544283019808305, 51.390140200672079],
            [-1.542638597824338, 51.390458398611436],
            [-1.542345084713656, 51.389626420678333],
            [-1.537731989864418, 51.388324310861108],
            [-1.538694716801543, 51.387051280676388],
            [-1.53834634163526, 51.385539303437128],
            [-1.535679611896581, 51.384079310760598],
            [-1.534682671237982, 51.384330723203099],
            [-1.534135587447528, 51.384148716707692],
            [-1.533315877947872, 51.383362279219156],
            [-1.52888583145295, 51.381391563476022],
            [-1.526904828349246, 51.379950307659534],
            [-1.528263101140577, 51.379207669523623],
            [-1.527880828980875, 51.378935480179663],
            [-1.525785955277406, 51.378377632122785],
            [-1.525611189312051, 51.378048727385],
            [-1.524253251452288, 51.378064805365959],
            [-1.523250360286519, 51.378619117514788],
            [-1.520685959149611, 51.379277646097279],
            [-1.518153522035507, 51.379073944050134],
            [-1.516975518192228, 51.378782261043369],
            [-1.516066535258489, 51.377641959079838],
            [-1.515450780067942, 51.377582768066794],
            [-1.514616323917328, 51.376873466136431],
            [-1.513772014132998, 51.37519750665232],
            [-1.51342035539768, 51.372047143891962],
            [-1.512336752770232, 51.371814251607489],
            [-1.510034337259824, 51.371906258588517],
            [-1.509449737481408, 51.37109006201446],
            [-1.507115906230002, 51.370899539655866],
            [-1.507020821144263, 51.37052328528867],
            [-1.504025857617706, 51.369833575457413],
            [-1.502553895157066, 51.368859827822114],
            [-1.499400362242362, 51.369028037700026],
            [-1.495464481766803, 51.369666663203304],
            [-1.495166911950261, 51.367724964065992],
            [-1.499327978844769, 51.363280204798968],
            [-1.499352187264683, 51.36145408347447],
            [-1.500038306773828, 51.360700805240839],
            [-1.499673204971667, 51.358485477333815],
            [-1.500623616427944, 51.356270363861015],
            [-1.500072466730806, 51.355581044100738],
            [-1.497423595549865, 51.354595909394533],
            [-1.495035428193743, 51.352765712559204],
            [-1.491372875386501, 51.352053914630211],
            [-1.489421090058322, 51.350399035931083],
            [-1.486827137960854, 51.349429185174742],
            [-1.485726192567769, 51.347725813401873],
            [-1.486570024256749, 51.345323312452607],
            [-1.490170194635408, 51.342024656071573],
            [-1.493038614928116, 51.337992591823209],
            [-1.497552329464234, 51.333229293838542],
            [-1.497652080967329, 51.331193079149209],
            [-1.498313464094772, 51.329378669173103],
            [-1.499777048178765, 51.330338955252202],
            [-1.502180278690344, 51.331085616277456],
            [-1.509326416494343, 51.334348302134892],
            [-1.511435363645664, 51.334995529332637],
            [-1.512603168144721, 51.336140550248295],
            [-1.514152936423359, 51.337008397785631],
            [-1.516295847332724, 51.339357822205876],
            [-1.516834047276421, 51.33896170608692],
            [-1.521289768198743, 51.338438683651752],
            [-1.522697399780263, 51.338639545917665],
            [-1.526648623270872, 51.338306688358131],
            [-1.527521212328441, 51.338468464363956],
            [-1.528477817427668, 51.334077133036324],
            [-1.52715202522626, 51.331511829639787],
            [-1.528862037590849, 51.33019962025665],
            [-1.531092665438591, 51.329196989141771],
            [-1.531894353709506, 51.328130170363906],
            [-1.532327818681912, 51.323045240532423],
            [-1.533575137204579, 51.316226328889925],
            [-1.530734142384274, 51.315378748473236],
            [-1.528510123384813, 51.31384031371946],
            [-1.52698412154207, 51.311407271978609],
            [-1.527115211561128, 51.308283142232767],
            [-1.525238337140489, 51.307069749048125],
            [-1.526390521342894, 51.305096208897147],
            [-1.526302431234152, 51.30375876733796],
            [-1.528035574978683, 51.299811151323546],
            [-1.525838632484568, 51.299207021910796],
            [-1.526475528854144, 51.297667496634396],
            [-1.525504028494851, 51.29715822492124],
            [-1.522672920401955, 51.297144927566272],
            [-1.521294276626752, 51.29758889544528],
            [-1.519825550968051, 51.296903102244116],
            [-1.519524348345873, 51.295941538282371],
            [-1.520336660551548, 51.295063665399603],
            [-1.522840667216782, 51.294105467345908],
            [-1.524159794077992, 51.293163990173277],
            [-1.522763559380974, 51.290058819714979],
            [-1.522820423859561, 51.287061165925941],
            [-1.528896277860881, 51.286481501166065],
            [-1.53143930825899, 51.286557339484752],
            [-1.53452400536948, 51.28709565887138],
            [-1.536683540707698, 51.285242004767426],
            [-1.54259637143439, 51.281253968629251],
            [-1.542766202235792, 51.280177406193964],
            [-1.536812759122225, 51.278236054953837],
            [-1.535928929799558, 51.277307293824947],
            [-1.536078691678999, 51.276814233199829],
            [-1.536897788353398, 51.276765321017834],
            [-1.537474966250093, 51.273822762354655],
            [-1.537106219916647, 51.27371250423235],
            [-1.537131517187954, 51.2707650662826],
            [-1.539122047459762, 51.267089838682466],
            [-1.540524526125262, 51.260836992011029],
            [-1.537359634791167, 51.260889276846157],
            [-1.536456163394677, 51.26180377770374],
            [-1.534379423639942, 51.261811726738792],
            [-1.530581897624714, 51.262620228108752],
            [-1.529832278818487, 51.26051761374508],
            [-1.531290000878451, 51.257674821485217],
            [-1.531246802980825, 51.255439261140431],
            [-1.533008374308242, 51.253413227155889],
            [-1.535966801812207, 51.248622404503877],
            [-1.5397259726868, 51.247368477709074],
            [-1.543138221994909, 51.24539192571833],
            [-1.545171152651411, 51.245102205156975],
            [-1.545897538292083, 51.245244398341306],
            [-1.548926221723023, 51.248022913622869],
            [-1.550218965677184, 51.248568298815364],
            [-1.552622838636681, 51.24904148755278],
            [-1.556782223497131, 51.249184985248206],
            [-1.563893498054667, 51.252323775797194],
            [-1.566972285447805, 51.252982614674551],
            [-1.572407798908535, 51.254932243365381],
            [-1.574018564588252, 51.254608109385238],
            [-1.577647404912449, 51.255634635914426],
            [-1.577811782760593, 51.254740534957712],
            [-1.578991291557126, 51.255347236991454],
            [-1.581948962385091, 51.254711312321398],
            [-1.584244458301744, 51.254720381045935],
            [-1.585360069292881, 51.253995991781487],
            [-1.58806860683398, 51.253158511204909],
            [-1.592762642043809, 51.253011271254458],
            [-1.600197737770367, 51.253527839939423],
            [-1.600752970321917, 51.251791600691703],
            [-1.600122612634271, 51.25161500786141],
            [-1.600717821700521, 51.250720546201826],
            [-1.603717024946589, 51.251852019841479],
            [-1.607437471788856, 51.252773626771933],
            [-1.606533392679476, 51.25192535581057],
            [-1.607379824468379, 51.249613682196845],
            [-1.611120174214252, 51.2456112762234],
            [-1.610404541555927, 51.242983262480585],
            [-1.613567661256928, 51.241445334001931],
            [-1.615063430514078, 51.241420585683542],
            [-1.616494416105773, 51.241951305966239],
            [-1.619346906640069, 51.241221475555086],
            [-1.619766343549751, 51.239865959036308],
            [-1.621227694779246, 51.238442778437559],
            [-1.621424127018554, 51.236324019900366],
            [-1.622148055861408, 51.234678139506165],
            [-1.623251535731115, 51.233376966913433],
            [-1.625604316649576, 51.231667054536842],
            [-1.626864750030441, 51.228645295017614],
            [-1.628521479379183, 51.226006035862596],
            [-1.631143983270031, 51.223802309621249],
            [-1.631350542038555, 51.221605331763278],
            [-1.632852869573855, 51.219412421853598],
            [-1.633552305592066, 51.217520010987485],
            [-1.637477713904899, 51.217590674752358],
            [-1.650206827938408, 51.219982572092029],
            [-1.650239673570802, 51.220183190265537],
            [-1.653507901091791, 51.220471654056837],
            [-1.660071730237598, 51.218902013368002],
            [-1.659857824318824, 51.218395146581074],
            [-1.660349937707348, 51.218258998098705],
            [-1.666747986303208, 51.216968151215134],
            [-1.666783179953443, 51.217448420575764],
            [-1.672737848801088, 51.217263790245333],
            [-1.673076728228905, 51.217125361645444],
            [-1.673030556797823, 51.215961676634251],
            [-1.673718673142071, 51.215836809254633],
            [-1.684401317365187, 51.214929120659626],
            [-1.684535534241027, 51.215192046665543],
            [-1.686899544005978, 51.215155228402317],
            [-1.689799899470636, 51.214772695422333],
            [-1.69062301483848, 51.212009852484428],
            [-1.691158160361073, 51.20733905260262],
            [-1.694076960850651, 51.203874023050922],
            [-1.685711494176536, 51.199328021874052],
            [-1.682723255046004, 51.197011728643488],
            [-1.676990929688789, 51.193405566939127],
            [-1.670565394632559, 51.19061724127463],
            [-1.6688471071674, 51.190776947942709],
            [-1.669463560343351, 51.184811612938944],
            [-1.669506007014745, 51.184277608954076],
            [-1.670175265460019, 51.183923413379723],
            [-1.669283093924561, 51.181836554771614],
            [-1.672379482877108, 51.178548795759731],
            [-1.671522582029766, 51.17814355382469],
            [-1.670379152546334, 51.176795138351075],
            [-1.666862170860676, 51.169945869170419],
            [-1.65705179079859, 51.159311461488727],
            [-1.653997974335067, 51.155974526453555],
            [-1.654623883300595, 51.152000097662054],
            [-1.659769051391282, 51.14214819581079],
            [-1.658973016124811, 51.141542512783523],
            [-1.658346718913885, 51.13943834445729],
            [-1.659447485898797, 51.135723336400744],
            [-1.660847640129657, 51.134427149639848],
            [-1.662940701332309, 51.130009998741528],
            [-1.662986878654892, 51.127197412033098],
            [-1.65628914020566, 51.127204012121616],
            [-1.640592869152132, 51.125388122921379],
            [-1.632753284421496, 51.120934284616936],
            [-1.627256416290418, 51.118302905071268],
            [-1.626261187033288, 51.117343871272702],
            [-1.628852183307054, 51.114034033269895],
            [-1.630846085283482, 51.112829104083971],
            [-1.630450312821478, 51.112120176506437],
            [-1.630942229801711, 51.111159573860327],
            [-1.629799582634485, 51.109172306126823],
            [-1.628351898951113, 51.107652546995162],
            [-1.628446124196987, 51.106054048847874],
            [-1.626814161278474, 51.104022932271164],
            [-1.626967692376083, 51.102869733236538],
            [-1.629275255614629, 51.100606556810725],
            [-1.6309986107737, 51.099586003127207],
            [-1.634004126819002, 51.096419424447184],
            [-1.636318423618098, 51.092847773376356],
            [-1.636198973017603, 51.092420275794275],
            [-1.637317745252373, 51.092146791308473],
            [-1.634101899571398, 51.085470008044226],
            [-1.631607709666614, 51.082590089929809],
            [-1.63203328441412, 51.082391803494417],
            [-1.631625703081083, 51.081941812684619],
            [-1.631142885051898, 51.082157901487996],
            [-1.629009270627351, 51.08118270077258],
            [-1.627739846185633, 51.078017027417388],
            [-1.629158283707396, 51.075574766169872],
            [-1.628969032441471, 51.074071576104963],
            [-1.628087959952833, 51.072338686261297],
            [-1.627821354888136, 51.068739622648287],
            [-1.63010560559743, 51.065421112721992],
            [-1.63283419843905, 51.04917273443219],
            [-1.632439563671948, 51.047662603753842],
            [-1.633596581046606, 51.04656558835736],
            [-1.634014328974601, 51.042064498476513],
            [-1.634736983437999, 51.041585674272369],
            [-1.634972065487386, 51.040893108717633],
            [-1.632083787339575, 51.036020169273193],
            [-1.632387466758565, 51.032785722685503],
            [-1.628861415611282, 51.032523703102164],
            [-1.622452104756962, 51.033112860755928],
            [-1.619469094535483, 51.033065425374453],
            [-1.619299661218326, 51.031638706585582],
            [-1.614653192799507, 51.02996442195834],
            [-1.613555167668725, 51.028420429007276],
            [-1.611458488702355, 51.027288552880471],
            [-1.608419574431073, 51.026305456358202],
            [-1.602861118879202, 51.025509781909655],
            [-1.599408694414446, 51.023735526228222],
            [-1.60045059027972, 51.022285044521006],
            [-1.601855928978494, 51.021340256447068],
            [-1.605663796317485, 51.020027701968338],
            [-1.605379287049846, 51.017264322298743],
            [-1.604946333685481, 51.01603811519584],
            [-1.603141534963929, 51.014202079207898],
            [-1.599532118886599, 51.012545987693997],
            [-1.597662001089398, 51.010721331774725],
            [-1.597535333669433, 51.008061882118852],
            [-1.600587731041551, 51.007142548245803],
            [-1.605025807060128, 51.00701194978199],
            [-1.606881912683935, 51.00781222242707],
            [-1.60856495221705, 51.009708043398383],
            [-1.609099959006963, 51.009819538845257],
            [-1.608635430096267, 51.008276710506316],
            [-1.610302551571975, 51.008168976108841],
            [-1.610463037923943, 51.006875523475706],
            [-1.614941646778325, 51.006662853815143],
            [-1.609667127969431, 51.005926882356022],
            [-1.605209229176578, 51.002527223143865],
            [-1.606089549403258, 51.001904329804013],
            [-1.606794479175572, 51.001627942022559],
            [-1.610744214957245, 51.001715794314372],
            [-1.614381614828254, 51.003381526855719],
            [-1.617242455206601, 51.004188541647522],
            [-1.620213170460969, 51.003419497958234],
            [-1.62382753406685, 51.001821565216098],
            [-1.624418576148458, 51.001163432306306],
            [-1.626269372341533, 51.001039874048494],
            [-1.627169465245643, 51.000044605048302],
            [-1.628815834607095, 50.99910115804699],
            [-1.628383496674586, 50.998633984747215],
            [-1.628812706156306, 50.998242385570784],
            [-1.628172558039264, 50.99810276968703],
            [-1.628391183591388, 50.997670936114574],
            [-1.62737200224037, 50.996995969066504],
            [-1.627459512281394, 50.995854228155196],
            [-1.625435050031544, 50.994033117041468],
            [-1.625813571081031, 50.993918328604508],
            [-1.624933833919805, 50.991159373392101],
            [-1.625223985786326, 50.990337509232305],
            [-1.624553068958998, 50.989787726034486],
            [-1.623931209854854, 50.989869355699888],
            [-1.620436569458841, 50.988605435763283],
            [-1.618964927214147, 50.987373200176762],
            [-1.619318641870165, 50.986976892920666],
            [-1.618926234590153, 50.986356045127572],
            [-1.619305548030237, 50.984918512904038],
            [-1.620169998216412, 50.98425589436853],
            [-1.619653123062974, 50.983003384539145],
            [-1.617064364812069, 50.981656878380939],
            [-1.615800421352057, 50.980495421293924],
            [-1.614146453320111, 50.979996296044021],
            [-1.614429742715811, 50.979501754449331],
            [-1.610844613034085, 50.979986250359033],
            [-1.607592667630814, 50.979971782687429],
            [-1.604501277918674, 50.979334600533143],
            [-1.602934125811773, 50.978528972016584],
            [-1.605150004911259, 50.97691066700753],
            [-1.604973560826234, 50.97520962355739],
            [-1.6077396478144, 50.973681251561011],
            [-1.608699902226727, 50.972289760776803],
            [-1.610801299768163, 50.972041394291757],
            [-1.612190779612233, 50.97125378861864],
            [-1.613437581468982, 50.968088123468846],
            [-1.61467298378814, 50.966108494511978],
            [-1.616074410741804, 50.965051111526599],
            [-1.616758543897982, 50.962552588400314],
            [-1.619761327029681, 50.958574292912971],
            [-1.623409558652419, 50.954644759688527],
            [-1.628500808339324, 50.957132148747924],
            [-1.634975205116732, 50.959249560533841],
            [-1.640387999832552, 50.955454485787563],
            [-1.641107483689443, 50.953169044842433],
            [-1.641724764806385, 50.952503706485317],
            [-1.646385929287301, 50.949473091912857],
            [-1.649657306773473, 50.948513567856061],
            [-1.651859479980584, 50.947177589735553],
            [-1.661669498861377, 50.945285630968264],
            [-1.667309360978774, 50.947037343755724],
            [-1.669191160142282, 50.947240516398068],
            [-1.676890918673925, 50.949674700546751],
            [-1.685720589008866, 50.952927023648627],
            [-1.689308108336714, 50.954645157407711],
            [-1.697660365424968, 50.960440131893634],
            [-1.701035900613589, 50.962732862398568],
            [-1.709643338697963, 50.971237903094909],
            [-1.716514291101788, 50.974181758219999],
            [-1.718723732451501, 50.976008933590286],
            [-1.719705868030923, 50.97611919956779],
            [-1.719626353809851, 50.976788035725995],
            [-1.72402950063351, 50.976957674149133],
            [-1.726196511394934, 50.977351235465768],
            [-1.729897242652685, 50.977321175369802],
            [-1.733723875159664, 50.976445110050115],
            [-1.735266619912316, 50.976416240608572],
            [-1.73981343372719, 50.976624276109064],
            [-1.742954264609427, 50.977121302049603],
            [-1.74394792274031, 50.977482274362089],
            [-1.743943167047601, 50.977829366344267],
            [-1.747110387727394, 50.977948661994411],
            [-1.754410720479291, 50.977899476332162],
            [-1.754328879283934, 50.980454912177805],
            [-1.765646677495165, 50.982945620642916],
            [-1.769849220033612, 50.983502494819199],
            [-1.769994307653599, 50.983835494481923],
            [-1.775363580447924, 50.984719079804002],
            [-1.775799031593313, 50.984818830316598],
            [-1.775619272224697, 50.985168285086957],
            [-1.78384269593243, 50.986905791080098],
            [-1.787954342706613, 50.988152451433152],
            [-1.790960336887728, 50.990092997460472],
            [-1.796022064266075, 50.990207142429561],
            [-1.800322627865985, 50.991401540547379],
            [-1.805678950835691, 50.991637164975252],
            [-1.807592198656371, 50.992052172653764],
            [-1.815430243007813, 50.98592937858826],
            [-1.81986408939443, 50.990264266062184],
            [-1.821553385896785, 50.992997803657509],
            [-1.827420792005467, 50.99727972312423],
            [-1.835821081425374, 51.009428635409975],
            [-1.842279798962369, 51.008474449229247],
            [-1.852812508820858, 51.005034250970148],
            [-1.85368792884565, 51.00447603139456],
            [-1.855898811636087, 51.001589567800032],
            [-1.862057157499132, 50.9966476556678],
            [-1.866836043880291, 50.990828907803412],
            [-1.870803035852388, 50.987614124459625],
            [-1.873271965961827, 50.985018958353272],
            [-1.873991516763833, 50.984447826971234],
            [-1.874244900558222, 50.987690709452352],
            [-1.875107391739567, 50.991742644593941],
            [-1.874640382627982, 50.995858798001905],
            [-1.874656692143835, 51.003573259725883],
            [-1.874375354947038, 51.005373203567252],
            [-1.873794353835536, 51.006242127666987],
            [-1.874489628773772, 51.006337293417261],
            [-1.875438945185081, 51.00470531763785],
            [-1.879239105191565, 51.00395484985507],
            [-1.882208078204185, 51.00271424768291],
            [-1.883316768794222, 51.002178521046822],
            [-1.885288195154235, 51.000257028795495],
            [-1.886749237160908, 50.999534575378071],
            [-1.888803127243279, 50.999975364798964],
            [-1.891580395612807, 50.999451023287584],
            [-1.892474933714206, 50.999624498924284],
            [-1.89535015942275, 50.999237738970599],
            [-1.898024890464757, 50.998629524742768],
            [-1.903306012988394, 50.999345288910789],
            [-1.91231519284126, 50.99936226832456],
            [-1.920342989716522, 50.997430170903499],
            [-1.923750333548717, 50.997324529584461],
            [-1.927883658703754, 50.997703919465103],
            [-1.935535636876579, 50.992763538714101],
            [-1.936583161629205, 50.991524977530169],
            [-1.949943552878833, 50.982317068525063],
            [-1.953933707576688, 50.988077344598466],
            [-1.95682852515187, 50.989836429352174],
            [-1.962644230935118, 50.991859878527684],
            [-1.965365516333237, 50.992043253405505],
            [-1.968063722726693, 50.990578279108114],
            [-1.971996763590142, 50.989529883183891],
            [-1.972986688546237, 50.987539228770238],
            [-1.97525513104833, 50.986916562690077],
            [-1.977379871261947, 50.98577587098989],
            [-1.979479877783592, 50.985580222686885],
            [-1.980428955652579, 50.984927545302227],
            [-1.983389477443129, 50.984440616471275],
            [-1.985217139684103, 50.984761881588732],
            [-1.986056442034096, 50.984057888816395],
            [-1.985892847226732, 50.983274643499669],
            [-1.98690738338877, 50.982381827517678],
            [-1.989797943974917, 50.981566510698258],
            [-1.990108619528262, 50.980928985046098],
            [-1.989595905056147, 50.980455948436756],
            [-1.989235870304956, 50.978866982790791],
            [-1.990292869693265, 50.978510079877715],
            [-1.991227536975368, 50.977150520250305],
            [-1.992260255773745, 50.976869132753762],
            [-1.992999619719502, 50.976063470016392],
            [-1.994043704207479, 50.975734408301335],
            [-1.999055942634915, 50.975271442771643],
            [-2.000675384602384, 50.974786757291199],
            [-2.002709296373063, 50.974850568358079],
            [-2.003820226522921, 50.974516022108304],
            [-2.004986739515645, 50.974639169668642],
            [-2.007067771120395, 50.975454656964402],
            [-2.00972694595138, 50.975263823530021],
            [-2.012307518412955, 50.974171911954834],
            [-2.014018001526627, 50.9738956524183],
            [-2.014879448892579, 50.973155479425202],
            [-2.017427064842804, 50.972164172156816],
            [-2.020286938813082, 50.972232944553703],
            [-2.02195631810723, 50.972621105402972],
            [-2.023730866296192, 50.972517343135387],
            [-2.028974065844179, 50.971185290838079],
            [-2.032861172367792, 50.971857775068415],
            [-2.034460993935353, 50.972431017926667],
            [-2.037215094589595, 50.971976056569936],
            [-2.038345459837694, 50.971415469297185],
            [-2.038545722191996, 50.970755369418924],
            [-2.041232188163065, 50.968073845841658],
            [-2.051084847424659, 50.966158169574292],
            [-2.05156356022998, 50.966369276964265],
            [-2.053326382555672, 50.966239891748145],
            [-2.058334334013645, 50.963649505067451],
            [-2.059411211287659, 50.96281897103664],
            [-2.058433957451756, 50.961346528025999],
            [-2.058606776971549, 50.960637847936233],
            [-2.059324755587408, 50.959795804609811],
            [-2.062379038090177, 50.959893121779508],
            [-2.062538820191253, 50.959067542756003],
            [-2.064278982859631, 50.957191698945692],
            [-2.0648681881494, 50.953968529037923],
            [-2.067289591616771, 50.951826983559286],
            [-2.068319358546605, 50.95120051839482],
            [-2.082870072000194, 50.951344836248254],
            [-2.083890116483262, 50.950974521602419],
            [-2.085440027646708, 50.949227985958309],
            [-2.087375969127963, 50.949957624699131],
            [-2.08922554877308, 50.950162146220578],
            [-2.090922579605471, 50.94951428600902],
            [-2.094151820238561, 50.949141236646874],
            [-2.100259926805544, 50.945077005097851],
            [-2.101798433533554, 50.944992042116567],
            [-2.10286735647819, 50.945614271203119],
            [-2.102661663108521, 50.946576631271299],
            [-2.103965091365783, 50.948923370332999],
            [-2.105563331433281, 50.950015399018682],
            [-2.111046725269982, 50.955927243999341],
            [-2.115647883805103, 50.957509910790876],
            [-2.11946777779369, 50.95968669153357],
            [-2.120677176542322, 50.961545952863602],
            [-2.121529446102046, 50.964021546402776],
            [-2.121519296394734, 50.965041283365061],
            [-2.120884534812334, 50.965721760921433],
            [-2.120657732431953, 50.969414230760073],
            [-2.123162957144769, 50.969474556551788],
            [-2.122719819446294, 50.973162760302912],
            [-2.119770344307202, 50.978197008238702],
            [-2.121981124274588, 50.978233382485769],
            [-2.122349616009903, 50.978610671834872],
            [-2.126859705972461, 50.978745231269421],
            [-2.13169571021208, 50.979776677832582],
            [-2.138402436946912, 50.982293939407519],
            [-2.143583198588018, 50.984970967931098],
            [-2.148749646003751, 50.986550727870139],
            [-2.152529317520455, 50.989164392553846],
            [-2.159644130497925, 50.992788642481493],
            [-2.161847949051842, 50.99348160588891],
            [-2.161532475816167, 50.994135780061519],
            [-2.163619524099164, 50.994418824851344],
            [-2.163588654701908, 50.996173256487261],
            [-2.164441915542528, 50.996091126487201],
            [-2.167088128068208, 50.998089940042355],
            [-2.169312003817676, 51.000667508138982],
            [-2.171804333359958, 51.001007368025732],
            [-2.172571308464053, 51.001079073172775],
            [-2.17297579445571, 51.002161140192634],
            [-2.176093501233503, 51.005409868396121],
            [-2.176420373121065, 51.006664690789997],
            [-2.178106927269184, 51.008267242184971],
            [-2.17628270838695, 51.011700548568989],
            [-2.176393885711042, 51.012824408894502],
            [-2.178766655226811, 51.014139057798353],
            [-2.185182307737688, 51.015781822827989],
            [-2.183827655017255, 51.017488889883637],
            [-2.185997269944468, 51.018158069750925],
            [-2.188609154176754, 51.018538744312174],
            [-2.188841854493921, 51.025276233324398],
            [-2.190143207048923, 51.026558207404648],
            [-2.190324901295362, 51.027401380102177],
            [-2.190293810687636, 51.028512867217437],
            [-2.189345312612436, 51.029502653008436],
            [-2.189188360355917, 51.030528917651374],
            [-2.190553915682343, 51.032127307354287],
            [-2.192001329664727, 51.03625325161326],
            [-2.193213989659645, 51.037718775836574],
            [-2.194723454248211, 51.038859174388065],
            [-2.195176115797381, 51.039664118267702],
            [-2.198129942414913, 51.041660801907796],
            [-2.201536640769945, 51.04277449628978],
            [-2.201440309607127, 51.043255744310414],
            [-2.205328101244476, 51.042800260292267],
            [-2.207221574811243, 51.045820085375013],
            [-2.210619969787166, 51.047188907081598],
            [-2.212445073824254, 51.050117947980908],
            [-2.215118958767887, 51.05174333202541],
            [-2.21707655629867, 51.053617273246644],
            [-2.217998454470693, 51.055184688446396],
            [-2.223274186991676, 51.056568503662128],
            [-2.225650051090507, 51.057492826394849],
            [-2.226789429460224, 51.058522918942984],
            [-2.227662372149045, 51.060204552378416],
            [-2.228896705573359, 51.060791125428111],
            [-2.23684375889192, 51.06673527551974],
            [-2.238830511748318, 51.06788042551198],
            [-2.238988653697094, 51.068657922727105],
            [-2.240169729043959, 51.069912601563395],
            [-2.244693879671023, 51.07231670053595],
            [-2.247650687287003, 51.072212453125175],
            [-2.251314269845631, 51.070843215766743],
            [-2.268624712846163, 51.068747277431953],
            [-2.28341594751713, 51.075256805176757],
            [-2.291273783368343, 51.077081762119313],
            [-2.293269919797078, 51.076945481932576],
            [-2.298685617831589, 51.078492794501898],
            [-2.300216276148102, 51.078552714603433],
            [-2.303354307368264, 51.079268474442678],
            [-2.30643826230213, 51.079563459755583],
            [-2.309476175200088, 51.079157098702787],
            [-2.310884017938883, 51.080268385795883],
            [-2.313304009126153, 51.079890544682847],
            [-2.315709065901035, 51.080050423875306],
            [-2.316087030506381, 51.079792226478268],
            [-2.320624918176698, 51.080996495952164],
            [-2.325856279144683, 51.079681756317086],
            [-2.326362630620318, 51.080027439503233],
            [-2.327133117869065, 51.079770806675555],
            [-2.327382235247255, 51.080075841670499],
            [-2.32753388934803, 51.082547356359065],
            [-2.328809000492584, 51.083588660851213],
            [-2.329143552990854, 51.085264752335981],
            [-2.330494527735423, 51.085316689392499],
            [-2.335708268603327, 51.086665030990268],
            [-2.337124042020625, 51.086785944692664],
            [-2.33902052426257, 51.086454941245009],
            [-2.339401996186903, 51.08707608939882],
            [-2.340418623684306, 51.086890591106147],
            [-2.340672864051121, 51.087486927782443],
            [-2.341616302277362, 51.087831269243637],
            [-2.3453264559123, 51.088319424868061],
            [-2.3470535103995, 51.088242370103409],
            [-2.353510977622283, 51.094548074261731],
            [-2.35440948460813, 51.096454380375206],
            [-2.360113889787465, 51.099311719364103],
            [-2.360871411011686, 51.09902343272293],
            [-2.362531295438656, 51.101629605784737],
            [-2.362836821795484, 51.103438770413419],
            [-2.363453526096438, 51.104309988045571],
            [-2.362293255019468, 51.105341389799683],
            [-2.362174165954654, 51.106916277529535],
            [-2.360779385216043, 51.108844004153546],
            [-2.362616425261681, 51.112502599983991],
            [-2.363453367973014, 51.113389318528135],
            [-2.362884974327165, 51.11578118456454],
            [-2.365591762551966, 51.114995830436179],
            [-2.364529491254788, 51.118884632933323],
            [-2.363708300815675, 51.119472576755257],
            [-2.361658849953379, 51.119759490578687],
            [-2.361145220801791, 51.120218776358975],
            [-2.359517866456515, 51.120589772197356],
            [-2.357657886144906, 51.120813987313852],
            [-2.357330016678369, 51.120356393875156],
            [-2.356788354570158, 51.120336467892727],
            [-2.352540904443161, 51.122816786872114],
            [-2.350812248702975, 51.123228431158374],
            [-2.350215334377811, 51.122897514526997],
            [-2.349311625856971, 51.122998233033563],
            [-2.347462247853637, 51.123702431164482],
            [-2.346532118099664, 51.123712386702742],
            [-2.344541769828549, 51.124684021162331],
            [-2.343189070635844, 51.127446770410607],
            [-2.3440343237982, 51.128695981658737],
            [-2.346307259975607, 51.130516457121018],
            [-2.345861310812211, 51.133374557537564],
            [-2.341399610806091, 51.135339853246251],
            [-2.332177144918767, 51.13798941358084],
            [-2.33090894236201, 51.140931614180211],
            [-2.332290137920765, 51.144963326316528],
            [-2.328872710725117, 51.148465501351779],
            [-2.32241705648268, 51.154092718438498],
            [-2.319530456426576, 51.158260356249663],
            [-2.316995524516674, 51.159357091514686],
            [-2.316259112902876, 51.159988527706716],
            [-2.316280346316317, 51.160583741839268],
            [-2.315320252842175, 51.160922637268719],
            [-2.312733283000311, 51.163030122162652],
            [-2.311230830115802, 51.167150674382071],
            [-2.310126535373449, 51.168405296611944],
            [-2.308888497019629, 51.169346440709411],
            [-2.30566354643763, 51.170698323291916],
            [-2.303869230684823, 51.172770261929628],
            [-2.298468833328037, 51.175328010983975],
            [-2.297318824103311, 51.178231757402145],
            [-2.29778826980978, 51.180040649123129],
            [-2.293063198468881, 51.183917343752363],
            [-2.291575436501824, 51.185763519913422],
            [-2.29101609324599, 51.188276371548184],
            [-2.28850035185095, 51.188006553739662],
            [-2.288137965651337, 51.188175597357386],
            [-2.288127692716554, 51.189510030861982],
            [-2.289058135301174, 51.191164953369984],
            [-2.288135579274793, 51.191690563223581],
            [-2.28841200008532, 51.192187137213359],
            [-2.284822868591915, 51.193085241934575],
            [-2.284934095177877, 51.194644176860187],
            [-2.284053110964658, 51.195665109671218],
            [-2.284537217099878, 51.19735352102618],
            [-2.281342085935777, 51.197460165431622],
            [-2.280868218803429, 51.197905507391894],
            [-2.280876685190039, 51.199296542122653],
            [-2.280358825794226, 51.19933645055054],
            [-2.28017006963089, 51.201249490802589],
            [-2.279626959118533, 51.202086143032766],
            [-2.278639059792041, 51.201799860235319],
            [-2.276460776103999, 51.205647289309262],
            [-2.275856113616102, 51.205546209461644],
            [-2.274641595355921, 51.206634396014834],
            [-2.274785395485676, 51.20698474374089],
            [-2.272943248672383, 51.20846013987282],
            [-2.273253731611764, 51.209400870253631],
            [-2.272040847206205, 51.209579940660767],
            [-2.27132317485495, 51.210960071717963],
            [-2.269476093851982, 51.212617060467274],
            [-2.269183245912019, 51.213462077163015],
            [-2.266668374127658, 51.21553868080624],
            [-2.26670465932471, 51.216614032076151],
            [-2.269391165520954, 51.217685105246311],
            [-2.269765152603558, 51.21895839807825],
            [-2.269014917085417, 51.220438397989696],
            [-2.267619614986177, 51.221065638976413],
            [-2.267555304362284, 51.221827401376771],
            [-2.268130036508754, 51.222411458422769],
            [-2.267967477392316, 51.224006996380893],
            [-2.267027442595619, 51.226366825601779],
            [-2.265563968280559, 51.228122681079917],
            [-2.26269481385051, 51.233540487680067],
            [-2.262785721449664, 51.233909850320778],
            [-2.263768089269484, 51.234377914594731],
            [-2.261963892909272, 51.235979829872605],
            [-2.260792841261708, 51.239126012003908],
            [-2.257859271116162, 51.241703297519173],
            [-2.254705821168694, 51.24317858290582],
            [-2.255000342910228, 51.243330802976267],
            [-2.252435517180554, 51.246377420626743],
            [-2.252864965898703, 51.247356607024656],
            [-2.248156878073356, 51.251042569122262],
            [-2.245345034313629, 51.25388093944774],
            [-2.247810754475304, 51.256483379828893],
            [-2.248123674484308, 51.25817678216243],
            [-2.249586823220742, 51.258971246254262],
            [-2.248928416004602, 51.25938537565154],
            [-2.248609009383267, 51.260213305569991],
            [-2.248925029061541, 51.260349309511653],
            [-2.247922881930351, 51.260807325160222],
            [-2.247969294494356, 51.261442051441534],
            [-2.244434046650488, 51.263123776069499],
            [-2.245127725898095, 51.264202244959783],
            [-2.244715447112684, 51.267530090350938],
            [-2.245096226575415, 51.272283277226826],
            [-2.247399409022567, 51.275403089451281],
            [-2.247273293215769, 51.277811367416348],
            [-2.248595767520746, 51.280318182046621],
            [-2.250619439742227, 51.282809992775874],
            [-2.251061517382994, 51.285259314647561],
            [-2.253499993315347, 51.289838979996794],
            [-2.255587100267728, 51.289428907977886],
            [-2.257441027544439, 51.291688080511513],
            [-2.260984861477477, 51.29071181048959],
            [-2.265289411167064, 51.292862859173937],
            [-2.262992220601327, 51.295169048195291],
            [-2.264971445331808, 51.296169865407435],
            [-2.267064553151498, 51.296020338036868],
            [-2.268346929647125, 51.295067869445347],
            [-2.269850356390991, 51.294645393325972],
            [-2.272966917287706, 51.294900723863819],
            [-2.275836933206029, 51.29349936351943],
            [-2.277561771498795, 51.293188663521583],
            [-2.279102434849333, 51.292309196197174],
            [-2.279996956364627, 51.292249511199614],
            [-2.279708617258394, 51.293179054609688],
            [-2.280701869279682, 51.293549835691884],
            [-2.279669611063487, 51.294075630899933],
            [-2.279963583243501, 51.294777187746377],
            [-2.279499146608216, 51.295524617885533],
            [-2.280179961741494, 51.297101050669085],
            [-2.281494767875597, 51.298674157112472],
            [-2.281250044812428, 51.298997551199001],
            [-2.280527989596946, 51.298900374914851],
            [-2.279864966884359, 51.299311089544673],
            [-2.279822285296476, 51.300311079382176],
            [-2.27879635003613, 51.300960038607094],
            [-2.278113224992729, 51.302560405210151],
            [-2.276781758172956, 51.303937508132044],
            [-2.277123188673507, 51.30441776066187],
            [-2.278594032254969, 51.30497265778093],
            [-2.278380566846506, 51.305491992012172],
            [-2.281093000312163, 51.306624766494075],
            [-2.28226368511942, 51.30640524617251],
            [-2.28203928042515, 51.306772652183049],
            [-2.281158358178411, 51.306990575141931],
            [-2.281264571050761, 51.307233997254052],
            [-2.283547521991983, 51.30778687704403],
            [-2.283123281673494, 51.308317520426741],
            [-2.284694942799071, 51.3086724791454],
            [-2.285069965366348, 51.309461044623326],
            [-2.283279026968854, 51.310776395814486],
            [-2.281559706587167, 51.310918119821707],
            [-2.27921474809062, 51.310334777398538],
            [-2.27827109289303, 51.310631056598737],
            [-2.276655036894353, 51.310530584114623],
            [-2.275806700336001, 51.312369607035414],
            [-2.273666062452631, 51.312849398974159],
            [-2.271957001169071, 51.31425789879534],
            [-2.273124922187885, 51.316913145939075],
            [-2.274711139378207, 51.317746566382404],
            [-2.275521742913512, 51.318684300221506],
            [-2.275139275492205, 51.319034080672147],
            [-2.272333688250311, 51.31850383543356],
            [-2.275211277186231, 51.32219091950742],
            [-2.273097268393699, 51.322882842754552],
            [-2.275105267598944, 51.324618044573377],
            [-2.274939874335349, 51.325038348329386],
            [-2.277749603287951, 51.325707893298926],
            [-2.278418376249541, 51.325469820890468],
            [-2.277765231064043, 51.3244948681903],
            [-2.279269151054932, 51.324717881103496],
            [-2.281266992030274, 51.324039613584176],
            [-2.282160260995522, 51.32531159534534],
            [-2.285164456757493, 51.324459088021982],
            [-2.289093195113625, 51.325275791300044],
            [-2.288485229082233, 51.325817696251711],
            [-2.288626560578769, 51.326840609705563],
            [-2.287244351550183, 51.328427458490069],
            [-2.288441190072758, 51.329775076014968],
            [-2.290587996974664, 51.330623981565004],
            [-2.295081399163236, 51.330986795240527],
            [-2.300118956605321, 51.332242721642153],
            [-2.300929199516547, 51.333226136844935],
            [-2.300974239096466, 51.333966041882263],
            [-2.299554299322244, 51.336117812729093],
            [-2.298595860703588, 51.336188599091159],
            [-2.296123380040337, 51.337210947716578],
            [-2.294862107098969, 51.336848168186165],
            [-2.294442752016374, 51.337266440732002],
            [-2.294341742463563, 51.337632658652367],
            [-2.295556384628809, 51.337884963110618],
            [-2.29690395159337, 51.338711490024146],
            [-2.296556793701169, 51.339194327356836],
            [-2.29520050139944, 51.339691402424201],
            [-2.295019666495501, 51.340145941103216],
            [-2.296411239583475, 51.342665506074312],
            [-2.297945117241504, 51.343195720515645],
            [-2.299047759454778, 51.342318012861114],
            [-2.299865896625428, 51.342727741115716],
            [-2.302140603557415, 51.341725607175213],
            [-2.303160411655429, 51.340930798261923],
            [-2.305918660779595, 51.341638464765822],
            [-2.306246703794388, 51.341099901061604],
            [-2.306558951144513, 51.341206984518884],
            [-2.308530367344021, 51.33975413467946],
            [-2.310637540279457, 51.340350108704762],
            [-2.311468850229617, 51.341210207263948],
            [-2.31646359348322, 51.34373337877134],
            [-2.320456272041857, 51.346812075812686],
            [-2.324049645837845, 51.347763399525697],
            [-2.327571705081188, 51.347151150606422],
            [-2.329074030547274, 51.347414890583032],
            [-2.333453128519905, 51.346203001152467],
            [-2.338599228595576, 51.346232288662634],
            [-2.339307367457165, 51.345494714207874],
            [-2.341139696534757, 51.344566836467031],
            [-2.342453632884929, 51.344982914209083],
            [-2.343590538020456, 51.344954405904481],
            [-2.344281640779305, 51.345404659838557],
            [-2.344020245549345, 51.346351359075847],
            [-2.343450394686297, 51.34675676129492],
            [-2.343686600885819, 51.347046501117013],
            [-2.34271484772266, 51.347278639292448],
            [-2.342319414279074, 51.348356106847099],
            [-2.341642702463381, 51.348685382798678],
            [-2.340592115940836, 51.348555366488043],
            [-2.340598143022235, 51.348980657882663],
            [-2.339917419400149, 51.34915887439746],
            [-2.339152083692102, 51.350129503553532],
            [-2.338585465931543, 51.350206674267987],
            [-2.338113016149116, 51.350787107116098],
            [-2.337411527149323, 51.350684827027138],
            [-2.337431385366041, 51.351432881556171],
            [-2.336372568015026, 51.351754235836303],
            [-2.33556954040645, 51.351513763843741],
            [-2.333846992833758, 51.351991653664271],
            [-2.332681694425354, 51.351693748239349],
            [-2.328315500611982, 51.35418329469546],
            [-2.32729619967033, 51.354022499589462],
            [-2.325406977212312, 51.354310106331631],
            [-2.324744719166467, 51.355087930758486],
            [-2.323712636498581, 51.355559257780449],
            [-2.322985107736841, 51.356660055146328],
            [-2.321328214742341, 51.35712588977384],
            [-2.320766819541889, 51.356528581086415],
            [-2.318727080452625, 51.356463117746344],
            [-2.316724081692917, 51.356934324904891],
            [-2.314782139013603, 51.35689100753217],
            [-2.312620762350643, 51.357522622707464],
            [-2.311730882841579, 51.356966610340265],
            [-2.310216756909545, 51.357538009634389],
            [-2.310874040961876, 51.360648296983967],
            [-2.310409913381315, 51.361889484956755],
            [-2.309326754384321, 51.363563909851507],
            [-2.30752085334257, 51.365000146502773],
            [-2.302976365974974, 51.362858483349129],
            [-2.300070181055943, 51.3621196573365],
            [-2.296507940579493, 51.36430472594207],
            [-2.294044634972471, 51.366777361783576],
            [-2.294308231094432, 51.366890893930652],
            [-2.292058609429751, 51.369573354875286],
            [-2.291093490788838, 51.371872235981421],
            [-2.290290883634086, 51.372624135537784],
            [-2.290798015488161, 51.37557035086337],
            [-2.291664982892611, 51.377024846450695],
            [-2.291520391046121, 51.378010694541615],
            [-2.292544844632451, 51.378464916408298],
            [-2.293702545004245, 51.381100175489024],
            [-2.297496290325967, 51.384921948356947],
            [-2.296285305819344, 51.387430996346083],
            [-2.295823922909609, 51.390536084541985],
            [-2.296790596182976, 51.394890095653416],
            [-2.29402254129405, 51.395595730263771],
            [-2.291653130750334, 51.395489260235301],
            [-2.290171775291666, 51.396027943777185],
            [-2.287358978573703, 51.396043872122732],
            [-2.284439370239968, 51.396914199042115],
            [-2.282913800980359, 51.396831575199975],
            [-2.281815225810488, 51.397230756025706],
            [-2.2845334532915, 51.397945312327593],
            [-2.286588178053011, 51.40057934895151],
            [-2.287358978922189, 51.402690495468818],
            [-2.290864263741021, 51.405714720385014],
            [-2.288805293902358, 51.406587509118133],
            [-2.288121099054008, 51.407524324290023],
            [-2.286385965035644, 51.407553757131019],
            [-2.285392233676114, 51.407971596525179],
            [-2.284751467254298, 51.408739243250935],
            [-2.283975468187929, 51.409033355558265],
            [-2.284089892764255, 51.409400835624623],
            [-2.282050779231491, 51.410277053371956],
            [-2.281696063487884, 51.410812908930012],
            [-2.28237861235799, 51.411212291551031],
            [-2.282111408872568, 51.411950248764505],
            [-2.282696399908866, 51.411915569031088],
            [-2.282883385543886, 51.412391673547667],
            [-2.282364943256756, 51.412726513969176],
            [-2.280818783280306, 51.412888484401329],
            [-2.280533811411767, 51.413313571964004],
            [-2.279668043022072, 51.413523349208184],
            [-2.280262387672249, 51.41360375206213],
            [-2.27937236527932, 51.414081535434022],
            [-2.279774028649468, 51.41439618238585],
            [-2.278543589103623, 51.415884530854555],
            [-2.280502072727265, 51.417756406469856],
            [-2.280733343484041, 51.418887896480136],
            [-2.282395463441889, 51.421406047313823],
            [-2.284745022953395, 51.423427066813836],
            [-2.286499523080991, 51.423408401431665],
            [-2.287258786508611, 51.424078214637319],
            [-2.290719269619694, 51.425452584937105],
            [-2.292987088647933, 51.426073648643744],
            [-2.295352812283584, 51.426254732196895],
            [-2.294618427530402, 51.428803902661436],
            [-2.288947385240998, 51.446422655645826],
            [-2.288043211919869, 51.454564946706327],
            [-2.28526642286627, 51.457930087606776],
            [-2.286751977305216, 51.457044389303327],
            [-2.287756939821552, 51.456871984716607],
            [-2.289247200223963, 51.457653277484717],
            [-2.293338503405247, 51.457786070401518],
            [-2.295745891831637, 51.458591955269135],
            [-2.294596651842311, 51.46384951269205],
            [-2.294583914219255, 51.466787082092559],
            [-2.294008048973945, 51.467229114108498],
            [-2.292823305330111, 51.467459567101834],
            [-2.293403919942525, 51.468652190968001],
            [-2.291618967548494, 51.472968987424501],
            [-2.291248278056227, 51.47557475553841],
            [-2.290343517715527, 51.477076787574973],
            [-2.288854257961864, 51.47815135894006],
            [-2.289636152041779, 51.478834582804822],
            [-2.290589108689356, 51.485353770822741],
            [-2.291340367963949, 51.486629599152913],
            [-2.290594036715441, 51.486581099898878],
            [-2.290561239999229, 51.486859018891259],
            [-2.291323485198567, 51.487369642389531],
            [-2.292322882491074, 51.486901393312053],
            [-2.293024248140473, 51.487123530164304],
            [-2.293695423803073, 51.486905154494949],
            [-2.295583533449036, 51.487795062305977],
            [-2.29746758758501, 51.487829857438143],
            [-2.300514377674181, 51.487278097238033],
            [-2.301784324482047, 51.48635680023645],
            [-2.304227781544067, 51.485842461578301],
            [-2.304405484999655, 51.486359012130869],
            [-2.30843138274917, 51.487509289427329],
            [-2.30971896089941, 51.488148786598813],
            [-2.310427628952888, 51.488158600459194],
            [-2.311426135455219, 51.487374588759309],
            [-2.313648105591096, 51.490477927331199],
            [-2.317033501140374, 51.492437958062439],
            [-2.319216186791275, 51.494571112200141],
            [-2.324327613647206, 51.497510786958507],
            [-2.321643373762269, 51.498256386341048],
            [-2.311891975390477, 51.502864807249615],
            [-2.306047520289466, 51.503800043741897],
            [-2.297456282772084, 51.506215696244354],
            [-2.296288473344382, 51.506766235517915],
            [-2.29712071715263, 51.508008549388904],
            [-2.296272946983211, 51.508813634483289],
            [-2.296640641318636, 51.509076154703152],
            [-2.296388514509565, 51.509746657547396],
            [-2.286935919977581, 51.512936977011421],
            [-2.279858685928489, 51.516169437956769],
            [-2.271558426938161, 51.518214739558729],
            [-2.26752231335449, 51.518574688653317],
            [-2.263723212016141, 51.518559921147634],
            [-2.263605966097319, 51.519220157790905],
            [-2.262694591935327, 51.519630416170976],
            [-2.255813440036151, 51.522078724767589],
            [-2.255788104296772, 51.522958143798775],
            [-2.254753499055133, 51.524040270102262],
            [-2.254929783627259, 51.525665540131847],
            [-2.25384771938804, 51.526005067910447],
            [-2.253357282959384, 51.526720948831581],
            [-2.252392351193344, 51.526874086987576],
            [-2.252112491893488, 51.528138885922715],
            [-2.2535371846675, 51.528744533130357],
            [-2.257668179087216, 51.528415428960649],
            [-2.260010251322318, 51.530369491356701],
            [-2.260106141227538, 51.532016509775438],
            [-2.260543334647032, 51.532082974536138],
            [-2.260261357492444, 51.533952920070142],
            [-2.261866246477198, 51.534505016075826],
            [-2.263366416386236, 51.534392858647642],
            [-2.263247188966919, 51.534717717166764],
            [-2.263911784709014, 51.534960789638554],
            [-2.264072908369088, 51.535398309448034],
            [-2.265663305684424, 51.53542618570745],
            [-2.266505842355194, 51.536262274446145],
            [-2.264819459995778, 51.537586038181551],
            [-2.262217375411872, 51.541661406413354],
            [-2.261726057641131, 51.544741166068036],
            [-2.264249129017851, 51.54565263006797],
            [-2.268372693891528, 51.549692095380649],
            [-2.266813327966765, 51.551354569557688],
            [-2.267558234863341, 51.552212450253393],
            [-2.267125652092859, 51.552475086614322],
            [-2.267397888625733, 51.552660588067056],
            [-2.267033984892408, 51.55332228573004],
            [-2.267386679527006, 51.553945485220851],
            [-2.267804535175019, 51.553874398501449],
            [-2.268408343893111, 51.555744128308646],
            [-2.267689826395128, 51.558162658929895],
            [-2.268106046321936, 51.558787508998201],
            [-2.267902425499826, 51.559483908421299],
            [-2.268627885720177, 51.559708831591266],
            [-2.268377453243148, 51.560049279808112],
            [-2.267956972839197, 51.559928857764127],
            [-2.267645171980774, 51.560628201212566],
            [-2.267410303606552, 51.562884677069228],
            [-2.2660655199568, 51.564319165462628],
            [-2.266503162385498, 51.564654449345056],
            [-2.264441235622569, 51.566827839912904],
            [-2.264139139694231, 51.567974022878715],
            [-2.260303737706694, 51.569900461769855],
            [-2.263426927914126, 51.571305132449034],
            [-2.266433582838755, 51.571748806777563],
            [-2.268806930959197, 51.572448313584481],
            [-2.273996533378365, 51.57235897573208],
            [-2.273955319073891, 51.574382131161848],
            [-2.272563299632423, 51.57758989929718],
            [-2.271310618128963, 51.578542292658],
            [-2.269591275009416, 51.581850583957369],
            [-2.269006093810734, 51.581735040165277],
            [-2.267457534613373, 51.582716842294104],
            [-2.267114258158871, 51.582507226958718],
            [-2.264271004489717, 51.582987513764955],
            [-2.256799774274409, 51.582808102184927],
            [-2.251845946886762, 51.583213567197504],
            [-2.25108979819055, 51.583495719104526],
            [-2.250627200461485, 51.582048201394748],
            [-2.245120791944053, 51.581408867756309],
            [-2.244998217525191, 51.583571544867901],
            [-2.244660643591776, 51.583599223795666],
            [-2.242687916858144, 51.587968623828374],
            [-2.241557870857892, 51.589625364741195],
            [-2.241119466708294, 51.589701794348848],
            [-2.241871298169446, 51.592106328989942],
            [-2.236107708373349, 51.593857000714578],
            [-2.230634013718282, 51.595070935300562],
            [-2.226052582075363, 51.595197643644717],
            [-2.222948919249422, 51.596109013225238],
            [-2.217958473237922, 51.59917859461688],
            [-2.212738780549502, 51.60122696388683],
            [-2.210817867436743, 51.60295856517299],
            [-2.207431109735236, 51.603399785475212],
            [-2.205797582803783, 51.600739435190604],
            [-2.206556917059625, 51.595948420869846],
            [-2.200626020367144, 51.596189797227545],
            [-2.194279699958902, 51.595514447667306],
            [-2.192447541901641, 51.59581238521168],
            [-2.192075750857431, 51.594261990761105],
            [-2.190017046045633, 51.592502145816553],
            [-2.183323091228505, 51.594945859335866],
            [-2.183748320229649, 51.595859611522961],
            [-2.181067155820479, 51.596506663426354],
            [-2.182780181516577, 51.599228375831473],
            [-2.179495974594787, 51.60004446775141],
            [-2.177419309042634, 51.597324157988872],
            [-2.1766865562068, 51.597489805992659],
            [-2.176488127134983, 51.596952423775967],
            [-2.175899515202254, 51.597058507029637],
            [-2.175615886628738, 51.59651225980317],
            [-2.174996445779444, 51.59648441398982],
            [-2.174722364169252, 51.596164731698664],
            [-2.170929830456522, 51.595768411419037],
            [-2.167893744603576, 51.594959084764454],
            [-2.167724813025462, 51.59456011173922],
            [-2.162200705636631, 51.593506910320201],
            [-2.158067698445187, 51.593490079241271],
            [-2.15646006201799, 51.591958315946997],
            [-2.15439415332127, 51.591466532814906],
            [-2.152542685348944, 51.590340545507971],
            [-2.131083158848185, 51.611058229150167],
            [-2.120745271015286, 51.620528176189985],
            [-2.117489101258245, 51.622595884889179],
            [-2.113620100053954, 51.626377835291279],
            [-2.108102371500083, 51.630916454991798],
            [-2.107758811081529, 51.631647761382816],
            [-2.107319417410385, 51.631571739019321],
            [-2.106635810549844, 51.632090260141084],
            [-2.073568321519159, 51.659403223612784],
            [-2.057180852092532, 51.672439744331385],
            [-2.054682196225421, 51.671384473731457],
            [-2.053406251019513, 51.669710002776675],
            [-2.047093042280252, 51.66693083890808],
            [-2.047361711599645, 51.666674480873354],
            [-2.046100134763618, 51.665934110085267],
            [-2.046208080220455, 51.665450340386762],
            [-2.040934727582005, 51.661916963891862],
            [-2.035894290544765, 51.659381302464404],
            [-2.033667274116339, 51.65845137578161],
            [-2.033424530330122, 51.658616884100191],
            [-2.031607101662436, 51.658246053958301],
            [-2.028003668925032, 51.656752635554014],
            [-2.027637655669165, 51.656294170722987],
            [-2.025329928477771, 51.655220245051034],
            [-2.024444754047558, 51.654283548937173],
            [-2.021053999206573, 51.655117699944491],
            [-2.020803157531561, 51.65346695612358],
            [-2.019662141339257, 51.652244348229502],
            [-2.014978279266785, 51.650312834092951],
            [-2.014508659243125, 51.650703113562145],
            [-2.012905707669463, 51.650644861255287],
            [-2.010611457954425, 51.648878318878381],
            [-2.006339125373564, 51.64929942657195],
            [-2.005629432090008, 51.649068389629065],
            [-2.002180860811816, 51.649052327858044],
            [-1.997476188166743, 51.651212924708318],
            [-1.99517654057341, 51.651316257117251],
            [-1.993734241818897, 51.649669897762038],
            [-1.992884399617602, 51.649495421343886],
            [-1.992192026249345, 51.649803778208216],
            [-1.991199202693134, 51.649069126485884],
            [-1.991271767646395, 51.647467792763408],
            [-1.98761258992248, 51.646407405206681],
            [-1.986521653734774, 51.645622352401901],
            [-1.985783677026578, 51.643883361127102],
            [-1.984785295106931, 51.643181022244086],
            [-1.983013432603658, 51.643481989958019],
            [-1.980106028317469, 51.642895310954287],
            [-1.976485725011213, 51.643344212208724],
            [-1.977335174765817, 51.638088094368968],
            [-1.976437706527752, 51.638345069525144],
            [-1.97580923330551, 51.6381561268987],
            [-1.975184799745423, 51.638523740167088],
            [-1.974582320752788, 51.638368064038609],
            [-1.974446204158081, 51.638884132564513],
            [-1.971889811366524, 51.639156888227099],
            [-1.970950216952219, 51.639679952371708],
            [-1.968749237201165, 51.639956322714148],
            [-1.964691039946637, 51.638741363186561],
            [-1.959926729645005, 51.638880108319732],
            [-1.958629332318561, 51.638633301102153],
            [-1.957154705540917, 51.637862225461056],
            [-1.954349972125643, 51.637890846133047],
            [-1.952289896513495, 51.63744406579552],
            [-1.952267418155794, 51.636829954135067],
            [-1.951353922471447, 51.637102016311495],
            [-1.950527562221083, 51.636959611153685],
            [-1.961183074040403, 51.656881881378474],
            [-1.962339670479789, 51.656724908926094],
            [-1.963065240809474, 51.658652855477776],
            [-1.957894686704287, 51.659677924592941],
            [-1.95863936874726, 51.661098799781598],
            [-1.954613053702389, 51.662544008518736],
            [-1.954805480404653, 51.66532596314466],
            [-1.952431242183549, 51.66546799121847],
            [-1.951839454520674, 51.667248909694784],
            [-1.952248057677618, 51.667813723335968],
            [-1.949498346747589, 51.667405277890715],
            [-1.947320270359545, 51.669110856912447],
            [-1.94423060609299, 51.669982483455151],
            [-1.942463564336175, 51.670005012334791],
            [-1.942068287770697, 51.670413019534216],
            [-1.941181243218525, 51.669785893898805],
            [-1.939063686152073, 51.669168022694492],
            [-1.938498232942821, 51.668152622246758],
            [-1.938928095374486, 51.667840850715393],
            [-1.92894199671873, 51.661658294622136],
            [-1.925580162774246, 51.661077185940862],
            [-1.926774210057414, 51.660248943450561],
            [-1.921712705565039, 51.658646152311391],
            [-1.921783117436915, 51.658050980202773],
            [-1.920914259659134, 51.65724029118941],
            [-1.914052150892871, 51.655635946791897],
            [-1.908915585726181, 51.655185223291049],
            [-1.908770021526488, 51.656412413002123],
            [-1.905594537120245, 51.655506292493619],
            [-1.903912280248025, 51.656024624956494],
            [-1.895753042640014, 51.653090140760177],
            [-1.895111489222035, 51.65236128125251],
            [-1.892286980981335, 51.65118807262337],
            [-1.888407449728282, 51.648855729109734],
            [-1.888042407842624, 51.649188957437353],
            [-1.886226746372282, 51.649314892245101],
            [-1.884276942881415, 51.649897422033355],
            [-1.881547021364766, 51.651423216666991],
            [-1.880767010317449, 51.652332342022405],
            [-1.877307581200178, 51.65197812875617],
            [-1.876629305705431, 51.651580906325314],
            [-1.876748075388958, 51.652558377889449],
            [-1.876336146174765, 51.653082134686755],
            [-1.878635759119404, 51.654212028704443],
            [-1.879140088730268, 51.654813161787096],
            [-1.880905806944181, 51.655635860693998],
            [-1.88078092313803, 51.655852422873537],
            [-1.88180210426464, 51.656169943888024],
            [-1.881673991045946, 51.656517775665527],
            [-1.882221799047654, 51.656542601347816],
            [-1.886720454112417, 51.659436795614205],
            [-1.886614924725502, 51.660015728687952],
            [-1.887362186083107, 51.660079384535557],
            [-1.887032744712717, 51.6605915684557],
            [-1.887956950303754, 51.661015938066242],
            [-1.887874062117498, 51.661798994310445],
            [-1.888615199948246, 51.662014587906313],
            [-1.888073337866181, 51.662483415583225],
            [-1.888771896413298, 51.664154645479115],
            [-1.88849062487536, 51.664474466654674],
            [-1.889203793737217, 51.664913911143834],
            [-1.887874265942024, 51.665239033559018],
            [-1.888471459192482, 51.665806047142681],
            [-1.891772645816594, 51.666909651965412],
            [-1.89191655909888, 51.668413114271026],
            [-1.893904940447499, 51.670189786650774],
            [-1.894711077328725, 51.670507899689184],
            [-1.896779481868896, 51.672795294441997],
            [-1.896848592087415, 51.674205175079756],
            [-1.89845237252006, 51.676791537761723],
            [-1.899766165973443, 51.677820356561782],
            [-1.899023000636884, 51.679682696620077],
            [-1.895060487519402, 51.677009747745707],
            [-1.893299159546823, 51.676935336423675],
            [-1.889077219053874, 51.678292701991666],
            [-1.88294279853551, 51.680854647510323],
            [-1.882263661223695, 51.680604914417145],
            [-1.878657449792918, 51.681871707984101],
            [-1.873893985515982, 51.682585996972776],
            [-1.872464107986098, 51.67875510867286],
            [-1.868716617200604, 51.675809069623853],
            [-1.869034415615312, 51.675432692433361],
            [-1.868756168316851, 51.675132975597919],
            [-1.866351861288937, 51.674413671577845],
            [-1.865501459834244, 51.673458736448936],
            [-1.864850431268168, 51.670631156114844],
            [-1.862862640562757, 51.669988682804231],
            [-1.860153130247128, 51.668480366023395],
            [-1.860736963497714, 51.666699000244463],
            [-1.86054319442413, 51.66481780877529],
            [-1.85735606688899, 51.662210132030886],
            [-1.855025576871735, 51.66218930151512],
            [-1.853297106449127, 51.661090230310272],
            [-1.853613330359337, 51.660761545398493],
            [-1.853145334644975, 51.660633286893102],
            [-1.853060859801055, 51.659930966893512],
            [-1.851156382903119, 51.657877674646052],
            [-1.850495195400975, 51.656281792447793],
            [-1.84645830081741, 51.656540935763083],
            [-1.840379444785696, 51.656190279569621],
            [-1.83448250239922, 51.657517328425584],
            [-1.819548768462681, 51.662778612729653],
            [-1.81622282057843, 51.662944296719736],
            [-1.815209857477826, 51.663526240091386],
            [-1.816550516338341, 51.664859938427007],
            [-1.816770909086764, 51.66793527837261],
            [-1.817882046677137, 51.669215554514452],
            [-1.817528249051805, 51.669459567243415],
            [-1.818456561787124, 51.670548940180623],
            [-1.818286406810844, 51.671153785941904],
            [-1.819908069857913, 51.670989043200507],
            [-1.820625917749133, 51.671566476490341],
            [-1.820408293923365, 51.671747766888501],
            [-1.821101280370422, 51.672032944962893],
            [-1.820975438377469, 51.67277632642228],
            [-1.821870827819647, 51.672710251534724],
            [-1.822106764702415, 51.673022603428244],
            [-1.823020295243819, 51.673126481017484],
            [-1.823182326585118, 51.673480978187719],
            [-1.822969782452039, 51.674586576786453],
            [-1.822350393323329, 51.675438908351012],
            [-1.821372128693717, 51.675972403631256],
            [-1.821682502583439, 51.676852214003915],
            [-1.820574134754498, 51.677361229021557],
            [-1.820389398989052, 51.677996624961594],
            [-1.817107102830734, 51.679618075851941],
            [-1.815704948949781, 51.679785821303071],
            [-1.816052974500542, 51.680633336695003],
            [-1.815415666795859, 51.681927969673637],
            [-1.812482976543013, 51.685996341304786],
            [-1.813501405337454, 51.687738653868664],
            [-1.815206252996732, 51.688611690666157],
            [-1.819377498477425, 51.694257435650641],
            [-1.819377777787556, 51.696007118370197],
            [-1.81871024235848, 51.696869244191895],
            [-1.819320055541257, 51.698152322942882],
            [-1.818820057448846, 51.699083039099811],
            [-1.819297043009534, 51.699572892052586],
            [-1.818498986580642, 51.702305876815522],
            [-1.811953491576436, 51.703141666624227],
            [-1.811952244218404, 51.702044742804723],
            [-1.807501892639671, 51.699148684319312],
            [-1.81118404456054, 51.697477797979097],
            [-1.806678067036874, 51.692810354603601],
            [-1.807221864870988, 51.692510042444326],
            [-1.806171283219311, 51.690536550451434],
            [-1.803672674605903, 51.68824594923634],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000055",
        LAD13CDO: "00KB",
        LAD13NM: "Bedford",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.457729300160711, 52.320076743163241],
            [-0.447150212648705, 52.315727650918141],
            [-0.444201522934725, 52.314217017645674],
            [-0.443876166267341, 52.314333212002737],
            [-0.443085591070384, 52.313952363536707],
            [-0.442314144520454, 52.314155280959817],
            [-0.441535202903799, 52.315197858348242],
            [-0.440226444370376, 52.315368497192232],
            [-0.43610676913418, 52.314456281194339],
            [-0.435392843585562, 52.312153208619016],
            [-0.433524588622278, 52.311843430909654],
            [-0.433069320645744, 52.308243664353661],
            [-0.434092708921697, 52.30567949715757],
            [-0.433921569912118, 52.302340641624681],
            [-0.435798717107272, 52.298849107377727],
            [-0.435779677559773, 52.296642444648178],
            [-0.434662739820758, 52.296378593362391],
            [-0.43189432664622, 52.296579233539063],
            [-0.432320076907188, 52.295984281146012],
            [-0.432092977768553, 52.294608327717491],
            [-0.43128819008836, 52.294434902104108],
            [-0.432246609794593, 52.292998265210024],
            [-0.431202626269068, 52.292000776748097],
            [-0.427003473960014, 52.290749106135621],
            [-0.426928554472539, 52.290173577068352],
            [-0.424911871528166, 52.289280854404204],
            [-0.422661298467713, 52.289361407004336],
            [-0.422176041445078, 52.288658114095178],
            [-0.423037231168632, 52.287858619207668],
            [-0.421786443739273, 52.284933286516249],
            [-0.419129610973736, 52.284735909846717],
            [-0.419028637543826, 52.284236450315802],
            [-0.416303994414473, 52.284256586062668],
            [-0.414928573751577, 52.284756920100556],
            [-0.413672884660757, 52.284490105866738],
            [-0.411679136968525, 52.28477439965696],
            [-0.410178059776157, 52.284482668718539],
            [-0.408840282663426, 52.286753791331634],
            [-0.407833912089822, 52.287383991881825],
            [-0.406926506315106, 52.287461665787639],
            [-0.403601885625081, 52.285282276060492],
            [-0.402879870186002, 52.2837116527676],
            [-0.402005657918417, 52.283194524315107],
            [-0.402750477890519, 52.282091498399431],
            [-0.401330041898081, 52.280051968113661],
            [-0.400175356751234, 52.279103049451273],
            [-0.398999046643439, 52.278714873367583],
            [-0.396022995241508, 52.281078718673577],
            [-0.392645441893584, 52.282415631254878],
            [-0.390998015034943, 52.282082101974488],
            [-0.386694947980933, 52.28189122189314],
            [-0.384657832395552, 52.281310417010452],
            [-0.380592155794682, 52.282652856570238],
            [-0.378086648310733, 52.281651920227922],
            [-0.377020800120154, 52.281533877569878],
            [-0.376241412826213, 52.28115902080043],
            [-0.375976091336598, 52.280516999242103],
            [-0.375029882299912, 52.280455429879218],
            [-0.375918013554627, 52.279298796201935],
            [-0.375448436776878, 52.279031592907742],
            [-0.375533802386115, 52.277979901142722],
            [-0.374338634593055, 52.277312492037154],
            [-0.374862669730033, 52.276891725548296],
            [-0.374785990459007, 52.276263086180641],
            [-0.373599931275856, 52.27610919068929],
            [-0.372519460859507, 52.275473912302367],
            [-0.372195068995704, 52.273485986288826],
            [-0.372947041454722, 52.273524223228335],
            [-0.374003097472951, 52.272983115574661],
            [-0.37526629724127, 52.273991331875088],
            [-0.376829338278428, 52.274015526135656],
            [-0.377422366247796, 52.273349338577596],
            [-0.378626568747208, 52.27348636195741],
            [-0.378449470375286, 52.272997507405755],
            [-0.381033373186662, 52.273121077421038],
            [-0.381172847774466, 52.272048544462699],
            [-0.381854839975102, 52.271693750117201],
            [-0.381487406975102, 52.271198695097873],
            [-0.382361154690769, 52.271111765852808],
            [-0.381987536851929, 52.268693414922154],
            [-0.381822858809499, 52.265769923649181],
            [-0.378478963102046, 52.265280781502618],
            [-0.378366474971749, 52.252778777171592],
            [-0.37724188095344, 52.248026755099858],
            [-0.375300376393308, 52.2429092226253],
            [-0.374708521548757, 52.239104080736816],
            [-0.375304130674153, 52.238635745116987],
            [-0.373768353228332, 52.236978192655478],
            [-0.373370148069813, 52.23401009405768],
            [-0.374582528671549, 52.233613198798814],
            [-0.374396076365945, 52.232984841006122],
            [-0.368792950456869, 52.234634756868473],
            [-0.362757487928658, 52.234053078898455],
            [-0.35905862341309, 52.234721913374187],
            [-0.358843629955927, 52.234476160486544],
            [-0.35534484960805, 52.234206276327164],
            [-0.353758726522621, 52.233339885757729],
            [-0.352403789852923, 52.23168906613386],
            [-0.350625450318837, 52.2309557170652],
            [-0.349938123172726, 52.230813039452492],
            [-0.348892484059804, 52.231211115929966],
            [-0.345829117520081, 52.230620654643587],
            [-0.341031571132549, 52.230634241315215],
            [-0.340375093955129, 52.230921724487999],
            [-0.344146754465593, 52.235143051024657],
            [-0.342673207317428, 52.235348050862981],
            [-0.343340284027293, 52.236429174542515],
            [-0.343073263898764, 52.237425255363135],
            [-0.344032879416008, 52.238478112084401],
            [-0.3432386900272, 52.241399018148783],
            [-0.34348951455219, 52.241861092556192],
            [-0.340235471734618, 52.242241568483067],
            [-0.338005040941953, 52.242194900473478],
            [-0.336306428248494, 52.242732027180281],
            [-0.334514399942434, 52.242907261806963],
            [-0.32930071307627, 52.242538671444173],
            [-0.327142289214483, 52.242991833063421],
            [-0.324938714755125, 52.242853587730984],
            [-0.323096644334636, 52.243111557987675],
            [-0.320017451644988, 52.242694636345043],
            [-0.31591898553671, 52.241501534108778],
            [-0.314400793604869, 52.240285820345939],
            [-0.313708978457096, 52.238985688107746],
            [-0.31061550209562, 52.23991880791904],
            [-0.307744014118064, 52.239864188484255],
            [-0.298541808797341, 52.240976298542947],
            [-0.295846838859637, 52.240479763162043],
            [-0.293230111389156, 52.240503096326762],
            [-0.291634487622322, 52.239223026724694],
            [-0.288024419312039, 52.23830575622096],
            [-0.285715005634548, 52.237388389971322],
            [-0.285749357313157, 52.236230805246102],
            [-0.286652516024598, 52.234834976962382],
            [-0.293779716281114, 52.228425800214978],
            [-0.299171681695382, 52.221013844978671],
            [-0.299986309176344, 52.218044953138609],
            [-0.299411815631144, 52.216030707095015],
            [-0.296506717589302, 52.210811586771698],
            [-0.296305931150294, 52.210474209629773],
            [-0.295600688607745, 52.210429858446943],
            [-0.294378065372396, 52.208340583668061],
            [-0.292296418137643, 52.207474279989619],
            [-0.291262285260201, 52.207420646748147],
            [-0.290808593754659, 52.20768741370653],
            [-0.28845296806726, 52.207364647239473],
            [-0.28033356218489, 52.208417277538764],
            [-0.274226503991362, 52.208707556497615],
            [-0.266230076186253, 52.20955601370747],
            [-0.261867097202726, 52.209854182610968],
            [-0.261949425420151, 52.209619820608083],
            [-0.264095153052349, 52.206351562230793],
            [-0.264933921076478, 52.205708425458155],
            [-0.260468862432297, 52.205407111141177],
            [-0.261206259335738, 52.20413760335331],
            [-0.260589609362139, 52.203626794224782],
            [-0.256544051995231, 52.202820800761245],
            [-0.255524881493344, 52.201230441190006],
            [-0.254766134173349, 52.201435914192842],
            [-0.254531589675416, 52.199466920391259],
            [-0.251980116551466, 52.196507833788196],
            [-0.252160010464635, 52.196024961674851],
            [-0.251638783262335, 52.19554878566553],
            [-0.249547246817689, 52.194709444268831],
            [-0.247878179064227, 52.192839627005242],
            [-0.246922399645382, 52.192552089512375],
            [-0.246243420152206, 52.191848758677857],
            [-0.244307498571846, 52.191485484453402],
            [-0.242082564400889, 52.190364388948083],
            [-0.240745320511338, 52.190180811974706],
            [-0.244024192527263, 52.182672343508848],
            [-0.244080490711018, 52.182914152521192],
            [-0.245178924141053, 52.183102233389036],
            [-0.247292468569559, 52.183217273395847],
            [-0.247067058585808, 52.183741722573465],
            [-0.24980747495411, 52.184358761443825],
            [-0.254229857409879, 52.185806270526975],
            [-0.256271997943415, 52.185805900742736],
            [-0.266346320899676, 52.186940730949871],
            [-0.267590857387861, 52.187288997674138],
            [-0.272531036747166, 52.187515189332359],
            [-0.275582068612174, 52.188588441068916],
            [-0.281125057340899, 52.189537897385541],
            [-0.281340291676603, 52.189267694252372],
            [-0.281793877382974, 52.189341736372491],
            [-0.286424008694067, 52.190752390266994],
            [-0.289139010937062, 52.190913175132025],
            [-0.294069498605753, 52.182882424224104],
            [-0.297887386730921, 52.181078148725263],
            [-0.301700044070922, 52.178212690923928],
            [-0.303199570580913, 52.173706200714925],
            [-0.302844076559698, 52.171711289472469],
            [-0.301850764032049, 52.170991169101576],
            [-0.302277707953557, 52.170570222664416],
            [-0.303307677510238, 52.170749584215216],
            [-0.303874597443145, 52.1690700502647],
            [-0.303437432052648, 52.168184403948608],
            [-0.30429654981716, 52.166894801198772],
            [-0.304837427048859, 52.166436820304988],
            [-0.306156815083313, 52.166346977608072],
            [-0.306048288403202, 52.165546982103123],
            [-0.307473105105182, 52.165452344823187],
            [-0.308339897788544, 52.165806452334522],
            [-0.308387406145541, 52.166481489045957],
            [-0.31080378844338, 52.167115843418181],
            [-0.313833496419584, 52.167232020630138],
            [-0.31535197881136, 52.167678110537075],
            [-0.316983374941159, 52.167457731234741],
            [-0.321286407130099, 52.168825495544318],
            [-0.321855594831461, 52.168427183606916],
            [-0.321479986543233, 52.167644983631078],
            [-0.321865898765586, 52.167065134965078],
            [-0.324436937879461, 52.166585577730174],
            [-0.326056616372576, 52.166908885633816],
            [-0.327373337804242, 52.165992459708825],
            [-0.329404976567027, 52.165701157057889],
            [-0.330087097736014, 52.165329581059936],
            [-0.332266804868414, 52.161350262133368],
            [-0.334051795998002, 52.159044020240934],
            [-0.333757028835971, 52.157620116524775],
            [-0.335090540878008, 52.156085235155885],
            [-0.343448082540877, 52.150844045238536],
            [-0.345717345496033, 52.14731440738003],
            [-0.347133448334601, 52.146331721936932],
            [-0.346257123528936, 52.145960680889232],
            [-0.349171765680474, 52.145318168586428],
            [-0.355306629448742, 52.145490198230938],
            [-0.356632781330994, 52.145951060250709],
            [-0.357820952458408, 52.145991886962982],
            [-0.361103671087533, 52.144156553777293],
            [-0.360978441599791, 52.143387848979543],
            [-0.362055573708105, 52.14290828770126],
            [-0.355387426465975, 52.134966005288632],
            [-0.355531492141342, 52.134505856562612],
            [-0.349772184245262, 52.127928212268039],
            [-0.349545950101971, 52.126775943393156],
            [-0.346803082595173, 52.122849630770524],
            [-0.346384399106796, 52.120585114037468],
            [-0.344683199974901, 52.118363738405627],
            [-0.344826862535906, 52.115944362282832],
            [-0.348464745880266, 52.112277416962485],
            [-0.35180000288089, 52.110425097785949],
            [-0.351779682242578, 52.10808524196721],
            [-0.353038009841393, 52.106353092781042],
            [-0.356131701897906, 52.107717111150826],
            [-0.359240801037799, 52.103534446801227],
            [-0.364428164269082, 52.100673520735917],
            [-0.367835832446066, 52.099274925489873],
            [-0.365502568356629, 52.097643022374591],
            [-0.366717125812748, 52.096806568290653],
            [-0.371422561760897, 52.094557270897134],
            [-0.374610494918999, 52.097177313422314],
            [-0.381210552715706, 52.093553769662243],
            [-0.381385073651899, 52.093128174142478],
            [-0.378535897904393, 52.090300776090402],
            [-0.37693160822308, 52.08772872648067],
            [-0.382315489627529, 52.084234032999326],
            [-0.383918566419975, 52.085753995352199],
            [-0.385110958432854, 52.083814699874431],
            [-0.389172203765325, 52.080868928399035],
            [-0.388515423968181, 52.079776482917389],
            [-0.387535414625135, 52.079555378309351],
            [-0.38537053625397, 52.077390275259162],
            [-0.384925725914361, 52.07556341324284],
            [-0.389672085868335, 52.074561969799817],
            [-0.39338355814664, 52.073320548896781],
            [-0.394738974724592, 52.072469544502454],
            [-0.395363578939423, 52.071042116460255],
            [-0.397040370364693, 52.070992102545141],
            [-0.39858770864143, 52.070318997446876],
            [-0.405445467014495, 52.068795315802639],
            [-0.416529340201917, 52.068672309798366],
            [-0.422303869799456, 52.067392045358496],
            [-0.429886106446746, 52.067762141920227],
            [-0.434101030716548, 52.066927168409364],
            [-0.435630176208231, 52.066044737634876],
            [-0.43776715196618, 52.065467961222957],
            [-0.440056615794844, 52.063276509946455],
            [-0.447675145172819, 52.067169673010561],
            [-0.453280077967975, 52.070681666880709],
            [-0.457775943304064, 52.072824780602879],
            [-0.458593398938173, 52.074000755657153],
            [-0.458783277431078, 52.077003658772583],
            [-0.459338480890317, 52.077704151337393],
            [-0.461930718644386, 52.079130766932892],
            [-0.464062687476092, 52.079600941308378],
            [-0.467915010703684, 52.084383223273427],
            [-0.471549706772022, 52.087997272558724],
            [-0.475362952359776, 52.086689859729915],
            [-0.476221689513188, 52.087017458111092],
            [-0.478804154101799, 52.085500705211309],
            [-0.480409056382186, 52.085216598424054],
            [-0.482364625324242, 52.084232064468019],
            [-0.483143260684676, 52.084468667904879],
            [-0.485358022221289, 52.083196999567697],
            [-0.486145278693462, 52.083868874381267],
            [-0.492704011357985, 52.079236071038039],
            [-0.49963651083103, 52.0756003069204],
            [-0.502407493257424, 52.072155901456938],
            [-0.504496823921881, 52.070716842412239],
            [-0.504779452529607, 52.070118008917916],
            [-0.499614005819947, 52.067696637840548],
            [-0.499625778210353, 52.067432442308153],
            [-0.502436860607889, 52.063733190536446],
            [-0.503747697627485, 52.063009851700301],
            [-0.503990198484206, 52.062519305362983],
            [-0.504432625575084, 52.060017234504549],
            [-0.505071188743318, 52.058978743323017],
            [-0.504511877314535, 52.056770565224056],
            [-0.506704721630849, 52.056489967578301],
            [-0.50853769556621, 52.054532384689097],
            [-0.509837221711816, 52.055014575545059],
            [-0.510793699258892, 52.05541868849523],
            [-0.510312515684434, 52.05870343914237],
            [-0.511540332907982, 52.059547953903945],
            [-0.514933469882954, 52.058967658951687],
            [-0.517918327707018, 52.060319765412373],
            [-0.516964733780581, 52.06383147229888],
            [-0.517593635499998, 52.06484011870814],
            [-0.518961542260181, 52.065692609594088],
            [-0.517635364810517, 52.067369003873239],
            [-0.517838757907967, 52.067879571417357],
            [-0.51883166097427, 52.068560108121886],
            [-0.522378550859755, 52.069394963545314],
            [-0.530804872485539, 52.075908398183955],
            [-0.531882453254346, 52.075931714758497],
            [-0.53350962533083, 52.077128930185147],
            [-0.533795102353825, 52.076967041680078],
            [-0.535624766542084, 52.077954551357116],
            [-0.541607038509794, 52.081871590323715],
            [-0.543384619252606, 52.081870191373696],
            [-0.54526855077702, 52.081201127248832],
            [-0.545821211213951, 52.081573891063314],
            [-0.550106747500424, 52.080693385624897],
            [-0.555730642166665, 52.085273340166715],
            [-0.557946581151363, 52.087614817034627],
            [-0.558681143356815, 52.089686393336642],
            [-0.56182394967846, 52.092313354735012],
            [-0.566900398551574, 52.094378420555188],
            [-0.566798089467167, 52.094746716642852],
            [-0.567642348149475, 52.094959279633059],
            [-0.568234648844513, 52.094697636320866],
            [-0.570687086838365, 52.094926999355103],
            [-0.572775182005162, 52.093445365701619],
            [-0.574973227877319, 52.09432702041719],
            [-0.578317426419852, 52.094103052886311],
            [-0.580107521993536, 52.095101971621155],
            [-0.58209480301847, 52.098402288388435],
            [-0.582546167902812, 52.098348373757723],
            [-0.583849087305997, 52.097312957923336],
            [-0.585665592716621, 52.096699983242118],
            [-0.589205732843856, 52.09748776954936],
            [-0.588385958883594, 52.09843731932714],
            [-0.587597254767311, 52.101082977321148],
            [-0.58652063992302, 52.101471083113545],
            [-0.583252315003011, 52.104537418798181],
            [-0.586776657149872, 52.107044201875347],
            [-0.590500069781367, 52.10876113251858],
            [-0.591428082852688, 52.110683749191764],
            [-0.591829775122043, 52.110680454965753],
            [-0.593074136356856, 52.112252582418144],
            [-0.595750605875047, 52.113566629156182],
            [-0.597849625937226, 52.115602034409342],
            [-0.598035985529356, 52.11650516591569],
            [-0.597178307223047, 52.117447131117444],
            [-0.596946197839287, 52.119211135020798],
            [-0.597827496514733, 52.121939647625112],
            [-0.599029207805784, 52.123584028207475],
            [-0.598931547704172, 52.125720067027117],
            [-0.602141583003754, 52.12892124828786],
            [-0.604035264276279, 52.130219522849558],
            [-0.60708433611654, 52.133731550349985],
            [-0.607516447331836, 52.133977615552155],
            [-0.610107389414425, 52.133728553408041],
            [-0.614170127801763, 52.133886053922907],
            [-0.617650483094922, 52.133034078619531],
            [-0.619018529936534, 52.133017737371077],
            [-0.622905095799465, 52.134291370092527],
            [-0.627160311997551, 52.135272458113675],
            [-0.628949097614483, 52.136926052816513],
            [-0.629871176883264, 52.137304504643389],
            [-0.634642324751407, 52.137961338711015],
            [-0.634961368219198, 52.139187822251145],
            [-0.635471113521362, 52.13924317172598],
            [-0.635617593104127, 52.141192339549548],
            [-0.634948104189644, 52.141151326158948],
            [-0.63438147143281, 52.142381942831165],
            [-0.636410082105543, 52.143761270480866],
            [-0.63789926566591, 52.145820354895328],
            [-0.637854971138713, 52.146362006359901],
            [-0.636051832451093, 52.147499222692232],
            [-0.640810411018167, 52.152399447635247],
            [-0.640752353126223, 52.152913967650832],
            [-0.636654696724928, 52.153026721020424],
            [-0.634334593126972, 52.153469218085462],
            [-0.630690084704691, 52.154913195414004],
            [-0.62962370003977, 52.159507844277947],
            [-0.630153555304604, 52.161915515673854],
            [-0.631320645700899, 52.164046460269155],
            [-0.633573739619004, 52.166145020217158],
            [-0.634508457300496, 52.1665613399188],
            [-0.634925673841483, 52.168121622640712],
            [-0.633283777713167, 52.170121107422261],
            [-0.631192021457164, 52.171812352163975],
            [-0.628273261664853, 52.172524711070317],
            [-0.627741166293281, 52.176177885850443],
            [-0.62933516912965, 52.178702222054163],
            [-0.627465181835903, 52.181533345176938],
            [-0.630311554968142, 52.181768720041859],
            [-0.639528393431916, 52.183422710450614],
            [-0.639796998586454, 52.184494841540022],
            [-0.639158492572424, 52.186675897423015],
            [-0.640875868149971, 52.187154233816756],
            [-0.643178464382775, 52.188832385156374],
            [-0.651017746072368, 52.189952635754473],
            [-0.655601805553806, 52.189587768368071],
            [-0.657466117589309, 52.18976274030215],
            [-0.659353683841282, 52.191013271714247],
            [-0.661325210556755, 52.191761228350622],
            [-0.666008768275927, 52.192718762134113],
            [-0.667962753709326, 52.194059814325954],
            [-0.668153476023584, 52.195021306322921],
            [-0.66868154465604, 52.197718269251368],
            [-0.661477782837398, 52.208113082605728],
            [-0.658515224698133, 52.210070948787084],
            [-0.653592538223563, 52.215226067183444],
            [-0.652382265532412, 52.217249610657504],
            [-0.648651155975438, 52.220862683347818],
            [-0.643636132089141, 52.223348708546453],
            [-0.6373486500801, 52.227304250103465],
            [-0.638638409311466, 52.22793141738903],
            [-0.639672098744989, 52.228937735987088],
            [-0.642765225466879, 52.233426566519583],
            [-0.643464646611084, 52.235845981634817],
            [-0.643101629328886, 52.238530105824928],
            [-0.645511585755893, 52.242447266386804],
            [-0.648983395982941, 52.25269895407321],
            [-0.649805407004751, 52.254092960080392],
            [-0.650049547654871, 52.256764258115254],
            [-0.652776292271723, 52.266573918995498],
            [-0.653671426220578, 52.268275317264276],
            [-0.651095318711522, 52.269040714066996],
            [-0.646915456098662, 52.271165995211291],
            [-0.639924103862832, 52.272428022259533],
            [-0.637678561101865, 52.272386853602505],
            [-0.635490112340772, 52.271918334437771],
            [-0.63142832374785, 52.270033586675211],
            [-0.62693747069992, 52.273367435439255],
            [-0.626993848958553, 52.274863280782299],
            [-0.620072268820988, 52.276908956180016],
            [-0.611090980878243, 52.278518237360743],
            [-0.610595770259623, 52.278885535649593],
            [-0.610632761318145, 52.279483867507601],
            [-0.607636111860013, 52.278842585803098],
            [-0.605712820060111, 52.278845967734142],
            [-0.602138898463985, 52.278572650875859],
            [-0.595524106741679, 52.275820260192887],
            [-0.588364980600896, 52.273803640932456],
            [-0.586351728655267, 52.272817524552245],
            [-0.588127364080012, 52.270764553430872],
            [-0.587634404957714, 52.270319899434377],
            [-0.58090034242583, 52.265218604492297],
            [-0.579370133204761, 52.266421178701208],
            [-0.5715997029298, 52.25823374735279],
            [-0.565710046854882, 52.253454658182456],
            [-0.558062990118991, 52.256336637768804],
            [-0.554755372541297, 52.258166326843053],
            [-0.552343659908941, 52.256907709557652],
            [-0.549986488564599, 52.256451710856069],
            [-0.546161293126776, 52.256713961889197],
            [-0.54453709324537, 52.256113130743678],
            [-0.542699097976354, 52.255957391420367],
            [-0.540921294138544, 52.256512658513863],
            [-0.539916992559194, 52.257292352617199],
            [-0.537620876365996, 52.261754054210783],
            [-0.539342544870399, 52.266081164161513],
            [-0.536449078994944, 52.268194188578953],
            [-0.531207729949062, 52.270388550717378],
            [-0.53254119701888, 52.272525211204751],
            [-0.534182360380118, 52.277601238743522],
            [-0.53447125540721, 52.279730297381256],
            [-0.534020406574826, 52.281176748022553],
            [-0.535252730408469, 52.282215214836299],
            [-0.53493753407537, 52.282437878100154],
            [-0.53599542318584, 52.282854693030799],
            [-0.541168068489193, 52.28787903103742],
            [-0.541980984350879, 52.28884121460932],
            [-0.542056304395581, 52.28971786586753],
            [-0.540621700860253, 52.291477664032435],
            [-0.518328232616475, 52.311653680877207],
            [-0.514068035151711, 52.314682273424012],
            [-0.520045049878457, 52.31678476886038],
            [-0.519846539102409, 52.317677781572371],
            [-0.516856413920078, 52.317636682170011],
            [-0.507047309051726, 52.319854371136493],
            [-0.487493715346123, 52.321059480716961],
            [-0.48189799487724, 52.320747644967753],
            [-0.478150080227633, 52.319267155597551],
            [-0.476278691428734, 52.318049026856315],
            [-0.474168068600777, 52.319737268028952],
            [-0.465383137982795, 52.322938458820978],
            [-0.46074584490118, 52.321854950091677],
            [-0.457729300160711, 52.320076743163241],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000056",
        LAD13CDO: "00KC",
        LAD13NM: "Central Bedfordshire",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.281793877382974, 52.189341736372491],
            [-0.281340291676603, 52.189267694252372],
            [-0.281125057340899, 52.189537897385541],
            [-0.275582068612174, 52.188588441068916],
            [-0.272531036747166, 52.187515189332359],
            [-0.267590857387861, 52.187288997674138],
            [-0.266346320899676, 52.186940730949871],
            [-0.256271997943415, 52.185805900742736],
            [-0.254229857409879, 52.185806270526975],
            [-0.24980747495411, 52.184358761443825],
            [-0.253470439475296, 52.180846139078099],
            [-0.257736201529348, 52.17813625350221],
            [-0.257251416202791, 52.177666932322246],
            [-0.260716371526503, 52.176389133776269],
            [-0.257413059558008, 52.174279543242733],
            [-0.254866822195856, 52.173385016509592],
            [-0.254182298605608, 52.172752678831976],
            [-0.254421439879285, 52.172172672121931],
            [-0.253798604943408, 52.172019590516229],
            [-0.253565565632989, 52.172555628204989],
            [-0.251099373076936, 52.172495712566629],
            [-0.240140680618759, 52.170220631858484],
            [-0.228885617238622, 52.16623079185814],
            [-0.226118383897136, 52.164925902533788],
            [-0.223254252490848, 52.162643908692417],
            [-0.223627033792318, 52.162241308959004],
            [-0.221236283684948, 52.160964450089075],
            [-0.21978090176125, 52.161530537815807],
            [-0.21664735216043, 52.1587237030758],
            [-0.218397778015232, 52.15790675197966],
            [-0.219544657316363, 52.156826205639867],
            [-0.222646034916604, 52.153138803783563],
            [-0.224826565726079, 52.149732386373678],
            [-0.227395825986644, 52.148011389440875],
            [-0.234826912066511, 52.144715061481527],
            [-0.232230412905144, 52.144774176718585],
            [-0.21919145230973, 52.143881159608256],
            [-0.217232984873948, 52.144066467267095],
            [-0.213630650406377, 52.143367261182327],
            [-0.209343132642768, 52.143830939962626],
            [-0.2078026390389, 52.144525064851173],
            [-0.206227750540636, 52.144437280515604],
            [-0.204161117223065, 52.143967039816594],
            [-0.203440812632856, 52.143344642651918],
            [-0.198001407566608, 52.14192910115829],
            [-0.190807018375183, 52.141456585915179],
            [-0.181703383930409, 52.139748434783975],
            [-0.17604936854571, 52.13975013513155],
            [-0.174499123624409, 52.140315995893843],
            [-0.171559039186595, 52.139490873610967],
            [-0.169157678959701, 52.14024847317274],
            [-0.166130237215307, 52.139880430677607],
            [-0.163775067797502, 52.140116226798654],
            [-0.163969826872492, 52.14238064969561],
            [-0.16273660914689, 52.142588031385749],
            [-0.162681086264623, 52.143049335156931],
            [-0.160111959958067, 52.14301107782866],
            [-0.159697645275078, 52.144056631780266],
            [-0.156490749594401, 52.143959786729376],
            [-0.154376935725455, 52.144396085781146],
            [-0.152730306493864, 52.143148342153076],
            [-0.150442172123404, 52.143594428157535],
            [-0.151368617934609, 52.141550771806948],
            [-0.149631916151033, 52.138743321264649],
            [-0.148383566805942, 52.138928735309861],
            [-0.14486015066955, 52.138334758113174],
            [-0.144470072021602, 52.138191947753072],
            [-0.14441760561073, 52.137450210942468],
            [-0.144159363040786, 52.129776272003348],
            [-0.145630777391005, 52.117878280832798],
            [-0.143957443275697, 52.117724268184176],
            [-0.145818504700763, 52.11560724047191],
            [-0.147885584800496, 52.114188476562688],
            [-0.146882689717719, 52.113613434995415],
            [-0.146784677243798, 52.112772072194645],
            [-0.145790359764976, 52.1117098069818],
            [-0.146997125492343, 52.111100262829758],
            [-0.14549593893478, 52.109830411340745],
            [-0.147074803000215, 52.109015414940316],
            [-0.145922826013587, 52.107883234015475],
            [-0.147691370186896, 52.107105381015671],
            [-0.146258942610875, 52.105975989214393],
            [-0.147852116203383, 52.103789976275124],
            [-0.146963074953085, 52.102974845475984],
            [-0.148495132161887, 52.101520685403209],
            [-0.147194514867023, 52.1003852807371],
            [-0.148530147735662, 52.099652762803707],
            [-0.147511392192775, 52.098833799416724],
            [-0.150361160633838, 52.097002883495819],
            [-0.148513677019081, 52.095431804233968],
            [-0.149163691223684, 52.095105723002135],
            [-0.148700060107158, 52.09466774010631],
            [-0.1490194495786, 52.094499216208035],
            [-0.148614641613331, 52.093451620397872],
            [-0.149316699322711, 52.093137145858876],
            [-0.148913234816036, 52.092692015672114],
            [-0.149774648873803, 52.092159744340115],
            [-0.149137445448186, 52.091119291805327],
            [-0.149712967482611, 52.090545665442768],
            [-0.149585792517601, 52.089809047125001],
            [-0.150476862050813, 52.088805172094041],
            [-0.154114103657043, 52.086124217449992],
            [-0.154354017462097, 52.084943767521231],
            [-0.154893269371759, 52.08478586400819],
            [-0.15483251610298, 52.084241814166894],
            [-0.155724932136558, 52.083730666319589],
            [-0.155511422600041, 52.083351472075115],
            [-0.156255860727811, 52.083384699866855],
            [-0.155860529056033, 52.082848903723502],
            [-0.156694476667452, 52.082126427983518],
            [-0.156302833590413, 52.081359604087666],
            [-0.156712465909759, 52.0812293373721],
            [-0.156256004961571, 52.0809731274258],
            [-0.157307268453173, 52.080536386295996],
            [-0.160570326943556, 52.079689086872655],
            [-0.164192390700089, 52.077024672883432],
            [-0.164584548169788, 52.076287167118373],
            [-0.16436443869329, 52.075249693363887],
            [-0.164842811003462, 52.074900172670297],
            [-0.164487888931522, 52.074588028428842],
            [-0.16637995310666, 52.07061257634102],
            [-0.167982207969989, 52.068861632988224],
            [-0.171646243489002, 52.063350871134482],
            [-0.17264580889845, 52.06019949480951],
            [-0.175213666776603, 52.06076080616625],
            [-0.180571549508791, 52.061339095365462],
            [-0.19500846190775, 52.062404531608706],
            [-0.19566181651811, 52.060961481200458],
            [-0.197750157026895, 52.059490928332046],
            [-0.197119362864313, 52.058999324937417],
            [-0.199163769040847, 52.057239442119013],
            [-0.199209161414119, 52.056654777091893],
            [-0.209034105023063, 52.047290415270183],
            [-0.211206698395143, 52.044522508055408],
            [-0.211991498141252, 52.04283499097086],
            [-0.219519092450655, 52.036731234007902],
            [-0.212849247083708, 52.026557741632502],
            [-0.207558759320168, 52.020231714568162],
            [-0.206071150109067, 52.016104344434154],
            [-0.201282569993247, 52.009815327961185],
            [-0.203926741933754, 52.00873530527673],
            [-0.21352447261455, 52.008441568539304],
            [-0.213652530230919, 52.008848138796303],
            [-0.213989858213805, 52.008646444265189],
            [-0.217145155947809, 52.005987734024366],
            [-0.218424332485631, 52.00631910247359],
            [-0.219391846444795, 52.005399491736625],
            [-0.220750574008103, 52.005745525117312],
            [-0.221797242572828, 52.004665239409015],
            [-0.223220704382817, 52.005068869514297],
            [-0.22477563701955, 52.003917995756055],
            [-0.227658117883179, 52.00297771024173],
            [-0.229208552096942, 52.002932693204833],
            [-0.232081513040624, 52.000529203557583],
            [-0.236894598193137, 51.997938003323831],
            [-0.238549916860427, 51.998148000859437],
            [-0.237627404992254, 51.999048658486757],
            [-0.244105441560397, 51.999014134553519],
            [-0.244986235602289, 51.993105318286183],
            [-0.245667438276762, 51.993042634427155],
            [-0.245041461993263, 51.992884944919908],
            [-0.24577059695569, 51.988912474675992],
            [-0.24624322094898, 51.98792412973139],
            [-0.24842833147657, 51.987873026296043],
            [-0.248477919528668, 51.987423278455637],
            [-0.24663810532602, 51.98747143153237],
            [-0.247916544224561, 51.98516429886282],
            [-0.257476116577768, 51.98537717028622],
            [-0.258324451566219, 51.983104924319058],
            [-0.259310020872196, 51.983150068210954],
            [-0.260282625220677, 51.979696334540883],
            [-0.262856376478467, 51.979600352858782],
            [-0.267434432141226, 51.980316099661636],
            [-0.270653329539275, 51.979613505283289],
            [-0.27169346668844, 51.981492756244343],
            [-0.273928253866729, 51.981200932718345],
            [-0.273213529173435, 51.982406132961216],
            [-0.274135897873771, 51.983034686549075],
            [-0.274890907775346, 51.982827250673644],
            [-0.274696980212491, 51.984273871631267],
            [-0.273766728467966, 51.985326654930027],
            [-0.273865816831309, 51.986650785945493],
            [-0.272828953797004, 51.987217346775886],
            [-0.272308984768756, 51.988126872881068],
            [-0.272880405347262, 51.988226969587565],
            [-0.272906750685767, 51.988677839841415],
            [-0.274625397190742, 51.989130136545214],
            [-0.274472724988142, 51.989616148598536],
            [-0.275819786481724, 51.990946865611591],
            [-0.275650198480388, 51.991835458945928],
            [-0.277133067503733, 51.993615034818497],
            [-0.278937384543794, 51.994688046797187],
            [-0.282626039759842, 51.996043885457901],
            [-0.283402499108811, 51.996651352605937],
            [-0.283621109914133, 51.997372073896948],
            [-0.282823088216672, 51.997708419427795],
            [-0.282981293638857, 51.998713297023961],
            [-0.284037148958589, 51.999367992143867],
            [-0.281956878463323, 52.000966956486579],
            [-0.28244279588167, 52.001554904916752],
            [-0.283920407688064, 52.001811125001566],
            [-0.284773937001721, 52.001205830331784],
            [-0.285576507888658, 52.00151423989395],
            [-0.28768957099117, 52.0014964229821],
            [-0.2905350053574, 52.000762673854346],
            [-0.29207266275169, 52.001049335454965],
            [-0.294436075047553, 52.002033100859762],
            [-0.296115925231475, 52.002183296717597],
            [-0.300081021290408, 52.000771354214457],
            [-0.295352559504856, 51.995395241280228],
            [-0.30072964389652, 51.992221560349982],
            [-0.304824350988869, 51.990435480421162],
            [-0.306842443214237, 51.989060014157069],
            [-0.308517377871344, 51.986932378979738],
            [-0.310420658337498, 51.982641006583968],
            [-0.311582620687287, 51.982101087746486],
            [-0.314913143368678, 51.981861956970832],
            [-0.318228093836467, 51.982581919080189],
            [-0.321897452278533, 51.982549741076646],
            [-0.331804157884767, 51.983954713002767],
            [-0.334438698636684, 51.983870614962619],
            [-0.338701941861074, 51.982745734929708],
            [-0.341517910470567, 51.982596600883411],
            [-0.345761549432525, 51.983220960089263],
            [-0.347122400918629, 51.983833514351893],
            [-0.352371042745663, 51.983386447239894],
            [-0.352365709760961, 51.984206407700107],
            [-0.353034406312301, 51.983920836230119],
            [-0.355249151288414, 51.98464144429375],
            [-0.35613735982859, 51.985240997395927],
            [-0.358747824583068, 51.985537263056393],
            [-0.361313920544239, 51.985374281662395],
            [-0.367183471195, 51.983751897769118],
            [-0.367973726677289, 51.980078993735219],
            [-0.366986368569464, 51.979041163015026],
            [-0.365908322195507, 51.978696217198156],
            [-0.365762299000286, 51.977507298885186],
            [-0.363361115801118, 51.976114420878282],
            [-0.363758420168816, 51.975200100015783],
            [-0.361784995109363, 51.974378703841815],
            [-0.359526169656755, 51.973919257473163],
            [-0.353962205344576, 51.974183297457152],
            [-0.351952605636036, 51.973758659363554],
            [-0.350825682058409, 51.972887778600928],
            [-0.349290604804658, 51.971160557416951],
            [-0.349676260329385, 51.970530254420893],
            [-0.349112207498394, 51.970033202985441],
            [-0.351754604640556, 51.966220000728711],
            [-0.350790553773002, 51.965358590620397],
            [-0.351249132211799, 51.964603420653326],
            [-0.349199788994886, 51.963727694582239],
            [-0.351192207443934, 51.961989652164014],
            [-0.35025122434619, 51.957568765536067],
            [-0.353913716604781, 51.957150661773667],
            [-0.363330643774964, 51.952854454001717],
            [-0.366114032184701, 51.950664983782005],
            [-0.369895652165997, 51.949429799742475],
            [-0.372608454630812, 51.94800978394494],
            [-0.374130749513916, 51.947760167982608],
            [-0.374238735940585, 51.948677008088438],
            [-0.381581722275501, 51.950599880686958],
            [-0.382549978870439, 51.950907212686104],
            [-0.382938083679795, 51.951404385715733],
            [-0.383011728821466, 51.953442899180956],
            [-0.381795684146138, 51.955287458056475],
            [-0.379832797023417, 51.957055192026097],
            [-0.379399703210447, 51.95812822623104],
            [-0.379535521471055, 51.963402779106829],
            [-0.380448539464462, 51.965371015638709],
            [-0.379272366023894, 51.96730870917844],
            [-0.37823143896122, 51.971626532815286],
            [-0.378928897259029, 51.973640363784362],
            [-0.378746262097846, 51.97587675715215],
            [-0.379560906194589, 51.976582120744034],
            [-0.379655757992491, 51.97794834961794],
            [-0.380270677346033, 51.978338951410208],
            [-0.380712081237714, 51.979423113146801],
            [-0.384871903962982, 51.978295146531018],
            [-0.386669335589392, 51.979451824546473],
            [-0.388592464124074, 51.978609567916941],
            [-0.38889394682075, 51.978039130740633],
            [-0.390291557833549, 51.977719260150145],
            [-0.391328849863672, 51.977011410306375],
            [-0.391155131041146, 51.975479569114796],
            [-0.391648619573585, 51.975115856815762],
            [-0.391773289665635, 51.973504469322428],
            [-0.393349945953997, 51.971796909104384],
            [-0.393094548021633, 51.970512123162386],
            [-0.393640628213712, 51.969367751231871],
            [-0.393458154206155, 51.968567707897279],
            [-0.394183435528376, 51.968314147132986],
            [-0.394801221421138, 51.967361369260153],
            [-0.398969393380786, 51.967428005745759],
            [-0.399464718010651, 51.967662226413282],
            [-0.398907367533758, 51.964920307737998],
            [-0.400246856675435, 51.964870177304725],
            [-0.401309241097935, 51.96058033165685],
            [-0.402237251916478, 51.960514701002516],
            [-0.401695013560227, 51.956363115401253],
            [-0.40373186452538, 51.956134486490363],
            [-0.404019843535067, 51.955565625600471],
            [-0.404015049267992, 51.955331779077163],
            [-0.402836813968751, 51.954988508098666],
            [-0.403354481894131, 51.953607226074737],
            [-0.402926658530577, 51.950944404942646],
            [-0.403502582118579, 51.950502635506567],
            [-0.402142163612297, 51.948943019033877],
            [-0.403962620391891, 51.947873432772269],
            [-0.404534034753188, 51.947024277329682],
            [-0.405244205667623, 51.946979949401126],
            [-0.404742362088283, 51.94615581333646],
            [-0.405256212682089, 51.946026102326151],
            [-0.405061909916152, 51.94547408285824],
            [-0.405850510365134, 51.943422087893417],
            [-0.40632635346746, 51.941284929464892],
            [-0.405470638263149, 51.938420302140258],
            [-0.403453391535667, 51.935988601888965],
            [-0.403805961807152, 51.935850416062067],
            [-0.402509549639869, 51.933401494467773],
            [-0.405634421100857, 51.932445787707621],
            [-0.402537454026137, 51.930199053565588],
            [-0.38924048482855, 51.925772897158595],
            [-0.388591309044002, 51.925138192759952],
            [-0.389384926449879, 51.924784892311393],
            [-0.38780325560611, 51.921398560465697],
            [-0.384800066804221, 51.919461045531477],
            [-0.384432645899126, 51.91956929805179],
            [-0.383576485025735, 51.918264541320177],
            [-0.385234160827432, 51.916805467195758],
            [-0.385641211007062, 51.915672705129197],
            [-0.386637621618573, 51.915620728279833],
            [-0.387244261859517, 51.915936557306743],
            [-0.393033634370232, 51.914404435070864],
            [-0.393759494346374, 51.915454678813191],
            [-0.401245973201194, 51.913045236778494],
            [-0.402462571709291, 51.909695277926183],
            [-0.407585767798559, 51.910683653493351],
            [-0.414784775001296, 51.91152264552408],
            [-0.418365457804779, 51.91277030284116],
            [-0.41909164703546, 51.912295409529868],
            [-0.420950970871576, 51.915244449984591],
            [-0.421203107939689, 51.917507430233861],
            [-0.4223919399891, 51.920511289063938],
            [-0.421709248547253, 51.925672321581644],
            [-0.426066082266221, 51.926680171970602],
            [-0.431582162715596, 51.926939923576285],
            [-0.437843131330268, 51.927738909134661],
            [-0.438742270754512, 51.927017123132892],
            [-0.438509834659947, 51.926045644446923],
            [-0.441388093889256, 51.926168310765291],
            [-0.446530803413, 51.925574509707459],
            [-0.450326845876924, 51.924722688057329],
            [-0.451883540323052, 51.924747667170109],
            [-0.456487886125573, 51.924577047738474],
            [-0.458717231048567, 51.923942679808277],
            [-0.462414437183775, 51.923589107668583],
            [-0.465855410806356, 51.92409527204925],
            [-0.470467542617716, 51.924162484318799],
            [-0.475777569242998, 51.923676581441356],
            [-0.482304276861618, 51.922340244974158],
            [-0.485727274655109, 51.922692739341443],
            [-0.486598064949949, 51.922139268074105],
            [-0.47852280230159, 51.917322685764631],
            [-0.481588202854174, 51.912875472706141],
            [-0.482098754251703, 51.908987803997427],
            [-0.482302073837736, 51.907786452718135],
            [-0.490773047554005, 51.905227578624611],
            [-0.491546200064473, 51.906318278219011],
            [-0.494072424098452, 51.906066494600218],
            [-0.497684139457688, 51.903996034546381],
            [-0.505948566215707, 51.900604414267974],
            [-0.499833269865478, 51.897822855199699],
            [-0.484868852764398, 51.891297467314502],
            [-0.483965827267631, 51.888291631361199],
            [-0.479055740233265, 51.888507012529089],
            [-0.466646362066265, 51.888156247036946],
            [-0.455299952980782, 51.882278376879931],
            [-0.449427624472282, 51.878339224780973],
            [-0.4416742433099, 51.872113467436357],
            [-0.432724899327163, 51.862414847634376],
            [-0.425690414290147, 51.857119221160097],
            [-0.423190191305306, 51.854658871287306],
            [-0.42209458074862, 51.854473338267653],
            [-0.421094168839107, 51.854887022332463],
            [-0.416042845830785, 51.859301492503796],
            [-0.414442003939723, 51.859027260568446],
            [-0.4149502224357, 51.860764116324582],
            [-0.416150395747067, 51.861986968821],
            [-0.412567377884272, 51.862860328541693],
            [-0.41084396768421, 51.861775139363495],
            [-0.409146259929991, 51.861486938819567],
            [-0.405317930656207, 51.864366411773346],
            [-0.403530675318259, 51.866441740749977],
            [-0.400918114221638, 51.868643401879943],
            [-0.397621145008889, 51.869659568739969],
            [-0.395884833716126, 51.870572846382245],
            [-0.393209145693795, 51.869718987204557],
            [-0.391492440751451, 51.870120838705937],
            [-0.389321908037607, 51.87155589760971],
            [-0.388547006725854, 51.871859098311582],
            [-0.387017572939052, 51.871205122655979],
            [-0.380350824823677, 51.870177496094833],
            [-0.378684220369979, 51.870151843908083],
            [-0.37746871900962, 51.870830149487013],
            [-0.376049408763901, 51.869866434194741],
            [-0.375378688824628, 51.870420957075311],
            [-0.374607722954381, 51.870048840355203],
            [-0.375204825591145, 51.8694816159067],
            [-0.374084816863279, 51.869404100084353],
            [-0.369779939612845, 51.868107263830872],
            [-0.365808771790999, 51.870754194543622],
            [-0.368322792565362, 51.873052311376448],
            [-0.362895565183796, 51.874016367427629],
            [-0.358033093904593, 51.873188805428711],
            [-0.354858254376357, 51.874006765811593],
            [-0.353908124960266, 51.870547831059106],
            [-0.355239961975798, 51.867247594424221],
            [-0.353769378839639, 51.864322682040303],
            [-0.352896217186749, 51.863535369083642],
            [-0.350347532718935, 51.86083089626235],
            [-0.349548960870738, 51.857175322324942],
            [-0.347109110527151, 51.85547848293632],
            [-0.342197559769731, 51.853005859496243],
            [-0.340970280056397, 51.850567062497994],
            [-0.339510470004621, 51.849597828406409],
            [-0.341479385772498, 51.848329884852248],
            [-0.341460558329881, 51.847736159542258],
            [-0.342300948108134, 51.847386534824217],
            [-0.342062942785168, 51.846633262947101],
            [-0.344329145912317, 51.845368562837777],
            [-0.352882909547272, 51.842146395435307],
            [-0.356055013709482, 51.840505707205303],
            [-0.357695977817926, 51.840008904007803],
            [-0.358990981062573, 51.841400926613176],
            [-0.363332224096164, 51.842097133050181],
            [-0.364082759576275, 51.841175124114784],
            [-0.36439520540999, 51.839883750535911],
            [-0.364974789417688, 51.837909115814789],
            [-0.367549862628166, 51.835097178899353],
            [-0.368563956147885, 51.832860600149438],
            [-0.368754717684436, 51.831075674284911],
            [-0.370790626292131, 51.830559889291294],
            [-0.37329059758408, 51.829246620988158],
            [-0.374079298644297, 51.829187390884634],
            [-0.383240799932549, 51.831946484421664],
            [-0.392764723671455, 51.835564029005681],
            [-0.398024143717712, 51.837175171299215],
            [-0.404883303035609, 51.840599839241413],
            [-0.409200517993809, 51.842942211224589],
            [-0.412009287788878, 51.846165939416792],
            [-0.413954796078734, 51.846492511392526],
            [-0.419355171505576, 51.84873760463325],
            [-0.421525438483533, 51.850197330653238],
            [-0.421655780008451, 51.849542683303724],
            [-0.423472228383083, 51.849704614542496],
            [-0.423509184039358, 51.849394895196589],
            [-0.424872522001104, 51.8494356374541],
            [-0.424937485456602, 51.846701223458687],
            [-0.425259380464357, 51.846712726484483],
            [-0.431677920265746, 51.848879160543241],
            [-0.432749681731318, 51.848118358249167],
            [-0.439010878269345, 51.851827068093968],
            [-0.447548811298433, 51.847593461962681],
            [-0.454087531934322, 51.85302423736605],
            [-0.457899407306761, 51.850590762746336],
            [-0.461207808564104, 51.852605962577869],
            [-0.46412893787114, 51.850139052864805],
            [-0.465890883255925, 51.850798654738675],
            [-0.468273935050362, 51.853051551681048],
            [-0.469708042643398, 51.855890004636215],
            [-0.470444818562334, 51.855701769408803],
            [-0.4748098217858, 51.853895366557445],
            [-0.478323430058091, 51.853128020081762],
            [-0.477005099267811, 51.85015809539162],
            [-0.471220261482609, 51.845459536974708],
            [-0.479595526360556, 51.842135960510568],
            [-0.490958083696323, 51.842810985645691],
            [-0.492087160557868, 51.838344914284242],
            [-0.491906842812944, 51.835270146214526],
            [-0.494005313128757, 51.835626128008201],
            [-0.496757511708226, 51.837316700438464],
            [-0.501843653278456, 51.836786341896449],
            [-0.50545061445514, 51.831653925075017],
            [-0.50604195462486, 51.827291483490349],
            [-0.507993908512203, 51.826452160725097],
            [-0.511218126590871, 51.825885179822748],
            [-0.509797517896999, 51.825093005732676],
            [-0.508835410806198, 51.824039575246879],
            [-0.50337170532251, 51.82243529571906],
            [-0.501618718352606, 51.821478739008057],
            [-0.502510770091943, 51.82056845227428],
            [-0.500900296927145, 51.819386208780315],
            [-0.504300817519075, 51.817688712826879],
            [-0.503992482829027, 51.816572518480513],
            [-0.502307576904884, 51.815450489703231],
            [-0.506392968257879, 51.81395767279151],
            [-0.510721841202331, 51.811099262389519],
            [-0.517539723948501, 51.808448334326187],
            [-0.519753106480383, 51.805087246132565],
            [-0.520837709360535, 51.805761783347954],
            [-0.521882579641755, 51.805479094739432],
            [-0.524124540203058, 51.805803080356469],
            [-0.529472999013375, 51.808279855403917],
            [-0.530459246056831, 51.809443119095413],
            [-0.532242910237073, 51.808745160923152],
            [-0.532533419117939, 51.80891153580064],
            [-0.536950074152442, 51.81411699532881],
            [-0.541117346475515, 51.814990601878975],
            [-0.541189860637143, 51.814721751511051],
            [-0.543204496134771, 51.815455267090158],
            [-0.542771330471223, 51.81575112126243],
            [-0.543735779061273, 51.816976042116771],
            [-0.541709731299278, 51.818436359304435],
            [-0.544877006148601, 51.820641678014688],
            [-0.548523639443665, 51.824149420818756],
            [-0.553653958878633, 51.826704203516833],
            [-0.550071259976423, 51.828101451960059],
            [-0.549017329993048, 51.827977858038963],
            [-0.546215365208215, 51.825761886964067],
            [-0.543675710894765, 51.82450940317144],
            [-0.542183372760443, 51.825518660719908],
            [-0.541481298333768, 51.826993578202448],
            [-0.539723709663953, 51.828168558166844],
            [-0.537654098763213, 51.831402316171236],
            [-0.541717728672772, 51.832838378446588],
            [-0.545322926921439, 51.835041910364879],
            [-0.546919563245656, 51.835218998480336],
            [-0.548271229663169, 51.835883085341131],
            [-0.551072019681556, 51.839605082036577],
            [-0.553334580609368, 51.841655143380535],
            [-0.555952833094419, 51.84330220659669],
            [-0.555638533218722, 51.843824356988755],
            [-0.556258739516195, 51.844721240217162],
            [-0.557370250825105, 51.845099935515528],
            [-0.562013100596659, 51.845547016232182],
            [-0.564079255577483, 51.847087338283508],
            [-0.565393376369611, 51.848357698370734],
            [-0.566846036573705, 51.85079953651011],
            [-0.563882720843018, 51.850981895140876],
            [-0.56370638297132, 51.851230607724396],
            [-0.564166686451364, 51.851594091804856],
            [-0.56746880997636, 51.851031012085386],
            [-0.569294110149114, 51.851755467004104],
            [-0.570758266635509, 51.854577737540581],
            [-0.571467146988118, 51.85502693449348],
            [-0.569274160449463, 51.858061917519144],
            [-0.573522342170592, 51.860840644453148],
            [-0.573887757385796, 51.862465354218742],
            [-0.575605708567281, 51.864491282993775],
            [-0.57623060296739, 51.864524921975281],
            [-0.576327536126587, 51.865134823366695],
            [-0.577292689156949, 51.865984510780372],
            [-0.579775894020164, 51.866365181371911],
            [-0.582507943297248, 51.867794514562654],
            [-0.583589261988818, 51.870315278302968],
            [-0.586885558192651, 51.870530247088304],
            [-0.608996323004404, 51.875554719108017],
            [-0.61258699438146, 51.877549166451459],
            [-0.620115075276125, 51.885476468793627],
            [-0.622300239107047, 51.88453099029217],
            [-0.623047734601094, 51.884509167878235],
            [-0.628272587400509, 51.882123603853223],
            [-0.629102111921231, 51.882460560381247],
            [-0.637376722382346, 51.882594428471023],
            [-0.638050018801916, 51.883055393104883],
            [-0.642268598936282, 51.884284720666912],
            [-0.647200764932519, 51.885371044132548],
            [-0.647313179262361, 51.885885748885372],
            [-0.64801610984944, 51.885606099679279],
            [-0.648315555483636, 51.886183194730776],
            [-0.649956529268663, 51.886263173041144],
            [-0.651542799015481, 51.886957517381191],
            [-0.652005783690061, 51.886781194016983],
            [-0.654852882306386, 51.887881056785503],
            [-0.655292222670468, 51.888691711789221],
            [-0.657488362858457, 51.889796657873767],
            [-0.65793368564587, 51.890408659733254],
            [-0.659532286162915, 51.89055096056682],
            [-0.660344706780878, 51.892209241986798],
            [-0.659457465496061, 51.892333111774533],
            [-0.65897649212096, 51.893064031487846],
            [-0.659538806855676, 51.894239324755638],
            [-0.660259313388606, 51.894543347145728],
            [-0.662561107277168, 51.89597039258642],
            [-0.662902896881188, 51.895521107742496],
            [-0.665363345256023, 51.896405019099618],
            [-0.665892806774285, 51.896242882127495],
            [-0.666408539593536, 51.896642551679605],
            [-0.667279767123783, 51.896620051097983],
            [-0.667980474685354, 51.897793272624433],
            [-0.668845213671555, 51.89784351748586],
            [-0.671229423349563, 51.899989737376771],
            [-0.672346330579079, 51.90016508621278],
            [-0.672870074421418, 51.900789602590699],
            [-0.67256918241226, 51.901283434955616],
            [-0.673261581227434, 51.901808248364155],
            [-0.673731774404181, 51.901537510998672],
            [-0.67569207822534, 51.90140583048214],
            [-0.678447690853598, 51.90151323003203],
            [-0.682707968541365, 51.900269821055154],
            [-0.689726809079356, 51.900169237414133],
            [-0.69070582864202, 51.900390530974207],
            [-0.692244683831324, 51.900153182392692],
            [-0.69448405740714, 51.900719324937072],
            [-0.695171289011506, 51.901180112140935],
            [-0.695790873984136, 51.90181907451214],
            [-0.696325941760322, 51.904072851169403],
            [-0.702181221797847, 51.909109793917864],
            [-0.698540903737466, 51.913137302210828],
            [-0.695868754506614, 51.917099001141494],
            [-0.692644801025167, 51.920567179658001],
            [-0.690316500337897, 51.925422715345888],
            [-0.691488013335841, 51.927045196977637],
            [-0.688855742046216, 51.929138771919575],
            [-0.683621035230049, 51.935313170590213],
            [-0.682981740109975, 51.93583471297417],
            [-0.681602977851643, 51.935132341804959],
            [-0.680342917572371, 51.93549226561062],
            [-0.680310438192741, 51.936351475726866],
            [-0.681605464028589, 51.937628371134558],
            [-0.681305599594533, 51.938081773940482],
            [-0.681719288300558, 51.938360643006071],
            [-0.681124458398399, 51.93865339357972],
            [-0.682071224854707, 51.939853551461724],
            [-0.68234938800384, 51.941382497368899],
            [-0.683000031983802, 51.941817765250235],
            [-0.682369646844272, 51.942726931044859],
            [-0.678691248895184, 51.944429132761051],
            [-0.674855698300864, 51.945085555726664],
            [-0.673991836315351, 51.944333144494131],
            [-0.673879897639242, 51.943195376445665],
            [-0.671618316062328, 51.943367692816274],
            [-0.670370044844749, 51.943810364216944],
            [-0.670649688490081, 51.944735135961956],
            [-0.672110655591759, 51.94517061854819],
            [-0.67271033170752, 51.946049541308994],
            [-0.672165818058629, 51.946653015799832],
            [-0.670585698903262, 51.947293353230556],
            [-0.669695446029155, 51.94866347127536],
            [-0.67114876124135, 51.949657241392657],
            [-0.671534664753618, 51.950828672850186],
            [-0.673161889473514, 51.952343179745057],
            [-0.672528795233405, 51.952790107204017],
            [-0.672771217577594, 51.954239546881475],
            [-0.671954352720972, 51.955980051986614],
            [-0.671668100293073, 51.956211497274232],
            [-0.667556510433432, 51.954507041446298],
            [-0.665394943956508, 51.955015749421314],
            [-0.665634346297975, 51.955628075772289],
            [-0.664102140404174, 51.956552097336036],
            [-0.662126178117523, 51.955563094353245],
            [-0.660295898152203, 51.957843171616702],
            [-0.659511244432065, 51.959753894628577],
            [-0.655291559347357, 51.960596827765798],
            [-0.655518241415357, 51.961192844936711],
            [-0.653674494879752, 51.96390335130954],
            [-0.653656125494816, 51.965828187797563],
            [-0.653163281461883, 51.966390805149636],
            [-0.653509502402449, 51.967175212536034],
            [-0.652869158164808, 51.967753225621507],
            [-0.651256739286035, 51.968271553291764],
            [-0.653001337811739, 51.969219426519324],
            [-0.65225916415533, 51.970327659515064],
            [-0.651081242891997, 51.970529066646861],
            [-0.649880125457669, 51.971259785878296],
            [-0.64749478451889, 51.97157588542597],
            [-0.645703710668897, 51.972218863650639],
            [-0.648099982468455, 51.974395323955349],
            [-0.649099558485996, 51.976206858202133],
            [-0.651311279234243, 51.977607879123028],
            [-0.656195231121685, 51.982016402086167],
            [-0.65637375670027, 51.982717063961566],
            [-0.655813932461395, 51.983760861863068],
            [-0.656307368239304, 51.984351826505069],
            [-0.655123864468652, 51.985165519870762],
            [-0.65599305578646, 51.986728240344704],
            [-0.65548399047345, 51.987242128237959],
            [-0.655487649625281, 51.98818985386869],
            [-0.657813141804369, 51.989806038032654],
            [-0.660648462823703, 51.990790490124532],
            [-0.659346450669869, 51.993033393434963],
            [-0.660140827873803, 51.994672553608488],
            [-0.660261499427149, 51.996680782535705],
            [-0.661711769341249, 51.999726432487989],
            [-0.659407048654964, 52.000026610672521],
            [-0.658775010148525, 52.001726861501758],
            [-0.656585485610189, 52.004063024129088],
            [-0.656307000132547, 52.005384263959769],
            [-0.65067869082258, 52.009660025945834],
            [-0.649435440579932, 52.010254489805511],
            [-0.645374329506196, 52.010313961195514],
            [-0.643499102289348, 52.010844449315265],
            [-0.644520375493397, 52.012632881471887],
            [-0.644089007340938, 52.012731317407649],
            [-0.645122439002528, 52.013636939835585],
            [-0.648358465588233, 52.015194534605911],
            [-0.64913718950753, 52.014836621911037],
            [-0.650652102408332, 52.016986711500799],
            [-0.6503805123968, 52.017149939221746],
            [-0.651045955174898, 52.018475679953532],
            [-0.647541616016097, 52.018991166134178],
            [-0.64587358796951, 52.021673883446944],
            [-0.640351278303969, 52.023650406052852],
            [-0.640223694634637, 52.024060733850732],
            [-0.641510074867168, 52.02475351399103],
            [-0.640850590181554, 52.025305165337372],
            [-0.643033829309886, 52.025887787494725],
            [-0.642589439494579, 52.026700873790837],
            [-0.643186297214738, 52.026821937263421],
            [-0.642557922382311, 52.028078871157668],
            [-0.643915375367162, 52.0282581426371],
            [-0.642269589789008, 52.030283802462307],
            [-0.644839178331802, 52.03121071003433],
            [-0.643426976710075, 52.032548543219818],
            [-0.643286058302236, 52.034651772964658],
            [-0.643892351059401, 52.035524609307913],
            [-0.64309759343422, 52.036880331871608],
            [-0.643079231277898, 52.03724606420267],
            [-0.668997423018111, 52.048706052769852],
            [-0.667665685072891, 52.050384049196957],
            [-0.665783036537631, 52.051556783198585],
            [-0.664098357218685, 52.052005241994912],
            [-0.663348018465376, 52.052518224485041],
            [-0.662151651041351, 52.054340654335569],
            [-0.66125912176109, 52.05394839326361],
            [-0.659153621676115, 52.056951797301743],
            [-0.658072590568347, 52.056765064558171],
            [-0.65687324314969, 52.05799488672352],
            [-0.654404280473242, 52.059485339706924],
            [-0.653438283852541, 52.061729301297504],
            [-0.651029011412499, 52.064132972959975],
            [-0.648348733064411, 52.065791714147196],
            [-0.643721163672621, 52.066661953897608],
            [-0.641604622527674, 52.06771023153167],
            [-0.638606728067571, 52.071686620560953],
            [-0.638557455856433, 52.073114753209232],
            [-0.636467001724921, 52.077076388675913],
            [-0.635387347843236, 52.078171610594524],
            [-0.632445782635405, 52.07973795490517],
            [-0.63137802660905, 52.080963650700902],
            [-0.628179773556819, 52.082133094576271],
            [-0.625839420639808, 52.084445346140342],
            [-0.624329858566377, 52.084223640089675],
            [-0.623403671579282, 52.083730900270822],
            [-0.621175602044694, 52.084330648928002],
            [-0.61860117617591, 52.085605133208659],
            [-0.616525608236494, 52.088206224033115],
            [-0.615192313374746, 52.088935940386619],
            [-0.6112284125021, 52.090098628582055],
            [-0.609731075484748, 52.091029552194186],
            [-0.608415756155922, 52.091128222147667],
            [-0.605807337474273, 52.092110712834632],
            [-0.607293291332481, 52.09454960577218],
            [-0.60599892735813, 52.095471191308029],
            [-0.604550343241227, 52.095812803510974],
            [-0.603978720602504, 52.099112998244365],
            [-0.6025558070949, 52.100123835122858],
            [-0.598125807259966, 52.10102251400955],
            [-0.596770932081498, 52.101673543608392],
            [-0.601891847384006, 52.10501616248969],
            [-0.600984604093691, 52.105358754947652],
            [-0.6003719885208, 52.106131020455535],
            [-0.598639154732931, 52.10686389987756],
            [-0.597156540768665, 52.108555494430178],
            [-0.593271718642965, 52.110475584051663],
            [-0.591829775122043, 52.110680454965753],
            [-0.591428082852688, 52.110683749191764],
            [-0.590500069781367, 52.10876113251858],
            [-0.586776657149872, 52.107044201875347],
            [-0.583252315003011, 52.104537418798181],
            [-0.58652063992302, 52.101471083113545],
            [-0.587597254767311, 52.101082977321148],
            [-0.588385958883594, 52.09843731932714],
            [-0.589205732843856, 52.09748776954936],
            [-0.585665592716621, 52.096699983242118],
            [-0.583849087305997, 52.097312957923336],
            [-0.582546167902812, 52.098348373757723],
            [-0.58209480301847, 52.098402288388435],
            [-0.580107521993536, 52.095101971621155],
            [-0.578317426419852, 52.094103052886311],
            [-0.574973227877319, 52.09432702041719],
            [-0.572775182005162, 52.093445365701619],
            [-0.570687086838365, 52.094926999355103],
            [-0.568234648844513, 52.094697636320866],
            [-0.567642348149475, 52.094959279633059],
            [-0.566798089467167, 52.094746716642852],
            [-0.566900398551574, 52.094378420555188],
            [-0.56182394967846, 52.092313354735012],
            [-0.558681143356815, 52.089686393336642],
            [-0.557946581151363, 52.087614817034627],
            [-0.555730642166665, 52.085273340166715],
            [-0.550106747500424, 52.080693385624897],
            [-0.545821211213951, 52.081573891063314],
            [-0.54526855077702, 52.081201127248832],
            [-0.543384619252606, 52.081870191373696],
            [-0.541607038509794, 52.081871590323715],
            [-0.535624766542084, 52.077954551357116],
            [-0.533795102353825, 52.076967041680078],
            [-0.53350962533083, 52.077128930185147],
            [-0.531882453254346, 52.075931714758497],
            [-0.530804872485539, 52.075908398183955],
            [-0.522378550859755, 52.069394963545314],
            [-0.51883166097427, 52.068560108121886],
            [-0.517838757907967, 52.067879571417357],
            [-0.517635364810517, 52.067369003873239],
            [-0.518961542260181, 52.065692609594088],
            [-0.517593635499998, 52.06484011870814],
            [-0.516964733780581, 52.06383147229888],
            [-0.517918327707018, 52.060319765412373],
            [-0.514933469882954, 52.058967658951687],
            [-0.511540332907982, 52.059547953903945],
            [-0.510312515684434, 52.05870343914237],
            [-0.510793699258892, 52.05541868849523],
            [-0.509837221711816, 52.055014575545059],
            [-0.50853769556621, 52.054532384689097],
            [-0.506704721630849, 52.056489967578301],
            [-0.504511877314535, 52.056770565224056],
            [-0.505071188743318, 52.058978743323017],
            [-0.504432625575084, 52.060017234504549],
            [-0.503990198484206, 52.062519305362983],
            [-0.503747697627485, 52.063009851700301],
            [-0.502436860607889, 52.063733190536446],
            [-0.499625778210353, 52.067432442308153],
            [-0.499614005819947, 52.067696637840548],
            [-0.504779452529607, 52.070118008917916],
            [-0.504496823921881, 52.070716842412239],
            [-0.502407493257424, 52.072155901456938],
            [-0.49963651083103, 52.0756003069204],
            [-0.492704011357985, 52.079236071038039],
            [-0.486145278693462, 52.083868874381267],
            [-0.485358022221289, 52.083196999567697],
            [-0.483143260684676, 52.084468667904879],
            [-0.482364625324242, 52.084232064468019],
            [-0.480409056382186, 52.085216598424054],
            [-0.478804154101799, 52.085500705211309],
            [-0.476221689513188, 52.087017458111092],
            [-0.475362952359776, 52.086689859729915],
            [-0.471549706772022, 52.087997272558724],
            [-0.467915010703684, 52.084383223273427],
            [-0.464062687476092, 52.079600941308378],
            [-0.461930718644386, 52.079130766932892],
            [-0.459338480890317, 52.077704151337393],
            [-0.458783277431078, 52.077003658772583],
            [-0.458593398938173, 52.074000755657153],
            [-0.457775943304064, 52.072824780602879],
            [-0.453280077967975, 52.070681666880709],
            [-0.447675145172819, 52.067169673010561],
            [-0.440056615794844, 52.063276509946455],
            [-0.43776715196618, 52.065467961222957],
            [-0.435630176208231, 52.066044737634876],
            [-0.434101030716548, 52.066927168409364],
            [-0.429886106446746, 52.067762141920227],
            [-0.422303869799456, 52.067392045358496],
            [-0.416529340201917, 52.068672309798366],
            [-0.405445467014495, 52.068795315802639],
            [-0.39858770864143, 52.070318997446876],
            [-0.397040370364693, 52.070992102545141],
            [-0.395363578939423, 52.071042116460255],
            [-0.394738974724592, 52.072469544502454],
            [-0.39338355814664, 52.073320548896781],
            [-0.389672085868335, 52.074561969799817],
            [-0.384925725914361, 52.07556341324284],
            [-0.38537053625397, 52.077390275259162],
            [-0.387535414625135, 52.079555378309351],
            [-0.388515423968181, 52.079776482917389],
            [-0.389172203765325, 52.080868928399035],
            [-0.385110958432854, 52.083814699874431],
            [-0.383918566419975, 52.085753995352199],
            [-0.382315489627529, 52.084234032999326],
            [-0.37693160822308, 52.08772872648067],
            [-0.378535897904393, 52.090300776090402],
            [-0.381385073651899, 52.093128174142478],
            [-0.381210552715706, 52.093553769662243],
            [-0.374610494918999, 52.097177313422314],
            [-0.371422561760897, 52.094557270897134],
            [-0.366717125812748, 52.096806568290653],
            [-0.365502568356629, 52.097643022374591],
            [-0.367835832446066, 52.099274925489873],
            [-0.364428164269082, 52.100673520735917],
            [-0.359240801037799, 52.103534446801227],
            [-0.356131701897906, 52.107717111150826],
            [-0.353038009841393, 52.106353092781042],
            [-0.351779682242578, 52.10808524196721],
            [-0.35180000288089, 52.110425097785949],
            [-0.348464745880266, 52.112277416962485],
            [-0.344826862535906, 52.115944362282832],
            [-0.344683199974901, 52.118363738405627],
            [-0.346384399106796, 52.120585114037468],
            [-0.346803082595173, 52.122849630770524],
            [-0.349545950101971, 52.126775943393156],
            [-0.349772184245262, 52.127928212268039],
            [-0.355531492141342, 52.134505856562612],
            [-0.355387426465975, 52.134966005288632],
            [-0.362055573708105, 52.14290828770126],
            [-0.360978441599791, 52.143387848979543],
            [-0.361103671087533, 52.144156553777293],
            [-0.357820952458408, 52.145991886962982],
            [-0.356632781330994, 52.145951060250709],
            [-0.355306629448742, 52.145490198230938],
            [-0.349171765680474, 52.145318168586428],
            [-0.346257123528936, 52.145960680889232],
            [-0.347133448334601, 52.146331721936932],
            [-0.345717345496033, 52.14731440738003],
            [-0.343448082540877, 52.150844045238536],
            [-0.335090540878008, 52.156085235155885],
            [-0.333757028835971, 52.157620116524775],
            [-0.334051795998002, 52.159044020240934],
            [-0.332266804868414, 52.161350262133368],
            [-0.330087097736014, 52.165329581059936],
            [-0.329404976567027, 52.165701157057889],
            [-0.327373337804242, 52.165992459708825],
            [-0.326056616372576, 52.166908885633816],
            [-0.324436937879461, 52.166585577730174],
            [-0.321865898765586, 52.167065134965078],
            [-0.321479986543233, 52.167644983631078],
            [-0.321855594831461, 52.168427183606916],
            [-0.321286407130099, 52.168825495544318],
            [-0.316983374941159, 52.167457731234741],
            [-0.31535197881136, 52.167678110537075],
            [-0.313833496419584, 52.167232020630138],
            [-0.31080378844338, 52.167115843418181],
            [-0.308387406145541, 52.166481489045957],
            [-0.308339897788544, 52.165806452334522],
            [-0.307473105105182, 52.165452344823187],
            [-0.306048288403202, 52.165546982103123],
            [-0.306156815083313, 52.166346977608072],
            [-0.304837427048859, 52.166436820304988],
            [-0.30429654981716, 52.166894801198772],
            [-0.303437432052648, 52.168184403948608],
            [-0.303874597443145, 52.1690700502647],
            [-0.303307677510238, 52.170749584215216],
            [-0.302277707953557, 52.170570222664416],
            [-0.301850764032049, 52.170991169101576],
            [-0.302844076559698, 52.171711289472469],
            [-0.303199570580913, 52.173706200714925],
            [-0.301700044070922, 52.178212690923928],
            [-0.297887386730921, 52.181078148725263],
            [-0.294069498605753, 52.182882424224104],
            [-0.289139010937062, 52.190913175132025],
            [-0.286424008694067, 52.190752390266994],
            [-0.281793877382974, 52.189341736372491],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E06000057",
        LAD13CDO: "00EM",
        LAD13NM: "Northumberland",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-1.536973218282294, 55.335971528422668],
              [-1.536562773347264, 55.33493930683656],
              [-1.537187686439941, 55.335018052693869],
              [-1.536777539644012, 55.333690201211446],
              [-1.537644684391739, 55.332999782374884],
              [-1.540335468334228, 55.333544580373662],
              [-1.540743867974665, 55.334760989741831],
              [-1.540198179581877, 55.336417709914898],
              [-1.539748209975482, 55.336748490072978],
              [-1.53836968321486, 55.336807991033702],
              [-1.537865286251535, 55.336801592897181],
              [-1.536973218282294, 55.335971528422668],
            ],
          ],
          [
            [
              [-1.648250260903049, 55.584068869241143],
              [-1.645026897455616, 55.582824101055849],
              [-1.64439684431947, 55.583031635494251],
              [-1.644313869038301, 55.582558765421936],
              [-1.644584798172062, 55.58241937895032],
              [-1.644926705297348, 55.582676451380117],
              [-1.644692739365219, 55.582235491801676],
              [-1.644960472461361, 55.58245011818282],
              [-1.645701760679204, 55.582219540158484],
              [-1.6452080397247, 55.582790480603855],
              [-1.648250260903049, 55.584068869241143],
            ],
          ],
          [
            [
              [-1.641959801659709, 55.616390670244932],
              [-1.642432249622803, 55.615766670029139],
              [-1.644497489144445, 55.616324365818805],
              [-1.645160587240224, 55.617264349907479],
              [-1.644634142477512, 55.61719004617477],
              [-1.643658288309495, 55.61712611740257],
              [-1.641959801659709, 55.616390670244932],
            ],
          ],
          [
            [
              [-1.648161691670771, 55.617757295400416],
              [-1.64731559626888, 55.616854540408475],
              [-1.646486705999282, 55.617044441775001],
              [-1.645953237649386, 55.616868589555672],
              [-1.645961944750759, 55.61607790798633],
              [-1.648305378969113, 55.616071165983954],
              [-1.64972145620322, 55.616617926892253],
              [-1.649879237898152, 55.617263521606979],
              [-1.64871336100936, 55.617853220867964],
              [-1.648161691670771, 55.617757295400416],
            ],
          ],
          [
            [
              [-1.654987373321417, 55.617386705591464],
              [-1.654072683229364, 55.617045385826785],
              [-1.654430014864642, 55.616853208251015],
              [-1.652132982447127, 55.616096448228561],
              [-1.653857116991286, 55.615200994458796],
              [-1.655376430945711, 55.615023765834756],
              [-1.658643118928813, 55.617105801285454],
              [-1.656264045587343, 55.617550225112147],
              [-1.655803488228765, 55.617382706711275],
              [-1.656114246775842, 55.617795104231362],
              [-1.655619936620975, 55.61839932766371],
              [-1.654987373321417, 55.617386705591464],
            ],
          ],
          [
            [
              [-1.649960081546427, 55.62030976829908],
              [-1.650105159632075, 55.619530258106472],
              [-1.651117877914633, 55.620278919806019],
              [-1.651912894944371, 55.620333291183954],
              [-1.651231700732807, 55.620872272700815],
              [-1.649960081546427, 55.62030976829908],
            ],
          ],
          [
            [
              [-1.674090782907126, 55.629051485530333],
              [-1.673903687802429, 55.628822762809037],
              [-1.675212158063416, 55.62903648617263],
              [-1.675480132328934, 55.629665265356763],
              [-1.67493933894137, 55.629762672958975],
              [-1.674090782907126, 55.629051485530333],
            ],
          ],
          [
            [
              [-1.624385893555848, 55.63184947903973],
              [-1.623679622595404, 55.63146364456253],
              [-1.622515339049465, 55.631637080773331],
              [-1.621931778519182, 55.63137561039396],
              [-1.62257569891378, 55.630809721123391],
              [-1.622200384709658, 55.630530022824622],
              [-1.623136800432473, 55.62976915087885],
              [-1.623711752230984, 55.629601092298429],
              [-1.623966889934325, 55.629991835623642],
              [-1.623985310061133, 55.629560598041152],
              [-1.624543240701511, 55.629677317187955],
              [-1.62382820370205, 55.629049752192252],
              [-1.624421529733023, 55.628951831865216],
              [-1.625181725518532, 55.629341421041573],
              [-1.624841297523645, 55.630564177947413],
              [-1.626064616769935, 55.631859994236898],
              [-1.625534583729094, 55.632312135904712],
              [-1.624385893555848, 55.63184947903973],
            ],
          ],
          [
            [
              [-1.62793771759986, 55.635161486856418],
              [-1.626867846156039, 55.634583182271726],
              [-1.625286113228182, 55.634716738826853],
              [-1.623165971894641, 55.633683232313814],
              [-1.621792192207429, 55.633501998286391],
              [-1.620999106915252, 55.633059273735711],
              [-1.619918112009571, 55.633154770406826],
              [-1.619551510113351, 55.632795122017463],
              [-1.620548603127347, 55.632505287086126],
              [-1.62254417028105, 55.632769315767419],
              [-1.623520326287716, 55.632507246749107],
              [-1.625917293320972, 55.633487679409527],
              [-1.625592499131871, 55.633901809630963],
              [-1.626150610849194, 55.633672588365307],
              [-1.627443518189534, 55.634009872138108],
              [-1.62793771759986, 55.635161486856418],
            ],
          ],
          [
            [
              [-1.624564912282189, 55.636385798422062],
              [-1.625384789565066, 55.63636224516344],
              [-1.624725560185674, 55.636862509408431],
              [-1.624127422339278, 55.636620772912451],
              [-1.624564912282189, 55.636385798422062],
            ],
          ],
          [
            [
              [-1.626976049015008, 55.637741837339384],
              [-1.62666260374533, 55.636959165435094],
              [-1.62830154585261, 55.637000078657614],
              [-1.629181689998801, 55.637324412721128],
              [-1.628952870804804, 55.637836780895569],
              [-1.627686454853122, 55.637874279904771],
              [-1.626976049015008, 55.637741837339384],
            ],
          ],
          [
            [
              [-1.619732581119868, 55.639031470147813],
              [-1.622339591473151, 55.63885082498188],
              [-1.622930897836434, 55.639144663703711],
              [-1.620892013566938, 55.640043203434118],
              [-1.619890220184619, 55.640142538671384],
              [-1.619684830363712, 55.639861562043208],
              [-1.618996995533606, 55.639694100657628],
              [-1.619732581119868, 55.639031470147813],
            ],
          ],
          [
            [
              [-1.628251849242498, 55.638558873990092],
              [-1.629182937875052, 55.638370302826509],
              [-1.63099885825968, 55.639194331743127],
              [-1.630915711512011, 55.639928178800581],
              [-1.630021201511425, 55.639940761947145],
              [-1.63076063362943, 55.640370686597848],
              [-1.630168849131279, 55.640456961434886],
              [-1.628671544134022, 55.640206246367747],
              [-1.628251849242498, 55.638558873990092],
            ],
          ],
          [
            [
              [-1.607268694808946, 55.641402091804046],
              [-1.607346405075939, 55.641097739289435],
              [-1.608510661467705, 55.641440201727249],
              [-1.609957121060458, 55.642483502979665],
              [-1.607268694808946, 55.641402091804046],
            ],
          ],
          [
            [
              [-1.610875100108722, 55.643957305148533],
              [-1.609743415527327, 55.643525116195867],
              [-1.611090552432988, 55.643699211733669],
              [-1.61142149050953, 55.643972513229734],
              [-1.610875100108722, 55.643957305148533],
            ],
          ],
          [
            [
              [-1.607107524422182, 55.644173534775227],
              [-1.606443391717447, 55.643699681043834],
              [-1.608268688464175, 55.643719893793246],
              [-1.609801207559068, 55.643145222626231],
              [-1.610507619924214, 55.643364010430048],
              [-1.609325029663556, 55.643579494565579],
              [-1.610001592159234, 55.643924882684466],
              [-1.609150557553918, 55.644029101732016],
              [-1.609631977252827, 55.644506852674041],
              [-1.60857509279829, 55.644699367429759],
              [-1.607947566741057, 55.644534732105775],
              [-1.607900108284011, 55.64419583566481],
              [-1.607107524422182, 55.644173534775227],
            ],
          ],
          [
            [
              [-1.61054460658773, 55.646363412376253],
              [-1.610134826739698, 55.645707984261939],
              [-1.610817806060453, 55.646051591003747],
              [-1.612254201873432, 55.645884516042187],
              [-1.611490173283166, 55.646344842809661],
              [-1.61054460658773, 55.646363412376253],
            ],
          ],
          [
            [
              [-1.612484561595275, 55.645887040228835],
              [-1.61319671140087, 55.645852445784882],
              [-1.613180612141775, 55.646519102225817],
              [-1.612484561595275, 55.645887040228835],
            ],
          ],
          [
            [
              [-1.784974430266532, 55.647513240196659],
              [-1.786899466505198, 55.647061941801319],
              [-1.79066143461797, 55.647461961020596],
              [-1.789689974151535, 55.6508459311762],
              [-1.78981733855752, 55.653474323171679],
              [-1.791581243220425, 55.656481081790403],
              [-1.793721671965812, 55.656543995472354],
              [-1.79472353963021, 55.657033569508719],
              [-1.795269462014028, 55.65779642525635],
              [-1.795046713642406, 55.658147375345578],
              [-1.793141595320009, 55.658352640148749],
              [-1.791702858047214, 55.658122884490311],
              [-1.788398196219235, 55.65701924715917],
              [-1.78718578315664, 55.655511233218775],
              [-1.784507221043304, 55.648394770099387],
              [-1.784974430266532, 55.647513240196659],
            ],
          ],
          [
            [
              [-1.800052766681512, 55.688193070071186],
              [-1.79737288537296, 55.687226372541019],
              [-1.795160061645, 55.687912765861896],
              [-1.79329050057194, 55.688038124601853],
              [-1.79245644342068, 55.688753734997078],
              [-1.791983638131466, 55.686129269786882],
              [-1.791118352197094, 55.6855437672669],
              [-1.789357242249624, 55.685039390450449],
              [-1.78655557584844, 55.68462124068926],
              [-1.784238143029329, 55.684655831502667],
              [-1.782753979315449, 55.685859026639186],
              [-1.781141942822299, 55.686303628471848],
              [-1.77919739073394, 55.68562895961152],
              [-1.779071785155307, 55.683926943727464],
              [-1.780177530976554, 55.682431997143347],
              [-1.779812887477965, 55.680246151269046],
              [-1.780637021799767, 55.677900697551536],
              [-1.78168503231172, 55.677310440301085],
              [-1.781831967828485, 55.676622436716464],
              [-1.780935466993275, 55.675426712212726],
              [-1.781324546414141, 55.674371646493483],
              [-1.780729843614918, 55.673525081570034],
              [-1.780477533436905, 55.671446358472153],
              [-1.778607469490203, 55.668408706674086],
              [-1.778860865558654, 55.667733476615126],
              [-1.78085320567075, 55.667946400143464],
              [-1.781906165131384, 55.668419995508096],
              [-1.782020101580151, 55.668801169601139],
              [-1.788450263503944, 55.668597659268599],
              [-1.793218541497345, 55.670604977041414],
              [-1.794871936694738, 55.670310337801041],
              [-1.7963522258559, 55.669362166797015],
              [-1.796569293531108, 55.668583510746188],
              [-1.794638219639309, 55.66848865072145],
              [-1.795720031637784, 55.668340400787073],
              [-1.794912038332608, 55.668111730095717],
              [-1.799999341624202, 55.667779574429225],
              [-1.802425907972528, 55.668286669016545],
              [-1.806790236845438, 55.671750205327641],
              [-1.806451564237574, 55.672400199454621],
              [-1.806779752694284, 55.673880574254305],
              [-1.804960668767008, 55.675509409343825],
              [-1.805138409675832, 55.676860162567436],
              [-1.806062079392547, 55.678184240148553],
              [-1.809805409396334, 55.680461538195196],
              [-1.815578392558416, 55.682412925014212],
              [-1.818310249793033, 55.682827613888875],
              [-1.822285072924214, 55.682706732076205],
              [-1.828468007359897, 55.683506206147818],
              [-1.831001786396269, 55.683433340480825],
              [-1.83473060084108, 55.682443751289689],
              [-1.836389860249592, 55.681168280438726],
              [-1.840267545202621, 55.679069952099319],
              [-1.842382325990882, 55.678717766800602],
              [-1.844568676589846, 55.679557966439717],
              [-1.847866970618262, 55.679918801407531],
              [-1.850096983344933, 55.681033902132818],
              [-1.852057661403279, 55.681490026493847],
              [-1.852766584498471, 55.683278922952915],
              [-1.853589771640366, 55.683872027119513],
              [-1.853220354701189, 55.684416985597288],
              [-1.854603263167207, 55.685048487090214],
              [-1.854943047493616, 55.685632923008228],
              [-1.853698422990118, 55.687577632184208],
              [-1.851813012497246, 55.688163902466854],
              [-1.848784333271559, 55.688228503671674],
              [-1.842389675637995, 55.687186285387178],
              [-1.840532344348949, 55.687479502718851],
              [-1.838504676922229, 55.686642136553331],
              [-1.833707717807399, 55.685517991112043],
              [-1.826579839833643, 55.684675222282451],
              [-1.820075347704766, 55.684602981060848],
              [-1.816526687399246, 55.685376750469544],
              [-1.813329772560265, 55.685065544899786],
              [-1.807775027200824, 55.687529701532966],
              [-1.805674198809645, 55.687795953947933],
              [-1.801197063182327, 55.689314475821782],
              [-1.800052766681512, 55.688193070071186],
            ],
          ],
          [
            [
              [-2.119156583413331, 55.742600631866168],
              [-2.118340155863757, 55.741244677945076],
              [-2.119180754267818, 55.741649090171386],
              [-2.119156583413331, 55.742600631866168],
            ],
          ],
          [
            [
              [-2.072407685076424, 55.760934313606761],
              [-2.070488877695451, 55.760819526118667],
              [-2.067023531806761, 55.76114044046205],
              [-2.064212720051258, 55.760280282473765],
              [-2.071409518966152, 55.760595265481662],
              [-2.074117981203073, 55.761169596906747],
              [-2.073736001178583, 55.761238014851045],
              [-2.073519016150921, 55.761276879751776],
              [-2.073222799073174, 55.761185572509724],
              [-2.072407685076424, 55.760934313606761],
            ],
          ],
          [
            [
              [-2.02939818044546, 55.768841964759979],
              [-2.028390430766726, 55.768241106079245],
              [-2.025364489954985, 55.769340641695329],
              [-2.021407657400396, 55.770658606068622],
              [-2.017757961335215, 55.771471440425593],
              [-2.015026021185806, 55.771704522323454],
              [-2.013411184611347, 55.771261751373814],
              [-2.011029328619212, 55.76950901683594],
              [-2.010488444584785, 55.7674667708621],
              [-2.007764411832132, 55.766247711689282],
              [-2.006694585176688, 55.763838892357185],
              [-2.003731798619639, 55.761771569873012],
              [-2.001699974762761, 55.760947692021055],
              [-2.001161367139556, 55.761018681156607],
              [-2.000959005212322, 55.761520047892397],
              [-2.000289706546593, 55.760816525008998],
              [-1.999849896809454, 55.761053730943061],
              [-1.997685919625897, 55.76047058547671],
              [-1.995558572819826, 55.760674491687311],
              [-1.994275719120165, 55.761232411626736],
              [-1.993950534985606, 55.761930533381964],
              [-1.992559457752809, 55.76137608380585],
              [-1.990923606507659, 55.758215043748329],
              [-1.988365526867945, 55.754996394151611],
              [-1.98326076544405, 55.750971416197004],
              [-1.980401717707858, 55.749772394703541],
              [-1.979268161599559, 55.74842265719095],
              [-1.975941755835911, 55.746132667637767],
              [-1.973936648839255, 55.745562610766683],
              [-1.971653600913754, 55.74172639051767],
              [-1.969973957363867, 55.740813113273106],
              [-1.966149182775842, 55.739773454428111],
              [-1.96321052723146, 55.738282892821353],
              [-1.960761330084722, 55.733269371862526],
              [-1.956583315998855, 55.731438614895815],
              [-1.954758609352208, 55.729974291885632],
              [-1.951741044338061, 55.728866188739779],
              [-1.9497732027138, 55.72751314998839],
              [-1.946470914342991, 55.726453320197685],
              [-1.94664231193793, 55.725686068456909],
              [-1.944313436247343, 55.724318408775389],
              [-1.944109099167735, 55.723590524578704],
              [-1.937796438701592, 55.71980747734667],
              [-1.927731727043691, 55.71519173793326],
              [-1.919203848194742, 55.711985949837022],
              [-1.919623813312717, 55.711305153564886],
              [-1.918615140471452, 55.710373634607429],
              [-1.918710011311273, 55.709133752449219],
              [-1.917419031032228, 55.70854077536697],
              [-1.917275157260607, 55.708093220413438],
              [-1.913816629320114, 55.706861686571635],
              [-1.913374593711756, 55.705980835530895],
              [-1.911997233251425, 55.704961845895831],
              [-1.908535914106844, 55.704435492195529],
              [-1.908445634218184, 55.703572853945033],
              [-1.907230070110154, 55.702855830505122],
              [-1.908291060685499, 55.703670676727],
              [-1.907932007092573, 55.704112476601452],
              [-1.906267828884588, 55.70401777616199],
              [-1.903736230283627, 55.703365300931829],
              [-1.898629201484461, 55.70003041853127],
              [-1.899750976951205, 55.699371828867335],
              [-1.897422114479688, 55.699224351726428],
              [-1.895570742264215, 55.698414134086548],
              [-1.890164129260935, 55.69687656641775],
              [-1.884081235108981, 55.694462074675208],
              [-1.882973536556168, 55.6930252020002],
              [-1.882023375694792, 55.688948636109572],
              [-1.88019894861545, 55.685304296113635],
              [-1.880710612608259, 55.684931909566373],
              [-1.882643688553924, 55.684696560342118],
              [-1.882999677404275, 55.685319570001489],
              [-1.88285200627224, 55.684705743972764],
              [-1.883238637752382, 55.684652200529783],
              [-1.885458083449496, 55.684919348107023],
              [-1.883798833102916, 55.684527837210609],
              [-1.88561516220534, 55.683955387865566],
              [-1.882022143521731, 55.684593535355809],
              [-1.880353823386629, 55.68458473648171],
              [-1.877123546893351, 55.684121513613555],
              [-1.873234561819569, 55.682786867679866],
              [-1.87256787637068, 55.681418638762125],
              [-1.872874598843061, 55.680007388276898],
              [-1.874592207582598, 55.67796502780817],
              [-1.872517231160606, 55.677423785693634],
              [-1.871828378436466, 55.676549712388812],
              [-1.872892584324706, 55.676415140576836],
              [-1.87406402349139, 55.67556455198325],
              [-1.872535724221335, 55.674174770318174],
              [-1.872539485964687, 55.673505379440492],
              [-1.8716154980999, 55.672598713893052],
              [-1.868674001176548, 55.670733881920683],
              [-1.86793978791039, 55.668752764092972],
              [-1.868122016240239, 55.667982930242502],
              [-1.866172039391728, 55.665928609947024],
              [-1.86801240992593, 55.665590958897354],
              [-1.869697640738684, 55.666487679307693],
              [-1.86932239327008, 55.666034428332487],
              [-1.870012697376765, 55.665916555439701],
              [-1.869214583574104, 55.665948056051121],
              [-1.86755991961897, 55.665425145235915],
              [-1.866167897769191, 55.665744409304274],
              [-1.863574098046128, 55.664223064330358],
              [-1.860873085245469, 55.661613437085357],
              [-1.860272282506, 55.65937185052519],
              [-1.858509412083579, 55.658111908397046],
              [-1.858154573848855, 55.656905687005356],
              [-1.856273569964818, 55.655404772620038],
              [-1.852793234280881, 55.653684482800017],
              [-1.851295832135685, 55.653372691576564],
              [-1.849247311671168, 55.653399848030404],
              [-1.847079306248721, 55.652277614755953],
              [-1.845267113825825, 55.650817947640867],
              [-1.843522352239217, 55.648020442019302],
              [-1.841037264183238, 55.645661331814459],
              [-1.843086968615564, 55.644210789776274],
              [-1.844771660761536, 55.645100934499325],
              [-1.845965835943333, 55.646474478181489],
              [-1.846698990916676, 55.646706314497422],
              [-1.844935690808213, 55.645004101241163],
              [-1.843115343345428, 55.644190708102492],
              [-1.842685189272269, 55.643998492084954],
              [-1.841469286518471, 55.64293847177337],
              [-1.840475068811094, 55.64246636021916],
              [-1.839004405619659, 55.642348533468528],
              [-1.836273816872352, 55.64149043241251],
              [-1.831783813103018, 55.638618083922047],
              [-1.824117316032317, 55.637582137049201],
              [-1.818855455247325, 55.636356096575334],
              [-1.816530152229854, 55.634609512173974],
              [-1.813173401594661, 55.633706829560126],
              [-1.805518159349068, 55.63454136015902],
              [-1.802157140354301, 55.636116496591292],
              [-1.800066395341191, 55.63774483017427],
              [-1.797021635480928, 55.64166009265368],
              [-1.790093020999981, 55.64678889704048],
              [-1.789438439331944, 55.64678148743586],
              [-1.787402356036626, 55.644858738420801],
              [-1.783949246250877, 55.644106043070231],
              [-1.783211787310226, 55.643578209967835],
              [-1.783091211271393, 55.6426839672488],
              [-1.782137470983943, 55.641629211190207],
              [-1.778465015598858, 55.639837278983016],
              [-1.777857278006311, 55.637746216463171],
              [-1.775648364199652, 55.636785274836512],
              [-1.774143648042425, 55.635200217293495],
              [-1.769498974780656, 55.631973076215353],
              [-1.764762438502212, 55.625911632276939],
              [-1.765982910112214, 55.621498608072812],
              [-1.770712722529338, 55.618577435129367],
              [-1.771813793624894, 55.617351200099407],
              [-1.776368513734777, 55.616580555588151],
              [-1.777298251011086, 55.616973104029029],
              [-1.781904000205889, 55.616707318489681],
              [-1.785698497700188, 55.617005120357035],
              [-1.785328877221828, 55.616366521164572],
              [-1.786318357060959, 55.616006140461508],
              [-1.785954767283134, 55.615717978888149],
              [-1.78449572708607, 55.616009245656734],
              [-1.782920705137097, 55.615448486978316],
              [-1.77893083571317, 55.614915731952784],
              [-1.778356214001918, 55.61461818243378],
              [-1.778445409031579, 55.614002852466839],
              [-1.777301162242583, 55.612532589718185],
              [-1.775024292306189, 55.612045930674057],
              [-1.775488171613657, 55.611152744896025],
              [-1.773314796904892, 55.609251960596566],
              [-1.772090521338591, 55.60877527187408],
              [-1.770933333424634, 55.606868239408918],
              [-1.769897200995513, 55.606264288320247],
              [-1.769086435773388, 55.604857467998983],
              [-1.770775261522941, 55.603485883596484],
              [-1.769743471403219, 55.603240451848933],
              [-1.769429515845234, 55.603460900770926],
              [-1.769073705779184, 55.602969635450549],
              [-1.768625430869589, 55.60309009295203],
              [-1.768301762167032, 55.602802851948987],
              [-1.768118748617085, 55.604233862672842],
              [-1.764930004929111, 55.604254755358404],
              [-1.76413328106901, 55.604513800809087],
              [-1.759270210617278, 55.607879235893634],
              [-1.758459257412444, 55.608886690524756],
              [-1.759087845901127, 55.610702053370375],
              [-1.758191427574183, 55.61165722352208],
              [-1.755679922709042, 55.612700834062046],
              [-1.74948731571497, 55.614403646124778],
              [-1.7481254996324, 55.615592307821771],
              [-1.746861395349682, 55.615647214230556],
              [-1.745362332075218, 55.616190419529005],
              [-1.742322304248367, 55.618564276711368],
              [-1.739172505646407, 55.618280880533064],
              [-1.736548459163299, 55.617498052762684],
              [-1.734375930948275, 55.617344216930448],
              [-1.733586889736968, 55.616634467858646],
              [-1.729574420363613, 55.617389445252904],
              [-1.728689878564293, 55.61695709929181],
              [-1.72744098244194, 55.617327222467679],
              [-1.727303254650433, 55.617040286342736],
              [-1.726843209706126, 55.617213578179388],
              [-1.725424773915562, 55.616620980347577],
              [-1.722697540618316, 55.616791861531013],
              [-1.72243178549762, 55.616653786300745],
              [-1.72365058302587, 55.616503785652085],
              [-1.723238156605895, 55.616226110145135],
              [-1.722350444067783, 55.616256453049857],
              [-1.722559659870201, 55.615631550462133],
              [-1.719909694685496, 55.61410073173306],
              [-1.719213851173647, 55.613279684484084],
              [-1.71648087131773, 55.612294007818143],
              [-1.703136921441468, 55.609488708281845],
              [-1.692000503284889, 55.606160973069272],
              [-1.687808289870315, 55.601397155280651],
              [-1.685351895594891, 55.600755621663147],
              [-1.682889967830409, 55.599235260506575],
              [-1.67975260229992, 55.598056328036272],
              [-1.677018168340096, 55.595176555631461],
              [-1.673624752825175, 55.593936589147937],
              [-1.667917227409321, 55.589230935161794],
              [-1.666352395016817, 55.587228351687507],
              [-1.664811905845524, 55.586111768578121],
              [-1.661036774532994, 55.585305297672313],
              [-1.65469528577344, 55.5832048135779],
              [-1.652959468092292, 55.583067832036626],
              [-1.64899482120566, 55.583828396846457],
              [-1.649023839126669, 55.583598454881518],
              [-1.651225364827993, 55.583275868228974],
              [-1.650142047734617, 55.582902587867977],
              [-1.651462222796878, 55.583219933518208],
              [-1.652846713788237, 55.582902182655602],
              [-1.651758434065231, 55.582551365898119],
              [-1.650677849232463, 55.582765739947575],
              [-1.651522495136257, 55.582503972252823],
              [-1.650577479187443, 55.581920831110097],
              [-1.648616204723084, 55.582170414429349],
              [-1.647061092935016, 55.581701408520068],
              [-1.645423463804293, 55.582120795932006],
              [-1.645431378376035, 55.581770389992066],
              [-1.646096246099199, 55.581569240399688],
              [-1.645978292393505, 55.581103458760602],
              [-1.645232894858661, 55.580384274477858],
              [-1.644435096270433, 55.580201359536645],
              [-1.643855807147264, 55.579360447075572],
              [-1.639409000061743, 55.578232375988627],
              [-1.639252567726947, 55.577821285107113],
              [-1.640141650038462, 55.577553436537812],
              [-1.639639951620456, 55.576740585765933],
              [-1.641347914094751, 55.576243308431778],
              [-1.643841215825439, 55.576238890394826],
              [-1.647673699565761, 55.572626158020178],
              [-1.650073908853949, 55.572542274987136],
              [-1.65148045144294, 55.572200341948431],
              [-1.652255430332352, 55.571720026771359],
              [-1.652143744207266, 55.571257862290473],
              [-1.651980630625401, 55.57177046417457],
              [-1.650047691805869, 55.572458636302798],
              [-1.647602534886923, 55.572426478484665],
              [-1.645644056818777, 55.574178375277413],
              [-1.644617306835404, 55.570909224185961],
              [-1.642105696824352, 55.568602566098782],
              [-1.640187197023978, 55.566145743357183],
              [-1.638032271799255, 55.565115070582344],
              [-1.635491404415303, 55.563138849473241],
              [-1.634853433057516, 55.563031823104595],
              [-1.634549866095246, 55.561572590830821],
              [-1.633253266982887, 55.559666514141291],
              [-1.631983794510306, 55.559282629962638],
              [-1.630104539919376, 55.557657809369594],
              [-1.626500983168918, 55.557676541261337],
              [-1.628161845848077, 55.556388588747659],
              [-1.628120626717205, 55.554373038711724],
              [-1.626543085769139, 55.553568544776226],
              [-1.6250484251934, 55.553889256242471],
              [-1.625405306466686, 55.553698957640457],
              [-1.62506685986532, 55.552951236487168],
              [-1.623450496249638, 55.552240930442508],
              [-1.622761736305839, 55.551500213512455],
              [-1.618806822699822, 55.552178064589228],
              [-1.619728483177663, 55.551609453742671],
              [-1.625657787394333, 55.549980669171859],
              [-1.626450080664688, 55.550169082799592],
              [-1.627167077185979, 55.549940339514862],
              [-1.62638574584038, 55.550267726318737],
              [-1.625696045752953, 55.550124552467565],
              [-1.625573464452305, 55.550347914956106],
              [-1.626467030939099, 55.550387479825574],
              [-1.625600250081245, 55.550698427615508],
              [-1.626068096179352, 55.551171588742463],
              [-1.627576704326006, 55.551543682009694],
              [-1.630889794242485, 55.551000196490229],
              [-1.63236282685681, 55.550262426267857],
              [-1.6340119570109, 55.548647289741844],
              [-1.634876180474517, 55.547060346957913],
              [-1.63624345514915, 55.541786378554669],
              [-1.638094476727139, 55.540907690048449],
              [-1.637498731433173, 55.54107216071278],
              [-1.63715521828963, 55.540861784599358],
              [-1.633117564293671, 55.533883372366162],
              [-1.626189676978428, 55.527819742485732],
              [-1.623742208577295, 55.526661221062099],
              [-1.618471565022502, 55.526386152241116],
              [-1.620250806946968, 55.524537978490486],
              [-1.619015406270948, 55.522560042476222],
              [-1.617176285200574, 55.521930723103885],
              [-1.61622755932521, 55.522255725957045],
              [-1.615042787725286, 55.521772188292054],
              [-1.610575263561108, 55.521718542683935],
              [-1.610940063823209, 55.520060087119937],
              [-1.613574364448572, 55.518233588458735],
              [-1.614116504551651, 55.518181383418394],
              [-1.618684619410099, 55.514503551887117],
              [-1.617854020718983, 55.512788335873218],
              [-1.614122313204742, 55.510136714760598],
              [-1.613599177272057, 55.509391069727606],
              [-1.613900842261274, 55.507525737438158],
              [-1.616326395230715, 55.504596900177916],
              [-1.617069547850017, 55.503060910153778],
              [-1.61662542074158, 55.502823203631721],
              [-1.616008588854886, 55.500485047150946],
              [-1.614256815283726, 55.498154102224049],
              [-1.611330607490803, 55.495842774274571],
              [-1.609753895577602, 55.495065011168315],
              [-1.606663941771421, 55.494306640831482],
              [-1.602045898402205, 55.494421122818267],
              [-1.599547509211014, 55.493094811315274],
              [-1.598696637269219, 55.492254579052386],
              [-1.595471723147216, 55.491271744096387],
              [-1.593937336856997, 55.491351133023713],
              [-1.592367352302451, 55.492272326271554],
              [-1.591115633481051, 55.492408329998263],
              [-1.59198471060519, 55.49131769058571],
              [-1.592253566564025, 55.489956382431799],
              [-1.59291085232228, 55.489752799612603],
              [-1.591742854388979, 55.489452391986866],
              [-1.591890457512964, 55.485734678454477],
              [-1.591547587599443, 55.485225854342943],
              [-1.591951361753361, 55.485506649190036],
              [-1.593048400416693, 55.485157166806431],
              [-1.593913267941528, 55.484147368458828],
              [-1.593898826902472, 55.482935174221311],
              [-1.592557802019078, 55.481173154394249],
              [-1.59204136652999, 55.479394997958821],
              [-1.592132474827629, 55.477312456286995],
              [-1.592754775219546, 55.476952409591306],
              [-1.592261486662405, 55.476922015232155],
              [-1.592756984687645, 55.473513654354029],
              [-1.592088489604117, 55.473296676129479],
              [-1.592929149472463, 55.473385733401514],
              [-1.593350301175698, 55.47304298611003],
              [-1.592577153745162, 55.472543517330372],
              [-1.591890056316608, 55.472597839250852],
              [-1.591794844320069, 55.473086335120946],
              [-1.591217889165974, 55.471208231482024],
              [-1.592037432729151, 55.470120119101857],
              [-1.591317732469518, 55.468282873001748],
              [-1.591765388157974, 55.467973465868674],
              [-1.591173033528191, 55.467897808998579],
              [-1.59134021483746, 55.467485031871291],
              [-1.590313897699836, 55.467619082637889],
              [-1.590086514651811, 55.465599270158009],
              [-1.590476181135205, 55.465389413139405],
              [-1.589346995646312, 55.464316352827865],
              [-1.588590145620473, 55.461967686116374],
              [-1.589121566624227, 55.461353959508763],
              [-1.590943073601317, 55.461383418354259],
              [-1.591342864009677, 55.460342428714917],
              [-1.590914690527293, 55.459999548440351],
              [-1.591508197850243, 55.459952108627434],
              [-1.59312759540423, 55.458101082937056],
              [-1.593027947398792, 55.457027876440016],
              [-1.591789226935974, 55.455400063854078],
              [-1.59218551273102, 55.455304339912288],
              [-1.592288642338672, 55.454503171230378],
              [-1.589924076258194, 55.453658721366295],
              [-1.588405558538002, 55.452656230043914],
              [-1.588142695383688, 55.45132817818984],
              [-1.587134101556366, 55.450409152885925],
              [-1.587603371816486, 55.449526553858384],
              [-1.586395426005474, 55.449384102606423],
              [-1.587224941486133, 55.448066920698167],
              [-1.586941608123925, 55.447646339141684],
              [-1.586485521481644, 55.447722075947524],
              [-1.586313399794769, 55.446645022835625],
              [-1.586871883010945, 55.446753836433942],
              [-1.587678840517917, 55.446380958924529],
              [-1.587311708800735, 55.445810935371298],
              [-1.58843568304025, 55.445809326471824],
              [-1.589484703739066, 55.444651910939363],
              [-1.58941538808759, 55.443867237646053],
              [-1.590343038780005, 55.442516217580696],
              [-1.59180940463177, 55.442258735185916],
              [-1.593328686781768, 55.440233947278266],
              [-1.591078161377736, 55.438834585838208],
              [-1.591454320642002, 55.437916616012046],
              [-1.591140241277445, 55.437415070563397],
              [-1.587874704582101, 55.435923298479537],
              [-1.587299428263197, 55.434256330757783],
              [-1.584320734263123, 55.433327927133504],
              [-1.582091329351933, 55.433438049658832],
              [-1.582023618128039, 55.432966074707281],
              [-1.580847258614671, 55.432576571244574],
              [-1.578977560763905, 55.432448850903953],
              [-1.578329759391735, 55.432883321806706],
              [-1.578621693271634, 55.432329016806868],
              [-1.578124499698268, 55.432273391125022],
              [-1.57679339806798, 55.432764802650311],
              [-1.575804108336098, 55.432029951403727],
              [-1.575340794620804, 55.430879086868167],
              [-1.577549679934576, 55.428829923459354],
              [-1.577829120723711, 55.427518988860825],
              [-1.580321275356286, 55.424503001171189],
              [-1.580121599321449, 55.420834389289759],
              [-1.581812185177525, 55.418123823838165],
              [-1.581710967993538, 55.415741389952743],
              [-1.579985253093038, 55.413133243995034],
              [-1.57876824448446, 55.409939163499907],
              [-1.57882987193123, 55.408896143564967],
              [-1.580335761970514, 55.406734879276073],
              [-1.582771207030161, 55.405962353505856],
              [-1.585061265032194, 55.406396955991056],
              [-1.587781087088578, 55.405905646158018],
              [-1.592582588154208, 55.402767764528797],
              [-1.593979977792115, 55.400620347634259],
              [-1.594023582113121, 55.398521446434451],
              [-1.596665941292649, 55.3947166752071],
              [-1.603270732906628, 55.391009220176798],
              [-1.606615500957727, 55.387701616405444],
              [-1.60965997918687, 55.385660838965698],
              [-1.611405815695289, 55.38516589434581],
              [-1.612452335368519, 55.385161126041503],
              [-1.614575563381091, 55.385780653456493],
              [-1.614800946657664, 55.38695219369103],
              [-1.61621272338921, 55.388028617525009],
              [-1.617588142726067, 55.388299797039018],
              [-1.619232217990551, 55.387554621788084],
              [-1.62125814998274, 55.388304021678152],
              [-1.62045283085784, 55.388662749730493],
              [-1.618676228710288, 55.388577254582799],
              [-1.617348469818124, 55.389254222290504],
              [-1.616838403071722, 55.391071321233078],
              [-1.617298759294749, 55.392115096419687],
              [-1.618778071325884, 55.393418138640726],
              [-1.618507390078946, 55.394805576596916],
              [-1.616947179997256, 55.395171810279344],
              [-1.617124721025012, 55.394607169093931],
              [-1.61761363713457, 55.394495478568942],
              [-1.617206623782782, 55.394463655049137],
              [-1.616583678461726, 55.395211107426725],
              [-1.614943742794179, 55.394679400361838],
              [-1.613842857788846, 55.394728947076395],
              [-1.613619733771888, 55.394296033520256],
              [-1.61427641108122, 55.393655631522726],
              [-1.613446741955532, 55.394227196376441],
              [-1.613553189481132, 55.394806207846891],
              [-1.61214381798928, 55.394757719890613],
              [-1.610714466538985, 55.394171810710887],
              [-1.609474157897436, 55.394592880155507],
              [-1.609037089628997, 55.395207899796397],
              [-1.609290285113312, 55.395949125216724],
              [-1.611869092073263, 55.397823647681072],
              [-1.618390805958009, 55.399700587397327],
              [-1.619649991587041, 55.399622737573829],
              [-1.622206630804467, 55.398266636776562],
              [-1.625395067603712, 55.397700457875317],
              [-1.622757703774384, 55.397931375290987],
              [-1.619665347316664, 55.399338839325807],
              [-1.618239900508837, 55.399466490372319],
              [-1.61165907273378, 55.397503991354171],
              [-1.609637196077348, 55.395511732911309],
              [-1.609808249121531, 55.39481499256825],
              [-1.610845683814488, 55.394475044176595],
              [-1.612247269277957, 55.395157010164958],
              [-1.613928820002972, 55.395142557744052],
              [-1.615028065761433, 55.395750753896266],
              [-1.616074034313813, 55.395668675921627],
              [-1.614190273902226, 55.395044540173991],
              [-1.616199382743507, 55.395604372466821],
              [-1.618574205546891, 55.39524518258299],
              [-1.619623217020675, 55.394021009044671],
              [-1.618970060681588, 55.391182210064869],
              [-1.618418279283976, 55.391269448766437],
              [-1.617796138659476, 55.390958401204934],
              [-1.617813210160667, 55.390497490910292],
              [-1.618839906199051, 55.390763073727904],
              [-1.618856256544007, 55.389718992617496],
              [-1.619661551255345, 55.38936656495946],
              [-1.619377730239233, 55.389662208894038],
              [-1.623575259753969, 55.391811977813063],
              [-1.621391581643073, 55.390531077634009],
              [-1.623455233953412, 55.389489718322622],
              [-1.622323566550754, 55.387649564914227],
              [-1.620514740119429, 55.386482124698894],
              [-1.61897664269125, 55.384254294876406],
              [-1.618042711002901, 55.383879377237243],
              [-1.618363097991165, 55.384865204585566],
              [-1.617768796915225, 55.384951409128874],
              [-1.618203071873455, 55.385256479910801],
              [-1.618527630082389, 55.384989719614708],
              [-1.61879904993632, 55.385160394260758],
              [-1.61876351856947, 55.385572725042223],
              [-1.619215395201895, 55.38552560943927],
              [-1.619762841020713, 55.38604668236178],
              [-1.619688205179418, 55.386587386635206],
              [-1.616002574187845, 55.384300709519692],
              [-1.617654092497696, 55.383587926936862],
              [-1.617760083874624, 55.383235121773851],
              [-1.614629288310773, 55.37992666635941],
              [-1.613892102630391, 55.380106751396866],
              [-1.613589660689224, 55.379562164079751],
              [-1.613205941233411, 55.379585213015275],
              [-1.613490009460096, 55.380071336444814],
              [-1.613152102822399, 55.379927396588386],
              [-1.612990879337749, 55.380276429061645],
              [-1.613864150781877, 55.381194826825592],
              [-1.613663706733246, 55.381843857393292],
              [-1.614201666564063, 55.381867120248074],
              [-1.614462570144048, 55.382303747029297],
              [-1.613773347391849, 55.384361086343105],
              [-1.610612556293929, 55.384615247689553],
              [-1.608471018967974, 55.383944374305131],
              [-1.606044138534293, 55.373871739343237],
              [-1.603792908582113, 55.367343561753437],
              [-1.602536730565744, 55.365014889781101],
              [-1.601707209413129, 55.364199885532294],
              [-1.600276276419783, 55.364208696919725],
              [-1.600392213476807, 55.363348246423548],
              [-1.601635478212129, 55.363173486022816],
              [-1.601437932496781, 55.361480836509962],
              [-1.596223186746641, 55.354007399550859],
              [-1.588582710374834, 55.345251416256531],
              [-1.581704740978208, 55.339407129160847],
              [-1.576562615184512, 55.339789297588283],
              [-1.570542616861536, 55.339697317339557],
              [-1.580796115209045, 55.339306971880582],
              [-1.581317974604603, 55.338856777173454],
              [-1.581315807861928, 55.337865643005422],
              [-1.581990232921871, 55.337596580947533],
              [-1.582452646600533, 55.338449109981561],
              [-1.585634109302449, 55.339656831202035],
              [-1.586794359325672, 55.340577301666691],
              [-1.588707171793778, 55.341302608111505],
              [-1.591705252212769, 55.341371061484267],
              [-1.594440191568481, 55.343277946959937],
              [-1.594917044201694, 55.343520344731253],
              [-1.595744394037057, 55.346351787329638],
              [-1.59653367460799, 55.346422685068752],
              [-1.595373558849023, 55.342973726797979],
              [-1.594746451663627, 55.34246844981034],
              [-1.594078295909036, 55.342432986760976],
              [-1.59172980990101, 55.340515702100255],
              [-1.592423257000597, 55.339619442185828],
              [-1.603326521968365, 55.343919019636544],
              [-1.604296641195551, 55.344035382323959],
              [-1.602681003671885, 55.343193573758725],
              [-1.606067582427883, 55.344328642266127],
              [-1.607960587714429, 55.344558469229177],
              [-1.607449103552809, 55.34828949444055],
              [-1.607969515492166, 55.349085500431919],
              [-1.610033593878688, 55.349904406840892],
              [-1.612581034356101, 55.349842413671396],
              [-1.614115276668787, 55.34921017697085],
              [-1.614679162654428, 55.348466140884256],
              [-1.613445538114661, 55.345597603569324],
              [-1.614489716549367, 55.344751749312373],
              [-1.616464229855404, 55.344222408504862],
              [-1.617391337898548, 55.344225312290206],
              [-1.619430259040454, 55.344866064958389],
              [-1.621079788894167, 55.346992712657908],
              [-1.622659157877063, 55.347891673228418],
              [-1.627108029625766, 55.348687073520665],
              [-1.626652459039382, 55.348166310093703],
              [-1.624799820643556, 55.348140872577886],
              [-1.622620823320369, 55.347611200902023],
              [-1.620856630319741, 55.34641783429494],
              [-1.619586070444845, 55.344731763993877],
              [-1.617974752982598, 55.344060001713501],
              [-1.616379680083376, 55.34399750047254],
              [-1.614828933507264, 55.344242429469482],
              [-1.613131417094787, 55.345308168964891],
              [-1.613777460828027, 55.348597183982456],
              [-1.61339302481288, 55.349210591922379],
              [-1.61214681171792, 55.349575959151608],
              [-1.610203591119088, 55.349454764712078],
              [-1.608604572845753, 55.348651727296165],
              [-1.608032128073632, 55.347694712973791],
              [-1.609358845715901, 55.345074230520588],
              [-1.608970052964971, 55.344364913647148],
              [-1.599323424927835, 55.341703569775859],
              [-1.592876287171111, 55.338488749094786],
              [-1.587036918651179, 55.336960464586973],
              [-1.585788474151638, 55.337250974394721],
              [-1.584724271765571, 55.337107187610471],
              [-1.584797398893293, 55.336898068243805],
              [-1.586873225845818, 55.33678379125201],
              [-1.586781537676132, 55.336050245301003],
              [-1.583942277117308, 55.335607505493172],
              [-1.583191547795193, 55.335490830030629],
              [-1.582685046183491, 55.336136075828719],
              [-1.581677103721512, 55.336492061513759],
              [-1.58018316019708, 55.336291951244917],
              [-1.579885652098059, 55.335803003792805],
              [-1.579676274863535, 55.336222816953146],
              [-1.575834080878506, 55.337293230360913],
              [-1.576170820146511, 55.336908909654397],
              [-1.575506327283785, 55.33654088510464],
              [-1.573813541660703, 55.336216003252524],
              [-1.572083115040016, 55.337193000340378],
              [-1.571995866032117, 55.338560324796987],
              [-1.571741485761482, 55.337149571397141],
              [-1.570341723464775, 55.336843638825755],
              [-1.570737735478216, 55.336522441425572],
              [-1.570199917164573, 55.335379362715841],
              [-1.567832197785712, 55.334504791606236],
              [-1.565998259551235, 55.332828743927315],
              [-1.563738010346406, 55.332381297346615],
              [-1.563168115397893, 55.33131175517579],
              [-1.560391129051513, 55.328571030884959],
              [-1.554068562141621, 55.325538802409653],
              [-1.551184683405547, 55.323563062158456],
              [-1.549379049126807, 55.321720626854642],
              [-1.550656846449908, 55.316025650919876],
              [-1.549602491256087, 55.312431051020553],
              [-1.549764285233743, 55.311360541021735],
              [-1.555508089951807, 55.308994037681131],
              [-1.557846318773085, 55.307259274451859],
              [-1.558965634762447, 55.305903767766608],
              [-1.561190249476886, 55.301444036539863],
              [-1.566029319690088, 55.296781508804393],
              [-1.569942859490113, 55.291831582907975],
              [-1.570795592720989, 55.289564764785226],
              [-1.571234322982246, 55.285964790104991],
              [-1.571420820162106, 55.277855738339412],
              [-1.572412523282745, 55.276390029446638],
              [-1.572317613589455, 55.275554014884236],
              [-1.573121044748827, 55.275344758821561],
              [-1.572191771658006, 55.275399916594303],
              [-1.571188167219141, 55.276510641787688],
              [-1.570911275859014, 55.275474501798435],
              [-1.565986314023721, 55.262745730792375],
              [-1.563536059575539, 55.259034827607138],
              [-1.561812895299917, 55.257488481774395],
              [-1.56166042050409, 55.25604570341207],
              [-1.554352865490961, 55.247296672341044],
              [-1.551160914065876, 55.244126445142818],
              [-1.544523662529085, 55.238897242151481],
              [-1.540114484274718, 55.237500483132557],
              [-1.537165023883279, 55.235143120194074],
              [-1.536055025697103, 55.234724657691935],
              [-1.533357601379343, 55.232768929829746],
              [-1.529393957823064, 55.229148568541682],
              [-1.528105678070077, 55.228537945458058],
              [-1.52674583429207, 55.228396995011451],
              [-1.52543625984751, 55.227203074548591],
              [-1.525361548003833, 55.226743603396564],
              [-1.52756381323042, 55.226368445479842],
              [-1.528402631315014, 55.225495560567985],
              [-1.529034446961197, 55.223378219194387],
              [-1.528850223676456, 55.221273902248768],
              [-1.527120000335162, 55.219910337112445],
              [-1.52731714781012, 55.217731112549181],
              [-1.524090021066808, 55.212484263618549],
              [-1.518263594461988, 55.206940440625083],
              [-1.51770716461669, 55.205784446331407],
              [-1.514851176820683, 55.202759236558123],
              [-1.510507484566684, 55.199121447097646],
              [-1.507902763105744, 55.1983525568129],
              [-1.507096438647224, 55.198639550119829],
              [-1.506197247311626, 55.198177630675445],
              [-1.506242979192455, 55.198417741242807],
              [-1.504446201394181, 55.198001597202904],
              [-1.504310751696723, 55.197649695456889],
              [-1.503810191038108, 55.197729433857603],
              [-1.503893707179726, 55.198088313857752],
              [-1.501722533085692, 55.196330797600858],
              [-1.501840219797055, 55.196089555137078],
              [-1.503195776683851, 55.19622717451923],
              [-1.504762376489809, 55.1941685630498],
              [-1.505096080565703, 55.192591978030677],
              [-1.503811630412615, 55.19040676214842],
              [-1.503524835090059, 55.188965141607561],
              [-1.501985617361116, 55.187831127886525],
              [-1.501246043610099, 55.186685088996171],
              [-1.500270858197384, 55.186047586701385],
              [-1.499748460251098, 55.186126320267853],
              [-1.499009584620694, 55.185433164471959],
              [-1.500184137090671, 55.185196257604858],
              [-1.501526385294721, 55.185363495854851],
              [-1.502437265161911, 55.184481193959449],
              [-1.503725216369317, 55.184593372074438],
              [-1.503867029821481, 55.185061220553088],
              [-1.504961834457796, 55.185553606325058],
              [-1.507417406163238, 55.185609371660838],
              [-1.510512146204092, 55.184642351230011],
              [-1.512652504418873, 55.183241015484086],
              [-1.51477513306782, 55.180571647508884],
              [-1.515059704594396, 55.179119742778504],
              [-1.514770662489695, 55.177706892966533],
              [-1.515145632123375, 55.175790770429209],
              [-1.513273738447399, 55.175172269323596],
              [-1.51320893710981, 55.174820657946398],
              [-1.514528488215799, 55.174893310369278],
              [-1.514748246906428, 55.174638981967249],
              [-1.513941521968791, 55.17422780621667],
              [-1.51509796218649, 55.174155127924578],
              [-1.515096282555691, 55.173646513227389],
              [-1.516947483642777, 55.172206210341592],
              [-1.521031574266131, 55.170191473311739],
              [-1.521269404616996, 55.16881574983119],
              [-1.524146555477433, 55.165153523021083],
              [-1.525063448853492, 55.162626633271117],
              [-1.527200700463674, 55.161875616310915],
              [-1.528504079987092, 55.16182135291745],
              [-1.529251445089765, 55.162199854658546],
              [-1.527187056568405, 55.162631287641183],
              [-1.527243965377747, 55.162995441924629],
              [-1.531790786337916, 55.163292441748602],
              [-1.538207490906142, 55.162332930596087],
              [-1.540195004966547, 55.162023231339973],
              [-1.540061124703086, 55.161552084188209],
              [-1.539744583277956, 55.161201109763397],
              [-1.536797017112918, 55.161063251047352],
              [-1.534145374089262, 55.161527615983218],
              [-1.530384256245233, 55.161434118806987],
              [-1.529437585045771, 55.161720719384753],
              [-1.526974124032012, 55.160596027245589],
              [-1.524920662113359, 55.160508970730014],
              [-1.524133546040016, 55.158399129441186],
              [-1.524703469416163, 55.158050446985733],
              [-1.521129792176859, 55.149671371516796],
              [-1.516152100641007, 55.143489096485318],
              [-1.506983342665824, 55.134610092586783],
              [-1.49865475788294, 55.129589763258537],
              [-1.49732535871771, 55.127549841227271],
              [-1.486687668809852, 55.116452624190103],
              [-1.492395393321766, 55.122065875136812],
              [-1.49351411591292, 55.123310461794446],
              [-1.498626752543636, 55.128444821360468],
              [-1.504629177071681, 55.130922552790281],
              [-1.50759531255838, 55.133569282441549],
              [-1.50953256699654, 55.134064134292238],
              [-1.511157915392572, 55.135058229245637],
              [-1.513239413924444, 55.135180678232707],
              [-1.513993169920508, 55.135755198178863],
              [-1.515537463657141, 55.136203457054506],
              [-1.51637595372681, 55.137169192304114],
              [-1.516400507886056, 55.138901801984474],
              [-1.516944266078434, 55.137909200219298],
              [-1.517073472815809, 55.138641177131632],
              [-1.518147765566661, 55.139442489675261],
              [-1.517468835226664, 55.140197329937962],
              [-1.519258080291852, 55.142602773573749],
              [-1.520861582380383, 55.142194826546834],
              [-1.521926059517984, 55.143569376371076],
              [-1.522531914042418, 55.143414493800307],
              [-1.521511290420114, 55.142044614346048],
              [-1.52271465409072, 55.141641362737559],
              [-1.521997961892686, 55.140702209277769],
              [-1.523087913831697, 55.141255524042315],
              [-1.524088595327809, 55.14127111478895],
              [-1.53165732980135, 55.139170720091435],
              [-1.533154795027559, 55.139089296651704],
              [-1.534520944263888, 55.139373085940804],
              [-1.535282650275632, 55.140106556462619],
              [-1.537034237143509, 55.140706297105027],
              [-1.538955421259193, 55.142112701800784],
              [-1.541475078974746, 55.143329025709178],
              [-1.542696290209385, 55.143271606900065],
              [-1.543412807067005, 55.143722694648062],
              [-1.543772371184806, 55.143146237785295],
              [-1.546376038575519, 55.142367873422351],
              [-1.550133020220806, 55.143649718414736],
              [-1.551192664926193, 55.143589821268691],
              [-1.551898640541973, 55.143029891087139],
              [-1.55235868666575, 55.144538538503468],
              [-1.553474360617849, 55.145223769273372],
              [-1.555987081624919, 55.145991363075368],
              [-1.55627244104797, 55.146432716981508],
              [-1.558129439673532, 55.146917515005768],
              [-1.558125980316518, 55.146378340749976],
              [-1.558875918309631, 55.146665013691724],
              [-1.559393398258996, 55.146405390613481],
              [-1.553673513383395, 55.144951322776841],
              [-1.55231334972512, 55.143963265885461],
              [-1.55214220064752, 55.14285465933277],
              [-1.549705087190349, 55.142780986701936],
              [-1.549932324337347, 55.142244460332499],
              [-1.548882751177523, 55.142527237487428],
              [-1.546240803645752, 55.141565815551829],
              [-1.543362381154641, 55.142367410989429],
              [-1.541800058138775, 55.142349869886942],
              [-1.538090128017472, 55.1397434019893],
              [-1.539508107195773, 55.139208702638044],
              [-1.537292018747384, 55.139441141282809],
              [-1.535993892779812, 55.138429771880638],
              [-1.534233982316744, 55.138285576870167],
              [-1.533967193856003, 55.137622285678361],
              [-1.538243884688888, 55.136614143098228],
              [-1.543912130985643, 55.136114281567231],
              [-1.545405008758842, 55.135043326537726],
              [-1.548801393996166, 55.135828739580347],
              [-1.554191634279917, 55.134214000224269],
              [-1.561105699812975, 55.134623681081074],
              [-1.564467859505283, 55.134254725947514],
              [-1.566902009922355, 55.133541815062863],
              [-1.567696720593413, 55.13271612134448],
              [-1.567764487159779, 55.131959736478784],
              [-1.568646905521572, 55.130849489561434],
              [-1.567647349239114, 55.131478567754598],
              [-1.567239788725634, 55.13290949796702],
              [-1.566309060847526, 55.133548693969828],
              [-1.565123594978922, 55.133811358117555],
              [-1.55898681619672, 55.133898052240554],
              [-1.558048760428927, 55.133624176441138],
              [-1.556768998675261, 55.133757018585023],
              [-1.552443890179901, 55.13327304963255],
              [-1.550451242616805, 55.133617075208413],
              [-1.548375464277264, 55.134514301180864],
              [-1.544786802415384, 55.133820714667721],
              [-1.542483951474269, 55.13398822380595],
              [-1.542162387286199, 55.133304975465357],
              [-1.54382409519665, 55.132277806203767],
              [-1.54182552066428, 55.13313746830142],
              [-1.541363960813287, 55.13413408406813],
              [-1.537980851715631, 55.134669463526379],
              [-1.537237198696578, 55.134414135794671],
              [-1.536807702582069, 55.133442011166068],
              [-1.53680545559284, 55.134585029018155],
              [-1.534735089437895, 55.135120804349562],
              [-1.52900720707684, 55.136425159535221],
              [-1.527759592929813, 55.137141016585346],
              [-1.526868572501942, 55.136482479577815],
              [-1.520829772955945, 55.135942203698946],
              [-1.51778927462034, 55.135345226614504],
              [-1.516685927937966, 55.134491675816065],
              [-1.514452934589124, 55.133671363775456],
              [-1.511430954492519, 55.132478524613788],
              [-1.511457747516536, 55.132210847058019],
              [-1.510690091351808, 55.132140373716879],
              [-1.510993284076548, 55.131971753162127],
              [-1.510260378143346, 55.131497043211198],
              [-1.504594881614655, 55.12938489572916],
              [-1.500295524231436, 55.127930478668873],
              [-1.500551314198908, 55.127791343753849],
              [-1.499013878897561, 55.126332885653923],
              [-1.497277031112788, 55.125153951507173],
              [-1.496683177054459, 55.123994093404761],
              [-1.498667951528278, 55.120395253100398],
              [-1.497728965201475, 55.117753070687989],
              [-1.495312077123838, 55.118318200373984],
              [-1.494030439054996, 55.119242050967564],
              [-1.495534356712652, 55.122080699954729],
              [-1.494515192937088, 55.120462576400122],
              [-1.492915825684011, 55.119229332845386],
              [-1.493943500561146, 55.118677363176182],
              [-1.493651698002941, 55.118443411692624],
              [-1.494665483575972, 55.118368540110914],
              [-1.496789663618032, 55.116892822223285],
              [-1.497930127891372, 55.115338435564809],
              [-1.498481095310507, 55.112653858325018],
              [-1.497738477403289, 55.108117309070337],
              [-1.494556634390455, 55.102393479988251],
              [-1.488673712564805, 55.09510815092095],
              [-1.4826618514265, 55.089724344765848],
              [-1.47789486131624, 55.086188633884667],
              [-1.475889719703953, 55.085221204192457],
              [-1.474361743752339, 55.085145429875141],
              [-1.473383316654014, 55.084378280901461],
              [-1.474169096159652, 55.084186674300774],
              [-1.476628199983775, 55.084332951131827],
              [-1.477107785206354, 55.084075309884035],
              [-1.477799036852582, 55.082079768312219],
              [-1.478952112141656, 55.079935197973434],
              [-1.478071512393099, 55.078393896736273],
              [-1.477453144621965, 55.078142297458669],
              [-1.476497906262795, 55.077753621828855],
              [-1.475061101473783, 55.077794168784102],
              [-1.477575613221238, 55.078295111129805],
              [-1.478803151173233, 55.079826726993659],
              [-1.478197024789199, 55.080930741258612],
              [-1.47758580741163, 55.082043964734552],
              [-1.476627462936765, 55.083789285399746],
              [-1.475534239416088, 55.08413324839735],
              [-1.473223455244266, 55.084026231147881],
              [-1.47257650294328, 55.084381980677207],
              [-1.474446074926257, 55.085643626695806],
              [-1.473257749704895, 55.085350939202371],
              [-1.472146993457977, 55.085593252500189],
              [-1.47118720551541, 55.085434529585626],
              [-1.469999410531344, 55.084985453344935],
              [-1.47006819466828, 55.084759301234079],
              [-1.471837409749778, 55.084596244768392],
              [-1.469992210142897, 55.084700560749532],
              [-1.469811976108861, 55.084469731042184],
              [-1.469904430719187, 55.083638014688979],
              [-1.470821784243998, 55.082853915204289],
              [-1.469198464174063, 55.080047664659119],
              [-1.468441392893851, 55.079613927113805],
              [-1.463867490749096, 55.079626239447563],
              [-1.463588313358942, 55.076842891606745],
              [-1.461758418422717, 55.074314199455536],
              [-1.462167362134988, 55.0741839083583],
              [-1.46405810359037, 55.07462177867977],
              [-1.466632636161214, 55.074403029185348],
              [-1.467358617443941, 55.07492830170618],
              [-1.470703607422385, 55.074479191076406],
              [-1.474123795329653, 55.072349898472417],
              [-1.474250075704822, 55.071317931645432],
              [-1.477460871047855, 55.070321697277926],
              [-1.47963107080764, 55.067201997455278],
              [-1.482258477103844, 55.066967867895585],
              [-1.483615594629866, 55.066153188965231],
              [-1.486628184194494, 55.066034721678861],
              [-1.488447396177082, 55.065564308689424],
              [-1.491244358482894, 55.065899522397167],
              [-1.492272973569498, 55.065909204892229],
              [-1.490703005453653, 55.063399102682567],
              [-1.487251773972687, 55.055709522749993],
              [-1.487800165772907, 55.05358479708832],
              [-1.490258303438687, 55.054402966758985],
              [-1.492564039502216, 55.054406302630134],
              [-1.496495946034989, 55.05193254283364],
              [-1.501068756997788, 55.050492898174618],
              [-1.502858037222511, 55.050346549587672],
              [-1.503161781743038, 55.049832879816691],
              [-1.507371806779557, 55.048327716693699],
              [-1.508784529639141, 55.048119550262868],
              [-1.510270404344293, 55.055001765628276],
              [-1.515240734746246, 55.054982105529646],
              [-1.515071100686434, 55.054122351278622],
              [-1.519952233755405, 55.054745554567802],
              [-1.526267056242685, 55.054471034365882],
              [-1.526089607162718, 55.053855689561047],
              [-1.532325705686941, 55.054142185224826],
              [-1.539854270396694, 55.061583543158747],
              [-1.540568845103089, 55.061253751777876],
              [-1.541123223340245, 55.059734483558692],
              [-1.541840621796434, 55.059702137367488],
              [-1.542018478596803, 55.059070180087382],
              [-1.542921449564868, 55.058413087897215],
              [-1.544925022121289, 55.057885911259234],
              [-1.5475707248724, 55.056634107158423],
              [-1.551567423179091, 55.055782617115959],
              [-1.5561665598848, 55.054228676890617],
              [-1.559795012751206, 55.054516818065089],
              [-1.566622115600287, 55.054609589370095],
              [-1.570635264795099, 55.055236654660149],
              [-1.572332093217255, 55.05582941881768],
              [-1.575066031579569, 55.055612533661183],
              [-1.575949491738137, 55.056002915055345],
              [-1.575974507353873, 55.056450512636474],
              [-1.576840192460274, 55.056599098063202],
              [-1.577151941878237, 55.059252892479705],
              [-1.578525508411126, 55.063074068125502],
              [-1.57903687676335, 55.062983278599923],
              [-1.581416565228104, 55.062998665643256],
              [-1.584261073919188, 55.062427899297361],
              [-1.588511260760388, 55.063402944870319],
              [-1.589846762134403, 55.064020302164487],
              [-1.592100965884756, 55.064218375043012],
              [-1.594882060599893, 55.06402905932476],
              [-1.595707888030457, 55.063649891325142],
              [-1.598984736685946, 55.064296039135975],
              [-1.599814740065894, 55.064908027641131],
              [-1.600634475128187, 55.064823551486199],
              [-1.601350056695409, 55.065135916171521],
              [-1.603741083273185, 55.064809430809596],
              [-1.603890727248462, 55.065194523756148],
              [-1.605638597117482, 55.065147174694189],
              [-1.605834410885883, 55.064818915863384],
              [-1.60759569761183, 55.064358220132796],
              [-1.609166875059792, 55.064282394367055],
              [-1.609485209985206, 55.064553897468592],
              [-1.611818179835364, 55.064229763417927],
              [-1.612211564337193, 55.064514078997071],
              [-1.612618745662786, 55.064342840419613],
              [-1.61510607584073, 55.064735328446083],
              [-1.621254266199615, 55.066451172712775],
              [-1.631041678557186, 55.065147658162296],
              [-1.631217724809739, 55.06558491651721],
              [-1.632860174844615, 55.065934044192026],
              [-1.633946489424382, 55.065114183508236],
              [-1.636256794403546, 55.06519928052446],
              [-1.637879748663222, 55.064761996878858],
              [-1.639742676980991, 55.069159033043107],
              [-1.641792415051735, 55.07074483313913],
              [-1.645559733449007, 55.076824163331295],
              [-1.647667336489286, 55.079382325384493],
              [-1.651003170111816, 55.079072915908533],
              [-1.654554786469517, 55.079163907998108],
              [-1.661833584053242, 55.078406123510483],
              [-1.679703625513866, 55.075036081653813],
              [-1.685820662086285, 55.073482131861283],
              [-1.691105060736497, 55.072819883937882],
              [-1.690612883041471, 55.070438219727379],
              [-1.691324659319986, 55.069720238145159],
              [-1.696708542006865, 55.070528124254402],
              [-1.696829835578015, 55.070848330886086],
              [-1.699282013511395, 55.071079057587497],
              [-1.701003576838953, 55.071218084007917],
              [-1.701085937824327, 55.070883104828425],
              [-1.704599701362858, 55.070926716593057],
              [-1.704093939249768, 55.065821388652971],
              [-1.713746405802325, 55.065473313972667],
              [-1.71052655930751, 55.052744079884917],
              [-1.697913167758829, 55.052372891927945],
              [-1.696704381841326, 55.051181924336142],
              [-1.695436465469266, 55.050573996024582],
              [-1.694619915144873, 55.049481037069988],
              [-1.695032926832044, 55.048681407678203],
              [-1.693971059314095, 55.046104219951523],
              [-1.694901643112, 55.042886822577813],
              [-1.700196487824094, 55.043619754666651],
              [-1.7007793094498, 55.04289960088326],
              [-1.703706988488854, 55.04229659674769],
              [-1.707337553801091, 55.042074427278266],
              [-1.708639781770889, 55.042254574830139],
              [-1.708605703715176, 55.041343299572503],
              [-1.711564042901816, 55.041232619979048],
              [-1.711144726464638, 55.039482925140568],
              [-1.716929488776136, 55.038619454616722],
              [-1.714433584400408, 55.034017220902747],
              [-1.721592644479665, 55.034065241161009],
              [-1.721513161130113, 55.033569024577687],
              [-1.724935017280917, 55.03307986405914],
              [-1.726908984702497, 55.03314630873043],
              [-1.727461747377463, 55.032612871059953],
              [-1.728680012274766, 55.032220199794139],
              [-1.731887656163424, 55.031682740205454],
              [-1.732315063907326, 55.029991589433749],
              [-1.735088504627907, 55.029551938402363],
              [-1.739307391909571, 55.029588895142346],
              [-1.739165118994732, 55.028370069673976],
              [-1.737968101899759, 55.026976445980338],
              [-1.739273675886625, 55.026827380930115],
              [-1.740076894678619, 55.025501843205213],
              [-1.739684630646141, 55.0252053614592],
              [-1.740018758434797, 55.024574348632427],
              [-1.742663878030084, 55.024072246779802],
              [-1.750231663352851, 55.024014309304413],
              [-1.751043887150858, 55.015607627592907],
              [-1.756330777379119, 55.015937325189221],
              [-1.756863118161158, 55.007811301877041],
              [-1.756549824653476, 55.00325648337499],
              [-1.757595983324101, 55.002951242459332],
              [-1.773829334883503, 55.004132693972139],
              [-1.773647995148195, 55.002740398972186],
              [-1.774161015093638, 55.002696420251525],
              [-1.773563120712692, 54.996151574837903],
              [-1.775007760134073, 54.994968974023898],
              [-1.775672133309261, 54.989623416840757],
              [-1.774252217184331, 54.988385191705362],
              [-1.772607890842429, 54.98760572556548],
              [-1.769835232600452, 54.981377572606192],
              [-1.774051571811197, 54.981808718385999],
              [-1.782470289557343, 54.984438131922857],
              [-1.785000297027707, 54.984707717044479],
              [-1.784959123420746, 54.984517136947524],
              [-1.792098597487931, 54.983639000177149],
              [-1.799720726172577, 54.979590008801885],
              [-1.802718225384327, 54.978797824395862],
              [-1.804854777568505, 54.977997899485779],
              [-1.812801000467087, 54.976314673923632],
              [-1.813078983871002, 54.974653551156784],
              [-1.811969125150297, 54.974127048345863],
              [-1.8132422274346, 54.973138726675259],
              [-1.811689769884014, 54.972729261092965],
              [-1.812586527118322, 54.972041401769722],
              [-1.812428736955533, 54.97103739889922],
              [-1.813783458294893, 54.970955007202235],
              [-1.813448946542837, 54.970328156203713],
              [-1.814228281190382, 54.969680542633022],
              [-1.814042590367505, 54.969300142349439],
              [-1.815986246655516, 54.968851087640132],
              [-1.815075224079619, 54.967909749559773],
              [-1.815498210599071, 54.967294835092268],
              [-1.816732673303668, 54.967180776601737],
              [-1.817446405854356, 54.967543094417366],
              [-1.817694627469787, 54.966881181900874],
              [-1.818742086489533, 54.966320207879214],
              [-1.820306992597029, 54.966350383939201],
              [-1.821078408864412, 54.966037001887955],
              [-1.821728896843822, 54.966223970434342],
              [-1.822023202026756, 54.965719375289247],
              [-1.821266150137824, 54.964909506867166],
              [-1.822581609055349, 54.96482516239319],
              [-1.822548649044045, 54.963797090589836],
              [-1.823405800583215, 54.96279277798368],
              [-1.824442552961143, 54.96249593410225],
              [-1.824679836814605, 54.961801640708622],
              [-1.82455950738364, 54.961459990870857],
              [-1.823922236088855, 54.961479739576994],
              [-1.824114803912874, 54.961012734050072],
              [-1.82361508714407, 54.961005721026993],
              [-1.822838534421453, 54.960381848393133],
              [-1.823694853593001, 54.959906820899562],
              [-1.822900037871697, 54.959534536508208],
              [-1.824116031220026, 54.959299062172384],
              [-1.823059311945138, 54.95775819028313],
              [-1.823500131846693, 54.957297836227845],
              [-1.822619361577665, 54.956598326318314],
              [-1.82298694071989, 54.95574606752961],
              [-1.824413705370455, 54.95513257582833],
              [-1.825318484989568, 54.95569641521481],
              [-1.82610537362327, 54.955359658884511],
              [-1.826500919332837, 54.955609141705615],
              [-1.827206780431642, 54.955230027492064],
              [-1.82879254693103, 54.955395816460523],
              [-1.831298341256426, 54.954745115261282],
              [-1.832934503304927, 54.953298789389464],
              [-1.833691842829015, 54.953285447900299],
              [-1.833391635699833, 54.951495877634613],
              [-1.833829652744138, 54.951291589787125],
              [-1.832030581733652, 54.9495161364963],
              [-1.832253826823684, 54.949144413747156],
              [-1.831370344196804, 54.947979475061132],
              [-1.830327899212207, 54.947503553405191],
              [-1.830531799656949, 54.947278282548112],
              [-1.829883126270991, 54.947094058719657],
              [-1.829674467140235, 54.946596827617924],
              [-1.830122425600212, 54.946247888303297],
              [-1.829841657463349, 54.945821548168354],
              [-1.831238225048697, 54.944853875297014],
              [-1.831067169025693, 54.944322550758748],
              [-1.829465317861507, 54.943631072630438],
              [-1.82712271439437, 54.941689434610915],
              [-1.825152945359395, 54.940930878050615],
              [-1.824846569866286, 54.940311284641368],
              [-1.824654000898065, 54.939007101418156],
              [-1.827197776320641, 54.936553894355136],
              [-1.826558661986523, 54.934188699517605],
              [-1.825605850908647, 54.93365175702904],
              [-1.827273982662858, 54.9311460673376],
              [-1.827001259980847, 54.930694569335763],
              [-1.826940880463566, 54.93021821113539],
              [-1.827635678654806, 54.930132029907966],
              [-1.833486119905037, 54.930492433405988],
              [-1.841138873371769, 54.929718156827569],
              [-1.841593541034826, 54.927983500088757],
              [-1.842591341158345, 54.927807764472689],
              [-1.843986214313203, 54.927037638750647],
              [-1.844355749961389, 54.92589505749914],
              [-1.846730214005471, 54.922707940834087],
              [-1.848521786900717, 54.920928203217763],
              [-1.851882572585421, 54.91874417763453],
              [-1.852076872909433, 54.918511669038494],
              [-1.851412698514604, 54.918399430423833],
              [-1.851941943397129, 54.917443035596264],
              [-1.852711168659948, 54.917406226098343],
              [-1.850360588262655, 54.915199921961708],
              [-1.84901990405224, 54.914552152516656],
              [-1.84583528094109, 54.914364841371643],
              [-1.842293769683736, 54.913301719935042],
              [-1.839683268554172, 54.913123077502092],
              [-1.835672998576993, 54.911943221298102],
              [-1.834413995667588, 54.912000824819771],
              [-1.833975877607823, 54.911575176173287],
              [-1.831127136083608, 54.910588156298189],
              [-1.826710330181072, 54.910124545761462],
              [-1.826857510812502, 54.909628711330114],
              [-1.824991056333987, 54.908785822648646],
              [-1.824412428124015, 54.9080642876566],
              [-1.823695251512059, 54.908003042682836],
              [-1.822102232611748, 54.906015649463299],
              [-1.821002767559196, 54.905654585076199],
              [-1.822703556514806, 54.905458477121009],
              [-1.825397061238826, 54.905785878085737],
              [-1.827686231357589, 54.905486301880003],
              [-1.830501976219228, 54.901591993868699],
              [-1.834676126615306, 54.899018662753484],
              [-1.839108264772674, 54.897306418250004],
              [-1.843553947008185, 54.897234931232497],
              [-1.845438806970629, 54.896895859111609],
              [-1.845864514420808, 54.89606786029217],
              [-1.844496054378746, 54.892690856964052],
              [-1.845628013574014, 54.891037016626633],
              [-1.84746460123646, 54.889774955781284],
              [-1.848610487095672, 54.889760211043338],
              [-1.854019130238034, 54.891311567523353],
              [-1.854426515921459, 54.891177260514802],
              [-1.855068910281395, 54.890283887575634],
              [-1.855149773054277, 54.889034884222987],
              [-1.851334137841899, 54.886323600679404],
              [-1.85095360180172, 54.884681331907878],
              [-1.852336241602428, 54.884248078539578],
              [-1.852946747672688, 54.883533505261013],
              [-1.853042824341384, 54.883259538003848],
              [-1.852259447395542, 54.883081558752991],
              [-1.853255001383339, 54.880198147918534],
              [-1.857756068165762, 54.879346194491092],
              [-1.860039231985992, 54.87802695016245],
              [-1.859898698952941, 54.872702376853887],
              [-1.860818796934699, 54.871138009711288],
              [-1.859769037046889, 54.869197548948208],
              [-1.861268144260225, 54.866423380517354],
              [-1.864928597635438, 54.864378607386961],
              [-1.866443988207942, 54.864063061129443],
              [-1.869661475128367, 54.861550366789174],
              [-1.869715359565375, 54.860296826005978],
              [-1.870549625774434, 54.859548252931717],
              [-1.868714410685234, 54.857669932459302],
              [-1.867403780477765, 54.856899277833811],
              [-1.866591007219971, 54.855377894626479],
              [-1.867228093544992, 54.853947061271391],
              [-1.869462184754682, 54.852814498436167],
              [-1.869449644533603, 54.851872712168841],
              [-1.870400462344554, 54.850665059931572],
              [-1.877007132029821, 54.848102708692295],
              [-1.877970917716992, 54.847138540833697],
              [-1.881372148305728, 54.846582950236865],
              [-1.881829357906626, 54.846799966987604],
              [-1.884750741760337, 54.84574057710936],
              [-1.886249727067336, 54.844307759247542],
              [-1.88763920817725, 54.843580253434055],
              [-1.8891694172188, 54.843759586939505],
              [-1.889069190016535, 54.843967081300704],
              [-1.894014134675354, 54.843630902916026],
              [-1.895156116701638, 54.844613205715895],
              [-1.894513378151189, 54.845061969994681],
              [-1.894960678418707, 54.845517966707909],
              [-1.896216438992999, 54.845883891087375],
              [-1.897459479670071, 54.845746553993557],
              [-1.898478234616523, 54.845000638814192],
              [-1.898208238492847, 54.843997532204398],
              [-1.899321009681021, 54.842991981592768],
              [-1.902236051260228, 54.84232127632535],
              [-1.904288659383644, 54.842185415269689],
              [-1.904769421725466, 54.841687047364282],
              [-1.906037463551907, 54.841443602531228],
              [-1.907168932048918, 54.840316677767419],
              [-1.908805649105134, 54.839507341507399],
              [-1.910982463679888, 54.839457729662485],
              [-1.912558999847192, 54.839790469800079],
              [-1.914119785140513, 54.839485144216141],
              [-1.914248816120732, 54.838843606380593],
              [-1.914962872555527, 54.838387597998178],
              [-1.914731805805808, 54.837948900211643],
              [-1.911372858215371, 54.837509763246693],
              [-1.910946147025298, 54.836850748827167],
              [-1.911598648359417, 54.836064914110381],
              [-1.913657488964827, 54.835762650762803],
              [-1.913013246045892, 54.834895903094726],
              [-1.913538094332363, 54.834074020864236],
              [-1.915191638456875, 54.833970042579828],
              [-1.917691509562901, 54.834910836743774],
              [-1.916810463120711, 54.836369629900346],
              [-1.917030606474621, 54.836827187842083],
              [-1.91974012013221, 54.837367288529514],
              [-1.92074625153751, 54.837983515542277],
              [-1.918264736673998, 54.839394537540059],
              [-1.918128067209052, 54.840765769692709],
              [-1.919865432484842, 54.840925984080272],
              [-1.924150357144626, 54.840161288894606],
              [-1.927184423204619, 54.841365519127592],
              [-1.926974894511061, 54.84185425350406],
              [-1.925058876925033, 54.8422763493448],
              [-1.920967992053095, 54.842445404675551],
              [-1.920603772756652, 54.843961171948386],
              [-1.921792905201001, 54.845866156628887],
              [-1.921308229647297, 54.848464706984359],
              [-1.922400605754352, 54.849744169654109],
              [-1.92615720171195, 54.848818210659651],
              [-1.928982764070517, 54.850318818036008],
              [-1.929416091823445, 54.850110585722859],
              [-1.931828774663188, 54.850897368331353],
              [-1.932019286032939, 54.851527419795737],
              [-1.930743281389455, 54.851780117425733],
              [-1.930908197390367, 54.85279926607322],
              [-1.931257535239497, 54.853451874381015],
              [-1.932162402328935, 54.853493718552784],
              [-1.932672141602695, 54.854179661433072],
              [-1.934980886018269, 54.853922106845161],
              [-1.932982930978251, 54.852733026376953],
              [-1.933746693090389, 54.852380279220242],
              [-1.939320369581552, 54.851773004977012],
              [-1.940033399095097, 54.851975551072755],
              [-1.940371603728335, 54.852880644880983],
              [-1.944467110697295, 54.852321827996477],
              [-1.946903155265882, 54.853434526741047],
              [-1.949048497651484, 54.852959163280893],
              [-1.952403485911766, 54.85423388631223],
              [-1.953832309701349, 54.855145652853672],
              [-1.959402899037037, 54.856150509526906],
              [-1.962765949162989, 54.857772720597914],
              [-1.964192917327249, 54.859431130990664],
              [-1.967824077686755, 54.859465388933756],
              [-1.969205623919009, 54.859738929543504],
              [-1.970812679925038, 54.860557980546581],
              [-1.972456402739343, 54.860234851830484],
              [-1.972781418985082, 54.86108413560509],
              [-1.974499356056014, 54.861669516824143],
              [-1.97475772313542, 54.862055983721511],
              [-1.97319180452843, 54.862432180039349],
              [-1.972216852084817, 54.862005110961],
              [-1.970684394739518, 54.863481212832156],
              [-1.973447008296287, 54.865274620325813],
              [-1.977763468846292, 54.866698916378169],
              [-1.980227608348162, 54.868175794753192],
              [-1.982779623316022, 54.868610218565344],
              [-1.986438243075145, 54.868247625701962],
              [-1.988190809396325, 54.869365706834451],
              [-1.990354945169169, 54.870028187147334],
              [-1.99535361651735, 54.870031166332474],
              [-1.996587739767519, 54.869554928165371],
              [-1.997581839301914, 54.869811060805084],
              [-2.000029737948517, 54.869287175488203],
              [-2.001042554069152, 54.8696457241426],
              [-2.001410238187827, 54.868555675498321],
              [-2.001929084271483, 54.86833280576861],
              [-2.004227291665843, 54.868102692012073],
              [-2.005992705130801, 54.868548336915701],
              [-2.008293909229373, 54.86780502545286],
              [-2.008913899400393, 54.86721008373646],
              [-2.011760362341927, 54.866703903738426],
              [-2.012373881301952, 54.865561675932682],
              [-2.011929723600478, 54.865154639455312],
              [-2.012066617729537, 54.864466270806076],
              [-2.014168141377116, 54.863949323838177],
              [-2.014512252313565, 54.863410101037715],
              [-2.016638483723436, 54.862575889764855],
              [-2.019026663849184, 54.862400300001482],
              [-2.021958773883034, 54.859841381970554],
              [-2.02242239961537, 54.858776412148288],
              [-2.023154415074016, 54.858552512743245],
              [-2.025129910182278, 54.858985258599333],
              [-2.026094082594757, 54.858857446830498],
              [-2.027479816076851, 54.857878523037662],
              [-2.027606515773539, 54.856379567975793],
              [-2.028746136388836, 54.855558845649043],
              [-2.030251805018287, 54.854815303886895],
              [-2.034820686063768, 54.853517336608206],
              [-2.036207753750543, 54.852727924425075],
              [-2.035980577392554, 54.851238051428858],
              [-2.039748277602315, 54.848283944613677],
              [-2.0415794768723, 54.848046087046072],
              [-2.043719444323277, 54.848210680307361],
              [-2.045352630068263, 54.847791312479387],
              [-2.047406895960396, 54.847913636936092],
              [-2.049611613460757, 54.847530830283475],
              [-2.05329763091178, 54.847406147129377],
              [-2.057190579184895, 54.846066295252278],
              [-2.059317355885943, 54.845761530635684],
              [-2.061407034172348, 54.84463718913225],
              [-2.064411853035784, 54.844262690937306],
              [-2.065049711091016, 54.84389570491296],
              [-2.066151233028705, 54.844257258937745],
              [-2.06687487109889, 54.844919157795609],
              [-2.067883738126355, 54.844792786428421],
              [-2.068487203600558, 54.84344448802041],
              [-2.069797793847747, 54.843131012606953],
              [-2.074323002304076, 54.840663350176705],
              [-2.076410250320302, 54.840258561101813],
              [-2.078664294887537, 54.839176952190741],
              [-2.079376556559382, 54.839538638914853],
              [-2.081088916736736, 54.839361370431661],
              [-2.082467637879033, 54.838214673268588],
              [-2.087472529779986, 54.837869677072845],
              [-2.088507822707132, 54.83712395208542],
              [-2.09058456506277, 54.837731694013321],
              [-2.09222661814904, 54.837502199829103],
              [-2.093551702678251, 54.836887412133223],
              [-2.097717683587571, 54.836755616456806],
              [-2.098445986266879, 54.83725826506781],
              [-2.100418511200687, 54.837217105393606],
              [-2.101400483740329, 54.837658418142723],
              [-2.102864917193533, 54.839846270068108],
              [-2.103910689333466, 54.840237184340197],
              [-2.104971909530432, 54.839974765136525],
              [-2.10866861657369, 54.840668849641602],
              [-2.111409067482659, 54.840114595554006],
              [-2.113908667251281, 54.840310870502343],
              [-2.116298252849642, 54.841225212804083],
              [-2.118126810053448, 54.840879264812486],
              [-2.119710116022008, 54.841326132077135],
              [-2.120723767736862, 54.840802118037921],
              [-2.121908772634638, 54.841343710011991],
              [-2.122929560583567, 54.841127005176922],
              [-2.12494137357404, 54.84165244906827],
              [-2.128636968949364, 54.841841790003024],
              [-2.129145107917561, 54.842491864690508],
              [-2.130998756610328, 54.842696562292069],
              [-2.13342913135112, 54.842129565177423],
              [-2.134809568686901, 54.842845152027877],
              [-2.138154217815049, 54.842362410708823],
              [-2.139781243295505, 54.841863597405599],
              [-2.139079513510675, 54.838400147014319],
              [-2.139732310591984, 54.834474133689447],
              [-2.141462119726921, 54.833192462049304],
              [-2.141823288939767, 54.831862050844443],
              [-2.143434399165754, 54.830081743189879],
              [-2.143122875421443, 54.826952141757509],
              [-2.151283818082824, 54.822807574990563],
              [-2.15715314495817, 54.820469475577177],
              [-2.160861812036416, 54.818525786673618],
              [-2.164257359000127, 54.815820807552726],
              [-2.166342342156095, 54.813882284185553],
              [-2.169462662335631, 54.803602852040214],
              [-2.171667248228183, 54.804028403336382],
              [-2.173844389222043, 54.806020293614381],
              [-2.177408933251741, 54.806814024226171],
              [-2.182289368471844, 54.807163544574877],
              [-2.186823302651813, 54.806242718160966],
              [-2.198593287280042, 54.806729044881962],
              [-2.199302356382726, 54.805709715100114],
              [-2.198616803306964, 54.80454799208357],
              [-2.201513743019094, 54.799919673267475],
              [-2.203789600049834, 54.797791466106659],
              [-2.206851238912671, 54.793950851174479],
              [-2.209394553810351, 54.792235457786752],
              [-2.212430488028411, 54.788122451497408],
              [-2.214455177121786, 54.786659479596132],
              [-2.21504978313425, 54.783845653011056],
              [-2.218899459259423, 54.782370367096291],
              [-2.219529413333259, 54.782990194808391],
              [-2.22669077252467, 54.782943761767889],
              [-2.234056870499557, 54.784158215707592],
              [-2.235513565136948, 54.786790235577264],
              [-2.248655724908173, 54.787952902828671],
              [-2.250966314411523, 54.789426420117238],
              [-2.250426306529994, 54.789866077885549],
              [-2.250712496560737, 54.790125196066953],
              [-2.251287235396077, 54.789769039471494],
              [-2.256275655829384, 54.791185652117122],
              [-2.260576313373281, 54.791526059190005],
              [-2.260203591313563, 54.792574685053609],
              [-2.263168918058649, 54.792502682958194],
              [-2.26690527108075, 54.794736645145832],
              [-2.268719131964728, 54.794560097788754],
              [-2.272190727132791, 54.795743962674734],
              [-2.277082563942011, 54.796242416175765],
              [-2.280664101390247, 54.797972166182369],
              [-2.282670948423946, 54.798245186857564],
              [-2.285972722499297, 54.796866105772544],
              [-2.286073945415205, 54.796223332621061],
              [-2.290087758578917, 54.795928025611587],
              [-2.292979319752415, 54.793158623295831],
              [-2.295532949491109, 54.793574791541744],
              [-2.298763596549014, 54.792994433962633],
              [-2.300548329000967, 54.793322523847543],
              [-2.302542498610389, 54.792328150300115],
              [-2.3029514952459, 54.792532920062143],
              [-2.305144986022264, 54.792204804121681],
              [-2.305866465022981, 54.791775228549454],
              [-2.307932843620642, 54.792123168066716],
              [-2.30879421139322, 54.790871852796784],
              [-2.312082852581402, 54.791008115704116],
              [-2.31480406756277, 54.792568328503599],
              [-2.315808584265532, 54.7925486420339],
              [-2.316400660975953, 54.792887687211127],
              [-2.318440323401279, 54.793181598593542],
              [-2.323277662116675, 54.795002046333863],
              [-2.323381780137515, 54.796743353608832],
              [-2.327368953188887, 54.80298905755636],
              [-2.32737851988717, 54.805325520443283],
              [-2.328167992801484, 54.804839913395561],
              [-2.328721000378548, 54.805313801274671],
              [-2.329689737853016, 54.804874428052074],
              [-2.331615280684702, 54.804635529378011],
              [-2.333993110772653, 54.804914774874355],
              [-2.336548723250353, 54.806364420963462],
              [-2.33832113626976, 54.8067863482134],
              [-2.341671581306846, 54.806891084354277],
              [-2.343602660088941, 54.806201756542201],
              [-2.34552473768718, 54.806287958260853],
              [-2.348422065940067, 54.807072278645542],
              [-2.348562113869135, 54.80833987087982],
              [-2.351643485414332, 54.810584789301899],
              [-2.352602904712022, 54.810697031136399],
              [-2.355781761875909, 54.813895926051011],
              [-2.359919190780179, 54.816685707212031],
              [-2.369824229418386, 54.82095954251654],
              [-2.369138122064366, 54.821836915616323],
              [-2.370320617863352, 54.822481237070662],
              [-2.370153924977552, 54.82331389392801],
              [-2.370870277334787, 54.824546448417252],
              [-2.371344990135862, 54.825054529621525],
              [-2.373992228884932, 54.826030417217311],
              [-2.372662889188783, 54.827372594138104],
              [-2.380919544078313, 54.830087826912873],
              [-2.381532647444498, 54.830064332950755],
              [-2.382319625189264, 54.83064957106307],
              [-2.386731283895235, 54.831627696236858],
              [-2.389597125000026, 54.831942041001511],
              [-2.391825214494507, 54.833429316872071],
              [-2.392719953913785, 54.834508395513431],
              [-2.398291962782089, 54.844157874064834],
              [-2.40161614497937, 54.851477169809492],
              [-2.41161732976212, 54.856423953128392],
              [-2.414643623975746, 54.850781843274028],
              [-2.417013377823899, 54.849204688500834],
              [-2.417739478842015, 54.847430960068749],
              [-2.418249084523125, 54.847315076825595],
              [-2.418749094140447, 54.846423692783034],
              [-2.420241278792464, 54.845403963019912],
              [-2.420629030407214, 54.844505770341122],
              [-2.422684125353507, 54.842826248043984],
              [-2.4274425346475, 54.841182130872696],
              [-2.428287718670585, 54.84115219154306],
              [-2.429954281810496, 54.84017216436412],
              [-2.433610703527212, 54.839634344237759],
              [-2.435964006647553, 54.838826115947576],
              [-2.438246928797678, 54.837853643307902],
              [-2.438402861156048, 54.837157526163629],
              [-2.440365222775285, 54.836785569514412],
              [-2.441889597925105, 54.835659413104253],
              [-2.442942458991358, 54.835838898923662],
              [-2.444653564467687, 54.835138884012636],
              [-2.447040937375133, 54.835061815182343],
              [-2.449534377940457, 54.833883461029771],
              [-2.450450432280372, 54.832683961415817],
              [-2.452228603114409, 54.832018637264007],
              [-2.453910781986858, 54.832098626816311],
              [-2.455474163124957, 54.831443943337682],
              [-2.4560306688526, 54.831511049227693],
              [-2.456704622283165, 54.831777210904768],
              [-2.457983658133008, 54.833236291124805],
              [-2.46058938970209, 54.834163720584861],
              [-2.461123497991616, 54.833764488887859],
              [-2.461185166135978, 54.832892564009185],
              [-2.463274750297543, 54.831703777999294],
              [-2.463062325875352, 54.830554316261832],
              [-2.465365501703938, 54.829409610718812],
              [-2.466341899547071, 54.828092933159084],
              [-2.467806384162381, 54.827262334180553],
              [-2.468874793511707, 54.825972240295322],
              [-2.473343826143791, 54.823736117380413],
              [-2.474085468970523, 54.822475108926078],
              [-2.475913672493536, 54.821767901627155],
              [-2.476177348575056, 54.82089787017815],
              [-2.477858340277065, 54.82038531984211],
              [-2.478888429096342, 54.819186948899798],
              [-2.480454739078048, 54.818199427389708],
              [-2.481339984872494, 54.818048539471569],
              [-2.481985954046824, 54.816884914968277],
              [-2.48613945041039, 54.81508002256929],
              [-2.487712439585858, 54.814932620765667],
              [-2.495578514322403, 54.810235828538111],
              [-2.496485642837325, 54.809984989398878],
              [-2.497265121384, 54.810236116269081],
              [-2.498295846128677, 54.810041372178958],
              [-2.502163128953412, 54.810613146624448],
              [-2.503624249459173, 54.810121816049879],
              [-2.507652218079888, 54.809728491247199],
              [-2.5109426593426, 54.808721663111186],
              [-2.514975847851781, 54.808632717170951],
              [-2.515575099750335, 54.80828598662363],
              [-2.517326885005525, 54.808279427802546],
              [-2.517895943114002, 54.807885185688427],
              [-2.519477420474624, 54.80782360601728],
              [-2.521132433166627, 54.807431882918408],
              [-2.521526258612044, 54.806844266408959],
              [-2.522669763937187, 54.80660389706491],
              [-2.523285229594156, 54.806074630931313],
              [-2.529193157629391, 54.806131679737369],
              [-2.529616963880164, 54.805942909587223],
              [-2.53374813250644, 54.806701243860722],
              [-2.536264061557295, 54.807658901116817],
              [-2.543259714268349, 54.812117468026024],
              [-2.558301082033418, 54.816717709718461],
              [-2.567843122769378, 54.823567924578057],
              [-2.572584848146843, 54.831434945176163],
              [-2.577946248918986, 54.836447393437773],
              [-2.582665472600947, 54.845859804214513],
              [-2.581777357585799, 54.84788333935694],
              [-2.573346606723899, 54.853539135967964],
              [-2.575834900045922, 54.85665826241511],
              [-2.57742482302843, 54.859566835750606],
              [-2.581193260143586, 54.864229030510117],
              [-2.583624653343794, 54.865586911284694],
              [-2.58858994035465, 54.869528688762323],
              [-2.5903657991798, 54.872906182727043],
              [-2.590241390791187, 54.873776681107337],
              [-2.593158691371777, 54.875265008342481],
              [-2.598955530558817, 54.879854716157915],
              [-2.605272861957227, 54.883931121131603],
              [-2.605424487098215, 54.884375196872824],
              [-2.604544181820282, 54.885442684633922],
              [-2.597184214217493, 54.886968161169307],
              [-2.596057381524146, 54.887617129426602],
              [-2.594380145994437, 54.887732288997334],
              [-2.592525837987308, 54.888953626825945],
              [-2.591163875551545, 54.888991716771514],
              [-2.588159593607157, 54.891118125874208],
              [-2.585066568777564, 54.892199759976627],
              [-2.581900967661863, 54.894206370163424],
              [-2.579686965242158, 54.894856794017905],
              [-2.576407993453722, 54.896710131614817],
              [-2.574798185993769, 54.898414404324434],
              [-2.574856027909907, 54.899737835761108],
              [-2.567559051406315, 54.908995839905977],
              [-2.566597147533578, 54.913689452113708],
              [-2.566214809470776, 54.919231367154403],
              [-2.559833636649153, 54.920108358776851],
              [-2.558090174421205, 54.921291803462395],
              [-2.557185217284731, 54.921514326984699],
              [-2.55859095010342, 54.922189042265167],
              [-2.558971702753636, 54.923093121667655],
              [-2.560066617296057, 54.923626365514927],
              [-2.56024797598634, 54.923877148943241],
              [-2.559398974761254, 54.924424738831995],
              [-2.553664042136922, 54.924595689337906],
              [-2.551648800250501, 54.925558313831573],
              [-2.547607002196036, 54.926212835172571],
              [-2.546241873804193, 54.927149072810124],
              [-2.54550182984477, 54.928387128971892],
              [-2.545265808938704, 54.929165511837716],
              [-2.546076602684781, 54.930498147851559],
              [-2.546827475205987, 54.930746390044604],
              [-2.54858836247531, 54.929757137982072],
              [-2.550360916250785, 54.929971086172017],
              [-2.550557929252185, 54.929428313745241],
              [-2.551200793771391, 54.929309474565549],
              [-2.551750127800291, 54.929767983646265],
              [-2.5512041085506, 54.930806593673339],
              [-2.551544857344597, 54.931758504235042],
              [-2.554334558151365, 54.933526014695239],
              [-2.56018397555332, 54.934089595423252],
              [-2.563434880681809, 54.937436376322673],
              [-2.564930012107869, 54.940320357591304],
              [-2.567642566237585, 54.940849596840266],
              [-2.571278469201979, 54.948364051270573],
              [-2.568419378761849, 54.958284934169107],
              [-2.571426588614517, 54.960010594833463],
              [-2.578414104637298, 54.960805190366138],
              [-2.586522613895616, 54.963056962416005],
              [-2.588833356033733, 54.962899313395262],
              [-2.595562341831362, 54.96347490979241],
              [-2.596860676269337, 54.964663729683316],
              [-2.596731025309392, 54.965084029776079],
              [-2.59783222762994, 54.964878223123193],
              [-2.598792153382091, 54.965255419438876],
              [-2.598104544366932, 54.965860891394961],
              [-2.598207977798487, 54.966514590289897],
              [-2.599078265159772, 54.9668481921953],
              [-2.598877812592592, 54.967757701866653],
              [-2.59996964809073, 54.968700380266149],
              [-2.59979884395867, 54.969083143744953],
              [-2.601422661283895, 54.971334294119593],
              [-2.60033882038603, 54.973656335447487],
              [-2.594236352060853, 54.973628803020944],
              [-2.591585033562938, 54.974144968989492],
              [-2.590224062450388, 54.976470062913592],
              [-2.59060238917284, 54.977444143464304],
              [-2.589959249937521, 54.977695289142872],
              [-2.588460650504865, 54.979705608435005],
              [-2.58856448561003, 54.980181382168446],
              [-2.586622702498691, 54.982028473364828],
              [-2.585765404364574, 54.982387566933909],
              [-2.581929184645533, 54.982271192482379],
              [-2.5814566941081, 54.982013747719634],
              [-2.580428828461345, 54.982467077031991],
              [-2.580323171313421, 54.982833325284297],
              [-2.579230137641214, 54.9828870633837],
              [-2.579433867695958, 54.983253633838217],
              [-2.578551669297128, 54.983408805126466],
              [-2.57697151554114, 54.984690575574824],
              [-2.57577776878895, 54.984917297922038],
              [-2.573303713283374, 54.987632075593787],
              [-2.572844611771843, 54.9893021011465],
              [-2.573494351867926, 54.989712409436009],
              [-2.573023400936056, 54.990332888615278],
              [-2.574427092176861, 54.991661633322174],
              [-2.572854760191264, 54.992866028183016],
              [-2.571962197379255, 54.995604765433079],
              [-2.570474340135982, 54.996939931016762],
              [-2.570463795976087, 54.998730053355253],
              [-2.568608039490384, 54.999928531073145],
              [-2.569087642198402, 55.00123109924283],
              [-2.567706707909696, 55.002741860165159],
              [-2.568424940645535, 55.003451119092468],
              [-2.570190502007177, 55.003927216168407],
              [-2.572935870199715, 55.003271793740275],
              [-2.574173852083202, 55.003694602223042],
              [-2.573491810177412, 55.004761798333526],
              [-2.570799313640199, 55.005843833972989],
              [-2.570234840161843, 55.006500684223816],
              [-2.570796223000978, 55.007056998588176],
              [-2.572635680874181, 55.006342029128852],
              [-2.573646418954945, 55.006720981173004],
              [-2.573460521201942, 55.007279906894247],
              [-2.57186110444832, 55.008383765493505],
              [-2.57396778257111, 55.009625633275427],
              [-2.574804488943866, 55.011167324541667],
              [-2.571773851419364, 55.014227960401819],
              [-2.573179972820059, 55.016109361274722],
              [-2.571391714147488, 55.017046057449278],
              [-2.567891401193731, 55.017247566417275],
              [-2.568168079772974, 55.018343499095529],
              [-2.565035237105884, 55.020105921904509],
              [-2.560925919111736, 55.020398122392571],
              [-2.558746180790326, 55.020886223369118],
              [-2.557593426305723, 55.020319985941697],
              [-2.557987767244904, 55.019771812526578],
              [-2.55697416797276, 55.01965064987192],
              [-2.55582163263191, 55.019886868598149],
              [-2.554742254063746, 55.020891068685962],
              [-2.552612441108593, 55.021036455118669],
              [-2.550423331763081, 55.02221998604265],
              [-2.546681459511163, 55.023560535392939],
              [-2.545894413494805, 55.024345874185514],
              [-2.546363296580932, 55.025237901851526],
              [-2.545766892417675, 55.026126623734697],
              [-2.544476123110881, 55.026800086182092],
              [-2.540891413938052, 55.027312117960797],
              [-2.539565505363457, 55.028871728254714],
              [-2.53405391400472, 55.028259821584328],
              [-2.533249204517605, 55.028683009110424],
              [-2.532981209491615, 55.029942257633422],
              [-2.53196841023802, 55.030367246539221],
              [-2.529179217783899, 55.028956888491571],
              [-2.527615095278561, 55.029094881083424],
              [-2.527070335077932, 55.03025916330698],
              [-2.527702216344393, 55.032271138795316],
              [-2.526905214136625, 55.032935081460991],
              [-2.525904178576436, 55.032358003221866],
              [-2.524491051139691, 55.032927540373166],
              [-2.521652293894516, 55.032512000429506],
              [-2.519098239052148, 55.032814977181033],
              [-2.51760102771925, 55.033424332195047],
              [-2.515785135077371, 55.033588404498758],
              [-2.516233798521056, 55.034654964170898],
              [-2.514434714495748, 55.034426246723925],
              [-2.513096929287556, 55.033809150383298],
              [-2.512291499749411, 55.033834111429385],
              [-2.511607100718197, 55.034639461312153],
              [-2.510757451334866, 55.034879368881917],
              [-2.510984752479922, 55.035896553645692],
              [-2.510161157094668, 55.036342132008876],
              [-2.509204962474334, 55.036327266586454],
              [-2.50862345501793, 55.035999904592728],
              [-2.50891628994017, 55.034415307482213],
              [-2.504471500278568, 55.033185632253137],
              [-2.503683870588719, 55.033631017816923],
              [-2.503818522358604, 55.034262191751935],
              [-2.505754757275933, 55.034826580643212],
              [-2.506292382758752, 55.035647482518257],
              [-2.506079429128022, 55.036378948263007],
              [-2.505250019951876, 55.036864955394712],
              [-2.501877937818579, 55.037099074273776],
              [-2.497065330669576, 55.036876203275256],
              [-2.493432527626153, 55.037161475776962],
              [-2.483042912599593, 55.040014053906852],
              [-2.482336105987583, 55.041486102128246],
              [-2.482949528119937, 55.043696071396887],
              [-2.484887311598174, 55.045152216379797],
              [-2.488114729136667, 55.046213163051604],
              [-2.487193289604583, 55.047274531175312],
              [-2.487558796778812, 55.047858967128469],
              [-2.494334838979491, 55.050709906839749],
              [-2.493140601048438, 55.052112105221738],
              [-2.490839421775896, 55.053372286355895],
              [-2.491187672062604, 55.054200306331573],
              [-2.490745471996444, 55.054521099997423],
              [-2.491957710042185, 55.054956528604215],
              [-2.492178524318508, 55.054198998801631],
              [-2.494062873791053, 55.053155255509708],
              [-2.494699591430387, 55.053125710233978],
              [-2.495525835217288, 55.053739699873049],
              [-2.495239821076986, 55.054662847157907],
              [-2.497818805386284, 55.056620299766067],
              [-2.499744612675729, 55.05614242917509],
              [-2.500670411566684, 55.056569062325202],
              [-2.502013895331416, 55.057720948763169],
              [-2.501883918800662, 55.059844021827949],
              [-2.502719388988555, 55.06079939940178],
              [-2.502258747975096, 55.061271278602106],
              [-2.500545263381712, 55.061216333159372],
              [-2.499952668058467, 55.062032018233815],
              [-2.50113378624893, 55.062456700044578],
              [-2.503395994953776, 55.062067248863066],
              [-2.503978223089605, 55.062425185167548],
              [-2.503467371702667, 55.063138106330243],
              [-2.500741708605827, 55.064284305183726],
              [-2.497875665080214, 55.064730095221449],
              [-2.497583365336171, 55.065653272007175],
              [-2.493791576181498, 55.065954474819456],
              [-2.492193209791275, 55.068149081038747],
              [-2.49325667483701, 55.069057770496563],
              [-2.493346204054446, 55.070860930936988],
              [-2.494027854545014, 55.07165883335562],
              [-2.499229592477586, 55.073494151451236],
              [-2.501042830860327, 55.074596492367036],
              [-2.501227607664833, 55.075220269867934],
              [-2.499472453492531, 55.076770407752946],
              [-2.497046484431541, 55.077412064423747],
              [-2.496305441521243, 55.080304136207126],
              [-2.494726429359757, 55.081817540715683],
              [-2.493509533251104, 55.082216971832644],
              [-2.491097820491532, 55.082267162406609],
              [-2.490116155090863, 55.080771330742337],
              [-2.486631772796603, 55.081098027219291],
              [-2.486076860395585, 55.081581002943764],
              [-2.486334247597832, 55.083041121874089],
              [-2.487850529385248, 55.083549960976988],
              [-2.489906823441828, 55.083507555986387],
              [-2.490755948411351, 55.083770125796612],
              [-2.490890600571619, 55.084020296308346],
              [-2.489864044010165, 55.083980399251558],
              [-2.489706204890274, 55.084267692210858],
              [-2.490691070962938, 55.085891116756919],
              [-2.492062655228957, 55.086340279504299],
              [-2.493475537983926, 55.086197072784366],
              [-2.495322736577267, 55.086464547289715],
              [-2.494867132682151, 55.087374898894105],
              [-2.495186056785934, 55.087700697616938],
              [-2.495729382253175, 55.086663283110262],
              [-2.498284581901244, 55.087296259633767],
              [-2.499042791605156, 55.087795479375863],
              [-2.500622108237853, 55.090186497704963],
              [-2.502244057919449, 55.090714492945878],
              [-2.503699995378454, 55.090746220080092],
              [-2.50487826580011, 55.0903774011],
              [-2.506005972592734, 55.089356386919],
              [-2.506927677119054, 55.089145871451464],
              [-2.508952857347976, 55.089716130063216],
              [-2.511559988354302, 55.088863201856327],
              [-2.513199999146958, 55.089204063801986],
              [-2.516460716355711, 55.089111197586725],
              [-2.516963481592603, 55.088613927079109],
              [-2.516762721782347, 55.086046537573054],
              [-2.518088016248188, 55.084952681810549],
              [-2.524943880224723, 55.085477759428663],
              [-2.526382385555745, 55.083957380610165],
              [-2.526921886109581, 55.08424260390958],
              [-2.527166152218197, 55.085067374511709],
              [-2.530463720871198, 55.085182451915514],
              [-2.5322502718579, 55.082756478115719],
              [-2.536853448930555, 55.082939353726253],
              [-2.540161370427247, 55.08429780821205],
              [-2.544021402025862, 55.083382003948316],
              [-2.545759276735352, 55.0837732117513],
              [-2.546805741504826, 55.082972341398701],
              [-2.54865491046009, 55.082681856119926],
              [-2.550411887978321, 55.081725883955457],
              [-2.54977901532442, 55.081495103035643],
              [-2.548590555705492, 55.081871593246447],
              [-2.549046782382345, 55.081214444400231],
              [-2.550830980677551, 55.080876592034144],
              [-2.551721991723017, 55.08131198137233],
              [-2.552646917537222, 55.081252071899236],
              [-2.553125123665052, 55.081851974025774],
              [-2.554262894832357, 55.082230509150101],
              [-2.553809306421472, 55.082619877555551],
              [-2.555181859100506, 55.082756506356276],
              [-2.556965034185884, 55.082118430571434],
              [-2.555708951676158, 55.081568824723163],
              [-2.556940572254848, 55.081034809267223],
              [-2.558784883276787, 55.083777027414584],
              [-2.560809901939761, 55.083966310813821],
              [-2.56212961161333, 55.083457897225166],
              [-2.562786276896448, 55.084371453733304],
              [-2.561606773698345, 55.085384250756256],
              [-2.559835234074327, 55.085178538671499],
              [-2.559128445936781, 55.085383975561911],
              [-2.56047749790806, 55.086296159688906],
              [-2.558894838027342, 55.087391660638218],
              [-2.559063840964317, 55.087714386901268],
              [-2.559678967461112, 55.087897574565716],
              [-2.561207431169206, 55.087518511348726],
              [-2.560389524516737, 55.088406517950226],
              [-2.558907148524147, 55.088049391859194],
              [-2.558287890755978, 55.088806172626867],
              [-2.559638293902098, 55.089247484312423],
              [-2.561025568648878, 55.088739671609574],
              [-2.560743903561032, 55.089435600103492],
              [-2.561172764110383, 55.090299890954007],
              [-2.563389161015547, 55.091261060401891],
              [-2.563979290708129, 55.091889157398491],
              [-2.565384304098757, 55.091636420396512],
              [-2.566698772129607, 55.091961899291007],
              [-2.567148374282455, 55.092730819195644],
              [-2.566775054398994, 55.093698570480385],
              [-2.568298610516621, 55.093956561128152],
              [-2.568967574788245, 55.094390164799357],
              [-2.568419014571961, 55.096257356072265],
              [-2.570194167082454, 55.09722944706872],
              [-2.572451672579001, 55.097625928403779],
              [-2.576671228587374, 55.095848324400777],
              [-2.577763929134398, 55.095992315043738],
              [-2.57742837564921, 55.096737961463916],
              [-2.579401876462694, 55.096131006850563],
              [-2.57929488972773, 55.09664282891184],
              [-2.580657212305068, 55.096683068346124],
              [-2.581892774049936, 55.097273851963969],
              [-2.583491754259355, 55.096992122336658],
              [-2.583983255086348, 55.097169489605221],
              [-2.582811838643543, 55.098080907730328],
              [-2.583319164235355, 55.099021127620169],
              [-2.582666920280769, 55.099429526405828],
              [-2.582627817081719, 55.100511647883799],
              [-2.583329693865268, 55.101680083636552],
              [-2.58541223665627, 55.102732254755864],
              [-2.585486250335822, 55.103077866220481],
              [-2.586909850174663, 55.102899377839805],
              [-2.587505438281991, 55.103114869760155],
              [-2.587552013281362, 55.103511833496242],
              [-2.588599693233943, 55.103561589856724],
              [-2.589334277803881, 55.103955225634749],
              [-2.590429196398193, 55.103700106703251],
              [-2.592081251413447, 55.104238444696364],
              [-2.592474039220015, 55.105150427324716],
              [-2.593551873655311, 55.105116423173804],
              [-2.59228826780732, 55.106048150031455],
              [-2.592426776924116, 55.106299088869733],
              [-2.593151284619061, 55.106218281326257],
              [-2.592895826520974, 55.106960883622193],
              [-2.594317791960246, 55.107615334213087],
              [-2.593770491509002, 55.108231758553266],
              [-2.594074569839729, 55.109075873272772],
              [-2.593272235626379, 55.110043100435945],
              [-2.594257683949452, 55.110851543310801],
              [-2.593429539547379, 55.112828940769511],
              [-2.59482744503417, 55.114064906616647],
              [-2.599606439275807, 55.115676953446744],
              [-2.600464284520368, 55.117158138256706],
              [-2.600391796373976, 55.118909896095552],
              [-2.600978655678622, 55.119671723172416],
              [-2.6015872187162, 55.122605391419995],
              [-2.600919691507877, 55.12378407367558],
              [-2.598896119403146, 55.124572239084053],
              [-2.60272569966843, 55.124692636083317],
              [-2.60614736029931, 55.126143095009972],
              [-2.610232507764104, 55.12635184600807],
              [-2.61075852455499, 55.126704162495173],
              [-2.61563363404102, 55.127491970253075],
              [-2.621266788036435, 55.129212974862128],
              [-2.624344615041577, 55.12896449866772],
              [-2.625683599746302, 55.127539614682092],
              [-2.632632687853822, 55.127753540443237],
              [-2.635704586857011, 55.12961026360032],
              [-2.645363567572066, 55.1310511806278],
              [-2.64711870211427, 55.131058944129379],
              [-2.648611436513034, 55.132390842076475],
              [-2.655444258083992, 55.135690833904135],
              [-2.656933733737628, 55.136128525181419],
              [-2.659767941347408, 55.142105177747176],
              [-2.659319454976162, 55.142972971475686],
              [-2.65989961243556, 55.144284504198076],
              [-2.661618984764735, 55.146361763554424],
              [-2.662584860305693, 55.146987342703255],
              [-2.666383457833821, 55.147460866544556],
              [-2.668572816216347, 55.147307791503337],
              [-2.671261330693063, 55.150531618233771],
              [-2.672526736579092, 55.150851736900862],
              [-2.673259439144565, 55.151777757000417],
              [-2.674884677367717, 55.155468361834963],
              [-2.677318987773507, 55.155484510994313],
              [-2.677762831080745, 55.156578352532861],
              [-2.676072876573908, 55.157892526447483],
              [-2.676091004443667, 55.15840733149372],
              [-2.683248592346696, 55.1666274694294],
              [-2.682918008519506, 55.167491091754137],
              [-2.679913099047223, 55.168856708009315],
              [-2.679541927696762, 55.169646860801315],
              [-2.677352348855936, 55.171245098074763],
              [-2.677686980318557, 55.171721299559891],
              [-2.676437506883415, 55.173032125168746],
              [-2.674264990074254, 55.173706438273221],
              [-2.674549919604313, 55.175888486834261],
              [-2.677372653929554, 55.177717682599067],
              [-2.689785063159612, 55.188981359583522],
              [-2.689265246632703, 55.188894438384388],
              [-2.687388707730475, 55.189646385733845],
              [-2.687408840272693, 55.190085692382389],
              [-2.686143930511575, 55.190787442598086],
              [-2.685594631027032, 55.192550908298273],
              [-2.684759842797515, 55.192876402886291],
              [-2.686243811362436, 55.194038048828119],
              [-2.685102685210471, 55.195553230212923],
              [-2.683774330788694, 55.195773656864006],
              [-2.683783585702406, 55.196222010650928],
              [-2.68231381641366, 55.196809846463943],
              [-2.681579235476146, 55.197858137573341],
              [-2.681244689492526, 55.198598662968358],
              [-2.682668444662779, 55.199723845103392],
              [-2.683326083141162, 55.201887603732139],
              [-2.668836693518769, 55.219679450601802],
              [-2.66680931096125, 55.221577619317074],
              [-2.659985756973616, 55.219881325863142],
              [-2.648064454851219, 55.219864375975817],
              [-2.643204654796688, 55.221058284156086],
              [-2.641288199628264, 55.22113756777032],
              [-2.638961330333485, 55.22267920016089],
              [-2.631408374651456, 55.223695312485034],
              [-2.634651277906508, 55.22546220589723],
              [-2.635799917510973, 55.229763218420452],
              [-2.634903039386253, 55.23041038916152],
              [-2.634922110831364, 55.231113894035126],
              [-2.634209600474424, 55.231097831264044],
              [-2.634023470689025, 55.231356697103344],
              [-2.634673290793869, 55.231681307058018],
              [-2.633994017853757, 55.232173677787088],
              [-2.63324065335096, 55.234336926545872],
              [-2.633546388154946, 55.235566420771534],
              [-2.63316631289847, 55.236189326323981],
              [-2.632204829999847, 55.236641815272804],
              [-2.632524992306492, 55.237295234648371],
              [-2.631913620675324, 55.237614709895588],
              [-2.631520072689438, 55.238580944634663],
              [-2.632968222499009, 55.239853943415881],
              [-2.631412488657642, 55.240028245830281],
              [-2.6312978505888, 55.241923983775962],
              [-2.630056895742544, 55.244106803876711],
              [-2.630223398452012, 55.244788878513425],
              [-2.626135185296834, 55.245664493891127],
              [-2.625623650153397, 55.246042731772853],
              [-2.624655834114617, 55.246011746657608],
              [-2.624225980589576, 55.246384168813513],
              [-2.622147859226009, 55.246162945035039],
              [-2.620194937205087, 55.2472125646621],
              [-2.617833749949985, 55.247199383247725],
              [-2.617661442674062, 55.246839918244042],
              [-2.616937536513487, 55.246918164323837],
              [-2.614830335566472, 55.24562763264727],
              [-2.611445007704186, 55.247131822812207],
              [-2.61200717288542, 55.247684336991199],
              [-2.61533817993916, 55.248261555711387],
              [-2.617616555994071, 55.248306655661317],
              [-2.620617801654316, 55.249113642964623],
              [-2.624082796387781, 55.249492250619532],
              [-2.627646973361797, 55.250570257087013],
              [-2.629491373424722, 55.251314678703388],
              [-2.632380618750293, 55.25336022212624],
              [-2.634759181939407, 55.25453851306839],
              [-2.638113157830699, 55.255903062122378],
              [-2.640411259455912, 55.256460733540401],
              [-2.644344615572593, 55.258215655451416],
              [-2.646765814668043, 55.260041376510536],
              [-2.645402071595531, 55.26006836572558],
              [-2.640967086041876, 55.260983148259776],
              [-2.640706290169479, 55.260714938854477],
              [-2.639037128584087, 55.261125363432129],
              [-2.635380553353122, 55.26113906479852],
              [-2.62665051115145, 55.262218489418863],
              [-2.626834557783379, 55.263313830418014],
              [-2.62630152242116, 55.26453955500881],
              [-2.628185172433678, 55.267930156501897],
              [-2.623275361030289, 55.269758807800628],
              [-2.623327188604027, 55.271961895261825],
              [-2.622483723372268, 55.27237416150691],
              [-2.62213125992297, 55.273084948900831],
              [-2.62028608676082, 55.27376019785418],
              [-2.618414787878476, 55.274986388569488],
              [-2.617017145508528, 55.277124025291997],
              [-2.613703566206703, 55.278344844351025],
              [-2.613371243137182, 55.279252295612757],
              [-2.611007278737551, 55.280923850821452],
              [-2.609232435164941, 55.283245696289974],
              [-2.605190344946569, 55.285372099968527],
              [-2.602472681922333, 55.28556885545472],
              [-2.598485699507135, 55.287107085043921],
              [-2.594193611365714, 55.289848981456011],
              [-2.590284861006913, 55.291403632733058],
              [-2.582980569788371, 55.293489310787052],
              [-2.57864131102618, 55.294409441355917],
              [-2.575774290211991, 55.296088079225143],
              [-2.573357297809394, 55.296882123596241],
              [-2.573698676735685, 55.300783981443544],
              [-2.570746511073399, 55.301406160479225],
              [-2.566341490821648, 55.303318196328753],
              [-2.565205220114546, 55.305934751296853],
              [-2.563297966840334, 55.307628412229896],
              [-2.560089055328874, 55.308747534318002],
              [-2.559624509213376, 55.309879185117033],
              [-2.557083967932812, 55.311607098046203],
              [-2.557265141015795, 55.313291116407527],
              [-2.559175471199597, 55.315136162138394],
              [-2.559420754764376, 55.31722514414809],
              [-2.559035378741749, 55.317832554111305],
              [-2.550419259859247, 55.315783385712372],
              [-2.548721973268514, 55.317243134237465],
              [-2.544294017669812, 55.318261275588974],
              [-2.543098868912042, 55.31959020833559],
              [-2.536547758048274, 55.320950864926999],
              [-2.530762646553558, 55.322706812280288],
              [-2.525102648132884, 55.321806640001796],
              [-2.520263578057189, 55.323044927786448],
              [-2.517692261683278, 55.32466251632728],
              [-2.51631621820517, 55.326828527754422],
              [-2.513706142934408, 55.329623333293071],
              [-2.509252540854642, 55.331644916524986],
              [-2.506879782499601, 55.33395335034502],
              [-2.504350040609584, 55.335343139757221],
              [-2.501982071954227, 55.337197674564798],
              [-2.502247308922554, 55.340217601297184],
              [-2.498802425430619, 55.342099858267929],
              [-2.496170939113773, 55.346512700339439],
              [-2.495014675886864, 55.347489650186375],
              [-2.488512988794346, 55.351832577565581],
              [-2.481359566809236, 55.3536662083199],
              [-2.477465717651518, 55.354331157287895],
              [-2.47517929936226, 55.354765995590959],
              [-2.473432961640543, 55.354733238170553],
              [-2.471309854729558, 55.353786263372704],
              [-2.467531475640632, 55.35275574029356],
              [-2.463307062135477, 55.353621884505088],
              [-2.461217261785133, 55.353207462516195],
              [-2.45704772311313, 55.354608732279267],
              [-2.456673770951021, 55.355279567485638],
              [-2.451783895524922, 55.356478472445062],
              [-2.444399520937078, 55.359181503668339],
              [-2.440348373043976, 55.359137765137575],
              [-2.439290287653936, 55.359451581356211],
              [-2.436897385654853, 55.35830370047195],
              [-2.429223954445823, 55.356433117916353],
              [-2.426360629594734, 55.358136052585422],
              [-2.424343372622073, 55.358590564323563],
              [-2.421868796160828, 55.35861530870249],
              [-2.419876300101633, 55.359031921078433],
              [-2.41499006803884, 55.358920120935444],
              [-2.412359697296455, 55.357768973110218],
              [-2.411280735016553, 55.358064645679804],
              [-2.408011431746155, 55.357190516352809],
              [-2.402492970533846, 55.354592185373029],
              [-2.400849403233478, 55.353485159069329],
              [-2.399515375621962, 55.350533230950141],
              [-2.400186918086497, 55.348787804429335],
              [-2.399795960095174, 55.348330813224358],
              [-2.392971712838881, 55.347445401605249],
              [-2.390262629141997, 55.347927634331697],
              [-2.384783335786419, 55.348300852148178],
              [-2.381121888094532, 55.349139016622445],
              [-2.378904958332454, 55.349167479475554],
              [-2.378954260687679, 55.350531356495807],
              [-2.374952576991193, 55.353782152055174],
              [-2.372791360131278, 55.355067431378451],
              [-2.369879488161086, 55.355703484542516],
              [-2.365449392348292, 55.357619088922512],
              [-2.364191725027917, 55.358759534353027],
              [-2.361535436412757, 55.359099895044295],
              [-2.358382427690154, 55.359985290000964],
              [-2.354864976637111, 55.3624657219138],
              [-2.351274677436944, 55.36318416977381],
              [-2.34714145473187, 55.364609431180739],
              [-2.345041267969336, 55.364558771313398],
              [-2.342082728122318, 55.365220349878058],
              [-2.340094521697411, 55.36608852520569],
              [-2.338920280679745, 55.367138618640404],
              [-2.337470021382431, 55.367279215543881],
              [-2.338451099601275, 55.367805757121928],
              [-2.338966267064802, 55.368825998763235],
              [-2.343321451889595, 55.370719696486674],
              [-2.346226138338769, 55.373059452581742],
              [-2.337233352999388, 55.376731882269958],
              [-2.336468446134488, 55.377899429843396],
              [-2.33229336182333, 55.380035961171679],
              [-2.330570385666225, 55.380462061491627],
              [-2.330013449327484, 55.381239926490558],
              [-2.332533564267137, 55.381953742416123],
              [-2.33473432910139, 55.383153606309186],
              [-2.334855751922333, 55.385567714700841],
              [-2.335823577055437, 55.386157212153165],
              [-2.334932776178139, 55.390009102260485],
              [-2.335505349239489, 55.392563942932235],
              [-2.343363463027661, 55.397413224507261],
              [-2.34471848499969, 55.399447348966305],
              [-2.343185196572193, 55.400343935862125],
              [-2.341890958367649, 55.400405969623066],
              [-2.340297041699618, 55.40124158741903],
              [-2.33765962853376, 55.401190495725039],
              [-2.336430740190812, 55.401885776855629],
              [-2.334045033204623, 55.402101685308736],
              [-2.333523499852221, 55.402423895944388],
              [-2.332991517972312, 55.404320411613021],
              [-2.335622180762801, 55.408206679612],
              [-2.333815382989311, 55.408759749101108],
              [-2.332359423668523, 55.40990938076613],
              [-2.330716501449945, 55.409675718521171],
              [-2.329094350808018, 55.408519157144184],
              [-2.327343032821074, 55.407964056925287],
              [-2.32284071881367, 55.407608520700805],
              [-2.320688171201945, 55.408194657032311],
              [-2.319688083137667, 55.407560197896856],
              [-2.315949634850453, 55.407325510558572],
              [-2.313246407405917, 55.406787941244175],
              [-2.312537853500959, 55.409853843462066],
              [-2.310182296077458, 55.410165354130058],
              [-2.306679714124849, 55.411247072198883],
              [-2.304244543375197, 55.412724098795593],
              [-2.301778205616869, 55.41365932251577],
              [-2.300942278443216, 55.413611962785907],
              [-2.295927619012613, 55.414920826135727],
              [-2.293796640086015, 55.418517502457398],
              [-2.294506208757876, 55.420463867468946],
              [-2.283842614826978, 55.422885545192635],
              [-2.281765251412002, 55.424907610451747],
              [-2.278702891838996, 55.426198667504799],
              [-2.277958504810788, 55.42639534902964],
              [-2.273885470539728, 55.425979525686614],
              [-2.271730658104781, 55.428785132647356],
              [-2.270639574690086, 55.429591760149819],
              [-2.2682879219688, 55.430001293757563],
              [-2.264465145914999, 55.42876961770714],
              [-2.262235762103531, 55.428556970262932],
              [-2.26060391136539, 55.432932828116186],
              [-2.25858414455227, 55.432184128484195],
              [-2.256708846466263, 55.432256371115606],
              [-2.253754603464168, 55.431895929106417],
              [-2.242536379300858, 55.432145137995633],
              [-2.23662702951083, 55.430960744893319],
              [-2.233805647528045, 55.429238254365693],
              [-2.231274262610964, 55.428428081584087],
              [-2.229948404538721, 55.430575432388686],
              [-2.228193085802422, 55.430349590849332],
              [-2.223344309827952, 55.431331681412267],
              [-2.223074441111773, 55.432233423750972],
              [-2.221127898833146, 55.433140905927324],
              [-2.205616476482417, 55.437537648934416],
              [-2.20378528800972, 55.438568659969455],
              [-2.201733521075039, 55.440683661519294],
              [-2.198337762493761, 55.442130496475535],
              [-2.194211822837761, 55.445256893071367],
              [-2.193291897858454, 55.447212699976376],
              [-2.193521147110336, 55.449798366310965],
              [-2.192257129363731, 55.452549925070493],
              [-2.190026793093054, 55.454307382273612],
              [-2.188417872886077, 55.456437638488339],
              [-2.189629664591492, 55.461164828898887],
              [-2.184405659771713, 55.464084118095833],
              [-2.166528077758202, 55.467467653395744],
              [-2.165513316870607, 55.468381057285455],
              [-2.168486658286173, 55.469060789595439],
              [-2.173715756324312, 55.472270269179873],
              [-2.189463372292784, 55.47396040842218],
              [-2.195484598400417, 55.475320315532215],
              [-2.200936134507786, 55.475014958138132],
              [-2.201584134798625, 55.47551528079682],
              [-2.203365555128356, 55.478603331864491],
              [-2.205367197146087, 55.480174240077773],
              [-2.206347480498793, 55.481559046769632],
              [-2.206055379594006, 55.482887590315528],
              [-2.202225225630368, 55.488242126381174],
              [-2.202641767027429, 55.489540732959334],
              [-2.205286877686974, 55.491492453802572],
              [-2.207023897411756, 55.494070145806923],
              [-2.208796604210078, 55.494676345616305],
              [-2.208753101377258, 55.495726818000968],
              [-2.211933826750633, 55.499423349181576],
              [-2.216913455634964, 55.502815607695119],
              [-2.219445217293006, 55.505732255958193],
              [-2.220574136509952, 55.50631517720123],
              [-2.226533145295943, 55.507097702135354],
              [-2.227987075726636, 55.508005225336198],
              [-2.228838176104622, 55.50951857928731],
              [-2.226620383395111, 55.519513592273533],
              [-2.224276063450894, 55.522787700844852],
              [-2.227595109678054, 55.528408231088989],
              [-2.233210006536324, 55.529613384889785],
              [-2.233816802649006, 55.540827788614003],
              [-2.232955650910164, 55.541779185040646],
              [-2.233215705813447, 55.543145365752295],
              [-2.232407709879467, 55.543716575259332],
              [-2.232241546227638, 55.544830178504647],
              [-2.234085412771308, 55.546559046396631],
              [-2.235165796194992, 55.548335180576451],
              [-2.240761794718015, 55.55268400349425],
              [-2.24029949213518, 55.555627617690597],
              [-2.244044797152306, 55.556817958292775],
              [-2.247847280219513, 55.556731249251513],
              [-2.253068967272764, 55.561392055872034],
              [-2.257498459831314, 55.562567989927459],
              [-2.259860345101618, 55.563693358188743],
              [-2.259798012957978, 55.56505836532903],
              [-2.264611699122842, 55.566919707402811],
              [-2.270048644248337, 55.570109319057778],
              [-2.276472285047914, 55.575690160592025],
              [-2.280141261064206, 55.577320750198815],
              [-2.285425825570245, 55.577720977575218],
              [-2.288529475803835, 55.580050792895179],
              [-2.292385446479972, 55.585799451602341],
              [-2.29036711427226, 55.589610435773444],
              [-2.288107683201789, 55.591538628424487],
              [-2.287841331604108, 55.592866385947829],
              [-2.288600751916292, 55.594942903030969],
              [-2.285929142001412, 55.596544958193405],
              [-2.288129159846582, 55.598145479514294],
              [-2.288504053304423, 55.597981064749987],
              [-2.288948030765249, 55.598363691302097],
              [-2.287745766942839, 55.601049533206371],
              [-2.290314134381733, 55.602839637471583],
              [-2.28922591325769, 55.603774885162366],
              [-2.292262096987863, 55.60462308216831],
              [-2.293878939608566, 55.604556318275648],
              [-2.295497730340312, 55.608335236502526],
              [-2.29512846146086, 55.608623655818789],
              [-2.305943045348232, 55.612085227448155],
              [-2.307279977714489, 55.611105181231736],
              [-2.316087839104068, 55.620459832557415],
              [-2.308928358060733, 55.622480904778996],
              [-2.308619789336718, 55.628877400788831],
              [-2.310833983855092, 55.628914037335413],
              [-2.314993080243849, 55.6278853898121],
              [-2.31588855451017, 55.628251481857674],
              [-2.318333140853821, 55.628109480718706],
              [-2.319810140209388, 55.627723762305472],
              [-2.321608121812553, 55.626201445326792],
              [-2.324304364840394, 55.626167386364799],
              [-2.328143567758612, 55.627410616397619],
              [-2.329798673047399, 55.629146623352632],
              [-2.332156005337118, 55.629765634481394],
              [-2.335240519326763, 55.632022426847207],
              [-2.336006367490304, 55.632056271448022],
              [-2.335103209121964, 55.633240306746529],
              [-2.3350408425588, 55.634493024020735],
              [-2.332335568246148, 55.634840029925002],
              [-2.327764000399565, 55.635965610915214],
              [-2.326107749653619, 55.637173154276013],
              [-2.325588372518731, 55.638329140825412],
              [-2.325890939423565, 55.640713025018542],
              [-2.324988655338862, 55.643021935131138],
              [-2.322517405599674, 55.643509171127725],
              [-2.319309727726978, 55.643122215100135],
              [-2.315890819459816, 55.643327847748786],
              [-2.314546648571911, 55.643713176468331],
              [-2.310873068343668, 55.645873613552844],
              [-2.305783573256024, 55.647045507730546],
              [-2.299298842348643, 55.647198083469071],
              [-2.294318383984494, 55.645818325627864],
              [-2.289018434124101, 55.64350914675633],
              [-2.278872004384771, 55.642750929235312],
              [-2.272675894213977, 55.642739707884147],
              [-2.260070403758788, 55.644574027303484],
              [-2.25555279525458, 55.644473907722833],
              [-2.251442479882183, 55.643219083314371],
              [-2.252177792070532, 55.642682052990551],
              [-2.2498626813572, 55.641631028479907],
              [-2.248610244249125, 55.640039593981484],
              [-2.245689112853434, 55.639585439388547],
              [-2.242470244911987, 55.63978233573463],
              [-2.242378478799551, 55.64009969604605],
              [-2.237592886462752, 55.640250133460171],
              [-2.234176654965437, 55.641198359975256],
              [-2.232170668373033, 55.64289139919542],
              [-2.231606403278132, 55.643922173798487],
              [-2.231910744369038, 55.645146284789575],
              [-2.233623991119746, 55.645763012173404],
              [-2.235370819420159, 55.646406606833111],
              [-2.240217157604341, 55.646970463425518],
              [-2.242487352073496, 55.647686609026344],
              [-2.247484835000679, 55.65050517075904],
              [-2.248337439088927, 55.651647265338909],
              [-2.248033293907671, 55.652301106482902],
              [-2.243840837206237, 55.653517129585673],
              [-2.23691992232843, 55.656388895213098],
              [-2.234180229152105, 55.658021380045952],
              [-2.232138221484178, 55.661022727613485],
              [-2.230346268254074, 55.662267862045276],
              [-2.226789104108993, 55.663120898983045],
              [-2.220889099947741, 55.66347400626389],
              [-2.218445566222007, 55.66426189323505],
              [-2.217518205768627, 55.66529414369964],
              [-2.217034165063053, 55.666872801130971],
              [-2.219697459391056, 55.673205309876629],
              [-2.219354195817377, 55.674938267184586],
              [-2.218652053975266, 55.675909021910485],
              [-2.21230527279595, 55.679276136990815],
              [-2.208887082054983, 55.680608218325013],
              [-2.207816347153722, 55.684409864067831],
              [-2.205957943454889, 55.686070761505896],
              [-2.20429294583128, 55.686724970513552],
              [-2.199693853393823, 55.687475618344735],
              [-2.197801023788419, 55.688401461497094],
              [-2.195390574077057, 55.690803463015676],
              [-2.192162678134569, 55.69557518382846],
              [-2.191187939850323, 55.696320677056903],
              [-2.189806961874233, 55.695975995667062],
              [-2.188227553634422, 55.696707124911022],
              [-2.186743560653096, 55.698441728441921],
              [-2.186899638071369, 55.699141432890642],
              [-2.186400064272725, 55.699809788832084],
              [-2.184047371805539, 55.701595992693534],
              [-2.180589698898116, 55.703276859791515],
              [-2.17056924839567, 55.704914818437189],
              [-2.167222894600126, 55.706038075153145],
              [-2.166556835734787, 55.706947377474876],
              [-2.166990259778514, 55.707829126957492],
              [-2.172491996887696, 55.710805470289451],
              [-2.17573875751755, 55.714376038399458],
              [-2.177045660239579, 55.716523397534772],
              [-2.176988797992773, 55.718378005924059],
              [-2.17352900962902, 55.720701106192884],
              [-2.168847575820451, 55.722244091884683],
              [-2.161573499795486, 55.722900816537404],
              [-2.15880481604317, 55.722841536852194],
              [-2.156132601561002, 55.722221401936196],
              [-2.153567890034004, 55.722199481837066],
              [-2.151743131161108, 55.722509043467703],
              [-2.150497707351681, 55.723202430490161],
              [-2.149141862014061, 55.724591385360092],
              [-2.147741177356326, 55.727199655623309],
              [-2.148058806017175, 55.729508440056271],
              [-2.149850060904011, 55.733238668210774],
              [-2.149875249094603, 55.734822706925847],
              [-2.148736621718262, 55.736489926151506],
              [-2.146043008244699, 55.738444720873026],
              [-2.144426163421163, 55.739211264883664],
              [-2.14150335387485, 55.740001763738192],
              [-2.138724237364999, 55.740475756064797],
              [-2.136629617714672, 55.740408922281929],
              [-2.136602908532747, 55.74005853436833],
              [-2.132026359290196, 55.739350131979414],
              [-2.128404475958841, 55.737832805441101],
              [-2.125341058509397, 55.737061461828027],
              [-2.119475849152895, 55.737630681030289],
              [-2.117298016502117, 55.738428861009638],
              [-2.116655664342205, 55.739316300060395],
              [-2.116707807470097, 55.740775425167044],
              [-2.117944337394668, 55.743079809214784],
              [-2.117321288568064, 55.74511013204058],
              [-2.116065576522363, 55.746539951233274],
              [-2.114496853223459, 55.747733742967618],
              [-2.113123224462465, 55.748118679772105],
              [-2.110606101246364, 55.747492022520611],
              [-2.107795431686557, 55.747251929497857],
              [-2.106679570131479, 55.747546716834627],
              [-2.106002634248348, 55.748161881675642],
              [-2.105110838691094, 55.751222957769848],
              [-2.108790666050886, 55.757399661103207],
              [-2.108495370394705, 55.758357727891685],
              [-2.107269855174989, 55.759451386672559],
              [-2.106283545354812, 55.760051547469317],
              [-2.10244650965913, 55.761293849746437],
              [-2.097894151099175, 55.762077470068668],
              [-2.092101719695302, 55.762157433269756],
              [-2.08652758482859, 55.761519964100977],
              [-2.07525338572807, 55.759939752258255],
              [-2.065575100216159, 55.759300191790842],
              [-2.055294341176036, 55.757205465990602],
              [-2.046025049721327, 55.757990105614425],
              [-2.040704211529079, 55.75905941603812],
              [-2.038128659407379, 55.76012587213097],
              [-2.034016382872066, 55.764347354940114],
              [-2.033568818411885, 55.766481421760837],
              [-2.031643060653302, 55.769758772857209],
              [-2.029616343518155, 55.770673058179455],
              [-2.025177684261095, 55.771291327247567],
              [-2.023091491500001, 55.771758063413593],
              [-2.025225231727668, 55.770876209759187],
              [-2.02939818044546, 55.768841964759979],
            ],
          ],
          [
            [
              [-2.025497990160719, 55.802593170583542],
              [-2.024179930194928, 55.801943826389852],
              [-2.020603410199949, 55.798788064927081],
              [-2.018935511542997, 55.796644521652233],
              [-2.016762615434737, 55.79541749377497],
              [-2.016382430126732, 55.794028468077961],
              [-2.013752138094056, 55.793057519524318],
              [-2.013039275454798, 55.793137565022029],
              [-2.013064700793918, 55.792868012828301],
              [-2.013721791224412, 55.792931733239058],
              [-2.013825306134591, 55.792519310761151],
              [-2.013231945821836, 55.792256117315901],
              [-2.013622566807501, 55.791961366403505],
              [-2.012922405165969, 55.79181319123763],
              [-2.01371332492678, 55.79155703187898],
              [-2.012560307511154, 55.791562548489019],
              [-2.010724463742158, 55.7906300848366],
              [-2.009388136950196, 55.79087009604315],
              [-2.008451970692061, 55.7906356578371],
              [-2.00636903447474, 55.789370701787441],
              [-2.006293986387681, 55.788786681650535],
              [-2.004911237743285, 55.78742372482693],
              [-2.004115540487422, 55.78750462062478],
              [-2.002281704013654, 55.786882012419674],
              [-2.000875262738562, 55.786127294224556],
              [-2.001071379469834, 55.785752618103515],
              [-1.999977530514072, 55.785931426424945],
              [-2.000148138788906, 55.785205438604549],
              [-1.999484825149696, 55.784739117878765],
              [-1.999755888068867, 55.782954698182763],
              [-2.001402905172772, 55.782530595101029],
              [-1.999422673906425, 55.780553007854891],
              [-1.998454920481474, 55.780565580553116],
              [-1.997990988611996, 55.780213362837429],
              [-1.994721130873722, 55.779693939830558],
              [-1.996307547179529, 55.778538523124389],
              [-1.995561531073927, 55.777718169247663],
              [-1.996318719602084, 55.77839296639236],
              [-1.996973970968299, 55.778088391274622],
              [-1.997449103363614, 55.777156656137379],
              [-1.997047443907918, 55.776074853349179],
              [-1.99313105132476, 55.773677505091328],
              [-1.993553745420026, 55.771951506471069],
              [-1.991060018379179, 55.768187532895418],
              [-1.991184748834895, 55.766302484606179],
              [-1.991589548222114, 55.76635282822518],
              [-1.991205563809454, 55.7658613215252],
              [-1.984101210771566, 55.764715931207604],
              [-1.991369730425049, 55.765799336165365],
              [-1.992794196576828, 55.767592834894053],
              [-1.997312620038765, 55.767236301879841],
              [-1.997301488599027, 55.766835569790146],
              [-1.997515032940219, 55.767181497091663],
              [-1.998131824491898, 55.767171623183494],
              [-1.999994944128763, 55.766631633745028],
              [-2.000469871845622, 55.766058387531587],
              [-2.001752797812918, 55.765544429993746],
              [-2.00276003413231, 55.765704342753153],
              [-2.004195946824687, 55.765486861558252],
              [-2.004946848410491, 55.767481506551746],
              [-2.007703023625255, 55.77001333668909],
              [-2.014424518321467, 55.774401895872685],
              [-2.016559086544564, 55.774653202610608],
              [-2.022742117723149, 55.773962152526281],
              [-2.027213931913551, 55.77240323361827],
              [-2.034043217240324, 55.77131433112082],
              [-2.038094716146795, 55.769377761521802],
              [-2.038306255770394, 55.768927545980503],
              [-2.038857908373684, 55.769108869024755],
              [-2.038301353613782, 55.768805351279724],
              [-2.03806273660596, 55.769273545299797],
              [-2.036047211926754, 55.770036984990071],
              [-2.036309449637977, 55.765825628925683],
              [-2.038009630054725, 55.762245477551645],
              [-2.038956838043306, 55.761280186985431],
              [-2.040481186382608, 55.760642652610123],
              [-2.044808527256464, 55.760096649270039],
              [-2.044213966735513, 55.759929744263694],
              [-2.044875131974694, 55.759818088871391],
              [-2.049884072937621, 55.761564634340253],
              [-2.053171175844662, 55.761283815006905],
              [-2.05894727235482, 55.762026027378823],
              [-2.062378193374491, 55.762009950844181],
              [-2.064842581406069, 55.763408537667885],
              [-2.065054174004082, 55.76319368327141],
              [-2.064072985816702, 55.762515831120169],
              [-2.062384299714034, 55.761842826193202],
              [-2.058865549215104, 55.761727763758564],
              [-2.053545302591016, 55.761026679960359],
              [-2.04951553694518, 55.761229642549019],
              [-2.045260025496733, 55.75919348723513],
              [-2.054812971231908, 55.758213803521464],
              [-2.066681057173418, 55.761218797216465],
              [-2.070528879955376, 55.760910251901436],
              [-2.072320360691339, 55.761093349934008],
              [-2.073521913150828, 55.761397546075507],
              [-2.081245994243336, 55.762005177401761],
              [-2.085661759184426, 55.762728160467653],
              [-2.086123288856356, 55.765334393513186],
              [-2.085503152669872, 55.765975460038895],
              [-2.085716523637393, 55.768062531884148],
              [-2.087385021166351, 55.770804480165246],
              [-2.087502880112555, 55.772182697659034],
              [-2.086547877616308, 55.77495794793618],
              [-2.086814725469271, 55.778102512492524],
              [-2.085842547877781, 55.782575931992596],
              [-2.086258850698576, 55.784088713635782],
              [-2.088233018954549, 55.785592295690144],
              [-2.088543439560051, 55.786769106272658],
              [-2.088272207447265, 55.790216843237417],
              [-2.086114443120681, 55.793048648749554],
              [-2.084562302643785, 55.792879014452573],
              [-2.081963273733813, 55.793890691942408],
              [-2.079302667936906, 55.793710046956043],
              [-2.076965416162752, 55.794120353657782],
              [-2.072519633173971, 55.797078225144148],
              [-2.067087962443276, 55.798517118946911],
              [-2.067145188108874, 55.800250289115873],
              [-2.064941783259097, 55.801839121505161],
              [-2.062232990272012, 55.802736329220906],
              [-2.060032929652762, 55.802580190488257],
              [-2.055928924335467, 55.802927156736317],
              [-2.052627822236722, 55.802514413547755],
              [-2.045604188615622, 55.805325933803807],
              [-2.041846101701982, 55.80728510114745],
              [-2.042202130189453, 55.807515892247743],
              [-2.041725418916289, 55.807813458096433],
              [-2.034382928347834, 55.811071894011462],
              [-2.031935769702491, 55.80967629670041],
              [-2.029612463016764, 55.807277000357907],
              [-2.031640340551026, 55.805457036283556],
              [-2.031162348645466, 55.804233407185322],
              [-2.028296307339843, 55.803000470189019],
              [-2.025497990160719, 55.802593170583542],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000004",
        LAD13CDO: "11UB",
        LAD13NM: "Aylesbury Vale",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.944507896767975, 52.073173508656339],
            [-0.944408618083729, 52.070020390963691],
            [-0.941767022689472, 52.068762249343209],
            [-0.941558214573598, 52.068214623393871],
            [-0.939297185514946, 52.067341957721737],
            [-0.940477122180244, 52.067415505303885],
            [-0.942060804241624, 52.066578284088237],
            [-0.940499876532342, 52.065035801197325],
            [-0.941664785022782, 52.064261353851208],
            [-0.939334139038719, 52.062148207599606],
            [-0.937657636064369, 52.062844296773349],
            [-0.933762259439601, 52.06262481224897],
            [-0.937898164473108, 52.061190326804017],
            [-0.93661168515797, 52.05817753998555],
            [-0.935729869275222, 52.05790614585537],
            [-0.933466595327395, 52.055071511228419],
            [-0.931890391453658, 52.054015183248758],
            [-0.931851238478838, 52.052170776140521],
            [-0.930130701254752, 52.049530700146221],
            [-0.930718335484592, 52.048799669881525],
            [-0.930204650895596, 52.048632271521051],
            [-0.930301645877201, 52.045729957576981],
            [-0.929835501398512, 52.045038813299549],
            [-0.930420990152907, 52.043723349313304],
            [-0.930225533055324, 52.043115581529328],
            [-0.928195667781316, 52.042210626107426],
            [-0.927283290584179, 52.039393533057741],
            [-0.924253496599604, 52.035998780264137],
            [-0.922582613424833, 52.035339759397189],
            [-0.914112074433511, 52.029586874623341],
            [-0.912126981407856, 52.026574549001971],
            [-0.911794462057007, 52.024961182467038],
            [-0.908328946827629, 52.022191353499323],
            [-0.90604874410879, 52.021209962222734],
            [-0.904596257323793, 52.022323048291462],
            [-0.903893827527618, 52.023693047115302],
            [-0.90026461217484, 52.025007891484464],
            [-0.899716536227177, 52.02637660849669],
            [-0.898319783582669, 52.027462265508568],
            [-0.897282239799526, 52.027274538268863],
            [-0.8957740622004, 52.028205375510609],
            [-0.893677562060923, 52.028756643908054],
            [-0.89135819356792, 52.029889298142116],
            [-0.890774428519777, 52.031155139896846],
            [-0.890064486544314, 52.031569236699355],
            [-0.889254027925246, 52.031509452451822],
            [-0.887176831614494, 52.030098939189564],
            [-0.883159056450079, 52.028245634620347],
            [-0.880311667004541, 52.028682553155981],
            [-0.879851624198794, 52.029126835254289],
            [-0.877729137427937, 52.029833119011755],
            [-0.876847849132237, 52.030682470444013],
            [-0.877321873401933, 52.031020555805497],
            [-0.876286693112498, 52.032483425491321],
            [-0.876053662993854, 52.033579910940865],
            [-0.873971185031779, 52.035003995692151],
            [-0.873148214418869, 52.035092333773392],
            [-0.873352996120683, 52.035471017116855],
            [-0.872084461955338, 52.036233013273261],
            [-0.873187556522107, 52.03735486039777],
            [-0.872138612776406, 52.039758925046591],
            [-0.871343557390969, 52.040240427029623],
            [-0.869526406733914, 52.040375858555883],
            [-0.866969104500964, 52.040086963622215],
            [-0.867108628237031, 52.038604780179412],
            [-0.865111145909568, 52.038091053347607],
            [-0.865428293293745, 52.037714684169842],
            [-0.861163100762788, 52.035738667451902],
            [-0.861330585906162, 52.035573051957343],
            [-0.859129518042425, 52.034417091115756],
            [-0.857802737719642, 52.033222812016241],
            [-0.858032146036492, 52.033029928961817],
            [-0.85592881316863, 52.0316509762417],
            [-0.856905874228848, 52.030549160500044],
            [-0.851896922948858, 52.028002742877746],
            [-0.852367690362977, 52.027434596317505],
            [-0.849527418735388, 52.025993501307973],
            [-0.847471702056967, 52.025563419132446],
            [-0.845145605187306, 52.022843315955221],
            [-0.843511117397392, 52.022362445590019],
            [-0.839149738109421, 52.021155211440067],
            [-0.835340049633766, 52.018859050559094],
            [-0.831832330756612, 52.018132011087729],
            [-0.829072259629536, 52.015954838056466],
            [-0.826597207040403, 52.014924108689158],
            [-0.8249519344105, 52.014817800890839],
            [-0.819837120865806, 52.01227613670266],
            [-0.819320197165992, 52.011654165879612],
            [-0.819634042517183, 52.010690763272692],
            [-0.81927912732691, 52.010612580626074],
            [-0.819357337857829, 52.007308221188673],
            [-0.818016285891536, 52.006062088917595],
            [-0.816348960703909, 52.005424061377134],
            [-0.81381724327956, 52.005522676569413],
            [-0.812263087093805, 52.006006027597927],
            [-0.807809691318516, 51.999908199322441],
            [-0.80631384629838, 51.995739133135487],
            [-0.804532924969864, 51.986150863557903],
            [-0.803525231697668, 51.985494166048497],
            [-0.799494578403659, 51.986959180291471],
            [-0.794138579351108, 51.987116664874527],
            [-0.789154492575616, 51.987953867465166],
            [-0.782845295497081, 51.988510136509809],
            [-0.776299184689286, 51.988615845203114],
            [-0.771227327090066, 51.982903079169084],
            [-0.765933500637791, 51.98441490471378],
            [-0.762018589482539, 51.985548976478839],
            [-0.761801825127143, 51.985330009673675],
            [-0.757826811730525, 51.982997193569851],
            [-0.755457496159931, 51.980638958871744],
            [-0.748214379326548, 51.977005269002191],
            [-0.743668843811465, 51.975237744361209],
            [-0.74757698497598, 51.973547652298961],
            [-0.74040139371976, 51.969853047704753],
            [-0.733101966284057, 51.971934455675438],
            [-0.73049191223253, 51.972340628894862],
            [-0.729778647391497, 51.973007283399951],
            [-0.723751069828064, 51.975278952453593],
            [-0.713963416500556, 51.977860877457225],
            [-0.713993088395832, 51.978553529315789],
            [-0.713485456457077, 51.978891452154315],
            [-0.712755913000705, 51.978894270728631],
            [-0.713213832083849, 51.979834366546775],
            [-0.714419041696632, 51.980051629155454],
            [-0.713861764906941, 51.980444757581502],
            [-0.714511593365924, 51.980989529358077],
            [-0.71711428751579, 51.981475561538026],
            [-0.717891475515734, 51.982406527447708],
            [-0.715250433681164, 51.984938461319032],
            [-0.715509688020775, 51.985598549864321],
            [-0.713838634808332, 51.987709445278163],
            [-0.713545330986023, 51.989610590406336],
            [-0.713405721037282, 51.989806873207222],
            [-0.712541915695364, 51.989660768062066],
            [-0.711233096905147, 51.988883997002425],
            [-0.709341113202933, 51.988333696361671],
            [-0.703772646526167, 51.988013601734963],
            [-0.701592665907286, 51.986951999664868],
            [-0.701590317410026, 51.985472010731378],
            [-0.700053058432166, 51.985403804841454],
            [-0.700063068348291, 51.984404085729579],
            [-0.696172129789038, 51.982196878886775],
            [-0.693158018196078, 51.982444902444833],
            [-0.687980786106415, 51.980624154576141],
            [-0.682259582460364, 51.979591922577548],
            [-0.678272489896059, 51.978172477396384],
            [-0.677348695557816, 51.976712708742866],
            [-0.670813148486924, 51.976558211026052],
            [-0.67047093177955, 51.977888658826949],
            [-0.66908629304419, 51.97847813554521],
            [-0.668662770839791, 51.977637157088296],
            [-0.666070093764856, 51.976054122443841],
            [-0.653001337811739, 51.969219426519324],
            [-0.651256739286035, 51.968271553291764],
            [-0.652869158164808, 51.967753225621507],
            [-0.653509502402449, 51.967175212536034],
            [-0.653163281461883, 51.966390805149636],
            [-0.653656125494816, 51.965828187797563],
            [-0.653674494879752, 51.96390335130954],
            [-0.655518241415357, 51.961192844936711],
            [-0.655291559347357, 51.960596827765798],
            [-0.659511244432065, 51.959753894628577],
            [-0.660295898152203, 51.957843171616702],
            [-0.662126178117523, 51.955563094353245],
            [-0.664102140404174, 51.956552097336036],
            [-0.665634346297975, 51.955628075772289],
            [-0.665394943956508, 51.955015749421314],
            [-0.667556510433432, 51.954507041446298],
            [-0.671668100293073, 51.956211497274232],
            [-0.671954352720972, 51.955980051986614],
            [-0.672771217577594, 51.954239546881475],
            [-0.672528795233405, 51.952790107204017],
            [-0.673161889473514, 51.952343179745057],
            [-0.671534664753618, 51.950828672850186],
            [-0.67114876124135, 51.949657241392657],
            [-0.669695446029155, 51.94866347127536],
            [-0.670585698903262, 51.947293353230556],
            [-0.672165818058629, 51.946653015799832],
            [-0.67271033170752, 51.946049541308994],
            [-0.672110655591759, 51.94517061854819],
            [-0.670649688490081, 51.944735135961956],
            [-0.670370044844749, 51.943810364216944],
            [-0.671618316062328, 51.943367692816274],
            [-0.673879897639242, 51.943195376445665],
            [-0.673991836315351, 51.944333144494131],
            [-0.674855698300864, 51.945085555726664],
            [-0.678691248895184, 51.944429132761051],
            [-0.682369646844272, 51.942726931044859],
            [-0.683000031983802, 51.941817765250235],
            [-0.68234938800384, 51.941382497368899],
            [-0.682071224854707, 51.939853551461724],
            [-0.681124458398399, 51.93865339357972],
            [-0.681719288300558, 51.938360643006071],
            [-0.681305599594533, 51.938081773940482],
            [-0.681605464028589, 51.937628371134558],
            [-0.680310438192741, 51.936351475726866],
            [-0.680342917572371, 51.93549226561062],
            [-0.681602977851643, 51.935132341804959],
            [-0.682981740109975, 51.93583471297417],
            [-0.683621035230049, 51.935313170590213],
            [-0.688855742046216, 51.929138771919575],
            [-0.691488013335841, 51.927045196977637],
            [-0.690316500337897, 51.925422715345888],
            [-0.692644801025167, 51.920567179658001],
            [-0.695868754506614, 51.917099001141494],
            [-0.698540903737466, 51.913137302210828],
            [-0.702181221797847, 51.909109793917864],
            [-0.696325941760322, 51.904072851169403],
            [-0.695790873984136, 51.90181907451214],
            [-0.695171289011506, 51.901180112140935],
            [-0.69448405740714, 51.900719324937072],
            [-0.692244683831324, 51.900153182392692],
            [-0.69070582864202, 51.900390530974207],
            [-0.689726809079356, 51.900169237414133],
            [-0.682707968541365, 51.900269821055154],
            [-0.678447690853598, 51.90151323003203],
            [-0.67569207822534, 51.90140583048214],
            [-0.673731774404181, 51.901537510998672],
            [-0.673261581227434, 51.901808248364155],
            [-0.67256918241226, 51.901283434955616],
            [-0.672870074421418, 51.900789602590699],
            [-0.672346330579079, 51.90016508621278],
            [-0.671229423349563, 51.899989737376771],
            [-0.668845213671555, 51.89784351748586],
            [-0.667980474685354, 51.897793272624433],
            [-0.667279767123783, 51.896620051097983],
            [-0.666408539593536, 51.896642551679605],
            [-0.665892806774285, 51.896242882127495],
            [-0.665363345256023, 51.896405019099618],
            [-0.662902896881188, 51.895521107742496],
            [-0.662561107277168, 51.89597039258642],
            [-0.660259313388606, 51.894543347145728],
            [-0.659538806855676, 51.894239324755638],
            [-0.65897649212096, 51.893064031487846],
            [-0.659457465496061, 51.892333111774533],
            [-0.660344706780878, 51.892209241986798],
            [-0.659532286162915, 51.89055096056682],
            [-0.65793368564587, 51.890408659733254],
            [-0.657488362858457, 51.889796657873767],
            [-0.655292222670468, 51.888691711789221],
            [-0.654852882306386, 51.887881056785503],
            [-0.652005783690061, 51.886781194016983],
            [-0.651542799015481, 51.886957517381191],
            [-0.649956529268663, 51.886263173041144],
            [-0.648315555483636, 51.886183194730776],
            [-0.64801610984944, 51.885606099679279],
            [-0.647313179262361, 51.885885748885372],
            [-0.647200764932519, 51.885371044132548],
            [-0.642268598936282, 51.884284720666912],
            [-0.638050018801916, 51.883055393104883],
            [-0.637376722382346, 51.882594428471023],
            [-0.629102111921231, 51.882460560381247],
            [-0.628272587400509, 51.882123603853223],
            [-0.623047734601094, 51.884509167878235],
            [-0.622300239107047, 51.88453099029217],
            [-0.620115075276125, 51.885476468793627],
            [-0.61258699438146, 51.877549166451459],
            [-0.608996323004404, 51.875554719108017],
            [-0.586885558192651, 51.870530247088304],
            [-0.583589261988818, 51.870315278302968],
            [-0.582507943297248, 51.867794514562654],
            [-0.579775894020164, 51.866365181371911],
            [-0.577292689156949, 51.865984510780372],
            [-0.576327536126587, 51.865134823366695],
            [-0.57623060296739, 51.864524921975281],
            [-0.575605708567281, 51.864491282993775],
            [-0.573887757385796, 51.862465354218742],
            [-0.573522342170592, 51.860840644453148],
            [-0.569274160449463, 51.858061917519144],
            [-0.571467146988118, 51.85502693449348],
            [-0.570758266635509, 51.854577737540581],
            [-0.569294110149114, 51.851755467004104],
            [-0.56746880997636, 51.851031012085386],
            [-0.564166686451364, 51.851594091804856],
            [-0.56370638297132, 51.851230607724396],
            [-0.563882720843018, 51.850981895140876],
            [-0.566846036573705, 51.85079953651011],
            [-0.565393376369611, 51.848357698370734],
            [-0.564079255577483, 51.847087338283508],
            [-0.562013100596659, 51.845547016232182],
            [-0.557370250825105, 51.845099935515528],
            [-0.556258739516195, 51.844721240217162],
            [-0.555638533218722, 51.843824356988755],
            [-0.555952833094419, 51.84330220659669],
            [-0.553334580609368, 51.841655143380535],
            [-0.551072019681556, 51.839605082036577],
            [-0.548271229663169, 51.835883085341131],
            [-0.546919563245656, 51.835218998480336],
            [-0.545322926921439, 51.835041910364879],
            [-0.541717728672772, 51.832838378446588],
            [-0.537654098763213, 51.831402316171236],
            [-0.539723709663953, 51.828168558166844],
            [-0.541481298333768, 51.826993578202448],
            [-0.542183372760443, 51.825518660719908],
            [-0.543675710894765, 51.82450940317144],
            [-0.546215365208215, 51.825761886964067],
            [-0.549017329993048, 51.827977858038963],
            [-0.550071259976423, 51.828101451960059],
            [-0.553653958878633, 51.826704203516833],
            [-0.555402553539434, 51.828382850414954],
            [-0.557163261479183, 51.830958083839185],
            [-0.560494127558892, 51.8301770609174],
            [-0.56129501055136, 51.828862393704284],
            [-0.558002699455245, 51.825531140840411],
            [-0.558373502501948, 51.824779490114388],
            [-0.558820090266599, 51.824653685262156],
            [-0.560812710776954, 51.826373905516796],
            [-0.56244681127152, 51.823725173969777],
            [-0.566252945877064, 51.820933874724645],
            [-0.567169732544011, 51.819796815918849],
            [-0.57103542402825, 51.818955473941557],
            [-0.572449695495677, 51.819424927493017],
            [-0.572579442153175, 51.819038063976365],
            [-0.573697361828259, 51.819198190051303],
            [-0.574157211876517, 51.818603125232286],
            [-0.575195335775696, 51.818809026130317],
            [-0.576267313048267, 51.816928371377216],
            [-0.574327479768094, 51.816242189506895],
            [-0.577394608321799, 51.81209012934508],
            [-0.577240709897123, 51.811771762126099],
            [-0.581598048742233, 51.807578546834058],
            [-0.581850056515068, 51.806897322594722],
            [-0.591679335544969, 51.808127708074771],
            [-0.594508372002512, 51.811142275027663],
            [-0.594857129081195, 51.814014768076731],
            [-0.597630076143591, 51.814274454348798],
            [-0.603206857668173, 51.813281608809589],
            [-0.606450530652997, 51.812342700828687],
            [-0.6118940783739, 51.812036622729401],
            [-0.612448773312194, 51.812916252883177],
            [-0.613699800709298, 51.813654829706657],
            [-0.614318928853458, 51.813475999947045],
            [-0.615579349606762, 51.814951078778293],
            [-0.618414088343284, 51.8145240436954],
            [-0.620698671304617, 51.814914119281028],
            [-0.621833929326739, 51.816029796517299],
            [-0.622770443384048, 51.815861834287794],
            [-0.624450320028323, 51.816246552058409],
            [-0.625676218580323, 51.815865253748669],
            [-0.626784983479851, 51.817236832830289],
            [-0.627626129200656, 51.817295205366058],
            [-0.632964927399433, 51.81993796196398],
            [-0.634437323202955, 51.818602733952993],
            [-0.635974205173633, 51.818011838525869],
            [-0.637244716403611, 51.817929457454035],
            [-0.640445166932006, 51.816630349417096],
            [-0.644406007139635, 51.815753533652291],
            [-0.647795665041629, 51.813597712637069],
            [-0.651140942071664, 51.815250112600104],
            [-0.656254528359529, 51.815844544624973],
            [-0.659056704244042, 51.815427848005164],
            [-0.662926712330219, 51.816231693855293],
            [-0.664938307798164, 51.815516339008255],
            [-0.66665494023915, 51.815994381524256],
            [-0.667239460718086, 51.815802293396516],
            [-0.668840938471985, 51.817186245765512],
            [-0.669220124572765, 51.818547360631428],
            [-0.672477926500107, 51.819660463442069],
            [-0.676828266109838, 51.823948991146764],
            [-0.678451086862057, 51.826061361801585],
            [-0.678667941253499, 51.827328905212795],
            [-0.679830888255321, 51.828797690270861],
            [-0.679947426807827, 51.82957676442917],
            [-0.681826976811316, 51.83180525888644],
            [-0.683270158821925, 51.835466579872396],
            [-0.687078659428588, 51.839107546654752],
            [-0.690263278994673, 51.841257845269219],
            [-0.690829098189858, 51.840417144944816],
            [-0.69204338451498, 51.840289485700552],
            [-0.695475998175554, 51.841102666944927],
            [-0.693835920299881, 51.842500627741998],
            [-0.691776992707692, 51.845073591633337],
            [-0.686792806657632, 51.849232370099458],
            [-0.6883079916033, 51.850598894280765],
            [-0.692395010639447, 51.857061602917291],
            [-0.694604689092741, 51.856422569955903],
            [-0.696382143535261, 51.858126378425141],
            [-0.699802486611201, 51.858004177376195],
            [-0.701751966510831, 51.857523981031328],
            [-0.703935867525168, 51.855857664372735],
            [-0.707100798364956, 51.854406198116763],
            [-0.709367087764777, 51.856242858580515],
            [-0.710467240632555, 51.857784367440594],
            [-0.714397667944163, 51.857340042951726],
            [-0.714648828230798, 51.857647597077126],
            [-0.717471186276913, 51.85710384270979],
            [-0.72029234565914, 51.856139208232705],
            [-0.722112131187056, 51.85469068453456],
            [-0.722571448283226, 51.85410943021143],
            [-0.722021843347222, 51.852547042154605],
            [-0.722070926649328, 51.850814023875962],
            [-0.722639869660476, 51.850000182064669],
            [-0.725318630956502, 51.849790962134158],
            [-0.727419533225658, 51.850354091550031],
            [-0.731211708849816, 51.848962711595568],
            [-0.731870006359702, 51.848113823487829],
            [-0.732915681124286, 51.84758290590301],
            [-0.734607283901708, 51.844103439359159],
            [-0.736359258533629, 51.842398609644981],
            [-0.741444118114355, 51.842558317526226],
            [-0.744107821629771, 51.84245371032965],
            [-0.7457017407216, 51.842085881421802],
            [-0.74527233309149, 51.838368733851951],
            [-0.744823164668777, 51.837609559514704],
            [-0.739135089658428, 51.834746094162064],
            [-0.737906746716018, 51.834036982767408],
            [-0.736730287466701, 51.831889781060291],
            [-0.737875759217426, 51.830939989951936],
            [-0.731521192394557, 51.826535048770182],
            [-0.730455324589194, 51.825168533657219],
            [-0.728985735455627, 51.821753881759015],
            [-0.723426575323184, 51.817616934662595],
            [-0.721376727553311, 51.816637972945358],
            [-0.719241076471542, 51.816130094610671],
            [-0.714369457684303, 51.817301588226186],
            [-0.711875514669047, 51.818320013619015],
            [-0.711522524260631, 51.81909840825864],
            [-0.709319796145194, 51.820531780568999],
            [-0.701172252744036, 51.814854764346329],
            [-0.699163585811761, 51.811962470598225],
            [-0.696304557634242, 51.810146893903436],
            [-0.692576327548958, 51.805794064917038],
            [-0.688781699438388, 51.803104707038742],
            [-0.682675183511835, 51.797201840943714],
            [-0.684048197364387, 51.796265007368966],
            [-0.682215147497865, 51.794576544843324],
            [-0.683995738896219, 51.793782748689381],
            [-0.688792734523667, 51.792624272784401],
            [-0.690131632359118, 51.792555573135679],
            [-0.690216416666293, 51.792331728475567],
            [-0.684002115264575, 51.789278943162238],
            [-0.684113525212477, 51.788987963872167],
            [-0.681525214955044, 51.788051720251204],
            [-0.679426589303893, 51.784748041392959],
            [-0.679158406660098, 51.782066435976098],
            [-0.67751269393161, 51.780937475947518],
            [-0.676211017645111, 51.778457333129552],
            [-0.677397244827542, 51.775862221038835],
            [-0.676119051398109, 51.773278036092968],
            [-0.676024748116232, 51.771250266678038],
            [-0.6734947123004, 51.768464920510567],
            [-0.676886858408397, 51.766549268577819],
            [-0.677584237820977, 51.766587686573395],
            [-0.681830349673824, 51.764695882120883],
            [-0.684747092740606, 51.762762085057091],
            [-0.686901847056299, 51.762162154743102],
            [-0.689427183798343, 51.760421685172361],
            [-0.692803498489934, 51.758708610563495],
            [-0.690737565111114, 51.755243630131773],
            [-0.689254926488596, 51.753609515543083],
            [-0.689663920340736, 51.752793140345162],
            [-0.692998198952604, 51.7502074046131],
            [-0.694451684816661, 51.749788362234355],
            [-0.69484938471911, 51.7493593824763],
            [-0.700388174727216, 51.749990818398956],
            [-0.706437089913608, 51.749454202033391],
            [-0.706775279517024, 51.750141285587056],
            [-0.712916917770371, 51.750825516778988],
            [-0.713524679429496, 51.751053363178983],
            [-0.713572241247668, 51.751418942778471],
            [-0.715018197338894, 51.750902454991362],
            [-0.715566725975981, 51.751073895337477],
            [-0.717026883896199, 51.750054907581678],
            [-0.719990753696993, 51.751286698085252],
            [-0.722783115402892, 51.74946210440887],
            [-0.724211364826075, 51.749105362063297],
            [-0.722138355440276, 51.74859370185051],
            [-0.72129996687695, 51.747350037869708],
            [-0.719558993816066, 51.746216127318995],
            [-0.717368158330084, 51.742147815470332],
            [-0.720170473836281, 51.740877275053712],
            [-0.719634498045806, 51.740419159735026],
            [-0.720314925026426, 51.740121753957794],
            [-0.718890898406592, 51.738904062529009],
            [-0.718054831382658, 51.739686209154954],
            [-0.71751254994339, 51.7395544108577],
            [-0.717950043515801, 51.737349942974951],
            [-0.716951194630842, 51.73447071594456],
            [-0.717509142351133, 51.734456124665954],
            [-0.717383822931695, 51.732539540804339],
            [-0.715918253635109, 51.731674728242588],
            [-0.715702504098591, 51.729681623653569],
            [-0.713485555596948, 51.725705552280196],
            [-0.712177845664781, 51.72607247546447],
            [-0.711272196132071, 51.725334224989595],
            [-0.713272381996719, 51.723473291205153],
            [-0.722636424630415, 51.720965169798774],
            [-0.723859699995315, 51.720503700948889],
            [-0.724864690937594, 51.719566890898619],
            [-0.72858268170285, 51.718572236216936],
            [-0.730289717061136, 51.7185106663864],
            [-0.733955716555556, 51.720459141504961],
            [-0.736661582405652, 51.719155700786501],
            [-0.737202377942482, 51.720893300994533],
            [-0.73944075821288, 51.72260326138418],
            [-0.740355084320136, 51.723043763667889],
            [-0.742327982173494, 51.722693539064252],
            [-0.742429288362025, 51.724938931507388],
            [-0.743792119142855, 51.725446247016478],
            [-0.745372991104017, 51.726812775200763],
            [-0.749514672187767, 51.734081669581016],
            [-0.750040599607387, 51.735384751456941],
            [-0.752283027735501, 51.738095265531101],
            [-0.752371660133292, 51.739662541342383],
            [-0.753550306838335, 51.740725263454799],
            [-0.754724243873241, 51.742857022675679],
            [-0.763326256460504, 51.749384903771904],
            [-0.764297803184245, 51.749508414437486],
            [-0.764810263429155, 51.750052395007316],
            [-0.765102009438942, 51.753006490649575],
            [-0.762558632618011, 51.753886094603637],
            [-0.763640806332401, 51.757548952097402],
            [-0.761229107289418, 51.758371467402164],
            [-0.762234311210091, 51.759973560062214],
            [-0.763921862138754, 51.760680967408234],
            [-0.765776046149239, 51.762240699953288],
            [-0.766260453677353, 51.763181804147699],
            [-0.765585234071988, 51.76413681151206],
            [-0.766240058692625, 51.764993385843823],
            [-0.765867753997728, 51.765201678613266],
            [-0.767139706466849, 51.766332672021704],
            [-0.767793588270811, 51.767758391480612],
            [-0.767604734115306, 51.768360643178674],
            [-0.766761218774277, 51.768841836919364],
            [-0.769774443119493, 51.771177934261182],
            [-0.768940012753663, 51.771483903780521],
            [-0.769176584786102, 51.771951242607187],
            [-0.771458123749051, 51.774129474076105],
            [-0.774148359373478, 51.776264266265891],
            [-0.774600854629436, 51.776040598470566],
            [-0.775888877363872, 51.775257366701325],
            [-0.779736486993987, 51.774206690623103],
            [-0.781317909297594, 51.774848002954684],
            [-0.782187712321288, 51.774788679670365],
            [-0.779459868353092, 51.771995502497326],
            [-0.783261072631273, 51.77071674471182],
            [-0.784321176905647, 51.769828558802288],
            [-0.785333278319205, 51.769755398252606],
            [-0.786103673068863, 51.771831403663924],
            [-0.786597024059069, 51.772134115343221],
            [-0.788392460937873, 51.771982691364961],
            [-0.793471882294088, 51.776132354219875],
            [-0.792503579002705, 51.776732038029401],
            [-0.798601604960201, 51.781370275639347],
            [-0.801178452190116, 51.780025373460219],
            [-0.803354115381078, 51.781675893806714],
            [-0.806004192604661, 51.780023223584749],
            [-0.808958455664379, 51.782445819057507],
            [-0.81285571963609, 51.780601514934396],
            [-0.81234790335307, 51.779956193335224],
            [-0.813634460967535, 51.780424150059659],
            [-0.81743986996167, 51.781026248594806],
            [-0.819632888382808, 51.781147189590783],
            [-0.819850772011046, 51.780796910726927],
            [-0.821630881855726, 51.781187010401247],
            [-0.823725461827658, 51.77979811917529],
            [-0.82689950141955, 51.780825184153279],
            [-0.827374884365473, 51.780381251481629],
            [-0.830738682519529, 51.779567752551657],
            [-0.83081541358392, 51.779234932004769],
            [-0.832701307915367, 51.779178140661394],
            [-0.832441378270329, 51.778521880134598],
            [-0.834623663841966, 51.778268390125561],
            [-0.834827365961027, 51.777900859477683],
            [-0.836069093119919, 51.778315076224835],
            [-0.836480829982543, 51.778030525070626],
            [-0.837463846038556, 51.778190405651607],
            [-0.83939246437568, 51.777935218142595],
            [-0.839350220196796, 51.775231066816779],
            [-0.83736946588518, 51.772612063057942],
            [-0.834943074131885, 51.770254756772317],
            [-0.835090685689352, 51.769024386444379],
            [-0.836469572473135, 51.768998477634078],
            [-0.84006068186743, 51.768153694991128],
            [-0.842695913280769, 51.768131109669291],
            [-0.847635006139347, 51.768692136358673],
            [-0.848125941064824, 51.768535100534351],
            [-0.850288495104578, 51.76937808753685],
            [-0.851201154680469, 51.76927821164184],
            [-0.853064775658784, 51.76973968518049],
            [-0.854569818398963, 51.769195986884448],
            [-0.856296307453993, 51.769063535444758],
            [-0.856937420752667, 51.768008776989191],
            [-0.858862350690728, 51.767820671717466],
            [-0.85900588810006, 51.767358105080035],
            [-0.859657650617393, 51.767717792907526],
            [-0.860734293291653, 51.7677219362008],
            [-0.862442941698262, 51.767200790573725],
            [-0.860981891147751, 51.765542106167175],
            [-0.861264008820105, 51.765272397446445],
            [-0.85957010495851, 51.764520476784227],
            [-0.856417648224647, 51.760819535542723],
            [-0.861290338077635, 51.758580298885242],
            [-0.863046309352605, 51.757199118857166],
            [-0.863198277229208, 51.756397649311246],
            [-0.863774138327222, 51.756428392449273],
            [-0.86548580907747, 51.753859872359634],
            [-0.867067140267005, 51.753019133213307],
            [-0.867634502756086, 51.753214319811612],
            [-0.869987921504303, 51.751154537774873],
            [-0.871330551067105, 51.750991202880087],
            [-0.875849274829154, 51.748933190980694],
            [-0.876984949795825, 51.748057485440107],
            [-0.877734834821053, 51.748017891031679],
            [-0.883395180738604, 51.749981581762967],
            [-0.883719673486033, 51.74974638936952],
            [-0.886520409263958, 51.750548015588535],
            [-0.887544809089637, 51.751188016033069],
            [-0.890486322078178, 51.751512532982638],
            [-0.890655658881371, 51.75187378973515],
            [-0.899376361152217, 51.752066353085489],
            [-0.904575442083583, 51.751776833924296],
            [-0.903817610883378, 51.747773964780478],
            [-0.904163352171747, 51.746715296249747],
            [-0.909949511495663, 51.739759344277644],
            [-0.911060556883679, 51.740112216947118],
            [-0.91231043130128, 51.738543987337337],
            [-0.911350105473097, 51.738188026522259],
            [-0.912804558786815, 51.737023604790224],
            [-0.913813176347883, 51.737781019738939],
            [-0.914599292660935, 51.737429521440816],
            [-0.914867584271303, 51.737897756689129],
            [-0.916325465891822, 51.73694911863047],
            [-0.916500926308581, 51.73718181689437],
            [-0.916994729453171, 51.736940900382557],
            [-0.917697131966704, 51.737424816866557],
            [-0.918321144076798, 51.737249832199574],
            [-0.918356306515533, 51.737778854147656],
            [-0.918837611817423, 51.737758105904241],
            [-0.918657115921981, 51.738099919715054],
            [-0.919026249327459, 51.738228296257581],
            [-0.918725027239369, 51.738774005339849],
            [-0.919716814743263, 51.738967449207593],
            [-0.919145823029332, 51.739286792288027],
            [-0.91987766711882, 51.739510213569034],
            [-0.919345549074085, 51.739717521487449],
            [-0.920377315358254, 51.740604569043306],
            [-0.920318613771348, 51.742642394257047],
            [-0.920912620133498, 51.744581012049167],
            [-0.924096027828723, 51.747702351379182],
            [-0.928401396859324, 51.745913714345065],
            [-0.932198727925956, 51.749502586056252],
            [-0.932244354243838, 51.750455194086754],
            [-0.932961784517023, 51.751116284800183],
            [-0.933292608991141, 51.752385281472797],
            [-0.936122722692255, 51.753460226796591],
            [-0.940426461016231, 51.752858904084469],
            [-0.941027908371361, 51.753344465634356],
            [-0.942376446409509, 51.752981662380279],
            [-0.943679099624715, 51.753529263167145],
            [-0.944109882066426, 51.753320935269514],
            [-0.944950272574219, 51.753626096372315],
            [-0.947044247904287, 51.755471027747362],
            [-0.947699116160873, 51.75534561631099],
            [-0.94936483386724, 51.756236278203701],
            [-0.949768304400914, 51.755893713420512],
            [-0.95033541894225, 51.756116370464795],
            [-0.950706317098034, 51.755866123290915],
            [-0.951140184093276, 51.756587510015819],
            [-0.953695725579851, 51.757849298089162],
            [-0.960622555515931, 51.758740646069377],
            [-0.9616434089044, 51.758830589109529],
            [-0.961851159612194, 51.758616629931282],
            [-0.963086066206486, 51.759166112957899],
            [-0.966747974111183, 51.758698429819148],
            [-0.969137089771407, 51.758575539401065],
            [-0.969782579258035, 51.758797890096439],
            [-0.970090472742633, 51.758511064434714],
            [-0.970718809995161, 51.758659530225216],
            [-0.971453158672345, 51.758095901901022],
            [-0.97221717201796, 51.75826712591968],
            [-0.972418565956207, 51.758011732194802],
            [-0.972699509894179, 51.758209300766843],
            [-0.973874672340572, 51.75801186175925],
            [-0.975266504631845, 51.758867394358816],
            [-0.976220771796558, 51.760109327465585],
            [-0.977066727775565, 51.75999081112959],
            [-0.977340799909025, 51.760301600233873],
            [-0.978224247199035, 51.760255332825921],
            [-0.979706351848558, 51.760712378646147],
            [-0.981441897896953, 51.760017106696459],
            [-0.981288187987704, 51.759443023180623],
            [-0.980614116424951, 51.759264545942891],
            [-0.981237332347279, 51.758032731714323],
            [-0.98075863681257, 51.757092578318627],
            [-0.981678763265494, 51.755863335050513],
            [-0.981310445921365, 51.754589659381729],
            [-0.980463662965727, 51.753913358841892],
            [-0.980840892054111, 51.753373548791082],
            [-0.982218193904726, 51.75294130494833],
            [-0.98340619696937, 51.752421989966635],
            [-0.986811938896385, 51.752168161028465],
            [-0.987226263881828, 51.752362348686766],
            [-0.986765051668758, 51.753163105030289],
            [-0.989008100667583, 51.753078114879216],
            [-0.989003525886008, 51.753672406978588],
            [-0.990030695401908, 51.753411493511116],
            [-0.990585945682567, 51.754043861631295],
            [-0.992605598443532, 51.754038706155001],
            [-0.99343387591587, 51.754708466187076],
            [-0.995078141567019, 51.754385354887184],
            [-0.997369592826488, 51.753424857110595],
            [-0.998472445484211, 51.753604194523447],
            [-0.998006953856631, 51.754410352598853],
            [-0.998295476533313, 51.754858784195072],
            [-0.999424492467617, 51.754511439981691],
            [-1.001540527782182, 51.754800969527743],
            [-1.003576424228012, 51.75504662305196],
            [-1.00377335678275, 51.754458456943922],
            [-1.005633559586934, 51.754985814624533],
            [-1.007526537162716, 51.754945163889325],
            [-1.007415055572869, 51.75593327495524],
            [-1.012258621365467, 51.755988453000256],
            [-1.023631768089921, 51.75551339822637],
            [-1.025239499164996, 51.755801874625213],
            [-1.027106172228732, 51.757255955165334],
            [-1.028238394249539, 51.757021652565896],
            [-1.029848579846402, 51.757875642814945],
            [-1.031991541000876, 51.756989673572114],
            [-1.032679452583548, 51.755849835237356],
            [-1.032158638292634, 51.755340234209399],
            [-1.032653595126785, 51.754618704187145],
            [-1.032581163227147, 51.753126439881498],
            [-1.031240621600269, 51.75213354363725],
            [-1.030572497861587, 51.749168974449866],
            [-1.029635322851487, 51.748895997182707],
            [-1.030033821060958, 51.74658939988133],
            [-1.029628995584186, 51.746013307985677],
            [-1.030456230834082, 51.74492318432015],
            [-1.031706880450106, 51.744350850265093],
            [-1.034132921499443, 51.744693598137452],
            [-1.036088472888687, 51.744073058612159],
            [-1.037208393699576, 51.744127192753496],
            [-1.037671955447616, 51.744601237547592],
            [-1.038465477070608, 51.744065550198336],
            [-1.039061804782133, 51.744699822727767],
            [-1.043263004813401, 51.74452728635309],
            [-1.044123483672504, 51.74507107115685],
            [-1.045492176486621, 51.744593960113903],
            [-1.045992470409271, 51.745047589835238],
            [-1.045260693784454, 51.745199897437161],
            [-1.045806219119116, 51.745431808260015],
            [-1.04558765799611, 51.74590567781997],
            [-1.047293634465287, 51.746540818979369],
            [-1.048180615500724, 51.747619774936986],
            [-1.050751401073461, 51.748327500798517],
            [-1.052897518073232, 51.749396799181845],
            [-1.053443105664487, 51.749283406235577],
            [-1.053270311960052, 51.749718995203068],
            [-1.054993577370949, 51.752175812279418],
            [-1.056333522965279, 51.751970782334254],
            [-1.058264407902753, 51.752327936243056],
            [-1.064133974504042, 51.754032810325981],
            [-1.065119239163863, 51.753605467488867],
            [-1.065831784910138, 51.753826923617119],
            [-1.066405412943292, 51.753619284131567],
            [-1.068261314827742, 51.754882005672371],
            [-1.067972819587245, 51.755384133622563],
            [-1.068682992752408, 51.756004769207102],
            [-1.072912475479993, 51.758317500249639],
            [-1.074032473370613, 51.758245404014509],
            [-1.075078924432763, 51.758581824454126],
            [-1.076293417573733, 51.758352202445352],
            [-1.077804566885796, 51.758495338128355],
            [-1.080872223030119, 51.756548457933974],
            [-1.083108203634199, 51.760389030930469],
            [-1.08274127327351, 51.760477880575507],
            [-1.082821299168151, 51.764109202881308],
            [-1.081428434064588, 51.767124809328593],
            [-1.079296816890474, 51.769400924722213],
            [-1.07994858644086, 51.770132524700045],
            [-1.078869131229184, 51.771260576240543],
            [-1.080839369907659, 51.772155343995408],
            [-1.080781326938953, 51.772872397086829],
            [-1.082726544291951, 51.773505289919797],
            [-1.079966211178021, 51.777464178314752],
            [-1.083376017335957, 51.780581116574417],
            [-1.085760575006964, 51.781298302556721],
            [-1.096729952556284, 51.782229148398706],
            [-1.097168919141913, 51.781661572276981],
            [-1.096949479815978, 51.781180651639708],
            [-1.097623665000465, 51.780447641740153],
            [-1.106381912951438, 51.781624943507268],
            [-1.105917705367359, 51.781935211071023],
            [-1.110671522611858, 51.783826157477009],
            [-1.115402726905025, 51.786893697417696],
            [-1.121963792727746, 51.787075035612155],
            [-1.122110801306447, 51.78838975712177],
            [-1.122883817012031, 51.78852589804977],
            [-1.120012474309566, 51.792287073667609],
            [-1.120459068639228, 51.792957565768049],
            [-1.123561598157276, 51.794175664210783],
            [-1.121254509538501, 51.796868414197462],
            [-1.117426456869783, 51.79916129358147],
            [-1.119892801102017, 51.800224557395467],
            [-1.120807954168959, 51.804322418254245],
            [-1.124910250682246, 51.807505337260665],
            [-1.124064379861277, 51.808043011191778],
            [-1.12295221709884, 51.810193519451396],
            [-1.122502019973679, 51.809922223229592],
            [-1.120398267400783, 51.810246379628239],
            [-1.11792908495158, 51.811083855670688],
            [-1.117244191964543, 51.814214859702929],
            [-1.116065859702794, 51.815199542260409],
            [-1.113463242364539, 51.815869584376522],
            [-1.11011732498391, 51.817299102235587],
            [-1.114259510019367, 51.818246569113867],
            [-1.117474107292694, 51.820417832600768],
            [-1.119028562598183, 51.820759461307311],
            [-1.123417423150491, 51.824573050974962],
            [-1.123244841644621, 51.825316237142282],
            [-1.126491160373685, 51.828521473737993],
            [-1.127451464251486, 51.829064475345263],
            [-1.130133585431434, 51.829720014298076],
            [-1.130510347219299, 51.831104746416763],
            [-1.133757318818809, 51.831751787385095],
            [-1.137857921400562, 51.833527996259605],
            [-1.140604080216895, 51.834348302704278],
            [-1.140696391065761, 51.834683449047901],
            [-1.13301807119612, 51.838282937038898],
            [-1.129138421086996, 51.839110246775967],
            [-1.127110085593451, 51.841171281342483],
            [-1.125191171724264, 51.842070534425702],
            [-1.12415929671538, 51.842585245869373],
            [-1.12161925268179, 51.845335595710893],
            [-1.12120263639718, 51.845335182300062],
            [-1.117117781603377, 51.843771606679226],
            [-1.112852420022443, 51.84143598210089],
            [-1.108447622624458, 51.841332557179221],
            [-1.103882888947015, 51.840658606479934],
            [-1.102338952240157, 51.8401972776607],
            [-1.100297156625733, 51.838499428765715],
            [-1.097348751384496, 51.837437462858702],
            [-1.092277468478339, 51.834481698403394],
            [-1.089907865495906, 51.834174774635059],
            [-1.08832529871446, 51.83363204050562],
            [-1.086216964023746, 51.831318427715324],
            [-1.080886475282279, 51.829546094362712],
            [-1.078021129489395, 51.829228762001406],
            [-1.075939295786445, 51.829343700737603],
            [-1.072899051940905, 51.830479650927472],
            [-1.07213543317831, 51.831271153147497],
            [-1.071627862874649, 51.83393664480419],
            [-1.06860940242138, 51.834629388873076],
            [-1.067435816084358, 51.834603908206695],
            [-1.066343633039737, 51.833168337870092],
            [-1.061927882684063, 51.832377948840261],
            [-1.059237860376602, 51.835415287351132],
            [-1.049017860545941, 51.838982666058548],
            [-1.048350329086505, 51.839595865512379],
            [-1.047356693866247, 51.839703811352727],
            [-1.047298322279376, 51.840131321380554],
            [-1.049763094930082, 51.839779915604822],
            [-1.051603295541081, 51.839862197479668],
            [-1.05577615749432, 51.841812688844733],
            [-1.062027150248022, 51.844157246277817],
            [-1.062218643609355, 51.846538747324473],
            [-1.063587831108497, 51.847786842427013],
            [-1.061617868229546, 51.84862261624744],
            [-1.066052008360509, 51.852810032912949],
            [-1.06603468398513, 51.853226188035308],
            [-1.063103896237015, 51.854315101435844],
            [-1.063520383006341, 51.855515145450582],
            [-1.063065563138352, 51.855741699698711],
            [-1.063463713550746, 51.856146775719751],
            [-1.063140778535827, 51.85631503790286],
            [-1.064316986504555, 51.857641606612113],
            [-1.063500441219031, 51.858574691059779],
            [-1.064535565855931, 51.859985550352775],
            [-1.064536427640283, 51.86106450013331],
            [-1.066147732909023, 51.864881468729465],
            [-1.065156574214511, 51.86519548381645],
            [-1.065584255183751, 51.866002692148363],
            [-1.064472021986507, 51.866340915415883],
            [-1.065613206383337, 51.868180585957383],
            [-1.064568454891145, 51.868203755538794],
            [-1.064662942675141, 51.868690030208526],
            [-1.069186448001306, 51.874637589730007],
            [-1.071777586326182, 51.874863951623801],
            [-1.074587273432613, 51.8755550198658],
            [-1.076158348053524, 51.875445981750843],
            [-1.076119202454378, 51.876505732349692],
            [-1.077625515775584, 51.876723444388951],
            [-1.076586521845763, 51.87759103618469],
            [-1.076556591676106, 51.878768642977256],
            [-1.074152856817011, 51.880015713986147],
            [-1.072870071269599, 51.881074664804672],
            [-1.071971621968979, 51.881386770285843],
            [-1.07105687641294, 51.881290541841743],
            [-1.070969466959967, 51.881866185014424],
            [-1.070185379915165, 51.882056000691669],
            [-1.070524676466096, 51.882652096785534],
            [-1.069711691897631, 51.883115012216678],
            [-1.069820977788508, 51.884296414343304],
            [-1.07144022779482, 51.885108512984338],
            [-1.070013537400716, 51.885455996218361],
            [-1.069653906975099, 51.88605556128843],
            [-1.068626517706495, 51.886554537151376],
            [-1.06899563351367, 51.887255170436063],
            [-1.069997290000066, 51.887581375433463],
            [-1.070437550949773, 51.888708746380047],
            [-1.071813619494262, 51.888778047836212],
            [-1.073562609814255, 51.888095012822482],
            [-1.075479505100823, 51.888290806418368],
            [-1.076103662014631, 51.887826369456938],
            [-1.076829820619249, 51.887772725353223],
            [-1.077277459472927, 51.888051364252064],
            [-1.076528182823147, 51.888594847006829],
            [-1.077289168731308, 51.889466661650893],
            [-1.078734447615635, 51.890132537059749],
            [-1.080890553952807, 51.890360683926076],
            [-1.082057721581396, 51.890915553565677],
            [-1.083146417238562, 51.890757702481501],
            [-1.085809917850437, 51.891119182337725],
            [-1.090038369125994, 51.892350463540559],
            [-1.090624772740088, 51.892960098048654],
            [-1.092638356487353, 51.893419797982915],
            [-1.093539265587296, 51.893929342732697],
            [-1.092921523638644, 51.894657360766416],
            [-1.092925666689624, 51.895678784172986],
            [-1.091336134479979, 51.89753488010841],
            [-1.087079797711212, 51.897439010748755],
            [-1.084274359549569, 51.899537327374162],
            [-1.086019278123817, 51.900857297441348],
            [-1.085399381567401, 51.901895452588768],
            [-1.085840267845735, 51.902874413103255],
            [-1.085024339812202, 51.904616846014925],
            [-1.086299554611443, 51.905311872930127],
            [-1.085583048025837, 51.907454285880938],
            [-1.083565568478577, 51.910016348935784],
            [-1.082732814828566, 51.911860233028996],
            [-1.082298167043667, 51.911929673774054],
            [-1.082656815208358, 51.915804935700159],
            [-1.081882795664738, 51.915688311883706],
            [-1.080058041469487, 51.91611912755242],
            [-1.079377013449097, 51.917214314732711],
            [-1.081201638290723, 51.917433564800135],
            [-1.083369239805388, 51.918161655778142],
            [-1.085062050206672, 51.919861551292755],
            [-1.086349720052531, 51.920393932696356],
            [-1.086693882730968, 51.922638080662601],
            [-1.085984234016164, 51.923140570261715],
            [-1.082606975603088, 51.923031587091607],
            [-1.082651997668415, 51.925674417617984],
            [-1.081269933357642, 51.927552668357691],
            [-1.082137936841207, 51.927705992337529],
            [-1.081520672994168, 51.928022160645121],
            [-1.078085269758717, 51.927468435856923],
            [-1.075555112761267, 51.929930137408405],
            [-1.075042235357657, 51.93068315871011],
            [-1.077508649858122, 51.931334579748309],
            [-1.077569388084032, 51.93163535753569],
            [-1.070053710052319, 51.934197142076208],
            [-1.064172778998497, 51.93463067526239],
            [-1.062131861471568, 51.935128727721185],
            [-1.060850116279005, 51.937023722104442],
            [-1.060565849857734, 51.938167817106184],
            [-1.059142001541722, 51.939129284102485],
            [-1.05871950283374, 51.940063676967391],
            [-1.0559388852556, 51.942590387944314],
            [-1.055865348161724, 51.94595515628437],
            [-1.054807223864099, 51.946528390647735],
            [-1.054636207097977, 51.947950303905479],
            [-1.055038979884023, 51.948724070721731],
            [-1.056517043000988, 51.949219640447069],
            [-1.059285867585035, 51.94929213964874],
            [-1.063521462979077, 51.948241574541946],
            [-1.064876516124489, 51.948219074991457],
            [-1.066630456260107, 51.946853760263451],
            [-1.070134669226614, 51.945833123341693],
            [-1.070944885778272, 51.946122736250011],
            [-1.072847756953836, 51.944789073653176],
            [-1.076040027459404, 51.943287480817801],
            [-1.077531642929076, 51.943124747785106],
            [-1.078971492987936, 51.94335809672009],
            [-1.080427860999094, 51.944705548838051],
            [-1.078833424263266, 51.946690001226401],
            [-1.081068419731367, 51.950239146045334],
            [-1.081930473947324, 51.950990327768366],
            [-1.083804084625688, 51.951872553832487],
            [-1.086191057422323, 51.952343347945281],
            [-1.089544837993562, 51.954008388183517],
            [-1.088871967393981, 51.955617075529247],
            [-1.095254979463382, 51.957113837057577],
            [-1.093543285498658, 51.960160322325464],
            [-1.091411910797457, 51.962644303685266],
            [-1.088812470496076, 51.964799151256329],
            [-1.08770096829544, 51.966996943700934],
            [-1.087189528612891, 51.966879694578978],
            [-1.085763464340958, 51.968433970834546],
            [-1.084938938816914, 51.968465330645977],
            [-1.083784707004537, 51.970317498705008],
            [-1.08419631620005, 51.972334686529884],
            [-1.082163127404372, 51.97280887199949],
            [-1.079729120105452, 51.975934038218291],
            [-1.078442997415884, 51.979369337646204],
            [-1.079416160449268, 51.979697026364263],
            [-1.078204714807735, 51.981047891898051],
            [-1.062252944504754, 51.994826575371],
            [-1.057892227613672, 51.99940686164927],
            [-1.05318995835434, 52.002525903949063],
            [-1.054802191132689, 52.003166419147199],
            [-1.055391797401623, 52.002769250554564],
            [-1.057552887696156, 52.003219921666442],
            [-1.059042262803709, 52.005208042558699],
            [-1.058454126368415, 52.006715626650561],
            [-1.058952173998911, 52.007906414065303],
            [-1.060865145188965, 52.008600494821749],
            [-1.063430703914163, 52.008396140587074],
            [-1.064607955469558, 52.008603293312937],
            [-1.067409072586188, 52.008210113675176],
            [-1.069869961602402, 52.007218980286794],
            [-1.073946126660105, 52.006311530274587],
            [-1.075582384354052, 52.007503993335582],
            [-1.075800522651136, 52.008301404768488],
            [-1.076693459623065, 52.008238275529955],
            [-1.077172624537924, 52.008812955275708],
            [-1.077666120939526, 52.008265673222489],
            [-1.078679380851, 52.008586485653012],
            [-1.080138528509239, 52.008194191472413],
            [-1.080100924779324, 52.007683211332044],
            [-1.080912700300042, 52.007811823066575],
            [-1.080995620228694, 52.007248736562623],
            [-1.082822645223493, 52.006603035487181],
            [-1.085293525091314, 52.006601563405965],
            [-1.085359073285693, 52.006173203439118],
            [-1.086206915599182, 52.006390170116909],
            [-1.086462224963153, 52.005871573237634],
            [-1.088079895059875, 52.005771716987304],
            [-1.089579424488927, 52.0063245684265],
            [-1.089132187164188, 52.006682548476974],
            [-1.089925143436305, 52.008314240376762],
            [-1.090909140074408, 52.008861293331535],
            [-1.090529551354193, 52.009331288285445],
            [-1.091434734781862, 52.010318284932715],
            [-1.093416422403095, 52.010669809576946],
            [-1.092819361966558, 52.011086889981819],
            [-1.094139961362069, 52.012214624173048],
            [-1.093687232578487, 52.012482669561336],
            [-1.094098390968749, 52.012541575023782],
            [-1.093858176199418, 52.012820245568236],
            [-1.094579348865212, 52.013040672586129],
            [-1.093755573278444, 52.013355316849335],
            [-1.094581504640499, 52.014305714909611],
            [-1.095936574986478, 52.014958971131811],
            [-1.096758817596494, 52.015083057802642],
            [-1.097718613547503, 52.014816187050201],
            [-1.097624476982965, 52.014425259412519],
            [-1.09934106291613, 52.013622014599584],
            [-1.100139716310786, 52.01252402855539],
            [-1.102000044025451, 52.012468090830261],
            [-1.102895165559725, 52.012079307457242],
            [-1.103433342650817, 52.012709172856113],
            [-1.105076237413147, 52.013400477162506],
            [-1.106327131517358, 52.013437842318275],
            [-1.106746344761415, 52.013752107227873],
            [-1.106554639708179, 52.01423256806163],
            [-1.107283511038484, 52.014656170488664],
            [-1.109091801752395, 52.015243477127839],
            [-1.109806879819561, 52.015115815341083],
            [-1.110366960130349, 52.015676583637543],
            [-1.111847641938497, 52.015666174375497],
            [-1.111611259547707, 52.016197554715134],
            [-1.112245587195939, 52.016248188824378],
            [-1.1119970943094, 52.016581678216134],
            [-1.11516648880017, 52.016507517481024],
            [-1.115643403986487, 52.015599417434252],
            [-1.116440123779833, 52.015395006107276],
            [-1.116234592675029, 52.015176783509268],
            [-1.118103786674718, 52.015415560121625],
            [-1.118494482527427, 52.015925571593044],
            [-1.118275255923648, 52.016329422244908],
            [-1.118904630631922, 52.016335927874188],
            [-1.11894502148487, 52.016653609515501],
            [-1.119452468270374, 52.016339123626906],
            [-1.120021177727832, 52.017135472184009],
            [-1.121540166572202, 52.016957096749032],
            [-1.123132685677695, 52.017265657779852],
            [-1.124580291850355, 52.017085812901335],
            [-1.12459380318145, 52.01669211092868],
            [-1.125708055535598, 52.017016863351721],
            [-1.125857125379956, 52.017443239726433],
            [-1.127440110704456, 52.017794828050647],
            [-1.12758436792522, 52.01816991826604],
            [-1.128418433206998, 52.018292971880939],
            [-1.128443435196648, 52.01798117197378],
            [-1.128914046152253, 52.017910026965112],
            [-1.129996123885245, 52.018319015360582],
            [-1.13052103617505, 52.018152961098856],
            [-1.130747877875114, 52.018629354877596],
            [-1.132038013713274, 52.018831269658293],
            [-1.132588118407941, 52.019321728420621],
            [-1.133420423361849, 52.019085097041071],
            [-1.13362865639275, 52.019395016385694],
            [-1.134200644959009, 52.019281439130332],
            [-1.134465536945309, 52.019600764127951],
            [-1.136175540876147, 52.020023296447128],
            [-1.135997240438584, 52.021108990263038],
            [-1.136680015519302, 52.022281912831275],
            [-1.136077673872889, 52.022456417816223],
            [-1.136320451939309, 52.022941908018943],
            [-1.135791908000855, 52.022916455171838],
            [-1.13530123206415, 52.023723835632822],
            [-1.135467227388252, 52.024260911344363],
            [-1.134302910438019, 52.025686413524923],
            [-1.13354851889671, 52.026033313063834],
            [-1.133693821285602, 52.026584624410553],
            [-1.132489823011375, 52.029678527539865],
            [-1.132955963366178, 52.031729184635353],
            [-1.131776679260996, 52.031956064656661],
            [-1.131273781486709, 52.032858641050382],
            [-1.130821996125849, 52.033379479234426],
            [-1.129975646268383, 52.033499107207902],
            [-1.126618226016042, 52.037178523242012],
            [-1.127118987551027, 52.037965339230688],
            [-1.125930468221906, 52.038730646847362],
            [-1.126509462445921, 52.038869802417395],
            [-1.126355934094265, 52.039341585183536],
            [-1.125199558200358, 52.040100830019163],
            [-1.125162684995954, 52.040570779659305],
            [-1.123159348553585, 52.041618616211331],
            [-1.122219445682823, 52.044942742528285],
            [-1.119706096738864, 52.046479421526499],
            [-1.117241008379364, 52.047334000721399],
            [-1.113596676712545, 52.048087060621164],
            [-1.111187680966297, 52.047941250215914],
            [-1.11100331867446, 52.048193402805886],
            [-1.106275783486521, 52.049070228399096],
            [-1.105637010041383, 52.048854097772562],
            [-1.106051464474895, 52.048107401878013],
            [-1.105083348898265, 52.048319433442536],
            [-1.103619018102048, 52.047749972970017],
            [-1.10291600148232, 52.048049415832679],
            [-1.102435926800643, 52.047919888827288],
            [-1.098886942587742, 52.04863006428868],
            [-1.098461975736845, 52.049023312987224],
            [-1.098551303199679, 52.049874534115318],
            [-1.098196125325327, 52.049770220363349],
            [-1.098089099439579, 52.050233331291409],
            [-1.096143303721419, 52.05133959095344],
            [-1.094993377639358, 52.05159420097889],
            [-1.09453480047483, 52.051266108343036],
            [-1.09246266487431, 52.051115309879279],
            [-1.090345185289078, 52.051402880447924],
            [-1.088916867357316, 52.052365562886273],
            [-1.085395915713686, 52.052174662581692],
            [-1.083292306346284, 52.052349828106017],
            [-1.083146522907334, 52.052774862586411],
            [-1.082249053147375, 52.052873971217991],
            [-1.081122758347076, 52.054459282110919],
            [-1.080416985314852, 52.056088322258319],
            [-1.080888840383908, 52.056761823346967],
            [-1.078957860140427, 52.057254737157081],
            [-1.078181939473446, 52.057750363725908],
            [-1.078266342281924, 52.058043227539116],
            [-1.077495479822921, 52.057867269860715],
            [-1.07666886004641, 52.058839905847343],
            [-1.071673519466311, 52.058123659841279],
            [-1.070729526972587, 52.05954487465636],
            [-1.068122505774051, 52.061041957227872],
            [-1.064827414304578, 52.06153194093735],
            [-1.06208205612359, 52.062723900803192],
            [-1.057835138349252, 52.061021325727914],
            [-1.052976244615332, 52.05964904194272],
            [-1.051814723293834, 52.060705126785408],
            [-1.049788890082158, 52.061382912463252],
            [-1.048271658402769, 52.062835290667024],
            [-1.040066133230855, 52.065912878131272],
            [-1.038220126020887, 52.066077650299604],
            [-1.037069763720663, 52.065233904030521],
            [-1.034635474222701, 52.064537879320198],
            [-1.033175528725167, 52.063511740787035],
            [-1.03096451618745, 52.062885801424237],
            [-1.027686136880277, 52.063722833748052],
            [-1.026422618269977, 52.064405604858841],
            [-1.026138958197212, 52.065710540129139],
            [-1.028453930871299, 52.06786857740304],
            [-1.029568397588679, 52.070094912771751],
            [-1.031770316676297, 52.071156860321835],
            [-1.042344575303496, 52.073698640143064],
            [-1.042129622265499, 52.073925262444668],
            [-1.028704532434163, 52.075012112819465],
            [-1.026335223721167, 52.075670483105611],
            [-1.026355325994311, 52.075417105917062],
            [-1.023099080958163, 52.074926239629569],
            [-1.021473173308078, 52.07494782069584],
            [-1.020094172109351, 52.073288335301811],
            [-1.016278586241847, 52.072632591204524],
            [-1.013524862494736, 52.072855932743828],
            [-1.01288313806716, 52.071587338963049],
            [-1.010917739050995, 52.071989842843251],
            [-1.007558485356804, 52.072083888703837],
            [-1.006525655488776, 52.071419752251032],
            [-1.006290088826445, 52.07158140153917],
            [-1.007067021248567, 52.072271256917233],
            [-1.005895360370916, 52.073391540641218],
            [-1.004323818058779, 52.073398058809317],
            [-1.002452362975325, 52.072720507546656],
            [-1.000463285013105, 52.072477988526202],
            [-0.998261044768589, 52.072568986014353],
            [-0.995509189113517, 52.073225284982442],
            [-0.993961492682152, 52.072820084042398],
            [-0.99345654275562, 52.072111784995975],
            [-0.991082417405909, 52.070895698691039],
            [-0.990031154152451, 52.070607978657982],
            [-0.989140743150468, 52.070821523990055],
            [-0.987901431148984, 52.070462942203626],
            [-0.987442594477963, 52.070753005505416],
            [-0.986386140281517, 52.070180195861035],
            [-0.984297483555332, 52.069705480102769],
            [-0.983978509737986, 52.070067763273691],
            [-0.983178597161653, 52.069828900649789],
            [-0.982400907335627, 52.06996065075483],
            [-0.981158783569442, 52.071331828642052],
            [-0.977481844051047, 52.071664127372834],
            [-0.975316889661408, 52.072484184162242],
            [-0.973375543734141, 52.07271184808836],
            [-0.972242079580995, 52.072321655808089],
            [-0.970166550546149, 52.072356590126546],
            [-0.967823403499164, 52.070899346623747],
            [-0.965121046836858, 52.074827173094093],
            [-0.962745485258612, 52.076344645716617],
            [-0.960780877025724, 52.07863262036021],
            [-0.959566552097149, 52.079271055820485],
            [-0.951890733539841, 52.081521831109477],
            [-0.950340656527712, 52.079853708699908],
            [-0.948845830428443, 52.079752279563728],
            [-0.947220805030572, 52.079168653535994],
            [-0.945119596429627, 52.076873364729003],
            [-0.941989793668446, 52.076251017349122],
            [-0.941832105883251, 52.075946607110517],
            [-0.940073681292826, 52.075830112553831],
            [-0.944507896767975, 52.073173508656339],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000005",
        LAD13CDO: "11UC",
        LAD13NM: "Chiltern",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.6734947123004, 51.768464920510567],
            [-0.668750944106491, 51.765720109254133],
            [-0.66670775609033, 51.765125987009561],
            [-0.663632896111554, 51.764824922154503],
            [-0.659374616287973, 51.763480745676013],
            [-0.652478080403538, 51.75935565903643],
            [-0.651339652869262, 51.759924363222765],
            [-0.648620513808668, 51.758546187891113],
            [-0.64923665358719, 51.757611843792759],
            [-0.647803234119228, 51.757287849781036],
            [-0.646935226725619, 51.756031619146057],
            [-0.643690306658738, 51.754080799662809],
            [-0.631065153158192, 51.753042476550057],
            [-0.629402511603296, 51.753360289804917],
            [-0.629903448385375, 51.753993748182978],
            [-0.62851184125216, 51.75459344948942],
            [-0.626243305171052, 51.75362912837948],
            [-0.625552577574391, 51.754381749622475],
            [-0.623733072552373, 51.753470285061354],
            [-0.623250992911655, 51.754363807437393],
            [-0.620226335627546, 51.752876187211065],
            [-0.617309883816319, 51.752643201496795],
            [-0.613790453632968, 51.750906817157592],
            [-0.613563387035, 51.748292052974513],
            [-0.612959929552517, 51.747420831758241],
            [-0.611140313954796, 51.748089008523181],
            [-0.609123645421661, 51.749464270495473],
            [-0.605175458704912, 51.749861675717021],
            [-0.600890748837523, 51.751349233367961],
            [-0.595292791265164, 51.751308544857579],
            [-0.586403211005555, 51.752109238162674],
            [-0.584168416213545, 51.746761942167147],
            [-0.582521647088682, 51.745319603679526],
            [-0.580834861355899, 51.7436321831198],
            [-0.579455272604808, 51.741251591254624],
            [-0.576192881454119, 51.737815936121329],
            [-0.572785970522558, 51.735857573902074],
            [-0.569956779058155, 51.735930185128431],
            [-0.569664326202553, 51.736065099244499],
            [-0.570392886256518, 51.736796001067532],
            [-0.569268919693776, 51.737809181575784],
            [-0.568421643471084, 51.737535406119584],
            [-0.566840672221248, 51.73902944973095],
            [-0.566224251756951, 51.73846264477438],
            [-0.563722855411746, 51.739610921699438],
            [-0.562977410988411, 51.738272828840643],
            [-0.565605882220285, 51.736539856507463],
            [-0.563468605789091, 51.735662233138044],
            [-0.560342573499054, 51.737282961559146],
            [-0.559268063101237, 51.737325543460479],
            [-0.558464310222442, 51.735640523122107],
            [-0.560669135219349, 51.734069710090836],
            [-0.559562123705923, 51.733406042968397],
            [-0.558371346449909, 51.734101791572293],
            [-0.556024860621239, 51.733207977132764],
            [-0.554547569919416, 51.734122263265022],
            [-0.553742986250048, 51.734058414299263],
            [-0.553021977847865, 51.73332750042934],
            [-0.554326673661662, 51.732331083618668],
            [-0.550105382105395, 51.730568845709726],
            [-0.551577729228316, 51.730034906207777],
            [-0.553102565561223, 51.728400102160315],
            [-0.553619345574635, 51.728404665232219],
            [-0.551456924420517, 51.725161671602457],
            [-0.551687709081325, 51.724464056243583],
            [-0.550555099669235, 51.724702761179081],
            [-0.550318922216771, 51.724391429731824],
            [-0.55043608065738, 51.723036913745226],
            [-0.55586223755103, 51.721816097938927],
            [-0.558503527542335, 51.719293962031855],
            [-0.561325168346973, 51.718013043137255],
            [-0.562442813360735, 51.715865096294955],
            [-0.563232259982424, 51.711874305179037],
            [-0.562332030123663, 51.710958719980439],
            [-0.558099201912086, 51.708473673714096],
            [-0.557234865894538, 51.707072032106502],
            [-0.555353378904802, 51.70552750077276],
            [-0.552441463090158, 51.704033190529664],
            [-0.548857649158419, 51.703643697163429],
            [-0.547690274108942, 51.703176085925797],
            [-0.546772912500076, 51.701319621790077],
            [-0.546906790691928, 51.698630924272656],
            [-0.544039275399154, 51.696812344789933],
            [-0.542991696730413, 51.693702570782513],
            [-0.543810512704401, 51.69226323870982],
            [-0.547137581853329, 51.689209440180925],
            [-0.548650418782656, 51.687006256203098],
            [-0.549181860543688, 51.684862871379863],
            [-0.548718288927467, 51.682666732132553],
            [-0.547898185876233, 51.682528913462669],
            [-0.54757348462912, 51.683002366439105],
            [-0.546720640159814, 51.683028685559492],
            [-0.546306159880702, 51.680433005441927],
            [-0.539647808940478, 51.68026499811355],
            [-0.538911120545722, 51.679829623522899],
            [-0.536466853672885, 51.68085574023268],
            [-0.535072591361151, 51.680896808524139],
            [-0.534817139404226, 51.681685805575675],
            [-0.532139748453241, 51.681773771313132],
            [-0.531955796332999, 51.680752694499759],
            [-0.531145283102922, 51.680678720465593],
            [-0.527624129662257, 51.681674998493698],
            [-0.524294713637442, 51.682107991714716],
            [-0.525007825228232, 51.679971487521932],
            [-0.524956030828726, 51.678716470927057],
            [-0.522486937419676, 51.678808629616007],
            [-0.521676796622689, 51.679835200247275],
            [-0.518998649977495, 51.680259152662693],
            [-0.516828079407357, 51.680198497314215],
            [-0.51541542759806, 51.679430729901448],
            [-0.513507610882673, 51.679872382859784],
            [-0.510919018351413, 51.67981262196534],
            [-0.509839425372568, 51.678683937766444],
            [-0.507949157327564, 51.677974761296007],
            [-0.505129599142556, 51.673072499438028],
            [-0.50791414695107, 51.671954242946541],
            [-0.510041652244019, 51.669877144510039],
            [-0.512489903750094, 51.668597155796917],
            [-0.514085186813737, 51.667041967033342],
            [-0.514740275248134, 51.665448794950109],
            [-0.517533663830014, 51.667050473591189],
            [-0.521016672347159, 51.668041228413834],
            [-0.522553394029967, 51.663564620844987],
            [-0.522382750949239, 51.659342575158156],
            [-0.522799622084562, 51.658393777965976],
            [-0.524462624887875, 51.65784729325356],
            [-0.532648047105018, 51.663003287042514],
            [-0.533296620195566, 51.660918075872104],
            [-0.53499303651204, 51.660679382275653],
            [-0.535190592613445, 51.660426476591013],
            [-0.536360940315543, 51.660360139842943],
            [-0.536913523745787, 51.660620595891281],
            [-0.536944605990738, 51.659790132517458],
            [-0.536268902340326, 51.65904347928393],
            [-0.536484662566425, 51.655487176435813],
            [-0.536103772854597, 51.655463546538634],
            [-0.536151843635169, 51.654868882107486],
            [-0.534275182231048, 51.652949084580051],
            [-0.53668782003555, 51.652365922303169],
            [-0.53094951712724, 51.649130032088706],
            [-0.531979375907473, 51.647676329330757],
            [-0.534926057026438, 51.645628801807483],
            [-0.537412993456892, 51.642903780012723],
            [-0.538859242169748, 51.640444511038908],
            [-0.539282010860763, 51.63803633995439],
            [-0.534151675472179, 51.637147851793991],
            [-0.535606961217784, 51.635888256433425],
            [-0.535752625721525, 51.635313690427935],
            [-0.53459499629882, 51.630028179794664],
            [-0.532001426267317, 51.62550970471348],
            [-0.530283617674448, 51.617820778921349],
            [-0.529244628402477, 51.615957223321438],
            [-0.528251272574419, 51.614694892759566],
            [-0.526993672974827, 51.614413858451407],
            [-0.526321074867676, 51.613775081174666],
            [-0.524303692395402, 51.610201510095344],
            [-0.52252238891571, 51.605059073902908],
            [-0.523556574553568, 51.604962382736126],
            [-0.526774453327631, 51.603785294363348],
            [-0.529858222550283, 51.60210558568054],
            [-0.534425719683953, 51.599764535766845],
            [-0.534237652948049, 51.599461855614592],
            [-0.537728741470606, 51.598163790610741],
            [-0.537667034218687, 51.595769356952516],
            [-0.536993788064861, 51.594390590046686],
            [-0.538034073041217, 51.593106007302048],
            [-0.542894730104772, 51.591983098163709],
            [-0.548202202947857, 51.593002908608746],
            [-0.548940753582669, 51.594788854480377],
            [-0.550772888658653, 51.594605571430399],
            [-0.552078907573236, 51.594077673545776],
            [-0.552417444058512, 51.594373188581208],
            [-0.5536362821616, 51.59395300117523],
            [-0.553735210472444, 51.594247358253213],
            [-0.556769682583243, 51.593569843529245],
            [-0.556719269396141, 51.593383989178747],
            [-0.557887268973861, 51.593472977291249],
            [-0.558245698044886, 51.593004402638073],
            [-0.561267246729892, 51.592959647724001],
            [-0.562538387914197, 51.591656099240751],
            [-0.564681182497836, 51.592648955450422],
            [-0.56698082086067, 51.592697732670786],
            [-0.570843124639842, 51.591162234274215],
            [-0.571416209283412, 51.590380615371615],
            [-0.575596129413479, 51.589386545557751],
            [-0.576573049956896, 51.591337960503743],
            [-0.577938877078974, 51.591748362063434],
            [-0.580298594114472, 51.59190640879109],
            [-0.582856882743332, 51.591529086976394],
            [-0.587262802603707, 51.591528242753355],
            [-0.592050267453091, 51.593194419269899],
            [-0.596212433054264, 51.595298922355738],
            [-0.598427945196589, 51.596585163978993],
            [-0.608672558299616, 51.598203284010985],
            [-0.607461678352585, 51.600500747867251],
            [-0.608073684181891, 51.600838008935945],
            [-0.608133743695703, 51.602793562250561],
            [-0.609442635849292, 51.604284644834749],
            [-0.612470769047813, 51.606178199481924],
            [-0.612804140461583, 51.60732231174682],
            [-0.612693139009998, 51.608453080002498],
            [-0.611137804152757, 51.610677270043496],
            [-0.610172707735262, 51.61172959060297],
            [-0.616429465434394, 51.613810477995699],
            [-0.616724556048932, 51.615791270220569],
            [-0.614941177415145, 51.617011125849032],
            [-0.612054021027258, 51.620176343810925],
            [-0.611580252722711, 51.622163342920913],
            [-0.612740348336782, 51.623676000461764],
            [-0.61272154576944, 51.625476850469241],
            [-0.611617041571118, 51.626874620715604],
            [-0.608920309871497, 51.628650969461162],
            [-0.612449814398954, 51.630406578726848],
            [-0.61386944762785, 51.630569912424285],
            [-0.612745447134474, 51.632414357925192],
            [-0.618114396011393, 51.633993688874142],
            [-0.620657720127438, 51.634225008006645],
            [-0.621232385489, 51.629997492302344],
            [-0.622246345346873, 51.628862931555958],
            [-0.624313840965096, 51.630057011805398],
            [-0.625376435492377, 51.630226813064574],
            [-0.627077768087359, 51.631110833888364],
            [-0.629334365843979, 51.633133385735277],
            [-0.632569240264818, 51.63352807552787],
            [-0.634163542079976, 51.634424233418002],
            [-0.635481776265856, 51.634747079230358],
            [-0.638006606753588, 51.634490449431404],
            [-0.640265936871878, 51.634703662026922],
            [-0.641685998417257, 51.632441565256784],
            [-0.64175015536809, 51.63082557491807],
            [-0.643245651727538, 51.628982451808604],
            [-0.643054751528568, 51.627439044317647],
            [-0.641924656387788, 51.624836328098773],
            [-0.642268710283572, 51.622311798886855],
            [-0.641844558322759, 51.620938333710207],
            [-0.642877235645395, 51.620895423653394],
            [-0.642716027194174, 51.619084399290436],
            [-0.653917505679232, 51.618219749835262],
            [-0.654577503041797, 51.617344314934989],
            [-0.653963109092743, 51.615555088617981],
            [-0.656336560427749, 51.615787288153477],
            [-0.657205983034754, 51.615202873276218],
            [-0.657932393437459, 51.612953325141561],
            [-0.659455057245879, 51.612498647901639],
            [-0.661030124812652, 51.610899886018565],
            [-0.663823811012066, 51.610486616180012],
            [-0.675400588148875, 51.60785370239487],
            [-0.675930233748051, 51.608213056164274],
            [-0.676757744483404, 51.609305003290551],
            [-0.674320643071271, 51.613795011151097],
            [-0.676264041947075, 51.614760180878427],
            [-0.677678857411301, 51.618460075670555],
            [-0.679100134830148, 51.620601740266132],
            [-0.68134174007807, 51.620611649429776],
            [-0.688569541012109, 51.617574234942936],
            [-0.689124452201687, 51.618467923860443],
            [-0.689838302449169, 51.625029123830849],
            [-0.688880101014368, 51.627398559944226],
            [-0.688720996227383, 51.629309339926394],
            [-0.69053706152027, 51.633275205869346],
            [-0.692024180556457, 51.637936937633853],
            [-0.695094403704899, 51.641883416502466],
            [-0.697328254117845, 51.645983287548447],
            [-0.696790309625554, 51.646245274360155],
            [-0.697137002871497, 51.646907318394831],
            [-0.693901993444974, 51.65109302253304],
            [-0.691908242447205, 51.654874350712923],
            [-0.694725044935991, 51.657615803528309],
            [-0.695141478311436, 51.65761323712772],
            [-0.701049891745573, 51.661764576493944],
            [-0.705077222160684, 51.660775892679723],
            [-0.705901410834482, 51.661280420866994],
            [-0.705461459986686, 51.662005702353191],
            [-0.706067173547664, 51.662110386288035],
            [-0.706081529957456, 51.662469314839285],
            [-0.706885808255546, 51.662455692358932],
            [-0.705587259249964, 51.664694719298502],
            [-0.715659099720299, 51.669721050827924],
            [-0.714848429528076, 51.670671601280674],
            [-0.718261114734357, 51.672418212311115],
            [-0.718988699782238, 51.673488969232544],
            [-0.719482626124935, 51.674128269973828],
            [-0.724250889509961, 51.675206102844804],
            [-0.729830551118864, 51.675256672778112],
            [-0.731038894430479, 51.675541280709844],
            [-0.733989284915595, 51.678341656348437],
            [-0.734613388678625, 51.680235736385335],
            [-0.73415130268875, 51.681401476358829],
            [-0.735908485241801, 51.681618213634678],
            [-0.73610580946432, 51.683362925331494],
            [-0.736947852996969, 51.683616554796849],
            [-0.737316492561379, 51.684092580728731],
            [-0.739700030177889, 51.684469748560375],
            [-0.74049596215899, 51.684874817088634],
            [-0.743069716590579, 51.684340400898719],
            [-0.743384669810565, 51.68571320231591],
            [-0.74776015702799, 51.684889526179809],
            [-0.748778736705306, 51.687663518608446],
            [-0.749043679805633, 51.687865954890036],
            [-0.750537667033624, 51.687460143298118],
            [-0.754218511206625, 51.689810087539023],
            [-0.758021147164669, 51.69406743186039],
            [-0.75835055277415, 51.69503661659963],
            [-0.757960640204539, 51.696595244120509],
            [-0.76036867534432, 51.699018745476074],
            [-0.762131132848072, 51.702359736510964],
            [-0.760789765031043, 51.702673792735844],
            [-0.757120666705393, 51.705021421556204],
            [-0.755826625129602, 51.706560582752815],
            [-0.754388940698487, 51.707529935846487],
            [-0.75256645316721, 51.706911760129124],
            [-0.750204195390567, 51.703089489152894],
            [-0.746642135322951, 51.704213269214506],
            [-0.744352227659829, 51.706217337154428],
            [-0.742723268719661, 51.708439737813592],
            [-0.742189043642599, 51.708289255276284],
            [-0.74117405675137, 51.710181917245336],
            [-0.741518130826449, 51.711030816775263],
            [-0.73987043356636, 51.71131168184305],
            [-0.739083699011579, 51.711965027485846],
            [-0.738313928611153, 51.715233318302708],
            [-0.736989315896611, 51.716744970007184],
            [-0.736661582405652, 51.719155700786501],
            [-0.733955716555556, 51.720459141504961],
            [-0.730289717061136, 51.7185106663864],
            [-0.72858268170285, 51.718572236216936],
            [-0.724864690937594, 51.719566890898619],
            [-0.723859699995315, 51.720503700948889],
            [-0.722636424630415, 51.720965169798774],
            [-0.713272381996719, 51.723473291205153],
            [-0.711272196132071, 51.725334224989595],
            [-0.712177845664781, 51.72607247546447],
            [-0.713485555596948, 51.725705552280196],
            [-0.715702504098591, 51.729681623653569],
            [-0.715918253635109, 51.731674728242588],
            [-0.717383822931695, 51.732539540804339],
            [-0.717509142351133, 51.734456124665954],
            [-0.716951194630842, 51.73447071594456],
            [-0.717950043515801, 51.737349942974951],
            [-0.71751254994339, 51.7395544108577],
            [-0.718054831382658, 51.739686209154954],
            [-0.718890898406592, 51.738904062529009],
            [-0.720314925026426, 51.740121753957794],
            [-0.719634498045806, 51.740419159735026],
            [-0.720170473836281, 51.740877275053712],
            [-0.717368158330084, 51.742147815470332],
            [-0.719558993816066, 51.746216127318995],
            [-0.72129996687695, 51.747350037869708],
            [-0.722138355440276, 51.74859370185051],
            [-0.724211364826075, 51.749105362063297],
            [-0.722783115402892, 51.74946210440887],
            [-0.719990753696993, 51.751286698085252],
            [-0.717026883896199, 51.750054907581678],
            [-0.715566725975981, 51.751073895337477],
            [-0.715018197338894, 51.750902454991362],
            [-0.713572241247668, 51.751418942778471],
            [-0.713524679429496, 51.751053363178983],
            [-0.712916917770371, 51.750825516778988],
            [-0.706775279517024, 51.750141285587056],
            [-0.706437089913608, 51.749454202033391],
            [-0.700388174727216, 51.749990818398956],
            [-0.69484938471911, 51.7493593824763],
            [-0.694451684816661, 51.749788362234355],
            [-0.692998198952604, 51.7502074046131],
            [-0.689663920340736, 51.752793140345162],
            [-0.689254926488596, 51.753609515543083],
            [-0.690737565111114, 51.755243630131773],
            [-0.692803498489934, 51.758708610563495],
            [-0.689427183798343, 51.760421685172361],
            [-0.686901847056299, 51.762162154743102],
            [-0.684747092740606, 51.762762085057091],
            [-0.681830349673824, 51.764695882120883],
            [-0.677584237820977, 51.766587686573395],
            [-0.676886858408397, 51.766549268577819],
            [-0.6734947123004, 51.768464920510567],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000006",
        LAD13CDO: "11UE",
        LAD13NM: "South Bucks",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.635481776265856, 51.634747079230358],
            [-0.634163542079976, 51.634424233418002],
            [-0.632569240264818, 51.63352807552787],
            [-0.629334365843979, 51.633133385735277],
            [-0.627077768087359, 51.631110833888364],
            [-0.625376435492377, 51.630226813064574],
            [-0.624313840965096, 51.630057011805398],
            [-0.622246345346873, 51.628862931555958],
            [-0.621232385489, 51.629997492302344],
            [-0.620657720127438, 51.634225008006645],
            [-0.618114396011393, 51.633993688874142],
            [-0.612745447134474, 51.632414357925192],
            [-0.61386944762785, 51.630569912424285],
            [-0.612449814398954, 51.630406578726848],
            [-0.608920309871497, 51.628650969461162],
            [-0.611617041571118, 51.626874620715604],
            [-0.61272154576944, 51.625476850469241],
            [-0.612740348336782, 51.623676000461764],
            [-0.611580252722711, 51.622163342920913],
            [-0.612054021027258, 51.620176343810925],
            [-0.614941177415145, 51.617011125849032],
            [-0.616724556048932, 51.615791270220569],
            [-0.616429465434394, 51.613810477995699],
            [-0.610172707735262, 51.61172959060297],
            [-0.611137804152757, 51.610677270043496],
            [-0.612693139009998, 51.608453080002498],
            [-0.612804140461583, 51.60732231174682],
            [-0.612470769047813, 51.606178199481924],
            [-0.609442635849292, 51.604284644834749],
            [-0.608133743695703, 51.602793562250561],
            [-0.608073684181891, 51.600838008935945],
            [-0.607461678352585, 51.600500747867251],
            [-0.608672558299616, 51.598203284010985],
            [-0.598427945196589, 51.596585163978993],
            [-0.596212433054264, 51.595298922355738],
            [-0.592050267453091, 51.593194419269899],
            [-0.587262802603707, 51.591528242753355],
            [-0.582856882743332, 51.591529086976394],
            [-0.580298594114472, 51.59190640879109],
            [-0.577938877078974, 51.591748362063434],
            [-0.576573049956896, 51.591337960503743],
            [-0.575596129413479, 51.589386545557751],
            [-0.571416209283412, 51.590380615371615],
            [-0.570843124639842, 51.591162234274215],
            [-0.56698082086067, 51.592697732670786],
            [-0.564681182497836, 51.592648955450422],
            [-0.562538387914197, 51.591656099240751],
            [-0.561267246729892, 51.592959647724001],
            [-0.558245698044886, 51.593004402638073],
            [-0.557887268973861, 51.593472977291249],
            [-0.556719269396141, 51.593383989178747],
            [-0.556769682583243, 51.593569843529245],
            [-0.553735210472444, 51.594247358253213],
            [-0.5536362821616, 51.59395300117523],
            [-0.552417444058512, 51.594373188581208],
            [-0.552078907573236, 51.594077673545776],
            [-0.550772888658653, 51.594605571430399],
            [-0.548940753582669, 51.594788854480377],
            [-0.548202202947857, 51.593002908608746],
            [-0.542894730104772, 51.591983098163709],
            [-0.538034073041217, 51.593106007302048],
            [-0.536993788064861, 51.594390590046686],
            [-0.537667034218687, 51.595769356952516],
            [-0.537728741470606, 51.598163790610741],
            [-0.534237652948049, 51.599461855614592],
            [-0.534425719683953, 51.599764535766845],
            [-0.529858222550283, 51.60210558568054],
            [-0.526774453327631, 51.603785294363348],
            [-0.523556574553568, 51.604962382736126],
            [-0.52252238891571, 51.605059073902908],
            [-0.520615411315771, 51.601841105051257],
            [-0.519572327084214, 51.600970119721111],
            [-0.517857387967229, 51.600247104841976],
            [-0.511425246950381, 51.599660368543006],
            [-0.503282348167522, 51.599862558309901],
            [-0.500616930158387, 51.5996873697501],
            [-0.499454571030912, 51.595395016512967],
            [-0.498589442458536, 51.594441590851254],
            [-0.49880344289446, 51.59307394394245],
            [-0.499563254752249, 51.592140395521398],
            [-0.49864149801675, 51.591899313067273],
            [-0.498843038722936, 51.591215799276497],
            [-0.497933382092105, 51.590958680215266],
            [-0.497230459662088, 51.589336513614292],
            [-0.495762334249934, 51.588113675796635],
            [-0.494962521181099, 51.586544204769588],
            [-0.495133854593104, 51.585421497965207],
            [-0.494723204734525, 51.584613243498751],
            [-0.491058568154336, 51.582821733191508],
            [-0.489624107401419, 51.581770097169226],
            [-0.488574100604928, 51.579616473201298],
            [-0.489478124052097, 51.578380913838323],
            [-0.489231749050908, 51.577759089621452],
            [-0.487739430309924, 51.576239097097613],
            [-0.485189734259831, 51.575360963343229],
            [-0.48426963404382, 51.57534278982817],
            [-0.48401516720918, 51.575053555696236],
            [-0.484562842677293, 51.574293605808663],
            [-0.485554919687896, 51.573838824309341],
            [-0.485082346639099, 51.572296881070805],
            [-0.484033903159006, 51.57119350477231],
            [-0.484783724851264, 51.56686901065342],
            [-0.482599865299645, 51.565723978652073],
            [-0.483242658098865, 51.565137911896606],
            [-0.482266270502218, 51.563913156538931],
            [-0.482814840153159, 51.562819619381109],
            [-0.48077345586816, 51.561778903114956],
            [-0.481061751280864, 51.560703584957345],
            [-0.48044211216688, 51.560812460143687],
            [-0.477539957792146, 51.560280280155965],
            [-0.477007933747338, 51.559623248663954],
            [-0.476616866245978, 51.558029270169428],
            [-0.477350943806913, 51.555270131796938],
            [-0.477805380016945, 51.554836314072233],
            [-0.47890315857393, 51.55483436339437],
            [-0.482685932338003, 51.551520293605336],
            [-0.484248789287957, 51.551453268546162],
            [-0.48540829961247, 51.55071919909912],
            [-0.487060269174181, 51.549660559747643],
            [-0.488169817826551, 51.547428635109441],
            [-0.48951107656295, 51.547211214879596],
            [-0.489394585773585, 51.546330288218101],
            [-0.491375834290895, 51.546121983471046],
            [-0.491595620621935, 51.545363178550396],
            [-0.49081493059022, 51.545072586530601],
            [-0.49105119927979, 51.544556781131867],
            [-0.492118906083411, 51.542446573286639],
            [-0.491827694968013, 51.541571495945774],
            [-0.493605735916332, 51.541003555859554],
            [-0.495510512897011, 51.538427479459479],
            [-0.493349564251457, 51.535835201709951],
            [-0.491616570397725, 51.534915522316282],
            [-0.491228111112085, 51.534323342635673],
            [-0.490502656311523, 51.534155750155719],
            [-0.49120955519413, 51.533271026743677],
            [-0.490134327435878, 51.532040561250035],
            [-0.489686574826442, 51.530061028130262],
            [-0.488998009059434, 51.528607128095508],
            [-0.490878565235983, 51.526232234684294],
            [-0.49027718681033, 51.524583434308539],
            [-0.490542750180736, 51.522480894906096],
            [-0.49119367144302, 51.521278028302497],
            [-0.490387157762457, 51.520167914897172],
            [-0.491651465013639, 51.51889560475874],
            [-0.492350064705551, 51.517075584712792],
            [-0.491219717764587, 51.515255430784279],
            [-0.489581154456575, 51.514076156749397],
            [-0.489941767752198, 51.513412683109259],
            [-0.489806740747066, 51.511525291980121],
            [-0.485556225589911, 51.510026353769184],
            [-0.485837505072917, 51.509800685001125],
            [-0.483532162230843, 51.508098363568003],
            [-0.483996522993064, 51.507609797241948],
            [-0.483232231699707, 51.507236633776451],
            [-0.483194196890283, 51.506646256295639],
            [-0.483680267932309, 51.506458310037978],
            [-0.483941400445326, 51.505623616063879],
            [-0.484667723220249, 51.505620412335979],
            [-0.48506482068844, 51.505073422968032],
            [-0.485179244769793, 51.503925702535881],
            [-0.484679290232015, 51.503577542686585],
            [-0.485168266774756, 51.502605511248419],
            [-0.486260366132195, 51.501823803740059],
            [-0.486186931306266, 51.500909252081918],
            [-0.48731274688137, 51.500978628764948],
            [-0.487477678808658, 51.500348606528433],
            [-0.488764681600654, 51.500205135036914],
            [-0.489763746341165, 51.498965394480379],
            [-0.489592205534406, 51.498619684920222],
            [-0.488867925905293, 51.49856449587552],
            [-0.489488454960528, 51.497574358339833],
            [-0.48860607154548, 51.496768083740385],
            [-0.488693038141097, 51.495968002045977],
            [-0.489663034486554, 51.495474237522416],
            [-0.490044351706669, 51.494746285269009],
            [-0.496096523188996, 51.494997592466468],
            [-0.500558086672819, 51.494818220690611],
            [-0.500732906206227, 51.495373473331185],
            [-0.508828727134018, 51.493923734353004],
            [-0.514163549580291, 51.493331414291852],
            [-0.531838096854996, 51.493393027355324],
            [-0.529047704733642, 51.496350648067072],
            [-0.527991040637529, 51.498384900238769],
            [-0.527967045351229, 51.501589393646334],
            [-0.528926283564389, 51.501509708108941],
            [-0.527493977374568, 51.505628113050662],
            [-0.52614919659763, 51.507829580067465],
            [-0.526483839156587, 51.509159222306288],
            [-0.528105552182332, 51.509722705931694],
            [-0.528412502586563, 51.510124907651836],
            [-0.540906622502419, 51.509574200434308],
            [-0.541818903906723, 51.509480336624272],
            [-0.542367036960478, 51.508966506279833],
            [-0.542488453995647, 51.509496750541359],
            [-0.553967638157139, 51.509131662553621],
            [-0.564859051812257, 51.510314791437587],
            [-0.568767094870066, 51.511236567221893],
            [-0.568704995474432, 51.511378783558285],
            [-0.566691391949947, 51.514560772008288],
            [-0.568987665398034, 51.517718944333467],
            [-0.565037891869437, 51.520675847125474],
            [-0.565383213440418, 51.521291530796596],
            [-0.567556763592392, 51.521431389796476],
            [-0.57108114806665, 51.523360003674426],
            [-0.571793344668315, 51.524277772560943],
            [-0.572455072308243, 51.527409664848371],
            [-0.57425666117441, 51.528345163846708],
            [-0.575113510000193, 51.529297939484756],
            [-0.57285623886258, 51.529702122131106],
            [-0.569950471771162, 51.529728785844881],
            [-0.567919737541526, 51.530262422589402],
            [-0.567220043363394, 51.532987462544348],
            [-0.569009115825476, 51.532992211483403],
            [-0.572772910567833, 51.533934562056281],
            [-0.575997571474451, 51.533908101166894],
            [-0.58075530194916, 51.526031173315182],
            [-0.581010166435132, 51.523072276259875],
            [-0.58843046445403, 51.523173479587456],
            [-0.589844138179493, 51.522672538446272],
            [-0.589841944814784, 51.524143609766256],
            [-0.5949825951676, 51.524272725726881],
            [-0.594719631422439, 51.5256507525445],
            [-0.595695898315885, 51.525592304187271],
            [-0.59550978858868, 51.526018996288776],
            [-0.596489520842887, 51.525894940961443],
            [-0.596239613292413, 51.527927746491244],
            [-0.602681800540021, 51.52892633009585],
            [-0.605985831064534, 51.530017699186281],
            [-0.606426079114696, 51.532172020464124],
            [-0.608330536601599, 51.532079527163333],
            [-0.610554020988354, 51.531499817644402],
            [-0.611828436913364, 51.5330183663268],
            [-0.613393675897928, 51.532797687181976],
            [-0.613812352649841, 51.533823226822307],
            [-0.614340219264116, 51.533722457114514],
            [-0.614566519303804, 51.534294323218887],
            [-0.616509054000474, 51.53399443120059],
            [-0.616845969906638, 51.53477081619225],
            [-0.617757280221242, 51.534625094607726],
            [-0.618238193306194, 51.535265594097964],
            [-0.619612279082578, 51.535455313252442],
            [-0.618935039048917, 51.536823119255487],
            [-0.619733669525545, 51.537925043820735],
            [-0.620810249212354, 51.537751568781708],
            [-0.624788077245412, 51.538003274610958],
            [-0.630573362670683, 51.538904493392707],
            [-0.635233270799878, 51.537858167329304],
            [-0.636739097233981, 51.537938615992267],
            [-0.639322218356648, 51.537190793013181],
            [-0.64323246937565, 51.536807155236467],
            [-0.644847315432415, 51.536177495329305],
            [-0.645175157439975, 51.534265091291282],
            [-0.647937681371932, 51.532559707118097],
            [-0.652235551245837, 51.530165135237475],
            [-0.653081552615879, 51.529782799938445],
            [-0.653456779236623, 51.530306843038431],
            [-0.654124195042332, 51.530015967132009],
            [-0.653990462299055, 51.529759960122227],
            [-0.658232441906782, 51.528756486351412],
            [-0.659228925579008, 51.528254436102891],
            [-0.659901202274531, 51.528335850748618],
            [-0.660012488614537, 51.52754043291516],
            [-0.659729884721683, 51.522386599925255],
            [-0.659630192114217, 51.520676085010507],
            [-0.660156582030766, 51.520420430942927],
            [-0.659211803166155, 51.520247781788775],
            [-0.658928735300509, 51.519334557436395],
            [-0.657893573173654, 51.519393756814424],
            [-0.656861331803312, 51.518814549283128],
            [-0.655023926109414, 51.518806088125153],
            [-0.654924658047053, 51.517576643949404],
            [-0.654342469709589, 51.517525007667302],
            [-0.653258837552999, 51.515074843126122],
            [-0.653959293132735, 51.512188357348506],
            [-0.654018007202871, 51.511027264307877],
            [-0.653035602180049, 51.510769607666475],
            [-0.653961510201202, 51.508249884848539],
            [-0.655428287630086, 51.5082541170026],
            [-0.65561959136739, 51.50679690678264],
            [-0.659007349335224, 51.507135116055707],
            [-0.659337891333546, 51.504906178995967],
            [-0.653942978931727, 51.504232941239486],
            [-0.65442325071104, 51.501575016503409],
            [-0.65482872941913, 51.501651603144332],
            [-0.655075262766596, 51.501009702413143],
            [-0.653547367047637, 51.500447258180216],
            [-0.648408390694456, 51.500688491404048],
            [-0.647059925588194, 51.501027231960336],
            [-0.644950065564867, 51.502214104016709],
            [-0.643023143134395, 51.502671103952387],
            [-0.642229550409867, 51.500625226902187],
            [-0.644464524149134, 51.496483269872066],
            [-0.643696181055567, 51.496063452197056],
            [-0.643134466981825, 51.496059653397545],
            [-0.642829422944918, 51.496484145152863],
            [-0.64109172759175, 51.496495498756708],
            [-0.638151479537182, 51.496011796880623],
            [-0.637690735343, 51.495423762491534],
            [-0.635723895648478, 51.495005252846966],
            [-0.633845386250309, 51.492693114898231],
            [-0.633899401859672, 51.492379921584146],
            [-0.636145516845614, 51.492365596034567],
            [-0.638121275161103, 51.491852627077868],
            [-0.640377087520197, 51.490300693708555],
            [-0.646490698228689, 51.489990139491738],
            [-0.650703284371049, 51.485860974046155],
            [-0.651747131359888, 51.485481824111346],
            [-0.65238250705003, 51.485571852977095],
            [-0.654776940197705, 51.487064143221303],
            [-0.659075097153741, 51.487102570640559],
            [-0.662301799139312, 51.487924420055634],
            [-0.665797977517032, 51.488350898931564],
            [-0.668208267258233, 51.489170488802692],
            [-0.669996735883758, 51.490077395596913],
            [-0.673518259609156, 51.492715966335432],
            [-0.678516627544685, 51.494561795296228],
            [-0.679382987090328, 51.497133374654517],
            [-0.682424999101361, 51.501249950189262],
            [-0.682742577801042, 51.503277614345016],
            [-0.686301811086814, 51.507170593360755],
            [-0.688729229502978, 51.508595105256163],
            [-0.690902395337133, 51.509192164901101],
            [-0.693058489272859, 51.510287149084327],
            [-0.695276113255928, 51.510341507228283],
            [-0.699043098957674, 51.509628016527131],
            [-0.701856604945541, 51.51036505272976],
            [-0.703182971335285, 51.511104474451535],
            [-0.703583711089639, 51.512623149957207],
            [-0.701604500024422, 51.518676228442899],
            [-0.70152602606253, 51.521283925070684],
            [-0.702070164220001, 51.523945267981439],
            [-0.700012837338677, 51.529071277427889],
            [-0.699740335049385, 51.531248802868248],
            [-0.697710611725532, 51.534779905435315],
            [-0.698110775070769, 51.536824615185978],
            [-0.69782521560735, 51.537787179716695],
            [-0.696420458947369, 51.540020463285728],
            [-0.693652865690885, 51.542605436334682],
            [-0.692696584075953, 51.54402180497997],
            [-0.691288355220452, 51.548672915096262],
            [-0.691222001668628, 51.556816149611983],
            [-0.692208968544745, 51.559320606852928],
            [-0.694195948335567, 51.562172481800438],
            [-0.691602740327706, 51.563203745406888],
            [-0.688302669394729, 51.562372924580039],
            [-0.687030887355752, 51.561749052867526],
            [-0.685432161802413, 51.561508157411282],
            [-0.684535452980647, 51.562436864781532],
            [-0.681767534221846, 51.562666573244002],
            [-0.681633790546046, 51.56364968448235],
            [-0.678152340138095, 51.563705839567177],
            [-0.675513758144716, 51.566630832620092],
            [-0.673788380396276, 51.570441899723683],
            [-0.672156093868663, 51.572682209854051],
            [-0.676580940208916, 51.576984450341889],
            [-0.675111620696542, 51.578133223444773],
            [-0.67267921151552, 51.581227726163156],
            [-0.672309643713892, 51.581775648339914],
            [-0.673670052155699, 51.583878942918368],
            [-0.672741784322107, 51.584925895823531],
            [-0.670497560700927, 51.58570886924003],
            [-0.672192210471192, 51.586745931181234],
            [-0.671207307527151, 51.587652857119288],
            [-0.670924509058299, 51.590715885007121],
            [-0.669991974682855, 51.591308677976826],
            [-0.66948451716094, 51.592718248201891],
            [-0.670147917409626, 51.593383970161774],
            [-0.670763289152194, 51.593470068065656],
            [-0.670916690182903, 51.594001425977197],
            [-0.672223569785914, 51.593939783815337],
            [-0.674187750732658, 51.596144308574345],
            [-0.675787191583433, 51.596946451237955],
            [-0.676097280122282, 51.59730512701563],
            [-0.674694684138133, 51.59797358186848],
            [-0.676778272752864, 51.600550772077504],
            [-0.675852100275803, 51.601913385522423],
            [-0.677538006339964, 51.60337558035377],
            [-0.678166545121768, 51.603460887803557],
            [-0.678633742586197, 51.604080290205793],
            [-0.680190066159654, 51.604837825010598],
            [-0.67735920382198, 51.606150248517856],
            [-0.676484585618686, 51.607077342448825],
            [-0.675131662760744, 51.607418160310736],
            [-0.675400588148875, 51.60785370239487],
            [-0.663823811012066, 51.610486616180012],
            [-0.661030124812652, 51.610899886018565],
            [-0.659455057245879, 51.612498647901639],
            [-0.657932393437459, 51.612953325141561],
            [-0.657205983034754, 51.615202873276218],
            [-0.656336560427749, 51.615787288153477],
            [-0.653963109092743, 51.615555088617981],
            [-0.654577503041797, 51.617344314934989],
            [-0.653917505679232, 51.618219749835262],
            [-0.642716027194174, 51.619084399290436],
            [-0.642877235645395, 51.620895423653394],
            [-0.641844558322759, 51.620938333710207],
            [-0.642268710283572, 51.622311798886855],
            [-0.641924656387788, 51.624836328098773],
            [-0.643054751528568, 51.627439044317647],
            [-0.643245651727538, 51.628982451808604],
            [-0.64175015536809, 51.63082557491807],
            [-0.641685998417257, 51.632441565256784],
            [-0.640265936871878, 51.634703662026922],
            [-0.638006606753588, 51.634490449431404],
            [-0.635481776265856, 51.634747079230358],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000007",
        LAD13CDO: "11UF",
        LAD13NM: "Wycombe",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.808958455664379, 51.782445819057507],
            [-0.806004192604661, 51.780023223584749],
            [-0.803354115381078, 51.781675893806714],
            [-0.801178452190116, 51.780025373460219],
            [-0.798601604960201, 51.781370275639347],
            [-0.792503579002705, 51.776732038029401],
            [-0.793471882294088, 51.776132354219875],
            [-0.788392460937873, 51.771982691364961],
            [-0.786597024059069, 51.772134115343221],
            [-0.786103673068863, 51.771831403663924],
            [-0.785333278319205, 51.769755398252606],
            [-0.784321176905647, 51.769828558802288],
            [-0.783261072631273, 51.77071674471182],
            [-0.779459868353092, 51.771995502497326],
            [-0.782187712321288, 51.774788679670365],
            [-0.781317909297594, 51.774848002954684],
            [-0.779736486993987, 51.774206690623103],
            [-0.775888877363872, 51.775257366701325],
            [-0.774600854629436, 51.776040598470566],
            [-0.774148359373478, 51.776264266265891],
            [-0.771458123749051, 51.774129474076105],
            [-0.769176584786102, 51.771951242607187],
            [-0.768940012753663, 51.771483903780521],
            [-0.769774443119493, 51.771177934261182],
            [-0.766761218774277, 51.768841836919364],
            [-0.767604734115306, 51.768360643178674],
            [-0.767793588270811, 51.767758391480612],
            [-0.767139706466849, 51.766332672021704],
            [-0.765867753997728, 51.765201678613266],
            [-0.766240058692625, 51.764993385843823],
            [-0.765585234071988, 51.76413681151206],
            [-0.766260453677353, 51.763181804147699],
            [-0.765776046149239, 51.762240699953288],
            [-0.763921862138754, 51.760680967408234],
            [-0.762234311210091, 51.759973560062214],
            [-0.761229107289418, 51.758371467402164],
            [-0.763640806332401, 51.757548952097402],
            [-0.762558632618011, 51.753886094603637],
            [-0.765102009438942, 51.753006490649575],
            [-0.764810263429155, 51.750052395007316],
            [-0.764297803184245, 51.749508414437486],
            [-0.763326256460504, 51.749384903771904],
            [-0.754724243873241, 51.742857022675679],
            [-0.753550306838335, 51.740725263454799],
            [-0.752371660133292, 51.739662541342383],
            [-0.752283027735501, 51.738095265531101],
            [-0.750040599607387, 51.735384751456941],
            [-0.749514672187767, 51.734081669581016],
            [-0.745372991104017, 51.726812775200763],
            [-0.743792119142855, 51.725446247016478],
            [-0.742429288362025, 51.724938931507388],
            [-0.742327982173494, 51.722693539064252],
            [-0.740355084320136, 51.723043763667889],
            [-0.73944075821288, 51.72260326138418],
            [-0.737202377942482, 51.720893300994533],
            [-0.736661582405652, 51.719155700786501],
            [-0.736989315896611, 51.716744970007184],
            [-0.738313928611153, 51.715233318302708],
            [-0.739083699011579, 51.711965027485846],
            [-0.73987043356636, 51.71131168184305],
            [-0.741518130826449, 51.711030816775263],
            [-0.74117405675137, 51.710181917245336],
            [-0.742189043642599, 51.708289255276284],
            [-0.742723268719661, 51.708439737813592],
            [-0.744352227659829, 51.706217337154428],
            [-0.746642135322951, 51.704213269214506],
            [-0.750204195390567, 51.703089489152894],
            [-0.75256645316721, 51.706911760129124],
            [-0.754388940698487, 51.707529935846487],
            [-0.755826625129602, 51.706560582752815],
            [-0.757120666705393, 51.705021421556204],
            [-0.760789765031043, 51.702673792735844],
            [-0.762131132848072, 51.702359736510964],
            [-0.76036867534432, 51.699018745476074],
            [-0.757960640204539, 51.696595244120509],
            [-0.75835055277415, 51.69503661659963],
            [-0.758021147164669, 51.69406743186039],
            [-0.754218511206625, 51.689810087539023],
            [-0.750537667033624, 51.687460143298118],
            [-0.749043679805633, 51.687865954890036],
            [-0.748778736705306, 51.687663518608446],
            [-0.74776015702799, 51.684889526179809],
            [-0.743384669810565, 51.68571320231591],
            [-0.743069716590579, 51.684340400898719],
            [-0.74049596215899, 51.684874817088634],
            [-0.739700030177889, 51.684469748560375],
            [-0.737316492561379, 51.684092580728731],
            [-0.736947852996969, 51.683616554796849],
            [-0.73610580946432, 51.683362925331494],
            [-0.735908485241801, 51.681618213634678],
            [-0.73415130268875, 51.681401476358829],
            [-0.734613388678625, 51.680235736385335],
            [-0.733989284915595, 51.678341656348437],
            [-0.731038894430479, 51.675541280709844],
            [-0.729830551118864, 51.675256672778112],
            [-0.724250889509961, 51.675206102844804],
            [-0.719482626124935, 51.674128269973828],
            [-0.718988699782238, 51.673488969232544],
            [-0.718261114734357, 51.672418212311115],
            [-0.714848429528076, 51.670671601280674],
            [-0.715659099720299, 51.669721050827924],
            [-0.705587259249964, 51.664694719298502],
            [-0.706885808255546, 51.662455692358932],
            [-0.706081529957456, 51.662469314839285],
            [-0.706067173547664, 51.662110386288035],
            [-0.705461459986686, 51.662005702353191],
            [-0.705901410834482, 51.661280420866994],
            [-0.705077222160684, 51.660775892679723],
            [-0.701049891745573, 51.661764576493944],
            [-0.695141478311436, 51.65761323712772],
            [-0.694725044935991, 51.657615803528309],
            [-0.691908242447205, 51.654874350712923],
            [-0.693901993444974, 51.65109302253304],
            [-0.697137002871497, 51.646907318394831],
            [-0.696790309625554, 51.646245274360155],
            [-0.697328254117845, 51.645983287548447],
            [-0.695094403704899, 51.641883416502466],
            [-0.692024180556457, 51.637936937633853],
            [-0.69053706152027, 51.633275205869346],
            [-0.688720996227383, 51.629309339926394],
            [-0.688880101014368, 51.627398559944226],
            [-0.689838302449169, 51.625029123830849],
            [-0.689124452201687, 51.618467923860443],
            [-0.688569541012109, 51.617574234942936],
            [-0.68134174007807, 51.620611649429776],
            [-0.679100134830148, 51.620601740266132],
            [-0.677678857411301, 51.618460075670555],
            [-0.676264041947075, 51.614760180878427],
            [-0.674320643071271, 51.613795011151097],
            [-0.676757744483404, 51.609305003290551],
            [-0.675930233748051, 51.608213056164274],
            [-0.675400588148875, 51.60785370239487],
            [-0.675131662760744, 51.607418160310736],
            [-0.676484585618686, 51.607077342448825],
            [-0.67735920382198, 51.606150248517856],
            [-0.680190066159654, 51.604837825010598],
            [-0.678633742586197, 51.604080290205793],
            [-0.678166545121768, 51.603460887803557],
            [-0.677538006339964, 51.60337558035377],
            [-0.675852100275803, 51.601913385522423],
            [-0.676778272752864, 51.600550772077504],
            [-0.674694684138133, 51.59797358186848],
            [-0.676097280122282, 51.59730512701563],
            [-0.675787191583433, 51.596946451237955],
            [-0.674187750732658, 51.596144308574345],
            [-0.672223569785914, 51.593939783815337],
            [-0.670916690182903, 51.594001425977197],
            [-0.670763289152194, 51.593470068065656],
            [-0.670147917409626, 51.593383970161774],
            [-0.66948451716094, 51.592718248201891],
            [-0.669991974682855, 51.591308677976826],
            [-0.670924509058299, 51.590715885007121],
            [-0.671207307527151, 51.587652857119288],
            [-0.672192210471192, 51.586745931181234],
            [-0.670497560700927, 51.58570886924003],
            [-0.672741784322107, 51.584925895823531],
            [-0.673670052155699, 51.583878942918368],
            [-0.672309643713892, 51.581775648339914],
            [-0.67267921151552, 51.581227726163156],
            [-0.675111620696542, 51.578133223444773],
            [-0.676580940208916, 51.576984450341889],
            [-0.672156093868663, 51.572682209854051],
            [-0.673788380396276, 51.570441899723683],
            [-0.675513758144716, 51.566630832620092],
            [-0.678152340138095, 51.563705839567177],
            [-0.681633790546046, 51.56364968448235],
            [-0.681767534221846, 51.562666573244002],
            [-0.684535452980647, 51.562436864781532],
            [-0.685432161802413, 51.561508157411282],
            [-0.687030887355752, 51.561749052867526],
            [-0.688302669394729, 51.562372924580039],
            [-0.691602740327706, 51.563203745406888],
            [-0.694195948335567, 51.562172481800438],
            [-0.694547941202259, 51.563180792216208],
            [-0.696205839558373, 51.565038962293549],
            [-0.698831856725292, 51.565587835709529],
            [-0.701542438176358, 51.565652023865887],
            [-0.703386723103445, 51.562847162721951],
            [-0.705487120412052, 51.562242714874664],
            [-0.708503500479715, 51.563116687450915],
            [-0.711220673416326, 51.564320895427123],
            [-0.712210496540582, 51.565491712448328],
            [-0.711464145841487, 51.568672039633846],
            [-0.712281318365208, 51.572229199459663],
            [-0.715067311280295, 51.576030918250282],
            [-0.716925097969246, 51.577191408000495],
            [-0.718964708138202, 51.577688455121084],
            [-0.722942528183974, 51.577825315160858],
            [-0.727882924470526, 51.577239640690628],
            [-0.733779050875288, 51.574921452996676],
            [-0.737749924849059, 51.573842938959878],
            [-0.740653055784573, 51.571867143361573],
            [-0.745150728692851, 51.570715801988776],
            [-0.748850289643855, 51.56840662662816],
            [-0.75388220831783, 51.566534104958905],
            [-0.757479796917209, 51.564814340305297],
            [-0.760330958145923, 51.565864163724612],
            [-0.76308166840947, 51.566420106062751],
            [-0.768536630988018, 51.565840844764878],
            [-0.77022863637422, 51.566710111474833],
            [-0.770942579504915, 51.567896418309665],
            [-0.773246770130995, 51.567307281222703],
            [-0.77567258245284, 51.566377678411939],
            [-0.776609386272078, 51.565608754568146],
            [-0.77777559892005, 51.563923247708878],
            [-0.779371170859372, 51.56015339305506],
            [-0.782535917272864, 51.556166016052615],
            [-0.78680884207787, 51.553821133382371],
            [-0.790993368464347, 51.552835649843004],
            [-0.792982582240154, 51.551356287498812],
            [-0.794489343869999, 51.551155071529855],
            [-0.80328624648857, 51.551913196810823],
            [-0.807146888683192, 51.552758167617469],
            [-0.810230066513254, 51.55272472351367],
            [-0.81693884246244, 51.550985188503802],
            [-0.823337563975891, 51.550622406453414],
            [-0.824909914773243, 51.550164292069411],
            [-0.826371785440118, 51.549178131663787],
            [-0.827901482579152, 51.546391570008936],
            [-0.828904643395675, 51.545627389540968],
            [-0.831381409188393, 51.545715022068087],
            [-0.832748224057564, 51.545332985040659],
            [-0.834319301990794, 51.545760426100827],
            [-0.835765284681673, 51.546739601376458],
            [-0.837616543327597, 51.546984545849938],
            [-0.838482151295995, 51.546802489957422],
            [-0.840941425828264, 51.545140840650049],
            [-0.842762473749349, 51.54475597920289],
            [-0.847066038302899, 51.545198473256342],
            [-0.856279760643192, 51.548703713812486],
            [-0.857549122687185, 51.548810491325305],
            [-0.859779822769734, 51.548365511331255],
            [-0.862544146621539, 51.549017265347324],
            [-0.863653360003268, 51.549937979444316],
            [-0.864984710566832, 51.55317801337943],
            [-0.867948776833647, 51.556199104096379],
            [-0.87117391944855, 51.557663455755062],
            [-0.872767427198164, 51.560307963300588],
            [-0.875618818277283, 51.5617362297955],
            [-0.879742406894634, 51.562124539823728],
            [-0.88141863117667, 51.562011944346864],
            [-0.883498576275796, 51.561436493958794],
            [-0.887389732844045, 51.559468282889995],
            [-0.889600043463029, 51.556967027250664],
            [-0.890401509505113, 51.554855249639168],
            [-0.893655961002801, 51.549309282004586],
            [-0.896901839556156, 51.544860137217938],
            [-0.898317694749977, 51.545289755891567],
            [-0.903215410761407, 51.545876035471778],
            [-0.902166364868111, 51.548598823591334],
            [-0.902804979249656, 51.552928032563614],
            [-0.901720811844267, 51.556082989030578],
            [-0.906311884137233, 51.556754383444542],
            [-0.910140093948814, 51.557724235681434],
            [-0.91113421132725, 51.558439314149467],
            [-0.911784714436276, 51.559832772436245],
            [-0.913644039841186, 51.561715781414534],
            [-0.914729932458389, 51.561490243439685],
            [-0.915936248868874, 51.561720789399104],
            [-0.917099341809992, 51.562556966772775],
            [-0.918510925718043, 51.561494570601752],
            [-0.919612764614145, 51.561873378703751],
            [-0.921413984849173, 51.564644110097859],
            [-0.924155377448371, 51.563411326729906],
            [-0.928356330047113, 51.565994412999665],
            [-0.92783952833056, 51.568092856023391],
            [-0.93052584503559, 51.56853277920581],
            [-0.93040715980721, 51.569040628693315],
            [-0.933868950082431, 51.569764483176037],
            [-0.936776165955735, 51.571380585353289],
            [-0.938622419069224, 51.571038524473387],
            [-0.939359852724442, 51.574010652755263],
            [-0.940125501850879, 51.576456116115921],
            [-0.93954036435363, 51.580330746584913],
            [-0.939910832134808, 51.582086572398616],
            [-0.941372786127044, 51.584326105602962],
            [-0.941550002527234, 51.585457957760703],
            [-0.934565467148933, 51.588546362958461],
            [-0.93325970079804, 51.588645103354743],
            [-0.928525036405655, 51.590059539202137],
            [-0.9282565009579, 51.590674816609202],
            [-0.924899639962843, 51.5908851017793],
            [-0.921029929794, 51.594644990956894],
            [-0.919455790960705, 51.595519791194967],
            [-0.919902357831587, 51.595621909567178],
            [-0.91688828369528, 51.599309504204882],
            [-0.917089705023594, 51.60000731777437],
            [-0.920091738383077, 51.603586677224357],
            [-0.921999521653781, 51.603633880733618],
            [-0.924020986672808, 51.604247670692381],
            [-0.929272427031192, 51.604320853004133],
            [-0.9298063927191, 51.604149484521912],
            [-0.931358555884908, 51.602246599540194],
            [-0.933524571892856, 51.601623389671225],
            [-0.936251123252256, 51.602282035261545],
            [-0.936025512709871, 51.603286157558465],
            [-0.938282536137217, 51.603837095710411],
            [-0.93894965538037, 51.604332272079233],
            [-0.942065116739114, 51.60448356950166],
            [-0.943175837176088, 51.605030373377367],
            [-0.942621958193631, 51.607490896767729],
            [-0.94414332773654, 51.60945037865055],
            [-0.943919487692982, 51.610757548678315],
            [-0.940058816040762, 51.614185443969646],
            [-0.938586194561478, 51.61793963780196],
            [-0.939605966832127, 51.618558483417118],
            [-0.945538707216316, 51.618412291576362],
            [-0.947636341259043, 51.620458719789148],
            [-0.949813979433509, 51.620674231578512],
            [-0.95009137998929, 51.622737591326874],
            [-0.949509569410239, 51.625600714108323],
            [-0.950050204181417, 51.628903672104776],
            [-0.948893935170527, 51.631362424817162],
            [-0.943611476918602, 51.63049859050556],
            [-0.943359247392482, 51.631094263586007],
            [-0.942337892592718, 51.631772029059377],
            [-0.940131607708651, 51.632764594657452],
            [-0.938237519727779, 51.635110481440108],
            [-0.935420148293213, 51.635654146501814],
            [-0.934606808610272, 51.636695192917657],
            [-0.937558881023464, 51.641645735966499],
            [-0.940232456101822, 51.643628259186478],
            [-0.941641466928643, 51.644190350134657],
            [-0.94462753996771, 51.646546939231442],
            [-0.945164284223917, 51.648330296202239],
            [-0.948361746100338, 51.649441553298772],
            [-0.950747090968159, 51.650737890802922],
            [-0.949972321803848, 51.650926084748654],
            [-0.945004722324596, 51.649787297893752],
            [-0.944022954325986, 51.651170372727371],
            [-0.944474127047705, 51.652158106603387],
            [-0.948201760801019, 51.652107922051613],
            [-0.946338775013327, 51.653780742883612],
            [-0.939813578292898, 51.654029542962824],
            [-0.93955742592273, 51.654289784882216],
            [-0.933190413176642, 51.652598362207307],
            [-0.931517738773602, 51.653161318026342],
            [-0.931455747661222, 51.655306146084286],
            [-0.930979833380458, 51.655196614407622],
            [-0.929451925508149, 51.6557455726551],
            [-0.929206363122833, 51.65377417736687],
            [-0.927272381994723, 51.651440298137715],
            [-0.927672888353376, 51.654682725205674],
            [-0.925193564045924, 51.654995451863456],
            [-0.924629958318672, 51.655721309574602],
            [-0.923236297739573, 51.656457541964286],
            [-0.923119517328727, 51.656993269121685],
            [-0.925072387432336, 51.659690647229546],
            [-0.926568381778226, 51.660010939806241],
            [-0.928364008633373, 51.661052373988696],
            [-0.929557458645765, 51.663355207218821],
            [-0.932234385868988, 51.664864976391769],
            [-0.931408690836532, 51.665114627411768],
            [-0.930817489993309, 51.666090228245871],
            [-0.929374347806571, 51.666278496176062],
            [-0.928633206019096, 51.665270977856004],
            [-0.927837972071868, 51.665576629372573],
            [-0.92491070653758, 51.665423994871752],
            [-0.924932089519808, 51.666903303939755],
            [-0.91865781442269, 51.671226412055049],
            [-0.917750696546457, 51.672396853576103],
            [-0.915716008012683, 51.672456319056963],
            [-0.910402817541595, 51.673845812992944],
            [-0.907043480395569, 51.673418979768144],
            [-0.90472432502595, 51.672198804646563],
            [-0.901225157410063, 51.67098373736021],
            [-0.897063684658605, 51.670015012332286],
            [-0.894766880350298, 51.668250857105285],
            [-0.883992611051097, 51.667723695396958],
            [-0.877728225005905, 51.668381547282664],
            [-0.878621982985339, 51.670016662196815],
            [-0.881389929466421, 51.672416831354873],
            [-0.884156384100865, 51.673771197571718],
            [-0.888954728076218, 51.673952446981055],
            [-0.892163984476729, 51.674775810809912],
            [-0.895091018118633, 51.67466130843367],
            [-0.897918176290437, 51.676397161665541],
            [-0.895785996002161, 51.677305071765993],
            [-0.894343155755378, 51.678885708213564],
            [-0.893834511478091, 51.679103911364493],
            [-0.892486243360961, 51.678284665279079],
            [-0.891038323860553, 51.678645955991094],
            [-0.886785420051121, 51.681386830499846],
            [-0.881858097043775, 51.680537992042204],
            [-0.879917732770056, 51.680861189605686],
            [-0.881987212896811, 51.684633984470842],
            [-0.885685933886315, 51.686757863100965],
            [-0.89266915944822, 51.689050194145686],
            [-0.893838687566078, 51.690449506672977],
            [-0.89479522061501, 51.692518472742989],
            [-0.890775419188838, 51.697280284369128],
            [-0.889278900511626, 51.699897074781958],
            [-0.889585760092023, 51.70125320226709],
            [-0.887257376359, 51.703320811617225],
            [-0.88658037297091, 51.70507943841929],
            [-0.887093875743583, 51.705207486733173],
            [-0.888599826506692, 51.707357228953732],
            [-0.88929040506522, 51.707111095927715],
            [-0.889699856455898, 51.708121117775605],
            [-0.88866374357364, 51.709001485722396],
            [-0.888872966673756, 51.709972751181553],
            [-0.888349409853651, 51.7103706203957],
            [-0.888275805131836, 51.712369640141397],
            [-0.887646234747071, 51.713665655925737],
            [-0.887705299818289, 51.718339109160254],
            [-0.889418623640081, 51.720138329596814],
            [-0.891362200860387, 51.72146674268631],
            [-0.89348643271828, 51.722041537121171],
            [-0.895468782123661, 51.721846185700421],
            [-0.897187556384736, 51.722844198361379],
            [-0.899722495766312, 51.724994446916945],
            [-0.901678973315998, 51.728141786860711],
            [-0.900615697593671, 51.729356487848968],
            [-0.903358858050217, 51.731061724148702],
            [-0.903577277906208, 51.731250784755957],
            [-0.90316561376965, 51.731388110381168],
            [-0.90405024124391, 51.731991598484313],
            [-0.905405690879217, 51.733307095067083],
            [-0.906116989048008, 51.733355077586921],
            [-0.908777142095372, 51.73509807553787],
            [-0.909406294492931, 51.736574920357022],
            [-0.9104329144223, 51.736821815840614],
            [-0.91108327030491, 51.737118267005904],
            [-0.91101431059965, 51.73685417794578],
            [-0.912216266907544, 51.737037044261108],
            [-0.912580729300708, 51.736695143853254],
            [-0.912804558786815, 51.737023604790224],
            [-0.911350105473097, 51.738188026522259],
            [-0.91231043130128, 51.738543987337337],
            [-0.911060556883679, 51.740112216947118],
            [-0.909949511495663, 51.739759344277644],
            [-0.904163352171747, 51.746715296249747],
            [-0.903817610883378, 51.747773964780478],
            [-0.904575442083583, 51.751776833924296],
            [-0.899376361152217, 51.752066353085489],
            [-0.890655658881371, 51.75187378973515],
            [-0.890486322078178, 51.751512532982638],
            [-0.887544809089637, 51.751188016033069],
            [-0.886520409263958, 51.750548015588535],
            [-0.883719673486033, 51.74974638936952],
            [-0.883395180738604, 51.749981581762967],
            [-0.877734834821053, 51.748017891031679],
            [-0.876984949795825, 51.748057485440107],
            [-0.875849274829154, 51.748933190980694],
            [-0.871330551067105, 51.750991202880087],
            [-0.869987921504303, 51.751154537774873],
            [-0.867634502756086, 51.753214319811612],
            [-0.867067140267005, 51.753019133213307],
            [-0.86548580907747, 51.753859872359634],
            [-0.863774138327222, 51.756428392449273],
            [-0.863198277229208, 51.756397649311246],
            [-0.863046309352605, 51.757199118857166],
            [-0.861290338077635, 51.758580298885242],
            [-0.856417648224647, 51.760819535542723],
            [-0.85957010495851, 51.764520476784227],
            [-0.861264008820105, 51.765272397446445],
            [-0.860981891147751, 51.765542106167175],
            [-0.862442941698262, 51.767200790573725],
            [-0.860734293291653, 51.7677219362008],
            [-0.859657650617393, 51.767717792907526],
            [-0.85900588810006, 51.767358105080035],
            [-0.858862350690728, 51.767820671717466],
            [-0.856937420752667, 51.768008776989191],
            [-0.856296307453993, 51.769063535444758],
            [-0.854569818398963, 51.769195986884448],
            [-0.853064775658784, 51.76973968518049],
            [-0.851201154680469, 51.76927821164184],
            [-0.850288495104578, 51.76937808753685],
            [-0.848125941064824, 51.768535100534351],
            [-0.847635006139347, 51.768692136358673],
            [-0.842695913280769, 51.768131109669291],
            [-0.84006068186743, 51.768153694991128],
            [-0.836469572473135, 51.768998477634078],
            [-0.835090685689352, 51.769024386444379],
            [-0.834943074131885, 51.770254756772317],
            [-0.83736946588518, 51.772612063057942],
            [-0.839350220196796, 51.775231066816779],
            [-0.83939246437568, 51.777935218142595],
            [-0.837463846038556, 51.778190405651607],
            [-0.836480829982543, 51.778030525070626],
            [-0.836069093119919, 51.778315076224835],
            [-0.834827365961027, 51.777900859477683],
            [-0.834623663841966, 51.778268390125561],
            [-0.832441378270329, 51.778521880134598],
            [-0.832701307915367, 51.779178140661394],
            [-0.83081541358392, 51.779234932004769],
            [-0.830738682519529, 51.779567752551657],
            [-0.827374884365473, 51.780381251481629],
            [-0.82689950141955, 51.780825184153279],
            [-0.823725461827658, 51.77979811917529],
            [-0.821630881855726, 51.781187010401247],
            [-0.819850772011046, 51.780796910726927],
            [-0.819632888382808, 51.781147189590783],
            [-0.81743986996167, 51.781026248594806],
            [-0.813634460967535, 51.780424150059659],
            [-0.81234790335307, 51.779956193335224],
            [-0.81285571963609, 51.780601514934396],
            [-0.808958455664379, 51.782445819057507],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000008",
        LAD13CDO: "12UB",
        LAD13NM: "Cambridge",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.155252062868588, 52.237216056969196],
            [0.166201719848352, 52.234241558269069],
            [0.167310210387395, 52.235771414428648],
            [0.169417958299954, 52.235235430524192],
            [0.170062663593621, 52.2343108945892],
            [0.17140982431812, 52.233826624873785],
            [0.171306729236068, 52.232944613799418],
            [0.169030547811506, 52.233537680340007],
            [0.166620615345833, 52.230122753602608],
            [0.164125375146122, 52.230756615172098],
            [0.163432990235105, 52.230069738704735],
            [0.160948917353479, 52.230994666605426],
            [0.157294696905892, 52.227480943021241],
            [0.154822476041477, 52.22647316044872],
            [0.156143511948872, 52.224937485108519],
            [0.156831618016005, 52.223304562653937],
            [0.160898786204296, 52.220796028899869],
            [0.163799606566247, 52.219089247592407],
            [0.164939553842066, 52.219258966007317],
            [0.16545137507757, 52.217892687701038],
            [0.17581467142776, 52.217228155347513],
            [0.174706327586549, 52.215697474333709],
            [0.175172030692936, 52.215551312341809],
            [0.173576896071017, 52.213253587136819],
            [0.172981368935263, 52.213378755035983],
            [0.17186110103215, 52.211239510843512],
            [0.170362883348693, 52.208821264831307],
            [0.17027283625053, 52.207003844609289],
            [0.170847387316118, 52.205999660167187],
            [0.168562506632959, 52.205033681471186],
            [0.167315715095791, 52.204134915548018],
            [0.171206889118216, 52.203797202608193],
            [0.172795017945441, 52.204371325530403],
            [0.173046476421575, 52.203945869060384],
            [0.175720411837034, 52.205445005257531],
            [0.178247741835998, 52.204063057272187],
            [0.177381047568195, 52.203249095944877],
            [0.179892689764596, 52.202237870819829],
            [0.17890483326352, 52.201400082559772],
            [0.180407935530264, 52.200655648093729],
            [0.179999273066958, 52.200259459886809],
            [0.181475991528162, 52.199545172434917],
            [0.180727251140089, 52.198898103740788],
            [0.182657738664196, 52.198098080357425],
            [0.181059249076921, 52.196533362966349],
            [0.183472235504878, 52.195757673530217],
            [0.18167322651563, 52.193114936808513],
            [0.184523428548747, 52.191472399125523],
            [0.182012891306675, 52.189952457009625],
            [0.180993033517759, 52.187858194405734],
            [0.183184969811399, 52.187835628912879],
            [0.183089585928214, 52.186904022434284],
            [0.18132057258212, 52.186868410807158],
            [0.181690522220061, 52.185251097667745],
            [0.181469994709964, 52.183106981981837],
            [0.181152821153975, 52.183052601099277],
            [0.181677998452548, 52.170590719986038],
            [0.181300373397353, 52.169478195764697],
            [0.176387442358676, 52.170931255058527],
            [0.171932634461899, 52.171227389379077],
            [0.163684835579403, 52.172321354925536],
            [0.162600976925135, 52.172468920745864],
            [0.159856483599374, 52.168435941667056],
            [0.154898485587516, 52.164610618387023],
            [0.141727566600768, 52.170992074315741],
            [0.132017829589042, 52.168226725845884],
            [0.130560163247793, 52.167804368713703],
            [0.130152551947493, 52.168057212440843],
            [0.126786150797758, 52.167043427928874],
            [0.126793669685003, 52.166741162258887],
            [0.120549341818498, 52.164981478422462],
            [0.103401054779948, 52.157929567087486],
            [0.101073054396754, 52.160712709761874],
            [0.104654017016264, 52.163414777229988],
            [0.105798911098524, 52.166171050530949],
            [0.107824362565844, 52.168310017777536],
            [0.106064057917917, 52.168286614528789],
            [0.106917311212562, 52.169880031785915],
            [0.099278906256454, 52.171302084170286],
            [0.100001720575883, 52.171775679350816],
            [0.099898458504693, 52.172750443772586],
            [0.101284651369189, 52.173800290024175],
            [0.101550091505457, 52.17483952264616],
            [0.101365000415038, 52.175846318610915],
            [0.100301188316399, 52.175959682096504],
            [0.099467557753934, 52.176674993967723],
            [0.099605414757517, 52.179322456768432],
            [0.098244452230995, 52.179992294146416],
            [0.098132721684292, 52.180788267126971],
            [0.099219807788025, 52.182191444782582],
            [0.100391044556475, 52.182585121378217],
            [0.099960363304428, 52.184931584388195],
            [0.101999014246588, 52.186500328117049],
            [0.102114650089564, 52.187401054659823],
            [0.102799634634893, 52.188120786744165],
            [0.10472102865086, 52.188998298699069],
            [0.10468608737438, 52.190643541464894],
            [0.103669559304569, 52.19117691000011],
            [0.104028667471331, 52.191500508134986],
            [0.100216644999253, 52.192418173119421],
            [0.099695148328935, 52.191815108614868],
            [0.096823234909524, 52.192067610647563],
            [0.09635618933329, 52.192838423703293],
            [0.097378892064166, 52.195075410434399],
            [0.097541984867462, 52.197296203997929],
            [0.096201728146859, 52.197547521255139],
            [0.094969985433484, 52.198737446748652],
            [0.094862765991526, 52.199320226068046],
            [0.094047425412889, 52.199314919373201],
            [0.09381072075789, 52.198980126990236],
            [0.09317201049109, 52.199307975390425],
            [0.09233133177704, 52.199168228286055],
            [0.092281894993883, 52.199486518236007],
            [0.090507612745258, 52.200220237567812],
            [0.084669202780874, 52.201481702481381],
            [0.085505247084465, 52.203241020290477],
            [0.078928417223477, 52.204339906302479],
            [0.079134366637368, 52.205553773242876],
            [0.079008397579618, 52.205802369712984],
            [0.077539962206086, 52.206043142038638],
            [0.077299554711481, 52.20785473557401],
            [0.073919057092998, 52.208368294962035],
            [0.074072499967383, 52.209682901503442],
            [0.073637312936335, 52.209861395801816],
            [0.074213791055275, 52.211521945124716],
            [0.069773399824603, 52.21216551003463],
            [0.071088867948149, 52.215113325243209],
            [0.06861180851022, 52.214920294340892],
            [0.068610791843709, 52.216095542911404],
            [0.074943142189338, 52.216775635470192],
            [0.075113352830186, 52.217098146220181],
            [0.078683726541836, 52.217721377037975],
            [0.081514834996844, 52.218218170665175],
            [0.085051916923304, 52.220033215516295],
            [0.085605327892355, 52.21978335298315],
            [0.087519473404846, 52.22092114068289],
            [0.088098922224496, 52.22057099638414],
            [0.089377183379192, 52.221509596517322],
            [0.089967372135711, 52.221076527898127],
            [0.092482046948475, 52.223118967494557],
            [0.093754424186499, 52.222559584068556],
            [0.095486509220696, 52.224332601384226],
            [0.10115107337812, 52.226960897748008],
            [0.103448994195185, 52.228086201408225],
            [0.107801364254662, 52.230568519394787],
            [0.111511692409689, 52.229633573265801],
            [0.111650247677461, 52.229066404992167],
            [0.113022024216763, 52.229059832909684],
            [0.117378098247401, 52.228252509361567],
            [0.118683281180844, 52.231307000047622],
            [0.124971785141142, 52.234287132730977],
            [0.126112772500901, 52.234351105006844],
            [0.127577059368006, 52.233825664964357],
            [0.129841459815588, 52.235636246126617],
            [0.136347702159383, 52.2334541026609],
            [0.147632971435645, 52.229871895129889],
            [0.151233076379246, 52.232853678767611],
            [0.155252062868588, 52.237216056969196],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000009",
        LAD13CDO: "12UC",
        LAD13NM: "East Cambridgeshire",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [0.245564435139604, 52.500540755542048],
              [0.140765777716422, 52.42180116854437],
              [0.123548883026369, 52.415210629796718],
              [0.115279698956007, 52.410867579594822],
              [0.109451893589621, 52.406537959829315],
              [0.099820528933492, 52.3985207943511],
              [0.093236363682894, 52.390875128303023],
              [0.051330030697303, 52.358167555583577],
              [0.050453308231074, 52.358992917864825],
              [0.049374871790548, 52.362854637997593],
              [0.048639337360826, 52.363972460549597],
              [0.049158881220566, 52.364471476089015],
              [0.047566848443078, 52.367714468138232],
              [0.046722204272819, 52.368349521419901],
              [0.045951555894632, 52.371794055076712],
              [0.043682565155109, 52.375595394316711],
              [0.041924614525771, 52.380672749768529],
              [0.038807270801708, 52.382758665466511],
              [0.036300510917701, 52.383621916156919],
              [0.034396226123616, 52.385733558948345],
              [0.046443872527013, 52.392227852878072],
              [0.054352221039996, 52.395386955318344],
              [0.054425231758338, 52.396547395482742],
              [0.056174659337079, 52.397818051429653],
              [0.055155487332375, 52.399261838495832],
              [0.055106391520746, 52.400134872903756],
              [0.052942687489704, 52.401594039066879],
              [0.052011398654998, 52.40503059472946],
              [0.050181738100945, 52.406630469252505],
              [0.050148545264445, 52.407341376401178],
              [0.052565189708528, 52.408820804204964],
              [0.047943408311769, 52.411249557960737],
              [0.045125673938225, 52.411969110216269],
              [0.041663140056778, 52.413342626679601],
              [0.043326280877729, 52.41639797971078],
              [0.043095422833337, 52.417416212698313],
              [0.043623012642501, 52.418108431060929],
              [0.045357937273977, 52.419085475177589],
              [0.046512551133767, 52.418739999316223],
              [0.049538455730386, 52.416729222582411],
              [0.049077202821489, 52.41604217414946],
              [0.050033511579649, 52.415101264649721],
              [0.052510693527334, 52.41455025688515],
              [0.055252856604754, 52.414418091099996],
              [0.058749284540806, 52.415420946914132],
              [0.062657112997644, 52.417988243790965],
              [0.070149063522348, 52.420579022203945],
              [0.070187878525997, 52.421470303952518],
              [0.072850755921641, 52.421081962563754],
              [0.073287131038378, 52.421417787331116],
              [0.074826203554597, 52.421433044888353],
              [0.077572207715642, 52.420749113795893],
              [0.080099714820266, 52.421392521038193],
              [0.082867123550018, 52.423597978325482],
              [0.081084636353942, 52.424786584228684],
              [0.081645489077025, 52.426575920457957],
              [0.082362393784739, 52.427212485284116],
              [0.084875913047737, 52.428079929087069],
              [0.086330921156573, 52.429175508180855],
              [0.087702753108911, 52.429754624627655],
              [0.106615608006523, 52.42532076025396],
              [0.110612120698956, 52.427665517984721],
              [0.111779570521307, 52.427871257661849],
              [0.113846056923997, 52.427345182410441],
              [0.113881635957013, 52.428150193792767],
              [0.115086450649477, 52.429873015325114],
              [0.116008583807266, 52.429838530788835],
              [0.117041606362291, 52.431565306727656],
              [0.11695218716849, 52.433045127110844],
              [0.120091065982113, 52.43410833499469],
              [0.120814104143461, 52.435471082207677],
              [0.122045343697333, 52.435284442973092],
              [0.126447959005554, 52.437838082257919],
              [0.129120337872227, 52.436629170255863],
              [0.12981613411612, 52.435712081481718],
              [0.120814764929027, 52.431103847665767],
              [0.118662994860674, 52.423443812442592],
              [0.123817503446783, 52.420953253547637],
              [0.137758878199162, 52.431421789269024],
              [0.151019559309119, 52.442247276389487],
              [0.155321496822377, 52.440157501540902],
              [0.166379461289997, 52.448719569888311],
              [0.179294972317989, 52.458742617554719],
              [0.177063968227312, 52.460960592603243],
              [0.175915215088203, 52.460501581630275],
              [0.171574748162175, 52.457133072055463],
              [0.170693970375189, 52.457540380503744],
              [0.200705207158038, 52.480851369173479],
              [0.201610464429428, 52.480458670013491],
              [0.2362982233601, 52.507273033933984],
              [0.238769983133388, 52.509167588785871],
              [0.245564435139604, 52.500540755542048],
            ],
          ],
          [
            [
              [0.309340124573062, 52.513604450219972],
              [0.315540575329731, 52.512679384320506],
              [0.317272432256983, 52.512989860104113],
              [0.318236803769266, 52.51187936041314],
              [0.318373248354135, 52.509572084629966],
              [0.317722748750854, 52.508720717692512],
              [0.318284791000041, 52.507639678412154],
              [0.323308887913418, 52.507703865023331],
              [0.328744959236974, 52.509065343193662],
              [0.330917020134704, 52.509117878511546],
              [0.33234995392749, 52.508542931582369],
              [0.337913842512371, 52.505864130313668],
              [0.341678416109735, 52.502889793208219],
              [0.345012689932138, 52.501667385993322],
              [0.347779511789148, 52.502131333549627],
              [0.34973713643732, 52.501070086024498],
              [0.352793562063228, 52.501466119125148],
              [0.355200581187618, 52.500738431081601],
              [0.359386601630487, 52.500821359013401],
              [0.361069137463142, 52.499197119584757],
              [0.362072088000953, 52.498914531924655],
              [0.363968678728477, 52.499443132653987],
              [0.364972534970549, 52.50085187952304],
              [0.367041353545088, 52.501155787276929],
              [0.367122778223196, 52.495925361878193],
              [0.371714262415514, 52.490985837312799],
              [0.373068032679441, 52.48775755502156],
              [0.375998998728888, 52.486030704704419],
              [0.377102356330586, 52.484257801689807],
              [0.379138384554739, 52.48238430148065],
              [0.380684889990837, 52.479521613260303],
              [0.384254981118213, 52.477673777567894],
              [0.391894142761401, 52.475510706230637],
              [0.39441470114463, 52.472219884189343],
              [0.396281396661219, 52.470884527093851],
              [0.397947635639772, 52.468734932538929],
              [0.40037698086875, 52.467272998833103],
              [0.402067422229327, 52.465330566446625],
              [0.404104291761999, 52.46453384152467],
              [0.405886298431344, 52.463384384393429],
              [0.416471860792724, 52.459443120123538],
              [0.423908398171085, 52.4563828689734],
              [0.426274702566808, 52.45506015756137],
              [0.428506273174045, 52.454634875934907],
              [0.428357317646392, 52.454281849488247],
              [0.423332006391519, 52.453687174827451],
              [0.423447941232544, 52.451027643488437],
              [0.423754906001086, 52.444905843813856],
              [0.425214238592836, 52.444361568574983],
              [0.425787003389519, 52.443724867683038],
              [0.424532800371008, 52.442698523633609],
              [0.427744644661342, 52.437204983931579],
              [0.428344428150226, 52.436580302946034],
              [0.42932078440588, 52.436409174829485],
              [0.42797657798445, 52.435054707152602],
              [0.425314081276867, 52.434125629582404],
              [0.423184314901331, 52.433948102298594],
              [0.425289889548333, 52.430309881778228],
              [0.417630278375853, 52.428112665108742],
              [0.374754090507972, 52.409711934784667],
              [0.382061736637633, 52.396256626410043],
              [0.384581906659362, 52.392234898422259],
              [0.389082102774042, 52.387357510350604],
              [0.400189018933364, 52.372933861548667],
              [0.407302831637663, 52.36307580820592],
              [0.407666368060599, 52.36149567214585],
              [0.409931306173671, 52.360174399223915],
              [0.415571467420076, 52.359773247520764],
              [0.419199318426598, 52.357919454179672],
              [0.418735707491841, 52.356691624679783],
              [0.420506681793215, 52.35582806750233],
              [0.419542547993497, 52.35363754225839],
              [0.419532751827068, 52.35254429351275],
              [0.420027491731732, 52.352138502104332],
              [0.42078240275425, 52.352006134441915],
              [0.422616003336309, 52.352345313804271],
              [0.425105472076836, 52.352083810745434],
              [0.427495959767896, 52.3536874722951],
              [0.429503691966087, 52.352835097331464],
              [0.431343883823524, 52.352730690724556],
              [0.432946567514269, 52.352205816363146],
              [0.435418823320529, 52.352166557327088],
              [0.437274570785786, 52.350584316254249],
              [0.440828443109704, 52.349096452667148],
              [0.442475152390821, 52.348815125363302],
              [0.442481921183897, 52.346914028392831],
              [0.44154733868954, 52.345888454591417],
              [0.441557156031191, 52.344734548586786],
              [0.440832718983568, 52.344244158782644],
              [0.440997995703758, 52.343433240729667],
              [0.4403533320973, 52.342071650605369],
              [0.438309009170518, 52.340539334411183],
              [0.438451665517919, 52.34001034362133],
              [0.437457047158248, 52.338636180194598],
              [0.437747258112999, 52.337667122219742],
              [0.436920488301095, 52.337101488276083],
              [0.437615830245316, 52.33527790072511],
              [0.437304127724202, 52.3351620396435],
              [0.436173630979082, 52.335879565766163],
              [0.435759124768616, 52.335736146862637],
              [0.436507329023245, 52.334953675419001],
              [0.436102579634563, 52.334587049073889],
              [0.436297728646963, 52.333517443565029],
              [0.435035624672747, 52.334029953523299],
              [0.43178813779563, 52.332549306138404],
              [0.430224956203547, 52.331345952333862],
              [0.424195296197234, 52.329200244494892],
              [0.429063583187122, 52.32389370550171],
              [0.429602088963381, 52.320035747315266],
              [0.430755211672812, 52.317112915745874],
              [0.431349911735962, 52.315809384909414],
              [0.438803898731905, 52.316799478499988],
              [0.441107814797994, 52.316456924147928],
              [0.44192403646747, 52.316810528285359],
              [0.441599159526173, 52.31719941777947],
              [0.442778607093674, 52.317140852848333],
              [0.445626916815507, 52.317650204616143],
              [0.44659605575217, 52.31760944569622],
              [0.447962439572381, 52.316896813949839],
              [0.449467728251442, 52.316654278366592],
              [0.450956024479608, 52.316609007119347],
              [0.452448359272221, 52.317193992282881],
              [0.452998749363279, 52.31658637101112],
              [0.453589478286985, 52.316814191397796],
              [0.454056214779485, 52.31647177727168],
              [0.4574732010853, 52.316279314482586],
              [0.458965840714286, 52.315465909523134],
              [0.4589861516222, 52.314297387107253],
              [0.459676220570802, 52.312903594979474],
              [0.460433974906992, 52.312519122634953],
              [0.46047079045496, 52.311648799709786],
              [0.461379877249935, 52.310977909624391],
              [0.462065815217372, 52.308798260055951],
              [0.464526769748594, 52.307807219130929],
              [0.466263500979521, 52.307908536159026],
              [0.467249137108605, 52.307693709181642],
              [0.467880750977581, 52.307180535444282],
              [0.46829030958584, 52.307369804978499],
              [0.469329385974616, 52.3070612222348],
              [0.471084799943667, 52.305496698110908],
              [0.471170293014867, 52.30484566245066],
              [0.470328266414273, 52.304819221476535],
              [0.47127821226752, 52.304202250323641],
              [0.471441487103192, 52.304420943133806],
              [0.472577727552779, 52.304409741583122],
              [0.47232707648435, 52.304123639101682],
              [0.472845601392677, 52.303925739003773],
              [0.473815807254353, 52.304225540854794],
              [0.473514820852414, 52.303852370635227],
              [0.473902460349178, 52.303596060042892],
              [0.474083001602707, 52.303966357990582],
              [0.476480946963298, 52.303143640456653],
              [0.476172369604109, 52.30276613989502],
              [0.476650940304251, 52.302352345272887],
              [0.477363517151095, 52.302425519689947],
              [0.476992734563464, 52.302195002490755],
              [0.47729995814041, 52.3019439644941],
              [0.479116891137887, 52.301830289084144],
              [0.479632349974447, 52.301289814479688],
              [0.479345577759439, 52.30106653242936],
              [0.480074299760754, 52.301008962195894],
              [0.479979486272016, 52.300546049389503],
              [0.48093843354399, 52.300435078565322],
              [0.480871129772925, 52.300018348496579],
              [0.481505591556791, 52.299718157970517],
              [0.482473886613451, 52.299696002308806],
              [0.48393769727849, 52.298228233302787],
              [0.485404164258758, 52.297516646957881],
              [0.485201853015033, 52.297073086335629],
              [0.485759355836933, 52.296787980831503],
              [0.485321699865099, 52.296332290144747],
              [0.485497892609197, 52.295262086017502],
              [0.48836898761319, 52.296063071161811],
              [0.496316852429716, 52.291162579776469],
              [0.501321520241834, 52.289280640964314],
              [0.504672658451973, 52.286349997292632],
              [0.504740823701116, 52.284699342455212],
              [0.501183238336584, 52.284848517214122],
              [0.49907666025121, 52.284596386281912],
              [0.498841024921189, 52.283770476708085],
              [0.500002240130356, 52.283352920137929],
              [0.500136624411263, 52.282823118760888],
              [0.497689058239422, 52.281876778275098],
              [0.498534235795663, 52.2817320980365],
              [0.497752623157144, 52.281495054462916],
              [0.498052722821174, 52.281304359350656],
              [0.497343424306089, 52.280710582671752],
              [0.496678182989008, 52.280978241554237],
              [0.496302384787105, 52.280787457709764],
              [0.496642125619995, 52.280545570342497],
              [0.495817772838612, 52.28035617869066],
              [0.496796187590706, 52.280234771305992],
              [0.496657618135457, 52.278682915400239],
              [0.498185002518736, 52.278182992567501],
              [0.496843934009511, 52.2776943055853],
              [0.496855389865837, 52.276933305443272],
              [0.496400907526777, 52.277115573052292],
              [0.495983589241982, 52.276863618921496],
              [0.495051941147029, 52.275780847574509],
              [0.497105008767446, 52.275741026464566],
              [0.496189155977129, 52.275278406067727],
              [0.498057219270769, 52.274243423445128],
              [0.498144647517502, 52.273686740964159],
              [0.49833914572243, 52.274095375973857],
              [0.498650758094461, 52.27395389329309],
              [0.498774763609686, 52.273005265742491],
              [0.497720523373848, 52.272896291402937],
              [0.497631167590078, 52.273236297179267],
              [0.495773302970884, 52.27327829557747],
              [0.495161871493768, 52.272336229070604],
              [0.494182793993891, 52.2719729475306],
              [0.459517515663569, 52.265630623353111],
              [0.453772857658745, 52.26367836223698],
              [0.432615915334266, 52.258083394826841],
              [0.425258777672222, 52.256240321585793],
              [0.424841130109638, 52.256400873921315],
              [0.424178394330476, 52.255856066802473],
              [0.416261930249154, 52.262604375431373],
              [0.409519627536164, 52.269019727163077],
              [0.403686805502383, 52.274479161436957],
              [0.394636234813608, 52.278883179830565],
              [0.39532711387939, 52.280439206374211],
              [0.393454530913911, 52.280840476653964],
              [0.393411479273299, 52.281156976858604],
              [0.385335851981046, 52.28298220240908],
              [0.385917280606839, 52.285862347887708],
              [0.385509667915622, 52.289628429656183],
              [0.38627507378729, 52.289954664242082],
              [0.382696491489025, 52.293250649908032],
              [0.380736372038452, 52.292988955802244],
              [0.380591095553144, 52.293534114665725],
              [0.378700768323554, 52.293507473974202],
              [0.378406815025003, 52.291798585645076],
              [0.377842892133829, 52.291236243666162],
              [0.376233205406912, 52.293546376101062],
              [0.37095202496511, 52.2948332297684],
              [0.368842851132275, 52.296504950655525],
              [0.36071023961958, 52.29779816250921],
              [0.35874416717606, 52.297342890314532],
              [0.360607837658934, 52.293556810742182],
              [0.358626586005198, 52.290218055708053],
              [0.358637074823655, 52.289587495591093],
              [0.359195560919047, 52.289142014537191],
              [0.353051120732617, 52.285069805330664],
              [0.350623408144971, 52.282944767374062],
              [0.352628777804952, 52.282301444010301],
              [0.355431953116323, 52.279839239775079],
              [0.355198430216817, 52.279306167521533],
              [0.349040817940691, 52.273676577184126],
              [0.342387997849396, 52.269938561613174],
              [0.344029266145977, 52.269792677644013],
              [0.339974755915008, 52.267672745685651],
              [0.344724536379019, 52.264553518992955],
              [0.342747018639006, 52.263460673614134],
              [0.35073676626461, 52.258329131922231],
              [0.35807063846345, 52.252496156154358],
              [0.360279274411961, 52.250217438654971],
              [0.360864774671598, 52.24940721801805],
              [0.355999028998169, 52.246804099113781],
              [0.356636005126853, 52.246273428714808],
              [0.35138221857583, 52.244686797513396],
              [0.346284685809836, 52.24361118205794],
              [0.342655969698815, 52.242328152082088],
              [0.357394866492098, 52.23414304894348],
              [0.36891306245459, 52.227777847892725],
              [0.369615797960413, 52.228187271712187],
              [0.370087156982449, 52.227799229957924],
              [0.36941656965077, 52.227470992894155],
              [0.371220872261898, 52.226540913709186],
              [0.380449592341318, 52.231641634269508],
              [0.384620453816975, 52.23399072296948],
              [0.394932094686643, 52.230664244010057],
              [0.402540933305959, 52.235399568604969],
              [0.408226699567808, 52.238809543298565],
              [0.41147965083903, 52.240101845698725],
              [0.415243984582695, 52.238103195705598],
              [0.421307114996285, 52.239675784924565],
              [0.421353589045471, 52.239964384230305],
              [0.420724804934421, 52.240172419131241],
              [0.421112062948394, 52.240236412397913],
              [0.421203363853311, 52.240890978892658],
              [0.420648524298, 52.241946371439852],
              [0.420903133328106, 52.242694704834406],
              [0.417332960041323, 52.246246125461191],
              [0.414489854590368, 52.247845614865618],
              [0.42693675933712, 52.253587262594074],
              [0.429731881325977, 52.251486689773294],
              [0.432244972205435, 52.25154642430865],
              [0.434167366881287, 52.249488006766448],
              [0.443376125320535, 52.242621765775496],
              [0.446234756994232, 52.242926728046342],
              [0.446027538414791, 52.243580272544357],
              [0.449248369815583, 52.243651045920039],
              [0.450393248719866, 52.243319737308717],
              [0.453308313434914, 52.247539536030494],
              [0.455532938974773, 52.247578691699424],
              [0.459603458483931, 52.24380344188917],
              [0.465083252859316, 52.240360104495288],
              [0.470754568154296, 52.240959078833534],
              [0.471828300229613, 52.240326904748599],
              [0.477892257518409, 52.234511106099482],
              [0.476585758849442, 52.234378472110784],
              [0.476099393567922, 52.233929167101934],
              [0.480308522727138, 52.232666345202233],
              [0.482974765084298, 52.232181324777812],
              [0.484066677266436, 52.232825579569031],
              [0.485671116184478, 52.23404443589672],
              [0.484134973018107, 52.23478269538748],
              [0.486087234027551, 52.236484310764865],
              [0.489298776339812, 52.238516333588194],
              [0.48977645378756, 52.238362375660941],
              [0.48935868876458, 52.23809512239599],
              [0.489992592157368, 52.237696869790625],
              [0.491725517599951, 52.238876065131223],
              [0.492723708005096, 52.237973720883829],
              [0.494902632808973, 52.23924236390156],
              [0.500861682527794, 52.235523719072212],
              [0.507330929584856, 52.229987330905743],
              [0.513199956662067, 52.228084637855396],
              [0.514454993157386, 52.226763874885442],
              [0.513029011239671, 52.225162119853131],
              [0.512537705206241, 52.215035331222964],
              [0.512791700498259, 52.213673849808529],
              [0.508621295547958, 52.213965843662599],
              [0.508643765328648, 52.21231254265394],
              [0.507345232273146, 52.212337453418016],
              [0.506669996827447, 52.208509305053468],
              [0.503101105614655, 52.207021295951463],
              [0.504044207529703, 52.204192917766576],
              [0.502861829332824, 52.203988703930889],
              [0.502104107360053, 52.203032689549261],
              [0.501672174944704, 52.199017702192819],
              [0.500662582614716, 52.197457328329541],
              [0.501320524252316, 52.197290504082737],
              [0.50252624541075, 52.196012269608929],
              [0.499742508891403, 52.194544372897916],
              [0.500624098878782, 52.193279321412533],
              [0.499131483841369, 52.192130241349986],
              [0.496800860836459, 52.19130013607203],
              [0.496791978588415, 52.189810264064377],
              [0.49563708433222, 52.189514574562004],
              [0.495760553105604, 52.18884561630243],
              [0.494945820379154, 52.188718974573824],
              [0.494593612679778, 52.186583508281913],
              [0.495026169439913, 52.185503348760903],
              [0.493817819107177, 52.184564009610384],
              [0.494546019608837, 52.184139448922274],
              [0.493934964847741, 52.181481593769412],
              [0.493267642257263, 52.1813725067775],
              [0.493134121708093, 52.181051598820659],
              [0.494141607123545, 52.178168873319606],
              [0.493001805510189, 52.176175951075571],
              [0.493191721982875, 52.175570335710304],
              [0.491054159988297, 52.174277422162675],
              [0.491226698978714, 52.171973488226392],
              [0.488720313315007, 52.171588477141135],
              [0.487434902497957, 52.169782922585263],
              [0.488732826178013, 52.169140446822915],
              [0.489758976821065, 52.168031587194385],
              [0.492637017382123, 52.167403353061204],
              [0.491302621661202, 52.165088098651026],
              [0.489700507034527, 52.16564890089461],
              [0.488301895616751, 52.164668562598003],
              [0.48742035396913, 52.164489326955675],
              [0.485899870659397, 52.165150870076985],
              [0.482405534752003, 52.165661525103509],
              [0.481685789150879, 52.166287267938955],
              [0.479233720061475, 52.165951272981218],
              [0.47861552920011, 52.16758921704433],
              [0.477211266605628, 52.167556678539746],
              [0.474425358481063, 52.165843649117349],
              [0.472426710975955, 52.163978263234512],
              [0.4707000415099, 52.163244697787633],
              [0.470160259176962, 52.164930411597695],
              [0.468231378545302, 52.167834915059537],
              [0.462787449078379, 52.170997195329321],
              [0.460980171926845, 52.171401820331248],
              [0.457233504406902, 52.171195791288532],
              [0.454643327413464, 52.172523071009138],
              [0.452525051987349, 52.173205610807074],
              [0.446567225383526, 52.171753891686024],
              [0.444717112906745, 52.17092718832594],
              [0.444492274324408, 52.171067635500386],
              [0.443273647811299, 52.168579500714806],
              [0.443506775821852, 52.166509102837388],
              [0.444275990459105, 52.164668590144146],
              [0.441064969348289, 52.164966214892779],
              [0.439360212407221, 52.165746968783225],
              [0.438676042815217, 52.165697274100921],
              [0.438253672626301, 52.165220416333064],
              [0.437041835973792, 52.164131304151525],
              [0.438249026360156, 52.163022757171568],
              [0.438156014118607, 52.160706425702983],
              [0.435885577627335, 52.159480920100165],
              [0.430610293949907, 52.159482774315407],
              [0.427089605588816, 52.160371811567124],
              [0.42613286279554, 52.159905909890647],
              [0.421807922137666, 52.160365309307231],
              [0.417246325579715, 52.159292596011959],
              [0.41255162385748, 52.157106472222786],
              [0.409826767506401, 52.156410429117919],
              [0.409140860832056, 52.156677132746424],
              [0.406806145770854, 52.159118592646209],
              [0.405416893515983, 52.159574980579528],
              [0.402281659135675, 52.159404197012314],
              [0.399154674543511, 52.15827187535664],
              [0.398574708350042, 52.158878971196607],
              [0.397122179633297, 52.158268251803769],
              [0.394884495849784, 52.157955825864725],
              [0.39393164320924, 52.159434639845585],
              [0.391566181098047, 52.159112155501809],
              [0.388880264273772, 52.161786981035725],
              [0.387103325164477, 52.161971341630803],
              [0.383641851724538, 52.161634911162473],
              [0.376311480636209, 52.163218945408538],
              [0.376370203752602, 52.163799567130901],
              [0.37741629904049, 52.163782976808278],
              [0.377383276046413, 52.164397820287952],
              [0.376761895998068, 52.164564115737505],
              [0.376177334449582, 52.164131674652531],
              [0.376070307753765, 52.164510610792725],
              [0.37740489629933, 52.166586122586516],
              [0.372686185062624, 52.166756698452332],
              [0.370927526787805, 52.16632716748763],
              [0.369696354872094, 52.16743009643298],
              [0.36899384566973, 52.167309326635866],
              [0.364048375822804, 52.167949014229286],
              [0.361634185302983, 52.167290591437414],
              [0.351656947414051, 52.168686860158232],
              [0.346768505200638, 52.171250831631376],
              [0.341930302256268, 52.17235954486501],
              [0.339843947970787, 52.173264218160746],
              [0.338422034963826, 52.174387681272734],
              [0.3342478311188, 52.175436146435281],
              [0.334415358820002, 52.175730474527086],
              [0.330630694725055, 52.177365486785874],
              [0.324931516572541, 52.178897853019649],
              [0.321910136984719, 52.178826089275383],
              [0.321212668753523, 52.17975792690649],
              [0.318082960948657, 52.181822953699232],
              [0.310350185027801, 52.185712751988461],
              [0.310962107127694, 52.190459444620963],
              [0.304143492897268, 52.193618718395165],
              [0.296692915761, 52.196573183513792],
              [0.287511196577894, 52.201172147771658],
              [0.280597737163597, 52.202628755105593],
              [0.27798710350175, 52.203549637040091],
              [0.278130266372082, 52.204059424638004],
              [0.273402483537905, 52.205483266012443],
              [0.268961097060876, 52.208081174014595],
              [0.260774600449615, 52.211970966922237],
              [0.257291498601483, 52.213112218415624],
              [0.254378884845627, 52.214508631708611],
              [0.249247785027382, 52.218619733678779],
              [0.249662504270776, 52.219235877134238],
              [0.247855408500854, 52.22122699596629],
              [0.244544227693796, 52.222749446322396],
              [0.242530784877844, 52.225476358753184],
              [0.239750680380966, 52.226010239423523],
              [0.234977306292079, 52.228811854353154],
              [0.232828041797241, 52.231475522788941],
              [0.230867435315048, 52.230488413172793],
              [0.231355669460785, 52.229682486941243],
              [0.230409065246333, 52.228910882818461],
              [0.225265890873922, 52.231685815494686],
              [0.227659965415656, 52.234340949234323],
              [0.22844444025151, 52.235821511346678],
              [0.229469140478514, 52.236570974202905],
              [0.226224271671112, 52.239716498347711],
              [0.222002070887066, 52.2416924899726],
              [0.222157909616201, 52.242876500741446],
              [0.220770698938965, 52.243374694130573],
              [0.219010298546501, 52.247080141394719],
              [0.217731291351457, 52.248135564995479],
              [0.218120287724392, 52.249208192994807],
              [0.215249162119722, 52.249655969146474],
              [0.210440690377988, 52.249445795289525],
              [0.218735054195363, 52.259764920048674],
              [0.218484699058367, 52.260415250146373],
              [0.21933052470877, 52.262592492829178],
              [0.220589142515864, 52.263526466498618],
              [0.217897294563534, 52.265058955271783],
              [0.216969855112273, 52.266062797468031],
              [0.216040004550151, 52.2660200121299],
              [0.216017327844422, 52.266564451931366],
              [0.214573834089847, 52.267125665833596],
              [0.211552257271933, 52.269904182396147],
              [0.213945029327383, 52.271448207610348],
              [0.215150016896131, 52.27391997057893],
              [0.21647121019802, 52.274281824825444],
              [0.219628121501427, 52.274329511398491],
              [0.220927564016645, 52.274900338632072],
              [0.222623297816757, 52.276359290663123],
              [0.224164971691135, 52.278428079243533],
              [0.22644942917151, 52.279811141745604],
              [0.228923717032083, 52.282023233020567],
              [0.229736540861209, 52.283319811623599],
              [0.229795353772517, 52.28568808366137],
              [0.230286167542338, 52.286439532581454],
              [0.236447710079187, 52.289792972480427],
              [0.240441389155306, 52.290417726792114],
              [0.241657951533828, 52.29113287511391],
              [0.241933699760313, 52.292614911711063],
              [0.241085156220882, 52.294542714819087],
              [0.241214260212991, 52.295531179153478],
              [0.243108195531538, 52.297342162903782],
              [0.244030540510232, 52.299601402889579],
              [0.246009806463004, 52.301150851533066],
              [0.247076486786319, 52.3038515257618],
              [0.245911724156504, 52.304052626494233],
              [0.244751779932257, 52.305740894516838],
              [0.246166570741489, 52.307029513825661],
              [0.247230888057907, 52.309188015292094],
              [0.247298876168496, 52.310530120114585],
              [0.246874736619282, 52.31113525735401],
              [0.248005810547426, 52.31159749741807],
              [0.250248600822511, 52.319195245057237],
              [0.248998243499097, 52.319076092003094],
              [0.248533812942402, 52.320045276032239],
              [0.247727999504102, 52.32047514496977],
              [0.247927138373611, 52.321393028973802],
              [0.248479458463224, 52.321693635629309],
              [0.24798411481406, 52.322806377300303],
              [0.248791394116766, 52.322838665133929],
              [0.244430171269715, 52.324522195765994],
              [0.243752779249979, 52.325346139966271],
              [0.239289752464028, 52.325574718794741],
              [0.239277226580938, 52.324687453089474],
              [0.238440722932958, 52.323698912854617],
              [0.239849509916893, 52.321955642478763],
              [0.238925558465542, 52.320309655490064],
              [0.20585971813016, 52.316831609936095],
              [0.203253520965033, 52.316883859359478],
              [0.198579003787299, 52.318397090530645],
              [0.199984499383029, 52.323391977361545],
              [0.19726349692296, 52.324274380756989],
              [0.195871305479973, 52.324165396293481],
              [0.192688374929607, 52.323098753591488],
              [0.189459043778475, 52.323080437270001],
              [0.181575162984504, 52.321187882411849],
              [0.177667776461365, 52.320984846674499],
              [0.17533165693396, 52.319817599162789],
              [0.170764396510343, 52.319260521368626],
              [0.169896909682234, 52.318774719552756],
              [0.169205938740959, 52.317787521970487],
              [0.167543507039932, 52.317547392186782],
              [0.166185765563345, 52.317927481715046],
              [0.164401756394685, 52.320307056867577],
              [0.16230048948005, 52.321510887527609],
              [0.162129600199343, 52.32224954660439],
              [0.16289457469244, 52.323462022750114],
              [0.162550523743776, 52.324170583636274],
              [0.151883190980064, 52.324621694146693],
              [0.149025112223803, 52.325512668437256],
              [0.147325991847982, 52.324124669040621],
              [0.143151195825346, 52.324068320851012],
              [0.142141961818428, 52.322648844454385],
              [0.142827884569914, 52.321234581891339],
              [0.140290658645088, 52.320654762114124],
              [0.138426830838872, 52.321105743479109],
              [0.134368300759233, 52.322879504720788],
              [0.130552774402949, 52.325105521730087],
              [0.128255074829322, 52.32538972589888],
              [0.125891479947413, 52.326656970817666],
              [0.123026164441817, 52.326919816668074],
              [0.120007406884223, 52.32811779087006],
              [0.11540760350371, 52.328357620686681],
              [0.110261472209324, 52.328189817066082],
              [0.107952572301293, 52.32929386916355],
              [0.104771128713957, 52.328746362315812],
              [0.102595300402624, 52.330276841729031],
              [0.099718127093029, 52.331071639243746],
              [0.096480165277887, 52.331284712686617],
              [0.093529352938583, 52.334128973805832],
              [0.09213678166736, 52.334323590675297],
              [0.091016058428671, 52.334898223310887],
              [0.089468568657011, 52.334827598439979],
              [0.089027531230722, 52.335249017065706],
              [0.087937561895221, 52.335231423047908],
              [0.086854020242584, 52.335630919607539],
              [0.084502893034106, 52.334640189073752],
              [0.082184660530502, 52.334847423402692],
              [0.076564087392314, 52.337035132889774],
              [0.073847464092127, 52.338425314774902],
              [0.065237780917015, 52.341350979947833],
              [0.059270652103872, 52.344172425665029],
              [0.056878682022534, 52.346251601746047],
              [0.049569306931678, 52.349474455888689],
              [0.045808339590942, 52.351813622478026],
              [0.045995774646753, 52.352056746513142],
              [0.044150253606433, 52.352762164486222],
              [0.046725076767479, 52.354969981729155],
              [0.048250406753205, 52.355754589509097],
              [0.093466215278703, 52.390763157429475],
              [0.098760660662551, 52.397188179607149],
              [0.109870709070229, 52.406564652651511],
              [0.115508979623168, 52.410804131506183],
              [0.1233573825919, 52.414866992896386],
              [0.141138102012645, 52.42177554283019],
              [0.245685457894589, 52.500342438822777],
              [0.246019604530783, 52.499873920931506],
              [0.256455392333792, 52.507696830544823],
              [0.257283662552827, 52.507065995637547],
              [0.268481895351107, 52.508959453132135],
              [0.278613781233241, 52.508539041283676],
              [0.289510581618902, 52.510703377495965],
              [0.309340124573062, 52.513604450219972],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000010",
        LAD13CDO: "12UD",
        LAD13NM: "Fenland",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [0.157157336820255, 52.680663537148973],
              [0.157129243652419, 52.679673203366889],
              [0.159620205928316, 52.680446045008821],
              [0.159745283879691, 52.679622856159632],
              [0.161797994183435, 52.6793993058035],
              [0.161904080631024, 52.679630246235533],
              [0.162385374796803, 52.679460519570299],
              [0.166372507978185, 52.680540386836519],
              [0.169855446909892, 52.678637930379466],
              [0.171915463604627, 52.678891511325347],
              [0.174355875019985, 52.678761339181335],
              [0.17461813371765, 52.678382485889827],
              [0.178362399909497, 52.678382947692853],
              [0.179800495503612, 52.677613814909492],
              [0.180399948392306, 52.678065842086191],
              [0.184701239104533, 52.677875125738595],
              [0.184487534170263, 52.676319959816119],
              [0.183562765075155, 52.674803074495721],
              [0.183351594337336, 52.674009429891299],
              [0.182918450042942, 52.674014712782224],
              [0.182658392795157, 52.673486314274776],
              [0.180820785344128, 52.673239617986916],
              [0.181063280320894, 52.672079761977599],
              [0.184414136540802, 52.67212143639015],
              [0.184395806290217, 52.666980496203692],
              [0.183616737422359, 52.663843372123807],
              [0.183974099644202, 52.663210084848693],
              [0.183565234756567, 52.662899325267553],
              [0.18412225843817, 52.660072051779252],
              [0.183047531905023, 52.659069536893426],
              [0.18382603614781, 52.655642950487639],
              [0.176263055680846, 52.655672423944971],
              [0.17832458313309, 52.652617022296027],
              [0.169579391157931, 52.652737038206084],
              [0.169909500230155, 52.650416607776634],
              [0.172043760639577, 52.648675424346507],
              [0.17395066344711, 52.647182040318128],
              [0.176171921720302, 52.64437100293393],
              [0.179167614671942, 52.642455607984708],
              [0.18048104909898, 52.641967481090646],
              [0.183101602343318, 52.641637782811912],
              [0.185657949404254, 52.640495486333457],
              [0.188076517184407, 52.638461030487797],
              [0.189702153211375, 52.635705683795535],
              [0.192011613370718, 52.634246814216304],
              [0.196558510328176, 52.632171871839581],
              [0.203166069897026, 52.630207696329556],
              [0.215192276394834, 52.624452713119382],
              [0.219283508635518, 52.62179375907165],
              [0.2170453799718, 52.621564134653404],
              [0.210848000583005, 52.617909852272483],
              [0.212522384175021, 52.616778921332028],
              [0.216920139435511, 52.614964012721202],
              [0.217369749860378, 52.614506918398568],
              [0.209295706883221, 52.613516800217631],
              [0.205596956567878, 52.611784748946349],
              [0.204972905987896, 52.610947572619736],
              [0.204861130153885, 52.609163941466896],
              [0.204156156432995, 52.608037842062927],
              [0.203416021818246, 52.60762272160688],
              [0.202624125858148, 52.605587391326551],
              [0.201370342397789, 52.604588372458629],
              [0.20150185778914, 52.603117614648177],
              [0.198317354626391, 52.602754177526705],
              [0.197050507294379, 52.602198637364857],
              [0.195088828502919, 52.602247607238681],
              [0.194612299677979, 52.601607254081088],
              [0.191582467397395, 52.601498825961386],
              [0.192542174478792, 52.599366259660719],
              [0.191653765809252, 52.599140835693063],
              [0.191609285365222, 52.598871014969298],
              [0.195142864910252, 52.595147814839301],
              [0.193909923206238, 52.595383765392171],
              [0.193835182827392, 52.592085267468988],
              [0.199130372328695, 52.591773934542559],
              [0.197183105275663, 52.589062279787022],
              [0.198136930500372, 52.588769434359918],
              [0.198069986397401, 52.58607501920271],
              [0.199526844257416, 52.582739979240934],
              [0.202593586525264, 52.580077215460697],
              [0.215762591244565, 52.579427746949243],
              [0.214586235822132, 52.577905908127043],
              [0.214612747707727, 52.575503766160999],
              [0.215260100717509, 52.574702194483741],
              [0.218018669340903, 52.573389921893863],
              [0.218468161616599, 52.572208098706142],
              [0.218308521729058, 52.570945975983733],
              [0.216105996964477, 52.569468553997325],
              [0.213773794097317, 52.568548297070585],
              [0.206805381189388, 52.567571553561294],
              [0.205613489380576, 52.565550885747228],
              [0.206717709074923, 52.563591723819997],
              [0.209416168008232, 52.562853530054902],
              [0.212290562254667, 52.561127408047561],
              [0.214846341010764, 52.56081256103684],
              [0.216507328424924, 52.561040425929455],
              [0.217220118276691, 52.56057569815988],
              [0.21695399848973, 52.558750894981451],
              [0.214078983976733, 52.556180966156205],
              [0.211581920318035, 52.555399526858324],
              [0.205973588649949, 52.555849466222931],
              [0.204689873911464, 52.554637943243449],
              [0.204184454506975, 52.549440334736389],
              [0.205121163049621, 52.54788262739752],
              [0.206924495230108, 52.547224996996107],
              [0.20892458363893, 52.546961993846999],
              [0.213527249149187, 52.5489134923181],
              [0.214606908096446, 52.549088411781185],
              [0.214339977012096, 52.546950708586778],
              [0.211217449355386, 52.545217965834091],
              [0.208534157453778, 52.54462067662449],
              [0.2063026328858, 52.544736930240575],
              [0.203853006446774, 52.545386807786691],
              [0.213125958983563, 52.538153553654453],
              [0.209355014743797, 52.536092107878268],
              [0.210064714551489, 52.535454837844796],
              [0.205828118720225, 52.533832675459529],
              [0.207667693328847, 52.531851686761414],
              [0.208114952321992, 52.530069296711638],
              [0.211036169299817, 52.530695413275723],
              [0.214920712031573, 52.532033549932812],
              [0.220272739616398, 52.528903069108118],
              [0.225584985914372, 52.532551884729173],
              [0.228803124723, 52.530822464367468],
              [0.230571316271275, 52.527892036745122],
              [0.22626262502969, 52.525602985188115],
              [0.225665602005191, 52.52592442858478],
              [0.222366864494151, 52.52389227918372],
              [0.224805196887528, 52.522405993892583],
              [0.223153268988033, 52.521366139352274],
              [0.219686667094076, 52.523128846630442],
              [0.216626331284199, 52.520844797221081],
              [0.215043665753683, 52.52243628396247],
              [0.206103417996801, 52.519617198231778],
              [0.213621451619175, 52.518455329770447],
              [0.2362982233601, 52.507273033933984],
              [0.201610464429428, 52.480458670013491],
              [0.200705207158038, 52.480851369173479],
              [0.170693970375189, 52.457540380503744],
              [0.171574748162175, 52.457133072055463],
              [0.175915215088203, 52.460501581630275],
              [0.177063968227312, 52.460960592603243],
              [0.179294972317989, 52.458742617554719],
              [0.166379461289997, 52.448719569888311],
              [0.155321496822377, 52.440157501540902],
              [0.151019559309119, 52.442247276389487],
              [0.137758878199162, 52.431421789269024],
              [0.123817503446783, 52.420953253547637],
              [0.118662994860674, 52.423443812442592],
              [0.120814764929027, 52.431103847665767],
              [0.12981613411612, 52.435712081481718],
              [0.129120337872227, 52.436629170255863],
              [0.126447959005554, 52.437838082257919],
              [0.122045343697333, 52.435284442973092],
              [0.120814104143461, 52.435471082207677],
              [0.120091065982113, 52.43410833499469],
              [0.11695218716849, 52.433045127110844],
              [0.117041606362291, 52.431565306727656],
              [0.116008583807266, 52.429838530788835],
              [0.115086450649477, 52.429873015325114],
              [0.113881635957013, 52.428150193792767],
              [0.113846056923997, 52.427345182410441],
              [0.111779570521307, 52.427871257661849],
              [0.110612120698956, 52.427665517984721],
              [0.106615608006523, 52.42532076025396],
              [0.087702753108911, 52.429754624627655],
              [0.086330921156573, 52.429175508180855],
              [0.084875913047737, 52.428079929087069],
              [0.082362393784739, 52.427212485284116],
              [0.081645489077025, 52.426575920457957],
              [0.081084636353942, 52.424786584228684],
              [0.082867123550018, 52.423597978325482],
              [0.080099714820266, 52.421392521038193],
              [0.077572207715642, 52.420749113795893],
              [0.074826203554597, 52.421433044888353],
              [0.073287131038378, 52.421417787331116],
              [0.072850755921641, 52.421081962563754],
              [0.070187878525997, 52.421470303952518],
              [0.070149063522348, 52.420579022203945],
              [0.062657112997644, 52.417988243790965],
              [0.058749284540806, 52.415420946914132],
              [0.055252856604754, 52.414418091099996],
              [0.052510693527334, 52.41455025688515],
              [0.050033511579649, 52.415101264649721],
              [0.049077202821489, 52.41604217414946],
              [0.049538455730386, 52.416729222582411],
              [0.046512551133767, 52.418739999316223],
              [0.045357937273977, 52.419085475177589],
              [0.043623012642501, 52.418108431060929],
              [0.043095422833337, 52.417416212698313],
              [0.043326280877729, 52.41639797971078],
              [0.041663140056778, 52.413342626679601],
              [0.045125673938225, 52.411969110216269],
              [0.047943408311769, 52.411249557960737],
              [0.052565189708528, 52.408820804204964],
              [0.050148545264445, 52.407341376401178],
              [0.050181738100945, 52.406630469252505],
              [0.052011398654998, 52.40503059472946],
              [0.052942687489704, 52.401594039066879],
              [0.055106391520746, 52.400134872903756],
              [0.055155487332375, 52.399261838495832],
              [0.056174659337079, 52.397818051429653],
              [0.054425231758338, 52.396547395482742],
              [0.054352221039996, 52.395386955318344],
              [0.046443872527013, 52.392227852878072],
              [0.034396226123616, 52.385733558948345],
              [0.030343046602317, 52.387776905833078],
              [0.028055742030227, 52.39003888585782],
              [0.026685670256535, 52.390640543906173],
              [0.029028949572067, 52.394242803945573],
              [0.028872545644317, 52.39865133414331],
              [0.027322586179531, 52.400785541294248],
              [0.026705609778975, 52.404258752635492],
              [0.026879373900463, 52.405644061649603],
              [0.023691577197081, 52.406757881276846],
              [0.022886584116279, 52.409343228638257],
              [0.023427295327549, 52.410813074846814],
              [0.019379970024849, 52.413592320749856],
              [0.019004021133237, 52.414182289795022],
              [0.026350100408492, 52.420846964057247],
              [0.029736570033355, 52.422853313811345],
              [0.030626810167518, 52.424719044652768],
              [0.034033603024533, 52.427251825582452],
              [0.034036347096527, 52.428557342131846],
              [0.033080369048906, 52.429742678444839],
              [0.031629845014982, 52.430539082424978],
              [0.025362363222623, 52.432152688152243],
              [0.023893466958194, 52.432777573089901],
              [0.02225734467546, 52.433831495095191],
              [0.021617058029355, 52.434980763743205],
              [0.021941464995114, 52.435061534396702],
              [0.023546764747182, 52.436544636053739],
              [0.024142776280299, 52.438217635149527],
              [0.024047438567508, 52.440503097163578],
              [0.023150883714536, 52.442450704347571],
              [0.022626250469342, 52.442336496997619],
              [0.021614945923089, 52.4435154871178],
              [0.01816677688463, 52.444725295427098],
              [0.012070765946015, 52.448347530281602],
              [0.011280132742494, 52.449372516151342],
              [0.011226816935153, 52.451755250973775],
              [0.008788383813219, 52.454637086962521],
              [0.007476476143092, 52.454819412019319],
              [0.00188814153807, 52.454107616905247],
              [-0.001231124025425, 52.454257487480014],
              [-0.002592085199832, 52.45462485333163],
              [-0.007001105043342, 52.456753774521012],
              [-0.008452604039861, 52.458672712544086],
              [-0.008588370943526, 52.460458889237842],
              [-0.009344600481629, 52.462163798530227],
              [-0.018554868241702, 52.467611683237919],
              [-0.031705900955324, 52.473030066105352],
              [-0.038876221866251, 52.473267856973806],
              [-0.039041512008483, 52.475908650877876],
              [-0.041709869046077, 52.476998548507375],
              [-0.037620231421606, 52.47849705747111],
              [-0.040664361893062, 52.482566637025414],
              [-0.040318977637624, 52.482716465222396],
              [-0.039123845770598, 52.498295663980684],
              [-0.039375581565599, 52.50022037493666],
              [-0.038527648304879, 52.501826549516451],
              [-0.040208965852445, 52.502384897732163],
              [-0.045206966437735, 52.502677079035678],
              [-0.051339769198167, 52.502431185406131],
              [-0.05274056867939, 52.502006484036166],
              [-0.052883136396124, 52.50444815997421],
              [-0.051684349982588, 52.508099556807316],
              [-0.05073033393022, 52.509045908088559],
              [-0.047990412484592, 52.510520260139742],
              [-0.048420200305265, 52.510997592641573],
              [-0.054588476709084, 52.513377552693989],
              [-0.057571311752123, 52.512141708159255],
              [-0.069695806740945, 52.516841132165993],
              [-0.070904303665061, 52.505873561031066],
              [-0.090445280534735, 52.496194118910537],
              [-0.097672662713432, 52.501687294472106],
              [-0.104579201775036, 52.498239424954946],
              [-0.102155576442002, 52.496396037213231],
              [-0.105471790656203, 52.494645552854358],
              [-0.104783414503446, 52.493799244484414],
              [-0.114456716698803, 52.489022141534711],
              [-0.129657544201767, 52.50092049544795],
              [-0.143465542533618, 52.51116218673527],
              [-0.14774824491438, 52.514204466506229],
              [-0.151289147828308, 52.516183039217509],
              [-0.160291465396343, 52.52323299647729],
              [-0.16707271729912, 52.52764404968066],
              [-0.17067847186825, 52.531265697819684],
              [-0.173667702679027, 52.53209948089264],
              [-0.176321395162109, 52.532351691055041],
              [-0.17903780964957, 52.533690026644756],
              [-0.182267164937903, 52.533965343788708],
              [-0.185994966376586, 52.535598667604994],
              [-0.186666163446546, 52.536306657533203],
              [-0.188305628077869, 52.537062735373773],
              [-0.190083209740771, 52.539660467158065],
              [-0.187866892168435, 52.541844643104213],
              [-0.186647817985266, 52.542691809217096],
              [-0.185644618491764, 52.544918798760754],
              [-0.190635067817443, 52.547648438116823],
              [-0.191047285017136, 52.550320574587381],
              [-0.197889642859706, 52.550787277272057],
              [-0.195071806990153, 52.55443335508992],
              [-0.188531746788162, 52.559864725218219],
              [-0.187651339357569, 52.560429371894038],
              [-0.189204145954376, 52.562917566881239],
              [-0.189894704099881, 52.565424027889925],
              [-0.181712195721831, 52.56648455362496],
              [-0.186995957442942, 52.568487860037855],
              [-0.176983413282094, 52.56978885552693],
              [-0.166030896433774, 52.569554069375918],
              [-0.12440908781064, 52.576661300216813],
              [-0.123968254413249, 52.576408859963124],
              [-0.119996393143993, 52.57721080036881],
              [-0.087518201819149, 52.582502493204885],
              [-0.083513331917999, 52.582543833320535],
              [-0.081383988976432, 52.583341026804284],
              [-0.027084848801995, 52.591778858428619],
              [-0.023948369387011, 52.592055635616326],
              [-0.012633920800586, 52.59409699513008],
              [0.039960680412848, 52.60219655339219],
              [0.061877163675202, 52.606535606540128],
              [0.061954825864114, 52.605610848990921],
              [0.064911146348176, 52.610876785859169],
              [0.074881006098322, 52.616557664160979],
              [0.096075457914396, 52.627945097084357],
              [0.10543398406072, 52.632145299482751],
              [0.115234818020425, 52.635397281287361],
              [0.122806282776321, 52.637966429071767],
              [0.130403240927397, 52.642045185735967],
              [0.151398059832362, 52.66079757312734],
              [0.151783646389043, 52.661452334411536],
              [0.151405686655862, 52.662638863279717],
              [0.151791242317121, 52.663172241830829],
              [0.155562200104255, 52.664553102839427],
              [0.158195854802424, 52.664938543269344],
              [0.15947563201177, 52.666628978007978],
              [0.159485725645619, 52.667433520445265],
              [0.157776892684216, 52.670752789496404],
              [0.153087616972097, 52.675554047443448],
              [0.152868131104757, 52.676204512248205],
              [0.153140432375442, 52.677800921239125],
              [0.153975873227387, 52.678184054353721],
              [0.153607110815375, 52.67862683640773],
              [0.154054040565403, 52.679682387707416],
              [0.154046531859716, 52.68214524940295],
              [0.154878421740533, 52.682123831331367],
              [0.15484451318788, 52.680773950115189],
              [0.157157336820255, 52.680663537148973],
            ],
          ],
          [
            [
              [0.17121188662737, 52.738203062152607],
              [0.169911462702632, 52.736120211190752],
              [0.16696790525964, 52.724290263810637],
              [0.158487326710756, 52.712247392470879],
              [0.155748880471468, 52.707692783751746],
              [0.155622667783828, 52.702219377013677],
              [0.154619321549653, 52.696244015385275],
              [0.153796684219015, 52.685095348925358],
              [0.153034569842411, 52.679709006883549],
              [0.152029094963662, 52.676228744246274],
              [0.152669381229106, 52.675140851578895],
              [0.154923349206821, 52.673239575067413],
              [0.157351239735047, 52.67054924338477],
              [0.15903805776153, 52.667412906229089],
              [0.159157005118951, 52.666795730007031],
              [0.158240601296347, 52.665306373838199],
              [0.152814211137897, 52.66394669615525],
              [0.150964381723637, 52.662927405781481],
              [0.151235239662059, 52.661155687941651],
              [0.139858821771144, 52.650766305903353],
              [0.130484757005284, 52.642505874789528],
              [0.123469979556376, 52.638547961641287],
              [0.104660711347561, 52.632198603595064],
              [0.093125155878364, 52.626872457894912],
              [0.065441234538929, 52.61159493715904],
              [0.063943118329614, 52.61039374497188],
              [0.062398132338883, 52.607376211484372],
              [0.061423456268311, 52.606686466676152],
              [0.03946535865652, 52.60237412074283],
              [-0.01285829564264, 52.594326439684558],
              [-0.016267944932248, 52.598368503686395],
              [-0.025064972307583, 52.632350139727883],
              [-0.031287512593998, 52.661513848522304],
              [0.021483747241839, 52.664868221849026],
              [0.047996881851155, 52.681030433061771],
              [0.048982107349606, 52.681977256251976],
              [0.048690032310046, 52.683481133229371],
              [0.049608573913319, 52.686130232467249],
              [0.049133185222939, 52.686910793780108],
              [0.049322802085791, 52.688685067691146],
              [0.048404379180238, 52.690085583064445],
              [0.044965246707514, 52.691916229111555],
              [0.044555729692286, 52.692462763054671],
              [0.04521538838723, 52.693690362043917],
              [0.04486718306207, 52.695770627246617],
              [0.045243399803366, 52.697170350818283],
              [0.045891739009514, 52.697774155888112],
              [0.044384010484342, 52.70066384839263],
              [0.045219706104653, 52.70141188019533],
              [0.045030367031522, 52.70321876872012],
              [0.045446113457406, 52.70498734416504],
              [0.044827338914778, 52.71084315195715],
              [0.045093627134068, 52.711978632726478],
              [0.044134707865629, 52.714362533505444],
              [0.04624814246799, 52.715358253040229],
              [0.04800380430294, 52.718765241081016],
              [0.055122503864594, 52.721810524106374],
              [0.059224379475873, 52.724703723593016],
              [0.062292988175687, 52.725366978940862],
              [0.063046040903851, 52.726010230075481],
              [0.063172025715959, 52.727110350317567],
              [0.063889931035427, 52.727416141372053],
              [0.067958811508496, 52.726333738627012],
              [0.072575373288999, 52.725531136416059],
              [0.076025928324071, 52.724502418679769],
              [0.082626585639769, 52.724540401564532],
              [0.087534369010146, 52.723550475120192],
              [0.088997348260017, 52.723610122070298],
              [0.091635159301112, 52.725196411098182],
              [0.09457269802946, 52.7284281275411],
              [0.098774908880621, 52.7303201853573],
              [0.100760410434198, 52.730399209868537],
              [0.10252585019544, 52.729844628784008],
              [0.104926354699678, 52.730483567207493],
              [0.111091230729765, 52.729758959630054],
              [0.113671114990027, 52.729970142922838],
              [0.114130201096069, 52.731377172874495],
              [0.115310003969746, 52.732281324425166],
              [0.116782182983955, 52.732707307663077],
              [0.119698504716119, 52.732857507754922],
              [0.129032797503729, 52.738416768937881],
              [0.130944357134621, 52.738368043973111],
              [0.132960835350504, 52.739284856156019],
              [0.137722306106653, 52.738896102837444],
              [0.13942827264593, 52.739186320470822],
              [0.140638736796518, 52.739733618333524],
              [0.143672976367263, 52.739980906179525],
              [0.144112705867726, 52.73900999392859],
              [0.147598160475505, 52.73942614836745],
              [0.148276904610163, 52.738618127631696],
              [0.150815381156541, 52.738725858051474],
              [0.152486211171808, 52.738120996998298],
              [0.167031701206936, 52.739372874651544],
              [0.170166135575445, 52.739874804332956],
              [0.170138824207183, 52.738937513872123],
              [0.17121188662737, 52.738203062152607],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000011",
        LAD13CDO: "12UE",
        LAD13NM: "Huntingdonshire",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [0.031999138811792, 52.352922331503088],
              [0.030474364100328, 52.352333523075444],
              [0.030542190851081, 52.352692020609354],
              [0.031999138811792, 52.352922331503088],
            ],
          ],
          [
            [
              [-0.396853188553614, 52.582980278162509],
              [-0.393035816848862, 52.580852580073959],
              [-0.391388963979678, 52.578506117044384],
              [-0.389898103839245, 52.577465406959533],
              [-0.389397703070038, 52.576219678560108],
              [-0.387864711026382, 52.575043507624301],
              [-0.387701993442852, 52.57445060054372],
              [-0.389252025480365, 52.573429669120479],
              [-0.389331894493882, 52.572901200953822],
              [-0.388939583276113, 52.57277448886213],
              [-0.389579061149459, 52.571310504303071],
              [-0.38859347795007, 52.569012547174097],
              [-0.385465061058005, 52.567171779845658],
              [-0.384022119900865, 52.566888666006882],
              [-0.383912659823192, 52.567458084970802],
              [-0.382650054751009, 52.567206186120636],
              [-0.381305186764951, 52.567666116209551],
              [-0.380039064278253, 52.566949319104786],
              [-0.379139316111406, 52.56718155414373],
              [-0.375757541874906, 52.566262207916125],
              [-0.373397022922785, 52.56637635196028],
              [-0.372915734142503, 52.566025392539458],
              [-0.372755308394835, 52.564659287932855],
              [-0.370819882076615, 52.564133684165931],
              [-0.369655104867718, 52.563078317141134],
              [-0.367398328002434, 52.562746927429664],
              [-0.36293059962143, 52.563312793230892],
              [-0.361177027928033, 52.56432517135184],
              [-0.356851728700665, 52.564981789061584],
              [-0.348492087673828, 52.564352103059385],
              [-0.346080953452134, 52.562753146928038],
              [-0.345222707336301, 52.561213616405517],
              [-0.343962619816901, 52.56078781479502],
              [-0.341168899712788, 52.557999312844366],
              [-0.340646397132316, 52.557146853604351],
              [-0.34064451887395, 52.555632773062115],
              [-0.33733045401749, 52.553629871594907],
              [-0.336010545000013, 52.553584354123096],
              [-0.332377348253675, 52.552383303060999],
              [-0.327258883437766, 52.552893696821712],
              [-0.326858483717879, 52.552329707240631],
              [-0.325473502214877, 52.552871153425407],
              [-0.323692318168097, 52.551500015490234],
              [-0.326691967639839, 52.550774634923641],
              [-0.326752413998155, 52.549612073850433],
              [-0.325940191291514, 52.548631377180008],
              [-0.332751227216097, 52.54652744069277],
              [-0.320699892677059, 52.535766751339601],
              [-0.317839811042324, 52.534330754794347],
              [-0.317612199794354, 52.533458101706579],
              [-0.314444468773311, 52.530090900524726],
              [-0.305123724035763, 52.521450598632988],
              [-0.29631477445482, 52.514302200763417],
              [-0.294134787125216, 52.51160947861657],
              [-0.29330745755163, 52.50687101045046],
              [-0.28782743536644, 52.508732990697538],
              [-0.285778901692392, 52.50654460397898],
              [-0.285322337259908, 52.506766359199162],
              [-0.284655944302312, 52.506094966327062],
              [-0.282368968174696, 52.507157789240907],
              [-0.279628087514158, 52.509275802559152],
              [-0.274557119436566, 52.5116574017811],
              [-0.271787154355728, 52.51404903612611],
              [-0.270846923585601, 52.515588040375697],
              [-0.271980929326499, 52.515307903233257],
              [-0.272526463233593, 52.515715066674822],
              [-0.27173085647902, 52.516422726776298],
              [-0.271134600090293, 52.516369062549764],
              [-0.271026141379323, 52.516766676618687],
              [-0.2743634620168, 52.517089612699635],
              [-0.274065200094796, 52.518946390649312],
              [-0.270504264496892, 52.518609390141769],
              [-0.270337850992813, 52.520444709586599],
              [-0.269349146035947, 52.520361923716671],
              [-0.269312514390095, 52.520806440369753],
              [-0.263223052215158, 52.521904936470484],
              [-0.256813668775638, 52.522587521568425],
              [-0.255899142031346, 52.521077949746513],
              [-0.252932021609369, 52.5222677462327],
              [-0.246732263242777, 52.523751279773222],
              [-0.244798065422881, 52.522980853275548],
              [-0.243223464675285, 52.524430212911888],
              [-0.242463707883689, 52.524703946635668],
              [-0.243091376890615, 52.529624139552581],
              [-0.242687602997942, 52.529656806177613],
              [-0.240484944390593, 52.529660044345988],
              [-0.240491440314882, 52.529276226069832],
              [-0.238283452671626, 52.529081542272642],
              [-0.237292392710676, 52.530416332452646],
              [-0.231743000172155, 52.534458616687452],
              [-0.235196518026382, 52.535991874876409],
              [-0.234379108828463, 52.53629976289043],
              [-0.235100425403256, 52.536549680840537],
              [-0.234747833008621, 52.536922043763255],
              [-0.235724949725507, 52.537301646222772],
              [-0.236673256435589, 52.538216673280374],
              [-0.235532946207599, 52.538817359660555],
              [-0.235011385576268, 52.539688900660423],
              [-0.234990993792589, 52.541116362908575],
              [-0.235575517666274, 52.542426070847547],
              [-0.234852534064944, 52.543648852410456],
              [-0.228691199950827, 52.543647620831656],
              [-0.228665844380554, 52.542848842743958],
              [-0.22313968013334, 52.542422560303798],
              [-0.22276191637537, 52.542063543184796],
              [-0.223048417474543, 52.540994320948244],
              [-0.219480767984459, 52.540278071667935],
              [-0.21968407107181, 52.5398819297138],
              [-0.217680528992117, 52.537905226627977],
              [-0.216491386744922, 52.54032297519985],
              [-0.214160153144533, 52.541773137798067],
              [-0.212374307226708, 52.542282043741203],
              [-0.208533421470425, 52.542302195765402],
              [-0.20523614153394, 52.542878019310493],
              [-0.202772621284282, 52.542535844421131],
              [-0.200930054700416, 52.544283577663251],
              [-0.200660069103064, 52.545151601736599],
              [-0.200126197120126, 52.545115612896858],
              [-0.195717523740047, 52.544841700219244],
              [-0.187866892168435, 52.541844643104213],
              [-0.190083209740771, 52.539660467158065],
              [-0.188305628077869, 52.537062735373773],
              [-0.186666163446546, 52.536306657533203],
              [-0.185994966376586, 52.535598667604994],
              [-0.182267164937903, 52.533965343788708],
              [-0.17903780964957, 52.533690026644756],
              [-0.176321395162109, 52.532351691055041],
              [-0.173667702679027, 52.53209948089264],
              [-0.17067847186825, 52.531265697819684],
              [-0.16707271729912, 52.52764404968066],
              [-0.160291465396343, 52.52323299647729],
              [-0.151289147828308, 52.516183039217509],
              [-0.14774824491438, 52.514204466506229],
              [-0.143465542533618, 52.51116218673527],
              [-0.129657544201767, 52.50092049544795],
              [-0.114456716698803, 52.489022141534711],
              [-0.104783414503446, 52.493799244484414],
              [-0.105471790656203, 52.494645552854358],
              [-0.102155576442002, 52.496396037213231],
              [-0.104579201775036, 52.498239424954946],
              [-0.097672662713432, 52.501687294472106],
              [-0.090445280534735, 52.496194118910537],
              [-0.070904303665061, 52.505873561031066],
              [-0.069695806740945, 52.516841132165993],
              [-0.057571311752123, 52.512141708159255],
              [-0.054588476709084, 52.513377552693989],
              [-0.048420200305265, 52.510997592641573],
              [-0.047990412484592, 52.510520260139742],
              [-0.05073033393022, 52.509045908088559],
              [-0.051684349982588, 52.508099556807316],
              [-0.052883136396124, 52.50444815997421],
              [-0.05274056867939, 52.502006484036166],
              [-0.051339769198167, 52.502431185406131],
              [-0.045206966437735, 52.502677079035678],
              [-0.040208965852445, 52.502384897732163],
              [-0.038527648304879, 52.501826549516451],
              [-0.039375581565599, 52.50022037493666],
              [-0.039123845770598, 52.498295663980684],
              [-0.040318977637624, 52.482716465222396],
              [-0.040664361893062, 52.482566637025414],
              [-0.037620231421606, 52.47849705747111],
              [-0.041709869046077, 52.476998548507375],
              [-0.039041512008483, 52.475908650877876],
              [-0.038876221866251, 52.473267856973806],
              [-0.031705900955324, 52.473030066105352],
              [-0.018554868241702, 52.467611683237919],
              [-0.009344600481629, 52.462163798530227],
              [-0.008588370943526, 52.460458889237842],
              [-0.008452604039861, 52.458672712544086],
              [-0.007001105043342, 52.456753774521012],
              [-0.002592085199832, 52.45462485333163],
              [-0.001231124025425, 52.454257487480014],
              [0.00188814153807, 52.454107616905247],
              [0.007476476143092, 52.454819412019319],
              [0.008788383813219, 52.454637086962521],
              [0.011226816935153, 52.451755250973775],
              [0.011280132742494, 52.449372516151342],
              [0.012070765946015, 52.448347530281602],
              [0.01816677688463, 52.444725295427098],
              [0.021614945923089, 52.4435154871178],
              [0.022626250469342, 52.442336496997619],
              [0.023150883714536, 52.442450704347571],
              [0.024047438567508, 52.440503097163578],
              [0.024142776280299, 52.438217635149527],
              [0.023546764747182, 52.436544636053739],
              [0.021941464995114, 52.435061534396702],
              [0.021617058029355, 52.434980763743205],
              [0.02225734467546, 52.433831495095191],
              [0.023893466958194, 52.432777573089901],
              [0.025362363222623, 52.432152688152243],
              [0.031629845014982, 52.430539082424978],
              [0.033080369048906, 52.429742678444839],
              [0.034036347096527, 52.428557342131846],
              [0.034033603024533, 52.427251825582452],
              [0.030626810167518, 52.424719044652768],
              [0.029736570033355, 52.422853313811345],
              [0.026350100408492, 52.420846964057247],
              [0.019004021133237, 52.414182289795022],
              [0.019379970024849, 52.413592320749856],
              [0.023427295327549, 52.410813074846814],
              [0.022886584116279, 52.409343228638257],
              [0.023691577197081, 52.406757881276846],
              [0.026879373900463, 52.405644061649603],
              [0.026705609778975, 52.404258752635492],
              [0.027322586179531, 52.400785541294248],
              [0.028872545644317, 52.39865133414331],
              [0.029028949572067, 52.394242803945573],
              [0.026685670256535, 52.390640543906173],
              [0.028055742030227, 52.39003888585782],
              [0.030343046602317, 52.387776905833078],
              [0.034396226123616, 52.385733558948345],
              [0.036300510917701, 52.383621916156919],
              [0.038807270801708, 52.382758665466511],
              [0.041924614525771, 52.380672749768529],
              [0.043682565155109, 52.375595394316711],
              [0.045951555894632, 52.371794055076712],
              [0.046722204272819, 52.368349521419901],
              [0.047566848443078, 52.367714468138232],
              [0.049158881220566, 52.364471476089015],
              [0.048639337360826, 52.363972460549597],
              [0.049374871790548, 52.362854637997593],
              [0.050453308231074, 52.358992917864825],
              [0.051330030697303, 52.358167555583577],
              [0.048994688668564, 52.356352213490858],
              [0.046629834153734, 52.355324102453757],
              [0.043490142920886, 52.352967802857513],
              [0.040626589134669, 52.353124299039777],
              [0.037497496561287, 52.353829296678349],
              [0.034291641576559, 52.35360040753789],
              [0.030606538736958, 52.352846468986222],
              [0.030140554148663, 52.353188065000019],
              [0.02753820100855, 52.353373922573461],
              [0.028038463728637, 52.352786278498002],
              [0.02804049173817, 52.35305509186572],
              [0.030033271107773, 52.352993892034284],
              [0.02844630692437, 52.352842217754038],
              [0.030226854885275, 52.352700137454896],
              [0.028158244737801, 52.352580113430115],
              [0.029134703781296, 52.352387111372792],
              [0.028488703384738, 52.352100583692035],
              [0.029494507896716, 52.352388124279372],
              [0.029953077950862, 52.352237279682967],
              [0.026177418800352, 52.351329205699336],
              [0.013391337370916, 52.343551603396811],
              [0.009009945267719, 52.341498791933262],
              [0.009344141730396, 52.339091459876407],
              [0.008532171939116, 52.335329707636866],
              [0.008153682193951, 52.334362355216001],
              [0.003888792401236, 52.329893140571976],
              [0.000138791610529, 52.328035201423972],
              [-0.004164756735544, 52.323457557867798],
              [-0.004760063376137, 52.321739435454909],
              [-0.005893244911125, 52.318892059010331],
              [-0.007198302910101, 52.316983594965379],
              [-0.011226595327453, 52.315297240428315],
              [-0.014848024263759, 52.312631023760751],
              [-0.016758083374196, 52.312632543180939],
              [-0.022155623016844, 52.315129201338806],
              [-0.024542315388991, 52.315565674019453],
              [-0.031241549136257, 52.316785296274709],
              [-0.032411949664844, 52.316497289617587],
              [-0.034866312694689, 52.314913381240906],
              [-0.037399696116527, 52.314225394051284],
              [-0.038791761283322, 52.314263804298669],
              [-0.042392783826834, 52.315112104727262],
              [-0.04526425207764, 52.314506879976818],
              [-0.046489131533313, 52.314644036388643],
              [-0.04703647203572, 52.313375392455775],
              [-0.046524118499348, 52.31031789099692],
              [-0.047525786476547, 52.308190873849924],
              [-0.049278839999952, 52.306787492452166],
              [-0.051966127795349, 52.305718699230773],
              [-0.05473220133092, 52.300774893904958],
              [-0.059922807078035, 52.293961980022836],
              [-0.057175787284791, 52.292764099354173],
              [-0.05775283528549, 52.291741363258055],
              [-0.060150633020136, 52.290392485364741],
              [-0.061557849012219, 52.290503712420367],
              [-0.061593664271576, 52.290825298321273],
              [-0.06246563848975, 52.291038322531662],
              [-0.063556745456988, 52.287740141870053],
              [-0.064873112677821, 52.287343616343762],
              [-0.066558819852603, 52.285131438899676],
              [-0.067420723203477, 52.285405404809524],
              [-0.070478252868092, 52.282624873876102],
              [-0.074457861385111, 52.280343051264779],
              [-0.074143845639238, 52.279944100359266],
              [-0.076235467489401, 52.279240893232853],
              [-0.075826256161564, 52.278670455615327],
              [-0.07862404153094, 52.276924006117291],
              [-0.082629809747648, 52.275270845841796],
              [-0.083021637784162, 52.274988580366461],
              [-0.082835917581337, 52.274231175105783],
              [-0.084102774000709, 52.27428950465638],
              [-0.085411667153682, 52.272488154523423],
              [-0.092859110721478, 52.273818054054175],
              [-0.09470298016228, 52.274605809800185],
              [-0.095053075341341, 52.275192311036548],
              [-0.09742851373033, 52.275583980885997],
              [-0.098153767570777, 52.27791997113637],
              [-0.102303494596762, 52.277651359522118],
              [-0.101757039627867, 52.276889087011106],
              [-0.106090871972657, 52.273021307066749],
              [-0.106130431105017, 52.27178381137265],
              [-0.107519148893257, 52.271767416760369],
              [-0.107503360787805, 52.270417538735579],
              [-0.109657894517774, 52.268763451681714],
              [-0.111188383020059, 52.268926417054828],
              [-0.111508053021185, 52.266198113517611],
              [-0.114085238463896, 52.266179068666759],
              [-0.113921958634359, 52.264156068995192],
              [-0.11489356410927, 52.263879363925788],
              [-0.114260875788917, 52.262005319722256],
              [-0.11835452333414, 52.262262159388008],
              [-0.118567715978971, 52.261033721282182],
              [-0.120632076635727, 52.259793407298105],
              [-0.120991519224576, 52.2574604387408],
              [-0.123747288249292, 52.257086166712092],
              [-0.142677602295955, 52.283178435388479],
              [-0.143967871941923, 52.283105213240233],
              [-0.144207745912579, 52.283539673623416],
              [-0.148030469245555, 52.284578860532235],
              [-0.151458509911719, 52.284325969687252],
              [-0.154975001676472, 52.283707511418164],
              [-0.159836691275293, 52.283349991828871],
              [-0.1639200434936, 52.282206909999275],
              [-0.17088702015985, 52.281699129561844],
              [-0.175498976708, 52.280203281673927],
              [-0.179844811397855, 52.270454435019765],
              [-0.177956910812799, 52.269957760975153],
              [-0.17899154084431, 52.266777274610838],
              [-0.180941160478932, 52.267087862606552],
              [-0.183126123750797, 52.263972713980529],
              [-0.184146839128779, 52.260761397644458],
              [-0.182437792812371, 52.256114402816301],
              [-0.180150631003044, 52.255757272390824],
              [-0.180361471498136, 52.255044804409643],
              [-0.177644141755779, 52.251279531175435],
              [-0.177519654856158, 52.250277760691318],
              [-0.177098781785218, 52.250252379920788],
              [-0.176175138945023, 52.252984099779184],
              [-0.173707984630724, 52.255765672456612],
              [-0.170121615810904, 52.255846354200614],
              [-0.162237105933845, 52.254528456750151],
              [-0.157145254167408, 52.254294422313031],
              [-0.155930135122294, 52.253742252281874],
              [-0.152202252308999, 52.252912488589018],
              [-0.151455196464856, 52.251887452942142],
              [-0.148733295327476, 52.25167305914799],
              [-0.148784128315717, 52.250135414131847],
              [-0.147800551788265, 52.248461055942684],
              [-0.146339326455211, 52.248442613760979],
              [-0.14597362028137, 52.247797575416662],
              [-0.145245037960844, 52.247950673476545],
              [-0.145196014116276, 52.247369952100222],
              [-0.143106935601559, 52.24646133488995],
              [-0.141786549164583, 52.245422718363336],
              [-0.139935173281007, 52.245488867566664],
              [-0.134713075319503, 52.244440770924456],
              [-0.132243715421966, 52.243248110727656],
              [-0.133535244966707, 52.241023360670894],
              [-0.134057318752562, 52.240938103635131],
              [-0.133763119246583, 52.239502003231188],
              [-0.134672274812594, 52.237275689595073],
              [-0.134546450860616, 52.235666017544744],
              [-0.136769023580597, 52.235818914915029],
              [-0.136934255533914, 52.23547534993272],
              [-0.137899546112067, 52.231376964218363],
              [-0.13761212654869, 52.230127103199706],
              [-0.140200028357635, 52.229871193198427],
              [-0.140198554545899, 52.229135663613782],
              [-0.144650835698765, 52.229396362138473],
              [-0.147454368354378, 52.228847862461869],
              [-0.148197364804855, 52.229149948944205],
              [-0.150024336427881, 52.227857748981286],
              [-0.1490646306674, 52.225936500403179],
              [-0.15290746474099, 52.2265164181348],
              [-0.153263493926847, 52.226122766979913],
              [-0.155775925393773, 52.226443489857765],
              [-0.158605311318676, 52.227137756368329],
              [-0.162137228248168, 52.227557866532109],
              [-0.165939842394018, 52.228588104004828],
              [-0.17128710355737, 52.229270813970757],
              [-0.175657248246942, 52.230467778123931],
              [-0.180268703324322, 52.230209883504884],
              [-0.182766472975758, 52.228676661269027],
              [-0.187039337158978, 52.228781040032352],
              [-0.187228264903561, 52.224969762673673],
              [-0.188733573144592, 52.220828027144222],
              [-0.188862460338551, 52.216544668645689],
              [-0.189830417125875, 52.213907933909276],
              [-0.191905911831748, 52.210919515888186],
              [-0.19294850568026, 52.209718040256099],
              [-0.193501763766078, 52.209573656453557],
              [-0.195631458663117, 52.204477500369244],
              [-0.194755149712645, 52.204361593545343],
              [-0.19253239390075, 52.20330972869106],
              [-0.191904857879281, 52.201356153257514],
              [-0.189074620540583, 52.200566453894425],
              [-0.183596909625479, 52.201976641302046],
              [-0.178753208588669, 52.201616045194847],
              [-0.17586280300123, 52.199549202578183],
              [-0.175908919281479, 52.197243591105988],
              [-0.173156342754827, 52.197677553702391],
              [-0.173162621914182, 52.198273788854372],
              [-0.172613901446039, 52.198309349886649],
              [-0.17217362457907, 52.199268219465665],
              [-0.168292873539887, 52.200800434860554],
              [-0.166672281240772, 52.202523217317754],
              [-0.159342757018229, 52.205693709415847],
              [-0.156334957252428, 52.209059035897141],
              [-0.152831190328294, 52.211984031415852],
              [-0.147023451603222, 52.210217850158507],
              [-0.141127065163445, 52.209604497679493],
              [-0.140269560213155, 52.207882591431208],
              [-0.137659098574842, 52.208008661357603],
              [-0.137255285052405, 52.207760414344335],
              [-0.13048944886741, 52.207194839519566],
              [-0.116874923889092, 52.208173321920206],
              [-0.115100525691016, 52.208116216789385],
              [-0.111500548091444, 52.207000343540045],
              [-0.10991784042296, 52.206945328838948],
              [-0.109555842202275, 52.206508832310384],
              [-0.108151578969476, 52.206870265923108],
              [-0.10725551624226, 52.205673500580971],
              [-0.107152076721517, 52.202941992332455],
              [-0.104666730545559, 52.202849043635034],
              [-0.105740744089194, 52.200994240073079],
              [-0.103974582939332, 52.200372424927735],
              [-0.107283611814622, 52.197573418321468],
              [-0.104438641299156, 52.196998137653694],
              [-0.104872117658509, 52.196060982289467],
              [-0.10575430943038, 52.19613269610975],
              [-0.106675233363537, 52.195086468660563],
              [-0.107129646702224, 52.193792674175384],
              [-0.104544802592132, 52.193539870671458],
              [-0.101243986880597, 52.185402422381124],
              [-0.100565378181234, 52.182652646362193],
              [-0.104307758584108, 52.180883947716978],
              [-0.106103405516815, 52.179279010974838],
              [-0.107957068851511, 52.179646842597087],
              [-0.108935860652433, 52.179088871849935],
              [-0.110986667296967, 52.178912225958292],
              [-0.116074172232101, 52.179524991195372],
              [-0.133874380640114, 52.183062883021805],
              [-0.137824179850046, 52.184456053524066],
              [-0.141037972626489, 52.183404372496831],
              [-0.143043668343079, 52.183364028935586],
              [-0.144959908169352, 52.1827314979395],
              [-0.150249598061059, 52.177294631450742],
              [-0.15330794503517, 52.175421049473684],
              [-0.15341205749867, 52.174531613894537],
              [-0.152025417533175, 52.172595590751961],
              [-0.15089710685413, 52.172367505564615],
              [-0.150615054499127, 52.171269706831019],
              [-0.151622320302781, 52.169654421223008],
              [-0.1556275958254, 52.169372739566612],
              [-0.156214677070406, 52.167233828462969],
              [-0.168679178379617, 52.16761863207207],
              [-0.17878072922342, 52.16700355915647],
              [-0.17956926226261, 52.167889710979971],
              [-0.182066788323119, 52.167854499264962],
              [-0.183776716888896, 52.168918465626881],
              [-0.186913248999325, 52.167590113285399],
              [-0.189557724343143, 52.169193462512801],
              [-0.190512491858297, 52.168763930807181],
              [-0.191878230564975, 52.166889457192141],
              [-0.193497897940119, 52.167442976749385],
              [-0.198137772465139, 52.164905502254413],
              [-0.202280614260038, 52.163622684341242],
              [-0.203513770839234, 52.162845722193644],
              [-0.211122992874674, 52.161083942016212],
              [-0.213453791907797, 52.1597984144368],
              [-0.21664735216043, 52.1587237030758],
              [-0.21978090176125, 52.161530537815807],
              [-0.221236283684948, 52.160964450089075],
              [-0.223627033792318, 52.162241308959004],
              [-0.223254252490848, 52.162643908692417],
              [-0.226118383897136, 52.164925902533788],
              [-0.228885617238622, 52.16623079185814],
              [-0.240140680618759, 52.170220631858484],
              [-0.251099373076936, 52.172495712566629],
              [-0.253565565632989, 52.172555628204989],
              [-0.253798604943408, 52.172019590516229],
              [-0.254421439879285, 52.172172672121931],
              [-0.254182298605608, 52.172752678831976],
              [-0.254866822195856, 52.173385016509592],
              [-0.257413059558008, 52.174279543242733],
              [-0.260716371526503, 52.176389133776269],
              [-0.257251416202791, 52.177666932322246],
              [-0.257736201529348, 52.17813625350221],
              [-0.253470439475296, 52.180846139078099],
              [-0.24980747495411, 52.184358761443825],
              [-0.247067058585808, 52.183741722573465],
              [-0.247292468569559, 52.183217273395847],
              [-0.245178924141053, 52.183102233389036],
              [-0.244080490711018, 52.182914152521192],
              [-0.244024192527263, 52.182672343508848],
              [-0.240745320511338, 52.190180811974706],
              [-0.242082564400889, 52.190364388948083],
              [-0.244307498571846, 52.191485484453402],
              [-0.246243420152206, 52.191848758677857],
              [-0.246922399645382, 52.192552089512375],
              [-0.247878179064227, 52.192839627005242],
              [-0.249547246817689, 52.194709444268831],
              [-0.251638783262335, 52.19554878566553],
              [-0.252160010464635, 52.196024961674851],
              [-0.251980116551466, 52.196507833788196],
              [-0.254531589675416, 52.199466920391259],
              [-0.254766134173349, 52.201435914192842],
              [-0.255524881493344, 52.201230441190006],
              [-0.256544051995231, 52.202820800761245],
              [-0.260589609362139, 52.203626794224782],
              [-0.261206259335738, 52.20413760335331],
              [-0.260468862432297, 52.205407111141177],
              [-0.264933921076478, 52.205708425458155],
              [-0.264095153052349, 52.206351562230793],
              [-0.261949425420151, 52.209619820608083],
              [-0.261867097202726, 52.209854182610968],
              [-0.266230076186253, 52.20955601370747],
              [-0.274226503991362, 52.208707556497615],
              [-0.28033356218489, 52.208417277538764],
              [-0.28845296806726, 52.207364647239473],
              [-0.290808593754659, 52.20768741370653],
              [-0.291262285260201, 52.207420646748147],
              [-0.292296418137643, 52.207474279989619],
              [-0.294378065372396, 52.208340583668061],
              [-0.295600688607745, 52.210429858446943],
              [-0.296305931150294, 52.210474209629773],
              [-0.296506717589302, 52.210811586771698],
              [-0.299411815631144, 52.216030707095015],
              [-0.299986309176344, 52.218044953138609],
              [-0.299171681695382, 52.221013844978671],
              [-0.293779716281114, 52.228425800214978],
              [-0.286652516024598, 52.234834976962382],
              [-0.285749357313157, 52.236230805246102],
              [-0.285715005634548, 52.237388389971322],
              [-0.288024419312039, 52.23830575622096],
              [-0.291634487622322, 52.239223026724694],
              [-0.293230111389156, 52.240503096326762],
              [-0.295846838859637, 52.240479763162043],
              [-0.298541808797341, 52.240976298542947],
              [-0.307744014118064, 52.239864188484255],
              [-0.31061550209562, 52.23991880791904],
              [-0.313708978457096, 52.238985688107746],
              [-0.314400793604869, 52.240285820345939],
              [-0.31591898553671, 52.241501534108778],
              [-0.320017451644988, 52.242694636345043],
              [-0.323096644334636, 52.243111557987675],
              [-0.324938714755125, 52.242853587730984],
              [-0.327142289214483, 52.242991833063421],
              [-0.32930071307627, 52.242538671444173],
              [-0.334514399942434, 52.242907261806963],
              [-0.336306428248494, 52.242732027180281],
              [-0.338005040941953, 52.242194900473478],
              [-0.340235471734618, 52.242241568483067],
              [-0.34348951455219, 52.241861092556192],
              [-0.3432386900272, 52.241399018148783],
              [-0.344032879416008, 52.238478112084401],
              [-0.343073263898764, 52.237425255363135],
              [-0.343340284027293, 52.236429174542515],
              [-0.342673207317428, 52.235348050862981],
              [-0.344146754465593, 52.235143051024657],
              [-0.340375093955129, 52.230921724487999],
              [-0.341031571132549, 52.230634241315215],
              [-0.345829117520081, 52.230620654643587],
              [-0.348892484059804, 52.231211115929966],
              [-0.349938123172726, 52.230813039452492],
              [-0.350625450318837, 52.2309557170652],
              [-0.352403789852923, 52.23168906613386],
              [-0.353758726522621, 52.233339885757729],
              [-0.35534484960805, 52.234206276327164],
              [-0.358843629955927, 52.234476160486544],
              [-0.35905862341309, 52.234721913374187],
              [-0.362757487928658, 52.234053078898455],
              [-0.368792950456869, 52.234634756868473],
              [-0.374396076365945, 52.232984841006122],
              [-0.374582528671549, 52.233613198798814],
              [-0.373370148069813, 52.23401009405768],
              [-0.373768353228332, 52.236978192655478],
              [-0.375304130674153, 52.238635745116987],
              [-0.374708521548757, 52.239104080736816],
              [-0.375300376393308, 52.2429092226253],
              [-0.37724188095344, 52.248026755099858],
              [-0.378366474971749, 52.252778777171592],
              [-0.378478963102046, 52.265280781502618],
              [-0.381822858809499, 52.265769923649181],
              [-0.381987536851929, 52.268693414922154],
              [-0.382361154690769, 52.271111765852808],
              [-0.381487406975102, 52.271198695097873],
              [-0.381854839975102, 52.271693750117201],
              [-0.381172847774466, 52.272048544462699],
              [-0.381033373186662, 52.273121077421038],
              [-0.378449470375286, 52.272997507405755],
              [-0.378626568747208, 52.27348636195741],
              [-0.377422366247796, 52.273349338577596],
              [-0.376829338278428, 52.274015526135656],
              [-0.37526629724127, 52.273991331875088],
              [-0.374003097472951, 52.272983115574661],
              [-0.372947041454722, 52.273524223228335],
              [-0.372195068995704, 52.273485986288826],
              [-0.372519460859507, 52.275473912302367],
              [-0.373599931275856, 52.27610919068929],
              [-0.374785990459007, 52.276263086180641],
              [-0.374862669730033, 52.276891725548296],
              [-0.374338634593055, 52.277312492037154],
              [-0.375533802386115, 52.277979901142722],
              [-0.375448436776878, 52.279031592907742],
              [-0.375918013554627, 52.279298796201935],
              [-0.375029882299912, 52.280455429879218],
              [-0.375976091336598, 52.280516999242103],
              [-0.376241412826213, 52.28115902080043],
              [-0.377020800120154, 52.281533877569878],
              [-0.378086648310733, 52.281651920227922],
              [-0.380592155794682, 52.282652856570238],
              [-0.384657832395552, 52.281310417010452],
              [-0.386694947980933, 52.28189122189314],
              [-0.390998015034943, 52.282082101974488],
              [-0.392645441893584, 52.282415631254878],
              [-0.396022995241508, 52.281078718673577],
              [-0.398999046643439, 52.278714873367583],
              [-0.400175356751234, 52.279103049451273],
              [-0.401330041898081, 52.280051968113661],
              [-0.402750477890519, 52.282091498399431],
              [-0.402005657918417, 52.283194524315107],
              [-0.402879870186002, 52.2837116527676],
              [-0.403601885625081, 52.285282276060492],
              [-0.406926506315106, 52.287461665787639],
              [-0.407833912089822, 52.287383991881825],
              [-0.408840282663426, 52.286753791331634],
              [-0.410178059776157, 52.284482668718539],
              [-0.411679136968525, 52.28477439965696],
              [-0.413672884660757, 52.284490105866738],
              [-0.414928573751577, 52.284756920100556],
              [-0.416303994414473, 52.284256586062668],
              [-0.419028637543826, 52.284236450315802],
              [-0.419129610973736, 52.284735909846717],
              [-0.421786443739273, 52.284933286516249],
              [-0.423037231168632, 52.287858619207668],
              [-0.422176041445078, 52.288658114095178],
              [-0.422661298467713, 52.289361407004336],
              [-0.424911871528166, 52.289280854404204],
              [-0.426928554472539, 52.290173577068352],
              [-0.427003473960014, 52.290749106135621],
              [-0.431202626269068, 52.292000776748097],
              [-0.432246609794593, 52.292998265210024],
              [-0.43128819008836, 52.294434902104108],
              [-0.432092977768553, 52.294608327717491],
              [-0.432320076907188, 52.295984281146012],
              [-0.43189432664622, 52.296579233539063],
              [-0.434662739820758, 52.296378593362391],
              [-0.435779677559773, 52.296642444648178],
              [-0.435798717107272, 52.298849107377727],
              [-0.433921569912118, 52.302340641624681],
              [-0.434092708921697, 52.30567949715757],
              [-0.433069320645744, 52.308243664353661],
              [-0.433524588622278, 52.311843430909654],
              [-0.435392843585562, 52.312153208619016],
              [-0.43610676913418, 52.314456281194339],
              [-0.440226444370376, 52.315368497192232],
              [-0.441535202903799, 52.315197858348242],
              [-0.442314144520454, 52.314155280959817],
              [-0.443085591070384, 52.313952363536707],
              [-0.443876166267341, 52.314333212002737],
              [-0.444201522934725, 52.314217017645674],
              [-0.447150212648705, 52.315727650918141],
              [-0.457729300160711, 52.320076743163241],
              [-0.46074584490118, 52.321854950091677],
              [-0.465383137982795, 52.322938458820978],
              [-0.46452728066346, 52.326086778540777],
              [-0.464813043058963, 52.330294685692458],
              [-0.465246925447448, 52.332599321256382],
              [-0.467115316820443, 52.335271424973939],
              [-0.466673828236788, 52.338912441410393],
              [-0.467790058426981, 52.338426124083369],
              [-0.472452494111819, 52.339559118831367],
              [-0.483247289399917, 52.340929947914262],
              [-0.480520775900979, 52.344415779036787],
              [-0.478445192088723, 52.346333815428522],
              [-0.477355550222281, 52.348393994412518],
              [-0.478288322687499, 52.349320390972451],
              [-0.480625394081251, 52.349800012189341],
              [-0.48207494950627, 52.350473183189074],
              [-0.484174464130319, 52.351762463678412],
              [-0.484294574917473, 52.352371792536331],
              [-0.48803029571683, 52.353694566070644],
              [-0.493005376543806, 52.357439904634745],
              [-0.496022226409842, 52.358372935233049],
              [-0.498716313904914, 52.360080402105467],
              [-0.4999018400503, 52.365185215216478],
              [-0.49950331310196, 52.366977439626766],
              [-0.4985464924203, 52.366823231389276],
              [-0.492320342184182, 52.371698862614487],
              [-0.488535394339107, 52.374007937615275],
              [-0.490477349165255, 52.375087389521006],
              [-0.495962704827346, 52.379499924081671],
              [-0.494212778997624, 52.381187712844429],
              [-0.489625662115197, 52.379210528494454],
              [-0.486754193838395, 52.379015561059262],
              [-0.485457875544237, 52.381306024622226],
              [-0.484777298165826, 52.381615580284411],
              [-0.478864329044362, 52.380137949953109],
              [-0.477972980494423, 52.380722573244149],
              [-0.476997607602382, 52.380674045880298],
              [-0.475844740267758, 52.38010534495843],
              [-0.474883255173552, 52.380251182985987],
              [-0.469905129322187, 52.379880254764267],
              [-0.468307213833311, 52.38287781196815],
              [-0.464073389357864, 52.381586620097181],
              [-0.462490811152234, 52.383029768204779],
              [-0.456848633918225, 52.382313390516394],
              [-0.456872338493378, 52.384077721255338],
              [-0.454763237806558, 52.384557256800683],
              [-0.454954511146808, 52.385686321082709],
              [-0.450902827274315, 52.38473599621512],
              [-0.448772833671481, 52.38371276522652],
              [-0.446238674548217, 52.38319756601134],
              [-0.443778428529786, 52.383841319366837],
              [-0.439272011741588, 52.386297570328935],
              [-0.437014919385269, 52.388171130778112],
              [-0.432936738838388, 52.39631328362232],
              [-0.431897311937949, 52.396363335954995],
              [-0.424851671981011, 52.399612500346421],
              [-0.424571683460303, 52.399384894843813],
              [-0.422452729197707, 52.400110078903872],
              [-0.421548316007144, 52.400739960274002],
              [-0.421923455271305, 52.400896016444619],
              [-0.416174286611349, 52.403573060511782],
              [-0.415953416043258, 52.403788581351776],
              [-0.420296361314244, 52.405049680760534],
              [-0.419629093192402, 52.407308278395519],
              [-0.418448413835542, 52.408134941055167],
              [-0.417090052001378, 52.411582769267959],
              [-0.413018188820857, 52.412584619055195],
              [-0.410851370283727, 52.412777590894017],
              [-0.407476812476857, 52.413476635493531],
              [-0.400963964958708, 52.415447658993806],
              [-0.397906104616759, 52.418312139184607],
              [-0.392040266840231, 52.420050483107339],
              [-0.388073343983556, 52.422734215130411],
              [-0.386956914837323, 52.423868034054642],
              [-0.379476100119751, 52.427942944729367],
              [-0.372383404966993, 52.430428632603167],
              [-0.365957492567644, 52.431648195174404],
              [-0.362425697684396, 52.433514414513184],
              [-0.364439464672701, 52.434032292013974],
              [-0.366512422097725, 52.435019381627725],
              [-0.367751898620464, 52.434479057021882],
              [-0.36935954773617, 52.43492291242022],
              [-0.37032479326452, 52.436830612908615],
              [-0.371884352518397, 52.436826021032353],
              [-0.371882251277834, 52.437042674052357],
              [-0.371296686346004, 52.438041597336998],
              [-0.370058927427106, 52.437895973130033],
              [-0.368877798160604, 52.439603253388519],
              [-0.370234588749935, 52.439913266347439],
              [-0.368816178324252, 52.441192003578038],
              [-0.367304810476286, 52.443550152745537],
              [-0.363080542277995, 52.44656756292644],
              [-0.363505607857074, 52.44708862679726],
              [-0.361896893569361, 52.448210004244558],
              [-0.361850437706508, 52.448986178636559],
              [-0.36349931690601, 52.450593210149854],
              [-0.360273871703597, 52.452487882808384],
              [-0.355477081101934, 52.456140837655887],
              [-0.354045694033453, 52.458018016181875],
              [-0.353527955177589, 52.460257646677924],
              [-0.350577765101406, 52.462909330364276],
              [-0.349489032714669, 52.462146996105865],
              [-0.344832200257117, 52.465823055656514],
              [-0.341608109204969, 52.466925142170837],
              [-0.343082187565543, 52.468480553358248],
              [-0.342296835653301, 52.46908632868935],
              [-0.348297946181353, 52.471238189568666],
              [-0.349735666131379, 52.472870328893826],
              [-0.349011046974485, 52.473545326607287],
              [-0.347817587038076, 52.477223934663122],
              [-0.347531165947576, 52.478069576369592],
              [-0.34846231583495, 52.479077874296493],
              [-0.34925445588657, 52.479276840429826],
              [-0.350291795972996, 52.480715471757186],
              [-0.349849710415532, 52.481589516809166],
              [-0.350112253275574, 52.482562399066978],
              [-0.351319690477848, 52.483472029262217],
              [-0.352098126976238, 52.483527829256346],
              [-0.353333595255708, 52.484753409627132],
              [-0.35466344917628, 52.485109070553349],
              [-0.355412672979944, 52.486225368768821],
              [-0.35639505109178, 52.486698455398319],
              [-0.356818618440498, 52.487781448638962],
              [-0.358246379848072, 52.48883342438959],
              [-0.358351698952951, 52.489406706672511],
              [-0.359468950108814, 52.49005785975924],
              [-0.358157630577388, 52.491018778145069],
              [-0.357678247479556, 52.492104518216664],
              [-0.358431780107724, 52.492952027207707],
              [-0.358158870039987, 52.493872503824129],
              [-0.359195151942819, 52.494366992504233],
              [-0.358929138780254, 52.494746314990834],
              [-0.359706938492027, 52.495853988222947],
              [-0.359185065675049, 52.496141653299397],
              [-0.359211781121742, 52.497323425762652],
              [-0.358531805727861, 52.497662840782269],
              [-0.358607163521324, 52.498210531789816],
              [-0.357992063199594, 52.498192107508231],
              [-0.357347892079681, 52.49994178286326],
              [-0.356234576683086, 52.500403737835398],
              [-0.355700136975918, 52.501263033209526],
              [-0.355311704933727, 52.501130863401144],
              [-0.354664233243462, 52.501860013419908],
              [-0.353243146246942, 52.50231586242883],
              [-0.353057767372284, 52.502860827079701],
              [-0.352192432463895, 52.503307314829577],
              [-0.354095714314594, 52.504813702513943],
              [-0.354362154850916, 52.506477123910003],
              [-0.355803891032868, 52.505905565697972],
              [-0.358200329260596, 52.50576981096718],
              [-0.362274279171162, 52.507357409204609],
              [-0.368697996278483, 52.508767793775952],
              [-0.371481135843873, 52.510331874883569],
              [-0.373027910042226, 52.510783812446526],
              [-0.375268946972726, 52.510911703145361],
              [-0.377943211864411, 52.510640003358354],
              [-0.379496473884958, 52.511919101948223],
              [-0.381050633238488, 52.512534668041248],
              [-0.380911286935845, 52.512757532529463],
              [-0.383457034745508, 52.513781326353651],
              [-0.387478524449052, 52.516162117351293],
              [-0.386640417292175, 52.516712621154447],
              [-0.390847643851073, 52.51870112913943],
              [-0.390765297716893, 52.519421971915683],
              [-0.394663600356061, 52.519788693543916],
              [-0.396765363300234, 52.520598485007227],
              [-0.398508261123664, 52.5208621367494],
              [-0.40048690737375, 52.521859002048885],
              [-0.404324413282461, 52.521674345161493],
              [-0.406512479822786, 52.522061658928649],
              [-0.40631016031772, 52.522357429075811],
              [-0.408735566138393, 52.522500650336454],
              [-0.411862338124485, 52.524735500890593],
              [-0.411691110191322, 52.525477639990655],
              [-0.413080268476175, 52.525175302978738],
              [-0.413618465952364, 52.525661726459099],
              [-0.41383649175304, 52.52742504292317],
              [-0.413361623721088, 52.528412158983684],
              [-0.412250801124082, 52.528760490414591],
              [-0.411932819899015, 52.529729026458789],
              [-0.410081722805284, 52.531184057572332],
              [-0.407155657173833, 52.53145939416774],
              [-0.404684179044904, 52.532289224066638],
              [-0.404617332405505, 52.533564114727163],
              [-0.402658333015438, 52.534893503912905],
              [-0.401299012134714, 52.538666563414871],
              [-0.402405269696232, 52.54068734054551],
              [-0.402542672831118, 52.541857995023825],
              [-0.403672104149451, 52.542796583016852],
              [-0.4038189420546, 52.543748887035946],
              [-0.403693073358197, 52.545145253650908],
              [-0.402219087374686, 52.546789558551097],
              [-0.402075614264025, 52.548303464473562],
              [-0.403320619726713, 52.548906460519788],
              [-0.411238868159734, 52.54993544303958],
              [-0.41175519456009, 52.550790198638808],
              [-0.414092892345356, 52.551528210231623],
              [-0.415353722073856, 52.551535203805962],
              [-0.41605823559845, 52.552696345632874],
              [-0.416382895765089, 52.555641558779762],
              [-0.417115238861796, 52.55586802911445],
              [-0.419608256329924, 52.558943796720158],
              [-0.416922452862388, 52.560732133551774],
              [-0.417073145108572, 52.561422837953579],
              [-0.416596580330566, 52.561956808130461],
              [-0.415267915713715, 52.562104461095799],
              [-0.413381194740255, 52.561485804653678],
              [-0.411786081801737, 52.561780891890614],
              [-0.409864202382321, 52.561605849605499],
              [-0.408741873591779, 52.562125719551091],
              [-0.407305045581504, 52.564036710614396],
              [-0.407373866957331, 52.56580251694367],
              [-0.411945414036306, 52.568888399581049],
              [-0.414647190005194, 52.569500010728845],
              [-0.415709125417182, 52.570086033743792],
              [-0.414731698846749, 52.572271181377268],
              [-0.415905231525235, 52.573365772055951],
              [-0.415092008960566, 52.575988268672738],
              [-0.415394757927695, 52.578724595593933],
              [-0.414780077403666, 52.579358304821156],
              [-0.414717701617477, 52.580269126990629],
              [-0.413695175724758, 52.581078982250041],
              [-0.40889349387067, 52.582640082981086],
              [-0.403883693609729, 52.583216400165185],
              [-0.401355849391808, 52.583038450862432],
              [-0.398696218316002, 52.583277632842083],
              [-0.396853188553614, 52.582980278162509],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000012",
        LAD13CDO: "12UG",
        LAD13NM: "South Cambridgeshire",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.038144729976511, 52.353311006893712],
            [0.043316364424727, 52.352288346144377],
            [0.044604392420811, 52.352325406399565],
            [0.044150253606433, 52.352762164486222],
            [0.045995774646753, 52.352056746513142],
            [0.045808339590942, 52.351813622478026],
            [0.049569306931678, 52.349474455888689],
            [0.056878682022534, 52.346251601746047],
            [0.059270652103872, 52.344172425665029],
            [0.065237780917015, 52.341350979947833],
            [0.073847464092127, 52.338425314774902],
            [0.076564087392314, 52.337035132889774],
            [0.082184660530502, 52.334847423402692],
            [0.084502893034106, 52.334640189073752],
            [0.086854020242584, 52.335630919607539],
            [0.087937561895221, 52.335231423047908],
            [0.089027531230722, 52.335249017065706],
            [0.089468568657011, 52.334827598439979],
            [0.091016058428671, 52.334898223310887],
            [0.09213678166736, 52.334323590675297],
            [0.093529352938583, 52.334128973805832],
            [0.096480165277887, 52.331284712686617],
            [0.099718127093029, 52.331071639243746],
            [0.102595300402624, 52.330276841729031],
            [0.104771128713957, 52.328746362315812],
            [0.107952572301293, 52.32929386916355],
            [0.110261472209324, 52.328189817066082],
            [0.11540760350371, 52.328357620686681],
            [0.120007406884223, 52.32811779087006],
            [0.123026164441817, 52.326919816668074],
            [0.125891479947413, 52.326656970817666],
            [0.128255074829322, 52.32538972589888],
            [0.130552774402949, 52.325105521730087],
            [0.134368300759233, 52.322879504720788],
            [0.138426830838872, 52.321105743479109],
            [0.140290658645088, 52.320654762114124],
            [0.142827884569914, 52.321234581891339],
            [0.142141961818428, 52.322648844454385],
            [0.143151195825346, 52.324068320851012],
            [0.147325991847982, 52.324124669040621],
            [0.149025112223803, 52.325512668437256],
            [0.151883190980064, 52.324621694146693],
            [0.162550523743776, 52.324170583636274],
            [0.16289457469244, 52.323462022750114],
            [0.162129600199343, 52.32224954660439],
            [0.16230048948005, 52.321510887527609],
            [0.164401756394685, 52.320307056867577],
            [0.166185765563345, 52.317927481715046],
            [0.167543507039932, 52.317547392186782],
            [0.169205938740959, 52.317787521970487],
            [0.169896909682234, 52.318774719552756],
            [0.170764396510343, 52.319260521368626],
            [0.17533165693396, 52.319817599162789],
            [0.177667776461365, 52.320984846674499],
            [0.181575162984504, 52.321187882411849],
            [0.189459043778475, 52.323080437270001],
            [0.192688374929607, 52.323098753591488],
            [0.195871305479973, 52.324165396293481],
            [0.19726349692296, 52.324274380756989],
            [0.199984499383029, 52.323391977361545],
            [0.198579003787299, 52.318397090530645],
            [0.203253520965033, 52.316883859359478],
            [0.20585971813016, 52.316831609936095],
            [0.238925558465542, 52.320309655490064],
            [0.239849509916893, 52.321955642478763],
            [0.238440722932958, 52.323698912854617],
            [0.239277226580938, 52.324687453089474],
            [0.239289752464028, 52.325574718794741],
            [0.243752779249979, 52.325346139966271],
            [0.244430171269715, 52.324522195765994],
            [0.248791394116766, 52.322838665133929],
            [0.24798411481406, 52.322806377300303],
            [0.248479458463224, 52.321693635629309],
            [0.247927138373611, 52.321393028973802],
            [0.247727999504102, 52.32047514496977],
            [0.248533812942402, 52.320045276032239],
            [0.248998243499097, 52.319076092003094],
            [0.250248600822511, 52.319195245057237],
            [0.248005810547426, 52.31159749741807],
            [0.246874736619282, 52.31113525735401],
            [0.247298876168496, 52.310530120114585],
            [0.247230888057907, 52.309188015292094],
            [0.246166570741489, 52.307029513825661],
            [0.244751779932257, 52.305740894516838],
            [0.245911724156504, 52.304052626494233],
            [0.247076486786319, 52.3038515257618],
            [0.246009806463004, 52.301150851533066],
            [0.244030540510232, 52.299601402889579],
            [0.243108195531538, 52.297342162903782],
            [0.241214260212991, 52.295531179153478],
            [0.241085156220882, 52.294542714819087],
            [0.241933699760313, 52.292614911711063],
            [0.241657951533828, 52.29113287511391],
            [0.240441389155306, 52.290417726792114],
            [0.236447710079187, 52.289792972480427],
            [0.230286167542338, 52.286439532581454],
            [0.229795353772517, 52.28568808366137],
            [0.229736540861209, 52.283319811623599],
            [0.228923717032083, 52.282023233020567],
            [0.22644942917151, 52.279811141745604],
            [0.224164971691135, 52.278428079243533],
            [0.222623297816757, 52.276359290663123],
            [0.220927564016645, 52.274900338632072],
            [0.219628121501427, 52.274329511398491],
            [0.21647121019802, 52.274281824825444],
            [0.215150016896131, 52.27391997057893],
            [0.213945029327383, 52.271448207610348],
            [0.211552257271933, 52.269904182396147],
            [0.214573834089847, 52.267125665833596],
            [0.216017327844422, 52.266564451931366],
            [0.216040004550151, 52.2660200121299],
            [0.216969855112273, 52.266062797468031],
            [0.217897294563534, 52.265058955271783],
            [0.220589142515864, 52.263526466498618],
            [0.21933052470877, 52.262592492829178],
            [0.218484699058367, 52.260415250146373],
            [0.218735054195363, 52.259764920048674],
            [0.210440690377988, 52.249445795289525],
            [0.215249162119722, 52.249655969146474],
            [0.218120287724392, 52.249208192994807],
            [0.217731291351457, 52.248135564995479],
            [0.219010298546501, 52.247080141394719],
            [0.220770698938965, 52.243374694130573],
            [0.222157909616201, 52.242876500741446],
            [0.222002070887066, 52.2416924899726],
            [0.226224271671112, 52.239716498347711],
            [0.229469140478514, 52.236570974202905],
            [0.22844444025151, 52.235821511346678],
            [0.227659965415656, 52.234340949234323],
            [0.225265890873922, 52.231685815494686],
            [0.230409065246333, 52.228910882818461],
            [0.231355669460785, 52.229682486941243],
            [0.230867435315048, 52.230488413172793],
            [0.232828041797241, 52.231475522788941],
            [0.234977306292079, 52.228811854353154],
            [0.239750680380966, 52.226010239423523],
            [0.242530784877844, 52.225476358753184],
            [0.244544227693796, 52.222749446322396],
            [0.247855408500854, 52.22122699596629],
            [0.249662504270776, 52.219235877134238],
            [0.249247785027382, 52.218619733678779],
            [0.254378884845627, 52.214508631708611],
            [0.257291498601483, 52.213112218415624],
            [0.260774600449615, 52.211970966922237],
            [0.268961097060876, 52.208081174014595],
            [0.273402483537905, 52.205483266012443],
            [0.278130266372082, 52.204059424638004],
            [0.27798710350175, 52.203549637040091],
            [0.280597737163597, 52.202628755105593],
            [0.287511196577894, 52.201172147771658],
            [0.296692915761, 52.196573183513792],
            [0.304143492897268, 52.193618718395165],
            [0.310962107127694, 52.190459444620963],
            [0.310350185027801, 52.185712751988461],
            [0.318082960948657, 52.181822953699232],
            [0.321212668753523, 52.17975792690649],
            [0.321910136984719, 52.178826089275383],
            [0.324931516572541, 52.178897853019649],
            [0.330630694725055, 52.177365486785874],
            [0.334415358820002, 52.175730474527086],
            [0.3342478311188, 52.175436146435281],
            [0.338422034963826, 52.174387681272734],
            [0.339843947970787, 52.173264218160746],
            [0.341930302256268, 52.17235954486501],
            [0.346768505200638, 52.171250831631376],
            [0.351656947414051, 52.168686860158232],
            [0.361634185302983, 52.167290591437414],
            [0.364048375822804, 52.167949014229286],
            [0.36899384566973, 52.167309326635866],
            [0.369696354872094, 52.16743009643298],
            [0.370927526787805, 52.16632716748763],
            [0.372686185062624, 52.166756698452332],
            [0.37740489629933, 52.166586122586516],
            [0.376070307753765, 52.164510610792725],
            [0.376177334449582, 52.164131674652531],
            [0.376761895998068, 52.164564115737505],
            [0.377383276046413, 52.164397820287952],
            [0.37741629904049, 52.163782976808278],
            [0.376370203752602, 52.163799567130901],
            [0.376311480636209, 52.163218945408538],
            [0.383641851724538, 52.161634911162473],
            [0.387103325164477, 52.161971341630803],
            [0.388880264273772, 52.161786981035725],
            [0.391566181098047, 52.159112155501809],
            [0.39393164320924, 52.159434639845585],
            [0.394884495849784, 52.157955825864725],
            [0.397122179633297, 52.158268251803769],
            [0.398574708350042, 52.158878971196607],
            [0.399154674543511, 52.15827187535664],
            [0.402281659135675, 52.159404197012314],
            [0.405416893515983, 52.159574980579528],
            [0.406806145770854, 52.159118592646209],
            [0.409140860832056, 52.156677132746424],
            [0.409826767506401, 52.156410429117919],
            [0.41255162385748, 52.157106472222786],
            [0.417246325579715, 52.159292596011959],
            [0.420446833220479, 52.156731540021305],
            [0.420856101005514, 52.155625161560856],
            [0.419757941536118, 52.152818684318312],
            [0.419426603778402, 52.150617840843054],
            [0.419658696703812, 52.146757119248242],
            [0.418422416895581, 52.145522644595616],
            [0.41855308415612, 52.142431958518955],
            [0.419661647413288, 52.139425515972164],
            [0.418252129815727, 52.134342228170738],
            [0.417280001742457, 52.133094230108284],
            [0.413715644704541, 52.134088093555192],
            [0.411700237084429, 52.133947695349626],
            [0.409971756219664, 52.134385006826115],
            [0.406815821876626, 52.130870492137468],
            [0.404675301680652, 52.129272157175009],
            [0.405544305975096, 52.128234683601541],
            [0.406509368798472, 52.127868776302506],
            [0.406868325670034, 52.126830013838173],
            [0.407700796278708, 52.126200627156585],
            [0.409407035736064, 52.125816856225448],
            [0.409158918422753, 52.124250945951076],
            [0.408545819205167, 52.123691555173679],
            [0.405764678018789, 52.12275198723561],
            [0.396331634755905, 52.122346140916221],
            [0.39484907292125, 52.121533680937816],
            [0.395197115320908, 52.119660678470844],
            [0.39830953213137, 52.11877907805777],
            [0.389558823714542, 52.117457768957443],
            [0.389291843221732, 52.116207837756889],
            [0.388138594128477, 52.114390459147479],
            [0.388550681859522, 52.113519734325017],
            [0.38546335201135, 52.110326999728798],
            [0.3852002513493, 52.106602262143426],
            [0.383663880274663, 52.105396885595525],
            [0.381772969191141, 52.102688824965881],
            [0.382556085397992, 52.101135288443054],
            [0.383973412049908, 52.100714555551484],
            [0.38588942705294, 52.099542732630134],
            [0.388657086313494, 52.099199808416913],
            [0.388181480664243, 52.098250852007553],
            [0.386270031727011, 52.097673589336388],
            [0.386833726879144, 52.097292583933935],
            [0.389162411257602, 52.097125796403247],
            [0.392341225667729, 52.096065841657698],
            [0.394966340406244, 52.095955867733686],
            [0.394636459371699, 52.094867288948784],
            [0.392691370424781, 52.095297071182117],
            [0.39244662759367, 52.095007087346758],
            [0.390953749310631, 52.090352311865914],
            [0.390819582623268, 52.088471111767298],
            [0.393843578572171, 52.0881579287989],
            [0.393351735784712, 52.086496218333053],
            [0.394189895297724, 52.084320096979994],
            [0.395327616701596, 52.084059572906753],
            [0.397152320672204, 52.083000019668297],
            [0.399477726753839, 52.084146891090455],
            [0.401681039041835, 52.082583170659767],
            [0.400990671708597, 52.082012728024829],
            [0.400491004595533, 52.082275597712815],
            [0.399935455025711, 52.081850778485048],
            [0.400589894573181, 52.081507422505148],
            [0.400206321148093, 52.081023347199476],
            [0.400574894381457, 52.080112992029804],
            [0.402965979898254, 52.078191114316269],
            [0.40163329852823, 52.076420684876695],
            [0.403648891223171, 52.075119732761813],
            [0.400878406260214, 52.074020683339825],
            [0.400863437749953, 52.073007532998609],
            [0.401940983700794, 52.072474795946079],
            [0.407062470669241, 52.070782195475452],
            [0.405677957581387, 52.069349189598498],
            [0.406172811981015, 52.069162830723357],
            [0.403295912806532, 52.066239632749564],
            [0.404608237033184, 52.065490755397548],
            [0.403539258550401, 52.063788702825832],
            [0.402361003721805, 52.063800146008482],
            [0.40219000355608, 52.063441234336814],
            [0.400829222246666, 52.06350404426199],
            [0.4006915995178, 52.063277539572788],
            [0.398984908331876, 52.063501148568143],
            [0.395985977083408, 52.060707954923622],
            [0.392441674200102, 52.061132492707728],
            [0.390389972802248, 52.060276688858991],
            [0.38226737448331, 52.050991904822133],
            [0.379582902242741, 52.049551609655438],
            [0.383064153544232, 52.045984046936098],
            [0.386216809008389, 52.044224234195241],
            [0.388159436450362, 52.043706461856956],
            [0.387707597167556, 52.040784059085055],
            [0.389665512693383, 52.036455814538385],
            [0.386324546655318, 52.036064033306921],
            [0.380810405878583, 52.03697595788622],
            [0.377477493488811, 52.036322980090389],
            [0.374832377306868, 52.037310664651741],
            [0.374557120273161, 52.037096797002917],
            [0.371692656841808, 52.037358640527877],
            [0.368545592307138, 52.039308593738319],
            [0.366997389610692, 52.039729990378945],
            [0.366185188750675, 52.041303808887761],
            [0.36380592251066, 52.043608694488967],
            [0.364612776415309, 52.043947703129589],
            [0.363294087448289, 52.04468727175135],
            [0.352210287396899, 52.046959408016434],
            [0.345929765904996, 52.049705952968239],
            [0.343827337088427, 52.051002249544247],
            [0.343277525725486, 52.052146235372035],
            [0.344437651584698, 52.052267931010711],
            [0.344028237059665, 52.053223881673802],
            [0.341319307580664, 52.054809164515],
            [0.341511679025159, 52.055670413251285],
            [0.341098012085736, 52.055960997035548],
            [0.341743408552493, 52.056279990377938],
            [0.33956318506457, 52.057463548744046],
            [0.337725526222355, 52.059705870672957],
            [0.335603091580375, 52.060268628629771],
            [0.33193692027462, 52.06281596546556],
            [0.329975376975006, 52.065604652441017],
            [0.328564018758206, 52.066829443987821],
            [0.327615073880266, 52.068614302009387],
            [0.327301308023667, 52.06847302499294],
            [0.325029684750854, 52.069909896859443],
            [0.325684898408768, 52.071146008348286],
            [0.324235061050816, 52.071916489553999],
            [0.323446920687357, 52.07196980215739],
            [0.324644303669633, 52.072771685025124],
            [0.321918398916339, 52.074290293787328],
            [0.316739265605597, 52.076093598153662],
            [0.31205142508227, 52.076539077077804],
            [0.312068259749904, 52.076893045778455],
            [0.311035566783096, 52.076932188919855],
            [0.31025173500974, 52.078851241900175],
            [0.308672969733545, 52.080272402933431],
            [0.302207955942498, 52.082694637181191],
            [0.291829108887357, 52.088311230268005],
            [0.285982746073213, 52.090395899782862],
            [0.276200082179274, 52.092626508563576],
            [0.275238213298081, 52.09111009980694],
            [0.271938625413258, 52.090127056513367],
            [0.268574828242612, 52.087242391416126],
            [0.264777294817861, 52.084776916263849],
            [0.261912131636804, 52.083422857584907],
            [0.259699745548379, 52.082765704548308],
            [0.254021331442347, 52.080030204306993],
            [0.252311099953527, 52.07818082452895],
            [0.251981410932871, 52.077081976052128],
            [0.25154418188089, 52.076774702776689],
            [0.24749151042791, 52.079099237368808],
            [0.246321589521194, 52.082045827256636],
            [0.244084131939317, 52.083006566705158],
            [0.235178949888215, 52.088988394641895],
            [0.231156205750339, 52.08969857635347],
            [0.229153471636503, 52.089788631080204],
            [0.224164317636944, 52.089239954770648],
            [0.214748164495045, 52.09017173675079],
            [0.213097922880347, 52.090423946303581],
            [0.212989691535855, 52.091039246290791],
            [0.21155671250052, 52.091080533579017],
            [0.210113726398921, 52.091628251252068],
            [0.207881245734737, 52.091709662489869],
            [0.203430376241595, 52.092662506682743],
            [0.200563312101454, 52.087385514364499],
            [0.199248232221624, 52.085874199602131],
            [0.193941434580704, 52.075470252407634],
            [0.193562761595373, 52.075128405476633],
            [0.185759077886917, 52.073888622438396],
            [0.184533980698764, 52.073843000537018],
            [0.184434857569499, 52.073546298002825],
            [0.183166026072445, 52.073172359209593],
            [0.186835502427321, 52.065666881251268],
            [0.189888260128747, 52.062188652490697],
            [0.188336670388266, 52.060889333794321],
            [0.18989408575547, 52.060190477904364],
            [0.186345474668759, 52.056650511601113],
            [0.181829771574615, 52.056929355068675],
            [0.18149499058774, 52.056674780843892],
            [0.177969652438952, 52.057311906730163],
            [0.177701059280629, 52.056948192069619],
            [0.176684388403089, 52.056919321113597],
            [0.17625706514662, 52.056110724939479],
            [0.175450068488645, 52.056133721766841],
            [0.174079243151249, 52.055570038007524],
            [0.171187860137269, 52.055503762130769],
            [0.167542529391244, 52.054989091007236],
            [0.167257557643789, 52.051753553673478],
            [0.166336171976899, 52.051551090765876],
            [0.166368542890316, 52.048180227085048],
            [0.164818055319001, 52.048055861766052],
            [0.164669162731051, 52.048385013762974],
            [0.160729158444082, 52.048429467799636],
            [0.161397023013156, 52.049757050221665],
            [0.160549806364531, 52.052088074032113],
            [0.152606974544685, 52.05030734896971],
            [0.151089340380194, 52.052841182312406],
            [0.143018256046947, 52.05090479139205],
            [0.136589468785384, 52.049994654571421],
            [0.134217636255134, 52.048916326487486],
            [0.133736666090256, 52.049137252942906],
            [0.132539178434331, 52.048338852853],
            [0.13166535361289, 52.048701763401205],
            [0.131202396588785, 52.048351353277383],
            [0.130396657476857, 52.048428871371122],
            [0.128107818460775, 52.047312053025593],
            [0.128236587775616, 52.049991178642685],
            [0.127565380073171, 52.051888050530145],
            [0.124910069646766, 52.054115660634018],
            [0.122870631018607, 52.057555039551865],
            [0.121932976006456, 52.057376801711413],
            [0.119049898623789, 52.059951873470688],
            [0.114541727484701, 52.060590376391758],
            [0.10951096806255, 52.059986365256684],
            [0.105708625300249, 52.060250408266604],
            [0.104943954982763, 52.057731902330659],
            [0.105948945487772, 52.049728969918185],
            [0.104946216683891, 52.049650674413762],
            [0.104713243341009, 52.048665706619389],
            [0.10301965193285, 52.047508994547748],
            [0.101255469108394, 52.045001107085959],
            [0.100437257638106, 52.041733584029544],
            [0.101128646982603, 52.038846480840462],
            [0.096313979750396, 52.034390349460459],
            [0.094812656977069, 52.033743549180137],
            [0.094167959136732, 52.032863895346743],
            [0.093611201685406, 52.030651844319586],
            [0.092952300689325, 52.030215746898087],
            [0.094673409100371, 52.029017978599008],
            [0.091168887404782, 52.025174976820438],
            [0.091709574861685, 52.024954061925634],
            [0.090772945169757, 52.022530233586878],
            [0.092227050365567, 52.021643868277131],
            [0.0895142397128, 52.019948455426032],
            [0.085183217998952, 52.0162017664411],
            [0.08539587336246, 52.014820410194268],
            [0.083220473228738, 52.013573935905249],
            [0.082885735093134, 52.010947865563807],
            [0.080493558195713, 52.008132455275877],
            [0.076001362665191, 52.007189324189021],
            [0.071432458316836, 52.00827239323889],
            [0.070037651961041, 52.007349150397545],
            [0.069741767664361, 52.006020822354458],
            [0.068098740708988, 52.005779024134291],
            [0.065067208219264, 52.00759467086106],
            [0.063928294379492, 52.009600988178605],
            [0.062421160282105, 52.010922250601318],
            [0.062116716511907, 52.01200213538695],
            [0.059013194694333, 52.014517564601775],
            [0.058962471111031, 52.016364523969692],
            [0.060138087242603, 52.018637844368342],
            [0.058258530992387, 52.020554542538029],
            [0.054367910346835, 52.022482922499847],
            [0.052069536160517, 52.024620832663629],
            [0.050427616303262, 52.028462952830594],
            [0.049637834210552, 52.03382516289777],
            [0.047611811638534, 52.036739645335516],
            [0.043005303476468, 52.041490978352705],
            [0.04333577732291, 52.041862911567911],
            [0.041596182934743, 52.045255184472452],
            [0.041540538456282, 52.049025598052815],
            [0.040277608328789, 52.053316877131344],
            [0.033619811827544, 52.051819717194341],
            [0.024827341986119, 52.05142084213584],
            [0.01126104502063, 52.049649799737026],
            [0.007157135952598, 52.049522861664144],
            [0.002288748315145, 52.050139820842773],
            [-0.001412290079274, 52.051654859211915],
            [-0.003697883262198, 52.053517172511143],
            [-0.006164086413691, 52.056929108156019],
            [-0.011605677768075, 52.06062412588058],
            [-0.01258794392727, 52.061631606571581],
            [-0.013326523744452, 52.061457928875399],
            [-0.016301341426714, 52.062998905888605],
            [-0.019309588823865, 52.063525182165428],
            [-0.022001122707031, 52.063593772575523],
            [-0.024441431248424, 52.063305607421256],
            [-0.028826463458418, 52.061889086652187],
            [-0.030175202017152, 52.061937717779223],
            [-0.030660226768363, 52.061225574491047],
            [-0.035753646539027, 52.058782072262694],
            [-0.051000613519946, 52.049491277950224],
            [-0.0542409036602, 52.046611683341581],
            [-0.05689999949413, 52.045489321349969],
            [-0.066783836088023, 52.042526341597735],
            [-0.079116797022558, 52.037876958767896],
            [-0.107722091124722, 52.028345466140578],
            [-0.112263744067788, 52.025902416778635],
            [-0.112431335188443, 52.026111015299762],
            [-0.121026738300019, 52.019511751745625],
            [-0.126595364194867, 52.02091235427622],
            [-0.122366637003971, 52.029626439793077],
            [-0.122553080988774, 52.029943225690971],
            [-0.123130000664469, 52.029986587491173],
            [-0.123775127579901, 52.033624160991224],
            [-0.124705135979497, 52.034846568430766],
            [-0.126856687970493, 52.036075807702701],
            [-0.131600697507182, 52.04261983468853],
            [-0.131330613466895, 52.043291733748006],
            [-0.133757448561666, 52.046222855753463],
            [-0.136748099806527, 52.046020216578931],
            [-0.141701142236131, 52.047234100548273],
            [-0.144603520245459, 52.046980414727365],
            [-0.146062193570054, 52.047535688094598],
            [-0.149053343946682, 52.05021550161846],
            [-0.152166140752458, 52.051016069610995],
            [-0.154557512433701, 52.052337569201221],
            [-0.155147989352171, 52.053050869325446],
            [-0.154972622441958, 52.054262908088013],
            [-0.152667260422093, 52.055839007735209],
            [-0.152086166633357, 52.057407947151646],
            [-0.150238216837761, 52.059169208831072],
            [-0.149960726432261, 52.061224860395406],
            [-0.15191214287464, 52.064663348304343],
            [-0.150682348326485, 52.065827367516327],
            [-0.149041205122559, 52.066211624805653],
            [-0.147954125271681, 52.067626029296527],
            [-0.148035722824743, 52.068862775642039],
            [-0.14753231402104, 52.069392569054443],
            [-0.148632953976363, 52.070473586619435],
            [-0.148002817372681, 52.071138964510972],
            [-0.148110217834569, 52.071717021998758],
            [-0.150040299904686, 52.074266816206929],
            [-0.152137824265408, 52.075170121098736],
            [-0.153517133171204, 52.076223089287332],
            [-0.156389880912599, 52.076768001829329],
            [-0.157188134129441, 52.079424947256058],
            [-0.157916890011445, 52.079908404139417],
            [-0.157195053084283, 52.080213627960326],
            [-0.157307268453173, 52.080536386295996],
            [-0.156256004961571, 52.0809731274258],
            [-0.156712465909759, 52.0812293373721],
            [-0.156302833590413, 52.081359604087666],
            [-0.156694476667452, 52.082126427983518],
            [-0.155860529056033, 52.082848903723502],
            [-0.156255860727811, 52.083384699866855],
            [-0.155511422600041, 52.083351472075115],
            [-0.155724932136558, 52.083730666319589],
            [-0.15483251610298, 52.084241814166894],
            [-0.154893269371759, 52.08478586400819],
            [-0.154354017462097, 52.084943767521231],
            [-0.154114103657043, 52.086124217449992],
            [-0.150476862050813, 52.088805172094041],
            [-0.149585792517601, 52.089809047125001],
            [-0.149712967482611, 52.090545665442768],
            [-0.149137445448186, 52.091119291805327],
            [-0.149774648873803, 52.092159744340115],
            [-0.148913234816036, 52.092692015672114],
            [-0.149316699322711, 52.093137145858876],
            [-0.148614641613331, 52.093451620397872],
            [-0.1490194495786, 52.094499216208035],
            [-0.148700060107158, 52.09466774010631],
            [-0.149163691223684, 52.095105723002135],
            [-0.148513677019081, 52.095431804233968],
            [-0.150361160633838, 52.097002883495819],
            [-0.147511392192775, 52.098833799416724],
            [-0.148530147735662, 52.099652762803707],
            [-0.147194514867023, 52.1003852807371],
            [-0.148495132161887, 52.101520685403209],
            [-0.146963074953085, 52.102974845475984],
            [-0.147852116203383, 52.103789976275124],
            [-0.146258942610875, 52.105975989214393],
            [-0.147691370186896, 52.107105381015671],
            [-0.145922826013587, 52.107883234015475],
            [-0.147074803000215, 52.109015414940316],
            [-0.14549593893478, 52.109830411340745],
            [-0.146997125492343, 52.111100262829758],
            [-0.145790359764976, 52.1117098069818],
            [-0.146784677243798, 52.112772072194645],
            [-0.146882689717719, 52.113613434995415],
            [-0.147885584800496, 52.114188476562688],
            [-0.145818504700763, 52.11560724047191],
            [-0.143957443275697, 52.117724268184176],
            [-0.145630777391005, 52.117878280832798],
            [-0.144159363040786, 52.129776272003348],
            [-0.14441760561073, 52.137450210942468],
            [-0.144470072021602, 52.138191947753072],
            [-0.14486015066955, 52.138334758113174],
            [-0.148383566805942, 52.138928735309861],
            [-0.149631916151033, 52.138743321264649],
            [-0.151368617934609, 52.141550771806948],
            [-0.150442172123404, 52.143594428157535],
            [-0.152730306493864, 52.143148342153076],
            [-0.154376935725455, 52.144396085781146],
            [-0.156490749594401, 52.143959786729376],
            [-0.159697645275078, 52.144056631780266],
            [-0.160111959958067, 52.14301107782866],
            [-0.162681086264623, 52.143049335156931],
            [-0.16273660914689, 52.142588031385749],
            [-0.163969826872492, 52.14238064969561],
            [-0.163775067797502, 52.140116226798654],
            [-0.166130237215307, 52.139880430677607],
            [-0.169157678959701, 52.14024847317274],
            [-0.171559039186595, 52.139490873610967],
            [-0.174499123624409, 52.140315995893843],
            [-0.17604936854571, 52.13975013513155],
            [-0.181703383930409, 52.139748434783975],
            [-0.190807018375183, 52.141456585915179],
            [-0.198001407566608, 52.14192910115829],
            [-0.203440812632856, 52.143344642651918],
            [-0.204161117223065, 52.143967039816594],
            [-0.206227750540636, 52.144437280515604],
            [-0.2078026390389, 52.144525064851173],
            [-0.209343132642768, 52.143830939962626],
            [-0.213630650406377, 52.143367261182327],
            [-0.217232984873948, 52.144066467267095],
            [-0.21919145230973, 52.143881159608256],
            [-0.232230412905144, 52.144774176718585],
            [-0.234826912066511, 52.144715061481527],
            [-0.227395825986644, 52.148011389440875],
            [-0.224826565726079, 52.149732386373678],
            [-0.222646034916604, 52.153138803783563],
            [-0.219544657316363, 52.156826205639867],
            [-0.218397778015232, 52.15790675197966],
            [-0.21664735216043, 52.1587237030758],
            [-0.213453791907797, 52.1597984144368],
            [-0.211122992874674, 52.161083942016212],
            [-0.203513770839234, 52.162845722193644],
            [-0.202280614260038, 52.163622684341242],
            [-0.198137772465139, 52.164905502254413],
            [-0.193497897940119, 52.167442976749385],
            [-0.191878230564975, 52.166889457192141],
            [-0.190512491858297, 52.168763930807181],
            [-0.189557724343143, 52.169193462512801],
            [-0.186913248999325, 52.167590113285399],
            [-0.183776716888896, 52.168918465626881],
            [-0.182066788323119, 52.167854499264962],
            [-0.17956926226261, 52.167889710979971],
            [-0.17878072922342, 52.16700355915647],
            [-0.168679178379617, 52.16761863207207],
            [-0.156214677070406, 52.167233828462969],
            [-0.1556275958254, 52.169372739566612],
            [-0.151622320302781, 52.169654421223008],
            [-0.150615054499127, 52.171269706831019],
            [-0.15089710685413, 52.172367505564615],
            [-0.152025417533175, 52.172595590751961],
            [-0.15341205749867, 52.174531613894537],
            [-0.15330794503517, 52.175421049473684],
            [-0.150249598061059, 52.177294631450742],
            [-0.144959908169352, 52.1827314979395],
            [-0.143043668343079, 52.183364028935586],
            [-0.141037972626489, 52.183404372496831],
            [-0.137824179850046, 52.184456053524066],
            [-0.133874380640114, 52.183062883021805],
            [-0.116074172232101, 52.179524991195372],
            [-0.110986667296967, 52.178912225958292],
            [-0.108935860652433, 52.179088871849935],
            [-0.107957068851511, 52.179646842597087],
            [-0.106103405516815, 52.179279010974838],
            [-0.104307758584108, 52.180883947716978],
            [-0.100565378181234, 52.182652646362193],
            [-0.101243986880597, 52.185402422381124],
            [-0.104544802592132, 52.193539870671458],
            [-0.107129646702224, 52.193792674175384],
            [-0.106675233363537, 52.195086468660563],
            [-0.10575430943038, 52.19613269610975],
            [-0.104872117658509, 52.196060982289467],
            [-0.104438641299156, 52.196998137653694],
            [-0.107283611814622, 52.197573418321468],
            [-0.103974582939332, 52.200372424927735],
            [-0.105740744089194, 52.200994240073079],
            [-0.104666730545559, 52.202849043635034],
            [-0.107152076721517, 52.202941992332455],
            [-0.10725551624226, 52.205673500580971],
            [-0.108151578969476, 52.206870265923108],
            [-0.109555842202275, 52.206508832310384],
            [-0.10991784042296, 52.206945328838948],
            [-0.111500548091444, 52.207000343540045],
            [-0.115100525691016, 52.208116216789385],
            [-0.116874923889092, 52.208173321920206],
            [-0.13048944886741, 52.207194839519566],
            [-0.137255285052405, 52.207760414344335],
            [-0.137659098574842, 52.208008661357603],
            [-0.140269560213155, 52.207882591431208],
            [-0.141127065163445, 52.209604497679493],
            [-0.147023451603222, 52.210217850158507],
            [-0.152831190328294, 52.211984031415852],
            [-0.156334957252428, 52.209059035897141],
            [-0.159342757018229, 52.205693709415847],
            [-0.166672281240772, 52.202523217317754],
            [-0.168292873539887, 52.200800434860554],
            [-0.17217362457907, 52.199268219465665],
            [-0.172613901446039, 52.198309349886649],
            [-0.173162621914182, 52.198273788854372],
            [-0.173156342754827, 52.197677553702391],
            [-0.175908919281479, 52.197243591105988],
            [-0.17586280300123, 52.199549202578183],
            [-0.178753208588669, 52.201616045194847],
            [-0.183596909625479, 52.201976641302046],
            [-0.189074620540583, 52.200566453894425],
            [-0.191904857879281, 52.201356153257514],
            [-0.19253239390075, 52.20330972869106],
            [-0.194755149712645, 52.204361593545343],
            [-0.195631458663117, 52.204477500369244],
            [-0.193501763766078, 52.209573656453557],
            [-0.19294850568026, 52.209718040256099],
            [-0.191905911831748, 52.210919515888186],
            [-0.189830417125875, 52.213907933909276],
            [-0.188862460338551, 52.216544668645689],
            [-0.188733573144592, 52.220828027144222],
            [-0.187228264903561, 52.224969762673673],
            [-0.187039337158978, 52.228781040032352],
            [-0.182766472975758, 52.228676661269027],
            [-0.180268703324322, 52.230209883504884],
            [-0.175657248246942, 52.230467778123931],
            [-0.17128710355737, 52.229270813970757],
            [-0.165939842394018, 52.228588104004828],
            [-0.162137228248168, 52.227557866532109],
            [-0.158605311318676, 52.227137756368329],
            [-0.155775925393773, 52.226443489857765],
            [-0.153263493926847, 52.226122766979913],
            [-0.15290746474099, 52.2265164181348],
            [-0.1490646306674, 52.225936500403179],
            [-0.150024336427881, 52.227857748981286],
            [-0.148197364804855, 52.229149948944205],
            [-0.147454368354378, 52.228847862461869],
            [-0.144650835698765, 52.229396362138473],
            [-0.140198554545899, 52.229135663613782],
            [-0.140200028357635, 52.229871193198427],
            [-0.13761212654869, 52.230127103199706],
            [-0.137899546112067, 52.231376964218363],
            [-0.136934255533914, 52.23547534993272],
            [-0.136769023580597, 52.235818914915029],
            [-0.134546450860616, 52.235666017544744],
            [-0.134672274812594, 52.237275689595073],
            [-0.133763119246583, 52.239502003231188],
            [-0.134057318752562, 52.240938103635131],
            [-0.133535244966707, 52.241023360670894],
            [-0.132243715421966, 52.243248110727656],
            [-0.134713075319503, 52.244440770924456],
            [-0.139935173281007, 52.245488867566664],
            [-0.141786549164583, 52.245422718363336],
            [-0.143106935601559, 52.24646133488995],
            [-0.145196014116276, 52.247369952100222],
            [-0.145245037960844, 52.247950673476545],
            [-0.14597362028137, 52.247797575416662],
            [-0.146339326455211, 52.248442613760979],
            [-0.147800551788265, 52.248461055942684],
            [-0.148784128315717, 52.250135414131847],
            [-0.148733295327476, 52.25167305914799],
            [-0.151455196464856, 52.251887452942142],
            [-0.152202252308999, 52.252912488589018],
            [-0.155930135122294, 52.253742252281874],
            [-0.157145254167408, 52.254294422313031],
            [-0.162237105933845, 52.254528456750151],
            [-0.170121615810904, 52.255846354200614],
            [-0.173707984630724, 52.255765672456612],
            [-0.176175138945023, 52.252984099779184],
            [-0.177098781785218, 52.250252379920788],
            [-0.177519654856158, 52.250277760691318],
            [-0.177644141755779, 52.251279531175435],
            [-0.180361471498136, 52.255044804409643],
            [-0.180150631003044, 52.255757272390824],
            [-0.182437792812371, 52.256114402816301],
            [-0.184146839128779, 52.260761397644458],
            [-0.183126123750797, 52.263972713980529],
            [-0.180941160478932, 52.267087862606552],
            [-0.17899154084431, 52.266777274610838],
            [-0.177956910812799, 52.269957760975153],
            [-0.179844811397855, 52.270454435019765],
            [-0.175498976708, 52.280203281673927],
            [-0.17088702015985, 52.281699129561844],
            [-0.1639200434936, 52.282206909999275],
            [-0.159836691275293, 52.283349991828871],
            [-0.154975001676472, 52.283707511418164],
            [-0.151458509911719, 52.284325969687252],
            [-0.148030469245555, 52.284578860532235],
            [-0.144207745912579, 52.283539673623416],
            [-0.143967871941923, 52.283105213240233],
            [-0.142677602295955, 52.283178435388479],
            [-0.123747288249292, 52.257086166712092],
            [-0.120991519224576, 52.2574604387408],
            [-0.120632076635727, 52.259793407298105],
            [-0.118567715978971, 52.261033721282182],
            [-0.11835452333414, 52.262262159388008],
            [-0.114260875788917, 52.262005319722256],
            [-0.11489356410927, 52.263879363925788],
            [-0.113921958634359, 52.264156068995192],
            [-0.114085238463896, 52.266179068666759],
            [-0.111508053021185, 52.266198113517611],
            [-0.111188383020059, 52.268926417054828],
            [-0.109657894517774, 52.268763451681714],
            [-0.107503360787805, 52.270417538735579],
            [-0.107519148893257, 52.271767416760369],
            [-0.106130431105017, 52.27178381137265],
            [-0.106090871972657, 52.273021307066749],
            [-0.101757039627867, 52.276889087011106],
            [-0.102303494596762, 52.277651359522118],
            [-0.098153767570777, 52.27791997113637],
            [-0.09742851373033, 52.275583980885997],
            [-0.095053075341341, 52.275192311036548],
            [-0.09470298016228, 52.274605809800185],
            [-0.092859110721478, 52.273818054054175],
            [-0.085411667153682, 52.272488154523423],
            [-0.084102774000709, 52.27428950465638],
            [-0.082835917581337, 52.274231175105783],
            [-0.083021637784162, 52.274988580366461],
            [-0.082629809747648, 52.275270845841796],
            [-0.07862404153094, 52.276924006117291],
            [-0.075826256161564, 52.278670455615327],
            [-0.076235467489401, 52.279240893232853],
            [-0.074143845639238, 52.279944100359266],
            [-0.074457861385111, 52.280343051264779],
            [-0.070478252868092, 52.282624873876102],
            [-0.067420723203477, 52.285405404809524],
            [-0.066558819852603, 52.285131438899676],
            [-0.064873112677821, 52.287343616343762],
            [-0.063556745456988, 52.287740141870053],
            [-0.06246563848975, 52.291038322531662],
            [-0.061593664271576, 52.290825298321273],
            [-0.061557849012219, 52.290503712420367],
            [-0.060150633020136, 52.290392485364741],
            [-0.05775283528549, 52.291741363258055],
            [-0.057175787284791, 52.292764099354173],
            [-0.059922807078035, 52.293961980022836],
            [-0.05473220133092, 52.300774893904958],
            [-0.051966127795349, 52.305718699230773],
            [-0.049278839999952, 52.306787492452166],
            [-0.047525786476547, 52.308190873849924],
            [-0.046524118499348, 52.31031789099692],
            [-0.04703647203572, 52.313375392455775],
            [-0.046489131533313, 52.314644036388643],
            [-0.04526425207764, 52.314506879976818],
            [-0.042392783826834, 52.315112104727262],
            [-0.038791761283322, 52.314263804298669],
            [-0.037399696116527, 52.314225394051284],
            [-0.034866312694689, 52.314913381240906],
            [-0.032411949664844, 52.316497289617587],
            [-0.031241549136257, 52.316785296274709],
            [-0.024542315388991, 52.315565674019453],
            [-0.022155623016844, 52.315129201338806],
            [-0.016758083374196, 52.312632543180939],
            [-0.014848024263759, 52.312631023760751],
            [-0.011226595327453, 52.315297240428315],
            [-0.007198302910101, 52.316983594965379],
            [-0.005893244911125, 52.318892059010331],
            [-0.004760063376137, 52.321739435454909],
            [-0.004164756735544, 52.323457557867798],
            [0.000138791610529, 52.328035201423972],
            [0.003888792401236, 52.329893140571976],
            [0.008153682193951, 52.334362355216001],
            [0.008532171939116, 52.335329707636866],
            [0.009095943698262, 52.335850623041196],
            [0.009772250378107, 52.338950202605325],
            [0.009554328997706, 52.341460759495902],
            [0.014970591750116, 52.344091145845383],
            [0.026518711620771, 52.351118343850281],
            [0.029560108604156, 52.351896956165319],
            [0.030335732099889, 52.351775730060034],
            [0.030867496770788, 52.352228757458967],
            [0.034596033371043, 52.35325798133384],
            [0.038144729976511, 52.353311006893712],
          ],
          [
            [0.155252062868588, 52.237216056969196],
            [0.151233076379246, 52.232853678767611],
            [0.147632971435645, 52.229871895129889],
            [0.136347702159383, 52.2334541026609],
            [0.129841459815588, 52.235636246126617],
            [0.127577059368006, 52.233825664964357],
            [0.126112772500901, 52.234351105006844],
            [0.124971785141142, 52.234287132730977],
            [0.118683281180844, 52.231307000047622],
            [0.117378098247401, 52.228252509361567],
            [0.113022024216763, 52.229059832909684],
            [0.111650247677461, 52.229066404992167],
            [0.111511692409689, 52.229633573265801],
            [0.107801364254662, 52.230568519394787],
            [0.103448994195185, 52.228086201408225],
            [0.10115107337812, 52.226960897748008],
            [0.095486509220696, 52.224332601384226],
            [0.093754424186499, 52.222559584068556],
            [0.092482046948475, 52.223118967494557],
            [0.089967372135711, 52.221076527898127],
            [0.089377183379192, 52.221509596517322],
            [0.088098922224496, 52.22057099638414],
            [0.087519473404846, 52.22092114068289],
            [0.085605327892355, 52.21978335298315],
            [0.085051916923304, 52.220033215516295],
            [0.081514834996844, 52.218218170665175],
            [0.078683726541836, 52.217721377037975],
            [0.075113352830186, 52.217098146220181],
            [0.074943142189338, 52.216775635470192],
            [0.068610791843709, 52.216095542911404],
            [0.06861180851022, 52.214920294340892],
            [0.071088867948149, 52.215113325243209],
            [0.069773399824603, 52.21216551003463],
            [0.074213791055275, 52.211521945124716],
            [0.073637312936335, 52.209861395801816],
            [0.074072499967383, 52.209682901503442],
            [0.073919057092998, 52.208368294962035],
            [0.077299554711481, 52.20785473557401],
            [0.077539962206086, 52.206043142038638],
            [0.079008397579618, 52.205802369712984],
            [0.079134366637368, 52.205553773242876],
            [0.078928417223477, 52.204339906302479],
            [0.085505247084465, 52.203241020290477],
            [0.084669202780874, 52.201481702481381],
            [0.090507612745258, 52.200220237567812],
            [0.092281894993883, 52.199486518236007],
            [0.09233133177704, 52.199168228286055],
            [0.09317201049109, 52.199307975390425],
            [0.09381072075789, 52.198980126990236],
            [0.094047425412889, 52.199314919373201],
            [0.094862765991526, 52.199320226068046],
            [0.094969985433484, 52.198737446748652],
            [0.096201728146859, 52.197547521255139],
            [0.097541984867462, 52.197296203997929],
            [0.097378892064166, 52.195075410434399],
            [0.09635618933329, 52.192838423703293],
            [0.096823234909524, 52.192067610647563],
            [0.099695148328935, 52.191815108614868],
            [0.100216644999253, 52.192418173119421],
            [0.104028667471331, 52.191500508134986],
            [0.103669559304569, 52.19117691000011],
            [0.10468608737438, 52.190643541464894],
            [0.10472102865086, 52.188998298699069],
            [0.102799634634893, 52.188120786744165],
            [0.102114650089564, 52.187401054659823],
            [0.101999014246588, 52.186500328117049],
            [0.099960363304428, 52.184931584388195],
            [0.100391044556475, 52.182585121378217],
            [0.099219807788025, 52.182191444782582],
            [0.098132721684292, 52.180788267126971],
            [0.098244452230995, 52.179992294146416],
            [0.099605414757517, 52.179322456768432],
            [0.099467557753934, 52.176674993967723],
            [0.100301188316399, 52.175959682096504],
            [0.101365000415038, 52.175846318610915],
            [0.101550091505457, 52.17483952264616],
            [0.101284651369189, 52.173800290024175],
            [0.099898458504693, 52.172750443772586],
            [0.100001720575883, 52.171775679350816],
            [0.099278906256454, 52.171302084170286],
            [0.106917311212562, 52.169880031785915],
            [0.106064057917917, 52.168286614528789],
            [0.107824362565844, 52.168310017777536],
            [0.105798911098524, 52.166171050530949],
            [0.104654017016264, 52.163414777229988],
            [0.101073054396754, 52.160712709761874],
            [0.103401054779948, 52.157929567087486],
            [0.120549341818498, 52.164981478422462],
            [0.126793669685003, 52.166741162258887],
            [0.126786150797758, 52.167043427928874],
            [0.130152551947493, 52.168057212440843],
            [0.130560163247793, 52.167804368713703],
            [0.132017829589042, 52.168226725845884],
            [0.141727566600768, 52.170992074315741],
            [0.154898485587516, 52.164610618387023],
            [0.159856483599374, 52.168435941667056],
            [0.162600976925135, 52.172468920745864],
            [0.163684835579403, 52.172321354925536],
            [0.171932634461899, 52.171227389379077],
            [0.176387442358676, 52.170931255058527],
            [0.181300373397353, 52.169478195764697],
            [0.181677998452548, 52.170590719986038],
            [0.181152821153975, 52.183052601099277],
            [0.181469994709964, 52.183106981981837],
            [0.181690522220061, 52.185251097667745],
            [0.18132057258212, 52.186868410807158],
            [0.183089585928214, 52.186904022434284],
            [0.183184969811399, 52.187835628912879],
            [0.180993033517759, 52.187858194405734],
            [0.182012891306675, 52.189952457009625],
            [0.184523428548747, 52.191472399125523],
            [0.18167322651563, 52.193114936808513],
            [0.183472235504878, 52.195757673530217],
            [0.181059249076921, 52.196533362966349],
            [0.182657738664196, 52.198098080357425],
            [0.180727251140089, 52.198898103740788],
            [0.181475991528162, 52.199545172434917],
            [0.179999273066958, 52.200259459886809],
            [0.180407935530264, 52.200655648093729],
            [0.17890483326352, 52.201400082559772],
            [0.179892689764596, 52.202237870819829],
            [0.177381047568195, 52.203249095944877],
            [0.178247741835998, 52.204063057272187],
            [0.175720411837034, 52.205445005257531],
            [0.173046476421575, 52.203945869060384],
            [0.172795017945441, 52.204371325530403],
            [0.171206889118216, 52.203797202608193],
            [0.167315715095791, 52.204134915548018],
            [0.168562506632959, 52.205033681471186],
            [0.170847387316118, 52.205999660167187],
            [0.17027283625053, 52.207003844609289],
            [0.170362883348693, 52.208821264831307],
            [0.17186110103215, 52.211239510843512],
            [0.172981368935263, 52.213378755035983],
            [0.173576896071017, 52.213253587136819],
            [0.175172030692936, 52.215551312341809],
            [0.174706327586549, 52.215697474333709],
            [0.17581467142776, 52.217228155347513],
            [0.16545137507757, 52.217892687701038],
            [0.164939553842066, 52.219258966007317],
            [0.163799606566247, 52.219089247592407],
            [0.160898786204296, 52.220796028899869],
            [0.156831618016005, 52.223304562653937],
            [0.156143511948872, 52.224937485108519],
            [0.154822476041477, 52.22647316044872],
            [0.157294696905892, 52.227480943021241],
            [0.160948917353479, 52.230994666605426],
            [0.163432990235105, 52.230069738704735],
            [0.164125375146122, 52.230756615172098],
            [0.166620615345833, 52.230122753602608],
            [0.169030547811506, 52.233537680340007],
            [0.171306729236068, 52.232944613799418],
            [0.17140982431812, 52.233826624873785],
            [0.170062663593621, 52.2343108945892],
            [0.169417958299954, 52.235235430524192],
            [0.167310210387395, 52.235771414428648],
            [0.166201719848352, 52.234241558269069],
            [0.155252062868588, 52.237216056969196],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000026",
        LAD13CDO: "16UB",
        LAD13NM: "Allerdale",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-3.290541302998928, 54.860158006941447],
              [-3.290360033185663, 54.85931154839782],
              [-3.291637692831393, 54.860145449539061],
              [-3.290541302998928, 54.860158006941447],
            ],
          ],
          [
            [
              [-3.286350624632413, 54.879783596983174],
              [-3.285348914236543, 54.878282573185174],
              [-3.287719065494728, 54.879291879686122],
              [-3.288260280045243, 54.880035663936589],
              [-3.287768955001019, 54.879683188234289],
              [-3.287590541810332, 54.879953794216696],
              [-3.288565136709368, 54.880454823404222],
              [-3.288641572626525, 54.881189157083199],
              [-3.286350624632413, 54.879783596983174],
            ],
          ],
          [
            [
              [-3.201855205643193, 54.953407502073723],
              [-3.197916661193228, 54.952083066977004],
              [-3.19012221523927, 54.951041660717969],
              [-3.185998453904941, 54.94992804974806],
              [-3.182521096708602, 54.945084747146119],
              [-3.179452982895625, 54.942993653225891],
              [-3.176650518394663, 54.941809384005403],
              [-3.169575347762552, 54.940721061963593],
              [-3.164012846511, 54.93945968286814],
              [-3.162526115156109, 54.93884123686864],
              [-3.157038106324431, 54.937819676057586],
              [-3.15516658589207, 54.93640497208002],
              [-3.155909289169281, 54.93492675972859],
              [-3.155046545024808, 54.933197800040631],
              [-3.155153399880863, 54.932352018249404],
              [-3.154774030539587, 54.932674657708247],
              [-3.153136964889336, 54.931751972816954],
              [-3.150704627072916, 54.931232221400961],
              [-3.145304796666875, 54.931014524239629],
              [-3.145014558262643, 54.930247084784021],
              [-3.143529241185112, 54.928886080028008],
              [-3.141532870307786, 54.928100525146391],
              [-3.137961338365282, 54.927456413224547],
              [-3.134445390867879, 54.927618697945114],
              [-3.126276165184704, 54.92896183268379],
              [-3.124171420520458, 54.928810571313136],
              [-3.123891690877952, 54.92846087431117],
              [-3.123507132341154, 54.928716960687566],
              [-3.121716020096132, 54.928451319978016],
              [-3.119377586937461, 54.927919293138892],
              [-3.119079760983998, 54.927365750579092],
              [-3.11833847908165, 54.927195534011901],
              [-3.11881865844884, 54.926392183534574],
              [-3.120611490455057, 54.926443065026085],
              [-3.119028534815678, 54.926195236478357],
              [-3.118650507218724, 54.925956072240346],
              [-3.118867554913448, 54.925342968451645],
              [-3.118903738336093, 54.924004493714527],
              [-3.120397797574593, 54.9240886839019],
              [-3.120353154536103, 54.923043924807892],
              [-3.121390309057079, 54.922914832643151],
              [-3.122280165560174, 54.92203489242695],
              [-3.119955691647678, 54.918959469028991],
              [-3.118675002496683, 54.918405094792689],
              [-3.118847861704225, 54.917381697553182],
              [-3.11996307186699, 54.91652935449742],
              [-3.119937429893532, 54.91611170184359],
              [-3.121694482793823, 54.915341495820165],
              [-3.120594419066523, 54.9128631838934],
              [-3.118514428675663, 54.910198872164955],
              [-3.121495929585878, 54.908146644349429],
              [-3.125470340172742, 54.906642333471929],
              [-3.126358714288089, 54.905501753154773],
              [-3.119874892261167, 54.903973713874372],
              [-3.114934544914513, 54.904047014467018],
              [-3.113638417780225, 54.904382430322329],
              [-3.112872200105164, 54.905044594583416],
              [-3.112532733136901, 54.905855623277631],
              [-3.113197701822978, 54.906779672703529],
              [-3.112745539370144, 54.907124417279888],
              [-3.110531097793146, 54.906967638560978],
              [-3.110075087753008, 54.907740182913948],
              [-3.108832773571843, 54.90827995528155],
              [-3.105470647767846, 54.908761734864612],
              [-3.103869527694564, 54.906111690267878],
              [-3.101572765937595, 54.90653514883585],
              [-3.101279823121168, 54.904774583467102],
              [-3.099374208512093, 54.904440457168882],
              [-3.09944368875589, 54.904071367265239],
              [-3.097205962690821, 54.904311776311594],
              [-3.091826021777198, 54.903185665882674],
              [-3.089689261942549, 54.903122172280618],
              [-3.084661839492608, 54.903898692961043],
              [-3.085047543919616, 54.904264607813438],
              [-3.083831730934876, 54.90458011479285],
              [-3.081874418044043, 54.904754830991564],
              [-3.07803252980011, 54.90450140522362],
              [-3.0782971956524, 54.902383555034042],
              [-3.075405974000894, 54.900107654974533],
              [-3.073535691410973, 54.899736864987084],
              [-3.071830013390561, 54.895229749538686],
              [-3.070875700768982, 54.889704966116525],
              [-3.066110207967085, 54.888484236266734],
              [-3.064111156371553, 54.889236890241271],
              [-3.061440652831715, 54.889596364819688],
              [-3.061310841393648, 54.888217120458634],
              [-3.060290747656795, 54.888078645567781],
              [-3.060987894789351, 54.886844056399624],
              [-3.059859150206548, 54.886247299427239],
              [-3.059275390413299, 54.887169948602569],
              [-3.05716366884807, 54.886718335628693],
              [-3.063684247572134, 54.882088896365687],
              [-3.064249370443498, 54.880879707133431],
              [-3.065797964823023, 54.879552248358799],
              [-3.070202796760652, 54.876968432022764],
              [-3.070555413180309, 54.877041712459111],
              [-3.07236343747969, 54.876047989248036],
              [-3.06772158841981, 54.87437509426848],
              [-3.065799102475697, 54.87262786015441],
              [-3.064858469306432, 54.872237993911362],
              [-3.057489895723724, 54.87066677635044],
              [-3.056500779582726, 54.870741888793525],
              [-3.054428863507045, 54.867781651371253],
              [-3.055369242738242, 54.866201678961275],
              [-3.054921210309191, 54.865632210072661],
              [-3.055600369036335, 54.865446570356063],
              [-3.056255019969878, 54.86450264816208],
              [-3.058072791282793, 54.863948508107313],
              [-3.058046425292062, 54.862465903599329],
              [-3.063677470954588, 54.862839087692457],
              [-3.06427176695114, 54.862628979141085],
              [-3.064575524693445, 54.86144454122752],
              [-3.070247232535644, 54.861356036463683],
              [-3.070539978752754, 54.860703703554456],
              [-3.070088141917058, 54.860584566191633],
              [-3.06402281961938, 54.859802090190549],
              [-3.066204956279696, 54.854943506838083],
              [-3.065345009553105, 54.855057999861856],
              [-3.064961452617195, 54.855812669117718],
              [-3.064558897985468, 54.855846754947983],
              [-3.059539218941124, 54.855286746573462],
              [-3.053646357397175, 54.85414996499199],
              [-3.045684859259977, 54.853236533305406],
              [-3.032180856015575, 54.852407574888119],
              [-3.028267582702567, 54.85085370265643],
              [-3.025597476691128, 54.851386742641246],
              [-3.023268869491841, 54.852553115121232],
              [-3.022622637282154, 54.852252108448027],
              [-3.022144682316785, 54.849427969357023],
              [-3.020945793487537, 54.847222796653348],
              [-3.021970059797846, 54.847018264610512],
              [-3.021893304229524, 54.846692687618123],
              [-3.025731806872588, 54.844711961739137],
              [-3.027013031032561, 54.842917234815651],
              [-3.027683060295017, 54.840850873577402],
              [-3.029014558514858, 54.839754877195162],
              [-3.03573588437858, 54.836809326424195],
              [-3.039016776039288, 54.836215111958168],
              [-3.044625872102477, 54.835887475483069],
              [-3.047987833764076, 54.83506495114807],
              [-3.04745343962355, 54.833487868586467],
              [-3.044393569927407, 54.83286088835289],
              [-3.042345757159925, 54.833080697261764],
              [-3.041577546980543, 54.832328797539141],
              [-3.04033436947661, 54.831751716213425],
              [-3.039741326837258, 54.831816111526663],
              [-3.037620768858297, 54.830594965189199],
              [-3.03759636404739, 54.829279491304575],
              [-3.037146572414805, 54.828858255265324],
              [-3.036077470428189, 54.828507011714919],
              [-3.032502367868992, 54.828712723116006],
              [-3.030729674602456, 54.82677224159066],
              [-3.030350200181659, 54.824829799614186],
              [-3.030687184158198, 54.824615745959193],
              [-3.029302145937314, 54.824075704929776],
              [-3.029425556088276, 54.823481522546807],
              [-3.028744472110352, 54.823384847172328],
              [-3.029244824690025, 54.821638942506901],
              [-3.030301882090907, 54.82109884661358],
              [-3.030843373285009, 54.819260918537786],
              [-3.030132591819552, 54.818728638243684],
              [-3.029910562474412, 54.817709609579495],
              [-3.029071802527941, 54.817161334070924],
              [-3.029895804281071, 54.816273626000999],
              [-3.030128928515792, 54.814549754068288],
              [-3.028182022500575, 54.81460401109549],
              [-3.02710781345141, 54.814278795001499],
              [-3.027797156914539, 54.813420099871408],
              [-3.026686381417513, 54.812572151290055],
              [-3.027356425773119, 54.812486496623094],
              [-3.026851612877191, 54.811540852341388],
              [-3.025986021243742, 54.810848093349847],
              [-3.025004358878101, 54.810977713134427],
              [-3.02422084768314, 54.810575424424115],
              [-3.026079265853996, 54.80986143978901],
              [-3.025697521459518, 54.808919243256916],
              [-3.024925870701466, 54.808676826043268],
              [-3.025465978009735, 54.807947916675971],
              [-3.024911247809662, 54.807423273936628],
              [-3.026159816622514, 54.806962476034499],
              [-3.025939890818786, 54.80601980996083],
              [-3.02757843688372, 54.805540421230383],
              [-3.026645202587927, 54.804690968376526],
              [-3.027018349944973, 54.804375065283224],
              [-3.027920030260227, 54.804717021134493],
              [-3.028409396654555, 54.803464589510362],
              [-3.02676659270454, 54.801195822340809],
              [-3.021040237126563, 54.798847334394367],
              [-3.017287119700104, 54.801896672028569],
              [-3.014205948757195, 54.800987827663782],
              [-3.011980652495279, 54.803322338217626],
              [-3.001757970211702, 54.8002347936864],
              [-3.0019820017806, 54.799292912616046],
              [-3.002881068250494, 54.798411955953355],
              [-3.009352159366799, 54.793300449899242],
              [-3.009907014179112, 54.792286599897444],
              [-3.009774465338271, 54.791211967578732],
              [-3.009316646383404, 54.791063898389687],
              [-3.004793465963824, 54.790727591206334],
              [-3.001530441123307, 54.790909149610243],
              [-2.996301553796328, 54.791595669771688],
              [-2.996012538331237, 54.792744773808153],
              [-2.993242945512701, 54.792950814362612],
              [-2.990154802899327, 54.792724425450693],
              [-2.989147457730488, 54.792234768313307],
              [-2.988590585065146, 54.792295026479188],
              [-2.987768622366134, 54.792454499462089],
              [-2.988374903929199, 54.793909035541411],
              [-2.987933053399689, 54.793712226521912],
              [-2.986141160689307, 54.791534000013193],
              [-2.987504875429008, 54.789291456166723],
              [-2.98306432412081, 54.785999669314485],
              [-2.986763314809148, 54.785215632912411],
              [-2.986477254762172, 54.784068532493201],
              [-2.987690624746298, 54.783642564099715],
              [-2.989109851283652, 54.782541784275772],
              [-2.990783422535388, 54.782244138818477],
              [-2.991205098014035, 54.78143007276126],
              [-2.990245602483539, 54.780342403096995],
              [-2.987050876595071, 54.779466155232008],
              [-2.987137172004239, 54.778094047505888],
              [-2.986490053828584, 54.777057727156411],
              [-2.986036335953649, 54.776812478573348],
              [-2.985747429903268, 54.777079042707179],
              [-2.98535809929048, 54.776476487135014],
              [-2.984432928742943, 54.776150584663363],
              [-2.985685076098509, 54.774706999306943],
              [-2.985440513404277, 54.774111355485275],
              [-2.987721383758423, 54.771891001993332],
              [-2.987961610797519, 54.770394516991885],
              [-2.988601158048265, 54.770039713340367],
              [-2.987038702273779, 54.768560607070775],
              [-2.987697596777208, 54.766959161086504],
              [-2.985721045097411, 54.76602172776019],
              [-2.987349231330609, 54.764844672770465],
              [-2.988115731900811, 54.765238349014083],
              [-2.990368036199341, 54.763338108308176],
              [-2.99010396072633, 54.763216244685196],
              [-2.991399190870296, 54.761270770841541],
              [-2.992956923074077, 54.760213740796033],
              [-2.994591663191188, 54.759385226231082],
              [-2.995376255600558, 54.760390720663018],
              [-2.996335820237886, 54.760736028119119],
              [-2.99683889259107, 54.761729446691206],
              [-2.997767027317417, 54.762058824754163],
              [-2.997727174352522, 54.759933735464742],
              [-3.000877696116086, 54.755293860976565],
              [-3.003238596120163, 54.754752207106073],
              [-3.005478777366609, 54.753542876583708],
              [-3.008382429459742, 54.752902256447385],
              [-3.01111471004847, 54.752932526225777],
              [-3.015655350039871, 54.751277816979744],
              [-3.018556933308536, 54.750876921847443],
              [-3.019885907432177, 54.749972442668366],
              [-3.021931439254693, 54.74945374452502],
              [-3.02166317786809, 54.748487206198376],
              [-3.025688350158038, 54.748233040096004],
              [-3.026135978953392, 54.747933579893548],
              [-3.026016598331466, 54.747144631972418],
              [-3.025326751752976, 54.746773013327264],
              [-3.023893097841106, 54.746413965410461],
              [-3.022154939468523, 54.746423239444752],
              [-3.021697882997862, 54.745978640143889],
              [-3.021848072907283, 54.745156860455801],
              [-3.022869398014287, 54.74419832105243],
              [-3.022828743751648, 54.743016871808003],
              [-3.023541833559744, 54.742281108058229],
              [-3.021998274519006, 54.7416219010411],
              [-3.021507229102957, 54.740133296152202],
              [-3.02082969575308, 54.739750764008022],
              [-3.017470499848853, 54.739461761667741],
              [-3.015688358662481, 54.738818856247789],
              [-3.015706705914372, 54.737565912731228],
              [-3.011734753621192, 54.736550341844016],
              [-3.011978797184145, 54.734751800664334],
              [-3.008177406295494, 54.734585760497794],
              [-3.005050405637022, 54.733554853691466],
              [-3.003505641422671, 54.733700659070308],
              [-3.002115495026213, 54.733190013716325],
              [-3.000879501927531, 54.733261336681558],
              [-2.999712014578275, 54.733844341448552],
              [-2.996609904891157, 54.733626336538912],
              [-2.996210022535445, 54.732962787753706],
              [-2.996713540932237, 54.731775060170811],
              [-2.993259766048014, 54.731203975837317],
              [-2.992560984793289, 54.730506915854839],
              [-2.992663297522971, 54.72891178327356],
              [-2.991776526612232, 54.72833578525627],
              [-2.989608709900891, 54.727759461761387],
              [-2.990095639006987, 54.72715964691114],
              [-2.989161367450486, 54.726734100908203],
              [-2.988704716182049, 54.726026954324503],
              [-2.988983982654512, 54.724168858736718],
              [-2.986687717373808, 54.723535116122584],
              [-2.986525139079029, 54.72240317754077],
              [-2.983743220166277, 54.720942931741092],
              [-2.984092953774582, 54.720511412237727],
              [-2.985431608481409, 54.720718920248736],
              [-2.98630750995337, 54.720538346336461],
              [-2.985969006933967, 54.719512986120421],
              [-2.985270630686779, 54.71926702895513],
              [-2.982994171543319, 54.71937358604216],
              [-2.98333266192666, 54.718221401323795],
              [-2.984425005166729, 54.71736505834297],
              [-2.984758052986372, 54.716054741728321],
              [-2.986578303745476, 54.715963548347545],
              [-2.986774535806883, 54.714839471882655],
              [-2.988820274576341, 54.715086117922176],
              [-2.989794822999567, 54.713799309103315],
              [-2.991225836481242, 54.712837684621228],
              [-2.990736235367087, 54.711729093869543],
              [-2.989114173090523, 54.710899360119974],
              [-2.990406620929653, 54.708713942781102],
              [-2.989820475918649, 54.707143304557562],
              [-2.992600337892497, 54.704737203917702],
              [-2.99286899915082, 54.701886110048903],
              [-2.994523114261795, 54.69978844886829],
              [-2.993545266879189, 54.698825869168161],
              [-2.994047947360535, 54.697300238374588],
              [-2.992270152877181, 54.696235465011007],
              [-2.993558405887875, 54.695744999564262],
              [-2.993784577826086, 54.695349511700776],
              [-2.995328559268813, 54.695610044895325],
              [-2.995764248364544, 54.694520828444773],
              [-2.997194796584564, 54.69509142485348],
              [-3.001067257830572, 54.69489323183258],
              [-3.004773189143498, 54.695115093305425],
              [-3.01765209419341, 54.693479806074926],
              [-3.022853982958551, 54.69346835712183],
              [-3.033695615749813, 54.69431280276671],
              [-3.038871994779805, 54.697123731561192],
              [-3.040724016677613, 54.697523042478402],
              [-3.051967800489241, 54.699279987790362],
              [-3.058153185176637, 54.699168613307769],
              [-3.063794132990993, 54.700074551862244],
              [-3.073623539527275, 54.695572483770974],
              [-3.07894479832691, 54.694669641243252],
              [-3.081915853751203, 54.695246185399121],
              [-3.08718669735011, 54.686084237472649],
              [-3.082686485327798, 54.685381299919229],
              [-3.070424698632293, 54.684926764876714],
              [-3.060118732527718, 54.682013884233932],
              [-3.054600263754963, 54.67974965976142],
              [-3.057802467279106, 54.677819163348545],
              [-3.05952424341347, 54.67544768712041],
              [-3.0635111262681, 54.673005092046878],
              [-3.066172078320963, 54.672646479448545],
              [-3.067717510168941, 54.671558901612002],
              [-3.069298552072492, 54.671416438617591],
              [-3.069640206624516, 54.67060996980036],
              [-3.071183683702413, 54.669395644865503],
              [-3.071752265489091, 54.667964351767161],
              [-3.072781959168849, 54.667440271485255],
              [-3.072892218320499, 54.666858723629183],
              [-3.074535514905783, 54.666556566868124],
              [-3.075276127456605, 54.665832817749781],
              [-3.076945556994886, 54.666344634202034],
              [-3.077695935614158, 54.666225620274361],
              [-3.079956205741657, 54.665045243299929],
              [-3.081484030220404, 54.664078070881985],
              [-3.081989764131322, 54.66455616587038],
              [-3.083255883675629, 54.664243778039236],
              [-3.083850208408761, 54.663157302550395],
              [-3.086492438415543, 54.66334752426728],
              [-3.086574462677928, 54.662703305604197],
              [-3.087939324968928, 54.661622476120385],
              [-3.08810376746159, 54.661046717158044],
              [-3.090058393542107, 54.660284102221063],
              [-3.089914302254444, 54.659881873811578],
              [-3.091037986719027, 54.659121325195976],
              [-3.090862421049206, 54.658643888861711],
              [-3.09159838506729, 54.658109708946789],
              [-3.091164559659694, 54.657655271028894],
              [-3.092041155988668, 54.6573948295392],
              [-3.092265464280373, 54.655528860325639],
              [-3.092782404256941, 54.655095507108364],
              [-3.092446706296563, 54.654889133827972],
              [-3.0929898891521, 54.654163458602106],
              [-3.092487981958576, 54.654116760275777],
              [-3.092237032710833, 54.649384560428608],
              [-3.094290946233956, 54.643862243182149],
              [-3.093275347311855, 54.641877151506662],
              [-3.092034563258876, 54.640736185565132],
              [-3.091971012406599, 54.637903085117507],
              [-3.091454297171955, 54.636717834500409],
              [-3.09065377425964, 54.636099536721495],
              [-3.09107077227456, 54.635529583981452],
              [-3.09069429303387, 54.634314306943779],
              [-3.091383744304048, 54.632811719043914],
              [-3.090771086529571, 54.631048551469007],
              [-3.09133014444241, 54.630694808417672],
              [-3.092082549249914, 54.628827664377717],
              [-3.091387443056888, 54.627339353669065],
              [-3.091988955642141, 54.627011287397693],
              [-3.091304285086087, 54.625390769237818],
              [-3.090056999234386, 54.62398561596936],
              [-3.090089918183731, 54.622726205683563],
              [-3.089606796177788, 54.62215087735499],
              [-3.09221558598404, 54.619457254052392],
              [-3.091917724131393, 54.618281711496479],
              [-3.092400557845601, 54.617228541818534],
              [-3.089254371882418, 54.615511567499389],
              [-3.088321781846202, 54.615671840633311],
              [-3.087419818033775, 54.615413025126706],
              [-3.086438700608122, 54.614159124995666],
              [-3.086946304011226, 54.612637518056843],
              [-3.085932734886597, 54.612894663969634],
              [-3.083837985378055, 54.612381393010587],
              [-3.08034393172746, 54.612116948327518],
              [-3.079323023294704, 54.611688376603688],
              [-3.078482666730324, 54.610709066300252],
              [-3.077791249101087, 54.610559745414463],
              [-3.070744753911579, 54.6135692043025],
              [-3.067702276282188, 54.614170364400287],
              [-3.065582062652378, 54.614157592299676],
              [-3.062719517041557, 54.612576738440481],
              [-3.061866645918794, 54.612588717031855],
              [-3.059081285927943, 54.613671816426823],
              [-3.059127021786316, 54.614061462237672],
              [-3.055711389968023, 54.614014010254941],
              [-3.055096683042633, 54.614554110172278],
              [-3.053856580796929, 54.613425332515405],
              [-3.053251912987776, 54.613395545298935],
              [-3.046524146458706, 54.614371494910714],
              [-3.044704086672873, 54.614288357568057],
              [-3.042183965098005, 54.612926948765669],
              [-3.04092180702147, 54.612677180062462],
              [-3.039845936370282, 54.612991992792018],
              [-3.039112313542184, 54.612336833701605],
              [-3.038216696587413, 54.610426646566864],
              [-3.038965842230017, 54.608836669944104],
              [-3.034025819154397, 54.603728396767615],
              [-3.034339008838154, 54.602770378390083],
              [-3.033447114596092, 54.60257848106184],
              [-3.032184839217271, 54.602798655336059],
              [-3.022886099041826, 54.602752680033731],
              [-3.017756289480387, 54.6002875953711],
              [-3.01150092309841, 54.599534784401186],
              [-3.010902782175592, 54.598821703980072],
              [-3.010577074930491, 54.597170777095826],
              [-3.008959170982494, 54.595776879065369],
              [-3.007687365777342, 54.595419898799683],
              [-3.008109382677716, 54.594834911221497],
              [-3.009216809248415, 54.594491356418068],
              [-3.009202875090748, 54.593927974132754],
              [-3.011168514705979, 54.592368459990894],
              [-3.016216053586997, 54.589162673571799],
              [-3.017374831952414, 54.588777270360076],
              [-3.019829863030448, 54.588680213101888],
              [-3.024606196781136, 54.58679748025299],
              [-3.026019851071845, 54.586523074325562],
              [-3.031146929634403, 54.582375014387296],
              [-3.037062314457779, 54.580979964392199],
              [-3.030304624574736, 54.575791833367219],
              [-3.026961037013082, 54.574543170688294],
              [-3.02390112534495, 54.574481928943534],
              [-3.019495132481988, 54.575960692048731],
              [-3.018279654476467, 54.57498772268756],
              [-3.020529921826719, 54.571496984896129],
              [-3.024019262756736, 54.568705713881734],
              [-3.02660116133333, 54.567243172205671],
              [-3.0287908826986, 54.566919901704971],
              [-3.020952321560531, 54.564756567026294],
              [-3.016774806510388, 54.561582397923516],
              [-3.020359472182979, 54.55891624612989],
              [-3.018408834694322, 54.55336865945695],
              [-3.019586793156389, 54.551810226127174],
              [-3.016577943988294, 54.54795214529954],
              [-3.025051143508309, 54.541036798257593],
              [-3.023259502936194, 54.538806941267573],
              [-3.024820022908548, 54.534714385154253],
              [-3.024836539013329, 54.532536617855023],
              [-3.025758011936459, 54.530088746633432],
              [-3.024382347270547, 54.529087537040049],
              [-3.019308449943992, 54.528124733677906],
              [-3.018648453199871, 54.527434676825457],
              [-3.016231141075682, 54.526414285359564],
              [-3.015311329079408, 54.525587090309898],
              [-3.015428955448953, 54.523478575220388],
              [-3.016631598778343, 54.521450814205807],
              [-3.015771244023766, 54.520276211541507],
              [-3.015894890778718, 54.519344983961233],
              [-3.015229846021452, 54.518318824498863],
              [-3.016980868264073, 54.514928464881919],
              [-3.015829885680346, 54.513677217753482],
              [-3.014893655686635, 54.50980884046291],
              [-3.013391497844808, 54.508775315183968],
              [-3.011751935003505, 54.508615592529885],
              [-3.011386147201964, 54.507375703732002],
              [-3.01241085447421, 54.505719745640242],
              [-3.013663503342097, 54.50092348296652],
              [-3.014831758946482, 54.499836096262598],
              [-3.022866417929116, 54.499667714459441],
              [-3.031438780905026, 54.49792142062693],
              [-3.032956056764458, 54.498021704784755],
              [-3.035872082772799, 54.497625571061789],
              [-3.039568387537555, 54.496340097146927],
              [-3.042287953067341, 54.495815180174439],
              [-3.044474709498374, 54.495772037565573],
              [-3.048301173246438, 54.496270965109524],
              [-3.050799607803261, 54.492066534676802],
              [-3.050798926878257, 54.491257674283453],
              [-3.052231557163724, 54.491135564004416],
              [-3.055749437353488, 54.492133944471867],
              [-3.061982662856457, 54.492515226207644],
              [-3.069975102334555, 54.49043694628336],
              [-3.073378057496749, 54.490275556084626],
              [-3.076211525996587, 54.489357906945877],
              [-3.081702810434543, 54.484918520552476],
              [-3.084379642637108, 54.485568590311118],
              [-3.088262261423402, 54.483956393585466],
              [-3.088804963780746, 54.482828972704979],
              [-3.092223857850578, 54.479064731658788],
              [-3.093813805222932, 54.474795684679897],
              [-3.094874043772358, 54.473641977937632],
              [-3.097750055287582, 54.471895682189832],
              [-3.098933261191764, 54.471997270803641],
              [-3.100676530790714, 54.472784884722792],
              [-3.102889382051362, 54.472325220183038],
              [-3.109475939309356, 54.473995913853656],
              [-3.111330722177055, 54.470974352242244],
              [-3.115701355110381, 54.466575138632301],
              [-3.112569911941533, 54.461482934302396],
              [-3.119422424917435, 54.46022969170204],
              [-3.126163347082055, 54.462792301105537],
              [-3.132209811625189, 54.46453599231554],
              [-3.133592993471493, 54.465569170575293],
              [-3.136440946812075, 54.470738103030868],
              [-3.137739750444652, 54.470276504836413],
              [-3.142469149709688, 54.469498499062212],
              [-3.145554831417411, 54.468435718547866],
              [-3.147142462596261, 54.467108476557414],
              [-3.147535447854698, 54.465493279978105],
              [-3.150030179453627, 54.462739145918647],
              [-3.152879864054553, 54.46164786239067],
              [-3.153821872059627, 54.460146038121685],
              [-3.156436703512171, 54.458613825190497],
              [-3.159163988986939, 54.457847114262918],
              [-3.162293638067256, 54.458272715400994],
              [-3.162956999084945, 54.458110846932364],
              [-3.163684893419901, 54.457287767308443],
              [-3.163760424595088, 54.455981146570323],
              [-3.165507005282725, 54.45572524008761],
              [-3.167136002331105, 54.454102535933089],
              [-3.17489633847926, 54.454595328995005],
              [-3.176582391409383, 54.455890208001911],
              [-3.178995840319714, 54.457103370464928],
              [-3.180608407092226, 54.459374063333414],
              [-3.184357964079891, 54.46244976385249],
              [-3.188255988880206, 54.462909402269773],
              [-3.188868490630327, 54.462590609019202],
              [-3.193115830128072, 54.463335154760969],
              [-3.196753586029339, 54.465548770608429],
              [-3.196432329537116, 54.468180820633478],
              [-3.195900766857904, 54.468736122911395],
              [-3.197507956415469, 54.469348437277453],
              [-3.198059884466875, 54.470335195124171],
              [-3.199465994873199, 54.470611544194028],
              [-3.199108716559609, 54.472060293261158],
              [-3.199652462602878, 54.472448552170377],
              [-3.199858420272655, 54.473474686335202],
              [-3.200786179875808, 54.474117070016348],
              [-3.203212439225489, 54.474758915254661],
              [-3.204945424700312, 54.474441461940195],
              [-3.208173751110794, 54.475118347902097],
              [-3.21197012770294, 54.477413509134443],
              [-3.212864750876788, 54.478334748537691],
              [-3.212709332605022, 54.478976224365447],
              [-3.214769344068583, 54.479638590624255],
              [-3.214970943418471, 54.48034748195181],
              [-3.217414790020194, 54.481768989977297],
              [-3.219317562510578, 54.482015839330295],
              [-3.218458548514641, 54.482974491172492],
              [-3.217472353909742, 54.483238778924381],
              [-3.216328054699146, 54.483117285130298],
              [-3.213550304338111, 54.484598501857178],
              [-3.213333732824291, 54.485260364504271],
              [-3.213745767872264, 54.48572537859696],
              [-3.211234073207872, 54.488173626761153],
              [-3.212798249172368, 54.491575014342573],
              [-3.212153053389559, 54.493666601763366],
              [-3.213621331292282, 54.496583610556563],
              [-3.216874753039255, 54.496949948083447],
              [-3.217873362153494, 54.497351520864292],
              [-3.221312709076753, 54.49944417483109],
              [-3.224712762664882, 54.502328035804318],
              [-3.226237929102967, 54.501689735265927],
              [-3.227695263282059, 54.502035344146961],
              [-3.22870647497257, 54.501665566838156],
              [-3.22899523848822, 54.501922372018122],
              [-3.229743972056392, 54.50124608190508],
              [-3.230080093770515, 54.501382868024052],
              [-3.231122174343715, 54.500243419843962],
              [-3.23315342397904, 54.500366515693209],
              [-3.233433482749464, 54.500743832740788],
              [-3.23459961181193, 54.500956617277055],
              [-3.234191653074538, 54.501305906283463],
              [-3.235406365479354, 54.501592783949377],
              [-3.235272332804395, 54.501808058052319],
              [-3.237607438976696, 54.503142195736842],
              [-3.241034226853786, 54.504312297135719],
              [-3.239907445844204, 54.505352038838161],
              [-3.239913899943716, 54.506177032111999],
              [-3.244297544579694, 54.507414517083923],
              [-3.247333715958548, 54.50730413653045],
              [-3.24737056484178, 54.507753135394253],
              [-3.248676327785573, 54.507876266074341],
              [-3.248933440411132, 54.508456904116002],
              [-3.253292161326393, 54.508711979451768],
              [-3.254436194588756, 54.509100061063371],
              [-3.256758607022832, 54.508578027053822],
              [-3.257837230833248, 54.508746560626228],
              [-3.261328600983842, 54.51067487067975],
              [-3.26202265895421, 54.511493586953812],
              [-3.266421014265727, 54.514364066085761],
              [-3.268422204897123, 54.515036030082804],
              [-3.272228088231795, 54.515369923919494],
              [-3.273571974594683, 54.516012768010299],
              [-3.278300435854208, 54.517242699622933],
              [-3.284581922283071, 54.519558736080434],
              [-3.28458249187793, 54.520610279910748],
              [-3.285541156600259, 54.521274161003795],
              [-3.291073503395025, 54.521802102434549],
              [-3.29311707511296, 54.522470512050845],
              [-3.293360112540473, 54.522973915070764],
              [-3.294760554256223, 54.523774102905527],
              [-3.298946040397002, 54.524886815189134],
              [-3.298641314673021, 54.527456052084311],
              [-3.299830773563218, 54.52749270318288],
              [-3.3025751709899, 54.528367324025318],
              [-3.305780591467915, 54.530125780696807],
              [-3.307272693376808, 54.530200433498976],
              [-3.314654195481117, 54.527872599866342],
              [-3.316455413899835, 54.527807165765459],
              [-3.324732056592294, 54.530103888369226],
              [-3.327202162654113, 54.529694826948528],
              [-3.328430315569233, 54.530967472068284],
              [-3.333198169262728, 54.531357190426057],
              [-3.337330257234309, 54.534088745514268],
              [-3.340036618957553, 54.535226280679254],
              [-3.350474727499909, 54.533665843011121],
              [-3.355344151471459, 54.535471810569703],
              [-3.360081672281364, 54.537945952612098],
              [-3.36135225113307, 54.5437502715683],
              [-3.366149339687312, 54.544636287588176],
              [-3.364326092108666, 54.548045218455634],
              [-3.364367674442341, 54.550726666739386],
              [-3.364846008067896, 54.551808776369143],
              [-3.366707399349793, 54.552912113326109],
              [-3.367695672143984, 54.554258977128981],
              [-3.370878199981184, 54.555420104636248],
              [-3.370021268945003, 54.558317547435337],
              [-3.372599381643516, 54.559658936950385],
              [-3.372387392274228, 54.56145078396699],
              [-3.376926777863133, 54.564670703936784],
              [-3.375710471187267, 54.565616561776416],
              [-3.37606244836909, 54.567387169753253],
              [-3.376881725596926, 54.568289644616627],
              [-3.376175212961102, 54.56917308052666],
              [-3.375810047213709, 54.571373814000282],
              [-3.376142383579207, 54.573248451040989],
              [-3.378277091160254, 54.575948304052098],
              [-3.381004404093968, 54.576536451985788],
              [-3.382302057765382, 54.57652522793812],
              [-3.382394110974245, 54.577005015032121],
              [-3.386025902534262, 54.576523069023843],
              [-3.388504251921436, 54.578793692006847],
              [-3.38898451277951, 54.58035832131462],
              [-3.39179007798095, 54.584862145737262],
              [-3.393401159998909, 54.586001211978235],
              [-3.395494726772225, 54.586358161119179],
              [-3.396973942228225, 54.587121220048253],
              [-3.398425430465001, 54.587344423976603],
              [-3.399448631507536, 54.587081839058555],
              [-3.401862706925742, 54.587395425641638],
              [-3.404551518009585, 54.586562545767308],
              [-3.407467128998175, 54.586745269397419],
              [-3.408195598251575, 54.586374591008166],
              [-3.413422924205376, 54.588560559249515],
              [-3.413653453621003, 54.589184306467274],
              [-3.415764250182669, 54.589671026542639],
              [-3.415685322906658, 54.589180324621971],
              [-3.41746070656856, 54.590034939458178],
              [-3.418948643762622, 54.590276345471757],
              [-3.42221262552318, 54.591652686729823],
              [-3.422579053907033, 54.591109119178824],
              [-3.424917031897803, 54.592289554435986],
              [-3.424675268790401, 54.592457774227597],
              [-3.427134226082789, 54.592667871392173],
              [-3.428868500944378, 54.593353837129655],
              [-3.428671132985113, 54.594303468582844],
              [-3.430234333756951, 54.595670004285289],
              [-3.431232969880331, 54.59629542379971],
              [-3.43324468522742, 54.596720998565679],
              [-3.433996839390352, 54.597466154349348],
              [-3.434977547229701, 54.596830784352022],
              [-3.433742461373912, 54.59626212307564],
              [-3.435956933496014, 54.594267563598002],
              [-3.435355609253104, 54.59376150077177],
              [-3.435972313473283, 54.592989332086283],
              [-3.437188412369811, 54.592669313584267],
              [-3.439168496374245, 54.589524354354424],
              [-3.438920196305913, 54.588495519682532],
              [-3.44153143108875, 54.586580617309025],
              [-3.443605071922755, 54.582685787788392],
              [-3.444786011307556, 54.582213318615153],
              [-3.44621673306525, 54.581541920128238],
              [-3.448792399095407, 54.581131824087983],
              [-3.450633484461217, 54.581038761340551],
              [-3.452859635620471, 54.581548617539383],
              [-3.453550081305857, 54.582079588332732],
              [-3.45661481165244, 54.581456731375319],
              [-3.457070055451188, 54.582506409389126],
              [-3.460336935184151, 54.583512297093584],
              [-3.461997825502987, 54.583699856409829],
              [-3.467026957256474, 54.582926256459167],
              [-3.470231594366159, 54.58284062217303],
              [-3.476301489081732, 54.582142962587788],
              [-3.481126846921437, 54.581181617997999],
              [-3.481581564888154, 54.582327383073896],
              [-3.483593412208569, 54.583612248045199],
              [-3.485330538158101, 54.584020550716112],
              [-3.487049849334008, 54.587124478138058],
              [-3.487347857442717, 54.591989484712208],
              [-3.486992313866723, 54.592765902493738],
              [-3.488687730960545, 54.595073784110269],
              [-3.488442072881741, 54.596443843092999],
              [-3.489125889364444, 54.597451945072258],
              [-3.492298820033044, 54.599389266694892],
              [-3.494750752635976, 54.598752332960792],
              [-3.496462331982744, 54.599203037457677],
              [-3.499932239959548, 54.599183448141034],
              [-3.501161567815438, 54.599483675706892],
              [-3.501915866100666, 54.600251758341287],
              [-3.502314690146484, 54.600278265220794],
              [-3.503803829761212, 54.601955761428577],
              [-3.50821139140191, 54.602125607330677],
              [-3.51010328934579, 54.603466358832407],
              [-3.511123916518406, 54.604772125437201],
              [-3.512762966733252, 54.605008699442124],
              [-3.516039592704206, 54.604690875301735],
              [-3.518851106561191, 54.604897395103976],
              [-3.520068720473306, 54.605456427379877],
              [-3.522027950816764, 54.605433601475667],
              [-3.525234937565933, 54.60366847090993],
              [-3.526661055826966, 54.603219974104888],
              [-3.529696200271101, 54.603611265443007],
              [-3.535396253261296, 54.603601998894781],
              [-3.536964584057585, 54.602863066984447],
              [-3.537493713994774, 54.60400408908324],
              [-3.538716469205764, 54.605062592062836],
              [-3.540626305817078, 54.605741142041708],
              [-3.541810398212382, 54.606592488059114],
              [-3.543677098214604, 54.606782603050966],
              [-3.543657469202576, 54.60716393664574],
              [-3.544381206567861, 54.607345245183375],
              [-3.546332740181519, 54.607117202306256],
              [-3.545970258523027, 54.604661867598658],
              [-3.551132888646118, 54.599269622048169],
              [-3.551629044776152, 54.597255379120014],
              [-3.554226190522896, 54.596434715844495],
              [-3.558940696512261, 54.59832713197676],
              [-3.560042731080904, 54.598364161007936],
              [-3.560059531212163, 54.598763004822239],
              [-3.569535686221861, 54.599991347685638],
              [-3.572586230127936, 54.600696838760079],
              [-3.570604623011712, 54.603187941963867],
              [-3.571876749390784, 54.606710842861453],
              [-3.570681183876948, 54.607427417051369],
              [-3.570273094347149, 54.608450136885693],
              [-3.571193623669402, 54.611725944370669],
              [-3.570791645468355, 54.612385476917702],
              [-3.571330980639559, 54.612638224912466],
              [-3.570712723187606, 54.61258782881238],
              [-3.570232497170667, 54.611939744986969],
              [-3.568170210686582, 54.612068943674331],
              [-3.567979825766695, 54.611789192063668],
              [-3.566385215431005, 54.611798155255251],
              [-3.565893429274369, 54.612217959242543],
              [-3.567277359878902, 54.612689883861599],
              [-3.568026574646793, 54.612239775962209],
              [-3.567859394601017, 54.612724588607534],
              [-3.569000831456152, 54.613131327085874],
              [-3.56844177210186, 54.613130483851187],
              [-3.568404110304114, 54.613520145267458],
              [-3.569060059649645, 54.616119015132824],
              [-3.570887636127351, 54.619217684399928],
              [-3.569753754712548, 54.622189390186065],
              [-3.570009181065136, 54.624437528115436],
              [-3.571269566348273, 54.626473994178824],
              [-3.571402627930068, 54.62771528295702],
              [-3.573467525537364, 54.630602314098809],
              [-3.573050570781464, 54.630797375625043],
              [-3.573164336852897, 54.631496947296483],
              [-3.575534880644545, 54.635267968014311],
              [-3.574594985068295, 54.639331902069593],
              [-3.575890618943268, 54.646626626797222],
              [-3.57878222000351, 54.651893560531654],
              [-3.579958937505503, 54.652339287344937],
              [-3.577795803918743, 54.65175811840416],
              [-3.577750335700101, 54.651944758243985],
              [-3.573551349233483, 54.649539435460852],
              [-3.571969045712685, 54.648982071769758],
              [-3.567548217457337, 54.648889285488721],
              [-3.566042190940958, 54.648498929116307],
              [-3.567154489347022, 54.647397915081541],
              [-3.565342254222253, 54.647587630098933],
              [-3.565291725255547, 54.647926224044753],
              [-3.564474258623557, 54.647020933244733],
              [-3.562515890244417, 54.646391010582256],
              [-3.558215099653066, 54.645984460481458],
              [-3.557214643787399, 54.646246292331],
              [-3.560014515876387, 54.646877145773104],
              [-3.563396896018136, 54.647015075111902],
              [-3.564486846966382, 54.647953702702544],
              [-3.562629093485372, 54.647402472923346],
              [-3.559960221367647, 54.647563612810764],
              [-3.558274958917452, 54.647953810905555],
              [-3.556473709949753, 54.649162464740684],
              [-3.553299603217439, 54.649897078062544],
              [-3.548801544307975, 54.649119730273149],
              [-3.546565156590065, 54.648388848418989],
              [-3.54486456727168, 54.647194512984306],
              [-3.544148209275506, 54.647349251977595],
              [-3.545941675259831, 54.648704191277226],
              [-3.551872502417115, 54.650354877393667],
              [-3.553872966512818, 54.650584476246806],
              [-3.55575042371149, 54.650393197465355],
              [-3.560433417654775, 54.648587515195118],
              [-3.563308641265149, 54.648107331474897],
              [-3.56798625507724, 54.649679033357948],
              [-3.568118149379069, 54.650085370939536],
              [-3.567273040741475, 54.650639174249037],
              [-3.569784477249767, 54.651375099060402],
              [-3.5717431121813, 54.650838287329691],
              [-3.56978859883832, 54.651482000298785],
              [-3.568672052877949, 54.65118639080886],
              [-3.567015634531451, 54.653817888985017],
              [-3.565377493175457, 54.654229145239967],
              [-3.563899646215108, 54.655262959409384],
              [-3.563189612305705, 54.657158660028216],
              [-3.562160874517408, 54.657134185993485],
              [-3.561393539452837, 54.658023984192738],
              [-3.561090415030221, 54.660791629408578],
              [-3.561508007686883, 54.661654463685139],
              [-3.5610572901495, 54.662474565204384],
              [-3.554455598175263, 54.666412525424199],
              [-3.550785025029894, 54.669576526751626],
              [-3.547470767951258, 54.673887187626804],
              [-3.543387979406008, 54.67660144038539],
              [-3.53815496489791, 54.678814267443109],
              [-3.533499158224763, 54.681869794470416],
              [-3.529147315629301, 54.68386884243872],
              [-3.526139798407288, 54.68582024496142],
              [-3.518980713712118, 54.692968232169207],
              [-3.514348276326348, 54.70106387412131],
              [-3.513471364515227, 54.704145007423705],
              [-3.511952144645445, 54.70631920719547],
              [-3.510577432309924, 54.71058929634949],
              [-3.509880867104112, 54.717616407059253],
              [-3.507141569181722, 54.716887455263539],
              [-3.507503547449097, 54.71593206404718],
              [-3.50691933962182, 54.71566790376481],
              [-3.504872339177678, 54.715560322712051],
              [-3.504132374217915, 54.716078207368497],
              [-3.504516581328368, 54.71458958428412],
              [-3.504138906595815, 54.714284199866789],
              [-3.503636175035906, 54.714458506730075],
              [-3.503401092518657, 54.715782601385612],
              [-3.501652905868073, 54.714855184342738],
              [-3.50119160272874, 54.713966632774678],
              [-3.501963933868451, 54.713358488700507],
              [-3.50127237465068, 54.713421882077128],
              [-3.501820245846519, 54.712705072874257],
              [-3.5036028864464, 54.712300994796855],
              [-3.504116899579787, 54.711593582439157],
              [-3.504001056662327, 54.710980267492239],
              [-3.502491619844188, 54.710123598115622],
              [-3.501422735922587, 54.709887889158786],
              [-3.500515430664742, 54.710244251236347],
              [-3.50065460583512, 54.71128059667187],
              [-3.500015088841765, 54.711617460649862],
              [-3.497265582191423, 54.711564282672597],
              [-3.494930477441259, 54.712015527937886],
              [-3.493611305129421, 54.712867640327119],
              [-3.495873934422718, 54.71183761533775],
              [-3.497706604445676, 54.711653202805323],
              [-3.500167362337091, 54.7119157617987],
              [-3.501479362236569, 54.711042095480771],
              [-3.501213043656747, 54.710178090544964],
              [-3.502828379608292, 54.710583183117926],
              [-3.503596611815777, 54.711041009517452],
              [-3.503719497147798, 54.711593121663817],
              [-3.502888863062489, 54.712218178217121],
              [-3.502687193724284, 54.71196992473751],
              [-3.501952219275275, 54.712201928775798],
              [-3.501305575378788, 54.712892100773018],
              [-3.500901000557469, 54.713580170121141],
              [-3.501064607001105, 54.715319042694929],
              [-3.502237622102908, 54.716225737521299],
              [-3.503547732618028, 54.716347001338377],
              [-3.502226656360843, 54.716349003610297],
              [-3.502200147706124, 54.71693352636558],
              [-3.504428975253844, 54.716834877312245],
              [-3.507128190232544, 54.717614719174719],
              [-3.50443944146053, 54.716949788738674],
              [-3.501132101962869, 54.717145386129069],
              [-3.498482937100565, 54.71978007905826],
              [-3.498324347420662, 54.720741914611111],
              [-3.495762869141647, 54.724208609057001],
              [-3.493571293548618, 54.725795878830368],
              [-3.490326514618804, 54.727801401200075],
              [-3.478988124082316, 54.732387321619036],
              [-3.471766877725006, 54.73621492791839],
              [-3.46501100258086, 54.738513085760076],
              [-3.461457230010268, 54.740330116205122],
              [-3.456290290056102, 54.744041222074848],
              [-3.451664931790431, 54.748107812450982],
              [-3.445411365646432, 54.752162183095109],
              [-3.441045103499351, 54.754742330348094],
              [-3.439016681723766, 54.756347315487915],
              [-3.435752509795228, 54.760670329866016],
              [-3.433315949211437, 54.766220142292582],
              [-3.431917733062718, 54.772753449169286],
              [-3.431542395493456, 54.781097310841517],
              [-3.433387176998052, 54.787356829050999],
              [-3.437621832704689, 54.795768363243738],
              [-3.438344020400723, 54.799722349222115],
              [-3.437854514501993, 54.801620897955814],
              [-3.435685847820723, 54.804911700160012],
              [-3.430945987121711, 54.810568630009158],
              [-3.427393003835456, 54.817570295610686],
              [-3.422531845900297, 54.824366993475572],
              [-3.418185862835382, 54.830273107701501],
              [-3.41492099835402, 54.83373808112335],
              [-3.410311154615433, 54.839728752315494],
              [-3.404762695395843, 54.853480736978462],
              [-3.401477348793597, 54.863268098917409],
              [-3.401065119368678, 54.863242299720341],
              [-3.400570461805912, 54.865872286876133],
              [-3.399841646135054, 54.867457955012455],
              [-3.398044936196098, 54.868762034800028],
              [-3.398651636773864, 54.869047133725736],
              [-3.394801272974221, 54.867835005712983],
              [-3.394254449897652, 54.868019233317753],
              [-3.394227922578096, 54.868514735255545],
              [-3.396661892514888, 54.869233604187386],
              [-3.39690261018545, 54.868984584552187],
              [-3.397495577055365, 54.86914312697715],
              [-3.397093373879932, 54.869589029207475],
              [-3.393025765731456, 54.870572231954704],
              [-3.386341365289489, 54.874868235832558],
              [-3.377871378353453, 54.883198718891478],
              [-3.377735000658187, 54.884089999344077],
              [-3.376320984776538, 54.88431543356193],
              [-3.366986812808533, 54.891464109922524],
              [-3.361669587010896, 54.893668175249751],
              [-3.35790557610559, 54.894688116395749],
              [-3.349129804601863, 54.898089582250023],
              [-3.345086143852634, 54.898975622354143],
              [-3.342395267704422, 54.899138398447377],
              [-3.338843121964774, 54.900228196761638],
              [-3.335821916954815, 54.899555068988533],
              [-3.335092448774915, 54.89909485910924],
              [-3.335130853155729, 54.898653168926195],
              [-3.341121222019654, 54.897169009649019],
              [-3.347615580530802, 54.894727222503818],
              [-3.349021631330983, 54.893874910932404],
              [-3.350478979379525, 54.893718516965507],
              [-3.351070899304653, 54.893328182812077],
              [-3.350935637876179, 54.892641270969577],
              [-3.352993703208064, 54.89254556746539],
              [-3.35435912404193, 54.892114244595724],
              [-3.354991969674502, 54.891226444313062],
              [-3.355735350583559, 54.892005424816901],
              [-3.35768676062178, 54.8920312600006],
              [-3.358980783496956, 54.891426331717156],
              [-3.359647139836509, 54.890796063345846],
              [-3.359145941476061, 54.890219304057545],
              [-3.360018249102231, 54.890241893560862],
              [-3.360672435039112, 54.889621641413733],
              [-3.358700770095745, 54.888249802842736],
              [-3.358150517993925, 54.888159794335081],
              [-3.357755475448433, 54.888466180139744],
              [-3.357369099522062, 54.888149656710489],
              [-3.358116973575917, 54.887718899183888],
              [-3.359308645581316, 54.887643556599521],
              [-3.359760098947876, 54.886420740656938],
              [-3.359308156289239, 54.885823658683769],
              [-3.358362330471331, 54.885559233080876],
              [-3.359577843788977, 54.886378743816216],
              [-3.35917357725524, 54.887567778466909],
              [-3.358047270618705, 54.887639692727667],
              [-3.357185577278887, 54.888209225471989],
              [-3.357731412427116, 54.888585079814966],
              [-3.358463959505733, 54.888300982477375],
              [-3.360400362779304, 54.889644460844622],
              [-3.359907516304963, 54.890102034794936],
              [-3.358917399163206, 54.890099635709475],
              [-3.359298402326328, 54.890857484551702],
              [-3.357610427830829, 54.891847876269722],
              [-3.35665748172193, 54.891561048573315],
              [-3.355658423096465, 54.891804072215642],
              [-3.355743502542311, 54.891227045259683],
              [-3.353365355892574, 54.890005249514424],
              [-3.35420293203051, 54.889318281559973],
              [-3.351842227512204, 54.887122951899357],
              [-3.352528640253087, 54.886857378200951],
              [-3.351627587454581, 54.88713792276603],
              [-3.353997270571325, 54.889322371840997],
              [-3.353089134680584, 54.889811508082971],
              [-3.351612814509217, 54.889257260800228],
              [-3.350770253680101, 54.889423909392633],
              [-3.350883406626752, 54.888984976093496],
              [-3.348273159323817, 54.887947203743281],
              [-3.350697415349634, 54.889064334188554],
              [-3.350666625453778, 54.889541894933238],
              [-3.351893964864319, 54.889412305875332],
              [-3.353683337088544, 54.890235372292665],
              [-3.353385346366889, 54.890694342303277],
              [-3.354094162393083, 54.89039795419442],
              [-3.354770121495133, 54.890980873222667],
              [-3.354278207937657, 54.891561537527743],
              [-3.353450747394768, 54.891389220030867],
              [-3.354309045378042, 54.891690608855406],
              [-3.354131411067965, 54.891926255608162],
              [-3.352993755201604, 54.892314596840627],
              [-3.350550161024235, 54.892490978560325],
              [-3.350725103294877, 54.893199018729398],
              [-3.349772499051787, 54.893574486779002],
              [-3.34673323586502, 54.892210720031748],
              [-3.348508762176669, 54.891545742923704],
              [-3.349190696472591, 54.889697599789585],
              [-3.348705450506907, 54.889033445575272],
              [-3.347427961859978, 54.888828337743213],
              [-3.346238986926044, 54.889173148261662],
              [-3.345342174373456, 54.888695082556005],
              [-3.342068815857342, 54.888808585688949],
              [-3.341957061770424, 54.887932674322833],
              [-3.341375394271203, 54.887740483598769],
              [-3.342360701695334, 54.88614695964776],
              [-3.341129497336206, 54.885642002312657],
              [-3.339719112881268, 54.886219265205931],
              [-3.33837196756018, 54.88597798380205],
              [-3.340247281916031, 54.885270685461457],
              [-3.34139261536118, 54.885209510494292],
              [-3.342588380696395, 54.884227482115762],
              [-3.342762779633722, 54.883706096211107],
              [-3.340966916464198, 54.882812842752685],
              [-3.341223981995152, 54.882480175327089],
              [-3.340682694166935, 54.882750374370694],
              [-3.341856976473956, 54.883609160259596],
              [-3.342577940865011, 54.883726113983073],
              [-3.342474120883667, 54.884166733752288],
              [-3.341243623157418, 54.885135663841787],
              [-3.338848332651118, 54.885440694047993],
              [-3.338108412354314, 54.885881130402602],
              [-3.326891131795945, 54.8852438860456],
              [-3.337963592919026, 54.886074152186836],
              [-3.33945906818906, 54.886416255897899],
              [-3.340820848736773, 54.885877278740281],
              [-3.341705032551246, 54.885978955064679],
              [-3.34185472198622, 54.886542593465009],
              [-3.340837097328251, 54.887774287577052],
              [-3.341581220716416, 54.88830440091607],
              [-3.34171696443745, 54.889011089076909],
              [-3.344908369347371, 54.888917376056568],
              [-3.3461387304729, 54.889626313453995],
              [-3.347493456830037, 54.889155642452501],
              [-3.348663577384563, 54.889506635172268],
              [-3.347958494450891, 54.890102222443183],
              [-3.347831666518045, 54.891299820544035],
              [-3.347306247646119, 54.89139372397667],
              [-3.345782826208561, 54.891156276383832],
              [-3.342386118503176, 54.889395535128543],
              [-3.338173891165123, 54.888232346240564],
              [-3.334963394070328, 54.888454608549061],
              [-3.338375739461608, 54.888395485717737],
              [-3.34224590283731, 54.88949234854843],
              [-3.345705044128982, 54.891348564553994],
              [-3.344674963416171, 54.892253295579167],
              [-3.346252071207231, 54.89307073224095],
              [-3.346787207234739, 54.894014741848544],
              [-3.345802830947928, 54.89456128651959],
              [-3.344800586369211, 54.894478920500539],
              [-3.338997536593422, 54.890690240948196],
              [-3.336509582146338, 54.889902508960688],
              [-3.335266386426471, 54.890120190507595],
              [-3.334959081535179, 54.888654170553082],
              [-3.335117985033146, 54.890158669143474],
              [-3.336670832241873, 54.890016668666959],
              [-3.338918658614756, 54.890803449807606],
              [-3.344547026628569, 54.894497005635216],
              [-3.344430347892287, 54.894832619012043],
              [-3.341097500580199, 54.896080030110411],
              [-3.339756333083861, 54.89626378826383],
              [-3.337236611898787, 54.893446225573413],
              [-3.334942534871824, 54.892254601357749],
              [-3.332606187726904, 54.89157296881227],
              [-3.333671391413329, 54.890921393132317],
              [-3.333733762154339, 54.890449782146376],
              [-3.333138352216795, 54.890219058268961],
              [-3.333423213424372, 54.889355861958073],
              [-3.332965249010102, 54.890264995094661],
              [-3.333576555930265, 54.890552164258708],
              [-3.33307494349364, 54.891273046902299],
              [-3.331908701079755, 54.891367624039326],
              [-3.330840494556463, 54.890605540500758],
              [-3.332617211299181, 54.88869606460505],
              [-3.331304829847952, 54.890029768699122],
              [-3.329748909197956, 54.88965317636687],
              [-3.329966396972668, 54.88858851546],
              [-3.330451337479772, 54.888401664178957],
              [-3.330010417244308, 54.888457719924212],
              [-3.329454663245594, 54.889618651467288],
              [-3.3283987027198, 54.889225783986539],
              [-3.329143676676837, 54.888223656560619],
              [-3.328355846173236, 54.887213134087965],
              [-3.328952881438189, 54.888300337579118],
              [-3.328023743666227, 54.889202024224552],
              [-3.331054479719024, 54.890097219877958],
              [-3.330135628415214, 54.890553946533984],
              [-3.330335015502161, 54.891352516605444],
              [-3.329648309326894, 54.891568537431674],
              [-3.328313682265625, 54.891616392628556],
              [-3.325881577297182, 54.890625622683736],
              [-3.324762896236098, 54.890506617082139],
              [-3.324716380311953, 54.890089406244307],
              [-3.325496711858955, 54.889683478469323],
              [-3.325504045296374, 54.889185354980114],
              [-3.326034652433368, 54.889068279136602],
              [-3.325660564970875, 54.888928568572389],
              [-3.325505189072825, 54.889107580440708],
              [-3.325421964158164, 54.889203465570219],
              [-3.324709163411868, 54.890024671574501],
              [-3.324600771165662, 54.890149544470312],
              [-3.324328053298129, 54.890463727312785],
              [-3.322466951886843, 54.889622139786269],
              [-3.32303376636948, 54.889220533266723],
              [-3.32419483362913, 54.889634769059768],
              [-3.32564371336097, 54.888652847529578],
              [-3.325906671781492, 54.887308198101316],
              [-3.326551242137453, 54.887045026393153],
              [-3.325992475605278, 54.886598176375593],
              [-3.326498864067486, 54.886542318822535],
              [-3.325775526316882, 54.886543027071525],
              [-3.326354112644677, 54.887023812749739],
              [-3.325725739863237, 54.887258047776172],
              [-3.32544131611956, 54.888661347400884],
              [-3.323976128006979, 54.889478979789885],
              [-3.323030374800206, 54.889021954662553],
              [-3.321829218806154, 54.889620094699751],
              [-3.320980966448169, 54.88966437294664],
              [-3.317640860879446, 54.888883724195217],
              [-3.31751407278942, 54.888438439585386],
              [-3.318321473558077, 54.888623800498976],
              [-3.318539290052829, 54.887989641242385],
              [-3.319416880385075, 54.888080766758442],
              [-3.319310572292963, 54.887594819323638],
              [-3.319844185927719, 54.887417367691143],
              [-3.319518288552702, 54.886795404656318],
              [-3.319651968287632, 54.887403279505996],
              [-3.319067040134144, 54.887632514217209],
              [-3.319367929538447, 54.887870999763763],
              [-3.318488635664336, 54.887823030610477],
              [-3.318275612067766, 54.888460732622072],
              [-3.317356590612337, 54.888294556378455],
              [-3.317266375522658, 54.88892193727947],
              [-3.316753832115328, 54.888933785917459],
              [-3.314664847730115, 54.88855558946878],
              [-3.31596936863992, 54.887108915040493],
              [-3.314545086819285, 54.888325018021966],
              [-3.312781465498424, 54.888218273358355],
              [-3.314409766414526, 54.887233648707081],
              [-3.314715318410665, 54.88661112891414],
              [-3.313576405447397, 54.887590466098132],
              [-3.312580452001139, 54.887933756806703],
              [-3.312616869693533, 54.886177280168219],
              [-3.312194291395082, 54.887325007976798],
              [-3.312559128497461, 54.888236853108459],
              [-3.311518470495173, 54.888260676716079],
              [-3.309486804730198, 54.88762833605427],
              [-3.310911743958101, 54.885948550554922],
              [-3.309236256128372, 54.887594189919483],
              [-3.308592570149085, 54.886974724957042],
              [-3.310471730686178, 54.885456311381716],
              [-3.309183776187589, 54.886168500436135],
              [-3.309161568478707, 54.885099272904455],
              [-3.309118408725185, 54.885979576875933],
              [-3.308414924022481, 54.886832845000818],
              [-3.308778942459808, 54.887433755754657],
              [-3.307973384713485, 54.886485305939289],
              [-3.305825198237646, 54.885908980815941],
              [-3.307874450059578, 54.884298008676964],
              [-3.307726224343538, 54.883524016534061],
              [-3.307476130771636, 54.884225008194285],
              [-3.305659334514937, 54.883672972651496],
              [-3.307264514247402, 54.884333334124591],
              [-3.305315428047309, 54.885909068319201],
              [-3.302201864947314, 54.885124664197512],
              [-3.301554659820038, 54.884729877955998],
              [-3.302417053168222, 54.884643343835599],
              [-3.30202315397221, 54.884127213580996],
              [-3.303126525740412, 54.882958738456018],
              [-3.301894691814417, 54.884010859549242],
              [-3.301978235858531, 54.884667820427801],
              [-3.299756688355748, 54.883937596959228],
              [-3.298095563657033, 54.882938920999713],
              [-3.297922206162359, 54.88254084749893],
              [-3.298441428447484, 54.88254518329628],
              [-3.299790461378268, 54.881633895044089],
              [-3.300755762782389, 54.88180414532917],
              [-3.300135933690671, 54.881531283194441],
              [-3.300399181199476, 54.881138424078159],
              [-3.298990387696004, 54.880085432738895],
              [-3.298348446144883, 54.879605594865595],
              [-3.298208569355108, 54.879909057153348],
              [-3.298846895531082, 54.88005306178141],
              [-3.300256908785014, 54.881318790920901],
              [-3.299734448360804, 54.881624015537469],
              [-3.298316849422863, 54.882452150500271],
              [-3.297308096440033, 54.882154672329349],
              [-3.296113400289241, 54.880337653089292],
              [-3.296220430387061, 54.880155853711216],
              [-3.296608559750574, 54.879496566338126],
              [-3.295752659594524, 54.878762467399227],
              [-3.296894649506222, 54.878358439351544],
              [-3.295541332237656, 54.8787341658212],
              [-3.296477385932175, 54.879537510037629],
              [-3.296206196189773, 54.880133666378931],
              [-3.294013033588348, 54.876714879521991],
              [-3.294639936374531, 54.87580229694538],
              [-3.294082740245349, 54.875437068086079],
              [-3.294484739804358, 54.875822824310504],
              [-3.293797490037822, 54.876505978955613],
              [-3.294158250427072, 54.877598562484593],
              [-3.2920643708992, 54.876019355354373],
              [-3.290246289387054, 54.875213668867026],
              [-3.290400956037382, 54.874347465522519],
              [-3.289803735295922, 54.873926234965332],
              [-3.289679781174551, 54.87383880643587],
              [-3.289273719769727, 54.873552396013913],
              [-3.289362570051325, 54.872820800560952],
              [-3.288570509407447, 54.872320537443386],
              [-3.288184904792155, 54.872592444495865],
              [-3.288526801425752, 54.872464795008668],
              [-3.289241101937472, 54.872872418044217],
              [-3.289088326041533, 54.873554364188934],
              [-3.289616821100048, 54.873874203961293],
              [-3.289066336799644, 54.874183695269856],
              [-3.288831620986291, 54.873812322857439],
              [-3.28732074451497, 54.874034153562391],
              [-3.28788142034757, 54.874217836623785],
              [-3.288708409530458, 54.873907096405709],
              [-3.289030628562666, 54.874285628643129],
              [-3.289737562206825, 54.87397345956478],
              [-3.290217236516639, 54.874499502347817],
              [-3.289676677711269, 54.875336551912568],
              [-3.2922162426706, 54.876333186960814],
              [-3.294475775026767, 54.878751819351777],
              [-3.291304629313658, 54.879261896037775],
              [-3.290956840049333, 54.878085586685408],
              [-3.289307543265176, 54.877394025326431],
              [-3.287191855988033, 54.875662185962057],
              [-3.285809475111194, 54.875320941384359],
              [-3.288109444878959, 54.87643163850764],
              [-3.288777939696636, 54.877990099531345],
              [-3.290374057317888, 54.878236472775839],
              [-3.290872986127565, 54.879314116545174],
              [-3.289598464582644, 54.88020749444663],
              [-3.283735551086242, 54.877198718842003],
              [-3.281928668553368, 54.875662139148403],
              [-3.281853735600841, 54.874970923524764],
              [-3.283999625310198, 54.87416967993957],
              [-3.284592960479252, 54.873948125118559],
              [-3.285413149678419, 54.873641852491303],
              [-3.285756796695261, 54.872981256984076],
              [-3.285193337015162, 54.873541726042376],
              [-3.284051438287211, 54.873800951136751],
              [-3.284006699010182, 54.874119334886764],
              [-3.281828195216994, 54.874755502732107],
              [-3.281782918298375, 54.873770994462284],
              [-3.284862218070823, 54.87033503527239],
              [-3.286115428627144, 54.867994650987129],
              [-3.287084754227549, 54.867314295575611],
              [-3.286869973802276, 54.866588615564645],
              [-3.286922100615334, 54.867393308754146],
              [-3.286157560516461, 54.867915965611076],
              [-3.285827127556504, 54.86814185215141],
              [-3.285134888639754, 54.86479968266768],
              [-3.282283172289019, 54.862334109754862],
              [-3.282717002497267, 54.861816362883189],
              [-3.281963430015324, 54.861930369666361],
              [-3.281932956239944, 54.861510094285634],
              [-3.284503208808852, 54.860565344913852],
              [-3.285582950173247, 54.859098901538609],
              [-3.289596548848049, 54.858966465921604],
              [-3.290352942058538, 54.86016270505889],
              [-3.291451609410295, 54.860367613715191],
              [-3.295753768856224, 54.858736445773481],
              [-3.297252399713122, 54.857570088553665],
              [-3.296223635282433, 54.855657827899428],
              [-3.295155024402181, 54.855075182191129],
              [-3.291566520147613, 54.854166166364024],
              [-3.289274805395601, 54.854204904419149],
              [-3.287997075568494, 54.853459053586249],
              [-3.286503039927604, 54.853696877045131],
              [-3.285221876791905, 54.853967478509489],
              [-3.283519791202752, 54.853050823506052],
              [-3.285028100607429, 54.854045920336276],
              [-3.28792233856845, 54.853557806249405],
              [-3.289134840215007, 54.854309742784643],
              [-3.291445017631235, 54.854263621298884],
              [-3.294773100977611, 54.855115204474693],
              [-3.29601075359805, 54.855723010570152],
              [-3.297015746570911, 54.857380292079419],
              [-3.295791873216492, 54.85851585420059],
              [-3.291996892478327, 54.860026592466532],
              [-3.289937609303405, 54.858816352599817],
              [-3.286320892883369, 54.858740587632532],
              [-3.284782031311722, 54.858995939063576],
              [-3.284003191330903, 54.859391523226428],
              [-3.283039359017942, 54.860683275917125],
              [-3.280935691381217, 54.86135795258857],
              [-3.280210991810358, 54.861891342236952],
              [-3.280938270071596, 54.863797927304539],
              [-3.283489812833526, 54.864367727267975],
              [-3.284575698936875, 54.867206058330019],
              [-3.283008887944397, 54.869927743700153],
              [-3.28200454168385, 54.869706483954232],
              [-3.281319642955297, 54.869910712067359],
              [-3.280883840170862, 54.869953332490446],
              [-3.279456820323211, 54.869906806139589],
              [-3.279797875048748, 54.870145863911155],
              [-3.281147846000255, 54.869961938537735],
              [-3.28218116372623, 54.869821451512585],
              [-3.282807933617572, 54.870175214816221],
              [-3.280259069399153, 54.873732248170377],
              [-3.280018774183602, 54.875394698229876],
              [-3.280630711457293, 54.877588287593255],
              [-3.278346607858915, 54.877417332623686],
              [-3.278199328410489, 54.876752939349444],
              [-3.277794038570465, 54.877785327712935],
              [-3.278304011676616, 54.877499563535679],
              [-3.280557364466651, 54.87788024287206],
              [-3.283632825120555, 54.880340794752705],
              [-3.284323872829961, 54.881974531960324],
              [-3.283511290125495, 54.882009184917059],
              [-3.282573379663592, 54.88271109937741],
              [-3.281737470519302, 54.882649824869304],
              [-3.28188250954315, 54.883192012915785],
              [-3.281145401750793, 54.883198891989878],
              [-3.281784963475856, 54.883605549760425],
              [-3.282081250459843, 54.882872670872779],
              [-3.284186421733104, 54.882651814727893],
              [-3.286011417304938, 54.884548551820451],
              [-3.28626050016441, 54.885760968703622],
              [-3.286086590106839, 54.886663316630106],
              [-3.283991627324581, 54.887015308028438],
              [-3.286055332634342, 54.887003359516292],
              [-3.28661784377947, 54.888412868170413],
              [-3.286048165682725, 54.888588757392739],
              [-3.285104659439143, 54.888090074957674],
              [-3.283957303056042, 54.888337670912534],
              [-3.282885660668547, 54.885621414879552],
              [-3.281030157251725, 54.883988275067502],
              [-3.279973974044588, 54.884011093952843],
              [-3.280858892129673, 54.884044003906055],
              [-3.28247544735761, 54.885565533689416],
              [-3.28380942354113, 54.888687932883066],
              [-3.285178041601492, 54.888436199961077],
              [-3.285642303567195, 54.888964220712801],
              [-3.287305849968425, 54.889356411816543],
              [-3.287914445686788, 54.890497610807031],
              [-3.288770646468504, 54.890993603263269],
              [-3.288045473387924, 54.89260009755683],
              [-3.286518203461451, 54.89311237034029],
              [-3.285073958088805, 54.893148328952691],
              [-3.284527644832549, 54.893031883331901],
              [-3.285348030679498, 54.892403096364937],
              [-3.283930624674722, 54.893085827009969],
              [-3.283873112294234, 54.89220929624031],
              [-3.283327844194587, 54.893007718596408],
              [-3.282526570846598, 54.892914627768931],
              [-3.28212619021189, 54.892679798468698],
              [-3.283761515197777, 54.891691022688192],
              [-3.283091054185657, 54.891837406719354],
              [-3.283633007361798, 54.891082158229771],
              [-3.282115920486102, 54.892258413061747],
              [-3.281728716125234, 54.891063623709861],
              [-3.281259109413144, 54.892527180547049],
              [-3.278606925779089, 54.89208420539719],
              [-3.276126170930366, 54.892080636729524],
              [-3.275131390383842, 54.891734297452757],
              [-3.274641564960315, 54.890689749169773],
              [-3.27818794408633, 54.891310334770772],
              [-3.28004191562606, 54.890676094849056],
              [-3.281227351291628, 54.890642925910115],
              [-3.282315666458528, 54.889859451028428],
              [-3.281119588647153, 54.890538913793485],
              [-3.280235240411084, 54.890528466696573],
              [-3.281856482655702, 54.888950312060679],
              [-3.28183857727106, 54.888190194060897],
              [-3.280522698321472, 54.890055412402887],
              [-3.278633924502992, 54.8910171557431],
              [-3.27769341085849, 54.891104341701961],
              [-3.277095892012792, 54.890255056480164],
              [-3.278020512128387, 54.889666563566074],
              [-3.278918327880167, 54.889856626376925],
              [-3.279834224562988, 54.889338310480987],
              [-3.279031884500585, 54.888963014033422],
              [-3.279626274981137, 54.888581992205147],
              [-3.279024706664811, 54.888146164348242],
              [-3.279019048487111, 54.886491702791822],
              [-3.278603962804809, 54.887906145811364],
              [-3.277902090976085, 54.887108290419171],
              [-3.277942201778784, 54.886307119734603],
              [-3.277755721056585, 54.887212283040775],
              [-3.279423553947117, 54.88858053297141],
              [-3.278686303540418, 54.888881275479164],
              [-3.279333105584448, 54.889317527270393],
              [-3.278854063482418, 54.88969823180831],
              [-3.27769620073602, 54.889518094034123],
              [-3.276787671927063, 54.890073163170854],
              [-3.277185093001818, 54.891004539026461],
              [-3.275336609676661, 54.890580899379813],
              [-3.275394789257842, 54.889659113791311],
              [-3.277384248849884, 54.888085240714538],
              [-3.277247123965114, 54.886954311209962],
              [-3.276873516069791, 54.888400665140971],
              [-3.275928398429618, 54.889080134265178],
              [-3.275630597665153, 54.88898080966981],
              [-3.276081845397721, 54.888361353787097],
              [-3.275297966261905, 54.887779136261692],
              [-3.27615037748174, 54.887816915772135],
              [-3.276680607558329, 54.887230778430435],
              [-3.277167813783983, 54.88558102103363],
              [-3.276553242781208, 54.886309144412273],
              [-3.275873088934162, 54.886344151195225],
              [-3.275473478860623, 54.885883716569737],
              [-3.275778772965948, 54.88651679508623],
              [-3.276549343261139, 54.886432308351061],
              [-3.276484715390203, 54.887149257573753],
              [-3.275987889720819, 54.88770718319779],
              [-3.275495364924076, 54.887464313253211],
              [-3.274986486042759, 54.887591880441398],
              [-3.275648103369675, 54.888449489830805],
              [-3.274380397587462, 54.888985845146273],
              [-3.275050812195911, 54.889332001037495],
              [-3.272455409015317, 54.89107664753741],
              [-3.271944948385521, 54.891056830328139],
              [-3.272028610809847, 54.890743204327329],
              [-3.271010348961415, 54.890985730204434],
              [-3.27085660691838, 54.890559554656747],
              [-3.269836696246189, 54.890105590545218],
              [-3.270776733116701, 54.890350093047665],
              [-3.271924740231296, 54.8900693634492],
              [-3.272281891447282, 54.889479666109906],
              [-3.271612034008491, 54.889199993519739],
              [-3.27227501106307, 54.889162494693231],
              [-3.273643788435087, 54.887925008915879],
              [-3.27337489964074, 54.887259190161089],
              [-3.273410467283447, 54.887892405795192],
              [-3.272790381732988, 54.888547771878663],
              [-3.272114029112491, 54.889100372673148],
              [-3.271448416976825, 54.889153176223942],
              [-3.271753795908743, 54.889493285069378],
              [-3.271219381451365, 54.889501023820301],
              [-3.270857739040547, 54.889506258797105],
              [-3.271218993271419, 54.888650504467904],
              [-3.272952752533285, 54.88746762181605],
              [-3.271009160814919, 54.888621245712173],
              [-3.27068079895328, 54.889531476392548],
              [-3.271228756511432, 54.889511974398282],
              [-3.271612603277004, 54.889960293385442],
              [-3.270663815963579, 54.89018052015475],
              [-3.269593219201607, 54.889750450643952],
              [-3.269811059762849, 54.888547501538312],
              [-3.269183602777094, 54.889416817968161],
              [-3.269665647958506, 54.890466861208552],
              [-3.268558995304612, 54.8906752409031],
              [-3.268042720141795, 54.889825063587601],
              [-3.26921468345402, 54.888868282267701],
              [-3.267901162838556, 54.889686343190512],
              [-3.268342586516541, 54.89083387576359],
              [-3.267614929874137, 54.891791404152677],
              [-3.266408185870515, 54.891693451131673],
              [-3.267373160744663, 54.891242121646073],
              [-3.266381386794914, 54.891286616830918],
              [-3.26652545603, 54.889716872835109],
              [-3.267742362300577, 54.889445350100672],
              [-3.268423055321454, 54.888681532696879],
              [-3.268009090664845, 54.88815921317498],
              [-3.267643709671935, 54.888240315911609],
              [-3.268196871309857, 54.888677603633425],
              [-3.267545539345994, 54.889383596145777],
              [-3.266524275329759, 54.889480525455788],
              [-3.265774770734435, 54.888896990033864],
              [-3.265265977345127, 54.888860777378746],
              [-3.266223519903726, 54.888193119216353],
              [-3.266484594999682, 54.887073305219587],
              [-3.26515318918134, 54.888852749515095],
              [-3.265017600719975, 54.889033955984068],
              [-3.264886542110812, 54.889209107267099],
              [-3.263937013572817, 54.889107552463223],
              [-3.262994055392704, 54.88846849589487],
              [-3.266162884674041, 54.88566389285937],
              [-3.259972731510246, 54.890518367805413],
              [-3.262820291204075, 54.8885952227687],
              [-3.263676089392954, 54.889089597188281],
              [-3.263430081019073, 54.889245009204586],
              [-3.264846476799275, 54.889472845327923],
              [-3.265606027210974, 54.889034453070408],
              [-3.266414431336752, 54.890054146533373],
              [-3.266043048831521, 54.891137363916705],
              [-3.264983068785416, 54.891592368365281],
              [-3.264337664497376, 54.891395980778299],
              [-3.264931198182, 54.890932359925735],
              [-3.264015124135188, 54.891601546482129],
              [-3.263104588080711, 54.890356425794906],
              [-3.263740050510923, 54.889937309928804],
              [-3.262837815360264, 54.890351111379644],
              [-3.263738693211383, 54.891288078537237],
              [-3.262535606616665, 54.891605250973086],
              [-3.262378382331162, 54.891064965811374],
              [-3.261350941632881, 54.890866241480495],
              [-3.259067417674464, 54.892234396273921],
              [-3.259367557569101, 54.892409228290255],
              [-3.261420938731863, 54.890960777318966],
              [-3.262216553427755, 54.891125063461295],
              [-3.26234600120449, 54.891674624548713],
              [-3.26325218985124, 54.891933916233725],
              [-3.262118824879223, 54.89298550002755],
              [-3.263467120435176, 54.892026044779406],
              [-3.26331135623901, 54.891483049482943],
              [-3.264542594699191, 54.892014852952848],
              [-3.263404512476767, 54.893014373102226],
              [-3.262110839519018, 54.893278560841779],
              [-3.261765443363517, 54.893950785704092],
              [-3.262303769056266, 54.893315200341647],
              [-3.263154683524712, 54.89330186093288],
              [-3.26080677469106, 54.895156916225488],
              [-3.260866060366557, 54.895457366879448],
              [-3.260448383173538, 54.895663910209159],
              [-3.260571364033851, 54.894808864690994],
              [-3.259989272238506, 54.894343982912609],
              [-3.260377836943149, 54.894853112032685],
              [-3.260165273670345, 54.895585964206127],
              [-3.259687956612772, 54.895630458104904],
              [-3.259133533891912, 54.895552626916682],
              [-3.257749131603124, 54.894410337130175],
              [-3.258866660289875, 54.895495179802523],
              [-3.258153738350587, 54.895384835925313],
              [-3.258072796385318, 54.895689436304885],
              [-3.256621192142306, 54.89570519805644],
              [-3.25629328747018, 54.895378029419525],
              [-3.256300463678613, 54.89570867827647],
              [-3.256654739924116, 54.895738670126924],
              [-3.258164077360071, 54.895866434296742],
              [-3.258207233579533, 54.895500214669305],
              [-3.259472463147175, 54.895920278057197],
              [-3.25736885733401, 54.897718808959681],
              [-3.256779689861131, 54.897427435065232],
              [-3.257036591480527, 54.897064396588249],
              [-3.253402784982322, 54.895442040707628],
              [-3.256568942238697, 54.897119562510973],
              [-3.256323164250021, 54.897439347147284],
              [-3.256940330053492, 54.897878719651253],
              [-3.255129887438141, 54.897327661592001],
              [-3.254441524883999, 54.897757162895125],
              [-3.255997696830423, 54.897653908209868],
              [-3.256904795163193, 54.898289794943985],
              [-3.254837530263249, 54.898406430167441],
              [-3.257202338149737, 54.898530264570191],
              [-3.255844595766836, 54.899247993825902],
              [-3.25543105142164, 54.901092812724215],
              [-3.252013921228372, 54.903045012034532],
              [-3.251625062358069, 54.902927694258487],
              [-3.252435438663828, 54.901250451408394],
              [-3.251536498486606, 54.900575803350968],
              [-3.252144334164241, 54.899910790323318],
              [-3.25290598377499, 54.899758197347523],
              [-3.253156369280285, 54.899708031878198],
              [-3.252604750636789, 54.897511898453388],
              [-3.252432339260826, 54.898689179994498],
              [-3.252894422492234, 54.899732104119146],
              [-3.251977659057821, 54.899816347039511],
              [-3.251309378995767, 54.900342682949976],
              [-3.250492440561747, 54.900249541913787],
              [-3.249585801554001, 54.900734285181592],
              [-3.250590964168352, 54.900359068093209],
              [-3.251091764069472, 54.900466249228089],
              [-3.252094372847245, 54.901215323082027],
              [-3.251858093092655, 54.901742600571616],
              [-3.248568835420256, 54.902089200613382],
              [-3.249632501185256, 54.902090845429498],
              [-3.250063635902962, 54.90286828069199],
              [-3.249084072695513, 54.903367250591899],
              [-3.249157347724658, 54.903820322809239],
              [-3.249306941294051, 54.903362262729104],
              [-3.250472450494097, 54.902874857645081],
              [-3.250469847027863, 54.9041492441727],
              [-3.250945381961561, 54.904094021056679],
              [-3.252229177170676, 54.904904904623152],
              [-3.254167544705964, 54.904293562678383],
              [-3.254805658946777, 54.905303405793447],
              [-3.255372755008392, 54.910093920523082],
              [-3.254342015248446, 54.910856780045236],
              [-3.250650138831878, 54.91177466867363],
              [-3.248710599816098, 54.911706545871404],
              [-3.245658725434952, 54.910826592079189],
              [-3.244968572656404, 54.910450819752633],
              [-3.243524903365788, 54.907592458963052],
              [-3.24269045253838, 54.90754258178891],
              [-3.242040159712209, 54.907090894474486],
              [-3.240950556428595, 54.90706158773272],
              [-3.241205631763227, 54.906683325086597],
              [-3.240301093716917, 54.907092484937202],
              [-3.241894613421964, 54.907125634321062],
              [-3.242599304434417, 54.907621701062091],
              [-3.243398613606188, 54.907695304760054],
              [-3.244741442747777, 54.910369567850239],
              [-3.238930867973958, 54.908946105617694],
              [-3.234879524122805, 54.909540951665463],
              [-3.232697138724193, 54.909076019527802],
              [-3.229978439485976, 54.906060578467759],
              [-3.224552712232093, 54.904072646894654],
              [-3.222715813102021, 54.902077164350338],
              [-3.221576616934384, 54.901795649290989],
              [-3.21998187662818, 54.901869191978506],
              [-3.216332807635473, 54.902860201375809],
              [-3.213108536046073, 54.904395064579582],
              [-3.211442053508581, 54.903433911178396],
              [-3.20942704946388, 54.903641816231861],
              [-3.207068801042856, 54.905149020012445],
              [-3.206180268572653, 54.905373532448365],
              [-3.205228008428115, 54.907015016511046],
              [-3.202648332339664, 54.907228408503535],
              [-3.200838160062453, 54.907827770785751],
              [-3.20086743403221, 54.908391862465727],
              [-3.202447966348021, 54.908930724843763],
              [-3.202239582020007, 54.909370452053082],
              [-3.201064443416183, 54.909350620810713],
              [-3.198386115951444, 54.908139565474016],
              [-3.197447673984512, 54.90884081426848],
              [-3.195947816539676, 54.909179118339992],
              [-3.195785678703657, 54.910128837669276],
              [-3.194404592103678, 54.911530007895784],
              [-3.192244797420774, 54.911341825635176],
              [-3.191983711979954, 54.909556102002867],
              [-3.191756388620892, 54.909277822528814],
              [-3.18975545431246, 54.908971207886516],
              [-3.187893554235442, 54.909676928281684],
              [-3.187006147948235, 54.909373327038992],
              [-3.186910317774725, 54.909340540687992],
              [-3.186929712699774, 54.909300636113521],
              [-3.187517725072433, 54.908090812116995],
              [-3.188410643064064, 54.907692047427794],
              [-3.189176240143113, 54.907736680195846],
              [-3.19062230034055, 54.908481914139287],
              [-3.191717819733937, 54.907814226961811],
              [-3.192302616675317, 54.907282752524189],
              [-3.19260222179025, 54.906654319952864],
              [-3.190597678517233, 54.906538278438532],
              [-3.189719776498476, 54.905435193182413],
              [-3.19031285558268, 54.903704787453066],
              [-3.192156892569537, 54.900927709155006],
              [-3.191983361571441, 54.900332677874061],
              [-3.190947045479826, 54.900199050827922],
              [-3.189731104615693, 54.901377472713449],
              [-3.188663848502197, 54.901676401674344],
              [-3.187759371723858, 54.900777566102974],
              [-3.186240893769472, 54.900475164137887],
              [-3.185982108563886, 54.899470253341981],
              [-3.185148839027815, 54.898762125476082],
              [-3.185274938806052, 54.898489489087289],
              [-3.186167771195364, 54.898359450185865],
              [-3.189368140883869, 54.898852096993586],
              [-3.190065885605356, 54.898716749813197],
              [-3.189686320139406, 54.898060824832157],
              [-3.18549123714739, 54.896510247780817],
              [-3.185791395949352, 54.896058868765977],
              [-3.187080123186022, 54.896031003627897],
              [-3.187506609647039, 54.895740150938686],
              [-3.187056430614468, 54.894324611013602],
              [-3.186178786939748, 54.893483017950864],
              [-3.189762304580707, 54.893021904589631],
              [-3.190080680143616, 54.892874179063895],
              [-3.18999144599974, 54.892584732874425],
              [-3.189124858290603, 54.892162310723023],
              [-3.188126262663776, 54.891418550595489],
              [-3.187817186992958, 54.891141180072147],
              [-3.187574308825696, 54.890411116999424],
              [-3.187067512666142, 54.890407982772423],
              [-3.185765802265053, 54.890730747967851],
              [-3.185234696916227, 54.890749412108264],
              [-3.184163696225372, 54.891071705908566],
              [-3.182894666515086, 54.892032195824022],
              [-3.183353369547655, 54.892096028758601],
              [-3.184539997275384, 54.891037481029031],
              [-3.187274231989129, 54.89049223720572],
              [-3.188174489862123, 54.89178564585908],
              [-3.189551761941023, 54.8924802604676],
              [-3.189898026171002, 54.892654889069277],
              [-3.189860979662519, 54.892710555088321],
              [-3.189714000394095, 54.892931400841022],
              [-3.189711396516305, 54.892935313208817],
              [-3.189613913550502, 54.89294701998449],
              [-3.185979399366545, 54.893383412205189],
              [-3.187293363979876, 54.895699996740099],
              [-3.185640743444308, 54.895973165823875],
              [-3.185278017466198, 54.896417965425847],
              [-3.186170690861899, 54.897026655836783],
              [-3.189551915222684, 54.898156504175383],
              [-3.189822974974556, 54.898675991905613],
              [-3.186234172861068, 54.898231186983047],
              [-3.185152365744931, 54.898404410243543],
              [-3.184945038962068, 54.898725469734785],
              [-3.185787709937761, 54.899488327634216],
              [-3.186076507638983, 54.900559449244774],
              [-3.187614039938531, 54.900873350031105],
              [-3.188505066291831, 54.901792089559201],
              [-3.189895473172779, 54.901451353260121],
              [-3.191121216471879, 54.900288111446436],
              [-3.191803477502749, 54.900419818952813],
              [-3.191935986074931, 54.900946053398435],
              [-3.189543157004462, 54.904682018824488],
              [-3.189540217465858, 54.90564005656784],
              [-3.190383513144175, 54.906627550525513],
              [-3.192099607194254, 54.906641279019297],
              [-3.192451881224409, 54.906897544708158],
              [-3.190713647661882, 54.908300381241084],
              [-3.188928429237443, 54.907531507135161],
              [-3.187506091276878, 54.907907592163731],
              [-3.186683889674842, 54.909066853448259],
              [-3.186831812194886, 54.909389893726555],
              [-3.187383557269309, 54.909732240606708],
              [-3.188222483320021, 54.909776162864873],
              [-3.189889965739654, 54.909089416924601],
              [-3.191372525375569, 54.909325623429069],
              [-3.191817395941196, 54.909535650927943],
              [-3.191831273950688, 54.909542202871812],
              [-3.191861465368563, 54.909721896367394],
              [-3.19215494904522, 54.911468524526065],
              [-3.194664430332699, 54.911612829170579],
              [-3.196118831274118, 54.910001538246675],
              [-3.196196366928718, 54.909302488711326],
              [-3.197802064240225, 54.909008971608337],
              [-3.198441754041018, 54.908332236201716],
              [-3.200795131332446, 54.909525832512081],
              [-3.202555393063244, 54.909499434778027],
              [-3.202778285355171, 54.908866343749665],
              [-3.201254003490357, 54.908069003741758],
              [-3.203114421116177, 54.907532945168988],
              [-3.20588450908874, 54.907270921284983],
              [-3.206521298148763, 54.906085508739935],
              [-3.20971061516937, 54.903892425545543],
              [-3.21120806073264, 54.903795723457193],
              [-3.211993516623265, 54.904191405047122],
              [-3.212222876540851, 54.905024905441785],
              [-3.214515370231265, 54.905432484642574],
              [-3.215317137236993, 54.905228550398704],
              [-3.21635412803833, 54.903567263504755],
              [-3.220018871692756, 54.902473644811018],
              [-3.221230816943144, 54.902376090899047],
              [-3.223071244606178, 54.904335612015153],
              [-3.224113295803161, 54.906963696296891],
              [-3.22574840975469, 54.907442392873811],
              [-3.228601935559918, 54.907210462855616],
              [-3.229735886251879, 54.907415576487352],
              [-3.233609065219352, 54.911352155658612],
              [-3.244291008265956, 54.911746497446508],
              [-3.245674377339895, 54.912393765277592],
              [-3.246581159340807, 54.91356895195473],
              [-3.247630072115793, 54.913890704589384],
              [-3.248952066761491, 54.913811517041069],
              [-3.252878960006188, 54.912618053036361],
              [-3.258738217820148, 54.911470061039104],
              [-3.260974069762175, 54.910188692587248],
              [-3.264811084916197, 54.906101946735006],
              [-3.266234255187266, 54.905280085544575],
              [-3.269466998749051, 54.903927049714035],
              [-3.274576259643513, 54.903085387133707],
              [-3.280141763640055, 54.903014284554551],
              [-3.282147673475012, 54.903513480838583],
              [-3.285866032363533, 54.904744051365235],
              [-3.285452543170652, 54.905178907550244],
              [-3.284345600600246, 54.905388330640513],
              [-3.284058337813478, 54.905967437718317],
              [-3.284872124622702, 54.905318954907841],
              [-3.28620866739791, 54.905315596153294],
              [-3.286230134205308, 54.904912748503037],
              [-3.28846984899694, 54.905890173404686],
              [-3.291371593018639, 54.906106507209181],
              [-3.292742041033945, 54.906624864945137],
              [-3.297070516883153, 54.907261745725712],
              [-3.299193066417379, 54.908172822502593],
              [-3.300995597381245, 54.909604945417307],
              [-3.300124739876999, 54.910122936224539],
              [-3.301579286893511, 54.909711031937604],
              [-3.301728371060894, 54.90925558739972],
              [-3.303138281359145, 54.909153297832503],
              [-3.309270029706888, 54.912036932303494],
              [-3.309891249537235, 54.912624282203097],
              [-3.308929201366771, 54.912734407211097],
              [-3.310513639579725, 54.912959079406392],
              [-3.31369337712509, 54.914889315778289],
              [-3.314264322730698, 54.916084713863661],
              [-3.31347416580491, 54.91888734067237],
              [-3.310900053776303, 54.921437810314359],
              [-3.307259152405866, 54.923870290373529],
              [-3.303208400187102, 54.927540077628031],
              [-3.301504476221148, 54.928464233618485],
              [-3.298987342068407, 54.929241584754791],
              [-3.290198754897674, 54.938332123304853],
              [-3.285426974584285, 54.941416701220994],
              [-3.283304379641445, 54.942117650577671],
              [-3.280337377232609, 54.942482359528242],
              [-3.27320983286869, 54.942054860058981],
              [-3.265334993612094, 54.94302860691834],
              [-3.264984276938744, 54.940013529281558],
              [-3.266243125415687, 54.93963734614551],
              [-3.266820602616663, 54.938995039496966],
              [-3.269280938106154, 54.937162993458081],
              [-3.268936097656894, 54.937067737479538],
              [-3.267211911709729, 54.938559788510773],
              [-3.266020009978699, 54.939591140736489],
              [-3.264582707402654, 54.939603408100908],
              [-3.265118824480114, 54.941613609777434],
              [-3.264926231828946, 54.943136211995508],
              [-3.256756624689052, 54.943381827216449],
              [-3.253993609246229, 54.943940596515944],
              [-3.249393632648439, 54.944067053479905],
              [-3.247864766406885, 54.944404492760178],
              [-3.242849644770342, 54.946459968612729],
              [-3.237726606925799, 54.948068783359766],
              [-3.23506762697204, 54.949747629643177],
              [-3.234025746669456, 54.949835499064186],
              [-3.231895690431042, 54.951713805435304],
              [-3.231488976896906, 54.951638841528236],
              [-3.232199249565179, 54.953214242330795],
              [-3.230681504164255, 54.952245548928985],
              [-3.226914656506426, 54.951818104410727],
              [-3.224867545096886, 54.952125432244493],
              [-3.22738999265064, 54.952044269767121],
              [-3.227052210145557, 54.952488938027606],
              [-3.220431197526986, 54.952738027195544],
              [-3.215212912964418, 54.953937035982747],
              [-3.211460939142923, 54.954470562987936],
              [-3.204389335838751, 54.9538695106625],
              [-3.201855205643193, 54.953407502073723],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000027",
        LAD13CDO: "16UC",
        LAD13NM: "Barrow-in-Furness",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-3.200536695570217, 54.064394809347363],
              [-3.20111015550159, 54.064093372241736],
              [-3.203451106032723, 54.065259102523598],
              [-3.203007195671127, 54.065707559590493],
              [-3.201782217800009, 54.06561914021794],
              [-3.200642115366227, 54.064871028491162],
              [-3.200536695570217, 54.064394809347363],
            ],
          ],
          [
            [
              [-3.172688219087129, 54.062242806594725],
              [-3.173738943289032, 54.061846964048641],
              [-3.176910352364489, 54.063504839363084],
              [-3.175869478547277, 54.065493311751204],
              [-3.174145141375575, 54.066496158739497],
              [-3.174094247058033, 54.065829735855829],
              [-3.172296678085153, 54.064289628392132],
              [-3.172688219087129, 54.062242806594725],
            ],
          ],
          [
            [
              [-3.252008701211177, 54.150737787380095],
              [-3.252880292384228, 54.150344028250501],
              [-3.255615915704425, 54.150426976546534],
              [-3.259117551540544, 54.14911139270653],
              [-3.262611625438949, 54.148510339822806],
              [-3.264513238907794, 54.147756948061996],
              [-3.264495727757511, 54.147031791154454],
              [-3.265119582898151, 54.1466036931809],
              [-3.264623167726879, 54.146541498808752],
              [-3.265419833515149, 54.146207756329147],
              [-3.264438448216796, 54.146257617288633],
              [-3.264453521003154, 54.145596832362266],
              [-3.265577756466043, 54.145362111683568],
              [-3.264571260631792, 54.1451902313405],
              [-3.265502831869306, 54.145214595482415],
              [-3.2654495176304, 54.144972476786975],
              [-3.264843017299255, 54.145014804078635],
              [-3.265629628773022, 54.144753969398323],
              [-3.264997595821637, 54.144661744169049],
              [-3.265717567697168, 54.144073542798374],
              [-3.264953464273582, 54.144369195273931],
              [-3.264826741090067, 54.144028978428601],
              [-3.26561328421573, 54.143515578228367],
              [-3.264385005303825, 54.144252929614339],
              [-3.264249379295784, 54.142967255456142],
              [-3.265571161867439, 54.142485982322015],
              [-3.264588604967443, 54.142494511935489],
              [-3.264362284944688, 54.141296975195409],
              [-3.263272142212602, 54.139631238119875],
              [-3.26476684585906, 54.138548649897601],
              [-3.262999833195367, 54.139536125385433],
              [-3.263301248080192, 54.138877727736151],
              [-3.262733209003553, 54.139426571398957],
              [-3.261574833317461, 54.138583948306547],
              [-3.260683309674715, 54.138512396780001],
              [-3.260962343365525, 54.138023216199933],
              [-3.261160387317316, 54.138495714109972],
              [-3.261847162676524, 54.137975293838437],
              [-3.261918034242306, 54.138048135665429],
              [-3.262142408007571, 54.138278745257125],
              [-3.262434961922746, 54.138579423489212],
              [-3.263664159311355, 54.138127903476693],
              [-3.262909515980623, 54.138127735056905],
              [-3.262499039184751, 54.138471792669513],
              [-3.262189119980492, 54.13824580736312],
              [-3.262719573244868, 54.137871768764867],
              [-3.261765215059106, 54.137847622777663],
              [-3.261218484341664, 54.138342307217023],
              [-3.261896733008506, 54.137088544886609],
              [-3.263274921007938, 54.137256966634126],
              [-3.26360365638823, 54.137297136891625],
              [-3.262805650993431, 54.136626009990337],
              [-3.262187348455717, 54.134514892633199],
              [-3.262572902292974, 54.136623058979453],
              [-3.263242540427478, 54.13722772837184],
              [-3.261794539598956, 54.136949401542289],
              [-3.26118643938499, 54.137942671473496],
              [-3.260708823731001, 54.137891050171284],
              [-3.260916670570147, 54.13641931430255],
              [-3.260414391558915, 54.137268560473977],
              [-3.260160299126719, 54.137307759698693],
              [-3.260199137268097, 54.137035325650295],
              [-3.259926859337566, 54.137343772510093],
              [-3.260154315196671, 54.137349732179196],
              [-3.260539617712147, 54.137359826671691],
              [-3.260521073652088, 54.137857962988242],
              [-3.260147850288282, 54.137614697867093],
              [-3.25952663137274, 54.137824332127963],
              [-3.258936747774464, 54.137301103378221],
              [-3.259962557196093, 54.137107909945442],
              [-3.260385382944584, 54.136313426518583],
              [-3.25976787224393, 54.137047929829599],
              [-3.259272175766639, 54.137055813750067],
              [-3.257550977641087, 54.136223533895667],
              [-3.257696921865755, 54.135856186840982],
              [-3.258968534292181, 54.136312647092225],
              [-3.259256259834133, 54.136430406820821],
              [-3.260211108508032, 54.135715743416277],
              [-3.259032427230058, 54.136281954788217],
              [-3.25844242734693, 54.135890643551875],
              [-3.259385083351748, 54.135732479158222],
              [-3.258251056043065, 54.135535815868394],
              [-3.258763531371142, 54.135274295427749],
              [-3.258280369155488, 54.1352910323996],
              [-3.258068789246569, 54.135944390762958],
              [-3.257711741941192, 54.135818882255656],
              [-3.256134887809691, 54.13520378907463],
              [-3.255795062807363, 54.134140445122362],
              [-3.25658856905744, 54.134971653553237],
              [-3.257862662717812, 54.135144398274718],
              [-3.258210179834083, 54.134944824558225],
              [-3.256636919801382, 54.134849809040361],
              [-3.256525697557294, 54.13436111751475],
              [-3.25724386988542, 54.134675392193401],
              [-3.258340560536866, 54.134398780977342],
              [-3.25953113681461, 54.135148520155255],
              [-3.258662209005533, 54.134355869772804],
              [-3.258667530671082, 54.133901541571319],
              [-3.258669032700205, 54.133773367742165],
              [-3.258198187553337, 54.133741439769466],
              [-3.258631355374424, 54.133889201698622],
              [-3.258496828334304, 54.134302771675067],
              [-3.25718552629614, 54.13451781065686],
              [-3.256520964239296, 54.134204773558778],
              [-3.256201204149593, 54.134411243192702],
              [-3.255311744529562, 54.13334194898443],
              [-3.255657830124032, 54.132842194094046],
              [-3.257806921822579, 54.133266123559451],
              [-3.257888140389183, 54.133661989974421],
              [-3.25853556607229, 54.133409845510144],
              [-3.257798883861251, 54.133226943828539],
              [-3.255607090972813, 54.132682734822886],
              [-3.255429217843381, 54.133076472735311],
              [-3.255058802055562, 54.132012546816327],
              [-3.257505792222425, 54.132408546550955],
              [-3.258764925322809, 54.131838119360367],
              [-3.257605967016046, 54.132279868366005],
              [-3.254626102629488, 54.131773480255291],
              [-3.254377729987167, 54.13013843247483],
              [-3.2507925704756, 54.12573920779743],
              [-3.250372993433577, 54.123290706789611],
              [-3.25079562033795, 54.12289443198258],
              [-3.250365516006822, 54.122788347770204],
              [-3.249964800123104, 54.120761192124355],
              [-3.250863362167809, 54.115949490759846],
              [-3.2496182965109, 54.113619218071058],
              [-3.249089106710279, 54.113165418548192],
              [-3.248673199774012, 54.113222765525975],
              [-3.248827904226438, 54.112974884709217],
              [-3.246602822928341, 54.111572438533493],
              [-3.245877807591827, 54.11065686539694],
              [-3.245469716888874, 54.109545661289367],
              [-3.246250591885615, 54.108245079439598],
              [-3.245965361756831, 54.107200913913644],
              [-3.245179994917666, 54.103451996120192],
              [-3.244529436237537, 54.101799513723947],
              [-3.244174437617281, 54.101793298582741],
              [-3.244356390717988, 54.101383354322728],
              [-3.243906989318976, 54.101799659433851],
              [-3.243950445618008, 54.101207789282277],
              [-3.243000345799642, 54.100325984206492],
              [-3.242934419082663, 54.098991024776659],
              [-3.244309746006261, 54.09819124188494],
              [-3.245102122925833, 54.098403253496656],
              [-3.24468683272921, 54.098119030920991],
              [-3.247128948273822, 54.097913076730407],
              [-3.246527443766812, 54.098405570672732],
              [-3.249092616309996, 54.098579399858998],
              [-3.250555496985744, 54.098050977320113],
              [-3.251228954740906, 54.098680337156154],
              [-3.252330972090471, 54.098813579829816],
              [-3.252705278752549, 54.099258192492989],
              [-3.252582796251837, 54.098644676921253],
              [-3.251502591219721, 54.098524691911955],
              [-3.250614987173377, 54.097842732147996],
              [-3.24981918812203, 54.097873472886391],
              [-3.249257118323948, 54.09825232001694],
              [-3.247649215791977, 54.098127892204964],
              [-3.2482543063456, 54.097398966499703],
              [-3.249910908580452, 54.097262223181033],
              [-3.251520866329257, 54.09633857827852],
              [-3.250274028828222, 54.096268852083028],
              [-3.250610767560532, 54.096482865626747],
              [-3.249430463005173, 54.097151266647387],
              [-3.248404826356944, 54.097013609748515],
              [-3.248351305510916, 54.095791773969395],
              [-3.248819265933557, 54.095386044463737],
              [-3.248305158974839, 54.095120836402998],
              [-3.248536754875541, 54.095500430251668],
              [-3.247724848764162, 54.095655361290476],
              [-3.247287841476009, 54.094850957754474],
              [-3.247195159917254, 54.095533222042384],
              [-3.248102836329535, 54.096123319178986],
              [-3.247701878646841, 54.097284255875394],
              [-3.246678023753893, 54.09684905743579],
              [-3.246960849240507, 54.097458220369631],
              [-3.2443068916817, 54.097483004078477],
              [-3.244309640147733, 54.096451134269962],
              [-3.243220849800395, 54.097523893452049],
              [-3.242460971562847, 54.09660776071302],
              [-3.2430459058204, 54.096685307941051],
              [-3.243913325219885, 54.096033687925889],
              [-3.245426312328085, 54.095545256056262],
              [-3.243030832722103, 54.096232460189221],
              [-3.242686104543111, 54.095800994557948],
              [-3.24400154355524, 54.095200471432946],
              [-3.246438687863168, 54.094783359731863],
              [-3.245309363534623, 54.094908306769142],
              [-3.245592372534895, 54.094657303234897],
              [-3.242826203454593, 54.095270144274167],
              [-3.242845736411339, 54.094951761379264],
              [-3.24499079704209, 54.093972340422084],
              [-3.2458158103575, 54.094153449542993],
              [-3.246989965389561, 54.093943544096042],
              [-3.245676930355157, 54.094009279379385],
              [-3.245767462860486, 54.093764762422737],
              [-3.245072017162376, 54.093876225201249],
              [-3.244014142667725, 54.093526744122371],
              [-3.243854299585022, 54.093039440564802],
              [-3.244291703150919, 54.092940541157851],
              [-3.24314583372319, 54.09281756578514],
              [-3.243957546838508, 54.092402010419065],
              [-3.243771451822763, 54.092212486646652],
              [-3.243219292587455, 54.092513006460692],
              [-3.243020112250325, 54.092294854632819],
              [-3.241886738962235, 54.092333525284189],
              [-3.242128124368408, 54.091866344621522],
              [-3.24132655621663, 54.091700248399206],
              [-3.241928058753527, 54.091362376321449],
              [-3.241304668667866, 54.091376900143821],
              [-3.241877395323666, 54.091099545967204],
              [-3.241752962470106, 54.090823994866355],
              [-3.241097292481415, 54.090678861432956],
              [-3.241755464238098, 54.090139969157732],
              [-3.240706937970775, 54.090202021142616],
              [-3.241243560658357, 54.089996048815287],
              [-3.240984499175077, 54.089871089699685],
              [-3.239696783192179, 54.090166596287951],
              [-3.24055304667685, 54.089451298365987],
              [-3.239486711920669, 54.089223205530736],
              [-3.23921126357066, 54.088702033121514],
              [-3.238066238400723, 54.088756967006638],
              [-3.239712112976315, 54.088117135156857],
              [-3.238922306398701, 54.087573398738265],
              [-3.237486163665463, 54.088068150476623],
              [-3.237482275024392, 54.087372506018731],
              [-3.237042186228813, 54.087379729229234],
              [-3.23642733306453, 54.086805415063886],
              [-3.238903696632959, 54.086333222406822],
              [-3.237469691010654, 54.086436067904025],
              [-3.237443969973121, 54.086188258931266],
              [-3.23488944625675, 54.086602800849853],
              [-3.233295218065696, 54.087283382848625],
              [-3.232334909777376, 54.088565949349103],
              [-3.230000625043279, 54.0884729951079],
              [-3.229031638094774, 54.087242533194235],
              [-3.229036078891661, 54.085841233025704],
              [-3.229920227487703, 54.085600301032393],
              [-3.230440849829605, 54.085783727952958],
              [-3.230543230636094, 54.084749041334405],
              [-3.232535559821466, 54.084865262575882],
              [-3.23277355687041, 54.084642613400732],
              [-3.232384002440619, 54.084548634261424],
              [-3.233219698911471, 54.08406729115282],
              [-3.232508108597381, 54.084300187933458],
              [-3.232205593190173, 54.084048023329672],
              [-3.232815951557789, 54.083906946469675],
              [-3.232747576130806, 54.083663168982213],
              [-3.22930440900408, 54.083231023639954],
              [-3.226880685454725, 54.083567659864833],
              [-3.224775265125937, 54.08184091889688],
              [-3.22365867850982, 54.080362944576017],
              [-3.220930757816255, 54.080486840132487],
              [-3.22282602390819, 54.079857289752276],
              [-3.223775770415639, 54.079926725932893],
              [-3.224706057113067, 54.078816206073604],
              [-3.225870475324454, 54.07854638306118],
              [-3.225716060751358, 54.078182138189582],
              [-3.226465000109635, 54.077974067070912],
              [-3.226174167330724, 54.076145242421532],
              [-3.226526038477992, 54.074860839250164],
              [-3.223953923295685, 54.068843329442394],
              [-3.223289521860665, 54.068487926892367],
              [-3.223355373858406, 54.067661242845276],
              [-3.222371082175736, 54.067498678583576],
              [-3.223381968845064, 54.067006632370109],
              [-3.221456539464725, 54.067265290047843],
              [-3.220062424889571, 54.065945595510399],
              [-3.218965331117492, 54.065686176771365],
              [-3.216891990347766, 54.066424431999849],
              [-3.213935098423865, 54.066421060463917],
              [-3.210167744009368, 54.065083840347242],
              [-3.211034649192766, 54.063474318861289],
              [-3.210788011763692, 54.061614451596903],
              [-3.207487509415306, 54.059235211376674],
              [-3.208187339660878, 54.05660722748285],
              [-3.205743892116374, 54.0522599002934],
              [-3.199031095665577, 54.049155997433097],
              [-3.197583479341441, 54.048744385138903],
              [-3.196069632672232, 54.048737882717838],
              [-3.185238765903086, 54.051580240423597],
              [-3.180178149186879, 54.053951661608039],
              [-3.178538586138053, 54.055284508251965],
              [-3.177163602342543, 54.054254452165644],
              [-3.176740973625471, 54.052597577347363],
              [-3.176344864979869, 54.052580780548489],
              [-3.176621657461375, 54.052969955976302],
              [-3.176151748839756, 54.053098590037656],
              [-3.174669527748844, 54.052431777380733],
              [-3.174989691275765, 54.052139230408507],
              [-3.174348082262269, 54.050783792612378],
              [-3.174682162272791, 54.050443473158616],
              [-3.175609788262924, 54.050459573639692],
              [-3.1758573799028, 54.051329902971439],
              [-3.176641930729481, 54.051903761858178],
              [-3.176716113907167, 54.051610021840631],
              [-3.176114871859053, 54.051306711770096],
              [-3.17582238600513, 54.050361321427815],
              [-3.176422476317419, 54.050137937891449],
              [-3.1746461003362, 54.050193954607728],
              [-3.175727914081131, 54.049125474097991],
              [-3.174894430103133, 54.048384907627536],
              [-3.174143503961952, 54.048296069893865],
              [-3.173607527918012, 54.048951146964036],
              [-3.17432443430232, 54.049945428228192],
              [-3.17310476090175, 54.049863857678311],
              [-3.172931385058709, 54.050595387667222],
              [-3.173706208652381, 54.050988698428384],
              [-3.172782183416834, 54.051317693342703],
              [-3.173573509966726, 54.053054573412091],
              [-3.175354005277616, 54.053843419618083],
              [-3.174176158297838, 54.054219846484855],
              [-3.172871562121153, 54.055249139154149],
              [-3.171029310326908, 54.054817687361314],
              [-3.170702442000648, 54.054167431732445],
              [-3.171552927665076, 54.052633858504336],
              [-3.171366592561231, 54.049377467242039],
              [-3.173187857178962, 54.047392201259491],
              [-3.173071630236189, 54.047061672086841],
              [-3.186909726005156, 54.045949743001763],
              [-3.191768804735077, 54.045281490779942],
              [-3.195620030947192, 54.044250957322554],
              [-3.209696731126054, 54.047513976289665],
              [-3.212058191756902, 54.049565569008948],
              [-3.223471061591616, 54.061773700502691],
              [-3.229249916593217, 54.067217339115537],
              [-3.232670555198054, 54.070080119094428],
              [-3.234875912105121, 54.070909574942696],
              [-3.247265358793124, 54.082176957416792],
              [-3.255076878018717, 54.09084210923956],
              [-3.264047851768855, 54.102574830392292],
              [-3.267531598337977, 54.106829133406961],
              [-3.268546756509601, 54.107364915267048],
              [-3.268941690423595, 54.108376414465766],
              [-3.268341696856391, 54.109115277209241],
              [-3.269492908155175, 54.111097611613516],
              [-3.268519097815399, 54.112420155273959],
              [-3.269392123652932, 54.115358168238181],
              [-3.271040721667689, 54.117888010895562],
              [-3.272255288783087, 54.123473900902944],
              [-3.27512255508033, 54.130041757704127],
              [-3.275869502286279, 54.133231824736193],
              [-3.276684465555635, 54.141813149755137],
              [-3.2759677228582, 54.143694776095984],
              [-3.271309506279923, 54.148152742163632],
              [-3.268319201516782, 54.149350945123686],
              [-3.262512358980024, 54.150380009031451],
              [-3.257234630766449, 54.152658903763836],
              [-3.25538323384373, 54.152857088925067],
              [-3.253956164573703, 54.15250434988404],
              [-3.252104307338058, 54.151470221589165],
              [-3.252008701211177, 54.150737787380095],
            ],
          ],
          [
            [
              [-3.149529563562877, 54.207288487476021],
              [-3.153343958147846, 54.203095114098886],
              [-3.158729289076317, 54.198534183612175],
              [-3.158859833796976, 54.197830970149006],
              [-3.161406909382432, 54.194913218901938],
              [-3.162200873657505, 54.194857923798438],
              [-3.162683731562757, 54.194313088078083],
              [-3.163767573126837, 54.192355831268102],
              [-3.163087864665594, 54.191729646158869],
              [-3.15828018440348, 54.192562425468779],
              [-3.154748662772521, 54.184144976628687],
              [-3.152203101308677, 54.182096739034982],
              [-3.151888358910779, 54.180200596460971],
              [-3.152362572684372, 54.178905389329579],
              [-3.150174239062571, 54.17740195503788],
              [-3.144837588180237, 54.17600755000776],
              [-3.145696888614614, 54.174725784157765],
              [-3.145276746888867, 54.1736593159115],
              [-3.14644642069296, 54.173243726674485],
              [-3.144921511022396, 54.172335173438235],
              [-3.144045304688803, 54.166454591534219],
              [-3.142598353227064, 54.165251362059465],
              [-3.143053051564385, 54.165021448051675],
              [-3.144696025173451, 54.165501973832455],
              [-3.14701735011587, 54.165254282839669],
              [-3.14732905876644, 54.163123860420619],
              [-3.147907370079419, 54.163045545208504],
              [-3.149437462689734, 54.159731460685045],
              [-3.156512014642801, 54.158930286761972],
              [-3.160207324639375, 54.158872260488081],
              [-3.16290880928906, 54.158872260169439],
              [-3.164446734341575, 54.154521584470167],
              [-3.164762768030036, 54.154487967837866],
              [-3.164709279541595, 54.15312860072828],
              [-3.163465924978884, 54.148871322196648],
              [-3.164350418661885, 54.147054382284821],
              [-3.163136605822509, 54.144225006992777],
              [-3.163955617178901, 54.139967555052635],
              [-3.162796451286806, 54.137823431862948],
              [-3.163659060059623, 54.135287662165219],
              [-3.159636256394336, 54.13494360036669],
              [-3.160964233116982, 54.134048173460954],
              [-3.163990505922104, 54.12865937844191],
              [-3.163668273626919, 54.127864356986592],
              [-3.164030471013634, 54.127574135709274],
              [-3.163086503447096, 54.125397371506864],
              [-3.163463924911487, 54.121937820292381],
              [-3.166450927917734, 54.121075710944346],
              [-3.166869467147749, 54.119744117851319],
              [-3.171680541601215, 54.118088505562582],
              [-3.172521284783698, 54.117249819185737],
              [-3.173411472634452, 54.117295968754675],
              [-3.174267022579697, 54.116495774122946],
              [-3.174871073368227, 54.114756079616171],
              [-3.171535562511176, 54.112569448367253],
              [-3.164927995766713, 54.109112079509949],
              [-3.161707592521945, 54.108052964788619],
              [-3.161971247052401, 54.107854478960043],
              [-3.161325509079325, 54.107298060739581],
              [-3.157323676903734, 54.105961448092273],
              [-3.154642315935583, 54.104635406638643],
              [-3.154088500494046, 54.10380393181174],
              [-3.148671521210805, 54.102539921360574],
              [-3.14477919514308, 54.09948423072565],
              [-3.142585070453729, 54.097383907434114],
              [-3.149114642203131, 54.095280523410985],
              [-3.148580120845958, 54.094308624830617],
              [-3.149621779218987, 54.093934657687434],
              [-3.149077274264407, 54.0935416884695],
              [-3.15430132999842, 54.090763791698002],
              [-3.158982021592406, 54.087659239698887],
              [-3.161035654064943, 54.086798149516632],
              [-3.164795490430358, 54.083215095000448],
              [-3.166919004004805, 54.082895208265079],
              [-3.16795035396507, 54.08222457212166],
              [-3.169439993207649, 54.08306666479286],
              [-3.170724657284886, 54.082273100870118],
              [-3.172119688866089, 54.082088740268631],
              [-3.172402810857696, 54.081774992206121],
              [-3.172077772744446, 54.080764300841736],
              [-3.170456133270574, 54.079413000397089],
              [-3.166379366617697, 54.076953909041507],
              [-3.164538364960864, 54.07441552908562],
              [-3.160795993340001, 54.073065740550646],
              [-3.157748309728623, 54.071518602866654],
              [-3.152705115740976, 54.067657218036274],
              [-3.151655298196051, 54.067380555336548],
              [-3.151653311667961, 54.069130559775019],
              [-3.154157809998615, 54.070998547491484],
              [-3.153048770235012, 54.070849197360708],
              [-3.151762605932021, 54.070208086306799],
              [-3.151260875786214, 54.069417442678009],
              [-3.151143427436274, 54.066884818264903],
              [-3.150058183238669, 54.065822919721434],
              [-3.150715308413599, 54.064775809830159],
              [-3.154029647088175, 54.066323261785485],
              [-3.154797228097972, 54.068653702398379],
              [-3.155917876085036, 54.069772742226746],
              [-3.165774341271097, 54.074617472170864],
              [-3.167390780732283, 54.076231338680749],
              [-3.165760537922753, 54.075270143210673],
              [-3.166528968071699, 54.076838309084764],
              [-3.171438669546617, 54.079619149626616],
              [-3.17224645212907, 54.079807220345018],
              [-3.173331338793975, 54.079631260452196],
              [-3.173625501394081, 54.079005513865994],
              [-3.17446602548138, 54.076077068612037],
              [-3.172946189192115, 54.074104626451323],
              [-3.173240229223655, 54.073584042365908],
              [-3.174268689273133, 54.07325582535757],
              [-3.175177530999136, 54.073454573868581],
              [-3.175945950407034, 54.075010997060382],
              [-3.177032871329941, 54.074587809587328],
              [-3.177979238832478, 54.074868860606522],
              [-3.175790815125094, 54.07568662324244],
              [-3.174937137812912, 54.076522772174563],
              [-3.173421942033796, 54.082138060739631],
              [-3.173752166492142, 54.0833850851919],
              [-3.176227789468917, 54.084544636097334],
              [-3.176607960192996, 54.084905835240484],
              [-3.175981441549595, 54.085131272147535],
              [-3.177665289136549, 54.086181686755992],
              [-3.183416806445234, 54.088768643037369],
              [-3.185726639240827, 54.090205566255754],
              [-3.186862586499948, 54.09041816768837],
              [-3.187984868968301, 54.092559742788175],
              [-3.18606611388215, 54.093422660155717],
              [-3.186543396811131, 54.093805340908581],
              [-3.185098755623692, 54.094567392164542],
              [-3.185161739870938, 54.095703767149629],
              [-3.185847856571915, 54.095901934472245],
              [-3.186839265167717, 54.096991402926172],
              [-3.187834693267665, 54.099289723596222],
              [-3.191014502124184, 54.102614443203954],
              [-3.192949325211907, 54.103578571852999],
              [-3.194279092551323, 54.103656158742943],
              [-3.194109883497149, 54.104260940036696],
              [-3.198558046291358, 54.102953879277869],
              [-3.214777420053856, 54.09803917751303],
              [-3.219070043880489, 54.095592326381968],
              [-3.220080798043405, 54.094810896743333],
              [-3.219380248098541, 54.093396075623957],
              [-3.221157602621769, 54.092687756444377],
              [-3.221319598891346, 54.092060536749329],
              [-3.221292468405891, 54.090772810436228],
              [-3.220253510254147, 54.089703877543286],
              [-3.221457731062162, 54.090777424143127],
              [-3.221348201233975, 54.092721774022607],
              [-3.221760517972346, 54.093536407236947],
              [-3.223851467886338, 54.093613123351602],
              [-3.223727685457151, 54.093927170181566],
              [-3.224206768962624, 54.094099362172713],
              [-3.224708015344479, 54.093830008527007],
              [-3.224885730670173, 54.094412428780331],
              [-3.226084972508342, 54.094116186243248],
              [-3.227191699496168, 54.094415891102834],
              [-3.228959698235, 54.095773029183384],
              [-3.230542419530688, 54.098202525529821],
              [-3.232716427871877, 54.099060208208634],
              [-3.231927750688241, 54.099487134804995],
              [-3.232183483042031, 54.100066048983585],
              [-3.233394616019496, 54.100991102029347],
              [-3.234019607453084, 54.100768078456426],
              [-3.235391210555965, 54.101172849266746],
              [-3.235332209839179, 54.10176038127409],
              [-3.23662766298253, 54.102071544472835],
              [-3.239311960943281, 54.104130971376449],
              [-3.242123840164474, 54.109323188961966],
              [-3.241886299795155, 54.110485111652331],
              [-3.242692743370329, 54.111620076928766],
              [-3.241802786662495, 54.112040922217332],
              [-3.241483381476284, 54.112870228392786],
              [-3.244724660907612, 54.116790623879332],
              [-3.245071037751967, 54.118597250953812],
              [-3.24394023519771, 54.123444560458005],
              [-3.24415875796629, 54.125273182592863],
              [-3.244853085383415, 54.126484791750912],
              [-3.244047382681219, 54.128348271850292],
              [-3.24347878174173, 54.128677720719153],
              [-3.244594805838825, 54.129303441636971],
              [-3.244679295233549, 54.1325805341069],
              [-3.244051967466381, 54.133760868036305],
              [-3.244241316572653, 54.136809475257678],
              [-3.24350037724879, 54.138849343633197],
              [-3.242694647317419, 54.139494922011693],
              [-3.242703576996868, 54.140508687713385],
              [-3.241214486570984, 54.141800363203423],
              [-3.240633646999608, 54.144491994484184],
              [-3.240105183692255, 54.144943249689092],
              [-3.24049324085629, 54.147117964252395],
              [-3.24075489562825, 54.148546171140431],
              [-3.240212549584754, 54.150787995933385],
              [-3.239205260205634, 54.151666618507257],
              [-3.238128075793081, 54.151846678646713],
              [-3.237905483866003, 54.152905067207818],
              [-3.237108539241772, 54.153805776006386],
              [-3.23701827102686, 54.154732475413525],
              [-3.237698153637576, 54.155911912916856],
              [-3.239226426745208, 54.156987342620539],
              [-3.242236090837628, 54.157769744390436],
              [-3.240520683075403, 54.158377050965555],
              [-3.242338560523927, 54.157816323773041],
              [-3.245454476978192, 54.157747276769605],
              [-3.246458634478935, 54.157018736225922],
              [-3.246227530637003, 54.156258939531305],
              [-3.24696269256715, 54.1557021547844],
              [-3.246740690196822, 54.155245163080565],
              [-3.248227220099429, 54.154519699588896],
              [-3.24958006048966, 54.154796878323438],
              [-3.25201428089244, 54.157364649429454],
              [-3.251623997193774, 54.158742087315076],
              [-3.251914198264737, 54.159680122230299],
              [-3.256242664618874, 54.162727832706643],
              [-3.257136642795826, 54.16382313654718],
              [-3.257413626796507, 54.165936038500831],
              [-3.25653988330233, 54.168693714880398],
              [-3.2546094514335, 54.170201372213477],
              [-3.25082898020298, 54.170665841706167],
              [-3.2461320046719, 54.171985457542924],
              [-3.245865155154409, 54.17167004171062],
              [-3.245214500334572, 54.17188799192332],
              [-3.243696447943768, 54.17153427282684],
              [-3.243178320802319, 54.17172747404701],
              [-3.243628858129671, 54.171885505213218],
              [-3.241305377206692, 54.172106319938585],
              [-3.237736682464177, 54.171721518791742],
              [-3.230195613287485, 54.1716927448891],
              [-3.228439344334457, 54.171360142670054],
              [-3.223301019165889, 54.173116589200653],
              [-3.217153413678337, 54.177455392640873],
              [-3.215597952315881, 54.181308988740803],
              [-3.215094487497112, 54.184831977610088],
              [-3.222990806306103, 54.18778190256387],
              [-3.221439227836915, 54.188279406959666],
              [-3.215968038375143, 54.185774091416363],
              [-3.214758372414478, 54.185650580914299],
              [-3.216134833966386, 54.185974637281703],
              [-3.21551981196582, 54.185957477851176],
              [-3.215640803819921, 54.186163879509571],
              [-3.216433386401407, 54.186121721638663],
              [-3.214582610852876, 54.186398358277152],
              [-3.216725682191797, 54.186212244025654],
              [-3.217879400278232, 54.186881872827939],
              [-3.215697684672941, 54.187005481525716],
              [-3.214489475089007, 54.186723764586361],
              [-3.214253347889005, 54.187137796310729],
              [-3.214742126057911, 54.187134664570635],
              [-3.214708838941893, 54.18683569976799],
              [-3.215739986835652, 54.18729806346861],
              [-3.216443551773013, 54.187094121353624],
              [-3.215652965397986, 54.187518249278511],
              [-3.215751925896411, 54.187861490868919],
              [-3.218393246763297, 54.18715799980621],
              [-3.218995934914004, 54.187484457010015],
              [-3.217993437289647, 54.18773727666354],
              [-3.216480910807349, 54.189462083299432],
              [-3.215938463236879, 54.191762198140431],
              [-3.216583224631854, 54.192319234199473],
              [-3.215802714239107, 54.192097922211445],
              [-3.216455247474844, 54.192606345290272],
              [-3.215863163459982, 54.192590753064245],
              [-3.214715089409879, 54.194730695545864],
              [-3.214593356402366, 54.196799161503705],
              [-3.213218515936862, 54.198844303745254],
              [-3.21236184238162, 54.199794870802343],
              [-3.21204126237413, 54.199736080179981],
              [-3.212443781592989, 54.201284253604889],
              [-3.211843686408903, 54.204665278988756],
              [-3.212351221444965, 54.206604270813429],
              [-3.213058165553985, 54.20718406816281],
              [-3.212619901078233, 54.207558785509114],
              [-3.211187525561521, 54.205923903707514],
              [-3.21230603266988, 54.208516467477573],
              [-3.21086248600165, 54.2087017585471],
              [-3.209485346458826, 54.208169125588043],
              [-3.208319458000053, 54.208096339645245],
              [-3.208088910865523, 54.208183238764789],
              [-3.206797831364176, 54.208669864289682],
              [-3.204505089475912, 54.209533967981358],
              [-3.204198908692933, 54.209866887406527],
              [-3.207309142711605, 54.208520754282951],
              [-3.208052592876208, 54.208303942003127],
              [-3.209106085117612, 54.208255622437882],
              [-3.210055513469895, 54.208581339813286],
              [-3.208042266939263, 54.208738164374594],
              [-3.206147908410134, 54.209765597338304],
              [-3.206355488554175, 54.211731882372057],
              [-3.204986984558105, 54.214973154872538],
              [-3.202915069403315, 54.214600189629195],
              [-3.202753318090316, 54.214363293194978],
              [-3.20269563638992, 54.21427881429387],
              [-3.202899109825085, 54.214071524778099],
              [-3.203493945328447, 54.213465520716937],
              [-3.202834026835168, 54.212867222490807],
              [-3.203298705903549, 54.212499468271112],
              [-3.203914702541075, 54.212589484612799],
              [-3.202449485997661, 54.211454559696222],
              [-3.202705530593991, 54.211189555193684],
              [-3.201816770805745, 54.211104950791871],
              [-3.201359365174252, 54.211670361878646],
              [-3.200843025183164, 54.211526310199787],
              [-3.201081645203776, 54.209762291669662],
              [-3.200144470310458, 54.208800034322863],
              [-3.200521684524654, 54.208219248444202],
              [-3.195182547270109, 54.208370311301003],
              [-3.19214722153776, 54.207166344248293],
              [-3.189235877025851, 54.206589335076501],
              [-3.187468185993296, 54.205203755562046],
              [-3.183981599630538, 54.204126268364696],
              [-3.183481870614003, 54.203699757965687],
              [-3.180160765633505, 54.203363838085323],
              [-3.177289964058487, 54.203604047468225],
              [-3.167645973733339, 54.203143367658996],
              [-3.163483252216578, 54.207930173289377],
              [-3.159435859064472, 54.211667726423585],
              [-3.151955957913997, 54.216352079543263],
              [-3.14762707051367, 54.218467801887847],
              [-3.146602473069616, 54.213704087683489],
              [-3.149529563562877, 54.207288487476021],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000028",
        LAD13CDO: "16UD",
        LAD13NM: "Carlisle",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-3.001125417953164, 54.933662761272132],
              [-3.001909147076478, 54.933295935132037],
              [-3.00170019424451, 54.934307764804664],
              [-3.001140779523329, 54.934343827952397],
              [-3.001125417953164, 54.933662761272132],
            ],
          ],
          [
            [
              [-2.999715100418809, 54.936076519961695],
              [-3.001800908701679, 54.934717627641398],
              [-3.002013930109201, 54.935814946968655],
              [-3.001416749644215, 54.936781447653964],
              [-3.000961436017902, 54.93692539123569],
              [-2.999715100418809, 54.936076519961695],
            ],
          ],
          [
            [
              [-3.038879512478237, 54.974329848392756],
              [-3.039308625688834, 54.973583880162963],
              [-3.039897611039277, 54.97503468921834],
              [-3.038879512478237, 54.974329848392756],
            ],
          ],
          [
            [
              [-2.676437506883415, 55.173032125168746],
              [-2.677686980318557, 55.171721299559891],
              [-2.677352348855936, 55.171245098074763],
              [-2.679541927696762, 55.169646860801315],
              [-2.679913099047223, 55.168856708009315],
              [-2.682918008519506, 55.167491091754137],
              [-2.683248592346696, 55.1666274694294],
              [-2.676091004443667, 55.15840733149372],
              [-2.676072876573908, 55.157892526447483],
              [-2.677762831080745, 55.156578352532861],
              [-2.677318987773507, 55.155484510994313],
              [-2.674884677367717, 55.155468361834963],
              [-2.673259439144565, 55.151777757000417],
              [-2.672526736579092, 55.150851736900862],
              [-2.671261330693063, 55.150531618233771],
              [-2.668572816216347, 55.147307791503337],
              [-2.666383457833821, 55.147460866544556],
              [-2.662584860305693, 55.146987342703255],
              [-2.661618984764735, 55.146361763554424],
              [-2.65989961243556, 55.144284504198076],
              [-2.659319454976162, 55.142972971475686],
              [-2.659767941347408, 55.142105177747176],
              [-2.656933733737628, 55.136128525181419],
              [-2.655444258083992, 55.135690833904135],
              [-2.648611436513034, 55.132390842076475],
              [-2.64711870211427, 55.131058944129379],
              [-2.645363567572066, 55.1310511806278],
              [-2.635704586857011, 55.12961026360032],
              [-2.632632687853822, 55.127753540443237],
              [-2.625683599746302, 55.127539614682092],
              [-2.624344615041577, 55.12896449866772],
              [-2.621266788036435, 55.129212974862128],
              [-2.61563363404102, 55.127491970253075],
              [-2.61075852455499, 55.126704162495173],
              [-2.610232507764104, 55.12635184600807],
              [-2.60614736029931, 55.126143095009972],
              [-2.60272569966843, 55.124692636083317],
              [-2.598896119403146, 55.124572239084053],
              [-2.600919691507877, 55.12378407367558],
              [-2.6015872187162, 55.122605391419995],
              [-2.600978655678622, 55.119671723172416],
              [-2.600391796373976, 55.118909896095552],
              [-2.600464284520368, 55.117158138256706],
              [-2.599606439275807, 55.115676953446744],
              [-2.59482744503417, 55.114064906616647],
              [-2.593429539547379, 55.112828940769511],
              [-2.594257683949452, 55.110851543310801],
              [-2.593272235626379, 55.110043100435945],
              [-2.594074569839729, 55.109075873272772],
              [-2.593770491509002, 55.108231758553266],
              [-2.594317791960246, 55.107615334213087],
              [-2.592895826520974, 55.106960883622193],
              [-2.593151284619061, 55.106218281326257],
              [-2.592426776924116, 55.106299088869733],
              [-2.59228826780732, 55.106048150031455],
              [-2.593551873655311, 55.105116423173804],
              [-2.592474039220015, 55.105150427324716],
              [-2.592081251413447, 55.104238444696364],
              [-2.590429196398193, 55.103700106703251],
              [-2.589334277803881, 55.103955225634749],
              [-2.588599693233943, 55.103561589856724],
              [-2.587552013281362, 55.103511833496242],
              [-2.587505438281991, 55.103114869760155],
              [-2.586909850174663, 55.102899377839805],
              [-2.585486250335822, 55.103077866220481],
              [-2.58541223665627, 55.102732254755864],
              [-2.583329693865268, 55.101680083636552],
              [-2.582627817081719, 55.100511647883799],
              [-2.582666920280769, 55.099429526405828],
              [-2.583319164235355, 55.099021127620169],
              [-2.582811838643543, 55.098080907730328],
              [-2.583983255086348, 55.097169489605221],
              [-2.583491754259355, 55.096992122336658],
              [-2.581892774049936, 55.097273851963969],
              [-2.580657212305068, 55.096683068346124],
              [-2.57929488972773, 55.09664282891184],
              [-2.579401876462694, 55.096131006850563],
              [-2.57742837564921, 55.096737961463916],
              [-2.577763929134398, 55.095992315043738],
              [-2.576671228587374, 55.095848324400777],
              [-2.572451672579001, 55.097625928403779],
              [-2.570194167082454, 55.09722944706872],
              [-2.568419014571961, 55.096257356072265],
              [-2.568967574788245, 55.094390164799357],
              [-2.568298610516621, 55.093956561128152],
              [-2.566775054398994, 55.093698570480385],
              [-2.567148374282455, 55.092730819195644],
              [-2.566698772129607, 55.091961899291007],
              [-2.565384304098757, 55.091636420396512],
              [-2.563979290708129, 55.091889157398491],
              [-2.563389161015547, 55.091261060401891],
              [-2.561172764110383, 55.090299890954007],
              [-2.560743903561032, 55.089435600103492],
              [-2.561025568648878, 55.088739671609574],
              [-2.559638293902098, 55.089247484312423],
              [-2.558287890755978, 55.088806172626867],
              [-2.558907148524147, 55.088049391859194],
              [-2.560389524516737, 55.088406517950226],
              [-2.561207431169206, 55.087518511348726],
              [-2.559678967461112, 55.087897574565716],
              [-2.559063840964317, 55.087714386901268],
              [-2.558894838027342, 55.087391660638218],
              [-2.56047749790806, 55.086296159688906],
              [-2.559128445936781, 55.085383975561911],
              [-2.559835234074327, 55.085178538671499],
              [-2.561606773698345, 55.085384250756256],
              [-2.562786276896448, 55.084371453733304],
              [-2.56212961161333, 55.083457897225166],
              [-2.560809901939761, 55.083966310813821],
              [-2.558784883276787, 55.083777027414584],
              [-2.556940572254848, 55.081034809267223],
              [-2.555708951676158, 55.081568824723163],
              [-2.556965034185884, 55.082118430571434],
              [-2.555181859100506, 55.082756506356276],
              [-2.553809306421472, 55.082619877555551],
              [-2.554262894832357, 55.082230509150101],
              [-2.553125123665052, 55.081851974025774],
              [-2.552646917537222, 55.081252071899236],
              [-2.551721991723017, 55.08131198137233],
              [-2.550830980677551, 55.080876592034144],
              [-2.549046782382345, 55.081214444400231],
              [-2.548590555705492, 55.081871593246447],
              [-2.54977901532442, 55.081495103035643],
              [-2.550411887978321, 55.081725883955457],
              [-2.54865491046009, 55.082681856119926],
              [-2.546805741504826, 55.082972341398701],
              [-2.545759276735352, 55.0837732117513],
              [-2.544021402025862, 55.083382003948316],
              [-2.540161370427247, 55.08429780821205],
              [-2.536853448930555, 55.082939353726253],
              [-2.5322502718579, 55.082756478115719],
              [-2.530463720871198, 55.085182451915514],
              [-2.527166152218197, 55.085067374511709],
              [-2.526921886109581, 55.08424260390958],
              [-2.526382385555745, 55.083957380610165],
              [-2.524943880224723, 55.085477759428663],
              [-2.518088016248188, 55.084952681810549],
              [-2.516762721782347, 55.086046537573054],
              [-2.516963481592603, 55.088613927079109],
              [-2.516460716355711, 55.089111197586725],
              [-2.513199999146958, 55.089204063801986],
              [-2.511559988354302, 55.088863201856327],
              [-2.508952857347976, 55.089716130063216],
              [-2.506927677119054, 55.089145871451464],
              [-2.506005972592734, 55.089356386919],
              [-2.50487826580011, 55.0903774011],
              [-2.503699995378454, 55.090746220080092],
              [-2.502244057919449, 55.090714492945878],
              [-2.500622108237853, 55.090186497704963],
              [-2.499042791605156, 55.087795479375863],
              [-2.498284581901244, 55.087296259633767],
              [-2.495729382253175, 55.086663283110262],
              [-2.495186056785934, 55.087700697616938],
              [-2.494867132682151, 55.087374898894105],
              [-2.495322736577267, 55.086464547289715],
              [-2.493475537983926, 55.086197072784366],
              [-2.492062655228957, 55.086340279504299],
              [-2.490691070962938, 55.085891116756919],
              [-2.489706204890274, 55.084267692210858],
              [-2.489864044010165, 55.083980399251558],
              [-2.490890600571619, 55.084020296308346],
              [-2.490755948411351, 55.083770125796612],
              [-2.489906823441828, 55.083507555986387],
              [-2.487850529385248, 55.083549960976988],
              [-2.486334247597832, 55.083041121874089],
              [-2.486076860395585, 55.081581002943764],
              [-2.486631772796603, 55.081098027219291],
              [-2.490116155090863, 55.080771330742337],
              [-2.491097820491532, 55.082267162406609],
              [-2.493509533251104, 55.082216971832644],
              [-2.494726429359757, 55.081817540715683],
              [-2.496305441521243, 55.080304136207126],
              [-2.497046484431541, 55.077412064423747],
              [-2.499472453492531, 55.076770407752946],
              [-2.501227607664833, 55.075220269867934],
              [-2.501042830860327, 55.074596492367036],
              [-2.499229592477586, 55.073494151451236],
              [-2.494027854545014, 55.07165883335562],
              [-2.493346204054446, 55.070860930936988],
              [-2.49325667483701, 55.069057770496563],
              [-2.492193209791275, 55.068149081038747],
              [-2.493791576181498, 55.065954474819456],
              [-2.497583365336171, 55.065653272007175],
              [-2.497875665080214, 55.064730095221449],
              [-2.500741708605827, 55.064284305183726],
              [-2.503467371702667, 55.063138106330243],
              [-2.503978223089605, 55.062425185167548],
              [-2.503395994953776, 55.062067248863066],
              [-2.50113378624893, 55.062456700044578],
              [-2.499952668058467, 55.062032018233815],
              [-2.500545263381712, 55.061216333159372],
              [-2.502258747975096, 55.061271278602106],
              [-2.502719388988555, 55.06079939940178],
              [-2.501883918800662, 55.059844021827949],
              [-2.502013895331416, 55.057720948763169],
              [-2.500670411566684, 55.056569062325202],
              [-2.499744612675729, 55.05614242917509],
              [-2.497818805386284, 55.056620299766067],
              [-2.495239821076986, 55.054662847157907],
              [-2.495525835217288, 55.053739699873049],
              [-2.494699591430387, 55.053125710233978],
              [-2.494062873791053, 55.053155255509708],
              [-2.492178524318508, 55.054198998801631],
              [-2.491957710042185, 55.054956528604215],
              [-2.490745471996444, 55.054521099997423],
              [-2.491187672062604, 55.054200306331573],
              [-2.490839421775896, 55.053372286355895],
              [-2.493140601048438, 55.052112105221738],
              [-2.494334838979491, 55.050709906839749],
              [-2.487558796778812, 55.047858967128469],
              [-2.487193289604583, 55.047274531175312],
              [-2.488114729136667, 55.046213163051604],
              [-2.484887311598174, 55.045152216379797],
              [-2.482949528119937, 55.043696071396887],
              [-2.482336105987583, 55.041486102128246],
              [-2.483042912599593, 55.040014053906852],
              [-2.493432527626153, 55.037161475776962],
              [-2.497065330669576, 55.036876203275256],
              [-2.501877937818579, 55.037099074273776],
              [-2.505250019951876, 55.036864955394712],
              [-2.506079429128022, 55.036378948263007],
              [-2.506292382758752, 55.035647482518257],
              [-2.505754757275933, 55.034826580643212],
              [-2.503818522358604, 55.034262191751935],
              [-2.503683870588719, 55.033631017816923],
              [-2.504471500278568, 55.033185632253137],
              [-2.50891628994017, 55.034415307482213],
              [-2.50862345501793, 55.035999904592728],
              [-2.509204962474334, 55.036327266586454],
              [-2.510161157094668, 55.036342132008876],
              [-2.510984752479922, 55.035896553645692],
              [-2.510757451334866, 55.034879368881917],
              [-2.511607100718197, 55.034639461312153],
              [-2.512291499749411, 55.033834111429385],
              [-2.513096929287556, 55.033809150383298],
              [-2.514434714495748, 55.034426246723925],
              [-2.516233798521056, 55.034654964170898],
              [-2.515785135077371, 55.033588404498758],
              [-2.51760102771925, 55.033424332195047],
              [-2.519098239052148, 55.032814977181033],
              [-2.521652293894516, 55.032512000429506],
              [-2.524491051139691, 55.032927540373166],
              [-2.525904178576436, 55.032358003221866],
              [-2.526905214136625, 55.032935081460991],
              [-2.527702216344393, 55.032271138795316],
              [-2.527070335077932, 55.03025916330698],
              [-2.527615095278561, 55.029094881083424],
              [-2.529179217783899, 55.028956888491571],
              [-2.53196841023802, 55.030367246539221],
              [-2.532981209491615, 55.029942257633422],
              [-2.533249204517605, 55.028683009110424],
              [-2.53405391400472, 55.028259821584328],
              [-2.539565505363457, 55.028871728254714],
              [-2.540891413938052, 55.027312117960797],
              [-2.544476123110881, 55.026800086182092],
              [-2.545766892417675, 55.026126623734697],
              [-2.546363296580932, 55.025237901851526],
              [-2.545894413494805, 55.024345874185514],
              [-2.546681459511163, 55.023560535392939],
              [-2.550423331763081, 55.02221998604265],
              [-2.552612441108593, 55.021036455118669],
              [-2.554742254063746, 55.020891068685962],
              [-2.55582163263191, 55.019886868598149],
              [-2.55697416797276, 55.01965064987192],
              [-2.557987767244904, 55.019771812526578],
              [-2.557593426305723, 55.020319985941697],
              [-2.558746180790326, 55.020886223369118],
              [-2.560925919111736, 55.020398122392571],
              [-2.565035237105884, 55.020105921904509],
              [-2.568168079772974, 55.018343499095529],
              [-2.567891401193731, 55.017247566417275],
              [-2.571391714147488, 55.017046057449278],
              [-2.573179972820059, 55.016109361274722],
              [-2.571773851419364, 55.014227960401819],
              [-2.574804488943866, 55.011167324541667],
              [-2.57396778257111, 55.009625633275427],
              [-2.57186110444832, 55.008383765493505],
              [-2.573460521201942, 55.007279906894247],
              [-2.573646418954945, 55.006720981173004],
              [-2.572635680874181, 55.006342029128852],
              [-2.570796223000978, 55.007056998588176],
              [-2.570234840161843, 55.006500684223816],
              [-2.570799313640199, 55.005843833972989],
              [-2.573491810177412, 55.004761798333526],
              [-2.574173852083202, 55.003694602223042],
              [-2.572935870199715, 55.003271793740275],
              [-2.570190502007177, 55.003927216168407],
              [-2.568424940645535, 55.003451119092468],
              [-2.567706707909696, 55.002741860165159],
              [-2.569087642198402, 55.00123109924283],
              [-2.568608039490384, 54.999928531073145],
              [-2.570463795976087, 54.998730053355253],
              [-2.570474340135982, 54.996939931016762],
              [-2.571962197379255, 54.995604765433079],
              [-2.572854760191264, 54.992866028183016],
              [-2.574427092176861, 54.991661633322174],
              [-2.573023400936056, 54.990332888615278],
              [-2.573494351867926, 54.989712409436009],
              [-2.572844611771843, 54.9893021011465],
              [-2.573303713283374, 54.987632075593787],
              [-2.57577776878895, 54.984917297922038],
              [-2.57697151554114, 54.984690575574824],
              [-2.578551669297128, 54.983408805126466],
              [-2.579433867695958, 54.983253633838217],
              [-2.579230137641214, 54.9828870633837],
              [-2.580323171313421, 54.982833325284297],
              [-2.580428828461345, 54.982467077031991],
              [-2.5814566941081, 54.982013747719634],
              [-2.581929184645533, 54.982271192482379],
              [-2.585765404364574, 54.982387566933909],
              [-2.586622702498691, 54.982028473364828],
              [-2.58856448561003, 54.980181382168446],
              [-2.588460650504865, 54.979705608435005],
              [-2.589959249937521, 54.977695289142872],
              [-2.59060238917284, 54.977444143464304],
              [-2.590224062450388, 54.976470062913592],
              [-2.591585033562938, 54.974144968989492],
              [-2.594236352060853, 54.973628803020944],
              [-2.60033882038603, 54.973656335447487],
              [-2.601422661283895, 54.971334294119593],
              [-2.59979884395867, 54.969083143744953],
              [-2.59996964809073, 54.968700380266149],
              [-2.598877812592592, 54.967757701866653],
              [-2.599078265159772, 54.9668481921953],
              [-2.598207977798487, 54.966514590289897],
              [-2.598104544366932, 54.965860891394961],
              [-2.598792153382091, 54.965255419438876],
              [-2.59783222762994, 54.964878223123193],
              [-2.596731025309392, 54.965084029776079],
              [-2.596860676269337, 54.964663729683316],
              [-2.595562341831362, 54.96347490979241],
              [-2.588833356033733, 54.962899313395262],
              [-2.586522613895616, 54.963056962416005],
              [-2.578414104637298, 54.960805190366138],
              [-2.571426588614517, 54.960010594833463],
              [-2.568419378761849, 54.958284934169107],
              [-2.571278469201979, 54.948364051270573],
              [-2.567642566237585, 54.940849596840266],
              [-2.564930012107869, 54.940320357591304],
              [-2.563434880681809, 54.937436376322673],
              [-2.56018397555332, 54.934089595423252],
              [-2.554334558151365, 54.933526014695239],
              [-2.551544857344597, 54.931758504235042],
              [-2.5512041085506, 54.930806593673339],
              [-2.551750127800291, 54.929767983646265],
              [-2.551200793771391, 54.929309474565549],
              [-2.550557929252185, 54.929428313745241],
              [-2.550360916250785, 54.929971086172017],
              [-2.54858836247531, 54.929757137982072],
              [-2.546827475205987, 54.930746390044604],
              [-2.546076602684781, 54.930498147851559],
              [-2.545265808938704, 54.929165511837716],
              [-2.54550182984477, 54.928387128971892],
              [-2.546241873804193, 54.927149072810124],
              [-2.547607002196036, 54.926212835172571],
              [-2.551648800250501, 54.925558313831573],
              [-2.553664042136922, 54.924595689337906],
              [-2.559398974761254, 54.924424738831995],
              [-2.56024797598634, 54.923877148943241],
              [-2.560066617296057, 54.923626365514927],
              [-2.558971702753636, 54.923093121667655],
              [-2.55859095010342, 54.922189042265167],
              [-2.557185217284731, 54.921514326984699],
              [-2.558090174421205, 54.921291803462395],
              [-2.559833636649153, 54.920108358776851],
              [-2.566214809470776, 54.919231367154403],
              [-2.566597147533578, 54.913689452113708],
              [-2.567559051406315, 54.908995839905977],
              [-2.574856027909907, 54.899737835761108],
              [-2.574798185993769, 54.898414404324434],
              [-2.576407993453722, 54.896710131614817],
              [-2.579686965242158, 54.894856794017905],
              [-2.581900967661863, 54.894206370163424],
              [-2.585066568777564, 54.892199759976627],
              [-2.588159593607157, 54.891118125874208],
              [-2.591163875551545, 54.888991716771514],
              [-2.592525837987308, 54.888953626825945],
              [-2.594380145994437, 54.887732288997334],
              [-2.596057381524146, 54.887617129426602],
              [-2.597184214217493, 54.886968161169307],
              [-2.604544181820282, 54.885442684633922],
              [-2.605424487098215, 54.884375196872824],
              [-2.605272861957227, 54.883931121131603],
              [-2.598955530558817, 54.879854716157915],
              [-2.593158691371777, 54.875265008342481],
              [-2.590241390791187, 54.873776681107337],
              [-2.5903657991798, 54.872906182727043],
              [-2.58858994035465, 54.869528688762323],
              [-2.583624653343794, 54.865586911284694],
              [-2.581193260143586, 54.864229030510117],
              [-2.57742482302843, 54.859566835750606],
              [-2.575834900045922, 54.85665826241511],
              [-2.573346606723899, 54.853539135967964],
              [-2.581777357585799, 54.84788333935694],
              [-2.582665472600947, 54.845859804214513],
              [-2.588506566581503, 54.847658588313692],
              [-2.589937224830416, 54.848687791673008],
              [-2.59325296736873, 54.849221607960885],
              [-2.594005143925426, 54.84911188829247],
              [-2.594066409542662, 54.849359617010144],
              [-2.595799651154262, 54.849770793584426],
              [-2.596799524836476, 54.850825395009153],
              [-2.597368371361975, 54.850955597768561],
              [-2.597627708425624, 54.851957219120017],
              [-2.600121504715274, 54.851970977003013],
              [-2.60163855709762, 54.85291424361958],
              [-2.602841627339705, 54.852956802350555],
              [-2.603763151176139, 54.853336846441238],
              [-2.604647174005349, 54.852875031447752],
              [-2.606122743168934, 54.853120194581074],
              [-2.606782654810416, 54.852879653059993],
              [-2.608437802136881, 54.853257789835048],
              [-2.609695664595094, 54.852904598120205],
              [-2.611070209440248, 54.853471028217974],
              [-2.613007965294895, 54.852657861233389],
              [-2.614295686310664, 54.852940718471189],
              [-2.614798900608092, 54.852641614889713],
              [-2.616708986399412, 54.852778406853588],
              [-2.617248278437514, 54.853109066856156],
              [-2.620231023046848, 54.85321068898913],
              [-2.62087605525804, 54.853730408849522],
              [-2.622546077648642, 54.853546618225522],
              [-2.624042328305648, 54.854315373799075],
              [-2.624927029021476, 54.853910921164157],
              [-2.626611157913935, 54.853833941974955],
              [-2.628574920110401, 54.85451126096104],
              [-2.631379601667406, 54.853974587393822],
              [-2.632607627953926, 54.854114675056465],
              [-2.633144290314456, 54.854466846215963],
              [-2.635071711163862, 54.854606853913509],
              [-2.635622663907932, 54.854281353016482],
              [-2.636651680754819, 54.854344258624877],
              [-2.63877224406249, 54.853793925428704],
              [-2.640143636737717, 54.854133582776285],
              [-2.641613568750313, 54.854105153571545],
              [-2.642829956091686, 54.853706904410473],
              [-2.646241416148574, 54.853435370555083],
              [-2.647503029971634, 54.853725204533745],
              [-2.648961802796078, 54.853197092750982],
              [-2.652857305737835, 54.853583297026411],
              [-2.655324945748529, 54.854761620288016],
              [-2.657764896242743, 54.849445447339853],
              [-2.659305401851153, 54.848503384575302],
              [-2.668966137460727, 54.845034727011054],
              [-2.672554506638902, 54.842440238381059],
              [-2.674127596130884, 54.839660044394556],
              [-2.675678046669371, 54.838333088400688],
              [-2.676316443022707, 54.838241464092668],
              [-2.676645803480377, 54.836988692738842],
              [-2.679157499391176, 54.836081395091227],
              [-2.680131615813108, 54.83531028097552],
              [-2.683234620286497, 54.835041227479039],
              [-2.685215091073974, 54.836059029923312],
              [-2.685344858717457, 54.837560858984055],
              [-2.686315077581098, 54.837940902360891],
              [-2.687017240180974, 54.837297083055525],
              [-2.690528471101504, 54.837130675532819],
              [-2.690870606604229, 54.837472017269945],
              [-2.691392981513472, 54.83733604081079],
              [-2.691250953158433, 54.837133752249095],
              [-2.693355176891191, 54.836361481975672],
              [-2.693678385810652, 54.836506117057858],
              [-2.699945799792156, 54.833670801352774],
              [-2.701219576878893, 54.833597846242235],
              [-2.700118441068959, 54.830513701241969],
              [-2.69709322727531, 54.827953749318844],
              [-2.696029095932024, 54.825907309729033],
              [-2.69346872198904, 54.823008499983288],
              [-2.690028948429274, 54.820630472893207],
              [-2.689787960661181, 54.819184095141459],
              [-2.705657721166531, 54.816355511112484],
              [-2.707254400240055, 54.818762726903188],
              [-2.714535019412291, 54.819934171435975],
              [-2.716719358971041, 54.819103503715667],
              [-2.720299868974771, 54.819252150950533],
              [-2.721210520047646, 54.819614296505229],
              [-2.724043926902505, 54.819059122339382],
              [-2.726395398818364, 54.819143023620171],
              [-2.729201792195032, 54.818648999751581],
              [-2.732218412077393, 54.820144189757009],
              [-2.736124081486173, 54.821390376604711],
              [-2.736660409993448, 54.822809711627734],
              [-2.737746670341382, 54.823571472825101],
              [-2.741012211428513, 54.824257928925732],
              [-2.743615091006477, 54.827839370466727],
              [-2.746132029410363, 54.828087231667233],
              [-2.748366682468762, 54.828977531128231],
              [-2.751009638046345, 54.829048375642948],
              [-2.753154254466417, 54.827351882110783],
              [-2.754867165833859, 54.82705366628656],
              [-2.754946915173929, 54.824988032241421],
              [-2.756422984532473, 54.822850795156128],
              [-2.757957457891973, 54.823507133880298],
              [-2.759886518845337, 54.823706257076779],
              [-2.767004223601333, 54.823298417523461],
              [-2.77319392511197, 54.82051639965681],
              [-2.77888484080734, 54.821860363268236],
              [-2.78304691829256, 54.822278420011777],
              [-2.792694485862875, 54.820422936373127],
              [-2.796896861386708, 54.819015053437283],
              [-2.798744736939105, 54.819044243340372],
              [-2.799373118466676, 54.818711191733186],
              [-2.811348511921532, 54.82344230724226],
              [-2.814760202095782, 54.825294978540711],
              [-2.817764335098762, 54.82753043953732],
              [-2.82196992273377, 54.825061234632606],
              [-2.82557169619967, 54.827731006752138],
              [-2.829405956844104, 54.82546896566047],
              [-2.832091227457457, 54.826768038962712],
              [-2.834270394080141, 54.828350919226622],
              [-2.833958719216055, 54.828862608848389],
              [-2.834471715242419, 54.831016796034014],
              [-2.833938216844069, 54.831190312045514],
              [-2.837763600979302, 54.831738219099563],
              [-2.838467653762094, 54.833033736706831],
              [-2.838268433884041, 54.834162946818175],
              [-2.845240979343488, 54.832940024901873],
              [-2.848672700839185, 54.833111988499908],
              [-2.855686534737892, 54.833422212537251],
              [-2.85638448527813, 54.834253955337196],
              [-2.858226855028769, 54.83479542390495],
              [-2.861007650766188, 54.836632389889552],
              [-2.863055715898136, 54.837246916223954],
              [-2.864047064097834, 54.836059904183031],
              [-2.866633136250779, 54.835696376198193],
              [-2.869064276916255, 54.834718310169364],
              [-2.8709205875127, 54.834813744184302],
              [-2.871474137978946, 54.833480630556132],
              [-2.872250139172395, 54.833512800660323],
              [-2.873206093566947, 54.832794176062954],
              [-2.872787428370346, 54.832078247076936],
              [-2.870779846715596, 54.831820368485481],
              [-2.872231763061042, 54.829628857257049],
              [-2.871986681789739, 54.828586357023582],
              [-2.873830887373687, 54.827917957933018],
              [-2.874298937298031, 54.826742713198648],
              [-2.875875763044762, 54.826886814547123],
              [-2.877423761170893, 54.825844850686543],
              [-2.878013406846309, 54.824755883245551],
              [-2.881466191127211, 54.823490679317949],
              [-2.882894626550827, 54.823187326824645],
              [-2.884927173582478, 54.823321703257974],
              [-2.888729888996622, 54.821563959342754],
              [-2.89030762857673, 54.821261222922452],
              [-2.893038746536944, 54.821669825372403],
              [-2.893978701588252, 54.822510358696057],
              [-2.904895076246189, 54.821379794353142],
              [-2.905423322587445, 54.821964489465671],
              [-2.907404333256799, 54.821951490610829],
              [-2.90674395420204, 54.820819596653514],
              [-2.904436435597927, 54.818825570697634],
              [-2.904746374647477, 54.818766643478419],
              [-2.904097761252034, 54.816274944125624],
              [-2.904409223698913, 54.813012210046068],
              [-2.921076584803405, 54.809200442202098],
              [-2.91971041098112, 54.805978242872754],
              [-2.928544215482374, 54.801230612800104],
              [-2.934830622029204, 54.792950376864454],
              [-2.931166635129366, 54.792150866233222],
              [-2.94087226834677, 54.78763197059412],
              [-2.942008834057117, 54.787623149753315],
              [-2.942461852081847, 54.786908768899174],
              [-2.942232693514908, 54.785881551492579],
              [-2.943643281665446, 54.785221733494609],
              [-2.944778782331938, 54.784036511658698],
              [-2.947249741792042, 54.783443877223156],
              [-2.949458295704188, 54.784064679073516],
              [-2.951833490256699, 54.783833074914284],
              [-2.952749474637497, 54.782846312455071],
              [-2.962070213110152, 54.778615401245176],
              [-2.964652674626307, 54.780249379772634],
              [-2.967568650437562, 54.77988824605324],
              [-2.969342157869105, 54.777136671630984],
              [-2.973900591355145, 54.778304402136051],
              [-2.976635516373938, 54.777407085170587],
              [-2.978483679003105, 54.777777728513129],
              [-2.97937364575276, 54.777612375754458],
              [-2.984432928742943, 54.776150584663363],
              [-2.98535809929048, 54.776476487135014],
              [-2.985747429903268, 54.777079042707179],
              [-2.986036335953649, 54.776812478573348],
              [-2.986490053828584, 54.777057727156411],
              [-2.987137172004239, 54.778094047505888],
              [-2.987050876595071, 54.779466155232008],
              [-2.990245602483539, 54.780342403096995],
              [-2.991205098014035, 54.78143007276126],
              [-2.990783422535388, 54.782244138818477],
              [-2.989109851283652, 54.782541784275772],
              [-2.987690624746298, 54.783642564099715],
              [-2.986477254762172, 54.784068532493201],
              [-2.986763314809148, 54.785215632912411],
              [-2.98306432412081, 54.785999669314485],
              [-2.987504875429008, 54.789291456166723],
              [-2.986141160689307, 54.791534000013193],
              [-2.987933053399689, 54.793712226521912],
              [-2.988374903929199, 54.793909035541411],
              [-2.987768622366134, 54.792454499462089],
              [-2.988590585065146, 54.792295026479188],
              [-2.989147457730488, 54.792234768313307],
              [-2.990154802899327, 54.792724425450693],
              [-2.993242945512701, 54.792950814362612],
              [-2.996012538331237, 54.792744773808153],
              [-2.996301553796328, 54.791595669771688],
              [-3.001530441123307, 54.790909149610243],
              [-3.004793465963824, 54.790727591206334],
              [-3.009316646383404, 54.791063898389687],
              [-3.009774465338271, 54.791211967578732],
              [-3.009907014179112, 54.792286599897444],
              [-3.009352159366799, 54.793300449899242],
              [-3.002881068250494, 54.798411955953355],
              [-3.0019820017806, 54.799292912616046],
              [-3.001757970211702, 54.8002347936864],
              [-3.011980652495279, 54.803322338217626],
              [-3.014205948757195, 54.800987827663782],
              [-3.017287119700104, 54.801896672028569],
              [-3.021040237126563, 54.798847334394367],
              [-3.02676659270454, 54.801195822340809],
              [-3.028409396654555, 54.803464589510362],
              [-3.027920030260227, 54.804717021134493],
              [-3.027018349944973, 54.804375065283224],
              [-3.026645202587927, 54.804690968376526],
              [-3.02757843688372, 54.805540421230383],
              [-3.025939890818786, 54.80601980996083],
              [-3.026159816622514, 54.806962476034499],
              [-3.024911247809662, 54.807423273936628],
              [-3.025465978009735, 54.807947916675971],
              [-3.024925870701466, 54.808676826043268],
              [-3.025697521459518, 54.808919243256916],
              [-3.026079265853996, 54.80986143978901],
              [-3.02422084768314, 54.810575424424115],
              [-3.025004358878101, 54.810977713134427],
              [-3.025986021243742, 54.810848093349847],
              [-3.026851612877191, 54.811540852341388],
              [-3.027356425773119, 54.812486496623094],
              [-3.026686381417513, 54.812572151290055],
              [-3.027797156914539, 54.813420099871408],
              [-3.02710781345141, 54.814278795001499],
              [-3.028182022500575, 54.81460401109549],
              [-3.030128928515792, 54.814549754068288],
              [-3.029895804281071, 54.816273626000999],
              [-3.029071802527941, 54.817161334070924],
              [-3.029910562474412, 54.817709609579495],
              [-3.030132591819552, 54.818728638243684],
              [-3.030843373285009, 54.819260918537786],
              [-3.030301882090907, 54.82109884661358],
              [-3.029244824690025, 54.821638942506901],
              [-3.028744472110352, 54.823384847172328],
              [-3.029425556088276, 54.823481522546807],
              [-3.029302145937314, 54.824075704929776],
              [-3.030687184158198, 54.824615745959193],
              [-3.030350200181659, 54.824829799614186],
              [-3.030729674602456, 54.82677224159066],
              [-3.032502367868992, 54.828712723116006],
              [-3.036077470428189, 54.828507011714919],
              [-3.037146572414805, 54.828858255265324],
              [-3.03759636404739, 54.829279491304575],
              [-3.037620768858297, 54.830594965189199],
              [-3.039741326837258, 54.831816111526663],
              [-3.04033436947661, 54.831751716213425],
              [-3.041577546980543, 54.832328797539141],
              [-3.042345757159925, 54.833080697261764],
              [-3.044393569927407, 54.83286088835289],
              [-3.04745343962355, 54.833487868586467],
              [-3.047987833764076, 54.83506495114807],
              [-3.044625872102477, 54.835887475483069],
              [-3.039016776039288, 54.836215111958168],
              [-3.03573588437858, 54.836809326424195],
              [-3.029014558514858, 54.839754877195162],
              [-3.027683060295017, 54.840850873577402],
              [-3.027013031032561, 54.842917234815651],
              [-3.025731806872588, 54.844711961739137],
              [-3.021893304229524, 54.846692687618123],
              [-3.021970059797846, 54.847018264610512],
              [-3.020945793487537, 54.847222796653348],
              [-3.022144682316785, 54.849427969357023],
              [-3.022622637282154, 54.852252108448027],
              [-3.023268869491841, 54.852553115121232],
              [-3.025597476691128, 54.851386742641246],
              [-3.028267582702567, 54.85085370265643],
              [-3.032180856015575, 54.852407574888119],
              [-3.045684859259977, 54.853236533305406],
              [-3.053646357397175, 54.85414996499199],
              [-3.059539218941124, 54.855286746573462],
              [-3.064558897985468, 54.855846754947983],
              [-3.064961452617195, 54.855812669117718],
              [-3.065345009553105, 54.855057999861856],
              [-3.066204956279696, 54.854943506838083],
              [-3.06402281961938, 54.859802090190549],
              [-3.070088141917058, 54.860584566191633],
              [-3.070539978752754, 54.860703703554456],
              [-3.070247232535644, 54.861356036463683],
              [-3.064575524693445, 54.86144454122752],
              [-3.06427176695114, 54.862628979141085],
              [-3.063677470954588, 54.862839087692457],
              [-3.058046425292062, 54.862465903599329],
              [-3.058072791282793, 54.863948508107313],
              [-3.056255019969878, 54.86450264816208],
              [-3.055600369036335, 54.865446570356063],
              [-3.054921210309191, 54.865632210072661],
              [-3.055369242738242, 54.866201678961275],
              [-3.054428863507045, 54.867781651371253],
              [-3.056500779582726, 54.870741888793525],
              [-3.057489895723724, 54.87066677635044],
              [-3.064858469306432, 54.872237993911362],
              [-3.065799102475697, 54.87262786015441],
              [-3.06772158841981, 54.87437509426848],
              [-3.07236343747969, 54.876047989248036],
              [-3.070555413180309, 54.877041712459111],
              [-3.070202796760652, 54.876968432022764],
              [-3.065797964823023, 54.879552248358799],
              [-3.064249370443498, 54.880879707133431],
              [-3.063684247572134, 54.882088896365687],
              [-3.05716366884807, 54.886718335628693],
              [-3.059275390413299, 54.887169948602569],
              [-3.059859150206548, 54.886247299427239],
              [-3.060987894789351, 54.886844056399624],
              [-3.060290747656795, 54.888078645567781],
              [-3.061310841393648, 54.888217120458634],
              [-3.061440652831715, 54.889596364819688],
              [-3.064111156371553, 54.889236890241271],
              [-3.066110207967085, 54.888484236266734],
              [-3.070875700768982, 54.889704966116525],
              [-3.071830013390561, 54.895229749538686],
              [-3.073535691410973, 54.899736864987084],
              [-3.075405974000894, 54.900107654974533],
              [-3.0782971956524, 54.902383555034042],
              [-3.07803252980011, 54.90450140522362],
              [-3.081874418044043, 54.904754830991564],
              [-3.083831730934876, 54.90458011479285],
              [-3.085047543919616, 54.904264607813438],
              [-3.084661839492608, 54.903898692961043],
              [-3.089689261942549, 54.903122172280618],
              [-3.091826021777198, 54.903185665882674],
              [-3.097205962690821, 54.904311776311594],
              [-3.09944368875589, 54.904071367265239],
              [-3.099374208512093, 54.904440457168882],
              [-3.101279823121168, 54.904774583467102],
              [-3.101572765937595, 54.90653514883585],
              [-3.103869527694564, 54.906111690267878],
              [-3.105470647767846, 54.908761734864612],
              [-3.108832773571843, 54.90827995528155],
              [-3.110075087753008, 54.907740182913948],
              [-3.110531097793146, 54.906967638560978],
              [-3.112745539370144, 54.907124417279888],
              [-3.113197701822978, 54.906779672703529],
              [-3.112532733136901, 54.905855623277631],
              [-3.112872200105164, 54.905044594583416],
              [-3.113638417780225, 54.904382430322329],
              [-3.114934544914513, 54.904047014467018],
              [-3.119874892261167, 54.903973713874372],
              [-3.126358714288089, 54.905501753154773],
              [-3.125470340172742, 54.906642333471929],
              [-3.121495929585878, 54.908146644349429],
              [-3.118514428675663, 54.910198872164955],
              [-3.120594419066523, 54.9128631838934],
              [-3.121694482793823, 54.915341495820165],
              [-3.119937429893532, 54.91611170184359],
              [-3.11996307186699, 54.91652935449742],
              [-3.118847861704225, 54.917381697553182],
              [-3.118675002496683, 54.918405094792689],
              [-3.119955691647678, 54.918959469028991],
              [-3.122280165560174, 54.92203489242695],
              [-3.121390309057079, 54.922914832643151],
              [-3.120353154536103, 54.923043924807892],
              [-3.120397797574593, 54.9240886839019],
              [-3.118903738336093, 54.924004493714527],
              [-3.118867554913448, 54.925342968451645],
              [-3.118349905154889, 54.925920195682153],
              [-3.118670443839038, 54.926224595376581],
              [-3.11798970915596, 54.927054953743927],
              [-3.118099487002135, 54.927749525343827],
              [-3.116260198051919, 54.927375507045191],
              [-3.115936839376614, 54.927025294099863],
              [-3.115352475452565, 54.927280493195482],
              [-3.111894603387074, 54.92706144893922],
              [-3.106222106972937, 54.928137718242027],
              [-3.106288936202861, 54.927559256712335],
              [-3.105855047166963, 54.928182398501356],
              [-3.104886278203922, 54.928478789042835],
              [-3.103872696712437, 54.928051240641416],
              [-3.103247265457377, 54.928519741881772],
              [-3.101915835460646, 54.928281089739471],
              [-3.100387019581084, 54.928642733005645],
              [-3.100198608377257, 54.928372138212666],
              [-3.100411143441398, 54.928726092009676],
              [-3.101763111708561, 54.928402897943656],
              [-3.103291100974444, 54.928638868688786],
              [-3.103829731371723, 54.928191825422623],
              [-3.104293959970013, 54.928503944045957],
              [-3.100757730457399, 54.929988297608773],
              [-3.097203220288795, 54.931957101111067],
              [-3.08934273055379, 54.933424364833108],
              [-3.088924686622234, 54.933779495341376],
              [-3.08757552320197, 54.933812246224612],
              [-3.086132655659387, 54.93442187350437],
              [-3.072885560313936, 54.937145709806543],
              [-3.066922659636549, 54.93948983049021],
              [-3.068953459191246, 54.938959739958705],
              [-3.065711131708432, 54.94136970815282],
              [-3.062899553289739, 54.945268522301532],
              [-3.057016222643042, 54.94517963472439],
              [-3.050190072206652, 54.94599279377254],
              [-3.048547298837219, 54.945798483888211],
              [-3.04858945824849, 54.945079180448573],
              [-3.049523091029528, 54.944541802891941],
              [-3.050683994709044, 54.944758239306772],
              [-3.051376701727604, 54.943604641227317],
              [-3.052944116120709, 54.943725874092117],
              [-3.053046941800175, 54.943299909810804],
              [-3.052170464583988, 54.94291477922976],
              [-3.051869356693113, 54.943160926484232],
              [-3.052618026208414, 54.943255096665489],
              [-3.052767723320445, 54.943606979561025],
              [-3.051355218021211, 54.94343947082163],
              [-3.050455253623293, 54.944607441653858],
              [-3.049301979737291, 54.944443959024809],
              [-3.046958520830045, 54.945638735092409],
              [-3.039278809993351, 54.944422234507478],
              [-3.035763977013087, 54.944152978971424],
              [-3.03330632903845, 54.944319482342991],
              [-3.033133422745844, 54.944090891782977],
              [-3.032917503723108, 54.94431559788616],
              [-3.02952061132499, 54.944383060761176],
              [-3.02666705218001, 54.944881696728856],
              [-3.026287286338307, 54.944559584412197],
              [-3.026111083983896, 54.944751590663209],
              [-3.025539882315631, 54.944574879626416],
              [-3.026176900902254, 54.944945148290905],
              [-3.025934980985809, 54.945437865695716],
              [-3.017326409932858, 54.946728821112806],
              [-3.013637030716375, 54.946721899763737],
              [-3.009604672143857, 54.946174019937025],
              [-3.00726546151583, 54.944842725273602],
              [-3.005854645439512, 54.941611105325777],
              [-3.00621486155705, 54.941220796418271],
              [-3.005726907138803, 54.94143422551123],
              [-3.005131378142142, 54.940902645257943],
              [-3.005583904720786, 54.940708383743917],
              [-3.005262764294615, 54.940099943876781],
              [-3.005357327916676, 54.940636567117437],
              [-3.004890693458012, 54.940703333126457],
              [-3.004092920545554, 54.940135675617498],
              [-3.002604588688406, 54.93778625435889],
              [-3.003713851596101, 54.933692635156888],
              [-3.006910677429562, 54.93114990719873],
              [-3.0063842502317, 54.930880172649658],
              [-3.005806901167743, 54.93063332412639],
              [-3.002307679547114, 54.933067082204758],
              [-3.00103513099964, 54.933483770628698],
              [-3.000928446600937, 54.934278176542833],
              [-2.999208013602659, 54.935139781197627],
              [-2.999206530015264, 54.936275714015061],
              [-2.998365182887736, 54.935832394097382],
              [-2.995859042313413, 54.935916761362684],
              [-2.997465897845525, 54.936137238382287],
              [-2.998482260942827, 54.935959043589357],
              [-3.000797888185701, 54.937567490752805],
              [-3.002203522245937, 54.939860100744752],
              [-3.004173970192466, 54.941758904646804],
              [-3.005713211107862, 54.945762337627173],
              [-3.008030670736809, 54.94703542877113],
              [-3.01190834234061, 54.94788660226866],
              [-3.016062510180598, 54.94817996297715],
              [-3.021454051755129, 54.947518286143968],
              [-3.028003990466425, 54.946127639738656],
              [-3.028029900835222, 54.946347595378001],
              [-3.028648702493877, 54.946124881354493],
              [-3.035846785264028, 54.945855259569342],
              [-3.04002733240874, 54.946082871987358],
              [-3.043838405070145, 54.947353280206023],
              [-3.041657760748618, 54.947974098719712],
              [-3.04251409896959, 54.949390256161223],
              [-3.041834775047684, 54.947996845821962],
              [-3.044222577459419, 54.947477592146193],
              [-3.047087204088103, 54.948546635281971],
              [-3.047347726274869, 54.948298154078365],
              [-3.05149748330172, 54.948649655728524],
              [-3.055780603291262, 54.950392803626777],
              [-3.052395080832663, 54.951033260305067],
              [-3.050037895605177, 54.952431312316584],
              [-3.050038835638233, 54.952646985907187],
              [-3.051416996925668, 54.952085084906557],
              [-3.05277308096306, 54.951098286356327],
              [-3.056513010093584, 54.95093283424039],
              [-3.055986151382505, 54.951681514859736],
              [-3.056123057723891, 54.952614946881368],
              [-3.055355625091535, 54.953397984820285],
              [-3.056421126200451, 54.952727387167535],
              [-3.056412404785029, 54.951859343438308],
              [-3.057083039474026, 54.952424171677521],
              [-3.056657344640127, 54.953100979948985],
              [-3.057290222290854, 54.952702756264138],
              [-3.058409143416108, 54.952677741528333],
              [-3.057387613176758, 54.952546438452622],
              [-3.056665598724925, 54.95168998928974],
              [-3.057442087530934, 54.951053266034826],
              [-3.060059999856731, 54.950734800014878],
              [-3.060014563788263, 54.951499968650843],
              [-3.058567385203457, 54.951985282803065],
              [-3.059249275906128, 54.951908347436024],
              [-3.060276965798804, 54.951503072189354],
              [-3.060625377566849, 54.95050340204137],
              [-3.061241411301783, 54.951068684027668],
              [-3.062048360824039, 54.951116455498955],
              [-3.061290650923213, 54.950922668763646],
              [-3.061512580154354, 54.950279076830995],
              [-3.06459894028836, 54.949256352565762],
              [-3.065445301831147, 54.949614697982973],
              [-3.064703045646274, 54.950418326997806],
              [-3.065500436245392, 54.949810126013901],
              [-3.065994746121507, 54.950552591581051],
              [-3.06587799905766, 54.948914430770337],
              [-3.066504851756515, 54.948648316356341],
              [-3.066835797097985, 54.949058803133461],
              [-3.06627048495093, 54.950116112776655],
              [-3.06735686730546, 54.949514350834683],
              [-3.068893426659585, 54.950580158581644],
              [-3.072501302526488, 54.95056096079346],
              [-3.075813668217688, 54.95169547631756],
              [-3.076225477387153, 54.952264287909493],
              [-3.076946147828089, 54.952120407748922],
              [-3.072505923319606, 54.950206841552003],
              [-3.070431542723571, 54.949939347546348],
              [-3.0689898798413, 54.950155135325836],
              [-3.068400312746072, 54.949590558149708],
              [-3.068568094575207, 54.948911481538403],
              [-3.069361892978017, 54.947877313681822],
              [-3.071107689881963, 54.94724185544527],
              [-3.072909585097252, 54.947600711919421],
              [-3.073562248874635, 54.948832427084398],
              [-3.074658722410973, 54.948672658992649],
              [-3.073552943259405, 54.948659064675837],
              [-3.073541834589004, 54.947832380191933],
              [-3.072652482128744, 54.946916391987436],
              [-3.072422351090338, 54.947244642303396],
              [-3.071748618717498, 54.947037598304725],
              [-3.071693051478599, 54.946416203924379],
              [-3.072498303532027, 54.946285982647453],
              [-3.073359659424871, 54.94533207238652],
              [-3.074264911279857, 54.945381590267033],
              [-3.073478418977662, 54.945101860911173],
              [-3.075313950505719, 54.94379962857181],
              [-3.075903532804592, 54.944013688414969],
              [-3.075106352449646, 54.945029058406917],
              [-3.076495306676651, 54.944367919714253],
              [-3.081125577954611, 54.940582928693026],
              [-3.082840239901979, 54.939969144210849],
              [-3.091676718885638, 54.938346163215378],
              [-3.092308930490574, 54.938520218870117],
              [-3.092249127009078, 54.939077937921148],
              [-3.093054533934254, 54.938385902918554],
              [-3.093968833851146, 54.93929073563784],
              [-3.095289276086584, 54.939687742594366],
              [-3.095458345709767, 54.939475927681187],
              [-3.097581564019799, 54.939601459529705],
              [-3.099403341530861, 54.939148235112114],
              [-3.100628029602648, 54.939295318507817],
              [-3.098707057643502, 54.938972998062333],
              [-3.09577655881344, 54.939065956962466],
              [-3.094712959074872, 54.938469831185557],
              [-3.094734080674022, 54.937925939346947],
              [-3.096927344012383, 54.937531413937869],
              [-3.097313357163454, 54.937835278112424],
              [-3.09732050248758, 54.937352622083225],
              [-3.100506039979682, 54.936715411536156],
              [-3.100944275120811, 54.936759072573338],
              [-3.100132519492013, 54.937555465261923],
              [-3.10143896017567, 54.936697973434171],
              [-3.103150451693972, 54.936441603854107],
              [-3.102990122227548, 54.936172555935848],
              [-3.105434606067791, 54.935440387541753],
              [-3.110118188641075, 54.93338554626029],
              [-3.111856623849001, 54.933886396475046],
              [-3.121111909533056, 54.93478362994238],
              [-3.129782251771456, 54.934398683519795],
              [-3.126887424074067, 54.935139115393781],
              [-3.117486101638205, 54.936018562808449],
              [-3.115783484270142, 54.936816958972187],
              [-3.115634838762247, 54.937592987971506],
              [-3.11613054667052, 54.938414325860201],
              [-3.117108127112626, 54.936797600982651],
              [-3.119579427569372, 54.936251826777003],
              [-3.124116359311583, 54.936626936509178],
              [-3.122838191869083, 54.938201562372484],
              [-3.120289758245963, 54.938446155876143],
              [-3.12278535327139, 54.938601964533738],
              [-3.120624055489447, 54.940531612774095],
              [-3.122664526758035, 54.939531420303524],
              [-3.123568760623036, 54.93825951586323],
              [-3.125299996849458, 54.937203721905817],
              [-3.126138733474504, 54.937167195204111],
              [-3.128646175921818, 54.938026443004468],
              [-3.127065600524353, 54.938049198915635],
              [-3.126903564108003, 54.939120134911917],
              [-3.126003183709011, 54.940135903964013],
              [-3.131507943678526, 54.945716829475018],
              [-3.131116001383242, 54.947604116778315],
              [-3.130224867412468, 54.947728336197578],
              [-3.128498170139709, 54.946951741419468],
              [-3.129465320366094, 54.947812683060747],
              [-3.129012816328035, 54.948100870586714],
              [-3.129547002814857, 54.948275644203385],
              [-3.128993016601693, 54.949121058973915],
              [-3.127143976284164, 54.949943438003338],
              [-3.126691983792098, 54.949693301383448],
              [-3.125572863859102, 54.950153909008357],
              [-3.125927563621827, 54.950498413898281],
              [-3.124750720104612, 54.951073680967411],
              [-3.12093787050925, 54.951920410206206],
              [-3.118660073727997, 54.950641002206638],
              [-3.115055594174807, 54.947061423205334],
              [-3.116704235356382, 54.950381293423888],
              [-3.116284591809457, 54.950507368469452],
              [-3.115174845757759, 54.950127527166735],
              [-3.116501646813572, 54.950845974994479],
              [-3.11596287979753, 54.95236430050786],
              [-3.111588932825094, 54.950431773802727],
              [-3.111195268216088, 54.949293150419713],
              [-3.111193378983496, 54.950412923381862],
              [-3.108727943197191, 54.949108984268911],
              [-3.109125542086769, 54.949769481635443],
              [-3.110480669202094, 54.95055693457104],
              [-3.110545042801418, 54.951192611986123],
              [-3.113157609650839, 54.952558973722688],
              [-3.11371914215603, 54.953327593869574],
              [-3.113033993610012, 54.953453393647152],
              [-3.109661095498076, 54.952659241988655],
              [-3.109833552980911, 54.953024328913607],
              [-3.108982991563577, 54.953210928790575],
              [-3.106000196341566, 54.953028711822483],
              [-3.108026308555507, 54.953513521000723],
              [-3.111034099135795, 54.953524710845919],
              [-3.111383181133379, 54.953951987427899],
              [-3.106999870417148, 54.955084777257625],
              [-3.106054710519302, 54.955070014956185],
              [-3.104954192608111, 54.954572269583593],
              [-3.105375115179225, 54.954944989325469],
              [-3.104540120760095, 54.955018182685862],
              [-3.103319747632962, 54.95459070985391],
              [-3.104629059107844, 54.955186326175216],
              [-3.107578263973136, 54.955617817605919],
              [-3.106042819207987, 54.956171903570763],
              [-3.104353505031174, 54.955786451212127],
              [-3.105548463181572, 54.956310303573623],
              [-3.105325304310066, 54.956714042262902],
              [-3.108522142635515, 54.955755698859903],
              [-3.111944875821488, 54.955462024767108],
              [-3.1089318002014, 54.95639811290377],
              [-3.112902896949573, 54.956111091423104],
              [-3.115959562250484, 54.956920639265974],
              [-3.11781115745969, 54.958251647538887],
              [-3.11626616177786, 54.960656331360646],
              [-3.113220818980397, 54.963542054087902],
              [-3.111094138559117, 54.964866389513688],
              [-3.110400754897473, 54.965040777147898],
              [-3.107663345222872, 54.964669430836494],
              [-3.106377702238217, 54.964777295749279],
              [-3.108669358078493, 54.96592110615709],
              [-3.10658383962247, 54.965457516036558],
              [-3.104919456264095, 54.965482538977007],
              [-3.104627593372182, 54.965092469065617],
              [-3.1043077316358, 54.9655033743716],
              [-3.100904130844019, 54.964987846220147],
              [-3.102868516287241, 54.965366359249792],
              [-3.102696723435799, 54.965595282839807],
              [-3.096958982058665, 54.966261882423026],
              [-3.094989499675192, 54.966212235478388],
              [-3.105384721265169, 54.9666124406018],
              [-3.10850337856664, 54.967664256832784],
              [-3.108733242479921, 54.968296627170751],
              [-3.10779761279457, 54.969093295689916],
              [-3.104020331091018, 54.971368054112979],
              [-3.09434117016907, 54.970746500839446],
              [-3.086010401173519, 54.968444201268632],
              [-3.082680169087903, 54.967869093945666],
              [-3.078797045160383, 54.967526167532988],
              [-3.075680930426877, 54.967591521239434],
              [-3.071875436499915, 54.968928207167025],
              [-3.070072913062484, 54.970284905310145],
              [-3.069543460860381, 54.971347302069724],
              [-3.069676490775503, 54.972649207561474],
              [-3.070485769780923, 54.973472459026368],
              [-3.072502022701567, 54.97444413015122],
              [-3.072332306750459, 54.974876091286049],
              [-3.068400342922285, 54.97501045638375],
              [-3.067006729251459, 54.977130978321284],
              [-3.066144661055519, 54.977145729256655],
              [-3.066528114197799, 54.977642028131754],
              [-3.064077990170536, 54.980292107188049],
              [-3.06270374346299, 54.97754250805319],
              [-3.063758751961983, 54.980451269049944],
              [-3.060832041890866, 54.982523109507007],
              [-3.058414712121139, 54.983060009987113],
              [-3.057072800455419, 54.982956652331289],
              [-3.057908264390359, 54.982579134957774],
              [-3.060251513011552, 54.97929205914803],
              [-3.060936263794917, 54.977291935447077],
              [-3.058101839425722, 54.982096662179082],
              [-3.057359850615102, 54.982700731789215],
              [-3.056595743244866, 54.982820605741466],
              [-3.055391099734599, 54.981933295217502],
              [-3.055880165779749, 54.981574973629513],
              [-3.055726197409367, 54.980531156930049],
              [-3.055266608266152, 54.980702298800743],
              [-3.055402462626269, 54.981473077494023],
              [-3.054935057089847, 54.981584973644765],
              [-3.054317853767811, 54.980870491695924],
              [-3.054990845761876, 54.980375778247158],
              [-3.054122122350456, 54.980735590708683],
              [-3.053808238909755, 54.979907939998277],
              [-3.053113855949656, 54.979884299502281],
              [-3.054010376164779, 54.979393047352389],
              [-3.053026403396085, 54.97970712015222],
              [-3.052314918096564, 54.979387960736588],
              [-3.053414283935169, 54.978707135248648],
              [-3.052032941020246, 54.979181010465147],
              [-3.049199865248244, 54.977194264575189],
              [-3.050580030751728, 54.977274911000784],
              [-3.050791559911586, 54.977656815446075],
              [-3.051637320287333, 54.977734877500922],
              [-3.050821046816629, 54.97735011406381],
              [-3.051860803096995, 54.977079611125141],
              [-3.050502446735162, 54.977175828888718],
              [-3.050647960039369, 54.976525731679885],
              [-3.053736006962866, 54.976017322675752],
              [-3.050593915255655, 54.97637072866074],
              [-3.04988818594272, 54.977226966259948],
              [-3.048937890920699, 54.976914342312263],
              [-3.048958074274712, 54.976489995859978],
              [-3.051199644606551, 54.975634874461861],
              [-3.051726454023583, 54.975108191318704],
              [-3.050398529487087, 54.975709197858649],
              [-3.051196819748081, 54.975227801094007],
              [-3.05053906477416, 54.975287405944215],
              [-3.048353642395152, 54.976798060398373],
              [-3.045992922228505, 54.975839739063204],
              [-3.04876492273764, 54.975193982008527],
              [-3.048012226919256, 54.975087688672708],
              [-3.047095174034351, 54.974068858417027],
              [-3.047002947924569, 54.974967424258715],
              [-3.044770174057492, 54.975381145633094],
              [-3.043543720542373, 54.975260471685893],
              [-3.042369210982524, 54.974672031806016],
              [-3.041571688801524, 54.973134951163928],
              [-3.042385728187617, 54.971329736087007],
              [-3.042140797932313, 54.969774439795586],
              [-3.040110499046977, 54.968040316348784],
              [-3.039990129544755, 54.968278595052894],
              [-3.039068620587081, 54.968041136880075],
              [-3.039165319634338, 54.967551433657384],
              [-3.038697487453389, 54.967884344747659],
              [-3.037586721491854, 54.967818340818447],
              [-3.036702799697238, 54.967644349623043],
              [-3.036736389197118, 54.967250445591546],
              [-3.036037618511339, 54.967108121470226],
              [-3.036583991167983, 54.967276907780345],
              [-3.036626184448104, 54.967762728691255],
              [-3.035999195213258, 54.967981956387561],
              [-3.033023251062065, 54.96833888706248],
              [-3.020225822434109, 54.971076450044507],
              [-3.014431699305283, 54.972997753090517],
              [-3.011807101676988, 54.973308094053934],
              [-3.008829206484512, 54.974234200707635],
              [-3.009946849301977, 54.974818042744218],
              [-3.011115147880747, 54.97424576780611],
              [-3.014616859840082, 54.97364953934354],
              [-3.021588479740265, 54.97152422801166],
              [-3.023407850374646, 54.97113238582746],
              [-3.023658135524668, 54.971329783322943],
              [-3.033885178476894, 54.968751239540737],
              [-3.035074504783982, 54.968773472417219],
              [-3.03330905290091, 54.969553257350086],
              [-3.032045043396645, 54.969604433305996],
              [-3.032851762426046, 54.969691044629883],
              [-3.032747510212558, 54.969889637516012],
              [-3.036189596734726, 54.968885294687809],
              [-3.038925493083419, 54.968850264704258],
              [-3.040764137222149, 54.969214677335998],
              [-3.041055548613856, 54.969670504449297],
              [-3.040412455187201, 54.971381685319926],
              [-3.038334601001835, 54.972950551657917],
              [-3.037869335058452, 54.974051799541307],
              [-3.040672039455368, 54.976706778318672],
              [-3.042652211225183, 54.977634316690825],
              [-3.045307245535104, 54.977952117601241],
              [-3.045569795625751, 54.978495352812644],
              [-3.04492110419195, 54.978906228828365],
              [-3.045741728573502, 54.978617890731329],
              [-3.047278454215824, 54.981709568605254],
              [-3.048675343167542, 54.98308507577287],
              [-3.047141811105144, 54.983063242503412],
              [-3.04815480103304, 54.983378036393844],
              [-3.048884972330247, 54.983273785730219],
              [-3.051035084022875, 54.984943821170901],
              [-3.049495588721077, 54.984816025855679],
              [-3.0487647009223, 54.984054867902927],
              [-3.047402365114096, 54.983975187704608],
              [-3.04499004577701, 54.981259251386376],
              [-3.045448420343738, 54.982662627337731],
              [-3.042320251950758, 54.979536050884725],
              [-3.041750615783274, 54.979622711179843],
              [-3.042131250298146, 54.97959878030165],
              [-3.045201602159384, 54.982665647668242],
              [-3.047241439673734, 54.983965774341634],
              [-3.047593073602033, 54.98418988157816],
              [-3.047421329277636, 54.984675743519105],
              [-3.046887210772939, 54.984688430764187],
              [-3.047663688808109, 54.984981000589052],
              [-3.047826495229179, 54.984212134599566],
              [-3.051872192513481, 54.985760663076086],
              [-3.054161200506411, 54.985982584722763],
              [-3.055621272484381, 54.986714017883536],
              [-3.055656241084296, 54.986438721489229],
              [-3.056368959029464, 54.986561042334429],
              [-3.055695003934837, 54.987319979692735],
              [-3.057674395174062, 54.989610563841019],
              [-3.05755314735653, 54.991715402503552],
              [-3.053448988468891, 54.992798868730617],
              [-3.051995657622037, 54.993407265393088],
              [-3.052657363372443, 54.994681243379141],
              [-3.049345535088608, 54.996971808302064],
              [-3.045122257935392, 54.998072192970106],
              [-3.044043312839559, 54.998722208518018],
              [-3.044316037491353, 54.999170997827186],
              [-3.045850343490947, 54.999559518685388],
              [-3.04651886570426, 55.000858632450274],
              [-3.046796721293229, 55.00480408231936],
              [-3.045059130599048, 55.006045701795614],
              [-3.045288524716194, 55.007110446806877],
              [-3.044899017011597, 55.007644904501063],
              [-3.045308373542214, 55.007993664352313],
              [-3.046246496446269, 55.007931679399789],
              [-3.047380060177235, 55.007029549537492],
              [-3.048648679187829, 55.006811027970855],
              [-3.049733068086778, 55.007860303344323],
              [-3.049494372142243, 55.009377513273016],
              [-3.048059872687639, 55.009414144756548],
              [-3.047472662761808, 55.010761808407992],
              [-3.04558532945951, 55.010645042439478],
              [-3.045238118194612, 55.012686198080907],
              [-3.044292171980667, 55.013353941770234],
              [-3.043216939663638, 55.01338024163033],
              [-3.042003441393029, 55.014105079782802],
              [-3.042648508624174, 55.014616284792538],
              [-3.04161542416954, 55.015907527947427],
              [-3.039313943738705, 55.01641518555023],
              [-3.03928482005451, 55.016861171457634],
              [-3.040731610340829, 55.017228039493652],
              [-3.040567887152092, 55.018696058720913],
              [-3.042328158758148, 55.019628181703816],
              [-3.04153508661058, 55.020288301839315],
              [-3.039695845763491, 55.020414568781291],
              [-3.040129042549188, 55.021136985788324],
              [-3.040570677978562, 55.021218582383341],
              [-3.041070129178716, 55.020875513711744],
              [-3.041268104643622, 55.021211716821014],
              [-3.040123906250831, 55.022629708700372],
              [-3.039106684475259, 55.022852282281676],
              [-3.037766476227118, 55.022077391246107],
              [-3.036673694067131, 55.02191775876674],
              [-3.034393024913275, 55.02294546911579],
              [-3.03417228433392, 55.024092241039995],
              [-3.03080703013862, 55.024375119805107],
              [-3.030069776225527, 55.024721956393925],
              [-3.0299099228732, 55.025442238736652],
              [-3.031732708126424, 55.026065744162658],
              [-3.031614120470887, 55.027051682522092],
              [-3.03232546407382, 55.027582150137171],
              [-3.03226304498835, 55.028257574279714],
              [-3.034026272711992, 55.029113405628728],
              [-3.034328632344416, 55.029858521894333],
              [-3.032273562939596, 55.031039739427783],
              [-3.031634086485544, 55.032943133858446],
              [-3.029925527539225, 55.034161811674117],
              [-3.028427480300842, 55.034134940626849],
              [-3.026255577440384, 55.035045646186497],
              [-3.025872225353825, 55.036453483376746],
              [-3.027393453952826, 55.036649138506455],
              [-3.031113467216641, 55.037953082913198],
              [-3.03200182916961, 55.038909814266724],
              [-3.032152397478263, 55.039835953969366],
              [-3.033769702080944, 55.039563412054378],
              [-3.034385206269447, 55.038915640138853],
              [-3.035840235304299, 55.039208808147571],
              [-3.036522710622678, 55.04030745014532],
              [-3.038144023666074, 55.041339670196201],
              [-3.040225687612539, 55.041170919706083],
              [-3.038348166625724, 55.043725667424319],
              [-3.040103899853597, 55.044804343635583],
              [-3.041881624484179, 55.044556384810569],
              [-3.044011207880164, 55.044840084702699],
              [-3.043361970536541, 55.047378075388352],
              [-3.046950069768616, 55.047347262491897],
              [-3.049076999265182, 55.048480130936468],
              [-3.050712263044169, 55.048824589959203],
              [-3.051755982936913, 55.047560146712527],
              [-3.053417063687104, 55.047278878596281],
              [-3.053773694278263, 55.048045044669607],
              [-3.053279760042769, 55.049739700233978],
              [-3.051192452477086, 55.051261203800493],
              [-3.051049381401094, 55.052730849161108],
              [-3.048008857129222, 55.053068003245244],
              [-3.043620434160032, 55.054967730475724],
              [-3.043168684935418, 55.055655481968259],
              [-3.03919922939434, 55.055195174723984],
              [-3.03417026374752, 55.05541054897067],
              [-3.02754956676507, 55.055275206974038],
              [-3.021194813345555, 55.054884751763282],
              [-3.000072915446211, 55.052461219779403],
              [-2.972285632480037, 55.050230347645574],
              [-2.964550852080379, 55.050064532452822],
              [-2.958693463739319, 55.049268775080215],
              [-2.956557084640695, 55.053475993049751],
              [-2.955362029704643, 55.054762361124403],
              [-2.950690453113476, 55.055474723381813],
              [-2.947991472750361, 55.055535314627328],
              [-2.943844737787273, 55.05813677747912],
              [-2.939067506988727, 55.059243118043788],
              [-2.937303057330967, 55.059108440583437],
              [-2.936447516579865, 55.059613774993089],
              [-2.937376767142345, 55.060654445909094],
              [-2.939541203183512, 55.061285684127185],
              [-2.941255457779319, 55.062212426455254],
              [-2.943055099719056, 55.064303130878983],
              [-2.942624073949102, 55.067215391065098],
              [-2.942180881859196, 55.067946726148364],
              [-2.940421036444887, 55.069160027171435],
              [-2.937634076022811, 55.070386608987306],
              [-2.934816274887417, 55.071103830662473],
              [-2.932486137902167, 55.071178315293345],
              [-2.929441138757475, 55.070343393803377],
              [-2.926180955022966, 55.070559651647422],
              [-2.920278874348898, 55.072961555005989],
              [-2.917996395837023, 55.074575677597977],
              [-2.916527284319788, 55.076218683137441],
              [-2.917197025436854, 55.078107980202503],
              [-2.915157646020835, 55.078862010960137],
              [-2.912340953919986, 55.07891460229758],
              [-2.910655225579002, 55.077108369042783],
              [-2.906226846313244, 55.077572759491446],
              [-2.900873595826629, 55.076963667443984],
              [-2.899642967008734, 55.077054543432929],
              [-2.896826987455948, 55.077973960414234],
              [-2.892624736085611, 55.085015138806845],
              [-2.893740664375495, 55.086231799865331],
              [-2.89330512086108, 55.087847156256686],
              [-2.89072733044683, 55.088245265874107],
              [-2.890071924638129, 55.088081114076942],
              [-2.889719380225963, 55.087162584784515],
              [-2.886028245800322, 55.086665592097553],
              [-2.884812156940285, 55.086862250488856],
              [-2.88381134691871, 55.087585734228739],
              [-2.883974850440961, 55.088250439029842],
              [-2.88562303441201, 55.088604209148194],
              [-2.886270450345302, 55.089328295227084],
              [-2.88302306011876, 55.090773533673044],
              [-2.882312533671548, 55.092944402669744],
              [-2.882904600160991, 55.093360674244394],
              [-2.88587072013981, 55.093499090877074],
              [-2.886493915775445, 55.094045421269968],
              [-2.886289791507879, 55.094805357890777],
              [-2.884281602294897, 55.095475063845662],
              [-2.87897339006064, 55.094798176992384],
              [-2.877067423932852, 55.095060841521175],
              [-2.876106655585902, 55.096555900815673],
              [-2.874423961384348, 55.09750347581587],
              [-2.871323480133158, 55.100276424828678],
              [-2.872889450915844, 55.101873770204897],
              [-2.871690242140984, 55.102941851162832],
              [-2.871791359215166, 55.104274703101623],
              [-2.870856519150997, 55.106260186612239],
              [-2.867942710224451, 55.107057406458757],
              [-2.864022238897213, 55.106609903113828],
              [-2.86207830772583, 55.107906029271462],
              [-2.85853733907806, 55.1083444119785],
              [-2.852091560076963, 55.108483133981153],
              [-2.850112949527607, 55.111047281098394],
              [-2.848108261696536, 55.111578868545926],
              [-2.845196787654973, 55.111486776810203],
              [-2.84453686659958, 55.111860692154302],
              [-2.844622644547254, 55.112359737040528],
              [-2.846182950880998, 55.113346389704503],
              [-2.846388757519871, 55.114627308175727],
              [-2.843095504939619, 55.117111499194024],
              [-2.841140640669073, 55.118092839531613],
              [-2.839016923137226, 55.116858387987804],
              [-2.838151814710149, 55.117024299925809],
              [-2.837981367554391, 55.117869287262998],
              [-2.838475246032583, 55.118542558017026],
              [-2.842089307562161, 55.120779482975294],
              [-2.842145500951201, 55.12151237738923],
              [-2.841247270351511, 55.122493597937989],
              [-2.840236319432343, 55.12251495300076],
              [-2.835527805230412, 55.121331490963058],
              [-2.83268956671852, 55.121475834144626],
              [-2.827604922811366, 55.124832716970289],
              [-2.826776238064843, 55.126012851992385],
              [-2.826848497349142, 55.127232699719464],
              [-2.829332601743688, 55.128518826916697],
              [-2.832443954489248, 55.131073976695376],
              [-2.83297698609771, 55.132644731029885],
              [-2.827016048042057, 55.136398468945416],
              [-2.825499458837077, 55.138310248748176],
              [-2.823285638188188, 55.137909162756728],
              [-2.819579618224728, 55.138480512187591],
              [-2.818140898693462, 55.13739565372736],
              [-2.817065259327284, 55.137878244944652],
              [-2.815509455350945, 55.137739495831916],
              [-2.813594309833563, 55.136900401009463],
              [-2.813329987019333, 55.136244371121514],
              [-2.812732631649568, 55.13618096015486],
              [-2.809779801460904, 55.137074085386743],
              [-2.807740782411828, 55.136928562899485],
              [-2.804125075413275, 55.137550963575094],
              [-2.802227063794559, 55.138804476533416],
              [-2.799904806996212, 55.138383012093442],
              [-2.796838180439988, 55.138862302877719],
              [-2.794854480792827, 55.139686717562817],
              [-2.793392311056551, 55.139601891435099],
              [-2.792801488802101, 55.140264428745411],
              [-2.790710245535499, 55.139862852944702],
              [-2.790589288075553, 55.141282565361003],
              [-2.789697436117796, 55.141719688109312],
              [-2.784915171586208, 55.141772154355472],
              [-2.784107475266921, 55.142584318385964],
              [-2.784433245948825, 55.143355936351043],
              [-2.783229314123272, 55.144125713161671],
              [-2.781930142562184, 55.145723720627615],
              [-2.774987821251457, 55.148169188410378],
              [-2.774943112039508, 55.149262196858089],
              [-2.769751963299387, 55.149966367763128],
              [-2.769366943992826, 55.150669723646445],
              [-2.768706161059384, 55.150905737805353],
              [-2.76608098663875, 55.150989665319443],
              [-2.766568468405151, 55.151616533078389],
              [-2.765707092628166, 55.152523264821532],
              [-2.761403168623763, 55.153371569770073],
              [-2.76039080373613, 55.154021302498208],
              [-2.76019918554713, 55.154837546180751],
              [-2.75729580888608, 55.154913133782863],
              [-2.754019041292725, 55.155826678920889],
              [-2.754460081350706, 55.156328074823612],
              [-2.753683493856866, 55.156674355120082],
              [-2.75226727745135, 55.156724441455587],
              [-2.751426374764305, 55.156406126219899],
              [-2.750449946312622, 55.156725760620475],
              [-2.750970846086801, 55.157135916691189],
              [-2.750596679662925, 55.157352092952308],
              [-2.748783185505441, 55.157308432615395],
              [-2.748909228170048, 55.157836944535177],
              [-2.74842746369906, 55.158258660208801],
              [-2.746251496144316, 55.158220781944522],
              [-2.745601329410006, 55.158787296252868],
              [-2.745145620097108, 55.160271904506651],
              [-2.74391430813172, 55.1597231818089],
              [-2.742893326108915, 55.161030607944348],
              [-2.740650580511671, 55.161377644586779],
              [-2.741412003733697, 55.162148522046394],
              [-2.739647024050189, 55.162786480573274],
              [-2.737754048229719, 55.162786271408883],
              [-2.737044322989417, 55.163538214634158],
              [-2.735808072435958, 55.163743379809155],
              [-2.735909600406379, 55.164397858475688],
              [-2.734962356989744, 55.164529380329434],
              [-2.735056460281472, 55.165548742665202],
              [-2.733995416290207, 55.165810343886115],
              [-2.733810473080148, 55.166680419431046],
              [-2.732556311505746, 55.167284644553902],
              [-2.729288581587365, 55.168349329956726],
              [-2.724659768624603, 55.168731889868717],
              [-2.722636185403448, 55.169424155169779],
              [-2.72088629573443, 55.169627718991819],
              [-2.719835625181092, 55.170475930294828],
              [-2.71362324422827, 55.172135355943482],
              [-2.71173418065873, 55.171850756538916],
              [-2.708406914347183, 55.17226642725965],
              [-2.707669008320922, 55.172000230691651],
              [-2.706866533062282, 55.172431728291428],
              [-2.705504382252731, 55.17228865259726],
              [-2.704571689671579, 55.172994067034864],
              [-2.703502368216604, 55.173164689288754],
              [-2.70279756602762, 55.17433245880693],
              [-2.700994757738815, 55.175385230232742],
              [-2.700574190738171, 55.17638241217734],
              [-2.699717392183385, 55.176948965979086],
              [-2.699813012409481, 55.177925208723224],
              [-2.697698331307075, 55.179925059757792],
              [-2.697634709485184, 55.180777307834454],
              [-2.696551042306653, 55.181219331011093],
              [-2.696290323526366, 55.18229735695688],
              [-2.693810656231316, 55.184464566358187],
              [-2.692717588074434, 55.186273395158885],
              [-2.689955683728343, 55.188311828271722],
              [-2.689785063159612, 55.188981359583522],
              [-2.677372653929554, 55.177717682599067],
              [-2.674549919604313, 55.175888486834261],
              [-2.674264990074254, 55.173706438273221],
              [-2.676437506883415, 55.173032125168746],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000029",
        LAD13CDO: "16UE",
        LAD13NM: "Copeland",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-3.252950672160367, 54.217679069390755],
              [-3.254176378349278, 54.217335556500181],
              [-3.254313536468654, 54.218065751949965],
              [-3.253271792758274, 54.218711146861502],
              [-3.253303536816223, 54.219100895835304],
              [-3.253439460199219, 54.219638761686518],
              [-3.252508977449695, 54.219097474931658],
              [-3.251994974075222, 54.218938337447334],
              [-3.252950672160367, 54.217679069390755],
            ],
          ],
          [
            [
              [-3.232464723545678, 54.260057358046602],
              [-3.233078270802495, 54.259833575015776],
              [-3.233525570858296, 54.260160652302773],
              [-3.232769272409558, 54.262237408748561],
              [-3.232292559821494, 54.261851309146522],
              [-3.232870270501623, 54.260734494718363],
              [-3.232464723545678, 54.260057358046602],
            ],
          ],
          [
            [
              [-3.537493713994774, 54.60400408908324],
              [-3.536964584057585, 54.602863066984447],
              [-3.535396253261296, 54.603601998894781],
              [-3.529696200271101, 54.603611265443007],
              [-3.526661055826966, 54.603219974104888],
              [-3.525234937565933, 54.60366847090993],
              [-3.522027950816764, 54.605433601475667],
              [-3.520068720473306, 54.605456427379877],
              [-3.518851106561191, 54.604897395103976],
              [-3.516039592704206, 54.604690875301735],
              [-3.512762966733252, 54.605008699442124],
              [-3.511123916518406, 54.604772125437201],
              [-3.51010328934579, 54.603466358832407],
              [-3.50821139140191, 54.602125607330677],
              [-3.503803829761212, 54.601955761428577],
              [-3.502314690146484, 54.600278265220794],
              [-3.501915866100666, 54.600251758341287],
              [-3.501161567815438, 54.599483675706892],
              [-3.499932239959548, 54.599183448141034],
              [-3.496462331982744, 54.599203037457677],
              [-3.494750752635976, 54.598752332960792],
              [-3.492298820033044, 54.599389266694892],
              [-3.489125889364444, 54.597451945072258],
              [-3.488442072881741, 54.596443843092999],
              [-3.488687730960545, 54.595073784110269],
              [-3.486992313866723, 54.592765902493738],
              [-3.487347857442717, 54.591989484712208],
              [-3.487049849334008, 54.587124478138058],
              [-3.485330538158101, 54.584020550716112],
              [-3.483593412208569, 54.583612248045199],
              [-3.481581564888154, 54.582327383073896],
              [-3.481126846921437, 54.581181617997999],
              [-3.476301489081732, 54.582142962587788],
              [-3.470231594366159, 54.58284062217303],
              [-3.467026957256474, 54.582926256459167],
              [-3.461997825502987, 54.583699856409829],
              [-3.460336935184151, 54.583512297093584],
              [-3.457070055451188, 54.582506409389126],
              [-3.45661481165244, 54.581456731375319],
              [-3.453550081305857, 54.582079588332732],
              [-3.452859635620471, 54.581548617539383],
              [-3.450633484461217, 54.581038761340551],
              [-3.448792399095407, 54.581131824087983],
              [-3.44621673306525, 54.581541920128238],
              [-3.444786011307556, 54.582213318615153],
              [-3.443605071922755, 54.582685787788392],
              [-3.44153143108875, 54.586580617309025],
              [-3.438920196305913, 54.588495519682532],
              [-3.439168496374245, 54.589524354354424],
              [-3.437188412369811, 54.592669313584267],
              [-3.435972313473283, 54.592989332086283],
              [-3.435355609253104, 54.59376150077177],
              [-3.435956933496014, 54.594267563598002],
              [-3.433742461373912, 54.59626212307564],
              [-3.434977547229701, 54.596830784352022],
              [-3.433996839390352, 54.597466154349348],
              [-3.43324468522742, 54.596720998565679],
              [-3.431232969880331, 54.59629542379971],
              [-3.430234333756951, 54.595670004285289],
              [-3.428671132985113, 54.594303468582844],
              [-3.428868500944378, 54.593353837129655],
              [-3.427134226082789, 54.592667871392173],
              [-3.424675268790401, 54.592457774227597],
              [-3.424917031897803, 54.592289554435986],
              [-3.422579053907033, 54.591109119178824],
              [-3.42221262552318, 54.591652686729823],
              [-3.418948643762622, 54.590276345471757],
              [-3.41746070656856, 54.590034939458178],
              [-3.415685322906658, 54.589180324621971],
              [-3.415764250182669, 54.589671026542639],
              [-3.413653453621003, 54.589184306467274],
              [-3.413422924205376, 54.588560559249515],
              [-3.408195598251575, 54.586374591008166],
              [-3.407467128998175, 54.586745269397419],
              [-3.404551518009585, 54.586562545767308],
              [-3.401862706925742, 54.587395425641638],
              [-3.399448631507536, 54.587081839058555],
              [-3.398425430465001, 54.587344423976603],
              [-3.396973942228225, 54.587121220048253],
              [-3.395494726772225, 54.586358161119179],
              [-3.393401159998909, 54.586001211978235],
              [-3.39179007798095, 54.584862145737262],
              [-3.38898451277951, 54.58035832131462],
              [-3.388504251921436, 54.578793692006847],
              [-3.386025902534262, 54.576523069023843],
              [-3.382394110974245, 54.577005015032121],
              [-3.382302057765382, 54.57652522793812],
              [-3.381004404093968, 54.576536451985788],
              [-3.378277091160254, 54.575948304052098],
              [-3.376142383579207, 54.573248451040989],
              [-3.375810047213709, 54.571373814000282],
              [-3.376175212961102, 54.56917308052666],
              [-3.376881725596926, 54.568289644616627],
              [-3.37606244836909, 54.567387169753253],
              [-3.375710471187267, 54.565616561776416],
              [-3.376926777863133, 54.564670703936784],
              [-3.372387392274228, 54.56145078396699],
              [-3.372599381643516, 54.559658936950385],
              [-3.370021268945003, 54.558317547435337],
              [-3.370878199981184, 54.555420104636248],
              [-3.367695672143984, 54.554258977128981],
              [-3.366707399349793, 54.552912113326109],
              [-3.364846008067896, 54.551808776369143],
              [-3.364367674442341, 54.550726666739386],
              [-3.364326092108666, 54.548045218455634],
              [-3.366149339687312, 54.544636287588176],
              [-3.36135225113307, 54.5437502715683],
              [-3.360081672281364, 54.537945952612098],
              [-3.355344151471459, 54.535471810569703],
              [-3.350474727499909, 54.533665843011121],
              [-3.340036618957553, 54.535226280679254],
              [-3.337330257234309, 54.534088745514268],
              [-3.333198169262728, 54.531357190426057],
              [-3.328430315569233, 54.530967472068284],
              [-3.327202162654113, 54.529694826948528],
              [-3.324732056592294, 54.530103888369226],
              [-3.316455413899835, 54.527807165765459],
              [-3.314654195481117, 54.527872599866342],
              [-3.307272693376808, 54.530200433498976],
              [-3.305780591467915, 54.530125780696807],
              [-3.3025751709899, 54.528367324025318],
              [-3.299830773563218, 54.52749270318288],
              [-3.298641314673021, 54.527456052084311],
              [-3.298946040397002, 54.524886815189134],
              [-3.294760554256223, 54.523774102905527],
              [-3.293360112540473, 54.522973915070764],
              [-3.29311707511296, 54.522470512050845],
              [-3.291073503395025, 54.521802102434549],
              [-3.285541156600259, 54.521274161003795],
              [-3.28458249187793, 54.520610279910748],
              [-3.284581922283071, 54.519558736080434],
              [-3.278300435854208, 54.517242699622933],
              [-3.273571974594683, 54.516012768010299],
              [-3.272228088231795, 54.515369923919494],
              [-3.268422204897123, 54.515036030082804],
              [-3.266421014265727, 54.514364066085761],
              [-3.26202265895421, 54.511493586953812],
              [-3.261328600983842, 54.51067487067975],
              [-3.257837230833248, 54.508746560626228],
              [-3.256758607022832, 54.508578027053822],
              [-3.254436194588756, 54.509100061063371],
              [-3.253292161326393, 54.508711979451768],
              [-3.248933440411132, 54.508456904116002],
              [-3.248676327785573, 54.507876266074341],
              [-3.24737056484178, 54.507753135394253],
              [-3.247333715958548, 54.50730413653045],
              [-3.244297544579694, 54.507414517083923],
              [-3.239913899943716, 54.506177032111999],
              [-3.239907445844204, 54.505352038838161],
              [-3.241034226853786, 54.504312297135719],
              [-3.237607438976696, 54.503142195736842],
              [-3.235272332804395, 54.501808058052319],
              [-3.235406365479354, 54.501592783949377],
              [-3.234191653074538, 54.501305906283463],
              [-3.23459961181193, 54.500956617277055],
              [-3.233433482749464, 54.500743832740788],
              [-3.23315342397904, 54.500366515693209],
              [-3.231122174343715, 54.500243419843962],
              [-3.230080093770515, 54.501382868024052],
              [-3.229743972056392, 54.50124608190508],
              [-3.22899523848822, 54.501922372018122],
              [-3.22870647497257, 54.501665566838156],
              [-3.227695263282059, 54.502035344146961],
              [-3.226237929102967, 54.501689735265927],
              [-3.224712762664882, 54.502328035804318],
              [-3.221312709076753, 54.49944417483109],
              [-3.217873362153494, 54.497351520864292],
              [-3.216874753039255, 54.496949948083447],
              [-3.213621331292282, 54.496583610556563],
              [-3.212153053389559, 54.493666601763366],
              [-3.212798249172368, 54.491575014342573],
              [-3.211234073207872, 54.488173626761153],
              [-3.213745767872264, 54.48572537859696],
              [-3.213333732824291, 54.485260364504271],
              [-3.213550304338111, 54.484598501857178],
              [-3.216328054699146, 54.483117285130298],
              [-3.217472353909742, 54.483238778924381],
              [-3.218458548514641, 54.482974491172492],
              [-3.219317562510578, 54.482015839330295],
              [-3.217414790020194, 54.481768989977297],
              [-3.214970943418471, 54.48034748195181],
              [-3.214769344068583, 54.479638590624255],
              [-3.212709332605022, 54.478976224365447],
              [-3.212864750876788, 54.478334748537691],
              [-3.21197012770294, 54.477413509134443],
              [-3.208173751110794, 54.475118347902097],
              [-3.204945424700312, 54.474441461940195],
              [-3.203212439225489, 54.474758915254661],
              [-3.200786179875808, 54.474117070016348],
              [-3.199858420272655, 54.473474686335202],
              [-3.199652462602878, 54.472448552170377],
              [-3.199108716559609, 54.472060293261158],
              [-3.199465994873199, 54.470611544194028],
              [-3.198059884466875, 54.470335195124171],
              [-3.197507956415469, 54.469348437277453],
              [-3.195900766857904, 54.468736122911395],
              [-3.196432329537116, 54.468180820633478],
              [-3.196753586029339, 54.465548770608429],
              [-3.193115830128072, 54.463335154760969],
              [-3.188868490630327, 54.462590609019202],
              [-3.188255988880206, 54.462909402269773],
              [-3.184357964079891, 54.46244976385249],
              [-3.180608407092226, 54.459374063333414],
              [-3.178995840319714, 54.457103370464928],
              [-3.176582391409383, 54.455890208001911],
              [-3.17489633847926, 54.454595328995005],
              [-3.167136002331105, 54.454102535933089],
              [-3.16617251923252, 54.451246601988508],
              [-3.1665393313112, 54.450532143189641],
              [-3.166275395683053, 54.447758428644789],
              [-3.165205589969212, 54.447015593297074],
              [-3.163049993983997, 54.447029176564882],
              [-3.161856802948072, 54.447372300299207],
              [-3.161112996878424, 54.447028936546388],
              [-3.160597472517639, 54.443088343754766],
              [-3.158588458853832, 54.442169326643345],
              [-3.158610881427488, 54.441761974163605],
              [-3.160153891988567, 54.441034448608491],
              [-3.160133812076642, 54.440760520161206],
              [-3.159528187842423, 54.439190811843233],
              [-3.158074547866999, 54.438384191198885],
              [-3.157910728941928, 54.434763763874109],
              [-3.160341151873338, 54.433914479403015],
              [-3.157940918061327, 54.431297858623346],
              [-3.156964573858907, 54.430240387859065],
              [-3.154137639571692, 54.429702115947684],
              [-3.135974519253955, 54.42187537367991],
              [-3.128731835440977, 54.421524465071911],
              [-3.123992719234288, 54.427498666802244],
              [-3.120531314501911, 54.423683255021167],
              [-3.115287214357742, 54.422356701815836],
              [-3.114930272756599, 54.421982520022326],
              [-3.115072673373034, 54.421153450371371],
              [-3.114275482295399, 54.420571224396653],
              [-3.114140685340094, 54.418272549323895],
              [-3.115462133011969, 54.417788502490403],
              [-3.11552792013831, 54.41646582275591],
              [-3.116257661019963, 54.415532460167597],
              [-3.115257323800779, 54.415013233834834],
              [-3.116253929949138, 54.414658003429025],
              [-3.117851265208356, 54.414860726175263],
              [-3.121815462175031, 54.414100462434149],
              [-3.122815657584989, 54.41416126930914],
              [-3.122752441331521, 54.413708882833745],
              [-3.123839243250405, 54.413274558624181],
              [-3.128384766490338, 54.412202188972813],
              [-3.128906646254186, 54.411736247339491],
              [-3.130808021469139, 54.411342774270523],
              [-3.131454966107722, 54.411502984377108],
              [-3.132935249943653, 54.410703588205962],
              [-3.1369264815334, 54.409663955394215],
              [-3.140400735959819, 54.409656357214878],
              [-3.141082152151847, 54.409226603067665],
              [-3.14219860649184, 54.409149538594349],
              [-3.142809239619348, 54.408724039155331],
              [-3.143658979121283, 54.408807667202538],
              [-3.145449758262291, 54.408159761351094],
              [-3.147065001550959, 54.408124654396389],
              [-3.147974604419477, 54.407759201164794],
              [-3.149804691354309, 54.407914349703866],
              [-3.152777544778877, 54.407119358748517],
              [-3.156260504596979, 54.407538132469654],
              [-3.159322005427687, 54.407160055555785],
              [-3.160583157606905, 54.406590706441385],
              [-3.161157346637631, 54.405483301770026],
              [-3.164407193349423, 54.40322306886813],
              [-3.165261478535524, 54.401622216253948],
              [-3.166653642352874, 54.401335547163178],
              [-3.169081014715337, 54.400075365941724],
              [-3.170754640436133, 54.399722110933361],
              [-3.171254861960311, 54.39732923885154],
              [-3.172074501332089, 54.397281738450637],
              [-3.172082677320454, 54.398054597682965],
              [-3.172731860948779, 54.395595560885859],
              [-3.172447598102102, 54.39453957530192],
              [-3.171571090887515, 54.393778740768241],
              [-3.173298077324734, 54.391959042539398],
              [-3.173094566306406, 54.39049423284235],
              [-3.172571063872313, 54.390444494199201],
              [-3.172517155420504, 54.389040244990028],
              [-3.173257427695647, 54.388483905568734],
              [-3.176711557364106, 54.387351091347057],
              [-3.178343058611966, 54.385978036527185],
              [-3.180070383780793, 54.385694228208621],
              [-3.181525794138239, 54.384844136387386],
              [-3.179080768135385, 54.382222069515983],
              [-3.180061438167361, 54.381948247975181],
              [-3.180615042939584, 54.380368190972305],
              [-3.180962236337484, 54.375354154032777],
              [-3.181995353703076, 54.374179234747984],
              [-3.184922508624564, 54.372758336693799],
              [-3.187071309588515, 54.370936982496467],
              [-3.187491261595248, 54.369753663172311],
              [-3.189133828219243, 54.368267099482217],
              [-3.189185190868529, 54.367168294120368],
              [-3.188587395440326, 54.366615147049266],
              [-3.188911935511096, 54.365275476090545],
              [-3.189728737887982, 54.364194292145697],
              [-3.19157389075805, 54.363442688751718],
              [-3.193305332272467, 54.359833183492555],
              [-3.195971399354902, 54.356200908463798],
              [-3.194593768016224, 54.355232194598429],
              [-3.19459720925662, 54.354343272726979],
              [-3.200146717908158, 54.354320559850443],
              [-3.203323603610176, 54.352984797042396],
              [-3.207083097995633, 54.352949942605782],
              [-3.208943294294795, 54.351637980236731],
              [-3.2100550794642, 54.349888601883265],
              [-3.212106972578646, 54.348354465910248],
              [-3.212375696566055, 54.346612632038621],
              [-3.215960194338947, 54.344787093347279],
              [-3.218562912064293, 54.344988224973982],
              [-3.217516399653746, 54.343256957854997],
              [-3.217596399469322, 54.342372651279099],
              [-3.21951705121037, 54.341519185583827],
              [-3.220083157089851, 54.340481663623528],
              [-3.219815963015784, 54.339769836107195],
              [-3.218052032672246, 54.339314001700757],
              [-3.218501492189314, 54.338232725533565],
              [-3.217570093737602, 54.337267852645034],
              [-3.218405202204133, 54.336017308124582],
              [-3.219298027037455, 54.335939081517296],
              [-3.220479726152161, 54.334761414694405],
              [-3.223486900804263, 54.333635340217342],
              [-3.224565768144732, 54.332781337193076],
              [-3.230109950518163, 54.332162292723687],
              [-3.230191467731368, 54.331286947141564],
              [-3.231192948335672, 54.330783298787289],
              [-3.233602683664509, 54.328520712740847],
              [-3.236371284455942, 54.327106444741936],
              [-3.237943651647955, 54.325495877068604],
              [-3.241775163135159, 54.323648140747011],
              [-3.241871085867294, 54.323208546667416],
              [-3.240653289705577, 54.322747419664495],
              [-3.240349144318298, 54.32117318191009],
              [-3.238460768913787, 54.318808112240553],
              [-3.239505569830206, 54.317202037359756],
              [-3.236994462302909, 54.316002774694958],
              [-3.235383893004983, 54.316738309678207],
              [-3.233537081916833, 54.316413876612621],
              [-3.230920829986958, 54.314671799533777],
              [-3.231137129906908, 54.311207475054623],
              [-3.231742536630421, 54.310634160330231],
              [-3.233364898913817, 54.31024728031344],
              [-3.233908627499501, 54.309771653942938],
              [-3.232328399596872, 54.306269966984061],
              [-3.233045752647312, 54.30481738738446],
              [-3.232664133242236, 54.303824535568886],
              [-3.234481747462399, 54.30216565811935],
              [-3.235382474120965, 54.300067654461579],
              [-3.236994212813517, 54.298214011483324],
              [-3.236933188127299, 54.296588731726771],
              [-3.238164933038862, 54.294009153025897],
              [-3.237226304565628, 54.292887214174641],
              [-3.238125892226459, 54.291069618807008],
              [-3.237761151947389, 54.290280632556808],
              [-3.236347340590664, 54.28949881187954],
              [-3.235224601451066, 54.287815204974272],
              [-3.235078556144793, 54.285758475775197],
              [-3.233430723316819, 54.283615556257971],
              [-3.22831429114534, 54.281405542000826],
              [-3.227805276423753, 54.277667272404933],
              [-3.228036157400262, 54.27752920360637],
              [-3.225962405442647, 54.276483447999759],
              [-3.227505166594149, 54.276820074242153],
              [-3.228198360051852, 54.277457445662371],
              [-3.227576659589527, 54.276693515798939],
              [-3.224548471707904, 54.275570268748382],
              [-3.225895620845183, 54.273510034133011],
              [-3.226618493794844, 54.270961802796585],
              [-3.227547929947618, 54.26988997193974],
              [-3.227797497096141, 54.268264215267521],
              [-3.230034894845258, 54.266155311876965],
              [-3.229549015583413, 54.266390359507234],
              [-3.229947657345178, 54.265803876077307],
              [-3.229441896859247, 54.266093053613808],
              [-3.228464948998634, 54.265769562862069],
              [-3.228101522213569, 54.264029612163291],
              [-3.231071262922649, 54.261311032484251],
              [-3.230556852729847, 54.260328513484957],
              [-3.231634443100243, 54.260478390622097],
              [-3.23215723821893, 54.260042525998053],
              [-3.23266102560549, 54.260770788940064],
              [-3.232040253552443, 54.26188534612298],
              [-3.232686788272209, 54.262508788617119],
              [-3.231733033692402, 54.263424520646517],
              [-3.231042658392605, 54.26509074207452],
              [-3.231942353825143, 54.263338793826513],
              [-3.233107792696916, 54.262262707311692],
              [-3.233702206515509, 54.260112107253065],
              [-3.23321514835204, 54.259687469083829],
              [-3.230318343631987, 54.260052322918519],
              [-3.230311690124631, 54.259777360575868],
              [-3.2313436203663, 54.259632003812904],
              [-3.230960624155812, 54.259555923505197],
              [-3.230581367052593, 54.257852990463974],
              [-3.227015024748698, 54.255829319654417],
              [-3.223708284910572, 54.254733158283649],
              [-3.222906114769434, 54.251591034885074],
              [-3.224709126655258, 54.249761659194583],
              [-3.226289887003979, 54.249706037705877],
              [-3.226094772424907, 54.250475591857914],
              [-3.227484383929136, 54.249771173397626],
              [-3.230872147395446, 54.250248033433365],
              [-3.231528502489633, 54.249971690036162],
              [-3.231657900894627, 54.249112020028988],
              [-3.232191888173146, 54.248850405309177],
              [-3.233229078240655, 54.248530611033495],
              [-3.234731593449821, 54.248991586821184],
              [-3.23564841097785, 54.247880268637957],
              [-3.238515215661424, 54.247261231068414],
              [-3.238871467145783, 54.247851272389624],
              [-3.239658610872028, 54.24915491879063],
              [-3.242559742754984, 54.248604639351129],
              [-3.240407913637479, 54.247324453624351],
              [-3.241716856354022, 54.247266932726149],
              [-3.241854370631596, 54.246646728771729],
              [-3.243414811292258, 54.246672399023581],
              [-3.243711453668289, 54.246432056022805],
              [-3.24187904615587, 54.246535440068982],
              [-3.241841689111408, 54.246646519709799],
              [-3.2416648985508, 54.247172195835802],
              [-3.240273620690574, 54.247247640688627],
              [-3.241818780725244, 54.248667103321402],
              [-3.239717960953139, 54.24903566743096],
              [-3.238871419748178, 54.247786764609103],
              [-3.238871031146485, 54.24725307977576],
              [-3.238448783719935, 54.247144171721125],
              [-3.235368045140468, 54.247794162400062],
              [-3.234560758786906, 54.248821667404236],
              [-3.233460408046922, 54.24831073549656],
              [-3.231827900276768, 54.248687849584663],
              [-3.231095217289643, 54.24918338130599],
              [-3.230809951943794, 54.250014982427267],
              [-3.229306645905341, 54.249785846442258],
              [-3.22775330556494, 54.249110517608301],
              [-3.226447875598132, 54.247220166348129],
              [-3.225932228211751, 54.248307559767106],
              [-3.225019109781566, 54.24810293247414],
              [-3.223919594815539, 54.24720093070308],
              [-3.224155609945782, 54.245116928862217],
              [-3.225229960255629, 54.243618507436985],
              [-3.224892675920574, 54.242334863914749],
              [-3.225637317093784, 54.239811568391573],
              [-3.228286893070633, 54.236956083268424],
              [-3.228949493448956, 54.237779582565693],
              [-3.229479625245176, 54.236767755353661],
              [-3.229986325063513, 54.236728429967492],
              [-3.231716367134545, 54.237477430010635],
              [-3.231554195956742, 54.237853885133369],
              [-3.230624943801054, 54.237667441763456],
              [-3.230316120655195, 54.238478611894543],
              [-3.229573112502838, 54.238673145184364],
              [-3.230102215209059, 54.239231289296391],
              [-3.229050232486118, 54.239717486017625],
              [-3.229545058982561, 54.240258006221211],
              [-3.22946815729048, 54.24107759253755],
              [-3.230033384248528, 54.240940600286379],
              [-3.229369013879055, 54.239807708785328],
              [-3.23030784806213, 54.239231886328483],
              [-3.229777091178169, 54.238721396105809],
              [-3.2307592160313, 54.238621489319037],
              [-3.230853237222081, 54.237914076203666],
              [-3.232112819173356, 54.238166347820183],
              [-3.232345265106412, 54.237316407023521],
              [-3.231738917263501, 54.236637724857054],
              [-3.232764205636948, 54.236134699882314],
              [-3.231998794310796, 54.236244990910571],
              [-3.232497113637932, 54.235461538032411],
              [-3.231772541562528, 54.23333700434334],
              [-3.234388254706247, 54.232304482917947],
              [-3.236152445417778, 54.232505702544216],
              [-3.23651877767971, 54.233366585606461],
              [-3.238234024900647, 54.233314816693316],
              [-3.237485729355439, 54.233943572479369],
              [-3.236062365898748, 54.234316802031245],
              [-3.236739096357217, 54.236218900458915],
              [-3.236633486146661, 54.235304111424398],
              [-3.237732828502534, 54.235740424200394],
              [-3.236571711981872, 54.23508543919899],
              [-3.236337773831832, 54.234342737935258],
              [-3.237753492146459, 54.234072945221996],
              [-3.238316847031987, 54.233621353885184],
              [-3.240127991938922, 54.233953257352482],
              [-3.240111940986856, 54.234901652748654],
              [-3.240768893301601, 54.235320020304769],
              [-3.240139693529308, 54.234496908755219],
              [-3.24029207397167, 54.233848206875905],
              [-3.239226451739206, 54.233768392810973],
              [-3.238400073448374, 54.233019203747823],
              [-3.236646389821755, 54.23301654301445],
              [-3.236555630751455, 54.232392811078903],
              [-3.235467139210178, 54.231391932497253],
              [-3.236691530460931, 54.231960892844292],
              [-3.237827607339935, 54.231372199481719],
              [-3.238985481539818, 54.231409732386197],
              [-3.238305817247134, 54.2322283327942],
              [-3.240625231903897, 54.232986426273669],
              [-3.241752362394949, 54.232918191343863],
              [-3.241691524664692, 54.234264316949059],
              [-3.242108179451118, 54.232812060989758],
              [-3.243440093486632, 54.234044055697929],
              [-3.243053423736938, 54.233124082249731],
              [-3.24233656044917, 54.232702749612457],
              [-3.240827086024114, 54.232862111371233],
              [-3.238616756507378, 54.232160423423451],
              [-3.239883325038062, 54.231268373343362],
              [-3.240984659834591, 54.231620148961355],
              [-3.240523433006537, 54.231280659336932],
              [-3.237548965949123, 54.231186314484788],
              [-3.236796150583487, 54.231816910356279],
              [-3.236070860820193, 54.231572690165358],
              [-3.235720364134319, 54.230881515738503],
              [-3.235165289704359, 54.230839571098912],
              [-3.233777044698863, 54.229764453436907],
              [-3.234175565263957, 54.229033245570285],
              [-3.233267657824456, 54.229196236402615],
              [-3.2331228286995, 54.228763600090623],
              [-3.237651750379606, 54.225547105149332],
              [-3.23629365099654, 54.227375720214084],
              [-3.236368177216936, 54.227867496202286],
              [-3.237248555635219, 54.22832403560728],
              [-3.238741737014481, 54.228240384290046],
              [-3.239082746800179, 54.229586870953725],
              [-3.240854215257692, 54.231158612010766],
              [-3.241400760480412, 54.231069394005686],
              [-3.239559169911602, 54.229871383078482],
              [-3.241421924142009, 54.229423478398004],
              [-3.24241754540966, 54.230138545318866],
              [-3.241609515967332, 54.230919840273138],
              [-3.241763658159663, 54.231199575235223],
              [-3.243276129766714, 54.231448224381275],
              [-3.244142582662307, 54.232252692101632],
              [-3.243755582775839, 54.231678760982923],
              [-3.244129954340582, 54.23152839016447],
              [-3.241939783028252, 54.231138439334721],
              [-3.242742210751545, 54.230068686033988],
              [-3.241820837620394, 54.229271964770476],
              [-3.23930947907143, 54.229576449836109],
              [-3.239291155102238, 54.228196984764807],
              [-3.23768759149702, 54.22802651851557],
              [-3.237628703156231, 54.22775029394618],
              [-3.238723567499798, 54.226814179885849],
              [-3.239577915194307, 54.226606758707668],
              [-3.239715403984704, 54.226997219755674],
              [-3.240148097861309, 54.227000855892427],
              [-3.241154428704704, 54.226081808883727],
              [-3.243291551695288, 54.225276022089723],
              [-3.244860202597466, 54.225869209294999],
              [-3.243595087996641, 54.226749614427042],
              [-3.244935875030642, 54.227211231663965],
              [-3.244355220632594, 54.227571486816927],
              [-3.243972757056151, 54.22793302751019],
              [-3.244929579585958, 54.228072345088073],
              [-3.244648463683621, 54.228671152379626],
              [-3.245123853877683, 54.228054159174881],
              [-3.244256814818903, 54.227941778688539],
              [-3.24442420094609, 54.227594853156923],
              [-3.245897004675252, 54.227290277535076],
              [-3.245913721463245, 54.227641534691358],
              [-3.247566689440822, 54.228381218004991],
              [-3.247826035744355, 54.229147004609374],
              [-3.247727523762062, 54.22837326092926],
              [-3.246085630481538, 54.227593918157019],
              [-3.246163611581171, 54.227229098406013],
              [-3.24529166870462, 54.227310017711211],
              [-3.244000770846921, 54.226773289072518],
              [-3.245097731513683, 54.226218184867378],
              [-3.245084426783733, 54.225113699394591],
              [-3.246293182884211, 54.223383597550566],
              [-3.247369155092371, 54.224120262680962],
              [-3.246698677765353, 54.224521775179312],
              [-3.246825497398759, 54.225472290372366],
              [-3.246152057316452, 54.225419937125714],
              [-3.245926216867288, 54.225764714987086],
              [-3.246790811650187, 54.226102706968724],
              [-3.24646120778696, 54.226872792293605],
              [-3.247540791933799, 54.226963182661649],
              [-3.246648502199223, 54.226826813354776],
              [-3.246972618406368, 54.226078355450014],
              [-3.246137590293964, 54.22560164402168],
              [-3.246994458859322, 54.225530761191926],
              [-3.246934437016429, 54.224862676369867],
              [-3.247301193263056, 54.22522379214594],
              [-3.248108256376177, 54.224926020913344],
              [-3.24875195345432, 54.225263590663772],
              [-3.248245385745538, 54.224844607156925],
              [-3.247327472904727, 54.225077914717616],
              [-3.246926213541807, 54.224640758248206],
              [-3.247779728082588, 54.224103427923488],
              [-3.247195827642215, 54.223357179422415],
              [-3.248693503154, 54.222565103609462],
              [-3.250993547728953, 54.219853831977296],
              [-3.251638308202636, 54.220329790409664],
              [-3.250928992021738, 54.220762289892349],
              [-3.250778613370676, 54.221368743287066],
              [-3.252344907972147, 54.221074742504918],
              [-3.253201208741899, 54.221292335570602],
              [-3.252955050780046, 54.222077750378091],
              [-3.251798079830029, 54.22206191469887],
              [-3.250534046143617, 54.222512757292456],
              [-3.2508581230156, 54.222882394623596],
              [-3.251822951963058, 54.22278249336123],
              [-3.252556159407248, 54.22323955189858],
              [-3.250877306647551, 54.224784953080814],
              [-3.251674563570683, 54.225228768765824],
              [-3.251089533872281, 54.225563805678654],
              [-3.250383326458525, 54.227218634956543],
              [-3.249466798633769, 54.227651477393515],
              [-3.249720051198062, 54.227960733808217],
              [-3.2496870707198, 54.227579985382789],
              [-3.250224608900898, 54.227651705322103],
              [-3.250628185218327, 54.227148683630894],
              [-3.251240899749425, 54.225598185720209],
              [-3.251849593643913, 54.22523414062109],
              [-3.251732627370011, 54.224917181070104],
              [-3.251051806795039, 54.224823587148769],
              [-3.252197445312869, 54.223854464296259],
              [-3.252251842354259, 54.225803394102293],
              [-3.251482321929688, 54.228152760501771],
              [-3.252429632641421, 54.225849182390625],
              [-3.252390679911846, 54.223853354530853],
              [-3.254164769847717, 54.224288793072994],
              [-3.252822390182109, 54.223673600164069],
              [-3.252252232632137, 54.223738848543526],
              [-3.252797016735706, 54.223190309972445],
              [-3.252035581760123, 54.222712873859017],
              [-3.250780944715225, 54.222612657348655],
              [-3.251802375586735, 54.222203880371211],
              [-3.253213121317047, 54.222191910075779],
              [-3.253440402183965, 54.221137950022658],
              [-3.252371694310959, 54.220895602924067],
              [-3.250990554876502, 54.221175098407137],
              [-3.252069313612303, 54.220482601687188],
              [-3.251582721268431, 54.219861194478469],
              [-3.251965138559011, 54.219422201832977],
              [-3.253208340556876, 54.220008775297877],
              [-3.254345699138006, 54.219531360144693],
              [-3.253963227768159, 54.219107513003145],
              [-3.253659312868095, 54.218846428811233],
              [-3.254410586938549, 54.218636378382257],
              [-3.255010010241233, 54.217060829852294],
              [-3.255658457276192, 54.217405503715668],
              [-3.255434677674305, 54.218218553982126],
              [-3.255866164897526, 54.219094880942258],
              [-3.254795727901041, 54.222232960162529],
              [-3.256028931028467, 54.219101273251582],
              [-3.25602606632994, 54.218047010270524],
              [-3.255593153175414, 54.218134212737382],
              [-3.255760396692415, 54.217530273213917],
              [-3.256124463779114, 54.217549846447788],
              [-3.254758368544449, 54.216601467691753],
              [-3.255184879220458, 54.216583451707635],
              [-3.255072004801768, 54.216374399173318],
              [-3.256008324337206, 54.215189009150492],
              [-3.256696396757836, 54.214920282035635],
              [-3.257220919155928, 54.213357186648949],
              [-3.257674421729426, 54.213242798999715],
              [-3.258380872894713, 54.21393289114323],
              [-3.259697649974689, 54.214117763768925],
              [-3.259965700669629, 54.213251212189064],
              [-3.261175402408392, 54.2137976079674],
              [-3.26202165716396, 54.212985216427477],
              [-3.262140812565694, 54.213623914916496],
              [-3.26260039010377, 54.213708979025647],
              [-3.26281287825173, 54.213184547821484],
              [-3.263590830078681, 54.212928465342401],
              [-3.264174753857048, 54.213239471241891],
              [-3.265316552232957, 54.212917396623112],
              [-3.265642888283058, 54.21340920839792],
              [-3.265462014746872, 54.212860142875392],
              [-3.264194537267255, 54.213134103786928],
              [-3.26362189572508, 54.212918239169234],
              [-3.263449897152939, 54.212853400706869],
              [-3.262680380799069, 54.21311403257797],
              [-3.262604847668466, 54.213603772493983],
              [-3.261939748694301, 54.212863837466401],
              [-3.261138444937602, 54.213691936292015],
              [-3.259972355468439, 54.213117221147805],
              [-3.259508725125351, 54.213452828552512],
              [-3.259692780050347, 54.214008160920095],
              [-3.25861770125911, 54.213854018844977],
              [-3.257843034760297, 54.213089141065389],
              [-3.25676357635867, 54.213244215934999],
              [-3.256793542699031, 54.212211180601706],
              [-3.257900178925341, 54.211740342162805],
              [-3.25880802727387, 54.212054668939444],
              [-3.258825483292421, 54.212050463487479],
              [-3.25975989081293, 54.212384223507541],
              [-3.260657030213841, 54.212235523766864],
              [-3.260301843735651, 54.212707516067418],
              [-3.260889630253311, 54.212924267875984],
              [-3.26045549737773, 54.212667259928594],
              [-3.260800936552266, 54.212177393145588],
              [-3.259787185782201, 54.212273385451404],
              [-3.259347211795599, 54.21192475686653],
              [-3.259019729258406, 54.212003661434068],
              [-3.257679415895883, 54.211641082694719],
              [-3.25610219282421, 54.212572519320375],
              [-3.255812174430868, 54.211100610147675],
              [-3.254011454862733, 54.210324829212794],
              [-3.253892092146539, 54.210585825339983],
              [-3.255763295998006, 54.211460640263972],
              [-3.255731286651979, 54.212427185344978],
              [-3.256921600003249, 54.213953518938304],
              [-3.256318353099517, 54.214742667430606],
              [-3.255316555769348, 54.21518453536094],
              [-3.251466715713979, 54.215823192439302],
              [-3.250904364665106, 54.215280764979177],
              [-3.250791764018104, 54.214041589648382],
              [-3.249379098845495, 54.2137776211922],
              [-3.250048953949972, 54.210062220314555],
              [-3.249791102986069, 54.209546287726901],
              [-3.245081727079272, 54.208307076084878],
              [-3.243766679811424, 54.207252886712737],
              [-3.24288744071414, 54.206311031139869],
              [-3.243497657366021, 54.206259793049114],
              [-3.243943813213626, 54.205545133607806],
              [-3.243854599733147, 54.204719158000906],
              [-3.24326028698141, 54.204323166653708],
              [-3.242631195893263, 54.203903989809213],
              [-3.243123865393595, 54.203513320429067],
              [-3.242507724730716, 54.203927733578787],
              [-3.24327299626898, 54.204377413831899],
              [-3.243356799792433, 54.204735083213592],
              [-3.242153940937826, 54.205166334980568],
              [-3.24299464275141, 54.205949505827498],
              [-3.242588401230474, 54.2061046958367],
              [-3.239941572519707, 54.204194157456186],
              [-3.239260840169665, 54.203118108320766],
              [-3.240106830450816, 54.203262197314807],
              [-3.245260706998178, 54.200801134507202],
              [-3.245743700321775, 54.198358588949489],
              [-3.248272045956356, 54.195783407099633],
              [-3.252497663472309, 54.193286702107194],
              [-3.253981776148749, 54.191630042487063],
              [-3.25651390022485, 54.192543799637058],
              [-3.262179754535668, 54.190462241694391],
              [-3.264762355235877, 54.19011427325831],
              [-3.268695159354456, 54.190116947829495],
              [-3.273204722473661, 54.19113264403304],
              [-3.275926595007434, 54.192421487579416],
              [-3.277816886721858, 54.193976154801703],
              [-3.280632464044679, 54.196450323204125],
              [-3.282852762769979, 54.196671169431305],
              [-3.28572559914561, 54.195592526322933],
              [-3.287755114090108, 54.197023315317928],
              [-3.2864365673967, 54.195534601088156],
              [-3.289019144574678, 54.192276673542658],
              [-3.287075786948684, 54.193118968178659],
              [-3.288742722108134, 54.191914715899408],
              [-3.287626364471459, 54.192192703948358],
              [-3.290587233069052, 54.190706739347405],
              [-3.299367518773203, 54.188401254442944],
              [-3.304180094787299, 54.188216208131507],
              [-3.314307267851051, 54.188553716006076],
              [-3.318735414503877, 54.189244129996396],
              [-3.322393266965755, 54.190525272772199],
              [-3.325865564109683, 54.192610088597156],
              [-3.33003914086906, 54.196330989874582],
              [-3.352547459667846, 54.220155915821501],
              [-3.364896150542823, 54.231743437720205],
              [-3.379215284088731, 54.24427069916532],
              [-3.386677643210983, 54.249328057179042],
              [-3.388998339920627, 54.251702954704626],
              [-3.393054424039883, 54.253456456879348],
              [-3.394215526215909, 54.254430823685851],
              [-3.403850862748993, 54.264321846997134],
              [-3.404782550595962, 54.267879270791255],
              [-3.408949453494496, 54.271373748556321],
              [-3.418931959098533, 54.281894084253146],
              [-3.419927167514372, 54.282425248243321],
              [-3.419982101991622, 54.282682559781044],
              [-3.418931796086536, 54.282599111132122],
              [-3.418839199615857, 54.282591753908143],
              [-3.418766893861154, 54.282522515627633],
              [-3.417239768803261, 54.281060119420545],
              [-3.41816672407576, 54.282243737519117],
              [-3.418771795785172, 54.282629197042041],
              [-3.419765205025094, 54.282986215718225],
              [-3.421403529996909, 54.284393322827505],
              [-3.421504110328196, 54.285351167429624],
              [-3.42000280096069, 54.286409681280119],
              [-3.419892888372663, 54.287412249798564],
              [-3.421982136985387, 54.290664686723709],
              [-3.422730326142974, 54.292847157635613],
              [-3.422585063299171, 54.294115293658862],
              [-3.420986295922727, 54.295876935979038],
              [-3.418709205243945, 54.296238106341171],
              [-3.417562785582938, 54.296994903831163],
              [-3.416033318725171, 54.301142895549908],
              [-3.415741972725119, 54.303579387085477],
              [-3.416121123289792, 54.309384820275824],
              [-3.417359045863416, 54.313649492496815],
              [-3.424884428306755, 54.328698499507787],
              [-3.4250590946339, 54.332681733879376],
              [-3.422533824258143, 54.339765346318629],
              [-3.421447005790103, 54.34103378360016],
              [-3.419000127437969, 54.34274514584078],
              [-3.414467966345302, 54.345201761052259],
              [-3.412366176369044, 54.349812961560524],
              [-3.410891702523646, 54.350382075520969],
              [-3.410038050511652, 54.350345317139485],
              [-3.409184437319478, 54.349725231401841],
              [-3.408964428242537, 54.348916184786397],
              [-3.410886942390719, 54.345348851143264],
              [-3.4098100706454, 54.342698293865773],
              [-3.410427002632055, 54.340768548095582],
              [-3.41211390993621, 54.338957503646391],
              [-3.411849894523747, 54.338031233032957],
              [-3.408727774038814, 54.33669166677754],
              [-3.408825840050757, 54.336500874488003],
              [-3.403706004729705, 54.335386723178239],
              [-3.403030611705497, 54.334794904770149],
              [-3.402498516327682, 54.335112252490909],
              [-3.403346647660892, 54.335357648509756],
              [-3.400241478520714, 54.335090835443054],
              [-3.401039511157919, 54.334315791588857],
              [-3.401062967078133, 54.333196512188174],
              [-3.401844652021695, 54.33289172529657],
              [-3.40099667825491, 54.332196916645096],
              [-3.400109309782343, 54.332559549242823],
              [-3.400822937824755, 54.331745938638221],
              [-3.400303613166319, 54.331579398466758],
              [-3.399605436895212, 54.331806808552571],
              [-3.399068799582774, 54.331085005016241],
              [-3.399384406771138, 54.331910038557787],
              [-3.40054417532849, 54.331824674771966],
              [-3.399943305164574, 54.332698093604328],
              [-3.400808656685788, 54.332366277165868],
              [-3.40152350725293, 54.332855010837648],
              [-3.4009096060933, 54.333028419930677],
              [-3.400577474866513, 54.333844792475148],
              [-3.400157166056286, 54.333922474518523],
              [-3.400518809193668, 54.334109720990952],
              [-3.399116110994751, 54.334380358418585],
              [-3.398656115886251, 54.333606432630241],
              [-3.398886945339812, 54.334380319693999],
              [-3.397443176370727, 54.335163729906],
              [-3.397811674282298, 54.335145079802146],
              [-3.398139952346447, 54.335668084791571],
              [-3.398159248754343, 54.335658881234842],
              [-3.397983924019019, 54.33505410256263],
              [-3.399829355516928, 54.334459267654736],
              [-3.398866485098924, 54.335000729220035],
              [-3.399317933761469, 54.334888536136731],
              [-3.399175370787475, 54.335174210664334],
              [-3.398455353316314, 54.335517646761303],
              [-3.398180767844364, 54.335733111800153],
              [-3.397855572777331, 54.335803725098572],
              [-3.3947389984604, 54.337290127218679],
              [-3.392225224205089, 54.339369324529187],
              [-3.391881845696242, 54.338812436216273],
              [-3.392628718789831, 54.337973326451369],
              [-3.392051018275031, 54.337582724747847],
              [-3.393076492486377, 54.33756908972213],
              [-3.393385346205754, 54.337196116273752],
              [-3.393034166538011, 54.336999739718237],
              [-3.392358142583094, 54.3372493211048],
              [-3.392408517679634, 54.33650992666508],
              [-3.391505533128918, 54.336551803711174],
              [-3.391944411765114, 54.336157560464038],
              [-3.391361898585068, 54.336488746869009],
              [-3.391673759808956, 54.336841972891285],
              [-3.392256419122487, 54.336606056053462],
              [-3.392217217896105, 54.337312065880319],
              [-3.393173016643435, 54.33719407454609],
              [-3.393018911340513, 54.337458303523832],
              [-3.391894866521154, 54.337513521368777],
              [-3.39242655201285, 54.337999028842958],
              [-3.391731627343362, 54.33873687256515],
              [-3.391689352398605, 54.339625373543207],
              [-3.387854281219274, 54.34103123265978],
              [-3.387757558099555, 54.340580249831113],
              [-3.38672855976586, 54.342684483689915],
              [-3.386639461380963, 54.344333003871384],
              [-3.385225264017929, 54.343908838689181],
              [-3.385255097807351, 54.344475637294067],
              [-3.383875492670085, 54.344714371489395],
              [-3.384214618527094, 54.345192236390382],
              [-3.382986740013317, 54.345139806743489],
              [-3.38443259936391, 54.345223889567663],
              [-3.384671999451057, 54.344931729541024],
              [-3.384187065542597, 54.344738659946692],
              [-3.385497260951784, 54.344540266377798],
              [-3.385326012283633, 54.344022727960308],
              [-3.385957568327137, 54.344138609733179],
              [-3.386748772034644, 54.344929447819439],
              [-3.386473288805597, 54.345748721963069],
              [-3.384367450935216, 54.347633412978993],
              [-3.382912690126427, 54.349852147506574],
              [-3.380658558431293, 54.350721033183405],
              [-3.377253199323644, 54.349467484149493],
              [-3.374901464558863, 54.349489811855349],
              [-3.373537229648331, 54.350565029498476],
              [-3.374018368978678, 54.350367209156488],
              [-3.373295498406094, 54.353373818581574],
              [-3.373797811810876, 54.355469482137494],
              [-3.372379607903405, 54.355486519346215],
              [-3.372141604273238, 54.353978352832058],
              [-3.371067779367787, 54.353338034717282],
              [-3.368882686238896, 54.353658547420956],
              [-3.367472148492368, 54.355201592172733],
              [-3.366485929905167, 54.355576776294804],
              [-3.36654141585911, 54.356089358997188],
              [-3.367668069723977, 54.356605983643362],
              [-3.367635191429999, 54.35695958213168],
              [-3.365866855875503, 54.357324748201087],
              [-3.3625120453509, 54.357322230187187],
              [-3.360737209636571, 54.357771880620533],
              [-3.360556592378396, 54.358592717174453],
              [-3.362210254241782, 54.358619000140443],
              [-3.362242414288257, 54.35898624323741],
              [-3.358461655509034, 54.359977090891491],
              [-3.355212980026955, 54.361662002983003],
              [-3.355233689901973, 54.362103975707456],
              [-3.356642526815731, 54.363243092281422],
              [-3.354084275778388, 54.363047127550992],
              [-3.354301443058832, 54.36351922340576],
              [-3.353859719389524, 54.364642304433396],
              [-3.354101726738532, 54.365448500769581],
              [-3.353401955338168, 54.365558812825071],
              [-3.353948065321556, 54.365736939069471],
              [-3.354366103385614, 54.365250496396371],
              [-3.354411900118692, 54.363759338757063],
              [-3.354429254004271, 54.363194252969606],
              [-3.356860912684466, 54.363378152030116],
              [-3.355451973709759, 54.361722234127406],
              [-3.358606687117293, 54.360081515541836],
              [-3.362525381919905, 54.358980352079193],
              [-3.362296823437736, 54.358491293085137],
              [-3.360662214603667, 54.358436035133913],
              [-3.360908799929805, 54.357841848914383],
              [-3.362572360773411, 54.35747074871977],
              [-3.366070304321484, 54.357473442381313],
              [-3.367617981979907, 54.357182677955791],
              [-3.368047585497969, 54.356727512563197],
              [-3.366797733895743, 54.355744914361637],
              [-3.369992927404752, 54.353544383038816],
              [-3.370871955524316, 54.353556869142935],
              [-3.371674482767749, 54.353998044662127],
              [-3.371788555139013, 54.355435717466257],
              [-3.372254477104235, 54.355841168586032],
              [-3.373870914693787, 54.355815584447953],
              [-3.374487725281492, 54.354241062220979],
              [-3.374474766870641, 54.352193755264523],
              [-3.375522230778238, 54.350110095632061],
              [-3.376531977682816, 54.350122847389535],
              [-3.380644728142941, 54.351272153306134],
              [-3.3836694052492, 54.350347696666923],
              [-3.384383682826988, 54.349900890638125],
              [-3.385466738145595, 54.34774842386755],
              [-3.389435776973149, 54.344547103100908],
              [-3.389526643704425, 54.342452756055032],
              [-3.390113608099822, 54.342022660276079],
              [-3.391978481504335, 54.341262345536684],
              [-3.394581005052538, 54.340937483453317],
              [-3.396456055089419, 54.340345056463867],
              [-3.397024248360742, 54.340316906956595],
              [-3.396352685979753, 54.340562863566518],
              [-3.396754284864042, 54.340566303836866],
              [-3.400244164975823, 54.339513799975165],
              [-3.400511045951287, 54.340396019898193],
              [-3.400652445554965, 54.339985424403565],
              [-3.401167730964908, 54.339984833587323],
              [-3.401543159096115, 54.340575478191752],
              [-3.402469062639133, 54.340571908443017],
              [-3.401756755710076, 54.340478622573471],
              [-3.401468984974997, 54.340113457927458],
              [-3.401969414436093, 54.339811938266877],
              [-3.400720833305739, 54.33987048281157],
              [-3.400464255861546, 54.339381818040792],
              [-3.404039922076013, 54.33918206760319],
              [-3.404390995523316, 54.337929547275174],
              [-3.405841283999612, 54.338960656826345],
              [-3.407061750547421, 54.341232078238711],
              [-3.405759101033698, 54.342546929200587],
              [-3.405863412984912, 54.343259360972858],
              [-3.405405006543516, 54.343620626624151],
              [-3.405861230470848, 54.344547366727014],
              [-3.405318340688657, 54.348203716499832],
              [-3.406100718008946, 54.350615067354383],
              [-3.409562097526674, 54.352801904158326],
              [-3.411684269898534, 54.352945169369725],
              [-3.411912641488084, 54.353950950130105],
              [-3.410850525729844, 54.356768525319858],
              [-3.411500332707045, 54.357274139935818],
              [-3.411168696039575, 54.357565634424283],
              [-3.410507292669536, 54.357170705832985],
              [-3.409962661881226, 54.357488954115453],
              [-3.409768081663378, 54.359131534673821],
              [-3.408418821862067, 54.360042493397806],
              [-3.403442749792178, 54.361470236998613],
              [-3.402937464768632, 54.36190753513015],
              [-3.403187150483677, 54.362730626486091],
              [-3.402401728750238, 54.363664618686862],
              [-3.4017603905658, 54.36349770362483],
              [-3.402337011843201, 54.363888272349378],
              [-3.401122662661067, 54.36406955404258],
              [-3.400433299006999, 54.364571894060155],
              [-3.401005775609818, 54.3653831538834],
              [-3.399676833287426, 54.365092984233875],
              [-3.399784819677685, 54.36636712339638],
              [-3.398811226967098, 54.366116862802592],
              [-3.398413860624387, 54.366548496810687],
              [-3.39836293604232, 54.366603812787517],
              [-3.398343532702891, 54.366599104130657],
              [-3.397917507967348, 54.366495725067374],
              [-3.39811440689996, 54.366763981608919],
              [-3.398399319915734, 54.366619704330404],
              [-3.398826578389096, 54.366251504250691],
              [-3.399911942668866, 54.366484289874435],
              [-3.399745292770543, 54.365250378314819],
              [-3.401099876353316, 54.365524969998141],
              [-3.401809253770454, 54.3646610776184],
              [-3.40301449609923, 54.36416711373613],
              [-3.40332380092417, 54.363533460688352],
              [-3.404192538963957, 54.363192597727334],
              [-3.404858247482562, 54.363396965666986],
              [-3.404127566406283, 54.363092688479156],
              [-3.403352868380665, 54.363392910346832],
              [-3.404141863145211, 54.362699747812115],
              [-3.404224755343276, 54.361971656369519],
              [-3.404921498945866, 54.362093872190115],
              [-3.405142215993349, 54.362563170868277],
              [-3.405465388264982, 54.362201686034638],
              [-3.409862940990232, 54.362889199749887],
              [-3.410401936501647, 54.363257700000133],
              [-3.410852785026198, 54.362715847451234],
              [-3.411579627834026, 54.362951820431299],
              [-3.411037179365396, 54.362572580186885],
              [-3.411580690319301, 54.36208536670317],
              [-3.412083021956736, 54.362102858324313],
              [-3.411863206106757, 54.362559323396539],
              [-3.412464361480286, 54.362406683144002],
              [-3.412453875336901, 54.362908335232312],
              [-3.412838125507227, 54.362937991399697],
              [-3.41235269169922, 54.363144105810122],
              [-3.412473336179789, 54.363520188912794],
              [-3.413023497648608, 54.363137151716558],
              [-3.413838986405128, 54.363175236355175],
              [-3.414243355415903, 54.363924589716383],
              [-3.41544116510264, 54.363443170979551],
              [-3.41453402335623, 54.36329112533511],
              [-3.414414592372104, 54.362413500467881],
              [-3.41322215985983, 54.362289056220263],
              [-3.413462628987176, 54.36144855973334],
              [-3.41304647942645, 54.361699704249631],
              [-3.41286745351112, 54.361147241901428],
              [-3.413536625609572, 54.359570102785355],
              [-3.411684809624189, 54.358031466777227],
              [-3.412030996893757, 54.357715533055902],
              [-3.412885199195878, 54.358077636907822],
              [-3.414765025262119, 54.356937505269201],
              [-3.416204988042779, 54.357014987426965],
              [-3.417001971357767, 54.358128231320691],
              [-3.418014190332471, 54.358697863689947],
              [-3.421259815526232, 54.359589937012977],
              [-3.422645636459322, 54.359656296159713],
              [-3.42677640162191, 54.358862419521479],
              [-3.428341184706281, 54.356059422357056],
              [-3.429861363243639, 54.355743914611097],
              [-3.430840648549637, 54.35593813157756],
              [-3.430517536187385, 54.356426416544785],
              [-3.432136417191229, 54.357090302291475],
              [-3.431725051232639, 54.357474478112941],
              [-3.432461297600076, 54.357095433956452],
              [-3.430927348876942, 54.356307409595352],
              [-3.431290590756555, 54.356088287906879],
              [-3.431961062004111, 54.356252004100078],
              [-3.434344620366117, 54.357838840519435],
              [-3.436565242841569, 54.358544048375059],
              [-3.436579908965895, 54.359184719359803],
              [-3.436741642478603, 54.358571608627436],
              [-3.437957890508952, 54.359070336881643],
              [-3.438360112533249, 54.359834917406964],
              [-3.438103081388097, 54.36106754156004],
              [-3.435509330629476, 54.363447738125252],
              [-3.435360546223349, 54.363584262454609],
              [-3.434680512784241, 54.364208246106976],
              [-3.435647694534798, 54.363696111544307],
              [-3.435545132721869, 54.363512004319347],
              [-3.436131927850473, 54.363282294071041],
              [-3.436621030898306, 54.36503632450713],
              [-3.435503869716562, 54.365044226060377],
              [-3.436532305271138, 54.365669237658366],
              [-3.436362521110322, 54.366625784892278],
              [-3.436863248390922, 54.365757874156976],
              [-3.436128410438143, 54.365210263763906],
              [-3.436875885394114, 54.365282258084754],
              [-3.437958761816679, 54.366054911570153],
              [-3.43803141876925, 54.366548386233028],
              [-3.438359511989359, 54.366158890592153],
              [-3.439926089913854, 54.366852957576029],
              [-3.442429157319269, 54.369451159706863],
              [-3.440160866028489, 54.372373285771026],
              [-3.439272829438819, 54.372687673916083],
              [-3.436932731683228, 54.372684100401187],
              [-3.436085347263253, 54.374031600352872],
              [-3.435203431659014, 54.373861432400602],
              [-3.434138540599143, 54.372858447886522],
              [-3.432156121068622, 54.373135450176406],
              [-3.432001911116998, 54.373437478912706],
              [-3.432592614043636, 54.373866386169361],
              [-3.434254055603092, 54.37414056058401],
              [-3.432069330378959, 54.374799258161836],
              [-3.431945713585848, 54.3758918665945],
              [-3.430758967264343, 54.376829006899328],
              [-3.432046976776368, 54.376058740473837],
              [-3.432263062880246, 54.374880547541167],
              [-3.434548881328656, 54.37425120406666],
              [-3.434410663708992, 54.373907707965422],
              [-3.432642506305209, 54.373707605295252],
              [-3.432387403616887, 54.373234269718786],
              [-3.434119525650456, 54.373019558929855],
              [-3.434875716958704, 54.373867126655817],
              [-3.436052801202814, 54.374245901885409],
              [-3.43759011927024, 54.372729300779774],
              [-3.439894579149015, 54.37281417876796],
              [-3.442033303065029, 54.371376627580737],
              [-3.443059311891173, 54.371617825536248],
              [-3.442112455352118, 54.371219290479139],
              [-3.443175760588148, 54.36989073548628],
              [-3.443234445382158, 54.36884293049237],
              [-3.442302484584919, 54.367023214364146],
              [-3.439650126958464, 54.364596664077645],
              [-3.441739332951546, 54.363163299781071],
              [-3.4423830514737, 54.36146136565764],
              [-3.44142562295658, 54.360885888348548],
              [-3.43968858607586, 54.357468707337809],
              [-3.439005291799377, 54.357511911247947],
              [-3.439110295626937, 54.357258095553],
              [-3.438645497375076, 54.357344530264967],
              [-3.436383500631338, 54.356599407166868],
              [-3.432165732158106, 54.35439444850573],
              [-3.428485388108458, 54.35360758316029],
              [-3.42341753368838, 54.353228859919227],
              [-3.422917470273028, 54.353719211704295],
              [-3.420881598014251, 54.353894201555086],
              [-3.417508203379944, 54.35325528500119],
              [-3.419745040792146, 54.352884745371178],
              [-3.418945339867747, 54.352406103887795],
              [-3.419271265666354, 54.352308795309469],
              [-3.418304061231668, 54.351121168691336],
              [-3.418077108952204, 54.349311854332157],
              [-3.417374156790666, 54.348914755175912],
              [-3.418680384769977, 54.347887353393183],
              [-3.424059402161789, 54.345005341880658],
              [-3.424988372452445, 54.345220871381713],
              [-3.42790528569843, 54.343980190993811],
              [-3.425003855907792, 54.345090362192863],
              [-3.424509429015192, 54.344850828016845],
              [-3.426027816978417, 54.344005992346197],
              [-3.426810266315372, 54.343056593803027],
              [-3.430645330729782, 54.341992833231863],
              [-3.433252744963914, 54.342743844166762],
              [-3.435096287265242, 54.34386520706714],
              [-3.435285310901335, 54.343374009275493],
              [-3.438551775734044, 54.345987475038115],
              [-3.442089804449684, 54.350060860573699],
              [-3.46105713026701, 54.364695306921575],
              [-3.471842196809185, 54.37519890251022],
              [-3.473374998441037, 54.375483096897902],
              [-3.472445868228578, 54.377612017702937],
              [-3.473014574424587, 54.380336542070474],
              [-3.47482759445663, 54.384178357324636],
              [-3.47645177557041, 54.386354277001274],
              [-3.482628909482161, 54.391657899577297],
              [-3.486567155804861, 54.395917428736176],
              [-3.492610461901532, 54.401192536336005],
              [-3.499474247472646, 54.405644641120752],
              [-3.501451049533518, 54.406911654151926],
              [-3.503713488976699, 54.408233500411718],
              [-3.505213062900149, 54.409673572894469],
              [-3.504188290849209, 54.410302024235321],
              [-3.509616643338756, 54.415622602811794],
              [-3.510064327243982, 54.415475892823274],
              [-3.506527581886915, 54.412241237966292],
              [-3.506587765550122, 54.41182344230532],
              [-3.50849522198726, 54.412446765091651],
              [-3.513223450610165, 54.416519401974227],
              [-3.514146183391558, 54.416759499808329],
              [-3.514302142959765, 54.417561973263439],
              [-3.525662893049188, 54.425634039503407],
              [-3.541353194277916, 54.438396463850609],
              [-3.555912213393722, 54.448155971942313],
              [-3.563196347140395, 54.451631084858555],
              [-3.563898137898991, 54.453936425211772],
              [-3.566995373012836, 54.456758085503999],
              [-3.567730548632748, 54.458032041155235],
              [-3.570908939151889, 54.460540664374271],
              [-3.57542016915384, 54.46291319036618],
              [-3.577851297070405, 54.464786033650967],
              [-3.58260834558532, 54.472423883469759],
              [-3.584218950702942, 54.473528081517308],
              [-3.586258379753185, 54.475780695861815],
              [-3.59026570020725, 54.478305797575068],
              [-3.591076982690123, 54.480159240739518],
              [-3.592986415872064, 54.482106962649645],
              [-3.603063200845188, 54.488541285238178],
              [-3.608005295956661, 54.490946447741401],
              [-3.60959366628907, 54.491055630611548],
              [-3.610703153957754, 54.490215744347424],
              [-3.616707296815045, 54.489938734916521],
              [-3.619573585204726, 54.49089623668759],
              [-3.620868426071902, 54.492170464832242],
              [-3.622289625094494, 54.495190265937417],
              [-3.626295804167889, 54.500069982823213],
              [-3.626989080650402, 54.501971517827151],
              [-3.630416584845001, 54.504273918056008],
              [-3.633534104999077, 54.504952671093491],
              [-3.635958123519045, 54.507174099172126],
              [-3.638861315676499, 54.511866099211097],
              [-3.639776946292126, 54.514323606408205],
              [-3.638894922000804, 54.517372637405074],
              [-3.63828748192696, 54.517983071395008],
              [-3.637181961426777, 54.518077142738711],
              [-3.635053381155815, 54.51893734578362],
              [-3.630411329382569, 54.519807165631399],
              [-3.629579699023835, 54.520223739367303],
              [-3.626261162065074, 54.520618059535082],
              [-3.622738814948987, 54.522770388508881],
              [-3.613606365854397, 54.525025477661394],
              [-3.611785135813624, 54.527236555524134],
              [-3.611954547524905, 54.527574942948085],
              [-3.609585548084036, 54.52887021799787],
              [-3.608576104142866, 54.530119503191976],
              [-3.607363074948432, 54.53083040780092],
              [-3.606281985639612, 54.532698100939669],
              [-3.603602629777791, 54.540121795723607],
              [-3.603539880630845, 54.542187168585968],
              [-3.602800055865512, 54.542637387665287],
              [-3.602727956198203, 54.54347961853199],
              [-3.599992198698279, 54.547955905079959],
              [-3.599088935131617, 54.550041376430102],
              [-3.599445230125488, 54.552231526796326],
              [-3.598889483861374, 54.552926454338007],
              [-3.59839584248201, 54.552798159713291],
              [-3.599118400677722, 54.552177426737245],
              [-3.598994073789533, 54.551371052311715],
              [-3.597907116107134, 54.55062054210331],
              [-3.597204447899758, 54.551116971467408],
              [-3.596928490834294, 54.550939957398754],
              [-3.597612615025035, 54.550404227535815],
              [-3.596612871804991, 54.549945561486659],
              [-3.595512867375984, 54.549690439219702],
              [-3.593646923851284, 54.550688437278012],
              [-3.592718350690904, 54.550706062117904],
              [-3.595452329455848, 54.54948631225524],
              [-3.59348632806139, 54.548195495097019],
              [-3.592818572734968, 54.54827889160682],
              [-3.593780875580273, 54.549682716874287],
              [-3.592515587840508, 54.548280185253944],
              [-3.591524734243867, 54.548721954301804],
              [-3.593108122546275, 54.549955826642076],
              [-3.591237815948308, 54.548818305666884],
              [-3.589779293476878, 54.549798299491378],
              [-3.591798769222423, 54.55119273285321],
              [-3.59088068383097, 54.550804848651921],
              [-3.589848810875103, 54.551903263463814],
              [-3.592217108138559, 54.552048272471879],
              [-3.592452310278076, 54.551140088549623],
              [-3.592897668331068, 54.551736416968467],
              [-3.592669059362746, 54.552218485752583],
              [-3.595929407077986, 54.552834325523619],
              [-3.595227190188733, 54.55298739682928],
              [-3.591591743241871, 54.552189525376548],
              [-3.590194193376747, 54.555255722721498],
              [-3.590278306614301, 54.559602086421783],
              [-3.589222547572545, 54.561086389523567],
              [-3.584467692504436, 54.564179498151965],
              [-3.583494516131565, 54.565488300592186],
              [-3.583841919181226, 54.568583687357624],
              [-3.582987525542747, 54.570405936508621],
              [-3.580875334275261, 54.571148995109233],
              [-3.580390649896071, 54.571895931957926],
              [-3.581274426223739, 54.574703891121501],
              [-3.580581570995264, 54.5752297499211],
              [-3.581294129408157, 54.575092810061996],
              [-3.581649141376924, 54.575391061818308],
              [-3.582487133690924, 54.578530365143067],
              [-3.582976209599807, 54.583841210467902],
              [-3.581752839877816, 54.58749191471469],
              [-3.582810372642172, 54.590857532757745],
              [-3.580773000772905, 54.59168139143835],
              [-3.580233831519389, 54.592506331275303],
              [-3.5785004837949, 54.593030478708791],
              [-3.577683030203235, 54.594826132000442],
              [-3.576660226321834, 54.595635788412331],
              [-3.576012270327694, 54.597975682878193],
              [-3.57374634052335, 54.600150575228831],
              [-3.572586230127936, 54.600696838760079],
              [-3.569535686221861, 54.599991347685638],
              [-3.560059531212163, 54.598763004822239],
              [-3.560042731080904, 54.598364161007936],
              [-3.558940696512261, 54.59832713197676],
              [-3.554226190522896, 54.596434715844495],
              [-3.551629044776152, 54.597255379120014],
              [-3.551132888646118, 54.599269622048169],
              [-3.545970258523027, 54.604661867598658],
              [-3.546332740181519, 54.607117202306256],
              [-3.544381206567861, 54.607345245183375],
              [-3.543657469202576, 54.60716393664574],
              [-3.543677098214604, 54.606782603050966],
              [-3.541810398212382, 54.606592488059114],
              [-3.540626305817078, 54.605741142041708],
              [-3.538716469205764, 54.605062592062836],
              [-3.537493713994774, 54.60400408908324],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000030",
        LAD13CDO: "16UF",
        LAD13NM: "Eden",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.398291962782089, 54.844157874064834],
            [-2.392719953913785, 54.834508395513431],
            [-2.391825214494507, 54.833429316872071],
            [-2.389597125000026, 54.831942041001511],
            [-2.386731283895235, 54.831627696236858],
            [-2.382319625189264, 54.83064957106307],
            [-2.381532647444498, 54.830064332950755],
            [-2.380919544078313, 54.830087826912873],
            [-2.372662889188783, 54.827372594138104],
            [-2.373992228884932, 54.826030417217311],
            [-2.371344990135862, 54.825054529621525],
            [-2.370870277334787, 54.824546448417252],
            [-2.370153924977552, 54.82331389392801],
            [-2.370320617863352, 54.822481237070662],
            [-2.369138122064366, 54.821836915616323],
            [-2.369824229418386, 54.82095954251654],
            [-2.359919190780179, 54.816685707212031],
            [-2.355781761875909, 54.813895926051011],
            [-2.352602904712022, 54.810697031136399],
            [-2.351643485414332, 54.810584789301899],
            [-2.348562113869135, 54.80833987087982],
            [-2.348422065940067, 54.807072278645542],
            [-2.34552473768718, 54.806287958260853],
            [-2.343602660088941, 54.806201756542201],
            [-2.341671581306846, 54.806891084354277],
            [-2.33832113626976, 54.8067863482134],
            [-2.336548723250353, 54.806364420963462],
            [-2.333993110772653, 54.804914774874355],
            [-2.331615280684702, 54.804635529378011],
            [-2.329689737853016, 54.804874428052074],
            [-2.328721000378548, 54.805313801274671],
            [-2.328167992801484, 54.804839913395561],
            [-2.32737851988717, 54.805325520443283],
            [-2.327368953188887, 54.80298905755636],
            [-2.323381780137515, 54.796743353608832],
            [-2.323277662116675, 54.795002046333863],
            [-2.318440323401279, 54.793181598593542],
            [-2.316400660975953, 54.792887687211127],
            [-2.315808584265532, 54.7925486420339],
            [-2.31480406756277, 54.792568328503599],
            [-2.312082852581402, 54.791008115704116],
            [-2.313664495944745, 54.79021142420774],
            [-2.312984406351926, 54.787330308038491],
            [-2.313108811603385, 54.786325294278605],
            [-2.314003267438029, 54.785166416165083],
            [-2.312992778424041, 54.782579113639365],
            [-2.313778907295271, 54.782098099908268],
            [-2.313917374471643, 54.781106527796148],
            [-2.311670631583924, 54.778064094503875],
            [-2.31167510844027, 54.775413056289906],
            [-2.308922670725432, 54.774483704804069],
            [-2.308480186860115, 54.773535853564859],
            [-2.310010826051332, 54.772653964288921],
            [-2.309622655412419, 54.77250847580946],
            [-2.309897797051995, 54.771899384156129],
            [-2.313145923376681, 54.770813550898723],
            [-2.313238951565364, 54.770381057935658],
            [-2.314662519842033, 54.770351310056704],
            [-2.317562740678961, 54.768736054421964],
            [-2.317550011621128, 54.76810343498164],
            [-2.320009526437325, 54.766413786599202],
            [-2.32092546892804, 54.766281958763038],
            [-2.320898850397723, 54.765668247877208],
            [-2.322472112057344, 54.763963817077098],
            [-2.323217465230442, 54.75979296825129],
            [-2.32222459150279, 54.759415480349894],
            [-2.322426172791149, 54.757798261910786],
            [-2.32112115606396, 54.756652345075736],
            [-2.320815934004767, 54.754996929448048],
            [-2.322584586452559, 54.753296469551522],
            [-2.322807822223731, 54.752062917740766],
            [-2.326920732817262, 54.747289906611819],
            [-2.326705200684742, 54.745411393799174],
            [-2.327588935093112, 54.743276491751637],
            [-2.326392996621052, 54.740975557863749],
            [-2.327453448380631, 54.740154917297339],
            [-2.327876362623083, 54.739056510971295],
            [-2.326885434093475, 54.737899019030856],
            [-2.327723164104933, 54.737011576724186],
            [-2.32858040677388, 54.737003863277728],
            [-2.329448664906232, 54.735675084139878],
            [-2.330921356756531, 54.735154341798875],
            [-2.331912221110125, 54.73345046828215],
            [-2.331612047003398, 54.732444790710041],
            [-2.332299362115922, 54.73198908425789],
            [-2.331941018268473, 54.731275631436638],
            [-2.330628970720916, 54.730338321183829],
            [-2.33065831625776, 54.728984858123937],
            [-2.329603162946765, 54.727546281070801],
            [-2.325740324209742, 54.726622120571086],
            [-2.330206886008085, 54.723121426258032],
            [-2.334412781432395, 54.720484898526749],
            [-2.335892599238268, 54.719961378001301],
            [-2.336089801420829, 54.719591480885754],
            [-2.337567390479296, 54.71955322366005],
            [-2.33744731141926, 54.717246691393683],
            [-2.337963419047222, 54.716775252081035],
            [-2.339037340485377, 54.716555673655947],
            [-2.34138375893057, 54.716660518550334],
            [-2.342088787460961, 54.716140899902051],
            [-2.342201540574746, 54.715521402595868],
            [-2.341382126994869, 54.714804783895708],
            [-2.341958399686729, 54.713929657883391],
            [-2.341699142529889, 54.712449392034799],
            [-2.34224576449888, 54.711559969271335],
            [-2.341143553600773, 54.71061139330974],
            [-2.344080023087263, 54.70987067707712],
            [-2.34502702079, 54.709168822850209],
            [-2.344193931609475, 54.708477424062011],
            [-2.344013345165064, 54.7076790244828],
            [-2.342539889849951, 54.707051438982703],
            [-2.341453921090864, 54.705990486301516],
            [-2.342766251571275, 54.705186069727972],
            [-2.342645209445934, 54.704634632041689],
            [-2.343353586174989, 54.704354040065574],
            [-2.343372562683762, 54.703294460344893],
            [-2.344524324087003, 54.702051930675019],
            [-2.34437602501847, 54.701764779524304],
            [-2.346357603920735, 54.700855072086554],
            [-2.348237466787858, 54.701122876344982],
            [-2.349183751616332, 54.700911661682973],
            [-2.349556851320659, 54.701172097196974],
            [-2.351148011378938, 54.700708276736385],
            [-2.352250766148423, 54.699934019623271],
            [-2.353459178129733, 54.697932764931359],
            [-2.355476108788786, 54.697916078359142],
            [-2.355464897462189, 54.697166623628121],
            [-2.354300592925286, 54.696738675189422],
            [-2.353922173385941, 54.695866277839997],
            [-2.352251583014489, 54.694480012236539],
            [-2.35251212183603, 54.693752232903144],
            [-2.354162907319505, 54.692868520851015],
            [-2.354196398656472, 54.691727115863941],
            [-2.353897404418198, 54.691418848434175],
            [-2.351167123622396, 54.690711456714595],
            [-2.350477048540339, 54.6900223806269],
            [-2.35177656006845, 54.68745830956],
            [-2.351587867335411, 54.685728922124035],
            [-2.350198348946976, 54.685403137104345],
            [-2.349072218017507, 54.683955938303001],
            [-2.346790405893346, 54.683932838103495],
            [-2.343503109536167, 54.682210474024565],
            [-2.341658607279557, 54.678844787243733],
            [-2.339858240110671, 54.678125526747792],
            [-2.335610592547393, 54.675320047681701],
            [-2.330510249157693, 54.674559424534841],
            [-2.329256804397329, 54.674692247830734],
            [-2.327100761837257, 54.673659230421734],
            [-2.326407554218051, 54.672686045355015],
            [-2.327772889463728, 54.671628218005182],
            [-2.327563621104993, 54.670865814132519],
            [-2.325011955031625, 54.670160045930039],
            [-2.322494061584605, 54.669009292707969],
            [-2.318969941958131, 54.669423928454947],
            [-2.316358354678761, 54.668757677470154],
            [-2.313432404976535, 54.669336841402817],
            [-2.308349536146355, 54.66949997147011],
            [-2.306148031228823, 54.670147208123389],
            [-2.303311288347085, 54.669622336382091],
            [-2.302747334857158, 54.669055789253711],
            [-2.303828791779114, 54.667497483911824],
            [-2.303391140819815, 54.666816491567879],
            [-2.302210122597044, 54.666243398558755],
            [-2.298190796243115, 54.665510166119347],
            [-2.292869064363092, 54.663877694349488],
            [-2.292761108310071, 54.661155885270134],
            [-2.291338122665872, 54.658354569461132],
            [-2.292090598777607, 54.655858945692607],
            [-2.289966975420585, 54.654969874473998],
            [-2.289828641090774, 54.653392139935931],
            [-2.289243871266648, 54.653109558786802],
            [-2.289307824905248, 54.651640977493983],
            [-2.287972900240425, 54.650472293798053],
            [-2.292895134522606, 54.649842196271344],
            [-2.294085118555372, 54.649380988205344],
            [-2.296806327484839, 54.646811333493808],
            [-2.298963955538393, 54.645616181034555],
            [-2.30059437541623, 54.644179658742537],
            [-2.302185334144313, 54.643704792374081],
            [-2.302854003301892, 54.642773893346074],
            [-2.305782396405129, 54.642753948514823],
            [-2.30691915648662, 54.642267585015034],
            [-2.308365830245966, 54.640764920829191],
            [-2.308253554196646, 54.640056153609855],
            [-2.309076730060458, 54.639707166194121],
            [-2.311140035299706, 54.637618753678034],
            [-2.312295399770077, 54.637161048777948],
            [-2.314179678694268, 54.637006093138815],
            [-2.317058174972431, 54.635407034121862],
            [-2.319969243691709, 54.634433297368787],
            [-2.32380883498284, 54.63199216932685],
            [-2.324932228065347, 54.631642269616876],
            [-2.308752605638746, 54.611875114512927],
            [-2.30450915224456, 54.596189548824128],
            [-2.270931142428269, 54.58219070451382],
            [-2.262275968664018, 54.574220509485606],
            [-2.245956720519417, 54.565054099887192],
            [-2.209482608250418, 54.55170457434798],
            [-2.21013687204774, 54.543649424725103],
            [-2.19752482714369, 54.532695047885134],
            [-2.18228042640683, 54.535846441104084],
            [-2.172392239094217, 54.532435165945827],
            [-2.171166300045235, 54.528994040641287],
            [-2.174019218601731, 54.52440036741659],
            [-2.174486716159757, 54.521718008147793],
            [-2.16869867142214, 54.51964307290654],
            [-2.162387441514113, 54.503933646763798],
            [-2.166464217978663, 54.489898618343283],
            [-2.158991147644566, 54.472020188869074],
            [-2.159361112721051, 54.471134485923166],
            [-2.161690401668516, 54.464722786572061],
            [-2.170207021876254, 54.458189230371929],
            [-2.171927615170539, 54.459457549499973],
            [-2.170572510571237, 54.461131948097758],
            [-2.177502513965273, 54.461616237596793],
            [-2.179909022622183, 54.45845375270035],
            [-2.18218830235932, 54.457286519714486],
            [-2.183571463079767, 54.455811457686806],
            [-2.186156325090788, 54.452025775118081],
            [-2.189310041302598, 54.448968887300573],
            [-2.192414208514595, 54.448596409831957],
            [-2.235612289434373, 54.44822144450999],
            [-2.237340960055894, 54.449275828068728],
            [-2.238035669801551, 54.449136060328684],
            [-2.238726198429268, 54.449868035960499],
            [-2.239614933340549, 54.449677548089689],
            [-2.240285642969662, 54.449916167801838],
            [-2.242567354216351, 54.449790279926361],
            [-2.24411268951318, 54.450058572269967],
            [-2.246234264522708, 54.451772577895341],
            [-2.247881197230123, 54.451482522113601],
            [-2.249534162091826, 54.45193385890029],
            [-2.25349684165064, 54.450529921668341],
            [-2.256762468230782, 54.450155451801621],
            [-2.259858057634749, 54.448511395754423],
            [-2.261163618628843, 54.448653269258564],
            [-2.262048937249581, 54.448431169092039],
            [-2.262716545544161, 54.448649899784435],
            [-2.267162211844788, 54.44717436214021],
            [-2.267898116680283, 54.447223957296444],
            [-2.265373579294351, 54.442916665327232],
            [-2.270327263010743, 54.441895533091035],
            [-2.271342998599782, 54.440906479393803],
            [-2.274978510728598, 54.440646616252131],
            [-2.280414155143302, 54.438931969729452],
            [-2.284309856589612, 54.438429469753679],
            [-2.292860548648306, 54.439297846148513],
            [-2.29206999934966, 54.438487333906565],
            [-2.292539342189658, 54.437496725379319],
            [-2.291488400491553, 54.43605145664084],
            [-2.2923414511215, 54.432660382462423],
            [-2.291848813056026, 54.431452817716256],
            [-2.294525044545725, 54.431262083241599],
            [-2.299433161954995, 54.427034184936034],
            [-2.302680195733006, 54.426538089657662],
            [-2.303525083627384, 54.426154018423162],
            [-2.305604222548252, 54.422416448560341],
            [-2.307975366447699, 54.420677716745054],
            [-2.307754581801354, 54.417745801263465],
            [-2.308208905604487, 54.41647836433544],
            [-2.307739488806115, 54.41388229922191],
            [-2.306860878320299, 54.412807885173983],
            [-2.306042919340894, 54.410355592269148],
            [-2.305677099632023, 54.40757501885971],
            [-2.307410385942949, 54.404294824477311],
            [-2.304781404024323, 54.401889358990374],
            [-2.306338420652961, 54.398747124303739],
            [-2.305337132218663, 54.397489671567143],
            [-2.30550219449566, 54.3971099983643],
            [-2.30163874831311, 54.396193148605029],
            [-2.299948903086851, 54.395102732848507],
            [-2.298780523929048, 54.394760527143077],
            [-2.295520331820229, 54.392442695168683],
            [-2.291982137929578, 54.391476209134062],
            [-2.291717383195846, 54.39019259281428],
            [-2.292268816788658, 54.38869490556182],
            [-2.293790834224852, 54.38748513932542],
            [-2.295417529648017, 54.387140555802787],
            [-2.293222375307041, 54.386802603412654],
            [-2.292056645873219, 54.384191979676849],
            [-2.297435988199853, 54.376888487166426],
            [-2.317038072573619, 54.376300235590385],
            [-2.318136475649565, 54.374610459484693],
            [-2.318882626527671, 54.374354153614377],
            [-2.320397256968735, 54.372957134602153],
            [-2.320300832685753, 54.372435238065037],
            [-2.320843359402705, 54.372326849438217],
            [-2.322198214591636, 54.370986851000417],
            [-2.323486008254588, 54.370525060439974],
            [-2.324327998806598, 54.369163045162722],
            [-2.326087966834839, 54.368085235537805],
            [-2.32692547464897, 54.368112626247559],
            [-2.329954195594285, 54.367115793882661],
            [-2.332879224683204, 54.36454462259556],
            [-2.333949924373047, 54.364489537679233],
            [-2.334552276215252, 54.363806642414602],
            [-2.338250559380439, 54.36382149272557],
            [-2.342709273663421, 54.361640306634087],
            [-2.3446436532929, 54.359618076416048],
            [-2.352871358437751, 54.358319008905944],
            [-2.355726861424432, 54.357198010124563],
            [-2.35643284520526, 54.355920646515528],
            [-2.360744846209951, 54.35493721276319],
            [-2.363685135503787, 54.35475582948294],
            [-2.366147833353123, 54.355026981481906],
            [-2.367708025473684, 54.356060254212011],
            [-2.372450715588473, 54.35866007249254],
            [-2.373913995030135, 54.360680355373766],
            [-2.375253849247545, 54.361469761970639],
            [-2.380355641677623, 54.366170249472269],
            [-2.380894999830502, 54.366742827544989],
            [-2.379977696662062, 54.367575235314831],
            [-2.380338417187671, 54.369633955817307],
            [-2.382134445400292, 54.371267538092532],
            [-2.392794313758579, 54.369547312357263],
            [-2.396698910475598, 54.369512069056746],
            [-2.399158179838789, 54.369950619900784],
            [-2.402388375536292, 54.369888663024994],
            [-2.402981245058151, 54.370230892709017],
            [-2.406282994948643, 54.370243186126089],
            [-2.40721123890907, 54.369941682812204],
            [-2.407455028472482, 54.369535538464838],
            [-2.41130534963537, 54.368537480045653],
            [-2.412871994249719, 54.367595665669086],
            [-2.415693543362818, 54.367019785055213],
            [-2.41691026528206, 54.367101863594669],
            [-2.418840256448851, 54.36603918564176],
            [-2.420869736349319, 54.365835303814414],
            [-2.42327460390006, 54.366816379065106],
            [-2.42595634189798, 54.367323703705686],
            [-2.425994512273815, 54.368037150871345],
            [-2.427693967287656, 54.368504762765234],
            [-2.42792244115784, 54.369161814000805],
            [-2.429276479608231, 54.370151884442087],
            [-2.431192183863657, 54.369945538076124],
            [-2.434532431855581, 54.371126153006827],
            [-2.438123638708453, 54.371282125236952],
            [-2.438580271547002, 54.372676174774689],
            [-2.441507070988234, 54.37344558771936],
            [-2.441681121183761, 54.374172013179709],
            [-2.442603306596991, 54.374753699078113],
            [-2.442553536513228, 54.375421628601572],
            [-2.444061376126424, 54.376061368001487],
            [-2.448980250284091, 54.375812195509674],
            [-2.45058110994353, 54.374814942825815],
            [-2.45307591095106, 54.374484758836147],
            [-2.456056874438223, 54.372328288901613],
            [-2.457021519565152, 54.370205460913681],
            [-2.462725296100004, 54.368374600628563],
            [-2.468089178497416, 54.371343949335191],
            [-2.470041165240292, 54.372034665771046],
            [-2.472384157713055, 54.373525477784952],
            [-2.472677320103334, 54.374522806227446],
            [-2.474999942548066, 54.375695500747831],
            [-2.477896471247522, 54.376034564472079],
            [-2.483649358028374, 54.377342646491755],
            [-2.48647568720566, 54.376546704129787],
            [-2.491979930063025, 54.376216128298168],
            [-2.495842412102091, 54.375194652185492],
            [-2.496231069615397, 54.373978884356156],
            [-2.499189414116525, 54.37268420914701],
            [-2.504568299150959, 54.373603696307462],
            [-2.507358252773525, 54.372907173330582],
            [-2.507266200595117, 54.372183191685586],
            [-2.508538148541534, 54.370230310295469],
            [-2.513679627762643, 54.367924881773988],
            [-2.519011322557244, 54.369763318434934],
            [-2.521154366117339, 54.369921243906532],
            [-2.522479633205388, 54.371860345833547],
            [-2.521317604579881, 54.372478299422227],
            [-2.52229634461297, 54.374276002647591],
            [-2.523458360669872, 54.375347635143413],
            [-2.525153752054421, 54.375506532377472],
            [-2.526379501730334, 54.376858260244305],
            [-2.526723470884939, 54.378112272015485],
            [-2.533986927215692, 54.381074887945978],
            [-2.53146078334443, 54.382654305483015],
            [-2.531085046653384, 54.384557650712125],
            [-2.535087840432787, 54.386049819499235],
            [-2.536038239929291, 54.388437094834266],
            [-2.537154984151991, 54.389521377742881],
            [-2.537773059178472, 54.390858615746176],
            [-2.536533539102063, 54.392406333722732],
            [-2.536476562993768, 54.393114777344344],
            [-2.536982679847075, 54.393896209437969],
            [-2.538220500705832, 54.394216032422328],
            [-2.543925521717179, 54.393081439579291],
            [-2.54458302493079, 54.39144550207142],
            [-2.546497827213114, 54.39021279158132],
            [-2.54746228219493, 54.389088616095478],
            [-2.549457609037534, 54.388365066749053],
            [-2.550196121706183, 54.387511513252925],
            [-2.552429140457884, 54.386953093829966],
            [-2.554823675445275, 54.386729113311539],
            [-2.556824714879379, 54.387241156007455],
            [-2.556969802077675, 54.387834540814346],
            [-2.559561795453852, 54.388426492047884],
            [-2.565616027844857, 54.388934808612632],
            [-2.568935502390974, 54.389780181817748],
            [-2.570888459939332, 54.390687655689881],
            [-2.571532852230388, 54.390625290576494],
            [-2.573656546035917, 54.39184196985817],
            [-2.575250817898308, 54.391975481520191],
            [-2.577127608396242, 54.391499187631986],
            [-2.578444763385251, 54.391529730178775],
            [-2.579632574173713, 54.3910153558652],
            [-2.583467050657149, 54.387641054876568],
            [-2.585110911751206, 54.388346682223627],
            [-2.58668755380031, 54.390380924625283],
            [-2.590084210264262, 54.391736714936172],
            [-2.59349579815109, 54.395284317923128],
            [-2.597994824968523, 54.39709640383326],
            [-2.599504778485652, 54.39956039720996],
            [-2.601735443905748, 54.39894715551231],
            [-2.60396368600057, 54.40351860548175],
            [-2.603972081783683, 54.406919321022443],
            [-2.605055121900362, 54.406814140024679],
            [-2.607532252597418, 54.407532359359614],
            [-2.610229098197129, 54.40747831571197],
            [-2.611385570948335, 54.407959571127186],
            [-2.611701974786467, 54.409239543409328],
            [-2.614859410051832, 54.409378983142417],
            [-2.615478751756772, 54.408657749835228],
            [-2.61751763643634, 54.409105685930761],
            [-2.625225115233556, 54.407547179287164],
            [-2.629666594081648, 54.407636436606793],
            [-2.631441291694155, 54.406927063622618],
            [-2.633550411128668, 54.405230011990646],
            [-2.635242920200804, 54.405194156520885],
            [-2.63606478991084, 54.404247968426162],
            [-2.63847003003785, 54.403982731050689],
            [-2.640273319803355, 54.404337163263143],
            [-2.641028933104915, 54.405669553613336],
            [-2.643798886306107, 54.407046935079634],
            [-2.64404981472065, 54.40791915466162],
            [-2.64359500903874, 54.409275953989379],
            [-2.644878302022467, 54.410348471247552],
            [-2.646034340181807, 54.410770981302974],
            [-2.651797920870135, 54.411328666863049],
            [-2.651877003970303, 54.412040027711662],
            [-2.652575564547898, 54.412658169652367],
            [-2.655416015674267, 54.412895324910878],
            [-2.657894221978004, 54.414371928030285],
            [-2.660426660910021, 54.414226889352854],
            [-2.660758531103066, 54.415412285444461],
            [-2.662424663694188, 54.416487014843696],
            [-2.665749237594639, 54.419856911250129],
            [-2.66539560540848, 54.420573344059278],
            [-2.666226909574207, 54.421467481745729],
            [-2.669990065271516, 54.422767781253533],
            [-2.671092694390071, 54.42280839194342],
            [-2.674144753579927, 54.423868958727702],
            [-2.675721569566343, 54.424063256631548],
            [-2.676450593125721, 54.424999237718552],
            [-2.678211265628852, 54.425870109180245],
            [-2.682549129669627, 54.426367826164068],
            [-2.685091406793898, 54.426311190287159],
            [-2.686800369019752, 54.426656478133431],
            [-2.688831095726443, 54.427895932595099],
            [-2.68940487401981, 54.428927985829709],
            [-2.690952866787497, 54.429133933412515],
            [-2.691091693164438, 54.43161181304464],
            [-2.690638283139694, 54.433524189334932],
            [-2.687020955338218, 54.438455879077303],
            [-2.687929612773823, 54.44345432935247],
            [-2.689212507480034, 54.445746838606524],
            [-2.69019475964853, 54.445010571186337],
            [-2.694549210173299, 54.446906174377659],
            [-2.696763694938541, 54.446089066795444],
            [-2.697611917864585, 54.444370312368683],
            [-2.697620508322692, 54.443061726476877],
            [-2.699214831539947, 54.442514174212043],
            [-2.698223355087259, 54.439705117473963],
            [-2.698351276508812, 54.437980631532696],
            [-2.695646195080829, 54.437206266779079],
            [-2.69605803239357, 54.436581080418065],
            [-2.694823915490404, 54.436537858237422],
            [-2.695014260079757, 54.435669497332441],
            [-2.695775889953782, 54.435216650203067],
            [-2.69558366887589, 54.434881635732097],
            [-2.698026495319712, 54.434023642423007],
            [-2.701281176811507, 54.432055459985129],
            [-2.703377584929451, 54.432048660757658],
            [-2.705097736697317, 54.432753114197794],
            [-2.704351456712183, 54.433552837834632],
            [-2.707128879084861, 54.434951196104969],
            [-2.712797105575237, 54.436875292454239],
            [-2.715183890966977, 54.437238645901481],
            [-2.719283315371859, 54.439431449117293],
            [-2.72007461697028, 54.43892344976674],
            [-2.721922329612763, 54.439659257950908],
            [-2.724255387352968, 54.439847503017944],
            [-2.728049285631832, 54.441811768783786],
            [-2.728982717922337, 54.441756709049102],
            [-2.730082719669355, 54.442137414412862],
            [-2.732147078137293, 54.443848668140483],
            [-2.73314055458333, 54.443699745344453],
            [-2.733394171386379, 54.444171832312357],
            [-2.733640223133365, 54.443875557221006],
            [-2.734374130476299, 54.444129030688877],
            [-2.734429869778119, 54.445003148468359],
            [-2.735696587244802, 54.44547176263729],
            [-2.735946339186818, 54.446155966175624],
            [-2.737071702465858, 54.44682224726273],
            [-2.737623851228687, 54.446225720225485],
            [-2.738931447609448, 54.447333042392323],
            [-2.740245578811391, 54.44734566689565],
            [-2.741108709702692, 54.447746596801665],
            [-2.744746767707122, 54.446818309186263],
            [-2.746493996286439, 54.446733828778832],
            [-2.747612010260917, 54.447071125122548],
            [-2.748698015148063, 54.44894902006363],
            [-2.752111331406197, 54.4500655988342],
            [-2.771619898760884, 54.463220291551856],
            [-2.77698829367606, 54.459864207802433],
            [-2.784287668131956, 54.464204608149949],
            [-2.78669078393103, 54.46498075942494],
            [-2.789173025517665, 54.464973558737633],
            [-2.793668764929348, 54.467523445017122],
            [-2.796237974617462, 54.469920510663933],
            [-2.795417330846042, 54.475259831968245],
            [-2.806873500300059, 54.482051911860673],
            [-2.809721810339923, 54.478881941028462],
            [-2.815466033277366, 54.47469031531476],
            [-2.817720174655006, 54.474281441221407],
            [-2.820441358283572, 54.477085886319422],
            [-2.827610036355271, 54.479922803479205],
            [-2.830435149573356, 54.479045141270248],
            [-2.835187689159326, 54.47634767057405],
            [-2.838028487980152, 54.476106017744456],
            [-2.839756767668713, 54.476324991865319],
            [-2.841648314055627, 54.477504439921766],
            [-2.846520256397227, 54.478539891797887],
            [-2.852977911670873, 54.478483697601845],
            [-2.853524540358797, 54.481016939711473],
            [-2.854902611079109, 54.482930470532715],
            [-2.854564266529759, 54.483285163749834],
            [-2.857391442338364, 54.484144990704472],
            [-2.859710705564656, 54.485289667003826],
            [-2.862342850722476, 54.485965628572224],
            [-2.863823441025406, 54.486783679479728],
            [-2.86553884381884, 54.488118595307895],
            [-2.865779451327852, 54.489204329188944],
            [-2.865339079434634, 54.490134967068215],
            [-2.872833110924748, 54.482192960913885],
            [-2.87561407697539, 54.481092573595575],
            [-2.877529091776008, 54.481146986121594],
            [-2.87900228262753, 54.482117689527733],
            [-2.879018226391799, 54.482788922595091],
            [-2.879836847670601, 54.483462402986476],
            [-2.881473651703683, 54.484426492771405],
            [-2.883661328575942, 54.484948853850966],
            [-2.889028901123587, 54.484548199058153],
            [-2.890277055946066, 54.483953040368085],
            [-2.891886015591314, 54.48369672293525],
            [-2.893969072016029, 54.483947355363789],
            [-2.894837254368683, 54.484474774462079],
            [-2.898420695009783, 54.483105487995836],
            [-2.900095026477083, 54.483088535470003],
            [-2.899595185171466, 54.481272331812733],
            [-2.902923031993067, 54.481841573144209],
            [-2.906275138699247, 54.481340154731093],
            [-2.907450241667812, 54.48031936717453],
            [-2.909137632349613, 54.476931054999092],
            [-2.912755151759101, 54.471807082261662],
            [-2.916793227188489, 54.470764540138084],
            [-2.917628486003187, 54.46954491189824],
            [-2.91904668511784, 54.465148319371558],
            [-2.919815814207404, 54.464771289919348],
            [-2.921310775979643, 54.464907291892537],
            [-2.92482357125679, 54.466060491463558],
            [-2.931451774208711, 54.469865141814253],
            [-2.933271271180882, 54.469945469126351],
            [-2.936926160922163, 54.468505273953731],
            [-2.938310053289481, 54.469105674076189],
            [-2.938985053615022, 54.470012645137118],
            [-2.941217752457184, 54.470884121797944],
            [-2.943162804958942, 54.473534599279539],
            [-2.945219172033166, 54.475379808327915],
            [-2.946428709012723, 54.477102200261115],
            [-2.946049670025742, 54.477462864075441],
            [-2.946303364765875, 54.477889573378988],
            [-2.947475857751994, 54.479673358503561],
            [-2.94813077813134, 54.479696082626504],
            [-2.949410262692085, 54.481895122783612],
            [-2.955344471396717, 54.484524783987098],
            [-2.962524475857397, 54.482327927784588],
            [-2.966357599849246, 54.482224540075094],
            [-2.96740141821443, 54.486295542179775],
            [-2.972591277014355, 54.48844769750832],
            [-2.974850821258338, 54.490698795951225],
            [-2.975698854792152, 54.492274622781657],
            [-2.976881114804577, 54.492713541049042],
            [-2.977053029204841, 54.493254087749158],
            [-2.979538045606784, 54.494194720112183],
            [-2.980195070188894, 54.49584306325103],
            [-2.984136095983886, 54.496259507277763],
            [-2.987740837885873, 54.496173483176868],
            [-2.990014203901885, 54.495750453654637],
            [-2.992655585463758, 54.49726292942605],
            [-2.996009897282827, 54.497542702931035],
            [-3.004807252851698, 54.496946826976504],
            [-3.010831275558382, 54.494598530834494],
            [-3.013750405657762, 54.494703500676266],
            [-3.014138932561764, 54.494868305116391],
            [-3.015008359033699, 54.498052419960693],
            [-3.014831758946482, 54.499836096262598],
            [-3.013663503342097, 54.50092348296652],
            [-3.01241085447421, 54.505719745640242],
            [-3.011386147201964, 54.507375703732002],
            [-3.011751935003505, 54.508615592529885],
            [-3.013391497844808, 54.508775315183968],
            [-3.014893655686635, 54.50980884046291],
            [-3.015829885680346, 54.513677217753482],
            [-3.016980868264073, 54.514928464881919],
            [-3.015229846021452, 54.518318824498863],
            [-3.015894890778718, 54.519344983961233],
            [-3.015771244023766, 54.520276211541507],
            [-3.016631598778343, 54.521450814205807],
            [-3.015428955448953, 54.523478575220388],
            [-3.015311329079408, 54.525587090309898],
            [-3.016231141075682, 54.526414285359564],
            [-3.018648453199871, 54.527434676825457],
            [-3.019308449943992, 54.528124733677906],
            [-3.024382347270547, 54.529087537040049],
            [-3.025758011936459, 54.530088746633432],
            [-3.024836539013329, 54.532536617855023],
            [-3.024820022908548, 54.534714385154253],
            [-3.023259502936194, 54.538806941267573],
            [-3.025051143508309, 54.541036798257593],
            [-3.016577943988294, 54.54795214529954],
            [-3.019586793156389, 54.551810226127174],
            [-3.018408834694322, 54.55336865945695],
            [-3.020359472182979, 54.55891624612989],
            [-3.016774806510388, 54.561582397923516],
            [-3.020952321560531, 54.564756567026294],
            [-3.0287908826986, 54.566919901704971],
            [-3.02660116133333, 54.567243172205671],
            [-3.024019262756736, 54.568705713881734],
            [-3.020529921826719, 54.571496984896129],
            [-3.018279654476467, 54.57498772268756],
            [-3.019495132481988, 54.575960692048731],
            [-3.02390112534495, 54.574481928943534],
            [-3.026961037013082, 54.574543170688294],
            [-3.030304624574736, 54.575791833367219],
            [-3.037062314457779, 54.580979964392199],
            [-3.031146929634403, 54.582375014387296],
            [-3.026019851071845, 54.586523074325562],
            [-3.024606196781136, 54.58679748025299],
            [-3.019829863030448, 54.588680213101888],
            [-3.017374831952414, 54.588777270360076],
            [-3.016216053586997, 54.589162673571799],
            [-3.011168514705979, 54.592368459990894],
            [-3.009202875090748, 54.593927974132754],
            [-3.009216809248415, 54.594491356418068],
            [-3.008109382677716, 54.594834911221497],
            [-3.007687365777342, 54.595419898799683],
            [-3.008959170982494, 54.595776879065369],
            [-3.010577074930491, 54.597170777095826],
            [-3.010902782175592, 54.598821703980072],
            [-3.01150092309841, 54.599534784401186],
            [-3.017756289480387, 54.6002875953711],
            [-3.022886099041826, 54.602752680033731],
            [-3.032184839217271, 54.602798655336059],
            [-3.033447114596092, 54.60257848106184],
            [-3.034339008838154, 54.602770378390083],
            [-3.034025819154397, 54.603728396767615],
            [-3.038965842230017, 54.608836669944104],
            [-3.038216696587413, 54.610426646566864],
            [-3.039112313542184, 54.612336833701605],
            [-3.039845936370282, 54.612991992792018],
            [-3.04092180702147, 54.612677180062462],
            [-3.042183965098005, 54.612926948765669],
            [-3.044704086672873, 54.614288357568057],
            [-3.046524146458706, 54.614371494910714],
            [-3.053251912987776, 54.613395545298935],
            [-3.053856580796929, 54.613425332515405],
            [-3.055096683042633, 54.614554110172278],
            [-3.055711389968023, 54.614014010254941],
            [-3.059127021786316, 54.614061462237672],
            [-3.059081285927943, 54.613671816426823],
            [-3.061866645918794, 54.612588717031855],
            [-3.062719517041557, 54.612576738440481],
            [-3.065582062652378, 54.614157592299676],
            [-3.067702276282188, 54.614170364400287],
            [-3.070744753911579, 54.6135692043025],
            [-3.077791249101087, 54.610559745414463],
            [-3.078482666730324, 54.610709066300252],
            [-3.079323023294704, 54.611688376603688],
            [-3.08034393172746, 54.612116948327518],
            [-3.083837985378055, 54.612381393010587],
            [-3.085932734886597, 54.612894663969634],
            [-3.086946304011226, 54.612637518056843],
            [-3.086438700608122, 54.614159124995666],
            [-3.087419818033775, 54.615413025126706],
            [-3.088321781846202, 54.615671840633311],
            [-3.089254371882418, 54.615511567499389],
            [-3.092400557845601, 54.617228541818534],
            [-3.091917724131393, 54.618281711496479],
            [-3.09221558598404, 54.619457254052392],
            [-3.089606796177788, 54.62215087735499],
            [-3.090089918183731, 54.622726205683563],
            [-3.090056999234386, 54.62398561596936],
            [-3.091304285086087, 54.625390769237818],
            [-3.091988955642141, 54.627011287397693],
            [-3.091387443056888, 54.627339353669065],
            [-3.092082549249914, 54.628827664377717],
            [-3.09133014444241, 54.630694808417672],
            [-3.090771086529571, 54.631048551469007],
            [-3.091383744304048, 54.632811719043914],
            [-3.09069429303387, 54.634314306943779],
            [-3.09107077227456, 54.635529583981452],
            [-3.09065377425964, 54.636099536721495],
            [-3.091454297171955, 54.636717834500409],
            [-3.091971012406599, 54.637903085117507],
            [-3.092034563258876, 54.640736185565132],
            [-3.093275347311855, 54.641877151506662],
            [-3.094290946233956, 54.643862243182149],
            [-3.092237032710833, 54.649384560428608],
            [-3.092487981958576, 54.654116760275777],
            [-3.0929898891521, 54.654163458602106],
            [-3.092446706296563, 54.654889133827972],
            [-3.092782404256941, 54.655095507108364],
            [-3.092265464280373, 54.655528860325639],
            [-3.092041155988668, 54.6573948295392],
            [-3.091164559659694, 54.657655271028894],
            [-3.09159838506729, 54.658109708946789],
            [-3.090862421049206, 54.658643888861711],
            [-3.091037986719027, 54.659121325195976],
            [-3.089914302254444, 54.659881873811578],
            [-3.090058393542107, 54.660284102221063],
            [-3.08810376746159, 54.661046717158044],
            [-3.087939324968928, 54.661622476120385],
            [-3.086574462677928, 54.662703305604197],
            [-3.086492438415543, 54.66334752426728],
            [-3.083850208408761, 54.663157302550395],
            [-3.083255883675629, 54.664243778039236],
            [-3.081989764131322, 54.66455616587038],
            [-3.081484030220404, 54.664078070881985],
            [-3.079956205741657, 54.665045243299929],
            [-3.077695935614158, 54.666225620274361],
            [-3.076945556994886, 54.666344634202034],
            [-3.075276127456605, 54.665832817749781],
            [-3.074535514905783, 54.666556566868124],
            [-3.072892218320499, 54.666858723629183],
            [-3.072781959168849, 54.667440271485255],
            [-3.071752265489091, 54.667964351767161],
            [-3.071183683702413, 54.669395644865503],
            [-3.069640206624516, 54.67060996980036],
            [-3.069298552072492, 54.671416438617591],
            [-3.067717510168941, 54.671558901612002],
            [-3.066172078320963, 54.672646479448545],
            [-3.0635111262681, 54.673005092046878],
            [-3.05952424341347, 54.67544768712041],
            [-3.057802467279106, 54.677819163348545],
            [-3.054600263754963, 54.67974965976142],
            [-3.060118732527718, 54.682013884233932],
            [-3.070424698632293, 54.684926764876714],
            [-3.082686485327798, 54.685381299919229],
            [-3.08718669735011, 54.686084237472649],
            [-3.081915853751203, 54.695246185399121],
            [-3.07894479832691, 54.694669641243252],
            [-3.073623539527275, 54.695572483770974],
            [-3.063794132990993, 54.700074551862244],
            [-3.058153185176637, 54.699168613307769],
            [-3.051967800489241, 54.699279987790362],
            [-3.040724016677613, 54.697523042478402],
            [-3.038871994779805, 54.697123731561192],
            [-3.033695615749813, 54.69431280276671],
            [-3.022853982958551, 54.69346835712183],
            [-3.01765209419341, 54.693479806074926],
            [-3.004773189143498, 54.695115093305425],
            [-3.001067257830572, 54.69489323183258],
            [-2.997194796584564, 54.69509142485348],
            [-2.995764248364544, 54.694520828444773],
            [-2.995328559268813, 54.695610044895325],
            [-2.993784577826086, 54.695349511700776],
            [-2.993558405887875, 54.695744999564262],
            [-2.992270152877181, 54.696235465011007],
            [-2.994047947360535, 54.697300238374588],
            [-2.993545266879189, 54.698825869168161],
            [-2.994523114261795, 54.69978844886829],
            [-2.99286899915082, 54.701886110048903],
            [-2.992600337892497, 54.704737203917702],
            [-2.989820475918649, 54.707143304557562],
            [-2.990406620929653, 54.708713942781102],
            [-2.989114173090523, 54.710899360119974],
            [-2.990736235367087, 54.711729093869543],
            [-2.991225836481242, 54.712837684621228],
            [-2.989794822999567, 54.713799309103315],
            [-2.988820274576341, 54.715086117922176],
            [-2.986774535806883, 54.714839471882655],
            [-2.986578303745476, 54.715963548347545],
            [-2.984758052986372, 54.716054741728321],
            [-2.984425005166729, 54.71736505834297],
            [-2.98333266192666, 54.718221401323795],
            [-2.982994171543319, 54.71937358604216],
            [-2.985270630686779, 54.71926702895513],
            [-2.985969006933967, 54.719512986120421],
            [-2.98630750995337, 54.720538346336461],
            [-2.985431608481409, 54.720718920248736],
            [-2.984092953774582, 54.720511412237727],
            [-2.983743220166277, 54.720942931741092],
            [-2.986525139079029, 54.72240317754077],
            [-2.986687717373808, 54.723535116122584],
            [-2.988983982654512, 54.724168858736718],
            [-2.988704716182049, 54.726026954324503],
            [-2.989161367450486, 54.726734100908203],
            [-2.990095639006987, 54.72715964691114],
            [-2.989608709900891, 54.727759461761387],
            [-2.991776526612232, 54.72833578525627],
            [-2.992663297522971, 54.72891178327356],
            [-2.992560984793289, 54.730506915854839],
            [-2.993259766048014, 54.731203975837317],
            [-2.996713540932237, 54.731775060170811],
            [-2.996210022535445, 54.732962787753706],
            [-2.996609904891157, 54.733626336538912],
            [-2.999712014578275, 54.733844341448552],
            [-3.000879501927531, 54.733261336681558],
            [-3.002115495026213, 54.733190013716325],
            [-3.003505641422671, 54.733700659070308],
            [-3.005050405637022, 54.733554853691466],
            [-3.008177406295494, 54.734585760497794],
            [-3.011978797184145, 54.734751800664334],
            [-3.011734753621192, 54.736550341844016],
            [-3.015706705914372, 54.737565912731228],
            [-3.015688358662481, 54.738818856247789],
            [-3.017470499848853, 54.739461761667741],
            [-3.02082969575308, 54.739750764008022],
            [-3.021507229102957, 54.740133296152202],
            [-3.021998274519006, 54.7416219010411],
            [-3.023541833559744, 54.742281108058229],
            [-3.022828743751648, 54.743016871808003],
            [-3.022869398014287, 54.74419832105243],
            [-3.021848072907283, 54.745156860455801],
            [-3.021697882997862, 54.745978640143889],
            [-3.022154939468523, 54.746423239444752],
            [-3.023893097841106, 54.746413965410461],
            [-3.025326751752976, 54.746773013327264],
            [-3.026016598331466, 54.747144631972418],
            [-3.026135978953392, 54.747933579893548],
            [-3.025688350158038, 54.748233040096004],
            [-3.02166317786809, 54.748487206198376],
            [-3.021931439254693, 54.74945374452502],
            [-3.019885907432177, 54.749972442668366],
            [-3.018556933308536, 54.750876921847443],
            [-3.015655350039871, 54.751277816979744],
            [-3.01111471004847, 54.752932526225777],
            [-3.008382429459742, 54.752902256447385],
            [-3.005478777366609, 54.753542876583708],
            [-3.003238596120163, 54.754752207106073],
            [-3.000877696116086, 54.755293860976565],
            [-2.997727174352522, 54.759933735464742],
            [-2.997767027317417, 54.762058824754163],
            [-2.99683889259107, 54.761729446691206],
            [-2.996335820237886, 54.760736028119119],
            [-2.995376255600558, 54.760390720663018],
            [-2.994591663191188, 54.759385226231082],
            [-2.992956923074077, 54.760213740796033],
            [-2.991399190870296, 54.761270770841541],
            [-2.99010396072633, 54.763216244685196],
            [-2.990368036199341, 54.763338108308176],
            [-2.988115731900811, 54.765238349014083],
            [-2.987349231330609, 54.764844672770465],
            [-2.985721045097411, 54.76602172776019],
            [-2.987697596777208, 54.766959161086504],
            [-2.987038702273779, 54.768560607070775],
            [-2.988601158048265, 54.770039713340367],
            [-2.987961610797519, 54.770394516991885],
            [-2.987721383758423, 54.771891001993332],
            [-2.985440513404277, 54.774111355485275],
            [-2.985685076098509, 54.774706999306943],
            [-2.984432928742943, 54.776150584663363],
            [-2.97937364575276, 54.777612375754458],
            [-2.978483679003105, 54.777777728513129],
            [-2.976635516373938, 54.777407085170587],
            [-2.973900591355145, 54.778304402136051],
            [-2.969342157869105, 54.777136671630984],
            [-2.967568650437562, 54.77988824605324],
            [-2.964652674626307, 54.780249379772634],
            [-2.962070213110152, 54.778615401245176],
            [-2.952749474637497, 54.782846312455071],
            [-2.951833490256699, 54.783833074914284],
            [-2.949458295704188, 54.784064679073516],
            [-2.947249741792042, 54.783443877223156],
            [-2.944778782331938, 54.784036511658698],
            [-2.943643281665446, 54.785221733494609],
            [-2.942232693514908, 54.785881551492579],
            [-2.942461852081847, 54.786908768899174],
            [-2.942008834057117, 54.787623149753315],
            [-2.94087226834677, 54.78763197059412],
            [-2.931166635129366, 54.792150866233222],
            [-2.934830622029204, 54.792950376864454],
            [-2.928544215482374, 54.801230612800104],
            [-2.91971041098112, 54.805978242872754],
            [-2.921076584803405, 54.809200442202098],
            [-2.904409223698913, 54.813012210046068],
            [-2.904097761252034, 54.816274944125624],
            [-2.904746374647477, 54.818766643478419],
            [-2.904436435597927, 54.818825570697634],
            [-2.90674395420204, 54.820819596653514],
            [-2.907404333256799, 54.821951490610829],
            [-2.905423322587445, 54.821964489465671],
            [-2.904895076246189, 54.821379794353142],
            [-2.893978701588252, 54.822510358696057],
            [-2.893038746536944, 54.821669825372403],
            [-2.89030762857673, 54.821261222922452],
            [-2.888729888996622, 54.821563959342754],
            [-2.884927173582478, 54.823321703257974],
            [-2.882894626550827, 54.823187326824645],
            [-2.881466191127211, 54.823490679317949],
            [-2.878013406846309, 54.824755883245551],
            [-2.877423761170893, 54.825844850686543],
            [-2.875875763044762, 54.826886814547123],
            [-2.874298937298031, 54.826742713198648],
            [-2.873830887373687, 54.827917957933018],
            [-2.871986681789739, 54.828586357023582],
            [-2.872231763061042, 54.829628857257049],
            [-2.870779846715596, 54.831820368485481],
            [-2.872787428370346, 54.832078247076936],
            [-2.873206093566947, 54.832794176062954],
            [-2.872250139172395, 54.833512800660323],
            [-2.871474137978946, 54.833480630556132],
            [-2.8709205875127, 54.834813744184302],
            [-2.869064276916255, 54.834718310169364],
            [-2.866633136250779, 54.835696376198193],
            [-2.864047064097834, 54.836059904183031],
            [-2.863055715898136, 54.837246916223954],
            [-2.861007650766188, 54.836632389889552],
            [-2.858226855028769, 54.83479542390495],
            [-2.85638448527813, 54.834253955337196],
            [-2.855686534737892, 54.833422212537251],
            [-2.848672700839185, 54.833111988499908],
            [-2.845240979343488, 54.832940024901873],
            [-2.838268433884041, 54.834162946818175],
            [-2.838467653762094, 54.833033736706831],
            [-2.837763600979302, 54.831738219099563],
            [-2.833938216844069, 54.831190312045514],
            [-2.834471715242419, 54.831016796034014],
            [-2.833958719216055, 54.828862608848389],
            [-2.834270394080141, 54.828350919226622],
            [-2.832091227457457, 54.826768038962712],
            [-2.829405956844104, 54.82546896566047],
            [-2.82557169619967, 54.827731006752138],
            [-2.82196992273377, 54.825061234632606],
            [-2.817764335098762, 54.82753043953732],
            [-2.814760202095782, 54.825294978540711],
            [-2.811348511921532, 54.82344230724226],
            [-2.799373118466676, 54.818711191733186],
            [-2.798744736939105, 54.819044243340372],
            [-2.796896861386708, 54.819015053437283],
            [-2.792694485862875, 54.820422936373127],
            [-2.78304691829256, 54.822278420011777],
            [-2.77888484080734, 54.821860363268236],
            [-2.77319392511197, 54.82051639965681],
            [-2.767004223601333, 54.823298417523461],
            [-2.759886518845337, 54.823706257076779],
            [-2.757957457891973, 54.823507133880298],
            [-2.756422984532473, 54.822850795156128],
            [-2.754946915173929, 54.824988032241421],
            [-2.754867165833859, 54.82705366628656],
            [-2.753154254466417, 54.827351882110783],
            [-2.751009638046345, 54.829048375642948],
            [-2.748366682468762, 54.828977531128231],
            [-2.746132029410363, 54.828087231667233],
            [-2.743615091006477, 54.827839370466727],
            [-2.741012211428513, 54.824257928925732],
            [-2.737746670341382, 54.823571472825101],
            [-2.736660409993448, 54.822809711627734],
            [-2.736124081486173, 54.821390376604711],
            [-2.732218412077393, 54.820144189757009],
            [-2.729201792195032, 54.818648999751581],
            [-2.726395398818364, 54.819143023620171],
            [-2.724043926902505, 54.819059122339382],
            [-2.721210520047646, 54.819614296505229],
            [-2.720299868974771, 54.819252150950533],
            [-2.716719358971041, 54.819103503715667],
            [-2.714535019412291, 54.819934171435975],
            [-2.707254400240055, 54.818762726903188],
            [-2.705657721166531, 54.816355511112484],
            [-2.689787960661181, 54.819184095141459],
            [-2.690028948429274, 54.820630472893207],
            [-2.69346872198904, 54.823008499983288],
            [-2.696029095932024, 54.825907309729033],
            [-2.69709322727531, 54.827953749318844],
            [-2.700118441068959, 54.830513701241969],
            [-2.701219576878893, 54.833597846242235],
            [-2.699945799792156, 54.833670801352774],
            [-2.693678385810652, 54.836506117057858],
            [-2.693355176891191, 54.836361481975672],
            [-2.691250953158433, 54.837133752249095],
            [-2.691392981513472, 54.83733604081079],
            [-2.690870606604229, 54.837472017269945],
            [-2.690528471101504, 54.837130675532819],
            [-2.687017240180974, 54.837297083055525],
            [-2.686315077581098, 54.837940902360891],
            [-2.685344858717457, 54.837560858984055],
            [-2.685215091073974, 54.836059029923312],
            [-2.683234620286497, 54.835041227479039],
            [-2.680131615813108, 54.83531028097552],
            [-2.679157499391176, 54.836081395091227],
            [-2.676645803480377, 54.836988692738842],
            [-2.676316443022707, 54.838241464092668],
            [-2.675678046669371, 54.838333088400688],
            [-2.674127596130884, 54.839660044394556],
            [-2.672554506638902, 54.842440238381059],
            [-2.668966137460727, 54.845034727011054],
            [-2.659305401851153, 54.848503384575302],
            [-2.657764896242743, 54.849445447339853],
            [-2.655324945748529, 54.854761620288016],
            [-2.652857305737835, 54.853583297026411],
            [-2.648961802796078, 54.853197092750982],
            [-2.647503029971634, 54.853725204533745],
            [-2.646241416148574, 54.853435370555083],
            [-2.642829956091686, 54.853706904410473],
            [-2.641613568750313, 54.854105153571545],
            [-2.640143636737717, 54.854133582776285],
            [-2.63877224406249, 54.853793925428704],
            [-2.636651680754819, 54.854344258624877],
            [-2.635622663907932, 54.854281353016482],
            [-2.635071711163862, 54.854606853913509],
            [-2.633144290314456, 54.854466846215963],
            [-2.632607627953926, 54.854114675056465],
            [-2.631379601667406, 54.853974587393822],
            [-2.628574920110401, 54.85451126096104],
            [-2.626611157913935, 54.853833941974955],
            [-2.624927029021476, 54.853910921164157],
            [-2.624042328305648, 54.854315373799075],
            [-2.622546077648642, 54.853546618225522],
            [-2.62087605525804, 54.853730408849522],
            [-2.620231023046848, 54.85321068898913],
            [-2.617248278437514, 54.853109066856156],
            [-2.616708986399412, 54.852778406853588],
            [-2.614798900608092, 54.852641614889713],
            [-2.614295686310664, 54.852940718471189],
            [-2.613007965294895, 54.852657861233389],
            [-2.611070209440248, 54.853471028217974],
            [-2.609695664595094, 54.852904598120205],
            [-2.608437802136881, 54.853257789835048],
            [-2.606782654810416, 54.852879653059993],
            [-2.606122743168934, 54.853120194581074],
            [-2.604647174005349, 54.852875031447752],
            [-2.603763151176139, 54.853336846441238],
            [-2.602841627339705, 54.852956802350555],
            [-2.60163855709762, 54.85291424361958],
            [-2.600121504715274, 54.851970977003013],
            [-2.597627708425624, 54.851957219120017],
            [-2.597368371361975, 54.850955597768561],
            [-2.596799524836476, 54.850825395009153],
            [-2.595799651154262, 54.849770793584426],
            [-2.594066409542662, 54.849359617010144],
            [-2.594005143925426, 54.84911188829247],
            [-2.59325296736873, 54.849221607960885],
            [-2.589937224830416, 54.848687791673008],
            [-2.588506566581503, 54.847658588313692],
            [-2.582665472600947, 54.845859804214513],
            [-2.577946248918986, 54.836447393437773],
            [-2.572584848146843, 54.831434945176163],
            [-2.567843122769378, 54.823567924578057],
            [-2.558301082033418, 54.816717709718461],
            [-2.543259714268349, 54.812117468026024],
            [-2.536264061557295, 54.807658901116817],
            [-2.53374813250644, 54.806701243860722],
            [-2.529616963880164, 54.805942909587223],
            [-2.529193157629391, 54.806131679737369],
            [-2.523285229594156, 54.806074630931313],
            [-2.522669763937187, 54.80660389706491],
            [-2.521526258612044, 54.806844266408959],
            [-2.521132433166627, 54.807431882918408],
            [-2.519477420474624, 54.80782360601728],
            [-2.517895943114002, 54.807885185688427],
            [-2.517326885005525, 54.808279427802546],
            [-2.515575099750335, 54.80828598662363],
            [-2.514975847851781, 54.808632717170951],
            [-2.5109426593426, 54.808721663111186],
            [-2.507652218079888, 54.809728491247199],
            [-2.503624249459173, 54.810121816049879],
            [-2.502163128953412, 54.810613146624448],
            [-2.498295846128677, 54.810041372178958],
            [-2.497265121384, 54.810236116269081],
            [-2.496485642837325, 54.809984989398878],
            [-2.495578514322403, 54.810235828538111],
            [-2.487712439585858, 54.814932620765667],
            [-2.48613945041039, 54.81508002256929],
            [-2.481985954046824, 54.816884914968277],
            [-2.481339984872494, 54.818048539471569],
            [-2.480454739078048, 54.818199427389708],
            [-2.478888429096342, 54.819186948899798],
            [-2.477858340277065, 54.82038531984211],
            [-2.476177348575056, 54.82089787017815],
            [-2.475913672493536, 54.821767901627155],
            [-2.474085468970523, 54.822475108926078],
            [-2.473343826143791, 54.823736117380413],
            [-2.468874793511707, 54.825972240295322],
            [-2.467806384162381, 54.827262334180553],
            [-2.466341899547071, 54.828092933159084],
            [-2.465365501703938, 54.829409610718812],
            [-2.463062325875352, 54.830554316261832],
            [-2.463274750297543, 54.831703777999294],
            [-2.461185166135978, 54.832892564009185],
            [-2.461123497991616, 54.833764488887859],
            [-2.46058938970209, 54.834163720584861],
            [-2.457983658133008, 54.833236291124805],
            [-2.456704622283165, 54.831777210904768],
            [-2.4560306688526, 54.831511049227693],
            [-2.455474163124957, 54.831443943337682],
            [-2.453910781986858, 54.832098626816311],
            [-2.452228603114409, 54.832018637264007],
            [-2.450450432280372, 54.832683961415817],
            [-2.449534377940457, 54.833883461029771],
            [-2.447040937375133, 54.835061815182343],
            [-2.444653564467687, 54.835138884012636],
            [-2.442942458991358, 54.835838898923662],
            [-2.441889597925105, 54.835659413104253],
            [-2.440365222775285, 54.836785569514412],
            [-2.438402861156048, 54.837157526163629],
            [-2.438246928797678, 54.837853643307902],
            [-2.435964006647553, 54.838826115947576],
            [-2.433610703527212, 54.839634344237759],
            [-2.429954281810496, 54.84017216436412],
            [-2.428287718670585, 54.84115219154306],
            [-2.4274425346475, 54.841182130872696],
            [-2.422684125353507, 54.842826248043984],
            [-2.420629030407214, 54.844505770341122],
            [-2.420241278792464, 54.845403963019912],
            [-2.418749094140447, 54.846423692783034],
            [-2.418249084523125, 54.847315076825595],
            [-2.417739478842015, 54.847430960068749],
            [-2.417013377823899, 54.849204688500834],
            [-2.414643623975746, 54.850781843274028],
            [-2.41161732976212, 54.856423953128392],
            [-2.40161614497937, 54.851477169809492],
            [-2.398291962782089, 54.844157874064834],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000031",
        LAD13CDO: "16UG",
        LAD13NM: "South Lakeland",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-2.987003309059242, 54.148331832649589],
              [-2.983130108217495, 54.147247239132227],
              [-2.97897439675788, 54.14663847888206],
              [-2.975588792652965, 54.146612039738258],
              [-2.981022709175598, 54.147863023270595],
              [-2.975664536224706, 54.147554249986342],
              [-2.972341489625701, 54.145496860073621],
              [-2.972043439714781, 54.145125373358127],
              [-2.972586508050397, 54.144259052765719],
              [-2.978343521030327, 54.139946764563746],
              [-2.984846037648936, 54.137108395996769],
              [-3.000446868905811, 54.132312321980812],
              [-3.005332743597365, 54.131582236352017],
              [-3.010962364377814, 54.131360712324984],
              [-3.014117355600887, 54.131644250287657],
              [-3.016466140441206, 54.132564569021895],
              [-3.017095693348625, 54.133460735928878],
              [-3.015017724947843, 54.136107236679265],
              [-3.011474397760431, 54.139183959384077],
              [-3.00932692347041, 54.140509723315361],
              [-3.00800535332933, 54.140865932939732],
              [-3.00633425966212, 54.140760377777028],
              [-2.999906008474157, 54.139401958403802],
              [-3.001027789962683, 54.140153906330895],
              [-3.000760572874239, 54.140689109075488],
              [-3.001567964229651, 54.140273445121117],
              [-3.002713576885067, 54.140425688213121],
              [-3.002741529042956, 54.14075890532397],
              [-3.003278821011808, 54.140505463865878],
              [-3.003634293557498, 54.141152323300311],
              [-3.003878180832604, 54.140856386033633],
              [-3.005585929822609, 54.141274446979004],
              [-3.004911756094701, 54.1418804673648],
              [-3.005835401209595, 54.141462006552864],
              [-3.0064426232208, 54.141820040633796],
              [-3.006060077356956, 54.141952664129107],
              [-3.006650860807687, 54.142705402092233],
              [-3.005739508021214, 54.145021105143826],
              [-3.002709177287961, 54.147908122379008],
              [-3.00264973167174, 54.148235775628478],
              [-3.003946518584553, 54.149058141450674],
              [-3.002968303475915, 54.150013616266556],
              [-3.001592782657302, 54.149989117973981],
              [-2.997806341518491, 54.148460272870985],
              [-2.994448730846955, 54.147820261142613],
              [-2.997262003447812, 54.148489949855247],
              [-3.002038632579051, 54.15025504304073],
              [-3.001893726784672, 54.151482192543789],
              [-3.000644563013033, 54.152381481442816],
              [-2.999076363157171, 54.152688409688082],
              [-2.99703215030837, 54.151857804230552],
              [-2.996280445530402, 54.149885802272358],
              [-2.995446786384372, 54.149344440326097],
              [-2.987003309059242, 54.148331832649589],
            ],
          ],
          [
            [
              [-3.040765232346514, 54.173995062356177],
              [-3.040707005588853, 54.1724694276976],
              [-3.041173013667437, 54.173578994368576],
              [-3.041677310444272, 54.173285223628348],
              [-3.04225119723602, 54.175697990511011],
              [-3.041525554975962, 54.175537095297514],
              [-3.040765232346514, 54.173995062356177],
            ],
          ],
          [
            [
              [-3.014831758946482, 54.499836096262598],
              [-3.015008359033699, 54.498052419960693],
              [-3.014138932561764, 54.494868305116391],
              [-3.013750405657762, 54.494703500676266],
              [-3.010831275558382, 54.494598530834494],
              [-3.004807252851698, 54.496946826976504],
              [-2.996009897282827, 54.497542702931035],
              [-2.992655585463758, 54.49726292942605],
              [-2.990014203901885, 54.495750453654637],
              [-2.987740837885873, 54.496173483176868],
              [-2.984136095983886, 54.496259507277763],
              [-2.980195070188894, 54.49584306325103],
              [-2.979538045606784, 54.494194720112183],
              [-2.977053029204841, 54.493254087749158],
              [-2.976881114804577, 54.492713541049042],
              [-2.975698854792152, 54.492274622781657],
              [-2.974850821258338, 54.490698795951225],
              [-2.972591277014355, 54.48844769750832],
              [-2.96740141821443, 54.486295542179775],
              [-2.966357599849246, 54.482224540075094],
              [-2.962524475857397, 54.482327927784588],
              [-2.955344471396717, 54.484524783987098],
              [-2.949410262692085, 54.481895122783612],
              [-2.94813077813134, 54.479696082626504],
              [-2.947475857751994, 54.479673358503561],
              [-2.946303364765875, 54.477889573378988],
              [-2.946049670025742, 54.477462864075441],
              [-2.946428709012723, 54.477102200261115],
              [-2.945219172033166, 54.475379808327915],
              [-2.943162804958942, 54.473534599279539],
              [-2.941217752457184, 54.470884121797944],
              [-2.938985053615022, 54.470012645137118],
              [-2.938310053289481, 54.469105674076189],
              [-2.936926160922163, 54.468505273953731],
              [-2.933271271180882, 54.469945469126351],
              [-2.931451774208711, 54.469865141814253],
              [-2.92482357125679, 54.466060491463558],
              [-2.921310775979643, 54.464907291892537],
              [-2.919815814207404, 54.464771289919348],
              [-2.91904668511784, 54.465148319371558],
              [-2.917628486003187, 54.46954491189824],
              [-2.916793227188489, 54.470764540138084],
              [-2.912755151759101, 54.471807082261662],
              [-2.909137632349613, 54.476931054999092],
              [-2.907450241667812, 54.48031936717453],
              [-2.906275138699247, 54.481340154731093],
              [-2.902923031993067, 54.481841573144209],
              [-2.899595185171466, 54.481272331812733],
              [-2.900095026477083, 54.483088535470003],
              [-2.898420695009783, 54.483105487995836],
              [-2.894837254368683, 54.484474774462079],
              [-2.893969072016029, 54.483947355363789],
              [-2.891886015591314, 54.48369672293525],
              [-2.890277055946066, 54.483953040368085],
              [-2.889028901123587, 54.484548199058153],
              [-2.883661328575942, 54.484948853850966],
              [-2.881473651703683, 54.484426492771405],
              [-2.879836847670601, 54.483462402986476],
              [-2.879018226391799, 54.482788922595091],
              [-2.87900228262753, 54.482117689527733],
              [-2.877529091776008, 54.481146986121594],
              [-2.87561407697539, 54.481092573595575],
              [-2.872833110924748, 54.482192960913885],
              [-2.865339079434634, 54.490134967068215],
              [-2.865779451327852, 54.489204329188944],
              [-2.86553884381884, 54.488118595307895],
              [-2.863823441025406, 54.486783679479728],
              [-2.862342850722476, 54.485965628572224],
              [-2.859710705564656, 54.485289667003826],
              [-2.857391442338364, 54.484144990704472],
              [-2.854564266529759, 54.483285163749834],
              [-2.854902611079109, 54.482930470532715],
              [-2.853524540358797, 54.481016939711473],
              [-2.852977911670873, 54.478483697601845],
              [-2.846520256397227, 54.478539891797887],
              [-2.841648314055627, 54.477504439921766],
              [-2.839756767668713, 54.476324991865319],
              [-2.838028487980152, 54.476106017744456],
              [-2.835187689159326, 54.47634767057405],
              [-2.830435149573356, 54.479045141270248],
              [-2.827610036355271, 54.479922803479205],
              [-2.820441358283572, 54.477085886319422],
              [-2.817720174655006, 54.474281441221407],
              [-2.815466033277366, 54.47469031531476],
              [-2.809721810339923, 54.478881941028462],
              [-2.806873500300059, 54.482051911860673],
              [-2.795417330846042, 54.475259831968245],
              [-2.796237974617462, 54.469920510663933],
              [-2.793668764929348, 54.467523445017122],
              [-2.789173025517665, 54.464973558737633],
              [-2.78669078393103, 54.46498075942494],
              [-2.784287668131956, 54.464204608149949],
              [-2.77698829367606, 54.459864207802433],
              [-2.771619898760884, 54.463220291551856],
              [-2.752111331406197, 54.4500655988342],
              [-2.748698015148063, 54.44894902006363],
              [-2.747612010260917, 54.447071125122548],
              [-2.746493996286439, 54.446733828778832],
              [-2.744746767707122, 54.446818309186263],
              [-2.741108709702692, 54.447746596801665],
              [-2.740245578811391, 54.44734566689565],
              [-2.738931447609448, 54.447333042392323],
              [-2.737623851228687, 54.446225720225485],
              [-2.737071702465858, 54.44682224726273],
              [-2.735946339186818, 54.446155966175624],
              [-2.735696587244802, 54.44547176263729],
              [-2.734429869778119, 54.445003148468359],
              [-2.734374130476299, 54.444129030688877],
              [-2.733640223133365, 54.443875557221006],
              [-2.733394171386379, 54.444171832312357],
              [-2.73314055458333, 54.443699745344453],
              [-2.732147078137293, 54.443848668140483],
              [-2.730082719669355, 54.442137414412862],
              [-2.728982717922337, 54.441756709049102],
              [-2.728049285631832, 54.441811768783786],
              [-2.724255387352968, 54.439847503017944],
              [-2.721922329612763, 54.439659257950908],
              [-2.72007461697028, 54.43892344976674],
              [-2.719283315371859, 54.439431449117293],
              [-2.715183890966977, 54.437238645901481],
              [-2.712797105575237, 54.436875292454239],
              [-2.707128879084861, 54.434951196104969],
              [-2.704351456712183, 54.433552837834632],
              [-2.705097736697317, 54.432753114197794],
              [-2.703377584929451, 54.432048660757658],
              [-2.701281176811507, 54.432055459985129],
              [-2.698026495319712, 54.434023642423007],
              [-2.69558366887589, 54.434881635732097],
              [-2.695775889953782, 54.435216650203067],
              [-2.695014260079757, 54.435669497332441],
              [-2.694823915490404, 54.436537858237422],
              [-2.69605803239357, 54.436581080418065],
              [-2.695646195080829, 54.437206266779079],
              [-2.698351276508812, 54.437980631532696],
              [-2.698223355087259, 54.439705117473963],
              [-2.699214831539947, 54.442514174212043],
              [-2.697620508322692, 54.443061726476877],
              [-2.697611917864585, 54.444370312368683],
              [-2.696763694938541, 54.446089066795444],
              [-2.694549210173299, 54.446906174377659],
              [-2.69019475964853, 54.445010571186337],
              [-2.689212507480034, 54.445746838606524],
              [-2.687929612773823, 54.44345432935247],
              [-2.687020955338218, 54.438455879077303],
              [-2.690638283139694, 54.433524189334932],
              [-2.691091693164438, 54.43161181304464],
              [-2.690952866787497, 54.429133933412515],
              [-2.68940487401981, 54.428927985829709],
              [-2.688831095726443, 54.427895932595099],
              [-2.686800369019752, 54.426656478133431],
              [-2.685091406793898, 54.426311190287159],
              [-2.682549129669627, 54.426367826164068],
              [-2.678211265628852, 54.425870109180245],
              [-2.676450593125721, 54.424999237718552],
              [-2.675721569566343, 54.424063256631548],
              [-2.674144753579927, 54.423868958727702],
              [-2.671092694390071, 54.42280839194342],
              [-2.669990065271516, 54.422767781253533],
              [-2.666226909574207, 54.421467481745729],
              [-2.66539560540848, 54.420573344059278],
              [-2.665749237594639, 54.419856911250129],
              [-2.662424663694188, 54.416487014843696],
              [-2.660758531103066, 54.415412285444461],
              [-2.660426660910021, 54.414226889352854],
              [-2.657894221978004, 54.414371928030285],
              [-2.655416015674267, 54.412895324910878],
              [-2.652575564547898, 54.412658169652367],
              [-2.651877003970303, 54.412040027711662],
              [-2.651797920870135, 54.411328666863049],
              [-2.646034340181807, 54.410770981302974],
              [-2.644878302022467, 54.410348471247552],
              [-2.64359500903874, 54.409275953989379],
              [-2.64404981472065, 54.40791915466162],
              [-2.643798886306107, 54.407046935079634],
              [-2.641028933104915, 54.405669553613336],
              [-2.640273319803355, 54.404337163263143],
              [-2.63847003003785, 54.403982731050689],
              [-2.63606478991084, 54.404247968426162],
              [-2.635242920200804, 54.405194156520885],
              [-2.633550411128668, 54.405230011990646],
              [-2.631441291694155, 54.406927063622618],
              [-2.629666594081648, 54.407636436606793],
              [-2.625225115233556, 54.407547179287164],
              [-2.61751763643634, 54.409105685930761],
              [-2.615478751756772, 54.408657749835228],
              [-2.614859410051832, 54.409378983142417],
              [-2.611701974786467, 54.409239543409328],
              [-2.611385570948335, 54.407959571127186],
              [-2.610229098197129, 54.40747831571197],
              [-2.607532252597418, 54.407532359359614],
              [-2.605055121900362, 54.406814140024679],
              [-2.603972081783683, 54.406919321022443],
              [-2.60396368600057, 54.40351860548175],
              [-2.601735443905748, 54.39894715551231],
              [-2.599504778485652, 54.39956039720996],
              [-2.597994824968523, 54.39709640383326],
              [-2.59349579815109, 54.395284317923128],
              [-2.590084210264262, 54.391736714936172],
              [-2.58668755380031, 54.390380924625283],
              [-2.585110911751206, 54.388346682223627],
              [-2.583467050657149, 54.387641054876568],
              [-2.579632574173713, 54.3910153558652],
              [-2.578444763385251, 54.391529730178775],
              [-2.577127608396242, 54.391499187631986],
              [-2.575250817898308, 54.391975481520191],
              [-2.573656546035917, 54.39184196985817],
              [-2.571532852230388, 54.390625290576494],
              [-2.570888459939332, 54.390687655689881],
              [-2.568935502390974, 54.389780181817748],
              [-2.565616027844857, 54.388934808612632],
              [-2.559561795453852, 54.388426492047884],
              [-2.556969802077675, 54.387834540814346],
              [-2.556824714879379, 54.387241156007455],
              [-2.554823675445275, 54.386729113311539],
              [-2.552429140457884, 54.386953093829966],
              [-2.550196121706183, 54.387511513252925],
              [-2.549457609037534, 54.388365066749053],
              [-2.54746228219493, 54.389088616095478],
              [-2.546497827213114, 54.39021279158132],
              [-2.54458302493079, 54.39144550207142],
              [-2.543925521717179, 54.393081439579291],
              [-2.538220500705832, 54.394216032422328],
              [-2.536982679847075, 54.393896209437969],
              [-2.536476562993768, 54.393114777344344],
              [-2.536533539102063, 54.392406333722732],
              [-2.537773059178472, 54.390858615746176],
              [-2.537154984151991, 54.389521377742881],
              [-2.536038239929291, 54.388437094834266],
              [-2.535087840432787, 54.386049819499235],
              [-2.531085046653384, 54.384557650712125],
              [-2.53146078334443, 54.382654305483015],
              [-2.533986927215692, 54.381074887945978],
              [-2.526723470884939, 54.378112272015485],
              [-2.526379501730334, 54.376858260244305],
              [-2.525153752054421, 54.375506532377472],
              [-2.523458360669872, 54.375347635143413],
              [-2.52229634461297, 54.374276002647591],
              [-2.521317604579881, 54.372478299422227],
              [-2.522479633205388, 54.371860345833547],
              [-2.521154366117339, 54.369921243906532],
              [-2.519011322557244, 54.369763318434934],
              [-2.513679627762643, 54.367924881773988],
              [-2.508538148541534, 54.370230310295469],
              [-2.507266200595117, 54.372183191685586],
              [-2.507358252773525, 54.372907173330582],
              [-2.504568299150959, 54.373603696307462],
              [-2.499189414116525, 54.37268420914701],
              [-2.496231069615397, 54.373978884356156],
              [-2.495842412102091, 54.375194652185492],
              [-2.491979930063025, 54.376216128298168],
              [-2.48647568720566, 54.376546704129787],
              [-2.483649358028374, 54.377342646491755],
              [-2.477896471247522, 54.376034564472079],
              [-2.474999942548066, 54.375695500747831],
              [-2.472677320103334, 54.374522806227446],
              [-2.472384157713055, 54.373525477784952],
              [-2.470041165240292, 54.372034665771046],
              [-2.468089178497416, 54.371343949335191],
              [-2.462725296100004, 54.368374600628563],
              [-2.457021519565152, 54.370205460913681],
              [-2.456056874438223, 54.372328288901613],
              [-2.45307591095106, 54.374484758836147],
              [-2.45058110994353, 54.374814942825815],
              [-2.448980250284091, 54.375812195509674],
              [-2.444061376126424, 54.376061368001487],
              [-2.442553536513228, 54.375421628601572],
              [-2.442603306596991, 54.374753699078113],
              [-2.441681121183761, 54.374172013179709],
              [-2.441507070988234, 54.37344558771936],
              [-2.438580271547002, 54.372676174774689],
              [-2.438123638708453, 54.371282125236952],
              [-2.434532431855581, 54.371126153006827],
              [-2.431192183863657, 54.369945538076124],
              [-2.429276479608231, 54.370151884442087],
              [-2.42792244115784, 54.369161814000805],
              [-2.427693967287656, 54.368504762765234],
              [-2.425994512273815, 54.368037150871345],
              [-2.42595634189798, 54.367323703705686],
              [-2.42327460390006, 54.366816379065106],
              [-2.420869736349319, 54.365835303814414],
              [-2.418840256448851, 54.36603918564176],
              [-2.41691026528206, 54.367101863594669],
              [-2.415693543362818, 54.367019785055213],
              [-2.412871994249719, 54.367595665669086],
              [-2.41130534963537, 54.368537480045653],
              [-2.407455028472482, 54.369535538464838],
              [-2.40721123890907, 54.369941682812204],
              [-2.406282994948643, 54.370243186126089],
              [-2.402981245058151, 54.370230892709017],
              [-2.402388375536292, 54.369888663024994],
              [-2.399158179838789, 54.369950619900784],
              [-2.396698910475598, 54.369512069056746],
              [-2.392794313758579, 54.369547312357263],
              [-2.382134445400292, 54.371267538092532],
              [-2.380338417187671, 54.369633955817307],
              [-2.379977696662062, 54.367575235314831],
              [-2.380894999830502, 54.366742827544989],
              [-2.380355641677623, 54.366170249472269],
              [-2.375253849247545, 54.361469761970639],
              [-2.373913995030135, 54.360680355373766],
              [-2.372450715588473, 54.35866007249254],
              [-2.367708025473684, 54.356060254212011],
              [-2.367151638648325, 54.354922375144646],
              [-2.361559755416566, 54.352420160797358],
              [-2.359669965261507, 54.350256305705422],
              [-2.357337381699002, 54.349340255481458],
              [-2.35324022848087, 54.34840956938158],
              [-2.348131142083194, 54.346766289816053],
              [-2.347920429877052, 54.345133027824936],
              [-2.340227343601515, 54.342695183603574],
              [-2.335763457116411, 54.340424050347366],
              [-2.334504546525885, 54.3378715920825],
              [-2.335206223515833, 54.335835841703279],
              [-2.334745201718329, 54.334933909202562],
              [-2.328157671418883, 54.33214261887138],
              [-2.322466034571733, 54.331519883545873],
              [-2.320197995781851, 54.331739823117871],
              [-2.318050719388557, 54.328085918765346],
              [-2.314987988146496, 54.327113455348979],
              [-2.313794563293663, 54.325668728877254],
              [-2.312457248156985, 54.324904694826841],
              [-2.309837402810599, 54.32430392103204],
              [-2.314973652292748, 54.313575155694764],
              [-2.317054924080501, 54.311981657126161],
              [-2.318668003426013, 54.311279994915338],
              [-2.320146633043656, 54.312074142797712],
              [-2.321637659148635, 54.311298170923564],
              [-2.323028419831187, 54.311071568874077],
              [-2.320397354898289, 54.306759327297854],
              [-2.321244280207031, 54.305394608249976],
              [-2.324518603773927, 54.303172280432946],
              [-2.324341377594406, 54.30076417639885],
              [-2.320433817401043, 54.29998285177335],
              [-2.320386485455143, 54.299033025770747],
              [-2.322286356092281, 54.295188607681609],
              [-2.324168220611515, 54.293593715581878],
              [-2.319853500329258, 54.288956135415333],
              [-2.317188189078695, 54.287154044140955],
              [-2.317914345967475, 54.286700071340746],
              [-2.319077884370979, 54.283812982506142],
              [-2.321651437821622, 54.283123108301616],
              [-2.321119090100666, 54.282217712437287],
              [-2.321703486348929, 54.281721853783267],
              [-2.324127232208111, 54.280166851579892],
              [-2.325888097414041, 54.279681283106392],
              [-2.323785645240899, 54.279304092685571],
              [-2.321741460215895, 54.27733416447871],
              [-2.321180503509932, 54.275111309096246],
              [-2.31939868639199, 54.27445907506717],
              [-2.315510681607779, 54.270273155383478],
              [-2.316101600480052, 54.267835152306567],
              [-2.318282201895409, 54.265887257392997],
              [-2.318052530375713, 54.263577229942079],
              [-2.320816723621826, 54.259921056415237],
              [-2.319092833406692, 54.257144063484724],
              [-2.32208786013706, 54.255009695463009],
              [-2.322642389763714, 54.252902483877143],
              [-2.322534573438584, 54.248136797244221],
              [-2.323249905895826, 54.246982704844719],
              [-2.322653230919801, 54.245448370629767],
              [-2.325465097708523, 54.242789543580713],
              [-2.325643095809627, 54.241426582860377],
              [-2.328267438534101, 54.241874218252129],
              [-2.331306062202128, 54.240589697005234],
              [-2.335807286572345, 54.23805268819163],
              [-2.337653843095592, 54.237587373875328],
              [-2.342505663320781, 54.237455041908774],
              [-2.348048782690438, 54.237795050874631],
              [-2.350546114686642, 54.241355784202241],
              [-2.360403241465501, 54.246637314431979],
              [-2.362313431295483, 54.24974120217545],
              [-2.372321532271538, 54.248223283138692],
              [-2.373016953491154, 54.240087585643316],
              [-2.385082077303355, 54.239138302661026],
              [-2.396775948456446, 54.239369973548058],
              [-2.400926743956738, 54.234032115604769],
              [-2.401373312533178, 54.230411414006539],
              [-2.403625558632903, 54.225572273266721],
              [-2.405628327512922, 54.224904073651096],
              [-2.406446608909552, 54.225917788111744],
              [-2.407494041354167, 54.225898970405538],
              [-2.409211355947976, 54.226613936204679],
              [-2.410550951557737, 54.226357730820517],
              [-2.411966806679741, 54.226675543517366],
              [-2.414791068491632, 54.225950454730466],
              [-2.424047679598308, 54.224437998418736],
              [-2.426491067055276, 54.22369959507963],
              [-2.429414471043066, 54.223556194554618],
              [-2.431941002020149, 54.224184366730199],
              [-2.43347420084449, 54.223250460730718],
              [-2.435424150653213, 54.222865956565911],
              [-2.437648311958766, 54.224193422703216],
              [-2.441414799630323, 54.225728206722408],
              [-2.460859481326116, 54.226705107774272],
              [-2.459467255696333, 54.229514494879851],
              [-2.458776052044503, 54.232473982142565],
              [-2.459581955833722, 54.239557465073247],
              [-2.46487926703264, 54.236210009846566],
              [-2.472371514363778, 54.233710230374449],
              [-2.473215469901492, 54.233725791636452],
              [-2.475161354762536, 54.231241204983334],
              [-2.478677750943219, 54.22928420674603],
              [-2.47946481786698, 54.228237638730917],
              [-2.490699899101083, 54.224790659690839],
              [-2.493730609910584, 54.22298348773387],
              [-2.494241096957523, 54.222320818722594],
              [-2.498447368510344, 54.220479038847003],
              [-2.499600117896, 54.219544064267396],
              [-2.510867298331954, 54.217656176865361],
              [-2.512440096340778, 54.217853510561056],
              [-2.515078904351437, 54.217694861050646],
              [-2.518256919056169, 54.216877751731502],
              [-2.518847801997094, 54.216426734541436],
              [-2.52006705195368, 54.216435856140251],
              [-2.520542338179968, 54.215940392409856],
              [-2.520514992149694, 54.214863814819253],
              [-2.521381688086273, 54.214400807734286],
              [-2.522685768386967, 54.214574904482397],
              [-2.523541283519189, 54.214321337814049],
              [-2.523857012840632, 54.213703426115515],
              [-2.525994326574984, 54.212839404702571],
              [-2.52653759768568, 54.211790891605524],
              [-2.528122787717732, 54.211288742486545],
              [-2.528361139905158, 54.211702917434415],
              [-2.532408085867518, 54.209119177152651],
              [-2.535897651507152, 54.209468591545132],
              [-2.539768072483542, 54.207391381274739],
              [-2.540984076031278, 54.206233732151766],
              [-2.542117991267491, 54.206305026830982],
              [-2.543362249338956, 54.205672094301939],
              [-2.545531451003058, 54.202985818899386],
              [-2.550749094681243, 54.200282004814497],
              [-2.557792386389486, 54.198657904354846],
              [-2.563298879825354, 54.197906084192176],
              [-2.563938571809891, 54.197605604347586],
              [-2.571018381299638, 54.196810102642871],
              [-2.576076366553924, 54.195584384321684],
              [-2.578243236192559, 54.195444579003883],
              [-2.580970049832511, 54.195996768476476],
              [-2.583791700893038, 54.195225476191005],
              [-2.585310876446938, 54.196327159880127],
              [-2.587002766316756, 54.194469296152931],
              [-2.591015624735225, 54.194917018925288],
              [-2.590869853240188, 54.195872207536709],
              [-2.595550663019125, 54.196071141403401],
              [-2.598947139276811, 54.195215761797201],
              [-2.604465746569421, 54.194955411164493],
              [-2.605751934911306, 54.194343189728066],
              [-2.611772185538293, 54.195265433355999],
              [-2.624956533817012, 54.195558133787536],
              [-2.627370259186218, 54.197356569785661],
              [-2.629986998783201, 54.197775209519016],
              [-2.631224154506262, 54.198892171949467],
              [-2.639964072412974, 54.202034820124894],
              [-2.64151579674519, 54.200375556272633],
              [-2.644666987941171, 54.190893057112618],
              [-2.647741386626236, 54.18727165414704],
              [-2.653070004609853, 54.183211055129277],
              [-2.657728942114058, 54.181620063021533],
              [-2.659494907564656, 54.18022901469125],
              [-2.661507589143822, 54.179615806900195],
              [-2.662879140649143, 54.179728697266405],
              [-2.66477609025308, 54.178495929243823],
              [-2.666297169349272, 54.178476736406886],
              [-2.666979148415795, 54.177822260167417],
              [-2.670499576757391, 54.176572307892066],
              [-2.671898891488298, 54.175000668222573],
              [-2.672640669706512, 54.17473588652728],
              [-2.672825610461524, 54.173671622060027],
              [-2.672293628179369, 54.173363623364942],
              [-2.672985391721818, 54.172567952356054],
              [-2.67184631480609, 54.172135719917982],
              [-2.672581682690172, 54.171667854633995],
              [-2.67246866038038, 54.171027670990469],
              [-2.675137355795361, 54.170195763400777],
              [-2.675590962237905, 54.169549707292525],
              [-2.676444721988623, 54.169302248346973],
              [-2.676407578184078, 54.16824641480374],
              [-2.677141484561024, 54.167508901398897],
              [-2.676978862258469, 54.166367492447272],
              [-2.675827014557309, 54.165050090970077],
              [-2.675775157175075, 54.163748078892603],
              [-2.678179646911419, 54.161496644056598],
              [-2.678838076769906, 54.161864121042754],
              [-2.679876547846808, 54.161123973165431],
              [-2.687964339756252, 54.164636218562464],
              [-2.690003795069389, 54.164781829694043],
              [-2.691080044876111, 54.164569840072232],
              [-2.693126860437799, 54.163231501599618],
              [-2.694546882277013, 54.163339257517137],
              [-2.696345370463636, 54.164003837650093],
              [-2.70143931713758, 54.163364904789802],
              [-2.705592318087342, 54.164860441399725],
              [-2.70878837129726, 54.163850332505085],
              [-2.713580618338901, 54.164470029846726],
              [-2.717759445706284, 54.165390685948275],
              [-2.723448172622623, 54.16593094469912],
              [-2.727889917964972, 54.166800237946994],
              [-2.727437622281326, 54.167909348498625],
              [-2.736489893426897, 54.169016413288347],
              [-2.735827442959662, 54.17320330505882],
              [-2.736528921677808, 54.173971052745813],
              [-2.736550970004098, 54.175556334244078],
              [-2.737059235902982, 54.176483442576739],
              [-2.7362836633326, 54.176894428071748],
              [-2.736587626931184, 54.178221837512503],
              [-2.735935245099984, 54.178842377055538],
              [-2.735707331877832, 54.180071477872765],
              [-2.736142704657415, 54.181119468837196],
              [-2.734985617989877, 54.182313800440603],
              [-2.73438753301277, 54.18410059086775],
              [-2.738768673423166, 54.1850579347795],
              [-2.743054828089752, 54.185492627088458],
              [-2.744942605980744, 54.185912368846935],
              [-2.745532692499248, 54.186599864179549],
              [-2.746787120854628, 54.186990242471019],
              [-2.748603746006791, 54.187028394846998],
              [-2.749301957040546, 54.188944704560576],
              [-2.750787704837865, 54.188732330762136],
              [-2.753426711097358, 54.187723616384368],
              [-2.768232734069348, 54.185953879692619],
              [-2.76911168485855, 54.186166668419339],
              [-2.769220920814908, 54.187015301242894],
              [-2.769761690433591, 54.187335400210728],
              [-2.771836447903351, 54.187325715112934],
              [-2.774797425313642, 54.188405887280638],
              [-2.776459002653177, 54.188670206721874],
              [-2.777742929475818, 54.189469909151917],
              [-2.780205481545893, 54.189539362523846],
              [-2.782493276232563, 54.190741443656634],
              [-2.783148208044231, 54.191498438410306],
              [-2.784347118480811, 54.191682072363221],
              [-2.786458228457617, 54.193091045767552],
              [-2.786928177700383, 54.193221891544042],
              [-2.788628326081975, 54.192841378774723],
              [-2.794896373797268, 54.195768784332159],
              [-2.795013515203363, 54.197000214890977],
              [-2.796142827031351, 54.197220141863681],
              [-2.796998650728293, 54.197007765982406],
              [-2.799230412655557, 54.197721873163097],
              [-2.799933033912279, 54.197275915865283],
              [-2.800071229072567, 54.196415780415315],
              [-2.802127667484723, 54.196940460826319],
              [-2.80286175280723, 54.196854680762883],
              [-2.803987074180421, 54.195998741161468],
              [-2.804049749350227, 54.19543749431886],
              [-2.804639542739014, 54.195500063774681],
              [-2.805499838294663, 54.194891242519333],
              [-2.806176147631925, 54.193808200618435],
              [-2.809041561518633, 54.194006486297639],
              [-2.811076578141677, 54.191950805562932],
              [-2.81633399698348, 54.189799589873658],
              [-2.815408013527486, 54.187292016561642],
              [-2.81408947101946, 54.186714043574739],
              [-2.813687578520546, 54.185757776945465],
              [-2.813973877474644, 54.184886735618939],
              [-2.813524585167211, 54.184480833323413],
              [-2.813773805131165, 54.183906635528032],
              [-2.814985037930557, 54.183318739964591],
              [-2.81562613903304, 54.182103761185942],
              [-2.818786634439018, 54.180834824308356],
              [-2.821140725882284, 54.181020117850863],
              [-2.823467415533026, 54.180056928501408],
              [-2.828801943636971, 54.17895708712944],
              [-2.837217679810891, 54.174162356160323],
              [-2.869492426825583, 54.176676663821098],
              [-2.870167051960726, 54.177649653228869],
              [-2.869829849207016, 54.179102705665748],
              [-2.868563375203939, 54.179863219829187],
              [-2.865608684042627, 54.180639463151124],
              [-2.864089377186497, 54.180817546388859],
              [-2.862685691486412, 54.180588538130372],
              [-2.859241366635433, 54.179410611008386],
              [-2.858755681158961, 54.178597992718572],
              [-2.857433605890669, 54.178975909870871],
              [-2.857592163600871, 54.17975221653581],
              [-2.858943505571593, 54.180858856601496],
              [-2.860080189799449, 54.181438538426278],
              [-2.86283589131838, 54.181914945036844],
              [-2.864728250460214, 54.183751038811963],
              [-2.864313177666328, 54.187065085014588],
              [-2.86230018485937, 54.188347678797413],
              [-2.863065484317145, 54.189773036306988],
              [-2.864428735370485, 54.190105689512855],
              [-2.864855250430639, 54.190568189425953],
              [-2.864119466830136, 54.192030374865219],
              [-2.861968506834746, 54.192955342618312],
              [-2.861396216817885, 54.193515774683171],
              [-2.859655850383409, 54.193848171385824],
              [-2.855583864385289, 54.19312579877483],
              [-2.854597387849271, 54.193301771210031],
              [-2.853452956995767, 54.192730181727015],
              [-2.854327076134373, 54.193423225124292],
              [-2.853043797012815, 54.194146841611122],
              [-2.85286827823067, 54.194852718160078],
              [-2.852003718086314, 54.195518532485693],
              [-2.850056397073003, 54.196379829802005],
              [-2.844224701898763, 54.197987398716428],
              [-2.840620980409296, 54.200328724227148],
              [-2.836215258355412, 54.201540370394511],
              [-2.83535121323684, 54.202555679023206],
              [-2.834210855884415, 54.203174742516282],
              [-2.8346778906843, 54.203071744056643],
              [-2.83386501741983, 54.204423723551734],
              [-2.834256741936099, 54.204767035260872],
              [-2.835326189373298, 54.204886349022132],
              [-2.835403321978384, 54.205213863060372],
              [-2.831805847673307, 54.20471747730744],
              [-2.82768991686968, 54.2029761650113],
              [-2.829961504058806, 54.20418105524115],
              [-2.82808703147822, 54.203745473765935],
              [-2.827544089059895, 54.203120070524484],
              [-2.825092560025777, 54.202802549047739],
              [-2.822055442410707, 54.203101932811229],
              [-2.819539050655911, 54.203997173053878],
              [-2.823608495389635, 54.202952014653142],
              [-2.827538143527202, 54.203282787668279],
              [-2.82417187106009, 54.203897501781874],
              [-2.82389201133648, 54.203948602170662],
              [-2.823877571267346, 54.204102428179112],
              [-2.823746543220787, 54.205498166743787],
              [-2.822887709860189, 54.206178109707821],
              [-2.823793085233968, 54.205525710102968],
              [-2.823961323024673, 54.204044109709017],
              [-2.824087959612102, 54.203955932042234],
              [-2.826905911389561, 54.203482161612321],
              [-2.828079573131476, 54.20390910009128],
              [-2.825803230419146, 54.203999325952701],
              [-2.824743847532182, 54.204459561487667],
              [-2.823955203645213, 54.206584243237309],
              [-2.821574042934342, 54.208662270548537],
              [-2.820375282856945, 54.208364861589715],
              [-2.819814699592976, 54.208781210360449],
              [-2.819289050046454, 54.208639187263117],
              [-2.819961048883118, 54.208196917192105],
              [-2.819679280332948, 54.207517572738716],
              [-2.818423028778497, 54.208032118441515],
              [-2.817829762685874, 54.209278237828897],
              [-2.818538840252371, 54.208073573344393],
              [-2.819608187744664, 54.207643883303888],
              [-2.819829246859, 54.208122318264799],
              [-2.819116364490983, 54.208745516925433],
              [-2.819859600703725, 54.208880667410334],
              [-2.820435907053474, 54.208483984128755],
              [-2.821652032821469, 54.20872914579229],
              [-2.821465704146121, 54.209383817182406],
              [-2.816824761758788, 54.2116505993123],
              [-2.814447707971224, 54.212178090176572],
              [-2.811907868899484, 54.213321384075314],
              [-2.811066532110875, 54.213973261217113],
              [-2.810428280293192, 54.215365244125564],
              [-2.808932229477068, 54.216496054507964],
              [-2.807645664458666, 54.218699460296783],
              [-2.805477733253281, 54.219675648540225],
              [-2.80231096092094, 54.219845075964272],
              [-2.796710191977506, 54.223356849367079],
              [-2.794340511299908, 54.223837155375399],
              [-2.791751708278467, 54.225293108904637],
              [-2.790886937503154, 54.226994745509735],
              [-2.787637028059369, 54.227180508513534],
              [-2.786551735396293, 54.226170205023692],
              [-2.786231639894102, 54.226276550286293],
              [-2.785814375657928, 54.226435656461625],
              [-2.787298973227812, 54.227391229879188],
              [-2.789541221538506, 54.227372062729088],
              [-2.788534488585625, 54.228715108654939],
              [-2.789279152043894, 54.231322920485049],
              [-2.791800810307187, 54.233066141832076],
              [-2.794647511327753, 54.233829319523338],
              [-2.7964342593918, 54.237702849150402],
              [-2.797509878815392, 54.239058250047769],
              [-2.796849386359121, 54.241807421861154],
              [-2.800567815666389, 54.246027600403004],
              [-2.801865245019088, 54.246615748011877],
              [-2.803984726881533, 54.247016851378376],
              [-2.808784930443502, 54.246534453542523],
              [-2.80932081493761, 54.24631425545499],
              [-2.8091443700502, 54.246002673088022],
              [-2.808608289510295, 54.245742037041161],
              [-2.806510265703102, 54.246203677399841],
              [-2.80597746828458, 54.246031984600883],
              [-2.809559537382915, 54.244477391031815],
              [-2.811655130906301, 54.244210743694396],
              [-2.812837733807304, 54.244651252162676],
              [-2.814300900595352, 54.247569523457749],
              [-2.813990375821844, 54.248945819976036],
              [-2.812804090748644, 54.249807645331884],
              [-2.807997382184963, 54.250523921991871],
              [-2.804034302524665, 54.24948630147248],
              [-2.802404547884096, 54.249415377228189],
              [-2.79934551991936, 54.250988755432232],
              [-2.79874425174119, 54.252517030348159],
              [-2.798066013607279, 54.253082346558102],
              [-2.796869150315743, 54.253180141797252],
              [-2.794110234415736, 54.252264546727517],
              [-2.793894592845587, 54.252464592203722],
              [-2.796500895643816, 54.253425239903791],
              [-2.798067727177297, 54.253409481892547],
              [-2.799165871200004, 54.252730835125448],
              [-2.800850918255668, 54.250579713636434],
              [-2.803042968623803, 54.249808374632558],
              [-2.804264569799262, 54.250041094631442],
              [-2.805383671858934, 54.250998884275482],
              [-2.805392963361968, 54.251790625689885],
              [-2.807746771297331, 54.25175779592913],
              [-2.808321414158522, 54.252024468432573],
              [-2.808767622182327, 54.253649120857993],
              [-2.810466770109044, 54.255135931324617],
              [-2.812817941748645, 54.255973914791653],
              [-2.814904050710768, 54.25582052044421],
              [-2.814817768911231, 54.256426864930845],
              [-2.813475573481416, 54.256552769935212],
              [-2.811811759352466, 54.257497795073334],
              [-2.811728129107388, 54.258163436983914],
              [-2.812967337449908, 54.258495712634804],
              [-2.812172302915632, 54.261235983080894],
              [-2.812720289122338, 54.260217595372289],
              [-2.813165493408257, 54.258424272973095],
              [-2.811943011950877, 54.258161989552335],
              [-2.811992748340413, 54.25749028455327],
              [-2.813506734432693, 54.256654118944134],
              [-2.814970236659772, 54.256530089650326],
              [-2.815106812597892, 54.255749945119788],
              [-2.812468336272066, 54.255758773337476],
              [-2.810579867764635, 54.255032712617513],
              [-2.80913405862024, 54.253785069532675],
              [-2.808886477982704, 54.25211504641949],
              [-2.807706661791499, 54.251511805741437],
              [-2.806381371104656, 54.251395753788849],
              [-2.807384916234755, 54.251148168200864],
              [-2.811691311138558, 54.250663571135988],
              [-2.81391549379121, 54.249897209900752],
              [-2.81525196239602, 54.248636208541122],
              [-2.815417561599058, 54.246683887446181],
              [-2.814381338269502, 54.244252567156494],
              [-2.812644091769306, 54.243309815220073],
              [-2.810678873684061, 54.24278829181722],
              [-2.808748842244532, 54.242729360163239],
              [-2.806686770572658, 54.2432168269657],
              [-2.803983665897936, 54.244911072907144],
              [-2.802911639622717, 54.24518155605822],
              [-2.802325655513685, 54.24511445725549],
              [-2.801396571603238, 54.244368380698752],
              [-2.801012406085695, 54.243549471779879],
              [-2.801291390108994, 54.241792345723816],
              [-2.804254120085071, 54.233808719313686],
              [-2.802500444098881, 54.230956969242143],
              [-2.80144305709172, 54.230460702864292],
              [-2.801152510879043, 54.23058756257641],
              [-2.80009518201746, 54.230489433893617],
              [-2.799782675933158, 54.230194918565509],
              [-2.799732936134059, 54.230399266825493],
              [-2.798559080303507, 54.22966827365687],
              [-2.798369749061912, 54.22995263719433],
              [-2.796138437712485, 54.22959082253319],
              [-2.794921725327661, 54.228541018204233],
              [-2.795345832322881, 54.228020534570518],
              [-2.794381523509581, 54.227410348487332],
              [-2.797789978434141, 54.225981269534337],
              [-2.799888841288489, 54.226404149528108],
              [-2.802887376356139, 54.225759566603422],
              [-2.803969385147987, 54.225318250347343],
              [-2.80472278427867, 54.224543882462157],
              [-2.810244270562559, 54.223588342425003],
              [-2.817090805323116, 54.221940510789267],
              [-2.824406447990331, 54.220771713440691],
              [-2.827155599803046, 54.219966452747087],
              [-2.827834824537308, 54.219497127445123],
              [-2.828867459903391, 54.21990435615092],
              [-2.82994518781236, 54.219882556051843],
              [-2.832196698908322, 54.219201041168326],
              [-2.834226598611335, 54.216811572629538],
              [-2.835762159545004, 54.215848240560447],
              [-2.841468149319073, 54.207165106262984],
              [-2.835633137041364, 54.216369519256496],
              [-2.835548588949194, 54.218485792732935],
              [-2.836002007802537, 54.218914951735584],
              [-2.836052168642679, 54.216388182120753],
              [-2.841508707974802, 54.208330520335458],
              [-2.842340133054073, 54.207898694668806],
              [-2.841717829745585, 54.208058531127662],
              [-2.84250830319216, 54.207422970875655],
              [-2.84190827621175, 54.207697694390596],
              [-2.84173571506634, 54.206751602619143],
              [-2.842473924947909, 54.205507943856155],
              [-2.844379092510565, 54.205633013071569],
              [-2.847645622905356, 54.205155294529334],
              [-2.854082847281609, 54.204915688035896],
              [-2.857910780250386, 54.204164078577179],
              [-2.856067609857659, 54.204960017871997],
              [-2.856506238793911, 54.204967684800323],
              [-2.858182487213345, 54.204181017083812],
              [-2.858979177346392, 54.204599555733751],
              [-2.858670433743056, 54.203979810956902],
              [-2.860480004193644, 54.203710748560468],
              [-2.860810887445008, 54.204288088572156],
              [-2.860899179511852, 54.203741906356257],
              [-2.864887155330924, 54.20325047140895],
              [-2.865347602730825, 54.202909227888604],
              [-2.866457355542793, 54.203047748770167],
              [-2.868555848705386, 54.202086232753402],
              [-2.868996182972941, 54.202760727082776],
              [-2.869497450592813, 54.202686106886524],
              [-2.869086816279406, 54.203207659178084],
              [-2.870075898856844, 54.203220291257395],
              [-2.870226036628406, 54.203798911414147],
              [-2.867410505859834, 54.20478449604596],
              [-2.870323984644228, 54.203863813705965],
              [-2.87008909573976, 54.203045835092659],
              [-2.869392213872314, 54.203076931491324],
              [-2.869789976537823, 54.202526710530094],
              [-2.869323167202914, 54.202344035829768],
              [-2.870062163835136, 54.20176438748976],
              [-2.871579759057644, 54.204018308903485],
              [-2.870705111764797, 54.202449994638421],
              [-2.872198542057592, 54.202819940488666],
              [-2.872629906395471, 54.20292679192157],
              [-2.873812329820853, 54.202657576942748],
              [-2.872359012723074, 54.202803796799472],
              [-2.871854438384652, 54.202574698237335],
              [-2.874908912306969, 54.202026770489518],
              [-2.874860815781491, 54.20236236019479],
              [-2.875464820245568, 54.201854661551302],
              [-2.878474072565098, 54.201492117908352],
              [-2.882223590726953, 54.200405064151674],
              [-2.882725456938687, 54.200504745415458],
              [-2.8845896904413, 54.19957971659575],
              [-2.885903000985651, 54.197555023591747],
              [-2.886445516484144, 54.198122649056195],
              [-2.886870372806053, 54.198062898884075],
              [-2.887177267112102, 54.199365651422951],
              [-2.886963982648977, 54.19792469785402],
              [-2.885253410152916, 54.197283877832206],
              [-2.885150255370659, 54.196760653544189],
              [-2.887366664055456, 54.194541459579376],
              [-2.888117386254342, 54.19552726893501],
              [-2.887256398131358, 54.196977937490459],
              [-2.887367312396256, 54.198291121062283],
              [-2.889158059080506, 54.198807289207728],
              [-2.896042036760006, 54.196884133676221],
              [-2.896141720704454, 54.196319863135393],
              [-2.897120444764909, 54.19663972912408],
              [-2.898256253624567, 54.196498245352252],
              [-2.900012992838446, 54.195447050406997],
              [-2.901547590255976, 54.195883160509396],
              [-2.905004580164975, 54.194716692777668],
              [-2.907610471684948, 54.19311884021721],
              [-2.909059002358726, 54.191240267335317],
              [-2.911045262096756, 54.18984654137585],
              [-2.911787008013543, 54.188244707906335],
              [-2.911694854978122, 54.187051835513053],
              [-2.912337178755821, 54.186435805468491],
              [-2.913531576710512, 54.184935686204966],
              [-2.915882195750392, 54.184170950547099],
              [-2.918154984866369, 54.182179935721287],
              [-2.919570718675407, 54.177550331135755],
              [-2.920614834621503, 54.175929051777878],
              [-2.925253114546978, 54.172395464519553],
              [-2.928253714826921, 54.169366860573142],
              [-2.929397459838885, 54.167499366622557],
              [-2.930772487183835, 54.167069015465771],
              [-2.931764017807953, 54.167704870484506],
              [-2.934954549783262, 54.167225355194788],
              [-2.935236625232865, 54.166964316459428],
              [-2.935342747435413, 54.166304688078952],
              [-2.934566838897156, 54.164975129877334],
              [-2.934834748928776, 54.164494001323909],
              [-2.93269092034093, 54.162539608874816],
              [-2.930804248737949, 54.158864726381687],
              [-2.93107034560826, 54.158234422532509],
              [-2.930599608768611, 54.155751141871882],
              [-2.931097341682581, 54.152889175723857],
              [-2.931855444280469, 54.152282924915866],
              [-2.931536011327587, 54.151899820907609],
              [-2.931862770581256, 54.151382291153013],
              [-2.935208643555353, 54.155199524502102],
              [-2.935861657491452, 54.15638353453744],
              [-2.935618433309791, 54.158159614873441],
              [-2.935827436731369, 54.157513565844681],
              [-2.935950402885007, 54.158008735859987],
              [-2.936411084989294, 54.157861348689494],
              [-2.937102125292872, 54.158556120259995],
              [-2.93774250051956, 54.158431596146087],
              [-2.937686497879127, 54.158663917342402],
              [-2.938725287446771, 54.158888605456191],
              [-2.9427081596389, 54.158064759119526],
              [-2.943361385043529, 54.158371518548243],
              [-2.943775143949275, 54.158047411252447],
              [-2.944185838281943, 54.158597839037618],
              [-2.943976453251453, 54.159759805260876],
              [-2.942840155371447, 54.15982892802338],
              [-2.944157037047754, 54.159888712135135],
              [-2.945054650986411, 54.160904479942985],
              [-2.946888452329119, 54.161333168793973],
              [-2.94686235646329, 54.16200116703191],
              [-2.948177357678442, 54.162645128747961],
              [-2.947692639964607, 54.163282584608751],
              [-2.947227840795972, 54.163317699749008],
              [-2.947427929228631, 54.163762818494511],
              [-2.948373156528752, 54.16263459872215],
              [-2.947119649396253, 54.162000940720532],
              [-2.947001037379274, 54.161233417508619],
              [-2.945256295785039, 54.160814816489605],
              [-2.944866612688092, 54.160247150706475],
              [-2.945306782535767, 54.160006417464267],
              [-2.944512971809182, 54.159983887076436],
              [-2.944143096041387, 54.159545487766813],
              [-2.945469828055435, 54.159567431507561],
              [-2.945249625682555, 54.159313008543386],
              [-2.944421515945322, 54.159397701652786],
              [-2.945370263999339, 54.159096354175368],
              [-2.945713953260245, 54.158587641851163],
              [-2.944586566095848, 54.158239677528783],
              [-2.945119223749576, 54.157824754611106],
              [-2.946110548919327, 54.157444872950776],
              [-2.948123153351147, 54.157662721620369],
              [-2.947081241395825, 54.157436341469541],
              [-2.947209183435158, 54.157137838701466],
              [-2.94460537279168, 54.156981238423661],
              [-2.944209948419897, 54.156226669175425],
              [-2.943772555188149, 54.156655221883376],
              [-2.939282433418091, 54.156577093475498],
              [-2.940450472967313, 54.156020617467071],
              [-2.943498700625711, 54.155522209637589],
              [-2.945793953457545, 54.154642268328075],
              [-2.95194518623288, 54.153514346767182],
              [-2.962396234898765, 54.150761864446061],
              [-2.962906590314432, 54.151112802013955],
              [-2.96349067345382, 54.150814226612646],
              [-2.965494609977605, 54.150735255461697],
              [-2.965500848120522, 54.150213911619502],
              [-2.966937109332191, 54.150283273444792],
              [-2.967246056850116, 54.150597162809092],
              [-2.967503996137058, 54.150168168171824],
              [-2.968293119513921, 54.149997345772405],
              [-2.966845899140913, 54.149917302783443],
              [-2.966227771133488, 54.14934434881495],
              [-2.9645338194817, 54.148858211101711],
              [-2.964982618634099, 54.148406121518022],
              [-2.964989468554507, 54.147055196078071],
              [-2.967070424043032, 54.146150497554309],
              [-2.968382372179062, 54.146015917762078],
              [-2.970145857747607, 54.14633606472232],
              [-2.971817872329919, 54.147651877743066],
              [-2.97112821649089, 54.146640919747519],
              [-2.976180760898044, 54.147956314843007],
              [-2.981910361784953, 54.148107445183221],
              [-2.984525802497443, 54.148577716698604],
              [-2.986972762972082, 54.148528018139061],
              [-2.989808771944765, 54.149062876531076],
              [-2.993876581656696, 54.149308880804881],
              [-2.995821575598935, 54.149968692349546],
              [-2.99672667080399, 54.151952908852479],
              [-2.999532456221006, 54.153251756763339],
              [-2.998119926387705, 54.15430247217369],
              [-2.996512371737059, 54.154695976170196],
              [-2.996255439594158, 54.155155584029799],
              [-2.996678444577981, 54.155744381432612],
              [-2.997547836954071, 54.155664378794569],
              [-2.996719820194568, 54.155618209062666],
              [-2.996746341323461, 54.155001423407839],
              [-3.000189374269027, 54.153824219108643],
              [-3.002564955789474, 54.154546854205364],
              [-3.005293851534625, 54.156259650143625],
              [-3.005960880474618, 54.157243640271751],
              [-3.006091582548872, 54.158717453822938],
              [-3.004709570893115, 54.162924520445529],
              [-3.003482119954909, 54.162517721947545],
              [-3.002606710735425, 54.160707674321017],
              [-3.002814117691497, 54.162824380573795],
              [-3.004353087894106, 54.163509008034673],
              [-3.004656850416958, 54.165489190402639],
              [-3.004303864282573, 54.166282166085601],
              [-3.001933360988142, 54.167129695852772],
              [-3.000361631801225, 54.168392073444494],
              [-2.999690879195609, 54.168162597680755],
              [-2.999403021692282, 54.167641461783496],
              [-3.000375478140099, 54.16737903014797],
              [-2.999307798893565, 54.167503839620821],
              [-2.998985305688957, 54.167215310261234],
              [-2.999287997776668, 54.166873058415241],
              [-2.998773139815747, 54.167119103165113],
              [-2.998523046651013, 54.166656506862452],
              [-2.997940689213206, 54.167008017364168],
              [-2.998804581101044, 54.167279724457131],
              [-2.999547108174441, 54.168113410078035],
              [-2.999854092201415, 54.168458069881005],
              [-3.000147533270296, 54.168787518955405],
              [-2.999731792533763, 54.169206209303844],
              [-2.998751911656088, 54.16922602579546],
              [-2.999408006371866, 54.169690644813443],
              [-2.998804035628962, 54.169799915395693],
              [-2.998438904011653, 54.170285589950225],
              [-2.997646014284714, 54.170247223281585],
              [-2.996673543282687, 54.170639955946299],
              [-2.997902427292838, 54.170526417231542],
              [-2.996565453770372, 54.171305050582141],
              [-2.997307705678919, 54.173121634546433],
              [-2.994847510315741, 54.172616199592056],
              [-2.994890051879781, 54.1719633325581],
              [-2.993956728925833, 54.170733418376209],
              [-2.992971137633188, 54.170260702376559],
              [-2.994738530237357, 54.172032891881663],
              [-2.994596605264658, 54.172374702595036],
              [-2.993984566992002, 54.172085855126426],
              [-2.99343087475865, 54.172185695630063],
              [-2.992486947100963, 54.173262131181289],
              [-2.993083138433837, 54.175446646576475],
              [-2.993576207092114, 54.175593682046078],
              [-2.994883920507582, 54.175983632750302],
              [-2.994729878600662, 54.176778529134069],
              [-2.99375961173268, 54.176759577258217],
              [-2.993367051280816, 54.177128620263943],
              [-2.994116309225516, 54.177514305231028],
              [-2.995822100152688, 54.177473246836264],
              [-2.99608381132592, 54.177780262854974],
              [-2.994357790844298, 54.177936535910987],
              [-2.992916460339348, 54.178592855378156],
              [-2.989427323035311, 54.179473619444799],
              [-2.991982394594499, 54.178935799063574],
              [-2.992456622199899, 54.179736299944537],
              [-2.992206416978842, 54.178885419195474],
              [-2.993778464198614, 54.178503955287709],
              [-2.996055626825465, 54.178779443386631],
              [-2.996302192645394, 54.179025065331643],
              [-2.996754906282582, 54.178864032100357],
              [-2.996024508061126, 54.178748443891699],
              [-2.993808089888265, 54.178397654656592],
              [-2.994643681535841, 54.177974619857281],
              [-2.995999008124001, 54.178076663283782],
              [-2.996299901862284, 54.177655342105169],
              [-2.995757955784242, 54.177354239419905],
              [-2.994170636346617, 54.177415889579706],
              [-2.993616368195259, 54.177111283930813],
              [-2.993762758003099, 54.176890773312302],
              [-2.994822238513409, 54.176924267415956],
              [-2.995098670487828, 54.17644113537407],
              [-2.994892312905953, 54.175758868108922],
              [-2.993532857319155, 54.17552426107401],
              [-2.993157935276724, 54.174923837815435],
              [-2.992806518546056, 54.173364654015451],
              [-2.993630002325404, 54.172312578285677],
              [-2.995978753720828, 54.173218018984279],
              [-2.995716761798433, 54.173599471692029],
              [-2.996284115497182, 54.173939910714218],
              [-2.994970906085127, 54.175331297609617],
              [-2.995570243875094, 54.175280505802498],
              [-2.996065010194457, 54.175914548702046],
              [-2.995767621066729, 54.17526988587025],
              [-2.995169556079578, 54.17524606929328],
              [-2.995977209307777, 54.174428690559317],
              [-2.997715049600983, 54.174578780205849],
              [-2.999300347288376, 54.175454528946702],
              [-2.997729064275465, 54.174460923683625],
              [-2.996147225900444, 54.174363470629174],
              [-2.996457973118154, 54.173970828181432],
              [-2.995908760065663, 54.173620353331721],
              [-2.996154917298341, 54.17328127394601],
              [-2.99724261570586, 54.173408885046769],
              [-2.998868155431028, 54.174494621057498],
              [-3.000219509320211, 54.174372852908149],
              [-2.999780157613971, 54.174069118367477],
              [-2.998913849746355, 54.174292914869802],
              [-2.997500640371871, 54.173308780522063],
              [-2.9995683611658, 54.172782922248054],
              [-2.99991517644605, 54.172934633122026],
              [-3.000927558098649, 54.17210024067159],
              [-3.001501505788976, 54.172518958103225],
              [-3.001362782027454, 54.173691960244255],
              [-3.001881408214016, 54.174042664912314],
              [-3.001543022728952, 54.174367244016317],
              [-3.003535406652839, 54.175791402711177],
              [-3.001761261660022, 54.174395987116242],
              [-3.002071923291495, 54.174065346650217],
              [-3.001580517789812, 54.173700035856825],
              [-3.001544283911326, 54.172550165713602],
              [-3.001534508245998, 54.172239897508788],
              [-3.004327373102008, 54.171930821100212],
              [-3.006526007790012, 54.17226029055665],
              [-3.00993083589527, 54.175188775223774],
              [-3.009788941373165, 54.175715753065482],
              [-3.008957927364603, 54.176129867717869],
              [-3.009529513222096, 54.176197878242291],
              [-3.009694732793323, 54.176816652763137],
              [-3.008247950319031, 54.176544760679342],
              [-3.008231004665935, 54.177989245475281],
              [-3.006363193134261, 54.178551329698621],
              [-3.005078434687058, 54.17832837700324],
              [-3.005409533630524, 54.17902935897191],
              [-3.005067304969676, 54.180269838100159],
              [-3.005605579787499, 54.179152652562856],
              [-3.005329628556813, 54.178388295996356],
              [-3.006356882860716, 54.178670021632414],
              [-3.00835157687794, 54.178221919181219],
              [-3.008871154798625, 54.17778884802091],
              [-3.008681939142369, 54.177065115999007],
              [-3.009676487959808, 54.177199687013967],
              [-3.010292283753617, 54.176943761609785],
              [-3.011859440006576, 54.179383379460624],
              [-3.010016454705803, 54.179958793272036],
              [-3.008956514213122, 54.179847242331256],
              [-3.008674599273204, 54.18016238020833],
              [-3.007594569750371, 54.179980880582235],
              [-3.007100497379562, 54.180267231205342],
              [-3.007715946811393, 54.180435547192182],
              [-3.007313672961047, 54.180275334724904],
              [-3.007554255072367, 54.180087274151475],
              [-3.008651706947121, 54.180291996537022],
              [-3.009039783419799, 54.179931928869379],
              [-3.00993485069311, 54.180068230325254],
              [-3.011900598276874, 54.179625704774111],
              [-3.011344307658238, 54.18125357842878],
              [-3.010050074586208, 54.182029304445962],
              [-3.008961607934981, 54.18206988672015],
              [-3.009901386954954, 54.182089871101311],
              [-3.009825851445128, 54.182762793145855],
              [-3.011697927155956, 54.181302737340395],
              [-3.012247825856234, 54.179539199639606],
              [-3.01265810023911, 54.17971281014367],
              [-3.014614942273893, 54.181098438117985],
              [-3.013226622588117, 54.181846230245711],
              [-3.013781333019572, 54.181722023523726],
              [-3.013701009190534, 54.182259272541764],
              [-3.014234381176997, 54.181576200982455],
              [-3.015615611717303, 54.181977999300848],
              [-3.014241036268556, 54.183162495025179],
              [-3.014922046169326, 54.183064182767119],
              [-3.015661476816858, 54.182348809463214],
              [-3.017651896522496, 54.183407847318414],
              [-3.015985385947476, 54.182373040521917],
              [-3.016478419046885, 54.182233163664684],
              [-3.018486704566906, 54.18383130627074],
              [-3.020140486272714, 54.184530940808592],
              [-3.019569431415304, 54.185174811078411],
              [-3.01857951301446, 54.185300029835197],
              [-3.019143109340107, 54.185411204314526],
              [-3.01836522747515, 54.186120632355532],
              [-3.020053353993905, 54.185099709177273],
              [-3.021056899536745, 54.185279049861549],
              [-3.019955450458392, 54.185666771086673],
              [-3.019344042756862, 54.186415240548904],
              [-3.021170615461654, 54.188151491356138],
              [-3.020203025265549, 54.188688175746236],
              [-3.020719218320227, 54.189112520531921],
              [-3.021229882038391, 54.188939220285093],
              [-3.021179600124611, 54.189448357014015],
              [-3.02017950094524, 54.189599739428097],
              [-3.021366187021657, 54.190116366904419],
              [-3.020975788698226, 54.189822180580293],
              [-3.021500547293174, 54.189039385854933],
              [-3.021362391020879, 54.188843724474559],
              [-3.020681009847269, 54.188992407646019],
              [-3.020430169206131, 54.188701530579849],
              [-3.021371443562709, 54.188154282167886],
              [-3.019737751174607, 54.186223163159198],
              [-3.020546164700471, 54.185509872309829],
              [-3.022132384367568, 54.185765155123292],
              [-3.023282454839192, 54.186602040906763],
              [-3.022900078246271, 54.187128380581314],
              [-3.023480854339017, 54.186878977348528],
              [-3.025872986297708, 54.188671466845292],
              [-3.025327727380349, 54.188870247320438],
              [-3.025068973793905, 54.188632475286511],
              [-3.025157636021902, 54.189056844431562],
              [-3.024391866540831, 54.189572969932847],
              [-3.025759901793454, 54.188870162147083],
              [-3.02699095735081, 54.189629027680652],
              [-3.026436879104754, 54.190523545484794],
              [-3.025354798293827, 54.190459063127555],
              [-3.025810910470732, 54.192167357925939],
              [-3.024605792461171, 54.191777658950322],
              [-3.025901859795853, 54.192312185820654],
              [-3.025498701698321, 54.190514461089926],
              [-3.026438776309555, 54.190661941712037],
              [-3.027743578573269, 54.190066605507099],
              [-3.028625269703743, 54.190763723098286],
              [-3.029176347114231, 54.192464004242396],
              [-3.028921735338332, 54.192826591248817],
              [-3.027734960844268, 54.192251617684143],
              [-3.02772108848801, 54.191445528852405],
              [-3.027402648216659, 54.192077393073532],
              [-3.029087902972332, 54.193037283972551],
              [-3.029248659013741, 54.193770214838963],
              [-3.038760558310219, 54.197184812756582],
              [-3.038274274719947, 54.197475717233402],
              [-3.036086699440195, 54.196733287500081],
              [-3.027560055900032, 54.193665095954863],
              [-3.023033094403041, 54.191645426146962],
              [-3.022817389926571, 54.191785670611509],
              [-3.024097401857783, 54.192415623980303],
              [-3.028843650080151, 54.194372263868075],
              [-3.026536180342446, 54.193787972581497],
              [-3.025611358633089, 54.193207150019106],
              [-3.025631403430466, 54.193459536871543],
              [-3.024017418795774, 54.19327194384099],
              [-3.024870940825799, 54.193511848449283],
              [-3.024329860056659, 54.193944271514802],
              [-3.025204136730943, 54.193535976019739],
              [-3.025799531113816, 54.193626177076681],
              [-3.028064444556469, 54.19434925802328],
              [-3.027759129853945, 54.194707781263652],
              [-3.028319168027879, 54.194422581032526],
              [-3.029639883786671, 54.194648575485445],
              [-3.034757816143863, 54.196491255493456],
              [-3.027351680517105, 54.197807558320029],
              [-3.02622760607046, 54.197726364457893],
              [-3.026304163326001, 54.197351819318357],
              [-3.02541287148139, 54.196828231504639],
              [-3.025977229829317, 54.197829160986316],
              [-3.024890210875161, 54.198563731741821],
              [-3.024163729509877, 54.200117611181625],
              [-3.022893051974366, 54.201054152734663],
              [-3.021222219548377, 54.201059342983577],
              [-3.01867356743757, 54.200278315005384],
              [-3.016246325699592, 54.201067275606796],
              [-3.01855069818992, 54.200393499142137],
              [-3.020087642192032, 54.200803817339711],
              [-3.017612609203228, 54.20133256409467],
              [-3.014926742676184, 54.203331641388282],
              [-3.014450889033837, 54.204308128766819],
              [-3.014545596200105, 54.206358343877781],
              [-3.01580246363659, 54.208281023469979],
              [-3.017505640730431, 54.208956011607398],
              [-3.01781364295164, 54.209883645046752],
              [-3.018713954552211, 54.210703809261666],
              [-3.018946169435412, 54.210544559029707],
              [-3.019802184579015, 54.210934575089539],
              [-3.020875553635737, 54.21056237679305],
              [-3.022227865970109, 54.211924241977961],
              [-3.02179628990334, 54.214566716025722],
              [-3.023118700926729, 54.215767044592639],
              [-3.023451659584395, 54.217014416225631],
              [-3.024577564510451, 54.218449181471811],
              [-3.0231898933616, 54.219390311174294],
              [-3.024834676315099, 54.218551252713574],
              [-3.024750269298836, 54.217743969626511],
              [-3.025809478932279, 54.218403645651918],
              [-3.026716696360188, 54.21849927669269],
              [-3.027627102210261, 54.219278843614582],
              [-3.028013024386968, 54.223477041811982],
              [-3.027692813384009, 54.224949568020747],
              [-3.028163078224845, 54.225109133143299],
              [-3.028133916294085, 54.222921079987735],
              [-3.028109880038866, 54.22111721870705],
              [-3.028558856967909, 54.220422226390575],
              [-3.029239802617619, 54.221407762526397],
              [-3.029173723498046, 54.22226576077113],
              [-3.031485887896813, 54.225371934619908],
              [-3.030436277058937, 54.225970513985914],
              [-3.031081749132427, 54.225896684684322],
              [-3.030839838864608, 54.226591711793105],
              [-3.033615493201609, 54.229261566127001],
              [-3.033764330417812, 54.22883337019077],
              [-3.032904440015004, 54.228001287388658],
              [-3.035193073825137, 54.228243189432064],
              [-3.039369861553018, 54.227376759914307],
              [-3.038589493940474, 54.227882310882684],
              [-3.039218858777137, 54.227960470326025],
              [-3.039177717084054, 54.228704114133762],
              [-3.03943564464068, 54.227919054061431],
              [-3.039029132591837, 54.227796731574323],
              [-3.039790622561844, 54.227455816951093],
              [-3.040400378958854, 54.227852306723378],
              [-3.039714947697624, 54.227252447489228],
              [-3.040430952518911, 54.226872375012348],
              [-3.042625819227657, 54.226559500754519],
              [-3.046318420683324, 54.226757576427723],
              [-3.048764914843177, 54.233224584162734],
              [-3.047586977275134, 54.235053956633678],
              [-3.045192598801132, 54.235987907178156],
              [-3.043448208457886, 54.237268503114947],
              [-3.038163769569153, 54.237444470476497],
              [-3.036878679902614, 54.238083779843613],
              [-3.036617054524029, 54.238909309408051],
              [-3.033973764097261, 54.239664521705841],
              [-3.03310504589084, 54.240944638382672],
              [-3.033706490306577, 54.243212482392963],
              [-3.033251144608856, 54.243781716820841],
              [-3.030879735977289, 54.243146819903089],
              [-3.028990002401518, 54.24157753283621],
              [-3.032096876911718, 54.239284252147932],
              [-3.031764455382981, 54.238383829781441],
              [-3.030204559080658, 54.236928577391332],
              [-3.028790107999655, 54.236813036064127],
              [-3.028049250344429, 54.237130335393509],
              [-3.025460722350745, 54.240156996160337],
              [-3.023427114848448, 54.239208102446206],
              [-3.022424336884756, 54.239113255993978],
              [-3.017378563471472, 54.240645231306303],
              [-3.014358946652993, 54.239840220900483],
              [-3.013654253914883, 54.24213982042145],
              [-3.014022755544889, 54.24283686438951],
              [-3.013144255702386, 54.243182192219841],
              [-3.013632805980282, 54.243392890513356],
              [-3.014547579276689, 54.242899854956519],
              [-3.014362303797448, 54.240915125740379],
              [-3.014754200718552, 54.240127196924668],
              [-3.017485912512665, 54.240954401856641],
              [-3.022587458468909, 54.239380605359493],
              [-3.023717865787199, 54.239672096638515],
              [-3.024705064327062, 54.24056158147534],
              [-3.02560976059066, 54.240597924807062],
              [-3.026397163551767, 54.240236204577855],
              [-3.029099708489819, 54.237416166854878],
              [-3.031552622858219, 54.238810764106773],
              [-3.031329300677519, 54.239392385676283],
              [-3.028257301425287, 54.240622098377209],
              [-3.027316329076123, 54.241534291319915],
              [-3.028062834495481, 54.24285990860821],
              [-3.029847466019117, 54.243535827753902],
              [-3.031054488937217, 54.245538761051421],
              [-3.03061477158534, 54.246060216307967],
              [-3.02869565530931, 54.245781815712746],
              [-3.027985147423388, 54.246647106486172],
              [-3.02848713360308, 54.248318137569818],
              [-3.029588216923267, 54.249084377260161],
              [-3.029877839640674, 54.249805415283419],
              [-3.029923182346529, 54.250824236860943],
              [-3.029292932940166, 54.251894667539169],
              [-3.030252117201068, 54.252875122205438],
              [-3.02865115686855, 54.253304929143482],
              [-3.026809156719471, 54.25459869141558],
              [-3.02539779836557, 54.255126602839056],
              [-3.024521573515657, 54.256313243596644],
              [-3.023793331584642, 54.256039913750932],
              [-3.022765144040405, 54.256113354919947],
              [-3.020285842482071, 54.257409725709635],
              [-3.020185087515486, 54.258054098971598],
              [-3.018361854919445, 54.259318809689844],
              [-3.015681147910681, 54.25999213961942],
              [-3.014915050094285, 54.260490220513667],
              [-3.012538288489317, 54.262295168205974],
              [-3.009610997084616, 54.265831216417396],
              [-3.008360047377465, 54.26892895940717],
              [-3.00978040487488, 54.265852266590521],
              [-3.012845571301077, 54.262179341431569],
              [-3.01542399919229, 54.260272925217102],
              [-3.018374696576487, 54.259466099645906],
              [-3.020342253339499, 54.258139949385573],
              [-3.020524348495079, 54.257494887290406],
              [-3.021539379127582, 54.256825691289322],
              [-3.023211144740151, 54.256147317757858],
              [-3.02448606018537, 54.256490603570079],
              [-3.025591403063438, 54.255319989102411],
              [-3.028194890233796, 54.254152770206538],
              [-3.028993436940696, 54.253427835464414],
              [-3.030529016024374, 54.253022849942013],
              [-3.030559289808449, 54.252575002059174],
              [-3.029690717470693, 54.251904751169398],
              [-3.030383255337896, 54.250747503591093],
              [-3.030155793563534, 54.249257484114175],
              [-3.028693887709721, 54.247745651631689],
              [-3.028441773632751, 54.246556928193513],
              [-3.028761992984359, 54.246289056700412],
              [-3.031605698864199, 54.246226099880253],
              [-3.032363136099488, 54.245768428891651],
              [-3.032475568086233, 54.244924415303359],
              [-3.034021503193149, 54.244885994602875],
              [-3.035803386120911, 54.244219978988774],
              [-3.036513543552708, 54.242981652306831],
              [-3.036440639098159, 54.241297974639416],
              [-3.036713891519124, 54.241547281188353],
              [-3.036971463091805, 54.240070174472905],
              [-3.037675281772782, 54.239867283850131],
              [-3.0461309052587, 54.240569816811274],
              [-3.048058522314209, 54.242277818709596],
              [-3.046493400471379, 54.240523528018315],
              [-3.047879037861891, 54.239616305108584],
              [-3.048256780619075, 54.237145877292917],
              [-3.049270137886147, 54.235299057977436],
              [-3.050459717556187, 54.233930640304393],
              [-3.051270147685408, 54.234427786495033],
              [-3.051043631572277, 54.234871961156301],
              [-3.05183715285814, 54.235185900427155],
              [-3.052185676239477, 54.235798520188084],
              [-3.051818008583023, 54.234192019436748],
              [-3.051083656737706, 54.233609728168069],
              [-3.051530679719169, 54.233028812462919],
              [-3.052418707086193, 54.231270239614588],
              [-3.051924327556575, 54.227334312014193],
              [-3.053119522766816, 54.220836481765751],
              [-3.051862138273256, 54.219459750604855],
              [-3.049509529893673, 54.218010763735116],
              [-3.047239497686631, 54.218121295151704],
              [-3.045086565984942, 54.219164599729055],
              [-3.043483870037004, 54.218069398806499],
              [-3.039841665436872, 54.217343220957005],
              [-3.039167400539678, 54.216814265509797],
              [-3.039147136330015, 54.217106543516294],
              [-3.035567147082654, 54.215612143122975],
              [-3.03329542288281, 54.213870043505786],
              [-3.032788730669827, 54.212615199178387],
              [-3.033218624830409, 54.212636678510734],
              [-3.032676010265928, 54.212399559759859],
              [-3.032418993109474, 54.211306661412294],
              [-3.034599464875326, 54.208434716350311],
              [-3.039026902851738, 54.205721836861194],
              [-3.039394966554434, 54.205848085798117],
              [-3.039065392228567, 54.206400084255336],
              [-3.039632237944886, 54.206504844393486],
              [-3.038329211942409, 54.207368127627483],
              [-3.039397229590181, 54.207159387226795],
              [-3.039176763971573, 54.208332400328906],
              [-3.037353877997273, 54.208522475942964],
              [-3.036674033599107, 54.209909710470868],
              [-3.036746578644561, 54.209864190266842],
              [-3.038327517870127, 54.210295469367274],
              [-3.038776032830884, 54.210143303733027],
              [-3.037204020805307, 54.209762285323755],
              [-3.037630186688632, 54.208718726514022],
              [-3.03879434727256, 54.208795874341703],
              [-3.039470932013876, 54.208445804847727],
              [-3.039951062712825, 54.207417050879577],
              [-3.041193294691289, 54.20773437456387],
              [-3.040443720361814, 54.207192595245836],
              [-3.039937891936531, 54.207258979208959],
              [-3.040572651037769, 54.20652728258559],
              [-3.040204834085257, 54.206594272943754],
              [-3.039780651608723, 54.205027765300798],
              [-3.039066692468891, 54.204497354158427],
              [-3.039407536832631, 54.203601025074299],
              [-3.04031572788772, 54.202831916824479],
              [-3.042977561629145, 54.201632391999382],
              [-3.044259658543087, 54.200558024241168],
              [-3.044691598727066, 54.199389456988726],
              [-3.045771071641111, 54.199158089906923],
              [-3.046017396119074, 54.199685333651885],
              [-3.049706423108643, 54.200561908251423],
              [-3.050794610098545, 54.200371764259231],
              [-3.053135813145391, 54.198879109855383],
              [-3.054849708680412, 54.195272573098649],
              [-3.054950560616831, 54.193515466192771],
              [-3.054207740197603, 54.190947853097349],
              [-3.053321359680671, 54.190445099409658],
              [-3.053825841080143, 54.190274410931714],
              [-3.05276930399736, 54.189950202545127],
              [-3.053308338821887, 54.189692032062766],
              [-3.053301868511766, 54.188473336970915],
              [-3.054270256426993, 54.187984912264191],
              [-3.055267199163358, 54.188076844399085],
              [-3.055938372496151, 54.187833682066739],
              [-3.055904700526979, 54.188135969007064],
              [-3.057191666311564, 54.188842807184713],
              [-3.059741022125687, 54.1893506836687],
              [-3.058501720999236, 54.188832198442405],
              [-3.058734401265523, 54.188342112836047],
              [-3.058092979242041, 54.188729733742889],
              [-3.056235013650633, 54.187747493679929],
              [-3.059884267624622, 54.18716267880869],
              [-3.056031721091679, 54.187347519684238],
              [-3.055869283180908, 54.187167389452142],
              [-3.056876433410545, 54.18693925124019],
              [-3.059537929182491, 54.184107159431271],
              [-3.061942142773982, 54.180457581971972],
              [-3.062369151485455, 54.181155767698407],
              [-3.061967946029629, 54.182241445295347],
              [-3.062364148127157, 54.182574096620911],
              [-3.062330336887943, 54.181554272490359],
              [-3.062549425106614, 54.182688405355286],
              [-3.06219231640074, 54.184119726892369],
              [-3.063385838652713, 54.186201567035873],
              [-3.062642190572997, 54.184154405700419],
              [-3.063019255858471, 54.183202858437404],
              [-3.062588719710478, 54.178544652853091],
              [-3.060803339011921, 54.17703245713583],
              [-3.060010018869756, 54.174349374060256],
              [-3.059083692378997, 54.173051587595928],
              [-3.058644298344734, 54.171922979635966],
              [-3.060077537768738, 54.169622056049072],
              [-3.060269698333097, 54.167792229187782],
              [-3.059825413952665, 54.166651084708114],
              [-3.06085643517779, 54.161988975357502],
              [-3.067534086289219, 54.160326507306394],
              [-3.068724094747505, 54.159555572749987],
              [-3.070402960926257, 54.159214396258179],
              [-3.0733410230022, 54.157467951638637],
              [-3.077975859825488, 54.153966191125228],
              [-3.079375884480574, 54.150512170852174],
              [-3.083765180226536, 54.146919803875619],
              [-3.085273747679936, 54.14143884867007],
              [-3.087737216870057, 54.138184545109468],
              [-3.087635822774397, 54.136243165191495],
              [-3.090705866409625, 54.134773714117841],
              [-3.094838144066433, 54.130936082863506],
              [-3.100629233580507, 54.127992727559665],
              [-3.101687171281767, 54.125243511094197],
              [-3.10437714396565, 54.122765134623222],
              [-3.104017932158656, 54.121512808769729],
              [-3.104650789300544, 54.119995220828294],
              [-3.108065147315595, 54.117678180904086],
              [-3.115427056034127, 54.115453040777396],
              [-3.121050964574987, 54.112399676677597],
              [-3.121242986565214, 54.111126082642954],
              [-3.122218630813927, 54.109529701979241],
              [-3.130107893817831, 54.105573028139027],
              [-3.130313290792974, 54.103509242523437],
              [-3.131352040847774, 54.102340021452655],
              [-3.134480621318347, 54.100697205602231],
              [-3.14092153695084, 54.098060318108679],
              [-3.142083589031453, 54.096997688689832],
              [-3.149077274264407, 54.0935416884695],
              [-3.149621779218987, 54.093934657687434],
              [-3.148580120845958, 54.094308624830617],
              [-3.149114642203131, 54.095280523410985],
              [-3.142585070453729, 54.097383907434114],
              [-3.14477919514308, 54.09948423072565],
              [-3.148671521210805, 54.102539921360574],
              [-3.154088500494046, 54.10380393181174],
              [-3.154642315935583, 54.104635406638643],
              [-3.157323676903734, 54.105961448092273],
              [-3.161325509079325, 54.107298060739581],
              [-3.161971247052401, 54.107854478960043],
              [-3.161707592521945, 54.108052964788619],
              [-3.164927995766713, 54.109112079509949],
              [-3.171535562511176, 54.112569448367253],
              [-3.174871073368227, 54.114756079616171],
              [-3.174267022579697, 54.116495774122946],
              [-3.173411472634452, 54.117295968754675],
              [-3.172521284783698, 54.117249819185737],
              [-3.171680541601215, 54.118088505562582],
              [-3.166869467147749, 54.119744117851319],
              [-3.166450927917734, 54.121075710944346],
              [-3.163463924911487, 54.121937820292381],
              [-3.163086503447096, 54.125397371506864],
              [-3.164030471013634, 54.127574135709274],
              [-3.163668273626919, 54.127864356986592],
              [-3.163990505922104, 54.12865937844191],
              [-3.160964233116982, 54.134048173460954],
              [-3.159636256394336, 54.13494360036669],
              [-3.163659060059623, 54.135287662165219],
              [-3.162796451286806, 54.137823431862948],
              [-3.163955617178901, 54.139967555052635],
              [-3.163136605822509, 54.144225006992777],
              [-3.164350418661885, 54.147054382284821],
              [-3.163465924978884, 54.148871322196648],
              [-3.164709279541595, 54.15312860072828],
              [-3.164762768030036, 54.154487967837866],
              [-3.164446734341575, 54.154521584470167],
              [-3.16290880928906, 54.158872260169439],
              [-3.160207324639375, 54.158872260488081],
              [-3.156512014642801, 54.158930286761972],
              [-3.149437462689734, 54.159731460685045],
              [-3.147907370079419, 54.163045545208504],
              [-3.14732905876644, 54.163123860420619],
              [-3.14701735011587, 54.165254282839669],
              [-3.144696025173451, 54.165501973832455],
              [-3.143053051564385, 54.165021448051675],
              [-3.142598353227064, 54.165251362059465],
              [-3.144045304688803, 54.166454591534219],
              [-3.144921511022396, 54.172335173438235],
              [-3.14644642069296, 54.173243726674485],
              [-3.145276746888867, 54.1736593159115],
              [-3.145696888614614, 54.174725784157765],
              [-3.144837588180237, 54.17600755000776],
              [-3.150174239062571, 54.17740195503788],
              [-3.152362572684372, 54.178905389329579],
              [-3.151888358910779, 54.180200596460971],
              [-3.152203101308677, 54.182096739034982],
              [-3.154748662772521, 54.184144976628687],
              [-3.15828018440348, 54.192562425468779],
              [-3.163087864665594, 54.191729646158869],
              [-3.163767573126837, 54.192355831268102],
              [-3.162683731562757, 54.194313088078083],
              [-3.162200873657505, 54.194857923798438],
              [-3.161406909382432, 54.194913218901938],
              [-3.158859833796976, 54.197830970149006],
              [-3.158729289076317, 54.198534183612175],
              [-3.153343958147846, 54.203095114098886],
              [-3.149529563562877, 54.207288487476021],
              [-3.146602473069616, 54.213704087683489],
              [-3.14762707051367, 54.218467801887847],
              [-3.151955957913997, 54.216352079543263],
              [-3.159435859064472, 54.211667726423585],
              [-3.163483252216578, 54.207930173289377],
              [-3.167645973733339, 54.203143367658996],
              [-3.177289964058487, 54.203604047468225],
              [-3.180160765633505, 54.203363838085323],
              [-3.183481870614003, 54.203699757965687],
              [-3.183981599630538, 54.204126268364696],
              [-3.187468185993296, 54.205203755562046],
              [-3.189235877025851, 54.206589335076501],
              [-3.19214722153776, 54.207166344248293],
              [-3.195182547270109, 54.208370311301003],
              [-3.200521684524654, 54.208219248444202],
              [-3.200144470310458, 54.208800034322863],
              [-3.201081645203776, 54.209762291669662],
              [-3.200843025183164, 54.211526310199787],
              [-3.201359365174252, 54.211670361878646],
              [-3.201816770805745, 54.211104950791871],
              [-3.202580201593316, 54.211256418538973],
              [-3.202242125628888, 54.21149527762956],
              [-3.203211378327011, 54.212343950116072],
              [-3.202674045057641, 54.212849945120908],
              [-3.203088063281429, 54.213693375095019],
              [-3.202530965272948, 54.214295078934519],
              [-3.202654751907905, 54.214560545401852],
              [-3.20497316631277, 54.215130582297505],
              [-3.204927242328795, 54.217555991279049],
              [-3.20299461527092, 54.218549599454384],
              [-3.202442811407615, 54.217821691893967],
              [-3.20270321656858, 54.218496783684081],
              [-3.20200984993836, 54.218808395229459],
              [-3.200734652334011, 54.218678203586009],
              [-3.198882032714114, 54.219950472402545],
              [-3.197718866911471, 54.221677839556321],
              [-3.198107574929141, 54.222177299030456],
              [-3.197258440593738, 54.222780741886609],
              [-3.197501877923046, 54.223613301603642],
              [-3.196662220606837, 54.225022863257088],
              [-3.192604087789814, 54.22839492607433],
              [-3.191922625712117, 54.230299019561805],
              [-3.190223532244385, 54.231175959562435],
              [-3.189667418375598, 54.233442810901323],
              [-3.187562153872384, 54.23311125265694],
              [-3.18575716549568, 54.234636301250632],
              [-3.185731058492949, 54.235063483709077],
              [-3.187181481771612, 54.235616338879488],
              [-3.187427505237073, 54.236062411201416],
              [-3.185234045887188, 54.237368378154493],
              [-3.183626328276533, 54.237217905107464],
              [-3.183236357147508, 54.238174452470588],
              [-3.182092797757251, 54.237515179057141],
              [-3.179843820337669, 54.238551055422683],
              [-3.179738164709172, 54.238988901556141],
              [-3.181955054831051, 54.237742126501551],
              [-3.182673280779725, 54.238283341131009],
              [-3.183240162960837, 54.238307436001421],
              [-3.183749742570309, 54.237991449760834],
              [-3.183830684633189, 54.237281509242344],
              [-3.185050317008078, 54.237542752923609],
              [-3.186287108703212, 54.23722499444122],
              [-3.187884823355251, 54.236119021429182],
              [-3.187278443203225, 54.23530619952232],
              [-3.186171478690482, 54.234959383144606],
              [-3.187272400192705, 54.233706411388077],
              [-3.188939947715206, 54.233967695037883],
              [-3.191409937157713, 54.233111016613478],
              [-3.191153131171404, 54.231812105500019],
              [-3.192400347009802, 54.231384527294772],
              [-3.192611931841689, 54.231539721753435],
              [-3.194497308089998, 54.230314866338006],
              [-3.194784722084583, 54.230439645091195],
              [-3.195080248444545, 54.228986962186916],
              [-3.196975766918292, 54.228755131954124],
              [-3.196972229057518, 54.228526873762249],
              [-3.197564308442169, 54.228789729226932],
              [-3.1979544586732, 54.232779185617403],
              [-3.199567273860065, 54.234907657886218],
              [-3.200048704734998, 54.237698106850907],
              [-3.206022087813434, 54.243012346111762],
              [-3.207117668711085, 54.243218884058358],
              [-3.208645177430428, 54.242609471698955],
              [-3.207531782120797, 54.24378906297305],
              [-3.208400803787695, 54.245326273318028],
              [-3.209562080155822, 54.246464177318963],
              [-3.211134650152078, 54.246551744137541],
              [-3.212686080156924, 54.247540092449626],
              [-3.212511118865237, 54.249318763638229],
              [-3.211118981548506, 54.250468834084153],
              [-3.211714542857843, 54.252145732034968],
              [-3.211894966372329, 54.251807422257983],
              [-3.214239132364646, 54.254849602949633],
              [-3.215064800487661, 54.255000367600786],
              [-3.214059083155365, 54.256558220088571],
              [-3.216392525411209, 54.258022190787905],
              [-3.21911374662014, 54.258835065866322],
              [-3.221178569758475, 54.258628113117801],
              [-3.221773971698152, 54.259191013699628],
              [-3.224082794518408, 54.260172435770727],
              [-3.229039477105317, 54.25989639755327],
              [-3.229095245255519, 54.260119627965679],
              [-3.224150540576182, 54.260489919736294],
              [-3.22400169885268, 54.260958802616919],
              [-3.224399673552948, 54.26117766061563],
              [-3.222586249414593, 54.262568521716211],
              [-3.2215727853786, 54.26266687867988],
              [-3.222524479220102, 54.262712055933626],
              [-3.224193357325901, 54.261364907199621],
              [-3.224679888816107, 54.261359964673339],
              [-3.224279613529017, 54.260597362453552],
              [-3.227837807991574, 54.260426348904865],
              [-3.227825288783078, 54.262071264407318],
              [-3.223914369023573, 54.264799322833049],
              [-3.224195144088676, 54.26645024554697],
              [-3.225655354060616, 54.268881913312732],
              [-3.22508703795858, 54.273224350105764],
              [-3.223991750768885, 54.27554446498214],
              [-3.224952964248353, 54.276521571776769],
              [-3.224464667443982, 54.276939076447235],
              [-3.223629269857096, 54.276838805321162],
              [-3.222677980910071, 54.277596249237156],
              [-3.223013469851274, 54.278759473404961],
              [-3.222129245624235, 54.280331432795265],
              [-3.22267213323092, 54.280199199065741],
              [-3.223129043987732, 54.279031532489185],
              [-3.22284784163545, 54.277679012468944],
              [-3.223759182863727, 54.276921073952458],
              [-3.224824249862599, 54.276997440028538],
              [-3.225269999740134, 54.276440155286515],
              [-3.227805276423753, 54.277667272404933],
              [-3.22831429114534, 54.281405542000826],
              [-3.233430723316819, 54.283615556257971],
              [-3.235078556144793, 54.285758475775197],
              [-3.235224601451066, 54.287815204974272],
              [-3.236347340590664, 54.28949881187954],
              [-3.237761151947389, 54.290280632556808],
              [-3.238125892226459, 54.291069618807008],
              [-3.237226304565628, 54.292887214174641],
              [-3.238164933038862, 54.294009153025897],
              [-3.236933188127299, 54.296588731726771],
              [-3.236994212813517, 54.298214011483324],
              [-3.235382474120965, 54.300067654461579],
              [-3.234481747462399, 54.30216565811935],
              [-3.232664133242236, 54.303824535568886],
              [-3.233045752647312, 54.30481738738446],
              [-3.232328399596872, 54.306269966984061],
              [-3.233908627499501, 54.309771653942938],
              [-3.233364898913817, 54.31024728031344],
              [-3.231742536630421, 54.310634160330231],
              [-3.231137129906908, 54.311207475054623],
              [-3.230920829986958, 54.314671799533777],
              [-3.233537081916833, 54.316413876612621],
              [-3.235383893004983, 54.316738309678207],
              [-3.236994462302909, 54.316002774694958],
              [-3.239505569830206, 54.317202037359756],
              [-3.238460768913787, 54.318808112240553],
              [-3.240349144318298, 54.32117318191009],
              [-3.240653289705577, 54.322747419664495],
              [-3.241871085867294, 54.323208546667416],
              [-3.241775163135159, 54.323648140747011],
              [-3.237943651647955, 54.325495877068604],
              [-3.236371284455942, 54.327106444741936],
              [-3.233602683664509, 54.328520712740847],
              [-3.231192948335672, 54.330783298787289],
              [-3.230191467731368, 54.331286947141564],
              [-3.230109950518163, 54.332162292723687],
              [-3.224565768144732, 54.332781337193076],
              [-3.223486900804263, 54.333635340217342],
              [-3.220479726152161, 54.334761414694405],
              [-3.219298027037455, 54.335939081517296],
              [-3.218405202204133, 54.336017308124582],
              [-3.217570093737602, 54.337267852645034],
              [-3.218501492189314, 54.338232725533565],
              [-3.218052032672246, 54.339314001700757],
              [-3.219815963015784, 54.339769836107195],
              [-3.220083157089851, 54.340481663623528],
              [-3.21951705121037, 54.341519185583827],
              [-3.217596399469322, 54.342372651279099],
              [-3.217516399653746, 54.343256957854997],
              [-3.218562912064293, 54.344988224973982],
              [-3.215960194338947, 54.344787093347279],
              [-3.212375696566055, 54.346612632038621],
              [-3.212106972578646, 54.348354465910248],
              [-3.2100550794642, 54.349888601883265],
              [-3.208943294294795, 54.351637980236731],
              [-3.207083097995633, 54.352949942605782],
              [-3.203323603610176, 54.352984797042396],
              [-3.200146717908158, 54.354320559850443],
              [-3.19459720925662, 54.354343272726979],
              [-3.194593768016224, 54.355232194598429],
              [-3.195971399354902, 54.356200908463798],
              [-3.193305332272467, 54.359833183492555],
              [-3.19157389075805, 54.363442688751718],
              [-3.189728737887982, 54.364194292145697],
              [-3.188911935511096, 54.365275476090545],
              [-3.188587395440326, 54.366615147049266],
              [-3.189185190868529, 54.367168294120368],
              [-3.189133828219243, 54.368267099482217],
              [-3.187491261595248, 54.369753663172311],
              [-3.187071309588515, 54.370936982496467],
              [-3.184922508624564, 54.372758336693799],
              [-3.181995353703076, 54.374179234747984],
              [-3.180962236337484, 54.375354154032777],
              [-3.180615042939584, 54.380368190972305],
              [-3.180061438167361, 54.381948247975181],
              [-3.179080768135385, 54.382222069515983],
              [-3.181525794138239, 54.384844136387386],
              [-3.180070383780793, 54.385694228208621],
              [-3.178343058611966, 54.385978036527185],
              [-3.176711557364106, 54.387351091347057],
              [-3.173257427695647, 54.388483905568734],
              [-3.172517155420504, 54.389040244990028],
              [-3.172571063872313, 54.390444494199201],
              [-3.173094566306406, 54.39049423284235],
              [-3.173298077324734, 54.391959042539398],
              [-3.171571090887515, 54.393778740768241],
              [-3.172447598102102, 54.39453957530192],
              [-3.172731860948779, 54.395595560885859],
              [-3.172082677320454, 54.398054597682965],
              [-3.172074501332089, 54.397281738450637],
              [-3.171254861960311, 54.39732923885154],
              [-3.170754640436133, 54.399722110933361],
              [-3.169081014715337, 54.400075365941724],
              [-3.166653642352874, 54.401335547163178],
              [-3.165261478535524, 54.401622216253948],
              [-3.164407193349423, 54.40322306886813],
              [-3.161157346637631, 54.405483301770026],
              [-3.160583157606905, 54.406590706441385],
              [-3.159322005427687, 54.407160055555785],
              [-3.156260504596979, 54.407538132469654],
              [-3.152777544778877, 54.407119358748517],
              [-3.149804691354309, 54.407914349703866],
              [-3.147974604419477, 54.407759201164794],
              [-3.147065001550959, 54.408124654396389],
              [-3.145449758262291, 54.408159761351094],
              [-3.143658979121283, 54.408807667202538],
              [-3.142809239619348, 54.408724039155331],
              [-3.14219860649184, 54.409149538594349],
              [-3.141082152151847, 54.409226603067665],
              [-3.140400735959819, 54.409656357214878],
              [-3.1369264815334, 54.409663955394215],
              [-3.132935249943653, 54.410703588205962],
              [-3.131454966107722, 54.411502984377108],
              [-3.130808021469139, 54.411342774270523],
              [-3.128906646254186, 54.411736247339491],
              [-3.128384766490338, 54.412202188972813],
              [-3.123839243250405, 54.413274558624181],
              [-3.122752441331521, 54.413708882833745],
              [-3.122815657584989, 54.41416126930914],
              [-3.121815462175031, 54.414100462434149],
              [-3.117851265208356, 54.414860726175263],
              [-3.116253929949138, 54.414658003429025],
              [-3.115257323800779, 54.415013233834834],
              [-3.116257661019963, 54.415532460167597],
              [-3.11552792013831, 54.41646582275591],
              [-3.115462133011969, 54.417788502490403],
              [-3.114140685340094, 54.418272549323895],
              [-3.114275482295399, 54.420571224396653],
              [-3.115072673373034, 54.421153450371371],
              [-3.114930272756599, 54.421982520022326],
              [-3.115287214357742, 54.422356701815836],
              [-3.120531314501911, 54.423683255021167],
              [-3.123992719234288, 54.427498666802244],
              [-3.128731835440977, 54.421524465071911],
              [-3.135974519253955, 54.42187537367991],
              [-3.154137639571692, 54.429702115947684],
              [-3.156964573858907, 54.430240387859065],
              [-3.157940918061327, 54.431297858623346],
              [-3.160341151873338, 54.433914479403015],
              [-3.157910728941928, 54.434763763874109],
              [-3.158074547866999, 54.438384191198885],
              [-3.159528187842423, 54.439190811843233],
              [-3.160133812076642, 54.440760520161206],
              [-3.160153891988567, 54.441034448608491],
              [-3.158610881427488, 54.441761974163605],
              [-3.158588458853832, 54.442169326643345],
              [-3.160597472517639, 54.443088343754766],
              [-3.161112996878424, 54.447028936546388],
              [-3.161856802948072, 54.447372300299207],
              [-3.163049993983997, 54.447029176564882],
              [-3.165205589969212, 54.447015593297074],
              [-3.166275395683053, 54.447758428644789],
              [-3.1665393313112, 54.450532143189641],
              [-3.16617251923252, 54.451246601988508],
              [-3.167136002331105, 54.454102535933089],
              [-3.165507005282725, 54.45572524008761],
              [-3.163760424595088, 54.455981146570323],
              [-3.163684893419901, 54.457287767308443],
              [-3.162956999084945, 54.458110846932364],
              [-3.162293638067256, 54.458272715400994],
              [-3.159163988986939, 54.457847114262918],
              [-3.156436703512171, 54.458613825190497],
              [-3.153821872059627, 54.460146038121685],
              [-3.152879864054553, 54.46164786239067],
              [-3.150030179453627, 54.462739145918647],
              [-3.147535447854698, 54.465493279978105],
              [-3.147142462596261, 54.467108476557414],
              [-3.145554831417411, 54.468435718547866],
              [-3.142469149709688, 54.469498499062212],
              [-3.137739750444652, 54.470276504836413],
              [-3.136440946812075, 54.470738103030868],
              [-3.133592993471493, 54.465569170575293],
              [-3.132209811625189, 54.46453599231554],
              [-3.126163347082055, 54.462792301105537],
              [-3.119422424917435, 54.46022969170204],
              [-3.112569911941533, 54.461482934302396],
              [-3.115701355110381, 54.466575138632301],
              [-3.111330722177055, 54.470974352242244],
              [-3.109475939309356, 54.473995913853656],
              [-3.102889382051362, 54.472325220183038],
              [-3.100676530790714, 54.472784884722792],
              [-3.098933261191764, 54.471997270803641],
              [-3.097750055287582, 54.471895682189832],
              [-3.094874043772358, 54.473641977937632],
              [-3.093813805222932, 54.474795684679897],
              [-3.092223857850578, 54.479064731658788],
              [-3.088804963780746, 54.482828972704979],
              [-3.088262261423402, 54.483956393585466],
              [-3.084379642637108, 54.485568590311118],
              [-3.081702810434543, 54.484918520552476],
              [-3.076211525996587, 54.489357906945877],
              [-3.073378057496749, 54.490275556084626],
              [-3.069975102334555, 54.49043694628336],
              [-3.061982662856457, 54.492515226207644],
              [-3.055749437353488, 54.492133944471867],
              [-3.052231557163724, 54.491135564004416],
              [-3.050798926878257, 54.491257674283453],
              [-3.050799607803261, 54.492066534676802],
              [-3.048301173246438, 54.496270965109524],
              [-3.044474709498374, 54.495772037565573],
              [-3.042287953067341, 54.495815180174439],
              [-3.039568387537555, 54.496340097146927],
              [-3.035872082772799, 54.497625571061789],
              [-3.032956056764458, 54.498021704784755],
              [-3.031438780905026, 54.49792142062693],
              [-3.022866417929116, 54.499667714459441],
              [-3.014831758946482, 54.499836096262598],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000032",
        LAD13CDO: "17UB",
        LAD13NM: "Amber Valley",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.495368127933902, 53.133946037026533],
            [-1.493618324971458, 53.13123470334974],
            [-1.490537377443403, 53.131537983345495],
            [-1.489001456163845, 53.129980791740635],
            [-1.486845298454946, 53.122911506487902],
            [-1.480301215318414, 53.121468255743672],
            [-1.474076781031045, 53.120682259134803],
            [-1.471177378121847, 53.119757030602088],
            [-1.469821044704865, 53.119724933253387],
            [-1.469539977309581, 53.118766343848819],
            [-1.468901676076871, 53.118426408746075],
            [-1.467135514849004, 53.116014837334504],
            [-1.466205014438706, 53.115749088401586],
            [-1.464872613537214, 53.115845585742683],
            [-1.464993782693581, 53.115592637487929],
            [-1.463565517308836, 53.114988438809092],
            [-1.463018260426783, 53.114184145255464],
            [-1.464694327159428, 53.113959770651732],
            [-1.465371654130367, 53.110782468309459],
            [-1.461516343359732, 53.110152044057067],
            [-1.460011404571468, 53.108292575363265],
            [-1.457283086364439, 53.108818622517127],
            [-1.455547899936328, 53.109597250566011],
            [-1.453357081752016, 53.107306677899778],
            [-1.452418234987122, 53.107013814625525],
            [-1.452352326692434, 53.106440005981291],
            [-1.450475992969563, 53.105518069194204],
            [-1.450433151283048, 53.105011784110033],
            [-1.450799849205614, 53.10495324850217],
            [-1.45030431298043, 53.104455661924391],
            [-1.448572649042074, 53.104144723587211],
            [-1.448205581496154, 53.103648621788224],
            [-1.443409167079255, 53.104485663198567],
            [-1.442698368612326, 53.104355592629254],
            [-1.442542504570708, 53.104627233636172],
            [-1.441913022431517, 53.104446300956589],
            [-1.441569919824347, 53.104758413137532],
            [-1.440242740266975, 53.104589479737051],
            [-1.438848753844559, 53.104964058818666],
            [-1.437255537282964, 53.104922382835667],
            [-1.434395597025587, 53.105684596783966],
            [-1.433006158715485, 53.105479330540341],
            [-1.432546827839758, 53.105878057949617],
            [-1.430146508410494, 53.105306574733476],
            [-1.428915049258962, 53.10569439998411],
            [-1.427367816043598, 53.105450556795638],
            [-1.425443017150528, 53.103949090998576],
            [-1.424940680007817, 53.103984420868713],
            [-1.424702685171531, 53.103574265944125],
            [-1.423736427809074, 53.103341270494283],
            [-1.422299852345776, 53.104546045874741],
            [-1.420321457973146, 53.105492876581557],
            [-1.41966499757519, 53.106545902538549],
            [-1.417251480825068, 53.105634305567946],
            [-1.413921572477848, 53.10544627317676],
            [-1.409861110952443, 53.10636199136836],
            [-1.410033860318306, 53.10738311396846],
            [-1.409095801506858, 53.107053955782916],
            [-1.409123609506696, 53.107313879726789],
            [-1.407917889108396, 53.107440930958163],
            [-1.407697912147759, 53.107034427194755],
            [-1.407005525142162, 53.106873671918329],
            [-1.406172977913845, 53.107345947908115],
            [-1.406196400247095, 53.106838178524988],
            [-1.404538981323493, 53.107007888263865],
            [-1.40423697518854, 53.106594675449543],
            [-1.40395447834437, 53.107040022089627],
            [-1.402897930381519, 53.107289122310455],
            [-1.400527506045852, 53.106201214608419],
            [-1.397464301866123, 53.105314708735811],
            [-1.395563744728811, 53.105142374623156],
            [-1.394909140349222, 53.104742629287912],
            [-1.393879775262694, 53.105077184531773],
            [-1.392725171307136, 53.1048636534371],
            [-1.392320763207319, 53.105366780575238],
            [-1.391201231506071, 53.105208247447379],
            [-1.390324243014437, 53.105432984353364],
            [-1.390426865728441, 53.105781389577082],
            [-1.389545484886464, 53.105787662051569],
            [-1.389756881988609, 53.106154603635275],
            [-1.389042201070361, 53.106413422503785],
            [-1.388230386016692, 53.105937325018253],
            [-1.388264352025169, 53.106386057840758],
            [-1.387188734173618, 53.106710429689528],
            [-1.386792849549676, 53.106293093474974],
            [-1.38627330725043, 53.106903477010107],
            [-1.386049539346218, 53.106567927465846],
            [-1.385195300280748, 53.106974324671498],
            [-1.384022864497625, 53.106962872990628],
            [-1.383430962091357, 53.107616017782149],
            [-1.382469154557776, 53.10749867024817],
            [-1.381289486561923, 53.107886272271664],
            [-1.381231995571876, 53.108353409164359],
            [-1.380236528630856, 53.108290702537822],
            [-1.379610696750569, 53.107870347762123],
            [-1.379674500786629, 53.108523292127352],
            [-1.379111202444772, 53.108428667532472],
            [-1.378975853535221, 53.108802809228671],
            [-1.378125227380441, 53.108225762040895],
            [-1.377923551305374, 53.10864090629471],
            [-1.377370904523098, 53.108429470186941],
            [-1.377034616456677, 53.108648844643071],
            [-1.373652452295528, 53.107837363551603],
            [-1.372806537884258, 53.107147938451362],
            [-1.372703161989707, 53.105525750906814],
            [-1.373190743463382, 53.104968293857802],
            [-1.372839396063135, 53.10375830165254],
            [-1.370298916309662, 53.102413601417098],
            [-1.368911388058666, 53.100063680986118],
            [-1.369003272670997, 53.099295594352704],
            [-1.369589761333657, 53.099020036433991],
            [-1.366894197858309, 53.097685227500008],
            [-1.365974451171832, 53.096654669875932],
            [-1.36405011208176, 53.095901004073276],
            [-1.364006398636109, 53.094510145507748],
            [-1.365009193965539, 53.093239933259134],
            [-1.363169660935045, 53.092417487939457],
            [-1.362256148301009, 53.091786952039357],
            [-1.361388130010046, 53.091817357739508],
            [-1.360744891595995, 53.090382828633551],
            [-1.359002829450096, 53.089847596260292],
            [-1.357049973207364, 53.088330479985451],
            [-1.353687275928928, 53.086549494638881],
            [-1.352794785637389, 53.086127548261359],
            [-1.350866846805554, 53.086553028973334],
            [-1.344416474930966, 53.086185087823267],
            [-1.343668673221324, 53.086563907840556],
            [-1.341731207496565, 53.085648898917398],
            [-1.340980211987868, 53.084769196546382],
            [-1.339815769791924, 53.084177548695656],
            [-1.331637690197194, 53.080968619589441],
            [-1.332721148315262, 53.080315787715051],
            [-1.332503454393026, 53.079912749668289],
            [-1.333129490207807, 53.079297800091204],
            [-1.334089104346213, 53.078927422188123],
            [-1.334719343977236, 53.077552899192469],
            [-1.335813944997684, 53.077137416638479],
            [-1.335642596791364, 53.076243831537759],
            [-1.336591851039761, 53.075667522623021],
            [-1.335885546334321, 53.075199741153405],
            [-1.335759446652022, 53.074567095655198],
            [-1.336556611514212, 53.074366586531404],
            [-1.336669330360467, 53.07383055828808],
            [-1.337032698548703, 53.073981803381024],
            [-1.337045071834751, 53.073564772533999],
            [-1.337586151532943, 53.073612730492243],
            [-1.337591112168367, 53.073192062642896],
            [-1.338342278774654, 53.07267935972741],
            [-1.339067793885656, 53.072573721320197],
            [-1.338535093867906, 53.072174338115332],
            [-1.339442455977538, 53.072084090434082],
            [-1.342194176571701, 53.070944212120438],
            [-1.343036910867488, 53.070979429051341],
            [-1.342633328323451, 53.070234691581533],
            [-1.34383704564409, 53.06871046782102],
            [-1.3440661495499, 53.06777595221984],
            [-1.343456751194488, 53.066826926381616],
            [-1.34420618591652, 53.066514636530151],
            [-1.343684633926542, 53.065874426035698],
            [-1.344430160433345, 53.065523459542128],
            [-1.344592804793904, 53.063262665092275],
            [-1.344114788903341, 53.06231256724066],
            [-1.343514607267903, 53.062136665520818],
            [-1.341928050328094, 53.060493662242813],
            [-1.340945057662805, 53.060765092317418],
            [-1.341137120600568, 53.060108143414013],
            [-1.340238222470077, 53.059660894069751],
            [-1.340117827629299, 53.058946481134782],
            [-1.3394431777583, 53.058570584642865],
            [-1.338088164171967, 53.056635763866943],
            [-1.33760374023529, 53.056604303482928],
            [-1.337744131388436, 53.056303046056662],
            [-1.336967549162959, 53.056156692719611],
            [-1.337516600539717, 53.055767818747313],
            [-1.337397202423806, 53.054700130603543],
            [-1.336048716590577, 53.054393274270872],
            [-1.336574432657099, 53.053677065735847],
            [-1.336165141920463, 53.053321506234589],
            [-1.336387938283316, 53.052997337759066],
            [-1.335871027702202, 53.052946811643935],
            [-1.336394918665354, 53.05215418494344],
            [-1.336323628457551, 53.051647692569844],
            [-1.335804758851513, 53.051628617576938],
            [-1.336121195240377, 53.051326546239203],
            [-1.335775397354428, 53.050724134843854],
            [-1.334440451138623, 53.050224965247722],
            [-1.333240241129047, 53.04814981958318],
            [-1.334639510053119, 53.04727580388078],
            [-1.333744992982233, 53.045694982304461],
            [-1.333985861604889, 53.044680543701482],
            [-1.3329643168016, 53.044570547253016],
            [-1.33302656711229, 53.044016258474684],
            [-1.333597747700503, 53.044119238915783],
            [-1.333045362436041, 53.043378125925599],
            [-1.33381906510989, 53.042921309882878],
            [-1.332615340325529, 53.042537915595005],
            [-1.332404569812158, 53.042181657381313],
            [-1.333605223220607, 53.042182992782415],
            [-1.333607954079395, 53.041715566124914],
            [-1.334950814688183, 53.041682626017518],
            [-1.334954589621455, 53.041437240091675],
            [-1.334731553610181, 53.0411986768503],
            [-1.334281090770342, 53.041297736668469],
            [-1.334619773557594, 53.040611951593696],
            [-1.333192040673679, 53.039670874322411],
            [-1.332575158967342, 53.038281270411233],
            [-1.332572173304782, 53.037218721354051],
            [-1.333102552992599, 53.036963702504828],
            [-1.332641908409025, 53.036372322588775],
            [-1.333213746379089, 53.035555705866081],
            [-1.332643651838641, 53.034616726291063],
            [-1.333050237519122, 53.034554282868939],
            [-1.332718936417268, 53.034280050456474],
            [-1.333113087098884, 53.033863359450585],
            [-1.332272699186152, 53.034013262595757],
            [-1.331845363101419, 53.033199131895714],
            [-1.332123831196475, 53.032934612321547],
            [-1.331629790917968, 53.032770930404148],
            [-1.331946158831537, 53.032466172315139],
            [-1.331270419806652, 53.031806159140366],
            [-1.331812244570195, 53.030903082806908],
            [-1.33116044042312, 53.030914702870369],
            [-1.330999576783995, 53.030421185717245],
            [-1.330451395335623, 53.030722839433352],
            [-1.329181603866075, 53.03072917205558],
            [-1.328025619970186, 53.029216943994946],
            [-1.327789499140927, 53.028387696688362],
            [-1.325887958757376, 53.027364745162792],
            [-1.324462297694978, 53.025853649832015],
            [-1.322701411485376, 53.021572822965425],
            [-1.321896421736285, 53.019952859772673],
            [-1.318580091155053, 53.016128737797544],
            [-1.320191015703898, 53.015492520201306],
            [-1.318277262817039, 53.013778094640124],
            [-1.319605927513815, 53.011749619517886],
            [-1.319193649849368, 53.010196603005944],
            [-1.315544185494687, 53.008464095632426],
            [-1.314564145313323, 53.007201745040156],
            [-1.313496239950924, 53.006596898071365],
            [-1.31335862274914, 53.005219837042084],
            [-1.313370677753783, 53.004553796957126],
            [-1.312319680157869, 53.004578290032619],
            [-1.310022936989934, 53.003381994705975],
            [-1.309291632192717, 53.00382542000542],
            [-1.308682765070477, 53.00350635756962],
            [-1.307988743544512, 53.003853805617744],
            [-1.307446817512898, 53.003635806808447],
            [-1.309836054724186, 53.002018126824019],
            [-1.31396616846407, 53.001300395720378],
            [-1.316868981525942, 52.99921808967278],
            [-1.318256479261207, 52.996740496307055],
            [-1.321338151399127, 52.994715738646299],
            [-1.322425603520268, 52.994440571350403],
            [-1.322360454605702, 52.993938594751583],
            [-1.324196899698027, 52.993687452274507],
            [-1.323106478049049, 52.992724785818936],
            [-1.322340048212833, 52.990577361085919],
            [-1.322055752775417, 52.989324423282476],
            [-1.323111774373352, 52.989812266680062],
            [-1.324649420087995, 52.989509076382603],
            [-1.323837209375406, 52.988289101627778],
            [-1.32620086774059, 52.988604556798236],
            [-1.326660611260106, 52.988066001210754],
            [-1.327237560085936, 52.988036904166236],
            [-1.32708508858674, 52.987687254803397],
            [-1.328048311032194, 52.987340317518253],
            [-1.330357885286495, 52.987298507341542],
            [-1.331477191871108, 52.986970399665886],
            [-1.328540112632672, 52.984250756648727],
            [-1.328146067426541, 52.981613750210904],
            [-1.33041841665742, 52.980876851673393],
            [-1.33279621164623, 52.97812328831678],
            [-1.33380577579464, 52.978329410306692],
            [-1.336211199692185, 52.977929348800401],
            [-1.337185588535469, 52.978488527671978],
            [-1.339949662123902, 52.978202755771754],
            [-1.341415134924312, 52.9788895762184],
            [-1.342329826173988, 52.97887396206297],
            [-1.347678483829887, 52.977954141460224],
            [-1.350318753518372, 52.978170853550743],
            [-1.351981265368006, 52.977842822425288],
            [-1.355404404660579, 52.97799446634734],
            [-1.357473460581635, 52.977565183585106],
            [-1.358491814390317, 52.977687542215719],
            [-1.361994358924824, 52.976695080181351],
            [-1.367179993246445, 52.977305294077617],
            [-1.369321642983222, 52.975868485565314],
            [-1.370127039438961, 52.973633506167481],
            [-1.370939041456291, 52.973092150044693],
            [-1.375070627916109, 52.97417827025216],
            [-1.378317223691424, 52.976552291541097],
            [-1.385983335919935, 52.979115422461824],
            [-1.385901300959917, 52.980174841733259],
            [-1.384168594966011, 52.981576309925778],
            [-1.385417200743625, 52.982278542420524],
            [-1.386400409670714, 52.981635487639728],
            [-1.387763971012468, 52.981794433763632],
            [-1.389223034487128, 52.981307521677714],
            [-1.389627415923012, 52.981677261169409],
            [-1.39367257229759, 52.981687163140499],
            [-1.394584494316096, 52.980593312021973],
            [-1.395468526248533, 52.980747930215323],
            [-1.402702637320686, 52.979552040443401],
            [-1.409305543980965, 52.976996088330786],
            [-1.409790090853188, 52.979581127649247],
            [-1.414865828957189, 52.9804628879616],
            [-1.416558727015523, 52.980502666464616],
            [-1.418588378407794, 52.980087409881087],
            [-1.42031826354158, 52.980478798087219],
            [-1.420500010150553, 52.981032526834511],
            [-1.424351488454894, 52.981378439659572],
            [-1.425073029084965, 52.980991793013473],
            [-1.426900884789871, 52.980852290088393],
            [-1.429944830146367, 52.980089341274549],
            [-1.431982757729418, 52.979258589540663],
            [-1.444675971263001, 52.978830407594138],
            [-1.449572675690625, 52.980722944003745],
            [-1.451215743787864, 52.982113090944296],
            [-1.453677884952981, 52.980924352406163],
            [-1.455944762423388, 52.982584279320783],
            [-1.456282025196023, 52.982295466930211],
            [-1.457868122476288, 52.982427657494249],
            [-1.460203596266105, 52.983861284613418],
            [-1.463424258974372, 52.982869953376024],
            [-1.469005164931848, 52.98251564829031],
            [-1.473849561948717, 52.981722744092927],
            [-1.476482408324484, 52.982253941601314],
            [-1.47681989850712, 52.981811355268029],
            [-1.478738532809162, 52.982533528115312],
            [-1.478918208000777, 52.981959899480664],
            [-1.479829640188719, 52.981968383155923],
            [-1.479554935864927, 52.980534288077173],
            [-1.478408731197563, 52.978750285745285],
            [-1.47656025038759, 52.977166341547161],
            [-1.473306644593714, 52.975658855981145],
            [-1.470583719172767, 52.971565621252083],
            [-1.470647839265826, 52.970704730435401],
            [-1.471535505137449, 52.969711762786865],
            [-1.474852359487053, 52.968414912754888],
            [-1.475082815646808, 52.966966852224118],
            [-1.474634063323327, 52.96590952482984],
            [-1.476669206112398, 52.964319297120873],
            [-1.477643546561485, 52.96438920245388],
            [-1.4784967896723, 52.964624871287214],
            [-1.480468757807326, 52.967296137355241],
            [-1.479663526042076, 52.967520945905015],
            [-1.480088417920405, 52.96809632225267],
            [-1.481161491743736, 52.968135167486885],
            [-1.483625779664539, 52.967255050794137],
            [-1.483330752047513, 52.966773740163276],
            [-1.4851881312607, 52.966212770580398],
            [-1.490694496480371, 52.966175349700734],
            [-1.49196922167058, 52.965901233772421],
            [-1.492269069145938, 52.966360070163347],
            [-1.495409006269601, 52.965861041930516],
            [-1.495403931062946, 52.96501782291552],
            [-1.496475197549575, 52.964950448492743],
            [-1.496050745162052, 52.964065001294585],
            [-1.496576515967387, 52.963918005291163],
            [-1.496435920339678, 52.963204557453146],
            [-1.494415398159028, 52.960160292722307],
            [-1.49531725261353, 52.960057150242001],
            [-1.496869360690943, 52.958567903472854],
            [-1.497979347306081, 52.956813383590998],
            [-1.497804338932825, 52.955986525748287],
            [-1.503430753512199, 52.955620014826707],
            [-1.506612415591396, 52.954186425334676],
            [-1.507647709587359, 52.954312521309532],
            [-1.507607890397278, 52.951264075748156],
            [-1.505907582191079, 52.948309421592143],
            [-1.508337759138735, 52.947799910373675],
            [-1.507924635550006, 52.946958597740235],
            [-1.510673198365834, 52.946316414784363],
            [-1.509153364251336, 52.945691684795825],
            [-1.508842747227517, 52.94473483565703],
            [-1.507888294966493, 52.944389294727159],
            [-1.5082285324225, 52.944174958128343],
            [-1.507773981933486, 52.943709227503959],
            [-1.50806810385897, 52.942442947502123],
            [-1.507510317334897, 52.941372704679551],
            [-1.507819225504524, 52.940901144170475],
            [-1.506609543017865, 52.939847074337862],
            [-1.50803262945423, 52.93743393713563],
            [-1.510976785265006, 52.938130171386668],
            [-1.511888863527672, 52.935221369697608],
            [-1.519877673478161, 52.933278036829932],
            [-1.52126537991008, 52.932367617251579],
            [-1.525461574099981, 52.932975038686791],
            [-1.527010640262184, 52.932399599918192],
            [-1.537802270067343, 52.924887386747308],
            [-1.539650079252355, 52.924431603693179],
            [-1.544073337021888, 52.924189754320309],
            [-1.545733204915592, 52.926618738897623],
            [-1.547345117812947, 52.926673428984188],
            [-1.549405913711553, 52.92790920197924],
            [-1.54946754917705, 52.928563860160608],
            [-1.551100365989388, 52.928906237499959],
            [-1.554605649661412, 52.930454799715719],
            [-1.558566922028831, 52.932978496914465],
            [-1.562076146689599, 52.933900294671623],
            [-1.563491890716248, 52.933962132984455],
            [-1.567169838908927, 52.93305147378684],
            [-1.570106447386959, 52.935776905293515],
            [-1.57206599217172, 52.935620367421869],
            [-1.574271388271501, 52.935880883662826],
            [-1.578068164966443, 52.934983796918274],
            [-1.579802601772271, 52.935942807208882],
            [-1.582936244079291, 52.936692761465451],
            [-1.584490126851136, 52.936480659385445],
            [-1.585356791076718, 52.93578161811616],
            [-1.588615048232451, 52.935009065281669],
            [-1.590991772242036, 52.936042046710909],
            [-1.591961101683439, 52.93612178593834],
            [-1.592731929163294, 52.936902004769088],
            [-1.594243116137541, 52.937592156868888],
            [-1.596351041915125, 52.937318863635909],
            [-1.597869096563941, 52.937757291847497],
            [-1.601651394456475, 52.937753840680585],
            [-1.602894668787455, 52.936682876211151],
            [-1.603157957516697, 52.937015460979737],
            [-1.604058653695865, 52.93679462915923],
            [-1.604030744045834, 52.937407607755922],
            [-1.607256369034018, 52.939724962504435],
            [-1.610058652971305, 52.940837172391184],
            [-1.608835273827766, 52.941853441622513],
            [-1.610099441204769, 52.942591120454153],
            [-1.607324805088532, 52.94433131097901],
            [-1.60830810557736, 52.945030325744895],
            [-1.606564397526378, 52.945641236557179],
            [-1.605804713623142, 52.94637494508958],
            [-1.607141507434426, 52.947154246190259],
            [-1.606366431154876, 52.947778237826526],
            [-1.60534008272979, 52.947881809230481],
            [-1.604861058828531, 52.948677569756121],
            [-1.603254174070561, 52.949278099229595],
            [-1.602963028883445, 52.950843063594959],
            [-1.603841756925887, 52.952733745930779],
            [-1.604826544692966, 52.953774386417408],
            [-1.608087573879933, 52.955112894440191],
            [-1.607281018403331, 52.956240187840855],
            [-1.608006741911162, 52.956480797865957],
            [-1.607000037553069, 52.957351234701946],
            [-1.606562606825611, 52.957163710378659],
            [-1.605032145156371, 52.958167236241771],
            [-1.599398771134184, 52.962570226069822],
            [-1.596305542279387, 52.962045581552523],
            [-1.588750285618091, 52.962033184216459],
            [-1.585612797336325, 52.961016394103218],
            [-1.584692810518313, 52.962256403907652],
            [-1.583110936976034, 52.962341662060858],
            [-1.581234184106923, 52.961049601373297],
            [-1.580650563224612, 52.961824219770278],
            [-1.580772701129418, 52.963049890155865],
            [-1.577609926992744, 52.963723684680893],
            [-1.577734306410761, 52.965322421167897],
            [-1.57692564610535, 52.9654966366206],
            [-1.576471978816127, 52.966214164854982],
            [-1.573379060315097, 52.966416158240939],
            [-1.573096694811439, 52.967119006058923],
            [-1.571047626559489, 52.966548905377401],
            [-1.571901412768627, 52.967539004463369],
            [-1.572190109607033, 52.969214746150463],
            [-1.573555091352212, 52.969863279736771],
            [-1.572282999310181, 52.97081696960295],
            [-1.572758282033065, 52.971091052665017],
            [-1.571441716957038, 52.973977267625813],
            [-1.572673772187265, 52.974226207989965],
            [-1.573114954202286, 52.974035421558135],
            [-1.57369485428493, 52.974729674998194],
            [-1.574672022274511, 52.974702609217267],
            [-1.574344750659429, 52.975122136507132],
            [-1.576583522739074, 52.975679370294827],
            [-1.57701249201539, 52.976739832044046],
            [-1.577952988579967, 52.977577376217603],
            [-1.579014519266266, 52.977610801997379],
            [-1.580069075692951, 52.9782105173094],
            [-1.580757421772225, 52.978180583715734],
            [-1.581201689290298, 52.978903986298803],
            [-1.582913936652549, 52.979727125386191],
            [-1.584063375709334, 52.979762612001601],
            [-1.584215244940253, 52.980077766955397],
            [-1.584725616060917, 52.979817063175972],
            [-1.586299682904961, 52.980928225982943],
            [-1.587351219576207, 52.981081998466095],
            [-1.587454516546432, 52.981495862507899],
            [-1.588741195221107, 52.981671113088133],
            [-1.589160289472711, 52.981454122149252],
            [-1.591011408098952, 52.982108625383496],
            [-1.591399995793171, 52.981967031438039],
            [-1.591452744971486, 52.982375324720486],
            [-1.59186430825174, 52.982482810482146],
            [-1.592130351340409, 52.982226630186808],
            [-1.59279248769432, 52.982772748121029],
            [-1.593693950569662, 52.982736277389506],
            [-1.593927739880007, 52.982424249575487],
            [-1.595042381845881, 52.983004259815807],
            [-1.597338904270241, 52.982548208996448],
            [-1.598508313747137, 52.983009713896024],
            [-1.598659783303351, 52.983380582056114],
            [-1.601266902851051, 52.984293666430887],
            [-1.602168047054481, 52.9857547388198],
            [-1.601581369266009, 52.985732100434333],
            [-1.60183351194895, 52.988281395048602],
            [-1.599604848343225, 52.995551602211542],
            [-1.600108332829062, 52.997337657442792],
            [-1.599270037514307, 52.99840545432869],
            [-1.595101086173751, 52.999675003383665],
            [-1.591972416288776, 53.001054949746532],
            [-1.588954144966152, 53.001144342442672],
            [-1.585721518084334, 53.000586587015562],
            [-1.582332625723064, 53.000481252307921],
            [-1.580384945741309, 53.001728397409799],
            [-1.577664064460064, 53.002180817871007],
            [-1.576802612278253, 53.002044716701405],
            [-1.575284226014188, 53.004622810791574],
            [-1.57611718277222, 53.00478219331324],
            [-1.576930228976453, 53.005454783874008],
            [-1.577385869550032, 53.008088446877565],
            [-1.57929390159013, 53.008966262837141],
            [-1.579736854946344, 53.01108299037142],
            [-1.579279764316253, 53.012107943453351],
            [-1.578232008074333, 53.011797702580935],
            [-1.578758517414243, 53.014668923542835],
            [-1.571279133200666, 53.013593173646143],
            [-1.569401999167648, 53.012451957653319],
            [-1.56698753101307, 53.012888163033352],
            [-1.566750085931679, 53.013678349727769],
            [-1.574364696088099, 53.015241184537807],
            [-1.575282337328062, 53.015601334506137],
            [-1.575893915967263, 53.016317258597113],
            [-1.576114728577303, 53.017666426190679],
            [-1.572329030054968, 53.019569380996742],
            [-1.571251968235178, 53.021000182517852],
            [-1.567848620871966, 53.021439131438321],
            [-1.566212782148514, 53.02222692693509],
            [-1.566396839487809, 53.022455923346676],
            [-1.568351101747168, 53.021729508275982],
            [-1.569008721809164, 53.021861336010716],
            [-1.570504215476459, 53.022590372788265],
            [-1.571893440418636, 53.023818807779101],
            [-1.574318633512712, 53.023713343536777],
            [-1.574870210236429, 53.024324782482232],
            [-1.577435873307427, 53.023882661507017],
            [-1.578141368568836, 53.024462270456183],
            [-1.578563226712843, 53.023550462755182],
            [-1.57734271611767, 53.022277759155777],
            [-1.581920031745809, 53.021608050246805],
            [-1.584882199374571, 53.020368920210082],
            [-1.587071971289993, 53.021164890242673],
            [-1.585361563232843, 53.023238144690339],
            [-1.586100439337998, 53.023611071200001],
            [-1.587179540841246, 53.023484475590614],
            [-1.588707970109864, 53.024080356934306],
            [-1.58909329218012, 53.024637219811304],
            [-1.58935215806965, 53.024381022363734],
            [-1.59043730838971, 53.024876470027749],
            [-1.590745593323879, 53.025384520706389],
            [-1.59142306486061, 53.025163916305829],
            [-1.591706179536852, 53.025654798588469],
            [-1.593848666236026, 53.026129566398858],
            [-1.594309029893676, 53.026960838258475],
            [-1.596253158775947, 53.027771081750039],
            [-1.596459798162164, 53.028795650397662],
            [-1.597202675626936, 53.029079527982553],
            [-1.597291949925544, 53.029740534833138],
            [-1.598373478554028, 53.030168478220453],
            [-1.598606617561973, 53.030593553866247],
            [-1.597241062802498, 53.031682924189596],
            [-1.596673303191591, 53.032763298651908],
            [-1.593377488733717, 53.034382722246228],
            [-1.593673553966765, 53.03509118119549],
            [-1.592726561533095, 53.036204400173318],
            [-1.591310928714517, 53.036088082626819],
            [-1.590013930029693, 53.036515102900438],
            [-1.589215879235353, 53.037792409633788],
            [-1.585922106896595, 53.039284886046943],
            [-1.584106041763965, 53.040632323918423],
            [-1.582767128136792, 53.041053725223122],
            [-1.581721957415948, 53.042097295374958],
            [-1.580975938274424, 53.042278949693163],
            [-1.583721740504727, 53.044299458971757],
            [-1.584922512966508, 53.044449276156278],
            [-1.58626642499121, 53.045540743847702],
            [-1.591084570959584, 53.047252745934529],
            [-1.591764397259752, 53.047924771081007],
            [-1.593293236901963, 53.048575424920038],
            [-1.593765290684311, 53.050091708645461],
            [-1.592850004643594, 53.050813107544904],
            [-1.592970616619821, 53.051470626755673],
            [-1.592196043019332, 53.052136768780478],
            [-1.59144215279862, 53.054715866894767],
            [-1.588401659719767, 53.057371574422682],
            [-1.588713255810243, 53.057553332924584],
            [-1.588169367255645, 53.058418904938819],
            [-1.585735832121971, 53.059506238846531],
            [-1.58316097827446, 53.059917045102509],
            [-1.581787371617113, 53.058155750191659],
            [-1.580772737114816, 53.057851944568135],
            [-1.578767235063125, 53.057871833583626],
            [-1.578294387854151, 53.058011289298449],
            [-1.578923875702074, 53.059558750716413],
            [-1.578293267184834, 53.060113845584624],
            [-1.577849385111275, 53.060034965996024],
            [-1.576543816432433, 53.06003122877479],
            [-1.575540448522871, 53.061010169082039],
            [-1.574243528251796, 53.061337237844697],
            [-1.57257841943346, 53.061322282927854],
            [-1.571367555911892, 53.061695473885699],
            [-1.570712802679949, 53.062414942904262],
            [-1.569889610470766, 53.062364329638299],
            [-1.564653536669673, 53.065616447373593],
            [-1.563047696199695, 53.065922488214454],
            [-1.560848689408596, 53.068351352675926],
            [-1.558491099115598, 53.069478860679929],
            [-1.555989025850244, 53.069985533174332],
            [-1.553275467939841, 53.069000062573949],
            [-1.55317853359269, 53.069425783844466],
            [-1.551519420809042, 53.070528805529257],
            [-1.551570133283521, 53.070963171642802],
            [-1.549070025197514, 53.071837361719425],
            [-1.547502910326477, 53.073827902796623],
            [-1.549715657345557, 53.076908774263345],
            [-1.545326199239645, 53.078554184325419],
            [-1.54258117765268, 53.078538278216648],
            [-1.542876212356502, 53.079293598614854],
            [-1.543513694152501, 53.079570212267434],
            [-1.543483081963821, 53.080206524742771],
            [-1.542957562148528, 53.080493059745301],
            [-1.542291056780835, 53.080418586857945],
            [-1.53891809447695, 53.081878891740317],
            [-1.538126488095153, 53.083589150255683],
            [-1.538299974091225, 53.084258613336587],
            [-1.53482639526723, 53.085871226892202],
            [-1.533605663310846, 53.086925370119431],
            [-1.530732040077787, 53.087565792234358],
            [-1.530000140778013, 53.087318402041724],
            [-1.526707115736655, 53.087664924867781],
            [-1.52301647501987, 53.08669645465185],
            [-1.521433510501874, 53.086592122461873],
            [-1.520461987588489, 53.086148646342409],
            [-1.519347379020016, 53.086221459264053],
            [-1.516180908905971, 53.090462287808016],
            [-1.514763100705583, 53.091594541393675],
            [-1.512373763469846, 53.092668869386223],
            [-1.512061481187185, 53.093346268397831],
            [-1.513772169061241, 53.093665187104456],
            [-1.514411438601358, 53.095112345360029],
            [-1.517457262247715, 53.096500058381054],
            [-1.520498361865546, 53.096583354811912],
            [-1.523409054349187, 53.095629608020666],
            [-1.527319410993772, 53.094742685554706],
            [-1.528856733364043, 53.09495282966305],
            [-1.529827855215502, 53.095459158251309],
            [-1.529904120596767, 53.096820409356305],
            [-1.528686230597567, 53.098121712384035],
            [-1.529175269045108, 53.099017163013364],
            [-1.531107043401582, 53.10046303915427],
            [-1.530419638758771, 53.100635618369473],
            [-1.530226314422303, 53.101107682410543],
            [-1.527619583036104, 53.101698742987431],
            [-1.526684160895763, 53.102692818851033],
            [-1.526298246096083, 53.10777731158035],
            [-1.525669448266959, 53.10831325549831],
            [-1.526355661895866, 53.109078260472188],
            [-1.526210050994166, 53.110774820177369],
            [-1.525113519216891, 53.111455421666214],
            [-1.523695659198115, 53.111739209080945],
            [-1.52243875632345, 53.113270410408482],
            [-1.522513097217642, 53.113842413812343],
            [-1.5206087456284, 53.114471191875523],
            [-1.520801962604829, 53.116309336495753],
            [-1.522744018450543, 53.11781561143038],
            [-1.52328464230697, 53.118784101247286],
            [-1.522890266933805, 53.119721880192024],
            [-1.523513197547549, 53.120411138221435],
            [-1.522012882037698, 53.120961551180258],
            [-1.520193281612416, 53.124015912254322],
            [-1.515969619444084, 53.125272576978283],
            [-1.514372761353464, 53.126542525298042],
            [-1.512973546382325, 53.126712999121345],
            [-1.510496694134523, 53.127816592762542],
            [-1.510032898329809, 53.127335569230887],
            [-1.505497304568352, 53.128110563676486],
            [-1.500146780976827, 53.128263521564648],
            [-1.500474642751265, 53.129117059964791],
            [-1.499432304897407, 53.130846675113482],
            [-1.498853419420042, 53.131145375024559],
            [-1.496046931275133, 53.130987913899759],
            [-1.497584687396284, 53.133309990441752],
            [-1.495368127933902, 53.133946037026533],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000033",
        LAD13CDO: "17UC",
        LAD13NM: "Bolsover",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.291590557317941, 53.309203043908418],
            [-1.286609840270146, 53.308993595806633],
            [-1.284482960933181, 53.309636152921378],
            [-1.28201135491068, 53.309441530399091],
            [-1.276429502335227, 53.309192083371713],
            [-1.274109509455454, 53.310503856732254],
            [-1.273597655480398, 53.310418943248997],
            [-1.271548681933341, 53.309290032291834],
            [-1.269640302177272, 53.309075218773053],
            [-1.269448314451646, 53.307837170460353],
            [-1.267812858247381, 53.305796553082743],
            [-1.264147851045966, 53.305377598392909],
            [-1.261314180197669, 53.303920965864471],
            [-1.258176520839968, 53.303391829887929],
            [-1.255016015895274, 53.302368976734932],
            [-1.25429480127633, 53.301733449525905],
            [-1.251033213542568, 53.3018694295532],
            [-1.247883019377168, 53.302667607526445],
            [-1.246166654253662, 53.302394300280994],
            [-1.244944414069406, 53.301671952883432],
            [-1.243330600665582, 53.301627572297022],
            [-1.242103872117697, 53.302095301378394],
            [-1.238735585024946, 53.304430757451549],
            [-1.23682159658274, 53.306319689799636],
            [-1.23230573219003, 53.308255702603951],
            [-1.230688807347958, 53.308538328603568],
            [-1.221820202668669, 53.30517469505947],
            [-1.219879190132189, 53.304056381782075],
            [-1.218737666256116, 53.304030935499028],
            [-1.212502281074515, 53.305429976423028],
            [-1.209809476469717, 53.305298015398925],
            [-1.205709192231988, 53.304268525059754],
            [-1.203240364598348, 53.304201727664989],
            [-1.20210922360524, 53.306035106191921],
            [-1.203028385999607, 53.30720531719335],
            [-1.199744007805193, 53.311418926888443],
            [-1.198287420924235, 53.310815879928342],
            [-1.194960138524439, 53.30857231129248],
            [-1.193675588587744, 53.308246328496736],
            [-1.190633985213601, 53.306552893193285],
            [-1.187221061147665, 53.303957954542284],
            [-1.185552971791512, 53.304635135028377],
            [-1.182142139208122, 53.305332720486469],
            [-1.180957474855742, 53.30622798551402],
            [-1.180477013330516, 53.305848049244766],
            [-1.179428237536794, 53.306384673900951],
            [-1.178993629751406, 53.305883695953767],
            [-1.176676973577551, 53.305158504723586],
            [-1.175721180001073, 53.303280403151724],
            [-1.174176858625825, 53.302511954831871],
            [-1.174853474606499, 53.299093635011175],
            [-1.174326277364274, 53.298343004069089],
            [-1.173779022849295, 53.297466386064293],
            [-1.173819028220969, 53.296170452286994],
            [-1.171564018257854, 53.294616801299],
            [-1.176397925905255, 53.289629020739902],
            [-1.178700467058288, 53.2888556605742],
            [-1.180118414944024, 53.287113459564225],
            [-1.17830519387594, 53.286235344928755],
            [-1.177532920877616, 53.284269523766675],
            [-1.17793744431728, 53.283746455696487],
            [-1.176967381261696, 53.28349167090748],
            [-1.176004631957117, 53.282701184396899],
            [-1.175764275828633, 53.281628934125614],
            [-1.173991923608479, 53.281214867029149],
            [-1.172079661352129, 53.28004832175251],
            [-1.171017841973476, 53.27981352591474],
            [-1.16927876325755, 53.278540270266234],
            [-1.166488843091692, 53.277634492574862],
            [-1.168335749857642, 53.276668479728613],
            [-1.170719974032244, 53.276140344275248],
            [-1.173300241237374, 53.27407550010274],
            [-1.17733917636898, 53.273657568236104],
            [-1.178247835324026, 53.273192807221797],
            [-1.181543152869738, 53.270010880396818],
            [-1.181562495680906, 53.269468975545379],
            [-1.180206288421986, 53.268408846252939],
            [-1.175553969880134, 53.266521446475679],
            [-1.174628237130129, 53.265776146370456],
            [-1.174798824943203, 53.265092362976546],
            [-1.177286094964945, 53.265172468365485],
            [-1.178107868285827, 53.264459910803531],
            [-1.179181019921916, 53.264319880839189],
            [-1.180595461472071, 53.26467838043142],
            [-1.181772876333994, 53.264574100570812],
            [-1.182981100908481, 53.264977000931502],
            [-1.185343649192905, 53.264362131501358],
            [-1.187748178654285, 53.264534939348955],
            [-1.191354079192164, 53.264127967922533],
            [-1.193879183400031, 53.263230877738515],
            [-1.196089030712684, 53.26339051583755],
            [-1.198194787849081, 53.262302636977189],
            [-1.201115185782491, 53.261739745355491],
            [-1.202618782752569, 53.261098103470452],
            [-1.203755054058481, 53.258922262766731],
            [-1.202691603256047, 53.257262077864141],
            [-1.204505759302849, 53.257161829113087],
            [-1.20580599386997, 53.256724639114324],
            [-1.205832283191513, 53.256195360951196],
            [-1.207055114218784, 53.255158973225164],
            [-1.207837677027186, 53.253204567007835],
            [-1.210350458172549, 53.251213083273981],
            [-1.209045233112185, 53.250794534347264],
            [-1.209394955229133, 53.250279085396208],
            [-1.213028175901287, 53.247652252177787],
            [-1.212461013510652, 53.245565749933789],
            [-1.209974119183554, 53.240965791151353],
            [-1.208731962630099, 53.240882047297575],
            [-1.207158058670938, 53.239208620291727],
            [-1.205188672289936, 53.238670549563125],
            [-1.205786212356089, 53.237796301115317],
            [-1.205092258399007, 53.236595234716454],
            [-1.201998531581988, 53.235535436198901],
            [-1.203484867500953, 53.233028439133356],
            [-1.203258839003836, 53.231080798190433],
            [-1.20576342650657, 53.230525803867444],
            [-1.206208822822705, 53.227178547271315],
            [-1.207027892080465, 53.226952079749402],
            [-1.207483113173779, 53.225824282371306],
            [-1.207955923673345, 53.225771692046138],
            [-1.208305337789593, 53.224856229306489],
            [-1.208993914896884, 53.224392469374266],
            [-1.209426092587141, 53.217677804172766],
            [-1.21105909155941, 53.21577035333484],
            [-1.209028896271342, 53.21257206963039],
            [-1.208459307965336, 53.212602447555327],
            [-1.208200510755611, 53.211694629289717],
            [-1.205603513268995, 53.210506073651288],
            [-1.205543957563353, 53.209435076329314],
            [-1.20474060598805, 53.208263834647717],
            [-1.202843432444235, 53.208350042939053],
            [-1.201816621619869, 53.207042450083826],
            [-1.202194314091191, 53.20677980018332],
            [-1.200972984923556, 53.203939155019285],
            [-1.201439697418526, 53.203154837378349],
            [-1.20159676694866, 53.200099594421204],
            [-1.201392032408798, 53.198630301741694],
            [-1.197317533564658, 53.195251791301068],
            [-1.196738726841586, 53.19354445422988],
            [-1.195818168598365, 53.192426292466742],
            [-1.195057226176138, 53.189340583164132],
            [-1.195307152874567, 53.187022177151817],
            [-1.196938007647157, 53.184799378765561],
            [-1.199300927763886, 53.183353645027012],
            [-1.198803399169913, 53.183070739226103],
            [-1.199273121134721, 53.182757478722245],
            [-1.200846515911306, 53.182721296436618],
            [-1.201193463461407, 53.182410800688338],
            [-1.204293806851366, 53.182339852417194],
            [-1.209402779403371, 53.18110279197456],
            [-1.212265522223116, 53.181008486134104],
            [-1.212809932421836, 53.180613865105229],
            [-1.213424357421273, 53.180890292643674],
            [-1.215662906957837, 53.180888866278416],
            [-1.218399754069063, 53.178910362651742],
            [-1.219036752153608, 53.179097916983991],
            [-1.219576877166709, 53.178769756606663],
            [-1.220467195798244, 53.178767493577553],
            [-1.22104105273573, 53.179137103374792],
            [-1.220949717245235, 53.179553601716165],
            [-1.222596983378692, 53.18014146055377],
            [-1.226735354431816, 53.178667201445705],
            [-1.229126387111304, 53.178833709374544],
            [-1.229915345008682, 53.178475649762937],
            [-1.231652717060574, 53.178128198136825],
            [-1.234014233440349, 53.17843914250065],
            [-1.234564141735719, 53.178226936990967],
            [-1.236204368911487, 53.178460390995319],
            [-1.236184360146264, 53.17882881654041],
            [-1.237420478220153, 53.179154947548106],
            [-1.237803309644231, 53.178913791635523],
            [-1.238767276164995, 53.179061981054424],
            [-1.241690957402674, 53.178130471364192],
            [-1.243334261244929, 53.1770001980803],
            [-1.243673102720418, 53.176279624182463],
            [-1.245761308242519, 53.17536698013194],
            [-1.245272080390155, 53.175195786231534],
            [-1.244608290980844, 53.173293078586553],
            [-1.246310773448593, 53.172100215337949],
            [-1.248236859139443, 53.171632362857913],
            [-1.24949161564264, 53.170883388164199],
            [-1.249072645773716, 53.16994588004308],
            [-1.249674119076614, 53.16925391078103],
            [-1.24945815461791, 53.168942425959081],
            [-1.25023256459447, 53.168798081675718],
            [-1.249849709356644, 53.16853498751582],
            [-1.251694110289449, 53.167247655927433],
            [-1.252905510359516, 53.166917266177926],
            [-1.252368649071539, 53.166219938493953],
            [-1.253397888657513, 53.165868624621638],
            [-1.25317393839663, 53.165499565942611],
            [-1.253667591464093, 53.165583561075444],
            [-1.253755697233677, 53.165160725035818],
            [-1.254679645597971, 53.164754805905382],
            [-1.259927381544873, 53.164461209709458],
            [-1.260285993020491, 53.164743897155603],
            [-1.260344688021385, 53.165332150169021],
            [-1.261780034569952, 53.165891185528956],
            [-1.262348007206664, 53.166614731471782],
            [-1.26513223161031, 53.166937564679159],
            [-1.265778342255102, 53.167469208716128],
            [-1.26869692608138, 53.167900655500198],
            [-1.268980959255879, 53.168175667364359],
            [-1.269272505931393, 53.1679203669134],
            [-1.271163900854402, 53.167973300377056],
            [-1.271303305064525, 53.168221353537653],
            [-1.272468839416883, 53.167853629359712],
            [-1.273434284107599, 53.168172340993237],
            [-1.273737906790437, 53.167909013113686],
            [-1.274914340912293, 53.168135511717772],
            [-1.27657470162304, 53.167339280104592],
            [-1.281991550570774, 53.1674088979942],
            [-1.284699489549888, 53.168733098736951],
            [-1.285399326977944, 53.169648792637666],
            [-1.287911925590847, 53.170587913117075],
            [-1.288759569850054, 53.171437051638854],
            [-1.296098935663745, 53.171164214050741],
            [-1.297522084718968, 53.168845335146969],
            [-1.298923097578802, 53.167783883406265],
            [-1.300264477170472, 53.168085711115388],
            [-1.300837280622218, 53.165791459843504],
            [-1.300713624103958, 53.164196067734437],
            [-1.30203916422798, 53.163808318855196],
            [-1.302841406426446, 53.163960436489262],
            [-1.304506059787494, 53.165447972135276],
            [-1.308064943343878, 53.163376927715802],
            [-1.309304001120004, 53.163153096458188],
            [-1.311667053494358, 53.163351046870382],
            [-1.312897249573944, 53.16358736885045],
            [-1.313598107877628, 53.164190081901943],
            [-1.314137144029227, 53.164154532610489],
            [-1.313923230718427, 53.164437356570801],
            [-1.315214047494714, 53.164720746770357],
            [-1.315506506299815, 53.16523570431459],
            [-1.316738350118048, 53.165278730349343],
            [-1.3190826929351, 53.163170723758533],
            [-1.323448532825355, 53.16232364977288],
            [-1.323458243440095, 53.159517309442002],
            [-1.325618170618048, 53.15758880743364],
            [-1.324949884025069, 53.155286510063341],
            [-1.323422380057013, 53.154281853351137],
            [-1.322629222388389, 53.152513684067721],
            [-1.321672265178063, 53.144251737834495],
            [-1.320261260191129, 53.142525874195741],
            [-1.318816942822886, 53.139699533270125],
            [-1.315140532110114, 53.136061227402664],
            [-1.31498183300237, 53.135795135386878],
            [-1.317694637359762, 53.135052021140467],
            [-1.319284965279023, 53.133921296858368],
            [-1.320182909420239, 53.132144777420564],
            [-1.321782079982974, 53.130631132726819],
            [-1.320158467688797, 53.128388974124107],
            [-1.318714032502159, 53.128152397109211],
            [-1.317858213185265, 53.126986101246494],
            [-1.314805203841743, 53.125659757752132],
            [-1.314936889123954, 53.125366569911741],
            [-1.314249493313064, 53.124895178452029],
            [-1.310996137155775, 53.118450053002604],
            [-1.311058769150132, 53.116868322036289],
            [-1.312294479110958, 53.1163010557867],
            [-1.309675252340133, 53.113134299664857],
            [-1.30969090821663, 53.111689830005027],
            [-1.310664264393107, 53.11023742344311],
            [-1.312056370667387, 53.109125421544178],
            [-1.311834819865795, 53.108686368515912],
            [-1.308803162745781, 53.107704272412086],
            [-1.303610679442713, 53.10317583209951],
            [-1.302520344259519, 53.103913756053544],
            [-1.301421695393244, 53.104148226544005],
            [-1.300700335750338, 53.101655783838204],
            [-1.301580300630077, 53.100994848701212],
            [-1.302155973676682, 53.101046765328313],
            [-1.304026126008159, 53.100385318338013],
            [-1.304982553994809, 53.100535630435147],
            [-1.30552832795049, 53.100125311620502],
            [-1.305994171136498, 53.100319497338532],
            [-1.30739719862846, 53.099444927010701],
            [-1.309253447857711, 53.09675085977014],
            [-1.308493236209244, 53.095823256658221],
            [-1.307088810131172, 53.095398892427816],
            [-1.306913786590166, 53.094766831812983],
            [-1.308155144360666, 53.094928665096639],
            [-1.309133011689658, 53.0944794890667],
            [-1.307395682280755, 53.092841451519284],
            [-1.30739189589794, 53.092426128189821],
            [-1.306104164573863, 53.091902650321003],
            [-1.305361087858055, 53.09075489029],
            [-1.30376740458038, 53.089671371458017],
            [-1.304108697958877, 53.089433354674703],
            [-1.303457293495456, 53.089457412306523],
            [-1.302563183277723, 53.088515502338957],
            [-1.30323788992262, 53.088064598309224],
            [-1.30588579705308, 53.086818877414053],
            [-1.30777538972467, 53.086299514583963],
            [-1.322308514452223, 53.084885519069225],
            [-1.326140667384053, 53.083857329892886],
            [-1.329681226073687, 53.082367128754022],
            [-1.331538108553206, 53.081325831104223],
            [-1.331637690197194, 53.080968619589441],
            [-1.339815769791924, 53.084177548695656],
            [-1.340980211987868, 53.084769196546382],
            [-1.341731207496565, 53.085648898917398],
            [-1.343668673221324, 53.086563907840556],
            [-1.344416474930966, 53.086185087823267],
            [-1.350866846805554, 53.086553028973334],
            [-1.352794785637389, 53.086127548261359],
            [-1.353687275928928, 53.086549494638881],
            [-1.357049973207364, 53.088330479985451],
            [-1.359002829450096, 53.089847596260292],
            [-1.360744891595995, 53.090382828633551],
            [-1.361388130010046, 53.091817357739508],
            [-1.362256148301009, 53.091786952039357],
            [-1.363169660935045, 53.092417487939457],
            [-1.365009193965539, 53.093239933259134],
            [-1.364006398636109, 53.094510145507748],
            [-1.36405011208176, 53.095901004073276],
            [-1.365974451171832, 53.096654669875932],
            [-1.366894197858309, 53.097685227500008],
            [-1.369589761333657, 53.099020036433991],
            [-1.369003272670997, 53.099295594352704],
            [-1.368911388058666, 53.100063680986118],
            [-1.370298916309662, 53.102413601417098],
            [-1.372839396063135, 53.10375830165254],
            [-1.373190743463382, 53.104968293857802],
            [-1.372703161989707, 53.105525750906814],
            [-1.372806537884258, 53.107147938451362],
            [-1.373652452295528, 53.107837363551603],
            [-1.372151396765803, 53.108233971514224],
            [-1.370909754620603, 53.109073298375662],
            [-1.371710166093088, 53.110938277082461],
            [-1.377313202324511, 53.118529365595201],
            [-1.376541002372531, 53.1200597708158],
            [-1.374882815109693, 53.12168170797581],
            [-1.375139706798185, 53.123545602957009],
            [-1.374814140899004, 53.124768213414619],
            [-1.374111355351659, 53.124797782520794],
            [-1.373298873240642, 53.125794000090011],
            [-1.374193434723054, 53.125834659670986],
            [-1.374882346533842, 53.126350656925922],
            [-1.372702582988985, 53.128567600902343],
            [-1.37338938584009, 53.129435968805431],
            [-1.372717959685144, 53.129975377741772],
            [-1.37338998856544, 53.130936255473614],
            [-1.372777601983274, 53.131627891239269],
            [-1.374199010017517, 53.133683985469794],
            [-1.374771213307097, 53.134113972715511],
            [-1.375537600453837, 53.134037989457333],
            [-1.3758940291213, 53.134918992118855],
            [-1.377299371516567, 53.135311978953496],
            [-1.377727077150303, 53.135814907041194],
            [-1.377196456611868, 53.136951053804623],
            [-1.37470463587045, 53.138392437960029],
            [-1.374194661785983, 53.139128664432782],
            [-1.370996870678228, 53.139453409633191],
            [-1.366628117142086, 53.141737766408895],
            [-1.366264620910141, 53.142362373782909],
            [-1.362532046275235, 53.143244067362865],
            [-1.362529065674641, 53.146980819894615],
            [-1.360654731131449, 53.147646756634749],
            [-1.357478593486368, 53.150479213871336],
            [-1.354960152015467, 53.151704303191742],
            [-1.355916997270412, 53.153813825650502],
            [-1.354751688819883, 53.15533746363095],
            [-1.353061329045811, 53.156167878595795],
            [-1.353700648371631, 53.157312961957082],
            [-1.352843338521078, 53.158731280864579],
            [-1.355536541529593, 53.162247124035119],
            [-1.356929115383994, 53.163241647881321],
            [-1.356845497230928, 53.163532442610169],
            [-1.358322303009297, 53.164199303236941],
            [-1.358758001420019, 53.165675856277126],
            [-1.360064907135294, 53.166408299557467],
            [-1.362443528124515, 53.166390486385623],
            [-1.364814621589788, 53.167389248877718],
            [-1.36477180422885, 53.168060503729727],
            [-1.36666473654235, 53.170964157475488],
            [-1.372203112563724, 53.171459095076187],
            [-1.373784785402714, 53.172356434056738],
            [-1.376002466706059, 53.172523576982087],
            [-1.378266834594301, 53.17370578581238],
            [-1.377443847644922, 53.173715872025582],
            [-1.376687118092944, 53.17503870015652],
            [-1.374119118699313, 53.176832040151268],
            [-1.373172554160274, 53.178644650365925],
            [-1.371171430046758, 53.180633272068697],
            [-1.369412136833212, 53.180811847914647],
            [-1.367907113077752, 53.180080257596003],
            [-1.365063449649026, 53.180330319122554],
            [-1.36456363453024, 53.180536201226495],
            [-1.363868182324596, 53.182334794666943],
            [-1.363243062843784, 53.182714389803401],
            [-1.361822872846175, 53.182621398056369],
            [-1.359309747282512, 53.184174705417092],
            [-1.359682848027824, 53.184343007685669],
            [-1.356784053398787, 53.185455524016916],
            [-1.354429491625206, 53.184398267354261],
            [-1.353620730402812, 53.185339531446616],
            [-1.352222285153391, 53.185487449742411],
            [-1.351917992422669, 53.184428683930285],
            [-1.345645270190904, 53.183419099166791],
            [-1.344265256718853, 53.187954579126341],
            [-1.338005738782712, 53.186843089919037],
            [-1.337198926098444, 53.186854786341407],
            [-1.337033364447852, 53.187304216902731],
            [-1.335955271328656, 53.187438444559383],
            [-1.334886527880812, 53.186967751606417],
            [-1.331779033467431, 53.18710767874375],
            [-1.331358790643471, 53.186896775962069],
            [-1.330360599647388, 53.18718511402799],
            [-1.329840658625263, 53.187706253452824],
            [-1.327877892546622, 53.187946894888412],
            [-1.321704766881762, 53.185559487980484],
            [-1.320635121682315, 53.185442830364664],
            [-1.31879320258377, 53.185804464265217],
            [-1.318159513090432, 53.186790536238156],
            [-1.31764537939773, 53.19118054559128],
            [-1.316347272178853, 53.193093168658365],
            [-1.31540665381701, 53.193322384208201],
            [-1.314925669365339, 53.19476416104991],
            [-1.311613244136069, 53.198969030599208],
            [-1.313891052982906, 53.202567891211906],
            [-1.313702829415832, 53.204885979016012],
            [-1.314206873163728, 53.205761714890173],
            [-1.313744648293027, 53.20845486530223],
            [-1.313382311312287, 53.208922905170581],
            [-1.312433952495519, 53.208677432255634],
            [-1.310999502575466, 53.21008042803458],
            [-1.31153187586733, 53.211991873920581],
            [-1.312547248786969, 53.212264707220633],
            [-1.31059718036835, 53.216116928246663],
            [-1.311152889097302, 53.217323769797773],
            [-1.314334727707735, 53.219235197337589],
            [-1.312577213206245, 53.221621547618348],
            [-1.312543194559995, 53.222249683234786],
            [-1.313036473654022, 53.223809422424097],
            [-1.31474051058707, 53.224027772972562],
            [-1.313733824804556, 53.224973918726704],
            [-1.314084813072405, 53.225881130985968],
            [-1.313454873773462, 53.229211525828816],
            [-1.311338753519716, 53.231697365471142],
            [-1.311519898263793, 53.232359102612357],
            [-1.316531395851733, 53.237611432948022],
            [-1.319203373877085, 53.239563842427806],
            [-1.322663622066328, 53.241241113368545],
            [-1.323852578138019, 53.252609014464078],
            [-1.326162845541428, 53.25438572203646],
            [-1.331262985497107, 53.257211787340104],
            [-1.323941556593777, 53.259136397308978],
            [-1.322577962292368, 53.259765977193219],
            [-1.321604941232571, 53.261005416302105],
            [-1.31667774804171, 53.262483839213253],
            [-1.309379659244316, 53.263420704382632],
            [-1.302621082992993, 53.263244749992403],
            [-1.301302077651069, 53.26387075234355],
            [-1.302093982801541, 53.265325306128815],
            [-1.302178238521174, 53.266596836459392],
            [-1.301617598502629, 53.26695850669379],
            [-1.303476511446936, 53.269466504456226],
            [-1.3045643252557, 53.269791059722529],
            [-1.304941919934415, 53.270269674321007],
            [-1.305979295763783, 53.272600253755542],
            [-1.305933023295682, 53.273605846523608],
            [-1.307180212265503, 53.275396500667824],
            [-1.309055181800121, 53.275369621317367],
            [-1.316703847407039, 53.281636706469364],
            [-1.318697431683153, 53.285486373817996],
            [-1.320739621477949, 53.286955120001721],
            [-1.321911247249364, 53.288560011904025],
            [-1.321925227100655, 53.288908860845687],
            [-1.318997865833014, 53.28913938349109],
            [-1.32255968483171, 53.291570483088435],
            [-1.324086377943571, 53.294074459076135],
            [-1.324142387718612, 53.29508872363936],
            [-1.323450590507995, 53.295383234511405],
            [-1.322938417017465, 53.295134033754991],
            [-1.320027618422959, 53.295145347418241],
            [-1.313358529791528, 53.296746731624445],
            [-1.310674854092143, 53.297927680739669],
            [-1.313572278033643, 53.299699015552982],
            [-1.316086786923133, 53.301784493750674],
            [-1.316263493392972, 53.30509611287485],
            [-1.317825138677911, 53.305790002948349],
            [-1.318945860104161, 53.307035071792505],
            [-1.321803749349576, 53.308520129698358],
            [-1.321927920132142, 53.309018818928429],
            [-1.318717068963918, 53.310378526734006],
            [-1.313583418500806, 53.310892038125502],
            [-1.312376853658686, 53.311350716046839],
            [-1.309167445061904, 53.311847243712791],
            [-1.307475870820611, 53.312499920071041],
            [-1.304638157918681, 53.312928369183339],
            [-1.303057351653046, 53.31285532705688],
            [-1.291590557317941, 53.309203043908418],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000034",
        LAD13CDO: "17UD",
        LAD13NM: "Chesterfield",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.358959313594397, 53.287730187282776],
            [-1.353171467206424, 53.286480057588541],
            [-1.349221874261709, 53.286582627461414],
            [-1.34844377664331, 53.285667805924732],
            [-1.347335800986778, 53.286126476165947],
            [-1.346615035992131, 53.285684772076777],
            [-1.345191465219554, 53.285788430137657],
            [-1.342665657377949, 53.285679259094238],
            [-1.341129571449792, 53.287441599259708],
            [-1.34040471966098, 53.287657822319765],
            [-1.339217961169165, 53.288741608931481],
            [-1.33969290770999, 53.289071434040267],
            [-1.339061464468266, 53.289842780629165],
            [-1.337273298361623, 53.289552408507951],
            [-1.33719594609574, 53.288346567870327],
            [-1.336442925597115, 53.288054737715306],
            [-1.336111349034669, 53.288833129537259],
            [-1.333635615429331, 53.289737993940854],
            [-1.322589187988733, 53.281355656117228],
            [-1.316703847407039, 53.281636706469364],
            [-1.309055181800121, 53.275369621317367],
            [-1.307180212265503, 53.275396500667824],
            [-1.305933023295682, 53.273605846523608],
            [-1.305979295763783, 53.272600253755542],
            [-1.304941919934415, 53.270269674321007],
            [-1.3045643252557, 53.269791059722529],
            [-1.303476511446936, 53.269466504456226],
            [-1.301617598502629, 53.26695850669379],
            [-1.302178238521174, 53.266596836459392],
            [-1.302093982801541, 53.265325306128815],
            [-1.301302077651069, 53.26387075234355],
            [-1.302621082992993, 53.263244749992403],
            [-1.309379659244316, 53.263420704382632],
            [-1.31667774804171, 53.262483839213253],
            [-1.321604941232571, 53.261005416302105],
            [-1.322577962292368, 53.259765977193219],
            [-1.323941556593777, 53.259136397308978],
            [-1.331262985497107, 53.257211787340104],
            [-1.326162845541428, 53.25438572203646],
            [-1.323852578138019, 53.252609014464078],
            [-1.322663622066328, 53.241241113368545],
            [-1.319203373877085, 53.239563842427806],
            [-1.326355842850923, 53.236080780470481],
            [-1.332123816863162, 53.2366777320686],
            [-1.331971228528524, 53.241572272332021],
            [-1.33531140429428, 53.241272726328312],
            [-1.336451932096661, 53.241839989266047],
            [-1.338829430000718, 53.241486444083712],
            [-1.338733079906044, 53.240445886367148],
            [-1.339329407748348, 53.240448292569837],
            [-1.339591574032029, 53.240062320147231],
            [-1.343326331521119, 53.240403849842245],
            [-1.347066288934313, 53.23952099390084],
            [-1.347862051888512, 53.241780678926958],
            [-1.349425876723468, 53.242904749969505],
            [-1.349641151008599, 53.244816976444156],
            [-1.350864460399237, 53.246369741180132],
            [-1.353171486183631, 53.245828556103113],
            [-1.356765203712241, 53.245722147101787],
            [-1.359995447914557, 53.244664446720961],
            [-1.363026449581796, 53.244298643109623],
            [-1.363203377951811, 53.243887894014414],
            [-1.366465045171615, 53.242711532331377],
            [-1.370456712899362, 53.241913776521436],
            [-1.371489293501555, 53.241187522203745],
            [-1.375043900537795, 53.240855633246049],
            [-1.377212460025913, 53.241141140284924],
            [-1.380345073230797, 53.243776836219901],
            [-1.382973400161833, 53.242635384054068],
            [-1.383262726650655, 53.24200136229738],
            [-1.387704752011416, 53.239438131391694],
            [-1.388426219388218, 53.239595544278856],
            [-1.389535132188007, 53.238849749922338],
            [-1.390273992081601, 53.238837349616979],
            [-1.390084076953366, 53.23786197676877],
            [-1.391197941021803, 53.237606988713232],
            [-1.390827303266606, 53.237223964707233],
            [-1.391581638973444, 53.237174780725034],
            [-1.391638504251024, 53.235593914851933],
            [-1.394055020838064, 53.234966204687673],
            [-1.393620838574784, 53.233990494484715],
            [-1.3989052410916, 53.233859041948222],
            [-1.400800776954887, 53.234263196046797],
            [-1.401095255143608, 53.233559042930317],
            [-1.400531628269976, 53.23132874922549],
            [-1.399266093277025, 53.230554724158822],
            [-1.396420868138406, 53.22706792287353],
            [-1.396336076085881, 53.226280060716093],
            [-1.397088090680562, 53.225421823963337],
            [-1.398521456268306, 53.22521512538097],
            [-1.39558660171129, 53.222698656487751],
            [-1.401560413251744, 53.217813619887451],
            [-1.400044851033692, 53.217061716665526],
            [-1.399624677243241, 53.21605103880102],
            [-1.399602823366415, 53.215150233402561],
            [-1.401406128498518, 53.213731851005477],
            [-1.405350130188021, 53.2142963186472],
            [-1.405122216012249, 53.214642156152685],
            [-1.408356475850994, 53.215536471569976],
            [-1.409168515646194, 53.215617803394466],
            [-1.409109443768514, 53.215339751444489],
            [-1.40988959284548, 53.215126082054269],
            [-1.411689934429263, 53.215539482511964],
            [-1.415291113131497, 53.214687064633139],
            [-1.420844411522053, 53.21242288026653],
            [-1.421933694108735, 53.213375603848299],
            [-1.422610582774615, 53.213484054613978],
            [-1.422566758843728, 53.213847895590177],
            [-1.423059840808851, 53.213817023367056],
            [-1.4223821690314, 53.214100490374427],
            [-1.422688684541908, 53.214358160458083],
            [-1.427501982624964, 53.215341390183731],
            [-1.427507960347911, 53.215342317781761],
            [-1.429281621605198, 53.215410147833573],
            [-1.433452499128355, 53.213799441224971],
            [-1.437269303310855, 53.213131658301045],
            [-1.440002358780677, 53.213574198277321],
            [-1.44071584351983, 53.217086836187114],
            [-1.439295191147683, 53.218765593073286],
            [-1.441206123331861, 53.220611905165519],
            [-1.44298112824216, 53.21978603551802],
            [-1.444173020138549, 53.220731841356638],
            [-1.445271539138996, 53.219981885204668],
            [-1.447528335019559, 53.221395537331702],
            [-1.450224126654716, 53.220820119816878],
            [-1.452430185389953, 53.220708020883528],
            [-1.457377390674433, 53.219618703676836],
            [-1.459116908401532, 53.220631570573047],
            [-1.460172415818584, 53.220303762772083],
            [-1.461677447722647, 53.22163014311608],
            [-1.465661819440501, 53.220154101962727],
            [-1.467600473800452, 53.219497591860019],
            [-1.468834054001124, 53.220627609394015],
            [-1.470469775916984, 53.221337819171268],
            [-1.475462795083019, 53.22161607140913],
            [-1.478397514860874, 53.222302215332249],
            [-1.479527942568059, 53.22221007409825],
            [-1.479671852206265, 53.222694306588295],
            [-1.47813570133447, 53.222894339300403],
            [-1.47841045160934, 53.223703646156011],
            [-1.477801118772501, 53.22429694542997],
            [-1.476978109716901, 53.224354463353428],
            [-1.475844566268942, 53.225065005090869],
            [-1.477344401430168, 53.226256328537026],
            [-1.481697931143377, 53.226079370849213],
            [-1.482153510988392, 53.227054850437803],
            [-1.482488343343676, 53.226988887007231],
            [-1.483068139390904, 53.228971660935592],
            [-1.484158848045483, 53.22895750699022],
            [-1.48416946545054, 53.23069241403963],
            [-1.48255833690818, 53.231000945561739],
            [-1.483975896727739, 53.232464190719007],
            [-1.482991290767224, 53.232617232439843],
            [-1.483164395754159, 53.233295746809105],
            [-1.480820811576, 53.23345995256868],
            [-1.480696476156415, 53.233211317219457],
            [-1.478055092224642, 53.23371754807247],
            [-1.471876140836532, 53.233396408557056],
            [-1.471865430293088, 53.236093934609556],
            [-1.471364148212926, 53.236164523950855],
            [-1.471494405747645, 53.23884919015336],
            [-1.472168516904656, 53.238982514572712],
            [-1.471553922177355, 53.239369911692918],
            [-1.471864291546673, 53.239474658891751],
            [-1.475095273142476, 53.239799938398612],
            [-1.477540386292034, 53.239695616238464],
            [-1.478970445478284, 53.242483937439175],
            [-1.478533939768163, 53.243384515415244],
            [-1.479088521445143, 53.243496603313581],
            [-1.480900186321517, 53.245109019821179],
            [-1.481712540073116, 53.246462684437283],
            [-1.480983646615151, 53.246633899582775],
            [-1.481313740009425, 53.247840745960303],
            [-1.480357734966682, 53.248695923011113],
            [-1.479218518864492, 53.248219936724233],
            [-1.478482280396912, 53.248498971237602],
            [-1.477896864909798, 53.248206069037927],
            [-1.476712557251679, 53.248604480609011],
            [-1.47475518128959, 53.248735205346435],
            [-1.47393966595532, 53.249120830565786],
            [-1.473839929058936, 53.249799052031527],
            [-1.474935407354958, 53.250051073722283],
            [-1.475906521193744, 53.250792433454109],
            [-1.478162958734631, 53.250972216145698],
            [-1.478794590092155, 53.252275669655432],
            [-1.47510552706612, 53.253419060040052],
            [-1.476272659683828, 53.254685330516722],
            [-1.473518071085386, 53.257219755350192],
            [-1.47220913199441, 53.256515535154264],
            [-1.47068457650627, 53.257253958481897],
            [-1.472512813208172, 53.258271507942816],
            [-1.471134863192996, 53.259394410211605],
            [-1.471466199486132, 53.259634083936845],
            [-1.473215108494482, 53.260040925809022],
            [-1.473756348161311, 53.259668478440013],
            [-1.474379002588444, 53.259991227534748],
            [-1.474420484221194, 53.259419717740954],
            [-1.475397179115354, 53.25947615229191],
            [-1.478663712203112, 53.26279927635894],
            [-1.479754911622333, 53.263310113257972],
            [-1.480601747465441, 53.263327286455926],
            [-1.479046569261441, 53.263493091790863],
            [-1.475426900640293, 53.265135677030429],
            [-1.474866957242709, 53.26507208931767],
            [-1.473291350232244, 53.266161785425197],
            [-1.47225276252899, 53.266371130690267],
            [-1.470105435718039, 53.267611065015807],
            [-1.467873131865976, 53.267472588414712],
            [-1.466513598404811, 53.267563596537805],
            [-1.466369860854431, 53.267909025117511],
            [-1.464200893632343, 53.267981102587505],
            [-1.462729041016435, 53.270333162108635],
            [-1.465418057674049, 53.270291308019132],
            [-1.465077774580218, 53.272207107326437],
            [-1.466873959812601, 53.273913142594871],
            [-1.466944681350198, 53.274617286152825],
            [-1.464902960425691, 53.274920060178033],
            [-1.46524660355377, 53.275983184852265],
            [-1.464913652696334, 53.277187097614515],
            [-1.465805610955552, 53.278057619850244],
            [-1.464401630802795, 53.27843784646641],
            [-1.460831343749526, 53.278036144572887],
            [-1.459990796069423, 53.279759102123123],
            [-1.459062108398759, 53.28109243660645],
            [-1.458639644393834, 53.282953909137461],
            [-1.457069973343095, 53.283727009270862],
            [-1.455254816088553, 53.28174208817574],
            [-1.454452754825809, 53.281360891939052],
            [-1.451594518242343, 53.2819131882151],
            [-1.447907192991307, 53.283132151211447],
            [-1.447117579601131, 53.282835458468739],
            [-1.443942778219306, 53.282685872609562],
            [-1.441927706876977, 53.282051739368562],
            [-1.439037839802504, 53.28019187625096],
            [-1.438663464634234, 53.2806062997917],
            [-1.436083785324701, 53.280835935883822],
            [-1.43219176861964, 53.28185569138931],
            [-1.42323837719867, 53.282696353166848],
            [-1.42312783769886, 53.283442793342907],
            [-1.421490428685335, 53.28340700018191],
            [-1.418917668937662, 53.284876762125919],
            [-1.417973495727941, 53.286253748399666],
            [-1.41549490023343, 53.287616928272989],
            [-1.414642067752409, 53.286797457894544],
            [-1.409813050605352, 53.286721534903698],
            [-1.407948966318414, 53.286255665814025],
            [-1.403002182383597, 53.283333006179618],
            [-1.401740078658265, 53.28155587633497],
            [-1.399280773183968, 53.281953412338076],
            [-1.399498921269952, 53.282440808639258],
            [-1.396319123705368, 53.283404550044658],
            [-1.396145953764223, 53.283772217639012],
            [-1.396747056943376, 53.284543807017357],
            [-1.391765169388539, 53.287120794050807],
            [-1.391422134401923, 53.286453868357313],
            [-1.387165869292455, 53.286086011873053],
            [-1.386562818199951, 53.286927867264914],
            [-1.387345912568792, 53.289859566345953],
            [-1.386301855615077, 53.292265914572695],
            [-1.386673893828113, 53.293733911370175],
            [-1.385959077133107, 53.294096081952347],
            [-1.38599139888988, 53.294876481630425],
            [-1.380731680093715, 53.295474926656418],
            [-1.380870131092118, 53.296587567524284],
            [-1.377154692058347, 53.296830707427482],
            [-1.377055256760963, 53.299025268663819],
            [-1.374169908196032, 53.298407917788396],
            [-1.371524442586662, 53.297297372850707],
            [-1.371359985017432, 53.295621880534803],
            [-1.370683511215111, 53.294486616092001],
            [-1.369853746773942, 53.293677733070126],
            [-1.368011421997236, 53.292965958650591],
            [-1.368506502734446, 53.292049916730711],
            [-1.366894410451161, 53.289164933006703],
            [-1.360790503812443, 53.288660473289902],
            [-1.359725445332834, 53.288359028505731],
            [-1.358959313594397, 53.287730187282776],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000035",
        LAD13CDO: "17UF",
        LAD13NM: "Derbyshire Dales",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.591465779595186, 53.344930576414882],
            [-1.601576819460973, 53.34259171990729],
            [-1.604249110123591, 53.34239746092851],
            [-1.607645348523291, 53.342690912902803],
            [-1.608812940934948, 53.343286199969015],
            [-1.612304331308154, 53.343201406478421],
            [-1.612141602678265, 53.341601787813715],
            [-1.614373033719952, 53.338818021199224],
            [-1.615736802457605, 53.337803097850909],
            [-1.616799417743668, 53.336367420045079],
            [-1.619512985173558, 53.335363968195082],
            [-1.622114004491734, 53.333657181207975],
            [-1.622086596693599, 53.331659802054787],
            [-1.623705279260605, 53.331149860518671],
            [-1.623576317835169, 53.330447435246548],
            [-1.626431081490052, 53.32920427207268],
            [-1.627265778373877, 53.328530029979071],
            [-1.627919862168171, 53.325830959184081],
            [-1.626840044791706, 53.325352085923733],
            [-1.632728206295935, 53.320822047008093],
            [-1.628233144485566, 53.316382082746493],
            [-1.625495025617148, 53.316406783607455],
            [-1.619435523992255, 53.31824020141881],
            [-1.609202767000083, 53.322646682782533],
            [-1.603889410962317, 53.319119070956567],
            [-1.601526275255263, 53.318188063353809],
            [-1.60060713115865, 53.316950837494169],
            [-1.600674750221996, 53.315655785395975],
            [-1.599198649949176, 53.314597357637396],
            [-1.599094428818605, 53.311300832519393],
            [-1.59873159608708, 53.308057368995691],
            [-1.59781572459053, 53.305384624752712],
            [-1.598602125934333, 53.304898282959385],
            [-1.599301292325941, 53.302346024773897],
            [-1.597442673553128, 53.29573931518987],
            [-1.584947752451223, 53.291118531252124],
            [-1.584334154734841, 53.290812574759883],
            [-1.584502005808108, 53.290365517485434],
            [-1.58394339037112, 53.290423796341869],
            [-1.583456727406184, 53.289870187069724],
            [-1.583133405057098, 53.290106362285485],
            [-1.582054900222997, 53.289328655388623],
            [-1.582261508821711, 53.289062411587743],
            [-1.580510940367675, 53.28875245021824],
            [-1.578854446570824, 53.286962340388101],
            [-1.578834294733069, 53.286112827877176],
            [-1.579395781202077, 53.286062673021206],
            [-1.580228783301113, 53.284938410482305],
            [-1.580845075117969, 53.2848039468604],
            [-1.582530296358884, 53.283182880912364],
            [-1.583921738120257, 53.283229088733556],
            [-1.584604058499761, 53.282468316418502],
            [-1.58796347629342, 53.28109119255673],
            [-1.587674874840366, 53.280845700313932],
            [-1.589392997013698, 53.278721277496693],
            [-1.589350206304106, 53.278021801089366],
            [-1.586303355729162, 53.275323623246116],
            [-1.586059224726827, 53.27358344118619],
            [-1.584312916742118, 53.272122980092483],
            [-1.583724769676858, 53.272004075141645],
            [-1.583478323041488, 53.271286806290682],
            [-1.582440235441981, 53.271257260736434],
            [-1.582760998623649, 53.270267664641274],
            [-1.583374931808455, 53.270353405819819],
            [-1.58358397317307, 53.269671883976564],
            [-1.582717261834582, 53.26952323726249],
            [-1.583218331604466, 53.269187008764675],
            [-1.582546970863247, 53.267146895406924],
            [-1.583087604916761, 53.266439567242479],
            [-1.582405224376551, 53.266152235176563],
            [-1.582724567373399, 53.265690427761839],
            [-1.583429961335378, 53.266077614360981],
            [-1.583643981136291, 53.265653189818416],
            [-1.582941812701738, 53.264780618958859],
            [-1.58360404492156, 53.264670571494364],
            [-1.582912811442568, 53.263908601073119],
            [-1.58315894718579, 53.263726987955707],
            [-1.584313913061393, 53.263976412391955],
            [-1.584305900803517, 53.263565594926767],
            [-1.584893304566624, 53.263594606465006],
            [-1.584539701368673, 53.263108877751407],
            [-1.584938138408635, 53.262679699419664],
            [-1.582268891846264, 53.263216905057092],
            [-1.578745497680963, 53.262638220870294],
            [-1.575344250608406, 53.263056728993284],
            [-1.570974152414889, 53.262906262569899],
            [-1.565781165967825, 53.261410610306775],
            [-1.557177282731593, 53.249543365390124],
            [-1.55762087645925, 53.248818711201224],
            [-1.559510513174834, 53.247524116383879],
            [-1.562051963734657, 53.246666048412557],
            [-1.563881165562924, 53.245102395263743],
            [-1.56799939172733, 53.243146122032037],
            [-1.568533766837262, 53.24260782515465],
            [-1.568518573063499, 53.241883267426928],
            [-1.569340042405703, 53.241253419636791],
            [-1.570385820933929, 53.240815837160888],
            [-1.570986886204092, 53.241244971704006],
            [-1.572057745308214, 53.240995332149076],
            [-1.571480081252469, 53.239872346288578],
            [-1.57064448350031, 53.239673384151175],
            [-1.571297234891458, 53.237476154743355],
            [-1.570764498967647, 53.237111088333087],
            [-1.57097024503036, 53.235863273662375],
            [-1.570035970655456, 53.235502152233671],
            [-1.569702560699489, 53.23423711355742],
            [-1.567474038780806, 53.233715788134155],
            [-1.567607224116395, 53.232838057256522],
            [-1.56569216100371, 53.23125444909958],
            [-1.564991626666568, 53.229720189831241],
            [-1.566251627422512, 53.227923405308204],
            [-1.565906917186039, 53.227200343230464],
            [-1.566900093712019, 53.226452482544268],
            [-1.567570734442719, 53.22634525150076],
            [-1.568900237985986, 53.22491543716648],
            [-1.569021695318016, 53.223110905915711],
            [-1.553612466050957, 53.215763322868654],
            [-1.542506511177002, 53.211852401871305],
            [-1.540241227708676, 53.210707489301058],
            [-1.537448961868026, 53.211514682845539],
            [-1.535257112050833, 53.211070197473319],
            [-1.531797196543276, 53.207863794312779],
            [-1.528722003502234, 53.207439087434338],
            [-1.525977050316747, 53.207485735730188],
            [-1.524401484122384, 53.20644573395262],
            [-1.521266297579707, 53.206449367346373],
            [-1.519442895518379, 53.206164274106229],
            [-1.518903895528789, 53.205763890462741],
            [-1.517271927191097, 53.206034153833073],
            [-1.516835101495194, 53.203605369937826],
            [-1.515596197628373, 53.202613356757553],
            [-1.517517722407416, 53.201209883355595],
            [-1.517647133592136, 53.200750172823184],
            [-1.52977234651946, 53.201072773206342],
            [-1.548296768241232, 53.199900322549517],
            [-1.552993105440478, 53.196269411199879],
            [-1.551750677954687, 53.188519868090744],
            [-1.547795158209814, 53.181978045185716],
            [-1.539630779426506, 53.171377555536438],
            [-1.517350943694358, 53.156451269932198],
            [-1.515670661345652, 53.154003035615602],
            [-1.509668309302687, 53.150973458620733],
            [-1.509084185749628, 53.149221788767797],
            [-1.509647656611102, 53.148716228597088],
            [-1.508657049342106, 53.146294997318094],
            [-1.495368127933902, 53.133946037026533],
            [-1.497584687396284, 53.133309990441752],
            [-1.496046931275133, 53.130987913899759],
            [-1.498853419420042, 53.131145375024559],
            [-1.499432304897407, 53.130846675113482],
            [-1.500474642751265, 53.129117059964791],
            [-1.500146780976827, 53.128263521564648],
            [-1.505497304568352, 53.128110563676486],
            [-1.510032898329809, 53.127335569230887],
            [-1.510496694134523, 53.127816592762542],
            [-1.512973546382325, 53.126712999121345],
            [-1.514372761353464, 53.126542525298042],
            [-1.515969619444084, 53.125272576978283],
            [-1.520193281612416, 53.124015912254322],
            [-1.522012882037698, 53.120961551180258],
            [-1.523513197547549, 53.120411138221435],
            [-1.522890266933805, 53.119721880192024],
            [-1.52328464230697, 53.118784101247286],
            [-1.522744018450543, 53.11781561143038],
            [-1.520801962604829, 53.116309336495753],
            [-1.5206087456284, 53.114471191875523],
            [-1.522513097217642, 53.113842413812343],
            [-1.52243875632345, 53.113270410408482],
            [-1.523695659198115, 53.111739209080945],
            [-1.525113519216891, 53.111455421666214],
            [-1.526210050994166, 53.110774820177369],
            [-1.526355661895866, 53.109078260472188],
            [-1.525669448266959, 53.10831325549831],
            [-1.526298246096083, 53.10777731158035],
            [-1.526684160895763, 53.102692818851033],
            [-1.527619583036104, 53.101698742987431],
            [-1.530226314422303, 53.101107682410543],
            [-1.530419638758771, 53.100635618369473],
            [-1.531107043401582, 53.10046303915427],
            [-1.529175269045108, 53.099017163013364],
            [-1.528686230597567, 53.098121712384035],
            [-1.529904120596767, 53.096820409356305],
            [-1.529827855215502, 53.095459158251309],
            [-1.528856733364043, 53.09495282966305],
            [-1.527319410993772, 53.094742685554706],
            [-1.523409054349187, 53.095629608020666],
            [-1.520498361865546, 53.096583354811912],
            [-1.517457262247715, 53.096500058381054],
            [-1.514411438601358, 53.095112345360029],
            [-1.513772169061241, 53.093665187104456],
            [-1.512061481187185, 53.093346268397831],
            [-1.512373763469846, 53.092668869386223],
            [-1.514763100705583, 53.091594541393675],
            [-1.516180908905971, 53.090462287808016],
            [-1.519347379020016, 53.086221459264053],
            [-1.520461987588489, 53.086148646342409],
            [-1.521433510501874, 53.086592122461873],
            [-1.52301647501987, 53.08669645465185],
            [-1.526707115736655, 53.087664924867781],
            [-1.530000140778013, 53.087318402041724],
            [-1.530732040077787, 53.087565792234358],
            [-1.533605663310846, 53.086925370119431],
            [-1.53482639526723, 53.085871226892202],
            [-1.538299974091225, 53.084258613336587],
            [-1.538126488095153, 53.083589150255683],
            [-1.53891809447695, 53.081878891740317],
            [-1.542291056780835, 53.080418586857945],
            [-1.542957562148528, 53.080493059745301],
            [-1.543483081963821, 53.080206524742771],
            [-1.543513694152501, 53.079570212267434],
            [-1.542876212356502, 53.079293598614854],
            [-1.54258117765268, 53.078538278216648],
            [-1.545326199239645, 53.078554184325419],
            [-1.549715657345557, 53.076908774263345],
            [-1.547502910326477, 53.073827902796623],
            [-1.549070025197514, 53.071837361719425],
            [-1.551570133283521, 53.070963171642802],
            [-1.551519420809042, 53.070528805529257],
            [-1.55317853359269, 53.069425783844466],
            [-1.553275467939841, 53.069000062573949],
            [-1.555989025850244, 53.069985533174332],
            [-1.558491099115598, 53.069478860679929],
            [-1.560848689408596, 53.068351352675926],
            [-1.563047696199695, 53.065922488214454],
            [-1.564653536669673, 53.065616447373593],
            [-1.569889610470766, 53.062364329638299],
            [-1.570712802679949, 53.062414942904262],
            [-1.571367555911892, 53.061695473885699],
            [-1.57257841943346, 53.061322282927854],
            [-1.574243528251796, 53.061337237844697],
            [-1.575540448522871, 53.061010169082039],
            [-1.576543816432433, 53.06003122877479],
            [-1.577849385111275, 53.060034965996024],
            [-1.578293267184834, 53.060113845584624],
            [-1.578923875702074, 53.059558750716413],
            [-1.578294387854151, 53.058011289298449],
            [-1.578767235063125, 53.057871833583626],
            [-1.580772737114816, 53.057851944568135],
            [-1.581787371617113, 53.058155750191659],
            [-1.58316097827446, 53.059917045102509],
            [-1.585735832121971, 53.059506238846531],
            [-1.588169367255645, 53.058418904938819],
            [-1.588713255810243, 53.057553332924584],
            [-1.588401659719767, 53.057371574422682],
            [-1.59144215279862, 53.054715866894767],
            [-1.592196043019332, 53.052136768780478],
            [-1.592970616619821, 53.051470626755673],
            [-1.592850004643594, 53.050813107544904],
            [-1.593765290684311, 53.050091708645461],
            [-1.593293236901963, 53.048575424920038],
            [-1.591764397259752, 53.047924771081007],
            [-1.591084570959584, 53.047252745934529],
            [-1.58626642499121, 53.045540743847702],
            [-1.584922512966508, 53.044449276156278],
            [-1.583721740504727, 53.044299458971757],
            [-1.580975938274424, 53.042278949693163],
            [-1.581721957415948, 53.042097295374958],
            [-1.582767128136792, 53.041053725223122],
            [-1.584106041763965, 53.040632323918423],
            [-1.585922106896595, 53.039284886046943],
            [-1.589215879235353, 53.037792409633788],
            [-1.590013930029693, 53.036515102900438],
            [-1.591310928714517, 53.036088082626819],
            [-1.592726561533095, 53.036204400173318],
            [-1.593673553966765, 53.03509118119549],
            [-1.593377488733717, 53.034382722246228],
            [-1.596673303191591, 53.032763298651908],
            [-1.597241062802498, 53.031682924189596],
            [-1.598606617561973, 53.030593553866247],
            [-1.598373478554028, 53.030168478220453],
            [-1.597291949925544, 53.029740534833138],
            [-1.597202675626936, 53.029079527982553],
            [-1.596459798162164, 53.028795650397662],
            [-1.596253158775947, 53.027771081750039],
            [-1.594309029893676, 53.026960838258475],
            [-1.593848666236026, 53.026129566398858],
            [-1.591706179536852, 53.025654798588469],
            [-1.59142306486061, 53.025163916305829],
            [-1.590745593323879, 53.025384520706389],
            [-1.59043730838971, 53.024876470027749],
            [-1.58935215806965, 53.024381022363734],
            [-1.58909329218012, 53.024637219811304],
            [-1.588707970109864, 53.024080356934306],
            [-1.587179540841246, 53.023484475590614],
            [-1.586100439337998, 53.023611071200001],
            [-1.585361563232843, 53.023238144690339],
            [-1.587071971289993, 53.021164890242673],
            [-1.584882199374571, 53.020368920210082],
            [-1.581920031745809, 53.021608050246805],
            [-1.57734271611767, 53.022277759155777],
            [-1.578563226712843, 53.023550462755182],
            [-1.578141368568836, 53.024462270456183],
            [-1.577435873307427, 53.023882661507017],
            [-1.574870210236429, 53.024324782482232],
            [-1.574318633512712, 53.023713343536777],
            [-1.571893440418636, 53.023818807779101],
            [-1.570504215476459, 53.022590372788265],
            [-1.569008721809164, 53.021861336010716],
            [-1.568351101747168, 53.021729508275982],
            [-1.566396839487809, 53.022455923346676],
            [-1.566212782148514, 53.02222692693509],
            [-1.567848620871966, 53.021439131438321],
            [-1.571251968235178, 53.021000182517852],
            [-1.572329030054968, 53.019569380996742],
            [-1.576114728577303, 53.017666426190679],
            [-1.575893915967263, 53.016317258597113],
            [-1.575282337328062, 53.015601334506137],
            [-1.574364696088099, 53.015241184537807],
            [-1.566750085931679, 53.013678349727769],
            [-1.56698753101307, 53.012888163033352],
            [-1.569401999167648, 53.012451957653319],
            [-1.571279133200666, 53.013593173646143],
            [-1.578758517414243, 53.014668923542835],
            [-1.578232008074333, 53.011797702580935],
            [-1.579279764316253, 53.012107943453351],
            [-1.579736854946344, 53.01108299037142],
            [-1.57929390159013, 53.008966262837141],
            [-1.577385869550032, 53.008088446877565],
            [-1.576930228976453, 53.005454783874008],
            [-1.57611718277222, 53.00478219331324],
            [-1.575284226014188, 53.004622810791574],
            [-1.576802612278253, 53.002044716701405],
            [-1.577664064460064, 53.002180817871007],
            [-1.580384945741309, 53.001728397409799],
            [-1.582332625723064, 53.000481252307921],
            [-1.585721518084334, 53.000586587015562],
            [-1.588954144966152, 53.001144342442672],
            [-1.591972416288776, 53.001054949746532],
            [-1.595101086173751, 52.999675003383665],
            [-1.599270037514307, 52.99840545432869],
            [-1.600108332829062, 52.997337657442792],
            [-1.599604848343225, 52.995551602211542],
            [-1.60183351194895, 52.988281395048602],
            [-1.601581369266009, 52.985732100434333],
            [-1.602168047054481, 52.9857547388198],
            [-1.601266902851051, 52.984293666430887],
            [-1.598659783303351, 52.983380582056114],
            [-1.598508313747137, 52.983009713896024],
            [-1.597338904270241, 52.982548208996448],
            [-1.595042381845881, 52.983004259815807],
            [-1.593927739880007, 52.982424249575487],
            [-1.593693950569662, 52.982736277389506],
            [-1.59279248769432, 52.982772748121029],
            [-1.592130351340409, 52.982226630186808],
            [-1.59186430825174, 52.982482810482146],
            [-1.591452744971486, 52.982375324720486],
            [-1.591399995793171, 52.981967031438039],
            [-1.591011408098952, 52.982108625383496],
            [-1.589160289472711, 52.981454122149252],
            [-1.588741195221107, 52.981671113088133],
            [-1.587454516546432, 52.981495862507899],
            [-1.587351219576207, 52.981081998466095],
            [-1.586299682904961, 52.980928225982943],
            [-1.584725616060917, 52.979817063175972],
            [-1.584215244940253, 52.980077766955397],
            [-1.584063375709334, 52.979762612001601],
            [-1.582913936652549, 52.979727125386191],
            [-1.581201689290298, 52.978903986298803],
            [-1.580757421772225, 52.978180583715734],
            [-1.580069075692951, 52.9782105173094],
            [-1.579014519266266, 52.977610801997379],
            [-1.577952988579967, 52.977577376217603],
            [-1.57701249201539, 52.976739832044046],
            [-1.576583522739074, 52.975679370294827],
            [-1.574344750659429, 52.975122136507132],
            [-1.574672022274511, 52.974702609217267],
            [-1.57369485428493, 52.974729674998194],
            [-1.573114954202286, 52.974035421558135],
            [-1.572673772187265, 52.974226207989965],
            [-1.571441716957038, 52.973977267625813],
            [-1.572758282033065, 52.971091052665017],
            [-1.572282999310181, 52.97081696960295],
            [-1.573555091352212, 52.969863279736771],
            [-1.572190109607033, 52.969214746150463],
            [-1.571901412768627, 52.967539004463369],
            [-1.571047626559489, 52.966548905377401],
            [-1.573096694811439, 52.967119006058923],
            [-1.573379060315097, 52.966416158240939],
            [-1.576471978816127, 52.966214164854982],
            [-1.57692564610535, 52.9654966366206],
            [-1.577734306410761, 52.965322421167897],
            [-1.577609926992744, 52.963723684680893],
            [-1.580772701129418, 52.963049890155865],
            [-1.580650563224612, 52.961824219770278],
            [-1.581234184106923, 52.961049601373297],
            [-1.583110936976034, 52.962341662060858],
            [-1.584692810518313, 52.962256403907652],
            [-1.585612797336325, 52.961016394103218],
            [-1.588750285618091, 52.962033184216459],
            [-1.596305542279387, 52.962045581552523],
            [-1.599398771134184, 52.962570226069822],
            [-1.605032145156371, 52.958167236241771],
            [-1.606562606825611, 52.957163710378659],
            [-1.607000037553069, 52.957351234701946],
            [-1.608006741911162, 52.956480797865957],
            [-1.607281018403331, 52.956240187840855],
            [-1.608087573879933, 52.955112894440191],
            [-1.604826544692966, 52.953774386417408],
            [-1.603841756925887, 52.952733745930779],
            [-1.602963028883445, 52.950843063594959],
            [-1.603254174070561, 52.949278099229595],
            [-1.604861058828531, 52.948677569756121],
            [-1.60534008272979, 52.947881809230481],
            [-1.606366431154876, 52.947778237826526],
            [-1.607141507434426, 52.947154246190259],
            [-1.609402804821411, 52.945922069253029],
            [-1.609930239932683, 52.946019086882806],
            [-1.612957895442082, 52.944275165041979],
            [-1.613850119221355, 52.945185086046514],
            [-1.616567527449607, 52.945856389346964],
            [-1.619467410108912, 52.947451415298495],
            [-1.620980886373811, 52.947799639099657],
            [-1.623548196082236, 52.946247250480084],
            [-1.62484280898575, 52.945916939136204],
            [-1.627509607083751, 52.946267813236162],
            [-1.62853857395043, 52.946716900450006],
            [-1.633095120983457, 52.946584518286819],
            [-1.633105080786424, 52.946814674980246],
            [-1.636178315060583, 52.947872266942959],
            [-1.639272444432535, 52.947687522330142],
            [-1.641381982634187, 52.948144264904741],
            [-1.645034507575477, 52.946467032288162],
            [-1.645893856527906, 52.946386892283435],
            [-1.646039646109129, 52.945846170782147],
            [-1.64726914442455, 52.94563138346733],
            [-1.647228560329487, 52.945314839965008],
            [-1.649224515912285, 52.945090617131683],
            [-1.6495170883168, 52.944611451301114],
            [-1.650239341859649, 52.944548854440988],
            [-1.649874588235951, 52.941801551185286],
            [-1.648658344676449, 52.939658511093128],
            [-1.649612367022182, 52.937233314545011],
            [-1.648995649006687, 52.937139805111002],
            [-1.649036891155701, 52.936823503244312],
            [-1.649940797594519, 52.935805880856599],
            [-1.649737128531076, 52.934678920835594],
            [-1.650366189353345, 52.934528852505352],
            [-1.651073809038507, 52.930527090944729],
            [-1.653314441179944, 52.928263841405396],
            [-1.65325409744125, 52.92669233242745],
            [-1.652370699296164, 52.926668178991399],
            [-1.652665086872182, 52.924454070102904],
            [-1.653711607824846, 52.924348351339404],
            [-1.653895246841096, 52.920529320918824],
            [-1.654997266501097, 52.91829238193472],
            [-1.656336613059792, 52.918375366412512],
            [-1.655513462885942, 52.914525548944233],
            [-1.653409493533292, 52.912065344806742],
            [-1.652696789826172, 52.909140829078744],
            [-1.650892188808144, 52.908704954407568],
            [-1.652019024363826, 52.908363965595761],
            [-1.654933954861901, 52.906227602117411],
            [-1.65410382065195, 52.905728078821795],
            [-1.657397477621614, 52.904171669928701],
            [-1.658382726096046, 52.902718229427698],
            [-1.660969782294595, 52.90250359755845],
            [-1.661347902691347, 52.902057904198934],
            [-1.66154079079137, 52.902679617920697],
            [-1.661898142626186, 52.902227571155272],
            [-1.662323806776352, 52.902549700844304],
            [-1.662963959032873, 52.902437352424293],
            [-1.664445516486387, 52.90560669394528],
            [-1.665280087952837, 52.90631740703374],
            [-1.668019899495024, 52.907150310456942],
            [-1.668682261578017, 52.908812490016309],
            [-1.66947478705965, 52.909399003002456],
            [-1.668652073819366, 52.911211661309082],
            [-1.670566995179786, 52.916747221303055],
            [-1.674309120316299, 52.916301770275098],
            [-1.674223190332193, 52.917845002861768],
            [-1.674843060970894, 52.918084017680179],
            [-1.676896063683914, 52.920799895235227],
            [-1.678019563715208, 52.921525683997999],
            [-1.680258155172868, 52.921103832143721],
            [-1.682397136110942, 52.920263668633552],
            [-1.683225201209387, 52.918477900625206],
            [-1.683761881491114, 52.918303138217041],
            [-1.689979845126303, 52.919996019407293],
            [-1.690654090119507, 52.91992586059223],
            [-1.691622423749684, 52.920539649315145],
            [-1.695015763702172, 52.92085313785126],
            [-1.699750164060516, 52.922109306285904],
            [-1.701758984599707, 52.922848787832251],
            [-1.705118978162224, 52.922414891807897],
            [-1.705612470062774, 52.922681299125763],
            [-1.708231958221904, 52.921987486543941],
            [-1.709712535830643, 52.920554616873503],
            [-1.710292009135288, 52.920418493289183],
            [-1.711254548909297, 52.918366774881882],
            [-1.710981902617489, 52.916203281911791],
            [-1.711570787732483, 52.91351960022979],
            [-1.712066934197429, 52.9127027737131],
            [-1.713553015263913, 52.911721132067278],
            [-1.714471260522984, 52.909568597720451],
            [-1.718991130622175, 52.909404072010169],
            [-1.718660033614258, 52.910247387361174],
            [-1.720895980378315, 52.910316480214398],
            [-1.722138394276065, 52.909958917427154],
            [-1.722697933257383, 52.910821401200643],
            [-1.724397903995518, 52.910766023348557],
            [-1.725899904633257, 52.911213567420134],
            [-1.729027306665313, 52.91045754031925],
            [-1.729418149115704, 52.911449952977691],
            [-1.72998819917529, 52.911364951030365],
            [-1.7311065546423, 52.912305071477611],
            [-1.736137264564025, 52.912536577459818],
            [-1.739782903978301, 52.91337881874869],
            [-1.74895887181491, 52.912475337106777],
            [-1.746967773316219, 52.910424249046613],
            [-1.748225615892235, 52.908136438144012],
            [-1.746336439452336, 52.907004263087522],
            [-1.743434187564636, 52.905983142071918],
            [-1.742991089909242, 52.904981672612585],
            [-1.743152118869742, 52.902639399724364],
            [-1.742625514356996, 52.902692197245187],
            [-1.73656500583658, 52.895202489250863],
            [-1.73766722046112, 52.890641031484222],
            [-1.737880746406005, 52.888257524596177],
            [-1.737306274239858, 52.884943676712894],
            [-1.737780379997863, 52.879758756294073],
            [-1.738729246350947, 52.877142240356605],
            [-1.738412007413682, 52.876773877268157],
            [-1.738958933235511, 52.876006487565718],
            [-1.742647085727439, 52.87467870645213],
            [-1.741593602992074, 52.872441661147114],
            [-1.742030672929826, 52.871892460048457],
            [-1.742851978067996, 52.871684786674635],
            [-1.744908744314233, 52.871829451922288],
            [-1.744501843858406, 52.870282403980191],
            [-1.744735422082895, 52.868704369344918],
            [-1.746007212029065, 52.868675630400297],
            [-1.745673084057932, 52.869163938784524],
            [-1.74606499270566, 52.869975619214763],
            [-1.747590261610733, 52.870561379460391],
            [-1.750652618937415, 52.868912895680367],
            [-1.752271076486954, 52.868790427663235],
            [-1.751847340846272, 52.870155930622488],
            [-1.752278957974156, 52.871060263822514],
            [-1.750518153852846, 52.872675569771609],
            [-1.750706952204335, 52.873692664717687],
            [-1.751596770200095, 52.87423478880519],
            [-1.754070304369316, 52.874533883564553],
            [-1.75600720517919, 52.875153646429908],
            [-1.758588489101104, 52.877203099197125],
            [-1.764658278605224, 52.878544795723201],
            [-1.768784496647346, 52.877996448691398],
            [-1.770688749668589, 52.878959301844837],
            [-1.773118262760854, 52.878943286571044],
            [-1.774235587399551, 52.879242062252473],
            [-1.776225961023, 52.880540293071832],
            [-1.776260898074893, 52.881259507947796],
            [-1.775389239707952, 52.881718120166845],
            [-1.77532859520541, 52.882239388645047],
            [-1.777224188549612, 52.883797215338433],
            [-1.780276216853566, 52.88239066501589],
            [-1.782514656554534, 52.883774646485122],
            [-1.783733851481968, 52.883946768724286],
            [-1.785602531022529, 52.882899296162421],
            [-1.786829247554043, 52.881533320663259],
            [-1.78697998347563, 52.880792867322796],
            [-1.788151803832454, 52.87999940190408],
            [-1.791155067312519, 52.880265405393672],
            [-1.79249633029728, 52.88068935428003],
            [-1.793665789558359, 52.883193127825798],
            [-1.79443542127623, 52.883850683647694],
            [-1.801381131057278, 52.886155669771462],
            [-1.802918161334427, 52.885746514158043],
            [-1.803002335242025, 52.883894846658059],
            [-1.804161074666977, 52.882989734178366],
            [-1.811056053665086, 52.880635786934782],
            [-1.812310063341068, 52.880665639354916],
            [-1.81198401856125, 52.881841831412984],
            [-1.813540186295813, 52.882456456232489],
            [-1.815813962782548, 52.882061770323389],
            [-1.818486546834326, 52.882590856981643],
            [-1.822846901735155, 52.882494052994957],
            [-1.823625562241767, 52.882862874645376],
            [-1.821773116094983, 52.885251281442542],
            [-1.822027064126431, 52.886021150020973],
            [-1.822648909956484, 52.88622793482422],
            [-1.82355758641069, 52.886068376688776],
            [-1.825866123853679, 52.884067153083869],
            [-1.8267120859698, 52.883968606571699],
            [-1.828353283571034, 52.88457327245883],
            [-1.827544341841083, 52.886578525358019],
            [-1.827957212642492, 52.887024994791254],
            [-1.829855419748569, 52.887309091253655],
            [-1.832719698934541, 52.886812446474167],
            [-1.83295956779882, 52.887431250542058],
            [-1.832118556192951, 52.888159102697706],
            [-1.832134473335198, 52.88865623591888],
            [-1.834089969890737, 52.889532743109363],
            [-1.833007547613681, 52.8900328340779],
            [-1.832711291998926, 52.890546608238537],
            [-1.833157437850743, 52.890866356319783],
            [-1.834688170492715, 52.890906248244796],
            [-1.835214165933774, 52.891339365171781],
            [-1.834023815684825, 52.892849717383704],
            [-1.832644211789881, 52.893303544257016],
            [-1.833989268750108, 52.894502808158698],
            [-1.834251563323303, 52.895493798487671],
            [-1.833127021656241, 52.896085522642672],
            [-1.830625740740253, 52.89596872268865],
            [-1.829899085888646, 52.896290403101368],
            [-1.83000204321855, 52.896571017432478],
            [-1.832514925880961, 52.899088896919373],
            [-1.833389364797967, 52.902107841294011],
            [-1.833941679364751, 52.902299187017221],
            [-1.835172015700791, 52.901683331102916],
            [-1.836004011981701, 52.901825613054555],
            [-1.836351494271858, 52.902326796755759],
            [-1.836152741755543, 52.904177424571472],
            [-1.838289374738792, 52.905330980146353],
            [-1.839960526580678, 52.904926922148789],
            [-1.840987469971459, 52.905041564204595],
            [-1.84260564358177, 52.906531447351483],
            [-1.84409604071857, 52.907195921673768],
            [-1.845326157203475, 52.908313100595095],
            [-1.845390904013869, 52.908921761170951],
            [-1.846919933926666, 52.908784403342814],
            [-1.847607648189358, 52.90899114114174],
            [-1.847581993229603, 52.909947570958806],
            [-1.846630865088055, 52.910643919534955],
            [-1.843674060615992, 52.910426126949602],
            [-1.842804956226381, 52.910624544862664],
            [-1.842312974841305, 52.911387985290752],
            [-1.842610592423924, 52.912971396255834],
            [-1.843578609832494, 52.913399664469082],
            [-1.847965469437806, 52.912970266690301],
            [-1.84781916768922, 52.914408368415316],
            [-1.847170587834049, 52.915312760160248],
            [-1.847605714197186, 52.91590930876329],
            [-1.84893437621457, 52.916154612543586],
            [-1.848955078754982, 52.916616688842907],
            [-1.848164772764678, 52.917218865312861],
            [-1.848193495997556, 52.9187893322649],
            [-1.84751059670152, 52.920133257972893],
            [-1.849946573399462, 52.921057756333852],
            [-1.851732545549591, 52.920276127916637],
            [-1.854571381846754, 52.9199578132556],
            [-1.855599252412053, 52.920807653829335],
            [-1.855731423024725, 52.922661405933837],
            [-1.856578370290403, 52.923373482094782],
            [-1.856397420894238, 52.923668112618941],
            [-1.854682561391094, 52.92407324641178],
            [-1.854634632428064, 52.925506979123952],
            [-1.855707530851444, 52.926723635651257],
            [-1.855659883835126, 52.927185627053021],
            [-1.854563845035127, 52.927547460147977],
            [-1.853171730821763, 52.927478331692441],
            [-1.852822267292859, 52.927885114658139],
            [-1.852711715571833, 52.928902564588221],
            [-1.854114164561185, 52.929451738610759],
            [-1.854302478698458, 52.930078521981997],
            [-1.854079747332469, 52.930841438903037],
            [-1.852955185845461, 52.931687744045938],
            [-1.852044109644026, 52.931866400774503],
            [-1.85006698303439, 52.930996461907768],
            [-1.849547455795155, 52.931091991724436],
            [-1.848881059541128, 52.932352343182203],
            [-1.850434623818135, 52.932671626565472],
            [-1.850755631799234, 52.933204194489754],
            [-1.850593743022682, 52.933989653955727],
            [-1.849281929814308, 52.93558988869718],
            [-1.84708508490204, 52.935820808231412],
            [-1.846538475493083, 52.936826004182542],
            [-1.845191619299664, 52.937337547623081],
            [-1.843039616048029, 52.93954069586372],
            [-1.841831595009527, 52.939893274038177],
            [-1.84113395298052, 52.939426701122017],
            [-1.840289313155269, 52.939689855356491],
            [-1.839461774396362, 52.941362543284313],
            [-1.837975978632628, 52.9415627887442],
            [-1.83552399911904, 52.940698254682751],
            [-1.833097915139796, 52.941252212214259],
            [-1.832339231354311, 52.943126306265306],
            [-1.832837187928303, 52.943656474041823],
            [-1.835343345236803, 52.94242843814105],
            [-1.836376875697722, 52.942606951675792],
            [-1.836469045717044, 52.943421505378566],
            [-1.835585648358173, 52.945246906026995],
            [-1.832463818862586, 52.945933827337349],
            [-1.829935514415117, 52.945523927735138],
            [-1.829699805977786, 52.946809953447762],
            [-1.82875853389495, 52.94774798032681],
            [-1.828858750106639, 52.948753123048739],
            [-1.830703988097315, 52.951483999218773],
            [-1.832286346538362, 52.952198187406999],
            [-1.834181948138015, 52.953918687687718],
            [-1.834480361328472, 52.954900728384239],
            [-1.831421199883473, 52.955748616163291],
            [-1.830557158250575, 52.95707240372078],
            [-1.829156082582587, 52.957914491008481],
            [-1.829165878327859, 52.959208056458237],
            [-1.828477313001491, 52.960921313749836],
            [-1.827693617232899, 52.961082887249901],
            [-1.826482973032359, 52.960420420141432],
            [-1.825548475491084, 52.961804295555332],
            [-1.825738520861042, 52.962302577413332],
            [-1.828344707239222, 52.963961281067753],
            [-1.828225489846641, 52.965494671600517],
            [-1.827557800465124, 52.966416001136842],
            [-1.829457422044032, 52.968360409842191],
            [-1.830188794048404, 52.971328797025024],
            [-1.829631120198361, 52.973041345252952],
            [-1.828370945150078, 52.973891713150849],
            [-1.828378793452937, 52.975295839693864],
            [-1.826115446047963, 52.977469743003169],
            [-1.824761099155937, 52.978356790323524],
            [-1.823526477567702, 52.97872172663579],
            [-1.821233956844679, 52.978475598200937],
            [-1.81528903179974, 52.979440055428697],
            [-1.806879863194855, 52.980197087690087],
            [-1.803227264934544, 52.981333635177641],
            [-1.801561547747483, 52.98308556350662],
            [-1.802622971992649, 52.983863096208729],
            [-1.804697550354016, 52.98458565357496],
            [-1.80471279677412, 52.985499880364472],
            [-1.802690590645274, 52.98536979859918],
            [-1.79883424734897, 52.986317998422905],
            [-1.798243615226871, 52.98552145471492],
            [-1.796782922194973, 52.985086589808247],
            [-1.796158305663323, 52.985510711273825],
            [-1.796023099692019, 52.986387825751578],
            [-1.794980151144307, 52.987700255332854],
            [-1.793412842176586, 52.988999181941843],
            [-1.793394968388883, 52.989623900090066],
            [-1.792212311218969, 52.989592177571815],
            [-1.791633208823429, 52.988272450206956],
            [-1.789963065235692, 52.988941008564424],
            [-1.788759359648513, 52.988953261617219],
            [-1.787525178073557, 52.989408615545926],
            [-1.788149488809205, 52.989990430223052],
            [-1.787122147317493, 52.990788636144202],
            [-1.787092882198907, 52.991287484133764],
            [-1.787656260763781, 52.991539288805043],
            [-1.789869661869329, 52.99161512946192],
            [-1.790396972226159, 52.991936074242261],
            [-1.789356178527921, 52.992742365795706],
            [-1.787178058483724, 52.993054911812841],
            [-1.786303878680594, 52.992382752202275],
            [-1.785355755425231, 52.99250869317197],
            [-1.785112759325849, 52.992841753884321],
            [-1.785999983055777, 52.993585859183462],
            [-1.784456650425499, 52.994759760173366],
            [-1.780445680774757, 52.994811759733018],
            [-1.779135479133551, 52.995808934238937],
            [-1.777624839248888, 52.996367047598575],
            [-1.773910780816781, 52.996308814026513],
            [-1.772293682704121, 52.995844586639002],
            [-1.771052628579238, 52.995838610769091],
            [-1.76781923811606, 52.999511623370346],
            [-1.762573583483594, 52.999651396910878],
            [-1.762653437631136, 53.000578341144262],
            [-1.764427674294831, 53.001753155687773],
            [-1.764104931620704, 53.002735933131241],
            [-1.764519101722691, 53.004122884587851],
            [-1.765712768271246, 53.004944153643216],
            [-1.766244233052023, 53.006977650929954],
            [-1.766071182392294, 53.009218311773274],
            [-1.764194092065594, 53.010447024238218],
            [-1.763560075370206, 53.011375246936716],
            [-1.762874069119159, 53.013355095020934],
            [-1.762026448225605, 53.015167417223829],
            [-1.759409426021446, 53.0145571873121],
            [-1.758972046256387, 53.014674060772805],
            [-1.757722661567884, 53.017126465275886],
            [-1.758315767829756, 53.018224348073311],
            [-1.762947962143383, 53.020550165559584],
            [-1.76373485729504, 53.021410193624767],
            [-1.762715595154991, 53.025139563126423],
            [-1.758988617643735, 53.026961369622484],
            [-1.757192243564941, 53.028778022039425],
            [-1.757418675370586, 53.030144833047352],
            [-1.756793209229691, 53.030800663452105],
            [-1.757016710258388, 53.031361143073596],
            [-1.759682999639778, 53.032943248147994],
            [-1.761547540837183, 53.033381171327456],
            [-1.759367814936996, 53.034650547301631],
            [-1.75904157885613, 53.037534499908084],
            [-1.760057066874329, 53.038899301949492],
            [-1.766004755523878, 53.041095493160483],
            [-1.767447676949263, 53.043466053556074],
            [-1.768197934805375, 53.044020346807812],
            [-1.77229866422445, 53.04422152279826],
            [-1.774438657408574, 53.043440844638788],
            [-1.778738057587773, 53.042995857269489],
            [-1.779659882, 53.043569273888771],
            [-1.778985247844911, 53.044826500196315],
            [-1.779973245320768, 53.047061224885766],
            [-1.780790880945302, 53.047904113065499],
            [-1.784699559604796, 53.049913115960884],
            [-1.783648374729337, 53.050109870705953],
            [-1.783363893735221, 53.050616339244826],
            [-1.784215398530632, 53.05187366252909],
            [-1.78414913000264, 53.054689827775171],
            [-1.783815184035143, 53.054939119176915],
            [-1.783207614813301, 53.054413950613743],
            [-1.78190473063253, 53.055388687529323],
            [-1.78246407146981, 53.055713317734558],
            [-1.782168147126488, 53.056111892916974],
            [-1.779304851140533, 53.057611397626239],
            [-1.777017494941298, 53.058204913041052],
            [-1.775656841863898, 53.059336782111963],
            [-1.778110142416917, 53.060439843813874],
            [-1.783062407004599, 53.06135236797811],
            [-1.785647143746232, 53.063093738045808],
            [-1.784234454215555, 53.065098450590739],
            [-1.784226450110582, 53.066702088268627],
            [-1.785071017317358, 53.067922533958551],
            [-1.785678719287916, 53.068162738114559],
            [-1.786007821151797, 53.07041778996463],
            [-1.787523563820999, 53.07236304117842],
            [-1.788811939827386, 53.07321479879684],
            [-1.789161012639504, 53.074783110529353],
            [-1.79005377011612, 53.075362683819236],
            [-1.789756247012519, 53.07638331710401],
            [-1.79084558782863, 53.07675648247502],
            [-1.791359653393614, 53.078182150136847],
            [-1.790253906777187, 53.079027877483547],
            [-1.788588490356531, 53.079228087246271],
            [-1.788608734819616, 53.080887503540708],
            [-1.791986998120907, 53.083681853419286],
            [-1.791919317571656, 53.08440535402265],
            [-1.7910863251978, 53.085007062054366],
            [-1.790833248857808, 53.085781474331505],
            [-1.791131751353436, 53.086419321511173],
            [-1.793132834022221, 53.086641245733034],
            [-1.794242851012474, 53.088376255651738],
            [-1.792881723265314, 53.089813942964454],
            [-1.792861067364302, 53.091007651986843],
            [-1.792298716446963, 53.09150826181979],
            [-1.79079232498477, 53.091765410349886],
            [-1.78861862083633, 53.091376843818232],
            [-1.787440867067213, 53.091906900355696],
            [-1.783563395885786, 53.092719722184079],
            [-1.782975046286825, 53.093316423436626],
            [-1.783210171654978, 53.09377529240853],
            [-1.785491800067471, 53.094998334512582],
            [-1.783037961093073, 53.095952124005095],
            [-1.782526965808524, 53.096598403246269],
            [-1.786370047251086, 53.099792864660046],
            [-1.784494720604986, 53.101489313935936],
            [-1.78354853486853, 53.101643109543922],
            [-1.783729382323404, 53.102810213489981],
            [-1.784521353896583, 53.103933478188935],
            [-1.787992700964989, 53.105423790790667],
            [-1.78822991094696, 53.105786470005434],
            [-1.786076962992979, 53.106798384754633],
            [-1.786248839664733, 53.107985243524681],
            [-1.788032398070764, 53.109202844637586],
            [-1.789759069995964, 53.109545687334879],
            [-1.793790971181663, 53.110469609644099],
            [-1.795364256732242, 53.112621593246523],
            [-1.797810661244227, 53.114037041806064],
            [-1.800114830345263, 53.114584762301099],
            [-1.800936953496606, 53.115140759483069],
            [-1.801879236152203, 53.118166229357207],
            [-1.805480776852065, 53.121656294877681],
            [-1.805743419789264, 53.122396517218341],
            [-1.807858355482401, 53.122945576027064],
            [-1.808635047833859, 53.123695608054682],
            [-1.809653786802221, 53.123782634133129],
            [-1.809920082535107, 53.125748051800997],
            [-1.810448409850762, 53.126244186002189],
            [-1.809343999324517, 53.126930981516175],
            [-1.809366527701909, 53.127243834210162],
            [-1.81025518230664, 53.128045271204201],
            [-1.81088576164561, 53.130110143149807],
            [-1.811524835953122, 53.129902610635057],
            [-1.812538044754128, 53.130265564779663],
            [-1.813317305333948, 53.131162089573643],
            [-1.811612370806684, 53.131760770895063],
            [-1.812281549795304, 53.132195993789139],
            [-1.814104679504424, 53.131917494823199],
            [-1.813577156881023, 53.132592642334536],
            [-1.812766182818315, 53.132797219319087],
            [-1.813332780982989, 53.133111822837215],
            [-1.813784586067472, 53.132667574928988],
            [-1.814118016528021, 53.132984507072933],
            [-1.815401809034158, 53.133008074816281],
            [-1.815267089354868, 53.133404279983971],
            [-1.816235412641987, 53.134155456490419],
            [-1.817317966598911, 53.133353505498029],
            [-1.819804094529941, 53.133943369067651],
            [-1.820058104977315, 53.13467994944596],
            [-1.821456886445553, 53.135824549969932],
            [-1.820717269952446, 53.13647693848884],
            [-1.820866940360757, 53.13678997950587],
            [-1.82187932714885, 53.136686324901497],
            [-1.82103700539347, 53.137592050763949],
            [-1.821502043558508, 53.137915451600037],
            [-1.821820515551115, 53.137534795622592],
            [-1.822160719862448, 53.138044977824208],
            [-1.821909094781538, 53.139613176795493],
            [-1.821398869699363, 53.139367015091636],
            [-1.821270922779426, 53.140301675662052],
            [-1.820643628949997, 53.140526356934046],
            [-1.821215542775672, 53.141400043854574],
            [-1.819657651991015, 53.142514127284706],
            [-1.819178609409678, 53.142669809868821],
            [-1.818478981270833, 53.14231008758734],
            [-1.817655253848262, 53.143018059982246],
            [-1.817629627177408, 53.143421625068257],
            [-1.818568523358792, 53.143768233045954],
            [-1.817733006838262, 53.144429445309484],
            [-1.818116642454023, 53.145277689586628],
            [-1.817233001975303, 53.145643987063877],
            [-1.816804621662467, 53.146533236386972],
            [-1.81735602034628, 53.147308929854141],
            [-1.816101414904028, 53.14770341484104],
            [-1.816770316161962, 53.147921078073303],
            [-1.81702486697372, 53.14924194578208],
            [-1.816563487259698, 53.149443488466581],
            [-1.816624086925084, 53.149960446249004],
            [-1.814711913322732, 53.150604695359007],
            [-1.814285956792553, 53.151254832712922],
            [-1.812814506505663, 53.151622877127345],
            [-1.812027660309269, 53.152726381164321],
            [-1.812576829892292, 53.154356940982176],
            [-1.81472295529802, 53.154645243450759],
            [-1.815381968517712, 53.155100206857774],
            [-1.815055706280005, 53.156207138920813],
            [-1.815614542882556, 53.15736308274451],
            [-1.816433344984643, 53.157883906226431],
            [-1.81607577517116, 53.158618650434221],
            [-1.816889862117382, 53.158848221870763],
            [-1.817034855037573, 53.159217889598281],
            [-1.817972056496495, 53.159337977376822],
            [-1.818157186214322, 53.159767930799525],
            [-1.819170951819922, 53.159822506598331],
            [-1.819232199025399, 53.160552500807363],
            [-1.8199311599774, 53.160786371298535],
            [-1.820135615804181, 53.161250508635156],
            [-1.821135868834144, 53.161326620732659],
            [-1.821020959029453, 53.16162577978676],
            [-1.822151268043048, 53.162082310154034],
            [-1.822749160994471, 53.162898495907648],
            [-1.823849372890304, 53.163046645075326],
            [-1.823739309132847, 53.163627167245693],
            [-1.825205179463499, 53.163975397730688],
            [-1.82483826639421, 53.164825211978403],
            [-1.825692156003847, 53.164869609484228],
            [-1.825690752314233, 53.165216579938296],
            [-1.826441190421854, 53.165323743341261],
            [-1.826988885232596, 53.166379838492375],
            [-1.828100252366886, 53.166382343876109],
            [-1.829024764992894, 53.166731543658749],
            [-1.828793739345886, 53.167276839898811],
            [-1.830251860255314, 53.167735560561397],
            [-1.833014336278562, 53.170526922296943],
            [-1.832016667285396, 53.17279702044921],
            [-1.834289217610949, 53.173549873458654],
            [-1.83448777332718, 53.174048134689897],
            [-1.835951381363251, 53.174320722072864],
            [-1.836414548896866, 53.175270586715911],
            [-1.837186138065386, 53.175376812227967],
            [-1.837178777561027, 53.175740852940699],
            [-1.838630499003492, 53.176410700728844],
            [-1.839405595707073, 53.176385678547035],
            [-1.839298917673084, 53.176907790349752],
            [-1.840805473120322, 53.176929585274053],
            [-1.843063368043926, 53.178111021297546],
            [-1.8437682697426, 53.17764632102196],
            [-1.844462449101471, 53.178075099583758],
            [-1.843954053195579, 53.178401631948404],
            [-1.845142782186746, 53.178197334525464],
            [-1.845662104071764, 53.178576440058507],
            [-1.846162048188232, 53.178518657701318],
            [-1.846538463340003, 53.179116005385247],
            [-1.846238928922834, 53.179615402717943],
            [-1.846957954514058, 53.179805093840471],
            [-1.847450012891604, 53.179442572254558],
            [-1.847497460314577, 53.179987360233895],
            [-1.848522240368238, 53.180459683633352],
            [-1.848238103803279, 53.180839553068608],
            [-1.849689755681923, 53.182056689036386],
            [-1.850914174650835, 53.182370139463465],
            [-1.850812175836322, 53.182873390142376],
            [-1.851750597058943, 53.182816131628442],
            [-1.852474205048541, 53.183875021033394],
            [-1.854398426373406, 53.184363682930282],
            [-1.854287390687349, 53.184894790934756],
            [-1.855496214135326, 53.184984351637318],
            [-1.855970241584416, 53.185535044671006],
            [-1.859116863804945, 53.186584207765534],
            [-1.859336338872935, 53.187656840861948],
            [-1.860464854220977, 53.187617715330859],
            [-1.860934962436563, 53.188483893616343],
            [-1.862926900738748, 53.188480804118448],
            [-1.863988339729721, 53.188836180208597],
            [-1.865344093687703, 53.189839977616685],
            [-1.866245631929428, 53.189654022305568],
            [-1.867492982024286, 53.190893182269434],
            [-1.868579718431011, 53.190837753500176],
            [-1.869303399436823, 53.191560354973596],
            [-1.869474959403643, 53.191250426295916],
            [-1.870113397418476, 53.191461462335006],
            [-1.870025708447153, 53.192249691607834],
            [-1.871045224485966, 53.192160908304196],
            [-1.871863251469862, 53.192887191448968],
            [-1.871409044011786, 53.193122211725637],
            [-1.871728667892703, 53.193851553407548],
            [-1.873848059576097, 53.193854710283354],
            [-1.873483945373289, 53.194501524058687],
            [-1.874150669497717, 53.194788074829468],
            [-1.873976916787681, 53.195342505093386],
            [-1.874741115898546, 53.195057463001156],
            [-1.875884918991781, 53.195489224387224],
            [-1.876375966167805, 53.194935120275147],
            [-1.879055322928645, 53.195964393294112],
            [-1.879978991959759, 53.195918583051274],
            [-1.880468796254836, 53.196334360180735],
            [-1.880890649683488, 53.195885338090619],
            [-1.881377434098928, 53.196313692891565],
            [-1.882506849531739, 53.196569194420839],
            [-1.882723635828797, 53.196113671733912],
            [-1.883837498977736, 53.196586676058729],
            [-1.884742160002419, 53.196375415183788],
            [-1.884937782050105, 53.196549089013061],
            [-1.884621517366388, 53.196709684684365],
            [-1.8870691634337, 53.198310245012514],
            [-1.888429657783411, 53.198385232308738],
            [-1.889332033808223, 53.19906922567128],
            [-1.890668009723302, 53.199378774074155],
            [-1.891362124927656, 53.198970413963131],
            [-1.893081919707076, 53.199042078807778],
            [-1.894011638427226, 53.199600216980322],
            [-1.894668321879516, 53.199183714441617],
            [-1.89557995484786, 53.199802049801832],
            [-1.896056925995729, 53.199413247895755],
            [-1.896956730259438, 53.199354700876668],
            [-1.897274251704282, 53.199911385057113],
            [-1.898636423766738, 53.199938616236111],
            [-1.898211710837283, 53.201025907632761],
            [-1.899054808319348, 53.202181691678391],
            [-1.900198853644519, 53.203342215699948],
            [-1.901581167304239, 53.203120438587085],
            [-1.902497026962663, 53.20393647891099],
            [-1.900715420966816, 53.204609176150825],
            [-1.900912830414495, 53.206653405759951],
            [-1.900074963812018, 53.208346207863833],
            [-1.905866446164112, 53.211728919621713],
            [-1.908395543911691, 53.211713806010792],
            [-1.911125928227647, 53.212114073209143],
            [-1.911769029453516, 53.213223772979887],
            [-1.908986993757869, 53.215263053051792],
            [-1.91057491132569, 53.216431002905829],
            [-1.912788233030241, 53.217190397157545],
            [-1.917456056268105, 53.218017088433641],
            [-1.918559874858782, 53.21796840675232],
            [-1.918286566633781, 53.218361931275076],
            [-1.917334090647222, 53.218361276563179],
            [-1.912397885438031, 53.220617554353431],
            [-1.910779546863776, 53.221022652685257],
            [-1.910758720619602, 53.221677024030782],
            [-1.910130048187119, 53.221499473094866],
            [-1.910562392103541, 53.221017097115556],
            [-1.907992499942248, 53.220242107124264],
            [-1.907394345478164, 53.219120737640424],
            [-1.900833957756496, 53.213598124817267],
            [-1.900083325234413, 53.21184107926333],
            [-1.897601020823226, 53.210434919823044],
            [-1.894857164475371, 53.211967831879853],
            [-1.897212124177472, 53.215908792100436],
            [-1.893754126256989, 53.217819491106489],
            [-1.895405600195962, 53.218585898204907],
            [-1.892389431534034, 53.219174685227998],
            [-1.888065134934423, 53.218263738448222],
            [-1.886947866448254, 53.21771526616169],
            [-1.885870297245478, 53.216889965018979],
            [-1.885873469721226, 53.215693553373292],
            [-1.883575106132811, 53.212254001392452],
            [-1.881629787761953, 53.207427773268854],
            [-1.882378630747474, 53.206247377485973],
            [-1.883558224040094, 53.20576313504742],
            [-1.88438842158511, 53.204919887806078],
            [-1.882648707695032, 53.203286713214368],
            [-1.881490621386482, 53.203592988740596],
            [-1.876618203436322, 53.203378613960417],
            [-1.87803377283689, 53.20520121097907],
            [-1.87777612467889, 53.206304779250466],
            [-1.878476506328921, 53.206941904962193],
            [-1.877419961554289, 53.207297682776975],
            [-1.876939322684319, 53.20678482373112],
            [-1.876225803451073, 53.207080718606676],
            [-1.876435315278544, 53.207636446798404],
            [-1.873191935049554, 53.207255515015845],
            [-1.874381662380944, 53.211039281511248],
            [-1.873579743238352, 53.212853283794047],
            [-1.872829315287821, 53.212928892269169],
            [-1.873244224863478, 53.214411594014244],
            [-1.872710485319851, 53.215640700155532],
            [-1.862837471496724, 53.205280903608148],
            [-1.855606558295771, 53.202953251169092],
            [-1.853340892811143, 53.202689812298253],
            [-1.847747203597881, 53.199071082107295],
            [-1.841347432659654, 53.197142724234126],
            [-1.842426473534724, 53.194744123740925],
            [-1.831607142353828, 53.197569834097258],
            [-1.831638181887525, 53.201497116009342],
            [-1.832286373621604, 53.201501624153323],
            [-1.832303080317211, 53.202976720814625],
            [-1.831530574960312, 53.202981026237666],
            [-1.831507555692353, 53.204271794854876],
            [-1.827665492815354, 53.204013722012988],
            [-1.827490164351736, 53.204428754298775],
            [-1.827947360669464, 53.204659529189186],
            [-1.827946968830847, 53.205506279606993],
            [-1.831957196337053, 53.20567559036234],
            [-1.831255049442593, 53.210631955397716],
            [-1.836318323690299, 53.212789139438826],
            [-1.844099555221298, 53.215081823632403],
            [-1.850810565764633, 53.217457161080759],
            [-1.853239703164523, 53.216558590690553],
            [-1.860753759382792, 53.222301569739912],
            [-1.86560451304783, 53.223880172877301],
            [-1.869598118387639, 53.22421808571363],
            [-1.871211324008904, 53.224697142980624],
            [-1.87426814738149, 53.226829853080012],
            [-1.869422875499046, 53.228658374638371],
            [-1.866969750628806, 53.229935675872312],
            [-1.861889348654907, 53.231070590435337],
            [-1.858240520298062, 53.233214642819291],
            [-1.857480364791116, 53.234301384929658],
            [-1.858266432625368, 53.236260983070672],
            [-1.85643135352591, 53.237530708102447],
            [-1.856651516725381, 53.238903563875617],
            [-1.854736296102915, 53.240784404989832],
            [-1.853879782899435, 53.243262472474036],
            [-1.851607049618155, 53.244432708913948],
            [-1.848138363163909, 53.24474924881455],
            [-1.84472266440334, 53.245731826842011],
            [-1.844673363253511, 53.246933565187689],
            [-1.846884369323718, 53.248036649162763],
            [-1.846878043618545, 53.248970576924464],
            [-1.846024271055045, 53.249675099610442],
            [-1.844638594560776, 53.249917800257435],
            [-1.844442132738136, 53.250367883076386],
            [-1.84304283788207, 53.250637513806495],
            [-1.841863955633349, 53.250468767074793],
            [-1.84071005315364, 53.249628579069658],
            [-1.837577911633917, 53.249195594628688],
            [-1.834587503557586, 53.250112841373728],
            [-1.833191383360206, 53.251480791512861],
            [-1.830211525011858, 53.251181759374504],
            [-1.828779822729328, 53.251280389578376],
            [-1.828102149904534, 53.251713574681311],
            [-1.825914052245751, 53.252056473262222],
            [-1.823256706354067, 53.252057063904211],
            [-1.822779984446355, 53.251720176431867],
            [-1.820649154164228, 53.251977669858562],
            [-1.816411586685369, 53.25385078608911],
            [-1.813536552936308, 53.25459779381989],
            [-1.813754375043941, 53.255177011886239],
            [-1.816044229322024, 53.255283033893051],
            [-1.816913495923807, 53.255656505806307],
            [-1.817523626998526, 53.257049802976553],
            [-1.816150660943691, 53.258081407300054],
            [-1.807698385345101, 53.257957531755558],
            [-1.805438307417906, 53.256215438651672],
            [-1.802584996659676, 53.255747832152515],
            [-1.798424148065469, 53.256038408559952],
            [-1.795092062983291, 53.255091609583651],
            [-1.794128060218904, 53.255136692835492],
            [-1.790287744740837, 53.25639563058435],
            [-1.791844636637045, 53.25804510165861],
            [-1.7947847775546, 53.258857384812679],
            [-1.795408939692057, 53.258738006283082],
            [-1.798315710081415, 53.260296214157798],
            [-1.798527479887853, 53.261488484394384],
            [-1.796842406356024, 53.262770125976715],
            [-1.798892308121733, 53.264572251080722],
            [-1.799513624663257, 53.26571936501616],
            [-1.799294029418667, 53.266510907215668],
            [-1.800099066032204, 53.269425518884916],
            [-1.799605667775028, 53.270409861017157],
            [-1.800060949664322, 53.271170175138266],
            [-1.801982795470727, 53.272892928552992],
            [-1.803518376906555, 53.273202880794955],
            [-1.804595284677697, 53.274135883720199],
            [-1.80977238618479, 53.275346040106001],
            [-1.81157184162764, 53.277750688967167],
            [-1.811666042038348, 53.279180948462482],
            [-1.812551940501959, 53.28031222842484],
            [-1.816873095043926, 53.281996232988774],
            [-1.81756632877801, 53.282628303998699],
            [-1.817486676945962, 53.284780987008212],
            [-1.817879296973654, 53.284866080505395],
            [-1.816813078791843, 53.286216357021324],
            [-1.816859204233741, 53.287006538159609],
            [-1.821418337204719, 53.28974152359973],
            [-1.823354660473245, 53.291273386538968],
            [-1.823889337550388, 53.292217992968517],
            [-1.822428483793978, 53.293949756596781],
            [-1.824661814740367, 53.298851911099788],
            [-1.824110647154397, 53.298976942997975],
            [-1.824084309666207, 53.299544991425442],
            [-1.822686428717897, 53.300866963351524],
            [-1.82262743157565, 53.30279585464168],
            [-1.816484643045128, 53.305832851966017],
            [-1.813805728891574, 53.307957233639193],
            [-1.812997834456881, 53.309790567197837],
            [-1.811148358614184, 53.310922032687728],
            [-1.808179796699486, 53.314250314486991],
            [-1.806106470599866, 53.317013689230677],
            [-1.802229364373843, 53.318286425556906],
            [-1.797741117488765, 53.319229016459168],
            [-1.787960125281075, 53.323996737720158],
            [-1.784069088744411, 53.325417171953944],
            [-1.781367148003348, 53.327084153512196],
            [-1.769009939227158, 53.333035676939517],
            [-1.766189307758944, 53.335342077805834],
            [-1.765954511737662, 53.336262957296967],
            [-1.761767377992721, 53.337934664204042],
            [-1.76006531470615, 53.338297096944977],
            [-1.759358451629369, 53.33821028281087],
            [-1.758201680104859, 53.337214698628472],
            [-1.757224493329787, 53.337132717577944],
            [-1.755757592159569, 53.336023221464025],
            [-1.754389996171402, 53.336986700289572],
            [-1.753787807317014, 53.336983662957671],
            [-1.751217792213702, 53.335261500987876],
            [-1.749354450107043, 53.334959181707923],
            [-1.745691347898639, 53.33605075509869],
            [-1.743611205449964, 53.336092150866591],
            [-1.741108035879203, 53.336801352051921],
            [-1.737187054792633, 53.336784700006319],
            [-1.730207381088262, 53.338613616810441],
            [-1.730434658396262, 53.337818631832654],
            [-1.731624348991038, 53.337296371768794],
            [-1.731252377215332, 53.336982729392489],
            [-1.730859139604628, 53.337185887065615],
            [-1.730642534680103, 53.337002928611483],
            [-1.731343230015949, 53.336146087542069],
            [-1.732338983505799, 53.336369444512982],
            [-1.732010897320137, 53.335762871743277],
            [-1.732866079771298, 53.33589062885131],
            [-1.733169497086503, 53.335397828360627],
            [-1.733827028471153, 53.335432553484559],
            [-1.734018626384114, 53.335049163521532],
            [-1.735292433974662, 53.334746377914037],
            [-1.729271282784327, 53.333227277997857],
            [-1.724981754881348, 53.331493447952134],
            [-1.727279013884403, 53.328923455187081],
            [-1.727483501290878, 53.326755848635955],
            [-1.727589095036349, 53.319127377601347],
            [-1.725484220140257, 53.318937390448234],
            [-1.720467147104317, 53.319213391297019],
            [-1.718942930004936, 53.319756327419768],
            [-1.712560189035672, 53.322412579566105],
            [-1.711693267732418, 53.324522835603787],
            [-1.706245500811471, 53.325100117800844],
            [-1.700450237736547, 53.323607978983688],
            [-1.699140661702397, 53.330080148658759],
            [-1.699551697357955, 53.3314178026611],
            [-1.699024496458293, 53.33250680399172],
            [-1.696868548871413, 53.33478807502388],
            [-1.695261481629366, 53.335668472615332],
            [-1.691339068913308, 53.336699290543422],
            [-1.685537119554587, 53.335565063124747],
            [-1.685619125601716, 53.33625830721914],
            [-1.683187165523641, 53.337562432091694],
            [-1.677056102751667, 53.342241702189867],
            [-1.676999626388122, 53.342759297717464],
            [-1.675104251358171, 53.344524927618387],
            [-1.67228203919574, 53.34862863955572],
            [-1.671071440339529, 53.349585302545435],
            [-1.671627999119826, 53.351053785557809],
            [-1.672997696198718, 53.352280002516956],
            [-1.673169763600583, 53.352960915798562],
            [-1.671930396701917, 53.35452064786363],
            [-1.67087473995326, 53.357966694869447],
            [-1.670158894653682, 53.365062164645444],
            [-1.673313255345366, 53.369376394724071],
            [-1.663757735674087, 53.380843655888611],
            [-1.661807538708789, 53.389629026265176],
            [-1.66186097915407, 53.391425103013553],
            [-1.653818503305987, 53.391893751522161],
            [-1.653531430682, 53.38483505376022],
            [-1.65602240158842, 53.381691736443926],
            [-1.661777962568089, 53.376276336195708],
            [-1.66219356447088, 53.375233032205536],
            [-1.663292717401755, 53.374231205000562],
            [-1.662709076391958, 53.373145528579563],
            [-1.663914584431054, 53.369314369978319],
            [-1.663951794482293, 53.36687585403935],
            [-1.657878126332849, 53.364158422666719],
            [-1.630593364444425, 53.356143762353476],
            [-1.628349720654329, 53.353781768271091],
            [-1.625605102167784, 53.352044672713824],
            [-1.615628740433287, 53.350831869376009],
            [-1.590549226896974, 53.345903610766022],
            [-1.591465779595186, 53.344930576414882],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000036",
        LAD13CDO: "17UG",
        LAD13NM: "Erewash",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.307029528550077, 53.003451792149789],
            [-1.307268952091701, 53.003017203586374],
            [-1.306334922517375, 53.003276946529319],
            [-1.305744208959761, 53.002664023335832],
            [-1.306341924676136, 53.002467946681612],
            [-1.304552569182796, 53.001705990571374],
            [-1.304925964542295, 53.001373769274856],
            [-1.303741343826405, 53.00109446462784],
            [-1.303682492299694, 53.000584424285336],
            [-1.304291864684265, 53.000496298294223],
            [-1.304232258404811, 53.000125588638966],
            [-1.302842569242341, 53.000276563950266],
            [-1.303548766988832, 52.999726956753314],
            [-1.302692975663174, 52.999665310498408],
            [-1.302452153378467, 52.999167686597282],
            [-1.301916285172278, 52.999041389075828],
            [-1.30249875199308, 52.998772428490689],
            [-1.302358333617771, 52.998424616186639],
            [-1.303006024161638, 52.998360992783418],
            [-1.302948586581687, 52.998041534318816],
            [-1.30361092337619, 52.997993275349494],
            [-1.302470717716389, 52.997182946991487],
            [-1.303139739432916, 52.996811113221078],
            [-1.301741871974043, 52.995904879679848],
            [-1.302343648446713, 52.994989698591134],
            [-1.301582129359757, 52.994816229977737],
            [-1.301440810400523, 52.994340762165031],
            [-1.301057572646242, 52.994638755437059],
            [-1.300751712002489, 52.994388851627662],
            [-1.300676753245536, 52.994048613279162],
            [-1.301409353421741, 52.993612439527553],
            [-1.300373499308863, 52.993176660204057],
            [-1.30050589846165, 52.992635380518202],
            [-1.30155874961778, 52.992293679037736],
            [-1.30072330737616, 52.991811435583337],
            [-1.301237964287647, 52.991030587781012],
            [-1.300492790792429, 52.990126372740669],
            [-1.299239829986084, 52.989487944314732],
            [-1.297690731965311, 52.989659494087732],
            [-1.298142468191654, 52.989361915678757],
            [-1.297765095426443, 52.988840102979609],
            [-1.298097543415434, 52.988549011894364],
            [-1.297273288283005, 52.988394022610791],
            [-1.296711672199963, 52.987752458109874],
            [-1.295479894069751, 52.987834163247129],
            [-1.295273971059375, 52.986848607348023],
            [-1.294681786686676, 52.986898134511137],
            [-1.29473345513866, 52.986377956291008],
            [-1.294651354322128, 52.985748213391354],
            [-1.295358434571905, 52.985410808281948],
            [-1.295655439804765, 52.985649886893725],
            [-1.296184073048535, 52.984926674872689],
            [-1.296749641375221, 52.98513857366035],
            [-1.297473965490126, 52.984654731182005],
            [-1.293461268847986, 52.977066398426281],
            [-1.290679965413835, 52.970656586793936],
            [-1.289626305092146, 52.969711804295883],
            [-1.288813875426312, 52.969857970412455],
            [-1.288667897015931, 52.969316834384173],
            [-1.288379520546161, 52.969740307730227],
            [-1.287851599459045, 52.969789286023463],
            [-1.288406244622657, 52.969019517720277],
            [-1.287627977214055, 52.968809000898815],
            [-1.287713498327686, 52.96768224208185],
            [-1.288257315118762, 52.967570433766014],
            [-1.289420140181414, 52.966301791340044],
            [-1.288531913213705, 52.96589914878539],
            [-1.289289169780701, 52.965390381903475],
            [-1.288668446416369, 52.96492821074699],
            [-1.289165942996405, 52.964461039643908],
            [-1.288646069470306, 52.960938576701835],
            [-1.289467086892917, 52.960803250552949],
            [-1.290566548699207, 52.959754458040038],
            [-1.291695939343048, 52.956141153003763],
            [-1.292504868833044, 52.955554465191767],
            [-1.291663473191283, 52.955394837808335],
            [-1.291851867557769, 52.954978850872529],
            [-1.290657037903914, 52.954132116931788],
            [-1.290223070874447, 52.954545737452648],
            [-1.288999520305241, 52.954000859854766],
            [-1.288227739261009, 52.954499650045761],
            [-1.287437512477277, 52.954398730171576],
            [-1.286433082598272, 52.953837160142044],
            [-1.285753106678391, 52.952827160693225],
            [-1.28415231904054, 52.952509196487568],
            [-1.282417360118369, 52.951215946733988],
            [-1.282407748391997, 52.950177609357794],
            [-1.281034836206484, 52.949139126234627],
            [-1.282145705080948, 52.947934961554303],
            [-1.28209172821572, 52.947509435304099],
            [-1.281263595238703, 52.947189801674213],
            [-1.281371640227424, 52.946417363097929],
            [-1.281914119674402, 52.946371198872875],
            [-1.282776190109877, 52.94553768782464],
            [-1.282904807293316, 52.945139332532101],
            [-1.281715985760699, 52.945035070243648],
            [-1.282204664736813, 52.944371904034639],
            [-1.280269859534091, 52.941603144389269],
            [-1.281228841765444, 52.941113627601091],
            [-1.281137549295717, 52.940785859752864],
            [-1.280479377682465, 52.939467620812529],
            [-1.279049775919368, 52.938908805500674],
            [-1.279416853824895, 52.938039953146436],
            [-1.278843441962982, 52.937625658148093],
            [-1.27916352671101, 52.936808660164331],
            [-1.277846651671064, 52.935740811158951],
            [-1.278381621940421, 52.934348895924458],
            [-1.278159570495411, 52.93365535946721],
            [-1.278215201759444, 52.932815182728554],
            [-1.280618440221843, 52.930451138761875],
            [-1.281472960283899, 52.928977540156254],
            [-1.284978170317087, 52.92687896008826],
            [-1.285781768582392, 52.926045972886385],
            [-1.285563781828234, 52.925100768813017],
            [-1.283687165464192, 52.922629955544629],
            [-1.282524337716904, 52.921439927459602],
            [-1.282826420554161, 52.91926989284466],
            [-1.283528816393661, 52.918615200043071],
            [-1.28353981212634, 52.916776917536758],
            [-1.282058078587519, 52.911137860866944],
            [-1.281806181866009, 52.910274246684523],
            [-1.280172697043114, 52.908705586143284],
            [-1.274984224539959, 52.905442322101884],
            [-1.273108471244771, 52.905138705194183],
            [-1.267621497348528, 52.905264132263063],
            [-1.26525393168892, 52.905032871187366],
            [-1.259644095104914, 52.903468040534584],
            [-1.25647965920871, 52.901766345629056],
            [-1.254011965397187, 52.901926176288271],
            [-1.2534075972732, 52.902403320251494],
            [-1.251338754033245, 52.902019037667849],
            [-1.250803742030248, 52.902610772288149],
            [-1.24763440421049, 52.903190350618026],
            [-1.246620857858046, 52.903080550053097],
            [-1.24512161628911, 52.902173881618573],
            [-1.247517279495913, 52.899909331560018],
            [-1.246986514678019, 52.899310862751612],
            [-1.247428561467489, 52.898679901283408],
            [-1.246840232990502, 52.898487393834024],
            [-1.246218065229657, 52.897503590105494],
            [-1.244524743397913, 52.897083813135147],
            [-1.244647066694656, 52.896546117708922],
            [-1.243042928084075, 52.896385784876422],
            [-1.241758797063331, 52.895265592307815],
            [-1.241054310844495, 52.895344697633597],
            [-1.240297455834538, 52.895018035827839],
            [-1.240461988689905, 52.894704453768547],
            [-1.239627673528141, 52.894218177619585],
            [-1.239710810576362, 52.89226977702365],
            [-1.24125377343307, 52.892093555932234],
            [-1.241286719100534, 52.892420086669738],
            [-1.243863749644406, 52.891500702088408],
            [-1.245861493762977, 52.890296213941944],
            [-1.246403607584646, 52.88911842741468],
            [-1.246249840008081, 52.888214901820014],
            [-1.245242592200935, 52.886992222336232],
            [-1.240692480548131, 52.884914513430289],
            [-1.238990277883827, 52.88350484837796],
            [-1.238452683959206, 52.881787095182801],
            [-1.240060507800162, 52.880089373552394],
            [-1.242621363633027, 52.879119570957705],
            [-1.244831524417848, 52.878743492609907],
            [-1.25025652754288, 52.879523084870222],
            [-1.253616184893619, 52.879266460845301],
            [-1.25583369947147, 52.878108132954004],
            [-1.259913945302271, 52.875052899007095],
            [-1.266109662800947, 52.873292522598327],
            [-1.26789454801181, 52.873349381641709],
            [-1.271480665615646, 52.874971566325421],
            [-1.274658679198051, 52.875510607125904],
            [-1.278433046144174, 52.875574043166203],
            [-1.280611850282119, 52.875267228016149],
            [-1.286007263006212, 52.872611900901759],
            [-1.289492575696654, 52.872438621452453],
            [-1.295941608108902, 52.874755862666092],
            [-1.298898995733909, 52.875357671105327],
            [-1.302335600578679, 52.875545106226902],
            [-1.306000642797273, 52.877061530094487],
            [-1.307464356932177, 52.87565242191814],
            [-1.307807438765096, 52.874074061446365],
            [-1.309337594882034, 52.873335936209926],
            [-1.310689356622206, 52.873163995978651],
            [-1.316165670371227, 52.873450046292326],
            [-1.319312907072995, 52.872850538632271],
            [-1.322830060647724, 52.872342053035759],
            [-1.3238840787197, 52.872968333317722],
            [-1.323747802647673, 52.873706496795251],
            [-1.321355705629206, 52.873329680268725],
            [-1.320600599514454, 52.87354291124246],
            [-1.320267883117899, 52.874678183340627],
            [-1.319545318882811, 52.875183753392101],
            [-1.320172825219684, 52.875911901284624],
            [-1.321978149862238, 52.876384278859497],
            [-1.325736851350801, 52.875358389623379],
            [-1.326919592977569, 52.874298938455851],
            [-1.327549453489581, 52.874497577678881],
            [-1.328677050385612, 52.874696330094729],
            [-1.330169995811467, 52.875677422433313],
            [-1.329978945566379, 52.878144866825934],
            [-1.332824953831861, 52.876705479214579],
            [-1.335229951641138, 52.876305441850135],
            [-1.336138719974052, 52.878483288548203],
            [-1.336934336785732, 52.878732245935481],
            [-1.340445138543478, 52.879345992856926],
            [-1.3426390291429, 52.879400401483217],
            [-1.343472203363396, 52.88070848653998],
            [-1.344971469252521, 52.880615179711413],
            [-1.346307613446112, 52.879906074522502],
            [-1.347239843102111, 52.87977096330858],
            [-1.348544238978847, 52.879986678639384],
            [-1.349975434144535, 52.880766715175632],
            [-1.349805976613416, 52.881267402229966],
            [-1.34749253828816, 52.882141984342731],
            [-1.346865656069517, 52.882819944382895],
            [-1.3481183001408, 52.885799650162461],
            [-1.347208706479133, 52.885903429551846],
            [-1.344023444160231, 52.885046268985342],
            [-1.34179478402749, 52.885306326557078],
            [-1.340982412247884, 52.886349100481148],
            [-1.341650791696126, 52.887552903330153],
            [-1.339058849465233, 52.888628041758224],
            [-1.338825299661468, 52.889228139746471],
            [-1.339303114071295, 52.889669484701734],
            [-1.341080395226921, 52.890082082383579],
            [-1.342759809925721, 52.889596960825628],
            [-1.344598311732792, 52.889992764358183],
            [-1.344772556744885, 52.890659846508967],
            [-1.345704923184689, 52.89072880920191],
            [-1.346541596211935, 52.890242586895361],
            [-1.34541045742047, 52.888667695072897],
            [-1.346457067711954, 52.88795609434262],
            [-1.348281247037957, 52.887817786452786],
            [-1.348703430107328, 52.888604883805101],
            [-1.350305752051035, 52.889091896063434],
            [-1.354369455390974, 52.888773342846768],
            [-1.354838578476335, 52.889309866324432],
            [-1.357050203154927, 52.889808184320863],
            [-1.356782433490358, 52.890427009698044],
            [-1.355991650046632, 52.890431716198485],
            [-1.355442908442584, 52.8909528280142],
            [-1.355489408292647, 52.891525710351324],
            [-1.356350427807831, 52.89169758146204],
            [-1.355330759404082, 52.893002720716616],
            [-1.356185062670084, 52.894332401487496],
            [-1.357600317044056, 52.895125740714064],
            [-1.358539277029632, 52.89525936221564],
            [-1.359272960110814, 52.894495618876512],
            [-1.359292595394515, 52.893667793828136],
            [-1.361422827182982, 52.892338928608893],
            [-1.362801438741415, 52.892794903729701],
            [-1.361524354312057, 52.894635386742408],
            [-1.361766690356092, 52.895948252867143],
            [-1.362398173878039, 52.896372349944706],
            [-1.364739861532252, 52.896647383055971],
            [-1.366288900851431, 52.897150973114741],
            [-1.367615483101832, 52.895577690663416],
            [-1.370829605057477, 52.894731761344957],
            [-1.372270501296325, 52.89469533274081],
            [-1.373189756070174, 52.895270117245055],
            [-1.374290626246592, 52.898306267820928],
            [-1.378184713829503, 52.901058603344438],
            [-1.383103586773897, 52.901303580021988],
            [-1.384720765649358, 52.901851330474052],
            [-1.38651076910827, 52.901323014469277],
            [-1.388267719645834, 52.901449832598786],
            [-1.391114423816626, 52.904228706747396],
            [-1.391303916306842, 52.905029738170271],
            [-1.390649269339937, 52.906228272575689],
            [-1.391763862561522, 52.906691544413427],
            [-1.391774911808674, 52.907389181757111],
            [-1.388916172659528, 52.9096767144637],
            [-1.387797298803583, 52.911413110204748],
            [-1.388158838457382, 52.918050072559929],
            [-1.38937764740209, 52.920006146345294],
            [-1.389611660722109, 52.92272125492218],
            [-1.387098725691775, 52.922349647131142],
            [-1.3863025311733, 52.92385396429971],
            [-1.38393680269447, 52.923797679424602],
            [-1.383078240411988, 52.925912932207211],
            [-1.38530316695711, 52.926361343465771],
            [-1.386551449763356, 52.926659947580902],
            [-1.386648558477764, 52.92705148823768],
            [-1.387710697508069, 52.927049772019444],
            [-1.390833498667746, 52.928079820790686],
            [-1.396468181128458, 52.928530163618554],
            [-1.396252067365827, 52.929308447970833],
            [-1.397166170772248, 52.929900993955094],
            [-1.401005950805701, 52.930537075777991],
            [-1.403577377357235, 52.932216638959076],
            [-1.40419555250977, 52.932269180262836],
            [-1.404844991570101, 52.931669244430594],
            [-1.40787475411152, 52.931066801301327],
            [-1.408801535836298, 52.931288957093194],
            [-1.410361361811631, 52.931017133264454],
            [-1.41450221653769, 52.931192216094956],
            [-1.416165012295254, 52.931336133331122],
            [-1.417458900224519, 52.931699359335745],
            [-1.419256945114882, 52.931410607069907],
            [-1.420227734924559, 52.931907065056805],
            [-1.423571190812042, 52.935674594770617],
            [-1.423967517712972, 52.935632467627364],
            [-1.422901101817, 52.938513787401483],
            [-1.416433997065365, 52.945742044321847],
            [-1.409283609033419, 52.950303901854767],
            [-1.420256401809746, 52.950699501797168],
            [-1.424061644001142, 52.95028920051363],
            [-1.42843145813602, 52.950287811769172],
            [-1.431616892849042, 52.950600628296009],
            [-1.434036891069403, 52.951290867509151],
            [-1.441083830819907, 52.949485874914437],
            [-1.445067480191237, 52.949182713404142],
            [-1.449942046710682, 52.94809877222157],
            [-1.450474159242518, 52.94885813620872],
            [-1.452752738078693, 52.94744293261482],
            [-1.452992960749963, 52.948338478778062],
            [-1.456893338778013, 52.948727617233722],
            [-1.457799940654681, 52.948232845415014],
            [-1.459763748554973, 52.948768559521518],
            [-1.459350866896699, 52.949415714171096],
            [-1.46257250322479, 52.949822253510071],
            [-1.468555948936434, 52.950643802883214],
            [-1.467357079212241, 52.953007131060261],
            [-1.466011281971569, 52.95399441780809],
            [-1.467598256446777, 52.955670853489423],
            [-1.466181126065927, 52.957851606305908],
            [-1.467830832767386, 52.959765637433172],
            [-1.46784685034507, 52.960643966724589],
            [-1.466512558620925, 52.962250674544826],
            [-1.46764543821247, 52.964802426952566],
            [-1.468255656031153, 52.965062251425131],
            [-1.471644555787478, 52.964795988901457],
            [-1.47346444357862, 52.965243637527344],
            [-1.474634063323327, 52.96590952482984],
            [-1.475082815646808, 52.966966852224118],
            [-1.474852359487053, 52.968414912754888],
            [-1.471535505137449, 52.969711762786865],
            [-1.470647839265826, 52.970704730435401],
            [-1.470583719172767, 52.971565621252083],
            [-1.473306644593714, 52.975658855981145],
            [-1.47656025038759, 52.977166341547161],
            [-1.478408731197563, 52.978750285745285],
            [-1.479554935864927, 52.980534288077173],
            [-1.479829640188719, 52.981968383155923],
            [-1.478918208000777, 52.981959899480664],
            [-1.478738532809162, 52.982533528115312],
            [-1.47681989850712, 52.981811355268029],
            [-1.476482408324484, 52.982253941601314],
            [-1.473849561948717, 52.981722744092927],
            [-1.469005164931848, 52.98251564829031],
            [-1.463424258974372, 52.982869953376024],
            [-1.460203596266105, 52.983861284613418],
            [-1.457868122476288, 52.982427657494249],
            [-1.456282025196023, 52.982295466930211],
            [-1.455944762423388, 52.982584279320783],
            [-1.453677884952981, 52.980924352406163],
            [-1.451215743787864, 52.982113090944296],
            [-1.449572675690625, 52.980722944003745],
            [-1.444675971263001, 52.978830407594138],
            [-1.431982757729418, 52.979258589540663],
            [-1.429944830146367, 52.980089341274549],
            [-1.426900884789871, 52.980852290088393],
            [-1.425073029084965, 52.980991793013473],
            [-1.424351488454894, 52.981378439659572],
            [-1.420500010150553, 52.981032526834511],
            [-1.42031826354158, 52.980478798087219],
            [-1.418588378407794, 52.980087409881087],
            [-1.416558727015523, 52.980502666464616],
            [-1.414865828957189, 52.9804628879616],
            [-1.409790090853188, 52.979581127649247],
            [-1.409305543980965, 52.976996088330786],
            [-1.402702637320686, 52.979552040443401],
            [-1.395468526248533, 52.980747930215323],
            [-1.394584494316096, 52.980593312021973],
            [-1.39367257229759, 52.981687163140499],
            [-1.389627415923012, 52.981677261169409],
            [-1.389223034487128, 52.981307521677714],
            [-1.387763971012468, 52.981794433763632],
            [-1.386400409670714, 52.981635487639728],
            [-1.385417200743625, 52.982278542420524],
            [-1.384168594966011, 52.981576309925778],
            [-1.385901300959917, 52.980174841733259],
            [-1.385983335919935, 52.979115422461824],
            [-1.378317223691424, 52.976552291541097],
            [-1.375070627916109, 52.97417827025216],
            [-1.370939041456291, 52.973092150044693],
            [-1.370127039438961, 52.973633506167481],
            [-1.369321642983222, 52.975868485565314],
            [-1.367179993246445, 52.977305294077617],
            [-1.361994358924824, 52.976695080181351],
            [-1.358491814390317, 52.977687542215719],
            [-1.357473460581635, 52.977565183585106],
            [-1.355404404660579, 52.97799446634734],
            [-1.351981265368006, 52.977842822425288],
            [-1.350318753518372, 52.978170853550743],
            [-1.347678483829887, 52.977954141460224],
            [-1.342329826173988, 52.97887396206297],
            [-1.341415134924312, 52.9788895762184],
            [-1.339949662123902, 52.978202755771754],
            [-1.337185588535469, 52.978488527671978],
            [-1.336211199692185, 52.977929348800401],
            [-1.33380577579464, 52.978329410306692],
            [-1.33279621164623, 52.97812328831678],
            [-1.33041841665742, 52.980876851673393],
            [-1.328146067426541, 52.981613750210904],
            [-1.328540112632672, 52.984250756648727],
            [-1.331477191871108, 52.986970399665886],
            [-1.330357885286495, 52.987298507341542],
            [-1.328048311032194, 52.987340317518253],
            [-1.32708508858674, 52.987687254803397],
            [-1.327237560085936, 52.988036904166236],
            [-1.326660611260106, 52.988066001210754],
            [-1.32620086774059, 52.988604556798236],
            [-1.323837209375406, 52.988289101627778],
            [-1.324649420087995, 52.989509076382603],
            [-1.323111774373352, 52.989812266680062],
            [-1.322055752775417, 52.989324423282476],
            [-1.322340048212833, 52.990577361085919],
            [-1.323106478049049, 52.992724785818936],
            [-1.324196899698027, 52.993687452274507],
            [-1.322360454605702, 52.993938594751583],
            [-1.322425603520268, 52.994440571350403],
            [-1.321338151399127, 52.994715738646299],
            [-1.318256479261207, 52.996740496307055],
            [-1.316868981525942, 52.99921808967278],
            [-1.31396616846407, 53.001300395720378],
            [-1.309836054724186, 53.002018126824019],
            [-1.307446817512898, 53.003635806808447],
            [-1.307029528550077, 53.003451792149789],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000037",
        LAD13CDO: "17UH",
        LAD13NM: "High Peak",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.859537263554004, 53.533634620036068],
            [-1.856648256819952, 53.530004392691716],
            [-1.85237986788625, 53.527140907117321],
            [-1.850625784783398, 53.526203492366726],
            [-1.847037033759675, 53.525410233395178],
            [-1.845685013763195, 53.524711001146379],
            [-1.843991493085108, 53.522545304428981],
            [-1.844244722295209, 53.521767240159832],
            [-1.841821844500266, 53.519892683336991],
            [-1.839535707133565, 53.519806949006203],
            [-1.835265973793269, 53.520578647399695],
            [-1.828929985410303, 53.523167407138224],
            [-1.827151846037582, 53.523492030981899],
            [-1.822748084285629, 53.521897346354002],
            [-1.822229652954476, 53.521075039415791],
            [-1.822590077523388, 53.520002360552461],
            [-1.820794116597681, 53.518491434568162],
            [-1.819524676235043, 53.513411984093061],
            [-1.813601089441714, 53.512081613944424],
            [-1.809933177846951, 53.511877203555485],
            [-1.806871172467372, 53.512074542308362],
            [-1.800779402335119, 53.511362565820086],
            [-1.810399753609545, 53.506324007338257],
            [-1.807760790528821, 53.50608340357509],
            [-1.80395096281778, 53.505057040181114],
            [-1.799034789244636, 53.50464980226252],
            [-1.797103518043979, 53.503687480444476],
            [-1.796746362590981, 53.503023529373358],
            [-1.796289010372976, 53.503136904809345],
            [-1.796082218122069, 53.501301117632224],
            [-1.795660276138385, 53.500950748519493],
            [-1.795866342576203, 53.499476998975723],
            [-1.795082684308502, 53.498175034995178],
            [-1.795347562370056, 53.496697790955189],
            [-1.795001676457003, 53.495902622041463],
            [-1.795856471816619, 53.495249725416784],
            [-1.795847605374012, 53.494269971436026],
            [-1.797477438679579, 53.493862867872664],
            [-1.797953068556799, 53.493370206843707],
            [-1.799709789478345, 53.49353315351415],
            [-1.800263874043621, 53.493339030473273],
            [-1.799873599168578, 53.492995020472499],
            [-1.800296625576823, 53.492783600041541],
            [-1.800250821297075, 53.488090661320484],
            [-1.801857108453119, 53.486530241610765],
            [-1.801471587657896, 53.480975642183076],
            [-1.798328745097087, 53.478658557397971],
            [-1.796847898724236, 53.478912223616391],
            [-1.795319563310984, 53.478383794763275],
            [-1.79432007077696, 53.477243243708955],
            [-1.793421310521003, 53.477089790635446],
            [-1.792765782539782, 53.476493621305828],
            [-1.792331011685861, 53.476667243662824],
            [-1.791107485003625, 53.475765369331235],
            [-1.790901473929633, 53.475990619849441],
            [-1.790707159760973, 53.475674785221628],
            [-1.786446549805888, 53.475333785403301],
            [-1.785703425972936, 53.475702781420374],
            [-1.780809152737342, 53.47495326674764],
            [-1.779063840905037, 53.474204913203124],
            [-1.777970091826618, 53.472462721708993],
            [-1.778040906185232, 53.470437751222569],
            [-1.777040629307463, 53.469858831598522],
            [-1.776200640357104, 53.468309448428137],
            [-1.772724841215529, 53.46700136498594],
            [-1.770495908153138, 53.466916213639252],
            [-1.769951096901051, 53.465710711296452],
            [-1.768437965662989, 53.464750519036031],
            [-1.764690355186467, 53.463936040747683],
            [-1.759398141882837, 53.46354440215903],
            [-1.758188276425431, 53.463874536543003],
            [-1.755711842614715, 53.463906357801406],
            [-1.754198900764932, 53.464285269166382],
            [-1.753657989538038, 53.464835150504577],
            [-1.752154311109976, 53.464907547788805],
            [-1.747827369685918, 53.464046396255959],
            [-1.746825080339238, 53.463393512757058],
            [-1.74526408025133, 53.461975410746774],
            [-1.746487165124511, 53.460697149431191],
            [-1.746217283993832, 53.459986486356449],
            [-1.74702291068864, 53.459248440031658],
            [-1.747081992019481, 53.457921863339628],
            [-1.748166911898533, 53.457313833665964],
            [-1.746658227422175, 53.455485084031572],
            [-1.747127815177791, 53.454260945314921],
            [-1.746213326146367, 53.452054128926456],
            [-1.746570429528063, 53.451256706967882],
            [-1.744276001853159, 53.448430330830483],
            [-1.744660742179793, 53.447293207472377],
            [-1.743908913257836, 53.446375670605867],
            [-1.743674798119412, 53.444997229927345],
            [-1.745465251942783, 53.442672129464619],
            [-1.746819611174786, 53.44175547893289],
            [-1.747114734610171, 53.440984888387817],
            [-1.749313272574282, 53.439856064732744],
            [-1.749728664640724, 53.43910279778158],
            [-1.748561675502307, 53.435585836489942],
            [-1.744897408428193, 53.432402427785838],
            [-1.74427349097144, 53.431280224881277],
            [-1.744122786915984, 53.429556800022297],
            [-1.744722148030937, 53.428741921800558],
            [-1.746258530348028, 53.42776633998929],
            [-1.746719624796672, 53.42614848127473],
            [-1.74500926463361, 53.425273856218894],
            [-1.739762442467783, 53.420971418683827],
            [-1.735075762846046, 53.419675762529934],
            [-1.73239963378104, 53.41716738828147],
            [-1.729951734111717, 53.415969102000354],
            [-1.725153630480178, 53.417621950060862],
            [-1.723348851778339, 53.418899555819038],
            [-1.718319960164114, 53.419410048017774],
            [-1.716778531946102, 53.419281465240807],
            [-1.714019005752319, 53.419053777264956],
            [-1.710881830820495, 53.417495704397147],
            [-1.708532829993251, 53.417322812971818],
            [-1.703945647434736, 53.414891818342603],
            [-1.703234745455282, 53.413705362068526],
            [-1.703851610132499, 53.412616578194203],
            [-1.7030017850923, 53.410645972039575],
            [-1.704126978453693, 53.408268587257211],
            [-1.703974137087721, 53.40579724799381],
            [-1.704966122046464, 53.405039266543952],
            [-1.694522043957523, 53.401144341523086],
            [-1.68182949810261, 53.40176379788015],
            [-1.672980670382564, 53.39692561864247],
            [-1.668090092572683, 53.393054225294208],
            [-1.665113567254549, 53.3935178229736],
            [-1.659008642835764, 53.39347808682804],
            [-1.654717709439673, 53.393010042615458],
            [-1.653818503305987, 53.391893751522161],
            [-1.66186097915407, 53.391425103013553],
            [-1.661807538708789, 53.389629026265176],
            [-1.663757735674087, 53.380843655888611],
            [-1.673313255345366, 53.369376394724071],
            [-1.670158894653682, 53.365062164645444],
            [-1.67087473995326, 53.357966694869447],
            [-1.671930396701917, 53.35452064786363],
            [-1.673169763600583, 53.352960915798562],
            [-1.672997696198718, 53.352280002516956],
            [-1.671627999119826, 53.351053785557809],
            [-1.671071440339529, 53.349585302545435],
            [-1.67228203919574, 53.34862863955572],
            [-1.675104251358171, 53.344524927618387],
            [-1.676999626388122, 53.342759297717464],
            [-1.677056102751667, 53.342241702189867],
            [-1.683187165523641, 53.337562432091694],
            [-1.685619125601716, 53.33625830721914],
            [-1.685537119554587, 53.335565063124747],
            [-1.691339068913308, 53.336699290543422],
            [-1.695261481629366, 53.335668472615332],
            [-1.696868548871413, 53.33478807502388],
            [-1.699024496458293, 53.33250680399172],
            [-1.699551697357955, 53.3314178026611],
            [-1.699140661702397, 53.330080148658759],
            [-1.700450237736547, 53.323607978983688],
            [-1.706245500811471, 53.325100117800844],
            [-1.711693267732418, 53.324522835603787],
            [-1.712560189035672, 53.322412579566105],
            [-1.718942930004936, 53.319756327419768],
            [-1.720467147104317, 53.319213391297019],
            [-1.725484220140257, 53.318937390448234],
            [-1.727589095036349, 53.319127377601347],
            [-1.727483501290878, 53.326755848635955],
            [-1.727279013884403, 53.328923455187081],
            [-1.724981754881348, 53.331493447952134],
            [-1.729271282784327, 53.333227277997857],
            [-1.735292433974662, 53.334746377914037],
            [-1.734018626384114, 53.335049163521532],
            [-1.733827028471153, 53.335432553484559],
            [-1.733169497086503, 53.335397828360627],
            [-1.732866079771298, 53.33589062885131],
            [-1.732010897320137, 53.335762871743277],
            [-1.732338983505799, 53.336369444512982],
            [-1.731343230015949, 53.336146087542069],
            [-1.730642534680103, 53.337002928611483],
            [-1.730859139604628, 53.337185887065615],
            [-1.731252377215332, 53.336982729392489],
            [-1.731624348991038, 53.337296371768794],
            [-1.730434658396262, 53.337818631832654],
            [-1.730207381088262, 53.338613616810441],
            [-1.737187054792633, 53.336784700006319],
            [-1.741108035879203, 53.336801352051921],
            [-1.743611205449964, 53.336092150866591],
            [-1.745691347898639, 53.33605075509869],
            [-1.749354450107043, 53.334959181707923],
            [-1.751217792213702, 53.335261500987876],
            [-1.753787807317014, 53.336983662957671],
            [-1.754389996171402, 53.336986700289572],
            [-1.755757592159569, 53.336023221464025],
            [-1.757224493329787, 53.337132717577944],
            [-1.758201680104859, 53.337214698628472],
            [-1.759358451629369, 53.33821028281087],
            [-1.76006531470615, 53.338297096944977],
            [-1.761767377992721, 53.337934664204042],
            [-1.765954511737662, 53.336262957296967],
            [-1.766189307758944, 53.335342077805834],
            [-1.769009939227158, 53.333035676939517],
            [-1.781367148003348, 53.327084153512196],
            [-1.784069088744411, 53.325417171953944],
            [-1.787960125281075, 53.323996737720158],
            [-1.797741117488765, 53.319229016459168],
            [-1.802229364373843, 53.318286425556906],
            [-1.806106470599866, 53.317013689230677],
            [-1.808179796699486, 53.314250314486991],
            [-1.811148358614184, 53.310922032687728],
            [-1.812997834456881, 53.309790567197837],
            [-1.813805728891574, 53.307957233639193],
            [-1.816484643045128, 53.305832851966017],
            [-1.82262743157565, 53.30279585464168],
            [-1.822686428717897, 53.300866963351524],
            [-1.824084309666207, 53.299544991425442],
            [-1.824110647154397, 53.298976942997975],
            [-1.824661814740367, 53.298851911099788],
            [-1.822428483793978, 53.293949756596781],
            [-1.823889337550388, 53.292217992968517],
            [-1.823354660473245, 53.291273386538968],
            [-1.821418337204719, 53.28974152359973],
            [-1.816859204233741, 53.287006538159609],
            [-1.816813078791843, 53.286216357021324],
            [-1.817879296973654, 53.284866080505395],
            [-1.817486676945962, 53.284780987008212],
            [-1.81756632877801, 53.282628303998699],
            [-1.816873095043926, 53.281996232988774],
            [-1.812551940501959, 53.28031222842484],
            [-1.811666042038348, 53.279180948462482],
            [-1.81157184162764, 53.277750688967167],
            [-1.80977238618479, 53.275346040106001],
            [-1.804595284677697, 53.274135883720199],
            [-1.803518376906555, 53.273202880794955],
            [-1.801982795470727, 53.272892928552992],
            [-1.800060949664322, 53.271170175138266],
            [-1.799605667775028, 53.270409861017157],
            [-1.800099066032204, 53.269425518884916],
            [-1.799294029418667, 53.266510907215668],
            [-1.799513624663257, 53.26571936501616],
            [-1.798892308121733, 53.264572251080722],
            [-1.796842406356024, 53.262770125976715],
            [-1.798527479887853, 53.261488484394384],
            [-1.798315710081415, 53.260296214157798],
            [-1.795408939692057, 53.258738006283082],
            [-1.7947847775546, 53.258857384812679],
            [-1.791844636637045, 53.25804510165861],
            [-1.790287744740837, 53.25639563058435],
            [-1.794128060218904, 53.255136692835492],
            [-1.795092062983291, 53.255091609583651],
            [-1.798424148065469, 53.256038408559952],
            [-1.802584996659676, 53.255747832152515],
            [-1.805438307417906, 53.256215438651672],
            [-1.807698385345101, 53.257957531755558],
            [-1.816150660943691, 53.258081407300054],
            [-1.817523626998526, 53.257049802976553],
            [-1.816913495923807, 53.255656505806307],
            [-1.816044229322024, 53.255283033893051],
            [-1.813754375043941, 53.255177011886239],
            [-1.813536552936308, 53.25459779381989],
            [-1.816411586685369, 53.25385078608911],
            [-1.820649154164228, 53.251977669858562],
            [-1.822779984446355, 53.251720176431867],
            [-1.823256706354067, 53.252057063904211],
            [-1.825914052245751, 53.252056473262222],
            [-1.828102149904534, 53.251713574681311],
            [-1.828779822729328, 53.251280389578376],
            [-1.830211525011858, 53.251181759374504],
            [-1.833191383360206, 53.251480791512861],
            [-1.834587503557586, 53.250112841373728],
            [-1.837577911633917, 53.249195594628688],
            [-1.84071005315364, 53.249628579069658],
            [-1.841863955633349, 53.250468767074793],
            [-1.84304283788207, 53.250637513806495],
            [-1.844442132738136, 53.250367883076386],
            [-1.844638594560776, 53.249917800257435],
            [-1.846024271055045, 53.249675099610442],
            [-1.846878043618545, 53.248970576924464],
            [-1.846884369323718, 53.248036649162763],
            [-1.844673363253511, 53.246933565187689],
            [-1.84472266440334, 53.245731826842011],
            [-1.848138363163909, 53.24474924881455],
            [-1.851607049618155, 53.244432708913948],
            [-1.853879782899435, 53.243262472474036],
            [-1.854736296102915, 53.240784404989832],
            [-1.856651516725381, 53.238903563875617],
            [-1.85643135352591, 53.237530708102447],
            [-1.858266432625368, 53.236260983070672],
            [-1.857480364791116, 53.234301384929658],
            [-1.858240520298062, 53.233214642819291],
            [-1.861889348654907, 53.231070590435337],
            [-1.866969750628806, 53.229935675872312],
            [-1.869422875499046, 53.228658374638371],
            [-1.87426814738149, 53.226829853080012],
            [-1.871211324008904, 53.224697142980624],
            [-1.869598118387639, 53.22421808571363],
            [-1.86560451304783, 53.223880172877301],
            [-1.860753759382792, 53.222301569739912],
            [-1.853239703164523, 53.216558590690553],
            [-1.850810565764633, 53.217457161080759],
            [-1.844099555221298, 53.215081823632403],
            [-1.836318323690299, 53.212789139438826],
            [-1.831255049442593, 53.210631955397716],
            [-1.831957196337053, 53.20567559036234],
            [-1.827946968830847, 53.205506279606993],
            [-1.827947360669464, 53.204659529189186],
            [-1.827490164351736, 53.204428754298775],
            [-1.827665492815354, 53.204013722012988],
            [-1.831507555692353, 53.204271794854876],
            [-1.831530574960312, 53.202981026237666],
            [-1.832303080317211, 53.202976720814625],
            [-1.832286373621604, 53.201501624153323],
            [-1.831638181887525, 53.201497116009342],
            [-1.831607142353828, 53.197569834097258],
            [-1.842426473534724, 53.194744123740925],
            [-1.841347432659654, 53.197142724234126],
            [-1.847747203597881, 53.199071082107295],
            [-1.853340892811143, 53.202689812298253],
            [-1.855606558295771, 53.202953251169092],
            [-1.862837471496724, 53.205280903608148],
            [-1.872710485319851, 53.215640700155532],
            [-1.873244224863478, 53.214411594014244],
            [-1.872829315287821, 53.212928892269169],
            [-1.873579743238352, 53.212853283794047],
            [-1.874381662380944, 53.211039281511248],
            [-1.873191935049554, 53.207255515015845],
            [-1.876435315278544, 53.207636446798404],
            [-1.876225803451073, 53.207080718606676],
            [-1.876939322684319, 53.20678482373112],
            [-1.877419961554289, 53.207297682776975],
            [-1.878476506328921, 53.206941904962193],
            [-1.87777612467889, 53.206304779250466],
            [-1.87803377283689, 53.20520121097907],
            [-1.876618203436322, 53.203378613960417],
            [-1.881490621386482, 53.203592988740596],
            [-1.882648707695032, 53.203286713214368],
            [-1.88438842158511, 53.204919887806078],
            [-1.883558224040094, 53.20576313504742],
            [-1.882378630747474, 53.206247377485973],
            [-1.881629787761953, 53.207427773268854],
            [-1.883575106132811, 53.212254001392452],
            [-1.885873469721226, 53.215693553373292],
            [-1.885870297245478, 53.216889965018979],
            [-1.886947866448254, 53.21771526616169],
            [-1.888065134934423, 53.218263738448222],
            [-1.892389431534034, 53.219174685227998],
            [-1.895405600195962, 53.218585898204907],
            [-1.893754126256989, 53.217819491106489],
            [-1.897212124177472, 53.215908792100436],
            [-1.894857164475371, 53.211967831879853],
            [-1.897601020823226, 53.210434919823044],
            [-1.900083325234413, 53.21184107926333],
            [-1.900833957756496, 53.213598124817267],
            [-1.907394345478164, 53.219120737640424],
            [-1.907992499942248, 53.220242107124264],
            [-1.910562392103541, 53.221017097115556],
            [-1.910130048187119, 53.221499473094866],
            [-1.910758720619602, 53.221677024030782],
            [-1.910779546863776, 53.221022652685257],
            [-1.912397885438031, 53.220617554353431],
            [-1.917334090647222, 53.218361276563179],
            [-1.918286566633781, 53.218361931275076],
            [-1.918559874858782, 53.21796840675232],
            [-1.917456056268105, 53.218017088433641],
            [-1.912788233030241, 53.217190397157545],
            [-1.91057491132569, 53.216431002905829],
            [-1.908986993757869, 53.215263053051792],
            [-1.911769029453516, 53.213223772979887],
            [-1.911125928227647, 53.212114073209143],
            [-1.908395543911691, 53.211713806010792],
            [-1.905866446164112, 53.211728919621713],
            [-1.900074963812018, 53.208346207863833],
            [-1.900912830414495, 53.206653405759951],
            [-1.900715420966816, 53.204609176150825],
            [-1.902497026962663, 53.20393647891099],
            [-1.901581167304239, 53.203120438587085],
            [-1.900198853644519, 53.203342215699948],
            [-1.899054808319348, 53.202181691678391],
            [-1.898211710837283, 53.201025907632761],
            [-1.898636423766738, 53.199938616236111],
            [-1.89924676329692, 53.200118909776563],
            [-1.900386967462211, 53.199682108157269],
            [-1.900906155461288, 53.199793102919664],
            [-1.901740469946447, 53.198913782073184],
            [-1.903750643772402, 53.199003509115101],
            [-1.903944727464812, 53.198564109761342],
            [-1.90534532871361, 53.198797140180439],
            [-1.906706937096692, 53.198379329819289],
            [-1.907464691375733, 53.19892823979135],
            [-1.908047864470725, 53.198521494331786],
            [-1.908126650778472, 53.198779535369319],
            [-1.909211842618075, 53.198816320166493],
            [-1.910386589750345, 53.199699014409454],
            [-1.911454974589283, 53.199913745659906],
            [-1.912436748039123, 53.200765714254146],
            [-1.912381376051321, 53.201493771458026],
            [-1.913409995506818, 53.201410025762769],
            [-1.913966719558298, 53.202242796235659],
            [-1.915119484167148, 53.202222047096939],
            [-1.915385993239344, 53.202966513572569],
            [-1.916586943676303, 53.202810951454175],
            [-1.917156164778953, 53.203414499656326],
            [-1.918124603085399, 53.2035068531723],
            [-1.919125565575386, 53.203023035038314],
            [-1.921759089658353, 53.202925909950906],
            [-1.922971081742823, 53.203279958971258],
            [-1.924958246663996, 53.205550009682014],
            [-1.928449284642665, 53.206655081398765],
            [-1.929789613010573, 53.207367792671064],
            [-1.930531769461208, 53.20858621513667],
            [-1.932788776828556, 53.209143015253964],
            [-1.935552933491778, 53.211033092652244],
            [-1.936151900960869, 53.21101453714968],
            [-1.937696089185679, 53.211759627338949],
            [-1.939288866472672, 53.212065167643914],
            [-1.948809742884474, 53.211646241839858],
            [-1.950729099098517, 53.211886149791013],
            [-1.956710781431083, 53.213729374161773],
            [-1.961034766373509, 53.219416295317295],
            [-1.962367733229862, 53.226223958756265],
            [-1.970775914135335, 53.220920194936305],
            [-1.976294589352262, 53.218780268713068],
            [-1.978190053136046, 53.216838139544045],
            [-1.98077054894492, 53.216354978784473],
            [-1.981221564397642, 53.215758190989668],
            [-1.982795763762149, 53.2149683063667],
            [-1.983531031518136, 53.214963914192815],
            [-1.984044848807361, 53.214473192394202],
            [-1.986348037258107, 53.214216392601116],
            [-1.987411420612017, 53.21356751312188],
            [-1.988004133007449, 53.214567131838834],
            [-1.989643582979686, 53.215770887267659],
            [-1.989457344582416, 53.218054035350796],
            [-1.989976710556969, 53.219370942887224],
            [-1.989115527264529, 53.219532668210306],
            [-1.990133602933853, 53.22097995614547],
            [-1.990267954333095, 53.22298087977245],
            [-1.988149814244623, 53.224086318111524],
            [-1.987770658871876, 53.224842240681248],
            [-1.986648689206764, 53.225150439373706],
            [-1.986602156264845, 53.225476728490719],
            [-1.985874220383248, 53.225411926537873],
            [-1.985937022349693, 53.225747216867894],
            [-1.984952998559476, 53.225537659343125],
            [-1.98407963171343, 53.225858448743296],
            [-1.983359192095469, 53.225768463720307],
            [-1.982244580473034, 53.226261791628829],
            [-1.979326486838165, 53.226727847447521],
            [-1.978953261520328, 53.227241943429703],
            [-1.9783451707467, 53.227115992382529],
            [-1.977688849048396, 53.227576999202405],
            [-1.976068128080245, 53.227511968907848],
            [-1.97493214091089, 53.228481631390871],
            [-1.974861750135326, 53.231043429092132],
            [-1.978014454387606, 53.232399558196185],
            [-1.97909414290647, 53.233310316565479],
            [-1.981885965043656, 53.238562027702024],
            [-1.983695900926607, 53.238623408833931],
            [-1.987730421260116, 53.240178047046449],
            [-1.990987704183008, 53.240993614636864],
            [-1.999733836649566, 53.245031702380743],
            [-1.999209332652977, 53.246358446752268],
            [-2.001861929968864, 53.2485004572235],
            [-2.001034833539964, 53.253868570052049],
            [-2.000334894576662, 53.255421836339266],
            [-2.009483862418509, 53.260635826199135],
            [-2.00773365728196, 53.264507417645305],
            [-2.005408777314285, 53.26757271806936],
            [-2.005199056818301, 53.269023513177508],
            [-2.006162009966369, 53.271387507409713],
            [-2.006637365405368, 53.271557368259522],
            [-2.007399868808722, 53.275896190544671],
            [-2.008133337357691, 53.27669524054437],
            [-2.009279812298716, 53.28005065145971],
            [-2.008275652084376, 53.283460155225086],
            [-2.008135449917853, 53.287471835636254],
            [-2.008171833509356, 53.28941699338737],
            [-2.009641921201236, 53.289419576981651],
            [-2.008654449525951, 53.294639404962254],
            [-2.008700614620501, 53.300165661927586],
            [-2.009329787431585, 53.302297734210413],
            [-2.008667005848227, 53.304489231551649],
            [-2.004333938283215, 53.311141112745361],
            [-2.004202223835664, 53.314594572055263],
            [-2.003178749254448, 53.316930765096529],
            [-2.001551691155102, 53.318905614225905],
            [-2.003731577288902, 53.321647107865125],
            [-2.004332037417636, 53.321391807855974],
            [-2.006052462335983, 53.321651501925643],
            [-2.008169315119937, 53.322330914939982],
            [-2.008975622321531, 53.322978039770909],
            [-2.010266686814644, 53.32288085514152],
            [-2.01078042875689, 53.324082594267082],
            [-2.009936791534812, 53.324484462992999],
            [-2.010866167759981, 53.32471538877661],
            [-2.011554113073567, 53.325937781764331],
            [-2.011114398814606, 53.326640738392335],
            [-2.011797798292509, 53.327580885884821],
            [-2.01081042597431, 53.329769721540444],
            [-2.009283754214723, 53.33137253191439],
            [-2.013650627210259, 53.332404000826017],
            [-2.013394890715039, 53.335648935532049],
            [-2.014319941315013, 53.340042478765859],
            [-2.010139198356257, 53.341886487795527],
            [-2.00466063224855, 53.345993750047619],
            [-2.007839353686026, 53.347749956326936],
            [-2.007705947222042, 53.349270842341653],
            [-2.008425865190896, 53.3510873947126],
            [-2.006704373261949, 53.351639409543751],
            [-2.006567717198835, 53.351956716080409],
            [-2.007268045279741, 53.353454179465828],
            [-2.006710690873077, 53.353529717802871],
            [-2.006785977172878, 53.354556214532089],
            [-2.008066077556959, 53.354950732724461],
            [-2.008939032833935, 53.355263472762218],
            [-2.010432412168532, 53.355239078997478],
            [-2.010912030343398, 53.356588227479364],
            [-2.010682580029565, 53.358243053952606],
            [-2.008999085860515, 53.361995045342404],
            [-2.01046880739288, 53.362563901523153],
            [-2.01187352107311, 53.361612771561902],
            [-2.014112754116531, 53.362577001207761],
            [-2.015571924058337, 53.362819509119944],
            [-2.016335522327907, 53.363447709418701],
            [-2.017395781553249, 53.365538308150633],
            [-2.02015809083743, 53.36602774673802],
            [-2.021817527172406, 53.366741146795604],
            [-2.023483911796457, 53.366337238438611],
            [-2.023579621099103, 53.365496784442307],
            [-2.024929776471904, 53.364096980480376],
            [-2.025994940621687, 53.363677881611395],
            [-2.027129523100179, 53.36375133325982],
            [-2.0285877117188, 53.364596818071256],
            [-2.030034446146814, 53.36616137658833],
            [-2.031729249996376, 53.365713301678504],
            [-2.032135541973823, 53.36643138193336],
            [-2.03408721706083, 53.367736880906428],
            [-2.031059334963302, 53.370246433238741],
            [-2.030373893957733, 53.370098299178402],
            [-2.028624912263295, 53.370718049908206],
            [-2.030835828510312, 53.372899926492082],
            [-2.03378348394569, 53.375076160360742],
            [-2.033776326800117, 53.375525592139567],
            [-2.030691683021908, 53.375172281174905],
            [-2.028037892523229, 53.373937906723057],
            [-2.026209967085728, 53.373602152538865],
            [-2.024068444108704, 53.374157204634727],
            [-2.023257030437347, 53.374595111875017],
            [-2.023418050445512, 53.374938444384718],
            [-2.021473102466511, 53.375043082048933],
            [-2.021996648432987, 53.37594184499887],
            [-2.020630405091526, 53.376145234133539],
            [-2.020945274234192, 53.377582454491147],
            [-2.017085189754591, 53.377816781173244],
            [-2.018593940982278, 53.380189539850875],
            [-2.01971556816992, 53.380579462091184],
            [-2.016981447002305, 53.381482342318606],
            [-2.015113984966611, 53.380551379963556],
            [-2.013063098785183, 53.384088634194747],
            [-2.004644233878951, 53.386311253850465],
            [-2.005053708669277, 53.390523282295959],
            [-2.005177243788586, 53.392378517544465],
            [-2.004482683380174, 53.393744809412986],
            [-2.001771543999377, 53.396474713358948],
            [-1.997388934802083, 53.403520837338618],
            [-1.996185561211244, 53.40465156729033],
            [-1.995150380721236, 53.407745390061088],
            [-1.993991917711226, 53.409169125330394],
            [-1.99330253799747, 53.411892618076926],
            [-1.993967308053685, 53.413166327860438],
            [-1.992331632997033, 53.415176973013097],
            [-2.000255890850147, 53.415254502850502],
            [-2.005094374966562, 53.415011693401425],
            [-2.010650945855092, 53.416711046458417],
            [-2.013698919688798, 53.415873896691075],
            [-2.013662997308259, 53.416444672695768],
            [-2.015226290591817, 53.416610768026793],
            [-2.014770840353561, 53.417835064127026],
            [-2.017020341823361, 53.418125988155843],
            [-2.016677751259937, 53.419287355138543],
            [-2.023711213748211, 53.420535555689412],
            [-2.02344103520078, 53.421735577709704],
            [-2.020997088604656, 53.42124346658175],
            [-2.021285156306333, 53.422559334852593],
            [-2.019725901425963, 53.425102458538419],
            [-2.020476088083902, 53.426676220109208],
            [-2.021654790374083, 53.427361833422125],
            [-2.022520240309586, 53.42756571078597],
            [-2.024435861159352, 53.427258821668183],
            [-2.023958925420482, 53.427486328778627],
            [-2.024147302033554, 53.427937513889589],
            [-2.026256942161317, 53.429848920935235],
            [-2.025766441832646, 53.430076438172165],
            [-2.027323811144269, 53.431870195303119],
            [-2.027318127085824, 53.432389732405873],
            [-2.026735823519484, 53.432669408029113],
            [-2.02275648736276, 53.433081914769502],
            [-2.022451595670621, 53.434305308802159],
            [-2.01952017394814, 53.435770956375606],
            [-2.017850467764888, 53.43837608875603],
            [-2.017311650943749, 53.43859728630563],
            [-2.01656630498141, 53.438081453794638],
            [-2.014722807073131, 53.43955491411738],
            [-2.013657106019669, 53.439866943884873],
            [-2.012299260820653, 53.439900351821784],
            [-2.010045058183362, 53.437242668598316],
            [-2.008318256141568, 53.436077895345605],
            [-2.007174198618457, 53.435685174489429],
            [-2.004217940754484, 53.435905540273509],
            [-2.004005732814291, 53.436217449072387],
            [-2.005434340095838, 53.437127025813041],
            [-2.004693840475416, 53.437994449080236],
            [-2.006417809475126, 53.440428450222342],
            [-2.008307292197059, 53.441435939890674],
            [-2.006609312920843, 53.442395121876395],
            [-2.003765522860832, 53.44288512444583],
            [-2.002534112621623, 53.444102201300318],
            [-1.999378502469087, 53.445833416437196],
            [-1.998216187086344, 53.445756106303918],
            [-1.996392916730616, 53.445756069183517],
            [-1.994766745522357, 53.44689755040887],
            [-1.995035846899973, 53.450580142796312],
            [-1.993150370935841, 53.452278876255576],
            [-1.989450150826114, 53.454004402447204],
            [-1.986903614554063, 53.454299876893316],
            [-1.985564395518367, 53.455742375557506],
            [-1.987661857881728, 53.456861670650149],
            [-1.987432362975132, 53.458886749357383],
            [-1.988093317081743, 53.459632858363861],
            [-1.988163845055247, 53.460581148362905],
            [-1.980915414110861, 53.462853408908309],
            [-1.980597454464496, 53.463192223403389],
            [-1.981535267320627, 53.464488505903731],
            [-1.984010948577304, 53.466110375180875],
            [-1.984212540065937, 53.466807006901377],
            [-1.986733937390127, 53.467488632763406],
            [-1.987616070560023, 53.469572248921736],
            [-1.988533373678572, 53.470008278322751],
            [-1.988165240066648, 53.47206749697704],
            [-1.987375622363377, 53.472756831812703],
            [-1.989313905030857, 53.475378047055784],
            [-1.987822238008152, 53.481093655631497],
            [-1.987077695121982, 53.481641873542998],
            [-1.985707931024133, 53.481725313039483],
            [-1.984646759219724, 53.482682451822882],
            [-1.984670680739047, 53.483215468816702],
            [-1.98146761062219, 53.485245508334309],
            [-1.976649193970255, 53.486302612584844],
            [-1.974633346121841, 53.487918325042934],
            [-1.973433530596142, 53.490640660023473],
            [-1.972264845420559, 53.491601259139713],
            [-1.97220075644161, 53.492814680754073],
            [-1.971225010296449, 53.493635993214717],
            [-1.970779290622254, 53.49520706086561],
            [-1.972495209947738, 53.499030235024087],
            [-1.971977390257378, 53.500186024948199],
            [-1.972722160447416, 53.502469250107147],
            [-1.971321721791883, 53.504542549409067],
            [-1.967440898387935, 53.506622377982652],
            [-1.966939777039665, 53.507334122461771],
            [-1.963388363143203, 53.509810296535406],
            [-1.961261570867098, 53.507264118092593],
            [-1.959476165923096, 53.505890103243786],
            [-1.957421702600727, 53.505327618861401],
            [-1.955675198826914, 53.504438934120522],
            [-1.951334911378731, 53.504181985086106],
            [-1.946891405109819, 53.504528853357833],
            [-1.942430432922216, 53.506593230953349],
            [-1.938249753081636, 53.506386218405488],
            [-1.935503585001491, 53.505769968049741],
            [-1.928779845880142, 53.508072589010524],
            [-1.926078384195104, 53.508646212392968],
            [-1.924401583051545, 53.509556590676148],
            [-1.921974752364896, 53.5099379423675],
            [-1.917500903072198, 53.513061111026062],
            [-1.916768273386618, 53.514478972375436],
            [-1.92186350187727, 53.517160039151641],
            [-1.922695316848965, 53.518359628034133],
            [-1.926782546234243, 53.520931968954358],
            [-1.923478875339157, 53.522941505824775],
            [-1.922606962811886, 53.526317877369578],
            [-1.922446452798824, 53.53079847345812],
            [-1.917391451976027, 53.532180205729013],
            [-1.91577721587828, 53.532930510080988],
            [-1.914254924371216, 53.53669195726907],
            [-1.911892394847331, 53.53801692596268],
            [-1.909622305962102, 53.538374770987254],
            [-1.908891276575149, 53.538717572894399],
            [-1.904276160333565, 53.538464101937493],
            [-1.902115067991861, 53.538628641879683],
            [-1.899323383474788, 53.538096017749673],
            [-1.898356956395099, 53.537779711240475],
            [-1.894110892471911, 53.53359555209083],
            [-1.887047174067328, 53.535853271702244],
            [-1.882688293221163, 53.53913522712012],
            [-1.875855325731308, 53.540393904205168],
            [-1.873493763507431, 53.540413905554693],
            [-1.870648372974562, 53.539262156782648],
            [-1.863430034575103, 53.53494872465042],
            [-1.859537263554004, 53.533634620036068],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000038",
        LAD13CDO: "17UJ",
        LAD13NM: "North East Derbyshire",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.406396755284264, 53.339380008341649],
            [-1.390698401896056, 53.335961583879175],
            [-1.389459025938912, 53.335992105934764],
            [-1.388824206374634, 53.336428003640734],
            [-1.388916727747388, 53.336214051257542],
            [-1.386573810158207, 53.334865400585883],
            [-1.387226310762056, 53.334078635872864],
            [-1.38792002282202, 53.334502870693314],
            [-1.388730586810744, 53.333161400023378],
            [-1.390725679109445, 53.331621929303765],
            [-1.390341090605241, 53.331002434808696],
            [-1.39120583119991, 53.328270654728257],
            [-1.390429560954274, 53.326058141994224],
            [-1.392005199246385, 53.322629753465812],
            [-1.389933869740675, 53.318492416473347],
            [-1.389261219737746, 53.318712798294086],
            [-1.38786387268024, 53.318382941255983],
            [-1.387305361714109, 53.317865015777876],
            [-1.386860261741682, 53.318023630152858],
            [-1.386740050136579, 53.317613121998946],
            [-1.385077694212666, 53.317761873715732],
            [-1.384351041691672, 53.317561272461234],
            [-1.383659990535676, 53.317807595458468],
            [-1.380617286404965, 53.316974759849927],
            [-1.379612623757892, 53.317212240532491],
            [-1.378146603065005, 53.317071576731031],
            [-1.377039959233227, 53.31740468492869],
            [-1.374130370177389, 53.31723125981685],
            [-1.371646717624115, 53.316062245858582],
            [-1.365450417048595, 53.31585777687895],
            [-1.36416801605386, 53.315184878760789],
            [-1.362622971275232, 53.315016636316265],
            [-1.361923033815594, 53.315752678233075],
            [-1.362947068268161, 53.317546029075153],
            [-1.36177749325716, 53.318466530197675],
            [-1.353472811513297, 53.319089705244558],
            [-1.347237661867361, 53.318849923194698],
            [-1.347307058499127, 53.318532097546104],
            [-1.343052261183578, 53.317884928117124],
            [-1.340894257300846, 53.316968752008698],
            [-1.341628735835203, 53.316061336147193],
            [-1.340259187053456, 53.315515341129149],
            [-1.337667719658566, 53.31585065445033],
            [-1.336548577926798, 53.31658422137928],
            [-1.336580017239942, 53.317945308648397],
            [-1.334524165279061, 53.320315010544284],
            [-1.334561563347562, 53.320999270542629],
            [-1.33231002547619, 53.322038391953107],
            [-1.332215104644247, 53.322445055781998],
            [-1.332805328854507, 53.322912181055401],
            [-1.332416630426136, 53.323480799478496],
            [-1.328164039826766, 53.326787306488335],
            [-1.328125714202501, 53.327315634785272],
            [-1.328768669631909, 53.327677008051047],
            [-1.328299663132378, 53.32895527969977],
            [-1.327663597959126, 53.329109902328021],
            [-1.324668614798439, 53.328790970240405],
            [-1.324755808741236, 53.327071897606501],
            [-1.324026815728876, 53.327312268043961],
            [-1.323718449784727, 53.327063328063609],
            [-1.322297059792542, 53.327507405362475],
            [-1.32165807365874, 53.325668252976755],
            [-1.321611164290868, 53.32547678725642],
            [-1.318369447131728, 53.325987498891614],
            [-1.315610910496054, 53.326767227368073],
            [-1.315511083232648, 53.32643766299163],
            [-1.314957532918541, 53.3265019037255],
            [-1.313677517693046, 53.327300850597581],
            [-1.313005124065085, 53.329341945892232],
            [-1.310536753281286, 53.329334006109661],
            [-1.311438214799111, 53.330232701174161],
            [-1.311321601241499, 53.330854056687087],
            [-1.3105076214313, 53.331886670644231],
            [-1.309248999845904, 53.331717594749989],
            [-1.308788513774736, 53.332150888225577],
            [-1.311501342304652, 53.332094655426253],
            [-1.312298769548236, 53.33414511289493],
            [-1.305972561558411, 53.33374984482937],
            [-1.302208641546087, 53.332505418358167],
            [-1.299071620980026, 53.332512207851046],
            [-1.297391435907141, 53.331406588817735],
            [-1.29709634082776, 53.330631809987942],
            [-1.295733978312854, 53.330111414916622],
            [-1.29537578318242, 53.330343910150553],
            [-1.294666627051733, 53.32945881378177],
            [-1.293179317706187, 53.328961920792977],
            [-1.292828205782827, 53.328765681755677],
            [-1.293230819669057, 53.328661997830316],
            [-1.2918214009321, 53.328179032205277],
            [-1.291766332373131, 53.327877578592229],
            [-1.291230521292758, 53.327955297915736],
            [-1.288588466482112, 53.326207425847599],
            [-1.289310752951499, 53.324393280779042],
            [-1.290343346090759, 53.323689304050212],
            [-1.290108079103753, 53.323119808419548],
            [-1.293946790069773, 53.322763247907695],
            [-1.295158164885854, 53.321770845494108],
            [-1.29568635190889, 53.319688545493811],
            [-1.294734184873337, 53.315250507979641],
            [-1.295677685487299, 53.315016076709362],
            [-1.293664226297581, 53.313960568145646],
            [-1.285307317077106, 53.311589884367898],
            [-1.28201135491068, 53.309441530399091],
            [-1.284482960933181, 53.309636152921378],
            [-1.286609840270146, 53.308993595806633],
            [-1.291590557317941, 53.309203043908418],
            [-1.303057351653046, 53.31285532705688],
            [-1.304638157918681, 53.312928369183339],
            [-1.307475870820611, 53.312499920071041],
            [-1.309167445061904, 53.311847243712791],
            [-1.312376853658686, 53.311350716046839],
            [-1.313583418500806, 53.310892038125502],
            [-1.318717068963918, 53.310378526734006],
            [-1.321927920132142, 53.309018818928429],
            [-1.321803749349576, 53.308520129698358],
            [-1.318945860104161, 53.307035071792505],
            [-1.317825138677911, 53.305790002948349],
            [-1.316263493392972, 53.30509611287485],
            [-1.316086786923133, 53.301784493750674],
            [-1.313572278033643, 53.299699015552982],
            [-1.310674854092143, 53.297927680739669],
            [-1.313358529791528, 53.296746731624445],
            [-1.320027618422959, 53.295145347418241],
            [-1.322938417017465, 53.295134033754991],
            [-1.323450590507995, 53.295383234511405],
            [-1.324142387718612, 53.29508872363936],
            [-1.324086377943571, 53.294074459076135],
            [-1.32255968483171, 53.291570483088435],
            [-1.318997865833014, 53.28913938349109],
            [-1.321925227100655, 53.288908860845687],
            [-1.321911247249364, 53.288560011904025],
            [-1.320739621477949, 53.286955120001721],
            [-1.318697431683153, 53.285486373817996],
            [-1.316703847407039, 53.281636706469364],
            [-1.322589187988733, 53.281355656117228],
            [-1.333635615429331, 53.289737993940854],
            [-1.336111349034669, 53.288833129537259],
            [-1.336442925597115, 53.288054737715306],
            [-1.33719594609574, 53.288346567870327],
            [-1.337273298361623, 53.289552408507951],
            [-1.339061464468266, 53.289842780629165],
            [-1.33969290770999, 53.289071434040267],
            [-1.339217961169165, 53.288741608931481],
            [-1.34040471966098, 53.287657822319765],
            [-1.341129571449792, 53.287441599259708],
            [-1.342665657377949, 53.285679259094238],
            [-1.345191465219554, 53.285788430137657],
            [-1.346615035992131, 53.285684772076777],
            [-1.347335800986778, 53.286126476165947],
            [-1.34844377664331, 53.285667805924732],
            [-1.349221874261709, 53.286582627461414],
            [-1.353171467206424, 53.286480057588541],
            [-1.358959313594397, 53.287730187282776],
            [-1.359725445332834, 53.288359028505731],
            [-1.360790503812443, 53.288660473289902],
            [-1.366894410451161, 53.289164933006703],
            [-1.368506502734446, 53.292049916730711],
            [-1.368011421997236, 53.292965958650591],
            [-1.369853746773942, 53.293677733070126],
            [-1.370683511215111, 53.294486616092001],
            [-1.371359985017432, 53.295621880534803],
            [-1.371524442586662, 53.297297372850707],
            [-1.374169908196032, 53.298407917788396],
            [-1.377055256760963, 53.299025268663819],
            [-1.377154692058347, 53.296830707427482],
            [-1.380870131092118, 53.296587567524284],
            [-1.380731680093715, 53.295474926656418],
            [-1.38599139888988, 53.294876481630425],
            [-1.385959077133107, 53.294096081952347],
            [-1.386673893828113, 53.293733911370175],
            [-1.386301855615077, 53.292265914572695],
            [-1.387345912568792, 53.289859566345953],
            [-1.386562818199951, 53.286927867264914],
            [-1.387165869292455, 53.286086011873053],
            [-1.391422134401923, 53.286453868357313],
            [-1.391765169388539, 53.287120794050807],
            [-1.396747056943376, 53.284543807017357],
            [-1.396145953764223, 53.283772217639012],
            [-1.396319123705368, 53.283404550044658],
            [-1.399498921269952, 53.282440808639258],
            [-1.399280773183968, 53.281953412338076],
            [-1.401740078658265, 53.28155587633497],
            [-1.403002182383597, 53.283333006179618],
            [-1.407948966318414, 53.286255665814025],
            [-1.409813050605352, 53.286721534903698],
            [-1.414642067752409, 53.286797457894544],
            [-1.41549490023343, 53.287616928272989],
            [-1.417973495727941, 53.286253748399666],
            [-1.418917668937662, 53.284876762125919],
            [-1.421490428685335, 53.28340700018191],
            [-1.42312783769886, 53.283442793342907],
            [-1.42323837719867, 53.282696353166848],
            [-1.43219176861964, 53.28185569138931],
            [-1.436083785324701, 53.280835935883822],
            [-1.438663464634234, 53.2806062997917],
            [-1.439037839802504, 53.28019187625096],
            [-1.441927706876977, 53.282051739368562],
            [-1.443942778219306, 53.282685872609562],
            [-1.447117579601131, 53.282835458468739],
            [-1.447907192991307, 53.283132151211447],
            [-1.451594518242343, 53.2819131882151],
            [-1.454452754825809, 53.281360891939052],
            [-1.455254816088553, 53.28174208817574],
            [-1.457069973343095, 53.283727009270862],
            [-1.458639644393834, 53.282953909137461],
            [-1.459062108398759, 53.28109243660645],
            [-1.459990796069423, 53.279759102123123],
            [-1.460831343749526, 53.278036144572887],
            [-1.464401630802795, 53.27843784646641],
            [-1.465805610955552, 53.278057619850244],
            [-1.464913652696334, 53.277187097614515],
            [-1.46524660355377, 53.275983184852265],
            [-1.464902960425691, 53.274920060178033],
            [-1.466944681350198, 53.274617286152825],
            [-1.466873959812601, 53.273913142594871],
            [-1.465077774580218, 53.272207107326437],
            [-1.465418057674049, 53.270291308019132],
            [-1.462729041016435, 53.270333162108635],
            [-1.464200893632343, 53.267981102587505],
            [-1.466369860854431, 53.267909025117511],
            [-1.466513598404811, 53.267563596537805],
            [-1.467873131865976, 53.267472588414712],
            [-1.470105435718039, 53.267611065015807],
            [-1.47225276252899, 53.266371130690267],
            [-1.473291350232244, 53.266161785425197],
            [-1.474866957242709, 53.26507208931767],
            [-1.475426900640293, 53.265135677030429],
            [-1.479046569261441, 53.263493091790863],
            [-1.480601747465441, 53.263327286455926],
            [-1.479754911622333, 53.263310113257972],
            [-1.478663712203112, 53.26279927635894],
            [-1.475397179115354, 53.25947615229191],
            [-1.474420484221194, 53.259419717740954],
            [-1.474379002588444, 53.259991227534748],
            [-1.473756348161311, 53.259668478440013],
            [-1.473215108494482, 53.260040925809022],
            [-1.471466199486132, 53.259634083936845],
            [-1.471134863192996, 53.259394410211605],
            [-1.472512813208172, 53.258271507942816],
            [-1.47068457650627, 53.257253958481897],
            [-1.47220913199441, 53.256515535154264],
            [-1.473518071085386, 53.257219755350192],
            [-1.476272659683828, 53.254685330516722],
            [-1.47510552706612, 53.253419060040052],
            [-1.478794590092155, 53.252275669655432],
            [-1.478162958734631, 53.250972216145698],
            [-1.475906521193744, 53.250792433454109],
            [-1.474935407354958, 53.250051073722283],
            [-1.473839929058936, 53.249799052031527],
            [-1.47393966595532, 53.249120830565786],
            [-1.47475518128959, 53.248735205346435],
            [-1.476712557251679, 53.248604480609011],
            [-1.477896864909798, 53.248206069037927],
            [-1.478482280396912, 53.248498971237602],
            [-1.479218518864492, 53.248219936724233],
            [-1.480357734966682, 53.248695923011113],
            [-1.481313740009425, 53.247840745960303],
            [-1.480983646615151, 53.246633899582775],
            [-1.481712540073116, 53.246462684437283],
            [-1.480900186321517, 53.245109019821179],
            [-1.479088521445143, 53.243496603313581],
            [-1.478533939768163, 53.243384515415244],
            [-1.478970445478284, 53.242483937439175],
            [-1.477540386292034, 53.239695616238464],
            [-1.475095273142476, 53.239799938398612],
            [-1.471864291546673, 53.239474658891751],
            [-1.471553922177355, 53.239369911692918],
            [-1.472168516904656, 53.238982514572712],
            [-1.471494405747645, 53.23884919015336],
            [-1.471364148212926, 53.236164523950855],
            [-1.471865430293088, 53.236093934609556],
            [-1.471876140836532, 53.233396408557056],
            [-1.478055092224642, 53.23371754807247],
            [-1.480696476156415, 53.233211317219457],
            [-1.480820811576, 53.23345995256868],
            [-1.483164395754159, 53.233295746809105],
            [-1.482991290767224, 53.232617232439843],
            [-1.483975896727739, 53.232464190719007],
            [-1.48255833690818, 53.231000945561739],
            [-1.48416946545054, 53.23069241403963],
            [-1.484158848045483, 53.22895750699022],
            [-1.483068139390904, 53.228971660935592],
            [-1.482488343343676, 53.226988887007231],
            [-1.482153510988392, 53.227054850437803],
            [-1.481697931143377, 53.226079370849213],
            [-1.477344401430168, 53.226256328537026],
            [-1.475844566268942, 53.225065005090869],
            [-1.476978109716901, 53.224354463353428],
            [-1.477801118772501, 53.22429694542997],
            [-1.47841045160934, 53.223703646156011],
            [-1.47813570133447, 53.222894339300403],
            [-1.479671852206265, 53.222694306588295],
            [-1.479527942568059, 53.22221007409825],
            [-1.478397514860874, 53.222302215332249],
            [-1.475462795083019, 53.22161607140913],
            [-1.470469775916984, 53.221337819171268],
            [-1.468834054001124, 53.220627609394015],
            [-1.467600473800452, 53.219497591860019],
            [-1.465661819440501, 53.220154101962727],
            [-1.461677447722647, 53.22163014311608],
            [-1.460172415818584, 53.220303762772083],
            [-1.459116908401532, 53.220631570573047],
            [-1.457377390674433, 53.219618703676836],
            [-1.452430185389953, 53.220708020883528],
            [-1.450224126654716, 53.220820119816878],
            [-1.447528335019559, 53.221395537331702],
            [-1.445271539138996, 53.219981885204668],
            [-1.444173020138549, 53.220731841356638],
            [-1.44298112824216, 53.21978603551802],
            [-1.441206123331861, 53.220611905165519],
            [-1.439295191147683, 53.218765593073286],
            [-1.44071584351983, 53.217086836187114],
            [-1.440002358780677, 53.213574198277321],
            [-1.437269303310855, 53.213131658301045],
            [-1.433452499128355, 53.213799441224971],
            [-1.429281621605198, 53.215410147833573],
            [-1.427507960347911, 53.215342317781761],
            [-1.427501982624964, 53.215341390183731],
            [-1.422688684541908, 53.214358160458083],
            [-1.4223821690314, 53.214100490374427],
            [-1.423059840808851, 53.213817023367056],
            [-1.422566758843728, 53.213847895590177],
            [-1.422610582774615, 53.213484054613978],
            [-1.421933694108735, 53.213375603848299],
            [-1.420844411522053, 53.21242288026653],
            [-1.415291113131497, 53.214687064633139],
            [-1.411689934429263, 53.215539482511964],
            [-1.40988959284548, 53.215126082054269],
            [-1.409109443768514, 53.215339751444489],
            [-1.409168515646194, 53.215617803394466],
            [-1.408356475850994, 53.215536471569976],
            [-1.405122216012249, 53.214642156152685],
            [-1.405350130188021, 53.2142963186472],
            [-1.401406128498518, 53.213731851005477],
            [-1.399602823366415, 53.215150233402561],
            [-1.399624677243241, 53.21605103880102],
            [-1.400044851033692, 53.217061716665526],
            [-1.401560413251744, 53.217813619887451],
            [-1.39558660171129, 53.222698656487751],
            [-1.398521456268306, 53.22521512538097],
            [-1.397088090680562, 53.225421823963337],
            [-1.396336076085881, 53.226280060716093],
            [-1.396420868138406, 53.22706792287353],
            [-1.399266093277025, 53.230554724158822],
            [-1.400531628269976, 53.23132874922549],
            [-1.401095255143608, 53.233559042930317],
            [-1.400800776954887, 53.234263196046797],
            [-1.3989052410916, 53.233859041948222],
            [-1.393620838574784, 53.233990494484715],
            [-1.394055020838064, 53.234966204687673],
            [-1.391638504251024, 53.235593914851933],
            [-1.391581638973444, 53.237174780725034],
            [-1.390827303266606, 53.237223964707233],
            [-1.391197941021803, 53.237606988713232],
            [-1.390084076953366, 53.23786197676877],
            [-1.390273992081601, 53.238837349616979],
            [-1.389535132188007, 53.238849749922338],
            [-1.388426219388218, 53.239595544278856],
            [-1.387704752011416, 53.239438131391694],
            [-1.383262726650655, 53.24200136229738],
            [-1.382973400161833, 53.242635384054068],
            [-1.380345073230797, 53.243776836219901],
            [-1.377212460025913, 53.241141140284924],
            [-1.375043900537795, 53.240855633246049],
            [-1.371489293501555, 53.241187522203745],
            [-1.370456712899362, 53.241913776521436],
            [-1.366465045171615, 53.242711532331377],
            [-1.363203377951811, 53.243887894014414],
            [-1.363026449581796, 53.244298643109623],
            [-1.359995447914557, 53.244664446720961],
            [-1.356765203712241, 53.245722147101787],
            [-1.353171486183631, 53.245828556103113],
            [-1.350864460399237, 53.246369741180132],
            [-1.349641151008599, 53.244816976444156],
            [-1.349425876723468, 53.242904749969505],
            [-1.347862051888512, 53.241780678926958],
            [-1.347066288934313, 53.23952099390084],
            [-1.343326331521119, 53.240403849842245],
            [-1.339591574032029, 53.240062320147231],
            [-1.339329407748348, 53.240448292569837],
            [-1.338733079906044, 53.240445886367148],
            [-1.338829430000718, 53.241486444083712],
            [-1.336451932096661, 53.241839989266047],
            [-1.33531140429428, 53.241272726328312],
            [-1.331971228528524, 53.241572272332021],
            [-1.332123816863162, 53.2366777320686],
            [-1.326355842850923, 53.236080780470481],
            [-1.319203373877085, 53.239563842427806],
            [-1.316531395851733, 53.237611432948022],
            [-1.311519898263793, 53.232359102612357],
            [-1.311338753519716, 53.231697365471142],
            [-1.313454873773462, 53.229211525828816],
            [-1.314084813072405, 53.225881130985968],
            [-1.313733824804556, 53.224973918726704],
            [-1.31474051058707, 53.224027772972562],
            [-1.313036473654022, 53.223809422424097],
            [-1.312543194559995, 53.222249683234786],
            [-1.312577213206245, 53.221621547618348],
            [-1.314334727707735, 53.219235197337589],
            [-1.311152889097302, 53.217323769797773],
            [-1.31059718036835, 53.216116928246663],
            [-1.312547248786969, 53.212264707220633],
            [-1.31153187586733, 53.211991873920581],
            [-1.310999502575466, 53.21008042803458],
            [-1.312433952495519, 53.208677432255634],
            [-1.313382311312287, 53.208922905170581],
            [-1.313744648293027, 53.20845486530223],
            [-1.314206873163728, 53.205761714890173],
            [-1.313702829415832, 53.204885979016012],
            [-1.313891052982906, 53.202567891211906],
            [-1.311613244136069, 53.198969030599208],
            [-1.314925669365339, 53.19476416104991],
            [-1.31540665381701, 53.193322384208201],
            [-1.316347272178853, 53.193093168658365],
            [-1.31764537939773, 53.19118054559128],
            [-1.318159513090432, 53.186790536238156],
            [-1.31879320258377, 53.185804464265217],
            [-1.320635121682315, 53.185442830364664],
            [-1.321704766881762, 53.185559487980484],
            [-1.327877892546622, 53.187946894888412],
            [-1.329840658625263, 53.187706253452824],
            [-1.330360599647388, 53.18718511402799],
            [-1.331358790643471, 53.186896775962069],
            [-1.331779033467431, 53.18710767874375],
            [-1.334886527880812, 53.186967751606417],
            [-1.335955271328656, 53.187438444559383],
            [-1.337033364447852, 53.187304216902731],
            [-1.337198926098444, 53.186854786341407],
            [-1.338005738782712, 53.186843089919037],
            [-1.344265256718853, 53.187954579126341],
            [-1.345645270190904, 53.183419099166791],
            [-1.351917992422669, 53.184428683930285],
            [-1.352222285153391, 53.185487449742411],
            [-1.353620730402812, 53.185339531446616],
            [-1.354429491625206, 53.184398267354261],
            [-1.356784053398787, 53.185455524016916],
            [-1.359682848027824, 53.184343007685669],
            [-1.359309747282512, 53.184174705417092],
            [-1.361822872846175, 53.182621398056369],
            [-1.363243062843784, 53.182714389803401],
            [-1.363868182324596, 53.182334794666943],
            [-1.36456363453024, 53.180536201226495],
            [-1.365063449649026, 53.180330319122554],
            [-1.367907113077752, 53.180080257596003],
            [-1.369412136833212, 53.180811847914647],
            [-1.371171430046758, 53.180633272068697],
            [-1.373172554160274, 53.178644650365925],
            [-1.374119118699313, 53.176832040151268],
            [-1.376687118092944, 53.17503870015652],
            [-1.377443847644922, 53.173715872025582],
            [-1.378266834594301, 53.17370578581238],
            [-1.376002466706059, 53.172523576982087],
            [-1.373784785402714, 53.172356434056738],
            [-1.372203112563724, 53.171459095076187],
            [-1.36666473654235, 53.170964157475488],
            [-1.36477180422885, 53.168060503729727],
            [-1.364814621589788, 53.167389248877718],
            [-1.362443528124515, 53.166390486385623],
            [-1.360064907135294, 53.166408299557467],
            [-1.358758001420019, 53.165675856277126],
            [-1.358322303009297, 53.164199303236941],
            [-1.356845497230928, 53.163532442610169],
            [-1.356929115383994, 53.163241647881321],
            [-1.355536541529593, 53.162247124035119],
            [-1.352843338521078, 53.158731280864579],
            [-1.353700648371631, 53.157312961957082],
            [-1.353061329045811, 53.156167878595795],
            [-1.354751688819883, 53.15533746363095],
            [-1.355916997270412, 53.153813825650502],
            [-1.354960152015467, 53.151704303191742],
            [-1.357478593486368, 53.150479213871336],
            [-1.360654731131449, 53.147646756634749],
            [-1.362529065674641, 53.146980819894615],
            [-1.362532046275235, 53.143244067362865],
            [-1.366264620910141, 53.142362373782909],
            [-1.366628117142086, 53.141737766408895],
            [-1.370996870678228, 53.139453409633191],
            [-1.374194661785983, 53.139128664432782],
            [-1.37470463587045, 53.138392437960029],
            [-1.377196456611868, 53.136951053804623],
            [-1.377727077150303, 53.135814907041194],
            [-1.377299371516567, 53.135311978953496],
            [-1.3758940291213, 53.134918992118855],
            [-1.375537600453837, 53.134037989457333],
            [-1.374771213307097, 53.134113972715511],
            [-1.374199010017517, 53.133683985469794],
            [-1.372777601983274, 53.131627891239269],
            [-1.37338998856544, 53.130936255473614],
            [-1.372717959685144, 53.129975377741772],
            [-1.37338938584009, 53.129435968805431],
            [-1.372702582988985, 53.128567600902343],
            [-1.374882346533842, 53.126350656925922],
            [-1.374193434723054, 53.125834659670986],
            [-1.373298873240642, 53.125794000090011],
            [-1.374111355351659, 53.124797782520794],
            [-1.374814140899004, 53.124768213414619],
            [-1.375139706798185, 53.123545602957009],
            [-1.374882815109693, 53.12168170797581],
            [-1.376541002372531, 53.1200597708158],
            [-1.377313202324511, 53.118529365595201],
            [-1.371710166093088, 53.110938277082461],
            [-1.370909754620603, 53.109073298375662],
            [-1.372151396765803, 53.108233971514224],
            [-1.373652452295528, 53.107837363551603],
            [-1.377034616456677, 53.108648844643071],
            [-1.377370904523098, 53.108429470186941],
            [-1.377923551305374, 53.10864090629471],
            [-1.378125227380441, 53.108225762040895],
            [-1.378975853535221, 53.108802809228671],
            [-1.379111202444772, 53.108428667532472],
            [-1.379674500786629, 53.108523292127352],
            [-1.379610696750569, 53.107870347762123],
            [-1.380236528630856, 53.108290702537822],
            [-1.381231995571876, 53.108353409164359],
            [-1.381289486561923, 53.107886272271664],
            [-1.382469154557776, 53.10749867024817],
            [-1.383430962091357, 53.107616017782149],
            [-1.384022864497625, 53.106962872990628],
            [-1.385195300280748, 53.106974324671498],
            [-1.386049539346218, 53.106567927465846],
            [-1.38627330725043, 53.106903477010107],
            [-1.386792849549676, 53.106293093474974],
            [-1.387188734173618, 53.106710429689528],
            [-1.388264352025169, 53.106386057840758],
            [-1.388230386016692, 53.105937325018253],
            [-1.389042201070361, 53.106413422503785],
            [-1.389756881988609, 53.106154603635275],
            [-1.389545484886464, 53.105787662051569],
            [-1.390426865728441, 53.105781389577082],
            [-1.390324243014437, 53.105432984353364],
            [-1.391201231506071, 53.105208247447379],
            [-1.392320763207319, 53.105366780575238],
            [-1.392725171307136, 53.1048636534371],
            [-1.393879775262694, 53.105077184531773],
            [-1.394909140349222, 53.104742629287912],
            [-1.395563744728811, 53.105142374623156],
            [-1.397464301866123, 53.105314708735811],
            [-1.400527506045852, 53.106201214608419],
            [-1.402897930381519, 53.107289122310455],
            [-1.40395447834437, 53.107040022089627],
            [-1.40423697518854, 53.106594675449543],
            [-1.404538981323493, 53.107007888263865],
            [-1.406196400247095, 53.106838178524988],
            [-1.406172977913845, 53.107345947908115],
            [-1.407005525142162, 53.106873671918329],
            [-1.407697912147759, 53.107034427194755],
            [-1.407917889108396, 53.107440930958163],
            [-1.409123609506696, 53.107313879726789],
            [-1.409095801506858, 53.107053955782916],
            [-1.410033860318306, 53.10738311396846],
            [-1.409861110952443, 53.10636199136836],
            [-1.413921572477848, 53.10544627317676],
            [-1.417251480825068, 53.105634305567946],
            [-1.41966499757519, 53.106545902538549],
            [-1.420321457973146, 53.105492876581557],
            [-1.422299852345776, 53.104546045874741],
            [-1.423736427809074, 53.103341270494283],
            [-1.424702685171531, 53.103574265944125],
            [-1.424940680007817, 53.103984420868713],
            [-1.425443017150528, 53.103949090998576],
            [-1.427367816043598, 53.105450556795638],
            [-1.428915049258962, 53.10569439998411],
            [-1.430146508410494, 53.105306574733476],
            [-1.432546827839758, 53.105878057949617],
            [-1.433006158715485, 53.105479330540341],
            [-1.434395597025587, 53.105684596783966],
            [-1.437255537282964, 53.104922382835667],
            [-1.438848753844559, 53.104964058818666],
            [-1.440242740266975, 53.104589479737051],
            [-1.441569919824347, 53.104758413137532],
            [-1.441913022431517, 53.104446300956589],
            [-1.442542504570708, 53.104627233636172],
            [-1.442698368612326, 53.104355592629254],
            [-1.443409167079255, 53.104485663198567],
            [-1.448205581496154, 53.103648621788224],
            [-1.448572649042074, 53.104144723587211],
            [-1.45030431298043, 53.104455661924391],
            [-1.450799849205614, 53.10495324850217],
            [-1.450433151283048, 53.105011784110033],
            [-1.450475992969563, 53.105518069194204],
            [-1.452352326692434, 53.106440005981291],
            [-1.452418234987122, 53.107013814625525],
            [-1.453357081752016, 53.107306677899778],
            [-1.455547899936328, 53.109597250566011],
            [-1.457283086364439, 53.108818622517127],
            [-1.460011404571468, 53.108292575363265],
            [-1.461516343359732, 53.110152044057067],
            [-1.465371654130367, 53.110782468309459],
            [-1.464694327159428, 53.113959770651732],
            [-1.463018260426783, 53.114184145255464],
            [-1.463565517308836, 53.114988438809092],
            [-1.464993782693581, 53.115592637487929],
            [-1.464872613537214, 53.115845585742683],
            [-1.466205014438706, 53.115749088401586],
            [-1.467135514849004, 53.116014837334504],
            [-1.468901676076871, 53.118426408746075],
            [-1.469539977309581, 53.118766343848819],
            [-1.469821044704865, 53.119724933253387],
            [-1.471177378121847, 53.119757030602088],
            [-1.474076781031045, 53.120682259134803],
            [-1.480301215318414, 53.121468255743672],
            [-1.486845298454946, 53.122911506487902],
            [-1.489001456163845, 53.129980791740635],
            [-1.490537377443403, 53.131537983345495],
            [-1.493618324971458, 53.13123470334974],
            [-1.495368127933902, 53.133946037026533],
            [-1.508657049342106, 53.146294997318094],
            [-1.509647656611102, 53.148716228597088],
            [-1.509084185749628, 53.149221788767797],
            [-1.509668309302687, 53.150973458620733],
            [-1.515670661345652, 53.154003035615602],
            [-1.517350943694358, 53.156451269932198],
            [-1.539630779426506, 53.171377555536438],
            [-1.547795158209814, 53.181978045185716],
            [-1.551750677954687, 53.188519868090744],
            [-1.552993105440478, 53.196269411199879],
            [-1.548296768241232, 53.199900322549517],
            [-1.52977234651946, 53.201072773206342],
            [-1.517647133592136, 53.200750172823184],
            [-1.517517722407416, 53.201209883355595],
            [-1.515596197628373, 53.202613356757553],
            [-1.516835101495194, 53.203605369937826],
            [-1.517271927191097, 53.206034153833073],
            [-1.518903895528789, 53.205763890462741],
            [-1.519442895518379, 53.206164274106229],
            [-1.521266297579707, 53.206449367346373],
            [-1.524401484122384, 53.20644573395262],
            [-1.525977050316747, 53.207485735730188],
            [-1.528722003502234, 53.207439087434338],
            [-1.531797196543276, 53.207863794312779],
            [-1.535257112050833, 53.211070197473319],
            [-1.537448961868026, 53.211514682845539],
            [-1.540241227708676, 53.210707489301058],
            [-1.542506511177002, 53.211852401871305],
            [-1.553612466050957, 53.215763322868654],
            [-1.569021695318016, 53.223110905915711],
            [-1.568900237985986, 53.22491543716648],
            [-1.567570734442719, 53.22634525150076],
            [-1.566900093712019, 53.226452482544268],
            [-1.565906917186039, 53.227200343230464],
            [-1.566251627422512, 53.227923405308204],
            [-1.564991626666568, 53.229720189831241],
            [-1.56569216100371, 53.23125444909958],
            [-1.567607224116395, 53.232838057256522],
            [-1.567474038780806, 53.233715788134155],
            [-1.569702560699489, 53.23423711355742],
            [-1.570035970655456, 53.235502152233671],
            [-1.57097024503036, 53.235863273662375],
            [-1.570764498967647, 53.237111088333087],
            [-1.571297234891458, 53.237476154743355],
            [-1.57064448350031, 53.239673384151175],
            [-1.571480081252469, 53.239872346288578],
            [-1.572057745308214, 53.240995332149076],
            [-1.570986886204092, 53.241244971704006],
            [-1.570385820933929, 53.240815837160888],
            [-1.569340042405703, 53.241253419636791],
            [-1.568518573063499, 53.241883267426928],
            [-1.568533766837262, 53.24260782515465],
            [-1.56799939172733, 53.243146122032037],
            [-1.563881165562924, 53.245102395263743],
            [-1.562051963734657, 53.246666048412557],
            [-1.559510513174834, 53.247524116383879],
            [-1.55762087645925, 53.248818711201224],
            [-1.557177282731593, 53.249543365390124],
            [-1.565781165967825, 53.261410610306775],
            [-1.570974152414889, 53.262906262569899],
            [-1.575344250608406, 53.263056728993284],
            [-1.578745497680963, 53.262638220870294],
            [-1.582268891846264, 53.263216905057092],
            [-1.584938138408635, 53.262679699419664],
            [-1.584539701368673, 53.263108877751407],
            [-1.584893304566624, 53.263594606465006],
            [-1.584305900803517, 53.263565594926767],
            [-1.584313913061393, 53.263976412391955],
            [-1.58315894718579, 53.263726987955707],
            [-1.582912811442568, 53.263908601073119],
            [-1.58360404492156, 53.264670571494364],
            [-1.582941812701738, 53.264780618958859],
            [-1.583643981136291, 53.265653189818416],
            [-1.583429961335378, 53.266077614360981],
            [-1.582724567373399, 53.265690427761839],
            [-1.582405224376551, 53.266152235176563],
            [-1.583087604916761, 53.266439567242479],
            [-1.582546970863247, 53.267146895406924],
            [-1.583218331604466, 53.269187008764675],
            [-1.582717261834582, 53.26952323726249],
            [-1.58358397317307, 53.269671883976564],
            [-1.583374931808455, 53.270353405819819],
            [-1.582760998623649, 53.270267664641274],
            [-1.582440235441981, 53.271257260736434],
            [-1.583478323041488, 53.271286806290682],
            [-1.583724769676858, 53.272004075141645],
            [-1.584312916742118, 53.272122980092483],
            [-1.586059224726827, 53.27358344118619],
            [-1.586303355729162, 53.275323623246116],
            [-1.589350206304106, 53.278021801089366],
            [-1.589392997013698, 53.278721277496693],
            [-1.587674874840366, 53.280845700313932],
            [-1.58796347629342, 53.28109119255673],
            [-1.584604058499761, 53.282468316418502],
            [-1.583921738120257, 53.283229088733556],
            [-1.582530296358884, 53.283182880912364],
            [-1.580845075117969, 53.2848039468604],
            [-1.580228783301113, 53.284938410482305],
            [-1.579395781202077, 53.286062673021206],
            [-1.578834294733069, 53.286112827877176],
            [-1.578854446570824, 53.286962340388101],
            [-1.580510940367675, 53.28875245021824],
            [-1.582261508821711, 53.289062411587743],
            [-1.582054900222997, 53.289328655388623],
            [-1.583133405057098, 53.290106362285485],
            [-1.583456727406184, 53.289870187069724],
            [-1.58394339037112, 53.290423796341869],
            [-1.584502005808108, 53.290365517485434],
            [-1.584334154734841, 53.290812574759883],
            [-1.584947752451223, 53.291118531252124],
            [-1.597442673553128, 53.29573931518987],
            [-1.599301292325941, 53.302346024773897],
            [-1.598602125934333, 53.304898282959385],
            [-1.59781572459053, 53.305384624752712],
            [-1.59873159608708, 53.308057368995691],
            [-1.599094428818605, 53.311300832519393],
            [-1.590379661156507, 53.318280676215039],
            [-1.586774778715534, 53.319909598255492],
            [-1.587295487545576, 53.320864206267402],
            [-1.586903132865833, 53.321386893045421],
            [-1.584903227041882, 53.321570517805036],
            [-1.578925432126404, 53.316677682463848],
            [-1.581289219694635, 53.316409142855541],
            [-1.583005769865039, 53.315705039588977],
            [-1.581240905723613, 53.314289425297602],
            [-1.580535099223884, 53.311720657156094],
            [-1.573158464809135, 53.312754283901761],
            [-1.573907371053046, 53.313362801733057],
            [-1.570494853161926, 53.314348324896471],
            [-1.567500906891775, 53.314744706285559],
            [-1.56609006503506, 53.31355127239587],
            [-1.564091655512151, 53.313615003005054],
            [-1.562260893962832, 53.315778190679602],
            [-1.561366021729199, 53.315803670002509],
            [-1.560176523242894, 53.313678844599742],
            [-1.558490749559915, 53.313698689054512],
            [-1.555752987629062, 53.311531228057639],
            [-1.556554041263509, 53.311426341320207],
            [-1.559124559851188, 53.309723502358899],
            [-1.559689253407027, 53.308226259733686],
            [-1.561828125953387, 53.306584690518392],
            [-1.557935038259157, 53.305737975572228],
            [-1.558458736822501, 53.305304857173617],
            [-1.55685304130794, 53.305449019285639],
            [-1.554792143447298, 53.306073261273802],
            [-1.553539605796636, 53.305878020879796],
            [-1.550396704689589, 53.306209595308495],
            [-1.549077146293652, 53.306965068270742],
            [-1.546859626513781, 53.30663037789413],
            [-1.542604636071004, 53.305271224910484],
            [-1.541656836722905, 53.304652756412167],
            [-1.540929110853574, 53.304925016498792],
            [-1.540936377832816, 53.304667066367202],
            [-1.539236292613927, 53.304514897363276],
            [-1.539008033700775, 53.304808848120601],
            [-1.536769918020961, 53.304730969445544],
            [-1.536791840425393, 53.305062740676746],
            [-1.535043106873816, 53.305244705923322],
            [-1.534987148099839, 53.30569932041962],
            [-1.534581150831474, 53.305498187005085],
            [-1.533116275112734, 53.306207074426247],
            [-1.533374495000982, 53.306473253300126],
            [-1.532974141125972, 53.306855508553824],
            [-1.533410599723588, 53.307153845312136],
            [-1.533087343748767, 53.30762179569227],
            [-1.533408361327496, 53.307634736404722],
            [-1.53285482951322, 53.308437966161605],
            [-1.531843083003747, 53.308733328675949],
            [-1.532015165436794, 53.309878275135354],
            [-1.531458853017835, 53.309970474935724],
            [-1.531215961405789, 53.310633792007067],
            [-1.531029383170663, 53.310402946393623],
            [-1.530120143884472, 53.31065195534368],
            [-1.530031131704678, 53.310420593307512],
            [-1.529411940802475, 53.310907143370521],
            [-1.528899592374779, 53.310684897318758],
            [-1.528675546982884, 53.31085929405625],
            [-1.528198344428822, 53.310308194429076],
            [-1.526186367901263, 53.310385618369068],
            [-1.526317187484955, 53.311448611303206],
            [-1.525790848943492, 53.312083825852397],
            [-1.52418543584428, 53.312439684999951],
            [-1.521631449858546, 53.311717565803733],
            [-1.521547839139421, 53.312752738060219],
            [-1.521197175032108, 53.312837624275815],
            [-1.519115681892294, 53.312277345834282],
            [-1.51733961304412, 53.312188379570323],
            [-1.516131423971703, 53.312583488858337],
            [-1.515897190237793, 53.313387933832736],
            [-1.51526246669676, 53.313770077742419],
            [-1.510489114259837, 53.316028354954035],
            [-1.502132988050177, 53.317564135419921],
            [-1.500716034898715, 53.315884506322256],
            [-1.496127682691263, 53.316368598167976],
            [-1.493096000976856, 53.316711718282797],
            [-1.492331497766106, 53.317383526948291],
            [-1.491066349296622, 53.31736106096487],
            [-1.484716781207316, 53.318730677532251],
            [-1.484632501413723, 53.318499301946055],
            [-1.482500974806457, 53.318865811422732],
            [-1.479691326019916, 53.318702586173011],
            [-1.479599857476217, 53.319311627089263],
            [-1.476917854806903, 53.319265747204881],
            [-1.474387643741133, 53.318472608352756],
            [-1.469624204244897, 53.317169736830458],
            [-1.467866804814091, 53.317140342172067],
            [-1.467209235580809, 53.317992243087751],
            [-1.463224018821266, 53.319769450410988],
            [-1.461667969420059, 53.32019390500114],
            [-1.45997475716372, 53.320064908207804],
            [-1.459735970551241, 53.319715961433054],
            [-1.457263121786359, 53.32069620743966],
            [-1.457281263659479, 53.321751574020297],
            [-1.455224798295959, 53.32185097091358],
            [-1.455825910634552, 53.323455515058491],
            [-1.454308318426409, 53.324262072624627],
            [-1.455131320198009, 53.324948080928479],
            [-1.456371086712002, 53.325093958319371],
            [-1.456594687541624, 53.326284192621465],
            [-1.458037642770699, 53.326394123315254],
            [-1.460240200622897, 53.327227474896574],
            [-1.461702476392753, 53.328430484098774],
            [-1.45993740455707, 53.329686334322645],
            [-1.458277064314212, 53.328710720111829],
            [-1.457521213670548, 53.328639871939721],
            [-1.456638051733956, 53.329372036497254],
            [-1.459881426237216, 53.330671250998549],
            [-1.453937917089104, 53.330699044303543],
            [-1.450805230809949, 53.331552091116116],
            [-1.443032491378099, 53.337243729926499],
            [-1.442137329630508, 53.337366292179979],
            [-1.440874056946382, 53.33635274095893],
            [-1.436737539880861, 53.337430826251726],
            [-1.433911435840525, 53.337410268393121],
            [-1.433613615326073, 53.337672227037153],
            [-1.427793715342887, 53.336131663136285],
            [-1.423491024312392, 53.335922198502196],
            [-1.423097747199774, 53.335352207523748],
            [-1.422403308603941, 53.335288624871914],
            [-1.422270448815955, 53.335007531885786],
            [-1.421184443230273, 53.335027440039646],
            [-1.420868046565201, 53.334549499898422],
            [-1.41611634570067, 53.336994675489201],
            [-1.414510872503525, 53.338316254055066],
            [-1.413852554600243, 53.337915719442798],
            [-1.412591008023941, 53.338792217679341],
            [-1.413027486213532, 53.339598860604546],
            [-1.412113835584951, 53.340947176909083],
            [-1.412385387892726, 53.341077952205886],
            [-1.411034835869431, 53.34197377101659],
            [-1.408747737731587, 53.341946286808771],
            [-1.406786613036535, 53.3408327360737],
            [-1.407566062747724, 53.340077054139464],
            [-1.406396755284264, 53.339380008341649],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000039",
        LAD13CDO: "17UK",
        LAD13NM: "South Derbyshire",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.641381982634187, 52.948144264904741],
            [-1.639272444432535, 52.947687522330142],
            [-1.636178315060583, 52.947872266942959],
            [-1.633105080786424, 52.946814674980246],
            [-1.633095120983457, 52.946584518286819],
            [-1.62853857395043, 52.946716900450006],
            [-1.627509607083751, 52.946267813236162],
            [-1.62484280898575, 52.945916939136204],
            [-1.623548196082236, 52.946247250480084],
            [-1.620980886373811, 52.947799639099657],
            [-1.619467410108912, 52.947451415298495],
            [-1.616567527449607, 52.945856389346964],
            [-1.613850119221355, 52.945185086046514],
            [-1.612957895442082, 52.944275165041979],
            [-1.609930239932683, 52.946019086882806],
            [-1.609402804821411, 52.945922069253029],
            [-1.607141507434426, 52.947154246190259],
            [-1.605804713623142, 52.94637494508958],
            [-1.606564397526378, 52.945641236557179],
            [-1.60830810557736, 52.945030325744895],
            [-1.607324805088532, 52.94433131097901],
            [-1.610099441204769, 52.942591120454153],
            [-1.608835273827766, 52.941853441622513],
            [-1.610058652971305, 52.940837172391184],
            [-1.607256369034018, 52.939724962504435],
            [-1.604030744045834, 52.937407607755922],
            [-1.604058653695865, 52.93679462915923],
            [-1.603157957516697, 52.937015460979737],
            [-1.602894668787455, 52.936682876211151],
            [-1.601651394456475, 52.937753840680585],
            [-1.597869096563941, 52.937757291847497],
            [-1.596351041915125, 52.937318863635909],
            [-1.594243116137541, 52.937592156868888],
            [-1.592731929163294, 52.936902004769088],
            [-1.591961101683439, 52.93612178593834],
            [-1.590991772242036, 52.936042046710909],
            [-1.588615048232451, 52.935009065281669],
            [-1.585356791076718, 52.93578161811616],
            [-1.584490126851136, 52.936480659385445],
            [-1.582936244079291, 52.936692761465451],
            [-1.579802601772271, 52.935942807208882],
            [-1.578068164966443, 52.934983796918274],
            [-1.574271388271501, 52.935880883662826],
            [-1.57206599217172, 52.935620367421869],
            [-1.570106447386959, 52.935776905293515],
            [-1.567169838908927, 52.93305147378684],
            [-1.563491890716248, 52.933962132984455],
            [-1.562076146689599, 52.933900294671623],
            [-1.558566922028831, 52.932978496914465],
            [-1.554605649661412, 52.930454799715719],
            [-1.551100365989388, 52.928906237499959],
            [-1.54946754917705, 52.928563860160608],
            [-1.549405913711553, 52.92790920197924],
            [-1.547345117812947, 52.926673428984188],
            [-1.545733204915592, 52.926618738897623],
            [-1.544073337021888, 52.924189754320309],
            [-1.549613547276193, 52.922806732692798],
            [-1.552880927810323, 52.922560171517567],
            [-1.551880073629035, 52.920526606315775],
            [-1.551452790415172, 52.918983319592812],
            [-1.551708264150753, 52.918439527776691],
            [-1.551072854833658, 52.917034789304893],
            [-1.556857005466739, 52.914660823870221],
            [-1.555674712515151, 52.913791633808643],
            [-1.556443444317284, 52.913509541416822],
            [-1.554041526664249, 52.909191050108419],
            [-1.55465641235394, 52.908534433857987],
            [-1.553894813789129, 52.906246478103334],
            [-1.555114718907447, 52.906032607832913],
            [-1.551157122819528, 52.901825980408042],
            [-1.549998079832565, 52.902201849445376],
            [-1.548627942057486, 52.900856336637318],
            [-1.54748147727958, 52.899596159602531],
            [-1.544979459543561, 52.900312052270458],
            [-1.544222713175908, 52.898757583581073],
            [-1.542437603228605, 52.896755079043267],
            [-1.543076313340739, 52.896377284459149],
            [-1.54243682603732, 52.895134287155017],
            [-1.541086431674057, 52.894073725125132],
            [-1.539641730912111, 52.893924307896071],
            [-1.542063368301027, 52.89234613370671],
            [-1.541670448849148, 52.892113591462262],
            [-1.541694795023962, 52.889522038889645],
            [-1.540374821479918, 52.889538516266605],
            [-1.539666024588766, 52.88924361725735],
            [-1.539907983574102, 52.888991052742739],
            [-1.5386748108609, 52.888532307262892],
            [-1.537122878105023, 52.888421996090372],
            [-1.536909864268309, 52.888188340481328],
            [-1.536261518081243, 52.888361106085441],
            [-1.53589270317144, 52.888104367092438],
            [-1.534357045757873, 52.888419282616063],
            [-1.533949647475574, 52.888019454701485],
            [-1.530663837365921, 52.888964796300996],
            [-1.530837227762392, 52.889148864944282],
            [-1.528421096008507, 52.890198255828828],
            [-1.528100924372107, 52.890666232426796],
            [-1.526375830753879, 52.890893095271736],
            [-1.524746873435811, 52.890617809285274],
            [-1.524264080182704, 52.888828781839919],
            [-1.523201580591945, 52.887323291121255],
            [-1.515833981204714, 52.886440408818018],
            [-1.51461557517161, 52.884687894529868],
            [-1.503116048453428, 52.884827351429777],
            [-1.502782576396497, 52.882539044945055],
            [-1.501649291560698, 52.882620596525975],
            [-1.502007408061349, 52.88158381791542],
            [-1.499498255952674, 52.882686169055262],
            [-1.499146913451172, 52.881193341230329],
            [-1.499998324082227, 52.879000806473748],
            [-1.499893689539179, 52.877245627000832],
            [-1.496717293166459, 52.876831287463069],
            [-1.496662136100672, 52.875819741125511],
            [-1.494950611167211, 52.874914433296482],
            [-1.495373950087634, 52.874667224073413],
            [-1.493598813374578, 52.873616896131438],
            [-1.49519513015448, 52.870359617754389],
            [-1.493670877180592, 52.869722971500437],
            [-1.490361269096754, 52.86931867997324],
            [-1.487532016375965, 52.869506077394284],
            [-1.487540650006053, 52.869782091050126],
            [-1.483830708037829, 52.870318884649571],
            [-1.484426226914642, 52.871214123429397],
            [-1.467666340160577, 52.870518148961295],
            [-1.467169134351875, 52.872905320086993],
            [-1.463761885244256, 52.872467487529853],
            [-1.45290782097832, 52.868306223214717],
            [-1.450656352119075, 52.866963594388515],
            [-1.449912537640817, 52.86693048772603],
            [-1.447104800083501, 52.864487598327763],
            [-1.444854665761552, 52.86318621574911],
            [-1.444882934530768, 52.862134577815674],
            [-1.446231947061724, 52.861291366614076],
            [-1.443566803160367, 52.861099124515775],
            [-1.441565617331923, 52.861716302572681],
            [-1.440887065336969, 52.862275853285489],
            [-1.440814043702564, 52.862063357663487],
            [-1.439085024568795, 52.862416584704143],
            [-1.438216792024125, 52.861672647326841],
            [-1.437912381807165, 52.862816468654039],
            [-1.427686018566254, 52.863922796782965],
            [-1.427960501690157, 52.864737667696204],
            [-1.423572756978986, 52.865059859295584],
            [-1.425000562567184, 52.868858544518019],
            [-1.426145473020122, 52.869118482228082],
            [-1.427532460808307, 52.868820429514351],
            [-1.429782931061184, 52.870192259115498],
            [-1.425825963121826, 52.87216617133295],
            [-1.425187169974231, 52.872949662275879],
            [-1.425412126853098, 52.873915321461489],
            [-1.416469582856993, 52.875680424124333],
            [-1.420097756674704, 52.878253894725795],
            [-1.419595207957734, 52.878611918975714],
            [-1.420560566167473, 52.879874257585456],
            [-1.420382018331217, 52.881898714406859],
            [-1.418917342076929, 52.884528171787707],
            [-1.416741072262664, 52.884796183428222],
            [-1.41378380533071, 52.884238667715934],
            [-1.411666295735096, 52.884226404859319],
            [-1.411010892327697, 52.884777808353412],
            [-1.41038091755879, 52.886521337693623],
            [-1.409738768861548, 52.88642106355141],
            [-1.40986817364476, 52.888274434974974],
            [-1.409287706473037, 52.889320621310254],
            [-1.407593029146514, 52.889672662775283],
            [-1.407588597947635, 52.891415697194773],
            [-1.407902773331104, 52.89148378494437],
            [-1.406512149078536, 52.893618137255714],
            [-1.406509285745115, 52.894589883173623],
            [-1.405924674782072, 52.894513248692355],
            [-1.405830109997851, 52.895450376116109],
            [-1.403107843429273, 52.895597645923992],
            [-1.402953732832391, 52.896211750528693],
            [-1.403495464279144, 52.896922839795273],
            [-1.402776655622557, 52.900443993512553],
            [-1.400339084621155, 52.901375611678617],
            [-1.398249312066732, 52.901554726651341],
            [-1.393644106350751, 52.901836063066725],
            [-1.388267719645834, 52.901449832598786],
            [-1.38651076910827, 52.901323014469277],
            [-1.384720765649358, 52.901851330474052],
            [-1.383103586773897, 52.901303580021988],
            [-1.378184713829503, 52.901058603344438],
            [-1.374290626246592, 52.898306267820928],
            [-1.373189756070174, 52.895270117245055],
            [-1.372270501296325, 52.89469533274081],
            [-1.370829605057477, 52.894731761344957],
            [-1.367615483101832, 52.895577690663416],
            [-1.366288900851431, 52.897150973114741],
            [-1.364739861532252, 52.896647383055971],
            [-1.362398173878039, 52.896372349944706],
            [-1.361766690356092, 52.895948252867143],
            [-1.361524354312057, 52.894635386742408],
            [-1.362801438741415, 52.892794903729701],
            [-1.361422827182982, 52.892338928608893],
            [-1.359292595394515, 52.893667793828136],
            [-1.359272960110814, 52.894495618876512],
            [-1.358539277029632, 52.89525936221564],
            [-1.357600317044056, 52.895125740714064],
            [-1.356185062670084, 52.894332401487496],
            [-1.355330759404082, 52.893002720716616],
            [-1.356350427807831, 52.89169758146204],
            [-1.355489408292647, 52.891525710351324],
            [-1.355442908442584, 52.8909528280142],
            [-1.355991650046632, 52.890431716198485],
            [-1.356782433490358, 52.890427009698044],
            [-1.357050203154927, 52.889808184320863],
            [-1.354838578476335, 52.889309866324432],
            [-1.354369455390974, 52.888773342846768],
            [-1.350305752051035, 52.889091896063434],
            [-1.348703430107328, 52.888604883805101],
            [-1.348281247037957, 52.887817786452786],
            [-1.346457067711954, 52.88795609434262],
            [-1.34541045742047, 52.888667695072897],
            [-1.346541596211935, 52.890242586895361],
            [-1.345704923184689, 52.89072880920191],
            [-1.344772556744885, 52.890659846508967],
            [-1.344598311732792, 52.889992764358183],
            [-1.342759809925721, 52.889596960825628],
            [-1.341080395226921, 52.890082082383579],
            [-1.339303114071295, 52.889669484701734],
            [-1.338825299661468, 52.889228139746471],
            [-1.339058849465233, 52.888628041758224],
            [-1.341650791696126, 52.887552903330153],
            [-1.340982412247884, 52.886349100481148],
            [-1.34179478402749, 52.885306326557078],
            [-1.344023444160231, 52.885046268985342],
            [-1.347208706479133, 52.885903429551846],
            [-1.3481183001408, 52.885799650162461],
            [-1.346865656069517, 52.882819944382895],
            [-1.34749253828816, 52.882141984342731],
            [-1.349805976613416, 52.881267402229966],
            [-1.349975434144535, 52.880766715175632],
            [-1.348544238978847, 52.879986678639384],
            [-1.347239843102111, 52.87977096330858],
            [-1.346307613446112, 52.879906074522502],
            [-1.344971469252521, 52.880615179711413],
            [-1.343472203363396, 52.88070848653998],
            [-1.3426390291429, 52.879400401483217],
            [-1.340445138543478, 52.879345992856926],
            [-1.336934336785732, 52.878732245935481],
            [-1.336138719974052, 52.878483288548203],
            [-1.335229951641138, 52.876305441850135],
            [-1.332824953831861, 52.876705479214579],
            [-1.329978945566379, 52.878144866825934],
            [-1.330169995811467, 52.875677422433313],
            [-1.328677050385612, 52.874696330094729],
            [-1.327549453489581, 52.874497577678881],
            [-1.326919592977569, 52.874298938455851],
            [-1.325736851350801, 52.875358389623379],
            [-1.321978149862238, 52.876384278859497],
            [-1.320172825219684, 52.875911901284624],
            [-1.319545318882811, 52.875183753392101],
            [-1.320267883117899, 52.874678183340627],
            [-1.320600599514454, 52.87354291124246],
            [-1.321355705629206, 52.873329680268725],
            [-1.323747802647673, 52.873706496795251],
            [-1.3238840787197, 52.872968333317722],
            [-1.322830060647724, 52.872342053035759],
            [-1.319312907072995, 52.872850538632271],
            [-1.319284677348654, 52.869527845905523],
            [-1.322955694450206, 52.868374789928346],
            [-1.32434887046291, 52.866574925785294],
            [-1.325338784219323, 52.865962970377339],
            [-1.327912310071936, 52.865822038369402],
            [-1.331054786080946, 52.867127974663262],
            [-1.332801318909338, 52.867155775112728],
            [-1.337528397095131, 52.864899786513817],
            [-1.339254673137195, 52.864592968724409],
            [-1.340392955697884, 52.86505326548636],
            [-1.342429569634767, 52.867505212489363],
            [-1.34361075343733, 52.867787722777251],
            [-1.344868660601404, 52.867514192812258],
            [-1.345931255338729, 52.866792795035821],
            [-1.346421576803838, 52.865203448358528],
            [-1.343465463656096, 52.863240016474208],
            [-1.34341008194614, 52.862775850534412],
            [-1.347476122094448, 52.861249357218185],
            [-1.348266710199831, 52.859338027393889],
            [-1.351901429783789, 52.857620235426992],
            [-1.352101033483941, 52.8563834649729],
            [-1.350462461517602, 52.854523570461666],
            [-1.351179122718411, 52.853270748973543],
            [-1.352208154720129, 52.853077696185039],
            [-1.353119320156499, 52.85342066837066],
            [-1.353933440299156, 52.855000066471476],
            [-1.355148722656861, 52.855970348277211],
            [-1.358062560175472, 52.856069732723476],
            [-1.360725538478865, 52.855352345100812],
            [-1.364031083423991, 52.853848159522059],
            [-1.366030066559381, 52.850884206734548],
            [-1.369219157825385, 52.848977422722292],
            [-1.372421522752401, 52.847881479879504],
            [-1.38003382547374, 52.846457957479323],
            [-1.381539854125004, 52.845799683480323],
            [-1.382777332156043, 52.844705800416342],
            [-1.383134307482908, 52.84361272862116],
            [-1.384792732117472, 52.843086452045767],
            [-1.384935550531419, 52.842225094462535],
            [-1.385956090069175, 52.841130953404033],
            [-1.385499163489286, 52.840686304694081],
            [-1.388219418189865, 52.839385176429388],
            [-1.387583552457004, 52.838891070256821],
            [-1.394329325862366, 52.836754685118322],
            [-1.393129227691488, 52.834031018104781],
            [-1.391509161181676, 52.83354358586238],
            [-1.389518300421323, 52.833567529660492],
            [-1.387942831638966, 52.833079378858422],
            [-1.392085035023974, 52.829505728999344],
            [-1.395581206290378, 52.825594235991389],
            [-1.396064972247482, 52.825597596048702],
            [-1.396986014947116, 52.824888504891014],
            [-1.397800597260907, 52.823504649326431],
            [-1.399319699763632, 52.821820506179492],
            [-1.402325869759872, 52.820113272358704],
            [-1.403574121021726, 52.818465467141614],
            [-1.405212380183807, 52.818675949223241],
            [-1.405797395199631, 52.817342128651276],
            [-1.40461192983227, 52.817226518160325],
            [-1.404140866344744, 52.816626348783636],
            [-1.408760333484817, 52.812326351454594],
            [-1.410171531043667, 52.810113842268727],
            [-1.411783737691481, 52.809155459575365],
            [-1.41403870330726, 52.807057652537125],
            [-1.417072698800067, 52.803038059812316],
            [-1.41771196020428, 52.801597468391904],
            [-1.423970111025114, 52.801242340852049],
            [-1.424980868661683, 52.801304773520414],
            [-1.425997342120336, 52.800595019570125],
            [-1.430104517186174, 52.802063026394684],
            [-1.433551489250503, 52.802076823845539],
            [-1.436234219676616, 52.802576827208604],
            [-1.441386547658611, 52.802049218465385],
            [-1.443568714995285, 52.80272649142357],
            [-1.443707960370718, 52.80227586702442],
            [-1.441425567424403, 52.801550480305337],
            [-1.441000726108035, 52.801020792516979],
            [-1.44110599759822, 52.799982092313385],
            [-1.441696399110365, 52.799627983444289],
            [-1.441009627568425, 52.798706016606076],
            [-1.44197985449276, 52.797064587160662],
            [-1.442355370421619, 52.797158045226631],
            [-1.443121841137599, 52.796600692367115],
            [-1.441897718810686, 52.793515110854464],
            [-1.442509324083247, 52.792886914812712],
            [-1.443397318102501, 52.79291085824466],
            [-1.443793126553485, 52.792456940784703],
            [-1.445170656812035, 52.792351016303378],
            [-1.446818783420991, 52.791487615250091],
            [-1.448053162353979, 52.790268977442771],
            [-1.449775695213368, 52.789609046044227],
            [-1.450181045997702, 52.789684638631641],
            [-1.449967037518964, 52.789840066308457],
            [-1.452190000756562, 52.789946532852554],
            [-1.454880274620383, 52.788969161357635],
            [-1.457546899475844, 52.78857234870074],
            [-1.462786208075646, 52.786845897873974],
            [-1.461520593355861, 52.785830632624283],
            [-1.460883788556742, 52.786008433465348],
            [-1.458848417704887, 52.784419700803952],
            [-1.457995428322884, 52.7846944871836],
            [-1.457655306296116, 52.784383691640414],
            [-1.458850512430066, 52.783652897430784],
            [-1.458300744256775, 52.782792782131715],
            [-1.46105981187846, 52.78172029456551],
            [-1.461218209210422, 52.781379408618733],
            [-1.45952639672614, 52.780317237707713],
            [-1.459871036062783, 52.780141711025223],
            [-1.459377296317198, 52.779787070987602],
            [-1.457100116498295, 52.779073691455423],
            [-1.45950587626305, 52.777065602956824],
            [-1.45876317515673, 52.776826691256645],
            [-1.457103200536991, 52.77739534538177],
            [-1.455890083203211, 52.777076952741993],
            [-1.460422202051062, 52.776211263647504],
            [-1.460428104285185, 52.774654289526154],
            [-1.462163031803876, 52.771358478942361],
            [-1.463557435045312, 52.771027677994745],
            [-1.466460128630161, 52.767954623903456],
            [-1.468349554543003, 52.767368887553602],
            [-1.468764371329411, 52.766773833852575],
            [-1.471838844192082, 52.766231096142626],
            [-1.472555440290138, 52.766536333825321],
            [-1.473352282595614, 52.765472803798836],
            [-1.477147953445634, 52.765953448377431],
            [-1.478596888676455, 52.763637802520627],
            [-1.479934168857351, 52.763841440998149],
            [-1.485693953456751, 52.762766207694085],
            [-1.485911704797213, 52.763026052112181],
            [-1.487800493812333, 52.76232763333725],
            [-1.488259412748056, 52.76249502182003],
            [-1.49248185097733, 52.759399145743515],
            [-1.492920576106915, 52.759645538647526],
            [-1.494071203368682, 52.759071516916336],
            [-1.495764556801509, 52.759873405433233],
            [-1.496460171597305, 52.760333031298202],
            [-1.498769782281065, 52.764260482440392],
            [-1.502128955357956, 52.766645180234171],
            [-1.503950235842142, 52.767444789703362],
            [-1.51179125760143, 52.767382007259833],
            [-1.517697704995723, 52.766953974978939],
            [-1.522020910995171, 52.765873830774758],
            [-1.525355020488812, 52.765510544651974],
            [-1.525010195419562, 52.763966549126181],
            [-1.52693558243595, 52.762297676206224],
            [-1.529591006182153, 52.761076652856971],
            [-1.532945012347664, 52.762554304230832],
            [-1.534787150960335, 52.761988900217148],
            [-1.537327096111104, 52.760455312030452],
            [-1.53919820637013, 52.760079633199844],
            [-1.54185623153484, 52.760542998092703],
            [-1.544753885701853, 52.763044266492976],
            [-1.547448811654654, 52.764117141780247],
            [-1.552793650692032, 52.763315748451291],
            [-1.552561076141137, 52.763017316343351],
            [-1.553237755096226, 52.762783438563368],
            [-1.552704047279364, 52.7620802399666],
            [-1.554849160620672, 52.762003801347802],
            [-1.554432424562888, 52.758402801159917],
            [-1.557635472941627, 52.75714365098802],
            [-1.558148331355052, 52.75551484648561],
            [-1.560394867064255, 52.752099045425403],
            [-1.562499204649443, 52.750569596642748],
            [-1.560483127805849, 52.748626683971516],
            [-1.560764053700326, 52.748091942677583],
            [-1.557454385359838, 52.744885635841698],
            [-1.556738819884932, 52.740511309507809],
            [-1.556181737426602, 52.739797248712321],
            [-1.555611894964479, 52.73961172784896],
            [-1.555053399791256, 52.739912585925502],
            [-1.554515549497417, 52.739785612346495],
            [-1.55470787987622, 52.73937101307336],
            [-1.55331472695679, 52.738296910129421],
            [-1.550921473760775, 52.735391416595647],
            [-1.545340222321594, 52.732904305636865],
            [-1.545142335340196, 52.731996491906258],
            [-1.544643719647637, 52.73166915440757],
            [-1.545232537102067, 52.731012471085819],
            [-1.544751432671216, 52.730284262669301],
            [-1.54535621390968, 52.729799342194674],
            [-1.545323769146379, 52.728219735959073],
            [-1.545766441884342, 52.72765688100705],
            [-1.546807973624374, 52.727602430532912],
            [-1.54666462781102, 52.727289043024697],
            [-1.547774112171676, 52.727249227105688],
            [-1.547541797539066, 52.726804252595628],
            [-1.548503341843799, 52.726750381211765],
            [-1.548667390382965, 52.726350066660324],
            [-1.550247593906611, 52.725601834285051],
            [-1.549919703759556, 52.724226971469555],
            [-1.550442152506378, 52.723095355976376],
            [-1.549964978707225, 52.722128956608692],
            [-1.550181261667811, 52.721253284666247],
            [-1.549774158157314, 52.720961374573562],
            [-1.550086345094957, 52.720261364942729],
            [-1.552392287857307, 52.719314484718552],
            [-1.552558102262827, 52.718880010316383],
            [-1.554032372812084, 52.718324605399332],
            [-1.5547109250059, 52.717546850785212],
            [-1.555159627002326, 52.717387618800025],
            [-1.555579270237312, 52.71761123608016],
            [-1.556529116708356, 52.716645703842794],
            [-1.557326260475045, 52.716649579470243],
            [-1.557543248458465, 52.716345639084821],
            [-1.558463359595742, 52.716559424800728],
            [-1.558732741090353, 52.71553650376871],
            [-1.560458179859784, 52.714273566833917],
            [-1.560558593542564, 52.7137084885128],
            [-1.562234273738736, 52.713115970734158],
            [-1.563875926918593, 52.711180247156143],
            [-1.564339958018963, 52.710958109044533],
            [-1.565017211237083, 52.71117454810765],
            [-1.565930464575114, 52.710430850841007],
            [-1.566543480434365, 52.710407920861975],
            [-1.566098153314419, 52.710238186212742],
            [-1.566936272287483, 52.710053364105505],
            [-1.568854231639882, 52.709785265103207],
            [-1.569291037501176, 52.710370281378374],
            [-1.571654082331084, 52.710600880860419],
            [-1.571716357371749, 52.710892371029743],
            [-1.57273522741531, 52.710992233890671],
            [-1.573005534976635, 52.711353692764362],
            [-1.574206494536518, 52.711452400176015],
            [-1.57461245362935, 52.712022003412244],
            [-1.577125572605771, 52.711746014280109],
            [-1.578014131597011, 52.711394085866445],
            [-1.579287072186011, 52.709549431198887],
            [-1.580340661478756, 52.710040402516867],
            [-1.580517796692732, 52.709627504065672],
            [-1.581270480439677, 52.70939193648865],
            [-1.580831244492046, 52.708581315121464],
            [-1.581530688863179, 52.708493887371823],
            [-1.582308295344981, 52.70921130627238],
            [-1.582316745793061, 52.708635997065869],
            [-1.582830952285618, 52.709037846488997],
            [-1.583412166384804, 52.708309927293513],
            [-1.583982532085151, 52.708409915948856],
            [-1.583829261177679, 52.70787359399371],
            [-1.584734087263625, 52.707509086703737],
            [-1.58482280039326, 52.706894503528865],
            [-1.585777839731661, 52.707476776525304],
            [-1.588938129440967, 52.70633619050502],
            [-1.589560163562068, 52.706448916920927],
            [-1.589384172313495, 52.70675036098153],
            [-1.590425684796668, 52.706164238337294],
            [-1.591602754163089, 52.705957038446577],
            [-1.592170790882868, 52.705034853893721],
            [-1.591082142734854, 52.70465174257351],
            [-1.591214032898224, 52.704157764918179],
            [-1.591823129867332, 52.704385502809004],
            [-1.591560755466963, 52.703637557810687],
            [-1.592992517808687, 52.703725183064293],
            [-1.594102021932974, 52.703294782624312],
            [-1.594447719315015, 52.703519807717811],
            [-1.59396206306007, 52.703705131917935],
            [-1.594453956389311, 52.703964817970366],
            [-1.595241843117608, 52.703424532053354],
            [-1.594716220566647, 52.703291488465446],
            [-1.594961166947393, 52.702729571189202],
            [-1.595768227711818, 52.703154838432049],
            [-1.596296048576906, 52.703049658429705],
            [-1.596567997524366, 52.702124646768262],
            [-1.596130058516753, 52.701471405169471],
            [-1.597547212570677, 52.700404651067529],
            [-1.598574072278677, 52.70041981645776],
            [-1.598967266556492, 52.699821534541535],
            [-1.599683478526911, 52.699823054338417],
            [-1.599257814438984, 52.699119523275257],
            [-1.598546976898326, 52.699340064199085],
            [-1.598322952300436, 52.698757673557601],
            [-1.598763627695625, 52.698467000049305],
            [-1.599649770644696, 52.69833065355396],
            [-1.601008879395488, 52.697442556581329],
            [-1.604009027648208, 52.696705565606251],
            [-1.605212904504201, 52.696936117752969],
            [-1.605500741387712, 52.696521751860303],
            [-1.606768480496521, 52.696726430471308],
            [-1.606642533538357, 52.697569245118679],
            [-1.607828334600665, 52.697343933866669],
            [-1.607561968397001, 52.698336412793687],
            [-1.608003837641058, 52.698566211419035],
            [-1.609863888609808, 52.698899566746356],
            [-1.610141024361665, 52.698520214326962],
            [-1.610688200239917, 52.69855796978802],
            [-1.61142079665438, 52.698878606047295],
            [-1.61142223630121, 52.699216622642894],
            [-1.612117174768327, 52.69911281844287],
            [-1.612553921386325, 52.699925114324039],
            [-1.615381032379343, 52.700690344422547],
            [-1.616612203753357, 52.70052712120259],
            [-1.618052745783005, 52.699602235005486],
            [-1.618409264164299, 52.699784973608288],
            [-1.620914484009869, 52.699453194748386],
            [-1.622377831191387, 52.698614629748576],
            [-1.623233777449526, 52.697508024928489],
            [-1.625257439068077, 52.697405658872896],
            [-1.626298238416287, 52.698901227721095],
            [-1.627309138173957, 52.699571441168438],
            [-1.626295977067946, 52.700206521089193],
            [-1.629457638719896, 52.700290152419385],
            [-1.630315092432696, 52.700039318429447],
            [-1.631901821612703, 52.700347203591377],
            [-1.632930399756717, 52.700864599262616],
            [-1.633289250944327, 52.701484198084366],
            [-1.634155029437733, 52.701476982103124],
            [-1.634282790944521, 52.701772237061384],
            [-1.633708283642807, 52.701987115871766],
            [-1.634442119966902, 52.702186252726264],
            [-1.634916297101307, 52.701935103036973],
            [-1.634883087103203, 52.702368302776122],
            [-1.635346959563826, 52.702465918712441],
            [-1.636326122243964, 52.702351159269739],
            [-1.637719624074956, 52.701344983683434],
            [-1.638214266700879, 52.700225481366282],
            [-1.638004396310311, 52.699835588589636],
            [-1.637527962396568, 52.700006735717636],
            [-1.637659588856704, 52.699658338584591],
            [-1.640947861688198, 52.699638668932948],
            [-1.641620302207246, 52.700287059039049],
            [-1.642365209677529, 52.700399879536242],
            [-1.644689749377806, 52.699337991746916],
            [-1.648527478289725, 52.698336286326622],
            [-1.648957951374894, 52.698722318505922],
            [-1.648638484204126, 52.699255359176576],
            [-1.649385801924396, 52.699621651325906],
            [-1.652616928024239, 52.699529571842618],
            [-1.653937354113466, 52.699285315505676],
            [-1.653938387135169, 52.698780099267267],
            [-1.655048201717562, 52.698781532981727],
            [-1.655596270258334, 52.699463642764151],
            [-1.654427742968116, 52.699961868607176],
            [-1.655061607694767, 52.701963913036856],
            [-1.654157776027269, 52.703040940449085],
            [-1.654308017147183, 52.708191552331634],
            [-1.656349167940526, 52.708554370163661],
            [-1.655916972364777, 52.710438247360003],
            [-1.657739563061906, 52.710562181387786],
            [-1.658215138793336, 52.712590717303868],
            [-1.659297546005246, 52.714066333381297],
            [-1.657557369973339, 52.714930623063438],
            [-1.657851947724326, 52.715309936610083],
            [-1.655684281392514, 52.715933841038655],
            [-1.656220460885181, 52.717780968298754],
            [-1.655934945594805, 52.719826181994527],
            [-1.656614340509406, 52.721731254932379],
            [-1.662620100528088, 52.72298814938798],
            [-1.66302974017259, 52.72362757668138],
            [-1.664559526892999, 52.723586063079701],
            [-1.664846317820301, 52.72403096081365],
            [-1.666290736556213, 52.723924458466527],
            [-1.666382231335824, 52.72357591820753],
            [-1.669176212534148, 52.723565759714646],
            [-1.671406835749377, 52.724046608467255],
            [-1.671402227216932, 52.724265942531581],
            [-1.675601383228255, 52.724852829693994],
            [-1.675440037457333, 52.726842690553255],
            [-1.677951033006799, 52.727486894083491],
            [-1.682096202764587, 52.726999144871705],
            [-1.68782911420678, 52.72726876704391],
            [-1.694027415016879, 52.726850708110057],
            [-1.696958451501552, 52.727177358586765],
            [-1.701332939453933, 52.728417325888913],
            [-1.703247841403653, 52.729469417983289],
            [-1.704516639913786, 52.731041269527083],
            [-1.704164699705445, 52.732061612116546],
            [-1.702358985347552, 52.732571303686115],
            [-1.697892519013439, 52.735364776771007],
            [-1.697984151907708, 52.736035634113293],
            [-1.699769881896055, 52.738034056606281],
            [-1.699159131127715, 52.738973721100237],
            [-1.697108654084678, 52.740343016136421],
            [-1.697804922638042, 52.742255078810203],
            [-1.697625751511699, 52.743745995448599],
            [-1.696363226116154, 52.744239897337906],
            [-1.69317707938367, 52.7437975085356],
            [-1.692241700232194, 52.744105228208632],
            [-1.692543317509018, 52.744817985101506],
            [-1.695545549370809, 52.747004797709444],
            [-1.696058580437305, 52.748003056950594],
            [-1.695660073195957, 52.749067301451156],
            [-1.693865854879663, 52.750147730883221],
            [-1.693691100599368, 52.750772954830957],
            [-1.696581191443353, 52.751893285544426],
            [-1.696770163013883, 52.752843966417643],
            [-1.692757471564793, 52.756291039998594],
            [-1.690977458464653, 52.756986708021394],
            [-1.687917768607458, 52.757387730604499],
            [-1.685974012017344, 52.758995334225787],
            [-1.683933978074341, 52.761601390119822],
            [-1.683172301197108, 52.764255775764269],
            [-1.683557979928941, 52.764819550669827],
            [-1.6850125435251, 52.765336721977306],
            [-1.685185709515202, 52.766397050013573],
            [-1.683014790306602, 52.768194579337013],
            [-1.682072575560388, 52.770174256763788],
            [-1.678651995437256, 52.771945879232092],
            [-1.676353357208284, 52.772301915293156],
            [-1.672025902550392, 52.772400613175854],
            [-1.670184962761294, 52.773378072015042],
            [-1.67005301582692, 52.773577273451224],
            [-1.669739454234763, 52.777798791498554],
            [-1.670745215522348, 52.781053101019282],
            [-1.66969785916288, 52.782317717217751],
            [-1.665609432897546, 52.785145170439343],
            [-1.664014527126807, 52.78546878682009],
            [-1.662274958204996, 52.785131239797693],
            [-1.65876205359648, 52.783475213686565],
            [-1.656831484572183, 52.781045171796627],
            [-1.653245501434604, 52.779698009680736],
            [-1.649935444368911, 52.779464458160795],
            [-1.648490014178516, 52.779831456448548],
            [-1.644329239844535, 52.782481769834185],
            [-1.64193402669534, 52.784568235723761],
            [-1.638248126764871, 52.786710957943299],
            [-1.626963050625518, 52.779754992733139],
            [-1.623758506634035, 52.782431858448284],
            [-1.62288916594757, 52.782150424749894],
            [-1.619294395107971, 52.78515045204901],
            [-1.617747404173767, 52.783857280266716],
            [-1.615016654706725, 52.782478450938456],
            [-1.615414937018381, 52.782034758845896],
            [-1.612467533871488, 52.78120083412287],
            [-1.61172410226173, 52.781609227991567],
            [-1.611134540784548, 52.781386155686306],
            [-1.610308546828152, 52.782239253102155],
            [-1.611270562556269, 52.782760204557526],
            [-1.609421570746248, 52.784436081775276],
            [-1.613725543310907, 52.78613749849444],
            [-1.612396766103162, 52.787501377207391],
            [-1.614350325785204, 52.788256566605668],
            [-1.611729429784655, 52.789844568436024],
            [-1.606334364105444, 52.790714073412616],
            [-1.604136306247069, 52.791250633458191],
            [-1.602663184558739, 52.791967573542991],
            [-1.601479392201706, 52.792824804490714],
            [-1.601720717325047, 52.79319328621213],
            [-1.600859014447788, 52.794176545230044],
            [-1.603554039945799, 52.795437815093777],
            [-1.597854752765601, 52.798326765831014],
            [-1.596792694758389, 52.798019318470381],
            [-1.595708162658315, 52.797097799965123],
            [-1.593589615244089, 52.797966149150788],
            [-1.593517844437667, 52.796914128097789],
            [-1.592861040373553, 52.797368546442343],
            [-1.592704341758403, 52.798266962423249],
            [-1.588950844195528, 52.799804724024078],
            [-1.589059381727808, 52.800406499259502],
            [-1.590029004167373, 52.800764936948781],
            [-1.589297990181972, 52.801371901199836],
            [-1.589732516737806, 52.801694329290257],
            [-1.589313408544097, 52.802097409445167],
            [-1.588505890771595, 52.8016909843682],
            [-1.587981156519127, 52.802126056100072],
            [-1.588345282753397, 52.80236374340879],
            [-1.590911812503552, 52.802474193653929],
            [-1.589877079501138, 52.806354099185775],
            [-1.590581043088881, 52.806898596140094],
            [-1.59183122346843, 52.809473002397823],
            [-1.593443094143802, 52.809073103786368],
            [-1.596658198359992, 52.810961975256646],
            [-1.598911584539842, 52.811464029377738],
            [-1.60079748858348, 52.812732510084132],
            [-1.604963977448903, 52.814649518930082],
            [-1.607701159673453, 52.814841976716316],
            [-1.610344293138112, 52.815599502488666],
            [-1.610328519743058, 52.817032377025711],
            [-1.607604746071041, 52.819455069340584],
            [-1.607117261711716, 52.822010969031922],
            [-1.605517290383533, 52.82318868349212],
            [-1.604418760874033, 52.824865165943855],
            [-1.602871334256797, 52.82628303869582],
            [-1.600735959210244, 52.827390577055517],
            [-1.597599610721824, 52.828394907146638],
            [-1.593694723696577, 52.828716923165807],
            [-1.590366816760388, 52.828598519086903],
            [-1.588627220102151, 52.828927815453646],
            [-1.585787100129739, 52.830736519264519],
            [-1.58535643433786, 52.831696891666631],
            [-1.586466531271527, 52.831399613524304],
            [-1.587980845292757, 52.831519041823675],
            [-1.589608170481963, 52.830987103725917],
            [-1.591899888741273, 52.832252627290117],
            [-1.591406606900691, 52.833413271494308],
            [-1.591953648169766, 52.834595470270962],
            [-1.591110139186976, 52.835426793475051],
            [-1.591290996395693, 52.836243660233293],
            [-1.592440205111485, 52.836375258860059],
            [-1.593217800348163, 52.835448412227592],
            [-1.594183831869263, 52.835164052877595],
            [-1.597527591620726, 52.837317619545161],
            [-1.59890869856847, 52.837742098656996],
            [-1.599397556349003, 52.837853419625546],
            [-1.601000606638985, 52.837065044016207],
            [-1.601872106880523, 52.837214496130443],
            [-1.602985461601523, 52.839820673021123],
            [-1.602990836303744, 52.840859873200294],
            [-1.604506482045343, 52.842181884611072],
            [-1.607062855110688, 52.843353602977082],
            [-1.608362089001215, 52.842842796696786],
            [-1.609204497590849, 52.842940858824221],
            [-1.612675923599578, 52.844579318152824],
            [-1.614726043818266, 52.845114563657582],
            [-1.617582939745025, 52.844747135023745],
            [-1.619086422887758, 52.845997902823562],
            [-1.620716414462981, 52.847394684188778],
            [-1.6226985284436, 52.84792238280459],
            [-1.622665674167883, 52.849486442296339],
            [-1.625496460857545, 52.849763283540867],
            [-1.628239123738817, 52.851843962631179],
            [-1.626161816836706, 52.852689644361483],
            [-1.62657082953935, 52.854346785083742],
            [-1.627296162882166, 52.854447047158587],
            [-1.629679533296649, 52.853765907739422],
            [-1.630736363580416, 52.852775863658906],
            [-1.635155619240098, 52.852965707475022],
            [-1.636929183573196, 52.853265993181601],
            [-1.639905232065549, 52.855411837323842],
            [-1.641914869408925, 52.855735236971832],
            [-1.642636373449219, 52.856309136699252],
            [-1.645967150708878, 52.855804012071424],
            [-1.654060641513012, 52.856965909816353],
            [-1.658685405871512, 52.856772525672625],
            [-1.662786829669352, 52.858182089709231],
            [-1.664791995635437, 52.858353167758679],
            [-1.668806313393523, 52.860129944577885],
            [-1.670280405632987, 52.86121097544909],
            [-1.672322953912595, 52.861371242450424],
            [-1.674621123502488, 52.861115964498346],
            [-1.679453009496297, 52.861484176482747],
            [-1.682739572947022, 52.862761400962],
            [-1.68496786629597, 52.862311565008376],
            [-1.686282866409853, 52.862449884394572],
            [-1.687369170800484, 52.862789849591231],
            [-1.687316158899222, 52.863346154555174],
            [-1.687930016226999, 52.863911403771425],
            [-1.68998960253842, 52.864417503502295],
            [-1.69175138691436, 52.864192855731993],
            [-1.694369588799439, 52.862788280082263],
            [-1.697540208021543, 52.862871901695236],
            [-1.698443407701185, 52.862641370989607],
            [-1.699228480692225, 52.862093208470895],
            [-1.698882791264328, 52.860748416577259],
            [-1.70061290106653, 52.860565807389101],
            [-1.703470461356627, 52.861232793448664],
            [-1.703567991121727, 52.862833151152145],
            [-1.702915698331331, 52.862866581265642],
            [-1.702253989743499, 52.862321965385824],
            [-1.701524011975038, 52.862205070127146],
            [-1.7000462629387, 52.863262100039535],
            [-1.70062442967078, 52.864289248781439],
            [-1.703465132731819, 52.866376517249236],
            [-1.705906423386083, 52.866716089205234],
            [-1.708067521099566, 52.866091259534841],
            [-1.710679567967354, 52.866444636711456],
            [-1.712064630458676, 52.865903242263364],
            [-1.714477863279056, 52.865997159460399],
            [-1.716533151670469, 52.865400687699719],
            [-1.717669380618943, 52.864503552945322],
            [-1.717324175796101, 52.863450972613123],
            [-1.715496827732874, 52.863505044078927],
            [-1.714418743840251, 52.862781508712253],
            [-1.71480037286558, 52.86234374208906],
            [-1.71626388287997, 52.862044301651288],
            [-1.716973243507277, 52.860768598362895],
            [-1.717875697098074, 52.860630509037669],
            [-1.71835083178891, 52.860883339181548],
            [-1.717587198253799, 52.861601578644787],
            [-1.717752674331716, 52.86196604255796],
            [-1.719859530138024, 52.861859558017237],
            [-1.72129524009989, 52.863110662038089],
            [-1.722173722265837, 52.863243064842436],
            [-1.722298633192901, 52.862988057729019],
            [-1.721202976475859, 52.862441634166167],
            [-1.721070364939008, 52.862043092512536],
            [-1.722348201351285, 52.861504023153429],
            [-1.7228950843728, 52.860290830765628],
            [-1.725326058305847, 52.859645640129493],
            [-1.727517874270984, 52.860419278847544],
            [-1.728086721661334, 52.861371660350066],
            [-1.727815162625222, 52.861807024867318],
            [-1.72647898885849, 52.861474945668206],
            [-1.725840652417519, 52.861656858934438],
            [-1.725728235035146, 52.862290352463447],
            [-1.726427584939263, 52.863037185396898],
            [-1.727233170670411, 52.863190058593638],
            [-1.729784994870802, 52.862713155938607],
            [-1.731219298730353, 52.862799110176447],
            [-1.734829769669682, 52.863838302900824],
            [-1.735542800676687, 52.8650525608953],
            [-1.739168793045474, 52.864789092314552],
            [-1.739660465196053, 52.865285485737388],
            [-1.739424741639745, 52.867194316377073],
            [-1.739788928418763, 52.867650876215954],
            [-1.74040394594824, 52.867647726092969],
            [-1.743176950174817, 52.866210052724917],
            [-1.744566151220602, 52.865905608506537],
            [-1.746788170683258, 52.865940925960928],
            [-1.749500543678609, 52.866724254460905],
            [-1.749749138784581, 52.867408870579588],
            [-1.749185936447971, 52.867185645800959],
            [-1.747400533326556, 52.868183283682768],
            [-1.746431655009625, 52.868237854284935],
            [-1.746007212029065, 52.868675630400297],
            [-1.744735422082895, 52.868704369344918],
            [-1.744501843858406, 52.870282403980191],
            [-1.744908744314233, 52.871829451922288],
            [-1.742851978067996, 52.871684786674635],
            [-1.742030672929826, 52.871892460048457],
            [-1.741593602992074, 52.872441661147114],
            [-1.742647085727439, 52.87467870645213],
            [-1.738958933235511, 52.876006487565718],
            [-1.738412007413682, 52.876773877268157],
            [-1.738729246350947, 52.877142240356605],
            [-1.737780379997863, 52.879758756294073],
            [-1.737306274239858, 52.884943676712894],
            [-1.737880746406005, 52.888257524596177],
            [-1.73766722046112, 52.890641031484222],
            [-1.73656500583658, 52.895202489250863],
            [-1.742625514356996, 52.902692197245187],
            [-1.743152118869742, 52.902639399724364],
            [-1.742991089909242, 52.904981672612585],
            [-1.743434187564636, 52.905983142071918],
            [-1.746336439452336, 52.907004263087522],
            [-1.748225615892235, 52.908136438144012],
            [-1.746967773316219, 52.910424249046613],
            [-1.74895887181491, 52.912475337106777],
            [-1.739782903978301, 52.91337881874869],
            [-1.736137264564025, 52.912536577459818],
            [-1.7311065546423, 52.912305071477611],
            [-1.72998819917529, 52.911364951030365],
            [-1.729418149115704, 52.911449952977691],
            [-1.729027306665313, 52.91045754031925],
            [-1.725899904633257, 52.911213567420134],
            [-1.724397903995518, 52.910766023348557],
            [-1.722697933257383, 52.910821401200643],
            [-1.722138394276065, 52.909958917427154],
            [-1.720895980378315, 52.910316480214398],
            [-1.718660033614258, 52.910247387361174],
            [-1.718991130622175, 52.909404072010169],
            [-1.714471260522984, 52.909568597720451],
            [-1.713553015263913, 52.911721132067278],
            [-1.712066934197429, 52.9127027737131],
            [-1.711570787732483, 52.91351960022979],
            [-1.710981902617489, 52.916203281911791],
            [-1.711254548909297, 52.918366774881882],
            [-1.710292009135288, 52.920418493289183],
            [-1.709712535830643, 52.920554616873503],
            [-1.708231958221904, 52.921987486543941],
            [-1.705612470062774, 52.922681299125763],
            [-1.705118978162224, 52.922414891807897],
            [-1.701758984599707, 52.922848787832251],
            [-1.699750164060516, 52.922109306285904],
            [-1.695015763702172, 52.92085313785126],
            [-1.691622423749684, 52.920539649315145],
            [-1.690654090119507, 52.91992586059223],
            [-1.689979845126303, 52.919996019407293],
            [-1.683761881491114, 52.918303138217041],
            [-1.683225201209387, 52.918477900625206],
            [-1.682397136110942, 52.920263668633552],
            [-1.680258155172868, 52.921103832143721],
            [-1.678019563715208, 52.921525683997999],
            [-1.676896063683914, 52.920799895235227],
            [-1.674843060970894, 52.918084017680179],
            [-1.674223190332193, 52.917845002861768],
            [-1.674309120316299, 52.916301770275098],
            [-1.670566995179786, 52.916747221303055],
            [-1.668652073819366, 52.911211661309082],
            [-1.66947478705965, 52.909399003002456],
            [-1.668682261578017, 52.908812490016309],
            [-1.668019899495024, 52.907150310456942],
            [-1.665280087952837, 52.90631740703374],
            [-1.664445516486387, 52.90560669394528],
            [-1.662963959032873, 52.902437352424293],
            [-1.662323806776352, 52.902549700844304],
            [-1.661898142626186, 52.902227571155272],
            [-1.66154079079137, 52.902679617920697],
            [-1.661347902691347, 52.902057904198934],
            [-1.660969782294595, 52.90250359755845],
            [-1.658382726096046, 52.902718229427698],
            [-1.657397477621614, 52.904171669928701],
            [-1.65410382065195, 52.905728078821795],
            [-1.654933954861901, 52.906227602117411],
            [-1.652019024363826, 52.908363965595761],
            [-1.650892188808144, 52.908704954407568],
            [-1.652696789826172, 52.909140829078744],
            [-1.653409493533292, 52.912065344806742],
            [-1.655513462885942, 52.914525548944233],
            [-1.656336613059792, 52.918375366412512],
            [-1.654997266501097, 52.91829238193472],
            [-1.653895246841096, 52.920529320918824],
            [-1.653711607824846, 52.924348351339404],
            [-1.652665086872182, 52.924454070102904],
            [-1.652370699296164, 52.926668178991399],
            [-1.65325409744125, 52.92669233242745],
            [-1.653314441179944, 52.928263841405396],
            [-1.651073809038507, 52.930527090944729],
            [-1.650366189353345, 52.934528852505352],
            [-1.649737128531076, 52.934678920835594],
            [-1.649940797594519, 52.935805880856599],
            [-1.649036891155701, 52.936823503244312],
            [-1.648995649006687, 52.937139805111002],
            [-1.649612367022182, 52.937233314545011],
            [-1.648658344676449, 52.939658511093128],
            [-1.649874588235951, 52.941801551185286],
            [-1.650239341859649, 52.944548854440988],
            [-1.6495170883168, 52.944611451301114],
            [-1.649224515912285, 52.945090617131683],
            [-1.647228560329487, 52.945314839965008],
            [-1.64726914442455, 52.94563138346733],
            [-1.646039646109129, 52.945846170782147],
            [-1.645893856527906, 52.946386892283435],
            [-1.645034507575477, 52.946467032288162],
            [-1.641381982634187, 52.948144264904741],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000040",
        LAD13CDO: "18UB",
        LAD13NM: "East Devon",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-3.052415358224968, 50.908272066628967],
            [-3.051296967872136, 50.906358609271564],
            [-3.050374601895669, 50.906009903827702],
            [-3.050515715474168, 50.904570702381889],
            [-3.049891813672327, 50.903958518263039],
            [-3.050166815701228, 50.902970444883614],
            [-3.051346775000481, 50.901878000449585],
            [-3.051775654733074, 50.900904723421498],
            [-3.052325654544586, 50.900826025530641],
            [-3.052266643891386, 50.900037897781587],
            [-3.053263386678578, 50.898645829258641],
            [-3.052966169877173, 50.897699780891763],
            [-3.054658548134599, 50.896575699002462],
            [-3.054659377870897, 50.896046021020034],
            [-3.053793674930868, 50.895490897453641],
            [-3.055008823738259, 50.894583345893878],
            [-3.055314119866638, 50.893181319710223],
            [-3.054855510228776, 50.892014614540365],
            [-3.055499379899945, 50.891753399899031],
            [-3.05544939426179, 50.890359980983696],
            [-3.053728103032502, 50.888617459276183],
            [-3.053170735914672, 50.886598229333018],
            [-3.052208364801139, 50.885782277875727],
            [-3.052251497743506, 50.88485743706012],
            [-3.05328173026739, 50.883451572802599],
            [-3.051808864992485, 50.882688785074343],
            [-3.051933205900942, 50.880193980707467],
            [-3.052447077896889, 50.879027486385802],
            [-3.053662185193566, 50.878576776395668],
            [-3.053450640738797, 50.877768440973334],
            [-3.054246245627176, 50.876744177380097],
            [-3.053886279710884, 50.876035204167536],
            [-3.054547272678054, 50.875786428611868],
            [-3.054705617655141, 50.875245433179465],
            [-3.054294538723875, 50.874790518308842],
            [-3.054778353182447, 50.874626974506718],
            [-3.054600687820473, 50.874123188310271],
            [-3.053768521749688, 50.873713443072575],
            [-3.054054899812654, 50.87305168857695],
            [-3.051571402967773, 50.872773738358632],
            [-3.046753592833873, 50.871387210809871],
            [-3.046099435925226, 50.87187688660066],
            [-3.045319060149974, 50.869584436316437],
            [-3.043725775344323, 50.86811309781968],
            [-3.042018726725537, 50.868863961083314],
            [-3.039273945624902, 50.8687094848923],
            [-3.038435444838798, 50.86832396746216],
            [-3.036977417138143, 50.866788384281875],
            [-3.036644969566899, 50.865190630133526],
            [-3.034588170577897, 50.864008354265835],
            [-3.034719931995495, 50.862450541587364],
            [-3.032624805822474, 50.861648064896535],
            [-3.033301991352316, 50.861171748980979],
            [-3.034585089007842, 50.859055175873003],
            [-3.036358991351775, 50.858254365456936],
            [-3.035726568022322, 50.856259093164063],
            [-3.037081986868991, 50.854403537427707],
            [-3.036077497976444, 50.852862109924693],
            [-3.036799308353599, 50.851074231214277],
            [-3.03476499295505, 50.850943911214614],
            [-3.031854424524455, 50.851725081526482],
            [-3.021057913182521, 50.855499903504274],
            [-3.018420206794433, 50.855786445071359],
            [-3.014084029706009, 50.855125478075166],
            [-3.012900027940943, 50.855821010281936],
            [-3.012564414670134, 50.856986685169893],
            [-3.011434752720335, 50.857241985440034],
            [-3.006254944430031, 50.853890215410821],
            [-3.003722400373797, 50.853384156430899],
            [-3.00164801080596, 50.851781491770552],
            [-2.998503044859901, 50.850994606841986],
            [-2.996310692158427, 50.854641919926372],
            [-2.994164687427685, 50.855206984206269],
            [-2.993476395629843, 50.854907993415083],
            [-2.990643413244225, 50.855639810354717],
            [-2.987922374659004, 50.855891297827192],
            [-2.98412207850633, 50.85495309939575],
            [-2.979812718376768, 50.85479872330734],
            [-2.976603608648819, 50.855557637276917],
            [-2.973584021542439, 50.855666505969708],
            [-2.973820039348465, 50.853901962239448],
            [-2.974646008736544, 50.852361805123486],
            [-2.972108498584803, 50.851937835656607],
            [-2.970966672340045, 50.851406881050295],
            [-2.968016895501523, 50.851307293472409],
            [-2.970080785459667, 50.849516784594833],
            [-2.970881821515927, 50.847799703592337],
            [-2.972481761325062, 50.846047182946869],
            [-2.972258760212127, 50.84433952208888],
            [-2.970898107710469, 50.842769030808547],
            [-2.96924932814278, 50.8416829286992],
            [-2.968476405868751, 50.840503205106934],
            [-2.969821144977818, 50.834189915364206],
            [-2.966320141764282, 50.833682101727547],
            [-2.965603486785906, 50.831847219465985],
            [-2.961659470217776, 50.829809668019706],
            [-2.962596491771211, 50.828837016652599],
            [-2.962923375455839, 50.827747993481161],
            [-2.959049358481111, 50.826124342045702],
            [-2.95750769731458, 50.824818668502708],
            [-2.957651883989201, 50.824302199595778],
            [-2.956188056827173, 50.823326803113922],
            [-2.954315708072596, 50.821182074874599],
            [-2.95550363451382, 50.820192138236173],
            [-2.956831620259342, 50.820500495346913],
            [-2.95816571251246, 50.819996740255455],
            [-2.958778896673653, 50.818536671369564],
            [-2.958383958728038, 50.818175709840133],
            [-2.957623004094685, 50.818311455718778],
            [-2.957363700794951, 50.818057290355114],
            [-2.957718471838167, 50.817773803432083],
            [-2.95808691685547, 50.818019877357379],
            [-2.958619798397175, 50.817769097063938],
            [-2.959447116618645, 50.817893589176215],
            [-2.959178648254698, 50.817261806729668],
            [-2.959610873986168, 50.816810411395664],
            [-2.961220051670882, 50.816575039723709],
            [-2.961593103624335, 50.816146606500588],
            [-2.961340402251332, 50.815730525449943],
            [-2.960833462066088, 50.815514379566316],
            [-2.959983039591444, 50.816407170446276],
            [-2.959052952861903, 50.816046117968085],
            [-2.958428815626788, 50.816345311969265],
            [-2.958318660998752, 50.81581834119612],
            [-2.956607724097847, 50.815866558553914],
            [-2.954722564667345, 50.815376610604801],
            [-2.954108377837543, 50.81553811059544],
            [-2.952727711313894, 50.815074578009941],
            [-2.951747575620513, 50.815459377092857],
            [-2.95091057787976, 50.814994982626146],
            [-2.9498249838011, 50.81501012305997],
            [-2.94944459039959, 50.815431384005471],
            [-2.94903601627397, 50.815163127452216],
            [-2.947955624765906, 50.8154354013786],
            [-2.94683336397078, 50.814620779248202],
            [-2.947207678748783, 50.814319180032896],
            [-2.945669884260737, 50.814082558597846],
            [-2.944808257190187, 50.813378213160391],
            [-2.944116196502202, 50.813408996906304],
            [-2.943197337790857, 50.812398449313342],
            [-2.94271774938498, 50.812406823025078],
            [-2.942583557922811, 50.811945679071584],
            [-2.941500588162477, 50.811735002437288],
            [-2.941628768982998, 50.811121559821615],
            [-2.940734463830905, 50.811116186574822],
            [-2.939780838475696, 50.810488984574029],
            [-2.939006448644748, 50.810446660731643],
            [-2.939037695686521, 50.810093892443469],
            [-2.938396838920236, 50.809844554875482],
            [-2.936731225006683, 50.810090856900388],
            [-2.936265771376276, 50.809740279004821],
            [-2.935518377768301, 50.809840700408181],
            [-2.934456549566269, 50.809191840928655],
            [-2.931587273971276, 50.809148247777422],
            [-2.931272140498922, 50.808646269972911],
            [-2.930723250423953, 50.809022052876706],
            [-2.929773508176146, 50.809081786029395],
            [-2.928951649845144, 50.808433661184907],
            [-2.927547510429295, 50.808409764694794],
            [-2.926590112186318, 50.80786701724265],
            [-2.926246176698022, 50.808273524558849],
            [-2.925271279656137, 50.807993494169111],
            [-2.924894141166519, 50.808300441126079],
            [-2.922717437225573, 50.807603656424192],
            [-2.921910691646487, 50.806923887879684],
            [-2.920166036269202, 50.806685868066147],
            [-2.918551734508892, 50.805640144221726],
            [-2.917610776070438, 50.805928125678513],
            [-2.915302277737709, 50.805302383361223],
            [-2.913587974987607, 50.805305932594706],
            [-2.913292806969035, 50.805015979406697],
            [-2.912081243937402, 50.805255675474392],
            [-2.911291420822903, 50.804633255100995],
            [-2.907344258463092, 50.804682015662777],
            [-2.905428538023962, 50.805276937201938],
            [-2.902604632884869, 50.80462796591614],
            [-2.900652831562281, 50.8047518673825],
            [-2.899805287782661, 50.805179270664027],
            [-2.897184443224616, 50.804532198642733],
            [-2.895715245786618, 50.804958057763635],
            [-2.89500672475909, 50.804642455620318],
            [-2.893915641123781, 50.804952980254633],
            [-2.893598871204809, 50.804714401832328],
            [-2.892799831692976, 50.804786168459039],
            [-2.891556047339456, 50.803785796371194],
            [-2.890254480891729, 50.803475596744065],
            [-2.890411912645114, 50.802575118910624],
            [-2.889875405089575, 50.802197021467627],
            [-2.890197852815302, 50.801616326116608],
            [-2.88968920679706, 50.801135497626838],
            [-2.889805592640094, 50.800688568664825],
            [-2.888751518760987, 50.799730783370229],
            [-2.888810283367933, 50.798415593268423],
            [-2.888300271767107, 50.797486029925068],
            [-2.887224029131078, 50.796775700549077],
            [-2.886640671067374, 50.794817015667846],
            [-2.889297498499999, 50.793883120186557],
            [-2.887758380340695, 50.793450603920675],
            [-2.887812335254256, 50.792928612309233],
            [-2.887035843813598, 50.792532544183494],
            [-2.890575920659639, 50.79052715221404],
            [-2.893741326913297, 50.789868944449545],
            [-2.895634937370307, 50.787548666528302],
            [-2.897452382121901, 50.786149804338365],
            [-2.907337421827564, 50.785062519434746],
            [-2.929647778752805, 50.782442545763381],
            [-2.931915365012452, 50.781842610821755],
            [-2.939160474155865, 50.778422069214692],
            [-2.943540302691968, 50.775880410710123],
            [-2.954327686056903, 50.767720649291448],
            [-2.955939475793699, 50.764981713566385],
            [-2.955700419912784, 50.764175215991521],
            [-2.956032910030777, 50.763652703303975],
            [-2.953053731455086, 50.762181582284896],
            [-2.952585395935351, 50.761392239940314],
            [-2.949607010447475, 50.760928224165788],
            [-2.93941733742365, 50.757123161743259],
            [-2.935057805128981, 50.754276897170271],
            [-2.936506732844926, 50.753003569328513],
            [-2.939677492280316, 50.751850351183116],
            [-2.940566969576617, 50.749903417762319],
            [-2.940580075065488, 50.748647907893492],
            [-2.938243108473842, 50.745976073096948],
            [-2.937857093409967, 50.744107762649151],
            [-2.938879592480457, 50.743358519407352],
            [-2.94062839250486, 50.74271402127453],
            [-2.945649261536317, 50.737830733514279],
            [-2.946579629741384, 50.737304292928613],
            [-2.946612800273195, 50.735857067803721],
            [-2.946302027302268, 50.735273254607996],
            [-2.945839834897646, 50.735191573267535],
            [-2.947013577031619, 50.735001286573137],
            [-2.947772794870513, 50.733791867187243],
            [-2.949595859205129, 50.732358850711734],
            [-2.953061570898178, 50.728508583369297],
            [-2.952597015006407, 50.724605862078754],
            [-2.948727184662453, 50.721274974995154],
            [-2.948779296961149, 50.719997557060417],
            [-2.947804553213794, 50.718306725705894],
            [-2.953453632442093, 50.714251606761096],
            [-2.957789150852308, 50.713061367418099],
            [-2.961938409679456, 50.713230436266009],
            [-2.966261217736597, 50.713021114896371],
            [-2.967172806681691, 50.712649339994982],
            [-2.96873849367052, 50.710960053438185],
            [-2.971074442987169, 50.709403720429862],
            [-2.973240625601339, 50.708684202162502],
            [-2.975709835633806, 50.708306536736288],
            [-2.981146387533994, 50.70536786384713],
            [-2.983764694013574, 50.704614664675397],
            [-2.99198561119986, 50.705562984154128],
            [-2.995140457939614, 50.705477634468565],
            [-2.997688689324154, 50.704635689914895],
            [-3.001860454219408, 50.702035108133003],
            [-3.006749402007359, 50.700413811453551],
            [-3.016163434163426, 50.698987656754866],
            [-3.022752263367685, 50.698414721518262],
            [-3.028567763451288, 50.698680092793211],
            [-3.047769118346983, 50.701573855688459],
            [-3.053481190172749, 50.701875786418114],
            [-3.054787818554269, 50.702930537523102],
            [-3.05852778064683, 50.704189821893614],
            [-3.058926685121697, 50.704735670288912],
            [-3.05749272320341, 50.709860307508059],
            [-3.056497983315483, 50.711602277489085],
            [-3.055951017484698, 50.7138599767123],
            [-3.056137804318827, 50.714923053291038],
            [-3.055268615445215, 50.716522683371501],
            [-3.054778457929887, 50.716583775903672],
            [-3.054642202951148, 50.718022985762943],
            [-3.054875347107815, 50.718753803388957],
            [-3.054929272676982, 50.71774880004039],
            [-3.056125324516988, 50.717707392504082],
            [-3.055720829400378, 50.717420583840521],
            [-3.055898376983282, 50.717000802110455],
            [-3.056935729152064, 50.717146978420764],
            [-3.056727043210666, 50.718889910818724],
            [-3.057401595132518, 50.71770571336392],
            [-3.057344223563181, 50.71893287577398],
            [-3.057103150410283, 50.719362228717713],
            [-3.056171593348065, 50.71888325444462],
            [-3.054805843947971, 50.719254441729682],
            [-3.053072110074271, 50.721347494822311],
            [-3.053018058338841, 50.721906446506772],
            [-3.054483773565893, 50.719673730561439],
            [-3.055110230974996, 50.719183340125774],
            [-3.056173492434941, 50.719030722018267],
            [-3.056910248597445, 50.719603191172283],
            [-3.05561611308787, 50.721207574190068],
            [-3.056991380979731, 50.721949621217192],
            [-3.055955096549952, 50.72267485427065],
            [-3.053752411146442, 50.722813495471492],
            [-3.053621500165881, 50.723293104727986],
            [-3.054339576561918, 50.723852269228971],
            [-3.053462727585344, 50.723983400998776],
            [-3.052320054315122, 50.724643914033891],
            [-3.052800827271116, 50.72460270146523],
            [-3.052377220077868, 50.72504538339588],
            [-3.051517606302974, 50.724808532638413],
            [-3.050982786703119, 50.724967135743427],
            [-3.050994340069078, 50.725293476103282],
            [-3.051444360780266, 50.725270532358053],
            [-3.051491826035696, 50.724985926572188],
            [-3.052810440662042, 50.725726735045896],
            [-3.053255105682962, 50.725401669139814],
            [-3.052694762540201, 50.725622562149866],
            [-3.052557913262639, 50.725142674467286],
            [-3.053558382279344, 50.724205561985826],
            [-3.054758757192105, 50.723970781164574],
            [-3.054801484842527, 50.723286929044058],
            [-3.056702432943025, 50.72277060135967],
            [-3.058424214758888, 50.723232506125704],
            [-3.058277364121598, 50.722939769402814],
            [-3.056984804451311, 50.722727572751772],
            [-3.057302135308568, 50.721846081528476],
            [-3.056257604414571, 50.721196366967625],
            [-3.0571286455241, 50.720306259733299],
            [-3.057162759817249, 50.72062610015945],
            [-3.05784274941344, 50.720752127494244],
            [-3.057577552372196, 50.721116950812537],
            [-3.058017813557949, 50.720914210646939],
            [-3.057451670723129, 50.720244875266943],
            [-3.057984060371791, 50.719730142413745],
            [-3.058821245602142, 50.719991510320753],
            [-3.058868582037138, 50.720006288321926],
            [-3.058917117985962, 50.720408265896573],
            [-3.059011593880653, 50.720568848522817],
            [-3.060334281221592, 50.720925535440479],
            [-3.058981196818191, 50.720414445547263],
            [-3.058954185058978, 50.720343022350889],
            [-3.05893090731082, 50.720026703620448],
            [-3.058750716149225, 50.719805017086571],
            [-3.058177667574726, 50.719583598003766],
            [-3.058320039974871, 50.719300825257449],
            [-3.058784213923249, 50.719028619034582],
            [-3.0588243826772, 50.719742296387444],
            [-3.059045401355569, 50.719555033127335],
            [-3.058980068228334, 50.719044825713937],
            [-3.058853240453141, 50.71882295213355],
            [-3.058819657233237, 50.717270170017066],
            [-3.058720923903832, 50.71666044362231],
            [-3.057829856201943, 50.714077656400931],
            [-3.05869209019315, 50.711043687961727],
            [-3.060243867197733, 50.708257942194976],
            [-3.060761107734708, 50.705636274087091],
            [-3.060230287545338, 50.704642881498245],
            [-3.059504656392595, 50.704618004942809],
            [-3.060128221170838, 50.704261607531762],
            [-3.05900625401476, 50.703553267315392],
            [-3.054845462934624, 50.702533424576579],
            [-3.05523404957278, 50.702306880417098],
            [-3.060898189934782, 50.703051337676108],
            [-3.071207644853439, 50.703122505890754],
            [-3.079866325525765, 50.702285386581963],
            [-3.082918180127934, 50.701433308463379],
            [-3.08450735078461, 50.700589374716714],
            [-3.084690749083733, 50.698780070915348],
            [-3.086335961872027, 50.698038110331893],
            [-3.087406914259621, 50.6965901377039],
            [-3.090219284043844, 50.696483823880264],
            [-3.092345135230741, 50.695594291082607],
            [-3.093118249901724, 50.691759606651743],
            [-3.092730916917259, 50.690383707328706],
            [-3.093122013854686, 50.690642636082913],
            [-3.093274920873461, 50.690216730232514],
            [-3.093740500450625, 50.690330170199125],
            [-3.094675880543834, 50.689825870767685],
            [-3.094539458737889, 50.687492558448447],
            [-3.095543936259544, 50.686554137778614],
            [-3.095589285314635, 50.685401702129319],
            [-3.095954877650363, 50.685235490138076],
            [-3.100435944660938, 50.685692373791525],
            [-3.105391821429908, 50.685387367271694],
            [-3.11516152515784, 50.686495755061188],
            [-3.126575518721026, 50.686905766077885],
            [-3.138439397053251, 50.68653506277613],
            [-3.141104406107621, 50.685927144590565],
            [-3.146897220812324, 50.68541252721765],
            [-3.151122594617375, 50.685406836072445],
            [-3.158354704560446, 50.686092387480755],
            [-3.165098301385868, 50.685392943748653],
            [-3.166804494125572, 50.684953196810788],
            [-3.184701835738729, 50.684964168380034],
            [-3.193272698694971, 50.684389289723896],
            [-3.197767801741269, 50.683083236912168],
            [-3.19918004481929, 50.682399624229113],
            [-3.207111826569265, 50.682153153464021],
            [-3.212635759094616, 50.681630854519859],
            [-3.231323188573096, 50.679317897538262],
            [-3.23404336408104, 50.678661399735951],
            [-3.234834695784993, 50.678450670620869],
            [-3.234733894098325, 50.678007477425126],
            [-3.234924615517016, 50.678317518434611],
            [-3.235430400452234, 50.678282479559812],
            [-3.236996578694277, 50.677988874671527],
            [-3.237008708425773, 50.677750427492441],
            [-3.237261624066535, 50.67794918874872],
            [-3.239984384376775, 50.677396844641081],
            [-3.245567966544048, 50.675312932895572],
            [-3.247900351653399, 50.675259198823042],
            [-3.253604104843446, 50.674255489771298],
            [-3.258833739896542, 50.672942762109976],
            [-3.261819698305304, 50.671881645249563],
            [-3.264125112302097, 50.671252314574936],
            [-3.266905842452274, 50.669777807817091],
            [-3.267564883350338, 50.667346078161607],
            [-3.267155561517512, 50.666649062183318],
            [-3.268856588658068, 50.664870583212178],
            [-3.270938295268793, 50.664566407046053],
            [-3.273715233543457, 50.662555780954165],
            [-3.275257464331533, 50.662555098310818],
            [-3.276013780898448, 50.66226982707736],
            [-3.276537824821501, 50.661559019816487],
            [-3.27630149296849, 50.661139827973621],
            [-3.27763225028847, 50.660230418959507],
            [-3.27698017584646, 50.659036975483126],
            [-3.278087380125703, 50.659484387217354],
            [-3.281081855780636, 50.657119560628459],
            [-3.280775261830429, 50.656198429704311],
            [-3.280066085563759, 50.655767355233728],
            [-3.283159195732393, 50.65449046703111],
            [-3.284675809478975, 50.653353198509983],
            [-3.285376540505403, 50.65166103965965],
            [-3.285102914597838, 50.648476860764156],
            [-3.288649483284743, 50.643324133449646],
            [-3.288821163992128, 50.640914745061352],
            [-3.289696814347663, 50.640819618071703],
            [-3.292886059851818, 50.638865107771387],
            [-3.294444488178559, 50.635827868588756],
            [-3.296387460482986, 50.635262169678413],
            [-3.297852492583952, 50.63428807456706],
            [-3.300611130313877, 50.632078239681384],
            [-3.30060489511611, 50.631493747479269],
            [-3.302549943850286, 50.630909934748274],
            [-3.303694509341862, 50.629809844670369],
            [-3.304892929568359, 50.629839593119137],
            [-3.306080985154011, 50.629495325606435],
            [-3.30627287591641, 50.629888877512478],
            [-3.305732991668888, 50.630050514230767],
            [-3.306257579443554, 50.630459222059699],
            [-3.306454761791884, 50.63262438708],
            [-3.30697203872813, 50.633481937368849],
            [-3.308123479889038, 50.634464566327537],
            [-3.306878613581793, 50.632968571042561],
            [-3.306929921559641, 50.632626251443405],
            [-3.307421129680256, 50.632798805723311],
            [-3.306823057672869, 50.632443987844724],
            [-3.306717833769724, 50.631606996214487],
            [-3.307408614846549, 50.631481433159607],
            [-3.308703919921234, 50.63347058649277],
            [-3.308348780200363, 50.63453218343021],
            [-3.309133795131103, 50.638865308319822],
            [-3.308485974817937, 50.638759271894486],
            [-3.308071877160665, 50.639227075145797],
            [-3.308606563154628, 50.638874829600212],
            [-3.309131213171056, 50.638975954363403],
            [-3.310009860300673, 50.643924055945476],
            [-3.309931518650473, 50.645098143683839],
            [-3.308620242328106, 50.647563052367708],
            [-3.309180690872322, 50.647320233152485],
            [-3.309907226352919, 50.645462201708895],
            [-3.310190901461458, 50.644737707429499],
            [-3.309328418088481, 50.634368290709823],
            [-3.309685017381955, 50.633868753164883],
            [-3.310099349039859, 50.634632118489286],
            [-3.309767483392338, 50.635054936420097],
            [-3.309848833009733, 50.635183524644525],
            [-3.310260046823505, 50.634918994709643],
            [-3.310698567116982, 50.636315701367941],
            [-3.310411236636929, 50.633847095837339],
            [-3.309364090359313, 50.633565690796054],
            [-3.30819481763644, 50.631075103830156],
            [-3.308845404293282, 50.6307755142521],
            [-3.311048280881171, 50.631789463264788],
            [-3.311170171502559, 50.63149491080636],
            [-3.309034835108417, 50.630621399371954],
            [-3.310337268676737, 50.630475453545557],
            [-3.310464702241234, 50.630888609563812],
            [-3.310812205560946, 50.630571733812339],
            [-3.31102995727939, 50.631283348600917],
            [-3.311805434241522, 50.631254832723108],
            [-3.311974101850295, 50.630913886904651],
            [-3.311312624883057, 50.6304150144461],
            [-3.30872831394787, 50.630428790469324],
            [-3.30781058816235, 50.630069474682891],
            [-3.306553192859627, 50.630054806978578],
            [-3.306972830858645, 50.629585147365781],
            [-3.310501045439969, 50.629856672872712],
            [-3.314156237621075, 50.629711170019952],
            [-3.322392735407688, 50.628414653008193],
            [-3.334197790694015, 50.625385991924027],
            [-3.349188228180623, 50.620098824900488],
            [-3.35661111342096, 50.6167534253185],
            [-3.359209360288156, 50.614797662044509],
            [-3.359615063543836, 50.613965540695936],
            [-3.359135142956508, 50.613890202239048],
            [-3.357698989265903, 50.612267469311021],
            [-3.357928946509482, 50.611614570721365],
            [-3.358855824818652, 50.611211650946551],
            [-3.358183198286187, 50.611069308231855],
            [-3.357841594153369, 50.610298965672229],
            [-3.357928393937257, 50.608208802525915],
            [-3.358959938751604, 50.606879246735765],
            [-3.359584108208098, 50.607401669351368],
            [-3.361044134028771, 50.607748845984823],
            [-3.361670776913609, 50.60732782748498],
            [-3.361755078765547, 50.606723388788893],
            [-3.362398547241611, 50.607668257684914],
            [-3.363114021755221, 50.607925189227679],
            [-3.364091310673787, 50.609855408209867],
            [-3.365962237951224, 50.609892835923695],
            [-3.368884373305877, 50.609724525971252],
            [-3.372254490012829, 50.60902115717321],
            [-3.373876575244733, 50.608279867735739],
            [-3.375111973887571, 50.608383100402861],
            [-3.377223832010096, 50.607864411112544],
            [-3.380911322246678, 50.607728114888758],
            [-3.385449805018492, 50.606733507967832],
            [-3.385962932690281, 50.607314668718146],
            [-3.387072136202539, 50.607547881245075],
            [-3.387868866937447, 50.608112165531693],
            [-3.387959198522875, 50.609116546147774],
            [-3.39680077492774, 50.610352649731674],
            [-3.39856699896034, 50.61028918452304],
            [-3.401071356560431, 50.610999237542472],
            [-3.407098259370494, 50.61115051264045],
            [-3.409045866327962, 50.611622500347003],
            [-3.412120712385921, 50.613628551959586],
            [-3.416098007110286, 50.615834897781227],
            [-3.423107625026759, 50.61667667577052],
            [-3.423733467201018, 50.6163839372201],
            [-3.424346604987027, 50.616559905269703],
            [-3.423822192189234, 50.617309169710857],
            [-3.424395784236735, 50.616924434672839],
            [-3.424718523305642, 50.617407028516659],
            [-3.426227582110217, 50.617666454263684],
            [-3.426474849567269, 50.617989885444892],
            [-3.425926900875544, 50.619035329343831],
            [-3.425258315206425, 50.618803389846015],
            [-3.42436534297095, 50.619519393756939],
            [-3.42174945036607, 50.619637691289242],
            [-3.420451013214616, 50.619379236296531],
            [-3.420478458699058, 50.619680179256186],
            [-3.422218859676709, 50.620390100933641],
            [-3.421993982787054, 50.621230128732186],
            [-3.421470220948306, 50.621535101674766],
            [-3.419938136912885, 50.621687790668098],
            [-3.417751068041694, 50.621074105811523],
            [-3.416975802170766, 50.621149191592586],
            [-3.416601923229996, 50.621550346592116],
            [-3.415602931795499, 50.6239673157081],
            [-3.416250073034084, 50.627342747573081],
            [-3.41586774738689, 50.629582242380614],
            [-3.417619522933564, 50.632023313368954],
            [-3.421710783463643, 50.634946649112543],
            [-3.423105910349821, 50.638859702636786],
            [-3.42493776907655, 50.640698030209705],
            [-3.430094851461237, 50.643794192005252],
            [-3.432780517858576, 50.646112048646884],
            [-3.432170950293817, 50.648020734754347],
            [-3.433252886278661, 50.647783494562574],
            [-3.432521749096279, 50.648020918210371],
            [-3.433887883015574, 50.648430394920673],
            [-3.434023539602593, 50.64907624495364],
            [-3.433389295159339, 50.649430293795064],
            [-3.433122188392208, 50.650181825009788],
            [-3.434754541376491, 50.652362399992455],
            [-3.436127205581774, 50.656088508440604],
            [-3.440043652018575, 50.660682516742014],
            [-3.44275817308108, 50.665186061241201],
            [-3.443511010118428, 50.665603019316094],
            [-3.443222252392903, 50.666107524143314],
            [-3.443554536831879, 50.666791396035592],
            [-3.442582376834112, 50.666375355371876],
            [-3.442820943843526, 50.666725838437927],
            [-3.442380800128155, 50.666631462448684],
            [-3.441976242094728, 50.667328955078091],
            [-3.442504303933801, 50.667198310696214],
            [-3.442460361842937, 50.666688034649233],
            [-3.443636444318405, 50.66692438152878],
            [-3.443914274537661, 50.66740118156217],
            [-3.443503072761383, 50.66765089493159],
            [-3.444135378085357, 50.667781556290208],
            [-3.445442427762955, 50.669930937488132],
            [-3.449571824393774, 50.673008408013587],
            [-3.450156961180856, 50.674452646623202],
            [-3.449856308641933, 50.675994243306576],
            [-3.451487075492813, 50.679031662834113],
            [-3.450673789476643, 50.682171458732668],
            [-3.451590249340413, 50.683567496151802],
            [-3.449739456825206, 50.684464685645864],
            [-3.450425664476236, 50.684682777119995],
            [-3.450166662832041, 50.684409007340278],
            [-3.451844259548813, 50.683725311516646],
            [-3.454647597487929, 50.683970940033987],
            [-3.455743309128547, 50.682509335389994],
            [-3.45647277608313, 50.68252450162246],
            [-3.456478813997583, 50.683222306158164],
            [-3.459112634173299, 50.685623841030015],
            [-3.459165556260124, 50.686873246514523],
            [-3.458183025904292, 50.68883439244523],
            [-3.458669329908149, 50.689633205608779],
            [-3.459893040783258, 50.690015336059112],
            [-3.459984566232948, 50.690043915801951],
            [-3.459992209411906, 50.69007481452001],
            [-3.46028731890776, 50.691267706419424],
            [-3.458897268188942, 50.692644902575623],
            [-3.460670791718156, 50.693357437242618],
            [-3.461048894330968, 50.693997515957321],
            [-3.461093909063642, 50.694808145956316],
            [-3.46029107177555, 50.695536772609834],
            [-3.45871473996004, 50.695529861716729],
            [-3.457494783278327, 50.694628384313525],
            [-3.458521360282987, 50.693032722097655],
            [-3.457828052723415, 50.692590833949296],
            [-3.4567293187778, 50.692589285582962],
            [-3.455344024020667, 50.696731812623376],
            [-3.455923658751284, 50.69711398134428],
            [-3.457880519556308, 50.696702805902333],
            [-3.45854519631638, 50.696813200003163],
            [-3.458692340116937, 50.697493948214245],
            [-3.457426150581016, 50.697803870677774],
            [-3.457067758357918, 50.699535061386484],
            [-3.45816628446792, 50.699661616364459],
            [-3.461378421074577, 50.700863351904395],
            [-3.462534134815366, 50.701552127854647],
            [-3.462658418003183, 50.702087466658519],
            [-3.462757937021201, 50.701462983506481],
            [-3.461810381405268, 50.700865128964097],
            [-3.458271038443883, 50.699523607786794],
            [-3.457161670942051, 50.699458343639336],
            [-3.457727985024635, 50.698496175158745],
            [-3.457585794913577, 50.697837847121029],
            [-3.459026206704101, 50.697438506966776],
            [-3.458738326410916, 50.696785601442066],
            [-3.457939089388984, 50.696582462534899],
            [-3.455999163422475, 50.696991628750766],
            [-3.455516059683108, 50.69666311376578],
            [-3.456989296272516, 50.692663377623212],
            [-3.45774323176082, 50.692686324548532],
            [-3.458346964647947, 50.693161710053118],
            [-3.457382433982759, 50.694338407065963],
            [-3.457660276278914, 50.695308005152484],
            [-3.458521075035415, 50.695529011314989],
            [-3.458816093430871, 50.695604753485661],
            [-3.459624229535117, 50.695812227467094],
            [-3.460737053113462, 50.695535679763694],
            [-3.462980955208629, 50.695342953879347],
            [-3.463507012771397, 50.696140348217803],
            [-3.466098199658059, 50.695505227318897],
            [-3.467966441149093, 50.697792983141817],
            [-3.468250898422365, 50.698739989877929],
            [-3.47087016767778, 50.697777953261522],
            [-3.471408455625071, 50.698825171313437],
            [-3.468082797919438, 50.705780248632003],
            [-3.463643911456421, 50.712587326054084],
            [-3.462250329755798, 50.717007917519602],
            [-3.462415982064691, 50.728652991613338],
            [-3.462422802023753, 50.728826475523647],
            [-3.462349261463575, 50.732152196965913],
            [-3.461635337442067, 50.73593830737196],
            [-3.460419124960445, 50.739208198398586],
            [-3.462556742728499, 50.739312699183643],
            [-3.465982281549444, 50.742082749994111],
            [-3.464917984697545, 50.743357878052677],
            [-3.465456323405445, 50.743803470642057],
            [-3.465082783869406, 50.744959297549372],
            [-3.466572604187898, 50.744328126214263],
            [-3.467491821913238, 50.744306665732907],
            [-3.469697237868954, 50.745391343252223],
            [-3.474969897473096, 50.74628792397332],
            [-3.475409021766877, 50.745918141632977],
            [-3.478717958674904, 50.746837591522798],
            [-3.481006548921349, 50.746997396960545],
            [-3.484059635877795, 50.74990565001476],
            [-3.485830983103611, 50.750826476302031],
            [-3.491234563638968, 50.753538186574445],
            [-3.494303601054066, 50.754287594915226],
            [-3.49685631303083, 50.754403235491772],
            [-3.500190943452291, 50.75449796913982],
            [-3.50273386852637, 50.755280904831174],
            [-3.502769719865943, 50.755780466338337],
            [-3.50459200063021, 50.757342469241436],
            [-3.505235047955898, 50.757190276856704],
            [-3.506846329246091, 50.75866773444821],
            [-3.50740520011412, 50.760616537022869],
            [-3.512266026927243, 50.761109369487706],
            [-3.512724136438011, 50.761464954269812],
            [-3.513916357577522, 50.760798369104492],
            [-3.514633368569948, 50.759555184613703],
            [-3.5154697400371, 50.759198975584731],
            [-3.522477870631099, 50.757573420471026],
            [-3.524051866726497, 50.757667968470358],
            [-3.525407715637554, 50.758378689086449],
            [-3.525855074540826, 50.758314378831287],
            [-3.526331662629964, 50.757666921798773],
            [-3.526206906111443, 50.755314122539367],
            [-3.527213076011769, 50.755170544008543],
            [-3.528829517445131, 50.755482107895887],
            [-3.529030775886545, 50.756567651931228],
            [-3.530527356019408, 50.75698418682191],
            [-3.534938550220108, 50.755958518014054],
            [-3.536117222002535, 50.754199200578284],
            [-3.537354179371228, 50.753139676950425],
            [-3.542089230503652, 50.751803692313338],
            [-3.544050729533638, 50.750926965886904],
            [-3.547445642877782, 50.748985269599522],
            [-3.548199894286047, 50.747313297149041],
            [-3.549059064144615, 50.748220097501104],
            [-3.55032863989226, 50.748804870887355],
            [-3.551625920848822, 50.747654457772093],
            [-3.552010569753884, 50.745141110257038],
            [-3.552945720199021, 50.745473099139979],
            [-3.555914317718482, 50.744846252886269],
            [-3.559956271152383, 50.744702301166768],
            [-3.564701447150596, 50.745414826768901],
            [-3.567522161732382, 50.745542408218228],
            [-3.569653532901381, 50.745019996521229],
            [-3.573362690434603, 50.744124622717415],
            [-3.579451706095974, 50.741846113221285],
            [-3.582592307878196, 50.741587677839838],
            [-3.58306791739867, 50.741569528477456],
            [-3.585467690009702, 50.743884169820021],
            [-3.58429586468143, 50.74498649426156],
            [-3.580459557498553, 50.747152896336743],
            [-3.57800340719926, 50.74977714079953],
            [-3.573867316758453, 50.75266234562104],
            [-3.576023455398038, 50.753966922488146],
            [-3.576287758454459, 50.755082114876096],
            [-3.573546804123105, 50.755659639851274],
            [-3.569372355729153, 50.755726701356373],
            [-3.570924985826216, 50.75823020543698],
            [-3.570528865955324, 50.760246440250654],
            [-3.561657050717154, 50.758370007957289],
            [-3.559582003333682, 50.757664833173749],
            [-3.556791634696985, 50.757960234600141],
            [-3.557009480565267, 50.758329646874827],
            [-3.556415922180637, 50.758900551266862],
            [-3.559546656047142, 50.759842577541264],
            [-3.558726750806847, 50.761836558439782],
            [-3.558346512321011, 50.762341667166943],
            [-3.551094725410451, 50.765245167667892],
            [-3.551429958814712, 50.765305458739419],
            [-3.550593619936861, 50.766386782104099],
            [-3.550559329162707, 50.767451140112591],
            [-3.551381493202138, 50.768683970124556],
            [-3.551337038788873, 50.769654933469582],
            [-3.552099978972832, 50.7711961153557],
            [-3.547401149529852, 50.772992468471834],
            [-3.550077240556447, 50.77457390745252],
            [-3.552113938599871, 50.774787832265581],
            [-3.553718343493033, 50.774496666517038],
            [-3.55590932421218, 50.775085286151111],
            [-3.557201092783833, 50.774989800091838],
            [-3.557911609543652, 50.774774363353835],
            [-3.558517139750528, 50.773619631602948],
            [-3.559175569133444, 50.775037158271118],
            [-3.563337633020398, 50.775014702851664],
            [-3.563199939734707, 50.779051820386073],
            [-3.564108302362321, 50.779408362157817],
            [-3.566996408687807, 50.779496389740025],
            [-3.568980641999782, 50.781015646713776],
            [-3.569162607732305, 50.784162633346476],
            [-3.570426826826513, 50.785524278357848],
            [-3.570729129469997, 50.787051755999791],
            [-3.571996428445231, 50.78854374443096],
            [-3.561514388104217, 50.791002136108311],
            [-3.554304980790786, 50.791631750650531],
            [-3.547503191490054, 50.79129143718702],
            [-3.543395657109313, 50.790988843445113],
            [-3.543180895403989, 50.790240752339159],
            [-3.541690536153817, 50.790911574163822],
            [-3.536743462604528, 50.791613592106202],
            [-3.536469889119601, 50.791275455130979],
            [-3.533922835914203, 50.79087731315574],
            [-3.528062278570698, 50.788893014181106],
            [-3.524696818614056, 50.787012521348636],
            [-3.523773598546437, 50.789204541023913],
            [-3.523914530056908, 50.791967208313551],
            [-3.524742312637678, 50.793685779221519],
            [-3.526410840899037, 50.795424818547325],
            [-3.526668621972799, 50.79754383728794],
            [-3.526124288484215, 50.797517687207325],
            [-3.525933955603229, 50.795421167113957],
            [-3.525867523800971, 50.796735941618266],
            [-3.525317369495355, 50.797315105627668],
            [-3.524248065958035, 50.796723845250071],
            [-3.523890413955932, 50.796939859337826],
            [-3.52151646226532, 50.796460043650967],
            [-3.519861338419802, 50.796747824813941],
            [-3.518359552835189, 50.797432883360671],
            [-3.51780691870694, 50.798989602503433],
            [-3.517326831293117, 50.798931998310003],
            [-3.51676305963622, 50.80001491634718],
            [-3.514103859676569, 50.80217096144365],
            [-3.512452830358598, 50.804702378696319],
            [-3.511455809095734, 50.804548031507679],
            [-3.510988761220649, 50.805028023414309],
            [-3.512431318477224, 50.807859258731604],
            [-3.512058313559845, 50.810988319287056],
            [-3.509822755870322, 50.811473234049998],
            [-3.50930498934973, 50.811263200555508],
            [-3.509432551260812, 50.811653651285077],
            [-3.50769101514271, 50.81223015556651],
            [-3.507836225801787, 50.813829958672592],
            [-3.504982901239609, 50.814400105643394],
            [-3.505040542038271, 50.81508913623221],
            [-3.505711509499613, 50.815559810821149],
            [-3.507276950193174, 50.815285084747849],
            [-3.509127977136574, 50.815811529561635],
            [-3.510021647102757, 50.816627332613535],
            [-3.510629220316779, 50.816319093820155],
            [-3.509700462434726, 50.817408498385845],
            [-3.510024176312026, 50.819389994463215],
            [-3.509641210226297, 50.82045704209667],
            [-3.508491239732261, 50.820676964128282],
            [-3.507515785782264, 50.82032085694361],
            [-3.506545427068986, 50.820563618976294],
            [-3.505255007848561, 50.821758380538192],
            [-3.506403099003887, 50.821875757588266],
            [-3.507145774177121, 50.822365282662631],
            [-3.506938921224577, 50.824054168386851],
            [-3.508070251339583, 50.82514750095855],
            [-3.507350729145703, 50.827405983893321],
            [-3.506039672837713, 50.826773614932662],
            [-3.505394148031599, 50.825642518187074],
            [-3.499458122025237, 50.825123632991655],
            [-3.499007088269228, 50.82141526768855],
            [-3.497575999945526, 50.817777042628293],
            [-3.498415078247118, 50.816804000266274],
            [-3.496736559979441, 50.816964046544904],
            [-3.497143679298606, 50.812294079329902],
            [-3.498584155370182, 50.808826707452091],
            [-3.500178520094152, 50.805960776300253],
            [-3.502649974789066, 50.803942370368162],
            [-3.502223719055547, 50.801051164109346],
            [-3.502503096568527, 50.799054679424124],
            [-3.499483848747311, 50.79920325632731],
            [-3.498918505608384, 50.798964111365876],
            [-3.498637087284149, 50.799304971776259],
            [-3.493938392964297, 50.799432706908171],
            [-3.490267362358803, 50.799970702476031],
            [-3.48073505781293, 50.799376269197396],
            [-3.480735517199221, 50.798448170282718],
            [-3.479179171317583, 50.79684106011468],
            [-3.477698888138418, 50.796382290244516],
            [-3.477925610077477, 50.795652770486818],
            [-3.479110657069427, 50.795120639652239],
            [-3.479432743881834, 50.795300014360215],
            [-3.480086494752147, 50.794479636942064],
            [-3.482005004682605, 50.794484049622945],
            [-3.483158733932576, 50.794130339770462],
            [-3.483285536176651, 50.792040515112177],
            [-3.481679987501038, 50.792686849973094],
            [-3.480394666220391, 50.792467553139922],
            [-3.479465306783838, 50.793006346503951],
            [-3.478217543131987, 50.792673235420779],
            [-3.477305809492143, 50.794041856201773],
            [-3.476624019488747, 50.793974049412668],
            [-3.476157536842448, 50.795110394508164],
            [-3.472408535352725, 50.797226213667841],
            [-3.471864095420874, 50.797150349472354],
            [-3.470138060961438, 50.795867215959312],
            [-3.470106689143907, 50.794867573547059],
            [-3.469159422298042, 50.795021602956012],
            [-3.468953931118591, 50.795301180483349],
            [-3.469505228092915, 50.79527984579515],
            [-3.46970612886606, 50.795577685128698],
            [-3.469007527683892, 50.795969595356631],
            [-3.468509181389177, 50.795598159777789],
            [-3.467984481583845, 50.795653328883603],
            [-3.468221985661428, 50.796938157107],
            [-3.467095856209037, 50.797363316160514],
            [-3.468250115970292, 50.798061945689561],
            [-3.468255751427494, 50.798377533984883],
            [-3.466367299402406, 50.798794294690452],
            [-3.465770542255632, 50.799450201938747],
            [-3.466415241045765, 50.800280257501839],
            [-3.466105209855673, 50.800938855751149],
            [-3.464169732883774, 50.800990150814137],
            [-3.463040652113904, 50.802324512504995],
            [-3.462140093177342, 50.80252556160373],
            [-3.460807320700686, 50.80338402011926],
            [-3.458415693054877, 50.803328517885866],
            [-3.457928174140104, 50.803122375662909],
            [-3.458136015734497, 50.802687207867336],
            [-3.457452026850785, 50.802551867710932],
            [-3.456842583979508, 50.802896724566281],
            [-3.454537091938897, 50.800587289106488],
            [-3.45195678404423, 50.800162592064922],
            [-3.451006902983024, 50.799687886323611],
            [-3.449591444292573, 50.799987873027568],
            [-3.446486291428761, 50.799797103788805],
            [-3.445830483369366, 50.800250395568646],
            [-3.443314111763271, 50.800423656659923],
            [-3.443015646356155, 50.800961541248128],
            [-3.444077209778838, 50.800641735946066],
            [-3.444172993629829, 50.801262873605332],
            [-3.441355355634136, 50.802577451340127],
            [-3.440804414330524, 50.802059960639703],
            [-3.440110262106055, 50.802422863000437],
            [-3.440254137627865, 50.802948082654488],
            [-3.439659558182365, 50.803411374641584],
            [-3.439799805216547, 50.80483415185865],
            [-3.439145675881079, 50.805392603747919],
            [-3.439512869173861, 50.805933953679123],
            [-3.438042016568873, 50.806564529464275],
            [-3.437573329081613, 50.807154859304319],
            [-3.437610573372429, 50.808090582221958],
            [-3.436078342051776, 50.808111257674007],
            [-3.434437744141894, 50.80950559270125],
            [-3.433476196986636, 50.809345646715933],
            [-3.431923112344379, 50.810820707112924],
            [-3.429719263846876, 50.812091488257117],
            [-3.427830297851919, 50.81163619626377],
            [-3.426072643265158, 50.812461682591355],
            [-3.425646423076955, 50.811936301115466],
            [-3.425535671180792, 50.81170203594877],
            [-3.424216275636797, 50.811584157839576],
            [-3.423533917633091, 50.812016961909158],
            [-3.422605524471305, 50.811547159511953],
            [-3.419776734353924, 50.811504288590953],
            [-3.418969098470663, 50.811130112634054],
            [-3.417750541863821, 50.810450665566165],
            [-3.416561394832008, 50.808490233460098],
            [-3.41381081613422, 50.806941725642687],
            [-3.413447399775109, 50.80708012751559],
            [-3.412261679055463, 50.806730271647446],
            [-3.410636553407744, 50.805503497747047],
            [-3.412066597217819, 50.805061718500866],
            [-3.410377527756652, 50.804678367305371],
            [-3.405963106072431, 50.805081498881769],
            [-3.402524800821017, 50.806424196231418],
            [-3.399190529720883, 50.806647704045922],
            [-3.397122497307356, 50.807403632725226],
            [-3.39490185759017, 50.807803425525528],
            [-3.391234033800273, 50.807935367620018],
            [-3.388702031205138, 50.808798301294544],
            [-3.386349170339356, 50.809003456133418],
            [-3.383843333858277, 50.810228392772984],
            [-3.380853745473504, 50.810744954655114],
            [-3.380457338343214, 50.810201970201369],
            [-3.37909085533539, 50.80978107355844],
            [-3.378910169083942, 50.810296713298072],
            [-3.376522545696333, 50.810144156894808],
            [-3.373135277524802, 50.810993459742079],
            [-3.368294631960471, 50.810879481363045],
            [-3.366839284077709, 50.812224818380592],
            [-3.363020189800793, 50.811660683497287],
            [-3.362523526942785, 50.812153010044092],
            [-3.361244700167252, 50.812399962790053],
            [-3.36106033021873, 50.811965952141342],
            [-3.359945185810453, 50.811886331796956],
            [-3.359896429972015, 50.814850105202659],
            [-3.361733421960801, 50.821003286563403],
            [-3.366974288681101, 50.820542693362903],
            [-3.367987084366713, 50.823573160823102],
            [-3.367459629926609, 50.827241304455974],
            [-3.367610667176877, 50.829542645753577],
            [-3.366736959680048, 50.829333456446264],
            [-3.364002256494202, 50.832552589724664],
            [-3.362344031531586, 50.831785978659525],
            [-3.355884915539116, 50.833913438431765],
            [-3.355396037834931, 50.8314991026987],
            [-3.356998258285052, 50.826912026205996],
            [-3.353831457100353, 50.826454208529562],
            [-3.350882687295945, 50.82730046436788],
            [-3.343555368855534, 50.825842791850562],
            [-3.34224379657716, 50.827736529644881],
            [-3.341221723187485, 50.828389486902516],
            [-3.342795674235833, 50.830441567107933],
            [-3.342834693741806, 50.831009475312463],
            [-3.344826580406682, 50.830897497648998],
            [-3.344858983575718, 50.831234361482771],
            [-3.343588814908851, 50.832446865362606],
            [-3.343458217957898, 50.833091368579183],
            [-3.341735982128511, 50.834141787426802],
            [-3.340699044213006, 50.835420821826695],
            [-3.342246338372959, 50.836288837878627],
            [-3.342577554428561, 50.837388465920952],
            [-3.34163483872805, 50.840518078470289],
            [-3.342913295635817, 50.843047482591459],
            [-3.344769722449748, 50.841664560535811],
            [-3.345907748699593, 50.842860992589074],
            [-3.348580992390896, 50.844342740210323],
            [-3.348155925934867, 50.846744281965435],
            [-3.343047884607936, 50.846200669298078],
            [-3.343377364035022, 50.847582692961375],
            [-3.339361367867306, 50.849657681282338],
            [-3.332492301146995, 50.852028643529749],
            [-3.331346102103424, 50.85208759300258],
            [-3.330737086985863, 50.85165388626816],
            [-3.329675184333035, 50.85167948317855],
            [-3.329413879061011, 50.851932465183367],
            [-3.330076554299411, 50.852704601439079],
            [-3.331055153175696, 50.856089179919834],
            [-3.32825924453126, 50.85679461558783],
            [-3.328290287785378, 50.857037071748202],
            [-3.325323755616562, 50.856989870918447],
            [-3.321546988009235, 50.857531819791781],
            [-3.3192291427191, 50.856960020381933],
            [-3.319385645998541, 50.856061654413899],
            [-3.318534354076818, 50.854793381077933],
            [-3.31202491923797, 50.855513357166686],
            [-3.306107349741667, 50.854439449070682],
            [-3.297595226763391, 50.85472328201994],
            [-3.293198089731357, 50.854157886717424],
            [-3.291667244508852, 50.854439236884801],
            [-3.28937314585373, 50.853936727880281],
            [-3.284095319475719, 50.854714369651624],
            [-3.281268614377742, 50.855821898546822],
            [-3.276960845220596, 50.858056201940791],
            [-3.275855396124125, 50.859095285519167],
            [-3.27643467811041, 50.859704064328831],
            [-3.279383727130409, 50.861080952743585],
            [-3.283199569592431, 50.863486917931354],
            [-3.28267314494119, 50.866310182613589],
            [-3.278014771684798, 50.86786491139204],
            [-3.27483880137958, 50.86818562842096],
            [-3.268696545348662, 50.870482811006859],
            [-3.265365364858487, 50.872008204069886],
            [-3.262485175217571, 50.873926107364511],
            [-3.259009202431232, 50.87700415285736],
            [-3.256724879941522, 50.878160978584319],
            [-3.254130540240644, 50.878628640882326],
            [-3.253187692318317, 50.879089312574948],
            [-3.252943683052812, 50.87979607375501],
            [-3.253516476095989, 50.880429314515446],
            [-3.25277100878428, 50.881201712866165],
            [-3.251801701541729, 50.883965726995932],
            [-3.249149959859382, 50.88639343424655],
            [-3.247162323808997, 50.887559482325514],
            [-3.245858229712867, 50.887225388801987],
            [-3.244210395178163, 50.88738235820194],
            [-3.243104196537756, 50.888606381823593],
            [-3.243636168858015, 50.889469422286481],
            [-3.238776429464096, 50.890375428669444],
            [-3.234919147917006, 50.892903726165819],
            [-3.234231898534779, 50.894122330077288],
            [-3.233052879548574, 50.895027780428642],
            [-3.231501431828891, 50.895023478390662],
            [-3.230323515609639, 50.894355145392915],
            [-3.228691309892933, 50.894036908654442],
            [-3.227169535934483, 50.894727381743579],
            [-3.225813117518582, 50.894622931487824],
            [-3.221714725150187, 50.893955474282542],
            [-3.220524781061792, 50.893364507002417],
            [-3.217553971645542, 50.892855070394283],
            [-3.21869325946906, 50.888076084788175],
            [-3.218349949687549, 50.887559884222114],
            [-3.215786993638107, 50.887520066570431],
            [-3.215671079553978, 50.886781164767406],
            [-3.218177963209506, 50.886308079808408],
            [-3.218283950372028, 50.885084848190132],
            [-3.217058359164572, 50.883606625597302],
            [-3.215111435478058, 50.882375112582942],
            [-3.214059290990585, 50.8807817425633],
            [-3.208628687349502, 50.877248177171936],
            [-3.207243840637962, 50.877067368081384],
            [-3.205096084042409, 50.87756169191006],
            [-3.201706233118343, 50.877624528198737],
            [-3.20088807163283, 50.878088887228316],
            [-3.198316196958492, 50.877458850110955],
            [-3.196842290168605, 50.877961387634009],
            [-3.191899253537868, 50.878654967645559],
            [-3.187517736022786, 50.878741005357739],
            [-3.187788327188892, 50.877419904256328],
            [-3.182136296665661, 50.876443125023521],
            [-3.18119953677959, 50.877114482376321],
            [-3.17810099282317, 50.878244727783759],
            [-3.176145935207593, 50.877091786775949],
            [-3.170549791292919, 50.878844100344693],
            [-3.168452543676658, 50.880125910139931],
            [-3.166664987837733, 50.88043696055005],
            [-3.165819313202189, 50.881409440345131],
            [-3.163638329816379, 50.882307107108737],
            [-3.165236397197336, 50.884254288171263],
            [-3.151197015697025, 50.886460170227565],
            [-3.142419866799547, 50.889258644340572],
            [-3.144168555740419, 50.891402475887176],
            [-3.143137434653922, 50.891796571750675],
            [-3.141842537653297, 50.891754392467988],
            [-3.141480483695123, 50.892356852579603],
            [-3.140084073892488, 50.892059352759503],
            [-3.13962572997271, 50.89145591841951],
            [-3.139176998824424, 50.891712096940012],
            [-3.13518912501032, 50.895000939454697],
            [-3.131018030525224, 50.897206891885311],
            [-3.127879562301155, 50.899849658046669],
            [-3.126332103106772, 50.901725201117749],
            [-3.122069310612661, 50.89814848074046],
            [-3.118478740245823, 50.899218010751596],
            [-3.115862638617026, 50.900784408035527],
            [-3.111977664384876, 50.902344856270197],
            [-3.110659498041276, 50.902650570673885],
            [-3.109511083538854, 50.902440272305903],
            [-3.106983047027053, 50.902823087163277],
            [-3.103415968952865, 50.904133832205005],
            [-3.101390440960189, 50.904060338862301],
            [-3.099209305445444, 50.904686111259664],
            [-3.094636521023726, 50.905034840119278],
            [-3.088750638618369, 50.90622387482329],
            [-3.078101242444024, 50.905445939476358],
            [-3.060023829007918, 50.90848019000785],
            [-3.057199188965756, 50.908422176916488],
            [-3.056266458724552, 50.908059223460278],
            [-3.052872386517914, 50.90873376609008],
            [-3.052415358224968, 50.908272066628967],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000041",
        LAD13CDO: "18UC",
        LAD13NM: "Exeter",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-3.506846329246091, 50.75866773444821],
            [-3.505235047955898, 50.757190276856704],
            [-3.50459200063021, 50.757342469241436],
            [-3.502769719865943, 50.755780466338337],
            [-3.50273386852637, 50.755280904831174],
            [-3.500190943452291, 50.75449796913982],
            [-3.49685631303083, 50.754403235491772],
            [-3.494303601054066, 50.754287594915226],
            [-3.491234563638968, 50.753538186574445],
            [-3.485830983103611, 50.750826476302031],
            [-3.484059635877795, 50.74990565001476],
            [-3.481006548921349, 50.746997396960545],
            [-3.478717958674904, 50.746837591522798],
            [-3.475409021766877, 50.745918141632977],
            [-3.474969897473096, 50.74628792397332],
            [-3.469697237868954, 50.745391343252223],
            [-3.467491821913238, 50.744306665732907],
            [-3.466572604187898, 50.744328126214263],
            [-3.465082783869406, 50.744959297549372],
            [-3.465456323405445, 50.743803470642057],
            [-3.464917984697545, 50.743357878052677],
            [-3.465982281549444, 50.742082749994111],
            [-3.462556742728499, 50.739312699183643],
            [-3.460419124960445, 50.739208198398586],
            [-3.461635337442067, 50.73593830737196],
            [-3.462349261463575, 50.732152196965913],
            [-3.462422802023753, 50.728826475523647],
            [-3.462415982064691, 50.728652991613338],
            [-3.462250329755798, 50.717007917519602],
            [-3.463643911456421, 50.712587326054084],
            [-3.468082797919438, 50.705780248632003],
            [-3.471408455625071, 50.698825171313437],
            [-3.47087016767778, 50.697777953261522],
            [-3.468250898422365, 50.698739989877929],
            [-3.467966441149093, 50.697792983141817],
            [-3.466098199658059, 50.695505227318897],
            [-3.463507012771397, 50.696140348217803],
            [-3.462980955208629, 50.695342953879347],
            [-3.460737053113462, 50.695535679763694],
            [-3.461321099718116, 50.694737847564951],
            [-3.461086007313267, 50.693506017175665],
            [-3.459157218924974, 50.6925355270739],
            [-3.460486055602422, 50.691512531262219],
            [-3.460376351313815, 50.690305207913845],
            [-3.4599946522348, 50.68992272534183],
            [-3.458756303980227, 50.689333539591708],
            [-3.458364621428622, 50.68870981111931],
            [-3.459494300495831, 50.686837652911947],
            [-3.459624214016628, 50.684911460075575],
            [-3.457737330559865, 50.683819012236839],
            [-3.456663242960672, 50.682001408654337],
            [-3.455132186523702, 50.682293035539637],
            [-3.454379749015915, 50.68364063217853],
            [-3.452625733169219, 50.683452967798388],
            [-3.451630673040994, 50.68253815961917],
            [-3.452765840920041, 50.678685672057803],
            [-3.453867950350779, 50.677112486783393],
            [-3.455681124365836, 50.675605952798968],
            [-3.45525300079705, 50.675217393757727],
            [-3.455799369275107, 50.675447991889435],
            [-3.456314708994731, 50.674904651310086],
            [-3.458912109038991, 50.67541715267415],
            [-3.461266604289286, 50.676992954394947],
            [-3.461952233442801, 50.677239763867256],
            [-3.462415525641391, 50.677027103845404],
            [-3.46288305398087, 50.677496981182237],
            [-3.462549138490882, 50.677910368664186],
            [-3.463461817773246, 50.678220869640292],
            [-3.463642643457741, 50.678664666585647],
            [-3.463056124487391, 50.678829415522287],
            [-3.463830165025708, 50.680096743835527],
            [-3.464634008225502, 50.680228736783469],
            [-3.465415823533426, 50.681198278314575],
            [-3.465017421985715, 50.681495570587629],
            [-3.465773156058269, 50.681627261289428],
            [-3.466499032905154, 50.682344787238058],
            [-3.466288091587767, 50.682525509349347],
            [-3.466548323810287, 50.682745268385894],
            [-3.466901127195479, 50.68257445129499],
            [-3.467854861773974, 50.683561597317585],
            [-3.46837824315294, 50.683776238415518],
            [-3.468706543153055, 50.683591335377145],
            [-3.471941972098889, 50.684975949301062],
            [-3.472589969109256, 50.686863551151973],
            [-3.473143618821408, 50.687364674909766],
            [-3.474889311911838, 50.68800180188569],
            [-3.479875324764278, 50.690788566154325],
            [-3.482512570616022, 50.691271260131963],
            [-3.484336759588692, 50.690439539586791],
            [-3.48573636014333, 50.690375837242371],
            [-3.487194165840642, 50.6912125050969],
            [-3.487898400427246, 50.692215262738515],
            [-3.490751848999433, 50.693929791657872],
            [-3.494205132789701, 50.693616663572392],
            [-3.497249437688113, 50.694333926402493],
            [-3.500993588368071, 50.698164576556167],
            [-3.501303331797, 50.699660670938115],
            [-3.504383780538121, 50.702641798662583],
            [-3.504691063881821, 50.702464260700381],
            [-3.503336575295331, 50.701380960399021],
            [-3.504173941043501, 50.701262235613846],
            [-3.504825883639452, 50.700792462141358],
            [-3.505399574210169, 50.700974811615488],
            [-3.505917665957913, 50.700791848942721],
            [-3.507103041697727, 50.701236982826359],
            [-3.506581701246, 50.701937108181859],
            [-3.507304361530228, 50.701244271751726],
            [-3.506054599043852, 50.700734320840269],
            [-3.505412351928443, 50.700887411429392],
            [-3.50492657229767, 50.700664356220997],
            [-3.503997358877594, 50.701187172199134],
            [-3.503142295868985, 50.701239573496977],
            [-3.502165052230423, 50.700038976785152],
            [-3.502479368451238, 50.699904521782756],
            [-3.507828760440006, 50.69975718837096],
            [-3.510869599723538, 50.701272748534194],
            [-3.510987950832921, 50.701033790540549],
            [-3.509689485885937, 50.700110817359629],
            [-3.508085609964964, 50.699465179332968],
            [-3.501875372768549, 50.699393393384753],
            [-3.501762417753501, 50.698339933567773],
            [-3.499828429798721, 50.695907867366749],
            [-3.497988110082039, 50.694231802123326],
            [-3.496278387974607, 50.693496530592036],
            [-3.494173093036153, 50.693143126758919],
            [-3.490690576526164, 50.693203016648177],
            [-3.488967408269139, 50.692261863535627],
            [-3.486995719584372, 50.690175410727676],
            [-3.485304465132668, 50.689790482532565],
            [-3.482463123024233, 50.689972356146818],
            [-3.481016001324398, 50.689560877172219],
            [-3.481823413328347, 50.689439994840733],
            [-3.483139733225836, 50.688621944429741],
            [-3.480531441852162, 50.687300720569596],
            [-3.476993955843399, 50.684199752446261],
            [-3.475488278575206, 50.683538933479106],
            [-3.471033838582265, 50.682961224851937],
            [-3.469837946633267, 50.682244260746458],
            [-3.470453084160657, 50.681862377883782],
            [-3.477278425056678, 50.683476681186917],
            [-3.479604639712459, 50.685335762390309],
            [-3.480441727343247, 50.685305344921701],
            [-3.480793767564901, 50.686055410784775],
            [-3.482327132503406, 50.686601594264289],
            [-3.482530645690395, 50.687803209845072],
            [-3.485080762343865, 50.688445236142094],
            [-3.487808859817529, 50.688893376936747],
            [-3.490222959439058, 50.68827706396614],
            [-3.492714227268745, 50.688621997397888],
            [-3.492741385117133, 50.68889774440408],
            [-3.494786605565165, 50.689861691221452],
            [-3.493873639783146, 50.690447172601743],
            [-3.49358601370146, 50.691153237619311],
            [-3.493616458644376, 50.692022501041357],
            [-3.494336244091894, 50.692752523511871],
            [-3.496014036254913, 50.692975614325626],
            [-3.499564560761776, 50.69420523119323],
            [-3.501756288562779, 50.694336188451189],
            [-3.503247218614256, 50.692431965008105],
            [-3.503640222892181, 50.692634640304192],
            [-3.505395443194327, 50.691427557802776],
            [-3.50676764956168, 50.691127432225272],
            [-3.510856821234187, 50.692240021204476],
            [-3.517734715594893, 50.692496886615778],
            [-3.518764733618842, 50.693044650720594],
            [-3.520884856817041, 50.692537654519036],
            [-3.522803538013167, 50.692588141110406],
            [-3.525075688388883, 50.693670902659349],
            [-3.526817343877851, 50.695331648978936],
            [-3.529373781662427, 50.692828554526223],
            [-3.533694875829255, 50.69273938685199],
            [-3.538127453508397, 50.693418418148234],
            [-3.539077061364337, 50.692929232415111],
            [-3.542984057415143, 50.699420226696297],
            [-3.545140596808391, 50.701657076641695],
            [-3.548960713860997, 50.703831317289712],
            [-3.553660257790968, 50.705178915610894],
            [-3.560034751007122, 50.706162168799757],
            [-3.56272938903567, 50.706913884094106],
            [-3.562808384748705, 50.708522634380344],
            [-3.563505128169338, 50.709626665602499],
            [-3.565094307830674, 50.710605395103428],
            [-3.564859689690964, 50.710875648022181],
            [-3.566185863575174, 50.711228359574804],
            [-3.566878092778013, 50.714189557115326],
            [-3.56868394600249, 50.714438662518106],
            [-3.568662896178572, 50.715123339354925],
            [-3.567004885490283, 50.715357886351399],
            [-3.566212393397597, 50.715083449935612],
            [-3.565733460923835, 50.715419041340219],
            [-3.565555839312903, 50.716720065717119],
            [-3.564612228533062, 50.717863199583952],
            [-3.564768307292698, 50.71999702132446],
            [-3.565112020644367, 50.720108420282024],
            [-3.565414410240258, 50.722252866392118],
            [-3.566066512391876, 50.723114660692239],
            [-3.565718202119081, 50.723801034582578],
            [-3.566668542691688, 50.724470858154689],
            [-3.56783623255736, 50.726175580645439],
            [-3.568396716279929, 50.726242684689382],
            [-3.570203114140348, 50.727807484720664],
            [-3.566879823581128, 50.72740613795191],
            [-3.567625855353221, 50.72831972114308],
            [-3.56600498218243, 50.729257029625927],
            [-3.563045991810188, 50.728776933049168],
            [-3.561853811539694, 50.729655373190162],
            [-3.560662813082621, 50.729718091263152],
            [-3.560561146262379, 50.730112460377391],
            [-3.561647234103693, 50.731964926484693],
            [-3.562720695540457, 50.732285990346753],
            [-3.563016110340984, 50.733370220368762],
            [-3.563813414082785, 50.733652708431315],
            [-3.564418145155063, 50.735597941206628],
            [-3.567353893817021, 50.737363461164527],
            [-3.566004317317488, 50.739645215495372],
            [-3.564696041838552, 50.739981146871294],
            [-3.566972136308423, 50.740743782224584],
            [-3.569010111919174, 50.740995161512572],
            [-3.569653532901381, 50.745019996521229],
            [-3.567522161732382, 50.745542408218228],
            [-3.564701447150596, 50.745414826768901],
            [-3.559956271152383, 50.744702301166768],
            [-3.555914317718482, 50.744846252886269],
            [-3.552945720199021, 50.745473099139979],
            [-3.552010569753884, 50.745141110257038],
            [-3.551625920848822, 50.747654457772093],
            [-3.55032863989226, 50.748804870887355],
            [-3.549059064144615, 50.748220097501104],
            [-3.548199894286047, 50.747313297149041],
            [-3.547445642877782, 50.748985269599522],
            [-3.544050729533638, 50.750926965886904],
            [-3.542089230503652, 50.751803692313338],
            [-3.537354179371228, 50.753139676950425],
            [-3.536117222002535, 50.754199200578284],
            [-3.534938550220108, 50.755958518014054],
            [-3.530527356019408, 50.75698418682191],
            [-3.529030775886545, 50.756567651931228],
            [-3.528829517445131, 50.755482107895887],
            [-3.527213076011769, 50.755170544008543],
            [-3.526206906111443, 50.755314122539367],
            [-3.526331662629964, 50.757666921798773],
            [-3.525855074540826, 50.758314378831287],
            [-3.525407715637554, 50.758378689086449],
            [-3.524051866726497, 50.757667968470358],
            [-3.522477870631099, 50.757573420471026],
            [-3.5154697400371, 50.759198975584731],
            [-3.514633368569948, 50.759555184613703],
            [-3.513916357577522, 50.760798369104492],
            [-3.512724136438011, 50.761464954269812],
            [-3.512266026927243, 50.761109369487706],
            [-3.50740520011412, 50.760616537022869],
            [-3.506846329246091, 50.75866773444821],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000042",
        LAD13CDO: "18UD",
        LAD13NM: "Mid Devon",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-3.468510982636393, 51.028054886849276],
            [-3.463674443451329, 51.02526666520474],
            [-3.461824479059322, 51.025567707778194],
            [-3.459737056084431, 51.025255681347438],
            [-3.454295345496254, 51.026703948808816],
            [-3.450477539783512, 51.025594042457278],
            [-3.447210627430448, 51.025729884572208],
            [-3.44642210115342, 51.025868247658508],
            [-3.446392146943849, 51.027425270785493],
            [-3.445432627625917, 51.028072937358637],
            [-3.440216292778638, 51.029133764298642],
            [-3.436767415006946, 51.030265258386095],
            [-3.430838064714235, 51.030925260138801],
            [-3.429259151701312, 51.030640632346383],
            [-3.426801350909922, 51.031409882152246],
            [-3.425371843635226, 51.03148578555745],
            [-3.424245349364009, 51.031025605858105],
            [-3.421290131498389, 51.030782821487421],
            [-3.418733597801586, 51.029631348573076],
            [-3.417807571294242, 51.028680365212139],
            [-3.415688554506644, 51.027840959854956],
            [-3.41504598097123, 51.027203963937467],
            [-3.415607655549192, 51.025654000138736],
            [-3.415347554402735, 51.025260570709136],
            [-3.41598398789735, 51.024480379105441],
            [-3.415755532961176, 51.023627935612815],
            [-3.412951175690432, 51.021389416417151],
            [-3.414160418107275, 51.019707523111258],
            [-3.407520864046284, 51.019527823436022],
            [-3.402913619144585, 51.02012191387999],
            [-3.399765169143763, 51.019732527787873],
            [-3.389890319619542, 51.019781196301182],
            [-3.380474701487294, 51.018490878202932],
            [-3.379066843492693, 51.01659474960281],
            [-3.378883773604627, 51.015614002919811],
            [-3.38083230514076, 51.013395853210099],
            [-3.380127940485968, 51.011566957038532],
            [-3.38047526761262, 51.008142005572246],
            [-3.380181442320732, 51.007031274154961],
            [-3.380854453625707, 51.006703177084106],
            [-3.382396583125205, 51.004541958205408],
            [-3.381405910830035, 50.999213769173871],
            [-3.381897561524418, 50.998253819074101],
            [-3.381670742677949, 50.997046973399236],
            [-3.382087861729518, 50.99592963073561],
            [-3.383414640135003, 50.994542522444668],
            [-3.382642917370343, 50.991879004224771],
            [-3.383059601372975, 50.991036841480032],
            [-3.382983298447249, 50.98928504956168],
            [-3.381288044909832, 50.9867997196353],
            [-3.380457853503173, 50.984317631480138],
            [-3.380455105762489, 50.9825442834751],
            [-3.379221633819832, 50.980582239598554],
            [-3.378650668251268, 50.977514341043808],
            [-3.372438910254396, 50.977285353298299],
            [-3.369145365439352, 50.978586604359286],
            [-3.366158921684343, 50.978951616226695],
            [-3.364629728496396, 50.978831006935465],
            [-3.362261672843867, 50.978132037609761],
            [-3.359803740018804, 50.978108526620964],
            [-3.355286567524226, 50.979327404087748],
            [-3.351531425178125, 50.979765707950172],
            [-3.350748373371551, 50.980124587251211],
            [-3.339397656960169, 50.98111773334837],
            [-3.335204508190234, 50.982784449561258],
            [-3.333707764259349, 50.98280335137774],
            [-3.331329660493331, 50.981793620584597],
            [-3.330822707071561, 50.981103358774412],
            [-3.327828395570828, 50.97986047085115],
            [-3.327142329039331, 50.979130862873888],
            [-3.325869315277322, 50.979098560910522],
            [-3.323165285440504, 50.977534813197259],
            [-3.322901616197361, 50.977178989079242],
            [-3.3236606370474, 50.976870928882882],
            [-3.322424882540409, 50.976393025285773],
            [-3.323295174944545, 50.976141260958528],
            [-3.322830177985632, 50.975617754562435],
            [-3.323544449405526, 50.974989159962988],
            [-3.323446123226767, 50.974486679205221],
            [-3.321359346923329, 50.972778303147045],
            [-3.320399275925617, 50.972424056512097],
            [-3.32030206044049, 50.971005196895462],
            [-3.319859197918496, 50.970556068312604],
            [-3.318924432587758, 50.970741090030828],
            [-3.317653577071698, 50.970374143845888],
            [-3.317361611054966, 50.971179593330859],
            [-3.31574441047427, 50.969644775055492],
            [-3.312140668651524, 50.967506377300978],
            [-3.305375914507497, 50.964915841604636],
            [-3.301806654400829, 50.963063610403871],
            [-3.300266579021315, 50.961763329502595],
            [-3.301547916646145, 50.960582690736004],
            [-3.300083047240078, 50.958708729001337],
            [-3.298252799835991, 50.95853574906117],
            [-3.298271465034826, 50.958132665145513],
            [-3.295617554388369, 50.95665495497208],
            [-3.29383569633611, 50.955145042512363],
            [-3.292449393763911, 50.954905001577515],
            [-3.290057799997321, 50.955122997020673],
            [-3.284397991369493, 50.953769932922782],
            [-3.276492425299234, 50.950876427940713],
            [-3.272488299519317, 50.949021763358921],
            [-3.264282184819527, 50.946588442404966],
            [-3.262126485318453, 50.944100085947305],
            [-3.256694182183843, 50.943343941591223],
            [-3.25481923755075, 50.941852424459064],
            [-3.244317656245117, 50.942972914186349],
            [-3.240250701273302, 50.9429082766653],
            [-3.232503351086338, 50.944153984502968],
            [-3.228062192190367, 50.943828485148309],
            [-3.226291527349381, 50.943993678901862],
            [-3.218336013024211, 50.94328740747126],
            [-3.198620421481954, 50.94710656232855],
            [-3.188478874417139, 50.948598714446561],
            [-3.184531787331628, 50.948672999181952],
            [-3.171817654095409, 50.947418221934093],
            [-3.166468467786829, 50.947719986746215],
            [-3.16536078341513, 50.9465179330222],
            [-3.163935209700778, 50.945979102684866],
            [-3.16521141043057, 50.944130961920628],
            [-3.170194046130989, 50.939734948964997],
            [-3.171296004148323, 50.936859717710952],
            [-3.172488447528947, 50.935720060391667],
            [-3.173088788627667, 50.931412808554256],
            [-3.173541089701652, 50.930612405940657],
            [-3.172891171669923, 50.928648630158818],
            [-3.173443277553191, 50.926952447486855],
            [-3.172494329919001, 50.925599583161386],
            [-3.176374423174565, 50.92379257310089],
            [-3.177479725236258, 50.923896536158146],
            [-3.18027017546859, 50.922897138737596],
            [-3.179183049924572, 50.920079007202936],
            [-3.179953710755679, 50.919499280509875],
            [-3.180139107049031, 50.918786079581494],
            [-3.182852059332442, 50.916540106503916],
            [-3.184238728095835, 50.914508970139913],
            [-3.186236841808555, 50.91308940669537],
            [-3.187860409948946, 50.910428122106111],
            [-3.180120958504072, 50.908453581635406],
            [-3.177454620301081, 50.908193640948646],
            [-3.175766193040613, 50.907680987772622],
            [-3.172846874154104, 50.907878524061204],
            [-3.170488539850488, 50.907590149315318],
            [-3.171274229370252, 50.906479754570846],
            [-3.171837041489735, 50.904254690375829],
            [-3.17171463831007, 50.902438480467623],
            [-3.17042380408942, 50.900359713642551],
            [-3.167531861957522, 50.900838317898724],
            [-3.163772961759449, 50.900656436251396],
            [-3.162307205769158, 50.900902156785008],
            [-3.159338068468147, 50.90079679658546],
            [-3.156086175425749, 50.900069164971406],
            [-3.154099990248019, 50.897845127480601],
            [-3.15435338484524, 50.897108811643818],
            [-3.153876574710097, 50.895192671299725],
            [-3.152865460614987, 50.893806086477106],
            [-3.147702677433067, 50.891728383737984],
            [-3.145068100900051, 50.891673328369272],
            [-3.144168555740419, 50.891402475887176],
            [-3.142419866799547, 50.889258644340572],
            [-3.151197015697025, 50.886460170227565],
            [-3.165236397197336, 50.884254288171263],
            [-3.163638329816379, 50.882307107108737],
            [-3.165819313202189, 50.881409440345131],
            [-3.166664987837733, 50.88043696055005],
            [-3.168452543676658, 50.880125910139931],
            [-3.170549791292919, 50.878844100344693],
            [-3.176145935207593, 50.877091786775949],
            [-3.17810099282317, 50.878244727783759],
            [-3.18119953677959, 50.877114482376321],
            [-3.182136296665661, 50.876443125023521],
            [-3.187788327188892, 50.877419904256328],
            [-3.187517736022786, 50.878741005357739],
            [-3.191899253537868, 50.878654967645559],
            [-3.196842290168605, 50.877961387634009],
            [-3.198316196958492, 50.877458850110955],
            [-3.20088807163283, 50.878088887228316],
            [-3.201706233118343, 50.877624528198737],
            [-3.205096084042409, 50.87756169191006],
            [-3.207243840637962, 50.877067368081384],
            [-3.208628687349502, 50.877248177171936],
            [-3.214059290990585, 50.8807817425633],
            [-3.215111435478058, 50.882375112582942],
            [-3.217058359164572, 50.883606625597302],
            [-3.218283950372028, 50.885084848190132],
            [-3.218177963209506, 50.886308079808408],
            [-3.215671079553978, 50.886781164767406],
            [-3.215786993638107, 50.887520066570431],
            [-3.218349949687549, 50.887559884222114],
            [-3.21869325946906, 50.888076084788175],
            [-3.217553971645542, 50.892855070394283],
            [-3.220524781061792, 50.893364507002417],
            [-3.221714725150187, 50.893955474282542],
            [-3.225813117518582, 50.894622931487824],
            [-3.227169535934483, 50.894727381743579],
            [-3.228691309892933, 50.894036908654442],
            [-3.230323515609639, 50.894355145392915],
            [-3.231501431828891, 50.895023478390662],
            [-3.233052879548574, 50.895027780428642],
            [-3.234231898534779, 50.894122330077288],
            [-3.234919147917006, 50.892903726165819],
            [-3.238776429464096, 50.890375428669444],
            [-3.243636168858015, 50.889469422286481],
            [-3.243104196537756, 50.888606381823593],
            [-3.244210395178163, 50.88738235820194],
            [-3.245858229712867, 50.887225388801987],
            [-3.247162323808997, 50.887559482325514],
            [-3.249149959859382, 50.88639343424655],
            [-3.251801701541729, 50.883965726995932],
            [-3.25277100878428, 50.881201712866165],
            [-3.253516476095989, 50.880429314515446],
            [-3.252943683052812, 50.87979607375501],
            [-3.253187692318317, 50.879089312574948],
            [-3.254130540240644, 50.878628640882326],
            [-3.256724879941522, 50.878160978584319],
            [-3.259009202431232, 50.87700415285736],
            [-3.262485175217571, 50.873926107364511],
            [-3.265365364858487, 50.872008204069886],
            [-3.268696545348662, 50.870482811006859],
            [-3.27483880137958, 50.86818562842096],
            [-3.278014771684798, 50.86786491139204],
            [-3.28267314494119, 50.866310182613589],
            [-3.283199569592431, 50.863486917931354],
            [-3.279383727130409, 50.861080952743585],
            [-3.27643467811041, 50.859704064328831],
            [-3.275855396124125, 50.859095285519167],
            [-3.276960845220596, 50.858056201940791],
            [-3.281268614377742, 50.855821898546822],
            [-3.284095319475719, 50.854714369651624],
            [-3.28937314585373, 50.853936727880281],
            [-3.291667244508852, 50.854439236884801],
            [-3.293198089731357, 50.854157886717424],
            [-3.297595226763391, 50.85472328201994],
            [-3.306107349741667, 50.854439449070682],
            [-3.31202491923797, 50.855513357166686],
            [-3.318534354076818, 50.854793381077933],
            [-3.319385645998541, 50.856061654413899],
            [-3.3192291427191, 50.856960020381933],
            [-3.321546988009235, 50.857531819791781],
            [-3.325323755616562, 50.856989870918447],
            [-3.328290287785378, 50.857037071748202],
            [-3.32825924453126, 50.85679461558783],
            [-3.331055153175696, 50.856089179919834],
            [-3.330076554299411, 50.852704601439079],
            [-3.329413879061011, 50.851932465183367],
            [-3.329675184333035, 50.85167948317855],
            [-3.330737086985863, 50.85165388626816],
            [-3.331346102103424, 50.85208759300258],
            [-3.332492301146995, 50.852028643529749],
            [-3.339361367867306, 50.849657681282338],
            [-3.343377364035022, 50.847582692961375],
            [-3.343047884607936, 50.846200669298078],
            [-3.348155925934867, 50.846744281965435],
            [-3.348580992390896, 50.844342740210323],
            [-3.345907748699593, 50.842860992589074],
            [-3.344769722449748, 50.841664560535811],
            [-3.342913295635817, 50.843047482591459],
            [-3.34163483872805, 50.840518078470289],
            [-3.342577554428561, 50.837388465920952],
            [-3.342246338372959, 50.836288837878627],
            [-3.340699044213006, 50.835420821826695],
            [-3.341735982128511, 50.834141787426802],
            [-3.343458217957898, 50.833091368579183],
            [-3.343588814908851, 50.832446865362606],
            [-3.344858983575718, 50.831234361482771],
            [-3.344826580406682, 50.830897497648998],
            [-3.342834693741806, 50.831009475312463],
            [-3.342795674235833, 50.830441567107933],
            [-3.341221723187485, 50.828389486902516],
            [-3.34224379657716, 50.827736529644881],
            [-3.343555368855534, 50.825842791850562],
            [-3.350882687295945, 50.82730046436788],
            [-3.353831457100353, 50.826454208529562],
            [-3.356998258285052, 50.826912026205996],
            [-3.355396037834931, 50.8314991026987],
            [-3.355884915539116, 50.833913438431765],
            [-3.362344031531586, 50.831785978659525],
            [-3.364002256494202, 50.832552589724664],
            [-3.366736959680048, 50.829333456446264],
            [-3.367610667176877, 50.829542645753577],
            [-3.367459629926609, 50.827241304455974],
            [-3.367987084366713, 50.823573160823102],
            [-3.366974288681101, 50.820542693362903],
            [-3.361733421960801, 50.821003286563403],
            [-3.359896429972015, 50.814850105202659],
            [-3.359945185810453, 50.811886331796956],
            [-3.36106033021873, 50.811965952141342],
            [-3.361244700167252, 50.812399962790053],
            [-3.362523526942785, 50.812153010044092],
            [-3.363020189800793, 50.811660683497287],
            [-3.366839284077709, 50.812224818380592],
            [-3.368294631960471, 50.810879481363045],
            [-3.373135277524802, 50.810993459742079],
            [-3.376522545696333, 50.810144156894808],
            [-3.378910169083942, 50.810296713298072],
            [-3.37909085533539, 50.80978107355844],
            [-3.380457338343214, 50.810201970201369],
            [-3.380853745473504, 50.810744954655114],
            [-3.383843333858277, 50.810228392772984],
            [-3.386349170339356, 50.809003456133418],
            [-3.388702031205138, 50.808798301294544],
            [-3.391234033800273, 50.807935367620018],
            [-3.39490185759017, 50.807803425525528],
            [-3.397122497307356, 50.807403632725226],
            [-3.399190529720883, 50.806647704045922],
            [-3.402524800821017, 50.806424196231418],
            [-3.405963106072431, 50.805081498881769],
            [-3.410377527756652, 50.804678367305371],
            [-3.412066597217819, 50.805061718500866],
            [-3.410636553407744, 50.805503497747047],
            [-3.412261679055463, 50.806730271647446],
            [-3.413447399775109, 50.80708012751559],
            [-3.41381081613422, 50.806941725642687],
            [-3.416561394832008, 50.808490233460098],
            [-3.417750541863821, 50.810450665566165],
            [-3.418969098470663, 50.811130112634054],
            [-3.419776734353924, 50.811504288590953],
            [-3.422605524471305, 50.811547159511953],
            [-3.423533917633091, 50.812016961909158],
            [-3.424216275636797, 50.811584157839576],
            [-3.425535671180792, 50.81170203594877],
            [-3.425646423076955, 50.811936301115466],
            [-3.426072643265158, 50.812461682591355],
            [-3.427830297851919, 50.81163619626377],
            [-3.429719263846876, 50.812091488257117],
            [-3.431923112344379, 50.810820707112924],
            [-3.433476196986636, 50.809345646715933],
            [-3.434437744141894, 50.80950559270125],
            [-3.436078342051776, 50.808111257674007],
            [-3.437610573372429, 50.808090582221958],
            [-3.437573329081613, 50.807154859304319],
            [-3.438042016568873, 50.806564529464275],
            [-3.439512869173861, 50.805933953679123],
            [-3.439145675881079, 50.805392603747919],
            [-3.439799805216547, 50.80483415185865],
            [-3.439659558182365, 50.803411374641584],
            [-3.440254137627865, 50.802948082654488],
            [-3.440110262106055, 50.802422863000437],
            [-3.440804414330524, 50.802059960639703],
            [-3.441355355634136, 50.802577451340127],
            [-3.444172993629829, 50.801262873605332],
            [-3.444077209778838, 50.800641735946066],
            [-3.443015646356155, 50.800961541248128],
            [-3.443314111763271, 50.800423656659923],
            [-3.445830483369366, 50.800250395568646],
            [-3.446486291428761, 50.799797103788805],
            [-3.449591444292573, 50.799987873027568],
            [-3.451006902983024, 50.799687886323611],
            [-3.45195678404423, 50.800162592064922],
            [-3.454537091938897, 50.800587289106488],
            [-3.456842583979508, 50.802896724566281],
            [-3.457452026850785, 50.802551867710932],
            [-3.458136015734497, 50.802687207867336],
            [-3.457928174140104, 50.803122375662909],
            [-3.458415693054877, 50.803328517885866],
            [-3.460807320700686, 50.80338402011926],
            [-3.462140093177342, 50.80252556160373],
            [-3.463040652113904, 50.802324512504995],
            [-3.464169732883774, 50.800990150814137],
            [-3.466105209855673, 50.800938855751149],
            [-3.466415241045765, 50.800280257501839],
            [-3.465770542255632, 50.799450201938747],
            [-3.466367299402406, 50.798794294690452],
            [-3.468255751427494, 50.798377533984883],
            [-3.468250115970292, 50.798061945689561],
            [-3.467095856209037, 50.797363316160514],
            [-3.468221985661428, 50.796938157107],
            [-3.467984481583845, 50.795653328883603],
            [-3.468509181389177, 50.795598159777789],
            [-3.469007527683892, 50.795969595356631],
            [-3.46970612886606, 50.795577685128698],
            [-3.469505228092915, 50.79527984579515],
            [-3.468953931118591, 50.795301180483349],
            [-3.469159422298042, 50.795021602956012],
            [-3.470106689143907, 50.794867573547059],
            [-3.470138060961438, 50.795867215959312],
            [-3.471864095420874, 50.797150349472354],
            [-3.472408535352725, 50.797226213667841],
            [-3.476157536842448, 50.795110394508164],
            [-3.476624019488747, 50.793974049412668],
            [-3.477305809492143, 50.794041856201773],
            [-3.478217543131987, 50.792673235420779],
            [-3.479465306783838, 50.793006346503951],
            [-3.480394666220391, 50.792467553139922],
            [-3.481679987501038, 50.792686849973094],
            [-3.483285536176651, 50.792040515112177],
            [-3.483158733932576, 50.794130339770462],
            [-3.482005004682605, 50.794484049622945],
            [-3.480086494752147, 50.794479636942064],
            [-3.479432743881834, 50.795300014360215],
            [-3.479110657069427, 50.795120639652239],
            [-3.477925610077477, 50.795652770486818],
            [-3.477698888138418, 50.796382290244516],
            [-3.479179171317583, 50.79684106011468],
            [-3.480735517199221, 50.798448170282718],
            [-3.48073505781293, 50.799376269197396],
            [-3.490267362358803, 50.799970702476031],
            [-3.493938392964297, 50.799432706908171],
            [-3.498637087284149, 50.799304971776259],
            [-3.498918505608384, 50.798964111365876],
            [-3.499483848747311, 50.79920325632731],
            [-3.502503096568527, 50.799054679424124],
            [-3.502223719055547, 50.801051164109346],
            [-3.502649974789066, 50.803942370368162],
            [-3.500178520094152, 50.805960776300253],
            [-3.498584155370182, 50.808826707452091],
            [-3.497143679298606, 50.812294079329902],
            [-3.496736559979441, 50.816964046544904],
            [-3.498415078247118, 50.816804000266274],
            [-3.497575999945526, 50.817777042628293],
            [-3.499007088269228, 50.82141526768855],
            [-3.499458122025237, 50.825123632991655],
            [-3.505394148031599, 50.825642518187074],
            [-3.506039672837713, 50.826773614932662],
            [-3.507350729145703, 50.827405983893321],
            [-3.508070251339583, 50.82514750095855],
            [-3.506938921224577, 50.824054168386851],
            [-3.507145774177121, 50.822365282662631],
            [-3.506403099003887, 50.821875757588266],
            [-3.505255007848561, 50.821758380538192],
            [-3.506545427068986, 50.820563618976294],
            [-3.507515785782264, 50.82032085694361],
            [-3.508491239732261, 50.820676964128282],
            [-3.509641210226297, 50.82045704209667],
            [-3.510024176312026, 50.819389994463215],
            [-3.509700462434726, 50.817408498385845],
            [-3.510629220316779, 50.816319093820155],
            [-3.510021647102757, 50.816627332613535],
            [-3.509127977136574, 50.815811529561635],
            [-3.507276950193174, 50.815285084747849],
            [-3.505711509499613, 50.815559810821149],
            [-3.505040542038271, 50.81508913623221],
            [-3.504982901239609, 50.814400105643394],
            [-3.507836225801787, 50.813829958672592],
            [-3.50769101514271, 50.81223015556651],
            [-3.509432551260812, 50.811653651285077],
            [-3.50930498934973, 50.811263200555508],
            [-3.509822755870322, 50.811473234049998],
            [-3.512058313559845, 50.810988319287056],
            [-3.512431318477224, 50.807859258731604],
            [-3.510988761220649, 50.805028023414309],
            [-3.511455809095734, 50.804548031507679],
            [-3.512452830358598, 50.804702378696319],
            [-3.514103859676569, 50.80217096144365],
            [-3.51676305963622, 50.80001491634718],
            [-3.517326831293117, 50.798931998310003],
            [-3.51780691870694, 50.798989602503433],
            [-3.518359552835189, 50.797432883360671],
            [-3.519861338419802, 50.796747824813941],
            [-3.52151646226532, 50.796460043650967],
            [-3.523890413955932, 50.796939859337826],
            [-3.524248065958035, 50.796723845250071],
            [-3.525317369495355, 50.797315105627668],
            [-3.525867523800971, 50.796735941618266],
            [-3.525933955603229, 50.795421167113957],
            [-3.526124288484215, 50.797517687207325],
            [-3.526668621972799, 50.79754383728794],
            [-3.526410840899037, 50.795424818547325],
            [-3.524742312637678, 50.793685779221519],
            [-3.523914530056908, 50.791967208313551],
            [-3.523773598546437, 50.789204541023913],
            [-3.524696818614056, 50.787012521348636],
            [-3.528062278570698, 50.788893014181106],
            [-3.533922835914203, 50.79087731315574],
            [-3.536469889119601, 50.791275455130979],
            [-3.536743462604528, 50.791613592106202],
            [-3.541690536153817, 50.790911574163822],
            [-3.543180895403989, 50.790240752339159],
            [-3.543395657109313, 50.790988843445113],
            [-3.547503191490054, 50.79129143718702],
            [-3.554304980790786, 50.791631750650531],
            [-3.561514388104217, 50.791002136108311],
            [-3.571996428445231, 50.78854374443096],
            [-3.570729129469997, 50.787051755999791],
            [-3.570426826826513, 50.785524278357848],
            [-3.569162607732305, 50.784162633346476],
            [-3.568980641999782, 50.781015646713776],
            [-3.566996408687807, 50.779496389740025],
            [-3.564108302362321, 50.779408362157817],
            [-3.563199939734707, 50.779051820386073],
            [-3.563337633020398, 50.775014702851664],
            [-3.559175569133444, 50.775037158271118],
            [-3.558517139750528, 50.773619631602948],
            [-3.557911609543652, 50.774774363353835],
            [-3.557201092783833, 50.774989800091838],
            [-3.55590932421218, 50.775085286151111],
            [-3.553718343493033, 50.774496666517038],
            [-3.552113938599871, 50.774787832265581],
            [-3.550077240556447, 50.77457390745252],
            [-3.547401149529852, 50.772992468471834],
            [-3.552099978972832, 50.7711961153557],
            [-3.551337038788873, 50.769654933469582],
            [-3.551381493202138, 50.768683970124556],
            [-3.550559329162707, 50.767451140112591],
            [-3.550593619936861, 50.766386782104099],
            [-3.551429958814712, 50.765305458739419],
            [-3.551094725410451, 50.765245167667892],
            [-3.558346512321011, 50.762341667166943],
            [-3.558726750806847, 50.761836558439782],
            [-3.559546656047142, 50.759842577541264],
            [-3.556415922180637, 50.758900551266862],
            [-3.557009480565267, 50.758329646874827],
            [-3.556791634696985, 50.757960234600141],
            [-3.559582003333682, 50.757664833173749],
            [-3.561657050717154, 50.758370007957289],
            [-3.570528865955324, 50.760246440250654],
            [-3.570924985826216, 50.75823020543698],
            [-3.569372355729153, 50.755726701356373],
            [-3.573546804123105, 50.755659639851274],
            [-3.576287758454459, 50.755082114876096],
            [-3.576023455398038, 50.753966922488146],
            [-3.573867316758453, 50.75266234562104],
            [-3.57800340719926, 50.74977714079953],
            [-3.580459557498553, 50.747152896336743],
            [-3.58429586468143, 50.74498649426156],
            [-3.585467690009702, 50.743884169820021],
            [-3.588066614912401, 50.743992686284493],
            [-3.592122414144538, 50.743300635573668],
            [-3.592244168851807, 50.744842226745902],
            [-3.591834848798036, 50.745018690002887],
            [-3.592177861167978, 50.745478060884075],
            [-3.593123615413253, 50.746489478872917],
            [-3.593549749217456, 50.746348754606778],
            [-3.595299007120512, 50.747644149346442],
            [-3.595941390392962, 50.747776551776248],
            [-3.596577548449839, 50.747809210075829],
            [-3.596647323299501, 50.746860358313718],
            [-3.598362997113574, 50.74704189470382],
            [-3.598857237225285, 50.746487423553816],
            [-3.600910892912837, 50.746354915904782],
            [-3.601950657187294, 50.746003382215676],
            [-3.603210415675125, 50.747444781523782],
            [-3.606217700090178, 50.748833323496207],
            [-3.606698275055562, 50.751276483109038],
            [-3.607596527156371, 50.752792065490354],
            [-3.608765717102657, 50.753318234072189],
            [-3.608852177806857, 50.754227165203922],
            [-3.610073796591341, 50.754338005018397],
            [-3.611222190776347, 50.753967801898966],
            [-3.614220643863868, 50.75405042996551],
            [-3.615652300616233, 50.752356039532593],
            [-3.617063847574346, 50.751973137624347],
            [-3.618373330820714, 50.751097901339797],
            [-3.6199305876773, 50.750995332226374],
            [-3.620052136988915, 50.752052158805824],
            [-3.62120920798617, 50.753085595713053],
            [-3.622187986633606, 50.755767282258503],
            [-3.623158709861936, 50.755549622131447],
            [-3.625022994237025, 50.756202647253005],
            [-3.62739162110886, 50.756229862037841],
            [-3.631856645494085, 50.754857117600423],
            [-3.634592087537931, 50.755078702802933],
            [-3.636296818276417, 50.755746376013725],
            [-3.639719883932689, 50.75527649199249],
            [-3.640888658920067, 50.757237684202721],
            [-3.64060401336353, 50.758260636339102],
            [-3.641486940770331, 50.758614237090214],
            [-3.643919225443288, 50.757379352850755],
            [-3.646421581259982, 50.756730693373733],
            [-3.647948750107879, 50.756897976112953],
            [-3.651797857318996, 50.756328183171078],
            [-3.653570230775919, 50.756412776215242],
            [-3.655973595037731, 50.757101728251932],
            [-3.655948967933187, 50.757448322431706],
            [-3.656697574450849, 50.757207456532939],
            [-3.658614730857949, 50.757415822558919],
            [-3.659310103392035, 50.757914951592483],
            [-3.659102332318753, 50.758133749325872],
            [-3.66181543966745, 50.759636569068235],
            [-3.665944180564865, 50.759912196176479],
            [-3.668724505870782, 50.762697248821809],
            [-3.670254244492762, 50.763088138441823],
            [-3.671777891152816, 50.763983623231596],
            [-3.673123406130544, 50.762587436947364],
            [-3.673028481590706, 50.762192191643848],
            [-3.673886965406926, 50.76177246923934],
            [-3.67373417144259, 50.760828560346305],
            [-3.674790997420525, 50.760405084008639],
            [-3.674647524826084, 50.759762320155673],
            [-3.675164504375411, 50.759707227945491],
            [-3.675295634015252, 50.759329420616268],
            [-3.674460530733804, 50.758972689847354],
            [-3.675067534327041, 50.758540381874191],
            [-3.67518385769392, 50.757787763525869],
            [-3.675843134996922, 50.75774771078332],
            [-3.675536855254323, 50.757389680046401],
            [-3.676213681474512, 50.757325090833433],
            [-3.675962069381268, 50.756830475129554],
            [-3.676599212368898, 50.756726883245655],
            [-3.67609455848987, 50.756570460388367],
            [-3.676811637913445, 50.756402764360189],
            [-3.676588615740311, 50.755994075158235],
            [-3.677373968013371, 50.755713875777005],
            [-3.677384713053677, 50.755419637580744],
            [-3.67838238758149, 50.75560583071681],
            [-3.678632764251183, 50.755312638823021],
            [-3.678305923984565, 50.755132980249215],
            [-3.679556881945561, 50.754275881362553],
            [-3.679214071288944, 50.75412523424675],
            [-3.679340363907661, 50.753455206413442],
            [-3.678513805778783, 50.753097482271642],
            [-3.67818343842257, 50.752343196727509],
            [-3.679158424845346, 50.752134899878264],
            [-3.678969665193729, 50.750940598528764],
            [-3.679424016428396, 50.750524854542164],
            [-3.680341768359674, 50.750421695411653],
            [-3.680125959502321, 50.749937364108803],
            [-3.680768827945861, 50.749838163005123],
            [-3.681563367741576, 50.75013427859642],
            [-3.682748797040744, 50.751566008577988],
            [-3.687669984799911, 50.75248773863094],
            [-3.688972008791484, 50.752425713565223],
            [-3.691830701662562, 50.753780935888649],
            [-3.694297848401467, 50.752455451255472],
            [-3.701912363616342, 50.750858822097001],
            [-3.70832488927437, 50.748734312233211],
            [-3.710043259412688, 50.742713200138674],
            [-3.71822383986848, 50.737317349943211],
            [-3.719639632729223, 50.734647900615769],
            [-3.722731339814626, 50.731506699135011],
            [-3.723325635885437, 50.728163138483914],
            [-3.726476149170736, 50.727564314638663],
            [-3.734212451295703, 50.723801707460382],
            [-3.737496841577927, 50.72353964531397],
            [-3.734971700092558, 50.721726403605821],
            [-3.73257029725981, 50.718730423684768],
            [-3.727756642929496, 50.717424981994363],
            [-3.7254376656496, 50.716307272396023],
            [-3.724223402985699, 50.715605764758884],
            [-3.729223653887638, 50.714483938986021],
            [-3.731037422543598, 50.713654793300925],
            [-3.73321887516639, 50.71142885755431],
            [-3.734852588391239, 50.708336866101007],
            [-3.73389776231005, 50.706527194100808],
            [-3.73384429497226, 50.70457909705852],
            [-3.735576157848599, 50.703193498165398],
            [-3.736261519898148, 50.703306499825537],
            [-3.736443866879884, 50.703872172684427],
            [-3.737500709746189, 50.704073163645333],
            [-3.737699373760087, 50.704466815229914],
            [-3.739059903599386, 50.704760393203429],
            [-3.739894117588584, 50.705526779148464],
            [-3.741472990682513, 50.705481610981764],
            [-3.743305487166088, 50.70597403710638],
            [-3.745782493327257, 50.707497330713508],
            [-3.747750972040516, 50.707872533205801],
            [-3.747707768103105, 50.708196048324147],
            [-3.748334054261524, 50.708050852094757],
            [-3.750259148903099, 50.708629017367421],
            [-3.750731067344939, 50.709206507252276],
            [-3.750830661455574, 50.710510869396174],
            [-3.752246112638721, 50.711814340248665],
            [-3.751410961131345, 50.713729021981052],
            [-3.751565212464231, 50.714636846921202],
            [-3.753649644495202, 50.716059750844856],
            [-3.755310143175774, 50.717886516630138],
            [-3.755445265964255, 50.71934142957231],
            [-3.754993816954893, 50.719960686264571],
            [-3.756851458109399, 50.721144123626715],
            [-3.756948637115958, 50.722681447202532],
            [-3.757969738879376, 50.722973627589035],
            [-3.759255103987685, 50.724003774479947],
            [-3.761956806147396, 50.724565527134473],
            [-3.764190684518893, 50.725985984673656],
            [-3.765871250656532, 50.725839122047155],
            [-3.766241125582579, 50.727573760856686],
            [-3.783513632153275, 50.722839150423553],
            [-3.793459010065344, 50.719079223807057],
            [-3.799871097246249, 50.717420892146521],
            [-3.803788246432309, 50.716835106545453],
            [-3.809389303266039, 50.716498259943378],
            [-3.818578772157255, 50.716973082599495],
            [-3.819590217675257, 50.719130141555283],
            [-3.819857495139663, 50.72101911418595],
            [-3.822110799474224, 50.723227801769951],
            [-3.82140976088993, 50.724367452071014],
            [-3.822468525352535, 50.726385244711288],
            [-3.82543148769813, 50.730290646372801],
            [-3.82750942043523, 50.729104209530696],
            [-3.831824811464431, 50.727850213277861],
            [-3.833826452839213, 50.730558177152112],
            [-3.836276193688163, 50.732180719344584],
            [-3.83379520208676, 50.733531041916294],
            [-3.832132809661342, 50.734036541778636],
            [-3.831172067349632, 50.73501934660645],
            [-3.830613226000903, 50.73504341406629],
            [-3.828587222685395, 50.736257868909995],
            [-3.827303687161376, 50.738672081476125],
            [-3.82469026059374, 50.741042350924218],
            [-3.825054153268146, 50.741178751846689],
            [-3.824681011105596, 50.741533543141728],
            [-3.824960043767595, 50.742184803993808],
            [-3.824208265011098, 50.743264105001117],
            [-3.824553636221051, 50.743908032986013],
            [-3.823056036619675, 50.74560246402131],
            [-3.822843133134315, 50.74774805319899],
            [-3.822464735977148, 50.74800578872],
            [-3.822657542610415, 50.749028036610611],
            [-3.822181329509972, 50.749687512528212],
            [-3.821686885034139, 50.749695240539268],
            [-3.821387887495849, 50.750172972289285],
            [-3.820966405858043, 50.750051849075888],
            [-3.82118554109668, 50.750292150257295],
            [-3.820586664341187, 50.750494865888079],
            [-3.82069495768033, 50.751057068548832],
            [-3.819726157707201, 50.75170084339986],
            [-3.819132580756224, 50.75306453226991],
            [-3.818694762696587, 50.753144211223997],
            [-3.818851703858106, 50.753937689092098],
            [-3.818316426035623, 50.754026981334498],
            [-3.818553615255837, 50.754403707210528],
            [-3.818056634665061, 50.754420452633987],
            [-3.818289840124843, 50.754877283457255],
            [-3.817804561634863, 50.754866864699977],
            [-3.818054005150858, 50.755084215211184],
            [-3.816830592197598, 50.755856046059399],
            [-3.816879843918039, 50.756358915242494],
            [-3.814817457794096, 50.756848810141385],
            [-3.814440029101123, 50.757464445256943],
            [-3.813762776047507, 50.757623377284119],
            [-3.814050595828771, 50.757916583986969],
            [-3.813072398148726, 50.757846363382114],
            [-3.812736703813324, 50.758185242976765],
            [-3.813063896846658, 50.758360024881355],
            [-3.812280301347211, 50.75826248839585],
            [-3.81201974327481, 50.758454502983135],
            [-3.812432505723511, 50.758605472796525],
            [-3.811982163478455, 50.758582794886401],
            [-3.811941435332893, 50.758886509065128],
            [-3.810944197393917, 50.758947871622134],
            [-3.810713678989012, 50.758559335551972],
            [-3.810013918463868, 50.758870585421825],
            [-3.809009883129552, 50.758755764304823],
            [-3.808286206765602, 50.759219365007546],
            [-3.807674955666428, 50.758995013789068],
            [-3.807645355958735, 50.759220310285841],
            [-3.806854476579891, 50.75930182090579],
            [-3.806712229679831, 50.758958675135005],
            [-3.80582001625683, 50.75902286283381],
            [-3.806214513304502, 50.759730835097542],
            [-3.807353302491783, 50.760435363526234],
            [-3.807361595136516, 50.759988258063323],
            [-3.811302642075775, 50.760005335037462],
            [-3.81470731133319, 50.760891308019325],
            [-3.814485358281046, 50.761238314701139],
            [-3.813837638727289, 50.76124569624951],
            [-3.813017895983291, 50.762268417973324],
            [-3.815000912502835, 50.763494853426799],
            [-3.816075006708733, 50.76380459045869],
            [-3.819699774330427, 50.769329435693706],
            [-3.820591367239941, 50.771691598131405],
            [-3.821663631470863, 50.773189353804717],
            [-3.822567673283596, 50.773714834641147],
            [-3.822934610649187, 50.775309040609457],
            [-3.825109618427033, 50.776928017512454],
            [-3.824270602004323, 50.778184948209336],
            [-3.822337672639105, 50.779290796935754],
            [-3.8220759888551, 50.779784132376115],
            [-3.822562401748532, 50.780400677890988],
            [-3.82202143983339, 50.78100809882983],
            [-3.8270683504464, 50.780672807529477],
            [-3.845228499466716, 50.78131944509321],
            [-3.845552957030713, 50.781597605259471],
            [-3.843775765156763, 50.782109573017308],
            [-3.842899088356531, 50.78311991315136],
            [-3.842510317901891, 50.783046015165283],
            [-3.842507900200629, 50.783417484999916],
            [-3.84152850405417, 50.783800791876146],
            [-3.841971850688088, 50.784252457343264],
            [-3.841494960133312, 50.784679986562644],
            [-3.84181362575594, 50.784810755092202],
            [-3.841161613582976, 50.786341853037975],
            [-3.841911839083592, 50.786515270200141],
            [-3.841229740017196, 50.786848010436557],
            [-3.841336886863402, 50.78715569466997],
            [-3.839841338233032, 50.787512061945208],
            [-3.839839047971495, 50.788682151450175],
            [-3.838594813828075, 50.790237865436218],
            [-3.839465343906093, 50.790366232742244],
            [-3.839456509288426, 50.790610995005828],
            [-3.83914279879023, 50.791113283374841],
            [-3.838039955219783, 50.791501204560355],
            [-3.837776939592799, 50.792066543696642],
            [-3.836315696988342, 50.792144425177952],
            [-3.836408689516685, 50.792706852049463],
            [-3.835881715850785, 50.792982256584914],
            [-3.836211160223021, 50.793857531020855],
            [-3.837020189145471, 50.79375485346398],
            [-3.838143225265565, 50.794964765875825],
            [-3.837295469822563, 50.795202064159326],
            [-3.838866197720087, 50.797296168567243],
            [-3.839777081836475, 50.800326992219617],
            [-3.842534417276241, 50.800397674163783],
            [-3.843327734465076, 50.807608694678677],
            [-3.844149953074168, 50.809240600628335],
            [-3.844376109501604, 50.811450316071877],
            [-3.844086975176793, 50.812362328912656],
            [-3.842871910171093, 50.813300667679883],
            [-3.839380807346827, 50.81747928078299],
            [-3.838966729166525, 50.818141433269794],
            [-3.839493701725369, 50.818834611834056],
            [-3.839858106034935, 50.81849790474714],
            [-3.842349772160011, 50.818608761117218],
            [-3.845678216689048, 50.817941881033562],
            [-3.848180935479254, 50.818261086040771],
            [-3.848849422714984, 50.817861073740708],
            [-3.849812709418249, 50.81787997406181],
            [-3.85187670115673, 50.817300415968916],
            [-3.852559571796549, 50.81744425832683],
            [-3.853481802648915, 50.819186928195215],
            [-3.854168483260312, 50.819676949046666],
            [-3.859864504845794, 50.821286018297279],
            [-3.86173545696959, 50.820635617911222],
            [-3.866594057583309, 50.820746821315012],
            [-3.871250390307484, 50.819988700348908],
            [-3.874319507187248, 50.819164178264828],
            [-3.877730240518839, 50.818895268673913],
            [-3.88149338628201, 50.821495793161745],
            [-3.882442503999016, 50.824795477105638],
            [-3.881432277028913, 50.824919699849829],
            [-3.880916073558536, 50.826171824501039],
            [-3.88341473052879, 50.825984908098327],
            [-3.884642156015824, 50.826387774431417],
            [-3.89008381953334, 50.829210908278043],
            [-3.893413929959158, 50.831670575422187],
            [-3.89577550545971, 50.831844461069345],
            [-3.897420488671786, 50.833537267486442],
            [-3.899644495687247, 50.834517331172847],
            [-3.897597948491702, 50.835181088078905],
            [-3.897527413297018, 50.835542874393909],
            [-3.896440773321968, 50.835958958570956],
            [-3.89635917272295, 50.836468417122582],
            [-3.897028411865274, 50.836685969034825],
            [-3.895911724272379, 50.837272513536789],
            [-3.896462865316772, 50.83793986365658],
            [-3.895885766332547, 50.838699301526376],
            [-3.892264732032687, 50.840122411468641],
            [-3.89183480607127, 50.840660000157349],
            [-3.889095217117277, 50.840949023577629],
            [-3.888048845035421, 50.842296998510555],
            [-3.886846806590127, 50.842675288094277],
            [-3.886394608317761, 50.843578350934017],
            [-3.885076850968775, 50.844540370199816],
            [-3.885574719899426, 50.845156469483129],
            [-3.884978357014103, 50.845515054210708],
            [-3.885823559958219, 50.845514883234372],
            [-3.886174428648328, 50.845902224483815],
            [-3.885086794700705, 50.846900992453278],
            [-3.883875842707409, 50.847273098995743],
            [-3.884283137679299, 50.848179356223504],
            [-3.885456528048299, 50.847474195502762],
            [-3.889621316719568, 50.846516451130377],
            [-3.890289551558562, 50.846600955013933],
            [-3.890621719255153, 50.846981391338772],
            [-3.891011189248992, 50.846671099174443],
            [-3.891745161017308, 50.846940693760111],
            [-3.893014825928092, 50.846617016351509],
            [-3.894108674034329, 50.847564258807068],
            [-3.89558310082821, 50.847385620648652],
            [-3.896591990947142, 50.847882748557467],
            [-3.899522376750069, 50.847525691485693],
            [-3.899684951536154, 50.8478558024363],
            [-3.900720040579842, 50.847703139542119],
            [-3.901422947342983, 50.848115276610557],
            [-3.903523033475031, 50.847951519612636],
            [-3.903852398563729, 50.851748584776182],
            [-3.904885491906366, 50.853114015782559],
            [-3.904471927408261, 50.854505762385777],
            [-3.904805096361504, 50.855743219776421],
            [-3.907036628107501, 50.858723169584401],
            [-3.907501589274187, 50.858626532554013],
            [-3.908027330752925, 50.859496596360295],
            [-3.909754546962835, 50.860766990264125],
            [-3.918995199838478, 50.862842136004517],
            [-3.921185480488403, 50.865997869858667],
            [-3.922719336074343, 50.866802698044879],
            [-3.923546931196761, 50.86664156283075],
            [-3.924683408425309, 50.867837837175863],
            [-3.92505715581468, 50.867733641873166],
            [-3.925909027055219, 50.869609110446689],
            [-3.917533874042177, 50.871798472242645],
            [-3.91166377232474, 50.87370788270718],
            [-3.907401846474801, 50.873518625534757],
            [-3.906592357049867, 50.874057973084959],
            [-3.904117888721457, 50.874794475599543],
            [-3.902534817789827, 50.876513764868037],
            [-3.89939363141103, 50.877044293955414],
            [-3.898553494261265, 50.877532820949739],
            [-3.899940257504999, 50.881326158503548],
            [-3.899565434729559, 50.88291060723099],
            [-3.900389952930645, 50.884586145716867],
            [-3.899952134511136, 50.885496215050892],
            [-3.902483102374081, 50.885886659568115],
            [-3.90801669406078, 50.885124494730555],
            [-3.907730083954479, 50.885312647076326],
            [-3.908747033248443, 50.88674845423386],
            [-3.910636402268753, 50.887925349157108],
            [-3.912709239725125, 50.889864547173552],
            [-3.914830730606515, 50.894718578591302],
            [-3.915431052360962, 50.895174585268201],
            [-3.917186804219766, 50.895128664283092],
            [-3.920225773269588, 50.897722762194647],
            [-3.920351771483132, 50.899333209543251],
            [-3.917646619540848, 50.899812976849418],
            [-3.916645710863452, 50.90044367107275],
            [-3.916824104712105, 50.90066647565753],
            [-3.915885596405323, 50.901016442907952],
            [-3.916090084065013, 50.901597657425015],
            [-3.914845115977297, 50.90220895991682],
            [-3.914818350651202, 50.902700439393215],
            [-3.913082640958722, 50.903380929207465],
            [-3.912265038912399, 50.903039090188429],
            [-3.911893857445909, 50.903907639790745],
            [-3.907010597705715, 50.904313102941927],
            [-3.904136674973994, 50.905093892833449],
            [-3.902951782009327, 50.904185101891201],
            [-3.90258900510758, 50.903380711938979],
            [-3.901685669484593, 50.90348087338139],
            [-3.901484333719588, 50.903950012064627],
            [-3.900573825643192, 50.904363251804504],
            [-3.899837635695072, 50.905075824560107],
            [-3.90077762873372, 50.905525476233123],
            [-3.900591741042441, 50.906060013273276],
            [-3.899380196144867, 50.905508658134401],
            [-3.899574398124064, 50.906051396000535],
            [-3.898724734569364, 50.906458234413073],
            [-3.89829471204282, 50.905782631910256],
            [-3.897656925779129, 50.905733648378693],
            [-3.898088561108661, 50.906274326121938],
            [-3.897287555554572, 50.906199216299754],
            [-3.895617897501766, 50.906869376499749],
            [-3.894926371200816, 50.906758297200746],
            [-3.893948964868997, 50.907733757759388],
            [-3.893134081978875, 50.907352170228378],
            [-3.892099946143763, 50.907841993328582],
            [-3.89090420336424, 50.907047473147479],
            [-3.890611760806608, 50.907833735893234],
            [-3.889888325852147, 50.908023522484626],
            [-3.889444258414359, 50.907630507658311],
            [-3.888538674079396, 50.908029183128896],
            [-3.887708017430737, 50.90757316816773],
            [-3.887609832242245, 50.907115194802174],
            [-3.88685900503276, 50.907471783126894],
            [-3.88495223637214, 50.907382984869081],
            [-3.88348496979915, 50.90665932562549],
            [-3.881792398060338, 50.906766671541455],
            [-3.879056422190276, 50.906112871495374],
            [-3.875631422559648, 50.906715664945942],
            [-3.874813005225967, 50.907870073516726],
            [-3.873818532527358, 50.907900434823951],
            [-3.869127977950257, 50.909299475932677],
            [-3.867333517233292, 50.909174415982456],
            [-3.865229737255458, 50.908570430368755],
            [-3.861766424382285, 50.90697455331518],
            [-3.860140234831084, 50.906750469850948],
            [-3.858190068717324, 50.906891256646475],
            [-3.856679886215049, 50.907615874334041],
            [-3.856184281690899, 50.907207366324933],
            [-3.85419125584709, 50.908024167677659],
            [-3.8495475943096, 50.908396433110831],
            [-3.847782528833795, 50.908221143687356],
            [-3.847480038824514, 50.907838320006078],
            [-3.846945272761136, 50.908055429785101],
            [-3.847396303311083, 50.908309996887127],
            [-3.846113126470583, 50.908593806936857],
            [-3.844123253294398, 50.907984942344889],
            [-3.843311624265453, 50.908251376619759],
            [-3.841512338698789, 50.90789306910338],
            [-3.839091300141719, 50.908504121410701],
            [-3.839656299385284, 50.908114798786279],
            [-3.8375274750366, 50.907695073626734],
            [-3.834309116297189, 50.908649531904871],
            [-3.834034854142778, 50.90822216302081],
            [-3.832780434994482, 50.908153735345202],
            [-3.832491981632701, 50.90783540490866],
            [-3.831621468841173, 50.907726761447464],
            [-3.831705010354034, 50.907246106418995],
            [-3.830046996148426, 50.907476266191871],
            [-3.83059066656806, 50.907085523542776],
            [-3.829993840604416, 50.906882641500736],
            [-3.827455150794648, 50.907361296332461],
            [-3.827107392511304, 50.908832651264355],
            [-3.824881517402019, 50.910002433475043],
            [-3.823212176054104, 50.911547493541043],
            [-3.821921702138774, 50.912233150690248],
            [-3.823358743083237, 50.913513837627882],
            [-3.820921647778485, 50.914310022321409],
            [-3.816774578912176, 50.914597685577704],
            [-3.817007916605927, 50.914123703832956],
            [-3.813236159553701, 50.913160726230572],
            [-3.811205769838466, 50.911612132493666],
            [-3.81420118624906, 50.909370344040688],
            [-3.815354075357325, 50.90701235980881],
            [-3.815924943912957, 50.906623061678758],
            [-3.81593985683513, 50.905836814901924],
            [-3.817616393070749, 50.904950055763784],
            [-3.818047864545345, 50.904342581861997],
            [-3.818235305643312, 50.902984369891485],
            [-3.816889322794258, 50.901802028304644],
            [-3.816458169168853, 50.90201558629537],
            [-3.815160550700559, 50.901891884006524],
            [-3.815305639721352, 50.90295263619668],
            [-3.813268035700093, 50.904026638039795],
            [-3.812922399242607, 50.904575203452168],
            [-3.808908495509058, 50.904094294020773],
            [-3.805949808913263, 50.902726370893461],
            [-3.802457877677202, 50.902755175590798],
            [-3.800966803984851, 50.901856383403029],
            [-3.798520876649781, 50.901199822447786],
            [-3.795277381811508, 50.901472798513247],
            [-3.795298963193761, 50.901996775253828],
            [-3.794336776317344, 50.902124888924796],
            [-3.792905879868577, 50.903601994642706],
            [-3.7904960107868, 50.903884509702152],
            [-3.789171704521606, 50.903769021878638],
            [-3.787102925322773, 50.902485903038027],
            [-3.785549900964435, 50.902450319469359],
            [-3.785082179779937, 50.902157998650068],
            [-3.783300019824566, 50.902490117585479],
            [-3.781998141324211, 50.901619670086355],
            [-3.781508112180651, 50.900742213420614],
            [-3.782817388617722, 50.898829133588364],
            [-3.781155568516497, 50.89724381339559],
            [-3.779463429396049, 50.894002369360898],
            [-3.777473704356523, 50.893469714370184],
            [-3.775139470196098, 50.892327126392502],
            [-3.774563115851752, 50.89152919518105],
            [-3.774616048289999, 50.889742324025377],
            [-3.76801365318051, 50.889046641081997],
            [-3.768181492872839, 50.888860635034717],
            [-3.766829509363343, 50.888226402974922],
            [-3.764758863978026, 50.885504894222585],
            [-3.762396126052725, 50.883249116315511],
            [-3.75984375273362, 50.879840514259271],
            [-3.760784311856899, 50.876810875219022],
            [-3.758662267353183, 50.876589268629324],
            [-3.757375436810421, 50.876804711145049],
            [-3.756994641531299, 50.876393157430563],
            [-3.755105632848959, 50.876471050626989],
            [-3.753563736887167, 50.876821589412636],
            [-3.753140984066133, 50.877260519025626],
            [-3.751552429261184, 50.877656698421717],
            [-3.751327338408949, 50.878022506318914],
            [-3.750753026092496, 50.877907917199927],
            [-3.749190808523593, 50.878515010984792],
            [-3.749724853653738, 50.87933798245637],
            [-3.749404794613132, 50.880053250222517],
            [-3.749907984164237, 50.881038562291714],
            [-3.749391721330639, 50.882474435129907],
            [-3.744760567876571, 50.885645552542115],
            [-3.744370496548195, 50.886354657408425],
            [-3.742715294639598, 50.88691808551723],
            [-3.740691716796036, 50.887097577718443],
            [-3.740313446248134, 50.887438668338298],
            [-3.740555733094546, 50.888255239390894],
            [-3.742617221838576, 50.888823422552996],
            [-3.743438616850766, 50.889687995547767],
            [-3.74478701965811, 50.890309960131638],
            [-3.738801791736095, 50.890866048351974],
            [-3.735426371151095, 50.890104205892065],
            [-3.734665257004497, 50.890666808585301],
            [-3.733714584214856, 50.892289827919342],
            [-3.734834739419246, 50.894538577780345],
            [-3.734887653977982, 50.895617879093926],
            [-3.73586647384279, 50.896356956522872],
            [-3.735718611527567, 50.898537317697127],
            [-3.737012942918727, 50.900376960846557],
            [-3.737000879439749, 50.901122679403393],
            [-3.735946794221378, 50.90184253385695],
            [-3.73622545839321, 50.902604611556008],
            [-3.735035822610749, 50.90323744098589],
            [-3.734228002954542, 50.905304400113167],
            [-3.732168995717891, 50.906007671732581],
            [-3.731503732786265, 50.907091334232952],
            [-3.729676083124515, 50.907746162243853],
            [-3.729216138315511, 50.910188340308899],
            [-3.728368752600554, 50.90996886767001],
            [-3.728038600640798, 50.910771454285403],
            [-3.725563454846615, 50.90907868183043],
            [-3.725600985461546, 50.909747221713587],
            [-3.725101159154898, 50.909963253289597],
            [-3.72214391990782, 50.910195784802859],
            [-3.720758536810774, 50.910584041137355],
            [-3.720327499902362, 50.910274734103872],
            [-3.719186165870854, 50.910608112827568],
            [-3.717062333278778, 50.910528762198446],
            [-3.712609389279251, 50.911442248415625],
            [-3.708442756930333, 50.911399893501425],
            [-3.706328012163878, 50.910752744163851],
            [-3.703465666417237, 50.91101668891951],
            [-3.697674316033883, 50.910614542726229],
            [-3.698512497507161, 50.913007123856033],
            [-3.69301536283538, 50.914249811312317],
            [-3.686903039700463, 50.914932763196369],
            [-3.681073463375914, 50.916794821347871],
            [-3.677246750653143, 50.917513569811014],
            [-3.676681040913984, 50.918042401272118],
            [-3.675790052454267, 50.918031815024001],
            [-3.673638753174211, 50.918978185273389],
            [-3.664399353666536, 50.920685902085779],
            [-3.658491670451766, 50.920556857587279],
            [-3.654148613482227, 50.920896375526482],
            [-3.650106467427329, 50.921659550416123],
            [-3.643793825461043, 50.921830459895141],
            [-3.642905758772784, 50.921258410773433],
            [-3.640999586503488, 50.918034214355451],
            [-3.634184756792747, 50.918801613782833],
            [-3.632552312952464, 50.91988204772688],
            [-3.627470178111026, 50.919594195585304],
            [-3.625339112914678, 50.921559205415818],
            [-3.624014317422557, 50.921840243983404],
            [-3.620568166226493, 50.921918717728758],
            [-3.619312375694155, 50.922508106988452],
            [-3.617191282715762, 50.924364010176447],
            [-3.615122653030612, 50.925110300408242],
            [-3.614297525201733, 50.926388840060078],
            [-3.612616846982334, 50.926589235040041],
            [-3.610487338960376, 50.928749994543622],
            [-3.609455259163715, 50.931582658088892],
            [-3.608459111265704, 50.93290308120968],
            [-3.6001879923616, 50.939374846024393],
            [-3.596678832547199, 50.943012019152775],
            [-3.595747460192201, 50.945160600737296],
            [-3.596036721739562, 50.947205260223143],
            [-3.598081876821719, 50.950510101268286],
            [-3.598034063994446, 50.952643902606319],
            [-3.598778474010117, 50.954089682309579],
            [-3.598423907206908, 50.954959666368609],
            [-3.600861298665484, 50.955327360060693],
            [-3.601122099470182, 50.956245569063299],
            [-3.600681327636047, 50.959080818346244],
            [-3.601626138302611, 50.960924021115012],
            [-3.601377876424595, 50.962317747204416],
            [-3.60046707902155, 50.963453463366008],
            [-3.600822205895258, 50.963717486115314],
            [-3.59906521046085, 50.965012274911793],
            [-3.598766681757367, 50.966147683786389],
            [-3.597830657316075, 50.967216275896803],
            [-3.599344647776207, 50.967384401086477],
            [-3.601665300597743, 50.968520783070844],
            [-3.605915835909848, 50.967837385707469],
            [-3.608101094696571, 50.967935010698753],
            [-3.610980744390402, 50.968590475796212],
            [-3.612702792184448, 50.968348173597889],
            [-3.615416553484033, 50.967380781551569],
            [-3.616693236095344, 50.96760322524888],
            [-3.618275962090479, 50.968394269515926],
            [-3.619553084503157, 50.968177816326161],
            [-3.620035702723246, 50.974270189072413],
            [-3.619647099681951, 50.978698344527082],
            [-3.620084488984471, 50.978740839291071],
            [-3.621083112971607, 50.980295361397339],
            [-3.62298928462728, 50.983360680591545],
            [-3.628648366259971, 50.989228069049013],
            [-3.628726053121663, 50.991528295112026],
            [-3.627882358006656, 50.993641722712574],
            [-3.627219484902736, 50.994490007327386],
            [-3.626634756220788, 50.994598874684804],
            [-3.62781222788889, 50.995668822312815],
            [-3.628726606937343, 50.999764072870981],
            [-3.628799037011814, 51.005778470846501],
            [-3.62285240584808, 51.005003319788933],
            [-3.620024541957537, 51.000627026545871],
            [-3.614582133905615, 50.998875918564856],
            [-3.611715887199185, 50.995249970585888],
            [-3.611772028952625, 50.994813935504567],
            [-3.60808421211972, 50.995066215773598],
            [-3.607213304022976, 50.994771543940054],
            [-3.60531055826809, 50.994927212915414],
            [-3.605985621107736, 50.993883739430984],
            [-3.607613161700859, 50.99277589414703],
            [-3.606564210570218, 50.991574477996956],
            [-3.606826255013212, 50.990745317059982],
            [-3.605101413715812, 50.990229457744761],
            [-3.601991284754218, 50.991068941066594],
            [-3.600445414408039, 50.992026305348546],
            [-3.600514839749977, 50.993505597418803],
            [-3.599698504175353, 50.994919685767414],
            [-3.599919639759066, 50.995175654063381],
            [-3.598760846152499, 50.995666351957723],
            [-3.600492888129072, 50.99866516470459],
            [-3.601765993369158, 50.999671110580991],
            [-3.601886702375594, 51.000361913306833],
            [-3.603853953748893, 51.00132593905564],
            [-3.604292085826608, 51.002334327945363],
            [-3.606208281201214, 51.003343081180482],
            [-3.607343985953723, 51.004448879491974],
            [-3.60575054925858, 51.006060753206967],
            [-3.603191255536685, 51.007238016645076],
            [-3.591630235990908, 51.006170395506373],
            [-3.578234884400453, 51.005593166756611],
            [-3.576526747473262, 51.006249338332701],
            [-3.576472169128357, 51.005354378827242],
            [-3.570967764652338, 51.0047306941429],
            [-3.569169424245581, 51.005039045502443],
            [-3.563460489315256, 51.004941143422329],
            [-3.55960981437232, 51.005256118493371],
            [-3.554293058873652, 51.00461025790316],
            [-3.550027901879264, 51.004464599115352],
            [-3.548114851060957, 51.004756162129489],
            [-3.541977164247421, 51.004233930810265],
            [-3.535402472654377, 51.004587635877456],
            [-3.534018084060383, 51.00422992364792],
            [-3.535033865583729, 51.003352360128737],
            [-3.527548446959131, 51.005526959318765],
            [-3.52702662791879, 51.006086845554343],
            [-3.527308850321185, 51.007648817348397],
            [-3.526678571476334, 51.009507793274992],
            [-3.526260771974415, 51.009764154944676],
            [-3.523220126072998, 51.009804747237574],
            [-3.522605414763579, 51.010795683893932],
            [-3.523016522962752, 51.012988183400985],
            [-3.51886600473262, 51.014800334804313],
            [-3.520685285970771, 51.015851311974856],
            [-3.520549180055054, 51.016658844928763],
            [-3.521724782906973, 51.017903436459576],
            [-3.520315700466396, 51.018935280796441],
            [-3.520975183673669, 51.020832283526765],
            [-3.51935563839086, 51.022538622133418],
            [-3.520477577566651, 51.024100472150465],
            [-3.520413037878832, 51.025875602817536],
            [-3.519922050628522, 51.025905373649167],
            [-3.519547642413456, 51.025401248990157],
            [-3.514834158406088, 51.025555093828864],
            [-3.513080851085104, 51.025880876140647],
            [-3.509734981037778, 51.027049171222899],
            [-3.505034852884161, 51.029910190667685],
            [-3.502946924536983, 51.030347144890143],
            [-3.495577237958853, 51.031025361474761],
            [-3.488511438921686, 51.032425860169845],
            [-3.484991627998371, 51.033718858132538],
            [-3.48367619202811, 51.033838089855898],
            [-3.478265641964643, 51.031568548874631],
            [-3.475324347742286, 51.029664183420913],
            [-3.472444162544517, 51.029185233909658],
            [-3.468510982636393, 51.028054886849276],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000043",
        LAD13CDO: "18UE",
        LAD13NM: "North Devon",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-3.785407524763853, 51.246393907931107],
            [-3.784028639272455, 51.245677530945109],
            [-3.784129964065791, 51.245185878547616],
            [-3.782379739323555, 51.243942770431026],
            [-3.782246067053137, 51.243487073259232],
            [-3.780992832335248, 51.243089789721324],
            [-3.781034475247781, 51.242869731594723],
            [-3.780772825180739, 51.243031987366805],
            [-3.780022266449857, 51.24207218626335],
            [-3.777917551782912, 51.240881167087352],
            [-3.777680288175158, 51.241007074239874],
            [-3.776032619184563, 51.23973983189876],
            [-3.77328180495421, 51.238729398956451],
            [-3.771229626260831, 51.238487103316217],
            [-3.771022028945009, 51.238080174523276],
            [-3.76973691719772, 51.237667066854705],
            [-3.764494606075958, 51.237015131073257],
            [-3.758342094424838, 51.23739642523028],
            [-3.755470302179917, 51.236806457446107],
            [-3.754996442861716, 51.237077954015916],
            [-3.754703239112118, 51.23675141749117],
            [-3.752635353897752, 51.236920001959035],
            [-3.751190519762102, 51.236369696006001],
            [-3.748997497645115, 51.236184874593199],
            [-3.7482324911845, 51.23663695389191],
            [-3.747786789509054, 51.236518613314423],
            [-3.748002672612802, 51.236356216092958],
            [-3.746900777672102, 51.236310623460525],
            [-3.746702309269532, 51.235722761555408],
            [-3.746168660405346, 51.235853928293977],
            [-3.745000949178435, 51.235469373408272],
            [-3.742170786056531, 51.235366766833451],
            [-3.740395502899162, 51.235810452649666],
            [-3.731347809369807, 51.235236061009246],
            [-3.728141556150833, 51.235302348114466],
            [-3.727726770197829, 51.235548580512685],
            [-3.724266832752257, 51.234156286071212],
            [-3.721513835978131, 51.233623088954801],
            [-3.720764943222556, 51.233092742432135],
            [-3.722007088274624, 51.232409003091583],
            [-3.722495368535665, 51.231024125676115],
            [-3.723102923555328, 51.230532268265833],
            [-3.727089272035413, 51.229389861510136],
            [-3.727302064682938, 51.227040508386949],
            [-3.726684129118576, 51.225645859733277],
            [-3.728287817596127, 51.225101498241266],
            [-3.729237078899998, 51.223910317405917],
            [-3.733185332826269, 51.222725994952611],
            [-3.728721007767661, 51.218852315384417],
            [-3.729030510046517, 51.216756021041718],
            [-3.730871263313036, 51.216012974064768],
            [-3.731557016753669, 51.215132324002951],
            [-3.730807992129042, 51.213028305639149],
            [-3.729396157519659, 51.211535700636432],
            [-3.727719266917126, 51.208429191102553],
            [-3.728447783380636, 51.207047927055619],
            [-3.728468735932989, 51.205772439270795],
            [-3.727971763655689, 51.204581042296454],
            [-3.726356962595632, 51.202742478166442],
            [-3.727052771859925, 51.201215121420937],
            [-3.726195686433043, 51.199424716918529],
            [-3.726992620022802, 51.197464212413216],
            [-3.728984840580662, 51.194631721401521],
            [-3.732005539394784, 51.193623898341599],
            [-3.732072050548826, 51.190970931532931],
            [-3.729434610208621, 51.188297726514712],
            [-3.725986314073488, 51.186356756716982],
            [-3.724405809306985, 51.183922333215456],
            [-3.725074236799827, 51.182471825346624],
            [-3.723810157924369, 51.180728770024764],
            [-3.725278598910168, 51.179361789149404],
            [-3.726016703606535, 51.178936328419418],
            [-3.728074638760382, 51.178721584300241],
            [-3.72873196228456, 51.178126436000404],
            [-3.730169557884758, 51.178043125682741],
            [-3.732835557227336, 51.177067498431398],
            [-3.73371577892142, 51.176962725469316],
            [-3.734223675208376, 51.177191706507607],
            [-3.735476487221905, 51.176950998202699],
            [-3.738083159785735, 51.177331352440561],
            [-3.738523662684245, 51.177061314337408],
            [-3.739572897992757, 51.17711405717224],
            [-3.742099396710775, 51.175973924508149],
            [-3.745859169700291, 51.175559040294843],
            [-3.751130414624766, 51.177128617702174],
            [-3.757233537856292, 51.177350921184726],
            [-3.777897811718797, 51.17339646930855],
            [-3.782093563685925, 51.172281334584369],
            [-3.786287116152894, 51.171928679713339],
            [-3.793364369395639, 51.172752022988497],
            [-3.795973293002361, 51.174545638099303],
            [-3.806250682226124, 51.177874799755408],
            [-3.811872463613855, 51.177633155205847],
            [-3.815552145357885, 51.178505054802571],
            [-3.817501832102508, 51.178531425121946],
            [-3.824341214801627, 51.176116443346046],
            [-3.832262910667492, 51.177563659952639],
            [-3.838429343020533, 51.177099069542784],
            [-3.83980306802249, 51.176674581067282],
            [-3.838905050656474, 51.174960260446476],
            [-3.837478347750824, 51.167359357255847],
            [-3.836444972324252, 51.165039219332684],
            [-3.835333042716855, 51.154108634597492],
            [-3.832051333677804, 51.151187023920961],
            [-3.834454263775401, 51.144860551491547],
            [-3.834719557656349, 51.141384193894204],
            [-3.83408358510103, 51.140199003664399],
            [-3.834473307020289, 51.138382598461881],
            [-3.831029338098883, 51.135231485086869],
            [-3.825089003640359, 51.131068889344199],
            [-3.821505760079923, 51.126381869360095],
            [-3.816390971952746, 51.122667310507751],
            [-3.8137097092696, 51.121050634219841],
            [-3.807158708883843, 51.117920912749497],
            [-3.804271137300985, 51.115687593789531],
            [-3.797483388559518, 51.114078107415288],
            [-3.791419530284454, 51.111373481117106],
            [-3.787017158093824, 51.110170161688572],
            [-3.780103753746729, 51.107707259432452],
            [-3.776891467598858, 51.106937777040116],
            [-3.767377956221906, 51.105786132275206],
            [-3.757532560461661, 51.100542408756461],
            [-3.75085641356679, 51.09513082063858],
            [-3.745473271978922, 51.092539530824475],
            [-3.741326718755202, 51.089854934626629],
            [-3.738612551382828, 51.088685790226428],
            [-3.730030905496386, 51.086779835550466],
            [-3.722434320761651, 51.082891079220175],
            [-3.719937370664968, 51.080818106944683],
            [-3.718437594486134, 51.080452574466669],
            [-3.714899444901381, 51.080211379800133],
            [-3.709224498092776, 51.080677621423007],
            [-3.707713915129065, 51.080403837888547],
            [-3.706089730160868, 51.0806505866667],
            [-3.704296432451985, 51.080414160110706],
            [-3.701322967609485, 51.080774938753066],
            [-3.697873132129561, 51.080448308664813],
            [-3.696448261257972, 51.08099329055662],
            [-3.694999154184957, 51.081110542587879],
            [-3.694304014635284, 51.080645796108044],
            [-3.692390502246746, 51.080595285173601],
            [-3.688664856599826, 51.078455804988636],
            [-3.687210407731684, 51.076188111576684],
            [-3.685552214616264, 51.075144579940471],
            [-3.68419224859142, 51.07476400294545],
            [-3.682404523827424, 51.073333799006647],
            [-3.679694193861387, 51.072714506901015],
            [-3.677932720391382, 51.072832440968433],
            [-3.677636008819089, 51.072601086339247],
            [-3.676050124877005, 51.072877444445353],
            [-3.673107873287622, 51.071286492787806],
            [-3.670665280590839, 51.070603805060323],
            [-3.666090902294818, 51.070176304663882],
            [-3.66483700018822, 51.06944146859734],
            [-3.664751245789068, 51.067648601635277],
            [-3.664316497542823, 51.067187162488736],
            [-3.659919055254549, 51.066148980159397],
            [-3.659254342981983, 51.066239357369113],
            [-3.658320825168876, 51.065637493739402],
            [-3.656298837469341, 51.065128395923786],
            [-3.653301390039611, 51.064999081319563],
            [-3.65314361998753, 51.06473692169655],
            [-3.650240735563231, 51.064095393059247],
            [-3.650839654367142, 51.061875573710552],
            [-3.652272838746867, 51.060443426944701],
            [-3.652314338743831, 51.059645167536544],
            [-3.649808956704404, 51.059417976914467],
            [-3.64881978453696, 51.058567719796706],
            [-3.645394878151952, 51.057880379486399],
            [-3.644504634428605, 51.057885718489054],
            [-3.642431592219331, 51.058540784805423],
            [-3.639953215827117, 51.057822889679073],
            [-3.638056013563213, 51.056629164177451],
            [-3.635337528087387, 51.056300333834116],
            [-3.633639140748763, 51.057020144318088],
            [-3.631262902839192, 51.059526394156045],
            [-3.628074074745574, 51.059786700143455],
            [-3.624287739652043, 51.061242289285595],
            [-3.621293578846728, 51.060666061658587],
            [-3.61933401548864, 51.059964811079489],
            [-3.614676506322949, 51.056570599877539],
            [-3.613036660847659, 51.056110331753047],
            [-3.612396958886009, 51.056220781112557],
            [-3.611205702458395, 51.0575834503462],
            [-3.610313730682874, 51.057950066806207],
            [-3.607569135889898, 51.057316991118412],
            [-3.604829466721964, 51.057566884988979],
            [-3.603815191349464, 51.057079908057062],
            [-3.602942905292606, 51.055787010541707],
            [-3.601412802938671, 51.055401513763272],
            [-3.596858932714443, 51.055921578190045],
            [-3.594455420223627, 51.055198101188942],
            [-3.594756353570455, 51.054944891344938],
            [-3.596001932527699, 51.054900905014804],
            [-3.596595494998712, 51.053739008757155],
            [-3.597535882059813, 51.053534606163737],
            [-3.599809907955749, 51.051744482289131],
            [-3.600314205825987, 51.05064583911193],
            [-3.599931653749333, 51.047494572917493],
            [-3.599155128912813, 51.046405373122745],
            [-3.598265499093823, 51.042734055708749],
            [-3.597496623009978, 51.041697798103456],
            [-3.598133257185774, 51.040508322936411],
            [-3.598152890947425, 51.036975640828793],
            [-3.6024442781756, 51.032664105716655],
            [-3.60268186012805, 51.029960272362352],
            [-3.610725405106268, 51.024497946217707],
            [-3.611188505932257, 51.023666007792755],
            [-3.610880621600061, 51.021654036741381],
            [-3.611905888300294, 51.0187378697293],
            [-3.614637625618556, 51.015495052392836],
            [-3.614195664169976, 51.013770916403708],
            [-3.61460826531617, 51.012721131206526],
            [-3.612068737609003, 51.011718421540813],
            [-3.610671488776778, 51.010725090860042],
            [-3.610091601489025, 51.009992965651087],
            [-3.610167845393464, 51.008696029188684],
            [-3.609728520881484, 51.007988043806193],
            [-3.603191255536685, 51.007238016645076],
            [-3.60575054925858, 51.006060753206967],
            [-3.607343985953723, 51.004448879491974],
            [-3.606208281201214, 51.003343081180482],
            [-3.604292085826608, 51.002334327945363],
            [-3.603853953748893, 51.00132593905564],
            [-3.601886702375594, 51.000361913306833],
            [-3.601765993369158, 50.999671110580991],
            [-3.600492888129072, 50.99866516470459],
            [-3.598760846152499, 50.995666351957723],
            [-3.599919639759066, 50.995175654063381],
            [-3.599698504175353, 50.994919685767414],
            [-3.600514839749977, 50.993505597418803],
            [-3.600445414408039, 50.992026305348546],
            [-3.601991284754218, 50.991068941066594],
            [-3.605101413715812, 50.990229457744761],
            [-3.606826255013212, 50.990745317059982],
            [-3.606564210570218, 50.991574477996956],
            [-3.607613161700859, 50.99277589414703],
            [-3.605985621107736, 50.993883739430984],
            [-3.60531055826809, 50.994927212915414],
            [-3.607213304022976, 50.994771543940054],
            [-3.60808421211972, 50.995066215773598],
            [-3.611772028952625, 50.994813935504567],
            [-3.611715887199185, 50.995249970585888],
            [-3.614582133905615, 50.998875918564856],
            [-3.620024541957537, 51.000627026545871],
            [-3.62285240584808, 51.005003319788933],
            [-3.628799037011814, 51.005778470846501],
            [-3.628726606937343, 50.999764072870981],
            [-3.62781222788889, 50.995668822312815],
            [-3.626634756220788, 50.994598874684804],
            [-3.627219484902736, 50.994490007327386],
            [-3.627882358006656, 50.993641722712574],
            [-3.628726053121663, 50.991528295112026],
            [-3.628648366259971, 50.989228069049013],
            [-3.62298928462728, 50.983360680591545],
            [-3.621083112971607, 50.980295361397339],
            [-3.620084488984471, 50.978740839291071],
            [-3.619647099681951, 50.978698344527082],
            [-3.620035702723246, 50.974270189072413],
            [-3.619553084503157, 50.968177816326161],
            [-3.618275962090479, 50.968394269515926],
            [-3.616693236095344, 50.96760322524888],
            [-3.615416553484033, 50.967380781551569],
            [-3.612702792184448, 50.968348173597889],
            [-3.610980744390402, 50.968590475796212],
            [-3.608101094696571, 50.967935010698753],
            [-3.605915835909848, 50.967837385707469],
            [-3.601665300597743, 50.968520783070844],
            [-3.599344647776207, 50.967384401086477],
            [-3.597830657316075, 50.967216275896803],
            [-3.598766681757367, 50.966147683786389],
            [-3.59906521046085, 50.965012274911793],
            [-3.600822205895258, 50.963717486115314],
            [-3.60046707902155, 50.963453463366008],
            [-3.601377876424595, 50.962317747204416],
            [-3.601626138302611, 50.960924021115012],
            [-3.600681327636047, 50.959080818346244],
            [-3.601122099470182, 50.956245569063299],
            [-3.600861298665484, 50.955327360060693],
            [-3.598423907206908, 50.954959666368609],
            [-3.598778474010117, 50.954089682309579],
            [-3.598034063994446, 50.952643902606319],
            [-3.598081876821719, 50.950510101268286],
            [-3.596036721739562, 50.947205260223143],
            [-3.595747460192201, 50.945160600737296],
            [-3.596678832547199, 50.943012019152775],
            [-3.6001879923616, 50.939374846024393],
            [-3.608459111265704, 50.93290308120968],
            [-3.609455259163715, 50.931582658088892],
            [-3.610487338960376, 50.928749994543622],
            [-3.612616846982334, 50.926589235040041],
            [-3.614297525201733, 50.926388840060078],
            [-3.615122653030612, 50.925110300408242],
            [-3.617191282715762, 50.924364010176447],
            [-3.619312375694155, 50.922508106988452],
            [-3.620568166226493, 50.921918717728758],
            [-3.624014317422557, 50.921840243983404],
            [-3.625339112914678, 50.921559205415818],
            [-3.627470178111026, 50.919594195585304],
            [-3.632552312952464, 50.91988204772688],
            [-3.634184756792747, 50.918801613782833],
            [-3.640999586503488, 50.918034214355451],
            [-3.642905758772784, 50.921258410773433],
            [-3.643793825461043, 50.921830459895141],
            [-3.650106467427329, 50.921659550416123],
            [-3.654148613482227, 50.920896375526482],
            [-3.658491670451766, 50.920556857587279],
            [-3.664399353666536, 50.920685902085779],
            [-3.673638753174211, 50.918978185273389],
            [-3.675790052454267, 50.918031815024001],
            [-3.676681040913984, 50.918042401272118],
            [-3.677246750653143, 50.917513569811014],
            [-3.681073463375914, 50.916794821347871],
            [-3.686903039700463, 50.914932763196369],
            [-3.69301536283538, 50.914249811312317],
            [-3.698512497507161, 50.913007123856033],
            [-3.697674316033883, 50.910614542726229],
            [-3.703465666417237, 50.91101668891951],
            [-3.706328012163878, 50.910752744163851],
            [-3.708442756930333, 50.911399893501425],
            [-3.712609389279251, 50.911442248415625],
            [-3.717062333278778, 50.910528762198446],
            [-3.719186165870854, 50.910608112827568],
            [-3.720327499902362, 50.910274734103872],
            [-3.720758536810774, 50.910584041137355],
            [-3.72214391990782, 50.910195784802859],
            [-3.725101159154898, 50.909963253289597],
            [-3.725600985461546, 50.909747221713587],
            [-3.725563454846615, 50.90907868183043],
            [-3.728038600640798, 50.910771454285403],
            [-3.728368752600554, 50.90996886767001],
            [-3.729216138315511, 50.910188340308899],
            [-3.729676083124515, 50.907746162243853],
            [-3.731503732786265, 50.907091334232952],
            [-3.732168995717891, 50.906007671732581],
            [-3.734228002954542, 50.905304400113167],
            [-3.735035822610749, 50.90323744098589],
            [-3.73622545839321, 50.902604611556008],
            [-3.735946794221378, 50.90184253385695],
            [-3.737000879439749, 50.901122679403393],
            [-3.737012942918727, 50.900376960846557],
            [-3.735718611527567, 50.898537317697127],
            [-3.73586647384279, 50.896356956522872],
            [-3.734887653977982, 50.895617879093926],
            [-3.734834739419246, 50.894538577780345],
            [-3.733714584214856, 50.892289827919342],
            [-3.734665257004497, 50.890666808585301],
            [-3.735426371151095, 50.890104205892065],
            [-3.738801791736095, 50.890866048351974],
            [-3.74478701965811, 50.890309960131638],
            [-3.743438616850766, 50.889687995547767],
            [-3.742617221838576, 50.888823422552996],
            [-3.740555733094546, 50.888255239390894],
            [-3.740313446248134, 50.887438668338298],
            [-3.740691716796036, 50.887097577718443],
            [-3.742715294639598, 50.88691808551723],
            [-3.744370496548195, 50.886354657408425],
            [-3.744760567876571, 50.885645552542115],
            [-3.749391721330639, 50.882474435129907],
            [-3.749907984164237, 50.881038562291714],
            [-3.749404794613132, 50.880053250222517],
            [-3.749724853653738, 50.87933798245637],
            [-3.749190808523593, 50.878515010984792],
            [-3.750753026092496, 50.877907917199927],
            [-3.751327338408949, 50.878022506318914],
            [-3.751552429261184, 50.877656698421717],
            [-3.753140984066133, 50.877260519025626],
            [-3.753563736887167, 50.876821589412636],
            [-3.755105632848959, 50.876471050626989],
            [-3.756994641531299, 50.876393157430563],
            [-3.757375436810421, 50.876804711145049],
            [-3.758662267353183, 50.876589268629324],
            [-3.760784311856899, 50.876810875219022],
            [-3.75984375273362, 50.879840514259271],
            [-3.762396126052725, 50.883249116315511],
            [-3.764758863978026, 50.885504894222585],
            [-3.766829509363343, 50.888226402974922],
            [-3.768181492872839, 50.888860635034717],
            [-3.76801365318051, 50.889046641081997],
            [-3.774616048289999, 50.889742324025377],
            [-3.774563115851752, 50.89152919518105],
            [-3.775139470196098, 50.892327126392502],
            [-3.777473704356523, 50.893469714370184],
            [-3.779463429396049, 50.894002369360898],
            [-3.781155568516497, 50.89724381339559],
            [-3.782817388617722, 50.898829133588364],
            [-3.781508112180651, 50.900742213420614],
            [-3.781998141324211, 50.901619670086355],
            [-3.783300019824566, 50.902490117585479],
            [-3.785082179779937, 50.902157998650068],
            [-3.785549900964435, 50.902450319469359],
            [-3.787102925322773, 50.902485903038027],
            [-3.789171704521606, 50.903769021878638],
            [-3.7904960107868, 50.903884509702152],
            [-3.792905879868577, 50.903601994642706],
            [-3.794336776317344, 50.902124888924796],
            [-3.795298963193761, 50.901996775253828],
            [-3.795277381811508, 50.901472798513247],
            [-3.798520876649781, 50.901199822447786],
            [-3.800966803984851, 50.901856383403029],
            [-3.802457877677202, 50.902755175590798],
            [-3.805949808913263, 50.902726370893461],
            [-3.808908495509058, 50.904094294020773],
            [-3.812922399242607, 50.904575203452168],
            [-3.813268035700093, 50.904026638039795],
            [-3.815305639721352, 50.90295263619668],
            [-3.815160550700559, 50.901891884006524],
            [-3.816458169168853, 50.90201558629537],
            [-3.816889322794258, 50.901802028304644],
            [-3.818235305643312, 50.902984369891485],
            [-3.818047864545345, 50.904342581861997],
            [-3.817616393070749, 50.904950055763784],
            [-3.81593985683513, 50.905836814901924],
            [-3.815924943912957, 50.906623061678758],
            [-3.815354075357325, 50.90701235980881],
            [-3.81420118624906, 50.909370344040688],
            [-3.811205769838466, 50.911612132493666],
            [-3.813236159553701, 50.913160726230572],
            [-3.817007916605927, 50.914123703832956],
            [-3.816774578912176, 50.914597685577704],
            [-3.820921647778485, 50.914310022321409],
            [-3.823358743083237, 50.913513837627882],
            [-3.821921702138774, 50.912233150690248],
            [-3.823212176054104, 50.911547493541043],
            [-3.824881517402019, 50.910002433475043],
            [-3.827107392511304, 50.908832651264355],
            [-3.827455150794648, 50.907361296332461],
            [-3.829993840604416, 50.906882641500736],
            [-3.83059066656806, 50.907085523542776],
            [-3.830046996148426, 50.907476266191871],
            [-3.831705010354034, 50.907246106418995],
            [-3.831621468841173, 50.907726761447464],
            [-3.832491981632701, 50.90783540490866],
            [-3.832780434994482, 50.908153735345202],
            [-3.834034854142778, 50.90822216302081],
            [-3.834309116297189, 50.908649531904871],
            [-3.8375274750366, 50.907695073626734],
            [-3.839656299385284, 50.908114798786279],
            [-3.839091300141719, 50.908504121410701],
            [-3.841512338698789, 50.90789306910338],
            [-3.843311624265453, 50.908251376619759],
            [-3.844123253294398, 50.907984942344889],
            [-3.846113126470583, 50.908593806936857],
            [-3.847396303311083, 50.908309996887127],
            [-3.846945272761136, 50.908055429785101],
            [-3.847480038824514, 50.907838320006078],
            [-3.847782528833795, 50.908221143687356],
            [-3.8495475943096, 50.908396433110831],
            [-3.85419125584709, 50.908024167677659],
            [-3.856184281690899, 50.907207366324933],
            [-3.856679886215049, 50.907615874334041],
            [-3.858190068717324, 50.906891256646475],
            [-3.860140234831084, 50.906750469850948],
            [-3.861766424382285, 50.90697455331518],
            [-3.865229737255458, 50.908570430368755],
            [-3.867333517233292, 50.909174415982456],
            [-3.869127977950257, 50.909299475932677],
            [-3.873818532527358, 50.907900434823951],
            [-3.874813005225967, 50.907870073516726],
            [-3.875631422559648, 50.906715664945942],
            [-3.879056422190276, 50.906112871495374],
            [-3.881792398060338, 50.906766671541455],
            [-3.88348496979915, 50.90665932562549],
            [-3.88495223637214, 50.907382984869081],
            [-3.88685900503276, 50.907471783126894],
            [-3.887609832242245, 50.907115194802174],
            [-3.887708017430737, 50.90757316816773],
            [-3.888538674079396, 50.908029183128896],
            [-3.889444258414359, 50.907630507658311],
            [-3.889888325852147, 50.908023522484626],
            [-3.890611760806608, 50.907833735893234],
            [-3.89090420336424, 50.907047473147479],
            [-3.892099946143763, 50.907841993328582],
            [-3.893134081978875, 50.907352170228378],
            [-3.893948964868997, 50.907733757759388],
            [-3.894926371200816, 50.906758297200746],
            [-3.895617897501766, 50.906869376499749],
            [-3.897287555554572, 50.906199216299754],
            [-3.898088561108661, 50.906274326121938],
            [-3.897656925779129, 50.905733648378693],
            [-3.89829471204282, 50.905782631910256],
            [-3.898724734569364, 50.906458234413073],
            [-3.899574398124064, 50.906051396000535],
            [-3.899380196144867, 50.905508658134401],
            [-3.900591741042441, 50.906060013273276],
            [-3.90077762873372, 50.905525476233123],
            [-3.899837635695072, 50.905075824560107],
            [-3.900573825643192, 50.904363251804504],
            [-3.901484333719588, 50.903950012064627],
            [-3.901685669484593, 50.90348087338139],
            [-3.90258900510758, 50.903380711938979],
            [-3.902951782009327, 50.904185101891201],
            [-3.904136674973994, 50.905093892833449],
            [-3.904415871426217, 50.906672174637301],
            [-3.904025916293353, 50.908200219814127],
            [-3.905023356277388, 50.909461903017387],
            [-3.903758523072186, 50.910466419869906],
            [-3.903751324197872, 50.911823639567331],
            [-3.902093957829452, 50.912832741354073],
            [-3.902388277883812, 50.913177786715515],
            [-3.903739098227494, 50.913475924013319],
            [-3.903459053920244, 50.915014763068292],
            [-3.902320034095818, 50.916213266095212],
            [-3.903521668706198, 50.916548912036077],
            [-3.903741939959333, 50.915880708327975],
            [-3.904783005177346, 50.915350199621919],
            [-3.905744041190383, 50.916552214712802],
            [-3.905918916257827, 50.917628564891338],
            [-3.90477092845479, 50.918433327818498],
            [-3.903235989566325, 50.91795024290699],
            [-3.902499957651498, 50.918182581123226],
            [-3.903461343635707, 50.919462851162116],
            [-3.901967172744242, 50.920921653410623],
            [-3.902783826945105, 50.922977717120887],
            [-3.901180135632965, 50.923882503093509],
            [-3.901360726169335, 50.924228503964358],
            [-3.902687657332212, 50.924700615840102],
            [-3.901821820901965, 50.925416210036467],
            [-3.901953379848819, 50.925920392799426],
            [-3.904396078548697, 50.927404938042059],
            [-3.905093889096684, 50.927557229588565],
            [-3.906075520773058, 50.927307375493335],
            [-3.905444983201124, 50.927025386424368],
            [-3.905752589152329, 50.926439392646259],
            [-3.90516288302394, 50.92645981191162],
            [-3.90447834949532, 50.925482612308009],
            [-3.904457335202582, 50.925177180644965],
            [-3.905301464669582, 50.924941267645913],
            [-3.907237141968598, 50.924848498065522],
            [-3.908633815858724, 50.925388651998219],
            [-3.909779221014186, 50.924622566276028],
            [-3.910327468471841, 50.924841126585605],
            [-3.910406574955558, 50.924445022559006],
            [-3.912984274616672, 50.924221135679424],
            [-3.914368356393244, 50.924418779855237],
            [-3.915223320836166, 50.923893929389699],
            [-3.915853448407582, 50.924200154706114],
            [-3.918314477961224, 50.924184912775146],
            [-3.919599174146391, 50.92393805646882],
            [-3.921270436724849, 50.922989634111467],
            [-3.922718582559958, 50.922981075315398],
            [-3.922743401378689, 50.923237877461311],
            [-3.923704102425456, 50.923416304157996],
            [-3.923582552012617, 50.923056773133212],
            [-3.924478090877149, 50.923173313637818],
            [-3.924374822233827, 50.922843160146883],
            [-3.925064045592536, 50.923028749487159],
            [-3.925272073768367, 50.922760012940273],
            [-3.927084559609309, 50.922856907388109],
            [-3.92839156865387, 50.922394643098379],
            [-3.929122325948804, 50.922758490924885],
            [-3.929756867234001, 50.922310022402762],
            [-3.930231566184113, 50.922571974659022],
            [-3.930748068282296, 50.922331401677873],
            [-3.931437315577524, 50.92248277815753],
            [-3.931973076914851, 50.922260767138901],
            [-3.931506373183846, 50.921848499772416],
            [-3.932889046949473, 50.921771648808587],
            [-3.933121903905651, 50.921519574200786],
            [-3.936930538048538, 50.921648887679197],
            [-3.939150903138446, 50.920639829765285],
            [-3.940948997055203, 50.920939099504714],
            [-3.940970965548328, 50.920338873368955],
            [-3.942286371204078, 50.920014811052937],
            [-3.94288918717418, 50.920140578049725],
            [-3.945790279849603, 50.918810684424869],
            [-3.947255315356996, 50.918868989391676],
            [-3.947486658037934, 50.918616910745889],
            [-3.948953057790475, 50.918810073003463],
            [-3.948867063710875, 50.918488645002277],
            [-3.949365686212149, 50.918674575664497],
            [-3.949705271244232, 50.918289380486328],
            [-3.952491740703422, 50.918170849513629],
            [-3.952747166795996, 50.917849107892671],
            [-3.953359782942539, 50.91797105947942],
            [-3.954184807876295, 50.917695539054726],
            [-3.954954039054667, 50.918838312512086],
            [-3.957479510599695, 50.920432786727744],
            [-3.958681989146313, 50.921013373735619],
            [-3.960080911394158, 50.920783038999801],
            [-3.963560312304401, 50.9217093340107],
            [-3.965381090444704, 50.92267695851848],
            [-3.966797457267247, 50.92400661145566],
            [-3.977088405425957, 50.925491130032448],
            [-3.978232491202954, 50.925960987598991],
            [-3.978940865774978, 50.925686370163888],
            [-3.978688392214022, 50.925269757258491],
            [-3.981584105979163, 50.926307038115667],
            [-3.988053444851758, 50.926516289193934],
            [-3.995929337635713, 50.925575154532879],
            [-3.996632762483871, 50.925984916716757],
            [-3.996521711989878, 50.926847491370694],
            [-3.997914318448236, 50.930852734099247],
            [-3.996014142879551, 50.93397000170922],
            [-3.997622675409515, 50.93557029928818],
            [-3.993203913826864, 50.936880641164144],
            [-3.993863149033392, 50.938948667558101],
            [-3.995940974450452, 50.941699326557789],
            [-3.995113589501329, 50.942821463766478],
            [-3.994911990678359, 50.944995027105115],
            [-3.994251042425478, 50.946149387063684],
            [-3.994265315760774, 50.947946032113371],
            [-3.993871835300437, 50.948432103843352],
            [-3.991318477210617, 50.949314776155738],
            [-3.990442351242931, 50.949934976248386],
            [-3.989542906591565, 50.949943114533163],
            [-3.989023759078753, 50.9506552474353],
            [-3.984563094996093, 50.952457903388556],
            [-3.980086856188377, 50.953131977174635],
            [-3.978501268312238, 50.952474462292649],
            [-3.976725999702074, 50.952814809393288],
            [-3.974498830924256, 50.953848071243826],
            [-3.973280774568026, 50.953428889449562],
            [-3.971498817924295, 50.953343881607097],
            [-3.967086439637216, 50.954423777434179],
            [-3.963638414769387, 50.954436859744469],
            [-3.961662400969261, 50.955255204045983],
            [-3.960763231368076, 50.956151662052321],
            [-3.964004712007103, 50.957214152655922],
            [-3.965670836603616, 50.958262619609727],
            [-3.966408014531266, 50.959735913024325],
            [-3.966065865942996, 50.961649173159266],
            [-3.964765819153339, 50.963440957464861],
            [-3.963280463474693, 50.964386867600112],
            [-3.960589301998302, 50.965582345024025],
            [-3.958144905167267, 50.965740282504726],
            [-3.957519809240333, 50.96611589595301],
            [-3.957138715257359, 50.966909204936265],
            [-3.958001300697763, 50.968725791777651],
            [-3.958339805878022, 50.97172749599023],
            [-3.957852186955112, 50.972224013500281],
            [-3.953307012687667, 50.974364117849731],
            [-3.952570616115056, 50.975961064010257],
            [-3.952894011432001, 50.976606774038842],
            [-3.95438123861269, 50.977455143921524],
            [-3.958226748553744, 50.977774728749715],
            [-3.958422177979591, 50.978491818726674],
            [-3.957832003041792, 50.980750953758907],
            [-3.958066241431227, 50.981340587062171],
            [-3.958729950036763, 50.98164062499896],
            [-3.96105978578106, 50.981077205627066],
            [-3.961422289073802, 50.979774274836956],
            [-3.960679553328455, 50.978917091809635],
            [-3.961031513421712, 50.978817650143561],
            [-3.962882246816916, 50.97899070104657],
            [-3.965819910280256, 50.980612245056257],
            [-3.967678954436042, 50.980878611407718],
            [-3.9702633040794, 50.980744198241965],
            [-3.973690805368038, 50.982082082255175],
            [-3.976388687589161, 50.980527359984137],
            [-3.980792485813905, 50.98103370712731],
            [-3.983629867524868, 50.982123200571323],
            [-3.985592203382318, 50.980332540010522],
            [-3.988069826313665, 50.979502559546603],
            [-3.989921433572824, 50.978293782898277],
            [-3.990711852852761, 50.975790937921502],
            [-3.991725304722574, 50.974407557102317],
            [-3.992257745058622, 50.974267169398921],
            [-3.992776067396071, 50.974495749573634],
            [-3.999824032486212, 50.972664696180004],
            [-4.001209165311312, 50.972645459301106],
            [-4.003835578837359, 50.971945691242027],
            [-4.006069488178986, 50.972782388593217],
            [-4.006464318952945, 50.972458136289063],
            [-4.004900980401858, 50.969530655018644],
            [-4.007396853655385, 50.96888071190854],
            [-4.010285819731286, 50.967539547166865],
            [-4.011799252529486, 50.967394764409221],
            [-4.012911748139437, 50.967760507539715],
            [-4.013031211799243, 50.968085808637781],
            [-4.014604002813178, 50.968259232925291],
            [-4.015617936744195, 50.968748961862545],
            [-4.018510401515265, 50.968676505573974],
            [-4.019991387347861, 50.969457602335147],
            [-4.020254383443372, 50.969905421579419],
            [-4.019673007702436, 50.971048653072472],
            [-4.019704205511827, 50.972095848369236],
            [-4.023051803036297, 50.973248406533862],
            [-4.024833553322623, 50.973439657375799],
            [-4.026539331683498, 50.974404736112874],
            [-4.027922506217795, 50.976104761720578],
            [-4.028040796397335, 50.976988560546403],
            [-4.029046099139117, 50.977043941078811],
            [-4.030558331763888, 50.978367565952354],
            [-4.031443054020126, 50.978335987972358],
            [-4.031425725733539, 50.978591703087503],
            [-4.03306126403771, 50.978985027719844],
            [-4.033068664516667, 50.979350032457191],
            [-4.034366850643543, 50.979781589061439],
            [-4.034267073610878, 50.980236596813697],
            [-4.035637220188955, 50.980846754456088],
            [-4.036220871067655, 50.981584831500584],
            [-4.035746552876111, 50.982140802712919],
            [-4.036243400309258, 50.982294920229158],
            [-4.037489016570318, 50.985199655188062],
            [-4.036772371618883, 50.986437063939853],
            [-4.036790390176105, 50.987922464572684],
            [-4.036931708597089, 50.988253655691629],
            [-4.037352108884972, 50.98815728625447],
            [-4.036357014749143, 50.989740400596638],
            [-4.035686618718305, 50.99005067179553],
            [-4.035775048043303, 50.990410665859606],
            [-4.03533380158551, 50.990421955261205],
            [-4.035463381353193, 50.991819972801984],
            [-4.035104706919919, 50.991992602833086],
            [-4.035527242306961, 50.992530239397432],
            [-4.036014712117264, 50.992500156590687],
            [-4.035847710028234, 50.99285201317317],
            [-4.036498513223894, 50.993297530714784],
            [-4.035855942119031, 50.993821360585478],
            [-4.036197039023985, 50.994614029024227],
            [-4.036834576924632, 50.994528264837712],
            [-4.037291515966416, 50.995134544310616],
            [-4.036012106476356, 50.995663188395788],
            [-4.035360689794063, 50.996930025636964],
            [-4.033547911646472, 50.997093811455159],
            [-4.033690202112775, 50.999043803531634],
            [-4.032794695976756, 51.002847419373154],
            [-4.034276629242748, 51.007625890288416],
            [-4.037887071477316, 51.007553050314513],
            [-4.043626651166432, 51.008181218424198],
            [-4.045808156728016, 51.007608814304781],
            [-4.047331743186564, 51.009609229755291],
            [-4.047984318831912, 51.00879287704408],
            [-4.049228855554649, 51.008373536412556],
            [-4.057424961308025, 51.008326557803393],
            [-4.058978984671112, 51.00900695217917],
            [-4.063956616767589, 51.010314867229447],
            [-4.064199585968893, 51.00952634555518],
            [-4.065371914685912, 51.009537994584363],
            [-4.066068940876115, 51.00895907876675],
            [-4.069084805820679, 51.008433528828647],
            [-4.077596041714767, 51.008547727622179],
            [-4.078485947145979, 51.008779212385058],
            [-4.079230544580384, 51.008694010058377],
            [-4.080345129288946, 51.007352122416243],
            [-4.080922343043393, 51.005184307939444],
            [-4.079146397545903, 51.003052114497358],
            [-4.077878249652495, 51.00267897404882],
            [-4.079225747267334, 51.002467925460849],
            [-4.081317116293103, 51.002954089081179],
            [-4.082178454440346, 51.002677024451422],
            [-4.086103616799331, 51.002734678280326],
            [-4.091123681222, 51.003842822177035],
            [-4.093551002992921, 51.003776847311322],
            [-4.093855426621896, 51.005440578295556],
            [-4.094345122879187, 51.006027164947625],
            [-4.096361272957799, 51.00673474982635],
            [-4.09704005532496, 51.008446611904688],
            [-4.098223869344966, 51.009597197857637],
            [-4.100471593155588, 51.009966893833806],
            [-4.106452495022462, 51.010200019667984],
            [-4.107054652129139, 51.010904138642452],
            [-4.108788033048606, 51.010806299428445],
            [-4.109949380449911, 51.010983179449774],
            [-4.109786969285636, 51.011142600320525],
            [-4.116293978948823, 51.010433091981803],
            [-4.119224537115494, 51.011423193997565],
            [-4.119593369168129, 51.011226737527224],
            [-4.119106000485741, 51.010418073313858],
            [-4.120670591282826, 51.010777291808914],
            [-4.120938427369458, 51.010898336800544],
            [-4.12018075375429, 51.011664850599999],
            [-4.11949775439575, 51.014288059929584],
            [-4.117459505465736, 51.015457314023728],
            [-4.115989289867907, 51.016954396323335],
            [-4.115656461809743, 51.018537883170183],
            [-4.116064798524442, 51.019301226831217],
            [-4.12209669256295, 51.019404927255231],
            [-4.124225566569742, 51.020185532725101],
            [-4.127291149925484, 51.020650457752126],
            [-4.132479285659478, 51.020198800632656],
            [-4.135186494920564, 51.020682652762055],
            [-4.136183390204222, 51.01904289390766],
            [-4.138334741899325, 51.018417146810705],
            [-4.13970898608317, 51.017513310748427],
            [-4.142657457623982, 51.017015872844823],
            [-4.14223918665189, 51.017870735271281],
            [-4.14412112373477, 51.018516109530708],
            [-4.148476413427161, 51.018778722199073],
            [-4.149569259593003, 51.016542608043309],
            [-4.151375207050629, 51.016377148788528],
            [-4.151409080892823, 51.01606175124693],
            [-4.154373133500819, 51.015997217205729],
            [-4.15498102748392, 51.014378856883887],
            [-4.158173188425243, 51.014715624813029],
            [-4.16032231185836, 51.014420472476701],
            [-4.166008703468946, 51.014524693760968],
            [-4.167376235724633, 51.015233195545967],
            [-4.168220026276526, 51.016816585953372],
            [-4.169352244947435, 51.017857698090914],
            [-4.171016224409155, 51.018316026276771],
            [-4.172268594993526, 51.019058089720197],
            [-4.172925335673043, 51.020752848311069],
            [-4.173847201161887, 51.021590979422143],
            [-4.174388211618462, 51.022791439912481],
            [-4.175774702443229, 51.023196409209618],
            [-4.175765914712945, 51.023465480066129],
            [-4.176547675028353, 51.023563332949756],
            [-4.177275414735742, 51.023269169501788],
            [-4.177331894586017, 51.023591884423098],
            [-4.17798920695221, 51.02371183351557],
            [-4.178186506357409, 51.023418561591974],
            [-4.178705423117633, 51.023445756028792],
            [-4.178177231394387, 51.023859418648051],
            [-4.178369989506402, 51.024320790158782],
            [-4.179713325641041, 51.0238955136494],
            [-4.179265801638077, 51.023232046610936],
            [-4.181055492239458, 51.022230931156919],
            [-4.182231246696958, 51.022398731999878],
            [-4.182561985881605, 51.023216361633743],
            [-4.184354286003851, 51.023303367302375],
            [-4.184633598818765, 51.023026537369844],
            [-4.183956345352945, 51.022937576637212],
            [-4.183899656330865, 51.021731700759133],
            [-4.185364215666796, 51.021097239368267],
            [-4.185861853465647, 51.021096919980749],
            [-4.186634150208421, 51.021933254105392],
            [-4.18609366024393, 51.02238675514436],
            [-4.186534839129158, 51.023580036340462],
            [-4.188266535978732, 51.022894670019362],
            [-4.187832428538646, 51.022397364594354],
            [-4.187148168410427, 51.022280673596065],
            [-4.187436391890508, 51.021557588993261],
            [-4.188041153889627, 51.021318723041269],
            [-4.190145807929212, 51.021469037495685],
            [-4.191333177955888, 51.021006079534011],
            [-4.191981429550174, 51.02126641962429],
            [-4.193162151020402, 51.020723524859967],
            [-4.194518493318784, 51.020729523650409],
            [-4.196224726991091, 51.021531154584977],
            [-4.195858188994336, 51.022042586503211],
            [-4.196403279999941, 51.022382186590647],
            [-4.197675534619703, 51.021152213860745],
            [-4.19809842789939, 51.021229694237967],
            [-4.197131767237674, 51.023252552593874],
            [-4.19716071974093, 51.024196333411297],
            [-4.19894201348075, 51.026609960698643],
            [-4.199293267592481, 51.027944288745324],
            [-4.199278563782292, 51.028778269813472],
            [-4.198784376309495, 51.028853227555061],
            [-4.19893221074662, 51.029475497514312],
            [-4.199080461164411, 51.02896187141954],
            [-4.199304879065037, 51.029243641307104],
            [-4.198278004492434, 51.033009693672511],
            [-4.197867955968751, 51.032934669744407],
            [-4.198057546568711, 51.033324120723272],
            [-4.196635026924998, 51.034520045827357],
            [-4.191432560086681, 51.036442563896856],
            [-4.187407420335242, 51.03886892695887],
            [-4.18613550970063, 51.041636668509383],
            [-4.182429536502689, 51.04491935911949],
            [-4.179672044917445, 51.048540376161739],
            [-4.179366683453066, 51.050089361689444],
            [-4.181440463297493, 51.051225207211139],
            [-4.182432540503793, 51.051142822647911],
            [-4.181697855098547, 51.054302479044971],
            [-4.181968726563896, 51.056026872943683],
            [-4.179402756265733, 51.060306217916072],
            [-4.180711039490643, 51.061117300598575],
            [-4.18048592526809, 51.06167190503588],
            [-4.180874617416188, 51.06253252281504],
            [-4.178811299816867, 51.063027892133498],
            [-4.1781787677437, 51.063842806731152],
            [-4.178468935352046, 51.064338334231145],
            [-4.179654531319652, 51.064814460333402],
            [-4.179764999204381, 51.065585840223541],
            [-4.180790489564808, 51.065782546219182],
            [-4.180872374206142, 51.06630983545849],
            [-4.179487821292873, 51.067554291900301],
            [-4.177714546819302, 51.068425552862216],
            [-4.171580584994065, 51.069665712490341],
            [-4.171630213154685, 51.069993951511599],
            [-4.172987890468632, 51.070089220357382],
            [-4.173442452096272, 51.070437806332087],
            [-4.173133308491429, 51.070968776390878],
            [-4.169398074007986, 51.072992475837026],
            [-4.164631760042882, 51.074556707902659],
            [-4.162520065787159, 51.074335900988821],
            [-4.158501862739944, 51.073363365577151],
            [-4.15862807147831, 51.072578602590553],
            [-4.16032425582517, 51.072217191638657],
            [-4.160238747163508, 51.071546061430226],
            [-4.160887715435512, 51.071289438022021],
            [-4.159278206033608, 51.071549428133743],
            [-4.159662336866928, 51.07185889815711],
            [-4.15838677428285, 51.072301565115119],
            [-4.157712478386263, 51.073395034085159],
            [-4.15628982072339, 51.07359217461655],
            [-4.153858641198285, 51.073314153270672],
            [-4.153845220666298, 51.072594924132382],
            [-4.153475762033032, 51.073307720792393],
            [-4.15159049057771, 51.073534014703149],
            [-4.153300630149255, 51.075285011848415],
            [-4.154238379802071, 51.075311793441166],
            [-4.153860524205747, 51.07581339961056],
            [-4.15447534095252, 51.075618597661268],
            [-4.155281813508954, 51.075922991293382],
            [-4.155410275792339, 51.076321729587882],
            [-4.154081611559223, 51.076915518418922],
            [-4.154483378854697, 51.07720489454956],
            [-4.154122488744566, 51.078133377406061],
            [-4.155013969386784, 51.079405699705895],
            [-4.152925496623504, 51.080211341640933],
            [-4.146002736772712, 51.080004138689759],
            [-4.142744400334648, 51.079196071548694],
            [-4.139257241033923, 51.079583733457874],
            [-4.136407841796457, 51.078599825345073],
            [-4.132768017974787, 51.078462170840972],
            [-4.128997169267052, 51.077584833426393],
            [-4.125545041359295, 51.078362661244995],
            [-4.123365078207256, 51.079269262881134],
            [-4.12291593039623, 51.078533669244919],
            [-4.123115806776002, 51.077317725516508],
            [-4.118530521807373, 51.07438816245827],
            [-4.116682450897613, 51.07440187177319],
            [-4.116865262661118, 51.074090985150214],
            [-4.114413772382045, 51.073988782724904],
            [-4.114194924480048, 51.073478320108492],
            [-4.114332357059826, 51.073361616892875],
            [-4.114305359234399, 51.073113887394051],
            [-4.113751598769112, 51.073429682830913],
            [-4.113069274465819, 51.072897923894352],
            [-4.112506106314883, 51.073047757349087],
            [-4.112920039818764, 51.073100276413257],
            [-4.114499192376275, 51.074484572248338],
            [-4.118911382554048, 51.075381323355387],
            [-4.119823066176767, 51.076493459263922],
            [-4.121862522656967, 51.077571622875794],
            [-4.121825721153228, 51.078576855151269],
            [-4.122285037138151, 51.078911162105634],
            [-4.118056325838057, 51.081570799690546],
            [-4.118127135893357, 51.08443301447042],
            [-4.118898213529457, 51.087369767779265],
            [-4.118599018696101, 51.088977815485151],
            [-4.117940626645648, 51.08981984068501],
            [-4.117081785528746, 51.089463077647395],
            [-4.111744231803701, 51.088814107934915],
            [-4.107505894565359, 51.085170963806803],
            [-4.103041608273874, 51.083361888221667],
            [-4.090457267971684, 51.08166582315733],
            [-4.087645731352223, 51.080614402359714],
            [-4.085891884935228, 51.079364170259517],
            [-4.083839035244162, 51.078256843595788],
            [-4.075659273425829, 51.076776439874273],
            [-4.070214677385716, 51.078159982153267],
            [-4.071209578961075, 51.077740347747294],
            [-4.070827305562911, 51.077811875548541],
            [-4.070911330612615, 51.077423672300441],
            [-4.070668307522153, 51.077640222397591],
            [-4.070286500802974, 51.07733851617327],
            [-4.070668332878633, 51.077896532400139],
            [-4.068395794910278, 51.078735392596535],
            [-4.06499049463027, 51.078953906742875],
            [-4.056991732519196, 51.075331284021694],
            [-4.056142073703535, 51.074553026876366],
            [-4.055516713385925, 51.072940732863316],
            [-4.054850310600819, 51.071689790089493],
            [-4.05694034533794, 51.069634907780213],
            [-4.057199614429704, 51.068593410338039],
            [-4.056813134421748, 51.067667604725401],
            [-4.054752010296752, 51.06557244853861],
            [-4.054008531028429, 51.063512555215809],
            [-4.054213302827941, 51.061142804438276],
            [-4.056184268726372, 51.058246446283292],
            [-4.057669017759159, 51.057874060473104],
            [-4.056264823943439, 51.05803548320651],
            [-4.055877719930264, 51.05683538719213],
            [-4.050894704261899, 51.0540612852921],
            [-4.051003205146791, 51.053578235786901],
            [-4.052392644257425, 51.052989050757823],
            [-4.052996168598187, 51.052149258179945],
            [-4.051481945259489, 51.050786384565313],
            [-4.052001256930686, 51.049623415144069],
            [-4.050969194401342, 51.047771818770627],
            [-4.050549759300107, 51.045027211519184],
            [-4.049817109862032, 51.04332953103598],
            [-4.049455079950977, 51.04340153488004],
            [-4.050218044230035, 51.04504292660512],
            [-4.050503173875435, 51.04742835915107],
            [-4.051531408355109, 51.049581304526775],
            [-4.051105376790898, 51.050950380714148],
            [-4.052442630963333, 51.052506128386618],
            [-4.050736989646452, 51.053366169363684],
            [-4.049538334331414, 51.053461849585496],
            [-4.04885898204836, 51.053006111617435],
            [-4.04728508001236, 51.05332868648938],
            [-4.048289482308221, 51.053390221847202],
            [-4.048873366475639, 51.0531056855991],
            [-4.049417983131501, 51.053579975265272],
            [-4.050457139522289, 51.053394464322842],
            [-4.050524699029578, 51.05437445381893],
            [-4.05213202987489, 51.055581917766389],
            [-4.055825876834284, 51.057372303731078],
            [-4.055691228151624, 51.058166088649372],
            [-4.053686441262802, 51.061040549499232],
            [-4.053361817534623, 51.064765904477909],
            [-4.053746926651367, 51.065437233767085],
            [-4.056463002267528, 51.068655841370848],
            [-4.056142306158743, 51.06953563858994],
            [-4.054526820864338, 51.070832118345649],
            [-4.053997294100856, 51.07183249440704],
            [-4.055219672013586, 51.075063888820878],
            [-4.060122976978949, 51.078332053009831],
            [-4.066783301423188, 51.081155255537517],
            [-4.065872223346566, 51.081664202063735],
            [-4.063364145087465, 51.082157285619353],
            [-4.062195612516176, 51.082882092321164],
            [-4.06112714673224, 51.083481913351712],
            [-4.061075240438984, 51.083952280739645],
            [-4.06300469736128, 51.083929922985597],
            [-4.063691129636219, 51.084627375742969],
            [-4.063193525208159, 51.085060648098285],
            [-4.062113163841559, 51.084912442798156],
            [-4.060985141150462, 51.085233620580112],
            [-4.0591079075377, 51.086014960324881],
            [-4.051085096520006, 51.0873197941076],
            [-4.048881315058048, 51.087148012163574],
            [-4.049224936082506, 51.087427074612087],
            [-4.051012540442285, 51.087422692076011],
            [-4.059098509777627, 51.086124844483507],
            [-4.061815072157775, 51.08508048223581],
            [-4.063206098724542, 51.085182735383981],
            [-4.063873006000533, 51.08454322349084],
            [-4.063189438875139, 51.083878097190443],
            [-4.061249566967362, 51.08369829181192],
            [-4.063303997054911, 51.082377785089676],
            [-4.066262642205589, 51.081806588184619],
            [-4.067074287120692, 51.081213061608373],
            [-4.070000094671517, 51.081252098823917],
            [-4.07285295231401, 51.080521627037186],
            [-4.07531191526328, 51.081072439496317],
            [-4.07397645862629, 51.080391072375143],
            [-4.075776048799877, 51.079895059369449],
            [-4.081545726818452, 51.083016536063788],
            [-4.081396742029645, 51.083163106168591],
            [-4.085175498263803, 51.08410116955455],
            [-4.087558856625125, 51.085375241870082],
            [-4.089402894536432, 51.085959227510791],
            [-4.092470013982024, 51.086527584017794],
            [-4.09833046580702, 51.088298509254344],
            [-4.102696030444282, 51.090600579926338],
            [-4.104988335955664, 51.092461407254625],
            [-4.108044652627536, 51.09372024375152],
            [-4.114517939646248, 51.094552989444864],
            [-4.118666263729605, 51.094540805653921],
            [-4.12877684796597, 51.093338105924353],
            [-4.128810373529809, 51.091922833411488],
            [-4.128231279925142, 51.09147202031977],
            [-4.129153077597909, 51.090212339907282],
            [-4.129277941341182, 51.088143380278446],
            [-4.131888583566599, 51.086503945598551],
            [-4.133946265177044, 51.08579097991975],
            [-4.145944145172452, 51.084012689419666],
            [-4.149117824082311, 51.084433709922294],
            [-4.152588516547043, 51.085499398838664],
            [-4.156924134891538, 51.085478803015647],
            [-4.159768528510506, 51.085018859283508],
            [-4.16225853543506, 51.08400331367092],
            [-4.163734812625147, 51.083908534429533],
            [-4.165200809370048, 51.084266297834638],
            [-4.168852188111133, 51.087507272184602],
            [-4.168544575013641, 51.088654250919156],
            [-4.167173938494038, 51.09064474892832],
            [-4.167737864171378, 51.092198249600237],
            [-4.167579646399202, 51.093364936717961],
            [-4.164407656539919, 51.097950130901495],
            [-4.164226567300082, 51.100145066252516],
            [-4.162700801940203, 51.100733722781598],
            [-4.164097437780551, 51.100512709746063],
            [-4.164214632904168, 51.100289711024764],
            [-4.164306948207311, 51.100114053035064],
            [-4.165317333122607, 51.098191390205372],
            [-4.165390242446733, 51.09672590740626],
            [-4.168974857258197, 51.092691519477825],
            [-4.169070281119447, 51.091770617612895],
            [-4.168416698524424, 51.090926571471989],
            [-4.169295796474111, 51.090456085307103],
            [-4.170263382983991, 51.088673602444537],
            [-4.170910076926121, 51.088273072094914],
            [-4.170819669136745, 51.08722701384152],
            [-4.167224960961004, 51.084192797918384],
            [-4.167653521406612, 51.081998538011796],
            [-4.166926234689363, 51.080582067786843],
            [-4.16830236936713, 51.080516067570343],
            [-4.181845675414685, 51.077229549080663],
            [-4.185355972620193, 51.076192647699663],
            [-4.187386623140291, 51.073299213067259],
            [-4.189192362745047, 51.071785061511392],
            [-4.190417434374091, 51.071337606549939],
            [-4.191167949343646, 51.069517637056471],
            [-4.191967677370774, 51.06940460614414],
            [-4.191138168422366, 51.069370702062223],
            [-4.191633165138342, 51.068910842643838],
            [-4.191124797115836, 51.069238748266343],
            [-4.19076184852173, 51.069109752723584],
            [-4.190794284717011, 51.068225082585556],
            [-4.190263108460751, 51.067797957469786],
            [-4.189799507181167, 51.067592602417669],
            [-4.18900271494088, 51.067737040948913],
            [-4.188585117109276, 51.067417500954683],
            [-4.188883652280057, 51.066303008434637],
            [-4.190522070539761, 51.064879204585523],
            [-4.191921808599322, 51.065383568252088],
            [-4.193325592499686, 51.06669725569332],
            [-4.195469470101586, 51.067782975283926],
            [-4.197534246677961, 51.069456521159566],
            [-4.203026370590823, 51.070435008149168],
            [-4.205418854745734, 51.071186696038019],
            [-4.210281800294574, 51.073692128511205],
            [-4.214807559958555, 51.075075065362668],
            [-4.215812295345328, 51.075726032624978],
            [-4.217700087954153, 51.077675986801033],
            [-4.218971864683713, 51.079787803744367],
            [-4.218979189854828, 51.080597082257867],
            [-4.219470351328608, 51.080686682065],
            [-4.221637614032564, 51.084378707021664],
            [-4.222836364581656, 51.08951549339956],
            [-4.223186617898403, 51.101072691111121],
            [-4.222008069733062, 51.111790175200362],
            [-4.222533633962988, 51.117798623740313],
            [-4.223827891488255, 51.117672377654742],
            [-4.225734823462183, 51.118460780192798],
            [-4.228977937225077, 51.118270366262749],
            [-4.230183985820773, 51.118448812733156],
            [-4.23134212944997, 51.119026573217567],
            [-4.231164180244143, 51.118773655903965],
            [-4.231958418421367, 51.118649669523506],
            [-4.233044333962493, 51.119151448561745],
            [-4.234974211230955, 51.119078585368676],
            [-4.234575644056873, 51.119140167501463],
            [-4.23466782720634, 51.119421700143484],
            [-4.238987032759391, 51.12005941494612],
            [-4.237147728672729, 51.120203457875064],
            [-4.239287318851762, 51.120240726753657],
            [-4.240289811129668, 51.120581255022351],
            [-4.239301354128831, 51.120708119966437],
            [-4.240641133785418, 51.120842526629033],
            [-4.239843549648975, 51.120956743183861],
            [-4.240795641469094, 51.121229882466949],
            [-4.239982903464206, 51.121503575449417],
            [-4.241028016238605, 51.121572576241427],
            [-4.24043312870332, 51.121741367710996],
            [-4.240636911159164, 51.121936217544594],
            [-4.242136626516162, 51.122185355939486],
            [-4.241739049765673, 51.122444800441343],
            [-4.242707304510382, 51.122372263789956],
            [-4.243184995537037, 51.122792986020272],
            [-4.242507003295698, 51.123016444621697],
            [-4.243095722434106, 51.123162533707095],
            [-4.242534950338519, 51.123593291287065],
            [-4.242799509573033, 51.123832838125317],
            [-4.242442519110704, 51.123986282086605],
            [-4.242940820341067, 51.124124213908679],
            [-4.242447142831107, 51.124848670154847],
            [-4.242791112873406, 51.125399667221856],
            [-4.24163189979735, 51.125484860183143],
            [-4.241120468073573, 51.126286095677607],
            [-4.239178586212058, 51.126973544031983],
            [-4.239568286345464, 51.127406758748386],
            [-4.238481441933528, 51.12745276068658],
            [-4.237953331822779, 51.12983175904025],
            [-4.237356320386827, 51.13037290488618],
            [-4.238499770260862, 51.131643375764348],
            [-4.239209289201918, 51.133338547770165],
            [-4.239986993217111, 51.133340734150174],
            [-4.240440864266823, 51.134005647480429],
            [-4.241870629778862, 51.134042087207277],
            [-4.245005474404159, 51.135045849104571],
            [-4.24413849078213, 51.135384464621225],
            [-4.246594927682241, 51.135426312887233],
            [-4.247193867649334, 51.135808714613987],
            [-4.246688165099709, 51.136021688684238],
            [-4.247368841818561, 51.136026590483482],
            [-4.247985077534494, 51.136441032249124],
            [-4.247774337618384, 51.136605169156788],
            [-4.248641449250273, 51.136769260519614],
            [-4.24796807116766, 51.136855959318034],
            [-4.249420197702616, 51.137232730636249],
            [-4.249290759038566, 51.137540100915821],
            [-4.248300458155847, 51.13757713999459],
            [-4.249334673625301, 51.137796469760985],
            [-4.248852317977659, 51.138078253672866],
            [-4.249712579018292, 51.137865641705375],
            [-4.249704949243251, 51.138120304404282],
            [-4.251152873495582, 51.13829208368395],
            [-4.251385909762524, 51.138674315727215],
            [-4.253504307586601, 51.138992335194729],
            [-4.253608150994316, 51.139333884742882],
            [-4.254421370013735, 51.139358674080746],
            [-4.255069125431974, 51.139830928826065],
            [-4.255360056155524, 51.13952313426563],
            [-4.256396360586817, 51.139637137721195],
            [-4.255896986133364, 51.139716026253694],
            [-4.256734890998489, 51.139895011317336],
            [-4.257124954012062, 51.140329960180509],
            [-4.259209666539268, 51.14098038755121],
            [-4.259199566629875, 51.141476124674213],
            [-4.260523475055634, 51.141623204977833],
            [-4.260049389019025, 51.141927358987637],
            [-4.260247614073225, 51.142473029188558],
            [-4.261894818751062, 51.142886345575285],
            [-4.261566331257088, 51.143361262815752],
            [-4.261100158393852, 51.143242573301649],
            [-4.260571427876264, 51.143599049205719],
            [-4.259683020009454, 51.143236700756155],
            [-4.258725446742902, 51.143448567952944],
            [-4.25931626808619, 51.144070292375716],
            [-4.258723040148876, 51.144131220897982],
            [-4.258399572044913, 51.144534083913179],
            [-4.259717821852171, 51.144826977836736],
            [-4.258070291449721, 51.144993717268846],
            [-4.256990169600594, 51.146240390029128],
            [-4.257435719088876, 51.146639191894252],
            [-4.255616916717951, 51.146960294167144],
            [-4.254907819944838, 51.146669095281894],
            [-4.253985392727308, 51.146577164413962],
            [-4.253948789593045, 51.146764934716423],
            [-4.253817614763924, 51.146508451455261],
            [-4.253734796081519, 51.146804135223825],
            [-4.253317196629205, 51.146802292797204],
            [-4.250896535748857, 51.146186108720812],
            [-4.250373991478924, 51.146319380068903],
            [-4.247932700388436, 51.145395055732706],
            [-4.24556977484238, 51.145758840320681],
            [-4.244357651725443, 51.145264993858397],
            [-4.242911807914881, 51.14558233433025],
            [-4.241566279180718, 51.145137784778825],
            [-4.240935142858592, 51.145538403520085],
            [-4.239138192603637, 51.14524907269314],
            [-4.237057525744279, 51.145655842478533],
            [-4.23659997657277, 51.14538939763343],
            [-4.234985582517306, 51.145592949354381],
            [-4.234595305213618, 51.14526855170859],
            [-4.232225159911549, 51.144981077204484],
            [-4.230854076940531, 51.145249175004679],
            [-4.228913445358453, 51.145180970314158],
            [-4.224818074499005, 51.144219343229246],
            [-4.222443875957905, 51.144678204441725],
            [-4.222123927595287, 51.145488301096876],
            [-4.221527558181911, 51.145514924906216],
            [-4.219937326815939, 51.146699893050844],
            [-4.214722079101816, 51.153970177501328],
            [-4.21221530569731, 51.160098990315909],
            [-4.209790468123577, 51.170557412822198],
            [-4.209708853912125, 51.172530307889282],
            [-4.212529738161283, 51.173390691534408],
            [-4.212301276365508, 51.173609057338375],
            [-4.212954690949364, 51.173929448032126],
            [-4.212544603045805, 51.17417913121578],
            [-4.213049073193448, 51.174346754007715],
            [-4.212450226894567, 51.174568532448319],
            [-4.213340115987728, 51.175016649162274],
            [-4.212629462316891, 51.175114637321947],
            [-4.214528542999687, 51.176439385648919],
            [-4.213371353701444, 51.176146527755137],
            [-4.214286525344015, 51.17694220324988],
            [-4.213221462255934, 51.17678070138615],
            [-4.213831442394969, 51.177387899453045],
            [-4.21299110029782, 51.177079145512842],
            [-4.213459418260514, 51.177447104253851],
            [-4.21278362541355, 51.177317799633123],
            [-4.213068644633926, 51.177622677508637],
            [-4.212240608775126, 51.177570896289751],
            [-4.211460911969955, 51.178170215817488],
            [-4.214294309770118, 51.180418002386418],
            [-4.213831772234564, 51.181365670442858],
            [-4.21426699399005, 51.182385374008845],
            [-4.215759196349128, 51.182439846630515],
            [-4.215866728005378, 51.183309268422938],
            [-4.21708378975753, 51.18350383879266],
            [-4.218269000662874, 51.184256591061171],
            [-4.219631540505024, 51.184410602985004],
            [-4.220250385209154, 51.184930363925126],
            [-4.220874777396682, 51.18488073447746],
            [-4.221619037192852, 51.185657115991091],
            [-4.222495396270968, 51.185787954508712],
            [-4.222233551396957, 51.186115793792126],
            [-4.223807064542461, 51.186281036675084],
            [-4.225897210968139, 51.185941788332826],
            [-4.226819163870929, 51.186483624337356],
            [-4.225856686457554, 51.186644944147126],
            [-4.229347761123194, 51.18725294439394],
            [-4.230505787080133, 51.18788108411097],
            [-4.228783874769745, 51.188349196841948],
            [-4.229071144764101, 51.188578448902547],
            [-4.22773367794326, 51.188828771334734],
            [-4.227950148628982, 51.189073764314088],
            [-4.226071199316234, 51.188749812255011],
            [-4.226340693459685, 51.18905585319083],
            [-4.224022136574057, 51.189023528938989],
            [-4.222607506451293, 51.189723130780692],
            [-4.221433183089827, 51.189647417042792],
            [-4.218629580512165, 51.190094552912498],
            [-4.217407627300059, 51.189414447696556],
            [-4.217938915761308, 51.190106755286564],
            [-4.216761864943616, 51.190004066187733],
            [-4.216858026642482, 51.190337696373739],
            [-4.216179976575193, 51.189926960286364],
            [-4.216235785911281, 51.189539187190704],
            [-4.215377094741463, 51.18992688477929],
            [-4.214786711961921, 51.189702441464682],
            [-4.214717841404159, 51.190116541945102],
            [-4.213888817454526, 51.189690665177721],
            [-4.214280860608133, 51.189987218498644],
            [-4.213634315968429, 51.190262965292206],
            [-4.212646335889469, 51.189805014524559],
            [-4.213095592209569, 51.190160744823409],
            [-4.212599070380953, 51.190491204108049],
            [-4.211532370683423, 51.190152546616631],
            [-4.212157354497231, 51.190681227151217],
            [-4.21132118735472, 51.190404757436859],
            [-4.210621238357969, 51.190551992684192],
            [-4.210866487860716, 51.19104109239322],
            [-4.21025127829533, 51.191075205407728],
            [-4.21058492172924, 51.191437627051265],
            [-4.209924089362912, 51.191445620619049],
            [-4.210045363324381, 51.191946058150208],
            [-4.208612559227229, 51.191912872250299],
            [-4.208736237001895, 51.192104793086386],
            [-4.208300972178595, 51.192011388367241],
            [-4.207225016907427, 51.192646847986275],
            [-4.206884250646338, 51.194081425617988],
            [-4.208168434533519, 51.195554579150702],
            [-4.20696111363272, 51.195120507157839],
            [-4.206583239266185, 51.195329988450865],
            [-4.207144207266411, 51.19571690891042],
            [-4.206779972480987, 51.195942321416318],
            [-4.206223681236656, 51.195563405017978],
            [-4.205000926338392, 51.195644923560586],
            [-4.205206874617093, 51.196061030046323],
            [-4.204937434962358, 51.195843974579688],
            [-4.204162645095974, 51.19676421144392],
            [-4.2047411276663, 51.196948462695829],
            [-4.204086034997919, 51.196928435103644],
            [-4.203819635012791, 51.197314773964841],
            [-4.204655974029203, 51.197952827261432],
            [-4.203340353473634, 51.19797942049297],
            [-4.203483870588792, 51.198527109688449],
            [-4.202816840309192, 51.198587340603474],
            [-4.202573213835477, 51.199061382274628],
            [-4.20400359162867, 51.199189119555911],
            [-4.204040430885636, 51.199540964703402],
            [-4.202500566058363, 51.199338846372953],
            [-4.203257466797142, 51.199841706720548],
            [-4.202016690392512, 51.199847988585901],
            [-4.202563448589359, 51.200176741666304],
            [-4.201620060218758, 51.199845563398448],
            [-4.201890773938584, 51.20020919403791],
            [-4.201022611763597, 51.199744392375848],
            [-4.201623538157095, 51.200308655385939],
            [-4.201235676275293, 51.200670294382476],
            [-4.199954392552592, 51.200157501228055],
            [-4.200107536055008, 51.200487374110949],
            [-4.198948642756017, 51.200289732564528],
            [-4.1989394635389, 51.199766492457897],
            [-4.198324612573971, 51.199359861163948],
            [-4.196122875429271, 51.199626960517747],
            [-4.194551247618056, 51.199147439908621],
            [-4.194610535404199, 51.199521349649892],
            [-4.193965051257385, 51.199101780523733],
            [-4.19429454993494, 51.199408566826477],
            [-4.193523387768291, 51.199115461411253],
            [-4.193623052269534, 51.199315042821141],
            [-4.193112994876008, 51.199275145640975],
            [-4.193090540895609, 51.198862772452543],
            [-4.190698003794568, 51.199935552981181],
            [-4.188896982092149, 51.199356830172853],
            [-4.187559235903219, 51.199551834861573],
            [-4.187397892884491, 51.199802169331669],
            [-4.186926304275009, 51.199577162359908],
            [-4.183614052375707, 51.199579698374869],
            [-4.183676832909807, 51.199878003904772],
            [-4.182947698860094, 51.199564259050618],
            [-4.183309803647103, 51.19950264019046],
            [-4.182980389783999, 51.199135566536143],
            [-4.182246626723416, 51.199056629580106],
            [-4.182517041936535, 51.198932871428937],
            [-4.181359162930122, 51.198784500551177],
            [-4.181264566325786, 51.199084843327292],
            [-4.18045853703154, 51.198597147488179],
            [-4.180159667451254, 51.198785283906609],
            [-4.180159775893297, 51.198029842723024],
            [-4.17757699543217, 51.197411557956755],
            [-4.177042033401601, 51.197631961557519],
            [-4.177224848349526, 51.198260788608799],
            [-4.176788494095411, 51.198174481596567],
            [-4.176714642303138, 51.198581454891652],
            [-4.176319933912703, 51.198255148699111],
            [-4.175659082798716, 51.198416733924212],
            [-4.176005271189386, 51.19908029519118],
            [-4.17491081205217, 51.198697753621126],
            [-4.174913528437257, 51.199059234407898],
            [-4.174662894354308, 51.198722149238861],
            [-4.173925579617491, 51.198810501880303],
            [-4.174318549761046, 51.199130553048938],
            [-4.174083755757177, 51.199312086425174],
            [-4.173056186285092, 51.198921089413311],
            [-4.173219967424478, 51.199177053458271],
            [-4.172783142808742, 51.199232834582155],
            [-4.171654740827758, 51.198949819733578],
            [-4.171183056697505, 51.199633975278992],
            [-4.171490581181236, 51.200511408667865],
            [-4.171016044568414, 51.200343949906539],
            [-4.171039630260706, 51.201058480105473],
            [-4.170140731078928, 51.200694746709495],
            [-4.169829282772076, 51.200951438834309],
            [-4.170528302099435, 51.201600374950402],
            [-4.168962577635984, 51.201089824928161],
            [-4.16811605002032, 51.2011702733476],
            [-4.166871714415119, 51.201711349346219],
            [-4.167364608209722, 51.202300270564663],
            [-4.166622317321266, 51.202192614884346],
            [-4.166803779744155, 51.202734247041633],
            [-4.166278371391607, 51.202520047077705],
            [-4.166514982201455, 51.202711718087329],
            [-4.166131672480867, 51.202810549855975],
            [-4.165042932263476, 51.202519541780063],
            [-4.163364650332898, 51.202569484410766],
            [-4.162643178769224, 51.202233887999903],
            [-4.162867956980907, 51.202539999954112],
            [-4.161533444356738, 51.202319155378547],
            [-4.16159120637547, 51.202574396680752],
            [-4.161186347462838, 51.202549503006729],
            [-4.159401613723264, 51.201762281668366],
            [-4.158615495582292, 51.202033102769377],
            [-4.158881427880848, 51.202393321693961],
            [-4.157749233900193, 51.202243343478401],
            [-4.156769011319041, 51.203292954447228],
            [-4.156980156354662, 51.203736926156438],
            [-4.156269481318333, 51.203815682010834],
            [-4.156077389653092, 51.204269787030832],
            [-4.154875448026077, 51.204129160533952],
            [-4.154695408569568, 51.204320437241698],
            [-4.153878016110496, 51.203953279062439],
            [-4.153650174825737, 51.204193998196338],
            [-4.152685040875214, 51.204096652683624],
            [-4.151702186223774, 51.203434847262237],
            [-4.151557610768871, 51.203987895795017],
            [-4.152192893521591, 51.204441159583801],
            [-4.15157964975386, 51.20442995969038],
            [-4.150901971636591, 51.20395768709669],
            [-4.150664773214435, 51.204397323841043],
            [-4.150227625494542, 51.204417052963528],
            [-4.148803965058131, 51.20357266943433],
            [-4.148955339123814, 51.204179632261635],
            [-4.148075803209959, 51.203706555668106],
            [-4.148092748468532, 51.204039894949723],
            [-4.146070774818282, 51.204058129116618],
            [-4.145303394588114, 51.204796166088833],
            [-4.144760548133431, 51.204851987934354],
            [-4.144475506294671, 51.205495736881993],
            [-4.143708713932742, 51.205538572582363],
            [-4.14321008905423, 51.205991978307139],
            [-4.1430937887643, 51.206909623877799],
            [-4.143652461099553, 51.20713231112672],
            [-4.143098206558138, 51.207128079262318],
            [-4.14199078099989, 51.206217563438514],
            [-4.141635767197015, 51.206866183802688],
            [-4.142131669440801, 51.207587355110626],
            [-4.141298547618369, 51.207497389982933],
            [-4.140520624326102, 51.206745404003051],
            [-4.140151903269271, 51.206944607469659],
            [-4.13976947256132, 51.206662921117619],
            [-4.139943348938259, 51.207139979992824],
            [-4.139479994304025, 51.206718579132207],
            [-4.139431771327, 51.207191607425393],
            [-4.138983964148906, 51.207352683447809],
            [-4.137429127504233, 51.207382907468308],
            [-4.138030296409732, 51.207812589038475],
            [-4.137118422727988, 51.207876919088406],
            [-4.13623703863785, 51.207486525729863],
            [-4.136048544673709, 51.208053845511358],
            [-4.135360243900928, 51.208185125100357],
            [-4.135750005170085, 51.208656449509554],
            [-4.134987079937311, 51.208443748599692],
            [-4.135666062464507, 51.208977242815934],
            [-4.135225608333918, 51.209328826877488],
            [-4.133745666891382, 51.208657959250601],
            [-4.1317113414072, 51.208506196631554],
            [-4.130425036656536, 51.209267979474689],
            [-4.129682256252657, 51.209150205513929],
            [-4.128315380162059, 51.209656225065643],
            [-4.128589458384506, 51.210010967073877],
            [-4.128061705937472, 51.210023268543836],
            [-4.128435118297807, 51.210265586625837],
            [-4.127909940116534, 51.210085385176278],
            [-4.12793375437774, 51.210322373487728],
            [-4.127071298628032, 51.210497241810742],
            [-4.127742807513608, 51.210807885045462],
            [-4.127372288568502, 51.210752571535764],
            [-4.127484109718262, 51.211066200324488],
            [-4.126269910121017, 51.210754630701743],
            [-4.124429800778858, 51.210997610476291],
            [-4.121677230785445, 51.210422544661611],
            [-4.121300422906006, 51.210697377695695],
            [-4.121710226783959, 51.210859916563756],
            [-4.120980162249324, 51.211018848190847],
            [-4.121976721575313, 51.212078161583726],
            [-4.121645739302331, 51.212633652314501],
            [-4.119243870170228, 51.212089039105507],
            [-4.117797629329067, 51.211431749316098],
            [-4.117571716334688, 51.211032943055088],
            [-4.115926804682527, 51.211162537094268],
            [-4.113683592000431, 51.210890132744268],
            [-4.113867478989113, 51.211247437709225],
            [-4.113490752238588, 51.211431412190294],
            [-4.112198790252415, 51.211202041097316],
            [-4.112477604636458, 51.211506372499656],
            [-4.111579132992947, 51.211521697161551],
            [-4.111387153140774, 51.210799410083396],
            [-4.113354428716291, 51.210361883343445],
            [-4.113149323803359, 51.209697392675345],
            [-4.113555945290086, 51.210385221672652],
            [-4.115289898851079, 51.210480679802394],
            [-4.115673657227134, 51.209888279732219],
            [-4.114528878013244, 51.209308234260021],
            [-4.113419214534789, 51.20918170105594],
            [-4.112513000544167, 51.209120730690131],
            [-4.111521518685253, 51.209480376873131],
            [-4.110525164121695, 51.20904510173353],
            [-4.110527966348691, 51.209795085788876],
            [-4.1086588665744, 51.209843187363575],
            [-4.108075974581044, 51.209463389064446],
            [-4.107681262668295, 51.209692635062652],
            [-4.107987964121956, 51.21004413850526],
            [-4.107163514950789, 51.21014353082974],
            [-4.106411582512584, 51.209792849926181],
            [-4.106473609190229, 51.210179340410868],
            [-4.105748935215044, 51.210049397418651],
            [-4.105532982710302, 51.210807815780655],
            [-4.106134894202363, 51.211290706126249],
            [-4.105623811632279, 51.211417719109882],
            [-4.104945761532928, 51.210991954354704],
            [-4.105240382854066, 51.211267239962126],
            [-4.104716545299977, 51.211397176508605],
            [-4.105221242002303, 51.211789190747133],
            [-4.103942662207665, 51.21144437466841],
            [-4.103971342863896, 51.211696567950504],
            [-4.10320127151111, 51.211921758126266],
            [-4.102411540254379, 51.212815491820336],
            [-4.103364107001269, 51.213075356481738],
            [-4.102942600141198, 51.213220531721149],
            [-4.10312729822828, 51.213408766125035],
            [-4.102291259253406, 51.213066765595556],
            [-4.100787251353388, 51.213211599593663],
            [-4.099821241197446, 51.212624602768436],
            [-4.10046478674416, 51.212324365751556],
            [-4.099180340040031, 51.21200748203745],
            [-4.098944487183576, 51.212173592377177],
            [-4.098629867477704, 51.211961602386531],
            [-4.099008907347113, 51.21189004974449],
            [-4.098163114017798, 51.211647120263635],
            [-4.098357726545954, 51.211298290016494],
            [-4.09790664063346, 51.210824346111721],
            [-4.096985077355403, 51.210898428663697],
            [-4.095815632805472, 51.211756656341478],
            [-4.096585498695555, 51.212219498528938],
            [-4.096281236372356, 51.212613458265594],
            [-4.095494297582992, 51.212468379858002],
            [-4.095473017796214, 51.212788019625577],
            [-4.094451724321831, 51.21274785634931],
            [-4.094718584892979, 51.212980497832277],
            [-4.093394928061628, 51.212903471920875],
            [-4.093719405333931, 51.213269982358462],
            [-4.093001001306609, 51.212899729800348],
            [-4.092345133302645, 51.213497820472462],
            [-4.092487933715214, 51.213962911907977],
            [-4.091454182625132, 51.213711605170303],
            [-4.091409295706345, 51.213984901479591],
            [-4.090151484849683, 51.214034364621178],
            [-4.089787544145612, 51.214692873767348],
            [-4.089302895683936, 51.214678149480271],
            [-4.089473847722887, 51.214880140416057],
            [-4.088388972903856, 51.215618970286776],
            [-4.088985543894625, 51.216271115074179],
            [-4.088256476003507, 51.216899267346719],
            [-4.088759381422467, 51.21709533240319],
            [-4.08791331392049, 51.21713202157644],
            [-4.088095442622473, 51.217454323611911],
            [-4.085519001858088, 51.217099193271793],
            [-4.085861750461907, 51.216635328775794],
            [-4.083997724817095, 51.216326813749532],
            [-4.084436095959758, 51.216267741431039],
            [-4.083371599995988, 51.21587481892896],
            [-4.083323861751265, 51.216212913400042],
            [-4.082160549660222, 51.216105024997105],
            [-4.082372609759126, 51.217313530070804],
            [-4.080107567551225, 51.21805170229517],
            [-4.077648982073387, 51.21624280514002],
            [-4.07787882535932, 51.215305231057918],
            [-4.077119166809724, 51.215320522249385],
            [-4.076305625503079, 51.215761242640873],
            [-4.076043123123685, 51.21546463084708],
            [-4.075776977984026, 51.21575533711686],
            [-4.075136915348144, 51.215469020374449],
            [-4.074006791445536, 51.215588888726607],
            [-4.071009789923871, 51.214217474093957],
            [-4.070155554235148, 51.21416694594452],
            [-4.069641114431804, 51.214668875094247],
            [-4.069878324773827, 51.215264521606109],
            [-4.072145136717363, 51.215904269076681],
            [-4.072700708003918, 51.216445706586818],
            [-4.074827931085387, 51.216685881415579],
            [-4.074771544505462, 51.217024125361071],
            [-4.073630539975882, 51.216934642051648],
            [-4.072355017612996, 51.217388919425133],
            [-4.0721406711703, 51.216697545284688],
            [-4.070657800001296, 51.216807441801144],
            [-4.070558599870387, 51.216447672746789],
            [-4.069936961352532, 51.216411910778326],
            [-4.070367934490592, 51.216251399513098],
            [-4.069257265238153, 51.216199108017797],
            [-4.06964531438544, 51.215975507314091],
            [-4.06535476138217, 51.215133144499568],
            [-4.064910038659397, 51.214858611189385],
            [-4.065319674194105, 51.214477263504392],
            [-4.06416795494039, 51.214498493346049],
            [-4.063948017521662, 51.214191211228837],
            [-4.064298427748856, 51.2140222513626],
            [-4.063535005532695, 51.213952985143237],
            [-4.065216642829913, 51.213550988698103],
            [-4.063884109957674, 51.213242661431039],
            [-4.063457939521007, 51.213542458118724],
            [-4.063335557455832, 51.213271225062556],
            [-4.062168189062641, 51.213165907941679],
            [-4.061839757515131, 51.212993633391271],
            [-4.062395616561917, 51.212872318412074],
            [-4.060986508238255, 51.212675924334626],
            [-4.06035150402784, 51.211730239905222],
            [-4.059929173830483, 51.21173138333333],
            [-4.060086710544775, 51.212020886326961],
            [-4.059808796474059, 51.211729006721043],
            [-4.058813406285712, 51.211754625623556],
            [-4.05793415340802, 51.212458075079375],
            [-4.05621677713386, 51.211962179263665],
            [-4.055997798700865, 51.211353595053012],
            [-4.055496253913028, 51.211312048450807],
            [-4.056154078282348, 51.210812158072407],
            [-4.055071824982901, 51.210558685878532],
            [-4.055134091840161, 51.210027895183416],
            [-4.054503968794122, 51.209734996676453],
            [-4.0538771817936, 51.20996723625327],
            [-4.052766901211688, 51.209855427496244],
            [-4.053012668619404, 51.209168533789608],
            [-4.051885258604822, 51.208960790743873],
            [-4.052208837318236, 51.208413726073573],
            [-4.050778958411642, 51.207455854591359],
            [-4.049799941677799, 51.20746132529046],
            [-4.050028907390449, 51.207845816389352],
            [-4.049368016431862, 51.207374466107709],
            [-4.049326851402205, 51.207608109932522],
            [-4.048329008005445, 51.207447524934004],
            [-4.047975646441436, 51.207872793405983],
            [-4.047005004943652, 51.207583118822242],
            [-4.046929670692199, 51.207950458275938],
            [-4.046114124055006, 51.207251571791154],
            [-4.045518251972764, 51.207180156800405],
            [-4.045380188061689, 51.207424486872547],
            [-4.044951285644272, 51.207276405186491],
            [-4.045156285896663, 51.207637942418863],
            [-4.044562031952077, 51.207473864871524],
            [-4.045031253167, 51.207885641439681],
            [-4.044624437677388, 51.207686810763384],
            [-4.044470798154421, 51.208032135173482],
            [-4.044216309808772, 51.207425948941292],
            [-4.043789739694976, 51.207653735587947],
            [-4.043164563509364, 51.207276154533979],
            [-4.042616706279058, 51.207416124136358],
            [-4.042648921666898, 51.207108895419189],
            [-4.041970802578202, 51.20715221231594],
            [-4.041512342982275, 51.206594546920826],
            [-4.040727138596198, 51.206676597604442],
            [-4.04063152871257, 51.207136915843904],
            [-4.03865067367254, 51.20669572217502],
            [-4.03804637911825, 51.207535420651354],
            [-4.039734871832669, 51.207936757951856],
            [-4.040013327215049, 51.209669375370282],
            [-4.041292946974524, 51.20979094017904],
            [-4.041399134822579, 51.210022908436137],
            [-4.039278201402871, 51.210302720867254],
            [-4.03719689591353, 51.210151033518414],
            [-4.036560774978237, 51.209719649262574],
            [-4.036616826870253, 51.209951595352962],
            [-4.035889733170182, 51.210054184755968],
            [-4.03605399963068, 51.210240181117946],
            [-4.035397700471719, 51.209805544596996],
            [-4.035141410205558, 51.210194910136678],
            [-4.033580520319084, 51.210026008491717],
            [-4.031868976048535, 51.210532388966605],
            [-4.03084989717151, 51.211515948819006],
            [-4.032445830452028, 51.213519774290447],
            [-4.032071412613655, 51.21457667592508],
            [-4.030623328423346, 51.215542504131172],
            [-4.029764677749621, 51.215553810134288],
            [-4.028425459902123, 51.21635944254669],
            [-4.025369067900903, 51.216344956234828],
            [-4.024206187481321, 51.21546037938726],
            [-4.02208087669981, 51.216138347727693],
            [-4.022119971670254, 51.216670962946694],
            [-4.021116332193362, 51.216707194433297],
            [-4.020501822588987, 51.216244773628738],
            [-4.019805270185943, 51.216622822722222],
            [-4.017193650355924, 51.216300971465685],
            [-4.014626254843849, 51.217122222062649],
            [-4.013142742821113, 51.217286257081803],
            [-4.008583003277487, 51.216620015823054],
            [-4.005951113923004, 51.217212858655095],
            [-4.004814824830392, 51.218283630553216],
            [-4.003831125767308, 51.218219548414268],
            [-4.003022667908079, 51.219289182434416],
            [-4.001899291736697, 51.219108767028757],
            [-4.001689973831188, 51.21864111191563],
            [-4.000859665335769, 51.218613949323988],
            [-4.00000726525168, 51.21794505299264],
            [-3.998787319656677, 51.218410163130066],
            [-3.99787538418231, 51.218415849909114],
            [-3.997029162757877, 51.21798784093663],
            [-3.996791510398214, 51.218452343285151],
            [-3.995965423161272, 51.218257802807642],
            [-3.996057649811121, 51.217874023559247],
            [-3.995540017536177, 51.218122970392912],
            [-3.993791121681025, 51.217582632543781],
            [-3.993770793371048, 51.217874354459582],
            [-3.992648249350528, 51.218374611541094],
            [-3.991424241976647, 51.21831631713426],
            [-3.991326589445411, 51.218011316060945],
            [-3.988592720901048, 51.218438231294215],
            [-3.988014671415631, 51.218017289251158],
            [-3.98747150278532, 51.218173106256181],
            [-3.987707737101924, 51.218437984735594],
            [-3.987156603615732, 51.218807970393598],
            [-3.983902523331668, 51.218728309476951],
            [-3.982794322064548, 51.218399068261824],
            [-3.981525910818737, 51.218973634605192],
            [-3.981413896018039, 51.219566375578644],
            [-3.979312393620313, 51.219583068092525],
            [-3.979340632995214, 51.219239955072879],
            [-3.977967024151894, 51.219399884235848],
            [-3.977641304902074, 51.219150888211168],
            [-3.976367025513461, 51.219824420728429],
            [-3.975698489632474, 51.220699046317655],
            [-3.975460765188667, 51.220431471261406],
            [-3.975043031349435, 51.220575219333348],
            [-3.97468730645711, 51.219993079888667],
            [-3.972655938463226, 51.220143364278975],
            [-3.971131942112874, 51.219432524708637],
            [-3.97016477140597, 51.219486589074535],
            [-3.969942926246864, 51.219958862581677],
            [-3.967851448926587, 51.219271025448059],
            [-3.966781343068819, 51.219564209553319],
            [-3.965197470096016, 51.21929405929319],
            [-3.964760468349072, 51.219490253814229],
            [-3.965096123928389, 51.219772392606238],
            [-3.964707731526885, 51.219798701284837],
            [-3.963146481669486, 51.219218783044148],
            [-3.961156089465523, 51.219389763233316],
            [-3.960600490005854, 51.219154466442014],
            [-3.959563899125108, 51.219327415250149],
            [-3.958895278091644, 51.220004997166328],
            [-3.958213036860739, 51.219215141524188],
            [-3.958369676861052, 51.219734114668654],
            [-3.957931576240233, 51.219770226027165],
            [-3.957573015642293, 51.219318482083807],
            [-3.957415453137008, 51.219891276467052],
            [-3.956748267875342, 51.219624554442831],
            [-3.957052573311838, 51.220181527639845],
            [-3.956500109462199, 51.220391313587946],
            [-3.956049252734463, 51.220160538465102],
            [-3.956009789267265, 51.220546998960749],
            [-3.954575117969186, 51.220620441329181],
            [-3.953957969930413, 51.220148725833859],
            [-3.9539131996109, 51.220748408697688],
            [-3.953577672591419, 51.220535482301784],
            [-3.953699760242904, 51.220780751310208],
            [-3.952490394743516, 51.221034761705738],
            [-3.951615777430621, 51.220637476679407],
            [-3.952137555672058, 51.221021765123091],
            [-3.950872812293993, 51.220779369138036],
            [-3.950745947161133, 51.221199660000963],
            [-3.950188806998931, 51.220994915927314],
            [-3.949609944000877, 51.221428135871037],
            [-3.948349373000756, 51.221386187227459],
            [-3.948294204885912, 51.221741430693655],
            [-3.947309770398212, 51.221761419649582],
            [-3.945365067059799, 51.223325285304846],
            [-3.945488641147139, 51.224048066378039],
            [-3.94447241372146, 51.224402200277318],
            [-3.944550304995254, 51.225026818473196],
            [-3.942386678623549, 51.225036684043765],
            [-3.941700875211779, 51.225654197292968],
            [-3.941930887560967, 51.226048769053698],
            [-3.941065194452989, 51.226544261762598],
            [-3.940814378217841, 51.227152752457833],
            [-3.940062113714426, 51.227247066691199],
            [-3.939687605671817, 51.227979910727377],
            [-3.939996093121025, 51.228026954733167],
            [-3.938671801380517, 51.228858277533078],
            [-3.936636650237687, 51.22875978924705],
            [-3.936816894112261, 51.228447446476864],
            [-3.934933207047217, 51.228954346741475],
            [-3.934429675314659, 51.228696481682739],
            [-3.934028957979735, 51.229216606511308],
            [-3.932653413905162, 51.229135919826675],
            [-3.930554451965266, 51.229805481017216],
            [-3.929199632837904, 51.22946721247051],
            [-3.928277463574621, 51.229575943113375],
            [-3.927227411028091, 51.230292000482201],
            [-3.927483664025872, 51.231248228376252],
            [-3.926198635837042, 51.231861130852415],
            [-3.925476843534991, 51.231521387663946],
            [-3.92564010452057, 51.23196655042976],
            [-3.924807355948559, 51.231336357895323],
            [-3.9245623243463, 51.23154003307787],
            [-3.923466176409155, 51.231325142649524],
            [-3.921578401905815, 51.230502732720836],
            [-3.920631802215987, 51.230541658969251],
            [-3.919661790051796, 51.230121421167986],
            [-3.919130109806134, 51.230390041080661],
            [-3.918273271652557, 51.230073152906868],
            [-3.917113874628956, 51.230609253488467],
            [-3.91570485771973, 51.230343660915807],
            [-3.913086782360154, 51.230488123735547],
            [-3.91049069920511, 51.229677116793383],
            [-3.91000445203056, 51.229833440341437],
            [-3.909680336382126, 51.229512287307379],
            [-3.90975911281869, 51.229858129433268],
            [-3.90886667953168, 51.229752188950506],
            [-3.908518143689813, 51.229256068583091],
            [-3.908452734428241, 51.229648329168192],
            [-3.907722497457472, 51.229689016940583],
            [-3.907857900394936, 51.229015035174136],
            [-3.907379119899414, 51.229559721628455],
            [-3.906809252319272, 51.229564514979145],
            [-3.906798073929091, 51.229017026350107],
            [-3.905561256683888, 51.228756598917819],
            [-3.904266891801712, 51.229114011647255],
            [-3.903392461421049, 51.228992448473335],
            [-3.903638849775604, 51.229687191595737],
            [-3.901314255809511, 51.228368858234028],
            [-3.901068747638183, 51.226408788016009],
            [-3.899086571613632, 51.225748525705733],
            [-3.898185094737663, 51.225733479827817],
            [-3.898079885033992, 51.226100300591909],
            [-3.897119741719586, 51.225912634449067],
            [-3.897297947137, 51.225405240039478],
            [-3.896802835559371, 51.225624603193353],
            [-3.896243859539852, 51.225300926197029],
            [-3.895975342965366, 51.225597549144126],
            [-3.895980690342796, 51.22447334415557],
            [-3.895207579520241, 51.224200795244101],
            [-3.894596684809386, 51.224251158853143],
            [-3.894627049242759, 51.224467397080389],
            [-3.89392237003395, 51.224117291358546],
            [-3.89218806891384, 51.224083305100208],
            [-3.891960603657718, 51.223810001450865],
            [-3.890367696890765, 51.224430186146229],
            [-3.89012959124877, 51.224875586910557],
            [-3.889572736743116, 51.22463727696195],
            [-3.888973374199568, 51.225179338608172],
            [-3.888662734645616, 51.22497302013263],
            [-3.887324788417795, 51.225455950819843],
            [-3.887591182415235, 51.225841046884753],
            [-3.886811403047735, 51.225369806394568],
            [-3.886137962692905, 51.225677433078602],
            [-3.885424221633077, 51.225209615137743],
            [-3.885269036328254, 51.225547552590825],
            [-3.884875764340778, 51.225316476281129],
            [-3.884778782793965, 51.225782074192985],
            [-3.883906701189262, 51.22557489521234],
            [-3.882788176048613, 51.226663061204249],
            [-3.88282152465732, 51.227515054158189],
            [-3.8819982210555, 51.22759125231569],
            [-3.882511653270948, 51.227993968232987],
            [-3.88208500393004, 51.228068279908413],
            [-3.881634513385938, 51.227733795326202],
            [-3.881269840510261, 51.228063405575206],
            [-3.880860956649895, 51.229381148554268],
            [-3.878904670151638, 51.228614012664856],
            [-3.878495569259723, 51.228873283522489],
            [-3.878309800747188, 51.228074996933685],
            [-3.877265825095482, 51.227656496640087],
            [-3.876537692150295, 51.227854332080156],
            [-3.876185661171834, 51.227368964849376],
            [-3.875597950776869, 51.227744400724994],
            [-3.875076569711439, 51.227602576882838],
            [-3.875009244312992, 51.228095568654268],
            [-3.873724645156189, 51.227182685585873],
            [-3.872859716797526, 51.227080526972969],
            [-3.87286186251887, 51.22748607321433],
            [-3.872426085247144, 51.227159411618622],
            [-3.872035011395432, 51.227439954267773],
            [-3.871675942404434, 51.227838690016362],
            [-3.872561481451675, 51.228836221960918],
            [-3.872573031564914, 51.229508707116011],
            [-3.87240497118483, 51.230131908101384],
            [-3.871643762829179, 51.230116210706463],
            [-3.872102942352639, 51.230595380219405],
            [-3.871489645278717, 51.230271555756055],
            [-3.871973347180397, 51.231178396075819],
            [-3.871684078948243, 51.231356587521844],
            [-3.870345049546082, 51.23125659823134],
            [-3.870871900364725, 51.231497277777372],
            [-3.869778104792787, 51.231581311813073],
            [-3.868487549551674, 51.231051564818976],
            [-3.864075153524725, 51.231459187287278],
            [-3.863542932671523, 51.231155612548697],
            [-3.86357676523099, 51.231602920138307],
            [-3.862964596682195, 51.230986764003866],
            [-3.861486158184049, 51.230732416863027],
            [-3.860150777388569, 51.231041429914882],
            [-3.859209059343144, 51.232268641958157],
            [-3.857064354211583, 51.232898934401362],
            [-3.857156769019909, 51.233237398763187],
            [-3.856805082629982, 51.233005568612022],
            [-3.855847985346903, 51.233213202831578],
            [-3.855450321009432, 51.233760882276783],
            [-3.854958364766611, 51.233745301913139],
            [-3.854692019267769, 51.234352947413264],
            [-3.849536528577515, 51.234958848473809],
            [-3.848668744920792, 51.23471626675358],
            [-3.848492296773964, 51.234960063946296],
            [-3.845626227456528, 51.235290386484536],
            [-3.844263387625831, 51.235097847311046],
            [-3.841481198479552, 51.235377284967242],
            [-3.838801795568165, 51.234966187010102],
            [-3.836087617601966, 51.233860424973173],
            [-3.834835218542086, 51.232841397060312],
            [-3.832727084655965, 51.231949973869547],
            [-3.830786750807546, 51.232072081089584],
            [-3.831160289744202, 51.231198423471],
            [-3.830976840237235, 51.23094319941751],
            [-3.830549540708546, 51.231869856288448],
            [-3.830826282259716, 51.230975232096199],
            [-3.830058130343017, 51.230387426395076],
            [-3.830540412381703, 51.231026468303796],
            [-3.829861073310399, 51.232063181707097],
            [-3.827560282999169, 51.232704364993687],
            [-3.825350905407728, 51.231820686149071],
            [-3.82186953836308, 51.231513438929078],
            [-3.820980108172408, 51.231769194086048],
            [-3.818837390819362, 51.231735978639236],
            [-3.817353638091403, 51.231413711090241],
            [-3.816932036511937, 51.231655872180028],
            [-3.816060583727329, 51.231241343227687],
            [-3.815049674772278, 51.231395521023188],
            [-3.814558206931852, 51.231100085753383],
            [-3.813446650058911, 51.231135306540999],
            [-3.812610193110108, 51.231573630785498],
            [-3.813064326864562, 51.232012639017107],
            [-3.812369697113273, 51.231723040743951],
            [-3.812557372535944, 51.232197653363365],
            [-3.810417465102984, 51.231652547251855],
            [-3.810442950074748, 51.231900355366555],
            [-3.810106208453503, 51.231678046961498],
            [-3.809438282684291, 51.232031905769304],
            [-3.808537318114116, 51.231812922216015],
            [-3.807835355764332, 51.232321974788967],
            [-3.806840783688653, 51.23230946135633],
            [-3.806094968724921, 51.232686985833041],
            [-3.801233532513525, 51.235624351380707],
            [-3.801041055691591, 51.236417784088793],
            [-3.800706954589051, 51.236592891149023],
            [-3.800327786684114, 51.236310955859381],
            [-3.799489579620008, 51.23678338438885],
            [-3.798261515387563, 51.237948851192314],
            [-3.798391138059892, 51.238407291317316],
            [-3.797539362334306, 51.238386208119316],
            [-3.795201322782446, 51.240518331268575],
            [-3.794953728097314, 51.2409663750121],
            [-3.795626543422574, 51.24176180742456],
            [-3.794556637270244, 51.242064193749407],
            [-3.794764378795486, 51.242321798842646],
            [-3.793442623415317, 51.242669404736446],
            [-3.791565430787201, 51.244245825702102],
            [-3.790609056307399, 51.244079710317394],
            [-3.789547814302856, 51.244791989502055],
            [-3.789178977660874, 51.244628565425543],
            [-3.789365709329154, 51.244935961589505],
            [-3.788323580520725, 51.245440203358449],
            [-3.788524830395375, 51.245900256530796],
            [-3.787938577122417, 51.245709576190187],
            [-3.788114997575979, 51.246083678561014],
            [-3.787737110485475, 51.245908697612791],
            [-3.786974397153174, 51.246303441830044],
            [-3.786536655681903, 51.246062824421138],
            [-3.786550361148747, 51.246268550237453],
            [-3.785407524763853, 51.246393907931107],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000044",
        LAD13CDO: "18UG",
        LAD13NM: "South Hams",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-3.898141680924155, 50.280616456384109],
              [-3.89689134267801, 50.27914113149636],
              [-3.897785823749619, 50.279398151183578],
              [-3.897832222210885, 50.279082594321416],
              [-3.896875850985375, 50.278822088316815],
              [-3.898542027337498, 50.278825454778634],
              [-3.898936974372621, 50.279121207596774],
              [-3.899083663078192, 50.278680789671022],
              [-3.899647068321634, 50.279012461827492],
              [-3.900151572977678, 50.278746076890108],
              [-3.899800374854739, 50.278350675962535],
              [-3.90051729378661, 50.278413603572893],
              [-3.899960245295013, 50.278135797225225],
              [-3.901129963058791, 50.277973654557648],
              [-3.901212973993695, 50.27773214944478],
              [-3.901832128066203, 50.278300347329825],
              [-3.90109666557063, 50.278582211453724],
              [-3.901865887110803, 50.278619091022726],
              [-3.902146065174446, 50.27930976084027],
              [-3.902916558351954, 50.279343014986658],
              [-3.902615621898873, 50.279680730992162],
              [-3.902925646259851, 50.279993151220793],
              [-3.90200100522699, 50.27986078545932],
              [-3.902247593722298, 50.280342438559408],
              [-3.900435572726357, 50.280449441686478],
              [-3.901120555992025, 50.280907735990382],
              [-3.900524528409, 50.281202604825502],
              [-3.899510610429735, 50.281014116227311],
              [-3.898141680924155, 50.280616456384109],
            ],
          ],
          [
            [
              [-4.105434488268701, 50.306818303862798],
              [-4.10588397380996, 50.30627948748549],
              [-4.108353372140257, 50.306638558475989],
              [-4.108306833009863, 50.307999360709552],
              [-4.105968797623769, 50.308485182714826],
              [-4.104634292952849, 50.30822513696301],
              [-4.105009918662005, 50.307476292915794],
              [-4.104701453042499, 50.307424316110406],
              [-4.105293037028527, 50.307272386978084],
              [-4.104635735927761, 50.306985678950831],
              [-4.105434488268701, 50.306818303862798],
            ],
          ],
          [
            [
              [-3.874858486106455, 50.537620942471555],
              [-3.872824312354977, 50.536110342488804],
              [-3.871944493465949, 50.534490308859262],
              [-3.871263095173916, 50.534102837336057],
              [-3.866469831723692, 50.534505380960191],
              [-3.863245041191419, 50.534425739671484],
              [-3.861499114484584, 50.534022870241245],
              [-3.858614925862697, 50.532591271112402],
              [-3.855256138140329, 50.533228560968467],
              [-3.852861030607728, 50.53559342582129],
              [-3.851493322269944, 50.536162916463979],
              [-3.844575034958976, 50.534375060635021],
              [-3.843310400947713, 50.533246581186894],
              [-3.844013712954516, 50.531850387090628],
              [-3.843768957381688, 50.531001647273847],
              [-3.842645284273998, 50.530518484955934],
              [-3.840275047664059, 50.530235805225828],
              [-3.836578095675594, 50.527508805791605],
              [-3.836095241311108, 50.526737556663221],
              [-3.836554046546365, 50.5253272762709],
              [-3.837125853448105, 50.524901836084595],
              [-3.836243643781139, 50.523328342784019],
              [-3.829313872402716, 50.518224634108677],
              [-3.827344131947311, 50.516386655532905],
              [-3.825508207511162, 50.516769826682676],
              [-3.822755668768278, 50.516575533118555],
              [-3.821870926720431, 50.516861001422527],
              [-3.820154039526801, 50.519239758226199],
              [-3.819917974870364, 50.522087304197157],
              [-3.818119070499958, 50.525092381886623],
              [-3.814050153634217, 50.525276391631571],
              [-3.813666098492658, 50.525526110056759],
              [-3.81362897495617, 50.526140067976677],
              [-3.81465022086138, 50.527641408948256],
              [-3.814839589796716, 50.529487587749756],
              [-3.811177650382638, 50.530802867037345],
              [-3.808931403909708, 50.532871302928775],
              [-3.805476625928446, 50.532897064649994],
              [-3.805187298745145, 50.533893570129692],
              [-3.803871361704276, 50.535426731448609],
              [-3.801848488880972, 50.536816129552456],
              [-3.800862513459336, 50.53693931453315],
              [-3.799449084174549, 50.536399061907474],
              [-3.798339048652501, 50.534967315836099],
              [-3.794851512772412, 50.532573034703788],
              [-3.797678795828114, 50.529887019550586],
              [-3.797978012284861, 50.525844174496427],
              [-3.799618100826708, 50.522746536421707],
              [-3.799481522140094, 50.52171525778828],
              [-3.798153592034467, 50.520962313572113],
              [-3.794245794886418, 50.520849044740388],
              [-3.791126964619238, 50.521452903771973],
              [-3.789391862817177, 50.521232264234328],
              [-3.788004990450891, 50.520557458045694],
              [-3.784768611325877, 50.517860423457826],
              [-3.785240149345492, 50.513870727449557],
              [-3.787481746959323, 50.509995055191297],
              [-3.786282036181396, 50.507431347542258],
              [-3.787082494008801, 50.50502489879127],
              [-3.788214717279878, 50.503824815810098],
              [-3.791640546870292, 50.502307923048022],
              [-3.791754387911574, 50.501440962244629],
              [-3.790667460961662, 50.500181466416819],
              [-3.789201934976183, 50.49942424277652],
              [-3.785554813210623, 50.498734673586242],
              [-3.780387946107528, 50.498837226230883],
              [-3.778375144127176, 50.498557717577015],
              [-3.776166628486021, 50.497120057309871],
              [-3.775870781594702, 50.495585726891697],
              [-3.779070001797512, 50.494909986582307],
              [-3.782940519779811, 50.494765303350476],
              [-3.787176509841652, 50.494927864555137],
              [-3.790785516003986, 50.495623364902393],
              [-3.789744685927008, 50.494087039303636],
              [-3.789619929646789, 50.492987210795022],
              [-3.792250378365937, 50.493013278901707],
              [-3.795293483933603, 50.491949160982784],
              [-3.794999512367185, 50.490888822842827],
              [-3.794245495799097, 50.490262787358233],
              [-3.794407425897919, 50.489959895156495],
              [-3.79825166704153, 50.489431966528258],
              [-3.796940131585565, 50.487265818817299],
              [-3.795527912288518, 50.486902682484285],
              [-3.794862391444498, 50.48641558960081],
              [-3.793098519837071, 50.48398655948661],
              [-3.791176885282761, 50.482301026596318],
              [-3.794332678554739, 50.480954584933599],
              [-3.793509282006973, 50.480352998566815],
              [-3.793279594684362, 50.478493007227108],
              [-3.792373956585078, 50.478547434897649],
              [-3.791893527282177, 50.477807443709722],
              [-3.788855266933943, 50.478268814916376],
              [-3.786254324890976, 50.477844691735967],
              [-3.787205388455567, 50.476939692601697],
              [-3.787398666243245, 50.475637108122761],
              [-3.786328066884196, 50.475426904962546],
              [-3.786616914785546, 50.473294517730345],
              [-3.787390428775642, 50.472018996193242],
              [-3.786307351766292, 50.472709272465735],
              [-3.784987728069593, 50.472882422196363],
              [-3.784576401150028, 50.473224203947012],
              [-3.781989575866429, 50.47230510676399],
              [-3.780989564579812, 50.47003327587877],
              [-3.779773414128401, 50.468607469551259],
              [-3.778800717672456, 50.465894501224611],
              [-3.776654100938624, 50.463962139466773],
              [-3.77594452042405, 50.464165442939141],
              [-3.774226827624825, 50.463561180625199],
              [-3.772894511251516, 50.463502345820466],
              [-3.77224858649933, 50.464390889752913],
              [-3.768118543555, 50.467069151398078],
              [-3.765802299404358, 50.466700505742907],
              [-3.764121610103178, 50.467505771744918],
              [-3.762021441212625, 50.467756149783348],
              [-3.75883771121309, 50.466832088570811],
              [-3.758921934153838, 50.470050618122897],
              [-3.757670675880305, 50.473549251881117],
              [-3.75786838798514, 50.474433958175958],
              [-3.760888484270967, 50.477198867551323],
              [-3.76076932577415, 50.47854345290699],
              [-3.761177567788391, 50.479060716108343],
              [-3.766280612758726, 50.481877580876898],
              [-3.767461925060517, 50.484902261113049],
              [-3.769107969001571, 50.485796416571411],
              [-3.768342568865481, 50.488931613886308],
              [-3.765940219100205, 50.492671761438579],
              [-3.76556082272042, 50.494029291973128],
              [-3.765814536965785, 50.496106614674822],
              [-3.768211774876832, 50.500870218084657],
              [-3.768047558059426, 50.502702956503853],
              [-3.767157669326661, 50.504401016400728],
              [-3.765014071492616, 50.506290772004654],
              [-3.759281625749189, 50.508767230586926],
              [-3.756320689918198, 50.510423644659646],
              [-3.750484957674822, 50.509371157818087],
              [-3.747446487560895, 50.508058759546167],
              [-3.745907463824766, 50.507743692598865],
              [-3.74499109254789, 50.507600045691298],
              [-3.741868844300467, 50.507950811772574],
              [-3.737383239820821, 50.506355813817429],
              [-3.737227395445, 50.505491139039826],
              [-3.735839348576711, 50.503828214434193],
              [-3.735254050070426, 50.502403333136662],
              [-3.730406185551904, 50.498862716144394],
              [-3.72761524357789, 50.497846515173727],
              [-3.725173189429673, 50.495334804153039],
              [-3.723325754368022, 50.494394447007295],
              [-3.722674179189041, 50.494583071496685],
              [-3.722313683790047, 50.494285317025692],
              [-3.721508027029336, 50.494346704869976],
              [-3.720680094954661, 50.494027979122862],
              [-3.720209512817646, 50.493416163910119],
              [-3.719344096006651, 50.493462231443509],
              [-3.718476098038512, 50.493048747920739],
              [-3.718687071320817, 50.492452041702521],
              [-3.716940806503639, 50.49067906303366],
              [-3.717591047253138, 50.489210673030527],
              [-3.716835606292752, 50.488282869494185],
              [-3.717031786932039, 50.487433658366761],
              [-3.716186196343958, 50.486740120195897],
              [-3.716060424457438, 50.485758952958783],
              [-3.715458822450061, 50.48502853374675],
              [-3.712046859638012, 50.483022804991947],
              [-3.711622971839797, 50.482370695217071],
              [-3.710929548544519, 50.482841376362202],
              [-3.710431093363358, 50.48262206114093],
              [-3.710222573146642, 50.481804891755203],
              [-3.70835710362956, 50.481005766491563],
              [-3.707854482034991, 50.481295550512769],
              [-3.707504739294299, 50.480977806248418],
              [-3.706462205188611, 50.481148693812536],
              [-3.705991954085146, 50.480498143116812],
              [-3.703849926932254, 50.480471070915989],
              [-3.703137995943923, 50.480036301323068],
              [-3.69997844685074, 50.479658899817387],
              [-3.698663338995399, 50.478713972426789],
              [-3.698269697017155, 50.478860021935148],
              [-3.697166442753649, 50.478279831471745],
              [-3.694046557147678, 50.477863917009266],
              [-3.693735745962147, 50.475950965428638],
              [-3.692261888349819, 50.474737563860103],
              [-3.691207201949584, 50.47448668309184],
              [-3.691368221671673, 50.473983388085436],
              [-3.690768555082824, 50.473647641743781],
              [-3.69079164208284, 50.4732677680975],
              [-3.689866962390063, 50.472907060384316],
              [-3.689477468982086, 50.472259767415089],
              [-3.6884860759548, 50.472003446185944],
              [-3.686286670547809, 50.470115169628549],
              [-3.682998390105007, 50.469106012664838],
              [-3.682453041967696, 50.468431271307018],
              [-3.681906976239986, 50.468530004099001],
              [-3.681482180524962, 50.468233952897087],
              [-3.681506191332479, 50.467957496385161],
              [-3.680005920947882, 50.4680897938943],
              [-3.679586355445391, 50.467622778224744],
              [-3.677861662315176, 50.467027991869848],
              [-3.676768441022443, 50.466995186514126],
              [-3.674984208715819, 50.466149389638204],
              [-3.674335278171042, 50.46624776912585],
              [-3.671276035125488, 50.464446230690356],
              [-3.671124552175446, 50.46314700509847],
              [-3.669633036383428, 50.462124240870438],
              [-3.668312423795116, 50.464154197644419],
              [-3.667961273470194, 50.465466929273923],
              [-3.668603401424575, 50.466055808201482],
              [-3.668287857112432, 50.466778936849863],
              [-3.666997977306501, 50.46720394497833],
              [-3.666308933095193, 50.467885651347892],
              [-3.664993052579732, 50.467892798570276],
              [-3.664704137082853, 50.469014859665791],
              [-3.664110729045432, 50.468890241017149],
              [-3.663951206420099, 50.469243279955684],
              [-3.662562437576894, 50.469784772840576],
              [-3.661963706545947, 50.469669213076131],
              [-3.657993176858243, 50.471699096987422],
              [-3.65683926062473, 50.471464609057456],
              [-3.655472450531194, 50.472074056352838],
              [-3.654694812472076, 50.471880060771397],
              [-3.653283764384486, 50.470657181979618],
              [-3.654674754470275, 50.470337015652518],
              [-3.655146830354688, 50.469812259691089],
              [-3.654847562999631, 50.469716685921782],
              [-3.652700839702049, 50.469782276101895],
              [-3.652221659245387, 50.47022527964053],
              [-3.650102828097113, 50.470566534196536],
              [-3.650140229917141, 50.468483947086376],
              [-3.652436839295524, 50.468151858348328],
              [-3.652918499927109, 50.468750296935724],
              [-3.654071794160452, 50.467798554033166],
              [-3.653603070439468, 50.46740949110643],
              [-3.6557924013942, 50.466509554547763],
              [-3.657724010239682, 50.465011565068203],
              [-3.65891414507575, 50.464594377711492],
              [-3.653038609905862, 50.463830795762114],
              [-3.647374430801285, 50.462169944140193],
              [-3.645222456449424, 50.461140930148858],
              [-3.6429716879645, 50.462543388720341],
              [-3.640603901341821, 50.465616695617257],
              [-3.638040622952286, 50.466875065618247],
              [-3.637168702907648, 50.467863161153034],
              [-3.63446185615154, 50.467048612027419],
              [-3.633958605684052, 50.466429715552309],
              [-3.633242902358191, 50.466392098058471],
              [-3.632366677005016, 50.466930530031028],
              [-3.631343507839158, 50.466229877857906],
              [-3.627320746143356, 50.466193579470037],
              [-3.626226840069042, 50.466796166973609],
              [-3.623428579818596, 50.466678755821434],
              [-3.621398241925973, 50.468289967955513],
              [-3.620944120034016, 50.469720904409186],
              [-3.616569851023033, 50.469674739700537],
              [-3.613826556978179, 50.469313502383301],
              [-3.61327710759118, 50.469813979120929],
              [-3.614372822834742, 50.472401566757952],
              [-3.61257279425781, 50.472265541197736],
              [-3.613205462463834, 50.475615928008715],
              [-3.609782371219016, 50.48072500388006],
              [-3.608566162273347, 50.481602522321367],
              [-3.607057100083737, 50.482075758392924],
              [-3.604403830259795, 50.484058612228488],
              [-3.603078917676787, 50.484520262979146],
              [-3.602348648120939, 50.484392718058551],
              [-3.599307062295435, 50.484445356061478],
              [-3.598314049500405, 50.484175698956662],
              [-3.598030159402201, 50.484409836911233],
              [-3.595201459810455, 50.483249791185052],
              [-3.595096336625991, 50.482723302162789],
              [-3.592410237326618, 50.480936171899785],
              [-3.591434836158698, 50.479676908317856],
              [-3.590708977771291, 50.479635569888231],
              [-3.588832839179255, 50.47858106057766],
              [-3.587548701203004, 50.478577003814188],
              [-3.584233800358642, 50.477737205885155],
              [-3.583558343815312, 50.476714823586512],
              [-3.58363669379183, 50.475980770688068],
              [-3.584115077003356, 50.475830359312496],
              [-3.58355199199564, 50.473362058118354],
              [-3.582589682729258, 50.47193436007295],
              [-3.582785630072637, 50.469024928358756],
              [-3.586622704701359, 50.464771700339249],
              [-3.590491372874161, 50.462320250800751],
              [-3.591467388816954, 50.459664546228865],
              [-3.58890538096777, 50.459812886372589],
              [-3.588683388359636, 50.458485745599603],
              [-3.588102518814714, 50.458304808093686],
              [-3.588263708673145, 50.457228756218555],
              [-3.586967054103782, 50.457001821901791],
              [-3.586928197679883, 50.456177626831597],
              [-3.587481242956057, 50.455444287249257],
              [-3.587971402209883, 50.455357553384388],
              [-3.586766700888234, 50.453709251231672],
              [-3.586868387003348, 50.452243684000777],
              [-3.588409013784091, 50.451978028714329],
              [-3.58864544190007, 50.450625739297024],
              [-3.589215256207782, 50.450815818683886],
              [-3.590614036911735, 50.450270567419395],
              [-3.592165029292044, 50.450232261929514],
              [-3.591945773494692, 50.449113742595756],
              [-3.594244562883131, 50.448527347236677],
              [-3.595554182420905, 50.448517486444302],
              [-3.597061464002069, 50.448981566176528],
              [-3.599576267074144, 50.447994575413162],
              [-3.600578545771043, 50.447809907941028],
              [-3.601256937866192, 50.448086573292485],
              [-3.602939316997742, 50.447978861984893],
              [-3.606035291887334, 50.447313791922646],
              [-3.607139540296386, 50.446731034993086],
              [-3.612758107418605, 50.446981547585544],
              [-3.615056093871485, 50.445554732188299],
              [-3.615621947575173, 50.444386678111094],
              [-3.618872993078985, 50.442582296910331],
              [-3.623545101003027, 50.441321907942367],
              [-3.625056934546754, 50.441300797090385],
              [-3.625122085082007, 50.44044817546613],
              [-3.624570432651312, 50.440254420439949],
              [-3.625267345358915, 50.438892019597979],
              [-3.625426918447854, 50.436644040605472],
              [-3.627108355957616, 50.434797490208865],
              [-3.627937729160935, 50.434919893118511],
              [-3.628393742043921, 50.434338806431491],
              [-3.628043297478418, 50.433605321230317],
              [-3.628024714580984, 50.426004018820265],
              [-3.624120630861806, 50.424974845963114],
              [-3.623457279830655, 50.424350043555975],
              [-3.623823245233554, 50.422701765943813],
              [-3.622750822714532, 50.42299913911517],
              [-3.621520446299548, 50.422794151950093],
              [-3.619394929117052, 50.423172725265488],
              [-3.61925675053522, 50.422753738972915],
              [-3.618732103447303, 50.422684594641126],
              [-3.616674325489543, 50.42310984253001],
              [-3.614023998130308, 50.422836365391639],
              [-3.614443149158095, 50.41909991788652],
              [-3.613820562636199, 50.417680341709371],
              [-3.613892788140679, 50.415498340472119],
              [-3.613056743317224, 50.415002688007263],
              [-3.613210393684543, 50.414512192830742],
              [-3.611995074956923, 50.414369853562569],
              [-3.61159489873447, 50.413776411610527],
              [-3.609839854048731, 50.413946417869766],
              [-3.60919219722223, 50.413566848284667],
              [-3.607232954506602, 50.413317830728346],
              [-3.607345693707391, 50.412739769435994],
              [-3.604464443507841, 50.412236328342743],
              [-3.604583533735175, 50.411970267337999],
              [-3.603788565544044, 50.411602593381168],
              [-3.603737983273407, 50.411230047813632],
              [-3.602350297722413, 50.411064802253058],
              [-3.600257505954285, 50.410189739812012],
              [-3.599029327902654, 50.409284767591821],
              [-3.597515909322984, 50.410149185418042],
              [-3.596299013564947, 50.409829526378303],
              [-3.594165243870913, 50.409992802310541],
              [-3.591397667923186, 50.408630370882562],
              [-3.589423951234971, 50.409201487089234],
              [-3.587792704185643, 50.410545857950069],
              [-3.585328087487649, 50.410623549930072],
              [-3.582531565710661, 50.410202054024268],
              [-3.58196911082558, 50.411189130245511],
              [-3.579834180819992, 50.410220739573035],
              [-3.580124653760774, 50.409433434040167],
              [-3.578336536030275, 50.408733714787296],
              [-3.577504789997437, 50.407717009322994],
              [-3.578861724164746, 50.404168530056637],
              [-3.576371676673051, 50.403724738603891],
              [-3.575356662806576, 50.402778854454873],
              [-3.573600540690392, 50.40218565575983],
              [-3.576026345644285, 50.400568997667094],
              [-3.575331217728425, 50.399125917023028],
              [-3.575510709095702, 50.397197911640014],
              [-3.573768835917799, 50.396731335445573],
              [-3.572959389930148, 50.395911259397089],
              [-3.574729756203194, 50.395031088656268],
              [-3.574703886051707, 50.394123064164582],
              [-3.574366458780212, 50.393960348089799],
              [-3.573024948914605, 50.395086539847384],
              [-3.571899032774355, 50.395161123818674],
              [-3.571863089948592, 50.394755089578297],
              [-3.569169824890845, 50.393892082814489],
              [-3.569820478989969, 50.392974926498638],
              [-3.569504054462294, 50.392212025805698],
              [-3.56723000862242, 50.391811892971795],
              [-3.566896703956649, 50.392284961966247],
              [-3.565785041487644, 50.391594821375939],
              [-3.564648118619863, 50.392187526891966],
              [-3.563829742355321, 50.391473634747946],
              [-3.562196997267921, 50.387683105977757],
              [-3.55997836577173, 50.384933803792983],
              [-3.559493718290113, 50.382295224076657],
              [-3.558886947960529, 50.381978683635104],
              [-3.557147690164586, 50.379594343640889],
              [-3.555059418007884, 50.377971925268845],
              [-3.548490763099762, 50.376142107815511],
              [-3.544240572870242, 50.373498689751187],
              [-3.543713872319255, 50.373995846796682],
              [-3.541654193563448, 50.374534922411115],
              [-3.541199848443706, 50.375399854708093],
              [-3.539073514864264, 50.375359666912651],
              [-3.536549499301038, 50.375982143594683],
              [-3.535422522641685, 50.376098661093408],
              [-3.533906630519092, 50.377325637383223],
              [-3.5294171925522, 50.378284155672418],
              [-3.526257058350304, 50.377676327183856],
              [-3.52426229571325, 50.377662921164308],
              [-3.515507752055151, 50.379312595099563],
              [-3.515499273499921, 50.378958350450176],
              [-3.514844391978906, 50.37884276983597],
              [-3.509389907561029, 50.379545965592165],
              [-3.507813692647171, 50.379338873137186],
              [-3.508261749196218, 50.378876178086429],
              [-3.507981243067357, 50.378235861592842],
              [-3.508568724453248, 50.378089736823888],
              [-3.508254412730766, 50.377048736789035],
              [-3.508975137999625, 50.376625671637477],
              [-3.50972094758772, 50.376771582835623],
              [-3.512308611592422, 50.375529190511116],
              [-3.514104991737676, 50.37421790123539],
              [-3.515575167429294, 50.37242607043104],
              [-3.516298600891814, 50.370813044303773],
              [-3.515791441795973, 50.36946069414369],
              [-3.514540142612199, 50.369290828902585],
              [-3.51436302852237, 50.368983749197419],
              [-3.513859423924667, 50.369331172387653],
              [-3.513414744003598, 50.369232633363644],
              [-3.513661745027337, 50.369040548230956],
              [-3.512774844896642, 50.369274237920898],
              [-3.513111300917592, 50.368976661971217],
              [-3.512835368021385, 50.368745514477702],
              [-3.51360599868366, 50.368481859648085],
              [-3.513669027649208, 50.368739161150614],
              [-3.514107790071814, 50.368121869771066],
              [-3.515336007767679, 50.368009635027171],
              [-3.515980719770229, 50.367590213826368],
              [-3.516306851539459, 50.367102094396081],
              [-3.516160727751371, 50.366355715486634],
              [-3.51564603206377, 50.366089014532378],
              [-3.516190092719529, 50.365688891586331],
              [-3.515890673983756, 50.364676495737591],
              [-3.517250342301609, 50.364191981460266],
              [-3.51782640717206, 50.364267205445053],
              [-3.518539136698495, 50.363690389221311],
              [-3.520459786743638, 50.360391539141226],
              [-3.520541378825556, 50.359821163433921],
              [-3.519711668867968, 50.35924201756098],
              [-3.521049405770145, 50.358781128238739],
              [-3.520465277380831, 50.358539640210388],
              [-3.521052919306033, 50.358143420439873],
              [-3.52078077955302, 50.356666597613447],
              [-3.519613548505676, 50.356698049189731],
              [-3.517298482504352, 50.355605857495704],
              [-3.516263159965351, 50.355717397664087],
              [-3.516092852049855, 50.355490276317497],
              [-3.516674664267568, 50.355396346897045],
              [-3.515241893193898, 50.355166800264229],
              [-3.515897572796074, 50.354479219124308],
              [-3.514718375433707, 50.354088967584488],
              [-3.515363520302454, 50.353468080854746],
              [-3.51472056969783, 50.35345127702832],
              [-3.514455768069959, 50.353125552969694],
              [-3.51494760371145, 50.352943765286327],
              [-3.514730618487306, 50.35160291534541],
              [-3.51504364992649, 50.351854260943902],
              [-3.515317503802131, 50.351272388760862],
              [-3.516291723967645, 50.350834278213142],
              [-3.515115028120992, 50.350828933976139],
              [-3.515180220665799, 50.350580754086465],
              [-3.516351127680986, 50.35031635830881],
              [-3.517037431118674, 50.349842423559323],
              [-3.51697034246805, 50.349457463720363],
              [-3.517324663647833, 50.349593143441879],
              [-3.518220350486019, 50.348986057969263],
              [-3.518134358019873, 50.348713768489503],
              [-3.517168060086791, 50.348869385206243],
              [-3.517492016155352, 50.348669990794079],
              [-3.517249675721949, 50.348254940748873],
              [-3.518413457966008, 50.347637159157365],
              [-3.51864552910931, 50.347069314960727],
              [-3.517624646299428, 50.347098835657803],
              [-3.518277546370127, 50.34690143989274],
              [-3.517694666493726, 50.346870377546139],
              [-3.517912635090514, 50.346521269064169],
              [-3.519352684798164, 50.346238038313416],
              [-3.520541321327895, 50.346488714762991],
              [-3.520320189390392, 50.346210099829456],
              [-3.520762565353152, 50.346154847504522],
              [-3.520207782116647, 50.345903081217109],
              [-3.520567267793766, 50.34589298335576],
              [-3.520334889189955, 50.345394166507944],
              [-3.521237503640729, 50.345095454994414],
              [-3.520794472621423, 50.345041892295768],
              [-3.522991474734846, 50.344610205937187],
              [-3.52364731556258, 50.345296835801712],
              [-3.524239129546435, 50.344466141037344],
              [-3.526269025048104, 50.344128320627533],
              [-3.525203092447722, 50.344021793215987],
              [-3.527356544046384, 50.343899983609873],
              [-3.526999898123667, 50.343516134790519],
              [-3.527713911327726, 50.343561616013943],
              [-3.527673673941611, 50.343928194344322],
              [-3.52921908562698, 50.343866503436054],
              [-3.530047651134101, 50.344283708065426],
              [-3.531077465901323, 50.344359188345734],
              [-3.531312132639554, 50.344139345639299],
              [-3.530738499748626, 50.343302378139747],
              [-3.531874998158036, 50.343156097107318],
              [-3.531440358527458, 50.342838944601425],
              [-3.53176521230771, 50.34232111563901],
              [-3.532572487758768, 50.342033464883649],
              [-3.532199236572896, 50.341527534307524],
              [-3.533899465033602, 50.339905104949459],
              [-3.533455962131941, 50.339879477789367],
              [-3.53360580761333, 50.339426908771117],
              [-3.532898726742771, 50.339551356417751],
              [-3.533077059093757, 50.339241414748017],
              [-3.532586065566117, 50.339137264667116],
              [-3.5336531540997, 50.338409978179307],
              [-3.535141730674794, 50.338509048236993],
              [-3.534297605904789, 50.337171113838373],
              [-3.535901646001441, 50.336581518866765],
              [-3.537361705604992, 50.336756485550012],
              [-3.538270880130271, 50.337234623365092],
              [-3.539236143981899, 50.336404303450159],
              [-3.540193412915302, 50.336153286263134],
              [-3.541296505146761, 50.336283466480168],
              [-3.541490725602668, 50.335771838142584],
              [-3.542575034895821, 50.33584289575365],
              [-3.542432119963105, 50.335638832593666],
              [-3.544116922330245, 50.335464468028874],
              [-3.543784909687509, 50.335712611799494],
              [-3.545004497231901, 50.336098434332762],
              [-3.544676057191863, 50.336326746625815],
              [-3.545279828485738, 50.336701860771967],
              [-3.545541447878099, 50.337878378301333],
              [-3.546293547705546, 50.337973604421563],
              [-3.5470212271691, 50.33883093139081],
              [-3.547824213515603, 50.338761782020882],
              [-3.548473811730417, 50.338124459089173],
              [-3.549696573168271, 50.338086579030453],
              [-3.550421551412, 50.337693772777357],
              [-3.55071290767521, 50.338182750598826],
              [-3.553033234601817, 50.33853401343579],
              [-3.552972336224439, 50.338993514565033],
              [-3.55395104089143, 50.339873526957454],
              [-3.553771351432062, 50.340912922276132],
              [-3.554350146847797, 50.341336890297143],
              [-3.555220175806359, 50.341211031523635],
              [-3.555076174851211, 50.341621279728322],
              [-3.555572485304473, 50.341283664836091],
              [-3.555909638955712, 50.34183857145014],
              [-3.556289685244921, 50.341813697545419],
              [-3.55599364363382, 50.342602825440117],
              [-3.556482288511584, 50.342675429838039],
              [-3.556459407680021, 50.343136222340917],
              [-3.55718207819102, 50.342457400124204],
              [-3.557337511868449, 50.34170972593207],
              [-3.557464181186362, 50.341930177534508],
              [-3.557644236716166, 50.341634564772349],
              [-3.558723498728053, 50.341589518973954],
              [-3.558742413792828, 50.341952617829875],
              [-3.559587276558773, 50.341702048976266],
              [-3.55965782152622, 50.342053662366098],
              [-3.560043292877394, 50.341808353481071],
              [-3.560643000062818, 50.342525210034545],
              [-3.560493482001415, 50.342937337886042],
              [-3.561823353496551, 50.34243380893173],
              [-3.562732654625761, 50.342947730223429],
              [-3.562599589699035, 50.34386059841475],
              [-3.563213208485141, 50.343757912864255],
              [-3.563258318353275, 50.344618019945472],
              [-3.563973985473194, 50.344754993884486],
              [-3.563945770521758, 50.345735705582712],
              [-3.56475436549692, 50.345535053707493],
              [-3.564889118286524, 50.346000021790381],
              [-3.565176205436615, 50.345882834021062],
              [-3.565916355521769, 50.346421492543627],
              [-3.567721476860767, 50.346946688678237],
              [-3.569821207611408, 50.346644040016621],
              [-3.572406274862649, 50.347078583215158],
              [-3.573564691125266, 50.347893942840962],
              [-3.573667579731278, 50.348370124918453],
              [-3.573255810011998, 50.348372100689289],
              [-3.573217325410925, 50.348738672222929],
              [-3.570604192785506, 50.350267888202332],
              [-3.570300418655639, 50.351285600983154],
              [-3.569559472409079, 50.350684023937355],
              [-3.568773526847552, 50.351104752921515],
              [-3.567896599713064, 50.350940303466352],
              [-3.564087484980423, 50.352785889953417],
              [-3.56457169875156, 50.353104952143639],
              [-3.56696360945592, 50.352104097719611],
              [-3.57026094473517, 50.351452520744672],
              [-3.570564645600092, 50.351795581520435],
              [-3.570017389050835, 50.355105520978114],
              [-3.570557274778484, 50.356853830601402],
              [-3.576274072120508, 50.36311263533937],
              [-3.576219102459377, 50.364593769213457],
              [-3.575170885926222, 50.364445186713297],
              [-3.574935370466285, 50.364848603778626],
              [-3.575409618851809, 50.365752356435415],
              [-3.577036359581844, 50.366342783739491],
              [-3.577207921921423, 50.367149003818618],
              [-3.576304675346918, 50.367048830225151],
              [-3.576743783289921, 50.367357660843687],
              [-3.576517525312347, 50.367616154387662],
              [-3.574937242262536, 50.367453197718021],
              [-3.574497069235282, 50.366772029374403],
              [-3.573599398553484, 50.366499976773227],
              [-3.57223500369125, 50.367634571769742],
              [-3.571066737815202, 50.367939965881376],
              [-3.570351323204211, 50.368545922720685],
              [-3.57327537630485, 50.367841747076994],
              [-3.573784438100717, 50.367205288127522],
              [-3.574045366662318, 50.367824130192496],
              [-3.575363998026705, 50.368153433255607],
              [-3.576707072502818, 50.370364802304579],
              [-3.578062657353825, 50.375746315139125],
              [-3.579816809243874, 50.377493381412897],
              [-3.580964200144475, 50.378246759317648],
              [-3.581292658242875, 50.378057919152184],
              [-3.581548783674417, 50.37844296948473],
              [-3.584537320767098, 50.37906962431768],
              [-3.586302897724039, 50.379799234337533],
              [-3.586492952916329, 50.380352460662102],
              [-3.587609317934719, 50.381098102243804],
              [-3.588368482722706, 50.381339561825655],
              [-3.590828862123515, 50.381306825679481],
              [-3.591636162388221, 50.382355252427622],
              [-3.588988585499525, 50.38356425962008],
              [-3.587002020148473, 50.384942263870983],
              [-3.582776280558806, 50.386163662317941],
              [-3.580988399232345, 50.387856339447772],
              [-3.580496534003279, 50.388801981097167],
              [-3.577725098395425, 50.389053669998113],
              [-3.577249628435991, 50.389817399809196],
              [-3.578532340120652, 50.390952102455117],
              [-3.57521690380727, 50.391560973710419],
              [-3.574782777592486, 50.39191851179767],
              [-3.574856198416686, 50.392563273289042],
              [-3.57549837994293, 50.393006963250762],
              [-3.575299430705465, 50.393286667970742],
              [-3.577106057359463, 50.392528284740933],
              [-3.577214396828654, 50.392867680930479],
              [-3.57774614000616, 50.392908134757107],
              [-3.578354547225113, 50.392418709250379],
              [-3.578443055530358, 50.39279614753201],
              [-3.578660572068139, 50.392398366266463],
              [-3.580847735737199, 50.392382144020338],
              [-3.582841188940276, 50.393350642559561],
              [-3.582938598666628, 50.393994173363545],
              [-3.58566208185993, 50.393309513232637],
              [-3.587606893954966, 50.39471299528585],
              [-3.589869690202101, 50.39510389067771],
              [-3.601923015122101, 50.394264835458706],
              [-3.602054381917184, 50.395198384245646],
              [-3.60281604352067, 50.395338085529296],
              [-3.607280049594046, 50.392333687492759],
              [-3.609826348570695, 50.392370415490376],
              [-3.612415966926026, 50.393227624962755],
              [-3.614588439539483, 50.39629765846378],
              [-3.615785047891752, 50.397144446727566],
              [-3.61695217994243, 50.397289213696276],
              [-3.61858327700385, 50.398125434184763],
              [-3.621249483852207, 50.39861533277216],
              [-3.622327026687664, 50.399678666871161],
              [-3.621398233755756, 50.400252836127287],
              [-3.623021838899689, 50.400905624852882],
              [-3.623760271847689, 50.40019649502225],
              [-3.623227475593818, 50.39864619966815],
              [-3.623634579426897, 50.39795968232999],
              [-3.627214609726861, 50.398795536356936],
              [-3.628905140966441, 50.399675750426084],
              [-3.632425219300591, 50.399988842474301],
              [-3.633153374711194, 50.40078626637132],
              [-3.633247491795797, 50.401886691000847],
              [-3.631291088752709, 50.402283798186772],
              [-3.630173211608559, 50.403374238234996],
              [-3.631918488324775, 50.405800582201614],
              [-3.635669332688831, 50.407651883931365],
              [-3.634226210315182, 50.408820685375424],
              [-3.635902009594635, 50.40864602810921],
              [-3.637217506758246, 50.407409749822087],
              [-3.642814374246521, 50.405657066679439],
              [-3.644554718807828, 50.405562316833169],
              [-3.647601991866157, 50.406226101211985],
              [-3.647658403154931, 50.406716366370802],
              [-3.645360868020536, 50.407099655197918],
              [-3.642273754630998, 50.408947456717655],
              [-3.642010716389858, 50.410132062605932],
              [-3.643458064070115, 50.41189599690437],
              [-3.644473937205532, 50.412374499395618],
              [-3.648854203971494, 50.412582295194802],
              [-3.649584910762949, 50.412953272236358],
              [-3.651464435904437, 50.412279940360797],
              [-3.655477010891097, 50.411710242357358],
              [-3.657396482305131, 50.411822308093356],
              [-3.658364397838479, 50.412213233522436],
              [-3.657574252984647, 50.411713647030609],
              [-3.655329521431425, 50.411355285796361],
              [-3.657200785935414, 50.411054323545358],
              [-3.658200708432495, 50.411314383319009],
              [-3.659059829966758, 50.412096288047046],
              [-3.65882432118997, 50.413815674673437],
              [-3.660603263888543, 50.415058420675429],
              [-3.659670153281257, 50.414618450239594],
              [-3.660183884118611, 50.415181328099749],
              [-3.661327259621515, 50.415314298876915],
              [-3.663144189878746, 50.416070795593342],
              [-3.663618921000345, 50.416001048876403],
              [-3.663413820634883, 50.415851986406508],
              [-3.661519780553688, 50.415305252917108],
              [-3.663284525465684, 50.415656870207734],
              [-3.665564126478399, 50.417162195195381],
              [-3.664312393913058, 50.416228564863694],
              [-3.664853526851069, 50.416366520265292],
              [-3.669858738903903, 50.420002030409698],
              [-3.669911574168332, 50.42070369166867],
              [-3.670205084797397, 50.420243488755112],
              [-3.670703849911271, 50.420455775137775],
              [-3.676587268669115, 50.423500074194841],
              [-3.677198670392794, 50.424348373362861],
              [-3.677866710210463, 50.424448463093377],
              [-3.680231304401508, 50.426142046603829],
              [-3.681703909720726, 50.427690596637746],
              [-3.680805883746367, 50.430996719324071],
              [-3.681015812288766, 50.431715891362607],
              [-3.679735191335879, 50.434361493844882],
              [-3.680121392554615, 50.436598979576381],
              [-3.681437033004973, 50.436847985060282],
              [-3.68211356020266, 50.437301386215843],
              [-3.684157631987771, 50.438829541204974],
              [-3.683278498619126, 50.439652611350517],
              [-3.684012868966041, 50.441416465646334],
              [-3.683406467114974, 50.442703266771957],
              [-3.684126108880527, 50.443376381191975],
              [-3.684523070571664, 50.444679235717309],
              [-3.683883725975829, 50.445197543258907],
              [-3.682960399504176, 50.445294548066407],
              [-3.682337106082985, 50.445948421494606],
              [-3.680682902997304, 50.446486780256294],
              [-3.679826846904969, 50.445938830279317],
              [-3.677946802320137, 50.445548651075185],
              [-3.674840880768427, 50.446379471404541],
              [-3.674481511991098, 50.447610502427466],
              [-3.675531252190393, 50.449814161590268],
              [-3.674387934093548, 50.450904485058565],
              [-3.673142650952577, 50.450863043285025],
              [-3.67246566826477, 50.451358444028003],
              [-3.672444579135254, 50.452438004362591],
              [-3.672534256410096, 50.451505855786209],
              [-3.673137248162202, 50.450949461582795],
              [-3.674466500789314, 50.450975304678749],
              [-3.67564236923411, 50.449849435705701],
              [-3.674598138665722, 50.447602527892592],
              [-3.674999314105493, 50.446398775356812],
              [-3.677962397801513, 50.445631169287978],
              [-3.679747361384029, 50.446001136093052],
              [-3.680694509417675, 50.446536078598292],
              [-3.682431517059201, 50.445989327031199],
              [-3.682989953463851, 50.445373266166982],
              [-3.683920096710152, 50.445269866772577],
              [-3.684673917524772, 50.444722919689234],
              [-3.684250882155331, 50.443360184520699],
              [-3.683581113342927, 50.442703437323424],
              [-3.684216585129576, 50.441481869357183],
              [-3.683454577140774, 50.439614087816956],
              [-3.684399701942318, 50.438785563805219],
              [-3.68229532986239, 50.437225007962759],
              [-3.681753108042761, 50.436865900185012],
              [-3.682229993311166, 50.436851809194273],
              [-3.684549510668882, 50.437174396414207],
              [-3.688229506607978, 50.439359610780521],
              [-3.689550317267093, 50.439552688052167],
              [-3.69075728248946, 50.439080062053947],
              [-3.690285956780856, 50.438618332807764],
              [-3.687935875411486, 50.438666849398786],
              [-3.684485624574868, 50.436842549493569],
              [-3.680727981096691, 50.436430125936987],
              [-3.6805023471494, 50.43483068379895],
              [-3.681098304238243, 50.432931566328747],
              [-3.681240797985355, 50.432740636045295],
              [-3.681532540920379, 50.432946874705458],
              [-3.681703802080909, 50.431570969243289],
              [-3.681723848025108, 50.431409917100616],
              [-3.682116691365546, 50.428253532796603],
              [-3.682505456219155, 50.428377421194753],
              [-3.682594039676554, 50.429208070980678],
              [-3.681800731175271, 50.431313707217029],
              [-3.682766640022606, 50.430104958976862],
              [-3.682532789510739, 50.427718675821566],
              [-3.68113385193724, 50.426104721269922],
              [-3.676982089984556, 50.422840528902334],
              [-3.671442271214621, 50.420197834503782],
              [-3.666788996610912, 50.41648719664731],
              [-3.664574026816473, 50.415259779966298],
              [-3.663126334103937, 50.41491803893301],
              [-3.664561835544804, 50.415152927580372],
              [-3.666532092921719, 50.416185986509021],
              [-3.669088046481978, 50.41818104524863],
              [-3.670398798130154, 50.417947273871455],
              [-3.669268561084068, 50.417069512118452],
              [-3.668328429483176, 50.415511775114794],
              [-3.665850913747977, 50.414062402699649],
              [-3.665973742418345, 50.41302994707717],
              [-3.663844373016047, 50.413396800815732],
              [-3.662181819245703, 50.412665090406129],
              [-3.660831767569281, 50.411570938521514],
              [-3.660257840754421, 50.413431867406416],
              [-3.660985719242792, 50.414161668345734],
              [-3.662441858900118, 50.414826194513196],
              [-3.661099053853019, 50.414343524269327],
              [-3.660026383582048, 50.413494530819186],
              [-3.660572255198563, 50.411837264888703],
              [-3.660251940238709, 50.411451504710421],
              [-3.660746183046925, 50.411457938721512],
              [-3.658450250256714, 50.410560737454979],
              [-3.657207517278085, 50.41035990200831],
              [-3.653063652270752, 50.410793021382894],
              [-3.651918943385017, 50.411265274905752],
              [-3.652770395176892, 50.410859246958694],
              [-3.649083043590492, 50.410701135315989],
              [-3.647210678281236, 50.41097140328241],
              [-3.644973940933244, 50.410556068134781],
              [-3.64403155269286, 50.4095566869767],
              [-3.646258678640943, 50.408478297975684],
              [-3.648457249647893, 50.407999260987289],
              [-3.649577785409378, 50.406751214405325],
              [-3.649376516034138, 50.406021069971224],
              [-3.647836176133126, 50.404660550599928],
              [-3.644047142788446, 50.403705067878391],
              [-3.641706034104467, 50.403597836869622],
              [-3.639289217274494, 50.404028558834213],
              [-3.636370934501926, 50.40542683031201],
              [-3.633935107706039, 50.404981707399521],
              [-3.638077182055671, 50.400425612550762],
              [-3.642121829554129, 50.399912580161882],
              [-3.643151824273786, 50.399261265839343],
              [-3.643951206269513, 50.396907066857565],
              [-3.645933903022779, 50.397278348171369],
              [-3.647788661509996, 50.397164840576423],
              [-3.651025977157026, 50.39626630389732],
              [-3.653388550988015, 50.396272309278899],
              [-3.658224100321045, 50.395295074028795],
              [-3.659578711610601, 50.39540706008993],
              [-3.662361080012756, 50.39478541382168],
              [-3.665331532692054, 50.394835548523275],
              [-3.66676833876544, 50.394563134140206],
              [-3.668047180728947, 50.394721076988802],
              [-3.672341497188479, 50.396967229790889],
              [-3.66758685435462, 50.394313061037934],
              [-3.662846383054255, 50.394179482504939],
              [-3.662861816360266, 50.393213318645053],
              [-3.663515599044465, 50.392638252264149],
              [-3.663191779655629, 50.392430628570025],
              [-3.6618978046121, 50.393205514733737],
              [-3.661746367256432, 50.393944277994031],
              [-3.661091013486262, 50.394193778051701],
              [-3.658885879702044, 50.393697316051352],
              [-3.657531499455473, 50.393792178052607],
              [-3.65427798920006, 50.394904282771584],
              [-3.65266602087584, 50.394552158955797],
              [-3.648117242118308, 50.395604244979673],
              [-3.642871384779137, 50.396115577739195],
              [-3.640850200791438, 50.397887125262031],
              [-3.63947259913678, 50.398210547781844],
              [-3.637857419223326, 50.397806099720867],
              [-3.635348926245649, 50.398018582440685],
              [-3.630252821050488, 50.396672705691849],
              [-3.629698269493369, 50.395641688728269],
              [-3.626787231254891, 50.393916056439771],
              [-3.626019856041756, 50.391966128953079],
              [-3.619157760715824, 50.391324384757368],
              [-3.61669523144297, 50.39027488862461],
              [-3.611290818924597, 50.38892347721201],
              [-3.609625635882716, 50.388687516578813],
              [-3.608559647632662, 50.38907191467149],
              [-3.607566089739578, 50.388769970628239],
              [-3.60747032084478, 50.388434025373215],
              [-3.609361748207875, 50.387375369231883],
              [-3.610606199510212, 50.385699672860895],
              [-3.61432188333299, 50.383771128954443],
              [-3.612020563175015, 50.38439125433311],
              [-3.60965267172789, 50.385456551685003],
              [-3.60608239476438, 50.385286470334705],
              [-3.605559730118309, 50.385804538938757],
              [-3.605716152945432, 50.387395189231967],
              [-3.601565908718598, 50.389555185642628],
              [-3.601257128984841, 50.390662082613986],
              [-3.600220023381718, 50.391364387687673],
              [-3.598650668971005, 50.391468716746779],
              [-3.597358454173673, 50.390945045920425],
              [-3.596696604973146, 50.390256214199859],
              [-3.595575021539518, 50.390287798270904],
              [-3.594054591833162, 50.389752832003154],
              [-3.595698252184005, 50.386499702374117],
              [-3.597431903303358, 50.384675331732431],
              [-3.596215322893903, 50.383423906883749],
              [-3.594572211068673, 50.380608893419797],
              [-3.591412301158634, 50.379575621431748],
              [-3.589312578048893, 50.379455042807308],
              [-3.589003112513086, 50.379075235228292],
              [-3.590413432865979, 50.378643135794931],
              [-3.590602580037237, 50.378243020464481],
              [-3.588845594122983, 50.377602397210985],
              [-3.586671735437335, 50.376058159172452],
              [-3.58488353165435, 50.375320768850791],
              [-3.582825609981726, 50.374928789327505],
              [-3.581117287796572, 50.373503124136164],
              [-3.580572016525423, 50.372076019338991],
              [-3.583143692010837, 50.366191429068692],
              [-3.586083205679246, 50.362629368725571],
              [-3.5894157787569, 50.36386008655515],
              [-3.588935072087685, 50.362138935418024],
              [-3.590153392336854, 50.361782310071035],
              [-3.593573568741338, 50.359876446743158],
              [-3.596456054106905, 50.359719080828697],
              [-3.596314541124844, 50.359314500697941],
              [-3.597517516196687, 50.358965203260169],
              [-3.598476894367471, 50.358102994044124],
              [-3.598351458916763, 50.357800725433606],
              [-3.598837524974035, 50.357915459221822],
              [-3.599633931895244, 50.357099551314576],
              [-3.601314752886384, 50.356330818832816],
              [-3.599161910550472, 50.356775070182088],
              [-3.597726122336364, 50.357850692952766],
              [-3.595877164034861, 50.358430111391868],
              [-3.595438940917838, 50.357561920979073],
              [-3.594379193332241, 50.358367021319786],
              [-3.592675764899044, 50.358591832687267],
              [-3.58884950015608, 50.360418678299041],
              [-3.587645918857373, 50.359994421004203],
              [-3.586322213479073, 50.360183373540593],
              [-3.583523578668236, 50.359862670878485],
              [-3.582383115374125, 50.359981628507832],
              [-3.581515534808017, 50.360485398104558],
              [-3.579462986433268, 50.360446746699751],
              [-3.579336064193244, 50.35992682687364],
              [-3.578665270643043, 50.359837004863429],
              [-3.578248496228971, 50.358843445154918],
              [-3.57786004478004, 50.358873001275349],
              [-3.577087688242916, 50.357839293459492],
              [-3.577188285696439, 50.357396329989456],
              [-3.576736104117795, 50.357411457400723],
              [-3.576578046811631, 50.357053846316759],
              [-3.576966598152731, 50.356815635364711],
              [-3.576487040289805, 50.356555920872054],
              [-3.577152734748888, 50.348985743569401],
              [-3.577778179645961, 50.348729924978066],
              [-3.576344804448619, 50.345514271610284],
              [-3.574485568599069, 50.344331589917587],
              [-3.572303963283169, 50.343768420832141],
              [-3.573173035613432, 50.343315962711877],
              [-3.573403522162344, 50.342507890740265],
              [-3.571710376978324, 50.342646816335382],
              [-3.57165963664605, 50.342982974162759],
              [-3.56859579146625, 50.343579279367106],
              [-3.568383580404307, 50.343366289579485],
              [-3.568781351133164, 50.34315406303358],
              [-3.568176190896561, 50.343128950889223],
              [-3.567182227769524, 50.342478606995627],
              [-3.565839093058927, 50.34244903920559],
              [-3.565931440809089, 50.34205206459562],
              [-3.565244382306926, 50.34167278225555],
              [-3.565559258624581, 50.34141851219848],
              [-3.56631270849023, 50.341509093994908],
              [-3.565722866774546, 50.340752560323551],
              [-3.566181607959609, 50.340172570948781],
              [-3.566964246485722, 50.340510086948264],
              [-3.566985821709871, 50.339713837529033],
              [-3.566522014847973, 50.339071629802561],
              [-3.564991653349532, 50.338388022114678],
              [-3.564983575330749, 50.338013985315555],
              [-3.565841376089813, 50.337732617016734],
              [-3.565460011685529, 50.336647694631537],
              [-3.565807220078764, 50.336309344977032],
              [-3.564995460356938, 50.336451587498026],
              [-3.564894061802572, 50.33597447785862],
              [-3.563951095278462, 50.335929607790327],
              [-3.563511529956949, 50.335466939556781],
              [-3.564693536494665, 50.334490487632891],
              [-3.565416301530369, 50.334505939245879],
              [-3.56577575154603, 50.334155732588435],
              [-3.566800503291555, 50.334295720000121],
              [-3.566088121914824, 50.333912286996295],
              [-3.56763975113929, 50.333666524391738],
              [-3.568620893351171, 50.334018440338554],
              [-3.568327902773142, 50.333185961756818],
              [-3.568697237421784, 50.33305146594784],
              [-3.568333983860916, 50.332943943972452],
              [-3.568719015682457, 50.332774160101046],
              [-3.568587418337993, 50.332063619557943],
              [-3.569532145128533, 50.331439282075159],
              [-3.569187154993631, 50.33124607415462],
              [-3.570828339528658, 50.330481010849901],
              [-3.572390028044194, 50.330203569521153],
              [-3.572333025749326, 50.32962603275238],
              [-3.571772671619958, 50.329250471123579],
              [-3.572575115904883, 50.328021842999519],
              [-3.571947064041766, 50.327680475514548],
              [-3.57143242945377, 50.327880803160753],
              [-3.570382536554694, 50.327698925296595],
              [-3.570243589296948, 50.32706403409815],
              [-3.571034914457714, 50.327159469241643],
              [-3.571368450044495, 50.326580250146456],
              [-3.572415101937754, 50.326068732711342],
              [-3.572805196066953, 50.326479872729848],
              [-3.573677146955552, 50.326336759713499],
              [-3.576253942698795, 50.325110151038238],
              [-3.57745498344089, 50.325530062028513],
              [-3.577480075547831, 50.326075651903523],
              [-3.579041817851453, 50.325805314619565],
              [-3.580468523865951, 50.326038652867432],
              [-3.581089607015373, 50.325364656451306],
              [-3.581710584025304, 50.32561883010149],
              [-3.581931724308454, 50.32517062177935],
              [-3.583955347751119, 50.324765318014322],
              [-3.583625000632439, 50.325858083291024],
              [-3.584126216846643, 50.326228098454017],
              [-3.586695555646583, 50.326135491880372],
              [-3.587286488776376, 50.325402513692701],
              [-3.588081260431422, 50.325853944976814],
              [-3.589001666022282, 50.325648896017171],
              [-3.590615245374187, 50.324366777215978],
              [-3.594415598214598, 50.322650831092204],
              [-3.594990102040337, 50.321977397949638],
              [-3.594612413063992, 50.321605734557316],
              [-3.595072298413644, 50.321285536034786],
              [-3.595061128610549, 50.32057426927387],
              [-3.596670123340302, 50.319793988458983],
              [-3.603477608314681, 50.319087784592163],
              [-3.60513493147323, 50.319622532731948],
              [-3.605336981487359, 50.320032564143034],
              [-3.606576545451071, 50.320174629520437],
              [-3.609049302953482, 50.319687128413634],
              [-3.61212943390137, 50.318552581065447],
              [-3.613110323832553, 50.317485779763501],
              [-3.612376485454009, 50.316928444104725],
              [-3.613346698136458, 50.316377142053994],
              [-3.612726152495739, 50.315680628365918],
              [-3.61480247555076, 50.315357696485705],
              [-3.616580025910174, 50.314260906000627],
              [-3.617026858659308, 50.313065688444922],
              [-3.619360620238862, 50.311849589193123],
              [-3.619770687442725, 50.310856337355681],
              [-3.621333715223075, 50.310025985082525],
              [-3.62198097206793, 50.309153532518643],
              [-3.621843400390104, 50.308742629137683],
              [-3.623844080682738, 50.308229917097322],
              [-3.627468970698548, 50.306464960102346],
              [-3.633758379188388, 50.300872393764614],
              [-3.640792364522283, 50.292193014021116],
              [-3.646160551896793, 50.283027734180422],
              [-3.650329087235888, 50.272507637449401],
              [-3.651819943947257, 50.266926329283912],
              [-3.652126083086694, 50.263446672204587],
              [-3.653494320860547, 50.261589720588248],
              [-3.656958464084078, 50.25027079020132],
              [-3.657410703640536, 50.248427746796324],
              [-3.657109797729721, 50.248002122046849],
              [-3.657782540996997, 50.246087575727273],
              [-3.657349174354011, 50.244592645363213],
              [-3.657994779512989, 50.244201183916125],
              [-3.658866866361826, 50.242335050814575],
              [-3.658983943285457, 50.236376585634119],
              [-3.6582150858928, 50.233980740683847],
              [-3.658439077698803, 50.233364144710336],
              [-3.657962119833233, 50.233196467633405],
              [-3.658395488099889, 50.233037381451332],
              [-3.657462470112289, 50.232414814718673],
              [-3.657697440149337, 50.23211465608329],
              [-3.657203710806181, 50.230896703088867],
              [-3.655919056253128, 50.230402359533883],
              [-3.656404661954303, 50.230092334257712],
              [-3.655931168836413, 50.229578326188935],
              [-3.65531686107241, 50.229167956460657],
              [-3.654604798162908, 50.229214078116904],
              [-3.654841268332971, 50.228795181459219],
              [-3.654617498973972, 50.22820385832312],
              [-3.654123758659409, 50.228035505276893],
              [-3.654265340454514, 50.22762965328009],
              [-3.653329087954937, 50.227598012842307],
              [-3.652706694400346, 50.227276786199205],
              [-3.652830742423603, 50.227052866522754],
              [-3.651625130248195, 50.226731836848728],
              [-3.651691705244391, 50.226061728125842],
              [-3.650542749656511, 50.225634650515197],
              [-3.650905469285685, 50.225298512323654],
              [-3.650390633341495, 50.225086372185316],
              [-3.649408974816753, 50.225240624248826],
              [-3.649669261634584, 50.225064240850486],
              [-3.64923738448776, 50.225102752564439],
              [-3.649342070737485, 50.224683039805463],
              [-3.646577810608579, 50.224572056762803],
              [-3.646101941202713, 50.22443219762004],
              [-3.646495363036566, 50.224213461002385],
              [-3.645104528663642, 50.22388419646358],
              [-3.643086821747367, 50.223833608010167],
              [-3.64328269869276, 50.223659049482521],
              [-3.642154371185051, 50.223420473767916],
              [-3.642138951299451, 50.22301235915252],
              [-3.641210879611162, 50.223420317727879],
              [-3.640731499070192, 50.223340746812937],
              [-3.640453719352545, 50.222558585116239],
              [-3.640714538843558, 50.222723990463471],
              [-3.641687489468142, 50.222357673785503],
              [-3.640409378318347, 50.22179741017959],
              [-3.642001326988348, 50.221291032921762],
              [-3.641523479720759, 50.22166384752348],
              [-3.642685298367478, 50.221899257362011],
              [-3.643125159806063, 50.221726644655824],
              [-3.643268774246713, 50.222275052669829],
              [-3.644570922014886, 50.221861781526663],
              [-3.644494271998702, 50.22207872551725],
              [-3.645247758891223, 50.221976315125247],
              [-3.645307234969974, 50.222238100802045],
              [-3.645951295034526, 50.221888998261818],
              [-3.646290814695494, 50.22189677829261],
              [-3.646226634517995, 50.22223136962689],
              [-3.647167469105142, 50.222155072505721],
              [-3.647001547409904, 50.221937069503134],
              [-3.647613202399927, 50.222153247692837],
              [-3.647214906454698, 50.221904363344208],
              [-3.647890606853187, 50.221782352405114],
              [-3.649770635530322, 50.221826717957271],
              [-3.650720985468024, 50.221173733733885],
              [-3.650415186463445, 50.220680704436312],
              [-3.651087234850371, 50.220697236126441],
              [-3.651434109587638, 50.220228208066011],
              [-3.652002012750144, 50.220476464676352],
              [-3.651716697850428, 50.219927383803672],
              [-3.65289696204986, 50.220047304481973],
              [-3.652678717381898, 50.219776089274461],
              [-3.652875412779349, 50.219626686132159],
              [-3.653191114598751, 50.219878525638428],
              [-3.653336059796658, 50.2192055007867],
              [-3.653990433483597, 50.219238456609794],
              [-3.653798781924984, 50.218844544650288],
              [-3.654364540178378, 50.218868866531807],
              [-3.654070788189195, 50.218643699973647],
              [-3.656429424981439, 50.217818603412056],
              [-3.657216663710978, 50.217843350188012],
              [-3.656836764177125, 50.218166262805141],
              [-3.658120223340315, 50.218635429277263],
              [-3.65751503377195, 50.219131549086413],
              [-3.658122107531216, 50.21901315583338],
              [-3.658014981523966, 50.21927911249594],
              [-3.659291396589012, 50.219948036345841],
              [-3.659387486375089, 50.220454831872878],
              [-3.661385030477687, 50.22069520112435],
              [-3.664259695112799, 50.220479560874772],
              [-3.664569426497688, 50.220235878528563],
              [-3.664787981535724, 50.220634783909468],
              [-3.665508084404467, 50.220380719374731],
              [-3.665097952658519, 50.220679808390372],
              [-3.665867235834445, 50.220509581437177],
              [-3.665777182801808, 50.220781596396677],
              [-3.666135221603696, 50.220637051931618],
              [-3.666275842749065, 50.220971415284623],
              [-3.668025171452587, 50.220403960134206],
              [-3.669562731693216, 50.220597728380518],
              [-3.670029990018973, 50.220369755200089],
              [-3.672492581787032, 50.221279605337202],
              [-3.675384607892787, 50.221239724798622],
              [-3.67645437435174, 50.221683889876758],
              [-3.679538614579867, 50.221807523156087],
              [-3.679441430281847, 50.222113829848141],
              [-3.680993026533396, 50.222464641703397],
              [-3.683116008465333, 50.221364493525279],
              [-3.68291965268513, 50.220963501521915],
              [-3.684111079407506, 50.220680905200233],
              [-3.684089912353089, 50.220159551464739],
              [-3.684633940232468, 50.219725339413941],
              [-3.685220930753818, 50.219555828862511],
              [-3.685733352294621, 50.219895567169985],
              [-3.686978110593297, 50.219337845487445],
              [-3.686990680688271, 50.218939221923598],
              [-3.688250357740871, 50.219003665179187],
              [-3.688964326129672, 50.21806418893879],
              [-3.688705371439164, 50.217320539822403],
              [-3.689303853099072, 50.217398180638575],
              [-3.688336816415237, 50.216688212037205],
              [-3.688809116397945, 50.216646268378234],
              [-3.688737508250671, 50.21632531868773],
              [-3.69068440468664, 50.215807712613518],
              [-3.691345260193486, 50.216222615356536],
              [-3.691639520025196, 50.215744337584297],
              [-3.693003847771019, 50.215361993607004],
              [-3.693549811805907, 50.214550855114254],
              [-3.694687619769623, 50.214264433736503],
              [-3.694529629656086, 50.213998712726735],
              [-3.695540396156648, 50.214122471556976],
              [-3.696430158376562, 50.213284713604558],
              [-3.697328769940612, 50.213289573069282],
              [-3.701410482481515, 50.211955376406841],
              [-3.70201966303064, 50.211389711192318],
              [-3.703387238474696, 50.211261731677169],
              [-3.703066657400057, 50.209697848182472],
              [-3.703541467920924, 50.209413863908068],
              [-3.703103562984443, 50.209238603791711],
              [-3.703727920956601, 50.20827426427693],
              [-3.704039022337699, 50.208312872190191],
              [-3.704351579767668, 50.20772366388664],
              [-3.706376394074844, 50.207086824351826],
              [-3.706899189614406, 50.20618511969537],
              [-3.708199182215352, 50.205424884944989],
              [-3.708620426583929, 50.205408794723375],
              [-3.708798023631759, 50.206025881505099],
              [-3.709722308659509, 50.206399927942002],
              [-3.71167266459044, 50.206113965555495],
              [-3.711549710582435, 50.20573262501356],
              [-3.713428480910017, 50.206031411114218],
              [-3.714053973138054, 50.206396339762271],
              [-3.71407085558887, 50.205889717444968],
              [-3.714564663136569, 50.205904014370113],
              [-3.714761839500066, 50.205462186489989],
              [-3.715571139998866, 50.205755137635641],
              [-3.716089619884304, 50.205636848917074],
              [-3.715847203421327, 50.204856136699696],
              [-3.716910856278959, 50.20563730297971],
              [-3.717512226578169, 50.203963584786415],
              [-3.718167429483454, 50.203711949254441],
              [-3.718424485573908, 50.203924006796385],
              [-3.718872838054919, 50.2036097701003],
              [-3.71933598841362, 50.203902421230254],
              [-3.719137609237292, 50.202866527554725],
              [-3.720164444771469, 50.201960896561147],
              [-3.721158889424309, 50.202103561350732],
              [-3.721388832392151, 50.201873499269432],
              [-3.72287420836731, 50.202496357609832],
              [-3.722938441530401, 50.203073732181771],
              [-3.726013924106911, 50.20523704205921],
              [-3.725479406158001, 50.205609250102661],
              [-3.72607220500362, 50.205609435416328],
              [-3.726475638692488, 50.205954212745219],
              [-3.726407037379194, 50.207082206359175],
              [-3.725819076807749, 50.207682763518186],
              [-3.726057639753423, 50.208196384850361],
              [-3.728249138093353, 50.208301405407603],
              [-3.728328488190258, 50.20801510832176],
              [-3.728904543975126, 50.207977752812624],
              [-3.729443437758149, 50.208656886514113],
              [-3.729928417268229, 50.208658657812038],
              [-3.73047264172769, 50.206656534603923],
              [-3.732001642825699, 50.207160802753606],
              [-3.733132475717119, 50.209903355597397],
              [-3.73342758180611, 50.20984768444422],
              [-3.733221635944315, 50.210120584233508],
              [-3.733980435734325, 50.210329615817507],
              [-3.734298994859719, 50.210108998333411],
              [-3.734521960093761, 50.210653415760945],
              [-3.734812893161212, 50.210405327421405],
              [-3.734790857564539, 50.210995676463426],
              [-3.735079307073297, 50.210524568259991],
              [-3.735019702941223, 50.212590528094601],
              [-3.735865690630983, 50.212111087782873],
              [-3.73686872984253, 50.212676218136089],
              [-3.736867328711536, 50.213100765536225],
              [-3.735836357494622, 50.213658529197453],
              [-3.736438615853332, 50.214302505553825],
              [-3.737555375423192, 50.214485474993467],
              [-3.737372374447321, 50.214811104487502],
              [-3.7383132621702, 50.214474134200174],
              [-3.738588200156305, 50.214788413686207],
              [-3.739219078130271, 50.214367933042382],
              [-3.738920476866761, 50.214790634621338],
              [-3.739886923261205, 50.214655638460464],
              [-3.739911621623766, 50.214910703866281],
              [-3.740658202670973, 50.214858143820209],
              [-3.740949634393076, 50.215316977274902],
              [-3.741523409496945, 50.215214835467222],
              [-3.74188193021226, 50.215475687531423],
              [-3.74181700601082, 50.215155568095753],
              [-3.743780010860199, 50.215404033142946],
              [-3.745671137099689, 50.216793111517518],
              [-3.745863437622455, 50.216339611291218],
              [-3.746511189258286, 50.216535839950943],
              [-3.74592652488579, 50.216992541793971],
              [-3.746805663885668, 50.216807533622131],
              [-3.747194061110696, 50.21704093744038],
              [-3.746759105567191, 50.217605120565921],
              [-3.747550214911775, 50.218271383214251],
              [-3.748101539310506, 50.218513125522271],
              [-3.749180830546695, 50.21828101523927],
              [-3.750059001620144, 50.219103348358786],
              [-3.750766801497754, 50.219062100984424],
              [-3.75090373761086, 50.218782115731599],
              [-3.751681984303701, 50.21901862121257],
              [-3.752795439395361, 50.218609675553246],
              [-3.753932601521482, 50.219038621565971],
              [-3.755008243041872, 50.218631125043238],
              [-3.755862554752273, 50.219144374352069],
              [-3.755989653996622, 50.218864531678754],
              [-3.756308924228325, 50.219198786066812],
              [-3.757690029506187, 50.219284025158849],
              [-3.757848535596199, 50.219019894696387],
              [-3.758013871995231, 50.219360971210207],
              [-3.758463969940892, 50.219249824687964],
              [-3.759247781267854, 50.219750625297074],
              [-3.759538405446381, 50.220257993684662],
              [-3.760577003966497, 50.220177496723217],
              [-3.76030268496782, 50.220341751590631],
              [-3.760891353072768, 50.220719579574578],
              [-3.761751612937476, 50.220174076582829],
              [-3.763852073025557, 50.220036065391213],
              [-3.764023839701447, 50.220474173400525],
              [-3.76470830731539, 50.220370235926858],
              [-3.764964357200746, 50.220926685050259],
              [-3.765829267957379, 50.220432348039317],
              [-3.766608684822334, 50.220849519888155],
              [-3.767044395143042, 50.220538887264397],
              [-3.766832976289, 50.220925257308615],
              [-3.76766191096462, 50.221126707019344],
              [-3.767997201466184, 50.221626179414358],
              [-3.768766478659518, 50.221199833417522],
              [-3.769101726145646, 50.22128107209091],
              [-3.76877054309578, 50.221537054773286],
              [-3.770038336408145, 50.221739898347813],
              [-3.769540923953863, 50.222011907682067],
              [-3.770550917348102, 50.221953343558191],
              [-3.770814565961632, 50.222750709261291],
              [-3.771586287834771, 50.223222829983527],
              [-3.771069911810589, 50.223626450614518],
              [-3.771285607361235, 50.223848016911916],
              [-3.772078774183005, 50.223460859394741],
              [-3.771964536698445, 50.223707245028635],
              [-3.773131753586776, 50.22380455820808],
              [-3.773412841256107, 50.224352511917786],
              [-3.772970341565376, 50.224328686962245],
              [-3.773109515764788, 50.224679135851943],
              [-3.772714746105907, 50.224883933845298],
              [-3.772733764496035, 50.225926073425889],
              [-3.771431469355098, 50.226611514121828],
              [-3.770921969574275, 50.226407019940865],
              [-3.7706826021022, 50.226757845926663],
              [-3.77102741950707, 50.22705839210159],
              [-3.7704511754156, 50.22705818275044],
              [-3.770852297269322, 50.22755394504842],
              [-3.770265929670393, 50.227659121379162],
              [-3.770846940892223, 50.228809618741991],
              [-3.768531894890218, 50.229751506591434],
              [-3.767135045230562, 50.229742185973805],
              [-3.767044491902042, 50.231350828129493],
              [-3.766048752482477, 50.231952393871566],
              [-3.766108792322858, 50.232476742422342],
              [-3.764931086449298, 50.232974033902728],
              [-3.764742037357562, 50.233968067017919],
              [-3.763950699854672, 50.234866914730489],
              [-3.762391016218611, 50.235465318331819],
              [-3.761587267122511, 50.236219531214381],
              [-3.759353626797844, 50.236530404607421],
              [-3.75741335195852, 50.236430259524241],
              [-3.756673131491111, 50.237312992975511],
              [-3.753576024231382, 50.237598134656331],
              [-3.752867738156266, 50.237901135841376],
              [-3.75109223814122, 50.237515081672676],
              [-3.749514399616983, 50.236705095926482],
              [-3.749401845297935, 50.237614303930421],
              [-3.74841887545533, 50.237884538182513],
              [-3.746166231577705, 50.238101903677297],
              [-3.745272142833176, 50.237350835433688],
              [-3.744624645763095, 50.238628740802071],
              [-3.740923734162285, 50.239666429081183],
              [-3.740000862371231, 50.239625392042868],
              [-3.738488859286917, 50.238887123897015],
              [-3.733550381191185, 50.237669366378128],
              [-3.734419260260331, 50.236687724378037],
              [-3.732868524039186, 50.237041853799248],
              [-3.728905272455772, 50.236413755276757],
              [-3.726295094739132, 50.236710707708973],
              [-3.72611491134852, 50.236963423006983],
              [-3.72901471202632, 50.236996747003438],
              [-3.730804722314249, 50.237401809390768],
              [-3.732206450011734, 50.238739033986285],
              [-3.737907434450299, 50.240213469637212],
              [-3.739111773271868, 50.241090364956854],
              [-3.738424821313941, 50.241636699701395],
              [-3.735741498274217, 50.241393509200911],
              [-3.734461385193792, 50.241943285352605],
              [-3.73291720449344, 50.243333443541907],
              [-3.733320588959613, 50.243705179921143],
              [-3.733126471205336, 50.244385336234508],
              [-3.727395857416484, 50.247531400927372],
              [-3.724849280855282, 50.248091799631624],
              [-3.723108116614284, 50.247495244184762],
              [-3.720217800914477, 50.248171267117087],
              [-3.72337637192739, 50.247971552162554],
              [-3.725774532707557, 50.248776903096157],
              [-3.730842809083474, 50.24735687475313],
              [-3.732911791289697, 50.246354661201337],
              [-3.733971644840566, 50.245335997879501],
              [-3.735077096503916, 50.242600557820445],
              [-3.738320252340954, 50.243272504113598],
              [-3.739855106109761, 50.242477826787649],
              [-3.741145369907036, 50.242631182679538],
              [-3.741404955077752, 50.242134411245132],
              [-3.742318231223284, 50.24171867752856],
              [-3.745258842254498, 50.240902834772349],
              [-3.747062983045438, 50.240765986070294],
              [-3.748545417657957, 50.24161071957797],
              [-3.749173653957585, 50.240536351058353],
              [-3.74718650290381, 50.240692174905995],
              [-3.747497521036742, 50.240414973037389],
              [-3.752433081291025, 50.239776684846511],
              [-3.755789069020444, 50.240197302742637],
              [-3.756126618372973, 50.240948610904162],
              [-3.755412311218812, 50.241203149745999],
              [-3.755163646779517, 50.241725871374314],
              [-3.756808410382759, 50.243623963880779],
              [-3.75530125272433, 50.244478708743813],
              [-3.754422289221621, 50.244299511501978],
              [-3.753169875659506, 50.244721358656037],
              [-3.752545076233898, 50.245429633612666],
              [-3.752784617543671, 50.246760437932693],
              [-3.754597083281151, 50.249058386112651],
              [-3.756808348205707, 50.25025806718449],
              [-3.755684579736281, 50.251664654973368],
              [-3.754468332774987, 50.252350396633325],
              [-3.751256800906871, 50.25287014799185],
              [-3.747143364414511, 50.251630629213039],
              [-3.746775970347446, 50.252247756727115],
              [-3.747336294505915, 50.253414866570843],
              [-3.748487581692646, 50.254323043223835],
              [-3.748343615117991, 50.255448579872564],
              [-3.747531678924061, 50.256492425429911],
              [-3.743142075996379, 50.257262613600808],
              [-3.737403945061442, 50.257372852904652],
              [-3.736746491261107, 50.258004178346361],
              [-3.737277383995355, 50.259099821700019],
              [-3.74429649710436, 50.259523503809646],
              [-3.745290237587194, 50.259957387324782],
              [-3.745036815546994, 50.260163562356709],
              [-3.742247189670511, 50.261458313944594],
              [-3.74150069760983, 50.261340889911843],
              [-3.739149156289616, 50.26184021274662],
              [-3.737967600854184, 50.261488235041739],
              [-3.734538050673272, 50.261589855452613],
              [-3.733259353207839, 50.262464281067103],
              [-3.729557075540717, 50.26336670210619],
              [-3.729609619464271, 50.263926253950359],
              [-3.730481218514923, 50.2643593837102],
              [-3.728625421604193, 50.266854098099657],
              [-3.727084989012283, 50.267482311259599],
              [-3.725720621237234, 50.268518020441334],
              [-3.724053413521897, 50.268746938270077],
              [-3.719949161762959, 50.27066235340881],
              [-3.723287911463804, 50.270203648792773],
              [-3.728767294217479, 50.268911640544836],
              [-3.729693866793825, 50.267993940978549],
              [-3.731185249561029, 50.268103042635666],
              [-3.730424802710696, 50.267370557224957],
              [-3.730427792731005, 50.266640189970246],
              [-3.732370631219159, 50.26442026811354],
              [-3.735666193926257, 50.263560710380723],
              [-3.738672284319467, 50.263501371818187],
              [-3.741500683643033, 50.263071362382753],
              [-3.743155312430114, 50.262159726153868],
              [-3.745212202036952, 50.262082976283146],
              [-3.74618426823003, 50.261692414946623],
              [-3.747139269327132, 50.261219355877124],
              [-3.747399910528014, 50.260252162949563],
              [-3.750190975718637, 50.259614737083631],
              [-3.751124527668525, 50.258712047394894],
              [-3.751890070886724, 50.257399051867317],
              [-3.751410189309281, 50.255981613180609],
              [-3.753385764257828, 50.255531786057155],
              [-3.754106718643968, 50.254993844904227],
              [-3.756563245128884, 50.254692299457197],
              [-3.757182067261096, 50.255535587686929],
              [-3.753673562218731, 50.257791271363253],
              [-3.75443547841931, 50.260543671315595],
              [-3.754827283896454, 50.260935295421369],
              [-3.756562387829749, 50.261419042868482],
              [-3.757627066833574, 50.260498128631312],
              [-3.759441180444794, 50.260270099458999],
              [-3.760836661051027, 50.26063660554653],
              [-3.761977326989886, 50.261549307503792],
              [-3.761474633589289, 50.263934085561274],
              [-3.761918638951457, 50.264635192778655],
              [-3.760901014072238, 50.265500568229768],
              [-3.76169309575801, 50.266584047941251],
              [-3.760342565758149, 50.267136971994454],
              [-3.759293928653095, 50.268038779101254],
              [-3.760003876682544, 50.26959660559497],
              [-3.759925467485911, 50.270704972394419],
              [-3.760945253743166, 50.271568248431144],
              [-3.761131515081857, 50.273231140236135],
              [-3.762060422254073, 50.274177630636785],
              [-3.761899677268465, 50.274803362152134],
              [-3.759992610956689, 50.275303558244701],
              [-3.759132462410185, 50.276754746390424],
              [-3.758763566082421, 50.280911122003111],
              [-3.758009606432766, 50.283647751636771],
              [-3.759504860822583, 50.282476569446217],
              [-3.759293935810302, 50.281857369722822],
              [-3.76082040000425, 50.278948031274794],
              [-3.76095874747163, 50.276763957834916],
              [-3.761921299924583, 50.276339232954932],
              [-3.762453334540824, 50.275050398648041],
              [-3.763063120991009, 50.274727251805537],
              [-3.762835076362252, 50.274480674881765],
              [-3.763976435123854, 50.273693453821849],
              [-3.7651260250429, 50.271763839817247],
              [-3.768099019248259, 50.272313148571712],
              [-3.768042922470659, 50.272579329572558],
              [-3.772551791074065, 50.275219667226331],
              [-3.772769401649725, 50.275901701209833],
              [-3.7723149553466, 50.277080571237072],
              [-3.773535671533178, 50.278695281177171],
              [-3.775892158867074, 50.282577136605973],
              [-3.776255557489725, 50.282422279182903],
              [-3.775154134623676, 50.279680608178722],
              [-3.776165115888534, 50.279314386600056],
              [-3.778016703381567, 50.279246493281128],
              [-3.774719118716578, 50.278400191433498],
              [-3.773566809267376, 50.27722965975412],
              [-3.774232985004987, 50.274460983896795],
              [-3.773146103466802, 50.27274440313613],
              [-3.772367273166593, 50.272443289937918],
              [-3.772116059448695, 50.2716493405661],
              [-3.772595030264737, 50.271660921835199],
              [-3.773469843202182, 50.270579965835147],
              [-3.775116680907637, 50.270147381229727],
              [-3.774418966549526, 50.270102275329656],
              [-3.774609123626079, 50.269630775448277],
              [-3.772558643794411, 50.27019003344909],
              [-3.772484814522074, 50.269940222878226],
              [-3.772936185875961, 50.269851489171963],
              [-3.772395723587091, 50.269846243953552],
              [-3.771964013840269, 50.27020000324034],
              [-3.769941731608781, 50.269666894605798],
              [-3.770397781652679, 50.268947609305215],
              [-3.771382100555542, 50.268435229380017],
              [-3.770984849757436, 50.268166963601828],
              [-3.771254071844765, 50.267669979748987],
              [-3.770408179041107, 50.266920168150314],
              [-3.769091077526799, 50.266555281029326],
              [-3.769157533917487, 50.26603890388963],
              [-3.767354514886263, 50.264524746892285],
              [-3.767446798371957, 50.262886406059593],
              [-3.769473962709732, 50.260569232498092],
              [-3.771786914376592, 50.260098663677425],
              [-3.775393644658716, 50.261823548583152],
              [-3.781002090744352, 50.261872619508203],
              [-3.782945536197501, 50.262592025140812],
              [-3.783717677339725, 50.262979514203565],
              [-3.78579383447054, 50.265743908841031],
              [-3.785773484022903, 50.264674814438024],
              [-3.784516472296083, 50.262653350669574],
              [-3.784833664495794, 50.262280616316986],
              [-3.781329814314219, 50.26125509178582],
              [-3.776784849246232, 50.260864188358816],
              [-3.776302173262446, 50.260222190084654],
              [-3.776647591061343, 50.259362461847054],
              [-3.776417071448256, 50.258445882895828],
              [-3.775133073260259, 50.257947444734199],
              [-3.774077342742836, 50.257059656802852],
              [-3.775892625793491, 50.257132656597413],
              [-3.779838488589462, 50.256327553386676],
              [-3.783811842177071, 50.256374541969777],
              [-3.787549345831222, 50.255180228245777],
              [-3.787772776459652, 50.254965426656589],
              [-3.786622610006488, 50.25515760805002],
              [-3.782166921268268, 50.254964315866772],
              [-3.781523031869059, 50.254701667989224],
              [-3.780757659337438, 50.253701556824289],
              [-3.778289373806423, 50.254736820858497],
              [-3.778341312120699, 50.255980820401085],
              [-3.777656567923229, 50.255336523908547],
              [-3.774475721989651, 50.254604458305131],
              [-3.772599879107853, 50.2546735694239],
              [-3.769272091500793, 50.254099203006966],
              [-3.766298277010214, 50.254293758447282],
              [-3.764867247137836, 50.25398632045956],
              [-3.764317196047064, 50.252691420024007],
              [-3.764955340088493, 50.25192891438914],
              [-3.766272080855446, 50.25134766550002],
              [-3.766062301361174, 50.2508336897208],
              [-3.763474257539979, 50.250643653855008],
              [-3.762368001564439, 50.250048837867872],
              [-3.761334828049505, 50.248582267283979],
              [-3.759224450081494, 50.24764737171899],
              [-3.761468929108277, 50.245866690730878],
              [-3.760357735161064, 50.244677415399515],
              [-3.76100178754187, 50.243886059410798],
              [-3.761312769857534, 50.242354132418313],
              [-3.760408824020009, 50.24123007055092],
              [-3.758807679815412, 50.240632826187202],
              [-3.759940905206605, 50.239492288762392],
              [-3.76121721021496, 50.239343428412688],
              [-3.762481538883403, 50.239706505064483],
              [-3.764325081817361, 50.24113287940466],
              [-3.764971231820957, 50.242299503423844],
              [-3.768047431478969, 50.244360961176838],
              [-3.768542348308675, 50.243749917423138],
              [-3.770484797375561, 50.244013539050961],
              [-3.771020924409339, 50.24470421515322],
              [-3.771604786844798, 50.244790650704893],
              [-3.772051875815541, 50.243987845560788],
              [-3.775226703602721, 50.243747794935288],
              [-3.774234473459473, 50.243277267516468],
              [-3.769207422650342, 50.243189345747545],
              [-3.768117089615008, 50.242605134818028],
              [-3.766527693065903, 50.241077818345339],
              [-3.76984495672677, 50.240068543050143],
              [-3.766574238063884, 50.240478097406346],
              [-3.76605396703811, 50.240251260047941],
              [-3.765902320696189, 50.240473923156216],
              [-3.766350443361489, 50.238976772295544],
              [-3.766922725466747, 50.238524654978363],
              [-3.76608500101445, 50.236348207531293],
              [-3.767952306343576, 50.23538530469942],
              [-3.771007421836472, 50.233065030281054],
              [-3.771760523956386, 50.232916833545829],
              [-3.772181580753889, 50.232057759440991],
              [-3.774234911393971, 50.231505648926905],
              [-3.775689850898532, 50.230057833303469],
              [-3.775743209409315, 50.22934287695481],
              [-3.778415885769501, 50.229161676757286],
              [-3.779258215656633, 50.229906089965638],
              [-3.780191332794935, 50.230188601794438],
              [-3.781162240493734, 50.230092769499983],
              [-3.781877840630823, 50.229617692183233],
              [-3.7808314582077, 50.228665961693167],
              [-3.780734854919374, 50.227841773281646],
              [-3.782402711876312, 50.226622657793165],
              [-3.783823865382764, 50.226346304118429],
              [-3.784316191740793, 50.225713643229938],
              [-3.78376078292404, 50.22488121386953],
              [-3.78132645108695, 50.224717102729656],
              [-3.780890901275965, 50.224578974668034],
              [-3.781263917573235, 50.224393370178298],
              [-3.781070944226655, 50.224068040170799],
              [-3.780523291067058, 50.224044057558018],
              [-3.780600646579606, 50.223486129047011],
              [-3.781592207395656, 50.223647210721943],
              [-3.781153117188731, 50.223188044024234],
              [-3.782102035993453, 50.22231813709903],
              [-3.781660789454625, 50.222101849569043],
              [-3.782036600185396, 50.221390037023802],
              [-3.781755637457963, 50.220659518722968],
              [-3.780774657442118, 50.21935061234786],
              [-3.78115968665327, 50.218773574473758],
              [-3.780660913570879, 50.217877301805892],
              [-3.781049078385365, 50.216895475384803],
              [-3.78071420409538, 50.216522852195055],
              [-3.781135068914779, 50.216419260957771],
              [-3.780658555277171, 50.215668353364833],
              [-3.781649456849962, 50.215853728542484],
              [-3.781312224390144, 50.215530611519966],
              [-3.78163344251048, 50.215386273752642],
              [-3.784269087760688, 50.215511306955378],
              [-3.784581003021684, 50.215268167240481],
              [-3.785333021508993, 50.215587597842372],
              [-3.785164459859949, 50.215202536964696],
              [-3.786673337020473, 50.21514515685498],
              [-3.786122395491024, 50.214767777868097],
              [-3.786757743267849, 50.214703140359646],
              [-3.785760225363911, 50.214302948044967],
              [-3.786694458740242, 50.214397410159521],
              [-3.787008460626019, 50.214173119605775],
              [-3.78678980846715, 50.213197909643256],
              [-3.785050721939849, 50.211742400032072],
              [-3.784600249706405, 50.210863389408594],
              [-3.786757041278758, 50.210407498183336],
              [-3.786511250022604, 50.210168434560615],
              [-3.786851575195128, 50.209824115679268],
              [-3.787533867786998, 50.210079846098985],
              [-3.788668943683755, 50.209775453436954],
              [-3.790075309380322, 50.209979540969222],
              [-3.79055162063723, 50.210760095168979],
              [-3.79219295044931, 50.211323902341746],
              [-3.792627251209963, 50.211131917990848],
              [-3.792939101469845, 50.21133756968721],
              [-3.79267345123473, 50.211504466468575],
              [-3.79634550628734, 50.211240860268319],
              [-3.796605394376467, 50.211555236990591],
              [-3.796309090506441, 50.211689337574363],
              [-3.798320927625452, 50.212449698176506],
              [-3.798572874264591, 50.212850538373729],
              [-3.798247628047096, 50.21307233637026],
              [-3.798961477524031, 50.213456129285028],
              [-3.798454615891748, 50.213470275199462],
              [-3.799220259488435, 50.213703060384127],
              [-3.798786914794543, 50.213734057324331],
              [-3.799330176163117, 50.214761780440824],
              [-3.802664479611904, 50.214977205148507],
              [-3.804058703201365, 50.214519335028662],
              [-3.804178832146909, 50.214768408841593],
              [-3.806940710280707, 50.214857687725633],
              [-3.807613822105693, 50.214606167156511],
              [-3.808251825662079, 50.214760830594777],
              [-3.808279779350221, 50.21438803281621],
              [-3.810460840757409, 50.214685942298409],
              [-3.810837860868703, 50.214537055683408],
              [-3.810422603019348, 50.214268304962076],
              [-3.811875637326748, 50.214329292418903],
              [-3.81404802808253, 50.215210997950223],
              [-3.816322410204625, 50.215269891245974],
              [-3.815937908915164, 50.216661021279094],
              [-3.816766868979356, 50.216601286966977],
              [-3.816754287580594, 50.217008024731555],
              [-3.817863741209212, 50.216730730473998],
              [-3.817854822011342, 50.216459243113988],
              [-3.819629200574299, 50.216195807097051],
              [-3.819310610506682, 50.216959015198924],
              [-3.820150403711393, 50.216926069855901],
              [-3.821027093086003, 50.217309874175832],
              [-3.822939394460713, 50.217097291282855],
              [-3.82327751578509, 50.217288958345726],
              [-3.822940050960637, 50.217776348643028],
              [-3.82419480104456, 50.217964415487259],
              [-3.823378774725328, 50.218841579089855],
              [-3.823869434938506, 50.218695363225734],
              [-3.824491616238133, 50.219057054579146],
              [-3.824153431700053, 50.219525571603469],
              [-3.824964405459004, 50.219581188156802],
              [-3.825036542454823, 50.220038762538962],
              [-3.826325869765172, 50.220212771868532],
              [-3.826117670608626, 50.221184728615626],
              [-3.827143097134475, 50.221898936514286],
              [-3.826808681011162, 50.22290615857861],
              [-3.827155947030942, 50.223592355420521],
              [-3.827682577470017, 50.223689302562775],
              [-3.828242171062002, 50.223108459930494],
              [-3.828809609772752, 50.223099526622036],
              [-3.828594865806494, 50.223386227234514],
              [-3.829734124599199, 50.223372782541304],
              [-3.83047997914414, 50.223849419218368],
              [-3.83113864864817, 50.223769780842282],
              [-3.83134015926105, 50.224089498093321],
              [-3.831837415389943, 50.224078058905526],
              [-3.831568882807606, 50.224204615894571],
              [-3.832098571817385, 50.224307790832398],
              [-3.831920879687738, 50.224682956446124],
              [-3.832849459190901, 50.224695289718603],
              [-3.832843686061205, 50.225020074048977],
              [-3.833632979987873, 50.224836724956113],
              [-3.832936751355909, 50.225363086034932],
              [-3.833812032954351, 50.225776497863812],
              [-3.833868046658828, 50.226176757994146],
              [-3.835498295224323, 50.227519934874159],
              [-3.83670585881637, 50.227493650265735],
              [-3.8377993177093, 50.227890989981525],
              [-3.837957106582224, 50.228676391481848],
              [-3.84160774956164, 50.229648411136942],
              [-3.842061656572826, 50.230366151892085],
              [-3.843789176046277, 50.229677662983498],
              [-3.844105673633277, 50.229885802797511],
              [-3.843750760904276, 50.23017205848344],
              [-3.847772100765621, 50.23035369935058],
              [-3.848943316207063, 50.231343320079318],
              [-3.849563648151846, 50.231176943382124],
              [-3.850754703642009, 50.232351512199457],
              [-3.85143173160801, 50.232309242663362],
              [-3.852279418443076, 50.232690576664282],
              [-3.851934333720266, 50.232867869882405],
              [-3.852830449419862, 50.233087430152338],
              [-3.852731606857322, 50.234335613740122],
              [-3.855673939259032, 50.234490116467768],
              [-3.855772706210447, 50.234216911281166],
              [-3.857095417927322, 50.234082443140345],
              [-3.85792674837851, 50.2344738913988],
              [-3.859461778962333, 50.234210080042274],
              [-3.859191501234676, 50.234507620110072],
              [-3.860015251787336, 50.234271373842994],
              [-3.85970698887402, 50.234745811334101],
              [-3.860162869007973, 50.23464137326129],
              [-3.860317005719057, 50.234998676070489],
              [-3.861226478302146, 50.23516309280032],
              [-3.860944331185106, 50.235407760850656],
              [-3.861941717501897, 50.235596846925411],
              [-3.86096312814298, 50.235926429823671],
              [-3.862634687366682, 50.236250414310177],
              [-3.862362105301565, 50.236776453439582],
              [-3.863172881790258, 50.236674407811471],
              [-3.862346442759639, 50.237166156986916],
              [-3.863165250632568, 50.237162020366675],
              [-3.863163888772164, 50.237486735814315],
              [-3.86440327790056, 50.237299555605745],
              [-3.865116412462871, 50.23785744522754],
              [-3.86579619350905, 50.237704418386137],
              [-3.865579132257507, 50.238143228129537],
              [-3.866073438514423, 50.238124494968815],
              [-3.865902900783635, 50.238461821905098],
              [-3.866871740581215, 50.238994906155817],
              [-3.867447149840147, 50.23846758603019],
              [-3.868196535545538, 50.238767637531545],
              [-3.867562473954584, 50.23926532381833],
              [-3.868553454954742, 50.239503025631642],
              [-3.86737628384946, 50.239632585679701],
              [-3.866898920189467, 50.240013521596055],
              [-3.868303477343268, 50.239960358493889],
              [-3.867970352951114, 50.240766207196323],
              [-3.86952857247143, 50.240912030168822],
              [-3.869802936200001, 50.240719633548593],
              [-3.869968072882652, 50.24114060600899],
              [-3.870643099654005, 50.241080268688449],
              [-3.870627170910219, 50.241568914826324],
              [-3.871261975710536, 50.241877987310083],
              [-3.870757084834942, 50.242486937790432],
              [-3.868839632833716, 50.243018783871868],
              [-3.867675060227485, 50.242827048007257],
              [-3.867691322699785, 50.24317126741456],
              [-3.866703450021411, 50.24323031924434],
              [-3.866422478815476, 50.243505561848849],
              [-3.86417432397027, 50.242393992781047],
              [-3.861664504631378, 50.242770629766795],
              [-3.859586092627341, 50.242160830618403],
              [-3.859002424720187, 50.242659461342377],
              [-3.859333276064978, 50.244962095720524],
              [-3.860137970408708, 50.245420512434713],
              [-3.860604723196047, 50.24490845878465],
              [-3.860404644409048, 50.245461213170501],
              [-3.861705047913119, 50.245466462677861],
              [-3.858968299807313, 50.246107510736408],
              [-3.859619206633421, 50.247154818147955],
              [-3.859224042368163, 50.247272672366734],
              [-3.859497175627852, 50.248523899838162],
              [-3.859838792195087, 50.248579591955888],
              [-3.859614800884816, 50.248915066223887],
              [-3.859983269890489, 50.248867793458125],
              [-3.859519531432676, 50.249206206553644],
              [-3.859627126337775, 50.249772022139567],
              [-3.860194067599937, 50.250570629696604],
              [-3.860986259407735, 50.250597516275214],
              [-3.860113793091407, 50.250994645384232],
              [-3.859583528868596, 50.251750557956761],
              [-3.860157697583895, 50.252950193785452],
              [-3.860760382743601, 50.253152806184509],
              [-3.860203699479257, 50.25337488556233],
              [-3.860436037142813, 50.254405504482222],
              [-3.859444627949406, 50.255100443722831],
              [-3.859678505845836, 50.255306266120499],
              [-3.858596768369955, 50.255627583495624],
              [-3.857750775276882, 50.25663587952512],
              [-3.8579941948239, 50.258024296531602],
              [-3.858062849537301, 50.260797923723054],
              [-3.859113799920831, 50.262132945442168],
              [-3.860107131916963, 50.262779921129962],
              [-3.86058066795515, 50.262545683362234],
              [-3.860660760731337, 50.262908667551315],
              [-3.861520499479703, 50.262935369026586],
              [-3.861178314934454, 50.263443629711077],
              [-3.862830982882146, 50.264481161002472],
              [-3.864195355286138, 50.264458376704148],
              [-3.864239056378589, 50.26417615556278],
              [-3.86515842870022, 50.263896968315166],
              [-3.866064825465148, 50.264075789383782],
              [-3.868025225419942, 50.263615258628064],
              [-3.868645677887189, 50.264554174012076],
              [-3.867888412640816, 50.265067331385495],
              [-3.867752868830783, 50.265798944051852],
              [-3.869050571227858, 50.267441110451529],
              [-3.870329988836784, 50.268292063121706],
              [-3.871506338370004, 50.268624794695612],
              [-3.872029819378253, 50.268483246082859],
              [-3.87248247357255, 50.268789850749776],
              [-3.873886955255267, 50.268320191989112],
              [-3.873214061100806, 50.268909373442987],
              [-3.874723796967789, 50.269368912264909],
              [-3.875133099908964, 50.270393047787188],
              [-3.876924096338314, 50.27097303849515],
              [-3.876983125467749, 50.271688926438131],
              [-3.877591585085476, 50.2716736983323],
              [-3.878115800387996, 50.272192288401264],
              [-3.879067069120471, 50.272079769175534],
              [-3.878576101184108, 50.27229907359343],
              [-3.879195787725202, 50.272283655590414],
              [-3.879136586845436, 50.273381911383616],
              [-3.879614635898395, 50.27365389960363],
              [-3.881643227829025, 50.273492445672261],
              [-3.881544543703719, 50.274121842440863],
              [-3.880204573089106, 50.27462832478615],
              [-3.880496449354327, 50.275339543020152],
              [-3.881212308496544, 50.275377422734614],
              [-3.880829067934605, 50.275588696155886],
              [-3.881759412676876, 50.276509932844576],
              [-3.881015330560214, 50.277038251318643],
              [-3.879463632578025, 50.276946437229569],
              [-3.87868465121541, 50.278121991017429],
              [-3.879827736014658, 50.281877484918859],
              [-3.877824782664952, 50.281879292818573],
              [-3.877271534671738, 50.281481693522203],
              [-3.877027865690293, 50.280497163810118],
              [-3.875581510366787, 50.279695749259311],
              [-3.871285550699026, 50.27881252843644],
              [-3.868079287798289, 50.279337219592726],
              [-3.866579510406829, 50.280250852328919],
              [-3.865331847661365, 50.282300885519426],
              [-3.865177107970332, 50.283441138568975],
              [-3.864451838851514, 50.283847624959229],
              [-3.865322195922698, 50.284674615204018],
              [-3.86414184545094, 50.287229927017421],
              [-3.85966012953922, 50.289263378635397],
              [-3.858250141815295, 50.290583799876856],
              [-3.85436603471647, 50.291102755515467],
              [-3.852304568882004, 50.291053788245698],
              [-3.85350719617816, 50.291726264888808],
              [-3.85836134773797, 50.291564188392954],
              [-3.859021108046565, 50.293182484952297],
              [-3.858573428292177, 50.294196097257228],
              [-3.859400180188446, 50.29469464073378],
              [-3.859838848116619, 50.296006170026637],
              [-3.85976929802197, 50.296922892429727],
              [-3.858914971003465, 50.297987084376111],
              [-3.857753855553067, 50.298926658343035],
              [-3.853590949313278, 50.300021171674558],
              [-3.851723702660644, 50.300897293892895],
              [-3.850138977119205, 50.302939930140269],
              [-3.850081227777707, 50.302465057469341],
              [-3.848128354414106, 50.303599717403571],
              [-3.850139091695348, 50.303847440914225],
              [-3.850126926316398, 50.304438552047344],
              [-3.843410600140494, 50.308446956723103],
              [-3.842036773487529, 50.309020982546237],
              [-3.840946590765506, 50.308976197768047],
              [-3.838382412380737, 50.308075100603901],
              [-3.838464464283446, 50.307289511688182],
              [-3.837189259891144, 50.307617278756382],
              [-3.83818328885378, 50.307463949208618],
              [-3.837910621720047, 50.308041189878267],
              [-3.834682400507445, 50.308748773384842],
              [-3.83075135512088, 50.310430614232551],
              [-3.829905382815454, 50.31016242110816],
              [-3.826434643518015, 50.31000925484669],
              [-3.825803938688992, 50.310666743925033],
              [-3.826096975411309, 50.310726897600972],
              [-3.826649219862241, 50.310111113852876],
              [-3.828990091031745, 50.310356712706287],
              [-3.828248947838487, 50.310550955765741],
              [-3.828386056263071, 50.310903168162064],
              [-3.828473656099116, 50.310623870757098],
              [-3.829509911579502, 50.310324246330644],
              [-3.830580793984644, 50.310853327818577],
              [-3.835060497638493, 50.308866023579],
              [-3.838856328573315, 50.308528103687578],
              [-3.839935165485429, 50.308897776886518],
              [-3.840373996824949, 50.309641840701808],
              [-3.837516821799069, 50.309848047842806],
              [-3.834141631975627, 50.310973469080508],
              [-3.838041520784271, 50.310233694439702],
              [-3.837163491413177, 50.311794572881517],
              [-3.837236669773291, 50.312417610332794],
              [-3.838406081691721, 50.310072329008442],
              [-3.839643605758149, 50.309858471884205],
              [-3.840333826894742, 50.310201913596536],
              [-3.839640925177283, 50.311171661485488],
              [-3.839576955779174, 50.312207002140667],
              [-3.839965210722653, 50.312622681700425],
              [-3.839805344309954, 50.31153781911928],
              [-3.840863100048525, 50.310196229164191],
              [-3.843100789485006, 50.309042787278315],
              [-3.846886380541114, 50.307897990294457],
              [-3.849224030934339, 50.306576447919021],
              [-3.85365663048012, 50.308066318067247],
              [-3.85456759079352, 50.309316365014752],
              [-3.854654995250872, 50.308783414416965],
              [-3.853884495958894, 50.308328011385491],
              [-3.853874951645449, 50.307829886329991],
              [-3.851029145128651, 50.306118687414767],
              [-3.85102353234803, 50.305070055880478],
              [-3.854319379582055, 50.303117041026326],
              [-3.854652432406025, 50.302653922101079],
              [-3.854320164785127, 50.302234699117982],
              [-3.859304845130521, 50.300855390779752],
              [-3.860451238280173, 50.300257816697524],
              [-3.862081216976675, 50.298344727000995],
              [-3.862892058268142, 50.298325435050309],
              [-3.862267795362083, 50.296434967069167],
              [-3.862651760545425, 50.294834141142964],
              [-3.863387808328019, 50.294519235614978],
              [-3.862524400125436, 50.294125640624387],
              [-3.860749364132746, 50.292317462237257],
              [-3.860491064549878, 50.291490533774812],
              [-3.860950008026157, 50.290947129915146],
              [-3.862985579989027, 50.289937740601367],
              [-3.864806058333089, 50.289389572606652],
              [-3.866129683609134, 50.288378959057255],
              [-3.866262282158625, 50.287892940665856],
              [-3.867255925562188, 50.287885971289469],
              [-3.867034737262519, 50.2872203558859],
              [-3.867725480538174, 50.286254068903737],
              [-3.868577524350726, 50.282991656942912],
              [-3.869435742029047, 50.282038854860765],
              [-3.870970934214526, 50.281276617700463],
              [-3.874232234697388, 50.280971318267987],
              [-3.875284041236534, 50.28141215346681],
              [-3.875839741363059, 50.281051508716509],
              [-3.876251033779676, 50.28151616566047],
              [-3.876105607002385, 50.282886536656235],
              [-3.876592173874193, 50.283551447666966],
              [-3.878758564954898, 50.284525591237582],
              [-3.880724915617107, 50.284625095671544],
              [-3.885214489059723, 50.283747354855265],
              [-3.887429054556597, 50.282386550424533],
              [-3.887365258515107, 50.282122256568677],
              [-3.889600877080836, 50.281898839848253],
              [-3.892796133170828, 50.282301040779252],
              [-3.893476980878291, 50.281842933249337],
              [-3.894660044614711, 50.281695929137491],
              [-3.896644776953453, 50.282965915452948],
              [-3.897011861455404, 50.283301704256594],
              [-3.896712440302467, 50.283502667087205],
              [-3.897324151605446, 50.283565533251959],
              [-3.896938990881374, 50.28429316085883],
              [-3.897487082771455, 50.284203263175954],
              [-3.898689384898032, 50.284961625136972],
              [-3.897455152145993, 50.28565905404102],
              [-3.897647905590289, 50.2860228714597],
              [-3.896703261284, 50.286201990741901],
              [-3.89704833368996, 50.287360212735756],
              [-3.899368823439699, 50.288593190824812],
              [-3.900241248570865, 50.288538452385787],
              [-3.901044964134473, 50.28781655896239],
              [-3.903030369333743, 50.289725917104825],
              [-3.904640287319339, 50.290314745524974],
              [-3.905312331826583, 50.290163417377002],
              [-3.905357377111521, 50.290588106696688],
              [-3.907595136202081, 50.290199715597879],
              [-3.906926791237169, 50.290689180067666],
              [-3.907483234324603, 50.291052408722635],
              [-3.907028860754058, 50.291416039115283],
              [-3.907599855163983, 50.291440844637656],
              [-3.907193748370511, 50.292098694573163],
              [-3.90834560891055, 50.292499814269483],
              [-3.909956093737239, 50.2922224140002],
              [-3.90923806552582, 50.292943860433319],
              [-3.910396647011207, 50.293126289236099],
              [-3.909208571823014, 50.293539764274875],
              [-3.910020515506464, 50.294391667340584],
              [-3.912223978676829, 50.294439972856225],
              [-3.913247102561387, 50.294849458757412],
              [-3.913853220054085, 50.294558856660302],
              [-3.914092643158189, 50.294822042241513],
              [-3.915525342821593, 50.294102278012126],
              [-3.915799153660764, 50.294277648989308],
              [-3.915106903495363, 50.294695600472593],
              [-3.916732725798784, 50.295776885347038],
              [-3.918586432851075, 50.29613394562363],
              [-3.919529866989099, 50.295852134711069],
              [-3.920654652142773, 50.296412778673336],
              [-3.921720078436808, 50.29598772365987],
              [-3.922067798914578, 50.29608000989662],
              [-3.921751172554935, 50.296410838886295],
              [-3.922038405609954, 50.296848605565344],
              [-3.922996774050056, 50.297076493813975],
              [-3.924774698596247, 50.296701677830612],
              [-3.926031905228087, 50.296992947029125],
              [-3.9263106533816, 50.296559300211612],
              [-3.926861151044506, 50.2965267879081],
              [-3.926632871092924, 50.296159110127419],
              [-3.927207577126017, 50.2965867009462],
              [-3.927939595251665, 50.296042997007717],
              [-3.9284323898836, 50.296215604359745],
              [-3.92842845594363, 50.295667019601019],
              [-3.930865318239293, 50.296206682181669],
              [-3.933601445603507, 50.295053090260076],
              [-3.933335674207171, 50.295389401051914],
              [-3.935241092782004, 50.295841574402189],
              [-3.934939739603701, 50.296165889476235],
              [-3.935288114473734, 50.296481183248105],
              [-3.936061092129647, 50.296568143203743],
              [-3.936540833094159, 50.296141915415305],
              [-3.937054627642323, 50.296243081627665],
              [-3.936477990500334, 50.296530615766081],
              [-3.936987751677146, 50.296497834875368],
              [-3.936949626714047, 50.296699942051617],
              [-3.938132533883917, 50.296059613171032],
              [-3.937411806928111, 50.296636472486782],
              [-3.93863642112711, 50.297505581271594],
              [-3.94285751563385, 50.296409733977136],
              [-3.943362556282144, 50.295882318754423],
              [-3.943687840161717, 50.295939838035054],
              [-3.94284911533444, 50.296685998710792],
              [-3.942936803013529, 50.297046102431146],
              [-3.943478122538316, 50.2968229861509],
              [-3.943653072106084, 50.297291359998596],
              [-3.944413174065738, 50.297061881331885],
              [-3.94490378062476, 50.297247947165083],
              [-3.944625661628754, 50.297865112115545],
              [-3.945364106290466, 50.297586521181216],
              [-3.943836771796599, 50.299076348995314],
              [-3.943491578002866, 50.300942138427338],
              [-3.944461096863045, 50.301783073175137],
              [-3.944918772762511, 50.301849166866809],
              [-3.945802870734749, 50.301353172585529],
              [-3.947405398646966, 50.301738265092716],
              [-3.946839998096017, 50.302576111558018],
              [-3.947573278742233, 50.302685246758855],
              [-3.947120820481822, 50.302987841189996],
              [-3.947124812684296, 50.304048198823146],
              [-3.947791615604191, 50.304763761003052],
              [-3.946868711984372, 50.305204655621068],
              [-3.947318546307202, 50.305318541008091],
              [-3.946735008794203, 50.306297900188305],
              [-3.947051890292287, 50.306285395823622],
              [-3.946933250155101, 50.306647154253049],
              [-3.943891524997662, 50.30809848225195],
              [-3.942628527469607, 50.308364246286544],
              [-3.94301002270698, 50.309832926773396],
              [-3.939951756085552, 50.312921379644742],
              [-3.938838055365466, 50.314823361183066],
              [-3.938702206206658, 50.317318832314122],
              [-3.93794081828616, 50.317145350451788],
              [-3.936886920312481, 50.318587607661115],
              [-3.936362470407572, 50.320580475995868],
              [-3.935068775960267, 50.322209293323745],
              [-3.934831852472159, 50.323190011523906],
              [-3.929670362897213, 50.324474755614389],
              [-3.927961579288435, 50.324430261497994],
              [-3.928498919325974, 50.324862962842879],
              [-3.928713280863418, 50.325229967671987],
              [-3.927214851466001, 50.326496027023374],
              [-3.921959408750467, 50.329591038977249],
              [-3.921083039140996, 50.330107084403537],
              [-3.919966620939267, 50.332039499260937],
              [-3.92187774038318, 50.329691387826479],
              [-3.926121868024327, 50.327471130582445],
              [-3.928479696049256, 50.325776195402206],
              [-3.930926025848667, 50.32512486974823],
              [-3.935767597314183, 50.324713342476372],
              [-3.935984951441875, 50.327364818395473],
              [-3.933395143537983, 50.330678228341718],
              [-3.928975999402787, 50.334214427902893],
              [-3.926816552976727, 50.336723653670859],
              [-3.926015910134706, 50.339998221235184],
              [-3.927350597702016, 50.342209357228477],
              [-3.92639748169446, 50.343488841969148],
              [-3.926912481219317, 50.344267297758961],
              [-3.925717794736547, 50.344996738350105],
              [-3.925959401569902, 50.345861574340304],
              [-3.924739026192789, 50.347104098822221],
              [-3.925355436552752, 50.348373763398335],
              [-3.925394175701924, 50.347767812875581],
              [-3.925706421971304, 50.348259119601238],
              [-3.925861199456871, 50.347915675608498],
              [-3.924969848404109, 50.347076891640569],
              [-3.926182513954288, 50.346026068739157],
              [-3.926027817253067, 50.344912452857294],
              [-3.92713042893752, 50.344234903104834],
              [-3.926852611201212, 50.343621607605229],
              [-3.927582758072003, 50.342424066446846],
              [-3.926918791711811, 50.341402541475517],
              [-3.927772374396548, 50.341935235077315],
              [-3.926945651815108, 50.341302260582218],
              [-3.926250378910501, 50.339992536953808],
              [-3.927216317772809, 50.336531745245971],
              [-3.928924235481568, 50.334566960610893],
              [-3.93176771479129, 50.332679519509782],
              [-3.93257187565196, 50.33248176967362],
              [-3.934635028480958, 50.333217351307191],
              [-3.933575538325011, 50.332352649196217],
              [-3.938756433564607, 50.328834146977961],
              [-3.938832362949013, 50.327731087368903],
              [-3.939549629052748, 50.327023865743641],
              [-3.939295957387745, 50.326038733337832],
              [-3.939608662183304, 50.325609886726369],
              [-3.939867666332406, 50.325794442846941],
              [-3.939483172591188, 50.325081321178452],
              [-3.93822399303286, 50.324175021927026],
              [-3.938920101965468, 50.321672007385871],
              [-3.938275124902014, 50.32115030713291],
              [-3.9382824690909, 50.319569896870448],
              [-3.940238756063238, 50.317624177656228],
              [-3.940091074559705, 50.316239730489599],
              [-3.941274684114576, 50.314950876751503],
              [-3.945400297591794, 50.313131605616405],
              [-3.946566288929102, 50.31289531932515],
              [-3.94769996120306, 50.311112550243053],
              [-3.948473053477304, 50.310646279723031],
              [-3.948481902513147, 50.309832150563224],
              [-3.950450936271627, 50.309418661589469],
              [-3.951254550872032, 50.308738695720422],
              [-3.951217004355535, 50.309125180227973],
              [-3.95193067727107, 50.309198640541602],
              [-3.952207115622901, 50.309604135540759],
              [-3.953607171059595, 50.309530239483472],
              [-3.954420473008955, 50.308642320987239],
              [-3.954341448211116, 50.308050927548969],
              [-3.955523304692476, 50.307110031847458],
              [-3.955362626752116, 50.306856398806595],
              [-3.956670875263585, 50.306258744388451],
              [-3.957874789143194, 50.306164713640946],
              [-3.957708378870133, 50.30584012554398],
              [-3.958476412144711, 50.305596930467949],
              [-3.960014356899479, 50.3057032163602],
              [-3.959879619585561, 50.305329527940266],
              [-3.960601762252021, 50.3050295292553],
              [-3.960282192813558, 50.304908101195139],
              [-3.96069965689733, 50.304814713054533],
              [-3.960319266971569, 50.304785153543996],
              [-3.960563553436527, 50.304510304389609],
              [-3.963865933813249, 50.30384742646909],
              [-3.96461537796446, 50.304006549209134],
              [-3.964017136695175, 50.304586000220226],
              [-3.964111304198007, 50.305167238329894],
              [-3.965392080437711, 50.304928821108035],
              [-3.965428177843212, 50.30529337861676],
              [-3.96590432786177, 50.305022691116349],
              [-3.966471066547856, 50.30521187563857],
              [-3.9667779029886, 50.304854106717862],
              [-3.967242524139481, 50.305018932357349],
              [-3.968167875627663, 50.30450587906445],
              [-3.969160362750362, 50.304564616976641],
              [-3.969101890601332, 50.304848927972444],
              [-3.967905855141125, 50.30489437281291],
              [-3.968524123731096, 50.305106959276493],
              [-3.968019259179564, 50.305396132469959],
              [-3.968881056687738, 50.305588402736682],
              [-3.968706470315439, 50.306053667098169],
              [-3.969506400576162, 50.305835941871081],
              [-3.969315979584387, 50.306155768049152],
              [-3.970165867377718, 50.306501133613246],
              [-3.970866902743592, 50.306031436678367],
              [-3.972478453736314, 50.306014886405734],
              [-3.972917647731231, 50.306141445619573],
              [-3.972571358131636, 50.306461225150045],
              [-3.973054766832133, 50.307079021076198],
              [-3.974536614601043, 50.30708353259844],
              [-3.97564600588359, 50.307419951472589],
              [-3.976350119744574, 50.307194813776185],
              [-3.977327897506081, 50.307439014873211],
              [-3.97863786289484, 50.306990387854881],
              [-3.978968651309891, 50.307211411336631],
              [-3.978970274217439, 50.306776959131177],
              [-3.979502804587518, 50.306952272082441],
              [-3.979681425028043, 50.306654216252085],
              [-3.979923800390803, 50.306876743210779],
              [-3.980527378332336, 50.306700063282243],
              [-3.98005131474454, 50.30717408067833],
              [-3.980899199326691, 50.307536491460013],
              [-3.98127263520023, 50.307397908915341],
              [-3.981338375132634, 50.307627941540638],
              [-3.981656540534127, 50.30707206518256],
              [-3.983949323671698, 50.306820678171846],
              [-3.984426186693522, 50.306333139442749],
              [-3.984845055275978, 50.306442911649931],
              [-3.984697998208569, 50.306789905200858],
              [-3.98593151302767, 50.306429745876478],
              [-3.985553071563424, 50.306885928005791],
              [-3.986427702806259, 50.306507610323393],
              [-3.986212758298142, 50.307078825061481],
              [-3.988269864612713, 50.307204644382004],
              [-3.988115112098392, 50.307433948599098],
              [-3.988895458570345, 50.307657145624567],
              [-3.990146322230335, 50.307409972888912],
              [-3.991634424041679, 50.307530185831048],
              [-3.993843477145079, 50.307023700487306],
              [-3.993920859848855, 50.306489909418701],
              [-3.994689698811051, 50.306135830107443],
              [-3.997160703354394, 50.305247022905775],
              [-3.997706604201337, 50.304332810861958],
              [-3.997451692153456, 50.30394683942675],
              [-3.998141700845555, 50.303958361792986],
              [-3.999783971228406, 50.303269927516816],
              [-4.001324909932165, 50.303212817745482],
              [-4.002413010957955, 50.302103960066269],
              [-4.003545162382164, 50.30171028087269],
              [-4.004564944787067, 50.30168369886858],
              [-4.004106184282985, 50.302261852480946],
              [-4.00487418800232, 50.301690955799124],
              [-4.006859551698174, 50.301651287647715],
              [-4.005772125007603, 50.302206125267865],
              [-4.006429081038519, 50.302166901114923],
              [-4.006090627175255, 50.30253341733998],
              [-4.007243550167477, 50.302033210288386],
              [-4.007121032411428, 50.302225106998016],
              [-4.008294860388228, 50.302454868186075],
              [-4.008187138159122, 50.30263121994863],
              [-4.008636266366484, 50.302525419025052],
              [-4.009345998110756, 50.302905301490533],
              [-4.010022382815128, 50.302727208342787],
              [-4.011304856921607, 50.303134946557215],
              [-4.012021554731785, 50.30311354485503],
              [-4.013071153106437, 50.302562002169168],
              [-4.013975277770839, 50.30265606648652],
              [-4.014393462802962, 50.302217089540569],
              [-4.013981203235628, 50.302098314771989],
              [-4.015493280781453, 50.301858032381965],
              [-4.016528277398958, 50.301228460467932],
              [-4.017330907098501, 50.299853684504825],
              [-4.018138125429978, 50.299915220061216],
              [-4.018111743621406, 50.299457866003969],
              [-4.017676875381633, 50.29946811620183],
              [-4.018448066128628, 50.299043680943583],
              [-4.017792347672737, 50.298845501118208],
              [-4.018117862207501, 50.298441398432466],
              [-4.017732806327078, 50.298533531742834],
              [-4.017873410521932, 50.29813983595843],
              [-4.018868848666473, 50.297706106597829],
              [-4.018983398551428, 50.297128477839408],
              [-4.018431690403918, 50.296965371245605],
              [-4.018820584086134, 50.296765236624758],
              [-4.01821003637516, 50.296671508340722],
              [-4.019114357729907, 50.295280561121551],
              [-4.020380658492527, 50.294613654504168],
              [-4.021771150891329, 50.294326833178488],
              [-4.021584171443831, 50.294884138862926],
              [-4.023337678968474, 50.294483050081681],
              [-4.023622896266011, 50.294884628169235],
              [-4.023097314637339, 50.29546941931779],
              [-4.024358406254982, 50.294945570093816],
              [-4.02377081015277, 50.295888520745372],
              [-4.024284100561077, 50.295874183038329],
              [-4.024503261988793, 50.296505366417847],
              [-4.025019917908566, 50.296570117208411],
              [-4.026230781464441, 50.295954481223717],
              [-4.029462959220146, 50.295161434657736],
              [-4.030775777691765, 50.294469316605159],
              [-4.031435693326627, 50.294568411805592],
              [-4.031434661450711, 50.294181672038398],
              [-4.032522806331425, 50.294214810068482],
              [-4.032352889680913, 50.293687114436921],
              [-4.033069089745492, 50.293492898801574],
              [-4.033027210542976, 50.293794942970649],
              [-4.035131542416685, 50.292833483041008],
              [-4.034464758990386, 50.293724809541942],
              [-4.03612254245475, 50.292926752097436],
              [-4.036644223425442, 50.293076809589515],
              [-4.035477994073274, 50.293812298233348],
              [-4.038136844890777, 50.29294900064059],
              [-4.038668316291128, 50.292966660001724],
              [-4.0384161602701, 50.293374032018896],
              [-4.039162753435551, 50.29307041346943],
              [-4.038413862630844, 50.293944315748263],
              [-4.040155016748581, 50.293291346181292],
              [-4.039921169593796, 50.293798237617764],
              [-4.04106110048677, 50.293204377700413],
              [-4.039699790237815, 50.294235652957468],
              [-4.040117505425574, 50.294613278804569],
              [-4.043226240544128, 50.293666411850353],
              [-4.042795021389252, 50.294121014621403],
              [-4.044321749146052, 50.293609366006095],
              [-4.044029694110913, 50.294329557412055],
              [-4.04509079514209, 50.29379730686582],
              [-4.04547274773167, 50.294060415037727],
              [-4.045872224431873, 50.293848310000165],
              [-4.046293749795376, 50.294346371546276],
              [-4.045915198256358, 50.294817147762672],
              [-4.046280293613239, 50.294719875401967],
              [-4.047048064923603, 50.295302679254107],
              [-4.047707065620477, 50.294987057285965],
              [-4.047608290720357, 50.295498779491197],
              [-4.048986971353871, 50.294940209341981],
              [-4.050013497677861, 50.294943689317925],
              [-4.049921658523045, 50.294473104336298],
              [-4.050667544513241, 50.294350211237287],
              [-4.050501759772863, 50.294674236632822],
              [-4.050926056764007, 50.294647859940618],
              [-4.051333666857144, 50.295212707539982],
              [-4.050838748761852, 50.296012949928702],
              [-4.052327026101234, 50.296337453277843],
              [-4.051838198920881, 50.296528672356573],
              [-4.052643071539153, 50.296927298960313],
              [-4.052218117616952, 50.297362937937685],
              [-4.05264899523846, 50.297554102983192],
              [-4.052547735515343, 50.29794175084298],
              [-4.053417594076434, 50.297598986284839],
              [-4.053592109888662, 50.297771292619196],
              [-4.053861014522434, 50.297429250318679],
              [-4.053989297823287, 50.297636551926431],
              [-4.055767516142313, 50.297421619208237],
              [-4.056489785657766, 50.296913247454235],
              [-4.056827167220752, 50.29715012430588],
              [-4.056247871877573, 50.297424812299937],
              [-4.057466040090642, 50.29783318045434],
              [-4.05711428142394, 50.298076860359004],
              [-4.058207557381039, 50.298291354135941],
              [-4.058830945683311, 50.299232816104556],
              [-4.058461441659319, 50.299520886942425],
              [-4.059081155746977, 50.29953149340048],
              [-4.059577315840275, 50.300257541652243],
              [-4.060235735493093, 50.300578661805943],
              [-4.061316235442655, 50.300366119632891],
              [-4.062949981895976, 50.300704996440736],
              [-4.063041454594119, 50.301196265052639],
              [-4.064601337417492, 50.301579603282867],
              [-4.064291991926094, 50.301894506366885],
              [-4.065044306179632, 50.301788496650659],
              [-4.065439477789852, 50.302352616998725],
              [-4.066463700649864, 50.302590747045237],
              [-4.066409593491095, 50.302865138791951],
              [-4.066752841547643, 50.302587403523347],
              [-4.066917054768428, 50.302844420606519],
              [-4.067359888963695, 50.302175454818645],
              [-4.067763583211125, 50.302158377906423],
              [-4.067947722340901, 50.302745133248976],
              [-4.07066901083376, 50.302933224246786],
              [-4.070038436582687, 50.303288045297997],
              [-4.071392878985706, 50.303368243600744],
              [-4.070890351562259, 50.303728881552544],
              [-4.071485905898506, 50.303634618261846],
              [-4.07168199631163, 50.303913546440697],
              [-4.072239346445181, 50.30339272655123],
              [-4.073512610855175, 50.303543604697545],
              [-4.073703859939174, 50.303097667370324],
              [-4.075220884452643, 50.303428561558391],
              [-4.075109713818862, 50.303649110847232],
              [-4.074442841370336, 50.303524303197101],
              [-4.074630011225373, 50.303985072614857],
              [-4.07538602820789, 50.304060616767615],
              [-4.0748825905843, 50.304432081617648],
              [-4.074892331734813, 50.305139766826542],
              [-4.073523796344859, 50.305317999702041],
              [-4.074578706913046, 50.305968353854922],
              [-4.073740223247865, 50.306515788757714],
              [-4.074278455107972, 50.306620411012538],
              [-4.074119343748479, 50.306965937591784],
              [-4.073357029366046, 50.30706948578144],
              [-4.073210585098655, 50.307770962985735],
              [-4.072413686800803, 50.308306852687871],
              [-4.072521101956077, 50.308806823954754],
              [-4.071611515104236, 50.309335723312685],
              [-4.069075730240405, 50.309126360917404],
              [-4.066318687339786, 50.309417367831749],
              [-4.065018815681833, 50.309271380233774],
              [-4.064320195027714, 50.309809064898396],
              [-4.064375733482436, 50.31073449940336],
              [-4.063688241378293, 50.311173044135195],
              [-4.062475637847141, 50.311616408609133],
              [-4.061110505906219, 50.311683802549425],
              [-4.057166812045096, 50.309479006927162],
              [-4.053316211003995, 50.309589383709891],
              [-4.052193617244328, 50.310232523981796],
              [-4.05081270969071, 50.312380472128886],
              [-4.048770155054423, 50.312929179543914],
              [-4.044570399110389, 50.311783511633429],
              [-4.040788366197305, 50.31199120333698],
              [-4.040402612357145, 50.310362803623654],
              [-4.039279243947353, 50.312600530145545],
              [-4.034798902940076, 50.312804988118444],
              [-4.033285925867851, 50.313425097135777],
              [-4.0329649363717, 50.314033333969327],
              [-4.031920387066843, 50.31427556173999],
              [-4.030401526704284, 50.315847336414542],
              [-4.0354445388699, 50.313846019732416],
              [-4.04032183050793, 50.313429497457314],
              [-4.041367316795567, 50.312849889094437],
              [-4.044616741836875, 50.312470764567244],
              [-4.044835410618675, 50.31282039519251],
              [-4.04784033891097, 50.313746966077282],
              [-4.04960596077653, 50.313877737057943],
              [-4.050768548731035, 50.313606283591945],
              [-4.052697081172469, 50.314192845046449],
              [-4.053181602518516, 50.31507112961723],
              [-4.053169171972954, 50.317748970203709],
              [-4.049049911180219, 50.320349087419672],
              [-4.047891491198486, 50.321865266761272],
              [-4.045970217417558, 50.323448832344866],
              [-4.043054939061347, 50.323938143312681],
              [-4.04318523925455, 50.324224570883224],
              [-4.045619977234705, 50.32428967270527],
              [-4.046313333427023, 50.324829718904446],
              [-4.045567267713441, 50.326726267678353],
              [-4.042659254062748, 50.32896213900051],
              [-4.042355340607375, 50.330393980969198],
              [-4.041600491715146, 50.33035417691368],
              [-4.039940311531482, 50.331288157828645],
              [-4.03982142156491, 50.331727368759722],
              [-4.037058581893419, 50.333034132073401],
              [-4.033632210107219, 50.334042136686442],
              [-4.03119187476948, 50.335240579807405],
              [-4.02962436945774, 50.335489223419465],
              [-4.029009977176673, 50.336603552410985],
              [-4.027536821137298, 50.336792057388202],
              [-4.025535096282481, 50.338364085815854],
              [-4.023373678866404, 50.339257090999041],
              [-4.021816685758231, 50.339757285459555],
              [-4.021074088425795, 50.339777396030279],
              [-4.020732880074978, 50.339486516945328],
              [-4.019122242540929, 50.340114427246036],
              [-4.017294538565717, 50.34029726745036],
              [-4.015703503536203, 50.341057905123066],
              [-4.01579493373131, 50.341325248686154],
              [-4.017444617221109, 50.340555497007045],
              [-4.018504075036528, 50.340682810363006],
              [-4.017838127638284, 50.341152182942359],
              [-4.018372560426811, 50.341160892207824],
              [-4.018949430817383, 50.340546456905038],
              [-4.018687845445745, 50.341470216955585],
              [-4.019335511163289, 50.3414976420069],
              [-4.019015266657691, 50.341701079716167],
              [-4.017475814695245, 50.341556919370156],
              [-4.018598598499908, 50.341851325991826],
              [-4.020725677183008, 50.341799974480487],
              [-4.022634373689242, 50.341272988568861],
              [-4.022682351271145, 50.34097983907283],
              [-4.023020038991292, 50.341220405713678],
              [-4.027176109220433, 50.339806044365844],
              [-4.030623358589764, 50.339109971722941],
              [-4.033257361278348, 50.3389532933558],
              [-4.036532443773477, 50.339360959998828],
              [-4.033596857077161, 50.339399767576637],
              [-4.031567611351052, 50.341773773789839],
              [-4.032720325849779, 50.34256940341038],
              [-4.033688226412228, 50.341439876629543],
              [-4.035597820495413, 50.340838907812532],
              [-4.037009655438944, 50.339839189683367],
              [-4.037608101731046, 50.339176612214082],
              [-4.036842164900379, 50.339373521342736],
              [-4.036715128560415, 50.339097823276738],
              [-4.03909173223406, 50.338116246862008],
              [-4.039686931972865, 50.336263766890561],
              [-4.040845393259576, 50.335718162593544],
              [-4.041511145464287, 50.336234437172742],
              [-4.043218182089276, 50.335963392641027],
              [-4.042041740979468, 50.33585994657669],
              [-4.042146532301603, 50.334666357594891],
              [-4.043127013503876, 50.333511343556715],
              [-4.043006157602753, 50.33292164135905],
              [-4.044267664462843, 50.332455139550071],
              [-4.044343090976724, 50.331626334511036],
              [-4.045459831656513, 50.330767514567711],
              [-4.045533610728807, 50.331503751286306],
              [-4.046595230965805, 50.332763156245129],
              [-4.051026778629327, 50.334906636189928],
              [-4.050653437858137, 50.334982480331739],
              [-4.051220387002846, 50.336171420208217],
              [-4.04924157478411, 50.337702083403364],
              [-4.049812468036756, 50.338199296405541],
              [-4.049674687394855, 50.339111952062865],
              [-4.050911251747323, 50.341783039607243],
              [-4.052070803871342, 50.342957918777309],
              [-4.051515717407353, 50.344459875462867],
              [-4.051809308191477, 50.345564592211545],
              [-4.051535724859351, 50.346782754818477],
              [-4.051890133180891, 50.347178544916112],
              [-4.052316535211014, 50.343637148610107],
              [-4.052940491075618, 50.343504706274807],
              [-4.053422841739957, 50.342891767159088],
              [-4.052166567340604, 50.341397511683169],
              [-4.051416276239777, 50.338662989903447],
              [-4.052445247816992, 50.337788563801574],
              [-4.053694570793517, 50.337494866495156],
              [-4.058253953810634, 50.33809148724837],
              [-4.05624582983713, 50.337638639328425],
              [-4.056151210474523, 50.337400164540746],
              [-4.052564793194669, 50.336747607458776],
              [-4.052554881594777, 50.335279007967728],
              [-4.051799466301617, 50.333630193381723],
              [-4.048375031463205, 50.331867896013669],
              [-4.047488396383743, 50.331018714065038],
              [-4.047678575425108, 50.330311107082395],
              [-4.048635574524493, 50.330444447361238],
              [-4.048933859004781, 50.330225123891935],
              [-4.047093716720423, 50.327917224005944],
              [-4.047335114876102, 50.327355322754066],
              [-4.050387101379539, 50.325566509352889],
              [-4.050376075117619, 50.324624995934641],
              [-4.049302378626433, 50.323148174185881],
              [-4.049467797410639, 50.322715327331942],
              [-4.053117991277662, 50.319592818149715],
              [-4.055331961301809, 50.318518432329171],
              [-4.055665293226638, 50.317713837315154],
              [-4.055181722820599, 50.314579759995681],
              [-4.053453775549355, 50.312488535866976],
              [-4.054956829396207, 50.311384440924762],
              [-4.056450984944528, 50.311410176425781],
              [-4.059539250681788, 50.312994258227413],
              [-4.060913066346227, 50.313321581933977],
              [-4.063904512051824, 50.313247803678863],
              [-4.065922805936335, 50.312629104420822],
              [-4.067079963953119, 50.312688576664037],
              [-4.069325253166599, 50.312220483226277],
              [-4.069678755870998, 50.312501108544026],
              [-4.069446855409328, 50.313079979542721],
              [-4.070310523995377, 50.314110642438941],
              [-4.071511541921192, 50.314144105077752],
              [-4.071672630017162, 50.314844594074351],
              [-4.074367524604539, 50.31502317867799],
              [-4.075368658359926, 50.315852574685202],
              [-4.076008753833311, 50.315713421211491],
              [-4.076921342664432, 50.316154029456783],
              [-4.078864404016611, 50.315969079938235],
              [-4.082002299801972, 50.316511030470743],
              [-4.085077279983198, 50.317828442599733],
              [-4.086599870185583, 50.31756815899508],
              [-4.089217624395271, 50.316367145305087],
              [-4.090138734960537, 50.316422536953368],
              [-4.090385244326268, 50.317014431566619],
              [-4.091166867007606, 50.316771911251813],
              [-4.092459215577824, 50.317059854612232],
              [-4.100889440355561, 50.317228854234557],
              [-4.102515656171867, 50.316740728203094],
              [-4.104934439265592, 50.315020381851852],
              [-4.10592658965549, 50.315380171621207],
              [-4.107398225725081, 50.315083660471295],
              [-4.107967780918504, 50.315511358452881],
              [-4.109891390758366, 50.315683320384203],
              [-4.109954713538865, 50.316034751936918],
              [-4.110660742524575, 50.315800667805426],
              [-4.111649493058561, 50.315923917436258],
              [-4.112253330811676, 50.316395044322704],
              [-4.112116908565334, 50.316929993684752],
              [-4.112831219090271, 50.316629187160352],
              [-4.113287310660077, 50.316904218149141],
              [-4.11265410694533, 50.317133396485993],
              [-4.113140938675103, 50.317943037260122],
              [-4.112264844220297, 50.318904278335495],
              [-4.112902223987283, 50.319338814536394],
              [-4.113437133481413, 50.319363266488693],
              [-4.113721935362223, 50.31897492433437],
              [-4.114178596464719, 50.319167192856376],
              [-4.113863235094774, 50.31940498576629],
              [-4.114317648351458, 50.319293283242779],
              [-4.113955128116147, 50.319513046112043],
              [-4.114837882833249, 50.319303604667894],
              [-4.115126894704016, 50.319579870115952],
              [-4.116092450044438, 50.319621655295691],
              [-4.116619460285154, 50.319246884093225],
              [-4.117687321987199, 50.319725720427876],
              [-4.11856365565029, 50.31971964160622],
              [-4.118685314700264, 50.319580708384848],
              [-4.118968888126681, 50.319355174638218],
              [-4.118726150521834, 50.319573493287891],
              [-4.118997538455544, 50.319525546301882],
              [-4.119301649983198, 50.320424839788913],
              [-4.11971701590875, 50.32047752676052],
              [-4.11931454029947, 50.321188231279585],
              [-4.119823770044817, 50.32117174791231],
              [-4.120638865023472, 50.321746011551824],
              [-4.118989653129075, 50.323359112937204],
              [-4.120225366848732, 50.323818675664413],
              [-4.121103430973642, 50.323692021288295],
              [-4.12098888304981, 50.32408536927386],
              [-4.121700477283819, 50.324227083948756],
              [-4.121208294346425, 50.324753247860677],
              [-4.121848672049812, 50.324809915100722],
              [-4.122166203180999, 50.32553536354618],
              [-4.121183795590295, 50.326379886027873],
              [-4.121682418256369, 50.326754845641453],
              [-4.121407447926847, 50.327331910263844],
              [-4.121869840816147, 50.3274610833856],
              [-4.121919998028776, 50.328271464012914],
              [-4.120772610514286, 50.328883340801475],
              [-4.119379125020036, 50.328986113269224],
              [-4.118927233083117, 50.329724699499636],
              [-4.119697763699514, 50.331093988752286],
              [-4.119030086964214, 50.332067666811106],
              [-4.119729249171057, 50.332464162472654],
              [-4.119276643834625, 50.33293472920095],
              [-4.11977380157742, 50.333275544909412],
              [-4.121033100997, 50.333186010760805],
              [-4.120726089637924, 50.333612551653715],
              [-4.121381846991603, 50.333475561638608],
              [-4.120776978563178, 50.333808600583204],
              [-4.121391894574018, 50.333732618326842],
              [-4.120880247864567, 50.334139509178165],
              [-4.120419513125405, 50.334048977590776],
              [-4.121136429547046, 50.334653811219852],
              [-4.11948505612536, 50.335122865367303],
              [-4.119505080631338, 50.335730521911401],
              [-4.119951552977908, 50.336131624275247],
              [-4.122555361438461, 50.3364105974022],
              [-4.122597351795358, 50.336658975578715],
              [-4.124238790197221, 50.336470684539847],
              [-4.125000491961747, 50.335998944753904],
              [-4.126635283028736, 50.336008618234295],
              [-4.12870658138699, 50.337389117947438],
              [-4.128442044152875, 50.337570253282188],
              [-4.1278461621533, 50.337161125394317],
              [-4.12709032623335, 50.337575212237866],
              [-4.127165574184124, 50.338158471639986],
              [-4.127504109565575, 50.338154973487192],
              [-4.126941116695447, 50.338607802721796],
              [-4.127572866254178, 50.338781524592804],
              [-4.127022266452879, 50.339291691480987],
              [-4.128360248374806, 50.339544225216528],
              [-4.127788170903956, 50.339825431600346],
              [-4.128670004374682, 50.340342652490953],
              [-4.127916441773276, 50.340557026812064],
              [-4.128507137051223, 50.3407548808106],
              [-4.127811412259335, 50.34147727850948],
              [-4.128620369521903, 50.34140130376877],
              [-4.127717872947956, 50.341994370320691],
              [-4.12849230923343, 50.34215198357478],
              [-4.128029364949887, 50.342202734367248],
              [-4.128316431825419, 50.342524874348989],
              [-4.127554207998288, 50.342766387854212],
              [-4.128565866551482, 50.342853998904523],
              [-4.126122200849339, 50.344029322135015],
              [-4.126006367423776, 50.344456876061756],
              [-4.126584700227639, 50.344502960727887],
              [-4.124237853443798, 50.345299606608343],
              [-4.122984846146886, 50.346703139532849],
              [-4.119320376518409, 50.345155521265845],
              [-4.11631956623115, 50.345599655959795],
              [-4.114646505462204, 50.344387982303857],
              [-4.107221257741022, 50.345599465092192],
              [-4.104633954235242, 50.345703007799671],
              [-4.101923659449371, 50.345122446488375],
              [-4.099930452981219, 50.344291417425978],
              [-4.094226587880842, 50.343264637038118],
              [-4.093248493126924, 50.34339558530484],
              [-4.090731319551056, 50.341733761133462],
              [-4.090559140081905, 50.340917471511617],
              [-4.089559111219762, 50.341348295834337],
              [-4.088615764310562, 50.342975242430931],
              [-4.085755207575053, 50.343383686816416],
              [-4.082886478103127, 50.344987556452985],
              [-4.081362459359718, 50.346617650303827],
              [-4.079389538303584, 50.35016885152104],
              [-4.072313423230344, 50.352723693760282],
              [-4.072327066745493, 50.353198351163947],
              [-4.071658502158625, 50.353502584323309],
              [-4.065335652971743, 50.355391479757643],
              [-4.065423760102989, 50.355898992731774],
              [-4.064279936427674, 50.356300670277413],
              [-4.064109456096898, 50.356065348246013],
              [-4.061896774785628, 50.356685651000227],
              [-4.060911885277488, 50.355889131495992],
              [-4.059659872343039, 50.356747793658741],
              [-4.058146200169351, 50.356141404838425],
              [-4.05755174573057, 50.357219553237684],
              [-4.057772155329536, 50.358349833949902],
              [-4.053800079449456, 50.357463986769979],
              [-4.052916450355573, 50.356535193664165],
              [-4.05204138522588, 50.357564302745111],
              [-4.050167745223351, 50.358064167497673],
              [-4.051564742982834, 50.360497662498823],
              [-4.051017007877372, 50.360903077117172],
              [-4.049675319568185, 50.361156096582604],
              [-4.050920112393603, 50.362275517444857],
              [-4.049733604178266, 50.363096037580569],
              [-4.055059870841067, 50.368270857375521],
              [-4.054292335702168, 50.369807156466493],
              [-4.05055836608881, 50.37469221567779],
              [-4.050165048814493, 50.376595145942197],
              [-4.050172498805116, 50.377485447737307],
              [-4.047526350423132, 50.377669693987926],
              [-4.040530413932155, 50.377146906927329],
              [-4.034972252336019, 50.377220044895957],
              [-4.030853582189712, 50.377835317572121],
              [-4.02703450930513, 50.379046935604848],
              [-4.024523665342316, 50.380992069055182],
              [-4.025290926562601, 50.381799880163115],
              [-4.025707743349212, 50.383328834909527],
              [-4.019607555969348, 50.387480566165159],
              [-4.020195631040863, 50.387514421275732],
              [-4.020904299106728, 50.388763096554328],
              [-4.021492431906903, 50.390683033666711],
              [-4.021142847154926, 50.391791803103878],
              [-4.028875522974331, 50.392224663513502],
              [-4.028016105503348, 50.393284786397025],
              [-4.027788445177496, 50.394016390145843],
              [-4.031523682173582, 50.394304660557559],
              [-4.030384976325927, 50.397092974189924],
              [-4.034667338383826, 50.397501106556533],
              [-4.037564075995763, 50.397358639607113],
              [-4.041113176505976, 50.397793772896122],
              [-4.041620127320789, 50.397639165968357],
              [-4.039450848962631, 50.400764056225213],
              [-4.03962317928701, 50.401434701366028],
              [-4.044226060467161, 50.401104730439293],
              [-4.046577759666171, 50.401992466813518],
              [-4.048671605554819, 50.402120189054294],
              [-4.048901551037233, 50.401643939882376],
              [-4.052484312774197, 50.402522450423774],
              [-4.05258167389954, 50.401486393844401],
              [-4.055312194034419, 50.401140451454353],
              [-4.05544053840918, 50.401992636190137],
              [-4.05672047319917, 50.40259510374532],
              [-4.059788333732803, 50.401168276023171],
              [-4.063516492791652, 50.401081486404827],
              [-4.071483088946413, 50.401864442444662],
              [-4.072156294913785, 50.403844676364578],
              [-4.072511662419571, 50.407150928652889],
              [-4.074577085881828, 50.408199704399273],
              [-4.077757074578355, 50.408599834928559],
              [-4.078934864328259, 50.409655396835355],
              [-4.077985630789352, 50.410027636980125],
              [-4.077576896143005, 50.410497245305933],
              [-4.077662759975844, 50.411301596660479],
              [-4.076942976108729, 50.411707503979066],
              [-4.077735443856672, 50.412994815806904],
              [-4.0772600237428, 50.413581640013966],
              [-4.080522416680526, 50.415371635993544],
              [-4.080993472567213, 50.416516270611289],
              [-4.079083634830726, 50.418903327773378],
              [-4.080539533111575, 50.419841472806688],
              [-4.081212381920384, 50.420771130775705],
              [-4.082368266991491, 50.422281259611708],
              [-4.082557169063889, 50.424565113633939],
              [-4.084703907904763, 50.425839810404142],
              [-4.085569638882567, 50.426028457394793],
              [-4.087205916502302, 50.425841702345089],
              [-4.08855908106378, 50.426676359129573],
              [-4.090531041575519, 50.427141907367016],
              [-4.093152381620772, 50.427070471933341],
              [-4.094305984804882, 50.427910452944616],
              [-4.097904149795987, 50.428658680562513],
              [-4.098853624779749, 50.429751439939658],
              [-4.101216088758246, 50.430413029998846],
              [-4.104122792909331, 50.430136519361298],
              [-4.106175166498883, 50.430344911694704],
              [-4.107273576641655, 50.431266713266361],
              [-4.109513249312015, 50.43210754749019],
              [-4.109939983827163, 50.432520737783889],
              [-4.109033758246192, 50.432344698164407],
              [-4.108265694205115, 50.432615860072367],
              [-4.108793883775963, 50.433189111989968],
              [-4.108235842082531, 50.433370120274681],
              [-4.108737062301719, 50.433811645695108],
              [-4.106680730287146, 50.434881453451901],
              [-4.107684409999761, 50.435570216984992],
              [-4.106671275247855, 50.436983582124356],
              [-4.106487015353625, 50.438574405155187],
              [-4.108719851505508, 50.438335169643672],
              [-4.109084110260604, 50.43848236637151],
              [-4.110189340850417, 50.442211123132402],
              [-4.112714678615647, 50.44341278035585],
              [-4.113460950944898, 50.444166431643005],
              [-4.116107844788105, 50.44300571469573],
              [-4.116894492762374, 50.443400638269544],
              [-4.118452163932211, 50.442357724416389],
              [-4.122648586280459, 50.440830438169165],
              [-4.125090981873634, 50.438414947313561],
              [-4.124367949470146, 50.436106734614988],
              [-4.124850688056683, 50.435722854580241],
              [-4.131936900385341, 50.433683684405871],
              [-4.135635185019913, 50.432941258403396],
              [-4.138045311487641, 50.432926656240916],
              [-4.13932456873382, 50.433159468435072],
              [-4.139341572287968, 50.433380415291658],
              [-4.14159454006256, 50.433284084150714],
              [-4.142508949958837, 50.432733880055544],
              [-4.142820396086069, 50.432863955882326],
              [-4.144586060952062, 50.432330426932971],
              [-4.145909115373879, 50.431196124417717],
              [-4.146546144239942, 50.431115111987175],
              [-4.148169816013051, 50.432237302169888],
              [-4.148619732717891, 50.432968322601738],
              [-4.149198360802464, 50.432872184971806],
              [-4.149359547588996, 50.433197498253925],
              [-4.150205472677248, 50.433313178029898],
              [-4.150715760051606, 50.433014125604622],
              [-4.151093154478967, 50.436648040522371],
              [-4.155826786228773, 50.436439866876825],
              [-4.156261394926171, 50.433763197828938],
              [-4.159925845483455, 50.431357574573049],
              [-4.16527439706034, 50.430010601148027],
              [-4.164690478507766, 50.4286003678261],
              [-4.164129513223981, 50.428526261639817],
              [-4.16348580882137, 50.427594733701333],
              [-4.165989915752898, 50.425449742818223],
              [-4.168513043487973, 50.424711955351412],
              [-4.168254124891356, 50.424591761941976],
              [-4.169473372042432, 50.423975395934804],
              [-4.171880399596167, 50.42442605418082],
              [-4.174498266511213, 50.424345651911636],
              [-4.175380952093433, 50.423852442819083],
              [-4.174333509387779, 50.424191331550468],
              [-4.173587572213311, 50.423551387533372],
              [-4.174409039119794, 50.423903899106421],
              [-4.174731165692352, 50.423744072112477],
              [-4.174194117937954, 50.423299899780879],
              [-4.174758382609827, 50.423201204008826],
              [-4.174858165193354, 50.423627468808817],
              [-4.174966640695088, 50.423260269533266],
              [-4.175210221718079, 50.423567817389063],
              [-4.176056783978683, 50.423423359960474],
              [-4.175133551478699, 50.423000808738152],
              [-4.176575704224948, 50.422612251936592],
              [-4.176710579040051, 50.423312186100375],
              [-4.177506554549692, 50.42329278696841],
              [-4.177111422617748, 50.423917197524126],
              [-4.177755319654485, 50.423897046058826],
              [-4.17831247053493, 50.424655627795673],
              [-4.181081650218251, 50.425447393224808],
              [-4.183590980970199, 50.425485748609859],
              [-4.18420402499962, 50.425773747785286],
              [-4.185427512661426, 50.424733499617979],
              [-4.186686146623856, 50.424610001856323],
              [-4.185116425388179, 50.425958080709606],
              [-4.18522329881916, 50.42623040031642],
              [-4.188236900394156, 50.427280038343646],
              [-4.189830586335375, 50.428466076380282],
              [-4.189526328054312, 50.428768619071889],
              [-4.186096690260139, 50.429479834892703],
              [-4.184389345391941, 50.429463347858622],
              [-4.18281311010339, 50.430858286249432],
              [-4.180222568013503, 50.432000593240794],
              [-4.178562647323171, 50.433172182618925],
              [-4.175544739344128, 50.433813309266412],
              [-4.17551201900161, 50.434174594134923],
              [-4.176459677897464, 50.434633562597817],
              [-4.173789027595782, 50.435418348834475],
              [-4.172936713000146, 50.436179906578992],
              [-4.171862405660966, 50.437937677737864],
              [-4.170389058892758, 50.439102064860123],
              [-4.169781448502762, 50.440874490070733],
              [-4.166609206332748, 50.442405098844048],
              [-4.165571048082983, 50.442464008858671],
              [-4.164973736350407, 50.443818879066242],
              [-4.166271846976981, 50.446181797531224],
              [-4.165458107366963, 50.446067432943899],
              [-4.163262532792266, 50.446847622162799],
              [-4.162660639490179, 50.449370923609237],
              [-4.16193436149545, 50.44926030442285],
              [-4.161741314441213, 50.449475258567269],
              [-4.160829000946253, 50.448280679440799],
              [-4.159053964625119, 50.447472676394391],
              [-4.157913330934803, 50.447356231857015],
              [-4.157609706278937, 50.447060555809159],
              [-4.157910679266631, 50.446988414332949],
              [-4.15718612953729, 50.446543145837978],
              [-4.156460442977818, 50.446506234989897],
              [-4.158458658565991, 50.447731968224709],
              [-4.157858465828869, 50.447760194228891],
              [-4.159782317217558, 50.448712059174333],
              [-4.158467162013909, 50.447547427341718],
              [-4.160287449673045, 50.448328515359336],
              [-4.160769090108926, 50.448975252816027],
              [-4.16052300560821, 50.449201083951102],
              [-4.161639790015009, 50.449782052137955],
              [-4.162072857266858, 50.450444077848708],
              [-4.160133731903181, 50.451138489965601],
              [-4.158746167286672, 50.452469323179095],
              [-4.156822649287012, 50.453509669455421],
              [-4.155462489439897, 50.45492000300041],
              [-4.154565020661003, 50.456561897435677],
              [-4.154532675724679, 50.459849005695673],
              [-4.157006780639416, 50.461179276695802],
              [-4.156905672196855, 50.461898894817473],
              [-4.155253238207786, 50.463022328092435],
              [-4.151885600336855, 50.463922942348404],
              [-4.151444646290378, 50.46342831937438],
              [-4.147450033558964, 50.463275478382194],
              [-4.145878164412593, 50.46241855174906],
              [-4.144286249131799, 50.462082741372512],
              [-4.14250080967852, 50.46223436514056],
              [-4.138594756115637, 50.46326053498575],
              [-4.137076836905436, 50.464752696247487],
              [-4.133986114887921, 50.464240101772859],
              [-4.127769799336435, 50.465101397420007],
              [-4.1265632140423, 50.464778982443995],
              [-4.123638234925843, 50.46478654786889],
              [-4.123603103196204, 50.464505669684634],
              [-4.122815366425971, 50.464284399002679],
              [-4.120236622311382, 50.463746805474813],
              [-4.119727830175121, 50.46390178474104],
              [-4.116233942772929, 50.463064178745526],
              [-4.108539668352672, 50.462982646484093],
              [-4.109519559637743, 50.45938697788646],
              [-4.109233371321873, 50.457229955357121],
              [-4.110413914364398, 50.453967920214282],
              [-4.108178905494289, 50.454491440632879],
              [-4.107240523817412, 50.450399878767755],
              [-4.104247984708955, 50.455372100720453],
              [-4.098832778817155, 50.460693780012122],
              [-4.079389470305578, 50.468192455285845],
              [-4.077983676537169, 50.468816587980307],
              [-4.077765696613831, 50.469919570180863],
              [-4.077135054380231, 50.467845981766004],
              [-4.077228851977336, 50.467478243754151],
              [-4.077886323610665, 50.467271327899887],
              [-4.076166622721049, 50.465651600903158],
              [-4.07645510523065, 50.465224624617527],
              [-4.075988220021855, 50.46395938138],
              [-4.072717820091474, 50.46251117493486],
              [-4.072743498058005, 50.461519558403531],
              [-4.07134872811361, 50.4609103132969],
              [-4.070093521043636, 50.459495387444548],
              [-4.069341425719746, 50.459308199215073],
              [-4.06796477885452, 50.459628589925828],
              [-4.06514737533092, 50.459639968417349],
              [-4.065124542660549, 50.458242678335239],
              [-4.066311680961681, 50.45713060191779],
              [-4.066670499280276, 50.456053919411744],
              [-4.067822791299254, 50.455372367953693],
              [-4.067988066540022, 50.454510484778694],
              [-4.066804963186317, 50.454484743089814],
              [-4.065376277065078, 50.454868988021431],
              [-4.063989971318961, 50.454317967567036],
              [-4.060151761301437, 50.454043332575367],
              [-4.058389107100143, 50.455951623074505],
              [-4.057141390431314, 50.456742697857919],
              [-4.053079903865704, 50.457334317912078],
              [-4.050638766125403, 50.458777770205863],
              [-4.047460888360442, 50.458858033725214],
              [-4.04610054726581, 50.459559230121165],
              [-4.044173392727498, 50.459859789204728],
              [-4.042596332329658, 50.460470763399542],
              [-4.042894274444412, 50.461466580482501],
              [-4.042466484515673, 50.461871634044641],
              [-4.038639240157487, 50.462649308498307],
              [-4.037458255219548, 50.463496561439143],
              [-4.036212321796765, 50.463816982825108],
              [-4.035454367306114, 50.463787075911213],
              [-4.033892872634657, 50.46279625139961],
              [-4.032579148696907, 50.463310293682909],
              [-4.031586933089299, 50.463337520050516],
              [-4.031013883513524, 50.463808026478027],
              [-4.030038321430091, 50.463829552320334],
              [-4.029377418548048, 50.463499306831743],
              [-4.02968922947795, 50.462455943406418],
              [-4.02892872202574, 50.462365777923218],
              [-4.028891123595034, 50.462935762491938],
              [-4.027388993920469, 50.461747743845407],
              [-4.025193502246134, 50.462500121591653],
              [-4.023901831847597, 50.462047711801503],
              [-4.023280354161638, 50.463068563758057],
              [-4.024051302008186, 50.464657006399563],
              [-4.02442123918421, 50.46741266529623],
              [-4.023029354399548, 50.468741926223409],
              [-4.017620145327301, 50.468329499223955],
              [-4.011048992085308, 50.469590030956667],
              [-4.009261971016106, 50.469454518693773],
              [-4.007876686139936, 50.469687099959259],
              [-4.006464890393557, 50.472156055356642],
              [-4.00702866375221, 50.472489904985743],
              [-4.006528757185145, 50.473031878434377],
              [-4.006714924780598, 50.474199698803112],
              [-4.004418145248035, 50.475877118284856],
              [-4.005262673613014, 50.476416600663697],
              [-4.001240375516847, 50.477356489182512],
              [-3.997619660299733, 50.47732787544517],
              [-3.996712582801144, 50.476974689668687],
              [-3.996421148620359, 50.477350247344006],
              [-3.994713336420818, 50.47742810951727],
              [-3.993902105565158, 50.477878228601675],
              [-3.993887973415326, 50.47834616286756],
              [-3.992333711765801, 50.479093215590495],
              [-3.990912728573697, 50.481201467782974],
              [-3.987823257789067, 50.481511525732707],
              [-3.98466734676281, 50.483331838455939],
              [-3.984876902645619, 50.48389219118387],
              [-3.98214507196851, 50.485578381515545],
              [-3.979337595121414, 50.486504896404497],
              [-3.978631191293881, 50.487007987009385],
              [-3.97614868213405, 50.487448614899421],
              [-3.975135378460498, 50.487963191984932],
              [-3.971670708512048, 50.488044441850349],
              [-3.971297813453358, 50.487824110578643],
              [-3.97042029161631, 50.488147470752992],
              [-3.967948630047052, 50.48983611130172],
              [-3.967530478081545, 50.491987360740801],
              [-3.965294389873505, 50.494195410846245],
              [-3.964746779921767, 50.496322751849384],
              [-3.96436272544281, 50.496411981261993],
              [-3.962447368297605, 50.498201732721661],
              [-3.960176722463762, 50.499247336344787],
              [-3.955945751517877, 50.500255710421037],
              [-3.954829173505347, 50.500227705428756],
              [-3.952340533447272, 50.499071486323096],
              [-3.949078307743662, 50.49837068019005],
              [-3.946542255601064, 50.498010201996642],
              [-3.945765132172605, 50.498280428313407],
              [-3.948682210869128, 50.492756059569828],
              [-3.948755088854309, 50.488668858183829],
              [-3.945982498877589, 50.489092112653559],
              [-3.938961352939476, 50.484436209517419],
              [-3.93701542955009, 50.483663658646513],
              [-3.935866029906683, 50.483546979394895],
              [-3.93256913549351, 50.481963980016509],
              [-3.932164146409533, 50.482229733919844],
              [-3.931862726877709, 50.481924445919645],
              [-3.928288073976801, 50.481302900269284],
              [-3.927469561216919, 50.480898242044852],
              [-3.926357871969369, 50.480978712621905],
              [-3.925638154652773, 50.479779134458845],
              [-3.923875183446832, 50.47878118513384],
              [-3.923718271870632, 50.479498803174984],
              [-3.922388970987387, 50.480051421487346],
              [-3.921447287722113, 50.481056317513925],
              [-3.91995532815009, 50.481666457095962],
              [-3.914759378647175, 50.481869906859515],
              [-3.913773858717451, 50.481690052843945],
              [-3.913697011242288, 50.481253309471121],
              [-3.911372632954529, 50.480275197632437],
              [-3.909217314999035, 50.478453327337569],
              [-3.906240332132453, 50.476838253991573],
              [-3.903821562841942, 50.477184554853679],
              [-3.898960365356796, 50.472788593948238],
              [-3.898677350318278, 50.473814930248828],
              [-3.897869764826494, 50.474553022615453],
              [-3.891676661464343, 50.478391782977326],
              [-3.891506818641702, 50.479468424843759],
              [-3.888035583387059, 50.480114811961904],
              [-3.883692981882406, 50.480012517838531],
              [-3.883710563416099, 50.480809098390203],
              [-3.882324591345435, 50.482714865194687],
              [-3.88189081915861, 50.484072774643643],
              [-3.880213999131284, 50.485687317327049],
              [-3.879834304316217, 50.487308766290774],
              [-3.881213990945588, 50.490775220196795],
              [-3.882718237418177, 50.492830290397279],
              [-3.883018755771148, 50.495281673609362],
              [-3.883653809965041, 50.495898272597117],
              [-3.883844613555831, 50.498035741617997],
              [-3.883164574364121, 50.499585612106792],
              [-3.884305633026009, 50.501576385278234],
              [-3.891317765856773, 50.505901132655794],
              [-3.89257973063075, 50.513864528116088],
              [-3.890630152296753, 50.519647830094982],
              [-3.891160683887089, 50.523531776058128],
              [-3.886388362846966, 50.524640828690686],
              [-3.885733187354102, 50.52512272697242],
              [-3.884549914033445, 50.527045902472345],
              [-3.884561594585126, 50.528684401717484],
              [-3.88399358203462, 50.529372637103741],
              [-3.884290476948822, 50.530662052300826],
              [-3.888098299939528, 50.533227458591249],
              [-3.889139514338339, 50.536330544677135],
              [-3.886463718327882, 50.536149997011563],
              [-3.88464450129751, 50.537207470015922],
              [-3.883475207692499, 50.539241926975336],
              [-3.882173667137757, 50.539977994727522],
              [-3.880421849345512, 50.540287818113335],
              [-3.879669754105209, 50.541569002169247],
              [-3.877422282328398, 50.542442604480435],
              [-3.875582971051409, 50.542542409524621],
              [-3.874998260895947, 50.54103816161291],
              [-3.875299626004109, 50.540132120560187],
              [-3.874858486106455, 50.537620942471555],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000045",
        LAD13CDO: "18UH",
        LAD13NM: "Teignbridge",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-3.658614730857949, 50.757415822558919],
            [-3.656697574450849, 50.757207456532939],
            [-3.655948967933187, 50.757448322431706],
            [-3.655973595037731, 50.757101728251932],
            [-3.653570230775919, 50.756412776215242],
            [-3.651797857318996, 50.756328183171078],
            [-3.647948750107879, 50.756897976112953],
            [-3.646421581259982, 50.756730693373733],
            [-3.643919225443288, 50.757379352850755],
            [-3.641486940770331, 50.758614237090214],
            [-3.64060401336353, 50.758260636339102],
            [-3.640888658920067, 50.757237684202721],
            [-3.639719883932689, 50.75527649199249],
            [-3.636296818276417, 50.755746376013725],
            [-3.634592087537931, 50.755078702802933],
            [-3.631856645494085, 50.754857117600423],
            [-3.62739162110886, 50.756229862037841],
            [-3.625022994237025, 50.756202647253005],
            [-3.623158709861936, 50.755549622131447],
            [-3.622187986633606, 50.755767282258503],
            [-3.62120920798617, 50.753085595713053],
            [-3.620052136988915, 50.752052158805824],
            [-3.6199305876773, 50.750995332226374],
            [-3.618373330820714, 50.751097901339797],
            [-3.617063847574346, 50.751973137624347],
            [-3.615652300616233, 50.752356039532593],
            [-3.614220643863868, 50.75405042996551],
            [-3.611222190776347, 50.753967801898966],
            [-3.610073796591341, 50.754338005018397],
            [-3.608852177806857, 50.754227165203922],
            [-3.608765717102657, 50.753318234072189],
            [-3.607596527156371, 50.752792065490354],
            [-3.606698275055562, 50.751276483109038],
            [-3.606217700090178, 50.748833323496207],
            [-3.603210415675125, 50.747444781523782],
            [-3.601950657187294, 50.746003382215676],
            [-3.600910892912837, 50.746354915904782],
            [-3.598857237225285, 50.746487423553816],
            [-3.598362997113574, 50.74704189470382],
            [-3.596647323299501, 50.746860358313718],
            [-3.596577548449839, 50.747809210075829],
            [-3.595941390392962, 50.747776551776248],
            [-3.595299007120512, 50.747644149346442],
            [-3.593549749217456, 50.746348754606778],
            [-3.593123615413253, 50.746489478872917],
            [-3.592177861167978, 50.745478060884075],
            [-3.591834848798036, 50.745018690002887],
            [-3.592244168851807, 50.744842226745902],
            [-3.592122414144538, 50.743300635573668],
            [-3.588066614912401, 50.743992686284493],
            [-3.585467690009702, 50.743884169820021],
            [-3.58306791739867, 50.741569528477456],
            [-3.582592307878196, 50.741587677839838],
            [-3.579451706095974, 50.741846113221285],
            [-3.573362690434603, 50.744124622717415],
            [-3.569653532901381, 50.745019996521229],
            [-3.569010111919174, 50.740995161512572],
            [-3.566972136308423, 50.740743782224584],
            [-3.564696041838552, 50.739981146871294],
            [-3.566004317317488, 50.739645215495372],
            [-3.567353893817021, 50.737363461164527],
            [-3.564418145155063, 50.735597941206628],
            [-3.563813414082785, 50.733652708431315],
            [-3.563016110340984, 50.733370220368762],
            [-3.562720695540457, 50.732285990346753],
            [-3.561647234103693, 50.731964926484693],
            [-3.560561146262379, 50.730112460377391],
            [-3.560662813082621, 50.729718091263152],
            [-3.561853811539694, 50.729655373190162],
            [-3.563045991810188, 50.728776933049168],
            [-3.56600498218243, 50.729257029625927],
            [-3.567625855353221, 50.72831972114308],
            [-3.566879823581128, 50.72740613795191],
            [-3.570203114140348, 50.727807484720664],
            [-3.568396716279929, 50.726242684689382],
            [-3.56783623255736, 50.726175580645439],
            [-3.566668542691688, 50.724470858154689],
            [-3.565718202119081, 50.723801034582578],
            [-3.566066512391876, 50.723114660692239],
            [-3.565414410240258, 50.722252866392118],
            [-3.565112020644367, 50.720108420282024],
            [-3.564768307292698, 50.71999702132446],
            [-3.564612228533062, 50.717863199583952],
            [-3.565555839312903, 50.716720065717119],
            [-3.565733460923835, 50.715419041340219],
            [-3.566212393397597, 50.715083449935612],
            [-3.567004885490283, 50.715357886351399],
            [-3.568662896178572, 50.715123339354925],
            [-3.56868394600249, 50.714438662518106],
            [-3.566878092778013, 50.714189557115326],
            [-3.566185863575174, 50.711228359574804],
            [-3.564859689690964, 50.710875648022181],
            [-3.565094307830674, 50.710605395103428],
            [-3.563505128169338, 50.709626665602499],
            [-3.562808384748705, 50.708522634380344],
            [-3.56272938903567, 50.706913884094106],
            [-3.560034751007122, 50.706162168799757],
            [-3.553660257790968, 50.705178915610894],
            [-3.548960713860997, 50.703831317289712],
            [-3.545140596808391, 50.701657076641695],
            [-3.542984057415143, 50.699420226696297],
            [-3.539077061364337, 50.692929232415111],
            [-3.538127453508397, 50.693418418148234],
            [-3.533694875829255, 50.69273938685199],
            [-3.529373781662427, 50.692828554526223],
            [-3.526817343877851, 50.695331648978936],
            [-3.525075688388883, 50.693670902659349],
            [-3.522803538013167, 50.692588141110406],
            [-3.520884856817041, 50.692537654519036],
            [-3.518764733618842, 50.693044650720594],
            [-3.517734715594893, 50.692496886615778],
            [-3.510856821234187, 50.692240021204476],
            [-3.50676764956168, 50.691127432225272],
            [-3.505395443194327, 50.691427557802776],
            [-3.503640222892181, 50.692634640304192],
            [-3.503247218614256, 50.692431965008105],
            [-3.501756288562779, 50.694336188451189],
            [-3.499564560761776, 50.69420523119323],
            [-3.496014036254913, 50.692975614325626],
            [-3.494336244091894, 50.692752523511871],
            [-3.493616458644376, 50.692022501041357],
            [-3.49358601370146, 50.691153237619311],
            [-3.493873639783146, 50.690447172601743],
            [-3.494786605565165, 50.689861691221452],
            [-3.492741385117133, 50.68889774440408],
            [-3.492714227268745, 50.688621997397888],
            [-3.490222959439058, 50.68827706396614],
            [-3.487808859817529, 50.688893376936747],
            [-3.485080762343865, 50.688445236142094],
            [-3.482530645690395, 50.687803209845072],
            [-3.482327132503406, 50.686601594264289],
            [-3.480793767564901, 50.686055410784775],
            [-3.480441727343247, 50.685305344921701],
            [-3.479604639712459, 50.685335762390309],
            [-3.477278425056678, 50.683476681186917],
            [-3.470453084160657, 50.681862377883782],
            [-3.469837946633267, 50.682244260746458],
            [-3.468586036775513, 50.681726742631284],
            [-3.468834389069517, 50.681157932996562],
            [-3.467247510192242, 50.675345761666009],
            [-3.467589814237251, 50.674976321907586],
            [-3.467650051782967, 50.667515600306466],
            [-3.466065638003915, 50.663351850033976],
            [-3.467571671808397, 50.664684587326498],
            [-3.468081207024351, 50.663963197782593],
            [-3.467261729711079, 50.663366470825096],
            [-3.465489502327075, 50.660344531988301],
            [-3.462166876946238, 50.658870002790962],
            [-3.460834513381813, 50.657925329127977],
            [-3.460016337580675, 50.657957169468276],
            [-3.457578912139282, 50.657097349661043],
            [-3.456401472544073, 50.656125505852003],
            [-3.455586607318887, 50.652877402873386],
            [-3.452442258413484, 50.649267149289166],
            [-3.451330462708297, 50.647294375679436],
            [-3.451328428937557, 50.646448127027945],
            [-3.452530541371022, 50.644379063043118],
            [-3.452417072859121, 50.642304811872634],
            [-3.449831658930149, 50.636467953866529],
            [-3.44898578785652, 50.631173293216328],
            [-3.446048364183016, 50.624980089135612],
            [-3.446109234098631, 50.622909058096347],
            [-3.44707150173802, 50.619807880376491],
            [-3.44691235245885, 50.617155909590494],
            [-3.445185474785631, 50.61311413701285],
            [-3.444197372882066, 50.607295975740755],
            [-3.444635590549816, 50.605638452935573],
            [-3.445971986979898, 50.605621867772349],
            [-3.444620709352397, 50.605521723300328],
            [-3.443659859756414, 50.601601721752367],
            [-3.442589408067373, 50.602501736604651],
            [-3.443746758280023, 50.602640282285336],
            [-3.44325103686415, 50.602834388984554],
            [-3.442117916341207, 50.602471602549748],
            [-3.439844591054523, 50.604150920158141],
            [-3.436171142289439, 50.605801600572292],
            [-3.435443914731495, 50.606610079164845],
            [-3.431277542409705, 50.607770242577182],
            [-3.430887723881028, 50.607266006863782],
            [-3.430325061725728, 50.607166796609],
            [-3.42842674138075, 50.607856505493857],
            [-3.427557164444372, 50.609010227047321],
            [-3.427423649234972, 50.610575812866806],
            [-3.429246405548084, 50.611212673187993],
            [-3.430385262353098, 50.611061992176801],
            [-3.430870059512643, 50.610596475073407],
            [-3.429687530578736, 50.611916836735269],
            [-3.427608684055977, 50.61225260906663],
            [-3.428372256827976, 50.61245009457177],
            [-3.42776199642569, 50.612974696717082],
            [-3.426868779622252, 50.61339753999323],
            [-3.42578373209087, 50.613273229581935],
            [-3.424528839623594, 50.611035744236531],
            [-3.42963547997633, 50.605398169887458],
            [-3.43258546092696, 50.603091986181575],
            [-3.437674148858844, 50.600030876723345],
            [-3.439557887932504, 50.598600137838218],
            [-3.439280795406018, 50.598039676090508],
            [-3.441943679752767, 50.596679306623031],
            [-3.44290663493489, 50.595825595453263],
            [-3.443673991427131, 50.593198101003601],
            [-3.443357172125953, 50.592771243520517],
            [-3.443893881890593, 50.592719622791037],
            [-3.443189890741678, 50.592106005024043],
            [-3.443987855072128, 50.591958513461428],
            [-3.444164655688406, 50.592149679520709],
            [-3.450240779195899, 50.589424702413474],
            [-3.458153398155175, 50.584894820601313],
            [-3.465019308963136, 50.579864938941995],
            [-3.464789126545034, 50.579684368372973],
            [-3.467258173900263, 50.577460676232398],
            [-3.467234451682514, 50.576788264271968],
            [-3.466606108705388, 50.576975150368767],
            [-3.467391338675191, 50.576246679463594],
            [-3.468946804019686, 50.574264691959797],
            [-3.468853160695978, 50.573660613018589],
            [-3.468216773056651, 50.573178495630849],
            [-3.468744760799064, 50.573126869153221],
            [-3.468443047404533, 50.572598261426172],
            [-3.468960524680429, 50.572072810655591],
            [-3.468316083728849, 50.571700677589853],
            [-3.468193517102869, 50.571703860025806],
            [-3.467942398340488, 50.571484887650215],
            [-3.468264797975573, 50.571671062217135],
            [-3.469081788629126, 50.571295144163948],
            [-3.469472966210573, 50.570160627672912],
            [-3.468470087339708, 50.566292596312046],
            [-3.469626972688221, 50.563901022102421],
            [-3.468562090315947, 50.563075367332274],
            [-3.46961390346882, 50.563208688907928],
            [-3.469928035000579, 50.562459163409294],
            [-3.471203896211149, 50.562079710982694],
            [-3.472522559022949, 50.56194162792989],
            [-3.473357204079805, 50.562355560953677],
            [-3.475362737068543, 50.56160618746329],
            [-3.478840785833732, 50.559232745065302],
            [-3.482746604791471, 50.555296074136443],
            [-3.482762891799619, 50.55509531153465],
            [-3.482506992406941, 50.555218184156274],
            [-3.482230121781219, 50.555347618668179],
            [-3.482309433215208, 50.555130764737306],
            [-3.483557044199744, 50.553995855537053],
            [-3.484141393262659, 50.553993128997242],
            [-3.486190159019823, 50.55257030000628],
            [-3.489885054543191, 50.549143307647952],
            [-3.496426321598773, 50.541705397092521],
            [-3.498138457873743, 50.541712153966706],
            [-3.499468074795107, 50.541183302007802],
            [-3.499725508732952, 50.540534249510813],
            [-3.50026717344054, 50.540881615097661],
            [-3.498524001457932, 50.542833182139255],
            [-3.498435205375394, 50.544261599064328],
            [-3.498943312293554, 50.54430632062742],
            [-3.498787128597334, 50.544900106103562],
            [-3.49948611593071, 50.544821854200237],
            [-3.499095418716222, 50.545052623867541],
            [-3.499369011802991, 50.545312610764284],
            [-3.499842790998632, 50.54503130534011],
            [-3.501092361042474, 50.545757165374965],
            [-3.500915424601674, 50.546407880514174],
            [-3.501407078620763, 50.545933876706471],
            [-3.501930903468412, 50.546072814676684],
            [-3.501835481901363, 50.546534514659875],
            [-3.502227117201596, 50.546111262126523],
            [-3.506841768502289, 50.546219799322394],
            [-3.508078682405397, 50.546544636855863],
            [-3.509960526587531, 50.546556216712787],
            [-3.515758300101488, 50.545440315929042],
            [-3.517376622745529, 50.545510067349895],
            [-3.519132741779675, 50.546002493566753],
            [-3.523625748936734, 50.546627283237534],
            [-3.524742520966903, 50.546855486898117],
            [-3.524798408649946, 50.547100278784136],
            [-3.524937859645414, 50.546872713989238],
            [-3.52938978549869, 50.547226214571161],
            [-3.532181486846856, 50.547045606954676],
            [-3.536817341944541, 50.546194863085951],
            [-3.53743844654884, 50.544895186127292],
            [-3.539273527619358, 50.545292733319116],
            [-3.540807114056263, 50.544970260701326],
            [-3.541898870874015, 50.545382099848709],
            [-3.54678551792507, 50.545088833923359],
            [-3.548632264342749, 50.544676657414939],
            [-3.549624621121072, 50.545111331274136],
            [-3.55207390558569, 50.544953689868912],
            [-3.553908929531041, 50.545345611451452],
            [-3.554892628082571, 50.54495744391366],
            [-3.555672921571153, 50.545508217922453],
            [-3.558993373234978, 50.545531248005076],
            [-3.564899426531591, 50.54452656138038],
            [-3.565801254528968, 50.544013491491121],
            [-3.565390191589642, 50.543875122629657],
            [-3.566604237267656, 50.542945940966497],
            [-3.568143023586574, 50.542447661883088],
            [-3.573021034554298, 50.542799148793108],
            [-3.577096372774347, 50.542187302384242],
            [-3.579384775364049, 50.541318971384179],
            [-3.580123970877706, 50.540633519889496],
            [-3.580214810686697, 50.539593525344515],
            [-3.580829602573277, 50.539620252729357],
            [-3.580242084632557, 50.539396195112225],
            [-3.580563971059839, 50.539315379187201],
            [-3.582198391854977, 50.53970058524709],
            [-3.590785887701912, 50.53886045652159],
            [-3.590709006062451, 50.538672641253044],
            [-3.591427515047478, 50.538594468627096],
            [-3.590753808985218, 50.538536225377939],
            [-3.590982701424002, 50.538376608160881],
            [-3.589495549188143, 50.538846605909598],
            [-3.585070384191881, 50.539029261571912],
            [-3.585042793248137, 50.538753533423261],
            [-3.583682175395588, 50.538537319757012],
            [-3.585186750320322, 50.538038379656882],
            [-3.584449241143965, 50.53788653592197],
            [-3.584530021034659, 50.537559867558258],
            [-3.58252875952572, 50.538144696746542],
            [-3.581961444936918, 50.53784932360783],
            [-3.580753563509677, 50.53814813247326],
            [-3.581227561766897, 50.537889873471066],
            [-3.580553121703459, 50.537724557288826],
            [-3.582781020348122, 50.53758636110458],
            [-3.586398613180014, 50.536807731924313],
            [-3.587204206714222, 50.536633965396021],
            [-3.588835491989156, 50.535919201562905],
            [-3.58990405412003, 50.534795697584947],
            [-3.591553718334411, 50.534504243582788],
            [-3.593662251749337, 50.53480725850617],
            [-3.59345996890393, 50.535420694902271],
            [-3.591779963815489, 50.53535644330816],
            [-3.591567654644955, 50.53584050521691],
            [-3.591863327592344, 50.535443440753603],
            [-3.593435926623062, 50.535502865998232],
            [-3.593782326167876, 50.534727369922514],
            [-3.593418739424115, 50.534490419269282],
            [-3.592038250697609, 50.534477831424951],
            [-3.596043741833209, 50.533706185243808],
            [-3.597190832112264, 50.533660771702287],
            [-3.599329358990361, 50.535225977930843],
            [-3.602724125771183, 50.536587386941505],
            [-3.601403203847213, 50.538085318516025],
            [-3.602850928761112, 50.538953962502596],
            [-3.602620584477206, 50.540068746139404],
            [-3.605994776973983, 50.54114374675666],
            [-3.602830335094779, 50.539928255668741],
            [-3.603022884547399, 50.538864356370645],
            [-3.601664026064494, 50.538119503151748],
            [-3.603036003619629, 50.536629259228867],
            [-3.603260521970453, 50.536466979741974],
            [-3.59900244837032, 50.534655776408975],
            [-3.601914427997653, 50.534764132005918],
            [-3.604523600664022, 50.535221944813124],
            [-3.607442343769211, 50.537229524442431],
            [-3.608045321606795, 50.537780599900678],
            [-3.608575566805616, 50.538101541049954],
            [-3.608073811578321, 50.538701154148519],
            [-3.609031129833942, 50.539918253583345],
            [-3.608526328681892, 50.538354942522993],
            [-3.609059331740494, 50.538424921050449],
            [-3.609340021635438, 50.53904250055885],
            [-3.609690287643446, 50.538843394893171],
            [-3.609940991217627, 50.538497270308945],
            [-3.610919389973537, 50.538840781204648],
            [-3.612258631609965, 50.539874494323655],
            [-3.611288701453287, 50.5404428316034],
            [-3.610595000776269, 50.540710549184581],
            [-3.611217300385761, 50.540832344775851],
            [-3.611809791797312, 50.54119737782127],
            [-3.613159286944923, 50.541040181899433],
            [-3.613338493641473, 50.540667160609352],
            [-3.612483682934427, 50.540597163078338],
            [-3.612564702711738, 50.539579760258896],
            [-3.611754932184919, 50.53934005319266],
            [-3.610935653347879, 50.53853117515478],
            [-3.610171558706862, 50.538471597023182],
            [-3.608228165460313, 50.537513661026615],
            [-3.604924822503063, 50.535193929299389],
            [-3.603525515160819, 50.534876852969596],
            [-3.604578998787789, 50.534026826631887],
            [-3.603352646045863, 50.534855850876923],
            [-3.60038601737742, 50.534634063526383],
            [-3.598965490161418, 50.534480908349344],
            [-3.597358072638686, 50.533431837280347],
            [-3.600166676380669, 50.534115448546935],
            [-3.601348413834784, 50.534092901181353],
            [-3.602980756813047, 50.533459764968001],
            [-3.604617610782971, 50.531838142735253],
            [-3.606435137444957, 50.531675469665309],
            [-3.606095024285115, 50.531466114633432],
            [-3.604631735910566, 50.531713835808873],
            [-3.602674168539748, 50.533490068327858],
            [-3.601206984024045, 50.534041784399562],
            [-3.59692350471887, 50.533266021877346],
            [-3.592563887468212, 50.534121688229924],
            [-3.590090180878745, 50.534204072897893],
            [-3.595059082406612, 50.525138857585254],
            [-3.594964990265154, 50.524356801015948],
            [-3.59490778465874, 50.525086969091994],
            [-3.589817701676322, 50.53424197062364],
            [-3.588393433597475, 50.535782236225586],
            [-3.587522503452002, 50.536152963060943],
            [-3.586198050477992, 50.536466009724165],
            [-3.578259662412969, 50.537970623624282],
            [-3.577603657894136, 50.537850008458193],
            [-3.577681895650704, 50.538122353691939],
            [-3.576312209471306, 50.538479069597024],
            [-3.570924474184705, 50.538701199225308],
            [-3.567186916502429, 50.538640059408316],
            [-3.566011884753593, 50.538315016288678],
            [-3.566136897797104, 50.539319717692088],
            [-3.564039506203484, 50.539567361723762],
            [-3.559364868992003, 50.539208278844825],
            [-3.558311671588344, 50.539795274557477],
            [-3.554138890971574, 50.540749520439924],
            [-3.552209670057996, 50.54010524006209],
            [-3.55100832267164, 50.541126735767023],
            [-3.548757903859473, 50.540025307021871],
            [-3.54346314183264, 50.539602785666887],
            [-3.540406813011235, 50.538144042327382],
            [-3.539689290071751, 50.54029130780188],
            [-3.539316002484917, 50.540442839925348],
            [-3.535840567820128, 50.540503136423567],
            [-3.533629803976213, 50.540046631622104],
            [-3.53220309972965, 50.540354113227927],
            [-3.531135979563473, 50.540086655990279],
            [-3.529298123829228, 50.540598267041652],
            [-3.526877071010002, 50.54031437508479],
            [-3.524086786485458, 50.540658521474867],
            [-3.522332466883069, 50.54118151928558],
            [-3.521668848921673, 50.541128140557163],
            [-3.521385588453529, 50.540790087905762],
            [-3.518814348934228, 50.541365076911958],
            [-3.517969529960209, 50.540679096331402],
            [-3.514061874661897, 50.540949430776529],
            [-3.512424188868564, 50.541945620947295],
            [-3.510254922598185, 50.542164448399461],
            [-3.509769566108477, 50.542875838292879],
            [-3.509627884307637, 50.542240033481882],
            [-3.507869710009416, 50.542339266357708],
            [-3.499093763547603, 50.538369547561317],
            [-3.49668385987467, 50.538205398518414],
            [-3.495970331846363, 50.537776585262385],
            [-3.496314013849663, 50.537131827736594],
            [-3.498292768663891, 50.535983081057992],
            [-3.500732376259474, 50.532907337065595],
            [-3.500838778573669, 50.530702544664322],
            [-3.50267165167143, 50.528748879529708],
            [-3.50305047820869, 50.527439022666208],
            [-3.504030030888719, 50.52638671334244],
            [-3.503903469442875, 50.525512375556467],
            [-3.505008522955062, 50.523174154259912],
            [-3.506306305124216, 50.521708501628339],
            [-3.507074494157309, 50.519562583403882],
            [-3.506813443968415, 50.519294357380758],
            [-3.508202109336462, 50.518737655497112],
            [-3.508044284335155, 50.517990535490455],
            [-3.509183159054109, 50.517122282561793],
            [-3.509171880187286, 50.516591807517507],
            [-3.513801503002916, 50.516552368432585],
            [-3.516530385909284, 50.518515217319987],
            [-3.517254268974699, 50.517378886495905],
            [-3.520891106208002, 50.517696558228387],
            [-3.519980932498433, 50.51105588738789],
            [-3.522911678437349, 50.509958127579033],
            [-3.52485045776828, 50.508717705219958],
            [-3.526886976978476, 50.508018282166276],
            [-3.52899880164937, 50.50681419155633],
            [-3.528130516717659, 50.506085421862139],
            [-3.529571707516612, 50.506082677343315],
            [-3.529936992759505, 50.504600224879148],
            [-3.532757744951041, 50.505263710345808],
            [-3.533165061148362, 50.504646778989951],
            [-3.535112134583744, 50.505155332387965],
            [-3.536618680651366, 50.505950271755168],
            [-3.538466651557286, 50.504405035357998],
            [-3.539128464891925, 50.504548276617967],
            [-3.539009362190222, 50.502186327227804],
            [-3.541270456010202, 50.500776780175443],
            [-3.542244137219159, 50.498850934787974],
            [-3.543260797419312, 50.498981354851807],
            [-3.543622904197825, 50.497459325844105],
            [-3.55155635930756, 50.494902151730621],
            [-3.553675140806981, 50.495106817665381],
            [-3.556352984470105, 50.494818312151793],
            [-3.558393392377146, 50.495040128991313],
            [-3.561059342895296, 50.494477367810163],
            [-3.562818250759872, 50.493346639555575],
            [-3.562080836691649, 50.491507444570146],
            [-3.562373236634303, 50.488490642017609],
            [-3.564757967869145, 50.486980938539361],
            [-3.567235144852568, 50.487928809809823],
            [-3.567491969045017, 50.489427293121558],
            [-3.568973561696708, 50.489978423463953],
            [-3.568952569199893, 50.490408603831355],
            [-3.56976637015931, 50.490716903537816],
            [-3.570177927372252, 50.490458628750353],
            [-3.570331217163975, 50.489256804703523],
            [-3.571513926015909, 50.487979924993638],
            [-3.572818812960947, 50.487879552767915],
            [-3.57297578703237, 50.487470017430013],
            [-3.574087998616627, 50.487680718409131],
            [-3.574905460973443, 50.486870119346918],
            [-3.574830506079568, 50.486014935676202],
            [-3.575276674735519, 50.485824525882109],
            [-3.575619201996102, 50.484552678671477],
            [-3.576692573045999, 50.483132426432782],
            [-3.579200522923989, 50.482211638043111],
            [-3.580829357024378, 50.480335021023677],
            [-3.581946038157921, 50.480303655494183],
            [-3.582060371883117, 50.479844321996843],
            [-3.584455070772963, 50.479254127745058],
            [-3.584155533892398, 50.478009881194268],
            [-3.584233800358642, 50.477737205885155],
            [-3.587548701203004, 50.478577003814188],
            [-3.588832839179255, 50.47858106057766],
            [-3.590708977771291, 50.479635569888231],
            [-3.591434836158698, 50.479676908317856],
            [-3.592410237326618, 50.480936171899785],
            [-3.595096336625991, 50.482723302162789],
            [-3.595201459810455, 50.483249791185052],
            [-3.598030159402201, 50.484409836911233],
            [-3.598314049500405, 50.484175698956662],
            [-3.599307062295435, 50.484445356061478],
            [-3.602348648120939, 50.484392718058551],
            [-3.603078917676787, 50.484520262979146],
            [-3.604403830259795, 50.484058612228488],
            [-3.607057100083737, 50.482075758392924],
            [-3.608566162273347, 50.481602522321367],
            [-3.609782371219016, 50.48072500388006],
            [-3.613205462463834, 50.475615928008715],
            [-3.61257279425781, 50.472265541197736],
            [-3.614372822834742, 50.472401566757952],
            [-3.61327710759118, 50.469813979120929],
            [-3.613826556978179, 50.469313502383301],
            [-3.616569851023033, 50.469674739700537],
            [-3.620944120034016, 50.469720904409186],
            [-3.621398241925973, 50.468289967955513],
            [-3.623428579818596, 50.466678755821434],
            [-3.626226840069042, 50.466796166973609],
            [-3.627320746143356, 50.466193579470037],
            [-3.631343507839158, 50.466229877857906],
            [-3.632366677005016, 50.466930530031028],
            [-3.633242902358191, 50.466392098058471],
            [-3.633958605684052, 50.466429715552309],
            [-3.63446185615154, 50.467048612027419],
            [-3.637168702907648, 50.467863161153034],
            [-3.638040622952286, 50.466875065618247],
            [-3.640603901341821, 50.465616695617257],
            [-3.6429716879645, 50.462543388720341],
            [-3.645222456449424, 50.461140930148858],
            [-3.647374430801285, 50.462169944140193],
            [-3.653038609905862, 50.463830795762114],
            [-3.65891414507575, 50.464594377711492],
            [-3.657724010239682, 50.465011565068203],
            [-3.6557924013942, 50.466509554547763],
            [-3.653603070439468, 50.46740949110643],
            [-3.654071794160452, 50.467798554033166],
            [-3.652918499927109, 50.468750296935724],
            [-3.652436839295524, 50.468151858348328],
            [-3.650140229917141, 50.468483947086376],
            [-3.650102828097113, 50.470566534196536],
            [-3.652221659245387, 50.47022527964053],
            [-3.652700839702049, 50.469782276101895],
            [-3.654847562999631, 50.469716685921782],
            [-3.655146830354688, 50.469812259691089],
            [-3.654674754470275, 50.470337015652518],
            [-3.653283764384486, 50.470657181979618],
            [-3.654694812472076, 50.471880060771397],
            [-3.655472450531194, 50.472074056352838],
            [-3.65683926062473, 50.471464609057456],
            [-3.657993176858243, 50.471699096987422],
            [-3.661963706545947, 50.469669213076131],
            [-3.662562437576894, 50.469784772840576],
            [-3.663951206420099, 50.469243279955684],
            [-3.664110729045432, 50.468890241017149],
            [-3.664704137082853, 50.469014859665791],
            [-3.664993052579732, 50.467892798570276],
            [-3.666308933095193, 50.467885651347892],
            [-3.666997977306501, 50.46720394497833],
            [-3.668287857112432, 50.466778936849863],
            [-3.668603401424575, 50.466055808201482],
            [-3.667961273470194, 50.465466929273923],
            [-3.668312423795116, 50.464154197644419],
            [-3.669633036383428, 50.462124240870438],
            [-3.671124552175446, 50.46314700509847],
            [-3.671276035125488, 50.464446230690356],
            [-3.674335278171042, 50.46624776912585],
            [-3.674984208715819, 50.466149389638204],
            [-3.676768441022443, 50.466995186514126],
            [-3.677861662315176, 50.467027991869848],
            [-3.679586355445391, 50.467622778224744],
            [-3.680005920947882, 50.4680897938943],
            [-3.681506191332479, 50.467957496385161],
            [-3.681482180524962, 50.468233952897087],
            [-3.681906976239986, 50.468530004099001],
            [-3.682453041967696, 50.468431271307018],
            [-3.682998390105007, 50.469106012664838],
            [-3.686286670547809, 50.470115169628549],
            [-3.6884860759548, 50.472003446185944],
            [-3.689477468982086, 50.472259767415089],
            [-3.689866962390063, 50.472907060384316],
            [-3.69079164208284, 50.4732677680975],
            [-3.690768555082824, 50.473647641743781],
            [-3.691368221671673, 50.473983388085436],
            [-3.691207201949584, 50.47448668309184],
            [-3.692261888349819, 50.474737563860103],
            [-3.693735745962147, 50.475950965428638],
            [-3.694046557147678, 50.477863917009266],
            [-3.697166442753649, 50.478279831471745],
            [-3.698269697017155, 50.478860021935148],
            [-3.698663338995399, 50.478713972426789],
            [-3.69997844685074, 50.479658899817387],
            [-3.703137995943923, 50.480036301323068],
            [-3.703849926932254, 50.480471070915989],
            [-3.705991954085146, 50.480498143116812],
            [-3.706462205188611, 50.481148693812536],
            [-3.707504739294299, 50.480977806248418],
            [-3.707854482034991, 50.481295550512769],
            [-3.70835710362956, 50.481005766491563],
            [-3.710222573146642, 50.481804891755203],
            [-3.710431093363358, 50.48262206114093],
            [-3.710929548544519, 50.482841376362202],
            [-3.711622971839797, 50.482370695217071],
            [-3.712046859638012, 50.483022804991947],
            [-3.715458822450061, 50.48502853374675],
            [-3.716060424457438, 50.485758952958783],
            [-3.716186196343958, 50.486740120195897],
            [-3.717031786932039, 50.487433658366761],
            [-3.716835606292752, 50.488282869494185],
            [-3.717591047253138, 50.489210673030527],
            [-3.716940806503639, 50.49067906303366],
            [-3.718687071320817, 50.492452041702521],
            [-3.718476098038512, 50.493048747920739],
            [-3.719344096006651, 50.493462231443509],
            [-3.720209512817646, 50.493416163910119],
            [-3.720680094954661, 50.494027979122862],
            [-3.721508027029336, 50.494346704869976],
            [-3.722313683790047, 50.494285317025692],
            [-3.722674179189041, 50.494583071496685],
            [-3.723325754368022, 50.494394447007295],
            [-3.725173189429673, 50.495334804153039],
            [-3.72761524357789, 50.497846515173727],
            [-3.730406185551904, 50.498862716144394],
            [-3.735254050070426, 50.502403333136662],
            [-3.735839348576711, 50.503828214434193],
            [-3.737227395445, 50.505491139039826],
            [-3.737383239820821, 50.506355813817429],
            [-3.741868844300467, 50.507950811772574],
            [-3.74499109254789, 50.507600045691298],
            [-3.745907463824766, 50.507743692598865],
            [-3.747446487560895, 50.508058759546167],
            [-3.750484957674822, 50.509371157818087],
            [-3.756320689918198, 50.510423644659646],
            [-3.759281625749189, 50.508767230586926],
            [-3.765014071492616, 50.506290772004654],
            [-3.767157669326661, 50.504401016400728],
            [-3.768047558059426, 50.502702956503853],
            [-3.768211774876832, 50.500870218084657],
            [-3.765814536965785, 50.496106614674822],
            [-3.76556082272042, 50.494029291973128],
            [-3.765940219100205, 50.492671761438579],
            [-3.768342568865481, 50.488931613886308],
            [-3.769107969001571, 50.485796416571411],
            [-3.767461925060517, 50.484902261113049],
            [-3.766280612758726, 50.481877580876898],
            [-3.761177567788391, 50.479060716108343],
            [-3.76076932577415, 50.47854345290699],
            [-3.760888484270967, 50.477198867551323],
            [-3.75786838798514, 50.474433958175958],
            [-3.757670675880305, 50.473549251881117],
            [-3.758921934153838, 50.470050618122897],
            [-3.75883771121309, 50.466832088570811],
            [-3.762021441212625, 50.467756149783348],
            [-3.764121610103178, 50.467505771744918],
            [-3.765802299404358, 50.466700505742907],
            [-3.768118543555, 50.467069151398078],
            [-3.77224858649933, 50.464390889752913],
            [-3.772894511251516, 50.463502345820466],
            [-3.774226827624825, 50.463561180625199],
            [-3.77594452042405, 50.464165442939141],
            [-3.776654100938624, 50.463962139466773],
            [-3.778800717672456, 50.465894501224611],
            [-3.779773414128401, 50.468607469551259],
            [-3.780989564579812, 50.47003327587877],
            [-3.781989575866429, 50.47230510676399],
            [-3.784576401150028, 50.473224203947012],
            [-3.784987728069593, 50.472882422196363],
            [-3.786307351766292, 50.472709272465735],
            [-3.787390428775642, 50.472018996193242],
            [-3.786616914785546, 50.473294517730345],
            [-3.786328066884196, 50.475426904962546],
            [-3.787398666243245, 50.475637108122761],
            [-3.787205388455567, 50.476939692601697],
            [-3.786254324890976, 50.477844691735967],
            [-3.788855266933943, 50.478268814916376],
            [-3.791893527282177, 50.477807443709722],
            [-3.792373956585078, 50.478547434897649],
            [-3.793279594684362, 50.478493007227108],
            [-3.793509282006973, 50.480352998566815],
            [-3.794332678554739, 50.480954584933599],
            [-3.791176885282761, 50.482301026596318],
            [-3.793098519837071, 50.48398655948661],
            [-3.794862391444498, 50.48641558960081],
            [-3.795527912288518, 50.486902682484285],
            [-3.796940131585565, 50.487265818817299],
            [-3.79825166704153, 50.489431966528258],
            [-3.794407425897919, 50.489959895156495],
            [-3.794245495799097, 50.490262787358233],
            [-3.794999512367185, 50.490888822842827],
            [-3.795293483933603, 50.491949160982784],
            [-3.792250378365937, 50.493013278901707],
            [-3.789619929646789, 50.492987210795022],
            [-3.789744685927008, 50.494087039303636],
            [-3.790785516003986, 50.495623364902393],
            [-3.787176509841652, 50.494927864555137],
            [-3.782940519779811, 50.494765303350476],
            [-3.779070001797512, 50.494909986582307],
            [-3.775870781594702, 50.495585726891697],
            [-3.776166628486021, 50.497120057309871],
            [-3.778375144127176, 50.498557717577015],
            [-3.780387946107528, 50.498837226230883],
            [-3.785554813210623, 50.498734673586242],
            [-3.789201934976183, 50.49942424277652],
            [-3.790667460961662, 50.500181466416819],
            [-3.791754387911574, 50.501440962244629],
            [-3.791640546870292, 50.502307923048022],
            [-3.788214717279878, 50.503824815810098],
            [-3.787082494008801, 50.50502489879127],
            [-3.786282036181396, 50.507431347542258],
            [-3.787481746959323, 50.509995055191297],
            [-3.785240149345492, 50.513870727449557],
            [-3.784768611325877, 50.517860423457826],
            [-3.788004990450891, 50.520557458045694],
            [-3.789391862817177, 50.521232264234328],
            [-3.791126964619238, 50.521452903771973],
            [-3.794245794886418, 50.520849044740388],
            [-3.798153592034467, 50.520962313572113],
            [-3.799481522140094, 50.52171525778828],
            [-3.799618100826708, 50.522746536421707],
            [-3.797978012284861, 50.525844174496427],
            [-3.797678795828114, 50.529887019550586],
            [-3.794851512772412, 50.532573034703788],
            [-3.798339048652501, 50.534967315836099],
            [-3.799449084174549, 50.536399061907474],
            [-3.800862513459336, 50.53693931453315],
            [-3.801848488880972, 50.536816129552456],
            [-3.803871361704276, 50.535426731448609],
            [-3.805187298745145, 50.533893570129692],
            [-3.805476625928446, 50.532897064649994],
            [-3.808931403909708, 50.532871302928775],
            [-3.811177650382638, 50.530802867037345],
            [-3.814839589796716, 50.529487587749756],
            [-3.81465022086138, 50.527641408948256],
            [-3.81362897495617, 50.526140067976677],
            [-3.813666098492658, 50.525526110056759],
            [-3.814050153634217, 50.525276391631571],
            [-3.818119070499958, 50.525092381886623],
            [-3.819917974870364, 50.522087304197157],
            [-3.820154039526801, 50.519239758226199],
            [-3.821870926720431, 50.516861001422527],
            [-3.822755668768278, 50.516575533118555],
            [-3.825508207511162, 50.516769826682676],
            [-3.827344131947311, 50.516386655532905],
            [-3.829313872402716, 50.518224634108677],
            [-3.836243643781139, 50.523328342784019],
            [-3.837125853448105, 50.524901836084595],
            [-3.836554046546365, 50.5253272762709],
            [-3.836095241311108, 50.526737556663221],
            [-3.836578095675594, 50.527508805791605],
            [-3.840275047664059, 50.530235805225828],
            [-3.842645284273998, 50.530518484955934],
            [-3.843768957381688, 50.531001647273847],
            [-3.844013712954516, 50.531850387090628],
            [-3.843310400947713, 50.533246581186894],
            [-3.844575034958976, 50.534375060635021],
            [-3.851493322269944, 50.536162916463979],
            [-3.852861030607728, 50.53559342582129],
            [-3.855256138140329, 50.533228560968467],
            [-3.858614925862697, 50.532591271112402],
            [-3.861499114484584, 50.534022870241245],
            [-3.863245041191419, 50.534425739671484],
            [-3.866469831723692, 50.534505380960191],
            [-3.871263095173916, 50.534102837336057],
            [-3.871944493465949, 50.534490308859262],
            [-3.872824312354977, 50.536110342488804],
            [-3.874858486106455, 50.537620942471555],
            [-3.875299626004109, 50.540132120560187],
            [-3.874998260895947, 50.54103816161291],
            [-3.875582971051409, 50.542542409524621],
            [-3.875815244820147, 50.544554192415212],
            [-3.874873304003777, 50.545430980841559],
            [-3.875126342017864, 50.546579018820786],
            [-3.875942649707587, 50.54723051225659],
            [-3.875650648233213, 50.547697502447981],
            [-3.875936094613628, 50.549305501883872],
            [-3.877094191665633, 50.549995548403963],
            [-3.877444114673176, 50.55240745446811],
            [-3.877106701550259, 50.552939936039465],
            [-3.874351010692796, 50.554184120946474],
            [-3.874597617655119, 50.555133498142418],
            [-3.874078700795656, 50.556216618651568],
            [-3.875916931597093, 50.556964977893074],
            [-3.876286412520074, 50.558589607964976],
            [-3.874273526693856, 50.561803156913108],
            [-3.874842095855204, 50.562858872170281],
            [-3.874855666998324, 50.564018858956914],
            [-3.874358244842192, 50.564756269220254],
            [-3.872951163439639, 50.565593757332358],
            [-3.872827764018004, 50.566398892000976],
            [-3.871857693553389, 50.56675625955075],
            [-3.871867707780428, 50.568113267695139],
            [-3.870755959399432, 50.56920859885539],
            [-3.870302497140985, 50.570666589003096],
            [-3.869733997285915, 50.571146097734513],
            [-3.870154635748492, 50.57210347930998],
            [-3.872798583625428, 50.573496391794485],
            [-3.873897502611624, 50.575172245992484],
            [-3.877044579876788, 50.575144933108916],
            [-3.877426627537481, 50.575695490661062],
            [-3.877738679275009, 50.577078205289638],
            [-3.877358470354729, 50.577746282222492],
            [-3.877637044208694, 50.578784173838393],
            [-3.877315279604517, 50.578933263750088],
            [-3.878414450286388, 50.580004686617585],
            [-3.878404085772734, 50.580489620741751],
            [-3.879341781815017, 50.580909789286736],
            [-3.880899428261988, 50.584409313420693],
            [-3.880330548993355, 50.585731599190446],
            [-3.879283760362334, 50.586334896456385],
            [-3.8806391210913, 50.586987548123027],
            [-3.880575002057833, 50.587434676877827],
            [-3.881329074097259, 50.58774537280398],
            [-3.880522858142502, 50.589174922131598],
            [-3.881311105151847, 50.591688549391371],
            [-3.881086667619074, 50.592508813139048],
            [-3.882363743681648, 50.593814760764303],
            [-3.882802692260654, 50.595573147574399],
            [-3.88205872716274, 50.597325475562592],
            [-3.879166782532996, 50.599537888923074],
            [-3.874852558798891, 50.609028420527885],
            [-3.872957683232793, 50.610926020020507],
            [-3.872420942445407, 50.612572417066545],
            [-3.873316642689121, 50.614268626749727],
            [-3.869782507060148, 50.616898539484744],
            [-3.867288760071373, 50.619321896783788],
            [-3.864244316392385, 50.623448436948117],
            [-3.862029457880345, 50.625145013951709],
            [-3.860545146055693, 50.62583157432541],
            [-3.858076690060957, 50.626194756760221],
            [-3.857723595908594, 50.628965962621216],
            [-3.855964515229118, 50.63068662753458],
            [-3.854435617166598, 50.631648127162308],
            [-3.852758641640935, 50.631614558056249],
            [-3.850353083052134, 50.632328228421613],
            [-3.848850710068399, 50.633537458782122],
            [-3.848364808963014, 50.634452636809598],
            [-3.845905265018725, 50.63570669325221],
            [-3.84526254137694, 50.641032158096081],
            [-3.843595199960885, 50.643059658715529],
            [-3.843906554838271, 50.645268079124776],
            [-3.84355951687873, 50.646953594289364],
            [-3.841739832384842, 50.648239697949833],
            [-3.837735067448691, 50.649830076555624],
            [-3.834743034774533, 50.649717154610016],
            [-3.832740075345367, 50.648920367935283],
            [-3.831731347395854, 50.647789540554768],
            [-3.830245432582678, 50.647663602357298],
            [-3.829261713602153, 50.647938972148367],
            [-3.828795796380974, 50.648981460112388],
            [-3.828828929565836, 50.650490078496667],
            [-3.826635390828096, 50.651780012765251],
            [-3.824495858539965, 50.652057271520121],
            [-3.823806334497978, 50.651603996024278],
            [-3.821918359296165, 50.651399704944978],
            [-3.820672031714058, 50.651801422226036],
            [-3.820105783709559, 50.651681661804417],
            [-3.820012460810461, 50.652231733359869],
            [-3.818500590553327, 50.652315602649779],
            [-3.817380628420433, 50.651411230903442],
            [-3.815959353898276, 50.651606074594064],
            [-3.814131670724348, 50.651313479031991],
            [-3.814212530842575, 50.652934675415977],
            [-3.813466264100678, 50.653393280158419],
            [-3.811679076792494, 50.653676508608214],
            [-3.810774325970071, 50.654245484352032],
            [-3.80989267938917, 50.654201626418995],
            [-3.81019171185117, 50.655171891578902],
            [-3.809362365878938, 50.655679426781283],
            [-3.806814706293108, 50.655504021612479],
            [-3.804627663327659, 50.655914756519749],
            [-3.805883406503299, 50.656711019775088],
            [-3.807225091779839, 50.658602261296075],
            [-3.809639915878271, 50.65899826736262],
            [-3.811907192283338, 50.658757969750503],
            [-3.811977579415429, 50.659005998919433],
            [-3.811065353944962, 50.659603871871532],
            [-3.810378806100492, 50.662464627938576],
            [-3.80946631038777, 50.662910499332654],
            [-3.809637660837673, 50.664643609509952],
            [-3.806220032839146, 50.665372968466031],
            [-3.803477245311803, 50.66658103486057],
            [-3.804510940678022, 50.667373555210375],
            [-3.801556244546195, 50.668743144999567],
            [-3.801590821609944, 50.669757090714832],
            [-3.80057028322454, 50.670343962553424],
            [-3.799396506467464, 50.671772296463146],
            [-3.79900002836998, 50.673173328814066],
            [-3.797198694634097, 50.674923406075457],
            [-3.798059303123432, 50.675777111334945],
            [-3.798466913932737, 50.677701747444672],
            [-3.802825046143714, 50.678625488509063],
            [-3.80700813297359, 50.681620312524522],
            [-3.807733521306984, 50.682815103010441],
            [-3.807854281627642, 50.684630837192593],
            [-3.810971098343161, 50.689398510158817],
            [-3.811604659691976, 50.691033591061128],
            [-3.809717763152398, 50.692589125832249],
            [-3.808900121155494, 50.694006621316305],
            [-3.807266530259005, 50.694753255746868],
            [-3.803299037335652, 50.693645557250015],
            [-3.801640396677158, 50.693703591411015],
            [-3.798765764314513, 50.692917894854425],
            [-3.791863395473985, 50.693848954010868],
            [-3.789354358982803, 50.692806482593575],
            [-3.786767310481487, 50.692167166585122],
            [-3.784926036803564, 50.692821339055413],
            [-3.784261138409938, 50.693433192131735],
            [-3.783763125350884, 50.694898674359784],
            [-3.782552618999648, 50.695536855290108],
            [-3.77998332740956, 50.695330606721932],
            [-3.777087212161566, 50.693482565165006],
            [-3.775732174461812, 50.693069733159085],
            [-3.77381476345967, 50.693174483336655],
            [-3.768462842606884, 50.694985292276925],
            [-3.761631496999509, 50.693225306195892],
            [-3.760626846358422, 50.693317834922553],
            [-3.758460010855123, 50.694439673058824],
            [-3.756727644409962, 50.694838135746252],
            [-3.753426047625549, 50.694316773966655],
            [-3.752656011580692, 50.696335716177138],
            [-3.749526470638213, 50.697166981068548],
            [-3.745581533076551, 50.696673041095444],
            [-3.741772446291882, 50.695524906710631],
            [-3.735059245245851, 50.695904757158758],
            [-3.732273670240627, 50.69700923416211],
            [-3.733669416308265, 50.698610007865135],
            [-3.733453466889683, 50.699473899753613],
            [-3.734013486813203, 50.700144578011077],
            [-3.733683250802641, 50.700252017622034],
            [-3.735109511086876, 50.701256050146362],
            [-3.734863795741858, 50.701696793181192],
            [-3.735230287729908, 50.70172731049756],
            [-3.734994399788568, 50.702012319690866],
            [-3.735660811607859, 50.702533013019554],
            [-3.735576157848599, 50.703193498165398],
            [-3.73384429497226, 50.70457909705852],
            [-3.73389776231005, 50.706527194100808],
            [-3.734852588391239, 50.708336866101007],
            [-3.73321887516639, 50.71142885755431],
            [-3.731037422543598, 50.713654793300925],
            [-3.729223653887638, 50.714483938986021],
            [-3.724223402985699, 50.715605764758884],
            [-3.7254376656496, 50.716307272396023],
            [-3.727756642929496, 50.717424981994363],
            [-3.73257029725981, 50.718730423684768],
            [-3.734971700092558, 50.721726403605821],
            [-3.737496841577927, 50.72353964531397],
            [-3.734212451295703, 50.723801707460382],
            [-3.726476149170736, 50.727564314638663],
            [-3.723325635885437, 50.728163138483914],
            [-3.722731339814626, 50.731506699135011],
            [-3.719639632729223, 50.734647900615769],
            [-3.71822383986848, 50.737317349943211],
            [-3.710043259412688, 50.742713200138674],
            [-3.70832488927437, 50.748734312233211],
            [-3.701912363616342, 50.750858822097001],
            [-3.694297848401467, 50.752455451255472],
            [-3.691830701662562, 50.753780935888649],
            [-3.688972008791484, 50.752425713565223],
            [-3.687669984799911, 50.75248773863094],
            [-3.682748797040744, 50.751566008577988],
            [-3.681563367741576, 50.75013427859642],
            [-3.680768827945861, 50.749838163005123],
            [-3.680125959502321, 50.749937364108803],
            [-3.680341768359674, 50.750421695411653],
            [-3.679424016428396, 50.750524854542164],
            [-3.678969665193729, 50.750940598528764],
            [-3.679158424845346, 50.752134899878264],
            [-3.67818343842257, 50.752343196727509],
            [-3.678513805778783, 50.753097482271642],
            [-3.679340363907661, 50.753455206413442],
            [-3.679214071288944, 50.75412523424675],
            [-3.679556881945561, 50.754275881362553],
            [-3.678305923984565, 50.755132980249215],
            [-3.678632764251183, 50.755312638823021],
            [-3.67838238758149, 50.75560583071681],
            [-3.677384713053677, 50.755419637580744],
            [-3.677373968013371, 50.755713875777005],
            [-3.676588615740311, 50.755994075158235],
            [-3.676811637913445, 50.756402764360189],
            [-3.67609455848987, 50.756570460388367],
            [-3.676599212368898, 50.756726883245655],
            [-3.675962069381268, 50.756830475129554],
            [-3.676213681474512, 50.757325090833433],
            [-3.675536855254323, 50.757389680046401],
            [-3.675843134996922, 50.75774771078332],
            [-3.67518385769392, 50.757787763525869],
            [-3.675067534327041, 50.758540381874191],
            [-3.674460530733804, 50.758972689847354],
            [-3.675295634015252, 50.759329420616268],
            [-3.675164504375411, 50.759707227945491],
            [-3.674647524826084, 50.759762320155673],
            [-3.674790997420525, 50.760405084008639],
            [-3.67373417144259, 50.760828560346305],
            [-3.673886965406926, 50.76177246923934],
            [-3.673028481590706, 50.762192191643848],
            [-3.673123406130544, 50.762587436947364],
            [-3.671777891152816, 50.763983623231596],
            [-3.670254244492762, 50.763088138441823],
            [-3.668724505870782, 50.762697248821809],
            [-3.665944180564865, 50.759912196176479],
            [-3.66181543966745, 50.759636569068235],
            [-3.659102332318753, 50.758133749325872],
            [-3.659310103392035, 50.757914951592483],
            [-3.658614730857949, 50.757415822558919],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000046",
        LAD13CDO: "18UK",
        LAD13NM: "Torridge",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-4.208387301342185, 51.061668526241149],
              [-4.208645203628935, 51.061060184060231],
              [-4.209543419933446, 51.0606816585342],
              [-4.210271884539814, 51.060634602171049],
              [-4.211258492425022, 51.06115284603694],
              [-4.212311488376123, 51.061027685324746],
              [-4.21269783947645, 51.060630048275158],
              [-4.212121711354865, 51.060399932109661],
              [-4.211930076317763, 51.059044635736569],
              [-4.212467538165543, 51.057805939096703],
              [-4.21309624025147, 51.057074546155967],
              [-4.214141869990093, 51.057454945471974],
              [-4.214455431717223, 51.05726733214982],
              [-4.210203500869763, 51.052967996170231],
              [-4.210332914889318, 51.05194028227374],
              [-4.209357793648009, 51.052707892218727],
              [-4.206523595388516, 51.053240817951199],
              [-4.204818891736547, 51.054211931720992],
              [-4.202035947996745, 51.055106219765172],
              [-4.201492364145381, 51.055589525356673],
              [-4.201687194644072, 51.056026536953162],
              [-4.199750481484027, 51.057466013842429],
              [-4.197606818019349, 51.057943445580726],
              [-4.194742673379616, 51.056947745508374],
              [-4.192962322053882, 51.055541316384328],
              [-4.192016617467003, 51.055519494173858],
              [-4.190522643217354, 51.054826237189793],
              [-4.191005349662283, 51.054774015742964],
              [-4.190583299788682, 51.052151323741988],
              [-4.191474097733394, 51.05173710155362],
              [-4.190898643707175, 51.051274836003813],
              [-4.191433753104469, 51.050520134092373],
              [-4.190939590377904, 51.049935830352332],
              [-4.19133608528876, 51.048451654602445],
              [-4.190432919287717, 51.046395583902886],
              [-4.191016588275425, 51.045129139380087],
              [-4.19281926701276, 51.043061871979994],
              [-4.193389019512908, 51.04289198888894],
              [-4.194875481906326, 51.040778462746587],
              [-4.195631031397636, 51.039560925525016],
              [-4.196787238228741, 51.038973491328633],
              [-4.196722011613625, 51.038529537053833],
              [-4.199658813080823, 51.036445320158023],
              [-4.202083244061446, 51.035232271952168],
              [-4.20312913259345, 51.033465997435023],
              [-4.202784212814878, 51.033080383353273],
              [-4.203692757604321, 51.03202808465614],
              [-4.203388718158302, 51.031602129267313],
              [-4.203108702494015, 51.02822492774466],
              [-4.202782445971107, 51.028231980900713],
              [-4.203049772640804, 51.027794347598821],
              [-4.202109382752945, 51.025346042937869],
              [-4.202100612824363, 51.024078113366251],
              [-4.204706536505781, 51.016638016706871],
              [-4.204798211382951, 51.014904119337587],
              [-4.205533446881256, 51.013783124007354],
              [-4.206163800621586, 51.010753863714264],
              [-4.206599945999501, 51.007809210555664],
              [-4.205285907241215, 51.005051410635424],
              [-4.197316247363402, 51.001945962698564],
              [-4.194744062911903, 51.000539171048885],
              [-4.193356833318991, 51.000198297481099],
              [-4.193145691120217, 50.999589798968181],
              [-4.193870272780388, 50.997547230316918],
              [-4.195369633237733, 50.996098959119053],
              [-4.195698632594006, 50.99594347903863],
              [-4.196562336525234, 50.996427279295162],
              [-4.197083324126183, 50.996353622846073],
              [-4.199215424148948, 50.994907783317082],
              [-4.199038804542458, 50.994578345921546],
              [-4.198310512776373, 50.994480279813722],
              [-4.197957351136261, 50.993979947287116],
              [-4.199667830643948, 50.992239848345648],
              [-4.201117743042878, 50.991801516052135],
              [-4.202380052735824, 50.990622032065446],
              [-4.203542529421369, 50.990434616478922],
              [-4.202444961354912, 50.990458023075931],
              [-4.198790407139819, 50.99270065951449],
              [-4.198786871844534, 50.993108137027782],
              [-4.197760945738004, 50.993985442972026],
              [-4.198190888766723, 50.994464171436022],
              [-4.198696623147344, 50.995027275694689],
              [-4.197845143557504, 50.99567645653579],
              [-4.196934502365469, 50.996190041175154],
              [-4.195933657048647, 50.99572950817835],
              [-4.195451416730794, 50.995808726534257],
              [-4.193903580556484, 50.996984504067996],
              [-4.192965840059933, 50.998526535396529],
              [-4.192903590907325, 51.000319230168436],
              [-4.192460162982819, 51.000315865106252],
              [-4.192250705097541, 50.999470801700575],
              [-4.188486811638406, 50.999173567166672],
              [-4.185935663915537, 50.999357156274264],
              [-4.182125588093999, 51.000139812150842],
              [-4.180951977213081, 51.00006640594372],
              [-4.179761198069301, 50.999687526513945],
              [-4.17783811739486, 50.997701651209638],
              [-4.177697608032221, 50.997349025091481],
              [-4.178182617185767, 50.997084560857424],
              [-4.177651319741156, 50.997121451242656],
              [-4.177938951465135, 50.996355229169566],
              [-4.1782806492484, 50.995800246364986],
              [-4.17894760869774, 50.995708658704686],
              [-4.178279659355717, 50.995687844960258],
              [-4.178629780402051, 50.995191162224856],
              [-4.180413246865644, 50.993502151761746],
              [-4.181111463540635, 50.993591638417641],
              [-4.182627033662626, 50.991814962629142],
              [-4.183896092405643, 50.991558299097292],
              [-4.186121248347743, 50.992027495808351],
              [-4.18583114478805, 50.992282949178438],
              [-4.190252853534604, 50.992489684049808],
              [-4.193520535207612, 50.99200654221589],
              [-4.197207909730619, 50.99021313530298],
              [-4.197746200295687, 50.989361201472171],
              [-4.197047132818075, 50.988076578084623],
              [-4.197192185284746, 50.988673723195468],
              [-4.195411022821824, 50.988342086783042],
              [-4.193247928292885, 50.987192883465191],
              [-4.193471647531448, 50.985984434973837],
              [-4.192721696761567, 50.985369867510968],
              [-4.189009925908567, 50.984490689972652],
              [-4.188079742463319, 50.983435187339218],
              [-4.188232653347325, 50.982596814372741],
              [-4.190003538746902, 50.982140910394968],
              [-4.189361501275754, 50.978185864027878],
              [-4.187755749967299, 50.977575618721751],
              [-4.185547831993423, 50.976063772780464],
              [-4.183306788797637, 50.975846687500827],
              [-4.185216216319748, 50.976221972440733],
              [-4.189218713401523, 50.97857256941284],
              [-4.189592981961437, 50.982136019600382],
              [-4.187840827566708, 50.982444970336111],
              [-4.187537126984687, 50.983349123404665],
              [-4.188073427734337, 50.984118820627913],
              [-4.188882425014115, 50.984657663569593],
              [-4.192692253816692, 50.985742756367721],
              [-4.193046158808965, 50.986147117978952],
              [-4.192683975571573, 50.986927371593609],
              [-4.192924938037274, 50.987444476044345],
              [-4.197251659744052, 50.989480231246837],
              [-4.194246828647811, 50.991331864957502],
              [-4.191743583632266, 50.991917597742649],
              [-4.188401769760143, 50.991946103302702],
              [-4.184417037228553, 50.990999045143305],
              [-4.182282614533132, 50.99133844396848],
              [-4.178675092526295, 50.993816095944794],
              [-4.178043215951678, 50.993925914381236],
              [-4.178626894449503, 50.994004061973641],
              [-4.177019264548218, 50.996010837357375],
              [-4.17684323486602, 50.997395534065824],
              [-4.178809108112906, 51.000052447844325],
              [-4.181616359174301, 51.000799563578013],
              [-4.184043337496108, 51.000853124751686],
              [-4.186669696658673, 51.001418234884383],
              [-4.190209568344899, 51.001162128076992],
              [-4.186277280138692, 51.001226824289098],
              [-4.184724125456035, 51.000842188212424],
              [-4.188339047412365, 51.000273555299863],
              [-4.19008820184514, 51.000431426218931],
              [-4.19357564684524, 51.001543227448174],
              [-4.199131075210236, 51.004092747821517],
              [-4.202131602916363, 51.004995819485757],
              [-4.20321964893733, 51.005696583217301],
              [-4.204205421708279, 51.007426337420334],
              [-4.204268559370223, 51.008427033346784],
              [-4.202205439658112, 51.015760665762684],
              [-4.199382714741965, 51.020767527750778],
              [-4.198885551177339, 51.021259844808029],
              [-4.198460361091981, 51.021013331180939],
              [-4.19809842789939, 51.021229694237967],
              [-4.197675534619703, 51.021152213860745],
              [-4.196403279999941, 51.022382186590647],
              [-4.195858188994336, 51.022042586503211],
              [-4.196224726991091, 51.021531154584977],
              [-4.194518493318784, 51.020729523650409],
              [-4.193162151020402, 51.020723524859967],
              [-4.191981429550174, 51.02126641962429],
              [-4.191333177955888, 51.021006079534011],
              [-4.190145807929212, 51.021469037495685],
              [-4.188041153889627, 51.021318723041269],
              [-4.187436391890508, 51.021557588993261],
              [-4.187148168410427, 51.022280673596065],
              [-4.187832428538646, 51.022397364594354],
              [-4.188266535978732, 51.022894670019362],
              [-4.186534839129158, 51.023580036340462],
              [-4.18609366024393, 51.02238675514436],
              [-4.186634150208421, 51.021933254105392],
              [-4.185861853465647, 51.021096919980749],
              [-4.185364215666796, 51.021097239368267],
              [-4.183899656330865, 51.021731700759133],
              [-4.183956345352945, 51.022937576637212],
              [-4.184633598818765, 51.023026537369844],
              [-4.184354286003851, 51.023303367302375],
              [-4.182561985881605, 51.023216361633743],
              [-4.182231246696958, 51.022398731999878],
              [-4.181055492239458, 51.022230931156919],
              [-4.179265801638077, 51.023232046610936],
              [-4.179713325641041, 51.0238955136494],
              [-4.178369989506402, 51.024320790158782],
              [-4.178177231394387, 51.023859418648051],
              [-4.178705423117633, 51.023445756028792],
              [-4.178186506357409, 51.023418561591974],
              [-4.17798920695221, 51.02371183351557],
              [-4.177331894586017, 51.023591884423098],
              [-4.177275414735742, 51.023269169501788],
              [-4.176547675028353, 51.023563332949756],
              [-4.175765914712945, 51.023465480066129],
              [-4.175774702443229, 51.023196409209618],
              [-4.174388211618462, 51.022791439912481],
              [-4.173847201161887, 51.021590979422143],
              [-4.172925335673043, 51.020752848311069],
              [-4.172268594993526, 51.019058089720197],
              [-4.171016224409155, 51.018316026276771],
              [-4.169352244947435, 51.017857698090914],
              [-4.168220026276526, 51.016816585953372],
              [-4.167376235724633, 51.015233195545967],
              [-4.166008703468946, 51.014524693760968],
              [-4.16032231185836, 51.014420472476701],
              [-4.158173188425243, 51.014715624813029],
              [-4.15498102748392, 51.014378856883887],
              [-4.154373133500819, 51.015997217205729],
              [-4.151409080892823, 51.01606175124693],
              [-4.151375207050629, 51.016377148788528],
              [-4.149569259593003, 51.016542608043309],
              [-4.148476413427161, 51.018778722199073],
              [-4.14412112373477, 51.018516109530708],
              [-4.14223918665189, 51.017870735271281],
              [-4.142657457623982, 51.017015872844823],
              [-4.13970898608317, 51.017513310748427],
              [-4.138334741899325, 51.018417146810705],
              [-4.136183390204222, 51.01904289390766],
              [-4.135186494920564, 51.020682652762055],
              [-4.132479285659478, 51.020198800632656],
              [-4.127291149925484, 51.020650457752126],
              [-4.124225566569742, 51.020185532725101],
              [-4.12209669256295, 51.019404927255231],
              [-4.116064798524442, 51.019301226831217],
              [-4.115656461809743, 51.018537883170183],
              [-4.115989289867907, 51.016954396323335],
              [-4.117459505465736, 51.015457314023728],
              [-4.11949775439575, 51.014288059929584],
              [-4.12018075375429, 51.011664850599999],
              [-4.120938427369458, 51.010898336800544],
              [-4.120670591282826, 51.010777291808914],
              [-4.119106000485741, 51.010418073313858],
              [-4.119593369168129, 51.011226737527224],
              [-4.119224537115494, 51.011423193997565],
              [-4.116293978948823, 51.010433091981803],
              [-4.109786969285636, 51.011142600320525],
              [-4.109949380449911, 51.010983179449774],
              [-4.108788033048606, 51.010806299428445],
              [-4.107054652129139, 51.010904138642452],
              [-4.106452495022462, 51.010200019667984],
              [-4.100471593155588, 51.009966893833806],
              [-4.098223869344966, 51.009597197857637],
              [-4.09704005532496, 51.008446611904688],
              [-4.096361272957799, 51.00673474982635],
              [-4.094345122879187, 51.006027164947625],
              [-4.093855426621896, 51.005440578295556],
              [-4.093551002992921, 51.003776847311322],
              [-4.091123681222, 51.003842822177035],
              [-4.086103616799331, 51.002734678280326],
              [-4.082178454440346, 51.002677024451422],
              [-4.081317116293103, 51.002954089081179],
              [-4.079225747267334, 51.002467925460849],
              [-4.077878249652495, 51.00267897404882],
              [-4.079146397545903, 51.003052114497358],
              [-4.080922343043393, 51.005184307939444],
              [-4.080345129288946, 51.007352122416243],
              [-4.079230544580384, 51.008694010058377],
              [-4.078485947145979, 51.008779212385058],
              [-4.077596041714767, 51.008547727622179],
              [-4.069084805820679, 51.008433528828647],
              [-4.066068940876115, 51.00895907876675],
              [-4.065371914685912, 51.009537994584363],
              [-4.064199585968893, 51.00952634555518],
              [-4.063956616767589, 51.010314867229447],
              [-4.058978984671112, 51.00900695217917],
              [-4.057424961308025, 51.008326557803393],
              [-4.049228855554649, 51.008373536412556],
              [-4.047984318831912, 51.00879287704408],
              [-4.047331743186564, 51.009609229755291],
              [-4.045808156728016, 51.007608814304781],
              [-4.043626651166432, 51.008181218424198],
              [-4.037887071477316, 51.007553050314513],
              [-4.034276629242748, 51.007625890288416],
              [-4.032794695976756, 51.002847419373154],
              [-4.033690202112775, 50.999043803531634],
              [-4.033547911646472, 50.997093811455159],
              [-4.035360689794063, 50.996930025636964],
              [-4.036012106476356, 50.995663188395788],
              [-4.037291515966416, 50.995134544310616],
              [-4.036834576924632, 50.994528264837712],
              [-4.036197039023985, 50.994614029024227],
              [-4.035855942119031, 50.993821360585478],
              [-4.036498513223894, 50.993297530714784],
              [-4.035847710028234, 50.99285201317317],
              [-4.036014712117264, 50.992500156590687],
              [-4.035527242306961, 50.992530239397432],
              [-4.035104706919919, 50.991992602833086],
              [-4.035463381353193, 50.991819972801984],
              [-4.03533380158551, 50.990421955261205],
              [-4.035775048043303, 50.990410665859606],
              [-4.035686618718305, 50.99005067179553],
              [-4.036357014749143, 50.989740400596638],
              [-4.037352108884972, 50.98815728625447],
              [-4.036931708597089, 50.988253655691629],
              [-4.036790390176105, 50.987922464572684],
              [-4.036772371618883, 50.986437063939853],
              [-4.037489016570318, 50.985199655188062],
              [-4.036243400309258, 50.982294920229158],
              [-4.035746552876111, 50.982140802712919],
              [-4.036220871067655, 50.981584831500584],
              [-4.035637220188955, 50.980846754456088],
              [-4.034267073610878, 50.980236596813697],
              [-4.034366850643543, 50.979781589061439],
              [-4.033068664516667, 50.979350032457191],
              [-4.03306126403771, 50.978985027719844],
              [-4.031425725733539, 50.978591703087503],
              [-4.031443054020126, 50.978335987972358],
              [-4.030558331763888, 50.978367565952354],
              [-4.029046099139117, 50.977043941078811],
              [-4.028040796397335, 50.976988560546403],
              [-4.027922506217795, 50.976104761720578],
              [-4.026539331683498, 50.974404736112874],
              [-4.024833553322623, 50.973439657375799],
              [-4.023051803036297, 50.973248406533862],
              [-4.019704205511827, 50.972095848369236],
              [-4.019673007702436, 50.971048653072472],
              [-4.020254383443372, 50.969905421579419],
              [-4.019991387347861, 50.969457602335147],
              [-4.018510401515265, 50.968676505573974],
              [-4.015617936744195, 50.968748961862545],
              [-4.014604002813178, 50.968259232925291],
              [-4.013031211799243, 50.968085808637781],
              [-4.012911748139437, 50.967760507539715],
              [-4.011799252529486, 50.967394764409221],
              [-4.010285819731286, 50.967539547166865],
              [-4.007396853655385, 50.96888071190854],
              [-4.004900980401858, 50.969530655018644],
              [-4.006464318952945, 50.972458136289063],
              [-4.006069488178986, 50.972782388593217],
              [-4.003835578837359, 50.971945691242027],
              [-4.001209165311312, 50.972645459301106],
              [-3.999824032486212, 50.972664696180004],
              [-3.992776067396071, 50.974495749573634],
              [-3.992257745058622, 50.974267169398921],
              [-3.991725304722574, 50.974407557102317],
              [-3.990711852852761, 50.975790937921502],
              [-3.989921433572824, 50.978293782898277],
              [-3.988069826313665, 50.979502559546603],
              [-3.985592203382318, 50.980332540010522],
              [-3.983629867524868, 50.982123200571323],
              [-3.980792485813905, 50.98103370712731],
              [-3.976388687589161, 50.980527359984137],
              [-3.973690805368038, 50.982082082255175],
              [-3.9702633040794, 50.980744198241965],
              [-3.967678954436042, 50.980878611407718],
              [-3.965819910280256, 50.980612245056257],
              [-3.962882246816916, 50.97899070104657],
              [-3.961031513421712, 50.978817650143561],
              [-3.960679553328455, 50.978917091809635],
              [-3.961422289073802, 50.979774274836956],
              [-3.96105978578106, 50.981077205627066],
              [-3.958729950036763, 50.98164062499896],
              [-3.958066241431227, 50.981340587062171],
              [-3.957832003041792, 50.980750953758907],
              [-3.958422177979591, 50.978491818726674],
              [-3.958226748553744, 50.977774728749715],
              [-3.95438123861269, 50.977455143921524],
              [-3.952894011432001, 50.976606774038842],
              [-3.952570616115056, 50.975961064010257],
              [-3.953307012687667, 50.974364117849731],
              [-3.957852186955112, 50.972224013500281],
              [-3.958339805878022, 50.97172749599023],
              [-3.958001300697763, 50.968725791777651],
              [-3.957138715257359, 50.966909204936265],
              [-3.957519809240333, 50.96611589595301],
              [-3.958144905167267, 50.965740282504726],
              [-3.960589301998302, 50.965582345024025],
              [-3.963280463474693, 50.964386867600112],
              [-3.964765819153339, 50.963440957464861],
              [-3.966065865942996, 50.961649173159266],
              [-3.966408014531266, 50.959735913024325],
              [-3.965670836603616, 50.958262619609727],
              [-3.964004712007103, 50.957214152655922],
              [-3.960763231368076, 50.956151662052321],
              [-3.961662400969261, 50.955255204045983],
              [-3.963638414769387, 50.954436859744469],
              [-3.967086439637216, 50.954423777434179],
              [-3.971498817924295, 50.953343881607097],
              [-3.973280774568026, 50.953428889449562],
              [-3.974498830924256, 50.953848071243826],
              [-3.976725999702074, 50.952814809393288],
              [-3.978501268312238, 50.952474462292649],
              [-3.980086856188377, 50.953131977174635],
              [-3.984563094996093, 50.952457903388556],
              [-3.989023759078753, 50.9506552474353],
              [-3.989542906591565, 50.949943114533163],
              [-3.990442351242931, 50.949934976248386],
              [-3.991318477210617, 50.949314776155738],
              [-3.993871835300437, 50.948432103843352],
              [-3.994265315760774, 50.947946032113371],
              [-3.994251042425478, 50.946149387063684],
              [-3.994911990678359, 50.944995027105115],
              [-3.995113589501329, 50.942821463766478],
              [-3.995940974450452, 50.941699326557789],
              [-3.993863149033392, 50.938948667558101],
              [-3.993203913826864, 50.936880641164144],
              [-3.997622675409515, 50.93557029928818],
              [-3.996014142879551, 50.93397000170922],
              [-3.997914318448236, 50.930852734099247],
              [-3.996521711989878, 50.926847491370694],
              [-3.996632762483871, 50.925984916716757],
              [-3.995929337635713, 50.925575154532879],
              [-3.988053444851758, 50.926516289193934],
              [-3.981584105979163, 50.926307038115667],
              [-3.978688392214022, 50.925269757258491],
              [-3.978940865774978, 50.925686370163888],
              [-3.978232491202954, 50.925960987598991],
              [-3.977088405425957, 50.925491130032448],
              [-3.966797457267247, 50.92400661145566],
              [-3.965381090444704, 50.92267695851848],
              [-3.963560312304401, 50.9217093340107],
              [-3.960080911394158, 50.920783038999801],
              [-3.958681989146313, 50.921013373735619],
              [-3.957479510599695, 50.920432786727744],
              [-3.954954039054667, 50.918838312512086],
              [-3.954184807876295, 50.917695539054726],
              [-3.953359782942539, 50.91797105947942],
              [-3.952747166795996, 50.917849107892671],
              [-3.952491740703422, 50.918170849513629],
              [-3.949705271244232, 50.918289380486328],
              [-3.949365686212149, 50.918674575664497],
              [-3.948867063710875, 50.918488645002277],
              [-3.948953057790475, 50.918810073003463],
              [-3.947486658037934, 50.918616910745889],
              [-3.947255315356996, 50.918868989391676],
              [-3.945790279849603, 50.918810684424869],
              [-3.94288918717418, 50.920140578049725],
              [-3.942286371204078, 50.920014811052937],
              [-3.940970965548328, 50.920338873368955],
              [-3.940948997055203, 50.920939099504714],
              [-3.939150903138446, 50.920639829765285],
              [-3.936930538048538, 50.921648887679197],
              [-3.933121903905651, 50.921519574200786],
              [-3.932889046949473, 50.921771648808587],
              [-3.931506373183846, 50.921848499772416],
              [-3.931973076914851, 50.922260767138901],
              [-3.931437315577524, 50.92248277815753],
              [-3.930748068282296, 50.922331401677873],
              [-3.930231566184113, 50.922571974659022],
              [-3.929756867234001, 50.922310022402762],
              [-3.929122325948804, 50.922758490924885],
              [-3.92839156865387, 50.922394643098379],
              [-3.927084559609309, 50.922856907388109],
              [-3.925272073768367, 50.922760012940273],
              [-3.925064045592536, 50.923028749487159],
              [-3.924374822233827, 50.922843160146883],
              [-3.924478090877149, 50.923173313637818],
              [-3.923582552012617, 50.923056773133212],
              [-3.923704102425456, 50.923416304157996],
              [-3.922743401378689, 50.923237877461311],
              [-3.922718582559958, 50.922981075315398],
              [-3.921270436724849, 50.922989634111467],
              [-3.919599174146391, 50.92393805646882],
              [-3.918314477961224, 50.924184912775146],
              [-3.915853448407582, 50.924200154706114],
              [-3.915223320836166, 50.923893929389699],
              [-3.914368356393244, 50.924418779855237],
              [-3.912984274616672, 50.924221135679424],
              [-3.910406574955558, 50.924445022559006],
              [-3.910327468471841, 50.924841126585605],
              [-3.909779221014186, 50.924622566276028],
              [-3.908633815858724, 50.925388651998219],
              [-3.907237141968598, 50.924848498065522],
              [-3.905301464669582, 50.924941267645913],
              [-3.904457335202582, 50.925177180644965],
              [-3.90447834949532, 50.925482612308009],
              [-3.90516288302394, 50.92645981191162],
              [-3.905752589152329, 50.926439392646259],
              [-3.905444983201124, 50.927025386424368],
              [-3.906075520773058, 50.927307375493335],
              [-3.905093889096684, 50.927557229588565],
              [-3.904396078548697, 50.927404938042059],
              [-3.901953379848819, 50.925920392799426],
              [-3.901821820901965, 50.925416210036467],
              [-3.902687657332212, 50.924700615840102],
              [-3.901360726169335, 50.924228503964358],
              [-3.901180135632965, 50.923882503093509],
              [-3.902783826945105, 50.922977717120887],
              [-3.901967172744242, 50.920921653410623],
              [-3.903461343635707, 50.919462851162116],
              [-3.902499957651498, 50.918182581123226],
              [-3.903235989566325, 50.91795024290699],
              [-3.90477092845479, 50.918433327818498],
              [-3.905918916257827, 50.917628564891338],
              [-3.905744041190383, 50.916552214712802],
              [-3.904783005177346, 50.915350199621919],
              [-3.903741939959333, 50.915880708327975],
              [-3.903521668706198, 50.916548912036077],
              [-3.902320034095818, 50.916213266095212],
              [-3.903459053920244, 50.915014763068292],
              [-3.903739098227494, 50.913475924013319],
              [-3.902388277883812, 50.913177786715515],
              [-3.902093957829452, 50.912832741354073],
              [-3.903751324197872, 50.911823639567331],
              [-3.903758523072186, 50.910466419869906],
              [-3.905023356277388, 50.909461903017387],
              [-3.904025916293353, 50.908200219814127],
              [-3.904415871426217, 50.906672174637301],
              [-3.904136674973994, 50.905093892833449],
              [-3.907010597705715, 50.904313102941927],
              [-3.911893857445909, 50.903907639790745],
              [-3.912265038912399, 50.903039090188429],
              [-3.913082640958722, 50.903380929207465],
              [-3.914818350651202, 50.902700439393215],
              [-3.914845115977297, 50.90220895991682],
              [-3.916090084065013, 50.901597657425015],
              [-3.915885596405323, 50.901016442907952],
              [-3.916824104712105, 50.90066647565753],
              [-3.916645710863452, 50.90044367107275],
              [-3.917646619540848, 50.899812976849418],
              [-3.920351771483132, 50.899333209543251],
              [-3.920225773269588, 50.897722762194647],
              [-3.917186804219766, 50.895128664283092],
              [-3.915431052360962, 50.895174585268201],
              [-3.914830730606515, 50.894718578591302],
              [-3.912709239725125, 50.889864547173552],
              [-3.910636402268753, 50.887925349157108],
              [-3.908747033248443, 50.88674845423386],
              [-3.907730083954479, 50.885312647076326],
              [-3.90801669406078, 50.885124494730555],
              [-3.902483102374081, 50.885886659568115],
              [-3.899952134511136, 50.885496215050892],
              [-3.900389952930645, 50.884586145716867],
              [-3.899565434729559, 50.88291060723099],
              [-3.899940257504999, 50.881326158503548],
              [-3.898553494261265, 50.877532820949739],
              [-3.89939363141103, 50.877044293955414],
              [-3.902534817789827, 50.876513764868037],
              [-3.904117888721457, 50.874794475599543],
              [-3.906592357049867, 50.874057973084959],
              [-3.907401846474801, 50.873518625534757],
              [-3.91166377232474, 50.87370788270718],
              [-3.917533874042177, 50.871798472242645],
              [-3.925909027055219, 50.869609110446689],
              [-3.92505715581468, 50.867733641873166],
              [-3.924683408425309, 50.867837837175863],
              [-3.923546931196761, 50.86664156283075],
              [-3.922719336074343, 50.866802698044879],
              [-3.921185480488403, 50.865997869858667],
              [-3.918995199838478, 50.862842136004517],
              [-3.909754546962835, 50.860766990264125],
              [-3.908027330752925, 50.859496596360295],
              [-3.907501589274187, 50.858626532554013],
              [-3.907036628107501, 50.858723169584401],
              [-3.904805096361504, 50.855743219776421],
              [-3.904471927408261, 50.854505762385777],
              [-3.904885491906366, 50.853114015782559],
              [-3.903852398563729, 50.851748584776182],
              [-3.903523033475031, 50.847951519612636],
              [-3.901422947342983, 50.848115276610557],
              [-3.900720040579842, 50.847703139542119],
              [-3.899684951536154, 50.8478558024363],
              [-3.899522376750069, 50.847525691485693],
              [-3.896591990947142, 50.847882748557467],
              [-3.89558310082821, 50.847385620648652],
              [-3.894108674034329, 50.847564258807068],
              [-3.893014825928092, 50.846617016351509],
              [-3.891745161017308, 50.846940693760111],
              [-3.891011189248992, 50.846671099174443],
              [-3.890621719255153, 50.846981391338772],
              [-3.890289551558562, 50.846600955013933],
              [-3.889621316719568, 50.846516451130377],
              [-3.885456528048299, 50.847474195502762],
              [-3.884283137679299, 50.848179356223504],
              [-3.883875842707409, 50.847273098995743],
              [-3.885086794700705, 50.846900992453278],
              [-3.886174428648328, 50.845902224483815],
              [-3.885823559958219, 50.845514883234372],
              [-3.884978357014103, 50.845515054210708],
              [-3.885574719899426, 50.845156469483129],
              [-3.885076850968775, 50.844540370199816],
              [-3.886394608317761, 50.843578350934017],
              [-3.886846806590127, 50.842675288094277],
              [-3.888048845035421, 50.842296998510555],
              [-3.889095217117277, 50.840949023577629],
              [-3.89183480607127, 50.840660000157349],
              [-3.892264732032687, 50.840122411468641],
              [-3.895885766332547, 50.838699301526376],
              [-3.896462865316772, 50.83793986365658],
              [-3.895911724272379, 50.837272513536789],
              [-3.897028411865274, 50.836685969034825],
              [-3.89635917272295, 50.836468417122582],
              [-3.896440773321968, 50.835958958570956],
              [-3.897527413297018, 50.835542874393909],
              [-3.897597948491702, 50.835181088078905],
              [-3.899644495687247, 50.834517331172847],
              [-3.899808240454024, 50.834108158450455],
              [-3.900747367833615, 50.834292513548434],
              [-3.900903181481624, 50.833478761320755],
              [-3.902474266171123, 50.83272017602836],
              [-3.903901820664853, 50.832832684011763],
              [-3.904545565967326, 50.830785146716288],
              [-3.905657100228682, 50.829555566282963],
              [-3.909677602715668, 50.8290428292499],
              [-3.912340781167863, 50.829683599660584],
              [-3.913479633637632, 50.830307957275117],
              [-3.915983700228521, 50.830562735558765],
              [-3.916311586610928, 50.830247072611229],
              [-3.918059060969346, 50.830024088289555],
              [-3.920551750382106, 50.828168176723615],
              [-3.924833089921584, 50.827202742338301],
              [-3.926971839969526, 50.827864423980444],
              [-3.927801875187587, 50.827365956049753],
              [-3.930554544159038, 50.82752187954064],
              [-3.938330767715077, 50.825612181427815],
              [-3.940391863571342, 50.82556261950986],
              [-3.940175589389221, 50.826921544612127],
              [-3.93913052238113, 50.827504618695961],
              [-3.938789771425981, 50.829253231581902],
              [-3.940459723563023, 50.830485454641838],
              [-3.94099341721989, 50.831677211546001],
              [-3.942774284391302, 50.832909354654376],
              [-3.942190006749282, 50.834947127699678],
              [-3.943312299770061, 50.837108466932158],
              [-3.945610351027871, 50.838714174483144],
              [-3.94848268353236, 50.839781425514694],
              [-3.947664485794473, 50.840154831964988],
              [-3.950186325600269, 50.842044506336677],
              [-3.954421877085145, 50.8440457245011],
              [-3.957530258131311, 50.845954191844228],
              [-3.962071943842036, 50.849513073537359],
              [-3.958622265467795, 50.854930290835114],
              [-3.958895924652607, 50.85539156031107],
              [-3.961257967561918, 50.856320474740009],
              [-3.962370719085505, 50.855648831155762],
              [-3.967367253104696, 50.855027757468825],
              [-3.969804154409367, 50.855470485599326],
              [-3.970652953677337, 50.856225097065966],
              [-3.972297832607853, 50.85665147189107],
              [-3.97326342681241, 50.856368034291307],
              [-3.975810789259372, 50.856328513868078],
              [-3.977399287844387, 50.855521857783465],
              [-3.978005992467152, 50.85474981858691],
              [-3.985641507803448, 50.851305971799739],
              [-3.985764967308967, 50.850834406358736],
              [-3.987908034250978, 50.850248397550388],
              [-3.988838306348405, 50.850642646716125],
              [-3.989480021235973, 50.850432046675344],
              [-3.989365810327659, 50.850051768036224],
              [-3.990204104549761, 50.851221920876682],
              [-3.991763511876063, 50.852276331150549],
              [-3.995376980577759, 50.851855732679972],
              [-3.996886651226435, 50.854735720843038],
              [-3.995357115066045, 50.856216153299023],
              [-3.995710385453005, 50.856935890576047],
              [-3.99506835387222, 50.859869779608296],
              [-3.997244272286974, 50.861807531956835],
              [-3.997775324046474, 50.862794925005488],
              [-3.996123992665778, 50.863226103591984],
              [-3.996497830941096, 50.864159532734931],
              [-3.995010628073445, 50.865074437244594],
              [-3.994184427834925, 50.868757930390323],
              [-3.995000892320863, 50.869206239568946],
              [-3.995865192378809, 50.869110514585081],
              [-4.000430353820289, 50.867751643440464],
              [-4.004390091805123, 50.867061323783346],
              [-4.008342806324102, 50.865978870440181],
              [-4.010628179946474, 50.864859377327171],
              [-4.010707439954071, 50.863233772612993],
              [-4.013560337112022, 50.864142381077556],
              [-4.015444429263906, 50.864267233548844],
              [-4.015613726969019, 50.865424479602787],
              [-4.01707353925084, 50.865727513766679],
              [-4.018367942062954, 50.865556732145983],
              [-4.019547471872222, 50.865851993353054],
              [-4.019447252889772, 50.866065077288241],
              [-4.020936604310871, 50.866261427765195],
              [-4.023018580435441, 50.866063458174537],
              [-4.023837467584375, 50.864265827442345],
              [-4.02377981204632, 50.862672265056979],
              [-4.024345695514578, 50.862219962217559],
              [-4.023908742056718, 50.860366770492327],
              [-4.02632892680531, 50.860404799395241],
              [-4.028993341246854, 50.859849449945891],
              [-4.031564475062499, 50.860287657673233],
              [-4.036294245230835, 50.859357994497579],
              [-4.038985120748841, 50.860259817449403],
              [-4.04070283833956, 50.863051974826661],
              [-4.039901676671526, 50.865305393879254],
              [-4.039927991348334, 50.868544427893966],
              [-4.041522273358326, 50.867950842281999],
              [-4.046407133824632, 50.865162678124435],
              [-4.046483871142742, 50.86370256856177],
              [-4.047932879526602, 50.863561122433801],
              [-4.048899885546057, 50.86396504356911],
              [-4.051472592310348, 50.863620342298837],
              [-4.051586888463881, 50.862792718270441],
              [-4.051961443728143, 50.862649427525113],
              [-4.053026559791716, 50.86383043613553],
              [-4.054701986361132, 50.867674489356538],
              [-4.058074667911781, 50.871386960966404],
              [-4.059238795121943, 50.87035643564775],
              [-4.061023810854911, 50.871126245515079],
              [-4.062100164077685, 50.870716902149738],
              [-4.063829134978706, 50.870635064823219],
              [-4.065145741605283, 50.870099129343487],
              [-4.066592256788314, 50.870347819758948],
              [-4.066738665116405, 50.87082998247994],
              [-4.067152207456296, 50.870892805894037],
              [-4.067396314431088, 50.872169172289517],
              [-4.066465531816427, 50.872398811795946],
              [-4.068656168461697, 50.874620085810349],
              [-4.071200680869453, 50.873437246543638],
              [-4.077444757516114, 50.871708270564035],
              [-4.079310012327774, 50.871845919679139],
              [-4.084940396346758, 50.869148067251878],
              [-4.089943143825745, 50.867365945623334],
              [-4.09011103091857, 50.866289994614],
              [-4.089393262068763, 50.864667805522224],
              [-4.089401705134408, 50.861615205982751],
              [-4.086942392830594, 50.859017790837726],
              [-4.085891531076967, 50.858841423968272],
              [-4.085385020117585, 50.857744259543544],
              [-4.094720448543789, 50.852480277258053],
              [-4.103107364239919, 50.850303983821597],
              [-4.101289076286549, 50.846547710532512],
              [-4.104817970961225, 50.846014612412247],
              [-4.104207852664964, 50.844105465339808],
              [-4.102951134892957, 50.84285192398368],
              [-4.103326810797006, 50.840845849327025],
              [-4.102672296197395, 50.838988758276109],
              [-4.103306940749319, 50.838035673779331],
              [-4.102853694275176, 50.835441965816749],
              [-4.103388358617907, 50.835519563604862],
              [-4.104577479943547, 50.836569263601497],
              [-4.10628204712745, 50.836227310220231],
              [-4.1073517959233, 50.836643282267978],
              [-4.107678316061609, 50.837459407800523],
              [-4.109520787935937, 50.837435091360817],
              [-4.110703283008176, 50.838491145150314],
              [-4.112735738860589, 50.838081106706895],
              [-4.113492479960884, 50.837477408091836],
              [-4.114082904233296, 50.838127746147315],
              [-4.115061338271297, 50.837495735554668],
              [-4.116154081655415, 50.837758317067852],
              [-4.118260020738969, 50.837655333369206],
              [-4.119211512524958, 50.838310779486513],
              [-4.120991385070121, 50.841853432573267],
              [-4.122666867050915, 50.843028111639953],
              [-4.124347825461395, 50.842883287365233],
              [-4.127698507736495, 50.844064245984157],
              [-4.128913598327277, 50.84407625007826],
              [-4.129455467496915, 50.843497954422112],
              [-4.13015129088098, 50.842713586093183],
              [-4.129943409026303, 50.842016770503555],
              [-4.130917303590686, 50.841880264232543],
              [-4.131956864385551, 50.84221831826985],
              [-4.132205840730574, 50.84160399150236],
              [-4.133215323973741, 50.841469513448445],
              [-4.135022462161015, 50.838894815110486],
              [-4.135735316608026, 50.838922235567615],
              [-4.13703889792405, 50.840034267840501],
              [-4.137848160876238, 50.839933097129105],
              [-4.138605486995764, 50.839317531257791],
              [-4.139608455894985, 50.837645185168107],
              [-4.138708333584177, 50.837156245637189],
              [-4.138966614689246, 50.836561518888786],
              [-4.138414079683023, 50.835939390036792],
              [-4.138931036921488, 50.835410970751688],
              [-4.138369091923022, 50.834489522168667],
              [-4.138943755175686, 50.833080455018255],
              [-4.140716247687107, 50.832120677169868],
              [-4.14509811673518, 50.830989691850178],
              [-4.147529957780503, 50.830847804594633],
              [-4.151619216703792, 50.831295870981016],
              [-4.151574809929739, 50.83224463416942],
              [-4.152878383244161, 50.833105565409603],
              [-4.153631533241311, 50.8325601279371],
              [-4.155023901352548, 50.832912146648063],
              [-4.155920324688398, 50.831685915653026],
              [-4.156713511429179, 50.831671250186524],
              [-4.15546800003714, 50.831392984756796],
              [-4.154725913126365, 50.829927220757902],
              [-4.15582425715913, 50.829786406011728],
              [-4.158790711133818, 50.830183919831207],
              [-4.160600371959757, 50.829517244899549],
              [-4.16399316598174, 50.830903239358811],
              [-4.163864322348756, 50.829837167527586],
              [-4.162919040006037, 50.829325867796705],
              [-4.162126670650432, 50.827145175789774],
              [-4.158342205351556, 50.823171668414794],
              [-4.159273677643373, 50.822033798068666],
              [-4.159952161581737, 50.819722418023687],
              [-4.158463276828254, 50.817854991937885],
              [-4.158553920044303, 50.817357755606984],
              [-4.159160941215767, 50.81725208286052],
              [-4.158213903202856, 50.815464557596343],
              [-4.163272709328271, 50.813449742158028],
              [-4.170747933004675, 50.811146012435479],
              [-4.172752582819023, 50.809775791276799],
              [-4.172992310272935, 50.809068905924555],
              [-4.175191926050145, 50.808445993179461],
              [-4.177185201908399, 50.807172139947866],
              [-4.174306335830213, 50.805454071978552],
              [-4.173721180415401, 50.80462046070555],
              [-4.174848562257641, 50.803193700964293],
              [-4.175906363075949, 50.802683801398558],
              [-4.176229975601514, 50.801493272587301],
              [-4.17547581318359, 50.800809423703839],
              [-4.172943254326247, 50.799602007347943],
              [-4.172690990310232, 50.799120141402511],
              [-4.17099073662606, 50.798720107497836],
              [-4.168739257520495, 50.797662960279744],
              [-4.169990944033333, 50.797387843928988],
              [-4.170278678381316, 50.79695168334198],
              [-4.173807434008855, 50.797704386386329],
              [-4.17586580922706, 50.797138052803533],
              [-4.176455879826021, 50.797529064691737],
              [-4.177190884818979, 50.797496455110512],
              [-4.178514648741344, 50.797124566078672],
              [-4.178930312300076, 50.796572670664325],
              [-4.180183639463387, 50.796456604836258],
              [-4.180545237162595, 50.795932695985414],
              [-4.181709552063361, 50.79628236141167],
              [-4.182292764510296, 50.796159922997312],
              [-4.183680803881852, 50.795646467402655],
              [-4.185448226377263, 50.794414469656147],
              [-4.187276165838197, 50.793963775601682],
              [-4.187356584134724, 50.793194190495221],
              [-4.188265238497419, 50.792931607066691],
              [-4.188879694718736, 50.792264419570039],
              [-4.190733603424428, 50.789610582383446],
              [-4.187810399662, 50.789117746914734],
              [-4.184124621415336, 50.789187753041432],
              [-4.179798737971651, 50.790930778856833],
              [-4.169028540879374, 50.791560651046758],
              [-4.168122709431448, 50.788329819897577],
              [-4.167539010129335, 50.788471084049945],
              [-4.166394428103745, 50.787007472946854],
              [-4.169495014766927, 50.78628337229258],
              [-4.171503784319001, 50.78676851792541],
              [-4.173813243194825, 50.786211030756668],
              [-4.174677823698263, 50.786345104425337],
              [-4.175083608153814, 50.786009258564818],
              [-4.177453837491329, 50.785753658236011],
              [-4.179247304809961, 50.784689450385336],
              [-4.181895536890964, 50.784769430034821],
              [-4.182538658272956, 50.784291509368515],
              [-4.186863078384156, 50.782988203980722],
              [-4.187492571469182, 50.782068011913211],
              [-4.190447551134287, 50.781489983376375],
              [-4.191104770650317, 50.781619736433356],
              [-4.196900743449289, 50.779791924799007],
              [-4.203358953454472, 50.776219079736613],
              [-4.203656008042925, 50.775326665080769],
              [-4.205768785139617, 50.774919755666524],
              [-4.202952212898902, 50.769044225660728],
              [-4.203326328160596, 50.768947215772272],
              [-4.202231376525441, 50.766681651940665],
              [-4.202501321445555, 50.766571322857757],
              [-4.20135430575265, 50.76533923680379],
              [-4.198497063087277, 50.763289482350189],
              [-4.194627076868169, 50.761834341712493],
              [-4.185949148515284, 50.757676727733532],
              [-4.193895081026071, 50.757978936087568],
              [-4.193435748305637, 50.756646586540832],
              [-4.195233974781335, 50.755139527936031],
              [-4.196050093419357, 50.75658117108803],
              [-4.200926954627078, 50.754633737165989],
              [-4.210386262326281, 50.75251923751334],
              [-4.212990030723734, 50.751303313961493],
              [-4.218167970974541, 50.748164027206549],
              [-4.218844190985738, 50.746365852937942],
              [-4.220740108861178, 50.746708389794549],
              [-4.221473067022123, 50.745033234861786],
              [-4.223125671613358, 50.74362204758927],
              [-4.226797162203222, 50.742162386080928],
              [-4.227253186287585, 50.74164641133877],
              [-4.227992581618571, 50.739635613345094],
              [-4.227378383267655, 50.737296324397597],
              [-4.229131083396231, 50.73501791189986],
              [-4.227369276064564, 50.733819420312052],
              [-4.225776524732892, 50.731432264942562],
              [-4.227688582298653, 50.728782073393432],
              [-4.227912416442162, 50.727377427818261],
              [-4.231561951101555, 50.72408414412849],
              [-4.230915881026351, 50.723488519221874],
              [-4.232538741347107, 50.722635385883081],
              [-4.233355403639321, 50.722825699953731],
              [-4.233890992020487, 50.722111202154728],
              [-4.232249456985759, 50.718517169703929],
              [-4.232941016641859, 50.717776303146834],
              [-4.232735210025554, 50.717172250717354],
              [-4.233825219645963, 50.716528973279438],
              [-4.233339480094483, 50.716085884570873],
              [-4.233889381060863, 50.716031272940093],
              [-4.233499831196883, 50.715436140522641],
              [-4.234032663677226, 50.714546309994006],
              [-4.234688170732081, 50.714270215320866],
              [-4.234770194045771, 50.712421267972758],
              [-4.233935559055647, 50.712028044069399],
              [-4.233435650141307, 50.71098802358668],
              [-4.231808001277182, 50.710300587593117],
              [-4.231947267880146, 50.709803248163887],
              [-4.230284299320674, 50.709474430193303],
              [-4.229873096132073, 50.708599985756834],
              [-4.228901872143832, 50.708161671785383],
              [-4.229141105967251, 50.707412389230235],
              [-4.228528718892385, 50.706924035253408],
              [-4.22902966408583, 50.705988966796419],
              [-4.228290675071572, 50.705488643059297],
              [-4.22864077393254, 50.705255297393492],
              [-4.227977144786562, 50.704312822481874],
              [-4.228403112423394, 50.703708371733399],
              [-4.227857826665411, 50.703558705542157],
              [-4.227731221874391, 50.702113086198288],
              [-4.227273901777265, 50.702085853149704],
              [-4.227801977914573, 50.700827384449418],
              [-4.227164171006868, 50.700906134022574],
              [-4.227277620092722, 50.700371518167877],
              [-4.228307609030683, 50.69969346000515],
              [-4.22781322123519, 50.699122798572354],
              [-4.228323034712368, 50.697420370026791],
              [-4.230308570253871, 50.696737504816369],
              [-4.23098747400244, 50.695944722442256],
              [-4.231892899034921, 50.696046102501342],
              [-4.231923956233624, 50.695388042326357],
              [-4.230993193207094, 50.69499124347314],
              [-4.230024804388889, 50.693950283932814],
              [-4.228654257623106, 50.693803823604618],
              [-4.226334438189131, 50.692718524157122],
              [-4.224094779528826, 50.692128120168995],
              [-4.219543447845727, 50.691367678266168],
              [-4.216413436827104, 50.689968433375022],
              [-4.214925740383423, 50.688984897071613],
              [-4.211332238729614, 50.685521201798345],
              [-4.209136969179426, 50.681555105871446],
              [-4.212870642301049, 50.679012678889826],
              [-4.212581432575674, 50.678040518808785],
              [-4.214051604153521, 50.677668113951754],
              [-4.213722237380202, 50.677226469480772],
              [-4.215487314930473, 50.675873485813277],
              [-4.216407065939705, 50.676047563388138],
              [-4.216646695482486, 50.675756093348461],
              [-4.218177616968415, 50.675863661952988],
              [-4.218782217049307, 50.675567935447539],
              [-4.218784311924373, 50.675162263290495],
              [-4.217886968800484, 50.675012961275996],
              [-4.217739622749062, 50.674649708179551],
              [-4.219292085353784, 50.673954581213472],
              [-4.21882975744114, 50.67279505912596],
              [-4.220843297734351, 50.672454487837882],
              [-4.220908681736405, 50.671650970209988],
              [-4.222175062973169, 50.671254464359528],
              [-4.223665774144181, 50.671592075991619],
              [-4.228088769664553, 50.67135555997158],
              [-4.235000364521883, 50.671457899803919],
              [-4.237901919917244, 50.672026382170571],
              [-4.24036558895389, 50.673027736335953],
              [-4.243428336593498, 50.673589383964043],
              [-4.245407209185353, 50.672940557043859],
              [-4.247105447153108, 50.672817868490021],
              [-4.2493836119208, 50.671740476534062],
              [-4.249979638003844, 50.670797176165998],
              [-4.251653210818538, 50.669835746658393],
              [-4.252829267785107, 50.667618443890156],
              [-4.254819922568021, 50.667134717880337],
              [-4.255164005263293, 50.666430113155236],
              [-4.256454195371067, 50.666951969543327],
              [-4.256542481235488, 50.666432197937667],
              [-4.257917019951939, 50.666588142207154],
              [-4.259296807271486, 50.667413131253966],
              [-4.259915354378514, 50.667385842222544],
              [-4.262052546371724, 50.668214989466243],
              [-4.263246929877518, 50.66803708987365],
              [-4.263900164140953, 50.668348184331144],
              [-4.26634306207458, 50.668587592511571],
              [-4.269663789051212, 50.668159572556945],
              [-4.271029782882905, 50.667696732483563],
              [-4.272627446875889, 50.668046024158883],
              [-4.273623057284532, 50.667855706370226],
              [-4.275754720182848, 50.668126175414969],
              [-4.277287517302636, 50.66906398826108],
              [-4.284922256470121, 50.666021955454497],
              [-4.28973317157688, 50.666154135744648],
              [-4.290681267384389, 50.666501556834561],
              [-4.292727983137695, 50.665289348595053],
              [-4.295493171120643, 50.665695388254726],
              [-4.304012659646481, 50.664688922193392],
              [-4.305873490085922, 50.663445978689843],
              [-4.307849313746493, 50.66359752357365],
              [-4.309109648128474, 50.663067970695828],
              [-4.313141573235939, 50.662240551399925],
              [-4.314135816170207, 50.661280008938967],
              [-4.314181260605896, 50.66027625287024],
              [-4.315778800468865, 50.658838707024366],
              [-4.315074430578328, 50.656917156999462],
              [-4.316332375276617, 50.655424291558177],
              [-4.316992449991181, 50.655841984810415],
              [-4.317332463694869, 50.655621158074013],
              [-4.316698484076936, 50.655043750177413],
              [-4.317757319615665, 50.65427886271484],
              [-4.317322567285557, 50.653839601869315],
              [-4.318279418563253, 50.653475182052624],
              [-4.319165558760217, 50.653657211717238],
              [-4.319525990360789, 50.653018640173762],
              [-4.321597280169312, 50.653306558997549],
              [-4.323037911719343, 50.652282173181163],
              [-4.323026569707, 50.651421652421476],
              [-4.325549928685594, 50.649664190050444],
              [-4.326339948427362, 50.649791420553171],
              [-4.326921720164847, 50.649397543651858],
              [-4.327297119480177, 50.649694047629858],
              [-4.328661613192071, 50.649566037661678],
              [-4.329720567752909, 50.648524013904279],
              [-4.330472176023514, 50.648961386371617],
              [-4.331572627118198, 50.648844021890298],
              [-4.331444348611474, 50.649253129374486],
              [-4.332456358272362, 50.649178001080514],
              [-4.332303034221329, 50.648653109953585],
              [-4.33172123159963, 50.648702535526574],
              [-4.331716273419548, 50.648202555237177],
              [-4.332910276679219, 50.648060819303907],
              [-4.332895593436357, 50.64767885827667],
              [-4.334484918626681, 50.647208092141668],
              [-4.335097824410594, 50.646445684931045],
              [-4.335341206013634, 50.647331234848323],
              [-4.334616300425694, 50.649115835226432],
              [-4.335718879701042, 50.651322500309234],
              [-4.335356031027891, 50.652249887132818],
              [-4.336824104093377, 50.652668353368163],
              [-4.336635210608817, 50.654335177528814],
              [-4.335573916863509, 50.655700203318801],
              [-4.338027584160175, 50.657016298309934],
              [-4.339555741306885, 50.658582987902818],
              [-4.338819040400232, 50.661269070198991],
              [-4.339459284813529, 50.661509849408617],
              [-4.342153877274617, 50.661460192343746],
              [-4.343329362513007, 50.661878165539214],
              [-4.344429204230932, 50.66285079499022],
              [-4.345181894244281, 50.664807178200348],
              [-4.345841472945733, 50.668045310595055],
              [-4.34551760111998, 50.669185105528925],
              [-4.344486278681287, 50.670329247137872],
              [-4.344382279930147, 50.671281130357677],
              [-4.346736521367163, 50.672455139244398],
              [-4.347032950831616, 50.672921363562857],
              [-4.34465472353373, 50.675497533790107],
              [-4.344376168687424, 50.676781218512332],
              [-4.345206917622082, 50.677828511800968],
              [-4.345393091819516, 50.680000463889982],
              [-4.346545375781804, 50.679971861431056],
              [-4.346772188793015, 50.68023352103372],
              [-4.346172982445446, 50.682069617345277],
              [-4.346573838646427, 50.682442897769015],
              [-4.34754596518395, 50.682434104327356],
              [-4.348453402498828, 50.683085882753687],
              [-4.348408683357641, 50.685138363910887],
              [-4.349107871768678, 50.68595623113346],
              [-4.348622051627376, 50.688927819267228],
              [-4.348910637397386, 50.689546198709905],
              [-4.350314576989618, 50.691006423717482],
              [-4.351724846584817, 50.690780090672661],
              [-4.352796367457271, 50.691169490282235],
              [-4.352775823499697, 50.69206033170915],
              [-4.353512961209501, 50.69264265763335],
              [-4.352354021274459, 50.69336491457387],
              [-4.352611077201724, 50.693776155484002],
              [-4.35355364687339, 50.693370366415259],
              [-4.353954018636371, 50.693617712748917],
              [-4.353860527747726, 50.69412597554529],
              [-4.354797048983227, 50.694617021005499],
              [-4.355937859656139, 50.694663211806045],
              [-4.358966999680241, 50.695570591720276],
              [-4.35958694024521, 50.696241597184589],
              [-4.360947973586482, 50.695793993501709],
              [-4.362222712886147, 50.695996606315241],
              [-4.362155257031321, 50.696795760756899],
              [-4.364225232082633, 50.697407651500654],
              [-4.364320235243896, 50.697804167213555],
              [-4.366708184975748, 50.69786720949859],
              [-4.366177589538979, 50.700463823875296],
              [-4.366960283839886, 50.701297878077071],
              [-4.367175038178742, 50.702440276934212],
              [-4.367987355600194, 50.70270888593577],
              [-4.368376557117926, 50.701804251688699],
              [-4.369307541310939, 50.702291696789779],
              [-4.368663894940554, 50.702643866301919],
              [-4.368428604218653, 50.703398767038919],
              [-4.367316524932098, 50.703646224083272],
              [-4.367042206615789, 50.704020560254563],
              [-4.368708263030569, 50.705855696533177],
              [-4.367227104876747, 50.70732487313613],
              [-4.368776114362499, 50.709167782975193],
              [-4.367861270027986, 50.709312297767561],
              [-4.366684941639169, 50.71112694369053],
              [-4.364891728402256, 50.712198586694541],
              [-4.365677048766532, 50.712349936457429],
              [-4.3649241844094, 50.712899475198711],
              [-4.365429509594392, 50.713341625465503],
              [-4.363563069302917, 50.714564936487562],
              [-4.36384386050239, 50.715443369224815],
              [-4.36344547487454, 50.717098286818292],
              [-4.362575664321952, 50.718225809258442],
              [-4.36147522372444, 50.718796762044299],
              [-4.364709958207213, 50.72046164116631],
              [-4.36423247816166, 50.721249327210337],
              [-4.366002504025639, 50.722381744536555],
              [-4.366610901007538, 50.722392774286597],
              [-4.368124902527904, 50.723335185612925],
              [-4.369142806976511, 50.723290209819673],
              [-4.369939354250764, 50.726186329349204],
              [-4.371008431070413, 50.727097278227085],
              [-4.370392590309995, 50.728567764419758],
              [-4.371210286670727, 50.728484571112091],
              [-4.371386515261896, 50.729644832168582],
              [-4.37174270159399, 50.729205862367046],
              [-4.372328844633773, 50.729419684907924],
              [-4.372394106120445, 50.730682938372247],
              [-4.371751707591628, 50.730702310964261],
              [-4.371341126865023, 50.731271004316156],
              [-4.372361887744853, 50.73172781873906],
              [-4.371223326097204, 50.733427507166788],
              [-4.373383354007243, 50.734804614479899],
              [-4.373853207440078, 50.737761323650481],
              [-4.37542073828368, 50.738964282453232],
              [-4.377433610478573, 50.73955552624782],
              [-4.379445875999498, 50.741000295457951],
              [-4.378930735538688, 50.742665740762384],
              [-4.379374035439985, 50.744167712106261],
              [-4.380491881203671, 50.74488240629335],
              [-4.381547322275166, 50.744925600882219],
              [-4.382206009320756, 50.746004930897115],
              [-4.382986993650014, 50.745695754192276],
              [-4.383749815399027, 50.745922097224877],
              [-4.383458069232466, 50.746597230969009],
              [-4.384903257645918, 50.747725216360159],
              [-4.385157484241702, 50.748766036323673],
              [-4.384196426483674, 50.748852258000333],
              [-4.385231724517365, 50.750086660569963],
              [-4.38486089571722, 50.750238156198058],
              [-4.38449290503924, 50.749748308043856],
              [-4.384172329339387, 50.749940146499853],
              [-4.383591293502295, 50.751758060802906],
              [-4.384070759632416, 50.752711529389721],
              [-4.383322552960987, 50.753305157131763],
              [-4.382089674079419, 50.753624471324635],
              [-4.382504176472899, 50.754248288518866],
              [-4.381547360972427, 50.754699563776377],
              [-4.381965529170174, 50.755200087871323],
              [-4.381408295435746, 50.755116136812376],
              [-4.380799421747969, 50.755690711157143],
              [-4.38105961419584, 50.755900356286233],
              [-4.381717213773854, 50.755698942024708],
              [-4.381753008883525, 50.756096651901522],
              [-4.380787791847657, 50.756495026641197],
              [-4.381417507914568, 50.756581996866132],
              [-4.38112445719536, 50.756954046545381],
              [-4.38159697550003, 50.757379709424661],
              [-4.382064826153299, 50.757294597531612],
              [-4.384502099492903, 50.758100104119428],
              [-4.38541427420793, 50.758247830632506],
              [-4.387570692108164, 50.757712594738123],
              [-4.387854134916314, 50.757876777785697],
              [-4.386585621474644, 50.763933344897787],
              [-4.387356581833937, 50.765065210893134],
              [-4.384881475021092, 50.765392000769424],
              [-4.384883857824546, 50.766275177836086],
              [-4.384151624577687, 50.767521456832625],
              [-4.3812866036001, 50.768635036899028],
              [-4.380775812850636, 50.769329025916981],
              [-4.380179587650293, 50.769486008019982],
              [-4.381033268429047, 50.77054427224477],
              [-4.381789463195099, 50.770548610037274],
              [-4.383974992918876, 50.771494186674758],
              [-4.385168316911257, 50.772925027116813],
              [-4.386747385054914, 50.773935126587567],
              [-4.387758008956877, 50.774032252315287],
              [-4.389009497678036, 50.774791798738221],
              [-4.393185753880259, 50.777991694691892],
              [-4.393475806152571, 50.778254664491591],
              [-4.403216481152809, 50.776222142607423],
              [-4.412935293575647, 50.77521368928042],
              [-4.414387884896473, 50.774416407446537],
              [-4.41390281636667, 50.772957703887265],
              [-4.413039509496084, 50.772306411849605],
              [-4.413400024728009, 50.771760196477608],
              [-4.412723309951569, 50.77139914841996],
              [-4.413131777055334, 50.771316041069447],
              [-4.412190288628915, 50.770936186888278],
              [-4.411466022892147, 50.76995371826451],
              [-4.410107663266976, 50.769740768782867],
              [-4.409693421979023, 50.769187196974428],
              [-4.410125330792183, 50.768870666106594],
              [-4.409268806159036, 50.768183229655996],
              [-4.410028680840709, 50.76679770640694],
              [-4.409239052431189, 50.76636252258745],
              [-4.409805557991, 50.765933481363561],
              [-4.409281632753522, 50.76443149023072],
              [-4.409744419360469, 50.764247435988374],
              [-4.409003801036133, 50.763412794947236],
              [-4.408814286867853, 50.762429149892967],
              [-4.409518191737037, 50.762577395062088],
              [-4.409963225205108, 50.763317410058541],
              [-4.410844981397005, 50.763752482140475],
              [-4.411473219618162, 50.763394108948532],
              [-4.41219094480113, 50.764003454185612],
              [-4.413094127684817, 50.763696944545828],
              [-4.413578246996321, 50.764423544988432],
              [-4.414176594829688, 50.764586539916266],
              [-4.414877449904318, 50.764536943893624],
              [-4.415074814955675, 50.764101133247436],
              [-4.415314918605789, 50.764384871609252],
              [-4.416563833427438, 50.764265444698381],
              [-4.416483502175171, 50.764880514367263],
              [-4.41754282308538, 50.76515445635539],
              [-4.418694549610611, 50.76491020430219],
              [-4.419251672534823, 50.765098313115551],
              [-4.422098327745199, 50.764281880778178],
              [-4.423360616301498, 50.764778205993828],
              [-4.423735865463215, 50.764575229139687],
              [-4.42410059477095, 50.764744829976429],
              [-4.423915921544637, 50.765067964931326],
              [-4.426858871740393, 50.7669899483733],
              [-4.42689733256858, 50.767321933210638],
              [-4.428043895386548, 50.767470743217544],
              [-4.427197899055912, 50.768360801335611],
              [-4.427739479314426, 50.768959329413157],
              [-4.429510268435323, 50.768836988144344],
              [-4.429972349759216, 50.76937870070828],
              [-4.431571623406236, 50.768847968900793],
              [-4.432305289186949, 50.769156452907289],
              [-4.432275026125541, 50.769775886126254],
              [-4.433068044667797, 50.76969727521027],
              [-4.43290211124657, 50.770243989150977],
              [-4.433416801903811, 50.769994898468674],
              [-4.434014371007685, 50.770112837521111],
              [-4.433479439811579, 50.770846241977679],
              [-4.434512957616332, 50.771137658713286],
              [-4.434848120704278, 50.771992303378006],
              [-4.436181097263439, 50.77228644392585],
              [-4.436268361086528, 50.772955588262661],
              [-4.437530428406536, 50.773685616113241],
              [-4.43698750629698, 50.773883148529336],
              [-4.437411154368341, 50.774310509755047],
              [-4.438720156536444, 50.774278633103336],
              [-4.440081206263055, 50.775464368240783],
              [-4.440058871260192, 50.775171624419698],
              [-4.44088660704113, 50.775049961311062],
              [-4.440959203229047, 50.775436091759531],
              [-4.441554830391457, 50.775651170842735],
              [-4.442067999514442, 50.775345410522796],
              [-4.443427488751523, 50.776254117998803],
              [-4.443801544819065, 50.776082581634654],
              [-4.44353197904871, 50.775782429461515],
              [-4.443976306896341, 50.775734438998164],
              [-4.444563136712529, 50.776487541538884],
              [-4.444819458648165, 50.776152977309167],
              [-4.445768893178353, 50.776460449542668],
              [-4.444867377983536, 50.777153028754277],
              [-4.445877587296294, 50.777428645134421],
              [-4.446326578169741, 50.778094688959058],
              [-4.445972477234486, 50.778947576291792],
              [-4.446497901173489, 50.779120931308903],
              [-4.446205006084023, 50.780765824406764],
              [-4.44726158865016, 50.78133006983338],
              [-4.447306798986675, 50.782005485664868],
              [-4.448662755668945, 50.782623694235866],
              [-4.448585799761656, 50.783838630373928],
              [-4.450057162210092, 50.784546139867011],
              [-4.451298731767957, 50.785660504855755],
              [-4.453844612855765, 50.784289288308365],
              [-4.456401518862958, 50.783699380684318],
              [-4.462945160346666, 50.784029906148376],
              [-4.467858280024521, 50.785106039636524],
              [-4.47307295844751, 50.784982933167939],
              [-4.474824263985161, 50.785395477798929],
              [-4.476326348572218, 50.787641821469641],
              [-4.475664492968603, 50.789132734386499],
              [-4.477667044291904, 50.790026490054039],
              [-4.480124325602615, 50.790797210697448],
              [-4.483932702086241, 50.791436564725849],
              [-4.486366157828173, 50.790979946551204],
              [-4.488690084320843, 50.791106644715605],
              [-4.4891102374409, 50.791429562488986],
              [-4.492924424646877, 50.792254680916017],
              [-4.492731159147384, 50.792516049622975],
              [-4.493439395764302, 50.793158383909478],
              [-4.489665519873063, 50.795228571870737],
              [-4.489922391481199, 50.796323083194309],
              [-4.484556142529159, 50.796394401189922],
              [-4.483795671606249, 50.796076919494588],
              [-4.474411532867349, 50.797323406010094],
              [-4.475718979016898, 50.79888762741313],
              [-4.4768074173321, 50.799449133456392],
              [-4.47271114829325, 50.800275412915603],
              [-4.4745754948741, 50.801795454461988],
              [-4.472381522935371, 50.802257535463511],
              [-4.471227292585608, 50.801982499654294],
              [-4.47071411218049, 50.801375473329557],
              [-4.469949504081238, 50.801140736073371],
              [-4.463918928091525, 50.801652396886958],
              [-4.458439799897596, 50.803802553757855],
              [-4.454074591329571, 50.804168843238855],
              [-4.451722212117764, 50.804810153201799],
              [-4.452079438744004, 50.805099448887653],
              [-4.453081174542007, 50.80514673194056],
              [-4.452656089449582, 50.805458779497293],
              [-4.452895334418026, 50.805880071001823],
              [-4.451654438506232, 50.806849661972841],
              [-4.450985938663652, 50.806715313213815],
              [-4.450162834970357, 50.807342419767714],
              [-4.449659615694776, 50.807192897421793],
              [-4.449184368152054, 50.807415144604882],
              [-4.449262968355334, 50.807913570126843],
              [-4.448413153356421, 50.807786613059491],
              [-4.447798155725963, 50.80824114081534],
              [-4.447458820852681, 50.808022510606492],
              [-4.446855555010471, 50.80826722259868],
              [-4.44719063810611, 50.808621755926737],
              [-4.445185460532414, 50.810344838008056],
              [-4.445411253805208, 50.810862664082109],
              [-4.444742864930541, 50.811329087837471],
              [-4.445275480365209, 50.811442937743024],
              [-4.445060992457072, 50.8117937116265],
              [-4.445968043749191, 50.813476384350857],
              [-4.445348708885862, 50.814284460047872],
              [-4.444606401718404, 50.814586939967434],
              [-4.444625240868137, 50.815273699527587],
              [-4.443988994871614, 50.815323913690115],
              [-4.443932694708206, 50.815686659329565],
              [-4.442666980889491, 50.816161991214834],
              [-4.443087569160821, 50.816494957641652],
              [-4.443189822074325, 50.817773584149933],
              [-4.442691133543794, 50.817875772931444],
              [-4.442193610461961, 50.817456273715592],
              [-4.441251875631839, 50.818400594519353],
              [-4.441980718064329, 50.818709122275941],
              [-4.441334690822776, 50.819062629614137],
              [-4.442334054979387, 50.819820595349398],
              [-4.442384747753289, 50.820465314201833],
              [-4.442015107306404, 50.820537814197955],
              [-4.44182894697926, 50.821378169546406],
              [-4.441127240958187, 50.821508886823281],
              [-4.440993754363407, 50.822188941281077],
              [-4.44009625334199, 50.822622356781309],
              [-4.439877727890235, 50.822026120066255],
              [-4.439628928560945, 50.822564679134075],
              [-4.439098869703964, 50.822692692023168],
              [-4.439215709499534, 50.823054511087278],
              [-4.438829323156618, 50.822806259867363],
              [-4.438516820957955, 50.82315817099407],
              [-4.439351930027168, 50.824114770131736],
              [-4.438892060371835, 50.824636156730108],
              [-4.440090241415849, 50.824766688484395],
              [-4.439783824173722, 50.825643733894012],
              [-4.440524230267309, 50.825709187166304],
              [-4.441547689214972, 50.826382110526566],
              [-4.441979739729618, 50.82598091830436],
              [-4.442266524101358, 50.826278914429267],
              [-4.442930384933107, 50.826129196594231],
              [-4.442754747880063, 50.826899177869258],
              [-4.443323488586426, 50.826613839917286],
              [-4.444116815569699, 50.827827614377689],
              [-4.443614539076642, 50.827890307164303],
              [-4.443425098817942, 50.828423132970492],
              [-4.444000761401433, 50.8292484245029],
              [-4.443779996133626, 50.829589432506438],
              [-4.444229759045873, 50.829771581362166],
              [-4.443743215852932, 50.830924035576572],
              [-4.444048122081905, 50.831432110690294],
              [-4.443145618230866, 50.831473508142892],
              [-4.443319373598896, 50.832026605280753],
              [-4.442734036048553, 50.831913849568103],
              [-4.442516230509955, 50.832366320288742],
              [-4.442953970140636, 50.832752893070946],
              [-4.442561335755717, 50.833665029287367],
              [-4.443450878965508, 50.83459797399145],
              [-4.442218360787906, 50.834875628856331],
              [-4.442557728712509, 50.835445045495632],
              [-4.443635062987667, 50.835377504392639],
              [-4.442988075930264, 50.835825477125937],
              [-4.443488941759414, 50.836087505774195],
              [-4.443092121969837, 50.83653923097242],
              [-4.443363456969848, 50.836866329368583],
              [-4.442540199612167, 50.837040073602289],
              [-4.442554917365186, 50.837484975181951],
              [-4.442081830292139, 50.837727831306516],
              [-4.442414865826811, 50.837985284318719],
              [-4.441919097761832, 50.838392308029398],
              [-4.442313252672817, 50.839901364568128],
              [-4.441157026327849, 50.841370930492594],
              [-4.441513190104276, 50.842016448422854],
              [-4.440984430827381, 50.842146239642787],
              [-4.44098001061188, 50.84306823042521],
              [-4.439879040521964, 50.843121849528579],
              [-4.439661161606031, 50.843465486239488],
              [-4.44040997752473, 50.843550553205183],
              [-4.440832562025757, 50.844080457718078],
              [-4.440382832377418, 50.844282343757541],
              [-4.440497396625907, 50.844599237866539],
              [-4.441352788997166, 50.844602920258396],
              [-4.441970174403572, 50.846305174412535],
              [-4.44130371777423, 50.846655507635198],
              [-4.442240140831257, 50.846985773115023],
              [-4.440708733827068, 50.847857883466396],
              [-4.440872654616342, 50.849038079278294],
              [-4.439971359111786, 50.849626266805551],
              [-4.440419262259089, 50.849823760015802],
              [-4.44001608798709, 50.849993190478592],
              [-4.440051172350204, 50.85066521650414],
              [-4.439572725557165, 50.850726491929869],
              [-4.439164233999389, 50.851420387538489],
              [-4.436354072812318, 50.852705022719014],
              [-4.437349572478972, 50.85408460582677],
              [-4.43724988742918, 50.856150836888652],
              [-4.433622319500232, 50.859563396244518],
              [-4.432883342231399, 50.861937064601186],
              [-4.433626978044076, 50.865518288727742],
              [-4.423395306969075, 50.866445445783121],
              [-4.420929530053506, 50.866091016905187],
              [-4.420894604735654, 50.866651173061285],
              [-4.421787359443194, 50.867175882612358],
              [-4.421733956998942, 50.86754394949746],
              [-4.422089940283539, 50.867419635112739],
              [-4.423047527025507, 50.868042823108986],
              [-4.422451971295987, 50.868179311336803],
              [-4.424010450151393, 50.868713561053426],
              [-4.424025930426073, 50.869011842576924],
              [-4.423475249199104, 50.868945036764764],
              [-4.424092425692431, 50.870046577820197],
              [-4.423713694645839, 50.870554518250238],
              [-4.424137046189768, 50.87109795682494],
              [-4.42498683651561, 50.871395085863774],
              [-4.427066714813083, 50.872222454460527],
              [-4.426464753200634, 50.873111899881089],
              [-4.427446348838416, 50.874713107944622],
              [-4.429021309339093, 50.875859445373017],
              [-4.429919002036915, 50.877730403372979],
              [-4.431609205975489, 50.878711513180328],
              [-4.431760503687886, 50.879787647717116],
              [-4.432403678877737, 50.879853386061725],
              [-4.43221303436582, 50.880123585622258],
              [-4.43403128406959, 50.881185635989077],
              [-4.434865054526187, 50.881007242176267],
              [-4.434750852120207, 50.881489009843101],
              [-4.435959975728261, 50.881855908912449],
              [-4.436728911682951, 50.882505411009738],
              [-4.437834072033106, 50.882568663929973],
              [-4.439762928096898, 50.886313945094109],
              [-4.439428437940744, 50.886552087704828],
              [-4.439761356965414, 50.886555918489321],
              [-4.439552969811146, 50.886948820335306],
              [-4.439904602276634, 50.886929774217052],
              [-4.439990765902383, 50.888170053273214],
              [-4.441107133422395, 50.888852733191605],
              [-4.442372497184545, 50.889121254258001],
              [-4.4425978735007, 50.889487990804064],
              [-4.444947922076178, 50.8902392353449],
              [-4.446115257287047, 50.891105178333618],
              [-4.446179991183449, 50.892231677899275],
              [-4.446841709330062, 50.892538889608211],
              [-4.445850301527097, 50.894488007627388],
              [-4.446317794836159, 50.895701399324807],
              [-4.446848201594094, 50.896025755045684],
              [-4.446368748371635, 50.898246551439712],
              [-4.446797124239494, 50.900909704893287],
              [-4.44790461134479, 50.902011630961255],
              [-4.448654147722215, 50.902092138860162],
              [-4.450696164003422, 50.903517994898756],
              [-4.449970758027059, 50.9058474020934],
              [-4.4569298306794, 50.907528660281038],
              [-4.458489312438734, 50.907617230562316],
              [-4.458564804819662, 50.908428703788758],
              [-4.459639542414985, 50.909251494967194],
              [-4.459938552927579, 50.910766987815812],
              [-4.462653196161034, 50.913493388028733],
              [-4.463417603370269, 50.915251781794097],
              [-4.463212613476959, 50.91759276779964],
              [-4.462149848618442, 50.919399619958824],
              [-4.460497398134313, 50.920576721484331],
              [-4.458703680891273, 50.923015942817649],
              [-4.458248113731567, 50.924145300247403],
              [-4.458185370476818, 50.926760292294418],
              [-4.456164283446822, 50.928996496895465],
              [-4.458224554492546, 50.929338059424531],
              [-4.460050971364513, 50.930182785964867],
              [-4.464625512527048, 50.931023440202004],
              [-4.471508562695173, 50.931270006938391],
              [-4.481072507778156, 50.930275579820439],
              [-4.486782789655217, 50.929348161815085],
              [-4.489119682026434, 50.929321722681088],
              [-4.490348800285039, 50.928992397675522],
              [-4.492068314024356, 50.929211120768507],
              [-4.492414277153244, 50.928947400325306],
              [-4.496767468648855, 50.928702339509492],
              [-4.49792606088593, 50.928439200582112],
              [-4.49930163583149, 50.927498644048306],
              [-4.501846436615242, 50.927370359558118],
              [-4.504729027863907, 50.927675480709631],
              [-4.509546431014454, 50.927385798646171],
              [-4.51080822680677, 50.927851533199714],
              [-4.514323243169488, 50.927953931710235],
              [-4.516372696025987, 50.927323337079144],
              [-4.516657161191008, 50.927452114237887],
              [-4.517147517684476, 50.926834433984688],
              [-4.520031289572767, 50.927238986739326],
              [-4.524465865945482, 50.927319417950628],
              [-4.526684562153279, 50.927057336703115],
              [-4.528147462984529, 50.927265794668912],
              [-4.528510082823296, 50.926945840875426],
              [-4.530613508045798, 50.926658300435555],
              [-4.530714229389136, 50.926862082037751],
              [-4.531811474217454, 50.926705166622106],
              [-4.53303277994377, 50.927181434287199],
              [-4.533264602652091, 50.926812138604205],
              [-4.534723867239491, 50.926900970926049],
              [-4.537885457807453, 50.928170581897618],
              [-4.539600339660977, 50.928326646504722],
              [-4.539874760531538, 50.9286876354154],
              [-4.54046949031633, 50.928580250267103],
              [-4.540754756328999, 50.928852858278994],
              [-4.54303297783358, 50.928508222285657],
              [-4.543650991262736, 50.929088366945884],
              [-4.545560117067264, 50.928135626222392],
              [-4.54592515645975, 50.928357013600895],
              [-4.545301431853278, 50.929548856151385],
              [-4.546148159889287, 50.930146484169384],
              [-4.545819816794653, 50.93064922654429],
              [-4.54630842123375, 50.93107388332276],
              [-4.545396737033604, 50.931606440850281],
              [-4.54501297418761, 50.933961387802533],
              [-4.546394121733532, 50.934413344729037],
              [-4.546153970130038, 50.934576882080151],
              [-4.546691175907267, 50.934691977200465],
              [-4.546360324983787, 50.934784641619537],
              [-4.546898181897664, 50.935562591223587],
              [-4.547528772068557, 50.935484968180859],
              [-4.546807321506257, 50.93590545289311],
              [-4.547167124808754, 50.936029813989691],
              [-4.547276210009936, 50.937556439926119],
              [-4.547935466947735, 50.937560036002232],
              [-4.547638861408233, 50.938045000883776],
              [-4.54823828233607, 50.938203701691819],
              [-4.548412921814488, 50.938637903604423],
              [-4.549503749309613, 50.938749889091198],
              [-4.548687944672281, 50.938851354870323],
              [-4.54916434767835, 50.939207011489302],
              [-4.5507985210045, 50.939050781210163],
              [-4.550354256674511, 50.939204397730393],
              [-4.550980617425949, 50.939360697009199],
              [-4.548253927670916, 50.939452648593047],
              [-4.548462917113128, 50.940462625750527],
              [-4.550068780844951, 50.94080260241855],
              [-4.549494817129403, 50.940979736110251],
              [-4.549641100261355, 50.941155523948837],
              [-4.546101250136548, 50.941452265697627],
              [-4.546114763175261, 50.94182972523415],
              [-4.546868360618231, 50.942281876488693],
              [-4.545330536482646, 50.942171521280912],
              [-4.54523279080948, 50.942545111506909],
              [-4.546405926916453, 50.942624751260148],
              [-4.544408439489913, 50.942934545848878],
              [-4.544616402900053, 50.943327552813379],
              [-4.543995210936342, 50.943318610521025],
              [-4.543595318963476, 50.943947023724519],
              [-4.542481386506608, 50.944404814869486],
              [-4.54227108085204, 50.944803339809461],
              [-4.542857441657438, 50.94586896265389],
              [-4.542226995613898, 50.947516932826503],
              [-4.542724920416668, 50.947953094052245],
              [-4.541073134600125, 50.948471156210488],
              [-4.541218317778017, 50.949331432007817],
              [-4.541892354916753, 50.949472351925301],
              [-4.541228902027401, 50.949734138769081],
              [-4.54211626612654, 50.949974744158361],
              [-4.542251959374137, 50.950452075240555],
              [-4.543928231123447, 50.951423791718589],
              [-4.542697712287307, 50.951720037160499],
              [-4.544225287584401, 50.952821300908205],
              [-4.543426586688682, 50.952953834373076],
              [-4.543566626685138, 50.953666715447092],
              [-4.542522998980976, 50.954920751767439],
              [-4.541836029643236, 50.955222627773452],
              [-4.542151732539714, 50.955319184249262],
              [-4.540737698068602, 50.955919303397998],
              [-4.539937434415659, 50.956703021046089],
              [-4.540214688283603, 50.956877768981961],
              [-4.539598855289754, 50.956918152990923],
              [-4.53891966909056, 50.957468079771402],
              [-4.538712134466511, 50.95841517795715],
              [-4.538000971417016, 50.95913668280604],
              [-4.538045226681859, 50.959582728111606],
              [-4.538778907235532, 50.959639623983165],
              [-4.537262319338685, 50.959850685955168],
              [-4.53674522859867, 50.961730003141056],
              [-4.533000218615615, 50.965379298152172],
              [-4.532802189583795, 50.968990229702406],
              [-4.534209159314685, 50.969690012415619],
              [-4.532964877093868, 50.970304334739922],
              [-4.533129175056262, 50.971726333573102],
              [-4.533925763220507, 50.97228106839119],
              [-4.532700348801768, 50.973949984592302],
              [-4.534330688988627, 50.975504755018584],
              [-4.535174578420182, 50.975540394884348],
              [-4.533924569989525, 50.975790589555167],
              [-4.533070459741165, 50.976378452827895],
              [-4.532836338805967, 50.977311722935625],
              [-4.534738101127075, 50.978955935667862],
              [-4.535620744650004, 50.979204790580233],
              [-4.53263297958105, 50.980380420169098],
              [-4.532077762184145, 50.981252298885948],
              [-4.530899611436918, 50.981778818073238],
              [-4.530535050751963, 50.983510886076957],
              [-4.532051792128282, 50.983863843646219],
              [-4.530162870237999, 50.984553271960557],
              [-4.53003659556881, 50.985191889789043],
              [-4.529292309833632, 50.985733271809465],
              [-4.52924197841101, 50.986825343145497],
              [-4.529838314146576, 50.987728917570543],
              [-4.53193609235952, 50.988884147659824],
              [-4.531159854800364, 50.98949368954132],
              [-4.531233440147093, 50.99063613972644],
              [-4.532235387085114, 50.991030836731895],
              [-4.532758458612437, 50.990770354144658],
              [-4.533814496294771, 50.990928219503161],
              [-4.533815820725151, 50.991266367864213],
              [-4.532857599936276, 50.991386995292999],
              [-4.532907408849111, 50.992039783523943],
              [-4.534579585093441, 50.992153692918201],
              [-4.534356751594019, 50.992458033074819],
              [-4.533404935916883, 50.992592016866666],
              [-4.533687964974307, 50.992869187927276],
              [-4.532958546774633, 50.992978554230831],
              [-4.532879369152887, 50.993591868684874],
              [-4.533767258096386, 50.994062778797257],
              [-4.535161852332836, 50.993972243181013],
              [-4.534500865009135, 50.994317576433268],
              [-4.535212202441579, 50.994503598762776],
              [-4.534763972425274, 50.994673425826619],
              [-4.534864351353252, 50.995102961703196],
              [-4.533696720215294, 50.995069846409763],
              [-4.533334095686852, 50.995503134963158],
              [-4.532470955844718, 50.995408535944591],
              [-4.531944240860081, 50.995707770163918],
              [-4.531219454679661, 50.995484244417852],
              [-4.530293643709284, 50.996463979542987],
              [-4.53006099345272, 50.997426888829942],
              [-4.531115608221888, 50.998053400878398],
              [-4.531516064157885, 50.99894232555819],
              [-4.532513994972524, 50.999207593685377],
              [-4.531577113685478, 50.999566088876669],
              [-4.531649960711511, 51.000118543251183],
              [-4.533141822132051, 51.000815602479072],
              [-4.532514109312647, 51.000858001021875],
              [-4.532128467316757, 51.001497748232133],
              [-4.532961779917218, 51.00248161048723],
              [-4.530437183848054, 51.003107476100347],
              [-4.530284416188979, 51.003940937883172],
              [-4.530904391945107, 51.004017440880041],
              [-4.530331732062718, 51.00423401792154],
              [-4.530043916399539, 51.005179233869569],
              [-4.530981820723313, 51.006983798907036],
              [-4.533356962734399, 51.008398318793859],
              [-4.534603346649923, 51.008405446452656],
              [-4.535013902529195, 51.008666354976882],
              [-4.534057035866959, 51.008870604580615],
              [-4.532773840937791, 51.008685287945902],
              [-4.53259843151551, 51.009416711293881],
              [-4.533479368572553, 51.009466854125613],
              [-4.532949284267985, 51.009628555339475],
              [-4.533694565881323, 51.009962253654251],
              [-4.530638892029688, 51.010948611933323],
              [-4.529632957691264, 51.010907451424814],
              [-4.529251169093929, 51.011358228991043],
              [-4.529584821611478, 51.011411261159282],
              [-4.529046328084866, 51.011471493862516],
              [-4.528701786527727, 51.01116596347191],
              [-4.528286699549949, 51.011398004373916],
              [-4.528609001569339, 51.011478267218166],
              [-4.527770367279739, 51.01139389364247],
              [-4.52683620510538, 51.011912383026825],
              [-4.526734570293915, 51.01256035417375],
              [-4.527377517313144, 51.013266861421023],
              [-4.52844119858855, 51.013320282329616],
              [-4.528633730584576, 51.01358323591748],
              [-4.527827502094095, 51.013937969028461],
              [-4.526309149724122, 51.013966332792904],
              [-4.526047750774595, 51.015295010112148],
              [-4.526613526435089, 51.015656920769906],
              [-4.527565199212, 51.015694785593716],
              [-4.527620779150904, 51.016113603773597],
              [-4.5288878905911, 51.016157220872444],
              [-4.528858013164718, 51.016446576413429],
              [-4.527526598433895, 51.017003353314365],
              [-4.527863598272886, 51.017562682121003],
              [-4.527457533332879, 51.017620939324132],
              [-4.527650575535665, 51.018050272971287],
              [-4.527028059125341, 51.018874108668207],
              [-4.524490281659371, 51.01990485322159],
              [-4.524853084938065, 51.020362000075664],
              [-4.524359935970157, 51.02049767897519],
              [-4.524542810092321, 51.021186265091373],
              [-4.525299983799116, 51.021762598905163],
              [-4.526059917350629, 51.021864883235025],
              [-4.5259387060633, 51.022179597832348],
              [-4.523604484532756, 51.022288527979484],
              [-4.523819170108564, 51.022145380252709],
              [-4.523286192912878, 51.022204566670659],
              [-4.523206056602068, 51.021726917112936],
              [-4.522362542614455, 51.02160843165661],
              [-4.52186060677001, 51.021162376731844],
              [-4.518258064518808, 51.020444153512827],
              [-4.516646685944698, 51.021023030651271],
              [-4.516231259735018, 51.021830651103798],
              [-4.516560202528164, 51.022295747959625],
              [-4.513937056392823, 51.022204748030006],
              [-4.513416620547919, 51.022469581950332],
              [-4.509002398741602, 51.021323338210237],
              [-4.50899012843977, 51.020937759308353],
              [-4.5078051345927, 51.020938031169926],
              [-4.508144086519645, 51.020560198597842],
              [-4.506969605694551, 51.020570129631132],
              [-4.506358541995854, 51.019789976490976],
              [-4.50555945741459, 51.019462655170145],
              [-4.500976194283409, 51.018584161217447],
              [-4.499057051054841, 51.019117220591305],
              [-4.498627010906652, 51.020347806500396],
              [-4.499248572407099, 51.021060319598028],
              [-4.498128235290276, 51.021414371642756],
              [-4.496929126817442, 51.02147059759676],
              [-4.497362570171978, 51.021310229683266],
              [-4.497048071631513, 51.021163158198732],
              [-4.496715686793164, 51.02134654741235],
              [-4.495005492507362, 51.02067527431003],
              [-4.493914437153729, 51.020645516654838],
              [-4.490399341647378, 51.020831161580254],
              [-4.48896528953562, 51.021319529695759],
              [-4.485180850770789, 51.021349765510763],
              [-4.482782642245738, 51.021702976730495],
              [-4.480965855873602, 51.02172899852097],
              [-4.476603872363283, 51.021171328259896],
              [-4.473773410674941, 51.021468764121032],
              [-4.472781463785932, 51.0212649248651],
              [-4.47095399125158, 51.021679555531925],
              [-4.46950983086206, 51.021579692905753],
              [-4.467905537664804, 51.021015511907947],
              [-4.464190754708912, 51.020637968428453],
              [-4.463746698296142, 51.020250705609499],
              [-4.462734479912881, 51.020227987194609],
              [-4.462327282551085, 51.019836344137026],
              [-4.460771407916039, 51.019564247199249],
              [-4.45969134518873, 51.018448370718517],
              [-4.456922192455679, 51.018016498128986],
              [-4.456927399191364, 51.017602667841764],
              [-4.455491833931879, 51.016448363589411],
              [-4.451762720377809, 51.015060711204818],
              [-4.448604921966138, 51.014364286789707],
              [-4.446858569650156, 51.014287570613867],
              [-4.443224900304243, 51.015167712064105],
              [-4.442974630831392, 51.014746636784857],
              [-4.440146026717873, 51.014017014526253],
              [-4.437999318073762, 51.013782144268696],
              [-4.437629985348232, 51.013509244980924],
              [-4.436670788824807, 51.013760408251372],
              [-4.435400139799301, 51.01282996496748],
              [-4.433734988313474, 51.012450962960102],
              [-4.428752412187431, 51.012893775243754],
              [-4.427602850106391, 51.013240560827043],
              [-4.426679057724466, 51.013733740666389],
              [-4.4257676824687, 51.013481960922554],
              [-4.425276851942526, 51.013638756783074],
              [-4.422412208236854, 51.011445250539921],
              [-4.421363847306711, 51.011135120124962],
              [-4.417993094320271, 51.011071859707279],
              [-4.416131521125191, 51.009790091841147],
              [-4.412884500173488, 51.008510850002409],
              [-4.410298532550152, 51.006505559908518],
              [-4.408506608164767, 51.006064960923339],
              [-4.406110661658523, 51.004832730674742],
              [-4.403701026391699, 51.004325639866899],
              [-4.401514301227285, 51.00225527728346],
              [-4.399338314520298, 51.001079543997839],
              [-4.398531633724354, 50.9997411913481],
              [-4.396695635186562, 50.998700977776743],
              [-4.397687998412199, 50.99884609055789],
              [-4.397498110000478, 50.998397598129117],
              [-4.395504327954051, 50.996658634382655],
              [-4.393555863572822, 50.996129283031117],
              [-4.392536847938596, 50.995326330548878],
              [-4.390661798350269, 50.994587668636633],
              [-4.382768113368989, 50.992952958329468],
              [-4.379287942182795, 50.991392446079495],
              [-4.37492571816128, 50.990107001251424],
              [-4.370795905992001, 50.99016125563363],
              [-4.366937514827785, 50.989808736221853],
              [-4.364373512120712, 50.990221341473294],
              [-4.355330050433769, 50.990160398708412],
              [-4.348522200712448, 50.989077977497466],
              [-4.345245476904252, 50.988866834717044],
              [-4.339217436463944, 50.989567866761632],
              [-4.331437754127692, 50.991488959170944],
              [-4.325859124482406, 50.991584034279299],
              [-4.318402556087042, 50.992714456336635],
              [-4.315889048264296, 50.993437073013844],
              [-4.313870759710369, 50.994404345483325],
              [-4.308493739628617, 50.995065695672587],
              [-4.303558948751133, 50.997349539040719],
              [-4.296863564325743, 51.001785390231532],
              [-4.296458112097126, 51.00245035713931],
              [-4.295646923408124, 51.002736119931299],
              [-4.293807092717035, 51.004476587776296],
              [-4.29048171867055, 51.00676601097814],
              [-4.284211622490134, 51.013974971774267],
              [-4.28153211542192, 51.015735256018218],
              [-4.27958153423251, 51.015965813551333],
              [-4.278789008372768, 51.016348223862764],
              [-4.274071405145892, 51.021571080475482],
              [-4.273115841231232, 51.023403707844246],
              [-4.268518102405465, 51.027370276132785],
              [-4.263054841080064, 51.033932808146091],
              [-4.260298754268795, 51.03574173679754],
              [-4.258254364575373, 51.037611487528096],
              [-4.256675397297599, 51.038406464127632],
              [-4.256291313356853, 51.038927420681176],
              [-4.255447760180178, 51.038887952410157],
              [-4.252826859855314, 51.039643633436455],
              [-4.244382118896692, 51.040800001332613],
              [-4.242916789762479, 51.040588019155813],
              [-4.238797389505391, 51.041159899187761],
              [-4.238140517981368, 51.041442295876728],
              [-4.235399785064874, 51.044487850148826],
              [-4.233344596328704, 51.047495948361494],
              [-4.230977333299345, 51.053396907732804],
              [-4.228798915481146, 51.058161017397424],
              [-4.226349279922885, 51.061892392090243],
              [-4.225183883296734, 51.063036092849636],
              [-4.223439673078612, 51.064373368163352],
              [-4.221880977662994, 51.064937240296054],
              [-4.21905044160057, 51.065231167151119],
              [-4.216904059245415, 51.065057870499082],
              [-4.214190298152414, 51.063732424110583],
              [-4.209769257438377, 51.062516566232311],
              [-4.208387301342185, 51.061668526241149],
            ],
          ],
          [
            [
              [-4.676506532824106, 51.201784144674427],
              [-4.675089790865003, 51.201423527636493],
              [-4.674195734819082, 51.201658036952203],
              [-4.672498057543396, 51.20119228776376],
              [-4.670275914097372, 51.201038893185789],
              [-4.670560355685533, 51.20056111021853],
              [-4.669832317312113, 51.200003018651579],
              [-4.670550449929706, 51.198905537187315],
              [-4.669242975171662, 51.198822967859734],
              [-4.669565810677742, 51.198241778792045],
              [-4.668635461338972, 51.196882434723783],
              [-4.670053510043505, 51.195833735297185],
              [-4.669482093048414, 51.195482525185781],
              [-4.669828686140661, 51.194891798456489],
              [-4.667124894521617, 51.193655670619087],
              [-4.667087024427694, 51.192851569401874],
              [-4.665435864387042, 51.191672332486625],
              [-4.665845009439419, 51.190900310898215],
              [-4.665521768991109, 51.190408512481604],
              [-4.66400271471354, 51.190598731852269],
              [-4.6641628249045, 51.189677693188109],
              [-4.665291722837815, 51.188456657129493],
              [-4.664084530698776, 51.187874376613749],
              [-4.663994337088483, 51.18735297953441],
              [-4.662415880647589, 51.187206357365909],
              [-4.662943546931079, 51.18652878189728],
              [-4.661792370135861, 51.18637061960554],
              [-4.662304117804462, 51.185640344626584],
              [-4.661681909416802, 51.185497116549435],
              [-4.662722218512782, 51.185197310773063],
              [-4.662937988822309, 51.184471974653704],
              [-4.662363331548899, 51.184408613417688],
              [-4.662146267690519, 51.183696732431557],
              [-4.663760398279979, 51.182429582311094],
              [-4.664225692229789, 51.181540262586431],
              [-4.663896393938049, 51.180371347028881],
              [-4.662794854541235, 51.179880184796076],
              [-4.663256073460663, 51.179491029709475],
              [-4.662389245624452, 51.178702210416702],
              [-4.661899794470371, 51.17644146309383],
              [-4.662407408442636, 51.175591659212309],
              [-4.66194008355844, 51.173888037870697],
              [-4.66226658542091, 51.173570308242873],
              [-4.661954320654178, 51.17306745727327],
              [-4.662382013482683, 51.169441212704733],
              [-4.661941307281144, 51.168198378757872],
              [-4.661101501131941, 51.16804841184576],
              [-4.661640031853425, 51.167090876731685],
              [-4.660092643469619, 51.166235464771006],
              [-4.657365358409632, 51.163032883877683],
              [-4.654846524533347, 51.162487518607513],
              [-4.654300255834864, 51.162813816385693],
              [-4.653092544902502, 51.162133403467649],
              [-4.653140781640639, 51.161752760130369],
              [-4.65382131981336, 51.161821855380836],
              [-4.653620251952724, 51.161409096684253],
              [-4.654076037516428, 51.161300713485936],
              [-4.654967313897274, 51.161975712342645],
              [-4.655348380484942, 51.161512857185322],
              [-4.655092196828253, 51.160937661352719],
              [-4.655769503158269, 51.16122447448295],
              [-4.655511572579255, 51.160867874501903],
              [-4.657215439839405, 51.16139577804482],
              [-4.656796407328379, 51.161670627437665],
              [-4.656969674480839, 51.162121787808061],
              [-4.658069011291958, 51.162359420239845],
              [-4.658921522920536, 51.161762613122413],
              [-4.659278227833041, 51.161871421123728],
              [-4.660347797824045, 51.160672459901747],
              [-4.662411442716718, 51.161138134721199],
              [-4.662792080652713, 51.16181121356778],
              [-4.664050131492125, 51.161774453088341],
              [-4.666028517958222, 51.160760685979007],
              [-4.667842598821849, 51.159255961159154],
              [-4.669102051193307, 51.159591468071845],
              [-4.669649952803298, 51.159097772797736],
              [-4.669051628399618, 51.158767863432807],
              [-4.669850178878274, 51.158874643626525],
              [-4.670121771385054, 51.158648984419465],
              [-4.670872757245892, 51.159046453265475],
              [-4.670891921605677, 51.158462300124086],
              [-4.67194739342318, 51.158980519900012],
              [-4.670951275974706, 51.15951414956357],
              [-4.671717894257729, 51.159810522286506],
              [-4.671687909358936, 51.160429999736991],
              [-4.672507220324955, 51.160400477010306],
              [-4.67233951666874, 51.160790156926204],
              [-4.67314410593638, 51.160629652895992],
              [-4.673643815932637, 51.16100946593631],
              [-4.67282176937233, 51.161412313068574],
              [-4.672963219379017, 51.161978403210121],
              [-4.6745543588557, 51.162457367166432],
              [-4.67308989206058, 51.162313683495618],
              [-4.674081532187143, 51.162863924182659],
              [-4.673489398747842, 51.163161681560723],
              [-4.674204356199556, 51.163503290947666],
              [-4.675615064716972, 51.163533067360206],
              [-4.675579680555235, 51.164206633920742],
              [-4.676239619592011, 51.164091692076362],
              [-4.675885460710005, 51.164398403323474],
              [-4.676040431105167, 51.165197126831629],
              [-4.676700053283394, 51.165743254077022],
              [-4.676566156172751, 51.166370508657451],
              [-4.677180937826908, 51.167129920096734],
              [-4.676862593317459, 51.168079789184929],
              [-4.677416821576966, 51.168559071700521],
              [-4.678001727175078, 51.168506099292429],
              [-4.677762017679151, 51.16896129420001],
              [-4.678373565556671, 51.16899765036613],
              [-4.678495191383121, 51.170774789926476],
              [-4.680539411184386, 51.172423314259781],
              [-4.679811814961507, 51.172976942140942],
              [-4.679582269749562, 51.172842796036036],
              [-4.679645456285755, 51.173364802369647],
              [-4.679393084800737, 51.1731331437976],
              [-4.678909309153693, 51.173351994213476],
              [-4.678459192997183, 51.174299490012785],
              [-4.679511815927196, 51.174489425795301],
              [-4.67966063433985, 51.175007670369297],
              [-4.680586360688674, 51.175032316309171],
              [-4.680596196319324, 51.17539904868859],
              [-4.680082090846893, 51.175958574886849],
              [-4.679467130678284, 51.175963677853701],
              [-4.678595360565708, 51.176400980800082],
              [-4.678487782727192, 51.1768405571069],
              [-4.678066858902967, 51.176811526222963],
              [-4.677621199191718, 51.178700593749134],
              [-4.674474630986407, 51.179738583258967],
              [-4.674156302597158, 51.179455357503592],
              [-4.673964628453853, 51.180049751566564],
              [-4.673256311143061, 51.180198163275712],
              [-4.673630081019916, 51.180302041338194],
              [-4.673121811903841, 51.181780593581365],
              [-4.673769503077299, 51.181523842129693],
              [-4.673664107049519, 51.182051504988635],
              [-4.674062239388647, 51.181587300152174],
              [-4.674078953418017, 51.182271364910818],
              [-4.674345483917241, 51.181981056170926],
              [-4.675026861167757, 51.182330625462413],
              [-4.674943358016867, 51.183260721932768],
              [-4.675584105586447, 51.183452023071524],
              [-4.675141831361385, 51.183648321028265],
              [-4.675578092877876, 51.183965720844057],
              [-4.676039624113232, 51.183780672723721],
              [-4.676433003813016, 51.184222269433775],
              [-4.67572785000436, 51.184327451742341],
              [-4.677154314413904, 51.184766083267959],
              [-4.676270547059282, 51.18492550062534],
              [-4.677486246348765, 51.18533230210511],
              [-4.674051462604285, 51.185503554375593],
              [-4.675560331782987, 51.185709173636752],
              [-4.67511344347021, 51.18599911491485],
              [-4.674280084351499, 51.185917447387432],
              [-4.67408742447944, 51.186470490882407],
              [-4.674975053214502, 51.186550018748676],
              [-4.675129395916505, 51.18736134757529],
              [-4.674164350449136, 51.187551614016094],
              [-4.675454500690118, 51.187760438950619],
              [-4.675137954844653, 51.188176012009933],
              [-4.676088072262328, 51.188049037622832],
              [-4.675948120854346, 51.189066769056154],
              [-4.676546801915701, 51.188777846946145],
              [-4.677322518963403, 51.189246663264328],
              [-4.67681750269294, 51.189571219816401],
              [-4.675353464058309, 51.189400566143803],
              [-4.674491542532524, 51.190284613679303],
              [-4.675276674699108, 51.190125444170924],
              [-4.675784123872917, 51.190361167514716],
              [-4.675337462639619, 51.191717796940978],
              [-4.675848266640892, 51.192060472393486],
              [-4.676695502883955, 51.191811730603888],
              [-4.676772613301274, 51.192944113606714],
              [-4.678207115504355, 51.193121722784866],
              [-4.678444428478664, 51.19343737368925],
              [-4.678808793269186, 51.193303109308935],
              [-4.678688847798057, 51.193801429132932],
              [-4.679378174992234, 51.193667811960992],
              [-4.679728411593248, 51.194177841937005],
              [-4.679059079669587, 51.194557439661871],
              [-4.679261285523093, 51.194835219016475],
              [-4.678695109789366, 51.194945327602163],
              [-4.67944141224092, 51.195176462309355],
              [-4.679513840412918, 51.196004952346513],
              [-4.678744747074435, 51.197181907118619],
              [-4.677978525575028, 51.197274109914268],
              [-4.678190156390583, 51.197615532846413],
              [-4.677091820594995, 51.197954577999873],
              [-4.677213041710327, 51.198884483624866],
              [-4.676489306204997, 51.19936424569331],
              [-4.676978887507457, 51.199684917332945],
              [-4.676706355019114, 51.200243390203937],
              [-4.677233536563052, 51.20017555731549],
              [-4.677639462596483, 51.200731986527828],
              [-4.677229337909286, 51.201263031728438],
              [-4.677931290070254, 51.201172306435872],
              [-4.678444901069991, 51.201536492484919],
              [-4.677650591022974, 51.201861381890716],
              [-4.678358369818174, 51.202240908473229],
              [-4.677533575948916, 51.20253411672352],
              [-4.676848022222818, 51.202340252742566],
              [-4.676506532824106, 51.201784144674427],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000047",
        LAD13CDO: "18UL",
        LAD13NM: "West Devon",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-4.067396314431088, 50.872169172289517],
            [-4.067152207456296, 50.870892805894037],
            [-4.066738665116405, 50.87082998247994],
            [-4.066592256788314, 50.870347819758948],
            [-4.065145741605283, 50.870099129343487],
            [-4.063829134978706, 50.870635064823219],
            [-4.062100164077685, 50.870716902149738],
            [-4.061023810854911, 50.871126245515079],
            [-4.059238795121943, 50.87035643564775],
            [-4.058074667911781, 50.871386960966404],
            [-4.054701986361132, 50.867674489356538],
            [-4.053026559791716, 50.86383043613553],
            [-4.051961443728143, 50.862649427525113],
            [-4.051586888463881, 50.862792718270441],
            [-4.051472592310348, 50.863620342298837],
            [-4.048899885546057, 50.86396504356911],
            [-4.047932879526602, 50.863561122433801],
            [-4.046483871142742, 50.86370256856177],
            [-4.046407133824632, 50.865162678124435],
            [-4.041522273358326, 50.867950842281999],
            [-4.039927991348334, 50.868544427893966],
            [-4.039901676671526, 50.865305393879254],
            [-4.04070283833956, 50.863051974826661],
            [-4.038985120748841, 50.860259817449403],
            [-4.036294245230835, 50.859357994497579],
            [-4.031564475062499, 50.860287657673233],
            [-4.028993341246854, 50.859849449945891],
            [-4.02632892680531, 50.860404799395241],
            [-4.023908742056718, 50.860366770492327],
            [-4.024345695514578, 50.862219962217559],
            [-4.02377981204632, 50.862672265056979],
            [-4.023837467584375, 50.864265827442345],
            [-4.023018580435441, 50.866063458174537],
            [-4.020936604310871, 50.866261427765195],
            [-4.019447252889772, 50.866065077288241],
            [-4.019547471872222, 50.865851993353054],
            [-4.018367942062954, 50.865556732145983],
            [-4.01707353925084, 50.865727513766679],
            [-4.015613726969019, 50.865424479602787],
            [-4.015444429263906, 50.864267233548844],
            [-4.013560337112022, 50.864142381077556],
            [-4.010707439954071, 50.863233772612993],
            [-4.010628179946474, 50.864859377327171],
            [-4.008342806324102, 50.865978870440181],
            [-4.004390091805123, 50.867061323783346],
            [-4.000430353820289, 50.867751643440464],
            [-3.995865192378809, 50.869110514585081],
            [-3.995000892320863, 50.869206239568946],
            [-3.994184427834925, 50.868757930390323],
            [-3.995010628073445, 50.865074437244594],
            [-3.996497830941096, 50.864159532734931],
            [-3.996123992665778, 50.863226103591984],
            [-3.997775324046474, 50.862794925005488],
            [-3.997244272286974, 50.861807531956835],
            [-3.99506835387222, 50.859869779608296],
            [-3.995710385453005, 50.856935890576047],
            [-3.995357115066045, 50.856216153299023],
            [-3.996886651226435, 50.854735720843038],
            [-3.995376980577759, 50.851855732679972],
            [-3.991763511876063, 50.852276331150549],
            [-3.990204104549761, 50.851221920876682],
            [-3.989365810327659, 50.850051768036224],
            [-3.989480021235973, 50.850432046675344],
            [-3.988838306348405, 50.850642646716125],
            [-3.987908034250978, 50.850248397550388],
            [-3.985764967308967, 50.850834406358736],
            [-3.985641507803448, 50.851305971799739],
            [-3.978005992467152, 50.85474981858691],
            [-3.977399287844387, 50.855521857783465],
            [-3.975810789259372, 50.856328513868078],
            [-3.97326342681241, 50.856368034291307],
            [-3.972297832607853, 50.85665147189107],
            [-3.970652953677337, 50.856225097065966],
            [-3.969804154409367, 50.855470485599326],
            [-3.967367253104696, 50.855027757468825],
            [-3.962370719085505, 50.855648831155762],
            [-3.961257967561918, 50.856320474740009],
            [-3.958895924652607, 50.85539156031107],
            [-3.958622265467795, 50.854930290835114],
            [-3.962071943842036, 50.849513073537359],
            [-3.957530258131311, 50.845954191844228],
            [-3.954421877085145, 50.8440457245011],
            [-3.950186325600269, 50.842044506336677],
            [-3.947664485794473, 50.840154831964988],
            [-3.94848268353236, 50.839781425514694],
            [-3.945610351027871, 50.838714174483144],
            [-3.943312299770061, 50.837108466932158],
            [-3.942190006749282, 50.834947127699678],
            [-3.942774284391302, 50.832909354654376],
            [-3.94099341721989, 50.831677211546001],
            [-3.940459723563023, 50.830485454641838],
            [-3.938789771425981, 50.829253231581902],
            [-3.93913052238113, 50.827504618695961],
            [-3.940175589389221, 50.826921544612127],
            [-3.940391863571342, 50.82556261950986],
            [-3.938330767715077, 50.825612181427815],
            [-3.930554544159038, 50.82752187954064],
            [-3.927801875187587, 50.827365956049753],
            [-3.926971839969526, 50.827864423980444],
            [-3.924833089921584, 50.827202742338301],
            [-3.920551750382106, 50.828168176723615],
            [-3.918059060969346, 50.830024088289555],
            [-3.916311586610928, 50.830247072611229],
            [-3.915983700228521, 50.830562735558765],
            [-3.913479633637632, 50.830307957275117],
            [-3.912340781167863, 50.829683599660584],
            [-3.909677602715668, 50.8290428292499],
            [-3.905657100228682, 50.829555566282963],
            [-3.904545565967326, 50.830785146716288],
            [-3.903901820664853, 50.832832684011763],
            [-3.902474266171123, 50.83272017602836],
            [-3.900903181481624, 50.833478761320755],
            [-3.900747367833615, 50.834292513548434],
            [-3.899808240454024, 50.834108158450455],
            [-3.899644495687247, 50.834517331172847],
            [-3.897420488671786, 50.833537267486442],
            [-3.89577550545971, 50.831844461069345],
            [-3.893413929959158, 50.831670575422187],
            [-3.89008381953334, 50.829210908278043],
            [-3.884642156015824, 50.826387774431417],
            [-3.88341473052879, 50.825984908098327],
            [-3.880916073558536, 50.826171824501039],
            [-3.881432277028913, 50.824919699849829],
            [-3.882442503999016, 50.824795477105638],
            [-3.88149338628201, 50.821495793161745],
            [-3.877730240518839, 50.818895268673913],
            [-3.874319507187248, 50.819164178264828],
            [-3.871250390307484, 50.819988700348908],
            [-3.866594057583309, 50.820746821315012],
            [-3.86173545696959, 50.820635617911222],
            [-3.859864504845794, 50.821286018297279],
            [-3.854168483260312, 50.819676949046666],
            [-3.853481802648915, 50.819186928195215],
            [-3.852559571796549, 50.81744425832683],
            [-3.85187670115673, 50.817300415968916],
            [-3.849812709418249, 50.81787997406181],
            [-3.848849422714984, 50.817861073740708],
            [-3.848180935479254, 50.818261086040771],
            [-3.845678216689048, 50.817941881033562],
            [-3.842349772160011, 50.818608761117218],
            [-3.839858106034935, 50.81849790474714],
            [-3.839493701725369, 50.818834611834056],
            [-3.838966729166525, 50.818141433269794],
            [-3.839380807346827, 50.81747928078299],
            [-3.842871910171093, 50.813300667679883],
            [-3.844086975176793, 50.812362328912656],
            [-3.844376109501604, 50.811450316071877],
            [-3.844149953074168, 50.809240600628335],
            [-3.843327734465076, 50.807608694678677],
            [-3.842534417276241, 50.800397674163783],
            [-3.839777081836475, 50.800326992219617],
            [-3.838866197720087, 50.797296168567243],
            [-3.837295469822563, 50.795202064159326],
            [-3.838143225265565, 50.794964765875825],
            [-3.837020189145471, 50.79375485346398],
            [-3.836211160223021, 50.793857531020855],
            [-3.835881715850785, 50.792982256584914],
            [-3.836408689516685, 50.792706852049463],
            [-3.836315696988342, 50.792144425177952],
            [-3.837776939592799, 50.792066543696642],
            [-3.838039955219783, 50.791501204560355],
            [-3.83914279879023, 50.791113283374841],
            [-3.839456509288426, 50.790610995005828],
            [-3.839465343906093, 50.790366232742244],
            [-3.838594813828075, 50.790237865436218],
            [-3.839839047971495, 50.788682151450175],
            [-3.839841338233032, 50.787512061945208],
            [-3.841336886863402, 50.78715569466997],
            [-3.841229740017196, 50.786848010436557],
            [-3.841911839083592, 50.786515270200141],
            [-3.841161613582976, 50.786341853037975],
            [-3.84181362575594, 50.784810755092202],
            [-3.841494960133312, 50.784679986562644],
            [-3.841971850688088, 50.784252457343264],
            [-3.84152850405417, 50.783800791876146],
            [-3.842507900200629, 50.783417484999916],
            [-3.842510317901891, 50.783046015165283],
            [-3.842899088356531, 50.78311991315136],
            [-3.843775765156763, 50.782109573017308],
            [-3.845552957030713, 50.781597605259471],
            [-3.845228499466716, 50.78131944509321],
            [-3.8270683504464, 50.780672807529477],
            [-3.82202143983339, 50.78100809882983],
            [-3.822562401748532, 50.780400677890988],
            [-3.8220759888551, 50.779784132376115],
            [-3.822337672639105, 50.779290796935754],
            [-3.824270602004323, 50.778184948209336],
            [-3.825109618427033, 50.776928017512454],
            [-3.822934610649187, 50.775309040609457],
            [-3.822567673283596, 50.773714834641147],
            [-3.821663631470863, 50.773189353804717],
            [-3.820591367239941, 50.771691598131405],
            [-3.819699774330427, 50.769329435693706],
            [-3.816075006708733, 50.76380459045869],
            [-3.815000912502835, 50.763494853426799],
            [-3.813017895983291, 50.762268417973324],
            [-3.813837638727289, 50.76124569624951],
            [-3.814485358281046, 50.761238314701139],
            [-3.81470731133319, 50.760891308019325],
            [-3.811302642075775, 50.760005335037462],
            [-3.807361595136516, 50.759988258063323],
            [-3.807353302491783, 50.760435363526234],
            [-3.806214513304502, 50.759730835097542],
            [-3.80582001625683, 50.75902286283381],
            [-3.806712229679831, 50.758958675135005],
            [-3.806854476579891, 50.75930182090579],
            [-3.807645355958735, 50.759220310285841],
            [-3.807674955666428, 50.758995013789068],
            [-3.808286206765602, 50.759219365007546],
            [-3.809009883129552, 50.758755764304823],
            [-3.810013918463868, 50.758870585421825],
            [-3.810713678989012, 50.758559335551972],
            [-3.810944197393917, 50.758947871622134],
            [-3.811941435332893, 50.758886509065128],
            [-3.811982163478455, 50.758582794886401],
            [-3.812432505723511, 50.758605472796525],
            [-3.81201974327481, 50.758454502983135],
            [-3.812280301347211, 50.75826248839585],
            [-3.813063896846658, 50.758360024881355],
            [-3.812736703813324, 50.758185242976765],
            [-3.813072398148726, 50.757846363382114],
            [-3.814050595828771, 50.757916583986969],
            [-3.813762776047507, 50.757623377284119],
            [-3.814440029101123, 50.757464445256943],
            [-3.814817457794096, 50.756848810141385],
            [-3.816879843918039, 50.756358915242494],
            [-3.816830592197598, 50.755856046059399],
            [-3.818054005150858, 50.755084215211184],
            [-3.817804561634863, 50.754866864699977],
            [-3.818289840124843, 50.754877283457255],
            [-3.818056634665061, 50.754420452633987],
            [-3.818553615255837, 50.754403707210528],
            [-3.818316426035623, 50.754026981334498],
            [-3.818851703858106, 50.753937689092098],
            [-3.818694762696587, 50.753144211223997],
            [-3.819132580756224, 50.75306453226991],
            [-3.819726157707201, 50.75170084339986],
            [-3.82069495768033, 50.751057068548832],
            [-3.820586664341187, 50.750494865888079],
            [-3.82118554109668, 50.750292150257295],
            [-3.820966405858043, 50.750051849075888],
            [-3.821387887495849, 50.750172972289285],
            [-3.821686885034139, 50.749695240539268],
            [-3.822181329509972, 50.749687512528212],
            [-3.822657542610415, 50.749028036610611],
            [-3.822464735977148, 50.74800578872],
            [-3.822843133134315, 50.74774805319899],
            [-3.823056036619675, 50.74560246402131],
            [-3.824553636221051, 50.743908032986013],
            [-3.824208265011098, 50.743264105001117],
            [-3.824960043767595, 50.742184803993808],
            [-3.824681011105596, 50.741533543141728],
            [-3.825054153268146, 50.741178751846689],
            [-3.82469026059374, 50.741042350924218],
            [-3.827303687161376, 50.738672081476125],
            [-3.828587222685395, 50.736257868909995],
            [-3.830613226000903, 50.73504341406629],
            [-3.831172067349632, 50.73501934660645],
            [-3.832132809661342, 50.734036541778636],
            [-3.83379520208676, 50.733531041916294],
            [-3.836276193688163, 50.732180719344584],
            [-3.833826452839213, 50.730558177152112],
            [-3.831824811464431, 50.727850213277861],
            [-3.82750942043523, 50.729104209530696],
            [-3.82543148769813, 50.730290646372801],
            [-3.822468525352535, 50.726385244711288],
            [-3.82140976088993, 50.724367452071014],
            [-3.822110799474224, 50.723227801769951],
            [-3.819857495139663, 50.72101911418595],
            [-3.819590217675257, 50.719130141555283],
            [-3.818578772157255, 50.716973082599495],
            [-3.809389303266039, 50.716498259943378],
            [-3.803788246432309, 50.716835106545453],
            [-3.799871097246249, 50.717420892146521],
            [-3.793459010065344, 50.719079223807057],
            [-3.783513632153275, 50.722839150423553],
            [-3.766241125582579, 50.727573760856686],
            [-3.765871250656532, 50.725839122047155],
            [-3.764190684518893, 50.725985984673656],
            [-3.761956806147396, 50.724565527134473],
            [-3.759255103987685, 50.724003774479947],
            [-3.757969738879376, 50.722973627589035],
            [-3.756948637115958, 50.722681447202532],
            [-3.756851458109399, 50.721144123626715],
            [-3.754993816954893, 50.719960686264571],
            [-3.755445265964255, 50.71934142957231],
            [-3.755310143175774, 50.717886516630138],
            [-3.753649644495202, 50.716059750844856],
            [-3.751565212464231, 50.714636846921202],
            [-3.751410961131345, 50.713729021981052],
            [-3.752246112638721, 50.711814340248665],
            [-3.750830661455574, 50.710510869396174],
            [-3.750731067344939, 50.709206507252276],
            [-3.750259148903099, 50.708629017367421],
            [-3.748334054261524, 50.708050852094757],
            [-3.747707768103105, 50.708196048324147],
            [-3.747750972040516, 50.707872533205801],
            [-3.745782493327257, 50.707497330713508],
            [-3.743305487166088, 50.70597403710638],
            [-3.741472990682513, 50.705481610981764],
            [-3.739894117588584, 50.705526779148464],
            [-3.739059903599386, 50.704760393203429],
            [-3.737699373760087, 50.704466815229914],
            [-3.737500709746189, 50.704073163645333],
            [-3.736443866879884, 50.703872172684427],
            [-3.736261519898148, 50.703306499825537],
            [-3.735576157848599, 50.703193498165398],
            [-3.735660811607859, 50.702533013019554],
            [-3.734994399788568, 50.702012319690866],
            [-3.735230287729908, 50.70172731049756],
            [-3.734863795741858, 50.701696793181192],
            [-3.735109511086876, 50.701256050146362],
            [-3.733683250802641, 50.700252017622034],
            [-3.734013486813203, 50.700144578011077],
            [-3.733453466889683, 50.699473899753613],
            [-3.733669416308265, 50.698610007865135],
            [-3.732273670240627, 50.69700923416211],
            [-3.735059245245851, 50.695904757158758],
            [-3.741772446291882, 50.695524906710631],
            [-3.745581533076551, 50.696673041095444],
            [-3.749526470638213, 50.697166981068548],
            [-3.752656011580692, 50.696335716177138],
            [-3.753426047625549, 50.694316773966655],
            [-3.756727644409962, 50.694838135746252],
            [-3.758460010855123, 50.694439673058824],
            [-3.760626846358422, 50.693317834922553],
            [-3.761631496999509, 50.693225306195892],
            [-3.768462842606884, 50.694985292276925],
            [-3.77381476345967, 50.693174483336655],
            [-3.775732174461812, 50.693069733159085],
            [-3.777087212161566, 50.693482565165006],
            [-3.77998332740956, 50.695330606721932],
            [-3.782552618999648, 50.695536855290108],
            [-3.783763125350884, 50.694898674359784],
            [-3.784261138409938, 50.693433192131735],
            [-3.784926036803564, 50.692821339055413],
            [-3.786767310481487, 50.692167166585122],
            [-3.789354358982803, 50.692806482593575],
            [-3.791863395473985, 50.693848954010868],
            [-3.798765764314513, 50.692917894854425],
            [-3.801640396677158, 50.693703591411015],
            [-3.803299037335652, 50.693645557250015],
            [-3.807266530259005, 50.694753255746868],
            [-3.808900121155494, 50.694006621316305],
            [-3.809717763152398, 50.692589125832249],
            [-3.811604659691976, 50.691033591061128],
            [-3.810971098343161, 50.689398510158817],
            [-3.807854281627642, 50.684630837192593],
            [-3.807733521306984, 50.682815103010441],
            [-3.80700813297359, 50.681620312524522],
            [-3.802825046143714, 50.678625488509063],
            [-3.798466913932737, 50.677701747444672],
            [-3.798059303123432, 50.675777111334945],
            [-3.797198694634097, 50.674923406075457],
            [-3.79900002836998, 50.673173328814066],
            [-3.799396506467464, 50.671772296463146],
            [-3.80057028322454, 50.670343962553424],
            [-3.801590821609944, 50.669757090714832],
            [-3.801556244546195, 50.668743144999567],
            [-3.804510940678022, 50.667373555210375],
            [-3.803477245311803, 50.66658103486057],
            [-3.806220032839146, 50.665372968466031],
            [-3.809637660837673, 50.664643609509952],
            [-3.80946631038777, 50.662910499332654],
            [-3.810378806100492, 50.662464627938576],
            [-3.811065353944962, 50.659603871871532],
            [-3.811977579415429, 50.659005998919433],
            [-3.811907192283338, 50.658757969750503],
            [-3.809639915878271, 50.65899826736262],
            [-3.807225091779839, 50.658602261296075],
            [-3.805883406503299, 50.656711019775088],
            [-3.804627663327659, 50.655914756519749],
            [-3.806814706293108, 50.655504021612479],
            [-3.809362365878938, 50.655679426781283],
            [-3.81019171185117, 50.655171891578902],
            [-3.80989267938917, 50.654201626418995],
            [-3.810774325970071, 50.654245484352032],
            [-3.811679076792494, 50.653676508608214],
            [-3.813466264100678, 50.653393280158419],
            [-3.814212530842575, 50.652934675415977],
            [-3.814131670724348, 50.651313479031991],
            [-3.815959353898276, 50.651606074594064],
            [-3.817380628420433, 50.651411230903442],
            [-3.818500590553327, 50.652315602649779],
            [-3.820012460810461, 50.652231733359869],
            [-3.820105783709559, 50.651681661804417],
            [-3.820672031714058, 50.651801422226036],
            [-3.821918359296165, 50.651399704944978],
            [-3.823806334497978, 50.651603996024278],
            [-3.824495858539965, 50.652057271520121],
            [-3.826635390828096, 50.651780012765251],
            [-3.828828929565836, 50.650490078496667],
            [-3.828795796380974, 50.648981460112388],
            [-3.829261713602153, 50.647938972148367],
            [-3.830245432582678, 50.647663602357298],
            [-3.831731347395854, 50.647789540554768],
            [-3.832740075345367, 50.648920367935283],
            [-3.834743034774533, 50.649717154610016],
            [-3.837735067448691, 50.649830076555624],
            [-3.841739832384842, 50.648239697949833],
            [-3.84355951687873, 50.646953594289364],
            [-3.843906554838271, 50.645268079124776],
            [-3.843595199960885, 50.643059658715529],
            [-3.84526254137694, 50.641032158096081],
            [-3.845905265018725, 50.63570669325221],
            [-3.848364808963014, 50.634452636809598],
            [-3.848850710068399, 50.633537458782122],
            [-3.850353083052134, 50.632328228421613],
            [-3.852758641640935, 50.631614558056249],
            [-3.854435617166598, 50.631648127162308],
            [-3.855964515229118, 50.63068662753458],
            [-3.857723595908594, 50.628965962621216],
            [-3.858076690060957, 50.626194756760221],
            [-3.860545146055693, 50.62583157432541],
            [-3.862029457880345, 50.625145013951709],
            [-3.864244316392385, 50.623448436948117],
            [-3.867288760071373, 50.619321896783788],
            [-3.869782507060148, 50.616898539484744],
            [-3.873316642689121, 50.614268626749727],
            [-3.872420942445407, 50.612572417066545],
            [-3.872957683232793, 50.610926020020507],
            [-3.874852558798891, 50.609028420527885],
            [-3.879166782532996, 50.599537888923074],
            [-3.88205872716274, 50.597325475562592],
            [-3.882802692260654, 50.595573147574399],
            [-3.882363743681648, 50.593814760764303],
            [-3.881086667619074, 50.592508813139048],
            [-3.881311105151847, 50.591688549391371],
            [-3.880522858142502, 50.589174922131598],
            [-3.881329074097259, 50.58774537280398],
            [-3.880575002057833, 50.587434676877827],
            [-3.8806391210913, 50.586987548123027],
            [-3.879283760362334, 50.586334896456385],
            [-3.880330548993355, 50.585731599190446],
            [-3.880899428261988, 50.584409313420693],
            [-3.879341781815017, 50.580909789286736],
            [-3.878404085772734, 50.580489620741751],
            [-3.878414450286388, 50.580004686617585],
            [-3.877315279604517, 50.578933263750088],
            [-3.877637044208694, 50.578784173838393],
            [-3.877358470354729, 50.577746282222492],
            [-3.877738679275009, 50.577078205289638],
            [-3.877426627537481, 50.575695490661062],
            [-3.877044579876788, 50.575144933108916],
            [-3.873897502611624, 50.575172245992484],
            [-3.872798583625428, 50.573496391794485],
            [-3.870154635748492, 50.57210347930998],
            [-3.869733997285915, 50.571146097734513],
            [-3.870302497140985, 50.570666589003096],
            [-3.870755959399432, 50.56920859885539],
            [-3.871867707780428, 50.568113267695139],
            [-3.871857693553389, 50.56675625955075],
            [-3.872827764018004, 50.566398892000976],
            [-3.872951163439639, 50.565593757332358],
            [-3.874358244842192, 50.564756269220254],
            [-3.874855666998324, 50.564018858956914],
            [-3.874842095855204, 50.562858872170281],
            [-3.874273526693856, 50.561803156913108],
            [-3.876286412520074, 50.558589607964976],
            [-3.875916931597093, 50.556964977893074],
            [-3.874078700795656, 50.556216618651568],
            [-3.874597617655119, 50.555133498142418],
            [-3.874351010692796, 50.554184120946474],
            [-3.877106701550259, 50.552939936039465],
            [-3.877444114673176, 50.55240745446811],
            [-3.877094191665633, 50.549995548403963],
            [-3.875936094613628, 50.549305501883872],
            [-3.875650648233213, 50.547697502447981],
            [-3.875942649707587, 50.54723051225659],
            [-3.875126342017864, 50.546579018820786],
            [-3.874873304003777, 50.545430980841559],
            [-3.875815244820147, 50.544554192415212],
            [-3.875582971051409, 50.542542409524621],
            [-3.877422282328398, 50.542442604480435],
            [-3.879669754105209, 50.541569002169247],
            [-3.880421849345512, 50.540287818113335],
            [-3.882173667137757, 50.539977994727522],
            [-3.883475207692499, 50.539241926975336],
            [-3.88464450129751, 50.537207470015922],
            [-3.886463718327882, 50.536149997011563],
            [-3.889139514338339, 50.536330544677135],
            [-3.888098299939528, 50.533227458591249],
            [-3.884290476948822, 50.530662052300826],
            [-3.88399358203462, 50.529372637103741],
            [-3.884561594585126, 50.528684401717484],
            [-3.884549914033445, 50.527045902472345],
            [-3.885733187354102, 50.52512272697242],
            [-3.886388362846966, 50.524640828690686],
            [-3.891160683887089, 50.523531776058128],
            [-3.890630152296753, 50.519647830094982],
            [-3.89257973063075, 50.513864528116088],
            [-3.891317765856773, 50.505901132655794],
            [-3.884305633026009, 50.501576385278234],
            [-3.883164574364121, 50.499585612106792],
            [-3.883844613555831, 50.498035741617997],
            [-3.883653809965041, 50.495898272597117],
            [-3.883018755771148, 50.495281673609362],
            [-3.882718237418177, 50.492830290397279],
            [-3.881213990945588, 50.490775220196795],
            [-3.879834304316217, 50.487308766290774],
            [-3.880213999131284, 50.485687317327049],
            [-3.88189081915861, 50.484072774643643],
            [-3.882324591345435, 50.482714865194687],
            [-3.883710563416099, 50.480809098390203],
            [-3.883692981882406, 50.480012517838531],
            [-3.888035583387059, 50.480114811961904],
            [-3.891506818641702, 50.479468424843759],
            [-3.891676661464343, 50.478391782977326],
            [-3.897869764826494, 50.474553022615453],
            [-3.898677350318278, 50.473814930248828],
            [-3.898960365356796, 50.472788593948238],
            [-3.903821562841942, 50.477184554853679],
            [-3.906240332132453, 50.476838253991573],
            [-3.909217314999035, 50.478453327337569],
            [-3.911372632954529, 50.480275197632437],
            [-3.913697011242288, 50.481253309471121],
            [-3.913773858717451, 50.481690052843945],
            [-3.914759378647175, 50.481869906859515],
            [-3.91995532815009, 50.481666457095962],
            [-3.921447287722113, 50.481056317513925],
            [-3.922388970987387, 50.480051421487346],
            [-3.923718271870632, 50.479498803174984],
            [-3.923875183446832, 50.47878118513384],
            [-3.925638154652773, 50.479779134458845],
            [-3.926357871969369, 50.480978712621905],
            [-3.927469561216919, 50.480898242044852],
            [-3.928288073976801, 50.481302900269284],
            [-3.931862726877709, 50.481924445919645],
            [-3.932164146409533, 50.482229733919844],
            [-3.93256913549351, 50.481963980016509],
            [-3.935866029906683, 50.483546979394895],
            [-3.93701542955009, 50.483663658646513],
            [-3.938961352939476, 50.484436209517419],
            [-3.945982498877589, 50.489092112653559],
            [-3.948755088854309, 50.488668858183829],
            [-3.948682210869128, 50.492756059569828],
            [-3.945765132172605, 50.498280428313407],
            [-3.946542255601064, 50.498010201996642],
            [-3.949078307743662, 50.49837068019005],
            [-3.952340533447272, 50.499071486323096],
            [-3.954829173505347, 50.500227705428756],
            [-3.955945751517877, 50.500255710421037],
            [-3.960176722463762, 50.499247336344787],
            [-3.962447368297605, 50.498201732721661],
            [-3.96436272544281, 50.496411981261993],
            [-3.964746779921767, 50.496322751849384],
            [-3.965294389873505, 50.494195410846245],
            [-3.967530478081545, 50.491987360740801],
            [-3.967948630047052, 50.48983611130172],
            [-3.97042029161631, 50.488147470752992],
            [-3.971297813453358, 50.487824110578643],
            [-3.971670708512048, 50.488044441850349],
            [-3.975135378460498, 50.487963191984932],
            [-3.97614868213405, 50.487448614899421],
            [-3.978631191293881, 50.487007987009385],
            [-3.979337595121414, 50.486504896404497],
            [-3.98214507196851, 50.485578381515545],
            [-3.984876902645619, 50.48389219118387],
            [-3.98466734676281, 50.483331838455939],
            [-3.987823257789067, 50.481511525732707],
            [-3.990912728573697, 50.481201467782974],
            [-3.992333711765801, 50.479093215590495],
            [-3.993887973415326, 50.47834616286756],
            [-3.993902105565158, 50.477878228601675],
            [-3.994713336420818, 50.47742810951727],
            [-3.996421148620359, 50.477350247344006],
            [-3.996712582801144, 50.476974689668687],
            [-3.997619660299733, 50.47732787544517],
            [-4.001240375516847, 50.477356489182512],
            [-4.005262673613014, 50.476416600663697],
            [-4.004418145248035, 50.475877118284856],
            [-4.006714924780598, 50.474199698803112],
            [-4.006528757185145, 50.473031878434377],
            [-4.00702866375221, 50.472489904985743],
            [-4.006464890393557, 50.472156055356642],
            [-4.007876686139936, 50.469687099959259],
            [-4.009261971016106, 50.469454518693773],
            [-4.011048992085308, 50.469590030956667],
            [-4.017620145327301, 50.468329499223955],
            [-4.023029354399548, 50.468741926223409],
            [-4.02442123918421, 50.46741266529623],
            [-4.024051302008186, 50.464657006399563],
            [-4.023280354161638, 50.463068563758057],
            [-4.023901831847597, 50.462047711801503],
            [-4.025193502246134, 50.462500121591653],
            [-4.027388993920469, 50.461747743845407],
            [-4.028891123595034, 50.462935762491938],
            [-4.02892872202574, 50.462365777923218],
            [-4.02968922947795, 50.462455943406418],
            [-4.029377418548048, 50.463499306831743],
            [-4.030038321430091, 50.463829552320334],
            [-4.031013883513524, 50.463808026478027],
            [-4.031586933089299, 50.463337520050516],
            [-4.032579148696907, 50.463310293682909],
            [-4.033892872634657, 50.46279625139961],
            [-4.035454367306114, 50.463787075911213],
            [-4.036212321796765, 50.463816982825108],
            [-4.037458255219548, 50.463496561439143],
            [-4.038639240157487, 50.462649308498307],
            [-4.042466484515673, 50.461871634044641],
            [-4.042894274444412, 50.461466580482501],
            [-4.042596332329658, 50.460470763399542],
            [-4.044173392727498, 50.459859789204728],
            [-4.04610054726581, 50.459559230121165],
            [-4.047460888360442, 50.458858033725214],
            [-4.050638766125403, 50.458777770205863],
            [-4.053079903865704, 50.457334317912078],
            [-4.057141390431314, 50.456742697857919],
            [-4.058389107100143, 50.455951623074505],
            [-4.060151761301437, 50.454043332575367],
            [-4.063989971318961, 50.454317967567036],
            [-4.065376277065078, 50.454868988021431],
            [-4.066804963186317, 50.454484743089814],
            [-4.067988066540022, 50.454510484778694],
            [-4.067822791299254, 50.455372367953693],
            [-4.066670499280276, 50.456053919411744],
            [-4.066311680961681, 50.45713060191779],
            [-4.065124542660549, 50.458242678335239],
            [-4.06514737533092, 50.459639968417349],
            [-4.06796477885452, 50.459628589925828],
            [-4.069341425719746, 50.459308199215073],
            [-4.070093521043636, 50.459495387444548],
            [-4.07134872811361, 50.4609103132969],
            [-4.072743498058005, 50.461519558403531],
            [-4.072717820091474, 50.46251117493486],
            [-4.075988220021855, 50.46395938138],
            [-4.07645510523065, 50.465224624617527],
            [-4.076166622721049, 50.465651600903158],
            [-4.077886323610665, 50.467271327899887],
            [-4.077228851977336, 50.467478243754151],
            [-4.077135054380231, 50.467845981766004],
            [-4.077765696613831, 50.469919570180863],
            [-4.077983676537169, 50.468816587980307],
            [-4.079389470305578, 50.468192455285845],
            [-4.098832778817155, 50.460693780012122],
            [-4.104247984708955, 50.455372100720453],
            [-4.107240523817412, 50.450399878767755],
            [-4.108178905494289, 50.454491440632879],
            [-4.110413914364398, 50.453967920214282],
            [-4.109233371321873, 50.457229955357121],
            [-4.109519559637743, 50.45938697788646],
            [-4.108539668352672, 50.462982646484093],
            [-4.116233942772929, 50.463064178745526],
            [-4.119727830175121, 50.46390178474104],
            [-4.120236622311382, 50.463746805474813],
            [-4.122815366425971, 50.464284399002679],
            [-4.123603103196204, 50.464505669684634],
            [-4.123638234925843, 50.46478654786889],
            [-4.1265632140423, 50.464778982443995],
            [-4.127769799336435, 50.465101397420007],
            [-4.133986114887921, 50.464240101772859],
            [-4.137076836905436, 50.464752696247487],
            [-4.138594756115637, 50.46326053498575],
            [-4.14250080967852, 50.46223436514056],
            [-4.144286249131799, 50.462082741372512],
            [-4.145878164412593, 50.46241855174906],
            [-4.147450033558964, 50.463275478382194],
            [-4.151444646290378, 50.46342831937438],
            [-4.151885600336855, 50.463922942348404],
            [-4.150492625923537, 50.465147638718648],
            [-4.15154126099813, 50.465059891163996],
            [-4.152427279931785, 50.464215127722831],
            [-4.153650368524849, 50.464090853966269],
            [-4.153200581212195, 50.46454979355439],
            [-4.155473610111664, 50.46371620022844],
            [-4.157522922581229, 50.462272402599936],
            [-4.157831606304457, 50.46137714271854],
            [-4.156314629488455, 50.458911161024517],
            [-4.158255971307957, 50.456648171528705],
            [-4.159565003448733, 50.456126490853862],
            [-4.16019902333817, 50.455477922057796],
            [-4.161480180502845, 50.455303916686432],
            [-4.162320215058766, 50.455373748991882],
            [-4.163243705538178, 50.456130086386985],
            [-4.163852910216519, 50.456080978697138],
            [-4.163356938403852, 50.456512036604806],
            [-4.164083641397704, 50.456475133183758],
            [-4.165207694923856, 50.45461937852992],
            [-4.166239940241956, 50.454147745373184],
            [-4.166711047448335, 50.45342032607747],
            [-4.168586347661571, 50.452162152348919],
            [-4.169565017246549, 50.451968512407177],
            [-4.170220582179444, 50.451025371764821],
            [-4.169743908726581, 50.450705972829773],
            [-4.170965389996856, 50.450462819464569],
            [-4.171522203512314, 50.449579077751729],
            [-4.171287127222452, 50.448629170277108],
            [-4.173103603843153, 50.44624772984001],
            [-4.173402982436389, 50.444881295804741],
            [-4.174610499561219, 50.444091509290871],
            [-4.174777589055406, 50.444325835045539],
            [-4.175754779528604, 50.4439504840417],
            [-4.175294558549075, 50.443712648266342],
            [-4.175243443100414, 50.4430588177324],
            [-4.17597500293849, 50.441259759850091],
            [-4.17995210685771, 50.437821401915777],
            [-4.18145964336026, 50.43737579732796],
            [-4.183461801226308, 50.436080816456034],
            [-4.184041479147168, 50.435029286473345],
            [-4.184716394182331, 50.434823231376093],
            [-4.185070336862099, 50.43428321722147],
            [-4.19072933611562, 50.435337024997843],
            [-4.19221542524342, 50.437265292562522],
            [-4.191950542241992, 50.437536967673658],
            [-4.192305317208629, 50.438052402369507],
            [-4.191163748643791, 50.440199279518268],
            [-4.191292681940475, 50.440856135610339],
            [-4.195305687473135, 50.442782596777576],
            [-4.198237264896443, 50.444739259374884],
            [-4.198940852693816, 50.445605605896326],
            [-4.198242462119784, 50.446370735131289],
            [-4.198405778930174, 50.447280573995215],
            [-4.199581883045882, 50.448402417870938],
            [-4.200532724540582, 50.451195163376511],
            [-4.200431862208125, 50.45354459366245],
            [-4.198662592448112, 50.453001515234249],
            [-4.195829797588075, 50.453767380883299],
            [-4.195739184281472, 50.453301386336506],
            [-4.195175620632857, 50.45311774645365],
            [-4.195258965193874, 50.451570947283692],
            [-4.195937429508388, 50.450920441566247],
            [-4.194897341466029, 50.450210633459946],
            [-4.194612828055653, 50.450972425654257],
            [-4.195066507632554, 50.452282433523848],
            [-4.194728796439656, 50.453264691313947],
            [-4.195281843249619, 50.4536167258264],
            [-4.19486046897428, 50.453917893268361],
            [-4.194472106427624, 50.453562749754425],
            [-4.194023852341655, 50.45383114226609],
            [-4.193571467253082, 50.454375736757733],
            [-4.193708841045614, 50.455030631176108],
            [-4.194484359900788, 50.455440534203184],
            [-4.195147921851067, 50.454345095767245],
            [-4.198363563247772, 50.453388527247917],
            [-4.198251461990522, 50.453826871146163],
            [-4.198569867142848, 50.453432297749551],
            [-4.198864084104271, 50.453549058898339],
            [-4.198125032992233, 50.454260089070139],
            [-4.198658821424993, 50.454469461751287],
            [-4.199099874190842, 50.453621052895578],
            [-4.198859311551183, 50.454175154741144],
            [-4.199138028271369, 50.4543524702772],
            [-4.19962053278199, 50.45382077563972],
            [-4.199615413609337, 50.45462136817433],
            [-4.199896966907131, 50.453979244434365],
            [-4.200473237221096, 50.454193201920852],
            [-4.200287364888197, 50.454589770659425],
            [-4.200654856112546, 50.454190665213019],
            [-4.200960301711864, 50.454306309219078],
            [-4.200640712005462, 50.454705408775773],
            [-4.201435493097931, 50.454436728449203],
            [-4.202494165975638, 50.456452104299331],
            [-4.200717783597556, 50.455485558178076],
            [-4.204089586619757, 50.457949120836247],
            [-4.205921224080624, 50.458706799009505],
            [-4.207819885390699, 50.46047863691372],
            [-4.209312038938365, 50.462715977620093],
            [-4.210431019596911, 50.463233479317225],
            [-4.21052710135929, 50.463813137111764],
            [-4.211721971339951, 50.465050080179132],
            [-4.212972123595486, 50.465749447401478],
            [-4.213044721332293, 50.466428937275168],
            [-4.215673778032193, 50.468287486768503],
            [-4.219592686537645, 50.468885550968174],
            [-4.222672782303614, 50.468063118195111],
            [-4.224580893932633, 50.46710292320558],
            [-4.225770841182369, 50.465255206738227],
            [-4.227362264588778, 50.464036590093642],
            [-4.22705848123395, 50.463929077950006],
            [-4.227501244662644, 50.463066136258426],
            [-4.227836980122621, 50.459416991788672],
            [-4.228597288978722, 50.457847301630174],
            [-4.229391322972873, 50.457175496458255],
            [-4.230678518350039, 50.456894477983766],
            [-4.232380981132144, 50.456965250397253],
            [-4.235617118131414, 50.458695672390427],
            [-4.235751473825092, 50.459303807586032],
            [-4.236101692613439, 50.459290779220858],
            [-4.235620434852097, 50.459572559601],
            [-4.236408136808223, 50.459454881115207],
            [-4.23687444938339, 50.460438892622186],
            [-4.236470398559915, 50.461013307447367],
            [-4.237015750798591, 50.460686218592976],
            [-4.237381356819654, 50.462043632173426],
            [-4.236059784542851, 50.462553841780199],
            [-4.234890862924362, 50.463881389097644],
            [-4.235517095249976, 50.463714652177735],
            [-4.235719401131893, 50.464120007375847],
            [-4.236252736107113, 50.462701237676434],
            [-4.23741550037008, 50.462468408955495],
            [-4.237148748754987, 50.464369551888083],
            [-4.236567476180086, 50.465309847679755],
            [-4.233874604390356, 50.465220383226267],
            [-4.236418539171333, 50.465468313779212],
            [-4.235498213128746, 50.466480781019328],
            [-4.234285658206511, 50.467303676533724],
            [-4.233642243104556, 50.467286352077309],
            [-4.231784643451063, 50.468058645185586],
            [-4.231030632576383, 50.468685624780015],
            [-4.229220659650665, 50.469304058775919],
            [-4.218647848987789, 50.474499849632238],
            [-4.218016197597586, 50.474103552559576],
            [-4.218038257145651, 50.474875744738682],
            [-4.216466233287419, 50.475135962902783],
            [-4.216139494441373, 50.475502860430041],
            [-4.216624539149867, 50.475205800533196],
            [-4.217678896098726, 50.475358396080317],
            [-4.216823087469194, 50.47712950078887],
            [-4.217133134236029, 50.482488703942394],
            [-4.217748968472965, 50.484561847927353],
            [-4.219378304638782, 50.485608294808586],
            [-4.219243271846627, 50.485795254243797],
            [-4.219905751845691, 50.485823089229605],
            [-4.221442325416653, 50.486903657111348],
            [-4.223147531166868, 50.489142148729563],
            [-4.222954005050664, 50.490065065588517],
            [-4.221146464940197, 50.491446037307497],
            [-4.219891921479889, 50.493106046365156],
            [-4.220803119807038, 50.497742309192667],
            [-4.219679777268218, 50.497933738340144],
            [-4.210785483963966, 50.496469722749538],
            [-4.207615261760025, 50.495433516248262],
            [-4.204944768410854, 50.492832641858406],
            [-4.203683932746546, 50.492219735032648],
            [-4.202949732141811, 50.492288504664955],
            [-4.202815374293531, 50.491915986883058],
            [-4.202433552168366, 50.492164262560934],
            [-4.199191206742544, 50.492205809665755],
            [-4.198259521380177, 50.491483187917218],
            [-4.198838550106086, 50.492262843299812],
            [-4.194841062582682, 50.493229658847284],
            [-4.193170898691157, 50.495069903253516],
            [-4.193260252328422, 50.495902887281915],
            [-4.19490010424088, 50.49790916501756],
            [-4.194681776461007, 50.498431353540163],
            [-4.193197152757786, 50.498740869337993],
            [-4.189582996971904, 50.498131673195076],
            [-4.187876172976381, 50.498607199078407],
            [-4.185008136929175, 50.499588403917933],
            [-4.182524009351765, 50.499662011549724],
            [-4.180419985992468, 50.500885104074108],
            [-4.180116860225559, 50.503060196989878],
            [-4.183695588545932, 50.507939023087403],
            [-4.185306823429405, 50.508500594566343],
            [-4.186985180141624, 50.508438480430911],
            [-4.189870168462867, 50.507782503959227],
            [-4.191667378353877, 50.506967964294979],
            [-4.192293346241085, 50.506232134189403],
            [-4.192870948388317, 50.506522557412161],
            [-4.192360619273788, 50.506070768868732],
            [-4.193154596216598, 50.504304623672759],
            [-4.194268067474538, 50.503012740974576],
            [-4.196732946889028, 50.503067864854479],
            [-4.1995995418595, 50.502693511607625],
            [-4.202441856303178, 50.50313172985328],
            [-4.20301921974329, 50.505113030497995],
            [-4.202337536960882, 50.506860030431113],
            [-4.20143636503114, 50.512348294143507],
            [-4.201602624594615, 50.513465833074861],
            [-4.203278235799766, 50.513795686424224],
            [-4.208229515983744, 50.513284494039986],
            [-4.209015092082541, 50.51349354013928],
            [-4.209455550700193, 50.514135462868779],
            [-4.209036472681031, 50.515522240376505],
            [-4.206731196079891, 50.517300075075696],
            [-4.205838254885387, 50.518934172976238],
            [-4.206157240256327, 50.519058542412843],
            [-4.206308837147819, 50.520493849482314],
            [-4.206981299367887, 50.521632361934259],
            [-4.211122700308637, 50.525508516673348],
            [-4.212088787298635, 50.528847710108117],
            [-4.211369284781214, 50.530027039341952],
            [-4.206995904896544, 50.532767860421984],
            [-4.20678623427207, 50.533143298569037],
            [-4.207197155064576, 50.533542045203944],
            [-4.208308568354481, 50.533771000415221],
            [-4.209892089025564, 50.533533174186111],
            [-4.213265385261101, 50.534540282818995],
            [-4.214475208452341, 50.534579328057973],
            [-4.218894962208271, 50.532050504438324],
            [-4.225983600689828, 50.529693546110742],
            [-4.229256931542588, 50.530823526627032],
            [-4.232518131444333, 50.531095595191218],
            [-4.234415309587685, 50.532064743223636],
            [-4.235904426457055, 50.533206296691162],
            [-4.237379375375122, 50.535001087821307],
            [-4.23911641692189, 50.539585337264263],
            [-4.24071883760956, 50.542156537174556],
            [-4.24283408874921, 50.543216690495576],
            [-4.246741603333737, 50.543851868826494],
            [-4.253441675120158, 50.543638684435201],
            [-4.25470665745304, 50.542550160822799],
            [-4.255534684585944, 50.540380884479809],
            [-4.257800555297045, 50.538945531007627],
            [-4.263470769295713, 50.536015673946935],
            [-4.268739062695457, 50.535281686524065],
            [-4.269667289703311, 50.53550913138276],
            [-4.271630858882575, 50.536753407411773],
            [-4.271703024624174, 50.537755763537746],
            [-4.27106247560992, 50.539081434272028],
            [-4.267006094394601, 50.542432648472506],
            [-4.26396142243732, 50.543548746300381],
            [-4.260997115219203, 50.543928368889603],
            [-4.260002327569671, 50.544554805162825],
            [-4.259265993780247, 50.545775234998381],
            [-4.259924167540229, 50.547731304718148],
            [-4.259431673161905, 50.550037106136749],
            [-4.259922634272123, 50.552155619703179],
            [-4.261425137692767, 50.554670915999544],
            [-4.263440891922404, 50.556991830446421],
            [-4.265960825338683, 50.558869372123588],
            [-4.272462296827243, 50.56164597171427],
            [-4.273396036907169, 50.562504679969855],
            [-4.27353654145761, 50.564807168538138],
            [-4.274400241169875, 50.565939763399975],
            [-4.279485269130528, 50.569183464867812],
            [-4.279313533708527, 50.570862461209373],
            [-4.277828156130633, 50.57479235199137],
            [-4.269693731534981, 50.5789869642106],
            [-4.26977376783228, 50.579887527023274],
            [-4.271622241969358, 50.582604610670899],
            [-4.284688203551036, 50.584798949248579],
            [-4.285974699514933, 50.584672058581106],
            [-4.28652392279849, 50.58399479730646],
            [-4.286130592472101, 50.582757715948851],
            [-4.282297058344983, 50.57894021968157],
            [-4.28179732661376, 50.577544212411794],
            [-4.282667530867612, 50.576231976726433],
            [-4.285441648459809, 50.575007389448629],
            [-4.286529873717848, 50.575108340831186],
            [-4.288110282743115, 50.575834604553727],
            [-4.289157041428219, 50.576769216740963],
            [-4.289593292353115, 50.578104381983344],
            [-4.289678729344343, 50.581990926163229],
            [-4.290992100453793, 50.583728862790792],
            [-4.293032826276744, 50.584297595587373],
            [-4.294719785137999, 50.58438847544727],
            [-4.298342882599336, 50.583710786057367],
            [-4.305209802468438, 50.579050879448275],
            [-4.306575484451119, 50.578583106393353],
            [-4.308031570843651, 50.578953592120889],
            [-4.308302293884275, 50.581860577236313],
            [-4.311187842559551, 50.584879372674578],
            [-4.312142158363056, 50.589506873506515],
            [-4.311136535096825, 50.591907629508221],
            [-4.307466043696818, 50.59350317004435],
            [-4.306249473625159, 50.594919590382091],
            [-4.306895903938594, 50.600018227932011],
            [-4.308423608184672, 50.602704216418203],
            [-4.307124393539393, 50.604255396349572],
            [-4.303398562992562, 50.611538961600935],
            [-4.303698658831821, 50.612212989726657],
            [-4.306358450726925, 50.614109388645765],
            [-4.305732954103961, 50.615713758672342],
            [-4.306395721208142, 50.616864488018663],
            [-4.307457753132158, 50.618430931102949],
            [-4.307435751042646, 50.620233813690646],
            [-4.30613655118129, 50.620928723372266],
            [-4.302079013488853, 50.625329841899529],
            [-4.301736341764197, 50.626737916697849],
            [-4.302604265673761, 50.629654674506796],
            [-4.301916561464743, 50.631236863303776],
            [-4.300088678928407, 50.632551959302241],
            [-4.300077200176915, 50.633417429751944],
            [-4.300764057723938, 50.634450788304136],
            [-4.30229674316292, 50.635601437694824],
            [-4.304039225663463, 50.636182180273536],
            [-4.308977878145565, 50.635720997885336],
            [-4.310503024487959, 50.636424677017033],
            [-4.311571955510821, 50.637456687967976],
            [-4.3138784026942, 50.636126507304873],
            [-4.319542488159627, 50.63601022912912],
            [-4.325868120010969, 50.637892476645483],
            [-4.325991283712296, 50.638496228683103],
            [-4.325207496966851, 50.640238774977753],
            [-4.325870236084688, 50.640570020025713],
            [-4.328009823676727, 50.640762018328097],
            [-4.329825518747246, 50.640239119206207],
            [-4.33108382570663, 50.640428901206356],
            [-4.332794517563492, 50.64081377804203],
            [-4.333646258086839, 50.641446997511757],
            [-4.33329350903103, 50.64357670772867],
            [-4.335097824410594, 50.646445684931045],
            [-4.334484918626681, 50.647208092141668],
            [-4.332895593436357, 50.64767885827667],
            [-4.332910276679219, 50.648060819303907],
            [-4.331716273419548, 50.648202555237177],
            [-4.33172123159963, 50.648702535526574],
            [-4.332303034221329, 50.648653109953585],
            [-4.332456358272362, 50.649178001080514],
            [-4.331444348611474, 50.649253129374486],
            [-4.331572627118198, 50.648844021890298],
            [-4.330472176023514, 50.648961386371617],
            [-4.329720567752909, 50.648524013904279],
            [-4.328661613192071, 50.649566037661678],
            [-4.327297119480177, 50.649694047629858],
            [-4.326921720164847, 50.649397543651858],
            [-4.326339948427362, 50.649791420553171],
            [-4.325549928685594, 50.649664190050444],
            [-4.323026569707, 50.651421652421476],
            [-4.323037911719343, 50.652282173181163],
            [-4.321597280169312, 50.653306558997549],
            [-4.319525990360789, 50.653018640173762],
            [-4.319165558760217, 50.653657211717238],
            [-4.318279418563253, 50.653475182052624],
            [-4.317322567285557, 50.653839601869315],
            [-4.317757319615665, 50.65427886271484],
            [-4.316698484076936, 50.655043750177413],
            [-4.317332463694869, 50.655621158074013],
            [-4.316992449991181, 50.655841984810415],
            [-4.316332375276617, 50.655424291558177],
            [-4.315074430578328, 50.656917156999462],
            [-4.315778800468865, 50.658838707024366],
            [-4.314181260605896, 50.66027625287024],
            [-4.314135816170207, 50.661280008938967],
            [-4.313141573235939, 50.662240551399925],
            [-4.309109648128474, 50.663067970695828],
            [-4.307849313746493, 50.66359752357365],
            [-4.305873490085922, 50.663445978689843],
            [-4.304012659646481, 50.664688922193392],
            [-4.295493171120643, 50.665695388254726],
            [-4.292727983137695, 50.665289348595053],
            [-4.290681267384389, 50.666501556834561],
            [-4.28973317157688, 50.666154135744648],
            [-4.284922256470121, 50.666021955454497],
            [-4.277287517302636, 50.66906398826108],
            [-4.275754720182848, 50.668126175414969],
            [-4.273623057284532, 50.667855706370226],
            [-4.272627446875889, 50.668046024158883],
            [-4.271029782882905, 50.667696732483563],
            [-4.269663789051212, 50.668159572556945],
            [-4.26634306207458, 50.668587592511571],
            [-4.263900164140953, 50.668348184331144],
            [-4.263246929877518, 50.66803708987365],
            [-4.262052546371724, 50.668214989466243],
            [-4.259915354378514, 50.667385842222544],
            [-4.259296807271486, 50.667413131253966],
            [-4.257917019951939, 50.666588142207154],
            [-4.256542481235488, 50.666432197937667],
            [-4.256454195371067, 50.666951969543327],
            [-4.255164005263293, 50.666430113155236],
            [-4.254819922568021, 50.667134717880337],
            [-4.252829267785107, 50.667618443890156],
            [-4.251653210818538, 50.669835746658393],
            [-4.249979638003844, 50.670797176165998],
            [-4.2493836119208, 50.671740476534062],
            [-4.247105447153108, 50.672817868490021],
            [-4.245407209185353, 50.672940557043859],
            [-4.243428336593498, 50.673589383964043],
            [-4.24036558895389, 50.673027736335953],
            [-4.237901919917244, 50.672026382170571],
            [-4.235000364521883, 50.671457899803919],
            [-4.228088769664553, 50.67135555997158],
            [-4.223665774144181, 50.671592075991619],
            [-4.222175062973169, 50.671254464359528],
            [-4.220908681736405, 50.671650970209988],
            [-4.220843297734351, 50.672454487837882],
            [-4.21882975744114, 50.67279505912596],
            [-4.219292085353784, 50.673954581213472],
            [-4.217739622749062, 50.674649708179551],
            [-4.217886968800484, 50.675012961275996],
            [-4.218784311924373, 50.675162263290495],
            [-4.218782217049307, 50.675567935447539],
            [-4.218177616968415, 50.675863661952988],
            [-4.216646695482486, 50.675756093348461],
            [-4.216407065939705, 50.676047563388138],
            [-4.215487314930473, 50.675873485813277],
            [-4.213722237380202, 50.677226469480772],
            [-4.214051604153521, 50.677668113951754],
            [-4.212581432575674, 50.678040518808785],
            [-4.212870642301049, 50.679012678889826],
            [-4.209136969179426, 50.681555105871446],
            [-4.211332238729614, 50.685521201798345],
            [-4.214925740383423, 50.688984897071613],
            [-4.216413436827104, 50.689968433375022],
            [-4.219543447845727, 50.691367678266168],
            [-4.224094779528826, 50.692128120168995],
            [-4.226334438189131, 50.692718524157122],
            [-4.228654257623106, 50.693803823604618],
            [-4.230024804388889, 50.693950283932814],
            [-4.230993193207094, 50.69499124347314],
            [-4.231923956233624, 50.695388042326357],
            [-4.231892899034921, 50.696046102501342],
            [-4.23098747400244, 50.695944722442256],
            [-4.230308570253871, 50.696737504816369],
            [-4.228323034712368, 50.697420370026791],
            [-4.22781322123519, 50.699122798572354],
            [-4.228307609030683, 50.69969346000515],
            [-4.227277620092722, 50.700371518167877],
            [-4.227164171006868, 50.700906134022574],
            [-4.227801977914573, 50.700827384449418],
            [-4.227273901777265, 50.702085853149704],
            [-4.227731221874391, 50.702113086198288],
            [-4.227857826665411, 50.703558705542157],
            [-4.228403112423394, 50.703708371733399],
            [-4.227977144786562, 50.704312822481874],
            [-4.22864077393254, 50.705255297393492],
            [-4.228290675071572, 50.705488643059297],
            [-4.22902966408583, 50.705988966796419],
            [-4.228528718892385, 50.706924035253408],
            [-4.229141105967251, 50.707412389230235],
            [-4.228901872143832, 50.708161671785383],
            [-4.229873096132073, 50.708599985756834],
            [-4.230284299320674, 50.709474430193303],
            [-4.231947267880146, 50.709803248163887],
            [-4.231808001277182, 50.710300587593117],
            [-4.233435650141307, 50.71098802358668],
            [-4.233935559055647, 50.712028044069399],
            [-4.234770194045771, 50.712421267972758],
            [-4.234688170732081, 50.714270215320866],
            [-4.234032663677226, 50.714546309994006],
            [-4.233499831196883, 50.715436140522641],
            [-4.233889381060863, 50.716031272940093],
            [-4.233339480094483, 50.716085884570873],
            [-4.233825219645963, 50.716528973279438],
            [-4.232735210025554, 50.717172250717354],
            [-4.232941016641859, 50.717776303146834],
            [-4.232249456985759, 50.718517169703929],
            [-4.233890992020487, 50.722111202154728],
            [-4.233355403639321, 50.722825699953731],
            [-4.232538741347107, 50.722635385883081],
            [-4.230915881026351, 50.723488519221874],
            [-4.231561951101555, 50.72408414412849],
            [-4.227912416442162, 50.727377427818261],
            [-4.227688582298653, 50.728782073393432],
            [-4.225776524732892, 50.731432264942562],
            [-4.227369276064564, 50.733819420312052],
            [-4.229131083396231, 50.73501791189986],
            [-4.227378383267655, 50.737296324397597],
            [-4.227992581618571, 50.739635613345094],
            [-4.227253186287585, 50.74164641133877],
            [-4.226797162203222, 50.742162386080928],
            [-4.223125671613358, 50.74362204758927],
            [-4.221473067022123, 50.745033234861786],
            [-4.220740108861178, 50.746708389794549],
            [-4.218844190985738, 50.746365852937942],
            [-4.218167970974541, 50.748164027206549],
            [-4.212990030723734, 50.751303313961493],
            [-4.210386262326281, 50.75251923751334],
            [-4.200926954627078, 50.754633737165989],
            [-4.196050093419357, 50.75658117108803],
            [-4.195233974781335, 50.755139527936031],
            [-4.193435748305637, 50.756646586540832],
            [-4.193895081026071, 50.757978936087568],
            [-4.185949148515284, 50.757676727733532],
            [-4.194627076868169, 50.761834341712493],
            [-4.198497063087277, 50.763289482350189],
            [-4.20135430575265, 50.76533923680379],
            [-4.202501321445555, 50.766571322857757],
            [-4.202231376525441, 50.766681651940665],
            [-4.203326328160596, 50.768947215772272],
            [-4.202952212898902, 50.769044225660728],
            [-4.205768785139617, 50.774919755666524],
            [-4.203656008042925, 50.775326665080769],
            [-4.203358953454472, 50.776219079736613],
            [-4.196900743449289, 50.779791924799007],
            [-4.191104770650317, 50.781619736433356],
            [-4.190447551134287, 50.781489983376375],
            [-4.187492571469182, 50.782068011913211],
            [-4.186863078384156, 50.782988203980722],
            [-4.182538658272956, 50.784291509368515],
            [-4.181895536890964, 50.784769430034821],
            [-4.179247304809961, 50.784689450385336],
            [-4.177453837491329, 50.785753658236011],
            [-4.175083608153814, 50.786009258564818],
            [-4.174677823698263, 50.786345104425337],
            [-4.173813243194825, 50.786211030756668],
            [-4.171503784319001, 50.78676851792541],
            [-4.169495014766927, 50.78628337229258],
            [-4.166394428103745, 50.787007472946854],
            [-4.167539010129335, 50.788471084049945],
            [-4.168122709431448, 50.788329819897577],
            [-4.169028540879374, 50.791560651046758],
            [-4.179798737971651, 50.790930778856833],
            [-4.184124621415336, 50.789187753041432],
            [-4.187810399662, 50.789117746914734],
            [-4.190733603424428, 50.789610582383446],
            [-4.188879694718736, 50.792264419570039],
            [-4.188265238497419, 50.792931607066691],
            [-4.187356584134724, 50.793194190495221],
            [-4.187276165838197, 50.793963775601682],
            [-4.185448226377263, 50.794414469656147],
            [-4.183680803881852, 50.795646467402655],
            [-4.182292764510296, 50.796159922997312],
            [-4.181709552063361, 50.79628236141167],
            [-4.180545237162595, 50.795932695985414],
            [-4.180183639463387, 50.796456604836258],
            [-4.178930312300076, 50.796572670664325],
            [-4.178514648741344, 50.797124566078672],
            [-4.177190884818979, 50.797496455110512],
            [-4.176455879826021, 50.797529064691737],
            [-4.17586580922706, 50.797138052803533],
            [-4.173807434008855, 50.797704386386329],
            [-4.170278678381316, 50.79695168334198],
            [-4.169990944033333, 50.797387843928988],
            [-4.168739257520495, 50.797662960279744],
            [-4.17099073662606, 50.798720107497836],
            [-4.172690990310232, 50.799120141402511],
            [-4.172943254326247, 50.799602007347943],
            [-4.17547581318359, 50.800809423703839],
            [-4.176229975601514, 50.801493272587301],
            [-4.175906363075949, 50.802683801398558],
            [-4.174848562257641, 50.803193700964293],
            [-4.173721180415401, 50.80462046070555],
            [-4.174306335830213, 50.805454071978552],
            [-4.177185201908399, 50.807172139947866],
            [-4.175191926050145, 50.808445993179461],
            [-4.172992310272935, 50.809068905924555],
            [-4.172752582819023, 50.809775791276799],
            [-4.170747933004675, 50.811146012435479],
            [-4.163272709328271, 50.813449742158028],
            [-4.158213903202856, 50.815464557596343],
            [-4.159160941215767, 50.81725208286052],
            [-4.158553920044303, 50.817357755606984],
            [-4.158463276828254, 50.817854991937885],
            [-4.159952161581737, 50.819722418023687],
            [-4.159273677643373, 50.822033798068666],
            [-4.158342205351556, 50.823171668414794],
            [-4.162126670650432, 50.827145175789774],
            [-4.162919040006037, 50.829325867796705],
            [-4.163864322348756, 50.829837167527586],
            [-4.16399316598174, 50.830903239358811],
            [-4.160600371959757, 50.829517244899549],
            [-4.158790711133818, 50.830183919831207],
            [-4.15582425715913, 50.829786406011728],
            [-4.154725913126365, 50.829927220757902],
            [-4.15546800003714, 50.831392984756796],
            [-4.156713511429179, 50.831671250186524],
            [-4.155920324688398, 50.831685915653026],
            [-4.155023901352548, 50.832912146648063],
            [-4.153631533241311, 50.8325601279371],
            [-4.152878383244161, 50.833105565409603],
            [-4.151574809929739, 50.83224463416942],
            [-4.151619216703792, 50.831295870981016],
            [-4.147529957780503, 50.830847804594633],
            [-4.14509811673518, 50.830989691850178],
            [-4.140716247687107, 50.832120677169868],
            [-4.138943755175686, 50.833080455018255],
            [-4.138369091923022, 50.834489522168667],
            [-4.138931036921488, 50.835410970751688],
            [-4.138414079683023, 50.835939390036792],
            [-4.138966614689246, 50.836561518888786],
            [-4.138708333584177, 50.837156245637189],
            [-4.139608455894985, 50.837645185168107],
            [-4.138605486995764, 50.839317531257791],
            [-4.137848160876238, 50.839933097129105],
            [-4.13703889792405, 50.840034267840501],
            [-4.135735316608026, 50.838922235567615],
            [-4.135022462161015, 50.838894815110486],
            [-4.133215323973741, 50.841469513448445],
            [-4.132205840730574, 50.84160399150236],
            [-4.131956864385551, 50.84221831826985],
            [-4.130917303590686, 50.841880264232543],
            [-4.129943409026303, 50.842016770503555],
            [-4.13015129088098, 50.842713586093183],
            [-4.129455467496915, 50.843497954422112],
            [-4.128913598327277, 50.84407625007826],
            [-4.127698507736495, 50.844064245984157],
            [-4.124347825461395, 50.842883287365233],
            [-4.122666867050915, 50.843028111639953],
            [-4.120991385070121, 50.841853432573267],
            [-4.119211512524958, 50.838310779486513],
            [-4.118260020738969, 50.837655333369206],
            [-4.116154081655415, 50.837758317067852],
            [-4.115061338271297, 50.837495735554668],
            [-4.114082904233296, 50.838127746147315],
            [-4.113492479960884, 50.837477408091836],
            [-4.112735738860589, 50.838081106706895],
            [-4.110703283008176, 50.838491145150314],
            [-4.109520787935937, 50.837435091360817],
            [-4.107678316061609, 50.837459407800523],
            [-4.1073517959233, 50.836643282267978],
            [-4.10628204712745, 50.836227310220231],
            [-4.104577479943547, 50.836569263601497],
            [-4.103388358617907, 50.835519563604862],
            [-4.102853694275176, 50.835441965816749],
            [-4.103306940749319, 50.838035673779331],
            [-4.102672296197395, 50.838988758276109],
            [-4.103326810797006, 50.840845849327025],
            [-4.102951134892957, 50.84285192398368],
            [-4.104207852664964, 50.844105465339808],
            [-4.104817970961225, 50.846014612412247],
            [-4.101289076286549, 50.846547710532512],
            [-4.103107364239919, 50.850303983821597],
            [-4.094720448543789, 50.852480277258053],
            [-4.085385020117585, 50.857744259543544],
            [-4.085891531076967, 50.858841423968272],
            [-4.086942392830594, 50.859017790837726],
            [-4.089401705134408, 50.861615205982751],
            [-4.089393262068763, 50.864667805522224],
            [-4.09011103091857, 50.866289994614],
            [-4.089943143825745, 50.867365945623334],
            [-4.084940396346758, 50.869148067251878],
            [-4.079310012327774, 50.871845919679139],
            [-4.077444757516114, 50.871708270564035],
            [-4.071200680869453, 50.873437246543638],
            [-4.068656168461697, 50.874620085810349],
            [-4.066465531816427, 50.872398811795946],
            [-4.067396314431088, 50.872169172289517],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000048",
        LAD13CDO: "19UC",
        LAD13NM: "Christchurch",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-1.741125084325582, 50.721543534746061],
              [-1.742697540169287, 50.721541616077005],
              [-1.742805694810832, 50.722228895666575],
              [-1.742250731214994, 50.722938992893994],
              [-1.740794950993895, 50.723611119710782],
              [-1.74038598438462, 50.722502312050523],
              [-1.741125084325582, 50.721543534746061],
            ],
          ],
          [
            [
              [-1.770288269492051, 50.733292346926525],
              [-1.769698182003694, 50.731105966374948],
              [-1.772324893037317, 50.732909651846597],
              [-1.773889059507903, 50.734710325184089],
              [-1.77370916461224, 50.735285506994693],
              [-1.772031193038397, 50.736497151038066],
              [-1.771751087748435, 50.736399483035029],
              [-1.771170822730285, 50.735084519390014],
              [-1.771551132396568, 50.734971956644479],
              [-1.770288269492051, 50.733292346926525],
            ],
          ],
          [
            [
              [-1.80355560318159, 50.74015060734618],
              [-1.801191455632843, 50.739214967618267],
              [-1.804439770582187, 50.739505519789645],
              [-1.804595979074358, 50.739769265959964],
              [-1.80355560318159, 50.74015060734618],
            ],
          ],
          [
            [
              [-1.842250795391941, 50.805440674577959],
              [-1.840602405547406, 50.804409690643304],
              [-1.837893536052593, 50.801878158853647],
              [-1.833857148674712, 50.800051497847498],
              [-1.819670064883756, 50.798215766113877],
              [-1.816814623023679, 50.797629505440646],
              [-1.813457992722209, 50.796070279680393],
              [-1.811206082462777, 50.795759116691279],
              [-1.80756258729658, 50.797802576854124],
              [-1.806215561175553, 50.796885806757317],
              [-1.805927293570708, 50.796255848847949],
              [-1.803912245699898, 50.795975513017858],
              [-1.804804461291255, 50.795694640692403],
              [-1.803963861417042, 50.794828148388675],
              [-1.801451967842979, 50.794626069728722],
              [-1.80087519802295, 50.794128698338021],
              [-1.803571511710292, 50.792974121261388],
              [-1.803520441624887, 50.791956076533054],
              [-1.805612108639971, 50.792535100237806],
              [-1.805974557024447, 50.792031219643718],
              [-1.804273351754199, 50.791381815928339],
              [-1.800972636984011, 50.791895110317519],
              [-1.801262472713191, 50.791127638202788],
              [-1.802622899910132, 50.790819701019849],
              [-1.80081139943427, 50.787431821053673],
              [-1.79859516927814, 50.785864211431516],
              [-1.797646299851963, 50.785830198229675],
              [-1.79622258475584, 50.786364580194856],
              [-1.795684076996472, 50.78591221198613],
              [-1.795843479844392, 50.785465559441882],
              [-1.797328477471863, 50.784531118483265],
              [-1.797530020195789, 50.783847132454525],
              [-1.79572351884197, 50.783368279443536],
              [-1.794352622912456, 50.782219319254516],
              [-1.79213072507345, 50.782676705821636],
              [-1.790987905652295, 50.78034288839995],
              [-1.78787553380486, 50.778866089412176],
              [-1.788537157301936, 50.778086732133971],
              [-1.788736564421067, 50.77695402614038],
              [-1.790506999077329, 50.77654445502688],
              [-1.79075662624645, 50.776223866844354],
              [-1.789964123011295, 50.774569604980059],
              [-1.788173224890345, 50.774500724599719],
              [-1.787873650302807, 50.774260078480239],
              [-1.788125464233732, 50.773770438893266],
              [-1.78888969935432, 50.773493052202639],
              [-1.789550497265807, 50.772244273202929],
              [-1.790507478116721, 50.771668668603731],
              [-1.790178711944469, 50.770980144251915],
              [-1.788508776893499, 50.771162379810143],
              [-1.789695348782514, 50.770299433948857],
              [-1.789564994722689, 50.769958379399064],
              [-1.787700089552722, 50.770615061484961],
              [-1.78724964730231, 50.770501834232888],
              [-1.788841355475421, 50.767843810526877],
              [-1.788284163635554, 50.76688059249846],
              [-1.785993323704791, 50.765498748889719],
              [-1.785503238499389, 50.764763153235364],
              [-1.785017399842493, 50.764961895906602],
              [-1.785194825896168, 50.765851591847223],
              [-1.784128161473141, 50.765930558511421],
              [-1.783773611095722, 50.765635844146239],
              [-1.783886379318427, 50.76485639327953],
              [-1.783432129281937, 50.764660412032264],
              [-1.782260622784657, 50.765032326375099],
              [-1.781284501679471, 50.766680646788274],
              [-1.779998095229403, 50.76734997849838],
              [-1.77905499855937, 50.767376974353013],
              [-1.776072100946982, 50.766364120093854],
              [-1.775606788050836, 50.767003500907009],
              [-1.7731691525543, 50.768201103553828],
              [-1.768888801560512, 50.769794294550572],
              [-1.769943430321277, 50.771326918732399],
              [-1.770124584909261, 50.772560161615402],
              [-1.768027651291564, 50.773315890042412],
              [-1.767582865231036, 50.772923827406579],
              [-1.756564924347131, 50.778430928918965],
              [-1.752083573288404, 50.778856742594449],
              [-1.749066031931616, 50.779521144915464],
              [-1.745696595649002, 50.776267527454095],
              [-1.748296593364789, 50.775151783363889],
              [-1.746394194388656, 50.773414792233595],
              [-1.744793205724615, 50.773128036968536],
              [-1.741247719453225, 50.767218383005257],
              [-1.739686887797679, 50.765358837262326],
              [-1.738966776087117, 50.76328173261394],
              [-1.739884059874095, 50.762784689245144],
              [-1.740125184789667, 50.76097501086776],
              [-1.741691046616131, 50.760349901142611],
              [-1.741420834762919, 50.758698256498775],
              [-1.741831184728524, 50.758326869744074],
              [-1.742023121830657, 50.756678944731163],
              [-1.744663236016139, 50.756084040695384],
              [-1.744360509548839, 50.753619396284385],
              [-1.743604138673789, 50.752726567265569],
              [-1.74358064226941, 50.750018831649093],
              [-1.74234025987627, 50.749259819397011],
              [-1.74417570502149, 50.747466226123635],
              [-1.728141914599875, 50.750924530871757],
              [-1.717976541014453, 50.752180068405664],
              [-1.714992069717084, 50.752036131865736],
              [-1.713600131395349, 50.752254843453265],
              [-1.712707047569042, 50.752259843038431],
              [-1.712711216381558, 50.752505352461512],
              [-1.703927643801882, 50.752571532902842],
              [-1.690620261026223, 50.751616174385603],
              [-1.681825696841801, 50.751859614704671],
              [-1.681681281714147, 50.750160509264404],
              [-1.682812236539469, 50.746620482221694],
              [-1.684652289056072, 50.743830549670854],
              [-1.687924788903472, 50.742127142560769],
              [-1.6885827969232, 50.741423875233693],
              [-1.689025337095657, 50.741582426774087],
              [-1.690827334732538, 50.740613308441773],
              [-1.691984486054763, 50.739422141866562],
              [-1.692554902434729, 50.737362526241206],
              [-1.692382599197778, 50.736840497363829],
              [-1.693986472115948, 50.736880684253052],
              [-1.694244506803714, 50.736207809460197],
              [-1.69439130982034, 50.736731567394003],
              [-1.695858416316476, 50.736883778723715],
              [-1.697773641658391, 50.73676735271642],
              [-1.69790410682528, 50.73631176209053],
              [-1.697966453472282, 50.736753463554862],
              [-1.698448067126056, 50.736783485404679],
              [-1.707334982529632, 50.736716180588552],
              [-1.715598865175662, 50.73586252149839],
              [-1.722222145108821, 50.734665360521497],
              [-1.72459188087273, 50.7340981428801],
              [-1.730195974446828, 50.731898134699065],
              [-1.734191433715543, 50.728940618384932],
              [-1.734013257311666, 50.728629065883851],
              [-1.734897120956925, 50.728168853053852],
              [-1.735470690584916, 50.727204340697554],
              [-1.736955814732172, 50.726892053944361],
              [-1.74113378790828, 50.724080390474832],
              [-1.742319674095301, 50.723542554219485],
              [-1.742013933632966, 50.725302646060996],
              [-1.741482613661351, 50.725053272516952],
              [-1.740688255342935, 50.725490353328624],
              [-1.741401960457982, 50.726065669702514],
              [-1.740800941226176, 50.726379979682889],
              [-1.740571501572775, 50.727136653528241],
              [-1.741525298386928, 50.727082115621322],
              [-1.743101199535117, 50.727520840213344],
              [-1.743434306407874, 50.728270662831285],
              [-1.743725406828948, 50.727631023726723],
              [-1.745506246724333, 50.727907398065199],
              [-1.746574395200927, 50.727667818977679],
              [-1.746731469164969, 50.727177159723816],
              [-1.745769054140388, 50.727254203135715],
              [-1.74578694449183, 50.727613949185049],
              [-1.745380210813954, 50.727372958203723],
              [-1.745868259627662, 50.726986437367941],
              [-1.747330988013924, 50.726609222472021],
              [-1.747443183745275, 50.726822591745723],
              [-1.747532543580947, 50.726539515693673],
              [-1.75043158148671, 50.72594324043925],
              [-1.753046424246965, 50.726573792810449],
              [-1.754174010385393, 50.727417887394779],
              [-1.75546112769908, 50.729469122027091],
              [-1.757093872084994, 50.730190146132117],
              [-1.757252348778642, 50.729408113325938],
              [-1.757983372973259, 50.729422220916454],
              [-1.757737875994148, 50.729221175094871],
              [-1.758136695345943, 50.729088011259847],
              [-1.757550176187687, 50.728802626109015],
              [-1.757888949035216, 50.728498477585205],
              [-1.757213008368638, 50.728246178101841],
              [-1.757352929989233, 50.727763562397918],
              [-1.756162446513955, 50.72701468965419],
              [-1.756049530114897, 50.72584001007521],
              [-1.757455565220946, 50.726543469557853],
              [-1.758300224034851, 50.725664838372253],
              [-1.759501090034971, 50.72687683369697],
              [-1.758936506401166, 50.725908956386149],
              [-1.759435514057404, 50.72584793633056],
              [-1.758881268476804, 50.724247894482581],
              [-1.757530358850105, 50.723577839320697],
              [-1.760067465521399, 50.723610059762741],
              [-1.762002606875385, 50.725298350104701],
              [-1.761966112856046, 50.724111242037374],
              [-1.760352961000323, 50.723186191658662],
              [-1.760298036360053, 50.722844356968892],
              [-1.764340111236661, 50.723339091147174],
              [-1.766017011444283, 50.724566368097449],
              [-1.766853119915865, 50.725944819935691],
              [-1.766278774493669, 50.726057878442823],
              [-1.765681780368754, 50.727303965787698],
              [-1.765621530393116, 50.728874864901002],
              [-1.766157345326683, 50.72853961347532],
              [-1.767137425167029, 50.728899480924746],
              [-1.769175649145859, 50.728734466525964],
              [-1.770725721246861, 50.729013596082389],
              [-1.771781260079781, 50.72959928864428],
              [-1.769391639987557, 50.730339185858426],
              [-1.769348290231974, 50.731377753460642],
              [-1.77159504335281, 50.73672561140399],
              [-1.772543578267443, 50.736912712002578],
              [-1.772551339033522, 50.736480180209291],
              [-1.773056743482459, 50.736287821541467],
              [-1.774059873558371, 50.737793339661579],
              [-1.773214797181399, 50.738480538724197],
              [-1.77099168018431, 50.73928014270075],
              [-1.771463164726839, 50.739951019356326],
              [-1.772632035830634, 50.740891235265671],
              [-1.771154454106332, 50.739320928817847],
              [-1.773143320951855, 50.738608994849535],
              [-1.77391787493275, 50.738737293732314],
              [-1.773470441060825, 50.73836413048425],
              [-1.774202097387144, 50.737981561344718],
              [-1.776853060025483, 50.739271708657043],
              [-1.778214794356187, 50.739002725129119],
              [-1.778509568114635, 50.738700232216658],
              [-1.778200491225395, 50.738429865803717],
              [-1.777198247223976, 50.73909880964186],
              [-1.77476888979001, 50.73799254806022],
              [-1.773276772819695, 50.735914154372544],
              [-1.773880981282854, 50.735506160090821],
              [-1.774093686550026, 50.734590219634882],
              [-1.773006888758721, 50.733112415546628],
              [-1.769966237999473, 50.730762974924325],
              [-1.770574883105146, 50.730309143562806],
              [-1.772253218112902, 50.730447319788631],
              [-1.773887987117388, 50.730218486587454],
              [-1.774612609722344, 50.730980667533828],
              [-1.774956687618869, 50.730436375592411],
              [-1.776155446984546, 50.730718352171984],
              [-1.779775365078108, 50.729862839696352],
              [-1.784468561329045, 50.730365295533872],
              [-1.786654383671213, 50.730147191298201],
              [-1.786961632077663, 50.729881569359932],
              [-1.785369156559507, 50.730173612245046],
              [-1.786839404885422, 50.729652033227666],
              [-1.789960605664433, 50.730636092136344],
              [-1.78919956392384, 50.730042103808977],
              [-1.792438025341331, 50.730478654575521],
              [-1.793632151317747, 50.73087195224452],
              [-1.794220451386155, 50.731453916739163],
              [-1.793941485228323, 50.731739391491097],
              [-1.791957835160919, 50.731729579014704],
              [-1.791316776980092, 50.732505401196647],
              [-1.791252695372614, 50.733215707027306],
              [-1.791964464458947, 50.734395016038462],
              [-1.792765891989976, 50.734530431841378],
              [-1.793186120359391, 50.734994298907424],
              [-1.792877678399862, 50.73649642539489],
              [-1.792962744500794, 50.73713055855854],
              [-1.793981478678488, 50.7374884696194],
              [-1.793829797603779, 50.737177056005955],
              [-1.794815039440823, 50.737417996114516],
              [-1.79632487167937, 50.739563588857706],
              [-1.798475216536885, 50.739113190854631],
              [-1.80163352924649, 50.739569130639644],
              [-1.802878615577821, 50.740049648099472],
              [-1.80363977219141, 50.741035625639775],
              [-1.804373063205636, 50.741232896117509],
              [-1.804793982904514, 50.741230902698206],
              [-1.806352732958875, 50.741274864093214],
              [-1.807392795967276, 50.741662368734715],
              [-1.808007543335677, 50.742089632512361],
              [-1.809487878633486, 50.741556994351903],
              [-1.810749279310184, 50.74153836223995],
              [-1.811120955525578, 50.742153161754231],
              [-1.815567660358895, 50.743386865644567],
              [-1.815445983851067, 50.744055726115505],
              [-1.815001108073468, 50.744016353890267],
              [-1.814849416843793, 50.744386610393818],
              [-1.815278729986694, 50.746584192392405],
              [-1.815017573721127, 50.748476729294282],
              [-1.816521295589376, 50.750336080358856],
              [-1.817310674674088, 50.753294994064099],
              [-1.816954430680153, 50.753770146337523],
              [-1.814338847358384, 50.754521398592075],
              [-1.815757328996866, 50.755384239131196],
              [-1.816185277435911, 50.756154682099684],
              [-1.81714545653081, 50.757139082982846],
              [-1.818753876020154, 50.75767755018191],
              [-1.819280855004524, 50.758152277856787],
              [-1.821205644126458, 50.757532051361032],
              [-1.82415204972171, 50.757419620139082],
              [-1.832462790252917, 50.757763663076268],
              [-1.836786187916617, 50.759165437913623],
              [-1.837770507653268, 50.759870930866029],
              [-1.839716599107665, 50.76085201364711],
              [-1.842140342267224, 50.761482098937215],
              [-1.843617787720272, 50.762737656043676],
              [-1.842781342648137, 50.764405561945942],
              [-1.844038421216689, 50.766297492839897],
              [-1.845083269244133, 50.766786282094024],
              [-1.845323949274155, 50.767331552804677],
              [-1.845096715899567, 50.767869009479249],
              [-1.843765671492149, 50.768580350656748],
              [-1.847257384136674, 50.769296282180505],
              [-1.848002530048392, 50.769967202608413],
              [-1.847927386022517, 50.77127732650176],
              [-1.851356073305403, 50.773619813327819],
              [-1.852323994359044, 50.773391729400466],
              [-1.853275048005072, 50.772658232421705],
              [-1.854190097343897, 50.772549668106542],
              [-1.8551704122521, 50.772889908018463],
              [-1.855989195189217, 50.772726355416935],
              [-1.856957781325946, 50.771334591888881],
              [-1.856856733457689, 50.77005661996548],
              [-1.857259753067552, 50.769495974725764],
              [-1.859100217295211, 50.768610638605765],
              [-1.864758294272171, 50.764136308616763],
              [-1.868723093885251, 50.762996070363343],
              [-1.869816141115267, 50.763042256044187],
              [-1.871378071649879, 50.763765192565145],
              [-1.873455100277081, 50.763305239734862],
              [-1.873391065995517, 50.763390600168258],
              [-1.873885252371788, 50.764162700165613],
              [-1.872909389413257, 50.764815405649486],
              [-1.872863595606811, 50.766019465674638],
              [-1.871734643386969, 50.768192643534704],
              [-1.868205909595048, 50.771979097940907],
              [-1.865332838894734, 50.773931712062584],
              [-1.865364134371373, 50.787756943277792],
              [-1.864491188186445, 50.795796186748483],
              [-1.864391238930013, 50.796996577290408],
              [-1.865867915297361, 50.803094331975856],
              [-1.864606089929149, 50.806629645116189],
              [-1.861369868905745, 50.806790415643498],
              [-1.859652067378928, 50.806509597032843],
              [-1.857704183297362, 50.805825607310489],
              [-1.856625623166264, 50.804895359519726],
              [-1.845239950937825, 50.809615411977468],
              [-1.842250795391941, 50.805440674577959],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000049",
        LAD13CDO: "19UD",
        LAD13NM: "East Dorset",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.95682852515187, 50.989836429352174],
            [-1.957232094276174, 50.983201189417215],
            [-1.955473171700844, 50.97827277375093],
            [-1.954736894452167, 50.978124119995385],
            [-1.952528591744689, 50.978589048173625],
            [-1.950805517790091, 50.978136027963515],
            [-1.948140860398727, 50.976481202545308],
            [-1.944837208320164, 50.975754919429804],
            [-1.94208692202102, 50.973369741894324],
            [-1.941265628726437, 50.971832552995977],
            [-1.939227547020898, 50.969663475609508],
            [-1.93487186247272, 50.969099120422243],
            [-1.929896468033615, 50.96780226167656],
            [-1.928692554009225, 50.966299823361098],
            [-1.928409743113969, 50.964994869504913],
            [-1.927191540495659, 50.963601213010364],
            [-1.923930658944511, 50.962729587372969],
            [-1.920993728609059, 50.961501994367374],
            [-1.91874861373075, 50.956372149259195],
            [-1.916113376396201, 50.953137557274758],
            [-1.912491949071234, 50.952205103322356],
            [-1.912450031852884, 50.951022582702763],
            [-1.910584301861278, 50.94922990407828],
            [-1.909191123693094, 50.945333358923065],
            [-1.905389477692311, 50.943090361455944],
            [-1.902607495730344, 50.940732989880999],
            [-1.899094222801872, 50.938399202411354],
            [-1.898574518410616, 50.935885395144325],
            [-1.896907048341812, 50.934402900394687],
            [-1.892561665206677, 50.931631149979779],
            [-1.881586649779775, 50.926384316304571],
            [-1.880389472568925, 50.924942523476332],
            [-1.878810340415446, 50.924333015526209],
            [-1.879237844620348, 50.924071779806326],
            [-1.877878771813786, 50.92311268321474],
            [-1.876125534331162, 50.920592979056153],
            [-1.875015130498915, 50.919804067180813],
            [-1.873652087764031, 50.917233483952181],
            [-1.864639596893106, 50.918931055669013],
            [-1.863495890095563, 50.919396430747149],
            [-1.861077996640923, 50.920647120564965],
            [-1.860948508491525, 50.921134352917001],
            [-1.859912274055004, 50.921312064785361],
            [-1.859100973067413, 50.922381181789625],
            [-1.857473869286485, 50.923159747927599],
            [-1.855755253546289, 50.924990284615404],
            [-1.855978342840168, 50.925545387985878],
            [-1.856487267685289, 50.925688092642481],
            [-1.855821474104996, 50.926123403367889],
            [-1.85596554792658, 50.92646529039812],
            [-1.851064859628518, 50.92884212824724],
            [-1.84715341801783, 50.929676069666598],
            [-1.846603095622065, 50.929569239570554],
            [-1.846057170279518, 50.930287910600889],
            [-1.844980305686507, 50.930195665773546],
            [-1.844144670256657, 50.930732298035402],
            [-1.842666844569423, 50.930967717798303],
            [-1.842021431052622, 50.931627785698147],
            [-1.840729492983993, 50.931588265914066],
            [-1.840327176822393, 50.931890759630839],
            [-1.838757072688398, 50.930810423031069],
            [-1.835397239074727, 50.93006477345191],
            [-1.835387569917242, 50.929578273532165],
            [-1.83445919785344, 50.929353053701696],
            [-1.832629849637864, 50.929206572655453],
            [-1.829748684791238, 50.92758019328091],
            [-1.828279569433984, 50.927775877395476],
            [-1.826307966393198, 50.926877327148915],
            [-1.824809856010158, 50.926839123241081],
            [-1.824150088752775, 50.927102508431858],
            [-1.823606389920018, 50.926770770860102],
            [-1.823255563588461, 50.926984259343925],
            [-1.822919632661614, 50.926645638481773],
            [-1.822655289775463, 50.926940187314159],
            [-1.822399065737437, 50.926595391137795],
            [-1.820755376002991, 50.926306926434862],
            [-1.818542134947831, 50.926865535791592],
            [-1.817858091858236, 50.926777246528268],
            [-1.817472398879158, 50.92716781281986],
            [-1.815365303233736, 50.927130334148089],
            [-1.815007651644219, 50.926901362999949],
            [-1.814290592816888, 50.927245533104767],
            [-1.813934207276575, 50.927057026300297],
            [-1.813222486852083, 50.92784272315653],
            [-1.81279119780525, 50.927527300965103],
            [-1.811445446599926, 50.927811095873885],
            [-1.811374965078893, 50.927294820793982],
            [-1.810665299960042, 50.927210944248898],
            [-1.811067218535683, 50.927044336395667],
            [-1.810558764901828, 50.926816007105579],
            [-1.81266764332222, 50.925340160970059],
            [-1.81478068464982, 50.92313949841472],
            [-1.814474366291341, 50.922889924310034],
            [-1.815041172851534, 50.920591473729068],
            [-1.817488374497232, 50.917616146033197],
            [-1.818851115916096, 50.916533784595472],
            [-1.818618396007296, 50.914553302441277],
            [-1.819166073917084, 50.912708015064119],
            [-1.820414389469244, 50.912113743923648],
            [-1.81757633851551, 50.907472876657984],
            [-1.817279936598754, 50.905436536487947],
            [-1.816549051985092, 50.903956143249765],
            [-1.823906024566653, 50.898233900664067],
            [-1.824960362393929, 50.895778758664193],
            [-1.82558309524928, 50.896175355125052],
            [-1.831481023287844, 50.897398877739796],
            [-1.837246258303638, 50.89720561399993],
            [-1.839877317585842, 50.897898064188567],
            [-1.841714588848749, 50.897028302261027],
            [-1.844896849470907, 50.89317662222647],
            [-1.848562958204548, 50.889895599737869],
            [-1.845106498816351, 50.888258949417988],
            [-1.844058936345836, 50.886917686935774],
            [-1.8444978914585, 50.885305031274292],
            [-1.847499308095871, 50.883416078870042],
            [-1.847984673931186, 50.882347511487787],
            [-1.84771792606007, 50.880885895065255],
            [-1.846129280491045, 50.879428840442117],
            [-1.845279724248893, 50.878006016040253],
            [-1.848487164674969, 50.873786468791536],
            [-1.847839593148749, 50.869694965995336],
            [-1.853546738090179, 50.866513531234745],
            [-1.851664121793186, 50.864202795870909],
            [-1.853530517422442, 50.863521722095491],
            [-1.852598299570969, 50.862198659728548],
            [-1.850571066073419, 50.860306771163913],
            [-1.850951167626201, 50.858733575651513],
            [-1.846027298629771, 50.858145382862347],
            [-1.84163891914466, 50.858051395088864],
            [-1.829525596163397, 50.855327626200797],
            [-1.821243255300044, 50.856781026385924],
            [-1.814839596178035, 50.858709833628218],
            [-1.814752460783836, 50.862060287224203],
            [-1.812931206613761, 50.862340649140471],
            [-1.813756826679243, 50.863389589052233],
            [-1.81279720959063, 50.864581355218064],
            [-1.811380441162938, 50.864602457983615],
            [-1.810965148590824, 50.864343703579344],
            [-1.808034660828551, 50.864468418853811],
            [-1.806419930022276, 50.861506337239916],
            [-1.807181422057262, 50.860147036309741],
            [-1.805572621027318, 50.859227139738678],
            [-1.80509326422327, 50.859018615378872],
            [-1.805441378821967, 50.857645146422371],
            [-1.804848189577828, 50.856800663886943],
            [-1.804009873277908, 50.856484524658057],
            [-1.804096193551098, 50.855547653927353],
            [-1.804762807066477, 50.854789804768572],
            [-1.803254103627346, 50.853769326720389],
            [-1.803918245564381, 50.85325967031531],
            [-1.805845329540104, 50.853079441096334],
            [-1.805504608505449, 50.851999777458587],
            [-1.804812018714725, 50.851163221645031],
            [-1.802890685603084, 50.850669906869115],
            [-1.802376910903863, 50.849552173182332],
            [-1.801278550957244, 50.849293125711846],
            [-1.799949705364939, 50.8484581546874],
            [-1.80041665518901, 50.847880736411057],
            [-1.800226169391184, 50.847249138543347],
            [-1.802752246733767, 50.84542705556791],
            [-1.803617830709881, 50.845288230527466],
            [-1.803365534761463, 50.844485676353052],
            [-1.801880038525369, 50.842429282581605],
            [-1.799855688344764, 50.842204617716114],
            [-1.799624153833481, 50.841547768936472],
            [-1.798084092320608, 50.841996541208282],
            [-1.797002890840148, 50.841101713355968],
            [-1.79736209384568, 50.840800188971421],
            [-1.798675687047778, 50.840826739203393],
            [-1.799272948547722, 50.841310663608326],
            [-1.800329286466066, 50.84105259022855],
            [-1.800012819154012, 50.839997229377403],
            [-1.798571941777419, 50.83886080070593],
            [-1.796795495124724, 50.838164403392071],
            [-1.794552137470793, 50.837733336938015],
            [-1.794412753731797, 50.836810461200784],
            [-1.792948140712656, 50.83659924910517],
            [-1.791398602082854, 50.836966084916845],
            [-1.790621834329003, 50.836323530202698],
            [-1.791634447947518, 50.835345157445936],
            [-1.791540529522461, 50.834112117475762],
            [-1.792073899677078, 50.833597797204135],
            [-1.795509050630046, 50.833887123432334],
            [-1.796662109828411, 50.83323088418944],
            [-1.795777870107361, 50.831831906347503],
            [-1.794124962211954, 50.831527756652044],
            [-1.794443752731187, 50.83103912535752],
            [-1.795667359947591, 50.830798473754513],
            [-1.797151661604444, 50.830030401764112],
            [-1.797983666030692, 50.83037265936526],
            [-1.798960425187542, 50.831397691658189],
            [-1.800570168602095, 50.831507460998026],
            [-1.801373327345161, 50.830962084424158],
            [-1.802934714247294, 50.830750706352227],
            [-1.803641999365268, 50.830033395429673],
            [-1.804631367589913, 50.827039647609894],
            [-1.803246024719126, 50.825255905494394],
            [-1.804587087254326, 50.824719504078573],
            [-1.80485246460671, 50.823723576378903],
            [-1.804332256263678, 50.82284593557123],
            [-1.804532993375041, 50.822370567110944],
            [-1.80404630467275, 50.821969584647249],
            [-1.80322303269137, 50.821939425003578],
            [-1.802144792458968, 50.822447480232114],
            [-1.80167573430762, 50.822246151749908],
            [-1.801763405075363, 50.82098554861787],
            [-1.80205454753253, 50.820620945676474],
            [-1.804033268073868, 50.819993905031531],
            [-1.805265113312795, 50.818371914053643],
            [-1.806600255072414, 50.817840874636609],
            [-1.807280823814038, 50.81630967189394],
            [-1.806431613902244, 50.815021436646866],
            [-1.803498806861312, 50.814062433074227],
            [-1.802880795705483, 50.813521839866915],
            [-1.805740169663049, 50.811637296777327],
            [-1.806768296865095, 50.810476266227404],
            [-1.809946101467913, 50.809780060364695],
            [-1.81054574144158, 50.808903362076833],
            [-1.8118393394855, 50.808739989680298],
            [-1.811813808309402, 50.80802863835121],
            [-1.810606919991413, 50.807108549387429],
            [-1.811911183555361, 50.807104361520338],
            [-1.81207516183286, 50.806557878575347],
            [-1.811671169130565, 50.806086018626246],
            [-1.810583369094305, 50.805911603232524],
            [-1.809742796715208, 50.804966919118392],
            [-1.806971513533342, 50.803516371223566],
            [-1.804289126713188, 50.803588345247199],
            [-1.804013695474228, 50.802599601973647],
            [-1.804802258719285, 50.8009993481812],
            [-1.80466540114909, 50.800469457866967],
            [-1.804041590293142, 50.799998102445471],
            [-1.802355859411397, 50.800328886583209],
            [-1.801380728116593, 50.799724731409185],
            [-1.801940216938774, 50.798955019000495],
            [-1.803498551109165, 50.798539496539028],
            [-1.80345011504725, 50.7975691177651],
            [-1.802163111118176, 50.796907790544687],
            [-1.803029227146318, 50.796090930849218],
            [-1.803912245699898, 50.795975513017858],
            [-1.805927293570708, 50.796255848847949],
            [-1.806215561175553, 50.796885806757317],
            [-1.80756258729658, 50.797802576854124],
            [-1.811206082462777, 50.795759116691279],
            [-1.813457992722209, 50.796070279680393],
            [-1.816814623023679, 50.797629505440646],
            [-1.819670064883756, 50.798215766113877],
            [-1.833857148674712, 50.800051497847498],
            [-1.837893536052593, 50.801878158853647],
            [-1.840602405547406, 50.804409690643304],
            [-1.842250795391941, 50.805440674577959],
            [-1.845239950937825, 50.809615411977468],
            [-1.856625623166264, 50.804895359519726],
            [-1.857704183297362, 50.805825607310489],
            [-1.859652067378928, 50.806509597032843],
            [-1.861369868905745, 50.806790415643498],
            [-1.864606089929149, 50.806629645116189],
            [-1.865867915297361, 50.803094331975856],
            [-1.864391238930013, 50.796996577290408],
            [-1.864491188186445, 50.795796186748483],
            [-1.865364134371373, 50.787756943277792],
            [-1.865332838894734, 50.773931712062584],
            [-1.868205909595048, 50.771979097940907],
            [-1.871734643386969, 50.768192643534704],
            [-1.872863595606811, 50.766019465674638],
            [-1.872909389413257, 50.764815405649486],
            [-1.873885252371788, 50.764162700165613],
            [-1.873391065995517, 50.763390600168258],
            [-1.874755161491701, 50.763886663230956],
            [-1.874899950294391, 50.764358030891984],
            [-1.874216058056809, 50.765590183180649],
            [-1.87784655466871, 50.769741421278233],
            [-1.880948459398568, 50.770630392253885],
            [-1.882070124611979, 50.771780782841034],
            [-1.883520096841406, 50.77209427950423],
            [-1.885561458181505, 50.772407439216757],
            [-1.887365411835461, 50.773563839863897],
            [-1.887830506865375, 50.774781883743515],
            [-1.886710112245866, 50.775369818119039],
            [-1.886738896369774, 50.775784404333855],
            [-1.890195231634735, 50.778190520245509],
            [-1.892839355737143, 50.779274780986412],
            [-1.893901259191028, 50.780101268245431],
            [-1.896222622279229, 50.78034165461802],
            [-1.897137801582035, 50.779548418211569],
            [-1.897503508151279, 50.779654851746848],
            [-1.897691521293528, 50.779292615321182],
            [-1.898400856868073, 50.77922039375764],
            [-1.898628662196201, 50.778817723735145],
            [-1.898082783788638, 50.778732719043063],
            [-1.898612365438888, 50.778482286262786],
            [-1.900857072873789, 50.778727010306916],
            [-1.901944246018427, 50.777700074477629],
            [-1.905994169180683, 50.777376968517515],
            [-1.907629465883343, 50.776657065871696],
            [-1.909281529069403, 50.775306772867722],
            [-1.910797935732498, 50.775143374536803],
            [-1.911363064455625, 50.775539477893538],
            [-1.912482038407514, 50.775551111357437],
            [-1.912963433676043, 50.775221442492423],
            [-1.913152929940578, 50.773981505339663],
            [-1.914231765770966, 50.773430156104979],
            [-1.914986279616033, 50.773407325996729],
            [-1.916990827868859, 50.774641649149814],
            [-1.917623468903043, 50.774575551045217],
            [-1.91869594561658, 50.773578123670411],
            [-1.919604877324294, 50.773651591576211],
            [-1.920035384083834, 50.774017884906137],
            [-1.919231520360317, 50.774695374077723],
            [-1.919352378917167, 50.775342024474156],
            [-1.920746111269312, 50.77557768308904],
            [-1.921603054230231, 50.776235618269354],
            [-1.920923835260131, 50.777845733216786],
            [-1.918901532612157, 50.779366793527061],
            [-1.91861993483818, 50.780627357759982],
            [-1.919707471663233, 50.782509356113941],
            [-1.919146826159976, 50.783541317784326],
            [-1.919513409211946, 50.784011882073635],
            [-1.920933362445527, 50.78399216698358],
            [-1.922411070404451, 50.783356481582295],
            [-1.924242412387285, 50.783305524165179],
            [-1.925064787158931, 50.782630711075214],
            [-1.925518949346589, 50.781573473918378],
            [-1.927006650030466, 50.780807344039665],
            [-1.93259631944068, 50.780000463700013],
            [-1.935060078624214, 50.782033278982581],
            [-1.937132820601881, 50.782796980124559],
            [-1.937509099574594, 50.783575039116791],
            [-1.936980533347143, 50.78529863208685],
            [-1.938066683861294, 50.785641828255905],
            [-1.939031548390049, 50.786544289057503],
            [-1.937447125886169, 50.787514651213051],
            [-1.936337245915076, 50.788954662478332],
            [-1.935619357369499, 50.79210346567087],
            [-1.935210832988685, 50.794110379589888],
            [-1.936128914614098, 50.797162956899363],
            [-1.937998863752521, 50.798361769268887],
            [-1.940518234167107, 50.798883743871109],
            [-1.941560447362898, 50.79831773754325],
            [-1.941737921039452, 50.79708494708148],
            [-1.942412660181094, 50.796461197375592],
            [-1.945422541817034, 50.794856567148642],
            [-1.950364311616755, 50.790829197449597],
            [-1.952316772865067, 50.790513469190103],
            [-1.955199726235769, 50.790346444447181],
            [-1.958334541206882, 50.789302663169082],
            [-1.963860488061881, 50.790816143138237],
            [-1.966143182446965, 50.790824016794176],
            [-1.968799415144584, 50.792348990372659],
            [-1.969673288244743, 50.792472416880777],
            [-1.978370250973065, 50.792516598722116],
            [-1.979862028565992, 50.790954854691712],
            [-1.980574206594722, 50.790991843331732],
            [-1.980921531638443, 50.791638464969694],
            [-1.98143647941692, 50.791771636761425],
            [-1.983631545923966, 50.790951838869319],
            [-1.984838706055001, 50.791465474467827],
            [-1.988245209216494, 50.790894832065533],
            [-1.991102394632571, 50.791414848471732],
            [-1.991574888562675, 50.791079459960493],
            [-1.993997896085235, 50.792370935868369],
            [-1.997173042113394, 50.792848553637761],
            [-2.005881193869814, 50.790935702347042],
            [-2.004873829784045, 50.790211849859872],
            [-1.999572191252921, 50.789613957551246],
            [-1.999139515371164, 50.788137377081661],
            [-2.000472980130749, 50.785356874335889],
            [-2.001175142369345, 50.784834399444776],
            [-2.006081546058575, 50.782777634382015],
            [-2.007966623562291, 50.782429503958603],
            [-2.009326954991907, 50.782598460419308],
            [-2.010074318116411, 50.7754205194517],
            [-2.01026257982007, 50.773797340742782],
            [-2.011190557964784, 50.764151795594891],
            [-2.012711893024775, 50.748510781121674],
            [-2.012743020871238, 50.74831024206685],
            [-2.017074328794248, 50.747405020105127],
            [-2.020326683379171, 50.746113149526586],
            [-2.022766601889437, 50.744547075924388],
            [-2.024507585702652, 50.742863297880888],
            [-2.027245441202336, 50.747211521265704],
            [-2.032327125942074, 50.753832380223869],
            [-2.034093262269889, 50.755523384685866],
            [-2.042203194913304, 50.759795801896352],
            [-2.047616945055621, 50.761878191623858],
            [-2.048368685670567, 50.76215755065347],
            [-2.050701074386733, 50.762195222982825],
            [-2.054979063326163, 50.756480269356409],
            [-2.057488231037357, 50.757545577943404],
            [-2.05705170410341, 50.759969302221513],
            [-2.059499343989966, 50.761522895783024],
            [-2.060377138778441, 50.76164923845549],
            [-2.061349167543318, 50.761192803784326],
            [-2.062483632417611, 50.76135406590781],
            [-2.063852749907403, 50.763068211416204],
            [-2.063920110590006, 50.763595141026933],
            [-2.058415657852531, 50.766814694026841],
            [-2.059460214371334, 50.769766433518306],
            [-2.062594686566934, 50.772282712487076],
            [-2.062153445978084, 50.775331437047342],
            [-2.061563537000353, 50.776466615520071],
            [-2.064162626042804, 50.7791225170945],
            [-2.067341286205076, 50.780196230693484],
            [-2.067584344048852, 50.778590913982036],
            [-2.068647146069496, 50.777943724297131],
            [-2.070549924088495, 50.777543313982243],
            [-2.069651865976855, 50.775459374545129],
            [-2.07071516877235, 50.773334683023783],
            [-2.072889624681082, 50.770855880492782],
            [-2.074478449445911, 50.771200188794126],
            [-2.07605715816749, 50.773156853731102],
            [-2.078762707383496, 50.77548862881406],
            [-2.081231823093666, 50.77546175060408],
            [-2.087515940114066, 50.773883487812938],
            [-2.08976811828876, 50.77762088883869],
            [-2.088503589498534, 50.779437460418634],
            [-2.087491524153866, 50.779782642045127],
            [-2.084806820451288, 50.779935706232571],
            [-2.079121365621154, 50.779584507136462],
            [-2.074193613897752, 50.781155163205121],
            [-2.072813704802337, 50.782199174779514],
            [-2.072447957718119, 50.785079724766852],
            [-2.069193927060402, 50.786095170425178],
            [-2.069293833617462, 50.786504272815975],
            [-2.076264218571291, 50.787065540872646],
            [-2.079657140024087, 50.787689148440357],
            [-2.084734525160911, 50.787782679112496],
            [-2.08892419134624, 50.787296650210664],
            [-2.093224098988332, 50.785938103128082],
            [-2.099535908432769, 50.786236822887467],
            [-2.100550722079922, 50.785826788271422],
            [-2.10403618514763, 50.788408188615946],
            [-2.107282537129604, 50.792960874215957],
            [-2.107677044149149, 50.792999177872446],
            [-2.109223503634649, 50.79178284158602],
            [-2.110652315465082, 50.791843540540405],
            [-2.111277539001544, 50.791062389882157],
            [-2.114401582160064, 50.791075547642301],
            [-2.116568060782942, 50.79054643373528],
            [-2.116532015000947, 50.79088369102589],
            [-2.119028574054131, 50.790738183010788],
            [-2.119381351203281, 50.78943839588834],
            [-2.12060041725729, 50.788505509155257],
            [-2.120105279341289, 50.786835201562582],
            [-2.123587695252859, 50.786216464335894],
            [-2.12428352065265, 50.786489996964335],
            [-2.12670375033696, 50.784969441435322],
            [-2.129808244240278, 50.783705261966531],
            [-2.13046394040739, 50.782814263795863],
            [-2.132071614645824, 50.782004917429553],
            [-2.13270160652888, 50.775574502863094],
            [-2.133130450052379, 50.774764680085298],
            [-2.134821838538287, 50.774081095096776],
            [-2.135248015561133, 50.777273869419524],
            [-2.135174043180738, 50.778179508810148],
            [-2.134254381503352, 50.778963827173385],
            [-2.134498286729928, 50.780426638994157],
            [-2.136837684658686, 50.78349667754749],
            [-2.137452006110282, 50.784019321924156],
            [-2.140341372133477, 50.784924125285983],
            [-2.142018301569099, 50.785933756697425],
            [-2.14300720250714, 50.785523384192466],
            [-2.145283691568326, 50.787260629123018],
            [-2.158667524934699, 50.79208566166966],
            [-2.159862205206209, 50.793774630210272],
            [-2.160314747680604, 50.795415150847234],
            [-2.161537624060887, 50.796187720703266],
            [-2.162433488552472, 50.798391450132961],
            [-2.164118376618636, 50.799778438157468],
            [-2.163888221368429, 50.800103393439628],
            [-2.162996694699612, 50.80161719185984],
            [-2.159645625141333, 50.802651480177893],
            [-2.148152772298503, 50.802704435312329],
            [-2.142262154133508, 50.803615531261492],
            [-2.13773616555044, 50.803448315647621],
            [-2.128677984855583, 50.805464915087704],
            [-2.114012450023826, 50.81243592863526],
            [-2.110781858754244, 50.814778904197304],
            [-2.109152215485682, 50.817151772797956],
            [-2.113204785396309, 50.817102939525391],
            [-2.1155890836162, 50.817487275020213],
            [-2.116145241940122, 50.818511869090685],
            [-2.115638864638306, 50.819820785001014],
            [-2.11547352030651, 50.820123097788347],
            [-2.112978391300379, 50.81916424562268],
            [-2.109467908089276, 50.819208965410965],
            [-2.108431366908857, 50.82093290228714],
            [-2.106934118668327, 50.820509840574665],
            [-2.105839540942743, 50.822362400279275],
            [-2.106551205287138, 50.82254339978995],
            [-2.104585796101921, 50.8248166912865],
            [-2.100685274656352, 50.827217533984125],
            [-2.099771875758454, 50.828330693691917],
            [-2.097631743644762, 50.829484448639427],
            [-2.097916294341676, 50.829758480938608],
            [-2.090670979387899, 50.832873059536105],
            [-2.089418856928609, 50.831548534152084],
            [-2.086468261587115, 50.83310466833759],
            [-2.083921974044473, 50.833756690630906],
            [-2.080856583937551, 50.833992666727966],
            [-2.079710258929827, 50.833774940313795],
            [-2.079338636013172, 50.834021588805413],
            [-2.080739552390559, 50.834472048050266],
            [-2.078756310563312, 50.836474237734144],
            [-2.073050335021366, 50.839162220256007],
            [-2.071822195733033, 50.838441787795588],
            [-2.071493137503726, 50.838713563754787],
            [-2.0727906037071, 50.839264897500563],
            [-2.071375448273594, 50.839761260871633],
            [-2.065069929718636, 50.840871037130583],
            [-2.067033417332879, 50.843358141403471],
            [-2.064178209724725, 50.84512677521159],
            [-2.063392680355125, 50.846116378847725],
            [-2.059775042807161, 50.847285519378595],
            [-2.057286986286337, 50.847706721399312],
            [-2.053895027057279, 50.850188468565797],
            [-2.054984895422952, 50.851678009810236],
            [-2.056669903621753, 50.853035961699973],
            [-2.054687001253408, 50.854455023828656],
            [-2.054708705106362, 50.854790432484585],
            [-2.059185164314127, 50.854790036640495],
            [-2.060452874773905, 50.855169765197083],
            [-2.058817323236691, 50.855998810337468],
            [-2.058580632711047, 50.856446754463036],
            [-2.056405173640374, 50.857312905651561],
            [-2.052059863018449, 50.86046319362098],
            [-2.047404593079981, 50.862598197437428],
            [-2.044123289427926, 50.863587761670864],
            [-2.043985950209297, 50.864104879613549],
            [-2.03991748539695, 50.865360797517518],
            [-2.041302423048599, 50.866389048272246],
            [-2.044679957989695, 50.867678212252194],
            [-2.044010392281989, 50.868907734930779],
            [-2.044836711857185, 50.871079092605839],
            [-2.044524604449088, 50.873129488143796],
            [-2.045969735075757, 50.872892423663053],
            [-2.049236462750811, 50.873662634879928],
            [-2.049184319117784, 50.87407990588023],
            [-2.052220503724955, 50.875727792157193],
            [-2.050905997820477, 50.877191444028618],
            [-2.050593360439478, 50.878547638652307],
            [-2.053820842927522, 50.879388778141895],
            [-2.055327591704194, 50.881836707056586],
            [-2.057647587913307, 50.883110704330896],
            [-2.063680757208052, 50.889551519506995],
            [-2.065966304259061, 50.891166180972782],
            [-2.061509666363723, 50.89266406129336],
            [-2.060222700577614, 50.893578363396479],
            [-2.05935887977699, 50.89520103701755],
            [-2.058996202767466, 50.899604798477526],
            [-2.063824144029879, 50.907012875012242],
            [-2.063295538046346, 50.909443804377702],
            [-2.06189691811502, 50.911212458692084],
            [-2.057585294802889, 50.914301756217419],
            [-2.0512990807775, 50.917827911189384],
            [-2.053644976498337, 50.92036359733487],
            [-2.054997668942336, 50.923762975784257],
            [-2.056758258049576, 50.926623496297481],
            [-2.056879110187131, 50.927712410898465],
            [-2.056033228949112, 50.93067040489246],
            [-2.056132605357471, 50.932833015586098],
            [-2.058199580386948, 50.943641683355779],
            [-2.060832262830915, 50.946501692995696],
            [-2.062582990728284, 50.947480027693445],
            [-2.065008818886709, 50.949680016541926],
            [-2.067628941692781, 50.951215309477618],
            [-2.067289591616771, 50.951826983559286],
            [-2.0648681881494, 50.953968529037923],
            [-2.064278982859631, 50.957191698945692],
            [-2.062538820191253, 50.959067542756003],
            [-2.062379038090177, 50.959893121779508],
            [-2.059324755587408, 50.959795804609811],
            [-2.058606776971549, 50.960637847936233],
            [-2.058433957451756, 50.961346528025999],
            [-2.059411211287659, 50.96281897103664],
            [-2.058334334013645, 50.963649505067451],
            [-2.053326382555672, 50.966239891748145],
            [-2.05156356022998, 50.966369276964265],
            [-2.051084847424659, 50.966158169574292],
            [-2.041232188163065, 50.968073845841658],
            [-2.038545722191996, 50.970755369418924],
            [-2.038345459837694, 50.971415469297185],
            [-2.037215094589595, 50.971976056569936],
            [-2.034460993935353, 50.972431017926667],
            [-2.032861172367792, 50.971857775068415],
            [-2.028974065844179, 50.971185290838079],
            [-2.023730866296192, 50.972517343135387],
            [-2.02195631810723, 50.972621105402972],
            [-2.020286938813082, 50.972232944553703],
            [-2.017427064842804, 50.972164172156816],
            [-2.014879448892579, 50.973155479425202],
            [-2.014018001526627, 50.9738956524183],
            [-2.012307518412955, 50.974171911954834],
            [-2.00972694595138, 50.975263823530021],
            [-2.007067771120395, 50.975454656964402],
            [-2.004986739515645, 50.974639169668642],
            [-2.003820226522921, 50.974516022108304],
            [-2.002709296373063, 50.974850568358079],
            [-2.000675384602384, 50.974786757291199],
            [-1.999055942634915, 50.975271442771643],
            [-1.994043704207479, 50.975734408301335],
            [-1.992999619719502, 50.976063470016392],
            [-1.992260255773745, 50.976869132753762],
            [-1.991227536975368, 50.977150520250305],
            [-1.990292869693265, 50.978510079877715],
            [-1.989235870304956, 50.978866982790791],
            [-1.989595905056147, 50.980455948436756],
            [-1.990108619528262, 50.980928985046098],
            [-1.989797943974917, 50.981566510698258],
            [-1.98690738338877, 50.982381827517678],
            [-1.985892847226732, 50.983274643499669],
            [-1.986056442034096, 50.984057888816395],
            [-1.985217139684103, 50.984761881588732],
            [-1.983389477443129, 50.984440616471275],
            [-1.980428955652579, 50.984927545302227],
            [-1.979479877783592, 50.985580222686885],
            [-1.977379871261947, 50.98577587098989],
            [-1.97525513104833, 50.986916562690077],
            [-1.972986688546237, 50.987539228770238],
            [-1.971996763590142, 50.989529883183891],
            [-1.968063722726693, 50.990578279108114],
            [-1.965365516333237, 50.992043253405505],
            [-1.962644230935118, 50.991859878527684],
            [-1.95682852515187, 50.989836429352174],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000050",
        LAD13CDO: "19UE",
        LAD13NM: "North Dorset",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.320624918176698, 51.080996495952164],
            [-2.316087030506381, 51.079792226478268],
            [-2.315709065901035, 51.080050423875306],
            [-2.313304009126153, 51.079890544682847],
            [-2.310884017938883, 51.080268385795883],
            [-2.309476175200088, 51.079157098702787],
            [-2.30643826230213, 51.079563459755583],
            [-2.303354307368264, 51.079268474442678],
            [-2.300216276148102, 51.078552714603433],
            [-2.298685617831589, 51.078492794501898],
            [-2.293269919797078, 51.076945481932576],
            [-2.291273783368343, 51.077081762119313],
            [-2.28341594751713, 51.075256805176757],
            [-2.268624712846163, 51.068747277431953],
            [-2.251314269845631, 51.070843215766743],
            [-2.247650687287003, 51.072212453125175],
            [-2.244693879671023, 51.07231670053595],
            [-2.240169729043959, 51.069912601563395],
            [-2.238988653697094, 51.068657922727105],
            [-2.238830511748318, 51.06788042551198],
            [-2.23684375889192, 51.06673527551974],
            [-2.228896705573359, 51.060791125428111],
            [-2.227662372149045, 51.060204552378416],
            [-2.226789429460224, 51.058522918942984],
            [-2.225650051090507, 51.057492826394849],
            [-2.223274186991676, 51.056568503662128],
            [-2.217998454470693, 51.055184688446396],
            [-2.21707655629867, 51.053617273246644],
            [-2.215118958767887, 51.05174333202541],
            [-2.212445073824254, 51.050117947980908],
            [-2.210619969787166, 51.047188907081598],
            [-2.207221574811243, 51.045820085375013],
            [-2.205328101244476, 51.042800260292267],
            [-2.201440309607127, 51.043255744310414],
            [-2.201536640769945, 51.04277449628978],
            [-2.198129942414913, 51.041660801907796],
            [-2.195176115797381, 51.039664118267702],
            [-2.194723454248211, 51.038859174388065],
            [-2.193213989659645, 51.037718775836574],
            [-2.192001329664727, 51.03625325161326],
            [-2.190553915682343, 51.032127307354287],
            [-2.189188360355917, 51.030528917651374],
            [-2.189345312612436, 51.029502653008436],
            [-2.190293810687636, 51.028512867217437],
            [-2.190324901295362, 51.027401380102177],
            [-2.190143207048923, 51.026558207404648],
            [-2.188841854493921, 51.025276233324398],
            [-2.188609154176754, 51.018538744312174],
            [-2.185997269944468, 51.018158069750925],
            [-2.183827655017255, 51.017488889883637],
            [-2.185182307737688, 51.015781822827989],
            [-2.178766655226811, 51.014139057798353],
            [-2.176393885711042, 51.012824408894502],
            [-2.17628270838695, 51.011700548568989],
            [-2.178106927269184, 51.008267242184971],
            [-2.176420373121065, 51.006664690789997],
            [-2.176093501233503, 51.005409868396121],
            [-2.17297579445571, 51.002161140192634],
            [-2.172571308464053, 51.001079073172775],
            [-2.171804333359958, 51.001007368025732],
            [-2.169312003817676, 51.000667508138982],
            [-2.167088128068208, 50.998089940042355],
            [-2.164441915542528, 50.996091126487201],
            [-2.163588654701908, 50.996173256487261],
            [-2.163619524099164, 50.994418824851344],
            [-2.161532475816167, 50.994135780061519],
            [-2.161847949051842, 50.99348160588891],
            [-2.159644130497925, 50.992788642481493],
            [-2.152529317520455, 50.989164392553846],
            [-2.148749646003751, 50.986550727870139],
            [-2.143583198588018, 50.984970967931098],
            [-2.138402436946912, 50.982293939407519],
            [-2.13169571021208, 50.979776677832582],
            [-2.126859705972461, 50.978745231269421],
            [-2.122349616009903, 50.978610671834872],
            [-2.121981124274588, 50.978233382485769],
            [-2.119770344307202, 50.978197008238702],
            [-2.122719819446294, 50.973162760302912],
            [-2.123162957144769, 50.969474556551788],
            [-2.120657732431953, 50.969414230760073],
            [-2.120884534812334, 50.965721760921433],
            [-2.121519296394734, 50.965041283365061],
            [-2.121529446102046, 50.964021546402776],
            [-2.120677176542322, 50.961545952863602],
            [-2.11946777779369, 50.95968669153357],
            [-2.115647883805103, 50.957509910790876],
            [-2.111046725269982, 50.955927243999341],
            [-2.105563331433281, 50.950015399018682],
            [-2.103965091365783, 50.948923370332999],
            [-2.102661663108521, 50.946576631271299],
            [-2.10286735647819, 50.945614271203119],
            [-2.101798433533554, 50.944992042116567],
            [-2.100259926805544, 50.945077005097851],
            [-2.094151820238561, 50.949141236646874],
            [-2.090922579605471, 50.94951428600902],
            [-2.08922554877308, 50.950162146220578],
            [-2.087375969127963, 50.949957624699131],
            [-2.085440027646708, 50.949227985958309],
            [-2.083890116483262, 50.950974521602419],
            [-2.082870072000194, 50.951344836248254],
            [-2.068319358546605, 50.95120051839482],
            [-2.067289591616771, 50.951826983559286],
            [-2.067628941692781, 50.951215309477618],
            [-2.065008818886709, 50.949680016541926],
            [-2.062582990728284, 50.947480027693445],
            [-2.060832262830915, 50.946501692995696],
            [-2.058199580386948, 50.943641683355779],
            [-2.056132605357471, 50.932833015586098],
            [-2.056033228949112, 50.93067040489246],
            [-2.056879110187131, 50.927712410898465],
            [-2.056758258049576, 50.926623496297481],
            [-2.054997668942336, 50.923762975784257],
            [-2.053644976498337, 50.92036359733487],
            [-2.0512990807775, 50.917827911189384],
            [-2.057585294802889, 50.914301756217419],
            [-2.06189691811502, 50.911212458692084],
            [-2.063295538046346, 50.909443804377702],
            [-2.063824144029879, 50.907012875012242],
            [-2.058996202767466, 50.899604798477526],
            [-2.05935887977699, 50.89520103701755],
            [-2.060222700577614, 50.893578363396479],
            [-2.061509666363723, 50.89266406129336],
            [-2.065966304259061, 50.891166180972782],
            [-2.063680757208052, 50.889551519506995],
            [-2.057647587913307, 50.883110704330896],
            [-2.055327591704194, 50.881836707056586],
            [-2.053820842927522, 50.879388778141895],
            [-2.050593360439478, 50.878547638652307],
            [-2.050905997820477, 50.877191444028618],
            [-2.052220503724955, 50.875727792157193],
            [-2.049184319117784, 50.87407990588023],
            [-2.049236462750811, 50.873662634879928],
            [-2.045969735075757, 50.872892423663053],
            [-2.044524604449088, 50.873129488143796],
            [-2.044836711857185, 50.871079092605839],
            [-2.044010392281989, 50.868907734930779],
            [-2.044679957989695, 50.867678212252194],
            [-2.041302423048599, 50.866389048272246],
            [-2.03991748539695, 50.865360797517518],
            [-2.043985950209297, 50.864104879613549],
            [-2.044123289427926, 50.863587761670864],
            [-2.047404593079981, 50.862598197437428],
            [-2.052059863018449, 50.86046319362098],
            [-2.056405173640374, 50.857312905651561],
            [-2.058580632711047, 50.856446754463036],
            [-2.058817323236691, 50.855998810337468],
            [-2.060452874773905, 50.855169765197083],
            [-2.059185164314127, 50.854790036640495],
            [-2.054708705106362, 50.854790432484585],
            [-2.054687001253408, 50.854455023828656],
            [-2.056669903621753, 50.853035961699973],
            [-2.054984895422952, 50.851678009810236],
            [-2.053895027057279, 50.850188468565797],
            [-2.057286986286337, 50.847706721399312],
            [-2.059775042807161, 50.847285519378595],
            [-2.063392680355125, 50.846116378847725],
            [-2.064178209724725, 50.84512677521159],
            [-2.067033417332879, 50.843358141403471],
            [-2.065069929718636, 50.840871037130583],
            [-2.071375448273594, 50.839761260871633],
            [-2.0727906037071, 50.839264897500563],
            [-2.071493137503726, 50.838713563754787],
            [-2.071822195733033, 50.838441787795588],
            [-2.073050335021366, 50.839162220256007],
            [-2.078756310563312, 50.836474237734144],
            [-2.080739552390559, 50.834472048050266],
            [-2.079338636013172, 50.834021588805413],
            [-2.079710258929827, 50.833774940313795],
            [-2.080856583937551, 50.833992666727966],
            [-2.083921974044473, 50.833756690630906],
            [-2.086468261587115, 50.83310466833759],
            [-2.089418856928609, 50.831548534152084],
            [-2.090670979387899, 50.832873059536105],
            [-2.097916294341676, 50.829758480938608],
            [-2.097631743644762, 50.829484448639427],
            [-2.099771875758454, 50.828330693691917],
            [-2.100685274656352, 50.827217533984125],
            [-2.104585796101921, 50.8248166912865],
            [-2.106551205287138, 50.82254339978995],
            [-2.105839540942743, 50.822362400279275],
            [-2.106934118668327, 50.820509840574665],
            [-2.108431366908857, 50.82093290228714],
            [-2.109467908089276, 50.819208965410965],
            [-2.112978391300379, 50.81916424562268],
            [-2.11547352030651, 50.820123097788347],
            [-2.115638864638306, 50.819820785001014],
            [-2.116145241940122, 50.818511869090685],
            [-2.1155890836162, 50.817487275020213],
            [-2.113204785396309, 50.817102939525391],
            [-2.109152215485682, 50.817151772797956],
            [-2.110781858754244, 50.814778904197304],
            [-2.114012450023826, 50.81243592863526],
            [-2.128677984855583, 50.805464915087704],
            [-2.13773616555044, 50.803448315647621],
            [-2.142262154133508, 50.803615531261492],
            [-2.148152772298503, 50.802704435312329],
            [-2.159645625141333, 50.802651480177893],
            [-2.162996694699612, 50.80161719185984],
            [-2.163888221368429, 50.800103393439628],
            [-2.164118376618636, 50.799778438157468],
            [-2.162433488552472, 50.798391450132961],
            [-2.161537624060887, 50.796187720703266],
            [-2.160314747680604, 50.795415150847234],
            [-2.159862205206209, 50.793774630210272],
            [-2.158667524934699, 50.79208566166966],
            [-2.145283691568326, 50.787260629123018],
            [-2.14300720250714, 50.785523384192466],
            [-2.142018301569099, 50.785933756697425],
            [-2.140341372133477, 50.784924125285983],
            [-2.137452006110282, 50.784019321924156],
            [-2.136837684658686, 50.78349667754749],
            [-2.134498286729928, 50.780426638994157],
            [-2.134254381503352, 50.778963827173385],
            [-2.135174043180738, 50.778179508810148],
            [-2.135248015561133, 50.777273869419524],
            [-2.134821838538287, 50.774081095096776],
            [-2.134760839933407, 50.773582976088832],
            [-2.138741778080592, 50.772177253662306],
            [-2.140976935977909, 50.770818484956074],
            [-2.143781860279503, 50.77032945443063],
            [-2.146671912005833, 50.768514739821278],
            [-2.150470704868936, 50.767153807881513],
            [-2.152672327735257, 50.766994470566388],
            [-2.156736234527125, 50.767828081148267],
            [-2.159086595742188, 50.767620761679169],
            [-2.159534714408183, 50.767629141207195],
            [-2.161274303047358, 50.774128394317842],
            [-2.163909523292558, 50.773374732644307],
            [-2.168180438858523, 50.77280031111713],
            [-2.189082800102085, 50.766840328759628],
            [-2.192783850508912, 50.766178700652119],
            [-2.196874080076122, 50.766036970003661],
            [-2.198467709302992, 50.769681667301541],
            [-2.200307244147013, 50.768424949312383],
            [-2.201263503844516, 50.769532988643789],
            [-2.201614052802982, 50.768940668977955],
            [-2.208622905643709, 50.76849758170912],
            [-2.209370987249628, 50.768671595441589],
            [-2.211761744768159, 50.77054133326255],
            [-2.212209240993756, 50.772595328872072],
            [-2.215096094949732, 50.772483022575749],
            [-2.217100898688087, 50.774524221330978],
            [-2.219818697573753, 50.773421128557722],
            [-2.220368768507118, 50.775190732380736],
            [-2.223168834901541, 50.775288817375298],
            [-2.224171716737636, 50.775624114513938],
            [-2.22748621984699, 50.778263312094097],
            [-2.231600395270913, 50.780477276701923],
            [-2.232425392019134, 50.781231910893034],
            [-2.241718670217639, 50.77624459349002],
            [-2.249743026122239, 50.777801375893709],
            [-2.252071981201146, 50.777552661622188],
            [-2.257755625105484, 50.777640045915184],
            [-2.258420987152391, 50.776900279746251],
            [-2.258931378420467, 50.774299385128813],
            [-2.258528415126804, 50.772208599497247],
            [-2.25946997951038, 50.770144501141914],
            [-2.26742610877915, 50.766286666189373],
            [-2.267880456393988, 50.765398950247032],
            [-2.266287717553434, 50.765080665219543],
            [-2.264606768081728, 50.764203217746179],
            [-2.265451105392373, 50.762821828904272],
            [-2.267054082288799, 50.76246295734407],
            [-2.267837624001651, 50.761377547481409],
            [-2.264892859770147, 50.759128028999186],
            [-2.266109844156908, 50.758725983108121],
            [-2.266648941471518, 50.758788598294906],
            [-2.267775261040808, 50.76014030526418],
            [-2.270408085084924, 50.760877915273589],
            [-2.271701194322293, 50.762113191903147],
            [-2.275173690580131, 50.762147308448078],
            [-2.276065344987008, 50.761637115921395],
            [-2.277779948676218, 50.762191483217414],
            [-2.279087710846857, 50.762991399850037],
            [-2.280063683569327, 50.764495322865521],
            [-2.283842604535448, 50.762651683608951],
            [-2.284938648566608, 50.762891359934038],
            [-2.287381235070027, 50.762595349348985],
            [-2.287903530497227, 50.762911499839142],
            [-2.290653812883054, 50.762392991789298],
            [-2.292658359382512, 50.762345709316286],
            [-2.298311021185165, 50.762743238315785],
            [-2.300014322925851, 50.763478059656308],
            [-2.305666505232726, 50.763336575017313],
            [-2.307139189580481, 50.768887447641923],
            [-2.304382879762732, 50.770255262666176],
            [-2.299862651503198, 50.771241780873126],
            [-2.294366283588664, 50.773540817011032],
            [-2.294530238068491, 50.77390370427117],
            [-2.296106764260745, 50.774047188088687],
            [-2.296251824734879, 50.778597078996235],
            [-2.298854289554447, 50.778567053794376],
            [-2.301456487687846, 50.779377779748373],
            [-2.307149072726291, 50.779915062442392],
            [-2.305919097374709, 50.781181758104019],
            [-2.305290558699916, 50.783548459993554],
            [-2.30378375828167, 50.785023585955031],
            [-2.300312587899276, 50.787455186242397],
            [-2.299419552703675, 50.788672382771139],
            [-2.300633274662818, 50.789235791582577],
            [-2.302620319498943, 50.792012056485653],
            [-2.307139176535335, 50.795278932279452],
            [-2.310422955437721, 50.794936608575348],
            [-2.312794772412418, 50.795289971231142],
            [-2.313642768185219, 50.79649000058626],
            [-2.314722562710974, 50.796704710594661],
            [-2.315349843620862, 50.798620232903779],
            [-2.315772512528164, 50.798593909474782],
            [-2.317086458223933, 50.799432047824133],
            [-2.313510895265736, 50.801376021739799],
            [-2.307057507205864, 50.803378758870629],
            [-2.303721328322299, 50.804051150537838],
            [-2.306081915431052, 50.808483705241962],
            [-2.309394722332312, 50.811532429539213],
            [-2.310711182211617, 50.813566640992612],
            [-2.311264957324187, 50.813171289904759],
            [-2.315269557854853, 50.812192929969051],
            [-2.320068439754796, 50.809706899889605],
            [-2.321938946156527, 50.809690958290105],
            [-2.322336078408938, 50.810888569117168],
            [-2.328328050902892, 50.810225282699804],
            [-2.332996177395339, 50.811828005526586],
            [-2.333111499364852, 50.812476039563528],
            [-2.330681980737754, 50.813944246507859],
            [-2.330356475681314, 50.813678090416026],
            [-2.329664573707411, 50.81398849361176],
            [-2.322020428099812, 50.81513842388815],
            [-2.321818032162255, 50.816461787026064],
            [-2.322703381653616, 50.817019575608995],
            [-2.322715273526105, 50.817714666716235],
            [-2.323607497209764, 50.818645620034907],
            [-2.323876863491422, 50.820235653405028],
            [-2.325163646623947, 50.820937986404893],
            [-2.325923188484232, 50.820536596629125],
            [-2.327303169229595, 50.820553413598127],
            [-2.328724198829289, 50.821765207618178],
            [-2.329779244392981, 50.822202862782667],
            [-2.330463448501091, 50.823197299152994],
            [-2.333024184900775, 50.824139625361745],
            [-2.333011290942589, 50.823923841220655],
            [-2.335521899146271, 50.823011094542714],
            [-2.337683542981615, 50.822739573931287],
            [-2.339735560834814, 50.823015079371523],
            [-2.347860575508463, 50.825706869582568],
            [-2.349945926815202, 50.826806711969084],
            [-2.353697898190669, 50.827670365171329],
            [-2.354445308092129, 50.828496308371193],
            [-2.354433689564476, 50.829586239134755],
            [-2.352026534857268, 50.835374829291993],
            [-2.351934170557584, 50.838196962195823],
            [-2.352970294888484, 50.83868392299015],
            [-2.356011084760933, 50.841323875639752],
            [-2.357347950870834, 50.843422237071856],
            [-2.36069560726896, 50.84599817778151],
            [-2.361452369294501, 50.846693653598223],
            [-2.362769592545273, 50.846392810696379],
            [-2.363192757071478, 50.846926548012256],
            [-2.365716847032697, 50.846565254230796],
            [-2.365936019887438, 50.847346015943593],
            [-2.36790251933804, 50.847245407527147],
            [-2.367761413008852, 50.846461705552493],
            [-2.368733449470327, 50.846162782552071],
            [-2.368489647847242, 50.845506200408735],
            [-2.367339507956304, 50.84591359272126],
            [-2.36727309891994, 50.845054117558604],
            [-2.367691369751746, 50.844599576791829],
            [-2.368803229877972, 50.84456549135399],
            [-2.369873072379054, 50.844786016784532],
            [-2.37095654137734, 50.846006456684883],
            [-2.374393335320444, 50.84505215930394],
            [-2.375139690656194, 50.845312342341174],
            [-2.376095811255177, 50.846398256436537],
            [-2.377094722895057, 50.846268236337657],
            [-2.377229351470884, 50.847462006772773],
            [-2.379511478361958, 50.847399748436906],
            [-2.380808907676509, 50.846773237250474],
            [-2.382686498645522, 50.846402894461932],
            [-2.384253340443505, 50.845383386695239],
            [-2.385000475905256, 50.844351279273575],
            [-2.385909298292494, 50.84415583558274],
            [-2.385674867068632, 50.843457892261718],
            [-2.386268230522369, 50.843074645582206],
            [-2.387705934435148, 50.842775819400003],
            [-2.388357518267611, 50.842223309023431],
            [-2.388494540100054, 50.842475542552982],
            [-2.388698179755968, 50.842198793546054],
            [-2.390341660771485, 50.84188575998234],
            [-2.395784445639895, 50.839241597712963],
            [-2.397229582178833, 50.838835617467375],
            [-2.398761646564013, 50.837135296657522],
            [-2.399333468980961, 50.837078485551949],
            [-2.401697758673993, 50.842509947085759],
            [-2.403442254924565, 50.842226056316861],
            [-2.403831694314673, 50.842423443310629],
            [-2.400848371561263, 50.84399393835568],
            [-2.40014013519987, 50.846210329210329],
            [-2.400296952808584, 50.846940882884532],
            [-2.399159349642716, 50.848289163203248],
            [-2.398371636867829, 50.852030943445897],
            [-2.397875595704472, 50.852333885761162],
            [-2.397524560032096, 50.852148937788421],
            [-2.397098232199534, 50.854304095795563],
            [-2.394577980155006, 50.85850585316939],
            [-2.394326989203165, 50.859405052612146],
            [-2.395641265735312, 50.862430173839272],
            [-2.402264581325082, 50.861175547918485],
            [-2.40636448119487, 50.861262052396803],
            [-2.406876678864286, 50.861994054632596],
            [-2.407961431722136, 50.862065802693863],
            [-2.408058501695936, 50.861629327028581],
            [-2.409196049810487, 50.861565092184414],
            [-2.40939827033011, 50.861130944173119],
            [-2.410896018322761, 50.860666160953308],
            [-2.412090177896292, 50.859774388346295],
            [-2.413824734926769, 50.859425633003724],
            [-2.41406698674047, 50.859025506162077],
            [-2.416150160893124, 50.85810265554116],
            [-2.418829593349065, 50.858248635472556],
            [-2.420137956825856, 50.857599167448079],
            [-2.422609839691645, 50.85741937997642],
            [-2.423660783822442, 50.857845409363726],
            [-2.423583441283408, 50.860284457665422],
            [-2.424117492303151, 50.861830126714779],
            [-2.424491930514216, 50.86238180346681],
            [-2.425590465008457, 50.86287058957415],
            [-2.42340584298689, 50.863891095159254],
            [-2.423749934403992, 50.866892439905008],
            [-2.427926605336544, 50.869250311424786],
            [-2.428521097883463, 50.869302084022273],
            [-2.432683382124631, 50.867449553399908],
            [-2.434694255508453, 50.867002344337159],
            [-2.435320705347788, 50.866978426240777],
            [-2.435722861232485, 50.867588414468621],
            [-2.435744938999902, 50.869193492126847],
            [-2.437655368980289, 50.868789771362195],
            [-2.440378006555172, 50.867266087958669],
            [-2.441444832310551, 50.867377159640569],
            [-2.442936416645483, 50.866424595549489],
            [-2.443409078212723, 50.865020869163011],
            [-2.443356291937955, 50.863201886613744],
            [-2.44439283438302, 50.862080174034212],
            [-2.451034988002513, 50.861886520740313],
            [-2.45264946715522, 50.861484596693913],
            [-2.456465272331796, 50.861711625859698],
            [-2.457269759635242, 50.863046560270774],
            [-2.458847468903295, 50.864110473350522],
            [-2.459415529677234, 50.864945439790965],
            [-2.45927386107382, 50.8654226003761],
            [-2.46052283751176, 50.866280956746202],
            [-2.460391439308188, 50.866501791720509],
            [-2.462343353316707, 50.866876253320171],
            [-2.463152255561552, 50.867770496289964],
            [-2.463917879463554, 50.868596562671186],
            [-2.463542275021941, 50.869125016253292],
            [-2.464015542585808, 50.869129429498578],
            [-2.464540437502492, 50.870038280656516],
            [-2.465829844955573, 50.870368559228965],
            [-2.4666046801999, 50.871111839983186],
            [-2.466909935782992, 50.872788618406972],
            [-2.468234725088295, 50.873661875415713],
            [-2.468779219764685, 50.873819755148936],
            [-2.468789040911063, 50.873523862636688],
            [-2.469565478221742, 50.873568400272134],
            [-2.474904546982084, 50.87477876014831],
            [-2.477987612012879, 50.875089898957341],
            [-2.479556570237321, 50.875492618695077],
            [-2.479778068212204, 50.876301032406666],
            [-2.480194127562977, 50.876401835072201],
            [-2.480239126060097, 50.878151588661829],
            [-2.475169679999762, 50.881420431976849],
            [-2.471526318176549, 50.882250831858457],
            [-2.471057985229858, 50.883030573283108],
            [-2.464914306676056, 50.882470705655152],
            [-2.464975063853197, 50.884146661431295],
            [-2.463924759229957, 50.884168827509626],
            [-2.463545685320516, 50.885505716813547],
            [-2.462601695918921, 50.886074190886127],
            [-2.462999999812733, 50.886386448247706],
            [-2.462551201158653, 50.886286613448029],
            [-2.462578040705063, 50.886556281216649],
            [-2.462015135874891, 50.886562108632681],
            [-2.461429727958341, 50.887167820191642],
            [-2.460924576758043, 50.887120358334471],
            [-2.461136649682965, 50.887433357565314],
            [-2.460664629136386, 50.887285947207168],
            [-2.460275373648484, 50.887602219940689],
            [-2.460607251917511, 50.887810434760667],
            [-2.460538582497947, 50.88819918052517],
            [-2.460104546117429, 50.888155930619583],
            [-2.460486865055409, 50.888577068354728],
            [-2.460436218074897, 50.890216595056799],
            [-2.459295739205106, 50.891925162208338],
            [-2.459970568510013, 50.892738120178436],
            [-2.459473396736064, 50.892785041403471],
            [-2.459277583430342, 50.893258815828865],
            [-2.458925318329071, 50.893149594643702],
            [-2.459380461883717, 50.893599225095087],
            [-2.4592001575017, 50.893915570205451],
            [-2.458657411218235, 50.893666815342044],
            [-2.45894790288915, 50.894144971192055],
            [-2.457868237298684, 50.894096157493387],
            [-2.457064333185967, 50.893022911919168],
            [-2.453424184138768, 50.890504837570155],
            [-2.451958023477228, 50.889881952966228],
            [-2.452231117989, 50.8896012291057],
            [-2.448502805995314, 50.88672993820461],
            [-2.445982951038243, 50.887248571454265],
            [-2.439578939739035, 50.886998605450735],
            [-2.433414185798952, 50.886327451507327],
            [-2.427200081381041, 50.884904382746349],
            [-2.426487848586792, 50.885057162769158],
            [-2.426284903750534, 50.885561482545356],
            [-2.425238885260886, 50.885591378040495],
            [-2.425101337694689, 50.885941686173823],
            [-2.423616783371334, 50.886054096379148],
            [-2.423267664526059, 50.88580627227023],
            [-2.421193451965491, 50.885637526871442],
            [-2.418685153043049, 50.886520622901585],
            [-2.417950268243199, 50.886372184557771],
            [-2.417865994678625, 50.886804124581758],
            [-2.417397039614657, 50.886665520919941],
            [-2.417432461891901, 50.886970238609472],
            [-2.416946383365379, 50.88730200008883],
            [-2.416631102607497, 50.88717903047791],
            [-2.415982874748058, 50.887821606714709],
            [-2.416121054077882, 50.889607016045602],
            [-2.414711454956278, 50.89001040095809],
            [-2.414623519031828, 50.890514290983184],
            [-2.414198433090988, 50.890355735213539],
            [-2.413624536644034, 50.890570892433928],
            [-2.413649041940746, 50.890929604467274],
            [-2.413028410121075, 50.890684510790727],
            [-2.411307039571851, 50.891048491723602],
            [-2.409480585639819, 50.892888477277097],
            [-2.409156396107377, 50.892562289472245],
            [-2.406484629989892, 50.893355770266872],
            [-2.402809293230056, 50.893365819805915],
            [-2.400282330496847, 50.897963360871366],
            [-2.400468167899934, 50.90023151528866],
            [-2.399331075795073, 50.901291124563684],
            [-2.399540861050334, 50.906193079615029],
            [-2.401491226279614, 50.909886767467931],
            [-2.404172170715291, 50.91265976669817],
            [-2.407640161227167, 50.918208612047977],
            [-2.409508046577843, 50.920479849164671],
            [-2.408997662886872, 50.921817011415762],
            [-2.409698421297668, 50.921745311735563],
            [-2.41105955550744, 50.920572411447438],
            [-2.41128186773256, 50.918518661147559],
            [-2.411583045230033, 50.918310773808159],
            [-2.412776005945287, 50.919047534678306],
            [-2.416806415891718, 50.91986051016471],
            [-2.41879009410377, 50.921344349484642],
            [-2.420572346935969, 50.921303768702977],
            [-2.424858673885755, 50.923310634673179],
            [-2.427130835635022, 50.923626067059686],
            [-2.429426942261269, 50.924684139877044],
            [-2.433032072670981, 50.924815596033582],
            [-2.437531920443277, 50.925831140414097],
            [-2.442327600250358, 50.924564016941389],
            [-2.443535493493133, 50.928297575745198],
            [-2.446691053629968, 50.929738712845413],
            [-2.446749648299749, 50.933625003345441],
            [-2.447132429836418, 50.934216136357492],
            [-2.445719755658324, 50.93426200567604],
            [-2.446056148620558, 50.934765193829293],
            [-2.444422600342434, 50.93672187542473],
            [-2.440246383405609, 50.938757393339642],
            [-2.435738308772021, 50.939437049527022],
            [-2.430226630748827, 50.939491667682098],
            [-2.43083197206511, 50.941850833988099],
            [-2.431570087206649, 50.942095397482795],
            [-2.431809326597665, 50.942569310221785],
            [-2.430262739782818, 50.943247651640299],
            [-2.429409939187865, 50.945231812539994],
            [-2.427632054122333, 50.947707645070359],
            [-2.426591067614651, 50.948594504919541],
            [-2.424771579028472, 50.948724336042346],
            [-2.42541921469228, 50.949808255237109],
            [-2.427262327293926, 50.951162967117774],
            [-2.427177167143391, 50.951811628223311],
            [-2.425656254637306, 50.953626447532052],
            [-2.424212745966562, 50.954417634760418],
            [-2.423712834503853, 50.956117207482741],
            [-2.423258517382628, 50.956414704234035],
            [-2.424030900330363, 50.958525104063362],
            [-2.42036290809792, 50.959602165872411],
            [-2.418100846286181, 50.959809020951312],
            [-2.41492976310333, 50.960590081011837],
            [-2.403141356710372, 50.965180614501762],
            [-2.397039912197348, 50.968408192906864],
            [-2.392740471038412, 50.969248233104686],
            [-2.383544842401965, 50.972365873987336],
            [-2.38107606993035, 50.972818180015842],
            [-2.374785639772596, 50.973232409232708],
            [-2.365568591444444, 50.97550791826044],
            [-2.362560132481605, 50.976570300670168],
            [-2.358265125242553, 50.97742434241993],
            [-2.353210655289225, 50.977891159569623],
            [-2.351186143413616, 50.978588776143937],
            [-2.345919177414058, 50.979047823531715],
            [-2.344560116616424, 50.978836027765183],
            [-2.344138216207731, 50.979762580281601],
            [-2.344934400716904, 50.980326745319154],
            [-2.344487006974686, 50.981274056468528],
            [-2.344770196835757, 50.98181185913446],
            [-2.343879217490786, 50.982301869795336],
            [-2.344634554721792, 50.983500113907404],
            [-2.343909011918706, 50.983826875541503],
            [-2.34385617123767, 50.984387251185048],
            [-2.344024813806439, 50.984845361319707],
            [-2.345854665775472, 50.984740139879626],
            [-2.346986907315941, 50.985269123720727],
            [-2.350389064636525, 50.988123906016519],
            [-2.351487135131021, 50.987884107820932],
            [-2.352295506055133, 50.988146044491302],
            [-2.352470626070473, 50.988506106729552],
            [-2.351945093836752, 50.988719910297597],
            [-2.352695160426622, 50.98993340406993],
            [-2.354845989504862, 50.990230825652958],
            [-2.354953705588672, 50.990903121285591],
            [-2.361239959484272, 50.991272305957125],
            [-2.364877993977212, 50.990425608692554],
            [-2.366001567037185, 50.991437321092619],
            [-2.367546051875715, 50.991254421605198],
            [-2.368687096790588, 50.991764283448113],
            [-2.369799305651152, 50.991511679422914],
            [-2.37061056985269, 50.99175819332293],
            [-2.371024174742122, 50.991273094469179],
            [-2.371623157064488, 50.991343127686406],
            [-2.372300213115508, 50.990486702990559],
            [-2.372932060624991, 50.990747261254391],
            [-2.373571833319065, 50.990397214184192],
            [-2.374059995654191, 50.990684303680517],
            [-2.374635256344499, 50.990463047221958],
            [-2.376325825374782, 50.990909922344102],
            [-2.376821670297738, 50.990735670996912],
            [-2.376915373230605, 50.991221851475217],
            [-2.377763319745839, 50.99141963895319],
            [-2.377034108881878, 50.991807765420681],
            [-2.377231328991868, 50.992407813287635],
            [-2.377844780501128, 50.992677396681742],
            [-2.377460582268566, 50.993618333541875],
            [-2.378240010506809, 50.993444957561202],
            [-2.378516586928851, 50.994163444182661],
            [-2.379388574581515, 50.994331467341048],
            [-2.378880709067699, 50.994782730860869],
            [-2.380949875181517, 50.996717432034522],
            [-2.381007649101161, 50.999941875391691],
            [-2.381502366238784, 51.000315237960514],
            [-2.38091385493937, 51.001191208740956],
            [-2.381759504936188, 51.001952790280711],
            [-2.381352101191229, 51.004928765516127],
            [-2.37969126215851, 51.005223729213739],
            [-2.379022976715345, 51.005941685084068],
            [-2.379644044787633, 51.006248102190376],
            [-2.379552306936641, 51.006533455327599],
            [-2.378543456819927, 51.007264204761157],
            [-2.377679897620321, 51.007285884960453],
            [-2.377850704758199, 51.008134202219573],
            [-2.377142746636451, 51.008363096108312],
            [-2.376476836637071, 51.009208719398892],
            [-2.375659279671452, 51.009283290635331],
            [-2.37626681595032, 51.009676094794251],
            [-2.375917165614541, 51.010856106441949],
            [-2.378161503210018, 51.011121324955432],
            [-2.37805091898361, 51.011720567552352],
            [-2.371165723547567, 51.013466472100454],
            [-2.369150851037892, 51.014282162238196],
            [-2.369101230414973, 51.015931498176784],
            [-2.367252661996485, 51.01778523400445],
            [-2.363482034710491, 51.019992039194676],
            [-2.361568113038942, 51.023023870167158],
            [-2.360463302597878, 51.02341395202069],
            [-2.360678063495328, 51.025181162565225],
            [-2.356774056906154, 51.02684952476352],
            [-2.353473519628579, 51.027843311947059],
            [-2.349772984997103, 51.030084526927183],
            [-2.347531494760044, 51.031253014512686],
            [-2.347296162384778, 51.031820224647092],
            [-2.344258181752687, 51.032525217969258],
            [-2.343414455393843, 51.033365777072362],
            [-2.342759354617574, 51.035227292267862],
            [-2.341540471226718, 51.035487141583509],
            [-2.33892534588657, 51.036919127305495],
            [-2.337732185801987, 51.036999017861476],
            [-2.336549918390756, 51.038188503680189],
            [-2.33608195654186, 51.039940634397283],
            [-2.335423516793414, 51.039617009521194],
            [-2.332092816843227, 51.039774899985495],
            [-2.33015316241837, 51.041194873633309],
            [-2.330080860341101, 51.042059228784389],
            [-2.329633965682976, 51.042198071760396],
            [-2.330371401583421, 51.043801095905465],
            [-2.331452498679935, 51.044381626370658],
            [-2.333318135670606, 51.046743962316356],
            [-2.332542112388585, 51.049531057046877],
            [-2.333173861171554, 51.0514850579628],
            [-2.340112505462306, 51.056069057672836],
            [-2.342198525368157, 51.056073755232923],
            [-2.344489907431675, 51.055488821956189],
            [-2.346511960076315, 51.05858514368316],
            [-2.349550246481483, 51.061675694651299],
            [-2.349524642158254, 51.063012008541392],
            [-2.349941972815454, 51.06366449004787],
            [-2.349460246823431, 51.064124533082612],
            [-2.3494175085439, 51.066596608679276],
            [-2.3499807795228, 51.068806995529471],
            [-2.349118982879271, 51.070732998251088],
            [-2.347641267821396, 51.071807472548521],
            [-2.347007496616322, 51.072753533712515],
            [-2.343179174668934, 51.074839333905395],
            [-2.341727359724205, 51.075009944882922],
            [-2.337746322048905, 51.074609682467248],
            [-2.334375332363873, 51.075267714730387],
            [-2.329960738875679, 51.07671811292029],
            [-2.328475455601357, 51.078022563561198],
            [-2.326536101026063, 51.078916424875956],
            [-2.325856279144683, 51.079681756317086],
            [-2.320624918176698, 51.080996495952164],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000051",
        LAD13CDO: "19UG",
        LAD13NM: "Purbeck",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-2.225217115210557, 50.616484189175203],
              [-2.225011749186691, 50.616101491195401],
              [-2.225400955622523, 50.616207752887462],
              [-2.225217115210557, 50.616484189175203],
            ],
          ],
          [
            [
              [-2.225651473784972, 50.616573276230085],
              [-2.226028577886943, 50.616218226300411],
              [-2.226285598004687, 50.616465030091327],
              [-2.225651473784972, 50.616573276230085],
            ],
          ],
          [
            [
              [-1.977826472103973, 50.665764095168768],
              [-1.979109960404589, 50.665440590131709],
              [-1.980213560376402, 50.665542398327311],
              [-1.980674721241914, 50.665804163840811],
              [-1.980559935941327, 50.666234897988659],
              [-1.979198601018804, 50.666586282798065],
              [-1.978612817987463, 50.666562796959262],
              [-1.977826472103973, 50.665764095168768],
            ],
          ],
          [
            [
              [-1.993639712089218, 50.677313580505711],
              [-1.992003656417914, 50.677245138683084],
              [-1.992031910816066, 50.67755808781628],
              [-1.9914530535119, 50.677578731426877],
              [-1.9910866088731, 50.676949213182532],
              [-1.991403662233192, 50.676765784410684],
              [-1.993447265944624, 50.677071665639787],
              [-1.993850647682772, 50.676845968970348],
              [-1.991180135312151, 50.676297246530943],
              [-1.993012992243395, 50.675559961962996],
              [-1.994634789376357, 50.67608162310453],
              [-1.995105979106002, 50.676967427535345],
              [-1.994921916459375, 50.677722810033764],
              [-1.993639712089218, 50.677313580505711],
            ],
          ],
          [
            [
              [-2.004778261642882, 50.679869366114907],
              [-2.005221149357443, 50.67884147656607],
              [-2.006292616630902, 50.67929645358624],
              [-2.006499343505416, 50.679900753497677],
              [-2.005974296361984, 50.680290168138342],
              [-2.0065291239951, 50.68030182766811],
              [-2.007398398776688, 50.681750502847606],
              [-2.008336875966954, 50.682057988745257],
              [-2.007589580257974, 50.682351204499128],
              [-2.007085658247694, 50.682175879136899],
              [-2.006585886739869, 50.68131710380441],
              [-2.005365768604263, 50.680978143156999],
              [-2.005651638521349, 50.680664282643768],
              [-2.005208583917313, 50.680324379000851],
              [-2.005727979196512, 50.679957449936047],
              [-2.004778261642882, 50.679869366114907],
            ],
          ],
          [
            [
              [-1.9938640471686, 50.682884590262496],
              [-1.993953279758312, 50.682402584431642],
              [-1.994805358672584, 50.682531219604357],
              [-1.994263302867933, 50.682035696011333],
              [-1.994625696333901, 50.681623845395833],
              [-1.994174187738526, 50.681556379253763],
              [-1.995351894695646, 50.68063827156945],
              [-1.992678201701892, 50.680871052162246],
              [-1.991980313483554, 50.681484312124361],
              [-1.991542986624814, 50.681287341400676],
              [-1.991565716732914, 50.680805332572199],
              [-1.990873531988147, 50.681124523996949],
              [-1.989955052704379, 50.680577692453582],
              [-1.990545394921704, 50.679920371698394],
              [-1.989975004741125, 50.679918526794708],
              [-1.989655040675473, 50.680350150762393],
              [-1.98836858877328, 50.67984014445571],
              [-1.989189499069128, 50.679830329596939],
              [-1.989618493024499, 50.679177495111475],
              [-1.988753632662657, 50.679556010970728],
              [-1.988833045802326, 50.678893253882407],
              [-1.988061499643998, 50.679663856527334],
              [-1.987114730919422, 50.67928066739902],
              [-1.987962660198167, 50.67872320654093],
              [-1.988535860254703, 50.678751140494938],
              [-1.990636529314281, 50.677068782340861],
              [-1.991407740501565, 50.677712719981237],
              [-1.994303426380019, 50.677775840008543],
              [-1.995373294354019, 50.678815445227158],
              [-1.995804871549282, 50.679987213855085],
              [-1.996273378923502, 50.679685972127977],
              [-1.996710704403957, 50.679980945954128],
              [-1.996610123726475, 50.681335248796842],
              [-1.995609386374646, 50.681870285172494],
              [-1.994852039259692, 50.68280999617528],
              [-1.9938640471686, 50.682884590262496],
            ],
          ],
          [
            [
              [-2.009503508830195, 50.683610041835308],
              [-2.009233025288145, 50.68297427829966],
              [-2.00964077706209, 50.683455355329798],
              [-2.00969449381813, 50.68311992198624],
              [-2.010365411692383, 50.683080294721229],
              [-2.01189140686885, 50.683596328158387],
              [-2.010858224333174, 50.684069448799114],
              [-2.009503508830195, 50.683610041835308],
            ],
          ],
          [
            [
              [-1.986187542739004, 50.684680697918083],
              [-1.984828775654688, 50.684310931043377],
              [-1.983182487134998, 50.684509448176584],
              [-1.981692054784448, 50.684279013226096],
              [-1.98214515215136, 50.683904985024213],
              [-1.981357910107867, 50.68450557774279],
              [-1.980053226620783, 50.683533250958583],
              [-1.982198221064527, 50.68203720323632],
              [-1.984654051712008, 50.681823519817613],
              [-1.987218749437538, 50.681975800529663],
              [-1.988996587411214, 50.681683714102157],
              [-1.989906498724797, 50.682641520114913],
              [-1.990138421764904, 50.68366940830532],
              [-1.991054273723742, 50.683450956808421],
              [-1.991147565089144, 50.684160489916884],
              [-1.99056583689755, 50.683967101983988],
              [-1.990558688985663, 50.684328609045714],
              [-1.986187542739004, 50.684680697918083],
            ],
          ],
          [
            [
              [-1.99894834083516, 50.683125740532589],
              [-1.999486217298813, 50.682637437736332],
              [-2.000310003087869, 50.682474668173263],
              [-2.00039350272443, 50.681675214018931],
              [-2.00123565848607, 50.681187800182123],
              [-2.000805371708014, 50.680951296087066],
              [-2.000972378141901, 50.68057719677271],
              [-2.001363011640373, 50.680240863762791],
              [-2.002759989534835, 50.680347849389754],
              [-2.002949676821059, 50.680745323089461],
              [-2.002050938633071, 50.681393720376327],
              [-2.003261130499506, 50.681662572696446],
              [-2.002750241363101, 50.682835239014643],
              [-2.002372309202174, 50.682704853609614],
              [-2.002182692231211, 50.68371653969259],
              [-2.002955554476042, 50.683901771080862],
              [-2.001037603940083, 50.684846044601954],
              [-1.999777799663988, 50.683840665045395],
              [-1.999844325981735, 50.683328978788367],
              [-1.99894834083516, 50.683125740532589],
            ],
          ],
          [
            [
              [-2.00459618268095, 50.684731742866859],
              [-2.005141078049511, 50.684084242553531],
              [-2.00516221541351, 50.683261406987299],
              [-2.005954966106824, 50.683958303779384],
              [-2.006518332092059, 50.683985250534235],
              [-2.006732164795937, 50.684621922944586],
              [-2.007991915557914, 50.684356555079262],
              [-2.007867293890239, 50.684020235854881],
              [-2.008599003478186, 50.683511194125202],
              [-2.009652238996605, 50.68411092419278],
              [-2.006831268970766, 50.684747815118556],
              [-2.005646482651031, 50.684688529240951],
              [-2.004512729212473, 50.685336057476668],
              [-2.00459618268095, 50.684731742866859],
            ],
          ],
          [
            [
              [-2.071289709536419, 50.689744009022789],
              [-2.072386219029766, 50.689339557681485],
              [-2.072198953875275, 50.689998840599941],
              [-2.073012229617996, 50.690438076175411],
              [-2.072566411267454, 50.690515692939599],
              [-2.071289709536419, 50.689744009022789],
            ],
          ],
          [
            [
              [-2.072819878703058, 50.692377026736288],
              [-2.073239195368183, 50.691636662556036],
              [-2.072483281355017, 50.691686596675041],
              [-2.071960159298882, 50.691215703582898],
              [-2.072385297433888, 50.690576057166119],
              [-2.07273758929014, 50.691351009355699],
              [-2.073473042011106, 50.690895514963337],
              [-2.074417243802269, 50.691746524222438],
              [-2.073399100154751, 50.692495366010171],
              [-2.072819878703058, 50.692377026736288],
            ],
          ],
          [
            [
              [-2.018017393390696, 50.694072046810277],
              [-2.018246375683717, 50.693118783162276],
              [-2.017246429148488, 50.691987654246255],
              [-2.015959358708701, 50.691474356448495],
              [-2.015339313769629, 50.691581454414298],
              [-2.014614255008648, 50.690904397704934],
              [-2.014775395116068, 50.690126506994012],
              [-2.015551018916722, 50.689643495374291],
              [-2.01582433912201, 50.689933023497147],
              [-2.016281321592168, 50.68914699591614],
              [-2.017852736677432, 50.689292444317424],
              [-2.016963558008899, 50.688869921318357],
              [-2.015727788199273, 50.68909221758674],
              [-2.015291587882743, 50.688535627263335],
              [-2.015726054009954, 50.68815517639387],
              [-2.015302733086003, 50.687992465449938],
              [-2.015077855024223, 50.688614791528828],
              [-2.014354532463732, 50.688810026513607],
              [-2.014630421709968, 50.688309996344771],
              [-2.013984950149704, 50.688469248191431],
              [-2.013843288520399, 50.688136534763075],
              [-2.012240777084071, 50.687975748002437],
              [-2.013044793817062, 50.687812890715584],
              [-2.013864191489617, 50.687034024555423],
              [-2.013319109877227, 50.686739128046788],
              [-2.014265954553501, 50.686191357002052],
              [-2.014050580816257, 50.685500742420686],
              [-2.01440293651004, 50.685144586696588],
              [-2.017386583520558, 50.684436445519516],
              [-2.018023684406215, 50.684763682538772],
              [-2.018635146139303, 50.684670959918286],
              [-2.018690577977338, 50.685234794824467],
              [-2.019159201191984, 50.685442449627708],
              [-2.018921513645628, 50.685739248911887],
              [-2.019849061628653, 50.686608688950095],
              [-2.020410314771872, 50.688179619289016],
              [-2.019538663468746, 50.689044870308479],
              [-2.0193894304879, 50.691017897876435],
              [-2.022011275038665, 50.693958943123853],
              [-2.021038448871808, 50.693555352189897],
              [-2.019450264011709, 50.694296631068504],
              [-2.018682816150411, 50.69407913471963],
              [-2.018017393390696, 50.694072046810277],
            ],
          ],
          [
            [
              [-1.971850187694167, 50.69613397286362],
              [-1.965006963091128, 50.694793120780673],
              [-1.96130140741096, 50.695263176096276],
              [-1.958162812675516, 50.694941059394964],
              [-1.957496504325376, 50.694340106788601],
              [-1.955922307050717, 50.691121033430392],
              [-1.956496014586964, 50.689200403041468],
              [-1.956097066881248, 50.688916983713455],
              [-1.957136523100326, 50.688494710318018],
              [-1.956771534915247, 50.688230190381958],
              [-1.958306364307613, 50.68788182833925],
              [-1.961066670336538, 50.686300057399237],
              [-1.966654121792096, 50.685925880988549],
              [-1.97059330576596, 50.686360381559481],
              [-1.974792782459301, 50.687256131632516],
              [-1.980360400821454, 50.686928955511469],
              [-1.98188486913328, 50.687199879845352],
              [-1.983552462120247, 50.687085016404104],
              [-1.985168622764117, 50.688467410813324],
              [-1.988095132866445, 50.692463203968536],
              [-1.987428264482934, 50.692675363531123],
              [-1.985601271520326, 50.694944916869204],
              [-1.984848133730538, 50.694708314851525],
              [-1.983555440572054, 50.694888896103777],
              [-1.978733777595333, 50.696735229678879],
              [-1.976305319165246, 50.697229365833152],
              [-1.974175827858075, 50.697237911141549],
              [-1.971850187694167, 50.69613397286362],
            ],
          ],
          [
            [
              [-2.107282537129604, 50.792960874215957],
              [-2.10403618514763, 50.788408188615946],
              [-2.100550722079922, 50.785826788271422],
              [-2.099535908432769, 50.786236822887467],
              [-2.093224098988332, 50.785938103128082],
              [-2.08892419134624, 50.787296650210664],
              [-2.084734525160911, 50.787782679112496],
              [-2.079657140024087, 50.787689148440357],
              [-2.076264218571291, 50.787065540872646],
              [-2.069293833617462, 50.786504272815975],
              [-2.069193927060402, 50.786095170425178],
              [-2.072447957718119, 50.785079724766852],
              [-2.072813704802337, 50.782199174779514],
              [-2.074193613897752, 50.781155163205121],
              [-2.079121365621154, 50.779584507136462],
              [-2.084806820451288, 50.779935706232571],
              [-2.087491524153866, 50.779782642045127],
              [-2.088503589498534, 50.779437460418634],
              [-2.08976811828876, 50.77762088883869],
              [-2.087515940114066, 50.773883487812938],
              [-2.081231823093666, 50.77546175060408],
              [-2.078762707383496, 50.77548862881406],
              [-2.07605715816749, 50.773156853731102],
              [-2.074478449445911, 50.771200188794126],
              [-2.072889624681082, 50.770855880492782],
              [-2.07071516877235, 50.773334683023783],
              [-2.069651865976855, 50.775459374545129],
              [-2.070549924088495, 50.777543313982243],
              [-2.068647146069496, 50.777943724297131],
              [-2.067584344048852, 50.778590913982036],
              [-2.067341286205076, 50.780196230693484],
              [-2.064162626042804, 50.7791225170945],
              [-2.061563537000353, 50.776466615520071],
              [-2.062153445978084, 50.775331437047342],
              [-2.062594686566934, 50.772282712487076],
              [-2.059460214371334, 50.769766433518306],
              [-2.058415657852531, 50.766814694026841],
              [-2.063920110590006, 50.763595141026933],
              [-2.063852749907403, 50.763068211416204],
              [-2.062483632417611, 50.76135406590781],
              [-2.061349167543318, 50.761192803784326],
              [-2.060377138778441, 50.76164923845549],
              [-2.059499343989966, 50.761522895783024],
              [-2.05705170410341, 50.759969302221513],
              [-2.057488231037357, 50.757545577943404],
              [-2.054979063326163, 50.756480269356409],
              [-2.050701074386733, 50.762195222982825],
              [-2.048368685670567, 50.76215755065347],
              [-2.047616945055621, 50.761878191623858],
              [-2.042203194913304, 50.759795801896352],
              [-2.034093262269889, 50.755523384685866],
              [-2.032327125942074, 50.753832380223869],
              [-2.027245441202336, 50.747211521265704],
              [-2.024507585702652, 50.742863297880888],
              [-2.022766601889437, 50.744547075924388],
              [-2.020326683379171, 50.746113149526586],
              [-2.017074328794248, 50.747405020105127],
              [-2.012743020871238, 50.74831024206685],
              [-2.013534888350955, 50.737063061075986],
              [-2.01624564163055, 50.736704801485189],
              [-2.016621035997211, 50.736344142635694],
              [-2.022527458509307, 50.736575148001151],
              [-2.022195043998385, 50.734859415452455],
              [-2.024554433491308, 50.729605429575756],
              [-2.024779587745342, 50.729368874561992],
              [-2.025798394524363, 50.729533216906447],
              [-2.02687509082663, 50.729336032086565],
              [-2.027524227587381, 50.729707275911522],
              [-2.030183720982544, 50.729777653755569],
              [-2.032516863297878, 50.72916012490434],
              [-2.03393301794389, 50.728212791272114],
              [-2.035193739999568, 50.727889578244593],
              [-2.036008685197563, 50.72825442953399],
              [-2.034758993809252, 50.730075824715975],
              [-2.036118936981474, 50.731577181663731],
              [-2.037284089062425, 50.730362803914488],
              [-2.039747416393447, 50.729625486119517],
              [-2.041176110629507, 50.730193323001039],
              [-2.04201320011062, 50.731451994193115],
              [-2.04149871388667, 50.731747620644171],
              [-2.042576132899575, 50.731910414067258],
              [-2.042653490316765, 50.731282698387744],
              [-2.043615292703213, 50.730984683554816],
              [-2.043403418142149, 50.730170927782112],
              [-2.044320869941147, 50.730943948957808],
              [-2.046303770574846, 50.730180596731493],
              [-2.048059022377751, 50.728554010078689],
              [-2.048291333626455, 50.72713667039789],
              [-2.049805365404921, 50.726658519781068],
              [-2.049511835190811, 50.726414045054703],
              [-2.050062740354244, 50.72621327221092],
              [-2.051450442130733, 50.728134396625443],
              [-2.053353944264522, 50.728766620054678],
              [-2.051735255342997, 50.72816484500175],
              [-2.051031830315613, 50.727537468920687],
              [-2.050426838590222, 50.726205920362034],
              [-2.050958090198495, 50.726176911676831],
              [-2.051927828130792, 50.726787083390299],
              [-2.050741046658066, 50.725918917404542],
              [-2.05089181787666, 50.725165266163543],
              [-2.049977750552709, 50.724903077815995],
              [-2.048971623690924, 50.723304611070056],
              [-2.048153694232791, 50.722807659092076],
              [-2.046208562209046, 50.722739207052101],
              [-2.045717173810757, 50.722932744220714],
              [-2.045772915087666, 50.723434513038654],
              [-2.046799955725624, 50.723388240404908],
              [-2.04751395361388, 50.723384352952223],
              [-2.047988883356349, 50.723720483097928],
              [-2.047969063247952, 50.725113455114197],
              [-2.047422650453004, 50.725548924784057],
              [-2.046190199570494, 50.725659132804964],
              [-2.045937073923885, 50.726137643032509],
              [-2.04303160666742, 50.726456199050197],
              [-2.042247986537525, 50.726290123143855],
              [-2.041575356713724, 50.725070062083184],
              [-2.042410882016006, 50.724695664446649],
              [-2.042145764989915, 50.724482635025716],
              [-2.042207767409103, 50.724114812455305],
              [-2.041341477490175, 50.723334561384853],
              [-2.044152957480879, 50.719695090206578],
              [-2.04097045401239, 50.720269991613705],
              [-2.040743572706616, 50.720007485622787],
              [-2.044523286650819, 50.718846040214487],
              [-2.044673102017732, 50.718493469753263],
              [-2.043836566479707, 50.717654772608796],
              [-2.045209743763872, 50.718265747026891],
              [-2.046252242074439, 50.718207782654623],
              [-2.04557501260834, 50.718068664417174],
              [-2.045667193426104, 50.716729618700612],
              [-2.046249286538514, 50.716652950179352],
              [-2.046949893865601, 50.717521361114841],
              [-2.046071528610455, 50.715943498695673],
              [-2.041632107963234, 50.714041428226238],
              [-2.045001076431286, 50.714868394793911],
              [-2.048376718963537, 50.716554961140545],
              [-2.051244719754079, 50.717537528196722],
              [-2.055609264592783, 50.717812492752984],
              [-2.057802719668674, 50.717240386064823],
              [-2.062908222306184, 50.716551590347855],
              [-2.065005786023838, 50.714353523389043],
              [-2.066477735456145, 50.713575722367871],
              [-2.06833434015747, 50.711455069129407],
              [-2.06898688520991, 50.711201089616438],
              [-2.069729426815375, 50.709566674878729],
              [-2.070161099568871, 50.709376669188693],
              [-2.070358975246876, 50.710053698944776],
              [-2.070864386693254, 50.709923896988855],
              [-2.070989047803752, 50.709009264397331],
              [-2.071909074974662, 50.708666076999563],
              [-2.071907937303383, 50.707925079977308],
              [-2.071350961241392, 50.707657441227205],
              [-2.070669234532653, 50.708240584393494],
              [-2.069206591216129, 50.708447397728499],
              [-2.067903152113261, 50.708091157297901],
              [-2.067340151847322, 50.707559117787554],
              [-2.066226196224542, 50.707975220753632],
              [-2.064826937349305, 50.707940938869129],
              [-2.064136637521126, 50.707497083265714],
              [-2.063200737191436, 50.707646875169957],
              [-2.060985858250462, 50.706599511901018],
              [-2.057638267657047, 50.705630011627505],
              [-2.058977318778232, 50.705143733364011],
              [-2.059802994649082, 50.705204461206222],
              [-2.061170882081274, 50.706221721426424],
              [-2.063374889367338, 50.706571254229338],
              [-2.064560047568191, 50.705420435696354],
              [-2.066607392274787, 50.705154894098989],
              [-2.067816812508282, 50.704213558082763],
              [-2.069700400001224, 50.70433744140491],
              [-2.070783205746862, 50.704938397957079],
              [-2.072169760577681, 50.705054449784768],
              [-2.072790489726086, 50.704445256928238],
              [-2.072411886893688, 50.704119058650676],
              [-2.072451068883203, 50.70290052359816],
              [-2.073076880246954, 50.701938811954172],
              [-2.072493375352076, 50.700957174567961],
              [-2.072521226420414, 50.699739545167134],
              [-2.071459912746545, 50.698312161363013],
              [-2.070801330828626, 50.69819206242272],
              [-2.071060042714066, 50.697928418120419],
              [-2.072366324400591, 50.69754182446205],
              [-2.073621649539412, 50.698079698774741],
              [-2.075727652210545, 50.697542379648795],
              [-2.075748752592713, 50.696581045359096],
              [-2.076620151292755, 50.696108356665036],
              [-2.076914612625872, 50.695224178633609],
              [-2.077561117245089, 50.694906306106752],
              [-2.077911967539083, 50.69559491304436],
              [-2.078766183918841, 50.695874010381416],
              [-2.079360597635056, 50.695732420344001],
              [-2.079675908248434, 50.69631762998938],
              [-2.080264647571361, 50.696167945805854],
              [-2.081654150823184, 50.694860336768336],
              [-2.081700912305931, 50.694070743721447],
              [-2.083222953406823, 50.693312477111895],
              [-2.084184961830667, 50.693704765802629],
              [-2.084717524848551, 50.694616239922993],
              [-2.086486665769355, 50.695051083384698],
              [-2.087363381896314, 50.695985669660132],
              [-2.089342023790239, 50.696356463802218],
              [-2.090883033560373, 50.69737504239059],
              [-2.092438138807272, 50.696890907681322],
              [-2.09345316360356, 50.696102334715057],
              [-2.09500112207423, 50.69701653792017],
              [-2.09673610766476, 50.696590651317976],
              [-2.099259591992217, 50.695449147808276],
              [-2.10019225848478, 50.695278385586228],
              [-2.101527878006287, 50.695508339718103],
              [-2.099978241397376, 50.695171556533211],
              [-2.094999343691414, 50.696837584453377],
              [-2.093418945040751, 50.695982759164224],
              [-2.092203028350962, 50.696852425751302],
              [-2.091117663256402, 50.697165328593776],
              [-2.089364413520056, 50.696217958852458],
              [-2.087550187530228, 50.695939666270441],
              [-2.086877182400543, 50.694916800715859],
              [-2.084898477174148, 50.694466828840426],
              [-2.084671502994547, 50.694219694822038],
              [-2.085188544065811, 50.694372193090516],
              [-2.085099883419801, 50.693886651522611],
              [-2.084617350793827, 50.694024592383165],
              [-2.084416629103242, 50.693415931621416],
              [-2.084800361787588, 50.69344892528872],
              [-2.083858165999458, 50.693052131006105],
              [-2.0828783794147, 50.693005172527606],
              [-2.080880171753756, 50.693491287861249],
              [-2.080272487841675, 50.694131990481033],
              [-2.079395135238554, 50.694389784890518],
              [-2.078688357319115, 50.693370491994187],
              [-2.078421505126618, 50.688727732167727],
              [-2.079272824819734, 50.688202878728774],
              [-2.080327679980521, 50.688334347240463],
              [-2.082891326992313, 50.69071921467404],
              [-2.083691527452398, 50.690908387478402],
              [-2.085269513492416, 50.690627566788947],
              [-2.086333321602365, 50.68943164891688],
              [-2.089161663643143, 50.689367463752951],
              [-2.090661573211742, 50.689018285164714],
              [-2.091150548683011, 50.688588949902027],
              [-2.091075232253481, 50.687711319177588],
              [-2.087903600693735, 50.686584274989606],
              [-2.088047795573785, 50.684973568519609],
              [-2.088708667681224, 50.684882239420048],
              [-2.092365100916948, 50.685686938169823],
              [-2.095552671592221, 50.68488670671023],
              [-2.09608905283068, 50.684147961542159],
              [-2.095686043325971, 50.681570077866496],
              [-2.097328247393583, 50.681045337418162],
              [-2.098723159264249, 50.68005496033733],
              [-2.102044751253409, 50.682534086937807],
              [-2.103962992668408, 50.683317453026675],
              [-2.108145153023621, 50.683699426750323],
              [-2.109976496477639, 50.684183316645893],
              [-2.11247453077532, 50.68346510606861],
              [-2.111505381510106, 50.683653088163652],
              [-2.112379549190148, 50.682813224826738],
              [-2.112568958711897, 50.682114306285435],
              [-2.114090094071157, 50.68192937217669],
              [-2.114152883226082, 50.680975181415334],
              [-2.114869134411803, 50.681000555099025],
              [-2.11551085449253, 50.68064740425843],
              [-2.115959416060746, 50.678881683526321],
              [-2.115279767594335, 50.678187220464217],
              [-2.117522838691463, 50.678109436413393],
              [-2.116729416926625, 50.67719837155569],
              [-2.117317565722484, 50.678088061084338],
              [-2.115600225094905, 50.677855070260314],
              [-2.114978421888875, 50.67823428103145],
              [-2.115680454827314, 50.678824407810865],
              [-2.115230598180114, 50.680643185909425],
              [-2.113938720624335, 50.680794637449523],
              [-2.113673825928112, 50.681874024851133],
              [-2.112264012315253, 50.681851113967952],
              [-2.111031583145496, 50.681422444493137],
              [-2.112047457876449, 50.681853121365869],
              [-2.112252991759941, 50.682569644001852],
              [-2.110337895004816, 50.68377200652084],
              [-2.103650649820425, 50.682891476413296],
              [-2.102181210683371, 50.682149078135261],
              [-2.099393296708344, 50.679702773339478],
              [-2.098782975021012, 50.679558510480788],
              [-2.097120215325365, 50.680376451931913],
              [-2.097028915211862, 50.680714655012345],
              [-2.095129554285733, 50.681455427618801],
              [-2.095623024252931, 50.683984678144405],
              [-2.095288963263135, 50.684678291875031],
              [-2.092629155677363, 50.685357594232002],
              [-2.090671059834064, 50.685137917089513],
              [-2.089318226274282, 50.684605695815236],
              [-2.089937749955298, 50.683624111312042],
              [-2.089373040797956, 50.682915020491585],
              [-2.089349881815902, 50.684132653950648],
              [-2.089046259003046, 50.684505185945383],
              [-2.088165691150146, 50.684437512542701],
              [-2.087662241994698, 50.684687891226865],
              [-2.087175451276787, 50.686288063512357],
              [-2.088013398664601, 50.687010447053822],
              [-2.090682013591057, 50.687875294189816],
              [-2.090691920774374, 50.688606394825257],
              [-2.08909746426019, 50.689106724069752],
              [-2.086086664135111, 50.689249279655201],
              [-2.084027925102275, 50.690608687191393],
              [-2.083144107857529, 50.690365620230544],
              [-2.080689200981904, 50.687825108641448],
              [-2.08168714869939, 50.687796533896162],
              [-2.082145793507981, 50.686983268331907],
              [-2.081203238861963, 50.686291491051875],
              [-2.08148015579406, 50.686800285429044],
              [-2.08117468293289, 50.68696776391635],
              [-2.079089055670285, 50.686678737332279],
              [-2.078053361946958, 50.686978895489673],
              [-2.077972191274785, 50.686688484997035],
              [-2.075827718390638, 50.687630543459619],
              [-2.073248763260958, 50.686888500119359],
              [-2.073223815412951, 50.687229340084819],
              [-2.072880773872585, 50.686926501046926],
              [-2.072538848687679, 50.687342178932845],
              [-2.072540758534954, 50.68674685941938],
              [-2.071573023777638, 50.686155738524164],
              [-2.068899526102785, 50.686478395723718],
              [-2.068091796806232, 50.686860163683583],
              [-2.065729029201335, 50.686731130760954],
              [-2.063252122916934, 50.688027458809735],
              [-2.06268319272983, 50.689192324798668],
              [-2.059286117637352, 50.690675210498952],
              [-2.059330372940727, 50.693201241753172],
              [-2.056949705151306, 50.696056721126375],
              [-2.055506274565066, 50.699062783269021],
              [-2.054525904363867, 50.699813239663371],
              [-2.053484257932601, 50.70025076993246],
              [-2.048757053274281, 50.700950687224491],
              [-2.047671762697676, 50.701712820296684],
              [-2.046171008911454, 50.701940043764417],
              [-2.044312597949353, 50.702813960266418],
              [-2.043024194636961, 50.703033867629159],
              [-2.041872726353199, 50.704375100632312],
              [-2.04159272451912, 50.706398557747278],
              [-2.039881246287715, 50.70716803484121],
              [-2.037758417068378, 50.707201120195542],
              [-2.034201475300693, 50.707894663198587],
              [-2.032465159044262, 50.707821423515142],
              [-2.031047180205499, 50.707278654443748],
              [-2.029022088447968, 50.707362812776687],
              [-2.027458449800673, 50.707078126773347],
              [-2.026140185106102, 50.707430047026726],
              [-2.024845239680877, 50.706403367726878],
              [-2.023693042380531, 50.704714782292342],
              [-2.022384108182116, 50.703778904294559],
              [-2.01933907480244, 50.702668857978729],
              [-2.018089699036138, 50.701584541866772],
              [-2.018971474920527, 50.700605993861224],
              [-2.022010246946079, 50.700755630943618],
              [-2.023760825033866, 50.701570921260561],
              [-2.023299368503178, 50.701897450450801],
              [-2.024848410654318, 50.701735258456651],
              [-2.024405445334093, 50.702208369027822],
              [-2.025021770315712, 50.702861106889429],
              [-2.028036926638433, 50.703496197624922],
              [-2.029741759114483, 50.703313219533364],
              [-2.03155807146483, 50.702575336651037],
              [-2.031016851130748, 50.702136639422463],
              [-2.03129419978304, 50.701848798288488],
              [-2.033208856620817, 50.702124340133814],
              [-2.031999206662828, 50.70159860824922],
              [-2.0325845830259, 50.700377235967089],
              [-2.031810436414704, 50.700952984434259],
              [-2.029185332815585, 50.701191983713009],
              [-2.029748223955032, 50.700130701375102],
              [-2.029642801620859, 50.699123545715473],
              [-2.031213082662669, 50.699130326453897],
              [-2.031882328177388, 50.6984080296535],
              [-2.03030386327557, 50.698843701149066],
              [-2.028702149526796, 50.698363000439961],
              [-2.029222992895579, 50.698022046324319],
              [-2.02935137137237, 50.697277417705983],
              [-2.030288548822604, 50.697065847235528],
              [-2.029181335861527, 50.697070628519569],
              [-2.028686207936106, 50.697767687291964],
              [-2.028091536157724, 50.697776826164016],
              [-2.027381071851343, 50.698315659877807],
              [-2.027820087015209, 50.698452244056725],
              [-2.027934621210332, 50.699050457872005],
              [-2.028913652231151, 50.699219053156504],
              [-2.02793939797231, 50.69907540967148],
              [-2.027717411217153, 50.699013052002059],
              [-2.026483657700994, 50.698800576029811],
              [-2.026062805496525, 50.69822693726644],
              [-2.025467263292096, 50.696656943691004],
              [-2.02452660385931, 50.695660755434211],
              [-2.024517477166036, 50.694462029109886],
              [-2.025748574342227, 50.69329090978502],
              [-2.025735442224455, 50.692584984706663],
              [-2.026410522715459, 50.692181958578594],
              [-2.024834870911253, 50.692286623979435],
              [-2.024003008264215, 50.690683398913691],
              [-2.024616866073315, 50.689644610600169],
              [-2.025630451784478, 50.689603922609798],
              [-2.026256605680052, 50.690376256312909],
              [-2.02699230676538, 50.689599115766356],
              [-2.027770991060586, 50.689725728420235],
              [-2.027293675256892, 50.689316673535011],
              [-2.02786958246501, 50.688882187610055],
              [-2.026809600670508, 50.689448080669081],
              [-2.026425910140806, 50.689360040507744],
              [-2.025989621433488, 50.688876331283424],
              [-2.026292298292746, 50.68840144709479],
              [-2.025021210772918, 50.688654425190755],
              [-2.024542621316733, 50.688448594729856],
              [-2.024462925063478, 50.687646460944976],
              [-2.025817277073734, 50.686977107242086],
              [-2.026963839941779, 50.686887817101969],
              [-2.027189057896073, 50.687131467061619],
              [-2.027602263363622, 50.686889464770537],
              [-2.027528458703736, 50.686559449451465],
              [-2.027062844736549, 50.686741212548597],
              [-2.027248037777452, 50.686320309527851],
              [-2.032019065808306, 50.685149132313207],
              [-2.030324740710642, 50.685226926694355],
              [-2.030320322853742, 50.684963440989769],
              [-2.032006702268847, 50.683633861262457],
              [-2.034409733433998, 50.683034255485836],
              [-2.036904604023189, 50.68051282413888],
              [-2.040094262078822, 50.679805833803201],
              [-2.040407487455623, 50.680304821653579],
              [-2.041933081463312, 50.68010643920946],
              [-2.043162031966322, 50.680552926680214],
              [-2.044707389483815, 50.680304141263036],
              [-2.04509822296409, 50.680501829951034],
              [-2.045038239623646, 50.6814137163502],
              [-2.04639423212221, 50.681447353835956],
              [-2.046382366830436, 50.680900600469968],
              [-2.045972267736283, 50.681271264433335],
              [-2.045472200987812, 50.680829019289064],
              [-2.046362475935362, 50.679396124072696],
              [-2.047701506828272, 50.679507989932304],
              [-2.048627973172466, 50.678942861233004],
              [-2.052677580687174, 50.677989660742043],
              [-2.051374177826526, 50.678072978639342],
              [-2.047389819652183, 50.679201465512506],
              [-2.046472486099864, 50.679006694579861],
              [-2.048095744347188, 50.677503346104928],
              [-2.051123464573617, 50.677886040505044],
              [-2.047990789881515, 50.677285765341324],
              [-2.04731951457158, 50.67405944594077],
              [-2.047493657390752, 50.676922663454782],
              [-2.046525357610417, 50.678090313546171],
              [-2.042036653650074, 50.678810548596445],
              [-2.037902380672665, 50.67868697661612],
              [-2.035108843084882, 50.679102422965698],
              [-2.033502997119815, 50.679884367865363],
              [-2.031387184842518, 50.682213179930926],
              [-2.028989922857671, 50.682979986798188],
              [-2.026066427727428, 50.684465383221685],
              [-2.024727447084403, 50.684616755803624],
              [-2.026870553958138, 50.682225110158811],
              [-2.027828660702288, 50.681991072190208],
              [-2.027926140640892, 50.681683497263641],
              [-2.028674912925872, 50.681712989779271],
              [-2.029813626353385, 50.680624580953314],
              [-2.029774951750632, 50.679905172268967],
              [-2.029044228016533, 50.679266874164448],
              [-2.02960228061803, 50.67990971300793],
              [-2.028903306493145, 50.68025521097919],
              [-2.029584197528816, 50.680408814342769],
              [-2.02907369770778, 50.681131959639586],
              [-2.028465289847486, 50.681481029171735],
              [-2.026912607036329, 50.681515573910509],
              [-2.027172162389982, 50.68000653260583],
              [-2.026339695197922, 50.679593959099108],
              [-2.026592163856201, 50.678053445624919],
              [-2.027567027564274, 50.677577501139467],
              [-2.027805095157098, 50.678078339500615],
              [-2.028222175354783, 50.677359718615044],
              [-2.027961864503375, 50.677528845410421],
              [-2.027556948803685, 50.67728703812157],
              [-2.028651728212089, 50.676231024148045],
              [-2.029811893591304, 50.675699259625716],
              [-2.032224624678761, 50.673296650627435],
              [-2.034615279050953, 50.671988414587908],
              [-2.037427198509522, 50.672076567372976],
              [-2.037992185695499, 50.672509833643417],
              [-2.038422200652016, 50.672282175244256],
              [-2.040618512323759, 50.67231379944603],
              [-2.043118557501099, 50.671777827320739],
              [-2.042980883101725, 50.671333636780822],
              [-2.044898140850193, 50.67111798298852],
              [-2.044964072290519, 50.670516342568128],
              [-2.044607944138196, 50.671011081373102],
              [-2.042296817724165, 50.6707025976837],
              [-2.041127075743575, 50.671308230887057],
              [-2.037889570432109, 50.671615089041303],
              [-2.034947248202411, 50.671206845076682],
              [-2.033703468399552, 50.671334911204916],
              [-2.033351559950598, 50.671968102596999],
              [-2.028823229823158, 50.67435599499133],
              [-2.027895625819958, 50.673266302315966],
              [-2.025276030655178, 50.67302320241717],
              [-2.024883815426422, 50.672615916368265],
              [-2.025509955820662, 50.671214709739964],
              [-2.026232850822574, 50.670822463844615],
              [-2.0271838331237, 50.670896883572524],
              [-2.025619923100507, 50.670468287302853],
              [-2.026358766389993, 50.669528382189547],
              [-2.027378037098081, 50.669060518296781],
              [-2.027080403429304, 50.668240449595679],
              [-2.027790503088964, 50.667845499009381],
              [-2.027265614046742, 50.667974220533445],
              [-2.026914845200085, 50.668236891220431],
              [-2.027189816006154, 50.669023692507892],
              [-2.026455641422614, 50.669405141360855],
              [-2.025154490486982, 50.670081143096844],
              [-2.02501834541813, 50.670151874600805],
              [-2.023258689698292, 50.67152903271365],
              [-2.022800644842608, 50.67244818289771],
              [-2.023528523642249, 50.673434540653851],
              [-2.026053659035107, 50.674291006244268],
              [-2.026008615876026, 50.674726265124178],
              [-2.024615543329696, 50.67643699004406],
              [-2.023890837474877, 50.676252791332239],
              [-2.023395600075686, 50.676452531724642],
              [-2.02404109232363, 50.676714087607515],
              [-2.023213609366799, 50.677609933925282],
              [-2.022337459643599, 50.677434748736808],
              [-2.022175713473528, 50.676588563327982],
              [-2.021036271335561, 50.676241657404546],
              [-2.021980410872063, 50.676595794956576],
              [-2.021925543472716, 50.677290944083488],
              [-2.022707007848417, 50.677759314305618],
              [-2.021876644127186, 50.678641662432113],
              [-2.021598441857294, 50.679968143300414],
              [-2.021512785239876, 50.681438471087148],
              [-2.022114254836882, 50.681273790175474],
              [-2.022072187678776, 50.682105625852763],
              [-2.02146505429697, 50.682289192311977],
              [-2.019865424125972, 50.681841641576391],
              [-2.019405535169054, 50.682133983830838],
              [-2.018592711315395, 50.681215061069267],
              [-2.017159270374389, 50.682185599819988],
              [-2.016840728496252, 50.681992303302415],
              [-2.016689387391001, 50.682295380419617],
              [-2.014593277989129, 50.682758792033354],
              [-2.012288895510852, 50.682625970609195],
              [-2.012407694584445, 50.682261752203182],
              [-2.011455238380857, 50.68278073235944],
              [-2.010811119924427, 50.682398604348187],
              [-2.011627673120564, 50.681792413879762],
              [-2.010328451283439, 50.682376167391332],
              [-2.01056628950238, 50.682572187037159],
              [-2.009970375759331, 50.682511989602943],
              [-2.010005661813746, 50.682054256548504],
              [-2.009630630836618, 50.682324070892584],
              [-2.009593734404066, 50.681869941146275],
              [-2.009055864361965, 50.681816928290374],
              [-2.009897951956062, 50.681417580654866],
              [-2.011041606219324, 50.681442654972003],
              [-2.0107740544269, 50.681272717997039],
              [-2.011089658872204, 50.681147688710411],
              [-2.009706844862731, 50.681289001103849],
              [-2.009266555992654, 50.680801631878168],
              [-2.008390449378586, 50.680901519558262],
              [-2.008621033567599, 50.680242334912663],
              [-2.008053361738842, 50.679630870931632],
              [-2.008138195035461, 50.679138962276461],
              [-2.006673254893243, 50.678693918305257],
              [-2.005577697624258, 50.677918804531721],
              [-2.004285548780526, 50.678135587098403],
              [-2.004002457750409, 50.677810060746019],
              [-2.004738376321673, 50.677469206739204],
              [-2.007996389376493, 50.677590423284137],
              [-2.009260106714079, 50.676910475888576],
              [-2.009801979920767, 50.676090292561192],
              [-2.009484852656254, 50.675548956578979],
              [-2.009991516847471, 50.675603768809147],
              [-2.011187131297017, 50.674551506757389],
              [-2.012380154071442, 50.674586455120682],
              [-2.012973017451102, 50.674210492669822],
              [-2.010969144817384, 50.674359982567246],
              [-2.009948921523131, 50.67496618723127],
              [-2.009425241166269, 50.674714435187937],
              [-2.010027777681005, 50.673156840474583],
              [-2.010695631416166, 50.672724228576008],
              [-2.010278161290725, 50.672713475794943],
              [-2.009805527472312, 50.672014208446036],
              [-2.009908767182814, 50.672521963159582],
              [-2.009058388007044, 50.673123648900457],
              [-2.009349981773098, 50.673473442943916],
              [-2.008523529944223, 50.673504982797063],
              [-2.008696277048061, 50.674012160353683],
              [-2.008281641346489, 50.674084133438591],
              [-2.008172797008956, 50.674781078878866],
              [-2.007172346263786, 50.675416034532027],
              [-2.005788277947559, 50.675590573802474],
              [-2.003700791638882, 50.67534606099666],
              [-2.00179027781526, 50.67621750685926],
              [-2.001418061702749, 50.676066434809961],
              [-1.999900920218972, 50.676480112859508],
              [-1.999907997672043, 50.676794858700553],
              [-1.998580480767155, 50.676642875843854],
              [-1.998631443619291, 50.676070938133591],
              [-1.999109798123596, 50.67595403606164],
              [-2.00013443645108, 50.676328135002308],
              [-1.999954697561366, 50.675957634618982],
              [-2.000735909183855, 50.675865005301837],
              [-1.999276797542474, 50.675864109438692],
              [-1.999932051167291, 50.675358718166876],
              [-1.998985273785755, 50.674888395579174],
              [-1.999242835526465, 50.675507097519031],
              [-1.997901190951972, 50.675712118622783],
              [-1.997786582545444, 50.675093416216058],
              [-1.998140384269798, 50.6751608671469],
              [-1.998658364704112, 50.674695948353651],
              [-1.9979875484272, 50.674953132638684],
              [-1.997328082384331, 50.674509778733643],
              [-1.997746990663623, 50.674284068800453],
              [-1.996992694433077, 50.674267866566282],
              [-1.995791270544786, 50.673391039680801],
              [-1.997834774167634, 50.673252602633831],
              [-1.998531042668745, 50.672883010113523],
              [-2.001362758002641, 50.672913130631983],
              [-2.001608956731396, 50.671972935428442],
              [-2.00211414294534, 50.671727424239698],
              [-2.001631570697898, 50.67127869504067],
              [-2.001899000446429, 50.670748118286355],
              [-1.999179223537942, 50.669287714655667],
              [-1.997682077974455, 50.670014312073015],
              [-1.997249131157811, 50.668829059221487],
              [-1.997791125882579, 50.667881233509782],
              [-1.995739437766324, 50.667165358134902],
              [-1.993524990569202, 50.667219217728778],
              [-1.992624939617323, 50.668041103897615],
              [-1.993512050622841, 50.668771366041561],
              [-1.993504890057395, 50.669417045133322],
              [-1.992706633982103, 50.670559078857238],
              [-1.991530681288643, 50.670682202675522],
              [-1.991142882790333, 50.671041884642015],
              [-1.99192373711424, 50.672715489612997],
              [-1.991010945234718, 50.67284312185128],
              [-1.98943169990532, 50.672567814979921],
              [-1.98830091081369, 50.672947203375848],
              [-1.986142354812283, 50.674505414059382],
              [-1.981683559356964, 50.676905870489911],
              [-1.980055859586843, 50.677237439105426],
              [-1.97925653213973, 50.676534069287527],
              [-1.978373218936472, 50.676955670203853],
              [-1.979228317073002, 50.676327231329466],
              [-1.979426789302917, 50.675546696259758],
              [-1.981024754378553, 50.675117110689079],
              [-1.981240188161038, 50.674300604122472],
              [-1.983388730572659, 50.673492477616776],
              [-1.983574530340472, 50.672290173583541],
              [-1.98525643837613, 50.669911812968074],
              [-1.984683551179502, 50.66924807548061],
              [-1.983214674088865, 50.669434026484929],
              [-1.983583391242241, 50.667086969966789],
              [-1.982037238708538, 50.665981535957123],
              [-1.981903290911594, 50.664736018807325],
              [-1.981402361458808, 50.664872631005949],
              [-1.980783036113532, 50.663867141636473],
              [-1.97894498269957, 50.664152798003748],
              [-1.976304142513533, 50.665480527284707],
              [-1.974715351606458, 50.665156457850742],
              [-1.97491080788853, 50.664781501678966],
              [-1.976282041207979, 50.66440139200899],
              [-1.977687693392364, 50.662988004673707],
              [-1.977476327660629, 50.661164232498265],
              [-1.976841053093726, 50.661227058789322],
              [-1.976677050439849, 50.660994113902582],
              [-1.978212886139973, 50.659274091787374],
              [-1.976602192798853, 50.660736906040839],
              [-1.976801181197051, 50.661755825372744],
              [-1.97533884286727, 50.663366131899629],
              [-1.974474314544492, 50.663453177509894],
              [-1.974372634068146, 50.663100639255553],
              [-1.972407536677841, 50.662864584784032],
              [-1.972176635358324, 50.663344743794511],
              [-1.97277459606043, 50.664263943582078],
              [-1.970830059243174, 50.662736507065809],
              [-1.970772309484176, 50.662315631527022],
              [-1.968605577920641, 50.661119035634464],
              [-1.968876086194343, 50.660688354205135],
              [-1.968126372612733, 50.660507398749331],
              [-1.969464159747885, 50.663630040653388],
              [-1.969071465619743, 50.664819679908504],
              [-1.968063368296166, 50.665835591823289],
              [-1.968011723436369, 50.666885032268723],
              [-1.971108962698958, 50.669423588683102],
              [-1.97006174805767, 50.669551923123336],
              [-1.969917654539534, 50.669170593746323],
              [-1.968730551569949, 50.66899762322204],
              [-1.96810420688868, 50.670337374642941],
              [-1.965751410663565, 50.671646056501082],
              [-1.965314283709662, 50.671450785633255],
              [-1.966261297390453, 50.671018510289606],
              [-1.965634427359173, 50.670995846715641],
              [-1.964740539433798, 50.670389470554973],
              [-1.963490954026417, 50.67048621039141],
              [-1.961655767119889, 50.672026982207392],
              [-1.961347749959084, 50.674909055605006],
              [-1.959419869949176, 50.673680895402136],
              [-1.960161418447857, 50.673683847338012],
              [-1.960511363430806, 50.673207350187425],
              [-1.959459781044571, 50.67334637912608],
              [-1.959463083015401, 50.672795125024066],
              [-1.958061069271295, 50.672346793488849],
              [-1.954316843141478, 50.675054908398543],
              [-1.954247102457644, 50.67692806898058],
              [-1.953460269398488, 50.676869306478274],
              [-1.95269710867414, 50.677199933801255],
              [-1.949835138866486, 50.680128577220721],
              [-1.949948717389086, 50.679798592443149],
              [-1.948631259000809, 50.678274652910325],
              [-1.948784695785826, 50.677736055119432],
              [-1.948454747848035, 50.677904973900809],
              [-1.948118232917558, 50.677607165762176],
              [-1.946759878120098, 50.674803519293171],
              [-1.945569032680749, 50.674143803965386],
              [-1.942133050525565, 50.674067516898539],
              [-1.941740866909202, 50.673062832899681],
              [-1.942571916704286, 50.670440958365994],
              [-1.946777440048135, 50.665483441749636],
              [-1.951081307344683, 50.65896736411289],
              [-1.952330331147327, 50.656339292524166],
              [-1.953020818556239, 50.653348575008245],
              [-1.952897964519967, 50.651711840353421],
              [-1.949400471402086, 50.64585608088052],
              [-1.948857796157806, 50.645457464751495],
              [-1.947751715568817, 50.645528019654556],
              [-1.946940285769658, 50.645176036881722],
              [-1.944281490675262, 50.642669410715293],
              [-1.940834614661677, 50.640700995574505],
              [-1.939610842289893, 50.6410960536098],
              [-1.936933635398389, 50.641198058764779],
              [-1.932404346564754, 50.641916752033431],
              [-1.93087450791839, 50.641679347630962],
              [-1.929750989825606, 50.642105836050774],
              [-1.927610376452709, 50.641763704004632],
              [-1.925853185728124, 50.642232926354531],
              [-1.92376608585569, 50.642045433667143],
              [-1.924464022732801, 50.641591750444384],
              [-1.924235985735287, 50.64092703537635],
              [-1.925123967945164, 50.641008542438648],
              [-1.925629645851942, 50.640492678615665],
              [-1.925128164557044, 50.640144337419812],
              [-1.925783031615462, 50.64007551029367],
              [-1.925529139340745, 50.639658083111001],
              [-1.926015612710448, 50.639654794746555],
              [-1.926016562258246, 50.639047781484116],
              [-1.927131823842326, 50.638447762682659],
              [-1.927474142096437, 50.637458767440997],
              [-1.928969935292354, 50.636723174928782],
              [-1.930661131828462, 50.634809621804514],
              [-1.932139421241963, 50.632382430876739],
              [-1.931896605378588, 50.632117901194896],
              [-1.932705738411743, 50.631859376524226],
              [-1.9330493107242, 50.630866767408271],
              [-1.943813828177815, 50.629179083940024],
              [-1.946880472727307, 50.627890046440648],
              [-1.95042316350015, 50.625462639657393],
              [-1.953158651863545, 50.622672393581482],
              [-1.956323937401346, 50.617912813391968],
              [-1.957126878921519, 50.616266522308941],
              [-1.957606937406106, 50.613677659706369],
              [-1.956553800916125, 50.609235710312944],
              [-1.955473527728024, 50.608324329785226],
              [-1.954148134140118, 50.608117882580871],
              [-1.954020735645322, 50.608334560239499],
              [-1.952768083214252, 50.607463554523441],
              [-1.951876265323203, 50.607559415064301],
              [-1.950780333964244, 50.608243315318312],
              [-1.950926139195216, 50.608006864649226],
              [-1.950073049785558, 50.607546070420163],
              [-1.94704038396717, 50.607512364040367],
              [-1.946927103520244, 50.607713752514641],
              [-1.94627312725314, 50.607420287464571],
              [-1.944096763878837, 50.607469628430046],
              [-1.94492008540491, 50.606740698765101],
              [-1.94689769817148, 50.606209235780753],
              [-1.949904888146955, 50.604862532572049],
              [-1.952597869486506, 50.601022805419916],
              [-1.953185875113304, 50.599387240982445],
              [-1.952030471604267, 50.596210494859761],
              [-1.950568015439491, 50.595004842908757],
              [-1.951171932314216, 50.594377394132394],
              [-1.953585210526746, 50.594147258431697],
              [-1.95626779613797, 50.592936950871263],
              [-1.95728013870503, 50.592033540773826],
              [-1.958307184319098, 50.592039306250854],
              [-1.959537102841732, 50.591043326995276],
              [-1.960328066826681, 50.591217159662648],
              [-1.960993784874978, 50.590807307984157],
              [-1.96114194134169, 50.591022286804694],
              [-1.966166516741675, 50.591553523109638],
              [-1.977204409172767, 50.590738728678637],
              [-1.97895319391147, 50.591011537780844],
              [-1.980603356461725, 50.590675487042567],
              [-1.984344194439036, 50.590517766742295],
              [-1.987002707363125, 50.591070249957518],
              [-1.988147051211612, 50.590823964710225],
              [-1.989866347208366, 50.590563329735339],
              [-1.99117729828356, 50.590653362124407],
              [-1.991640601436364, 50.59098523220819],
              [-1.99486720939269, 50.590819043556962],
              [-2.003521412970519, 50.591775025842388],
              [-2.004781518032143, 50.591417961088254],
              [-2.006290316709372, 50.591624721970426],
              [-2.007085603050985, 50.591171433793981],
              [-2.009020930369922, 50.590816976436344],
              [-2.012679777575461, 50.590763569340169],
              [-2.021170689996675, 50.589122016185271],
              [-2.022654070325935, 50.589351052069716],
              [-2.024430933222616, 50.588885757289553],
              [-2.026689732745552, 50.588892450808245],
              [-2.028392772464119, 50.587916317243334],
              [-2.031928981814462, 50.584460329324678],
              [-2.033483925625763, 50.584157728341602],
              [-2.03778005340772, 50.581581741515478],
              [-2.038600582244332, 50.581481649583807],
              [-2.039643257591448, 50.580186321161371],
              [-2.042718492354275, 50.579189714051665],
              [-2.052487322762109, 50.57763262710553],
              [-2.054377197925207, 50.576607465244265],
              [-2.055268214866263, 50.57650452484468],
              [-2.057664200514008, 50.577150846290479],
              [-2.061882528411028, 50.581329467008203],
              [-2.061575228288871, 50.582869212727566],
              [-2.062088800768499, 50.584584781213337],
              [-2.063619299576264, 50.586190080529562],
              [-2.064401019582212, 50.588676180048829],
              [-2.063980175943041, 50.590839199271365],
              [-2.062717237393332, 50.591901947138105],
              [-2.063378736749566, 50.593206453100038],
              [-2.064448848446492, 50.593682485606884],
              [-2.065724671546699, 50.593745619250328],
              [-2.067724799877503, 50.592513345231055],
              [-2.070286587922008, 50.592842759532758],
              [-2.072912058727407, 50.592307862658295],
              [-2.074793832740257, 50.593205053145169],
              [-2.075692566237405, 50.595096570098711],
              [-2.077036731914253, 50.595449105574204],
              [-2.078918458266317, 50.595354315926869],
              [-2.080821963309633, 50.594782858765122],
              [-2.083037152959382, 50.594730935750469],
              [-2.090990100153132, 50.597060424735858],
              [-2.098100506838461, 50.598131083801483],
              [-2.099889074397614, 50.59804952340496],
              [-2.103049296986427, 50.597200535155999],
              [-2.105074669644196, 50.596888467521467],
              [-2.112631733649085, 50.599092734422598],
              [-2.114080462680405, 50.599286466595316],
              [-2.114544750671122, 50.599052195541113],
              [-2.115974212389886, 50.599451859700025],
              [-2.116226786309464, 50.599885063354435],
              [-2.120872285604826, 50.602515233441025],
              [-2.127251270392573, 50.604279119493079],
              [-2.131595958793702, 50.607615128001839],
              [-2.131484686876043, 50.608253746232499],
              [-2.129484898998633, 50.609247002409504],
              [-2.12936679283021, 50.609981849496215],
              [-2.13136077783175, 50.611455339806554],
              [-2.134421106165209, 50.612627206904087],
              [-2.137375178903013, 50.612207385887551],
              [-2.139329077812755, 50.610526097046922],
              [-2.143071035304304, 50.610857886007125],
              [-2.146040125287485, 50.608505263442751],
              [-2.14689855706468, 50.608700226423558],
              [-2.147305967685336, 50.61063587136649],
              [-2.149262530288428, 50.611264674567124],
              [-2.151907798402303, 50.611130853333236],
              [-2.151041133656424, 50.611473710218817],
              [-2.151519372366627, 50.612081004522487],
              [-2.154369368624665, 50.614028703894519],
              [-2.157777464705687, 50.615421618369894],
              [-2.162054343291394, 50.614967895051819],
              [-2.1627713461186, 50.614675525879356],
              [-2.165732737850695, 50.614763970612628],
              [-2.170127979976156, 50.613856542809074],
              [-2.170995924460522, 50.614281531029739],
              [-2.173282866076331, 50.614301528393156],
              [-2.173678573398259, 50.614673242010845],
              [-2.179251496042844, 50.614639608814457],
              [-2.182804968466702, 50.615413759098736],
              [-2.183486183562392, 50.616124919848104],
              [-2.184463208745214, 50.615849093221414],
              [-2.184518576853314, 50.615189830058668],
              [-2.187505223057246, 50.61485771557691],
              [-2.186559505785231, 50.615177582515045],
              [-2.186677843418512, 50.615792503157891],
              [-2.18614848428697, 50.616322130409799],
              [-2.184539204962248, 50.616847178096137],
              [-2.18483012028896, 50.618221721472544],
              [-2.187371301006295, 50.619920000970424],
              [-2.193147570104184, 50.621754986270936],
              [-2.198497809183426, 50.623025662548137],
              [-2.204712879421249, 50.621548163594788],
              [-2.204780157370794, 50.622066032514198],
              [-2.207210053180062, 50.622358494656055],
              [-2.20887204513161, 50.621940054449823],
              [-2.209135511697653, 50.621423392491899],
              [-2.209918854515795, 50.621152196976148],
              [-2.213244418236858, 50.620700105849842],
              [-2.216668033926415, 50.620681192029849],
              [-2.217416594234777, 50.620236449005802],
              [-2.218193412901719, 50.620402261928334],
              [-2.220408794925161, 50.620166071971546],
              [-2.223022247118481, 50.618623317193411],
              [-2.223379297542498, 50.617906802132381],
              [-2.222789003742965, 50.617126455563508],
              [-2.223092017153221, 50.616939723200808],
              [-2.224570743082019, 50.616692273714271],
              [-2.227340481941778, 50.617142833287957],
              [-2.228266394726868, 50.616868537666264],
              [-2.228232947792168, 50.616091621375816],
              [-2.23636394039223, 50.615978268919491],
              [-2.24595301499656, 50.616571711905095],
              [-2.244874670491131, 50.616868051808794],
              [-2.243721271041053, 50.617959506505194],
              [-2.244361454833146, 50.618751333108726],
              [-2.246046708670056, 50.619355698956916],
              [-2.247926849571948, 50.619379587024746],
              [-2.249425185417291, 50.618841311511758],
              [-2.249780367510549, 50.617847739646798],
              [-2.247993045538364, 50.616917204807372],
              [-2.249705050785664, 50.616971997936339],
              [-2.250365691011863, 50.617341084042337],
              [-2.252960425892366, 50.617788713182932],
              [-2.251674383040696, 50.617833767475311],
              [-2.253168645106376, 50.618132686499116],
              [-2.253463925402152, 50.617843373470876],
              [-2.25643229403585, 50.617846763688675],
              [-2.256987885403496, 50.618121618731969],
              [-2.25745594579956, 50.617897561746233],
              [-2.259149530291385, 50.618449561323217],
              [-2.260118937152355, 50.618145239286754],
              [-2.261788686843107, 50.618725131595852],
              [-2.262818522599194, 50.61962659189475],
              [-2.26527437285253, 50.620720845849355],
              [-2.268080297049758, 50.621458125618204],
              [-2.270014117840269, 50.621705455502223],
              [-2.271565444091451, 50.621562457572921],
              [-2.273352993424639, 50.622177880502662],
              [-2.274584318749111, 50.62170195890819],
              [-2.274610551513658, 50.621352075382021],
              [-2.273943805852566, 50.621190875905782],
              [-2.277159369768526, 50.62112660916884],
              [-2.276361662877618, 50.621293074824997],
              [-2.276472202954431, 50.621581482651713],
              [-2.278135509225982, 50.622212415111051],
              [-2.284789484522404, 50.622681940121346],
              [-2.290600867288024, 50.622892399135836],
              [-2.29102386718229, 50.622257346846276],
              [-2.291507032911006, 50.622209375156146],
              [-2.291274675507357, 50.622582260572607],
              [-2.291752719851761, 50.622848150976424],
              [-2.298919739849795, 50.624377637188132],
              [-2.303383684091232, 50.625176344922686],
              [-2.310296518334271, 50.625780429973211],
              [-2.313534852693042, 50.625698911830959],
              [-2.314760964541808, 50.625335889578636],
              [-2.319745413130966, 50.625700905718006],
              [-2.320734844138109, 50.62525843401243],
              [-2.323306181143641, 50.62481337026675],
              [-2.324208334948249, 50.625254208871745],
              [-2.323544800763933, 50.627857684580555],
              [-2.32360791950816, 50.631488817743204],
              [-2.323139313235459, 50.632637605091148],
              [-2.323645699943118, 50.634723435181655],
              [-2.323399431139654, 50.639013698571546],
              [-2.322252160177982, 50.642880195692314],
              [-2.323629560678901, 50.646571523675362],
              [-2.324517305173341, 50.646502506155514],
              [-2.325090064311615, 50.647723031758524],
              [-2.32759584710442, 50.648256477110905],
              [-2.327657105091326, 50.649132204522118],
              [-2.328385186664293, 50.649689506322993],
              [-2.323705236337723, 50.649349192698061],
              [-2.32393665408326, 50.651324268965297],
              [-2.325375329663823, 50.654001912110786],
              [-2.324998686974973, 50.65435907884649],
              [-2.325510593745491, 50.655765022316423],
              [-2.32226596250206, 50.656258758608338],
              [-2.306124875731857, 50.656929107813831],
              [-2.306075135562009, 50.661026354212481],
              [-2.305273707555821, 50.664850391509439],
              [-2.29906046115271, 50.680592168307435],
              [-2.298936637565956, 50.681819997337229],
              [-2.298162723256077, 50.682986542821943],
              [-2.297129541606886, 50.682778752895871],
              [-2.296619042137811, 50.686443700507446],
              [-2.301346834459199, 50.694584383321761],
              [-2.31809851950491, 50.696028137428357],
              [-2.32244664113623, 50.696653767094311],
              [-2.32152604463947, 50.697854144062632],
              [-2.318104609478769, 50.700483120397791],
              [-2.316668881702012, 50.702595826801151],
              [-2.313197614238224, 50.704214909260571],
              [-2.308996892858427, 50.705418562773474],
              [-2.308763118488698, 50.706047773728024],
              [-2.310709553528174, 50.710212520540374],
              [-2.310840827034786, 50.712071862996233],
              [-2.31049051959564, 50.715642002569432],
              [-2.308061736699649, 50.718943376295194],
              [-2.308865836379239, 50.719514082077474],
              [-2.309375136651588, 50.719198885519361],
              [-2.310535535765964, 50.719450291534933],
              [-2.310542311893822, 50.718761432746341],
              [-2.311437012715432, 50.71931928951637],
              [-2.309725601337508, 50.722507266396342],
              [-2.306563126275578, 50.725681957421145],
              [-2.3061291117219, 50.725606660822095],
              [-2.305401894364021, 50.727935876581306],
              [-2.303752382381646, 50.730290876018508],
              [-2.30121210747143, 50.729658991007419],
              [-2.299952312710577, 50.729627169835595],
              [-2.293645456914421, 50.737940776587308],
              [-2.293219111585886, 50.742281709721638],
              [-2.29187174775875, 50.742351638444305],
              [-2.289749284383319, 50.745885654544594],
              [-2.284999662284819, 50.746806526427712],
              [-2.284497309926286, 50.749044226178512],
              [-2.280900162745942, 50.749810134832245],
              [-2.275658402015004, 50.750059160802188],
              [-2.272259936125605, 50.750658867771314],
              [-2.269077089674022, 50.752042136182794],
              [-2.268917514172441, 50.752395016131054],
              [-2.266595107743055, 50.752563120089924],
              [-2.261479549479356, 50.755175383832892],
              [-2.26343400606205, 50.756859797873233],
              [-2.264832488805751, 50.757476219930851],
              [-2.265090692416024, 50.758009795088142],
              [-2.266463364660294, 50.758565106341742],
              [-2.266109844156908, 50.758725983108121],
              [-2.264892859770147, 50.759128028999186],
              [-2.267837624001651, 50.761377547481409],
              [-2.267054082288799, 50.76246295734407],
              [-2.265451105392373, 50.762821828904272],
              [-2.264606768081728, 50.764203217746179],
              [-2.266287717553434, 50.765080665219543],
              [-2.267880456393988, 50.765398950247032],
              [-2.26742610877915, 50.766286666189373],
              [-2.25946997951038, 50.770144501141914],
              [-2.258528415126804, 50.772208599497247],
              [-2.258931378420467, 50.774299385128813],
              [-2.258420987152391, 50.776900279746251],
              [-2.257755625105484, 50.777640045915184],
              [-2.252071981201146, 50.777552661622188],
              [-2.249743026122239, 50.777801375893709],
              [-2.241718670217639, 50.77624459349002],
              [-2.232425392019134, 50.781231910893034],
              [-2.231600395270913, 50.780477276701923],
              [-2.22748621984699, 50.778263312094097],
              [-2.224171716737636, 50.775624114513938],
              [-2.223168834901541, 50.775288817375298],
              [-2.220368768507118, 50.775190732380736],
              [-2.219818697573753, 50.773421128557722],
              [-2.217100898688087, 50.774524221330978],
              [-2.215096094949732, 50.772483022575749],
              [-2.212209240993756, 50.772595328872072],
              [-2.211761744768159, 50.77054133326255],
              [-2.209370987249628, 50.768671595441589],
              [-2.208622905643709, 50.76849758170912],
              [-2.201614052802982, 50.768940668977955],
              [-2.201263503844516, 50.769532988643789],
              [-2.200307244147013, 50.768424949312383],
              [-2.198467709302992, 50.769681667301541],
              [-2.196874080076122, 50.766036970003661],
              [-2.192783850508912, 50.766178700652119],
              [-2.189082800102085, 50.766840328759628],
              [-2.168180438858523, 50.77280031111713],
              [-2.163909523292558, 50.773374732644307],
              [-2.161274303047358, 50.774128394317842],
              [-2.159534714408183, 50.767629141207195],
              [-2.159086595742188, 50.767620761679169],
              [-2.156736234527125, 50.767828081148267],
              [-2.152672327735257, 50.766994470566388],
              [-2.150470704868936, 50.767153807881513],
              [-2.146671912005833, 50.768514739821278],
              [-2.143781860279503, 50.77032945443063],
              [-2.140976935977909, 50.770818484956074],
              [-2.138741778080592, 50.772177253662306],
              [-2.134760839933407, 50.773582976088832],
              [-2.134821838538287, 50.774081095096776],
              [-2.133130450052379, 50.774764680085298],
              [-2.13270160652888, 50.775574502863094],
              [-2.132071614645824, 50.782004917429553],
              [-2.13046394040739, 50.782814263795863],
              [-2.129808244240278, 50.783705261966531],
              [-2.12670375033696, 50.784969441435322],
              [-2.12428352065265, 50.786489996964335],
              [-2.123587695252859, 50.786216464335894],
              [-2.120105279341289, 50.786835201562582],
              [-2.12060041725729, 50.788505509155257],
              [-2.119381351203281, 50.78943839588834],
              [-2.119028574054131, 50.790738183010788],
              [-2.116532015000947, 50.79088369102589],
              [-2.116568060782942, 50.79054643373528],
              [-2.114401582160064, 50.791075547642301],
              [-2.111277539001544, 50.791062389882157],
              [-2.110652315465082, 50.791843540540405],
              [-2.109223503634649, 50.79178284158602],
              [-2.107677044149149, 50.792999177872446],
              [-2.107282537129604, 50.792960874215957],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000052",
        LAD13CDO: "19UH",
        LAD13NM: "West Dorset",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.535853206825665, 50.99686197484953],
            [-2.535559795620946, 50.99402804067936],
            [-2.529361408178953, 50.992647206240576],
            [-2.52646558081156, 50.992602747047648],
            [-2.52686189146819, 50.992117172394771],
            [-2.526529216104946, 50.991930733266656],
            [-2.523515055107427, 50.992255419921783],
            [-2.520100841184968, 50.994015187700455],
            [-2.518051607728076, 50.994507184624936],
            [-2.517297061685284, 50.99443859108959],
            [-2.518461069294371, 50.993785981591536],
            [-2.518139778494544, 50.993340489205451],
            [-2.51628468314778, 50.994505118504193],
            [-2.512810745169013, 50.993989879206538],
            [-2.510859216160163, 50.994432762219731],
            [-2.508533430008893, 50.994664125059167],
            [-2.502231282507337, 50.993767889474057],
            [-2.497175621941887, 50.987358216133799],
            [-2.492141289064082, 50.978522995857062],
            [-2.488333301749764, 50.975762150418205],
            [-2.488218489388746, 50.974457841624584],
            [-2.490484370356377, 50.9741695817847],
            [-2.490345795922981, 50.972778148705068],
            [-2.48778001255867, 50.973118915758988],
            [-2.485087560909361, 50.971149119733205],
            [-2.480195085611617, 50.965871052262763],
            [-2.475921017561949, 50.96359190678254],
            [-2.475460811241052, 50.962868997981367],
            [-2.474578612781286, 50.962513792580808],
            [-2.476416161311608, 50.960769883203213],
            [-2.47545295903402, 50.960008560099652],
            [-2.474742897513694, 50.957957593953104],
            [-2.47302659556888, 50.958036500143066],
            [-2.470242990382341, 50.959157404111316],
            [-2.466818154093672, 50.959816801523125],
            [-2.463809213458681, 50.955352396784583],
            [-2.461446842084468, 50.956315849216637],
            [-2.458644927453577, 50.954294617040375],
            [-2.459030316363494, 50.953528750919347],
            [-2.457906938822089, 50.95279399040367],
            [-2.460421772752587, 50.951134897236919],
            [-2.458664268374006, 50.949753393923601],
            [-2.457994456899572, 50.949967344735448],
            [-2.457408500817373, 50.949588365707591],
            [-2.455365484007112, 50.949273527475732],
            [-2.45248691196548, 50.950280177392393],
            [-2.448606067653238, 50.952919130097513],
            [-2.44573564578747, 50.955857140714471],
            [-2.444756069999005, 50.958088284550229],
            [-2.44347969706692, 50.958469021164426],
            [-2.442377051075643, 50.960802224913124],
            [-2.443667126859036, 50.961254140417928],
            [-2.444337424036921, 50.961067247695524],
            [-2.442272425750631, 50.962529152850244],
            [-2.44287754920651, 50.962972879227415],
            [-2.444758069180084, 50.963067343247673],
            [-2.444850921100412, 50.963992302193823],
            [-2.447179230250728, 50.967856409990603],
            [-2.441454735418615, 50.968648841209891],
            [-2.441362665319316, 50.968401000732399],
            [-2.436663325890979, 50.969338589364817],
            [-2.433896017034734, 50.970234652783311],
            [-2.431768615632381, 50.971343202207159],
            [-2.431140127029479, 50.970683689171565],
            [-2.427993498714874, 50.97139307280959],
            [-2.424951161847134, 50.970276548369768],
            [-2.421345519800261, 50.967171075887727],
            [-2.419598698762351, 50.965175673093341],
            [-2.41944399989347, 50.963652926360524],
            [-2.418348504810868, 50.962334083798062],
            [-2.41621515655275, 50.96166368713277],
            [-2.41492976310333, 50.960590081011837],
            [-2.418100846286181, 50.959809020951312],
            [-2.42036290809792, 50.959602165872411],
            [-2.424030900330363, 50.958525104063362],
            [-2.423258517382628, 50.956414704234035],
            [-2.423712834503853, 50.956117207482741],
            [-2.424212745966562, 50.954417634760418],
            [-2.425656254637306, 50.953626447532052],
            [-2.427177167143391, 50.951811628223311],
            [-2.427262327293926, 50.951162967117774],
            [-2.42541921469228, 50.949808255237109],
            [-2.424771579028472, 50.948724336042346],
            [-2.426591067614651, 50.948594504919541],
            [-2.427632054122333, 50.947707645070359],
            [-2.429409939187865, 50.945231812539994],
            [-2.430262739782818, 50.943247651640299],
            [-2.431809326597665, 50.942569310221785],
            [-2.431570087206649, 50.942095397482795],
            [-2.43083197206511, 50.941850833988099],
            [-2.430226630748827, 50.939491667682098],
            [-2.435738308772021, 50.939437049527022],
            [-2.440246383405609, 50.938757393339642],
            [-2.444422600342434, 50.93672187542473],
            [-2.446056148620558, 50.934765193829293],
            [-2.445719755658324, 50.93426200567604],
            [-2.447132429836418, 50.934216136357492],
            [-2.446749648299749, 50.933625003345441],
            [-2.446691053629968, 50.929738712845413],
            [-2.443535493493133, 50.928297575745198],
            [-2.442327600250358, 50.924564016941389],
            [-2.437531920443277, 50.925831140414097],
            [-2.433032072670981, 50.924815596033582],
            [-2.429426942261269, 50.924684139877044],
            [-2.427130835635022, 50.923626067059686],
            [-2.424858673885755, 50.923310634673179],
            [-2.420572346935969, 50.921303768702977],
            [-2.41879009410377, 50.921344349484642],
            [-2.416806415891718, 50.91986051016471],
            [-2.412776005945287, 50.919047534678306],
            [-2.411583045230033, 50.918310773808159],
            [-2.41128186773256, 50.918518661147559],
            [-2.41105955550744, 50.920572411447438],
            [-2.409698421297668, 50.921745311735563],
            [-2.408997662886872, 50.921817011415762],
            [-2.409508046577843, 50.920479849164671],
            [-2.407640161227167, 50.918208612047977],
            [-2.404172170715291, 50.91265976669817],
            [-2.401491226279614, 50.909886767467931],
            [-2.399540861050334, 50.906193079615029],
            [-2.399331075795073, 50.901291124563684],
            [-2.400468167899934, 50.90023151528866],
            [-2.400282330496847, 50.897963360871366],
            [-2.402809293230056, 50.893365819805915],
            [-2.406484629989892, 50.893355770266872],
            [-2.409156396107377, 50.892562289472245],
            [-2.409480585639819, 50.892888477277097],
            [-2.411307039571851, 50.891048491723602],
            [-2.413028410121075, 50.890684510790727],
            [-2.413649041940746, 50.890929604467274],
            [-2.413624536644034, 50.890570892433928],
            [-2.414198433090988, 50.890355735213539],
            [-2.414623519031828, 50.890514290983184],
            [-2.414711454956278, 50.89001040095809],
            [-2.416121054077882, 50.889607016045602],
            [-2.415982874748058, 50.887821606714709],
            [-2.416631102607497, 50.88717903047791],
            [-2.416946383365379, 50.88730200008883],
            [-2.417432461891901, 50.886970238609472],
            [-2.417397039614657, 50.886665520919941],
            [-2.417865994678625, 50.886804124581758],
            [-2.417950268243199, 50.886372184557771],
            [-2.418685153043049, 50.886520622901585],
            [-2.421193451965491, 50.885637526871442],
            [-2.423267664526059, 50.88580627227023],
            [-2.423616783371334, 50.886054096379148],
            [-2.425101337694689, 50.885941686173823],
            [-2.425238885260886, 50.885591378040495],
            [-2.426284903750534, 50.885561482545356],
            [-2.426487848586792, 50.885057162769158],
            [-2.427200081381041, 50.884904382746349],
            [-2.433414185798952, 50.886327451507327],
            [-2.439578939739035, 50.886998605450735],
            [-2.445982951038243, 50.887248571454265],
            [-2.448502805995314, 50.88672993820461],
            [-2.452231117989, 50.8896012291057],
            [-2.451958023477228, 50.889881952966228],
            [-2.453424184138768, 50.890504837570155],
            [-2.457064333185967, 50.893022911919168],
            [-2.457868237298684, 50.894096157493387],
            [-2.45894790288915, 50.894144971192055],
            [-2.458657411218235, 50.893666815342044],
            [-2.4592001575017, 50.893915570205451],
            [-2.459380461883717, 50.893599225095087],
            [-2.458925318329071, 50.893149594643702],
            [-2.459277583430342, 50.893258815828865],
            [-2.459473396736064, 50.892785041403471],
            [-2.459970568510013, 50.892738120178436],
            [-2.459295739205106, 50.891925162208338],
            [-2.460436218074897, 50.890216595056799],
            [-2.460486865055409, 50.888577068354728],
            [-2.460104546117429, 50.888155930619583],
            [-2.460538582497947, 50.88819918052517],
            [-2.460607251917511, 50.887810434760667],
            [-2.460275373648484, 50.887602219940689],
            [-2.460664629136386, 50.887285947207168],
            [-2.461136649682965, 50.887433357565314],
            [-2.460924576758043, 50.887120358334471],
            [-2.461429727958341, 50.887167820191642],
            [-2.462015135874891, 50.886562108632681],
            [-2.462578040705063, 50.886556281216649],
            [-2.462551201158653, 50.886286613448029],
            [-2.462999999812733, 50.886386448247706],
            [-2.462601695918921, 50.886074190886127],
            [-2.463545685320516, 50.885505716813547],
            [-2.463924759229957, 50.884168827509626],
            [-2.464975063853197, 50.884146661431295],
            [-2.464914306676056, 50.882470705655152],
            [-2.471057985229858, 50.883030573283108],
            [-2.471526318176549, 50.882250831858457],
            [-2.475169679999762, 50.881420431976849],
            [-2.480239126060097, 50.878151588661829],
            [-2.480194127562977, 50.876401835072201],
            [-2.479778068212204, 50.876301032406666],
            [-2.479556570237321, 50.875492618695077],
            [-2.477987612012879, 50.875089898957341],
            [-2.474904546982084, 50.87477876014831],
            [-2.469565478221742, 50.873568400272134],
            [-2.468789040911063, 50.873523862636688],
            [-2.468779219764685, 50.873819755148936],
            [-2.468234725088295, 50.873661875415713],
            [-2.466909935782992, 50.872788618406972],
            [-2.4666046801999, 50.871111839983186],
            [-2.465829844955573, 50.870368559228965],
            [-2.464540437502492, 50.870038280656516],
            [-2.464015542585808, 50.869129429498578],
            [-2.463542275021941, 50.869125016253292],
            [-2.463917879463554, 50.868596562671186],
            [-2.463152255561552, 50.867770496289964],
            [-2.462343353316707, 50.866876253320171],
            [-2.460391439308188, 50.866501791720509],
            [-2.46052283751176, 50.866280956746202],
            [-2.45927386107382, 50.8654226003761],
            [-2.459415529677234, 50.864945439790965],
            [-2.458847468903295, 50.864110473350522],
            [-2.457269759635242, 50.863046560270774],
            [-2.456465272331796, 50.861711625859698],
            [-2.45264946715522, 50.861484596693913],
            [-2.451034988002513, 50.861886520740313],
            [-2.44439283438302, 50.862080174034212],
            [-2.443356291937955, 50.863201886613744],
            [-2.443409078212723, 50.865020869163011],
            [-2.442936416645483, 50.866424595549489],
            [-2.441444832310551, 50.867377159640569],
            [-2.440378006555172, 50.867266087958669],
            [-2.437655368980289, 50.868789771362195],
            [-2.435744938999902, 50.869193492126847],
            [-2.435722861232485, 50.867588414468621],
            [-2.435320705347788, 50.866978426240777],
            [-2.434694255508453, 50.867002344337159],
            [-2.432683382124631, 50.867449553399908],
            [-2.428521097883463, 50.869302084022273],
            [-2.427926605336544, 50.869250311424786],
            [-2.423749934403992, 50.866892439905008],
            [-2.42340584298689, 50.863891095159254],
            [-2.425590465008457, 50.86287058957415],
            [-2.424491930514216, 50.86238180346681],
            [-2.424117492303151, 50.861830126714779],
            [-2.423583441283408, 50.860284457665422],
            [-2.423660783822442, 50.857845409363726],
            [-2.422609839691645, 50.85741937997642],
            [-2.420137956825856, 50.857599167448079],
            [-2.418829593349065, 50.858248635472556],
            [-2.416150160893124, 50.85810265554116],
            [-2.41406698674047, 50.859025506162077],
            [-2.413824734926769, 50.859425633003724],
            [-2.412090177896292, 50.859774388346295],
            [-2.410896018322761, 50.860666160953308],
            [-2.40939827033011, 50.861130944173119],
            [-2.409196049810487, 50.861565092184414],
            [-2.408058501695936, 50.861629327028581],
            [-2.407961431722136, 50.862065802693863],
            [-2.406876678864286, 50.861994054632596],
            [-2.40636448119487, 50.861262052396803],
            [-2.402264581325082, 50.861175547918485],
            [-2.395641265735312, 50.862430173839272],
            [-2.394326989203165, 50.859405052612146],
            [-2.394577980155006, 50.85850585316939],
            [-2.397098232199534, 50.854304095795563],
            [-2.397524560032096, 50.852148937788421],
            [-2.397875595704472, 50.852333885761162],
            [-2.398371636867829, 50.852030943445897],
            [-2.399159349642716, 50.848289163203248],
            [-2.400296952808584, 50.846940882884532],
            [-2.40014013519987, 50.846210329210329],
            [-2.400848371561263, 50.84399393835568],
            [-2.403831694314673, 50.842423443310629],
            [-2.403442254924565, 50.842226056316861],
            [-2.401697758673993, 50.842509947085759],
            [-2.399333468980961, 50.837078485551949],
            [-2.398761646564013, 50.837135296657522],
            [-2.397229582178833, 50.838835617467375],
            [-2.395784445639895, 50.839241597712963],
            [-2.390341660771485, 50.84188575998234],
            [-2.388698179755968, 50.842198793546054],
            [-2.388494540100054, 50.842475542552982],
            [-2.388357518267611, 50.842223309023431],
            [-2.387705934435148, 50.842775819400003],
            [-2.386268230522369, 50.843074645582206],
            [-2.385674867068632, 50.843457892261718],
            [-2.385909298292494, 50.84415583558274],
            [-2.385000475905256, 50.844351279273575],
            [-2.384253340443505, 50.845383386695239],
            [-2.382686498645522, 50.846402894461932],
            [-2.380808907676509, 50.846773237250474],
            [-2.379511478361958, 50.847399748436906],
            [-2.377229351470884, 50.847462006772773],
            [-2.377094722895057, 50.846268236337657],
            [-2.376095811255177, 50.846398256436537],
            [-2.375139690656194, 50.845312342341174],
            [-2.374393335320444, 50.84505215930394],
            [-2.37095654137734, 50.846006456684883],
            [-2.369873072379054, 50.844786016784532],
            [-2.368803229877972, 50.84456549135399],
            [-2.367691369751746, 50.844599576791829],
            [-2.36727309891994, 50.845054117558604],
            [-2.367339507956304, 50.84591359272126],
            [-2.368489647847242, 50.845506200408735],
            [-2.368733449470327, 50.846162782552071],
            [-2.367761413008852, 50.846461705552493],
            [-2.36790251933804, 50.847245407527147],
            [-2.365936019887438, 50.847346015943593],
            [-2.365716847032697, 50.846565254230796],
            [-2.363192757071478, 50.846926548012256],
            [-2.362769592545273, 50.846392810696379],
            [-2.361452369294501, 50.846693653598223],
            [-2.36069560726896, 50.84599817778151],
            [-2.357347950870834, 50.843422237071856],
            [-2.356011084760933, 50.841323875639752],
            [-2.352970294888484, 50.83868392299015],
            [-2.351934170557584, 50.838196962195823],
            [-2.352026534857268, 50.835374829291993],
            [-2.354433689564476, 50.829586239134755],
            [-2.354445308092129, 50.828496308371193],
            [-2.353697898190669, 50.827670365171329],
            [-2.349945926815202, 50.826806711969084],
            [-2.347860575508463, 50.825706869582568],
            [-2.339735560834814, 50.823015079371523],
            [-2.337683542981615, 50.822739573931287],
            [-2.335521899146271, 50.823011094542714],
            [-2.333011290942589, 50.823923841220655],
            [-2.333024184900775, 50.824139625361745],
            [-2.330463448501091, 50.823197299152994],
            [-2.329779244392981, 50.822202862782667],
            [-2.328724198829289, 50.821765207618178],
            [-2.327303169229595, 50.820553413598127],
            [-2.325923188484232, 50.820536596629125],
            [-2.325163646623947, 50.820937986404893],
            [-2.323876863491422, 50.820235653405028],
            [-2.323607497209764, 50.818645620034907],
            [-2.322715273526105, 50.817714666716235],
            [-2.322703381653616, 50.817019575608995],
            [-2.321818032162255, 50.816461787026064],
            [-2.322020428099812, 50.81513842388815],
            [-2.329664573707411, 50.81398849361176],
            [-2.330356475681314, 50.813678090416026],
            [-2.330681980737754, 50.813944246507859],
            [-2.333111499364852, 50.812476039563528],
            [-2.332996177395339, 50.811828005526586],
            [-2.328328050902892, 50.810225282699804],
            [-2.322336078408938, 50.810888569117168],
            [-2.321938946156527, 50.809690958290105],
            [-2.320068439754796, 50.809706899889605],
            [-2.315269557854853, 50.812192929969051],
            [-2.311264957324187, 50.813171289904759],
            [-2.310711182211617, 50.813566640992612],
            [-2.309394722332312, 50.811532429539213],
            [-2.306081915431052, 50.808483705241962],
            [-2.303721328322299, 50.804051150537838],
            [-2.307057507205864, 50.803378758870629],
            [-2.313510895265736, 50.801376021739799],
            [-2.317086458223933, 50.799432047824133],
            [-2.315772512528164, 50.798593909474782],
            [-2.315349843620862, 50.798620232903779],
            [-2.314722562710974, 50.796704710594661],
            [-2.313642768185219, 50.79649000058626],
            [-2.312794772412418, 50.795289971231142],
            [-2.310422955437721, 50.794936608575348],
            [-2.307139176535335, 50.795278932279452],
            [-2.302620319498943, 50.792012056485653],
            [-2.300633274662818, 50.789235791582577],
            [-2.299419552703675, 50.788672382771139],
            [-2.300312587899276, 50.787455186242397],
            [-2.30378375828167, 50.785023585955031],
            [-2.305290558699916, 50.783548459993554],
            [-2.305919097374709, 50.781181758104019],
            [-2.307149072726291, 50.779915062442392],
            [-2.301456487687846, 50.779377779748373],
            [-2.298854289554447, 50.778567053794376],
            [-2.296251824734879, 50.778597078996235],
            [-2.296106764260745, 50.774047188088687],
            [-2.294530238068491, 50.77390370427117],
            [-2.294366283588664, 50.773540817011032],
            [-2.299862651503198, 50.771241780873126],
            [-2.304382879762732, 50.770255262666176],
            [-2.307139189580481, 50.768887447641923],
            [-2.305666505232726, 50.763336575017313],
            [-2.300014322925851, 50.763478059656308],
            [-2.298311021185165, 50.762743238315785],
            [-2.292658359382512, 50.762345709316286],
            [-2.290653812883054, 50.762392991789298],
            [-2.287903530497227, 50.762911499839142],
            [-2.287381235070027, 50.762595349348985],
            [-2.284938648566608, 50.762891359934038],
            [-2.283842604535448, 50.762651683608951],
            [-2.280063683569327, 50.764495322865521],
            [-2.279087710846857, 50.762991399850037],
            [-2.277779948676218, 50.762191483217414],
            [-2.276065344987008, 50.761637115921395],
            [-2.275173690580131, 50.762147308448078],
            [-2.271701194322293, 50.762113191903147],
            [-2.270408085084924, 50.760877915273589],
            [-2.267775261040808, 50.76014030526418],
            [-2.266648941471518, 50.758788598294906],
            [-2.266109844156908, 50.758725983108121],
            [-2.266463364660294, 50.758565106341742],
            [-2.265090692416024, 50.758009795088142],
            [-2.264832488805751, 50.757476219930851],
            [-2.26343400606205, 50.756859797873233],
            [-2.261479549479356, 50.755175383832892],
            [-2.266595107743055, 50.752563120089924],
            [-2.268917514172441, 50.752395016131054],
            [-2.269077089674022, 50.752042136182794],
            [-2.272259936125605, 50.750658867771314],
            [-2.275658402015004, 50.750059160802188],
            [-2.280900162745942, 50.749810134832245],
            [-2.284497309926286, 50.749044226178512],
            [-2.284999662284819, 50.746806526427712],
            [-2.289749284383319, 50.745885654544594],
            [-2.29187174775875, 50.742351638444305],
            [-2.293219111585886, 50.742281709721638],
            [-2.293645456914421, 50.737940776587308],
            [-2.299952312710577, 50.729627169835595],
            [-2.30121210747143, 50.729658991007419],
            [-2.303752382381646, 50.730290876018508],
            [-2.305401894364021, 50.727935876581306],
            [-2.3061291117219, 50.725606660822095],
            [-2.306563126275578, 50.725681957421145],
            [-2.309725601337508, 50.722507266396342],
            [-2.311437012715432, 50.71931928951637],
            [-2.310542311893822, 50.718761432746341],
            [-2.310535535765964, 50.719450291534933],
            [-2.309375136651588, 50.719198885519361],
            [-2.308865836379239, 50.719514082077474],
            [-2.308061736699649, 50.718943376295194],
            [-2.31049051959564, 50.715642002569432],
            [-2.310840827034786, 50.712071862996233],
            [-2.310709553528174, 50.710212520540374],
            [-2.308763118488698, 50.706047773728024],
            [-2.308996892858427, 50.705418562773474],
            [-2.313197614238224, 50.704214909260571],
            [-2.316668881702012, 50.702595826801151],
            [-2.318104609478769, 50.700483120397791],
            [-2.32152604463947, 50.697854144062632],
            [-2.32244664113623, 50.696653767094311],
            [-2.31809851950491, 50.696028137428357],
            [-2.301346834459199, 50.694584383321761],
            [-2.296619042137811, 50.686443700507446],
            [-2.297129541606886, 50.682778752895871],
            [-2.298162723256077, 50.682986542821943],
            [-2.298936637565956, 50.681819997337229],
            [-2.29906046115271, 50.680592168307435],
            [-2.305273707555821, 50.664850391509439],
            [-2.306075135562009, 50.661026354212481],
            [-2.306124875731857, 50.656929107813831],
            [-2.32226596250206, 50.656258758608338],
            [-2.325510593745491, 50.655765022316423],
            [-2.324998686974973, 50.65435907884649],
            [-2.325375329663823, 50.654001912110786],
            [-2.32393665408326, 50.651324268965297],
            [-2.323705236337723, 50.649349192698061],
            [-2.328385186664293, 50.649689506322993],
            [-2.327657105091326, 50.649132204522118],
            [-2.32759584710442, 50.648256477110905],
            [-2.325090064311615, 50.647723031758524],
            [-2.324517305173341, 50.646502506155514],
            [-2.323629560678901, 50.646571523675362],
            [-2.322252160177982, 50.642880195692314],
            [-2.323399431139654, 50.639013698571546],
            [-2.323645699943118, 50.634723435181655],
            [-2.323139313235459, 50.632637605091148],
            [-2.32360791950816, 50.631488817743204],
            [-2.323544800763933, 50.627857684580555],
            [-2.324208334948249, 50.625254208871745],
            [-2.327429861086296, 50.626424155482432],
            [-2.330130833356387, 50.62692642249781],
            [-2.332386708198342, 50.628857961976699],
            [-2.333971562981051, 50.629648393959933],
            [-2.334467293377902, 50.630967119923596],
            [-2.338744652031334, 50.631995225669414],
            [-2.3439198473994, 50.632025917950578],
            [-2.349274674514734, 50.63092004583882],
            [-2.351571555279739, 50.631205400608948],
            [-2.355365763197125, 50.630924097263396],
            [-2.35832268863857, 50.631543635017508],
            [-2.365649514389773, 50.630987581807801],
            [-2.366208301430539, 50.631389604484021],
            [-2.372579117206688, 50.632965623900127],
            [-2.373455627429482, 50.63348260240722],
            [-2.376006863648779, 50.633890760858378],
            [-2.376441977310855, 50.634372270677943],
            [-2.379457435443533, 50.635526157074167],
            [-2.384980044618844, 50.635624936999029],
            [-2.393618794623155, 50.636687787983114],
            [-2.398158608851749, 50.636263179993371],
            [-2.406173400363497, 50.634614090695763],
            [-2.407958249544759, 50.633840761593],
            [-2.408514956151632, 50.632979093488487],
            [-2.410203456017675, 50.633168302760886],
            [-2.411082694875288, 50.634112149435417],
            [-2.410018192647688, 50.635763392134997],
            [-2.408845275760922, 50.639432099817178],
            [-2.406591411547776, 50.643069500045215],
            [-2.406280648778901, 50.644915914014341],
            [-2.406600349990644, 50.646725055079337],
            [-2.405819027356575, 50.650266457453249],
            [-2.406072559385382, 50.653749392591592],
            [-2.40537702568693, 50.658892108128349],
            [-2.405533383987365, 50.659634369896018],
            [-2.409669422000381, 50.660527270033498],
            [-2.411705334477297, 50.661986818340374],
            [-2.414828384451062, 50.662687967711364],
            [-2.414867889955203, 50.661873078689005],
            [-2.41801273952403, 50.660564172368019],
            [-2.42431167904664, 50.659500028355197],
            [-2.425018472798241, 50.657085578289355],
            [-2.426753574398753, 50.657474917826171],
            [-2.42922006644314, 50.656928983374712],
            [-2.429617884607397, 50.655411327264723],
            [-2.428645829827411, 50.653214365063185],
            [-2.433373611998912, 50.652017909994179],
            [-2.433407368515579, 50.650921558658872],
            [-2.434321616416613, 50.65005394202484],
            [-2.432482514234755, 50.648328766387898],
            [-2.432679285535084, 50.646192237401017],
            [-2.442908504112483, 50.646706845443454],
            [-2.448185861614534, 50.647485220914604],
            [-2.458699112381267, 50.650684409760458],
            [-2.460673853999751, 50.651996764120391],
            [-2.467000830968941, 50.652402324928893],
            [-2.466597341295318, 50.656791544289909],
            [-2.465956006438535, 50.65888584293215],
            [-2.466240280680218, 50.659876613850393],
            [-2.465679004959111, 50.660058715571701],
            [-2.465327256947374, 50.661257065820145],
            [-2.465756216738917, 50.663285929948159],
            [-2.465215621766154, 50.66456237231651],
            [-2.465622698960518, 50.666951035691767],
            [-2.464139156785648, 50.669180878109138],
            [-2.463722919790086, 50.671599802080159],
            [-2.466757546979153, 50.671946495316156],
            [-2.468187493698289, 50.67243985767486],
            [-2.468118255616975, 50.673161358757412],
            [-2.471924049402956, 50.67560013891179],
            [-2.478241328287202, 50.677931389117013],
            [-2.478561541478813, 50.677548779499752],
            [-2.483711420796108, 50.678335963146104],
            [-2.494572992321136, 50.67864107828683],
            [-2.496192395865999, 50.674104516313285],
            [-2.497909840232913, 50.666693404146976],
            [-2.490088160469068, 50.665363262003702],
            [-2.490957086203268, 50.661038551083095],
            [-2.491083413997848, 50.6606711112032],
            [-2.491455788587224, 50.660698317782625],
            [-2.492382599145376, 50.657058602226428],
            [-2.490032444747171, 50.656887756930431],
            [-2.489822081690399, 50.656656626617618],
            [-2.491041534865192, 50.651503991698917],
            [-2.488648832631469, 50.651158836956597],
            [-2.489124055997102, 50.64883938642614],
            [-2.488398850913519, 50.648741709717996],
            [-2.488340549890601, 50.648166412017957],
            [-2.487683042189724, 50.648185354010437],
            [-2.488520322396751, 50.64449927343378],
            [-2.48731451971049, 50.644420691216197],
            [-2.487429586604371, 50.642556890466835],
            [-2.488048835094486, 50.641037202384368],
            [-2.488996801545972, 50.639990953869578],
            [-2.486119306848217, 50.639750300347679],
            [-2.486942154216746, 50.637406021265029],
            [-2.485985208594614, 50.636347961167587],
            [-2.48626322168209, 50.635746077373156],
            [-2.483665063432873, 50.631422334012868],
            [-2.482685662822866, 50.627739319160156],
            [-2.481623680139683, 50.627441555330783],
            [-2.479763103378896, 50.622995961127778],
            [-2.480626156323892, 50.622650672733776],
            [-2.482206784882389, 50.620180087559405],
            [-2.483696123967373, 50.619298003470313],
            [-2.483945587076163, 50.61840037581581],
            [-2.48327036054848, 50.615547936353714],
            [-2.482029136147747, 50.611655558889275],
            [-2.488457184686598, 50.608952483505327],
            [-2.486429592501087, 50.60707425920679],
            [-2.485102120880353, 50.604691280009625],
            [-2.482954771855872, 50.602402516925487],
            [-2.483353734004158, 50.602311831555433],
            [-2.482754579124359, 50.601343082255305],
            [-2.483378237039234, 50.600842287172291],
            [-2.48312097895557, 50.600557380086286],
            [-2.485080140688686, 50.600081602665199],
            [-2.484613063443403, 50.599591634897259],
            [-2.486668448579636, 50.598724237965051],
            [-2.485337563422347, 50.597635354475194],
            [-2.484731427501067, 50.596535346982421],
            [-2.485577898537906, 50.596002136515317],
            [-2.484109014265755, 50.595223170770346],
            [-2.484676351887426, 50.594475296719828],
            [-2.483671473018401, 50.5938877408108],
            [-2.487789258769713, 50.591107033782166],
            [-2.490279835814193, 50.591920325327898],
            [-2.492683182497093, 50.593436280824619],
            [-2.495759756565235, 50.594603094091234],
            [-2.498063574528297, 50.596297422055308],
            [-2.499383809643461, 50.59849143715229],
            [-2.49903315762191, 50.599937204289397],
            [-2.498259039717327, 50.60062398166037],
            [-2.49585529315695, 50.601675623316503],
            [-2.495460209792092, 50.602267240667629],
            [-2.495701077955729, 50.602866942462882],
            [-2.497037247037765, 50.603347763328237],
            [-2.499415338258843, 50.603470682970361],
            [-2.500675077124121, 50.604067798567527],
            [-2.501404070216803, 50.605514319913524],
            [-2.501051620303826, 50.606390847690541],
            [-2.501393034519744, 50.606868700392575],
            [-2.504343356595308, 50.605114031295024],
            [-2.506539970411025, 50.605960622038616],
            [-2.507503287952127, 50.606706436678543],
            [-2.507850473300919, 50.607582631490153],
            [-2.507332954380644, 50.608081296267287],
            [-2.505171396853267, 50.608670736752792],
            [-2.505012333333576, 50.609125568745966],
            [-2.505615411747873, 50.609881054041388],
            [-2.507148530289482, 50.610380687839907],
            [-2.508527627992711, 50.611273065025017],
            [-2.510339304484801, 50.611647337876342],
            [-2.512351851416076, 50.610734712858843],
            [-2.512912265398724, 50.610154001550569],
            [-2.513639282353199, 50.610208351606097],
            [-2.514703150022549, 50.611472553827376],
            [-2.514578550939447, 50.614300474759808],
            [-2.516411962097226, 50.615353521515978],
            [-2.516407739760989, 50.616774419003846],
            [-2.517493993506515, 50.617868529535926],
            [-2.521173273325608, 50.618116512717414],
            [-2.522437566877394, 50.617788901751211],
            [-2.523451015558506, 50.617912949269503],
            [-2.524062649003466, 50.617748326152665],
            [-2.524818878864679, 50.616860018761528],
            [-2.531688273896603, 50.617476347056098],
            [-2.537965445125447, 50.619690349209343],
            [-2.539610194821786, 50.620745700947069],
            [-2.540246068424018, 50.622080896679968],
            [-2.542252197385419, 50.622956474496362],
            [-2.544334468880139, 50.623441370036801],
            [-2.544650811083742, 50.625248362963816],
            [-2.545570270564705, 50.625905037271288],
            [-2.547689594643849, 50.624698135198031],
            [-2.548849731007434, 50.624895913097028],
            [-2.551868133080486, 50.627440128629452],
            [-2.549389506015168, 50.628268402174491],
            [-2.546767096497978, 50.627679118594124],
            [-2.546113013709857, 50.628210969659179],
            [-2.54614232296584, 50.629397894009479],
            [-2.545285924277626, 50.630898323696677],
            [-2.545921468802898, 50.630470882009654],
            [-2.549053800046075, 50.62991298679966],
            [-2.55231326113821, 50.6306781581414],
            [-2.553080676973052, 50.630409226031695],
            [-2.554649504909486, 50.630599608977668],
            [-2.55544557435689, 50.630363798507908],
            [-2.558057065532256, 50.630845024494938],
            [-2.558908171513838, 50.633342765562503],
            [-2.560100985205727, 50.635196764676195],
            [-2.559909150149857, 50.635950391596296],
            [-2.560412404142183, 50.637483056596352],
            [-2.560145223498999, 50.638798201911065],
            [-2.560512541074933, 50.637579697879417],
            [-2.560580504825786, 50.638062288593538],
            [-2.561372688777149, 50.638560275983544],
            [-2.560497372693463, 50.639157122350376],
            [-2.561527377738812, 50.638606293306637],
            [-2.561586249862151, 50.638206725499899],
            [-2.56301523261657, 50.637912054525479],
            [-2.566832320054615, 50.638062606989294],
            [-2.570259867603107, 50.637572850266004],
            [-2.57156351476041, 50.638375819987189],
            [-2.574687348187973, 50.638957576448746],
            [-2.576000163206871, 50.640038331412477],
            [-2.578246139118678, 50.641096456818289],
            [-2.582087452934819, 50.641923553816966],
            [-2.586110035980965, 50.64435034524692],
            [-2.586392911773502, 50.645146589973372],
            [-2.587499858574048, 50.645221948011184],
            [-2.588549039628723, 50.646106946551967],
            [-2.59214962948197, 50.646752372722787],
            [-2.598447010631358, 50.649133878059494],
            [-2.600724950687482, 50.650849684380077],
            [-2.598407735094348, 50.651274384169639],
            [-2.597811768779407, 50.651906945304667],
            [-2.597468431369427, 50.653275623250451],
            [-2.598221819094108, 50.654009172047644],
            [-2.599093391938464, 50.654245701802317],
            [-2.600287256659995, 50.653797106564987],
            [-2.603431047756075, 50.653620790618895],
            [-2.604848521762929, 50.654065780747551],
            [-2.606160701940476, 50.653692050965041],
            [-2.606403843200688, 50.654667411393802],
            [-2.607052494911961, 50.654945509828686],
            [-2.609661347193002, 50.65442109033873],
            [-2.60995308940422, 50.654119201167653],
            [-2.611486354530318, 50.654106666340795],
            [-2.577726346577187, 50.637920051185688],
            [-2.573862196614074, 50.635649564340966],
            [-2.56666443928586, 50.63268837453915],
            [-2.561044375021749, 50.629160691802149],
            [-2.557847522580918, 50.627954812643026],
            [-2.556770124667904, 50.626871827616327],
            [-2.553582417848829, 50.625579455815917],
            [-2.552083204444274, 50.624438179082688],
            [-2.543905656063606, 50.620705934138634],
            [-2.539941487323059, 50.618322376146686],
            [-2.538812074885396, 50.617950804373756],
            [-2.538040771045158, 50.617249326553342],
            [-2.536071310499371, 50.616462536851778],
            [-2.535140579625733, 50.615656556497896],
            [-2.528717648864912, 50.612610319872424],
            [-2.527875591033848, 50.611753518292154],
            [-2.525967009447871, 50.611193802364284],
            [-2.523883398339444, 50.609632132754413],
            [-2.522251901039601, 50.608940711758628],
            [-2.516201673839416, 50.605184369191313],
            [-2.502574449891484, 50.597586509812409],
            [-2.504000427344796, 50.596562346415155],
            [-2.517350310848292, 50.60426109421887],
            [-2.544995712442249, 50.619498486565234],
            [-2.56862576499925, 50.63176513748909],
            [-2.59233337436997, 50.643594037918881],
            [-2.627107201728989, 50.65958391614776],
            [-2.652550979590801, 50.670035976245885],
            [-2.678137575791811, 50.680182190421661],
            [-2.724471757329669, 50.696719960760731],
            [-2.731760583791373, 50.698982883263753],
            [-2.739904419642036, 50.702533096548791],
            [-2.760253918530834, 50.70887517284153],
            [-2.762412336955801, 50.709488773314746],
            [-2.763482840102364, 50.709476369632114],
            [-2.763493404965061, 50.709080615228402],
            [-2.763822282764571, 50.709273604991346],
            [-2.763038911575545, 50.711007161105321],
            [-2.761803866194541, 50.710919017172564],
            [-2.761669306556292, 50.711267919627439],
            [-2.763901809666643, 50.711551870349048],
            [-2.764151583211669, 50.710972892445106],
            [-2.763516301502881, 50.710576874545389],
            [-2.763833161330597, 50.710117062534636],
            [-2.763962051024928, 50.710378808765306],
            [-2.764806509454746, 50.710225787557633],
            [-2.763859219255952, 50.708583611619808],
            [-2.764451738997911, 50.708790159175201],
            [-2.765258478554234, 50.710497102458064],
            [-2.766998515772286, 50.710893939168869],
            [-2.767292854787577, 50.710531388646785],
            [-2.76755913536885, 50.710965788211524],
            [-2.767820869307386, 50.711295000826887],
            [-2.770358181864791, 50.71185379906958],
            [-2.779365370414637, 50.714880259189279],
            [-2.793108612007845, 50.718040260517739],
            [-2.79539335695218, 50.718200051488523],
            [-2.799257008362734, 50.717661028288511],
            [-2.805442859712586, 50.718893630656474],
            [-2.811185484740646, 50.717902361009067],
            [-2.813781014458985, 50.718418443163294],
            [-2.816731571864354, 50.71995986339838],
            [-2.818904360487451, 50.720675736658052],
            [-2.826930566695035, 50.722506669063563],
            [-2.836531955007104, 50.723733126376914],
            [-2.840410690265229, 50.722770866476061],
            [-2.842713571508603, 50.723366653005812],
            [-2.846843732063753, 50.72362898674713],
            [-2.848062981143381, 50.724305377940695],
            [-2.861781583936921, 50.727493446544052],
            [-2.872099699126945, 50.729137938811476],
            [-2.875397916518373, 50.729959444191834],
            [-2.894452020959031, 50.732765351379243],
            [-2.910025777801897, 50.733843559373902],
            [-2.912196257961831, 50.733677312890329],
            [-2.913351246917852, 50.732809449127082],
            [-2.915862460689535, 50.732316720269168],
            [-2.920415185486213, 50.731954419128073],
            [-2.927083483930854, 50.729517560757827],
            [-2.930401854418457, 50.725942525401955],
            [-2.929941754549989, 50.724958776954487],
            [-2.932651629482396, 50.72472579250644],
            [-2.932998315098585, 50.724062939150073],
            [-2.934708312529389, 50.723778552709476],
            [-2.937751663283224, 50.72265517509382],
            [-2.938502887276697, 50.721752536115531],
            [-2.938213854676373, 50.720970681471996],
            [-2.938721100463979, 50.720408137747761],
            [-2.938358189016847, 50.719442341065125],
            [-2.938311836238384, 50.719377849264738],
            [-2.937731135741825, 50.719001521790062],
            [-2.936955287314017, 50.719804532949858],
            [-2.93614603530365, 50.719927943222224],
            [-2.937041036513241, 50.719345205234653],
            [-2.935871990744598, 50.718971499839974],
            [-2.937210064028103, 50.719083951135694],
            [-2.937911688329496, 50.71882111012075],
            [-2.938274653014521, 50.719326116514402],
            [-2.938341076291033, 50.719396797546622],
            [-2.938391215344525, 50.719488289741776],
            [-2.938794682340091, 50.720049627326283],
            [-2.9397102699698, 50.720001781137363],
            [-2.946331042069187, 50.718854676432954],
            [-2.947804553213794, 50.718306725705894],
            [-2.948779296961149, 50.719997557060417],
            [-2.948727184662453, 50.721274974995154],
            [-2.952597015006407, 50.724605862078754],
            [-2.953061570898178, 50.728508583369297],
            [-2.949595859205129, 50.732358850711734],
            [-2.947772794870513, 50.733791867187243],
            [-2.947013577031619, 50.735001286573137],
            [-2.945839834897646, 50.735191573267535],
            [-2.946302027302268, 50.735273254607996],
            [-2.946612800273195, 50.735857067803721],
            [-2.946579629741384, 50.737304292928613],
            [-2.945649261536317, 50.737830733514279],
            [-2.94062839250486, 50.74271402127453],
            [-2.938879592480457, 50.743358519407352],
            [-2.937857093409967, 50.744107762649151],
            [-2.938243108473842, 50.745976073096948],
            [-2.940580075065488, 50.748647907893492],
            [-2.940566969576617, 50.749903417762319],
            [-2.939677492280316, 50.751850351183116],
            [-2.936506732844926, 50.753003569328513],
            [-2.935057805128981, 50.754276897170271],
            [-2.93941733742365, 50.757123161743259],
            [-2.949607010447475, 50.760928224165788],
            [-2.952585395935351, 50.761392239940314],
            [-2.953053731455086, 50.762181582284896],
            [-2.956032910030777, 50.763652703303975],
            [-2.955700419912784, 50.764175215991521],
            [-2.955939475793699, 50.764981713566385],
            [-2.954327686056903, 50.767720649291448],
            [-2.943540302691968, 50.775880410710123],
            [-2.939160474155865, 50.778422069214692],
            [-2.931915365012452, 50.781842610821755],
            [-2.929647778752805, 50.782442545763381],
            [-2.907337421827564, 50.785062519434746],
            [-2.897452382121901, 50.786149804338365],
            [-2.895634937370307, 50.787548666528302],
            [-2.893741326913297, 50.789868944449545],
            [-2.890575920659639, 50.79052715221404],
            [-2.887035843813598, 50.792532544183494],
            [-2.887812335254256, 50.792928612309233],
            [-2.887758380340695, 50.793450603920675],
            [-2.889297498499999, 50.793883120186557],
            [-2.886640671067374, 50.794817015667846],
            [-2.887224029131078, 50.796775700549077],
            [-2.888300271767107, 50.797486029925068],
            [-2.888810283367933, 50.798415593268423],
            [-2.888751518760987, 50.799730783370229],
            [-2.889805592640094, 50.800688568664825],
            [-2.88968920679706, 50.801135497626838],
            [-2.890197852815302, 50.801616326116608],
            [-2.889875405089575, 50.802197021467627],
            [-2.890411912645114, 50.802575118910624],
            [-2.890254480891729, 50.803475596744065],
            [-2.891556047339456, 50.803785796371194],
            [-2.892799831692976, 50.804786168459039],
            [-2.893598871204809, 50.804714401832328],
            [-2.893915641123781, 50.804952980254633],
            [-2.89500672475909, 50.804642455620318],
            [-2.895715245786618, 50.804958057763635],
            [-2.897184443224616, 50.804532198642733],
            [-2.899805287782661, 50.805179270664027],
            [-2.900652831562281, 50.8047518673825],
            [-2.902604632884869, 50.80462796591614],
            [-2.905428538023962, 50.805276937201938],
            [-2.907344258463092, 50.804682015662777],
            [-2.911291420822903, 50.804633255100995],
            [-2.912081243937402, 50.805255675474392],
            [-2.913292806969035, 50.805015979406697],
            [-2.913587974987607, 50.805305932594706],
            [-2.915302277737709, 50.805302383361223],
            [-2.917610776070438, 50.805928125678513],
            [-2.918551734508892, 50.805640144221726],
            [-2.920166036269202, 50.806685868066147],
            [-2.921910691646487, 50.806923887879684],
            [-2.922717437225573, 50.807603656424192],
            [-2.924894141166519, 50.808300441126079],
            [-2.925271279656137, 50.807993494169111],
            [-2.926246176698022, 50.808273524558849],
            [-2.926590112186318, 50.80786701724265],
            [-2.927547510429295, 50.808409764694794],
            [-2.928951649845144, 50.808433661184907],
            [-2.929773508176146, 50.809081786029395],
            [-2.930723250423953, 50.809022052876706],
            [-2.931272140498922, 50.808646269972911],
            [-2.931587273971276, 50.809148247777422],
            [-2.934456549566269, 50.809191840928655],
            [-2.935518377768301, 50.809840700408181],
            [-2.936265771376276, 50.809740279004821],
            [-2.936731225006683, 50.810090856900388],
            [-2.938396838920236, 50.809844554875482],
            [-2.939037695686521, 50.810093892443469],
            [-2.939006448644748, 50.810446660731643],
            [-2.939780838475696, 50.810488984574029],
            [-2.940734463830905, 50.811116186574822],
            [-2.941628768982998, 50.811121559821615],
            [-2.941500588162477, 50.811735002437288],
            [-2.942583557922811, 50.811945679071584],
            [-2.94271774938498, 50.812406823025078],
            [-2.943197337790857, 50.812398449313342],
            [-2.944116196502202, 50.813408996906304],
            [-2.944808257190187, 50.813378213160391],
            [-2.945669884260737, 50.814082558597846],
            [-2.947207678748783, 50.814319180032896],
            [-2.94683336397078, 50.814620779248202],
            [-2.947955624765906, 50.8154354013786],
            [-2.94903601627397, 50.815163127452216],
            [-2.94944459039959, 50.815431384005471],
            [-2.9498249838011, 50.81501012305997],
            [-2.95091057787976, 50.814994982626146],
            [-2.951747575620513, 50.815459377092857],
            [-2.952727711313894, 50.815074578009941],
            [-2.954108377837543, 50.81553811059544],
            [-2.954722564667345, 50.815376610604801],
            [-2.956607724097847, 50.815866558553914],
            [-2.958318660998752, 50.81581834119612],
            [-2.958428815626788, 50.816345311969265],
            [-2.959052952861903, 50.816046117968085],
            [-2.959983039591444, 50.816407170446276],
            [-2.960833462066088, 50.815514379566316],
            [-2.961340402251332, 50.815730525449943],
            [-2.961593103624335, 50.816146606500588],
            [-2.961220051670882, 50.816575039723709],
            [-2.959610873986168, 50.816810411395664],
            [-2.959178648254698, 50.817261806729668],
            [-2.959447116618645, 50.817893589176215],
            [-2.958619798397175, 50.817769097063938],
            [-2.95808691685547, 50.818019877357379],
            [-2.957718471838167, 50.817773803432083],
            [-2.957363700794951, 50.818057290355114],
            [-2.957623004094685, 50.818311455718778],
            [-2.958383958728038, 50.818175709840133],
            [-2.958778896673653, 50.818536671369564],
            [-2.95816571251246, 50.819996740255455],
            [-2.956831620259342, 50.820500495346913],
            [-2.95550363451382, 50.820192138236173],
            [-2.954315708072596, 50.821182074874599],
            [-2.953848757845583, 50.820839672866072],
            [-2.95296864093186, 50.822307291554594],
            [-2.951477016700814, 50.822889609540816],
            [-2.949434195970737, 50.822477300506669],
            [-2.949507285393391, 50.823700620125152],
            [-2.948212757473199, 50.823293886645146],
            [-2.947363481003298, 50.823700965125212],
            [-2.948033408164565, 50.825303427061499],
            [-2.947052875849078, 50.825330276946467],
            [-2.947317425184159, 50.826054743314685],
            [-2.946125575501145, 50.826164236108376],
            [-2.94602650280548, 50.828848476537289],
            [-2.945166345375358, 50.828511926266451],
            [-2.945382425941472, 50.828946323551591],
            [-2.944708130351689, 50.828743154673845],
            [-2.944093606296514, 50.82917978181522],
            [-2.944702603685917, 50.829664956073515],
            [-2.943868770280923, 50.830211270774299],
            [-2.944603515507256, 50.83136808526779],
            [-2.944638282059286, 50.83203506533237],
            [-2.944109590314211, 50.831942223822487],
            [-2.944436296646613, 50.832299288922094],
            [-2.943818725885412, 50.832657702032961],
            [-2.944255127453132, 50.833243194934084],
            [-2.943014160243109, 50.8347999846523],
            [-2.942078439906248, 50.835017075917357],
            [-2.941677392865921, 50.835905199303461],
            [-2.939710959400078, 50.83642105247938],
            [-2.938020820786207, 50.835137003497721],
            [-2.934376790761588, 50.836483683653057],
            [-2.934549359050824, 50.837657651187754],
            [-2.932485314992482, 50.838200242859266],
            [-2.93283123383249, 50.83881078129275],
            [-2.931794768538224, 50.83896744460165],
            [-2.930665243204956, 50.838730060466609],
            [-2.93026398083242, 50.839119948158093],
            [-2.930066389754515, 50.840967729835754],
            [-2.929023447207923, 50.840443670892178],
            [-2.927872423670337, 50.840770274591378],
            [-2.928617272914575, 50.839872269806428],
            [-2.927523137449529, 50.840059030074066],
            [-2.927291947826285, 50.839716446705125],
            [-2.926298435318797, 50.839892503619637],
            [-2.925200537504995, 50.839458774157357],
            [-2.92496510243075, 50.839977722626287],
            [-2.922343969972234, 50.840081208766748],
            [-2.922062336165982, 50.840419762834287],
            [-2.921669279895894, 50.840221431959577],
            [-2.92074265895898, 50.840622630195497],
            [-2.920119435270133, 50.841502538777981],
            [-2.919086090595012, 50.841534065488176],
            [-2.919238224229153, 50.841831424987674],
            [-2.917701072199017, 50.841811153317522],
            [-2.917365222189432, 50.841560200234511],
            [-2.916998624630867, 50.841624232902319],
            [-2.917300435911502, 50.841805311513376],
            [-2.916493254241631, 50.841781979673186],
            [-2.91425235824241, 50.842016287308027],
            [-2.913882840170018, 50.842440041536058],
            [-2.913256702788067, 50.842451239739283],
            [-2.91212302186034, 50.844112069867109],
            [-2.91104428611197, 50.844441538685267],
            [-2.911110761327858, 50.843916744672995],
            [-2.91055680069996, 50.843988515031789],
            [-2.910218971696555, 50.844802293521688],
            [-2.908932840458585, 50.844620776426474],
            [-2.908454528499778, 50.844127205399914],
            [-2.906742941351436, 50.844270915375553],
            [-2.906468074837479, 50.843720000021278],
            [-2.903076433869297, 50.843801159598826],
            [-2.902812440265098, 50.844105357737547],
            [-2.901718634316656, 50.844172269864799],
            [-2.901285251438875, 50.84460547002093],
            [-2.902547887618975, 50.84482232464439],
            [-2.901616572794388, 50.845950915836568],
            [-2.900648081029643, 50.845962893048188],
            [-2.901030829888886, 50.846586728732767],
            [-2.899684298349068, 50.846810247625058],
            [-2.899466799212981, 50.847318213608197],
            [-2.898510981226913, 50.846808498851608],
            [-2.897340606626026, 50.847552210184524],
            [-2.896500345958309, 50.847067668957507],
            [-2.895750237581887, 50.847210119712173],
            [-2.895006161943451, 50.846703246730563],
            [-2.894341569538593, 50.847306357619644],
            [-2.893531816043941, 50.846781094087419],
            [-2.893187732000399, 50.847581380931565],
            [-2.892455214498438, 50.847753351654511],
            [-2.892467176359711, 50.848380050830663],
            [-2.890310666508193, 50.849040404502325],
            [-2.890089547905399, 50.849662587301403],
            [-2.887117153227762, 50.850376764631321],
            [-2.886273708147276, 50.850170947635682],
            [-2.886240614130724, 50.850598351626694],
            [-2.884907761502197, 50.850278437274028],
            [-2.884883848969519, 50.849690496976855],
            [-2.883918633795539, 50.849654649028579],
            [-2.883417406484262, 50.849215106072243],
            [-2.88343632235518, 50.849539598810679],
            [-2.882600360852814, 50.849277044723372],
            [-2.883033790588146, 50.84883492181924],
            [-2.882862521911108, 50.848340720954702],
            [-2.882093692442121, 50.848623511316433],
            [-2.881990544325116, 50.848200735605232],
            [-2.880853957811382, 50.847963822374957],
            [-2.88065860161996, 50.846792650704096],
            [-2.879323291417867, 50.846485281045915],
            [-2.878945477099734, 50.84648632959415],
            [-2.878839815654, 50.847212835517972],
            [-2.877873057607295, 50.847470110897085],
            [-2.877990810581509, 50.847840622711153],
            [-2.878578345729868, 50.847811919307247],
            [-2.878933054641618, 50.84824359425432],
            [-2.878379605367257, 50.848952789051694],
            [-2.877426576952307, 50.848656907118432],
            [-2.877021609046901, 50.849331705622603],
            [-2.876785612627005, 50.84909427408995],
            [-2.876256030417132, 50.849260121206733],
            [-2.873949635270049, 50.848907822171718],
            [-2.873194637302037, 50.849478215903389],
            [-2.87260075256559, 50.849396329647249],
            [-2.872533961912457, 50.848938202442689],
            [-2.871456424019683, 50.849047873638689],
            [-2.871166124763188, 50.848257786500035],
            [-2.869739404896094, 50.848151526644536],
            [-2.869219918133693, 50.848479134812123],
            [-2.869637874538814, 50.848878892133705],
            [-2.867935559292554, 50.849155054541264],
            [-2.867503190422861, 50.848896583408013],
            [-2.866940663694848, 50.849355794463314],
            [-2.866016387591273, 50.849074893410744],
            [-2.865765483025192, 50.848489533716524],
            [-2.86508884937459, 50.848770628276966],
            [-2.864421735508577, 50.848491404503328],
            [-2.864148634632514, 50.848855832642244],
            [-2.863667733453267, 50.848583318533429],
            [-2.862925672568028, 50.849091500310386],
            [-2.861924739344619, 50.848965807926632],
            [-2.860323284059281, 50.849785170791115],
            [-2.85831007153067, 50.850076973749871],
            [-2.857996665269874, 50.850415605021468],
            [-2.856058960866698, 50.850023370631739],
            [-2.855353504410532, 50.850363971589211],
            [-2.854971705827427, 50.849605090887543],
            [-2.854415171194816, 50.849388847613596],
            [-2.85402473750332, 50.849322462790198],
            [-2.853670246731761, 50.84966857730214],
            [-2.85257401951062, 50.849379835387609],
            [-2.850688646373597, 50.850765879619239],
            [-2.848996546850888, 50.850910397768857],
            [-2.8488182823189, 50.85063741852796],
            [-2.848553290203451, 50.851221171782527],
            [-2.847609450640569, 50.851345836750035],
            [-2.847306223437106, 50.850839953721156],
            [-2.846593347181633, 50.850774986125039],
            [-2.846342155973091, 50.851337950996928],
            [-2.845193434138243, 50.851602569548888],
            [-2.845392546384128, 50.851222535709553],
            [-2.844923826285944, 50.851147695040936],
            [-2.844735330967596, 50.850149974836427],
            [-2.843978388833504, 50.850239984209772],
            [-2.843489372664574, 50.849590653232866],
            [-2.843766494625155, 50.849203763145212],
            [-2.843157995809534, 50.848781010760888],
            [-2.842843917605655, 50.84735434516147],
            [-2.838845371005144, 50.848068405839754],
            [-2.837156081427672, 50.848763083438826],
            [-2.831442913418774, 50.84940554912955],
            [-2.831588663089386, 50.849773209428292],
            [-2.830998664533005, 50.849906008506451],
            [-2.829639571225717, 50.848564983667458],
            [-2.828580382706264, 50.848753263645371],
            [-2.829374671629581, 50.850608201073676],
            [-2.828956647467656, 50.851708276310703],
            [-2.828524241091086, 50.851597141171474],
            [-2.82849052487462, 50.851939101560809],
            [-2.828119934858493, 50.851227715716405],
            [-2.827425666244901, 50.851249727768284],
            [-2.827155251062861, 50.850659029196372],
            [-2.826319080066061, 50.850689234809884],
            [-2.824418524639748, 50.852066866428487],
            [-2.823157342868918, 50.852487635221244],
            [-2.822164605648331, 50.853472136641372],
            [-2.820475218833444, 50.853943554045848],
            [-2.817728643506397, 50.8556228798479],
            [-2.813899356494125, 50.859102830588917],
            [-2.81237365129525, 50.861242020922688],
            [-2.812935886071622, 50.862115785992941],
            [-2.811423919973059, 50.863417652854316],
            [-2.810150374103018, 50.862575799874683],
            [-2.808067225319903, 50.863638786593611],
            [-2.80731896351528, 50.863260880449033],
            [-2.807808390583881, 50.862886097285482],
            [-2.806498233347945, 50.862058846247201],
            [-2.805588972893059, 50.861900562707035],
            [-2.805605101499422, 50.861517365255452],
            [-2.807051174136647, 50.858944470814158],
            [-2.80784095586119, 50.858687211185135],
            [-2.802903163164311, 50.857250101035483],
            [-2.801686320553809, 50.856796247247551],
            [-2.80114153746948, 50.856178598172718],
            [-2.800415235873458, 50.856160202398108],
            [-2.799052118945585, 50.85545732928729],
            [-2.795073425473168, 50.854661684865519],
            [-2.79371952446622, 50.853406538783808],
            [-2.792431393318388, 50.85284246415052],
            [-2.788976787925973, 50.85345129835563],
            [-2.787537181886692, 50.853758681271593],
            [-2.786198976291826, 50.854521289634022],
            [-2.784079989625824, 50.856846655952893],
            [-2.782285131592618, 50.859704870714786],
            [-2.780060556511004, 50.860326767313474],
            [-2.775411928022888, 50.860691381221883],
            [-2.771698068949412, 50.860276265609038],
            [-2.767395713418232, 50.86070571385244],
            [-2.761686972161623, 50.86080786950177],
            [-2.760967492225853, 50.86129906471934],
            [-2.760955675503616, 50.863191187145674],
            [-2.759915588940233, 50.865989271225928],
            [-2.757497750545649, 50.866910548726807],
            [-2.756961500990434, 50.86661547456066],
            [-2.754987610061089, 50.866786533610707],
            [-2.754823197930623, 50.865493561866003],
            [-2.753828289454446, 50.864335451877174],
            [-2.75045223610452, 50.863814062222467],
            [-2.738238689111743, 50.864680631845928],
            [-2.737065138796542, 50.864241119281424],
            [-2.733144712236316, 50.864965443884728],
            [-2.729910871522515, 50.864712344601784],
            [-2.724988141789743, 50.865413884586047],
            [-2.724030960427303, 50.866092474120158],
            [-2.723090477316577, 50.866199023524679],
            [-2.721384276171607, 50.864938932248748],
            [-2.717995541769611, 50.864033598487119],
            [-2.715394513611429, 50.863895802694358],
            [-2.714554840558435, 50.863902746749964],
            [-2.714419739782681, 50.865475478512685],
            [-2.713807651426172, 50.866336218306166],
            [-2.714388585028249, 50.871151787477167],
            [-2.711759934144895, 50.870905268988828],
            [-2.711209986849018, 50.870067816407335],
            [-2.70714394277825, 50.869774192868526],
            [-2.704202491507623, 50.87252662480784],
            [-2.7029019976811, 50.873276353604325],
            [-2.69957676080372, 50.87283142215076],
            [-2.697133513799348, 50.873486316411316],
            [-2.695683290617909, 50.874689184930013],
            [-2.696502475184649, 50.875487333957828],
            [-2.695725542380253, 50.875999149677718],
            [-2.684627113190205, 50.877009914888625],
            [-2.680563846590199, 50.877131703920377],
            [-2.678964327709126, 50.877488134951882],
            [-2.671791290359626, 50.880589818329959],
            [-2.668859933555908, 50.882452827699915],
            [-2.666315878317465, 50.883579760179487],
            [-2.664456890913955, 50.885105603509807],
            [-2.661411345077824, 50.886374666918186],
            [-2.660610042737173, 50.88711299682204],
            [-2.658425009196611, 50.886611872710219],
            [-2.650417948938629, 50.883316052156609],
            [-2.649575106670624, 50.884142664835089],
            [-2.646833181917359, 50.88416149342882],
            [-2.647358054857283, 50.88541483945172],
            [-2.646151368276681, 50.887384596768285],
            [-2.646989228821742, 50.887831379125373],
            [-2.647103186629322, 50.88836040707023],
            [-2.643023240829934, 50.887246305676818],
            [-2.64251118033667, 50.886710473920935],
            [-2.640766328263987, 50.886984448653457],
            [-2.637598650978234, 50.886965830023783],
            [-2.63078616492555, 50.885810446648613],
            [-2.627959672929205, 50.886313985837887],
            [-2.627179804820642, 50.879902910953014],
            [-2.624128033411571, 50.87926731612874],
            [-2.623327666228701, 50.879578239885092],
            [-2.623005082501457, 50.880329939805868],
            [-2.622661352745127, 50.880242748538102],
            [-2.622185070592064, 50.880768654432352],
            [-2.620935941635272, 50.880900304622294],
            [-2.620520118648352, 50.881271210382927],
            [-2.617894015842759, 50.881007277384882],
            [-2.61609997409191, 50.881641744574573],
            [-2.615177806534715, 50.882533273058336],
            [-2.614949120920798, 50.883841991387371],
            [-2.614503948586319, 50.883716642862581],
            [-2.613097776343401, 50.884454232542886],
            [-2.612910813710344, 50.885047821827897],
            [-2.612317595300484, 50.884799145751572],
            [-2.611237324418804, 50.885026025557572],
            [-2.611758315287152, 50.885621297774648],
            [-2.611226298705303, 50.886138457826803],
            [-2.611609428905951, 50.886518632270388],
            [-2.610151275265651, 50.888945252461752],
            [-2.610363433537977, 50.890166225287501],
            [-2.60944128509936, 50.890862569110546],
            [-2.609757876791969, 50.891915736503478],
            [-2.610299781112476, 50.892366125980537],
            [-2.612558221070977, 50.892196024295487],
            [-2.614844634708839, 50.893287380462965],
            [-2.614533873733135, 50.893766519558255],
            [-2.615403728608388, 50.894281702346873],
            [-2.614988015918022, 50.894457449305726],
            [-2.615704787461659, 50.895869989206211],
            [-2.615479754997128, 50.896381051255773],
            [-2.616380507218657, 50.896754881384076],
            [-2.615527123973213, 50.897277353912521],
            [-2.615634354906312, 50.898076221624166],
            [-2.616209281242449, 50.898540797212057],
            [-2.616203476693092, 50.899501226797796],
            [-2.616999794837671, 50.899607627006105],
            [-2.617519500239755, 50.900087775271487],
            [-2.617631467842016, 50.901563750761177],
            [-2.618696876476892, 50.901369264832105],
            [-2.619661232658216, 50.90234702805617],
            [-2.618946695392057, 50.902620594787166],
            [-2.619326900149021, 50.904051081951025],
            [-2.619976757079982, 50.904687896887367],
            [-2.622212518710844, 50.905441259229534],
            [-2.622353889920145, 50.906123934743576],
            [-2.624065588569149, 50.90777570704995],
            [-2.623871750755243, 50.908170614393704],
            [-2.622399039427542, 50.908762090128214],
            [-2.622097077159709, 50.910116169922901],
            [-2.620433178890317, 50.910027908452442],
            [-2.619995067241108, 50.910560793212341],
            [-2.620023189391206, 50.911499458966958],
            [-2.619415043663528, 50.911761671653665],
            [-2.618871358836949, 50.911629668696904],
            [-2.61733500009714, 50.912368894040824],
            [-2.617335617170518, 50.913275332623009],
            [-2.616608743853828, 50.913382588630093],
            [-2.6170436353532, 50.914321802519495],
            [-2.616433877666019, 50.915111866273413],
            [-2.616993322253382, 50.915679932262094],
            [-2.616132637896251, 50.915998316380701],
            [-2.615625008494701, 50.916661042578532],
            [-2.616073304647032, 50.917537241574514],
            [-2.615303806348101, 50.917653706714141],
            [-2.616044710866232, 50.919249558724331],
            [-2.61519001129822, 50.919487871020692],
            [-2.615339702752556, 50.919943000019266],
            [-2.614530372917435, 50.9211423628049],
            [-2.614903518943621, 50.921928137428225],
            [-2.614629594813681, 50.922193958650489],
            [-2.615187736983839, 50.922442807142708],
            [-2.614804470755387, 50.923264039925073],
            [-2.614156212107094, 50.923627151215193],
            [-2.613408380677775, 50.92356004334718],
            [-2.60978044997655, 50.925308303052844],
            [-2.606593137661171, 50.925838380212546],
            [-2.605198789099719, 50.926607279132888],
            [-2.604039359541116, 50.926731985403116],
            [-2.604472072366419, 50.927732403696176],
            [-2.604031829145792, 50.930982755254831],
            [-2.604628649419023, 50.932564134129599],
            [-2.606267060804537, 50.93468144935575],
            [-2.607860537963404, 50.935633554928849],
            [-2.610520524818395, 50.936515319968045],
            [-2.610359428916743, 50.93671129836325],
            [-2.612784621325574, 50.937262418299468],
            [-2.614596742503578, 50.938157530824576],
            [-2.615858267120088, 50.939600463069667],
            [-2.616035078554976, 50.941455568850195],
            [-2.615732961967062, 50.941211669338671],
            [-2.614003989341522, 50.941344871851854],
            [-2.613926049034099, 50.940290469206289],
            [-2.611587859195472, 50.940200229080396],
            [-2.608611129490584, 50.942218401434317],
            [-2.608619935379117, 50.943655345754898],
            [-2.608143739561114, 50.944031013258162],
            [-2.607305436407741, 50.944036276674851],
            [-2.607174231662443, 50.945764404207779],
            [-2.60675038669168, 50.94589969581444],
            [-2.605574459292145, 50.945339279098761],
            [-2.604960131389304, 50.945384728703416],
            [-2.604472466605392, 50.945977157884833],
            [-2.601688314680487, 50.946866503778118],
            [-2.601683988432616, 50.947413265253701],
            [-2.599192139078581, 50.947686862076964],
            [-2.598200061966093, 50.948581300091284],
            [-2.598159742550243, 50.948988863178954],
            [-2.599203907935215, 50.949265870234726],
            [-2.597988323713855, 50.950162352387757],
            [-2.598658771923083, 50.950601342993451],
            [-2.598481686610256, 50.951887266828209],
            [-2.599354654648804, 50.952241585537067],
            [-2.599298868495116, 50.952663616038599],
            [-2.598556033835291, 50.953019929509132],
            [-2.599554171805184, 50.954359172969191],
            [-2.598148492251389, 50.957757419749242],
            [-2.598731354012535, 50.958573639934613],
            [-2.59837750593581, 50.959513361527883],
            [-2.597944989770473, 50.960759226364026],
            [-2.596724883615611, 50.961216887226207],
            [-2.59546464191236, 50.960876215129559],
            [-2.594908080824172, 50.962116406194419],
            [-2.5959967978132, 50.964628723825264],
            [-2.597354916442846, 50.964820514944648],
            [-2.597255812291475, 50.965753532940674],
            [-2.599081508059098, 50.96664431765128],
            [-2.599881710657025, 50.967408159076314],
            [-2.599650293907029, 50.969563923833668],
            [-2.599222412177923, 50.970067895669317],
            [-2.599581770538084, 50.970767456807707],
            [-2.598559007551211, 50.971528064584909],
            [-2.598949590764446, 50.972661799421928],
            [-2.59802057277246, 50.973517240445801],
            [-2.598975396157315, 50.974556361339722],
            [-2.601074421354919, 50.974634599301829],
            [-2.602066024876443, 50.974986487902946],
            [-2.60274776854747, 50.975607041655742],
            [-2.602693574257602, 50.976041653212555],
            [-2.603385550936535, 50.976351014135972],
            [-2.595843378960572, 50.981346339607967],
            [-2.591548426548874, 50.978767584432106],
            [-2.58929016277817, 50.976926575144788],
            [-2.586021979089832, 50.97687558072036],
            [-2.58672602152315, 50.977810848452741],
            [-2.584255481126059, 50.978896925104124],
            [-2.583179686011855, 50.978869930573396],
            [-2.580821893518881, 50.981353683349077],
            [-2.579357224267627, 50.981561489364488],
            [-2.578334697326661, 50.982157356342704],
            [-2.576873607094592, 50.98231205848618],
            [-2.574937330073514, 50.983324256165119],
            [-2.574348100391299, 50.985099549022394],
            [-2.574628573167776, 50.986699707079964],
            [-2.569103127092, 50.985376116014081],
            [-2.562370280250653, 50.977716675612953],
            [-2.560100219513613, 50.978700560297639],
            [-2.556205879451588, 50.979541077557222],
            [-2.555127294322114, 50.981429209453445],
            [-2.553332000780058, 50.982833343814406],
            [-2.550216601735096, 50.983807549145261],
            [-2.548090659893018, 50.98485796193031],
            [-2.545422161586569, 50.985086273310309],
            [-2.545426298965019, 50.989813527456256],
            [-2.54472285060627, 50.991671929853609],
            [-2.545617278678231, 50.992126363124541],
            [-2.547373739069498, 50.992215260162816],
            [-2.551922431047319, 50.993703662631113],
            [-2.551487874341288, 50.996350360206783],
            [-2.54853394127641, 50.995991990560853],
            [-2.547289329623407, 50.99642406654948],
            [-2.546920087701659, 50.997257586674749],
            [-2.547432970682685, 50.999547327004343],
            [-2.535165396467512, 50.999378482729959],
            [-2.535853206825665, 50.99686197484953],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000053",
        LAD13CDO: "19UJ",
        LAD13NM: "Weymouth and Portland",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-2.492782224464129, 50.59236840053493],
              [-2.491476772968818, 50.591547467859279],
              [-2.492042708235304, 50.591628711804056],
              [-2.491881221298514, 50.591451334049012],
              [-2.481283627256585, 50.585278806932919],
              [-2.472952084302307, 50.579739124598248],
              [-2.470385350308992, 50.578842135429454],
              [-2.469947638394819, 50.578144251754303],
              [-2.469471043508661, 50.577937538278256],
              [-2.469773355916266, 50.578654855411393],
              [-2.470550277174812, 50.579235360065645],
              [-2.469894140217494, 50.579721830908582],
              [-2.471036422491768, 50.58110482634617],
              [-2.471508306682086, 50.582673983508386],
              [-2.46983714383332, 50.582508974706514],
              [-2.469857306876726, 50.582264285271854],
              [-2.469529947999954, 50.582439169822436],
              [-2.468704344580488, 50.580374119411147],
              [-2.466155618551479, 50.577291678172244],
              [-2.464446928334507, 50.574439648293435],
              [-2.462885686280002, 50.572778572853942],
              [-2.460439495255031, 50.571006769273751],
              [-2.460462605561083, 50.571494096198208],
              [-2.459905659871224, 50.571145572554499],
              [-2.457512768253092, 50.571065072280923],
              [-2.455937209800083, 50.571686372262597],
              [-2.455305340019467, 50.571033258860176],
              [-2.453437601053993, 50.570494676711093],
              [-2.452871689648945, 50.571265777556775],
              [-2.452099577052897, 50.571003486628705],
              [-2.452851261117371, 50.570460987377146],
              [-2.447275782960252, 50.568464524303991],
              [-2.445948416623085, 50.568308643114072],
              [-2.445027363641127, 50.568714153340984],
              [-2.443705822927968, 50.568577109540456],
              [-2.44342617381997, 50.569472075160121],
              [-2.442606796479851, 50.569431128104533],
              [-2.442589338281815, 50.568472545209801],
              [-2.441794184516342, 50.568453083446272],
              [-2.441163584912541, 50.568665910323119],
              [-2.440795699806697, 50.569946102442366],
              [-2.438919085553589, 50.569341672659739],
              [-2.437839798064813, 50.570203665231141],
              [-2.432134193713034, 50.571350004817688],
              [-2.431951565135852, 50.571144744069244],
              [-2.433715676885981, 50.57011118920115],
              [-2.437731903114181, 50.569989139399823],
              [-2.438703416566909, 50.569382054685278],
              [-2.437364410400173, 50.569036368189195],
              [-2.437665160275885, 50.568882357522597],
              [-2.437254687374821, 50.568928864928893],
              [-2.437365718349703, 50.568262968988932],
              [-2.433860093615577, 50.56800180298066],
              [-2.43334305151977, 50.567667392395563],
              [-2.430717774912065, 50.56762767505041],
              [-2.431592289975722, 50.568144228687679],
              [-2.431436400871308, 50.568391213585166],
              [-2.428446975933445, 50.567750266113698],
              [-2.422111117748877, 50.569308512021102],
              [-2.422180918703772, 50.569060952681099],
              [-2.428242105134493, 50.567424575707186],
              [-2.428639718819639, 50.567050803764886],
              [-2.428453687799875, 50.565844633683689],
              [-2.426740541809123, 50.56485720500433],
              [-2.423864157123199, 50.562149142652203],
              [-2.421495050768592, 50.559297083581889],
              [-2.421556213929459, 50.558399363828237],
              [-2.419191970119468, 50.554944709618077],
              [-2.418128234701725, 50.554218306472258],
              [-2.41731592629311, 50.55238845338345],
              [-2.415842258960483, 50.551104135010206],
              [-2.415770417409911, 50.550158330200283],
              [-2.416346601627817, 50.549872092214862],
              [-2.41567867673146, 50.54919640890212],
              [-2.417347669543697, 50.547992570519853],
              [-2.418212979124952, 50.542742954810599],
              [-2.420384613416509, 50.541123586074789],
              [-2.425229346789128, 50.538619420779177],
              [-2.428266015775121, 50.537985969714029],
              [-2.429571659781229, 50.536007193230311],
              [-2.434292753623373, 50.531980586920476],
              [-2.437180364055358, 50.53023951870869],
              [-2.437728901267825, 50.526702300917968],
              [-2.439486524098217, 50.524706419379065],
              [-2.441555089099997, 50.523545682592342],
              [-2.442771920445074, 50.520469039840975],
              [-2.444056194237348, 50.519785172008476],
              [-2.445297951462925, 50.519683301943772],
              [-2.44622533620187, 50.519003473124457],
              [-2.447171570153408, 50.519126642508674],
              [-2.447448983509157, 50.518630059825],
              [-2.448323371412367, 50.518465718922251],
              [-2.448207055989941, 50.518099251086376],
              [-2.448907113735073, 50.517996729781572],
              [-2.449160348240788, 50.517184580505152],
              [-2.450155609290065, 50.517126776296081],
              [-2.450694531211553, 50.516546437725083],
              [-2.451876406618316, 50.51668304481737],
              [-2.452175841648502, 50.516136004031829],
              [-2.452612261804805, 50.516197258601707],
              [-2.453014345814249, 50.515176782232714],
              [-2.453566310088027, 50.515374277591093],
              [-2.453524637923472, 50.515146916027561],
              [-2.454290295068179, 50.515125043930411],
              [-2.454450400762004, 50.514760200473177],
              [-2.455067417180827, 50.514682247500104],
              [-2.456589600727492, 50.513109695969185],
              [-2.45758097798713, 50.513243395274024],
              [-2.459441701194109, 50.514304437174168],
              [-2.459286322536794, 50.515733146086866],
              [-2.459472705963683, 50.516334944179668],
              [-2.459693515885121, 50.516124534175006],
              [-2.460131078396293, 50.516592241600293],
              [-2.459878549251704, 50.519343311651696],
              [-2.458684637504156, 50.521902046115827],
              [-2.457743894767334, 50.52308383774109],
              [-2.456715685505733, 50.523685914320588],
              [-2.455397720323247, 50.525697424276338],
              [-2.456044115158804, 50.531284056858205],
              [-2.454533158792931, 50.535847628142264],
              [-2.45440488330785, 50.537475863922722],
              [-2.453667591526592, 50.53887355584645],
              [-2.454476493466444, 50.540374030373982],
              [-2.456105002866228, 50.541397358556665],
              [-2.456414530642049, 50.542469010311656],
              [-2.455113212407976, 50.544652210865209],
              [-2.452721287603035, 50.546594134506705],
              [-2.452067309403825, 50.547573315618067],
              [-2.451601450531779, 50.549778406951773],
              [-2.452109464059474, 50.551536362464283],
              [-2.451180870386417, 50.552442861577283],
              [-2.449654785567235, 50.555684442318253],
              [-2.448018645123812, 50.557168297917286],
              [-2.448012252132683, 50.557833802787002],
              [-2.449189831427296, 50.560231287727973],
              [-2.451502192876964, 50.562284429490468],
              [-2.478610079505629, 50.58085900484086],
              [-2.497860726103218, 50.593141770647492],
              [-2.504000427344796, 50.596562346415155],
              [-2.502574449891484, 50.597586509812409],
              [-2.501378213587178, 50.597366843868294],
              [-2.496616297861528, 50.594102134696776],
              [-2.492782224464129, 50.59236840053493],
            ],
          ],
          [
            [
              [-2.483711420796108, 50.678335963146104],
              [-2.478561541478813, 50.677548779499752],
              [-2.478241328287202, 50.677931389117013],
              [-2.471924049402956, 50.67560013891179],
              [-2.468118255616975, 50.673161358757412],
              [-2.468187493698289, 50.67243985767486],
              [-2.466757546979153, 50.671946495316156],
              [-2.463722919790086, 50.671599802080159],
              [-2.464139156785648, 50.669180878109138],
              [-2.465622698960518, 50.666951035691767],
              [-2.465215621766154, 50.66456237231651],
              [-2.465756216738917, 50.663285929948159],
              [-2.465327256947374, 50.661257065820145],
              [-2.465679004959111, 50.660058715571701],
              [-2.466240280680218, 50.659876613850393],
              [-2.465956006438535, 50.65888584293215],
              [-2.466597341295318, 50.656791544289909],
              [-2.467000830968941, 50.652402324928893],
              [-2.460673853999751, 50.651996764120391],
              [-2.458699112381267, 50.650684409760458],
              [-2.448185861614534, 50.647485220914604],
              [-2.442908504112483, 50.646706845443454],
              [-2.432679285535084, 50.646192237401017],
              [-2.432482514234755, 50.648328766387898],
              [-2.434321616416613, 50.65005394202484],
              [-2.433407368515579, 50.650921558658872],
              [-2.433373611998912, 50.652017909994179],
              [-2.428645829827411, 50.653214365063185],
              [-2.429617884607397, 50.655411327264723],
              [-2.42922006644314, 50.656928983374712],
              [-2.426753574398753, 50.657474917826171],
              [-2.425018472798241, 50.657085578289355],
              [-2.42431167904664, 50.659500028355197],
              [-2.41801273952403, 50.660564172368019],
              [-2.414867889955203, 50.661873078689005],
              [-2.414828384451062, 50.662687967711364],
              [-2.411705334477297, 50.661986818340374],
              [-2.409669422000381, 50.660527270033498],
              [-2.405533383987365, 50.659634369896018],
              [-2.40537702568693, 50.658892108128349],
              [-2.406072559385382, 50.653749392591592],
              [-2.405819027356575, 50.650266457453249],
              [-2.406600349990644, 50.646725055079337],
              [-2.406280648778901, 50.644915914014341],
              [-2.406591411547776, 50.643069500045215],
              [-2.408845275760922, 50.639432099817178],
              [-2.410018192647688, 50.635763392134997],
              [-2.411082694875288, 50.634112149435417],
              [-2.414750267284043, 50.635038897732599],
              [-2.418126638883291, 50.635322688544527],
              [-2.419655488678506, 50.63646557599445],
              [-2.423369455610124, 50.635907166553899],
              [-2.425909970949313, 50.63522343908194],
              [-2.431767445967138, 50.632664085789031],
              [-2.437393677998637, 50.62932742232416],
              [-2.44097388644192, 50.626644837434029],
              [-2.443871773911431, 50.6242075511894],
              [-2.447796831327817, 50.620406522846629],
              [-2.451014110129943, 50.616562238835272],
              [-2.452476198245523, 50.614117695345932],
              [-2.452573810591078, 50.612949139449448],
              [-2.452073797051416, 50.610918687885423],
              [-2.450466319150378, 50.608641621437592],
              [-2.446419379012768, 50.610341577163688],
              [-2.444990994147394, 50.60949002192104],
              [-2.443907736752004, 50.60986556337064],
              [-2.444578394901602, 50.609347711708104],
              [-2.443785470262755, 50.609037783784714],
              [-2.442843513094774, 50.608658272784886],
              [-2.44136752105909, 50.609653095580533],
              [-2.443389139356369, 50.60806985943767],
              [-2.443048887874514, 50.607503702784456],
              [-2.443400213972121, 50.606993367357163],
              [-2.450750107430456, 50.604474111970461],
              [-2.450909959972672, 50.603753161315886],
              [-2.449520907762861, 50.601975238998186],
              [-2.448486978082089, 50.601126698496913],
              [-2.443307940000023, 50.599583565262087],
              [-2.442406951689887, 50.599634654764586],
              [-2.442467352043804, 50.599295392517007],
              [-2.432799298969925, 50.59660688141954],
              [-2.43242817312207, 50.596194586472606],
              [-2.446705323971456, 50.600396129184645],
              [-2.447303462971928, 50.599549398452247],
              [-2.447624465783115, 50.599576942080475],
              [-2.447955782759949, 50.599797792734933],
              [-2.447588348423758, 50.599791112219819],
              [-2.447294408414178, 50.600531459079221],
              [-2.449872941575046, 50.601254446620672],
              [-2.450630834158105, 50.600717336908495],
              [-2.451576040433688, 50.600560795278724],
              [-2.452347745467737, 50.599846460355472],
              [-2.459232384647978, 50.597592863184957],
              [-2.460093100981758, 50.596605639817469],
              [-2.460085075249708, 50.595782819385263],
              [-2.460633004647983, 50.595033341121095],
              [-2.463704768000559, 50.594200093618035],
              [-2.464802771294048, 50.59322268087444],
              [-2.466230007880072, 50.591827567053116],
              [-2.46761502145336, 50.588176278225703],
              [-2.469135303089201, 50.586786149296032],
              [-2.469984327490311, 50.584949064889308],
              [-2.47048711457915, 50.585367004332888],
              [-2.471613345518941, 50.585269820210634],
              [-2.471347378273436, 50.584662974597514],
              [-2.470206213822209, 50.583968837737139],
              [-2.470373647372406, 50.583194768388118],
              [-2.471841906043154, 50.583547645244145],
              [-2.47240548679173, 50.584535482567809],
              [-2.475288140928595, 50.585629881460498],
              [-2.477028190237557, 50.585749565202924],
              [-2.480996057999495, 50.588662239767608],
              [-2.483528492393535, 50.588897256931908],
              [-2.484188419578378, 50.590158021801876],
              [-2.484040004097285, 50.591113688543729],
              [-2.484565269745241, 50.591500897603247],
              [-2.485478303139555, 50.591678751271616],
              [-2.486335373984675, 50.591223729052977],
              [-2.487789258769713, 50.591107033782166],
              [-2.483671473018401, 50.5938877408108],
              [-2.484676351887426, 50.594475296719828],
              [-2.484109014265755, 50.595223170770346],
              [-2.485577898537906, 50.596002136515317],
              [-2.484731427501067, 50.596535346982421],
              [-2.485337563422347, 50.597635354475194],
              [-2.486668448579636, 50.598724237965051],
              [-2.484613063443403, 50.599591634897259],
              [-2.485080140688686, 50.600081602665199],
              [-2.48312097895557, 50.600557380086286],
              [-2.483378237039234, 50.600842287172291],
              [-2.482754579124359, 50.601343082255305],
              [-2.483353734004158, 50.602311831555433],
              [-2.482954771855872, 50.602402516925487],
              [-2.485102120880353, 50.604691280009625],
              [-2.486429592501087, 50.60707425920679],
              [-2.488457184686598, 50.608952483505327],
              [-2.482029136147747, 50.611655558889275],
              [-2.48327036054848, 50.615547936353714],
              [-2.483945587076163, 50.61840037581581],
              [-2.483696123967373, 50.619298003470313],
              [-2.482206784882389, 50.620180087559405],
              [-2.480626156323892, 50.622650672733776],
              [-2.479763103378896, 50.622995961127778],
              [-2.481623680139683, 50.627441555330783],
              [-2.482685662822866, 50.627739319160156],
              [-2.483665063432873, 50.631422334012868],
              [-2.48626322168209, 50.635746077373156],
              [-2.485985208594614, 50.636347961167587],
              [-2.486942154216746, 50.637406021265029],
              [-2.486119306848217, 50.639750300347679],
              [-2.488996801545972, 50.639990953869578],
              [-2.488048835094486, 50.641037202384368],
              [-2.487429586604371, 50.642556890466835],
              [-2.48731451971049, 50.644420691216197],
              [-2.488520322396751, 50.64449927343378],
              [-2.487683042189724, 50.648185354010437],
              [-2.488340549890601, 50.648166412017957],
              [-2.488398850913519, 50.648741709717996],
              [-2.489124055997102, 50.64883938642614],
              [-2.488648832631469, 50.651158836956597],
              [-2.491041534865192, 50.651503991698917],
              [-2.489822081690399, 50.656656626617618],
              [-2.490032444747171, 50.656887756930431],
              [-2.492382599145376, 50.657058602226428],
              [-2.491455788587224, 50.660698317782625],
              [-2.491083413997848, 50.6606711112032],
              [-2.490957086203268, 50.661038551083095],
              [-2.490088160469068, 50.665363262003702],
              [-2.497909840232913, 50.666693404146976],
              [-2.496192395865999, 50.674104516313285],
              [-2.494572992321136, 50.67864107828683],
              [-2.483711420796108, 50.678335963146104],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000061",
        LAD13CDO: "21UC",
        LAD13NM: "Eastbourne",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.289386007212168, 50.813068768523856],
            [0.295419242261598, 50.812690257279876],
            [0.3000458486885, 50.81283744831461],
            [0.300743288111145, 50.811690450111875],
            [0.301224786796193, 50.811551437142406],
            [0.300137141312905, 50.809870331401576],
            [0.302092499911685, 50.808296477033224],
            [0.30408964237068, 50.809189714903738],
            [0.310372327334177, 50.807808961385071],
            [0.313541039463817, 50.808738183698871],
            [0.315803289361817, 50.808672598288638],
            [0.315629549211542, 50.808173283077188],
            [0.31785349319938, 50.806732334460804],
            [0.320169061643749, 50.806971400401814],
            [0.320663069156707, 50.806600011836792],
            [0.32404023236302, 50.807865674829877],
            [0.323804727501718, 50.806809972785437],
            [0.324793835540762, 50.806233529856769],
            [0.325332994256849, 50.806372081580555],
            [0.325920840121448, 50.806090538332285],
            [0.326928654283179, 50.805405775558171],
            [0.326940962862698, 50.805025082663981],
            [0.32866004588802, 50.804829775989511],
            [0.330288693564789, 50.805469997580325],
            [0.331482008714226, 50.804321892983694],
            [0.332972489070958, 50.804125695972871],
            [0.332458021721929, 50.802288611773228],
            [0.332904028278458, 50.802164566763949],
            [0.333966328537095, 50.802166695511069],
            [0.339189796812987, 50.799192936002129],
            [0.330964864037627, 50.789967846670983],
            [0.330570869932347, 50.788436839636091],
            [0.331223262336702, 50.788145882462096],
            [0.331193160099262, 50.787797514978458],
            [0.328720533207849, 50.784894173985791],
            [0.318971899248791, 50.782249937568317],
            [0.31572797502075, 50.781056140634099],
            [0.310127818397782, 50.779227212356467],
            [0.307156028746401, 50.777241708278744],
            [0.30284572941824, 50.77534822974313],
            [0.301777432016146, 50.774564358155253],
            [0.301594610584806, 50.773960869850335],
            [0.296032650457516, 50.77036319022293],
            [0.294333880806038, 50.768283933067011],
            [0.291680262962981, 50.765932017096503],
            [0.288231256536894, 50.763171136802363],
            [0.286273611112358, 50.760364730294796],
            [0.27679359235186, 50.755999273525049],
            [0.271528969143622, 50.75254303487057],
            [0.265581984975817, 50.746300884393406],
            [0.262146311341021, 50.740518789729116],
            [0.260029333360143, 50.738441758618883],
            [0.258194349625714, 50.737656165980077],
            [0.25322684404921, 50.73742401007015],
            [0.251767491978919, 50.736783953934101],
            [0.246522155542305, 50.736281662549516],
            [0.243095204703579, 50.734976969608233],
            [0.240880169127349, 50.734949419111878],
            [0.232467962115947, 50.735853735318592],
            [0.229376592456184, 50.736085583571246],
            [0.228797940092975, 50.735897881582247],
            [0.222953476097199, 50.73695205109356],
            [0.218237210018003, 50.737022106625567],
            [0.213373746108425, 50.738088600172929],
            [0.209914790624237, 50.738405156670844],
            [0.211172202309038, 50.742983525263597],
            [0.212442220827518, 50.742291177339837],
            [0.213585626235094, 50.740628072079971],
            [0.215460806251585, 50.73972633007434],
            [0.214646378047813, 50.744544584892253],
            [0.213531851540137, 50.745618945745903],
            [0.211442943499138, 50.749330823474452],
            [0.209352892472154, 50.751339227286053],
            [0.212077998867946, 50.752787743395373],
            [0.213109805841924, 50.753712526182625],
            [0.212879500834862, 50.753879688329029],
            [0.217455781626664, 50.757141185941627],
            [0.213585398578487, 50.760126075528269],
            [0.212212129510949, 50.760142247092951],
            [0.215066287622076, 50.760977557672113],
            [0.21717799730157, 50.762063460925141],
            [0.214969959183999, 50.76353186872651],
            [0.217020058545203, 50.774500582695914],
            [0.216390949173516, 50.774724799334912],
            [0.218750580217904, 50.776119851372322],
            [0.222775401225876, 50.779752293999216],
            [0.227599516841728, 50.783293784253729],
            [0.23073721733362, 50.788649047533191],
            [0.232387861991243, 50.793454393422508],
            [0.233870103365097, 50.795762630823617],
            [0.23306319689794, 50.796815982939144],
            [0.229863147178684, 50.797710950186627],
            [0.2268943295981, 50.79944234397253],
            [0.22827433879547, 50.800080634548912],
            [0.227694856911436, 50.800876873236412],
            [0.233077926331982, 50.801470951939983],
            [0.233770769110367, 50.801267911932065],
            [0.234229878498153, 50.801669239891993],
            [0.236217258631961, 50.801499838355383],
            [0.236635764857246, 50.802239207392347],
            [0.237298134475411, 50.80211138033998],
            [0.237640233946054, 50.802528430572572],
            [0.239423620657535, 50.802158726339322],
            [0.240470093251868, 50.802850945707561],
            [0.242162539967842, 50.80313140834469],
            [0.244078600153143, 50.805382609452742],
            [0.245849645146993, 50.80549421980389],
            [0.245852379688347, 50.804752168406715],
            [0.247197993979159, 50.804102969127158],
            [0.246138074069414, 50.803103476176418],
            [0.248483899893725, 50.802181364638159],
            [0.249418688846999, 50.801402451553351],
            [0.250455253564944, 50.79884346543458],
            [0.254189915373098, 50.799037557503489],
            [0.256405637763189, 50.800076679597659],
            [0.257588409738986, 50.799264120674252],
            [0.258776203529868, 50.799380526973863],
            [0.259975131588052, 50.799992272004523],
            [0.258884615521622, 50.800863327754236],
            [0.261540924099391, 50.802028727734893],
            [0.260794761841064, 50.803124267213335],
            [0.26144020750645, 50.803499392254906],
            [0.260910298925373, 50.804047504341341],
            [0.26230387590162, 50.804865910596895],
            [0.261298211879608, 50.805291041097675],
            [0.260849870680764, 50.80608850202713],
            [0.267321978976472, 50.810566858585631],
            [0.26915124561815, 50.809677757020246],
            [0.271452444906497, 50.81006197303045],
            [0.2740134965582, 50.807641259663249],
            [0.275830484440456, 50.80486895537873],
            [0.276871801719012, 50.804771282116768],
            [0.277798853156271, 50.807343440665171],
            [0.279826646474999, 50.809313969163377],
            [0.283205801619155, 50.811658246954643],
            [0.28517316158344, 50.812498426421165],
            [0.287214120674468, 50.812954886199194],
            [0.289386007212168, 50.813068768523856],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000062",
        LAD13CDO: "21UD",
        LAD13NM: "Hastings",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.540351982701209, 50.893461314219074],
            [0.546243794372959, 50.892106082164304],
            [0.551993524414431, 50.892397800756655],
            [0.552057453633086, 50.892786751654434],
            [0.55370015242259, 50.892972068972064],
            [0.555114561403203, 50.893559007806566],
            [0.555106192746344, 50.894029588906861],
            [0.556283990214215, 50.894086538555534],
            [0.559104345906728, 50.891419987221965],
            [0.557363462499131, 50.890428317058088],
            [0.558711979584348, 50.889917561257157],
            [0.561192666724363, 50.89075985994382],
            [0.560840760685951, 50.888881492732892],
            [0.568240646061472, 50.888440923260099],
            [0.568396218624598, 50.887624418674669],
            [0.573066126420263, 50.885823410708362],
            [0.581172777597757, 50.88362063864129],
            [0.58113028961812, 50.88408658248747],
            [0.581839003846313, 50.884740974863867],
            [0.582693087920203, 50.884732864978552],
            [0.586415171893023, 50.884239374278515],
            [0.587120912284344, 50.884607782919872],
            [0.588511977330959, 50.88405256220603],
            [0.588117957408935, 50.883562120674831],
            [0.589076790715544, 50.882597346531952],
            [0.589574850156561, 50.882912785454849],
            [0.589837882535805, 50.882733357290974],
            [0.591453813697186, 50.883951279667514],
            [0.594343370758217, 50.884393441040324],
            [0.59422411268884, 50.883680147261174],
            [0.595549948585069, 50.883590397571119],
            [0.596070146586039, 50.884712104707454],
            [0.597713608196874, 50.884606268389412],
            [0.599156183948028, 50.883905864003729],
            [0.598977463186929, 50.883453834410695],
            [0.599903734643505, 50.882853064588339],
            [0.603698995386661, 50.878552795775008],
            [0.606472665452293, 50.878731009616736],
            [0.613177266168893, 50.880763050310613],
            [0.611209084860352, 50.881796493061252],
            [0.613793753971679, 50.883699377816754],
            [0.614039485099915, 50.884863133915744],
            [0.615401747499146, 50.885296711115657],
            [0.617450804345829, 50.88481723332648],
            [0.618946951573735, 50.884088396957381],
            [0.620869197126681, 50.884188241397545],
            [0.623186708727688, 50.883427404391078],
            [0.623735711971109, 50.88317760888247],
            [0.622860830164035, 50.882460644593365],
            [0.623527204246385, 50.881718919349119],
            [0.626582871968326, 50.880940512370891],
            [0.627610482165401, 50.879924397101355],
            [0.633540409500209, 50.877352319485816],
            [0.635863197442444, 50.877424883636522],
            [0.636248628750708, 50.876949367318026],
            [0.63857425462196, 50.877324023825913],
            [0.638665822183872, 50.87620215436683],
            [0.639322130520175, 50.876344709515926],
            [0.639506124982952, 50.876053626439784],
            [0.640418722696385, 50.876379258251632],
            [0.640310144058293, 50.874509088052676],
            [0.642563455365219, 50.874386113307345],
            [0.64324055549659, 50.872962254596942],
            [0.646455633182445, 50.871274871819082],
            [0.647197516342725, 50.871147408353735],
            [0.655849920676414, 50.87272264135887],
            [0.656424398386209, 50.871701289418596],
            [0.658538321653088, 50.869931605065389],
            [0.652385006663765, 50.869230698880699],
            [0.651143856305842, 50.86814900417739],
            [0.650188924601847, 50.867852301038361],
            [0.646903253853492, 50.868036631298352],
            [0.646296545906462, 50.86776437449479],
            [0.640739850716911, 50.867375816863643],
            [0.638601576621087, 50.867013159032574],
            [0.635778791832699, 50.86582855445296],
            [0.632501652135176, 50.86548161878855],
            [0.63160574678952, 50.86516994096155],
            [0.628873388029106, 50.862707729769745],
            [0.624414801093009, 50.861310562833452],
            [0.620330490009805, 50.860703519180987],
            [0.617677899625575, 50.860867409163987],
            [0.611699062388941, 50.859797080337628],
            [0.60866138161641, 50.859731935860033],
            [0.603171565549613, 50.857535836574058],
            [0.599385357378047, 50.856937599835128],
            [0.599308765835485, 50.856122621197812],
            [0.597342923009896, 50.85606027732949],
            [0.597359892060558, 50.854754822066504],
            [0.597143084165134, 50.855149104060345],
            [0.59249224312188, 50.854789354657768],
            [0.591726770366038, 50.854566216465571],
            [0.592041724294155, 50.853455614514949],
            [0.59183003096425, 50.853865962533902],
            [0.586752886079894, 50.854041624391861],
            [0.582279632236377, 50.854140737437866],
            [0.575762136597597, 50.853836021093699],
            [0.57162674063884, 50.853051219745403],
            [0.565022941843276, 50.851816898122166],
            [0.560310334577083, 50.851278240374477],
            [0.55756415371727, 50.85060814966026],
            [0.543704077948256, 50.850188081941241],
            [0.53713801486075, 50.849967715550292],
            [0.534698491399366, 50.849731136590009],
            [0.534549509077419, 50.849446557897899],
            [0.530909421918512, 50.849392480126113],
            [0.522927782767155, 50.848422219882202],
            [0.516787973404018, 50.847060914844249],
            [0.513191341133402, 50.845749753980741],
            [0.512018100098843, 50.844934066706585],
            [0.509160898513644, 50.844080851845156],
            [0.503074103120658, 50.843134122066417],
            [0.505198584138649, 50.844314424169234],
            [0.50409434307877, 50.844810331324496],
            [0.505901566945871, 50.845476666460037],
            [0.505632265936919, 50.847370336737022],
            [0.506240613445403, 50.847402238501623],
            [0.507538949465945, 50.848325923109435],
            [0.507651705977148, 50.849077214113144],
            [0.507058985424319, 50.84952257627242],
            [0.508543741217825, 50.850873965313156],
            [0.510563443311306, 50.850014738695606],
            [0.512097238599794, 50.849844105854785],
            [0.512160502391361, 50.848985594480055],
            [0.521159313366867, 50.851747847387117],
            [0.520080493243264, 50.852557254483173],
            [0.520273040700144, 50.854362732365459],
            [0.520979698575991, 50.854503062775265],
            [0.521663325423866, 50.856955402037293],
            [0.520451199898198, 50.857271211821555],
            [0.521296466437661, 50.857767415053814],
            [0.521692739726027, 50.858553938565294],
            [0.520982594265394, 50.85932480174251],
            [0.51780061915266, 50.860771435745882],
            [0.518082932633518, 50.862265576552424],
            [0.519746353067092, 50.862646990860021],
            [0.518594177178433, 50.864895239792901],
            [0.521138382868357, 50.866926941321978],
            [0.520958666190159, 50.86815313771686],
            [0.52175231397296, 50.869190106160936],
            [0.520869028722844, 50.870100521176333],
            [0.521362296196589, 50.870312018525745],
            [0.520235976817286, 50.872277303794462],
            [0.520559368791865, 50.874898422326886],
            [0.519920086965865, 50.876065287770594],
            [0.519997267024508, 50.877390264391948],
            [0.522756214395106, 50.878198580615525],
            [0.524378884826032, 50.880076548469781],
            [0.52460144560456, 50.880722013220314],
            [0.522833494278875, 50.882155254295107],
            [0.522659405377989, 50.882880352544355],
            [0.524276297835445, 50.883149385893354],
            [0.526334269557438, 50.886130490053482],
            [0.52881704776553, 50.890202341984043],
            [0.529063677679897, 50.892287246020366],
            [0.531466745089577, 50.893279361092645],
            [0.532728276365276, 50.892128610602036],
            [0.533740702659719, 50.893017742182494],
            [0.535582670541245, 50.893574045059523],
            [0.536920797755505, 50.89482843115578],
            [0.540351982701209, 50.893461314219074],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000063",
        LAD13CDO: "21UF",
        LAD13NM: "Lewes",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [0.154406277586007, 50.771680989345334],
              [0.153951030391417, 50.770879053183663],
              [0.15149590113834, 50.771341694821523],
              [0.148901302978011, 50.770742882072213],
              [0.149021333807672, 50.770143478070395],
              [0.151822115126337, 50.768807502009658],
              [0.151277435353716, 50.767476568205829],
              [0.150220320940352, 50.766443785736932],
              [0.146499780062361, 50.773337770966158],
              [0.14915701531929, 50.773939080745635],
              [0.151930742807611, 50.775129975895574],
              [0.152013322250541, 50.774858636365629],
              [0.150777988536844, 50.774335501754365],
              [0.150910377098621, 50.772676392350469],
              [0.151527160684422, 50.772270183195545],
              [0.153604611215937, 50.772230033585785],
              [0.154406277586007, 50.771680989345334],
            ],
          ],
          [
            [
              [0.145191624213285, 50.780241233004297],
              [0.143178163766282, 50.78004441586004],
              [0.143679171098597, 50.78079428638474],
              [0.145191624213285, 50.780241233004297],
            ],
          ],
          [
            [
              [0.145486020206587, 50.783015811031611],
              [0.146805528861168, 50.782058866179796],
              [0.144942701694356, 50.782241543738124],
              [0.145486020206587, 50.783015811031611],
            ],
          ],
          [
            [
              [0.049099054488842, 50.798388243696252],
              [0.051130551645302, 50.795390907588562],
              [0.048947203189895, 50.795435580808878],
              [0.048115140388218, 50.79587829583118],
              [0.047433425932759, 50.797423691373886],
              [0.048592222345139, 50.798448411203317],
              [0.049099054488842, 50.798388243696252],
            ],
          ],
          [
            [
              [0.015381175468978, 50.889704993260338],
              [0.01597506974905, 50.888805275248771],
              [0.015722872977192, 50.887706123728243],
              [0.011442174869706, 50.886692659260156],
              [0.010719199879617, 50.886907471817644],
              [0.010207558254852, 50.887936152456504],
              [0.009170663828997, 50.888489122456321],
              [0.005363854009015, 50.889139173585129],
              [0.002618175703638, 50.889204305058314],
              [0.001378397073319, 50.888841547893065],
              [0.000337179535729, 50.889296483296718],
              [0.000268508208658, 50.889552176918592],
              [0.009576831921448, 50.895217383752595],
              [0.010557096060751, 50.893247107770584],
              [0.011806349359866, 50.892736333135616],
              [0.014866989749367, 50.890079908094812],
              [0.015381175468978, 50.889704993260338],
            ],
          ],
          [
            [
              [-0.01513269296845, 51.001230201532529],
              [-0.01171895455629, 51.00036723926322],
              [-0.011498159813559, 51.001364441429857],
              [-0.010849417565896, 51.00116993139541],
              [-0.010667097894093, 51.001567030951819],
              [-0.009833624528097, 51.001326200956782],
              [-0.009972187738966, 51.001918525926726],
              [-0.009337923764163, 51.001918510709814],
              [-0.008967288935787, 51.001187329117279],
              [-0.008271099059521, 51.001135889682544],
              [-0.008670186186653, 51.000436715041964],
              [-0.007407551228929, 51.000333343634416],
              [-0.007231258969499, 51.000090213433182],
              [-0.007385350131262, 50.999554139679404],
              [-0.008183170751497, 50.999295247911711],
              [-0.007752720938034, 50.99833101315086],
              [-0.006509888041829, 50.998332292440935],
              [-0.006235356008982, 50.998752093716831],
              [-0.005794223567829, 50.998403714708857],
              [-0.006029692853638, 50.997897810629773],
              [-0.005050761371666, 50.997926961736702],
              [-0.005881829587194, 50.997490584869226],
              [-0.002682342655583, 50.99705819793401],
              [-0.000595177728647, 50.995682477012693],
              [0.001664506985712, 50.995007043764687],
              [0.001919969916592, 50.994353343547616],
              [0.002684206490409, 50.993965218600479],
              [0.002380752232517, 50.993338186370785],
              [0.003706742262494, 50.993685075117973],
              [0.003864572421032, 50.993511493194859],
              [0.003333484583511, 50.991371184208774],
              [0.003792110822188, 50.990902855640371],
              [0.003043018440316, 50.99028436862254],
              [0.0046100948831, 50.989415702421262],
              [0.00529187828395, 50.988207877846854],
              [0.006830802761494, 50.988373904729869],
              [0.006952012056024, 50.987847507090848],
              [0.005740284278806, 50.987701944312988],
              [0.006655615419623, 50.986690652956696],
              [0.008696462287454, 50.98713042325371],
              [0.008761215513566, 50.98391057138695],
              [0.009491017227112, 50.982994179952065],
              [0.007699754466168, 50.982424226496178],
              [0.008030887131536, 50.981480521624697],
              [0.011139510967415, 50.977621920372755],
              [0.014386010448187, 50.978409551847101],
              [0.01441734368355, 50.978771446103352],
              [0.01624972727506, 50.979371164148262],
              [0.01722663112579, 50.980216763674385],
              [0.020348673098978, 50.980362451301282],
              [0.022797141188579, 50.981169948214713],
              [0.025533341668744, 50.980501079840614],
              [0.026725902543551, 50.980503773644351],
              [0.027196687890709, 50.980911099283865],
              [0.027536735231944, 50.980442934142502],
              [0.028993232091353, 50.980388860499296],
              [0.029635530086501, 50.979207654482323],
              [0.028186077707529, 50.978964830786573],
              [0.029049775128076, 50.978646749660207],
              [0.029922269608343, 50.978759294673331],
              [0.030239776673135, 50.978493865316771],
              [0.029499108865024, 50.977810646858963],
              [0.030826039809779, 50.977923381195197],
              [0.031371076378172, 50.977714247939971],
              [0.031594279141541, 50.976953117892307],
              [0.032572392746428, 50.976255295316435],
              [0.031713869855291, 50.975319627443575],
              [0.03208193087191, 50.975005647994621],
              [0.034535531434377, 50.974042000222312],
              [0.036089043213149, 50.974289225743703],
              [0.037283433891091, 50.973718896099967],
              [0.037522471911154, 50.974103242874513],
              [0.037944168444273, 50.974006849637298],
              [0.038014192373777, 50.972905728615167],
              [0.038680292470754, 50.972047818457888],
              [0.038104118571857, 50.970079315458619],
              [0.037095724111962, 50.969331567057779],
              [0.037259386512995, 50.969035525369904],
              [0.038519086592602, 50.969081892920336],
              [0.037392713086648, 50.968111370043076],
              [0.038986868985963, 50.966623907905849],
              [0.040355874228427, 50.966760979360302],
              [0.041919081864094, 50.966227324136241],
              [0.044220003456623, 50.966342655276272],
              [0.046816796831815, 50.967486105243644],
              [0.048668503278665, 50.96778099713157],
              [0.048976272802877, 50.968236962484809],
              [0.04984983407029, 50.968150583369358],
              [0.050206651907168, 50.968911462589823],
              [0.051832504594706, 50.969283116747135],
              [0.052686937069595, 50.968697914784435],
              [0.052510717597131, 50.968060676788639],
              [0.0537468998258, 50.967606356598957],
              [0.053542005636366, 50.966932751032729],
              [0.054421696377688, 50.96530744528598],
              [0.05384592341493, 50.964555825598531],
              [0.054130550055586, 50.964002216803209],
              [0.053502757618441, 50.963782125902227],
              [0.053012254642599, 50.96234729659831],
              [0.051865449692332, 50.962144414891668],
              [0.05084468124834, 50.961407793723218],
              [0.049472729601225, 50.961556890359148],
              [0.048876493093029, 50.961114082017453],
              [0.04860568791729, 50.958713076957245],
              [0.050499384251554, 50.958966730800675],
              [0.0509182849125, 50.958422462979044],
              [0.050311795560033, 50.956807990665347],
              [0.050583461579052, 50.956380526259437],
              [0.049956683051904, 50.955630682469256],
              [0.050483966390077, 50.954993678720641],
              [0.049281393433445, 50.954975219026679],
              [0.05059252128615, 50.954710276129269],
              [0.050852287496132, 50.95365707280304],
              [0.050022051671147, 50.95284515009925],
              [0.050999684613047, 50.952541988703395],
              [0.049753896177557, 50.952316543648912],
              [0.050594920520618, 50.951497758307035],
              [0.050587454013946, 50.950551773678178],
              [0.049536461702589, 50.950092671892506],
              [0.04922303632453, 50.948955099683005],
              [0.048143203362397, 50.948745611545007],
              [0.049426100809095, 50.94797214369278],
              [0.048461677116374, 50.947634723861242],
              [0.048999918538486, 50.947021815829643],
              [0.048203221355195, 50.946515970026887],
              [0.049265881637449, 50.945267912254792],
              [0.050297495485054, 50.944965606720515],
              [0.049931583079495, 50.944090668297925],
              [0.051427249859246, 50.943982557994062],
              [0.050787864425321, 50.943136709172059],
              [0.051966994221911, 50.942791326473461],
              [0.052011563897356, 50.942187078706752],
              [0.053130874857805, 50.941324709060375],
              [0.053194317882959, 50.940761498876455],
              [0.053843711421817, 50.940477573587899],
              [0.05347859885896, 50.939589141169328],
              [0.055185436565903, 50.938818042568869],
              [0.055429019015727, 50.937757016099091],
              [0.054853420771414, 50.937292290410696],
              [0.05435444770222, 50.935853114774659],
              [0.054943055834874, 50.935322031083707],
              [0.054624671910027, 50.934879757248673],
              [0.055758016307972, 50.934534240789709],
              [0.056003375490072, 50.93396602613339],
              [0.055416490654889, 50.933567154701123],
              [0.054420527708768, 50.933961513670006],
              [0.05403541072274, 50.933651717953566],
              [0.055602574159671, 50.932848893138804],
              [0.056162240475071, 50.931953175104411],
              [0.055386505624037, 50.93143441814788],
              [0.056646496687548, 50.930929275794789],
              [0.056075791609521, 50.929637964440275],
              [0.055192212050986, 50.929351339929859],
              [0.056189870367592, 50.928836430279333],
              [0.055445928093268, 50.928488890278054],
              [0.056659653272323, 50.927904519204006],
              [0.056713675355205, 50.927064470694525],
              [0.057690551537921, 50.926660534276373],
              [0.057277152022123, 50.926095831207853],
              [0.060620443466872, 50.925273314116062],
              [0.063463960855517, 50.925668267955359],
              [0.067999086615439, 50.924706650243202],
              [0.068427938712579, 50.924911300286844],
              [0.072155970720853, 50.922762278127969],
              [0.078280664430176, 50.921656013611063],
              [0.082670059749464, 50.919740398174909],
              [0.086612474266018, 50.91929046513502],
              [0.088495561510546, 50.918798116933708],
              [0.08997459906273, 50.920656700898192],
              [0.095541452832945, 50.923110180285249],
              [0.101037932446207, 50.920893391519733],
              [0.105595154229768, 50.919565665345203],
              [0.110925728502485, 50.915651603666795],
              [0.112553714445986, 50.915252514138722],
              [0.116960106072617, 50.915259921952511],
              [0.121886612944711, 50.915285590023139],
              [0.121382339140145, 50.914270381475951],
              [0.119839936832669, 50.912762291146379],
              [0.112830418080047, 50.908765803254262],
              [0.111364136795749, 50.907389330153691],
              [0.110071209343475, 50.904622887845399],
              [0.104926132501199, 50.900703732086882],
              [0.103220186989986, 50.896617202833092],
              [0.101081564685137, 50.894916354359545],
              [0.101002013825912, 50.89384394691011],
              [0.098727651811985, 50.892313680247717],
              [0.095128465092579, 50.888856442006876],
              [0.091850731614729, 50.884913972084853],
              [0.096781717243496, 50.88298988029274],
              [0.098020747114281, 50.8815187359682],
              [0.096555418673964, 50.872342768511714],
              [0.096662334754742, 50.871779643428489],
              [0.098469909106079, 50.869998784348873],
              [0.099041459988502, 50.869813128191183],
              [0.101185318666391, 50.87042568555826],
              [0.102917774750952, 50.871401767630033],
              [0.105264643315508, 50.872006095115488],
              [0.105946152914625, 50.871327367066115],
              [0.114611447615057, 50.870685001669948],
              [0.116763813914225, 50.866428838882925],
              [0.116752666619735, 50.865680766309985],
              [0.120779098280995, 50.863306168119095],
              [0.123807649121878, 50.862309442774588],
              [0.122125144505207, 50.85921204588],
              [0.12248136565087, 50.858795453625895],
              [0.127299860661633, 50.856765959706529],
              [0.12480742850932, 50.854761709344942],
              [0.121786600941026, 50.854446142459174],
              [0.120416480943662, 50.853810013043812],
              [0.119180886709906, 50.854166128333794],
              [0.117587875587279, 50.851933134395303],
              [0.115015053604424, 50.849644136521405],
              [0.124497064818003, 50.846306071790231],
              [0.120992072416189, 50.841285692866897],
              [0.118845251112154, 50.841775288495754],
              [0.118591460358511, 50.840938984454702],
              [0.116132750066806, 50.839024775553185],
              [0.119494228278847, 50.837947462235753],
              [0.11900464313858, 50.837423927244721],
              [0.117418421842999, 50.83592379453718],
              [0.114789418613848, 50.834947996221295],
              [0.112208600445338, 50.83236318846965],
              [0.111260003503655, 50.83082264648467],
              [0.107568526909624, 50.831439812964376],
              [0.105770648101992, 50.829168088658307],
              [0.101055302054332, 50.825482943586692],
              [0.099904243811121, 50.822962943001798],
              [0.097906091283173, 50.82032506174064],
              [0.100876149768054, 50.819625857534788],
              [0.103220422563557, 50.818525049682336],
              [0.104827651764799, 50.816064151902815],
              [0.108881509593602, 50.812928558698303],
              [0.109396015181149, 50.809507924938366],
              [0.111339929701069, 50.804894929110127],
              [0.111553917054797, 50.802211812597605],
              [0.111564897768382, 50.801983172111001],
              [0.114464527322979, 50.79739597855955],
              [0.119202020306948, 50.794239512858837],
              [0.121822074356461, 50.793092832670546],
              [0.130522603561211, 50.791706557620287],
              [0.135598888262857, 50.791723434302519],
              [0.138488878381564, 50.791124551695091],
              [0.140468056656003, 50.791275313720114],
              [0.143701066292089, 50.79268641904423],
              [0.144904904036482, 50.790745902421257],
              [0.144089156790161, 50.78981835058584],
              [0.147186027345868, 50.787825001291672],
              [0.146612593484202, 50.786642976497696],
              [0.147644200712297, 50.785408016684094],
              [0.145492499749222, 50.783529239626908],
              [0.141597625063406, 50.778859286301206],
              [0.141711783920463, 50.778470454811718],
              [0.142790608603729, 50.777990151178884],
              [0.141659033663093, 50.77706748601544],
              [0.143643926702299, 50.775184578820586],
              [0.145765822092506, 50.774067189647653],
              [0.146439459498328, 50.771896268232815],
              [0.1468509234685, 50.771899485903475],
              [0.149929295398788, 50.766066013790038],
              [0.147936967283197, 50.763778719463133],
              [0.147230961976292, 50.760933473954793],
              [0.14730552730193, 50.760270152295945],
              [0.148451170952964, 50.758844205326042],
              [0.146073294580304, 50.758813349071339],
              [0.141835434901859, 50.756709365584918],
              [0.139347071161363, 50.756367416024538],
              [0.137809684948036, 50.755766944839344],
              [0.123930447106896, 50.758116172945705],
              [0.120042011876072, 50.759045853191239],
              [0.118432007045493, 50.759942122287555],
              [0.115899690013842, 50.760637459093992],
              [0.1124532926069, 50.762763133668741],
              [0.11164456926107, 50.762784977887129],
              [0.111644561808663, 50.763068283347145],
              [0.111092421089768, 50.762899304540454],
              [0.111126738547284, 50.763220661916193],
              [0.110175476055754, 50.763762221103534],
              [0.109193129006285, 50.763928393163269],
              [0.108806939599333, 50.763634085589153],
              [0.10896087588133, 50.763903814010824],
              [0.108382215318665, 50.764185892165919],
              [0.107955503166945, 50.763904905876473],
              [0.104346711552883, 50.7660908320846],
              [0.091783899893759, 50.772843672233556],
              [0.076745315501467, 50.779604170371286],
              [0.071307970973681, 50.781465449093545],
              [0.066745458781671, 50.782488942237883],
              [0.061371510868056, 50.782872756803265],
              [0.058211770949357, 50.782314294508836],
              [0.058415895686547, 50.782698321563927],
              [0.055733733709658, 50.788224161367907],
              [0.055048844020329, 50.788378782531304],
              [0.054660684662904, 50.789201352166266],
              [0.052829601047393, 50.794462542780494],
              [0.049850202052778, 50.798560314635111],
              [0.050075099795934, 50.799418857678532],
              [0.04824463129434, 50.800852235887497],
              [0.048141192564632, 50.803419953423138],
              [0.047777435686312, 50.804416545925584],
              [0.045334914285973, 50.80640114033109],
              [0.044606864125728, 50.808418596921108],
              [0.043308618473493, 50.809569154261922],
              [0.042089306864817, 50.809862116656312],
              [0.038561472876957, 50.809655814786417],
              [0.036329713379957, 50.810300072124562],
              [0.033923686916237, 50.810495843075337],
              [0.030959850046015, 50.814325723486327],
              [0.028043305322665, 50.814768591097256],
              [0.027140159958227, 50.815155727745577],
              [0.026660201663614, 50.81603825065374],
              [0.02680225588398, 50.816892877453704],
              [0.028504276391741, 50.821901534202532],
              [0.026169185008817, 50.831031085683506],
              [0.026370099383063, 50.83294863530768],
              [0.027109964910922, 50.834324398044863],
              [0.027945467276011, 50.835022172932554],
              [0.02987431368486, 50.835615484209498],
              [0.030487858832014, 50.836139028929864],
              [0.03194035827058, 50.839595173288657],
              [0.03322110763498, 50.841448031086195],
              [0.033116896490797, 50.843723434993549],
              [0.033956070971975, 50.845087530631488],
              [0.034625727745257, 50.845427505117343],
              [0.036702616951509, 50.845102572928376],
              [0.037797790033199, 50.845394629167053],
              [0.038851721601447, 50.847151557885276],
              [0.04398620354239, 50.851646671220138],
              [0.047088102457431, 50.853473756408491],
              [0.048946265084013, 50.853889922711964],
              [0.047032370537482, 50.853563771066561],
              [0.044146778509958, 50.851907371261845],
              [0.038450724579343, 50.847240406501953],
              [0.03755366490847, 50.84856194959594],
              [0.034977927786345, 50.850321984949446],
              [0.030847205871496, 50.855962797057536],
              [0.029554714240648, 50.856759634670382],
              [0.02742230069795, 50.857422660281358],
              [0.023680113835339, 50.861011310694721],
              [0.021197753364176, 50.862230696237766],
              [0.020496601374237, 50.86336793607893],
              [0.020457835211522, 50.86431203382898],
              [0.021005519943089, 50.864883534707943],
              [0.022541715231424, 50.865374948776847],
              [0.023712666225625, 50.865865482727656],
              [0.024138033942134, 50.866794336724176],
              [0.024134973973981, 50.870037473888935],
              [0.02295587434053, 50.871757705416336],
              [0.016772362149877, 50.873322591011778],
              [0.016190150040615, 50.873961301328912],
              [0.016158350439594, 50.875760562702283],
              [0.015606098698734, 50.876435625615485],
              [0.013716506260045, 50.877150857459931],
              [0.010085616624246, 50.879223511268812],
              [0.00714141561257, 50.879551185979757],
              [0.005993827416476, 50.879939652513386],
              [0.004851679154208, 50.881316404117214],
              [0.001017449607257, 50.883670163139996],
              [0.000842726640112, 50.884801846752772],
              [-0.001697318128662, 50.886078377853664],
              [-0.002413515577029, 50.886984594605551],
              [-0.000149418472959, 50.889089876933618],
              [0.001391389076884, 50.888647064976716],
              [0.002708002436571, 50.889011201628293],
              [0.005259743753552, 50.888933211874999],
              [0.008925967188718, 50.888320658791983],
              [0.009842252742928, 50.887905570369256],
              [0.010481339069451, 50.886732598145116],
              [0.011368912789153, 50.8864771776026],
              [0.014584528177088, 50.887257215281984],
              [0.015787122239184, 50.887547627061089],
              [0.016239071641176, 50.888037163106937],
              [0.016104439911866, 50.88923293177487],
              [0.014931206010856, 50.890282952780289],
              [0.014492922487824, 50.890637669044203],
              [0.011932097058805, 50.89288255881393],
              [0.010721743455405, 50.8935041843812],
              [0.0099934040906, 50.895413464326346],
              [0.011471627450153, 50.896782894175928],
              [0.012825419209572, 50.896847692174106],
              [0.014915119621125, 50.89679634715155],
              [0.016905872723585, 50.897713479040391],
              [0.018191648840332, 50.89702663165307],
              [0.019074720153035, 50.897058125322808],
              [0.021376230920452, 50.899237893535989],
              [0.021493302592954, 50.900857399760064],
              [0.020772824352266, 50.901101009218522],
              [0.020055958775885, 50.901362538707019],
              [0.020081393965697, 50.90207625026531],
              [0.020082480565533, 50.90210674379977],
              [0.020233521693011, 50.902185715352545],
              [0.022265331489573, 50.903247996938077],
              [0.022944123261445, 50.903584284230945],
              [0.024217449658901, 50.904182762655914],
              [0.024818763802467, 50.904896312253896],
              [0.025553330042079, 50.904583183509189],
              [0.026961174242296, 50.904806066304133],
              [0.02752957674097, 50.90436810224314],
              [0.029827528981376, 50.903766966779294],
              [0.030943760722716, 50.904315941572008],
              [0.032675046476715, 50.904192272206956],
              [0.033447184385378, 50.905167213197338],
              [0.034472016700488, 50.905477619296143],
              [0.034429408066599, 50.907273472074756],
              [0.03344531520651, 50.907866206037781],
              [0.033769714824992, 50.908618709300818],
              [0.033145134854397, 50.90917100054557],
              [0.033116004054773, 50.910461180364287],
              [0.03182404891134, 50.911956815688136],
              [0.030273465355392, 50.912480237694716],
              [0.029975235716676, 50.91301514293027],
              [0.030989211290061, 50.914183751112489],
              [0.032760047385819, 50.915318488132876],
              [0.034066897582625, 50.91517967476571],
              [0.035513355967496, 50.915263315315968],
              [0.036222289786291, 50.914589027532926],
              [0.038430360825976, 50.913810322358245],
              [0.039735168771218, 50.914011468717845],
              [0.040635286559329, 50.913384176436118],
              [0.041537782491645, 50.913234392220687],
              [0.042472197559614, 50.913456374944026],
              [0.042491654397344, 50.913770808299383],
              [0.041576618251287, 50.913341635441689],
              [0.040718351420716, 50.913461867414888],
              [0.03986869484542, 50.914104466895161],
              [0.038504846431245, 50.91391964206921],
              [0.036929720257853, 50.914564993696835],
              [0.037773497234146, 50.914861443199527],
              [0.03761225042768, 50.91507830395129],
              [0.036408653459797, 50.914654127358077],
              [0.035770840167214, 50.915328075338977],
              [0.037013623736583, 50.915313589905885],
              [0.03728905044377, 50.91562625487834],
              [0.034387536628264, 50.91528588301054],
              [0.034058688345049, 50.915247301627893],
              [0.033808024142822, 50.915297495667438],
              [0.032828851527664, 50.915493563036598],
              [0.030656892405527, 50.914164350702372],
              [0.02987428845587, 50.913245333872219],
              [0.030021791561916, 50.912548468761919],
              [0.031702477316894, 50.911843814574539],
              [0.032933326918905, 50.910414897573432],
              [0.032899126046668, 50.909140210459228],
              [0.033536736047269, 50.908593089664834],
              [0.033251406195771, 50.907790440786989],
              [0.034182825735118, 50.907098798155253],
              [0.034315146912891, 50.905990287018433],
              [0.034261996076305, 50.905554127371751],
              [0.033053406831446, 50.905074244250919],
              [0.032521462983392, 50.904323554281781],
              [0.030842289361133, 50.904435522416883],
              [0.029630519611995, 50.903881013117861],
              [0.027054836535288, 50.90493214792906],
              [0.025778313791279, 50.904752854202236],
              [0.024875371598698, 50.90505361637026],
              [0.023713268425943, 50.904101569766411],
              [0.022872848451365, 50.903679052175356],
              [0.022190454047502, 50.903358115879428],
              [0.021312816776231, 50.902962313163336],
              [0.020003979662292, 50.902123692648836],
              [0.019828660667282, 50.901894392319846],
              [0.01998267100711, 50.901147962368881],
              [0.021157053390158, 50.900777784243878],
              [0.021408511759973, 50.900147479926524],
              [0.021175774605208, 50.899305218990044],
              [0.02005452668655, 50.898207633035163],
              [0.019222168269077, 50.89750794926146],
              [0.018483563508382, 50.897068350102586],
              [0.016834275916002, 50.897866706864768],
              [0.014782669825551, 50.896923643618457],
              [0.013777087038992, 50.89718382285244],
              [0.011280645654015, 50.896938175885964],
              [0.010499210323982, 50.895997426002182],
              [-0.000798344533532, 50.889176539997486],
              [-0.002731094047531, 50.887437907162258],
              [-0.002095086933754, 50.886052811091666],
              [0.000316793808861, 50.884738014556653],
              [0.000590282331627, 50.883588450446027],
              [0.005831431771554, 50.879799446469747],
              [0.010419503176233, 50.878822943199445],
              [0.013042889903103, 50.877220937094918],
              [0.015478548832217, 50.876213888969566],
              [0.015888376981611, 50.875631222557324],
              [0.015790200409271, 50.874443070548658],
              [0.016550590910035, 50.873222997493393],
              [0.022447651016281, 50.871574951436621],
              [0.02336344684536, 50.870699290642769],
              [0.02377763149775, 50.869367354793582],
              [0.023649391407886, 50.866537501129351],
              [0.022494693072713, 50.865634778877208],
              [0.02077292922896, 50.865091717047008],
              [0.020034020827552, 50.864271705356678],
              [0.020453422303389, 50.862632109457479],
              [0.021419406452398, 50.861769982649903],
              [0.02324903277502, 50.860966623277584],
              [0.027046028518923, 50.85723223781374],
              [0.030121936619457, 50.856046467559928],
              [0.033869195796416, 50.850994253523552],
              [0.035634637655943, 50.849220503073219],
              [0.037303981694714, 50.848178684658478],
              [0.038067136658022, 50.846718282669606],
              [0.037221985466663, 50.845579161030905],
              [0.034505617737342, 50.845768659109538],
              [0.033473926274039, 50.845302788143321],
              [0.032620255973538, 50.843833716322877],
              [0.032649852654067, 50.841475072956875],
              [0.031180747477976, 50.839372767578901],
              [0.029957364863781, 50.836383893725028],
              [0.029306401007285, 50.835881682471552],
              [0.02740714580206, 50.835381381277308],
              [0.026052662060764, 50.833718606941538],
              [0.025570229720224, 50.831530740279049],
              [0.025796782306104, 50.829547308290742],
              [0.027898539319629, 50.821848211054103],
              [0.026325996360528, 50.817533405187376],
              [0.026317287941339, 50.815364287918733],
              [0.027279205227621, 50.814596604104089],
              [0.030291411087697, 50.813839111269878],
              [0.034075703406043, 50.809583932394631],
              [0.035809626302539, 50.809895441757355],
              [0.038479482955393, 50.809272318465979],
              [0.042043239752003, 50.809391654456213],
              [0.043077754081426, 50.809125313061557],
              [0.043846910338216, 50.808438211352758],
              [0.044731498313873, 50.806071758755458],
              [0.0461744730648, 50.804630847247616],
              [0.046931324822781, 50.803145300091984],
              [0.047196796928152, 50.800971361917931],
              [0.04808608992374, 50.799303607192293],
              [0.047775481184075, 50.798755948667925],
              [0.046353770247606, 50.798014638735317],
              [0.046627916335503, 50.797760702942277],
              [0.046305805543438, 50.797403908789818],
              [0.046844529122112, 50.796807166966666],
              [0.046350354942364, 50.796864404566442],
              [0.04809582153738, 50.795339012883133],
              [0.05159959468821, 50.79479267043731],
              [0.052507841643784, 50.792980646001027],
              [0.052511157249146, 50.790529800706402],
              [0.05398129359927, 50.787750041429099],
              [0.053332852347162, 50.787716491523753],
              [0.053603751782753, 50.787876306649714],
              [0.053287759487618, 50.788144487131618],
              [0.052709928861986, 50.787779621039384],
              [0.052229707329133, 50.786857222640059],
              [0.052434973398647, 50.786101735284227],
              [0.054307243351494, 50.785055177143796],
              [0.054754625890245, 50.785260446755679],
              [0.054428356661023, 50.786426385257549],
              [0.05720105713828, 50.781653101685485],
              [0.053874025023372, 50.781482405092525],
              [0.052461246368945, 50.781129541983319],
              [0.052356402707249, 50.780711380088022],
              [0.054884699620634, 50.77771061656069],
              [0.058439898421867, 50.775814990845959],
              [0.055252472095877, 50.777242083074718],
              [0.052696187708696, 50.779788938632301],
              [0.044591282402916, 50.781236309708028],
              [0.035856768499668, 50.781166075796669],
              [0.029650921218884, 50.782979454792134],
              [0.024706762961969, 50.784059201172219],
              [0.021234757759061, 50.785231947654239],
              [0.016002128779345, 50.785288343377957],
              [0.01138393845199, 50.786670368709466],
              [0.00772240282147, 50.787041044307628],
              [0.005269633749085, 50.787876485016909],
              [0.004556530497696, 50.787789813704173],
              [0.002489315088699, 50.788386531267257],
              [-0.000101052296903, 50.788694493824863],
              [-0.003142756984236, 50.789931976796254],
              [-0.012635386002345, 50.792061029295247],
              [-0.017614754600146, 50.79339318813831],
              [-0.020583161892029, 50.793864506356407],
              [-0.021620013808702, 50.794210366123423],
              [-0.021520069680246, 50.794493768782857],
              [-0.023069235462188, 50.794973316335145],
              [-0.023336022870912, 50.794670256094221],
              [-0.024533094010617, 50.795390241590233],
              [-0.025641581004207, 50.795317276284244],
              [-0.026655625044631, 50.796105190820334],
              [-0.032025314257584, 50.797455023362346],
              [-0.034385452622402, 50.798396880783578],
              [-0.035320311901899, 50.798066377850759],
              [-0.034980927728287, 50.79844378830164],
              [-0.035891815469689, 50.798786498105407],
              [-0.036073741387308, 50.798610588742697],
              [-0.037012699256307, 50.79916421270979],
              [-0.037239337396218, 50.798971066836799],
              [-0.038230787537412, 50.799525564164426],
              [-0.037124474769349, 50.800933336897444],
              [-0.034418908097865, 50.802469749305395],
              [-0.031793848824376, 50.804551571070633],
              [-0.028107444487754, 50.808372746800288],
              [-0.020651790478541, 50.812894365315984],
              [-0.016030748322282, 50.814880793466315],
              [-0.016614544291975, 50.816280233075673],
              [-0.019330938912834, 50.819654918389595],
              [-0.020456845030956, 50.8218360895807],
              [-0.026367874023345, 50.818023134653039],
              [-0.031031354658628, 50.817421127794162],
              [-0.033298624418258, 50.818805714206512],
              [-0.039713622047074, 50.821535316658249],
              [-0.042181559587956, 50.821459845221348],
              [-0.045996711983674, 50.821830492167727],
              [-0.045512328276556, 50.822379978710153],
              [-0.045848579768696, 50.823542183962374],
              [-0.045142942572823, 50.82340535113206],
              [-0.042486165985352, 50.824259258034125],
              [-0.042195654728005, 50.824822776212876],
              [-0.039132075609391, 50.82863855502675],
              [-0.038884489346532, 50.834681661848009],
              [-0.03810438633372, 50.836418691691499],
              [-0.039020371425594, 50.837877556082908],
              [-0.036260032278563, 50.839585769802191],
              [-0.036870765075021, 50.841244565078242],
              [-0.04261952146181, 50.842728854560654],
              [-0.043404171660602, 50.843326595483859],
              [-0.043484561970981, 50.844291151334211],
              [-0.045613781468461, 50.844973471364632],
              [-0.048852710691373, 50.844390937984869],
              [-0.053616858089471, 50.844293337742414],
              [-0.058388544581335, 50.842884413067978],
              [-0.060382251794168, 50.841974153257624],
              [-0.062649074267626, 50.841532460602124],
              [-0.073469996732933, 50.841250269815603],
              [-0.072417244931709, 50.844047849797647],
              [-0.07080700051725, 50.845824434301221],
              [-0.072526001386406, 50.848336848899017],
              [-0.075289625032972, 50.85070548854808],
              [-0.078860096371448, 50.853810423454213],
              [-0.080042180822517, 50.860937425666293],
              [-0.081697722328693, 50.860693049974159],
              [-0.082831902997178, 50.863077871070644],
              [-0.083264041575351, 50.863178501931039],
              [-0.08416221607935, 50.866921041918843],
              [-0.082892477806657, 50.867455985515811],
              [-0.084667254682238, 50.868988834241215],
              [-0.0861293923959, 50.871078619001928],
              [-0.084955374682227, 50.87312515567848],
              [-0.094387438111898, 50.876786991390333],
              [-0.094828084527344, 50.876543268662019],
              [-0.097546881787781, 50.877951024300458],
              [-0.0985032236402, 50.877505246697034],
              [-0.097990653528624, 50.876548087217266],
              [-0.104490070187599, 50.874609623302028],
              [-0.103522449185168, 50.872779928248384],
              [-0.104942808090079, 50.872348832676231],
              [-0.111388433385313, 50.873161102586032],
              [-0.115340601071982, 50.874234054576569],
              [-0.120117688199162, 50.873568245185169],
              [-0.121827315139331, 50.873433889822856],
              [-0.122261960988962, 50.876129014163538],
              [-0.124327646171783, 50.876191909452864],
              [-0.1242373311027, 50.877981044809047],
              [-0.1270690278464, 50.878156926045477],
              [-0.129530894914472, 50.87872971606064],
              [-0.135311366014438, 50.878110836905137],
              [-0.135851233263897, 50.880754524285109],
              [-0.135565026823803, 50.885146818453116],
              [-0.135037641736283, 50.886640287432186],
              [-0.134006343209697, 50.888183256451477],
              [-0.133397609007204, 50.893985941755638],
              [-0.131903244856179, 50.896363265072523],
              [-0.130890204066702, 50.900283445129787],
              [-0.130601291352563, 50.907852127661194],
              [-0.13011922651844, 50.909517170884534],
              [-0.130797610333981, 50.909769057066754],
              [-0.130820742773396, 50.910327914635296],
              [-0.13032280382045, 50.911323598711846],
              [-0.129735640448944, 50.911386140058312],
              [-0.129630481939634, 50.914257830468856],
              [-0.128414095539132, 50.918940056841159],
              [-0.126723145426846, 50.92111900320824],
              [-0.125190386775874, 50.924491183701257],
              [-0.122505453675633, 50.928305279875765],
              [-0.120754755309224, 50.929903109050557],
              [-0.119900457148677, 50.932878739594493],
              [-0.120474126587893, 50.938700363340331],
              [-0.120091260842184, 50.940535132795503],
              [-0.117966408432942, 50.940598030813952],
              [-0.116895180325833, 50.941076291584451],
              [-0.117886019676606, 50.941496067336502],
              [-0.12004306109523, 50.941341956963569],
              [-0.120002846438294, 50.941703740275585],
              [-0.118085582745781, 50.945261189437844],
              [-0.111125740276239, 50.943681999959473],
              [-0.10894263020084, 50.943808544021358],
              [-0.105453301223744, 50.943115252946136],
              [-0.104715518905309, 50.945610620881986],
              [-0.104203395918909, 50.947281362003942],
              [-0.105012749134976, 50.949293715985675],
              [-0.105263374751513, 50.951743067539724],
              [-0.106328114196613, 50.95399068213203],
              [-0.106238543410877, 50.954688010291449],
              [-0.108148499791878, 50.956917833807275],
              [-0.10785614672994, 50.957910455332254],
              [-0.108258894351959, 50.958021302733499],
              [-0.108375354591734, 50.958765137264265],
              [-0.108048240610774, 50.960578284872426],
              [-0.106383454762119, 50.962927333388258],
              [-0.105544223775373, 50.964408409507911],
              [-0.109386277949992, 50.965047142464584],
              [-0.109121520911601, 50.965464641726861],
              [-0.109424673741959, 50.965715066774422],
              [-0.108581255339917, 50.966984753579816],
              [-0.11041199474621, 50.967812094431167],
              [-0.11269543319803, 50.967346292785749],
              [-0.116335341384821, 50.967181125290807],
              [-0.115150397313225, 50.969784449840979],
              [-0.115735926076723, 50.969851455252005],
              [-0.116133528486103, 50.970374084016072],
              [-0.116344880389524, 50.973458591381501],
              [-0.115241570755205, 50.976082117676654],
              [-0.114484323667879, 50.975926902047625],
              [-0.114088741572105, 50.976514071474405],
              [-0.111778805716931, 50.976358038093899],
              [-0.111668012958326, 50.976912031562975],
              [-0.110006439726712, 50.978224254938539],
              [-0.10999031258868, 50.978831939656786],
              [-0.108840165036513, 50.980246850864184],
              [-0.104437583382527, 50.98029329400778],
              [-0.102728191988864, 50.980848303681768],
              [-0.10169094505917, 50.980082309211973],
              [-0.102180560605991, 50.979534480878726],
              [-0.101838756598074, 50.979392228453541],
              [-0.09813372046255, 50.979383217435284],
              [-0.097367137911558, 50.97879426210843],
              [-0.094214993203675, 50.979374199462107],
              [-0.093093925554806, 50.979232693169592],
              [-0.092830751557112, 50.980196974068562],
              [-0.093166015963616, 50.980602649748221],
              [-0.092308862073512, 50.983301929344165],
              [-0.091881766385694, 50.983294053434356],
              [-0.086858619061994, 50.987857819345685],
              [-0.086693979766004, 50.988916330890717],
              [-0.085316993447319, 50.989656398093054],
              [-0.082417559802847, 50.989096216088797],
              [-0.082033110532211, 50.989677166031569],
              [-0.083185312489852, 50.989829180664117],
              [-0.083846710445375, 50.990723173865192],
              [-0.078791746407979, 50.990876662858028],
              [-0.076811265737357, 50.991457414014889],
              [-0.075489186811981, 50.991105584548222],
              [-0.073645734296258, 50.991679546587036],
              [-0.072038156803169, 50.991305881866239],
              [-0.070551513713169, 50.99134968324676],
              [-0.068553879483837, 50.989560275090554],
              [-0.06721492269393, 50.989068675732042],
              [-0.065722578325217, 50.9857892909802],
              [-0.065585407068973, 50.983673589298689],
              [-0.066262100421766, 50.982817838700235],
              [-0.065212352633497, 50.982125954776826],
              [-0.06506983528123, 50.9811676035076],
              [-0.063794858616444, 50.980822714527406],
              [-0.063598585611678, 50.97999297383506],
              [-0.064059103358513, 50.979641774338724],
              [-0.063470383224334, 50.979068132729061],
              [-0.061199238983077, 50.978391926746234],
              [-0.060005970531958, 50.979507070650513],
              [-0.058458131654604, 50.980002064184696],
              [-0.056543589592807, 50.979998101235935],
              [-0.054218845750288, 50.97941259934619],
              [-0.052303035165974, 50.979541647007231],
              [-0.051821015495814, 50.980030040074553],
              [-0.050160785066952, 50.980382753204225],
              [-0.048817390254398, 50.981129248770671],
              [-0.047011694365734, 50.981212384840262],
              [-0.045426160567493, 50.980496971153705],
              [-0.044480091672927, 50.979617773176912],
              [-0.039612121580951, 50.979314029193219],
              [-0.036743965091313, 50.979796459490949],
              [-0.036742154911705, 50.979062571042363],
              [-0.037895307252855, 50.978844524991068],
              [-0.037097672200232, 50.97738858715848],
              [-0.036326612181543, 50.977194858849749],
              [-0.034691557895103, 50.977456942509825],
              [-0.032051496595182, 50.979587981637636],
              [-0.03217850970351, 50.979886003367902],
              [-0.029344068798613, 50.979232958381289],
              [-0.02463041019205, 50.980029283005628],
              [-0.023558971462715, 50.982246008870383],
              [-0.020102027929823, 50.985407983919501],
              [-0.020256702726578, 50.986193028803214],
              [-0.019561205618279, 50.987268536024573],
              [-0.019370574287291, 50.98866736925239],
              [-0.019177799599869, 50.989078694238657],
              [-0.018800559533693, 50.988969771624859],
              [-0.01885805563995, 50.992337861884465],
              [-0.018172011401955, 50.995295830801936],
              [-0.018510352932255, 50.995917615828787],
              [-0.01763329212309, 50.998974864155564],
              [-0.017816073473344, 51.000170484441689],
              [-0.016436082116573, 51.002517706045474],
              [-0.015568756262881, 51.002000246590434],
              [-0.014467379404278, 51.001824147149733],
              [-0.015157335208827, 51.001521105533456],
              [-0.01513269296845, 51.001230201532529],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000064",
        LAD13CDO: "21UG",
        LAD13NM: "Rother",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.397596593110617, 51.08364807669917],
            [0.400233510165652, 51.082398673962444],
            [0.400447184974735, 51.082554373995414],
            [0.404051900069515, 51.081247225718769],
            [0.406585366721443, 51.079688620352712],
            [0.405040228446504, 51.077921676007833],
            [0.405535543440031, 51.077231550794544],
            [0.405408107994961, 51.075435417792022],
            [0.400630520714408, 51.072692532928592],
            [0.40088502927593, 51.071183543690516],
            [0.402383719921507, 51.070462917104848],
            [0.405788693885989, 51.069506972742644],
            [0.406578269975784, 51.068905217335221],
            [0.408977587115901, 51.069395403571143],
            [0.409200054100618, 51.068236912743274],
            [0.411053611230269, 51.067097838751167],
            [0.411283429555986, 51.065889725513465],
            [0.41410012376739, 51.065487999945937],
            [0.415877725530388, 51.066622252975328],
            [0.416951830077858, 51.067896047473369],
            [0.417749745129272, 51.067895728519957],
            [0.416233839892769, 51.065292799271354],
            [0.416218015624988, 51.063892790047944],
            [0.418238890794012, 51.062629621418544],
            [0.421026270982746, 51.062847108280714],
            [0.421988293749982, 51.063668092556668],
            [0.422656564940916, 51.062853787810639],
            [0.425228552625246, 51.062991098548338],
            [0.425544681978226, 51.064069191638708],
            [0.426518335859314, 51.063009288571706],
            [0.428228022974243, 51.063141953858917],
            [0.428541023885128, 51.062522968561964],
            [0.430968128799396, 51.062047086498097],
            [0.432505091609107, 51.061063549781011],
            [0.438344618535371, 51.059747443814423],
            [0.439903192036853, 51.058962120795179],
            [0.440788829322377, 51.057919222405786],
            [0.442716384623523, 51.057638801692306],
            [0.442758942328711, 51.058094799846749],
            [0.444248577066111, 51.058414405573259],
            [0.446829414842578, 51.058127443057444],
            [0.447736820821189, 51.05750045062527],
            [0.447961135404502, 51.056585571659596],
            [0.449571848456923, 51.056711902434216],
            [0.451652159768993, 51.056380462958664],
            [0.453677878330012, 51.0569603128151],
            [0.457282658894725, 51.055261155105931],
            [0.463238377837483, 51.054789472177312],
            [0.463029089666424, 51.054099556313162],
            [0.463782120602922, 51.052344259369697],
            [0.466369957624636, 51.05080116534382],
            [0.468364094397144, 51.050546792952389],
            [0.471372395144255, 51.049080143211832],
            [0.471147673105883, 51.04879889062348],
            [0.471872452034279, 51.046975781183384],
            [0.471080650952181, 51.045744177501135],
            [0.466061490096009, 51.042739245799744],
            [0.466305738792047, 51.042127000495178],
            [0.467286744631808, 51.041768113938808],
            [0.467641832957667, 51.040960155370549],
            [0.468334293414475, 51.040621744849695],
            [0.466969562885196, 51.03786605755402],
            [0.466864736691509, 51.036752126180474],
            [0.466380992743708, 51.036775831760096],
            [0.466303725414633, 51.03642220341289],
            [0.466614062386836, 51.036034308646606],
            [0.46637578643717, 51.034314306131421],
            [0.467349208839424, 51.033010316290337],
            [0.468017025282927, 51.032586085295407],
            [0.469479272328686, 51.03248322955212],
            [0.469260947723319, 51.031998575343238],
            [0.470273493229994, 51.031942090114143],
            [0.471393690900583, 51.030772567972498],
            [0.476351858686448, 51.029548712602065],
            [0.477058681812255, 51.029751378843621],
            [0.477303632721164, 51.029156181444137],
            [0.477692170381921, 51.02932781926792],
            [0.478046403695333, 51.029070273105518],
            [0.478192869203619, 51.029382853148391],
            [0.478721980933649, 51.029088316914859],
            [0.47965978143582, 51.029182635300437],
            [0.480466166700331, 51.029593625686779],
            [0.480808299123039, 51.029376800784874],
            [0.480908823071888, 51.029790186084767],
            [0.481515419305288, 51.029824968618129],
            [0.482862788618889, 51.030643566339428],
            [0.48525137712338, 51.030928244119707],
            [0.486235958683509, 51.030669851098089],
            [0.486564999770007, 51.030928168603594],
            [0.489687839387901, 51.030594527783478],
            [0.493312023412844, 51.031072150769184],
            [0.496506487018613, 51.03053263524091],
            [0.496639079255544, 51.029894827085599],
            [0.497426614896145, 51.029925659401812],
            [0.498643472358052, 51.02926735906388],
            [0.498490837525477, 51.029054768735676],
            [0.499358675365052, 51.029199892459566],
            [0.502104772174801, 51.028071715728053],
            [0.504452103779568, 51.027988130985264],
            [0.506865618261628, 51.026488324406245],
            [0.508426755729096, 51.02412806232163],
            [0.509590269474611, 51.023675853732513],
            [0.513362352520389, 51.023519194634716],
            [0.514049134543971, 51.022954880382301],
            [0.515508273376184, 51.02253852025607],
            [0.51595593775811, 51.02269436760934],
            [0.518296292122318, 51.02238220456811],
            [0.522840771906056, 51.02316374604159],
            [0.525973320013977, 51.022786646372587],
            [0.526206882241312, 51.022413739451132],
            [0.526910096328565, 51.022521745560546],
            [0.526716395592906, 51.022132897390719],
            [0.5274179967023, 51.021660820831485],
            [0.528917491495061, 51.021438588814497],
            [0.529661472302362, 51.021719280300623],
            [0.531279813988302, 51.021506135951093],
            [0.531808532051447, 51.021813964052299],
            [0.533828919138456, 51.021991403415456],
            [0.534725949882642, 51.021704816539312],
            [0.53626898610979, 51.021915057380014],
            [0.537570754872799, 51.021676321165685],
            [0.537668681915668, 51.020910599618858],
            [0.5386171852331, 51.020705609236565],
            [0.53936864573208, 51.019869915991862],
            [0.539017786599945, 51.019633805408581],
            [0.539926432764603, 51.018517674425894],
            [0.539761521332821, 51.017926754285789],
            [0.541024497062769, 51.016928824925976],
            [0.540700791549641, 51.014346480319489],
            [0.541281907143426, 51.013240160019819],
            [0.54128399008969, 51.011661658056354],
            [0.541877055068416, 51.011009273924358],
            [0.54512717362926, 51.010375460482535],
            [0.547667337943744, 51.009171531882387],
            [0.54945369061783, 51.009067791146926],
            [0.551805167202208, 51.008247439900941],
            [0.554684349617719, 51.00828882297337],
            [0.557059550705654, 51.007876177152269],
            [0.558779916614396, 51.007222400387739],
            [0.56165524752989, 51.007789849691072],
            [0.563294573549539, 51.007529026517091],
            [0.564776771856062, 51.006720293068639],
            [0.566785506680563, 51.004269337810207],
            [0.568351411372393, 51.003817582229452],
            [0.571983621982513, 51.003457923726806],
            [0.573117997560385, 51.004482546760904],
            [0.575528156352292, 51.005221797208627],
            [0.578045355988097, 51.004471008305856],
            [0.579642397592378, 51.004453728460163],
            [0.581757476812828, 51.005242540748419],
            [0.585295612278689, 51.005856812940593],
            [0.586733093133717, 51.008427881077573],
            [0.587285549706643, 51.008748425345296],
            [0.5934941495339, 51.010049769710996],
            [0.597316903494592, 51.008935881260228],
            [0.601534887865247, 51.009894307719783],
            [0.602514180495428, 51.010451710942291],
            [0.603580856806027, 51.011881389093872],
            [0.605360941485115, 51.012072839438915],
            [0.609352451197875, 51.012366873468743],
            [0.611637187746085, 51.01202349146299],
            [0.615115859017035, 51.013616760338167],
            [0.616015689345706, 51.014987104590787],
            [0.618265507051918, 51.015211907526371],
            [0.620715593590922, 51.016054569667418],
            [0.622417012899107, 51.015706977062443],
            [0.624576590147019, 51.016125260992709],
            [0.625968337502911, 51.017337869430534],
            [0.627631657716892, 51.017854498475643],
            [0.629249970698297, 51.017776702754773],
            [0.630351937375403, 51.017368736442933],
            [0.633605619238116, 51.014017926711624],
            [0.636021547588376, 51.012218545437605],
            [0.637132942975679, 51.011879555765965],
            [0.63925554502878, 51.012024079685368],
            [0.643804802687448, 51.014549283668885],
            [0.64566212165834, 51.015190781124382],
            [0.645728254697754, 51.016202030434265],
            [0.646655790382343, 51.016840288724836],
            [0.647229221301825, 51.016794013177012],
            [0.648123278943924, 51.015943578025869],
            [0.649793740024394, 51.015181653325584],
            [0.652186631315722, 51.015211879603939],
            [0.654961912256469, 51.014722492350202],
            [0.656424360951068, 51.015163230763491],
            [0.657819770808158, 51.01712370162906],
            [0.658621857376117, 51.017584836645462],
            [0.661274735066158, 51.017795139034163],
            [0.662074917765328, 51.017475595047813],
            [0.664898559186102, 51.016113328163989],
            [0.670910021170178, 51.011423107638379],
            [0.671725419903127, 51.008990398001359],
            [0.672912536407838, 51.008139357878143],
            [0.675313277031776, 51.004497469678739],
            [0.676925146072145, 51.003302067781455],
            [0.681456866898746, 51.001301198129177],
            [0.682207550748781, 51.000550923276244],
            [0.689442494653154, 50.99931058957813],
            [0.699188882218316, 50.998493869623331],
            [0.701196294051046, 50.997324957731919],
            [0.702044256322472, 50.99597417932825],
            [0.706739486301392, 50.993605156373668],
            [0.705752299715571, 50.995107603487135],
            [0.70848417690209, 50.997744360700828],
            [0.711223521285687, 50.998474984532301],
            [0.72111079174796, 50.999465516604026],
            [0.725375242692188, 50.999216751706037],
            [0.731314407137108, 51.000398345330922],
            [0.731434798065172, 50.999972794382934],
            [0.737726900759546, 51.000825607250981],
            [0.740579148318372, 51.000679545958349],
            [0.748054494039139, 50.998394667783408],
            [0.749351557550112, 50.997502470945832],
            [0.750081917286751, 50.997295489240926],
            [0.750910509929985, 50.997592572665184],
            [0.751300452835504, 50.997245195725519],
            [0.757393928876791, 50.99544558155484],
            [0.77281059742263, 50.992734728223446],
            [0.775692717025366, 50.992000656492216],
            [0.775981543594016, 50.991658297115627],
            [0.778582280689825, 50.990820211295762],
            [0.778923473796187, 50.989494404936266],
            [0.779505266438682, 50.987497280379799],
            [0.781020395235627, 50.986501491297403],
            [0.776993507205447, 50.98485781946551],
            [0.778979621472664, 50.982396449489428],
            [0.779905797137454, 50.982324929689383],
            [0.781669289722419, 50.982888251105059],
            [0.778801586506788, 50.979845363233721],
            [0.777751708931813, 50.979443112081896],
            [0.778417385109632, 50.978350634878694],
            [0.777051967664998, 50.977339766926804],
            [0.777101874210805, 50.975430855651297],
            [0.778629681028838, 50.976585366859055],
            [0.780715088852902, 50.976793852409827],
            [0.78293525675958, 50.977420928726382],
            [0.783815650264038, 50.977086031063521],
            [0.784294544720874, 50.977232013339169],
            [0.785423071183266, 50.975235303255474],
            [0.789508260373918, 50.971420338746334],
            [0.789461534484333, 50.970973530132767],
            [0.790360100010505, 50.969994142097825],
            [0.80194445619522, 50.958285533359678],
            [0.805515204298827, 50.954983319830703],
            [0.81342964548611, 50.949012922127615],
            [0.812907956696648, 50.942001653224217],
            [0.814631865061133, 50.942399924019362],
            [0.816696483910958, 50.942287151267436],
            [0.818515306130434, 50.943100421840697],
            [0.819351001230364, 50.94429989911152],
            [0.818439753448849, 50.945122424491252],
            [0.819033778581088, 50.945457066603396],
            [0.821261072118706, 50.945724352477932],
            [0.822869859704131, 50.944759910801167],
            [0.824777001538571, 50.945453120016076],
            [0.82639325721188, 50.945270076483993],
            [0.82786506767003, 50.945873937751337],
            [0.829834394376104, 50.945667000291564],
            [0.833283940020478, 50.949501346892248],
            [0.834332115353213, 50.949902239819309],
            [0.835617203822623, 50.949656953610557],
            [0.835700817261474, 50.950118143003337],
            [0.836927572385434, 50.950458007995564],
            [0.838703060188886, 50.952394545519425],
            [0.838834776673703, 50.954298193079019],
            [0.841066876582015, 50.954705303258123],
            [0.840996559648045, 50.955279969795654],
            [0.839967051015833, 50.9557484573495],
            [0.839727710600289, 50.956213903773879],
            [0.841366626735528, 50.957610452095018],
            [0.844240503477459, 50.957353368276188],
            [0.844942345458899, 50.955857553194186],
            [0.84638534004694, 50.955002064887978],
            [0.846882997586055, 50.953568781583456],
            [0.846266961517816, 50.952529639850709],
            [0.84568257255329, 50.952287543822365],
            [0.84607764416196, 50.951855164667101],
            [0.848002528288822, 50.951733554816947],
            [0.849030279379429, 50.951262339161318],
            [0.851113335930974, 50.951305914998272],
            [0.852495072771064, 50.951569874385946],
            [0.85593278671933, 50.953130019308091],
            [0.860937608521132, 50.941832525145664],
            [0.859594034860011, 50.939681558588198],
            [0.864080482215517, 50.935130884471654],
            [0.867855862819715, 50.933263520196306],
            [0.865978049548309, 50.931151836955948],
            [0.865060476553159, 50.92926120662883],
            [0.854701573596926, 50.923726131834371],
            [0.836096112177641, 50.92648963236519],
            [0.833401122315168, 50.926638750494263],
            [0.797946950622326, 50.932237938181558],
            [0.791541964864108, 50.932619786929997],
            [0.788054891096371, 50.933288691248571],
            [0.781511984885532, 50.933271227107184],
            [0.78204349765684, 50.932947347378331],
            [0.777824542399535, 50.932611613115157],
            [0.776353928941453, 50.932105139355869],
            [0.776384064723251, 50.932348173554807],
            [0.773713022221041, 50.932262379792945],
            [0.772366502327614, 50.931831158826739],
            [0.771984085919623, 50.931264594881917],
            [0.763844951736735, 50.9401337178546],
            [0.760617994460531, 50.941628520822604],
            [0.760253127334949, 50.941501334166482],
            [0.753793652884838, 50.942817807964524],
            [0.74866878188316, 50.94583932562773],
            [0.74667584675881, 50.946303585051339],
            [0.742078407400125, 50.947874181753917],
            [0.740627808243982, 50.950177547277214],
            [0.737930826273172, 50.951531542668853],
            [0.738009854237621, 50.952052264907373],
            [0.739367503649086, 50.952716578115343],
            [0.739524319837632, 50.953131138409674],
            [0.738960143953134, 50.957193667121004],
            [0.741474351566052, 50.958583645487515],
            [0.742328067061773, 50.960359777691835],
            [0.743052546416343, 50.961440074347486],
            [0.747020930284679, 50.962558301347933],
            [0.748280997175704, 50.963805849626574],
            [0.748908502945867, 50.965218489416131],
            [0.748533730011592, 50.964950286053863],
            [0.747855733098481, 50.967169881509989],
            [0.750415855353454, 50.969057781357989],
            [0.750234709712659, 50.969752820836852],
            [0.747600576572434, 50.96769756388256],
            [0.747342697575265, 50.966441716212181],
            [0.748123421227691, 50.964731488005278],
            [0.747176631718833, 50.963097000917102],
            [0.746181405809508, 50.962571758954155],
            [0.743158166033765, 50.961830648468911],
            [0.74225944849829, 50.961250038432802],
            [0.741828611017999, 50.96041558317544],
            [0.741089784448018, 50.958824732977241],
            [0.739106323571851, 50.957838734525481],
            [0.738346140463376, 50.956850994694612],
            [0.738951617545371, 50.953150874744608],
            [0.737454175288135, 50.952070695217714],
            [0.737405110107957, 50.95111483928693],
            [0.739351294491446, 50.950238094585124],
            [0.737502002633804, 50.949589810699955],
            [0.736599049607048, 50.948447106288477],
            [0.734883761484418, 50.947533002427086],
            [0.731627974684333, 50.947128044171507],
            [0.731217653590049, 50.948507496453544],
            [0.729628123314329, 50.949397351254056],
            [0.728274444714934, 50.949744704764349],
            [0.730816892992021, 50.948447616588368],
            [0.730818051445345, 50.947544549967915],
            [0.73154460775713, 50.946799000598347],
            [0.731387831696964, 50.946262104903724],
            [0.73228997036485, 50.947029800368362],
            [0.735309350352141, 50.947336844769545],
            [0.737119970372158, 50.94834674608601],
            [0.738332246458202, 50.949616206921228],
            [0.739370803394758, 50.949795110845599],
            [0.74009751446069, 50.949295951344162],
            [0.740450038852042, 50.947761319824878],
            [0.740687936250251, 50.947884354273164],
            [0.744049609206532, 50.946314090736074],
            [0.746569626689802, 50.945661183039228],
            [0.748737229348003, 50.944851922102451],
            [0.752962042029198, 50.94221231185972],
            [0.760527559060095, 50.940751000578103],
            [0.760568273863257, 50.940356979094474],
            [0.760991236706085, 50.940527762727541],
            [0.762093125949048, 50.940167995083044],
            [0.761067619289355, 50.939846872951058],
            [0.762220828395688, 50.940040848975514],
            [0.763362522975673, 50.939560500473625],
            [0.766318131506671, 50.936568173440293],
            [0.766108723679081, 50.936181876787394],
            [0.765281091306292, 50.937323102342752],
            [0.764892048284554, 50.936860109452432],
            [0.765386690628701, 50.937015687172789],
            [0.766444812807936, 50.935465144817435],
            [0.766880168621711, 50.93555736118892],
            [0.767188431797704, 50.93475583894088],
            [0.769911162866776, 50.932691771546345],
            [0.772508559135906, 50.929410061607257],
            [0.774575643275625, 50.927601607924373],
            [0.769283752127074, 50.927235307837591],
            [0.764644087979306, 50.92653851087632],
            [0.752045467635882, 50.923055022563304],
            [0.749742742821792, 50.922182874503015],
            [0.740142913072436, 50.917412260572888],
            [0.731856834496858, 50.914560117737061],
            [0.726387058089362, 50.911435650823726],
            [0.718455933285143, 50.907488644206524],
            [0.709771866211099, 50.901991763656177],
            [0.70203287911078, 50.897134355648568],
            [0.69261572859209, 50.892468989456539],
            [0.690775238134365, 50.890984197330049],
            [0.688101513809289, 50.889622013473023],
            [0.682497316966997, 50.885860915121874],
            [0.679394221413623, 50.881244332161543],
            [0.672751396445475, 50.877835819741193],
            [0.670246867984274, 50.87533246348665],
            [0.669502216718648, 50.873496656496947],
            [0.666517047804417, 50.872003498807082],
            [0.664652528782635, 50.87173759455316],
            [0.658538321653088, 50.869931605065389],
            [0.656424398386209, 50.871701289418596],
            [0.655849920676414, 50.87272264135887],
            [0.647197516342725, 50.871147408353735],
            [0.646455633182445, 50.871274871819082],
            [0.64324055549659, 50.872962254596942],
            [0.642563455365219, 50.874386113307345],
            [0.640310144058293, 50.874509088052676],
            [0.640418722696385, 50.876379258251632],
            [0.639506124982952, 50.876053626439784],
            [0.639322130520175, 50.876344709515926],
            [0.638665822183872, 50.87620215436683],
            [0.63857425462196, 50.877324023825913],
            [0.636248628750708, 50.876949367318026],
            [0.635863197442444, 50.877424883636522],
            [0.633540409500209, 50.877352319485816],
            [0.627610482165401, 50.879924397101355],
            [0.626582871968326, 50.880940512370891],
            [0.623527204246385, 50.881718919349119],
            [0.622860830164035, 50.882460644593365],
            [0.623735711971109, 50.88317760888247],
            [0.623186708727688, 50.883427404391078],
            [0.620869197126681, 50.884188241397545],
            [0.618946951573735, 50.884088396957381],
            [0.617450804345829, 50.88481723332648],
            [0.615401747499146, 50.885296711115657],
            [0.614039485099915, 50.884863133915744],
            [0.613793753971679, 50.883699377816754],
            [0.611209084860352, 50.881796493061252],
            [0.613177266168893, 50.880763050310613],
            [0.606472665452293, 50.878731009616736],
            [0.603698995386661, 50.878552795775008],
            [0.599903734643505, 50.882853064588339],
            [0.598977463186929, 50.883453834410695],
            [0.599156183948028, 50.883905864003729],
            [0.597713608196874, 50.884606268389412],
            [0.596070146586039, 50.884712104707454],
            [0.595549948585069, 50.883590397571119],
            [0.59422411268884, 50.883680147261174],
            [0.594343370758217, 50.884393441040324],
            [0.591453813697186, 50.883951279667514],
            [0.589837882535805, 50.882733357290974],
            [0.589574850156561, 50.882912785454849],
            [0.589076790715544, 50.882597346531952],
            [0.588117957408935, 50.883562120674831],
            [0.588511977330959, 50.88405256220603],
            [0.587120912284344, 50.884607782919872],
            [0.586415171893023, 50.884239374278515],
            [0.582693087920203, 50.884732864978552],
            [0.581839003846313, 50.884740974863867],
            [0.58113028961812, 50.88408658248747],
            [0.581172777597757, 50.88362063864129],
            [0.573066126420263, 50.885823410708362],
            [0.568396218624598, 50.887624418674669],
            [0.568240646061472, 50.888440923260099],
            [0.560840760685951, 50.888881492732892],
            [0.561192666724363, 50.89075985994382],
            [0.558711979584348, 50.889917561257157],
            [0.557363462499131, 50.890428317058088],
            [0.559104345906728, 50.891419987221965],
            [0.556283990214215, 50.894086538555534],
            [0.555106192746344, 50.894029588906861],
            [0.555114561403203, 50.893559007806566],
            [0.55370015242259, 50.892972068972064],
            [0.552057453633086, 50.892786751654434],
            [0.551993524414431, 50.892397800756655],
            [0.546243794372959, 50.892106082164304],
            [0.540351982701209, 50.893461314219074],
            [0.536920797755505, 50.89482843115578],
            [0.535582670541245, 50.893574045059523],
            [0.533740702659719, 50.893017742182494],
            [0.532728276365276, 50.892128610602036],
            [0.531466745089577, 50.893279361092645],
            [0.529063677679897, 50.892287246020366],
            [0.52881704776553, 50.890202341984043],
            [0.526334269557438, 50.886130490053482],
            [0.524276297835445, 50.883149385893354],
            [0.522659405377989, 50.882880352544355],
            [0.522833494278875, 50.882155254295107],
            [0.52460144560456, 50.880722013220314],
            [0.524378884826032, 50.880076548469781],
            [0.522756214395106, 50.878198580615525],
            [0.519997267024508, 50.877390264391948],
            [0.519920086965865, 50.876065287770594],
            [0.520559368791865, 50.874898422326886],
            [0.520235976817286, 50.872277303794462],
            [0.521362296196589, 50.870312018525745],
            [0.520869028722844, 50.870100521176333],
            [0.52175231397296, 50.869190106160936],
            [0.520958666190159, 50.86815313771686],
            [0.521138382868357, 50.866926941321978],
            [0.518594177178433, 50.864895239792901],
            [0.519746353067092, 50.862646990860021],
            [0.518082932633518, 50.862265576552424],
            [0.51780061915266, 50.860771435745882],
            [0.520982594265394, 50.85932480174251],
            [0.521692739726027, 50.858553938565294],
            [0.521296466437661, 50.857767415053814],
            [0.520451199898198, 50.857271211821555],
            [0.521663325423866, 50.856955402037293],
            [0.520979698575991, 50.854503062775265],
            [0.520273040700144, 50.854362732365459],
            [0.520080493243264, 50.852557254483173],
            [0.521159313366867, 50.851747847387117],
            [0.512160502391361, 50.848985594480055],
            [0.512097238599794, 50.849844105854785],
            [0.510563443311306, 50.850014738695606],
            [0.508543741217825, 50.850873965313156],
            [0.507058985424319, 50.84952257627242],
            [0.507651705977148, 50.849077214113144],
            [0.507538949465945, 50.848325923109435],
            [0.506240613445403, 50.847402238501623],
            [0.505632265936919, 50.847370336737022],
            [0.505901566945871, 50.845476666460037],
            [0.50409434307877, 50.844810331324496],
            [0.505198584138649, 50.844314424169234],
            [0.503074103120658, 50.843134122066417],
            [0.501174091747153, 50.842775536663929],
            [0.497713528360034, 50.84115158425189],
            [0.485940077143509, 50.83938210658556],
            [0.483337191518469, 50.83878187593038],
            [0.480867480053728, 50.837772217768837],
            [0.476143828720666, 50.8371395660429],
            [0.462948529155691, 50.835952893740782],
            [0.454672469357467, 50.834929335388864],
            [0.447902032782202, 50.834579643287881],
            [0.439895779948837, 50.833353304276727],
            [0.430998218654889, 50.83267655119225],
            [0.427270198404015, 50.832130899021863],
            [0.418902677787537, 50.831369341293914],
            [0.412335945233021, 50.830189503314863],
            [0.407579364339258, 50.828634667990656],
            [0.40183167040026, 50.827351888908588],
            [0.381767268600935, 50.821849736903445],
            [0.377483006026523, 50.820303824618961],
            [0.377703346837064, 50.823522807649177],
            [0.37611676466454, 50.825973644992956],
            [0.375311012211684, 50.828591139300734],
            [0.372505022185624, 50.831645966712308],
            [0.372795943837126, 50.831893681176744],
            [0.373786924188523, 50.832251267932534],
            [0.375118097280847, 50.831784360175341],
            [0.375571727838086, 50.832634953762096],
            [0.376553482979518, 50.833117722447405],
            [0.377260175395159, 50.833022375105884],
            [0.376770649988376, 50.834149412541933],
            [0.376996187142059, 50.836435601766112],
            [0.376335059752505, 50.836646042084809],
            [0.376059141161811, 50.836443000929371],
            [0.374625648817609, 50.837135053670089],
            [0.373825569761021, 50.83816226928959],
            [0.374541083019961, 50.838882519425539],
            [0.374924357276973, 50.840136580999818],
            [0.375741850316151, 50.840906013613676],
            [0.377150758017327, 50.841488905416369],
            [0.377810426781404, 50.842310107744261],
            [0.379503743828907, 50.842478843983955],
            [0.380532258580635, 50.842926448944581],
            [0.380938804069782, 50.842680708812864],
            [0.381902103122946, 50.843325703147173],
            [0.384073496101153, 50.84295126319396],
            [0.386234250798545, 50.843927907081991],
            [0.3899860249582, 50.844401535751082],
            [0.393938431723016, 50.846017669724596],
            [0.394501175837387, 50.846659088531666],
            [0.398831859921975, 50.84830326933406],
            [0.398540093298849, 50.848819232188426],
            [0.401676658247105, 50.849752140609937],
            [0.401674398127867, 50.850371878283802],
            [0.405804128883803, 50.850473859613807],
            [0.408058779153474, 50.850913019562384],
            [0.407952238710706, 50.852320092874763],
            [0.411985984753796, 50.855762438112521],
            [0.41394866384726, 50.856182338827026],
            [0.415436487862266, 50.856074193785794],
            [0.41740999855566, 50.856592747097601],
            [0.41940768853797, 50.857632422467248],
            [0.420700862826185, 50.858484317983987],
            [0.421657291198713, 50.859610314222543],
            [0.421117036648075, 50.860099113152636],
            [0.424151742136603, 50.862880022291435],
            [0.425864112126391, 50.863124144593236],
            [0.426729116642235, 50.864100001000871],
            [0.429744806431901, 50.865000499805745],
            [0.431505736457683, 50.866148333610532],
            [0.435113134918986, 50.866562372820574],
            [0.440600383362845, 50.867972211129796],
            [0.441167392897616, 50.867834430998258],
            [0.44139375334061, 50.868385529541889],
            [0.443687123686473, 50.87008506921142],
            [0.44464592299124, 50.869665644857207],
            [0.447568706133382, 50.872522950875634],
            [0.450130028628853, 50.87376703934131],
            [0.446678253799991, 50.873460824427276],
            [0.445246815515762, 50.874546740322344],
            [0.445704342437645, 50.874922997157022],
            [0.445309361659895, 50.876067229918377],
            [0.446468130348426, 50.877753614542677],
            [0.449055950498573, 50.878955799086015],
            [0.444536663251148, 50.881701128581653],
            [0.44418090641313, 50.882401125809928],
            [0.441140151844645, 50.884474959128248],
            [0.441139136963294, 50.885216991527656],
            [0.439743589047255, 50.887243762878782],
            [0.439523619344795, 50.889292713696427],
            [0.438836708385838, 50.889726196172226],
            [0.437734255755498, 50.889881445766356],
            [0.434387470716874, 50.889810109494299],
            [0.432951162746701, 50.891090239525504],
            [0.430918462513839, 50.891296295395342],
            [0.42958676574188, 50.891829496055919],
            [0.429751946661051, 50.892298244329275],
            [0.43114874335074, 50.893014758166082],
            [0.43504603794154, 50.892866939713642],
            [0.435816003620543, 50.893587482993141],
            [0.433877988954258, 50.894247611689089],
            [0.430332144585959, 50.8943466984664],
            [0.428611519379835, 50.895059772899131],
            [0.427243957265973, 50.894687092489569],
            [0.422989543097989, 50.894944596625216],
            [0.422626392101281, 50.893236074093153],
            [0.419912331840252, 50.893029763509283],
            [0.420058450448519, 50.894750889666113],
            [0.417074921452279, 50.89604310946735],
            [0.416327680190536, 50.896855455428508],
            [0.414112248867511, 50.896806870936501],
            [0.41224076605037, 50.896423752624635],
            [0.409673072852212, 50.895116007430552],
            [0.408459892985216, 50.895031325851221],
            [0.405482000567195, 50.8938731609706],
            [0.402555677828684, 50.893713993231458],
            [0.398674386316605, 50.892045404748977],
            [0.395855874124462, 50.891435052737847],
            [0.394624526253046, 50.890882912798361],
            [0.392394557721814, 50.889046208337],
            [0.389793923411336, 50.887798066421503],
            [0.388660791386751, 50.887772700073839],
            [0.38545408863863, 50.886903822435436],
            [0.384724665725026, 50.887126493116909],
            [0.380641942212049, 50.885331913949798],
            [0.380513888479566, 50.884744520502295],
            [0.379207688379636, 50.883609137201709],
            [0.377495547049714, 50.883574781432323],
            [0.375435635643412, 50.883020433107149],
            [0.37439085506732, 50.883052490545623],
            [0.372625505837347, 50.884236931582549],
            [0.370971746348695, 50.886388628677047],
            [0.371215749320134, 50.886741630898122],
            [0.370097685217276, 50.887068342057724],
            [0.369750683808681, 50.887683382340981],
            [0.368257004889515, 50.887641754950742],
            [0.366684790359515, 50.889108191347034],
            [0.367164922435313, 50.889996050605681],
            [0.368623766787558, 50.891059215168056],
            [0.36953734666294, 50.892584932278822],
            [0.371089551135656, 50.893579615385207],
            [0.372122398523562, 50.896190259917233],
            [0.374274063148562, 50.8983976929281],
            [0.372506592870102, 50.899803420202254],
            [0.370088171777963, 50.900436269708749],
            [0.370426221401371, 50.901127334936888],
            [0.368146439401005, 50.90194350214076],
            [0.368600308956847, 50.903851802206972],
            [0.368029106108713, 50.904003699550273],
            [0.367271815760387, 50.902001719792651],
            [0.364594773978668, 50.902951789669324],
            [0.364087181290449, 50.902337893231177],
            [0.362942689640879, 50.903443043852391],
            [0.361916790783053, 50.903801084083526],
            [0.362458768900089, 50.904363927603619],
            [0.361014387825575, 50.905194517686823],
            [0.36064393348808, 50.906053737750696],
            [0.361018234209027, 50.908418767013686],
            [0.359076422997225, 50.911258744638005],
            [0.358200407721339, 50.913488055259528],
            [0.360297552428262, 50.913743362096952],
            [0.361528712298677, 50.913472923271513],
            [0.363751555391567, 50.913934283948564],
            [0.363866065213784, 50.914369069906272],
            [0.363511006364457, 50.91443741937298],
            [0.364122337116478, 50.914934094516553],
            [0.364033498581597, 50.916100608149684],
            [0.364654651690287, 50.916509840488359],
            [0.363608333228943, 50.917059877444593],
            [0.361776183444941, 50.917348796062839],
            [0.359926487395792, 50.917122688661472],
            [0.357733341280731, 50.917307317558198],
            [0.358362240688606, 50.921415625504281],
            [0.358965077562223, 50.92233431374828],
            [0.363029569677896, 50.923187465289182],
            [0.363753995286988, 50.923835648863069],
            [0.363781055293309, 50.92408872941877],
            [0.363189124149884, 50.923892955885997],
            [0.36248254653081, 50.924102427499328],
            [0.36246595194809, 50.925825098588625],
            [0.366569656209197, 50.930611275043518],
            [0.36681793910436, 50.932866411713754],
            [0.364744179244396, 50.934155896621427],
            [0.362230902180119, 50.934807577049881],
            [0.360852368226896, 50.936001075464453],
            [0.356605095196534, 50.935270251816974],
            [0.355833436132227, 50.935435126411889],
            [0.354634644715055, 50.935963871260327],
            [0.353473161002128, 50.93765476137213],
            [0.351421082499382, 50.938680046538423],
            [0.347621398239997, 50.938274450890908],
            [0.34266122528714, 50.939445268686185],
            [0.341292226884974, 50.941436095530406],
            [0.342443375506583, 50.942247633432146],
            [0.343183514641755, 50.942358693780342],
            [0.343487894310088, 50.944334832495862],
            [0.340975510630661, 50.946607623549809],
            [0.337606745791656, 50.947849724763309],
            [0.338144187271744, 50.950359939409772],
            [0.338785861423589, 50.950719431904751],
            [0.335904083265993, 50.953048073905222],
            [0.337739559941044, 50.953787504159081],
            [0.341520604567909, 50.957084426187748],
            [0.343981833097214, 50.957349823457434],
            [0.343555546511968, 50.959150855320594],
            [0.34261725311652, 50.960116737747789],
            [0.340687708783351, 50.960713954962998],
            [0.338484061658509, 50.960646589166892],
            [0.334969622448222, 50.961191801641228],
            [0.327983813142828, 50.960841137065074],
            [0.326704198695107, 50.960997054727265],
            [0.32399633180805, 50.962925297526695],
            [0.323517252928363, 50.963884579380014],
            [0.324959824512555, 50.964423372792993],
            [0.325797441539951, 50.966080440047499],
            [0.326454635988123, 50.966548516408849],
            [0.32763457056226, 50.970492172077968],
            [0.330123083123638, 50.973054341757901],
            [0.330242857366843, 50.974877695287709],
            [0.328874319620247, 50.978160756108636],
            [0.329004234723688, 50.97987418268999],
            [0.328175683654139, 50.981196604930197],
            [0.326995298014392, 50.984914784210908],
            [0.324269801390822, 50.986146350078336],
            [0.323831518524602, 50.986899758114767],
            [0.322983317586018, 50.987092012621829],
            [0.323117200063898, 50.988203679218685],
            [0.323583380150376, 50.988635999274635],
            [0.323164525044874, 50.989436684789013],
            [0.3235698767649, 50.990419735041179],
            [0.322329500260875, 50.992805274680642],
            [0.323312435477962, 50.994566491956398],
            [0.324706206060714, 50.99573492839432],
            [0.321557080200772, 50.996687954733524],
            [0.324622052933582, 50.998100167403202],
            [0.323443808938644, 50.998907870749832],
            [0.323403724615387, 50.999531936443859],
            [0.322576943875671, 51.000100598799683],
            [0.32590373439945, 51.001413137317769],
            [0.327830806084088, 51.003092553973232],
            [0.32888459075875, 51.003442089239776],
            [0.32934689338661, 51.003963502387521],
            [0.330057214252088, 51.004029377992318],
            [0.330188470240993, 51.005880375598565],
            [0.330673193216777, 51.006023597907046],
            [0.330820213870448, 51.006510824466318],
            [0.330385387865593, 51.006765930535714],
            [0.329954710636409, 51.010460168355088],
            [0.328733804617611, 51.011445050913416],
            [0.328666438767593, 51.01203548534081],
            [0.328233628125422, 51.012104371753864],
            [0.328630596059154, 51.012885210842015],
            [0.331133055543596, 51.013886663822035],
            [0.333368890726838, 51.013721503031277],
            [0.333545012900543, 51.014446479347278],
            [0.333969095889799, 51.014089947293186],
            [0.336207999969313, 51.0139858284073],
            [0.341715452213347, 51.015594251048057],
            [0.343574085955941, 51.015327632125334],
            [0.344317688955372, 51.01571023466942],
            [0.344643613982693, 51.015472552573577],
            [0.346056259436576, 51.016341766181021],
            [0.347220142420671, 51.016349861308186],
            [0.347667400170677, 51.017074764951502],
            [0.348552384285988, 51.016952646178225],
            [0.348838772896141, 51.017343513156042],
            [0.349359196331778, 51.017004773630369],
            [0.34945653451258, 51.017519958163703],
            [0.351580182810718, 51.017586946711191],
            [0.352885403152152, 51.017203608423294],
            [0.353922262471519, 51.017490304823781],
            [0.353895918329352, 51.017899153639888],
            [0.35266758261066, 51.017970668970676],
            [0.351866226179932, 51.018336665328867],
            [0.351686444499348, 51.020109361955619],
            [0.351394288422965, 51.020450711642013],
            [0.350238320780856, 51.020403824923946],
            [0.349920635436717, 51.021171090138402],
            [0.349527041188436, 51.021198794109054],
            [0.34970010616034, 51.021660291069367],
            [0.348992629121842, 51.021571991519316],
            [0.348844439137801, 51.022025559623827],
            [0.347617247441143, 51.021839776043961],
            [0.347691242243401, 51.022118894569992],
            [0.346872553941897, 51.022539166624945],
            [0.348126584844847, 51.022973546084053],
            [0.347688661567458, 51.023394263263661],
            [0.347157912169748, 51.023360859862485],
            [0.346572210291706, 51.024102921510988],
            [0.347139246644584, 51.024176969832418],
            [0.34683238735913, 51.024567169279152],
            [0.347387693073273, 51.025481470070332],
            [0.346899974874205, 51.02582044192237],
            [0.347522044315725, 51.026559820943987],
            [0.35031044330645, 51.028144213748156],
            [0.352660316074249, 51.030049457500738],
            [0.353241891629479, 51.03007101999345],
            [0.355466098714979, 51.031856424409341],
            [0.355544369785906, 51.033006048663232],
            [0.354932382876072, 51.03387639283536],
            [0.356087705012518, 51.036434308084466],
            [0.355244861803449, 51.036831740160395],
            [0.354833539317038, 51.041210100340962],
            [0.355662176182104, 51.042808673386489],
            [0.357924329397992, 51.044624745605908],
            [0.35828400661799, 51.046060084275609],
            [0.356820372155192, 51.051010100854327],
            [0.356985277597052, 51.057123419347171],
            [0.356682105818851, 51.057869719572309],
            [0.35892617542277, 51.059482880097292],
            [0.361501192100586, 51.062435672736108],
            [0.361326223718924, 51.065279431054066],
            [0.363017222005955, 51.066981036560371],
            [0.364944767721489, 51.06766423657141],
            [0.365786280852814, 51.067511396166168],
            [0.367265149224516, 51.067792627748602],
            [0.367607483384076, 51.068263259335865],
            [0.370684540680309, 51.069790064734654],
            [0.371619988031368, 51.069854723981557],
            [0.373597498174825, 51.070728335238684],
            [0.373870778596999, 51.071214741542846],
            [0.374645529945571, 51.071318616269672],
            [0.375231959764968, 51.071898485745848],
            [0.375013866326765, 51.072127762387574],
            [0.376078456890227, 51.072510838069476],
            [0.376807867548307, 51.072425852513121],
            [0.378097523795023, 51.072798935505666],
            [0.378384602692077, 51.072495399558846],
            [0.37944388453894, 51.072802105963966],
            [0.379870223750896, 51.072263691655728],
            [0.380394927382542, 51.072668515276284],
            [0.381490629745108, 51.07276670559412],
            [0.381984863536955, 51.072355510319497],
            [0.382608816373019, 51.0727744896571],
            [0.384215511948573, 51.072593320746861],
            [0.384920457142155, 51.07303222030761],
            [0.385232144965377, 51.072708378687246],
            [0.386470016838592, 51.072489737972553],
            [0.387532440660609, 51.072828684735498],
            [0.388103951677468, 51.072422179829857],
            [0.389767767297606, 51.073017727508187],
            [0.390009775012909, 51.072699794819883],
            [0.390712763107319, 51.072905761301143],
            [0.390881608043241, 51.072553348549],
            [0.391298282536922, 51.072771465096963],
            [0.391083100927391, 51.073083455320138],
            [0.392196071973064, 51.072906882110658],
            [0.392317467768781, 51.073378368342453],
            [0.392794851662346, 51.073168032712083],
            [0.394024396345825, 51.073562857362255],
            [0.393578992053716, 51.073673611497732],
            [0.393932546686156, 51.073830055320826],
            [0.395744341269272, 51.073916137550697],
            [0.392224996714359, 51.077369895460656],
            [0.395154650009341, 51.080410033643808],
            [0.39791479589081, 51.082511931140523],
            [0.396445863391609, 51.083098777946724],
            [0.397596593110617, 51.08364807669917],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000065",
        LAD13CDO: "21UH",
        LAD13NM: "Wealden",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.130574529012997, 51.147165492665358],
            [0.130849947293332, 51.146874486219744],
            [0.132766326972401, 51.14685303838796],
            [0.133090631471678, 51.147281500726919],
            [0.134659827466169, 51.146862554122464],
            [0.135989539497217, 51.147017239261153],
            [0.136899831874861, 51.146449315938739],
            [0.137330180778777, 51.146727438722088],
            [0.139570241217167, 51.146717949082934],
            [0.139461555311605, 51.146284660076553],
            [0.141350836810293, 51.145832785947157],
            [0.14124935198877, 51.145246479736514],
            [0.142544929736333, 51.145004209352017],
            [0.143147897939507, 51.144316859712312],
            [0.144511270220396, 51.144026559241595],
            [0.144847965394516, 51.143733495792425],
            [0.144544102169764, 51.143254327655143],
            [0.148078293217704, 51.143161575215288],
            [0.148419117197372, 51.142742518417556],
            [0.149171280254581, 51.143022778555157],
            [0.149429423978619, 51.142701467324194],
            [0.149744731639228, 51.142995149529277],
            [0.150168147721834, 51.142538595568155],
            [0.150695598153688, 51.14293629427403],
            [0.151868040903759, 51.142876951048649],
            [0.153025099658575, 51.14245634615876],
            [0.152969918475601, 51.14216148059915],
            [0.155424544715269, 51.141975061931056],
            [0.155752100666374, 51.141303514154359],
            [0.156616707411013, 51.141296563620166],
            [0.156569879453264, 51.140905316456362],
            [0.157161344160118, 51.140712739919174],
            [0.158019935517286, 51.141036845943759],
            [0.158468575666616, 51.140663433932602],
            [0.1589109213549, 51.141013203462556],
            [0.159865759887029, 51.141040536260064],
            [0.160096565713016, 51.140533542973046],
            [0.160940607150972, 51.140637558777954],
            [0.162284066407769, 51.139681008118067],
            [0.159720316707044, 51.138904598505057],
            [0.160845430238439, 51.137987171100754],
            [0.160721459338739, 51.137322153476191],
            [0.159323478421596, 51.138120510899611],
            [0.158620769319552, 51.137950021542295],
            [0.158310982477526, 51.137315412089862],
            [0.156405066848331, 51.137307410428811],
            [0.155734968580085, 51.136946542226291],
            [0.154581777340898, 51.137538877457168],
            [0.153238653898093, 51.13613907027689],
            [0.152379070912278, 51.135913875009287],
            [0.152860976705787, 51.135364501092575],
            [0.151160356811227, 51.134358866712546],
            [0.151853223100818, 51.132935956073254],
            [0.151161191101146, 51.132347034044663],
            [0.151230006274289, 51.131828648908403],
            [0.150036334746689, 51.131178800877457],
            [0.150921198830477, 51.131211988932094],
            [0.151177893453574, 51.129600149953497],
            [0.152142572489682, 51.128122770970641],
            [0.151038171799883, 51.127700621198514],
            [0.151583200619722, 51.124205656532155],
            [0.15117429924967, 51.123832760939635],
            [0.152051969733721, 51.122545842774748],
            [0.154628143909138, 51.12173845078415],
            [0.153775245763195, 51.121528432549205],
            [0.153411330307449, 51.120586333560681],
            [0.154123069228166, 51.120404145492074],
            [0.15483161661502, 51.121044008040769],
            [0.156060526704706, 51.12049074008609],
            [0.15597852258607, 51.119957145388703],
            [0.158278353348033, 51.119081021350667],
            [0.158252410921642, 51.118340444100163],
            [0.159295523721604, 51.11821775777441],
            [0.158402649064119, 51.117700937541926],
            [0.159836295813655, 51.11761870023691],
            [0.159736177567209, 51.11731027747237],
            [0.160369563286131, 51.117226627055551],
            [0.160456344149444, 51.116850897659759],
            [0.162333995972299, 51.116883632814115],
            [0.162571505665266, 51.116400790077911],
            [0.163128152533793, 51.116632414125952],
            [0.163321519554829, 51.116305972571425],
            [0.164815326900945, 51.116350266947798],
            [0.165032910825739, 51.116144785074475],
            [0.167059243600078, 51.116576696078994],
            [0.171601380114253, 51.115413198395238],
            [0.172705628680323, 51.115407969099323],
            [0.174162192379044, 51.115755015080879],
            [0.174856823546821, 51.115455203844967],
            [0.175636553771575, 51.115993790687128],
            [0.176112288570666, 51.115840141741927],
            [0.176443870120653, 51.116146040823082],
            [0.17687702038045, 51.115967999982288],
            [0.178086447487249, 51.116193691084348],
            [0.178852836199051, 51.115688368124154],
            [0.18013235508146, 51.115581773360255],
            [0.182814195414731, 51.116216128335722],
            [0.185040332689455, 51.116245588291136],
            [0.185514991867585, 51.116826684832915],
            [0.187996142007867, 51.116657961871368],
            [0.193219299055987, 51.117183331386656],
            [0.194281702281546, 51.116897188522366],
            [0.195451576094731, 51.11728082829336],
            [0.196264707091919, 51.117945459413811],
            [0.197349014920479, 51.117759603798063],
            [0.198247992047117, 51.116920701146071],
            [0.19953378646705, 51.11691630118878],
            [0.201008067910591, 51.117330131513761],
            [0.201049475387431, 51.117600054919286],
            [0.201706576297398, 51.117445584422413],
            [0.202292242776443, 51.117742128844036],
            [0.202002493912367, 51.118079445565073],
            [0.202375476122293, 51.118260382614558],
            [0.203182655936123, 51.118106673738815],
            [0.20428631993701, 51.119108424688044],
            [0.205912639565308, 51.119291792307187],
            [0.206383449155596, 51.119156099572969],
            [0.206136514304522, 51.118711986189226],
            [0.206664579252748, 51.118698422176863],
            [0.209049862663609, 51.120503328334962],
            [0.20932661329352, 51.120223797828835],
            [0.209816610100136, 51.120340444985573],
            [0.210019285287155, 51.120994035833455],
            [0.211792274607941, 51.120715882590829],
            [0.212127045288375, 51.120963164069551],
            [0.212464893031436, 51.120616817603647],
            [0.212847641297157, 51.121240015274907],
            [0.213695572837724, 51.121011716113578],
            [0.214253180543037, 51.121615409778428],
            [0.214539093016422, 51.121288927237359],
            [0.215235979653427, 51.121487969773291],
            [0.215568940255671, 51.121069759076072],
            [0.216376805114301, 51.121407888994931],
            [0.217375363962635, 51.12086373436464],
            [0.216822701636299, 51.120572934388605],
            [0.217676081066666, 51.120399362980478],
            [0.219518293079325, 51.120582942810309],
            [0.220803700547652, 51.121521732430246],
            [0.221364423937606, 51.121443628056483],
            [0.221628264224507, 51.121879294736978],
            [0.224323409367433, 51.122681526814652],
            [0.224205996573139, 51.123360969670948],
            [0.224799548556287, 51.123549330665377],
            [0.225432901198037, 51.124357481057956],
            [0.227418179557924, 51.124243227771757],
            [0.227670832791809, 51.123791437647021],
            [0.243008601395484, 51.11704712243175],
            [0.242530339565742, 51.116200115947549],
            [0.243468641305449, 51.116072391274038],
            [0.243310207826739, 51.115279505885539],
            [0.244523914015805, 51.114879379703709],
            [0.246960126744823, 51.114880242413612],
            [0.247288539272836, 51.115552939936769],
            [0.24827674944933, 51.115570811343268],
            [0.248904820542129, 51.114939975765552],
            [0.251151194231073, 51.114239310810383],
            [0.251867042376342, 51.114739053287892],
            [0.251955608605781, 51.114415378914174],
            [0.252353149471019, 51.114598382513812],
            [0.255263783811535, 51.113954986561218],
            [0.259390568127555, 51.113699022108008],
            [0.259284281240719, 51.11409769135625],
            [0.262615371836517, 51.114312961444611],
            [0.265016609709728, 51.113657595626535],
            [0.266387095774751, 51.114889229140367],
            [0.266237417494362, 51.115100781245488],
            [0.275643064696189, 51.112357590497346],
            [0.278914863847201, 51.114678942609359],
            [0.275108768725058, 51.117332433535609],
            [0.275638892730268, 51.117477703262544],
            [0.272419019598937, 51.11863215686769],
            [0.272146671674696, 51.118847899783603],
            [0.273907690312858, 51.120080848671542],
            [0.274802928196051, 51.119774738343899],
            [0.274708638595931, 51.120240640228928],
            [0.275289411556369, 51.11990377126196],
            [0.275913028635358, 51.120409660191754],
            [0.278979736286835, 51.119045849028602],
            [0.278982538282125, 51.11834789448146],
            [0.279639521070298, 51.11784763360378],
            [0.281802916012082, 51.11802127016189],
            [0.284656560512207, 51.117602192924629],
            [0.287041796052857, 51.117999833204891],
            [0.289524942137843, 51.11885957561406],
            [0.291616588915031, 51.118765528358644],
            [0.292875573612911, 51.119141954248548],
            [0.295407179085575, 51.118612013874326],
            [0.297451197062024, 51.118566463183171],
            [0.298691447507856, 51.118161652596946],
            [0.301322143721505, 51.118592847582995],
            [0.302764262514744, 51.11923087274787],
            [0.303152091579419, 51.118897664370891],
            [0.3038752641088, 51.119474292607364],
            [0.304756325502598, 51.119203305628552],
            [0.305282311594594, 51.11960753851757],
            [0.307597719522208, 51.119960270509829],
            [0.309656456266651, 51.119721753066642],
            [0.310535537878347, 51.119325750422526],
            [0.311247879315407, 51.119568885276337],
            [0.311794112787374, 51.11940429351408],
            [0.313282907751516, 51.119829017851224],
            [0.313205411652428, 51.12010845362898],
            [0.313984046810759, 51.119990516071077],
            [0.314667646443293, 51.120515698893719],
            [0.315095970757411, 51.120365118029177],
            [0.315878299544267, 51.121206712049663],
            [0.316576829477391, 51.121115526339352],
            [0.316925518035053, 51.121543906205694],
            [0.317626356047546, 51.121527315407299],
            [0.317578639561012, 51.121801666964949],
            [0.318249153255264, 51.121379162734236],
            [0.320279552568179, 51.122285906267834],
            [0.320648223668744, 51.12194312653839],
            [0.322018110975475, 51.122387188823268],
            [0.323052581337573, 51.122354944350668],
            [0.324132043302684, 51.123019700407568],
            [0.324434147712927, 51.122717803926328],
            [0.325238431053424, 51.122882580279857],
            [0.325344813840797, 51.122525216481506],
            [0.326199454826759, 51.122440760759829],
            [0.326347981619569, 51.122069066898518],
            [0.326967529669555, 51.121970397150868],
            [0.327268806095989, 51.121083924511069],
            [0.328860366476454, 51.119371320169712],
            [0.328436558701897, 51.11909916175825],
            [0.32937279839186, 51.11899057284684],
            [0.32950908317312, 51.119256766337259],
            [0.329851642235637, 51.118964841418581],
            [0.329355740365639, 51.118282215262035],
            [0.330051932170437, 51.117805168516604],
            [0.327967873782906, 51.116456277317667],
            [0.327874270266908, 51.115930215684763],
            [0.328338384010349, 51.115583711150336],
            [0.327720358742778, 51.115113961446944],
            [0.32653390509234, 51.114705887840131],
            [0.325042416538981, 51.1148812606014],
            [0.324744345838896, 51.114066970660176],
            [0.325431366032, 51.113407562888547],
            [0.324544434509685, 51.112133719791267],
            [0.325762226623732, 51.110777539334777],
            [0.326109980186821, 51.111357903388701],
            [0.326663909854906, 51.111036598545255],
            [0.328276941090111, 51.110922637932738],
            [0.329343939930789, 51.109866212417266],
            [0.329867271461289, 51.110215527608439],
            [0.330438927205199, 51.110048541737171],
            [0.331981099312621, 51.107988819502644],
            [0.334183361221144, 51.106500482041554],
            [0.334157763690172, 51.105907412611401],
            [0.334676324935487, 51.105566988950272],
            [0.336383964936894, 51.105123656154412],
            [0.342006356872727, 51.105313320578716],
            [0.344239204026899, 51.10474872535255],
            [0.346589872628153, 51.105157534190546],
            [0.348355311242366, 51.105011454726537],
            [0.348985463552713, 51.104620158758095],
            [0.350140737573643, 51.105006147201856],
            [0.352265202930443, 51.104419293975219],
            [0.353942412554156, 51.104871185941263],
            [0.358423243503424, 51.104776360782438],
            [0.358873212466597, 51.104551432077507],
            [0.358866569036118, 51.104308736753893],
            [0.358205048405306, 51.104334676776396],
            [0.358357087507335, 51.103953874491758],
            [0.356091810903698, 51.101039740185357],
            [0.355234714692378, 51.100519192790934],
            [0.354641094189762, 51.100601304260088],
            [0.353817474277169, 51.099628589409882],
            [0.352327735667188, 51.099207998980333],
            [0.351026948761986, 51.099301624825621],
            [0.350744069305138, 51.098990737310103],
            [0.351027921817239, 51.098448105211411],
            [0.350379077455846, 51.098046546786335],
            [0.350618338362974, 51.097666698562371],
            [0.351669353859931, 51.097436902134497],
            [0.352308145844948, 51.096205403093663],
            [0.353261531811729, 51.095797683248129],
            [0.35395083598353, 51.093621719499041],
            [0.35367447448867, 51.091610900271306],
            [0.352401803257209, 51.089327843747625],
            [0.352101594605054, 51.089377955299966],
            [0.352314143456918, 51.086587430962069],
            [0.350792819205269, 51.084071028565013],
            [0.356743114062751, 51.0830635028342],
            [0.357840549510754, 51.084034270958114],
            [0.361653076810693, 51.085435852233068],
            [0.362245393247579, 51.086298968774685],
            [0.364660862594127, 51.088161282073663],
            [0.366594593286262, 51.088416233276632],
            [0.368828859703655, 51.088152419424766],
            [0.369925243463767, 51.087614850811264],
            [0.370342653418425, 51.087790756546966],
            [0.371983919159123, 51.086850888453213],
            [0.373030254739288, 51.088321704876712],
            [0.373875975366884, 51.088467315225429],
            [0.375265756817116, 51.087720477770951],
            [0.381662423185864, 51.088036379601142],
            [0.383241891035532, 51.087565285461672],
            [0.382718781209553, 51.086889730160465],
            [0.383821161039711, 51.086472423461544],
            [0.384100619360343, 51.086047614348743],
            [0.386572615293619, 51.086161742660884],
            [0.38733457740418, 51.085457258490571],
            [0.390052385300105, 51.086185060961007],
            [0.391614012698407, 51.085786167261972],
            [0.389222099776414, 51.084399691875674],
            [0.39046500554142, 51.083663767635734],
            [0.390071288963709, 51.081815510320716],
            [0.390801781134281, 51.081333791598773],
            [0.393314736450716, 51.083075707012789],
            [0.396445863391609, 51.083098777946724],
            [0.39791479589081, 51.082511931140523],
            [0.395154650009341, 51.080410033643808],
            [0.392224996714359, 51.077369895460656],
            [0.395744341269272, 51.073916137550697],
            [0.393932546686156, 51.073830055320826],
            [0.393578992053716, 51.073673611497732],
            [0.394024396345825, 51.073562857362255],
            [0.392794851662346, 51.073168032712083],
            [0.392317467768781, 51.073378368342453],
            [0.392196071973064, 51.072906882110658],
            [0.391083100927391, 51.073083455320138],
            [0.391298282536922, 51.072771465096963],
            [0.390881608043241, 51.072553348549],
            [0.390712763107319, 51.072905761301143],
            [0.390009775012909, 51.072699794819883],
            [0.389767767297606, 51.073017727508187],
            [0.388103951677468, 51.072422179829857],
            [0.387532440660609, 51.072828684735498],
            [0.386470016838592, 51.072489737972553],
            [0.385232144965377, 51.072708378687246],
            [0.384920457142155, 51.07303222030761],
            [0.384215511948573, 51.072593320746861],
            [0.382608816373019, 51.0727744896571],
            [0.381984863536955, 51.072355510319497],
            [0.381490629745108, 51.07276670559412],
            [0.380394927382542, 51.072668515276284],
            [0.379870223750896, 51.072263691655728],
            [0.37944388453894, 51.072802105963966],
            [0.378384602692077, 51.072495399558846],
            [0.378097523795023, 51.072798935505666],
            [0.376807867548307, 51.072425852513121],
            [0.376078456890227, 51.072510838069476],
            [0.375013866326765, 51.072127762387574],
            [0.375231959764968, 51.071898485745848],
            [0.374645529945571, 51.071318616269672],
            [0.373870778596999, 51.071214741542846],
            [0.373597498174825, 51.070728335238684],
            [0.371619988031368, 51.069854723981557],
            [0.370684540680309, 51.069790064734654],
            [0.367607483384076, 51.068263259335865],
            [0.367265149224516, 51.067792627748602],
            [0.365786280852814, 51.067511396166168],
            [0.364944767721489, 51.06766423657141],
            [0.363017222005955, 51.066981036560371],
            [0.361326223718924, 51.065279431054066],
            [0.361501192100586, 51.062435672736108],
            [0.35892617542277, 51.059482880097292],
            [0.356682105818851, 51.057869719572309],
            [0.356985277597052, 51.057123419347171],
            [0.356820372155192, 51.051010100854327],
            [0.35828400661799, 51.046060084275609],
            [0.357924329397992, 51.044624745605908],
            [0.355662176182104, 51.042808673386489],
            [0.354833539317038, 51.041210100340962],
            [0.355244861803449, 51.036831740160395],
            [0.356087705012518, 51.036434308084466],
            [0.354932382876072, 51.03387639283536],
            [0.355544369785906, 51.033006048663232],
            [0.355466098714979, 51.031856424409341],
            [0.353241891629479, 51.03007101999345],
            [0.352660316074249, 51.030049457500738],
            [0.35031044330645, 51.028144213748156],
            [0.347522044315725, 51.026559820943987],
            [0.346899974874205, 51.02582044192237],
            [0.347387693073273, 51.025481470070332],
            [0.34683238735913, 51.024567169279152],
            [0.347139246644584, 51.024176969832418],
            [0.346572210291706, 51.024102921510988],
            [0.347157912169748, 51.023360859862485],
            [0.347688661567458, 51.023394263263661],
            [0.348126584844847, 51.022973546084053],
            [0.346872553941897, 51.022539166624945],
            [0.347691242243401, 51.022118894569992],
            [0.347617247441143, 51.021839776043961],
            [0.348844439137801, 51.022025559623827],
            [0.348992629121842, 51.021571991519316],
            [0.34970010616034, 51.021660291069367],
            [0.349527041188436, 51.021198794109054],
            [0.349920635436717, 51.021171090138402],
            [0.350238320780856, 51.020403824923946],
            [0.351394288422965, 51.020450711642013],
            [0.351686444499348, 51.020109361955619],
            [0.351866226179932, 51.018336665328867],
            [0.35266758261066, 51.017970668970676],
            [0.353895918329352, 51.017899153639888],
            [0.353922262471519, 51.017490304823781],
            [0.352885403152152, 51.017203608423294],
            [0.351580182810718, 51.017586946711191],
            [0.34945653451258, 51.017519958163703],
            [0.349359196331778, 51.017004773630369],
            [0.348838772896141, 51.017343513156042],
            [0.348552384285988, 51.016952646178225],
            [0.347667400170677, 51.017074764951502],
            [0.347220142420671, 51.016349861308186],
            [0.346056259436576, 51.016341766181021],
            [0.344643613982693, 51.015472552573577],
            [0.344317688955372, 51.01571023466942],
            [0.343574085955941, 51.015327632125334],
            [0.341715452213347, 51.015594251048057],
            [0.336207999969313, 51.0139858284073],
            [0.333969095889799, 51.014089947293186],
            [0.333545012900543, 51.014446479347278],
            [0.333368890726838, 51.013721503031277],
            [0.331133055543596, 51.013886663822035],
            [0.328630596059154, 51.012885210842015],
            [0.328233628125422, 51.012104371753864],
            [0.328666438767593, 51.01203548534081],
            [0.328733804617611, 51.011445050913416],
            [0.329954710636409, 51.010460168355088],
            [0.330385387865593, 51.006765930535714],
            [0.330820213870448, 51.006510824466318],
            [0.330673193216777, 51.006023597907046],
            [0.330188470240993, 51.005880375598565],
            [0.330057214252088, 51.004029377992318],
            [0.32934689338661, 51.003963502387521],
            [0.32888459075875, 51.003442089239776],
            [0.327830806084088, 51.003092553973232],
            [0.32590373439945, 51.001413137317769],
            [0.322576943875671, 51.000100598799683],
            [0.323403724615387, 50.999531936443859],
            [0.323443808938644, 50.998907870749832],
            [0.324622052933582, 50.998100167403202],
            [0.321557080200772, 50.996687954733524],
            [0.324706206060714, 50.99573492839432],
            [0.323312435477962, 50.994566491956398],
            [0.322329500260875, 50.992805274680642],
            [0.3235698767649, 50.990419735041179],
            [0.323164525044874, 50.989436684789013],
            [0.323583380150376, 50.988635999274635],
            [0.323117200063898, 50.988203679218685],
            [0.322983317586018, 50.987092012621829],
            [0.323831518524602, 50.986899758114767],
            [0.324269801390822, 50.986146350078336],
            [0.326995298014392, 50.984914784210908],
            [0.328175683654139, 50.981196604930197],
            [0.329004234723688, 50.97987418268999],
            [0.328874319620247, 50.978160756108636],
            [0.330242857366843, 50.974877695287709],
            [0.330123083123638, 50.973054341757901],
            [0.32763457056226, 50.970492172077968],
            [0.326454635988123, 50.966548516408849],
            [0.325797441539951, 50.966080440047499],
            [0.324959824512555, 50.964423372792993],
            [0.323517252928363, 50.963884579380014],
            [0.32399633180805, 50.962925297526695],
            [0.326704198695107, 50.960997054727265],
            [0.327983813142828, 50.960841137065074],
            [0.334969622448222, 50.961191801641228],
            [0.338484061658509, 50.960646589166892],
            [0.340687708783351, 50.960713954962998],
            [0.34261725311652, 50.960116737747789],
            [0.343555546511968, 50.959150855320594],
            [0.343981833097214, 50.957349823457434],
            [0.341520604567909, 50.957084426187748],
            [0.337739559941044, 50.953787504159081],
            [0.335904083265993, 50.953048073905222],
            [0.338785861423589, 50.950719431904751],
            [0.338144187271744, 50.950359939409772],
            [0.337606745791656, 50.947849724763309],
            [0.340975510630661, 50.946607623549809],
            [0.343487894310088, 50.944334832495862],
            [0.343183514641755, 50.942358693780342],
            [0.342443375506583, 50.942247633432146],
            [0.341292226884974, 50.941436095530406],
            [0.34266122528714, 50.939445268686185],
            [0.347621398239997, 50.938274450890908],
            [0.351421082499382, 50.938680046538423],
            [0.353473161002128, 50.93765476137213],
            [0.354634644715055, 50.935963871260327],
            [0.355833436132227, 50.935435126411889],
            [0.356605095196534, 50.935270251816974],
            [0.360852368226896, 50.936001075464453],
            [0.362230902180119, 50.934807577049881],
            [0.364744179244396, 50.934155896621427],
            [0.36681793910436, 50.932866411713754],
            [0.366569656209197, 50.930611275043518],
            [0.36246595194809, 50.925825098588625],
            [0.36248254653081, 50.924102427499328],
            [0.363189124149884, 50.923892955885997],
            [0.363781055293309, 50.92408872941877],
            [0.363753995286988, 50.923835648863069],
            [0.363029569677896, 50.923187465289182],
            [0.358965077562223, 50.92233431374828],
            [0.358362240688606, 50.921415625504281],
            [0.357733341280731, 50.917307317558198],
            [0.359926487395792, 50.917122688661472],
            [0.361776183444941, 50.917348796062839],
            [0.363608333228943, 50.917059877444593],
            [0.364654651690287, 50.916509840488359],
            [0.364033498581597, 50.916100608149684],
            [0.364122337116478, 50.914934094516553],
            [0.363511006364457, 50.91443741937298],
            [0.363866065213784, 50.914369069906272],
            [0.363751555391567, 50.913934283948564],
            [0.361528712298677, 50.913472923271513],
            [0.360297552428262, 50.913743362096952],
            [0.358200407721339, 50.913488055259528],
            [0.359076422997225, 50.911258744638005],
            [0.361018234209027, 50.908418767013686],
            [0.36064393348808, 50.906053737750696],
            [0.361014387825575, 50.905194517686823],
            [0.362458768900089, 50.904363927603619],
            [0.361916790783053, 50.903801084083526],
            [0.362942689640879, 50.903443043852391],
            [0.364087181290449, 50.902337893231177],
            [0.364594773978668, 50.902951789669324],
            [0.367271815760387, 50.902001719792651],
            [0.368029106108713, 50.904003699550273],
            [0.368600308956847, 50.903851802206972],
            [0.368146439401005, 50.90194350214076],
            [0.370426221401371, 50.901127334936888],
            [0.370088171777963, 50.900436269708749],
            [0.372506592870102, 50.899803420202254],
            [0.374274063148562, 50.8983976929281],
            [0.372122398523562, 50.896190259917233],
            [0.371089551135656, 50.893579615385207],
            [0.36953734666294, 50.892584932278822],
            [0.368623766787558, 50.891059215168056],
            [0.367164922435313, 50.889996050605681],
            [0.366684790359515, 50.889108191347034],
            [0.368257004889515, 50.887641754950742],
            [0.369750683808681, 50.887683382340981],
            [0.370097685217276, 50.887068342057724],
            [0.371215749320134, 50.886741630898122],
            [0.370971746348695, 50.886388628677047],
            [0.372625505837347, 50.884236931582549],
            [0.37439085506732, 50.883052490545623],
            [0.375435635643412, 50.883020433107149],
            [0.377495547049714, 50.883574781432323],
            [0.379207688379636, 50.883609137201709],
            [0.380513888479566, 50.884744520502295],
            [0.380641942212049, 50.885331913949798],
            [0.384724665725026, 50.887126493116909],
            [0.38545408863863, 50.886903822435436],
            [0.388660791386751, 50.887772700073839],
            [0.389793923411336, 50.887798066421503],
            [0.392394557721814, 50.889046208337],
            [0.394624526253046, 50.890882912798361],
            [0.395855874124462, 50.891435052737847],
            [0.398674386316605, 50.892045404748977],
            [0.402555677828684, 50.893713993231458],
            [0.405482000567195, 50.8938731609706],
            [0.408459892985216, 50.895031325851221],
            [0.409673072852212, 50.895116007430552],
            [0.41224076605037, 50.896423752624635],
            [0.414112248867511, 50.896806870936501],
            [0.416327680190536, 50.896855455428508],
            [0.417074921452279, 50.89604310946735],
            [0.420058450448519, 50.894750889666113],
            [0.419912331840252, 50.893029763509283],
            [0.422626392101281, 50.893236074093153],
            [0.422989543097989, 50.894944596625216],
            [0.427243957265973, 50.894687092489569],
            [0.428611519379835, 50.895059772899131],
            [0.430332144585959, 50.8943466984664],
            [0.433877988954258, 50.894247611689089],
            [0.435816003620543, 50.893587482993141],
            [0.43504603794154, 50.892866939713642],
            [0.43114874335074, 50.893014758166082],
            [0.429751946661051, 50.892298244329275],
            [0.42958676574188, 50.891829496055919],
            [0.430918462513839, 50.891296295395342],
            [0.432951162746701, 50.891090239525504],
            [0.434387470716874, 50.889810109494299],
            [0.437734255755498, 50.889881445766356],
            [0.438836708385838, 50.889726196172226],
            [0.439523619344795, 50.889292713696427],
            [0.439743589047255, 50.887243762878782],
            [0.441139136963294, 50.885216991527656],
            [0.441140151844645, 50.884474959128248],
            [0.44418090641313, 50.882401125809928],
            [0.444536663251148, 50.881701128581653],
            [0.449055950498573, 50.878955799086015],
            [0.446468130348426, 50.877753614542677],
            [0.445309361659895, 50.876067229918377],
            [0.445704342437645, 50.874922997157022],
            [0.445246815515762, 50.874546740322344],
            [0.446678253799991, 50.873460824427276],
            [0.450130028628853, 50.87376703934131],
            [0.447568706133382, 50.872522950875634],
            [0.44464592299124, 50.869665644857207],
            [0.443687123686473, 50.87008506921142],
            [0.44139375334061, 50.868385529541889],
            [0.441167392897616, 50.867834430998258],
            [0.440600383362845, 50.867972211129796],
            [0.435113134918986, 50.866562372820574],
            [0.431505736457683, 50.866148333610532],
            [0.429744806431901, 50.865000499805745],
            [0.426729116642235, 50.864100001000871],
            [0.425864112126391, 50.863124144593236],
            [0.424151742136603, 50.862880022291435],
            [0.421117036648075, 50.860099113152636],
            [0.421657291198713, 50.859610314222543],
            [0.420700862826185, 50.858484317983987],
            [0.41940768853797, 50.857632422467248],
            [0.41740999855566, 50.856592747097601],
            [0.415436487862266, 50.856074193785794],
            [0.41394866384726, 50.856182338827026],
            [0.411985984753796, 50.855762438112521],
            [0.407952238710706, 50.852320092874763],
            [0.408058779153474, 50.850913019562384],
            [0.405804128883803, 50.850473859613807],
            [0.401674398127867, 50.850371878283802],
            [0.401676658247105, 50.849752140609937],
            [0.398540093298849, 50.848819232188426],
            [0.398831859921975, 50.84830326933406],
            [0.394501175837387, 50.846659088531666],
            [0.393938431723016, 50.846017669724596],
            [0.3899860249582, 50.844401535751082],
            [0.386234250798545, 50.843927907081991],
            [0.384073496101153, 50.84295126319396],
            [0.381902103122946, 50.843325703147173],
            [0.380938804069782, 50.842680708812864],
            [0.380532258580635, 50.842926448944581],
            [0.379503743828907, 50.842478843983955],
            [0.377810426781404, 50.842310107744261],
            [0.377150758017327, 50.841488905416369],
            [0.375741850316151, 50.840906013613676],
            [0.374924357276973, 50.840136580999818],
            [0.374541083019961, 50.838882519425539],
            [0.373825569761021, 50.83816226928959],
            [0.374625648817609, 50.837135053670089],
            [0.376059141161811, 50.836443000929371],
            [0.376335059752505, 50.836646042084809],
            [0.376996187142059, 50.836435601766112],
            [0.376770649988376, 50.834149412541933],
            [0.377260175395159, 50.833022375105884],
            [0.376553482979518, 50.833117722447405],
            [0.375571727838086, 50.832634953762096],
            [0.375118097280847, 50.831784360175341],
            [0.373786924188523, 50.832251267932534],
            [0.372795943837126, 50.831893681176744],
            [0.372505022185624, 50.831645966712308],
            [0.375311012211684, 50.828591139300734],
            [0.37611676466454, 50.825973644992956],
            [0.377703346837064, 50.823522807649177],
            [0.377483006026523, 50.820303824618961],
            [0.363357685220688, 50.816072293958577],
            [0.353886288168633, 50.811912516330771],
            [0.349934542097676, 50.809827341230388],
            [0.343687918620852, 50.80445318277831],
            [0.340683247829301, 50.801360215714169],
            [0.339189796812987, 50.799192936002129],
            [0.333966328537095, 50.802166695511069],
            [0.332904028278458, 50.802164566763949],
            [0.332458021721929, 50.802288611773228],
            [0.332972489070958, 50.804125695972871],
            [0.331482008714226, 50.804321892983694],
            [0.330288693564789, 50.805469997580325],
            [0.32866004588802, 50.804829775989511],
            [0.326940962862698, 50.805025082663981],
            [0.326928654283179, 50.805405775558171],
            [0.325920840121448, 50.806090538332285],
            [0.325332994256849, 50.806372081580555],
            [0.324793835540762, 50.806233529856769],
            [0.323804727501718, 50.806809972785437],
            [0.32404023236302, 50.807865674829877],
            [0.320663069156707, 50.806600011836792],
            [0.320169061643749, 50.806971400401814],
            [0.31785349319938, 50.806732334460804],
            [0.315629549211542, 50.808173283077188],
            [0.315803289361817, 50.808672598288638],
            [0.313541039463817, 50.808738183698871],
            [0.310372327334177, 50.807808961385071],
            [0.30408964237068, 50.809189714903738],
            [0.302092499911685, 50.808296477033224],
            [0.300137141312905, 50.809870331401576],
            [0.301224786796193, 50.811551437142406],
            [0.300743288111145, 50.811690450111875],
            [0.3000458486885, 50.81283744831461],
            [0.295419242261598, 50.812690257279876],
            [0.289386007212168, 50.813068768523856],
            [0.287214120674468, 50.812954886199194],
            [0.28517316158344, 50.812498426421165],
            [0.283205801619155, 50.811658246954643],
            [0.279826646474999, 50.809313969163377],
            [0.277798853156271, 50.807343440665171],
            [0.276871801719012, 50.804771282116768],
            [0.275830484440456, 50.80486895537873],
            [0.2740134965582, 50.807641259663249],
            [0.271452444906497, 50.81006197303045],
            [0.26915124561815, 50.809677757020246],
            [0.267321978976472, 50.810566858585631],
            [0.260849870680764, 50.80608850202713],
            [0.261298211879608, 50.805291041097675],
            [0.26230387590162, 50.804865910596895],
            [0.260910298925373, 50.804047504341341],
            [0.26144020750645, 50.803499392254906],
            [0.260794761841064, 50.803124267213335],
            [0.261540924099391, 50.802028727734893],
            [0.258884615521622, 50.800863327754236],
            [0.259975131588052, 50.799992272004523],
            [0.258776203529868, 50.799380526973863],
            [0.257588409738986, 50.799264120674252],
            [0.256405637763189, 50.800076679597659],
            [0.254189915373098, 50.799037557503489],
            [0.250455253564944, 50.79884346543458],
            [0.249418688846999, 50.801402451553351],
            [0.248483899893725, 50.802181364638159],
            [0.246138074069414, 50.803103476176418],
            [0.247197993979159, 50.804102969127158],
            [0.245852379688347, 50.804752168406715],
            [0.245849645146993, 50.80549421980389],
            [0.244078600153143, 50.805382609452742],
            [0.242162539967842, 50.80313140834469],
            [0.240470093251868, 50.802850945707561],
            [0.239423620657535, 50.802158726339322],
            [0.237640233946054, 50.802528430572572],
            [0.237298134475411, 50.80211138033998],
            [0.236635764857246, 50.802239207392347],
            [0.236217258631961, 50.801499838355383],
            [0.234229878498153, 50.801669239891993],
            [0.233770769110367, 50.801267911932065],
            [0.233077926331982, 50.801470951939983],
            [0.227694856911436, 50.800876873236412],
            [0.22827433879547, 50.800080634548912],
            [0.2268943295981, 50.79944234397253],
            [0.229863147178684, 50.797710950186627],
            [0.23306319689794, 50.796815982939144],
            [0.233870103365097, 50.795762630823617],
            [0.232387861991243, 50.793454393422508],
            [0.23073721733362, 50.788649047533191],
            [0.227599516841728, 50.783293784253729],
            [0.222775401225876, 50.779752293999216],
            [0.218750580217904, 50.776119851372322],
            [0.216390949173516, 50.774724799334912],
            [0.217020058545203, 50.774500582695914],
            [0.214969959183999, 50.76353186872651],
            [0.21717799730157, 50.762063460925141],
            [0.215066287622076, 50.760977557672113],
            [0.212212129510949, 50.760142247092951],
            [0.213585398578487, 50.760126075528269],
            [0.217455781626664, 50.757141185941627],
            [0.212879500834862, 50.753879688329029],
            [0.213109805841924, 50.753712526182625],
            [0.212077998867946, 50.752787743395373],
            [0.209352892472154, 50.751339227286053],
            [0.211442943499138, 50.749330823474452],
            [0.213531851540137, 50.745618945745903],
            [0.214646378047813, 50.744544584892253],
            [0.215460806251585, 50.73972633007434],
            [0.213585626235094, 50.740628072079971],
            [0.212442220827518, 50.742291177339837],
            [0.211172202309038, 50.742983525263597],
            [0.209914790624237, 50.738405156670844],
            [0.205174496425662, 50.739566105293839],
            [0.203216489486316, 50.740549292178486],
            [0.199056851806998, 50.743522986477949],
            [0.194132360150886, 50.745854367836237],
            [0.188740494206125, 50.747840851292501],
            [0.182301095143894, 50.749352029455032],
            [0.17944655187374, 50.749579011018632],
            [0.177644515276977, 50.750521969751226],
            [0.174451538983712, 50.751126597758166],
            [0.16316276206211, 50.754672435536634],
            [0.158328749372309, 50.755828699506203],
            [0.153954349274174, 50.757859469697991],
            [0.148914720957838, 50.75908928966313],
            [0.147837414093445, 50.760892624056218],
            [0.148415426997434, 50.761252522028713],
            [0.147800399702703, 50.761199097299269],
            [0.148583697877606, 50.763687657664576],
            [0.150254736362358, 50.765586036149877],
            [0.150220320940352, 50.766443785736932],
            [0.151277435353716, 50.767476568205829],
            [0.151822115126337, 50.768807502009658],
            [0.149021333807672, 50.770143478070395],
            [0.148901302978011, 50.770742882072213],
            [0.15149590113834, 50.771341694821523],
            [0.153951030391417, 50.770879053183663],
            [0.154406277586007, 50.771680989345334],
            [0.153604611215937, 50.772230033585785],
            [0.151527160684422, 50.772270183195545],
            [0.150910377098621, 50.772676392350469],
            [0.150777988536844, 50.774335501754365],
            [0.152013322250541, 50.774858636365629],
            [0.151930742807611, 50.775129975895574],
            [0.14915701531929, 50.773939080745635],
            [0.146499780062361, 50.773337770966158],
            [0.145841830867113, 50.774302329028529],
            [0.144004666724871, 50.775225613939682],
            [0.142045895455304, 50.777028002360908],
            [0.14312507713545, 50.778014583600452],
            [0.141869203377415, 50.778811127914913],
            [0.143178163766282, 50.78004441586004],
            [0.145191624213285, 50.780241233004297],
            [0.143679171098597, 50.78079428638474],
            [0.144942701694356, 50.782241543738124],
            [0.146805528861168, 50.782058866179796],
            [0.145486020206587, 50.783015811031611],
            [0.147937958728706, 50.785564495288263],
            [0.146914116210298, 50.786504317154701],
            [0.147468768802874, 50.787802706764552],
            [0.144529247852252, 50.789637577110859],
            [0.144635421568747, 50.790407294537992],
            [0.149430052382604, 50.792019754986171],
            [0.150703526905776, 50.792065529123263],
            [0.152684571914138, 50.79148305262926],
            [0.154121655255192, 50.791723633462759],
            [0.154905498946922, 50.793124781216534],
            [0.157303502570039, 50.793986125564068],
            [0.157538204045893, 50.795723888775029],
            [0.158392872526339, 50.79659486585922],
            [0.156341082068482, 50.797185041813449],
            [0.156797598949667, 50.798100266056366],
            [0.156406802272053, 50.798982591681693],
            [0.154006521310053, 50.799774334430033],
            [0.153409070202678, 50.800608301762992],
            [0.15569894204016, 50.804063422694711],
            [0.156027728100203, 50.804559472692908],
            [0.15612059103923, 50.804640351609301],
            [0.156968142201404, 50.805378509429943],
            [0.159033120403787, 50.805744124119634],
            [0.159595699667274, 50.806740116786919],
            [0.158735189777882, 50.807963916444024],
            [0.160814452508939, 50.81026650367383],
            [0.162208986948452, 50.811170620354716],
            [0.162950521402482, 50.813732699636219],
            [0.164209870897836, 50.81441175613876],
            [0.163769918351957, 50.815213172725471],
            [0.164267509490776, 50.815566391486968],
            [0.163565616031052, 50.815246642118979],
            [0.163968290642408, 50.814375766208165],
            [0.162769832422435, 50.8137855161742],
            [0.161949858230411, 50.811153838659749],
            [0.161053523340539, 50.810709966290872],
            [0.158608173851077, 50.808102973253547],
            [0.159377354849617, 50.806837695205324],
            [0.158827924850226, 50.805818973586561],
            [0.156688707398448, 50.805393172597455],
            [0.15595031160154, 50.804596019236733],
            [0.153190106272669, 50.800630332135881],
            [0.153834654901541, 50.799678576520776],
            [0.156334017692711, 50.798788771459513],
            [0.15656388259955, 50.798017347923626],
            [0.156116073664843, 50.797137037719395],
            [0.158033922533425, 50.796534954161075],
            [0.157343564589358, 50.795780553048573],
            [0.157041218318029, 50.794083613540096],
            [0.154704728554548, 50.793202240245513],
            [0.154034777771133, 50.791870938225905],
            [0.152574084317184, 50.79164158469424],
            [0.150921510395283, 50.792177529254836],
            [0.149644711795058, 50.792183083555493],
            [0.144904904036482, 50.790745902421257],
            [0.143701066292089, 50.79268641904423],
            [0.140468056656003, 50.791275313720114],
            [0.138488878381564, 50.791124551695091],
            [0.135598888262857, 50.791723434302519],
            [0.130522603561211, 50.791706557620287],
            [0.121822074356461, 50.793092832670546],
            [0.119202020306948, 50.794239512858837],
            [0.114464527322979, 50.79739597855955],
            [0.111564897768382, 50.801983172111001],
            [0.111553917054797, 50.802211812597605],
            [0.111339929701069, 50.804894929110127],
            [0.109396015181149, 50.809507924938366],
            [0.108881509593602, 50.812928558698303],
            [0.104827651764799, 50.816064151902815],
            [0.103220422563557, 50.818525049682336],
            [0.100876149768054, 50.819625857534788],
            [0.097906091283173, 50.82032506174064],
            [0.099904243811121, 50.822962943001798],
            [0.101055302054332, 50.825482943586692],
            [0.105770648101992, 50.829168088658307],
            [0.107568526909624, 50.831439812964376],
            [0.111260003503655, 50.83082264648467],
            [0.112208600445338, 50.83236318846965],
            [0.114789418613848, 50.834947996221295],
            [0.117418421842999, 50.83592379453718],
            [0.11900464313858, 50.837423927244721],
            [0.119494228278847, 50.837947462235753],
            [0.116132750066806, 50.839024775553185],
            [0.118591460358511, 50.840938984454702],
            [0.118845251112154, 50.841775288495754],
            [0.120992072416189, 50.841285692866897],
            [0.124497064818003, 50.846306071790231],
            [0.115015053604424, 50.849644136521405],
            [0.117587875587279, 50.851933134395303],
            [0.119180886709906, 50.854166128333794],
            [0.120416480943662, 50.853810013043812],
            [0.121786600941026, 50.854446142459174],
            [0.12480742850932, 50.854761709344942],
            [0.127299860661633, 50.856765959706529],
            [0.12248136565087, 50.858795453625895],
            [0.122125144505207, 50.85921204588],
            [0.123807649121878, 50.862309442774588],
            [0.120779098280995, 50.863306168119095],
            [0.116752666619735, 50.865680766309985],
            [0.116763813914225, 50.866428838882925],
            [0.114611447615057, 50.870685001669948],
            [0.105946152914625, 50.871327367066115],
            [0.105264643315508, 50.872006095115488],
            [0.102917774750952, 50.871401767630033],
            [0.101185318666391, 50.87042568555826],
            [0.099041459988502, 50.869813128191183],
            [0.098469909106079, 50.869998784348873],
            [0.096662334754742, 50.871779643428489],
            [0.096555418673964, 50.872342768511714],
            [0.098020747114281, 50.8815187359682],
            [0.096781717243496, 50.88298988029274],
            [0.091850731614729, 50.884913972084853],
            [0.095128465092579, 50.888856442006876],
            [0.098727651811985, 50.892313680247717],
            [0.101002013825912, 50.89384394691011],
            [0.101081564685137, 50.894916354359545],
            [0.103220186989986, 50.896617202833092],
            [0.104926132501199, 50.900703732086882],
            [0.110071209343475, 50.904622887845399],
            [0.111364136795749, 50.907389330153691],
            [0.112830418080047, 50.908765803254262],
            [0.119839936832669, 50.912762291146379],
            [0.121382339140145, 50.914270381475951],
            [0.121886612944711, 50.915285590023139],
            [0.116960106072617, 50.915259921952511],
            [0.112553714445986, 50.915252514138722],
            [0.110925728502485, 50.915651603666795],
            [0.105595154229768, 50.919565665345203],
            [0.101037932446207, 50.920893391519733],
            [0.095541452832945, 50.923110180285249],
            [0.08997459906273, 50.920656700898192],
            [0.088495561510546, 50.918798116933708],
            [0.086612474266018, 50.91929046513502],
            [0.082670059749464, 50.919740398174909],
            [0.078280664430176, 50.921656013611063],
            [0.072155970720853, 50.922762278127969],
            [0.068427938712579, 50.924911300286844],
            [0.067999086615439, 50.924706650243202],
            [0.063463960855517, 50.925668267955359],
            [0.060620443466872, 50.925273314116062],
            [0.057277152022123, 50.926095831207853],
            [0.057690551537921, 50.926660534276373],
            [0.056713675355205, 50.927064470694525],
            [0.056659653272323, 50.927904519204006],
            [0.055445928093268, 50.928488890278054],
            [0.056189870367592, 50.928836430279333],
            [0.055192212050986, 50.929351339929859],
            [0.056075791609521, 50.929637964440275],
            [0.056646496687548, 50.930929275794789],
            [0.055386505624037, 50.93143441814788],
            [0.056162240475071, 50.931953175104411],
            [0.055602574159671, 50.932848893138804],
            [0.05403541072274, 50.933651717953566],
            [0.054420527708768, 50.933961513670006],
            [0.055416490654889, 50.933567154701123],
            [0.056003375490072, 50.93396602613339],
            [0.055758016307972, 50.934534240789709],
            [0.054624671910027, 50.934879757248673],
            [0.054943055834874, 50.935322031083707],
            [0.05435444770222, 50.935853114774659],
            [0.054853420771414, 50.937292290410696],
            [0.055429019015727, 50.937757016099091],
            [0.055185436565903, 50.938818042568869],
            [0.05347859885896, 50.939589141169328],
            [0.053843711421817, 50.940477573587899],
            [0.053194317882959, 50.940761498876455],
            [0.053130874857805, 50.941324709060375],
            [0.052011563897356, 50.942187078706752],
            [0.051966994221911, 50.942791326473461],
            [0.050787864425321, 50.943136709172059],
            [0.051427249859246, 50.943982557994062],
            [0.049931583079495, 50.944090668297925],
            [0.050297495485054, 50.944965606720515],
            [0.049265881637449, 50.945267912254792],
            [0.048203221355195, 50.946515970026887],
            [0.048999918538486, 50.947021815829643],
            [0.048461677116374, 50.947634723861242],
            [0.049426100809095, 50.94797214369278],
            [0.048143203362397, 50.948745611545007],
            [0.04922303632453, 50.948955099683005],
            [0.049536461702589, 50.950092671892506],
            [0.050587454013946, 50.950551773678178],
            [0.050594920520618, 50.951497758307035],
            [0.049753896177557, 50.952316543648912],
            [0.050999684613047, 50.952541988703395],
            [0.050022051671147, 50.95284515009925],
            [0.050852287496132, 50.95365707280304],
            [0.05059252128615, 50.954710276129269],
            [0.049281393433445, 50.954975219026679],
            [0.050483966390077, 50.954993678720641],
            [0.049956683051904, 50.955630682469256],
            [0.050583461579052, 50.956380526259437],
            [0.050311795560033, 50.956807990665347],
            [0.0509182849125, 50.958422462979044],
            [0.050499384251554, 50.958966730800675],
            [0.04860568791729, 50.958713076957245],
            [0.048876493093029, 50.961114082017453],
            [0.049472729601225, 50.961556890359148],
            [0.05084468124834, 50.961407793723218],
            [0.051865449692332, 50.962144414891668],
            [0.053012254642599, 50.96234729659831],
            [0.053502757618441, 50.963782125902227],
            [0.054130550055586, 50.964002216803209],
            [0.05384592341493, 50.964555825598531],
            [0.054421696377688, 50.96530744528598],
            [0.053542005636366, 50.966932751032729],
            [0.0537468998258, 50.967606356598957],
            [0.052510717597131, 50.968060676788639],
            [0.052686937069595, 50.968697914784435],
            [0.051832504594706, 50.969283116747135],
            [0.050206651907168, 50.968911462589823],
            [0.04984983407029, 50.968150583369358],
            [0.048976272802877, 50.968236962484809],
            [0.048668503278665, 50.96778099713157],
            [0.046816796831815, 50.967486105243644],
            [0.044220003456623, 50.966342655276272],
            [0.041919081864094, 50.966227324136241],
            [0.040355874228427, 50.966760979360302],
            [0.038986868985963, 50.966623907905849],
            [0.037392713086648, 50.968111370043076],
            [0.038519086592602, 50.969081892920336],
            [0.037259386512995, 50.969035525369904],
            [0.037095724111962, 50.969331567057779],
            [0.038104118571857, 50.970079315458619],
            [0.038680292470754, 50.972047818457888],
            [0.038014192373777, 50.972905728615167],
            [0.037944168444273, 50.974006849637298],
            [0.037522471911154, 50.974103242874513],
            [0.037283433891091, 50.973718896099967],
            [0.036089043213149, 50.974289225743703],
            [0.034535531434377, 50.974042000222312],
            [0.03208193087191, 50.975005647994621],
            [0.031713869855291, 50.975319627443575],
            [0.032572392746428, 50.976255295316435],
            [0.031594279141541, 50.976953117892307],
            [0.031371076378172, 50.977714247939971],
            [0.030826039809779, 50.977923381195197],
            [0.029499108865024, 50.977810646858963],
            [0.030239776673135, 50.978493865316771],
            [0.029922269608343, 50.978759294673331],
            [0.029049775128076, 50.978646749660207],
            [0.028186077707529, 50.978964830786573],
            [0.029635530086501, 50.979207654482323],
            [0.028993232091353, 50.980388860499296],
            [0.027536735231944, 50.980442934142502],
            [0.027196687890709, 50.980911099283865],
            [0.026725902543551, 50.980503773644351],
            [0.025533341668744, 50.980501079840614],
            [0.022797141188579, 50.981169948214713],
            [0.020348673098978, 50.980362451301282],
            [0.01722663112579, 50.980216763674385],
            [0.01624972727506, 50.979371164148262],
            [0.01441734368355, 50.978771446103352],
            [0.014386010448187, 50.978409551847101],
            [0.011139510967415, 50.977621920372755],
            [0.008030887131536, 50.981480521624697],
            [0.007699754466168, 50.982424226496178],
            [0.009491017227112, 50.982994179952065],
            [0.008761215513566, 50.98391057138695],
            [0.008696462287454, 50.98713042325371],
            [0.006655615419623, 50.986690652956696],
            [0.005740284278806, 50.987701944312988],
            [0.006952012056024, 50.987847507090848],
            [0.006830802761494, 50.988373904729869],
            [0.00529187828395, 50.988207877846854],
            [0.0046100948831, 50.989415702421262],
            [0.003043018440316, 50.99028436862254],
            [0.003792110822188, 50.990902855640371],
            [0.003333484583511, 50.991371184208774],
            [0.003864572421032, 50.993511493194859],
            [0.003706742262494, 50.993685075117973],
            [0.002380752232517, 50.993338186370785],
            [0.002684206490409, 50.993965218600479],
            [0.001919969916592, 50.994353343547616],
            [0.001664506985712, 50.995007043764687],
            [-0.000595177728647, 50.995682477012693],
            [-0.002682342655583, 50.99705819793401],
            [-0.005881829587194, 50.997490584869226],
            [-0.005050761371666, 50.997926961736702],
            [-0.006029692853638, 50.997897810629773],
            [-0.005794223567829, 50.998403714708857],
            [-0.006235356008982, 50.998752093716831],
            [-0.006509888041829, 50.998332292440935],
            [-0.007752720938034, 50.99833101315086],
            [-0.008183170751497, 50.999295247911711],
            [-0.007385350131262, 50.999554139679404],
            [-0.007231258969499, 51.000090213433182],
            [-0.007407551228929, 51.000333343634416],
            [-0.008670186186653, 51.000436715041964],
            [-0.008271099059521, 51.001135889682544],
            [-0.008967288935787, 51.001187329117279],
            [-0.009337923764163, 51.001918510709814],
            [-0.009972187738966, 51.001918525926726],
            [-0.009833624528097, 51.001326200956782],
            [-0.010667097894093, 51.001567030951819],
            [-0.010849417565896, 51.00116993139541],
            [-0.011498159813559, 51.001364441429857],
            [-0.01171895455629, 51.00036723926322],
            [-0.01513269296845, 51.001230201532529],
            [-0.015157335208827, 51.001521105533456],
            [-0.014467379404278, 51.001824147149733],
            [-0.015568756262881, 51.002000246590434],
            [-0.016436082116573, 51.002517706045474],
            [-0.015810289264448, 51.002586219350214],
            [-0.015804549552672, 51.003021399973832],
            [-0.017041186996265, 51.002967754403301],
            [-0.017398966661526, 51.003201359071987],
            [-0.018510575838804, 51.002636552400858],
            [-0.018512074573941, 51.003136607884684],
            [-0.02012271541521, 51.00347149040924],
            [-0.019178538515155, 51.004422268105905],
            [-0.018760886810855, 51.00572731395841],
            [-0.019149342690464, 51.00581034513111],
            [-0.019130110655181, 51.006529486307272],
            [-0.018603922958281, 51.006701328580192],
            [-0.019969890718947, 51.007997946532008],
            [-0.019913970513125, 51.009444925914153],
            [-0.019512772020427, 51.010128813038058],
            [-0.018454903490882, 51.010733212515419],
            [-0.018709306515896, 51.011186294975012],
            [-0.017956194364371, 51.012198759755698],
            [-0.018355728404076, 51.012524801145915],
            [-0.017603400995293, 51.013183838935547],
            [-0.017740138027625, 51.013621436526101],
            [-0.016676932536474, 51.013813834946355],
            [-0.016545145096442, 51.014530164796383],
            [-0.016034352892013, 51.01480657991457],
            [-0.01944126997956, 51.015722352431204],
            [-0.019505137251884, 51.016632660556475],
            [-0.021540403594623, 51.01711861102563],
            [-0.022198845924204, 51.016789812442063],
            [-0.020070712937053, 51.018618080646931],
            [-0.019748697558729, 51.019516451753262],
            [-0.021604347854924, 51.020741304294624],
            [-0.019661972228625, 51.021585244195251],
            [-0.020487697497191, 51.022586704266018],
            [-0.020036277016472, 51.024647517489456],
            [-0.020353923798105, 51.025994702675682],
            [-0.021003283610229, 51.026485047316164],
            [-0.02194030841062, 51.02651620411865],
            [-0.0207784257535, 51.027478596495769],
            [-0.019926176869052, 51.027699780653968],
            [-0.018508190942917, 51.027676639735979],
            [-0.017953597576354, 51.028007179814615],
            [-0.017678658847046, 51.027732715872077],
            [-0.017119115933789, 51.027778079995862],
            [-0.014994519471839, 51.028839187025703],
            [-0.013527734446567, 51.029122728136741],
            [-0.012100059129968, 51.028791773410262],
            [-0.011238255456617, 51.029434518565218],
            [-0.01014443554454, 51.03129819605185],
            [-0.008241273032196, 51.032476268323791],
            [-0.008070796136911, 51.034190186201045],
            [-0.005425109785284, 51.03488520142978],
            [-0.002592487946677, 51.035215430081692],
            [-0.001592077858661, 51.035758607438858],
            [-0.000665951723612, 51.036961358531272],
            [-0.002156357346812, 51.036998543449393],
            [-0.001915749497619, 51.03771749189206],
            [-0.004256032307849, 51.038292591182959],
            [-0.007542946970139, 51.038050109465424],
            [-0.007930474688791, 51.038992021346594],
            [-0.006645815903376, 51.04007538986783],
            [-0.007664827109638, 51.040961523138776],
            [-0.005724665205299, 51.041229701047293],
            [-0.005689047363646, 51.041494395729124],
            [-0.002736290084712, 51.042319909476554],
            [-0.00204345414866, 51.043071596794988],
            [-0.002400624147032, 51.044021101615662],
            [-0.002120664579041, 51.044592786236912],
            [-0.003535530936324, 51.04682392620149],
            [-0.003028439345755, 51.047568000608997],
            [-0.00183670752171, 51.048439763667623],
            [0.00199553507286, 51.04893625106503],
            [0.004012496289928, 51.049883746798706],
            [0.007693787830692, 51.050910544215121],
            [0.011228506139144, 51.052996463466272],
            [0.013698747060552, 51.057016200298754],
            [0.010464917133943, 51.05730750535011],
            [0.008705893466962, 51.056923163448523],
            [0.007816994140231, 51.057981662578712],
            [0.006080525598862, 51.057524947216386],
            [0.004263108353875, 51.05806785065063],
            [0.004155139633755, 51.057748641662606],
            [0.001635102767636, 51.057519336576085],
            [0.001997224313497, 51.055658715645222],
            [-0.00257838194771, 51.055508585216131],
            [-0.003803838986036, 51.056311941500681],
            [-0.006076134506093, 51.056830976591755],
            [-0.008237900759492, 51.056335439485643],
            [-0.008653731977451, 51.056588042904366],
            [-0.008501555692304, 51.057640361824376],
            [-0.010430093781368, 51.059256928079883],
            [-0.011166292391673, 51.060959296224631],
            [-0.011447760105762, 51.062683600026375],
            [-0.00979132029948, 51.063536743423647],
            [-0.010279958415396, 51.063790580216164],
            [-0.010440636634082, 51.065235836511107],
            [-0.012179755128265, 51.066351817289032],
            [-0.013170511385218, 51.066513456532149],
            [-0.01499717028176, 51.069355790095656],
            [-0.014018716381151, 51.070439043074913],
            [-0.013283652091448, 51.073970792830401],
            [-0.016875663818657, 51.074229648458505],
            [-0.019609168244527, 51.075672649394562],
            [-0.021868573380956, 51.075951031072634],
            [-0.024038280730279, 51.07582225731597],
            [-0.026879207689868, 51.075116619021273],
            [-0.028070965962342, 51.081871763970192],
            [-0.03156257302598, 51.087841861916196],
            [-0.031811004471291, 51.091340807857826],
            [-0.031071848415374, 51.092571221487525],
            [-0.032828866231303, 51.09239216091818],
            [-0.035154234984822, 51.092449257571452],
            [-0.036220518411964, 51.093000472815746],
            [-0.036223859127284, 51.093460980929351],
            [-0.035512442508245, 51.093836634238436],
            [-0.030861981054433, 51.094217040803109],
            [-0.028073218463036, 51.093133112077673],
            [-0.02701830440183, 51.091746544166732],
            [-0.026196872317667, 51.091753360937233],
            [-0.025402375956404, 51.09421217751251],
            [-0.022376342806003, 51.09537870329364],
            [-0.021606695633118, 51.095910668758975],
            [-0.021738891637254, 51.096594591758823],
            [-0.021360532423308, 51.096759059140176],
            [-0.019580870033832, 51.095992377535225],
            [-0.018331892859317, 51.096376805030175],
            [-0.017686556698023, 51.097006180388945],
            [-0.015847351069205, 51.096563986507427],
            [-0.01423843360313, 51.096814556627827],
            [-0.013871195318063, 51.097552057128837],
            [-0.01202905191523, 51.096346228298778],
            [-0.010892635546109, 51.096502264247746],
            [-0.008083297752984, 51.097765639086177],
            [-0.007190194364185, 51.098440197803448],
            [-0.007092546820353, 51.099149897483983],
            [-0.005292760444163, 51.098920449749194],
            [-0.004268882898494, 51.099180864833912],
            [-0.000690236132705, 51.100985809402921],
            [0.001346584961198, 51.10144828183234],
            [0.002912999900224, 51.102295596280271],
            [0.007783889548712, 51.102560076945586],
            [0.01044593665092, 51.1021860716532],
            [0.011338462306295, 51.102608689464091],
            [0.009982793439556, 51.103758860174487],
            [0.007335551156478, 51.10483946851442],
            [0.006436395604688, 51.10465975140022],
            [0.005002540830737, 51.105261718218614],
            [0.005722404232877, 51.106249417122164],
            [0.00689123555115, 51.106750964996046],
            [0.006701942803605, 51.107723684980186],
            [0.008555754854775, 51.108833090235144],
            [0.00947783485874, 51.110395556636028],
            [0.013147684972621, 51.106080415129071],
            [0.015674433346535, 51.104562882137849],
            [0.018591374615652, 51.103579050488186],
            [0.02149901748856, 51.106968725144903],
            [0.023889488864315, 51.107895937538302],
            [0.025423066881187, 51.10902321174612],
            [0.027183517263419, 51.109032268095277],
            [0.027016467628475, 51.108577408053144],
            [0.031865339103935, 51.108647016517139],
            [0.032308602080388, 51.108102413738692],
            [0.033119280407229, 51.108345524925575],
            [0.032895414037144, 51.108615618636534],
            [0.034188734501499, 51.10862549696806],
            [0.035392249307824, 51.109842921304548],
            [0.036393853792639, 51.109943290074739],
            [0.036782776530993, 51.110470714550878],
            [0.03750647904978, 51.11042392913587],
            [0.038046097021263, 51.109829960725797],
            [0.038471252867973, 51.109946655083583],
            [0.039261376159618, 51.109429251945009],
            [0.041282113690087, 51.11025464076949],
            [0.044533929884821, 51.110647478737221],
            [0.044193653795517, 51.111127374740931],
            [0.041289611308836, 51.110846266956578],
            [0.041485745071224, 51.112217013422132],
            [0.040000705370992, 51.111781582132863],
            [0.036773927920392, 51.112251534718027],
            [0.037425618647846, 51.113197961325788],
            [0.036494640504972, 51.115095572124766],
            [0.034561866654653, 51.114974540088099],
            [0.034151315012373, 51.115939465732033],
            [0.03273214930606, 51.11658288530959],
            [0.028125464152961, 51.117723194787494],
            [0.026862271522355, 51.121802850871553],
            [0.02558820217212, 51.122633423157694],
            [0.025177778757026, 51.123443628789737],
            [0.026335348866943, 51.125436257678174],
            [0.026427660445662, 51.126696408021147],
            [0.025899246670411, 51.126770316413825],
            [0.025469099098656, 51.127326355797116],
            [0.025261991757768, 51.128211279662196],
            [0.026018754271748, 51.131917766601866],
            [0.027785083184351, 51.133183070559078],
            [0.027200165258007, 51.135558429947388],
            [0.02606217906374, 51.137024263052894],
            [0.027334010769239, 51.139853967207252],
            [0.029301010161075, 51.140015000047995],
            [0.031568774347538, 51.139797556229716],
            [0.035208651531902, 51.140387168126736],
            [0.038340819024736, 51.140288543639208],
            [0.041178340643579, 51.141191428565605],
            [0.041681023673599, 51.141017177203594],
            [0.042917945304554, 51.141639488706303],
            [0.049989478251868, 51.14265337284764],
            [0.051220182407783, 51.142228893441683],
            [0.051665522059601, 51.142406341194508],
            [0.052669778835671, 51.142162986169993],
            [0.053930655234276, 51.142483488008004],
            [0.05582014189512, 51.142471867517763],
            [0.058372787311811, 51.141363059778165],
            [0.058736642286969, 51.140833247112397],
            [0.062449323293035, 51.14176337405285],
            [0.06325707326378, 51.141122294312837],
            [0.064004788939068, 51.141154963996428],
            [0.064188786799802, 51.140889112961737],
            [0.065525817941761, 51.141057058916381],
            [0.065431262602918, 51.140732273931235],
            [0.066673352051288, 51.140534958797261],
            [0.068117820487009, 51.140675795024499],
            [0.070528244045872, 51.139687945480794],
            [0.078161020186988, 51.139949141662747],
            [0.080380238881208, 51.140628241453861],
            [0.080934619488265, 51.141130094110679],
            [0.081650635344694, 51.14106159107277],
            [0.083269679835758, 51.141712648482489],
            [0.083548483137415, 51.141490942899757],
            [0.084021479270734, 51.141898902516019],
            [0.084493230102022, 51.141612601351355],
            [0.084623291903997, 51.141865691808377],
            [0.085353730436422, 51.141768130500324],
            [0.085882610318189, 51.142083354576933],
            [0.085998740161923, 51.14167838395862],
            [0.086485154813206, 51.141685893353447],
            [0.08668087782924, 51.141365837217982],
            [0.087052606774027, 51.141493202806707],
            [0.087843859591143, 51.140937681577512],
            [0.088290291607737, 51.14129483674408],
            [0.088812987933032, 51.140997715427631],
            [0.090209615512604, 51.141470086595014],
            [0.090620840206007, 51.141174050400721],
            [0.092530552842834, 51.141674091721349],
            [0.093819103138566, 51.141526018455423],
            [0.093924681996371, 51.141805617825796],
            [0.094396624776027, 51.141714427258968],
            [0.095508200310431, 51.142204431515673],
            [0.095851750245057, 51.142056180221125],
            [0.095979791545849, 51.142326381886704],
            [0.097790032282489, 51.142175207678811],
            [0.09818990521032, 51.142480999689766],
            [0.099480426461749, 51.142124184195275],
            [0.10025118259525, 51.1426319539015],
            [0.103257441342074, 51.142561730061367],
            [0.10426067900621, 51.142961868689952],
            [0.104362133860798, 51.143463667729534],
            [0.105231624510359, 51.143563130813916],
            [0.105453825002707, 51.144078942906347],
            [0.107468047597899, 51.144199141057911],
            [0.107501112706184, 51.144548384611021],
            [0.108604836232155, 51.14467327874884],
            [0.108819848374423, 51.144371722890405],
            [0.109642194892472, 51.144314620676937],
            [0.111739621187661, 51.144628398940782],
            [0.114587551528788, 51.145189376029649],
            [0.11461578732859, 51.145588168401915],
            [0.115320500442778, 51.145897382666618],
            [0.117784443411601, 51.146188234180357],
            [0.118678890913937, 51.146674757502495],
            [0.120032918531533, 51.146425383417316],
            [0.121917563648152, 51.14720869139046],
            [0.122141827304593, 51.146954607357905],
            [0.125129782213617, 51.14719622281546],
            [0.125906256957416, 51.146949181579657],
            [0.128544736784613, 51.147456970074643],
            [0.130574529012997, 51.147165492665358],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000066",
        LAD13CDO: "22UB",
        LAD13NM: "Basildon",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [0.551608276383781, 51.617745562936541],
              [0.554039844530743, 51.61746603436864],
              [0.554066494219171, 51.617117412742616],
              [0.551608276383781, 51.617745562936541],
            ],
          ],
          [
            [
              [0.551608276383781, 51.617745562936541],
              [0.553994875257862, 51.617025440940118],
              [0.553893967191851, 51.616694884129807],
              [0.554931321680031, 51.616773927622006],
              [0.555773738420242, 51.616809521565429],
              [0.556533249393636, 51.616841606920559],
              [0.560591871732287, 51.617012978660156],
              [0.562584313927097, 51.617838241693981],
              [0.56330434476403, 51.617797334478681],
              [0.563928644098847, 51.6165965751009],
              [0.563665241576866, 51.61451227757555],
              [0.561800373553623, 51.614410910318703],
              [0.561594382817956, 51.612471946455088],
              [0.558221038270684, 51.610640749274921],
              [0.556618342978249, 51.608390472678956],
              [0.553613218235174, 51.609018893256049],
              [0.551517717041646, 51.608844141320091],
              [0.55047478693996, 51.603303540166195],
              [0.54932136873123, 51.602333044059748],
              [0.549757122823283, 51.598176747761968],
              [0.549007435107937, 51.596574236114805],
              [0.56553946794499, 51.593854043263576],
              [0.563722799832014, 51.59159414992687],
              [0.562716741646628, 51.590881371687573],
              [0.562271113455731, 51.58935144000786],
              [0.565420585315021, 51.588644982900483],
              [0.565891563615595, 51.588240773136306],
              [0.566012278903881, 51.58812841368686],
              [0.566224989854623, 51.587778415306062],
              [0.567839415406862, 51.587221474066538],
              [0.568764203567838, 51.587312746712939],
              [0.5683991017053, 51.586573390156346],
              [0.567989937415902, 51.586665086312927],
              [0.567013698135882, 51.585507418983816],
              [0.564631768932499, 51.583868758618948],
              [0.557586107009244, 51.580614084689508],
              [0.549403328800806, 51.577423282330749],
              [0.545117543442579, 51.574756281660818],
              [0.542962891332806, 51.571916141037761],
              [0.542205691750205, 51.569442291460945],
              [0.542174186249751, 51.56786913548239],
              [0.542766512760261, 51.567415621955739],
              [0.543923640942623, 51.567282612263028],
              [0.543031256807365, 51.566160703863609],
              [0.541421123905821, 51.565932994013963],
              [0.54154440292563, 51.564340295539559],
              [0.539664618450522, 51.560075885282522],
              [0.53971337580717, 51.558879610440179],
              [0.538237698359028, 51.55603547341498],
              [0.538119197218634, 51.555384219518146],
              [0.538631894308508, 51.555419889238948],
              [0.53796464792288, 51.554655503146975],
              [0.538548203254984, 51.554461208115519],
              [0.538570388776349, 51.554134268368173],
              [0.538034237819171, 51.55432483975008],
              [0.538355240759668, 51.553327722230641],
              [0.539079785561842, 51.551728305056365],
              [0.541693325378737, 51.548487137803335],
              [0.542224552473108, 51.548468429326135],
              [0.544214142093686, 51.546330724256229],
              [0.543163594803945, 51.544020622951535],
              [0.54389263883794, 51.541680017690972],
              [0.543462642725088, 51.538495787209428],
              [0.542586409977003, 51.537057856781765],
              [0.542840497236457, 51.53496767606044],
              [0.542142549516989, 51.53442431762462],
              [0.539723981502799, 51.534263578649991],
              [0.538246267447491, 51.5337775527899],
              [0.535188352194908, 51.531167265544894],
              [0.53327869808725, 51.530949525270984],
              [0.530729829898799, 51.531840945984577],
              [0.528597660865947, 51.531849168624646],
              [0.527066079534292, 51.53246945184172],
              [0.527012692744615, 51.532937358985642],
              [0.526624882509402, 51.532600369315787],
              [0.522522668331914, 51.532811892153475],
              [0.516731389871561, 51.529982900518057],
              [0.515478986115152, 51.530359614720652],
              [0.515582885903614, 51.530648771111849],
              [0.515154033514602, 51.530480800622577],
              [0.513720890973068, 51.531084407657268],
              [0.513191588235838, 51.531867383254429],
              [0.511531852942588, 51.534013683385766],
              [0.511204774284958, 51.533939747628814],
              [0.509209567060692, 51.535548198829431],
              [0.509414460899917, 51.536572654279048],
              [0.507676698309359, 51.539037131051224],
              [0.506766291825051, 51.542781637437081],
              [0.50563554408107, 51.544041485766208],
              [0.50646180764777, 51.544799055890358],
              [0.505858961114059, 51.544695024668698],
              [0.505292921710953, 51.545024582256083],
              [0.504462490405917, 51.544821082487815],
              [0.504947184657055, 51.545111108540375],
              [0.504351412176157, 51.544952059129209],
              [0.50427464365068, 51.544604756987859],
              [0.505022861995372, 51.544650832040077],
              [0.505248998317942, 51.544356416291834],
              [0.505502772630859, 51.544565036516929],
              [0.505248191824482, 51.543974216697485],
              [0.500660337986278, 51.545475987758138],
              [0.49745847848634, 51.547375253785368],
              [0.496498377381665, 51.548702423562979],
              [0.498812020738909, 51.550359186021034],
              [0.49965743142754, 51.550466178501438],
              [0.500525489171503, 51.552011613402833],
              [0.502936365661013, 51.552490795941267],
              [0.503018837097853, 51.553125763219256],
              [0.502384736453935, 51.553374019276355],
              [0.502136278565676, 51.553078942983149],
              [0.502716855084757, 51.552854313658443],
              [0.502161371459834, 51.552721372817281],
              [0.501801132269157, 51.552991662340126],
              [0.501829508635672, 51.552746438736541],
              [0.500323080249632, 51.552424225182335],
              [0.499597111352002, 51.550786726914602],
              [0.498009172398924, 51.550581335739246],
              [0.495932336864366, 51.5488232899026],
              [0.496911503273856, 51.547185449503665],
              [0.499898479140658, 51.545260201649462],
              [0.49739344423092, 51.545318966526082],
              [0.495581059908635, 51.545989775715206],
              [0.49397981142804, 51.547067061415511],
              [0.492935853439242, 51.548867234004803],
              [0.493088043172595, 51.549962082414822],
              [0.494918698500953, 51.551440326879863],
              [0.49566082854637, 51.552954300510628],
              [0.499405866045207, 51.55598084542585],
              [0.499936157497136, 51.555969544107754],
              [0.50120708936179, 51.557045928625037],
              [0.502547435511404, 51.557309621598165],
              [0.50470243643716, 51.558666580746497],
              [0.503544982525539, 51.558975480086872],
              [0.503754673369298, 51.558616667577994],
              [0.502506175416756, 51.557714301800054],
              [0.501038633402688, 51.557393964665124],
              [0.500405582679414, 51.556821097436597],
              [0.498033673323067, 51.555819419597775],
              [0.494605417992501, 51.552912000755924],
              [0.491558280821458, 51.554251121257224],
              [0.491914698913856, 51.554701308624864],
              [0.491622867152241, 51.555485429018844],
              [0.49037481864477, 51.556011062650754],
              [0.491196885009467, 51.555557430445759],
              [0.490941775229175, 51.555192324515943],
              [0.490153056536051, 51.555569703295269],
              [0.489156982203872, 51.555082724708818],
              [0.490457714363307, 51.554887838065419],
              [0.491177100603197, 51.554139606956447],
              [0.494189096177484, 51.552580008271534],
              [0.492291937419884, 51.549938532135918],
              [0.492235808895839, 51.548832648334482],
              [0.491830831121436, 51.548865535064103],
              [0.493726736942222, 51.546343084719958],
              [0.494484495995023, 51.545666860904362],
              [0.496933300579142, 51.54481794315226],
              [0.502666129701655, 51.544178633621755],
              [0.505049501752772, 51.543114201067525],
              [0.506004702347631, 51.541835626062664],
              [0.507106474604394, 51.537505164951469],
              [0.506267911081898, 51.538515954397326],
              [0.503809690356512, 51.53910536549467],
              [0.50223938162423, 51.538662233810996],
              [0.499612288480579, 51.538592350588054],
              [0.499764267758651, 51.538207793758957],
              [0.496673929899045, 51.538948124663783],
              [0.496444917751175, 51.538821697243563],
              [0.497743340091835, 51.53848827724881],
              [0.494069928840713, 51.539274231179185],
              [0.490682168483892, 51.540618783127492],
              [0.490137576439362, 51.541447840176161],
              [0.49166803723727, 51.543088990945343],
              [0.490936460742431, 51.543980480271451],
              [0.489961097782379, 51.544000292659113],
              [0.487834511813279, 51.543037272149995],
              [0.487026747156961, 51.543220780492213],
              [0.487912788652333, 51.545000649965637],
              [0.487431594076359, 51.545486602579111],
              [0.485463750946808, 51.545848483391232],
              [0.485698825516118, 51.547117852607975],
              [0.484812521621608, 51.54787318475109],
              [0.481638565414633, 51.54860409062276],
              [0.480770195565365, 51.548418313349678],
              [0.480280797987418, 51.547907052792787],
              [0.477563145831097, 51.5471883715703],
              [0.475037948900865, 51.54709060016225],
              [0.474306676921795, 51.546400056792265],
              [0.469811719102118, 51.547109939304484],
              [0.466734341503623, 51.546723240355107],
              [0.466919788924686, 51.547351567071786],
              [0.465226508929894, 51.549861218769877],
              [0.461587838846307, 51.553695025754671],
              [0.459127328116399, 51.552261851751005],
              [0.45769640277537, 51.552772083558871],
              [0.452323716940127, 51.551931254099053],
              [0.450075585521819, 51.551167945280433],
              [0.446910065366991, 51.550887787175377],
              [0.445983468152591, 51.551174213298864],
              [0.445796479398093, 51.549302123160025],
              [0.443440096031678, 51.548963628019266],
              [0.444190211936652, 51.547699737258164],
              [0.442901894876979, 51.547372244250639],
              [0.443770664828232, 51.546411654511289],
              [0.43990620718963, 51.545111648793082],
              [0.439032971166117, 51.546421239930531],
              [0.437502917202455, 51.546157170866266],
              [0.437303608586972, 51.5465300352555],
              [0.436525107694538, 51.546537217234196],
              [0.435779688747642, 51.549188614735868],
              [0.435319845380541, 51.549518322258379],
              [0.43097005589185, 51.549720972456726],
              [0.430643104855447, 51.548864399650746],
              [0.429904771062404, 51.548814047665019],
              [0.430369410798742, 51.546003033236971],
              [0.427660291106609, 51.545129240584941],
              [0.426418523466404, 51.548437459651808],
              [0.4247959432063, 51.548087904760841],
              [0.424908118134039, 51.550061393426596],
              [0.426589882616501, 51.551873817025765],
              [0.424883331436315, 51.552687022407177],
              [0.424419589596237, 51.552809923063045],
              [0.423808620089742, 51.551981685595088],
              [0.41884865680075, 51.550558829987253],
              [0.418217476222569, 51.550815560392046],
              [0.417980523516676, 51.551080348753779],
              [0.421052149109021, 51.552964014475798],
              [0.418649196261472, 51.556614462623962],
              [0.417366627195942, 51.557131030505843],
              [0.414550497133523, 51.557485808414484],
              [0.411340524672695, 51.557296434180316],
              [0.410884696694531, 51.557543223706524],
              [0.408488578672015, 51.557520501931087],
              [0.402279154448906, 51.558506681469758],
              [0.39915703551421, 51.558232437103058],
              [0.398595313164322, 51.557784374370186],
              [0.393502794458233, 51.557652776715528],
              [0.392095794101932, 51.562566554228738],
              [0.391779672233333, 51.567209862266552],
              [0.389593002568368, 51.566457633414167],
              [0.385861823481223, 51.565721498826129],
              [0.382575631240661, 51.565821562922821],
              [0.38169360697626, 51.568299092201222],
              [0.383636316629332, 51.569767780959076],
              [0.382688411352809, 51.57195168057099],
              [0.383625711105154, 51.572102609993209],
              [0.38294378315821, 51.573688460020122],
              [0.383209489270713, 51.576753311228089],
              [0.382001550979379, 51.582081976285977],
              [0.381260524135425, 51.584998192411014],
              [0.386000288098986, 51.585916321206376],
              [0.384990335126565, 51.590309299861993],
              [0.383395238153071, 51.59345781828123],
              [0.383880943684187, 51.593716844714692],
              [0.382627381880972, 51.594263006025571],
              [0.381860724856317, 51.595533106578166],
              [0.382768206444804, 51.598084006076135],
              [0.38357990042661, 51.598721317771378],
              [0.382505270772789, 51.599543526301098],
              [0.379328446463727, 51.603577527803921],
              [0.377669850553405, 51.612104194221502],
              [0.37574380686748, 51.614886082173811],
              [0.375098472173366, 51.615169835155122],
              [0.376237180211198, 51.615868926218234],
              [0.376634248556103, 51.615754767803068],
              [0.37867046673817, 51.616599330064957],
              [0.384198194490895, 51.619561888413678],
              [0.38499296505177, 51.623229295663933],
              [0.386938834590617, 51.62608640002324],
              [0.38679115175824, 51.626933851005944],
              [0.387597578057636, 51.628340149625203],
              [0.387179005464274, 51.629752478752224],
              [0.385828403332653, 51.63102817529581],
              [0.386213972004503, 51.631463694331366],
              [0.385185808995225, 51.632799375447028],
              [0.386050131917828, 51.634343899555539],
              [0.387050200743566, 51.635995371960817],
              [0.386873959690945, 51.636932433484489],
              [0.387448097271895, 51.63768246746281],
              [0.38978605005733, 51.639102555240754],
              [0.390507982395679, 51.639033896648399],
              [0.391082189704783, 51.639399009653509],
              [0.391973499120522, 51.639253148698749],
              [0.392178918409336, 51.639641059184008],
              [0.392628171327929, 51.639581541620878],
              [0.394152612406273, 51.641220466706663],
              [0.394898705920411, 51.6431729248959],
              [0.394516242587346, 51.64397930954776],
              [0.394888799473324, 51.644438448062765],
              [0.395682908953203, 51.644366487061298],
              [0.396256402182684, 51.644852995941761],
              [0.398845682224002, 51.6447785274311],
              [0.399246667146766, 51.645200199992516],
              [0.400272409357495, 51.645162143491355],
              [0.400868154910883, 51.645576230813205],
              [0.401342285848318, 51.645521567280973],
              [0.401437777492702, 51.645988151669677],
              [0.403018709260357, 51.645842486437935],
              [0.402914549140167, 51.64682486124925],
              [0.403658624592053, 51.647473314788343],
              [0.402374175136358, 51.647567955714933],
              [0.401969705746298, 51.647983617694081],
              [0.402313509783767, 51.648553036698736],
              [0.402606687692145, 51.648356383927542],
              [0.402859026964605, 51.649164190521951],
              [0.402815524803664, 51.649844955323523],
              [0.402180933097003, 51.649599842471332],
              [0.401893730956476, 51.649772989881335],
              [0.402300825526075, 51.65099221032591],
              [0.410196780897157, 51.65120267297214],
              [0.412199591779921, 51.649851246415039],
              [0.412702480795501, 51.650119696493306],
              [0.41418281140283, 51.650091959820266],
              [0.415746238418737, 51.65002292494907],
              [0.415761170525586, 51.649760020128177],
              [0.423347794624777, 51.649458859666602],
              [0.428897806100026, 51.649759634843377],
              [0.433782174151527, 51.649507385583199],
              [0.435357467225472, 51.648959410116916],
              [0.439946338670477, 51.647056503247988],
              [0.442908263279223, 51.646652261921695],
              [0.441928512788147, 51.64418425361918],
              [0.445825246254055, 51.643480799887058],
              [0.446795293995642, 51.641980315275731],
              [0.446906049482772, 51.641513961292858],
              [0.445900469980599, 51.641199476056634],
              [0.443359399515181, 51.638859329200045],
              [0.442866033279536, 51.636994532205662],
              [0.444135444156925, 51.633160415076908],
              [0.446192735527404, 51.630625557969985],
              [0.445483247283426, 51.630464976869263],
              [0.446268336579661, 51.629533114634881],
              [0.446164246350834, 51.628484885484255],
              [0.446800392196703, 51.626665802187979],
              [0.455145053995148, 51.624636247663162],
              [0.457936547038959, 51.624289137266786],
              [0.458934394384292, 51.623824871698972],
              [0.463046567601787, 51.623614535662981],
              [0.465799483227631, 51.623464097930764],
              [0.478004666169909, 51.625173835549205],
              [0.478162966121801, 51.625400718775268],
              [0.481330070619572, 51.625557730814421],
              [0.48793640865486, 51.62477035740492],
              [0.494539518771898, 51.623612157793396],
              [0.504801614289829, 51.621712743047922],
              [0.505421575905579, 51.621925239464304],
              [0.504793326868243, 51.624368623029255],
              [0.506779008870244, 51.624953054229522],
              [0.508714073768451, 51.625116751177487],
              [0.508575144877332, 51.625715073439466],
              [0.511422149538743, 51.62590779309663],
              [0.512872629163928, 51.626609694246895],
              [0.51465353943356, 51.626962754718896],
              [0.515527179500317, 51.624419626477817],
              [0.520232893055898, 51.62540595134621],
              [0.520777929238547, 51.624473335701126],
              [0.520398034714292, 51.623119915268212],
              [0.521055899582813, 51.621095790142569],
              [0.520795014283647, 51.618787426772101],
              [0.529039991115094, 51.62024410785191],
              [0.53051918681349, 51.62005843555648],
              [0.531850366049817, 51.619446059013114],
              [0.536172653655172, 51.620321303759354],
              [0.537551789133606, 51.619611597280027],
              [0.538181128322183, 51.619834510700279],
              [0.538546022281196, 51.620444457237795],
              [0.540960999205096, 51.620872433984374],
              [0.542448973687041, 51.62079254182057],
              [0.543521760997977, 51.619721575102282],
              [0.544702202545516, 51.619671701794665],
              [0.543593478485696, 51.618369226359917],
              [0.544349430341717, 51.617911267075257],
              [0.545091376300414, 51.618132601289318],
              [0.546785838688437, 51.617639878437046],
              [0.549541264240965, 51.617982946394648],
              [0.551608276383781, 51.617745562936541],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000067",
        LAD13CDO: "22UC",
        LAD13NM: "Braintree",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.688222775014369, 52.084761578225994],
            [0.688111014952911, 52.083388202009012],
            [0.689938054985076, 52.080079347492088],
            [0.69172206027991, 52.079406391830574],
            [0.692397074979936, 52.078714693482638],
            [0.692938022890443, 52.078487396536076],
            [0.694683175829721, 52.078697496397304],
            [0.69764436558218, 52.079588379569387],
            [0.702050238845526, 52.079481112353704],
            [0.702996067016594, 52.07904663531454],
            [0.703043602983556, 52.078480783240231],
            [0.704774994938565, 52.078148770565548],
            [0.706852264022293, 52.076731420975705],
            [0.706264233744114, 52.075069530594462],
            [0.706676580775873, 52.074672457532316],
            [0.706248606372179, 52.074184076511344],
            [0.706560623316762, 52.073841468160019],
            [0.707694717203514, 52.073771340062407],
            [0.708875819912101, 52.073103881959909],
            [0.709447236980133, 52.073261608081957],
            [0.712087404223937, 52.072228705675499],
            [0.712020004190895, 52.071742834654714],
            [0.71272677375245, 52.070980138297365],
            [0.711802563126248, 52.070852133715512],
            [0.710914142877662, 52.06996608430493],
            [0.711307452305797, 52.068702502820962],
            [0.709848546451351, 52.066973437777115],
            [0.707620833987827, 52.066281840717657],
            [0.707140395591854, 52.066782104539541],
            [0.705127060660502, 52.066884115035904],
            [0.704123989013118, 52.066415227539366],
            [0.70400911916731, 52.065866591625323],
            [0.703449357528815, 52.065851561667152],
            [0.70314656933046, 52.065259574680546],
            [0.703780256915136, 52.064783688129786],
            [0.703536740874831, 52.063409746148594],
            [0.704941777687427, 52.062660743839103],
            [0.705920712440567, 52.060873824469084],
            [0.70694455121938, 52.059975276055063],
            [0.707725710267411, 52.057694670049578],
            [0.70883428420174, 52.056968621014391],
            [0.70856328542435, 52.056660995798694],
            [0.708980961250475, 52.056041661014845],
            [0.710810205683779, 52.055533733652886],
            [0.710875466926736, 52.053696748336904],
            [0.711359474706708, 52.053354662455703],
            [0.713014449077412, 52.053198742736654],
            [0.713057398341252, 52.052391075487243],
            [0.714219281493313, 52.052259086304396],
            [0.715002320706478, 52.051484721109077],
            [0.716033926940432, 52.051153372710196],
            [0.716232110467054, 52.048673908273969],
            [0.714360723520899, 52.048938283210163],
            [0.714124709465682, 52.048557919688548],
            [0.713691582520341, 52.048583188674556],
            [0.712677600319514, 52.049012135541425],
            [0.71194960954262, 52.048431761377003],
            [0.709828665010681, 52.04862177208031],
            [0.709247673875721, 52.048300596612073],
            [0.70699830351112, 52.048151774222951],
            [0.703953120091009, 52.047311630701756],
            [0.70309906987803, 52.047370805260577],
            [0.702629296547492, 52.047876204341655],
            [0.701165893415894, 52.048086040771459],
            [0.700533195925163, 52.048478258133152],
            [0.699368950525903, 52.048424910157955],
            [0.699637629186776, 52.048284755382603],
            [0.698959971670575, 52.046866795382662],
            [0.698360743947213, 52.046846352623497],
            [0.698651465353557, 52.044506894910548],
            [0.697016026204884, 52.043118061771516],
            [0.696495066651808, 52.041344871170899],
            [0.696480680185398, 52.039289389406605],
            [0.69866189488747, 52.036742053712217],
            [0.699983818196259, 52.036852044487048],
            [0.702592222831689, 52.037740979699343],
            [0.703750351895551, 52.036175681794496],
            [0.712476403015313, 52.034856271041846],
            [0.71062130557456, 52.032995151324769],
            [0.70812688806262, 52.032422128292922],
            [0.707395063890225, 52.030542315677295],
            [0.706497422457084, 52.029512558882836],
            [0.710563531315886, 52.028232843165561],
            [0.711939844436045, 52.028329754673379],
            [0.713480996140788, 52.027994780391069],
            [0.714365057847649, 52.02730621755974],
            [0.719000603915071, 52.027777525414407],
            [0.735472487859216, 52.032010393855288],
            [0.737015281938173, 52.032323477687491],
            [0.738018283157186, 52.032082526413653],
            [0.73914485141453, 52.032264966869533],
            [0.740786382595863, 52.031021690109512],
            [0.741695082689054, 52.029431232365539],
            [0.741754292667078, 52.027277793869992],
            [0.740885221702189, 52.025392378918831],
            [0.741248300790529, 52.023201289212722],
            [0.742259840236808, 52.023079709574432],
            [0.742608451347747, 52.022701971309083],
            [0.742296388297646, 52.020651605904888],
            [0.741461523953617, 52.020252865813546],
            [0.742378315177262, 52.016344672583827],
            [0.741136227897755, 52.014958073688717],
            [0.739401139562997, 52.014947196211303],
            [0.73960080589748, 52.014303136792122],
            [0.737784377212755, 52.012267970174506],
            [0.738507704395522, 52.011511909940864],
            [0.738495183937855, 52.01068662902771],
            [0.739665877149012, 52.00981133704439],
            [0.741117984464652, 52.009320683834694],
            [0.742539422442218, 52.007733559928326],
            [0.743436387851785, 52.007507616949681],
            [0.744007396383385, 52.008027604543294],
            [0.74519218309807, 52.007766161137489],
            [0.745300339510091, 52.006819355458909],
            [0.74637945075806, 52.006688966385468],
            [0.749127197283856, 52.005538457095746],
            [0.749275041156405, 52.004384775878641],
            [0.750447039854628, 52.003109144921609],
            [0.749603309860139, 52.002157589897415],
            [0.749831693796501, 51.999846400640173],
            [0.75131032882215, 51.999248877582275],
            [0.751996590414237, 51.999415398352177],
            [0.752424166804406, 51.998571732189767],
            [0.757399778009524, 51.998738576078289],
            [0.758843555254515, 51.998452938620872],
            [0.759728184069937, 51.997841348866871],
            [0.761117200411007, 51.997471531279842],
            [0.761070973259583, 51.99688625772378],
            [0.760181042796381, 51.996582472833076],
            [0.760447712711711, 51.995707474803595],
            [0.759542341622473, 51.995436422954135],
            [0.760308587227099, 51.994725080425845],
            [0.759552337463478, 51.993751758785329],
            [0.761508462878146, 51.993579979795733],
            [0.762880438751208, 51.990854313942606],
            [0.761656819916908, 51.98825160970749],
            [0.759618131447414, 51.987006197913118],
            [0.760094299802582, 51.986364607824918],
            [0.759635498363286, 51.985417586565482],
            [0.760436738723509, 51.984520159631543],
            [0.762229210983348, 51.983920527387617],
            [0.767066416537548, 51.983781727756927],
            [0.771028686694837, 51.982069726023688],
            [0.772663752133892, 51.982556426282123],
            [0.774600839940082, 51.982580026521724],
            [0.773841514435299, 51.98082805041431],
            [0.774001929533181, 51.9801245939857],
            [0.772669286170508, 51.97901564988134],
            [0.772539437099595, 51.978092402844609],
            [0.773016928180947, 51.974035083719215],
            [0.773915609124493, 51.97212981567948],
            [0.773679415615167, 51.971512148764084],
            [0.772454215173473, 51.970889005693778],
            [0.772228263950206, 51.970413188393401],
            [0.772820052232446, 51.969786805068644],
            [0.774280445927217, 51.9696687503004],
            [0.776047046910176, 51.967509174925212],
            [0.781437222285609, 51.966501459869363],
            [0.78175912138111, 51.966339166726804],
            [0.781269683388825, 51.965865097139549],
            [0.782256982814611, 51.965069235487086],
            [0.781572221370338, 51.963463032408519],
            [0.777756855264579, 51.960903729930003],
            [0.777396556106945, 51.960562395132641],
            [0.77823647060503, 51.960396877296198],
            [0.777543207525662, 51.959873644946683],
            [0.773121820188529, 51.960150591554545],
            [0.773082063426317, 51.960709112023871],
            [0.76982607140713, 51.961873980917083],
            [0.771559248477688, 51.962429428591328],
            [0.770720125107306, 51.962962704487026],
            [0.768476508916696, 51.961896744646189],
            [0.767841631324087, 51.959886393593045],
            [0.768306277315201, 51.959253129224678],
            [0.767671875522881, 51.957627678043721],
            [0.766672450005211, 51.956810308613235],
            [0.766150695995404, 51.955736186826051],
            [0.764009048660194, 51.954308916291161],
            [0.756016917577861, 51.953173463825514],
            [0.749676005284406, 51.954777812118806],
            [0.747653798597917, 51.952128872611503],
            [0.748512562516035, 51.951653758629718],
            [0.745822451763452, 51.948574322518589],
            [0.745728482536157, 51.945869547178582],
            [0.746889160414836, 51.945359519027761],
            [0.744907502054131, 51.943888603093683],
            [0.744439010643684, 51.94267465052291],
            [0.740868378805038, 51.940699326594469],
            [0.74024271026975, 51.939983648101844],
            [0.740567960540917, 51.936321215438483],
            [0.741437115880913, 51.934135390701925],
            [0.74042075094113, 51.932927891941581],
            [0.740111628877387, 51.93162747222479],
            [0.738868076432605, 51.930892001603972],
            [0.738182689688515, 51.930005032437379],
            [0.737328098487679, 51.929874731921238],
            [0.736734536384474, 51.929372328024151],
            [0.736757456845456, 51.928075866745544],
            [0.734759641531195, 51.925483703153766],
            [0.734817133266571, 51.924395981314035],
            [0.733801349227039, 51.924603052963569],
            [0.732083729989244, 51.923313751733929],
            [0.730414580221117, 51.923300342782063],
            [0.731439899044257, 51.918843765848287],
            [0.730086814985774, 51.915839951202656],
            [0.727810349618857, 51.91458424306181],
            [0.726676562485672, 51.912889219986461],
            [0.725566342418734, 51.912950023026099],
            [0.724154474056321, 51.912568138987091],
            [0.723327219707325, 51.910961314015985],
            [0.72219715241174, 51.910164588978482],
            [0.723908113468753, 51.908402772778032],
            [0.724318432418554, 51.90879167156956],
            [0.725033843380304, 51.908455839461169],
            [0.727227580672557, 51.909120861112704],
            [0.728984106140069, 51.908042299777534],
            [0.727170652120753, 51.907221904594252],
            [0.72641308965994, 51.905997496649604],
            [0.72528967063844, 51.905141291818865],
            [0.723424503928585, 51.905577497374132],
            [0.722576466872593, 51.9035763441019],
            [0.720647339728519, 51.903627276437469],
            [0.720558708674212, 51.904542141922583],
            [0.716766429290256, 51.904606411036085],
            [0.715065597361441, 51.904947855880764],
            [0.71351834547054, 51.903544641174143],
            [0.712776498579267, 51.902211867085555],
            [0.711103208043002, 51.902719894291494],
            [0.710880858807664, 51.904217896768458],
            [0.706986450768155, 51.905218600752477],
            [0.705432414216856, 51.904810988324201],
            [0.705643641110688, 51.903922994736142],
            [0.704792218960771, 51.903569357460242],
            [0.699568999694676, 51.903457325441934],
            [0.69938830769368, 51.901729378154954],
            [0.699930416683946, 51.899715044226532],
            [0.702703525393757, 51.898421081917562],
            [0.704694397663975, 51.896860856948777],
            [0.709252513502475, 51.895209112891415],
            [0.710526449914775, 51.895266995481634],
            [0.71027925150165, 51.894323904364398],
            [0.711540730222387, 51.893161678519299],
            [0.71244701095283, 51.892980700966092],
            [0.712884860763852, 51.891128783279342],
            [0.712480363022438, 51.891073361542752],
            [0.71287332465819, 51.889948234563477],
            [0.712510133911653, 51.888937675407341],
            [0.713172693371071, 51.886956465270195],
            [0.71184075019193, 51.886534824535929],
            [0.715026897602643, 51.884830833439736],
            [0.716164315395453, 51.883545511276388],
            [0.716502817371717, 51.883640216024254],
            [0.717685499950488, 51.882191054389885],
            [0.717313509824505, 51.882071046393044],
            [0.720162352416915, 51.880733535826316],
            [0.722731295106711, 51.878782797606533],
            [0.718160862365533, 51.877931642554167],
            [0.719978948896868, 51.875602611817826],
            [0.722624878014229, 51.875892119601133],
            [0.722810741214178, 51.874688110788114],
            [0.723471082931842, 51.873906590195269],
            [0.722094001585716, 51.872973495126764],
            [0.71741695437395, 51.872199428063816],
            [0.716377114397747, 51.871242285551411],
            [0.718675947472943, 51.869901319642807],
            [0.719386333880546, 51.870598960750002],
            [0.722178948605751, 51.869353524918779],
            [0.723006026109176, 51.869769650801331],
            [0.726072654599978, 51.869862274577905],
            [0.730432838288042, 51.867542459436869],
            [0.732112326517701, 51.868287665742919],
            [0.731849529897411, 51.868625624974612],
            [0.734854583198739, 51.870170072420372],
            [0.736578552770006, 51.871668721416789],
            [0.740714360540257, 51.870610112088812],
            [0.740533116028508, 51.87028158309726],
            [0.747194219382182, 51.868589185557617],
            [0.74615857876572, 51.867590833838911],
            [0.747767476824818, 51.866578368366113],
            [0.749413587343889, 51.866200839979612],
            [0.751457796293309, 51.864777938576324],
            [0.752351025635362, 51.863467385324924],
            [0.752897882830304, 51.863121828616585],
            [0.748704544458394, 51.861173044948636],
            [0.745845554153861, 51.85973525902444],
            [0.746154140158382, 51.859507714809517],
            [0.747319277128717, 51.860083949841872],
            [0.748726831308835, 51.859085165459106],
            [0.7476103036082, 51.858448452280136],
            [0.748265796880649, 51.857717260773207],
            [0.74694202374649, 51.857087184308867],
            [0.746532318699654, 51.857275723593837],
            [0.745206624661721, 51.856374972711869],
            [0.747503699471335, 51.854816726565588],
            [0.750316168148607, 51.853591723594107],
            [0.744439719234508, 51.85176939135642],
            [0.742133596193189, 51.851076752674139],
            [0.741125628628655, 51.849664918331662],
            [0.737078718855036, 51.848352644298245],
            [0.736194054453573, 51.84932833081627],
            [0.733116151459302, 51.848452025809493],
            [0.734550251810396, 51.84712543434204],
            [0.736349562257967, 51.847272428941544],
            [0.739066948291092, 51.844628055705996],
            [0.739247124675572, 51.843889100101791],
            [0.73870571268975, 51.843198433353763],
            [0.740058772817959, 51.843219965224428],
            [0.740097930379385, 51.842836834604739],
            [0.739383853680554, 51.84315205585105],
            [0.738446897045073, 51.842929265665752],
            [0.738355282542927, 51.841755068085021],
            [0.741428694974999, 51.841440616340037],
            [0.741561890391078, 51.839666715857852],
            [0.739545589542237, 51.84000600558246],
            [0.738985902664177, 51.839826589180724],
            [0.738452705530485, 51.839485571853487],
            [0.738749655192538, 51.83897232762375],
            [0.738159594440816, 51.837789955445487],
            [0.73664567596813, 51.83753112584337],
            [0.735036263157093, 51.837732260759488],
            [0.735142674632706, 51.840588773790643],
            [0.734300806579312, 51.84195736124385],
            [0.733164648097027, 51.841968492867316],
            [0.727801834716214, 51.839479585820882],
            [0.722065627838913, 51.838732101199732],
            [0.71937162299225, 51.837440054951216],
            [0.718892507446646, 51.837656185149697],
            [0.715747644003895, 51.837167695376962],
            [0.711876483305379, 51.834521293769285],
            [0.714155867451321, 51.833512696934761],
            [0.717524433837835, 51.832995997096688],
            [0.718101889447074, 51.833184096919545],
            [0.721304321453655, 51.830226738531032],
            [0.718524291393986, 51.829112037750058],
            [0.716234932508456, 51.827562348622259],
            [0.71775912395594, 51.825613334434266],
            [0.716804792703153, 51.824708182168422],
            [0.714402964960674, 51.823648497508501],
            [0.70905550004886, 51.819861563237758],
            [0.708881416763873, 51.818895194416875],
            [0.70476179746668, 51.822676431539882],
            [0.700909753713012, 51.825168022229064],
            [0.698745090866693, 51.828408567657192],
            [0.697241675292485, 51.829117572303943],
            [0.696922151337425, 51.828652755109793],
            [0.693088922192824, 51.827875483313846],
            [0.692486458862191, 51.826730048403611],
            [0.691643843789061, 51.826521815612352],
            [0.69056551903583, 51.825646273743274],
            [0.689202201213767, 51.825613619117021],
            [0.689048217552011, 51.824636872694455],
            [0.689877059567451, 51.82444433788973],
            [0.690074770878334, 51.824060296154414],
            [0.689830132727802, 51.822975007529656],
            [0.689468446871187, 51.823018357573446],
            [0.686786714722553, 51.821290920510052],
            [0.684141157528657, 51.820949363168644],
            [0.682074476584375, 51.820116096141092],
            [0.682311345660621, 51.819633147289217],
            [0.681824721246938, 51.819087566618713],
            [0.682518283986823, 51.818451202920009],
            [0.681576354270719, 51.817520306730621],
            [0.682554854365049, 51.816819884334031],
            [0.682351229514492, 51.81620129623537],
            [0.681711024322093, 51.815707783786024],
            [0.680907821500071, 51.815694630403584],
            [0.679828178094435, 51.81513109000673],
            [0.679980296433816, 51.814002560781219],
            [0.678914038668964, 51.813761566241205],
            [0.677763075601089, 51.812874975105515],
            [0.677606231350276, 51.812358736202711],
            [0.678663369400079, 51.810636714263474],
            [0.678130627617257, 51.810705512540572],
            [0.676748111513902, 51.810022939742979],
            [0.673334654739972, 51.810421691290841],
            [0.670031252250343, 51.809443669762089],
            [0.669084987954709, 51.808358090149198],
            [0.668428324713475, 51.80596100311061],
            [0.667401637622855, 51.805968121364828],
            [0.666640711314165, 51.805218262106656],
            [0.664661836416753, 51.805314407049046],
            [0.66241642944698, 51.806229548581285],
            [0.660997448607112, 51.806226611052644],
            [0.659728594113452, 51.805668971739635],
            [0.659173658576589, 51.804818175318537],
            [0.659806489101369, 51.80361674354274],
            [0.656662440807927, 51.801511486174007],
            [0.6564632370266, 51.80064004479766],
            [0.655583688932987, 51.800063669019281],
            [0.655322061729524, 51.799363609073446],
            [0.654581571026319, 51.799104244330103],
            [0.654926049793402, 51.798721443455591],
            [0.654269374227191, 51.797913392663041],
            [0.654601975799619, 51.797106378577226],
            [0.654063476918128, 51.79687752168924],
            [0.654279694558838, 51.796311458405242],
            [0.653473092993417, 51.795592225513957],
            [0.655320931122081, 51.79384086680011],
            [0.655925490164704, 51.792430549178803],
            [0.655418151870554, 51.79142307605062],
            [0.655886996843085, 51.790887273300243],
            [0.654798054802066, 51.789680699427869],
            [0.654963659676718, 51.789216502115835],
            [0.653097698490996, 51.788433062541365],
            [0.651080600676146, 51.784824618131985],
            [0.649870474792008, 51.784786262591282],
            [0.648980478350313, 51.784173202666707],
            [0.645734239088983, 51.783088904444085],
            [0.644627340680083, 51.781592160538814],
            [0.640565386999254, 51.779643838345812],
            [0.637046439727335, 51.777205650495702],
            [0.633930955411636, 51.777605581930558],
            [0.633653367345624, 51.777145953322652],
            [0.632524444017817, 51.772646743004884],
            [0.632654281700385, 51.77161500468933],
            [0.636806682492166, 51.770334778855194],
            [0.638274185411037, 51.770344104227675],
            [0.6383910456936, 51.76881891863767],
            [0.636392454108081, 51.768301702186832],
            [0.637532310805039, 51.766794926876173],
            [0.639186256049112, 51.766772175121723],
            [0.640031052775628, 51.767835986358783],
            [0.641843103078336, 51.767045215637921],
            [0.643468996224587, 51.766864752508852],
            [0.645167850307025, 51.764239146781996],
            [0.647089532562184, 51.763839730715205],
            [0.648287049044722, 51.761988902125047],
            [0.647476023527968, 51.761503556290741],
            [0.647025839609694, 51.761785297841662],
            [0.64563574920228, 51.760889400540769],
            [0.639458709221222, 51.760039977230086],
            [0.639449607011719, 51.759312624648324],
            [0.638524238218672, 51.759647276741859],
            [0.637510219769396, 51.757641160435284],
            [0.635309524167094, 51.758322774390074],
            [0.635891514704494, 51.759805301035328],
            [0.635161482748482, 51.760035718076089],
            [0.634955519907071, 51.761426202110414],
            [0.633772951592051, 51.761451819474608],
            [0.633331566549781, 51.760143299133674],
            [0.630584935835681, 51.76103491016066],
            [0.629857389307678, 51.761982001773546],
            [0.628709682300455, 51.761984304445079],
            [0.627931083506711, 51.761529576227836],
            [0.624964597724679, 51.761957422517064],
            [0.623070095845117, 51.761302758010245],
            [0.619602451412916, 51.760835136669627],
            [0.619098291460744, 51.76113865768933],
            [0.619432193275419, 51.762525167733813],
            [0.618578983010759, 51.762557677232266],
            [0.618790725688334, 51.761007917053398],
            [0.618112906003728, 51.76094028598073],
            [0.618097808972703, 51.760278719072254],
            [0.618665335262015, 51.758541164471083],
            [0.614985606194289, 51.758085330443571],
            [0.614649954160893, 51.758822153515077],
            [0.613873028063462, 51.75874411333136],
            [0.614353612174871, 51.760816255841881],
            [0.611053938479218, 51.760394107896396],
            [0.608603601745244, 51.759293808084642],
            [0.606721451239522, 51.758935384890265],
            [0.605407345000456, 51.758273854355473],
            [0.605373888047907, 51.757869001477118],
            [0.604788659061287, 51.757898166175828],
            [0.604244725510971, 51.757461462458146],
            [0.604750707202035, 51.757289266407128],
            [0.604530455390381, 51.756911937049857],
            [0.605162383169154, 51.756334949301902],
            [0.604354333848169, 51.754833907618305],
            [0.602592646527896, 51.753706525758666],
            [0.60173529471836, 51.753765086270299],
            [0.601118314998231, 51.753215772111943],
            [0.600435974065696, 51.75326864948731],
            [0.599377185607374, 51.752090585728652],
            [0.599862858110241, 51.752019583844358],
            [0.599938258136893, 51.751516092804337],
            [0.598674962777041, 51.750409098682233],
            [0.59882251582037, 51.751262889571045],
            [0.594963285025837, 51.753929254621646],
            [0.594857889823908, 51.754997279238239],
            [0.593338667882383, 51.755441815100255],
            [0.590669372334958, 51.755870328775465],
            [0.589544990119143, 51.753998449507847],
            [0.584500224769325, 51.75114447934687],
            [0.581099161386996, 51.752382073765943],
            [0.578435452397594, 51.752805690578008],
            [0.575741659016691, 51.752574302477896],
            [0.572955568605757, 51.751763914319959],
            [0.57207185010746, 51.751893881772169],
            [0.572473028848526, 51.752171081111065],
            [0.570915060066915, 51.754384233900481],
            [0.569314273079968, 51.755820390747125],
            [0.568549957725668, 51.755962108600471],
            [0.567808493575629, 51.757451398627119],
            [0.567966760049255, 51.757916483660125],
            [0.566487696060976, 51.758666458066436],
            [0.56593086882936, 51.761828926514234],
            [0.56672657668268, 51.762749532961898],
            [0.565846286206188, 51.76319953310211],
            [0.566240054917556, 51.764826789177562],
            [0.565556731747304, 51.765044957483639],
            [0.565167974998457, 51.764783650883125],
            [0.562555648395845, 51.767255317004761],
            [0.560298966049491, 51.770867597303543],
            [0.558371811666418, 51.775390854269823],
            [0.55754946750252, 51.775692039659589],
            [0.554913812647722, 51.778025536979086],
            [0.554241935517153, 51.779092338940991],
            [0.551195224152909, 51.780211618973645],
            [0.549241984010816, 51.781800824018951],
            [0.548973540705095, 51.781694233327258],
            [0.545744776301715, 51.78494148880511],
            [0.545410328569166, 51.784873191495087],
            [0.543779099491743, 51.787553383126863],
            [0.54322683174045, 51.787557242430132],
            [0.542520084060242, 51.788398101175744],
            [0.541878257801673, 51.79044532309797],
            [0.539599596212848, 51.790850712913297],
            [0.536126326840037, 51.792180702126672],
            [0.531431570351155, 51.794957808088022],
            [0.52904142283885, 51.797134326445615],
            [0.528275896051785, 51.798642745292092],
            [0.528266410125836, 51.800261699350081],
            [0.523763578047868, 51.803420607378989],
            [0.524678655857306, 51.804895598667599],
            [0.524276107529522, 51.807011314235872],
            [0.525038948927402, 51.807292598443148],
            [0.524732710884881, 51.809677835559341],
            [0.527526869782135, 51.809261660313879],
            [0.527813975770835, 51.810269901312566],
            [0.528386415898259, 51.810699147511265],
            [0.529846187610012, 51.810133551056623],
            [0.53029763575117, 51.810474562387896],
            [0.532365094541219, 51.810268163318611],
            [0.534156669049136, 51.81091122426421],
            [0.532709623087212, 51.812479325042233],
            [0.532553213619849, 51.81592523243657],
            [0.53082275004347, 51.816427442813577],
            [0.524034625916942, 51.819693077142411],
            [0.523708077947741, 51.820522949829297],
            [0.525536787824467, 51.821020558745559],
            [0.525706655149396, 51.821462963131893],
            [0.522512190445372, 51.822156569924282],
            [0.524439010218773, 51.823343654182445],
            [0.525215825010223, 51.822935773392146],
            [0.525513588548979, 51.822162271855603],
            [0.52848800969722, 51.822619028021165],
            [0.52860777018883, 51.823306217167101],
            [0.530163684401724, 51.823180110728089],
            [0.529562635599488, 51.824185876053072],
            [0.529882640731709, 51.826089108498785],
            [0.529538233560246, 51.827479646843493],
            [0.530101047263423, 51.827862329001121],
            [0.529807335504952, 51.828655539352404],
            [0.530174202032975, 51.8296332792345],
            [0.528744138136281, 51.831076851913743],
            [0.530127195801818, 51.832652348165453],
            [0.529840852799131, 51.836399604365504],
            [0.526709856625731, 51.838581191694999],
            [0.523732028193505, 51.840080433424241],
            [0.523210826378113, 51.841274199110586],
            [0.520337911499093, 51.844006736828256],
            [0.524914295838036, 51.849245889303738],
            [0.519233736983357, 51.850440597741716],
            [0.513775778465086, 51.853159074393162],
            [0.512064457803677, 51.854398905289329],
            [0.511413393312911, 51.855537831669515],
            [0.512105002617988, 51.857264095169633],
            [0.511737625682676, 51.86062811364539],
            [0.51351014449616, 51.863789932890519],
            [0.513476714655978, 51.864388678663076],
            [0.508019734448013, 51.864497993178055],
            [0.505822056805414, 51.86557003774297],
            [0.503246827755826, 51.864841598719622],
            [0.502658610553056, 51.865261498148357],
            [0.50124330038886, 51.864624333978945],
            [0.495796990970658, 51.867366879561601],
            [0.494697415537509, 51.869572790709839],
            [0.491535256425567, 51.869737849104766],
            [0.490767243524586, 51.870377326034053],
            [0.485006589743427, 51.869313926827829],
            [0.483342002629196, 51.869713300582859],
            [0.483037115427716, 51.869505707320343],
            [0.482272310000035, 51.869625270853469],
            [0.481841320786961, 51.869765663186676],
            [0.482721434111164, 51.870341516854957],
            [0.483244032580517, 51.871547221748756],
            [0.482664508163441, 51.872683568306002],
            [0.479866279472864, 51.87400160884895],
            [0.473174654886994, 51.87625584073465],
            [0.472360453009384, 51.876986992996649],
            [0.47275964006282, 51.877297847738355],
            [0.469255103192548, 51.877669140712015],
            [0.467177194367085, 51.878648004687001],
            [0.470610704444789, 51.878172150713787],
            [0.469405997812071, 51.880263110890212],
            [0.462709087967423, 51.886646357675659],
            [0.460362049433839, 51.888428394416557],
            [0.456747112054759, 51.890416726982053],
            [0.455092348096929, 51.891974660729765],
            [0.451119476029361, 51.897997265473933],
            [0.451717323311981, 51.898271677421533],
            [0.451024595248729, 51.899004638251213],
            [0.450481728371668, 51.898912530712423],
            [0.448188059747699, 51.901535825795968],
            [0.444764124896976, 51.904044977301929],
            [0.440005090484938, 51.908269667775073],
            [0.43943113257978, 51.913104394563376],
            [0.437508895175237, 51.919092048864222],
            [0.437273894936615, 51.919613101473487],
            [0.435793919452322, 51.919757058390616],
            [0.434604765244489, 51.920814038625714],
            [0.432987311311944, 51.924789916269738],
            [0.423906889541349, 51.926381090475132],
            [0.421364135419583, 51.926295854770743],
            [0.42024138932156, 51.930326109747426],
            [0.42148778878428, 51.931375097108301],
            [0.421370384866289, 51.931950349163607],
            [0.422987883777246, 51.931920651315956],
            [0.422855845541334, 51.933087927180907],
            [0.420958083966977, 51.93615393364189],
            [0.420406276426114, 51.938389181701559],
            [0.420997295811199, 51.938935471340862],
            [0.41995669531001, 51.939578270878918],
            [0.420241878439423, 51.939713591531692],
            [0.419088475690485, 51.941099701652377],
            [0.421075375537959, 51.941682036074376],
            [0.420539579218026, 51.94359411516001],
            [0.420824796242989, 51.944269895193685],
            [0.424714449801151, 51.947778807393384],
            [0.423425169331093, 51.94904815440163],
            [0.425185035484778, 51.952022658510948],
            [0.427204010412976, 51.951410001260193],
            [0.430023403371685, 51.953812225941675],
            [0.42715067847846, 51.954491100931925],
            [0.426578931058883, 51.955250183474327],
            [0.426340485980866, 51.956818029741058],
            [0.425381522770964, 51.957819796924085],
            [0.425593860688154, 51.95819401343033],
            [0.424070897818527, 51.958698404993065],
            [0.423163912657993, 51.958695498845003],
            [0.422554928767547, 51.959171158176453],
            [0.42154547964265, 51.959103802533846],
            [0.420045031231802, 51.958379277829387],
            [0.418729031395499, 51.95856549954987],
            [0.417854618763678, 51.960006107078641],
            [0.417894277192323, 51.961391962966644],
            [0.416409907379873, 51.962698506273362],
            [0.417501742575086, 51.963754307613364],
            [0.417243768653931, 51.96450599740205],
            [0.41757186854214, 51.965273527832458],
            [0.416962560176912, 51.96563765416608],
            [0.41780425430065, 51.96596658575848],
            [0.417740696363086, 51.96640853202679],
            [0.4155272014003, 51.968724624333191],
            [0.413791514709428, 51.969099252767599],
            [0.412475440099028, 51.970970626604057],
            [0.413114171190279, 51.971937733631762],
            [0.412262723634535, 51.973081964381493],
            [0.413070752760816, 51.97354111439779],
            [0.414281640674003, 51.973503696167079],
            [0.414139964695878, 51.97403716832045],
            [0.417841481347378, 51.974094289773852],
            [0.419607402065574, 51.974656016951933],
            [0.42121959761094, 51.974769448209301],
            [0.421792865174023, 51.974626362759267],
            [0.422802291344295, 51.973445537281115],
            [0.423593217683814, 51.973208036098661],
            [0.424627700434688, 51.974541928104074],
            [0.424357138243416, 51.975382919502373],
            [0.425095030481238, 51.97588839585206],
            [0.423995563854455, 51.976399342672444],
            [0.423571085022573, 51.97706005078966],
            [0.423528183969043, 51.978288432537568],
            [0.424335302059484, 51.978591952177446],
            [0.423493227020852, 51.978585010784869],
            [0.424114521564455, 51.984157559298914],
            [0.426914060616205, 51.986749117192915],
            [0.425638274441509, 51.989796042250148],
            [0.423414374696601, 51.991905652954365],
            [0.421343236720687, 51.992800769162592],
            [0.42066596599252, 51.993477453532705],
            [0.420165969846389, 51.994794362648847],
            [0.421414448874569, 51.995901764909888],
            [0.420862372718368, 51.996712562976555],
            [0.421946588128398, 51.99715068804921],
            [0.42298149804759, 51.998186029759751],
            [0.422558796988477, 51.998155160200277],
            [0.420123251267, 52.000807714638647],
            [0.420227607789289, 52.001607712040268],
            [0.41865287504192, 52.003747041901704],
            [0.418828280760186, 52.004648995755403],
            [0.417704375666953, 52.00558572839396],
            [0.417052046532652, 52.00748667090906],
            [0.415373640115633, 52.008816946831416],
            [0.415181404701084, 52.01068595703299],
            [0.413809265314782, 52.011671804709302],
            [0.420043672181391, 52.014157095908679],
            [0.41988589794975, 52.014528136037583],
            [0.420936672577641, 52.014824876617517],
            [0.427989981269768, 52.014599624031028],
            [0.427925730385036, 52.014947164778832],
            [0.431215339582041, 52.01358075767309],
            [0.432791057948435, 52.013264047165435],
            [0.437725436491785, 52.014036984488094],
            [0.435691142661601, 52.016669858125951],
            [0.433192659470453, 52.01854251688539],
            [0.431318306629285, 52.019134276555377],
            [0.430897824668932, 52.020518828260975],
            [0.430412306743513, 52.020516259811792],
            [0.430060112275443, 52.024720382218099],
            [0.431073045998411, 52.025496222252258],
            [0.430880965745362, 52.027760030861799],
            [0.432667630108683, 52.032420879309761],
            [0.432559776483541, 52.034032783739093],
            [0.430053329408423, 52.033950535382061],
            [0.431359776879003, 52.038921660510084],
            [0.428218866516531, 52.039137531603501],
            [0.423271643726145, 52.038984030810411],
            [0.412810547169751, 52.038063031890275],
            [0.394002601205024, 52.031830101914039],
            [0.390751090615427, 52.035677512013706],
            [0.389665512693383, 52.036455814538385],
            [0.387707597167556, 52.040784059085055],
            [0.388159436450362, 52.043706461856956],
            [0.386216809008389, 52.044224234195241],
            [0.383064153544232, 52.045984046936098],
            [0.379582902242741, 52.049551609655438],
            [0.38226737448331, 52.050991904822133],
            [0.390389972802248, 52.060276688858991],
            [0.392441674200102, 52.061132492707728],
            [0.395985977083408, 52.060707954923622],
            [0.398984908331876, 52.063501148568143],
            [0.4006915995178, 52.063277539572788],
            [0.400829222246666, 52.06350404426199],
            [0.40219000355608, 52.063441234336814],
            [0.402361003721805, 52.063800146008482],
            [0.403539258550401, 52.063788702825832],
            [0.404608237033184, 52.065490755397548],
            [0.406236930173906, 52.065335203093973],
            [0.409667991952824, 52.064266904329934],
            [0.414552123557605, 52.065066157332673],
            [0.418755051191508, 52.064893613755352],
            [0.420627549708676, 52.066063752960481],
            [0.422940482129156, 52.066877693370813],
            [0.424534735172188, 52.067002264479441],
            [0.426107983879584, 52.067681187931392],
            [0.427267603974526, 52.067862332436469],
            [0.428764236297345, 52.06766602502217],
            [0.42895919024922, 52.068354432723183],
            [0.431039863993572, 52.068379860331611],
            [0.435349905117882, 52.069071399472328],
            [0.437028387649025, 52.070284859666359],
            [0.438095470062721, 52.072300497909161],
            [0.441200481680537, 52.072240698038598],
            [0.441203964839185, 52.07179639277642],
            [0.442276010114602, 52.071706730232343],
            [0.446068557372249, 52.072105556912099],
            [0.444229920631381, 52.070183333827863],
            [0.443920898721158, 52.06850633349611],
            [0.446713853715821, 52.068828749014756],
            [0.447261861988148, 52.069590729157873],
            [0.447335885673544, 52.068996580057565],
            [0.446766116211664, 52.068476952766559],
            [0.446987695824807, 52.067806000002783],
            [0.449513190862164, 52.067561075815505],
            [0.451772979820186, 52.067899851058172],
            [0.451844381586388, 52.069389337228017],
            [0.452685228181622, 52.069369137239242],
            [0.45271732823904, 52.068011486266542],
            [0.453592312805379, 52.068135349679508],
            [0.453798807939029, 52.071129180532047],
            [0.4562349196689, 52.071326612409841],
            [0.455996613908896, 52.070919719465074],
            [0.461792603456874, 52.072322997903342],
            [0.460212388678863, 52.072936928695043],
            [0.460737502783768, 52.073755080156744],
            [0.45983562563504, 52.073914198046673],
            [0.459035332708094, 52.074673692912775],
            [0.458085546929751, 52.074864371917407],
            [0.458129223872479, 52.07611073373149],
            [0.462555663818648, 52.076190957019676],
            [0.465240262803162, 52.076548474988506],
            [0.466636364775747, 52.078443672779471],
            [0.47023769537863, 52.075920432656112],
            [0.469840779792683, 52.0743352624023],
            [0.470763864728699, 52.073450817796626],
            [0.474690332821574, 52.075181321670442],
            [0.475805254960798, 52.074442084956225],
            [0.475437001638703, 52.07351009342689],
            [0.480997288869833, 52.072251068933944],
            [0.482954512534427, 52.072467025670186],
            [0.484108794569716, 52.070311436543655],
            [0.487115492063264, 52.069614917839374],
            [0.490216075108289, 52.067067446402731],
            [0.489438281517589, 52.066410334679638],
            [0.489257483643099, 52.065573341411401],
            [0.487217823726566, 52.063843069903939],
            [0.488499199806646, 52.061461730067663],
            [0.4913022005576, 52.059266725689767],
            [0.492092645090225, 52.059392989657091],
            [0.492289723936073, 52.058766527230418],
            [0.493195607788238, 52.058765343141566],
            [0.493220397576651, 52.058477052857384],
            [0.494659208992251, 52.058091380612971],
            [0.495048893857675, 52.057414072087354],
            [0.498372290605707, 52.057130505965432],
            [0.500060676960344, 52.05669630840989],
            [0.500471457087034, 52.056189394415703],
            [0.50024022026419, 52.05403425824143],
            [0.500788863346249, 52.053802290652854],
            [0.504558643902513, 52.054463194857277],
            [0.505359497821138, 52.054932669234546],
            [0.506084050630431, 52.05539386963752],
            [0.506526985773215, 52.056322384029386],
            [0.509178249292781, 52.057557291073422],
            [0.509021050467739, 52.058136172425506],
            [0.511023630480564, 52.059819201372086],
            [0.513145265966634, 52.059644461963408],
            [0.513281444918874, 52.05897789475334],
            [0.5148145207929, 52.059285999420531],
            [0.515772995091591, 52.05863694285415],
            [0.516531073489053, 52.058549707812368],
            [0.517237862688913, 52.058847552836959],
            [0.518159299118505, 52.058474445046016],
            [0.518359932893527, 52.057710271223726],
            [0.519264196751604, 52.057317737596392],
            [0.523122034758372, 52.05676125090028],
            [0.524822911377781, 52.057070127234809],
            [0.529720555800948, 52.05934902339142],
            [0.534182113030148, 52.059740532272556],
            [0.537070651063418, 52.059095625248183],
            [0.53765883452105, 52.058173788578451],
            [0.538493991975158, 52.057903097499221],
            [0.5422328850023, 52.057746925244359],
            [0.543077842013847, 52.058496668077552],
            [0.546182791165502, 52.059684984217085],
            [0.546637089589326, 52.061284868171477],
            [0.547574144225728, 52.06198402757493],
            [0.550276053916269, 52.062212432844852],
            [0.551005786080365, 52.061704727829934],
            [0.551499285144401, 52.062043858426904],
            [0.551713560649457, 52.062808098810457],
            [0.549640674858527, 52.064043624502041],
            [0.549433125598759, 52.065112859549146],
            [0.550298619132212, 52.066269479666836],
            [0.550049723427584, 52.067150762640743],
            [0.551341252409995, 52.068740596186714],
            [0.553346291221746, 52.068844636771757],
            [0.555813792493531, 52.068384656411695],
            [0.556037549371838, 52.068699944291787],
            [0.555777679801791, 52.06887644504156],
            [0.555477829302621, 52.068685109009763],
            [0.555112637511386, 52.069138172307568],
            [0.556676007126995, 52.069760725745127],
            [0.558412079977224, 52.070034185847469],
            [0.56146943646854, 52.071108932378088],
            [0.56230692891787, 52.070749893638371],
            [0.563062198202339, 52.070815298320312],
            [0.564903553713826, 52.070989250650811],
            [0.565055405445231, 52.071296195372909],
            [0.566458318243138, 52.071178410894035],
            [0.567033673266758, 52.070854724851401],
            [0.566638733751505, 52.070456856156035],
            [0.567403772734573, 52.070183891375279],
            [0.567813815832857, 52.069548157080632],
            [0.568367134751453, 52.069679979632504],
            [0.570546067073022, 52.06910098070562],
            [0.571746850567133, 52.069072974379431],
            [0.576231135698185, 52.071153050550492],
            [0.576269546566562, 52.072859043907307],
            [0.576745622330559, 52.073599530643868],
            [0.57733914639092, 52.073136008159778],
            [0.577807377465729, 52.073891950130751],
            [0.578945768315411, 52.073513618091006],
            [0.58254753021323, 52.07447518465446],
            [0.582102346958727, 52.075839259402855],
            [0.584291178105575, 52.075530474141125],
            [0.584929525461904, 52.074126181010904],
            [0.585898057558181, 52.074445753367407],
            [0.589349917940951, 52.074553388174202],
            [0.589827894567389, 52.075272199295924],
            [0.591276211703415, 52.074652221242168],
            [0.59322151674761, 52.075028482680374],
            [0.593403392088892, 52.074641384715001],
            [0.594133051627534, 52.074741327432989],
            [0.594772365992971, 52.074164297015379],
            [0.595960436068514, 52.074568879878683],
            [0.595831788351398, 52.075017758982227],
            [0.595336536390837, 52.07508442598067],
            [0.595673083429135, 52.075954711452674],
            [0.59506195055078, 52.076162419843882],
            [0.595750832947008, 52.076363970641289],
            [0.598470207030063, 52.07531571004759],
            [0.599938538342048, 52.075594466597394],
            [0.601083083711473, 52.076353377452619],
            [0.603673655714418, 52.077231408138815],
            [0.604365937030786, 52.079445431522053],
            [0.605458702591848, 52.080214438397718],
            [0.60621183801866, 52.080264327378714],
            [0.607346625650831, 52.080901991283334],
            [0.608631859310671, 52.080690086753101],
            [0.609369846926766, 52.080305037249481],
            [0.609152873988583, 52.079938438059614],
            [0.611196770066944, 52.079396749752199],
            [0.612243072519754, 52.078793515810169],
            [0.615390347312103, 52.078103181811358],
            [0.615698144824067, 52.077651201719505],
            [0.61708049296769, 52.077829136293722],
            [0.618172667886314, 52.077537781189363],
            [0.618229024174752, 52.07695469029558],
            [0.619317826501981, 52.076556384093159],
            [0.620395450172167, 52.076964077491141],
            [0.620225772769365, 52.078776317373276],
            [0.622652648796727, 52.079268183799385],
            [0.624092426291034, 52.079055366742921],
            [0.624265311015419, 52.079340187101693],
            [0.625690968550034, 52.079259860258283],
            [0.6267501107997, 52.079847765857906],
            [0.626250212214272, 52.080382296019934],
            [0.626763980378903, 52.08102822139908],
            [0.62808227285168, 52.081653502903883],
            [0.631745889712422, 52.081850500618131],
            [0.632921288893471, 52.082799067271083],
            [0.632514232712465, 52.083298275644559],
            [0.632546304012352, 52.08451249754723],
            [0.634543081449455, 52.086269138524393],
            [0.637235722544861, 52.086628865194655],
            [0.637854488395304, 52.086155476511479],
            [0.641752475342232, 52.086235406730204],
            [0.642311904693968, 52.085549293594603],
            [0.6434479089869, 52.085554386489946],
            [0.643765601306463, 52.085174951906694],
            [0.645371636081647, 52.08485922265757],
            [0.645688744865709, 52.085185738833275],
            [0.646592822761769, 52.085095288666132],
            [0.646885934655488, 52.084523050024224],
            [0.647757603212952, 52.084132056403483],
            [0.651847097841095, 52.083136290354489],
            [0.652871718923487, 52.083405492785332],
            [0.654688500206365, 52.08504354798999],
            [0.656870318779221, 52.085427824707665],
            [0.658580269517491, 52.085412632691096],
            [0.660830647829503, 52.083685547376817],
            [0.663222567336828, 52.084092839075794],
            [0.664437320688595, 52.084979055522261],
            [0.664372157591754, 52.085477838678926],
            [0.665742488117887, 52.086009798962536],
            [0.668436217313861, 52.086212312089302],
            [0.66890381167209, 52.08580423232879],
            [0.670212018768259, 52.085759301863106],
            [0.670850678282139, 52.086089255234207],
            [0.671653760051809, 52.085823740679494],
            [0.672412294548639, 52.086156363031343],
            [0.673764693689599, 52.084702094003354],
            [0.675686894401551, 52.083916535122988],
            [0.6759418947342, 52.084494386564302],
            [0.678914605119675, 52.083871962729773],
            [0.680835224075861, 52.085034231623951],
            [0.681059661571523, 52.086440120964149],
            [0.683086017241949, 52.087026196558028],
            [0.684257045709084, 52.086932974210626],
            [0.687662176845069, 52.085562153515696],
            [0.688222775014369, 52.084761578225994],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000068",
        LAD13CDO: "22UD",
        LAD13NM: "Brentwood",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.35734960034587, 51.691419401103474],
            [0.360525134769747, 51.690379879695918],
            [0.362860024738573, 51.691704344834157],
            [0.368919548541179, 51.692832284289331],
            [0.371741969782331, 51.694921931137884],
            [0.373078022746788, 51.695427322827577],
            [0.376671489067175, 51.698498587644295],
            [0.3774918131213, 51.698714900806507],
            [0.377944274702091, 51.697932346652642],
            [0.380479451593168, 51.697375582052587],
            [0.380063595491312, 51.696924470924415],
            [0.380732865817863, 51.696193267444507],
            [0.380461744974987, 51.694419057656006],
            [0.381689615577977, 51.694102789870499],
            [0.381801434881973, 51.6936418811161],
            [0.385818973645714, 51.691987908318787],
            [0.388101213808052, 51.691595739097494],
            [0.389543157674021, 51.690978266248678],
            [0.391143385431636, 51.691717290457959],
            [0.391999062389091, 51.691452557165555],
            [0.392855820189626, 51.690550193292054],
            [0.392804460201482, 51.687820970699327],
            [0.394309827754216, 51.687750717267072],
            [0.395693009337368, 51.686161323651717],
            [0.396865423881223, 51.686526794993028],
            [0.396678320480447, 51.684409167139684],
            [0.398939038881625, 51.683523057098469],
            [0.398783917871985, 51.683215967675608],
            [0.401471598312681, 51.682417309957479],
            [0.399676376606424, 51.681325389861399],
            [0.397799671968599, 51.679456312887282],
            [0.404870444007192, 51.678688492558578],
            [0.406234950535402, 51.674467093555926],
            [0.407399389934465, 51.672804695302922],
            [0.4125336751766, 51.67089260969535],
            [0.411539007430201, 51.670328496724117],
            [0.411439786758019, 51.669767570706874],
            [0.408956115764595, 51.669487612617537],
            [0.408688091211646, 51.668933745043681],
            [0.407411485967613, 51.668423943761177],
            [0.406664132856786, 51.667257581572436],
            [0.405736206881053, 51.666940259756345],
            [0.405978091484427, 51.666292301473156],
            [0.402818424057695, 51.663400102114061],
            [0.400223751811631, 51.662243615036985],
            [0.400822927867348, 51.661761028285795],
            [0.40178040123306, 51.661628132169803],
            [0.401632232725097, 51.660631138303899],
            [0.402748011932918, 51.660320530687905],
            [0.402734361858416, 51.659652627660499],
            [0.401619362023654, 51.659294137752028],
            [0.401493727940439, 51.658176175822348],
            [0.400534047250873, 51.657609457395701],
            [0.400593981201732, 51.657156781566577],
            [0.399238454853233, 51.65621234542629],
            [0.397926946173775, 51.654347906863748],
            [0.396968762926994, 51.653752351624831],
            [0.397519562807223, 51.653532465615228],
            [0.399234371591484, 51.653808590728282],
            [0.400717633703111, 51.652657734503272],
            [0.401956843819912, 51.652552347605898],
            [0.40240141896274, 51.652183528630474],
            [0.402300825526075, 51.65099221032591],
            [0.401893730956476, 51.649772989881335],
            [0.402180933097003, 51.649599842471332],
            [0.402815524803664, 51.649844955323523],
            [0.402859026964605, 51.649164190521951],
            [0.402606687692145, 51.648356383927542],
            [0.402313509783767, 51.648553036698736],
            [0.401969705746298, 51.647983617694081],
            [0.402374175136358, 51.647567955714933],
            [0.403658624592053, 51.647473314788343],
            [0.402914549140167, 51.64682486124925],
            [0.403018709260357, 51.645842486437935],
            [0.401437777492702, 51.645988151669677],
            [0.401342285848318, 51.645521567280973],
            [0.400868154910883, 51.645576230813205],
            [0.400272409357495, 51.645162143491355],
            [0.399246667146766, 51.645200199992516],
            [0.398845682224002, 51.6447785274311],
            [0.396256402182684, 51.644852995941761],
            [0.395682908953203, 51.644366487061298],
            [0.394888799473324, 51.644438448062765],
            [0.394516242587346, 51.64397930954776],
            [0.394898705920411, 51.6431729248959],
            [0.394152612406273, 51.641220466706663],
            [0.392628171327929, 51.639581541620878],
            [0.392178918409336, 51.639641059184008],
            [0.391973499120522, 51.639253148698749],
            [0.391082189704783, 51.639399009653509],
            [0.390507982395679, 51.639033896648399],
            [0.38978605005733, 51.639102555240754],
            [0.387448097271895, 51.63768246746281],
            [0.386873959690945, 51.636932433484489],
            [0.387050200743566, 51.635995371960817],
            [0.386050131917828, 51.634343899555539],
            [0.385185808995225, 51.632799375447028],
            [0.386213972004503, 51.631463694331366],
            [0.385828403332653, 51.63102817529581],
            [0.387179005464274, 51.629752478752224],
            [0.387597578057636, 51.628340149625203],
            [0.38679115175824, 51.626933851005944],
            [0.386938834590617, 51.62608640002324],
            [0.38499296505177, 51.623229295663933],
            [0.384198194490895, 51.619561888413678],
            [0.37867046673817, 51.616599330064957],
            [0.376634248556103, 51.615754767803068],
            [0.376237180211198, 51.615868926218234],
            [0.375098472173366, 51.615169835155122],
            [0.37574380686748, 51.614886082173811],
            [0.377669850553405, 51.612104194221502],
            [0.379328446463727, 51.603577527803921],
            [0.382505270772789, 51.599543526301098],
            [0.38357990042661, 51.598721317771378],
            [0.382768206444804, 51.598084006076135],
            [0.381860724856317, 51.595533106578166],
            [0.382627381880972, 51.594263006025571],
            [0.383880943684187, 51.593716844714692],
            [0.383395238153071, 51.59345781828123],
            [0.384990335126565, 51.590309299861993],
            [0.386000288098986, 51.585916321206376],
            [0.381260524135425, 51.584998192411014],
            [0.382001550979379, 51.582081976285977],
            [0.383209489270713, 51.576753311228089],
            [0.38294378315821, 51.573688460020122],
            [0.383625711105154, 51.572102609993209],
            [0.382688411352809, 51.57195168057099],
            [0.383636316629332, 51.569767780959076],
            [0.38169360697626, 51.568299092201222],
            [0.382575631240661, 51.565821562922821],
            [0.379202406176746, 51.565733545158537],
            [0.34375565466105, 51.56781943532232],
            [0.3129800399729, 51.565940078477809],
            [0.31300694726663, 51.565816343565736],
            [0.290261744473168, 51.564297622612614],
            [0.29017356590092, 51.564495391260856],
            [0.286677043439061, 51.571504380613987],
            [0.287137867260392, 51.572548475428462],
            [0.286724800825904, 51.573462119855677],
            [0.285065264810989, 51.574126659962339],
            [0.282001656571905, 51.578829356012612],
            [0.273813118993403, 51.586859858855064],
            [0.272071496927619, 51.587744327872272],
            [0.270900377597453, 51.590285020000429],
            [0.270104196782504, 51.59151626984147],
            [0.269308243584922, 51.595272722088311],
            [0.270312192811005, 51.597308185087421],
            [0.26985186624805, 51.599572513964951],
            [0.26437135086122, 51.59976652480961],
            [0.260848450426015, 51.600387513310693],
            [0.258224962018516, 51.600918248474756],
            [0.257887311521207, 51.60127277066141],
            [0.254789971938049, 51.601335021810087],
            [0.253982665170995, 51.601596036574506],
            [0.25389030233245, 51.601917957478761],
            [0.254669880878659, 51.602729428820219],
            [0.255139031618287, 51.602667356134667],
            [0.256599278656525, 51.603604223205096],
            [0.257129714818035, 51.604923171679644],
            [0.257972450490163, 51.604909657838618],
            [0.258053073341663, 51.606505244146689],
            [0.26165039430385, 51.607458464944102],
            [0.263089093624244, 51.607317419623122],
            [0.264298903442432, 51.607830060298653],
            [0.264535513467253, 51.608318305681038],
            [0.263231329206801, 51.609189695441714],
            [0.259022989658566, 51.612281595152915],
            [0.257665561075275, 51.614180033487798],
            [0.252767745721984, 51.617426149243379],
            [0.237324076158979, 51.624070507470414],
            [0.224060102665074, 51.631734464913642],
            [0.22204934178643, 51.63012326467009],
            [0.217519513128998, 51.629090222811634],
            [0.212081900898745, 51.627183831368882],
            [0.21222350835632, 51.626976126772092],
            [0.208435084922605, 51.626679660150828],
            [0.20358663574063, 51.625170065046916],
            [0.200283844292244, 51.624932563652074],
            [0.199324576076769, 51.631267070479808],
            [0.196920788899475, 51.634331856075384],
            [0.196032619625818, 51.636647025993732],
            [0.195893406708738, 51.638880734685841],
            [0.194595575423243, 51.640976036612606],
            [0.193210170127118, 51.641349047989614],
            [0.190526370474351, 51.642792134201805],
            [0.188931533632064, 51.644460360220258],
            [0.188416329667859, 51.645827869495989],
            [0.184703228739675, 51.648660493637621],
            [0.177278470094217, 51.652122133762226],
            [0.1756758875862, 51.655788496038483],
            [0.17675740524142, 51.656182114550461],
            [0.178982127388346, 51.656385449260512],
            [0.179574128617576, 51.655585799702088],
            [0.180619525835175, 51.655045709902033],
            [0.180676098726345, 51.653724527855623],
            [0.182212084690171, 51.653075490999271],
            [0.182957688399119, 51.653157852358319],
            [0.183974246872044, 51.654034622353933],
            [0.184489961910187, 51.654086178563361],
            [0.186542885461652, 51.652734125878617],
            [0.189260444261339, 51.653116932790134],
            [0.191197665731643, 51.652696803670871],
            [0.192963309699426, 51.653095488696671],
            [0.193573952871473, 51.654903312162205],
            [0.192285862757795, 51.655653984026898],
            [0.192419360418139, 51.657221622512154],
            [0.190880787152098, 51.658356448307025],
            [0.191285173381361, 51.658916342990118],
            [0.190831227477209, 51.659816892807925],
            [0.193048503974336, 51.660968834197355],
            [0.196088985596217, 51.660991140649081],
            [0.197388834067688, 51.660541464804545],
            [0.199193549088185, 51.660455517628272],
            [0.20090033116153, 51.659691527256513],
            [0.208129798436349, 51.660967648589349],
            [0.20842169847799, 51.66239110636679],
            [0.206908120045205, 51.663678549589484],
            [0.206760653594156, 51.664482574675077],
            [0.208076832097911, 51.665231206529448],
            [0.208789622018997, 51.664812227205545],
            [0.210566850209283, 51.664812054347941],
            [0.213274248749159, 51.665530830980416],
            [0.213575880003703, 51.666586289450798],
            [0.212789947602488, 51.667136171849059],
            [0.212664161398772, 51.667908321418373],
            [0.213496640278909, 51.668995141872337],
            [0.213080839610945, 51.670641457507635],
            [0.213833121658465, 51.673226179319151],
            [0.214908724207235, 51.673631252448494],
            [0.214639528436454, 51.674013125690536],
            [0.214999963768137, 51.674848947165934],
            [0.216028395454492, 51.675086735781001],
            [0.218131543855508, 51.674614475850262],
            [0.219978600487323, 51.675383522537821],
            [0.223606267037422, 51.67541380438437],
            [0.224417156386111, 51.674989276074321],
            [0.226052374944063, 51.675005050641296],
            [0.227466604119699, 51.674290286270875],
            [0.229410177712432, 51.674694946392748],
            [0.231657179557635, 51.673592016573323],
            [0.234103754697025, 51.673519414356861],
            [0.235123110277567, 51.674041380369609],
            [0.236141208189502, 51.673539084930958],
            [0.238975104774402, 51.674105585068233],
            [0.240059790936571, 51.675656835020831],
            [0.239040505623343, 51.676897493528259],
            [0.237610497922695, 51.67731953424083],
            [0.237060211066995, 51.677893865428359],
            [0.235971249239969, 51.678045900112814],
            [0.234242222211044, 51.679329703535153],
            [0.233699092831106, 51.679345431691083],
            [0.232157505421153, 51.683354039823151],
            [0.233467608648562, 51.684400167284657],
            [0.23540738882258, 51.684659120428385],
            [0.236701534130598, 51.683618296923925],
            [0.23842351197739, 51.682864266873679],
            [0.240198557775961, 51.682746785843435],
            [0.240309120846326, 51.683550428416687],
            [0.243171279304553, 51.684996684017882],
            [0.242711237914168, 51.685494677094198],
            [0.244371060099243, 51.687725546941621],
            [0.245794586755898, 51.688131784182808],
            [0.24694963941428, 51.688725694568596],
            [0.248765619266039, 51.688816833869019],
            [0.248830312130992, 51.689187895748219],
            [0.25191716091643, 51.688997450203033],
            [0.252908151848492, 51.689898404213913],
            [0.254285298287123, 51.689834207618681],
            [0.255172483420847, 51.690450265828602],
            [0.254923155294096, 51.692303968056244],
            [0.25556940296321, 51.692816736021562],
            [0.255678330835765, 51.693203131763141],
            [0.257054926487975, 51.694027400209144],
            [0.257685638484362, 51.6950701297609],
            [0.257750999867541, 51.695801784363425],
            [0.256987129320442, 51.696590744277806],
            [0.257485682377158, 51.69683835558606],
            [0.263487127306446, 51.699221038346479],
            [0.265712358867374, 51.699702410711865],
            [0.266254930554491, 51.698744103354159],
            [0.265680715610705, 51.698518671906086],
            [0.265699409730929, 51.69813971515277],
            [0.266329287786391, 51.696206703454507],
            [0.26684077728984, 51.695814638619289],
            [0.267980573922861, 51.696093891618929],
            [0.268009256294352, 51.696435962943234],
            [0.269415263404671, 51.696366536388631],
            [0.269881341311298, 51.696657888434331],
            [0.272905210842377, 51.696443830693781],
            [0.273942117289617, 51.696892283352994],
            [0.275294843229879, 51.69622219981769],
            [0.275861954750411, 51.695440528524529],
            [0.279643772158912, 51.693638763944463],
            [0.28345130322504, 51.694107060512771],
            [0.28462702603663, 51.693345884376185],
            [0.283886250604286, 51.69271550794965],
            [0.288643215068504, 51.690492500947009],
            [0.292605059395379, 51.687289315587989],
            [0.291793591054865, 51.686290763020537],
            [0.293869450469259, 51.686428290666164],
            [0.297199186930358, 51.684412653954404],
            [0.298733147884967, 51.682650613490395],
            [0.302097768712273, 51.680961490154935],
            [0.303162282365835, 51.681784146067507],
            [0.303399442562409, 51.682722845014695],
            [0.302962547777439, 51.683520986782433],
            [0.303887654716296, 51.683420106084711],
            [0.305031551711128, 51.684806838627907],
            [0.303840207087635, 51.684992988103559],
            [0.303864876304731, 51.685279376172744],
            [0.305570780195332, 51.688044472157266],
            [0.305690384574203, 51.689031337680575],
            [0.306866563044314, 51.690195295411051],
            [0.307393569668426, 51.691880094422686],
            [0.307429177665377, 51.694286780527499],
            [0.313504109608015, 51.70220597383927],
            [0.321229645123768, 51.708281090612786],
            [0.323799609223068, 51.709790531779966],
            [0.323743275834434, 51.710329418817736],
            [0.32167742024794, 51.711343298082696],
            [0.32197652533537, 51.712152137341825],
            [0.323345038701982, 51.7132635698431],
            [0.323034997564357, 51.714162691717789],
            [0.324559802654653, 51.7143114825246],
            [0.330010466793847, 51.716425618422726],
            [0.333972570332174, 51.717007931689032],
            [0.335930852460393, 51.713626356622612],
            [0.335949141231331, 51.712071126414635],
            [0.336948462156753, 51.709300328720865],
            [0.33917314646957, 51.706772210548543],
            [0.339659033111515, 51.703958552182272],
            [0.338859633387643, 51.701508625754784],
            [0.340076597106221, 51.701112078600538],
            [0.343874560843734, 51.69840232379044],
            [0.344664788084657, 51.697087975393373],
            [0.344942347007117, 51.695514971114839],
            [0.34660691957515, 51.693900769343756],
            [0.353029189976719, 51.692093286641658],
            [0.35734960034587, 51.691419401103474],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000069",
        LAD13CDO: "22UE",
        LAD13NM: "Castle Point",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [0.525240156114017, 51.517066231542792],
              [0.524512132072287, 51.516960497608331],
              [0.522872109381678, 51.517566877412122],
              [0.521702050025628, 51.518722516781544],
              [0.521334583999157, 51.521386167692746],
              [0.524632272491625, 51.517959775677085],
              [0.524340965918536, 51.517358093715515],
              [0.525240156114017, 51.517066231542792],
            ],
          ],
          [
            [
              [0.613925269204814, 51.530748011816769],
              [0.612043539566953, 51.530218854877276],
              [0.609032728542126, 51.530746352226345],
              [0.604985787328398, 51.533987651996789],
              [0.604689350590186, 51.534402542096309],
              [0.606229349765975, 51.533921347883528],
              [0.607562644497329, 51.533507684351896],
              [0.608914129106192, 51.532227525181973],
              [0.611452680640954, 51.531685368296223],
              [0.613925269204814, 51.530748011816769],
            ],
          ],
          [
            [
              [0.582532278401624, 51.535926849560965],
              [0.581517097853952, 51.535927617780594],
              [0.581430648474437, 51.536209217670439],
              [0.582532278401624, 51.535926849560965],
            ],
          ],
          [
            [
              [0.578714735030846, 51.536379591611805],
              [0.579785807338039, 51.535913555438867],
              [0.577375734948014, 51.536315496503526],
              [0.578714735030846, 51.536379591611805],
            ],
          ],
          [
            [
              [0.579397602453471, 51.536494978622898],
              [0.580294563617653, 51.536086728155539],
              [0.578918169409905, 51.536457857781159],
              [0.578677562892461, 51.536920916087944],
              [0.579454847697412, 51.536793201784164],
              [0.579397602453471, 51.536494978622898],
            ],
          ],
          [
            [
              [0.597597006973848, 51.534218110451953],
              [0.596483968966722, 51.534120449212018],
              [0.591803243266591, 51.535568522779215],
              [0.588976412477251, 51.535739787987829],
              [0.586112315741324, 51.536551242680893],
              [0.58554133954122, 51.537188879433209],
              [0.587249329697082, 51.53709634883888],
              [0.594019861036188, 51.536686865047137],
              [0.600643796913868, 51.535504101219971],
              [0.597597006973848, 51.534218110451953],
            ],
          ],
          [
            [
              [0.624218580838645, 51.536502404987694],
              [0.627398745394051, 51.534580323400441],
              [0.627859273618221, 51.533913475139187],
              [0.626709241471543, 51.532172909806143],
              [0.625845266119638, 51.531993502871295],
              [0.624763834425609, 51.532345079575741],
              [0.624281566047084, 51.53223356150049],
              [0.624525005312938, 51.532630124399347],
              [0.619394191508142, 51.532626134478349],
              [0.618529643671761, 51.532988996473911],
              [0.614505659886443, 51.533276642768712],
              [0.611916292691003, 51.53376333797533],
              [0.60900813619341, 51.534829056102382],
              [0.609629631149659, 51.535030171832247],
              [0.614131708399979, 51.534928995718516],
              [0.617084744569506, 51.535602395185279],
              [0.621351021645117, 51.535788601167795],
              [0.624218580838645, 51.536502404987694],
            ],
          ],
          [
            [
              [0.58368028756677, 51.53806718923925],
              [0.586293294649814, 51.53753023807829],
              [0.582155073563212, 51.538077399874112],
              [0.58368028756677, 51.53806718923925],
            ],
          ],
          [
            [
              [0.586631466468232, 51.538076777352785],
              [0.589918366113475, 51.537395372733563],
              [0.587383178587036, 51.537472920347852],
              [0.583275000432302, 51.538295558449128],
              [0.586631466468232, 51.538076777352785],
            ],
          ],
          [
            [
              [0.552412314029726, 51.543050762506418],
              [0.55309279939259, 51.541886592022934],
              [0.553613662455488, 51.542225095457518],
              [0.553100407826993, 51.542331600409149],
              [0.553063464189219, 51.542856720704819],
              [0.55360897405351, 51.543120042309695],
              [0.555366717130809, 51.542186011026295],
              [0.555886064793438, 51.542265527069524],
              [0.554240032452749, 51.543108098410677],
              [0.554610339414729, 51.543564992219181],
              [0.55543553775689, 51.543491254987124],
              [0.554951570339965, 51.54378599150904],
              [0.555670155830462, 51.543894443573485],
              [0.555459527447991, 51.543687688074066],
              [0.556107450394626, 51.543436141788597],
              [0.555792458895071, 51.543631868558897],
              [0.556666391854162, 51.543887119023616],
              [0.558485495219964, 51.54399583931356],
              [0.562022881128974, 51.542752167355928],
              [0.563706581636752, 51.54145359426029],
              [0.565486584352268, 51.541227604871537],
              [0.565539632497635, 51.540811846986735],
              [0.565839499667857, 51.541117358755955],
              [0.568736980880899, 51.540247216814834],
              [0.567305614053995, 51.540209311295378],
              [0.566678917288638, 51.53986239226009],
              [0.56801769499454, 51.540202713819546],
              [0.569153753133916, 51.539606747391353],
              [0.569014923981194, 51.540086441200593],
              [0.569424671818897, 51.540191681091748],
              [0.573207056235923, 51.539108676625943],
              [0.576899052269115, 51.538266760637299],
              [0.581419477940668, 51.53822849662442],
              [0.585241191952125, 51.537363674653761],
              [0.585885991398889, 51.536451009969973],
              [0.582704333371696, 51.535934751841971],
              [0.581679922700991, 51.536358417726703],
              [0.579637048708881, 51.536496007001212],
              [0.580462169768204, 51.536729670262979],
              [0.580436748828179, 51.5369685564552],
              [0.577929120084888, 51.537201779334112],
              [0.577811993054053, 51.537476855921483],
              [0.577472870881224, 51.536834982251314],
              [0.57858462377941, 51.536552429112817],
              [0.577327151499461, 51.536553092287818],
              [0.577079877665817, 51.536158317663372],
              [0.577946869494323, 51.53588294776204],
              [0.579903915082405, 51.535758068201048],
              [0.580007218608396, 51.536029196794999],
              [0.580938171024178, 51.535916078460538],
              [0.581015199956685, 51.536181489059842],
              [0.581563354927702, 51.535751226850799],
              [0.582927857748128, 51.535731073022355],
              [0.586577991395383, 51.536269369223092],
              [0.588633182727614, 51.535510836702514],
              [0.592111878204702, 51.535188471330329],
              [0.593933233759466, 51.534605889504022],
              [0.593641918355794, 51.53442796658905],
              [0.596712986216966, 51.533735852603613],
              [0.600915778750315, 51.534937774040756],
              [0.601611831506464, 51.534879164850778],
              [0.605266994371055, 51.532170111976981],
              [0.605877290636032, 51.531494628687284],
              [0.605811360380829, 51.530693872513773],
              [0.602574147237097, 51.53142499043696],
              [0.600694299479682, 51.530749915543375],
              [0.596811718472755, 51.531949356654238],
              [0.596607227262698, 51.532384673467639],
              [0.596074906275104, 51.530492986193785],
              [0.597627707525411, 51.530807096121514],
              [0.599774800525453, 51.529796308749901],
              [0.598836290589217, 51.529799125683311],
              [0.598759002260744, 51.529581397129171],
              [0.600878239945508, 51.52982849828296],
              [0.600900357454815, 51.529609465504173],
              [0.604654885290142, 51.530263595417267],
              [0.607947402838226, 51.529190334592741],
              [0.610556507269666, 51.528850779394681],
              [0.616378404215191, 51.529674334582928],
              [0.61963862378561, 51.529349725420609],
              [0.621005094243909, 51.529492764795727],
              [0.622460419440763, 51.52907440283181],
              [0.623056983856758, 51.528566414343238],
              [0.625617167653617, 51.528206032276323],
              [0.62447060902102, 51.527599454084786],
              [0.623751745401093, 51.527785519728447],
              [0.621810648679059, 51.52757801806667],
              [0.620983513334373, 51.527209787281592],
              [0.621033664426888, 51.526727511758942],
              [0.622623510561608, 51.526870035113241],
              [0.625064633775741, 51.526622048522164],
              [0.626585158713363, 51.527563798058431],
              [0.627961974602288, 51.527386353833244],
              [0.629058128973747, 51.526066709496931],
              [0.631560840143144, 51.525238020591715],
              [0.631886647062254, 51.524587668246319],
              [0.62987534408096, 51.524210999167451],
              [0.623736532336155, 51.521278127723718],
              [0.620530493255265, 51.520979291835708],
              [0.619617473390084, 51.521322562497872],
              [0.619318869642086, 51.520967692960959],
              [0.618842622109239, 51.521034091126097],
              [0.618990619754107, 51.521452582736309],
              [0.619590300099533, 51.521502141178019],
              [0.617849957126174, 51.521974499348261],
              [0.617372012204441, 51.521684785037635],
              [0.617013959006539, 51.522128064654993],
              [0.616752615441054, 51.521945033091349],
              [0.617581280286788, 51.521085640189177],
              [0.617260665881434, 51.521025345787962],
              [0.617896745600893, 51.520654101802634],
              [0.617737840373477, 51.520949939833628],
              [0.618133297680169, 51.520807106352272],
              [0.618817619102363, 51.519844803372315],
              [0.619852941841712, 51.520452122134998],
              [0.623047197780853, 51.520672096588449],
              [0.62256809333758, 51.52033746144658],
              [0.62371926623469, 51.52070202729157],
              [0.624460395653217, 51.520353578362737],
              [0.623977098639225, 51.520748420063498],
              [0.62486218732052, 51.520722313502652],
              [0.628193921733671, 51.522702707271428],
              [0.630428300461634, 51.523549260424218],
              [0.63387269297452, 51.523577163118865],
              [0.635940797088893, 51.52261604206948],
              [0.636689899560819, 51.522780869110292],
              [0.636829322473815, 51.521950324986328],
              [0.634647663943385, 51.520993886573216],
              [0.632653577178464, 51.520863303059265],
              [0.632403631439112, 51.519354397922399],
              [0.627803112246345, 51.520422605675087],
              [0.62793102606668, 51.52006539246203],
              [0.626273628028529, 51.519396534009601],
              [0.626593206389887, 51.518436957154215],
              [0.62491367131223, 51.518509645155412],
              [0.624895323347737, 51.518917464123355],
              [0.624450668328284, 51.518755641063009],
              [0.625028207983295, 51.516365714180324],
              [0.620777754155895, 51.515770995360405],
              [0.616869379775979, 51.514277227710515],
              [0.616595620854343, 51.514455114457114],
              [0.609381102607765, 51.513744427908961],
              [0.603289111319067, 51.51279703148419],
              [0.600032905531237, 51.512059869980135],
              [0.597466337907153, 51.512016931033628],
              [0.589399084055623, 51.510845453145059],
              [0.587816227259763, 51.51170420644182],
              [0.587306553116634, 51.513163409339953],
              [0.58563221988827, 51.513147284083196],
              [0.584453283494195, 51.512818034224772],
              [0.584189343180655, 51.51116005429261],
              [0.581443724058284, 51.5096826506919],
              [0.58045915415041, 51.509169211722934],
              [0.576846939701928, 51.508828663785941],
              [0.573302692108308, 51.507826391031664],
              [0.553285886857604, 51.510503395584138],
              [0.553539357685689, 51.509933086409163],
              [0.548734461334106, 51.516235918202447],
              [0.546643372293185, 51.518078242975825],
              [0.541309784894156, 51.520123052918962],
              [0.539836244871863, 51.520558784869145],
              [0.53696181479969, 51.520171341071709],
              [0.534057573954703, 51.519713423411048],
              [0.531028631588377, 51.518513471783002],
              [0.528734307028451, 51.518289574797564],
              [0.526674991385543, 51.519211724608923],
              [0.52564233746693, 51.519879698363503],
              [0.524583589759675, 51.521666103635255],
              [0.524281408640464, 51.521650125805238],
              [0.525017576067249, 51.520442650436266],
              [0.524226276482431, 51.52117466272999],
              [0.523734455502857, 51.522004547474161],
              [0.524353709463379, 51.521836531261343],
              [0.524311615289335, 51.522091950530502],
              [0.520864947311049, 51.527054903743988],
              [0.517966516437219, 51.529868250933937],
              [0.518569210375517, 51.530364333177587],
              [0.521301806252467, 51.5312553130372],
              [0.522524350845618, 51.532242575106842],
              [0.523836115676156, 51.532469762610781],
              [0.525970196527388, 51.532365356477953],
              [0.528000816324513, 51.531487012441822],
              [0.53128476237632, 51.531109497207432],
              [0.533439101112816, 51.530332710256012],
              [0.534469293933116, 51.530422866541379],
              [0.537799298522389, 51.532093768931126],
              [0.537620595820363, 51.532484352456201],
              [0.538987474796243, 51.533674271135801],
              [0.541976834235217, 51.534012414484145],
              [0.543081410558517, 51.53450378808148],
              [0.543447786007961, 51.535333128607988],
              [0.54307567242691, 51.536909648814706],
              [0.543917573456958, 51.538404079566057],
              [0.544258029725559, 51.540965209829771],
              [0.543787294129288, 51.54436773161035],
              [0.544740330811282, 51.545834564104929],
              [0.544808178979304, 51.546688363900216],
              [0.545903123667451, 51.54738856853222],
              [0.548000855723464, 51.547164043971136],
              [0.551370556175613, 51.545363152827143],
              [0.556490759614995, 51.544433250801113],
              [0.554135592149633, 51.544050184853695],
              [0.554124763409059, 51.543702375565246],
              [0.552412314029726, 51.543050762506418],
            ],
          ],
          [
            [
              [0.570075543108665, 51.587678836212788],
              [0.582665020269716, 51.582949726453926],
              [0.587462555310191, 51.579631559291258],
              [0.588352578662392, 51.579494110991412],
              [0.5892768181945, 51.578613043593812],
              [0.591288718354018, 51.577923789715037],
              [0.593293987935094, 51.577727487569248],
              [0.598946244387825, 51.577194094795004],
              [0.59858432843795, 51.576922416460349],
              [0.612911652743348, 51.575468282525634],
              [0.632049034227376, 51.570524798291046],
              [0.631561278653829, 51.569925982342042],
              [0.634091330360648, 51.569479774080804],
              [0.635112545495657, 51.567948624124924],
              [0.633086476258216, 51.56547773876423],
              [0.634363861206125, 51.565258393328016],
              [0.634648588734624, 51.561797587117717],
              [0.635547237749706, 51.560645112700833],
              [0.633108377229366, 51.559090050125441],
              [0.631656867247327, 51.557640495663456],
              [0.629336365708315, 51.556381285383402],
              [0.628454425216656, 51.555398276556303],
              [0.626987116156206, 51.555396976190565],
              [0.625502073837713, 51.553660305891974],
              [0.627163180479696, 51.551193058630872],
              [0.625131995724824, 51.549263564460318],
              [0.624150058027396, 51.547717069979704],
              [0.624757317450588, 51.543783202783175],
              [0.623884691218447, 51.542078671300388],
              [0.623184699693828, 51.542120412506996],
              [0.623153433185455, 51.541351265936875],
              [0.623729059546943, 51.541253849423292],
              [0.622950740047863, 51.540911519432498],
              [0.622932213072692, 51.540464056105307],
              [0.624236883574043, 51.540604845233297],
              [0.624711435901684, 51.540405360605931],
              [0.623968855896367, 51.539029780049169],
              [0.624306640993866, 51.538559054353705],
              [0.625400423226866, 51.538068706456293],
              [0.626728670281458, 51.53829437814165],
              [0.627175235760749, 51.53803525336734],
              [0.625113442455553, 51.537016591960146],
              [0.621480266961343, 51.535954790324936],
              [0.617456707294318, 51.535776661785036],
              [0.613229670894998, 51.534991370615941],
              [0.609639669115724, 51.535152859199634],
              [0.608442430564606, 51.534947764526699],
              [0.60752950262357, 51.535299029390686],
              [0.606753546039694, 51.535120222668418],
              [0.605585171281837, 51.535796419910191],
              [0.605105461411741, 51.535477016193497],
              [0.605178209588785, 51.536068971410145],
              [0.604760769921673, 51.535880387401576],
              [0.599880034811204, 51.537157853324295],
              [0.599912711386239, 51.537503378430394],
              [0.599692577238096, 51.537208775387413],
              [0.598722937312364, 51.537250052951691],
              [0.587563301855799, 51.538766704374886],
              [0.584985195654131, 51.539258846006248],
              [0.584575024169876, 51.539044848670308],
              [0.580695747293236, 51.539455845065667],
              [0.578175381061644, 51.539338607491111],
              [0.565360547482126, 51.541935447281872],
              [0.563773760424008, 51.542391939275333],
              [0.560687644977311, 51.544324601355463],
              [0.555092192612645, 51.545600496956055],
              [0.554153032631973, 51.545726177174608],
              [0.554561006295361, 51.545413314053654],
              [0.55665947732779, 51.544844169379687],
              [0.550908001435954, 51.545964081533171],
              [0.547555432112352, 51.547685444693855],
              [0.545006234348423, 51.547626574071742],
              [0.544147118382245, 51.547016575562864],
              [0.544214142093686, 51.546330724256229],
              [0.542224552473108, 51.548468429326135],
              [0.541693325378737, 51.548487137803335],
              [0.539079785561842, 51.551728305056365],
              [0.538355240759668, 51.553327722230641],
              [0.538034237819171, 51.55432483975008],
              [0.538570388776349, 51.554134268368173],
              [0.538548203254984, 51.554461208115519],
              [0.53796464792288, 51.554655503146975],
              [0.538631894308508, 51.555419889238948],
              [0.538119197218634, 51.555384219518146],
              [0.538237698359028, 51.55603547341498],
              [0.53971337580717, 51.558879610440179],
              [0.539664618450522, 51.560075885282522],
              [0.54154440292563, 51.564340295539559],
              [0.541421123905821, 51.565932994013963],
              [0.543031256807365, 51.566160703863609],
              [0.543923640942623, 51.567282612263028],
              [0.542766512760261, 51.567415621955739],
              [0.542174186249751, 51.56786913548239],
              [0.542205691750205, 51.569442291460945],
              [0.542962891332806, 51.571916141037761],
              [0.545117543442579, 51.574756281660818],
              [0.549403328800806, 51.577423282330749],
              [0.557586107009244, 51.580614084689508],
              [0.564631768932499, 51.583868758618948],
              [0.567013698135882, 51.585507418983816],
              [0.567989937415902, 51.586665086312927],
              [0.5683991017053, 51.586573390156346],
              [0.568764203567838, 51.587312746712939],
              [0.567839415406862, 51.587221474066538],
              [0.566224989854623, 51.587778415306062],
              [0.566012278903881, 51.58812841368686],
              [0.565891563615595, 51.588240773136306],
              [0.567727954945838, 51.588188903485673],
              [0.570075543108665, 51.587678836212788],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000070",
        LAD13CDO: "22UF",
        LAD13NM: "Chelmsford",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [0.554931321680031, 51.616773927622006],
              [0.553893967191851, 51.616694884129807],
              [0.553994875257862, 51.617025440940118],
              [0.554931321680031, 51.616773927622006],
            ],
          ],
          [
            [
              [0.423436694475231, 51.846101449903479],
              [0.422550426166578, 51.844955141449887],
              [0.42564925458475, 51.843289631709581],
              [0.426081736460569, 51.842746534448096],
              [0.425631890924116, 51.842426677569897],
              [0.426370065614894, 51.841925833703776],
              [0.426040934442125, 51.841501868194079],
              [0.426769138255152, 51.84070806070784],
              [0.427490448709468, 51.84056906971724],
              [0.426833004233038, 51.840195049822469],
              [0.428014427509064, 51.838355003598018],
              [0.430627874161319, 51.836934091836731],
              [0.43144500083752, 51.836096148861266],
              [0.431623137952319, 51.835253431400339],
              [0.43071291922522, 51.834201203862918],
              [0.43053727400303, 51.833203930182819],
              [0.43134133171835, 51.83163963408839],
              [0.434088013218905, 51.831325604283705],
              [0.434392973443592, 51.831032417710574],
              [0.4366766682829, 51.831257592715168],
              [0.439428015605891, 51.832454143467189],
              [0.440088513904947, 51.83218683800321],
              [0.440576690721332, 51.830799895971538],
              [0.441079784795366, 51.830467506208677],
              [0.443205542098815, 51.82938377221749],
              [0.445743874520721, 51.828871457731452],
              [0.447045919943051, 51.829115049612284],
              [0.448507746516056, 51.830002787604535],
              [0.449665210042268, 51.830175620144196],
              [0.45134681953795, 51.833958950894612],
              [0.453026016634551, 51.834621775794226],
              [0.4593139491322, 51.835414884272716],
              [0.461876408607282, 51.8345968586938],
              [0.464702062298939, 51.837137506025918],
              [0.465314059176675, 51.838693937069387],
              [0.467068507651587, 51.839079802975256],
              [0.467087117254899, 51.839579418119222],
              [0.475168280469819, 51.840466239672345],
              [0.480691776226936, 51.840377708177051],
              [0.486393754355382, 51.839919129931538],
              [0.487312682478182, 51.841524717142306],
              [0.486770236135586, 51.842640523808711],
              [0.488872033539813, 51.842552885393047],
              [0.492490376272811, 51.84326851433125],
              [0.495870194810696, 51.844735510787885],
              [0.500275329254578, 51.845495350065335],
              [0.502050316005935, 51.846686029678338],
              [0.506786536578169, 51.848146327148321],
              [0.512482900356183, 51.848225287401959],
              [0.517681799196622, 51.849198634228557],
              [0.517665613467045, 51.849663018369959],
              [0.519233736983357, 51.850440597741716],
              [0.524914295838036, 51.849245889303738],
              [0.520337911499093, 51.844006736828256],
              [0.523210826378113, 51.841274199110586],
              [0.523732028193505, 51.840080433424241],
              [0.526709856625731, 51.838581191694999],
              [0.529840852799131, 51.836399604365504],
              [0.530127195801818, 51.832652348165453],
              [0.528744138136281, 51.831076851913743],
              [0.530174202032975, 51.8296332792345],
              [0.529807335504952, 51.828655539352404],
              [0.530101047263423, 51.827862329001121],
              [0.529538233560246, 51.827479646843493],
              [0.529882640731709, 51.826089108498785],
              [0.529562635599488, 51.824185876053072],
              [0.530163684401724, 51.823180110728089],
              [0.52860777018883, 51.823306217167101],
              [0.52848800969722, 51.822619028021165],
              [0.525513588548979, 51.822162271855603],
              [0.525215825010223, 51.822935773392146],
              [0.524439010218773, 51.823343654182445],
              [0.522512190445372, 51.822156569924282],
              [0.525706655149396, 51.821462963131893],
              [0.525536787824467, 51.821020558745559],
              [0.523708077947741, 51.820522949829297],
              [0.524034625916942, 51.819693077142411],
              [0.53082275004347, 51.816427442813577],
              [0.532553213619849, 51.81592523243657],
              [0.532709623087212, 51.812479325042233],
              [0.534156669049136, 51.81091122426421],
              [0.532365094541219, 51.810268163318611],
              [0.53029763575117, 51.810474562387896],
              [0.529846187610012, 51.810133551056623],
              [0.528386415898259, 51.810699147511265],
              [0.527813975770835, 51.810269901312566],
              [0.527526869782135, 51.809261660313879],
              [0.524732710884881, 51.809677835559341],
              [0.525038948927402, 51.807292598443148],
              [0.524276107529522, 51.807011314235872],
              [0.524678655857306, 51.804895598667599],
              [0.523763578047868, 51.803420607378989],
              [0.528266410125836, 51.800261699350081],
              [0.528275896051785, 51.798642745292092],
              [0.52904142283885, 51.797134326445615],
              [0.531431570351155, 51.794957808088022],
              [0.536126326840037, 51.792180702126672],
              [0.539599596212848, 51.790850712913297],
              [0.541878257801673, 51.79044532309797],
              [0.542520084060242, 51.788398101175744],
              [0.54322683174045, 51.787557242430132],
              [0.543779099491743, 51.787553383126863],
              [0.545410328569166, 51.784873191495087],
              [0.545744776301715, 51.78494148880511],
              [0.548973540705095, 51.781694233327258],
              [0.549241984010816, 51.781800824018951],
              [0.551195224152909, 51.780211618973645],
              [0.554241935517153, 51.779092338940991],
              [0.554913812647722, 51.778025536979086],
              [0.55754946750252, 51.775692039659589],
              [0.558371811666418, 51.775390854269823],
              [0.560298966049491, 51.770867597303543],
              [0.562555648395845, 51.767255317004761],
              [0.565167974998457, 51.764783650883125],
              [0.565556731747304, 51.765044957483639],
              [0.566240054917556, 51.764826789177562],
              [0.565846286206188, 51.76319953310211],
              [0.56672657668268, 51.762749532961898],
              [0.56593086882936, 51.761828926514234],
              [0.566487696060976, 51.758666458066436],
              [0.567966760049255, 51.757916483660125],
              [0.567808493575629, 51.757451398627119],
              [0.568549957725668, 51.755962108600471],
              [0.569314273079968, 51.755820390747125],
              [0.570915060066915, 51.754384233900481],
              [0.572473028848526, 51.752171081111065],
              [0.57207185010746, 51.751893881772169],
              [0.572955568605757, 51.751763914319959],
              [0.575741659016691, 51.752574302477896],
              [0.578435452397594, 51.752805690578008],
              [0.581099161386996, 51.752382073765943],
              [0.584500224769325, 51.75114447934687],
              [0.589544990119143, 51.753998449507847],
              [0.590669372334958, 51.755870328775465],
              [0.593338667882383, 51.755441815100255],
              [0.592482386427118, 51.754153999535632],
              [0.591667360896743, 51.751308541706663],
              [0.592098578239661, 51.748667611252714],
              [0.591778479662434, 51.745658346337471],
              [0.592984535428659, 51.739347260384683],
              [0.595130668879711, 51.734054107621624],
              [0.591774306122476, 51.733692041749975],
              [0.591177235535329, 51.733382357338506],
              [0.589101901067809, 51.731814734143228],
              [0.589471106892569, 51.730166228210834],
              [0.591162942259529, 51.728196269107293],
              [0.595339940426603, 51.725776591795572],
              [0.604081822226854, 51.724937379591147],
              [0.607311600098543, 51.72346187469131],
              [0.610272539496316, 51.723330454913857],
              [0.61112562263128, 51.722780888341035],
              [0.608828714425507, 51.719506214425877],
              [0.605202983015271, 51.715903048788284],
              [0.603989161576608, 51.713726613981208],
              [0.602047216926504, 51.711675120870467],
              [0.601984650872245, 51.708845420497738],
              [0.603759307645222, 51.705063976833792],
              [0.602962852705608, 51.702915148676595],
              [0.604536826522217, 51.700381909844616],
              [0.606625902389397, 51.700845475620717],
              [0.60708344473688, 51.700622176878291],
              [0.610335146200049, 51.700564327291765],
              [0.612557461629056, 51.69879268483681],
              [0.611157816792972, 51.696377634699076],
              [0.612229549229379, 51.694934651139747],
              [0.61087807270456, 51.694233552420208],
              [0.610662056039126, 51.692668121498208],
              [0.610534911533247, 51.689755312678059],
              [0.612354573095466, 51.690020614614326],
              [0.612826745588831, 51.689475904615179],
              [0.611275349896693, 51.689421383484401],
              [0.6123619557435, 51.68730986063288],
              [0.612177125191042, 51.685532393650618],
              [0.611124866052991, 51.684092584451086],
              [0.60913161398232, 51.684200748890248],
              [0.608809276709942, 51.681941592094191],
              [0.609541320745681, 51.681212152342077],
              [0.611200210407222, 51.680550232410077],
              [0.613303221443135, 51.68019047277312],
              [0.61461994837262, 51.679165593432664],
              [0.616114730382896, 51.678620573365073],
              [0.617823538463443, 51.677573429423759],
              [0.619738314609072, 51.675334540668622],
              [0.619716708612395, 51.673557035566489],
              [0.619189101271553, 51.673312496999124],
              [0.619488725346307, 51.670409049857831],
              [0.620899419042439, 51.667787474045255],
              [0.62329500266367, 51.665619623626533],
              [0.623489885214117, 51.663886741259731],
              [0.623298254673785, 51.662272215465663],
              [0.624071596339924, 51.661566037700695],
              [0.625243703000901, 51.658978514078456],
              [0.626398587789998, 51.658070428313984],
              [0.626092044619639, 51.657495414310141],
              [0.627575567093803, 51.649923049796733],
              [0.628416093419553, 51.645935440320727],
              [0.627522256243307, 51.644827680173911],
              [0.629106767370233, 51.642957541391311],
              [0.628940644652197, 51.641610451680165],
              [0.628349252781927, 51.641531964155924],
              [0.628442826464069, 51.639226654812887],
              [0.630669044896035, 51.638933054563878],
              [0.629465712632613, 51.638569706188967],
              [0.629667118379859, 51.637779168687793],
              [0.630359476696728, 51.637480360673528],
              [0.631169768048686, 51.637471190858577],
              [0.630632555383048, 51.637703572334999],
              [0.63187653667738, 51.637945485225913],
              [0.631889050487249, 51.638610717982971],
              [0.631241057645901, 51.638777236315768],
              [0.632705169663324, 51.638951251259648],
              [0.632088121855038, 51.638753745776363],
              [0.632162875704411, 51.638073065728179],
              [0.633141041025233, 51.638266966063924],
              [0.632918156003218, 51.638511191581713],
              [0.634024383119482, 51.638869489396363],
              [0.636498517803269, 51.638166408592454],
              [0.636636868897971, 51.637810757978258],
              [0.636100326653782, 51.637331769183966],
              [0.634578669036039, 51.638482921503979],
              [0.634146925089598, 51.637966496986124],
              [0.632114602886273, 51.637938347643207],
              [0.631901189288866, 51.637372950566373],
              [0.635614452116455, 51.637078274636963],
              [0.636460037190942, 51.635809194844214],
              [0.637178288417486, 51.635733700736758],
              [0.637174900391995, 51.636322846898047],
              [0.637765684945213, 51.636740355702671],
              [0.637234371205165, 51.637746070553838],
              [0.637678051118289, 51.638492447638207],
              [0.639841943554316, 51.638346666142851],
              [0.641921875227921, 51.637552508841559],
              [0.646672224788093, 51.636627073976392],
              [0.645084848800854, 51.635775207717323],
              [0.645264119003465, 51.635180297506238],
              [0.643512196547154, 51.634156746955824],
              [0.642582981957408, 51.632641593042322],
              [0.641926291297312, 51.632656378199741],
              [0.642005958804259, 51.63352964711946],
              [0.63816745254379, 51.633179816185027],
              [0.637070655453954, 51.63370450186423],
              [0.636863113052761, 51.633440261403827],
              [0.63373805456363, 51.633081448282603],
              [0.633337501378956, 51.632553528809098],
              [0.63201828592595, 51.632282742072697],
              [0.63090472890714, 51.632793356109481],
              [0.630129735378344, 51.632711799457319],
              [0.626882951419915, 51.633445535176655],
              [0.624898726165756, 51.632870285361335],
              [0.622333971900899, 51.632927621834263],
              [0.618398104693052, 51.632514568203661],
              [0.613498466019433, 51.630946512560286],
              [0.611980664173904, 51.630838207785679],
              [0.611295432170715, 51.630104306547075],
              [0.609633138068609, 51.630171859873336],
              [0.607730958113227, 51.629811238604688],
              [0.607357181477952, 51.631180243916226],
              [0.604815968805199, 51.63217648374966],
              [0.604535647309938, 51.636035470968459],
              [0.603143688839649, 51.636789420898403],
              [0.603091025577667, 51.637964224758228],
              [0.601986453146528, 51.638311580521751],
              [0.599776029704806, 51.637964856328118],
              [0.597622408768642, 51.638250865988219],
              [0.595544313665296, 51.637041367721899],
              [0.594584591325846, 51.637648056252381],
              [0.595003343081814, 51.638223366839682],
              [0.59651208185678, 51.638928353629048],
              [0.59734080165881, 51.640732971969911],
              [0.599681519169918, 51.642360210436017],
              [0.600578422386064, 51.643635385815706],
              [0.600954528301643, 51.645786358844198],
              [0.600522482211501, 51.647430925338902],
              [0.600265916259514, 51.643352723212494],
              [0.599014199048542, 51.642115980457099],
              [0.598472418941935, 51.641918430933814],
              [0.598086830742972, 51.642101438214283],
              [0.598588059984412, 51.642676709286377],
              [0.598406286621584, 51.643795011296525],
              [0.599072556589933, 51.644247914129167],
              [0.598339924790657, 51.64430280669022],
              [0.598249894153478, 51.644573701228552],
              [0.598783357925226, 51.645257077609244],
              [0.59991090138063, 51.645560364593081],
              [0.599020715166805, 51.646474919010608],
              [0.599123849902299, 51.646963672682091],
              [0.597998699496298, 51.64761272152635],
              [0.596021431733971, 51.649161957336744],
              [0.595616710849223, 51.649087269707138],
              [0.598943223162932, 51.646964974321634],
              [0.598830764789181, 51.646439554172886],
              [0.599727804017057, 51.645568916904118],
              [0.598726097195096, 51.645393245837411],
              [0.598038450859948, 51.644541508962149],
              [0.59820547711, 51.644201459989361],
              [0.598868959396978, 51.644175978827512],
              [0.598201381264793, 51.643826527479078],
              [0.598329801603489, 51.642685124891429],
              [0.597721090805504, 51.642277709545908],
              [0.598175852604207, 51.64171185359961],
              [0.596902169541072, 51.640695912531164],
              [0.596086959325223, 51.638950348610592],
              [0.594182810238379, 51.638078726411521],
              [0.594313738227999, 51.637460686360015],
              [0.595513360023209, 51.6367524658816],
              [0.596425003100233, 51.636949041491015],
              [0.597826359790555, 51.638000832865004],
              [0.599484881882809, 51.63771049746267],
              [0.602002989859889, 51.638019828552721],
              [0.602658559597688, 51.637857801262662],
              [0.602643533838747, 51.636787922598565],
              [0.604088696432787, 51.635524672131538],
              [0.604239470251265, 51.632203667273224],
              [0.603908818592148, 51.632006854220144],
              [0.606867043978043, 51.63097439159197],
              [0.606452314776448, 51.629739817132908],
              [0.604975081278342, 51.630287937903077],
              [0.601150512611998, 51.630777471447367],
              [0.598500473290155, 51.630579866241959],
              [0.597482869866011, 51.63232012801506],
              [0.595034823706634, 51.632447130455922],
              [0.592861646500649, 51.63156614812187],
              [0.590467767443099, 51.631121679393836],
              [0.586940900185164, 51.631158105374759],
              [0.587228592502099, 51.629357588012169],
              [0.585744991223983, 51.628578185230772],
              [0.587333582305469, 51.629145728058987],
              [0.587855668831028, 51.62856943343732],
              [0.585797839959771, 51.626484265545997],
              [0.585626908080136, 51.624901602395646],
              [0.586561912097458, 51.623964573814042],
              [0.585953714605796, 51.623559785962591],
              [0.584722443562372, 51.623657059858466],
              [0.582775793943523, 51.624539899210568],
              [0.581558445571163, 51.624221340006336],
              [0.5805012385415, 51.624738327210984],
              [0.578576710153411, 51.625096296777805],
              [0.577424888688626, 51.624990296735859],
              [0.576052201592524, 51.624275787445086],
              [0.575342250687486, 51.625210489769138],
              [0.575673095103518, 51.624266121989599],
              [0.574848034602846, 51.624112454568397],
              [0.573695461673727, 51.623150268011997],
              [0.570042780972641, 51.622739282749578],
              [0.568546730547088, 51.622216248645593],
              [0.566275626874477, 51.622628367006683],
              [0.564978357166394, 51.621555944497615],
              [0.563737918494033, 51.621336633149674],
              [0.564196826543662, 51.620558575837336],
              [0.564029679374169, 51.619849056506823],
              [0.562377851630109, 51.617917394814],
              [0.56076283058802, 51.617175624786221],
              [0.555472170852697, 51.616863909392961],
              [0.554066494219171, 51.617117412742616],
              [0.554039844530743, 51.61746603436864],
              [0.551608276383781, 51.617745562936541],
              [0.549541264240965, 51.617982946394648],
              [0.546785838688437, 51.617639878437046],
              [0.545091376300414, 51.618132601289318],
              [0.544349430341717, 51.617911267075257],
              [0.543593478485696, 51.618369226359917],
              [0.544702202545516, 51.619671701794665],
              [0.543521760997977, 51.619721575102282],
              [0.542448973687041, 51.62079254182057],
              [0.540960999205096, 51.620872433984374],
              [0.538546022281196, 51.620444457237795],
              [0.538181128322183, 51.619834510700279],
              [0.537551789133606, 51.619611597280027],
              [0.536172653655172, 51.620321303759354],
              [0.531850366049817, 51.619446059013114],
              [0.53051918681349, 51.62005843555648],
              [0.529039991115094, 51.62024410785191],
              [0.520795014283647, 51.618787426772101],
              [0.521055899582813, 51.621095790142569],
              [0.520398034714292, 51.623119915268212],
              [0.520777929238547, 51.624473335701126],
              [0.520232893055898, 51.62540595134621],
              [0.515527179500317, 51.624419626477817],
              [0.51465353943356, 51.626962754718896],
              [0.512872629163928, 51.626609694246895],
              [0.511422149538743, 51.62590779309663],
              [0.508575144877332, 51.625715073439466],
              [0.508714073768451, 51.625116751177487],
              [0.506779008870244, 51.624953054229522],
              [0.504793326868243, 51.624368623029255],
              [0.505421575905579, 51.621925239464304],
              [0.504801614289829, 51.621712743047922],
              [0.494539518771898, 51.623612157793396],
              [0.48793640865486, 51.62477035740492],
              [0.481330070619572, 51.625557730814421],
              [0.478162966121801, 51.625400718775268],
              [0.478004666169909, 51.625173835549205],
              [0.465799483227631, 51.623464097930764],
              [0.463046567601787, 51.623614535662981],
              [0.458934394384292, 51.623824871698972],
              [0.457936547038959, 51.624289137266786],
              [0.455145053995148, 51.624636247663162],
              [0.446800392196703, 51.626665802187979],
              [0.446164246350834, 51.628484885484255],
              [0.446268336579661, 51.629533114634881],
              [0.445483247283426, 51.630464976869263],
              [0.446192735527404, 51.630625557969985],
              [0.444135444156925, 51.633160415076908],
              [0.442866033279536, 51.636994532205662],
              [0.443359399515181, 51.638859329200045],
              [0.445900469980599, 51.641199476056634],
              [0.446906049482772, 51.641513961292858],
              [0.446795293995642, 51.641980315275731],
              [0.445825246254055, 51.643480799887058],
              [0.441928512788147, 51.64418425361918],
              [0.442908263279223, 51.646652261921695],
              [0.439946338670477, 51.647056503247988],
              [0.435357467225472, 51.648959410116916],
              [0.433782174151527, 51.649507385583199],
              [0.428897806100026, 51.649759634843377],
              [0.423347794624777, 51.649458859666602],
              [0.415761170525586, 51.649760020128177],
              [0.415746238418737, 51.65002292494907],
              [0.41418281140283, 51.650091959820266],
              [0.412702480795501, 51.650119696493306],
              [0.412199591779921, 51.649851246415039],
              [0.410196780897157, 51.65120267297214],
              [0.402300825526075, 51.65099221032591],
              [0.40240141896274, 51.652183528630474],
              [0.401956843819912, 51.652552347605898],
              [0.400717633703111, 51.652657734503272],
              [0.399234371591484, 51.653808590728282],
              [0.397519562807223, 51.653532465615228],
              [0.396968762926994, 51.653752351624831],
              [0.397926946173775, 51.654347906863748],
              [0.399238454853233, 51.65621234542629],
              [0.400593981201732, 51.657156781566577],
              [0.400534047250873, 51.657609457395701],
              [0.401493727940439, 51.658176175822348],
              [0.401619362023654, 51.659294137752028],
              [0.402734361858416, 51.659652627660499],
              [0.402748011932918, 51.660320530687905],
              [0.401632232725097, 51.660631138303899],
              [0.40178040123306, 51.661628132169803],
              [0.400822927867348, 51.661761028285795],
              [0.400223751811631, 51.662243615036985],
              [0.402818424057695, 51.663400102114061],
              [0.405978091484427, 51.666292301473156],
              [0.405736206881053, 51.666940259756345],
              [0.406664132856786, 51.667257581572436],
              [0.407411485967613, 51.668423943761177],
              [0.408688091211646, 51.668933745043681],
              [0.408956115764595, 51.669487612617537],
              [0.411439786758019, 51.669767570706874],
              [0.411539007430201, 51.670328496724117],
              [0.4125336751766, 51.67089260969535],
              [0.407399389934465, 51.672804695302922],
              [0.406234950535402, 51.674467093555926],
              [0.404870444007192, 51.678688492558578],
              [0.397799671968599, 51.679456312887282],
              [0.399676376606424, 51.681325389861399],
              [0.401471598312681, 51.682417309957479],
              [0.398783917871985, 51.683215967675608],
              [0.398939038881625, 51.683523057098469],
              [0.396678320480447, 51.684409167139684],
              [0.396865423881223, 51.686526794993028],
              [0.395693009337368, 51.686161323651717],
              [0.394309827754216, 51.687750717267072],
              [0.392804460201482, 51.687820970699327],
              [0.392855820189626, 51.690550193292054],
              [0.391999062389091, 51.691452557165555],
              [0.391143385431636, 51.691717290457959],
              [0.389543157674021, 51.690978266248678],
              [0.388101213808052, 51.691595739097494],
              [0.385818973645714, 51.691987908318787],
              [0.381801434881973, 51.6936418811161],
              [0.381689615577977, 51.694102789870499],
              [0.380461744974987, 51.694419057656006],
              [0.380732865817863, 51.696193267444507],
              [0.380063595491312, 51.696924470924415],
              [0.380479451593168, 51.697375582052587],
              [0.377944274702091, 51.697932346652642],
              [0.3774918131213, 51.698714900806507],
              [0.376671489067175, 51.698498587644295],
              [0.373078022746788, 51.695427322827577],
              [0.371741969782331, 51.694921931137884],
              [0.368919548541179, 51.692832284289331],
              [0.362860024738573, 51.691704344834157],
              [0.360525134769747, 51.690379879695918],
              [0.35734960034587, 51.691419401103474],
              [0.353029189976719, 51.692093286641658],
              [0.34660691957515, 51.693900769343756],
              [0.344942347007117, 51.695514971114839],
              [0.344664788084657, 51.697087975393373],
              [0.343874560843734, 51.69840232379044],
              [0.340076597106221, 51.701112078600538],
              [0.338859633387643, 51.701508625754784],
              [0.339659033111515, 51.703958552182272],
              [0.33917314646957, 51.706772210548543],
              [0.336948462156753, 51.709300328720865],
              [0.335949141231331, 51.712071126414635],
              [0.335930852460393, 51.713626356622612],
              [0.333972570332174, 51.717007931689032],
              [0.333512313273479, 51.718725718818831],
              [0.335192644564739, 51.719163550826842],
              [0.33905919076683, 51.721002098166316],
              [0.340991885854794, 51.723014868170651],
              [0.342830085991042, 51.724176968538565],
              [0.343403883942083, 51.725892152301775],
              [0.344696524260562, 51.727785434510295],
              [0.34616053529922, 51.729126715839357],
              [0.346997837470151, 51.729411250959011],
              [0.347424478593478, 51.730434207357312],
              [0.347480898767286, 51.731578770228047],
              [0.345007951668334, 51.73522800004487],
              [0.345820296347514, 51.737908737476012],
              [0.345531449949416, 51.740917215982833],
              [0.346051172881526, 51.741861878708576],
              [0.343994555656165, 51.747464999004443],
              [0.344124504112067, 51.749358994699016],
              [0.342035930468561, 51.750995074246831],
              [0.341370410800483, 51.75084556824234],
              [0.341087043813135, 51.752005893320231],
              [0.342522383456406, 51.758126576515963],
              [0.344132337850939, 51.760000946549631],
              [0.344959342851831, 51.763431391699648],
              [0.344940790403064, 51.766205145272259],
              [0.346673570824595, 51.767589619783088],
              [0.347899510267625, 51.768227002373749],
              [0.348256440223964, 51.768876345562163],
              [0.347777558857113, 51.771350843386536],
              [0.348074720006743, 51.773193827320675],
              [0.346234489166374, 51.773235978226687],
              [0.344436360367232, 51.774034454121079],
              [0.340489973367368, 51.774107721942386],
              [0.335237161781556, 51.77591545394764],
              [0.333667088039707, 51.778807245745746],
              [0.332516230630016, 51.778924517166196],
              [0.332291802300431, 51.779239222027584],
              [0.332608626272951, 51.780968539497579],
              [0.333472756981519, 51.781109657950715],
              [0.33411439276497, 51.781658964784278],
              [0.334326094295924, 51.783119682934085],
              [0.335064227314814, 51.783739909499928],
              [0.335272689157186, 51.784575692519624],
              [0.334926428037427, 51.785703970790891],
              [0.339588705180619, 51.786471862131989],
              [0.340742192405287, 51.786430009796547],
              [0.340671354694527, 51.788030336382533],
              [0.338234396155443, 51.789078840314126],
              [0.337671664700742, 51.79025459786196],
              [0.337903762039618, 51.791405552078722],
              [0.339802335248914, 51.792784132938621],
              [0.340553915785145, 51.794471499807564],
              [0.342780570745132, 51.794201420058073],
              [0.342908628873395, 51.793902106023189],
              [0.346793023820491, 51.793495476607852],
              [0.350130927233699, 51.794015111937966],
              [0.354712274574669, 51.793486369018034],
              [0.35875354901858, 51.793976378753705],
              [0.358890759826111, 51.794521286327203],
              [0.362589902559262, 51.794900225227771],
              [0.362276085255728, 51.795666424393055],
              [0.359485030393571, 51.796264751721864],
              [0.359017863808794, 51.798123046878437],
              [0.361703339090986, 51.798388362969263],
              [0.360433178193803, 51.800117111914062],
              [0.361542744500938, 51.801143384211194],
              [0.364731039726185, 51.799649430318084],
              [0.366429394692447, 51.799619746292947],
              [0.366607607122613, 51.799339179971632],
              [0.368067043170491, 51.799372735634982],
              [0.36838132018067, 51.799142482713727],
              [0.369653996753697, 51.799210357128914],
              [0.37450216510186, 51.800827445330079],
              [0.378772616503262, 51.799950629799341],
              [0.379199944611631, 51.800380830357298],
              [0.382759856803509, 51.799812314484825],
              [0.381448013446452, 51.798204005330199],
              [0.378476909015157, 51.798824418023699],
              [0.378290379445845, 51.797981068774092],
              [0.378568399508142, 51.797641809276726],
              [0.380909152600538, 51.797253588991289],
              [0.384137999866661, 51.797067635176333],
              [0.384716344159497, 51.79879241809158],
              [0.386805114431106, 51.798493725201439],
              [0.38734269801639, 51.799774178278106],
              [0.387981897003193, 51.799710833922781],
              [0.388484934233258, 51.80159899991947],
              [0.38830584178752, 51.803534298897695],
              [0.389874837328311, 51.80388100693164],
              [0.389978854953118, 51.805079434844437],
              [0.391445679391851, 51.805056795735815],
              [0.391531163428632, 51.805767288776401],
              [0.394057669410461, 51.807098956161205],
              [0.397306889516566, 51.807147842993594],
              [0.398849753870431, 51.807490468965931],
              [0.398895607361344, 51.809146014145902],
              [0.400002785516971, 51.812754721663971],
              [0.403551224368402, 51.812856682268119],
              [0.404143412392166, 51.811498343966093],
              [0.40324487427475, 51.811353047736048],
              [0.402276130614974, 51.807705567864929],
              [0.404587616733828, 51.807415498820717],
              [0.404904055338718, 51.807773235552759],
              [0.405398474758559, 51.807713658428668],
              [0.407041333178641, 51.808406650923025],
              [0.406840284679644, 51.81040538179392],
              [0.404924126188563, 51.812482378406393],
              [0.407805953182508, 51.813279504177082],
              [0.408048783003202, 51.81279970772907],
              [0.410185450733031, 51.813253223126949],
              [0.410673049498984, 51.812983331383741],
              [0.413025387267043, 51.813209349571615],
              [0.413609281446028, 51.814144309935536],
              [0.414074683919063, 51.814056515436548],
              [0.414369770873898, 51.814338226486583],
              [0.414641182051992, 51.816049387728121],
              [0.412955156889959, 51.818058837474325],
              [0.410793098651814, 51.819607698647737],
              [0.411033053319982, 51.820568608922564],
              [0.410658810557869, 51.821984562590032],
              [0.408595123017835, 51.82350528396843],
              [0.407060635241065, 51.825400026900084],
              [0.406232628314207, 51.830666988399855],
              [0.407656118645035, 51.831557811822471],
              [0.410633513096926, 51.832353818142273],
              [0.411047561679189, 51.833366013743166],
              [0.410481377686383, 51.834451366355921],
              [0.40653572361583, 51.835480036660854],
              [0.407936808312886, 51.835703149540429],
              [0.409957289387299, 51.836570027432906],
              [0.403492775706543, 51.840268901050564],
              [0.4042912482916, 51.842748079862879],
              [0.40531341575422, 51.84285846929113],
              [0.406602447974845, 51.843970582438111],
              [0.407961744884493, 51.846180163719033],
              [0.411716046319515, 51.845792070130699],
              [0.411118389245268, 51.844932923464448],
              [0.411956746425077, 51.843722383124238],
              [0.412022914557234, 51.844036673586501],
              [0.412876811453166, 51.844257463745258],
              [0.412782098965167, 51.845608329389499],
              [0.413893013684225, 51.845557650878725],
              [0.413738658545442, 51.84853384350815],
              [0.414596802173625, 51.849618743779438],
              [0.415030282879048, 51.85353790081335],
              [0.412967210886601, 51.856314963706808],
              [0.413143507003435, 51.856651273946923],
              [0.414420248063021, 51.85665474359827],
              [0.415090437891795, 51.857101413113057],
              [0.416726635921246, 51.857470676396979],
              [0.418273134604972, 51.857223056609364],
              [0.418310996917398, 51.856737565665014],
              [0.419351323560243, 51.856205375441689],
              [0.420040273610867, 51.855106666074526],
              [0.423123424444218, 51.854675368946374],
              [0.424002871406504, 51.854150947969458],
              [0.424745014157792, 51.851316397342522],
              [0.424017295676279, 51.849421324975005],
              [0.424453704778711, 51.848409627509866],
              [0.424247371413358, 51.846921072055821],
              [0.424027212501056, 51.846228666565572],
              [0.423436694475231, 51.846101449903479],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000071",
        LAD13CDO: "22UG",
        LAD13NM: "Colchester",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [0.896038395291996, 51.773950893754588],
              [0.895328737778652, 51.772105784753698],
              [0.895109766498652, 51.773168843644385],
              [0.895554799688656, 51.774956620873347],
              [0.896038395291996, 51.773950893754588],
            ],
          ],
          [
            [
              [0.884222265409983, 51.779076598013127],
              [0.885529129445845, 51.778175715948976],
              [0.88622348388358, 51.77830975409185],
              [0.888006209263209, 51.777664265430431],
              [0.890665473876958, 51.776034857656811],
              [0.892022658700534, 51.773226893441439],
              [0.892195339278239, 51.77180163423921],
              [0.891601040333433, 51.771916094198488],
              [0.885191434553914, 51.777473507033768],
              [0.884222265409983, 51.779076598013127],
            ],
          ],
          [
            [
              [0.974563763566945, 51.804664408731867],
              [0.974749527522176, 51.804379098191426],
              [0.978491655876642, 51.804630565274117],
              [0.981594207425577, 51.804225403709488],
              [0.984184293768691, 51.80346442732651],
              [0.984415280087634, 51.803688803636703],
              [0.990847554966912, 51.80381575758399],
              [0.99330589525185, 51.803521116227365],
              [0.997118279992358, 51.802688235379797],
              [0.999223161856114, 51.801922210467197],
              [1.003048121701744, 51.799689362434052],
              [1.003060048198009, 51.79912693871983],
              [1.004166347112473, 51.798421416861693],
              [1.004279148997469, 51.797936459293517],
              [1.004794614490141, 51.799165336528212],
              [1.005374264826809, 51.799461700583628],
              [1.005905008960296, 51.799262849306565],
              [1.0051176340101, 51.798308050523389],
              [1.004751735224981, 51.796732681816486],
              [1.000479780982528, 51.793410679858219],
              [0.995089292052531, 51.790423738816479],
              [0.991294813110269, 51.788773795167323],
              [0.989376058111046, 51.787501429992446],
              [0.98411625977618, 51.786625132542461],
              [0.981618747436779, 51.785785557363155],
              [0.981916767754005, 51.78540115014777],
              [0.981131019844837, 51.785118896670603],
              [0.973793440542739, 51.783269545126537],
              [0.971243835549557, 51.782337534848729],
              [0.962317942737921, 51.780598684562619],
              [0.95563757758586, 51.778487904171321],
              [0.950495267888504, 51.776474836239089],
              [0.945761473828282, 51.774716621504197],
              [0.944172299545803, 51.774468657701043],
              [0.942882820684601, 51.774882305106466],
              [0.942112158607003, 51.774825160848224],
              [0.935720204975449, 51.773622507062306],
              [0.934317830380645, 51.77377176522478],
              [0.926804882622834, 51.77320730213372],
              [0.920006418409179, 51.773130052655176],
              [0.907840650870811, 51.774105446169763],
              [0.904123465998077, 51.77390240063194],
              [0.902217623608286, 51.77436321042665],
              [0.902344413970657, 51.775209087040118],
              [0.901627794864493, 51.774999247387775],
              [0.902349948611023, 51.775498548669169],
              [0.901437480989661, 51.775761220375436],
              [0.90092902371952, 51.775315995606483],
              [0.90130532590162, 51.775848125896374],
              [0.900077178495968, 51.776433363121555],
              [0.900197315657018, 51.776633655780621],
              [0.899703724132379, 51.776578386752476],
              [0.900059861516716, 51.776796238006717],
              [0.898857658948944, 51.777899759704844],
              [0.898603201904773, 51.779584267556615],
              [0.898069784547771, 51.779722439693174],
              [0.898608252808904, 51.779708256359264],
              [0.898557422996215, 51.780317486278427],
              [0.899715326079262, 51.780805153123794],
              [0.900440401345975, 51.782365654133571],
              [0.899880299777338, 51.781862340950426],
              [0.89978221632276, 51.78234502680148],
              [0.900870695243587, 51.783579976108491],
              [0.900879979757812, 51.784650899534569],
              [0.901616514983584, 51.784603931849894],
              [0.901113917319214, 51.784929324962725],
              [0.901460079730273, 51.785645671074676],
              [0.903174839180341, 51.787417346760662],
              [0.907180237450297, 51.790009231398308],
              [0.910608694174348, 51.791675430875877],
              [0.913017937295401, 51.792309141608825],
              [0.913326648891095, 51.792688218269227],
              [0.916221360542378, 51.792999541751762],
              [0.917224379417321, 51.793618595117458],
              [0.919507691784828, 51.794051142747605],
              [0.919971052722076, 51.796148662526342],
              [0.919289579519577, 51.797431915072153],
              [0.920181350259186, 51.797225384019235],
              [0.919884700531313, 51.797662657151257],
              [0.920318869132618, 51.798073674016742],
              [0.921448057714023, 51.798712041045377],
              [0.922325417650485, 51.79882063338043],
              [0.923101498847581, 51.799695308077624],
              [0.923698229477054, 51.799636392424709],
              [0.923636227259872, 51.80038171824107],
              [0.926448368732026, 51.801559148643044],
              [0.927879409839754, 51.801122378368035],
              [0.931147327115649, 51.801004930256795],
              [0.93272812609062, 51.802138273321923],
              [0.93364172046533, 51.803562564781515],
              [0.936341251800071, 51.803982599888819],
              [0.942745843115163, 51.806737266030929],
              [0.944074258706156, 51.806973804679949],
              [0.944665842330477, 51.804965959353638],
              [0.944070270911131, 51.804909831706624],
              [0.945699560048074, 51.804399523749041],
              [0.942206070305413, 51.803485141046394],
              [0.941674568115645, 51.80309822485394],
              [0.941973132824436, 51.802114025408144],
              [0.942655656353758, 51.802770569283886],
              [0.942229743917339, 51.802801920281532],
              [0.942417138409063, 51.803317967880069],
              [0.945974273021165, 51.804207364775401],
              [0.945992409441172, 51.804618825523804],
              [0.944817412374584, 51.805399258877252],
              [0.945178096808154, 51.806769865702258],
              [0.948081643073018, 51.807354502535404],
              [0.953979312084255, 51.807709145874092],
              [0.957204060579691, 51.807856479254085],
              [0.964474650921106, 51.808789416166377],
              [0.967627492821497, 51.808657670912417],
              [0.970516663829176, 51.807958700136652],
              [0.976511917511445, 51.805541441941351],
              [0.974563763566945, 51.804664408731867],
            ],
          ],
          [
            [
              [0.974630743594709, 51.810174137302511],
              [0.977432395725883, 51.807950057303159],
              [0.975062928525562, 51.808629747013178],
              [0.96911864667849, 51.811189538847607],
              [0.97083818045122, 51.811169463261614],
              [0.972456609630283, 51.811024207580502],
              [0.974630743594709, 51.810174137302511],
            ],
          ],
          [
            [
              [0.982271600988592, 51.815756316834019],
              [0.982171898846308, 51.815433269874795],
              [0.98261374193051, 51.815531781270202],
              [0.983446567621006, 51.814915247884251],
              [0.98453945020237, 51.813272201193406],
              [0.983255720438278, 51.813213060709039],
              [0.981745003199695, 51.813605760724755],
              [0.978357575124114, 51.814937310280506],
              [0.975523661196177, 51.816897804962586],
              [0.975456317334232, 51.817527279352547],
              [0.976825505909328, 51.817406263615922],
              [0.980204235375942, 51.816620907547652],
              [0.982271600988592, 51.815756316834019],
            ],
          ],
          [
            [
              [0.963297497781014, 51.976144579142485],
              [0.964584204394561, 51.975671483734629],
              [0.965330269043394, 51.974708355202679],
              [0.965308295176143, 51.97422145406437],
              [0.964166208800183, 51.973730395584759],
              [0.964145412553536, 51.973106761442608],
              [0.965165060061109, 51.972780685884487],
              [0.964859619129639, 51.971948376519869],
              [0.967848956858985, 51.970514082361795],
              [0.971274005363846, 51.969791798349874],
              [0.971998639122691, 51.968486507429212],
              [0.971468287934837, 51.966985370266578],
              [0.968193474848429, 51.964096520913323],
              [0.968654411380742, 51.963404972749501],
              [0.971454239962731, 51.962263156760081],
              [0.973860545080951, 51.962369646569805],
              [0.975816230037532, 51.961256251567299],
              [0.980261010860612, 51.961455835580111],
              [0.982447209921011, 51.962106445564245],
              [0.989052695686087, 51.961366830594471],
              [0.992464711176961, 51.961753172285562],
              [0.995205706115857, 51.963340969545158],
              [0.99758314416721, 51.963907287996285],
              [0.998679718455926, 51.96395489411637],
              [1.000112215831213, 51.963430019649955],
              [1.001233660943738, 51.963392426789873],
              [1.004364191672908, 51.962222496019329],
              [1.004889595437045, 51.960936467308052],
              [1.005426454780274, 51.96062686189542],
              [1.004913433492512, 51.959486969255849],
              [1.006529020216582, 51.958199166525254],
              [1.00667553340036, 51.957626119329731],
              [1.007562826301342, 51.957528793693939],
              [1.008972029883321, 51.955987211390372],
              [1.011033041159922, 51.955506382032084],
              [1.011531339394611, 51.955057431890097],
              [1.016161091739704, 51.95537323396848],
              [1.017067470135406, 51.954737520009139],
              [1.017175456707532, 51.953918117663392],
              [1.018666244551771, 51.953207144074192],
              [1.019410612181262, 51.953323848872387],
              [1.019930070846357, 51.95399224365233],
              [1.021088225529406, 51.954439179793702],
              [1.022827568461728, 51.954588769605238],
              [1.023681829088305, 51.954975135377381],
              [1.024681945660717, 51.954890069354605],
              [1.025190190318107, 51.953942549093163],
              [1.026802747306347, 51.954003603464756],
              [1.025120061127006, 51.950631944945677],
              [1.023116639070666, 51.948870297225511],
              [1.023517570756402, 51.948015478445534],
              [1.022790560578277, 51.946446758915513],
              [1.018460338658508, 51.943016193929544],
              [1.01559992357903, 51.941767468096906],
              [1.012528391758181, 51.939646289176878],
              [1.00585335399711, 51.936704298133016],
              [1.005703366665429, 51.935592904978606],
              [1.004170485346365, 51.935820930560155],
              [1.001651409266331, 51.935530882037099],
              [0.999439727766901, 51.936619777388742],
              [0.997074301493411, 51.936910305715706],
              [0.994948741821044, 51.937612888083756],
              [0.993837732730071, 51.937579111329278],
              [0.985497253342149, 51.939724724757838],
              [0.97564538401572, 51.940242344758367],
              [0.974480811214058, 51.939861683561908],
              [0.973640504285997, 51.940159033261907],
              [0.972088441368205, 51.940167675985492],
              [0.969238314178475, 51.939712616834854],
              [0.96445042246364, 51.938336817014026],
              [0.95528628302363, 51.936742575295895],
              [0.95100854899623, 51.934593453990502],
              [0.949196605052725, 51.935006733596524],
              [0.94714052613545, 51.932695631575697],
              [0.941954822919669, 51.929731623703503],
              [0.939058575052367, 51.926575307214137],
              [0.939180976837637, 51.924575661610803],
              [0.938195760958898, 51.922699020222971],
              [0.937348916420959, 51.922004277002358],
              [0.935252927259559, 51.922494589023181],
              [0.934338390600674, 51.921051444848068],
              [0.931171901079956, 51.919712099907159],
              [0.932210854531861, 51.918202255778816],
              [0.932765223923623, 51.917915927546986],
              [0.93236558634541, 51.917630900197324],
              [0.934115517225016, 51.916520615392884],
              [0.931095617068424, 51.9141226607018],
              [0.931838166874992, 51.913823552324025],
              [0.931480231981316, 51.913218208642633],
              [0.937870452779733, 51.913043476674659],
              [0.941290635299906, 51.911171856461493],
              [0.946613488596898, 51.90989193002914],
              [0.947972987514703, 51.91000086137975],
              [0.944108651496923, 51.906213293162779],
              [0.949062052430939, 51.90478600652677],
              [0.954851803624711, 51.904674859211106],
              [0.957112871798698, 51.903636816439572],
              [0.956300958510458, 51.903007883831151],
              [0.956687225761597, 51.902535897431989],
              [0.954143175790215, 51.90147741672471],
              [0.951701924270226, 51.899257914613933],
              [0.951056814547673, 51.89839901852374],
              [0.949985958426173, 51.898274791535229],
              [0.948779150494255, 51.896741055637385],
              [0.950820278629704, 51.894391968528268],
              [0.952341086718238, 51.893421151084461],
              [0.953068161807007, 51.891696789991911],
              [0.956743124760796, 51.892299658924884],
              [0.959507280226897, 51.89222472935365],
              [0.962589586892222, 51.89139614036683],
              [0.962045985027926, 51.889585008536763],
              [0.959297179376991, 51.886308553931926],
              [0.959607838043741, 51.884511884103304],
              [0.957946687539722, 51.881503012873921],
              [0.95809060394202, 51.8811054673264],
              [0.952823555905657, 51.880298911159471],
              [0.957893634886663, 51.877376227782356],
              [0.960109018083113, 51.877411311950894],
              [0.970173683096454, 51.874575390670515],
              [0.975660206163958, 51.873562468406263],
              [0.977159590933084, 51.872989381837087],
              [0.978291197456586, 51.872150383239152],
              [0.979368436559456, 51.868842160445062],
              [0.979211024054776, 51.867911695816098],
              [0.977416819017951, 51.865955133203506],
              [0.977002470783511, 51.864872882068255],
              [0.976727108702123, 51.863849167531605],
              [0.973948670858095, 51.860445189477048],
              [0.975653826011151, 51.859518852212275],
              [0.976657244745717, 51.856391492935472],
              [0.978074376046491, 51.854032504760866],
              [0.973144800761743, 51.853367631394534],
              [0.971278876566943, 51.8526233565212],
              [0.966939364267392, 51.852093504919893],
              [0.966171262204687, 51.851764829737888],
              [0.962486275096341, 51.853480229224068],
              [0.961412901589499, 51.853545048166687],
              [0.961521119378372, 51.854064859416091],
              [0.961203869824597, 51.853701409043943],
              [0.957437751405749, 51.854628139183049],
              [0.954833027594722, 51.854773683460451],
              [0.953347191328592, 51.854551102035302],
              [0.950527983990391, 51.855391758679765],
              [0.949633026592102, 51.85678576212193],
              [0.952037810247928, 51.859794962326788],
              [0.95156257136971, 51.862563474126176],
              [0.951030062282344, 51.863460026344754],
              [0.94910324982842, 51.864294427381296],
              [0.948709863363992, 51.864787257632038],
              [0.947088505794998, 51.866784045861081],
              [0.942372735691298, 51.869451876767293],
              [0.941231682581893, 51.870711665318353],
              [0.9392760922129, 51.871780455659284],
              [0.936608636210886, 51.875950031079846],
              [0.935533424660746, 51.876798003653164],
              [0.927816308906762, 51.880533922254124],
              [0.926970266235455, 51.881625231355457],
              [0.926718239009757, 51.884969951394574],
              [0.926048754976716, 51.885850003575086],
              [0.923675930595239, 51.887133958963666],
              [0.919990318345642, 51.886890972841158],
              [0.918804685610789, 51.887037867700826],
              [0.918764771594659, 51.887042812923852],
              [0.918681497499819, 51.887087681490364],
              [0.917462914684474, 51.887744245979917],
              [0.916117563812982, 51.888627514790763],
              [0.915322512255922, 51.890198629482427],
              [0.914743972936816, 51.890377554137856],
              [0.914826544420489, 51.891271272949801],
              [0.914360951759751, 51.890770175728079],
              [0.914776838245168, 51.889874894976117],
              [0.915939001366206, 51.888496138821473],
              [0.917194801530272, 51.887688841441665],
              [0.918755862582992, 51.886939836520511],
              [0.919289499961244, 51.886841820261004],
              [0.92395157835512, 51.886745774407906],
              [0.925825457560351, 51.885813289595305],
              [0.926575249498428, 51.8845364184182],
              [0.926651056113683, 51.880760791483652],
              [0.929306308839792, 51.879312346014771],
              [0.93601807604268, 51.875940496224423],
              [0.938925743924568, 51.871487924713172],
              [0.940518571643125, 51.87058200570997],
              [0.942446963369756, 51.868808767821868],
              [0.944759470935255, 51.867691410879715],
              [0.946683938461198, 51.866240173378998],
              [0.947518557027618, 51.86414170236943],
              [0.950525043044983, 51.862491485973869],
              [0.950822838505582, 51.859918100106015],
              [0.948704309574515, 51.857819956116707],
              [0.94906116614356, 51.856148063415013],
              [0.949996824225213, 51.854865464640298],
              [0.950615972878783, 51.85471681861199],
              [0.950344163334094, 51.854491602141678],
              [0.95108578886202, 51.854527848830642],
              [0.950821168699582, 51.854368107266936],
              [0.953389778000826, 51.853494166477525],
              [0.954295345730217, 51.853461518876244],
              [0.955329044965208, 51.852658476270499],
              [0.955048718177749, 51.85239211340118],
              [0.953017755623858, 51.852138261797826],
              [0.951029513969685, 51.851027997844689],
              [0.950723737873279, 51.850394419694219],
              [0.951964952138464, 51.849411718497024],
              [0.95078737413756, 51.848992496250723],
              [0.948097624130052, 51.84940984064945],
              [0.946327763503623, 51.849104357671138],
              [0.946437025727806, 51.848420793117825],
              [0.9469461636023, 51.84814541314433],
              [0.946120650758085, 51.84815711184968],
              [0.947276508824941, 51.84802291053073],
              [0.947243155363272, 51.847490417986897],
              [0.945813221254509, 51.847207882768352],
              [0.945122048108649, 51.84676382298283],
              [0.942496571251339, 51.846406868015073],
              [0.940953290062064, 51.846700911407375],
              [0.939739944968825, 51.846309455907814],
              [0.939182463795731, 51.846467296064567],
              [0.938863369004813, 51.847297300719923],
              [0.937640480338207, 51.847873786688339],
              [0.936059810582334, 51.847371855778306],
              [0.933033196686163, 51.845669355327786],
              [0.930508822181241, 51.845011019826543],
              [0.928952340062021, 51.845146945065466],
              [0.927656650473375, 51.845699058748615],
              [0.926482509246979, 51.845618572636617],
              [0.924994690271637, 51.845067415664325],
              [0.923618349955894, 51.84563048485942],
              [0.92247121270529, 51.845505658669381],
              [0.921543210915259, 51.845616323441554],
              [0.920873470015278, 51.845331779116655],
              [0.917216971006478, 51.845485534892717],
              [0.915491768262653, 51.846112026320377],
              [0.912840495198828, 51.845328750241855],
              [0.915551779038696, 51.845984626369479],
              [0.917030001498737, 51.845378653046687],
              [0.920978701000554, 51.845230233658619],
              [0.921351914653907, 51.845383850159948],
              [0.921594829832183, 51.845483833306993],
              [0.921917358367151, 51.845445386510221],
              [0.922957949168465, 51.845321336286169],
              [0.923529306202948, 51.845511284553417],
              [0.924954351739307, 51.844938010749537],
              [0.92656481671574, 51.845499605353019],
              [0.927745103628921, 51.845585333770288],
              [0.928309008593215, 51.845302375632606],
              [0.92991607443426, 51.844894487518999],
              [0.931705710384389, 51.845151156159275],
              [0.933268030367057, 51.845618527026801],
              [0.93582643157425, 51.847110571216689],
              [0.937767074369785, 51.847766295804519],
              [0.938819535870369, 51.847114924598579],
              [0.939051633038852, 51.846264610939819],
              [0.939791467561826, 51.846164267742118],
              [0.941200186781076, 51.846567922465475],
              [0.942578514158373, 51.846238432721179],
              [0.945484559380581, 51.846622531956449],
              [0.947319723884183, 51.847217786609733],
              [0.947986411755293, 51.84797542997326],
              [0.946752251433535, 51.848844590908556],
              [0.947228842355149, 51.849070976893344],
              [0.948943397544315, 51.849041467821067],
              [0.950141673098495, 51.848645355736672],
              [0.950387385204918, 51.848161621001651],
              [0.950471482633123, 51.848581316882601],
              [0.951516369635737, 51.848548790317516],
              [0.950763192612239, 51.848046061794967],
              [0.951726685190663, 51.848256608846334],
              [0.952967045392483, 51.849302008216966],
              [0.952661763853256, 51.850073245961298],
              [0.952229279797814, 51.849988774570761],
              [0.951296819560796, 51.850496951827481],
              [0.951466081026566, 51.850997251273249],
              [0.953506424717882, 51.851993777047454],
              [0.955918399302901, 51.852100435731487],
              [0.955818388684611, 51.85183763480503],
              [0.956379333948905, 51.852131115124102],
              [0.956371702232702, 51.852391226506406],
              [0.956020237289806, 51.852213894342285],
              [0.956327778650646, 51.852784458731257],
              [0.956017611009678, 51.853325588052058],
              [0.954746784764587, 51.853923243954256],
              [0.955438844581289, 51.854111803893403],
              [0.958236050861444, 51.853535997591806],
              [0.958415119230968, 51.853166344779986],
              [0.958581432730893, 51.853421179323171],
              [0.959063837101602, 51.8533361878901],
              [0.960402913492602, 51.852850087128573],
              [0.960093994510011, 51.85261323608708],
              [0.960909474596517, 51.852801354082501],
              [0.96480131146625, 51.850565444112988],
              [0.965149168111609, 51.850665494820035],
              [0.967033280705755, 51.849349831915895],
              [0.974066563329973, 51.847328369298204],
              [0.974801816277858, 51.846609147784662],
              [0.975486466967846, 51.846707838997162],
              [0.975204865979684, 51.846291359130845],
              [0.975986108306544, 51.846379508114474],
              [0.976277303587006, 51.845951225651419],
              [0.975984215501463, 51.845778770368433],
              [0.976534140601602, 51.845750456163785],
              [0.976932759263703, 51.844903938099513],
              [0.976724048186508, 51.844447841763028],
              [0.978391113017462, 51.838139627000579],
              [0.977513188476596, 51.83559503663129],
              [0.976522292861751, 51.834218898994557],
              [0.975025760150526, 51.833341219041728],
              [0.974051323935529, 51.83139803471736],
              [0.97448769293671, 51.830993062934674],
              [0.974392888608677, 51.828806368239043],
              [0.975653672313756, 51.826177938580166],
              [0.981455859656547, 51.819268424486495],
              [0.982292631812008, 51.817348592682926],
              [0.97565351035237, 51.818647414311371],
              [0.966342650226744, 51.822270770136569],
              [0.965118010967083, 51.823202853435156],
              [0.964650544407481, 51.824150901342868],
              [0.964754152342726, 51.82681045221473],
              [0.964026555520528, 51.828991812050646],
              [0.962809652531221, 51.829470386115389],
              [0.960406173062004, 51.831665175807444],
              [0.957837460118825, 51.832051825624987],
              [0.957417429698677, 51.832376278186153],
              [0.956984600846753, 51.83230622439055],
              [0.957431108822271, 51.831787740938005],
              [0.95682967405521, 51.831625695651049],
              [0.955481381610762, 51.832050836736194],
              [0.954272544215065, 51.832034460215823],
              [0.953521387908557, 51.831691789021356],
              [0.953219112947036, 51.832906348409551],
              [0.953063338689108, 51.832189861494776],
              [0.952504123870034, 51.832694970300075],
              [0.952903816672551, 51.832990717287387],
              [0.952562077961202, 51.832937245550816],
              [0.952524693649386, 51.83327545093038],
              [0.952034833301678, 51.831823570211768],
              [0.953696205949321, 51.831346531918193],
              [0.955347850057863, 51.831718726673266],
              [0.957024012938003, 51.831113557356794],
              [0.959365980746942, 51.831364897254517],
              [0.962910044167465, 51.828258190328043],
              [0.963337744768286, 51.827344430304038],
              [0.96323144619377, 51.824067972495769],
              [0.964136588790269, 51.821982868517956],
              [0.955472833353438, 51.823587906052126],
              [0.953092967037988, 51.824503038304769],
              [0.951977030134563, 51.824439336836242],
              [0.951510338261842, 51.824049935761117],
              [0.949909376189262, 51.824248421447543],
              [0.949209457354357, 51.824642826372667],
              [0.948867865896133, 51.824324024570608],
              [0.949659476700924, 51.824000169946508],
              [0.948799606060508, 51.823834677427634],
              [0.948646970353239, 51.824319673751965],
              [0.947718421425268, 51.823636056568965],
              [0.943510044158586, 51.822154134828637],
              [0.944408931932298, 51.821316782788365],
              [0.946744615870582, 51.821992133203274],
              [0.947772000162135, 51.822834267273237],
              [0.948391343571845, 51.822517439826015],
              [0.948279830184591, 51.822965429012449],
              [0.950152186501952, 51.823411302014435],
              [0.951184077709437, 51.823366507647734],
              [0.95253857900863, 51.823061774001758],
              [0.951595576719738, 51.822934365592907],
              [0.951548372754525, 51.822700813526914],
              [0.953352230132134, 51.822875844454629],
              [0.953520651727528, 51.822411130369424],
              [0.954229646253599, 51.822644236806255],
              [0.966588577316164, 51.819432412458617],
              [0.9713947259073, 51.817354834910333],
              [0.976708305336666, 51.813626426107582],
              [0.984027277593534, 51.811055632394421],
              [0.991224908321377, 51.807213030894744],
              [0.984168065012664, 51.807368174284768],
              [0.980055195350193, 51.808164178804333],
              [0.978388912320571, 51.808747854314468],
              [0.974800240863017, 51.811425385893507],
              [0.971673698525922, 51.812400278083942],
              [0.969559011841021, 51.812722650950185],
              [0.961551730898125, 51.812338326084365],
              [0.96113895689267, 51.812040240473586],
              [0.95966166340894, 51.812015399450289],
              [0.959226519074881, 51.811641423331096],
              [0.958190914538826, 51.811868958774348],
              [0.958392362026596, 51.811488845926768],
              [0.953631412227409, 51.811284830406755],
              [0.952187937987788, 51.810866916247498],
              [0.944321533087024, 51.810209866554551],
              [0.93766783188732, 51.807984047731559],
              [0.932807674559815, 51.807965144573991],
              [0.933104064142988, 51.80815021339604],
              [0.931529009638242, 51.80820390664271],
              [0.931250897298676, 51.807920346057294],
              [0.928705443623011, 51.807955033594702],
              [0.927447070528549, 51.807544796034364],
              [0.925304229900056, 51.809270096170479],
              [0.92363103830423, 51.809646303002914],
              [0.924056873289433, 51.809433348650003],
              [0.92336718514584, 51.809201381495335],
              [0.922641784833034, 51.810009075169916],
              [0.921233252625221, 51.810507275160845],
              [0.921361587055726, 51.810091272503904],
              [0.920087689316086, 51.809687637770942],
              [0.919946097340424, 51.810684064016883],
              [0.919213418745642, 51.81095409142484],
              [0.917046561891479, 51.810203859848002],
              [0.915998568174277, 51.811050996389881],
              [0.917582072837337, 51.810767063515563],
              [0.918280466996159, 51.811178722903009],
              [0.917221853471615, 51.811075497319997],
              [0.916146753060687, 51.811390879468917],
              [0.915678636600694, 51.810702783283737],
              [0.91681767024293, 51.81002966688672],
              [0.917679291042376, 51.809958811894113],
              [0.919249891316115, 51.810687870386829],
              [0.919263404108284, 51.809842124112208],
              [0.919931960218434, 51.809433387276641],
              [0.92184180384797, 51.809642237918716],
              [0.9226334572637, 51.808916543236705],
              [0.925228310015012, 51.808299762478754],
              [0.9252737638103, 51.807724830138959],
              [0.92472264181899, 51.807772724490746],
              [0.923526802349776, 51.807252064596987],
              [0.91986332226195, 51.807900761958273],
              [0.92004370252973, 51.807549121691508],
              [0.922564691504303, 51.806890165414927],
              [0.923229630778752, 51.806157724196567],
              [0.92504977350032, 51.806596294713735],
              [0.927287161317886, 51.805816583563747],
              [0.929955081553508, 51.806396743077947],
              [0.930499496067683, 51.80613404045247],
              [0.930577404627451, 51.806395614543618],
              [0.93103622452639, 51.806244767853258],
              [0.930945864190141, 51.806461972457207],
              [0.933811777877758, 51.806492983961618],
              [0.936834374830412, 51.806211523506178],
              [0.9371227812483, 51.805849960678643],
              [0.936404576458242, 51.805380447845351],
              [0.934900542077849, 51.804940453620148],
              [0.933726326833028, 51.80493200147933],
              [0.931972516726476, 51.804219399248886],
              [0.930611490029813, 51.801890690398267],
              [0.927494548961229, 51.802816497670449],
              [0.925375619094762, 51.802690293080687],
              [0.92119441697358, 51.800397479324914],
              [0.92108388186802, 51.800011697451758],
              [0.920782517183615, 51.800353756134221],
              [0.919551354501047, 51.799372556571448],
              [0.918013223900077, 51.799095083547698],
              [0.918589041396576, 51.798443126255322],
              [0.917966347823544, 51.798279616000023],
              [0.917743754693971, 51.798628704399817],
              [0.917735212695994, 51.798294349368597],
              [0.916977036609504, 51.798256512904018],
              [0.917014247890313, 51.797664700443256],
              [0.917615694582118, 51.797387150534512],
              [0.916812432510393, 51.796763141412441],
              [0.916982487356037, 51.796454032057376],
              [0.915999053961146, 51.796094409315067],
              [0.915434312380755, 51.795322370359322],
              [0.913003332226547, 51.79451206994645],
              [0.911228409973818, 51.794653192369459],
              [0.910060092942121, 51.793853810936938],
              [0.909827506602438, 51.794093408136014],
              [0.909375302490486, 51.793512818590671],
              [0.903320299213417, 51.790539071565192],
              [0.900850252402835, 51.788886777235717],
              [0.899571418834718, 51.788778041885735],
              [0.899744428036022, 51.788038985379082],
              [0.899058173059216, 51.7883958827409],
              [0.899559094221816, 51.787523722367666],
              [0.898928067010922, 51.78763372761216],
              [0.898841158826327, 51.786572814429043],
              [0.897368080025655, 51.784835589121244],
              [0.896894115035031, 51.783255388348643],
              [0.896133249264058, 51.782379271504489],
              [0.896386547205484, 51.783933437354264],
              [0.897046570065408, 51.78405027139717],
              [0.896491496719726, 51.784326573599465],
              [0.899667333790564, 51.789820744718661],
              [0.900372027603988, 51.789818640708837],
              [0.899780692594549, 51.789960047790686],
              [0.900932528127921, 51.791862565926699],
              [0.903684525232952, 51.79480748445377],
              [0.909169883138369, 51.797823202140947],
              [0.909462532075579, 51.798988737967356],
              [0.910906308514618, 51.799153540296217],
              [0.911475040083897, 51.799605324840236],
              [0.909843684742959, 51.800492044131765],
              [0.909492595489865, 51.801279594174375],
              [0.908279224193387, 51.801923003148346],
              [0.90671751964868, 51.802181091545165],
              [0.905726519869494, 51.801749620035906],
              [0.905087963251926, 51.801813075358176],
              [0.905046431704592, 51.802069523561755],
              [0.906201957048827, 51.802555390988189],
              [0.905641196449696, 51.803557667435612],
              [0.905994929849297, 51.803689222001722],
              [0.904176808812384, 51.804089421747626],
              [0.904862806523229, 51.802800754966448],
              [0.905645241020284, 51.802989164819586],
              [0.905778194997854, 51.802710669539721],
              [0.904763085003348, 51.802310364727646],
              [0.904813568979957, 51.80162919212674],
              [0.905749978182601, 51.801460341724244],
              [0.907360993912199, 51.801788335678054],
              [0.908985754091604, 51.80115813626184],
              [0.908304124915045, 51.800191995195384],
              [0.908170459814246, 51.798566540475178],
              [0.905463175735418, 51.797407670092277],
              [0.904922835115839, 51.796786972697888],
              [0.903531409984017, 51.796351891900741],
              [0.904319352841101, 51.797033928596115],
              [0.903803080520175, 51.796877606429511],
              [0.903083305363526, 51.796285618058491],
              [0.903289767353099, 51.795999912913054],
              [0.902650739587799, 51.796167694485746],
              [0.902816810634447, 51.795727396693842],
              [0.900193375651403, 51.793407046153675],
              [0.897219078905294, 51.789025837854744],
              [0.896767384908617, 51.789015390125485],
              [0.895633177362345, 51.790009272375038],
              [0.895483186056518, 51.790791823866769],
              [0.894724225755595, 51.7910110842789],
              [0.893804782604506, 51.7904230601969],
              [0.895179989454, 51.78886654737498],
              [0.895083509168784, 51.78790389968664],
              [0.891469652865367, 51.779495635558483],
              [0.892686806501224, 51.779528629418252],
              [0.891996263519856, 51.779114830853715],
              [0.892684256556413, 51.778945000066606],
              [0.89357076380185, 51.77591926704212],
              [0.893398587573011, 51.774429650483548],
              [0.892071198129794, 51.776592047663158],
              [0.888373020833379, 51.77875337963934],
              [0.888241373278497, 51.77939337062282],
              [0.886090444442213, 51.780177417061886],
              [0.879441219729992, 51.783663736701833],
              [0.878687102475633, 51.784390918433502],
              [0.878498851629454, 51.785738287472093],
              [0.877646676233816, 51.784721291471755],
              [0.876568511975848, 51.784253940356287],
              [0.876106308786932, 51.78487502712246],
              [0.876804234496289, 51.785815767158333],
              [0.875538257804552, 51.784926704652712],
              [0.874926787492981, 51.785025309611022],
              [0.874648783910464, 51.784005933370942],
              [0.873931811837701, 51.783973108756442],
              [0.873159924303795, 51.784650324172219],
              [0.87119944416285, 51.784262956094615],
              [0.872088224592818, 51.785789943200015],
              [0.871208193887562, 51.786363663272716],
              [0.869340882331123, 51.786393990994483],
              [0.868742771803139, 51.787617344267623],
              [0.867598619983495, 51.787800864479287],
              [0.867803063788224, 51.78841733647149],
              [0.867285143324187, 51.788551390962802],
              [0.866540659262776, 51.788291652462469],
              [0.866256221445841, 51.788677224452186],
              [0.867281086807596, 51.788830293337675],
              [0.866712734195752, 51.789929695808006],
              [0.867295577101297, 51.790315691783171],
              [0.867201165354573, 51.790816244171808],
              [0.868965617220035, 51.791538534863939],
              [0.86791061218516, 51.792465454363843],
              [0.867632318266066, 51.792331046207259],
              [0.868017529243911, 51.791796414878853],
              [0.868680382387472, 51.791568882597019],
              [0.866898386228093, 51.790844317627631],
              [0.866401311345562, 51.789867143016913],
              [0.86695809212061, 51.788928111557119],
              [0.866062662506162, 51.78921526979989],
              [0.86584813630166, 51.788782511505318],
              [0.866398950503975, 51.788115236513747],
              [0.867520622643446, 51.788285727536305],
              [0.867163129322312, 51.787816888494866],
              [0.867428318698193, 51.787493840145373],
              [0.868616323385351, 51.787293062275893],
              [0.868789916059742, 51.786304017860346],
              [0.869417741279023, 51.785982902249913],
              [0.870906950164037, 51.786095818220417],
              [0.871481927279916, 51.78578767447857],
              [0.870504809509048, 51.784422029161334],
              [0.871180078183131, 51.783866808500498],
              [0.872746919740765, 51.784061444535119],
              [0.872184486089615, 51.783197412698939],
              [0.86478629910191, 51.78445457741563],
              [0.863019558492598, 51.785224329470225],
              [0.862936034997322, 51.784729915018332],
              [0.859854902092398, 51.785880592714975],
              [0.859578072273926, 51.786593331780296],
              [0.858485183441351, 51.786991362856632],
              [0.85854926616716, 51.787202053778884],
              [0.85616802033312, 51.787407458766424],
              [0.857930454971731, 51.787093894351599],
              [0.858256888220328, 51.786706422061577],
              [0.858822546884981, 51.786799684463723],
              [0.859566310197378, 51.785742819114077],
              [0.857281389244575, 51.78595669546177],
              [0.854648603462155, 51.785347067885581],
              [0.851226058716368, 51.785158565068983],
              [0.851756380672564, 51.786025271775969],
              [0.851520025447755, 51.7862783330742],
              [0.848386983201157, 51.783965620762444],
              [0.845173981902704, 51.782980423615612],
              [0.843937555286353, 51.783148860054119],
              [0.842101722448512, 51.782570924258366],
              [0.842145832017785, 51.782834269625432],
              [0.84142133971785, 51.782496545072554],
              [0.841870177200095, 51.782439822069378],
              [0.841575474403468, 51.782295857150849],
              [0.840983453737454, 51.782796728536788],
              [0.839021350235017, 51.78330103170223],
              [0.837439127012137, 51.784198142497964],
              [0.838758905833082, 51.784293972058002],
              [0.840242790440116, 51.785247407978225],
              [0.84028536644876, 51.786824757491324],
              [0.839890986211577, 51.787264184867148],
              [0.838409315995337, 51.787639945748289],
              [0.837453155680738, 51.788324969293477],
              [0.83399194283232, 51.787799636430435],
              [0.832176282713211, 51.78803048208114],
              [0.831510331015983, 51.788858653694497],
              [0.833291071311902, 51.7898050229104],
              [0.831138439697325, 51.788825345139621],
              [0.829780976499481, 51.788963270395158],
              [0.829835228364264, 51.788046416447571],
              [0.828779895905533, 51.787659022736399],
              [0.828184754166656, 51.787742599985151],
              [0.828512065655191, 51.787457718613474],
              [0.830224642188468, 51.787965987759655],
              [0.830203210236633, 51.788804706571533],
              [0.831449447567082, 51.78858131951705],
              [0.831780479289352, 51.787870042967278],
              [0.834317971182296, 51.787564234276907],
              [0.836897871431672, 51.788003815520291],
              [0.839623091626771, 51.78710877461662],
              [0.839952899178255, 51.786703285918712],
              [0.839738355602523, 51.785181356381734],
              [0.839027775779684, 51.784926921293902],
              [0.837674721670164, 51.785179051492399],
              [0.836768779366028, 51.784607349884773],
              [0.835249842708206, 51.785197119704932],
              [0.834885989707271, 51.784366796098062],
              [0.836970023478202, 51.784070968724031],
              [0.841925810087769, 51.781410505791015],
              [0.844198688395895, 51.782436539628968],
              [0.844861696709708, 51.78202746648401],
              [0.844786799642279, 51.781666836925176],
              [0.843088884779913, 51.781206091959326],
              [0.843278032949316, 51.780755431037058],
              [0.842879555118647, 51.780436806399507],
              [0.844404909025398, 51.778870071905217],
              [0.84358213239147, 51.778905277768189],
              [0.842410173773691, 51.779451669707889],
              [0.84021082686001, 51.779246741181353],
              [0.839329885931002, 51.7796718474549],
              [0.836668747979636, 51.779594029274946],
              [0.836534136169305, 51.780383320893939],
              [0.835810775143891, 51.780801897576339],
              [0.834463969522717, 51.782678086144074],
              [0.825640290475793, 51.783519274060417],
              [0.823469073620064, 51.784845860737697],
              [0.821764954303269, 51.785189882735608],
              [0.819600806530279, 51.786133099812815],
              [0.816129923745376, 51.786353941571555],
              [0.816310606579938, 51.787531365508727],
              [0.803900991290272, 51.789696112044183],
              [0.805702235275335, 51.791939274258581],
              [0.811539291240645, 51.790114362505868],
              [0.815900705790726, 51.789238275156876],
              [0.816647687269956, 51.791031674950403],
              [0.813852461826033, 51.791675133094408],
              [0.813375990101113, 51.79154624277124],
              [0.810632775839836, 51.792887176959795],
              [0.808083235289028, 51.79330967317469],
              [0.809895223148001, 51.795043481791232],
              [0.811826438125016, 51.799110034116261],
              [0.808995781171407, 51.799822569438923],
              [0.808926567861918, 51.800412401245026],
              [0.80111859969178, 51.801472947904607],
              [0.797901370976559, 51.803982337006062],
              [0.794765659804918, 51.804251211727639],
              [0.793056432239818, 51.804996938481587],
              [0.790604401120786, 51.806846695211625],
              [0.779061531467753, 51.81155400406432],
              [0.774863867069826, 51.808907484328955],
              [0.774266442786092, 51.807897233040997],
              [0.773423088236356, 51.807890158140907],
              [0.772146207461241, 51.807343803571683],
              [0.770342897921017, 51.805901506579687],
              [0.768594445553069, 51.805849178399328],
              [0.763372610048783, 51.803230876687863],
              [0.760298301696014, 51.802987457063359],
              [0.759315936920195, 51.80231803172213],
              [0.754202387633563, 51.800960364850894],
              [0.746467057160059, 51.799443441915294],
              [0.742834511885137, 51.800200990043372],
              [0.737620770443517, 51.798541867516931],
              [0.733826928914805, 51.798408949293275],
              [0.733610899825729, 51.799321409205255],
              [0.726829115882711, 51.797485132750133],
              [0.722598228579767, 51.797270260581172],
              [0.721540739207293, 51.796497941878194],
              [0.718143156902678, 51.79735450540862],
              [0.718779583003554, 51.798361430987725],
              [0.718472569270998, 51.800828222033388],
              [0.718992650368463, 51.801997919802687],
              [0.71867204087404, 51.804190726779041],
              [0.719008517767332, 51.805153327300957],
              [0.71732931255647, 51.806949469219184],
              [0.713302252472026, 51.8084508707284],
              [0.715027584241126, 51.810507377887298],
              [0.716360395938373, 51.810257143096429],
              [0.717288796192217, 51.810760894224686],
              [0.720147460328194, 51.80980442321961],
              [0.7216360129308, 51.809996594553979],
              [0.71708376562197, 51.812585890457065],
              [0.71310240422448, 51.815846221449384],
              [0.710538925257452, 51.817184220820167],
              [0.710764167630191, 51.817334611024314],
              [0.709834727143702, 51.818434344732424],
              [0.708881416763873, 51.818895194416875],
              [0.70905550004886, 51.819861563237758],
              [0.714402964960674, 51.823648497508501],
              [0.716804792703153, 51.824708182168422],
              [0.71775912395594, 51.825613334434266],
              [0.716234932508456, 51.827562348622259],
              [0.718524291393986, 51.829112037750058],
              [0.721304321453655, 51.830226738531032],
              [0.718101889447074, 51.833184096919545],
              [0.717524433837835, 51.832995997096688],
              [0.714155867451321, 51.833512696934761],
              [0.711876483305379, 51.834521293769285],
              [0.715747644003895, 51.837167695376962],
              [0.718892507446646, 51.837656185149697],
              [0.71937162299225, 51.837440054951216],
              [0.722065627838913, 51.838732101199732],
              [0.727801834716214, 51.839479585820882],
              [0.733164648097027, 51.841968492867316],
              [0.734300806579312, 51.84195736124385],
              [0.735142674632706, 51.840588773790643],
              [0.735036263157093, 51.837732260759488],
              [0.73664567596813, 51.83753112584337],
              [0.738159594440816, 51.837789955445487],
              [0.738749655192538, 51.83897232762375],
              [0.738452705530485, 51.839485571853487],
              [0.738985902664177, 51.839826589180724],
              [0.739545589542237, 51.84000600558246],
              [0.741561890391078, 51.839666715857852],
              [0.741428694974999, 51.841440616340037],
              [0.738355282542927, 51.841755068085021],
              [0.738446897045073, 51.842929265665752],
              [0.739383853680554, 51.84315205585105],
              [0.740097930379385, 51.842836834604739],
              [0.740058772817959, 51.843219965224428],
              [0.73870571268975, 51.843198433353763],
              [0.739247124675572, 51.843889100101791],
              [0.739066948291092, 51.844628055705996],
              [0.736349562257967, 51.847272428941544],
              [0.734550251810396, 51.84712543434204],
              [0.733116151459302, 51.848452025809493],
              [0.736194054453573, 51.84932833081627],
              [0.737078718855036, 51.848352644298245],
              [0.741125628628655, 51.849664918331662],
              [0.742133596193189, 51.851076752674139],
              [0.744439719234508, 51.85176939135642],
              [0.750316168148607, 51.853591723594107],
              [0.747503699471335, 51.854816726565588],
              [0.745206624661721, 51.856374972711869],
              [0.746532318699654, 51.857275723593837],
              [0.74694202374649, 51.857087184308867],
              [0.748265796880649, 51.857717260773207],
              [0.7476103036082, 51.858448452280136],
              [0.748726831308835, 51.859085165459106],
              [0.747319277128717, 51.860083949841872],
              [0.746154140158382, 51.859507714809517],
              [0.745845554153861, 51.85973525902444],
              [0.748704544458394, 51.861173044948636],
              [0.752897882830304, 51.863121828616585],
              [0.752351025635362, 51.863467385324924],
              [0.751457796293309, 51.864777938576324],
              [0.749413587343889, 51.866200839979612],
              [0.747767476824818, 51.866578368366113],
              [0.74615857876572, 51.867590833838911],
              [0.747194219382182, 51.868589185557617],
              [0.740533116028508, 51.87028158309726],
              [0.740714360540257, 51.870610112088812],
              [0.736578552770006, 51.871668721416789],
              [0.734854583198739, 51.870170072420372],
              [0.731849529897411, 51.868625624974612],
              [0.732112326517701, 51.868287665742919],
              [0.730432838288042, 51.867542459436869],
              [0.726072654599978, 51.869862274577905],
              [0.723006026109176, 51.869769650801331],
              [0.722178948605751, 51.869353524918779],
              [0.719386333880546, 51.870598960750002],
              [0.718675947472943, 51.869901319642807],
              [0.716377114397747, 51.871242285551411],
              [0.71741695437395, 51.872199428063816],
              [0.722094001585716, 51.872973495126764],
              [0.723471082931842, 51.873906590195269],
              [0.722810741214178, 51.874688110788114],
              [0.722624878014229, 51.875892119601133],
              [0.719978948896868, 51.875602611817826],
              [0.718160862365533, 51.877931642554167],
              [0.722731295106711, 51.878782797606533],
              [0.720162352416915, 51.880733535826316],
              [0.717313509824505, 51.882071046393044],
              [0.717685499950488, 51.882191054389885],
              [0.716502817371717, 51.883640216024254],
              [0.716164315395453, 51.883545511276388],
              [0.715026897602643, 51.884830833439736],
              [0.71184075019193, 51.886534824535929],
              [0.713172693371071, 51.886956465270195],
              [0.712510133911653, 51.888937675407341],
              [0.71287332465819, 51.889948234563477],
              [0.712480363022438, 51.891073361542752],
              [0.712884860763852, 51.891128783279342],
              [0.71244701095283, 51.892980700966092],
              [0.711540730222387, 51.893161678519299],
              [0.71027925150165, 51.894323904364398],
              [0.710526449914775, 51.895266995481634],
              [0.709252513502475, 51.895209112891415],
              [0.704694397663975, 51.896860856948777],
              [0.702703525393757, 51.898421081917562],
              [0.699930416683946, 51.899715044226532],
              [0.69938830769368, 51.901729378154954],
              [0.699568999694676, 51.903457325441934],
              [0.704792218960771, 51.903569357460242],
              [0.705643641110688, 51.903922994736142],
              [0.705432414216856, 51.904810988324201],
              [0.706986450768155, 51.905218600752477],
              [0.710880858807664, 51.904217896768458],
              [0.711103208043002, 51.902719894291494],
              [0.712776498579267, 51.902211867085555],
              [0.71351834547054, 51.903544641174143],
              [0.715065597361441, 51.904947855880764],
              [0.716766429290256, 51.904606411036085],
              [0.720558708674212, 51.904542141922583],
              [0.720647339728519, 51.903627276437469],
              [0.722576466872593, 51.9035763441019],
              [0.723424503928585, 51.905577497374132],
              [0.72528967063844, 51.905141291818865],
              [0.72641308965994, 51.905997496649604],
              [0.727170652120753, 51.907221904594252],
              [0.728984106140069, 51.908042299777534],
              [0.727227580672557, 51.909120861112704],
              [0.725033843380304, 51.908455839461169],
              [0.724318432418554, 51.90879167156956],
              [0.723908113468753, 51.908402772778032],
              [0.72219715241174, 51.910164588978482],
              [0.723327219707325, 51.910961314015985],
              [0.724154474056321, 51.912568138987091],
              [0.725566342418734, 51.912950023026099],
              [0.726676562485672, 51.912889219986461],
              [0.727810349618857, 51.91458424306181],
              [0.730086814985774, 51.915839951202656],
              [0.731439899044257, 51.918843765848287],
              [0.730414580221117, 51.923300342782063],
              [0.732083729989244, 51.923313751733929],
              [0.733801349227039, 51.924603052963569],
              [0.734817133266571, 51.924395981314035],
              [0.734759641531195, 51.925483703153766],
              [0.736757456845456, 51.928075866745544],
              [0.736734536384474, 51.929372328024151],
              [0.737328098487679, 51.929874731921238],
              [0.738182689688515, 51.930005032437379],
              [0.738868076432605, 51.930892001603972],
              [0.740111628877387, 51.93162747222479],
              [0.74042075094113, 51.932927891941581],
              [0.741437115880913, 51.934135390701925],
              [0.740567960540917, 51.936321215438483],
              [0.74024271026975, 51.939983648101844],
              [0.740868378805038, 51.940699326594469],
              [0.744439010643684, 51.94267465052291],
              [0.744907502054131, 51.943888603093683],
              [0.746889160414836, 51.945359519027761],
              [0.745728482536157, 51.945869547178582],
              [0.745822451763452, 51.948574322518589],
              [0.748512562516035, 51.951653758629718],
              [0.747653798597917, 51.952128872611503],
              [0.749676005284406, 51.954777812118806],
              [0.756016917577861, 51.953173463825514],
              [0.764009048660194, 51.954308916291161],
              [0.766150695995404, 51.955736186826051],
              [0.766672450005211, 51.956810308613235],
              [0.767671875522881, 51.957627678043721],
              [0.768306277315201, 51.959253129224678],
              [0.767841631324087, 51.959886393593045],
              [0.768476508916696, 51.961896744646189],
              [0.770720125107306, 51.962962704487026],
              [0.771559248477688, 51.962429428591328],
              [0.76982607140713, 51.961873980917083],
              [0.773082063426317, 51.960709112023871],
              [0.773121820188529, 51.960150591554545],
              [0.777543207525662, 51.959873644946683],
              [0.77823647060503, 51.960396877296198],
              [0.777396556106945, 51.960562395132641],
              [0.777756855264579, 51.960903729930003],
              [0.781572221370338, 51.963463032408519],
              [0.782256982814611, 51.965069235487086],
              [0.781269683388825, 51.965865097139549],
              [0.78175912138111, 51.966339166726804],
              [0.781437222285609, 51.966501459869363],
              [0.78275526135584, 51.967635831534615],
              [0.785089928233521, 51.967483475330994],
              [0.785404018778898, 51.966726000605483],
              [0.78639967794159, 51.966323812486394],
              [0.785684223401217, 51.963967418089894],
              [0.787128428808904, 51.963122925663093],
              [0.789394120531508, 51.963410988736456],
              [0.78993302728561, 51.96315719287815],
              [0.79101977084098, 51.963636847573817],
              [0.792761817489754, 51.963463316588403],
              [0.79573444860977, 51.963717391459724],
              [0.796817225457432, 51.963430856307852],
              [0.7991123615323, 51.963209010614868],
              [0.799702317470272, 51.962350502715225],
              [0.807655872793989, 51.961752710126078],
              [0.809623783582657, 51.960747049911667],
              [0.811549517953775, 51.960743323904573],
              [0.812487190089578, 51.961024892283369],
              [0.814150803702783, 51.962320633136422],
              [0.813930610064431, 51.963104723358569],
              [0.814520848027923, 51.963625712682109],
              [0.81383206734897, 51.96429690055681],
              [0.814111980395335, 51.965720150459561],
              [0.815170856818244, 51.966980874196281],
              [0.816367135138675, 51.967042184305917],
              [0.818135580838221, 51.968193266930101],
              [0.819805876363534, 51.968104692386639],
              [0.821609866780817, 51.968707176277768],
              [0.824844327051175, 51.968509069749928],
              [0.826118972897744, 51.967709532855544],
              [0.828870551221072, 51.966966219967631],
              [0.829895906497351, 51.966948762085764],
              [0.830468049637141, 51.966324351498194],
              [0.8334363491626, 51.966041574799242],
              [0.83582643413708, 51.96471863226143],
              [0.840179318227779, 51.96337975950415],
              [0.841188879951821, 51.96214757417922],
              [0.842058303315098, 51.96180731313936],
              [0.850208063019073, 51.960754922826546],
              [0.853852505913318, 51.962483265502698],
              [0.858299425526012, 51.962953611784712],
              [0.859444961683592, 51.964184862833939],
              [0.85886913286909, 51.965499304882975],
              [0.861542583542049, 51.966715890315484],
              [0.861858819446706, 51.967428574680021],
              [0.863062011502871, 51.96825908080276],
              [0.867526789077186, 51.969135153390731],
              [0.870643299303034, 51.969189576489306],
              [0.873169420572369, 51.968550547652669],
              [0.872380766347694, 51.967625487003495],
              [0.872681691928091, 51.967163072235323],
              [0.875834307259945, 51.966782989949444],
              [0.87672959592334, 51.967121745211386],
              [0.877543716360563, 51.966511868715912],
              [0.878775578590942, 51.966740751520874],
              [0.879973758718011, 51.96644432751981],
              [0.882995139351524, 51.967763434390747],
              [0.883987903821677, 51.967646475412643],
              [0.886534064962006, 51.968433032522952],
              [0.888132933384623, 51.970585515805098],
              [0.887867786525325, 51.971737792905458],
              [0.888788128387823, 51.972983285837032],
              [0.889656457349206, 51.973374768481975],
              [0.890750684819295, 51.972796594102491],
              [0.891428430740029, 51.973416683032511],
              [0.892786654646782, 51.973387800698958],
              [0.893697945892898, 51.972615338955329],
              [0.894689532031664, 51.972614335507089],
              [0.895660949413265, 51.972120076857024],
              [0.897300207711192, 51.972249706921815],
              [0.900270728184028, 51.973511171681487],
              [0.903657384387593, 51.972873658236381],
              [0.907641182373612, 51.972958762366737],
              [0.909861020266837, 51.973484875672426],
              [0.910979151535663, 51.973324118500152],
              [0.912772297412395, 51.973826555854878],
              [0.915482792404594, 51.974102989186513],
              [0.917012427176471, 51.973955362308779],
              [0.919687346025693, 51.974803670819327],
              [0.920697294401325, 51.974815483174062],
              [0.922533411236311, 51.976176489763361],
              [0.925137619172599, 51.976181937827455],
              [0.926504430320012, 51.97492123535342],
              [0.926623347794995, 51.973953269437168],
              [0.927750199261361, 51.972252437879334],
              [0.930146915055053, 51.97122417790721],
              [0.930757042403597, 51.971670354282551],
              [0.93203038474304, 51.971747457063408],
              [0.93520019656102, 51.971922054839034],
              [0.937232042041005, 51.972619623344393],
              [0.939541699157675, 51.972355099489597],
              [0.94006309720373, 51.971956249225443],
              [0.939929645931903, 51.971408276521522],
              [0.940648788719395, 51.971383113042911],
              [0.941989771372918, 51.970714647812983],
              [0.943262752574078, 51.970719689122596],
              [0.945210283773942, 51.971484882543784],
              [0.948142378359897, 51.972030221158811],
              [0.948788998665846, 51.972496976114016],
              [0.95152459640302, 51.972820516149667],
              [0.95310161933069, 51.974037358670671],
              [0.952656852781351, 51.974669977347325],
              [0.953145995727417, 51.975176634533483],
              [0.955904438488192, 51.976330510857146],
              [0.957827037096956, 51.976246228810261],
              [0.959010349744297, 51.976832543780517],
              [0.961874749812756, 51.976917873786547],
              [0.962458252442065, 51.977153209061733],
              [0.963309041899772, 51.97700317969116],
              [0.963297497781014, 51.976144579142485],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000072",
        LAD13CDO: "22UH",
        LAD13NM: "Epping Forest",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.174942923439333, 51.820524055881904],
            [0.176216520514863, 51.820003204234517],
            [0.179836493895044, 51.820295844446129],
            [0.18493808972091, 51.820109424897929],
            [0.186377900018991, 51.819727454826484],
            [0.188405650922003, 51.819651061267983],
            [0.188561098901319, 51.819295661226292],
            [0.189995721566363, 51.815037967341119],
            [0.192139845571821, 51.813686897593158],
            [0.192074372933597, 51.812397690776081],
            [0.190745819113709, 51.809653655899083],
            [0.192366316201346, 51.80807043650502],
            [0.192786009439729, 51.806286583133449],
            [0.192565471640962, 51.804731390572655],
            [0.195421813227259, 51.801123328670322],
            [0.195220699028819, 51.800058769853464],
            [0.19412707294095, 51.798564842658926],
            [0.195026103701818, 51.798083143537283],
            [0.195009949165064, 51.797631119876826],
            [0.19663302021546, 51.795784304766734],
            [0.197949290313316, 51.795921565966104],
            [0.198660277976583, 51.796554834400389],
            [0.199626302153347, 51.796557444421865],
            [0.201216592419813, 51.795352347267993],
            [0.201772401191688, 51.795419273731284],
            [0.203412618744583, 51.794080120737043],
            [0.20605276372797, 51.793263534441301],
            [0.207415519731651, 51.792096792680297],
            [0.210484504294371, 51.792699173429568],
            [0.213799652956801, 51.794062107384953],
            [0.212868290479734, 51.796513936036405],
            [0.212319827241329, 51.796953205717443],
            [0.212724899650477, 51.797139820957405],
            [0.221955636090364, 51.798515121064781],
            [0.222116810623604, 51.796837654143715],
            [0.224071790075701, 51.796866326642863],
            [0.224156170132199, 51.796576967072149],
            [0.226739488978291, 51.795906669770815],
            [0.225769439223612, 51.794497017434523],
            [0.231520168782892, 51.795154153146946],
            [0.23260699054772, 51.793668619357653],
            [0.233067859782367, 51.791461171047381],
            [0.233792064371023, 51.79103285064204],
            [0.235810939637198, 51.791068205044432],
            [0.234886499326004, 51.789646067733557],
            [0.235874421015005, 51.788569746998888],
            [0.237314294854053, 51.787866102659244],
            [0.238060005921029, 51.787991291366787],
            [0.238315751714972, 51.788646480037031],
            [0.240894304879891, 51.788390516800241],
            [0.237403738254721, 51.786714243298995],
            [0.236812066852705, 51.786126595978779],
            [0.236511564380207, 51.782191744844425],
            [0.237500451999459, 51.779820450089325],
            [0.242587397687717, 51.780662326886272],
            [0.250141539184095, 51.780880346329447],
            [0.25379623432421, 51.781718577116941],
            [0.257471564920238, 51.781863863812198],
            [0.258385548808496, 51.783588183268968],
            [0.259241081972213, 51.784199428670796],
            [0.262481420653413, 51.78439788421646],
            [0.263771910633771, 51.785267805581888],
            [0.266072747343333, 51.785581375310791],
            [0.267562801501961, 51.786207303522346],
            [0.26796570317034, 51.787157249059511],
            [0.274107819383039, 51.787644675286849],
            [0.277671314626756, 51.789099033460332],
            [0.280060915456123, 51.788924106710056],
            [0.281613333112183, 51.789515375844097],
            [0.284438815207176, 51.789098987193782],
            [0.285157453281859, 51.789460012653002],
            [0.285913911820861, 51.789106278412468],
            [0.288628615771174, 51.78896622019036],
            [0.295589346390397, 51.790258516846713],
            [0.297433217991001, 51.789959902606391],
            [0.296701865400021, 51.788779964152752],
            [0.297668378522398, 51.78856683124711],
            [0.29733288789238, 51.78815702674386],
            [0.297872069116779, 51.787695951664354],
            [0.2983166282199, 51.785430987127555],
            [0.299172061342417, 51.785202027845003],
            [0.298206317408067, 51.784430456275288],
            [0.298526863914966, 51.784175988765718],
            [0.298262317788388, 51.783335846668116],
            [0.296822731052265, 51.782596910051915],
            [0.29724612551414, 51.782055366755515],
            [0.296429009970751, 51.780554263956418],
            [0.297247541142508, 51.780485210137506],
            [0.297883265890451, 51.779814514767061],
            [0.299971805013799, 51.779090213257099],
            [0.30230834754741, 51.778710833093939],
            [0.302200973069606, 51.777826253212659],
            [0.302802686601298, 51.777400798741667],
            [0.302025647307288, 51.775435822015488],
            [0.299117847106175, 51.774327286885907],
            [0.298569347406001, 51.7737175178193],
            [0.298874239152314, 51.772243939922909],
            [0.299338539852813, 51.771853564387747],
            [0.303278342383536, 51.77156867971609],
            [0.307275085557481, 51.769900359036285],
            [0.307881762238078, 51.770171717648182],
            [0.312261022854368, 51.770321241533864],
            [0.312938796152628, 51.768835788897434],
            [0.318441787692576, 51.769928781540962],
            [0.319571556161482, 51.772598021729486],
            [0.320055053085282, 51.772557002667625],
            [0.322322048870147, 51.772018521845794],
            [0.321611658475811, 51.769926451644501],
            [0.323786220033803, 51.770046239871128],
            [0.324951765234788, 51.770500702273537],
            [0.325292384722098, 51.769682814395352],
            [0.327271672799407, 51.769162518823585],
            [0.336082072629468, 51.768305149885194],
            [0.33882661363227, 51.767899838098757],
            [0.340521003653356, 51.767312145624359],
            [0.346673570824595, 51.767589619783088],
            [0.344940790403064, 51.766205145272259],
            [0.344959342851831, 51.763431391699648],
            [0.344132337850939, 51.760000946549631],
            [0.342522383456406, 51.758126576515963],
            [0.341087043813135, 51.752005893320231],
            [0.341370410800483, 51.75084556824234],
            [0.342035930468561, 51.750995074246831],
            [0.344124504112067, 51.749358994699016],
            [0.343994555656165, 51.747464999004443],
            [0.346051172881526, 51.741861878708576],
            [0.345531449949416, 51.740917215982833],
            [0.345820296347514, 51.737908737476012],
            [0.345007951668334, 51.73522800004487],
            [0.347480898767286, 51.731578770228047],
            [0.347424478593478, 51.730434207357312],
            [0.346997837470151, 51.729411250959011],
            [0.34616053529922, 51.729126715839357],
            [0.344696524260562, 51.727785434510295],
            [0.343403883942083, 51.725892152301775],
            [0.342830085991042, 51.724176968538565],
            [0.340991885854794, 51.723014868170651],
            [0.33905919076683, 51.721002098166316],
            [0.335192644564739, 51.719163550826842],
            [0.333512313273479, 51.718725718818831],
            [0.333972570332174, 51.717007931689032],
            [0.330010466793847, 51.716425618422726],
            [0.324559802654653, 51.7143114825246],
            [0.323034997564357, 51.714162691717789],
            [0.323345038701982, 51.7132635698431],
            [0.32197652533537, 51.712152137341825],
            [0.32167742024794, 51.711343298082696],
            [0.323743275834434, 51.710329418817736],
            [0.323799609223068, 51.709790531779966],
            [0.321229645123768, 51.708281090612786],
            [0.313504109608015, 51.70220597383927],
            [0.307429177665377, 51.694286780527499],
            [0.307393569668426, 51.691880094422686],
            [0.306866563044314, 51.690195295411051],
            [0.305690384574203, 51.689031337680575],
            [0.305570780195332, 51.688044472157266],
            [0.303864876304731, 51.685279376172744],
            [0.303840207087635, 51.684992988103559],
            [0.305031551711128, 51.684806838627907],
            [0.303887654716296, 51.683420106084711],
            [0.302962547777439, 51.683520986782433],
            [0.303399442562409, 51.682722845014695],
            [0.303162282365835, 51.681784146067507],
            [0.302097768712273, 51.680961490154935],
            [0.298733147884967, 51.682650613490395],
            [0.297199186930358, 51.684412653954404],
            [0.293869450469259, 51.686428290666164],
            [0.291793591054865, 51.686290763020537],
            [0.292605059395379, 51.687289315587989],
            [0.288643215068504, 51.690492500947009],
            [0.283886250604286, 51.69271550794965],
            [0.28462702603663, 51.693345884376185],
            [0.28345130322504, 51.694107060512771],
            [0.279643772158912, 51.693638763944463],
            [0.275861954750411, 51.695440528524529],
            [0.275294843229879, 51.69622219981769],
            [0.273942117289617, 51.696892283352994],
            [0.272905210842377, 51.696443830693781],
            [0.269881341311298, 51.696657888434331],
            [0.269415263404671, 51.696366536388631],
            [0.268009256294352, 51.696435962943234],
            [0.267980573922861, 51.696093891618929],
            [0.26684077728984, 51.695814638619289],
            [0.266329287786391, 51.696206703454507],
            [0.265699409730929, 51.69813971515277],
            [0.265680715610705, 51.698518671906086],
            [0.266254930554491, 51.698744103354159],
            [0.265712358867374, 51.699702410711865],
            [0.263487127306446, 51.699221038346479],
            [0.257485682377158, 51.69683835558606],
            [0.256987129320442, 51.696590744277806],
            [0.257750999867541, 51.695801784363425],
            [0.257685638484362, 51.6950701297609],
            [0.257054926487975, 51.694027400209144],
            [0.255678330835765, 51.693203131763141],
            [0.25556940296321, 51.692816736021562],
            [0.254923155294096, 51.692303968056244],
            [0.255172483420847, 51.690450265828602],
            [0.254285298287123, 51.689834207618681],
            [0.252908151848492, 51.689898404213913],
            [0.25191716091643, 51.688997450203033],
            [0.248830312130992, 51.689187895748219],
            [0.248765619266039, 51.688816833869019],
            [0.24694963941428, 51.688725694568596],
            [0.245794586755898, 51.688131784182808],
            [0.244371060099243, 51.687725546941621],
            [0.242711237914168, 51.685494677094198],
            [0.243171279304553, 51.684996684017882],
            [0.240309120846326, 51.683550428416687],
            [0.240198557775961, 51.682746785843435],
            [0.23842351197739, 51.682864266873679],
            [0.236701534130598, 51.683618296923925],
            [0.23540738882258, 51.684659120428385],
            [0.233467608648562, 51.684400167284657],
            [0.232157505421153, 51.683354039823151],
            [0.233699092831106, 51.679345431691083],
            [0.234242222211044, 51.679329703535153],
            [0.235971249239969, 51.678045900112814],
            [0.237060211066995, 51.677893865428359],
            [0.237610497922695, 51.67731953424083],
            [0.239040505623343, 51.676897493528259],
            [0.240059790936571, 51.675656835020831],
            [0.238975104774402, 51.674105585068233],
            [0.236141208189502, 51.673539084930958],
            [0.235123110277567, 51.674041380369609],
            [0.234103754697025, 51.673519414356861],
            [0.231657179557635, 51.673592016573323],
            [0.229410177712432, 51.674694946392748],
            [0.227466604119699, 51.674290286270875],
            [0.226052374944063, 51.675005050641296],
            [0.224417156386111, 51.674989276074321],
            [0.223606267037422, 51.67541380438437],
            [0.219978600487323, 51.675383522537821],
            [0.218131543855508, 51.674614475850262],
            [0.216028395454492, 51.675086735781001],
            [0.214999963768137, 51.674848947165934],
            [0.214639528436454, 51.674013125690536],
            [0.214908724207235, 51.673631252448494],
            [0.213833121658465, 51.673226179319151],
            [0.213080839610945, 51.670641457507635],
            [0.213496640278909, 51.668995141872337],
            [0.212664161398772, 51.667908321418373],
            [0.212789947602488, 51.667136171849059],
            [0.213575880003703, 51.666586289450798],
            [0.213274248749159, 51.665530830980416],
            [0.210566850209283, 51.664812054347941],
            [0.208789622018997, 51.664812227205545],
            [0.208076832097911, 51.665231206529448],
            [0.206760653594156, 51.664482574675077],
            [0.206908120045205, 51.663678549589484],
            [0.20842169847799, 51.66239110636679],
            [0.208129798436349, 51.660967648589349],
            [0.20090033116153, 51.659691527256513],
            [0.199193549088185, 51.660455517628272],
            [0.197388834067688, 51.660541464804545],
            [0.196088985596217, 51.660991140649081],
            [0.193048503974336, 51.660968834197355],
            [0.190831227477209, 51.659816892807925],
            [0.191285173381361, 51.658916342990118],
            [0.190880787152098, 51.658356448307025],
            [0.192419360418139, 51.657221622512154],
            [0.192285862757795, 51.655653984026898],
            [0.193573952871473, 51.654903312162205],
            [0.192963309699426, 51.653095488696671],
            [0.191197665731643, 51.652696803670871],
            [0.189260444261339, 51.653116932790134],
            [0.186542885461652, 51.652734125878617],
            [0.184489961910187, 51.654086178563361],
            [0.183974246872044, 51.654034622353933],
            [0.182957688399119, 51.653157852358319],
            [0.182212084690171, 51.653075490999271],
            [0.180676098726345, 51.653724527855623],
            [0.180619525835175, 51.655045709902033],
            [0.179574128617576, 51.655585799702088],
            [0.178982127388346, 51.656385449260512],
            [0.17675740524142, 51.656182114550461],
            [0.1756758875862, 51.655788496038483],
            [0.177278470094217, 51.652122133762226],
            [0.184703228739675, 51.648660493637621],
            [0.188416329667859, 51.645827869495989],
            [0.188931533632064, 51.644460360220258],
            [0.190526370474351, 51.642792134201805],
            [0.193210170127118, 51.641349047989614],
            [0.194595575423243, 51.640976036612606],
            [0.195893406708738, 51.638880734685841],
            [0.196032619625818, 51.636647025993732],
            [0.196920788899475, 51.634331856075384],
            [0.199324576076769, 51.631267070479808],
            [0.200283844292244, 51.624932563652074],
            [0.196959546275582, 51.625564973950652],
            [0.194747361716966, 51.625711573807223],
            [0.189786736460049, 51.625100987984382],
            [0.189372869000191, 51.624487307802482],
            [0.188343802484066, 51.625410271132758],
            [0.18596941660459, 51.625519261721287],
            [0.186240989324643, 51.624556474235966],
            [0.185904071328404, 51.62437030385756],
            [0.182470784717506, 51.625805587045718],
            [0.180584670170579, 51.625732727856452],
            [0.180369508956571, 51.624469645111326],
            [0.175276190407524, 51.623584835012551],
            [0.168873652552486, 51.621417357707053],
            [0.165813250932475, 51.622376739226155],
            [0.162774416135268, 51.622789783348985],
            [0.162502680470513, 51.622574467912898],
            [0.15551748525551, 51.623337837983257],
            [0.151045901248467, 51.62324450989729],
            [0.138156906432108, 51.623542949725994],
            [0.135783262642079, 51.623544796873546],
            [0.129160273156842, 51.621441225279511],
            [0.128852341237433, 51.620695920589625],
            [0.126071179724223, 51.619641127136326],
            [0.124171356766201, 51.617503788753048],
            [0.119280179178439, 51.615366513887075],
            [0.118579520485394, 51.615364774551125],
            [0.116683030987011, 51.616919653734456],
            [0.109604762377142, 51.613959900804396],
            [0.103810880451421, 51.612549528938523],
            [0.103387503710872, 51.613451887736652],
            [0.100141460688446, 51.612723155799983],
            [0.098041444197624, 51.615419843160609],
            [0.09225798342374, 51.613572571985912],
            [0.093614496049257, 51.612478256943525],
            [0.094665678645763, 51.61074550049215],
            [0.094209339785468, 51.610652925935604],
            [0.093689855188715, 51.609753035724005],
            [0.092624181609221, 51.609475283106086],
            [0.091846893345649, 51.608213080514751],
            [0.091687476595844, 51.607478523363234],
            [0.092554211275719, 51.606195107610688],
            [0.089602896257472, 51.605516587095757],
            [0.088900152817654, 51.605182879348156],
            [0.088895075721724, 51.604852039722516],
            [0.087118373482815, 51.604465489920706],
            [0.084448170137849, 51.606357338277007],
            [0.080009903212025, 51.606983731144183],
            [0.077882461970067, 51.606728254666933],
            [0.072805474674718, 51.604685873376205],
            [0.065940540795626, 51.606319475785547],
            [0.063984226626999, 51.607223482362031],
            [0.062972074814149, 51.606910348593068],
            [0.062518865773622, 51.607075684710729],
            [0.060671163151509, 51.608275382380796],
            [0.052604679058358, 51.612013772782376],
            [0.048894828011061, 51.613569564403349],
            [0.048067560769485, 51.613321418333648],
            [0.048457124366729, 51.614980049405673],
            [0.050149988889679, 51.616389309032371],
            [0.051974508829856, 51.61680795656806],
            [0.053403668480903, 51.616339628422288],
            [0.054184941170699, 51.61671893496402],
            [0.054364798447976, 51.617598860705357],
            [0.053684743476106, 51.618278011962161],
            [0.053071514732924, 51.618421829930277],
            [0.04783412362604, 51.617409029057313],
            [0.04367878331255, 51.616940097094037],
            [0.040761332572131, 51.615727398199127],
            [0.039987730979364, 51.616096052797033],
            [0.040188111167759, 51.616409109078965],
            [0.037518939303153, 51.617898781426469],
            [0.037451330277155, 51.618324404703081],
            [0.032755330213064, 51.622242052524996],
            [0.031444277421763, 51.623099258795307],
            [0.030497879205082, 51.623290988087305],
            [0.030701035389917, 51.623634586716122],
            [0.02893707714305, 51.624672255446683],
            [0.029056374181222, 51.625111728082373],
            [0.02680757318824, 51.625905951902723],
            [0.024561812409684, 51.627516603142304],
            [0.021792686490706, 51.628831377599369],
            [0.023863960743601, 51.630795628684623],
            [0.024303315041836, 51.632387826941979],
            [0.025712348457367, 51.634563997072846],
            [0.025198033535913, 51.635248210356501],
            [0.024686058104351, 51.636050183121114],
            [0.025132829406305, 51.637287943655728],
            [0.023056419597807, 51.637716716862023],
            [0.022693114012789, 51.64111406035483],
            [0.020053209021486, 51.640445505781337],
            [0.017394201794702, 51.640256521997259],
            [0.009872303338654, 51.641448448204002],
            [0.001040860750637, 51.64176116774636],
            [-0.000521073108705, 51.643709475399461],
            [-0.004010466504147, 51.642715827881545],
            [-0.004585800750462, 51.643536730282484],
            [-0.006683314186573, 51.643332260624938],
            [-0.0082177840334, 51.64360739434634],
            [-0.008472227557521, 51.644496571204591],
            [-0.007370156912229, 51.646160366316266],
            [-0.009240415469788, 51.646526612836922],
            [-0.012286061781355, 51.646227507168227],
            [-0.011863612760221, 51.648846157711397],
            [-0.011806130916044, 51.65303387789271],
            [-0.008967245962211, 51.660215717764117],
            [-0.009111860166019, 51.661006808691745],
            [-0.009857831575289, 51.661768525887567],
            [-0.011520350825561, 51.663889235227572],
            [-0.01124488789096, 51.667603848780281],
            [-0.010584897871078, 51.669134874881728],
            [-0.011397077199181, 51.670699828166832],
            [-0.011377064532896, 51.671916167091034],
            [-0.010354822639989, 51.676662148980107],
            [-0.011091949664576, 51.680866908204273],
            [-0.011945056131974, 51.680875057638005],
            [-0.011828486339679, 51.683932308824701],
            [-0.010973541981477, 51.685549059405218],
            [-0.011984593353125, 51.687072408412661],
            [-0.016182928821951, 51.68988788111777],
            [-0.018522641070342, 51.698430594169913],
            [-0.019756993347178, 51.703270434176559],
            [-0.017605324615655, 51.707399417519632],
            [-0.015525970133704, 51.715323469192434],
            [-0.014146590471067, 51.723116334088438],
            [-0.013562466431346, 51.728382281862899],
            [-0.013949777234552, 51.731439038787585],
            [-0.012993882702031, 51.733389506289853],
            [-0.013773418872791, 51.73539539274222],
            [-0.012661188342456, 51.737605790879776],
            [-0.013618908514886, 51.740966240220978],
            [-0.013536740480499, 51.742212089688451],
            [-0.013006325065882, 51.742746259401926],
            [-0.012075557668846, 51.742995790946587],
            [-0.007451138494262, 51.741727777371828],
            [-0.006260036469766, 51.741704875896758],
            [-0.004951647158568, 51.742075634761008],
            [0.001230901445313, 51.745131317662675],
            [0.001742169446185, 51.745915735575871],
            [0.003236501013, 51.753562549548938],
            [0.002887430080698, 51.753794207490913],
            [0.002255115538609, 51.754173668998924],
            [0.004143665534512, 51.755366234882274],
            [0.010051038498844, 51.755596260859953],
            [0.012965153701657, 51.757459955265993],
            [0.013447645986071, 51.758110829712109],
            [0.013361387294533, 51.758940503741897],
            [0.012055010114072, 51.760976265501668],
            [0.013107909618018, 51.762852024221402],
            [0.014484367671772, 51.76320431887131],
            [0.01371764165474, 51.764309130247355],
            [0.014001695792012, 51.764374403200783],
            [0.015972033154369, 51.765596853460437],
            [0.016622178628175, 51.766982211851854],
            [0.018016459363661, 51.767275709777394],
            [0.017420302311926, 51.768727418347602],
            [0.017879939887812, 51.769089113315523],
            [0.018271283463203, 51.768894453572969],
            [0.018109632342708, 51.769689455418863],
            [0.026772124246153, 51.774174155542191],
            [0.030806984173994, 51.775192584753185],
            [0.035760172517534, 51.775739960746492],
            [0.040388520482036, 51.77528562269228],
            [0.040804585098214, 51.775511301594541],
            [0.042296152447313, 51.775124799484985],
            [0.046747638404524, 51.775313540422928],
            [0.051721161072126, 51.776048730656406],
            [0.053314536285211, 51.776852701381735],
            [0.054879726937529, 51.778279416647287],
            [0.05763641373929, 51.779668173609871],
            [0.058045896721803, 51.779523420954014],
            [0.058754041352877, 51.779888699905911],
            [0.060130952869604, 51.778353852786374],
            [0.060910857568385, 51.77838514244506],
            [0.061241262729713, 51.778000768834325],
            [0.054041607168481, 51.775529806631212],
            [0.055431805595775, 51.7713519169519],
            [0.055308146507516, 51.770576237218926],
            [0.054705167096308, 51.770256759338892],
            [0.055269067343725, 51.767738020372271],
            [0.054799323323773, 51.766426149695278],
            [0.057642851498342, 51.765884514996579],
            [0.05816164041134, 51.763015844868661],
            [0.057636157124232, 51.762900951190552],
            [0.058432840827833, 51.761140673286533],
            [0.060226040953716, 51.761854721979617],
            [0.061623081159436, 51.760011056412147],
            [0.062923240750841, 51.760177081518108],
            [0.0632292728813, 51.762058318747094],
            [0.064560792292444, 51.762179712679618],
            [0.06456174023183, 51.76159698717148],
            [0.065399220581045, 51.761562488281683],
            [0.067439429571914, 51.761399821087295],
            [0.065602650875736, 51.758209234669764],
            [0.06548186816622, 51.753975024028819],
            [0.063436812455919, 51.752212465239907],
            [0.064205082267788, 51.751807801451982],
            [0.063924973722503, 51.75144762872781],
            [0.067949497631425, 51.751155659961071],
            [0.067018584332282, 51.749266540627133],
            [0.06801071824148, 51.749133981107661],
            [0.067610368078181, 51.748425227749685],
            [0.068262410108437, 51.748237500971072],
            [0.068032844387347, 51.747842276871999],
            [0.069105422181062, 51.747093208755004],
            [0.069714677712335, 51.747554582540658],
            [0.070029867287574, 51.747414143769284],
            [0.076936473022913, 51.743671008034219],
            [0.076261678165322, 51.742823254328954],
            [0.077943740104252, 51.741819636095897],
            [0.07887684789422, 51.742267141984186],
            [0.080725180154592, 51.741384637496481],
            [0.081095244809775, 51.738279277800309],
            [0.084259699763161, 51.734684645129079],
            [0.092776899225925, 51.736216639950662],
            [0.096715706215501, 51.738141851429894],
            [0.100313972679196, 51.738963346028441],
            [0.100048845437861, 51.739336777472246],
            [0.099604877384529, 51.740570389092127],
            [0.097408887628329, 51.743257898033526],
            [0.09974858173134, 51.743950797217316],
            [0.099629555036015, 51.744164247711744],
            [0.102415387191849, 51.746289718707644],
            [0.102780099989637, 51.745970253804288],
            [0.107440931475901, 51.747350731672555],
            [0.107986361899884, 51.745499284240012],
            [0.112660965692529, 51.746520507720504],
            [0.114708136634515, 51.746989960592352],
            [0.114440464487724, 51.747772629412673],
            [0.11901922135574, 51.748285451292816],
            [0.120440352353542, 51.747912703592881],
            [0.123475499465078, 51.748902823417552],
            [0.126797533965486, 51.749119712105966],
            [0.126582791278955, 51.752135193202427],
            [0.131874226978841, 51.75306266176095],
            [0.133736652344533, 51.752921847161687],
            [0.133632549328319, 51.753940791971552],
            [0.147369095115903, 51.757561859542982],
            [0.148109912119205, 51.759065360919493],
            [0.151234336129915, 51.763642060644685],
            [0.154607965176771, 51.767046878978753],
            [0.162071983125809, 51.774232423604133],
            [0.165116605547225, 51.778089027547104],
            [0.167991434564327, 51.782777797643639],
            [0.162556085641109, 51.783568554716759],
            [0.158040986353118, 51.785083149686201],
            [0.156173896516296, 51.785304459366145],
            [0.155009971102308, 51.78579881111326],
            [0.154710932301838, 51.786643294595756],
            [0.15363345435254, 51.78874031062508],
            [0.151568354136401, 51.789009236834502],
            [0.151530364127261, 51.79064836587628],
            [0.152404065779323, 51.791608056734539],
            [0.154371668768185, 51.792516223712532],
            [0.153542151038531, 51.793793970945835],
            [0.151958703883314, 51.794193460765953],
            [0.150522413945542, 51.793937381875971],
            [0.149066225395675, 51.793201450215037],
            [0.147392954391227, 51.793450547473981],
            [0.145901248723668, 51.794591958073745],
            [0.145841194599618, 51.795373602170791],
            [0.146496972605133, 51.795776182895658],
            [0.146114246375821, 51.796237291459207],
            [0.148146765148924, 51.795946578131584],
            [0.150739807518987, 51.797043008327257],
            [0.15152381355709, 51.799193153693068],
            [0.150427100278729, 51.800955964955641],
            [0.150647127056583, 51.802379047022818],
            [0.15109759300984, 51.802731403658647],
            [0.15342340643823, 51.803019745539338],
            [0.154047458957362, 51.8035136901565],
            [0.15548167456111, 51.806455821478075],
            [0.155297396768214, 51.807275717592283],
            [0.158160598878677, 51.810062117981957],
            [0.158671809524021, 51.81238088610673],
            [0.158281834435868, 51.813510307384355],
            [0.162233256106716, 51.81458783997477],
            [0.166981244838545, 51.813272947227674],
            [0.167977846698239, 51.814963147084981],
            [0.170359791553925, 51.816554933883069],
            [0.172555733806368, 51.816297656672077],
            [0.173278358782068, 51.817535157204816],
            [0.172994550768395, 51.818873989772229],
            [0.170131400456712, 51.819290168899634],
            [0.169277835702986, 51.818255598233613],
            [0.164704784589414, 51.819233737870682],
            [0.16464168895269, 51.819703407749444],
            [0.166224467374737, 51.822147188569772],
            [0.174942923439333, 51.820524055881904],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000073",
        LAD13CDO: "22UJ",
        LAD13NM: "Harlow",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.145841194599618, 51.795373602170791],
            [0.145901248723668, 51.794591958073745],
            [0.147392954391227, 51.793450547473981],
            [0.149066225395675, 51.793201450215037],
            [0.150522413945542, 51.793937381875971],
            [0.151958703883314, 51.794193460765953],
            [0.153542151038531, 51.793793970945835],
            [0.154371668768185, 51.792516223712532],
            [0.152404065779323, 51.791608056734539],
            [0.151530364127261, 51.79064836587628],
            [0.151568354136401, 51.789009236834502],
            [0.15363345435254, 51.78874031062508],
            [0.154710932301838, 51.786643294595756],
            [0.155009971102308, 51.78579881111326],
            [0.156173896516296, 51.785304459366145],
            [0.158040986353118, 51.785083149686201],
            [0.162556085641109, 51.783568554716759],
            [0.167991434564327, 51.782777797643639],
            [0.165116605547225, 51.778089027547104],
            [0.162071983125809, 51.774232423604133],
            [0.154607965176771, 51.767046878978753],
            [0.151234336129915, 51.763642060644685],
            [0.148109912119205, 51.759065360919493],
            [0.147369095115903, 51.757561859542982],
            [0.133632549328319, 51.753940791971552],
            [0.133736652344533, 51.752921847161687],
            [0.131874226978841, 51.75306266176095],
            [0.126582791278955, 51.752135193202427],
            [0.126797533965486, 51.749119712105966],
            [0.123475499465078, 51.748902823417552],
            [0.120440352353542, 51.747912703592881],
            [0.11901922135574, 51.748285451292816],
            [0.114440464487724, 51.747772629412673],
            [0.114708136634515, 51.746989960592352],
            [0.112660965692529, 51.746520507720504],
            [0.107986361899884, 51.745499284240012],
            [0.107440931475901, 51.747350731672555],
            [0.102780099989637, 51.745970253804288],
            [0.102415387191849, 51.746289718707644],
            [0.099629555036015, 51.744164247711744],
            [0.09974858173134, 51.743950797217316],
            [0.097408887628329, 51.743257898033526],
            [0.099604877384529, 51.740570389092127],
            [0.100048845437861, 51.739336777472246],
            [0.100313972679196, 51.738963346028441],
            [0.096715706215501, 51.738141851429894],
            [0.092776899225925, 51.736216639950662],
            [0.084259699763161, 51.734684645129079],
            [0.081095244809775, 51.738279277800309],
            [0.080725180154592, 51.741384637496481],
            [0.07887684789422, 51.742267141984186],
            [0.077943740104252, 51.741819636095897],
            [0.076261678165322, 51.742823254328954],
            [0.076936473022913, 51.743671008034219],
            [0.070029867287574, 51.747414143769284],
            [0.069714677712335, 51.747554582540658],
            [0.069105422181062, 51.747093208755004],
            [0.068032844387347, 51.747842276871999],
            [0.068262410108437, 51.748237500971072],
            [0.067610368078181, 51.748425227749685],
            [0.06801071824148, 51.749133981107661],
            [0.067018584332282, 51.749266540627133],
            [0.067949497631425, 51.751155659961071],
            [0.063924973722503, 51.75144762872781],
            [0.064205082267788, 51.751807801451982],
            [0.063436812455919, 51.752212465239907],
            [0.06548186816622, 51.753975024028819],
            [0.065602650875736, 51.758209234669764],
            [0.067439429571914, 51.761399821087295],
            [0.065399220581045, 51.761562488281683],
            [0.06456174023183, 51.76159698717148],
            [0.064560792292444, 51.762179712679618],
            [0.0632292728813, 51.762058318747094],
            [0.062923240750841, 51.760177081518108],
            [0.061623081159436, 51.760011056412147],
            [0.060226040953716, 51.761854721979617],
            [0.058432840827833, 51.761140673286533],
            [0.057636157124232, 51.762900951190552],
            [0.05816164041134, 51.763015844868661],
            [0.057642851498342, 51.765884514996579],
            [0.054799323323773, 51.766426149695278],
            [0.055269067343725, 51.767738020372271],
            [0.054705167096308, 51.770256759338892],
            [0.055308146507516, 51.770576237218926],
            [0.055431805595775, 51.7713519169519],
            [0.054041607168481, 51.775529806631212],
            [0.061241262729713, 51.778000768834325],
            [0.060910857568385, 51.77838514244506],
            [0.060130952869604, 51.778353852786374],
            [0.058754041352877, 51.779888699905911],
            [0.058045896721803, 51.779523420954014],
            [0.05763641373929, 51.779668173609871],
            [0.05970181664469, 51.782936701183388],
            [0.060841914984314, 51.783521006021132],
            [0.063656677357618, 51.783947319317789],
            [0.068227460922728, 51.78393352659419],
            [0.069804020166939, 51.783312277304006],
            [0.077027427661775, 51.782466364178397],
            [0.079663952854299, 51.782955693124975],
            [0.079788467198152, 51.783361745535927],
            [0.08066394675997, 51.783577356482979],
            [0.080999516149039, 51.783213517859053],
            [0.081743962244002, 51.783196738550053],
            [0.082467998993983, 51.78349235293495],
            [0.084590997154797, 51.783375587554566],
            [0.085966718903214, 51.783811593004877],
            [0.086195257551806, 51.783517081819802],
            [0.08657052372931, 51.783763107787088],
            [0.087169883457675, 51.783555532526186],
            [0.087682971640563, 51.784054492387057],
            [0.088987104685961, 51.784257931234897],
            [0.089721846379846, 51.78403085120145],
            [0.090939822134069, 51.784064044811217],
            [0.091248415929293, 51.783745618401355],
            [0.091318134519987, 51.784281225842363],
            [0.091840663224665, 51.784105567880445],
            [0.091955546000322, 51.784426351434412],
            [0.092533385296376, 51.78450599017323],
            [0.09569797878652, 51.783400193131627],
            [0.096895957591154, 51.783596444501825],
            [0.097712929332964, 51.783236557556307],
            [0.099227472856848, 51.783224805253639],
            [0.10097628688907, 51.783948923997656],
            [0.102133029174881, 51.784910216701789],
            [0.103544687729279, 51.785085493370765],
            [0.107293506354804, 51.787933694773749],
            [0.111126527441185, 51.788444928122843],
            [0.115347774389209, 51.788698161621973],
            [0.118680033474841, 51.790061652526425],
            [0.120493359213418, 51.790170136246836],
            [0.122016716673662, 51.791237027752196],
            [0.123853993530277, 51.793674973778472],
            [0.125406502253015, 51.794494002813828],
            [0.125582415195445, 51.795150868485017],
            [0.12730821104673, 51.794904729698608],
            [0.128881462939814, 51.795299793020114],
            [0.129140688734024, 51.795876910389012],
            [0.132425523741354, 51.795846143914609],
            [0.135830372132235, 51.795040651708327],
            [0.138903165053681, 51.79574014822699],
            [0.139319568656385, 51.796326074176292],
            [0.14043884383569, 51.796472059904083],
            [0.141425040469514, 51.797206768909867],
            [0.143975754351361, 51.797198942879952],
            [0.146114246375821, 51.796237291459207],
            [0.146496972605133, 51.795776182895658],
            [0.145841194599618, 51.795373602170791],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000074",
        LAD13CDO: "22UK",
        LAD13NM: "Maldon",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [0.738254972263186, 51.636211330572891],
              [0.738964780154325, 51.635039087614537],
              [0.737963876139375, 51.635074135693557],
              [0.737006885408174, 51.635526353681712],
              [0.736886529456389, 51.636239655396565],
              [0.737337336431598, 51.636261517967519],
              [0.738254972263186, 51.636211330572891],
            ],
          ],
          [
            [
              [0.750415238848333, 51.635151709515668],
              [0.747660385422892, 51.634599280763339],
              [0.743461593975016, 51.63433057586758],
              [0.738964197099258, 51.634477900004335],
              [0.739258934770082, 51.635315520495851],
              [0.741201890015352, 51.635371779688136],
              [0.746732325803984, 51.634715439594856],
              [0.747288053167018, 51.63525374007412],
              [0.748293117814441, 51.635550379664188],
              [0.74987958972838, 51.635542894162299],
              [0.751758854625881, 51.636074435293175],
              [0.752196791669284, 51.636003008790318],
              [0.750415238848333, 51.635151709515668],
            ],
          ],
          [
            [
              [0.696059054525984, 51.638762165762998],
              [0.696614753355658, 51.638213384035502],
              [0.697137141341558, 51.638272428611728],
              [0.697685334010569, 51.637743599894549],
              [0.697274424977476, 51.637931116608655],
              [0.696164395204877, 51.637760564369401],
              [0.696059054525984, 51.638762165762998],
            ],
          ],
          [
            [
              [0.751844984463136, 51.642150308328652],
              [0.751997630614582, 51.641854436211737],
              [0.753118629624182, 51.641962149027094],
              [0.753119190802561, 51.641637465986712],
              [0.75337965051696, 51.641880478986444],
              [0.755460375721477, 51.641581621159091],
              [0.76139832459157, 51.641603938553651],
              [0.762452446306253, 51.640991845747266],
              [0.762691530853388, 51.64028831099629],
              [0.758475529851194, 51.639579855614151],
              [0.75511370990093, 51.638200959846401],
              [0.75320914132893, 51.636919996362622],
              [0.751407164473705, 51.636949667199339],
              [0.749658945111061, 51.636315220565386],
              [0.751352586447482, 51.637886284050978],
              [0.751367861807878, 51.637613419020646],
              [0.752898045976305, 51.63739136582236],
              [0.75257636985995, 51.63923900767255],
              [0.752878584197605, 51.639551192655446],
              [0.754855089802923, 51.639609138328574],
              [0.755444935481081, 51.639253532603732],
              [0.756968223581522, 51.639512748023854],
              [0.757859910756598, 51.639867730786307],
              [0.757675374524835, 51.640375710798061],
              [0.75842878476203, 51.640643102838787],
              [0.758692729259263, 51.641324012423169],
              [0.759436510394166, 51.641480103397754],
              [0.755805704728203, 51.641126529203554],
              [0.752442346448435, 51.641416809856935],
              [0.752539128533218, 51.641129442040381],
              [0.751964366680382, 51.640923476797745],
              [0.750821217403722, 51.641714735214109],
              [0.750995921470928, 51.642332099355116],
              [0.751844984463136, 51.642150308328652],
            ],
          ],
          [
            [
              [0.750469406076561, 51.642421785261718],
              [0.750572264326577, 51.641733161344447],
              [0.748427792745376, 51.642221391546705],
              [0.747415772308941, 51.64190782704388],
              [0.745969269698748, 51.64105401161536],
              [0.745525541863375, 51.639691068354104],
              [0.741204623986639, 51.639897300231105],
              [0.739115546096282, 51.639722137004739],
              [0.736829950072052, 51.640051563882558],
              [0.735522372749778, 51.639508258260918],
              [0.734228329904038, 51.640078030071329],
              [0.734595481555119, 51.640462495646432],
              [0.733318119555951, 51.640757563753539],
              [0.733154544291917, 51.640273021282859],
              [0.73356953488443, 51.639819072320904],
              [0.732537674974605, 51.639509440188363],
              [0.734832473428617, 51.639260824929487],
              [0.735423017603009, 51.638050013743531],
              [0.735213493461489, 51.637618707744316],
              [0.734501616340114, 51.637557952390566],
              [0.732448818253926, 51.638801015249037],
              [0.732041610926585, 51.637629636849816],
              [0.73417754818379, 51.637048371164987],
              [0.733114172550633, 51.635997506989682],
              [0.73467380273224, 51.636264258009746],
              [0.735926152202511, 51.635315921973501],
              [0.736167569721929, 51.634644766708114],
              [0.736774585285075, 51.634788899557321],
              [0.737289469240646, 51.634363184952264],
              [0.738400709789234, 51.63450454067064],
              [0.738298584715226, 51.63432075600349],
              [0.735525967591406, 51.634334155945687],
              [0.734537508398608, 51.634743917989269],
              [0.7333679788189, 51.634810736176007],
              [0.72913524907537, 51.637248480716885],
              [0.72536788897006, 51.638463826192627],
              [0.720241383452572, 51.638781524020814],
              [0.713828679449227, 51.637288663672955],
              [0.712248457099113, 51.637135430890886],
              [0.711441342935652, 51.63736722878825],
              [0.715375952667494, 51.640469871178993],
              [0.715433627191993, 51.641096287343153],
              [0.716841739942973, 51.64163209179236],
              [0.721145321742768, 51.641711359118659],
              [0.723255791590289, 51.641302663385318],
              [0.723316316827612, 51.640672608037782],
              [0.724162510726743, 51.640947958434786],
              [0.724857731941962, 51.640730362695713],
              [0.725322220383499, 51.641003774335196],
              [0.728595801438927, 51.641166039549802],
              [0.730221640826943, 51.642050969626332],
              [0.731476480289552, 51.642173762472957],
              [0.738092234793405, 51.640682250452059],
              [0.737966010480722, 51.640314658977125],
              [0.73901645113541, 51.640452032316837],
              [0.739237588567491, 51.6399243426414],
              [0.73957576892436, 51.640331053845365],
              [0.740387009442918, 51.640169115315757],
              [0.742742254129424, 51.640490927227383],
              [0.742412969408585, 51.640016564969237],
              [0.74520211688646, 51.639816450668377],
              [0.745659280559434, 51.64097132825232],
              [0.745348412423621, 51.640682710900506],
              [0.745055866553065, 51.641032210683385],
              [0.744118407417849, 51.640827495470219],
              [0.747400735873609, 51.642232848557619],
              [0.749248613670002, 51.642504351484824],
              [0.750469406076561, 51.642421785261718],
            ],
          ],
          [
            [
              [0.778924657903093, 51.693169880411887],
              [0.778555740084494, 51.692888118210725],
              [0.778758213980813, 51.693334805478663],
              [0.778924657903093, 51.693169880411887],
            ],
          ],
          [
            [
              [0.851568106925282, 51.716841523893386],
              [0.851158788227311, 51.716227299512568],
              [0.850315185750526, 51.716649798575993],
              [0.848097459551329, 51.715851006004527],
              [0.848296551154521, 51.716645717467962],
              [0.849026898051403, 51.717138842333718],
              [0.851211185294639, 51.717289983329287],
              [0.851568106925282, 51.716841523893386],
            ],
          ],
          [
            [
              [0.778570500979286, 51.726445549564119],
              [0.77885329174347, 51.725342542259774],
              [0.778127716295582, 51.724775126545396],
              [0.780209204812587, 51.723981200349705],
              [0.78233636773899, 51.723672707043171],
              [0.782611117223787, 51.72376062991561],
              [0.781921265795575, 51.724199671722943],
              [0.782080197275976, 51.72445312311558],
              [0.783090793016132, 51.72456767910468],
              [0.784893037124981, 51.723802770227003],
              [0.784291845912309, 51.723304391023873],
              [0.784485061987698, 51.722913985169036],
              [0.785853302661564, 51.722981364192627],
              [0.78543095561562, 51.722377120507332],
              [0.785748032666803, 51.721928913610697],
              [0.787717622413448, 51.722332754217604],
              [0.786418541162887, 51.721694461964674],
              [0.783710983416116, 51.721015803698052],
              [0.783723258801057, 51.720579323818427],
              [0.782704601701094, 51.720190660699842],
              [0.779623766686473, 51.720198869756786],
              [0.778327613512489, 51.719416522739806],
              [0.772300844328563, 51.718654200854886],
              [0.768976158404869, 51.719337907972566],
              [0.762737930292712, 51.721492193358344],
              [0.757404015389899, 51.722382904437282],
              [0.75600853719402, 51.722961569098537],
              [0.754481099626264, 51.724537996349184],
              [0.754643998897898, 51.725287833295482],
              [0.756241048845674, 51.726735194589914],
              [0.757438665645954, 51.726234921229057],
              [0.759594322507904, 51.726461289118681],
              [0.763779417287991, 51.726268442539656],
              [0.765976189287937, 51.726835480665315],
              [0.769666199198606, 51.726882519604608],
              [0.770214582476156, 51.726258923203737],
              [0.771032207745664, 51.725988713800973],
              [0.770263684046853, 51.725475325627841],
              [0.767009276594299, 51.726085365469018],
              [0.769297924532201, 51.72535960200144],
              [0.770602481704174, 51.725213715007449],
              [0.771017439301596, 51.725510605022585],
              [0.771940787582406, 51.725146160180927],
              [0.772100193979516, 51.726662309036541],
              [0.775527540371759, 51.72639339361222],
              [0.7771258634457, 51.72690511568883],
              [0.777933101022597, 51.726560456967739],
              [0.778570500979286, 51.726445549564119],
            ],
          ],
          [
            [
              [0.72186956116016, 51.730518150557046],
              [0.725356636414061, 51.727839071149027],
              [0.724086043013301, 51.727830776199127],
              [0.723981441597883, 51.728595848738216],
              [0.722930945024153, 51.729189495257529],
              [0.723558121489249, 51.728492348492601],
              [0.723244687038712, 51.727663223796604],
              [0.722688095979648, 51.727414418353092],
              [0.724311355197755, 51.727242772919482],
              [0.723849928859638, 51.72674624361651],
              [0.724927070136602, 51.725609663249642],
              [0.724371882052193, 51.724926448415864],
              [0.724716452087274, 51.724852802366755],
              [0.725334921697336, 51.725576816489976],
              [0.726391314898938, 51.72578073188425],
              [0.72685236633498, 51.726246682288526],
              [0.726225449761792, 51.726489670941426],
              [0.725758863261335, 51.725883548336533],
              [0.72511996594561, 51.725855206158855],
              [0.72432880838935, 51.726593938591662],
              [0.724899283767066, 51.727073546436436],
              [0.725178446855714, 51.726754995484683],
              [0.724615581914708, 51.726594480793914],
              [0.725278550032973, 51.726035892589998],
              [0.726303933400042, 51.72671088732416],
              [0.728579675586776, 51.725783870550998],
              [0.728674163913095, 51.725260952569009],
              [0.727719132994198, 51.724359507337908],
              [0.727989275916004, 51.72369940637558],
              [0.727026769997277, 51.723418679705198],
              [0.726647739044285, 51.722943630997108],
              [0.72559051638607, 51.722869243782021],
              [0.725446467603213, 51.72230419881447],
              [0.724590516233519, 51.722518319060519],
              [0.724156419661888, 51.722066124391539],
              [0.725062984457168, 51.721971345469207],
              [0.725309146937863, 51.721489878074202],
              [0.726685323576614, 51.720993870402253],
              [0.726851761164709, 51.721160882158728],
              [0.725896383203094, 51.721671407688106],
              [0.726679498134799, 51.72207592184067],
              [0.727215171127455, 51.722916065814609],
              [0.728310059264928, 51.722918514866421],
              [0.729450483405496, 51.721465648304907],
              [0.729214638791982, 51.720266901177119],
              [0.729633951906866, 51.719367699385884],
              [0.728366534357609, 51.718710948385727],
              [0.729039257886148, 51.718340973625068],
              [0.726519843926901, 51.717113425282868],
              [0.725393578935361, 51.717260980830979],
              [0.724612241636402, 51.717004809946467],
              [0.724155607367749, 51.717212360801128],
              [0.724868935598323, 51.717469213427364],
              [0.723957792306603, 51.717654932084152],
              [0.722853866487682, 51.718439582748537],
              [0.72178879110851, 51.718642343444699],
              [0.721727393812847, 51.718222871569822],
              [0.718050407703441, 51.719079672608203],
              [0.717422513902535, 51.718991677779371],
              [0.717208984658573, 51.719270020036177],
              [0.712726290356872, 51.71811186972667],
              [0.710433414626147, 51.718557825791926],
              [0.708187233734443, 51.719664578115825],
              [0.710408643218959, 51.720055808427134],
              [0.712727367176088, 51.722047383842636],
              [0.713689653228614, 51.722133073829369],
              [0.713692709016283, 51.722740061829498],
              [0.71484898101823, 51.723670242687739],
              [0.715196091755884, 51.725233375832616],
              [0.7158383854986, 51.725874148069515],
              [0.716250162798789, 51.725858329767931],
              [0.716437562628078, 51.726302769144972],
              [0.716002751587899, 51.726489996488205],
              [0.717214201403737, 51.727637419884118],
              [0.717559782889843, 51.72849729300281],
              [0.720134444695681, 51.730589826245392],
              [0.721186875669712, 51.7308226600355],
              [0.72186956116016, 51.730518150557046],
            ],
          ],
          [
            [
              [0.886017455020992, 51.736758395316215],
              [0.88334927743274, 51.735008094494312],
              [0.883127109125727, 51.735204216301504],
              [0.880768753587125, 51.732697072736109],
              [0.877946498872017, 51.730634751237872],
              [0.878036627820273, 51.731095720305092],
              [0.879166572626728, 51.731827994895454],
              [0.879433804739139, 51.732750496887462],
              [0.88010784520164, 51.733009177111839],
              [0.88086507734277, 51.734506949743192],
              [0.881744748456363, 51.734536632172514],
              [0.881568200752285, 51.734903412108558],
              [0.882533016727922, 51.735337516921113],
              [0.883636582360585, 51.737267464988697],
              [0.885150821948941, 51.738297833514643],
              [0.885893768711362, 51.738294869228838],
              [0.886557721044645, 51.737371082861365],
              [0.886017455020992, 51.736758395316215],
            ],
          ],
          [
            [
              [0.843409778374412, 51.738258157328815],
              [0.842012087891259, 51.738192157450364],
              [0.841733276720034, 51.738481305049163],
              [0.842763315518123, 51.739682215224398],
              [0.845102381085193, 51.739075163651094],
              [0.845461241334048, 51.738861414152993],
              [0.845165928216702, 51.738657217104439],
              [0.843409778374412, 51.738258157328815],
            ],
          ],
          [
            [
              [0.873614068458561, 51.763669539283455],
              [0.883557998518714, 51.761160347199599],
              [0.882614628822597, 51.760776085586052],
              [0.880489037341596, 51.760785058034919],
              [0.878681079834891, 51.761594742943458],
              [0.87775695644958, 51.761626379636901],
              [0.877160121200119, 51.762246275951441],
              [0.876760357003142, 51.762068098229605],
              [0.872481569596474, 51.763456206420784],
              [0.872194180670351, 51.763746534945582],
              [0.872508010344954, 51.763712779577894],
              [0.873614068458561, 51.763669539283455],
            ],
          ],
          [
            [
              [0.70476179746668, 51.822676431539882],
              [0.708881416763873, 51.818895194416875],
              [0.709834727143702, 51.818434344732424],
              [0.710764167630191, 51.817334611024314],
              [0.710538925257452, 51.817184220820167],
              [0.71310240422448, 51.815846221449384],
              [0.71708376562197, 51.812585890457065],
              [0.7216360129308, 51.809996594553979],
              [0.720147460328194, 51.80980442321961],
              [0.717288796192217, 51.810760894224686],
              [0.716360395938373, 51.810257143096429],
              [0.715027584241126, 51.810507377887298],
              [0.713302252472026, 51.8084508707284],
              [0.71732931255647, 51.806949469219184],
              [0.719008517767332, 51.805153327300957],
              [0.71867204087404, 51.804190726779041],
              [0.718992650368463, 51.801997919802687],
              [0.718472569270998, 51.800828222033388],
              [0.718779583003554, 51.798361430987725],
              [0.718143156902678, 51.79735450540862],
              [0.721540739207293, 51.796497941878194],
              [0.722598228579767, 51.797270260581172],
              [0.726829115882711, 51.797485132750133],
              [0.733610899825729, 51.799321409205255],
              [0.733826928914805, 51.798408949293275],
              [0.737620770443517, 51.798541867516931],
              [0.742834511885137, 51.800200990043372],
              [0.746467057160059, 51.799443441915294],
              [0.754202387633563, 51.800960364850894],
              [0.759315936920195, 51.80231803172213],
              [0.760298301696014, 51.802987457063359],
              [0.763372610048783, 51.803230876687863],
              [0.768594445553069, 51.805849178399328],
              [0.770342897921017, 51.805901506579687],
              [0.772146207461241, 51.807343803571683],
              [0.773423088236356, 51.807890158140907],
              [0.774266442786092, 51.807897233040997],
              [0.774863867069826, 51.808907484328955],
              [0.779061531467753, 51.81155400406432],
              [0.790604401120786, 51.806846695211625],
              [0.793056432239818, 51.804996938481587],
              [0.794765659804918, 51.804251211727639],
              [0.797901370976559, 51.803982337006062],
              [0.80111859969178, 51.801472947904607],
              [0.808926567861918, 51.800412401245026],
              [0.808995781171407, 51.799822569438923],
              [0.811826438125016, 51.799110034116261],
              [0.809895223148001, 51.795043481791232],
              [0.808083235289028, 51.79330967317469],
              [0.810632775839836, 51.792887176959795],
              [0.813375990101113, 51.79154624277124],
              [0.813852461826033, 51.791675133094408],
              [0.816647687269956, 51.791031674950403],
              [0.815900705790726, 51.789238275156876],
              [0.811539291240645, 51.790114362505868],
              [0.805702235275335, 51.791939274258581],
              [0.803900991290272, 51.789696112044183],
              [0.816310606579938, 51.787531365508727],
              [0.816129923745376, 51.786353941571555],
              [0.819600806530279, 51.786133099812815],
              [0.821764954303269, 51.785189882735608],
              [0.823469073620064, 51.784845860737697],
              [0.825640290475793, 51.783519274060417],
              [0.834463969522717, 51.782678086144074],
              [0.835810775143891, 51.780801897576339],
              [0.836534136169305, 51.780383320893939],
              [0.836668747979636, 51.779594029274946],
              [0.839329885931002, 51.7796718474549],
              [0.84021082686001, 51.779246741181353],
              [0.842410173773691, 51.779451669707889],
              [0.84358213239147, 51.778905277768189],
              [0.844404909025398, 51.778870071905217],
              [0.842879555118647, 51.780436806399507],
              [0.843278032949316, 51.780755431037058],
              [0.844581733577451, 51.779998088393242],
              [0.847025653197826, 51.78164498351552],
              [0.850033026000742, 51.782865354824565],
              [0.857902670698994, 51.784040328721041],
              [0.859283168695484, 51.782298847082195],
              [0.863680127265479, 51.782192658064666],
              [0.871678041223848, 51.779882132900006],
              [0.877204359391314, 51.779344907606131],
              [0.883185580823857, 51.774984727654697],
              [0.883498426573089, 51.775048099732075],
              [0.882368914805629, 51.77625129405692],
              [0.882804891258713, 51.776199225778917],
              [0.890672383096289, 51.769703109420192],
              [0.890237929059818, 51.769437691798217],
              [0.890578507395142, 51.768914871095554],
              [0.888837755835118, 51.768623124696987],
              [0.888708217631901, 51.768387976534896],
              [0.886435435114755, 51.769253277003521],
              [0.886276553961533, 51.770102589872288],
              [0.887021060214124, 51.770569956309316],
              [0.88660344375595, 51.770795168386186],
              [0.884935615497907, 51.769602200686421],
              [0.884930676649369, 51.768252365656721],
              [0.887790824898691, 51.767709921045316],
              [0.891551035467592, 51.768028438734632],
              [0.894579745980845, 51.766293606372223],
              [0.891753868631242, 51.765737240274483],
              [0.891607395326673, 51.765441354688228],
              [0.889179285196353, 51.764688965285416],
              [0.887905335802163, 51.764758964092223],
              [0.887533455003843, 51.764133149005879],
              [0.88638109753704, 51.76381431182979],
              [0.88307280724377, 51.76384878670514],
              [0.879275931766748, 51.764632628861364],
              [0.877904573203246, 51.765290396623627],
              [0.877240834399315, 51.765155561253934],
              [0.874823154911222, 51.766204958788812],
              [0.872794191530612, 51.766598153522757],
              [0.872065554220465, 51.767609772839592],
              [0.870845621758483, 51.767733126242561],
              [0.870388066855529, 51.768243456699651],
              [0.868193432334994, 51.768494920782786],
              [0.867001240787943, 51.768141790883099],
              [0.863683207277202, 51.768439468409078],
              [0.863535609620496, 51.76821462694253],
              [0.86330532874982, 51.76860696790196],
              [0.860331303057983, 51.768844001665308],
              [0.859677650329323, 51.769212466747184],
              [0.859498563235322, 51.769841885767477],
              [0.860156351139703, 51.772976353497135],
              [0.858414858661717, 51.773337113995638],
              [0.857404622592731, 51.774992239353494],
              [0.856217150357136, 51.774944668805006],
              [0.854578504787643, 51.77408872806852],
              [0.853281939887126, 51.774448492915973],
              [0.851224763706864, 51.774081131988886],
              [0.850914461477403, 51.773321504509632],
              [0.850041437758756, 51.773063883839136],
              [0.851794295299759, 51.772445753911995],
              [0.850624574066969, 51.771896750440902],
              [0.851414900796917, 51.771785835547071],
              [0.851774910682094, 51.771241974927634],
              [0.851339884681776, 51.770504262627746],
              [0.850998080808078, 51.770785065621148],
              [0.850166806460471, 51.770476068634665],
              [0.84825610622273, 51.771533294157905],
              [0.846259976804164, 51.772248101028033],
              [0.844876198106, 51.77205138113046],
              [0.844611547183493, 51.772499376993139],
              [0.844074861252923, 51.772448515890822],
              [0.844369638967805, 51.772064546046408],
              [0.843943654587141, 51.771537938946672],
              [0.842508102640243, 51.773169051175962],
              [0.842118645407659, 51.773154190727041],
              [0.841869366920527, 51.772670967553196],
              [0.841054406493438, 51.772667295066341],
              [0.8404708790256, 51.772793825342355],
              [0.83983385053498, 51.773615054436924],
              [0.838768414460232, 51.773501402493025],
              [0.837738131656301, 51.773993961216831],
              [0.836696285218059, 51.773863532122441],
              [0.836884004723222, 51.773389532466084],
              [0.837577265670917, 51.773163238735407],
              [0.835886846304036, 51.772052872340787],
              [0.835449607402132, 51.772316146698564],
              [0.835235752988714, 51.772142334926073],
              [0.835043437113512, 51.77092474327744],
              [0.834191871238446, 51.770752826770206],
              [0.834554632187904, 51.770434694199636],
              [0.835735151781305, 51.771298371877506],
              [0.835472669916318, 51.771944153638252],
              [0.836028941633654, 51.771845287053424],
              [0.836960451532729, 51.772574663205994],
              [0.839328157564083, 51.773203677426125],
              [0.839747918128098, 51.773100897528991],
              [0.839742913942995, 51.771659340258381],
              [0.838890066022634, 51.771028813653572],
              [0.839739285062502, 51.771162979764355],
              [0.840242722798774, 51.771700320247461],
              [0.841245517613281, 51.771717442894662],
              [0.841301989145896, 51.772270084689673],
              [0.842452568795367, 51.772239554063198],
              [0.843609814309623, 51.771070256754662],
              [0.846638386451114, 51.771373744454372],
              [0.849530064344455, 51.770010354872589],
              [0.854176717242691, 51.768665423940341],
              [0.854323664970203, 51.768352471857611],
              [0.85522487528704, 51.768345860950554],
              [0.856173678244351, 51.767715724680833],
              [0.852382713554781, 51.767364445384921],
              [0.85208301812238, 51.768105603941102],
              [0.850516924861591, 51.768234450794772],
              [0.849723802415216, 51.769634217828383],
              [0.849652350033639, 51.768845409431869],
              [0.849087657705436, 51.769522836946166],
              [0.848397543585401, 51.769383082178798],
              [0.848992408452365, 51.768310105213466],
              [0.850122926900068, 51.767663919876334],
              [0.850442616586379, 51.767975437963464],
              [0.851961921414729, 51.767725414216351],
              [0.850925701462811, 51.767176770885769],
              [0.849875770450416, 51.76723822073928],
              [0.846900430085519, 51.768996670732079],
              [0.844990882970641, 51.769450342072624],
              [0.845008111835911, 51.769056003443126],
              [0.844418499698465, 51.769038803081145],
              [0.843919561747538, 51.768573320317245],
              [0.844049803477249, 51.769537882736792],
              [0.842610461664665, 51.769101452610577],
              [0.843713750179034, 51.769818524981716],
              [0.843500282616845, 51.77022750625396],
              [0.842267651951864, 51.770012707878152],
              [0.840936112385577, 51.770207700038007],
              [0.840911121630529, 51.769879137486932],
              [0.842139289156734, 51.769769387843695],
              [0.843290503749804, 51.770026629369596],
              [0.842113485392436, 51.769151250112543],
              [0.84237847349409, 51.768893017274863],
              [0.843528894706688, 51.768907447303725],
              [0.843033918638274, 51.768758440964106],
              [0.843149779897879, 51.768457048263436],
              [0.84200941557111, 51.768602458022499],
              [0.842134067538833, 51.768417770967019],
              [0.843703957650764, 51.767887828109068],
              [0.844557648423548, 51.767909430250519],
              [0.84432944123398, 51.768129903763615],
              [0.844833246575206, 51.768258005444892],
              [0.845311853068492, 51.767501741012666],
              [0.845186900274095, 51.766921079982353],
              [0.842243106332352, 51.764824872988903],
              [0.844237946104936, 51.765499680392914],
              [0.844445469853704, 51.765319279710141],
              [0.844479010641976, 51.765967808550052],
              [0.846869074744918, 51.765850554308919],
              [0.847296755326356, 51.766427474306049],
              [0.849376360016716, 51.766121631910245],
              [0.854539618169938, 51.766147375685009],
              [0.851447400204352, 51.765588420799787],
              [0.851780137409185, 51.7649345992862],
              [0.853032178670462, 51.764562439040958],
              [0.852304759390458, 51.76357731049049],
              [0.852648687297259, 51.763514997209583],
              [0.853339459343962, 51.763919124021591],
              [0.853610389008537, 51.764790324240778],
              [0.852017111792898, 51.765152788319824],
              [0.85243142151206, 51.765562732793192],
              [0.853734724683816, 51.765727141305163],
              [0.855636365518355, 51.765197970618019],
              [0.857303406940425, 51.765429943077677],
              [0.860705308667188, 51.764284097215494],
              [0.858587333889582, 51.763292389691891],
              [0.856430203409198, 51.763629961456374],
              [0.855990125023185, 51.763477876176296],
              [0.857605074086899, 51.76311572941205],
              [0.857672389290328, 51.762712974214246],
              [0.856279247345884, 51.761813313719287],
              [0.855192294428582, 51.760528461247297],
              [0.85469647161283, 51.761306772044662],
              [0.854027249469415, 51.761335624424611],
              [0.85450063820437, 51.760890627376135],
              [0.853615035519082, 51.760682805727676],
              [0.853841541888487, 51.760206934397132],
              [0.854723630463028, 51.760267343529378],
              [0.853078124503503, 51.758925894159567],
              [0.852131561555305, 51.75912965336753],
              [0.851603017257203, 51.758791732454803],
              [0.850938010426022, 51.759093872541449],
              [0.851415347450857, 51.758320523337837],
              [0.85073664374046, 51.757968256214561],
              [0.849860031447124, 51.758592102277838],
              [0.849371404494026, 51.758519415390559],
              [0.84937838572491, 51.757824935496004],
              [0.850328149065479, 51.757120173659573],
              [0.85126431786508, 51.757119937760223],
              [0.851132786176023, 51.757953245561374],
              [0.851665451283295, 51.757253199567927],
              [0.852385102104016, 51.757335556399148],
              [0.852438305301926, 51.758524126465865],
              [0.854008539410611, 51.758673054396134],
              [0.85469599959198, 51.759553917796282],
              [0.856250412886081, 51.760277896118872],
              [0.856992416083382, 51.759726527185244],
              [0.856666560041071, 51.760648209010597],
              [0.857629138448851, 51.761638383368876],
              [0.858793190548542, 51.76120624430343],
              [0.859484917140806, 51.761648085439269],
              [0.859805692373643, 51.761517962595086],
              [0.858689564647992, 51.760300405074965],
              [0.860687787609881, 51.76164667896883],
              [0.859700265862118, 51.762097023715029],
              [0.858610401105566, 51.761616307282686],
              [0.858231084191202, 51.761714574662186],
              [0.861804531030837, 51.763101636150374],
              [0.862086267720964, 51.762652283241067],
              [0.861403972026913, 51.762566377013833],
              [0.861384313801509, 51.762209806876768],
              [0.864013366743631, 51.762437133077476],
              [0.868820754805376, 51.761200111892101],
              [0.871547771966218, 51.759591960929569],
              [0.872644691889615, 51.759680260248736],
              [0.873187287697609, 51.7601454547491],
              [0.873869136732547, 51.759882347610507],
              [0.873186626875163, 51.759678697795785],
              [0.875614580061595, 51.758841330211382],
              [0.87556020721715, 51.759194315219304],
              [0.876057506428886, 51.759197429607021],
              [0.875873351691139, 51.758717177495861],
              [0.876744416161704, 51.758399055806478],
              [0.876970449282454, 51.758648941359759],
              [0.876228642442898, 51.758948610490677],
              [0.876579789178154, 51.759023653383686],
              [0.880412835761068, 51.757858572772378],
              [0.880742407559418, 51.757913446702105],
              [0.880265489852674, 51.758139192320897],
              [0.882681688089259, 51.757643726012425],
              [0.880789098957424, 51.756962564934454],
              [0.880122646979603, 51.75630527828455],
              [0.877953185913303, 51.755940244484748],
              [0.87396818319911, 51.753616706966532],
              [0.87237560468154, 51.753479385946328],
              [0.872374960668307, 51.753126848348721],
              [0.870581711071419, 51.752521341334869],
              [0.869980025213162, 51.750985529543378],
              [0.868091325926474, 51.750403902462693],
              [0.865384116342482, 51.748702714142723],
              [0.864366469646628, 51.748557554661986],
              [0.864305004597704, 51.748112966117951],
              [0.863295210660661, 51.748045851154409],
              [0.861394876146806, 51.746062284528968],
              [0.860191932355346, 51.745803786101639],
              [0.858586119147561, 51.744717768469265],
              [0.858129280446942, 51.744825118703972],
              [0.856954518998452, 51.744117116585933],
              [0.856227123280689, 51.744138405264437],
              [0.856219828635988, 51.743793224999216],
              [0.855584522577196, 51.743965162943894],
              [0.854736668691011, 51.743684485033107],
              [0.85340926490044, 51.744414650676632],
              [0.850320821901374, 51.744443773150728],
              [0.849799522762922, 51.744816169807692],
              [0.850440737655248, 51.746094800192502],
              [0.849831665446946, 51.746569156607812],
              [0.850207142031471, 51.746848742728034],
              [0.851140706206688, 51.746741544317281],
              [0.849961564724546, 51.74748156078698],
              [0.849343066510355, 51.747231253093268],
              [0.84740763899374, 51.747396904759022],
              [0.847120228520834, 51.745780072091009],
              [0.847455700258201, 51.747032395396893],
              [0.84792956309732, 51.747332987040103],
              [0.848720846265603, 51.747036793231182],
              [0.849436773433276, 51.746901610137158],
              [0.849908921950373, 51.745932328654405],
              [0.847464165663582, 51.745232556703691],
              [0.8470589431653, 51.74543529144033],
              [0.846945690509581, 51.744798139623818],
              [0.847628124731457, 51.744637697429511],
              [0.848871854025009, 51.745223603634102],
              [0.849671572445143, 51.744003547764223],
              [0.852178627423601, 51.743508299184136],
              [0.853048993546777, 51.74207784979258],
              [0.852401998748548, 51.74160430804767],
              [0.849738118583494, 51.741171622474056],
              [0.847737475255688, 51.740296497255478],
              [0.847158369779885, 51.740463429647747],
              [0.844918023472841, 51.740083323409451],
              [0.843225518967108, 51.740398614909488],
              [0.84265120167225, 51.740988111368118],
              [0.841674521587378, 51.740845363108413],
              [0.841377683124481, 51.73999364955332],
              [0.842065611033361, 51.739944627711395],
              [0.841474204540478, 51.738761880087679],
              [0.837737243414974, 51.738353082311406],
              [0.837483949476469, 51.738656892229876],
              [0.836433044793947, 51.738484416294121],
              [0.834812922516479, 51.738729486622248],
              [0.833959792068056, 51.739147594752787],
              [0.832135719138619, 51.738726622387155],
              [0.827144617262182, 51.738669695876709],
              [0.825036684295593, 51.73838765289608],
              [0.823903336526164, 51.738889772223594],
              [0.822585162081203, 51.740819114930623],
              [0.822217821004172, 51.740155218651616],
              [0.822855356400406, 51.740343153717518],
              [0.823007934282132, 51.740002225268427],
              [0.822139078363495, 51.739961049557138],
              [0.82163755827778, 51.738840800954691],
              [0.813664463782675, 51.73851494436429],
              [0.813196640501352, 51.739052277353167],
              [0.811187470394353, 51.738843166884465],
              [0.809167298152942, 51.740230650923152],
              [0.805627612280076, 51.741131877313023],
              [0.80536307410416, 51.741678711457659],
              [0.804227480133985, 51.740769595925322],
              [0.798760662340214, 51.742182476407706],
              [0.796966491035812, 51.742382602751022],
              [0.793341545984904, 51.744138975068253],
              [0.792259746544345, 51.745155788982757],
              [0.791630178196879, 51.744720175746473],
              [0.791435544545759, 51.742785787302353],
              [0.790052542787387, 51.742441816767553],
              [0.790176257292609, 51.740053782429889],
              [0.789017269429692, 51.737630560430972],
              [0.789265923195872, 51.737528424302504],
              [0.790431270807273, 51.738503527182964],
              [0.790129395999641, 51.739058407551944],
              [0.79159047527529, 51.739542619223776],
              [0.792734406035387, 51.740474140377529],
              [0.794204653004615, 51.740354632808234],
              [0.795352033472103, 51.740824676163456],
              [0.796649556463553, 51.740582439102269],
              [0.796660782287964, 51.740013777194832],
              [0.794055931963789, 51.739189005179831],
              [0.793580533788842, 51.738762326013202],
              [0.791848022614658, 51.738692000579817],
              [0.788604606844873, 51.736645669521856],
              [0.781129761087337, 51.738171041020777],
              [0.778536899602107, 51.739305339616841],
              [0.776309499829892, 51.740778991946804],
              [0.776321801698219, 51.741215787623489],
              [0.777002331633519, 51.740785097110432],
              [0.77739661632304, 51.741355858298128],
              [0.777077144079722, 51.74219351681527],
              [0.776597523462831, 51.74205016674663],
              [0.776134755500897, 51.742276051200022],
              [0.777026950604073, 51.742390762908983],
              [0.777543607457609, 51.742948737401761],
              [0.77671563201025, 51.743975591113156],
              [0.776059188977573, 51.743713207127307],
              [0.775673763502516, 51.743027114858407],
              [0.773799023086828, 51.742765622208459],
              [0.773081147810584, 51.742162018644159],
              [0.771846547494992, 51.741849405911651],
              [0.771377743196645, 51.740864883484541],
              [0.771672772094615, 51.740265247503409],
              [0.770739944869487, 51.739651413234995],
              [0.770773771678467, 51.739352029710822],
              [0.771899509791085, 51.739307480648627],
              [0.772369761940649, 51.739889955619738],
              [0.772400949664027, 51.739453032620752],
              [0.772866882432444, 51.73930263377796],
              [0.772967850881696, 51.739792196923567],
              [0.774138918616521, 51.739116108646435],
              [0.774822901440601, 51.739427316731593],
              [0.774717330088787, 51.738886600502006],
              [0.772281765132976, 51.739162656303336],
              [0.770656266574992, 51.739136258603594],
              [0.770215016567823, 51.73879052240801],
              [0.767430804584786, 51.738884936263318],
              [0.764701401063294, 51.740659783830196],
              [0.762507022693566, 51.74111612384624],
              [0.76041119826314, 51.742476653004914],
              [0.760164519579162, 51.741828621229708],
              [0.763068456145534, 51.740322279853721],
              [0.763149328361381, 51.738704240549502],
              [0.759778510038306, 51.737492921653313],
              [0.757963746283741, 51.737259447966949],
              [0.755148574259933, 51.735296581349225],
              [0.754279594523917, 51.735036364866772],
              [0.746063300912612, 51.736745957154312],
              [0.744971268535508, 51.736437825153381],
              [0.744333589006214, 51.734544306146439],
              [0.744973446304533, 51.733439341209028],
              [0.745026701999669, 51.734604553692115],
              [0.747712724206712, 51.735330476328286],
              [0.748757044718951, 51.735210709152511],
              [0.748940715159603, 51.734323248043765],
              [0.747740174271132, 51.733897168689367],
              [0.748298288477529, 51.733141246512361],
              [0.747259279440945, 51.733013564376179],
              [0.74850533798326, 51.73309233319371],
              [0.748128845847418, 51.733809831554623],
              [0.749271429128573, 51.734140133738975],
              [0.749353751906853, 51.734828008811455],
              [0.749980980700328, 51.734590285555228],
              [0.749040362725562, 51.732457460581578],
              [0.748412737918373, 51.732139389475122],
              [0.747360710450264, 51.732250341026798],
              [0.747545461379814, 51.73192944767451],
              [0.747644441460808, 51.732176252305202],
              [0.74839127260127, 51.732000492382497],
              [0.74915269739491, 51.732256074589259],
              [0.750201946120803, 51.733291844811539],
              [0.752061650071691, 51.732742847435219],
              [0.75050166629654, 51.732503289899419],
              [0.746099921123086, 51.731068401461428],
              [0.744634265311466, 51.730902106502377],
              [0.741118404517435, 51.731535517764137],
              [0.739380034769534, 51.731050856593136],
              [0.739295425198128, 51.731591540358799],
              [0.740277697585368, 51.73226652153523],
              [0.740462227454799, 51.731941148191012],
              [0.740667433819465, 51.73229250231487],
              [0.743435120213138, 51.731429262705106],
              [0.744364895922557, 51.731428225641338],
              [0.739050026380678, 51.732993953145971],
              [0.73812055721857, 51.732952671250978],
              [0.737927185200662, 51.733299831183857],
              [0.737244534808662, 51.732956902549553],
              [0.737692470701822, 51.732939267445765],
              [0.737445077059651, 51.732728290707051],
              [0.737706492984617, 51.73053587965444],
              [0.738125775631924, 51.730619637714184],
              [0.738715092911873, 51.729965571031265],
              [0.739372824755228, 51.729806324914165],
              [0.738747383524271, 51.729929732623141],
              [0.738586301426314, 51.729996752645434],
              [0.73752719200168, 51.730253166621836],
              [0.736395677571226, 51.729527679333572],
              [0.735488553347573, 51.729471467010313],
              [0.732241287249827, 51.730788156180523],
              [0.730514198674951, 51.731161080249429],
              [0.726806778913826, 51.7338121522005],
              [0.721722525179157, 51.736168552671117],
              [0.718614453506703, 51.7367540599816],
              [0.715231329683778, 51.736425813389367],
              [0.715219148701688, 51.737213021532547],
              [0.713994569025548, 51.737541704788704],
              [0.712606836803048, 51.737057538709927],
              [0.714709315181577, 51.73699637275336],
              [0.71483619571234, 51.73669665642489],
              [0.712251238342955, 51.73697311819712],
              [0.713420188569701, 51.737553176625816],
              [0.712861807980397, 51.737493227916119],
              [0.711162452357318, 51.736812090938209],
              [0.713053043033163, 51.736381718067072],
              [0.712294828756209, 51.736467579548865],
              [0.711741793198228, 51.735166407270718],
              [0.713705318651204, 51.734890825178297],
              [0.714603935257363, 51.735045431807499],
              [0.714577030732923, 51.734113433081816],
              [0.712318185929893, 51.731078167971745],
              [0.709644582438318, 51.729214383815133],
              [0.709924873872081, 51.728840084900476],
              [0.708726532839176, 51.726698509563924],
              [0.708059014726635, 51.726215666183506],
              [0.707860321128768, 51.724878425210697],
              [0.70534721706368, 51.723849934056375],
              [0.705646308125103, 51.724008523369292],
              [0.704630142837589, 51.724330511661719],
              [0.703569333490212, 51.725481814581244],
              [0.703224507607464, 51.726837868363319],
              [0.702147642058953, 51.727915780618005],
              [0.701825823157751, 51.727570641769127],
              [0.700462236000255, 51.728066963155136],
              [0.700816562623885, 51.728374485052129],
              [0.698742046163885, 51.728363714847767],
              [0.697225942517777, 51.72897502097922],
              [0.695535158793531, 51.729255760998576],
              [0.694569654136028, 51.729093550943041],
              [0.692878718964621, 51.729493868409961],
              [0.691798977417728, 51.730284858662273],
              [0.692214415160748, 51.73152362247238],
              [0.691240177996726, 51.73045954569173],
              [0.690464396491705, 51.730980050943081],
              [0.690675656056882, 51.731269295932464],
              [0.69002817228631, 51.732365135020515],
              [0.689891840409515, 51.73170364449421],
              [0.689386281291853, 51.731604602862319],
              [0.690801899521874, 51.730176402293687],
              [0.689530150925957, 51.730417775851897],
              [0.688408705423296, 51.731971430603878],
              [0.686443055946836, 51.733212529131748],
              [0.687322099110654, 51.733747312908434],
              [0.689300520038465, 51.734062665858453],
              [0.690238188444437, 51.734510648476011],
              [0.69054148487274, 51.735568520474985],
              [0.690083917815506, 51.73554662477364],
              [0.689517950532438, 51.734553223847492],
              [0.685859209910876, 51.734260122577233],
              [0.685192231672095, 51.733051371116609],
              [0.682927310170043, 51.733595068076482],
              [0.682791617843174, 51.734116187025009],
              [0.682424270809104, 51.733734263794524],
              [0.68044702069488, 51.734269543393545],
              [0.677228592946806, 51.734611849391875],
              [0.676188412142804, 51.735319051196313],
              [0.6766463046124, 51.735420135352335],
              [0.675935706517381, 51.736091944028281],
              [0.675256944285033, 51.735856490542211],
              [0.673081418511374, 51.736176721842341],
              [0.665456534237848, 51.738807885521609],
              [0.664155230656301, 51.739341035909085],
              [0.665044123040067, 51.741276026976074],
              [0.66382116639159, 51.743609662768755],
              [0.662933003515976, 51.743755707608699],
              [0.662234694945485, 51.743508930391862],
              [0.662049568819273, 51.742750490088973],
              [0.663294120324938, 51.743023555954913],
              [0.664450048719428, 51.741839897288685],
              [0.664498252053246, 51.740862125660477],
              [0.664093182487405, 51.740600613537822],
              [0.66438434352348, 51.741191168232],
              [0.663382713631292, 51.741894680420941],
              [0.663816858034245, 51.741271489154968],
              [0.66354670446127, 51.739708275249484],
              [0.664124835833565, 51.738898352856928],
              [0.669711105246097, 51.736697647073491],
              [0.674858939228796, 51.73539610069119],
              [0.6769536367335, 51.7342484882506],
              [0.678988792757794, 51.734250651021178],
              [0.686598768517715, 51.731931005072688],
              [0.687363573316804, 51.731419770531431],
              [0.686940701357019, 51.731078702574628],
              [0.68743833966411, 51.731117680078299],
              [0.687191802754311, 51.73081934381802],
              [0.68864873337787, 51.728549340421893],
              [0.689301435925082, 51.727907556040343],
              [0.691687713587105, 51.726755694296578],
              [0.69738077729183, 51.726759981554785],
              [0.699290006233184, 51.726161222140767],
              [0.699142988844584, 51.725929872438705],
              [0.697040518502109, 51.726469213984309],
              [0.699031358376746, 51.725028597981726],
              [0.698365328669449, 51.725006129944468],
              [0.69780123854197, 51.725503834824067],
              [0.697259311630247, 51.725401166161923],
              [0.697624459029472, 51.724529410260793],
              [0.696935983753629, 51.724081161166779],
              [0.697877789997376, 51.723287893576291],
              [0.697144314903347, 51.723129368654206],
              [0.697315838074771, 51.722824149418337],
              [0.698330091721618, 51.722567021221614],
              [0.699385338913399, 51.721588567634946],
              [0.699289854295754, 51.722537771176704],
              [0.700175763040829, 51.722362718934562],
              [0.7003887303554, 51.721489955454111],
              [0.703496388421065, 51.720862663045615],
              [0.703752793369818, 51.720427772891291],
              [0.702848812076614, 51.719789361816012],
              [0.704386170476253, 51.720049853965868],
              [0.704961859029587, 51.719675958298133],
              [0.70470317762911, 51.719490355756129],
              [0.705326175993557, 51.719496690880298],
              [0.706068405934741, 51.71861442037109],
              [0.706746471221269, 51.71837935373977],
              [0.706532584996216, 51.717925617154023],
              [0.70691385987421, 51.718224405541761],
              [0.709072591202102, 51.717350826302464],
              [0.709294496543087, 51.716315955900697],
              [0.710104598083242, 51.716546382480018],
              [0.709945347568802, 51.7163045359652],
              [0.710426285449219, 51.716308725676477],
              [0.710972773002813, 51.715545157096123],
              [0.710692333525995, 51.716084943112634],
              [0.711005321674721, 51.716282769103181],
              [0.712034492816774, 51.715746378434943],
              [0.711629554696868, 51.714544309524968],
              [0.709756756743425, 51.714264673699638],
              [0.710155356680219, 51.713909226963537],
              [0.711118881011541, 51.71384921384734],
              [0.71117931338064, 51.713479985849823],
              [0.711455908414333, 51.713844130577812],
              [0.711814649375005, 51.713476108152861],
              [0.712312773087551, 51.713511890868645],
              [0.711725311979235, 51.713870286358507],
              [0.71324257539683, 51.715320961368711],
              [0.713940428202689, 51.715514383629944],
              [0.714061578743356, 51.714805596320154],
              [0.713446295873556, 51.714564400296773],
              [0.713421377401555, 51.714075731293086],
              [0.713531806771446, 51.714541739440918],
              [0.714068021699612, 51.714671444699086],
              [0.714756121466378, 51.713928867003808],
              [0.714123167575599, 51.713851764276221],
              [0.715437781960842, 51.713693812467199],
              [0.714961887410542, 51.71405271666864],
              [0.715313108378122, 51.714236157125697],
              [0.71493533938123, 51.714092901720576],
              [0.714439578273297, 51.714420932449656],
              [0.714270097254328, 51.714999532658609],
              [0.714868990179765, 51.714822007560763],
              [0.714192581421103, 51.715083164860424],
              [0.714353712839512, 51.715476951984364],
              [0.715374751070478, 51.715868845765932],
              [0.715782600875252, 51.715740700700941],
              [0.715633429208539, 51.71545006380623],
              [0.715903835487067, 51.715734299573576],
              [0.715568645423739, 51.716011855217126],
              [0.716154341922986, 51.716072955841298],
              [0.71637581490684, 51.71570989291267],
              [0.716400143034457, 51.71609155279323],
              [0.717807384543557, 51.716235265235667],
              [0.718267272481305, 51.715936829807063],
              [0.718056964955284, 51.715592750760557],
              [0.718441033337452, 51.71576822697444],
              [0.718704110044201, 51.715447365074262],
              [0.718837038257588, 51.715780643652451],
              [0.719901688207725, 51.715716418648775],
              [0.722022525764339, 51.71429037594261],
              [0.726848145451817, 51.713209821629498],
              [0.72914448207211, 51.713020674273878],
              [0.729827191358313, 51.712577614489398],
              [0.731216223899036, 51.712828611534349],
              [0.732518333132921, 51.712478150742847],
              [0.732746869702951, 51.712954884505585],
              [0.735014954822957, 51.712777970901548],
              [0.736786360694042, 51.711736621650992],
              [0.737643632373505, 51.711764304601161],
              [0.737937992090941, 51.712131571988508],
              [0.73892592269243, 51.712356754585372],
              [0.740011475441021, 51.711754046102151],
              [0.74429056804892, 51.710863590821248],
              [0.745700236062495, 51.711502454883139],
              [0.746977600909787, 51.711462687633045],
              [0.745436775308685, 51.710475258271501],
              [0.74648423152405, 51.711013759158142],
              [0.746946361872707, 51.710254687629721],
              [0.745224573413109, 51.709959493956362],
              [0.745029974255672, 51.709256252327101],
              [0.744559428021109, 51.709972343972971],
              [0.744600153617202, 51.710405779564013],
              [0.74506805412733, 51.710506362216982],
              [0.744817365601252, 51.710789222951718],
              [0.743862037731797, 51.710146028474597],
              [0.744583010320853, 51.709883656201384],
              [0.744432329094303, 51.709355660351846],
              [0.743444658725585, 51.709253729636245],
              [0.743030093335959, 51.709675318049634],
              [0.743559707174494, 51.708715027354877],
              [0.742692284656662, 51.708470881178812],
              [0.742902553905476, 51.708309482340006],
              [0.743701917211676, 51.708720698154814],
              [0.743445471779051, 51.70914758700566],
              [0.744049668150489, 51.709299849735167],
              [0.745154700173504, 51.709116634894308],
              [0.746046942631603, 51.709531059763023],
              [0.746791402453384, 51.709159977186218],
              [0.746074186901423, 51.709192265809072],
              [0.746061328571089, 51.70838188400807],
              [0.745872216865136, 51.708295836820433],
              [0.7451418624994, 51.708904687665772],
              [0.744863370652959, 51.70836978726188],
              [0.744424133964126, 51.708837824544545],
              [0.743791481993471, 51.708478478147072],
              [0.744507053006776, 51.708174256759079],
              [0.744003466535036, 51.708034640360275],
              [0.744385934548864, 51.707609301608933],
              [0.743549846575755, 51.70706944172732],
              [0.743731686315546, 51.706510292068025],
              [0.743630284300629, 51.707035185593718],
              [0.744454690585609, 51.70762118494806],
              [0.744118902520062, 51.707980679639114],
              [0.744569134769587, 51.708147863074636],
              [0.745032618528107, 51.708319963867588],
              [0.745202460955965, 51.708788740162042],
              [0.745534018117523, 51.708256978448595],
              [0.74613018644101, 51.708252930648349],
              [0.746127298110441, 51.708411900453179],
              [0.74611616101604, 51.709024903844366],
              [0.746984713450536, 51.708976708628896],
              [0.746870550780072, 51.709120524668307],
              [0.746841004942849, 51.709157743954826],
              [0.746495149552466, 51.709593425031102],
              [0.748002450949287, 51.710266851356288],
              [0.748218317868614, 51.709935334111137],
              [0.748825108566868, 51.710034449876012],
              [0.748623856768632, 51.709580490432103],
              [0.750755286761186, 51.709612421111373],
              [0.750992113402587, 51.709078052845605],
              [0.750122572178644, 51.708847501162886],
              [0.749833752794617, 51.709336318767917],
              [0.749330179925539, 51.709220401972239],
              [0.749818139950057, 51.708697243708428],
              [0.749498338628886, 51.708389059046233],
              [0.749056057360872, 51.708662924945195],
              [0.74835012928289, 51.708408743977763],
              [0.747766098970715, 51.708875686147422],
              [0.748356930965416, 51.708306058449558],
              [0.749275359089263, 51.70850759851011],
              [0.748686535504092, 51.7081076804593],
              [0.748758600687792, 51.707744453246363],
              [0.749097055469117, 51.708288733248089],
              [0.749547943965662, 51.707751154863757],
              [0.751523587027778, 51.707531301919857],
              [0.750811664329576, 51.706677406852918],
              [0.750202356347956, 51.70694169977839],
              [0.749379581397525, 51.706693859203767],
              [0.749015507717659, 51.707302252364435],
              [0.749041056965369, 51.706744954051551],
              [0.750047114896335, 51.706506450747789],
              [0.74915294174873, 51.706322336219948],
              [0.748987678128271, 51.705864836648637],
              [0.748728795462573, 51.706513035957975],
              [0.746968165624706, 51.706219677464425],
              [0.74695487205968, 51.706000325778412],
              [0.746292213827419, 51.705360417475731],
              [0.746550931615671, 51.705115138094243],
              [0.745670810919117, 51.70497113957547],
              [0.746005517930376, 51.704857188867663],
              [0.746635560541929, 51.70510236629891],
              [0.746393209403271, 51.705331074702343],
              [0.74695249279359, 51.705961066185672],
              [0.747182424938566, 51.706220061029967],
              [0.748630004721103, 51.706292308684183],
              [0.748499097008876, 51.705971605436105],
              [0.748979017940655, 51.705722042074171],
              [0.749388898907215, 51.706131544659137],
              [0.750456455553993, 51.706143416947484],
              [0.750162872496532, 51.706362541003152],
              [0.751261899267351, 51.706606601762545],
              [0.751946823046775, 51.707683267489024],
              [0.750123794868494, 51.708032657715705],
              [0.750189911467376, 51.708430422450718],
              [0.751564083749999, 51.708627565330879],
              [0.752067704731408, 51.709030365458105],
              [0.752582770419744, 51.708310500818278],
              [0.752294923543379, 51.707670612087014],
              [0.752930766163255, 51.707342731376301],
              [0.752410020662563, 51.707658920716895],
              [0.752730598468774, 51.708313330913001],
              [0.754322977917462, 51.708623141472486],
              [0.753946321680762, 51.708952146059964],
              [0.75367714003442, 51.708643684701194],
              [0.753384868150674, 51.709027367888901],
              [0.752886717927968, 51.708690997295037],
              [0.752755715733115, 51.709227385640048],
              [0.753638969744668, 51.709492671236134],
              [0.753960425522289, 51.709065133975621],
              [0.754119129703202, 51.709723336827913],
              [0.754820242739964, 51.709706890678433],
              [0.754639227383872, 51.709991735866318],
              [0.75636819140095, 51.709927780817232],
              [0.756541809718751, 51.709378696107798],
              [0.757961311749431, 51.709082753009341],
              [0.760522238621839, 51.708917351615227],
              [0.763258638066205, 51.707831320795208],
              [0.764959949393757, 51.707742706990132],
              [0.768055158506711, 51.708505285899669],
              [0.769157594600123, 51.70835248322647],
              [0.769249386402523, 51.707935714683472],
              [0.767691196917209, 51.708139732527698],
              [0.764378505962901, 51.707038710177109],
              [0.763552996706185, 51.707317156320237],
              [0.764156902657888, 51.706799301976574],
              [0.763563922814124, 51.706689148248387],
              [0.762120848373047, 51.705028715008531],
              [0.76146426042308, 51.705014479583198],
              [0.761636085424264, 51.704627312623657],
              [0.76120878176498, 51.704313593401224],
              [0.760883853619442, 51.704421062449889],
              [0.760852356671462, 51.704117820544852],
              [0.76009693414125, 51.703835195146709],
              [0.759390914651805, 51.704173758509398],
              [0.759703224634227, 51.703740123831892],
              [0.758410488481684, 51.703499793722742],
              [0.756833707656027, 51.703610572103294],
              [0.757020084325984, 51.703319301565756],
              [0.755629165365534, 51.703011096592306],
              [0.755947631062089, 51.702868718907709],
              [0.755659390357773, 51.702674927568204],
              [0.755121774158758, 51.70283144041457],
              [0.755166043888805, 51.7024166980475],
              [0.754328201724931, 51.701538797783435],
              [0.753890456104095, 51.701528380254359],
              [0.754130814881756, 51.70138693996217],
              [0.753402665306794, 51.700717808863274],
              [0.752981099220983, 51.700616173694129],
              [0.753047173436328, 51.7008700414478],
              [0.752761276615077, 51.700595246206106],
              [0.751775635211956, 51.700523917553987],
              [0.751120844529835, 51.700824357019329],
              [0.751541042181533, 51.700259608660119],
              [0.751098572325925, 51.700075715968509],
              [0.749809491894962, 51.700322657042435],
              [0.749908526796762, 51.700070316971285],
              [0.750950998699489, 51.699885812199426],
              [0.751781457910993, 51.700071405349867],
              [0.751417790145199, 51.699397317051726],
              [0.751859354363643, 51.699590223288482],
              [0.752745311212053, 51.699188129036834],
              [0.752226741558979, 51.698918787237353],
              [0.752311560633995, 51.698313331391603],
              [0.751514877817367, 51.698445322558719],
              [0.75105814188293, 51.698169130527674],
              [0.75129557775329, 51.697955815945463],
              [0.751588641248896, 51.698396827458502],
              [0.751945212129582, 51.698167229243253],
              [0.752484170777272, 51.698295794766423],
              [0.752461828672465, 51.698809850612875],
              [0.75291961706162, 51.698912436959084],
              [0.753232500869999, 51.698249470244676],
              [0.75277292482578, 51.69808456484553],
              [0.752371422608769, 51.697940494238722],
              [0.753285756299817, 51.697767965405873],
              [0.752684741850762, 51.69747537699363],
              [0.752239175399775, 51.697764622465186],
              [0.752778490243115, 51.697398532544732],
              [0.753405736319407, 51.697741768622777],
              [0.753503521057997, 51.697969710603587],
              [0.752921209100817, 51.69806125382965],
              [0.753455420504986, 51.6981785898287],
              [0.753051760506839, 51.699277175123243],
              [0.753488201396828, 51.699051994973317],
              [0.753796462872905, 51.697812647925389],
              [0.754228783801863, 51.697871183282949],
              [0.754114623139339, 51.697959442072303],
              [0.753857899784845, 51.697942512956153],
              [0.753588331175911, 51.698794229736663],
              [0.754175556839844, 51.698382941399402],
              [0.754284080248215, 51.697878670164933],
              [0.754297048631111, 51.697818407680948],
              [0.754210047647686, 51.695353100711472],
              [0.753697320982666, 51.694917247604707],
              [0.752770145634338, 51.695330209399593],
              [0.752292194658935, 51.695014048644175],
              [0.751604416673916, 51.695174965501522],
              [0.751377951003969, 51.694924855571585],
              [0.750390358633315, 51.694914718239438],
              [0.750701393107826, 51.695324175223881],
              [0.749905681482472, 51.695107740799756],
              [0.750057016536517, 51.694668008748039],
              [0.749340425872782, 51.694401493906838],
              [0.748358914489998, 51.694730254176761],
              [0.747780506995866, 51.694331887385268],
              [0.746913048079741, 51.694608501699278],
              [0.747016181805654, 51.694328188004512],
              [0.746446727791357, 51.694149047940819],
              [0.747874172105825, 51.694062586733899],
              [0.745560979114708, 51.692641759586195],
              [0.747031774256059, 51.693200031644828],
              [0.747461334090775, 51.693673833653797],
              [0.747203773881759, 51.692742732106687],
              [0.746704830070997, 51.692676161698969],
              [0.747596916802723, 51.692281156623629],
              [0.748254320548089, 51.690950912070207],
              [0.745065284289569, 51.691983330578495],
              [0.745253118920385, 51.692241550479615],
              [0.74498181146412, 51.69217419532859],
              [0.744782174223276, 51.691944082373269],
              [0.743469303835657, 51.691650999622382],
              [0.744034440838632, 51.692189996714106],
              [0.742045460615221, 51.692418135191922],
              [0.740544138159901, 51.694035152495204],
              [0.739996668412398, 51.693740350893805],
              [0.739991809388129, 51.693300680131429],
              [0.74111888727596, 51.693105293551241],
              [0.7410681384475, 51.692337530352063],
              [0.740152118607822, 51.69195420301817],
              [0.739841824719698, 51.692111636582389],
              [0.740260670795495, 51.692289826738701],
              [0.739626961858525, 51.692339690241369],
              [0.740029909149195, 51.691799668144476],
              [0.739188694706417, 51.691504523047357],
              [0.738591064091611, 51.69222355808639],
              [0.739861240423083, 51.693007839631086],
              [0.739927477365677, 51.693433487126157],
              [0.738719584208759, 51.693249421207717],
              [0.739416096852105, 51.693472400390029],
              [0.738881176427825, 51.693792458959209],
              [0.738239383512322, 51.693732782174934],
              [0.737907967220098, 51.692222401739613],
              [0.738653881894645, 51.691394687082791],
              [0.739873794535589, 51.691155776258761],
              [0.741492886003622, 51.69177540960812],
              [0.742037749766684, 51.691429025160737],
              [0.741315026257533, 51.691011513687968],
              [0.740693012254915, 51.691326419642898],
              [0.739599758237503, 51.691074933575791],
              [0.739683190468502, 51.690084596249903],
              [0.737577676357964, 51.689291913888148],
              [0.737321945095811, 51.688842803317158],
              [0.738277057134718, 51.687933767498627],
              [0.737805811158549, 51.687198293411051],
              [0.738299740369408, 51.686967331678872],
              [0.740876739699101, 51.687183300059999],
              [0.741519729104094, 51.687934537795321],
              [0.741338990095514, 51.688798541430565],
              [0.739524917825588, 51.689141269148863],
              [0.739336480137984, 51.689471232284632],
              [0.741472509225372, 51.688852982489571],
              [0.742440728106035, 51.688951783087042],
              [0.746177264734026, 51.69055972829986],
              [0.746791420286764, 51.690139752037211],
              [0.746663713151331, 51.689631905711821],
              [0.747380188606663, 51.689276984072706],
              [0.746921928343251, 51.689236443532302],
              [0.746937164940639, 51.688890734398925],
              [0.746346549740657, 51.689006178766206],
              [0.74691017452991, 51.688707897294428],
              [0.747394723160576, 51.688955573889857],
              [0.74768314779615, 51.688569295889629],
              [0.747759540416286, 51.688970419509204],
              [0.748525635739248, 51.688431760048211],
              [0.751200749099135, 51.688497711240203],
              [0.752512537360843, 51.688821310702281],
              [0.752553068655339, 51.689179203829333],
              [0.754287416318462, 51.689836430458207],
              [0.753915952890239, 51.689938677368019],
              [0.754351681528967, 51.690155993264511],
              [0.756908149104544, 51.690720144537025],
              [0.756505883709294, 51.6909346432113],
              [0.754231725403035, 51.690420520259671],
              [0.753958744306489, 51.691668036230944],
              [0.755214678178504, 51.691499169464635],
              [0.756222167043369, 51.691742631308507],
              [0.756522836504711, 51.692094416009049],
              [0.756819384757763, 51.69194985155972],
              [0.757882690672742, 51.692349376191352],
              [0.759376501889806, 51.693568881202665],
              [0.759174743186796, 51.695770752735527],
              [0.75822922318014, 51.697689712444841],
              [0.759186016876025, 51.699856266403444],
              [0.760783677354973, 51.700352030144977],
              [0.76213740509798, 51.700383147237552],
              [0.762495177708494, 51.700886464526114],
              [0.76493828484341, 51.70126514748857],
              [0.766930057033204, 51.70273814265294],
              [0.767347490699294, 51.702202180807667],
              [0.769138075316069, 51.702322749480409],
              [0.769754033135849, 51.702004238137057],
              [0.770414218756699, 51.702125365562701],
              [0.7721492072952, 51.701739062390018],
              [0.771274488971259, 51.70128125208214],
              [0.773066507579226, 51.701378343385848],
              [0.772653308209436, 51.70004176101623],
              [0.773044773801754, 51.699011743533568],
              [0.772463759170295, 51.699143280415278],
              [0.772120824948738, 51.698813218536351],
              [0.771773136616418, 51.699115517320131],
              [0.771497641680963, 51.698917866095989],
              [0.77200809316888, 51.69867378133668],
              [0.772655437159972, 51.698964278526596],
              [0.773312531058644, 51.698160021618811],
              [0.772206113321633, 51.697225634567701],
              [0.7715936322756, 51.697173541665272],
              [0.771978332626915, 51.696861375806826],
              [0.772606831197114, 51.69727013410192],
              [0.772838636183127, 51.697084788054845],
              [0.77276134204041, 51.696152178948282],
              [0.772157615230344, 51.695636328221724],
              [0.772283221269509, 51.695646337544218],
              [0.772690141454845, 51.695177155899863],
              [0.772768857485189, 51.69440544505953],
              [0.772179434817325, 51.694043431251004],
              [0.772846134107414, 51.694225546810436],
              [0.772977706012234, 51.693371644985838],
              [0.773760856583616, 51.692594985111974],
              [0.773664133438828, 51.692930033404544],
              [0.774072365918868, 51.692672165093938],
              [0.773955309415561, 51.691444606041976],
              [0.775517612385225, 51.690844683266008],
              [0.775551305392902, 51.690496733524292],
              [0.776426190245953, 51.690865470461901],
              [0.775949438072395, 51.690168946933682],
              [0.776339055649646, 51.690269456066957],
              [0.776330349051677, 51.689489016698403],
              [0.775904575614542, 51.689530562120609],
              [0.776218303379701, 51.689171493772754],
              [0.77436443602455, 51.688646921222336],
              [0.773413559390912, 51.688927504092675],
              [0.773742499913976, 51.688533008042299],
              [0.776424414197024, 51.689016426439018],
              [0.776889646825294, 51.688792274829552],
              [0.775601345865152, 51.687933271460196],
              [0.775337227390654, 51.68872465746788],
              [0.774984528864841, 51.688139416365487],
              [0.774120584689147, 51.687948484020531],
              [0.77381347710858, 51.688439596343379],
              [0.774038905456577, 51.687701291079797],
              [0.773372573129555, 51.687523671088236],
              [0.774908193150974, 51.687553937990224],
              [0.775252613841393, 51.687885755214644],
              [0.776204987337454, 51.687228289374438],
              [0.777301967083162, 51.685388331733229],
              [0.778105237956434, 51.68515617741415],
              [0.77696543377124, 51.686343320196698],
              [0.777041582712423, 51.686996254064205],
              [0.776631336901951, 51.687291054935116],
              [0.777007762279621, 51.68738917559854],
              [0.776046485039954, 51.687664630090516],
              [0.777586175098991, 51.689249761404952],
              [0.777588172234898, 51.68977853851888],
              [0.777026746173917, 51.690156960174818],
              [0.77700058885351, 51.691242208151778],
              [0.775880376295602, 51.6915466017063],
              [0.776959865575403, 51.691735121742809],
              [0.77747209632669, 51.692537825931851],
              [0.778300210275192, 51.69268551357596],
              [0.77892039031766, 51.692415417534825],
              [0.779754548269223, 51.692779697919171],
              [0.779499713808685, 51.69308431905408],
              [0.779281778978318, 51.692645193881589],
              [0.778827270423547, 51.692737794095841],
              [0.779259934324728, 51.693115177464662],
              [0.778896755665199, 51.693541077536416],
              [0.777768313161571, 51.69281411666104],
              [0.777793489195021, 51.693697592633811],
              [0.777399442232748, 51.69317089591086],
              [0.775003182469748, 51.693055779740149],
              [0.773965234008698, 51.693499409550228],
              [0.774415549187626, 51.696791218900927],
              [0.775532393971093, 51.697233389142355],
              [0.775809169275414, 51.698019182088032],
              [0.774726336846013, 51.700119599362615],
              [0.775410505749583, 51.701578378629918],
              [0.774215910049224, 51.702942157941884],
              [0.771554045495765, 51.70405457085117],
              [0.774245714001888, 51.704919143912967],
              [0.776238742389677, 51.705176021350411],
              [0.778940901047742, 51.706232702814553],
              [0.780228787590965, 51.706320031901171],
              [0.782488985158079, 51.707649709652713],
              [0.785266979368687, 51.707744833136609],
              [0.785327345202081, 51.708138220420807],
              [0.785946798563914, 51.707618084304904],
              [0.785552517269918, 51.707868467002228],
              [0.785201233866976, 51.707570117989818],
              [0.786236460227561, 51.706819774067576],
              [0.787522589583731, 51.706783852587982],
              [0.789011259229072, 51.707310603198501],
              [0.790189493155238, 51.706297810788399],
              [0.789367637102146, 51.70757374491204],
              [0.788798677938238, 51.707572869561439],
              [0.788665262885916, 51.708274841612131],
              [0.789433022083134, 51.708330353044424],
              [0.790793217280767, 51.709049898718696],
              [0.791907774039333, 51.709024302901533],
              [0.792057446652016, 51.708442242420027],
              [0.792098695058237, 51.708833595456404],
              [0.792922910956344, 51.708841874260052],
              [0.792868643290205, 51.708078707638357],
              [0.793619558755767, 51.707508636471012],
              [0.794844200167017, 51.707977740471655],
              [0.794345397864967, 51.708199163212797],
              [0.795110774739417, 51.708474138562202],
              [0.796110849746142, 51.70762561318513],
              [0.796228399469747, 51.707744764766623],
              [0.795926447809688, 51.707851247288801],
              [0.795838599613653, 51.708140235574668],
              [0.796358942975983, 51.708291528766402],
              [0.795633303002371, 51.708308807502405],
              [0.794532938065773, 51.709033804706664],
              [0.793777267194887, 51.71009054926958],
              [0.79267262543917, 51.710159995523284],
              [0.792940354562204, 51.709876624486839],
              [0.792610657961418, 51.709882667244464],
              [0.792279418818033, 51.710216113283387],
              [0.792120416859057, 51.710224381096658],
              [0.792080432606362, 51.710226460544639],
              [0.790781521489576, 51.710293993120793],
              [0.791455883567876, 51.710971371155203],
              [0.792084103531009, 51.710265201382263],
              [0.792344926227682, 51.713017864281319],
              [0.791956403047447, 51.713502864491197],
              [0.792460479570062, 51.717030762321642],
              [0.79311479570263, 51.717874090374529],
              [0.794928121922921, 51.7185162589561],
              [0.801740948353039, 51.718800894755233],
              [0.803756107091171, 51.71834988418626],
              [0.80424723031911, 51.718611563346094],
              [0.804342439219447, 51.71827832431503],
              [0.805854356476161, 51.717791627827715],
              [0.81247525649842, 51.71974314938636],
              [0.819698126331296, 51.721073677452551],
              [0.823689185673699, 51.720450750532677],
              [0.830774658135749, 51.71681759868823],
              [0.832513686085358, 51.715452693502279],
              [0.834139471695615, 51.714987164810502],
              [0.83937197065639, 51.715818585784042],
              [0.840194776488516, 51.715640398271212],
              [0.847386568097767, 51.716519390530408],
              [0.848047003072991, 51.716476393022674],
              [0.847779507723117, 51.716021503467438],
              [0.848150083422084, 51.715697735969705],
              [0.850320467371496, 51.716480588623689],
              [0.850650339197805, 51.715947348291067],
              [0.85238987832935, 51.715589426212226],
              [0.851940660158662, 51.716135463429701],
              [0.85225341686375, 51.717032634553895],
              [0.855190237023291, 51.716947772188469],
              [0.85465562144392, 51.717333110009513],
              [0.855348391132771, 51.717665222785492],
              [0.860577615951041, 51.718914891078207],
              [0.860546861211535, 51.719368024676903],
              [0.864938752357323, 51.721110066672608],
              [0.866523729328579, 51.721008430735083],
              [0.866286349139075, 51.721559245393479],
              [0.867472769059682, 51.721978168494971],
              [0.868908638086528, 51.721968280127363],
              [0.86993940065556, 51.722946794372916],
              [0.870673544029268, 51.722603276567611],
              [0.870779488596643, 51.723680834758255],
              [0.873736088675132, 51.725316430719758],
              [0.875235655047977, 51.725144818412353],
              [0.874906879402613, 51.725554888168269],
              [0.875962091511558, 51.725637874716156],
              [0.876073600947314, 51.726276397862868],
              [0.877723296997369, 51.727213603711277],
              [0.87803173919588, 51.728033469720252],
              [0.878845872502449, 51.728742907517287],
              [0.878918153548469, 51.728353505017516],
              [0.879254426702365, 51.729337271281615],
              [0.880593258864398, 51.730735344187572],
              [0.884148214586212, 51.733815691558341],
              [0.884359200968346, 51.733466948514447],
              [0.882806362501796, 51.732009415738759],
              [0.88364615000215, 51.731618255480448],
              [0.884642401271836, 51.731455284801896],
              [0.886261798565607, 51.733001994913977],
              [0.884998020059982, 51.73360415096581],
              [0.884905064440053, 51.734009354607814],
              [0.885857173362313, 51.734516593804535],
              [0.886296731659533, 51.734299834756058],
              [0.885824672529786, 51.73475662630014],
              [0.886189032766076, 51.735223442068119],
              [0.886314208640772, 51.734892093688146],
              [0.888701731121567, 51.736192348755381],
              [0.888729804326575, 51.736541515407026],
              [0.889504771946252, 51.736632174824713],
              [0.889835527615883, 51.737275184724197],
              [0.888671574729061, 51.739125054928628],
              [0.890675179983279, 51.741327793874255],
              [0.894055662857716, 51.742992030373543],
              [0.89949481745857, 51.744876978585339],
              [0.909090139183985, 51.746451053466032],
              [0.918171133206157, 51.747341009339507],
              [0.921845863076366, 51.748153527384765],
              [0.927175713825878, 51.747693440367627],
              [0.927718293379298, 51.748107122919762],
              [0.930677252612728, 51.747036811608112],
              [0.933291385429226, 51.745342770544838],
              [0.934207362279587, 51.744274806862286],
              [0.93916789404725, 51.74224137837512],
              [0.940551444449727, 51.740546481001012],
              [0.942720552762784, 51.739795126659828],
              [0.943681171111925, 51.738572171524069],
              [0.943491544839024, 51.738106545676047],
              [0.946201085050764, 51.736980924609952],
              [0.945873029786074, 51.736102359148767],
              [0.947045215894107, 51.735840008373856],
              [0.948770631388446, 51.734086062751864],
              [0.948509074917555, 51.733294873611072],
              [0.94910249596544, 51.732954394475144],
              [0.949511600249172, 51.731630114799167],
              [0.95054245538846, 51.730823550073069],
              [0.949918087507838, 51.730555029420913],
              [0.950316741526852, 51.729471145226185],
              [0.949947208995465, 51.726622173288902],
              [0.949263705612006, 51.725088796481593],
              [0.950661603954734, 51.722871761750476],
              [0.950395433828124, 51.721964670764805],
              [0.948533132406225, 51.719895186889502],
              [0.947096852941013, 51.716536951105731],
              [0.94575278932909, 51.716152462517066],
              [0.945567608295027, 51.714796333009531],
              [0.943613132995819, 51.711388997097224],
              [0.943702175011094, 51.708168750817066],
              [0.942454144376802, 51.706538863137034],
              [0.942884370443521, 51.704960444576571],
              [0.942014728555296, 51.704051371560844],
              [0.942363636428028, 51.703583939340866],
              [0.941437777028086, 51.702655585171634],
              [0.939664534290289, 51.703056156272666],
              [0.94058595174724, 51.702667030248833],
              [0.939258972194959, 51.702583338387001],
              [0.940523562819973, 51.702285453034897],
              [0.940854472000588, 51.701540564687555],
              [0.939487626604183, 51.698609508261619],
              [0.940191764805452, 51.700048887002829],
              [0.940632120323777, 51.700076531788177],
              [0.940433797724836, 51.698781881147127],
              [0.94096369494852, 51.696084828617245],
              [0.941539995731037, 51.694505451149475],
              [0.942391309453491, 51.693819467589151],
              [0.940787877227619, 51.693641992262187],
              [0.942901293133546, 51.693716745281172],
              [0.943175749361494, 51.693485916543686],
              [0.943630814246423, 51.692225252921375],
              [0.944685812557048, 51.691311994612526],
              [0.944619055126689, 51.6899942619957],
              [0.94545996979827, 51.689239262213192],
              [0.946646302175896, 51.68921308756051],
              [0.947055084904391, 51.688912322340101],
              [0.94673556610708, 51.688716181329873],
              [0.947266530548135, 51.68826934488655],
              [0.946568475054741, 51.68792621238925],
              [0.947429724661257, 51.686817226010696],
              [0.946908771317608, 51.685680881739316],
              [0.947485477280144, 51.684981065560713],
              [0.948454034571585, 51.684967534627454],
              [0.948535583915931, 51.684641704895711],
              [0.949279741449593, 51.684426043600084],
              [0.950027612516745, 51.683488970875942],
              [0.949710994771372, 51.682847565548016],
              [0.948398020892001, 51.682369696145955],
              [0.949451143576279, 51.681632718951107],
              [0.949096262172957, 51.680936511062342],
              [0.947574830625351, 51.680366735526277],
              [0.94805274566597, 51.679884351038787],
              [0.947789143872602, 51.679211929234107],
              [0.948145324374857, 51.678748790306187],
              [0.946312089054732, 51.678143659071154],
              [0.94733169172011, 51.677671063544267],
              [0.947257158321974, 51.677013679665009],
              [0.947674330493148, 51.676999606625159],
              [0.947055996958068, 51.675898985599801],
              [0.947285726016744, 51.675293321961519],
              [0.946574049748131, 51.674826416520361],
              [0.945143563117854, 51.67467344542014],
              [0.944747174472437, 51.673603219350888],
              [0.945053024155466, 51.673233989593534],
              [0.943429531484303, 51.672758461489856],
              [0.942985792505488, 51.67227312333069],
              [0.943496008729078, 51.671977020478565],
              [0.943002921616656, 51.671778926986491],
              [0.942337240765789, 51.670346693758091],
              [0.942126972998708, 51.669535315543683],
              [0.94272141053342, 51.669066219784071],
              [0.942156122052677, 51.668802478683311],
              [0.942488857292392, 51.66782189313107],
              [0.941682786972317, 51.666632414392339],
              [0.941871749294685, 51.665285782841714],
              [0.942910728649893, 51.663866574497227],
              [0.942002660204688, 51.663713059163484],
              [0.942585145239491, 51.663506885730854],
              [0.941586153097573, 51.663423101375386],
              [0.942233297057293, 51.662585733223153],
              [0.941888848290877, 51.662001667011324],
              [0.942197359671069, 51.660580985019621],
              [0.940402392696611, 51.660285998601417],
              [0.938568427558397, 51.660594555132732],
              [0.938333684136896, 51.660363891916568],
              [0.937641864078449, 51.661226640276467],
              [0.935625236988829, 51.661081931031859],
              [0.937688933631887, 51.661082458554844],
              [0.938266700643884, 51.660245050162622],
              [0.939013883696402, 51.660483568177007],
              [0.940412273452423, 51.660170628612143],
              [0.941819513977661, 51.660284662621123],
              [0.940062118147878, 51.659386135341961],
              [0.936984989024838, 51.659589995051896],
              [0.936496223207543, 51.660015046878883],
              [0.936997367830564, 51.659490751989829],
              [0.939931740941545, 51.659181641342002],
              [0.939529209698037, 51.657967648770381],
              [0.93998349735295, 51.657390550399526],
              [0.93952472914169, 51.656558410257787],
              [0.938443172792618, 51.657251946182022],
              [0.936287665062286, 51.657908489188465],
              [0.938338385762021, 51.657174523704882],
              [0.939027738748027, 51.656520491330312],
              [0.936635532750587, 51.655998466967262],
              [0.936025945902137, 51.655314889899728],
              [0.935232630286382, 51.655479536409707],
              [0.934732243600284, 51.655075631175109],
              [0.935311992399475, 51.655365127270848],
              [0.936120562990212, 51.655235174904817],
              [0.936799387396415, 51.655916119513748],
              [0.939191339102983, 51.656389579594133],
              [0.939599648441468, 51.654477133797933],
              [0.937604199171819, 51.654722267828639],
              [0.936714459289889, 51.654558361112699],
              [0.936625437660179, 51.653986775336683],
              [0.934208963937148, 51.653825067497806],
              [0.936643532502815, 51.653886489808116],
              [0.936893508203561, 51.654509810219679],
              [0.937752806839754, 51.654650193954261],
              [0.939414169859772, 51.654336977046349],
              [0.938889098846399, 51.653507394535204],
              [0.93734379422753, 51.652373272278155],
              [0.937413475695789, 51.651885855189242],
              [0.936384892974246, 51.650669532678506],
              [0.93554030280354, 51.650419944962451],
              [0.935028684300816, 51.646396259672997],
              [0.935141832646027, 51.64494810235351],
              [0.937265455713183, 51.640387196582395],
              [0.937098495402947, 51.636217279201325],
              [0.934555963749664, 51.63276875203556],
              [0.926516732174997, 51.628942044975702],
              [0.925457386436219, 51.629210399466118],
              [0.923544761595419, 51.627873902703719],
              [0.920487461129307, 51.626734959249305],
              [0.914910078214773, 51.625075765118645],
              [0.913729831594178, 51.625103281933981],
              [0.911299166227392, 51.624062761902906],
              [0.908187598150074, 51.623534636762102],
              [0.904301507551407, 51.622416716114152],
              [0.899238234375219, 51.622706529089484],
              [0.896979521475823, 51.622951192640215],
              [0.896663376376455, 51.623227915809544],
              [0.894115748665946, 51.622884260155189],
              [0.89189002754943, 51.623807050420368],
              [0.891966760033394, 51.624013817463883],
              [0.8910821247612, 51.623645283636748],
              [0.890278341478156, 51.623798196011307],
              [0.890545261919213, 51.62358745919726],
              [0.889882638021858, 51.623642455350961],
              [0.88788135002901, 51.622745573491748],
              [0.885605812093044, 51.623441024856824],
              [0.877312067921859, 51.62337578833943],
              [0.876406443315342, 51.623061622815598],
              [0.870367364620157, 51.622866850632036],
              [0.859551321190848, 51.621222459503493],
              [0.851453748514683, 51.621674056269114],
              [0.841735221318066, 51.622847890930345],
              [0.841140732833184, 51.622583479612395],
              [0.838945063785935, 51.623032352635462],
              [0.838610180343295, 51.623292281134631],
              [0.838992509593013, 51.623792079974415],
              [0.838419885806172, 51.624039772294459],
              [0.837810115777947, 51.623299042647496],
              [0.836946880307738, 51.623451226790131],
              [0.836967383460494, 51.623224087434338],
              [0.836739713242276, 51.623511997070224],
              [0.836541493095578, 51.623044614990192],
              [0.833242604771121, 51.622485759776247],
              [0.822849095516104, 51.623107711454239],
              [0.82143140332697, 51.623895487331019],
              [0.817368254676209, 51.624688335683537],
              [0.815310862069695, 51.625578633391193],
              [0.81417976662267, 51.625467262672942],
              [0.805659955531136, 51.626470830633245],
              [0.804348800515114, 51.626110946348902],
              [0.805324125571135, 51.626812527588093],
              [0.805001678217924, 51.628963442562636],
              [0.802954008738862, 51.6293622290587],
              [0.802059766664184, 51.628522886713846],
              [0.802410603272285, 51.626338026108641],
              [0.802878879710635, 51.625870859238425],
              [0.800609857271769, 51.625606659309256],
              [0.800288054201268, 51.625848176437991],
              [0.800106638940537, 51.625577297711033],
              [0.797462851689976, 51.625257224874055],
              [0.796031605384867, 51.625832760136397],
              [0.796611651366691, 51.625372848540678],
              [0.795092786134492, 51.625510667634487],
              [0.789785847496262, 51.626954539701707],
              [0.78846396931181, 51.6280903866719],
              [0.78801003157544, 51.628016632113813],
              [0.785179888530888, 51.629797140114491],
              [0.785377725932024, 51.630266412016617],
              [0.785013709408178, 51.630033121602288],
              [0.784501066230327, 51.630322293122703],
              [0.784790006565241, 51.630839768802112],
              [0.785728629295391, 51.630427163693923],
              [0.78464033789498, 51.631015100114347],
              [0.784335474359216, 51.630591536313865],
              [0.783581471919754, 51.630833367557166],
              [0.780050605296602, 51.634252827898209],
              [0.780169721476781, 51.634782431848855],
              [0.779776949277327, 51.634362737929472],
              [0.777473354503324, 51.635500121519158],
              [0.777481892643642, 51.635758037912275],
              [0.776410132134962, 51.636028926905325],
              [0.773896222944581, 51.637441024050446],
              [0.771416177777614, 51.638249690629678],
              [0.770998275688054, 51.638744317321198],
              [0.764712202854159, 51.640928738808917],
              [0.763266649972085, 51.642186819319839],
              [0.76160732654519, 51.642640484083117],
              [0.761546523832162, 51.643138363693332],
              [0.762246859601379, 51.643363811097657],
              [0.761888254187387, 51.644146603175088],
              [0.762049915735594, 51.643334270502969],
              [0.76145231774904, 51.64334833343311],
              [0.761149964536293, 51.642821225291193],
              [0.760636816424786, 51.642726272922815],
              [0.758475316131869, 51.642909304434625],
              [0.755595735038506, 51.642621703968175],
              [0.754880891964234, 51.643014419941252],
              [0.754408637274224, 51.642759293287696],
              [0.754138701651203, 51.643051625632282],
              [0.753664487300639, 51.642859497379376],
              [0.753205744343071, 51.643280369451503],
              [0.752960665645358, 51.643028900988298],
              [0.749054769429982, 51.643767998638971],
              [0.748784441086554, 51.643481138621809],
              [0.745439509124378, 51.643290526001273],
              [0.745163759745838, 51.643607255874258],
              [0.745614201495322, 51.643908797081735],
              [0.745019366131235, 51.643681682780191],
              [0.744776541635913, 51.643873529783043],
              [0.746210538395917, 51.645643202264175],
              [0.745606372816695, 51.645238235648073],
              [0.744853564528323, 51.645411434021746],
              [0.745458118414397, 51.645009668766725],
              [0.744582047453788, 51.643884373869625],
              [0.745125001452114, 51.643252914877102],
              [0.746120581944415, 51.643055147411403],
              [0.744015435007529, 51.64180301179082],
              [0.741980627834355, 51.641235407986152],
              [0.740977241413981, 51.64120758335968],
              [0.740542937341251, 51.641699783136197],
              [0.740433017075513, 51.641291342221507],
              [0.739644432996026, 51.641325942183904],
              [0.738332457016517, 51.641574206914896],
              [0.738163068137523, 51.641905526149969],
              [0.737980634653058, 51.641660659736431],
              [0.735722678849436, 51.642066764093272],
              [0.734876527711827, 51.642466014902134],
              [0.731154275542437, 51.643017664650799],
              [0.731041586233437, 51.643403413773505],
              [0.730760941606657, 51.643015125742679],
              [0.728644982592788, 51.643067938188977],
              [0.729095238532408, 51.642742693515039],
              [0.728271294457735, 51.642501935971374],
              [0.727758877154717, 51.642658643586635],
              [0.727784888641032, 51.642297396422357],
              [0.726396129356863, 51.64206255746673],
              [0.722350055258392, 51.642831898858702],
              [0.71690466518687, 51.642998555923207],
              [0.71386092300269, 51.641773006662284],
              [0.713520531249202, 51.640986746245602],
              [0.711753787220751, 51.640665141976847],
              [0.711032326536917, 51.639911062355878],
              [0.710507252403916, 51.638378097724555],
              [0.710003279023714, 51.63821616081102],
              [0.709655088372482, 51.637103603611642],
              [0.709008752058981, 51.636686834438791],
              [0.701435911274637, 51.636769663711448],
              [0.700922114400641, 51.637049497177486],
              [0.6993719049633, 51.637084264845413],
              [0.695888106637556, 51.639242749882641],
              [0.694545180026313, 51.638825712992926],
              [0.691959962824175, 51.637005393592375],
              [0.688487565612469, 51.637823359985198],
              [0.687461230220459, 51.637087814081511],
              [0.686648205319724, 51.636928357566077],
              [0.6850878158135, 51.637108864277842],
              [0.678567838073509, 51.636591436980311],
              [0.676060566853165, 51.636891460090126],
              [0.675593940779108, 51.636551352870711],
              [0.66437418205887, 51.636749949384587],
              [0.663918767253362, 51.636821443276354],
              [0.663702506352953, 51.637323691289936],
              [0.663291491123281, 51.63694450105892],
              [0.66208014922543, 51.636990870515838],
              [0.660726462458833, 51.637296768680429],
              [0.660245534887456, 51.637697987692519],
              [0.660473437599368, 51.639615625694958],
              [0.659889850366067, 51.643053565696555],
              [0.660582373350227, 51.643228530356481],
              [0.662458367682569, 51.642755201135273],
              [0.662570614627178, 51.641858703793559],
              [0.6627437053032, 51.642641705565687],
              [0.664813520161606, 51.642097389012989],
              [0.664806941748291, 51.640876224465913],
              [0.665374095679027, 51.641156532886939],
              [0.665510965145622, 51.64208514857652],
              [0.666622177342372, 51.642571635045897],
              [0.665329385623292, 51.642414836143253],
              [0.661701556326744, 51.643629444549738],
              [0.661703872526066, 51.644976613294105],
              [0.661175888365224, 51.644353647247186],
              [0.66076531450032, 51.64452573755166],
              [0.660034752956311, 51.644244613730642],
              [0.655610513864753, 51.645342176185871],
              [0.654436779004746, 51.645887649715448],
              [0.651386024973019, 51.646398184412895],
              [0.649923717691177, 51.647276592477851],
              [0.650630358695287, 51.647519647467],
              [0.650909602085906, 51.64809341803749],
              [0.650352775798443, 51.648436052310899],
              [0.649754089261768, 51.648408199758066],
              [0.649535723732855, 51.647688257916336],
              [0.64890567896354, 51.64746954869625],
              [0.649038459742631, 51.646622967090856],
              [0.649763843342383, 51.646518457257301],
              [0.650443150289724, 51.645723387460052],
              [0.65258048093977, 51.64563733014014],
              [0.653309522611132, 51.645348349735571],
              [0.653424375166297, 51.644963531563924],
              [0.654159952623027, 51.645083600065512],
              [0.654541792455853, 51.644716125752318],
              [0.656893423414525, 51.644365231312186],
              [0.656942546849255, 51.644018770466921],
              [0.657658856736773, 51.643744413277226],
              [0.657105498914258, 51.644185057844794],
              [0.658534444528604, 51.643703925204925],
              [0.65781518240304, 51.643684537396346],
              [0.658429909676705, 51.643449080019906],
              [0.658522595781522, 51.642044000356144],
              [0.657718009589626, 51.642053229376224],
              [0.657589783683409, 51.641813309475104],
              [0.658634218582838, 51.641259040444325],
              [0.658898196347897, 51.639471454594776],
              [0.658314473433077, 51.639351574305543],
              [0.658838511648437, 51.639069000058626],
              [0.657846842387636, 51.637855759716459],
              [0.65279854942215, 51.638330615936177],
              [0.646385652163252, 51.63815882528344],
              [0.640425854163663, 51.639465804572197],
              [0.636324765394895, 51.639630844862303],
              [0.634794379559798, 51.640084305205718],
              [0.634683707128765, 51.639671293387984],
              [0.633748304416825, 51.640315532602173],
              [0.633126101468385, 51.639879822373139],
              [0.632388677873103, 51.640199442783612],
              [0.632279614686691, 51.639863736226189],
              [0.629467432999654, 51.639598514787416],
              [0.629490051391241, 51.641537888150189],
              [0.628940644652197, 51.641610451680165],
              [0.629106767370233, 51.642957541391311],
              [0.627522256243307, 51.644827680173911],
              [0.628416093419553, 51.645935440320727],
              [0.627575567093803, 51.649923049796733],
              [0.626092044619639, 51.657495414310141],
              [0.626398587789998, 51.658070428313984],
              [0.625243703000901, 51.658978514078456],
              [0.624071596339924, 51.661566037700695],
              [0.623298254673785, 51.662272215465663],
              [0.623489885214117, 51.663886741259731],
              [0.62329500266367, 51.665619623626533],
              [0.620899419042439, 51.667787474045255],
              [0.619488725346307, 51.670409049857831],
              [0.619189101271553, 51.673312496999124],
              [0.619716708612395, 51.673557035566489],
              [0.619738314609072, 51.675334540668622],
              [0.617823538463443, 51.677573429423759],
              [0.616114730382896, 51.678620573365073],
              [0.61461994837262, 51.679165593432664],
              [0.613303221443135, 51.68019047277312],
              [0.611200210407222, 51.680550232410077],
              [0.609541320745681, 51.681212152342077],
              [0.608809276709942, 51.681941592094191],
              [0.60913161398232, 51.684200748890248],
              [0.611124866052991, 51.684092584451086],
              [0.612177125191042, 51.685532393650618],
              [0.6123619557435, 51.68730986063288],
              [0.611275349896693, 51.689421383484401],
              [0.612826745588831, 51.689475904615179],
              [0.612354573095466, 51.690020614614326],
              [0.610534911533247, 51.689755312678059],
              [0.610662056039126, 51.692668121498208],
              [0.61087807270456, 51.694233552420208],
              [0.612229549229379, 51.694934651139747],
              [0.611157816792972, 51.696377634699076],
              [0.612557461629056, 51.69879268483681],
              [0.610335146200049, 51.700564327291765],
              [0.60708344473688, 51.700622176878291],
              [0.606625902389397, 51.700845475620717],
              [0.604536826522217, 51.700381909844616],
              [0.602962852705608, 51.702915148676595],
              [0.603759307645222, 51.705063976833792],
              [0.601984650872245, 51.708845420497738],
              [0.602047216926504, 51.711675120870467],
              [0.603989161576608, 51.713726613981208],
              [0.605202983015271, 51.715903048788284],
              [0.608828714425507, 51.719506214425877],
              [0.61112562263128, 51.722780888341035],
              [0.610272539496316, 51.723330454913857],
              [0.607311600098543, 51.72346187469131],
              [0.604081822226854, 51.724937379591147],
              [0.595339940426603, 51.725776591795572],
              [0.591162942259529, 51.728196269107293],
              [0.589471106892569, 51.730166228210834],
              [0.589101901067809, 51.731814734143228],
              [0.591177235535329, 51.733382357338506],
              [0.591774306122476, 51.733692041749975],
              [0.595130668879711, 51.734054107621624],
              [0.592984535428659, 51.739347260384683],
              [0.591778479662434, 51.745658346337471],
              [0.592098578239661, 51.748667611252714],
              [0.591667360896743, 51.751308541706663],
              [0.592482386427118, 51.754153999535632],
              [0.593338667882383, 51.755441815100255],
              [0.594857889823908, 51.754997279238239],
              [0.594963285025837, 51.753929254621646],
              [0.59882251582037, 51.751262889571045],
              [0.598674962777041, 51.750409098682233],
              [0.599938258136893, 51.751516092804337],
              [0.599862858110241, 51.752019583844358],
              [0.599377185607374, 51.752090585728652],
              [0.600435974065696, 51.75326864948731],
              [0.601118314998231, 51.753215772111943],
              [0.60173529471836, 51.753765086270299],
              [0.602592646527896, 51.753706525758666],
              [0.604354333848169, 51.754833907618305],
              [0.605162383169154, 51.756334949301902],
              [0.604530455390381, 51.756911937049857],
              [0.604750707202035, 51.757289266407128],
              [0.604244725510971, 51.757461462458146],
              [0.604788659061287, 51.757898166175828],
              [0.605373888047907, 51.757869001477118],
              [0.605407345000456, 51.758273854355473],
              [0.606721451239522, 51.758935384890265],
              [0.608603601745244, 51.759293808084642],
              [0.611053938479218, 51.760394107896396],
              [0.614353612174871, 51.760816255841881],
              [0.613873028063462, 51.75874411333136],
              [0.614649954160893, 51.758822153515077],
              [0.614985606194289, 51.758085330443571],
              [0.618665335262015, 51.758541164471083],
              [0.618097808972703, 51.760278719072254],
              [0.618112906003728, 51.76094028598073],
              [0.618790725688334, 51.761007917053398],
              [0.618578983010759, 51.762557677232266],
              [0.619432193275419, 51.762525167733813],
              [0.619098291460744, 51.76113865768933],
              [0.619602451412916, 51.760835136669627],
              [0.623070095845117, 51.761302758010245],
              [0.624964597724679, 51.761957422517064],
              [0.627931083506711, 51.761529576227836],
              [0.628709682300455, 51.761984304445079],
              [0.629857389307678, 51.761982001773546],
              [0.630584935835681, 51.76103491016066],
              [0.633331566549781, 51.760143299133674],
              [0.633772951592051, 51.761451819474608],
              [0.634955519907071, 51.761426202110414],
              [0.635161482748482, 51.760035718076089],
              [0.635891514704494, 51.759805301035328],
              [0.635309524167094, 51.758322774390074],
              [0.637510219769396, 51.757641160435284],
              [0.638524238218672, 51.759647276741859],
              [0.639449607011719, 51.759312624648324],
              [0.639458709221222, 51.760039977230086],
              [0.64563574920228, 51.760889400540769],
              [0.647025839609694, 51.761785297841662],
              [0.647476023527968, 51.761503556290741],
              [0.648287049044722, 51.761988902125047],
              [0.647089532562184, 51.763839730715205],
              [0.645167850307025, 51.764239146781996],
              [0.643468996224587, 51.766864752508852],
              [0.641843103078336, 51.767045215637921],
              [0.640031052775628, 51.767835986358783],
              [0.639186256049112, 51.766772175121723],
              [0.637532310805039, 51.766794926876173],
              [0.636392454108081, 51.768301702186832],
              [0.6383910456936, 51.76881891863767],
              [0.638274185411037, 51.770344104227675],
              [0.636806682492166, 51.770334778855194],
              [0.632654281700385, 51.77161500468933],
              [0.632524444017817, 51.772646743004884],
              [0.633653367345624, 51.777145953322652],
              [0.633930955411636, 51.777605581930558],
              [0.637046439727335, 51.777205650495702],
              [0.640565386999254, 51.779643838345812],
              [0.644627340680083, 51.781592160538814],
              [0.645734239088983, 51.783088904444085],
              [0.648980478350313, 51.784173202666707],
              [0.649870474792008, 51.784786262591282],
              [0.651080600676146, 51.784824618131985],
              [0.653097698490996, 51.788433062541365],
              [0.654963659676718, 51.789216502115835],
              [0.654798054802066, 51.789680699427869],
              [0.655886996843085, 51.790887273300243],
              [0.655418151870554, 51.79142307605062],
              [0.655925490164704, 51.792430549178803],
              [0.655320931122081, 51.79384086680011],
              [0.653473092993417, 51.795592225513957],
              [0.654279694558838, 51.796311458405242],
              [0.654063476918128, 51.79687752168924],
              [0.654601975799619, 51.797106378577226],
              [0.654269374227191, 51.797913392663041],
              [0.654926049793402, 51.798721443455591],
              [0.654581571026319, 51.799104244330103],
              [0.655322061729524, 51.799363609073446],
              [0.655583688932987, 51.800063669019281],
              [0.6564632370266, 51.80064004479766],
              [0.656662440807927, 51.801511486174007],
              [0.659806489101369, 51.80361674354274],
              [0.659173658576589, 51.804818175318537],
              [0.659728594113452, 51.805668971739635],
              [0.660997448607112, 51.806226611052644],
              [0.66241642944698, 51.806229548581285],
              [0.664661836416753, 51.805314407049046],
              [0.666640711314165, 51.805218262106656],
              [0.667401637622855, 51.805968121364828],
              [0.668428324713475, 51.80596100311061],
              [0.669084987954709, 51.808358090149198],
              [0.670031252250343, 51.809443669762089],
              [0.673334654739972, 51.810421691290841],
              [0.676748111513902, 51.810022939742979],
              [0.678130627617257, 51.810705512540572],
              [0.678663369400079, 51.810636714263474],
              [0.677606231350276, 51.812358736202711],
              [0.677763075601089, 51.812874975105515],
              [0.678914038668964, 51.813761566241205],
              [0.679980296433816, 51.814002560781219],
              [0.679828178094435, 51.81513109000673],
              [0.680907821500071, 51.815694630403584],
              [0.681711024322093, 51.815707783786024],
              [0.682351229514492, 51.81620129623537],
              [0.682554854365049, 51.816819884334031],
              [0.681576354270719, 51.817520306730621],
              [0.682518283986823, 51.818451202920009],
              [0.681824721246938, 51.819087566618713],
              [0.682311345660621, 51.819633147289217],
              [0.682074476584375, 51.820116096141092],
              [0.684141157528657, 51.820949363168644],
              [0.686786714722553, 51.821290920510052],
              [0.689468446871187, 51.823018357573446],
              [0.689830132727802, 51.822975007529656],
              [0.690074770878334, 51.824060296154414],
              [0.689877059567451, 51.82444433788973],
              [0.689048217552011, 51.824636872694455],
              [0.689202201213767, 51.825613619117021],
              [0.69056551903583, 51.825646273743274],
              [0.691643843789061, 51.826521815612352],
              [0.692486458862191, 51.826730048403611],
              [0.693088922192824, 51.827875483313846],
              [0.696922151337425, 51.828652755109793],
              [0.697241675292485, 51.829117572303943],
              [0.698745090866693, 51.828408567657192],
              [0.700909753713012, 51.825168022229064],
              [0.70476179746668, 51.822676431539882],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000075",
        LAD13CDO: "22UL",
        LAD13NM: "Rochford",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [0.833875990716532, 51.570021199648025],
              [0.833708542949026, 51.56911596713644],
              [0.83417871072349, 51.569110901926983],
              [0.834326929539693, 51.567063016948545],
              [0.834811231409609, 51.566566542849692],
              [0.837768846330408, 51.565279944781885],
              [0.839026113482182, 51.562275243935446],
              [0.840252809977829, 51.560865881240332],
              [0.834993198210825, 51.560725128691146],
              [0.834571378583442, 51.560392658860593],
              [0.831377883816852, 51.560225171074777],
              [0.830475321827363, 51.560817168979376],
              [0.829567522953728, 51.562788943074203],
              [0.825749236980669, 51.564462123925637],
              [0.825208906854238, 51.566127310328291],
              [0.825608605843017, 51.566475635970242],
              [0.82501684414328, 51.566404450220631],
              [0.824408364456561, 51.567925573437634],
              [0.824934429091193, 51.567867934213623],
              [0.824974941687701, 51.567311139170421],
              [0.825837510536811, 51.566513291218087],
              [0.827582361206355, 51.566515306223323],
              [0.826856326809753, 51.567303580749517],
              [0.825704708343777, 51.567350220000037],
              [0.826385715370005, 51.568254779466209],
              [0.827260984832438, 51.568289444776212],
              [0.826579654485284, 51.568612557305102],
              [0.828074700081316, 51.569424635407927],
              [0.828673581085843, 51.569447067618221],
              [0.828887996188327, 51.569948249419532],
              [0.828123823337525, 51.569913615238377],
              [0.825759528094746, 51.56858285395343],
              [0.824924072904858, 51.568445588041392],
              [0.824645934063461, 51.568754480045691],
              [0.827198380154403, 51.56993143047012],
              [0.828894053154887, 51.572253224839066],
              [0.831305162146965, 51.573264412633399],
              [0.832154767217737, 51.574277292618802],
              [0.833734103573436, 51.572588774457351],
              [0.833875990716532, 51.570021199648025],
            ],
          ],
          [
            [
              [0.834714236474065, 51.59028115434927],
              [0.835786211617433, 51.588298186342946],
              [0.835028149733878, 51.588042198468763],
              [0.834466565464406, 51.588344472027543],
              [0.834900097763579, 51.587794365366761],
              [0.836129626535733, 51.587947218558263],
              [0.837461058432897, 51.585171898360642],
              [0.836671126196721, 51.583407526036808],
              [0.833978100966874, 51.581635182175162],
              [0.834207390751624, 51.581399399744207],
              [0.831809475817858, 51.578473161946626],
              [0.831291329670896, 51.578636767076894],
              [0.831493989084139, 51.578112031114742],
              [0.830383468397789, 51.576501956435159],
              [0.829736959555218, 51.574135088328745],
              [0.826854650422319, 51.572555123767252],
              [0.826143114002302, 51.570578337846626],
              [0.825616039012357, 51.570341008726857],
              [0.825115592099485, 51.570881902003286],
              [0.824951900235934, 51.570104280596325],
              [0.824391962742379, 51.570035022923797],
              [0.824493392813292, 51.570600091218118],
              [0.82371436139198, 51.569580961289041],
              [0.824178496619855, 51.569967313309533],
              [0.824945139316703, 51.569925466115322],
              [0.823493252631039, 51.569320067029714],
              [0.823249021047823, 51.568383392661829],
              [0.824085318891968, 51.565834190951414],
              [0.823617446587149, 51.564991041697233],
              [0.822919178742652, 51.564877440556216],
              [0.820665860724577, 51.565448802102928],
              [0.819989912710282, 51.566418403417082],
              [0.82104936483196, 51.566445088463254],
              [0.819674166601189, 51.5666796210811],
              [0.818350831427496, 51.567635994829146],
              [0.818951845987186, 51.568299685116159],
              [0.8194767733753, 51.567407470552219],
              [0.819423286868462, 51.568921518243961],
              [0.818922124439034, 51.568542333430464],
              [0.819465316607938, 51.56962382511653],
              [0.818145361356311, 51.571849142436875],
              [0.819214404172542, 51.573031320935719],
              [0.818290659262109, 51.573357512404613],
              [0.818740325009107, 51.573113766570991],
              [0.81861835016466, 51.572612144549154],
              [0.817878804185843, 51.572579549533742],
              [0.817555825639654, 51.572258135043754],
              [0.815787364859957, 51.573371768779175],
              [0.815544221001337, 51.57693286010894],
              [0.814322842373918, 51.577886736184041],
              [0.810660179266491, 51.578368489991064],
              [0.809350574678514, 51.579111273893467],
              [0.809536238739011, 51.581114242227976],
              [0.808035777191037, 51.585621885147923],
              [0.809565202365184, 51.587688909551566],
              [0.812158658480691, 51.590126137856814],
              [0.818309260969191, 51.594109377582178],
              [0.822733205661712, 51.595592213587551],
              [0.826767273147919, 51.596001425780592],
              [0.830113527061288, 51.595961215906136],
              [0.836323125925117, 51.594898368527588],
              [0.834679096342809, 51.591965648155664],
              [0.834714236474065, 51.59028115434927],
            ],
          ],
          [
            [
              [0.562872130859942, 51.618279824289381],
              [0.56330434476403, 51.617797334478681],
              [0.562584313927097, 51.617838241693981],
              [0.562872130859942, 51.618279824289381],
            ],
          ],
          [
            [
              [0.564673738280396, 51.621167204855546],
              [0.564196826543662, 51.620558575837336],
              [0.563737918494033, 51.621336633149674],
              [0.564978357166394, 51.621555944497615],
              [0.564867623675247, 51.621414632390142],
              [0.564673738280396, 51.621167204855546],
            ],
          ],
          [
            [
              [0.958097552577789, 51.61975508617774],
              [0.957116581136414, 51.617468352938481],
              [0.957042747023437, 51.616755189329943],
              [0.957538532750268, 51.616536728857369],
              [0.95622066275138, 51.616043793954496],
              [0.956214951837961, 51.615289339544958],
              [0.955774144072668, 51.615108873704209],
              [0.955929702879282, 51.614212747471072],
              [0.954907359119347, 51.614108988214802],
              [0.954668327761809, 51.614810246214091],
              [0.954164779954179, 51.614175357800534],
              [0.954274713503984, 51.613732781988276],
              [0.955306985169539, 51.613544890304382],
              [0.955286218187643, 51.613158670524975],
              [0.954443985385771, 51.612936146103287],
              [0.95461472166219, 51.612495636198766],
              [0.95383918708987, 51.612343379882489],
              [0.952702614846597, 51.612593244555605],
              [0.951468354876828, 51.611783362554362],
              [0.951587714122842, 51.61063991824696],
              [0.950206522892442, 51.610080156158922],
              [0.950719583032303, 51.609327044942823],
              [0.949685863804639, 51.609290982442936],
              [0.949765497551245, 51.608113462177649],
              [0.949244783663388, 51.607946673916601],
              [0.948026641944476, 51.608251609493614],
              [0.947860134892399, 51.606997531106444],
              [0.947238203911348, 51.606828780496521],
              [0.94772676146334, 51.606477430869113],
              [0.946063626710151, 51.606044325921623],
              [0.945689012907979, 51.605345005045294],
              [0.944664755311162, 51.604895836416567],
              [0.944474223713641, 51.604295322000866],
              [0.943926788253933, 51.604205630424275],
              [0.944035000101233, 51.603735226141104],
              [0.942863991050466, 51.603517273515003],
              [0.942823067826741, 51.602951677523798],
              [0.941686400887923, 51.602594344089326],
              [0.941463287411691, 51.60189121101083],
              [0.940471735119496, 51.601722698975422],
              [0.939531659704971, 51.600406152880524],
              [0.938788954108123, 51.600377993121789],
              [0.938703396093449, 51.59983599919579],
              [0.935114341525429, 51.597504672160184],
              [0.934516202065845, 51.596582462218564],
              [0.933262074560027, 51.596079586298345],
              [0.933507495573999, 51.595272083457246],
              [0.932327324911626, 51.59493013970085],
              [0.93236036240271, 51.59463610940162],
              [0.931513201785552, 51.594329902863073],
              [0.93158462216731, 51.593891908946873],
              [0.930684240788799, 51.594149151551647],
              [0.931026174836792, 51.593942739967403],
              [0.930467349228051, 51.59349441088343],
              [0.929402550435752, 51.593328536359806],
              [0.930203398312374, 51.592781485019927],
              [0.929836352516123, 51.592440787045099],
              [0.929164226827809, 51.592654541868576],
              [0.928204687044538, 51.592441058110097],
              [0.927133486440196, 51.591389414657968],
              [0.927631376905163, 51.590981252968234],
              [0.925278209254393, 51.591010282487915],
              [0.917979068718923, 51.586098797976788],
              [0.918066042289744, 51.585856494855918],
              [0.917528436612073, 51.585870767912674],
              [0.913710368992472, 51.58385119230244],
              [0.913879286515023, 51.58362574407532],
              [0.913380268526084, 51.583678613181526],
              [0.911108201369942, 51.582486663444755],
              [0.911528042589405, 51.582234304314362],
              [0.911145856116611, 51.581788695724136],
              [0.910590273608374, 51.581927500417521],
              [0.910363526433272, 51.58149691927909],
              [0.909828876828018, 51.581421144367951],
              [0.909383038521228, 51.581650757955998],
              [0.907977169371588, 51.580953513319251],
              [0.908206870550682, 51.58054938504165],
              [0.904734766119545, 51.579345691310493],
              [0.905258384540741, 51.578502578828235],
              [0.904263047392177, 51.578287090903004],
              [0.902914076185923, 51.578524649749944],
              [0.90160319372859, 51.577910421748335],
              [0.901632933290746, 51.577312486804104],
              [0.899747685210717, 51.5769714729825],
              [0.899173338210991, 51.575999034243921],
              [0.898243348292746, 51.575448204596505],
              [0.895930944043963, 51.574902729543133],
              [0.895262771712914, 51.575018159527794],
              [0.894497436700375, 51.574536086901752],
              [0.894327354536746, 51.573858542595396],
              [0.890422484627638, 51.572485231892401],
              [0.890554741932692, 51.571885673418798],
              [0.889584113048752, 51.571669453570195],
              [0.889514887544813, 51.571124327044892],
              [0.888734061606725, 51.571169644702572],
              [0.886996833825961, 51.57060802852375],
              [0.887071968639567, 51.570062944623977],
              [0.886542252677783, 51.569584014691067],
              [0.884403756080743, 51.569726568734339],
              [0.883338226930627, 51.569035952898652],
              [0.88192637467408, 51.568870090567522],
              [0.880073290882372, 51.569098189362933],
              [0.879183235441655, 51.568876307958028],
              [0.876175870216858, 51.567006512010543],
              [0.875069770944644, 51.565349969979799],
              [0.874099247440585, 51.564857507378726],
              [0.872145410279594, 51.564996213113616],
              [0.870265253460778, 51.563624802061796],
              [0.871004914622516, 51.563202869382934],
              [0.870942638287537, 51.562237546957164],
              [0.867078478570671, 51.559603325797092],
              [0.866424517986918, 51.559529373082327],
              [0.866339576715616, 51.558936051656552],
              [0.865297092720058, 51.55906225959788],
              [0.864776660625048, 51.558587503917572],
              [0.863993040264723, 51.558837788042858],
              [0.863805661102166, 51.558037408460166],
              [0.860820867544313, 51.557194681073838],
              [0.860488308653757, 51.5567926710725],
              [0.857314787833931, 51.55668836190285],
              [0.855013930768055, 51.557409050618361],
              [0.854280917763583, 51.556947519137474],
              [0.853586178291274, 51.556935646317605],
              [0.841727310747668, 51.560816670123877],
              [0.840115863175049, 51.561631878277048],
              [0.838851306732097, 51.563707701866065],
              [0.838798519635594, 51.565130010561738],
              [0.835784912980231, 51.56693063882102],
              [0.835126625475806, 51.570126781369204],
              [0.835251519469443, 51.572249009507068],
              [0.832694714459515, 51.575764426234478],
              [0.836229153862809, 51.580275765559442],
              [0.837452015671952, 51.578193793523155],
              [0.838811969937833, 51.578821934049195],
              [0.837927142725456, 51.580397483124017],
              [0.838619289484645, 51.581473486799311],
              [0.84189604087499, 51.581354567384651],
              [0.843846720970892, 51.582539446272484],
              [0.84551594437315, 51.582106831171203],
              [0.846514194609693, 51.580963773388575],
              [0.846033392098366, 51.580777575835398],
              [0.845954447171341, 51.58027673456278],
              [0.845496703486795, 51.580436238985627],
              [0.845137248691441, 51.580228206771459],
              [0.845796353692819, 51.580010754595683],
              [0.845942130856894, 51.579548530649234],
              [0.846509969049889, 51.580596027014934],
              [0.846211976645573, 51.578877336389496],
              [0.847823604904349, 51.577618648671603],
              [0.851439552239295, 51.576904822657994],
              [0.857246551098069, 51.576467603542667],
              [0.858220901925437, 51.574490390736919],
              [0.857104796015328, 51.575036531966084],
              [0.858415397600552, 51.573840788838439],
              [0.858015409336492, 51.573354973164676],
              [0.858540903583255, 51.57358680043771],
              [0.860363323178407, 51.572199580413411],
              [0.8623271182831, 51.571387188324145],
              [0.862758598847901, 51.571271428389892],
              [0.863369856968258, 51.571857299119323],
              [0.861676165196088, 51.572718880667964],
              [0.861117213476014, 51.572712730994681],
              [0.859509356584211, 51.573724183283808],
              [0.858434624912191, 51.576860468066876],
              [0.857884455002523, 51.577248020689758],
              [0.856358477002415, 51.577658439402796],
              [0.853612917010124, 51.577450057847912],
              [0.848403018611077, 51.578297106685049],
              [0.84863077492934, 51.578661223320566],
              [0.847997531275686, 51.57866850674548],
              [0.847562541976097, 51.579653103759213],
              [0.84791442286279, 51.581032313605483],
              [0.846446013285783, 51.582667066309995],
              [0.843691179111474, 51.583261826254258],
              [0.840650710671495, 51.581963937769252],
              [0.840047774421217, 51.582018111196597],
              [0.840613109546287, 51.584970592249462],
              [0.839613142013484, 51.587779298096429],
              [0.83805556360856, 51.589149880572641],
              [0.837637489595331, 51.592680181435057],
              [0.837967176500001, 51.59361385989591],
              [0.838489036865339, 51.594109384866734],
              [0.84003193909791, 51.594398516750864],
              [0.843185207367459, 51.594021737982388],
              [0.848660304886006, 51.592790758361545],
              [0.849335531961961, 51.593012695971339],
              [0.852788311667375, 51.592602299342545],
              [0.856638557975531, 51.592728049307034],
              [0.858626362113766, 51.592489853584368],
              [0.862719511251766, 51.593400945153377],
              [0.866795379643208, 51.595181126352337],
              [0.870048750192764, 51.597607268918168],
              [0.869602027877451, 51.59798874560029],
              [0.871159623083556, 51.601709176720547],
              [0.871109620600725, 51.604280851234122],
              [0.870675396527065, 51.604426385713921],
              [0.871140926065155, 51.604708193643184],
              [0.871746549538091, 51.608833251230024],
              [0.871343381562806, 51.609288316125223],
              [0.872160779640721, 51.610657861011731],
              [0.871579926105902, 51.610838466364619],
              [0.871528917317776, 51.611266024347088],
              [0.87312056927077, 51.613365799069314],
              [0.87434431132648, 51.614145272803263],
              [0.878586061750231, 51.615701545464624],
              [0.883212141134234, 51.616159962721234],
              [0.892405881765827, 51.615358957250891],
              [0.895633397728965, 51.614543641584483],
              [0.898308114999649, 51.614916498969436],
              [0.899576187744351, 51.613868853029466],
              [0.900738374547037, 51.613743888909035],
              [0.903437605514821, 51.614251831366126],
              [0.904574924028096, 51.615207615204632],
              [0.90661797003574, 51.614714515575287],
              [0.909132730971003, 51.614662978288813],
              [0.912945467109609, 51.615388621514143],
              [0.915271375443753, 51.616469482265444],
              [0.918074565122436, 51.615913221119392],
              [0.922859291261552, 51.617281715851028],
              [0.929046036537421, 51.616501441262876],
              [0.932881498414459, 51.618400485748481],
              [0.938796352551698, 51.619940645115548],
              [0.941747466329267, 51.620331675824332],
              [0.945244727813622, 51.620632474760455],
              [0.94759630538632, 51.620042772289537],
              [0.951087060125311, 51.61990915001067],
              [0.9517292396665, 51.619607885104344],
              [0.957485965741368, 51.620526002292138],
              [0.957932790980029, 51.6205093414206],
              [0.957665146095526, 51.620259759444679],
              [0.958097552577789, 51.61975508617774],
            ],
          ],
          [
            [
              [0.775755110697207, 51.631623131974123],
              [0.778731327432065, 51.62941129792268],
              [0.779834366962796, 51.627693459998198],
              [0.782889304702291, 51.625028166667789],
              [0.782479393322887, 51.625082856080574],
              [0.782761601220714, 51.624759585623302],
              [0.78317086651496, 51.624859602120196],
              [0.784388821089582, 51.624291085224968],
              [0.78324442491685, 51.623535783665652],
              [0.783611848666809, 51.623518973544442],
              [0.782669185974899, 51.622494465899315],
              [0.781945507462389, 51.620207439880915],
              [0.782223471079034, 51.620074937387812],
              [0.781650140538748, 51.619937436485401],
              [0.781414565102713, 51.619440273134536],
              [0.779734943403798, 51.61921476238328],
              [0.77804985669034, 51.619537972515218],
              [0.777920968459076, 51.619201961727534],
              [0.778648059011092, 51.619302562878886],
              [0.779413539825877, 51.618825754126476],
              [0.78063783059524, 51.618858803237387],
              [0.782764004199526, 51.619747339546983],
              [0.783059439842161, 51.619617118752451],
              [0.783180366938323, 51.622206235469406],
              [0.783635336299745, 51.622203537268057],
              [0.783313091063059, 51.622320904727665],
              [0.783825755114372, 51.623048025160493],
              [0.785053930651809, 51.623614263569557],
              [0.786252042227224, 51.623217977464265],
              [0.79241213100671, 51.623060748379693],
              [0.794466862027739, 51.622051302227945],
              [0.795194989218157, 51.622284883812746],
              [0.796913615614416, 51.6219669232473],
              [0.799114236156683, 51.621991792900097],
              [0.79953257219053, 51.621746176780199],
              [0.806807628343187, 51.622451062414704],
              [0.804827759391514, 51.622093688191207],
              [0.810071950835115, 51.621684908767932],
              [0.807169208233086, 51.622363267508568],
              [0.807876761573896, 51.62254330067033],
              [0.810626569253148, 51.621704901670874],
              [0.811164693481193, 51.621411405225949],
              [0.811216274435492, 51.620890330575889],
              [0.811369886656804, 51.621294966393734],
              [0.812373943562285, 51.621059551336295],
              [0.812380855941472, 51.620727516138523],
              [0.813065039959935, 51.620880197470846],
              [0.812750931764794, 51.620333712466042],
              [0.813321166791036, 51.620793112777008],
              [0.813248671194714, 51.620485465795156],
              [0.815500437135358, 51.620543876576477],
              [0.817209536943298, 51.619679024246203],
              [0.818276984939302, 51.619907912964607],
              [0.819829780104865, 51.618900177523074],
              [0.821804186156652, 51.618680928821973],
              [0.82191831055112, 51.618447943481925],
              [0.822156574833236, 51.618678749005142],
              [0.825820584477679, 51.618424176474569],
              [0.827225194248966, 51.618986626815214],
              [0.830219027355292, 51.618224625085404],
              [0.831646551024362, 51.618690236598276],
              [0.839438833465792, 51.617890339776743],
              [0.842253414022291, 51.617315839681879],
              [0.84231996392459, 51.616739522950674],
              [0.842604826591912, 51.617136443828663],
              [0.843384058671928, 51.617167927475073],
              [0.846864899299513, 51.616875762083467],
              [0.84650156145861, 51.616425892858608],
              [0.851984801728596, 51.616158726058231],
              [0.848764072402029, 51.61686563992037],
              [0.849061467963176, 51.617000521046897],
              [0.852117778432706, 51.616320980038232],
              [0.85605984088564, 51.616427446478447],
              [0.856550967008069, 51.616104303240427],
              [0.860197343162254, 51.616103607338339],
              [0.863964039536182, 51.615490077824148],
              [0.865716775121403, 51.615564199584348],
              [0.863611508025067, 51.609616701735369],
              [0.866111715636296, 51.601521426359241],
              [0.866107753980965, 51.59921189645371],
              [0.865093723553588, 51.597396520392849],
              [0.862496081221122, 51.596046998870946],
              [0.859817994993518, 51.595643738276259],
              [0.855198176660907, 51.595661838885079],
              [0.836177660133371, 51.597985868045228],
              [0.828923213429674, 51.598579244794344],
              [0.82114023404883, 51.598733393597492],
              [0.82010932111865, 51.598992918725685],
              [0.819018439746098, 51.599799798816761],
              [0.818648788509808, 51.601572362861234],
              [0.817527837562231, 51.603504173467307],
              [0.816572486376565, 51.60435634034188],
              [0.814145159642167, 51.605719587589512],
              [0.813913163550212, 51.605494911657203],
              [0.809849343917487, 51.606813666158828],
              [0.808259548021362, 51.608154927510988],
              [0.808533578847958, 51.608405589465804],
              [0.807974355502354, 51.608308351949042],
              [0.806069130944086, 51.608963712696628],
              [0.806223155342077, 51.609329672112047],
              [0.805776219527945, 51.609062454362935],
              [0.80497747570308, 51.60932078748128],
              [0.801537990240918, 51.610935503219373],
              [0.80094228944501, 51.611489350822971],
              [0.801718391624772, 51.611308033350433],
              [0.80195101534358, 51.611590277969803],
              [0.801561263408139, 51.611407119162408],
              [0.800938603891143, 51.611617149982486],
              [0.800393153402437, 51.613268830265923],
              [0.799146574237289, 51.614303177886015],
              [0.799548223963806, 51.614444689700754],
              [0.798550674893077, 51.614455896515594],
              [0.798862689952939, 51.614737152805695],
              [0.798189029114903, 51.614541868158021],
              [0.79819996844969, 51.614954419747647],
              [0.797773148603583, 51.614521207117058],
              [0.790971353318133, 51.613383609315484],
              [0.785322796081834, 51.613474669752705],
              [0.783754545634335, 51.612587335093757],
              [0.782334812753671, 51.612261263064745],
              [0.781638781100781, 51.611533087644183],
              [0.780908871985021, 51.612045943763746],
              [0.781154326176218, 51.611571553448123],
              [0.779197274794377, 51.611599940226846],
              [0.778317784584118, 51.61200570049273],
              [0.779057678945269, 51.611537592777623],
              [0.778226507689685, 51.611220912725528],
              [0.777277830762637, 51.610067895816627],
              [0.77689447264392, 51.609940265547827],
              [0.77578797996938, 51.610649970435176],
              [0.777164788696483, 51.609186490408611],
              [0.779023327439122, 51.609982495093234],
              [0.777425050911728, 51.609379989802505],
              [0.777495430088529, 51.610035763593828],
              [0.778266227011328, 51.610901595014653],
              [0.778625634593837, 51.61077886369781],
              [0.778495083690977, 51.61112461564597],
              [0.7790433012799, 51.610971329813886],
              [0.779021547274149, 51.611229964470681],
              [0.779924721152853, 51.611566517838028],
              [0.781135990657142, 51.611082730242529],
              [0.782758896544286, 51.612156771491158],
              [0.783524583273886, 51.612299595980183],
              [0.783635275915776, 51.611983988577833],
              [0.785696726314357, 51.612952251050473],
              [0.786005352191635, 51.612425086147539],
              [0.786256180963894, 51.612840935165899],
              [0.79016764816726, 51.612527635554265],
              [0.798116321197795, 51.6137593483271],
              [0.799283190761514, 51.612955354475552],
              [0.79937487112987, 51.612074478585996],
              [0.798800234798129, 51.612290547017572],
              [0.798854073415928, 51.611710065811756],
              [0.799261352956724, 51.611873029123849],
              [0.799509014809665, 51.611578420822795],
              [0.798935892899744, 51.611513849141126],
              [0.799043990456771, 51.610430222517799],
              [0.799525787593701, 51.611240755270046],
              [0.8011811200545, 51.60997269958434],
              [0.800314669840877, 51.609601256095168],
              [0.801771163199018, 51.60960875365631],
              [0.804233719681544, 51.608456279728017],
              [0.807102069827796, 51.607749128437426],
              [0.809120131390863, 51.605694316782483],
              [0.814055656207737, 51.604226069634002],
              [0.815914646313795, 51.602805415475466],
              [0.816951030545988, 51.599553564677187],
              [0.819080285940739, 51.597604726859622],
              [0.816244773923589, 51.596729384008782],
              [0.810979947810341, 51.594278939271312],
              [0.806929394015564, 51.59099876675532],
              [0.805392998915892, 51.590317816826825],
              [0.80421490065494, 51.589335085259592],
              [0.799568945706365, 51.587250678266066],
              [0.796360415291779, 51.58632620404876],
              [0.794895259038836, 51.586424079266457],
              [0.794283036977409, 51.586987284325019],
              [0.794122885939544, 51.586730280123966],
              [0.792424522285112, 51.586910125979784],
              [0.790300845252805, 51.587500286970851],
              [0.787721524489379, 51.587794551350591],
              [0.784863012264368, 51.587443340672436],
              [0.783957437510851, 51.587910026450373],
              [0.782849373100295, 51.586954202227034],
              [0.780312549786145, 51.586171644132968],
              [0.780467694717153, 51.585828003433988],
              [0.779867203073839, 51.585427624315017],
              [0.779964911222509, 51.585987417393703],
              [0.778148022138866, 51.584840590861923],
              [0.775018030385625, 51.584412829211352],
              [0.772996800214736, 51.583463224027163],
              [0.769553571718566, 51.584338717604439],
              [0.770124843639505, 51.584497907615585],
              [0.769290316188217, 51.584710078015945],
              [0.769057074456813, 51.584388212779729],
              [0.767224584113727, 51.583905324336165],
              [0.767081567354485, 51.583642484064022],
              [0.767728376137465, 51.583524695891789],
              [0.764613241889047, 51.583219521195261],
              [0.763548779429151, 51.583330044554323],
              [0.7638287026199, 51.583827097291348],
              [0.763242650112806, 51.583780646417573],
              [0.763081855730866, 51.583414792560994],
              [0.762436957855907, 51.583706987976321],
              [0.76299334432624, 51.584073418389799],
              [0.762388236642506, 51.584165015821689],
              [0.762085414061374, 51.583837580742198],
              [0.761485086618393, 51.584793356306641],
              [0.760000795832681, 51.585956999383363],
              [0.760173710477285, 51.586380131718812],
              [0.759666116558065, 51.586604325734385],
              [0.759838224924065, 51.586156887084471],
              [0.759175222974123, 51.586175181955163],
              [0.759344694111126, 51.587041785919034],
              [0.758868187498751, 51.586588019445848],
              [0.758535545064607, 51.5867928043983],
              [0.758906587790746, 51.586316405902707],
              [0.758398328748377, 51.586196151300243],
              [0.756349076580451, 51.586877477734582],
              [0.75630951491531, 51.587273230572102],
              [0.75586656811999, 51.587015625515733],
              [0.75557499530838, 51.587589077877475],
              [0.755019883652797, 51.587553550527801],
              [0.754231935238348, 51.588108077540021],
              [0.751080691261988, 51.586396776496905],
              [0.754707205608695, 51.585201847701306],
              [0.759732970004482, 51.584730263877233],
              [0.759927518429142, 51.583343354067324],
              [0.76051392466363, 51.582657725294837],
              [0.76033486507772, 51.581585392166978],
              [0.759086986674354, 51.581193845605753],
              [0.758424182176988, 51.580523214272915],
              [0.756542256096384, 51.580387581018236],
              [0.753509100607867, 51.580979556828837],
              [0.753572109865656, 51.580780216348771],
              [0.753274189271479, 51.580985970330431],
              [0.752897541665951, 51.580796949146539],
              [0.751307346834193, 51.581212893265572],
              [0.751455343638146, 51.580938711637415],
              [0.75093081595957, 51.581192944524119],
              [0.747607798593027, 51.581337382685206],
              [0.745735101075523, 51.581139303258361],
              [0.744517884574168, 51.580607482882769],
              [0.74131322457002, 51.580367641461017],
              [0.73942225410202, 51.5799126696242],
              [0.73932800550221, 51.579665745874671],
              [0.738351283740314, 51.579866626324076],
              [0.737422216391106, 51.579491690795358],
              [0.73691656933888, 51.579652785022262],
              [0.736526568521698, 51.579336313302406],
              [0.733510873066509, 51.578923669400865],
              [0.73162892212261, 51.579001686951628],
              [0.731294345701999, 51.579415991560943],
              [0.731339633678576, 51.578847436919879],
              [0.730365542932196, 51.579116541228757],
              [0.730071647606405, 51.579029847788036],
              [0.730455840282084, 51.578841032041943],
              [0.729205555487849, 51.578548156247521],
              [0.729049433386843, 51.578953805228551],
              [0.728620315480609, 51.578658003821168],
              [0.727817603746219, 51.578727937731671],
              [0.724022376939557, 51.580097730438503],
              [0.721805274036023, 51.579834418821243],
              [0.72155212072185, 51.578907651602776],
              [0.722746321300342, 51.579687567515172],
              [0.724089969677248, 51.579538554474389],
              [0.724103483294739, 51.578992326082059],
              [0.725608155953122, 51.578398866715546],
              [0.72509259954792, 51.577357688490508],
              [0.725562522313119, 51.577130023076187],
              [0.724032381521495, 51.57633185633663],
              [0.721044894190898, 51.576047747560395],
              [0.720563099857018, 51.575714466503776],
              [0.719433903657913, 51.574933022072223],
              [0.71717900200614, 51.574853971794646],
              [0.716140902806586, 51.574073983347134],
              [0.714298905984383, 51.573632765202845],
              [0.71362982799184, 51.572649952318031],
              [0.714366842639005, 51.573513376382515],
              [0.716363340234263, 51.57402836087175],
              [0.717151530110578, 51.574708011791792],
              [0.7189788495471, 51.574491190927937],
              [0.720361997157297, 51.57498165152348],
              [0.7208564288979, 51.57564740523398],
              [0.721469290689474, 51.57583104770633],
              [0.723315146199338, 51.575585849062911],
              [0.724475256301583, 51.575748674625054],
              [0.727678874737684, 51.576918042402859],
              [0.730917981812315, 51.576437958959076],
              [0.7323762371724, 51.576589268005399],
              [0.732443027420131, 51.576788270913788],
              [0.731741010704314, 51.576899955269084],
              [0.732163799982263, 51.577042101064329],
              [0.733227326395462, 51.57689321116424],
              [0.732681920298661, 51.576583945560969],
              [0.733435810817606, 51.576639228275191],
              [0.739284904397106, 51.577845532916903],
              [0.744015778948802, 51.57783298650574],
              [0.745821418170082, 51.5790039803626],
              [0.747697486355697, 51.579426821006997],
              [0.752933289556285, 51.578666402292605],
              [0.754745898909337, 51.578707488149107],
              [0.754848706628447, 51.578235602310492],
              [0.755203459464894, 51.578683250773288],
              [0.757739369384406, 51.578583185205972],
              [0.759708831909676, 51.578902009611653],
              [0.759700193852422, 51.578497495818439],
              [0.760359612148065, 51.578160004495409],
              [0.760029028027898, 51.577921356026188],
              [0.760858439048927, 51.57800706307416],
              [0.759900732548832, 51.577686041418502],
              [0.759997390430146, 51.577161232303268],
              [0.758104530783489, 51.576914360506734],
              [0.757952832787888, 51.576649914891746],
              [0.761012474589157, 51.576357587366616],
              [0.760715437938539, 51.577529265160372],
              [0.761174068296298, 51.577475299892043],
              [0.761524733219083, 51.577807007047106],
              [0.761891752835084, 51.577575321439696],
              [0.762726059371836, 51.577694176730937],
              [0.760516321026574, 51.57867345503572],
              [0.764603969822781, 51.580546807222795],
              [0.767692146937735, 51.580554025134177],
              [0.770641260785813, 51.579699249785349],
              [0.77127710944139, 51.58108635126068],
              [0.772810382577191, 51.580947587632657],
              [0.774954901406424, 51.581411315188795],
              [0.777903099080614, 51.58255388771866],
              [0.77899126242121, 51.582241212174644],
              [0.782615475719472, 51.583458486492937],
              [0.785106631862355, 51.584794294501911],
              [0.787710239674989, 51.58496988231078],
              [0.790697206896742, 51.584145180389221],
              [0.793906964616291, 51.583870909581634],
              [0.794268478044737, 51.583504346382533],
              [0.795933905021376, 51.583777644186448],
              [0.795704783628516, 51.583525883523933],
              [0.798129397532024, 51.584242433762228],
              [0.800036114912093, 51.584259890594588],
              [0.800472596575132, 51.584643397935395],
              [0.80584928392542, 51.586125695039172],
              [0.807449236891084, 51.581331497419171],
              [0.806748777679473, 51.579710491289788],
              [0.802693161142177, 51.578244348466974],
              [0.79944581646375, 51.577688735856299],
              [0.798284337713087, 51.57802583733146],
              [0.798093500993424, 51.57771560773061],
              [0.797162162125381, 51.577569638048843],
              [0.797245531943427, 51.577775406112693],
              [0.794869653990888, 51.577117937152238],
              [0.793444265625724, 51.576329862122179],
              [0.793906346340188, 51.576238811619532],
              [0.794783548493247, 51.576843879982668],
              [0.793685470236701, 51.575132442231933],
              [0.795330046713493, 51.572743184875883],
              [0.7947066088787, 51.572320943922279],
              [0.795450981677259, 51.572620685285884],
              [0.797557732476567, 51.571414752492032],
              [0.798204660433571, 51.570551205822468],
              [0.800378239563215, 51.570341037674901],
              [0.801279337868406, 51.569223175518104],
              [0.801140464068514, 51.568865841190359],
              [0.797421638205128, 51.567789005190811],
              [0.796533030068904, 51.567254380617399],
              [0.794494913063296, 51.567035845031434],
              [0.789554345138086, 51.56732973765336],
              [0.789357784674898, 51.56704211488794],
              [0.78925692020173, 51.567374585310823],
              [0.789191075715088, 51.567147709301977],
              [0.78708564557399, 51.567573700704173],
              [0.785697608208561, 51.56668121828438],
              [0.787464224063534, 51.567298488018537],
              [0.789109841531345, 51.566999445094119],
              [0.789019611116911, 51.56677494766479],
              [0.790115080257824, 51.567060076698979],
              [0.79061784227822, 51.567022033160882],
              [0.790507946655552, 51.566759331473499],
              [0.790811554660942, 51.567003933161381],
              [0.793774742926445, 51.566893812319186],
              [0.794580031818296, 51.566612008306564],
              [0.794853264679899, 51.565537934872239],
              [0.794954330284311, 51.566220853037819],
              [0.795682873211758, 51.566005625449712],
              [0.794806616616284, 51.566565237649321],
              [0.794988743766745, 51.566852296161429],
              [0.796838674045549, 51.566779415261408],
              [0.798959933107376, 51.567787379702033],
              [0.80166584843328, 51.568561893050486],
              [0.801774095955909, 51.568280499489269],
              [0.802352673510631, 51.568278368403355],
              [0.801922300848228, 51.569376000882819],
              [0.80293886939796, 51.56905671410393],
              [0.804726554134715, 51.569120103935106],
              [0.804156541195897, 51.569589719472255],
              [0.80286462243949, 51.569233867545393],
              [0.8003553697828, 51.570954960319156],
              [0.798906545968831, 51.570934681755389],
              [0.798481489983186, 51.571462866655381],
              [0.798704068518256, 51.571749853781803],
              [0.801852915394201, 51.572072199229119],
              [0.806991525968537, 51.569645118575814],
              [0.806819556875613, 51.568868571979884],
              [0.80612038719295, 51.56869373432319],
              [0.807458321643211, 51.567641817589468],
              [0.806909757967577, 51.567428303666894],
              [0.80559714622313, 51.568024521886805],
              [0.804536714158737, 51.568029198592981],
              [0.804404657991814, 51.567525105878815],
              [0.803743615279945, 51.567218932466147],
              [0.804577423852693, 51.567073083263764],
              [0.805717189833287, 51.567607035804301],
              [0.806887735644011, 51.5670474933018],
              [0.808190280787734, 51.567131433212552],
              [0.807969749293004, 51.567955149987156],
              [0.807101341188972, 51.568218770880677],
              [0.808257781323324, 51.568928578682964],
              [0.808234482130958, 51.568386809949118],
              [0.809883490224524, 51.569805210990182],
              [0.80474444131987, 51.571613656837599],
              [0.804390434239584, 51.571888338301342],
              [0.804817146388833, 51.571739630108567],
              [0.804764217896566, 51.572098848997442],
              [0.804001882998648, 51.572211511626428],
              [0.804464381826028, 51.572620463985039],
              [0.803798751806533, 51.572452005190513],
              [0.800635500146633, 51.572905320367546],
              [0.799522948588843, 51.572510072251966],
              [0.798274298801155, 51.572647794866128],
              [0.796594042522364, 51.57337229747619],
              [0.795638502002418, 51.574593947984667],
              [0.795826365549965, 51.575420495203794],
              [0.79667348928533, 51.575982197013538],
              [0.803204980022874, 51.577085409511398],
              [0.808032068910896, 51.578727333290644],
              [0.810227393087964, 51.577717815646082],
              [0.812501132012142, 51.577184846052553],
              [0.81287815451316, 51.577352982883248],
              [0.812647422161262, 51.57709949447181],
              [0.813627065202854, 51.576998651748021],
              [0.814351834504455, 51.576440735502082],
              [0.814452949676604, 51.575064054999245],
              [0.813459087166186, 51.574863953510388],
              [0.814403877516802, 51.57487726186325],
              [0.813892371942694, 51.574401170646688],
              [0.813755474417245, 51.574708445700892],
              [0.813406413050401, 51.574618786627539],
              [0.812886153567861, 51.573930647192888],
              [0.814263723028315, 51.573823851978744],
              [0.815035870607589, 51.572492225228423],
              [0.816689418009919, 51.571854437238677],
              [0.818082050687856, 51.569613000574492],
              [0.817241318575923, 51.568130330734576],
              [0.817523675508581, 51.566722309861746],
              [0.818520519782413, 51.56648340518705],
              [0.820981474665394, 51.564370045200171],
              [0.819855993097345, 51.563951917079152],
              [0.818293844072268, 51.563962485806933],
              [0.817976904277887, 51.562827885171821],
              [0.81826248076905, 51.562522427644126],
              [0.81768530422032, 51.562079409628879],
              [0.818928201301707, 51.561807606977972],
              [0.820719848603194, 51.56333755001225],
              [0.82272125926353, 51.563266910935532],
              [0.824637092651316, 51.563561650033613],
              [0.827657334056132, 51.562771159043301],
              [0.829040165085549, 51.562057874127078],
              [0.8296814445216, 51.560534133425215],
              [0.829766641295642, 51.559489689813731],
              [0.829120128559492, 51.559046601592179],
              [0.829576809761892, 51.558547217149808],
              [0.830593789335515, 51.559472426167488],
              [0.829896597319382, 51.559793244438616],
              [0.829765705374059, 51.560334235464083],
              [0.831756688994668, 51.559587354055417],
              [0.833825381393566, 51.559696574982986],
              [0.834386562770532, 51.559161368888674],
              [0.834323810713788, 51.559776267098215],
              [0.837810233749603, 51.560291844230996],
              [0.840292356964171, 51.560134620892896],
              [0.849281662194062, 51.557203101560255],
              [0.850469989113654, 51.556617500690336],
              [0.850724677495167, 51.555937666758915],
              [0.850539416317096, 51.55479814640492],
              [0.850129134549331, 51.554743362806185],
              [0.850911271281343, 51.553915799048106],
              [0.848414441831675, 51.551292695091291],
              [0.844259008231223, 51.549294385086036],
              [0.842952537668895, 51.549441188811073],
              [0.840086348839816, 51.548240705879799],
              [0.839110745579611, 51.548117730727306],
              [0.839424561068305, 51.54771350184263],
              [0.83912089949819, 51.547493309158092],
              [0.837990974018234, 51.54688389444231],
              [0.837465432203168, 51.547015331974926],
              [0.837533210793754, 51.546667427282514],
              [0.836254082014052, 51.546464532528375],
              [0.836007486371403, 51.546001915569683],
              [0.834789424188065, 51.545899158926893],
              [0.834661566231366, 51.5455595830012],
              [0.834318946467081, 51.545686584330404],
              [0.834078369930446, 51.545134778566528],
              [0.832677005128798, 51.545099387518484],
              [0.831666567916875, 51.544414175881577],
              [0.830966867639347, 51.544667609776852],
              [0.831176070839505, 51.544343276195129],
              [0.827937525370041, 51.543733394280437],
              [0.82661129118807, 51.542908256115901],
              [0.824172270374261, 51.542070337479942],
              [0.823832736832119, 51.541502007944565],
              [0.821658359734267, 51.541069583234382],
              [0.821226228044985, 51.540659068520654],
              [0.819138013029516, 51.542202274020084],
              [0.814838807015464, 51.54372748582027],
              [0.810837824291202, 51.540127892070707],
              [0.807928929575722, 51.540950372050922],
              [0.798396473339921, 51.541673815927489],
              [0.794345152071798, 51.542335243378439],
              [0.788960102711658, 51.542440064334642],
              [0.78762637378263, 51.545160026044165],
              [0.787440228132632, 51.546928134900185],
              [0.786773999399334, 51.546931378401098],
              [0.786768331090958, 51.547263384174542],
              [0.786659487581453, 51.552939254832062],
              [0.775239091451501, 51.55196444146317],
              [0.769830931706337, 51.551779320298493],
              [0.769524804218236, 51.552391830480282],
              [0.761129896375964, 51.55296123008349],
              [0.760998327114221, 51.553289901320426],
              [0.757657008453017, 51.554123094838332],
              [0.753123997703856, 51.554862758792119],
              [0.75335221096824, 51.55751774563057],
              [0.75155262646114, 51.557988982032562],
              [0.752062554156801, 51.55835745147975],
              [0.749042369354815, 51.559356436604247],
              [0.743760744280657, 51.559871346520794],
              [0.741717488244185, 51.559893049097205],
              [0.741047585102325, 51.559238677095948],
              [0.738996515371913, 51.55934595521525],
              [0.737543093767085, 51.559482418265638],
              [0.737348713137506, 51.560017583409127],
              [0.735334104212464, 51.560132040329961],
              [0.730507943452413, 51.559438667594719],
              [0.730441394059247, 51.560495176103153],
              [0.722810328351024, 51.56040547577426],
              [0.718362114001972, 51.560941256417806],
              [0.719026604863648, 51.56267062348595],
              [0.715776208188572, 51.562801693977626],
              [0.715782092447761, 51.563166700136705],
              [0.707910633264091, 51.564283142404463],
              [0.708557768332608, 51.56643027067372],
              [0.70720489346524, 51.566661163789824],
              [0.705630691214507, 51.567490726770252],
              [0.704989244098467, 51.567114298916273],
              [0.704148345509097, 51.566756856051292],
              [0.687137709984068, 51.568208153862557],
              [0.685006208133069, 51.568893713703851],
              [0.683759302746042, 51.56859938068505],
              [0.680734481016015, 51.56950675511959],
              [0.675390074983135, 51.570139614913401],
              [0.67530468825114, 51.572863018970011],
              [0.667804296319439, 51.572202842508908],
              [0.667688464558569, 51.572689330653851],
              [0.662921247400589, 51.572440610355414],
              [0.660090364805696, 51.573096619464415],
              [0.651280535542947, 51.573492136928365],
              [0.646552689217093, 51.574293204145214],
              [0.643926793679535, 51.57507999669717],
              [0.638922602989595, 51.576796225749185],
              [0.637774202421052, 51.576257262909763],
              [0.635651197255035, 51.574464912032916],
              [0.634617612577753, 51.574936007835184],
              [0.632192535081452, 51.570710441818001],
              [0.632049034227376, 51.570524798291046],
              [0.612911652743348, 51.575468282525634],
              [0.59858432843795, 51.576922416460349],
              [0.598946244387825, 51.577194094795004],
              [0.593293987935094, 51.577727487569248],
              [0.591288718354018, 51.577923789715037],
              [0.5892768181945, 51.578613043593812],
              [0.588352578662392, 51.579494110991412],
              [0.587462555310191, 51.579631559291258],
              [0.582665020269716, 51.582949726453926],
              [0.570075543108665, 51.587678836212788],
              [0.567727954945838, 51.588188903485673],
              [0.565891563615595, 51.588240773136306],
              [0.565420585315021, 51.588644982900483],
              [0.562271113455731, 51.58935144000786],
              [0.562716741646628, 51.590881371687573],
              [0.563722799832014, 51.59159414992687],
              [0.56553946794499, 51.593854043263576],
              [0.549007435107937, 51.596574236114805],
              [0.549757122823283, 51.598176747761968],
              [0.54932136873123, 51.602333044059748],
              [0.55047478693996, 51.603303540166195],
              [0.551517717041646, 51.608844141320091],
              [0.553613218235174, 51.609018893256049],
              [0.556618342978249, 51.608390472678956],
              [0.558221038270684, 51.610640749274921],
              [0.561594382817956, 51.612471946455088],
              [0.561800373553623, 51.614410910318703],
              [0.563665241576866, 51.61451227757555],
              [0.563549454919088, 51.612458029605349],
              [0.564485671154535, 51.611974415312687],
              [0.563697473980581, 51.612521346533811],
              [0.564098270553299, 51.616709782199123],
              [0.562964653759405, 51.618409106272381],
              [0.564253718634248, 51.619876537168999],
              [0.564675689848948, 51.620933768961692],
              [0.565038697740918, 51.621432246992811],
              [0.566399713569233, 51.622420605329687],
              [0.567012626658659, 51.622426085813096],
              [0.567514537685826, 51.622026593491633],
              [0.570591492516582, 51.622120213880507],
              [0.571381227193146, 51.622493223216381],
              [0.574433725630663, 51.622945214250578],
              [0.575081972813739, 51.623752983560514],
              [0.577205414520768, 51.624202802553086],
              [0.578093742953262, 51.624784046494142],
              [0.580322310453929, 51.624386124632586],
              [0.581018351941695, 51.623844706795772],
              [0.583197695342754, 51.623878594298077],
              [0.584779617781632, 51.62293453267602],
              [0.586819447745437, 51.623208850290425],
              [0.586527776354676, 51.622628012371401],
              [0.587565858295247, 51.623528746570564],
              [0.586575242304968, 51.625062370063034],
              [0.586963831240968, 51.626279600329873],
              [0.588851257957257, 51.627756958439797],
              [0.589209387348103, 51.628564755953654],
              [0.588110724568811, 51.63035888413571],
              [0.592425750428589, 51.630460600066151],
              [0.59575704309595, 51.631352853353377],
              [0.596187383491838, 51.630259632626455],
              [0.595893220600838, 51.630068285472021],
              [0.597308009000318, 51.62963947524468],
              [0.602035143229853, 51.629569834819478],
              [0.605017502137763, 51.628935289703669],
              [0.606359539208247, 51.62857543451706],
              [0.610220075656123, 51.628942899289719],
              [0.617316623351637, 51.630774185563602],
              [0.622313878065188, 51.631276879684485],
              [0.62699873300012, 51.63157321089917],
              [0.627487539293902, 51.631329338408705],
              [0.628351607691678, 51.631602276067525],
              [0.63017747214896, 51.631538885847],
              [0.63073984137591, 51.631242987734289],
              [0.633793456001789, 51.631493763107215],
              [0.634949417587177, 51.631242079272944],
              [0.63477590625843, 51.631489697069078],
              [0.637169524551433, 51.631672460889085],
              [0.639576239435958, 51.631234333722091],
              [0.641308029873711, 51.630074785650635],
              [0.643375627294158, 51.629646909456625],
              [0.644493325026145, 51.629956284770024],
              [0.644661447662872, 51.629294175166493],
              [0.645009532369413, 51.629316008683773],
              [0.645135668327415, 51.62996878864837],
              [0.647762326662381, 51.629860990320545],
              [0.648211352347193, 51.625587956522111],
              [0.648559479275104, 51.629727885797962],
              [0.648990106878017, 51.630174134725578],
              [0.648577879245654, 51.630018858696616],
              [0.648744396747275, 51.630520532981031],
              [0.648093903116552, 51.630864373738703],
              [0.647636959259642, 51.630561711312716],
              [0.646534894943089, 51.63062074490805],
              [0.647296883545182, 51.632141439728805],
              [0.646874240122109, 51.633395667121768],
              [0.647968831940178, 51.634621065042914],
              [0.648629743653622, 51.634455060933007],
              [0.649646214849131, 51.635405208196502],
              [0.651568555737316, 51.635903197625709],
              [0.655368282349672, 51.635336136346517],
              [0.655253395166595, 51.634952916010249],
              [0.654537396173433, 51.634967314708078],
              [0.654961296221076, 51.634579100784563],
              [0.654646506551418, 51.634039418982638],
              [0.6562522205758, 51.633892466826197],
              [0.657776526272027, 51.632699596623105],
              [0.657692722446643, 51.630475609412088],
              [0.658477074394979, 51.629876865029779],
              [0.657555596658125, 51.629885145336935],
              [0.657447715060611, 51.628830854749324],
              [0.655396991805457, 51.628505844965531],
              [0.657746829058747, 51.628608237434342],
              [0.65776440712817, 51.628562871937135],
              [0.657814890322927, 51.629772250769186],
              [0.658742561779529, 51.629820486998028],
              [0.657955960579068, 51.630479540437946],
              [0.65805046046489, 51.632762642239861],
              [0.656461685707984, 51.634063997648397],
              [0.655163996431162, 51.634090666423212],
              [0.655856235955762, 51.634881716904317],
              [0.656942575406234, 51.635219564506016],
              [0.65847591466957, 51.634575982337296],
              [0.661591336957922, 51.633999934639881],
              [0.666718260245848, 51.634357503592433],
              [0.668691142927633, 51.633843196763003],
              [0.668844395300892, 51.633302800261731],
              [0.669450216317321, 51.633258443575293],
              [0.669751766386, 51.633717446881057],
              [0.67149923714378, 51.633991553437774],
              [0.67569053326807, 51.63379803872413],
              [0.685532989881804, 51.634370045111979],
              [0.707386108467389, 51.633589126454446],
              [0.711881298430318, 51.633647218842675],
              [0.718213413509703, 51.634817380114981],
              [0.718498745419557, 51.634441134864538],
              [0.720727579559983, 51.635725028970249],
              [0.721947340155133, 51.635772290018892],
              [0.724721528994588, 51.634843628681288],
              [0.72596611275957, 51.634750870806556],
              [0.729366929988936, 51.632611392525725],
              [0.732160474967763, 51.631385312723673],
              [0.737627281691385, 51.630494348189288],
              [0.738898505299857, 51.630917963012664],
              [0.739554175464545, 51.630647239296451],
              [0.751631336534843, 51.631486176042827],
              [0.757163044664804, 51.633743248759522],
              [0.760576997764149, 51.636531960812697],
              [0.764065042980703, 51.636872558558089],
              [0.764305785893335, 51.63652962607808],
              [0.76710283594606, 51.635823358230617],
              [0.77253360496643, 51.633277679117889],
              [0.775238137316182, 51.632410614281],
              [0.775755110697207, 51.631623131974123],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000076",
        LAD13CDO: "22UN",
        LAD13NM: "Tendring",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [1.039599828129654, 51.804243872682534],
              [1.033897130975977, 51.802455758298485],
              [1.031266330957523, 51.802035286813108],
              [1.028423329829656, 51.802213826988243],
              [1.02551505560806, 51.803323952438156],
              [1.029468090220517, 51.804055837052601],
              [1.030990094835885, 51.803928444866315],
              [1.035990960692922, 51.804383044757948],
              [1.039599828129654, 51.804243872682534],
            ],
          ],
          [
            [
              [1.268087314567242, 51.860160921597164],
              [1.269120290266824, 51.859542164299114],
              [1.269376718646267, 51.859732908154285],
              [1.269976971266964, 51.859316859649347],
              [1.269233704657391, 51.858762794861704],
              [1.267800534097913, 51.859130944141221],
              [1.268134142538949, 51.859664928872782],
              [1.267447121132761, 51.860186815145575],
              [1.268087314567242, 51.860160921597164],
            ],
          ],
          [
            [
              [1.254439051856957, 51.873606588339975],
              [1.256336763960703, 51.873124105395235],
              [1.257827741769391, 51.873306752871855],
              [1.260086293077856, 51.872309625068368],
              [1.260531861393764, 51.872503237583778],
              [1.264581097343789, 51.872237049382633],
              [1.265416615168309, 51.870814320396654],
              [1.266996441531912, 51.869477933280606],
              [1.26759409371498, 51.867702023186617],
              [1.267314047849529, 51.867566797324471],
              [1.266503695758501, 51.868333153215275],
              [1.266479699247506, 51.867761778507088],
              [1.267014462077035, 51.867322382636132],
              [1.266491359161457, 51.866900693780529],
              [1.266816337257783, 51.866754048573618],
              [1.267855867306706, 51.867184765303591],
              [1.268488842576907, 51.86541596239136],
              [1.268118676937534, 51.864879397210359],
              [1.264672166453591, 51.863950714549659],
              [1.263305382887592, 51.863954489911606],
              [1.263573190746772, 51.864101757489259],
              [1.261807271447283, 51.865174333215435],
              [1.261652573961627, 51.866489103625163],
              [1.258769223980217, 51.868706133219327],
              [1.256062306950944, 51.868664219806782],
              [1.254963672690312, 51.869244201004328],
              [1.251828124848392, 51.869464100301983],
              [1.251912969084726, 51.870196590011844],
              [1.251385098492624, 51.870935235519028],
              [1.251666687656434, 51.871454514209631],
              [1.252673926060575, 51.872183070923178],
              [1.253137506226979, 51.873431247733286],
              [1.254439051856957, 51.873606588339975],
            ],
          ],
          [
            [
              [1.263715197236415, 51.874965695576243],
              [1.263505734566625, 51.872977463153717],
              [1.26197091650263, 51.873513853271476],
              [1.255084558499226, 51.874516031450099],
              [1.255594817066805, 51.874860775023961],
              [1.254934666714039, 51.874854771078063],
              [1.254615671659376, 51.875608333919885],
              [1.25521718288719, 51.875828229814822],
              [1.257304163092865, 51.87498791302027],
              [1.259612930748891, 51.875586799262194],
              [1.260342360158882, 51.876870750508893],
              [1.259600504048267, 51.878366394901768],
              [1.260861371585636, 51.879864062495571],
              [1.262184971411263, 51.880681803090042],
              [1.263410841658344, 51.879669194793706],
              [1.26392680468771, 51.878246355705386],
              [1.263715197236415, 51.874965695576243],
            ],
          ],
          [
            [
              [1.212512112085805, 51.882243945150911],
              [1.212115741842003, 51.881495662135968],
              [1.210495414200523, 51.881721605593924],
              [1.209832363468986, 51.882246985184452],
              [1.210571278356767, 51.882439072311598],
              [1.212512112085805, 51.882243945150911],
            ],
          ],
          [
            [
              [1.207135134177078, 51.883086004244767],
              [1.20779423349541, 51.881770155832569],
              [1.205085790195827, 51.883248023252236],
              [1.205323767084247, 51.883671458923956],
              [1.206366639253786, 51.883682596036998],
              [1.207135134177078, 51.883086004244767],
            ],
          ],
          [
            [
              [1.256462687672407, 51.884009154993628],
              [1.257005679545491, 51.883719785975579],
              [1.258365901750701, 51.883757646155011],
              [1.257992250657526, 51.882992690475604],
              [1.258799420999436, 51.882678902136625],
              [1.258932328034413, 51.882868596019662],
              [1.258147625625701, 51.88307113229132],
              [1.258697413260617, 51.883618939110193],
              [1.260261164186245, 51.881931418610279],
              [1.259924644237306, 51.88025431265968],
              [1.25850405833374, 51.878396791939373],
              [1.258985017107658, 51.876307566178816],
              [1.257464480139912, 51.875697621031669],
              [1.255547804489241, 51.876481956338161],
              [1.254058402589148, 51.876724667072715],
              [1.252869834061421, 51.876263773387521],
              [1.25185203133484, 51.874987755520813],
              [1.251248924745409, 51.874906399375199],
              [1.250913729513719, 51.875597439284576],
              [1.249429999341583, 51.874247040369042],
              [1.248292052881763, 51.874224524278439],
              [1.249794388747742, 51.876155455950958],
              [1.251296220025343, 51.876366668903636],
              [1.25164025679522, 51.876683647110234],
              [1.250892080982665, 51.876726825416505],
              [1.25086493217274, 51.877035181984972],
              [1.252060619488622, 51.876689108145506],
              [1.252634122967428, 51.877285754860893],
              [1.2532485306741, 51.877261557869915],
              [1.25355069280276, 51.876777395530937],
              [1.253435890286646, 51.877139446476697],
              [1.254031260051577, 51.877213810423626],
              [1.2526913430422, 51.877393902400719],
              [1.250231537780707, 51.876957356223301],
              [1.248261571681596, 51.875051044971372],
              [1.246041495772145, 51.874551124031449],
              [1.246119275089749, 51.873533518166752],
              [1.24694312142197, 51.872766935431116],
              [1.243669901525525, 51.871038650714539],
              [1.244443289620087, 51.870580183726162],
              [1.244036348858826, 51.870538348363041],
              [1.244907286047318, 51.869215530517515],
              [1.244527602759399, 51.868825763924256],
              [1.241467370429621, 51.869708886273258],
              [1.239918416954895, 51.869363934456686],
              [1.238035396481033, 51.870172240435146],
              [1.235569784523048, 51.872135230291654],
              [1.234144316443895, 51.873935538981279],
              [1.233166990917366, 51.874368964811531],
              [1.23180616689637, 51.874156343270649],
              [1.2283931963227, 51.874465086073428],
              [1.227286859474649, 51.874234630004189],
              [1.225880890842996, 51.875054822372128],
              [1.226380651323713, 51.876209463068349],
              [1.226898991907646, 51.876385914501768],
              [1.22702538147055, 51.879265108654366],
              [1.227812863309562, 51.879891077068521],
              [1.227889344756723, 51.880674176837935],
              [1.229394346291537, 51.881415345946614],
              [1.232893267760333, 51.881952376669382],
              [1.233813545087196, 51.881592478772845],
              [1.233163192128486, 51.881258690112269],
              [1.233435813944549, 51.881047921460699],
              [1.234390214000124, 51.881385935968183],
              [1.234458720296843, 51.882036136606835],
              [1.236413249259309, 51.881931977928751],
              [1.237006829999536, 51.881697973762485],
              [1.23825551157094, 51.880044612962379],
              [1.239160670269103, 51.87981910362754],
              [1.238596780646642, 51.878889339116803],
              [1.23978758305839, 51.879220796946811],
              [1.239520543915936, 51.879912621746215],
              [1.238204691812518, 51.880772751521398],
              [1.238221044975105, 51.88120222815521],
              [1.24120872712942, 51.881863722729236],
              [1.242890994682752, 51.88177325798128],
              [1.242806452459334, 51.881002979673987],
              [1.244737072752119, 51.88121324242524],
              [1.245011729821984, 51.881495278025945],
              [1.246968754006943, 51.88140616453456],
              [1.250278969902088, 51.882788859468036],
              [1.256855785485769, 51.883395645676487],
              [1.256462687672407, 51.884009154993628],
            ],
          ],
          [
            [
              [1.225640617432297, 51.888640054266588],
              [1.226201527555289, 51.88839441083352],
              [1.227162392172626, 51.888679241363619],
              [1.227776895625265, 51.888248636311246],
              [1.230094422335199, 51.885400362303912],
              [1.2297283334436, 51.884916634598291],
              [1.224749460026843, 51.883829188394003],
              [1.224191923487493, 51.884202451474188],
              [1.222703759315687, 51.88389967391965],
              [1.221315034910541, 51.883320727800331],
              [1.22248727740212, 51.882438644961994],
              [1.221871328644752, 51.882076868725569],
              [1.219472976860796, 51.882113251604743],
              [1.219400272107585, 51.882074064224732],
              [1.219612589905745, 51.88178887225326],
              [1.219075254775134, 51.881530161413146],
              [1.21649978464954, 51.88215829693825],
              [1.215402185730033, 51.881746704401209],
              [1.216270383921758, 51.8810627781995],
              [1.213611242378219, 51.880701974226945],
              [1.212807964215364, 51.88109449188704],
              [1.21196979299808, 51.880957297353326],
              [1.21254451916493, 51.881251894855197],
              [1.214017307409095, 51.88091393075446],
              [1.215442259027096, 51.881168178048668],
              [1.215199467795533, 51.881838593340383],
              [1.216400999902349, 51.882301309797811],
              [1.218638618682791, 51.881663538785965],
              [1.219134672316135, 51.881930909279355],
              [1.219369934661662, 51.88211481326929],
              [1.219863005266785, 51.88232346794193],
              [1.221155103677097, 51.882100992089704],
              [1.22215239241462, 51.882490996291118],
              [1.221107672148921, 51.883327308855179],
              [1.222307260809332, 51.883964507610095],
              [1.223551573514418, 51.884251491007227],
              [1.225650015813948, 51.884227213381287],
              [1.229759548419234, 51.885169415430695],
              [1.229859613081876, 51.885490460075502],
              [1.227134509245268, 51.888188920167686],
              [1.224917447891115, 51.888365780770741],
              [1.224499143668083, 51.887822308584866],
              [1.223008064745485, 51.887886579122018],
              [1.223521988095752, 51.88745155844498],
              [1.22087804839798, 51.888264247145926],
              [1.221023346467955, 51.888486921556158],
              [1.225568655156673, 51.888873180965028],
              [1.225640617432297, 51.888640054266588],
            ],
          ],
          [
            [
              [1.223504541839104, 51.898209156010871],
              [1.223457473424351, 51.89755206793297],
              [1.22476583016732, 51.897976697035752],
              [1.22602803493046, 51.897777477936458],
              [1.226984658834993, 51.897027189981209],
              [1.227694327923937, 51.897151618684219],
              [1.226665437798441, 51.897786071043669],
              [1.226671027824931, 51.898168173183578],
              [1.226896878175318, 51.898337362914539],
              [1.227940861046699, 51.898055068955351],
              [1.229146788932189, 51.896666521939423],
              [1.228866463527067, 51.895616496093886],
              [1.222830385607005, 51.897139333311728],
              [1.222186042191177, 51.896932136995005],
              [1.218619971649576, 51.897483118578563],
              [1.217938202128127, 51.897870542795438],
              [1.218741780623765, 51.898230802443742],
              [1.219509085668207, 51.898083875791983],
              [1.220050823329556, 51.898319980101874],
              [1.221735914347432, 51.897995898126155],
              [1.223602601750046, 51.898502378146723],
              [1.223504541839104, 51.898209156010871],
            ],
          ],
          [
            [
              [1.236410706400384, 51.899182170883364],
              [1.238151887051854, 51.896507004490395],
              [1.236404791610434, 51.896532622088316],
              [1.234313560629301, 51.897595725168642],
              [1.234138851380204, 51.897187694156337],
              [1.234711672341802, 51.896661961638117],
              [1.236745789886699, 51.896078918332158],
              [1.241432636704336, 51.89569256467184],
              [1.24171514911127, 51.895376271884871],
              [1.243871895894735, 51.895750312201542],
              [1.244588100251005, 51.895501195810645],
              [1.244236706929575, 51.892844984919613],
              [1.246599341810648, 51.891298407144326],
              [1.246125627220538, 51.890998485818166],
              [1.246275781366257, 51.890500553042983],
              [1.2454091779484, 51.889932653318276],
              [1.245550108726834, 51.889286570156685],
              [1.244612060749697, 51.888049662568875],
              [1.241605890762676, 51.888227927252338],
              [1.240313149802244, 51.889694554756048],
              [1.239260575467188, 51.89021734595368],
              [1.239021329919648, 51.891195319773651],
              [1.238101177182628, 51.891114533334928],
              [1.237315715704994, 51.891653334522495],
              [1.236447703661237, 51.891792358543029],
              [1.234965063789661, 51.891126212472834],
              [1.235242088849476, 51.890955793489816],
              [1.236501226712477, 51.89142481816635],
              [1.238300423459382, 51.89061076248219],
              [1.240198866450187, 51.889076199291637],
              [1.241042100154699, 51.887887296976935],
              [1.242178643818953, 51.88738106126678],
              [1.241854626457028, 51.88734232657972],
              [1.242636835925624, 51.886820672230691],
              [1.24245824322247, 51.886480217042028],
              [1.238750995699252, 51.886630073427852],
              [1.238966845716943, 51.887125108668926],
              [1.238439835733506, 51.88733570196716],
              [1.237575385034118, 51.886714620334381],
              [1.234569789569953, 51.887102256340484],
              [1.23349205820977, 51.886789220567572],
              [1.231619718250323, 51.887413636202822],
              [1.230835255092097, 51.888250076935222],
              [1.230584708102239, 51.889295799514329],
              [1.231078393766346, 51.890827447862534],
              [1.231722900479949, 51.891422243404548],
              [1.232598920758536, 51.890989821074903],
              [1.232108798534921, 51.891856851713648],
              [1.232526618259678, 51.892573900557146],
              [1.233046269055052, 51.89288700359306],
              [1.234215735648099, 51.892957374345976],
              [1.232561819368234, 51.89302354546988],
              [1.232292105315031, 51.89418672365597],
              [1.229822013442143, 51.895520995297993],
              [1.230095865981655, 51.89678436081077],
              [1.228637497302368, 51.898443381516564],
              [1.229202159384084, 51.899727544459743],
              [1.231390526225731, 51.900115337379482],
              [1.231754937112144, 51.901545302349497],
              [1.230821582675227, 51.902336362790528],
              [1.231800917186802, 51.901740113339166],
              [1.232686613454862, 51.90168248757027],
              [1.232667233793795, 51.901109186762802],
              [1.233904327001438, 51.900600272024221],
              [1.23502753787663, 51.899559318552775],
              [1.236410706400384, 51.899182170883364],
            ],
          ],
          [
            [
              [1.050319754977431, 51.958442665801194],
              [1.051833892444272, 51.956923916757979],
              [1.052694091680324, 51.957350392605875],
              [1.053167469752411, 51.957247271547544],
              [1.052789913136783, 51.956618507107443],
              [1.051667992910191, 51.95621770787919],
              [1.052235870532215, 51.955406123991118],
              [1.05320982194381, 51.954958142847971],
              [1.052829612810516, 51.954654124580671],
              [1.053078076665186, 51.954176400504991],
              [1.053909428429406, 51.954628798510711],
              [1.054343273583044, 51.954479930607143],
              [1.05360686894445, 51.954053851594566],
              [1.053889504277045, 51.952987042133621],
              [1.053796726320451, 51.952393159319136],
              [1.053387895996777, 51.952417261334659],
              [1.055805235760176, 51.951299521048455],
              [1.056358554935895, 51.950660975234499],
              [1.055606344963962, 51.951071746536847],
              [1.055735661420669, 51.950768892970515],
              [1.057626594291958, 51.949275351839859],
              [1.05832993033389, 51.949280453843606],
              [1.059903945197674, 51.948606357865039],
              [1.061244246738573, 51.947514711584148],
              [1.061951708478264, 51.94649168666939],
              [1.0618466996259, 51.945933203181333],
              [1.06307798475812, 51.946296879865699],
              [1.064003371805722, 51.946144169378407],
              [1.065064231660745, 51.94637735618349],
              [1.065080338684826, 51.946080139079307],
              [1.066151218493467, 51.94590563299468],
              [1.069237479183088, 51.946067987748961],
              [1.07006400836334, 51.946470931549847],
              [1.075547263937244, 51.947014840466302],
              [1.076128719831203, 51.946685751244608],
              [1.078439796952233, 51.946684657091531],
              [1.079455404740105, 51.945407025860881],
              [1.080962400618934, 51.944441196201332],
              [1.083610041243514, 51.944447373065259],
              [1.086420424691219, 51.945148946513456],
              [1.086991056597128, 51.945193333559793],
              [1.087220314068292, 51.944929192156202],
              [1.088726167756154, 51.945071340908399],
              [1.089078896379245, 51.945396652615599],
              [1.095245207748466, 51.944044672873204],
              [1.098253135594607, 51.943725352108274],
              [1.106432706752007, 51.942031930955629],
              [1.10652521128538, 51.942254336862703],
              [1.109303245807973, 51.942421978627799],
              [1.110373184441427, 51.942952217929886],
              [1.112327825988619, 51.942850152801959],
              [1.115945598035495, 51.940835065920425],
              [1.119408222359732, 51.940027372801389],
              [1.123520076146399, 51.94052265597292],
              [1.12606909650877, 51.941540517603137],
              [1.126718949204462, 51.941280408139669],
              [1.126843429280237, 51.941689024205914],
              [1.128377782756018, 51.941362219538419],
              [1.128874110715484, 51.940903815888035],
              [1.129306317305299, 51.941091985573941],
              [1.130201241050996, 51.940548321516609],
              [1.136279676409949, 51.940465561415465],
              [1.143037406787613, 51.942251284697832],
              [1.145049412848427, 51.942263164248502],
              [1.143792935771537, 51.942525199668957],
              [1.145483205558367, 51.94265989432148],
              [1.145785790434194, 51.942242574340327],
              [1.146541435549759, 51.942995850636891],
              [1.146302085610581, 51.943173139387518],
              [1.146082456107825, 51.942717619801606],
              [1.145662275325796, 51.942951908845693],
              [1.147066162700997, 51.944316527944942],
              [1.148883241728944, 51.945491089887177],
              [1.150336922827397, 51.945882083071353],
              [1.154223986714894, 51.946064830244566],
              [1.163008414332479, 51.947486563047697],
              [1.170594471325688, 51.947985678311603],
              [1.171597121109194, 51.946045608923484],
              [1.170813802988176, 51.945917424268657],
              [1.172886324367257, 51.945176191536902],
              [1.175661823548518, 51.943468867503491],
              [1.178519496067864, 51.942329480834346],
              [1.18100013145313, 51.941745262633376],
              [1.188261917390115, 51.940617766181525],
              [1.190993121695531, 51.94055269899777],
              [1.19847353341959, 51.941051072289611],
              [1.201048048085708, 51.94160523176668],
              [1.20402660506303, 51.94268168371228],
              [1.205846252392523, 51.942770614413426],
              [1.209413911631395, 51.942271286631538],
              [1.21305568392214, 51.940733696366287],
              [1.212848267409089, 51.940214102272897],
              [1.214048210936373, 51.940307238567584],
              [1.213333685573095, 51.940530928466877],
              [1.214259877771061, 51.940458852709952],
              [1.21337632073207, 51.941025342752326],
              [1.214807398300374, 51.941155329009042],
              [1.215102299127376, 51.940517676180903],
              [1.215078935044154, 51.941147007873319],
              [1.216120255649479, 51.941585338321559],
              [1.217264931560753, 51.941536046408494],
              [1.216787420259867, 51.942021300810694],
              [1.216908410898401, 51.942268030149563],
              [1.21731302315958, 51.942065387739902],
              [1.217261900668484, 51.942692781064821],
              [1.217762586550769, 51.942653002274739],
              [1.217648616899815, 51.942219902135058],
              [1.218826888751799, 51.942354956338598],
              [1.218242644845938, 51.943422362742588],
              [1.217399837560883, 51.943704451187934],
              [1.21906612961409, 51.94490185804311],
              [1.219798414150547, 51.943889757123372],
              [1.221100209695517, 51.943372027373442],
              [1.22124279562646, 51.942985869654052],
              [1.218560546900316, 51.941643609162099],
              [1.218766461564768, 51.941186466427602],
              [1.218271447663832, 51.941203609035881],
              [1.217586103602879, 51.940574777256444],
              [1.218438069709981, 51.941030858771768],
              [1.218194489579198, 51.940230744970663],
              [1.217256261055277, 51.939809400403774],
              [1.218234945034258, 51.940064145076398],
              [1.218150911024132, 51.939581657645029],
              [1.21861958721538, 51.939623698276677],
              [1.219120784168816, 51.940688385565366],
              [1.219526600607867, 51.94060442556286],
              [1.219227069682174, 51.940059481238492],
              [1.219562468972788, 51.939297487577036],
              [1.219771491754599, 51.940264937296071],
              [1.220970691505909, 51.939555741489706],
              [1.227382784060366, 51.93946092519765],
              [1.231633711284212, 51.940031366351086],
              [1.234450924493405, 51.941788751036114],
              [1.234809685311375, 51.943092940498296],
              [1.232887503593735, 51.94360269287143],
              [1.23212491033509, 51.943036327910107],
              [1.231089606977001, 51.943574743327446],
              [1.231981097142288, 51.944779759964121],
              [1.234971670748169, 51.945079805303045],
              [1.23437543740497, 51.944358675736297],
              [1.234572830018519, 51.943765026097907],
              [1.233968305269205, 51.943676415986801],
              [1.234040099416503, 51.9433596445189],
              [1.235134200756526, 51.943496850147447],
              [1.239747027223167, 51.946112229124324],
              [1.241065940540695, 51.947528463359191],
              [1.241474223973726, 51.947296854507378],
              [1.241463216055092, 51.946619892477067],
              [1.24200244291057, 51.946508793546343],
              [1.243405489996997, 51.946992679912718],
              [1.242623807343574, 51.946971058636862],
              [1.242814520678621, 51.94747577401872],
              [1.243849929836093, 51.94760462411832],
              [1.244823179545954, 51.948162405980071],
              [1.247273208805831, 51.94832954553145],
              [1.260585053459641, 51.947766127410482],
              [1.261911575765953, 51.94589002063065],
              [1.262876801298083, 51.945673470461102],
              [1.265800330334764, 51.945507790219381],
              [1.267175968996869, 51.944010715025826],
              [1.270003683982796, 51.943010221464526],
              [1.272088610566124, 51.940984287284685],
              [1.274577640553795, 51.939394080742481],
              [1.27826658618309, 51.93941364206583],
              [1.280087314592091, 51.939875556271694],
              [1.280533077489109, 51.940400084993449],
              [1.279435928717773, 51.94151809361319],
              [1.280167842848225, 51.943091477133933],
              [1.281199293576512, 51.943843414675207],
              [1.281782898013698, 51.945417328183517],
              [1.282508918751978, 51.945274746673732],
              [1.283914980276717, 51.945615059649128],
              [1.2851660238679, 51.944910048095785],
              [1.28416572161393, 51.946516485377366],
              [1.284529493075156, 51.947291532233287],
              [1.287266727321912, 51.948676644469231],
              [1.287302646739219, 51.949028218169062],
              [1.290433539223256, 51.949704296288743],
              [1.289318242787223, 51.949262387971544],
              [1.289488748311631, 51.948967102987233],
              [1.291669510950966, 51.949329729591064],
              [1.291804871312173, 51.949051614385262],
              [1.29130839885972, 51.948930592252232],
              [1.291549110613331, 51.94850112292265],
              [1.289492055865189, 51.947919171040432],
              [1.289499734161965, 51.947447653374184],
              [1.290503976241528, 51.946652347748888],
              [1.291225059361613, 51.943422993831057],
              [1.290101270076678, 51.938153170436863],
              [1.290974723728604, 51.937184327529863],
              [1.296592226435823, 51.935566325283737],
              [1.28845808258766, 51.937493068913888],
              [1.285295168186019, 51.936904186785725],
              [1.281257327468047, 51.934833982638182],
              [1.275455796008954, 51.930499436748534],
              [1.272968062267624, 51.928084465385595],
              [1.269350477645148, 51.923372231193667],
              [1.267785821799048, 51.923320378792553],
              [1.266222134753876, 51.921159313682928],
              [1.263743784968931, 51.919340216968855],
              [1.262567580155619, 51.918866475496365],
              [1.261796337444498, 51.917899396170519],
              [1.261020246590319, 51.916864989208577],
              [1.258973222263183, 51.916335308694912],
              [1.258569982464135, 51.916511078792041],
              [1.258088839207379, 51.916856299763339],
              [1.258657267293666, 51.91683245477634],
              [1.259252893268542, 51.917244076652409],
              [1.258524099515964, 51.916981852459074],
              [1.256429609857333, 51.917087237558448],
              [1.25523730831242, 51.918051460155283],
              [1.255444428510416, 51.91840100101777],
              [1.255081379299993, 51.9180674689254],
              [1.256256141215221, 51.91709596436079],
              [1.256363142406646, 51.91700753318559],
              [1.256085198667608, 51.916865024749697],
              [1.254900037457928, 51.918033812866454],
              [1.254220966720107, 51.918036416720618],
              [1.25451797392462, 51.918301623702696],
              [1.254005063876315, 51.918108049380926],
              [1.253353506369243, 51.91776720467734],
              [1.254087531314821, 51.91794117200034],
              [1.254522170611339, 51.91785718981923],
              [1.255790291960631, 51.916849804807335],
              [1.254482529770692, 51.916445284371498],
              [1.256080259253578, 51.91655575790513],
              [1.257500309017739, 51.916301466293199],
              [1.257898785621609, 51.916140222723833],
              [1.25779428005764, 51.915701498025314],
              [1.258881363322675, 51.915268432360769],
              [1.256374890845702, 51.913192728064409],
              [1.257718239873052, 51.91250343362956],
              [1.257956560241143, 51.912039021186295],
              [1.256360788714896, 51.908900140375344],
              [1.255921696501827, 51.9068319177535],
              [1.25410088293267, 51.90409498833187],
              [1.253755346055345, 51.90259440753843],
              [1.25290585784936, 51.901382994399263],
              [1.248392936872241, 51.898163681112997],
              [1.246699932101617, 51.89609858296965],
              [1.245577369012548, 51.896680026249925],
              [1.246333719778135, 51.896644754646118],
              [1.246427105506305, 51.896891318055751],
              [1.244622721648626, 51.897090430505251],
              [1.244184977314421, 51.897451487868032],
              [1.242950072038849, 51.899319488923133],
              [1.243443916474637, 51.900180111370645],
              [1.242777220476572, 51.899967347263292],
              [1.242613933141005, 51.899396216578431],
              [1.24435361818461, 51.896967439153521],
              [1.242254292510722, 51.896563941388294],
              [1.23995451526577, 51.896566175685791],
              [1.238088234016273, 51.897865093910688],
              [1.236888613273344, 51.899560266071639],
              [1.235311194566488, 51.900026411022246],
              [1.233349572662073, 51.90125681910196],
              [1.233117471295816, 51.902356903088574],
              [1.232125896958369, 51.902175496925707],
              [1.231630829661508, 51.902449038239951],
              [1.232034142961053, 51.902902056524752],
              [1.231048439365467, 51.90334917804109],
              [1.232056413051549, 51.90392049333699],
              [1.232572777388614, 51.903659856316409],
              [1.234176199671887, 51.903694914384708],
              [1.234030395124762, 51.90338592449443],
              [1.235295700748199, 51.90312715965581],
              [1.23549018708947, 51.902596543360254],
              [1.236688645130959, 51.902338703138689],
              [1.237119008289989, 51.902560706702829],
              [1.238399130561124, 51.902245735584231],
              [1.238535106426577, 51.901912800287342],
              [1.239180668799461, 51.901973268126611],
              [1.240044222094109, 51.90261775001381],
              [1.239290113935529, 51.902402877466557],
              [1.238184205083327, 51.902794908520853],
              [1.237730065054747, 51.902545681275619],
              [1.236079591920166, 51.902806079469762],
              [1.235763017686288, 51.903296884104869],
              [1.234301165882652, 51.903794911075565],
              [1.22972625213427, 51.904537665579809],
              [1.230700873076887, 51.903996420514197],
              [1.229569536510259, 51.903367321201792],
              [1.229354730617245, 51.903818436120794],
              [1.225982093909706, 51.903727531213455],
              [1.225086686884375, 51.902737543355045],
              [1.226038837743286, 51.903160236657349],
              [1.226773670954461, 51.902944898471496],
              [1.227116432214765, 51.903223307640026],
              [1.227629306488495, 51.902934006758507],
              [1.228020412257243, 51.903217382275521],
              [1.227974961344419, 51.902725745361501],
              [1.227175439604273, 51.902485059576932],
              [1.22590752080935, 51.902748305436603],
              [1.22440841192307, 51.90242335821749],
              [1.222855853599881, 51.901088005508264],
              [1.222483278223314, 51.900230273118289],
              [1.221964650123518, 51.900561082737617],
              [1.217524898755465, 51.899938833300425],
              [1.216215557503814, 51.899317177138649],
              [1.216646398021157, 51.898307032435383],
              [1.215940564152332, 51.899070162189325],
              [1.215871371799129, 51.89828505998554],
              [1.215545421043983, 51.898423491244252],
              [1.215503918480036, 51.899185537553542],
              [1.212986307040057, 51.898500644281867],
              [1.212137513433023, 51.896915669008756],
              [1.212086275647126, 51.896503334698174],
              [1.214829068177122, 51.896821421085235],
              [1.215518120283935, 51.896393341808512],
              [1.21736190782917, 51.897374541847149],
              [1.218228245403653, 51.896965879681325],
              [1.221505336570682, 51.896460603445576],
              [1.222109647553906, 51.895499649198506],
              [1.223799690652783, 51.89575373044844],
              [1.22449658821803, 51.89523364092657],
              [1.227627222714481, 51.894756492573251],
              [1.22929370782063, 51.893709672437119],
              [1.230093071603998, 51.894112243524205],
              [1.230694261081921, 51.892644951362023],
              [1.228910975145603, 51.891941119623738],
              [1.228098042339716, 51.890801384280493],
              [1.228278870010455, 51.890525692062489],
              [1.227387248779559, 51.890173311415367],
              [1.226581982496319, 51.890214301539082],
              [1.225562355532954, 51.88951554360527],
              [1.222045228398159, 51.889726210471849],
              [1.221537879309491, 51.889522452549762],
              [1.221914993423593, 51.889307949312951],
              [1.218337618876171, 51.888899553561664],
              [1.217175150812203, 51.888986233639741],
              [1.217610479684266, 51.889118228428948],
              [1.21700754413383, 51.889346092224002],
              [1.216676974163487, 51.888991769734282],
              [1.215666490158925, 51.889110253453715],
              [1.213534101937924, 51.890128240901596],
              [1.213559547393622, 51.889812747401358],
              [1.21312645890688, 51.890160068783985],
              [1.212864235670255, 51.889927988323123],
              [1.212485999231993, 51.890371845400438],
              [1.212899121447929, 51.886791646695293],
              [1.212229060519152, 51.886363842271713],
              [1.210968076015893, 51.88616893553646],
              [1.210467084959455, 51.885808457444114],
              [1.210039834865108, 51.886359777245445],
              [1.20652224284548, 51.88717350566241],
              [1.205168873634131, 51.88686503091828],
              [1.204685387597256, 51.886157774122793],
              [1.205441829739298, 51.885554327427862],
              [1.206338141979362, 51.885343706158991],
              [1.20656037761604, 51.884851957246376],
              [1.203298226644215, 51.88525118479739],
              [1.202065255896644, 51.884917808455398],
              [1.201890210015401, 51.885113253098716],
              [1.201113267962981, 51.88471348109033],
              [1.200193706127245, 51.885047016418191],
              [1.198985472229682, 51.884774994690815],
              [1.20020101503944, 51.884884022338113],
              [1.201058406274333, 51.884575564612462],
              [1.199951355739216, 51.883927538308328],
              [1.199976991361792, 51.882877216551641],
              [1.19920552869659, 51.882635581290188],
              [1.199845735315484, 51.881930101276403],
              [1.200995377172288, 51.882135352817947],
              [1.202518036663608, 51.878144524515783],
              [1.207944287644396, 51.878144085936569],
              [1.210509890357625, 51.879519422179982],
              [1.210895978956574, 51.880368726558586],
              [1.207186783586855, 51.880485264469648],
              [1.206750123020355, 51.880883927663866],
              [1.206213917123982, 51.880864374354957],
              [1.206258572028086, 51.881797656250399],
              [1.206769378467179, 51.880970646410894],
              [1.209620445654563, 51.880945909380806],
              [1.211176242444371, 51.880549051091428],
              [1.211392497596675, 51.880137503995037],
              [1.210833457448005, 51.879145419474654],
              [1.209755841859915, 51.878482290429382],
              [1.208341742519622, 51.877991130831028],
              [1.202496985505948, 51.878011983544759],
              [1.197080943184121, 51.877316640642434],
              [1.198165291304113, 51.875683478768224],
              [1.198308170839336, 51.87648817129844],
              [1.198905938794148, 51.876515978634458],
              [1.19960545933577, 51.876114687840527],
              [1.199661292899812, 51.875673351107245],
              [1.198103093998687, 51.874664327733129],
              [1.195844915524469, 51.874540457964905],
              [1.195918210254081, 51.874836173692614],
              [1.194399446835062, 51.87462741384244],
              [1.19368400176523, 51.874066726270634],
              [1.194399089165922, 51.873823341591567],
              [1.193262514813351, 51.873368533378134],
              [1.192708566273442, 51.871887843219049],
              [1.192309094163153, 51.871745790414167],
              [1.186487952252746, 51.872561199100645],
              [1.178183255661804, 51.871418897569121],
              [1.178427932039336, 51.871118172836539],
              [1.179913455816282, 51.871105880338696],
              [1.180533607432032, 51.870834568117523],
              [1.180893498277305, 51.871301522065792],
              [1.181688386010787, 51.871241335835386],
              [1.182753776338396, 51.871801614845928],
              [1.183062530854005, 51.871606176074025],
              [1.182742906676084, 51.871442141774843],
              [1.183608500518113, 51.871436691572072],
              [1.184404475119551, 51.871967375615235],
              [1.184737187397317, 51.871371042024983],
              [1.187521075361525, 51.871062640036982],
              [1.18841202520126, 51.869814368185963],
              [1.1960998039694, 51.871211067122459],
              [1.199700907713427, 51.871260609270188],
              [1.203005686148582, 51.871985486993097],
              [1.20511649372324, 51.871530409930294],
              [1.204744053849157, 51.870502629732606],
              [1.205978581391014, 51.871087770647357],
              [1.207749881822014, 51.870864961266768],
              [1.208871797182476, 51.871275958592598],
              [1.210646282243353, 51.873095610804533],
              [1.213081830703223, 51.873832252340506],
              [1.212432450985425, 51.874914018608393],
              [1.214240019807219, 51.876846941576467],
              [1.218014352208259, 51.878459787773025],
              [1.218498000408033, 51.878943929786544],
              [1.223733723538122, 51.880157706527626],
              [1.223428783614651, 51.88042510279459],
              [1.224393871952794, 51.88056862855256],
              [1.224565765020135, 51.876454434565922],
              [1.223298708493961, 51.874831539742196],
              [1.221485394303815, 51.87371648853707],
              [1.221742177324904, 51.872065299861603],
              [1.221555422694961, 51.872318659734987],
              [1.219911334069882, 51.871942773055459],
              [1.216537197137206, 51.872346346950025],
              [1.21554419465351, 51.872154953181465],
              [1.214036549956232, 51.871389399154666],
              [1.212609442867556, 51.871120805952451],
              [1.213138983672075, 51.870742965294184],
              [1.212726950281773, 51.869936649802909],
              [1.213287143580058, 51.869581355567838],
              [1.214903330038591, 51.869547958796979],
              [1.216220013557961, 51.870753148736242],
              [1.215765429033647, 51.870878013412991],
              [1.217324694596571, 51.871511715888161],
              [1.218727734605027, 51.871402252849194],
              [1.221642977432394, 51.870576769319101],
              [1.222124820394267, 51.871117609712741],
              [1.224472620284878, 51.871865415353952],
              [1.22696252150776, 51.872587687787785],
              [1.228464099314925, 51.872595926817382],
              [1.228749581470394, 51.870213595981014],
              [1.229448152436236, 51.869196040859293],
              [1.230170790361496, 51.86896032270446],
              [1.229834243848763, 51.868663764564076],
              [1.230119160270494, 51.86797686708168],
              [1.228208343435544, 51.867020200345287],
              [1.225522308469289, 51.866314132161428],
              [1.225252373748841, 51.864938218786094],
              [1.226491973640713, 51.865470839522487],
              [1.22860525012245, 51.865280597711745],
              [1.229031563061608, 51.865026041721947],
              [1.229133387962631, 51.863852188117953],
              [1.230167026219482, 51.863943411907314],
              [1.230669315321877, 51.863796492185976],
              [1.230660686748829, 51.863331724403331],
              [1.232246291449747, 51.862956244143277],
              [1.231351750489692, 51.861178382283683],
              [1.230501941143783, 51.860408444614329],
              [1.229129549307034, 51.860352614909402],
              [1.228359070628489, 51.859878193832195],
              [1.228559298009808, 51.85940858872965],
              [1.22807737593118, 51.858906452143067],
              [1.227997220208784, 51.858476927466029],
              [1.229087975041452, 51.858353426085401],
              [1.229765175158287, 51.858762948214007],
              [1.230246860226269, 51.858228042133277],
              [1.232397758630587, 51.858101453151811],
              [1.232307297555931, 51.857468043697054],
              [1.23312084365763, 51.856173873450125],
              [1.232377297746014, 51.85506194117746],
              [1.2336369816307, 51.856201048484351],
              [1.233008453453197, 51.857745571698665],
              [1.234147325812715, 51.858637055978548],
              [1.233565346091698, 51.858441701917997],
              [1.233377193314729, 51.858856118799096],
              [1.23441017588015, 51.858919439845316],
              [1.234187752020724, 51.859180099078323],
              [1.234883144986341, 51.859133979777852],
              [1.234833851333156, 51.858649643866208],
              [1.235354950308081, 51.858846667529569],
              [1.235263589033335, 51.861155322407768],
              [1.235591027673535, 51.861487193532291],
              [1.236709027807062, 51.861459111020189],
              [1.237122673198645, 51.861959495968719],
              [1.237554859661062, 51.861605808921155],
              [1.237237399465682, 51.861372605697582],
              [1.23869176922266, 51.861194029007322],
              [1.239386111101176, 51.860728777249889],
              [1.240968265567678, 51.860610509886342],
              [1.242648706205036, 51.859597272940817],
              [1.24299919454508, 51.859784578857585],
              [1.243440751265195, 51.859420717046916],
              [1.243108622218724, 51.859205922914398],
              [1.245994126246427, 51.859879995669829],
              [1.249263799489164, 51.862294575872056],
              [1.250722495161896, 51.859880637391456],
              [1.249705463871752, 51.858407605712863],
              [1.251239898003404, 51.85909910936865],
              [1.251813348015428, 51.859859457313057],
              [1.252355353194767, 51.858936032321388],
              [1.252008280751273, 51.858414069909649],
              [1.249644250793049, 51.857236436344465],
              [1.249339883406586, 51.856540594365669],
              [1.249964021713518, 51.856111397160888],
              [1.253761143832039, 51.858046259309596],
              [1.253434818154678, 51.859320797016032],
              [1.253046861196092, 51.859460154116363],
              [1.2533670009508, 51.85954843223297],
              [1.252939835813899, 51.860875228081248],
              [1.251599647075904, 51.861286475105359],
              [1.253039971386569, 51.863228074836847],
              [1.252501277061776, 51.863734972641033],
              [1.255603667917895, 51.863745325115552],
              [1.256953584789669, 51.863970563180743],
              [1.25743166030959, 51.864474490353821],
              [1.258539346370442, 51.864584099946839],
              [1.258958380782589, 51.864311646448265],
              [1.257917748435055, 51.863582270508132],
              [1.256381604318483, 51.863473732903621],
              [1.2559758064493, 51.862944414210524],
              [1.256038928176049, 51.862551411568646],
              [1.257348489629785, 51.862239001071465],
              [1.25868980394732, 51.863490712860198],
              [1.259471649887727, 51.863976316108968],
              [1.261862134102281, 51.862942209907736],
              [1.264171746493606, 51.862557893921242],
              [1.269942491745636, 51.863645913690604],
              [1.271429103422062, 51.862291367799003],
              [1.271827145793009, 51.861691156550272],
              [1.27154677701838, 51.861170990406677],
              [1.270463633940083, 51.860778397662322],
              [1.269668878506288, 51.860916536337456],
              [1.269185798138017, 51.861526300839628],
              [1.267177853077184, 51.861742231838384],
              [1.267064773587895, 51.862106049302213],
              [1.266536598311609, 51.86199838756815],
              [1.266400783857454, 51.861970703199312],
              [1.266206614982052, 51.86193112351463],
              [1.266459077490027, 51.861706444000106],
              [1.266529428277447, 51.861971383357023],
              [1.267026145174394, 51.861974006294048],
              [1.266879028308324, 51.861648001912897],
              [1.267403243340673, 51.861424761260501],
              [1.26903644705059, 51.861329880158443],
              [1.269489164889084, 51.860702072644955],
              [1.271395294116643, 51.860585175216578],
              [1.270185674249995, 51.859610566024486],
              [1.269357575425537, 51.859849468121361],
              [1.269113134833589, 51.859663787425667],
              [1.267783299337407, 51.860355557802592],
              [1.267300254601341, 51.860244864068108],
              [1.267801650330799, 51.859727240221467],
              [1.267631363613342, 51.859081681120422],
              [1.268975229733123, 51.858583800350708],
              [1.268706561980309, 51.857703527182437],
              [1.267024905079463, 51.857749383447171],
              [1.266949415998379, 51.858230881943086],
              [1.267805390395837, 51.858857380086334],
              [1.26751322853167, 51.858995021582153],
              [1.266180670880168, 51.857925751248224],
              [1.2654222164121, 51.855878119569617],
              [1.265504746523473, 51.853749556193669],
              [1.266204365187293, 51.853843444943877],
              [1.266152812514653, 51.853593034997239],
              [1.266736863752813, 51.853475166420097],
              [1.267239038073642, 51.854131288879167],
              [1.266853129221454, 51.854217568860236],
              [1.267597420699246, 51.854466709916856],
              [1.268062986689108, 51.853936588444071],
              [1.267590381343006, 51.854089141634489],
              [1.267290449444402, 51.853818654128759],
              [1.267433279123208, 51.852883764996626],
              [1.268574955415092, 51.854046474037823],
              [1.269115716242242, 51.853171570904792],
              [1.268941914191922, 51.853018103925884],
              [1.268300791319843, 51.853390312848951],
              [1.267728023331529, 51.852762241273204],
              [1.268719394894512, 51.852973924238114],
              [1.268052009315645, 51.852503189064379],
              [1.268473179170815, 51.852382645123633],
              [1.26920062677207, 51.852960539337616],
              [1.26847854843545, 51.852196312042814],
              [1.269036582323567, 51.851740066920314],
              [1.269545209683937, 51.852645144816023],
              [1.270719275162198, 51.852392115193759],
              [1.270529551051792, 51.854100931958577],
              [1.268707037388826, 51.854746161472939],
              [1.26972210746134, 51.85604459836803],
              [1.274208027943308, 51.855072444169181],
              [1.274333176967311, 51.854156026627813],
              [1.274902835801323, 51.854859706594638],
              [1.270503172709825, 51.856339469200563],
              [1.270131167960048, 51.856957840413955],
              [1.269939638411847, 51.857721395602134],
              [1.270393712050949, 51.858333871523776],
              [1.272523202609438, 51.860113951554261],
              [1.273560070929391, 51.860269462903695],
              [1.273210378863575, 51.861194830250525],
              [1.273453372976343, 51.862100093767502],
              [1.272369751033127, 51.863021610854958],
              [1.272601952484205, 51.863478356698174],
              [1.271710559297352, 51.863766707123041],
              [1.271424757102237, 51.864412363435768],
              [1.270909875368405, 51.86804602917141],
              [1.270020210704603, 51.869700562307834],
              [1.270184271303646, 51.870879657249461],
              [1.269551340036791, 51.870947632123261],
              [1.268769882863118, 51.871892187282299],
              [1.269294715781199, 51.872156417073064],
              [1.269311796209976, 51.872692006211594],
              [1.268548099846225, 51.872720439204926],
              [1.267650656661203, 51.874029784427357],
              [1.267759112348321, 51.877086650463703],
              [1.271144151259216, 51.875693718618265],
              [1.276252094701344, 51.876094728974699],
              [1.28342527612573, 51.875381909339353],
              [1.285912623815179, 51.875714456487955],
              [1.285383590013321, 51.876399816927218],
              [1.285318863828016, 51.875802108029404],
              [1.282873343718993, 51.875644670251496],
              [1.281815474030543, 51.876163503349026],
              [1.278593231031217, 51.876215640780494],
              [1.278112559866892, 51.876719239607823],
              [1.279317932406585, 51.87696804380775],
              [1.280005337709286, 51.8776270421777],
              [1.28097564473878, 51.877581076374071],
              [1.280374848013774, 51.877692284314492],
              [1.279828095235927, 51.877707540289293],
              [1.279302510995022, 51.87717534479431],
              [1.276864055806119, 51.876716272551164],
              [1.275229309535687, 51.876837383274783],
              [1.277550342604267, 51.87861833901141],
              [1.276919313527271, 51.878853594851954],
              [1.275003685824728, 51.877551526411494],
              [1.273558890487559, 51.877047609354072],
              [1.271453338070488, 51.876649310523398],
              [1.270457065029932, 51.876857818668846],
              [1.269180753318233, 51.877772968211886],
              [1.269685386779529, 51.878078233195268],
              [1.268968173035685, 51.878067598747428],
              [1.267666316410224, 51.880153606868269],
              [1.26646324015188, 51.881278955896931],
              [1.271606547364382, 51.882078541480766],
              [1.270305874750054, 51.88231980254119],
              [1.269437906688652, 51.882835935902854],
              [1.266753646366655, 51.882236879607767],
              [1.264266524124545, 51.883293544282971],
              [1.26385277130547, 51.882999224380107],
              [1.264056798710387, 51.882605903573953],
              [1.263207937201083, 51.882985644983236],
              [1.263537245599554, 51.883538650084837],
              [1.263047353146306, 51.885001236729138],
              [1.263358558746813, 51.886086310214615],
              [1.264212197677649, 51.886152555105717],
              [1.266013265968179, 51.885454041697677],
              [1.269123644741476, 51.884152454337134],
              [1.270436078593656, 51.882811769514447],
              [1.272360760549518, 51.882497374047723],
              [1.277747538367671, 51.879291016854388],
              [1.281387605604228, 51.878228866140645],
              [1.28170367860804, 51.877560754427023],
              [1.285412378962686, 51.876665654781043],
              [1.285388613499659, 51.876446206337597],
              [1.28561749755832, 51.876708491601626],
              [1.286235183761062, 51.876649851326796],
              [1.287475592804247, 51.87570583470913],
              [1.285484491121845, 51.875303686604063],
              [1.286275672787804, 51.874914599439158],
              [1.287042973471399, 51.875273609270707],
              [1.287680929587348, 51.874935567880279],
              [1.288035300486929, 51.87523146593503],
              [1.287788256580111, 51.874594376553787],
              [1.289435956943183, 51.874075174377467],
              [1.290982178916894, 51.874096655473764],
              [1.292220121989155, 51.870377878551636],
              [1.291703794096684, 51.870171975529658],
              [1.289957748586464, 51.863394096757467],
              [1.28866142923637, 51.862034440542494],
              [1.288015892649006, 51.860852645440076],
              [1.288343090849124, 51.860638419062582],
              [1.287585429659281, 51.860487820543881],
              [1.286948662442097, 51.85940561263827],
              [1.287197415337321, 51.859251147386615],
              [1.286178265661239, 51.859617836370994],
              [1.285454337719116, 51.85939162755075],
              [1.28012763952448, 51.854284073240947],
              [1.279695105684842, 51.853749283987796],
              [1.28014107169312, 51.853450816194858],
              [1.279387079859101, 51.85376777294794],
              [1.276415280128369, 51.851768452227496],
              [1.274819764270019, 51.849958275461809],
              [1.275897414986574, 51.849518996942265],
              [1.274639968877894, 51.849882335634177],
              [1.270527420162729, 51.845964664821487],
              [1.271569603582103, 51.845497630208847],
              [1.2699607112073, 51.845879697105353],
              [1.265949030295404, 51.841579502298906],
              [1.264887337961259, 51.841816773214781],
              [1.263980170249577, 51.841581135227003],
              [1.260612037452284, 51.838597656014159],
              [1.258190611750277, 51.8360915243953],
              [1.257275693636047, 51.835967581189458],
              [1.253010569913896, 51.83281357332649],
              [1.242255228203122, 51.823654151736029],
              [1.238731589368663, 51.821234706779954],
              [1.236466257609202, 51.819741102937918],
              [1.236480450972461, 51.819149783097423],
              [1.231856149487486, 51.817158000410096],
              [1.226304581106902, 51.814166150576646],
              [1.225940798915577, 51.813218242475855],
              [1.221286826860087, 51.811893347431869],
              [1.218790989012364, 51.810163783968996],
              [1.219003184079148, 51.809456413104066],
              [1.218589317489337, 51.809736681822514],
              [1.218281884053804, 51.809433899795899],
              [1.214924978046822, 51.80854722210514],
              [1.20973443554362, 51.80592878528897],
              [1.204793350080963, 51.803893346009687],
              [1.196005902752932, 51.801148418147896],
              [1.181316443417305, 51.796625448761368],
              [1.180655915284445, 51.796185525900519],
              [1.178804330068437, 51.795846189891549],
              [1.173650367521254, 51.794233454898794],
              [1.172961471920589, 51.793594583772261],
              [1.17268667101938, 51.793729723855854],
              [1.169184548533102, 51.792583046993208],
              [1.168744497836865, 51.792182081821046],
              [1.166012099312472, 51.791522721833637],
              [1.161331222523179, 51.789609787800657],
              [1.150120325511894, 51.784766258170443],
              [1.143367585517292, 51.781524854033592],
              [1.133385715341014, 51.777536389618042],
              [1.127033002460521, 51.776313378783961],
              [1.124344330279136, 51.775424346450031],
              [1.113351194789071, 51.77297931190617],
              [1.110561633010712, 51.773166542830495],
              [1.099080100205867, 51.772227039500748],
              [1.098024758740193, 51.772778336201945],
              [1.094418277150994, 51.771918225233499],
              [1.094129071271593, 51.772289205796959],
              [1.091623824726209, 51.772373158898709],
              [1.087091130098483, 51.771959021766641],
              [1.082897542273549, 51.771924355340502],
              [1.067465505886188, 51.76998720918052],
              [1.05823043116771, 51.770106594493029],
              [1.054544498995266, 51.769557629780969],
              [1.04430769587023, 51.769589539275145],
              [1.04265130339501, 51.769899578449277],
              [1.039686802751376, 51.771496316730584],
              [1.037517076859867, 51.77385580614002],
              [1.034678994217257, 51.776132687058244],
              [1.032974334579179, 51.77829929234597],
              [1.030958389040772, 51.784540078761339],
              [1.031631255001468, 51.784950830169073],
              [1.029213027707616, 51.788680948539373],
              [1.02947917627707, 51.789535707712155],
              [1.030179949231035, 51.789669633425326],
              [1.029966423964078, 51.788540101968835],
              [1.030498603482216, 51.787436306619291],
              [1.032176248617113, 51.78620041847136],
              [1.034133974717733, 51.786675121753838],
              [1.033702581053925, 51.787550583381609],
              [1.035042565295377, 51.787701249812322],
              [1.035558861835173, 51.787463960613948],
              [1.03582922339969, 51.786458642811326],
              [1.034033718249561, 51.78528994025055],
              [1.035134085154612, 51.783450129250426],
              [1.034585372700264, 51.782037858758166],
              [1.035843911605931, 51.778733329702206],
              [1.039828436786192, 51.776103876569465],
              [1.038545802343785, 51.775313210539231],
              [1.039072620006788, 51.774156444999711],
              [1.039872932681443, 51.774437036173971],
              [1.039199492399577, 51.775008491998136],
              [1.039863455723373, 51.77509384351832],
              [1.040170969314036, 51.774600209216182],
              [1.04024120338664, 51.775062481999328],
              [1.040683102952719, 51.774907140274934],
              [1.040264501862015, 51.775301119451484],
              [1.041292462613419, 51.775279906437461],
              [1.040540471429921, 51.775437880722045],
              [1.040648904049692, 51.776101529580423],
              [1.04172126640003, 51.776567538050159],
              [1.039603198498126, 51.777001909610291],
              [1.036650556565797, 51.778718776766468],
              [1.035406371606464, 51.782041831551048],
              [1.036111026899874, 51.783400601454503],
              [1.034932230085778, 51.785086852325001],
              [1.036290966217884, 51.785517631693153],
              [1.037740731895356, 51.784001542061709],
              [1.038405341032991, 51.783576927166585],
              [1.039767926509151, 51.783332117924402],
              [1.040412213297629, 51.782627404588681],
              [1.040709524009097, 51.782800488829096],
              [1.039769450744502, 51.783527248101336],
              [1.041149585114048, 51.783866578256905],
              [1.038860441279095, 51.783789106107299],
              [1.037063108386181, 51.785484182079948],
              [1.03777805518883, 51.786606134581206],
              [1.038084903502455, 51.786575714873521],
              [1.03848744668494, 51.788033122659982],
              [1.037291009514222, 51.789673083483429],
              [1.037593188641597, 51.790004343996635],
              [1.037124517374907, 51.789785315391413],
              [1.036466260859201, 51.790154894239457],
              [1.037121043972794, 51.790596662077554],
              [1.036380903396113, 51.79033068408409],
              [1.035952533669715, 51.790495551429061],
              [1.035908739958861, 51.791030925265581],
              [1.03416847074389, 51.792091301374732],
              [1.032357825218422, 51.794157193728324],
              [1.02991409723589, 51.795912903166801],
              [1.025274844233155, 51.79781055392445],
              [1.02231528801354, 51.798129556931791],
              [1.01920413190981, 51.800109063160939],
              [1.017580864657338, 51.800651725532788],
              [1.018090176312938, 51.800849102139644],
              [1.017719705311372, 51.801275036567098],
              [1.01883723799221, 51.802194289619401],
              [1.018778448020214, 51.802573545108658],
              [1.020751694771588, 51.803141614424874],
              [1.026964661684951, 51.800753022616405],
              [1.029739177906634, 51.800376617111596],
              [1.033338379498183, 51.80072445705553],
              [1.041239793343143, 51.802895511898214],
              [1.042993038305789, 51.802583897025883],
              [1.044283816902105, 51.801739203742024],
              [1.04598302378986, 51.801315619405074],
              [1.046909304572627, 51.801563220963317],
              [1.047731022535009, 51.802453899446327],
              [1.048580581938464, 51.802703478143002],
              [1.049364130662575, 51.801395206148051],
              [1.052046914038161, 51.800779613382531],
              [1.054771176923601, 51.798440528408207],
              [1.057330707549683, 51.797872087301634],
              [1.059004088405944, 51.798313308278239],
              [1.059930180814701, 51.79787277000603],
              [1.061273453018328, 51.797723554136468],
              [1.062969129755353, 51.798364704635325],
              [1.064388142021198, 51.797770972632698],
              [1.064401245571733, 51.797130256318809],
              [1.063681547140724, 51.797362183827822],
              [1.065006967066331, 51.796715121395103],
              [1.064783276377397, 51.796475419802348],
              [1.065532000372158, 51.796372241982745],
              [1.066554884931227, 51.797337589781726],
              [1.066034417102259, 51.797833253021487],
              [1.063554937926695, 51.798767653287769],
              [1.061736873940976, 51.798739494878348],
              [1.06094446318767, 51.798177329504135],
              [1.059045129861653, 51.798683692444783],
              [1.057510320573761, 51.79823257012562],
              [1.055662167895227, 51.798466826425674],
              [1.054391452217629, 51.798938774961108],
              [1.052887839420789, 51.80072988501383],
              [1.049919097052644, 51.8016434262979],
              [1.04914750268129, 51.803128573418128],
              [1.047499683609163, 51.8031533361629],
              [1.046006091026424, 51.801894233948993],
              [1.045249106022031, 51.80211352222824],
              [1.044401399797207, 51.803117632412622],
              [1.044921762959336, 51.803820968615454],
              [1.049480559025492, 51.804924123806813],
              [1.053880540057752, 51.807279598052425],
              [1.062151290293907, 51.810371443646886],
              [1.062907973237903, 51.811430103362305],
              [1.063659024702153, 51.811742402668997],
              [1.066044593943854, 51.815888477393948],
              [1.066020839341066, 51.817141062934986],
              [1.064659591669648, 51.8189573846267],
              [1.065296373303096, 51.819593740454685],
              [1.065225478981653, 51.820173903995396],
              [1.065817230925038, 51.820082917537725],
              [1.065999173212884, 51.820347091343606],
              [1.065510784197538, 51.820293277128116],
              [1.066429934747249, 51.820599375973501],
              [1.066430011960733, 51.82096363107361],
              [1.067765686710109, 51.821077171131897],
              [1.066490814523949, 51.821153616717723],
              [1.066035130222076, 51.820662742077445],
              [1.065546702176125, 51.82090752983428],
              [1.065233389199436, 51.820354477084621],
              [1.065340401321796, 51.820861645562282],
              [1.064943500007522, 51.821043783443955],
              [1.063257243085042, 51.820575993101798],
              [1.059942183234717, 51.824339103112472],
              [1.059524569293935, 51.82417369415753],
              [1.059738587367454, 51.823220155916736],
              [1.061033516403434, 51.822432736757094],
              [1.061271467847464, 51.821490263885906],
              [1.062632097416419, 51.820474465935511],
              [1.062160573572509, 51.820455274843695],
              [1.062108811211953, 51.820013219706311],
              [1.062528224836432, 51.81982061132021],
              [1.062842482104677, 51.82011012225734],
              [1.063298647427161, 51.819432676116136],
              [1.062851565725962, 51.81919609540968],
              [1.063355028390435, 51.819151493020698],
              [1.063594634848425, 51.818533654656711],
              [1.060850857935154, 51.819054854164477],
              [1.058469470144818, 51.81894957000921],
              [1.05742976906026, 51.81917629201179],
              [1.053633055170976, 51.821382302066198],
              [1.048888769332446, 51.823209843087746],
              [1.047972411531658, 51.823158963752689],
              [1.047092554634265, 51.822595374407911],
              [1.04617960621648, 51.822831300157404],
              [1.04689829698601, 51.822405242171136],
              [1.046824921276564, 51.821425903249178],
              [1.045743816030428, 51.81975047162814],
              [1.044796200483102, 51.819428760706806],
              [1.046058054526841, 51.819675771401847],
              [1.047635344823093, 51.822659546630369],
              [1.048853064485008, 51.822939151656968],
              [1.052606163740742, 51.821391892822],
              [1.056746203511727, 51.81879024500234],
              [1.059315564965944, 51.818371717154648],
              [1.061178114319269, 51.818487802562338],
              [1.063251739594749, 51.817907617288519],
              [1.064478017136846, 51.816860221578942],
              [1.064461184451541, 51.81614203965777],
              [1.060199842407274, 51.811593301337254],
              [1.039642566604319, 51.805590063393474],
              [1.03632621117225, 51.80578733319021],
              [1.031902332950065, 51.805307066592384],
              [1.027635303494321, 51.805282183222978],
              [1.026641424331934, 51.805216050013854],
              [1.026493844319351, 51.804767456091582],
              [1.026360445975962, 51.805266458165242],
              [1.026136332723382, 51.804606678591576],
              [1.026019248565045, 51.805284239509056],
              [1.025734261173144, 51.804581956601616],
              [1.025584169939865, 51.805221693039599],
              [1.02540718586095, 51.804615561650465],
              [1.025230668969009, 51.80520741072008],
              [1.023426959299264, 51.805136914776092],
              [1.023511730453733, 51.805427935789446],
              [1.022960822235891, 51.805693940470626],
              [1.022463706503151, 51.805203068438409],
              [1.02239814704188, 51.805719207769329],
              [1.021851042040071, 51.80532675349582],
              [1.0222202475814, 51.806139303263748],
              [1.021093756329102, 51.806324766213152],
              [1.017325160619845, 51.806421599662876],
              [1.015876248846712, 51.805646646010416],
              [1.012918773773883, 51.804831219986561],
              [1.011513335586917, 51.805402386930254],
              [1.011841326441833, 51.805969590640395],
              [1.0114780241152, 51.806112911554209],
              [1.010571758462493, 51.80561988429281],
              [1.010556434563411, 51.806391054948136],
              [1.006322115882009, 51.80844934356287],
              [1.002741311917643, 51.811132940996067],
              [0.996978645848185, 51.813809132738513],
              [0.993621787800288, 51.816404884898489],
              [0.990272757929155, 51.8207235597934],
              [0.986943312473102, 51.824075694104785],
              [0.985580890360235, 51.826385754677268],
              [0.984825102570981, 51.826677465317516],
              [0.983587112770495, 51.828237851906486],
              [0.982842101932654, 51.829527590813164],
              [0.982659684922514, 51.831181688419086],
              [0.983625301095017, 51.833995631169493],
              [0.985396830255706, 51.83667847476196],
              [0.986314371034473, 51.837139033645698],
              [0.989332348189244, 51.837593850671389],
              [0.991260409131236, 51.837462954023025],
              [0.994743910501609, 51.835437873358266],
              [0.996822521594454, 51.835236493676675],
              [0.999462229668069, 51.835417388061565],
              [1.003604518394742, 51.83304694824097],
              [1.007629175621921, 51.832946723482777],
              [1.00963326310572, 51.831870119132731],
              [1.010663296482355, 51.831664759719246],
              [1.012201928596197, 51.832293580954996],
              [1.013510611183379, 51.832108935640733],
              [1.013790817655347, 51.83243542383498],
              [1.013218047401201, 51.832271131608564],
              [1.01252611168209, 51.832691795437015],
              [1.013959056568695, 51.834041015111737],
              [1.017212576957811, 51.83400070744144],
              [1.018385015614275, 51.834296181998027],
              [1.018654862443962, 51.833794587409159],
              [1.018882206080868, 51.833981217424146],
              [1.019578125276479, 51.833815840113829],
              [1.020867059559115, 51.834723479554995],
              [1.020283255708429, 51.834525328099268],
              [1.019166239328798, 51.834949758963369],
              [1.016308549322279, 51.834505984355879],
              [1.012930554585358, 51.834378573815449],
              [1.011441947183027, 51.832804122274062],
              [1.010468628225267, 51.832358677480769],
              [1.007326584573989, 51.833786399684691],
              [1.007074386602905, 51.83455463341344],
              [1.00675465191293, 51.833743471837039],
              [1.004336474521182, 51.833705478304886],
              [1.003274017950565, 51.833970070037338],
              [0.999736505431376, 51.836136192302128],
              [0.997886010541686, 51.836340798921015],
              [0.995933457079271, 51.836167537963547],
              [0.994635068502743, 51.836510014748207],
              [0.991461598611472, 51.838394991403497],
              [0.988969720688823, 51.838738094441055],
              [0.987393314461988, 51.839400544582503],
              [0.987660647224864, 51.838417019926688],
              [0.986903834827478, 51.838761831307885],
              [0.985464383164966, 51.838730049194716],
              [0.984058260733849, 51.841067274864173],
              [0.983136760318216, 51.841447720398783],
              [0.981204034911359, 51.843136328163673],
              [0.981460980613272, 51.844431215422354],
              [0.982201523937457, 51.844650765441401],
              [0.98038860895495, 51.847143961419825],
              [0.979565760289591, 51.847788991722524],
              [0.975801626576455, 51.849326948344242],
              [0.971559968226039, 51.850101589581243],
              [0.96848404489344, 51.850739618008738],
              [0.966171262204687, 51.851764829737888],
              [0.966939364267392, 51.852093504919893],
              [0.971278876566943, 51.8526233565212],
              [0.973144800761743, 51.853367631394534],
              [0.978074376046491, 51.854032504760866],
              [0.976657244745717, 51.856391492935472],
              [0.975653826011151, 51.859518852212275],
              [0.973948670858095, 51.860445189477048],
              [0.976727108702123, 51.863849167531605],
              [0.977002470783511, 51.864872882068255],
              [0.977416819017951, 51.865955133203506],
              [0.979211024054776, 51.867911695816098],
              [0.979368436559456, 51.868842160445062],
              [0.978291197456586, 51.872150383239152],
              [0.977159590933084, 51.872989381837087],
              [0.975660206163958, 51.873562468406263],
              [0.970173683096454, 51.874575390670515],
              [0.960109018083113, 51.877411311950894],
              [0.957893634886663, 51.877376227782356],
              [0.952823555905657, 51.880298911159471],
              [0.95809060394202, 51.8811054673264],
              [0.957946687539722, 51.881503012873921],
              [0.959607838043741, 51.884511884103304],
              [0.959297179376991, 51.886308553931926],
              [0.962045985027926, 51.889585008536763],
              [0.962589586892222, 51.89139614036683],
              [0.959507280226897, 51.89222472935365],
              [0.956743124760796, 51.892299658924884],
              [0.953068161807007, 51.891696789991911],
              [0.952341086718238, 51.893421151084461],
              [0.950820278629704, 51.894391968528268],
              [0.948779150494255, 51.896741055637385],
              [0.949985958426173, 51.898274791535229],
              [0.951056814547673, 51.89839901852374],
              [0.951701924270226, 51.899257914613933],
              [0.954143175790215, 51.90147741672471],
              [0.956687225761597, 51.902535897431989],
              [0.956300958510458, 51.903007883831151],
              [0.957112871798698, 51.903636816439572],
              [0.954851803624711, 51.904674859211106],
              [0.949062052430939, 51.90478600652677],
              [0.944108651496923, 51.906213293162779],
              [0.947972987514703, 51.91000086137975],
              [0.946613488596898, 51.90989193002914],
              [0.941290635299906, 51.911171856461493],
              [0.937870452779733, 51.913043476674659],
              [0.931480231981316, 51.913218208642633],
              [0.931838166874992, 51.913823552324025],
              [0.931095617068424, 51.9141226607018],
              [0.934115517225016, 51.916520615392884],
              [0.93236558634541, 51.917630900197324],
              [0.932765223923623, 51.917915927546986],
              [0.932210854531861, 51.918202255778816],
              [0.931171901079956, 51.919712099907159],
              [0.934338390600674, 51.921051444848068],
              [0.935252927259559, 51.922494589023181],
              [0.937348916420959, 51.922004277002358],
              [0.938195760958898, 51.922699020222971],
              [0.939180976837637, 51.924575661610803],
              [0.939058575052367, 51.926575307214137],
              [0.941954822919669, 51.929731623703503],
              [0.94714052613545, 51.932695631575697],
              [0.949196605052725, 51.935006733596524],
              [0.95100854899623, 51.934593453990502],
              [0.95528628302363, 51.936742575295895],
              [0.96445042246364, 51.938336817014026],
              [0.969238314178475, 51.939712616834854],
              [0.972088441368205, 51.940167675985492],
              [0.973640504285997, 51.940159033261907],
              [0.974480811214058, 51.939861683561908],
              [0.97564538401572, 51.940242344758367],
              [0.985497253342149, 51.939724724757838],
              [0.993837732730071, 51.937579111329278],
              [0.994948741821044, 51.937612888083756],
              [0.997074301493411, 51.936910305715706],
              [0.999439727766901, 51.936619777388742],
              [1.001651409266331, 51.935530882037099],
              [1.004170485346365, 51.935820930560155],
              [1.005703366665429, 51.935592904978606],
              [1.00585335399711, 51.936704298133016],
              [1.012528391758181, 51.939646289176878],
              [1.01559992357903, 51.941767468096906],
              [1.018460338658508, 51.943016193929544],
              [1.022790560578277, 51.946446758915513],
              [1.023517570756402, 51.948015478445534],
              [1.023116639070666, 51.948870297225511],
              [1.025120061127006, 51.950631944945677],
              [1.026802747306347, 51.954003603464756],
              [1.025190190318107, 51.953942549093163],
              [1.024681945660717, 51.954890069354605],
              [1.025520932502543, 51.95496293195275],
              [1.025769837562709, 51.955870298285859],
              [1.028056783701967, 51.956022815323671],
              [1.028116050773261, 51.956621174854945],
              [1.029007512512038, 51.95723408776302],
              [1.031804120049632, 51.957344630354157],
              [1.033106459946832, 51.957746362971498],
              [1.034260481477989, 51.95722374762682],
              [1.035428675510245, 51.957729644602544],
              [1.036416005795569, 51.957024237351028],
              [1.037886540333426, 51.956920622082826],
              [1.039826282014739, 51.95592077586106],
              [1.042052551551286, 51.956530574583084],
              [1.041682544335647, 51.957386457761181],
              [1.042445203284178, 51.959296808678943],
              [1.045075341989246, 51.959633488582838],
              [1.048301128792293, 51.959308024031571],
              [1.050319754977431, 51.958442665801194],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000077",
        LAD13CDO: "22UQ",
        LAD13NM: "Uttlesford",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.316739265605597, 52.076093598153662],
            [0.321918398916339, 52.074290293787328],
            [0.324644303669633, 52.072771685025124],
            [0.323446920687357, 52.07196980215739],
            [0.324235061050816, 52.071916489553999],
            [0.325684898408768, 52.071146008348286],
            [0.325029684750854, 52.069909896859443],
            [0.327301308023667, 52.06847302499294],
            [0.327615073880266, 52.068614302009387],
            [0.328564018758206, 52.066829443987821],
            [0.329975376975006, 52.065604652441017],
            [0.33193692027462, 52.06281596546556],
            [0.335603091580375, 52.060268628629771],
            [0.337725526222355, 52.059705870672957],
            [0.33956318506457, 52.057463548744046],
            [0.341743408552493, 52.056279990377938],
            [0.341098012085736, 52.055960997035548],
            [0.341511679025159, 52.055670413251285],
            [0.341319307580664, 52.054809164515],
            [0.344028237059665, 52.053223881673802],
            [0.344437651584698, 52.052267931010711],
            [0.343277525725486, 52.052146235372035],
            [0.343827337088427, 52.051002249544247],
            [0.345929765904996, 52.049705952968239],
            [0.352210287396899, 52.046959408016434],
            [0.363294087448289, 52.04468727175135],
            [0.364612776415309, 52.043947703129589],
            [0.36380592251066, 52.043608694488967],
            [0.366185188750675, 52.041303808887761],
            [0.366997389610692, 52.039729990378945],
            [0.368545592307138, 52.039308593738319],
            [0.371692656841808, 52.037358640527877],
            [0.374557120273161, 52.037096797002917],
            [0.374832377306868, 52.037310664651741],
            [0.377477493488811, 52.036322980090389],
            [0.380810405878583, 52.03697595788622],
            [0.386324546655318, 52.036064033306921],
            [0.389665512693383, 52.036455814538385],
            [0.390751090615427, 52.035677512013706],
            [0.394002601205024, 52.031830101914039],
            [0.412810547169751, 52.038063031890275],
            [0.423271643726145, 52.038984030810411],
            [0.428218866516531, 52.039137531603501],
            [0.431359776879003, 52.038921660510084],
            [0.430053329408423, 52.033950535382061],
            [0.432559776483541, 52.034032783739093],
            [0.432667630108683, 52.032420879309761],
            [0.430880965745362, 52.027760030861799],
            [0.431073045998411, 52.025496222252258],
            [0.430060112275443, 52.024720382218099],
            [0.430412306743513, 52.020516259811792],
            [0.430897824668932, 52.020518828260975],
            [0.431318306629285, 52.019134276555377],
            [0.433192659470453, 52.01854251688539],
            [0.435691142661601, 52.016669858125951],
            [0.437725436491785, 52.014036984488094],
            [0.432791057948435, 52.013264047165435],
            [0.431215339582041, 52.01358075767309],
            [0.427925730385036, 52.014947164778832],
            [0.427989981269768, 52.014599624031028],
            [0.420936672577641, 52.014824876617517],
            [0.41988589794975, 52.014528136037583],
            [0.420043672181391, 52.014157095908679],
            [0.413809265314782, 52.011671804709302],
            [0.415181404701084, 52.01068595703299],
            [0.415373640115633, 52.008816946831416],
            [0.417052046532652, 52.00748667090906],
            [0.417704375666953, 52.00558572839396],
            [0.418828280760186, 52.004648995755403],
            [0.41865287504192, 52.003747041901704],
            [0.420227607789289, 52.001607712040268],
            [0.420123251267, 52.000807714638647],
            [0.422558796988477, 51.998155160200277],
            [0.42298149804759, 51.998186029759751],
            [0.421946588128398, 51.99715068804921],
            [0.420862372718368, 51.996712562976555],
            [0.421414448874569, 51.995901764909888],
            [0.420165969846389, 51.994794362648847],
            [0.42066596599252, 51.993477453532705],
            [0.421343236720687, 51.992800769162592],
            [0.423414374696601, 51.991905652954365],
            [0.425638274441509, 51.989796042250148],
            [0.426914060616205, 51.986749117192915],
            [0.424114521564455, 51.984157559298914],
            [0.423493227020852, 51.978585010784869],
            [0.424335302059484, 51.978591952177446],
            [0.423528183969043, 51.978288432537568],
            [0.423571085022573, 51.97706005078966],
            [0.423995563854455, 51.976399342672444],
            [0.425095030481238, 51.97588839585206],
            [0.424357138243416, 51.975382919502373],
            [0.424627700434688, 51.974541928104074],
            [0.423593217683814, 51.973208036098661],
            [0.422802291344295, 51.973445537281115],
            [0.421792865174023, 51.974626362759267],
            [0.42121959761094, 51.974769448209301],
            [0.419607402065574, 51.974656016951933],
            [0.417841481347378, 51.974094289773852],
            [0.414139964695878, 51.97403716832045],
            [0.414281640674003, 51.973503696167079],
            [0.413070752760816, 51.97354111439779],
            [0.412262723634535, 51.973081964381493],
            [0.413114171190279, 51.971937733631762],
            [0.412475440099028, 51.970970626604057],
            [0.413791514709428, 51.969099252767599],
            [0.4155272014003, 51.968724624333191],
            [0.417740696363086, 51.96640853202679],
            [0.41780425430065, 51.96596658575848],
            [0.416962560176912, 51.96563765416608],
            [0.41757186854214, 51.965273527832458],
            [0.417243768653931, 51.96450599740205],
            [0.417501742575086, 51.963754307613364],
            [0.416409907379873, 51.962698506273362],
            [0.417894277192323, 51.961391962966644],
            [0.417854618763678, 51.960006107078641],
            [0.418729031395499, 51.95856549954987],
            [0.420045031231802, 51.958379277829387],
            [0.42154547964265, 51.959103802533846],
            [0.422554928767547, 51.959171158176453],
            [0.423163912657993, 51.958695498845003],
            [0.424070897818527, 51.958698404993065],
            [0.425593860688154, 51.95819401343033],
            [0.425381522770964, 51.957819796924085],
            [0.426340485980866, 51.956818029741058],
            [0.426578931058883, 51.955250183474327],
            [0.42715067847846, 51.954491100931925],
            [0.430023403371685, 51.953812225941675],
            [0.427204010412976, 51.951410001260193],
            [0.425185035484778, 51.952022658510948],
            [0.423425169331093, 51.94904815440163],
            [0.424714449801151, 51.947778807393384],
            [0.420824796242989, 51.944269895193685],
            [0.420539579218026, 51.94359411516001],
            [0.421075375537959, 51.941682036074376],
            [0.419088475690485, 51.941099701652377],
            [0.420241878439423, 51.939713591531692],
            [0.41995669531001, 51.939578270878918],
            [0.420997295811199, 51.938935471340862],
            [0.420406276426114, 51.938389181701559],
            [0.420958083966977, 51.93615393364189],
            [0.422855845541334, 51.933087927180907],
            [0.422987883777246, 51.931920651315956],
            [0.421370384866289, 51.931950349163607],
            [0.42148778878428, 51.931375097108301],
            [0.42024138932156, 51.930326109747426],
            [0.421364135419583, 51.926295854770743],
            [0.423906889541349, 51.926381090475132],
            [0.432987311311944, 51.924789916269738],
            [0.434604765244489, 51.920814038625714],
            [0.435793919452322, 51.919757058390616],
            [0.437273894936615, 51.919613101473487],
            [0.437508895175237, 51.919092048864222],
            [0.43943113257978, 51.913104394563376],
            [0.440005090484938, 51.908269667775073],
            [0.444764124896976, 51.904044977301929],
            [0.448188059747699, 51.901535825795968],
            [0.450481728371668, 51.898912530712423],
            [0.451024595248729, 51.899004638251213],
            [0.451717323311981, 51.898271677421533],
            [0.451119476029361, 51.897997265473933],
            [0.455092348096929, 51.891974660729765],
            [0.456747112054759, 51.890416726982053],
            [0.460362049433839, 51.888428394416557],
            [0.462709087967423, 51.886646357675659],
            [0.469405997812071, 51.880263110890212],
            [0.470610704444789, 51.878172150713787],
            [0.467177194367085, 51.878648004687001],
            [0.469255103192548, 51.877669140712015],
            [0.47275964006282, 51.877297847738355],
            [0.472360453009384, 51.876986992996649],
            [0.473174654886994, 51.87625584073465],
            [0.479866279472864, 51.87400160884895],
            [0.482664508163441, 51.872683568306002],
            [0.483244032580517, 51.871547221748756],
            [0.482721434111164, 51.870341516854957],
            [0.481841320786961, 51.869765663186676],
            [0.482272310000035, 51.869625270853469],
            [0.483037115427716, 51.869505707320343],
            [0.483342002629196, 51.869713300582859],
            [0.485006589743427, 51.869313926827829],
            [0.490767243524586, 51.870377326034053],
            [0.491535256425567, 51.869737849104766],
            [0.494697415537509, 51.869572790709839],
            [0.495796990970658, 51.867366879561601],
            [0.50124330038886, 51.864624333978945],
            [0.502658610553056, 51.865261498148357],
            [0.503246827755826, 51.864841598719622],
            [0.505822056805414, 51.86557003774297],
            [0.508019734448013, 51.864497993178055],
            [0.513476714655978, 51.864388678663076],
            [0.51351014449616, 51.863789932890519],
            [0.511737625682676, 51.86062811364539],
            [0.512105002617988, 51.857264095169633],
            [0.511413393312911, 51.855537831669515],
            [0.512064457803677, 51.854398905289329],
            [0.513775778465086, 51.853159074393162],
            [0.519233736983357, 51.850440597741716],
            [0.517665613467045, 51.849663018369959],
            [0.517681799196622, 51.849198634228557],
            [0.512482900356183, 51.848225287401959],
            [0.506786536578169, 51.848146327148321],
            [0.502050316005935, 51.846686029678338],
            [0.500275329254578, 51.845495350065335],
            [0.495870194810696, 51.844735510787885],
            [0.492490376272811, 51.84326851433125],
            [0.488872033539813, 51.842552885393047],
            [0.486770236135586, 51.842640523808711],
            [0.487312682478182, 51.841524717142306],
            [0.486393754355382, 51.839919129931538],
            [0.480691776226936, 51.840377708177051],
            [0.475168280469819, 51.840466239672345],
            [0.467087117254899, 51.839579418119222],
            [0.467068507651587, 51.839079802975256],
            [0.465314059176675, 51.838693937069387],
            [0.464702062298939, 51.837137506025918],
            [0.461876408607282, 51.8345968586938],
            [0.4593139491322, 51.835414884272716],
            [0.453026016634551, 51.834621775794226],
            [0.45134681953795, 51.833958950894612],
            [0.449665210042268, 51.830175620144196],
            [0.448507746516056, 51.830002787604535],
            [0.447045919943051, 51.829115049612284],
            [0.445743874520721, 51.828871457731452],
            [0.443205542098815, 51.82938377221749],
            [0.441079784795366, 51.830467506208677],
            [0.440576690721332, 51.830799895971538],
            [0.440088513904947, 51.83218683800321],
            [0.439428015605891, 51.832454143467189],
            [0.4366766682829, 51.831257592715168],
            [0.434392973443592, 51.831032417710574],
            [0.434088013218905, 51.831325604283705],
            [0.43134133171835, 51.83163963408839],
            [0.43053727400303, 51.833203930182819],
            [0.43071291922522, 51.834201203862918],
            [0.431623137952319, 51.835253431400339],
            [0.43144500083752, 51.836096148861266],
            [0.430627874161319, 51.836934091836731],
            [0.428014427509064, 51.838355003598018],
            [0.426833004233038, 51.840195049822469],
            [0.427490448709468, 51.84056906971724],
            [0.426769138255152, 51.84070806070784],
            [0.426040934442125, 51.841501868194079],
            [0.426370065614894, 51.841925833703776],
            [0.425631890924116, 51.842426677569897],
            [0.426081736460569, 51.842746534448096],
            [0.42564925458475, 51.843289631709581],
            [0.422550426166578, 51.844955141449887],
            [0.423436694475231, 51.846101449903479],
            [0.424027212501056, 51.846228666565572],
            [0.424247371413358, 51.846921072055821],
            [0.424453704778711, 51.848409627509866],
            [0.424017295676279, 51.849421324975005],
            [0.424745014157792, 51.851316397342522],
            [0.424002871406504, 51.854150947969458],
            [0.423123424444218, 51.854675368946374],
            [0.420040273610867, 51.855106666074526],
            [0.419351323560243, 51.856205375441689],
            [0.418310996917398, 51.856737565665014],
            [0.418273134604972, 51.857223056609364],
            [0.416726635921246, 51.857470676396979],
            [0.415090437891795, 51.857101413113057],
            [0.414420248063021, 51.85665474359827],
            [0.413143507003435, 51.856651273946923],
            [0.412967210886601, 51.856314963706808],
            [0.415030282879048, 51.85353790081335],
            [0.414596802173625, 51.849618743779438],
            [0.413738658545442, 51.84853384350815],
            [0.413893013684225, 51.845557650878725],
            [0.412782098965167, 51.845608329389499],
            [0.412876811453166, 51.844257463745258],
            [0.412022914557234, 51.844036673586501],
            [0.411956746425077, 51.843722383124238],
            [0.411118389245268, 51.844932923464448],
            [0.411716046319515, 51.845792070130699],
            [0.407961744884493, 51.846180163719033],
            [0.406602447974845, 51.843970582438111],
            [0.40531341575422, 51.84285846929113],
            [0.4042912482916, 51.842748079862879],
            [0.403492775706543, 51.840268901050564],
            [0.409957289387299, 51.836570027432906],
            [0.407936808312886, 51.835703149540429],
            [0.40653572361583, 51.835480036660854],
            [0.410481377686383, 51.834451366355921],
            [0.411047561679189, 51.833366013743166],
            [0.410633513096926, 51.832353818142273],
            [0.407656118645035, 51.831557811822471],
            [0.406232628314207, 51.830666988399855],
            [0.407060635241065, 51.825400026900084],
            [0.408595123017835, 51.82350528396843],
            [0.410658810557869, 51.821984562590032],
            [0.411033053319982, 51.820568608922564],
            [0.410793098651814, 51.819607698647737],
            [0.412955156889959, 51.818058837474325],
            [0.414641182051992, 51.816049387728121],
            [0.414369770873898, 51.814338226486583],
            [0.414074683919063, 51.814056515436548],
            [0.413609281446028, 51.814144309935536],
            [0.413025387267043, 51.813209349571615],
            [0.410673049498984, 51.812983331383741],
            [0.410185450733031, 51.813253223126949],
            [0.408048783003202, 51.81279970772907],
            [0.407805953182508, 51.813279504177082],
            [0.404924126188563, 51.812482378406393],
            [0.406840284679644, 51.81040538179392],
            [0.407041333178641, 51.808406650923025],
            [0.405398474758559, 51.807713658428668],
            [0.404904055338718, 51.807773235552759],
            [0.404587616733828, 51.807415498820717],
            [0.402276130614974, 51.807705567864929],
            [0.40324487427475, 51.811353047736048],
            [0.404143412392166, 51.811498343966093],
            [0.403551224368402, 51.812856682268119],
            [0.400002785516971, 51.812754721663971],
            [0.398895607361344, 51.809146014145902],
            [0.398849753870431, 51.807490468965931],
            [0.397306889516566, 51.807147842993594],
            [0.394057669410461, 51.807098956161205],
            [0.391531163428632, 51.805767288776401],
            [0.391445679391851, 51.805056795735815],
            [0.389978854953118, 51.805079434844437],
            [0.389874837328311, 51.80388100693164],
            [0.38830584178752, 51.803534298897695],
            [0.388484934233258, 51.80159899991947],
            [0.387981897003193, 51.799710833922781],
            [0.38734269801639, 51.799774178278106],
            [0.386805114431106, 51.798493725201439],
            [0.384716344159497, 51.79879241809158],
            [0.384137999866661, 51.797067635176333],
            [0.380909152600538, 51.797253588991289],
            [0.378568399508142, 51.797641809276726],
            [0.378290379445845, 51.797981068774092],
            [0.378476909015157, 51.798824418023699],
            [0.381448013446452, 51.798204005330199],
            [0.382759856803509, 51.799812314484825],
            [0.379199944611631, 51.800380830357298],
            [0.378772616503262, 51.799950629799341],
            [0.37450216510186, 51.800827445330079],
            [0.369653996753697, 51.799210357128914],
            [0.36838132018067, 51.799142482713727],
            [0.368067043170491, 51.799372735634982],
            [0.366607607122613, 51.799339179971632],
            [0.366429394692447, 51.799619746292947],
            [0.364731039726185, 51.799649430318084],
            [0.361542744500938, 51.801143384211194],
            [0.360433178193803, 51.800117111914062],
            [0.361703339090986, 51.798388362969263],
            [0.359017863808794, 51.798123046878437],
            [0.359485030393571, 51.796264751721864],
            [0.362276085255728, 51.795666424393055],
            [0.362589902559262, 51.794900225227771],
            [0.358890759826111, 51.794521286327203],
            [0.35875354901858, 51.793976378753705],
            [0.354712274574669, 51.793486369018034],
            [0.350130927233699, 51.794015111937966],
            [0.346793023820491, 51.793495476607852],
            [0.342908628873395, 51.793902106023189],
            [0.342780570745132, 51.794201420058073],
            [0.340553915785145, 51.794471499807564],
            [0.339802335248914, 51.792784132938621],
            [0.337903762039618, 51.791405552078722],
            [0.337671664700742, 51.79025459786196],
            [0.338234396155443, 51.789078840314126],
            [0.340671354694527, 51.788030336382533],
            [0.340742192405287, 51.786430009796547],
            [0.339588705180619, 51.786471862131989],
            [0.334926428037427, 51.785703970790891],
            [0.335272689157186, 51.784575692519624],
            [0.335064227314814, 51.783739909499928],
            [0.334326094295924, 51.783119682934085],
            [0.33411439276497, 51.781658964784278],
            [0.333472756981519, 51.781109657950715],
            [0.332608626272951, 51.780968539497579],
            [0.332291802300431, 51.779239222027584],
            [0.332516230630016, 51.778924517166196],
            [0.333667088039707, 51.778807245745746],
            [0.335237161781556, 51.77591545394764],
            [0.340489973367368, 51.774107721942386],
            [0.344436360367232, 51.774034454121079],
            [0.346234489166374, 51.773235978226687],
            [0.348074720006743, 51.773193827320675],
            [0.347777558857113, 51.771350843386536],
            [0.348256440223964, 51.768876345562163],
            [0.347899510267625, 51.768227002373749],
            [0.346673570824595, 51.767589619783088],
            [0.340521003653356, 51.767312145624359],
            [0.33882661363227, 51.767899838098757],
            [0.336082072629468, 51.768305149885194],
            [0.327271672799407, 51.769162518823585],
            [0.325292384722098, 51.769682814395352],
            [0.324951765234788, 51.770500702273537],
            [0.323786220033803, 51.770046239871128],
            [0.321611658475811, 51.769926451644501],
            [0.322322048870147, 51.772018521845794],
            [0.320055053085282, 51.772557002667625],
            [0.319571556161482, 51.772598021729486],
            [0.318441787692576, 51.769928781540962],
            [0.312938796152628, 51.768835788897434],
            [0.312261022854368, 51.770321241533864],
            [0.307881762238078, 51.770171717648182],
            [0.307275085557481, 51.769900359036285],
            [0.303278342383536, 51.77156867971609],
            [0.299338539852813, 51.771853564387747],
            [0.298874239152314, 51.772243939922909],
            [0.298569347406001, 51.7737175178193],
            [0.299117847106175, 51.774327286885907],
            [0.302025647307288, 51.775435822015488],
            [0.302802686601298, 51.777400798741667],
            [0.302200973069606, 51.777826253212659],
            [0.30230834754741, 51.778710833093939],
            [0.299971805013799, 51.779090213257099],
            [0.297883265890451, 51.779814514767061],
            [0.297247541142508, 51.780485210137506],
            [0.296429009970751, 51.780554263956418],
            [0.29724612551414, 51.782055366755515],
            [0.296822731052265, 51.782596910051915],
            [0.298262317788388, 51.783335846668116],
            [0.298526863914966, 51.784175988765718],
            [0.298206317408067, 51.784430456275288],
            [0.299172061342417, 51.785202027845003],
            [0.2983166282199, 51.785430987127555],
            [0.297872069116779, 51.787695951664354],
            [0.29733288789238, 51.78815702674386],
            [0.297668378522398, 51.78856683124711],
            [0.296701865400021, 51.788779964152752],
            [0.297433217991001, 51.789959902606391],
            [0.295589346390397, 51.790258516846713],
            [0.288628615771174, 51.78896622019036],
            [0.285913911820861, 51.789106278412468],
            [0.285157453281859, 51.789460012653002],
            [0.284438815207176, 51.789098987193782],
            [0.281613333112183, 51.789515375844097],
            [0.280060915456123, 51.788924106710056],
            [0.277671314626756, 51.789099033460332],
            [0.274107819383039, 51.787644675286849],
            [0.26796570317034, 51.787157249059511],
            [0.267562801501961, 51.786207303522346],
            [0.266072747343333, 51.785581375310791],
            [0.263771910633771, 51.785267805581888],
            [0.262481420653413, 51.78439788421646],
            [0.259241081972213, 51.784199428670796],
            [0.258385548808496, 51.783588183268968],
            [0.257471564920238, 51.781863863812198],
            [0.25379623432421, 51.781718577116941],
            [0.250141539184095, 51.780880346329447],
            [0.242587397687717, 51.780662326886272],
            [0.237500451999459, 51.779820450089325],
            [0.236511564380207, 51.782191744844425],
            [0.236812066852705, 51.786126595978779],
            [0.237403738254721, 51.786714243298995],
            [0.240894304879891, 51.788390516800241],
            [0.238315751714972, 51.788646480037031],
            [0.238060005921029, 51.787991291366787],
            [0.237314294854053, 51.787866102659244],
            [0.235874421015005, 51.788569746998888],
            [0.234886499326004, 51.789646067733557],
            [0.235810939637198, 51.791068205044432],
            [0.233792064371023, 51.79103285064204],
            [0.233067859782367, 51.791461171047381],
            [0.23260699054772, 51.793668619357653],
            [0.231520168782892, 51.795154153146946],
            [0.225769439223612, 51.794497017434523],
            [0.226739488978291, 51.795906669770815],
            [0.224156170132199, 51.796576967072149],
            [0.224071790075701, 51.796866326642863],
            [0.222116810623604, 51.796837654143715],
            [0.221955636090364, 51.798515121064781],
            [0.212724899650477, 51.797139820957405],
            [0.212319827241329, 51.796953205717443],
            [0.212868290479734, 51.796513936036405],
            [0.213799652956801, 51.794062107384953],
            [0.210484504294371, 51.792699173429568],
            [0.207415519731651, 51.792096792680297],
            [0.20605276372797, 51.793263534441301],
            [0.203412618744583, 51.794080120737043],
            [0.201772401191688, 51.795419273731284],
            [0.201216592419813, 51.795352347267993],
            [0.199626302153347, 51.796557444421865],
            [0.198660277976583, 51.796554834400389],
            [0.197949290313316, 51.795921565966104],
            [0.19663302021546, 51.795784304766734],
            [0.195009949165064, 51.797631119876826],
            [0.195026103701818, 51.798083143537283],
            [0.19412707294095, 51.798564842658926],
            [0.195220699028819, 51.800058769853464],
            [0.195421813227259, 51.801123328670322],
            [0.192565471640962, 51.804731390572655],
            [0.192786009439729, 51.806286583133449],
            [0.192366316201346, 51.80807043650502],
            [0.190745819113709, 51.809653655899083],
            [0.192074372933597, 51.812397690776081],
            [0.192139845571821, 51.813686897593158],
            [0.189995721566363, 51.815037967341119],
            [0.188561098901319, 51.819295661226292],
            [0.188405650922003, 51.819651061267983],
            [0.186377900018991, 51.819727454826484],
            [0.18493808972091, 51.820109424897929],
            [0.179836493895044, 51.820295844446129],
            [0.176216520514863, 51.820003204234517],
            [0.174942923439333, 51.820524055881904],
            [0.166224467374737, 51.822147188569772],
            [0.163526707693405, 51.822931546541504],
            [0.16340295631746, 51.822741384689998],
            [0.162208848628895, 51.825199410456513],
            [0.16154799344572, 51.825248430470303],
            [0.161182034799561, 51.825940383594002],
            [0.161547130356249, 51.827594580815905],
            [0.162986471911864, 51.828799180569895],
            [0.165378882590794, 51.829538390606352],
            [0.166096419966952, 51.830699590734525],
            [0.165194444909604, 51.831896014564457],
            [0.164426702898623, 51.832048633031427],
            [0.163354198102569, 51.83180848784248],
            [0.161671299217203, 51.832127196693037],
            [0.161888580026076, 51.833057518511886],
            [0.160879173210466, 51.834044560103401],
            [0.161553428230349, 51.835939467292924],
            [0.161792389874441, 51.840746035688731],
            [0.163139397536365, 51.844769664992896],
            [0.165139035395127, 51.846927022868954],
            [0.164747371841281, 51.847630248222544],
            [0.164807686590826, 51.8496434448804],
            [0.166777939910653, 51.850729417340204],
            [0.167428799588527, 51.850464736269011],
            [0.168336574798234, 51.850590982439108],
            [0.170813135078954, 51.849703601407761],
            [0.173371489973114, 51.849762460308305],
            [0.17350811706302, 51.851362390100512],
            [0.171172586498384, 51.85237491664072],
            [0.173037075052546, 51.854273856805655],
            [0.169369615586659, 51.855978187178984],
            [0.171510150840663, 51.856611318974629],
            [0.171165389081447, 51.857021444567906],
            [0.171655130312652, 51.857149087701252],
            [0.17118880769211, 51.857598326834164],
            [0.172380510279785, 51.858007958199735],
            [0.170441065011398, 51.861614661167067],
            [0.174321396330562, 51.862057357876068],
            [0.177886003307006, 51.863028234505819],
            [0.18278719359194, 51.863552460904963],
            [0.181966539032517, 51.864632393700042],
            [0.1827133839658, 51.865087941992499],
            [0.184987021314406, 51.864738184901604],
            [0.185452829613026, 51.865148578769201],
            [0.186344899823005, 51.863506164676963],
            [0.187510833863982, 51.864186796198531],
            [0.187063629499498, 51.864968463600157],
            [0.194222792246197, 51.865770338994253],
            [0.195566963443017, 51.868078798763925],
            [0.193892738596926, 51.869762852255455],
            [0.194235388015378, 51.86990843314009],
            [0.193590111243763, 51.871633531280295],
            [0.190823393552121, 51.871827176332125],
            [0.192830075026646, 51.872344617767034],
            [0.193382363247686, 51.873245255596039],
            [0.18979998172858, 51.874234626837854],
            [0.187544960735381, 51.875306220044131],
            [0.185927989860277, 51.87665457093604],
            [0.184989440127132, 51.87836258560597],
            [0.182874183265726, 51.878366786730567],
            [0.181798031839258, 51.879636698955245],
            [0.180011335004775, 51.880667083689509],
            [0.177851063393193, 51.880794324738211],
            [0.176679964760723, 51.881576758654454],
            [0.177114918164552, 51.882038113697952],
            [0.174949114675627, 51.882804763682131],
            [0.17698799793968, 51.884433343707848],
            [0.175414867641333, 51.88480685449121],
            [0.176499901325325, 51.885549341001457],
            [0.177982318853608, 51.885974223017769],
            [0.17727658854729, 51.886315509759505],
            [0.177109888275631, 51.886865333383192],
            [0.175957473168437, 51.887285917703728],
            [0.178122888688055, 51.888250297019034],
            [0.179622003690772, 51.889798900897844],
            [0.182195785310159, 51.890638738315751],
            [0.182342760787723, 51.891962392446757],
            [0.182833395936668, 51.892009942240314],
            [0.183874034340967, 51.893359274151322],
            [0.183637341271448, 51.893633439911412],
            [0.180547794534248, 51.893882282309633],
            [0.179842958205759, 51.895325135991769],
            [0.179321681345749, 51.895546114646393],
            [0.175360680234053, 51.894607776541186],
            [0.16771141305193, 51.894129389649976],
            [0.168962102292865, 51.891790798490263],
            [0.165713971709946, 51.891188797581826],
            [0.164615829000747, 51.891926600223535],
            [0.163203480645465, 51.891539827644273],
            [0.162178697033418, 51.89208112358169],
            [0.157078816465476, 51.890077719626767],
            [0.15040364353487, 51.888217105458757],
            [0.146955045167632, 51.886691140782972],
            [0.146136250588383, 51.887135016635128],
            [0.144289737965428, 51.887359325833437],
            [0.141476506754547, 51.888188408076019],
            [0.141229881186937, 51.887341320954803],
            [0.139357719595613, 51.887332218061047],
            [0.139556875296715, 51.885375456410692],
            [0.132283153009058, 51.88459209837113],
            [0.132341239213582, 51.884314980412725],
            [0.130985370164684, 51.884343145874873],
            [0.129201363031757, 51.883921335055263],
            [0.127593568399942, 51.883937834640314],
            [0.127210263333624, 51.884960001686757],
            [0.124734992077831, 51.8845775977573],
            [0.123799287889303, 51.889295685985104],
            [0.124757605186791, 51.892420302172972],
            [0.123841503451414, 51.897464451365458],
            [0.122742516466065, 51.90054526905093],
            [0.123482522421845, 51.903074942042281],
            [0.121736609675334, 51.911186922978864],
            [0.12231959822869, 51.915841612769299],
            [0.122893982553146, 51.917321274920752],
            [0.122576131611743, 51.919183916605682],
            [0.124230163144789, 51.921660221825221],
            [0.124831867816985, 51.923530545512932],
            [0.124422391953315, 51.924006436316766],
            [0.121111528534616, 51.925256736197298],
            [0.120242125815286, 51.926560097103554],
            [0.120321927522355, 51.927142258048299],
            [0.121802171187961, 51.928551645881527],
            [0.121098768265727, 51.930322315103155],
            [0.118604942570665, 51.933983025256708],
            [0.118758066995297, 51.937572677341656],
            [0.118168359976293, 51.940495883699796],
            [0.118089748624599, 51.945590509524692],
            [0.118415446905837, 51.947160089851224],
            [0.117984882405212, 51.948058969654738],
            [0.116749382391547, 51.95158007517464],
            [0.115990939235234, 51.953058546821566],
            [0.114896686396274, 51.954113218019096],
            [0.114708089154416, 51.955826928588991],
            [0.11373118305728, 51.95711957215395],
            [0.111216503822023, 51.958985624114689],
            [0.110577546209378, 51.960163372530047],
            [0.106068137824023, 51.96286266610521],
            [0.104463093997305, 51.965577340903913],
            [0.104474802974761, 51.967722670745822],
            [0.10552250911097, 51.970103955546762],
            [0.106984116132172, 51.971808804888575],
            [0.10149492124928, 51.97615752075194],
            [0.09988699331043, 51.976397526118362],
            [0.097728676405999, 51.977355001356081],
            [0.097390217758659, 51.978837546439308],
            [0.098260200252131, 51.98053415565289],
            [0.098068927978146, 51.982330603324598],
            [0.096433070098454, 51.984316436998874],
            [0.083970901990251, 51.981360736107291],
            [0.07681681865895, 51.980802838714887],
            [0.071893779657208, 51.98153790412087],
            [0.069416066626689, 51.982977056234837],
            [0.069032225144849, 51.984033183282961],
            [0.0709894236889, 51.984946545919087],
            [0.072441295501786, 51.987404627391435],
            [0.073029739638167, 51.992198762419527],
            [0.073777461026418, 51.994258281092378],
            [0.074293443931025, 52.00085120629182],
            [0.07412201596741, 52.001397349333715],
            [0.072166964041967, 52.002692460092661],
            [0.070992966103129, 52.002730196499229],
            [0.070167449093961, 52.00435249914743],
            [0.068098740708988, 52.005779024134291],
            [0.069741767664361, 52.006020822354458],
            [0.070037651961041, 52.007349150397545],
            [0.071432458316836, 52.00827239323889],
            [0.076001362665191, 52.007189324189021],
            [0.080493558195713, 52.008132455275877],
            [0.082885735093134, 52.010947865563807],
            [0.083220473228738, 52.013573935905249],
            [0.08539587336246, 52.014820410194268],
            [0.085183217998952, 52.0162017664411],
            [0.0895142397128, 52.019948455426032],
            [0.092227050365567, 52.021643868277131],
            [0.090772945169757, 52.022530233586878],
            [0.091709574861685, 52.024954061925634],
            [0.091168887404782, 52.025174976820438],
            [0.094673409100371, 52.029017978599008],
            [0.092952300689325, 52.030215746898087],
            [0.093611201685406, 52.030651844319586],
            [0.094167959136732, 52.032863895346743],
            [0.094812656977069, 52.033743549180137],
            [0.096313979750396, 52.034390349460459],
            [0.101128646982603, 52.038846480840462],
            [0.100437257638106, 52.041733584029544],
            [0.101255469108394, 52.045001107085959],
            [0.10301965193285, 52.047508994547748],
            [0.104713243341009, 52.048665706619389],
            [0.104946216683891, 52.049650674413762],
            [0.105948945487772, 52.049728969918185],
            [0.104943954982763, 52.057731902330659],
            [0.105708625300249, 52.060250408266604],
            [0.10951096806255, 52.059986365256684],
            [0.114541727484701, 52.060590376391758],
            [0.119049898623789, 52.059951873470688],
            [0.121932976006456, 52.057376801711413],
            [0.122870631018607, 52.057555039551865],
            [0.124910069646766, 52.054115660634018],
            [0.127565380073171, 52.051888050530145],
            [0.128236587775616, 52.049991178642685],
            [0.128107818460775, 52.047312053025593],
            [0.130396657476857, 52.048428871371122],
            [0.131202396588785, 52.048351353277383],
            [0.13166535361289, 52.048701763401205],
            [0.132539178434331, 52.048338852853],
            [0.133736666090256, 52.049137252942906],
            [0.134217636255134, 52.048916326487486],
            [0.136589468785384, 52.049994654571421],
            [0.143018256046947, 52.05090479139205],
            [0.151089340380194, 52.052841182312406],
            [0.152606974544685, 52.05030734896971],
            [0.160549806364531, 52.052088074032113],
            [0.161397023013156, 52.049757050221665],
            [0.160729158444082, 52.048429467799636],
            [0.164669162731051, 52.048385013762974],
            [0.164818055319001, 52.048055861766052],
            [0.166368542890316, 52.048180227085048],
            [0.166336171976899, 52.051551090765876],
            [0.167257557643789, 52.051753553673478],
            [0.167542529391244, 52.054989091007236],
            [0.171187860137269, 52.055503762130769],
            [0.174079243151249, 52.055570038007524],
            [0.175450068488645, 52.056133721766841],
            [0.17625706514662, 52.056110724939479],
            [0.176684388403089, 52.056919321113597],
            [0.177701059280629, 52.056948192069619],
            [0.177969652438952, 52.057311906730163],
            [0.18149499058774, 52.056674780843892],
            [0.181829771574615, 52.056929355068675],
            [0.186345474668759, 52.056650511601113],
            [0.18989408575547, 52.060190477904364],
            [0.188336670388266, 52.060889333794321],
            [0.189888260128747, 52.062188652490697],
            [0.186835502427321, 52.065666881251268],
            [0.183166026072445, 52.073172359209593],
            [0.184434857569499, 52.073546298002825],
            [0.184533980698764, 52.073843000537018],
            [0.185759077886917, 52.073888622438396],
            [0.193562761595373, 52.075128405476633],
            [0.193941434580704, 52.075470252407634],
            [0.199248232221624, 52.085874199602131],
            [0.200563312101454, 52.087385514364499],
            [0.203430376241595, 52.092662506682743],
            [0.207881245734737, 52.091709662489869],
            [0.210113726398921, 52.091628251252068],
            [0.21155671250052, 52.091080533579017],
            [0.212989691535855, 52.091039246290791],
            [0.213097922880347, 52.090423946303581],
            [0.214748164495045, 52.09017173675079],
            [0.224164317636944, 52.089239954770648],
            [0.229153471636503, 52.089788631080204],
            [0.231156205750339, 52.08969857635347],
            [0.235178949888215, 52.088988394641895],
            [0.244084131939317, 52.083006566705158],
            [0.246321589521194, 52.082045827256636],
            [0.24749151042791, 52.079099237368808],
            [0.25154418188089, 52.076774702776689],
            [0.251981410932871, 52.077081976052128],
            [0.252311099953527, 52.07818082452895],
            [0.254021331442347, 52.080030204306993],
            [0.259699745548379, 52.082765704548308],
            [0.261912131636804, 52.083422857584907],
            [0.264777294817861, 52.084776916263849],
            [0.268574828242612, 52.087242391416126],
            [0.271938625413258, 52.090127056513367],
            [0.275238213298081, 52.09111009980694],
            [0.276200082179274, 52.092626508563576],
            [0.285982746073213, 52.090395899782862],
            [0.291829108887357, 52.088311230268005],
            [0.302207955942498, 52.082694637181191],
            [0.308672969733545, 52.080272402933431],
            [0.31025173500974, 52.078851241900175],
            [0.311035566783096, 52.076932188919855],
            [0.312068259749904, 52.076893045778455],
            [0.31205142508227, 52.076539077077804],
            [0.316739265605597, 52.076093598153662],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000078",
        LAD13CDO: "23UB",
        LAD13NM: "Cheltenham",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.095068941929261, 51.936163326748442],
            [-2.092451940895619, 51.934616302622779],
            [-2.091698684245321, 51.934699608752993],
            [-2.091810817055622, 51.934055780014312],
            [-2.091299724685693, 51.933774766640518],
            [-2.090979012751132, 51.932697917899596],
            [-2.08962339187813, 51.931923953735861],
            [-2.085841216137587, 51.931546469848122],
            [-2.083559894920275, 51.931879876844995],
            [-2.081893895615378, 51.931492648667145],
            [-2.078884271359645, 51.93204135118993],
            [-2.078813876677087, 51.931709637955038],
            [-2.077028080207903, 51.931828603419817],
            [-2.073918628629276, 51.930982771273044],
            [-2.071906191620653, 51.931242957237274],
            [-2.070706809702455, 51.930627816474562],
            [-2.068764704335877, 51.930281926276173],
            [-2.068401428175325, 51.930486229935354],
            [-2.068402163509568, 51.930015111453557],
            [-2.067224989922201, 51.929608508796697],
            [-2.064461956979704, 51.928732557875293],
            [-2.06249658437691, 51.927251937260152],
            [-2.060214573535326, 51.928120744521756],
            [-2.055030176748828, 51.927033608060128],
            [-2.052599002564834, 51.926073607544886],
            [-2.048486733639299, 51.923622689351255],
            [-2.046493810786629, 51.922895241998724],
            [-2.039951315905074, 51.922402265075014],
            [-2.039737014145545, 51.918512011759297],
            [-2.040602533660988, 51.91575692668782],
            [-2.040534599027669, 51.914591739370088],
            [-2.038030548299795, 51.913968617325629],
            [-2.036517250228946, 51.914131833881882],
            [-2.036660188231453, 51.912923421960926],
            [-2.033671088508267, 51.912783165672316],
            [-2.033684832446816, 51.911730335628761],
            [-2.032706487624936, 51.911771971555019],
            [-2.032680079272925, 51.911442914570728],
            [-2.033369029745239, 51.911277288447714],
            [-2.033576054602968, 51.910133595353798],
            [-2.033178501857253, 51.90920135959616],
            [-2.033814625610508, 51.908429763552519],
            [-2.034597756245204, 51.907933240096128],
            [-2.036220172002574, 51.908136840344959],
            [-2.036395486601207, 51.907430106164213],
            [-2.0375439549543, 51.907565504488453],
            [-2.03790831918491, 51.905268227745033],
            [-2.036560891126759, 51.9052767487862],
            [-2.03378687310829, 51.904409068823384],
            [-2.029911584569779, 51.904088254053988],
            [-2.024648771417917, 51.902058454254629],
            [-2.023606835605244, 51.902399423189344],
            [-2.019265496107119, 51.902673545279647],
            [-2.010223578587406, 51.902916552009678],
            [-2.01267865793048, 51.898458645931512],
            [-2.012615770876576, 51.897080355373184],
            [-2.011852377659294, 51.895513330722039],
            [-2.013016740646695, 51.891692090685844],
            [-2.016711084185157, 51.885435771779903],
            [-2.015844693917456, 51.884150199018727],
            [-2.014898964612841, 51.884400270916359],
            [-2.015610593365441, 51.883611677576475],
            [-2.015338573611263, 51.882636204178596],
            [-2.015913380767156, 51.881223660035893],
            [-2.021117743449588, 51.876252670797626],
            [-2.022112658380862, 51.876007933747779],
            [-2.023286158828014, 51.872843811589078],
            [-2.024801288015173, 51.870543628604381],
            [-2.023925817999318, 51.868473208818912],
            [-2.025354834331933, 51.865735177810095],
            [-2.025259611462717, 51.864276874273919],
            [-2.026955823536573, 51.864304365880621],
            [-2.028043974124538, 51.864998206751288],
            [-2.03173646250294, 51.864192574655895],
            [-2.036550629811971, 51.864265791301349],
            [-2.036998676046257, 51.861672673276175],
            [-2.038531298492621, 51.8607011598627],
            [-2.041838077812241, 51.859500635691987],
            [-2.047261776600673, 51.858334246779826],
            [-2.048124312488167, 51.858361767023247],
            [-2.050255429975985, 51.859242879668663],
            [-2.051095562878277, 51.859982467855282],
            [-2.052025982399579, 51.859666477071357],
            [-2.056175729031897, 51.862923763755198],
            [-2.059372522212421, 51.864436255157528],
            [-2.061953480812786, 51.864690260316671],
            [-2.065881332020116, 51.864386023369164],
            [-2.068364056776205, 51.864958221073813],
            [-2.070764713513694, 51.865019732654915],
            [-2.071258111083379, 51.865704540532718],
            [-2.069942948525142, 51.867018004155447],
            [-2.069560215180442, 51.868392940326345],
            [-2.069941493327947, 51.868881818264178],
            [-2.070677854471908, 51.868887670227771],
            [-2.072239957792225, 51.868484824918852],
            [-2.074798738881652, 51.868320485122474],
            [-2.076249262308043, 51.868954308198148],
            [-2.076687322868965, 51.870330528721709],
            [-2.076078755835071, 51.872043690396893],
            [-2.077021968944746, 51.87236944404188],
            [-2.076832510259046, 51.872846984672073],
            [-2.078124189481836, 51.873081692612594],
            [-2.079806170307968, 51.873069771016141],
            [-2.079350061627699, 51.872234826670621],
            [-2.08029315319668, 51.871664162189177],
            [-2.079807265590938, 51.87123293094762],
            [-2.080725978326829, 51.870851088266647],
            [-2.081057890208935, 51.871270734099134],
            [-2.081744395070079, 51.870994237186984],
            [-2.082412824367367, 51.871161899141093],
            [-2.081311723583819, 51.871882838234718],
            [-2.081828345833528, 51.872430024292363],
            [-2.08353451695389, 51.8729431005967],
            [-2.084442416854619, 51.872215986446768],
            [-2.085719415906455, 51.873119543958325],
            [-2.089900660093448, 51.872131012519368],
            [-2.092295269611665, 51.871168925766916],
            [-2.092776967058, 51.872327472072456],
            [-2.092373234118844, 51.873769030564851],
            [-2.093177079275979, 51.875458682180401],
            [-2.095174225197013, 51.874699147388668],
            [-2.095667619261242, 51.875158180608501],
            [-2.097470435129889, 51.874567793787854],
            [-2.098017860686819, 51.875148149281422],
            [-2.09625146480105, 51.875795157151934],
            [-2.097048207773242, 51.876802379390391],
            [-2.097064746488787, 51.878407238690819],
            [-2.097526219795542, 51.878856400271381],
            [-2.102275806996928, 51.875986962122155],
            [-2.104280864771017, 51.876815955801476],
            [-2.105908561394809, 51.878369921641401],
            [-2.107715479492384, 51.878887950555139],
            [-2.113995875802107, 51.880192890742457],
            [-2.117955609767076, 51.881693153767372],
            [-2.121970409374773, 51.882508121122527],
            [-2.123257801162775, 51.883111862989018],
            [-2.12603062902821, 51.87925992337707],
            [-2.127731581278808, 51.87971302427809],
            [-2.128602285721795, 51.882437210325818],
            [-2.127392078829217, 51.883969676007368],
            [-2.127515497602674, 51.884455948511523],
            [-2.127144718692484, 51.884351157269954],
            [-2.124407745648078, 51.886011107021467],
            [-2.126083907162298, 51.887383124775567],
            [-2.142423574973082, 51.884501752798656],
            [-2.143003149891741, 51.889033348486841],
            [-2.141436835123812, 51.891818817840722],
            [-2.139605655617486, 51.894543442147814],
            [-2.137878132262571, 51.898442120899297],
            [-2.136300213434385, 51.90000927020413],
            [-2.135380815166209, 51.90213487170012],
            [-2.133467586867296, 51.904904444106407],
            [-2.132984402424751, 51.906662702190474],
            [-2.131460401944913, 51.908432017063632],
            [-2.130325056936507, 51.909919467848411],
            [-2.128883879156908, 51.913776327878359],
            [-2.124968746925155, 51.91900332999505],
            [-2.124504783077954, 51.91947673996512],
            [-2.123603713515489, 51.919098278851301],
            [-2.121361755572162, 51.919133882683902],
            [-2.119483003991253, 51.918514542970144],
            [-2.118934716703258, 51.917912714229814],
            [-2.11584208373741, 51.91731971356112],
            [-2.114884342233043, 51.916907975438356],
            [-2.114945647940763, 51.916434098911125],
            [-2.113918805942649, 51.916282255389859],
            [-2.112519422272987, 51.916570411859375],
            [-2.110714010195891, 51.916119889258788],
            [-2.109628735625204, 51.915845788141993],
            [-2.106260587559368, 51.919745507429056],
            [-2.105141445886504, 51.921799118877438],
            [-2.103257625942588, 51.923352605483373],
            [-2.10415295172188, 51.924426217049088],
            [-2.105218490535162, 51.924891890311045],
            [-2.105485078819381, 51.926331080102464],
            [-2.106370903052877, 51.927029766075385],
            [-2.105997128682338, 51.928247459548928],
            [-2.106626404805896, 51.929902996430755],
            [-2.106100423222039, 51.930725232364971],
            [-2.106645999645599, 51.930804756336627],
            [-2.106569144063771, 51.931512402327627],
            [-2.107127897643868, 51.931624278990185],
            [-2.107689114962559, 51.933973961060389],
            [-2.106928734335776, 51.934103224262181],
            [-2.106799571440185, 51.934834293691729],
            [-2.105867044175121, 51.934752422690273],
            [-2.105164161740178, 51.93646040645497],
            [-2.105759438846668, 51.936615412641508],
            [-2.106829750671741, 51.937735595941646],
            [-2.106572080475806, 51.938873165274515],
            [-2.105040554296488, 51.93771563193684],
            [-2.105291643374496, 51.937478949223461],
            [-2.104943319070543, 51.937188858364628],
            [-2.102816319817543, 51.936375274792319],
            [-2.095068941929261, 51.936163326748442],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000079",
        LAD13CDO: "23UC",
        LAD13NM: "Cotswold",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.757694423309217, 52.103149262984459],
            [-1.755991496626152, 52.101920342084796],
            [-1.755553370113536, 52.101947306266339],
            [-1.755052194920801, 52.10122342642368],
            [-1.754138512005909, 52.100917645867021],
            [-1.754848235833224, 52.099279243940636],
            [-1.753550618575012, 52.097654644083796],
            [-1.75180153620232, 52.096910156364316],
            [-1.751328565720706, 52.095859961706338],
            [-1.75018811646351, 52.094884773858112],
            [-1.746033118020132, 52.092947428530216],
            [-1.742408598922214, 52.092227519712559],
            [-1.742415875128555, 52.09197759762516],
            [-1.740282130457369, 52.091439780044503],
            [-1.738552775752942, 52.091395503928588],
            [-1.732898478112293, 52.095041106531262],
            [-1.732210973233458, 52.096990502242519],
            [-1.730229933061961, 52.097981246377813],
            [-1.729839900551585, 52.098992692000245],
            [-1.728773722723523, 52.099556652188774],
            [-1.728571843369245, 52.100107309696696],
            [-1.728554053443905, 52.09798549515147],
            [-1.729646495772431, 52.095242282786778],
            [-1.730201244304383, 52.095232763649399],
            [-1.730519666696686, 52.092773670248121],
            [-1.730176614418193, 52.091569048197456],
            [-1.729275114409602, 52.091964367036304],
            [-1.728694259388157, 52.091712195971205],
            [-1.728409816528698, 52.090960828421665],
            [-1.728859632034795, 52.088747482872215],
            [-1.728096552596821, 52.088707966651199],
            [-1.728082545596742, 52.088370787535943],
            [-1.72911175267218, 52.085431435292953],
            [-1.729140128059127, 52.08411797552386],
            [-1.729463036531043, 52.084053084982209],
            [-1.729078609331596, 52.081501572316249],
            [-1.728169903477389, 52.081432952068717],
            [-1.730682976089113, 52.073386734128768],
            [-1.728164315424447, 52.072735432812053],
            [-1.728792977367078, 52.071556412758632],
            [-1.727836995737349, 52.07137799554345],
            [-1.72713180666273, 52.070745225994642],
            [-1.724236000085826, 52.069537347622173],
            [-1.723003479375713, 52.069500296089316],
            [-1.720686955831284, 52.068771090321164],
            [-1.717082854330854, 52.068195194984646],
            [-1.714291329533233, 52.066715804719294],
            [-1.71254463919548, 52.066570409059707],
            [-1.711823660942896, 52.066623492934106],
            [-1.712682168692067, 52.068327510752162],
            [-1.712231034946229, 52.070654081329394],
            [-1.709893995647061, 52.073336550582589],
            [-1.708004281843783, 52.072488569613647],
            [-1.701743240585519, 52.07279656966881],
            [-1.70159076273218, 52.070279711385425],
            [-1.703372822204857, 52.067523186920077],
            [-1.704060372958546, 52.063257964256842],
            [-1.705451539164527, 52.061544236227419],
            [-1.705153025041201, 52.059694118019507],
            [-1.702998814258448, 52.05748151366241],
            [-1.702514261953761, 52.05621351210619],
            [-1.699573071925575, 52.054617412245399],
            [-1.692370343036029, 52.054366889176485],
            [-1.690191972072251, 52.054723510734547],
            [-1.690024799749264, 52.053168589409701],
            [-1.688648733521048, 52.051820863712528],
            [-1.690618636337462, 52.051435853019534],
            [-1.691175907308155, 52.048230348946177],
            [-1.693052868605382, 52.044994117850024],
            [-1.694807878800951, 52.042927224660353],
            [-1.695013838500754, 52.040512866014041],
            [-1.693992799149043, 52.039515853945694],
            [-1.691862680442004, 52.039746762427804],
            [-1.691868270550888, 52.039357481262869],
            [-1.691242115095626, 52.039461933549646],
            [-1.689777018915084, 52.038184111580428],
            [-1.689358074042627, 52.038262126723133],
            [-1.68870280923673, 52.037730848667287],
            [-1.688022967584048, 52.037800977606771],
            [-1.687461097906576, 52.037468633378218],
            [-1.687425319253256, 52.037791303549227],
            [-1.686206059519173, 52.037446420381606],
            [-1.685910135051721, 52.037652418025473],
            [-1.685710853206342, 52.037381267997574],
            [-1.683719526560055, 52.037785019929373],
            [-1.683314626271419, 52.037318216708151],
            [-1.68308284559576, 52.037522581358928],
            [-1.682823411233141, 52.037307905985465],
            [-1.680990522502565, 52.03736409890395],
            [-1.679737839288017, 52.036808676604466],
            [-1.679168140028557, 52.036969859297486],
            [-1.678631820083262, 52.036744531337582],
            [-1.678359112773213, 52.036961362144247],
            [-1.678215023604462, 52.036522224308399],
            [-1.677621655632088, 52.036730985511582],
            [-1.677826749046406, 52.036997669844972],
            [-1.676409029099464, 52.036693499924809],
            [-1.675977230874524, 52.036935062483771],
            [-1.675786726543181, 52.036668415024685],
            [-1.675317693082437, 52.036817268878899],
            [-1.675418988681728, 52.036518158326494],
            [-1.673359755979972, 52.036029673624014],
            [-1.673338827885879, 52.036301133996595],
            [-1.672104547458834, 52.036236572091155],
            [-1.670662183934521, 52.036716251159042],
            [-1.668890567242847, 52.036570136798211],
            [-1.667144981811401, 52.036060846001192],
            [-1.664851921458348, 52.031763100540125],
            [-1.663879029787005, 52.031264944888981],
            [-1.663275725056563, 52.031632740795793],
            [-1.66107782952504, 52.031602169490789],
            [-1.66009475962181, 52.031491451751144],
            [-1.660374369477075, 52.03171792353055],
            [-1.659965766538943, 52.031971182351235],
            [-1.659105545593974, 52.031614465819452],
            [-1.658264563471312, 52.031986943182176],
            [-1.657673074050952, 52.031754167016622],
            [-1.656452784521142, 52.032165987799594],
            [-1.655587239405677, 52.034784246678875],
            [-1.653562120105679, 52.035390579969238],
            [-1.652265093328167, 52.035340909983631],
            [-1.652206796390643, 52.034963129512853],
            [-1.6516725228504, 52.0352456580585],
            [-1.651450764279043, 52.034893467223171],
            [-1.650980542804196, 52.035186970756811],
            [-1.650233437918331, 52.034906945160337],
            [-1.650279854033094, 52.034562739746256],
            [-1.649365289407557, 52.034634646564662],
            [-1.649340151228435, 52.034307311009464],
            [-1.64810801386449, 52.03398087513937],
            [-1.646351537013906, 52.035080575363544],
            [-1.64613463398006, 52.034672646797965],
            [-1.64552608751037, 52.034757129625838],
            [-1.645387475003922, 52.034219969137034],
            [-1.645269402192423, 52.034588231994853],
            [-1.64423021505261, 52.034565321847914],
            [-1.644473180189796, 52.035545139864034],
            [-1.643706097429949, 52.035765793568359],
            [-1.644015096992116, 52.03522099243267],
            [-1.64338196503282, 52.035279316994639],
            [-1.639265608364004, 52.037227664044771],
            [-1.638516953524865, 52.036783030020821],
            [-1.637928714036208, 52.037055441100321],
            [-1.637019653088557, 52.036978922821611],
            [-1.637042240850003, 52.03743032449416],
            [-1.636655844607733, 52.037439024309862],
            [-1.6365935436718, 52.037031554515096],
            [-1.635701764443462, 52.037160965911617],
            [-1.636017107699209, 52.036925484779722],
            [-1.635546956672159, 52.036657009423578],
            [-1.634880999386556, 52.036983109178294],
            [-1.634018233683089, 52.036414920776437],
            [-1.63343160302814, 52.03648772130218],
            [-1.632224940208915, 52.035905863188276],
            [-1.629120214062028, 52.037273511760255],
            [-1.628506651744839, 52.036899366156774],
            [-1.626891945993597, 52.037884142293152],
            [-1.626695921664079, 52.038490392881876],
            [-1.624430755377468, 52.038969596113446],
            [-1.624114053420358, 52.038488484652902],
            [-1.625074328241031, 52.037671591542761],
            [-1.624741568701698, 52.037367547060747],
            [-1.625062719317731, 52.037142003413038],
            [-1.624193069168399, 52.036713975723046],
            [-1.624239645435779, 52.036200756067721],
            [-1.622575964173196, 52.036067775087254],
            [-1.621473733581184, 52.035220915599908],
            [-1.620622279327633, 52.035235261566818],
            [-1.620495455468013, 52.034890510130055],
            [-1.621185385958335, 52.034668860471477],
            [-1.620555107655962, 52.034387223562199],
            [-1.619280823330653, 52.034928850904052],
            [-1.618919964827947, 52.034164375762408],
            [-1.617519920223611, 52.033383047120076],
            [-1.618017477602105, 52.033155397387105],
            [-1.617548400487436, 52.0326072428253],
            [-1.617729285333318, 52.031907454437004],
            [-1.617543981955267, 52.031586784856543],
            [-1.618487152495071, 52.030549617001967],
            [-1.618746027806715, 52.029074182044305],
            [-1.620158375752946, 52.027700466336064],
            [-1.620970433874793, 52.027674309337563],
            [-1.624563724089424, 52.026393842104696],
            [-1.627046433846798, 52.027370019453194],
            [-1.62841793566263, 52.02719543470139],
            [-1.635275677576493, 52.021970773211777],
            [-1.638401782693212, 52.020688437656268],
            [-1.64020931103795, 52.018809515182781],
            [-1.642624189537832, 52.015300592989533],
            [-1.643314355795851, 52.012888676232457],
            [-1.642174471885334, 52.011395460302481],
            [-1.641948729525265, 52.010288017386273],
            [-1.642850558567889, 52.009192086714151],
            [-1.644501813792574, 52.008752036714526],
            [-1.64861571181911, 52.006754044303271],
            [-1.65155930972577, 52.005713563450321],
            [-1.653033835901449, 52.004550916088668],
            [-1.65536921212359, 52.003388072553648],
            [-1.657448723386159, 52.000996305741921],
            [-1.659057076656594, 51.999816888795884],
            [-1.661112753411494, 51.999377774749604],
            [-1.665363336735798, 51.997586375827332],
            [-1.669900069534416, 51.993079522948562],
            [-1.669290667285042, 51.989208199007265],
            [-1.665766266163563, 51.987478328601249],
            [-1.664651146416656, 51.985091713200724],
            [-1.664110767022867, 51.984917552141894],
            [-1.6649407817198, 51.983156827278286],
            [-1.66534748905537, 51.980552460859812],
            [-1.667532908270467, 51.978389174515058],
            [-1.668955068515996, 51.975262592832124],
            [-1.666091669921041, 51.971121458099979],
            [-1.664291010565308, 51.966440238973803],
            [-1.662540893143321, 51.963995141906679],
            [-1.660971052520236, 51.96333790523272],
            [-1.657235296913545, 51.96310412765061],
            [-1.652386576695502, 51.961502151771036],
            [-1.653479284726826, 51.959837577638858],
            [-1.655190978259243, 51.959805736075175],
            [-1.655378963168062, 51.957493853170824],
            [-1.652346304496108, 51.957674651354395],
            [-1.648938627281015, 51.958915169023939],
            [-1.645779934907507, 51.956974493103381],
            [-1.643399421204924, 51.957129147874454],
            [-1.638848209334442, 51.956674738097838],
            [-1.635325422318699, 51.956819429745863],
            [-1.632503190457941, 51.955641857305508],
            [-1.628881101907934, 51.953190399883169],
            [-1.625366592248955, 51.949923704614996],
            [-1.622794709112703, 51.946387501571913],
            [-1.615202015886552, 51.937672412005739],
            [-1.617297049119355, 51.937281841007838],
            [-1.619922493722142, 51.937463863263773],
            [-1.623611300184807, 51.935728786024526],
            [-1.628269870874132, 51.933123646217638],
            [-1.630019695416004, 51.931516197240057],
            [-1.633776441085168, 51.929187618317819],
            [-1.634034164124138, 51.92932867639292],
            [-1.636130534600857, 51.927444394777133],
            [-1.637846791151117, 51.926693552808615],
            [-1.640033579986019, 51.925105278108738],
            [-1.64338194621027, 51.923716485126235],
            [-1.645542437732003, 51.922285368022791],
            [-1.643857997168178, 51.921992580422632],
            [-1.642832752128627, 51.922189972558741],
            [-1.641194697763771, 51.921552937661097],
            [-1.641683215709678, 51.921193891224782],
            [-1.641254734379707, 51.920587502462737],
            [-1.641857789870196, 51.920085849355985],
            [-1.641472121279612, 51.919764601648545],
            [-1.642623745295432, 51.919026355066599],
            [-1.643015973122931, 51.91943752750781],
            [-1.643229525402756, 51.918910411006877],
            [-1.643540880834791, 51.919068693600074],
            [-1.643735285466237, 51.918755500687702],
            [-1.644849607533095, 51.91868334220802],
            [-1.6448819531959, 51.918084648425683],
            [-1.645516881841491, 51.917772779695866],
            [-1.64514342084665, 51.917562168228876],
            [-1.644563160330917, 51.917763612323121],
            [-1.644474892906171, 51.91697304920077],
            [-1.643412742399848, 51.916704608339074],
            [-1.644026660910756, 51.916474500841154],
            [-1.643586351081273, 51.916078470542161],
            [-1.644719385896069, 51.91546871626619],
            [-1.643984932326359, 51.914947725931505],
            [-1.645385719129703, 51.914856647956661],
            [-1.64460173206885, 51.914164685822371],
            [-1.644901921984403, 51.913707056902119],
            [-1.644233708615735, 51.91400623473475],
            [-1.644348370579781, 51.913477917967832],
            [-1.643718122804541, 51.913572215720301],
            [-1.64392869564884, 51.913049583888821],
            [-1.642910246555575, 51.912965583112054],
            [-1.642676890256621, 51.912693351223211],
            [-1.641451990655357, 51.913708294665064],
            [-1.640775807561823, 51.912816138514195],
            [-1.640669562746686, 51.913377743799025],
            [-1.63988291229773, 51.912849375656265],
            [-1.639161594700171, 51.913060251879969],
            [-1.639129693856479, 51.912505417463606],
            [-1.638359575277589, 51.912457202192122],
            [-1.637964687582371, 51.912028023706569],
            [-1.63744156033049, 51.912180158628416],
            [-1.637455734181129, 51.912948022630083],
            [-1.636916910599952, 51.912340378316969],
            [-1.636264832234434, 51.912797800080618],
            [-1.634854031876405, 51.912874353055599],
            [-1.634718083413826, 51.912249065978394],
            [-1.635377167382319, 51.912004758094092],
            [-1.634195807057694, 51.911578524283541],
            [-1.636081541341593, 51.910652011768185],
            [-1.635108177265648, 51.910394558769539],
            [-1.635902146707517, 51.91000501349734],
            [-1.635127621259145, 51.90978863400899],
            [-1.635311615432191, 51.909327072785302],
            [-1.634674933437275, 51.909323301662184],
            [-1.635036710130029, 51.908808346825786],
            [-1.634707998350317, 51.908293049667144],
            [-1.635201810577916, 51.907807274457916],
            [-1.634972643706238, 51.907385791347338],
            [-1.635979429759998, 51.907096703514213],
            [-1.633758484668036, 51.905466065592968],
            [-1.635646711394078, 51.904380428480643],
            [-1.635025484270545, 51.903912777339663],
            [-1.635449113257916, 51.903831373123744],
            [-1.635179334079456, 51.902683300811567],
            [-1.635781656271229, 51.901891269572651],
            [-1.633596221067819, 51.900036863450694],
            [-1.634474870331965, 51.899758178686568],
            [-1.63426162955989, 51.899529148066151],
            [-1.633265704388456, 51.89957460204495],
            [-1.633063690522191, 51.899217034435715],
            [-1.634190051038179, 51.89849946858795],
            [-1.637805402247488, 51.897300467785236],
            [-1.639556670157925, 51.897112536079106],
            [-1.641561724785779, 51.895528165871383],
            [-1.642833033451314, 51.895379182818424],
            [-1.642721471504582, 51.89570341578478],
            [-1.643517909944068, 51.895879353359817],
            [-1.644236647720425, 51.894852968119942],
            [-1.644580299955189, 51.894949309170059],
            [-1.644895552782824, 51.894592422414505],
            [-1.645038519738722, 51.894891351142654],
            [-1.647484251877577, 51.89489510038927],
            [-1.648728373723653, 51.895432876688957],
            [-1.651380101181503, 51.895863327489849],
            [-1.653105531466589, 51.896941035398541],
            [-1.654596764182666, 51.896919344390646],
            [-1.65558312707527, 51.89659316621578],
            [-1.65760162267823, 51.897179862878886],
            [-1.657653724539852, 51.896634267288576],
            [-1.658664440573071, 51.896542796337009],
            [-1.658979327708783, 51.895644619337482],
            [-1.660266161762665, 51.895163729062382],
            [-1.660329905335781, 51.894804277203683],
            [-1.661682683028876, 51.89443864500435],
            [-1.661281171468869, 51.893528511633193],
            [-1.663033316380593, 51.892218169512489],
            [-1.66256199550445, 51.891703439917642],
            [-1.66263245436611, 51.887335862296894],
            [-1.663937836618878, 51.886083564986841],
            [-1.666026325642083, 51.885017789891144],
            [-1.666990308590917, 51.882953509785771],
            [-1.666885199967123, 51.881448134066375],
            [-1.668969587578842, 51.881496268631317],
            [-1.669274356953214, 51.879557784983589],
            [-1.668485248894602, 51.879596025426395],
            [-1.668964363567182, 51.878050935674658],
            [-1.667137444885249, 51.877977455165883],
            [-1.667124378852119, 51.876992013624047],
            [-1.668053125809795, 51.876330206239089],
            [-1.671661533695534, 51.875117556939642],
            [-1.673585256017104, 51.87404309510304],
            [-1.674474683846803, 51.87256834840732],
            [-1.675581087919562, 51.872623548930378],
            [-1.678877895371894, 51.869816627172362],
            [-1.680793943545292, 51.869154709603308],
            [-1.683559510212091, 51.868708131643615],
            [-1.685912118288293, 51.866863197603585],
            [-1.686880156445374, 51.865491961257426],
            [-1.685064368236345, 51.864308407729489],
            [-1.687203656851396, 51.862818917153248],
            [-1.681169118646952, 51.859632527512538],
            [-1.680619325094007, 51.858734640542934],
            [-1.68092485627461, 51.858033276097366],
            [-1.680601248311976, 51.854721931880391],
            [-1.678706283451374, 51.852914989608472],
            [-1.6763976735607, 51.850302189302447],
            [-1.678697476306068, 51.849459741239485],
            [-1.679220093175167, 51.845987058891879],
            [-1.681140453158017, 51.841896890083291],
            [-1.683066075680095, 51.840316981414674],
            [-1.685439603034496, 51.836924758865536],
            [-1.686474179086998, 51.834448705926413],
            [-1.686052945560557, 51.830741500228662],
            [-1.684619055573724, 51.825565151050576],
            [-1.684175703909103, 51.825679946248336],
            [-1.683138327823687, 51.82028705508926],
            [-1.681132750589911, 51.816045976394904],
            [-1.678105536399525, 51.81701595858523],
            [-1.67687242039222, 51.817017974534124],
            [-1.677826955764058, 51.812950360088415],
            [-1.680286281132664, 51.80712099571857],
            [-1.683765634959235, 51.80133118316558],
            [-1.685751055940906, 51.799856577209823],
            [-1.686889063141425, 51.798233132183583],
            [-1.693462542489442, 51.794143330941203],
            [-1.702159940797506, 51.790989127902307],
            [-1.707733252403527, 51.788294098609349],
            [-1.713860740728883, 51.786119835864412],
            [-1.719500791596, 51.783205927433777],
            [-1.717538414587599, 51.781513613083526],
            [-1.708415940919123, 51.777515517293075],
            [-1.700717432413697, 51.770578460669185],
            [-1.699262816253125, 51.764625354284533],
            [-1.699661959598228, 51.764525673645501],
            [-1.698493227822073, 51.761600581268517],
            [-1.696443921083041, 51.75713662882066],
            [-1.693691570579052, 51.753656233429957],
            [-1.695665064494332, 51.752302799310776],
            [-1.694388861820715, 51.751007469227211],
            [-1.694745492424464, 51.750751249457387],
            [-1.693938410773306, 51.749067815334492],
            [-1.694878964187469, 51.748569453680652],
            [-1.691657450990468, 51.746655837588236],
            [-1.692691780756718, 51.745994999840207],
            [-1.688319047521633, 51.741980649900988],
            [-1.687034429541995, 51.739878711032944],
            [-1.689053988306013, 51.739140500707784],
            [-1.68749885526299, 51.736793291737833],
            [-1.686639685063837, 51.734130530177048],
            [-1.687405017270327, 51.734020177363661],
            [-1.687186394152979, 51.733385721191105],
            [-1.685804950762884, 51.733389232206434],
            [-1.685521452465844, 51.730629094542579],
            [-1.686813139279422, 51.728514228113866],
            [-1.690121602488057, 51.726383997822417],
            [-1.69583190946448, 51.72376809388242],
            [-1.69560129203487, 51.722969083272524],
            [-1.694865780079439, 51.722550887231982],
            [-1.695120298182666, 51.722376219816638],
            [-1.693719958192394, 51.721583158569743],
            [-1.692599962117434, 51.720015776060237],
            [-1.690732198857545, 51.719218758830564],
            [-1.690993719133624, 51.719078285061798],
            [-1.688832576551365, 51.717377753966332],
            [-1.689791958702457, 51.716789569134534],
            [-1.688930985005866, 51.716536441727598],
            [-1.689031396957582, 51.716035899606567],
            [-1.688489267397355, 51.715726967206216],
            [-1.688893056530373, 51.71510135201671],
            [-1.687688718207014, 51.715110747684363],
            [-1.687314795410242, 51.714550503770035],
            [-1.687915947670485, 51.714061182958964],
            [-1.687613025079834, 51.713700732141128],
            [-1.688372515951086, 51.713968885510909],
            [-1.688781727379239, 51.713605826921302],
            [-1.688222352834189, 51.713283360746587],
            [-1.688152071910845, 51.713611351946],
            [-1.687848538479366, 51.713550305897783],
            [-1.687692892240638, 51.712821608246493],
            [-1.687331390464816, 51.71277209541406],
            [-1.688013790686141, 51.712040228437374],
            [-1.686179897863348, 51.71017147974662],
            [-1.681905357713324, 51.709032502517182],
            [-1.675323371344597, 51.703756473420391],
            [-1.666537247900401, 51.698360550181],
            [-1.664713538194935, 51.696874514416329],
            [-1.663498883864498, 51.696583326311334],
            [-1.66294645614962, 51.696741787360246],
            [-1.6624553734271, 51.69643287971698],
            [-1.659041475295571, 51.69750017519609],
            [-1.657452059597889, 51.69644718199222],
            [-1.656229166326485, 51.693811888717725],
            [-1.655306102323187, 51.693624866622443],
            [-1.655081858689512, 51.692481427040946],
            [-1.655497648744556, 51.691451354823172],
            [-1.654234948243728, 51.690276993104703],
            [-1.651105627312537, 51.688797685844754],
            [-1.650858443612588, 51.688208926839287],
            [-1.651830035334263, 51.687521282977109],
            [-1.653405078806522, 51.687177077641188],
            [-1.648851963887461, 51.685410280009933],
            [-1.648267728541082, 51.684083228081704],
            [-1.652668633047583, 51.681521226798147],
            [-1.655591058189628, 51.681804056616791],
            [-1.659576495607514, 51.680752002900235],
            [-1.660564706931465, 51.681285342224839],
            [-1.661903700438196, 51.680359508249168],
            [-1.662294175316093, 51.680753546887736],
            [-1.662181324847525, 51.681536357963729],
            [-1.663306973104437, 51.681291429339751],
            [-1.665324001414881, 51.681772823911949],
            [-1.665876078359153, 51.681046105429232],
            [-1.667127251645794, 51.681036167714026],
            [-1.668472420299205, 51.680433960392115],
            [-1.669092615190632, 51.681267395406486],
            [-1.672042652227701, 51.681944608026562],
            [-1.672666402580441, 51.682698011966416],
            [-1.673553475846036, 51.682236531588643],
            [-1.674662988107487, 51.682218927192274],
            [-1.675221954818334, 51.682531568657076],
            [-1.676277458849727, 51.684207744513643],
            [-1.674403555238667, 51.683705355975462],
            [-1.673876525253735, 51.683979925549494],
            [-1.673782663573353, 51.684763698926503],
            [-1.676038802233051, 51.686843310140908],
            [-1.675021334739751, 51.687324229315337],
            [-1.676029123142517, 51.688403257966215],
            [-1.677338806949696, 51.689145037274493],
            [-1.677647315014779, 51.688887835890689],
            [-1.679197628955194, 51.689156417371535],
            [-1.679126515866125, 51.689393591120833],
            [-1.681351645016647, 51.690393170271037],
            [-1.683072272949797, 51.690106505633707],
            [-1.684159868058728, 51.691187477085229],
            [-1.686944133288431, 51.691274045414971],
            [-1.68856249095598, 51.692188252254994],
            [-1.691447350479992, 51.692171581398824],
            [-1.693660091187499, 51.692708746575939],
            [-1.696844479041158, 51.692038164034933],
            [-1.697695734877975, 51.690862508721445],
            [-1.69734989693004, 51.690440831012211],
            [-1.697948384570687, 51.690287721269591],
            [-1.698246025679381, 51.689254497478906],
            [-1.699077497699056, 51.688868208409438],
            [-1.699006085668432, 51.68763802920364],
            [-1.699374626323298, 51.687469937396173],
            [-1.700589031612661, 51.687796719230427],
            [-1.701117953025212, 51.686996948661466],
            [-1.700665769890525, 51.686468913865973],
            [-1.700976280996831, 51.686324046407627],
            [-1.702716142202985, 51.685929248591485],
            [-1.703968379098154, 51.686441310038163],
            [-1.703738687188493, 51.685950710915741],
            [-1.706025095593143, 51.684691386799926],
            [-1.706145044048134, 51.683366382692206],
            [-1.707446759469189, 51.683377720699568],
            [-1.70967371167046, 51.681653331744478],
            [-1.709380630710982, 51.681117630699489],
            [-1.70980662588759, 51.680091886445751],
            [-1.708857347977717, 51.679490724986969],
            [-1.709477989328431, 51.678784651575995],
            [-1.709393927060061, 51.677907800353857],
            [-1.71025818582531, 51.677321907708745],
            [-1.709590913953893, 51.676277280264003],
            [-1.710591322325488, 51.675880536836303],
            [-1.711287536954625, 51.674876130988807],
            [-1.711068540438969, 51.671789805418278],
            [-1.712917463804201, 51.671883347608563],
            [-1.717476817243335, 51.670967396446024],
            [-1.720869250085493, 51.66884278029017],
            [-1.721478316219489, 51.669027645714756],
            [-1.721673806331784, 51.669689862942022],
            [-1.722243240165834, 51.669737064777394],
            [-1.723138615957845, 51.668974922290424],
            [-1.724639647654328, 51.668717701652021],
            [-1.724750109707199, 51.668146118212199],
            [-1.726570631155463, 51.667893216889226],
            [-1.727566657994372, 51.667206800855723],
            [-1.728589911212511, 51.667767522648887],
            [-1.729323524448647, 51.667436538351694],
            [-1.73035414006366, 51.667735616954019],
            [-1.731658622242033, 51.667448184665027],
            [-1.735486106962503, 51.667675344348481],
            [-1.737357925691385, 51.667289321106878],
            [-1.739225286147886, 51.667422951087225],
            [-1.740569810653143, 51.66669493852789],
            [-1.740456297205795, 51.666063503792714],
            [-1.741666575204954, 51.666049984867563],
            [-1.742096623107177, 51.665430534119125],
            [-1.744692909405537, 51.664742976340072],
            [-1.744894593324361, 51.664100541099835],
            [-1.749187662886612, 51.662972386183753],
            [-1.749579113406659, 51.663299602242553],
            [-1.750096929880785, 51.662995901162041],
            [-1.751010784646785, 51.6632379057436],
            [-1.751685415689794, 51.662809551501432],
            [-1.752854165071039, 51.662972057798257],
            [-1.753805263555615, 51.662212495679071],
            [-1.754573780967953, 51.66259622963986],
            [-1.757906141357315, 51.663165089710887],
            [-1.760113161303482, 51.663023055848441],
            [-1.760959236975461, 51.663242366617268],
            [-1.761446214942938, 51.663014978321897],
            [-1.761469417221978, 51.662450376107664],
            [-1.766331780694597, 51.662681323157962],
            [-1.768199826381429, 51.663794537983463],
            [-1.768481157071044, 51.665616714704854],
            [-1.770286736718429, 51.667966964834207],
            [-1.772948959200642, 51.667917288515589],
            [-1.773519225375107, 51.668384133369173],
            [-1.774560880837546, 51.668269248951923],
            [-1.777000494464885, 51.669387912327359],
            [-1.778034282844955, 51.669110241149063],
            [-1.781494691699529, 51.669070866111163],
            [-1.784508367753464, 51.667794278890021],
            [-1.788628637758397, 51.666998840266615],
            [-1.790700483489754, 51.668600285142034],
            [-1.792074784579624, 51.670357808116464],
            [-1.795448963099229, 51.672790452861911],
            [-1.794388940946711, 51.67663594234412],
            [-1.795219208404163, 51.67724699439259],
            [-1.796622801032527, 51.677080394995777],
            [-1.797842461230005, 51.678596615961567],
            [-1.799368523708326, 51.680836239146764],
            [-1.801189826062709, 51.685386178143112],
            [-1.802473001053157, 51.685355072335781],
            [-1.803672674605903, 51.68824594923634],
            [-1.806171283219311, 51.690536550451434],
            [-1.807221864870988, 51.692510042444326],
            [-1.806678067036874, 51.692810354603601],
            [-1.81118404456054, 51.697477797979097],
            [-1.807501892639671, 51.699148684319312],
            [-1.811952244218404, 51.702044742804723],
            [-1.811953491576436, 51.703141666624227],
            [-1.818498986580642, 51.702305876815522],
            [-1.819297043009534, 51.699572892052586],
            [-1.818820057448846, 51.699083039099811],
            [-1.819320055541257, 51.698152322942882],
            [-1.81871024235848, 51.696869244191895],
            [-1.819377777787556, 51.696007118370197],
            [-1.819377498477425, 51.694257435650641],
            [-1.815206252996732, 51.688611690666157],
            [-1.813501405337454, 51.687738653868664],
            [-1.812482976543013, 51.685996341304786],
            [-1.815415666795859, 51.681927969673637],
            [-1.816052974500542, 51.680633336695003],
            [-1.815704948949781, 51.679785821303071],
            [-1.817107102830734, 51.679618075851941],
            [-1.820389398989052, 51.677996624961594],
            [-1.820574134754498, 51.677361229021557],
            [-1.821682502583439, 51.676852214003915],
            [-1.821372128693717, 51.675972403631256],
            [-1.822350393323329, 51.675438908351012],
            [-1.822969782452039, 51.674586576786453],
            [-1.823182326585118, 51.673480978187719],
            [-1.823020295243819, 51.673126481017484],
            [-1.822106764702415, 51.673022603428244],
            [-1.821870827819647, 51.672710251534724],
            [-1.820975438377469, 51.67277632642228],
            [-1.821101280370422, 51.672032944962893],
            [-1.820408293923365, 51.671747766888501],
            [-1.820625917749133, 51.671566476490341],
            [-1.819908069857913, 51.670989043200507],
            [-1.818286406810844, 51.671153785941904],
            [-1.818456561787124, 51.670548940180623],
            [-1.817528249051805, 51.669459567243415],
            [-1.817882046677137, 51.669215554514452],
            [-1.816770909086764, 51.66793527837261],
            [-1.816550516338341, 51.664859938427007],
            [-1.815209857477826, 51.663526240091386],
            [-1.81622282057843, 51.662944296719736],
            [-1.819548768462681, 51.662778612729653],
            [-1.83448250239922, 51.657517328425584],
            [-1.840379444785696, 51.656190279569621],
            [-1.84645830081741, 51.656540935763083],
            [-1.850495195400975, 51.656281792447793],
            [-1.851156382903119, 51.657877674646052],
            [-1.853060859801055, 51.659930966893512],
            [-1.853145334644975, 51.660633286893102],
            [-1.853613330359337, 51.660761545398493],
            [-1.853297106449127, 51.661090230310272],
            [-1.855025576871735, 51.66218930151512],
            [-1.85735606688899, 51.662210132030886],
            [-1.86054319442413, 51.66481780877529],
            [-1.860736963497714, 51.666699000244463],
            [-1.860153130247128, 51.668480366023395],
            [-1.862862640562757, 51.669988682804231],
            [-1.864850431268168, 51.670631156114844],
            [-1.865501459834244, 51.673458736448936],
            [-1.866351861288937, 51.674413671577845],
            [-1.868756168316851, 51.675132975597919],
            [-1.869034415615312, 51.675432692433361],
            [-1.868716617200604, 51.675809069623853],
            [-1.872464107986098, 51.67875510867286],
            [-1.873893985515982, 51.682585996972776],
            [-1.878657449792918, 51.681871707984101],
            [-1.882263661223695, 51.680604914417145],
            [-1.88294279853551, 51.680854647510323],
            [-1.889077219053874, 51.678292701991666],
            [-1.893299159546823, 51.676935336423675],
            [-1.895060487519402, 51.677009747745707],
            [-1.899023000636884, 51.679682696620077],
            [-1.899766165973443, 51.677820356561782],
            [-1.89845237252006, 51.676791537761723],
            [-1.896848592087415, 51.674205175079756],
            [-1.896779481868896, 51.672795294441997],
            [-1.894711077328725, 51.670507899689184],
            [-1.893904940447499, 51.670189786650774],
            [-1.89191655909888, 51.668413114271026],
            [-1.891772645816594, 51.666909651965412],
            [-1.888471459192482, 51.665806047142681],
            [-1.887874265942024, 51.665239033559018],
            [-1.889203793737217, 51.664913911143834],
            [-1.88849062487536, 51.664474466654674],
            [-1.888771896413298, 51.664154645479115],
            [-1.888073337866181, 51.662483415583225],
            [-1.888615199948246, 51.662014587906313],
            [-1.887874062117498, 51.661798994310445],
            [-1.887956950303754, 51.661015938066242],
            [-1.887032744712717, 51.6605915684557],
            [-1.887362186083107, 51.660079384535557],
            [-1.886614924725502, 51.660015728687952],
            [-1.886720454112417, 51.659436795614205],
            [-1.882221799047654, 51.656542601347816],
            [-1.881673991045946, 51.656517775665527],
            [-1.88180210426464, 51.656169943888024],
            [-1.88078092313803, 51.655852422873537],
            [-1.880905806944181, 51.655635860693998],
            [-1.879140088730268, 51.654813161787096],
            [-1.878635759119404, 51.654212028704443],
            [-1.876336146174765, 51.653082134686755],
            [-1.876748075388958, 51.652558377889449],
            [-1.876629305705431, 51.651580906325314],
            [-1.877307581200178, 51.65197812875617],
            [-1.880767010317449, 51.652332342022405],
            [-1.881547021364766, 51.651423216666991],
            [-1.884276942881415, 51.649897422033355],
            [-1.886226746372282, 51.649314892245101],
            [-1.888042407842624, 51.649188957437353],
            [-1.888407449728282, 51.648855729109734],
            [-1.892286980981335, 51.65118807262337],
            [-1.895111489222035, 51.65236128125251],
            [-1.895753042640014, 51.653090140760177],
            [-1.903912280248025, 51.656024624956494],
            [-1.905594537120245, 51.655506292493619],
            [-1.908770021526488, 51.656412413002123],
            [-1.908915585726181, 51.655185223291049],
            [-1.914052150892871, 51.655635946791897],
            [-1.920914259659134, 51.65724029118941],
            [-1.921783117436915, 51.658050980202773],
            [-1.921712705565039, 51.658646152311391],
            [-1.926774210057414, 51.660248943450561],
            [-1.925580162774246, 51.661077185940862],
            [-1.92894199671873, 51.661658294622136],
            [-1.938928095374486, 51.667840850715393],
            [-1.938498232942821, 51.668152622246758],
            [-1.939063686152073, 51.669168022694492],
            [-1.941181243218525, 51.669785893898805],
            [-1.942068287770697, 51.670413019534216],
            [-1.942463564336175, 51.670005012334791],
            [-1.94423060609299, 51.669982483455151],
            [-1.947320270359545, 51.669110856912447],
            [-1.949498346747589, 51.667405277890715],
            [-1.952248057677618, 51.667813723335968],
            [-1.951839454520674, 51.667248909694784],
            [-1.952431242183549, 51.66546799121847],
            [-1.954805480404653, 51.66532596314466],
            [-1.954613053702389, 51.662544008518736],
            [-1.95863936874726, 51.661098799781598],
            [-1.957894686704287, 51.659677924592941],
            [-1.963065240809474, 51.658652855477776],
            [-1.962339670479789, 51.656724908926094],
            [-1.961183074040403, 51.656881881378474],
            [-1.950527562221083, 51.636959611153685],
            [-1.951353922471447, 51.637102016311495],
            [-1.952267418155794, 51.636829954135067],
            [-1.952289896513495, 51.63744406579552],
            [-1.954349972125643, 51.637890846133047],
            [-1.957154705540917, 51.637862225461056],
            [-1.958629332318561, 51.638633301102153],
            [-1.959926729645005, 51.638880108319732],
            [-1.964691039946637, 51.638741363186561],
            [-1.968749237201165, 51.639956322714148],
            [-1.970950216952219, 51.639679952371708],
            [-1.971889811366524, 51.639156888227099],
            [-1.974446204158081, 51.638884132564513],
            [-1.974582320752788, 51.638368064038609],
            [-1.975184799745423, 51.638523740167088],
            [-1.97580923330551, 51.6381561268987],
            [-1.976437706527752, 51.638345069525144],
            [-1.977335174765817, 51.638088094368968],
            [-1.976485725011213, 51.643344212208724],
            [-1.980106028317469, 51.642895310954287],
            [-1.983013432603658, 51.643481989958019],
            [-1.984785295106931, 51.643181022244086],
            [-1.985783677026578, 51.643883361127102],
            [-1.986521653734774, 51.645622352401901],
            [-1.98761258992248, 51.646407405206681],
            [-1.991271767646395, 51.647467792763408],
            [-1.991199202693134, 51.649069126485884],
            [-1.992192026249345, 51.649803778208216],
            [-1.992884399617602, 51.649495421343886],
            [-1.993734241818897, 51.649669897762038],
            [-1.99517654057341, 51.651316257117251],
            [-1.997476188166743, 51.651212924708318],
            [-2.002180860811816, 51.649052327858044],
            [-2.005629432090008, 51.649068389629065],
            [-2.006339125373564, 51.64929942657195],
            [-2.010611457954425, 51.648878318878381],
            [-2.012905707669463, 51.650644861255287],
            [-2.014508659243125, 51.650703113562145],
            [-2.014978279266785, 51.650312834092951],
            [-2.019662141339257, 51.652244348229502],
            [-2.020803157531561, 51.65346695612358],
            [-2.021053999206573, 51.655117699944491],
            [-2.024444754047558, 51.654283548937173],
            [-2.025329928477771, 51.655220245051034],
            [-2.027637655669165, 51.656294170722987],
            [-2.028003668925032, 51.656752635554014],
            [-2.031607101662436, 51.658246053958301],
            [-2.033424530330122, 51.658616884100191],
            [-2.033667274116339, 51.65845137578161],
            [-2.035894290544765, 51.659381302464404],
            [-2.040934727582005, 51.661916963891862],
            [-2.046208080220455, 51.665450340386762],
            [-2.046100134763618, 51.665934110085267],
            [-2.047361711599645, 51.666674480873354],
            [-2.047093042280252, 51.66693083890808],
            [-2.053406251019513, 51.669710002776675],
            [-2.054682196225421, 51.671384473731457],
            [-2.057180852092532, 51.672439744331385],
            [-2.073568321519159, 51.659403223612784],
            [-2.106635810549844, 51.632090260141084],
            [-2.107319417410385, 51.631571739019321],
            [-2.107758811081529, 51.631647761382816],
            [-2.108102371500083, 51.630916454991798],
            [-2.113620100053954, 51.626377835291279],
            [-2.117489101258245, 51.622595884889179],
            [-2.120745271015286, 51.620528176189985],
            [-2.131083158848185, 51.611058229150167],
            [-2.152542685348944, 51.590340545507971],
            [-2.15439415332127, 51.591466532814906],
            [-2.15646006201799, 51.591958315946997],
            [-2.158067698445187, 51.593490079241271],
            [-2.162200705636631, 51.593506910320201],
            [-2.167724813025462, 51.59456011173922],
            [-2.167893744603576, 51.594959084764454],
            [-2.170929830456522, 51.595768411419037],
            [-2.174722364169252, 51.596164731698664],
            [-2.174996445779444, 51.59648441398982],
            [-2.175615886628738, 51.59651225980317],
            [-2.175899515202254, 51.597058507029637],
            [-2.176488127134983, 51.596952423775967],
            [-2.1766865562068, 51.597489805992659],
            [-2.177419309042634, 51.597324157988872],
            [-2.179495974594787, 51.60004446775141],
            [-2.182780181516577, 51.599228375831473],
            [-2.181067155820479, 51.596506663426354],
            [-2.183748320229649, 51.595859611522961],
            [-2.183323091228505, 51.594945859335866],
            [-2.190017046045633, 51.592502145816553],
            [-2.192075750857431, 51.594261990761105],
            [-2.192447541901641, 51.59581238521168],
            [-2.194279699958902, 51.595514447667306],
            [-2.200626020367144, 51.596189797227545],
            [-2.206556917059625, 51.595948420869846],
            [-2.205797582803783, 51.600739435190604],
            [-2.207431109735236, 51.603399785475212],
            [-2.210817867436743, 51.60295856517299],
            [-2.212738780549502, 51.60122696388683],
            [-2.217958473237922, 51.59917859461688],
            [-2.222948919249422, 51.596109013225238],
            [-2.226052582075363, 51.595197643644717],
            [-2.230634013718282, 51.595070935300562],
            [-2.236107708373349, 51.593857000714578],
            [-2.241871298169446, 51.592106328989942],
            [-2.241119466708294, 51.589701794348848],
            [-2.241557870857892, 51.589625364741195],
            [-2.242687916858144, 51.587968623828374],
            [-2.244660643591776, 51.583599223795666],
            [-2.244998217525191, 51.583571544867901],
            [-2.245120791944053, 51.581408867756309],
            [-2.250627200461485, 51.582048201394748],
            [-2.25108979819055, 51.583495719104526],
            [-2.251845946886762, 51.583213567197504],
            [-2.256799774274409, 51.582808102184927],
            [-2.264271004489717, 51.582987513764955],
            [-2.267114258158871, 51.582507226958718],
            [-2.267457534613373, 51.582716842294104],
            [-2.269006093810734, 51.581735040165277],
            [-2.269591275009416, 51.581850583957369],
            [-2.271310618128963, 51.578542292658],
            [-2.272563299632423, 51.57758989929718],
            [-2.274477560362916, 51.578910761541863],
            [-2.274333200622781, 51.579142177445476],
            [-2.276565956774164, 51.579895803918298],
            [-2.27867312342272, 51.577803020023588],
            [-2.282749918379788, 51.578516166787139],
            [-2.28128189739864, 51.579621139684704],
            [-2.282053449873537, 51.58070094764058],
            [-2.280720628219689, 51.581043120212676],
            [-2.28089258554096, 51.581312449337474],
            [-2.282076144332763, 51.582267188457955],
            [-2.284415160127032, 51.58312021392922],
            [-2.284936929641759, 51.583241229829767],
            [-2.285288869797814, 51.582746748456529],
            [-2.285759912140728, 51.583063895774167],
            [-2.287281675718064, 51.583156385311568],
            [-2.286555338669529, 51.584470900949491],
            [-2.284597676383864, 51.584150180983222],
            [-2.283507290451501, 51.584962943487888],
            [-2.286012121219706, 51.585533207302596],
            [-2.28767568536724, 51.586582026206081],
            [-2.29053161844779, 51.587412980213827],
            [-2.291009175520906, 51.58692985951793],
            [-2.292110474860854, 51.587381185065027],
            [-2.291877266092507, 51.587702757085189],
            [-2.293428904660147, 51.587701582556804],
            [-2.293032947368798, 51.588301396798869],
            [-2.294089835747182, 51.588572987596585],
            [-2.292704438549443, 51.58996381918157],
            [-2.290451824491562, 51.590967459491388],
            [-2.289304440866319, 51.592360357924044],
            [-2.290233843586859, 51.592549577329642],
            [-2.289858355537045, 51.593203277709591],
            [-2.291641425419677, 51.593695179682804],
            [-2.290129204572477, 51.596514119884503],
            [-2.28760029195221, 51.599317553837139],
            [-2.287218746500012, 51.600169968291794],
            [-2.287511221219114, 51.601214943877814],
            [-2.286716198913854, 51.603130245440234],
            [-2.285185631307152, 51.604733534327565],
            [-2.280826507027643, 51.611531600959616],
            [-2.27869540040376, 51.613019351645285],
            [-2.274328945495156, 51.614586041110186],
            [-2.275797245437239, 51.61545655328058],
            [-2.275876121718021, 51.616792476234103],
            [-2.276856711715314, 51.619147686278509],
            [-2.278301869332499, 51.620216929027102],
            [-2.278078225872639, 51.620492593255847],
            [-2.27969293192214, 51.6209347229575],
            [-2.279331923056594, 51.621186440472741],
            [-2.282216934245482, 51.622187459978903],
            [-2.286316148075975, 51.627882504278183],
            [-2.287172056284684, 51.628445065376802],
            [-2.287684488851265, 51.628148895523012],
            [-2.288244869618242, 51.628352521460009],
            [-2.289179710087092, 51.629508299132233],
            [-2.290251058261856, 51.630313970392379],
            [-2.290776844554586, 51.630298282333683],
            [-2.290868483432575, 51.630620842446646],
            [-2.292288287799749, 51.630335886392068],
            [-2.293764441650219, 51.632512586803387],
            [-2.299674924927879, 51.631598509855593],
            [-2.30853016210117, 51.628003316393439],
            [-2.308177461028875, 51.627760579254918],
            [-2.309100591748647, 51.627546855303507],
            [-2.310331260107221, 51.626462852470965],
            [-2.312253410568921, 51.626159241244963],
            [-2.314343343644663, 51.625054024150984],
            [-2.315019788306895, 51.625526051373939],
            [-2.31644535512338, 51.625490745393591],
            [-2.317334606658657, 51.626436925769532],
            [-2.320296638922855, 51.62754110057643],
            [-2.321572755246819, 51.630263771226247],
            [-2.322942973504436, 51.631568241109939],
            [-2.32267372248042, 51.632512168504832],
            [-2.323600588275269, 51.632818916306242],
            [-2.323055406978445, 51.634783217283896],
            [-2.32220104650161, 51.63553723816068],
            [-2.317799216479851, 51.63709934072692],
            [-2.316264595819667, 51.636673703838113],
            [-2.314262030905287, 51.637945956322923],
            [-2.311415934933747, 51.638866159685321],
            [-2.310677237799108, 51.639668341792031],
            [-2.309275681921551, 51.640324811228638],
            [-2.307528694032566, 51.642472923067075],
            [-2.307300159845145, 51.642870036970152],
            [-2.308589018708807, 51.642626587420594],
            [-2.309753392617, 51.642779068447297],
            [-2.3102220191795, 51.643684150733009],
            [-2.312262452165646, 51.644083345719238],
            [-2.312606550208726, 51.644734296705586],
            [-2.315364467309128, 51.645842733657993],
            [-2.31290098476325, 51.647824710187834],
            [-2.311094931770304, 51.648330325728502],
            [-2.305989207143958, 51.651204770449951],
            [-2.302847728478085, 51.653714288041655],
            [-2.301474671226146, 51.653302427613554],
            [-2.299415739303252, 51.653215089303693],
            [-2.298365979105335, 51.654481933567318],
            [-2.296925239047601, 51.654548526784652],
            [-2.297021137054118, 51.654844995435262],
            [-2.295576497679441, 51.654980814603149],
            [-2.295847068153693, 51.655463862110878],
            [-2.292855145470513, 51.656190667185214],
            [-2.293320242485232, 51.657027489967007],
            [-2.293124672748361, 51.657858769970311],
            [-2.292812450017371, 51.658311809191275],
            [-2.291806727578576, 51.658155169374155],
            [-2.291728195395103, 51.65965690263279],
            [-2.292487566248917, 51.659493169968442],
            [-2.292186788376223, 51.660154775713366],
            [-2.29170329809451, 51.660281855973267],
            [-2.29155071885043, 51.659935173150437],
            [-2.290056106735112, 51.661328023971912],
            [-2.289408494878487, 51.662246729462083],
            [-2.289543612542901, 51.663257011440145],
            [-2.28745025423434, 51.663508517728502],
            [-2.287293716936339, 51.665045503370358],
            [-2.285829312796428, 51.665542698099067],
            [-2.28344643376948, 51.665758872702469],
            [-2.283364839425704, 51.666816438713234],
            [-2.284302959746487, 51.667003884714745],
            [-2.289700287246024, 51.669455181260162],
            [-2.286739011649521, 51.669929997831566],
            [-2.286493174505075, 51.669469348240511],
            [-2.283852067371344, 51.670045809944916],
            [-2.284505064200935, 51.671564644995755],
            [-2.283889014157688, 51.672021991924247],
            [-2.282637184497457, 51.672096943243368],
            [-2.281800822486961, 51.673640949141252],
            [-2.283029449622966, 51.674244000806908],
            [-2.284909321750767, 51.673305264253848],
            [-2.285478987996432, 51.674208396791592],
            [-2.286147491229035, 51.674269707294449],
            [-2.286014940741248, 51.674577530148945],
            [-2.286448281434246, 51.674726626649374],
            [-2.285794551364417, 51.677470541909294],
            [-2.286856143569846, 51.678393148057935],
            [-2.287166809878832, 51.680632998609305],
            [-2.285691744344445, 51.67928791665603],
            [-2.284323847993622, 51.679567268676756],
            [-2.285167890093798, 51.680391513269896],
            [-2.285365201523614, 51.681407939600646],
            [-2.280258456481771, 51.681938138720852],
            [-2.280252524695238, 51.681446333785757],
            [-2.276957580775319, 51.680480407554676],
            [-2.276387310068394, 51.681361991271913],
            [-2.276157234609127, 51.680632446717226],
            [-2.271532288827564, 51.682883847025678],
            [-2.268439940453801, 51.681432587269626],
            [-2.267793006619207, 51.680383892201434],
            [-2.265361014451666, 51.678286372530287],
            [-2.258797522686407, 51.677215791184089],
            [-2.252127957826978, 51.676779850671998],
            [-2.249327033181608, 51.676327283428222],
            [-2.247053677220792, 51.67634198198617],
            [-2.24675366353812, 51.674361849107527],
            [-2.244291959427505, 51.673756496436624],
            [-2.242533536191164, 51.674038871763095],
            [-2.243263523258627, 51.672093459867128],
            [-2.242443632292645, 51.67074647408181],
            [-2.240347532988951, 51.668957037960453],
            [-2.24011397682957, 51.668541222578227],
            [-2.240457658379257, 51.668455102149764],
            [-2.238486685275917, 51.667328928107999],
            [-2.239396370115426, 51.666819071409549],
            [-2.237888708561178, 51.666622532685025],
            [-2.237576979813066, 51.665353603249791],
            [-2.235746874085003, 51.66514240440825],
            [-2.235796037304873, 51.663195704726824],
            [-2.234090339426479, 51.662979734359176],
            [-2.234058441997584, 51.662401661899679],
            [-2.233606618893779, 51.662256904465728],
            [-2.232334111791071, 51.66220997951941],
            [-2.232726514644359, 51.660918060924956],
            [-2.226342738972703, 51.66014921252787],
            [-2.219527278936874, 51.659752163740414],
            [-2.214815669676601, 51.660637531076482],
            [-2.212156224237782, 51.660777239297353],
            [-2.202628664692969, 51.659732219429358],
            [-2.202532836540648, 51.659963459541039],
            [-2.197278016379374, 51.659686493851851],
            [-2.197025788279949, 51.660528496836498],
            [-2.194174862748457, 51.659852614938906],
            [-2.192112925689734, 51.659758904979654],
            [-2.191520405758203, 51.661862922037749],
            [-2.18877350005494, 51.664991825080918],
            [-2.187896764467268, 51.667301278648587],
            [-2.187772174160684, 51.669687746637926],
            [-2.188135531272267, 51.670135825940115],
            [-2.187554894492917, 51.670295001116223],
            [-2.188589124112, 51.670722223709653],
            [-2.190653463542553, 51.672646602186049],
            [-2.194703091201345, 51.672108570079416],
            [-2.196373038160236, 51.672715390530101],
            [-2.198144526839092, 51.674045808010959],
            [-2.199767875268126, 51.674224677298433],
            [-2.200588216624176, 51.674637772178521],
            [-2.202306270211449, 51.677236876873756],
            [-2.20191358176535, 51.679015114005608],
            [-2.204636794871888, 51.681545014655399],
            [-2.206088217611215, 51.68201990668004],
            [-2.210007284899934, 51.681881685822063],
            [-2.211848764842811, 51.682241619681818],
            [-2.209373047796408, 51.68360463333569],
            [-2.20672440701138, 51.685778005551754],
            [-2.205015237909351, 51.688789456103066],
            [-2.201859656220408, 51.689317315357542],
            [-2.201385004142912, 51.689932228891074],
            [-2.200446544452987, 51.690024646861929],
            [-2.199889721564152, 51.691367081229423],
            [-2.200629692704508, 51.692835876230042],
            [-2.196522582192226, 51.692538925433233],
            [-2.195572141652947, 51.691546089065504],
            [-2.193594429426687, 51.691187024476015],
            [-2.193321789156808, 51.690692059950656],
            [-2.189254411609709, 51.691537568694898],
            [-2.18791795554256, 51.690559678295678],
            [-2.187476562654937, 51.688422281223829],
            [-2.182734768255798, 51.688812787462226],
            [-2.180691750069142, 51.687620124571609],
            [-2.177149338754103, 51.687677676359527],
            [-2.175182449188076, 51.690785285194821],
            [-2.172525818546702, 51.690639968867025],
            [-2.171849869538348, 51.690926880558123],
            [-2.171457643903337, 51.690879801260223],
            [-2.171504822503165, 51.690349252722015],
            [-2.168306086723345, 51.690298140587537],
            [-2.168278988657028, 51.690013159074724],
            [-2.16667840281177, 51.690242921210718],
            [-2.166552405378964, 51.689811523564579],
            [-2.164134947919294, 51.690573786764922],
            [-2.160390034775486, 51.687032845713702],
            [-2.158990426119923, 51.686392780793653],
            [-2.154299538001707, 51.688573113869467],
            [-2.154023983290468, 51.688366678918094],
            [-2.152864183395925, 51.68890317156611],
            [-2.152096723207109, 51.689969624080561],
            [-2.149897184880247, 51.689336779670043],
            [-2.150283367709985, 51.68975347612659],
            [-2.149399411453953, 51.690172695300824],
            [-2.14971192196476, 51.691058826824673],
            [-2.149230478304918, 51.692027790023189],
            [-2.146176152356067, 51.694166141193492],
            [-2.140902736752365, 51.698839011454396],
            [-2.140778372841177, 51.699327380966047],
            [-2.135919743139749, 51.698906931831559],
            [-2.12991176615077, 51.697271955456081],
            [-2.129128675922484, 51.700706545334896],
            [-2.125854824265129, 51.703028025506732],
            [-2.124068565636493, 51.704923466322697],
            [-2.123733365538045, 51.706176287970735],
            [-2.125124735391887, 51.707459646742016],
            [-2.129350047658944, 51.709309935626216],
            [-2.131095199554508, 51.708728068669551],
            [-2.133232385472343, 51.708597985725049],
            [-2.133091211744981, 51.711773818556871],
            [-2.132333160871477, 51.711894258607934],
            [-2.133096900253113, 51.714692336349309],
            [-2.132513933929867, 51.714810780423917],
            [-2.133547465382698, 51.717775783802551],
            [-2.134059561692641, 51.718153726276476],
            [-2.133463361145116, 51.718216445075534],
            [-2.133295276251215, 51.718650008826934],
            [-2.134040755359449, 51.722066687600659],
            [-2.134475562158466, 51.723211659357894],
            [-2.130415275410045, 51.72389057888337],
            [-2.128157802549193, 51.724744526861855],
            [-2.126451337493537, 51.724898329224821],
            [-2.124764952624126, 51.72446316693398],
            [-2.120294324358957, 51.725003709106943],
            [-2.117730987957394, 51.724693419754324],
            [-2.116745931703707, 51.725026177055973],
            [-2.115821940410181, 51.724900318603872],
            [-2.112363043581855, 51.725396397837862],
            [-2.110656236068395, 51.726033693358616],
            [-2.108086430333572, 51.725436383084713],
            [-2.104926472650591, 51.725662233783197],
            [-2.102464064301237, 51.725138431565163],
            [-2.098518402104309, 51.726230638890932],
            [-2.098377391165459, 51.726631761185985],
            [-2.097348764438934, 51.726986870081753],
            [-2.091640948025123, 51.728162115634845],
            [-2.089282824479366, 51.729115195927406],
            [-2.088467761955991, 51.729165265261734],
            [-2.084732041166021, 51.728303980423448],
            [-2.083850938922169, 51.728651671356069],
            [-2.083260219746192, 51.729444209712476],
            [-2.081578117031105, 51.729640500283942],
            [-2.083316131422563, 51.731497739637128],
            [-2.083007612575685, 51.732229834872825],
            [-2.083797867049797, 51.732043156351793],
            [-2.084492074527325, 51.732377126979088],
            [-2.083827484336284, 51.733177812916772],
            [-2.084086685296044, 51.734736684834907],
            [-2.083523923518101, 51.73580972600535],
            [-2.080401087573776, 51.734586423450303],
            [-2.077300384548416, 51.736043271154557],
            [-2.074850279860187, 51.734353636114086],
            [-2.073975221290459, 51.734972780060197],
            [-2.072387286498259, 51.735348701375159],
            [-2.071888318596001, 51.735739222595782],
            [-2.072245693059864, 51.736452896111629],
            [-2.070410185536296, 51.737836845331088],
            [-2.068690595332711, 51.738382727330496],
            [-2.068800309636417, 51.739104648017474],
            [-2.072593878630522, 51.739550114227328],
            [-2.073456497856116, 51.739230392406668],
            [-2.079956156021148, 51.739280981573543],
            [-2.080262214848695, 51.743627966028818],
            [-2.083499702585182, 51.744639898307845],
            [-2.088594736006543, 51.74518461331094],
            [-2.089639718402837, 51.746989228926758],
            [-2.093390525077059, 51.745949627904309],
            [-2.096258360100988, 51.744568074856936],
            [-2.098206931681778, 51.742798810013547],
            [-2.098630899059862, 51.741955988618969],
            [-2.10064156947942, 51.741500229372086],
            [-2.100489322485978, 51.740769383936019],
            [-2.101871401658043, 51.740964196947104],
            [-2.103631023048964, 51.742848986275099],
            [-2.105991671230345, 51.744031002728249],
            [-2.106361589240795, 51.746740581987183],
            [-2.107168817969363, 51.747537356314403],
            [-2.106175190998809, 51.750014404784281],
            [-2.106794630538184, 51.7503977614208],
            [-2.107193884327313, 51.75199691027197],
            [-2.105637825416898, 51.75375158260352],
            [-2.105598521774746, 51.754290183443764],
            [-2.106714327143376, 51.755648623708971],
            [-2.105936969751213, 51.757763129530026],
            [-2.104569760409746, 51.758587938719131],
            [-2.103681304125286, 51.759709014729424],
            [-2.103761235392947, 51.762337032277301],
            [-2.102927892742649, 51.764157557567088],
            [-2.103682828146319, 51.766051308501176],
            [-2.102658217082882, 51.767273195417701],
            [-2.102394082271519, 51.767098100787777],
            [-2.100823189307843, 51.766419738228777],
            [-2.099109761403879, 51.76613977901134],
            [-2.092980104686497, 51.766098946242245],
            [-2.090347666710271, 51.765636167098826],
            [-2.083908449003844, 51.766621878117526],
            [-2.07905315329467, 51.767787797486108],
            [-2.073646089455697, 51.768441374295875],
            [-2.073128213486341, 51.769900946245436],
            [-2.075630456780992, 51.770460398986522],
            [-2.076470835874146, 51.770364548351218],
            [-2.076953993421828, 51.770699598112415],
            [-2.076778068201971, 51.772067251830478],
            [-2.075946750437077, 51.773259107168485],
            [-2.076362496650177, 51.773995203424597],
            [-2.07776238947453, 51.774746832968702],
            [-2.078298430243676, 51.774621500504821],
            [-2.079226766903454, 51.775052446521052],
            [-2.080018417176622, 51.776039124914767],
            [-2.079490127321205, 51.777279348796569],
            [-2.08077617611956, 51.77831513601469],
            [-2.082760735349086, 51.778449516001601],
            [-2.083131051384485, 51.78040300588178],
            [-2.082516286045853, 51.781104740916241],
            [-2.08098971798502, 51.781805308102456],
            [-2.082829267549406, 51.782616811640963],
            [-2.08354581488491, 51.783639482229276],
            [-2.083187554367336, 51.78510167815292],
            [-2.083637621151166, 51.786242319016758],
            [-2.082957571102753, 51.786957588740194],
            [-2.084051361552036, 51.788104064002383],
            [-2.083816609861448, 51.788943993493866],
            [-2.088843598437185, 51.790681851850934],
            [-2.092486249010411, 51.79239182373265],
            [-2.098310836490318, 51.79318908371679],
            [-2.098806182594821, 51.79426938732248],
            [-2.100017218632763, 51.7950622671977],
            [-2.101285496239807, 51.795498141937131],
            [-2.104761962520494, 51.795836746732242],
            [-2.10560278806791, 51.796994933990469],
            [-2.106966240735404, 51.797161830452168],
            [-2.108830062226099, 51.796762713443265],
            [-2.110592551411881, 51.798811914187752],
            [-2.112425680998766, 51.799465616174238],
            [-2.114527300843393, 51.79903920789512],
            [-2.115642055328899, 51.799451700731879],
            [-2.117430192034711, 51.800609764404271],
            [-2.119508319103375, 51.803355316652514],
            [-2.11940641419143, 51.804304870006725],
            [-2.118844490224339, 51.804604840758437],
            [-2.118237320006897, 51.805904653338878],
            [-2.117598946162375, 51.809156438394162],
            [-2.121893379443877, 51.809562944393456],
            [-2.123700938233842, 51.810194018728069],
            [-2.128829913223957, 51.815800674431436],
            [-2.132692467806645, 51.816594770508019],
            [-2.134929565557286, 51.817609100185507],
            [-2.13543680234509, 51.818886134175401],
            [-2.134413829445839, 51.819310785077455],
            [-2.134260388117643, 51.819912457043657],
            [-2.131976674303804, 51.819362101859923],
            [-2.121615622403922, 51.819513554326392],
            [-2.120341064289293, 51.819768413725654],
            [-2.117429854385492, 51.82108494430171],
            [-2.114901028475616, 51.822783144929026],
            [-2.113950586929139, 51.823887263694175],
            [-2.113612898726982, 51.825187685068251],
            [-2.114179264085387, 51.825953165684311],
            [-2.114106196760548, 51.826894590782892],
            [-2.111583691138959, 51.827225186696964],
            [-2.110589234236612, 51.828203445777881],
            [-2.111152966093178, 51.828495119394915],
            [-2.111140896784416, 51.830661053056069],
            [-2.1116196503753, 51.831782670123609],
            [-2.110299062163428, 51.831775826967629],
            [-2.111571441156902, 51.833410977963858],
            [-2.113142813913986, 51.83444343310456],
            [-2.110245984127518, 51.833806034451506],
            [-2.107840046956238, 51.833899078816529],
            [-2.105873969590186, 51.834669595690045],
            [-2.101307052413592, 51.837352031608177],
            [-2.100160624172197, 51.840026023899483],
            [-2.100281510770639, 51.840864775561826],
            [-2.100831020945293, 51.841246419612922],
            [-2.100167145905094, 51.841652479700102],
            [-2.100474508346009, 51.842144021316962],
            [-2.098739353175127, 51.843193837900053],
            [-2.09953348551542, 51.84325610344721],
            [-2.104729111044248, 51.842240999460309],
            [-2.107536152036341, 51.842770724259417],
            [-2.102895594892399, 51.84530493491684],
            [-2.103556122944961, 51.845955297505796],
            [-2.101753276702188, 51.847275844612057],
            [-2.101133669041107, 51.847390565130084],
            [-2.098532548499532, 51.850174571901576],
            [-2.096888487465571, 51.851217090354609],
            [-2.097821709496411, 51.854424277391701],
            [-2.098563144636744, 51.855526842785792],
            [-2.098417290939077, 51.856552828990431],
            [-2.096571045975401, 51.856186634531952],
            [-2.094673733934482, 51.856377889220944],
            [-2.09196383613722, 51.856121106322504],
            [-2.091135875829633, 51.856668400229218],
            [-2.089297915963136, 51.856791191106829],
            [-2.087463224956543, 51.856360110359773],
            [-2.083928789203657, 51.854618454602672],
            [-2.082144930884289, 51.854914619808191],
            [-2.080109299297941, 51.855708127987356],
            [-2.080011509381278, 51.856237759782829],
            [-2.080494096699068, 51.856536827040131],
            [-2.079985096058576, 51.856897711066608],
            [-2.07461500397491, 51.858254384056508],
            [-2.074503907258475, 51.858682422179356],
            [-2.07193329411154, 51.860185509945531],
            [-2.070681330661592, 51.861865773355731],
            [-2.070296159394635, 51.864424816774509],
            [-2.070764713513694, 51.865019732654915],
            [-2.068364056776205, 51.864958221073813],
            [-2.065881332020116, 51.864386023369164],
            [-2.061953480812786, 51.864690260316671],
            [-2.059372522212421, 51.864436255157528],
            [-2.056175729031897, 51.862923763755198],
            [-2.052025982399579, 51.859666477071357],
            [-2.051095562878277, 51.859982467855282],
            [-2.050255429975985, 51.859242879668663],
            [-2.048124312488167, 51.858361767023247],
            [-2.047261776600673, 51.858334246779826],
            [-2.041838077812241, 51.859500635691987],
            [-2.038531298492621, 51.8607011598627],
            [-2.036998676046257, 51.861672673276175],
            [-2.036550629811971, 51.864265791301349],
            [-2.03173646250294, 51.864192574655895],
            [-2.028043974124538, 51.864998206751288],
            [-2.026955823536573, 51.864304365880621],
            [-2.025259611462717, 51.864276874273919],
            [-2.025354834331933, 51.865735177810095],
            [-2.023925817999318, 51.868473208818912],
            [-2.024801288015173, 51.870543628604381],
            [-2.023286158828014, 51.872843811589078],
            [-2.022112658380862, 51.876007933747779],
            [-2.021117743449588, 51.876252670797626],
            [-2.015913380767156, 51.881223660035893],
            [-2.015338573611263, 51.882636204178596],
            [-2.015610593365441, 51.883611677576475],
            [-2.014898964612841, 51.884400270916359],
            [-2.015844693917456, 51.884150199018727],
            [-2.016711084185157, 51.885435771779903],
            [-2.013016740646695, 51.891692090685844],
            [-2.011852377659294, 51.895513330722039],
            [-2.012615770876576, 51.897080355373184],
            [-2.01267865793048, 51.898458645931512],
            [-2.010223578587406, 51.902916552009678],
            [-2.009677724580538, 51.905860200661159],
            [-2.000428677171915, 51.904066945989207],
            [-1.998072423209565, 51.907386353934797],
            [-1.996304477727492, 51.912022888046494],
            [-1.994930525629987, 51.913402932074632],
            [-1.994177260621098, 51.914969100754689],
            [-1.986771033512233, 51.910893874957615],
            [-1.981173546960035, 51.908930426654734],
            [-1.981151969125576, 51.908380184190293],
            [-1.976046424149074, 51.906276310758685],
            [-1.972546477986144, 51.905836801409045],
            [-1.965763873879831, 51.904285917481985],
            [-1.962176403624458, 51.902786053927279],
            [-1.960589513306708, 51.90245017835889],
            [-1.95756648992812, 51.902281005311721],
            [-1.953305842391672, 51.902856613903978],
            [-1.952799772227529, 51.903109954974624],
            [-1.953217348271268, 51.904101810697902],
            [-1.952805050538216, 51.905018712829808],
            [-1.945727260389555, 51.90545622913276],
            [-1.942484629176336, 51.905268586178288],
            [-1.942367405318372, 51.904865739434719],
            [-1.939551778593547, 51.904968627173218],
            [-1.940090687666945, 51.906331912835846],
            [-1.938871828075744, 51.906895014458414],
            [-1.93576934983924, 51.906226249070798],
            [-1.933086248135551, 51.905110795245278],
            [-1.929416724414169, 51.904686989691513],
            [-1.929875799209716, 51.903904160682067],
            [-1.927250545929479, 51.903145544174009],
            [-1.926684953518162, 51.903262974558331],
            [-1.926744812802559, 51.901304805496125],
            [-1.927421388227675, 51.900845790821094],
            [-1.928348660337815, 51.89901042546856],
            [-1.927326761480946, 51.896422233799953],
            [-1.92167161664154, 51.894204170697741],
            [-1.914954328126108, 51.889148456815704],
            [-1.912168998333166, 51.887592795323251],
            [-1.907528232898213, 51.887576663496567],
            [-1.905994441406325, 51.888011508904434],
            [-1.903145517872551, 51.887154173420448],
            [-1.902864565618177, 51.88740119141881],
            [-1.901676902963491, 51.887003710425702],
            [-1.900223230668451, 51.889302354462814],
            [-1.899421612507471, 51.889098480300497],
            [-1.898305276314864, 51.88992827956077],
            [-1.897702564107262, 51.891077690254441],
            [-1.896647524723897, 51.891120826062149],
            [-1.895363665678042, 51.893340437626158],
            [-1.896025568397979, 51.894292255806988],
            [-1.882186896277027, 51.894270256598176],
            [-1.883423793835, 51.892491294811876],
            [-1.883509425168359, 51.891968111134609],
            [-1.882895908481855, 51.891524254015494],
            [-1.880495699814933, 51.890856522572356],
            [-1.874790513409605, 51.889951517099085],
            [-1.872611608677987, 51.890228799626072],
            [-1.873395388931534, 51.893648869638696],
            [-1.869074531836346, 51.89184597904427],
            [-1.862059613423328, 51.88971343973077],
            [-1.860421874451904, 51.890705003421694],
            [-1.858665450588518, 51.892451634737817],
            [-1.855836157830883, 51.893376066618849],
            [-1.855230950511052, 51.893138865334343],
            [-1.853618485499059, 51.893421884841452],
            [-1.854863543227374, 51.895775433712771],
            [-1.85703878927943, 51.896772480307646],
            [-1.862127525730356, 51.897450157219332],
            [-1.866818910368845, 51.896904409327902],
            [-1.869008544594305, 51.897059706396909],
            [-1.872977182442491, 51.896638780050473],
            [-1.878534218147071, 51.897273094822822],
            [-1.878547431157134, 51.896683308214847],
            [-1.880744509002487, 51.896782651284994],
            [-1.880423135906196, 51.897955632660171],
            [-1.877139513274534, 51.900845514687724],
            [-1.871213889223144, 51.903938338988368],
            [-1.868414055945819, 51.908059348535538],
            [-1.871738890113642, 51.908951306013137],
            [-1.877211290505974, 51.909187295114926],
            [-1.883249711681217, 51.91092236367961],
            [-1.883662935288395, 51.910780716692074],
            [-1.887870184051899, 51.91187987390898],
            [-1.886669789899257, 51.914611040615789],
            [-1.886799754300212, 51.91728054195525],
            [-1.890471400790297, 51.917686796230853],
            [-1.890340769766732, 51.918192858285835],
            [-1.891412927730447, 51.91855258361835],
            [-1.891986203226819, 51.919596045100455],
            [-1.890832044398586, 51.921265477355547],
            [-1.890922241279019, 51.922449651461406],
            [-1.887215128990261, 51.926184547007416],
            [-1.889301969468192, 51.926762834090113],
            [-1.891374432941239, 51.927898501020408],
            [-1.894495085452413, 51.9286152022176],
            [-1.896618605188913, 51.929777794438621],
            [-1.9063389668009, 51.931380875698522],
            [-1.906421957996124, 51.932038169103578],
            [-1.907244619734166, 51.932297753343086],
            [-1.916296031654559, 51.934340932234043],
            [-1.918363166263935, 51.93577551195893],
            [-1.923196636148911, 51.934880573002154],
            [-1.924157638913973, 51.940326017196384],
            [-1.92696113770554, 51.944612791048243],
            [-1.928481731713642, 51.949924572746767],
            [-1.931050227386711, 51.954396309296683],
            [-1.931670058088711, 51.957315970004267],
            [-1.928384308700607, 51.959053736927636],
            [-1.927622449112713, 51.960426161487391],
            [-1.92611015378177, 51.961442979210865],
            [-1.922860539118382, 51.96302507347319],
            [-1.920569193728885, 51.963324745591081],
            [-1.916642127715329, 51.962721457816755],
            [-1.915601327863351, 51.963599115358733],
            [-1.915344514044935, 51.965499576275164],
            [-1.914731149148565, 51.965818306720173],
            [-1.914667092034465, 51.966595061099532],
            [-1.913576718573874, 51.968209006449669],
            [-1.913086723920764, 51.968686055492547],
            [-1.910939813919198, 51.969375844516094],
            [-1.910286349820306, 51.968590458041398],
            [-1.908927761417117, 51.968128193074797],
            [-1.906527019345577, 51.968367269412191],
            [-1.904087233182527, 51.969157397746535],
            [-1.903130210891533, 51.968122680907094],
            [-1.900627331682368, 51.967139710699669],
            [-1.901281412151019, 51.966228597476139],
            [-1.901581062430151, 51.964314716869829],
            [-1.902826850665658, 51.962326994024259],
            [-1.899616692047193, 51.961480975659562],
            [-1.89707974647592, 51.960316286509823],
            [-1.892706704421856, 51.960229678141076],
            [-1.890793621255018, 51.960556086515759],
            [-1.881983666100151, 51.965317202305847],
            [-1.879982287361863, 51.964256972539729],
            [-1.877295043539512, 51.964433125203009],
            [-1.874508925072128, 51.965089217655716],
            [-1.87423685843364, 51.965571731426806],
            [-1.876368000108492, 51.968113870056456],
            [-1.875234617482735, 51.970030403520781],
            [-1.875421235645854, 51.971501486340685],
            [-1.873024085331591, 51.97446857319531],
            [-1.872757106663353, 51.976730354971934],
            [-1.872002109904649, 51.977545897230605],
            [-1.86957297506574, 51.978800142134752],
            [-1.868474369847007, 51.980581784767395],
            [-1.871645610701023, 51.981562568477671],
            [-1.88343548132806, 51.983604006878558],
            [-1.87849765429658, 51.988555611633714],
            [-1.876188266311398, 51.989538594392201],
            [-1.874324359062728, 51.991059651539466],
            [-1.869394722921526, 51.993564506364898],
            [-1.867529205365826, 51.994013760943602],
            [-1.865363029637727, 51.995148634366494],
            [-1.861767251990698, 51.993186301223332],
            [-1.85757109853435, 51.99143981063397],
            [-1.854070644067676, 51.990815174538447],
            [-1.847168418748214, 51.988467052012147],
            [-1.842522499383518, 51.987784840485439],
            [-1.831956204788561, 51.987784648582902],
            [-1.824799438123016, 51.989143523707206],
            [-1.823276937648588, 51.988212511160967],
            [-1.820407082846571, 51.987390021021611],
            [-1.812208988788061, 51.988009302102867],
            [-1.81022876721019, 51.991747171845795],
            [-1.808248666896033, 51.994354873608835],
            [-1.805195589027967, 51.99612823404626],
            [-1.801689401455715, 51.996884803740812],
            [-1.804805902292342, 51.998996525403228],
            [-1.806319663087584, 52.000937416860282],
            [-1.806884709104309, 52.002662760140161],
            [-1.817940441998476, 52.001373991494631],
            [-1.821098235172731, 52.003263273286841],
            [-1.826207028050841, 52.004748081149309],
            [-1.829726569938817, 52.005133519375327],
            [-1.833030659271854, 52.005886267095278],
            [-1.837194348637651, 52.005845333890683],
            [-1.839060102189144, 52.006773035644997],
            [-1.835318982429557, 52.009152204859539],
            [-1.835677099499161, 52.011221461190836],
            [-1.834417473687131, 52.014601096217625],
            [-1.834131736341942, 52.017009297705378],
            [-1.835669387180585, 52.022492162843761],
            [-1.826248201940308, 52.029135355015477],
            [-1.825032921363325, 52.030922700309887],
            [-1.828961224935704, 52.032063079788458],
            [-1.829329583612977, 52.03179209531249],
            [-1.830570227575853, 52.032128335301067],
            [-1.830636122132964, 52.032821609256473],
            [-1.829096618033589, 52.036319450064347],
            [-1.829422708537578, 52.03720639960666],
            [-1.830136718455371, 52.037676741121253],
            [-1.829761179212405, 52.039060759266157],
            [-1.832416388350941, 52.040122757861234],
            [-1.832954121486796, 52.041753523196128],
            [-1.834849704628403, 52.043277406605441],
            [-1.834598744776232, 52.043722091680763],
            [-1.838898576305681, 52.043695672333918],
            [-1.847823795109398, 52.04652607883208],
            [-1.853851386560104, 52.049749641785297],
            [-1.857334480104374, 52.052236212700286],
            [-1.859179518808923, 52.052147622327901],
            [-1.859324520324272, 52.05241211940011],
            [-1.860252875622308, 52.052133613504516],
            [-1.860506843011356, 52.052531299203004],
            [-1.861280299198806, 52.052354196131134],
            [-1.861560135685555, 52.052878677655784],
            [-1.863458732464306, 52.053406839492894],
            [-1.861823779554065, 52.055822514335972],
            [-1.861412873896752, 52.058299845593034],
            [-1.86288708931858, 52.06342081787993],
            [-1.864997976618408, 52.066266976677646],
            [-1.871691771612012, 52.06541493722159],
            [-1.874415586701941, 52.069979692629062],
            [-1.872927037895508, 52.070150719987517],
            [-1.869591572082048, 52.071305072560158],
            [-1.870188680247819, 52.071668950129862],
            [-1.872014600027986, 52.071905597092396],
            [-1.869054564816997, 52.073845218591565],
            [-1.863188122692563, 52.073763947050864],
            [-1.855616635935079, 52.074429227125734],
            [-1.854374266622008, 52.072857945022619],
            [-1.853398390678233, 52.072355961727006],
            [-1.850893368466383, 52.074543831594745],
            [-1.849194643700301, 52.077267619682623],
            [-1.846629470852704, 52.079409468556811],
            [-1.837646337269552, 52.074263835184475],
            [-1.834475793314936, 52.073099646746371],
            [-1.83176335686699, 52.072757766516986],
            [-1.831809829921334, 52.073203766048302],
            [-1.82979591980052, 52.075257027615926],
            [-1.822475911467909, 52.081127887523976],
            [-1.818953562459486, 52.085392163332706],
            [-1.817827935700867, 52.084781769941728],
            [-1.816137354846585, 52.079600575788113],
            [-1.813953691698723, 52.078714279254697],
            [-1.81274688149302, 52.078732150422169],
            [-1.812292849425629, 52.081922186928551],
            [-1.813131434798029, 52.082741660020297],
            [-1.809370618924196, 52.085873358091312],
            [-1.809197117779234, 52.087204581652337],
            [-1.809802790304479, 52.087548099783596],
            [-1.806469204967129, 52.092656516928663],
            [-1.802299164798672, 52.096929114384885],
            [-1.79555360769611, 52.100760188879789],
            [-1.791734095450504, 52.099598226308302],
            [-1.790145556872185, 52.100578979841096],
            [-1.788269812700154, 52.103621629531276],
            [-1.789678151300052, 52.106224213262081],
            [-1.789427830021654, 52.106362221446162],
            [-1.785695623532252, 52.107025301146358],
            [-1.778383052963537, 52.109466218620369],
            [-1.773942675844078, 52.110618478294136],
            [-1.771023191655964, 52.111860738697509],
            [-1.767659411536878, 52.112579702797383],
            [-1.766232094904769, 52.109624391214254],
            [-1.763722174993379, 52.108492878849248],
            [-1.762538284786122, 52.107337915126251],
            [-1.758877364089397, 52.106062826315032],
            [-1.757694423309217, 52.103149262984459],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000080",
        LAD13CDO: "23UD",
        LAD13NM: "Forest of Dean",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.47118830604453, 52.023798448944021],
            [-2.47075439141335, 52.023280520160171],
            [-2.468488919640965, 52.023770559241953],
            [-2.466206052750679, 52.023713987192451],
            [-2.464080895800726, 52.023264753683719],
            [-2.463496493927503, 52.02227897419602],
            [-2.462555515589295, 52.021900568241044],
            [-2.462982733875139, 52.021216492728364],
            [-2.462572617981803, 52.020310040519291],
            [-2.463634178441325, 52.018824192375966],
            [-2.463245091309803, 52.018541616488868],
            [-2.463532591342878, 52.018160176896878],
            [-2.46250411543616, 52.017343366814984],
            [-2.462938513939986, 52.017076432947171],
            [-2.462482515475035, 52.016238489854011],
            [-2.463506077442148, 52.015596122503773],
            [-2.4632601269518, 52.015343551531124],
            [-2.4638514934827, 52.014758623289929],
            [-2.463543271917783, 52.01440200555917],
            [-2.461982030711785, 52.014475572155149],
            [-2.460273118266528, 52.015635776886839],
            [-2.456089837373334, 52.014789843370117],
            [-2.447737221025464, 52.015759635540199],
            [-2.44602014581703, 52.015262671233359],
            [-2.444080941392231, 52.015725831409441],
            [-2.443452606128727, 52.015105141613475],
            [-2.440581519753618, 52.014453302773852],
            [-2.437824938058789, 52.014327825384896],
            [-2.437029503970999, 52.014939453285521],
            [-2.436116629556841, 52.014565227298974],
            [-2.435163624647675, 52.013057410811641],
            [-2.434545117751375, 52.013133419223678],
            [-2.434396817844244, 52.012564852845962],
            [-2.433725082480708, 52.01225894702187],
            [-2.435585710920749, 52.010461122888984],
            [-2.434650431367526, 52.010169683109204],
            [-2.43459503901842, 52.009715855168984],
            [-2.434105303088617, 52.009544140594741],
            [-2.434226974892739, 52.008568196377411],
            [-2.433635927964471, 52.007861904211168],
            [-2.434070318351589, 52.007284895818053],
            [-2.433824148340201, 52.006837164980873],
            [-2.434994143478133, 52.006252045727145],
            [-2.43491705889181, 52.005664335646237],
            [-2.435659233516039, 52.005582473026436],
            [-2.435538233925646, 52.003623836414249],
            [-2.434879133553206, 52.003255853959395],
            [-2.435381072986271, 52.002890772405529],
            [-2.435291132647196, 52.002329182960409],
            [-2.435949075014807, 52.002429243011733],
            [-2.435784888358433, 52.001573032075179],
            [-2.436593406715716, 52.001280534154475],
            [-2.435912431567964, 52.000457706262949],
            [-2.435719860313936, 51.999377730202291],
            [-2.435006371162182, 51.998952408720733],
            [-2.435643187312342, 51.997932299980882],
            [-2.437110669245668, 51.997559138234251],
            [-2.437294655862565, 51.997158366077393],
            [-2.435681057252021, 51.996582646653309],
            [-2.432405642840609, 51.996006727395304],
            [-2.42713984474141, 51.995792191920437],
            [-2.422822177780684, 51.994332395393783],
            [-2.421013806572419, 51.994232785891008],
            [-2.41807218267882, 51.994584918698578],
            [-2.416230148997015, 51.994153596272326],
            [-2.413197199741761, 51.994081566350332],
            [-2.402975433758415, 51.99612643618218],
            [-2.399019695436807, 51.99613721412743],
            [-2.399425233145152, 51.999315867052104],
            [-2.39677951043133, 52.001065422723173],
            [-2.395103013514208, 52.003723330282106],
            [-2.39490020885013, 52.004679726309625],
            [-2.395566535518928, 52.005736598134611],
            [-2.394883441702201, 52.006745853820753],
            [-2.394703976926389, 52.009859047275235],
            [-2.394076909468134, 52.011781567565279],
            [-2.392893864913699, 52.012949820597896],
            [-2.386984562487979, 52.012157530492694],
            [-2.379955618430977, 52.013839213467833],
            [-2.375335711188945, 52.015981236674051],
            [-2.373269360795915, 52.016490387185023],
            [-2.368675668243066, 52.017285255867236],
            [-2.36520037222261, 52.017473204509564],
            [-2.363426123115294, 52.018086466875005],
            [-2.361475627773792, 52.018173388134088],
            [-2.35702629814274, 52.019211006728725],
            [-2.356251831184433, 52.019676374164597],
            [-2.354412740317217, 52.019672035090117],
            [-2.35397423477164, 52.020057257433706],
            [-2.35262420718555, 52.020351708483268],
            [-2.351381689505897, 52.021349795203392],
            [-2.350982717100497, 52.020089589699502],
            [-2.349379658830581, 52.017838589996174],
            [-2.350470635645403, 52.016475053112941],
            [-2.35185889975172, 52.015681527729505],
            [-2.352545738057833, 52.013526197256269],
            [-2.351065339616418, 52.013150314079219],
            [-2.349274491348975, 52.011449203252454],
            [-2.347577250738448, 52.011280705871577],
            [-2.345690318816352, 52.010317961868445],
            [-2.344004335561537, 52.008761213060474],
            [-2.343057753112324, 52.009194630901042],
            [-2.341906376813866, 52.00875833454343],
            [-2.3412880877407, 52.008301602060762],
            [-2.340880995119323, 52.006878651486502],
            [-2.340154284041095, 52.006719819769792],
            [-2.336340934932597, 52.007776398149083],
            [-2.333812398198491, 52.007659523834924],
            [-2.333048905331565, 52.007254406917291],
            [-2.329426891598179, 52.006819558593875],
            [-2.326112605893375, 52.005620434688616],
            [-2.32471371205145, 52.003558234204021],
            [-2.325797111368669, 52.000695290644252],
            [-2.325660839867902, 51.999581716480144],
            [-2.323517215763618, 51.999448271699322],
            [-2.322830015934475, 51.997468601525846],
            [-2.323498052082208, 51.99739753867572],
            [-2.32325985000496, 51.996277947782602],
            [-2.320660862530135, 51.992119648981998],
            [-2.32116898586757, 51.990472058758058],
            [-2.322039070940882, 51.98975401800179],
            [-2.321811106996757, 51.989441763671898],
            [-2.322332269663971, 51.988808288100607],
            [-2.32233283228474, 51.986454508972976],
            [-2.322925679468006, 51.984456937119226],
            [-2.322452889035708, 51.982492854781903],
            [-2.322600115306483, 51.980895692982081],
            [-2.323307582635906, 51.980471185861589],
            [-2.324120925608254, 51.978782282025414],
            [-2.324688369316254, 51.978932661636186],
            [-2.325328381241285, 51.976640946774154],
            [-2.326885726906809, 51.976383091529932],
            [-2.326568492978963, 51.975802269772444],
            [-2.323699137218672, 51.975612400745284],
            [-2.312623561303626, 51.976496468533611],
            [-2.308780449166528, 51.97442974953352],
            [-2.308628144286098, 51.974086701885717],
            [-2.309267917567374, 51.973764051817597],
            [-2.307930804007622, 51.972810041915054],
            [-2.309051564902701, 51.972153474837484],
            [-2.309469097898112, 51.970638331918231],
            [-2.305791910351788, 51.970825063800234],
            [-2.303707216206609, 51.96864750394753],
            [-2.300437001832662, 51.966766938033864],
            [-2.297246095705703, 51.967590505684768],
            [-2.293307740200973, 51.969656578515185],
            [-2.288295169892018, 51.970659749813358],
            [-2.280566465469335, 51.970565149729005],
            [-2.276974460097054, 51.96947678714367],
            [-2.273651027257372, 51.969436912996116],
            [-2.270560600064879, 51.968930687816339],
            [-2.269658171421345, 51.967727097882587],
            [-2.26980251235316, 51.966556168932492],
            [-2.270941856602458, 51.963124470137714],
            [-2.270721189993842, 51.961527317274864],
            [-2.27160707335618, 51.96049313404113],
            [-2.269172157161575, 51.959255305676898],
            [-2.268359778885561, 51.958470468355223],
            [-2.26754969032396, 51.953180337372018],
            [-2.274320750391589, 51.952785331026448],
            [-2.277314096434739, 51.953059724443804],
            [-2.279888093017131, 51.952816271576282],
            [-2.283308442422218, 51.951805610657885],
            [-2.284012908576612, 51.950920116838859],
            [-2.283762562431151, 51.94837362801681],
            [-2.28139837875105, 51.945834011429717],
            [-2.282311945739071, 51.944199991447618],
            [-2.285781805475188, 51.945366710968869],
            [-2.287859010337284, 51.945545058631247],
            [-2.288893183101098, 51.944152544795095],
            [-2.289092056402598, 51.942954480596256],
            [-2.289554797872561, 51.94297402131366],
            [-2.291160186346172, 51.941972078743682],
            [-2.292239879118753, 51.940896797501267],
            [-2.292323901529322, 51.938830499615939],
            [-2.291568880206385, 51.937923401993629],
            [-2.29532865521004, 51.933218116409151],
            [-2.288056794630641, 51.925563376026588],
            [-2.283864170950005, 51.923696283298824],
            [-2.283612941472267, 51.923288705367483],
            [-2.284527834481155, 51.92309768796251],
            [-2.28729739769941, 51.920964621293621],
            [-2.285789625063451, 51.9168594786398],
            [-2.28620714476302, 51.913013975261812],
            [-2.284819334470134, 51.911266825106637],
            [-2.287892473783243, 51.909438693026416],
            [-2.291649175665544, 51.906359056426183],
            [-2.29565592495371, 51.905355560235208],
            [-2.293659216119327, 51.903646004708662],
            [-2.295259396973669, 51.902742011591286],
            [-2.296526803034061, 51.902959997930516],
            [-2.295324102974292, 51.904406958316343],
            [-2.296276924585213, 51.904523241040152],
            [-2.297213874904579, 51.902896226370494],
            [-2.297120660588416, 51.899565342960784],
            [-2.295564364876408, 51.897391674305695],
            [-2.296015020583962, 51.896528315223883],
            [-2.293212162235073, 51.896157720809136],
            [-2.289958193446865, 51.894332549075408],
            [-2.295269846724757, 51.893923731722083],
            [-2.295185884079532, 51.893308066862815],
            [-2.29721314705524, 51.893103362385233],
            [-2.297024049306042, 51.892634515898216],
            [-2.299994240162315, 51.891103918828669],
            [-2.308153865221569, 51.888419715029684],
            [-2.309170518261385, 51.888354109972745],
            [-2.309577958045824, 51.887807290500177],
            [-2.311308488813914, 51.888651462219805],
            [-2.313534664545562, 51.888048551667204],
            [-2.314131919998479, 51.88847582340221],
            [-2.314716127153491, 51.888074166265511],
            [-2.315145797083262, 51.888222264369837],
            [-2.315911012137763, 51.887113431316898],
            [-2.316756909109344, 51.887565196042345],
            [-2.317364404082533, 51.88717425200327],
            [-2.319819111105648, 51.888492859631377],
            [-2.320939780338818, 51.886306815599227],
            [-2.323593516506236, 51.885187367685113],
            [-2.325649560854884, 51.882802697549074],
            [-2.326923477914137, 51.882173395193263],
            [-2.328311264897468, 51.879432694778224],
            [-2.329515356625209, 51.879205452715397],
            [-2.330680476810614, 51.879607671962944],
            [-2.331101017123923, 51.879505790247848],
            [-2.33091242172442, 51.878354584896869],
            [-2.333032810835591, 51.877301156295218],
            [-2.333203383072366, 51.876598482260675],
            [-2.334577604155336, 51.876212465728166],
            [-2.334573540660527, 51.8754698272135],
            [-2.331399154686301, 51.873627586420724],
            [-2.329744800081655, 51.871852034714088],
            [-2.328523437411972, 51.871675635312663],
            [-2.326800630948258, 51.870849675042663],
            [-2.324819696641064, 51.868660479022289],
            [-2.32097750866459, 51.868769931851951],
            [-2.317413832914379, 51.868420869315131],
            [-2.317113592663642, 51.866826688578634],
            [-2.317676474253815, 51.86344367869043],
            [-2.318207619929143, 51.862570121289622],
            [-2.316591929118739, 51.861618746779598],
            [-2.315979561760248, 51.860852568102452],
            [-2.316509952732892, 51.860284712015194],
            [-2.319327618515332, 51.859343832147744],
            [-2.322052450293544, 51.857823222899526],
            [-2.323165888838342, 51.856166732035049],
            [-2.327140954006565, 51.856509081421166],
            [-2.33128451048288, 51.855863615512348],
            [-2.332691991568651, 51.85593606376392],
            [-2.33465793396904, 51.854956763473496],
            [-2.338579183311311, 51.85393585093307],
            [-2.344561112757923, 51.854711472355355],
            [-2.346868043658503, 51.854500593473482],
            [-2.350637740127595, 51.850088334986815],
            [-2.352749028202414, 51.84939870526],
            [-2.352173237621592, 51.84668156303853],
            [-2.353880191354426, 51.845361057750701],
            [-2.355505088930644, 51.842482639390454],
            [-2.356662110435394, 51.839381742063111],
            [-2.357155759844226, 51.835919613191784],
            [-2.356786108806481, 51.834323037159969],
            [-2.355932640240801, 51.833203549108951],
            [-2.351313671438994, 51.831004762471643],
            [-2.350388278786265, 51.829754180216497],
            [-2.352503364973825, 51.828928776053246],
            [-2.35882866979762, 51.828015930122369],
            [-2.360802923616989, 51.827209687173983],
            [-2.362730671778944, 51.825139419245623],
            [-2.363231563950806, 51.82157203856643],
            [-2.362210642613546, 51.817112050063983],
            [-2.362349242118651, 51.811064261447974],
            [-2.360380694247875, 51.806859815610864],
            [-2.359059658450587, 51.805798421995],
            [-2.355603205919743, 51.804474662917791],
            [-2.353720687438078, 51.803360960685758],
            [-2.352309142845563, 51.803119745898428],
            [-2.34970405564204, 51.803253402018619],
            [-2.348011214848218, 51.802827757131659],
            [-2.347866060920277, 51.801493017946662],
            [-2.348358363806434, 51.800265181769973],
            [-2.350722461118738, 51.797878220407675],
            [-2.354491307432037, 51.796221549272687],
            [-2.36076483660149, 51.794668582159453],
            [-2.366288940048802, 51.794086853658321],
            [-2.369255549476899, 51.794437210624061],
            [-2.382004054073982, 51.799781198737662],
            [-2.385843558202592, 51.801079549558331],
            [-2.401754554796657, 51.804274697109769],
            [-2.405392158294081, 51.807372207861064],
            [-2.410737114564708, 51.813775948665644],
            [-2.414553802012086, 51.817109010976964],
            [-2.416662610351414, 51.818309049503227],
            [-2.420817936093428, 51.819126814982049],
            [-2.423935827560443, 51.819299027781348],
            [-2.431159314841908, 51.817630989888215],
            [-2.440556402288873, 51.812647474923445],
            [-2.443988886457913, 51.809297980948998],
            [-2.445767959919222, 51.805705614634789],
            [-2.446262661609103, 51.805719022062256],
            [-2.445876300773966, 51.805514593263695],
            [-2.447612567583338, 51.801876504275292],
            [-2.448904211736498, 51.801533513762003],
            [-2.451203024288361, 51.7990872325502],
            [-2.451793940913923, 51.798868277617679],
            [-2.452905660455065, 51.79606687633121],
            [-2.451896991966369, 51.791628268441315],
            [-2.450626773545095, 51.790890482913092],
            [-2.450953826191856, 51.790247265133182],
            [-2.450086695027942, 51.786887025015893],
            [-2.451090177134198, 51.786912847792529],
            [-2.450051964508102, 51.786747796193033],
            [-2.448431810195328, 51.784914413528249],
            [-2.447292543071318, 51.784493477144835],
            [-2.44701160370965, 51.783937098644124],
            [-2.446523897712529, 51.784020771848738],
            [-2.443970764162533, 51.782957808955629],
            [-2.44321854503893, 51.782971437967078],
            [-2.441433985013377, 51.781924399763561],
            [-2.441397460062587, 51.781449807316854],
            [-2.440858566789752, 51.781782702596246],
            [-2.436150185980361, 51.781017149511257],
            [-2.43002486783725, 51.782137538126904],
            [-2.41999870658302, 51.78127379573295],
            [-2.411348626914376, 51.779782206076995],
            [-2.406887283549931, 51.778523703316175],
            [-2.405691354302942, 51.777865195598878],
            [-2.404270418629799, 51.774894941612672],
            [-2.404640160134627, 51.772806835876018],
            [-2.406556170917221, 51.770555144247865],
            [-2.410995933909286, 51.768410603845126],
            [-2.4136118329261, 51.767796322247982],
            [-2.416814594867524, 51.766557717007345],
            [-2.419876223216468, 51.765900354244302],
            [-2.42094932691529, 51.765362442209721],
            [-2.424232273867676, 51.76483364449831],
            [-2.426041793913459, 51.764183332680744],
            [-2.430201931746134, 51.761594015822098],
            [-2.434228232765378, 51.760054308856731],
            [-2.436769848803614, 51.759331891008841],
            [-2.438573973410711, 51.759511286081143],
            [-2.439548245687353, 51.758979865860766],
            [-2.440399175315032, 51.759472989231028],
            [-2.439308516611228, 51.760253905874379],
            [-2.439971918251996, 51.760681199029186],
            [-2.439741200378926, 51.761987573851435],
            [-2.440321914509435, 51.760764404336101],
            [-2.439794601377233, 51.760177460886908],
            [-2.440685974371197, 51.759316367287617],
            [-2.439735303190614, 51.758697743587049],
            [-2.438482807169806, 51.759223910372128],
            [-2.437611696528463, 51.759038344823061],
            [-2.440890963396741, 51.757455333891301],
            [-2.448384540933641, 51.752960176607473],
            [-2.449359538835782, 51.753111098751084],
            [-2.449057738251073, 51.752624933089109],
            [-2.453946207607791, 51.750242373791998],
            [-2.459703697086209, 51.747760006660805],
            [-2.465055742844702, 51.746618643208713],
            [-2.471023646395223, 51.744416308487835],
            [-2.473641135404026, 51.742917747048047],
            [-2.476973088036154, 51.739691722295518],
            [-2.477574892192347, 51.740040831743578],
            [-2.477291146201564, 51.73963918074012],
            [-2.478809265820126, 51.735017834515148],
            [-2.489973862491857, 51.731255739150427],
            [-2.494160775928075, 51.728678408312526],
            [-2.496101390502763, 51.726753427343795],
            [-2.498153575255493, 51.723896209556095],
            [-2.498270974331115, 51.722591987805878],
            [-2.500840991223586, 51.718434313832056],
            [-2.501347475606291, 51.716064767703237],
            [-2.505166992784935, 51.711614832961978],
            [-2.505648041585931, 51.710624627787226],
            [-2.505334138396249, 51.710379619460483],
            [-2.508387162445552, 51.709793702167538],
            [-2.510929399793044, 51.708581442085759],
            [-2.515608953120421, 51.708507941941797],
            [-2.519503199378905, 51.70747297303361],
            [-2.525464896388303, 51.703907499991935],
            [-2.527090634776175, 51.702469708413147],
            [-2.530185680166965, 51.702081740380144],
            [-2.534389218861022, 51.70072209300865],
            [-2.537541016837801, 51.700620493268183],
            [-2.542963570498139, 51.699347561773394],
            [-2.544605747460713, 51.698856228286246],
            [-2.546947475177181, 51.69746518770544],
            [-2.54706865217183, 51.698274735178728],
            [-2.547591336190107, 51.697105234320887],
            [-2.552417499391449, 51.693014984738745],
            [-2.553743666957838, 51.69249983305734],
            [-2.556683019572771, 51.693409338291872],
            [-2.555462872067213, 51.69466580073108],
            [-2.552660603482293, 51.695868566063837],
            [-2.555769286673351, 51.694758759185945],
            [-2.55769780624908, 51.693576256359265],
            [-2.560412518980626, 51.69303734322191],
            [-2.563985432346458, 51.690199676663759],
            [-2.565957068896516, 51.690302581480985],
            [-2.569519665400954, 51.689914017381199],
            [-2.569062426910495, 51.690367595996896],
            [-2.569248540988299, 51.690788384593723],
            [-2.568557098499773, 51.691255684036733],
            [-2.569262848464759, 51.691928408409709],
            [-2.568630494460547, 51.692494325665464],
            [-2.566630154500546, 51.693228691122677],
            [-2.568324426880018, 51.692776334932717],
            [-2.569387211399122, 51.692039297215899],
            [-2.568896927163907, 51.691244147780701],
            [-2.570641044315325, 51.689701774038923],
            [-2.573512966116831, 51.688583653974206],
            [-2.574431284251713, 51.687647668882605],
            [-2.574709682181886, 51.686671651962364],
            [-2.574353291015341, 51.685714925178068],
            [-2.574814109042878, 51.685437537038808],
            [-2.589605375957401, 51.680704911649734],
            [-2.591700618966975, 51.680885886562187],
            [-2.59108365791087, 51.680268594541509],
            [-2.5968968228704, 51.678422045717888],
            [-2.603471726596555, 51.675273849528068],
            [-2.606692852783687, 51.673253086650902],
            [-2.60919914707561, 51.672510913898044],
            [-2.609961784583903, 51.672658907670922],
            [-2.609987309528161, 51.672406119115614],
            [-2.608514128536649, 51.672435342576499],
            [-2.608255151160331, 51.672102207588843],
            [-2.611173242515965, 51.670053218070564],
            [-2.611701807375681, 51.670002811469935],
            [-2.612829278784274, 51.668685997398029],
            [-2.615149210467838, 51.667128256752378],
            [-2.616120207927021, 51.667079107768807],
            [-2.615932899641352, 51.66648846130682],
            [-2.616975566024257, 51.665649490654609],
            [-2.621761452919566, 51.663327856946935],
            [-2.625564832939203, 51.660559900413766],
            [-2.625945785551697, 51.660612717095482],
            [-2.6259699428909, 51.65858953660198],
            [-2.623629740226997, 51.653980439796193],
            [-2.624714930807098, 51.653006301649029],
            [-2.625480397393618, 51.65295996637029],
            [-2.631323104924844, 51.647661553904889],
            [-2.631216545524335, 51.646961699863013],
            [-2.632179102524298, 51.645299411758955],
            [-2.636220730781767, 51.642256476369468],
            [-2.638911886117475, 51.639503086872786],
            [-2.640661889706863, 51.635816074072672],
            [-2.645271521279448, 51.633393720148582],
            [-2.648000220228257, 51.631119150906564],
            [-2.648752371178378, 51.631382040398677],
            [-2.648280294734255, 51.630800208151022],
            [-2.649161317422805, 51.629666918728233],
            [-2.650668559338945, 51.626059315819823],
            [-2.650941376963001, 51.622108778024348],
            [-2.65064177485489, 51.620553127277795],
            [-2.64960468184641, 51.618734514799414],
            [-2.646968401871277, 51.616279135866641],
            [-2.64769720937355, 51.61461619952081],
            [-2.647691298047188, 51.613288199554916],
            [-2.649549876922657, 51.61054813137995],
            [-2.651238238052602, 51.609752923595487],
            [-2.652629703909205, 51.609405317629744],
            [-2.654647084224794, 51.610106202287319],
            [-2.656128795260324, 51.610209421088555],
            [-2.657172591851047, 51.610688218558089],
            [-2.65813491391428, 51.613619425471541],
            [-2.659386768831073, 51.615791020186812],
            [-2.659335903320631, 51.616566366401145],
            [-2.65775729643969, 51.618966042308394],
            [-2.655501085501284, 51.620164632951095],
            [-2.654986037968913, 51.621611529138598],
            [-2.655180055544958, 51.622746061243419],
            [-2.656080833373961, 51.624105925078581],
            [-2.657870428893149, 51.625688285787596],
            [-2.662451785346365, 51.628398601948653],
            [-2.664435839505928, 51.630111938180924],
            [-2.665547269831028, 51.631916509343185],
            [-2.665978604853859, 51.631969809465318],
            [-2.667359867996469, 51.63364425486327],
            [-2.666963031088227, 51.633579974097195],
            [-2.666905206543186, 51.634070333772435],
            [-2.66736036488636, 51.633973337164782],
            [-2.66767755650314, 51.634320399511004],
            [-2.667643885165983, 51.635372584303624],
            [-2.666474392999293, 51.63701026911037],
            [-2.665164454741777, 51.640313043378164],
            [-2.665786784937868, 51.643238009073862],
            [-2.667975478306366, 51.645645155659039],
            [-2.669621469583985, 51.646713844386085],
            [-2.673359235662577, 51.646829132882445],
            [-2.675310831448296, 51.645693100447559],
            [-2.677248963810023, 51.645211685598078],
            [-2.678435106399759, 51.64576589882396],
            [-2.679213113001949, 51.64686014689687],
            [-2.679309756419231, 51.647910680164522],
            [-2.678635532638022, 51.648927908191212],
            [-2.670907518294246, 51.655113434639929],
            [-2.666969307245759, 51.659014747808087],
            [-2.66515168512551, 51.662243964873191],
            [-2.665197260297333, 51.664664178329751],
            [-2.666374139029479, 51.665547648159112],
            [-2.667730611218746, 51.665760229497231],
            [-2.669043809037865, 51.665586413782528],
            [-2.670542138839029, 51.664927791527639],
            [-2.674464911234129, 51.661530866774442],
            [-2.677094183954361, 51.660523985886087],
            [-2.679072334803906, 51.660260802760526],
            [-2.682572405029942, 51.66083753256467],
            [-2.684708735096178, 51.661765589435745],
            [-2.685520386973756, 51.662570075650713],
            [-2.685435286215506, 51.663636947809955],
            [-2.685060825289539, 51.664116574481518],
            [-2.684449479339935, 51.664132727895343],
            [-2.684835588615404, 51.664808424757886],
            [-2.684204537221094, 51.665916242439621],
            [-2.684031068568041, 51.667646290538954],
            [-2.682524145809658, 51.668591958838199],
            [-2.680514102137268, 51.669161988842063],
            [-2.678107479736608, 51.669323370771998],
            [-2.67455843987415, 51.669189163044322],
            [-2.67171416424787, 51.668603049301311],
            [-2.667358601362814, 51.668879644240064],
            [-2.659690754720658, 51.670785982463201],
            [-2.657873535460293, 51.671715992129727],
            [-2.65641689778644, 51.673385743036029],
            [-2.656598426005462, 51.67523784134503],
            [-2.657913290616617, 51.676454201190978],
            [-2.662068977322895, 51.677987241931206],
            [-2.667914334555837, 51.678935991108432],
            [-2.670648334089754, 51.68033834046436],
            [-2.672222981001459, 51.681871349154527],
            [-2.67180990760786, 51.683963296861869],
            [-2.669156995149713, 51.686997729309581],
            [-2.668479653487536, 51.68834848612741],
            [-2.668519626138345, 51.69047200667287],
            [-2.668021232212331, 51.691502549599647],
            [-2.668624303887189, 51.692568184144136],
            [-2.668402325134147, 51.693409235883379],
            [-2.669149560468087, 51.693947156337799],
            [-2.669539217246698, 51.694861149505861],
            [-2.672752544107571, 51.697393618807943],
            [-2.673916336133988, 51.697815831477925],
            [-2.682688582460618, 51.700193717311279],
            [-2.683529142224725, 51.701048396729718],
            [-2.683244095344453, 51.702681976342873],
            [-2.681525234150065, 51.703083983799708],
            [-2.679642196331514, 51.702768511574099],
            [-2.67696557947812, 51.700473206619691],
            [-2.676020179951289, 51.700050666377585],
            [-2.673701123410712, 51.699983969691573],
            [-2.670437835516836, 51.700645521572731],
            [-2.668859407512613, 51.701741569114283],
            [-2.668059217207172, 51.705769726196728],
            [-2.668627400830166, 51.707204199732352],
            [-2.672596481194619, 51.710576646534712],
            [-2.677712598728306, 51.712986572884269],
            [-2.678974784984467, 51.713956635640393],
            [-2.680529830505705, 51.719099637865412],
            [-2.681329956920566, 51.721191764094122],
            [-2.681106802432144, 51.722333151159646],
            [-2.679996901128828, 51.723908557496053],
            [-2.680229151338291, 51.724817128808482],
            [-2.686160455275953, 51.728434883896185],
            [-2.687026056736062, 51.729851342976588],
            [-2.687089041699199, 51.73075819260599],
            [-2.685653016368371, 51.731833845040676],
            [-2.675484277554057, 51.734395020074288],
            [-2.672087386735238, 51.736074286492602],
            [-2.670995809221128, 51.736975154837616],
            [-2.671272041712966, 51.739881343659199],
            [-2.670787103247837, 51.740972053080213],
            [-2.669311573304451, 51.742460425566158],
            [-2.669535185056009, 51.74269472173529],
            [-2.668560557193925, 51.744957969860316],
            [-2.665214575921455, 51.748792837910742],
            [-2.662692505829582, 51.754025592175424],
            [-2.663177089899389, 51.755063141260536],
            [-2.665867925733102, 51.756871340440235],
            [-2.671543483905831, 51.758865658931029],
            [-2.673262315288675, 51.759871829528109],
            [-2.674731568812277, 51.761334363426094],
            [-2.676413632647987, 51.764231549195223],
            [-2.679454896342479, 51.767205376091155],
            [-2.680448781388356, 51.768925036171183],
            [-2.679921976697037, 51.771381782556304],
            [-2.676842202608474, 51.773893717361972],
            [-2.675050299668544, 51.776772212232643],
            [-2.676130742679446, 51.780303131560736],
            [-2.67348383296785, 51.783804208053425],
            [-2.673358442677667, 51.784729218889566],
            [-2.675840879041825, 51.788064180274986],
            [-2.674088797574713, 51.788307113383382],
            [-2.673324597987706, 51.788783530000984],
            [-2.672887442660142, 51.789803833237549],
            [-2.672711359486794, 51.793072228612353],
            [-2.6722222156717, 51.793525484121979],
            [-2.670535956055571, 51.793724829546349],
            [-2.669807424023569, 51.794268452759766],
            [-2.67147014310586, 51.795020516473052],
            [-2.676745244932667, 51.798598412441862],
            [-2.677562702253057, 51.799924393295605],
            [-2.677730227491166, 51.801424949183115],
            [-2.678582584861572, 51.802462105500254],
            [-2.678299512812631, 51.803313402480178],
            [-2.675747993145614, 51.805706255048001],
            [-2.675533079324244, 51.806658752764513],
            [-2.674292772428985, 51.80712981663482],
            [-2.673482883642244, 51.80709220118316],
            [-2.67260766079168, 51.80890083391747],
            [-2.669767730360229, 51.808906259704649],
            [-2.664998424160663, 51.810175011577989],
            [-2.662022405106562, 51.810104595333001],
            [-2.659535896892339, 51.810676921177929],
            [-2.658859592840352, 51.811447656522937],
            [-2.658848749472855, 51.813687405695092],
            [-2.661103939802369, 51.818110962170088],
            [-2.659730377283269, 51.818628474493728],
            [-2.658885636178043, 51.8197948635854],
            [-2.660868601328808, 51.822748101958446],
            [-2.650161628432516, 51.824108813750158],
            [-2.649870153677178, 51.825027517453883],
            [-2.650398073280797, 51.826118814782554],
            [-2.648380822954326, 51.826802491062928],
            [-2.647237797371169, 51.827841865914088],
            [-2.64341243696206, 51.834638545791812],
            [-2.641045605844223, 51.83659534525534],
            [-2.640472849575225, 51.83622353862507],
            [-2.638381357497783, 51.837549408162992],
            [-2.636304144023366, 51.839484757357312],
            [-2.635619173316758, 51.840419937049823],
            [-2.635640777905709, 51.841129215610387],
            [-2.636707707194825, 51.843088890412965],
            [-2.635723219894337, 51.842859550358604],
            [-2.632926500145377, 51.840303192549335],
            [-2.630221449226594, 51.838883646913942],
            [-2.629685520101617, 51.838754349513096],
            [-2.628814201798725, 51.839137536536306],
            [-2.625464701273788, 51.838957598338276],
            [-2.6244647014145, 51.839484395454306],
            [-2.621591407170803, 51.843318124526157],
            [-2.615396353278192, 51.84546360867332],
            [-2.609432084385367, 51.846790282382521],
            [-2.606398691334889, 51.852018972272319],
            [-2.60469353550266, 51.854458030576211],
            [-2.603991029634737, 51.854908495360874],
            [-2.601214842026438, 51.856209338494793],
            [-2.598366827659563, 51.856486402746548],
            [-2.596109508837489, 51.856196667589217],
            [-2.593063244130492, 51.855202377537502],
            [-2.591616818955154, 51.854184685939565],
            [-2.58894449709138, 51.851344346512363],
            [-2.586465988473238, 51.849874112294032],
            [-2.583035797521241, 51.849564795644781],
            [-2.582108687530857, 51.849721337372955],
            [-2.579230699079964, 51.851371917259229],
            [-2.578773759830747, 51.851967575195722],
            [-2.578804131948114, 51.853202793061783],
            [-2.58310134340665, 51.858227326536664],
            [-2.585217206216248, 51.859923311867256],
            [-2.584910232690242, 51.860214349927631],
            [-2.58063937527452, 51.861664187301798],
            [-2.577701528259746, 51.861426907594613],
            [-2.576098091469968, 51.862542466348074],
            [-2.573068076118773, 51.862482645318934],
            [-2.569346970531754, 51.863008711583547],
            [-2.568133625773119, 51.8626027913621],
            [-2.563999125392573, 51.863404910470372],
            [-2.563513814649011, 51.86384779628515],
            [-2.561812807382649, 51.864277612947433],
            [-2.55877835628447, 51.863867694867778],
            [-2.556396434188775, 51.86290436363425],
            [-2.553795650829119, 51.863148607878436],
            [-2.550087888294501, 51.862767708586233],
            [-2.548275284811508, 51.8620290210864],
            [-2.546704915365291, 51.860783885339465],
            [-2.543998806862874, 51.860236295389086],
            [-2.5340826246594, 51.860380651044643],
            [-2.531622912293391, 51.860764024083544],
            [-2.531120837472983, 51.86301314307952],
            [-2.527301584818219, 51.862890970165537],
            [-2.525178096760574, 51.863481290902008],
            [-2.522565041755684, 51.864813708197438],
            [-2.522232476234412, 51.864688412576797],
            [-2.521848798524646, 51.866169133487844],
            [-2.520111912445008, 51.868062237865402],
            [-2.519345428500126, 51.870866316169455],
            [-2.517942289318393, 51.873387280546368],
            [-2.513273309006514, 51.880097031107738],
            [-2.508623592072392, 51.885161158715157],
            [-2.50825704485751, 51.884866940839174],
            [-2.50786039285094, 51.885252567973659],
            [-2.506684815433432, 51.885366428684954],
            [-2.505430556067476, 51.886108184261353],
            [-2.503831415995958, 51.886546610928328],
            [-2.501946484725767, 51.886245381487178],
            [-2.500696314233475, 51.884358114787929],
            [-2.501178349457876, 51.881978853233925],
            [-2.500253055898567, 51.881730146621216],
            [-2.498187024196137, 51.881978974665294],
            [-2.494655295199372, 51.884231729673296],
            [-2.493860609899058, 51.879994935190886],
            [-2.488812264410952, 51.880524014564664],
            [-2.487794094419402, 51.880409561286825],
            [-2.486727730301022, 51.884708057618617],
            [-2.485043233331088, 51.884922707478957],
            [-2.482393202020356, 51.885877655722403],
            [-2.480733696063427, 51.885717218867626],
            [-2.480012658590113, 51.885817266006562],
            [-2.477877863260006, 51.884953837669407],
            [-2.47340769704977, 51.885545537799949],
            [-2.473738937355561, 51.887338795942341],
            [-2.471438027992358, 51.889201073509398],
            [-2.468649979612213, 51.890768546433563],
            [-2.467818576555791, 51.892004513004025],
            [-2.465213059008962, 51.892983170458244],
            [-2.462522503323494, 51.892909266740915],
            [-2.456161279078777, 51.893407931274844],
            [-2.449829510638738, 51.894396144331552],
            [-2.448920940762005, 51.894801509801383],
            [-2.446009338709396, 51.893986320941991],
            [-2.443668476698405, 51.895778972659528],
            [-2.439655543193465, 51.897065356791451],
            [-2.439357855808589, 51.897387444800003],
            [-2.440188436497372, 51.897761058644534],
            [-2.439314584099129, 51.899505865476947],
            [-2.440835283299497, 51.898515671021542],
            [-2.443171550442291, 51.898444854433414],
            [-2.44334761640113, 51.898761570181961],
            [-2.444302500860733, 51.898912610835055],
            [-2.444196047683397, 51.899759956884495],
            [-2.445388342188465, 51.90054754758583],
            [-2.443785309391653, 51.900849407542673],
            [-2.442379918238721, 51.901890454273286],
            [-2.440505490358362, 51.902091692513658],
            [-2.440154193367867, 51.902433762971597],
            [-2.442207295233144, 51.903995874631121],
            [-2.442468855270261, 51.905023450501552],
            [-2.444056829238084, 51.905831143282178],
            [-2.442935168607946, 51.906177026528319],
            [-2.442898360476995, 51.906720215577778],
            [-2.4417545768047, 51.907184850652826],
            [-2.443040864228709, 51.908497179839074],
            [-2.442590661078101, 51.908984383074483],
            [-2.444431915962854, 51.909811796290512],
            [-2.443131632392064, 51.911957433757834],
            [-2.44326325170214, 51.912479308752751],
            [-2.444475370637874, 51.913769423581982],
            [-2.446449590524691, 51.915080012309147],
            [-2.447689650854501, 51.915655212038381],
            [-2.448258054559945, 51.918712646312834],
            [-2.44932491178196, 51.919112267139667],
            [-2.45157780697236, 51.918742207402985],
            [-2.452225371414569, 51.919229723182838],
            [-2.455022533276881, 51.9193394155551],
            [-2.459926510464634, 51.923424684491877],
            [-2.461294034383512, 51.925045780684954],
            [-2.466043156377175, 51.927966182319771],
            [-2.466205457023597, 51.931417135453387],
            [-2.467878893211856, 51.937371437197342],
            [-2.466958690177478, 51.937564799818873],
            [-2.46583116407853, 51.937263579755054],
            [-2.465432927421564, 51.937997011735128],
            [-2.464236792765763, 51.938376655860857],
            [-2.464099060008395, 51.938702668276164],
            [-2.463584111136811, 51.938560844470942],
            [-2.463384772350701, 51.939261118415807],
            [-2.464381161589541, 51.939678861529089],
            [-2.464032042254719, 51.939960753562971],
            [-2.464629949872351, 51.940104945112665],
            [-2.464270615251046, 51.940243024468671],
            [-2.464650434113048, 51.941242207473735],
            [-2.465042760321858, 51.941339557010984],
            [-2.464779685904832, 51.941783845539639],
            [-2.46546477546118, 51.942196514986541],
            [-2.464992206207163, 51.942218163244505],
            [-2.464942489517466, 51.942756012708763],
            [-2.463729542615299, 51.943633810764169],
            [-2.464862436813338, 51.944580572696722],
            [-2.465273855537418, 51.945535573035244],
            [-2.465458185408885, 51.946757599336735],
            [-2.464480965413868, 51.948492198304599],
            [-2.466027940567752, 51.950350782871659],
            [-2.465487434711934, 51.951688061892369],
            [-2.467819615855489, 51.951941346287605],
            [-2.47052661550401, 51.953108353904902],
            [-2.472224556586852, 51.955446363170942],
            [-2.472524526849126, 51.955327379174008],
            [-2.473728995682581, 51.956530006029915],
            [-2.474598102824516, 51.955736209939843],
            [-2.475185545355887, 51.955693380691194],
            [-2.47575972725705, 51.956322217549449],
            [-2.477298581298454, 51.956251252479959],
            [-2.479238513105996, 51.955719202952807],
            [-2.481096470316257, 51.956097329284688],
            [-2.482439310447335, 51.9556620701089],
            [-2.483884553852467, 51.956765606059875],
            [-2.486107848385711, 51.957411875807644],
            [-2.487520781680602, 51.956865681916341],
            [-2.490187907458179, 51.954971022117576],
            [-2.493116994850948, 51.957343156483518],
            [-2.497520459486594, 51.958780240586918],
            [-2.496614400225224, 51.959622912456794],
            [-2.496624984225051, 51.960316960361197],
            [-2.500906840154006, 51.960600906593442],
            [-2.499873701829731, 51.961530456122368],
            [-2.499946212604641, 51.96255330507006],
            [-2.499030511295057, 51.963709815923046],
            [-2.49756717774764, 51.963786139812051],
            [-2.497261441949237, 51.964172239469946],
            [-2.495762075936772, 51.964414124381911],
            [-2.493382389592582, 51.963884674303735],
            [-2.49224535551786, 51.964120499565574],
            [-2.49294580433315, 51.964684889237766],
            [-2.49243690745509, 51.966723441674716],
            [-2.493013528188104, 51.968600109852581],
            [-2.493917928482418, 51.970230849385295],
            [-2.495131466432927, 51.971111346463147],
            [-2.496501175486415, 51.976105369186541],
            [-2.49635941849073, 51.97750493716174],
            [-2.495572032871328, 51.979901607696426],
            [-2.494904082968128, 51.98019661904371],
            [-2.494913678633193, 51.981066888552149],
            [-2.493128990593927, 51.981763973076582],
            [-2.49130321157745, 51.98190467132715],
            [-2.491150630872516, 51.983395982685067],
            [-2.49032325686621, 51.983500128804884],
            [-2.489686249515057, 51.986238679665817],
            [-2.488638790434134, 51.987399248179024],
            [-2.486769883579894, 51.988016566545085],
            [-2.482604280837264, 51.988215331467309],
            [-2.481643763643071, 51.989492360785846],
            [-2.480249406415885, 51.990515814289068],
            [-2.480028148522293, 51.991342070656884],
            [-2.478922509765725, 51.991998405549083],
            [-2.477117505213275, 51.992766354881574],
            [-2.474872175799912, 51.992806002111678],
            [-2.474032467999647, 51.993141146055208],
            [-2.4740636818618, 51.993476376392863],
            [-2.473430227285287, 51.993484320012506],
            [-2.472208933515514, 51.994374816643777],
            [-2.470980924694285, 51.994769036504103],
            [-2.475417067937672, 51.997497886954044],
            [-2.47514480443921, 51.998195770910066],
            [-2.476938860397923, 51.999251225722645],
            [-2.477647513458515, 52.000142035267196],
            [-2.478844678958573, 52.000397904485439],
            [-2.479653739602532, 52.000184227059982],
            [-2.485497787443764, 52.000323904792992],
            [-2.487287920030108, 52.003401242158837],
            [-2.489770289310649, 52.005144141421198],
            [-2.489387991322217, 52.005888368239383],
            [-2.490874592119586, 52.005947812312726],
            [-2.491571181663119, 52.006500535279919],
            [-2.492112328080935, 52.008286537385587],
            [-2.491253505439444, 52.010482070872072],
            [-2.49028484419931, 52.010635354919671],
            [-2.489563431726688, 52.011282993885608],
            [-2.487634983666985, 52.011472609526685],
            [-2.487132451282116, 52.012289251991419],
            [-2.484623165536145, 52.012531568009635],
            [-2.483393471568158, 52.01415675609833],
            [-2.482861785370757, 52.01390000407779],
            [-2.480367019358501, 52.016051804310734],
            [-2.477417497835751, 52.01985789324867],
            [-2.478933825393734, 52.022521085540191],
            [-2.473865654778637, 52.023809283098871],
            [-2.47118830604453, 52.023798448944021],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000081",
        LAD13CDO: "23UE",
        LAD13NM: "Gloucester",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.205399790527884, 51.88320196587204],
            [-2.202962822092678, 51.881465563737464],
            [-2.201251516646549, 51.879530969765007],
            [-2.199125187408835, 51.88061709513547],
            [-2.198027116646141, 51.878683211621407],
            [-2.199689891724172, 51.877903584713785],
            [-2.197697853347128, 51.871849784224658],
            [-2.197633384846013, 51.871722221837103],
            [-2.196176604373436, 51.868064464934292],
            [-2.196389242358908, 51.865536764625439],
            [-2.192054980091008, 51.86434993561889],
            [-2.187112708085509, 51.862169521366198],
            [-2.177262628020751, 51.855870472328974],
            [-2.181121052118734, 51.853126849340711],
            [-2.186678959255463, 51.846159164398976],
            [-2.187687468537832, 51.845022001399563],
            [-2.192155363945955, 51.841583832987581],
            [-2.199641386247935, 51.837474172289248],
            [-2.200444111177541, 51.837501576449085],
            [-2.200361993247471, 51.836985635598857],
            [-2.202160592343922, 51.836099642140617],
            [-2.206948716863908, 51.831406114073708],
            [-2.209334173342718, 51.829161338141859],
            [-2.212638085881506, 51.826958010607186],
            [-2.223365833676662, 51.821163196709783],
            [-2.225807614175381, 51.821457029346725],
            [-2.224763468035454, 51.822715072109197],
            [-2.226288518389643, 51.823037618032529],
            [-2.225209485539849, 51.82457804836649],
            [-2.223409928451454, 51.825379883263587],
            [-2.224770620806629, 51.82589786544122],
            [-2.223605452936911, 51.826186001989576],
            [-2.225081547668383, 51.826562602479797],
            [-2.227491759074504, 51.828304906756777],
            [-2.230937321783803, 51.826463126257195],
            [-2.236875969237909, 51.825571086129912],
            [-2.238109231052608, 51.825282680032359],
            [-2.240242345619126, 51.825590324652225],
            [-2.241840059998407, 51.826166065967854],
            [-2.244141304642583, 51.826158609389083],
            [-2.245071389830953, 51.825890540042174],
            [-2.246238851385426, 51.826049037681187],
            [-2.248004745600884, 51.825263108134934],
            [-2.249348090045053, 51.825217108241205],
            [-2.25210350289283, 51.822624522015204],
            [-2.252874750466184, 51.822772115023255],
            [-2.255231976031293, 51.820893296041049],
            [-2.256633785761284, 51.821457573993946],
            [-2.262858306321333, 51.807574294961555],
            [-2.26836566674203, 51.809800605584748],
            [-2.268881327892485, 51.809925300716444],
            [-2.269158237643609, 51.80941667460656],
            [-2.270549388555567, 51.80968500784229],
            [-2.270262097350628, 51.810153201556183],
            [-2.272222746823282, 51.811047775996492],
            [-2.272033520822087, 51.811660501901166],
            [-2.277356543901706, 51.813005698876687],
            [-2.276854667591621, 51.813246043281488],
            [-2.278856478569228, 51.813481369215836],
            [-2.282810169451925, 51.814539151724539],
            [-2.281530926017127, 51.815968196950415],
            [-2.286018872763513, 51.818646566562471],
            [-2.28954117929482, 51.819982094405432],
            [-2.289562742317847, 51.821081640180758],
            [-2.290071838693435, 51.821735828139694],
            [-2.296087209191429, 51.823964078405027],
            [-2.297222972489968, 51.824136535599536],
            [-2.297858424503768, 51.824567393396677],
            [-2.299087775251045, 51.824627207935144],
            [-2.301101407214799, 51.826758325267527],
            [-2.301293590147303, 51.829245638964565],
            [-2.301108652731174, 51.829800856741528],
            [-2.300036029618869, 51.830200102506438],
            [-2.301355443115724, 51.831771047704393],
            [-2.300020657958834, 51.832252783026952],
            [-2.298515941227417, 51.832279092203983],
            [-2.296642700737638, 51.831876544054815],
            [-2.295085881979364, 51.831922721023965],
            [-2.291503782088049, 51.833181406909056],
            [-2.290012532876391, 51.834856518891293],
            [-2.289579172204962, 51.838788440382956],
            [-2.288464184636136, 51.839648021630794],
            [-2.285444979795332, 51.840988753632999],
            [-2.280913126958221, 51.842797865662597],
            [-2.280241199142572, 51.842819249906618],
            [-2.280556854599869, 51.842927287429525],
            [-2.27932303192256, 51.843627924811734],
            [-2.278589255228278, 51.844918070804411],
            [-2.279558248330332, 51.847852215895905],
            [-2.287498039626575, 51.858167256458657],
            [-2.287794819208849, 51.858937952692528],
            [-2.287485447483544, 51.859607635451653],
            [-2.285395463538316, 51.860454279970426],
            [-2.277970590922209, 51.861352285173808],
            [-2.275018877737535, 51.862143237584711],
            [-2.272583737445233, 51.864084656790425],
            [-2.271099634003056, 51.866750301794539],
            [-2.268018113322372, 51.868260650346016],
            [-2.26590864404936, 51.868150357450489],
            [-2.264667243520819, 51.86771709955498],
            [-2.2627887624225, 51.864873889266129],
            [-2.261140610727119, 51.863395858107609],
            [-2.260502569458872, 51.861797788010442],
            [-2.259803643556078, 51.862476351844478],
            [-2.262291703292902, 51.864806668475687],
            [-2.263832675033604, 51.867310787956995],
            [-2.264752139199376, 51.868076545157344],
            [-2.267430611166547, 51.868623423392542],
            [-2.269386271421739, 51.868267412878325],
            [-2.26984463836001, 51.871080518810665],
            [-2.267776211778583, 51.873499309079364],
            [-2.267550824350431, 51.875169434314387],
            [-2.265761952899926, 51.875288575788176],
            [-2.265495492282412, 51.875673089672354],
            [-2.260409271497547, 51.875553199766514],
            [-2.255487883556563, 51.876318336113947],
            [-2.252787985275174, 51.877185501829402],
            [-2.250084170128514, 51.878673884984799],
            [-2.24700003679347, 51.880778881717291],
            [-2.24704655665248, 51.881049409950471],
            [-2.246795841094505, 51.880896192261069],
            [-2.243130328881055, 51.88220481341785],
            [-2.237960187182638, 51.883101895368888],
            [-2.237551668111434, 51.882499432778275],
            [-2.233955803353791, 51.881910548570396],
            [-2.228848844198962, 51.882119301171002],
            [-2.226806265285458, 51.882419060595574],
            [-2.21852369772559, 51.884792162042196],
            [-2.214546267556779, 51.885216666656866],
            [-2.21159752753149, 51.88504489182084],
            [-2.208456267723872, 51.884395069625306],
            [-2.205399790527884, 51.88320196587204],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000082",
        LAD13CDO: "23UF",
        LAD13NM: "Stroud",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.301108652731174, 51.829800856741528],
            [-2.301293590147303, 51.829245638964565],
            [-2.301101407214799, 51.826758325267527],
            [-2.299087775251045, 51.824627207935144],
            [-2.297858424503768, 51.824567393396677],
            [-2.297222972489968, 51.824136535599536],
            [-2.296087209191429, 51.823964078405027],
            [-2.290071838693435, 51.821735828139694],
            [-2.289562742317847, 51.821081640180758],
            [-2.28954117929482, 51.819982094405432],
            [-2.286018872763513, 51.818646566562471],
            [-2.281530926017127, 51.815968196950415],
            [-2.282810169451925, 51.814539151724539],
            [-2.278856478569228, 51.813481369215836],
            [-2.276854667591621, 51.813246043281488],
            [-2.277356543901706, 51.813005698876687],
            [-2.272033520822087, 51.811660501901166],
            [-2.272222746823282, 51.811047775996492],
            [-2.270262097350628, 51.810153201556183],
            [-2.270549388555567, 51.80968500784229],
            [-2.269158237643609, 51.80941667460656],
            [-2.268881327892485, 51.809925300716444],
            [-2.26836566674203, 51.809800605584748],
            [-2.262858306321333, 51.807574294961555],
            [-2.256633785761284, 51.821457573993946],
            [-2.255231976031293, 51.820893296041049],
            [-2.252874750466184, 51.822772115023255],
            [-2.25210350289283, 51.822624522015204],
            [-2.249348090045053, 51.825217108241205],
            [-2.248004745600884, 51.825263108134934],
            [-2.246238851385426, 51.826049037681187],
            [-2.245071389830953, 51.825890540042174],
            [-2.244141304642583, 51.826158609389083],
            [-2.241840059998407, 51.826166065967854],
            [-2.240242345619126, 51.825590324652225],
            [-2.238109231052608, 51.825282680032359],
            [-2.236875969237909, 51.825571086129912],
            [-2.230937321783803, 51.826463126257195],
            [-2.227491759074504, 51.828304906756777],
            [-2.225081547668383, 51.826562602479797],
            [-2.223605452936911, 51.826186001989576],
            [-2.224770620806629, 51.82589786544122],
            [-2.223409928451454, 51.825379883263587],
            [-2.225209485539849, 51.82457804836649],
            [-2.226288518389643, 51.823037618032529],
            [-2.224763468035454, 51.822715072109197],
            [-2.225807614175381, 51.821457029346725],
            [-2.223365833676662, 51.821163196709783],
            [-2.212638085881506, 51.826958010607186],
            [-2.209334173342718, 51.829161338141859],
            [-2.206948716863908, 51.831406114073708],
            [-2.202160592343922, 51.836099642140617],
            [-2.200361993247471, 51.836985635598857],
            [-2.200444111177541, 51.837501576449085],
            [-2.199641386247935, 51.837474172289248],
            [-2.192155363945955, 51.841583832987581],
            [-2.187687468537832, 51.845022001399563],
            [-2.186852788179867, 51.844669987911999],
            [-2.18651162472582, 51.845011286835245],
            [-2.186677202452363, 51.844683753483267],
            [-2.185076350066789, 51.84403893853262],
            [-2.18343713964559, 51.842874484986496],
            [-2.178658864106716, 51.841820917948255],
            [-2.180061467421789, 51.839754454336628],
            [-2.179750837868437, 51.83829300134196],
            [-2.177951904086326, 51.837389452415309],
            [-2.17556260514157, 51.836923720204481],
            [-2.174358354868617, 51.835892453313306],
            [-2.172470749892893, 51.835277561626313],
            [-2.171031200076285, 51.831869395929466],
            [-2.167868137219977, 51.830447993005677],
            [-2.166063957644539, 51.828364654163188],
            [-2.164266289710096, 51.828421128270818],
            [-2.163690356856977, 51.826461901492195],
            [-2.160980010849503, 51.825280638441626],
            [-2.160089449019905, 51.824557183648672],
            [-2.156419660090368, 51.823620774745308],
            [-2.152851703499692, 51.823147153888897],
            [-2.149238993076718, 51.822718436559875],
            [-2.146487978582298, 51.821337740692172],
            [-2.142491510455291, 51.821528322920969],
            [-2.14134940202146, 51.821898333554302],
            [-2.136921101269432, 51.82132995784557],
            [-2.134260388117643, 51.819912457043657],
            [-2.134413829445839, 51.819310785077455],
            [-2.13543680234509, 51.818886134175401],
            [-2.134929565557286, 51.817609100185507],
            [-2.132692467806645, 51.816594770508019],
            [-2.128829913223957, 51.815800674431436],
            [-2.123700938233842, 51.810194018728069],
            [-2.121893379443877, 51.809562944393456],
            [-2.117598946162375, 51.809156438394162],
            [-2.118237320006897, 51.805904653338878],
            [-2.118844490224339, 51.804604840758437],
            [-2.11940641419143, 51.804304870006725],
            [-2.119508319103375, 51.803355316652514],
            [-2.117430192034711, 51.800609764404271],
            [-2.115642055328899, 51.799451700731879],
            [-2.114527300843393, 51.79903920789512],
            [-2.112425680998766, 51.799465616174238],
            [-2.110592551411881, 51.798811914187752],
            [-2.108830062226099, 51.796762713443265],
            [-2.106966240735404, 51.797161830452168],
            [-2.10560278806791, 51.796994933990469],
            [-2.104761962520494, 51.795836746732242],
            [-2.101285496239807, 51.795498141937131],
            [-2.100017218632763, 51.7950622671977],
            [-2.098806182594821, 51.79426938732248],
            [-2.098310836490318, 51.79318908371679],
            [-2.092486249010411, 51.79239182373265],
            [-2.088843598437185, 51.790681851850934],
            [-2.083816609861448, 51.788943993493866],
            [-2.084051361552036, 51.788104064002383],
            [-2.082957571102753, 51.786957588740194],
            [-2.083637621151166, 51.786242319016758],
            [-2.083187554367336, 51.78510167815292],
            [-2.08354581488491, 51.783639482229276],
            [-2.082829267549406, 51.782616811640963],
            [-2.08098971798502, 51.781805308102456],
            [-2.082516286045853, 51.781104740916241],
            [-2.083131051384485, 51.78040300588178],
            [-2.082760735349086, 51.778449516001601],
            [-2.08077617611956, 51.77831513601469],
            [-2.079490127321205, 51.777279348796569],
            [-2.080018417176622, 51.776039124914767],
            [-2.079226766903454, 51.775052446521052],
            [-2.078298430243676, 51.774621500504821],
            [-2.07776238947453, 51.774746832968702],
            [-2.076362496650177, 51.773995203424597],
            [-2.075946750437077, 51.773259107168485],
            [-2.076778068201971, 51.772067251830478],
            [-2.076953993421828, 51.770699598112415],
            [-2.076470835874146, 51.770364548351218],
            [-2.075630456780992, 51.770460398986522],
            [-2.073128213486341, 51.769900946245436],
            [-2.073646089455697, 51.768441374295875],
            [-2.07905315329467, 51.767787797486108],
            [-2.083908449003844, 51.766621878117526],
            [-2.090347666710271, 51.765636167098826],
            [-2.092980104686497, 51.766098946242245],
            [-2.099109761403879, 51.76613977901134],
            [-2.100823189307843, 51.766419738228777],
            [-2.102394082271519, 51.767098100787777],
            [-2.102658217082882, 51.767273195417701],
            [-2.103682828146319, 51.766051308501176],
            [-2.102927892742649, 51.764157557567088],
            [-2.103761235392947, 51.762337032277301],
            [-2.103681304125286, 51.759709014729424],
            [-2.104569760409746, 51.758587938719131],
            [-2.105936969751213, 51.757763129530026],
            [-2.106714327143376, 51.755648623708971],
            [-2.105598521774746, 51.754290183443764],
            [-2.105637825416898, 51.75375158260352],
            [-2.107193884327313, 51.75199691027197],
            [-2.106794630538184, 51.7503977614208],
            [-2.106175190998809, 51.750014404784281],
            [-2.107168817969363, 51.747537356314403],
            [-2.106361589240795, 51.746740581987183],
            [-2.105991671230345, 51.744031002728249],
            [-2.103631023048964, 51.742848986275099],
            [-2.101871401658043, 51.740964196947104],
            [-2.100489322485978, 51.740769383936019],
            [-2.10064156947942, 51.741500229372086],
            [-2.098630899059862, 51.741955988618969],
            [-2.098206931681778, 51.742798810013547],
            [-2.096258360100988, 51.744568074856936],
            [-2.093390525077059, 51.745949627904309],
            [-2.089639718402837, 51.746989228926758],
            [-2.088594736006543, 51.74518461331094],
            [-2.083499702585182, 51.744639898307845],
            [-2.080262214848695, 51.743627966028818],
            [-2.079956156021148, 51.739280981573543],
            [-2.073456497856116, 51.739230392406668],
            [-2.072593878630522, 51.739550114227328],
            [-2.068800309636417, 51.739104648017474],
            [-2.068690595332711, 51.738382727330496],
            [-2.070410185536296, 51.737836845331088],
            [-2.072245693059864, 51.736452896111629],
            [-2.071888318596001, 51.735739222595782],
            [-2.072387286498259, 51.735348701375159],
            [-2.073975221290459, 51.734972780060197],
            [-2.074850279860187, 51.734353636114086],
            [-2.077300384548416, 51.736043271154557],
            [-2.080401087573776, 51.734586423450303],
            [-2.083523923518101, 51.73580972600535],
            [-2.084086685296044, 51.734736684834907],
            [-2.083827484336284, 51.733177812916772],
            [-2.084492074527325, 51.732377126979088],
            [-2.083797867049797, 51.732043156351793],
            [-2.083007612575685, 51.732229834872825],
            [-2.083316131422563, 51.731497739637128],
            [-2.081578117031105, 51.729640500283942],
            [-2.083260219746192, 51.729444209712476],
            [-2.083850938922169, 51.728651671356069],
            [-2.084732041166021, 51.728303980423448],
            [-2.088467761955991, 51.729165265261734],
            [-2.089282824479366, 51.729115195927406],
            [-2.091640948025123, 51.728162115634845],
            [-2.097348764438934, 51.726986870081753],
            [-2.098377391165459, 51.726631761185985],
            [-2.098518402104309, 51.726230638890932],
            [-2.102464064301237, 51.725138431565163],
            [-2.104926472650591, 51.725662233783197],
            [-2.108086430333572, 51.725436383084713],
            [-2.110656236068395, 51.726033693358616],
            [-2.112363043581855, 51.725396397837862],
            [-2.115821940410181, 51.724900318603872],
            [-2.116745931703707, 51.725026177055973],
            [-2.117730987957394, 51.724693419754324],
            [-2.120294324358957, 51.725003709106943],
            [-2.124764952624126, 51.72446316693398],
            [-2.126451337493537, 51.724898329224821],
            [-2.128157802549193, 51.724744526861855],
            [-2.130415275410045, 51.72389057888337],
            [-2.134475562158466, 51.723211659357894],
            [-2.134040755359449, 51.722066687600659],
            [-2.133295276251215, 51.718650008826934],
            [-2.133463361145116, 51.718216445075534],
            [-2.134059561692641, 51.718153726276476],
            [-2.133547465382698, 51.717775783802551],
            [-2.132513933929867, 51.714810780423917],
            [-2.133096900253113, 51.714692336349309],
            [-2.132333160871477, 51.711894258607934],
            [-2.133091211744981, 51.711773818556871],
            [-2.133232385472343, 51.708597985725049],
            [-2.131095199554508, 51.708728068669551],
            [-2.129350047658944, 51.709309935626216],
            [-2.125124735391887, 51.707459646742016],
            [-2.123733365538045, 51.706176287970735],
            [-2.124068565636493, 51.704923466322697],
            [-2.125854824265129, 51.703028025506732],
            [-2.129128675922484, 51.700706545334896],
            [-2.12991176615077, 51.697271955456081],
            [-2.135919743139749, 51.698906931831559],
            [-2.140778372841177, 51.699327380966047],
            [-2.140902736752365, 51.698839011454396],
            [-2.146176152356067, 51.694166141193492],
            [-2.149230478304918, 51.692027790023189],
            [-2.14971192196476, 51.691058826824673],
            [-2.149399411453953, 51.690172695300824],
            [-2.150283367709985, 51.68975347612659],
            [-2.149897184880247, 51.689336779670043],
            [-2.152096723207109, 51.689969624080561],
            [-2.152864183395925, 51.68890317156611],
            [-2.154023983290468, 51.688366678918094],
            [-2.154299538001707, 51.688573113869467],
            [-2.158990426119923, 51.686392780793653],
            [-2.160390034775486, 51.687032845713702],
            [-2.164134947919294, 51.690573786764922],
            [-2.166552405378964, 51.689811523564579],
            [-2.16667840281177, 51.690242921210718],
            [-2.168278988657028, 51.690013159074724],
            [-2.168306086723345, 51.690298140587537],
            [-2.171504822503165, 51.690349252722015],
            [-2.171457643903337, 51.690879801260223],
            [-2.171849869538348, 51.690926880558123],
            [-2.172525818546702, 51.690639968867025],
            [-2.175182449188076, 51.690785285194821],
            [-2.177149338754103, 51.687677676359527],
            [-2.180691750069142, 51.687620124571609],
            [-2.182734768255798, 51.688812787462226],
            [-2.187476562654937, 51.688422281223829],
            [-2.18791795554256, 51.690559678295678],
            [-2.189254411609709, 51.691537568694898],
            [-2.193321789156808, 51.690692059950656],
            [-2.193594429426687, 51.691187024476015],
            [-2.195572141652947, 51.691546089065504],
            [-2.196522582192226, 51.692538925433233],
            [-2.200629692704508, 51.692835876230042],
            [-2.199889721564152, 51.691367081229423],
            [-2.200446544452987, 51.690024646861929],
            [-2.201385004142912, 51.689932228891074],
            [-2.201859656220408, 51.689317315357542],
            [-2.205015237909351, 51.688789456103066],
            [-2.20672440701138, 51.685778005551754],
            [-2.209373047796408, 51.68360463333569],
            [-2.211848764842811, 51.682241619681818],
            [-2.210007284899934, 51.681881685822063],
            [-2.206088217611215, 51.68201990668004],
            [-2.204636794871888, 51.681545014655399],
            [-2.20191358176535, 51.679015114005608],
            [-2.202306270211449, 51.677236876873756],
            [-2.200588216624176, 51.674637772178521],
            [-2.199767875268126, 51.674224677298433],
            [-2.198144526839092, 51.674045808010959],
            [-2.196373038160236, 51.672715390530101],
            [-2.194703091201345, 51.672108570079416],
            [-2.190653463542553, 51.672646602186049],
            [-2.188589124112, 51.670722223709653],
            [-2.187554894492917, 51.670295001116223],
            [-2.188135531272267, 51.670135825940115],
            [-2.187772174160684, 51.669687746637926],
            [-2.187896764467268, 51.667301278648587],
            [-2.18877350005494, 51.664991825080918],
            [-2.191520405758203, 51.661862922037749],
            [-2.192112925689734, 51.659758904979654],
            [-2.194174862748457, 51.659852614938906],
            [-2.197025788279949, 51.660528496836498],
            [-2.197278016379374, 51.659686493851851],
            [-2.202532836540648, 51.659963459541039],
            [-2.202628664692969, 51.659732219429358],
            [-2.212156224237782, 51.660777239297353],
            [-2.214815669676601, 51.660637531076482],
            [-2.219527278936874, 51.659752163740414],
            [-2.226342738972703, 51.66014921252787],
            [-2.232726514644359, 51.660918060924956],
            [-2.232334111791071, 51.66220997951941],
            [-2.233606618893779, 51.662256904465728],
            [-2.234058441997584, 51.662401661899679],
            [-2.234090339426479, 51.662979734359176],
            [-2.235796037304873, 51.663195704726824],
            [-2.235746874085003, 51.66514240440825],
            [-2.237576979813066, 51.665353603249791],
            [-2.237888708561178, 51.666622532685025],
            [-2.239396370115426, 51.666819071409549],
            [-2.238486685275917, 51.667328928107999],
            [-2.240457658379257, 51.668455102149764],
            [-2.24011397682957, 51.668541222578227],
            [-2.240347532988951, 51.668957037960453],
            [-2.242443632292645, 51.67074647408181],
            [-2.243263523258627, 51.672093459867128],
            [-2.242533536191164, 51.674038871763095],
            [-2.244291959427505, 51.673756496436624],
            [-2.24675366353812, 51.674361849107527],
            [-2.247053677220792, 51.67634198198617],
            [-2.249327033181608, 51.676327283428222],
            [-2.252127957826978, 51.676779850671998],
            [-2.258797522686407, 51.677215791184089],
            [-2.265361014451666, 51.678286372530287],
            [-2.267793006619207, 51.680383892201434],
            [-2.268439940453801, 51.681432587269626],
            [-2.271532288827564, 51.682883847025678],
            [-2.276157234609127, 51.680632446717226],
            [-2.276387310068394, 51.681361991271913],
            [-2.276957580775319, 51.680480407554676],
            [-2.280252524695238, 51.681446333785757],
            [-2.280258456481771, 51.681938138720852],
            [-2.285365201523614, 51.681407939600646],
            [-2.285167890093798, 51.680391513269896],
            [-2.284323847993622, 51.679567268676756],
            [-2.285691744344445, 51.67928791665603],
            [-2.287166809878832, 51.680632998609305],
            [-2.286856143569846, 51.678393148057935],
            [-2.285794551364417, 51.677470541909294],
            [-2.286448281434246, 51.674726626649374],
            [-2.286014940741248, 51.674577530148945],
            [-2.286147491229035, 51.674269707294449],
            [-2.285478987996432, 51.674208396791592],
            [-2.284909321750767, 51.673305264253848],
            [-2.283029449622966, 51.674244000806908],
            [-2.281800822486961, 51.673640949141252],
            [-2.282637184497457, 51.672096943243368],
            [-2.283889014157688, 51.672021991924247],
            [-2.284505064200935, 51.671564644995755],
            [-2.283852067371344, 51.670045809944916],
            [-2.286493174505075, 51.669469348240511],
            [-2.286739011649521, 51.669929997831566],
            [-2.289700287246024, 51.669455181260162],
            [-2.284302959746487, 51.667003884714745],
            [-2.283364839425704, 51.666816438713234],
            [-2.28344643376948, 51.665758872702469],
            [-2.285829312796428, 51.665542698099067],
            [-2.287293716936339, 51.665045503370358],
            [-2.28745025423434, 51.663508517728502],
            [-2.289543612542901, 51.663257011440145],
            [-2.289408494878487, 51.662246729462083],
            [-2.290056106735112, 51.661328023971912],
            [-2.29155071885043, 51.659935173150437],
            [-2.29170329809451, 51.660281855973267],
            [-2.292186788376223, 51.660154775713366],
            [-2.292487566248917, 51.659493169968442],
            [-2.291728195395103, 51.65965690263279],
            [-2.291806727578576, 51.658155169374155],
            [-2.292812450017371, 51.658311809191275],
            [-2.293124672748361, 51.657858769970311],
            [-2.293320242485232, 51.657027489967007],
            [-2.292855145470513, 51.656190667185214],
            [-2.295847068153693, 51.655463862110878],
            [-2.295576497679441, 51.654980814603149],
            [-2.297021137054118, 51.654844995435262],
            [-2.296925239047601, 51.654548526784652],
            [-2.298365979105335, 51.654481933567318],
            [-2.299415739303252, 51.653215089303693],
            [-2.301474671226146, 51.653302427613554],
            [-2.302847728478085, 51.653714288041655],
            [-2.305989207143958, 51.651204770449951],
            [-2.311094931770304, 51.648330325728502],
            [-2.31290098476325, 51.647824710187834],
            [-2.315364467309128, 51.645842733657993],
            [-2.312606550208726, 51.644734296705586],
            [-2.312262452165646, 51.644083345719238],
            [-2.3102220191795, 51.643684150733009],
            [-2.309753392617, 51.642779068447297],
            [-2.308589018708807, 51.642626587420594],
            [-2.307300159845145, 51.642870036970152],
            [-2.307528694032566, 51.642472923067075],
            [-2.309275681921551, 51.640324811228638],
            [-2.310677237799108, 51.639668341792031],
            [-2.311415934933747, 51.638866159685321],
            [-2.314262030905287, 51.637945956322923],
            [-2.316264595819667, 51.636673703838113],
            [-2.317799216479851, 51.63709934072692],
            [-2.32220104650161, 51.63553723816068],
            [-2.323055406978445, 51.634783217283896],
            [-2.323600588275269, 51.632818916306242],
            [-2.32267372248042, 51.632512168504832],
            [-2.322942973504436, 51.631568241109939],
            [-2.321572755246819, 51.630263771226247],
            [-2.320296638922855, 51.62754110057643],
            [-2.317334606658657, 51.626436925769532],
            [-2.31644535512338, 51.625490745393591],
            [-2.315019788306895, 51.625526051373939],
            [-2.314343343644663, 51.625054024150984],
            [-2.312253410568921, 51.626159241244963],
            [-2.310331260107221, 51.626462852470965],
            [-2.309100591748647, 51.627546855303507],
            [-2.308177461028875, 51.627760579254918],
            [-2.30853016210117, 51.628003316393439],
            [-2.299674924927879, 51.631598509855593],
            [-2.293764441650219, 51.632512586803387],
            [-2.292288287799749, 51.630335886392068],
            [-2.290868483432575, 51.630620842446646],
            [-2.290776844554586, 51.630298282333683],
            [-2.290251058261856, 51.630313970392379],
            [-2.289179710087092, 51.629508299132233],
            [-2.288244869618242, 51.628352521460009],
            [-2.287684488851265, 51.628148895523012],
            [-2.287172056284684, 51.628445065376802],
            [-2.286316148075975, 51.627882504278183],
            [-2.282216934245482, 51.622187459978903],
            [-2.279331923056594, 51.621186440472741],
            [-2.27969293192214, 51.6209347229575],
            [-2.278078225872639, 51.620492593255847],
            [-2.278301869332499, 51.620216929027102],
            [-2.276856711715314, 51.619147686278509],
            [-2.275876121718021, 51.616792476234103],
            [-2.275797245437239, 51.61545655328058],
            [-2.274328945495156, 51.614586041110186],
            [-2.27869540040376, 51.613019351645285],
            [-2.280826507027643, 51.611531600959616],
            [-2.285185631307152, 51.604733534327565],
            [-2.286716198913854, 51.603130245440234],
            [-2.287511221219114, 51.601214943877814],
            [-2.287218746500012, 51.600169968291794],
            [-2.28760029195221, 51.599317553837139],
            [-2.290129204572477, 51.596514119884503],
            [-2.291641425419677, 51.593695179682804],
            [-2.29158801073204, 51.593920096133125],
            [-2.294394234883243, 51.594795136772746],
            [-2.296553736629821, 51.594993799935772],
            [-2.296442023640527, 51.59380093079001],
            [-2.296874389252258, 51.593028379589413],
            [-2.297889340949548, 51.592386521247782],
            [-2.29962529182608, 51.59207189772237],
            [-2.300102089355279, 51.591480846107061],
            [-2.301016625694924, 51.591600784832707],
            [-2.301340724716687, 51.592150223209138],
            [-2.301791278621077, 51.591958448112379],
            [-2.304005249007027, 51.59276285101361],
            [-2.305601898599503, 51.592989779344634],
            [-2.306106099058797, 51.591976038480276],
            [-2.306909359809294, 51.591645754971168],
            [-2.308152102512576, 51.591627211145891],
            [-2.308613734690196, 51.591796833061714],
            [-2.307878466699148, 51.592360724899855],
            [-2.308257197424602, 51.592864144888281],
            [-2.316574092300699, 51.589833484657078],
            [-2.317593757280268, 51.590744249785864],
            [-2.318736260876161, 51.590638649111582],
            [-2.317740319517162, 51.591061247665877],
            [-2.319671391789435, 51.592658259711342],
            [-2.319387316201375, 51.592907194927839],
            [-2.319850562697129, 51.593914762228685],
            [-2.322653757774026, 51.593675107728934],
            [-2.323828248699618, 51.593187237137037],
            [-2.324993485715326, 51.593226273671],
            [-2.326753089906521, 51.592809581026437],
            [-2.328405764986834, 51.593182602281018],
            [-2.328590589898218, 51.591989831035811],
            [-2.329198584723795, 51.592825221347219],
            [-2.331066304660133, 51.591601635842395],
            [-2.331182572718386, 51.592503140614461],
            [-2.330379276099003, 51.593204034233224],
            [-2.331147969838718, 51.595286062097806],
            [-2.331273572975676, 51.595485315422323],
            [-2.333296043839739, 51.59547598955853],
            [-2.334179382980398, 51.596632461341535],
            [-2.335051749193063, 51.596488808583885],
            [-2.335256156890259, 51.594252974509217],
            [-2.338259565006591, 51.595320616802837],
            [-2.339482124309685, 51.594132923634412],
            [-2.343369092167317, 51.595196076566772],
            [-2.34411534809654, 51.594228218224458],
            [-2.344022794100399, 51.596497907346453],
            [-2.34719574210813, 51.597411970668219],
            [-2.349868316562792, 51.597261067443505],
            [-2.352788855778063, 51.596694856490018],
            [-2.354055710353582, 51.59938844511283],
            [-2.35481854390992, 51.598715385524834],
            [-2.355099317218046, 51.60009830465868],
            [-2.35683222582281, 51.600512942222856],
            [-2.357542184305197, 51.601565465606775],
            [-2.35986086077019, 51.601002710449208],
            [-2.36294670465565, 51.601235071022408],
            [-2.362235093480004, 51.602710053215702],
            [-2.365584434331172, 51.603733668204924],
            [-2.366583923859904, 51.603055299148807],
            [-2.366764964305755, 51.600975034410425],
            [-2.36761686032805, 51.600444575656816],
            [-2.367784631468266, 51.599766101887951],
            [-2.370081964207425, 51.598558531262356],
            [-2.370085046468716, 51.597872481154106],
            [-2.370579570605317, 51.59761286808488],
            [-2.373575620139648, 51.597480183739208],
            [-2.375379938837328, 51.597617386551079],
            [-2.375761031761662, 51.597961434582452],
            [-2.380002817103129, 51.596421937350492],
            [-2.385159815266778, 51.59678725181837],
            [-2.385479991111263, 51.597090108158412],
            [-2.386829569824632, 51.597227729253227],
            [-2.388091720456115, 51.596931341370613],
            [-2.388451679059839, 51.597156732303816],
            [-2.390676210259628, 51.597133160774248],
            [-2.39187227899556, 51.597380929645254],
            [-2.393102554990633, 51.597074703831211],
            [-2.394709258075177, 51.597392998008303],
            [-2.394491662483413, 51.599104785867979],
            [-2.396372854051012, 51.600096485853435],
            [-2.39636116069001, 51.600743003991184],
            [-2.394908840818712, 51.601758530165156],
            [-2.394492015231152, 51.603141903671542],
            [-2.393889823838904, 51.603131339130584],
            [-2.394910862393008, 51.603821139938042],
            [-2.395034280907444, 51.60489788834316],
            [-2.395430442420552, 51.605124034921772],
            [-2.395066833341162, 51.60648475190969],
            [-2.395436245894842, 51.606788314086991],
            [-2.394449630991855, 51.606913018508216],
            [-2.395188389500091, 51.60751205354142],
            [-2.394550772513568, 51.607752470210578],
            [-2.394453069492222, 51.608974722550563],
            [-2.393853934404783, 51.609159259628967],
            [-2.394166837860039, 51.60960597772192],
            [-2.39318928952291, 51.610619884685711],
            [-2.391671379064666, 51.611106897620857],
            [-2.391809285108264, 51.611357295558307],
            [-2.390572104817436, 51.61157811184566],
            [-2.390261832906679, 51.612108734164842],
            [-2.39101009487008, 51.612465896394944],
            [-2.390773736701008, 51.613026844182393],
            [-2.391842747050382, 51.613397318492247],
            [-2.391251570149702, 51.614346978165948],
            [-2.39169949657172, 51.614706935310181],
            [-2.391237924070085, 51.614943149165455],
            [-2.391596685729307, 51.615346562360131],
            [-2.390005407899239, 51.61639305885447],
            [-2.390731024587057, 51.616964291640038],
            [-2.389877885667749, 51.617017481055512],
            [-2.390020491002762, 51.617646400236112],
            [-2.389359812891996, 51.617574865605505],
            [-2.389303670938494, 51.618102842931307],
            [-2.38993075454079, 51.618466707715967],
            [-2.388933639578647, 51.619590336569644],
            [-2.389543654789275, 51.619645857429717],
            [-2.389242927801106, 51.621130424143878],
            [-2.389581922319735, 51.621246186311055],
            [-2.388852314548774, 51.622241248596815],
            [-2.389627144762681, 51.622477847446831],
            [-2.388887171783061, 51.62293885966335],
            [-2.389537022347953, 51.623254996869072],
            [-2.388618163268989, 51.623407299613667],
            [-2.388443609907349, 51.624449072435233],
            [-2.388936848402798, 51.624861938362052],
            [-2.389511171537387, 51.624789901003112],
            [-2.389435923245193, 51.625786388802112],
            [-2.388973182339298, 51.626069351878982],
            [-2.389581339603848, 51.626403609560576],
            [-2.38916705512505, 51.626609986291761],
            [-2.389548923896877, 51.6271724746735],
            [-2.38891207894799, 51.627538735375751],
            [-2.393077906758066, 51.630145822360063],
            [-2.393658059843445, 51.631740734279319],
            [-2.395011033858776, 51.631815311258499],
            [-2.395308428594547, 51.632619031940614],
            [-2.39657158706232, 51.632828763113189],
            [-2.396664553107954, 51.633382313464004],
            [-2.397502415841545, 51.633035111237696],
            [-2.398355531236215, 51.633934945624802],
            [-2.399253994832109, 51.633743074819527],
            [-2.399697087327195, 51.63434578231135],
            [-2.400459242789649, 51.634425903957215],
            [-2.400477108607765, 51.635141550959545],
            [-2.399875755161441, 51.635110333781469],
            [-2.399361593958873, 51.635464543821328],
            [-2.39874392638027, 51.635221181566493],
            [-2.3983512873128, 51.635758396793669],
            [-2.39744442535364, 51.635986254229032],
            [-2.397000766219636, 51.635647884682925],
            [-2.396317579790936, 51.636008946927802],
            [-2.392637089642459, 51.63614629800847],
            [-2.391550233443347, 51.635947625117566],
            [-2.39135271646522, 51.636334010830254],
            [-2.389993948042855, 51.636441035138311],
            [-2.388183906389999, 51.637172634431096],
            [-2.386118434464651, 51.637610131796315],
            [-2.385352907310758, 51.63866912552772],
            [-2.38536709832814, 51.639320048756566],
            [-2.385990848656005, 51.63994109556424],
            [-2.388443395620466, 51.640144298655215],
            [-2.389992173759503, 51.64044306775429],
            [-2.390166463891418, 51.640714025527139],
            [-2.392571361350143, 51.639074984270799],
            [-2.394817301958292, 51.638618783905876],
            [-2.395785082514528, 51.638741402175555],
            [-2.397144704693817, 51.639552319679439],
            [-2.394785573902634, 51.642118303117449],
            [-2.396567620322366, 51.642804624461732],
            [-2.395836169229922, 51.643610016222055],
            [-2.398838298413389, 51.645808113038655],
            [-2.401101541514589, 51.644662102556644],
            [-2.402739985186169, 51.64249588370329],
            [-2.404133417498237, 51.642857039325321],
            [-2.404784850049083, 51.641360440888128],
            [-2.406423450196345, 51.641667674862163],
            [-2.407017986761955, 51.641573004071319],
            [-2.4072183717126, 51.641196472691888],
            [-2.410185493408664, 51.641874873171474],
            [-2.411536286484186, 51.642789054836911],
            [-2.412382436714933, 51.642711456931288],
            [-2.413919341220958, 51.644059236629367],
            [-2.415322001256734, 51.644624329425589],
            [-2.415768404146931, 51.643658881961969],
            [-2.416737789782168, 51.643308379759887],
            [-2.420216817381592, 51.64461499799264],
            [-2.42128559881417, 51.645477927331861],
            [-2.422765065857475, 51.64558499863135],
            [-2.423482168859838, 51.646078732848743],
            [-2.424117235899184, 51.645833674710779],
            [-2.427230517287452, 51.647241209592316],
            [-2.430835827392041, 51.64655997734878],
            [-2.432012026419674, 51.646989933454172],
            [-2.431703231663715, 51.648562747922398],
            [-2.432177917325935, 51.649083395124244],
            [-2.434305080184872, 51.649194228374661],
            [-2.435507022402144, 51.649886599900555],
            [-2.437064653763026, 51.649536443489836],
            [-2.437707510496759, 51.650102297599688],
            [-2.43867257623459, 51.650205691081609],
            [-2.439076200442759, 51.650542254467162],
            [-2.439557049898301, 51.649896678131391],
            [-2.441935559879224, 51.652214693016411],
            [-2.442984742446165, 51.652489468006841],
            [-2.444804064464102, 51.651109617397324],
            [-2.445043610375186, 51.64988948991666],
            [-2.445656754617732, 51.649331501408057],
            [-2.449208160976914, 51.6497873089615],
            [-2.452520802250564, 51.651368741020427],
            [-2.455951498494997, 51.652304046135498],
            [-2.455800758680173, 51.651830790364407],
            [-2.458780003975429, 51.651278806604374],
            [-2.460442611962514, 51.652599411651643],
            [-2.462748987062245, 51.65197533707618],
            [-2.463714438825745, 51.650974392011506],
            [-2.465790742710869, 51.651042596980197],
            [-2.470335717090147, 51.647472009309986],
            [-2.470962790556042, 51.647593574890294],
            [-2.473060180530847, 51.646785813253665],
            [-2.474718466222161, 51.646562427533631],
            [-2.482152709006294, 51.646406246186466],
            [-2.485572346160849, 51.645067728818816],
            [-2.487262057441959, 51.644686687694588],
            [-2.490675759243774, 51.644831612855654],
            [-2.490835128120656, 51.645135752122435],
            [-2.490050639944627, 51.645696491576643],
            [-2.487947861937001, 51.646508176270608],
            [-2.487569064065113, 51.647033943867413],
            [-2.486435393260526, 51.647385712151156],
            [-2.484970755822538, 51.648416782096852],
            [-2.489350736303924, 51.64887873949187],
            [-2.49184520741262, 51.649662246173385],
            [-2.493034448998221, 51.651898789955034],
            [-2.491403794806978, 51.653750643384633],
            [-2.49170195390413, 51.655922592263664],
            [-2.4927050469481, 51.657775988455874],
            [-2.49180200474804, 51.660355781163879],
            [-2.491678723906049, 51.662855876370251],
            [-2.491142021706421, 51.663227664980134],
            [-2.489816449063796, 51.663382458740379],
            [-2.489640491843093, 51.66395863513992],
            [-2.490835682984524, 51.664313295442923],
            [-2.492540156657049, 51.664164993283876],
            [-2.495195533580992, 51.664639351822608],
            [-2.496423529930705, 51.664418374545861],
            [-2.498637568851098, 51.664987131037662],
            [-2.500076285041128, 51.664864124518367],
            [-2.500844878934441, 51.664416678306793],
            [-2.501673949480123, 51.664733228192489],
            [-2.50136428426336, 51.66536484041319],
            [-2.505351331803874, 51.666215404548367],
            [-2.509005562629944, 51.665998212720574],
            [-2.509861327316301, 51.667911441858607],
            [-2.532088734815307, 51.675830337300816],
            [-2.534741799979227, 51.677242502916172],
            [-2.527256753463901, 51.68079014503725],
            [-2.523574085213718, 51.681772287110178],
            [-2.519167452790263, 51.682323238432581],
            [-2.518894205362617, 51.682586991330055],
            [-2.517367985154303, 51.682564954225064],
            [-2.516585263519875, 51.683181604890549],
            [-2.512545382251986, 51.684334906031424],
            [-2.508648493116909, 51.68626159571096],
            [-2.497347933916881, 51.69323146955378],
            [-2.490047107203633, 51.700146755245243],
            [-2.488761711763113, 51.699967792110215],
            [-2.489974902262952, 51.699222757911677],
            [-2.489387953626094, 51.698063538185302],
            [-2.48740550096363, 51.698180577450273],
            [-2.486571482830297, 51.69920364200852],
            [-2.486039917676296, 51.699288562719588],
            [-2.484580319526201, 51.697021611436973],
            [-2.484070327007213, 51.696685644511724],
            [-2.483347655823095, 51.697028490066877],
            [-2.485331019192968, 51.699028051678681],
            [-2.484726690065312, 51.699750745413112],
            [-2.487055352649994, 51.69952801775657],
            [-2.488087981996643, 51.698540988504398],
            [-2.489070837041412, 51.698443390312455],
            [-2.489169467113018, 51.699136203548711],
            [-2.487864540099396, 51.699828562323162],
            [-2.48899290078666, 51.700876742144125],
            [-2.488990490797842, 51.701592454020577],
            [-2.486220152201368, 51.705031412316359],
            [-2.485461010716558, 51.707384858035198],
            [-2.484682601350162, 51.707943730554994],
            [-2.485045361935209, 51.708709183626304],
            [-2.484442166751846, 51.709821189422875],
            [-2.483563071684366, 51.716005369975761],
            [-2.482634201533359, 51.717131293057228],
            [-2.483105687462366, 51.718858362652277],
            [-2.482281294524902, 51.719062254580642],
            [-2.483031854335634, 51.719265068577052],
            [-2.48244821406489, 51.722774933403649],
            [-2.482758153195411, 51.723874181945263],
            [-2.482134563059592, 51.725270378074846],
            [-2.481699579988225, 51.725209224507466],
            [-2.481854799365504, 51.72564645876767],
            [-2.474594485178078, 51.72540268798052],
            [-2.472301469464644, 51.725983770576974],
            [-2.465516416019714, 51.730003842376497],
            [-2.462243899914861, 51.732963174892006],
            [-2.45692897683226, 51.736626293257963],
            [-2.451503432894117, 51.739303262835833],
            [-2.449454947066287, 51.739816422066895],
            [-2.448109621618846, 51.739971714388794],
            [-2.445921723501649, 51.738982921224782],
            [-2.43664927083474, 51.736777160082916],
            [-2.432999275388631, 51.736706155323773],
            [-2.43262246071819, 51.736220223016218],
            [-2.432304510693978, 51.736741981758506],
            [-2.429826652930389, 51.737342693577553],
            [-2.429135967871938, 51.738268610748385],
            [-2.42810814053141, 51.738615823534467],
            [-2.426183757600084, 51.739106545732973],
            [-2.415942483392347, 51.743574994850327],
            [-2.411760949926876, 51.74658647460695],
            [-2.410041014714287, 51.7484365730758],
            [-2.409211065786192, 51.74860220680484],
            [-2.409211867722712, 51.749011300589309],
            [-2.407714285601696, 51.748872649996301],
            [-2.405758122421452, 51.749123083108351],
            [-2.399504752619659, 51.750922962994132],
            [-2.397379503467627, 51.751894020569196],
            [-2.395942551456288, 51.753062324388431],
            [-2.394347837413108, 51.753590972152587],
            [-2.391152832806438, 51.755356721275334],
            [-2.390132992792357, 51.756708780404701],
            [-2.388759722669381, 51.756728618085887],
            [-2.382668569353207, 51.758383203025176],
            [-2.381740320293674, 51.758951764121122],
            [-2.382037707115594, 51.7591674831124],
            [-2.38313638951126, 51.758536325262916],
            [-2.380942262112641, 51.760358766191601],
            [-2.379739837666124, 51.762621225321048],
            [-2.379328527855203, 51.766272941632181],
            [-2.380773531852995, 51.770091281236844],
            [-2.384063629493955, 51.77508681089413],
            [-2.38373599265454, 51.77576041473538],
            [-2.384459772471667, 51.775312090631502],
            [-2.397779227607046, 51.780551811523296],
            [-2.4029605493309, 51.782089613258513],
            [-2.405385820282916, 51.782424730050678],
            [-2.408266119989031, 51.784023261676985],
            [-2.413126362242765, 51.785560831549219],
            [-2.413039528255696, 51.786052948776337],
            [-2.413626904744486, 51.78560402603091],
            [-2.417683724736241, 51.786290979660834],
            [-2.428754962951716, 51.786410246558276],
            [-2.428923691372112, 51.786927517043743],
            [-2.429306712300791, 51.78649994108428],
            [-2.430157196691753, 51.786446480737595],
            [-2.435185462324401, 51.786960238335084],
            [-2.440537090440278, 51.78821253525912],
            [-2.445521152100683, 51.790534123229236],
            [-2.4470751690279, 51.792855112366077],
            [-2.446964589826274, 51.795601407111832],
            [-2.444118420027338, 51.79975528191023],
            [-2.438041053699239, 51.806959249206159],
            [-2.434272674165532, 51.810400621696758],
            [-2.427979978639252, 51.814487655092663],
            [-2.426223140602888, 51.814935499083909],
            [-2.42433937454673, 51.815001656040188],
            [-2.4214491169207, 51.813542020842391],
            [-2.41986684965258, 51.812018308260704],
            [-2.418246330344681, 51.809328572074605],
            [-2.41525828930153, 51.806795601184206],
            [-2.41260938463339, 51.80531151802812],
            [-2.406928287380381, 51.803364984297787],
            [-2.403467596479467, 51.801540944082056],
            [-2.401246330018576, 51.800939855112581],
            [-2.399340274372078, 51.800555236931203],
            [-2.397012690522288, 51.800550535984506],
            [-2.390004045190668, 51.799384489112718],
            [-2.380715993385526, 51.794753087007088],
            [-2.374883819951458, 51.792795604274652],
            [-2.370285379501736, 51.7920018892668],
            [-2.367049872764328, 51.791907744639296],
            [-2.362174892789426, 51.792761737573748],
            [-2.361153115530101, 51.792451993891149],
            [-2.361153584465586, 51.792692053609535],
            [-2.356451270135868, 51.793402320336185],
            [-2.354113137400133, 51.794038763730299],
            [-2.350409170817028, 51.79543898522887],
            [-2.34751825192783, 51.797754673242238],
            [-2.347147934508108, 51.797683839545407],
            [-2.347166677670132, 51.798235834223355],
            [-2.346072206000014, 51.799228976509347],
            [-2.345212229589894, 51.80093081266061],
            [-2.345440488198258, 51.802523353614632],
            [-2.345977887923559, 51.803196999318821],
            [-2.345806418673578, 51.803910493078163],
            [-2.347116698383056, 51.805695845341695],
            [-2.348818952588003, 51.806388508683355],
            [-2.350047855887574, 51.807381060531576],
            [-2.354555209510307, 51.808981437283592],
            [-2.359348966390435, 51.811170577101883],
            [-2.359676457258041, 51.816956196598504],
            [-2.361676997577487, 51.822208900759165],
            [-2.361089425408644, 51.82493318439893],
            [-2.359764922332466, 51.826599682689817],
            [-2.357383546976391, 51.827300154034177],
            [-2.351068372071016, 51.828200304788929],
            [-2.349951652868945, 51.828652285849593],
            [-2.348996338654704, 51.829525454785639],
            [-2.348580080303035, 51.829364852137786],
            [-2.349635373191666, 51.831281294886622],
            [-2.354732420601799, 51.833565918009391],
            [-2.35583652042596, 51.835184555155166],
            [-2.355190673983564, 51.839421262371289],
            [-2.354405907768998, 51.841511336584425],
            [-2.353698040610542, 51.843236139790214],
            [-2.351453787968499, 51.846010292574064],
            [-2.342950822340968, 51.847444291929641],
            [-2.336827442049316, 51.849559589103812],
            [-2.332782426755247, 51.850091686619997],
            [-2.329625137181155, 51.849737347663222],
            [-2.325229359176087, 51.847946904337697],
            [-2.322753124167742, 51.847389996259771],
            [-2.311648503889279, 51.847080104679691],
            [-2.307974833146778, 51.846517962594156],
            [-2.305945032853765, 51.845819271298183],
            [-2.304655712301392, 51.844490159696555],
            [-2.304150439219668, 51.841029047474123],
            [-2.302171189196053, 51.835047963318367],
            [-2.30103250129644, 51.833611429933185],
            [-2.298515941227417, 51.832279092203983],
            [-2.300020657958834, 51.832252783026952],
            [-2.301355443115724, 51.831771047704393],
            [-2.300036029618869, 51.830200102506438],
            [-2.301108652731174, 51.829800856741528],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000083",
        LAD13CDO: "23UG",
        LAD13NM: "Tewkesbury",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.155261190461073, 52.048507394498444],
            [-2.149496048556403, 52.04692172244431],
            [-2.1472251778937, 52.046753764420366],
            [-2.14152906784445, 52.04562613814597],
            [-2.13372452383792, 52.046324851417054],
            [-2.1263216250622, 52.043213287049127],
            [-2.120451695103056, 52.042145065576349],
            [-2.118391446144548, 52.042098608622794],
            [-2.117217601521911, 52.039252450335958],
            [-2.116097245459525, 52.037860012910606],
            [-2.116249359403481, 52.036930230342293],
            [-2.115604755947243, 52.036269154689009],
            [-2.117163621655074, 52.033673810567272],
            [-2.117930217877857, 52.033061680113413],
            [-2.125893613380072, 52.030494684166001],
            [-2.129300887203208, 52.02978342244036],
            [-2.136546467999504, 52.029292434317895],
            [-2.139577766593318, 52.027752372525619],
            [-2.141179600907493, 52.024527308825888],
            [-2.142369103238889, 52.019568421278876],
            [-2.144653483216166, 52.016682324841831],
            [-2.145691183411023, 52.014059362391556],
            [-2.149514657076376, 52.011479632363105],
            [-2.150789176931402, 52.006618538554271],
            [-2.146295461415592, 52.00456984161039],
            [-2.145414706451689, 52.004749848869615],
            [-2.143743125009486, 52.004086592781249],
            [-2.14247880805185, 52.004129488742109],
            [-2.141735816891201, 52.004573627889357],
            [-2.139070894122819, 52.003940268922364],
            [-2.140825748814416, 52.002386386200151],
            [-2.139503336684443, 52.001534745455572],
            [-2.139150921202963, 52.00062800157832],
            [-2.139714852538839, 52.00022994389672],
            [-2.141864934276065, 51.999805705512998],
            [-2.13932774234643, 51.999405956022564],
            [-2.136854331660294, 51.999844010975025],
            [-2.135209373575345, 51.999694870468829],
            [-2.133254680058995, 52.001675055930875],
            [-2.133433405586503, 52.002503795437562],
            [-2.132357588671007, 52.002733375179943],
            [-2.132692114726493, 52.003051268703224],
            [-2.131933004672439, 52.003984458004091],
            [-2.132035977905145, 52.005312267674952],
            [-2.131530735538782, 52.00589722891285],
            [-2.130776747273327, 52.00611474566243],
            [-2.130716537816442, 52.006449266281805],
            [-2.129638025878203, 52.006288628062975],
            [-2.12787500106652, 52.007211205467648],
            [-2.127154033634095, 52.007271326005927],
            [-2.125863859656241, 52.009036687073667],
            [-2.123867652434552, 52.009999911489487],
            [-2.124063476681906, 52.010214582325496],
            [-2.123367476474194, 52.010895010384083],
            [-2.121124801360626, 52.011283042773265],
            [-2.120381373849179, 52.011687488010544],
            [-2.120077914918689, 52.013165866086439],
            [-2.119503286624992, 52.013503602077527],
            [-2.11914265696936, 52.013226156189006],
            [-2.119048820080993, 52.01355351193456],
            [-2.118279981718522, 52.013736798805809],
            [-2.118552979260716, 52.013936116831843],
            [-2.118061650613781, 52.014375356343407],
            [-2.117363165427759, 52.014167472100247],
            [-2.116352970833696, 52.014556873579153],
            [-2.115925436384488, 52.014321740281652],
            [-2.113899333523311, 52.014592542239811],
            [-2.113617616276585, 52.014969524188793],
            [-2.11166166905762, 52.015343612540185],
            [-2.111578687971627, 52.014788966710682],
            [-2.108731727067536, 52.014269276735632],
            [-2.107611977140397, 52.014576889389815],
            [-2.105359398762468, 52.013989142109132],
            [-2.102383692043266, 52.013822747640639],
            [-2.099743529734795, 52.013182185010017],
            [-2.099385827011251, 52.012854328397658],
            [-2.098567064138131, 52.012893678005092],
            [-2.098344929668234, 52.013253491218045],
            [-2.097681539604911, 52.013053552515977],
            [-2.097719541948124, 52.011774147452712],
            [-2.097385382408774, 52.011540667245313],
            [-2.096897573710337, 52.011670536342734],
            [-2.096799358395743, 52.011394603451706],
            [-2.095668538353385, 52.011980822894891],
            [-2.095542474335869, 52.011623995536461],
            [-2.093928482052017, 52.011790725506366],
            [-2.094155098926427, 52.011470475935567],
            [-2.093385402306122, 52.011274194418846],
            [-2.093058202248633, 52.011572045539893],
            [-2.092363709004927, 52.01110328017262],
            [-2.091366317350619, 52.011413339512053],
            [-2.086870244203053, 52.010750541329934],
            [-2.086155617767135, 52.011134969725369],
            [-2.084880960083049, 52.010468789429787],
            [-2.084590265991576, 52.010836717969518],
            [-2.083487798723878, 52.011079356151726],
            [-2.08077870716468, 52.010755788324971],
            [-2.078852794919409, 52.011688531745158],
            [-2.078134886836493, 52.011076746481372],
            [-2.076756514734792, 52.011030904152108],
            [-2.07676780652708, 52.011667436793516],
            [-2.07617372949449, 52.011887196273626],
            [-2.075728738488682, 52.011528756110827],
            [-2.074597100899945, 52.011792008419512],
            [-2.072486966062012, 52.011539793268007],
            [-2.071623996449105, 52.012177762031811],
            [-2.070992505305977, 52.011795142831424],
            [-2.070192484426665, 52.012638949469064],
            [-2.069478340991227, 52.012508110523143],
            [-2.068138805842765, 52.013111271158259],
            [-2.066865291752472, 52.013067949381664],
            [-2.066737717431997, 52.013499574397635],
            [-2.063875565850496, 52.014162880979136],
            [-2.062881451318364, 52.013883808051474],
            [-2.06240425420597, 52.014398329369797],
            [-2.060791640114935, 52.014670694444],
            [-2.060246052229195, 52.014198066535918],
            [-2.059718763441436, 52.009967323859989],
            [-2.059301412229286, 52.009464057535418],
            [-2.057202440342348, 52.009812140357255],
            [-2.053220698978628, 52.008627245512557],
            [-2.051564348801801, 52.008743066576777],
            [-2.050167115157322, 52.007382483703118],
            [-2.049307155102968, 52.007003441856881],
            [-2.048691024059689, 52.007105294233078],
            [-2.050097102266411, 52.006009636156477],
            [-2.049522111127875, 52.003786483647303],
            [-2.044962388762602, 52.003639077024879],
            [-2.038364230115114, 52.004590842055649],
            [-2.036844566862326, 52.006033436608597],
            [-2.037161184825066, 52.006622226283596],
            [-2.036790011630123, 52.007006245813379],
            [-2.03795626850612, 52.007930115399844],
            [-2.038260690309348, 52.009552834133324],
            [-2.030853853285777, 52.009760909988053],
            [-2.027005149605197, 52.010578217324898],
            [-2.027393270596551, 52.011505065652351],
            [-2.028191921507274, 52.011858209021511],
            [-2.028091690081708, 52.012334738988393],
            [-2.029279479579572, 52.012829833289011],
            [-2.021667892856187, 52.015644677463861],
            [-2.02366775151478, 52.016943439859219],
            [-2.019651134134975, 52.018683881269183],
            [-2.016985028971519, 52.020246879171971],
            [-2.013432544395624, 52.021337913008388],
            [-2.007694136349246, 52.023875604363155],
            [-1.998573669529942, 52.029657756154549],
            [-1.997566475294624, 52.02988790250938],
            [-1.995198244124856, 52.026553202874666],
            [-1.99394762554267, 52.027384783422967],
            [-1.994001498631314, 52.027810043867568],
            [-1.992587476804046, 52.029278139707408],
            [-1.984144449391184, 52.035869265081786],
            [-1.963596907839252, 52.036116492866128],
            [-1.956709360237092, 52.037136414846699],
            [-1.954229423555752, 52.037324286638615],
            [-1.954031399960828, 52.037088655303464],
            [-1.951396452559879, 52.037715150336972],
            [-1.951041862254563, 52.036682879171302],
            [-1.950533118277834, 52.036656594996181],
            [-1.950851673789957, 52.035964448390118],
            [-1.95043674804303, 52.035472487539074],
            [-1.94919944688849, 52.035171676011501],
            [-1.948012754744398, 52.033979002035196],
            [-1.94752977498237, 52.031884868038695],
            [-1.945821685363405, 52.031659332743288],
            [-1.942253957329713, 52.032350825116204],
            [-1.939923268139535, 52.0322678502353],
            [-1.933772604737768, 52.030832357601454],
            [-1.931739118389213, 52.02994921906825],
            [-1.93107985638749, 52.030232941296582],
            [-1.929630715044985, 52.032285551887036],
            [-1.927053784429958, 52.036650745661902],
            [-1.92320693034453, 52.03563146874567],
            [-1.922166130434872, 52.035604715346722],
            [-1.920537715894352, 52.038130001762525],
            [-1.920059436474909, 52.038189017554259],
            [-1.919744711936589, 52.038909853396532],
            [-1.919148555177714, 52.038846512759193],
            [-1.916693321895566, 52.042088628834613],
            [-1.91569827629597, 52.041686941265745],
            [-1.913454978512731, 52.044450837964533],
            [-1.909117990557367, 52.042650355989331],
            [-1.903248922972949, 52.03888131819528],
            [-1.896440245893229, 52.035895149480105],
            [-1.891342550215916, 52.032819366306349],
            [-1.888019541071049, 52.031451482228853],
            [-1.885023294411063, 52.029490439555325],
            [-1.879315027397697, 52.026936792375892],
            [-1.876442520485696, 52.025275045110583],
            [-1.873582817189991, 52.024318108884103],
            [-1.867305639434048, 52.017954819178108],
            [-1.862278333277922, 52.017475252670266],
            [-1.863803098576758, 52.014406706097205],
            [-1.862028714911063, 52.013347341661749],
            [-1.861553052234788, 52.012617640561544],
            [-1.859894621446521, 52.012727168154143],
            [-1.857115135434798, 52.011190025810663],
            [-1.856764316930186, 52.010634875404683],
            [-1.854811969527311, 52.010191946366511],
            [-1.851079418450181, 52.00839275456763],
            [-1.847263461062772, 52.007647044815954],
            [-1.844485832665753, 52.00831502162837],
            [-1.843895453423297, 52.007994173597922],
            [-1.843290560214887, 52.008077884352296],
            [-1.841317716048273, 52.006907357256345],
            [-1.840034311809618, 52.007297618574079],
            [-1.839060102189144, 52.006773035644997],
            [-1.837194348637651, 52.005845333890683],
            [-1.833030659271854, 52.005886267095278],
            [-1.829726569938817, 52.005133519375327],
            [-1.826207028050841, 52.004748081149309],
            [-1.821098235172731, 52.003263273286841],
            [-1.817940441998476, 52.001373991494631],
            [-1.806884709104309, 52.002662760140161],
            [-1.806319663087584, 52.000937416860282],
            [-1.804805902292342, 51.998996525403228],
            [-1.801689401455715, 51.996884803740812],
            [-1.805195589027967, 51.99612823404626],
            [-1.808248666896033, 51.994354873608835],
            [-1.81022876721019, 51.991747171845795],
            [-1.812208988788061, 51.988009302102867],
            [-1.820407082846571, 51.987390021021611],
            [-1.823276937648588, 51.988212511160967],
            [-1.824799438123016, 51.989143523707206],
            [-1.831956204788561, 51.987784648582902],
            [-1.842522499383518, 51.987784840485439],
            [-1.847168418748214, 51.988467052012147],
            [-1.854070644067676, 51.990815174538447],
            [-1.85757109853435, 51.99143981063397],
            [-1.861767251990698, 51.993186301223332],
            [-1.865363029637727, 51.995148634366494],
            [-1.867529205365826, 51.994013760943602],
            [-1.869394722921526, 51.993564506364898],
            [-1.874324359062728, 51.991059651539466],
            [-1.876188266311398, 51.989538594392201],
            [-1.87849765429658, 51.988555611633714],
            [-1.88343548132806, 51.983604006878558],
            [-1.871645610701023, 51.981562568477671],
            [-1.868474369847007, 51.980581784767395],
            [-1.86957297506574, 51.978800142134752],
            [-1.872002109904649, 51.977545897230605],
            [-1.872757106663353, 51.976730354971934],
            [-1.873024085331591, 51.97446857319531],
            [-1.875421235645854, 51.971501486340685],
            [-1.875234617482735, 51.970030403520781],
            [-1.876368000108492, 51.968113870056456],
            [-1.87423685843364, 51.965571731426806],
            [-1.874508925072128, 51.965089217655716],
            [-1.877295043539512, 51.964433125203009],
            [-1.879982287361863, 51.964256972539729],
            [-1.881983666100151, 51.965317202305847],
            [-1.890793621255018, 51.960556086515759],
            [-1.892706704421856, 51.960229678141076],
            [-1.89707974647592, 51.960316286509823],
            [-1.899616692047193, 51.961480975659562],
            [-1.902826850665658, 51.962326994024259],
            [-1.901581062430151, 51.964314716869829],
            [-1.901281412151019, 51.966228597476139],
            [-1.900627331682368, 51.967139710699669],
            [-1.903130210891533, 51.968122680907094],
            [-1.904087233182527, 51.969157397746535],
            [-1.906527019345577, 51.968367269412191],
            [-1.908927761417117, 51.968128193074797],
            [-1.910286349820306, 51.968590458041398],
            [-1.910939813919198, 51.969375844516094],
            [-1.913086723920764, 51.968686055492547],
            [-1.913576718573874, 51.968209006449669],
            [-1.914667092034465, 51.966595061099532],
            [-1.914731149148565, 51.965818306720173],
            [-1.915344514044935, 51.965499576275164],
            [-1.915601327863351, 51.963599115358733],
            [-1.916642127715329, 51.962721457816755],
            [-1.920569193728885, 51.963324745591081],
            [-1.922860539118382, 51.96302507347319],
            [-1.92611015378177, 51.961442979210865],
            [-1.927622449112713, 51.960426161487391],
            [-1.928384308700607, 51.959053736927636],
            [-1.931670058088711, 51.957315970004267],
            [-1.931050227386711, 51.954396309296683],
            [-1.928481731713642, 51.949924572746767],
            [-1.92696113770554, 51.944612791048243],
            [-1.924157638913973, 51.940326017196384],
            [-1.923196636148911, 51.934880573002154],
            [-1.918363166263935, 51.93577551195893],
            [-1.916296031654559, 51.934340932234043],
            [-1.907244619734166, 51.932297753343086],
            [-1.906421957996124, 51.932038169103578],
            [-1.9063389668009, 51.931380875698522],
            [-1.896618605188913, 51.929777794438621],
            [-1.894495085452413, 51.9286152022176],
            [-1.891374432941239, 51.927898501020408],
            [-1.889301969468192, 51.926762834090113],
            [-1.887215128990261, 51.926184547007416],
            [-1.890922241279019, 51.922449651461406],
            [-1.890832044398586, 51.921265477355547],
            [-1.891986203226819, 51.919596045100455],
            [-1.891412927730447, 51.91855258361835],
            [-1.890340769766732, 51.918192858285835],
            [-1.890471400790297, 51.917686796230853],
            [-1.886799754300212, 51.91728054195525],
            [-1.886669789899257, 51.914611040615789],
            [-1.887870184051899, 51.91187987390898],
            [-1.883662935288395, 51.910780716692074],
            [-1.883249711681217, 51.91092236367961],
            [-1.877211290505974, 51.909187295114926],
            [-1.871738890113642, 51.908951306013137],
            [-1.868414055945819, 51.908059348535538],
            [-1.871213889223144, 51.903938338988368],
            [-1.877139513274534, 51.900845514687724],
            [-1.880423135906196, 51.897955632660171],
            [-1.880744509002487, 51.896782651284994],
            [-1.878547431157134, 51.896683308214847],
            [-1.878534218147071, 51.897273094822822],
            [-1.872977182442491, 51.896638780050473],
            [-1.869008544594305, 51.897059706396909],
            [-1.866818910368845, 51.896904409327902],
            [-1.862127525730356, 51.897450157219332],
            [-1.85703878927943, 51.896772480307646],
            [-1.854863543227374, 51.895775433712771],
            [-1.853618485499059, 51.893421884841452],
            [-1.855230950511052, 51.893138865334343],
            [-1.855836157830883, 51.893376066618849],
            [-1.858665450588518, 51.892451634737817],
            [-1.860421874451904, 51.890705003421694],
            [-1.862059613423328, 51.88971343973077],
            [-1.869074531836346, 51.89184597904427],
            [-1.873395388931534, 51.893648869638696],
            [-1.872611608677987, 51.890228799626072],
            [-1.874790513409605, 51.889951517099085],
            [-1.880495699814933, 51.890856522572356],
            [-1.882895908481855, 51.891524254015494],
            [-1.883509425168359, 51.891968111134609],
            [-1.883423793835, 51.892491294811876],
            [-1.882186896277027, 51.894270256598176],
            [-1.896025568397979, 51.894292255806988],
            [-1.895363665678042, 51.893340437626158],
            [-1.896647524723897, 51.891120826062149],
            [-1.897702564107262, 51.891077690254441],
            [-1.898305276314864, 51.88992827956077],
            [-1.899421612507471, 51.889098480300497],
            [-1.900223230668451, 51.889302354462814],
            [-1.901676902963491, 51.887003710425702],
            [-1.902864565618177, 51.88740119141881],
            [-1.903145517872551, 51.887154173420448],
            [-1.905994441406325, 51.888011508904434],
            [-1.907528232898213, 51.887576663496567],
            [-1.912168998333166, 51.887592795323251],
            [-1.914954328126108, 51.889148456815704],
            [-1.92167161664154, 51.894204170697741],
            [-1.927326761480946, 51.896422233799953],
            [-1.928348660337815, 51.89901042546856],
            [-1.927421388227675, 51.900845790821094],
            [-1.926744812802559, 51.901304805496125],
            [-1.926684953518162, 51.903262974558331],
            [-1.927250545929479, 51.903145544174009],
            [-1.929875799209716, 51.903904160682067],
            [-1.929416724414169, 51.904686989691513],
            [-1.933086248135551, 51.905110795245278],
            [-1.93576934983924, 51.906226249070798],
            [-1.938871828075744, 51.906895014458414],
            [-1.940090687666945, 51.906331912835846],
            [-1.939551778593547, 51.904968627173218],
            [-1.942367405318372, 51.904865739434719],
            [-1.942484629176336, 51.905268586178288],
            [-1.945727260389555, 51.90545622913276],
            [-1.952805050538216, 51.905018712829808],
            [-1.953217348271268, 51.904101810697902],
            [-1.952799772227529, 51.903109954974624],
            [-1.953305842391672, 51.902856613903978],
            [-1.95756648992812, 51.902281005311721],
            [-1.960589513306708, 51.90245017835889],
            [-1.962176403624458, 51.902786053927279],
            [-1.965763873879831, 51.904285917481985],
            [-1.972546477986144, 51.905836801409045],
            [-1.976046424149074, 51.906276310758685],
            [-1.981151969125576, 51.908380184190293],
            [-1.981173546960035, 51.908930426654734],
            [-1.986771033512233, 51.910893874957615],
            [-1.994177260621098, 51.914969100754689],
            [-1.994930525629987, 51.913402932074632],
            [-1.996304477727492, 51.912022888046494],
            [-1.998072423209565, 51.907386353934797],
            [-2.000428677171915, 51.904066945989207],
            [-2.009677724580538, 51.905860200661159],
            [-2.010223578587406, 51.902916552009678],
            [-2.019265496107119, 51.902673545279647],
            [-2.023606835605244, 51.902399423189344],
            [-2.024648771417917, 51.902058454254629],
            [-2.029911584569779, 51.904088254053988],
            [-2.03378687310829, 51.904409068823384],
            [-2.036560891126759, 51.9052767487862],
            [-2.03790831918491, 51.905268227745033],
            [-2.0375439549543, 51.907565504488453],
            [-2.036395486601207, 51.907430106164213],
            [-2.036220172002574, 51.908136840344959],
            [-2.034597756245204, 51.907933240096128],
            [-2.033814625610508, 51.908429763552519],
            [-2.033178501857253, 51.90920135959616],
            [-2.033576054602968, 51.910133595353798],
            [-2.033369029745239, 51.911277288447714],
            [-2.032680079272925, 51.911442914570728],
            [-2.032706487624936, 51.911771971555019],
            [-2.033684832446816, 51.911730335628761],
            [-2.033671088508267, 51.912783165672316],
            [-2.036660188231453, 51.912923421960926],
            [-2.036517250228946, 51.914131833881882],
            [-2.038030548299795, 51.913968617325629],
            [-2.040534599027669, 51.914591739370088],
            [-2.040602533660988, 51.91575692668782],
            [-2.039737014145545, 51.918512011759297],
            [-2.039951315905074, 51.922402265075014],
            [-2.046493810786629, 51.922895241998724],
            [-2.048486733639299, 51.923622689351255],
            [-2.052599002564834, 51.926073607544886],
            [-2.055030176748828, 51.927033608060128],
            [-2.060214573535326, 51.928120744521756],
            [-2.06249658437691, 51.927251937260152],
            [-2.064461956979704, 51.928732557875293],
            [-2.067224989922201, 51.929608508796697],
            [-2.068402163509568, 51.930015111453557],
            [-2.068401428175325, 51.930486229935354],
            [-2.068764704335877, 51.930281926276173],
            [-2.070706809702455, 51.930627816474562],
            [-2.071906191620653, 51.931242957237274],
            [-2.073918628629276, 51.930982771273044],
            [-2.077028080207903, 51.931828603419817],
            [-2.078813876677087, 51.931709637955038],
            [-2.078884271359645, 51.93204135118993],
            [-2.081893895615378, 51.931492648667145],
            [-2.083559894920275, 51.931879876844995],
            [-2.085841216137587, 51.931546469848122],
            [-2.08962339187813, 51.931923953735861],
            [-2.090979012751132, 51.932697917899596],
            [-2.091299724685693, 51.933774766640518],
            [-2.091810817055622, 51.934055780014312],
            [-2.091698684245321, 51.934699608752993],
            [-2.092451940895619, 51.934616302622779],
            [-2.095068941929261, 51.936163326748442],
            [-2.102816319817543, 51.936375274792319],
            [-2.104943319070543, 51.937188858364628],
            [-2.105291643374496, 51.937478949223461],
            [-2.105040554296488, 51.93771563193684],
            [-2.106572080475806, 51.938873165274515],
            [-2.106829750671741, 51.937735595941646],
            [-2.105759438846668, 51.936615412641508],
            [-2.105164161740178, 51.93646040645497],
            [-2.105867044175121, 51.934752422690273],
            [-2.106799571440185, 51.934834293691729],
            [-2.106928734335776, 51.934103224262181],
            [-2.107689114962559, 51.933973961060389],
            [-2.107127897643868, 51.931624278990185],
            [-2.106569144063771, 51.931512402327627],
            [-2.106645999645599, 51.930804756336627],
            [-2.106100423222039, 51.930725232364971],
            [-2.106626404805896, 51.929902996430755],
            [-2.105997128682338, 51.928247459548928],
            [-2.106370903052877, 51.927029766075385],
            [-2.105485078819381, 51.926331080102464],
            [-2.105218490535162, 51.924891890311045],
            [-2.10415295172188, 51.924426217049088],
            [-2.103257625942588, 51.923352605483373],
            [-2.105141445886504, 51.921799118877438],
            [-2.106260587559368, 51.919745507429056],
            [-2.109628735625204, 51.915845788141993],
            [-2.110714010195891, 51.916119889258788],
            [-2.112519422272987, 51.916570411859375],
            [-2.113918805942649, 51.916282255389859],
            [-2.114945647940763, 51.916434098911125],
            [-2.114884342233043, 51.916907975438356],
            [-2.11584208373741, 51.91731971356112],
            [-2.118934716703258, 51.917912714229814],
            [-2.119483003991253, 51.918514542970144],
            [-2.121361755572162, 51.919133882683902],
            [-2.123603713515489, 51.919098278851301],
            [-2.124504783077954, 51.91947673996512],
            [-2.124968746925155, 51.91900332999505],
            [-2.128883879156908, 51.913776327878359],
            [-2.130325056936507, 51.909919467848411],
            [-2.131460401944913, 51.908432017063632],
            [-2.132984402424751, 51.906662702190474],
            [-2.133467586867296, 51.904904444106407],
            [-2.135380815166209, 51.90213487170012],
            [-2.136300213434385, 51.90000927020413],
            [-2.137878132262571, 51.898442120899297],
            [-2.139605655617486, 51.894543442147814],
            [-2.141436835123812, 51.891818817840722],
            [-2.143003149891741, 51.889033348486841],
            [-2.142423574973082, 51.884501752798656],
            [-2.126083907162298, 51.887383124775567],
            [-2.124407745648078, 51.886011107021467],
            [-2.127144718692484, 51.884351157269954],
            [-2.127515497602674, 51.884455948511523],
            [-2.127392078829217, 51.883969676007368],
            [-2.128602285721795, 51.882437210325818],
            [-2.127731581278808, 51.87971302427809],
            [-2.12603062902821, 51.87925992337707],
            [-2.123257801162775, 51.883111862989018],
            [-2.121970409374773, 51.882508121122527],
            [-2.117955609767076, 51.881693153767372],
            [-2.113995875802107, 51.880192890742457],
            [-2.107715479492384, 51.878887950555139],
            [-2.105908561394809, 51.878369921641401],
            [-2.104280864771017, 51.876815955801476],
            [-2.102275806996928, 51.875986962122155],
            [-2.097526219795542, 51.878856400271381],
            [-2.097064746488787, 51.878407238690819],
            [-2.097048207773242, 51.876802379390391],
            [-2.09625146480105, 51.875795157151934],
            [-2.098017860686819, 51.875148149281422],
            [-2.097470435129889, 51.874567793787854],
            [-2.095667619261242, 51.875158180608501],
            [-2.095174225197013, 51.874699147388668],
            [-2.093177079275979, 51.875458682180401],
            [-2.092373234118844, 51.873769030564851],
            [-2.092776967058, 51.872327472072456],
            [-2.092295269611665, 51.871168925766916],
            [-2.089900660093448, 51.872131012519368],
            [-2.085719415906455, 51.873119543958325],
            [-2.084442416854619, 51.872215986446768],
            [-2.08353451695389, 51.8729431005967],
            [-2.081828345833528, 51.872430024292363],
            [-2.081311723583819, 51.871882838234718],
            [-2.082412824367367, 51.871161899141093],
            [-2.081744395070079, 51.870994237186984],
            [-2.081057890208935, 51.871270734099134],
            [-2.080725978326829, 51.870851088266647],
            [-2.079807265590938, 51.87123293094762],
            [-2.08029315319668, 51.871664162189177],
            [-2.079350061627699, 51.872234826670621],
            [-2.079806170307968, 51.873069771016141],
            [-2.078124189481836, 51.873081692612594],
            [-2.076832510259046, 51.872846984672073],
            [-2.077021968944746, 51.87236944404188],
            [-2.076078755835071, 51.872043690396893],
            [-2.076687322868965, 51.870330528721709],
            [-2.076249262308043, 51.868954308198148],
            [-2.074798738881652, 51.868320485122474],
            [-2.072239957792225, 51.868484824918852],
            [-2.070677854471908, 51.868887670227771],
            [-2.069941493327947, 51.868881818264178],
            [-2.069560215180442, 51.868392940326345],
            [-2.069942948525142, 51.867018004155447],
            [-2.071258111083379, 51.865704540532718],
            [-2.070764713513694, 51.865019732654915],
            [-2.070296159394635, 51.864424816774509],
            [-2.070681330661592, 51.861865773355731],
            [-2.07193329411154, 51.860185509945531],
            [-2.074503907258475, 51.858682422179356],
            [-2.07461500397491, 51.858254384056508],
            [-2.079985096058576, 51.856897711066608],
            [-2.080494096699068, 51.856536827040131],
            [-2.080011509381278, 51.856237759782829],
            [-2.080109299297941, 51.855708127987356],
            [-2.082144930884289, 51.854914619808191],
            [-2.083928789203657, 51.854618454602672],
            [-2.087463224956543, 51.856360110359773],
            [-2.089297915963136, 51.856791191106829],
            [-2.091135875829633, 51.856668400229218],
            [-2.09196383613722, 51.856121106322504],
            [-2.094673733934482, 51.856377889220944],
            [-2.096571045975401, 51.856186634531952],
            [-2.098417290939077, 51.856552828990431],
            [-2.098563144636744, 51.855526842785792],
            [-2.097821709496411, 51.854424277391701],
            [-2.096888487465571, 51.851217090354609],
            [-2.098532548499532, 51.850174571901576],
            [-2.101133669041107, 51.847390565130084],
            [-2.101753276702188, 51.847275844612057],
            [-2.103556122944961, 51.845955297505796],
            [-2.102895594892399, 51.84530493491684],
            [-2.107536152036341, 51.842770724259417],
            [-2.104729111044248, 51.842240999460309],
            [-2.09953348551542, 51.84325610344721],
            [-2.098739353175127, 51.843193837900053],
            [-2.100474508346009, 51.842144021316962],
            [-2.100167145905094, 51.841652479700102],
            [-2.100831020945293, 51.841246419612922],
            [-2.100281510770639, 51.840864775561826],
            [-2.100160624172197, 51.840026023899483],
            [-2.101307052413592, 51.837352031608177],
            [-2.105873969590186, 51.834669595690045],
            [-2.107840046956238, 51.833899078816529],
            [-2.110245984127518, 51.833806034451506],
            [-2.113142813913986, 51.83444343310456],
            [-2.111571441156902, 51.833410977963858],
            [-2.110299062163428, 51.831775826967629],
            [-2.1116196503753, 51.831782670123609],
            [-2.111140896784416, 51.830661053056069],
            [-2.111152966093178, 51.828495119394915],
            [-2.110589234236612, 51.828203445777881],
            [-2.111583691138959, 51.827225186696964],
            [-2.114106196760548, 51.826894590782892],
            [-2.114179264085387, 51.825953165684311],
            [-2.113612898726982, 51.825187685068251],
            [-2.113950586929139, 51.823887263694175],
            [-2.114901028475616, 51.822783144929026],
            [-2.117429854385492, 51.82108494430171],
            [-2.120341064289293, 51.819768413725654],
            [-2.121615622403922, 51.819513554326392],
            [-2.131976674303804, 51.819362101859923],
            [-2.134260388117643, 51.819912457043657],
            [-2.136921101269432, 51.82132995784557],
            [-2.14134940202146, 51.821898333554302],
            [-2.142491510455291, 51.821528322920969],
            [-2.146487978582298, 51.821337740692172],
            [-2.149238993076718, 51.822718436559875],
            [-2.152851703499692, 51.823147153888897],
            [-2.156419660090368, 51.823620774745308],
            [-2.160089449019905, 51.824557183648672],
            [-2.160980010849503, 51.825280638441626],
            [-2.163690356856977, 51.826461901492195],
            [-2.164266289710096, 51.828421128270818],
            [-2.166063957644539, 51.828364654163188],
            [-2.167868137219977, 51.830447993005677],
            [-2.171031200076285, 51.831869395929466],
            [-2.172470749892893, 51.835277561626313],
            [-2.174358354868617, 51.835892453313306],
            [-2.17556260514157, 51.836923720204481],
            [-2.177951904086326, 51.837389452415309],
            [-2.179750837868437, 51.83829300134196],
            [-2.180061467421789, 51.839754454336628],
            [-2.178658864106716, 51.841820917948255],
            [-2.18343713964559, 51.842874484986496],
            [-2.185076350066789, 51.84403893853262],
            [-2.186677202452363, 51.844683753483267],
            [-2.18651162472582, 51.845011286835245],
            [-2.186852788179867, 51.844669987911999],
            [-2.187687468537832, 51.845022001399563],
            [-2.186678959255463, 51.846159164398976],
            [-2.181121052118734, 51.853126849340711],
            [-2.177262628020751, 51.855870472328974],
            [-2.187112708085509, 51.862169521366198],
            [-2.192054980091008, 51.86434993561889],
            [-2.196389242358908, 51.865536764625439],
            [-2.196176604373436, 51.868064464934292],
            [-2.197633384846013, 51.871722221837103],
            [-2.197697853347128, 51.871849784224658],
            [-2.199689891724172, 51.877903584713785],
            [-2.198027116646141, 51.878683211621407],
            [-2.199125187408835, 51.88061709513547],
            [-2.201251516646549, 51.879530969765007],
            [-2.202962822092678, 51.881465563737464],
            [-2.205399790527884, 51.88320196587204],
            [-2.208456267723872, 51.884395069625306],
            [-2.21159752753149, 51.88504489182084],
            [-2.214546267556779, 51.885216666656866],
            [-2.21852369772559, 51.884792162042196],
            [-2.226806265285458, 51.882419060595574],
            [-2.228848844198962, 51.882119301171002],
            [-2.233955803353791, 51.881910548570396],
            [-2.237551668111434, 51.882499432778275],
            [-2.237960187182638, 51.883101895368888],
            [-2.243130328881055, 51.88220481341785],
            [-2.246795841094505, 51.880896192261069],
            [-2.24704655665248, 51.881049409950471],
            [-2.24700003679347, 51.880778881717291],
            [-2.250084170128514, 51.878673884984799],
            [-2.252787985275174, 51.877185501829402],
            [-2.255487883556563, 51.876318336113947],
            [-2.260409271497547, 51.875553199766514],
            [-2.265495492282412, 51.875673089672354],
            [-2.265761952899926, 51.875288575788176],
            [-2.267550824350431, 51.875169434314387],
            [-2.266701716567331, 51.877731974078237],
            [-2.26794378402535, 51.882133792380131],
            [-2.267198574481281, 51.885084502363945],
            [-2.267514108139057, 51.887817015858737],
            [-2.266971072738611, 51.889351196692004],
            [-2.267541279538544, 51.889455093877174],
            [-2.268313613836611, 51.887878130157461],
            [-2.268118816610754, 51.884690404259231],
            [-2.268783717366334, 51.881439575793323],
            [-2.267594073846656, 51.877070013720754],
            [-2.268559261428233, 51.873766350994046],
            [-2.270772541948346, 51.870804162156091],
            [-2.27054683781612, 51.868289922038876],
            [-2.27228961541716, 51.86684015781313],
            [-2.273385496669164, 51.864585387595135],
            [-2.275453645811608, 51.862949605038935],
            [-2.278079881277072, 51.862119852217276],
            [-2.286198047992418, 51.861072703078122],
            [-2.2882114823023, 51.860288269575669],
            [-2.288811114550467, 51.859595394827501],
            [-2.288863704131734, 51.858285285977239],
            [-2.280610442795477, 51.846873290850141],
            [-2.279920467347256, 51.844932889665515],
            [-2.281101549738891, 51.843684822931856],
            [-2.288108528935165, 51.84077546211919],
            [-2.290762824071654, 51.839130771151396],
            [-2.291463263609479, 51.8374666058318],
            [-2.2911023223084, 51.834837640779313],
            [-2.291851123225616, 51.833931291435199],
            [-2.293832539482258, 51.83288789619084],
            [-2.295514924333667, 51.832509653039402],
            [-2.296979621361261, 51.832575191393957],
            [-2.298868360005069, 51.833231240364107],
            [-2.300671875958195, 51.834592369714279],
            [-2.302065280888761, 51.837437137648855],
            [-2.30361928286574, 51.844491043368841],
            [-2.305236061070211, 51.846377655029229],
            [-2.307551710925956, 51.84726801743976],
            [-2.313151711432339, 51.847811570021477],
            [-2.319590802110802, 51.847721412299748],
            [-2.322836045473372, 51.848022732249355],
            [-2.324687071550279, 51.848440209081367],
            [-2.32896345917597, 51.850179758255088],
            [-2.332968854377742, 51.850762783388106],
            [-2.337343169623637, 51.849995070959537],
            [-2.343426965057227, 51.848014727276926],
            [-2.351968507889472, 51.846489769454486],
            [-2.352173237621592, 51.84668156303853],
            [-2.352749028202414, 51.84939870526],
            [-2.350637740127595, 51.850088334986815],
            [-2.346868043658503, 51.854500593473482],
            [-2.344561112757923, 51.854711472355355],
            [-2.338579183311311, 51.85393585093307],
            [-2.33465793396904, 51.854956763473496],
            [-2.332691991568651, 51.85593606376392],
            [-2.33128451048288, 51.855863615512348],
            [-2.327140954006565, 51.856509081421166],
            [-2.323165888838342, 51.856166732035049],
            [-2.322052450293544, 51.857823222899526],
            [-2.319327618515332, 51.859343832147744],
            [-2.316509952732892, 51.860284712015194],
            [-2.315979561760248, 51.860852568102452],
            [-2.316591929118739, 51.861618746779598],
            [-2.318207619929143, 51.862570121289622],
            [-2.317676474253815, 51.86344367869043],
            [-2.317113592663642, 51.866826688578634],
            [-2.317413832914379, 51.868420869315131],
            [-2.32097750866459, 51.868769931851951],
            [-2.324819696641064, 51.868660479022289],
            [-2.326800630948258, 51.870849675042663],
            [-2.328523437411972, 51.871675635312663],
            [-2.329744800081655, 51.871852034714088],
            [-2.331399154686301, 51.873627586420724],
            [-2.334573540660527, 51.8754698272135],
            [-2.334577604155336, 51.876212465728166],
            [-2.333203383072366, 51.876598482260675],
            [-2.333032810835591, 51.877301156295218],
            [-2.33091242172442, 51.878354584896869],
            [-2.331101017123923, 51.879505790247848],
            [-2.330680476810614, 51.879607671962944],
            [-2.329515356625209, 51.879205452715397],
            [-2.328311264897468, 51.879432694778224],
            [-2.326923477914137, 51.882173395193263],
            [-2.325649560854884, 51.882802697549074],
            [-2.323593516506236, 51.885187367685113],
            [-2.320939780338818, 51.886306815599227],
            [-2.319819111105648, 51.888492859631377],
            [-2.317364404082533, 51.88717425200327],
            [-2.316756909109344, 51.887565196042345],
            [-2.315911012137763, 51.887113431316898],
            [-2.315145797083262, 51.888222264369837],
            [-2.314716127153491, 51.888074166265511],
            [-2.314131919998479, 51.88847582340221],
            [-2.313534664545562, 51.888048551667204],
            [-2.311308488813914, 51.888651462219805],
            [-2.309577958045824, 51.887807290500177],
            [-2.309170518261385, 51.888354109972745],
            [-2.308153865221569, 51.888419715029684],
            [-2.299994240162315, 51.891103918828669],
            [-2.297024049306042, 51.892634515898216],
            [-2.29721314705524, 51.893103362385233],
            [-2.295185884079532, 51.893308066862815],
            [-2.295269846724757, 51.893923731722083],
            [-2.289958193446865, 51.894332549075408],
            [-2.293212162235073, 51.896157720809136],
            [-2.296015020583962, 51.896528315223883],
            [-2.295564364876408, 51.897391674305695],
            [-2.297120660588416, 51.899565342960784],
            [-2.297213874904579, 51.902896226370494],
            [-2.296276924585213, 51.904523241040152],
            [-2.295324102974292, 51.904406958316343],
            [-2.296526803034061, 51.902959997930516],
            [-2.295259396973669, 51.902742011591286],
            [-2.293659216119327, 51.903646004708662],
            [-2.29565592495371, 51.905355560235208],
            [-2.291649175665544, 51.906359056426183],
            [-2.287892473783243, 51.909438693026416],
            [-2.284819334470134, 51.911266825106637],
            [-2.28620714476302, 51.913013975261812],
            [-2.285789625063451, 51.9168594786398],
            [-2.28729739769941, 51.920964621293621],
            [-2.284527834481155, 51.92309768796251],
            [-2.283612941472267, 51.923288705367483],
            [-2.283864170950005, 51.923696283298824],
            [-2.288056794630641, 51.925563376026588],
            [-2.29532865521004, 51.933218116409151],
            [-2.291568880206385, 51.937923401993629],
            [-2.292323901529322, 51.938830499615939],
            [-2.292239879118753, 51.940896797501267],
            [-2.291160186346172, 51.941972078743682],
            [-2.289554797872561, 51.94297402131366],
            [-2.289092056402598, 51.942954480596256],
            [-2.288893183101098, 51.944152544795095],
            [-2.287859010337284, 51.945545058631247],
            [-2.285781805475188, 51.945366710968869],
            [-2.282311945739071, 51.944199991447618],
            [-2.28139837875105, 51.945834011429717],
            [-2.283762562431151, 51.94837362801681],
            [-2.284012908576612, 51.950920116838859],
            [-2.283308442422218, 51.951805610657885],
            [-2.279888093017131, 51.952816271576282],
            [-2.277314096434739, 51.953059724443804],
            [-2.274320750391589, 51.952785331026448],
            [-2.26754969032396, 51.953180337372018],
            [-2.268359778885561, 51.958470468355223],
            [-2.269172157161575, 51.959255305676898],
            [-2.27160707335618, 51.96049313404113],
            [-2.270721189993842, 51.961527317274864],
            [-2.270941856602458, 51.963124470137714],
            [-2.26980251235316, 51.966556168932492],
            [-2.269658171421345, 51.967727097882587],
            [-2.270560600064879, 51.968930687816339],
            [-2.267637482687055, 51.968398826921238],
            [-2.267770919765578, 51.968074855710022],
            [-2.263624058170406, 51.967345180006134],
            [-2.258696759043081, 51.96780745450517],
            [-2.25650498084173, 51.967097487889262],
            [-2.251365328297282, 51.966556549034337],
            [-2.251828764979148, 51.967697385062756],
            [-2.251115604317253, 51.968749929765735],
            [-2.248911776638402, 51.968216066682565],
            [-2.246242723281929, 51.971488026929322],
            [-2.243975128368218, 51.97289441037131],
            [-2.242866753994571, 51.974135629658711],
            [-2.243588958503849, 51.974697856699308],
            [-2.243316332472878, 51.975162343663165],
            [-2.237638563995771, 51.980911843890979],
            [-2.23404969086002, 51.985963741124735],
            [-2.233176921377307, 51.986701815148002],
            [-2.227811887408271, 51.988617461736347],
            [-2.228988223647534, 51.990838584829795],
            [-2.226404395978868, 51.990774356799946],
            [-2.224436727232166, 51.992773168317704],
            [-2.223308537534961, 51.992874213007752],
            [-2.220619809333197, 51.995487493167673],
            [-2.216957207875169, 51.994067477386977],
            [-2.214452598018084, 51.995375723493936],
            [-2.215681155988431, 51.99583649952018],
            [-2.213442446994716, 51.996732461221512],
            [-2.211695690644069, 51.996646609205385],
            [-2.205602934548078, 51.995332183663749],
            [-2.205649988927787, 51.996382216829637],
            [-2.202892715674549, 51.996353738834941],
            [-2.203052692153271, 51.99533661308854],
            [-2.200835003659594, 51.994798279932695],
            [-2.200469211137389, 51.995724947432223],
            [-2.198419244415355, 51.995564791958863],
            [-2.197207889111997, 51.994999514876497],
            [-2.197104485277503, 51.994337971568505],
            [-2.195208292256401, 51.994355518629497],
            [-2.194421036844231, 51.993167350706614],
            [-2.193666236121641, 51.993414042377765],
            [-2.190371219210047, 51.991547553798746],
            [-2.18509079704642, 51.990551718159701],
            [-2.183699760960869, 51.990842500994098],
            [-2.18170881004305, 51.991878627141197],
            [-2.17943273461311, 51.99483826840676],
            [-2.179852784960415, 51.996801198123912],
            [-2.181509413590275, 51.999102072730572],
            [-2.181113269682624, 51.999473999500132],
            [-2.1794605907777, 51.999249067737701],
            [-2.176632497391459, 51.997183687901376],
            [-2.176035447745716, 51.99721515139921],
            [-2.174914067211402, 51.996131645800027],
            [-2.171744131693091, 51.994829068003718],
            [-2.170272482813461, 51.994660384273416],
            [-2.166434209954789, 51.995294329320039],
            [-2.164304708647985, 51.996442740011311],
            [-2.163859420760587, 51.997348725510747],
            [-2.163556979026317, 52.001480374788898],
            [-2.16473079704679, 52.004185835845924],
            [-2.172141634607495, 52.008451197156532],
            [-2.184072572659564, 52.013891379250524],
            [-2.187690305884306, 52.019070588457197],
            [-2.183059060811784, 52.019743195552856],
            [-2.176913255612346, 52.022549595901886],
            [-2.177134429321888, 52.023936523249468],
            [-2.178320160181745, 52.025619582876004],
            [-2.177578720245019, 52.026455037963352],
            [-2.177599184315134, 52.027204828371978],
            [-2.178568022958852, 52.028567245255744],
            [-2.178109757630179, 52.029149635590166],
            [-2.1794036070631, 52.031943764312352],
            [-2.179164927638988, 52.033494116786841],
            [-2.179770952542587, 52.034851679937823],
            [-2.180556266026465, 52.035472630497239],
            [-2.180686440573075, 52.041718232001315],
            [-2.180416111538717, 52.042291350564085],
            [-2.178171980689993, 52.043682924521072],
            [-2.176987814778991, 52.045463958411126],
            [-2.175511802902071, 52.046470422984797],
            [-2.174114232590305, 52.04704250532636],
            [-2.171388047962505, 52.047563468980272],
            [-2.170800173544116, 52.047501389369437],
            [-2.170533087314177, 52.047054942406184],
            [-2.168321441416392, 52.048698916912919],
            [-2.166416782549127, 52.049021692518906],
            [-2.164635520061191, 52.04999428701143],
            [-2.162400408754003, 52.05049637294195],
            [-2.158141247737696, 52.050195594380675],
            [-2.155532375336729, 52.049335073023947],
            [-2.155261190461073, 52.048507394498444],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000084",
        LAD13CDO: "24UB",
        LAD13NM: "Basingstoke and Deane",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.080010396565932, 51.374635051177691],
            [-1.077882545709442, 51.374567075601028],
            [-1.072539303437297, 51.37528293391145],
            [-1.069230578336919, 51.373223632076261],
            [-1.068955075707669, 51.373490302249905],
            [-1.065786442183911, 51.372326710918976],
            [-1.062613754418602, 51.36982140516151],
            [-1.063081664067748, 51.369067127048467],
            [-1.062137798383085, 51.367663130829364],
            [-1.060170637697218, 51.368183291498681],
            [-1.059170106153108, 51.367517054257654],
            [-1.059176429691702, 51.366296899437842],
            [-1.058547154171594, 51.365741539539258],
            [-1.055817978166829, 51.365039793176678],
            [-1.055415754178551, 51.363574462340999],
            [-1.053101665216745, 51.362324790611957],
            [-1.051544072904104, 51.36263230836348],
            [-1.050264717575604, 51.359972020113659],
            [-1.050035305993165, 51.358147492004292],
            [-1.022665954795122, 51.359072501887951],
            [-1.011142382995779, 51.359169156618115],
            [-1.008587447535312, 51.359429018259362],
            [-1.004705904507707, 51.36052640720817],
            [-1.00232542343844, 51.360287660129039],
            [-1.000242735374611, 51.362667171845338],
            [-0.999281558499118, 51.361558349976441],
            [-0.996337170361972, 51.362278604717559],
            [-0.994238935054272, 51.362266909984051],
            [-0.992892203111149, 51.362569164159154],
            [-0.992925303213569, 51.361452644555463],
            [-0.990787994662143, 51.362567247498525],
            [-0.99019033986028, 51.363226606491295],
            [-0.988865708935754, 51.362913953382304],
            [-0.987588117252861, 51.361966857543429],
            [-0.986895490214156, 51.362046295601218],
            [-0.987164181384551, 51.362562959655449],
            [-0.986143842847215, 51.362847275327738],
            [-0.985502712262198, 51.362025254606287],
            [-0.985733063987698, 51.361480535794648],
            [-0.986704593207204, 51.361389129386417],
            [-0.987941717541518, 51.360716429105118],
            [-0.987558614394124, 51.36011245517642],
            [-0.986759988507346, 51.35985467619107],
            [-0.986731087007234, 51.35823496901304],
            [-0.988317980279642, 51.355501626345067],
            [-0.988803937276996, 51.351431547228259],
            [-0.994646471277776, 51.346862629909886],
            [-0.993863534030568, 51.344454145148767],
            [-0.994622571836875, 51.342310671304844],
            [-0.992333809898101, 51.342190299692668],
            [-0.990755885922179, 51.34265149705832],
            [-0.990383687970172, 51.341425376830109],
            [-0.989524689330621, 51.340466616738311],
            [-0.990172527008556, 51.339404849716573],
            [-0.991692455294733, 51.338703074361199],
            [-0.981947488431333, 51.337605404920673],
            [-0.981019848415869, 51.337228667899396],
            [-0.982179825921978, 51.333919793226592],
            [-0.98188288762238, 51.331123388909425],
            [-0.983499431069159, 51.330394683621492],
            [-0.983762553925039, 51.329919489232459],
            [-0.98383813055807, 51.329032630875716],
            [-0.98312765037021, 51.328254050387109],
            [-0.982590828655497, 51.32617942225199],
            [-0.985001982627629, 51.325594273233229],
            [-0.985355882445936, 51.324580338976688],
            [-0.986853926483122, 51.323875738820767],
            [-0.987098150149671, 51.32288692755214],
            [-0.986607227507947, 51.320417063114135],
            [-0.986844167052815, 51.317021916149542],
            [-0.985449056560698, 51.314370673855379],
            [-0.985275880941294, 51.312399013859064],
            [-0.983905105180318, 51.310020420772702],
            [-0.984292543040563, 51.306832496421507],
            [-0.986452958893065, 51.306772079578252],
            [-0.988978583459741, 51.304388527610499],
            [-0.990254328169225, 51.304172032633026],
            [-0.990626910319938, 51.303535907699917],
            [-0.993359658409494, 51.303535146051466],
            [-0.995672957521227, 51.299221724844536],
            [-0.995414784886868, 51.298695272947313],
            [-0.998435008709158, 51.29997193473401],
            [-1.001235050555386, 51.300231436111574],
            [-1.001092021343078, 51.298097297327416],
            [-1.001621287012096, 51.295914937607868],
            [-1.002295550727586, 51.293988285873596],
            [-1.001074483491674, 51.293220745116827],
            [-1.001246645733449, 51.292556799415728],
            [-1.000734776152946, 51.291824074859996],
            [-1.00127704179797, 51.291618285360755],
            [-1.00092256355437, 51.290968731430446],
            [-1.001261997215168, 51.290129967378839],
            [-1.00081849789419, 51.290279050098114],
            [-1.000239148625137, 51.28922113300996],
            [-0.996400970553277, 51.288460846894985],
            [-0.99570925552391, 51.288681518217906],
            [-0.992157882045665, 51.288123175989469],
            [-0.991102144101528, 51.28754758446469],
            [-0.988003603476917, 51.286944458331341],
            [-0.981744000319349, 51.286631230416567],
            [-0.982413924473642, 51.283764076267303],
            [-0.984184465932095, 51.282347903657914],
            [-0.9821442706965, 51.282210594708829],
            [-0.98310532874536, 51.280374662158763],
            [-0.981483585291097, 51.278435358024375],
            [-0.981498307233755, 51.277574940765653],
            [-0.97806008373992, 51.275128805727839],
            [-0.979536140064164, 51.273342353976922],
            [-0.980132351868706, 51.271211918622313],
            [-0.979767974639093, 51.270123395051016],
            [-0.979943090520453, 51.267587341924184],
            [-0.979873814262493, 51.26521281553358],
            [-0.980817437319258, 51.261625077111113],
            [-0.982126976666185, 51.260994429243041],
            [-0.982647515398782, 51.260331736295228],
            [-0.98318491333915, 51.257864464579413],
            [-0.984187109481926, 51.256542326101801],
            [-0.985816899034615, 51.255350607428127],
            [-0.985410923094989, 51.254914568092659],
            [-0.989768576578778, 51.25243264754117],
            [-0.990404958625134, 51.251639633874845],
            [-0.990179015184887, 51.250833787316914],
            [-0.989083557375017, 51.25014902371182],
            [-0.992160406151435, 51.248157710430277],
            [-0.991031116695897, 51.247383651093529],
            [-0.992597334243181, 51.246324373742233],
            [-0.992952863248642, 51.24572405869305],
            [-0.99268244900121, 51.245576059539026],
            [-0.993724936094879, 51.244417842235364],
            [-0.99078126017273, 51.243808014523424],
            [-0.987677355950762, 51.243844162803917],
            [-0.982003877033138, 51.24261969330319],
            [-0.981019130800955, 51.24270914131813],
            [-0.978835322078343, 51.24208494119037],
            [-0.976782105734042, 51.241194773990976],
            [-0.974813029549829, 51.238520360338995],
            [-0.977222110154834, 51.237825654223066],
            [-0.976255237823125, 51.237049271372776],
            [-0.978329810707044, 51.235607963778719],
            [-0.983126320086358, 51.235308920974965],
            [-0.983156684951114, 51.233346192200884],
            [-0.982210985432944, 51.231025184923588],
            [-0.979907865439825, 51.229213895104174],
            [-0.976977366866935, 51.230064161481103],
            [-0.976065863379903, 51.229058958548571],
            [-0.97611332986785, 51.227629615272747],
            [-0.975491139454768, 51.225916553975125],
            [-0.977336762546971, 51.225027178076701],
            [-0.978699130326827, 51.224746825696229],
            [-0.97894929735273, 51.223601603813663],
            [-0.981378026694595, 51.224314262974033],
            [-0.983298369223342, 51.223080143239564],
            [-0.981666831245364, 51.2219338437761],
            [-0.978117538163987, 51.22048574239939],
            [-0.980379242857339, 51.218509195931034],
            [-0.981708160753044, 51.216295193470842],
            [-0.982737943832802, 51.215630631215348],
            [-0.984175718185574, 51.212429302416666],
            [-0.984282535712446, 51.209525744722932],
            [-0.983494935525865, 51.208300462089497],
            [-0.983059010885128, 51.205863380901782],
            [-0.9862909908331, 51.203201844240738],
            [-0.98642966804421, 51.200078246961311],
            [-0.985327895948748, 51.196675032325622],
            [-0.984747764315558, 51.192920238607684],
            [-0.985898446417212, 51.190437566048111],
            [-0.989505067354939, 51.190871615406252],
            [-0.994337641995314, 51.189947476055941],
            [-0.997220466236756, 51.190040554303771],
            [-1.001044972996649, 51.189202814052841],
            [-1.002717130958138, 51.189298005651196],
            [-1.004096796880482, 51.188955460522934],
            [-1.004901765780055, 51.189716756273796],
            [-1.008130528824621, 51.189959979202868],
            [-1.010918268936077, 51.188412630447807],
            [-1.013146197227885, 51.187743533164436],
            [-1.019945072560289, 51.188792510169101],
            [-1.023399632684209, 51.189862688340845],
            [-1.029680805430267, 51.189967988861476],
            [-1.035706917526875, 51.190708398685331],
            [-1.044325198983859, 51.189699127445792],
            [-1.050043798374502, 51.187935553793253],
            [-1.0520181267432, 51.189067495975614],
            [-1.057269055812555, 51.18916836097889],
            [-1.061861831880624, 51.188772742114971],
            [-1.061707599648779, 51.190519589429364],
            [-1.071120326760414, 51.187757637279567],
            [-1.073431280440842, 51.185328279968111],
            [-1.074703939553767, 51.180720852784809],
            [-1.076026224069867, 51.179200826656427],
            [-1.076212851016542, 51.177736569913186],
            [-1.074870632520191, 51.175107434196221],
            [-1.07734024736922, 51.173287124923995],
            [-1.077487095659891, 51.172737959013375],
            [-1.074265649975109, 51.164454058791222],
            [-1.07503396421276, 51.164295576969081],
            [-1.080551746119935, 51.165006287946476],
            [-1.08279213594124, 51.165691993243179],
            [-1.08503606562473, 51.167142021935888],
            [-1.087393727482937, 51.167691873562347],
            [-1.094204356935217, 51.165737728953225],
            [-1.096216829880795, 51.164704797425188],
            [-1.099325988000955, 51.16397342433158],
            [-1.100469500251028, 51.163122564543613],
            [-1.104936681862644, 51.163151425591138],
            [-1.10723374972449, 51.1613588407685],
            [-1.109069041134674, 51.158585241158747],
            [-1.111806283756761, 51.156338212846926],
            [-1.11628120347645, 51.153681623131796],
            [-1.117548176807642, 51.152399901959484],
            [-1.11725771713071, 51.151436441753553],
            [-1.122249534037844, 51.149978589581977],
            [-1.126131184166407, 51.148164239012978],
            [-1.130518553545477, 51.145591877359962],
            [-1.131820611682339, 51.143625953285266],
            [-1.137498702884534, 51.142168046210365],
            [-1.14125410385604, 51.140464666666539],
            [-1.142706363950073, 51.141316091081464],
            [-1.14702384160752, 51.139736240767476],
            [-1.151797696474325, 51.138976035599626],
            [-1.151797914344579, 51.137169497887541],
            [-1.151027064049028, 51.136180156177588],
            [-1.155854585614111, 51.135980391593833],
            [-1.156609039040346, 51.136305956394324],
            [-1.160355908103395, 51.135086584550649],
            [-1.162355538266562, 51.134765508738866],
            [-1.168540586006415, 51.134807831631932],
            [-1.174304435502561, 51.133543881788029],
            [-1.178151326657374, 51.134058348003983],
            [-1.186581341457811, 51.138184444608882],
            [-1.183797159220451, 51.140686470827916],
            [-1.184242582378364, 51.142584236117621],
            [-1.184791288424064, 51.143002601436422],
            [-1.184283897320095, 51.143484644218844],
            [-1.184460841907502, 51.144314059992851],
            [-1.190151730888758, 51.146691561994324],
            [-1.190009109879681, 51.146917178169062],
            [-1.196064045920872, 51.148783455353637],
            [-1.198673493358373, 51.150006309433167],
            [-1.200754320928275, 51.150386523823023],
            [-1.203695109380784, 51.150230327902385],
            [-1.207483269557441, 51.150600448226136],
            [-1.208605562108409, 51.150436293670062],
            [-1.210432665516096, 51.151447668963009],
            [-1.213413695018834, 51.15195422395103],
            [-1.213713984025625, 51.152457106712347],
            [-1.213314647293559, 51.155173657275007],
            [-1.214421956537728, 51.157582007095009],
            [-1.214375662768446, 51.158708415355093],
            [-1.211261879766665, 51.15883314145956],
            [-1.209450032863772, 51.159337964540107],
            [-1.208089333205421, 51.160050835162977],
            [-1.207426520615318, 51.161093935549417],
            [-1.205993028182729, 51.161543717352941],
            [-1.197012950541133, 51.161084984868772],
            [-1.193371436726569, 51.162922218776913],
            [-1.189326371191646, 51.163777296725996],
            [-1.185588484986114, 51.163991432771425],
            [-1.186865176979585, 51.165073976877792],
            [-1.182255038585472, 51.170449743294796],
            [-1.181712352764265, 51.175252261592483],
            [-1.189002028013344, 51.176730061007262],
            [-1.19293700296753, 51.179379374945164],
            [-1.189610550844395, 51.182412819325869],
            [-1.19032364838791, 51.183767475810974],
            [-1.19167906433906, 51.184721919145851],
            [-1.192868634518262, 51.184688765089838],
            [-1.195172021405626, 51.185192900655494],
            [-1.196486564710227, 51.18480268199751],
            [-1.197000993971605, 51.184263986661776],
            [-1.197795856805017, 51.184219081609228],
            [-1.197985236154038, 51.185178941492033],
            [-1.198885108509329, 51.185185104524471],
            [-1.199649917380209, 51.185637246289275],
            [-1.201047884468214, 51.185634208772115],
            [-1.202257361788935, 51.185194650580648],
            [-1.206576776922561, 51.180704564284774],
            [-1.207087118246332, 51.181312294796477],
            [-1.213992693352049, 51.184110469492452],
            [-1.217234215000229, 51.184738263729216],
            [-1.221211648053756, 51.186447224489221],
            [-1.224762574420022, 51.189032653989308],
            [-1.227869124350593, 51.190488330983946],
            [-1.231715505009559, 51.191009107443548],
            [-1.241100562992209, 51.191318516747984],
            [-1.248563951413143, 51.191008783737011],
            [-1.253092484141789, 51.190510831893711],
            [-1.254508311395539, 51.191981974113141],
            [-1.25883471582582, 51.193203600311975],
            [-1.262295092119822, 51.19364629065867],
            [-1.270461030654459, 51.1936291315128],
            [-1.271171703092666, 51.193755851211733],
            [-1.27140770337608, 51.194217715548398],
            [-1.272783123698597, 51.194208284687001],
            [-1.272916085099725, 51.194671304118579],
            [-1.274222743442637, 51.194849363825057],
            [-1.279186561667699, 51.193975432567029],
            [-1.279490847136766, 51.195202927490278],
            [-1.28028273494585, 51.195080111603055],
            [-1.281935885352979, 51.193960858558889],
            [-1.282560824276977, 51.19500327659231],
            [-1.284162600557034, 51.195256768736002],
            [-1.285158065283842, 51.195109986841437],
            [-1.287342933419172, 51.196159197329948],
            [-1.287763915670734, 51.195494547148812],
            [-1.289886196580686, 51.194195496522049],
            [-1.292780072039124, 51.194367680627664],
            [-1.295102106908084, 51.195065085190905],
            [-1.295107214346501, 51.195949936560801],
            [-1.298921198984774, 51.19698714813962],
            [-1.29977348342632, 51.196749463568828],
            [-1.300975944220348, 51.196730573499089],
            [-1.30467957214294, 51.19560344482484],
            [-1.305570098331017, 51.195579057426116],
            [-1.306493423580818, 51.194894839260101],
            [-1.307175295266543, 51.19542311510034],
            [-1.308743230064216, 51.195652690699106],
            [-1.308838419442938, 51.194742355667508],
            [-1.310335668497207, 51.194529082532576],
            [-1.313166065752515, 51.193393839790531],
            [-1.32067280047364, 51.191486366176086],
            [-1.323522794806564, 51.191328425497893],
            [-1.32773609988693, 51.190509242960978],
            [-1.332212255373248, 51.187729338017569],
            [-1.340953993151271, 51.184583100774518],
            [-1.341691625525606, 51.187040289973474],
            [-1.339550442162072, 51.196096728288786],
            [-1.339131293813565, 51.199887212755698],
            [-1.341098389678371, 51.20446536405472],
            [-1.340619072434057, 51.20495273182545],
            [-1.341626755899923, 51.205657984474179],
            [-1.352793287354806, 51.210670367543429],
            [-1.358193033088811, 51.212024620156441],
            [-1.360270856547578, 51.210022676895477],
            [-1.35920892639424, 51.20824723712699],
            [-1.360292485174894, 51.206290201578476],
            [-1.360055770269851, 51.204571428453349],
            [-1.362073363103364, 51.202962074419681],
            [-1.362380765927035, 51.203193945397103],
            [-1.363274212341248, 51.202965914395257],
            [-1.365412143985773, 51.201960523221622],
            [-1.366856675211718, 51.202980848567137],
            [-1.368154336490621, 51.202813414059221],
            [-1.368876938789799, 51.202410871736781],
            [-1.372644340647428, 51.205036114708747],
            [-1.373972493578023, 51.206529607583555],
            [-1.376011533884248, 51.20722388775733],
            [-1.379164209545277, 51.209206306139869],
            [-1.381694832709982, 51.211798610720081],
            [-1.383680569485802, 51.214904133529345],
            [-1.385906022147786, 51.214431157793292],
            [-1.388560476034895, 51.214749879889375],
            [-1.39658991515178, 51.217349764109798],
            [-1.400111329967099, 51.219505258854326],
            [-1.401346237744807, 51.21989733629637],
            [-1.404810109407698, 51.219995024447805],
            [-1.408681204673809, 51.221167412693539],
            [-1.421105143847336, 51.22374006071707],
            [-1.421356343224923, 51.224948926339856],
            [-1.422757415186196, 51.225363178700121],
            [-1.426033809023029, 51.224911701518181],
            [-1.428108662583057, 51.225039648646764],
            [-1.427647184385382, 51.227032714909882],
            [-1.427041167113717, 51.231782910846938],
            [-1.427808614424943, 51.233534702942627],
            [-1.430654025695113, 51.235669777402279],
            [-1.43378238460522, 51.236713626266301],
            [-1.441339252154567, 51.24538669420847],
            [-1.443161373909102, 51.248016524376048],
            [-1.447094369475512, 51.250875712204284],
            [-1.453068496250046, 51.2531166781257],
            [-1.458181443911069, 51.254062115653689],
            [-1.460166104979276, 51.25589394381818],
            [-1.459612612315117, 51.2567806929518],
            [-1.459601085533117, 51.258008936677349],
            [-1.458481121994671, 51.259659177039765],
            [-1.456702545273389, 51.261005123966768],
            [-1.455218965339595, 51.261513470101228],
            [-1.450478829924412, 51.265537690534472],
            [-1.449063906551719, 51.265440225619002],
            [-1.448343448725265, 51.26611842190318],
            [-1.445674087592581, 51.267117410152331],
            [-1.446964471765844, 51.267921991567647],
            [-1.444187068083711, 51.269073298177837],
            [-1.440500107800509, 51.271810866825838],
            [-1.439944971279851, 51.271488101968963],
            [-1.438127850189129, 51.272973855132449],
            [-1.434550616791391, 51.274425020289229],
            [-1.428996020572086, 51.275428548278228],
            [-1.422074108225712, 51.277412386531104],
            [-1.420619187338613, 51.278305289300548],
            [-1.418765279053198, 51.280135848916942],
            [-1.416863852133654, 51.282877021856798],
            [-1.416776770862245, 51.285451867898821],
            [-1.418969824640832, 51.290860597729008],
            [-1.419276622132218, 51.293029163231203],
            [-1.420683106769043, 51.293973979887255],
            [-1.424506635210331, 51.29500530981106],
            [-1.424906625706726, 51.294787872099391],
            [-1.425121123485518, 51.295412961169198],
            [-1.42831350750889, 51.297969686104722],
            [-1.428666398209959, 51.300392918325372],
            [-1.427863827316624, 51.300453743366923],
            [-1.426080505735785, 51.30336198319123],
            [-1.426519822540605, 51.307708102762234],
            [-1.424825716897468, 51.310221115529274],
            [-1.425819375991021, 51.313372238203087],
            [-1.425003850422889, 51.31353278996761],
            [-1.424953345991899, 51.315392955621547],
            [-1.423933702862689, 51.315465277135552],
            [-1.423405823421478, 51.316369958127936],
            [-1.421240474888903, 51.316379964300083],
            [-1.420641849964506, 51.316972266300091],
            [-1.421341301600688, 51.317832645374686],
            [-1.42099706568485, 51.321014057174331],
            [-1.41938419135243, 51.323395208265779],
            [-1.419165523297879, 51.325597124273358],
            [-1.418417404696002, 51.326423360006373],
            [-1.418643507364228, 51.327043119965019],
            [-1.42006805171563, 51.32720573707995],
            [-1.421131860989683, 51.32923325915106],
            [-1.423319518927317, 51.330926415456425],
            [-1.423203414026363, 51.331829522315772],
            [-1.421197586282324, 51.334161103144815],
            [-1.421352302043126, 51.335554699887915],
            [-1.421916452421573, 51.335668083372539],
            [-1.42184611793007, 51.335896128570369],
            [-1.425903163469009, 51.337245962160296],
            [-1.429708971490475, 51.336532609813567],
            [-1.433136893616513, 51.340146863240918],
            [-1.433477902354384, 51.341133114834328],
            [-1.436747882713368, 51.343159442492095],
            [-1.441184446331559, 51.346566091014637],
            [-1.444951916976132, 51.348120690028296],
            [-1.444238993135195, 51.349131585679878],
            [-1.443628785070941, 51.351974591373441],
            [-1.444125779072173, 51.354930749969249],
            [-1.440528156741081, 51.355541259904122],
            [-1.432247307496271, 51.355799969744538],
            [-1.428454946457051, 51.356692406986724],
            [-1.427770740008898, 51.357663777986005],
            [-1.427649635580655, 51.359084787225228],
            [-1.427283210718872, 51.358984987068162],
            [-1.426745185288719, 51.360091942614481],
            [-1.42236577792858, 51.362858793548547],
            [-1.421602246186284, 51.364408807212698],
            [-1.419088720027351, 51.366670397242764],
            [-1.417584065534215, 51.370690344170569],
            [-1.415646726473742, 51.371885599441207],
            [-1.411084563983938, 51.372854561019395],
            [-1.409483856419646, 51.372318692060418],
            [-1.407119865523763, 51.372710490285634],
            [-1.406131826050553, 51.372237917246672],
            [-1.401956857075443, 51.372139363456562],
            [-1.401440343473861, 51.371536976269617],
            [-1.399581783210214, 51.371616490203571],
            [-1.396625579739684, 51.371264120148339],
            [-1.393531556523621, 51.370473063079068],
            [-1.390806359229402, 51.370583918065883],
            [-1.387852589117224, 51.370274499525912],
            [-1.385265348869483, 51.369415733121805],
            [-1.38275383211805, 51.369129185284045],
            [-1.380842609698944, 51.368644339886451],
            [-1.380481031833379, 51.36829354835028],
            [-1.378783504003862, 51.368156881219171],
            [-1.37788648084724, 51.367780763303685],
            [-1.376164069677046, 51.368314713211227],
            [-1.373595274200307, 51.367812760258381],
            [-1.371919217544694, 51.368419729020687],
            [-1.368656098694849, 51.367957105948271],
            [-1.366290190181809, 51.368582756227632],
            [-1.36522244584394, 51.368405235823033],
            [-1.363802699152544, 51.368647510612],
            [-1.363291041715878, 51.36825179137255],
            [-1.362686136545566, 51.368365397362894],
            [-1.361772718342807, 51.3678344079174],
            [-1.360579801015161, 51.367880059096549],
            [-1.359604322217649, 51.367475498342813],
            [-1.359143719785719, 51.367748129859791],
            [-1.358463447911487, 51.367498033617309],
            [-1.357066843055039, 51.36793367701452],
            [-1.355479356514505, 51.36794564016347],
            [-1.354779201896858, 51.367476013424934],
            [-1.35289753282084, 51.367264224562028],
            [-1.351851092903877, 51.367822228498426],
            [-1.350470551514437, 51.367218430750974],
            [-1.348763858772757, 51.367230541433521],
            [-1.347130719372516, 51.368131426396801],
            [-1.345844277567432, 51.368287005731354],
            [-1.345418190795257, 51.368852908566168],
            [-1.343946437376119, 51.369107241042521],
            [-1.342944950960816, 51.369123211909105],
            [-1.341219226106976, 51.368554236091661],
            [-1.340815916606283, 51.369030332328869],
            [-1.338410640583997, 51.368568989881751],
            [-1.337336797091865, 51.369418943219522],
            [-1.336774592746334, 51.369056990863285],
            [-1.336551367628963, 51.369294909453934],
            [-1.334219224236602, 51.369439042893703],
            [-1.333358688222582, 51.37053115136527],
            [-1.332723267775275, 51.370463694150608],
            [-1.330028996361051, 51.371299849094456],
            [-1.329266748611418, 51.371161512177764],
            [-1.328251986954976, 51.371786925472669],
            [-1.327619188282677, 51.37144160908371],
            [-1.32646639249995, 51.371681366141566],
            [-1.324742515938241, 51.372557140318719],
            [-1.324229180312896, 51.372100096273549],
            [-1.322417141355698, 51.371644541785315],
            [-1.3212730466688, 51.372459773268332],
            [-1.318714312067624, 51.372950268856897],
            [-1.31515939256638, 51.373090506864429],
            [-1.31459448665597, 51.37272573471207],
            [-1.312364088702891, 51.373062452994688],
            [-1.311356564497903, 51.372803868653037],
            [-1.310923170741848, 51.372197971736639],
            [-1.309341531878862, 51.371714795188502],
            [-1.307223744944387, 51.371253606731713],
            [-1.303942197765362, 51.371182912926734],
            [-1.303392946682252, 51.370835263807955],
            [-1.300889415390564, 51.370995699118168],
            [-1.298214587404306, 51.370519334480235],
            [-1.2967635163327, 51.370715651082463],
            [-1.296792327876056, 51.370428985127489],
            [-1.296088883899587, 51.370304272584356],
            [-1.296050261037804, 51.370574694197117],
            [-1.293306189348209, 51.371055429580416],
            [-1.292661981489856, 51.370349283245339],
            [-1.292061658110752, 51.370431980465263],
            [-1.291661207141961, 51.370036618897011],
            [-1.290021608888937, 51.37006806156684],
            [-1.289923390610438, 51.36954504209104],
            [-1.288208395469304, 51.368788319335344],
            [-1.287823986982721, 51.368287837571259],
            [-1.286839183428581, 51.368246781758096],
            [-1.287026293083158, 51.367853179141029],
            [-1.284613585476038, 51.367999432028554],
            [-1.283977028538504, 51.36764306577237],
            [-1.282960067462863, 51.367638647335319],
            [-1.282777700504194, 51.368094316139036],
            [-1.282210869285674, 51.367964960821872],
            [-1.281453536157654, 51.368351464861654],
            [-1.280008466074083, 51.368347992357251],
            [-1.278261509047828, 51.368815613433966],
            [-1.27735423652172, 51.368046617555699],
            [-1.276686081943955, 51.368149498997788],
            [-1.276036491611865, 51.367347913681584],
            [-1.273429417032835, 51.367521509447322],
            [-1.270948465980027, 51.368435859157145],
            [-1.27069660780684, 51.368827235151436],
            [-1.269682870659361, 51.369431468201867],
            [-1.26982297700087, 51.370377381857381],
            [-1.267827031751648, 51.370321770447298],
            [-1.265463170310512, 51.370815018905539],
            [-1.264259701563631, 51.370611449207573],
            [-1.263814403248986, 51.370160860289197],
            [-1.263014621179811, 51.370136951490281],
            [-1.26283112137884, 51.368591900600514],
            [-1.262206779126239, 51.368379361847957],
            [-1.260595733323823, 51.369584006205201],
            [-1.259090867965978, 51.370167964218183],
            [-1.259211418606091, 51.371422186997584],
            [-1.257400154325113, 51.372114785936482],
            [-1.256590242146718, 51.371827308637158],
            [-1.254543308128629, 51.372077769001237],
            [-1.253892828577255, 51.371439706341057],
            [-1.252996970246034, 51.372022066090018],
            [-1.251300642615693, 51.371826011437932],
            [-1.251159222878616, 51.372040911955999],
            [-1.250390161525709, 51.371629565414409],
            [-1.248575372393155, 51.371826555802741],
            [-1.247846082661139, 51.370742858356067],
            [-1.247008432687474, 51.371100750213841],
            [-1.247103629389561, 51.370201279177444],
            [-1.247855622786628, 51.369809567210666],
            [-1.247262110613729, 51.369472160063125],
            [-1.246468160293619, 51.369531799787119],
            [-1.246166263434852, 51.368407677330559],
            [-1.245233138907968, 51.368108538320051],
            [-1.24477043995728, 51.367418581363978],
            [-1.243949971940149, 51.36743307390087],
            [-1.243206469314583, 51.367832908422457],
            [-1.243195325878713, 51.367114388429769],
            [-1.242052469189545, 51.366905586290187],
            [-1.241598343906716, 51.366221966260341],
            [-1.240160292594241, 51.366232434463491],
            [-1.240054292658628, 51.366561747885612],
            [-1.238728153822958, 51.366752760964943],
            [-1.238704753700122, 51.367211192976406],
            [-1.2380588705185, 51.366832935353877],
            [-1.236896594091076, 51.36718954358512],
            [-1.236400957706892, 51.367019966501289],
            [-1.235932109051508, 51.367571707828908],
            [-1.234846090311159, 51.367056583965741],
            [-1.234446331316265, 51.367598879367186],
            [-1.233332213333936, 51.36713301304929],
            [-1.232173963124851, 51.367675732046564],
            [-1.231474907613854, 51.36747332988174],
            [-1.229753596673268, 51.368011432521605],
            [-1.228947639309032, 51.367845179280209],
            [-1.228572567634715, 51.36828151337005],
            [-1.227417184271099, 51.368307173165597],
            [-1.226939920168861, 51.368498250179925],
            [-1.227044210362549, 51.368788475428225],
            [-1.225092831600548, 51.369503929998963],
            [-1.22391450768478, 51.369185015940886],
            [-1.222607674965816, 51.369754531376678],
            [-1.222006574520373, 51.369452013723041],
            [-1.222224829291398, 51.368694550282719],
            [-1.22103579600284, 51.36858594533237],
            [-1.221815024081645, 51.367785451791669],
            [-1.221045511383945, 51.367589712963706],
            [-1.220643094451962, 51.367516901397281],
            [-1.220776442252551, 51.366938712795275],
            [-1.220028096664136, 51.366851010159849],
            [-1.219676155114448, 51.366344224651201],
            [-1.218328336442049, 51.365863171196757],
            [-1.2167493970895, 51.36621769954899],
            [-1.216070945820788, 51.365907440635254],
            [-1.214846366528151, 51.36611864491892],
            [-1.213954972433124, 51.365745802400177],
            [-1.21357976982871, 51.366350235772238],
            [-1.21256310506704, 51.366079049227771],
            [-1.210790829164825, 51.367148849268695],
            [-1.209899605294097, 51.366683360947803],
            [-1.206644355480206, 51.366946413557372],
            [-1.206379625577977, 51.366222575063702],
            [-1.20496798198928, 51.36644949394568],
            [-1.202102461047402, 51.366189938681131],
            [-1.200623401723792, 51.365667324161578],
            [-1.199949476841891, 51.364691604491497],
            [-1.194816641065507, 51.363741981427388],
            [-1.188887913939465, 51.363220035639465],
            [-1.182338833039372, 51.360115491602699],
            [-1.176913841168781, 51.357324201919383],
            [-1.169641355629279, 51.360725784926437],
            [-1.146877177350095, 51.359368162028886],
            [-1.144150209154834, 51.357263057148131],
            [-1.144034029312463, 51.35725501511476],
            [-1.138609383689219, 51.357190990188812],
            [-1.131335159216191, 51.358028395353152],
            [-1.12478618263818, 51.358100161048959],
            [-1.124679358861552, 51.359711612927931],
            [-1.123763719646838, 51.361256774013484],
            [-1.120163709700544, 51.360541022045787],
            [-1.120190048392549, 51.360215712584086],
            [-1.119297343713603, 51.360256664520108],
            [-1.119262593358212, 51.359896726871057],
            [-1.117379058436313, 51.360003048169943],
            [-1.115810372236982, 51.360465996474879],
            [-1.115409734344149, 51.362549995962723],
            [-1.116437016873691, 51.3647742508007],
            [-1.11615761858637, 51.36645452963176],
            [-1.117533926239849, 51.368089737535534],
            [-1.115476654466693, 51.374214796233098],
            [-1.11377243978065, 51.374825060888526],
            [-1.100238870312079, 51.379425610487729],
            [-1.098818764013212, 51.380108874723291],
            [-1.097460590423431, 51.38136718061601],
            [-1.097536884128271, 51.381725645345462],
            [-1.086638673924011, 51.383915279703253],
            [-1.082799622650911, 51.381726347309503],
            [-1.075366726631376, 51.37949550369153],
            [-1.080010396565932, 51.374635051177691],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000085",
        LAD13CDO: "24UC",
        LAD13NM: "East Hampshire",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.860837968287032, 51.211969206889073],
            [-0.858805618996953, 51.211944013243397],
            [-0.856818321284983, 51.212354451236322],
            [-0.855543968328366, 51.211667575470166],
            [-0.851137269779982, 51.211496712457865],
            [-0.849079944947367, 51.211038575073935],
            [-0.848929027340678, 51.210717864327847],
            [-0.84707148334465, 51.208854360747502],
            [-0.84610825856238, 51.206553628834541],
            [-0.847044329149312, 51.2059855479052],
            [-0.845511155611336, 51.20438419077572],
            [-0.843828849559972, 51.201711254089268],
            [-0.845768457813921, 51.200789803363932],
            [-0.845548614334283, 51.200136591222844],
            [-0.843775768236445, 51.198914118806478],
            [-0.843333593519903, 51.1989106486322],
            [-0.843063645789624, 51.199402557894437],
            [-0.842649847876048, 51.199351706379268],
            [-0.839309109277726, 51.19633766538049],
            [-0.838124597649372, 51.195236041036793],
            [-0.838108913808976, 51.194721524062892],
            [-0.837180051171964, 51.194325627920108],
            [-0.83536367119008, 51.191668315210443],
            [-0.830206307035252, 51.188385026414068],
            [-0.828317621265619, 51.186008337166491],
            [-0.828103682081706, 51.18485247622673],
            [-0.827198557088562, 51.184659964076126],
            [-0.82544411448578, 51.181235163126473],
            [-0.826573424168106, 51.178567669480557],
            [-0.826825484550119, 51.17754236993553],
            [-0.827609593917198, 51.17759339237449],
            [-0.827746979180763, 51.17702735073658],
            [-0.827136328222318, 51.174594191870966],
            [-0.829174596468302, 51.167823566873331],
            [-0.830390606994426, 51.162723561678106],
            [-0.830041886631782, 51.16248807152131],
            [-0.831267303908441, 51.16125127718314],
            [-0.830475636337591, 51.161227179771998],
            [-0.831320157868689, 51.159102624919356],
            [-0.830939322878199, 51.155724875176759],
            [-0.833959322712997, 51.149257109492375],
            [-0.834221614489521, 51.147585338446163],
            [-0.833225388005616, 51.147971971398356],
            [-0.83234775412969, 51.147737505366337],
            [-0.828391375689587, 51.145269998009667],
            [-0.825986998985434, 51.145768351528829],
            [-0.824839856315437, 51.147593083435112],
            [-0.82335590173509, 51.146410052592614],
            [-0.817405673354537, 51.147754666581065],
            [-0.817862990999945, 51.148041650576808],
            [-0.817438406558513, 51.148487879167661],
            [-0.818014413499627, 51.148774263777483],
            [-0.81703919753905, 51.148947851436468],
            [-0.816813057555769, 51.149516582606154],
            [-0.815671415757227, 51.150158778680783],
            [-0.812583802674986, 51.151191281870659],
            [-0.813582073121122, 51.151849763041675],
            [-0.811047485208351, 51.152387855084385],
            [-0.810967118251499, 51.152733246101761],
            [-0.809456420352383, 51.153597339640626],
            [-0.80828993545661, 51.15375272168535],
            [-0.808312730798766, 51.154033517116979],
            [-0.806878743030657, 51.153915488319065],
            [-0.806915090735557, 51.154502164247639],
            [-0.805683239138984, 51.15475127404919],
            [-0.805459965587738, 51.155368571598459],
            [-0.804747888973981, 51.155367593276232],
            [-0.803400149288684, 51.154180305938375],
            [-0.803435347210115, 51.153040427749637],
            [-0.802314813007925, 51.152690846398556],
            [-0.798414061210978, 51.152531246760901],
            [-0.799162357475086, 51.151410381555259],
            [-0.796986653216011, 51.150897940803873],
            [-0.795015096779256, 51.149520692472862],
            [-0.794472501972335, 51.149493520363293],
            [-0.794578977829247, 51.148095395746068],
            [-0.793576336017168, 51.145479053080621],
            [-0.793774741629242, 51.144832744604869],
            [-0.79254374862179, 51.143686098480863],
            [-0.791301426654695, 51.139417146027782],
            [-0.790161274404654, 51.13857625564016],
            [-0.786936593509804, 51.137537502644818],
            [-0.78479584409767, 51.136104366343183],
            [-0.781041312079255, 51.135223606122345],
            [-0.780527333594086, 51.134442199526312],
            [-0.780919605693879, 51.132788978149676],
            [-0.78060071760479, 51.131275819616384],
            [-0.782601153714728, 51.130150118571962],
            [-0.78303886957589, 51.129319273671967],
            [-0.782365473284058, 51.128514633564969],
            [-0.780897247795262, 51.128100973155981],
            [-0.78066927730692, 51.127803643817394],
            [-0.779305718390838, 51.127595183419437],
            [-0.777781996063364, 51.126903936260192],
            [-0.774092892182525, 51.123098378827564],
            [-0.773753687669984, 51.123173057503152],
            [-0.772312348314142, 51.121754210294057],
            [-0.770679884810369, 51.121025757370454],
            [-0.769538328918326, 51.118869943653436],
            [-0.76929976384186, 51.117147169853887],
            [-0.764486342800848, 51.115270941549966],
            [-0.761856394327884, 51.114940080733064],
            [-0.760438705578857, 51.115383680713649],
            [-0.758307428577225, 51.115317907729811],
            [-0.756171351526243, 51.114207115646316],
            [-0.754120611208091, 51.113751847764341],
            [-0.753055793245898, 51.113185661285989],
            [-0.75022219907982, 51.113473748940891],
            [-0.748492756750677, 51.112392339099067],
            [-0.745545217777871, 51.112193498004252],
            [-0.746869315156, 51.108450619334803],
            [-0.747584481938129, 51.107782043042555],
            [-0.745734186892423, 51.106425923223497],
            [-0.744703216667581, 51.106413062397777],
            [-0.749292348748004, 51.103251003194103],
            [-0.756305395186179, 51.104582055479256],
            [-0.759884231269218, 51.104872752441487],
            [-0.764931417497903, 51.102914541443788],
            [-0.765774393690856, 51.102850602228344],
            [-0.767820332391907, 51.103418922916575],
            [-0.768905927078735, 51.103255002640005],
            [-0.76707196703251, 51.10239578009508],
            [-0.763746551158204, 51.102730264218501],
            [-0.761353324822545, 51.10382990315361],
            [-0.759834214817742, 51.103912720311619],
            [-0.759463238493125, 51.103577860735697],
            [-0.758642179693615, 51.102342567844779],
            [-0.758524899161654, 51.100588679937211],
            [-0.758015194207411, 51.100635424118813],
            [-0.756260303611618, 51.098330871586739],
            [-0.755871704758184, 51.098548853217203],
            [-0.754332392927428, 51.095881466852951],
            [-0.75517667356436, 51.09533921545998],
            [-0.755666356484236, 51.094333666471762],
            [-0.758572479882923, 51.092038187143899],
            [-0.758919804850625, 51.090852161354221],
            [-0.758585056855486, 51.090821630224639],
            [-0.758282186370863, 51.090347205191371],
            [-0.758593915516326, 51.090170664395465],
            [-0.757524332771866, 51.088840997192747],
            [-0.757402919809249, 51.087776788291663],
            [-0.754748351826077, 51.086614587070059],
            [-0.753608178253129, 51.086696860320906],
            [-0.753500292272691, 51.086461004689312],
            [-0.755045996043343, 51.085845296702402],
            [-0.755119406157309, 51.085291238014413],
            [-0.755837981015898, 51.085116337731634],
            [-0.757567150988176, 51.085057388924078],
            [-0.7591856929792, 51.085504420245812],
            [-0.760951930262416, 51.085287545881954],
            [-0.761574771918887, 51.085542338791839],
            [-0.764106672174265, 51.08515007612386],
            [-0.765254604751939, 51.084398737338653],
            [-0.765762505499634, 51.084681070191806],
            [-0.766620419204047, 51.084633470158288],
            [-0.768506047697914, 51.084004084276479],
            [-0.768805897571647, 51.083518947026995],
            [-0.769311039054978, 51.083636671879027],
            [-0.770593549962322, 51.083189746050863],
            [-0.771057516080037, 51.08254446282929],
            [-0.77222910154472, 51.082564864612934],
            [-0.77264758032155, 51.08196495941872],
            [-0.773361098219402, 51.082192764523249],
            [-0.774359331308802, 51.081533288566632],
            [-0.775837156972997, 51.08120075946465],
            [-0.777306074164421, 51.079797106875937],
            [-0.780806066211457, 51.079380431977292],
            [-0.782297178465727, 51.077286321202962],
            [-0.782722329501547, 51.075935572150698],
            [-0.784418582917487, 51.075102525860814],
            [-0.785632506610641, 51.073170952658451],
            [-0.788108959887893, 51.072025819625608],
            [-0.793037521326641, 51.067638982565775],
            [-0.795488785544976, 51.064916140394125],
            [-0.796953819609993, 51.063894384540411],
            [-0.798034058426947, 51.063511622219423],
            [-0.800229546159672, 51.063321950321225],
            [-0.802775474404303, 51.063985622455704],
            [-0.805851628842977, 51.064123196568026],
            [-0.808268667084925, 51.063419464425472],
            [-0.81156792661299, 51.062980042473271],
            [-0.813273721910881, 51.06558632305466],
            [-0.814522956218485, 51.066685295707792],
            [-0.815541584664546, 51.06546993761976],
            [-0.815585613284743, 51.062402129247296],
            [-0.817312155581165, 51.061233492546876],
            [-0.818680515179369, 51.060734752937769],
            [-0.819930239959354, 51.060691620997282],
            [-0.822983321671619, 51.061901321694862],
            [-0.826386974682001, 51.06133034282901],
            [-0.827350200692155, 51.062234765904194],
            [-0.829905061315638, 51.06357597236174],
            [-0.829700462281683, 51.06716284222712],
            [-0.82916125414678, 51.067774330604585],
            [-0.831437491075144, 51.068518304766172],
            [-0.837309009141131, 51.069208119273178],
            [-0.838449693296742, 51.068511746691769],
            [-0.842865998423848, 51.066891924942297],
            [-0.844029076634305, 51.066035652957538],
            [-0.846055290254183, 51.064213103174829],
            [-0.850130477639947, 51.059341542031142],
            [-0.849713816479611, 51.055655003564624],
            [-0.851075144317511, 51.048153330552324],
            [-0.851781904912545, 51.046167528070463],
            [-0.852828754889009, 51.044848708704194],
            [-0.860697234329472, 51.041888883924166],
            [-0.867047322437224, 51.038510058562714],
            [-0.878558015002081, 51.029572765430395],
            [-0.882674424905272, 51.026867663474],
            [-0.883838036321839, 51.026510087034069],
            [-0.885903374752452, 51.026605329907341],
            [-0.887895666397182, 51.026237624261718],
            [-0.891977776685885, 51.024756654500976],
            [-0.893102755186407, 51.023800615368565],
            [-0.894522906211637, 51.023501115985781],
            [-0.895297067333746, 51.023384338764217],
            [-0.897078257367804, 51.021929080964419],
            [-0.898525048916698, 51.017673061992816],
            [-0.897980787139865, 51.016816336208386],
            [-0.897105705574311, 51.016324281799541],
            [-0.89782396316334, 51.010320430002167],
            [-0.896867232980428, 51.008888778736356],
            [-0.894303448650709, 51.006948232549654],
            [-0.894379641803246, 51.006501123302229],
            [-0.893429097204742, 51.005356364065662],
            [-0.893406976543016, 51.003110708023378],
            [-0.893400754991339, 51.002713177099594],
            [-0.894701046482722, 51.002512359131465],
            [-0.894696236075756, 51.001934990022853],
            [-0.895852441679646, 51.000898284903798],
            [-0.896669624840213, 51.000814279170598],
            [-0.896902838337549, 51.001436968916487],
            [-0.897985911773963, 51.001078492179914],
            [-0.899726905421135, 51.001282842656522],
            [-0.90109249333313, 51.000788511216527],
            [-0.901086550010959, 51.000497095800029],
            [-0.901810093964376, 51.000213437550485],
            [-0.901934656916769, 50.999162476389493],
            [-0.903964818591509, 50.998583529489032],
            [-0.905639370893591, 50.996823189602736],
            [-0.906023891156072, 50.99574228482156],
            [-0.907942044269589, 50.994098397880308],
            [-0.907593033249337, 50.990633889108715],
            [-0.909646791288259, 50.988967860608263],
            [-0.91052159614097, 50.988725127398531],
            [-0.910667809876578, 50.987956724884484],
            [-0.911756721112955, 50.987147646608605],
            [-0.912408132811233, 50.985731982994892],
            [-0.913678596142921, 50.984709653559371],
            [-0.915171230252321, 50.981120188483438],
            [-0.916559134573028, 50.980307546186737],
            [-0.917709736222845, 50.978670765866617],
            [-0.915245508945499, 50.971450230083192],
            [-0.915542584496863, 50.970619372765732],
            [-0.916438194650746, 50.970017084254799],
            [-0.916994991051081, 50.968611305796614],
            [-0.920757433537961, 50.964563475369751],
            [-0.92487243406262, 50.962363154998926],
            [-0.929290599842379, 50.958354354877137],
            [-0.929560904617471, 50.956070906662809],
            [-0.928099179750188, 50.954811124864086],
            [-0.926540048176279, 50.952529765290372],
            [-0.928961444079706, 50.95235595208284],
            [-0.93020954936002, 50.948157936305925],
            [-0.931864234523964, 50.945587694867164],
            [-0.937810207559613, 50.944455786317661],
            [-0.942127148027073, 50.942901467011986],
            [-0.94115646208888, 50.942460125863668],
            [-0.937323877173471, 50.94212406722685],
            [-0.935335818682629, 50.941110477510612],
            [-0.934426158390954, 50.938999701365709],
            [-0.931202092133229, 50.935290450025057],
            [-0.931030163154606, 50.934471444020872],
            [-0.929017146541054, 50.932581630284105],
            [-0.928151554083277, 50.931111482736561],
            [-0.927655992096254, 50.927846192417967],
            [-0.924030377496637, 50.924206784385099],
            [-0.925996945696415, 50.923083714787573],
            [-0.936256108070308, 50.919797261944574],
            [-0.938038667297008, 50.919487050537903],
            [-0.939416953235607, 50.918810736420987],
            [-0.941816962979507, 50.915736328313784],
            [-0.942973455235287, 50.913100255509939],
            [-0.946144666949113, 50.910494059942074],
            [-0.947431126725634, 50.908448135722601],
            [-0.948656205042178, 50.905017669189661],
            [-0.949569937573721, 50.90498812283915],
            [-0.949653626163526, 50.904112987387116],
            [-0.950386327060439, 50.901317451880573],
            [-0.95020434872765, 50.89846872749883],
            [-0.951997530762393, 50.897010931404814],
            [-0.955621470416114, 50.891127999388139],
            [-0.957597322781623, 50.890638466852252],
            [-0.951464506212918, 50.886360575459818],
            [-0.947119362812908, 50.884114659283952],
            [-0.945346376304268, 50.880347790432914],
            [-0.941242836986453, 50.87763890835312],
            [-0.93951800726251, 50.875476686774206],
            [-0.93948604988663, 50.874254282739834],
            [-0.938707903810955, 50.873847034455295],
            [-0.938623857717626, 50.873730263990971],
            [-0.943354786160437, 50.872494410168166],
            [-0.953561530164925, 50.86820238170381],
            [-0.955743505564807, 50.868239905200937],
            [-0.958024720300774, 50.867714428737877],
            [-0.96086234747271, 50.867663282917867],
            [-0.963400751176986, 50.867263191099084],
            [-0.960458928985275, 50.872192930569547],
            [-0.96335161236055, 50.8730037174317],
            [-0.968026254989329, 50.873447106843948],
            [-0.966925422285378, 50.875025486763967],
            [-0.968970752148854, 50.875666763094372],
            [-0.968271615495089, 50.87771992050898],
            [-0.96990634347929, 50.878036510468682],
            [-0.971708022888443, 50.877872538232872],
            [-0.972586012786878, 50.878668029953388],
            [-0.974261579937531, 50.878033488566899],
            [-0.975853576477407, 50.878032176722222],
            [-0.9765182284362, 50.879161195323213],
            [-0.978729964278123, 50.878316363000074],
            [-0.985459992130119, 50.885009856924945],
            [-0.986007651103833, 50.884732243539361],
            [-0.98646763431545, 50.885088301592127],
            [-0.986553844741066, 50.886029236660448],
            [-0.992351775139532, 50.891675569046626],
            [-0.993233351335327, 50.89206895970645],
            [-0.995774496692799, 50.893044965884272],
            [-0.997309790296744, 50.894648965052056],
            [-0.99878363500588, 50.895409802557232],
            [-1.002753989439518, 50.895727047547247],
            [-1.004722789894278, 50.896554984285373],
            [-1.001496277093288, 50.899485128825603],
            [-1.007283646352127, 50.901977779457198],
            [-1.007794876726129, 50.902547762848741],
            [-1.009115333567941, 50.902715447216544],
            [-1.008988938602687, 50.902975161811426],
            [-1.011149245362043, 50.903778534286126],
            [-1.012025265059247, 50.902503595861823],
            [-1.015218992086198, 50.903399263287746],
            [-1.018506244505457, 50.903843298452564],
            [-1.018386027346191, 50.904487960537935],
            [-1.019400697636841, 50.904999174563478],
            [-1.018878981935243, 50.906747460194119],
            [-1.019196917540106, 50.907117030984182],
            [-1.018705927808885, 50.908078716722763],
            [-1.017739914535021, 50.908496846953668],
            [-1.0187492261913, 50.909063775407141],
            [-1.021671109441901, 50.909655725660123],
            [-1.024543845511803, 50.90947202626333],
            [-1.025565933110379, 50.90909117764663],
            [-1.023876136864333, 50.90616884403687],
            [-1.028456546968957, 50.905790680955704],
            [-1.029280684599434, 50.904664460997317],
            [-1.032182260614073, 50.904788362632921],
            [-1.034067752066573, 50.905286878646464],
            [-1.034132072692389, 50.904171423462493],
            [-1.038042045122906, 50.903601190077097],
            [-1.038831977147117, 50.903156263736214],
            [-1.041687552145461, 50.902607800241334],
            [-1.044483086255072, 50.90234923812536],
            [-1.048494486418863, 50.902649972725229],
            [-1.046226817509545, 50.909928098361853],
            [-1.044871555229297, 50.912070756806479],
            [-1.04530401888062, 50.913807171968124],
            [-1.04420408170087, 50.915700115156035],
            [-1.044802693225423, 50.916269749295822],
            [-1.043425465054934, 50.916695518505364],
            [-1.043136775357614, 50.91641348306996],
            [-1.040310222794116, 50.917793139823765],
            [-1.037376799496402, 50.920345384414254],
            [-1.03637014213119, 50.922038486459329],
            [-1.03713475082454, 50.924836100127393],
            [-1.039388917364419, 50.92778715151168],
            [-1.039218838243859, 50.928307323762922],
            [-1.0377445463309, 50.929279877460182],
            [-1.036605253691156, 50.931736257989854],
            [-1.037204352692095, 50.932011875192579],
            [-1.036633985760619, 50.932825499037072],
            [-1.037904979762586, 50.933229850862297],
            [-1.036978479826521, 50.935094474781565],
            [-1.036740724306742, 50.937855039605395],
            [-1.03749759614897, 50.94090257285238],
            [-1.037438813911162, 50.943543211072019],
            [-1.038483489450438, 50.943961876558497],
            [-1.038528304494015, 50.945170848477062],
            [-1.03799734771993, 50.945309459121368],
            [-1.037648728497555, 50.947579012526838],
            [-1.03632880763435, 50.949453874811496],
            [-1.035705160997346, 50.951524214716571],
            [-1.037826082283154, 50.951193690212925],
            [-1.040701787518912, 50.951747902017104],
            [-1.040497401211708, 50.952063661187353],
            [-1.040918353943006, 50.95250055976252],
            [-1.04165075170992, 50.952885157478718],
            [-1.043085409874136, 50.952905011299976],
            [-1.044374372034738, 50.954406529772783],
            [-1.045999494387847, 50.954444092138523],
            [-1.046270112533775, 50.954716977900162],
            [-1.04579098705436, 50.959412576939869],
            [-1.044677650602776, 50.963364698035264],
            [-1.045257700704384, 50.965495278160823],
            [-1.046048193655952, 50.966462139221228],
            [-1.045251659087905, 50.968639022394306],
            [-1.051757420435758, 50.969538202602784],
            [-1.053580733422876, 50.970922549533299],
            [-1.054425842706036, 50.972369096627787],
            [-1.054116746095076, 50.974895294137497],
            [-1.055544054277366, 50.977558735874531],
            [-1.056627642353488, 50.977437997267444],
            [-1.056766113486293, 50.977704394595158],
            [-1.060253385346489, 50.978326899545614],
            [-1.061261591596312, 50.977988791927281],
            [-1.061507744497202, 50.978355865568844],
            [-1.063381307598999, 50.977698258594749],
            [-1.0663689536107, 50.984985421116704],
            [-1.067522433859627, 50.985721226107287],
            [-1.066850553184327, 50.988535911439854],
            [-1.067702979747247, 50.989653289173944],
            [-1.066869122337687, 50.990026116911608],
            [-1.066782246019557, 50.990734931231572],
            [-1.068174432620649, 50.991867405285497],
            [-1.068270378949897, 50.992555196907759],
            [-1.069528446896358, 50.993700972790187],
            [-1.069104456925725, 50.994524005983081],
            [-1.069435854720916, 50.995908791025414],
            [-1.068821807188664, 50.998252737276836],
            [-1.069174662784609, 50.999563055512368],
            [-1.067942375735384, 51.003739233384046],
            [-1.068741705436104, 51.00638489695617],
            [-1.068802253948143, 51.008557960443696],
            [-1.067310559054306, 51.008879686154742],
            [-1.067361400111344, 51.011746890558641],
            [-1.064688775736262, 51.011668880274321],
            [-1.063929012255854, 51.016413516252932],
            [-1.062358833807493, 51.020118412464974],
            [-1.063563824248597, 51.024849109816913],
            [-1.063309729962504, 51.026066449649996],
            [-1.063979295272252, 51.026096992258481],
            [-1.064244202580563, 51.028591818684504],
            [-1.065067949669929, 51.029962563491686],
            [-1.064955611548793, 51.030939142981715],
            [-1.072800446577777, 51.031293886823391],
            [-1.073426558429542, 51.032220575832206],
            [-1.074517269808745, 51.032591611448005],
            [-1.07380098957364, 51.033969873117769],
            [-1.064276502718449, 51.042640952369247],
            [-1.055243868827091, 51.045752519451156],
            [-1.05378996186711, 51.047237099388539],
            [-1.055781891205971, 51.047425859231815],
            [-1.060427301337659, 51.046696233868722],
            [-1.059971095955546, 51.045765448179644],
            [-1.062211533514763, 51.044524503679455],
            [-1.065277557964486, 51.045066122919543],
            [-1.066227093386483, 51.046794859379951],
            [-1.067352699924599, 51.047644635203078],
            [-1.069478099018476, 51.047156198337781],
            [-1.076438877017381, 51.048203258487909],
            [-1.079929346018505, 51.047287477740468],
            [-1.081698083831736, 51.046431819046489],
            [-1.082098526288381, 51.046741604880097],
            [-1.081238330596542, 51.048904714793927],
            [-1.082963178350648, 51.048901171839276],
            [-1.083817457908782, 51.050361041550552],
            [-1.084955262983878, 51.05068468656993],
            [-1.088476045061266, 51.053386528342195],
            [-1.089685915856802, 51.053896831177894],
            [-1.090675421942688, 51.054951245611328],
            [-1.090916982446958, 51.055730965659365],
            [-1.088848152853453, 51.056382096029722],
            [-1.088322093026694, 51.058103633939034],
            [-1.088934990046255, 51.060755768824286],
            [-1.090143874100575, 51.061763336593437],
            [-1.091289760314434, 51.061544740982349],
            [-1.092211754027479, 51.061756030130027],
            [-1.094342340335653, 51.062557587352721],
            [-1.095002103530277, 51.06319216212767],
            [-1.094675908094578, 51.06535679846332],
            [-1.092002299877069, 51.067576964998381],
            [-1.094800731009395, 51.06832344782935],
            [-1.099150041918333, 51.070719331152496],
            [-1.100153021109122, 51.071549854918892],
            [-1.1015541836029, 51.073859075486219],
            [-1.10715244935369, 51.078899902461806],
            [-1.106458925883429, 51.081110318183192],
            [-1.108186134560366, 51.08483374139545],
            [-1.108665822597228, 51.087138536311762],
            [-1.10777850007362, 51.087173134557567],
            [-1.108141797975394, 51.088550832927723],
            [-1.106539371922302, 51.088645610033993],
            [-1.106007410465793, 51.090613562090169],
            [-1.105438919524032, 51.090919450155127],
            [-1.102267007874073, 51.091684670499937],
            [-1.101937779455079, 51.091504993907883],
            [-1.100283963810926, 51.092108256605442],
            [-1.101092045249922, 51.092925578452906],
            [-1.100458523874329, 51.094004282386429],
            [-1.099610693514234, 51.094057107364826],
            [-1.098864180826759, 51.093599043639458],
            [-1.098242049789182, 51.093648202169874],
            [-1.095407985514996, 51.094867256159013],
            [-1.092050401926804, 51.095319629174412],
            [-1.08583030692452, 51.097252183329118],
            [-1.085279668506451, 51.097872844559987],
            [-1.082872370162693, 51.099074250131892],
            [-1.083819401360371, 51.100872943667305],
            [-1.083303063197022, 51.102933534610429],
            [-1.080512907774152, 51.10455452899771],
            [-1.079287140119616, 51.10564644491069],
            [-1.075163625209812, 51.106926789792908],
            [-1.073240336357588, 51.107174141566681],
            [-1.072226846936795, 51.106926011583667],
            [-1.072560171615877, 51.107471791246383],
            [-1.073196286403522, 51.107876991775996],
            [-1.074685240333947, 51.107922052507554],
            [-1.080778693098201, 51.109446638937179],
            [-1.084970299838424, 51.109568530161823],
            [-1.087727435536883, 51.109487555248343],
            [-1.093940563800627, 51.107772459756056],
            [-1.093176646231175, 51.108852810019492],
            [-1.091616101770182, 51.109834350218996],
            [-1.090547907955455, 51.112002186747752],
            [-1.091254294136378, 51.114590265534588],
            [-1.091505902680829, 51.120265457892593],
            [-1.09379157872674, 51.121748031340417],
            [-1.095193997389288, 51.124052819046234],
            [-1.094958805509319, 51.125997828220044],
            [-1.092502813077352, 51.129695308787831],
            [-1.093327888157586, 51.129889645055982],
            [-1.093353363815988, 51.130340355389414],
            [-1.092850451844217, 51.130625108518963],
            [-1.094698649162891, 51.132947741703589],
            [-1.093601284526201, 51.134905853233128],
            [-1.093489282866319, 51.137049640411981],
            [-1.095099454057294, 51.136865175675716],
            [-1.095430266189132, 51.13807629392295],
            [-1.095058515920687, 51.140349359540075],
            [-1.099567290904212, 51.140698877684102],
            [-1.102035659255922, 51.14034467117974],
            [-1.109630245773702, 51.137072886103077],
            [-1.11203221435737, 51.136589374501561],
            [-1.113543161587023, 51.137103500113795],
            [-1.116501302405946, 51.137283241781127],
            [-1.125230435319449, 51.138937767861755],
            [-1.12600950647297, 51.138859063485356],
            [-1.126415415172622, 51.138027614957608],
            [-1.12973640341913, 51.138604489810149],
            [-1.130745491705424, 51.140358284749787],
            [-1.130774110990752, 51.141955518716578],
            [-1.131820611682339, 51.143625953285266],
            [-1.130518553545477, 51.145591877359962],
            [-1.126131184166407, 51.148164239012978],
            [-1.122249534037844, 51.149978589581977],
            [-1.11725771713071, 51.151436441753553],
            [-1.117548176807642, 51.152399901959484],
            [-1.11628120347645, 51.153681623131796],
            [-1.111806283756761, 51.156338212846926],
            [-1.109069041134674, 51.158585241158747],
            [-1.10723374972449, 51.1613588407685],
            [-1.104936681862644, 51.163151425591138],
            [-1.100469500251028, 51.163122564543613],
            [-1.099325988000955, 51.16397342433158],
            [-1.096216829880795, 51.164704797425188],
            [-1.094204356935217, 51.165737728953225],
            [-1.087393727482937, 51.167691873562347],
            [-1.08503606562473, 51.167142021935888],
            [-1.08279213594124, 51.165691993243179],
            [-1.080551746119935, 51.165006287946476],
            [-1.07503396421276, 51.164295576969081],
            [-1.074265649975109, 51.164454058791222],
            [-1.077487095659891, 51.172737959013375],
            [-1.07734024736922, 51.173287124923995],
            [-1.074870632520191, 51.175107434196221],
            [-1.076212851016542, 51.177736569913186],
            [-1.076026224069867, 51.179200826656427],
            [-1.074703939553767, 51.180720852784809],
            [-1.073431280440842, 51.185328279968111],
            [-1.071120326760414, 51.187757637279567],
            [-1.061707599648779, 51.190519589429364],
            [-1.061861831880624, 51.188772742114971],
            [-1.057269055812555, 51.18916836097889],
            [-1.0520181267432, 51.189067495975614],
            [-1.050043798374502, 51.187935553793253],
            [-1.044325198983859, 51.189699127445792],
            [-1.035706917526875, 51.190708398685331],
            [-1.029680805430267, 51.189967988861476],
            [-1.023399632684209, 51.189862688340845],
            [-1.019945072560289, 51.188792510169101],
            [-1.013146197227885, 51.187743533164436],
            [-1.010918268936077, 51.188412630447807],
            [-1.008130528824621, 51.189959979202868],
            [-1.004901765780055, 51.189716756273796],
            [-1.004096796880482, 51.188955460522934],
            [-1.002717130958138, 51.189298005651196],
            [-1.001044972996649, 51.189202814052841],
            [-0.997220466236756, 51.190040554303771],
            [-0.994337641995314, 51.189947476055941],
            [-0.989505067354939, 51.190871615406252],
            [-0.985898446417212, 51.190437566048111],
            [-0.985305056647925, 51.190025074004062],
            [-0.982172794257695, 51.189303672577687],
            [-0.979849391102116, 51.18792111654448],
            [-0.977119623078907, 51.187708451396773],
            [-0.975454247886943, 51.186860301307959],
            [-0.974823110461934, 51.186156075869043],
            [-0.973836202836334, 51.186009844561582],
            [-0.969322971041396, 51.187307330782282],
            [-0.967218766790089, 51.188303106230514],
            [-0.964974098434002, 51.190135679984685],
            [-0.964260966320684, 51.191881961104727],
            [-0.962033395717728, 51.194978940036485],
            [-0.960874806541959, 51.195587325524407],
            [-0.957207452701495, 51.196381093814828],
            [-0.953843642595308, 51.198364438273501],
            [-0.951191474257379, 51.200448484317846],
            [-0.949073960494243, 51.198965550927014],
            [-0.933192637702524, 51.203307204102593],
            [-0.92992761862372, 51.20299863785462],
            [-0.922200723619677, 51.203562589769923],
            [-0.92106393606577, 51.203695991814826],
            [-0.919553574791476, 51.204623548437986],
            [-0.917500630474985, 51.205123432208659],
            [-0.914901272188854, 51.205177594496476],
            [-0.912067100067099, 51.205780742446052],
            [-0.909193596799009, 51.205500414791445],
            [-0.905628304332041, 51.206748530168404],
            [-0.90665373837774, 51.208240039290217],
            [-0.904575997105495, 51.208806907294324],
            [-0.90432362305186, 51.209910593529372],
            [-0.90300081441473, 51.2093083102355],
            [-0.896016666830509, 51.209944038667224],
            [-0.884407157212119, 51.210207137399607],
            [-0.880903168937379, 51.210300477633403],
            [-0.872054831625839, 51.212507672538521],
            [-0.869068402106523, 51.212738731288887],
            [-0.863350562569734, 51.212550257253859],
            [-0.860837968287032, 51.211969206889073],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000086",
        LAD13CDO: "24UD",
        LAD13NM: "Eastleigh",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.392985156969756, 51.004368635149021],
            [-1.389071301148285, 51.003622563458386],
            [-1.386416686094182, 51.00423002944266],
            [-1.384688852749969, 51.004265009361674],
            [-1.378857223084818, 51.002954556067635],
            [-1.369360487658458, 51.00153596892541],
            [-1.367330869218526, 51.00029394832405],
            [-1.363508192519322, 51.000021408817425],
            [-1.359686553204211, 50.998640891782884],
            [-1.35714840869825, 50.99844351559652],
            [-1.356537631838771, 50.998402386849314],
            [-1.356224252371563, 50.997774793156019],
            [-1.354539058741976, 50.998449817535416],
            [-1.353478489129083, 50.998166092077703],
            [-1.353923758777897, 50.997092168856696],
            [-1.351122800655416, 50.996742136804812],
            [-1.351191948913039, 50.995359495378352],
            [-1.350535259539113, 50.994629265187399],
            [-1.349734612599894, 50.994413492883794],
            [-1.349236119080116, 50.993275881789103],
            [-1.346871786009401, 50.991073051458073],
            [-1.345854598287501, 50.992584375643972],
            [-1.343564872820012, 50.991975343689134],
            [-1.34342248275323, 50.991261448786901],
            [-1.342776490826649, 50.99089632458368],
            [-1.345631440690693, 50.985551089325654],
            [-1.346577149105146, 50.981407295080928],
            [-1.344929903351885, 50.982556292738998],
            [-1.345046638976632, 50.984081156070808],
            [-1.344068221900187, 50.984869696133757],
            [-1.34247720071398, 50.9849497802687],
            [-1.339511373797671, 50.983926808720192],
            [-1.337658208779655, 50.984310187234136],
            [-1.335482776716131, 50.983514601059937],
            [-1.333927856661147, 50.983363669612338],
            [-1.330402068454676, 50.983755373010119],
            [-1.329106575904472, 50.982111328188509],
            [-1.32854140052204, 50.980603654962131],
            [-1.327689298297515, 50.980127554515185],
            [-1.323103160664118, 50.98390036727676],
            [-1.323305205380775, 50.985878064586998],
            [-1.322739433544648, 50.987555464417142],
            [-1.31928196073661, 50.987144208410413],
            [-1.31640510804897, 50.985132005055974],
            [-1.3153107726213, 50.985146281322237],
            [-1.313418802749051, 50.984569560003628],
            [-1.312018935282592, 50.984725888346816],
            [-1.30177840485499, 50.982749773525669],
            [-1.295890804368852, 50.982834047835759],
            [-1.293162112464602, 50.983720412842089],
            [-1.290586468328715, 50.982876605541399],
            [-1.290491142589075, 50.9823077067756],
            [-1.295150296444175, 50.976133836267856],
            [-1.295242859259483, 50.974629064256668],
            [-1.293439776376404, 50.974152368809982],
            [-1.290349587137797, 50.97411565852854],
            [-1.284957580714506, 50.973531553652137],
            [-1.280322362324615, 50.974398743831827],
            [-1.277004260188755, 50.97436297435172],
            [-1.275400676192336, 50.973892629617708],
            [-1.272862481755687, 50.977091654586943],
            [-1.272083195290894, 50.97910560127012],
            [-1.267773033493725, 50.978636246504763],
            [-1.266566687300739, 50.980163687560008],
            [-1.262746233953383, 50.980161228921908],
            [-1.259642853671221, 50.979227978541971],
            [-1.258166863435566, 50.978349050533964],
            [-1.26028224918827, 50.974486723939322],
            [-1.265669134543356, 50.968968772315179],
            [-1.268511518731994, 50.967531624198671],
            [-1.269217030812454, 50.967585497087178],
            [-1.269340510405004, 50.966431640195317],
            [-1.271771991948588, 50.962658303770098],
            [-1.273147393327954, 50.96221095091164],
            [-1.276140251545735, 50.961953462255018],
            [-1.276965795389805, 50.960965807876846],
            [-1.278346137819746, 50.960192897952041],
            [-1.28197728312, 50.958698243072888],
            [-1.283022249245038, 50.957781135772187],
            [-1.283362112496527, 50.956234721276459],
            [-1.285933486956486, 50.954371941853253],
            [-1.28722194050941, 50.952789044560056],
            [-1.289290820453081, 50.951274729159685],
            [-1.290495629496571, 50.951246081955354],
            [-1.291104036005782, 50.950837021384423],
            [-1.292477459259499, 50.948420980514854],
            [-1.291825090223496, 50.947416167448345],
            [-1.290398923899841, 50.946563122075723],
            [-1.28800226596328, 50.945414591330774],
            [-1.2867266907468, 50.942727954951934],
            [-1.286999002691036, 50.939626315592584],
            [-1.285079448744737, 50.937370961900044],
            [-1.28473732064586, 50.936386889084211],
            [-1.282217119686779, 50.936558475157199],
            [-1.281773491280162, 50.936255399731486],
            [-1.280627706412524, 50.936634127188519],
            [-1.27945707080272, 50.936409294708405],
            [-1.278881027081413, 50.936663824527848],
            [-1.276337673057348, 50.935848665552513],
            [-1.275216413414262, 50.936020664821449],
            [-1.273702742085079, 50.935710914916882],
            [-1.272570982131034, 50.934000696216842],
            [-1.270769870690557, 50.933512864999081],
            [-1.270220914627218, 50.931671371854733],
            [-1.268636576307678, 50.9307972848503],
            [-1.268750786462473, 50.930501247538537],
            [-1.267252484237253, 50.929408263069817],
            [-1.267158329596267, 50.928970637077079],
            [-1.267532232693339, 50.92881021797659],
            [-1.266311925213369, 50.927055317256752],
            [-1.266673548265818, 50.926860652247726],
            [-1.265402942387023, 50.926498364173916],
            [-1.265272697832332, 50.926185505213127],
            [-1.264686365521291, 50.926283431091178],
            [-1.264718463475796, 50.925420352851098],
            [-1.265828098749922, 50.924797857039316],
            [-1.265223626736118, 50.923880417770548],
            [-1.265560434262823, 50.923182019691026],
            [-1.264563691238864, 50.922872700916095],
            [-1.264842288622177, 50.920902397426765],
            [-1.264249522400307, 50.920510188718318],
            [-1.265171643000233, 50.919863137048253],
            [-1.265389582728475, 50.918399627538591],
            [-1.266490449653344, 50.918052240858827],
            [-1.267517078975327, 50.917267342083569],
            [-1.267764826344138, 50.916527912179895],
            [-1.266856923518058, 50.916457470952857],
            [-1.267593643964712, 50.915561042622933],
            [-1.268076720046112, 50.91469089722834],
            [-1.267796668353838, 50.914226027258337],
            [-1.268776463909998, 50.914063105893],
            [-1.268504179300172, 50.913294179846361],
            [-1.268497214513743, 50.913274511849735],
            [-1.268558435987116, 50.913244786494424],
            [-1.269226787235598, 50.912920277171722],
            [-1.268863270567512, 50.912062813425514],
            [-1.269373552760039, 50.9116316677041],
            [-1.268834938577753, 50.911419670689369],
            [-1.268683773528519, 50.910899855676291],
            [-1.268957531140297, 50.910769261532039],
            [-1.269488412420902, 50.911199845779073],
            [-1.270690594972946, 50.91127390320478],
            [-1.270486374818219, 50.911053210290483],
            [-1.269545357339358, 50.91110578042619],
            [-1.268986096708014, 50.910755634097377],
            [-1.268787185401522, 50.910631096088736],
            [-1.268335401970395, 50.910348230629374],
            [-1.2689725276973, 50.909627422505487],
            [-1.267440612588923, 50.908996440719172],
            [-1.267074974241944, 50.908187517048923],
            [-1.26590459139603, 50.908267394299529],
            [-1.265827089144536, 50.907859545558999],
            [-1.266136834112877, 50.907879477243277],
            [-1.264796454503361, 50.906742484900846],
            [-1.265397679003008, 50.906043951168158],
            [-1.264890981070595, 50.905345640241151],
            [-1.262256256258835, 50.903325490167589],
            [-1.261314363058135, 50.903172958298114],
            [-1.261042280643454, 50.902842110172379],
            [-1.260863703007633, 50.902084707513026],
            [-1.262277467724777, 50.901352660908906],
            [-1.262839659222923, 50.899791508354546],
            [-1.264019389618835, 50.899195549064693],
            [-1.264143341684757, 50.898819542521871],
            [-1.268499512344689, 50.899419733432268],
            [-1.272046018480456, 50.898691914945559],
            [-1.273110715115531, 50.898990804007738],
            [-1.272860796821422, 50.898602569001071],
            [-1.275468010708338, 50.898650246569204],
            [-1.279091184971505, 50.897274327760989],
            [-1.280558676454459, 50.897549556243902],
            [-1.280830522937829, 50.899380312836286],
            [-1.280984930403947, 50.897386718549846],
            [-1.280245949432324, 50.897173539689831],
            [-1.28288876311119, 50.896750061998453],
            [-1.283184128434902, 50.896961401428854],
            [-1.283913100094052, 50.896622358385507],
            [-1.284063816279046, 50.89680852872327],
            [-1.284254775045593, 50.896595676744489],
            [-1.284281629410555, 50.896791878683317],
            [-1.285428060472374, 50.896676600257095],
            [-1.285597699187513, 50.896462716387163],
            [-1.286591685794807, 50.896738568627867],
            [-1.28675279473361, 50.896431108582377],
            [-1.287817701619766, 50.896998741702838],
            [-1.287535705668508, 50.896375637341194],
            [-1.288012395987959, 50.896633033250822],
            [-1.287992080403718, 50.896286696667019],
            [-1.288426635863927, 50.896603184751051],
            [-1.288370416075067, 50.896280010072836],
            [-1.289066019701293, 50.896168242811044],
            [-1.289633814049879, 50.894736488541717],
            [-1.28945285012279, 50.893546573697044],
            [-1.290010513426396, 50.892497837781349],
            [-1.290291354376515, 50.892823276367494],
            [-1.290300439422695, 50.892318849888774],
            [-1.290456350218116, 50.892631838514859],
            [-1.291341089008851, 50.892139023618732],
            [-1.290939135326571, 50.892579916233544],
            [-1.29198087854978, 50.892328150556047],
            [-1.293736852323716, 50.892419715991124],
            [-1.294315138112526, 50.893010424741611],
            [-1.294704253701078, 50.892947129800859],
            [-1.295120224604806, 50.893553939931095],
            [-1.295198728513154, 50.893156943443472],
            [-1.294206429042802, 50.892394677954492],
            [-1.292739431415536, 50.89218616844105],
            [-1.295163610922184, 50.890207177427961],
            [-1.295968358446963, 50.8886949891371],
            [-1.300783071477543, 50.886929003505756],
            [-1.30115618033678, 50.886601210357504],
            [-1.300296858326699, 50.886929688345575],
            [-1.300836179455322, 50.886610086012659],
            [-1.300808284741589, 50.885814078411521],
            [-1.301598027474155, 50.885383564731569],
            [-1.301350763055775, 50.884901883504718],
            [-1.302670680541169, 50.884855817350505],
            [-1.304010092636889, 50.884073362574419],
            [-1.304526180120033, 50.883209555297917],
            [-1.305122266766717, 50.883082711779508],
            [-1.304451993734458, 50.883036456449851],
            [-1.305177782223788, 50.882790784154459],
            [-1.304565278902919, 50.882683723817955],
            [-1.304632914452176, 50.882246189236199],
            [-1.303170114095559, 50.881107105426864],
            [-1.30087261167873, 50.880184225507342],
            [-1.300220861723655, 50.879473506545217],
            [-1.301586896309661, 50.879089609028881],
            [-1.301841975754122, 50.879617199061876],
            [-1.302371076608147, 50.879404539778562],
            [-1.303746328809932, 50.879831800850489],
            [-1.302910912888521, 50.879138886086658],
            [-1.302977016508398, 50.878709436338823],
            [-1.302255198224612, 50.878787857127961],
            [-1.302275568938772, 50.878565862612525],
            [-1.304103570340397, 50.87798685869663],
            [-1.305253854702316, 50.87853416082983],
            [-1.305262836443954, 50.878216776922393],
            [-1.304411747936262, 50.877813339908457],
            [-1.305798196034809, 50.877580589776024],
            [-1.305968239659155, 50.877232689191636],
            [-1.30673285486207, 50.87752499305445],
            [-1.306399884506457, 50.87697536883767],
            [-1.30720326955572, 50.876473856785864],
            [-1.308756996806565, 50.876843666207336],
            [-1.308097442623982, 50.877423377228226],
            [-1.309096064395189, 50.877367241311852],
            [-1.308776224280866, 50.877177404001834],
            [-1.309058186898936, 50.87694886317167],
            [-1.310704399466972, 50.877121357476433],
            [-1.309078125682906, 50.876753842591249],
            [-1.307860011986912, 50.876083877120266],
            [-1.30962259295623, 50.875400085351799],
            [-1.310312464233081, 50.875748577083172],
            [-1.310236009399151, 50.875535901041289],
            [-1.310728109636695, 50.875609848046807],
            [-1.310527006630468, 50.876235442323456],
            [-1.311046683480141, 50.876560443207737],
            [-1.311510505075328, 50.876335667046121],
            [-1.312407432121701, 50.876620620469218],
            [-1.312911603559708, 50.876454527974857],
            [-1.313512660620113, 50.876078577027435],
            [-1.312948928406455, 50.875752428663581],
            [-1.313574499491226, 50.875739021787759],
            [-1.314009493294985, 50.875249684961418],
            [-1.313510133927011, 50.874798920793296],
            [-1.314226108559243, 50.874725791727279],
            [-1.314010712323217, 50.874391801765036],
            [-1.314877079248177, 50.874529978063478],
            [-1.31424107253469, 50.874287042591035],
            [-1.314658979057238, 50.873798502170388],
            [-1.314172982419746, 50.873406270798732],
            [-1.314523520393853, 50.87305312282399],
            [-1.315287045323116, 50.873127744453136],
            [-1.315761074158509, 50.873561266853201],
            [-1.315618647491059, 50.875620627948109],
            [-1.315980402341331, 50.874986973581635],
            [-1.316624027329135, 50.875682269834599],
            [-1.316422270275314, 50.874316917552619],
            [-1.317170443814836, 50.874179212490581],
            [-1.316500903729173, 50.874090765298391],
            [-1.316396453637047, 50.873166617721019],
            [-1.314876139278762, 50.872363664080098],
            [-1.314212431996792, 50.872654724135778],
            [-1.314146294439487, 50.872416032723038],
            [-1.313623739468574, 50.873063124718385],
            [-1.313095163581623, 50.873151740696969],
            [-1.312534143082346, 50.871870595561617],
            [-1.313055203821124, 50.871808915619177],
            [-1.311890002259074, 50.870059295273393],
            [-1.309822313632125, 50.869766523851908],
            [-1.309412960639688, 50.868712871925688],
            [-1.309764586696678, 50.868189784765491],
            [-1.310617619156306, 50.868067129643329],
            [-1.309899639676697, 50.867801204597711],
            [-1.310608653617261, 50.866940307202761],
            [-1.312205335760802, 50.86717183986341],
            [-1.312233823712715, 50.866780830694175],
            [-1.311555269171908, 50.866540327179194],
            [-1.311019652108127, 50.866822232563678],
            [-1.31074895806613, 50.866676753791168],
            [-1.311749996827374, 50.864895833696011],
            [-1.312486731516653, 50.864946935403594],
            [-1.312152025851619, 50.865482720230119],
            [-1.313142605651159, 50.865085684074643],
            [-1.312986091078849, 50.864809590421459],
            [-1.311832674148099, 50.864684995429741],
            [-1.31275737435174, 50.862775022410666],
            [-1.313126884788322, 50.862770002659893],
            [-1.312769545981631, 50.862526898829685],
            [-1.313137178498776, 50.860327677936475],
            [-1.313504892497079, 50.860346926433216],
            [-1.313616786113078, 50.859790044053725],
            [-1.313590276925954, 50.85956327507747],
            [-1.31291462288265, 50.859518834390045],
            [-1.312746561363452, 50.858482798623221],
            [-1.313074268859135, 50.858322860541662],
            [-1.312644524770944, 50.858271771436655],
            [-1.312367284059881, 50.857221603200188],
            [-1.312746964560145, 50.856908198826304],
            [-1.312534798348884, 50.855103935332004],
            [-1.312838822138196, 50.854910585818374],
            [-1.312451587436446, 50.854772517945548],
            [-1.313981812576566, 50.855245536922823],
            [-1.314439319553355, 50.853982067238825],
            [-1.315345406560908, 50.853321932235154],
            [-1.315795824432811, 50.853607839363058],
            [-1.315357805944119, 50.853833683508846],
            [-1.316232459854297, 50.853573527707965],
            [-1.316578966307458, 50.853971230587192],
            [-1.317747709909987, 50.854196585981768],
            [-1.316634809333198, 50.853746742640872],
            [-1.316884734433246, 50.852975741449349],
            [-1.316127959083188, 50.853433530456762],
            [-1.315283971372818, 50.853151611930372],
            [-1.314147871972683, 50.853706081436336],
            [-1.31243390996377, 50.852882170564534],
            [-1.312324093033357, 50.852041615303186],
            [-1.311585964526065, 50.851905973421054],
            [-1.310115794368519, 50.850548405604343],
            [-1.310007518020288, 50.849317577929398],
            [-1.3107213733166, 50.848976478745023],
            [-1.313456079298377, 50.849055540436012],
            [-1.31443500589618, 50.850010911454618],
            [-1.321422231261999, 50.851698275905136],
            [-1.324205819578921, 50.852929320425602],
            [-1.329375255032111, 50.856013010172298],
            [-1.331918194210436, 50.857115691055895],
            [-1.335125922263574, 50.859235567123484],
            [-1.339713411128443, 50.86297912941189],
            [-1.35176297106894, 50.87003746273497],
            [-1.358405426674572, 50.87572596487292],
            [-1.365120641396595, 50.880048510211992],
            [-1.362704873212595, 50.881667496809065],
            [-1.360303605768066, 50.884084150184179],
            [-1.358630173531413, 50.885035373959539],
            [-1.354943367203916, 50.885699399609493],
            [-1.351039454087448, 50.886838706767151],
            [-1.349395717273718, 50.888597492252977],
            [-1.349392836545058, 50.888600173962885],
            [-1.346952934035313, 50.889433652053768],
            [-1.343334428635421, 50.89137193436747],
            [-1.3415931826759, 50.894165999746697],
            [-1.336153609468001, 50.895333011406855],
            [-1.335103254638794, 50.896301825725949],
            [-1.335060995295357, 50.897372594247116],
            [-1.321987971900872, 50.900962784857242],
            [-1.324034070352669, 50.904419677347654],
            [-1.327886130258247, 50.904462590311603],
            [-1.327540603874378, 50.904655738957707],
            [-1.328030667056608, 50.905190916034542],
            [-1.32771393622785, 50.905556887022072],
            [-1.328730322053895, 50.906679602320551],
            [-1.324649930416516, 50.908447387797857],
            [-1.329053096325936, 50.913316124835994],
            [-1.328598700218692, 50.913459192298582],
            [-1.329016588254022, 50.91437163416181],
            [-1.328583943750332, 50.914584068721496],
            [-1.329826210898015, 50.914950003286037],
            [-1.329832188027326, 50.915623575346352],
            [-1.332418039501434, 50.918130201644885],
            [-1.333367355437898, 50.919891855829547],
            [-1.335119473913314, 50.920931481204363],
            [-1.335844881705805, 50.92104261839274],
            [-1.336645086771151, 50.920495926122705],
            [-1.338508874615407, 50.921492970524007],
            [-1.337743625971763, 50.922484102158613],
            [-1.339498817359856, 50.923722412150148],
            [-1.344514826140104, 50.922857713096967],
            [-1.350143425358294, 50.925802722071758],
            [-1.351148615137799, 50.926675183573103],
            [-1.353143431690245, 50.926163783969955],
            [-1.354917068792254, 50.928783205099748],
            [-1.357943716912634, 50.930740451960418],
            [-1.357258605658137, 50.932544168767819],
            [-1.355963600068422, 50.933269022803998],
            [-1.352781009732524, 50.935366460099488],
            [-1.354587551954769, 50.93686560445672],
            [-1.356638274678275, 50.937695228488749],
            [-1.359008429839019, 50.937966339766298],
            [-1.356963807580394, 50.939477525383531],
            [-1.354579229814616, 50.940552462411119],
            [-1.35470070860768, 50.941030632289447],
            [-1.3669285483005, 50.94687696871496],
            [-1.368928217270559, 50.947215110969211],
            [-1.369829540994512, 50.947090484733195],
            [-1.370983636354875, 50.946060775622264],
            [-1.376828468516441, 50.947219746444183],
            [-1.379901951384165, 50.947526553968707],
            [-1.378516585274896, 50.950753768259503],
            [-1.377820447858482, 50.950860670244381],
            [-1.375702032082631, 50.952357399696815],
            [-1.374044429182224, 50.95398425247474],
            [-1.375707147686256, 50.954314177335249],
            [-1.376447468605704, 50.955591456530207],
            [-1.380652762155505, 50.956051752829843],
            [-1.380284056373659, 50.95679796664519],
            [-1.38448821529482, 50.956277046494215],
            [-1.385589264402749, 50.958508462435979],
            [-1.385296455917846, 50.959226315003733],
            [-1.383584291963884, 50.960032906739151],
            [-1.382955106174189, 50.960773257882792],
            [-1.383740437110933, 50.961146090802202],
            [-1.384533489674073, 50.960719534377404],
            [-1.385288366195527, 50.961674904178167],
            [-1.389965362075338, 50.963024893835346],
            [-1.390563168938753, 50.963806754800231],
            [-1.392774189521055, 50.964495392442387],
            [-1.393967011843231, 50.964752472487831],
            [-1.392756493759562, 50.967710980302719],
            [-1.396475865051444, 50.968510787662325],
            [-1.395551659444627, 50.970482537628264],
            [-1.392392679545952, 50.976279730712967],
            [-1.396391446108435, 50.977554888820734],
            [-1.395969825410753, 50.978982497827928],
            [-1.394428335257208, 50.979657940887989],
            [-1.39106311170406, 50.982865119288235],
            [-1.39242581962682, 50.985859475191077],
            [-1.391437419101802, 50.986816515621456],
            [-1.391705490315422, 50.987235156712678],
            [-1.393841713397412, 50.988612194771086],
            [-1.395467448649279, 50.988933548994574],
            [-1.397167534259123, 50.990446751121482],
            [-1.396863775197128, 50.991017996975607],
            [-1.397328290520563, 50.991786541556955],
            [-1.396876484314066, 50.992566545648224],
            [-1.397800084094322, 50.992984956023051],
            [-1.398129746523258, 50.994377770456737],
            [-1.399653345124156, 50.994681454266306],
            [-1.398971403414321, 50.99673180139682],
            [-1.396481216649568, 50.998655921017836],
            [-1.395168852726576, 50.999086165374429],
            [-1.391943517481673, 50.999226797365331],
            [-1.392985156969756, 51.004368635149021],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000087",
        LAD13CDO: "24UE",
        LAD13NM: "Fareham",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.269345244769228, 50.898625590569701],
            [-1.269292645675582, 50.898261063497657],
            [-1.268939590733886, 50.898559204948668],
            [-1.268875253635092, 50.898036335433105],
            [-1.268647334712831, 50.898516009449551],
            [-1.26836697905446, 50.897985491904237],
            [-1.268281227048843, 50.898466955008637],
            [-1.267788802226663, 50.898311894771361],
            [-1.268585396287437, 50.897386158039751],
            [-1.267193386755745, 50.898195753452548],
            [-1.265523755440952, 50.89775721996439],
            [-1.265809915239396, 50.897464063776404],
            [-1.265258002502125, 50.896572129218953],
            [-1.264116706947818, 50.895546087604671],
            [-1.261102389755785, 50.895088218507126],
            [-1.260915734694008, 50.89304572785322],
            [-1.260331585993408, 50.893211089520527],
            [-1.258731826720404, 50.892864624598744],
            [-1.259090572471571, 50.891760816711233],
            [-1.25775179670485, 50.891278407496728],
            [-1.259412804057444, 50.889730542361143],
            [-1.257233151890687, 50.889381276709742],
            [-1.255536971169199, 50.888760780206496],
            [-1.254675172823065, 50.886904613179219],
            [-1.255383710668245, 50.884193377608675],
            [-1.25391855423535, 50.883095025387348],
            [-1.254397607127137, 50.882115196875503],
            [-1.256598876845608, 50.880725490523751],
            [-1.254837468813591, 50.878956214805179],
            [-1.25611718066134, 50.876427567373028],
            [-1.254977808804313, 50.875948192750343],
            [-1.255298069667476, 50.874937670764346],
            [-1.255254658347192, 50.873112798208453],
            [-1.253509470486098, 50.8723957359162],
            [-1.240956885275904, 50.865685466292007],
            [-1.23662147664372, 50.863766055849652],
            [-1.234550291954518, 50.862516910798085],
            [-1.230841695420017, 50.861309110059736],
            [-1.229662450337601, 50.860614299467898],
            [-1.229021596638988, 50.861220668031642],
            [-1.227874638246268, 50.862616835816056],
            [-1.227876755109787, 50.863177089691952],
            [-1.226078112192903, 50.86419213756605],
            [-1.225302770871344, 50.86416181627586],
            [-1.22478579868527, 50.865346309076891],
            [-1.225210438384417, 50.865875195499221],
            [-1.224496462722068, 50.867026905136257],
            [-1.223570687358069, 50.867580992061626],
            [-1.220483169502421, 50.868662014274541],
            [-1.220303255125983, 50.869224649525748],
            [-1.219525552888334, 50.86958635200638],
            [-1.217674768505046, 50.872757348460468],
            [-1.218572355663885, 50.873134753480322],
            [-1.215969107017579, 50.875057006728795],
            [-1.20969410705757, 50.87396436741097],
            [-1.208291285522966, 50.87497372620664],
            [-1.207349949551875, 50.87659230322744],
            [-1.197973821111046, 50.87590419055357],
            [-1.198111986297119, 50.880774634497044],
            [-1.198513686741626, 50.881719816831293],
            [-1.19725685706712, 50.884132889796042],
            [-1.195825931872876, 50.885007016234795],
            [-1.188183188648867, 50.883957752497395],
            [-1.188323143246348, 50.888012587978594],
            [-1.186142595302661, 50.887831047924095],
            [-1.185752013937747, 50.888298637793362],
            [-1.180742181144771, 50.888853511650865],
            [-1.180606605745857, 50.887991069668594],
            [-1.177125738037977, 50.887620377547023],
            [-1.174954898024501, 50.888019618708178],
            [-1.173655991491764, 50.888874623015091],
            [-1.169478394609958, 50.88842862814694],
            [-1.167231131993672, 50.889440477710998],
            [-1.165287467405152, 50.887937425227634],
            [-1.162647549245241, 50.886580424800343],
            [-1.159777098262375, 50.884138095681969],
            [-1.155594006724358, 50.883082762561813],
            [-1.153817203177615, 50.882291139342236],
            [-1.153791855140835, 50.881335939522749],
            [-1.152451189101851, 50.879152699212675],
            [-1.151703224076248, 50.878855906561533],
            [-1.150016255282615, 50.879389490699893],
            [-1.149451850538099, 50.87759854596014],
            [-1.150643338397726, 50.875074894986234],
            [-1.151713832377276, 50.870283308648382],
            [-1.150729499102767, 50.869393975022469],
            [-1.148011184170859, 50.868598105560345],
            [-1.147585751330716, 50.867535666976167],
            [-1.144544927490743, 50.866667218398661],
            [-1.143290930165638, 50.85910149756846],
            [-1.143200888685265, 50.858510019105779],
            [-1.140119762005005, 50.858015251188057],
            [-1.139927924871785, 50.858480557173493],
            [-1.138339695220943, 50.858160397423333],
            [-1.137989149239129, 50.858833158509256],
            [-1.136904066885214, 50.85904186469476],
            [-1.135443085530028, 50.858986091233412],
            [-1.132810456710532, 50.858212980349073],
            [-1.13234825929901, 50.858543174039724],
            [-1.126773063104639, 50.857782192313742],
            [-1.125963542405315, 50.858221271477071],
            [-1.122478475948308, 50.857836319976023],
            [-1.122111470686064, 50.858541283858358],
            [-1.115862051623278, 50.858280995947908],
            [-1.115938484026198, 50.854763640169011],
            [-1.116838019518897, 50.854524948676961],
            [-1.116739675326811, 50.851532339918741],
            [-1.117645305105285, 50.851494225167642],
            [-1.117718509181906, 50.844449007838421],
            [-1.118205601314174, 50.844227871044573],
            [-1.116034101714267, 50.842035214904598],
            [-1.116245741049165, 50.841282330069909],
            [-1.115580010820809, 50.841337539007327],
            [-1.114667055568916, 50.840196638792996],
            [-1.114197226369487, 50.84033246124762],
            [-1.112292877268832, 50.837851295756209],
            [-1.112717409434149, 50.836582954927735],
            [-1.113032490571443, 50.836068268918432],
            [-1.121099009751672, 50.835599615887354],
            [-1.123104206745934, 50.835826025751366],
            [-1.130048603478204, 50.838113573730411],
            [-1.131701428049014, 50.838280554675357],
            [-1.131359541299179, 50.838872428128418],
            [-1.131917927903199, 50.838629282978623],
            [-1.131966662157065, 50.838222276311477],
            [-1.13489858206136, 50.839030903143097],
            [-1.134555067061795, 50.839328712695284],
            [-1.135446869635002, 50.839641072792816],
            [-1.135551602528023, 50.839045632977388],
            [-1.138243288478316, 50.839177944264037],
            [-1.138945369640649, 50.838687628050536],
            [-1.141571324562763, 50.838608948762747],
            [-1.144037493616286, 50.839035327180952],
            [-1.147178301522139, 50.841566376047325],
            [-1.1484958458455, 50.842060699978376],
            [-1.152340748429385, 50.841110279818402],
            [-1.154392799941626, 50.842773521127619],
            [-1.154393970061349, 50.843101762335905],
            [-1.15293476526461, 50.843601966900756],
            [-1.153815885241684, 50.844830462011707],
            [-1.154662355579782, 50.845070404377111],
            [-1.15637470196961, 50.844790529945769],
            [-1.156288414248968, 50.844534514378715],
            [-1.160023008860383, 50.845221509016369],
            [-1.164060639937624, 50.843183976461134],
            [-1.163236331180026, 50.842021612721091],
            [-1.163626716877275, 50.841396721711597],
            [-1.168051069032663, 50.840266484354565],
            [-1.170758265122596, 50.840013269227725],
            [-1.172684039420902, 50.840659118677678],
            [-1.173287897010105, 50.841368422314353],
            [-1.174035710704522, 50.844705499646828],
            [-1.176619159187836, 50.847300148155497],
            [-1.176733792490479, 50.847970010552601],
            [-1.175849198748868, 50.848514119610172],
            [-1.173764763662365, 50.847823137243275],
            [-1.171349629691545, 50.847601885253781],
            [-1.169272377944513, 50.848272362542666],
            [-1.166359050101123, 50.850181419836467],
            [-1.166423105148297, 50.852326626501267],
            [-1.167070388021249, 50.852594731400714],
            [-1.167000048122604, 50.852954835056117],
            [-1.169144522774638, 50.855151734841677],
            [-1.170506759568806, 50.856124534694487],
            [-1.171833529393669, 50.856535925498449],
            [-1.169310052969769, 50.855030612715311],
            [-1.16740054034167, 50.852802119080025],
            [-1.168550914074582, 50.852340901171175],
            [-1.168548074447295, 50.851621468362623],
            [-1.169389674203538, 50.850946715155096],
            [-1.16977730793532, 50.849996250674174],
            [-1.169372845071948, 50.849574315568717],
            [-1.170260603051055, 50.849417861644191],
            [-1.170498794797, 50.849763973259819],
            [-1.173909349738791, 50.849610102726018],
            [-1.17660743172934, 50.849984374057875],
            [-1.176913028087153, 50.849247332681671],
            [-1.177239973318131, 50.849234349875488],
            [-1.177476614548257, 50.849833129863114],
            [-1.179070874558261, 50.848837174230084],
            [-1.179377658289495, 50.848353727673107],
            [-1.178450804731786, 50.84758463115201],
            [-1.17846757268475, 50.846467860746259],
            [-1.17618792401669, 50.84492753912118],
            [-1.175761540269004, 50.842120615797675],
            [-1.175855918433297, 50.840793964066407],
            [-1.176695710804492, 50.839558901415835],
            [-1.179514197490355, 50.837326980855067],
            [-1.18038904287908, 50.836919463794857],
            [-1.18167653777751, 50.836708176281377],
            [-1.181383596145642, 50.835512794212029],
            [-1.181984713985151, 50.833782320822223],
            [-1.1817796585449, 50.832190078504283],
            [-1.184274446620919, 50.830078972004053],
            [-1.183672358996569, 50.827719578412569],
            [-1.184650190303028, 50.826522292184144],
            [-1.185906280253103, 50.825241508204257],
            [-1.185691784158466, 50.824349735550292],
            [-1.188040811577924, 50.823978515312689],
            [-1.18912522599293, 50.82268211378593],
            [-1.189245529757982, 50.821883499098981],
            [-1.186329952336117, 50.821531379643332],
            [-1.18631382704798, 50.819599635913725],
            [-1.183966096031498, 50.818360237637954],
            [-1.183625400972303, 50.817708581993045],
            [-1.182323987149969, 50.81728400881066],
            [-1.180938379638819, 50.815911898285023],
            [-1.179404903698551, 50.815449692593312],
            [-1.178855417031651, 50.81432443704221],
            [-1.179607698294723, 50.813191250372611],
            [-1.184764911176923, 50.812347901440873],
            [-1.186822602979246, 50.812372148710381],
            [-1.18701110263662, 50.811406745634066],
            [-1.193977802020096, 50.811048597350783],
            [-1.203854345779023, 50.811256675919971],
            [-1.209905941621597, 50.811708767153931],
            [-1.209894437276869, 50.811296823593899],
            [-1.214157957812571, 50.810802234017807],
            [-1.213743745861488, 50.809857908510338],
            [-1.214112490318336, 50.809366692824597],
            [-1.220724571973078, 50.81173833985396],
            [-1.223946290270242, 50.813252593783801],
            [-1.228231892574044, 50.814004023570511],
            [-1.230648111160451, 50.815366181070473],
            [-1.238332047721769, 50.818053232524107],
            [-1.239972361383503, 50.818183527902889],
            [-1.240761141532752, 50.817688668667259],
            [-1.242905801579352, 50.818320396753499],
            [-1.243881804329341, 50.817657668457635],
            [-1.242663544456265, 50.817851205914764],
            [-1.242328362878079, 50.817422777656319],
            [-1.244701008503591, 50.817121613424909],
            [-1.246190115418214, 50.817296705496219],
            [-1.250247159611661, 50.819335397339565],
            [-1.258033773242961, 50.823986701829639],
            [-1.264389265386058, 50.826830838354262],
            [-1.267101873128901, 50.827418035865755],
            [-1.267141218309381, 50.827353535800803],
            [-1.274987263168232, 50.82982784949121],
            [-1.297043835267831, 50.835807969523387],
            [-1.30227962074939, 50.837815060006356],
            [-1.306417957489444, 50.84068948134361],
            [-1.308303610653367, 50.843216802448694],
            [-1.308014900979269, 50.845260011128936],
            [-1.307257141675094, 50.845115232504057],
            [-1.307847843795388, 50.844742845036734],
            [-1.307876699219658, 50.843368045922169],
            [-1.306898262902014, 50.843644609120801],
            [-1.306895657301669, 50.845067224662699],
            [-1.306317339307988, 50.845465759464517],
            [-1.304737650083764, 50.845480642574337],
            [-1.304043394996263, 50.847751635873571],
            [-1.303344912498545, 50.848584681617446],
            [-1.304192586975646, 50.850041143061681],
            [-1.307702288848971, 50.853525057414252],
            [-1.307042942219387, 50.854208180225079],
            [-1.307715380264418, 50.85542437108505],
            [-1.307406993406296, 50.856198602898395],
            [-1.307765453728744, 50.856360796882917],
            [-1.306680046463902, 50.857222141620589],
            [-1.306383496360743, 50.857004558074188],
            [-1.306956533086328, 50.856492685434837],
            [-1.30608800943392, 50.857002802283453],
            [-1.307367797652355, 50.85942131461713],
            [-1.307798597628303, 50.85940138795354],
            [-1.308576823648284, 50.860387989516695],
            [-1.307829838415974, 50.86343474719736],
            [-1.307144720895495, 50.863642009411294],
            [-1.306346864955426, 50.865414206071002],
            [-1.306296225114205, 50.867295152393282],
            [-1.306114489409283, 50.865848066162862],
            [-1.305571028514723, 50.867708996481291],
            [-1.305416251040689, 50.870953495503215],
            [-1.305035498389469, 50.871041155705825],
            [-1.304810085879656, 50.87195975381259],
            [-1.305354421592027, 50.872245359955372],
            [-1.305061696901777, 50.87291176601687],
            [-1.305480057504395, 50.872589623395037],
            [-1.305815485798191, 50.869716693305328],
            [-1.306125867085328, 50.871114185173681],
            [-1.30654805511091, 50.871011480130022],
            [-1.30666942590777, 50.871260395783729],
            [-1.305806551688465, 50.872041219682352],
            [-1.305313817964277, 50.874212692096435],
            [-1.305762146161253, 50.874457258423291],
            [-1.305081165921039, 50.87447029366556],
            [-1.304531674564822, 50.875102796616133],
            [-1.303171812545134, 50.875082099990266],
            [-1.302952028262525, 50.875714764039323],
            [-1.302124343328217, 50.876223295787078],
            [-1.301715834874314, 50.87607247635043],
            [-1.301974833944654, 50.876336607722024],
            [-1.301365382566934, 50.876316776406512],
            [-1.301594858145783, 50.876559149819421],
            [-1.301082087741933, 50.876822261727973],
            [-1.300639655748298, 50.876943710169826],
            [-1.300102642999983, 50.876641939595778],
            [-1.300134028163622, 50.87710884175582],
            [-1.297084977553064, 50.87835488210176],
            [-1.296356305227816, 50.879265936412764],
            [-1.298367558004834, 50.882204216774348],
            [-1.298976784173437, 50.881675516284737],
            [-1.30205179740525, 50.883651614506626],
            [-1.300699526651057, 50.884531988723253],
            [-1.299608422710352, 50.884487681100261],
            [-1.299381672479293, 50.885199430273602],
            [-1.298733844067331, 50.885076840141586],
            [-1.299359324049784, 50.885457382404944],
            [-1.299063813440645, 50.885824303633704],
            [-1.297973637141866, 50.885621717468339],
            [-1.297823646989464, 50.885953540080273],
            [-1.298801307010973, 50.885980996606207],
            [-1.298812698431427, 50.886642916662105],
            [-1.295032178914278, 50.887422291447592],
            [-1.293709640790509, 50.888764981076321],
            [-1.292419891305739, 50.888500885978274],
            [-1.292859908160055, 50.888735558965514],
            [-1.292443066460295, 50.888848138769433],
            [-1.292323382215475, 50.889616276400432],
            [-1.293070742419423, 50.888989526024432],
            [-1.293048520390792, 50.889236686564139],
            [-1.293296392650393, 50.889016070893319],
            [-1.29347590736039, 50.889179022939551],
            [-1.292948664266352, 50.890386228748881],
            [-1.291943977193144, 50.890729052435432],
            [-1.291536905679808, 50.890196023431137],
            [-1.291379006332728, 50.890857816081002],
            [-1.287751325239455, 50.891746692548864],
            [-1.287487801182377, 50.892804406860812],
            [-1.288008939376668, 50.894807526645806],
            [-1.287436008273309, 50.895174525043707],
            [-1.287036031432744, 50.894643322515989],
            [-1.287468728215319, 50.894241298887003],
            [-1.286604056785393, 50.893973437937042],
            [-1.2872583421215, 50.89442436220763],
            [-1.286157298223684, 50.895295309450248],
            [-1.286827938007765, 50.895048514820424],
            [-1.287130125876771, 50.895372292732162],
            [-1.284553866817834, 50.895450061208081],
            [-1.281787192891252, 50.896074247793301],
            [-1.279533696958284, 50.896242016947838],
            [-1.27814601470163, 50.894577920668198],
            [-1.279157982191111, 50.896263079149215],
            [-1.276575843958681, 50.8968999715457],
            [-1.274985262314494, 50.897778571252786],
            [-1.273625562803062, 50.897522827476593],
            [-1.273719534949991, 50.897697867231081],
            [-1.272829674500746, 50.897678841167412],
            [-1.272730864708342, 50.897358091365852],
            [-1.273444952954507, 50.89743267784187],
            [-1.272301178876576, 50.897191750510316],
            [-1.272393044472027, 50.897684214998783],
            [-1.271877682502917, 50.897540719402009],
            [-1.271223860051444, 50.897978175134057],
            [-1.271078617219884, 50.897626559521264],
            [-1.271846714489522, 50.897155645461012],
            [-1.270642728039715, 50.897493446255986],
            [-1.27045414591939, 50.898278216663087],
            [-1.270160575296105, 50.89804617333386],
            [-1.270446518701833, 50.897583045425151],
            [-1.269944060956534, 50.898069999198704],
            [-1.269431956581236, 50.898082084174014],
            [-1.270021356525061, 50.898401407937328],
            [-1.269345244769228, 50.898625590569701],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000088",
        LAD13CDO: "24UF",
        LAD13NM: "Gosport",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.171507000783521, 50.837831566986473],
            [-1.171094204580792, 50.837484216849795],
            [-1.169208416833608, 50.837554442339027],
            [-1.167935994813189, 50.837951848331429],
            [-1.166464969320236, 50.837697651740889],
            [-1.165493427216796, 50.836830110554814],
            [-1.166000279369736, 50.836279783809125],
            [-1.165605113491546, 50.835424453383247],
            [-1.166517872017485, 50.834726847300807],
            [-1.165540651385291, 50.83513712595213],
            [-1.165107810987078, 50.835758123116008],
            [-1.165559498697881, 50.835832395139306],
            [-1.165419846828407, 50.836257649907466],
            [-1.165155160574564, 50.836128960323414],
            [-1.164394071583166, 50.836919366887948],
            [-1.163887675212718, 50.837045235342018],
            [-1.163430849786227, 50.836781174459851],
            [-1.163417568336861, 50.837206433152005],
            [-1.161260377465743, 50.837364511578834],
            [-1.158532257227677, 50.838708167809422],
            [-1.157715276434182, 50.838255339234962],
            [-1.156892966509778, 50.838335732526318],
            [-1.156878075465867, 50.83805865031492],
            [-1.157334295502182, 50.83803946295594],
            [-1.156502232697395, 50.837400368325142],
            [-1.152408047816485, 50.835747530955729],
            [-1.150725113655203, 50.834266791933608],
            [-1.150107020521208, 50.83311392616006],
            [-1.150476173698074, 50.831864830279386],
            [-1.150175636917911, 50.831055098359911],
            [-1.150746794510181, 50.831271483065606],
            [-1.151083669264661, 50.831017641758812],
            [-1.15025928983485, 50.830592583443092],
            [-1.150777727787759, 50.830427293820037],
            [-1.151441704547191, 50.830692911004135],
            [-1.151207352355844, 50.830148048085867],
            [-1.151908157089728, 50.83034019031431],
            [-1.152364768114986, 50.829746391970282],
            [-1.150964914307955, 50.828640007629829],
            [-1.150085965203088, 50.827156109741175],
            [-1.150270291631914, 50.826778858980667],
            [-1.156748243930724, 50.82476920857701],
            [-1.157140564925086, 50.823402453373554],
            [-1.154026875718864, 50.825379904275458],
            [-1.150982925613337, 50.826159051952082],
            [-1.150323714877444, 50.825869186182082],
            [-1.150154371741787, 50.826359854252821],
            [-1.149291934002797, 50.826627849006208],
            [-1.148104631681302, 50.826567930829057],
            [-1.148964789213934, 50.826347589548277],
            [-1.149065167980962, 50.82598051974756],
            [-1.147540489823313, 50.826437914064243],
            [-1.147747191877249, 50.826002378030196],
            [-1.14579247960377, 50.825982693059736],
            [-1.143007117313391, 50.821622392102483],
            [-1.142024504827533, 50.817061259940495],
            [-1.140956471863388, 50.816316899483731],
            [-1.140361297277705, 50.81579723400975],
            [-1.139254045447083, 50.815645189470956],
            [-1.134985796586335, 50.813484153844151],
            [-1.134140632527011, 50.813440117045516],
            [-1.13316253880596, 50.812755703547914],
            [-1.133282502156028, 50.812485913241886],
            [-1.128300028239146, 50.808921852086954],
            [-1.12684806612209, 50.808161902213762],
            [-1.125629816125514, 50.808037673853626],
            [-1.126090706096716, 50.80790533567658],
            [-1.125698856046089, 50.807608337923739],
            [-1.125385944765664, 50.807796639416985],
            [-1.125717324955664, 50.807379161292694],
            [-1.123827984647632, 50.806182445443881],
            [-1.125658230045341, 50.805374235331932],
            [-1.129207930019003, 50.805203835561294],
            [-1.131518954371039, 50.804405415151145],
            [-1.133505304898114, 50.804583848832827],
            [-1.133724018323695, 50.80534266171199],
            [-1.134547889491474, 50.805609564088201],
            [-1.135822013268139, 50.805087534988161],
            [-1.136077675141739, 50.804537273444538],
            [-1.135072262574676, 50.804341877656903],
            [-1.134704301221556, 50.803825664093083],
            [-1.137117934279643, 50.802301279815147],
            [-1.136705886806553, 50.801943918055279],
            [-1.135402177950824, 50.802459442737863],
            [-1.134468497362487, 50.802147667261799],
            [-1.133372854974507, 50.802921005674747],
            [-1.130755916912879, 50.802926721878691],
            [-1.127201679587833, 50.803502707491887],
            [-1.125912492987487, 50.804068594359165],
            [-1.124977677482071, 50.803743245296928],
            [-1.124781687682216, 50.804131161666078],
            [-1.123751281811153, 50.804136918325057],
            [-1.124052700002589, 50.800842437837701],
            [-1.122476519999081, 50.80089804157344],
            [-1.122932788810248, 50.800781868078872],
            [-1.122328436102363, 50.800015637709194],
            [-1.1241396340197, 50.799227991205122],
            [-1.124144398811854, 50.798897093456866],
            [-1.123204287344997, 50.798709279081692],
            [-1.12089915674244, 50.797385287997962],
            [-1.120923875900751, 50.797126482651045],
            [-1.120077274803719, 50.796933358123567],
            [-1.11879756689855, 50.797258489349019],
            [-1.117250994273579, 50.796258831491329],
            [-1.117100887700821, 50.796469025857398],
            [-1.116786270473592, 50.795921683352816],
            [-1.116911060898484, 50.794193318521863],
            [-1.118212394738199, 50.791766118814365],
            [-1.121107217765459, 50.790224115631077],
            [-1.12186994429295, 50.790556632077525],
            [-1.122808415074714, 50.791633462804256],
            [-1.122831991702044, 50.792177076722602],
            [-1.122173010844886, 50.792479431955869],
            [-1.124005041170662, 50.79200399308197],
            [-1.125341846642832, 50.792139020563489],
            [-1.128293896763881, 50.791780716912598],
            [-1.128525237396222, 50.791318418082966],
            [-1.127443959545257, 50.790718613365627],
            [-1.126959707353236, 50.78929593199053],
            [-1.129663896694429, 50.789440233646097],
            [-1.130697088444457, 50.788807650206472],
            [-1.132785180464436, 50.789892428023656],
            [-1.13480027829913, 50.790401989062985],
            [-1.136235541945424, 50.790351471510576],
            [-1.13621142663601, 50.78973528849906],
            [-1.133380589737018, 50.789225991077252],
            [-1.132314834220215, 50.788323290251356],
            [-1.131699977264356, 50.788507564653862],
            [-1.131856149368176, 50.787888225474155],
            [-1.132788706183975, 50.787324119063392],
            [-1.131816539650106, 50.787269228039406],
            [-1.132584503931711, 50.78708968841979],
            [-1.133784748862457, 50.785933141382273],
            [-1.135884667035625, 50.785988279784924],
            [-1.138337454182779, 50.785214155291889],
            [-1.140026609672292, 50.785079135465871],
            [-1.14050289707721, 50.784633905492981],
            [-1.140821987975414, 50.78510298039501],
            [-1.14515953042387, 50.785282320126036],
            [-1.145501417529506, 50.784658927578967],
            [-1.143390325111192, 50.784752260878712],
            [-1.143370789142582, 50.784190968516342],
            [-1.142487340023164, 50.784484837850385],
            [-1.140563076851158, 50.784523737666866],
            [-1.140568358821017, 50.783693743592941],
            [-1.1400047383277, 50.784260631947461],
            [-1.1382200731826, 50.784345485776818],
            [-1.135652763422645, 50.785024335385785],
            [-1.132985419060792, 50.785125049170333],
            [-1.131935208654448, 50.785533606286975],
            [-1.128985878949151, 50.787982841813623],
            [-1.12814715001619, 50.788075498435845],
            [-1.126977730864947, 50.787642295857935],
            [-1.127100013175417, 50.788008317330828],
            [-1.126371981701209, 50.787717797796624],
            [-1.12665746606295, 50.788080545151601],
            [-1.125849293643437, 50.788284025086362],
            [-1.124371882223348, 50.789606575910973],
            [-1.120758441640259, 50.788169336816523],
            [-1.119449220173584, 50.788536261521337],
            [-1.118344749922502, 50.788047707142553],
            [-1.11642868858268, 50.788018825304277],
            [-1.11478146624222, 50.788778820015075],
            [-1.115291884137609, 50.789132789002416],
            [-1.115601865445368, 50.788729426114401],
            [-1.116082677755658, 50.788886411554259],
            [-1.11508134834571, 50.790139718980562],
            [-1.113876322881459, 50.790356477120213],
            [-1.113824432463946, 50.7913003245163],
            [-1.113111179734561, 50.791355157117316],
            [-1.111914917230793, 50.789570884370363],
            [-1.112259471794373, 50.789281240952171],
            [-1.126315008347863, 50.781410735027457],
            [-1.134101234245024, 50.775554223490118],
            [-1.137989028751688, 50.773840209553896],
            [-1.139721717651371, 50.773474401819911],
            [-1.142579722778559, 50.773690583621438],
            [-1.149802932089732, 50.778295588575759],
            [-1.154872243561185, 50.781675030818832],
            [-1.16138555642415, 50.784386581804824],
            [-1.166285136131752, 50.785587154371392],
            [-1.171080937640086, 50.785610530604508],
            [-1.175988368363138, 50.786466327748329],
            [-1.184785100420448, 50.787855446331513],
            [-1.187810533696811, 50.788869342741926],
            [-1.190831455690974, 50.790316579049616],
            [-1.194776182154143, 50.793333052136553],
            [-1.196478206731988, 50.794110065903887],
            [-1.199668129709905, 50.797578000851622],
            [-1.202351254238428, 50.799501030229386],
            [-1.209382512875185, 50.806138738389699],
            [-1.214112490318336, 50.809366692824597],
            [-1.213743745861488, 50.809857908510338],
            [-1.214157957812571, 50.810802234017807],
            [-1.209894437276869, 50.811296823593899],
            [-1.209905941621597, 50.811708767153931],
            [-1.203854345779023, 50.811256675919971],
            [-1.193977802020096, 50.811048597350783],
            [-1.18701110263662, 50.811406745634066],
            [-1.186822602979246, 50.812372148710381],
            [-1.184764911176923, 50.812347901440873],
            [-1.179607698294723, 50.813191250372611],
            [-1.178855417031651, 50.81432443704221],
            [-1.179404903698551, 50.815449692593312],
            [-1.180938379638819, 50.815911898285023],
            [-1.182323987149969, 50.81728400881066],
            [-1.183625400972303, 50.817708581993045],
            [-1.183966096031498, 50.818360237637954],
            [-1.18631382704798, 50.819599635913725],
            [-1.186329952336117, 50.821531379643332],
            [-1.189245529757982, 50.821883499098981],
            [-1.18912522599293, 50.82268211378593],
            [-1.188040811577924, 50.823978515312689],
            [-1.185691784158466, 50.824349735550292],
            [-1.185906280253103, 50.825241508204257],
            [-1.184650190303028, 50.826522292184144],
            [-1.183672358996569, 50.827719578412569],
            [-1.184274446620919, 50.830078972004053],
            [-1.1817796585449, 50.832190078504283],
            [-1.181984713985151, 50.833782320822223],
            [-1.181383596145642, 50.835512794212029],
            [-1.18167653777751, 50.836708176281377],
            [-1.18038904287908, 50.836919463794857],
            [-1.179514197490355, 50.837326980855067],
            [-1.176223072842641, 50.839300175036954],
            [-1.174793322996101, 50.838552677158866],
            [-1.171507000783521, 50.837831566986473],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000089",
        LAD13CDO: "24UG",
        LAD13NM: "Hart",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.931924898967922, 51.364542619074179],
            [-0.930714851455759, 51.364444361597322],
            [-0.928531277367071, 51.365254380221046],
            [-0.926986201801653, 51.365170105411778],
            [-0.92507710199956, 51.36590973738506],
            [-0.92378624101096, 51.366010289942714],
            [-0.918322087324441, 51.364906116695202],
            [-0.917767265196769, 51.364555700446971],
            [-0.917642335798193, 51.36357891189666],
            [-0.918347828789021, 51.36289843509045],
            [-0.917829292627006, 51.3622282376786],
            [-0.915141500944835, 51.360785342483396],
            [-0.910080855583646, 51.360929912999261],
            [-0.908952982787983, 51.360159590153941],
            [-0.907274925856802, 51.359817543273074],
            [-0.906898175600019, 51.359413883719689],
            [-0.903631217673577, 51.358128073895983],
            [-0.901511017144939, 51.358983143401552],
            [-0.899785956977482, 51.358988540337521],
            [-0.89747356662245, 51.357912938464253],
            [-0.897719160721127, 51.357553768648152],
            [-0.897192860733354, 51.357281752821073],
            [-0.895392943954931, 51.357177576516875],
            [-0.894958777848548, 51.356777832727879],
            [-0.893750614061828, 51.356732263195511],
            [-0.892771959427652, 51.356340857626776],
            [-0.889670658277414, 51.356901390750572],
            [-0.888165996896989, 51.356289151843647],
            [-0.88587097050636, 51.35469194433395],
            [-0.885148030621564, 51.3547156412654],
            [-0.884544823960906, 51.355545263007926],
            [-0.883019166026542, 51.355564899492848],
            [-0.882004422353509, 51.355067852333988],
            [-0.882409180323734, 51.354605025463378],
            [-0.881800542714291, 51.353826800582638],
            [-0.879349844648238, 51.353915788745958],
            [-0.879188842954153, 51.353157116835398],
            [-0.877874318898524, 51.352590624163788],
            [-0.873251651901431, 51.352298981415764],
            [-0.871736771918474, 51.352702535753274],
            [-0.868120780794116, 51.354743032080052],
            [-0.86582627386135, 51.353854009303859],
            [-0.864783531889525, 51.354266539092222],
            [-0.859132910328739, 51.354190976247146],
            [-0.857456956164966, 51.353845536144888],
            [-0.854225936519756, 51.353895814309112],
            [-0.853613862081817, 51.354253107728113],
            [-0.851423565801714, 51.354032933104385],
            [-0.850375707898963, 51.353554169271376],
            [-0.8501067711684, 51.353053368735075],
            [-0.848598226900419, 51.352965718732143],
            [-0.847718797707058, 51.352529949427982],
            [-0.845866135471144, 51.352707743056818],
            [-0.844595518716628, 51.352239321647012],
            [-0.841862391944079, 51.352081953559868],
            [-0.841146617710891, 51.352733097838012],
            [-0.83736629879719, 51.352870994527798],
            [-0.836545064238375, 51.352296340847303],
            [-0.836042899025753, 51.352618665852638],
            [-0.83207191013238, 51.352969408634351],
            [-0.830843482852151, 51.353331228985539],
            [-0.830442464957773, 51.352893807638644],
            [-0.828141226209662, 51.352863621293487],
            [-0.827848428292593, 51.352350840334459],
            [-0.826405398289503, 51.352286939089851],
            [-0.82555087561769, 51.350997901050413],
            [-0.824619879606609, 51.350005728029885],
            [-0.821353876236497, 51.349469361130147],
            [-0.820464144615663, 51.349000011307268],
            [-0.81972960569387, 51.34914188340619],
            [-0.818520280062654, 51.348202608054059],
            [-0.818784935771033, 51.347850087892368],
            [-0.816135063473233, 51.347149833376854],
            [-0.815793563646519, 51.346360470971085],
            [-0.814255356670345, 51.345708271609418],
            [-0.81398743659906, 51.345240667060651],
            [-0.81539915929828, 51.344170498931092],
            [-0.815114630720178, 51.342342220218029],
            [-0.814251480842746, 51.341629404054274],
            [-0.813131477773157, 51.341301540433506],
            [-0.809484419973981, 51.34103883510133],
            [-0.807052610498493, 51.341084237615242],
            [-0.804547232020627, 51.341639592337138],
            [-0.804168495696662, 51.341352474941907],
            [-0.801392902216968, 51.341476978211531],
            [-0.798918427471511, 51.341505589578176],
            [-0.798331175346104, 51.34178192097027],
            [-0.796540000248438, 51.341484779319316],
            [-0.795770844061137, 51.341031764932936],
            [-0.794242213431593, 51.341071794013629],
            [-0.793202905288804, 51.339973043540724],
            [-0.789994384261821, 51.339802369845607],
            [-0.789222200289731, 51.339576782107784],
            [-0.789080175945346, 51.339191349425889],
            [-0.787096284454024, 51.339185214712217],
            [-0.786300918746982, 51.33858799166795],
            [-0.784667980028989, 51.338232059269693],
            [-0.783336947215487, 51.337426934191676],
            [-0.783350768063686, 51.336362406192876],
            [-0.782682485420063, 51.335957111435427],
            [-0.782798650019292, 51.33569035205106],
            [-0.780156888346087, 51.334785239614583],
            [-0.778019624356017, 51.333373676174752],
            [-0.776605349844433, 51.333170979207239],
            [-0.775483533362038, 51.331958804010299],
            [-0.774672286840089, 51.330621278391241],
            [-0.773617828052995, 51.33017052698824],
            [-0.773559356363823, 51.3294568353096],
            [-0.772731072118328, 51.329352843761434],
            [-0.773037147180556, 51.329017044839034],
            [-0.772238261490121, 51.328457454227895],
            [-0.772373818471876, 51.327895964464673],
            [-0.770668407053473, 51.327530981931716],
            [-0.769224172923149, 51.327865612920256],
            [-0.768616976145312, 51.327600257012442],
            [-0.768219758758709, 51.326676180540105],
            [-0.767110930461444, 51.326224797956137],
            [-0.766360478209957, 51.325950728597405],
            [-0.765008506263363, 51.324667685262675],
            [-0.764995117058079, 51.324097439251219],
            [-0.765920161651392, 51.323479533346287],
            [-0.766079193874521, 51.322901212324233],
            [-0.765081040516336, 51.322661392896656],
            [-0.764416246959812, 51.321551939609904],
            [-0.764091852546416, 51.321767926989509],
            [-0.763488834921463, 51.321404572970806],
            [-0.763683780742037, 51.320825734796621],
            [-0.763069269009241, 51.320410102443844],
            [-0.762920710151627, 51.318730589340525],
            [-0.76388717348505, 51.318223740736329],
            [-0.764226981325697, 51.317593376063968],
            [-0.764917399324122, 51.318494482555714],
            [-0.766504492148428, 51.319244070183707],
            [-0.766293373819356, 51.31983892866765],
            [-0.767823724217211, 51.320084337496915],
            [-0.768424261606778, 51.318236462377122],
            [-0.771207407649036, 51.317619152684024],
            [-0.773313365062151, 51.316779773746212],
            [-0.777345403840693, 51.316515295822569],
            [-0.778668470417625, 51.3174858676581],
            [-0.791562455014541, 51.309532924991998],
            [-0.793055462733739, 51.308076297128046],
            [-0.78913412100131, 51.306728358371295],
            [-0.791415873371286, 51.3035336170717],
            [-0.793192436272302, 51.303619377262322],
            [-0.796586395695846, 51.304666819826245],
            [-0.797340402389844, 51.30381041447658],
            [-0.79708806548807, 51.302378063629085],
            [-0.796672650650991, 51.299447733918818],
            [-0.79730474380289, 51.299411063965593],
            [-0.796891398592667, 51.297664129280378],
            [-0.799523364543891, 51.297611106203718],
            [-0.799951006808285, 51.296942871892441],
            [-0.798468283957289, 51.29621458774605],
            [-0.799486352196339, 51.295400442528006],
            [-0.801616400848278, 51.294567997798346],
            [-0.801650976584525, 51.293349907345174],
            [-0.802501882426734, 51.293278580309135],
            [-0.802455448665594, 51.29302362599698],
            [-0.802057579234099, 51.292109545255656],
            [-0.800924363857884, 51.291567407690117],
            [-0.80098927845147, 51.290616696157883],
            [-0.800134062052264, 51.288929090512909],
            [-0.797579510920269, 51.287958706658266],
            [-0.79596601969096, 51.286814504331616],
            [-0.798058038389331, 51.284953922230912],
            [-0.798006015473954, 51.284584707063765],
            [-0.796410164857486, 51.283921775061465],
            [-0.792116591219439, 51.283282282026597],
            [-0.790309708323525, 51.282197152019123],
            [-0.807216934463124, 51.275696553348943],
            [-0.807541363875476, 51.275292509498613],
            [-0.806421234750183, 51.275088663610582],
            [-0.805196412397399, 51.27438916587343],
            [-0.803434732896464, 51.27222742779248],
            [-0.804766323857203, 51.270355354742726],
            [-0.80474336692244, 51.269361477677769],
            [-0.804418034017871, 51.261458465802434],
            [-0.806147172256213, 51.25811210674302],
            [-0.80649323571884, 51.253436062059372],
            [-0.804767102029057, 51.245066430314324],
            [-0.806228847760935, 51.244774708278257],
            [-0.807586462700677, 51.242968506976716],
            [-0.813449940895473, 51.241532667627979],
            [-0.813675197736719, 51.240349768235987],
            [-0.816271899821398, 51.23936532179534],
            [-0.820795322888999, 51.239272492704629],
            [-0.820880589120185, 51.234479567410524],
            [-0.823706866611145, 51.234302977440137],
            [-0.823546807940332, 51.233951569220132],
            [-0.826650147898682, 51.23436848519539],
            [-0.828006205429728, 51.233087181412159],
            [-0.828527726838558, 51.228906494006118],
            [-0.828991924012311, 51.225457200773114],
            [-0.832120403785484, 51.224124326104558],
            [-0.83360021784544, 51.22291433151527],
            [-0.833707674501748, 51.22200538231403],
            [-0.834650250896755, 51.221992289634116],
            [-0.836154237472111, 51.219591024545551],
            [-0.839583672579075, 51.216856346776808],
            [-0.840126358421821, 51.215202646997746],
            [-0.84253232920729, 51.213922581500483],
            [-0.842674224229292, 51.213516633509755],
            [-0.843674536740307, 51.213592164130638],
            [-0.846823449772337, 51.212366108222987],
            [-0.848929027340678, 51.210717864327847],
            [-0.849079944947367, 51.211038575073935],
            [-0.851137269779982, 51.211496712457865],
            [-0.855543968328366, 51.211667575470166],
            [-0.856818321284983, 51.212354451236322],
            [-0.858805618996953, 51.211944013243397],
            [-0.860837968287032, 51.211969206889073],
            [-0.863350562569734, 51.212550257253859],
            [-0.869068402106523, 51.212738731288887],
            [-0.872054831625839, 51.212507672538521],
            [-0.880903168937379, 51.210300477633403],
            [-0.884407157212119, 51.210207137399607],
            [-0.896016666830509, 51.209944038667224],
            [-0.90300081441473, 51.2093083102355],
            [-0.90432362305186, 51.209910593529372],
            [-0.904575997105495, 51.208806907294324],
            [-0.90665373837774, 51.208240039290217],
            [-0.905628304332041, 51.206748530168404],
            [-0.909193596799009, 51.205500414791445],
            [-0.912067100067099, 51.205780742446052],
            [-0.914901272188854, 51.205177594496476],
            [-0.917500630474985, 51.205123432208659],
            [-0.919553574791476, 51.204623548437986],
            [-0.92106393606577, 51.203695991814826],
            [-0.922200723619677, 51.203562589769923],
            [-0.92992761862372, 51.20299863785462],
            [-0.933192637702524, 51.203307204102593],
            [-0.949073960494243, 51.198965550927014],
            [-0.951191474257379, 51.200448484317846],
            [-0.953843642595308, 51.198364438273501],
            [-0.957207452701495, 51.196381093814828],
            [-0.960874806541959, 51.195587325524407],
            [-0.962033395717728, 51.194978940036485],
            [-0.964260966320684, 51.191881961104727],
            [-0.964974098434002, 51.190135679984685],
            [-0.967218766790089, 51.188303106230514],
            [-0.969322971041396, 51.187307330782282],
            [-0.973836202836334, 51.186009844561582],
            [-0.974823110461934, 51.186156075869043],
            [-0.975454247886943, 51.186860301307959],
            [-0.977119623078907, 51.187708451396773],
            [-0.979849391102116, 51.18792111654448],
            [-0.982172794257695, 51.189303672577687],
            [-0.985305056647925, 51.190025074004062],
            [-0.985898446417212, 51.190437566048111],
            [-0.984747764315558, 51.192920238607684],
            [-0.985327895948748, 51.196675032325622],
            [-0.98642966804421, 51.200078246961311],
            [-0.9862909908331, 51.203201844240738],
            [-0.983059010885128, 51.205863380901782],
            [-0.983494935525865, 51.208300462089497],
            [-0.984282535712446, 51.209525744722932],
            [-0.984175718185574, 51.212429302416666],
            [-0.982737943832802, 51.215630631215348],
            [-0.981708160753044, 51.216295193470842],
            [-0.980379242857339, 51.218509195931034],
            [-0.978117538163987, 51.22048574239939],
            [-0.981666831245364, 51.2219338437761],
            [-0.983298369223342, 51.223080143239564],
            [-0.981378026694595, 51.224314262974033],
            [-0.97894929735273, 51.223601603813663],
            [-0.978699130326827, 51.224746825696229],
            [-0.977336762546971, 51.225027178076701],
            [-0.975491139454768, 51.225916553975125],
            [-0.97611332986785, 51.227629615272747],
            [-0.976065863379903, 51.229058958548571],
            [-0.976977366866935, 51.230064161481103],
            [-0.979907865439825, 51.229213895104174],
            [-0.982210985432944, 51.231025184923588],
            [-0.983156684951114, 51.233346192200884],
            [-0.983126320086358, 51.235308920974965],
            [-0.978329810707044, 51.235607963778719],
            [-0.976255237823125, 51.237049271372776],
            [-0.977222110154834, 51.237825654223066],
            [-0.974813029549829, 51.238520360338995],
            [-0.976782105734042, 51.241194773990976],
            [-0.978835322078343, 51.24208494119037],
            [-0.981019130800955, 51.24270914131813],
            [-0.982003877033138, 51.24261969330319],
            [-0.987677355950762, 51.243844162803917],
            [-0.99078126017273, 51.243808014523424],
            [-0.993724936094879, 51.244417842235364],
            [-0.99268244900121, 51.245576059539026],
            [-0.992952863248642, 51.24572405869305],
            [-0.992597334243181, 51.246324373742233],
            [-0.991031116695897, 51.247383651093529],
            [-0.992160406151435, 51.248157710430277],
            [-0.989083557375017, 51.25014902371182],
            [-0.990179015184887, 51.250833787316914],
            [-0.990404958625134, 51.251639633874845],
            [-0.989768576578778, 51.25243264754117],
            [-0.985410923094989, 51.254914568092659],
            [-0.985816899034615, 51.255350607428127],
            [-0.984187109481926, 51.256542326101801],
            [-0.98318491333915, 51.257864464579413],
            [-0.982647515398782, 51.260331736295228],
            [-0.982126976666185, 51.260994429243041],
            [-0.980817437319258, 51.261625077111113],
            [-0.979873814262493, 51.26521281553358],
            [-0.979943090520453, 51.267587341924184],
            [-0.979767974639093, 51.270123395051016],
            [-0.980132351868706, 51.271211918622313],
            [-0.979536140064164, 51.273342353976922],
            [-0.97806008373992, 51.275128805727839],
            [-0.981498307233755, 51.277574940765653],
            [-0.981483585291097, 51.278435358024375],
            [-0.98310532874536, 51.280374662158763],
            [-0.9821442706965, 51.282210594708829],
            [-0.984184465932095, 51.282347903657914],
            [-0.982413924473642, 51.283764076267303],
            [-0.981744000319349, 51.286631230416567],
            [-0.988003603476917, 51.286944458331341],
            [-0.991102144101528, 51.28754758446469],
            [-0.992157882045665, 51.288123175989469],
            [-0.99570925552391, 51.288681518217906],
            [-0.996400970553277, 51.288460846894985],
            [-1.000239148625137, 51.28922113300996],
            [-1.00081849789419, 51.290279050098114],
            [-1.001261997215168, 51.290129967378839],
            [-1.00092256355437, 51.290968731430446],
            [-1.00127704179797, 51.291618285360755],
            [-1.000734776152946, 51.291824074859996],
            [-1.001246645733449, 51.292556799415728],
            [-1.001074483491674, 51.293220745116827],
            [-1.002295550727586, 51.293988285873596],
            [-1.001621287012096, 51.295914937607868],
            [-1.001092021343078, 51.298097297327416],
            [-1.001235050555386, 51.300231436111574],
            [-0.998435008709158, 51.29997193473401],
            [-0.995414784886868, 51.298695272947313],
            [-0.995672957521227, 51.299221724844536],
            [-0.993359658409494, 51.303535146051466],
            [-0.990626910319938, 51.303535907699917],
            [-0.990254328169225, 51.304172032633026],
            [-0.988978583459741, 51.304388527610499],
            [-0.986452958893065, 51.306772079578252],
            [-0.984292543040563, 51.306832496421507],
            [-0.983905105180318, 51.310020420772702],
            [-0.985275880941294, 51.312399013859064],
            [-0.985449056560698, 51.314370673855379],
            [-0.986844167052815, 51.317021916149542],
            [-0.986607227507947, 51.320417063114135],
            [-0.987098150149671, 51.32288692755214],
            [-0.986853926483122, 51.323875738820767],
            [-0.985355882445936, 51.324580338976688],
            [-0.985001982627629, 51.325594273233229],
            [-0.982590828655497, 51.32617942225199],
            [-0.98312765037021, 51.328254050387109],
            [-0.98383813055807, 51.329032630875716],
            [-0.983762553925039, 51.329919489232459],
            [-0.983499431069159, 51.330394683621492],
            [-0.98188288762238, 51.331123388909425],
            [-0.982179825921978, 51.333919793226592],
            [-0.981019848415869, 51.337228667899396],
            [-0.981947488431333, 51.337605404920673],
            [-0.991692455294733, 51.338703074361199],
            [-0.990172527008556, 51.339404849716573],
            [-0.989524689330621, 51.340466616738311],
            [-0.990383687970172, 51.341425376830109],
            [-0.990755885922179, 51.34265149705832],
            [-0.992333809898101, 51.342190299692668],
            [-0.994622571836875, 51.342310671304844],
            [-0.993863534030568, 51.344454145148767],
            [-0.994646471277776, 51.346862629909886],
            [-0.988803937276996, 51.351431547228259],
            [-0.988317980279642, 51.355501626345067],
            [-0.986731087007234, 51.35823496901304],
            [-0.986759988507346, 51.35985467619107],
            [-0.982727712197916, 51.359820641203306],
            [-0.977442739704554, 51.360637875249822],
            [-0.975522868991333, 51.360167011177239],
            [-0.972384004113654, 51.35993720816743],
            [-0.952261281406074, 51.362733576284192],
            [-0.941400002794208, 51.363897543828742],
            [-0.939173144509936, 51.363170634398223],
            [-0.937069595496173, 51.36388352311792],
            [-0.93669501921376, 51.365978860514772],
            [-0.936009195212386, 51.366316130536852],
            [-0.936359126554055, 51.365782484112813],
            [-0.935756116462197, 51.36545959146212],
            [-0.931924898967922, 51.364542619074179],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000090",
        LAD13CDO: "24UH",
        LAD13NM: "Havant",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-1.008494460053964, 50.825493290263275],
              [-1.008255762736324, 50.825099177028939],
              [-1.007185922012023, 50.825000153257037],
              [-1.007127029874792, 50.824697494868126],
              [-1.008235208369638, 50.824661953311292],
              [-1.008751002849584, 50.824843493030656],
              [-1.008793333987233, 50.825392412027583],
              [-1.009383364405867, 50.825286810481622],
              [-1.008932926047489, 50.825035684931215],
              [-1.009168857140481, 50.824822760769962],
              [-1.009795196913539, 50.824946781248379],
              [-1.009429823832449, 50.82463432912617],
              [-1.009855826724144, 50.824093880958252],
              [-1.008888500012944, 50.824585668734962],
              [-1.009008828787832, 50.82399856276087],
              [-1.008438506981745, 50.824179869190395],
              [-1.009194903992791, 50.823591870404684],
              [-1.007221857916759, 50.823305319822076],
              [-1.006138102764527, 50.823796993707042],
              [-1.007120373048271, 50.823069744878822],
              [-1.00760302241069, 50.82191692848977],
              [-1.009040412424081, 50.822372036859832],
              [-1.010753647256202, 50.823934228874386],
              [-1.010251931380487, 50.82510173413889],
              [-1.010479144959401, 50.825636932469429],
              [-1.009793713878254, 50.826560972084543],
              [-1.009033631265674, 50.826247867156631],
              [-1.008494460053964, 50.825493290263275],
            ],
          ],
          [
            [
              [-1.014813404787485, 50.828759919252654],
              [-1.013787430536106, 50.82771978352217],
              [-1.013322888099079, 50.828000928067191],
              [-1.013278886291475, 50.827730772919608],
              [-1.012516539611828, 50.827590332957108],
              [-1.013107787439466, 50.827293176806116],
              [-1.012753996103336, 50.826429576239875],
              [-1.014213190790666, 50.825571409856614],
              [-1.014166987161783, 50.82601256480536],
              [-1.014906638061167, 50.826151003961954],
              [-1.01468710046199, 50.8266653359183],
              [-1.017050474813677, 50.827913676893147],
              [-1.015880420926769, 50.82845777141101],
              [-1.01544930177534, 50.828297661213291],
              [-1.014973770526465, 50.828966307952463],
              [-1.014813404787485, 50.828759919252654],
            ],
          ],
          [
            [
              [-1.005332062969213, 50.833803581300849],
              [-1.003628200062908, 50.831726107008251],
              [-1.001775700863955, 50.830366757481855],
              [-1.001877599006609, 50.82958345900083],
              [-1.00239550179819, 50.8293342906913],
              [-1.003423604142678, 50.830130840954084],
              [-1.00348854203361, 50.831215923815094],
              [-1.004992800613423, 50.831592964814348],
              [-1.005588023759982, 50.831313866124248],
              [-1.006088142197841, 50.831434132836321],
              [-1.005289109482193, 50.831681821114117],
              [-1.005402551327609, 50.832290699113656],
              [-1.005869653818523, 50.83196014762558],
              [-1.005461820061614, 50.831706674134651],
              [-1.006318553065558, 50.831550302916206],
              [-1.007008444246069, 50.832163186381919],
              [-1.005776757107432, 50.833325357869512],
              [-1.007959190704925, 50.833065152391391],
              [-1.008986017992015, 50.833326572260042],
              [-1.008792385826772, 50.833886975842105],
              [-1.007259850211121, 50.833900929091399],
              [-1.00714493544091, 50.834297431856562],
              [-1.005332062969213, 50.833803581300849],
            ],
          ],
          [
            [
              [-0.968830211919965, 50.833838415395675],
              [-0.96776537204366, 50.83337846397604],
              [-0.96788158576105, 50.833131290761116],
              [-0.968672061549814, 50.83328216301819],
              [-0.968835250152632, 50.833738639967962],
              [-0.969500375940925, 50.833520594033352],
              [-0.968857984877021, 50.833351251673093],
              [-0.969561252990649, 50.832754945981307],
              [-0.968853545775237, 50.832972616064673],
              [-0.969045043406217, 50.832466215188958],
              [-0.96830503220958, 50.832925502625983],
              [-0.967926516323021, 50.832703631313358],
              [-0.969618758039096, 50.832206893542661],
              [-0.968920923492325, 50.832170155242075],
              [-0.969252173580853, 50.831959052749312],
              [-0.970196896513483, 50.83232710381742],
              [-0.970534109949532, 50.831586375027037],
              [-0.968483569378189, 50.831589852818915],
              [-0.970257415099457, 50.831125301795872],
              [-0.970295792586728, 50.830736252546657],
              [-0.968800916647252, 50.831171794519406],
              [-0.968520695488291, 50.830678311870905],
              [-0.966926328498754, 50.830538311073184],
              [-0.966413110937089, 50.830951932071194],
              [-0.965277016941871, 50.831085750089237],
              [-0.964960036541165, 50.831485816567969],
              [-0.964232549424479, 50.83150634108236],
              [-0.963658581838927, 50.833443689103866],
              [-0.962493853678371, 50.832367695350527],
              [-0.962217437530478, 50.831385023291361],
              [-0.961153485182945, 50.830634551546403],
              [-0.959549825806967, 50.83053033834593],
              [-0.959074436617693, 50.831474839493652],
              [-0.95729439244415, 50.831319561716398],
              [-0.957133490523396, 50.83070211741655],
              [-0.955303798539422, 50.829973524112617],
              [-0.952563138740703, 50.828204355763887],
              [-0.952121585219985, 50.826109564450192],
              [-0.951041749220613, 50.823989251205226],
              [-0.951766945449933, 50.821846484685004],
              [-0.953543157404839, 50.820885805514344],
              [-0.954469592015785, 50.819832057532665],
              [-0.954547137984809, 50.818711347915674],
              [-0.955568486801627, 50.817536138928489],
              [-0.954860395052352, 50.81669706436162],
              [-0.957141014102238, 50.81627681882447],
              [-0.957618450822557, 50.815808961315653],
              [-0.957060024863345, 50.814941560347229],
              [-0.956992154821104, 50.813527281904079],
              [-0.955727827780468, 50.814082522709363],
              [-0.954759435119787, 50.813778888251214],
              [-0.956595645463565, 50.81227463380695],
              [-0.956530741408386, 50.811492576768231],
              [-0.956878185045746, 50.811378776294163],
              [-0.956085719810389, 50.811208020336828],
              [-0.956639924870827, 50.810732759739871],
              [-0.956092795330656, 50.80974405095872],
              [-0.956552231122867, 50.809635749675699],
              [-0.956056709720551, 50.809197863284759],
              [-0.956552104971897, 50.808876753589523],
              [-0.957179595695515, 50.809379666409313],
              [-0.957033928668454, 50.808973687169669],
              [-0.956207834237763, 50.808657846680156],
              [-0.956798891517266, 50.808438311296236],
              [-0.95673375025254, 50.808113087670677],
              [-0.957177793902743, 50.808185402269793],
              [-0.956954448817457, 50.807698692419571],
              [-0.957538242380493, 50.808060025380414],
              [-0.957569590249443, 50.807607965931503],
              [-0.957072835346169, 50.807353526466841],
              [-0.957489039125622, 50.807145913960248],
              [-0.958286953547821, 50.807834696503193],
              [-0.958670200145206, 50.807768872337249],
              [-0.959005815142519, 50.809591116467921],
              [-0.960168016040439, 50.811007956677777],
              [-0.961224693758717, 50.811361792898623],
              [-0.965455226249436, 50.811004589996678],
              [-0.966638572517341, 50.811408960476442],
              [-0.966815683897821, 50.810972578250272],
              [-0.9671162477058, 50.811181174615712],
              [-0.967182728403003, 50.810869712085889],
              [-0.968850674713791, 50.810344893171532],
              [-0.971068017303199, 50.810653134857162],
              [-0.97091486766955, 50.81038829472709],
              [-0.969125925703352, 50.810220526261539],
              [-0.969217637840823, 50.809922774692204],
              [-0.967512464878562, 50.810076765137538],
              [-0.9671594772192, 50.809735511235033],
              [-0.966340675352894, 50.809664412182656],
              [-0.966574527809993, 50.809486626890688],
              [-0.965219884850324, 50.809771385670381],
              [-0.961739168580762, 50.809282733500062],
              [-0.962179886729001, 50.808737192090533],
              [-0.963649128048115, 50.809109962849881],
              [-0.963392643533659, 50.808321711541815],
              [-0.963133121933938, 50.808697104654584],
              [-0.962267203661995, 50.808191204609088],
              [-0.962403062615151, 50.807632159409053],
              [-0.965184993694725, 50.80653185962445],
              [-0.966406371197601, 50.806300777304472],
              [-0.969334220359791, 50.80643009168692],
              [-0.970952991204146, 50.807231250504557],
              [-0.97132934380382, 50.808060110130683],
              [-0.972157513419288, 50.808803020677672],
              [-0.973384951019052, 50.809073718164385],
              [-0.973369318808717, 50.80855649335205],
              [-0.971895019882331, 50.806988654161685],
              [-0.972751422965806, 50.807543859367556],
              [-0.973239847909679, 50.807081430388209],
              [-0.972726242525779, 50.806620973820266],
              [-0.972071959667939, 50.806880500472197],
              [-0.972460936912448, 50.806616839373142],
              [-0.970897801560584, 50.805673206110242],
              [-0.969539703190568, 50.804701686045149],
              [-0.968986559223018, 50.802751650361969],
              [-0.967494180767305, 50.803049606727342],
              [-0.965783875708591, 50.802675646504412],
              [-0.964515376372273, 50.803437780625025],
              [-0.961558961857074, 50.803453774380948],
              [-0.960828459527926, 50.803629825727178],
              [-0.959897833639973, 50.804573875717288],
              [-0.95862319152831, 50.804711788352172],
              [-0.95727569394623, 50.804296875822232],
              [-0.956136162159182, 50.80295664639587],
              [-0.955410522651496, 50.802850332146896],
              [-0.95417909461911, 50.801752966381606],
              [-0.95276089419423, 50.801078373455518],
              [-0.952991110759717, 50.800619106403047],
              [-0.954826755295503, 50.799628364364452],
              [-0.956227621483454, 50.799806372261777],
              [-0.957079787587524, 50.799135032146872],
              [-0.956492061746628, 50.797107286059827],
              [-0.956593440226887, 50.795230485397788],
              [-0.955455286579237, 50.794475693728749],
              [-0.955500408198958, 50.793724295014066],
              [-0.957416445551901, 50.793633517174428],
              [-0.958612566902277, 50.793056965576362],
              [-0.959785790822365, 50.790761662851232],
              [-0.960470482530266, 50.790531252958623],
              [-0.960637554868256, 50.790802527071008],
              [-0.961039198386209, 50.790732362808022],
              [-0.960844160089916, 50.790570553059339],
              [-0.962751213864886, 50.789468810844262],
              [-0.965006735901251, 50.789286501332676],
              [-0.966367479030691, 50.789470327624265],
              [-0.967877411872654, 50.790159956935156],
              [-0.96699053744887, 50.789072965704108],
              [-0.966823372269103, 50.787638038575821],
              [-0.966166532535017, 50.788224835226366],
              [-0.965203548214312, 50.788404249181617],
              [-0.962298316072267, 50.788073588462098],
              [-0.960764550540549, 50.789105806725601],
              [-0.96014860906002, 50.788924058830375],
              [-0.957568417046503, 50.78979852221525],
              [-0.951323810762459, 50.786917004755146],
              [-0.95254484022824, 50.786046672115219],
              [-0.951652550720952, 50.785919052808708],
              [-0.951210888049234, 50.784228020934798],
              [-0.955217197652049, 50.783768458852357],
              [-0.955947224140367, 50.782899987627822],
              [-0.958636446423843, 50.782082289168706],
              [-0.96143881725125, 50.782235866788923],
              [-0.959738444301966, 50.781770175358623],
              [-0.958903356124356, 50.781177290510868],
              [-0.957487603974518, 50.781693429323887],
              [-0.956423141622619, 50.781593084810055],
              [-0.955414310861258, 50.781861037189188],
              [-0.95384346769363, 50.783095172998891],
              [-0.949287781394146, 50.783131572859297],
              [-0.948413516751552, 50.783657872843996],
              [-0.948380890106721, 50.784353627199728],
              [-0.94437188694111, 50.784486489002319],
              [-0.9421839842976, 50.784947791399077],
              [-0.943222082969352, 50.786723399317871],
              [-0.94219058469987, 50.785912786758104],
              [-0.941963035561361, 50.786252452683158],
              [-0.941329100871812, 50.785867202832755],
              [-0.941255626794336, 50.785100343100147],
              [-0.939317036287494, 50.7842580881346],
              [-0.937932468665054, 50.782820120853138],
              [-0.93836622681337, 50.782395108196511],
              [-0.937028064909978, 50.782542997151907],
              [-0.936812254502634, 50.784991590737363],
              [-0.937756500108349, 50.785077530304612],
              [-0.938229616964368, 50.785671769660752],
              [-0.938950948085508, 50.785892362374277],
              [-0.937559993421657, 50.786800574866938],
              [-0.936824660532061, 50.786821754392825],
              [-0.936106117452647, 50.785977967587442],
              [-0.936621823936064, 50.782004218622362],
              [-0.93629392350442, 50.781013810774823],
              [-0.936486196567793, 50.778164818368495],
              [-0.937448971356303, 50.77736423095272],
              [-0.939358231642432, 50.776792570136742],
              [-0.946471921070815, 50.77713760468518],
              [-0.951861120409595, 50.77785791496926],
              [-0.970900207064793, 50.781652434984579],
              [-0.97870452236643, 50.78263558647506],
              [-0.98669447975648, 50.783511013534273],
              [-1.001082947934692, 50.784561353467907],
              [-1.001098685965746, 50.784754834340951],
              [-1.007004221950051, 50.78548959991501],
              [-1.007086742774655, 50.785277171918985],
              [-1.01407173141393, 50.785822018653207],
              [-1.018761129143112, 50.785506333638061],
              [-1.020200892997259, 50.785647030605809],
              [-1.021576617975914, 50.786273684885145],
              [-1.022628408590837, 50.787333761868105],
              [-1.023105139812736, 50.789441176281265],
              [-1.024829831268617, 50.792191219682373],
              [-1.025346037173597, 50.795409565342233],
              [-1.024759982344373, 50.795957726669116],
              [-1.022792866754443, 50.795506016897598],
              [-1.021324568753133, 50.795833639410766],
              [-1.01990415158643, 50.795770458164426],
              [-1.019652932015461, 50.795570505798523],
              [-1.021068926337997, 50.795573401151216],
              [-1.022081592516142, 50.794165523969276],
              [-1.021962822438271, 50.793805715063208],
              [-1.02048213295685, 50.792963257257398],
              [-1.017609598267548, 50.792462488080339],
              [-1.017204751226739, 50.792553503441852],
              [-1.017697406583198, 50.793756395086859],
              [-1.016974191769222, 50.792587532530568],
              [-1.016661530506678, 50.79301835203578],
              [-1.017073157238909, 50.793822181407073],
              [-1.017487650827352, 50.794083766282029],
              [-1.017776745514484, 50.793897350980508],
              [-1.018217600287209, 50.794458616036991],
              [-1.017331117655115, 50.795188564908656],
              [-1.015643657215359, 50.794397361861741],
              [-1.009289176499104, 50.793631349649822],
              [-1.010981362172758, 50.79351980564384],
              [-1.009531779001099, 50.793162185198995],
              [-1.007081971928883, 50.793540645472774],
              [-1.00595478350563, 50.793570616525869],
              [-1.005879683390442, 50.793298393312575],
              [-1.004219751414675, 50.793828305087246],
              [-1.005308639410724, 50.793932017409873],
              [-1.00355354563062, 50.793997977838629],
              [-1.000405752917085, 50.794833466011973],
              [-0.994911770699397, 50.79814689104424],
              [-0.992998340554999, 50.800422664962575],
              [-0.990905452480875, 50.801863220755429],
              [-0.989870156574368, 50.803413618544759],
              [-0.989274352856254, 50.805573930454713],
              [-0.98899950732113, 50.810944756186743],
              [-0.985907903682964, 50.813709299159598],
              [-0.986191684183502, 50.819550792108053],
              [-0.98572033554732, 50.818569179142095],
              [-0.984084760327666, 50.819233009785918],
              [-0.983347270640215, 50.820769751953108],
              [-0.983577541474283, 50.821999274599193],
              [-0.984838164969279, 50.823181108930036],
              [-0.986176877718365, 50.823906773758623],
              [-0.987799800112806, 50.823965824656426],
              [-0.986068895699376, 50.824041626841492],
              [-0.985258575125074, 50.825007604181891],
              [-0.98625688326168, 50.825982105595678],
              [-0.987211288110864, 50.825968809075846],
              [-0.98681036021826, 50.826912269770745],
              [-0.983499555563136, 50.828272866453766],
              [-0.981887926302636, 50.832127514955317],
              [-0.981328129024287, 50.832076767204981],
              [-0.982177742527925, 50.830619255084173],
              [-0.981111606052398, 50.829955277851013],
              [-0.980576039496972, 50.830357974355138],
              [-0.979740592460587, 50.830316501527648],
              [-0.978241290285627, 50.831414893845142],
              [-0.977761193381066, 50.832071659585075],
              [-0.978193675677423, 50.833010696242347],
              [-0.977765522903405, 50.833043817479627],
              [-0.977015412003931, 50.831954513967744],
              [-0.975405590484719, 50.832193090130048],
              [-0.976060825117606, 50.832684450837512],
              [-0.975336974956921, 50.832797702228113],
              [-0.974854574208768, 50.833427457436748],
              [-0.974948201246658, 50.832848245133462],
              [-0.973815574326703, 50.832952500579012],
              [-0.973975253379106, 50.832599588650559],
              [-0.972828117287451, 50.832718095410733],
              [-0.973140878645082, 50.832313474297436],
              [-0.970787085655106, 50.832803531890562],
              [-0.969118579297652, 50.834543299404331],
              [-0.968830211919965, 50.833838415395675],
            ],
          ],
          [
            [
              [-1.014934195474654, 50.837324746946884],
              [-1.013166669284462, 50.836287339746683],
              [-1.011641241521177, 50.836431805873588],
              [-1.011361974482344, 50.835668653215961],
              [-1.010245459827393, 50.835011711777568],
              [-1.014157994480992, 50.835474678092716],
              [-1.014333296662189, 50.835984249521758],
              [-1.015642625004553, 50.836930545991663],
              [-1.018092736236864, 50.836642731864607],
              [-1.020372908125917, 50.835312978328652],
              [-1.021407723328687, 50.835265903955282],
              [-1.021720728166377, 50.835849459843331],
              [-1.022077640316709, 50.835827271046689],
              [-1.0223797825796, 50.836251562321067],
              [-1.021881650287316, 50.837322922306257],
              [-1.021589654884891, 50.837160404003832],
              [-1.020168506193129, 50.83829325995648],
              [-1.018499407819869, 50.838175819832266],
              [-1.016833449460174, 50.83743608361997],
              [-1.014934195474654, 50.837324746946884],
            ],
          ],
          [
            [
              [-1.019400697636841, 50.904999174563478],
              [-1.018386027346191, 50.904487960537935],
              [-1.018506244505457, 50.903843298452564],
              [-1.015218992086198, 50.903399263287746],
              [-1.012025265059247, 50.902503595861823],
              [-1.011149245362043, 50.903778534286126],
              [-1.008988938602687, 50.902975161811426],
              [-1.009115333567941, 50.902715447216544],
              [-1.007794876726129, 50.902547762848741],
              [-1.007283646352127, 50.901977779457198],
              [-1.001496277093288, 50.899485128825603],
              [-1.004722789894278, 50.896554984285373],
              [-1.002753989439518, 50.895727047547247],
              [-0.99878363500588, 50.895409802557232],
              [-0.997309790296744, 50.894648965052056],
              [-0.995774496692799, 50.893044965884272],
              [-0.993233351335327, 50.89206895970645],
              [-0.992351775139532, 50.891675569046626],
              [-0.986553844741066, 50.886029236660448],
              [-0.98646763431545, 50.885088301592127],
              [-0.986007651103833, 50.884732243539361],
              [-0.985459992130119, 50.885009856924945],
              [-0.978729964278123, 50.878316363000074],
              [-0.9765182284362, 50.879161195323213],
              [-0.975853576477407, 50.878032176722222],
              [-0.974261579937531, 50.878033488566899],
              [-0.972586012786878, 50.878668029953388],
              [-0.971708022888443, 50.877872538232872],
              [-0.96990634347929, 50.878036510468682],
              [-0.968271615495089, 50.87771992050898],
              [-0.968970752148854, 50.875666763094372],
              [-0.966925422285378, 50.875025486763967],
              [-0.968026254989329, 50.873447106843948],
              [-0.96335161236055, 50.8730037174317],
              [-0.960458928985275, 50.872192930569547],
              [-0.963400751176986, 50.867263191099084],
              [-0.96086234747271, 50.867663282917867],
              [-0.958024720300774, 50.867714428737877],
              [-0.955743505564807, 50.868239905200937],
              [-0.953561530164925, 50.86820238170381],
              [-0.943354786160437, 50.872494410168166],
              [-0.938623857717626, 50.873730263990971],
              [-0.9387424339518, 50.872576673379605],
              [-0.936953086222469, 50.870077495729191],
              [-0.935085890541639, 50.868945376989515],
              [-0.933550669414079, 50.868900787368815],
              [-0.931946940240677, 50.868433790511197],
              [-0.929382823270526, 50.866777218467163],
              [-0.929238730408487, 50.866119425325735],
              [-0.928708234538082, 50.86632498893168],
              [-0.928672105560949, 50.865793185123302],
              [-0.927056686358198, 50.865037345310839],
              [-0.926465727963918, 50.864101161050399],
              [-0.9281394284977, 50.862684892134965],
              [-0.928263728121745, 50.861539455384438],
              [-0.929807332109422, 50.858537441520205],
              [-0.931244937703606, 50.857243063213673],
              [-0.932047903588228, 50.855677575796754],
              [-0.933461579886028, 50.854802913766903],
              [-0.932777063870117, 50.853919861681284],
              [-0.932788845428456, 50.853216734106269],
              [-0.932844704197128, 50.852076061009754],
              [-0.93374203399063, 50.851848650326822],
              [-0.93444204047241, 50.84968148711458],
              [-0.933581126695071, 50.847565715952769],
              [-0.932403594941064, 50.846754593617938],
              [-0.93249332198388, 50.846059372351561],
              [-0.933609818906616, 50.846306987755504],
              [-0.932155283358364, 50.844751426262796],
              [-0.932198207370457, 50.843430777632982],
              [-0.932394710500482, 50.84372034455474],
              [-0.932816126461008, 50.843303335602371],
              [-0.933011429385341, 50.843957098631648],
              [-0.934123618946519, 50.844017619901699],
              [-0.934260431532632, 50.844309336348147],
              [-0.935203125562669, 50.844515781339226],
              [-0.937585851452144, 50.844420587268559],
              [-0.937822478153, 50.844133173010128],
              [-0.937274608869534, 50.843740596780052],
              [-0.937756079161776, 50.842678434456808],
              [-0.938396427572873, 50.842249908520479],
              [-0.939647168293519, 50.842211817355455],
              [-0.940027375657245, 50.841917609367663],
              [-0.94086120134506, 50.842289386703563],
              [-0.943569576075432, 50.842296845285986],
              [-0.94520099174103, 50.842698289670281],
              [-0.950967956318327, 50.842995766010873],
              [-0.951476414396787, 50.843123535812332],
              [-0.951245972797731, 50.844026139445816],
              [-0.951836282369822, 50.843036840173411],
              [-0.95167071112427, 50.842565929902683],
              [-0.955838208832692, 50.84115364936175],
              [-0.955874288237128, 50.839854964359837],
              [-0.956201478021046, 50.839703664558478],
              [-0.957829626130898, 50.83967864526722],
              [-0.959011488143964, 50.83928541600848],
              [-0.963467354183838, 50.840860139674149],
              [-0.965629454280745, 50.840390113031503],
              [-0.967484543440551, 50.841280636094503],
              [-0.97087336051922, 50.842183762929452],
              [-0.974473844899151, 50.842313473873588],
              [-0.977134142566104, 50.841401568992616],
              [-0.978159842526656, 50.839568833316385],
              [-0.979216304016338, 50.839512430255418],
              [-0.979984527795018, 50.838996666207002],
              [-0.980467166206259, 50.837116896770453],
              [-0.979216646341512, 50.836176113553257],
              [-0.978939796855215, 50.835521715498572],
              [-0.979471877428318, 50.835347412751538],
              [-0.981979771255667, 50.837632795734443],
              [-0.981230926886444, 50.835694613569252],
              [-0.981436260924619, 50.834938314478343],
              [-0.982134121246189, 50.837392236250679],
              [-0.98339366793684, 50.838248534301414],
              [-0.986181518607521, 50.838313250319018],
              [-0.987373938401496, 50.839650934294639],
              [-0.986822115874887, 50.839686613012411],
              [-0.987578802394424, 50.839962961938845],
              [-0.986378919244898, 50.837854534891626],
              [-0.985991673320548, 50.837833185737161],
              [-0.987614183083606, 50.837539717471834],
              [-0.989660695168002, 50.837668960810525],
              [-0.993526298492685, 50.838862425131445],
              [-0.994838923248175, 50.839705567223525],
              [-0.997272248345917, 50.840479191063963],
              [-0.998670092949583, 50.842323016329559],
              [-1.001204825893409, 50.843678359102817],
              [-1.000870696184187, 50.844160208972937],
              [-0.999863355040226, 50.844307156727538],
              [-0.998028063127734, 50.846039608267318],
              [-0.996665584097637, 50.846649304081197],
              [-0.998012696633295, 50.846226525613204],
              [-0.999872933261662, 50.844855796779214],
              [-1.000924673107309, 50.844891781831855],
              [-1.002819068178428, 50.84790976072059],
              [-1.002236865600302, 50.849632292190108],
              [-1.000798430231386, 50.851401450734677],
              [-1.002197432897148, 50.850149037937577],
              [-1.002534925217364, 50.849377647015167],
              [-1.002583396904179, 50.849769245600491],
              [-1.002968650051793, 50.849555811686649],
              [-1.002648847981652, 50.849164593106508],
              [-1.002989468903904, 50.847712476577108],
              [-1.00135823529241, 50.84527498611606],
              [-1.001615371043587, 50.844274493836764],
              [-1.002764107175328, 50.843024428905878],
              [-1.004251104599579, 50.842828491777226],
              [-1.011920614370345, 50.844454808017062],
              [-1.013873303027137, 50.844402976272669],
              [-1.015692104491296, 50.843760958053004],
              [-1.019924988940215, 50.843207551129886],
              [-1.021481606313699, 50.842395976117906],
              [-1.021204063590309, 50.842220089113397],
              [-1.020558401053996, 50.842735353067766],
              [-1.019292424123736, 50.842622206017715],
              [-1.019653149247804, 50.842352756030969],
              [-1.019173097945513, 50.84201329371421],
              [-1.019014850479426, 50.842244876006781],
              [-1.01877867001442, 50.841858001249932],
              [-1.019552809614791, 50.841922059958002],
              [-1.021040918189448, 50.841464229514742],
              [-1.019268867176245, 50.840696660828847],
              [-1.019602756797393, 50.840148210236485],
              [-1.019105029574527, 50.840043309763836],
              [-1.019415628764968, 50.839860668872355],
              [-1.018575969464492, 50.839265485929296],
              [-1.019697036300389, 50.839037498129116],
              [-1.020025052057191, 50.839243488733437],
              [-1.020381988613005, 50.838746488011715],
              [-1.020640821253073, 50.839068801993108],
              [-1.022367024344996, 50.841825161837662],
              [-1.021653976148251, 50.842099761120309],
              [-1.021719437031014, 50.842637176664759],
              [-1.022560886041704, 50.842811490945031],
              [-1.023456107031429, 50.846058169351309],
              [-1.024836039001803, 50.845956397215048],
              [-1.024999110566694, 50.84671944429514],
              [-1.025152755764432, 50.847730611811151],
              [-1.023864424824487, 50.847932973214967],
              [-1.024534313890214, 50.849800069086911],
              [-1.022534592149386, 50.850393048504188],
              [-1.022644184539928, 50.852082801088663],
              [-1.02311967458452, 50.852782818454415],
              [-1.037558248421724, 50.852954012469922],
              [-1.046985439506294, 50.85393602332767],
              [-1.046810403717795, 50.855065877590526],
              [-1.049782412796752, 50.855279853292117],
              [-1.04989439541343, 50.85585180186127],
              [-1.050596482276977, 50.856189344882239],
              [-1.051265379632113, 50.85592230388685],
              [-1.052288471145166, 50.856544816097788],
              [-1.054029975847078, 50.856560739640287],
              [-1.052567061359681, 50.860185525749124],
              [-1.050414125574321, 50.859642859505989],
              [-1.048829686773132, 50.863075964154994],
              [-1.048700871691926, 50.864588385713056],
              [-1.048907517671053, 50.86546685730503],
              [-1.054039298131566, 50.865020248309378],
              [-1.053539720743025, 50.866393879868973],
              [-1.054788591229864, 50.866404896991732],
              [-1.054632288648507, 50.867458474038166],
              [-1.047396706509342, 50.865759391799834],
              [-1.047452796502495, 50.866981956750976],
              [-1.046455004724414, 50.866922553058934],
              [-1.046036440042468, 50.867587290841421],
              [-1.045565659916715, 50.869277666457521],
              [-1.046403570029272, 50.869653213799261],
              [-1.045712266483234, 50.870589099946564],
              [-1.047809452161587, 50.871099921625714],
              [-1.047615383152226, 50.872394184891888],
              [-1.046508800556258, 50.874109050644215],
              [-1.044192539159574, 50.873254690815337],
              [-1.043090684866629, 50.875009130832382],
              [-1.04265840238914, 50.874949832407808],
              [-1.042430287920145, 50.875261806416724],
              [-1.041434567886229, 50.877934354471513],
              [-1.040434926898919, 50.877815533160245],
              [-1.039405447092676, 50.877696457778427],
              [-1.037912413568588, 50.880210208656244],
              [-1.035037879489406, 50.880639718099694],
              [-1.036614602231869, 50.882279513997119],
              [-1.041940159163994, 50.882600308166431],
              [-1.044511967768823, 50.883352486705292],
              [-1.044778549581886, 50.883802502922322],
              [-1.04429131382435, 50.884340773854788],
              [-1.04528874118341, 50.884644792474916],
              [-1.046651743405077, 50.886958951052925],
              [-1.046839269019973, 50.887592666286139],
              [-1.045134830845223, 50.889045443748394],
              [-1.047587729713778, 50.890559157636538],
              [-1.049147262761715, 50.891474730342651],
              [-1.042490247671469, 50.895920257255312],
              [-1.037998562579423, 50.901501049093881],
              [-1.033910786209318, 50.902219087866456],
              [-1.034132072692389, 50.904171423462493],
              [-1.034067752066573, 50.905286878646464],
              [-1.032182260614073, 50.904788362632921],
              [-1.029280684599434, 50.904664460997317],
              [-1.028456546968957, 50.905790680955704],
              [-1.023876136864333, 50.90616884403687],
              [-1.025565933110379, 50.90909117764663],
              [-1.024543845511803, 50.90947202626333],
              [-1.021671109441901, 50.909655725660123],
              [-1.0187492261913, 50.909063775407141],
              [-1.017739914535021, 50.908496846953668],
              [-1.018705927808885, 50.908078716722763],
              [-1.019196917540106, 50.907117030984182],
              [-1.018878981935243, 50.906747460194119],
              [-1.019400697636841, 50.904999174563478],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000091",
        LAD13CDO: "24UJ",
        LAD13NM: "New Forest",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-1.379884540810711, 50.780271246362744],
              [-1.38239810563, 50.778859235923214],
              [-1.384582105521006, 50.778556030193805],
              [-1.386936754760129, 50.777348116965896],
              [-1.387901705227452, 50.777418828994954],
              [-1.388407868669214, 50.777758708509722],
              [-1.390242378616076, 50.777711654721635],
              [-1.390760976078199, 50.778078566989215],
              [-1.390704313341122, 50.77850991988668],
              [-1.384698091853602, 50.78020230267024],
              [-1.384111408296383, 50.780053526383],
              [-1.384280319608653, 50.780260350120706],
              [-1.383749661287846, 50.780381648040887],
              [-1.379853784092069, 50.780773773852751],
              [-1.379653223831366, 50.780601846914294],
              [-1.380477239794788, 50.78007115978285],
              [-1.378827554032519, 50.780934680408222],
              [-1.377303970619592, 50.781163068043625],
              [-1.377647579563906, 50.780922997886918],
              [-1.378355680193416, 50.780471742355331],
              [-1.379884540810711, 50.780271246362744],
            ],
          ],
          [
            [
              [-1.480363273642456, 50.929111774743902],
              [-1.48073015336036, 50.928749212791637],
              [-1.478254789237462, 50.928831706076089],
              [-1.475883242492624, 50.928031556572058],
              [-1.477290423187787, 50.926766331697387],
              [-1.477238387405995, 50.925822793228853],
              [-1.478270746571097, 50.92600455875381],
              [-1.478718349256475, 50.925802428611824],
              [-1.478949251779115, 50.927295302205025],
              [-1.479484591147039, 50.92751980136984],
              [-1.48079824397591, 50.927233392729654],
              [-1.482030646496239, 50.927984334904657],
              [-1.48101725618589, 50.928266696099172],
              [-1.481049506802553, 50.928565387802038],
              [-1.482603864979218, 50.928772812530795],
              [-1.481392075197105, 50.929109151971652],
              [-1.480860368088371, 50.929578892769712],
              [-1.481399518024546, 50.92971977043063],
              [-1.482249267682126, 50.931107472526229],
              [-1.481632107638087, 50.931198257178522],
              [-1.4800696364951, 50.930690436794144],
              [-1.48052117350134, 50.930393896943109],
              [-1.480363273642456, 50.929111774743902],
            ],
          ],
          [
            [
              [-1.81986408939443, 50.990264266062184],
              [-1.815430243007813, 50.98592937858826],
              [-1.807592198656371, 50.992052172653764],
              [-1.805678950835691, 50.991637164975252],
              [-1.800322627865985, 50.991401540547379],
              [-1.796022064266075, 50.990207142429561],
              [-1.790960336887728, 50.990092997460472],
              [-1.787954342706613, 50.988152451433152],
              [-1.78384269593243, 50.986905791080098],
              [-1.775619272224697, 50.985168285086957],
              [-1.775799031593313, 50.984818830316598],
              [-1.775363580447924, 50.984719079804002],
              [-1.769994307653599, 50.983835494481923],
              [-1.769849220033612, 50.983502494819199],
              [-1.765646677495165, 50.982945620642916],
              [-1.754328879283934, 50.980454912177805],
              [-1.754410720479291, 50.977899476332162],
              [-1.747110387727394, 50.977948661994411],
              [-1.743943167047601, 50.977829366344267],
              [-1.74394792274031, 50.977482274362089],
              [-1.742954264609427, 50.977121302049603],
              [-1.73981343372719, 50.976624276109064],
              [-1.735266619912316, 50.976416240608572],
              [-1.733723875159664, 50.976445110050115],
              [-1.729897242652685, 50.977321175369802],
              [-1.726196511394934, 50.977351235465768],
              [-1.72402950063351, 50.976957674149133],
              [-1.719626353809851, 50.976788035725995],
              [-1.719705868030923, 50.97611919956779],
              [-1.718723732451501, 50.976008933590286],
              [-1.716514291101788, 50.974181758219999],
              [-1.709643338697963, 50.971237903094909],
              [-1.701035900613589, 50.962732862398568],
              [-1.697660365424968, 50.960440131893634],
              [-1.689308108336714, 50.954645157407711],
              [-1.685720589008866, 50.952927023648627],
              [-1.676890918673925, 50.949674700546751],
              [-1.669191160142282, 50.947240516398068],
              [-1.667309360978774, 50.947037343755724],
              [-1.661669498861377, 50.945285630968264],
              [-1.651859479980584, 50.947177589735553],
              [-1.649657306773473, 50.948513567856061],
              [-1.646385929287301, 50.949473091912857],
              [-1.641724764806385, 50.952503706485317],
              [-1.641107483689443, 50.953169044842433],
              [-1.640387999832552, 50.955454485787563],
              [-1.634975205116732, 50.959249560533841],
              [-1.628500808339324, 50.957132148747924],
              [-1.623409558652419, 50.954644759688527],
              [-1.619761327029681, 50.958574292912971],
              [-1.61279279455582, 50.958105395336254],
              [-1.607756140585587, 50.954969156006577],
              [-1.596506373433368, 50.956079183379863],
              [-1.595588232335327, 50.953761378499244],
              [-1.590912188877095, 50.953355735814377],
              [-1.5900934035056, 50.951271136114762],
              [-1.588493131703185, 50.95354687376944],
              [-1.586896218354447, 50.954783084531975],
              [-1.586348818615332, 50.957429396370664],
              [-1.58523403996446, 50.957744674067897],
              [-1.58424405787238, 50.959072024463325],
              [-1.5840858248436, 50.962125262397961],
              [-1.575692608036205, 50.960783110379502],
              [-1.56169589139902, 50.965923640179234],
              [-1.561156620848362, 50.964969327343169],
              [-1.556856666314833, 50.965508830154413],
              [-1.552700427772053, 50.967533360955315],
              [-1.54762352141893, 50.967828553258933],
              [-1.545236936731622, 50.96912048383291],
              [-1.54440998175007, 50.969217977573514],
              [-1.544222585108073, 50.968575193628148],
              [-1.543873119325764, 50.969068409247804],
              [-1.543423978946868, 50.968251049976388],
              [-1.542113109030042, 50.968175781522923],
              [-1.542360905669402, 50.967740623281053],
              [-1.541490435038639, 50.967486325219333],
              [-1.54148381249963, 50.966567281357264],
              [-1.540932437938397, 50.966593892484546],
              [-1.541144144204796, 50.965919398463136],
              [-1.540746460646949, 50.965523971575934],
              [-1.541423398431502, 50.965326100190147],
              [-1.541572880202775, 50.964461622493424],
              [-1.540617169006687, 50.96390484028953],
              [-1.540534313841354, 50.963209405722729],
              [-1.539670565866007, 50.962860700486416],
              [-1.540059360599619, 50.962566382736334],
              [-1.539521629501483, 50.961504965853692],
              [-1.537972433256499, 50.960354124599405],
              [-1.537367814718758, 50.960157497834615],
              [-1.536952140375139, 50.960434614766044],
              [-1.535679130301254, 50.960012318625679],
              [-1.535341013831122, 50.959364424120871],
              [-1.534280338023743, 50.959070648155816],
              [-1.533607342890173, 50.95802664879723],
              [-1.534017198306673, 50.957191993898185],
              [-1.533217036036052, 50.956623180932041],
              [-1.533985015974154, 50.956280939001786],
              [-1.533656133006164, 50.955711309546302],
              [-1.534448694207535, 50.954616501462723],
              [-1.533573430883179, 50.953304621686634],
              [-1.532921771868583, 50.952840710342251],
              [-1.53229800476106, 50.95300367524203],
              [-1.531937978344384, 50.952137167636501],
              [-1.530886313395136, 50.951956700225438],
              [-1.531331783496511, 50.951543939190572],
              [-1.531135217581636, 50.95127158101613],
              [-1.530455988588067, 50.951147456828522],
              [-1.530075780240303, 50.951582058702407],
              [-1.529111431245651, 50.95036960166707],
              [-1.52710230365233, 50.950696904264099],
              [-1.526912992437554, 50.950133215254709],
              [-1.525947716588, 50.950843298230822],
              [-1.525250395979536, 50.95040074020249],
              [-1.523972065735038, 50.950536720250852],
              [-1.523714207250872, 50.950834216498912],
              [-1.52432319445686, 50.951143336988046],
              [-1.522736344735485, 50.951376963440637],
              [-1.522704305409494, 50.952003601174759],
              [-1.522194579079491, 50.952010510562317],
              [-1.521897403877066, 50.951558777106655],
              [-1.52155022863914, 50.952234482111471],
              [-1.519397736204187, 50.952093461669186],
              [-1.519027198293153, 50.951717854345702],
              [-1.517946091998296, 50.951774547894303],
              [-1.517051333207448, 50.951432740152988],
              [-1.516164836644764, 50.951530687015058],
              [-1.515122981581711, 50.950414011498246],
              [-1.512741657977498, 50.950929261473298],
              [-1.510808047600678, 50.950015650261072],
              [-1.509978486277826, 50.950115586975954],
              [-1.5097017822675, 50.949760126489657],
              [-1.510403198391872, 50.948306301245296],
              [-1.509672061281675, 50.947577550207406],
              [-1.508946037603099, 50.947446809656739],
              [-1.508834148580532, 50.946582171056036],
              [-1.508241319654264, 50.947050878841644],
              [-1.50771208922138, 50.946752800947294],
              [-1.508294258848261, 50.945810152104301],
              [-1.507356796880226, 50.94440788696366],
              [-1.507866080969437, 50.943760782305198],
              [-1.507349940112064, 50.943170504906604],
              [-1.507898350756539, 50.9427258938018],
              [-1.507667605475251, 50.941258262237277],
              [-1.507645955915948, 50.940612516957131],
              [-1.506210790920345, 50.940391542637336],
              [-1.506511615229717, 50.93980471067227],
              [-1.505224001714462, 50.93882538868251],
              [-1.504350273111619, 50.938411632227471],
              [-1.504143818827023, 50.937616727206724],
              [-1.50312827012543, 50.936892121625355],
              [-1.503085817831779, 50.936201324435828],
              [-1.501151574131575, 50.93608697211355],
              [-1.501358474694207, 50.935636436483264],
              [-1.50027591564976, 50.934532939356231],
              [-1.498438923486968, 50.934645566556256],
              [-1.49735544675999, 50.935088732518004],
              [-1.493489282788544, 50.934920064986905],
              [-1.492772841885409, 50.934715527032758],
              [-1.492484057210721, 50.933921142386325],
              [-1.490586022049978, 50.933499231607861],
              [-1.489687081987627, 50.934500660521692],
              [-1.488486321502549, 50.934860501837449],
              [-1.488774971763728, 50.933713434146192],
              [-1.487485183146419, 50.932981196635517],
              [-1.486757034683863, 50.93319921374767],
              [-1.486593570392561, 50.933182309236315],
              [-1.486055648934124, 50.933054050507792],
              [-1.48511052530902, 50.932687495016623],
              [-1.485854730092233, 50.932174607571547],
              [-1.486690907635391, 50.931448999189634],
              [-1.486127043078114, 50.931094017824115],
              [-1.48586374975205, 50.929935535249626],
              [-1.484983372992285, 50.930154669866042],
              [-1.484467021714669, 50.929365555888069],
              [-1.483947777317411, 50.92935427117412],
              [-1.483019479441482, 50.928376287997388],
              [-1.482243624522807, 50.928538312936453],
              [-1.481985741393245, 50.92831235926645],
              [-1.482225396806851, 50.927872792845321],
              [-1.481076308777457, 50.927178875065863],
              [-1.479529581909589, 50.927313176024583],
              [-1.479051003525602, 50.926720240691395],
              [-1.479435489315987, 50.926309202069532],
              [-1.480804382296696, 50.926425900181691],
              [-1.481088431425099, 50.925832762722102],
              [-1.482013278799208, 50.925575187139721],
              [-1.482739448612397, 50.924885088251735],
              [-1.482941844715612, 50.926136830770716],
              [-1.48570016324802, 50.926186773633241],
              [-1.486844244566683, 50.928375164626324],
              [-1.486651364568669, 50.92896422014293],
              [-1.487119024534725, 50.929914075040756],
              [-1.487053044264988, 50.928537945098014],
              [-1.487310319790842, 50.928044491153045],
              [-1.48774690425429, 50.928585052172373],
              [-1.487733955022635, 50.928079621316201],
              [-1.485876900455149, 50.925900693124106],
              [-1.483106628986212, 50.925903756843475],
              [-1.483487182708346, 50.925329924560394],
              [-1.483121197871401, 50.924716820888769],
              [-1.482454897312388, 50.924756135603779],
              [-1.481500417613711, 50.925505468993308],
              [-1.479582824472935, 50.924197537003764],
              [-1.47880472100222, 50.924438662490452],
              [-1.478615381493997, 50.924066430303469],
              [-1.478040044066811, 50.923987425645173],
              [-1.478585611324606, 50.921509748872005],
              [-1.477395763345428, 50.920917226401826],
              [-1.476813156595541, 50.921236547979504],
              [-1.476854416131743, 50.920728660119423],
              [-1.478018261489298, 50.919956020181083],
              [-1.476981573570428, 50.920172597626426],
              [-1.476939265440565, 50.919885549462592],
              [-1.479017213276419, 50.919168245478261],
              [-1.478564210315213, 50.919222876812128],
              [-1.477600030591506, 50.917665575070643],
              [-1.476142997508735, 50.916933360600559],
              [-1.476707910701008, 50.916665219875057],
              [-1.476063622277795, 50.916654238926007],
              [-1.476303313207688, 50.916337880420997],
              [-1.475697507650137, 50.917080636650084],
              [-1.474830190342428, 50.916282708225204],
              [-1.474315796951039, 50.916232734728901],
              [-1.474539196481548, 50.915595276228245],
              [-1.473411203500816, 50.91559019714763],
              [-1.474639216713992, 50.915429365838961],
              [-1.473472626357237, 50.914810828958068],
              [-1.47497676541868, 50.91286264050423],
              [-1.474077920103815, 50.911714757748342],
              [-1.474938473980725, 50.911714132572691],
              [-1.475523020317534, 50.912858799530305],
              [-1.476293037673455, 50.912181525667002],
              [-1.477221200463928, 50.912368229667415],
              [-1.475698483497405, 50.912940519194073],
              [-1.476100253168374, 50.913263352079611],
              [-1.475953371481231, 50.914056726376863],
              [-1.476501155655103, 50.914425174943069],
              [-1.478005816948227, 50.914199003536346],
              [-1.477898334280235, 50.915160714861109],
              [-1.477370221952921, 50.915193423975509],
              [-1.477675546113474, 50.915747824812819],
              [-1.47819998484215, 50.915535249029674],
              [-1.478552761739003, 50.915790411108595],
              [-1.478159891178872, 50.916195115280303],
              [-1.479047489488264, 50.91645536132441],
              [-1.479305659438259, 50.916265872518061],
              [-1.478531564676987, 50.915141062049109],
              [-1.478647213302992, 50.91472073181091],
              [-1.479462848211138, 50.915564263228752],
              [-1.478417721992827, 50.912598389258427],
              [-1.479752215860896, 50.912191586520692],
              [-1.479957543220214, 50.912402024788243],
              [-1.481416785366377, 50.911775443319875],
              [-1.482333877826823, 50.910909041760178],
              [-1.482893878009052, 50.910637508525731],
              [-1.48405603681158, 50.910073980722515],
              [-1.48498941498615, 50.909621370086434],
              [-1.487345755071621, 50.910310664470231],
              [-1.489606164963426, 50.910543574925441],
              [-1.491171894120368, 50.912005383034817],
              [-1.490826095131354, 50.913193576796729],
              [-1.491341837356251, 50.911943175995589],
              [-1.489879617406847, 50.910510598028907],
              [-1.488946178943041, 50.910154913501302],
              [-1.487521929081536, 50.910199031846609],
              [-1.484949484684633, 50.909373002390645],
              [-1.483784704130098, 50.910089529579288],
              [-1.481694468468582, 50.910209291670419],
              [-1.479714285780718, 50.91163838198765],
              [-1.47824962627452, 50.911984353615395],
              [-1.47377906427395, 50.909330411920564],
              [-1.471149010584391, 50.906960712540908],
              [-1.467014156331667, 50.905872716152253],
              [-1.466521005684222, 50.9050944154775],
              [-1.465923640852424, 50.905347071766087],
              [-1.465639777486893, 50.905048123040316],
              [-1.465833261713904, 50.904793622004185],
              [-1.466329108402544, 50.904961349720139],
              [-1.466365998728399, 50.904595524973978],
              [-1.465681883656986, 50.904475495687436],
              [-1.465926485424391, 50.904103426995071],
              [-1.465631714750139, 50.904261245536425],
              [-1.46538567958598, 50.904012826973428],
              [-1.46536128705813, 50.904777074833859],
              [-1.464547247622152, 50.904205022440465],
              [-1.465181754339358, 50.903937256023696],
              [-1.463931333071917, 50.903967494175717],
              [-1.464500879059508, 50.903535770428796],
              [-1.464025817493203, 50.903417588812353],
              [-1.463961686143281, 50.903677176815236],
              [-1.463805242690339, 50.903430065221279],
              [-1.46350896807304, 50.903842358318869],
              [-1.463096508174196, 50.90372176268864],
              [-1.463237921640042, 50.903420265708746],
              [-1.462113803870522, 50.903721737945375],
              [-1.462088517916935, 50.903324154464173],
              [-1.461549506830492, 50.903449364119027],
              [-1.460355817955391, 50.903986101366492],
              [-1.459868902318716, 50.903663719062642],
              [-1.460063992242938, 50.903272549399887],
              [-1.461806530401957, 50.903112431833776],
              [-1.461513863669044, 50.90284040974381],
              [-1.460106678784744, 50.903024554580874],
              [-1.461307270516547, 50.902627234926165],
              [-1.46016506970457, 50.902771236833701],
              [-1.461009824380773, 50.902276955017349],
              [-1.460861424726326, 50.901950742770019],
              [-1.459157343538587, 50.903214400241431],
              [-1.458668636560195, 50.902925276704849],
              [-1.458231435076429, 50.90310040140568],
              [-1.457573868621388, 50.902902213158661],
              [-1.457340116595406, 50.902580096290308],
              [-1.45807929119588, 50.902485510001142],
              [-1.458913379131991, 50.901808646665323],
              [-1.458685295144746, 50.901610654792648],
              [-1.459029144694713, 50.901513330571362],
              [-1.458451197783015, 50.901318213635385],
              [-1.458146913079485, 50.902172885745657],
              [-1.457491829632238, 50.902129378997877],
              [-1.45796230792005, 50.902027250481304],
              [-1.457893492431858, 50.901339906337739],
              [-1.457170432166335, 50.902251981950982],
              [-1.456261251141761, 50.902535512368452],
              [-1.455531572553373, 50.902059109518909],
              [-1.453891612197134, 50.901961529110075],
              [-1.454002541739735, 50.901596054063326],
              [-1.453102371498846, 50.901959635044022],
              [-1.453221397131596, 50.901631967020514],
              [-1.45220738832389, 50.901758506148902],
              [-1.451942783043509, 50.901524359885087],
              [-1.452717135486552, 50.900611657478031],
              [-1.452334742626331, 50.900357176669303],
              [-1.449541032043618, 50.901632666180774],
              [-1.44709742335514, 50.901551882994816],
              [-1.443234234933696, 50.90197776113515],
              [-1.442488830889203, 50.901758386350323],
              [-1.442325341695058, 50.901992309527401],
              [-1.4424912197481, 50.900962563596536],
              [-1.441343412743636, 50.901692664147319],
              [-1.429493809668763, 50.897680495181433],
              [-1.429540803968429, 50.89709261609287],
              [-1.429038148621191, 50.896801501197054],
              [-1.427344764579888, 50.896789614118561],
              [-1.426484075340626, 50.89647604952782],
              [-1.425023831021168, 50.894505809535204],
              [-1.426110839053638, 50.89433310424149],
              [-1.426868610186696, 50.894687532257429],
              [-1.427031990939636, 50.894380755562999],
              [-1.427837758382921, 50.894153633578753],
              [-1.428670644681181, 50.894645103697407],
              [-1.431154798017028, 50.894400043388494],
              [-1.428542746805435, 50.894523079378963],
              [-1.428002594463316, 50.894045631790803],
              [-1.426980586744202, 50.89431498498633],
              [-1.426712018029959, 50.894470943956648],
              [-1.425450356787884, 50.893930590423167],
              [-1.424161964349029, 50.894523145547616],
              [-1.42375673859178, 50.894176734529239],
              [-1.421857886105494, 50.895035126398007],
              [-1.421677721430743, 50.894886757582604],
              [-1.423616469419342, 50.894021371517972],
              [-1.420080485688223, 50.890914945616089],
              [-1.407274562683231, 50.879828536152317],
              [-1.399693615248568, 50.87588256109369],
              [-1.399722083630708, 50.875657894197033],
              [-1.397510516911268, 50.874777826752776],
              [-1.400777237529744, 50.871955692222379],
              [-1.399590911419928, 50.87137407645568],
              [-1.39878701080021, 50.871447276217978],
              [-1.399381388454374, 50.870868517693772],
              [-1.399021549911185, 50.870669729797761],
              [-1.398557173414215, 50.870974883766472],
              [-1.395699169119588, 50.869110363514118],
              [-1.394969134180031, 50.869078706448967],
              [-1.394574897126181, 50.868685487592003],
              [-1.393898907942061, 50.869308761049908],
              [-1.392849204183123, 50.869599162578467],
              [-1.391860104055015, 50.867195704392557],
              [-1.391407269506187, 50.866940654654591],
              [-1.391685773591343, 50.866723586973002],
              [-1.390982807696848, 50.866580538328535],
              [-1.389954136068778, 50.867113822314749],
              [-1.38943146365856, 50.866766676039781],
              [-1.388954174444336, 50.866426958920506],
              [-1.390465859349271, 50.865576071280486],
              [-1.389807952489057, 50.865792952623202],
              [-1.388110616285586, 50.865083551159529],
              [-1.38757877993504, 50.864570885453503],
              [-1.388573584167217, 50.864560811341896],
              [-1.388326040865864, 50.864366175058713],
              [-1.386657984651461, 50.864246816945546],
              [-1.38423355882101, 50.862973301218844],
              [-1.383880607456214, 50.862584759568215],
              [-1.383073793750522, 50.862565212300581],
              [-1.382205495012639, 50.861714421970056],
              [-1.382591965926606, 50.861822578263777],
              [-1.382493496514821, 50.861424586546143],
              [-1.381938087426656, 50.861090721873275],
              [-1.381514446383694, 50.861215273762774],
              [-1.380996269972647, 50.860538086041949],
              [-1.379169998658143, 50.860147105647449],
              [-1.379160288884279, 50.859272977095664],
              [-1.381577530397768, 50.859023221882907],
              [-1.378910425208402, 50.858937124984323],
              [-1.378787922634859, 50.859174776198792],
              [-1.378199112231967, 50.859006178950899],
              [-1.378300502602489, 50.859288186035208],
              [-1.376955187353984, 50.858634449987299],
              [-1.37757715806103, 50.858659349300332],
              [-1.377631185930774, 50.858442916516523],
              [-1.376133025348334, 50.857746990239463],
              [-1.374470848267916, 50.857632884283866],
              [-1.373381275267734, 50.856985872026868],
              [-1.37342067109321, 50.856378185875933],
              [-1.374649637016309, 50.856055646480144],
              [-1.373841349440321, 50.855518055299648],
              [-1.376793781865951, 50.853899901324972],
              [-1.374308992608534, 50.855075430043911],
              [-1.372378684515746, 50.855258410228885],
              [-1.371625363028457, 50.854862282370455],
              [-1.371214574564622, 50.854032754266491],
              [-1.369676040702417, 50.853820327179683],
              [-1.36850637018819, 50.853224993202609],
              [-1.367006697798941, 50.853188096222766],
              [-1.365979819418736, 50.852332726100151],
              [-1.364456671397906, 50.851725539371834],
              [-1.363637025552839, 50.851723772651425],
              [-1.363249160350425, 50.851205483956427],
              [-1.362747937308242, 50.851188361225532],
              [-1.362721378776908, 50.850635172586095],
              [-1.361082309427123, 50.850306078711249],
              [-1.361016903996444, 50.849166360563274],
              [-1.360618278570179, 50.85016685121883],
              [-1.359904643983292, 50.850309520049329],
              [-1.357520233744341, 50.849537449326384],
              [-1.35763318055801, 50.848760212230985],
              [-1.358662844511869, 50.848877380885696],
              [-1.358867041190681, 50.84838480946442],
              [-1.35759864505586, 50.848482151322479],
              [-1.35718936897436, 50.848805430266204],
              [-1.356297493048388, 50.848378764139738],
              [-1.356349236293827, 50.848747745435766],
              [-1.355096231226141, 50.848757916880565],
              [-1.352716768305082, 50.848153036979959],
              [-1.354699044445999, 50.848100162695147],
              [-1.354050247852653, 50.84797157795493],
              [-1.35523340735547, 50.846946668136447],
              [-1.354296469914996, 50.846495457625373],
              [-1.354419931415535, 50.845988958288856],
              [-1.355600812830993, 50.84625986424377],
              [-1.35917485887452, 50.845511569184438],
              [-1.360223075468367, 50.844786220430898],
              [-1.360513062552746, 50.845080967608681],
              [-1.360377076596066, 50.84380687180856],
              [-1.361172864035142, 50.843569328195393],
              [-1.360522680057327, 50.842921899063299],
              [-1.359267906623902, 50.842968974969494],
              [-1.358299820493158, 50.843860219483631],
              [-1.358179740555546, 50.844330771243378],
              [-1.359053902116524, 50.844905703887676],
              [-1.358977761575828, 50.845175962830815],
              [-1.356264919555664, 50.845687102841389],
              [-1.356204581979217, 50.84542688426707],
              [-1.354276992420659, 50.845539437515356],
              [-1.356457697455657, 50.843340199801908],
              [-1.356096424867849, 50.843170945572659],
              [-1.354155470472192, 50.845287872029047],
              [-1.351708189409295, 50.846516185222569],
              [-1.353063228000595, 50.844669429202227],
              [-1.352642669141338, 50.843960280140635],
              [-1.352811968545554, 50.843221128206665],
              [-1.354085474368913, 50.841920657450579],
              [-1.35256094664692, 50.842886111098437],
              [-1.352423909713306, 50.844988718912326],
              [-1.351684989821346, 50.845525072806879],
              [-1.351429528129478, 50.845305133938155],
              [-1.350560513052105, 50.846310171230918],
              [-1.347992211443443, 50.845713136395425],
              [-1.346326111005105, 50.845711913199658],
              [-1.345484707498735, 50.845140665717594],
              [-1.344240520962805, 50.845136381716223],
              [-1.342150877017107, 50.844347665404328],
              [-1.341965488162013, 50.843491424532033],
              [-1.340550383192296, 50.843020320938187],
              [-1.340070394734347, 50.842612042415396],
              [-1.340485098891233, 50.842412052352273],
              [-1.339950351445219, 50.842462985866909],
              [-1.339156370429493, 50.841761567550854],
              [-1.341061857225551, 50.840789446649396],
              [-1.339523726850504, 50.841293333288313],
              [-1.338250433442006, 50.840843686650643],
              [-1.337525026785859, 50.840117466331186],
              [-1.338187774510161, 50.839750729927466],
              [-1.337385400212351, 50.838744402119353],
              [-1.335598451299724, 50.838469863258013],
              [-1.335482252763513, 50.838151762786026],
              [-1.336405721535231, 50.838226258790385],
              [-1.336215449968152, 50.837723389468735],
              [-1.335275881050342, 50.837983325354358],
              [-1.33457739398062, 50.83746766655981],
              [-1.336088551118647, 50.836757760738109],
              [-1.336589805397901, 50.836861327570134],
              [-1.339170706683648, 50.836219505100175],
              [-1.339590557995112, 50.835751567413588],
              [-1.338463200044466, 50.835336020537483],
              [-1.339389157279066, 50.835129937655516],
              [-1.33885689682964, 50.834809485220276],
              [-1.338829170291599, 50.834258980225734],
              [-1.339126894959425, 50.834494474024595],
              [-1.339567659623474, 50.834354885115843],
              [-1.339446695723133, 50.834675237024193],
              [-1.340346442368566, 50.834413244040121],
              [-1.339507568561786, 50.83408476672362],
              [-1.339385180706085, 50.833700988823807],
              [-1.339913248775187, 50.833613150523206],
              [-1.340404548678621, 50.832204086835368],
              [-1.339637810771129, 50.831697064730548],
              [-1.338220183545349, 50.829421997279375],
              [-1.338951484649846, 50.82821123750503],
              [-1.338785843304595, 50.827674338995742],
              [-1.339509048505172, 50.827034561304629],
              [-1.339085760820575, 50.826946735571632],
              [-1.339256676589117, 50.826506165217616],
              [-1.336879452645431, 50.827842478245508],
              [-1.335422442318633, 50.826859391713086],
              [-1.335510689493099, 50.826343717288623],
              [-1.333553771149098, 50.82637392731106],
              [-1.331653458652164, 50.825621170601103],
              [-1.331352555750418, 50.825813688190713],
              [-1.332108451623127, 50.826371062290086],
              [-1.331893116388584, 50.826735830009774],
              [-1.331487775690864, 50.826684949169909],
              [-1.331477886318573, 50.826979850741338],
              [-1.330020240175992, 50.827638746720929],
              [-1.328672801268108, 50.827441260924203],
              [-1.327629602443112, 50.827810249422981],
              [-1.326834601187085, 50.827407294103686],
              [-1.32681441096744, 50.827033983549498],
              [-1.326065688859806, 50.82756022844589],
              [-1.325399650640937, 50.826485359013411],
              [-1.326322927613249, 50.826457418338677],
              [-1.326849464176709, 50.825286917489009],
              [-1.328130660549789, 50.824851861259432],
              [-1.328475950382518, 50.824435690733338],
              [-1.330300551174997, 50.824022620069933],
              [-1.330048158910736, 50.82380175152003],
              [-1.329015041066331, 50.824167213262392],
              [-1.328399542561502, 50.823922671039917],
              [-1.327900846128323, 50.824443172003392],
              [-1.325891655330618, 50.825150996959771],
              [-1.325998628105894, 50.825906096521678],
              [-1.324021638643642, 50.826442315939801],
              [-1.324150897038031, 50.827225423287956],
              [-1.323469986515521, 50.827674707549882],
              [-1.322668152176717, 50.826274401309554],
              [-1.319809619040822, 50.825231720017939],
              [-1.319312546250787, 50.824460851382533],
              [-1.319715711012235, 50.822509101500316],
              [-1.320393291287663, 50.821895254131192],
              [-1.321908397464161, 50.822755668439981],
              [-1.321565067956204, 50.823033344511551],
              [-1.321711168018946, 50.823238326442727],
              [-1.322078762070948, 50.823049817986124],
              [-1.322417201061238, 50.824189351573899],
              [-1.322875774558369, 50.824091295064228],
              [-1.323341032780098, 50.824513049821284],
              [-1.32393163412283, 50.823628898839097],
              [-1.326225115928181, 50.824473079120978],
              [-1.327523447977559, 50.823931116092957],
              [-1.327509149983749, 50.823740389873528],
              [-1.326202118251221, 50.824097953530725],
              [-1.326191542114082, 50.823550241020179],
              [-1.325833729695939, 50.824046367000342],
              [-1.324030693025365, 50.823356096388324],
              [-1.323033769594886, 50.823670456836119],
              [-1.322729837839165, 50.823383627208848],
              [-1.323125989695651, 50.823183590727673],
              [-1.325223657515842, 50.822638192210505],
              [-1.326628742844933, 50.822962847024471],
              [-1.326814781499536, 50.822563747768527],
              [-1.325683922808244, 50.822618370399823],
              [-1.325538956433858, 50.822136426768971],
              [-1.324444616259165, 50.822711022828869],
              [-1.322961987973265, 50.822939838406455],
              [-1.322666511689875, 50.822559534176108],
              [-1.324117452898537, 50.821771196783565],
              [-1.322564455007937, 50.822352111247511],
              [-1.321003499812473, 50.821129035286354],
              [-1.322460731707877, 50.820591649623296],
              [-1.323550515859572, 50.821018825802575],
              [-1.323477248400812, 50.821373610455701],
              [-1.323929092038943, 50.821149613856441],
              [-1.322762302593521, 50.820451315983966],
              [-1.323631924288547, 50.819805291015818],
              [-1.323068782120797, 50.819480089911472],
              [-1.324041757386648, 50.819146705881153],
              [-1.323270765567791, 50.818764547459061],
              [-1.325547710030226, 50.817782240583959],
              [-1.324930867197076, 50.817342531434768],
              [-1.325984683811091, 50.816516801726095],
              [-1.324701453974638, 50.817107395613824],
              [-1.324096159416364, 50.818032832369305],
              [-1.322957187729121, 50.818459676911935],
              [-1.322933685844059, 50.818121417156362],
              [-1.321741126136071, 50.818130680269782],
              [-1.321323788083012, 50.817835993468321],
              [-1.320811971640384, 50.817987689947437],
              [-1.320322068140573, 50.817215966997352],
              [-1.3191365341085, 50.817036398753928],
              [-1.318281890683562, 50.817140221371716],
              [-1.318157018134084, 50.817722215550724],
              [-1.317370247674165, 50.817940634589881],
              [-1.317371384505316, 50.817180762507718],
              [-1.31457717119819, 50.815377547923127],
              [-1.314019392380554, 50.816341880432738],
              [-1.314697441074681, 50.815889036740231],
              [-1.3154426080898, 50.815990530077471],
              [-1.316210851354138, 50.817234220324757],
              [-1.316795665964677, 50.817430986789397],
              [-1.315159647181131, 50.817868333943949],
              [-1.314729382612184, 50.818460222703706],
              [-1.316134871253887, 50.817868656150644],
              [-1.318192811290198, 50.818676544417443],
              [-1.317967664054803, 50.820685085055509],
              [-1.316882078955979, 50.820655356005972],
              [-1.315528643661584, 50.821177094060047],
              [-1.314775697500278, 50.820631319261764],
              [-1.313985811674669, 50.819119512258887],
              [-1.313244368756215, 50.819051304467614],
              [-1.312437290340274, 50.81794225595624],
              [-1.312938742266363, 50.817434426242912],
              [-1.311515141676495, 50.817228199521431],
              [-1.310890910634407, 50.817589617427359],
              [-1.310241414166999, 50.817063308088315],
              [-1.31070461946704, 50.81645454414987],
              [-1.311344195311144, 50.81630364579155],
              [-1.31195320413653, 50.816977189869782],
              [-1.312196745429423, 50.816052382154687],
              [-1.312484025098443, 50.816885894894035],
              [-1.312785319340921, 50.816861590506157],
              [-1.312921146419843, 50.816117798623232],
              [-1.312471962503453, 50.815773432860709],
              [-1.313383657690881, 50.815650204591918],
              [-1.313170077235939, 50.815791931165762],
              [-1.313767078796549, 50.816123674402377],
              [-1.313992424899452, 50.815857018039736],
              [-1.313347127546209, 50.815527689625249],
              [-1.313559224685108, 50.81529332967979],
              [-1.312630180441766, 50.815341818127337],
              [-1.313407734215598, 50.814685434570094],
              [-1.313120081609049, 50.814167563785425],
              [-1.311516419713249, 50.813471076516961],
              [-1.309827338945933, 50.814529428517169],
              [-1.308320530546455, 50.81698539532475],
              [-1.308816640243113, 50.817900188987018],
              [-1.310367258896183, 50.819121569902407],
              [-1.308409806702463, 50.820360865644439],
              [-1.306923169609453, 50.819996838286428],
              [-1.307565234958247, 50.816224634969622],
              [-1.308593282410129, 50.814855752421359],
              [-1.31216437423161, 50.812166465197159],
              [-1.321374033480016, 50.807508222849151],
              [-1.327805860544357, 50.802966379000416],
              [-1.333185428599183, 50.796598055118437],
              [-1.336801121871385, 50.793565644629339],
              [-1.340410959831424, 50.787688702731096],
              [-1.342021977038599, 50.786008970773594],
              [-1.343082121459453, 50.785570704632214],
              [-1.346223036961061, 50.785046983537534],
              [-1.350148349549189, 50.785033836866369],
              [-1.352554080515343, 50.784015747747951],
              [-1.354269749760063, 50.78386338546008],
              [-1.357217169065912, 50.784399434566737],
              [-1.359494525943324, 50.784354403043658],
              [-1.366619544871696, 50.78521342397903],
              [-1.376023809891222, 50.785163368023696],
              [-1.377551002186586, 50.785421520033616],
              [-1.378219908146301, 50.785149910004947],
              [-1.378651906975304, 50.785520910574931],
              [-1.380553812610886, 50.785442893133265],
              [-1.38191961639334, 50.785032874704768],
              [-1.3883510917, 50.785920164830948],
              [-1.388810431055478, 50.785615022022355],
              [-1.388420248107867, 50.785185826000379],
              [-1.388954091384615, 50.785149953918186],
              [-1.390029923733693, 50.785869600064572],
              [-1.392707350075172, 50.785715400561529],
              [-1.395786618765511, 50.78486898671391],
              [-1.395719172095482, 50.784598857384132],
              [-1.396038888422973, 50.784776768772943],
              [-1.3977055162023, 50.784126219345822],
              [-1.395826235524917, 50.783996003482514],
              [-1.395661857038826, 50.784423203513562],
              [-1.39480930229376, 50.784644501495244],
              [-1.395095490602883, 50.78357226027245],
              [-1.396463425299705, 50.783313159675615],
              [-1.397326890390533, 50.782681840078034],
              [-1.399323044631395, 50.782321633581375],
              [-1.400738585670022, 50.782439520903765],
              [-1.402233157642942, 50.783257425682002],
              [-1.40352592833223, 50.783439397404187],
              [-1.404581206569685, 50.784116538638024],
              [-1.406307894258295, 50.784537199646309],
              [-1.407396167075427, 50.78530261114603],
              [-1.405523571102504, 50.784415403045145],
              [-1.404523193544405, 50.784328469611033],
              [-1.40345962966138, 50.783633300191347],
              [-1.401978022309701, 50.783464748504642],
              [-1.401109099650601, 50.782971990550926],
              [-1.400529809189325, 50.784346693400444],
              [-1.403963260561116, 50.784744669525722],
              [-1.407530845674879, 50.785870732122198],
              [-1.407741124558836, 50.785507596518677],
              [-1.408730857119231, 50.786666373902456],
              [-1.407609493936667, 50.787168772748963],
              [-1.408040905095712, 50.787493798355001],
              [-1.407560385208275, 50.787574991808604],
              [-1.407648399951862, 50.787794859522094],
              [-1.4072814662129, 50.787643718346217],
              [-1.407598590667292, 50.787919604697272],
              [-1.407055026963366, 50.787937527411138],
              [-1.406298506573506, 50.789200747259663],
              [-1.406910935282148, 50.78968406916826],
              [-1.406612992126912, 50.790133986160498],
              [-1.406390337301242, 50.789567215629852],
              [-1.406064048078465, 50.790350614691562],
              [-1.406422260896969, 50.790183375140458],
              [-1.406958310928142, 50.790761630377865],
              [-1.405810440399043, 50.790659570492871],
              [-1.405856753941743, 50.790923291150349],
              [-1.405496683096948, 50.790900774417189],
              [-1.405737701876995, 50.791361526689066],
              [-1.406099265685097, 50.790818412128466],
              [-1.406460242633456, 50.79088139856924],
              [-1.406460567490891, 50.791304055105826],
              [-1.407221204838589, 50.791625361731406],
              [-1.406668721964161, 50.791672013904524],
              [-1.406214777377806, 50.791331581345332],
              [-1.40628735830744, 50.791537882434291],
              [-1.405624601933296, 50.791665801809962],
              [-1.405067712452184, 50.791388688314285],
              [-1.405667229277057, 50.791884540443725],
              [-1.405965261805276, 50.791653147977307],
              [-1.407004429092295, 50.791826595258946],
              [-1.405961890384038, 50.792143230571341],
              [-1.406884288357955, 50.792127238728646],
              [-1.406687367571184, 50.793113631298525],
              [-1.407394698883454, 50.793052477866688],
              [-1.408344434030939, 50.793457469424453],
              [-1.407301633565385, 50.793568183590239],
              [-1.408691150863481, 50.794090510753769],
              [-1.408814518123885, 50.794546163880888],
              [-1.407813358665136, 50.794840543640134],
              [-1.408771401473272, 50.794702417598216],
              [-1.409013165065991, 50.795220719449034],
              [-1.409038715740698, 50.79465700964677],
              [-1.409451923012317, 50.795081755726784],
              [-1.409626378021594, 50.797903631930772],
              [-1.408621620236626, 50.798028039366422],
              [-1.40944364708347, 50.798104142745032],
              [-1.409637237746841, 50.798956725570314],
              [-1.409013492857793, 50.800709832066694],
              [-1.409879478693659, 50.800566734385924],
              [-1.409553293250094, 50.800889719086747],
              [-1.410025591334076, 50.800795886127972],
              [-1.411155936071232, 50.801522803992569],
              [-1.41201039291624, 50.802751908965469],
              [-1.412537041474518, 50.802851681332967],
              [-1.41321186219916, 50.802337999204461],
              [-1.414086165351746, 50.802666126313078],
              [-1.413259225580475, 50.80365565857629],
              [-1.413584499421326, 50.803858728280254],
              [-1.413117853538111, 50.803952604058161],
              [-1.413475111664205, 50.803981377753026],
              [-1.412970882370229, 50.804243226352369],
              [-1.413215374741159, 50.804437797382256],
              [-1.41280574245253, 50.804737889846557],
              [-1.412154105418753, 50.804644683448785],
              [-1.412148323582184, 50.804879362084201],
              [-1.412453580288264, 50.804755002201233],
              [-1.413499023438853, 50.805245862260541],
              [-1.413682607466305, 50.805658647169317],
              [-1.413676596785344, 50.805118159742804],
              [-1.413144626550471, 50.80487448370959],
              [-1.413496534592673, 50.8041973086398],
              [-1.413905199477305, 50.804427774323912],
              [-1.413750387041188, 50.803643738354339],
              [-1.416015011846443, 50.803324161956539],
              [-1.416953952147222, 50.802452074012606],
              [-1.418198889870159, 50.802076099158676],
              [-1.418144283904072, 50.801788062580322],
              [-1.41878620840183, 50.801517884716745],
              [-1.418531618775585, 50.800763932895158],
              [-1.418938396220461, 50.800802828498234],
              [-1.419589819631565, 50.80022244745264],
              [-1.420079719988657, 50.800309413392682],
              [-1.420703125510215, 50.801764820083292],
              [-1.418209754198751, 50.803258684889492],
              [-1.417255093487616, 50.804939142029831],
              [-1.417339340066271, 50.805468329368928],
              [-1.419148738393896, 50.807074438671599],
              [-1.421600462654753, 50.807589301315602],
              [-1.422258844537836, 50.80714023278049],
              [-1.422817995418014, 50.808178949605612],
              [-1.423737025173332, 50.807538719148504],
              [-1.424711658312745, 50.808031826377658],
              [-1.425674060048056, 50.8073243497535],
              [-1.426494066695617, 50.80758107438465],
              [-1.427079697515629, 50.808551556703989],
              [-1.426332278227236, 50.809899476995511],
              [-1.426993771130045, 50.80931101157929],
              [-1.429151562648933, 50.811135395741786],
              [-1.429656477154453, 50.811165741013511],
              [-1.429629551539906, 50.810929103567361],
              [-1.430047009794067, 50.811264768821118],
              [-1.430417370124391, 50.811271972178851],
              [-1.430395430725608, 50.810975108703943],
              [-1.431121321431783, 50.81115850099512],
              [-1.432197235456516, 50.810685332239885],
              [-1.432064922915248, 50.810360055001901],
              [-1.432620179672606, 50.810686489553042],
              [-1.432679358248814, 50.810253332951014],
              [-1.433204161481877, 50.810633572421423],
              [-1.435165920138354, 50.810141293542436],
              [-1.435597993099397, 50.810326832919877],
              [-1.434999190133007, 50.809961533515398],
              [-1.434074874076512, 50.810108131851528],
              [-1.433815164566802, 50.809871265794861],
              [-1.434196494491378, 50.809674377916593],
              [-1.435992826879798, 50.809951051373609],
              [-1.438368413989956, 50.811517329061104],
              [-1.438499754444027, 50.812164529946173],
              [-1.437422295362093, 50.813596361806397],
              [-1.438298915227844, 50.814946777265575],
              [-1.438163724704332, 50.81544881356843],
              [-1.442521274950003, 50.815705311519089],
              [-1.4425592134877, 50.815379960278712],
              [-1.444605774768216, 50.816244013056711],
              [-1.444614156265362, 50.818889677159284],
              [-1.445388861345719, 50.819401442337401],
              [-1.448663587974767, 50.82014715357662],
              [-1.451238889737355, 50.819166505007246],
              [-1.450650230947154, 50.818763565618532],
              [-1.44930092722798, 50.818945153507919],
              [-1.447097138681819, 50.818636182063138],
              [-1.446457640462511, 50.81773389113399],
              [-1.446821945479178, 50.815733864164351],
              [-1.445536075250142, 50.81463965804592],
              [-1.44561779609534, 50.813611291662276],
              [-1.44471328213377, 50.814598877293676],
              [-1.44266121434236, 50.814322916481323],
              [-1.440391543863065, 50.814441551421901],
              [-1.439452266633844, 50.814053060284138],
              [-1.44042805005579, 50.813761885030608],
              [-1.439301270802227, 50.813742089996246],
              [-1.439934839852736, 50.813224459276562],
              [-1.439343015533164, 50.813337622951792],
              [-1.439701556103947, 50.812435586847812],
              [-1.440447800578992, 50.812226940711746],
              [-1.439738554496932, 50.812308968576154],
              [-1.440225836177932, 50.811559523766398],
              [-1.439908769821417, 50.811605663708299],
              [-1.439263504406291, 50.810848984604263],
              [-1.439950804029707, 50.810345100626698],
              [-1.439230756598696, 50.810620414825763],
              [-1.438411636064927, 50.810397956258271],
              [-1.437621425446492, 50.809659454913593],
              [-1.435906460314806, 50.808872418937909],
              [-1.436114004152827, 50.808609038684388],
              [-1.435653866434593, 50.808751596123763],
              [-1.435337465252376, 50.808508164461252],
              [-1.433781833501324, 50.80851861397408],
              [-1.431302202300183, 50.809235860994988],
              [-1.430101333764361, 50.809248888620424],
              [-1.429321700854367, 50.808469915511452],
              [-1.429715824194307, 50.807805488335028],
              [-1.429192673683528, 50.807993574832814],
              [-1.42792340682523, 50.80628775473739],
              [-1.426444383718164, 50.805610545706351],
              [-1.424827685620501, 50.805285150520248],
              [-1.42203754189445, 50.805866680273724],
              [-1.420373331372234, 50.805712747305222],
              [-1.420467477550966, 50.805444335136009],
              [-1.421629721794262, 50.805678512767379],
              [-1.422542557356874, 50.805423145382044],
              [-1.420570711217104, 50.805244311987593],
              [-1.422415674024378, 50.803742694061562],
              [-1.421487456514604, 50.803522273959402],
              [-1.423349471925395, 50.802472155759261],
              [-1.423194321238732, 50.802279846025741],
              [-1.423813387367924, 50.802020318757343],
              [-1.423705139151103, 50.801475729907359],
              [-1.424266240962671, 50.801313034477815],
              [-1.423721027357985, 50.801222216022857],
              [-1.423626540108732, 50.800712766246583],
              [-1.423989208353449, 50.800646212555655],
              [-1.42299944622458, 50.800588267272147],
              [-1.422853852088707, 50.799966156162043],
              [-1.422379215198931, 50.800022260358105],
              [-1.421036905699948, 50.799327672811849],
              [-1.420270610334403, 50.799446169063025],
              [-1.420309785665089, 50.799146908724687],
              [-1.420086454231452, 50.799420974092101],
              [-1.419983419461918, 50.799147085851317],
              [-1.418976689895149, 50.799087223514135],
              [-1.416605989446056, 50.799168921373465],
              [-1.413673833075484, 50.800234244704491],
              [-1.412342912230799, 50.800112445436241],
              [-1.411500344790981, 50.796922106346614],
              [-1.413555655858366, 50.796636593561765],
              [-1.412803383643397, 50.796430476692009],
              [-1.411636075391209, 50.796621536989122],
              [-1.411697004582491, 50.794932125520134],
              [-1.413052772706339, 50.79464399266972],
              [-1.411662091613396, 50.794774578162453],
              [-1.411367845191876, 50.793800988981594],
              [-1.411658223748071, 50.793726015606154],
              [-1.411903845920321, 50.794617525942868],
              [-1.412500576299655, 50.794552187123998],
              [-1.411933074427566, 50.793644668413947],
              [-1.413131293268893, 50.793358438503567],
              [-1.410964318824117, 50.793282774881916],
              [-1.409697505043989, 50.790451781530543],
              [-1.411648583798033, 50.789971533849119],
              [-1.412031411755453, 50.788970781798177],
              [-1.413818165924213, 50.787329616774684],
              [-1.413092534935414, 50.787727042529788],
              [-1.412926655562597, 50.787492399046187],
              [-1.411724944719832, 50.787503433872516],
              [-1.412409542131128, 50.786532078186845],
              [-1.413452593276868, 50.786614661998946],
              [-1.412980796980695, 50.783960353258529],
              [-1.406643895466442, 50.781636974073606],
              [-1.4058606833036, 50.78087130989541],
              [-1.398928529123862, 50.777465355353172],
              [-1.398027355725484, 50.77629166153978],
              [-1.395118591177545, 50.777271211088845],
              [-1.395628760863969, 50.777414140229595],
              [-1.39528078288315, 50.777672226017813],
              [-1.394374003409561, 50.777269147741478],
              [-1.392987067846655, 50.777255646700979],
              [-1.39277015853035, 50.777467644783805],
              [-1.392394051369807, 50.77693961512955],
              [-1.393083485432851, 50.777038525269212],
              [-1.397216191902372, 50.776063556512852],
              [-1.40192000448288, 50.774665116681902],
              [-1.407006353725642, 50.772376357483786],
              [-1.407808547100312, 50.771066599722936],
              [-1.40708680881692, 50.771284154893877],
              [-1.406987710056252, 50.771046244377501],
              [-1.410141150557824, 50.770180943794905],
              [-1.410518411583361, 50.769386997257591],
              [-1.409164474386462, 50.770262332536724],
              [-1.40951358110565, 50.76967867560213],
              [-1.406878546976632, 50.770931482436886],
              [-1.406339682877436, 50.771602294543079],
              [-1.405894182436398, 50.771730421208964],
              [-1.406259493847602, 50.77087887447226],
              [-1.405291027565649, 50.772212953938087],
              [-1.404968217158935, 50.772059331919564],
              [-1.404742615254758, 50.772291991176026],
              [-1.40445480411854, 50.771839089785253],
              [-1.402755110816212, 50.772232376032179],
              [-1.402894261057493, 50.77266473710916],
              [-1.404188261800404, 50.772168660051072],
              [-1.404402192507635, 50.772520466927624],
              [-1.398545268923256, 50.775056039551764],
              [-1.395139513807734, 50.775868460971836],
              [-1.394095325940908, 50.775565386105313],
              [-1.393270649744067, 50.775726566669952],
              [-1.394820993664892, 50.774613229229971],
              [-1.397977159764139, 50.773350794641637],
              [-1.402351041644933, 50.772224012373229],
              [-1.415546041064981, 50.767292709988681],
              [-1.419207418769893, 50.766484562802148],
              [-1.426152193853185, 50.766295004123343],
              [-1.428835973840592, 50.765516814754186],
              [-1.438915990628504, 50.764468610489949],
              [-1.442171621544059, 50.763865522399648],
              [-1.443499666211818, 50.763073312816807],
              [-1.447132507211847, 50.762331598758237],
              [-1.456447631977101, 50.762226982285078],
              [-1.461406311945616, 50.761076429498509],
              [-1.462080885908938, 50.761845717936303],
              [-1.461698068425737, 50.761103854563508],
              [-1.46210959406601, 50.760825180446432],
              [-1.463776145268893, 50.760894900219782],
              [-1.463834817759075, 50.761095706714968],
              [-1.462318357856166, 50.761290165179297],
              [-1.463114128102425, 50.761257858474671],
              [-1.463422004118827, 50.76161178785776],
              [-1.462373038936339, 50.761838970926974],
              [-1.463139300986984, 50.761908145417976],
              [-1.462902642100823, 50.762764958416966],
              [-1.464953181061347, 50.76485348635331],
              [-1.465954831741421, 50.7643032263829],
              [-1.465303265933977, 50.763248098979858],
              [-1.465869227409319, 50.762979111931372],
              [-1.466149716527799, 50.762003790132106],
              [-1.465097612599051, 50.760889274801698],
              [-1.464178906684626, 50.760885959117353],
              [-1.464982225495229, 50.760315012446256],
              [-1.46678544899602, 50.760209059161845],
              [-1.467507568644057, 50.759793297501886],
              [-1.470742171850089, 50.758981585435045],
              [-1.473084807595884, 50.758810535015662],
              [-1.473262077747217, 50.758553245252287],
              [-1.47561242741512, 50.758577320205063],
              [-1.480366435503512, 50.757396265286744],
              [-1.483461725284188, 50.756068299229341],
              [-1.484933800722626, 50.75576096154618],
              [-1.496154989882619, 50.755193054537628],
              [-1.501541306825658, 50.754058828974635],
              [-1.509659212900672, 50.753992505714898],
              [-1.513795066944478, 50.753240037979367],
              [-1.514913499648611, 50.753929935186726],
              [-1.515626759688059, 50.753497652227189],
              [-1.519552204057351, 50.75417664108609],
              [-1.521060136693892, 50.755189120749876],
              [-1.526821152063005, 50.757185616500962],
              [-1.527149857799884, 50.758331716204893],
              [-1.527664912476597, 50.758572107878884],
              [-1.528349084785821, 50.758631528941557],
              [-1.528997628442297, 50.757013668729584],
              [-1.5296851022365, 50.758156712409004],
              [-1.530840133180834, 50.759049837671185],
              [-1.534321432624431, 50.759121334781348],
              [-1.535354364386608, 50.759469871319638],
              [-1.53455986591355, 50.759525157573741],
              [-1.533489619523932, 50.761505565775217],
              [-1.533522252435003, 50.762502083521895],
              [-1.534997452143997, 50.763870359351991],
              [-1.536787670377751, 50.763068139236573],
              [-1.537539441975463, 50.762462317478629],
              [-1.535961600229369, 50.762295085460053],
              [-1.535017178344882, 50.761592596245393],
              [-1.536250418036785, 50.760328638316999],
              [-1.536185444032826, 50.759873351126764],
              [-1.536479759889209, 50.760075956259868],
              [-1.537030745187107, 50.759842535556452],
              [-1.536540563957855, 50.759517753084182],
              [-1.536673562620434, 50.758970627685962],
              [-1.535294690898215, 50.758193573890814],
              [-1.533931657910441, 50.757967817664074],
              [-1.533782255453232, 50.757594025004707],
              [-1.533485828447221, 50.757749312848276],
              [-1.52856140028672, 50.754571294946061],
              [-1.528651391483128, 50.754079758976289],
              [-1.527578432678397, 50.753217518465704],
              [-1.53060452516121, 50.750566997265757],
              [-1.529785507850899, 50.749687812347396],
              [-1.531668846197344, 50.748741262416281],
              [-1.528902105950356, 50.749719320674664],
              [-1.527932732485984, 50.748573331894235],
              [-1.526694970995583, 50.749731971537784],
              [-1.525875356707545, 50.749482244577109],
              [-1.525355023663787, 50.748793087913597],
              [-1.527093439182964, 50.747182361606519],
              [-1.526568809365165, 50.74509213125971],
              [-1.525933836139626, 50.74453110693868],
              [-1.530660119402158, 50.742450419089288],
              [-1.532593429612025, 50.744284595707597],
              [-1.53366326831409, 50.743736724939403],
              [-1.534876346837599, 50.744894429907006],
              [-1.535381117855648, 50.744444108520121],
              [-1.537409518841828, 50.745141007708476],
              [-1.535583479911354, 50.7443325054656],
              [-1.536929802580232, 50.743051001869752],
              [-1.537045203162701, 50.742270893940592],
              [-1.535285138382249, 50.741825053869199],
              [-1.538194413170191, 50.739117212761585],
              [-1.541812828592787, 50.739800534461942],
              [-1.538169883691288, 50.738873413519407],
              [-1.534675317627138, 50.741007885218735],
              [-1.53333238153809, 50.741377517377309],
              [-1.531299728939671, 50.740276757266749],
              [-1.531927670147541, 50.739410583181581],
              [-1.531143091347188, 50.738505465203133],
              [-1.531133385647799, 50.737063895892732],
              [-1.533416098702249, 50.735088356501407],
              [-1.535485865558478, 50.73442396053899],
              [-1.540718925810292, 50.729925840554849],
              [-1.540006291313466, 50.729485987255217],
              [-1.540847467619592, 50.729821131742277],
              [-1.543200687559251, 50.728092976464225],
              [-1.544921386288717, 50.727291255585364],
              [-1.545722915109662, 50.727037187509687],
              [-1.546793964026655, 50.728364181189029],
              [-1.55025181342681, 50.728578099069615],
              [-1.552523949152972, 50.727734326253334],
              [-1.554725691721966, 50.723843508184252],
              [-1.554595413366787, 50.72338887858637],
              [-1.555607560519975, 50.722272245262786],
              [-1.560559813461974, 50.721095870517686],
              [-1.563194006708353, 50.721781115845346],
              [-1.564675059452871, 50.723085199950582],
              [-1.567536426145463, 50.722502321781676],
              [-1.566008769478046, 50.72225024878702],
              [-1.56618324764317, 50.721918167079714],
              [-1.564976471064109, 50.720978430814526],
              [-1.571106011123918, 50.718195395538594],
              [-1.576517083019635, 50.716469674254668],
              [-1.579551201307445, 50.717732435091875],
              [-1.56660334767244, 50.710153643023681],
              [-1.562535028905095, 50.708713109110676],
              [-1.561284972413474, 50.708661652539057],
              [-1.561007373141424, 50.708208273378858],
              [-1.560676089892636, 50.708196234985444],
              [-1.560489753351039, 50.708737795406094],
              [-1.559967334239961, 50.707962450273058],
              [-1.559280157275513, 50.707844749135354],
              [-1.559136510298155, 50.708212009521517],
              [-1.558661659566559, 50.707652664021474],
              [-1.559417964336498, 50.709159109182004],
              [-1.558779392422329, 50.709293385424481],
              [-1.557893046784689, 50.707460906082254],
              [-1.556886552373505, 50.707115363083695],
              [-1.556165567946983, 50.707280786569015],
              [-1.556682639513767, 50.707564225548786],
              [-1.556269971256015, 50.707622907996353],
              [-1.55646685050975, 50.707770238414192],
              [-1.555212852918794, 50.707240287726577],
              [-1.557206204512217, 50.708362071731109],
              [-1.556539271893925, 50.70880108201343],
              [-1.556433744612355, 50.70947873349656],
              [-1.554423251169381, 50.707173425995272],
              [-1.55346404246034, 50.706623898670429],
              [-1.551882048651224, 50.706785996747058],
              [-1.552759833770202, 50.708003385853779],
              [-1.553948791024516, 50.708670703157658],
              [-1.55369840743907, 50.709087908037645],
              [-1.554229080811908, 50.711821931315363],
              [-1.553601742052356, 50.712856395068329],
              [-1.555141101048224, 50.713778634855807],
              [-1.555226277497622, 50.714057734373938],
              [-1.55472409909036, 50.714139451176855],
              [-1.555319629955132, 50.714371037366185],
              [-1.554870279198356, 50.714407992480908],
              [-1.553423839721258, 50.713095820248476],
              [-1.552422170740555, 50.711043435993005],
              [-1.550901025226015, 50.709266919363444],
              [-1.550167263836919, 50.708689456025269],
              [-1.548720896917797, 50.708287289838971],
              [-1.547945048511475, 50.707525298263079],
              [-1.551010813908259, 50.706074018340857],
              [-1.553991109640382, 50.706000019857598],
              [-1.555322515435532, 50.706126504671033],
              [-1.564687142073066, 50.708774216674811],
              [-1.570612420618553, 50.711750289836928],
              [-1.576383809980937, 50.715382869131851],
              [-1.577060934572121, 50.715532806495347],
              [-1.577278401022507, 50.715291690298073],
              [-1.580633746984485, 50.717694964753505],
              [-1.583706117815144, 50.718978437721219],
              [-1.594271237660729, 50.721543508353051],
              [-1.630264319014885, 50.72934377948431],
              [-1.641059848458052, 50.732246153397043],
              [-1.642100196592093, 50.73183298731221],
              [-1.647082785020097, 50.733254618224407],
              [-1.649616387402324, 50.733057219605705],
              [-1.64983068963739, 50.732821355247687],
              [-1.653097503173714, 50.733683618371813],
              [-1.654676099825859, 50.733866355331763],
              [-1.654890294574777, 50.733642171898239],
              [-1.658199521701639, 50.734343448258251],
              [-1.661442427225886, 50.7346262785628],
              [-1.661756959440434, 50.734238706836592],
              [-1.661710858629845, 50.734540727254746],
              [-1.664291210414557, 50.734941158482407],
              [-1.666338690819465, 50.735157458916056],
              [-1.666683191755424, 50.734734888204507],
              [-1.666845446259497, 50.735033908416625],
              [-1.670376784109376, 50.735422527469431],
              [-1.670749571424051, 50.735205057722403],
              [-1.6808586915476, 50.736662974462547],
              [-1.689425403518045, 50.73744596589593],
              [-1.691284243847937, 50.737505752725276],
              [-1.691441107844202, 50.737140165859948],
              [-1.692554902434729, 50.737362526241206],
              [-1.691984486054763, 50.739422141866562],
              [-1.690827334732538, 50.740613308441773],
              [-1.689025337095657, 50.741582426774087],
              [-1.6885827969232, 50.741423875233693],
              [-1.687924788903472, 50.742127142560769],
              [-1.684652289056072, 50.743830549670854],
              [-1.682812236539469, 50.746620482221694],
              [-1.681681281714147, 50.750160509264404],
              [-1.681825696841801, 50.751859614704671],
              [-1.690620261026223, 50.751616174385603],
              [-1.703927643801882, 50.752571532902842],
              [-1.712711216381558, 50.752505352461512],
              [-1.712707047569042, 50.752259843038431],
              [-1.713600131395349, 50.752254843453265],
              [-1.714992069717084, 50.752036131865736],
              [-1.717976541014453, 50.752180068405664],
              [-1.728141914599875, 50.750924530871757],
              [-1.74417570502149, 50.747466226123635],
              [-1.74234025987627, 50.749259819397011],
              [-1.74358064226941, 50.750018831649093],
              [-1.743604138673789, 50.752726567265569],
              [-1.744360509548839, 50.753619396284385],
              [-1.744663236016139, 50.756084040695384],
              [-1.742023121830657, 50.756678944731163],
              [-1.741831184728524, 50.758326869744074],
              [-1.741420834762919, 50.758698256498775],
              [-1.741691046616131, 50.760349901142611],
              [-1.740125184789667, 50.76097501086776],
              [-1.739884059874095, 50.762784689245144],
              [-1.738966776087117, 50.76328173261394],
              [-1.739686887797679, 50.765358837262326],
              [-1.741247719453225, 50.767218383005257],
              [-1.744793205724615, 50.773128036968536],
              [-1.746394194388656, 50.773414792233595],
              [-1.748296593364789, 50.775151783363889],
              [-1.745696595649002, 50.776267527454095],
              [-1.749066031931616, 50.779521144915464],
              [-1.752083573288404, 50.778856742594449],
              [-1.756564924347131, 50.778430928918965],
              [-1.767582865231036, 50.772923827406579],
              [-1.768027651291564, 50.773315890042412],
              [-1.770124584909261, 50.772560161615402],
              [-1.769943430321277, 50.771326918732399],
              [-1.768888801560512, 50.769794294550572],
              [-1.7731691525543, 50.768201103553828],
              [-1.775606788050836, 50.767003500907009],
              [-1.776072100946982, 50.766364120093854],
              [-1.77905499855937, 50.767376974353013],
              [-1.779998095229403, 50.76734997849838],
              [-1.781284501679471, 50.766680646788274],
              [-1.782260622784657, 50.765032326375099],
              [-1.783432129281937, 50.764660412032264],
              [-1.783886379318427, 50.76485639327953],
              [-1.783773611095722, 50.765635844146239],
              [-1.784128161473141, 50.765930558511421],
              [-1.785194825896168, 50.765851591847223],
              [-1.785017399842493, 50.764961895906602],
              [-1.785503238499389, 50.764763153235364],
              [-1.785993323704791, 50.765498748889719],
              [-1.788284163635554, 50.76688059249846],
              [-1.788841355475421, 50.767843810526877],
              [-1.78724964730231, 50.770501834232888],
              [-1.787700089552722, 50.770615061484961],
              [-1.789564994722689, 50.769958379399064],
              [-1.789695348782514, 50.770299433948857],
              [-1.788508776893499, 50.771162379810143],
              [-1.790178711944469, 50.770980144251915],
              [-1.790507478116721, 50.771668668603731],
              [-1.789550497265807, 50.772244273202929],
              [-1.78888969935432, 50.773493052202639],
              [-1.788125464233732, 50.773770438893266],
              [-1.787873650302807, 50.774260078480239],
              [-1.788173224890345, 50.774500724599719],
              [-1.789964123011295, 50.774569604980059],
              [-1.79075662624645, 50.776223866844354],
              [-1.790506999077329, 50.77654445502688],
              [-1.788736564421067, 50.77695402614038],
              [-1.788537157301936, 50.778086732133971],
              [-1.78787553380486, 50.778866089412176],
              [-1.790987905652295, 50.78034288839995],
              [-1.79213072507345, 50.782676705821636],
              [-1.794352622912456, 50.782219319254516],
              [-1.79572351884197, 50.783368279443536],
              [-1.797530020195789, 50.783847132454525],
              [-1.797328477471863, 50.784531118483265],
              [-1.795843479844392, 50.785465559441882],
              [-1.795684076996472, 50.78591221198613],
              [-1.79622258475584, 50.786364580194856],
              [-1.797646299851963, 50.785830198229675],
              [-1.79859516927814, 50.785864211431516],
              [-1.80081139943427, 50.787431821053673],
              [-1.802622899910132, 50.790819701019849],
              [-1.801262472713191, 50.791127638202788],
              [-1.800972636984011, 50.791895110317519],
              [-1.804273351754199, 50.791381815928339],
              [-1.805974557024447, 50.792031219643718],
              [-1.805612108639971, 50.792535100237806],
              [-1.803520441624887, 50.791956076533054],
              [-1.803571511710292, 50.792974121261388],
              [-1.80087519802295, 50.794128698338021],
              [-1.801451967842979, 50.794626069728722],
              [-1.803963861417042, 50.794828148388675],
              [-1.804804461291255, 50.795694640692403],
              [-1.803912245699898, 50.795975513017858],
              [-1.803029227146318, 50.796090930849218],
              [-1.802163111118176, 50.796907790544687],
              [-1.80345011504725, 50.7975691177651],
              [-1.803498551109165, 50.798539496539028],
              [-1.801940216938774, 50.798955019000495],
              [-1.801380728116593, 50.799724731409185],
              [-1.802355859411397, 50.800328886583209],
              [-1.804041590293142, 50.799998102445471],
              [-1.80466540114909, 50.800469457866967],
              [-1.804802258719285, 50.8009993481812],
              [-1.804013695474228, 50.802599601973647],
              [-1.804289126713188, 50.803588345247199],
              [-1.806971513533342, 50.803516371223566],
              [-1.809742796715208, 50.804966919118392],
              [-1.810583369094305, 50.805911603232524],
              [-1.811671169130565, 50.806086018626246],
              [-1.81207516183286, 50.806557878575347],
              [-1.811911183555361, 50.807104361520338],
              [-1.810606919991413, 50.807108549387429],
              [-1.811813808309402, 50.80802863835121],
              [-1.8118393394855, 50.808739989680298],
              [-1.81054574144158, 50.808903362076833],
              [-1.809946101467913, 50.809780060364695],
              [-1.806768296865095, 50.810476266227404],
              [-1.805740169663049, 50.811637296777327],
              [-1.802880795705483, 50.813521839866915],
              [-1.803498806861312, 50.814062433074227],
              [-1.806431613902244, 50.815021436646866],
              [-1.807280823814038, 50.81630967189394],
              [-1.806600255072414, 50.817840874636609],
              [-1.805265113312795, 50.818371914053643],
              [-1.804033268073868, 50.819993905031531],
              [-1.80205454753253, 50.820620945676474],
              [-1.801763405075363, 50.82098554861787],
              [-1.80167573430762, 50.822246151749908],
              [-1.802144792458968, 50.822447480232114],
              [-1.80322303269137, 50.821939425003578],
              [-1.80404630467275, 50.821969584647249],
              [-1.804532993375041, 50.822370567110944],
              [-1.804332256263678, 50.82284593557123],
              [-1.80485246460671, 50.823723576378903],
              [-1.804587087254326, 50.824719504078573],
              [-1.803246024719126, 50.825255905494394],
              [-1.804631367589913, 50.827039647609894],
              [-1.803641999365268, 50.830033395429673],
              [-1.802934714247294, 50.830750706352227],
              [-1.801373327345161, 50.830962084424158],
              [-1.800570168602095, 50.831507460998026],
              [-1.798960425187542, 50.831397691658189],
              [-1.797983666030692, 50.83037265936526],
              [-1.797151661604444, 50.830030401764112],
              [-1.795667359947591, 50.830798473754513],
              [-1.794443752731187, 50.83103912535752],
              [-1.794124962211954, 50.831527756652044],
              [-1.795777870107361, 50.831831906347503],
              [-1.796662109828411, 50.83323088418944],
              [-1.795509050630046, 50.833887123432334],
              [-1.792073899677078, 50.833597797204135],
              [-1.791540529522461, 50.834112117475762],
              [-1.791634447947518, 50.835345157445936],
              [-1.790621834329003, 50.836323530202698],
              [-1.791398602082854, 50.836966084916845],
              [-1.792948140712656, 50.83659924910517],
              [-1.794412753731797, 50.836810461200784],
              [-1.794552137470793, 50.837733336938015],
              [-1.796795495124724, 50.838164403392071],
              [-1.798571941777419, 50.83886080070593],
              [-1.800012819154012, 50.839997229377403],
              [-1.800329286466066, 50.84105259022855],
              [-1.799272948547722, 50.841310663608326],
              [-1.798675687047778, 50.840826739203393],
              [-1.79736209384568, 50.840800188971421],
              [-1.797002890840148, 50.841101713355968],
              [-1.798084092320608, 50.841996541208282],
              [-1.799624153833481, 50.841547768936472],
              [-1.799855688344764, 50.842204617716114],
              [-1.801880038525369, 50.842429282581605],
              [-1.803365534761463, 50.844485676353052],
              [-1.803617830709881, 50.845288230527466],
              [-1.802752246733767, 50.84542705556791],
              [-1.800226169391184, 50.847249138543347],
              [-1.80041665518901, 50.847880736411057],
              [-1.799949705364939, 50.8484581546874],
              [-1.801278550957244, 50.849293125711846],
              [-1.802376910903863, 50.849552173182332],
              [-1.802890685603084, 50.850669906869115],
              [-1.804812018714725, 50.851163221645031],
              [-1.805504608505449, 50.851999777458587],
              [-1.805845329540104, 50.853079441096334],
              [-1.803918245564381, 50.85325967031531],
              [-1.803254103627346, 50.853769326720389],
              [-1.804762807066477, 50.854789804768572],
              [-1.804096193551098, 50.855547653927353],
              [-1.804009873277908, 50.856484524658057],
              [-1.804848189577828, 50.856800663886943],
              [-1.805441378821967, 50.857645146422371],
              [-1.80509326422327, 50.859018615378872],
              [-1.805572621027318, 50.859227139738678],
              [-1.807181422057262, 50.860147036309741],
              [-1.806419930022276, 50.861506337239916],
              [-1.808034660828551, 50.864468418853811],
              [-1.810965148590824, 50.864343703579344],
              [-1.811380441162938, 50.864602457983615],
              [-1.81279720959063, 50.864581355218064],
              [-1.813756826679243, 50.863389589052233],
              [-1.812931206613761, 50.862340649140471],
              [-1.814752460783836, 50.862060287224203],
              [-1.814839596178035, 50.858709833628218],
              [-1.821243255300044, 50.856781026385924],
              [-1.829525596163397, 50.855327626200797],
              [-1.84163891914466, 50.858051395088864],
              [-1.846027298629771, 50.858145382862347],
              [-1.850951167626201, 50.858733575651513],
              [-1.850571066073419, 50.860306771163913],
              [-1.852598299570969, 50.862198659728548],
              [-1.853530517422442, 50.863521722095491],
              [-1.851664121793186, 50.864202795870909],
              [-1.853546738090179, 50.866513531234745],
              [-1.847839593148749, 50.869694965995336],
              [-1.848487164674969, 50.873786468791536],
              [-1.845279724248893, 50.878006016040253],
              [-1.846129280491045, 50.879428840442117],
              [-1.84771792606007, 50.880885895065255],
              [-1.847984673931186, 50.882347511487787],
              [-1.847499308095871, 50.883416078870042],
              [-1.8444978914585, 50.885305031274292],
              [-1.844058936345836, 50.886917686935774],
              [-1.845106498816351, 50.888258949417988],
              [-1.848562958204548, 50.889895599737869],
              [-1.844896849470907, 50.89317662222647],
              [-1.841714588848749, 50.897028302261027],
              [-1.839877317585842, 50.897898064188567],
              [-1.837246258303638, 50.89720561399993],
              [-1.831481023287844, 50.897398877739796],
              [-1.82558309524928, 50.896175355125052],
              [-1.824960362393929, 50.895778758664193],
              [-1.823906024566653, 50.898233900664067],
              [-1.816549051985092, 50.903956143249765],
              [-1.817279936598754, 50.905436536487947],
              [-1.81757633851551, 50.907472876657984],
              [-1.820414389469244, 50.912113743923648],
              [-1.819166073917084, 50.912708015064119],
              [-1.818618396007296, 50.914553302441277],
              [-1.818851115916096, 50.916533784595472],
              [-1.817488374497232, 50.917616146033197],
              [-1.815041172851534, 50.920591473729068],
              [-1.814474366291341, 50.922889924310034],
              [-1.81478068464982, 50.92313949841472],
              [-1.81266764332222, 50.925340160970059],
              [-1.810558764901828, 50.926816007105579],
              [-1.811067218535683, 50.927044336395667],
              [-1.810665299960042, 50.927210944248898],
              [-1.811374965078893, 50.927294820793982],
              [-1.811445446599926, 50.927811095873885],
              [-1.81279119780525, 50.927527300965103],
              [-1.813222486852083, 50.92784272315653],
              [-1.813934207276575, 50.927057026300297],
              [-1.814290592816888, 50.927245533104767],
              [-1.815007651644219, 50.926901362999949],
              [-1.815365303233736, 50.927130334148089],
              [-1.817472398879158, 50.92716781281986],
              [-1.817858091858236, 50.926777246528268],
              [-1.818542134947831, 50.926865535791592],
              [-1.820755376002991, 50.926306926434862],
              [-1.822399065737437, 50.926595391137795],
              [-1.822655289775463, 50.926940187314159],
              [-1.822919632661614, 50.926645638481773],
              [-1.823255563588461, 50.926984259343925],
              [-1.823606389920018, 50.926770770860102],
              [-1.824150088752775, 50.927102508431858],
              [-1.824809856010158, 50.926839123241081],
              [-1.826307966393198, 50.926877327148915],
              [-1.828279569433984, 50.927775877395476],
              [-1.829748684791238, 50.92758019328091],
              [-1.832629849637864, 50.929206572655453],
              [-1.83445919785344, 50.929353053701696],
              [-1.835387569917242, 50.929578273532165],
              [-1.835397239074727, 50.93006477345191],
              [-1.838757072688398, 50.930810423031069],
              [-1.840327176822393, 50.931890759630839],
              [-1.840729492983993, 50.931588265914066],
              [-1.842021431052622, 50.931627785698147],
              [-1.842666844569423, 50.930967717798303],
              [-1.844144670256657, 50.930732298035402],
              [-1.844980305686507, 50.930195665773546],
              [-1.846057170279518, 50.930287910600889],
              [-1.846603095622065, 50.929569239570554],
              [-1.84715341801783, 50.929676069666598],
              [-1.851064859628518, 50.92884212824724],
              [-1.85596554792658, 50.92646529039812],
              [-1.855821474104996, 50.926123403367889],
              [-1.856487267685289, 50.925688092642481],
              [-1.855978342840168, 50.925545387985878],
              [-1.855755253546289, 50.924990284615404],
              [-1.857473869286485, 50.923159747927599],
              [-1.859100973067413, 50.922381181789625],
              [-1.859912274055004, 50.921312064785361],
              [-1.860948508491525, 50.921134352917001],
              [-1.861077996640923, 50.920647120564965],
              [-1.863495890095563, 50.919396430747149],
              [-1.864639596893106, 50.918931055669013],
              [-1.873652087764031, 50.917233483952181],
              [-1.875015130498915, 50.919804067180813],
              [-1.876125534331162, 50.920592979056153],
              [-1.877878771813786, 50.92311268321474],
              [-1.879237844620348, 50.924071779806326],
              [-1.878810340415446, 50.924333015526209],
              [-1.880389472568925, 50.924942523476332],
              [-1.881586649779775, 50.926384316304571],
              [-1.892561665206677, 50.931631149979779],
              [-1.896907048341812, 50.934402900394687],
              [-1.898574518410616, 50.935885395144325],
              [-1.899094222801872, 50.938399202411354],
              [-1.902607495730344, 50.940732989880999],
              [-1.905389477692311, 50.943090361455944],
              [-1.909191123693094, 50.945333358923065],
              [-1.910584301861278, 50.94922990407828],
              [-1.912450031852884, 50.951022582702763],
              [-1.912491949071234, 50.952205103322356],
              [-1.916113376396201, 50.953137557274758],
              [-1.91874861373075, 50.956372149259195],
              [-1.920993728609059, 50.961501994367374],
              [-1.923930658944511, 50.962729587372969],
              [-1.927191540495659, 50.963601213010364],
              [-1.928409743113969, 50.964994869504913],
              [-1.928692554009225, 50.966299823361098],
              [-1.929896468033615, 50.96780226167656],
              [-1.93487186247272, 50.969099120422243],
              [-1.939227547020898, 50.969663475609508],
              [-1.941265628726437, 50.971832552995977],
              [-1.94208692202102, 50.973369741894324],
              [-1.944837208320164, 50.975754919429804],
              [-1.948140860398727, 50.976481202545308],
              [-1.950805517790091, 50.978136027963515],
              [-1.952528591744689, 50.978589048173625],
              [-1.954736894452167, 50.978124119995385],
              [-1.955473171700844, 50.97827277375093],
              [-1.957232094276174, 50.983201189417215],
              [-1.95682852515187, 50.989836429352174],
              [-1.953933707576688, 50.988077344598466],
              [-1.949943552878833, 50.982317068525063],
              [-1.936583161629205, 50.991524977530169],
              [-1.935535636876579, 50.992763538714101],
              [-1.927883658703754, 50.997703919465103],
              [-1.923750333548717, 50.997324529584461],
              [-1.920342989716522, 50.997430170903499],
              [-1.91231519284126, 50.99936226832456],
              [-1.903306012988394, 50.999345288910789],
              [-1.898024890464757, 50.998629524742768],
              [-1.89535015942275, 50.999237738970599],
              [-1.892474933714206, 50.999624498924284],
              [-1.891580395612807, 50.999451023287584],
              [-1.888803127243279, 50.999975364798964],
              [-1.886749237160908, 50.999534575378071],
              [-1.885288195154235, 51.000257028795495],
              [-1.883316768794222, 51.002178521046822],
              [-1.882208078204185, 51.00271424768291],
              [-1.879239105191565, 51.00395484985507],
              [-1.875438945185081, 51.00470531763785],
              [-1.874489628773772, 51.006337293417261],
              [-1.873794353835536, 51.006242127666987],
              [-1.874375354947038, 51.005373203567252],
              [-1.874656692143835, 51.003573259725883],
              [-1.874640382627982, 50.995858798001905],
              [-1.875107391739567, 50.991742644593941],
              [-1.874244900558222, 50.987690709452352],
              [-1.873991516763833, 50.984447826971234],
              [-1.873271965961827, 50.985018958353272],
              [-1.870803035852388, 50.987614124459625],
              [-1.866836043880291, 50.990828907803412],
              [-1.862057157499132, 50.9966476556678],
              [-1.855898811636087, 51.001589567800032],
              [-1.85368792884565, 51.00447603139456],
              [-1.852812508820858, 51.005034250970148],
              [-1.842279798962369, 51.008474449229247],
              [-1.835821081425374, 51.009428635409975],
              [-1.827420792005467, 50.99727972312423],
              [-1.821553385896785, 50.992997803657509],
              [-1.81986408939443, 50.990264266062184],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000092",
        LAD13CDO: "24UL",
        LAD13NM: "Rushmoor",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.764917399324122, 51.318494482555714],
            [-0.764226981325697, 51.317593376063968],
            [-0.76388717348505, 51.318223740736329],
            [-0.762920710151627, 51.318730589340525],
            [-0.763068633279817, 51.318352680624542],
            [-0.762329091874165, 51.31746543155144],
            [-0.761350833493227, 51.317344486887635],
            [-0.761088970728193, 51.316944262106894],
            [-0.759266443923037, 51.317162365558602],
            [-0.758529454907443, 51.31676879113386],
            [-0.75830827362106, 51.315925675342932],
            [-0.756465428971734, 51.314666998920785],
            [-0.75642836770892, 51.314020964686854],
            [-0.755601376228197, 51.31350951699217],
            [-0.754449700631293, 51.313490981370542],
            [-0.753590414652631, 51.312795734691129],
            [-0.752155210229144, 51.312439648512338],
            [-0.751285298693058, 51.312560764807465],
            [-0.749641141112077, 51.311447073389239],
            [-0.748656215750156, 51.311629887832247],
            [-0.747986876552032, 51.311340379278391],
            [-0.748141977954172, 51.311071371207753],
            [-0.747381693658484, 51.310433787246097],
            [-0.746406772764965, 51.31061848818414],
            [-0.746172496734533, 51.310001811588279],
            [-0.745670916290584, 51.310033309298461],
            [-0.744184589085287, 51.309295306748709],
            [-0.744441685992182, 51.308233383190448],
            [-0.743700075386365, 51.307861245624785],
            [-0.743607830833646, 51.307456505004801],
            [-0.74471606923518, 51.306905474167458],
            [-0.74363848094884, 51.306544122279014],
            [-0.743587942622621, 51.30571539550504],
            [-0.742338941945728, 51.305538331784454],
            [-0.742438970859267, 51.305027747677897],
            [-0.741750803281638, 51.304749690383218],
            [-0.741623463278923, 51.303688137529306],
            [-0.740304643107593, 51.302343108866864],
            [-0.740501176746721, 51.30192348643633],
            [-0.739410758804709, 51.301355135207309],
            [-0.739240764935899, 51.299915445528256],
            [-0.738853668520302, 51.299751215853938],
            [-0.73941809737259, 51.298939897235776],
            [-0.739828868469433, 51.298658364404822],
            [-0.739671338468907, 51.297287149931272],
            [-0.738964021753675, 51.297032249169149],
            [-0.739991372530278, 51.296127895674104],
            [-0.739324629011645, 51.295125278150742],
            [-0.739364748682213, 51.294026856692575],
            [-0.738488777142146, 51.29364784246993],
            [-0.738255493328081, 51.292897173649919],
            [-0.736705183675209, 51.292424552976826],
            [-0.737248136883084, 51.289675180259103],
            [-0.736107018062826, 51.288353596631396],
            [-0.736380674486323, 51.287526563731127],
            [-0.735639772863386, 51.286255153218647],
            [-0.736002476167301, 51.285945238553197],
            [-0.735555619715048, 51.285768662099791],
            [-0.735732378532945, 51.285441453493611],
            [-0.735146084059763, 51.285434223090327],
            [-0.734271737729988, 51.284377176307316],
            [-0.733778313822293, 51.284434787904452],
            [-0.73329747467454, 51.284034826871085],
            [-0.733890621617868, 51.283792155508742],
            [-0.733410949616129, 51.283402098997961],
            [-0.733853177749, 51.282807096679832],
            [-0.733277598795736, 51.282411506353959],
            [-0.733366330488392, 51.281791999124522],
            [-0.73231060048364, 51.281338154846139],
            [-0.731669929174651, 51.281537140540706],
            [-0.731230249652549, 51.281362423408964],
            [-0.731582412191678, 51.280601894832031],
            [-0.731111532408601, 51.280362994037318],
            [-0.732276971289537, 51.279642746170055],
            [-0.73231686958945, 51.279287083796824],
            [-0.732958147786496, 51.279169031711412],
            [-0.732842363372846, 51.27816603868105],
            [-0.733497860311388, 51.277790958284427],
            [-0.733400794278724, 51.277358278828302],
            [-0.732962083785836, 51.277358028732522],
            [-0.733087430997553, 51.276762297355695],
            [-0.732330376278549, 51.276451963212509],
            [-0.732234595647092, 51.275660504346625],
            [-0.731422652446071, 51.274949413102668],
            [-0.731570295222111, 51.274481615107788],
            [-0.730431417478381, 51.273462136044365],
            [-0.731172149479912, 51.272436950803595],
            [-0.730376305519507, 51.271508412593604],
            [-0.730156241321207, 51.269974638367934],
            [-0.730918788206638, 51.268519859105936],
            [-0.731503789272647, 51.268305887005567],
            [-0.73165152630902, 51.26679228513764],
            [-0.731263925773668, 51.265980577185296],
            [-0.731580281364506, 51.264277265813774],
            [-0.732687392968205, 51.263630118547795],
            [-0.732600376310245, 51.262729046983821],
            [-0.732009004814021, 51.26213544642507],
            [-0.733249680619761, 51.261163319527],
            [-0.733723828069547, 51.260128937846538],
            [-0.733319347594821, 51.259723505549779],
            [-0.732455941012542, 51.257662115846138],
            [-0.730417234272608, 51.25738014816951],
            [-0.730305984548035, 51.256944612922275],
            [-0.729414646274739, 51.256423280237378],
            [-0.729575776794308, 51.255724527310683],
            [-0.730553214532809, 51.255356553114758],
            [-0.730508411877115, 51.254901955267655],
            [-0.731173868078848, 51.253476692804107],
            [-0.730714130470917, 51.251958301709472],
            [-0.732832858458486, 51.250884190257864],
            [-0.734482954479639, 51.25059270315046],
            [-0.735759260137396, 51.249405118620203],
            [-0.735884273682119, 51.248503639360031],
            [-0.734700338457694, 51.246587173912665],
            [-0.735221985184408, 51.245590166318614],
            [-0.736546557840772, 51.245092805893101],
            [-0.737405188955142, 51.242407967734408],
            [-0.738103860312169, 51.241743774010175],
            [-0.73851633029609, 51.241119655530383],
            [-0.738201094625762, 51.240127101428655],
            [-0.736545410672328, 51.238963619677449],
            [-0.735917800114335, 51.238865122273261],
            [-0.735804100196389, 51.237682301140175],
            [-0.738866255142589, 51.235499618740718],
            [-0.739206426461124, 51.233482703947018],
            [-0.741775882657279, 51.231336002452807],
            [-0.743863663666346, 51.230617456413292],
            [-0.745558055075641, 51.230458477968718],
            [-0.750272313191903, 51.23101427420611],
            [-0.752117861279545, 51.231991647406353],
            [-0.758738648080511, 51.233882988362943],
            [-0.759679798124471, 51.23403953933348],
            [-0.761193768858385, 51.234894555233879],
            [-0.767712670335731, 51.236071215486533],
            [-0.769745366973606, 51.23644419555005],
            [-0.771380337703393, 51.238036820369892],
            [-0.771489408224024, 51.238997444756521],
            [-0.775874359452795, 51.241911925841805],
            [-0.777911168563508, 51.242141827898344],
            [-0.781946332304212, 51.241673120027095],
            [-0.784441730356825, 51.242006594267437],
            [-0.786918093265237, 51.241866823146275],
            [-0.79024878710165, 51.242417447189162],
            [-0.793064746423903, 51.241618322949265],
            [-0.797203973823501, 51.239623271485861],
            [-0.798507241080659, 51.239416343675479],
            [-0.801757384647833, 51.239040507225695],
            [-0.8034753010776, 51.241512973011091],
            [-0.804767102029057, 51.245066430314324],
            [-0.80649323571884, 51.253436062059372],
            [-0.806147172256213, 51.25811210674302],
            [-0.804418034017871, 51.261458465802434],
            [-0.80474336692244, 51.269361477677769],
            [-0.804766323857203, 51.270355354742726],
            [-0.803434732896464, 51.27222742779248],
            [-0.805196412397399, 51.27438916587343],
            [-0.806421234750183, 51.275088663610582],
            [-0.807541363875476, 51.275292509498613],
            [-0.807216934463124, 51.275696553348943],
            [-0.790309708323525, 51.282197152019123],
            [-0.792116591219439, 51.283282282026597],
            [-0.796410164857486, 51.283921775061465],
            [-0.798006015473954, 51.284584707063765],
            [-0.798058038389331, 51.284953922230912],
            [-0.79596601969096, 51.286814504331616],
            [-0.797579510920269, 51.287958706658266],
            [-0.800134062052264, 51.288929090512909],
            [-0.80098927845147, 51.290616696157883],
            [-0.800924363857884, 51.291567407690117],
            [-0.802057579234099, 51.292109545255656],
            [-0.802455448665594, 51.29302362599698],
            [-0.802501882426734, 51.293278580309135],
            [-0.801650976584525, 51.293349907345174],
            [-0.801616400848278, 51.294567997798346],
            [-0.799486352196339, 51.295400442528006],
            [-0.798468283957289, 51.29621458774605],
            [-0.799951006808285, 51.296942871892441],
            [-0.799523364543891, 51.297611106203718],
            [-0.796891398592667, 51.297664129280378],
            [-0.79730474380289, 51.299411063965593],
            [-0.796672650650991, 51.299447733918818],
            [-0.79708806548807, 51.302378063629085],
            [-0.797340402389844, 51.30381041447658],
            [-0.796586395695846, 51.304666819826245],
            [-0.793192436272302, 51.303619377262322],
            [-0.791415873371286, 51.3035336170717],
            [-0.78913412100131, 51.306728358371295],
            [-0.793055462733739, 51.308076297128046],
            [-0.791562455014541, 51.309532924991998],
            [-0.778668470417625, 51.3174858676581],
            [-0.777345403840693, 51.316515295822569],
            [-0.773313365062151, 51.316779773746212],
            [-0.771207407649036, 51.317619152684024],
            [-0.768424261606778, 51.318236462377122],
            [-0.767823724217211, 51.320084337496915],
            [-0.766293373819356, 51.31983892866765],
            [-0.766504492148428, 51.319244070183707],
            [-0.764917399324122, 51.318494482555714],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000093",
        LAD13CDO: "24UN",
        LAD13NM: "Test Valley",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-1.485854730092233, 50.932174607571547],
              [-1.485534423043742, 50.93179911310876],
              [-1.483573848509515, 50.931898372801875],
              [-1.483474518866067, 50.930201963607836],
              [-1.481316608215196, 50.929625881208921],
              [-1.481138649834515, 50.929634083462858],
              [-1.481835920029502, 50.928990622807447],
              [-1.482768194501823, 50.928966877208182],
              [-1.482669438038077, 50.928633720352607],
              [-1.481211146878824, 50.928488770816145],
              [-1.481985741393245, 50.92831235926645],
              [-1.482243624522807, 50.928538312936453],
              [-1.483019479441482, 50.928376287997388],
              [-1.483947777317411, 50.92935427117412],
              [-1.484467021714669, 50.929365555888069],
              [-1.484983372992285, 50.930154669866042],
              [-1.48586374975205, 50.929935535249626],
              [-1.486127043078114, 50.931094017824115],
              [-1.486690907635391, 50.931448999189634],
              [-1.485854730092233, 50.932174607571547],
            ],
          ],
          [
            [
              [-1.516295847332724, 51.339357822205876],
              [-1.514152936423359, 51.337008397785631],
              [-1.512603168144721, 51.336140550248295],
              [-1.511435363645664, 51.334995529332637],
              [-1.509326416494343, 51.334348302134892],
              [-1.502180278690344, 51.331085616277456],
              [-1.499777048178765, 51.330338955252202],
              [-1.498313464094772, 51.329378669173103],
              [-1.498050322401199, 51.329028660511021],
              [-1.497013628544291, 51.329199555996674],
              [-1.495408718027054, 51.328963366093433],
              [-1.490718218096618, 51.329746944634628],
              [-1.488456787774182, 51.330469028611176],
              [-1.484414304874589, 51.331039378292168],
              [-1.465537054505941, 51.332382710089192],
              [-1.460651845405507, 51.33243765930051],
              [-1.45822392679741, 51.332984852000024],
              [-1.4556287207864, 51.33250165524511],
              [-1.453598345507539, 51.332806919989622],
              [-1.450819557670596, 51.332677037919481],
              [-1.429708971490475, 51.336532609813567],
              [-1.425903163469009, 51.337245962160296],
              [-1.42184611793007, 51.335896128570369],
              [-1.421916452421573, 51.335668083372539],
              [-1.421352302043126, 51.335554699887915],
              [-1.421197586282324, 51.334161103144815],
              [-1.423203414026363, 51.331829522315772],
              [-1.423319518927317, 51.330926415456425],
              [-1.421131860989683, 51.32923325915106],
              [-1.42006805171563, 51.32720573707995],
              [-1.418643507364228, 51.327043119965019],
              [-1.418417404696002, 51.326423360006373],
              [-1.419165523297879, 51.325597124273358],
              [-1.41938419135243, 51.323395208265779],
              [-1.42099706568485, 51.321014057174331],
              [-1.421341301600688, 51.317832645374686],
              [-1.420641849964506, 51.316972266300091],
              [-1.421240474888903, 51.316379964300083],
              [-1.423405823421478, 51.316369958127936],
              [-1.423933702862689, 51.315465277135552],
              [-1.424953345991899, 51.315392955621547],
              [-1.425003850422889, 51.31353278996761],
              [-1.425819375991021, 51.313372238203087],
              [-1.424825716897468, 51.310221115529274],
              [-1.426519822540605, 51.307708102762234],
              [-1.426080505735785, 51.30336198319123],
              [-1.427863827316624, 51.300453743366923],
              [-1.428666398209959, 51.300392918325372],
              [-1.42831350750889, 51.297969686104722],
              [-1.425121123485518, 51.295412961169198],
              [-1.424906625706726, 51.294787872099391],
              [-1.424506635210331, 51.29500530981106],
              [-1.420683106769043, 51.293973979887255],
              [-1.419276622132218, 51.293029163231203],
              [-1.418969824640832, 51.290860597729008],
              [-1.416776770862245, 51.285451867898821],
              [-1.416863852133654, 51.282877021856798],
              [-1.418765279053198, 51.280135848916942],
              [-1.420619187338613, 51.278305289300548],
              [-1.422074108225712, 51.277412386531104],
              [-1.428996020572086, 51.275428548278228],
              [-1.434550616791391, 51.274425020289229],
              [-1.438127850189129, 51.272973855132449],
              [-1.439944971279851, 51.271488101968963],
              [-1.440500107800509, 51.271810866825838],
              [-1.444187068083711, 51.269073298177837],
              [-1.446964471765844, 51.267921991567647],
              [-1.445674087592581, 51.267117410152331],
              [-1.448343448725265, 51.26611842190318],
              [-1.449063906551719, 51.265440225619002],
              [-1.450478829924412, 51.265537690534472],
              [-1.455218965339595, 51.261513470101228],
              [-1.456702545273389, 51.261005123966768],
              [-1.458481121994671, 51.259659177039765],
              [-1.459601085533117, 51.258008936677349],
              [-1.459612612315117, 51.2567806929518],
              [-1.460166104979276, 51.25589394381818],
              [-1.458181443911069, 51.254062115653689],
              [-1.453068496250046, 51.2531166781257],
              [-1.447094369475512, 51.250875712204284],
              [-1.443161373909102, 51.248016524376048],
              [-1.441339252154567, 51.24538669420847],
              [-1.43378238460522, 51.236713626266301],
              [-1.430654025695113, 51.235669777402279],
              [-1.427808614424943, 51.233534702942627],
              [-1.427041167113717, 51.231782910846938],
              [-1.427647184385382, 51.227032714909882],
              [-1.428108662583057, 51.225039648646764],
              [-1.426033809023029, 51.224911701518181],
              [-1.422757415186196, 51.225363178700121],
              [-1.421356343224923, 51.224948926339856],
              [-1.421105143847336, 51.22374006071707],
              [-1.408681204673809, 51.221167412693539],
              [-1.404810109407698, 51.219995024447805],
              [-1.401346237744807, 51.21989733629637],
              [-1.400111329967099, 51.219505258854326],
              [-1.39658991515178, 51.217349764109798],
              [-1.388560476034895, 51.214749879889375],
              [-1.385906022147786, 51.214431157793292],
              [-1.383680569485802, 51.214904133529345],
              [-1.381694832709982, 51.211798610720081],
              [-1.379164209545277, 51.209206306139869],
              [-1.376011533884248, 51.20722388775733],
              [-1.373972493578023, 51.206529607583555],
              [-1.372644340647428, 51.205036114708747],
              [-1.368876938789799, 51.202410871736781],
              [-1.368154336490621, 51.202813414059221],
              [-1.366856675211718, 51.202980848567137],
              [-1.365412143985773, 51.201960523221622],
              [-1.363274212341248, 51.202965914395257],
              [-1.362380765927035, 51.203193945397103],
              [-1.362073363103364, 51.202962074419681],
              [-1.360055770269851, 51.204571428453349],
              [-1.360292485174894, 51.206290201578476],
              [-1.35920892639424, 51.20824723712699],
              [-1.360270856547578, 51.210022676895477],
              [-1.358193033088811, 51.212024620156441],
              [-1.352793287354806, 51.210670367543429],
              [-1.341626755899923, 51.205657984474179],
              [-1.340619072434057, 51.20495273182545],
              [-1.341098389678371, 51.20446536405472],
              [-1.339131293813565, 51.199887212755698],
              [-1.339550442162072, 51.196096728288786],
              [-1.341691625525606, 51.187040289973474],
              [-1.340953993151271, 51.184583100774518],
              [-1.332212255373248, 51.187729338017569],
              [-1.32773609988693, 51.190509242960978],
              [-1.323522794806564, 51.191328425497893],
              [-1.32067280047364, 51.191486366176086],
              [-1.313166065752515, 51.193393839790531],
              [-1.310335668497207, 51.194529082532576],
              [-1.308838419442938, 51.194742355667508],
              [-1.308985686822635, 51.193411498689294],
              [-1.31040825792569, 51.190911098243809],
              [-1.325421980495, 51.180426595430447],
              [-1.328730624074233, 51.175333613335887],
              [-1.329744328239672, 51.1754680099816],
              [-1.331038970816768, 51.173981829706399],
              [-1.331199090163368, 51.173691400830812],
              [-1.330209585876213, 51.173271206428147],
              [-1.328657703321421, 51.171685100321533],
              [-1.332377500013662, 51.168458431159664],
              [-1.332826981234694, 51.168630945195751],
              [-1.334396650537352, 51.167791028201329],
              [-1.337234342130019, 51.167388100654286],
              [-1.337500232443978, 51.165999426464104],
              [-1.336514337975097, 51.165634156925705],
              [-1.336403287997045, 51.165200107646527],
              [-1.337346418836852, 51.16445640898872],
              [-1.335730630595034, 51.163838482851546],
              [-1.33592820324228, 51.163425067782086],
              [-1.335527626280956, 51.163235758101798],
              [-1.336037235968034, 51.16210564424329],
              [-1.337105526040066, 51.161306006965937],
              [-1.336276503439604, 51.160679053638901],
              [-1.336891016467417, 51.159110715235762],
              [-1.341907708155876, 51.159091376100996],
              [-1.347710162732959, 51.159529406773018],
              [-1.348226932094872, 51.15553169349306],
              [-1.35025067458071, 51.154684200143883],
              [-1.350760472293337, 51.155240944177898],
              [-1.35949606587492, 51.15231179288724],
              [-1.36408106542244, 51.149732680711331],
              [-1.368056079826286, 51.146348897646398],
              [-1.369247927715941, 51.144492159438457],
              [-1.369838195255398, 51.142506280623863],
              [-1.370906278975607, 51.14143477046013],
              [-1.377777519827681, 51.136336103077625],
              [-1.37023573103859, 51.129146998038358],
              [-1.374012661439511, 51.12777887854611],
              [-1.376036086098669, 51.127359862199015],
              [-1.380264948620168, 51.124123582211652],
              [-1.388017203508026, 51.124632880288956],
              [-1.391545911055006, 51.123601001395137],
              [-1.399200278230083, 51.122735947682848],
              [-1.407000569313699, 51.12060593743837],
              [-1.407307957300671, 51.120478907272464],
              [-1.407119136464593, 51.119826019529725],
              [-1.40411555897862, 51.115181597551732],
              [-1.405202213831056, 51.114777983970214],
              [-1.403681592192471, 51.113045546159285],
              [-1.407547422614411, 51.111135468716277],
              [-1.408378822282235, 51.111694492750864],
              [-1.411564328718676, 51.110689948091974],
              [-1.414377922927708, 51.112881068876924],
              [-1.413738591417047, 51.11113428648909],
              [-1.417254329851333, 51.109756268162748],
              [-1.418450819752619, 51.109915088862095],
              [-1.420462975544569, 51.110645346267695],
              [-1.431428407757398, 51.116791336799096],
              [-1.433129899976777, 51.115511917454356],
              [-1.436283608488605, 51.11204809177741],
              [-1.435973661979836, 51.107817585852906],
              [-1.434431406964416, 51.105206908603961],
              [-1.433645752806745, 51.102172746863943],
              [-1.434556354873974, 51.100114347552974],
              [-1.436304277906503, 51.097894522371163],
              [-1.43118565812804, 51.098152998446075],
              [-1.429987447447325, 51.094436991991799],
              [-1.420943148333477, 51.0947315753181],
              [-1.414546354058505, 51.095697867242123],
              [-1.414300740485866, 51.094114012517451],
              [-1.414678008627726, 51.091262678479879],
              [-1.411974398536157, 51.085565149185562],
              [-1.412608147466068, 51.081968753418238],
              [-1.415465358278036, 51.078297160409228],
              [-1.415866852221, 51.077133775313264],
              [-1.410127997075849, 51.07784413266014],
              [-1.410086923401491, 51.076695619393234],
              [-1.410423522507254, 51.074919554943889],
              [-1.412408697908971, 51.072464777446896],
              [-1.412482286268496, 51.071386979442003],
              [-1.41154671441721, 51.070541502663943],
              [-1.41171903377364, 51.069557720666872],
              [-1.413745614401523, 51.066625639508672],
              [-1.416719730986335, 51.063334066402419],
              [-1.415480751836452, 51.062678640787581],
              [-1.423472015892342, 51.060726529410893],
              [-1.42942408426329, 51.060707163072834],
              [-1.430602479853829, 51.060321744275839],
              [-1.432750153084031, 51.060369948827329],
              [-1.434361828556904, 51.05973031430797],
              [-1.436054971130682, 51.059546057597586],
              [-1.440623171401091, 51.057597798835246],
              [-1.442729495277098, 51.056266213226209],
              [-1.445124911555598, 51.054133853784464],
              [-1.446306711535189, 51.052180945025484],
              [-1.447128139987539, 51.052210009781497],
              [-1.446499472191868, 51.051209795958371],
              [-1.447408253191273, 51.050130528333362],
              [-1.448026732980834, 51.04814616259354],
              [-1.448791822191478, 51.047627321852772],
              [-1.448627282543353, 51.047067228166824],
              [-1.450991817057575, 51.045432771537371],
              [-1.452047865822788, 51.045394562136146],
              [-1.454155390661157, 51.044492604608706],
              [-1.456514147702472, 51.041128798254981],
              [-1.455889056691998, 51.040305797257638],
              [-1.453028904939651, 51.039003862131374],
              [-1.451958468331853, 51.037976429627385],
              [-1.449851580805277, 51.037159030214326],
              [-1.449927410861651, 51.036777215680004],
              [-1.448395390644662, 51.03595799660247],
              [-1.44717620367556, 51.034621382776699],
              [-1.446821490099374, 51.033868850504739],
              [-1.447099165066272, 51.031865786244104],
              [-1.446496698241595, 51.031682191007235],
              [-1.446179907998417, 51.030023414364102],
              [-1.444875703174566, 51.028301502482037],
              [-1.444800692140046, 51.027537701625114],
              [-1.443632464927456, 51.027093324894714],
              [-1.441901626854458, 51.025173315820624],
              [-1.439694913360452, 51.024419998176917],
              [-1.437993174715685, 51.024124976258527],
              [-1.43309044558389, 51.025587731339151],
              [-1.429994892618806, 51.024073665334846],
              [-1.42957567870173, 51.023145414557341],
              [-1.427680447418565, 51.022593916800901],
              [-1.426667766584985, 51.021814718318602],
              [-1.42607754110385, 51.016843576321918],
              [-1.424539146233418, 51.015934984783748],
              [-1.422519128126172, 51.015358508478727],
              [-1.422060447275118, 51.014527150948766],
              [-1.421035680984737, 51.013824277018671],
              [-1.419001101255274, 51.015451682627472],
              [-1.411208724531178, 51.014118701788171],
              [-1.410757224689097, 51.013975247553923],
              [-1.411256625656456, 51.013487682606382],
              [-1.411177520441053, 51.012529602628931],
              [-1.406019656356083, 51.011339902824858],
              [-1.405271532901756, 51.010541177993595],
              [-1.404898464520054, 51.010733513512186],
              [-1.399807611563967, 51.009791168521936],
              [-1.39866540326862, 51.009165727237644],
              [-1.399029566327289, 51.008450012403827],
              [-1.398534197363809, 51.008289199672618],
              [-1.397129888536934, 51.009195585956512],
              [-1.394384904525985, 51.009720936529291],
              [-1.394708130483849, 51.009089550884511],
              [-1.393410174855261, 51.006422891826752],
              [-1.392985156969756, 51.004368635149021],
              [-1.391943517481673, 50.999226797365331],
              [-1.395168852726576, 50.999086165374429],
              [-1.396481216649568, 50.998655921017836],
              [-1.398971403414321, 50.99673180139682],
              [-1.399653345124156, 50.994681454266306],
              [-1.398129746523258, 50.994377770456737],
              [-1.397800084094322, 50.992984956023051],
              [-1.396876484314066, 50.992566545648224],
              [-1.397328290520563, 50.991786541556955],
              [-1.396863775197128, 50.991017996975607],
              [-1.397167534259123, 50.990446751121482],
              [-1.395467448649279, 50.988933548994574],
              [-1.393841713397412, 50.988612194771086],
              [-1.391705490315422, 50.987235156712678],
              [-1.391437419101802, 50.986816515621456],
              [-1.39242581962682, 50.985859475191077],
              [-1.39106311170406, 50.982865119288235],
              [-1.394428335257208, 50.979657940887989],
              [-1.395969825410753, 50.978982497827928],
              [-1.396391446108435, 50.977554888820734],
              [-1.392392679545952, 50.976279730712967],
              [-1.395551659444627, 50.970482537628264],
              [-1.396475865051444, 50.968510787662325],
              [-1.392756493759562, 50.967710980302719],
              [-1.393967011843231, 50.964752472487831],
              [-1.392774189521055, 50.964495392442387],
              [-1.390563168938753, 50.963806754800231],
              [-1.389965362075338, 50.963024893835346],
              [-1.385288366195527, 50.961674904178167],
              [-1.384533489674073, 50.960719534377404],
              [-1.383740437110933, 50.961146090802202],
              [-1.382955106174189, 50.960773257882792],
              [-1.383584291963884, 50.960032906739151],
              [-1.385296455917846, 50.959226315003733],
              [-1.385589264402749, 50.958508462435979],
              [-1.38448821529482, 50.956277046494215],
              [-1.380284056373659, 50.95679796664519],
              [-1.380652762155505, 50.956051752829843],
              [-1.376447468605704, 50.955591456530207],
              [-1.375707147686256, 50.954314177335249],
              [-1.374044429182224, 50.95398425247474],
              [-1.375702032082631, 50.952357399696815],
              [-1.377820447858482, 50.950860670244381],
              [-1.378516585274896, 50.950753768259503],
              [-1.379901951384165, 50.947526553968707],
              [-1.380495768994739, 50.947616930514741],
              [-1.381563874329743, 50.946077689987099],
              [-1.383082778039036, 50.945963422736291],
              [-1.383136642736823, 50.946195711754342],
              [-1.385224920493428, 50.946081723587547],
              [-1.387654957858881, 50.946390335236025],
              [-1.389831518225256, 50.945947604599418],
              [-1.390841491059891, 50.946543676851427],
              [-1.391297775416775, 50.948119728774635],
              [-1.392880794867857, 50.948878832060508],
              [-1.392871285766434, 50.949390451302222],
              [-1.398114671950599, 50.949464339309564],
              [-1.400138514156089, 50.950494486892175],
              [-1.400157883865116, 50.949763502976126],
              [-1.40136687420316, 50.95039827309833],
              [-1.403928418259879, 50.952360923520239],
              [-1.403415038703769, 50.954208941312288],
              [-1.402541724697818, 50.955254792405576],
              [-1.404696872381592, 50.955283776748665],
              [-1.406149610019607, 50.956135555644067],
              [-1.406528981864045, 50.955860517004652],
              [-1.406505002577356, 50.954724654443446],
              [-1.407125996278118, 50.954704426659184],
              [-1.407375707422158, 50.953540277483683],
              [-1.409256632203473, 50.95172254453459],
              [-1.409620208024633, 50.949767633448865],
              [-1.411935784060706, 50.950375507196405],
              [-1.414276273332215, 50.949345042570435],
              [-1.415137221125323, 50.950843893752321],
              [-1.416375609232824, 50.9508545819996],
              [-1.416473869380526, 50.95142069522344],
              [-1.417193349302478, 50.951148219112596],
              [-1.417183128492427, 50.951738070066682],
              [-1.421047475846352, 50.951685341603991],
              [-1.421959111067074, 50.950954276143399],
              [-1.423793061051549, 50.947238678439383],
              [-1.429779920000209, 50.947378655215743],
              [-1.434626483095706, 50.948937210367056],
              [-1.435726110327705, 50.949000077211245],
              [-1.439663159873926, 50.948325709078752],
              [-1.440748741232805, 50.947066577659534],
              [-1.441302504639863, 50.94706383133439],
              [-1.446616278408648, 50.948267123030128],
              [-1.447000566920793, 50.948152041123173],
              [-1.449269540550318, 50.949003546886729],
              [-1.449554822816143, 50.949919418233591],
              [-1.451781907913439, 50.949138556999777],
              [-1.449968582496611, 50.947185878056963],
              [-1.451017084945169, 50.944334820943034],
              [-1.452444781535128, 50.943604142645626],
              [-1.455964390934246, 50.946076409061881],
              [-1.45943243741384, 50.943205043530334],
              [-1.463668858458099, 50.939941437729829],
              [-1.464760598318531, 50.938692002565368],
              [-1.465123487293484, 50.937949991831751],
              [-1.464855298466054, 50.936019890836455],
              [-1.464117130026069, 50.935114568120859],
              [-1.466219338541071, 50.934716834516827],
              [-1.470158332430411, 50.931595510023683],
              [-1.471569629601469, 50.931623483411542],
              [-1.47188366390941, 50.930028748379605],
              [-1.474368131308083, 50.929523787125881],
              [-1.474129874510798, 50.928948099093375],
              [-1.476397294732406, 50.92913813117989],
              [-1.477329956630707, 50.928572187583718],
              [-1.477853292077149, 50.928725600320512],
              [-1.478403341241759, 50.929544569247206],
              [-1.477340440566173, 50.930430968231711],
              [-1.478585090504315, 50.9307251861689],
              [-1.478226932606996, 50.932213631119424],
              [-1.47885599201599, 50.932715517849296],
              [-1.478695319479045, 50.933340673283624],
              [-1.47897242307391, 50.933630565872932],
              [-1.479304013828219, 50.933437068726953],
              [-1.48052071034373, 50.934141934456427],
              [-1.479700454985019, 50.934575315991623],
              [-1.480620442329368, 50.934259279217947],
              [-1.481434961669331, 50.934852799433685],
              [-1.480610887202808, 50.933839290979357],
              [-1.479358282470039, 50.933405400076751],
              [-1.47908736213311, 50.933311552653016],
              [-1.478922547451708, 50.933254460457547],
              [-1.479186929625224, 50.932771847234655],
              [-1.478476189290678, 50.932193162178478],
              [-1.479071672171572, 50.930731852869393],
              [-1.477799557409325, 50.93034849240405],
              [-1.47864959968039, 50.929791161495373],
              [-1.478254789237462, 50.928831706076089],
              [-1.48073015336036, 50.928749212791637],
              [-1.480363273642456, 50.929111774743902],
              [-1.48052117350134, 50.930393896943109],
              [-1.4800696364951, 50.930690436794144],
              [-1.481632107638087, 50.931198257178522],
              [-1.482249267682126, 50.931107472526229],
              [-1.481399518024546, 50.92971977043063],
              [-1.483277732524226, 50.93023976087035],
              [-1.483437952714176, 50.931962517464541],
              [-1.485414053595366, 50.931875018574281],
              [-1.485702653289338, 50.932157751914019],
              [-1.485691091311184, 50.932173887369153],
              [-1.484912912362821, 50.932670437655325],
              [-1.484892693085136, 50.932697325762987],
              [-1.486074081488865, 50.933318508568853],
              [-1.486757034683863, 50.93319921374767],
              [-1.487485183146419, 50.932981196635517],
              [-1.488774971763728, 50.933713434146192],
              [-1.488486321502549, 50.934860501837449],
              [-1.489687081987627, 50.934500660521692],
              [-1.490586022049978, 50.933499231607861],
              [-1.492484057210721, 50.933921142386325],
              [-1.492772841885409, 50.934715527032758],
              [-1.493489282788544, 50.934920064986905],
              [-1.49735544675999, 50.935088732518004],
              [-1.498438923486968, 50.934645566556256],
              [-1.50027591564976, 50.934532939356231],
              [-1.501358474694207, 50.935636436483264],
              [-1.501151574131575, 50.93608697211355],
              [-1.503085817831779, 50.936201324435828],
              [-1.50312827012543, 50.936892121625355],
              [-1.504143818827023, 50.937616727206724],
              [-1.504350273111619, 50.938411632227471],
              [-1.505224001714462, 50.93882538868251],
              [-1.506511615229717, 50.93980471067227],
              [-1.506210790920345, 50.940391542637336],
              [-1.507645955915948, 50.940612516957131],
              [-1.507667605475251, 50.941258262237277],
              [-1.507898350756539, 50.9427258938018],
              [-1.507349940112064, 50.943170504906604],
              [-1.507866080969437, 50.943760782305198],
              [-1.507356796880226, 50.94440788696366],
              [-1.508294258848261, 50.945810152104301],
              [-1.50771208922138, 50.946752800947294],
              [-1.508241319654264, 50.947050878841644],
              [-1.508834148580532, 50.946582171056036],
              [-1.508946037603099, 50.947446809656739],
              [-1.509672061281675, 50.947577550207406],
              [-1.510403198391872, 50.948306301245296],
              [-1.5097017822675, 50.949760126489657],
              [-1.509978486277826, 50.950115586975954],
              [-1.510808047600678, 50.950015650261072],
              [-1.512741657977498, 50.950929261473298],
              [-1.515122981581711, 50.950414011498246],
              [-1.516164836644764, 50.951530687015058],
              [-1.517051333207448, 50.951432740152988],
              [-1.517946091998296, 50.951774547894303],
              [-1.519027198293153, 50.951717854345702],
              [-1.519397736204187, 50.952093461669186],
              [-1.52155022863914, 50.952234482111471],
              [-1.521897403877066, 50.951558777106655],
              [-1.522194579079491, 50.952010510562317],
              [-1.522704305409494, 50.952003601174759],
              [-1.522736344735485, 50.951376963440637],
              [-1.52432319445686, 50.951143336988046],
              [-1.523714207250872, 50.950834216498912],
              [-1.523972065735038, 50.950536720250852],
              [-1.525250395979536, 50.95040074020249],
              [-1.525947716588, 50.950843298230822],
              [-1.526912992437554, 50.950133215254709],
              [-1.52710230365233, 50.950696904264099],
              [-1.529111431245651, 50.95036960166707],
              [-1.530075780240303, 50.951582058702407],
              [-1.530455988588067, 50.951147456828522],
              [-1.531135217581636, 50.95127158101613],
              [-1.531331783496511, 50.951543939190572],
              [-1.530886313395136, 50.951956700225438],
              [-1.531937978344384, 50.952137167636501],
              [-1.53229800476106, 50.95300367524203],
              [-1.532921771868583, 50.952840710342251],
              [-1.533573430883179, 50.953304621686634],
              [-1.534448694207535, 50.954616501462723],
              [-1.533656133006164, 50.955711309546302],
              [-1.533985015974154, 50.956280939001786],
              [-1.533217036036052, 50.956623180932041],
              [-1.534017198306673, 50.957191993898185],
              [-1.533607342890173, 50.95802664879723],
              [-1.534280338023743, 50.959070648155816],
              [-1.535341013831122, 50.959364424120871],
              [-1.535679130301254, 50.960012318625679],
              [-1.536952140375139, 50.960434614766044],
              [-1.537367814718758, 50.960157497834615],
              [-1.537972433256499, 50.960354124599405],
              [-1.539521629501483, 50.961504965853692],
              [-1.540059360599619, 50.962566382736334],
              [-1.539670565866007, 50.962860700486416],
              [-1.540534313841354, 50.963209405722729],
              [-1.540617169006687, 50.96390484028953],
              [-1.541572880202775, 50.964461622493424],
              [-1.541423398431502, 50.965326100190147],
              [-1.540746460646949, 50.965523971575934],
              [-1.541144144204796, 50.965919398463136],
              [-1.540932437938397, 50.966593892484546],
              [-1.54148381249963, 50.966567281357264],
              [-1.541490435038639, 50.967486325219333],
              [-1.542360905669402, 50.967740623281053],
              [-1.542113109030042, 50.968175781522923],
              [-1.543423978946868, 50.968251049976388],
              [-1.543873119325764, 50.969068409247804],
              [-1.544222585108073, 50.968575193628148],
              [-1.54440998175007, 50.969217977573514],
              [-1.545236936731622, 50.96912048383291],
              [-1.54762352141893, 50.967828553258933],
              [-1.552700427772053, 50.967533360955315],
              [-1.556856666314833, 50.965508830154413],
              [-1.561156620848362, 50.964969327343169],
              [-1.56169589139902, 50.965923640179234],
              [-1.575692608036205, 50.960783110379502],
              [-1.5840858248436, 50.962125262397961],
              [-1.58424405787238, 50.959072024463325],
              [-1.58523403996446, 50.957744674067897],
              [-1.586348818615332, 50.957429396370664],
              [-1.586896218354447, 50.954783084531975],
              [-1.588493131703185, 50.95354687376944],
              [-1.5900934035056, 50.951271136114762],
              [-1.590912188877095, 50.953355735814377],
              [-1.595588232335327, 50.953761378499244],
              [-1.596506373433368, 50.956079183379863],
              [-1.607756140585587, 50.954969156006577],
              [-1.61279279455582, 50.958105395336254],
              [-1.619761327029681, 50.958574292912971],
              [-1.616758543897982, 50.962552588400314],
              [-1.616074410741804, 50.965051111526599],
              [-1.61467298378814, 50.966108494511978],
              [-1.613437581468982, 50.968088123468846],
              [-1.612190779612233, 50.97125378861864],
              [-1.610801299768163, 50.972041394291757],
              [-1.608699902226727, 50.972289760776803],
              [-1.6077396478144, 50.973681251561011],
              [-1.604973560826234, 50.97520962355739],
              [-1.605150004911259, 50.97691066700753],
              [-1.602934125811773, 50.978528972016584],
              [-1.604501277918674, 50.979334600533143],
              [-1.607592667630814, 50.979971782687429],
              [-1.610844613034085, 50.979986250359033],
              [-1.614429742715811, 50.979501754449331],
              [-1.614146453320111, 50.979996296044021],
              [-1.615800421352057, 50.980495421293924],
              [-1.617064364812069, 50.981656878380939],
              [-1.619653123062974, 50.983003384539145],
              [-1.620169998216412, 50.98425589436853],
              [-1.619305548030237, 50.984918512904038],
              [-1.618926234590153, 50.986356045127572],
              [-1.619318641870165, 50.986976892920666],
              [-1.618964927214147, 50.987373200176762],
              [-1.620436569458841, 50.988605435763283],
              [-1.623931209854854, 50.989869355699888],
              [-1.624553068958998, 50.989787726034486],
              [-1.625223985786326, 50.990337509232305],
              [-1.624933833919805, 50.991159373392101],
              [-1.625813571081031, 50.993918328604508],
              [-1.625435050031544, 50.994033117041468],
              [-1.627459512281394, 50.995854228155196],
              [-1.62737200224037, 50.996995969066504],
              [-1.628391183591388, 50.997670936114574],
              [-1.628172558039264, 50.99810276968703],
              [-1.628812706156306, 50.998242385570784],
              [-1.628383496674586, 50.998633984747215],
              [-1.628815834607095, 50.99910115804699],
              [-1.627169465245643, 51.000044605048302],
              [-1.626269372341533, 51.001039874048494],
              [-1.624418576148458, 51.001163432306306],
              [-1.62382753406685, 51.001821565216098],
              [-1.620213170460969, 51.003419497958234],
              [-1.617242455206601, 51.004188541647522],
              [-1.614381614828254, 51.003381526855719],
              [-1.610744214957245, 51.001715794314372],
              [-1.606794479175572, 51.001627942022559],
              [-1.606089549403258, 51.001904329804013],
              [-1.605209229176578, 51.002527223143865],
              [-1.609667127969431, 51.005926882356022],
              [-1.614941646778325, 51.006662853815143],
              [-1.610463037923943, 51.006875523475706],
              [-1.610302551571975, 51.008168976108841],
              [-1.608635430096267, 51.008276710506316],
              [-1.609099959006963, 51.009819538845257],
              [-1.60856495221705, 51.009708043398383],
              [-1.606881912683935, 51.00781222242707],
              [-1.605025807060128, 51.00701194978199],
              [-1.600587731041551, 51.007142548245803],
              [-1.597535333669433, 51.008061882118852],
              [-1.597662001089398, 51.010721331774725],
              [-1.599532118886599, 51.012545987693997],
              [-1.603141534963929, 51.014202079207898],
              [-1.604946333685481, 51.01603811519584],
              [-1.605379287049846, 51.017264322298743],
              [-1.605663796317485, 51.020027701968338],
              [-1.601855928978494, 51.021340256447068],
              [-1.60045059027972, 51.022285044521006],
              [-1.599408694414446, 51.023735526228222],
              [-1.602861118879202, 51.025509781909655],
              [-1.608419574431073, 51.026305456358202],
              [-1.611458488702355, 51.027288552880471],
              [-1.613555167668725, 51.028420429007276],
              [-1.614653192799507, 51.02996442195834],
              [-1.619299661218326, 51.031638706585582],
              [-1.619469094535483, 51.033065425374453],
              [-1.622452104756962, 51.033112860755928],
              [-1.628861415611282, 51.032523703102164],
              [-1.632387466758565, 51.032785722685503],
              [-1.632083787339575, 51.036020169273193],
              [-1.634972065487386, 51.040893108717633],
              [-1.634736983437999, 51.041585674272369],
              [-1.634014328974601, 51.042064498476513],
              [-1.633596581046606, 51.04656558835736],
              [-1.632439563671948, 51.047662603753842],
              [-1.63283419843905, 51.04917273443219],
              [-1.63010560559743, 51.065421112721992],
              [-1.627821354888136, 51.068739622648287],
              [-1.628087959952833, 51.072338686261297],
              [-1.628969032441471, 51.074071576104963],
              [-1.629158283707396, 51.075574766169872],
              [-1.627739846185633, 51.078017027417388],
              [-1.629009270627351, 51.08118270077258],
              [-1.631142885051898, 51.082157901487996],
              [-1.631625703081083, 51.081941812684619],
              [-1.63203328441412, 51.082391803494417],
              [-1.631607709666614, 51.082590089929809],
              [-1.634101899571398, 51.085470008044226],
              [-1.637317745252373, 51.092146791308473],
              [-1.636198973017603, 51.092420275794275],
              [-1.636318423618098, 51.092847773376356],
              [-1.634004126819002, 51.096419424447184],
              [-1.6309986107737, 51.099586003127207],
              [-1.629275255614629, 51.100606556810725],
              [-1.626967692376083, 51.102869733236538],
              [-1.626814161278474, 51.104022932271164],
              [-1.628446124196987, 51.106054048847874],
              [-1.628351898951113, 51.107652546995162],
              [-1.629799582634485, 51.109172306126823],
              [-1.630942229801711, 51.111159573860327],
              [-1.630450312821478, 51.112120176506437],
              [-1.630846085283482, 51.112829104083971],
              [-1.628852183307054, 51.114034033269895],
              [-1.626261187033288, 51.117343871272702],
              [-1.627256416290418, 51.118302905071268],
              [-1.632753284421496, 51.120934284616936],
              [-1.640592869152132, 51.125388122921379],
              [-1.65628914020566, 51.127204012121616],
              [-1.662986878654892, 51.127197412033098],
              [-1.662940701332309, 51.130009998741528],
              [-1.660847640129657, 51.134427149639848],
              [-1.659447485898797, 51.135723336400744],
              [-1.658346718913885, 51.13943834445729],
              [-1.658973016124811, 51.141542512783523],
              [-1.659769051391282, 51.14214819581079],
              [-1.654623883300595, 51.152000097662054],
              [-1.653997974335067, 51.155974526453555],
              [-1.65705179079859, 51.159311461488727],
              [-1.666862170860676, 51.169945869170419],
              [-1.670379152546334, 51.176795138351075],
              [-1.671522582029766, 51.17814355382469],
              [-1.672379482877108, 51.178548795759731],
              [-1.669283093924561, 51.181836554771614],
              [-1.670175265460019, 51.183923413379723],
              [-1.669506007014745, 51.184277608954076],
              [-1.669463560343351, 51.184811612938944],
              [-1.6688471071674, 51.190776947942709],
              [-1.670565394632559, 51.19061724127463],
              [-1.676990929688789, 51.193405566939127],
              [-1.682723255046004, 51.197011728643488],
              [-1.685711494176536, 51.199328021874052],
              [-1.694076960850651, 51.203874023050922],
              [-1.691158160361073, 51.20733905260262],
              [-1.69062301483848, 51.212009852484428],
              [-1.689799899470636, 51.214772695422333],
              [-1.686899544005978, 51.215155228402317],
              [-1.684535534241027, 51.215192046665543],
              [-1.684401317365187, 51.214929120659626],
              [-1.673718673142071, 51.215836809254633],
              [-1.673030556797823, 51.215961676634251],
              [-1.673076728228905, 51.217125361645444],
              [-1.672737848801088, 51.217263790245333],
              [-1.666783179953443, 51.217448420575764],
              [-1.666747986303208, 51.216968151215134],
              [-1.660349937707348, 51.218258998098705],
              [-1.659857824318824, 51.218395146581074],
              [-1.660071730237598, 51.218902013368002],
              [-1.653507901091791, 51.220471654056837],
              [-1.650239673570802, 51.220183190265537],
              [-1.650206827938408, 51.219982572092029],
              [-1.637477713904899, 51.217590674752358],
              [-1.633552305592066, 51.217520010987485],
              [-1.632852869573855, 51.219412421853598],
              [-1.631350542038555, 51.221605331763278],
              [-1.631143983270031, 51.223802309621249],
              [-1.628521479379183, 51.226006035862596],
              [-1.626864750030441, 51.228645295017614],
              [-1.625604316649576, 51.231667054536842],
              [-1.623251535731115, 51.233376966913433],
              [-1.622148055861408, 51.234678139506165],
              [-1.621424127018554, 51.236324019900366],
              [-1.621227694779246, 51.238442778437559],
              [-1.619766343549751, 51.239865959036308],
              [-1.619346906640069, 51.241221475555086],
              [-1.616494416105773, 51.241951305966239],
              [-1.615063430514078, 51.241420585683542],
              [-1.613567661256928, 51.241445334001931],
              [-1.610404541555927, 51.242983262480585],
              [-1.611120174214252, 51.2456112762234],
              [-1.607379824468379, 51.249613682196845],
              [-1.606533392679476, 51.25192535581057],
              [-1.607437471788856, 51.252773626771933],
              [-1.603717024946589, 51.251852019841479],
              [-1.600717821700521, 51.250720546201826],
              [-1.600122612634271, 51.25161500786141],
              [-1.600752970321917, 51.251791600691703],
              [-1.600197737770367, 51.253527839939423],
              [-1.592762642043809, 51.253011271254458],
              [-1.58806860683398, 51.253158511204909],
              [-1.585360069292881, 51.253995991781487],
              [-1.584244458301744, 51.254720381045935],
              [-1.581948962385091, 51.254711312321398],
              [-1.578991291557126, 51.255347236991454],
              [-1.577811782760593, 51.254740534957712],
              [-1.577647404912449, 51.255634635914426],
              [-1.574018564588252, 51.254608109385238],
              [-1.572407798908535, 51.254932243365381],
              [-1.566972285447805, 51.252982614674551],
              [-1.563893498054667, 51.252323775797194],
              [-1.556782223497131, 51.249184985248206],
              [-1.552622838636681, 51.24904148755278],
              [-1.550218965677184, 51.248568298815364],
              [-1.548926221723023, 51.248022913622869],
              [-1.545897538292083, 51.245244398341306],
              [-1.545171152651411, 51.245102205156975],
              [-1.543138221994909, 51.24539192571833],
              [-1.5397259726868, 51.247368477709074],
              [-1.535966801812207, 51.248622404503877],
              [-1.533008374308242, 51.253413227155889],
              [-1.531246802980825, 51.255439261140431],
              [-1.531290000878451, 51.257674821485217],
              [-1.529832278818487, 51.26051761374508],
              [-1.530581897624714, 51.262620228108752],
              [-1.534379423639942, 51.261811726738792],
              [-1.536456163394677, 51.26180377770374],
              [-1.537359634791167, 51.260889276846157],
              [-1.540524526125262, 51.260836992011029],
              [-1.539122047459762, 51.267089838682466],
              [-1.537131517187954, 51.2707650662826],
              [-1.537106219916647, 51.27371250423235],
              [-1.537474966250093, 51.273822762354655],
              [-1.536897788353398, 51.276765321017834],
              [-1.536078691678999, 51.276814233199829],
              [-1.535928929799558, 51.277307293824947],
              [-1.536812759122225, 51.278236054953837],
              [-1.542766202235792, 51.280177406193964],
              [-1.54259637143439, 51.281253968629251],
              [-1.536683540707698, 51.285242004767426],
              [-1.53452400536948, 51.28709565887138],
              [-1.53143930825899, 51.286557339484752],
              [-1.528896277860881, 51.286481501166065],
              [-1.522820423859561, 51.287061165925941],
              [-1.522763559380974, 51.290058819714979],
              [-1.524159794077992, 51.293163990173277],
              [-1.522840667216782, 51.294105467345908],
              [-1.520336660551548, 51.295063665399603],
              [-1.519524348345873, 51.295941538282371],
              [-1.519825550968051, 51.296903102244116],
              [-1.521294276626752, 51.29758889544528],
              [-1.522672920401955, 51.297144927566272],
              [-1.525504028494851, 51.29715822492124],
              [-1.526475528854144, 51.297667496634396],
              [-1.525838632484568, 51.299207021910796],
              [-1.528035574978683, 51.299811151323546],
              [-1.526302431234152, 51.30375876733796],
              [-1.526390521342894, 51.305096208897147],
              [-1.525238337140489, 51.307069749048125],
              [-1.527115211561128, 51.308283142232767],
              [-1.52698412154207, 51.311407271978609],
              [-1.528510123384813, 51.31384031371946],
              [-1.530734142384274, 51.315378748473236],
              [-1.533575137204579, 51.316226328889925],
              [-1.532327818681912, 51.323045240532423],
              [-1.531894353709506, 51.328130170363906],
              [-1.531092665438591, 51.329196989141771],
              [-1.528862037590849, 51.33019962025665],
              [-1.52715202522626, 51.331511829639787],
              [-1.528477817427668, 51.334077133036324],
              [-1.527521212328441, 51.338468464363956],
              [-1.526648623270872, 51.338306688358131],
              [-1.522697399780263, 51.338639545917665],
              [-1.521289768198743, 51.338438683651752],
              [-1.516834047276421, 51.33896170608692],
              [-1.516295847332724, 51.339357822205876],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000094",
        LAD13CDO: "24UP",
        LAD13NM: "Winchester",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.295102106908084, 51.195065085190905],
            [-1.292780072039124, 51.194367680627664],
            [-1.289886196580686, 51.194195496522049],
            [-1.287763915670734, 51.195494547148812],
            [-1.287342933419172, 51.196159197329948],
            [-1.285158065283842, 51.195109986841437],
            [-1.284162600557034, 51.195256768736002],
            [-1.282560824276977, 51.19500327659231],
            [-1.281935885352979, 51.193960858558889],
            [-1.28028273494585, 51.195080111603055],
            [-1.279490847136766, 51.195202927490278],
            [-1.279186561667699, 51.193975432567029],
            [-1.274222743442637, 51.194849363825057],
            [-1.272916085099725, 51.194671304118579],
            [-1.272783123698597, 51.194208284687001],
            [-1.27140770337608, 51.194217715548398],
            [-1.271171703092666, 51.193755851211733],
            [-1.270461030654459, 51.1936291315128],
            [-1.262295092119822, 51.19364629065867],
            [-1.25883471582582, 51.193203600311975],
            [-1.254508311395539, 51.191981974113141],
            [-1.253092484141789, 51.190510831893711],
            [-1.248563951413143, 51.191008783737011],
            [-1.241100562992209, 51.191318516747984],
            [-1.231715505009559, 51.191009107443548],
            [-1.227869124350593, 51.190488330983946],
            [-1.224762574420022, 51.189032653989308],
            [-1.221211648053756, 51.186447224489221],
            [-1.217234215000229, 51.184738263729216],
            [-1.213992693352049, 51.184110469492452],
            [-1.207087118246332, 51.181312294796477],
            [-1.206576776922561, 51.180704564284774],
            [-1.202257361788935, 51.185194650580648],
            [-1.201047884468214, 51.185634208772115],
            [-1.199649917380209, 51.185637246289275],
            [-1.198885108509329, 51.185185104524471],
            [-1.197985236154038, 51.185178941492033],
            [-1.197795856805017, 51.184219081609228],
            [-1.197000993971605, 51.184263986661776],
            [-1.196486564710227, 51.18480268199751],
            [-1.195172021405626, 51.185192900655494],
            [-1.192868634518262, 51.184688765089838],
            [-1.19167906433906, 51.184721919145851],
            [-1.19032364838791, 51.183767475810974],
            [-1.189610550844395, 51.182412819325869],
            [-1.19293700296753, 51.179379374945164],
            [-1.189002028013344, 51.176730061007262],
            [-1.181712352764265, 51.175252261592483],
            [-1.182255038585472, 51.170449743294796],
            [-1.186865176979585, 51.165073976877792],
            [-1.185588484986114, 51.163991432771425],
            [-1.189326371191646, 51.163777296725996],
            [-1.193371436726569, 51.162922218776913],
            [-1.197012950541133, 51.161084984868772],
            [-1.205993028182729, 51.161543717352941],
            [-1.207426520615318, 51.161093935549417],
            [-1.208089333205421, 51.160050835162977],
            [-1.209450032863772, 51.159337964540107],
            [-1.211261879766665, 51.15883314145956],
            [-1.214375662768446, 51.158708415355093],
            [-1.214421956537728, 51.157582007095009],
            [-1.213314647293559, 51.155173657275007],
            [-1.213713984025625, 51.152457106712347],
            [-1.213413695018834, 51.15195422395103],
            [-1.210432665516096, 51.151447668963009],
            [-1.208605562108409, 51.150436293670062],
            [-1.207483269557441, 51.150600448226136],
            [-1.203695109380784, 51.150230327902385],
            [-1.200754320928275, 51.150386523823023],
            [-1.198673493358373, 51.150006309433167],
            [-1.196064045920872, 51.148783455353637],
            [-1.190009109879681, 51.146917178169062],
            [-1.190151730888758, 51.146691561994324],
            [-1.184460841907502, 51.144314059992851],
            [-1.184283897320095, 51.143484644218844],
            [-1.184791288424064, 51.143002601436422],
            [-1.184242582378364, 51.142584236117621],
            [-1.183797159220451, 51.140686470827916],
            [-1.186581341457811, 51.138184444608882],
            [-1.178151326657374, 51.134058348003983],
            [-1.174304435502561, 51.133543881788029],
            [-1.168540586006415, 51.134807831631932],
            [-1.162355538266562, 51.134765508738866],
            [-1.160355908103395, 51.135086584550649],
            [-1.156609039040346, 51.136305956394324],
            [-1.155854585614111, 51.135980391593833],
            [-1.151027064049028, 51.136180156177588],
            [-1.151797914344579, 51.137169497887541],
            [-1.151797696474325, 51.138976035599626],
            [-1.14702384160752, 51.139736240767476],
            [-1.142706363950073, 51.141316091081464],
            [-1.14125410385604, 51.140464666666539],
            [-1.137498702884534, 51.142168046210365],
            [-1.131820611682339, 51.143625953285266],
            [-1.130774110990752, 51.141955518716578],
            [-1.130745491705424, 51.140358284749787],
            [-1.12973640341913, 51.138604489810149],
            [-1.126415415172622, 51.138027614957608],
            [-1.12600950647297, 51.138859063485356],
            [-1.125230435319449, 51.138937767861755],
            [-1.116501302405946, 51.137283241781127],
            [-1.113543161587023, 51.137103500113795],
            [-1.11203221435737, 51.136589374501561],
            [-1.109630245773702, 51.137072886103077],
            [-1.102035659255922, 51.14034467117974],
            [-1.099567290904212, 51.140698877684102],
            [-1.095058515920687, 51.140349359540075],
            [-1.095430266189132, 51.13807629392295],
            [-1.095099454057294, 51.136865175675716],
            [-1.093489282866319, 51.137049640411981],
            [-1.093601284526201, 51.134905853233128],
            [-1.094698649162891, 51.132947741703589],
            [-1.092850451844217, 51.130625108518963],
            [-1.093353363815988, 51.130340355389414],
            [-1.093327888157586, 51.129889645055982],
            [-1.092502813077352, 51.129695308787831],
            [-1.094958805509319, 51.125997828220044],
            [-1.095193997389288, 51.124052819046234],
            [-1.09379157872674, 51.121748031340417],
            [-1.091505902680829, 51.120265457892593],
            [-1.091254294136378, 51.114590265534588],
            [-1.090547907955455, 51.112002186747752],
            [-1.091616101770182, 51.109834350218996],
            [-1.093176646231175, 51.108852810019492],
            [-1.093940563800627, 51.107772459756056],
            [-1.087727435536883, 51.109487555248343],
            [-1.084970299838424, 51.109568530161823],
            [-1.080778693098201, 51.109446638937179],
            [-1.074685240333947, 51.107922052507554],
            [-1.073196286403522, 51.107876991775996],
            [-1.072560171615877, 51.107471791246383],
            [-1.072226846936795, 51.106926011583667],
            [-1.073240336357588, 51.107174141566681],
            [-1.075163625209812, 51.106926789792908],
            [-1.079287140119616, 51.10564644491069],
            [-1.080512907774152, 51.10455452899771],
            [-1.083303063197022, 51.102933534610429],
            [-1.083819401360371, 51.100872943667305],
            [-1.082872370162693, 51.099074250131892],
            [-1.085279668506451, 51.097872844559987],
            [-1.08583030692452, 51.097252183329118],
            [-1.092050401926804, 51.095319629174412],
            [-1.095407985514996, 51.094867256159013],
            [-1.098242049789182, 51.093648202169874],
            [-1.098864180826759, 51.093599043639458],
            [-1.099610693514234, 51.094057107364826],
            [-1.100458523874329, 51.094004282386429],
            [-1.101092045249922, 51.092925578452906],
            [-1.100283963810926, 51.092108256605442],
            [-1.101937779455079, 51.091504993907883],
            [-1.102267007874073, 51.091684670499937],
            [-1.105438919524032, 51.090919450155127],
            [-1.106007410465793, 51.090613562090169],
            [-1.106539371922302, 51.088645610033993],
            [-1.108141797975394, 51.088550832927723],
            [-1.10777850007362, 51.087173134557567],
            [-1.108665822597228, 51.087138536311762],
            [-1.108186134560366, 51.08483374139545],
            [-1.106458925883429, 51.081110318183192],
            [-1.10715244935369, 51.078899902461806],
            [-1.1015541836029, 51.073859075486219],
            [-1.100153021109122, 51.071549854918892],
            [-1.099150041918333, 51.070719331152496],
            [-1.094800731009395, 51.06832344782935],
            [-1.092002299877069, 51.067576964998381],
            [-1.094675908094578, 51.06535679846332],
            [-1.095002103530277, 51.06319216212767],
            [-1.094342340335653, 51.062557587352721],
            [-1.092211754027479, 51.061756030130027],
            [-1.091289760314434, 51.061544740982349],
            [-1.090143874100575, 51.061763336593437],
            [-1.088934990046255, 51.060755768824286],
            [-1.088322093026694, 51.058103633939034],
            [-1.088848152853453, 51.056382096029722],
            [-1.090916982446958, 51.055730965659365],
            [-1.090675421942688, 51.054951245611328],
            [-1.089685915856802, 51.053896831177894],
            [-1.088476045061266, 51.053386528342195],
            [-1.084955262983878, 51.05068468656993],
            [-1.083817457908782, 51.050361041550552],
            [-1.082963178350648, 51.048901171839276],
            [-1.081238330596542, 51.048904714793927],
            [-1.082098526288381, 51.046741604880097],
            [-1.081698083831736, 51.046431819046489],
            [-1.079929346018505, 51.047287477740468],
            [-1.076438877017381, 51.048203258487909],
            [-1.069478099018476, 51.047156198337781],
            [-1.067352699924599, 51.047644635203078],
            [-1.066227093386483, 51.046794859379951],
            [-1.065277557964486, 51.045066122919543],
            [-1.062211533514763, 51.044524503679455],
            [-1.059971095955546, 51.045765448179644],
            [-1.060427301337659, 51.046696233868722],
            [-1.055781891205971, 51.047425859231815],
            [-1.05378996186711, 51.047237099388539],
            [-1.055243868827091, 51.045752519451156],
            [-1.064276502718449, 51.042640952369247],
            [-1.07380098957364, 51.033969873117769],
            [-1.074517269808745, 51.032591611448005],
            [-1.073426558429542, 51.032220575832206],
            [-1.072800446577777, 51.031293886823391],
            [-1.064955611548793, 51.030939142981715],
            [-1.065067949669929, 51.029962563491686],
            [-1.064244202580563, 51.028591818684504],
            [-1.063979295272252, 51.026096992258481],
            [-1.063309729962504, 51.026066449649996],
            [-1.063563824248597, 51.024849109816913],
            [-1.062358833807493, 51.020118412464974],
            [-1.063929012255854, 51.016413516252932],
            [-1.064688775736262, 51.011668880274321],
            [-1.067361400111344, 51.011746890558641],
            [-1.067310559054306, 51.008879686154742],
            [-1.068802253948143, 51.008557960443696],
            [-1.068741705436104, 51.00638489695617],
            [-1.067942375735384, 51.003739233384046],
            [-1.069174662784609, 50.999563055512368],
            [-1.068821807188664, 50.998252737276836],
            [-1.069435854720916, 50.995908791025414],
            [-1.069104456925725, 50.994524005983081],
            [-1.069528446896358, 50.993700972790187],
            [-1.068270378949897, 50.992555196907759],
            [-1.068174432620649, 50.991867405285497],
            [-1.066782246019557, 50.990734931231572],
            [-1.066869122337687, 50.990026116911608],
            [-1.067702979747247, 50.989653289173944],
            [-1.066850553184327, 50.988535911439854],
            [-1.067522433859627, 50.985721226107287],
            [-1.0663689536107, 50.984985421116704],
            [-1.063381307598999, 50.977698258594749],
            [-1.061507744497202, 50.978355865568844],
            [-1.061261591596312, 50.977988791927281],
            [-1.060253385346489, 50.978326899545614],
            [-1.056766113486293, 50.977704394595158],
            [-1.056627642353488, 50.977437997267444],
            [-1.055544054277366, 50.977558735874531],
            [-1.054116746095076, 50.974895294137497],
            [-1.054425842706036, 50.972369096627787],
            [-1.053580733422876, 50.970922549533299],
            [-1.051757420435758, 50.969538202602784],
            [-1.045251659087905, 50.968639022394306],
            [-1.046048193655952, 50.966462139221228],
            [-1.045257700704384, 50.965495278160823],
            [-1.044677650602776, 50.963364698035264],
            [-1.04579098705436, 50.959412576939869],
            [-1.046270112533775, 50.954716977900162],
            [-1.045999494387847, 50.954444092138523],
            [-1.044374372034738, 50.954406529772783],
            [-1.043085409874136, 50.952905011299976],
            [-1.04165075170992, 50.952885157478718],
            [-1.040918353943006, 50.95250055976252],
            [-1.040497401211708, 50.952063661187353],
            [-1.040701787518912, 50.951747902017104],
            [-1.037826082283154, 50.951193690212925],
            [-1.035705160997346, 50.951524214716571],
            [-1.03632880763435, 50.949453874811496],
            [-1.037648728497555, 50.947579012526838],
            [-1.03799734771993, 50.945309459121368],
            [-1.038528304494015, 50.945170848477062],
            [-1.038483489450438, 50.943961876558497],
            [-1.037438813911162, 50.943543211072019],
            [-1.03749759614897, 50.94090257285238],
            [-1.036740724306742, 50.937855039605395],
            [-1.036978479826521, 50.935094474781565],
            [-1.037904979762586, 50.933229850862297],
            [-1.036633985760619, 50.932825499037072],
            [-1.037204352692095, 50.932011875192579],
            [-1.036605253691156, 50.931736257989854],
            [-1.0377445463309, 50.929279877460182],
            [-1.039218838243859, 50.928307323762922],
            [-1.039388917364419, 50.92778715151168],
            [-1.03713475082454, 50.924836100127393],
            [-1.03637014213119, 50.922038486459329],
            [-1.037376799496402, 50.920345384414254],
            [-1.040310222794116, 50.917793139823765],
            [-1.043136775357614, 50.91641348306996],
            [-1.043425465054934, 50.916695518505364],
            [-1.044802693225423, 50.916269749295822],
            [-1.04420408170087, 50.915700115156035],
            [-1.04530401888062, 50.913807171968124],
            [-1.044871555229297, 50.912070756806479],
            [-1.046226817509545, 50.909928098361853],
            [-1.048494486418863, 50.902649972725229],
            [-1.044483086255072, 50.90234923812536],
            [-1.041687552145461, 50.902607800241334],
            [-1.038831977147117, 50.903156263736214],
            [-1.038042045122906, 50.903601190077097],
            [-1.034132072692389, 50.904171423462493],
            [-1.033910786209318, 50.902219087866456],
            [-1.037998562579423, 50.901501049093881],
            [-1.042490247671469, 50.895920257255312],
            [-1.049147262761715, 50.891474730342651],
            [-1.047587729713778, 50.890559157636538],
            [-1.045134830845223, 50.889045443748394],
            [-1.046839269019973, 50.887592666286139],
            [-1.046651743405077, 50.886958951052925],
            [-1.04528874118341, 50.884644792474916],
            [-1.04429131382435, 50.884340773854788],
            [-1.044778549581886, 50.883802502922322],
            [-1.044511967768823, 50.883352486705292],
            [-1.041940159163994, 50.882600308166431],
            [-1.036614602231869, 50.882279513997119],
            [-1.035037879489406, 50.880639718099694],
            [-1.037912413568588, 50.880210208656244],
            [-1.039405447092676, 50.877696457778427],
            [-1.040434926898919, 50.877815533160245],
            [-1.041434567886229, 50.877934354471513],
            [-1.042430287920145, 50.875261806416724],
            [-1.04265840238914, 50.874949832407808],
            [-1.043090684866629, 50.875009130832382],
            [-1.044192539159574, 50.873254690815337],
            [-1.046508800556258, 50.874109050644215],
            [-1.047615383152226, 50.872394184891888],
            [-1.047809452161587, 50.871099921625714],
            [-1.045712266483234, 50.870589099946564],
            [-1.046403570029272, 50.869653213799261],
            [-1.045565659916715, 50.869277666457521],
            [-1.046036440042468, 50.867587290841421],
            [-1.046455004724414, 50.866922553058934],
            [-1.047452796502495, 50.866981956750976],
            [-1.047396706509342, 50.865759391799834],
            [-1.054632288648507, 50.867458474038166],
            [-1.054788591229864, 50.866404896991732],
            [-1.053539720743025, 50.866393879868973],
            [-1.054039298131566, 50.865020248309378],
            [-1.048907517671053, 50.86546685730503],
            [-1.048700871691926, 50.864588385713056],
            [-1.048829686773132, 50.863075964154994],
            [-1.050414125574321, 50.859642859505989],
            [-1.052567061359681, 50.860185525749124],
            [-1.054029975847078, 50.856560739640287],
            [-1.054354691926632, 50.855116443826901],
            [-1.062155461466503, 50.855484226977751],
            [-1.062176096010383, 50.857643540593337],
            [-1.06524858984001, 50.857675377063288],
            [-1.065752667845608, 50.858472568178478],
            [-1.067988178287886, 50.858228749549113],
            [-1.071055601981745, 50.85873250900795],
            [-1.071487915891084, 50.856566010728123],
            [-1.075646780703392, 50.856403873575985],
            [-1.084815933252379, 50.857360996206523],
            [-1.090791895168919, 50.858591128889323],
            [-1.094077081686336, 50.859309104792217],
            [-1.099169232374085, 50.858870098093362],
            [-1.099896869752573, 50.857162604806014],
            [-1.11302496480882, 50.858344006359879],
            [-1.115862051623278, 50.858280995947908],
            [-1.122111470686064, 50.858541283858358],
            [-1.122478475948308, 50.857836319976023],
            [-1.125963542405315, 50.858221271477071],
            [-1.126773063104639, 50.857782192313742],
            [-1.13234825929901, 50.858543174039724],
            [-1.132810456710532, 50.858212980349073],
            [-1.135443085530028, 50.858986091233412],
            [-1.136904066885214, 50.85904186469476],
            [-1.137989149239129, 50.858833158509256],
            [-1.138339695220943, 50.858160397423333],
            [-1.139927924871785, 50.858480557173493],
            [-1.140119762005005, 50.858015251188057],
            [-1.143200888685265, 50.858510019105779],
            [-1.143290930165638, 50.85910149756846],
            [-1.144544927490743, 50.866667218398661],
            [-1.147585751330716, 50.867535666976167],
            [-1.148011184170859, 50.868598105560345],
            [-1.150729499102767, 50.869393975022469],
            [-1.151713832377276, 50.870283308648382],
            [-1.150643338397726, 50.875074894986234],
            [-1.149451850538099, 50.87759854596014],
            [-1.150016255282615, 50.879389490699893],
            [-1.151703224076248, 50.878855906561533],
            [-1.152451189101851, 50.879152699212675],
            [-1.153791855140835, 50.881335939522749],
            [-1.153817203177615, 50.882291139342236],
            [-1.155594006724358, 50.883082762561813],
            [-1.159777098262375, 50.884138095681969],
            [-1.162647549245241, 50.886580424800343],
            [-1.165287467405152, 50.887937425227634],
            [-1.167231131993672, 50.889440477710998],
            [-1.169478394609958, 50.88842862814694],
            [-1.173655991491764, 50.888874623015091],
            [-1.174954898024501, 50.888019618708178],
            [-1.177125738037977, 50.887620377547023],
            [-1.180606605745857, 50.887991069668594],
            [-1.180742181144771, 50.888853511650865],
            [-1.185752013937747, 50.888298637793362],
            [-1.186142595302661, 50.887831047924095],
            [-1.188323143246348, 50.888012587978594],
            [-1.188183188648867, 50.883957752497395],
            [-1.195825931872876, 50.885007016234795],
            [-1.19725685706712, 50.884132889796042],
            [-1.198513686741626, 50.881719816831293],
            [-1.198111986297119, 50.880774634497044],
            [-1.197973821111046, 50.87590419055357],
            [-1.207349949551875, 50.87659230322744],
            [-1.208291285522966, 50.87497372620664],
            [-1.20969410705757, 50.87396436741097],
            [-1.215969107017579, 50.875057006728795],
            [-1.218572355663885, 50.873134753480322],
            [-1.217674768505046, 50.872757348460468],
            [-1.219525552888334, 50.86958635200638],
            [-1.220303255125983, 50.869224649525748],
            [-1.220483169502421, 50.868662014274541],
            [-1.223570687358069, 50.867580992061626],
            [-1.224496462722068, 50.867026905136257],
            [-1.225210438384417, 50.865875195499221],
            [-1.22478579868527, 50.865346309076891],
            [-1.225302770871344, 50.86416181627586],
            [-1.226078112192903, 50.86419213756605],
            [-1.227876755109787, 50.863177089691952],
            [-1.227874638246268, 50.862616835816056],
            [-1.229021596638988, 50.861220668031642],
            [-1.229662450337601, 50.860614299467898],
            [-1.230841695420017, 50.861309110059736],
            [-1.234550291954518, 50.862516910798085],
            [-1.23662147664372, 50.863766055849652],
            [-1.240956885275904, 50.865685466292007],
            [-1.253509470486098, 50.8723957359162],
            [-1.255254658347192, 50.873112798208453],
            [-1.255298069667476, 50.874937670764346],
            [-1.254977808804313, 50.875948192750343],
            [-1.25611718066134, 50.876427567373028],
            [-1.254837468813591, 50.878956214805179],
            [-1.256598876845608, 50.880725490523751],
            [-1.254397607127137, 50.882115196875503],
            [-1.25391855423535, 50.883095025387348],
            [-1.255383710668245, 50.884193377608675],
            [-1.254675172823065, 50.886904613179219],
            [-1.255536971169199, 50.888760780206496],
            [-1.257233151890687, 50.889381276709742],
            [-1.259412804057444, 50.889730542361143],
            [-1.25775179670485, 50.891278407496728],
            [-1.259090572471571, 50.891760816711233],
            [-1.258731826720404, 50.892864624598744],
            [-1.260331585993408, 50.893211089520527],
            [-1.260915734694008, 50.89304572785322],
            [-1.261102389755785, 50.895088218507126],
            [-1.264116706947818, 50.895546087604671],
            [-1.265258002502125, 50.896572129218953],
            [-1.265809915239396, 50.897464063776404],
            [-1.265523755440952, 50.89775721996439],
            [-1.263667609048185, 50.897245546307069],
            [-1.261151068664428, 50.900011047056218],
            [-1.260462396710553, 50.900401710011195],
            [-1.260806348196721, 50.900587086275287],
            [-1.259200237490405, 50.901611052149391],
            [-1.258954938156216, 50.902103186513905],
            [-1.259401503251782, 50.902191448288455],
            [-1.259341393155837, 50.90257414934694],
            [-1.258738196397851, 50.902677333710457],
            [-1.257672051836457, 50.902291076877987],
            [-1.256252311833699, 50.902409738407925],
            [-1.255159477729757, 50.903787625312816],
            [-1.255302678479491, 50.904082594555881],
            [-1.25466975108949, 50.904353728857622],
            [-1.25311693822537, 50.904074032765827],
            [-1.252392700309391, 50.904362555867465],
            [-1.25191511673077, 50.903635598194008],
            [-1.250561719109014, 50.903072980769501],
            [-1.250972863370944, 50.902620595611729],
            [-1.250714593722543, 50.902431989332229],
            [-1.251704071432969, 50.899854679473016],
            [-1.251630043859319, 50.899061062342156],
            [-1.250692252309586, 50.902415674702759],
            [-1.250615625928844, 50.902689755110138],
            [-1.250480471026241, 50.903173175866364],
            [-1.249150763490669, 50.902996474168617],
            [-1.248509399282144, 50.903260326890496],
            [-1.249086677672165, 50.903090483857163],
            [-1.251106643416521, 50.903323771171401],
            [-1.252504131236869, 50.904508948262588],
            [-1.253045430371166, 50.90427680687597],
            [-1.255108803239421, 50.904468037409814],
            [-1.256134254541566, 50.902946740539136],
            [-1.257324287955895, 50.902518175771469],
            [-1.258520015889922, 50.902983493627445],
            [-1.260422374108249, 50.903012641553559],
            [-1.262044737878432, 50.903751299432031],
            [-1.262184031196701, 50.904206302615172],
            [-1.261505664883371, 50.904025761460531],
            [-1.260292341628635, 50.904308542130515],
            [-1.258945392585253, 50.905034693774709],
            [-1.260331914207323, 50.90441400544664],
            [-1.261458154832243, 50.904152255817792],
            [-1.262256969707857, 50.904360535748395],
            [-1.262443934710122, 50.904138701681717],
            [-1.264453505331844, 50.905490363481753],
            [-1.264486298441324, 50.906839449968579],
            [-1.265265914514261, 50.907367722294318],
            [-1.265737825237916, 50.908380551135913],
            [-1.26634458366515, 50.908597486982572],
            [-1.266823517065885, 50.90825967756529],
            [-1.267179252776855, 50.909064942878587],
            [-1.268741863736211, 50.909643064373249],
            [-1.268059041474037, 50.910284450664363],
            [-1.268591357915371, 50.910625005001201],
            [-1.268595549042443, 50.911446947940142],
            [-1.26907303333783, 50.911656765023565],
            [-1.268617583437266, 50.912037894673979],
            [-1.268975884944632, 50.912864751987094],
            [-1.268418197906603, 50.913266128718078],
            [-1.267685801201294, 50.914039187048957],
            [-1.267593643964712, 50.915561042622933],
            [-1.266856923518058, 50.916457470952857],
            [-1.267764826344138, 50.916527912179895],
            [-1.267517078975327, 50.917267342083569],
            [-1.266490449653344, 50.918052240858827],
            [-1.265389582728475, 50.918399627538591],
            [-1.265171643000233, 50.919863137048253],
            [-1.264249522400307, 50.920510188718318],
            [-1.264842288622177, 50.920902397426765],
            [-1.264563691238864, 50.922872700916095],
            [-1.265560434262823, 50.923182019691026],
            [-1.265223626736118, 50.923880417770548],
            [-1.265828098749922, 50.924797857039316],
            [-1.264718463475796, 50.925420352851098],
            [-1.264686365521291, 50.926283431091178],
            [-1.265272697832332, 50.926185505213127],
            [-1.265402942387023, 50.926498364173916],
            [-1.266673548265818, 50.926860652247726],
            [-1.266311925213369, 50.927055317256752],
            [-1.267532232693339, 50.92881021797659],
            [-1.267158329596267, 50.928970637077079],
            [-1.267252484237253, 50.929408263069817],
            [-1.268750786462473, 50.930501247538537],
            [-1.268636576307678, 50.9307972848503],
            [-1.270220914627218, 50.931671371854733],
            [-1.270769870690557, 50.933512864999081],
            [-1.272570982131034, 50.934000696216842],
            [-1.273702742085079, 50.935710914916882],
            [-1.275216413414262, 50.936020664821449],
            [-1.276337673057348, 50.935848665552513],
            [-1.278881027081413, 50.936663824527848],
            [-1.27945707080272, 50.936409294708405],
            [-1.280627706412524, 50.936634127188519],
            [-1.281773491280162, 50.936255399731486],
            [-1.282217119686779, 50.936558475157199],
            [-1.28473732064586, 50.936386889084211],
            [-1.285079448744737, 50.937370961900044],
            [-1.286999002691036, 50.939626315592584],
            [-1.2867266907468, 50.942727954951934],
            [-1.28800226596328, 50.945414591330774],
            [-1.290398923899841, 50.946563122075723],
            [-1.291825090223496, 50.947416167448345],
            [-1.292477459259499, 50.948420980514854],
            [-1.291104036005782, 50.950837021384423],
            [-1.290495629496571, 50.951246081955354],
            [-1.289290820453081, 50.951274729159685],
            [-1.28722194050941, 50.952789044560056],
            [-1.285933486956486, 50.954371941853253],
            [-1.283362112496527, 50.956234721276459],
            [-1.283022249245038, 50.957781135772187],
            [-1.28197728312, 50.958698243072888],
            [-1.278346137819746, 50.960192897952041],
            [-1.276965795389805, 50.960965807876846],
            [-1.276140251545735, 50.961953462255018],
            [-1.273147393327954, 50.96221095091164],
            [-1.271771991948588, 50.962658303770098],
            [-1.269340510405004, 50.966431640195317],
            [-1.269217030812454, 50.967585497087178],
            [-1.268511518731994, 50.967531624198671],
            [-1.265669134543356, 50.968968772315179],
            [-1.26028224918827, 50.974486723939322],
            [-1.258166863435566, 50.978349050533964],
            [-1.259642853671221, 50.979227978541971],
            [-1.262746233953383, 50.980161228921908],
            [-1.266566687300739, 50.980163687560008],
            [-1.267773033493725, 50.978636246504763],
            [-1.272083195290894, 50.97910560127012],
            [-1.272862481755687, 50.977091654586943],
            [-1.275400676192336, 50.973892629617708],
            [-1.277004260188755, 50.97436297435172],
            [-1.280322362324615, 50.974398743831827],
            [-1.284957580714506, 50.973531553652137],
            [-1.290349587137797, 50.97411565852854],
            [-1.293439776376404, 50.974152368809982],
            [-1.295242859259483, 50.974629064256668],
            [-1.295150296444175, 50.976133836267856],
            [-1.290491142589075, 50.9823077067756],
            [-1.290586468328715, 50.982876605541399],
            [-1.293162112464602, 50.983720412842089],
            [-1.295890804368852, 50.982834047835759],
            [-1.30177840485499, 50.982749773525669],
            [-1.312018935282592, 50.984725888346816],
            [-1.313418802749051, 50.984569560003628],
            [-1.3153107726213, 50.985146281322237],
            [-1.31640510804897, 50.985132005055974],
            [-1.31928196073661, 50.987144208410413],
            [-1.322739433544648, 50.987555464417142],
            [-1.323305205380775, 50.985878064586998],
            [-1.323103160664118, 50.98390036727676],
            [-1.327689298297515, 50.980127554515185],
            [-1.32854140052204, 50.980603654962131],
            [-1.329106575904472, 50.982111328188509],
            [-1.330402068454676, 50.983755373010119],
            [-1.333927856661147, 50.983363669612338],
            [-1.335482776716131, 50.983514601059937],
            [-1.337658208779655, 50.984310187234136],
            [-1.339511373797671, 50.983926808720192],
            [-1.34247720071398, 50.9849497802687],
            [-1.344068221900187, 50.984869696133757],
            [-1.345046638976632, 50.984081156070808],
            [-1.344929903351885, 50.982556292738998],
            [-1.346577149105146, 50.981407295080928],
            [-1.345631440690693, 50.985551089325654],
            [-1.342776490826649, 50.99089632458368],
            [-1.34342248275323, 50.991261448786901],
            [-1.343564872820012, 50.991975343689134],
            [-1.345854598287501, 50.992584375643972],
            [-1.346871786009401, 50.991073051458073],
            [-1.349236119080116, 50.993275881789103],
            [-1.349734612599894, 50.994413492883794],
            [-1.350535259539113, 50.994629265187399],
            [-1.351191948913039, 50.995359495378352],
            [-1.351122800655416, 50.996742136804812],
            [-1.353923758777897, 50.997092168856696],
            [-1.353478489129083, 50.998166092077703],
            [-1.354539058741976, 50.998449817535416],
            [-1.356224252371563, 50.997774793156019],
            [-1.356537631838771, 50.998402386849314],
            [-1.35714840869825, 50.99844351559652],
            [-1.359686553204211, 50.998640891782884],
            [-1.363508192519322, 51.000021408817425],
            [-1.367330869218526, 51.00029394832405],
            [-1.369360487658458, 51.00153596892541],
            [-1.378857223084818, 51.002954556067635],
            [-1.384688852749969, 51.004265009361674],
            [-1.386416686094182, 51.00423002944266],
            [-1.389071301148285, 51.003622563458386],
            [-1.392985156969756, 51.004368635149021],
            [-1.393410174855261, 51.006422891826752],
            [-1.394708130483849, 51.009089550884511],
            [-1.394384904525985, 51.009720936529291],
            [-1.397129888536934, 51.009195585956512],
            [-1.398534197363809, 51.008289199672618],
            [-1.399029566327289, 51.008450012403827],
            [-1.39866540326862, 51.009165727237644],
            [-1.399807611563967, 51.009791168521936],
            [-1.404898464520054, 51.010733513512186],
            [-1.405271532901756, 51.010541177993595],
            [-1.406019656356083, 51.011339902824858],
            [-1.411177520441053, 51.012529602628931],
            [-1.411256625656456, 51.013487682606382],
            [-1.410757224689097, 51.013975247553923],
            [-1.411208724531178, 51.014118701788171],
            [-1.419001101255274, 51.015451682627472],
            [-1.421035680984737, 51.013824277018671],
            [-1.422060447275118, 51.014527150948766],
            [-1.422519128126172, 51.015358508478727],
            [-1.424539146233418, 51.015934984783748],
            [-1.42607754110385, 51.016843576321918],
            [-1.426667766584985, 51.021814718318602],
            [-1.427680447418565, 51.022593916800901],
            [-1.42957567870173, 51.023145414557341],
            [-1.429994892618806, 51.024073665334846],
            [-1.43309044558389, 51.025587731339151],
            [-1.437993174715685, 51.024124976258527],
            [-1.439694913360452, 51.024419998176917],
            [-1.441901626854458, 51.025173315820624],
            [-1.443632464927456, 51.027093324894714],
            [-1.444800692140046, 51.027537701625114],
            [-1.444875703174566, 51.028301502482037],
            [-1.446179907998417, 51.030023414364102],
            [-1.446496698241595, 51.031682191007235],
            [-1.447099165066272, 51.031865786244104],
            [-1.446821490099374, 51.033868850504739],
            [-1.44717620367556, 51.034621382776699],
            [-1.448395390644662, 51.03595799660247],
            [-1.449927410861651, 51.036777215680004],
            [-1.449851580805277, 51.037159030214326],
            [-1.451958468331853, 51.037976429627385],
            [-1.453028904939651, 51.039003862131374],
            [-1.455889056691998, 51.040305797257638],
            [-1.456514147702472, 51.041128798254981],
            [-1.454155390661157, 51.044492604608706],
            [-1.452047865822788, 51.045394562136146],
            [-1.450991817057575, 51.045432771537371],
            [-1.448627282543353, 51.047067228166824],
            [-1.448791822191478, 51.047627321852772],
            [-1.448026732980834, 51.04814616259354],
            [-1.447408253191273, 51.050130528333362],
            [-1.446499472191868, 51.051209795958371],
            [-1.447128139987539, 51.052210009781497],
            [-1.446306711535189, 51.052180945025484],
            [-1.445124911555598, 51.054133853784464],
            [-1.442729495277098, 51.056266213226209],
            [-1.440623171401091, 51.057597798835246],
            [-1.436054971130682, 51.059546057597586],
            [-1.434361828556904, 51.05973031430797],
            [-1.432750153084031, 51.060369948827329],
            [-1.430602479853829, 51.060321744275839],
            [-1.42942408426329, 51.060707163072834],
            [-1.423472015892342, 51.060726529410893],
            [-1.415480751836452, 51.062678640787581],
            [-1.416719730986335, 51.063334066402419],
            [-1.413745614401523, 51.066625639508672],
            [-1.41171903377364, 51.069557720666872],
            [-1.41154671441721, 51.070541502663943],
            [-1.412482286268496, 51.071386979442003],
            [-1.412408697908971, 51.072464777446896],
            [-1.410423522507254, 51.074919554943889],
            [-1.410086923401491, 51.076695619393234],
            [-1.410127997075849, 51.07784413266014],
            [-1.415866852221, 51.077133775313264],
            [-1.415465358278036, 51.078297160409228],
            [-1.412608147466068, 51.081968753418238],
            [-1.411974398536157, 51.085565149185562],
            [-1.414678008627726, 51.091262678479879],
            [-1.414300740485866, 51.094114012517451],
            [-1.414546354058505, 51.095697867242123],
            [-1.420943148333477, 51.0947315753181],
            [-1.429987447447325, 51.094436991991799],
            [-1.43118565812804, 51.098152998446075],
            [-1.436304277906503, 51.097894522371163],
            [-1.434556354873974, 51.100114347552974],
            [-1.433645752806745, 51.102172746863943],
            [-1.434431406964416, 51.105206908603961],
            [-1.435973661979836, 51.107817585852906],
            [-1.436283608488605, 51.11204809177741],
            [-1.433129899976777, 51.115511917454356],
            [-1.431428407757398, 51.116791336799096],
            [-1.420462975544569, 51.110645346267695],
            [-1.418450819752619, 51.109915088862095],
            [-1.417254329851333, 51.109756268162748],
            [-1.413738591417047, 51.11113428648909],
            [-1.414377922927708, 51.112881068876924],
            [-1.411564328718676, 51.110689948091974],
            [-1.408378822282235, 51.111694492750864],
            [-1.407547422614411, 51.111135468716277],
            [-1.403681592192471, 51.113045546159285],
            [-1.405202213831056, 51.114777983970214],
            [-1.40411555897862, 51.115181597551732],
            [-1.407119136464593, 51.119826019529725],
            [-1.407307957300671, 51.120478907272464],
            [-1.407000569313699, 51.12060593743837],
            [-1.399200278230083, 51.122735947682848],
            [-1.391545911055006, 51.123601001395137],
            [-1.388017203508026, 51.124632880288956],
            [-1.380264948620168, 51.124123582211652],
            [-1.376036086098669, 51.127359862199015],
            [-1.374012661439511, 51.12777887854611],
            [-1.37023573103859, 51.129146998038358],
            [-1.377777519827681, 51.136336103077625],
            [-1.370906278975607, 51.14143477046013],
            [-1.369838195255398, 51.142506280623863],
            [-1.369247927715941, 51.144492159438457],
            [-1.368056079826286, 51.146348897646398],
            [-1.36408106542244, 51.149732680711331],
            [-1.35949606587492, 51.15231179288724],
            [-1.350760472293337, 51.155240944177898],
            [-1.35025067458071, 51.154684200143883],
            [-1.348226932094872, 51.15553169349306],
            [-1.347710162732959, 51.159529406773018],
            [-1.341907708155876, 51.159091376100996],
            [-1.336891016467417, 51.159110715235762],
            [-1.336276503439604, 51.160679053638901],
            [-1.337105526040066, 51.161306006965937],
            [-1.336037235968034, 51.16210564424329],
            [-1.335527626280956, 51.163235758101798],
            [-1.33592820324228, 51.163425067782086],
            [-1.335730630595034, 51.163838482851546],
            [-1.337346418836852, 51.16445640898872],
            [-1.336403287997045, 51.165200107646527],
            [-1.336514337975097, 51.165634156925705],
            [-1.337500232443978, 51.165999426464104],
            [-1.337234342130019, 51.167388100654286],
            [-1.334396650537352, 51.167791028201329],
            [-1.332826981234694, 51.168630945195751],
            [-1.332377500013662, 51.168458431159664],
            [-1.328657703321421, 51.171685100321533],
            [-1.330209585876213, 51.173271206428147],
            [-1.331199090163368, 51.173691400830812],
            [-1.331038970816768, 51.173981829706399],
            [-1.329744328239672, 51.1754680099816],
            [-1.328730624074233, 51.175333613335887],
            [-1.325421980495, 51.180426595430447],
            [-1.31040825792569, 51.190911098243809],
            [-1.308985686822635, 51.193411498689294],
            [-1.308838419442938, 51.194742355667508],
            [-1.308743230064216, 51.195652690699106],
            [-1.307175295266543, 51.19542311510034],
            [-1.306493423580818, 51.194894839260101],
            [-1.305570098331017, 51.195579057426116],
            [-1.30467957214294, 51.19560344482484],
            [-1.300975944220348, 51.196730573499089],
            [-1.29977348342632, 51.196749463568828],
            [-1.298921198984774, 51.19698714813962],
            [-1.295107214346501, 51.195949936560801],
            [-1.295102106908084, 51.195065085190905],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000095",
        LAD13CDO: "26UB",
        LAD13NM: "Broxbourne",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.012691211944449, 51.780514972801932],
            [-0.012479889263966, 51.779088818481355],
            [0.000277602967494, 51.77974915990503],
            [0.005546739439036, 51.780063129244141],
            [0.003414510051876, 51.777615812419768],
            [0.001977719385992, 51.775744712160098],
            [0.002778303770531, 51.774018935186341],
            [0.004132065296772, 51.772806174996404],
            [0.005202141488965, 51.77091751927302],
            [0.007282600536073, 51.769067353474362],
            [0.008643321630847, 51.768539636036643],
            [0.009168494022347, 51.767702464950411],
            [0.010450465950578, 51.767295670415045],
            [0.011103310986722, 51.766462600805383],
            [0.012022887395061, 51.766120439584704],
            [0.012054867061617, 51.764916716041228],
            [0.014001695792012, 51.764374403200783],
            [0.01371764165474, 51.764309130247355],
            [0.014484367671772, 51.76320431887131],
            [0.013107909618018, 51.762852024221402],
            [0.012055010114072, 51.760976265501668],
            [0.013361387294533, 51.758940503741897],
            [0.013447645986071, 51.758110829712109],
            [0.012965153701657, 51.757459955265993],
            [0.010051038498844, 51.755596260859953],
            [0.004143665534512, 51.755366234882274],
            [0.002255115538609, 51.754173668998924],
            [0.002887430080698, 51.753794207490913],
            [0.003236501013, 51.753562549548938],
            [0.001742169446185, 51.745915735575871],
            [0.001230901445313, 51.745131317662675],
            [-0.004951647158568, 51.742075634761008],
            [-0.006260036469766, 51.741704875896758],
            [-0.007451138494262, 51.741727777371828],
            [-0.012075557668846, 51.742995790946587],
            [-0.013006325065882, 51.742746259401926],
            [-0.013536740480499, 51.742212089688451],
            [-0.013618908514886, 51.740966240220978],
            [-0.012661188342456, 51.737605790879776],
            [-0.013773418872791, 51.73539539274222],
            [-0.012993882702031, 51.733389506289853],
            [-0.013949777234552, 51.731439038787585],
            [-0.013562466431346, 51.728382281862899],
            [-0.014146590471067, 51.723116334088438],
            [-0.015525970133704, 51.715323469192434],
            [-0.017605324615655, 51.707399417519632],
            [-0.019756993347178, 51.703270434176559],
            [-0.018522641070342, 51.698430594169913],
            [-0.016182928821951, 51.68988788111777],
            [-0.011984593353125, 51.687072408412661],
            [-0.010973541981477, 51.685549059405218],
            [-0.011828486339679, 51.683932308824701],
            [-0.011945056131974, 51.680875057638005],
            [-0.019797987240473, 51.681148906965966],
            [-0.028402879644094, 51.682329600760632],
            [-0.033418409645046, 51.682491923740407],
            [-0.043697982271299, 51.683094333567375],
            [-0.043655604668312, 51.683339119900296],
            [-0.044269305995783, 51.683432070678215],
            [-0.047186255927854, 51.68345273033438],
            [-0.048345748408971, 51.68407449295313],
            [-0.048955972992393, 51.684046863417464],
            [-0.049206796283422, 51.684269544602039],
            [-0.051720886483473, 51.683532535663097],
            [-0.056626642357636, 51.682986143290059],
            [-0.062043355948554, 51.683005505423786],
            [-0.066367746360464, 51.683842928716516],
            [-0.079020961860449, 51.68891182033645],
            [-0.083936198016322, 51.689962377771728],
            [-0.094802820528717, 51.690111263527328],
            [-0.099561479219148, 51.6911118636664],
            [-0.105804731996277, 51.691872745266288],
            [-0.106086250497245, 51.692615552608274],
            [-0.105692987987742, 51.694341127900394],
            [-0.106426139133406, 51.696744000342591],
            [-0.1075160773933, 51.705511059990229],
            [-0.107196747467095, 51.707994978058771],
            [-0.107626019115393, 51.711408167330966],
            [-0.107357167412484, 51.712785948479251],
            [-0.10560018447994, 51.71360919825532],
            [-0.108057324416797, 51.714149663239574],
            [-0.112971053897658, 51.718064788784595],
            [-0.114204106133845, 51.720336247372416],
            [-0.113897619782198, 51.720951793007544],
            [-0.10753452674784, 51.725354592130294],
            [-0.105498840341963, 51.727706531584808],
            [-0.105376476110508, 51.728901427218496],
            [-0.104590348855965, 51.729208872582824],
            [-0.103085348791811, 51.729219654855555],
            [-0.101831523048946, 51.730078848479621],
            [-0.102349219760827, 51.731721102785976],
            [-0.101824584670352, 51.731799853478606],
            [-0.101484371037549, 51.732972341788233],
            [-0.098631775801324, 51.735931426965458],
            [-0.095319434654686, 51.73555496031468],
            [-0.090158396341319, 51.735437018031163],
            [-0.081464989066811, 51.734397069408381],
            [-0.068804342176731, 51.734229232805177],
            [-0.065382399263998, 51.734698091042482],
            [-0.062840936058681, 51.73445569093029],
            [-0.058382060956725, 51.734719306793551],
            [-0.057451533863684, 51.735378349342824],
            [-0.055104477602827, 51.736983316829722],
            [-0.05510187557633, 51.74078791529103],
            [-0.055744785646652, 51.740893872928837],
            [-0.05549195890249, 51.742531682333855],
            [-0.052426532108265, 51.742372109297207],
            [-0.050713524867277, 51.744209607293335],
            [-0.050763230233588, 51.74695847711164],
            [-0.049902102909894, 51.75087472056034],
            [-0.050279460792908, 51.750892671912403],
            [-0.048907970333598, 51.761335128138519],
            [-0.048878243527451, 51.764847915817839],
            [-0.047941931989947, 51.768780873850773],
            [-0.045710955072797, 51.770166376476922],
            [-0.044632818624871, 51.770228476272791],
            [-0.038896916625982, 51.772550034647566],
            [-0.038529278168211, 51.772103278634305],
            [-0.036995630367542, 51.772673862763597],
            [-0.034384927029918, 51.772878418960566],
            [-0.033940525482778, 51.773565190132587],
            [-0.033266110211669, 51.773740946641581],
            [-0.032561645977693, 51.773674304019529],
            [-0.030349451150036, 51.771549245583202],
            [-0.02812058370392, 51.771407562806488],
            [-0.028075116820942, 51.772151361419496],
            [-0.025482515001695, 51.772106937080046],
            [-0.026067275984601, 51.774351342773357],
            [-0.024182483262481, 51.774128138789919],
            [-0.023020933010782, 51.773542093012594],
            [-0.021514208593677, 51.773690291507165],
            [-0.021272114883859, 51.775049447714139],
            [-0.020052730610557, 51.775553164127324],
            [-0.020100323467671, 51.776187922414792],
            [-0.017739917569157, 51.776661598867427],
            [-0.018901158752154, 51.776593052432197],
            [-0.019280006314756, 51.777144370045519],
            [-0.019812871619739, 51.777191115949734],
            [-0.019496258224224, 51.778462686819722],
            [-0.021057845629178, 51.778720989107192],
            [-0.019832660248696, 51.778957534979462],
            [-0.019879011561592, 51.779719962591393],
            [-0.018168366262558, 51.779686636683465],
            [-0.018118116446733, 51.780535560740603],
            [-0.012691211944449, 51.780514972801932],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000096",
        LAD13CDO: "26UC",
        LAD13NM: "Dacorum",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.465890883255925, 51.850798654738675],
            [-0.46412893787114, 51.850139052864805],
            [-0.461207808564104, 51.852605962577869],
            [-0.457899407306761, 51.850590762746336],
            [-0.454087531934322, 51.85302423736605],
            [-0.447548811298433, 51.847593461962681],
            [-0.439010878269345, 51.851827068093968],
            [-0.432749681731318, 51.848118358249167],
            [-0.431677920265746, 51.848879160543241],
            [-0.425259380464357, 51.846712726484483],
            [-0.424937485456602, 51.846701223458687],
            [-0.424872522001104, 51.8494356374541],
            [-0.423509184039358, 51.849394895196589],
            [-0.423472228383083, 51.849704614542496],
            [-0.421655780008451, 51.849542683303724],
            [-0.421525438483533, 51.850197330653238],
            [-0.419355171505576, 51.84873760463325],
            [-0.413954796078734, 51.846492511392526],
            [-0.412009287788878, 51.846165939416792],
            [-0.409200517993809, 51.842942211224589],
            [-0.404883303035609, 51.840599839241413],
            [-0.408624312259175, 51.838781125310902],
            [-0.41271069830255, 51.838111605234985],
            [-0.416300304989, 51.835752898834073],
            [-0.4176123331508, 51.831986826232047],
            [-0.417960516156656, 51.828677146998125],
            [-0.419962386574681, 51.825879744585315],
            [-0.418999067453787, 51.822723276725384],
            [-0.419079490090472, 51.821793709492368],
            [-0.419750429545424, 51.820947609128552],
            [-0.414135565199225, 51.819078169053768],
            [-0.41551793463562, 51.818219204177474],
            [-0.416981824019855, 51.817889138034175],
            [-0.417505569900194, 51.817434009405019],
            [-0.421838644112403, 51.815790093470149],
            [-0.421608788547124, 51.815226820877001],
            [-0.42290975993756, 51.814572589068838],
            [-0.424313947344258, 51.813037632534673],
            [-0.427524264724629, 51.811623036364857],
            [-0.427729359637079, 51.810648372357157],
            [-0.427193901967661, 51.809736638920327],
            [-0.428852535205122, 51.806757354450767],
            [-0.428558673614142, 51.806364982730742],
            [-0.427968528611875, 51.806442516778063],
            [-0.425227593488979, 51.804846666306858],
            [-0.424853062106822, 51.804897399061822],
            [-0.42292000202583, 51.803265555074006],
            [-0.420975751408443, 51.802248566888245],
            [-0.415946639751268, 51.802062233810481],
            [-0.411992074818889, 51.800271676468952],
            [-0.412462950613026, 51.799128881476499],
            [-0.409386088017527, 51.797298827311963],
            [-0.41145164675887, 51.795304492096712],
            [-0.412184122181996, 51.793318202239242],
            [-0.41368385910849, 51.793307873519908],
            [-0.413391725550558, 51.79117826560514],
            [-0.416432573068282, 51.790841604169003],
            [-0.41974557940501, 51.790972500166994],
            [-0.427328626024473, 51.792068667710055],
            [-0.429973745929531, 51.790432441679989],
            [-0.434920152304608, 51.789034523192598],
            [-0.440607439037528, 51.786871133359824],
            [-0.43941404936581, 51.784522825449201],
            [-0.436919143137713, 51.782378405809574],
            [-0.434648063910561, 51.780182061606119],
            [-0.432982347666698, 51.779640144228026],
            [-0.430347392137968, 51.777537773612011],
            [-0.4314811002091, 51.77665552102728],
            [-0.430730174234477, 51.773272665478459],
            [-0.430845100854258, 51.771881365072211],
            [-0.430103259104178, 51.770203475581233],
            [-0.428465916114203, 51.770233750886682],
            [-0.429761512382501, 51.767897900325927],
            [-0.425912798107186, 51.766383454495276],
            [-0.422854443564166, 51.765982787500526],
            [-0.423379618498425, 51.762472220911278],
            [-0.422841792721673, 51.760149603554005],
            [-0.423484057933103, 51.756270139985439],
            [-0.42441290155128, 51.753810731641991],
            [-0.425724569318098, 51.752879665487512],
            [-0.42376848030978, 51.750681014757397],
            [-0.421046906638512, 51.748754393478869],
            [-0.424066455650842, 51.745699017584208],
            [-0.424589571893338, 51.745818429161027],
            [-0.426579222822236, 51.744336245665878],
            [-0.423792674330782, 51.742619224852589],
            [-0.422102366781775, 51.740830537528836],
            [-0.425347097717291, 51.740112444034871],
            [-0.425051469263209, 51.739533901405956],
            [-0.426506912635697, 51.739204511791819],
            [-0.426116341296919, 51.738440366992499],
            [-0.43493368155077, 51.735924397804965],
            [-0.434568722581755, 51.735337762400611],
            [-0.438331115724511, 51.73445715970697],
            [-0.440470033221595, 51.733448800012908],
            [-0.439604060579078, 51.732396944723128],
            [-0.44025369177463, 51.732203251411676],
            [-0.440690270452634, 51.732393378540593],
            [-0.442394285486334, 51.731394498990412],
            [-0.439909426715909, 51.728196395219712],
            [-0.445009293827556, 51.726590600241046],
            [-0.447649425324665, 51.726067110482404],
            [-0.447764809371088, 51.726418418770294],
            [-0.448459556969296, 51.726299009835309],
            [-0.450106219634209, 51.725525857509211],
            [-0.450051417385386, 51.722682801365359],
            [-0.449339283771029, 51.720476688752015],
            [-0.4484631482673, 51.71921525128451],
            [-0.446683575725485, 51.718380671836982],
            [-0.445969982309117, 51.715928138896729],
            [-0.444190738511868, 51.713612558806368],
            [-0.442957022666302, 51.712655663704666],
            [-0.44258964750774, 51.709823739608062],
            [-0.441590547193159, 51.707897912940759],
            [-0.442315231484343, 51.706140611979336],
            [-0.440283190912668, 51.701008042372962],
            [-0.443387468512899, 51.700622096422705],
            [-0.446468186418257, 51.699570355166301],
            [-0.44866498658114, 51.698338716353156],
            [-0.454465344124659, 51.697257005742422],
            [-0.455098304904066, 51.69685619872449],
            [-0.463802719372599, 51.695335609754054],
            [-0.466834460306603, 51.695879659349146],
            [-0.469660241124645, 51.696826482329136],
            [-0.471906421894981, 51.697542700319993],
            [-0.475167242097338, 51.698080520824568],
            [-0.475892874300119, 51.698534135918862],
            [-0.481201782680854, 51.698238714950804],
            [-0.48396110566572, 51.698502747364969],
            [-0.489510047354378, 51.698123726148914],
            [-0.494808307255586, 51.699017178660661],
            [-0.497267737756577, 51.699776104092365],
            [-0.502970355241205, 51.700529588640521],
            [-0.5119152341053, 51.704221978628524],
            [-0.512522272973009, 51.70530509032843],
            [-0.514126113124821, 51.706223669174555],
            [-0.515517110107201, 51.70657215153215],
            [-0.518612565855548, 51.704054839161657],
            [-0.520689928178838, 51.698177866134877],
            [-0.522383661030425, 51.696600429159339],
            [-0.523036683390546, 51.69507552977398],
            [-0.523179437770816, 51.690467200259164],
            [-0.524567300147164, 51.688051441538185],
            [-0.524364612228117, 51.68538640524978],
            [-0.523749174991171, 51.683760137084761],
            [-0.524294713637442, 51.682107991714716],
            [-0.527624129662257, 51.681674998493698],
            [-0.531145283102922, 51.680678720465593],
            [-0.531955796332999, 51.680752694499759],
            [-0.532139748453241, 51.681773771313132],
            [-0.534817139404226, 51.681685805575675],
            [-0.535072591361151, 51.680896808524139],
            [-0.536466853672885, 51.68085574023268],
            [-0.538911120545722, 51.679829623522899],
            [-0.539647808940478, 51.68026499811355],
            [-0.546306159880702, 51.680433005441927],
            [-0.546720640159814, 51.683028685559492],
            [-0.54757348462912, 51.683002366439105],
            [-0.547898185876233, 51.682528913462669],
            [-0.548718288927467, 51.682666732132553],
            [-0.549181860543688, 51.684862871379863],
            [-0.548650418782656, 51.687006256203098],
            [-0.547137581853329, 51.689209440180925],
            [-0.543810512704401, 51.69226323870982],
            [-0.542991696730413, 51.693702570782513],
            [-0.544039275399154, 51.696812344789933],
            [-0.546906790691928, 51.698630924272656],
            [-0.546772912500076, 51.701319621790077],
            [-0.547690274108942, 51.703176085925797],
            [-0.548857649158419, 51.703643697163429],
            [-0.552441463090158, 51.704033190529664],
            [-0.555353378904802, 51.70552750077276],
            [-0.557234865894538, 51.707072032106502],
            [-0.558099201912086, 51.708473673714096],
            [-0.562332030123663, 51.710958719980439],
            [-0.563232259982424, 51.711874305179037],
            [-0.562442813360735, 51.715865096294955],
            [-0.561325168346973, 51.718013043137255],
            [-0.558503527542335, 51.719293962031855],
            [-0.55586223755103, 51.721816097938927],
            [-0.55043608065738, 51.723036913745226],
            [-0.550318922216771, 51.724391429731824],
            [-0.550555099669235, 51.724702761179081],
            [-0.551687709081325, 51.724464056243583],
            [-0.551456924420517, 51.725161671602457],
            [-0.553619345574635, 51.728404665232219],
            [-0.553102565561223, 51.728400102160315],
            [-0.551577729228316, 51.730034906207777],
            [-0.550105382105395, 51.730568845709726],
            [-0.554326673661662, 51.732331083618668],
            [-0.553021977847865, 51.73332750042934],
            [-0.553742986250048, 51.734058414299263],
            [-0.554547569919416, 51.734122263265022],
            [-0.556024860621239, 51.733207977132764],
            [-0.558371346449909, 51.734101791572293],
            [-0.559562123705923, 51.733406042968397],
            [-0.560669135219349, 51.734069710090836],
            [-0.558464310222442, 51.735640523122107],
            [-0.559268063101237, 51.737325543460479],
            [-0.560342573499054, 51.737282961559146],
            [-0.563468605789091, 51.735662233138044],
            [-0.565605882220285, 51.736539856507463],
            [-0.562977410988411, 51.738272828840643],
            [-0.563722855411746, 51.739610921699438],
            [-0.566224251756951, 51.73846264477438],
            [-0.566840672221248, 51.73902944973095],
            [-0.568421643471084, 51.737535406119584],
            [-0.569268919693776, 51.737809181575784],
            [-0.570392886256518, 51.736796001067532],
            [-0.569664326202553, 51.736065099244499],
            [-0.569956779058155, 51.735930185128431],
            [-0.572785970522558, 51.735857573902074],
            [-0.576192881454119, 51.737815936121329],
            [-0.579455272604808, 51.741251591254624],
            [-0.580834861355899, 51.7436321831198],
            [-0.582521647088682, 51.745319603679526],
            [-0.584168416213545, 51.746761942167147],
            [-0.586403211005555, 51.752109238162674],
            [-0.595292791265164, 51.751308544857579],
            [-0.600890748837523, 51.751349233367961],
            [-0.605175458704912, 51.749861675717021],
            [-0.609123645421661, 51.749464270495473],
            [-0.611140313954796, 51.748089008523181],
            [-0.612959929552517, 51.747420831758241],
            [-0.613563387035, 51.748292052974513],
            [-0.613790453632968, 51.750906817157592],
            [-0.617309883816319, 51.752643201496795],
            [-0.620226335627546, 51.752876187211065],
            [-0.623250992911655, 51.754363807437393],
            [-0.623733072552373, 51.753470285061354],
            [-0.625552577574391, 51.754381749622475],
            [-0.626243305171052, 51.75362912837948],
            [-0.62851184125216, 51.75459344948942],
            [-0.629903448385375, 51.753993748182978],
            [-0.629402511603296, 51.753360289804917],
            [-0.631065153158192, 51.753042476550057],
            [-0.643690306658738, 51.754080799662809],
            [-0.646935226725619, 51.756031619146057],
            [-0.647803234119228, 51.757287849781036],
            [-0.64923665358719, 51.757611843792759],
            [-0.648620513808668, 51.758546187891113],
            [-0.651339652869262, 51.759924363222765],
            [-0.652478080403538, 51.75935565903643],
            [-0.659374616287973, 51.763480745676013],
            [-0.663632896111554, 51.764824922154503],
            [-0.66670775609033, 51.765125987009561],
            [-0.668750944106491, 51.765720109254133],
            [-0.6734947123004, 51.768464920510567],
            [-0.676024748116232, 51.771250266678038],
            [-0.676119051398109, 51.773278036092968],
            [-0.677397244827542, 51.775862221038835],
            [-0.676211017645111, 51.778457333129552],
            [-0.67751269393161, 51.780937475947518],
            [-0.679158406660098, 51.782066435976098],
            [-0.679426589303893, 51.784748041392959],
            [-0.681525214955044, 51.788051720251204],
            [-0.684113525212477, 51.788987963872167],
            [-0.684002115264575, 51.789278943162238],
            [-0.690216416666293, 51.792331728475567],
            [-0.690131632359118, 51.792555573135679],
            [-0.688792734523667, 51.792624272784401],
            [-0.683995738896219, 51.793782748689381],
            [-0.682215147497865, 51.794576544843324],
            [-0.684048197364387, 51.796265007368966],
            [-0.682675183511835, 51.797201840943714],
            [-0.688781699438388, 51.803104707038742],
            [-0.692576327548958, 51.805794064917038],
            [-0.696304557634242, 51.810146893903436],
            [-0.699163585811761, 51.811962470598225],
            [-0.701172252744036, 51.814854764346329],
            [-0.709319796145194, 51.820531780568999],
            [-0.711522524260631, 51.81909840825864],
            [-0.711875514669047, 51.818320013619015],
            [-0.714369457684303, 51.817301588226186],
            [-0.719241076471542, 51.816130094610671],
            [-0.721376727553311, 51.816637972945358],
            [-0.723426575323184, 51.817616934662595],
            [-0.728985735455627, 51.821753881759015],
            [-0.730455324589194, 51.825168533657219],
            [-0.731521192394557, 51.826535048770182],
            [-0.737875759217426, 51.830939989951936],
            [-0.736730287466701, 51.831889781060291],
            [-0.737906746716018, 51.834036982767408],
            [-0.739135089658428, 51.834746094162064],
            [-0.744823164668777, 51.837609559514704],
            [-0.74527233309149, 51.838368733851951],
            [-0.7457017407216, 51.842085881421802],
            [-0.744107821629771, 51.84245371032965],
            [-0.741444118114355, 51.842558317526226],
            [-0.736359258533629, 51.842398609644981],
            [-0.734607283901708, 51.844103439359159],
            [-0.732915681124286, 51.84758290590301],
            [-0.731870006359702, 51.848113823487829],
            [-0.731211708849816, 51.848962711595568],
            [-0.727419533225658, 51.850354091550031],
            [-0.725318630956502, 51.849790962134158],
            [-0.722639869660476, 51.850000182064669],
            [-0.722070926649328, 51.850814023875962],
            [-0.722021843347222, 51.852547042154605],
            [-0.722571448283226, 51.85410943021143],
            [-0.722112131187056, 51.85469068453456],
            [-0.72029234565914, 51.856139208232705],
            [-0.717471186276913, 51.85710384270979],
            [-0.714648828230798, 51.857647597077126],
            [-0.714397667944163, 51.857340042951726],
            [-0.710467240632555, 51.857784367440594],
            [-0.709367087764777, 51.856242858580515],
            [-0.707100798364956, 51.854406198116763],
            [-0.703935867525168, 51.855857664372735],
            [-0.701751966510831, 51.857523981031328],
            [-0.699802486611201, 51.858004177376195],
            [-0.696382143535261, 51.858126378425141],
            [-0.694604689092741, 51.856422569955903],
            [-0.692395010639447, 51.857061602917291],
            [-0.6883079916033, 51.850598894280765],
            [-0.686792806657632, 51.849232370099458],
            [-0.691776992707692, 51.845073591633337],
            [-0.693835920299881, 51.842500627741998],
            [-0.695475998175554, 51.841102666944927],
            [-0.69204338451498, 51.840289485700552],
            [-0.690829098189858, 51.840417144944816],
            [-0.690263278994673, 51.841257845269219],
            [-0.687078659428588, 51.839107546654752],
            [-0.683270158821925, 51.835466579872396],
            [-0.681826976811316, 51.83180525888644],
            [-0.679947426807827, 51.82957676442917],
            [-0.679830888255321, 51.828797690270861],
            [-0.678667941253499, 51.827328905212795],
            [-0.678451086862057, 51.826061361801585],
            [-0.676828266109838, 51.823948991146764],
            [-0.672477926500107, 51.819660463442069],
            [-0.669220124572765, 51.818547360631428],
            [-0.668840938471985, 51.817186245765512],
            [-0.667239460718086, 51.815802293396516],
            [-0.66665494023915, 51.815994381524256],
            [-0.664938307798164, 51.815516339008255],
            [-0.662926712330219, 51.816231693855293],
            [-0.659056704244042, 51.815427848005164],
            [-0.656254528359529, 51.815844544624973],
            [-0.651140942071664, 51.815250112600104],
            [-0.647795665041629, 51.813597712637069],
            [-0.644406007139635, 51.815753533652291],
            [-0.640445166932006, 51.816630349417096],
            [-0.637244716403611, 51.817929457454035],
            [-0.635974205173633, 51.818011838525869],
            [-0.634437323202955, 51.818602733952993],
            [-0.632964927399433, 51.81993796196398],
            [-0.627626129200656, 51.817295205366058],
            [-0.626784983479851, 51.817236832830289],
            [-0.625676218580323, 51.815865253748669],
            [-0.624450320028323, 51.816246552058409],
            [-0.622770443384048, 51.815861834287794],
            [-0.621833929326739, 51.816029796517299],
            [-0.620698671304617, 51.814914119281028],
            [-0.618414088343284, 51.8145240436954],
            [-0.615579349606762, 51.814951078778293],
            [-0.614318928853458, 51.813475999947045],
            [-0.613699800709298, 51.813654829706657],
            [-0.612448773312194, 51.812916252883177],
            [-0.6118940783739, 51.812036622729401],
            [-0.606450530652997, 51.812342700828687],
            [-0.603206857668173, 51.813281608809589],
            [-0.597630076143591, 51.814274454348798],
            [-0.594857129081195, 51.814014768076731],
            [-0.594508372002512, 51.811142275027663],
            [-0.591679335544969, 51.808127708074771],
            [-0.581850056515068, 51.806897322594722],
            [-0.581598048742233, 51.807578546834058],
            [-0.577240709897123, 51.811771762126099],
            [-0.577394608321799, 51.81209012934508],
            [-0.574327479768094, 51.816242189506895],
            [-0.576267313048267, 51.816928371377216],
            [-0.575195335775696, 51.818809026130317],
            [-0.574157211876517, 51.818603125232286],
            [-0.573697361828259, 51.819198190051303],
            [-0.572579442153175, 51.819038063976365],
            [-0.572449695495677, 51.819424927493017],
            [-0.57103542402825, 51.818955473941557],
            [-0.567169732544011, 51.819796815918849],
            [-0.566252945877064, 51.820933874724645],
            [-0.56244681127152, 51.823725173969777],
            [-0.560812710776954, 51.826373905516796],
            [-0.558820090266599, 51.824653685262156],
            [-0.558373502501948, 51.824779490114388],
            [-0.558002699455245, 51.825531140840411],
            [-0.56129501055136, 51.828862393704284],
            [-0.560494127558892, 51.8301770609174],
            [-0.557163261479183, 51.830958083839185],
            [-0.555402553539434, 51.828382850414954],
            [-0.553653958878633, 51.826704203516833],
            [-0.548523639443665, 51.824149420818756],
            [-0.544877006148601, 51.820641678014688],
            [-0.541709731299278, 51.818436359304435],
            [-0.543735779061273, 51.816976042116771],
            [-0.542771330471223, 51.81575112126243],
            [-0.543204496134771, 51.815455267090158],
            [-0.541189860637143, 51.814721751511051],
            [-0.541117346475515, 51.814990601878975],
            [-0.536950074152442, 51.81411699532881],
            [-0.532533419117939, 51.80891153580064],
            [-0.532242910237073, 51.808745160923152],
            [-0.530459246056831, 51.809443119095413],
            [-0.529472999013375, 51.808279855403917],
            [-0.524124540203058, 51.805803080356469],
            [-0.521882579641755, 51.805479094739432],
            [-0.520837709360535, 51.805761783347954],
            [-0.519753106480383, 51.805087246132565],
            [-0.517539723948501, 51.808448334326187],
            [-0.510721841202331, 51.811099262389519],
            [-0.506392968257879, 51.81395767279151],
            [-0.502307576904884, 51.815450489703231],
            [-0.503992482829027, 51.816572518480513],
            [-0.504300817519075, 51.817688712826879],
            [-0.500900296927145, 51.819386208780315],
            [-0.502510770091943, 51.82056845227428],
            [-0.501618718352606, 51.821478739008057],
            [-0.50337170532251, 51.82243529571906],
            [-0.508835410806198, 51.824039575246879],
            [-0.509797517896999, 51.825093005732676],
            [-0.511218126590871, 51.825885179822748],
            [-0.507993908512203, 51.826452160725097],
            [-0.50604195462486, 51.827291483490349],
            [-0.50545061445514, 51.831653925075017],
            [-0.501843653278456, 51.836786341896449],
            [-0.496757511708226, 51.837316700438464],
            [-0.494005313128757, 51.835626128008201],
            [-0.491906842812944, 51.835270146214526],
            [-0.492087160557868, 51.838344914284242],
            [-0.490958083696323, 51.842810985645691],
            [-0.479595526360556, 51.842135960510568],
            [-0.471220261482609, 51.845459536974708],
            [-0.477005099267811, 51.85015809539162],
            [-0.478323430058091, 51.853128020081762],
            [-0.4748098217858, 51.853895366557445],
            [-0.470444818562334, 51.855701769408803],
            [-0.469708042643398, 51.855890004636215],
            [-0.468273935050362, 51.853051551681048],
            [-0.465890883255925, 51.850798654738675],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000098",
        LAD13CDO: "26UE",
        LAD13NM: "Hertsmere",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.262337455017807, 51.736837780355188],
            [-0.260160492881183, 51.733976666665988],
            [-0.255158693007234, 51.729532356701277],
            [-0.252275788498031, 51.726749625851056],
            [-0.250807216195723, 51.724620028171607],
            [-0.241133992029447, 51.715735301364106],
            [-0.240626621470705, 51.712843933876869],
            [-0.241041851450999, 51.710424077904428],
            [-0.239415698858344, 51.708055474187468],
            [-0.238995182328171, 51.706137445704471],
            [-0.237406524644651, 51.70590140737761],
            [-0.235220174919594, 51.710090389389521],
            [-0.234293937745372, 51.710756277263791],
            [-0.232256368958303, 51.711375767700162],
            [-0.229766686683467, 51.711672787953454],
            [-0.227059067780466, 51.712693031276196],
            [-0.223392700179217, 51.712835473915298],
            [-0.22228125018466, 51.713124394338323],
            [-0.221071203667814, 51.713564677796526],
            [-0.218416520454786, 51.713708791211815],
            [-0.21477490013098, 51.711271494080819],
            [-0.213132686779783, 51.710703402903498],
            [-0.210815325257349, 51.710262588438816],
            [-0.207774571667384, 51.710400580269443],
            [-0.206983750075762, 51.71026711949515],
            [-0.206509421924732, 51.709634025222513],
            [-0.204760190790554, 51.709908546025943],
            [-0.202645053392163, 51.709736827567198],
            [-0.199454622924731, 51.709853430367943],
            [-0.195827441526917, 51.709196208066373],
            [-0.194637400965175, 51.708293995981357],
            [-0.19048125566719, 51.707078155872026],
            [-0.189880524239181, 51.707805361044954],
            [-0.187656885596674, 51.706984262591376],
            [-0.183350904496857, 51.706517603557231],
            [-0.181445194640285, 51.706001641406743],
            [-0.177759866587125, 51.703959956956922],
            [-0.173744842513732, 51.70263870034632],
            [-0.173369836769986, 51.703002449627085],
            [-0.17247679705366, 51.702574019536094],
            [-0.172138784750814, 51.702737814198585],
            [-0.169746450039373, 51.701484819074892],
            [-0.164521447890516, 51.700393470325999],
            [-0.163142463396128, 51.699853963897105],
            [-0.163518007750547, 51.688111486896723],
            [-0.163528600365344, 51.685919344462235],
            [-0.163631965330001, 51.682399597960185],
            [-0.172498221769114, 51.6730879372601],
            [-0.174983709155306, 51.673212012438512],
            [-0.17535466792694, 51.673446179278606],
            [-0.1756829052283, 51.673159929589701],
            [-0.1754657618335, 51.672589145753264],
            [-0.176410950670027, 51.672641593047643],
            [-0.177031442184659, 51.672676404132126],
            [-0.176736560579837, 51.67209632299835],
            [-0.182110443559578, 51.668600714230706],
            [-0.185829309693543, 51.668506251026756],
            [-0.187815400981271, 51.667875984661244],
            [-0.191091827594294, 51.663893477538572],
            [-0.192555927543839, 51.664746003689338],
            [-0.196689871010208, 51.665478576588804],
            [-0.194877116637175, 51.668302163977707],
            [-0.196184172713469, 51.668526370133776],
            [-0.196639013960932, 51.668143092547197],
            [-0.199029938314097, 51.668203161376525],
            [-0.199896403871687, 51.670166858479107],
            [-0.203377623597464, 51.670123048584671],
            [-0.202633160191303, 51.669311350207458],
            [-0.202254731348437, 51.667802058748769],
            [-0.205659210973254, 51.668597779840098],
            [-0.207984221181829, 51.666591147025102],
            [-0.209863519276418, 51.667559494279175],
            [-0.211158575635625, 51.666951570842222],
            [-0.207888601397595, 51.662857916686697],
            [-0.212159957462204, 51.661350281333128],
            [-0.213418068629271, 51.662374757903727],
            [-0.218919533997528, 51.660058301624773],
            [-0.219272297449528, 51.660796516638761],
            [-0.219825732446319, 51.660657435001831],
            [-0.22606892793393, 51.657622619395347],
            [-0.226487518443282, 51.657158649276539],
            [-0.228082807563121, 51.658588212964595],
            [-0.228513559616748, 51.659962425887969],
            [-0.228944026720489, 51.659972515460353],
            [-0.23425217370442, 51.65824951214671],
            [-0.234732016720667, 51.658997683247328],
            [-0.236702742615759, 51.658771912011346],
            [-0.238300488773912, 51.658246468355593],
            [-0.2379959392345, 51.657785994932496],
            [-0.239224049156006, 51.657396169665091],
            [-0.241018380582897, 51.656832272644493],
            [-0.241390114393385, 51.657201124696996],
            [-0.243649515307314, 51.656685515392397],
            [-0.247890488740447, 51.655244474434447],
            [-0.249372918270114, 51.656054298719866],
            [-0.250607575244082, 51.656053814233125],
            [-0.251275188845432, 51.655535916531193],
            [-0.250816087553238, 51.654806107308701],
            [-0.24988100803052, 51.654611432318347],
            [-0.251581594667769, 51.647949272694476],
            [-0.254069555168071, 51.644178096786561],
            [-0.255482697844717, 51.643284593808858],
            [-0.256196081140704, 51.643488522571452],
            [-0.257356709107825, 51.641828708670772],
            [-0.258406979886174, 51.641990864020123],
            [-0.260736767033487, 51.642670136982467],
            [-0.261628651700807, 51.643989010430651],
            [-0.263296213990688, 51.644830176573578],
            [-0.264382920242884, 51.643624206081256],
            [-0.265235110495673, 51.644017168722222],
            [-0.266791169655346, 51.64410758863346],
            [-0.268369906638191, 51.643830542074816],
            [-0.268270403644632, 51.642383131601179],
            [-0.270060920048074, 51.642084007646417],
            [-0.272890291736135, 51.642095984114178],
            [-0.273145811759153, 51.641190632828256],
            [-0.274200187947379, 51.639304288252411],
            [-0.273737222534417, 51.638670727545438],
            [-0.276498771328069, 51.638865061508433],
            [-0.278636569387813, 51.63832002286096],
            [-0.281414720238532, 51.638342733990783],
            [-0.283898038367505, 51.637791866038626],
            [-0.285523941741269, 51.636945175807064],
            [-0.287810611378942, 51.636509151489015],
            [-0.291891362340547, 51.636475068613365],
            [-0.294406538211774, 51.635646584912429],
            [-0.29615110457172, 51.635443512837789],
            [-0.29723276934667, 51.635731666757387],
            [-0.29987767300298, 51.635758303697038],
            [-0.302436414616259, 51.636351040396661],
            [-0.302746593192066, 51.636132518974748],
            [-0.303569689566782, 51.636458240164195],
            [-0.304482780394745, 51.636347337196732],
            [-0.305401092762215, 51.63710424520233],
            [-0.312342465849337, 51.638957687548455],
            [-0.316696245502833, 51.640531761236971],
            [-0.318087174809531, 51.639928537926735],
            [-0.31981673671474, 51.638216918107098],
            [-0.326570190707164, 51.636715459528787],
            [-0.330006839537504, 51.63533194211918],
            [-0.332479725008396, 51.633823139210648],
            [-0.332995252374475, 51.633832257962453],
            [-0.333112954618927, 51.6333807257583],
            [-0.33830753788097, 51.629469046036064],
            [-0.339913334357077, 51.630720085754298],
            [-0.345129773688049, 51.628327185759574],
            [-0.349245621668119, 51.62690592166534],
            [-0.359701579004706, 51.625258538307257],
            [-0.361815488048044, 51.623726122809408],
            [-0.362618442911358, 51.623533202798185],
            [-0.364357435486158, 51.624628402395118],
            [-0.369948581017276, 51.63119395911297],
            [-0.373251504703552, 51.634509289440508],
            [-0.375170101690073, 51.635813629421285],
            [-0.377446706943704, 51.637698377682099],
            [-0.379984017942095, 51.639612754331722],
            [-0.376968645705792, 51.640200559582347],
            [-0.37534316434062, 51.641565557899646],
            [-0.375819207414602, 51.641954306361335],
            [-0.371657527225703, 51.64342981871679],
            [-0.376299903937034, 51.644802447969347],
            [-0.377940590187399, 51.646334890087175],
            [-0.380736832090878, 51.648135924782558],
            [-0.380914862675663, 51.647885702680291],
            [-0.383449693833544, 51.648467341127535],
            [-0.383402916606203, 51.652342271521768],
            [-0.386925742376672, 51.657188866583709],
            [-0.385355023157332, 51.660502428812528],
            [-0.386672073348494, 51.662133699174042],
            [-0.386887801050387, 51.663061942361765],
            [-0.386634221623544, 51.663687901327968],
            [-0.385594014568134, 51.664192447640993],
            [-0.385747159837689, 51.664928302225206],
            [-0.383677102846449, 51.665846693221717],
            [-0.383093130099049, 51.668155900982889],
            [-0.384268080447208, 51.668948081901227],
            [-0.383490687421687, 51.669865362103053],
            [-0.382500065772811, 51.670434407217407],
            [-0.379276146471459, 51.670041973718988],
            [-0.374748419721857, 51.673553740927915],
            [-0.374250798676355, 51.674122344058844],
            [-0.371699213517818, 51.676471685247911],
            [-0.36883954432537, 51.678370689068167],
            [-0.369469753017519, 51.678825443460653],
            [-0.370417989042088, 51.678923127499644],
            [-0.371093694704702, 51.680690440257329],
            [-0.371065791423378, 51.681508327209528],
            [-0.369607551541083, 51.683325165824861],
            [-0.369513450082071, 51.6842131703362],
            [-0.37010428072333, 51.684518106379237],
            [-0.372412660331895, 51.6846903886196],
            [-0.378399098091339, 51.68816136962635],
            [-0.378784233390696, 51.689894049022335],
            [-0.375381928055411, 51.692506879560987],
            [-0.374230986707264, 51.689537983077742],
            [-0.371985973326852, 51.687552028710293],
            [-0.369749691561498, 51.687905873059478],
            [-0.366706418880504, 51.689175534185765],
            [-0.364167435131719, 51.689334432943774],
            [-0.362781558718863, 51.68913078959902],
            [-0.359068323242226, 51.690715526665933],
            [-0.358235191499201, 51.691469106915768],
            [-0.355762450063794, 51.691552318259397],
            [-0.355320551806947, 51.69057139702214],
            [-0.354939228294242, 51.69143198952829],
            [-0.356045811928999, 51.692995906535792],
            [-0.355963338759862, 51.694433475350358],
            [-0.35660217834557, 51.694972043707146],
            [-0.35589346298477, 51.695323606334455],
            [-0.356554089931614, 51.696099868920129],
            [-0.355472963434034, 51.69802251874075],
            [-0.35496946422738, 51.697940835775263],
            [-0.35449926479717, 51.698297528145325],
            [-0.354194040494073, 51.698893019886469],
            [-0.353490138004063, 51.699109755432374],
            [-0.353503544142233, 51.698501183973598],
            [-0.352478306190533, 51.697843881544742],
            [-0.351968041963934, 51.698426600821797],
            [-0.350961710949926, 51.698722704094585],
            [-0.35056411758661, 51.699389725362479],
            [-0.349772405693291, 51.699513487287376],
            [-0.349134559310227, 51.698908355087006],
            [-0.347977080422379, 51.698506326525504],
            [-0.346188003138392, 51.698798572948746],
            [-0.344135583755047, 51.697860568647833],
            [-0.343317629939465, 51.698188036132365],
            [-0.343399425105678, 51.697850190203845],
            [-0.342217377683953, 51.697607816026995],
            [-0.336858922604857, 51.697729903446749],
            [-0.334930972321693, 51.698216938055381],
            [-0.334096474267503, 51.698795884150407],
            [-0.333412435773953, 51.701383980638347],
            [-0.332375281397641, 51.702040967888635],
            [-0.330022395920833, 51.702310577462782],
            [-0.32942286870304, 51.702124910777563],
            [-0.329004996219789, 51.702586553779881],
            [-0.326762987989742, 51.70345834326136],
            [-0.325960328448712, 51.702188922796239],
            [-0.324730025824803, 51.69737502976129],
            [-0.322413851458494, 51.693526650060903],
            [-0.318331595878511, 51.695064372498166],
            [-0.318100783385061, 51.695552933637778],
            [-0.316198320102767, 51.696675764709923],
            [-0.31331948493582, 51.69619025903247],
            [-0.311346781523139, 51.6972913219265],
            [-0.309253696953708, 51.69789245931792],
            [-0.306350774572437, 51.697703174378887],
            [-0.300044111094707, 51.696519545784462],
            [-0.301053678502225, 51.698578947623972],
            [-0.299291725058433, 51.699374429346307],
            [-0.301141075301423, 51.704243414527291],
            [-0.301999624850559, 51.708061265404879],
            [-0.295245848216937, 51.709149514034834],
            [-0.293993029497772, 51.709786850209056],
            [-0.295783246394574, 51.711179624602352],
            [-0.286515150272073, 51.713132780681534],
            [-0.283869950133997, 51.714256847743286],
            [-0.283521885935663, 51.714809271404789],
            [-0.279798173093782, 51.717303163961716],
            [-0.278854738019388, 51.719236130029429],
            [-0.276977546098838, 51.721279489800509],
            [-0.28086314622454, 51.720828353169289],
            [-0.282190265334419, 51.721414262603602],
            [-0.283269294438359, 51.721403056607869],
            [-0.283997377496699, 51.721063001848897],
            [-0.286146584187716, 51.720814717154482],
            [-0.286555477241419, 51.721181261974216],
            [-0.284239501611597, 51.72285146063286],
            [-0.283695455199162, 51.724180633513747],
            [-0.289554127904086, 51.728002256624848],
            [-0.289114968617011, 51.728818635041023],
            [-0.285208692815025, 51.731675117012436],
            [-0.284318881460471, 51.73299295082716],
            [-0.283460480672143, 51.733480372312165],
            [-0.279404203171904, 51.734698819793216],
            [-0.275948977957935, 51.735301899864631],
            [-0.274440044579505, 51.735184436629396],
            [-0.273259659397758, 51.73541437919765],
            [-0.270439079198082, 51.737067900992791],
            [-0.266150874413869, 51.738197954816435],
            [-0.264459279132765, 51.738045297217347],
            [-0.262337455017807, 51.736837780355188],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000099",
        LAD13CDO: "26UF",
        LAD13NM: "North Hertfordshire",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.149960726432261, 52.061224860395406],
            [-0.150238216837761, 52.059169208831072],
            [-0.152086166633357, 52.057407947151646],
            [-0.152667260422093, 52.055839007735209],
            [-0.154972622441958, 52.054262908088013],
            [-0.155147989352171, 52.053050869325446],
            [-0.154557512433701, 52.052337569201221],
            [-0.152166140752458, 52.051016069610995],
            [-0.149053343946682, 52.05021550161846],
            [-0.146062193570054, 52.047535688094598],
            [-0.144603520245459, 52.046980414727365],
            [-0.141701142236131, 52.047234100548273],
            [-0.136748099806527, 52.046020216578931],
            [-0.133757448561666, 52.046222855753463],
            [-0.131330613466895, 52.043291733748006],
            [-0.131600697507182, 52.04261983468853],
            [-0.126856687970493, 52.036075807702701],
            [-0.124705135979497, 52.034846568430766],
            [-0.123775127579901, 52.033624160991224],
            [-0.123130000664469, 52.029986587491173],
            [-0.122553080988774, 52.029943225690971],
            [-0.122366637003971, 52.029626439793077],
            [-0.126595364194867, 52.02091235427622],
            [-0.121026738300019, 52.019511751745625],
            [-0.112431335188443, 52.026111015299762],
            [-0.112263744067788, 52.025902416778635],
            [-0.107722091124722, 52.028345466140578],
            [-0.079116797022558, 52.037876958767896],
            [-0.066783836088023, 52.042526341597735],
            [-0.05689999949413, 52.045489321349969],
            [-0.0542409036602, 52.046611683341581],
            [-0.051000613519946, 52.049491277950224],
            [-0.035753646539027, 52.058782072262694],
            [-0.030660226768363, 52.061225574491047],
            [-0.030175202017152, 52.061937717779223],
            [-0.028826463458418, 52.061889086652187],
            [-0.024441431248424, 52.063305607421256],
            [-0.022001122707031, 52.063593772575523],
            [-0.019309588823865, 52.063525182165428],
            [-0.016301341426714, 52.062998905888605],
            [-0.013326523744452, 52.061457928875399],
            [-0.01258794392727, 52.061631606571581],
            [-0.011605677768075, 52.06062412588058],
            [-0.006164086413691, 52.056929108156019],
            [-0.003697883262198, 52.053517172511143],
            [-0.001412290079274, 52.051654859211915],
            [0.002288748315145, 52.050139820842773],
            [0.007157135952598, 52.049522861664144],
            [0.01126104502063, 52.049649799737026],
            [0.024827341986119, 52.05142084213584],
            [0.033619811827544, 52.051819717194341],
            [0.040277608328789, 52.053316877131344],
            [0.041540538456282, 52.049025598052815],
            [0.041596182934743, 52.045255184472452],
            [0.04333577732291, 52.041862911567911],
            [0.043005303476468, 52.041490978352705],
            [0.047611811638534, 52.036739645335516],
            [0.049637834210552, 52.03382516289777],
            [0.050427616303262, 52.028462952830594],
            [0.052069536160517, 52.024620832663629],
            [0.054367910346835, 52.022482922499847],
            [0.058258530992387, 52.020554542538029],
            [0.060138087242603, 52.018637844368342],
            [0.058962471111031, 52.016364523969692],
            [0.059013194694333, 52.014517564601775],
            [0.062116716511907, 52.01200213538695],
            [0.062421160282105, 52.010922250601318],
            [0.063928294379492, 52.009600988178605],
            [0.065067208219264, 52.00759467086106],
            [0.068098740708988, 52.005779024134291],
            [0.070167449093961, 52.00435249914743],
            [0.070992966103129, 52.002730196499229],
            [0.072166964041967, 52.002692460092661],
            [0.07412201596741, 52.001397349333715],
            [0.074293443931025, 52.00085120629182],
            [0.073777461026418, 51.994258281092378],
            [0.073029739638167, 51.992198762419527],
            [0.072441295501786, 51.987404627391435],
            [0.0709894236889, 51.984946545919087],
            [0.069032225144849, 51.984033183282961],
            [0.066878481480107, 51.979797949284766],
            [0.064710351097529, 51.980433965714312],
            [0.058398755166049, 51.981314200007482],
            [0.048551200244446, 51.984557644037011],
            [0.048355199652814, 51.984251725580371],
            [0.045821400175281, 51.984735490008141],
            [0.043684875353142, 51.984797769450083],
            [0.043277246025666, 51.985312896915339],
            [0.043500191035415, 51.986019403273588],
            [0.04414243954874, 51.986242949202286],
            [0.044543867947615, 51.986934665306293],
            [0.042295189808072, 51.987255140595884],
            [0.041677190013467, 51.98654019612497],
            [0.038511489528834, 51.986390923174184],
            [0.038564560429767, 51.98685399593986],
            [0.037726602132046, 51.986842423032357],
            [0.037808390489566, 51.988451489481918],
            [0.03831686605658, 51.988936354065139],
            [0.034996502488309, 51.989619631278394],
            [0.034418627987055, 51.988727711373947],
            [0.033859894605418, 51.988707689396378],
            [0.032240035135125, 51.987028961102396],
            [0.026189119954944, 51.987974893583456],
            [0.024925976746298, 51.987868934506047],
            [0.021541137177532, 51.988934203341586],
            [0.022618920248686, 51.988061449904336],
            [0.023241529515825, 51.984361309918221],
            [0.016181840115585, 51.983854756068801],
            [0.01593540612645, 51.984139527604029],
            [0.012692942576874, 51.983961202136662],
            [0.012876398998585, 51.982466284346657],
            [0.010060227746451, 51.982243731616805],
            [0.009548274234106, 51.983819784342771],
            [0.009170769573984, 51.983845109630757],
            [0.00877744133199, 51.984786094086537],
            [0.006981357852124, 51.984874269848923],
            [0.005761504528351, 51.983745871881673],
            [0.003445746671243, 51.983511937824929],
            [0.003592045271226, 51.983135379600242],
            [0.002440439674801, 51.983044367267709],
            [0.001986610473862, 51.984231839763474],
            [0.000219460360194, 51.983792487115998],
            [-0.001532376820389, 51.986967645397542],
            [-0.002053259129192, 51.989433106479467],
            [-0.001274650353457, 51.989550277798422],
            [-0.001828910609082, 51.992640351705028],
            [-0.003037027484063, 51.992626675423082],
            [-0.003065085755967, 51.993436433037715],
            [-0.01003742004851, 51.993315272959457],
            [-0.013526418718516, 51.996365819219264],
            [-0.023762633909348, 51.997160286351075],
            [-0.023128320067791, 51.996149730982147],
            [-0.023124237521556, 51.994952828537912],
            [-0.02568095224018, 51.995144987005396],
            [-0.026515315415815, 51.987400681818833],
            [-0.029959605450657, 51.987336962932076],
            [-0.031558857119317, 51.984909792742457],
            [-0.030657801770901, 51.983804896771048],
            [-0.03276361236661, 51.983405783216242],
            [-0.038132049272275, 51.981659185241121],
            [-0.040628712577851, 51.981376145606276],
            [-0.047981427460895, 51.97972774505709],
            [-0.048229848881348, 51.979439623750963],
            [-0.047112213369561, 51.978118154594959],
            [-0.045330830091657, 51.978064317697076],
            [-0.04353457579513, 51.977349295586279],
            [-0.040904630988704, 51.97495236236194],
            [-0.040065288210283, 51.972999719127614],
            [-0.038563831959618, 51.971408309407963],
            [-0.038610180418219, 51.969113422771066],
            [-0.040218249354201, 51.967692487867495],
            [-0.042204590702335, 51.967676978073136],
            [-0.045943152974027, 51.964761834684083],
            [-0.051173966909317, 51.96260053661063],
            [-0.057052826639205, 51.958433699921265],
            [-0.058852501018164, 51.95774312881499],
            [-0.062662983996588, 51.957706085511582],
            [-0.063668559779999, 51.957168722605878],
            [-0.065567623242854, 51.956836666254887],
            [-0.067105711266171, 51.959685412576789],
            [-0.068471344151037, 51.964416924384423],
            [-0.069627998217212, 51.966327805388417],
            [-0.070751463805484, 51.971284607429638],
            [-0.074684945417076, 51.971155666603302],
            [-0.075347070727918, 51.969531754010262],
            [-0.077743565532024, 51.969084439513693],
            [-0.07817774851305, 51.968633836792598],
            [-0.077901178658092, 51.967646501171878],
            [-0.07721041941308, 51.967187422777066],
            [-0.079241142208338, 51.966473337683247],
            [-0.079798408277625, 51.967297098863305],
            [-0.080148090695907, 51.967221873769098],
            [-0.080264016108466, 51.966008051529911],
            [-0.085941471633401, 51.961663858047103],
            [-0.087640623123978, 51.961720244381979],
            [-0.088759148265249, 51.959993968625632],
            [-0.08839117355206, 51.959542892550239],
            [-0.092551896445851, 51.956229403287281],
            [-0.092901024121741, 51.956473346235072],
            [-0.094220679696739, 51.95600195732154],
            [-0.097490471888044, 51.954430897571953],
            [-0.098272558328278, 51.955139510854607],
            [-0.104356767173463, 51.953980563180032],
            [-0.104480028964094, 51.95423252392446],
            [-0.107038695894517, 51.953950883016674],
            [-0.106273124501684, 51.948771799223437],
            [-0.107288849029955, 51.946317147062778],
            [-0.107569110852906, 51.94391091070996],
            [-0.110180513605913, 51.941892807571307],
            [-0.115728776383368, 51.942484400735196],
            [-0.117031000969051, 51.942031363057936],
            [-0.120190695910535, 51.942179875819967],
            [-0.120831491188646, 51.940807146752704],
            [-0.120552645774796, 51.939820774856742],
            [-0.118452444461088, 51.937024896947818],
            [-0.116700440191302, 51.936167820494916],
            [-0.117575357926443, 51.934241352379495],
            [-0.120608821774269, 51.930957408775527],
            [-0.119169435059151, 51.930594520862726],
            [-0.119307993030097, 51.928289398345356],
            [-0.122390580732181, 51.929042683942498],
            [-0.124807722476241, 51.929292529243149],
            [-0.130051730834079, 51.931414432673705],
            [-0.138363837896263, 51.932979506730263],
            [-0.142795524361676, 51.932429072177811],
            [-0.145291839602431, 51.934046515870463],
            [-0.148524291660763, 51.933122681385193],
            [-0.150718426783225, 51.93183536224079],
            [-0.151681098211799, 51.930850581895889],
            [-0.154823662608823, 51.92959156445179],
            [-0.152291345258425, 51.926475703261985],
            [-0.155269789257066, 51.925522521279582],
            [-0.154813456419523, 51.924485793155789],
            [-0.154830028552788, 51.923975311774733],
            [-0.155267909122234, 51.923901248434767],
            [-0.160072392742547, 51.921284273339566],
            [-0.159950055577814, 51.920186245605954],
            [-0.162218355923473, 51.920334985088367],
            [-0.162603669647087, 51.920763621256967],
            [-0.163523236703007, 51.921749100505011],
            [-0.163226181799635, 51.92229297181688],
            [-0.164632046730382, 51.926866608339552],
            [-0.166496023429952, 51.9267383012143],
            [-0.167960439629222, 51.927957027323117],
            [-0.171180220649369, 51.928252578191383],
            [-0.172981769577279, 51.928767017749415],
            [-0.175675851574256, 51.930622469323247],
            [-0.184564248959679, 51.932431485531104],
            [-0.188001449168399, 51.928459685465313],
            [-0.190330534441721, 51.928664564978668],
            [-0.193118551056672, 51.927728182298793],
            [-0.195170685441384, 51.929180382292728],
            [-0.194407964182559, 51.93042664683184],
            [-0.197328281956652, 51.930472307049939],
            [-0.198655828183239, 51.928776084412846],
            [-0.199690191871272, 51.929642539872695],
            [-0.200426554405104, 51.929232981196378],
            [-0.202396768070749, 51.930558813704529],
            [-0.205071647094109, 51.928553986810002],
            [-0.207473526082478, 51.929781110922711],
            [-0.208529828763656, 51.928896208966485],
            [-0.210108655391564, 51.928366330122635],
            [-0.210415805091732, 51.930041686040703],
            [-0.216755101725092, 51.930615363361248],
            [-0.216976472703163, 51.930094492612987],
            [-0.220068309632011, 51.930988318151684],
            [-0.226547492552674, 51.930086224042604],
            [-0.224978939821656, 51.929327048918772],
            [-0.226217754399618, 51.928193870209022],
            [-0.227699780259477, 51.927050853213636],
            [-0.227164187451728, 51.925989843732708],
            [-0.227830201911398, 51.924589054492969],
            [-0.226550030046908, 51.924007782827623],
            [-0.226222414545121, 51.924534263659645],
            [-0.225466536937533, 51.924156003034753],
            [-0.224681932329541, 51.922478882303338],
            [-0.225842274957116, 51.922298558121625],
            [-0.226016545200269, 51.920939820055501],
            [-0.22935240208887, 51.917209881899488],
            [-0.228129718607436, 51.916946003509906],
            [-0.228225061926481, 51.915964630056948],
            [-0.228774328775656, 51.913091909359935],
            [-0.230009864205459, 51.911362481946227],
            [-0.231477280805585, 51.908428021416348],
            [-0.233022920065153, 51.907199572550972],
            [-0.234382134961055, 51.905236369875333],
            [-0.232114533586594, 51.904232105047356],
            [-0.232685519161102, 51.903610352117227],
            [-0.232376406747756, 51.903486117753282],
            [-0.228891481015168, 51.903462494499095],
            [-0.229826908284142, 51.902630434834592],
            [-0.230077251091571, 51.901253949135949],
            [-0.229340986413298, 51.899424717507117],
            [-0.230506087636911, 51.898885646297664],
            [-0.229672493750864, 51.898053949201241],
            [-0.22776935021926, 51.89891729607335],
            [-0.227092191812624, 51.898063658231372],
            [-0.225956868281941, 51.897551095412496],
            [-0.224904704761965, 51.89555611603587],
            [-0.225298255093899, 51.893203481912401],
            [-0.219744261225616, 51.895799167003723],
            [-0.218623306885619, 51.895955746134824],
            [-0.216073854019444, 51.896106852400763],
            [-0.215492594288707, 51.894269987589517],
            [-0.216451819101871, 51.893976108811593],
            [-0.215845600593825, 51.893430102672944],
            [-0.215317731573853, 51.893547984191343],
            [-0.214648452381848, 51.893273469674938],
            [-0.21460563010325, 51.894060509835064],
            [-0.212742342770013, 51.894209363859638],
            [-0.210427911029084, 51.892324554036144],
            [-0.208576636831813, 51.890089770678784],
            [-0.210636373320507, 51.889674213976122],
            [-0.208893536262476, 51.886322489544753],
            [-0.207644891677711, 51.88538360268975],
            [-0.205934960359077, 51.883149127566945],
            [-0.204915596676678, 51.883497752497419],
            [-0.204181760824174, 51.880145155889281],
            [-0.20024967706683, 51.881941006962677],
            [-0.198130652528319, 51.882137868084584],
            [-0.196108965884832, 51.882915263869087],
            [-0.193247330192932, 51.882570125392348],
            [-0.191920868645607, 51.882893240532162],
            [-0.185469318878512, 51.878822177688207],
            [-0.180263620180802, 51.876881340297643],
            [-0.175470073196629, 51.875787410460461],
            [-0.173579701239879, 51.875365129923111],
            [-0.173103303109152, 51.8746815371411],
            [-0.171188349123189, 51.87350620787268],
            [-0.170548883516376, 51.870327493398158],
            [-0.167342307014803, 51.868272372393868],
            [-0.167279024846631, 51.865791396699748],
            [-0.166621871458777, 51.863485501717065],
            [-0.164586095860312, 51.861904435304517],
            [-0.165998999921481, 51.860802472487173],
            [-0.169477785941895, 51.859321724939562],
            [-0.169643159772911, 51.858825242580856],
            [-0.169043748631451, 51.858101050459048],
            [-0.169785881628481, 51.857133371232237],
            [-0.172809903775356, 51.856580617450021],
            [-0.176018255849859, 51.856746407144577],
            [-0.176099555092215, 51.858506500787307],
            [-0.178606455940836, 51.859036306432174],
            [-0.179427218034587, 51.859677551678629],
            [-0.18098481531626, 51.860126069351622],
            [-0.183576354248812, 51.860108566221022],
            [-0.183570399741082, 51.86043668141528],
            [-0.185919406532406, 51.860239138569952],
            [-0.185808146029783, 51.85892549360134],
            [-0.186427394385942, 51.859416113005565],
            [-0.189688959523273, 51.857792046927642],
            [-0.190522757776445, 51.858220304946876],
            [-0.1918370495037, 51.857313442616416],
            [-0.196856167826601, 51.859373214647661],
            [-0.197623521663434, 51.857687292032637],
            [-0.19876618629616, 51.857765040757698],
            [-0.20012150456531, 51.856444185562012],
            [-0.20091026770491, 51.856619905562063],
            [-0.204243661226077, 51.855033404834479],
            [-0.204969033584577, 51.85526927525531],
            [-0.206714067371552, 51.854256421321757],
            [-0.205197033060739, 51.85329812001612],
            [-0.203546171917274, 51.854753895116325],
            [-0.203140397488942, 51.853968094266499],
            [-0.203668104439747, 51.853223526967369],
            [-0.202027222887351, 51.851513362624452],
            [-0.199087214638534, 51.849724842903179],
            [-0.197658923645876, 51.85022360125663],
            [-0.196438948938367, 51.84954219277806],
            [-0.197199972864704, 51.848919025873485],
            [-0.197578503124996, 51.846131019511098],
            [-0.198724419294626, 51.845938161771848],
            [-0.198567730585426, 51.845462783976565],
            [-0.199453494545173, 51.844585241686474],
            [-0.198753094573426, 51.844347020516253],
            [-0.199646220217441, 51.843757332895045],
            [-0.201485944779149, 51.843529207450985],
            [-0.20397219371494, 51.844892615975425],
            [-0.20543737604746, 51.844372764036642],
            [-0.208152202106609, 51.841958446650807],
            [-0.209969940735447, 51.840890903107926],
            [-0.210651787408643, 51.840425603077634],
            [-0.21201068330667, 51.840567660547471],
            [-0.21298373851432, 51.84015263278453],
            [-0.213425771550424, 51.840243872632755],
            [-0.214595265592268, 51.839596225801294],
            [-0.215267229953064, 51.840039837266055],
            [-0.212729112228084, 51.841560502957947],
            [-0.212204587808302, 51.842488595129325],
            [-0.208257141730705, 51.844690007759127],
            [-0.205987127690614, 51.846392654457958],
            [-0.20873936622306, 51.847572983642976],
            [-0.211324299698071, 51.849351366184791],
            [-0.210437821375855, 51.849847726532879],
            [-0.212949564675561, 51.851389368827029],
            [-0.216523827855264, 51.848941156682372],
            [-0.217194262190528, 51.847700539478424],
            [-0.218904332271425, 51.847043957317609],
            [-0.218283434118169, 51.846627218159746],
            [-0.222369603708225, 51.844691931441794],
            [-0.223048618548142, 51.846213738650441],
            [-0.223972876341153, 51.846633235014039],
            [-0.22578149476621, 51.844537531393676],
            [-0.226020095104145, 51.843712072534537],
            [-0.223446493103532, 51.841307444900529],
            [-0.223816640741288, 51.840459698583174],
            [-0.224218458501324, 51.840208596017959],
            [-0.222600954546587, 51.839002619961548],
            [-0.22180772834359, 51.839240606473894],
            [-0.220809198013114, 51.838757923546218],
            [-0.219605643259461, 51.838799956882376],
            [-0.218705183234481, 51.83840776083801],
            [-0.22021561226804, 51.837614156718267],
            [-0.219870665198244, 51.836680965340769],
            [-0.223426085807413, 51.835298708571557],
            [-0.221681162938798, 51.834089893781822],
            [-0.224934323311697, 51.833227253174059],
            [-0.2263954525995, 51.834456917630632],
            [-0.226129606952778, 51.834906103176394],
            [-0.226977948249642, 51.835294759303693],
            [-0.226948812752885, 51.835888688651032],
            [-0.225586589224888, 51.83719985387058],
            [-0.225704331233473, 51.837638639398683],
            [-0.226886860918466, 51.837096273989808],
            [-0.227954003965878, 51.837052111178409],
            [-0.22798768460413, 51.836711823449953],
            [-0.229152810583768, 51.836056773783994],
            [-0.23007511784212, 51.836040983666443],
            [-0.230750735658798, 51.836764211058032],
            [-0.231669733826676, 51.836944383151454],
            [-0.232129085244013, 51.836556541977892],
            [-0.233320475196724, 51.836378419608323],
            [-0.236012907135916, 51.836231799922182],
            [-0.237103384187635, 51.836444174690932],
            [-0.23903266470234, 51.834986708208895],
            [-0.240459320615932, 51.834952310554712],
            [-0.241647880566398, 51.835291997824605],
            [-0.242450881160624, 51.835879484699248],
            [-0.241737828766983, 51.836336407972048],
            [-0.244927593222946, 51.838815470266972],
            [-0.244977065416957, 51.839409676192425],
            [-0.243066701401071, 51.841798186958279],
            [-0.243035191399706, 51.842719389372448],
            [-0.245051563316826, 51.843503915039804],
            [-0.245298772433033, 51.84457584427269],
            [-0.246528435364557, 51.845210129154999],
            [-0.247349771520781, 51.846153933769017],
            [-0.247982814331276, 51.846227207438616],
            [-0.253968916066366, 51.844530424802024],
            [-0.257049184633196, 51.8454411315427],
            [-0.259343488480265, 51.84460019368673],
            [-0.259582974580419, 51.844752104068519],
            [-0.266417740208605, 51.8417688140172],
            [-0.26815330856296, 51.842200815737016],
            [-0.27135267775474, 51.840460289947039],
            [-0.272545988362238, 51.840458039931676],
            [-0.27304451314937, 51.839683065218722],
            [-0.272819454807926, 51.836498424519327],
            [-0.273563636975988, 51.835333209118282],
            [-0.280150975083977, 51.834705871666401],
            [-0.284565001017025, 51.834928592359837],
            [-0.290763093687549, 51.835682431927133],
            [-0.292048127091828, 51.835515866854628],
            [-0.292040276611731, 51.834802696150369],
            [-0.296169121840933, 51.834314976160805],
            [-0.295902602623357, 51.834838938477006],
            [-0.29836351642066, 51.835319666839766],
            [-0.298518440939045, 51.834829153109318],
            [-0.299586064341931, 51.834922822582016],
            [-0.300938996685935, 51.835688700785042],
            [-0.302562512018472, 51.835937838907441],
            [-0.305670157790889, 51.835085258918753],
            [-0.307392413485429, 51.835062399848404],
            [-0.315515483009373, 51.836322784110223],
            [-0.319480891702407, 51.83663487799766],
            [-0.320462113747592, 51.837882576031333],
            [-0.32232128927725, 51.839060066400506],
            [-0.323377843676836, 51.843128655763984],
            [-0.323255860711221, 51.844848854538036],
            [-0.327718306714337, 51.846813255509389],
            [-0.329229599333751, 51.846539799426658],
            [-0.329269577753634, 51.846910830703642],
            [-0.333480819272369, 51.848063076615297],
            [-0.33713183453891, 51.848664146184262],
            [-0.339510470004621, 51.849597828406409],
            [-0.340970280056397, 51.850567062497994],
            [-0.342197559769731, 51.853005859496243],
            [-0.347109110527151, 51.85547848293632],
            [-0.349548960870738, 51.857175322324942],
            [-0.350347532718935, 51.86083089626235],
            [-0.352896217186749, 51.863535369083642],
            [-0.353769378839639, 51.864322682040303],
            [-0.355239961975798, 51.867247594424221],
            [-0.353908124960266, 51.870547831059106],
            [-0.354858254376357, 51.874006765811593],
            [-0.354639064027897, 51.875156444947194],
            [-0.354150289921218, 51.875532657898049],
            [-0.350816784553188, 51.876114504634764],
            [-0.349923102105808, 51.878700594953933],
            [-0.353018977263357, 51.878902225699584],
            [-0.352002853978351, 51.879593851186399],
            [-0.354669889268765, 51.884539774302525],
            [-0.355156426937155, 51.885103165793325],
            [-0.357717483939882, 51.886436447002815],
            [-0.359114265147614, 51.889099505084609],
            [-0.365158530837886, 51.894370063560025],
            [-0.37196072865385, 51.894715235442533],
            [-0.373933377810074, 51.895306290317528],
            [-0.377514012234199, 51.898425481555606],
            [-0.377623953112518, 51.900497786855816],
            [-0.378414314211343, 51.901638037676982],
            [-0.378663884090363, 51.90403236894474],
            [-0.384946675745548, 51.911201506728901],
            [-0.38577124942609, 51.914560420236015],
            [-0.385641211007062, 51.915672705129197],
            [-0.385234160827432, 51.916805467195758],
            [-0.383576485025735, 51.918264541320177],
            [-0.384432645899126, 51.91956929805179],
            [-0.384800066804221, 51.919461045531477],
            [-0.38780325560611, 51.921398560465697],
            [-0.389384926449879, 51.924784892311393],
            [-0.388591309044002, 51.925138192759952],
            [-0.38924048482855, 51.925772897158595],
            [-0.402537454026137, 51.930199053565588],
            [-0.405634421100857, 51.932445787707621],
            [-0.402509549639869, 51.933401494467773],
            [-0.403805961807152, 51.935850416062067],
            [-0.403453391535667, 51.935988601888965],
            [-0.405470638263149, 51.938420302140258],
            [-0.40632635346746, 51.941284929464892],
            [-0.405850510365134, 51.943422087893417],
            [-0.405061909916152, 51.94547408285824],
            [-0.405256212682089, 51.946026102326151],
            [-0.404742362088283, 51.94615581333646],
            [-0.405244205667623, 51.946979949401126],
            [-0.404534034753188, 51.947024277329682],
            [-0.403962620391891, 51.947873432772269],
            [-0.402142163612297, 51.948943019033877],
            [-0.403502582118579, 51.950502635506567],
            [-0.402926658530577, 51.950944404942646],
            [-0.403354481894131, 51.953607226074737],
            [-0.402836813968751, 51.954988508098666],
            [-0.404015049267992, 51.955331779077163],
            [-0.404019843535067, 51.955565625600471],
            [-0.40373186452538, 51.956134486490363],
            [-0.401695013560227, 51.956363115401253],
            [-0.402237251916478, 51.960514701002516],
            [-0.401309241097935, 51.96058033165685],
            [-0.400246856675435, 51.964870177304725],
            [-0.398907367533758, 51.964920307737998],
            [-0.399464718010651, 51.967662226413282],
            [-0.398969393380786, 51.967428005745759],
            [-0.394801221421138, 51.967361369260153],
            [-0.394183435528376, 51.968314147132986],
            [-0.393458154206155, 51.968567707897279],
            [-0.393640628213712, 51.969367751231871],
            [-0.393094548021633, 51.970512123162386],
            [-0.393349945953997, 51.971796909104384],
            [-0.391773289665635, 51.973504469322428],
            [-0.391648619573585, 51.975115856815762],
            [-0.391155131041146, 51.975479569114796],
            [-0.391328849863672, 51.977011410306375],
            [-0.390291557833549, 51.977719260150145],
            [-0.38889394682075, 51.978039130740633],
            [-0.388592464124074, 51.978609567916941],
            [-0.386669335589392, 51.979451824546473],
            [-0.384871903962982, 51.978295146531018],
            [-0.380712081237714, 51.979423113146801],
            [-0.380270677346033, 51.978338951410208],
            [-0.379655757992491, 51.97794834961794],
            [-0.379560906194589, 51.976582120744034],
            [-0.378746262097846, 51.97587675715215],
            [-0.378928897259029, 51.973640363784362],
            [-0.37823143896122, 51.971626532815286],
            [-0.379272366023894, 51.96730870917844],
            [-0.380448539464462, 51.965371015638709],
            [-0.379535521471055, 51.963402779106829],
            [-0.379399703210447, 51.95812822623104],
            [-0.379832797023417, 51.957055192026097],
            [-0.381795684146138, 51.955287458056475],
            [-0.383011728821466, 51.953442899180956],
            [-0.382938083679795, 51.951404385715733],
            [-0.382549978870439, 51.950907212686104],
            [-0.381581722275501, 51.950599880686958],
            [-0.374238735940585, 51.948677008088438],
            [-0.374130749513916, 51.947760167982608],
            [-0.372608454630812, 51.94800978394494],
            [-0.369895652165997, 51.949429799742475],
            [-0.366114032184701, 51.950664983782005],
            [-0.363330643774964, 51.952854454001717],
            [-0.353913716604781, 51.957150661773667],
            [-0.35025122434619, 51.957568765536067],
            [-0.351192207443934, 51.961989652164014],
            [-0.349199788994886, 51.963727694582239],
            [-0.351249132211799, 51.964603420653326],
            [-0.350790553773002, 51.965358590620397],
            [-0.351754604640556, 51.966220000728711],
            [-0.349112207498394, 51.970033202985441],
            [-0.349676260329385, 51.970530254420893],
            [-0.349290604804658, 51.971160557416951],
            [-0.350825682058409, 51.972887778600928],
            [-0.351952605636036, 51.973758659363554],
            [-0.353962205344576, 51.974183297457152],
            [-0.359526169656755, 51.973919257473163],
            [-0.361784995109363, 51.974378703841815],
            [-0.363758420168816, 51.975200100015783],
            [-0.363361115801118, 51.976114420878282],
            [-0.365762299000286, 51.977507298885186],
            [-0.365908322195507, 51.978696217198156],
            [-0.366986368569464, 51.979041163015026],
            [-0.367973726677289, 51.980078993735219],
            [-0.367183471195, 51.983751897769118],
            [-0.361313920544239, 51.985374281662395],
            [-0.358747824583068, 51.985537263056393],
            [-0.35613735982859, 51.985240997395927],
            [-0.355249151288414, 51.98464144429375],
            [-0.353034406312301, 51.983920836230119],
            [-0.352365709760961, 51.984206407700107],
            [-0.352371042745663, 51.983386447239894],
            [-0.347122400918629, 51.983833514351893],
            [-0.345761549432525, 51.983220960089263],
            [-0.341517910470567, 51.982596600883411],
            [-0.338701941861074, 51.982745734929708],
            [-0.334438698636684, 51.983870614962619],
            [-0.331804157884767, 51.983954713002767],
            [-0.321897452278533, 51.982549741076646],
            [-0.318228093836467, 51.982581919080189],
            [-0.314913143368678, 51.981861956970832],
            [-0.311582620687287, 51.982101087746486],
            [-0.310420658337498, 51.982641006583968],
            [-0.308517377871344, 51.986932378979738],
            [-0.306842443214237, 51.989060014157069],
            [-0.304824350988869, 51.990435480421162],
            [-0.30072964389652, 51.992221560349982],
            [-0.295352559504856, 51.995395241280228],
            [-0.300081021290408, 52.000771354214457],
            [-0.296115925231475, 52.002183296717597],
            [-0.294436075047553, 52.002033100859762],
            [-0.29207266275169, 52.001049335454965],
            [-0.2905350053574, 52.000762673854346],
            [-0.28768957099117, 52.0014964229821],
            [-0.285576507888658, 52.00151423989395],
            [-0.284773937001721, 52.001205830331784],
            [-0.283920407688064, 52.001811125001566],
            [-0.28244279588167, 52.001554904916752],
            [-0.281956878463323, 52.000966956486579],
            [-0.284037148958589, 51.999367992143867],
            [-0.282981293638857, 51.998713297023961],
            [-0.282823088216672, 51.997708419427795],
            [-0.283621109914133, 51.997372073896948],
            [-0.283402499108811, 51.996651352605937],
            [-0.282626039759842, 51.996043885457901],
            [-0.278937384543794, 51.994688046797187],
            [-0.277133067503733, 51.993615034818497],
            [-0.275650198480388, 51.991835458945928],
            [-0.275819786481724, 51.990946865611591],
            [-0.274472724988142, 51.989616148598536],
            [-0.274625397190742, 51.989130136545214],
            [-0.272906750685767, 51.988677839841415],
            [-0.272880405347262, 51.988226969587565],
            [-0.272308984768756, 51.988126872881068],
            [-0.272828953797004, 51.987217346775886],
            [-0.273865816831309, 51.986650785945493],
            [-0.273766728467966, 51.985326654930027],
            [-0.274696980212491, 51.984273871631267],
            [-0.274890907775346, 51.982827250673644],
            [-0.274135897873771, 51.983034686549075],
            [-0.273213529173435, 51.982406132961216],
            [-0.273928253866729, 51.981200932718345],
            [-0.27169346668844, 51.981492756244343],
            [-0.270653329539275, 51.979613505283289],
            [-0.267434432141226, 51.980316099661636],
            [-0.262856376478467, 51.979600352858782],
            [-0.260282625220677, 51.979696334540883],
            [-0.259310020872196, 51.983150068210954],
            [-0.258324451566219, 51.983104924319058],
            [-0.257476116577768, 51.98537717028622],
            [-0.247916544224561, 51.98516429886282],
            [-0.24663810532602, 51.98747143153237],
            [-0.248477919528668, 51.987423278455637],
            [-0.24842833147657, 51.987873026296043],
            [-0.24624322094898, 51.98792412973139],
            [-0.24577059695569, 51.988912474675992],
            [-0.245041461993263, 51.992884944919908],
            [-0.245667438276762, 51.993042634427155],
            [-0.244986235602289, 51.993105318286183],
            [-0.244105441560397, 51.999014134553519],
            [-0.237627404992254, 51.999048658486757],
            [-0.238549916860427, 51.998148000859437],
            [-0.236894598193137, 51.997938003323831],
            [-0.232081513040624, 52.000529203557583],
            [-0.229208552096942, 52.002932693204833],
            [-0.227658117883179, 52.00297771024173],
            [-0.22477563701955, 52.003917995756055],
            [-0.223220704382817, 52.005068869514297],
            [-0.221797242572828, 52.004665239409015],
            [-0.220750574008103, 52.005745525117312],
            [-0.219391846444795, 52.005399491736625],
            [-0.218424332485631, 52.00631910247359],
            [-0.217145155947809, 52.005987734024366],
            [-0.213989858213805, 52.008646444265189],
            [-0.213652530230919, 52.008848138796303],
            [-0.21352447261455, 52.008441568539304],
            [-0.203926741933754, 52.00873530527673],
            [-0.201282569993247, 52.009815327961185],
            [-0.206071150109067, 52.016104344434154],
            [-0.207558759320168, 52.020231714568162],
            [-0.212849247083708, 52.026557741632502],
            [-0.219519092450655, 52.036731234007902],
            [-0.211991498141252, 52.04283499097086],
            [-0.211206698395143, 52.044522508055408],
            [-0.209034105023063, 52.047290415270183],
            [-0.199209161414119, 52.056654777091893],
            [-0.199163769040847, 52.057239442119013],
            [-0.197119362864313, 52.058999324937417],
            [-0.197750157026895, 52.059490928332046],
            [-0.19566181651811, 52.060961481200458],
            [-0.19500846190775, 52.062404531608706],
            [-0.180571549508791, 52.061339095365462],
            [-0.175213666776603, 52.06076080616625],
            [-0.17264580889845, 52.06019949480951],
            [-0.171646243489002, 52.063350871134482],
            [-0.167982207969989, 52.068861632988224],
            [-0.16637995310666, 52.07061257634102],
            [-0.164487888931522, 52.074588028428842],
            [-0.164842811003462, 52.074900172670297],
            [-0.16436443869329, 52.075249693363887],
            [-0.164584548169788, 52.076287167118373],
            [-0.164192390700089, 52.077024672883432],
            [-0.160570326943556, 52.079689086872655],
            [-0.157307268453173, 52.080536386295996],
            [-0.157195053084283, 52.080213627960326],
            [-0.157916890011445, 52.079908404139417],
            [-0.157188134129441, 52.079424947256058],
            [-0.156389880912599, 52.076768001829329],
            [-0.153517133171204, 52.076223089287332],
            [-0.152137824265408, 52.075170121098736],
            [-0.150040299904686, 52.074266816206929],
            [-0.148110217834569, 52.071717021998758],
            [-0.148002817372681, 52.071138964510972],
            [-0.148632953976363, 52.070473586619435],
            [-0.14753231402104, 52.069392569054443],
            [-0.148035722824743, 52.068862775642039],
            [-0.147954125271681, 52.067626029296527],
            [-0.149041205122559, 52.066211624805653],
            [-0.150682348326485, 52.065827367516327],
            [-0.15191214287464, 52.064663348304343],
            [-0.149960726432261, 52.061224860395406],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000102",
        LAD13CDO: "26UJ",
        LAD13NM: "Three Rivers",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.416186471233538, 51.732606102426743],
            [-0.41524995472788, 51.732014398424518],
            [-0.411910147195515, 51.732069122682702],
            [-0.410137686752062, 51.729963529486426],
            [-0.409295938263005, 51.725809562913248],
            [-0.407586159236492, 51.72563893813409],
            [-0.406630989716214, 51.724431863168327],
            [-0.409355310486865, 51.72370535816669],
            [-0.409708435569535, 51.722347861528661],
            [-0.409350112558454, 51.721708181755986],
            [-0.408949368165171, 51.72132869222051],
            [-0.402552773524109, 51.72117446726709],
            [-0.403477290204069, 51.719629629531227],
            [-0.402579846904977, 51.718064525340992],
            [-0.405946136402957, 51.713851660406299],
            [-0.404514779427618, 51.713474353743493],
            [-0.405026615350818, 51.712585705468207],
            [-0.403056690668368, 51.712212758133475],
            [-0.400557310292163, 51.711052070344927],
            [-0.397994599994375, 51.710335566653249],
            [-0.394105575366063, 51.708695395886217],
            [-0.383710077236876, 51.70761318831142],
            [-0.380764397186189, 51.707015126020679],
            [-0.380826418254803, 51.705196904149993],
            [-0.378755431412505, 51.704264650465426],
            [-0.378883755963619, 51.701686621601731],
            [-0.377236335636668, 51.701662079652507],
            [-0.3771875952259, 51.700031159242982],
            [-0.376198496320813, 51.698151654529745],
            [-0.376489525318005, 51.697014592943653],
            [-0.375746741884932, 51.69561326416629],
            [-0.375966956214714, 51.693826901326091],
            [-0.375581734578107, 51.692907088992428],
            [-0.37876567065788, 51.692681312384728],
            [-0.378648575391893, 51.694500575407368],
            [-0.379317052777777, 51.694784055204352],
            [-0.379223357931158, 51.695341165332685],
            [-0.384426833875009, 51.696024294215569],
            [-0.388566397877605, 51.696437230884925],
            [-0.396022785712576, 51.700362656835694],
            [-0.402423459028342, 51.702001881190597],
            [-0.403307176055874, 51.699836941165366],
            [-0.405848346400134, 51.696539928383963],
            [-0.40736822683625, 51.696647764520478],
            [-0.412859201654046, 51.68863200626658],
            [-0.415679491763939, 51.687329374191179],
            [-0.423183071695533, 51.685715566472638],
            [-0.425285219452664, 51.685334630656385],
            [-0.427401161334029, 51.685431314998908],
            [-0.428179485689261, 51.684051575237355],
            [-0.430068221434862, 51.683438403564395],
            [-0.431426587879594, 51.682515991034329],
            [-0.431633267822909, 51.681809285177067],
            [-0.431060004666001, 51.680901534673261],
            [-0.429743355528538, 51.680075564081086],
            [-0.431102529950947, 51.6792107146427],
            [-0.430344310052585, 51.676918424375408],
            [-0.427585337672198, 51.674268456815163],
            [-0.427881487462039, 51.672774358026523],
            [-0.428387595472343, 51.672274882710852],
            [-0.427879702782531, 51.671781619982326],
            [-0.42827732493384, 51.670655750586022],
            [-0.431005508786724, 51.6703829016204],
            [-0.4324468261633, 51.672659131167187],
            [-0.439582541990116, 51.672782019541636],
            [-0.438266007056527, 51.670483260334208],
            [-0.436310035338428, 51.66828028057072],
            [-0.438257740395805, 51.664331741850035],
            [-0.437624003405999, 51.663292832600845],
            [-0.43734795632495, 51.661355884259095],
            [-0.436185620471178, 51.659924175976307],
            [-0.432518474762887, 51.657343145448735],
            [-0.431428693885294, 51.657209001915874],
            [-0.430017047917421, 51.656348488682205],
            [-0.427912785909248, 51.656245706237286],
            [-0.42785695790657, 51.655686555748645],
            [-0.424483512434047, 51.654956157681525],
            [-0.424802416120941, 51.654305817559951],
            [-0.426593722298673, 51.652088123521857],
            [-0.426580401910437, 51.650427121305576],
            [-0.430336073943382, 51.648494642516823],
            [-0.427724508407123, 51.646896895023836],
            [-0.426054815283596, 51.645360272779861],
            [-0.429384503337877, 51.643698169374147],
            [-0.431109376863083, 51.642088276834329],
            [-0.428846868065562, 51.641314387077522],
            [-0.424535452767191, 51.641074084106855],
            [-0.418716716990224, 51.640751289417103],
            [-0.41498790679364, 51.641047210768683],
            [-0.414500607956615, 51.640681850091816],
            [-0.412253038493817, 51.640648784092953],
            [-0.410914866055777, 51.639985054353964],
            [-0.409954437574934, 51.64079392561306],
            [-0.409340023946194, 51.640634538084392],
            [-0.408096445572728, 51.642759589671442],
            [-0.404991452905401, 51.643805492868566],
            [-0.402815701304039, 51.643863142787268],
            [-0.398189157655162, 51.641078231603309],
            [-0.399831200434271, 51.640002690069302],
            [-0.394314788374241, 51.637716240445663],
            [-0.390239089476918, 51.640056821091036],
            [-0.387697060005725, 51.638833194380311],
            [-0.387271868647184, 51.639575495139425],
            [-0.386849065606878, 51.639598462100537],
            [-0.386317686541572, 51.639545301365708],
            [-0.386314313955468, 51.638215328572443],
            [-0.383712543181607, 51.637824357997189],
            [-0.382835288020384, 51.638110816218891],
            [-0.382344182925074, 51.63781990215287],
            [-0.380895941974808, 51.638557074044776],
            [-0.378559245898331, 51.637095990120287],
            [-0.377446706943704, 51.637698377682099],
            [-0.375170101690073, 51.635813629421285],
            [-0.373251504703552, 51.634509289440508],
            [-0.369948581017276, 51.63119395911297],
            [-0.364357435486158, 51.624628402395118],
            [-0.362618442911358, 51.623533202798185],
            [-0.363454727814076, 51.622534149551271],
            [-0.365559452244296, 51.621330650513464],
            [-0.36819716795753, 51.620648872043226],
            [-0.375409998964205, 51.618299452331556],
            [-0.378532859402003, 51.617776121774114],
            [-0.382013401475182, 51.617428488313408],
            [-0.384599078548453, 51.616164736385933],
            [-0.387132163654871, 51.615565620703158],
            [-0.392700990946294, 51.615178901345494],
            [-0.399487049288232, 51.613403954918297],
            [-0.404071972353939, 51.613180423856996],
            [-0.411022495738901, 51.613788151362073],
            [-0.412006890786743, 51.614179137918413],
            [-0.416973106683096, 51.616488829154797],
            [-0.420924202791033, 51.616929595994165],
            [-0.426502955808749, 51.617003598366495],
            [-0.428417106352282, 51.617748601348765],
            [-0.432751615247183, 51.618345166910373],
            [-0.438303626500947, 51.619919022054745],
            [-0.440596701206257, 51.620069994378973],
            [-0.443616026905427, 51.619513886190987],
            [-0.445724621528757, 51.619525633756865],
            [-0.448131415657314, 51.61591929402649],
            [-0.449191456834567, 51.615160885184331],
            [-0.455368876874098, 51.612698475732579],
            [-0.457151951867813, 51.612291203518268],
            [-0.463261612966334, 51.614334295148112],
            [-0.468926119606649, 51.616702167140126],
            [-0.476712374568089, 51.621898318110361],
            [-0.487879231876784, 51.626759861235101],
            [-0.489047855054977, 51.627591377178476],
            [-0.491557673027208, 51.630763645303389],
            [-0.493568623658009, 51.630554771284103],
            [-0.494169216726391, 51.631048940636838],
            [-0.495555476787039, 51.631161125829259],
            [-0.496154613393375, 51.631700226013301],
            [-0.497247039315678, 51.631653965697289],
            [-0.499282728309085, 51.630476875568554],
            [-0.499732262366761, 51.62933254744339],
            [-0.500328150649726, 51.628919335492682],
            [-0.497654440820193, 51.626669534519884],
            [-0.498362576649622, 51.624781286319411],
            [-0.500045980800648, 51.623675209861531],
            [-0.500204919730076, 51.622184572014461],
            [-0.499281279116958, 51.621106321257834],
            [-0.496829747101164, 51.620497675543547],
            [-0.495430319318606, 51.618915145206856],
            [-0.497064759810825, 51.614913962331826],
            [-0.497907242578903, 51.614244949624123],
            [-0.497914574024441, 51.613235242688972],
            [-0.498966999364596, 51.611104112444899],
            [-0.49899715687576, 51.610145951271235],
            [-0.498337633283375, 51.609474806990669],
            [-0.49925613957319, 51.608861608169356],
            [-0.498591747442714, 51.608294710067931],
            [-0.497284203231005, 51.604900584273594],
            [-0.497185215654531, 51.603218711058481],
            [-0.496327635962367, 51.602427223211393],
            [-0.496707443260982, 51.601376425476175],
            [-0.497658150977947, 51.600876950741984],
            [-0.499124606296576, 51.601375882122959],
            [-0.500353254251854, 51.60124861120611],
            [-0.500902622382996, 51.600175687738989],
            [-0.500616930158387, 51.5996873697501],
            [-0.503282348167522, 51.599862558309901],
            [-0.511425246950381, 51.599660368543006],
            [-0.517857387967229, 51.600247104841976],
            [-0.519572327084214, 51.600970119721111],
            [-0.520615411315771, 51.601841105051257],
            [-0.52252238891571, 51.605059073902908],
            [-0.524303692395402, 51.610201510095344],
            [-0.526321074867676, 51.613775081174666],
            [-0.526993672974827, 51.614413858451407],
            [-0.528251272574419, 51.614694892759566],
            [-0.529244628402477, 51.615957223321438],
            [-0.530283617674448, 51.617820778921349],
            [-0.532001426267317, 51.62550970471348],
            [-0.53459499629882, 51.630028179794664],
            [-0.535752625721525, 51.635313690427935],
            [-0.535606961217784, 51.635888256433425],
            [-0.534151675472179, 51.637147851793991],
            [-0.539282010860763, 51.63803633995439],
            [-0.538859242169748, 51.640444511038908],
            [-0.537412993456892, 51.642903780012723],
            [-0.534926057026438, 51.645628801807483],
            [-0.531979375907473, 51.647676329330757],
            [-0.53094951712724, 51.649130032088706],
            [-0.53668782003555, 51.652365922303169],
            [-0.534275182231048, 51.652949084580051],
            [-0.536151843635169, 51.654868882107486],
            [-0.536103772854597, 51.655463546538634],
            [-0.536484662566425, 51.655487176435813],
            [-0.536268902340326, 51.65904347928393],
            [-0.536944605990738, 51.659790132517458],
            [-0.536913523745787, 51.660620595891281],
            [-0.536360940315543, 51.660360139842943],
            [-0.535190592613445, 51.660426476591013],
            [-0.53499303651204, 51.660679382275653],
            [-0.533296620195566, 51.660918075872104],
            [-0.532648047105018, 51.663003287042514],
            [-0.524462624887875, 51.65784729325356],
            [-0.522799622084562, 51.658393777965976],
            [-0.522382750949239, 51.659342575158156],
            [-0.522553394029967, 51.663564620844987],
            [-0.521016672347159, 51.668041228413834],
            [-0.517533663830014, 51.667050473591189],
            [-0.514740275248134, 51.665448794950109],
            [-0.514085186813737, 51.667041967033342],
            [-0.512489903750094, 51.668597155796917],
            [-0.510041652244019, 51.669877144510039],
            [-0.50791414695107, 51.671954242946541],
            [-0.505129599142556, 51.673072499438028],
            [-0.507949157327564, 51.677974761296007],
            [-0.509839425372568, 51.678683937766444],
            [-0.510919018351413, 51.67981262196534],
            [-0.513507610882673, 51.679872382859784],
            [-0.51541542759806, 51.679430729901448],
            [-0.516828079407357, 51.680198497314215],
            [-0.518998649977495, 51.680259152662693],
            [-0.521676796622689, 51.679835200247275],
            [-0.522486937419676, 51.678808629616007],
            [-0.524956030828726, 51.678716470927057],
            [-0.525007825228232, 51.679971487521932],
            [-0.524294713637442, 51.682107991714716],
            [-0.523749174991171, 51.683760137084761],
            [-0.524364612228117, 51.68538640524978],
            [-0.524567300147164, 51.688051441538185],
            [-0.523179437770816, 51.690467200259164],
            [-0.523036683390546, 51.69507552977398],
            [-0.522383661030425, 51.696600429159339],
            [-0.520689928178838, 51.698177866134877],
            [-0.518612565855548, 51.704054839161657],
            [-0.515517110107201, 51.70657215153215],
            [-0.514126113124821, 51.706223669174555],
            [-0.512522272973009, 51.70530509032843],
            [-0.5119152341053, 51.704221978628524],
            [-0.502970355241205, 51.700529588640521],
            [-0.497267737756577, 51.699776104092365],
            [-0.494808307255586, 51.699017178660661],
            [-0.489510047354378, 51.698123726148914],
            [-0.48396110566572, 51.698502747364969],
            [-0.481201782680854, 51.698238714950804],
            [-0.475892874300119, 51.698534135918862],
            [-0.475167242097338, 51.698080520824568],
            [-0.471906421894981, 51.697542700319993],
            [-0.469660241124645, 51.696826482329136],
            [-0.466834460306603, 51.695879659349146],
            [-0.463802719372599, 51.695335609754054],
            [-0.455098304904066, 51.69685619872449],
            [-0.454465344124659, 51.697257005742422],
            [-0.44866498658114, 51.698338716353156],
            [-0.446468186418257, 51.699570355166301],
            [-0.443387468512899, 51.700622096422705],
            [-0.440283190912668, 51.701008042372962],
            [-0.442315231484343, 51.706140611979336],
            [-0.441590547193159, 51.707897912940759],
            [-0.44258964750774, 51.709823739608062],
            [-0.442957022666302, 51.712655663704666],
            [-0.444190738511868, 51.713612558806368],
            [-0.445969982309117, 51.715928138896729],
            [-0.446683575725485, 51.718380671836982],
            [-0.4484631482673, 51.71921525128451],
            [-0.449339283771029, 51.720476688752015],
            [-0.450051417385386, 51.722682801365359],
            [-0.450106219634209, 51.725525857509211],
            [-0.448459556969296, 51.726299009835309],
            [-0.447764809371088, 51.726418418770294],
            [-0.447649425324665, 51.726067110482404],
            [-0.445009293827556, 51.726590600241046],
            [-0.439909426715909, 51.728196395219712],
            [-0.442394285486334, 51.731394498990412],
            [-0.440690270452634, 51.732393378540593],
            [-0.44025369177463, 51.732203251411676],
            [-0.439604060579078, 51.732396944723128],
            [-0.440470033221595, 51.733448800012908],
            [-0.438331115724511, 51.73445715970697],
            [-0.434568722581755, 51.735337762400611],
            [-0.43493368155077, 51.735924397804965],
            [-0.426116341296919, 51.738440366992499],
            [-0.426506912635697, 51.739204511791819],
            [-0.425051469263209, 51.739533901405956],
            [-0.425347097717291, 51.740112444034871],
            [-0.422102366781775, 51.740830537528836],
            [-0.418033831869415, 51.737056796887011],
            [-0.418169422536474, 51.735330379841997],
            [-0.416186471233538, 51.732606102426743],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000103",
        LAD13CDO: "26UK",
        LAD13NM: "Watford",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.388566397877605, 51.696437230884925],
            [-0.384426833875009, 51.696024294215569],
            [-0.379223357931158, 51.695341165332685],
            [-0.379317052777777, 51.694784055204352],
            [-0.378648575391893, 51.694500575407368],
            [-0.37876567065788, 51.692681312384728],
            [-0.375581734578107, 51.692907088992428],
            [-0.375381928055411, 51.692506879560987],
            [-0.378784233390696, 51.689894049022335],
            [-0.378399098091339, 51.68816136962635],
            [-0.372412660331895, 51.6846903886196],
            [-0.37010428072333, 51.684518106379237],
            [-0.369513450082071, 51.6842131703362],
            [-0.369607551541083, 51.683325165824861],
            [-0.371065791423378, 51.681508327209528],
            [-0.371093694704702, 51.680690440257329],
            [-0.370417989042088, 51.678923127499644],
            [-0.369469753017519, 51.678825443460653],
            [-0.36883954432537, 51.678370689068167],
            [-0.371699213517818, 51.676471685247911],
            [-0.374250798676355, 51.674122344058844],
            [-0.374748419721857, 51.673553740927915],
            [-0.379276146471459, 51.670041973718988],
            [-0.382500065772811, 51.670434407217407],
            [-0.383490687421687, 51.669865362103053],
            [-0.384268080447208, 51.668948081901227],
            [-0.383093130099049, 51.668155900982889],
            [-0.383677102846449, 51.665846693221717],
            [-0.385747159837689, 51.664928302225206],
            [-0.385594014568134, 51.664192447640993],
            [-0.386634221623544, 51.663687901327968],
            [-0.386887801050387, 51.663061942361765],
            [-0.386672073348494, 51.662133699174042],
            [-0.385355023157332, 51.660502428812528],
            [-0.386925742376672, 51.657188866583709],
            [-0.383402916606203, 51.652342271521768],
            [-0.383449693833544, 51.648467341127535],
            [-0.380914862675663, 51.647885702680291],
            [-0.380736832090878, 51.648135924782558],
            [-0.377940590187399, 51.646334890087175],
            [-0.376299903937034, 51.644802447969347],
            [-0.371657527225703, 51.64342981871679],
            [-0.375819207414602, 51.641954306361335],
            [-0.37534316434062, 51.641565557899646],
            [-0.376968645705792, 51.640200559582347],
            [-0.379984017942095, 51.639612754331722],
            [-0.377446706943704, 51.637698377682099],
            [-0.378559245898331, 51.637095990120287],
            [-0.380895941974808, 51.638557074044776],
            [-0.382344182925074, 51.63781990215287],
            [-0.382835288020384, 51.638110816218891],
            [-0.383712543181607, 51.637824357997189],
            [-0.386314313955468, 51.638215328572443],
            [-0.386317686541572, 51.639545301365708],
            [-0.386849065606878, 51.639598462100537],
            [-0.387271868647184, 51.639575495139425],
            [-0.387697060005725, 51.638833194380311],
            [-0.390239089476918, 51.640056821091036],
            [-0.394314788374241, 51.637716240445663],
            [-0.399831200434271, 51.640002690069302],
            [-0.398189157655162, 51.641078231603309],
            [-0.402815701304039, 51.643863142787268],
            [-0.404991452905401, 51.643805492868566],
            [-0.408096445572728, 51.642759589671442],
            [-0.409340023946194, 51.640634538084392],
            [-0.409954437574934, 51.64079392561306],
            [-0.410914866055777, 51.639985054353964],
            [-0.412253038493817, 51.640648784092953],
            [-0.414500607956615, 51.640681850091816],
            [-0.41498790679364, 51.641047210768683],
            [-0.418716716990224, 51.640751289417103],
            [-0.424535452767191, 51.641074084106855],
            [-0.428846868065562, 51.641314387077522],
            [-0.431109376863083, 51.642088276834329],
            [-0.429384503337877, 51.643698169374147],
            [-0.426054815283596, 51.645360272779861],
            [-0.427724508407123, 51.646896895023836],
            [-0.430336073943382, 51.648494642516823],
            [-0.426580401910437, 51.650427121305576],
            [-0.426593722298673, 51.652088123521857],
            [-0.424802416120941, 51.654305817559951],
            [-0.424483512434047, 51.654956157681525],
            [-0.42785695790657, 51.655686555748645],
            [-0.427912785909248, 51.656245706237286],
            [-0.430017047917421, 51.656348488682205],
            [-0.431428693885294, 51.657209001915874],
            [-0.432518474762887, 51.657343145448735],
            [-0.436185620471178, 51.659924175976307],
            [-0.43734795632495, 51.661355884259095],
            [-0.437624003405999, 51.663292832600845],
            [-0.438257740395805, 51.664331741850035],
            [-0.436310035338428, 51.66828028057072],
            [-0.438266007056527, 51.670483260334208],
            [-0.439582541990116, 51.672782019541636],
            [-0.4324468261633, 51.672659131167187],
            [-0.431005508786724, 51.6703829016204],
            [-0.42827732493384, 51.670655750586022],
            [-0.427879702782531, 51.671781619982326],
            [-0.428387595472343, 51.672274882710852],
            [-0.427881487462039, 51.672774358026523],
            [-0.427585337672198, 51.674268456815163],
            [-0.430344310052585, 51.676918424375408],
            [-0.431102529950947, 51.6792107146427],
            [-0.429743355528538, 51.680075564081086],
            [-0.431060004666001, 51.680901534673261],
            [-0.431633267822909, 51.681809285177067],
            [-0.431426587879594, 51.682515991034329],
            [-0.430068221434862, 51.683438403564395],
            [-0.428179485689261, 51.684051575237355],
            [-0.427401161334029, 51.685431314998908],
            [-0.425285219452664, 51.685334630656385],
            [-0.423183071695533, 51.685715566472638],
            [-0.415679491763939, 51.687329374191179],
            [-0.412859201654046, 51.68863200626658],
            [-0.40736822683625, 51.696647764520478],
            [-0.405848346400134, 51.696539928383963],
            [-0.403307176055874, 51.699836941165366],
            [-0.402423459028342, 51.702001881190597],
            [-0.396022785712576, 51.700362656835694],
            [-0.388566397877605, 51.696437230884925],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000105",
        LAD13CDO: "29UB",
        LAD13NM: "Ashford",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.955002427815866, 51.270243354890994],
            [0.958120986418795, 51.268502374968612],
            [0.961469840127583, 51.268629018435306],
            [0.964876492374375, 51.268049837403979],
            [0.968158925203296, 51.269259103498023],
            [0.970030005467116, 51.269347432748944],
            [0.97194115128557, 51.269190065481219],
            [0.973248868411423, 51.268591986161937],
            [0.973376293825151, 51.267434760191669],
            [0.973869596477576, 51.267058845514704],
            [0.979167608134428, 51.262676862478159],
            [0.98770147270781, 51.256572331619758],
            [0.991614385976833, 51.255133045561223],
            [0.99321987710262, 51.25502451531046],
            [0.993105295055568, 51.25434206669572],
            [0.996544668479226, 51.251908242886728],
            [0.997539923485786, 51.25187284832981],
            [0.998208673486402, 51.251225193691603],
            [0.997603459234831, 51.249765605767095],
            [0.995379015982056, 51.248772032999661],
            [0.99486637493487, 51.248095281266842],
            [0.992522418536494, 51.246693666368877],
            [0.990381725835989, 51.245794098803202],
            [0.990866378065231, 51.242464534049525],
            [0.989806137779067, 51.240810567907253],
            [0.987428349090734, 51.238392440773779],
            [0.986427813289479, 51.237833350035032],
            [0.986144130871439, 51.23586540028527],
            [0.99130618389173, 51.230408734322971],
            [0.991054457632665, 51.228891500796514],
            [0.990528180197273, 51.228287035851963],
            [0.991347761841645, 51.226788275187872],
            [0.99053903833665, 51.22604712216031],
            [0.990297119602693, 51.224304772695916],
            [0.991768539687488, 51.223559269950066],
            [0.995087660439055, 51.222791625520642],
            [0.997196894483413, 51.221283169189135],
            [1.000660259656303, 51.215251653599843],
            [0.999385513101986, 51.213839832234974],
            [1.000116275414262, 51.21285507079007],
            [1.002637323601443, 51.212644650786508],
            [1.004474460194773, 51.211740280410119],
            [1.005367834157509, 51.208483775614042],
            [1.003246096270165, 51.202923879788557],
            [1.002884382329553, 51.197387115257712],
            [0.997030754714532, 51.191722318254392],
            [0.993610539771503, 51.19100672741191],
            [0.989672457056887, 51.190780093338809],
            [0.989369241604413, 51.190231083185964],
            [0.989842329946282, 51.187921774292882],
            [0.997811026876913, 51.182664559895748],
            [1.003939374151078, 51.180050827366941],
            [1.002759150937401, 51.178846189764855],
            [1.001663258273392, 51.17559130515297],
            [1.00050141359071, 51.17358745325177],
            [0.999459220792665, 51.172036546355777],
            [1.00125116950123, 51.169490952562832],
            [1.000679224176534, 51.168218507602354],
            [0.998573841601458, 51.166595939788635],
            [0.998406149506794, 51.164739257496848],
            [1.00300978121846, 51.164432190627416],
            [1.008182099568228, 51.163302590445248],
            [1.009541305970896, 51.163437601310001],
            [1.011719001178899, 51.162588185339075],
            [1.013536389210189, 51.161347756433109],
            [1.015747651681721, 51.161497602202019],
            [1.016892568385666, 51.161199117245566],
            [1.009466504798838, 51.154095003293413],
            [1.011080341938465, 51.153290693127502],
            [1.011851140584326, 51.152499092695045],
            [1.011898180924826, 51.151377147781893],
            [1.013251795323862, 51.151080513574655],
            [1.012389442809277, 51.149895661275046],
            [1.009731743001812, 51.147953827835863],
            [1.008431837008611, 51.145536254797427],
            [1.008591517872448, 51.145001460203446],
            [1.010194039730738, 51.144159672398544],
            [1.011997703363271, 51.143706644168994],
            [1.013057587901739, 51.142789752508641],
            [1.016530455113843, 51.14225852796578],
            [1.017982862796198, 51.141289183163934],
            [1.016460955661267, 51.140228427051959],
            [1.020537022596696, 51.134662480791022],
            [1.022417393829766, 51.133946458217309],
            [1.023916771624855, 51.134138833218145],
            [1.025057625083178, 51.133920418386857],
            [1.029210110169268, 51.132536506244712],
            [1.026878204027633, 51.129517146673898],
            [1.024419096802851, 51.128570713514236],
            [1.022899038602827, 51.125608516288217],
            [1.023843461642441, 51.125077679785996],
            [1.022098945688793, 51.123841066185868],
            [1.02053351658445, 51.124076726432769],
            [1.017549653496245, 51.125422068895091],
            [1.016755096330268, 51.122859310850174],
            [1.016345099578167, 51.122994021630092],
            [1.015488416860728, 51.121845024476798],
            [1.016520891692011, 51.121261604485177],
            [1.017040546714355, 51.120200307084247],
            [1.016841319055665, 51.119402225184515],
            [1.014847820444665, 51.118790768536513],
            [1.012086014431007, 51.117198877266588],
            [1.011214648524948, 51.117226732882777],
            [1.009245687374327, 51.116264656483956],
            [1.007928371649653, 51.116554923265781],
            [1.006409733482724, 51.117772309172018],
            [1.00400009902759, 51.118981102478259],
            [1.003009170944284, 51.118224037361706],
            [1.000218646817579, 51.118453126896156],
            [0.998944031363822, 51.118896902867341],
            [1.000476904959434, 51.121246533455569],
            [0.995557321673848, 51.122369360173522],
            [0.995349346415529, 51.121938451000901],
            [0.99604026411227, 51.120777518104546],
            [0.996233474365534, 51.118993421676066],
            [0.995388237685577, 51.118166896123419],
            [0.994095051857316, 51.117970675667877],
            [0.993346045381551, 51.117460080560576],
            [0.991728434769326, 51.117683199965661],
            [0.991105154381739, 51.117304291354195],
            [0.989705932223874, 51.117125128328532],
            [0.988820597556684, 51.117578620642945],
            [0.986100276805477, 51.115351838971733],
            [0.983830635034717, 51.114570619139634],
            [0.98452377211121, 51.11412837000217],
            [0.983684641044991, 51.113857474548553],
            [0.981821835220782, 51.113888836287963],
            [0.98098812407064, 51.113457678819287],
            [0.979734538414736, 51.112186331393254],
            [0.982176520004895, 51.110876475101556],
            [0.981658705180422, 51.110046892386741],
            [0.983359160899887, 51.108318790280897],
            [0.980415013723146, 51.107462982055381],
            [0.978625612259247, 51.10618820019797],
            [0.977940873939425, 51.105160477501116],
            [0.978473338469899, 51.104376064451415],
            [0.979768549538203, 51.10458859308941],
            [0.980270009699415, 51.103611577324266],
            [0.982117756617228, 51.101932801470191],
            [0.982072193920529, 51.101557988069573],
            [0.983135370157637, 51.101318573256357],
            [0.983882612408155, 51.100277699002554],
            [0.985382426611725, 51.100119755949748],
            [0.985139989363274, 51.097599351187355],
            [0.983681481571124, 51.093540440408873],
            [0.982356159396327, 51.092722486123101],
            [0.983433367953395, 51.091084937695769],
            [0.980226645197905, 51.089593619172177],
            [0.97990538937393, 51.087485370449748],
            [0.980200147682383, 51.086850023983644],
            [0.976909320406201, 51.084598914920726],
            [0.976554527353185, 51.083166111501363],
            [0.977598909985252, 51.081173262515762],
            [0.975507045310905, 51.079017474868373],
            [0.972752014872952, 51.079698355170954],
            [0.971114031989847, 51.078270300300794],
            [0.969978523710337, 51.077863834272428],
            [0.969697227133312, 51.07694993307053],
            [0.968001366877664, 51.077313243148517],
            [0.96741430989981, 51.077064614170084],
            [0.967710544449915, 51.071588338059264],
            [0.966618171758369, 51.070737308146697],
            [0.966542834885633, 51.070229224362876],
            [0.96664281830707, 51.068801931069174],
            [0.968328731290239, 51.068733915426769],
            [0.968135081564834, 51.067024453459084],
            [0.968894756647717, 51.062925165475555],
            [0.966159404760749, 51.062338050460021],
            [0.963556284980117, 51.060736516713177],
            [0.960660614785627, 51.060161437151102],
            [0.958200415322784, 51.060258851722914],
            [0.95561091241148, 51.059379968851708],
            [0.954357655956779, 51.058639035243758],
            [0.95435605058774, 51.057807070629508],
            [0.953473383472619, 51.057281619686705],
            [0.951778158852668, 51.056950297159659],
            [0.949302711810109, 51.057029921495719],
            [0.946567485847786, 51.055266749154583],
            [0.944094113054085, 51.054905474129264],
            [0.943757028705923, 51.056003220973231],
            [0.939786665436176, 51.057062948825632],
            [0.936531246692897, 51.058349220893277],
            [0.935807639546883, 51.058033710813454],
            [0.935095022699528, 51.058681243770408],
            [0.934240462350956, 51.061146551855231],
            [0.932478044176982, 51.060474841722765],
            [0.93155023556123, 51.058960952916578],
            [0.930130667790194, 51.058879643568361],
            [0.930008220181728, 51.058545417437713],
            [0.927324066462271, 51.057852675694171],
            [0.926476159795353, 51.056493251485634],
            [0.921301666283811, 51.056116390736989],
            [0.922759660404598, 51.05207461890933],
            [0.919639505920097, 51.051883719069593],
            [0.919434972220809, 51.052602103475039],
            [0.91671811245208, 51.052152793080651],
            [0.916256999169377, 51.053444237981836],
            [0.915349612172798, 51.053425515435315],
            [0.914956433631017, 51.053391255305549],
            [0.915836596424462, 51.050433450216929],
            [0.914404838715962, 51.049812580659136],
            [0.913934190614305, 51.049771255843098],
            [0.913646355992252, 51.05025515011517],
            [0.913109016807266, 51.0503108283038],
            [0.909740598811827, 51.049958557677726],
            [0.906667703815731, 51.051225055887556],
            [0.903290735070416, 51.050462659141729],
            [0.90111500000532, 51.049077591585238],
            [0.897940020370069, 51.044919071699887],
            [0.899656507976628, 51.043023585321286],
            [0.900088551616628, 51.038657682245393],
            [0.897980450438132, 51.038253987695974],
            [0.896817583762472, 51.037702687728192],
            [0.896353340967321, 51.037876104608806],
            [0.894447582975434, 51.036987018028881],
            [0.893848667414265, 51.037403018313348],
            [0.893121937800471, 51.039453796921649],
            [0.892055457620989, 51.040036975371109],
            [0.891225976863777, 51.039592494157624],
            [0.890171926498088, 51.041108983104223],
            [0.887906613077683, 51.039990331505848],
            [0.885548233046292, 51.040218622465325],
            [0.883938010397603, 51.041834241680299],
            [0.882096415707816, 51.042712597663062],
            [0.882782899415641, 51.042945703375864],
            [0.883160264069571, 51.04369193022157],
            [0.882640604819044, 51.044950502317313],
            [0.881945679171881, 51.045248282029888],
            [0.882358436882106, 51.046334531160433],
            [0.881415549787793, 51.048338394886898],
            [0.87807364270327, 51.051236982086287],
            [0.877204909267589, 51.051633457547688],
            [0.876027478195407, 51.050917709428113],
            [0.875243102589864, 51.050994586168805],
            [0.87265800642515, 51.052803132419562],
            [0.87218014839459, 51.052414628077884],
            [0.871655157067302, 51.052647902487443],
            [0.871085794044299, 51.052141116916168],
            [0.870401319980013, 51.052310846971942],
            [0.868969588138242, 51.05161207114557],
            [0.869609407001675, 51.050858802950209],
            [0.870583567307028, 51.051035442611536],
            [0.870438733681575, 51.049478452986641],
            [0.868847852379673, 51.049204530820603],
            [0.867732052363005, 51.04772804923784],
            [0.870307432285652, 51.04823234084845],
            [0.870873750124116, 51.047214633209684],
            [0.871388680943519, 51.047073353153372],
            [0.870786965731526, 51.046388370700214],
            [0.871055506291621, 51.04558575419982],
            [0.869307298493726, 51.045142112307119],
            [0.869650360188641, 51.044113704566186],
            [0.866983056670942, 51.043179919687191],
            [0.866531365137903, 51.043951943311725],
            [0.861436439225343, 51.043792654086751],
            [0.860408538428552, 51.044315229651261],
            [0.857966945690575, 51.044472142259728],
            [0.855704771757552, 51.045017676547793],
            [0.855251048522461, 51.043695784412193],
            [0.854028679316728, 51.043562860896174],
            [0.85340689785653, 51.043149915291082],
            [0.853335652665877, 51.042292681116358],
            [0.855411009578649, 51.042214076824159],
            [0.855492319511458, 51.040265677887803],
            [0.856032919675057, 51.039382690153822],
            [0.855889470880595, 51.036053732005506],
            [0.856817720024955, 51.035258400167059],
            [0.855834645708223, 51.033933266993216],
            [0.856719817367822, 51.032554345152228],
            [0.854631236609918, 51.03259642223356],
            [0.854775511993315, 51.031764500683813],
            [0.85258386546423, 51.032055510427504],
            [0.849453331116713, 51.033341696899321],
            [0.846186125700358, 51.03307597970722],
            [0.845316436814659, 51.032617765120627],
            [0.84031539409906, 51.031942590841126],
            [0.839059889925414, 51.031355197914294],
            [0.837484164793474, 51.032646425904296],
            [0.839624356248536, 51.034521026056353],
            [0.839190166982554, 51.036168578845142],
            [0.840083110797215, 51.038942353144279],
            [0.833573998545038, 51.040027789015532],
            [0.833516297482484, 51.041466505265866],
            [0.831285697413763, 51.041292141219401],
            [0.831059451925398, 51.041723063842269],
            [0.831135090838063, 51.043594777195629],
            [0.832769536929374, 51.04458950983225],
            [0.834246077835534, 51.044656225250961],
            [0.837848741974327, 51.044072249748361],
            [0.837176984281863, 51.044846803977805],
            [0.83761734385389, 51.045841687910482],
            [0.837038966412992, 51.046416993307197],
            [0.836153177479484, 51.046678658364328],
            [0.836072163789935, 51.047034108128258],
            [0.836583943088425, 51.047143105314092],
            [0.835461741685883, 51.04818313084597],
            [0.833909836485489, 51.047912285305863],
            [0.832062968059983, 51.048419396903483],
            [0.83083832002264, 51.049372815556104],
            [0.785736003100574, 50.997996405324223],
            [0.778923473796187, 50.989494404936266],
            [0.778582280689825, 50.990820211295762],
            [0.775981543594016, 50.991658297115627],
            [0.775692717025366, 50.992000656492216],
            [0.77281059742263, 50.992734728223446],
            [0.757393928876791, 50.99544558155484],
            [0.751300452835504, 50.997245195725519],
            [0.750910509929985, 50.997592572665184],
            [0.750081917286751, 50.997295489240926],
            [0.749351557550112, 50.997502470945832],
            [0.748054494039139, 50.998394667783408],
            [0.740579148318372, 51.000679545958349],
            [0.737726900759546, 51.000825607250981],
            [0.731434798065172, 50.999972794382934],
            [0.731314407137108, 51.000398345330922],
            [0.725375242692188, 50.999216751706037],
            [0.72111079174796, 50.999465516604026],
            [0.711223521285687, 50.998474984532301],
            [0.70848417690209, 50.997744360700828],
            [0.705752299715571, 50.995107603487135],
            [0.706739486301392, 50.993605156373668],
            [0.702044256322472, 50.99597417932825],
            [0.701196294051046, 50.997324957731919],
            [0.699188882218316, 50.998493869623331],
            [0.689442494653154, 50.99931058957813],
            [0.682207550748781, 51.000550923276244],
            [0.681456866898746, 51.001301198129177],
            [0.676925146072145, 51.003302067781455],
            [0.675313277031776, 51.004497469678739],
            [0.672912536407838, 51.008139357878143],
            [0.671725419903127, 51.008990398001359],
            [0.670910021170178, 51.011423107638379],
            [0.664898559186102, 51.016113328163989],
            [0.662074917765328, 51.017475595047813],
            [0.661274735066158, 51.017795139034163],
            [0.658621857376117, 51.017584836645462],
            [0.657819770808158, 51.01712370162906],
            [0.656424360951068, 51.015163230763491],
            [0.654961912256469, 51.014722492350202],
            [0.652186631315722, 51.015211879603939],
            [0.649793740024394, 51.015181653325584],
            [0.648123278943924, 51.015943578025869],
            [0.647229221301825, 51.016794013177012],
            [0.646655790382343, 51.016840288724836],
            [0.645728254697754, 51.016202030434265],
            [0.64566212165834, 51.015190781124382],
            [0.643804802687448, 51.014549283668885],
            [0.63925554502878, 51.012024079685368],
            [0.637132942975679, 51.011879555765965],
            [0.636021547588376, 51.012218545437605],
            [0.633605619238116, 51.014017926711624],
            [0.630351937375403, 51.017368736442933],
            [0.629249970698297, 51.017776702754773],
            [0.627631657716892, 51.017854498475643],
            [0.625968337502911, 51.017337869430534],
            [0.624576590147019, 51.016125260992709],
            [0.622417012899107, 51.015706977062443],
            [0.620715593590922, 51.016054569667418],
            [0.618265507051918, 51.015211907526371],
            [0.616015689345706, 51.014987104590787],
            [0.615115859017035, 51.013616760338167],
            [0.611637187746085, 51.01202349146299],
            [0.609352451197875, 51.012366873468743],
            [0.605360941485115, 51.012072839438915],
            [0.604570421371459, 51.01246822709625],
            [0.604699041050622, 51.014076210196521],
            [0.606133388025724, 51.017304588412216],
            [0.606034418145095, 51.019963666577254],
            [0.597225440378297, 51.020820065823976],
            [0.595819023714803, 51.024948156182504],
            [0.597511354411385, 51.025168662258437],
            [0.602426866850031, 51.026764524316704],
            [0.59928685861345, 51.029500304663109],
            [0.598385611125424, 51.029503269161992],
            [0.598158951608213, 51.029887863122802],
            [0.597105555128178, 51.029924782084862],
            [0.596065884709704, 51.030385908588769],
            [0.594167519867206, 51.030565697453007],
            [0.593302735524529, 51.031451933500371],
            [0.595101614779817, 51.033702778926795],
            [0.593950366813324, 51.034875099958988],
            [0.594922083851831, 51.035748429662007],
            [0.595024028824953, 51.036449503778961],
            [0.594089228267093, 51.037156518283339],
            [0.593362202938198, 51.037293183207879],
            [0.59290716985185, 51.038034505421706],
            [0.593215917644209, 51.038632954186703],
            [0.592426871293785, 51.039984293594976],
            [0.593470044995695, 51.041854394088496],
            [0.595644308246079, 51.042186550001631],
            [0.597049803507234, 51.044485680796839],
            [0.599645241647309, 51.046144921787608],
            [0.600360856517899, 51.049750017554921],
            [0.601540040043575, 51.04965810572029],
            [0.603635683802569, 51.051073856669923],
            [0.604267382570817, 51.052566287141595],
            [0.604699679821506, 51.052463110381957],
            [0.60544616514334, 51.053660666980214],
            [0.604104438295399, 51.05370587405104],
            [0.604976830114353, 51.054578638119224],
            [0.605508441773024, 51.056472631467905],
            [0.606832848655877, 51.057032198668793],
            [0.607378284500599, 51.058662348206553],
            [0.607842036066494, 51.058457724370562],
            [0.61049064198632, 51.060964585930108],
            [0.609775613032155, 51.061599354991529],
            [0.611946379446851, 51.06248442942541],
            [0.611686588519385, 51.063548842527958],
            [0.613037274847117, 51.0637084155221],
            [0.618676732637218, 51.06555455092073],
            [0.627263570062335, 51.066580466811686],
            [0.628436634986648, 51.067759292572987],
            [0.633833459179736, 51.070818072018497],
            [0.636550970571381, 51.073821075276449],
            [0.638797842151925, 51.07527146893591],
            [0.640838548645595, 51.075731730085337],
            [0.644679477125459, 51.077890685022489],
            [0.642617202684803, 51.078958180282562],
            [0.642532800759802, 51.079756968508981],
            [0.643558620402957, 51.080243730185103],
            [0.643372013819618, 51.080401751585335],
            [0.64664360218637, 51.083284059369412],
            [0.642550479083821, 51.082797480869239],
            [0.638232646097054, 51.086013312332618],
            [0.635817867184998, 51.086641633830013],
            [0.633530193618245, 51.088000059222104],
            [0.631252904158598, 51.088185374751063],
            [0.629256294466141, 51.089542556276101],
            [0.629347322300323, 51.091423870298897],
            [0.628479316847174, 51.091228439943201],
            [0.625780696557038, 51.089601821994677],
            [0.621472356911545, 51.09112321865652],
            [0.620082206097956, 51.090507723320393],
            [0.620569861601382, 51.08960997195652],
            [0.619095048827768, 51.089289570709866],
            [0.618478787970559, 51.089478766485577],
            [0.611324996599259, 51.089322264132228],
            [0.609792742702765, 51.090668729562005],
            [0.606920271131535, 51.090940611150359],
            [0.605028645969696, 51.090419769379821],
            [0.601187801637759, 51.091046773167193],
            [0.600974940883457, 51.090740317344306],
            [0.59923662449808, 51.090863540875162],
            [0.596593096292314, 51.090252256742069],
            [0.596921748101974, 51.090663176141803],
            [0.59454880116957, 51.094144413947149],
            [0.595752945626963, 51.096721444575479],
            [0.595366987774362, 51.097914530837535],
            [0.596470417203885, 51.099481962430303],
            [0.595181669205643, 51.104092145040973],
            [0.596141271612476, 51.105461307241782],
            [0.595569261642491, 51.105910222793703],
            [0.595568297650465, 51.108340427795518],
            [0.590338479135462, 51.111341738236916],
            [0.592615391852308, 51.111866867164927],
            [0.595507590981225, 51.113018662925633],
            [0.599167138251158, 51.115364819009862],
            [0.604101696855602, 51.116185856540469],
            [0.608993676762719, 51.117871063695404],
            [0.608869245867046, 51.119187868132968],
            [0.609748356507073, 51.121701858067503],
            [0.608472735886584, 51.125541998129648],
            [0.607090726897215, 51.127192653551646],
            [0.60656103592635, 51.12705066941907],
            [0.603765745975042, 51.128506142931762],
            [0.602912871762483, 51.130038828945985],
            [0.603243425943631, 51.131010914190952],
            [0.60375632472517, 51.131158684027014],
            [0.60361814968292, 51.131675319264403],
            [0.60487381714525, 51.131864098892109],
            [0.605901637453412, 51.131585759727493],
            [0.606447708429517, 51.132398334562737],
            [0.607893640860748, 51.132589134996238],
            [0.609135075502658, 51.133310631824401],
            [0.609368216894431, 51.132704627937294],
            [0.610048510247127, 51.132955662172087],
            [0.61114876065791, 51.13226373381886],
            [0.611810622611195, 51.132441418667121],
            [0.611958239861694, 51.132093649524663],
            [0.612675266178712, 51.132286286009929],
            [0.613197789069989, 51.132097423474534],
            [0.613477961192399, 51.131388727003596],
            [0.614235027544299, 51.131885355773754],
            [0.61371658440549, 51.132095717416782],
            [0.614291235151032, 51.132576637835498],
            [0.613744455131248, 51.132715681179299],
            [0.617230745413747, 51.133242970203789],
            [0.619289877196113, 51.132168830073248],
            [0.619879393265783, 51.132153819644671],
            [0.621262958181306, 51.13323626124496],
            [0.62386716926806, 51.134415410562589],
            [0.623108617537267, 51.13482817264002],
            [0.6237362644844, 51.135510223727074],
            [0.623434584668651, 51.135786815576722],
            [0.624430617599003, 51.136410228094476],
            [0.624537949708213, 51.137798294489201],
            [0.625803851357767, 51.138364370143876],
            [0.626255706886742, 51.139020676448091],
            [0.627823993074793, 51.13894405879573],
            [0.628527869153728, 51.137820168848286],
            [0.629554176198349, 51.13731410665153],
            [0.633069487563808, 51.136809557035484],
            [0.636422273355153, 51.136841014134454],
            [0.640075160875699, 51.138122965308128],
            [0.642133187228695, 51.140464385669468],
            [0.640376430858451, 51.140646185820486],
            [0.639444098865982, 51.141295025467016],
            [0.641151733568057, 51.141304123813242],
            [0.643950238070142, 51.143564023017348],
            [0.646033229165239, 51.143859565156653],
            [0.646386148475218, 51.144330059555251],
            [0.647563802439936, 51.144570516635703],
            [0.648302820482011, 51.144616734988773],
            [0.64997159796202, 51.145392879058846],
            [0.648157333169751, 51.146471006546768],
            [0.649361070415537, 51.14773977445455],
            [0.646784923955766, 51.149349602941165],
            [0.648839692579472, 51.150595507268136],
            [0.648712841719286, 51.151326899086975],
            [0.64756073333951, 51.152210129167749],
            [0.647612928760952, 51.152672140217597],
            [0.650169143905395, 51.154579418882896],
            [0.652173252425467, 51.15533533979783],
            [0.65311988054542, 51.156703440689611],
            [0.655471238057011, 51.157152822577466],
            [0.657574052410055, 51.158129462529864],
            [0.658932737743097, 51.16081387296628],
            [0.658647573505765, 51.163838760175395],
            [0.661377758037792, 51.164723716862994],
            [0.663228660174303, 51.166732212793129],
            [0.666602142868993, 51.168150131594167],
            [0.666049768079627, 51.168593543599904],
            [0.665872159112502, 51.170350533611142],
            [0.664520547809582, 51.17134910294466],
            [0.665325735264856, 51.171220123910196],
            [0.665606466611507, 51.17147365196201],
            [0.666852675945888, 51.171202394457886],
            [0.667624302547711, 51.171656979406102],
            [0.667612668509965, 51.171158075092855],
            [0.667998816260278, 51.171056625536849],
            [0.66793223068291, 51.171340558109627],
            [0.66870717337819, 51.171455984608599],
            [0.669225160705743, 51.171011546517249],
            [0.670491308149027, 51.170813545971363],
            [0.671465203270965, 51.171227509540351],
            [0.671607422308033, 51.171633489485892],
            [0.672133587190962, 51.17152882678468],
            [0.672362968385786, 51.172180150339983],
            [0.672584125138141, 51.171872895043627],
            [0.673197010404215, 51.172278007139077],
            [0.673544206930818, 51.171824862708178],
            [0.675185284119379, 51.171826000262648],
            [0.675369123947598, 51.172134787119404],
            [0.676688556431088, 51.172263783475856],
            [0.677349533096938, 51.172831465069855],
            [0.676136393335578, 51.173129959347378],
            [0.676600989296846, 51.173592412244588],
            [0.676064496967238, 51.174754133281581],
            [0.676644874709524, 51.175189652080398],
            [0.675881368755262, 51.175940143645988],
            [0.676675304239155, 51.177344831143472],
            [0.677602961506516, 51.177500774333552],
            [0.677266529970719, 51.178040926323476],
            [0.678379814786113, 51.179201746246001],
            [0.68078771443406, 51.179220316353728],
            [0.684916985953692, 51.178696554308644],
            [0.68406386646637, 51.181301934615909],
            [0.684904075032674, 51.184382898294217],
            [0.683858766253801, 51.185431318622882],
            [0.683685013074914, 51.186751137053221],
            [0.681829768587746, 51.187975519613431],
            [0.683716045028174, 51.191126931323375],
            [0.685724833076292, 51.192221258334762],
            [0.688347892640557, 51.192580108072839],
            [0.692756081558983, 51.195568143357669],
            [0.694459088436567, 51.194482007462859],
            [0.696189943796495, 51.194217261608578],
            [0.69833134185589, 51.195662683630268],
            [0.698256966502286, 51.195957606396512],
            [0.699269316119003, 51.196305698451432],
            [0.699142729761889, 51.196735838554162],
            [0.700544559294167, 51.196645012877283],
            [0.701807705233836, 51.197339858868133],
            [0.699380998886139, 51.199953812409767],
            [0.701208969630813, 51.200073497029507],
            [0.702320791196485, 51.200749348035018],
            [0.708001265026314, 51.199697853331529],
            [0.709751721661369, 51.199618631273601],
            [0.710147594588689, 51.20016799015626],
            [0.709599509670919, 51.201464145861337],
            [0.710167101266974, 51.203889288289972],
            [0.712063058418075, 51.206064174850702],
            [0.713888807354015, 51.207236921503103],
            [0.715538072071084, 51.209448057398831],
            [0.716204891535461, 51.209440667121108],
            [0.716796202203639, 51.211142103592508],
            [0.723847398330198, 51.211415206120989],
            [0.723948974767165, 51.210588981618244],
            [0.725695516011039, 51.209006701797207],
            [0.727748511886967, 51.208856303230142],
            [0.728978718526514, 51.209271912107482],
            [0.733742741073079, 51.209081501160952],
            [0.735636981845743, 51.209562463619626],
            [0.737834204295702, 51.209212442286223],
            [0.740041524152681, 51.208140814339814],
            [0.743157408628117, 51.208457215244444],
            [0.745447102279634, 51.209326283353697],
            [0.746861337310208, 51.210280630432642],
            [0.746990048483764, 51.210590602960814],
            [0.745983688226645, 51.211750194576879],
            [0.744051105915995, 51.212072573751293],
            [0.743757964970851, 51.212825963400199],
            [0.744553102915033, 51.214661894252046],
            [0.749553845037591, 51.21603206440151],
            [0.750645293465229, 51.217428371565163],
            [0.751190519246922, 51.219366373393974],
            [0.754571863726423, 51.21838197623125],
            [0.755119085942763, 51.219007670208725],
            [0.758699862546664, 51.221862548437535],
            [0.76013081188223, 51.224042245111384],
            [0.76124889419374, 51.224487147425947],
            [0.7657739847606, 51.224706718898574],
            [0.769581064888122, 51.226063790408872],
            [0.771256107738231, 51.227098016414551],
            [0.772334092352613, 51.228373923995598],
            [0.777488210547046, 51.231347434716085],
            [0.778506081621473, 51.234093459524836],
            [0.782424551200641, 51.23936532832451],
            [0.787233846145949, 51.239045765878828],
            [0.788887681076295, 51.23843611695176],
            [0.79262680365098, 51.23807348587944],
            [0.795733630369476, 51.236877697856343],
            [0.792348272909668, 51.23335729495097],
            [0.792148107770679, 51.232015645055242],
            [0.791215118068724, 51.230826397350469],
            [0.794032576300262, 51.230181702679317],
            [0.79354427551521, 51.229641123253771],
            [0.795508117438954, 51.22942601187772],
            [0.795087370732235, 51.228534849758496],
            [0.79729556859946, 51.228074622743172],
            [0.798207141316504, 51.229074558130115],
            [0.799508809524098, 51.228476776085024],
            [0.800138586393092, 51.229482541762465],
            [0.803916421894014, 51.229651971274286],
            [0.803726945583367, 51.228185062213527],
            [0.804637185055189, 51.228006740135328],
            [0.805308712031546, 51.229014176039882],
            [0.808264963517909, 51.227642672016778],
            [0.808866928635517, 51.22828299572479],
            [0.809752763797695, 51.227116755864735],
            [0.811269119496108, 51.227412205312348],
            [0.8120311253733, 51.227974913705211],
            [0.816270636821322, 51.225197127910626],
            [0.82107803739119, 51.224429189296956],
            [0.82213874189068, 51.224347835628009],
            [0.823258013572996, 51.226603560053604],
            [0.825163064856349, 51.228565972346807],
            [0.835286492187126, 51.231919606046127],
            [0.841737448367704, 51.237101143934147],
            [0.845688948926051, 51.234430917877297],
            [0.848575141700838, 51.233585357270314],
            [0.849568409491671, 51.232757974404386],
            [0.851421958539686, 51.231548942723585],
            [0.854480830821354, 51.230697224672575],
            [0.859843028654763, 51.230836963781115],
            [0.861000087283451, 51.235330996169878],
            [0.863463640672739, 51.238871125646163],
            [0.867730455771707, 51.239881885896985],
            [0.871751950857875, 51.239758037620135],
            [0.87628783691541, 51.24009360404407],
            [0.880021035990703, 51.239024961374696],
            [0.881110512296937, 51.241256603776819],
            [0.882424658037756, 51.242862991821539],
            [0.888620704759807, 51.245079248103522],
            [0.891380158941125, 51.246714596777728],
            [0.892026863857189, 51.247634019672859],
            [0.896694275655583, 51.247014848454043],
            [0.90234337987705, 51.245237848103059],
            [0.904942397712255, 51.245593367545581],
            [0.906367966952467, 51.247792152680937],
            [0.903674655784549, 51.249057986935995],
            [0.905290633548031, 51.250184429143481],
            [0.909755237967859, 51.250686872809716],
            [0.910250335023885, 51.250197855485823],
            [0.91674468481807, 51.251247642050146],
            [0.919500071573666, 51.251100654145489],
            [0.920818968364093, 51.252575186166055],
            [0.919860442988024, 51.25495474083241],
            [0.920077926770479, 51.256870508700956],
            [0.920620433179817, 51.257740212071106],
            [0.921834044300361, 51.258138937458774],
            [0.923698113180454, 51.259412737482286],
            [0.926899853383976, 51.259605203573436],
            [0.93130184346794, 51.258024401749665],
            [0.933393026607114, 51.256513092681764],
            [0.933488610710239, 51.25576326203322],
            [0.937938284160438, 51.253366115706093],
            [0.941893147244768, 51.253439412329264],
            [0.949092366916861, 51.256032011927914],
            [0.9510820243328, 51.257577444347838],
            [0.950118196656713, 51.260612185850619],
            [0.950275659952591, 51.262354030187758],
            [0.952393296701011, 51.265599755937657],
            [0.954313074101624, 51.266606335456878],
            [0.954164412544465, 51.267261285051653],
            [0.955264000118394, 51.269180803747489],
            [0.955002427815866, 51.270243354890994],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000106",
        LAD13CDO: "29UC",
        LAD13NM: "Canterbury",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [1.121980277673862, 51.296467578727466],
              [1.122748212000872, 51.296134969729515],
              [1.124909614274003, 51.29620948439964],
              [1.125347220924341, 51.295787637952891],
              [1.123835598296046, 51.295541975626008],
              [1.122229969971651, 51.295771023529156],
              [1.121728559011525, 51.295993079476723],
              [1.121980277673862, 51.296467578727466],
            ],
          ],
          [
            [
              [1.233852297999702, 51.325982235710924],
              [1.232927886818667, 51.325984391376061],
              [1.231163859482589, 51.326785982558405],
              [1.231948533054632, 51.328001107182693],
              [1.231689513561835, 51.328625307894683],
              [1.232379723822193, 51.328823920798875],
              [1.233646784984447, 51.328242927433173],
              [1.234194467022785, 51.327599950695273],
              [1.234487162841859, 51.326618616427645],
              [1.233852297999702, 51.325982235710924],
            ],
          ],
          [
            [
              [1.225265509612621, 51.371665569206812],
              [1.224834703282588, 51.369973822316688],
              [1.22516832277182, 51.369527483873604],
              [1.226542308972407, 51.369121733631523],
              [1.229843002449408, 51.369018117967187],
              [1.230459345677979, 51.368363378431155],
              [1.228865900009498, 51.364548565935756],
              [1.220529332895783, 51.366739986848231],
              [1.217139409849857, 51.366286317281201],
              [1.21472693879915, 51.366390372136536],
              [1.21310074622623, 51.366149885033245],
              [1.211988286237465, 51.365530092217412],
              [1.210753256082322, 51.363610307313259],
              [1.21393755076155, 51.356538466895827],
              [1.217402647649635, 51.353833879674461],
              [1.220210175185394, 51.35236787408963],
              [1.220557766556955, 51.351528089319601],
              [1.22027333147198, 51.349910552487664],
              [1.22158710751994, 51.349473229893967],
              [1.223224967098203, 51.349642213590755],
              [1.225224245416734, 51.349292108778457],
              [1.227890690347514, 51.348176101281148],
              [1.228581413875538, 51.346846502613786],
              [1.227543303848014, 51.345482734309556],
              [1.227651938661981, 51.344637822019266],
              [1.229719080987691, 51.342540767121122],
              [1.230793822968423, 51.340098686581342],
              [1.231694457342085, 51.339061000729345],
              [1.237851485116181, 51.336116805750414],
              [1.242593633424791, 51.334762206147623],
              [1.244625601719988, 51.333138079702849],
              [1.244717514568704, 51.332142496528512],
              [1.246764467122589, 51.33020489295258],
              [1.247793549604182, 51.329712200729681],
              [1.24971902549941, 51.329388899459218],
              [1.2483141814792, 51.328587780671327],
              [1.245645138567, 51.328770675951404],
              [1.242102044289695, 51.330666966938033],
              [1.239810705498233, 51.330873443276872],
              [1.235521569781012, 51.330705172199458],
              [1.234033646754183, 51.332269130921006],
              [1.231800568595657, 51.332346111995491],
              [1.230420412401526, 51.331668227760581],
              [1.231803723084294, 51.330187259268314],
              [1.232140223788937, 51.328950167569808],
              [1.230467745745449, 51.32870761376951],
              [1.22776286234613, 51.326767404499478],
              [1.22940130147031, 51.325771444265882],
              [1.229713965676813, 51.32513407468857],
              [1.227177786709227, 51.325530557861747],
              [1.224049226977372, 51.325239910580734],
              [1.222941197615371, 51.32430258724245],
              [1.220759527253242, 51.323981272603056],
              [1.220083888381482, 51.323067101238316],
              [1.217955738364516, 51.322258540900549],
              [1.217405965498699, 51.321070148651685],
              [1.215800982473896, 51.320699604785283],
              [1.214544721268961, 51.321804507088096],
              [1.212290813214555, 51.322500536524771],
              [1.210853587748483, 51.323893702003076],
              [1.209493963037422, 51.323563111744001],
              [1.205623014579406, 51.323841915608874],
              [1.203300631061115, 51.323373917616763],
              [1.20086198747958, 51.322472805937615],
              [1.198783430979642, 51.321257777255845],
              [1.198681555355285, 51.320887277694638],
              [1.199389920076439, 51.320196000601406],
              [1.199012126607516, 51.319738599358914],
              [1.196044768466573, 51.319136077987352],
              [1.193815379071476, 51.316944649772928],
              [1.189891028674742, 51.316633436980638],
              [1.187807055516353, 51.31446311868801],
              [1.187117923215327, 51.314399309546111],
              [1.187041439713126, 51.314392227023006],
              [1.186768052743566, 51.314465717029591],
              [1.184010598335158, 51.315206912357567],
              [1.181638204443355, 51.314400747439329],
              [1.177855316560252, 51.313909888721241],
              [1.177689525163579, 51.311719666765654],
              [1.174512929426807, 51.312356369939529],
              [1.171339994638902, 51.311778593966608],
              [1.170646228319135, 51.310334846690978],
              [1.165701639422629, 51.307951993212768],
              [1.165452055486608, 51.305807200030515],
              [1.163910073045817, 51.303272807988542],
              [1.162464498430986, 51.303180565068487],
              [1.161626697899421, 51.302661722386553],
              [1.160359636073559, 51.302431507551788],
              [1.159035761771495, 51.301583074837119],
              [1.157085327819431, 51.302182599503915],
              [1.154590726688381, 51.300969915780222],
              [1.152209713241259, 51.300698599536858],
              [1.151073972693787, 51.300059084353919],
              [1.150010110270532, 51.300237942966056],
              [1.146845127017731, 51.299885065631329],
              [1.142365383335619, 51.299944299707718],
              [1.14160331829095, 51.299437673492974],
              [1.141149536772479, 51.298045781401456],
              [1.139942185907006, 51.297614960913769],
              [1.136882169000944, 51.29886005756611],
              [1.128998088037907, 51.296540957052336],
              [1.127653140178819, 51.29640511880649],
              [1.125593664475862, 51.295825128665975],
              [1.124883386986413, 51.296309126473304],
              [1.12274522830709, 51.296217800477294],
              [1.121685081768243, 51.296599581730987],
              [1.121484616132667, 51.296161492182669],
              [1.120046712137026, 51.296748520569757],
              [1.119319346716951, 51.297489287814614],
              [1.120146051202025, 51.296563279482854],
              [1.117259755161194, 51.297054868214737],
              [1.120235668261455, 51.296425069911166],
              [1.12197341241465, 51.295669033889432],
              [1.123748784290611, 51.295425563562461],
              [1.128993492448141, 51.296389069562416],
              [1.136485584302537, 51.298599049192916],
              [1.138059295144564, 51.298364365468018],
              [1.139290055082996, 51.297477751009268],
              [1.140421870375813, 51.297495949114861],
              [1.14137949911731, 51.29796764963681],
              [1.14166125272045, 51.299109609630655],
              [1.142696526127171, 51.299876940076395],
              [1.146774979354922, 51.29971965032361],
              [1.149734657002579, 51.300070860678488],
              [1.151141544284665, 51.299893560343193],
              [1.152283810488046, 51.300544592197276],
              [1.154576763880326, 51.30077777779433],
              [1.157241109546931, 51.302027284301531],
              [1.15836103817811, 51.301452876666524],
              [1.159284011519568, 51.301416265448545],
              [1.160670976266233, 51.302347541764853],
              [1.161804257859264, 51.302510309872844],
              [1.162766887169175, 51.303070750872081],
              [1.164173714964548, 51.303183826327398],
              [1.165742448783051, 51.305793946682471],
              [1.165892653435442, 51.307808305927317],
              [1.171017935098083, 51.310293292316665],
              [1.171887717993856, 51.311750255828272],
              [1.174661850240434, 51.312143651087418],
              [1.177741267659087, 51.311513180750381],
              [1.178322189934767, 51.312566881580608],
              [1.178019092203901, 51.313787609294423],
              [1.181423164928028, 51.314176327901762],
              [1.184169950630829, 51.314958817624955],
              [1.187109715546323, 51.31422677739279],
              [1.187921227398468, 51.314246831722691],
              [1.188807493303052, 51.314711999549907],
              [1.190002331919085, 51.316458602236708],
              [1.194305519354424, 51.316851217168043],
              [1.196243702031593, 51.318983129698005],
              [1.199247563189255, 51.319615230315037],
              [1.199709879054042, 51.320276300754131],
              [1.199055762619063, 51.321169379365834],
              [1.202912650465941, 51.323018448727183],
              [1.205602256631793, 51.323647296778894],
              [1.209647501176417, 51.323377205181373],
              [1.210872717975528, 51.323653914779221],
              [1.211961939977286, 51.322478082051937],
              [1.214540252580244, 51.321617537310942],
              [1.215114193391491, 51.320485510458809],
              [1.215808460963723, 51.320498814400658],
              [1.215330338249295, 51.320148561574328],
              [1.217683297591177, 51.320971675167414],
              [1.218197369139805, 51.322142158870143],
              [1.220263104079669, 51.322961427275906],
              [1.220820446095898, 51.322577299751771],
              [1.222653739989148, 51.322967573323872],
              [1.22266947440524, 51.322025379390169],
              [1.224810892325768, 51.321815271657748],
              [1.225925519094422, 51.320209549756072],
              [1.225399558058839, 51.319874148485304],
              [1.225920626338576, 51.319260727175312],
              [1.22545923023698, 51.318720262488171],
              [1.225810725369446, 51.318022466453847],
              [1.227841233419757, 51.317646255148816],
              [1.227787555956573, 51.317086456350495],
              [1.228596510596087, 51.316465080573771],
              [1.229267399364871, 51.316452863933414],
              [1.229354687289964, 51.316060977178886],
              [1.226301813407161, 51.314181613419038],
              [1.225692713977149, 51.314235285331364],
              [1.2247078839764, 51.313782104938475],
              [1.224727773584882, 51.312470105888885],
              [1.223558537048143, 51.312292743551495],
              [1.221612051324463, 51.31300477590937],
              [1.219195957290358, 51.312156496441972],
              [1.2197425056727, 51.310636619189331],
              [1.221822758371411, 51.310093635141143],
              [1.221672846791573, 51.308876260901627],
              [1.219778790159494, 51.308860036118929],
              [1.218506848712052, 51.309837691273472],
              [1.217017526364308, 51.309760815957432],
              [1.216484251500239, 51.310405116178949],
              [1.21397876715237, 51.311353659545361],
              [1.213155217946448, 51.310063930263404],
              [1.213220242236207, 51.30740146957536],
              [1.212510961047631, 51.306636595362235],
              [1.212919954123647, 51.305876993965214],
              [1.211928862886727, 51.305124351985611],
              [1.210977258190365, 51.305347458303771],
              [1.209750242944228, 51.305099586836597],
              [1.209903189359502, 51.304433370555948],
              [1.208266735310497, 51.303664221267717],
              [1.207432218460242, 51.302521370041099],
              [1.20807692824227, 51.302013475515537],
              [1.207466698435974, 51.301246755354271],
              [1.205806482573247, 51.301307548369032],
              [1.204943970429952, 51.300646670954009],
              [1.204414815579452, 51.299127545062682],
              [1.206095703020361, 51.298767575860808],
              [1.205223073619399, 51.29771390343975],
              [1.203911648644734, 51.29756902737266],
              [1.204165136905974, 51.296862286910738],
              [1.2030768739124, 51.295039149258507],
              [1.204208897466865, 51.294360522572866],
              [1.202780718320798, 51.293176330295452],
              [1.202993083725926, 51.292785537187889],
              [1.200688439345864, 51.292095742547083],
              [1.199971759397427, 51.291568461313695],
              [1.201934108574022, 51.290062103329007],
              [1.202718196958871, 51.290341968023306],
              [1.202622459756638, 51.289935323478488],
              [1.20344590081818, 51.28962674258679],
              [1.203504833429562, 51.289154698386376],
              [1.202711259628753, 51.288738377106057],
              [1.20361666421489, 51.287217743753708],
              [1.2041907183839, 51.287015828853292],
              [1.205196890702631, 51.285707399102527],
              [1.206231172005363, 51.285173546174725],
              [1.206813258496503, 51.285910460908163],
              [1.208747873540962, 51.285431956303761],
              [1.20841213385189, 51.284402258305484],
              [1.210357003361726, 51.28448292555283],
              [1.210663012013171, 51.284921572261794],
              [1.211403364762172, 51.28506855530555],
              [1.211903391646251, 51.284444076238216],
              [1.211737380509984, 51.283915238444671],
              [1.212380012956084, 51.283464040735034],
              [1.212045201680229, 51.282941637457562],
              [1.21231695082297, 51.282684118202248],
              [1.211358777355998, 51.282251687244035],
              [1.210401649569804, 51.282306741704168],
              [1.209744265812745, 51.281765300089724],
              [1.210554920064438, 51.28168728635714],
              [1.210460587563438, 51.280910919661792],
              [1.208942517373988, 51.280367006400731],
              [1.208041739527905, 51.279131531900489],
              [1.206676884121773, 51.278315341103436],
              [1.206542715459742, 51.277316091510514],
              [1.20576444848943, 51.276540477447185],
              [1.203423721854443, 51.275547705544341],
              [1.194793762511038, 51.269312825898375],
              [1.195719669606902, 51.268295400936069],
              [1.197057843697435, 51.267888245227134],
              [1.195176973946035, 51.265413883333778],
              [1.194557803948499, 51.262234922800914],
              [1.196401807275425, 51.261847237283021],
              [1.194949698800831, 51.258874532449013],
              [1.193417030152542, 51.259057608333499],
              [1.19261195088469, 51.25718436983189],
              [1.194087732869293, 51.256533325572015],
              [1.195233549029471, 51.256085566207858],
              [1.196442227423287, 51.25765902317999],
              [1.196832460237949, 51.259067747757825],
              [1.201015418612366, 51.258694290600211],
              [1.200374152574918, 51.257470546531316],
              [1.204352013673468, 51.256438757651175],
              [1.204346619973389, 51.255434176904785],
              [1.205626340251791, 51.254543695045037],
              [1.207652257306405, 51.255188851931671],
              [1.211515954168505, 51.252422131518678],
              [1.208619765180411, 51.248233544482567],
              [1.206306683794639, 51.246258738731576],
              [1.200909883644885, 51.239932950713694],
              [1.198900526310327, 51.238462398612164],
              [1.197649515517449, 51.236871235855965],
              [1.195245855557062, 51.231693815724626],
              [1.193799214921294, 51.230541512596218],
              [1.191910626453046, 51.227060786219582],
              [1.189020474030483, 51.223302423741991],
              [1.187384995942463, 51.2197436643619],
              [1.185518692261677, 51.217815649040041],
              [1.190351359817815, 51.219557524077715],
              [1.193214588785686, 51.220136892455649],
              [1.197114154136642, 51.219630957706869],
              [1.202429352856923, 51.217586674723897],
              [1.208725132158806, 51.213010148381805],
              [1.219422153755831, 51.209385253979519],
              [1.222164012726678, 51.207390194163736],
              [1.219693766406021, 51.204290222034494],
              [1.21561461541688, 51.201273143155476],
              [1.207755152393646, 51.195127827985345],
              [1.207357102191485, 51.194220364752546],
              [1.20599891820439, 51.193091874524001],
              [1.203055065518195, 51.191349296187745],
              [1.197662956139984, 51.187094791054712],
              [1.19818032016098, 51.186629091249181],
              [1.194300176898013, 51.185224034878793],
              [1.195474249508199, 51.184754791637459],
              [1.194547182711785, 51.184139690880727],
              [1.192107154540838, 51.183832158719191],
              [1.188235800535029, 51.18590590324483],
              [1.183882598464354, 51.189610825877054],
              [1.182416641814948, 51.190313668683785],
              [1.180808850543142, 51.190475260432827],
              [1.180542933376564, 51.189358131943109],
              [1.179465539401015, 51.188283764907325],
              [1.177575371941643, 51.18737810132744],
              [1.174324401115068, 51.186414022107947],
              [1.169886709669779, 51.183943912804864],
              [1.163414475776027, 51.184210990545324],
              [1.163732665934254, 51.185157635966874],
              [1.15955032508623, 51.187872970079574],
              [1.157772237074543, 51.191006253987865],
              [1.153600044027458, 51.190907499350452],
              [1.152402542935296, 51.188233244705813],
              [1.151962967771542, 51.188210017747188],
              [1.151434053763069, 51.185242481907316],
              [1.150018120151786, 51.185592766804909],
              [1.149864206593743, 51.185266802901417],
              [1.147607959051928, 51.177174637751634],
              [1.143764716239826, 51.178504962322634],
              [1.139974950214138, 51.181593113037593],
              [1.138527937415152, 51.183317605710386],
              [1.134483413236349, 51.184940854806669],
              [1.131308515982848, 51.185694257365313],
              [1.126850356673424, 51.185549141887556],
              [1.123175000907007, 51.186312108806518],
              [1.120326594801801, 51.1865635717553],
              [1.116002937950174, 51.187796951005069],
              [1.11667101146695, 51.190908429862311],
              [1.117935263942779, 51.19258732670847],
              [1.117734338178212, 51.193618091258102],
              [1.11842838538678, 51.196136110623407],
              [1.118116134558574, 51.196324334812722],
              [1.114765560930406, 51.196950638006847],
              [1.115058778983369, 51.194231798449394],
              [1.113555773936797, 51.189521691630667],
              [1.112707844629945, 51.188642994255169],
              [1.108941643494409, 51.186958648804165],
              [1.097279480513012, 51.188920548919022],
              [1.094968021232184, 51.188645361783337],
              [1.092416472777851, 51.189315531962919],
              [1.091168117374844, 51.189013042924316],
              [1.08973138401626, 51.189723830151749],
              [1.085215325041263, 51.190257754897843],
              [1.080328116600246, 51.19164498868939],
              [1.078257089455319, 51.192979486181962],
              [1.077033624909949, 51.194940160319547],
              [1.074580453815706, 51.196730784212598],
              [1.069937990988414, 51.19935870053159],
              [1.070111263104959, 51.200736635348214],
              [1.066512927507081, 51.201268208893531],
              [1.063881178792019, 51.202148524092827],
              [1.060701258485438, 51.203788788483145],
              [1.059378702766675, 51.204831704567773],
              [1.058227296963547, 51.205001243678026],
              [1.055064930282605, 51.191730428751541],
              [1.054732417788811, 51.188587382082282],
              [1.050858756646839, 51.186456955041166],
              [1.049547865622787, 51.184985448952133],
              [1.041986632120666, 51.187111797391452],
              [1.041819418062317, 51.186507209838773],
              [1.042400322461569, 51.185526966735459],
              [1.04201924055064, 51.184435037472646],
              [1.041177675180712, 51.183043871689762],
              [1.039807376167164, 51.182185441712939],
              [1.028486941821982, 51.181002333486589],
              [1.024040003095868, 51.180250367763506],
              [1.021258825591737, 51.179343662081386],
              [1.017584296074941, 51.179458357795234],
              [1.014153529790337, 51.181049865226925],
              [1.007655659908655, 51.181630970581516],
              [1.003939374151078, 51.180050827366941],
              [0.997811026876913, 51.182664559895748],
              [0.989842329946282, 51.187921774292882],
              [0.989369241604413, 51.190231083185964],
              [0.989672457056887, 51.190780093338809],
              [0.993610539771503, 51.19100672741191],
              [0.997030754714532, 51.191722318254392],
              [1.002884382329553, 51.197387115257712],
              [1.003246096270165, 51.202923879788557],
              [1.005367834157509, 51.208483775614042],
              [1.004474460194773, 51.211740280410119],
              [1.002637323601443, 51.212644650786508],
              [1.000116275414262, 51.21285507079007],
              [0.999385513101986, 51.213839832234974],
              [1.000660259656303, 51.215251653599843],
              [0.997196894483413, 51.221283169189135],
              [0.995087660439055, 51.222791625520642],
              [0.991768539687488, 51.223559269950066],
              [0.990297119602693, 51.224304772695916],
              [0.99053903833665, 51.22604712216031],
              [0.991347761841645, 51.226788275187872],
              [0.990528180197273, 51.228287035851963],
              [0.991054457632665, 51.228891500796514],
              [0.99130618389173, 51.230408734322971],
              [0.986144130871439, 51.23586540028527],
              [0.986427813289479, 51.237833350035032],
              [0.987428349090734, 51.238392440773779],
              [0.989806137779067, 51.240810567907253],
              [0.990866378065231, 51.242464534049525],
              [0.990381725835989, 51.245794098803202],
              [0.992522418536494, 51.246693666368877],
              [0.99486637493487, 51.248095281266842],
              [0.995379015982056, 51.248772032999661],
              [0.997603459234831, 51.249765605767095],
              [0.998208673486402, 51.251225193691603],
              [0.997539923485786, 51.25187284832981],
              [0.996544668479226, 51.251908242886728],
              [0.993105295055568, 51.25434206669572],
              [0.99321987710262, 51.25502451531046],
              [0.991614385976833, 51.255133045561223],
              [0.98770147270781, 51.256572331619758],
              [0.979167608134428, 51.262676862478159],
              [0.973869596477576, 51.267058845514704],
              [0.973376293825151, 51.267434760191669],
              [0.973248868411423, 51.268591986161937],
              [0.974604888328969, 51.269629653020743],
              [0.976969750118217, 51.270169428842358],
              [0.977138863473772, 51.272474000314816],
              [0.980137099368331, 51.274577019554897],
              [0.983162896814006, 51.274667201579717],
              [0.981722531983028, 51.27766759183617],
              [0.983194279427206, 51.277585101654402],
              [0.985544330936857, 51.278153867910909],
              [0.988675719986842, 51.279601177222418],
              [0.989164578892427, 51.279002249471098],
              [0.990539945804698, 51.279047152503374],
              [0.993657047647221, 51.279720271630751],
              [0.996173081273049, 51.280896203051014],
              [0.997535312119835, 51.280628353004417],
              [1.005937431281386, 51.28377941997114],
              [1.004021420800731, 51.288175662414019],
              [1.006202932433079, 51.292320953771913],
              [1.010996225822891, 51.302912827369617],
              [1.010190618440619, 51.303976912646746],
              [1.010253243847651, 51.304977285649805],
              [1.009039160658604, 51.305786532513864],
              [1.0091568717636, 51.307124580551879],
              [1.007548505722977, 51.307830618941608],
              [1.006656859189033, 51.308876201646335],
              [1.00662888648951, 51.309633354310577],
              [1.012110267863825, 51.311989258738294],
              [1.013527552765087, 51.312263979175945],
              [1.014089992314061, 51.312086694491931],
              [1.014481819505133, 51.312437280169299],
              [1.016955458460062, 51.312931192232583],
              [1.015644089004117, 51.314577700830135],
              [1.014815602722763, 51.316300799999148],
              [1.015175311115586, 51.316292432330087],
              [1.015010523689558, 51.316777881768857],
              [1.012430859564171, 51.318172794569755],
              [1.009565468183786, 51.318670154816964],
              [1.007857319001092, 51.319590128282385],
              [1.005080177294766, 51.319683057431803],
              [1.000919315092188, 51.318984820788771],
              [0.990544115321526, 51.32462546111865],
              [0.973694264530528, 51.335301182633074],
              [0.97233726657502, 51.336497722070128],
              [0.97193310465707, 51.338240299094714],
              [0.967561742116075, 51.337146860818052],
              [0.96764686999792, 51.336353199110235],
              [0.965205850866299, 51.336553574910937],
              [0.963875642044189, 51.335944168754672],
              [0.96272300430177, 51.336929448616488],
              [0.961068188709453, 51.337520876746581],
              [0.958120555195798, 51.337949782540221],
              [0.950213307163503, 51.345758538803622],
              [0.951982343618221, 51.345820074497567],
              [0.957420968716462, 51.345013621322416],
              [0.959082025683633, 51.345048995517942],
              [0.968459556433501, 51.346593350551416],
              [0.976321222927111, 51.346235426286277],
              [0.979852587656784, 51.346673554890153],
              [0.989372389984907, 51.346813093655882],
              [0.99773254184009, 51.347558101791314],
              [1.001508953890887, 51.348202470799947],
              [1.008299082088451, 51.349968264221467],
              [1.011490992898792, 51.351747138477847],
              [1.012709347465367, 51.35256126484402],
              [1.01463472989113, 51.354735119047312],
              [1.016264118868992, 51.355790467621297],
              [1.017275023287832, 51.35705151888677],
              [1.019737324270358, 51.358973099757321],
              [1.021329937053537, 51.359683942225324],
              [1.02251643188688, 51.360902641294516],
              [1.022448697854538, 51.361292049934953],
              [1.022963760024809, 51.36130303618657],
              [1.024549871677681, 51.362763239826364],
              [1.02550633205022, 51.363192744517249],
              [1.024518182533763, 51.363633819954913],
              [1.024803720195667, 51.363933152922328],
              [1.026893791340073, 51.363055242783908],
              [1.026056685734572, 51.362888899562897],
              [1.026225640900204, 51.362575123755001],
              [1.028199224616818, 51.363068254463101],
              [1.025532712619793, 51.364028544933639],
              [1.025032686947901, 51.364485791008391],
              [1.030263446101101, 51.364389193042086],
              [1.031475716405773, 51.364709491438695],
              [1.033119505642037, 51.365558265071861],
              [1.047170644573205, 51.365281530566847],
              [1.054251171099963, 51.365472992112643],
              [1.058823112012406, 51.365622561746825],
              [1.062878536591081, 51.366105671510375],
              [1.065754980119395, 51.367027871666856],
              [1.069368295123789, 51.369340124652432],
              [1.082252029039845, 51.368140860391534],
              [1.091089363748884, 51.36816510218712],
              [1.095644351115077, 51.368759813134872],
              [1.097070026439044, 51.369295059639846],
              [1.097205295225898, 51.369808668202936],
              [1.098582174558149, 51.37045042394108],
              [1.098883802232268, 51.373064354274959],
              [1.09893667077847, 51.372613226984392],
              [1.112231370437748, 51.372045592966074],
              [1.121550125701641, 51.372353361369683],
              [1.126148238999338, 51.373125633594626],
              [1.126621569138749, 51.373491666605545],
              [1.126713092497055, 51.373274251133395],
              [1.128796749219422, 51.373398475984501],
              [1.136713571746665, 51.372995943553121],
              [1.147340838708107, 51.373036330333008],
              [1.169450288543822, 51.374838732398153],
              [1.174511208510694, 51.375657746064483],
              [1.180309934497692, 51.377185972728981],
              [1.191818141636013, 51.378055589479715],
              [1.196442862036328, 51.378915234881148],
              [1.199312310077482, 51.379850562715127],
              [1.214235880348412, 51.380112797811726],
              [1.224154663986139, 51.379330951111683],
              [1.224235740302041, 51.377254519156565],
              [1.225126990098131, 51.374191527325166],
              [1.225265509612621, 51.371665569206812],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000107",
        LAD13CDO: "29UD",
        LAD13NM: "Dartford",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.235609934907007, 51.471843773343856],
            [0.238147519022463, 51.469997643659241],
            [0.238656315226703, 51.470037390025759],
            [0.239171523739839, 51.469502356252747],
            [0.239705667402986, 51.469501145157132],
            [0.239641339329383, 51.468584633982644],
            [0.241043088842766, 51.467968350037786],
            [0.242115085595348, 51.468130409943818],
            [0.248957668316113, 51.465122453046014],
            [0.249553322494711, 51.464821446829454],
            [0.249840333030788, 51.463895048661968],
            [0.253011549827395, 51.462569713381718],
            [0.254137415575577, 51.462219820820138],
            [0.254913766317718, 51.462606879128842],
            [0.255624471043303, 51.462538347401335],
            [0.256087424614323, 51.462087877804898],
            [0.260863544119183, 51.460762902174615],
            [0.262377734428476, 51.459185076814336],
            [0.266411160777458, 51.456884063742294],
            [0.267380525549951, 51.455937228151683],
            [0.274264591015953, 51.453775010976877],
            [0.276491188344296, 51.453569010293741],
            [0.278567739273013, 51.453472003000435],
            [0.278902548400841, 51.453142640690558],
            [0.279229302373511, 51.453518494635667],
            [0.281473120499838, 51.453889422577078],
            [0.281426569554082, 51.453562079720861],
            [0.282513933964663, 51.453862863117024],
            [0.282908654831707, 51.453609663318815],
            [0.284700635216522, 51.453636798862526],
            [0.291337154975655, 51.454745601056452],
            [0.292891314959774, 51.455627112012479],
            [0.294236839323831, 51.455877771153553],
            [0.297405949051028, 51.45767098133517],
            [0.299924189883857, 51.457966931654902],
            [0.300761271334357, 51.459097129088484],
            [0.30032895646646, 51.459403287248939],
            [0.301737680261297, 51.460391857168005],
            [0.302070621916893, 51.461114662470578],
            [0.303562137650153, 51.461172593100166],
            [0.303837644870164, 51.461811986720441],
            [0.30453682468341, 51.46183061758839],
            [0.304433992702389, 51.462218445193791],
            [0.305358313552404, 51.462476358937671],
            [0.305726753172363, 51.463216442266734],
            [0.306728328018466, 51.463492610600483],
            [0.306385515381557, 51.463603676924443],
            [0.307276196031366, 51.464792128867622],
            [0.307979903221274, 51.464985117411501],
            [0.308822626784097, 51.464252666788553],
            [0.310005903630903, 51.464020712545697],
            [0.310025193459484, 51.463661505727586],
            [0.310315143922824, 51.463929182919877],
            [0.311015467744451, 51.46374180965212],
            [0.310312882540074, 51.464454427420876],
            [0.310300824057891, 51.464244225398787],
            [0.309607654027598, 51.464373897197802],
            [0.308851933303303, 51.464747611929653],
            [0.309560817957779, 51.465328093680348],
            [0.309313236653135, 51.465129726471396],
            [0.309124102877773, 51.465347489369208],
            [0.309290535237732, 51.466476447662046],
            [0.310389914923112, 51.466549211870365],
            [0.309918225287278, 51.466959602303547],
            [0.31155276600453, 51.466880616112697],
            [0.312675453686988, 51.466474463765152],
            [0.314212227784585, 51.465886558102213],
            [0.31536853169734, 51.464950918312439],
            [0.314487983987255, 51.462060821010127],
            [0.31343822718635, 51.460832395136421],
            [0.313709106822037, 51.460267673944202],
            [0.312775100622909, 51.458964112719471],
            [0.310656488135148, 51.453045230725365],
            [0.317715354364755, 51.451938189280078],
            [0.31967910458588, 51.451237441464222],
            [0.320296943143919, 51.450307907119281],
            [0.319309682990068, 51.449486585759715],
            [0.31980080484344, 51.448896805603169],
            [0.319278854551124, 51.447004178052978],
            [0.321689900032726, 51.446897984713274],
            [0.321678719265876, 51.446223717074005],
            [0.320558187472172, 51.445355583715077],
            [0.321426453661001, 51.445619875267809],
            [0.322519674320378, 51.445070315524966],
            [0.323535824055942, 51.443173294200285],
            [0.326334303440584, 51.44265282901128],
            [0.325988282551802, 51.439893388366279],
            [0.323606983540919, 51.435418845205156],
            [0.323824723875837, 51.431658064544344],
            [0.324906872462147, 51.430387442650883],
            [0.325204455528073, 51.429221413487276],
            [0.326564408449427, 51.428974985894783],
            [0.328039517070952, 51.427739698295404],
            [0.330886956467615, 51.426589516689916],
            [0.332574941092356, 51.425611638448778],
            [0.330593543241874, 51.420993469580679],
            [0.331241849856078, 51.420759339322288],
            [0.331054814336894, 51.414709711270213],
            [0.332063140814287, 51.411830693292153],
            [0.334379766185541, 51.40886355133361],
            [0.334084721640658, 51.406345921839169],
            [0.335141182219846, 51.402386720562355],
            [0.335065637206431, 51.399323321122246],
            [0.336527440728829, 51.398949733368724],
            [0.337152736469979, 51.399060468410539],
            [0.337700921934255, 51.399773489402577],
            [0.338855908902539, 51.399501323148939],
            [0.33835815177337, 51.398142483139424],
            [0.338708410597427, 51.396356621382381],
            [0.34373395494254, 51.395477368011122],
            [0.344212031611549, 51.394002795912336],
            [0.344542720688588, 51.393188580722502],
            [0.342601460537594, 51.391235403211546],
            [0.341003986850146, 51.391170210261002],
            [0.339840821777041, 51.390686227662314],
            [0.33570313853835, 51.389966599831588],
            [0.334590838016269, 51.38946356484967],
            [0.335166269557051, 51.388230807787963],
            [0.336397123801596, 51.387591124253689],
            [0.337039373331825, 51.387670943643386],
            [0.33664995861198, 51.386544658615641],
            [0.331979508254166, 51.387323915827913],
            [0.32556583315628, 51.388905630753918],
            [0.296708924100264, 51.397055332827719],
            [0.293240000001658, 51.397976743180024],
            [0.284015805449721, 51.399618333539159],
            [0.277459790156844, 51.401585068940307],
            [0.279756046748332, 51.402859709730016],
            [0.278478520396901, 51.404522220286417],
            [0.277578464287321, 51.406924711787866],
            [0.274865643450201, 51.407457651191095],
            [0.274026145840483, 51.407086342161513],
            [0.272733068023615, 51.407571882620161],
            [0.272167156097099, 51.406743795915084],
            [0.269098980244784, 51.407419289000678],
            [0.258810533101176, 51.407800651624186],
            [0.245716441376882, 51.40771241684638],
            [0.246126066176886, 51.407033677059836],
            [0.246058425578978, 51.40650437878238],
            [0.245484133325377, 51.406492903742333],
            [0.245615890513406, 51.405425591286438],
            [0.244574021436625, 51.403969781990426],
            [0.242981690552592, 51.402928294919803],
            [0.241866707257046, 51.401003520647791],
            [0.240178412781497, 51.40114283239933],
            [0.241005923494447, 51.399571194333909],
            [0.24015521730186, 51.398373389977266],
            [0.240517892835645, 51.397616426962081],
            [0.238852562632041, 51.397075400133474],
            [0.238285979581623, 51.396306521085727],
            [0.235606319300207, 51.396660754450011],
            [0.233455077296357, 51.396353751009002],
            [0.232670754497741, 51.39589386333185],
            [0.227484536398248, 51.395166115411968],
            [0.223627352978008, 51.395665662572313],
            [0.219315805311727, 51.395571151403999],
            [0.215785556853774, 51.396174860912417],
            [0.213840247563766, 51.396080336924683],
            [0.212812279248155, 51.396763448288965],
            [0.212096516660405, 51.396588109878422],
            [0.208882462924607, 51.397044447729172],
            [0.207504235730088, 51.396844697758922],
            [0.206922812783454, 51.397219871875286],
            [0.207163326042744, 51.400005897883226],
            [0.206830700349189, 51.402414217797165],
            [0.212710161650087, 51.402749385130591],
            [0.213501725927657, 51.404849609145792],
            [0.211372960767942, 51.404953657586411],
            [0.210990271441388, 51.407452864573557],
            [0.205817472071856, 51.409682621108608],
            [0.204964381638976, 51.4122455165856],
            [0.205278067038143, 51.413205469161106],
            [0.204122843057597, 51.413406167527228],
            [0.200751852596827, 51.415741177426],
            [0.20017652752673, 51.416638697274081],
            [0.200472006172122, 51.416951503189004],
            [0.199718682400269, 51.41797646715321],
            [0.182582739558306, 51.415794132466807],
            [0.179430807018662, 51.415625275059512],
            [0.178052011484193, 51.4155133182919],
            [0.164903436428454, 51.410994278127838],
            [0.166331594541523, 51.410754748176608],
            [0.16569316857619, 51.408595633890343],
            [0.165588672982541, 51.408240542145798],
            [0.163907291684975, 51.408414594799417],
            [0.163461707963554, 51.406804078708461],
            [0.161427860429098, 51.40715637028952],
            [0.159667236912494, 51.407845325333192],
            [0.159861275851485, 51.408270715668166],
            [0.159355477681798, 51.408369968268886],
            [0.156069800461578, 51.408738034749327],
            [0.15290633792244, 51.4087089701314],
            [0.153744036058547, 51.411265575581389],
            [0.149725869995418, 51.411658614327678],
            [0.149133881620537, 51.412435677056891],
            [0.151128205706357, 51.415063707756602],
            [0.151029425753371, 51.41761593461392],
            [0.154068143729819, 51.417762457534657],
            [0.154610567345156, 51.418470126464904],
            [0.151132824040425, 51.420430641665938],
            [0.152977694200229, 51.421980426822145],
            [0.153491298211769, 51.423604116548958],
            [0.153292739898136, 51.425316432867795],
            [0.153737435432989, 51.427523233274805],
            [0.155274538312038, 51.428975219505283],
            [0.155848857350841, 51.430876564133733],
            [0.158605978363848, 51.430127057551537],
            [0.159059444499924, 51.430224821391889],
            [0.159811131842397, 51.429202856411692],
            [0.160669218039191, 51.42967445905429],
            [0.163896963496594, 51.428932336237281],
            [0.163805457709149, 51.428609378679674],
            [0.164302056322368, 51.428584920356506],
            [0.165563664139482, 51.430348522470595],
            [0.166158475372924, 51.430270981434496],
            [0.166352551380091, 51.430541680417633],
            [0.166667330972772, 51.431295769381919],
            [0.166069435379829, 51.431430025785573],
            [0.16681898483828, 51.431946756314616],
            [0.166663759695217, 51.435051309584807],
            [0.168688076896451, 51.437479746189418],
            [0.170876387639799, 51.441207295777083],
            [0.172827974620008, 51.443244914764456],
            [0.172770333552477, 51.443031050920979],
            [0.173779518417868, 51.442979063859305],
            [0.175991104276773, 51.442005451223345],
            [0.178698861380589, 51.443476762673484],
            [0.179549507501522, 51.443117414466897],
            [0.180861099584807, 51.443522881760828],
            [0.181058000994432, 51.444182001772027],
            [0.183629115086563, 51.444804106289126],
            [0.184250886971899, 51.444654927567449],
            [0.187438086723817, 51.446874314294718],
            [0.187617342802113, 51.44869744743653],
            [0.188407911693713, 51.448616141109504],
            [0.188823959214035, 51.44912996644473],
            [0.190985140730037, 51.448787422373492],
            [0.191720486588528, 51.450678392668607],
            [0.192992916264309, 51.451701378262257],
            [0.193819382337968, 51.45194760878735],
            [0.194154076299719, 51.451663460029785],
            [0.194921333358528, 51.451815465307043],
            [0.195027446815022, 51.45213722638988],
            [0.195482191182894, 51.452015397572985],
            [0.195863394084512, 51.452370674838512],
            [0.19595899519189, 51.451783438986361],
            [0.197988371005708, 51.451841626024581],
            [0.199109466171535, 51.452138050715455],
            [0.199694196497908, 51.452714322265436],
            [0.200206672039557, 51.45259588812668],
            [0.201359500417851, 51.453850351009827],
            [0.202000749372851, 51.454104499200191],
            [0.202693450767847, 51.453932307459709],
            [0.203352909470171, 51.454355174776069],
            [0.203235697318931, 51.454940125370392],
            [0.203873634864088, 51.455662861711751],
            [0.203777852078037, 51.456035174738005],
            [0.202580930516259, 51.456229438749212],
            [0.203080761253307, 51.457970085242913],
            [0.203514113480889, 51.458060862697579],
            [0.203065226879509, 51.458275240225603],
            [0.203590369612656, 51.458358896387217],
            [0.203453629146718, 51.458807520187968],
            [0.204852094560353, 51.459896356659129],
            [0.205820199836087, 51.460000447703379],
            [0.2077909642605, 51.461371650832959],
            [0.210352047669543, 51.462085993186285],
            [0.210789636816686, 51.458986841193031],
            [0.212475633176839, 51.457295817452341],
            [0.212138084308018, 51.455911866875653],
            [0.211504230933436, 51.455514645078566],
            [0.21309568428857, 51.453836185884839],
            [0.214650043932751, 51.453050367838323],
            [0.212962766761562, 51.454837820817971],
            [0.212330180657209, 51.455061997668857],
            [0.212928688785393, 51.457109203125306],
            [0.211295210019022, 51.459201230822941],
            [0.210761720027708, 51.464277059182386],
            [0.211046055424845, 51.464738433681973],
            [0.210668543994447, 51.465417331567011],
            [0.211816327095125, 51.467804907655136],
            [0.211496339182407, 51.469078059481383],
            [0.211884017678805, 51.469323448705318],
            [0.210424352295668, 51.469778148915353],
            [0.209219156863971, 51.470880927247336],
            [0.212183390546367, 51.472922195831302],
            [0.212038866165972, 51.474786474130418],
            [0.212503851889462, 51.475378431808217],
            [0.216288667208459, 51.476275487061272],
            [0.2169565941811, 51.476893267571086],
            [0.217405262725549, 51.477889300596544],
            [0.216986460558314, 51.478310899272337],
            [0.217230295062136, 51.479507752903864],
            [0.220136111502678, 51.479526503126692],
            [0.227390437975595, 51.475860940245603],
            [0.228918365515307, 51.475653838046682],
            [0.235609934907007, 51.471843773343856],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000108",
        LAD13CDO: "29UE",
        LAD13NM: "Dover",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [1.349390954704818, 51.299297169011183],
              [1.350845557094454, 51.298780571224306],
              [1.354315423016425, 51.298766585521797],
              [1.355281315758236, 51.2973149566267],
              [1.355315259883324, 51.296698715698376],
              [1.354734016861962, 51.296421244459452],
              [1.351507470805574, 51.297605736122584],
              [1.350512074297073, 51.297571283178222],
              [1.349094238081411, 51.296996615694368],
              [1.348100171011274, 51.296172332510501],
              [1.346408146292916, 51.292933029469502],
              [1.3466299142687, 51.29241216418724],
              [1.350398705891922, 51.29016704042175],
              [1.353502544467954, 51.289549187009456],
              [1.35433161116573, 51.28907565924866],
              [1.35435074972169, 51.288649640174249],
              [1.353316565357056, 51.287982167834045],
              [1.3510667449356, 51.288939858283221],
              [1.349984105695284, 51.288788266679454],
              [1.349220570333944, 51.288211953961486],
              [1.349835637468993, 51.285607340841203],
              [1.352151424108045, 51.284296971342144],
              [1.351181508536149, 51.283052849679805],
              [1.350564927028542, 51.2806921971262],
              [1.351322334975823, 51.280122694473306],
              [1.353818029529434, 51.280241859707722],
              [1.35437094730982, 51.279602640637201],
              [1.354663498287332, 51.27972018272844],
              [1.355656478150452, 51.279156487923714],
              [1.356461811975759, 51.278183492413824],
              [1.356140185509216, 51.277785240690875],
              [1.353632656091792, 51.277993889251043],
              [1.352011524361052, 51.277613089015311],
              [1.351205223629392, 51.27668360687354],
              [1.351529635386876, 51.275230589948919],
              [1.347951692689325, 51.274424548993288],
              [1.345399228521839, 51.275512234120612],
              [1.342104616565726, 51.276058874306742],
              [1.339714719810066, 51.277453018820232],
              [1.33895723140349, 51.278279708163431],
              [1.340228825688571, 51.279378587220087],
              [1.340221530101505, 51.279831250309272],
              [1.336821877497352, 51.281940490020439],
              [1.33544940899408, 51.282120857308207],
              [1.333575653591732, 51.280826804998398],
              [1.332259997825176, 51.281078376272568],
              [1.330921543514879, 51.282342530932631],
              [1.331698024335565, 51.284092452593164],
              [1.329613972044952, 51.285025216770258],
              [1.328364783491482, 51.286435217796694],
              [1.328633749922422, 51.287178654837625],
              [1.330122033473035, 51.288397408014475],
              [1.331374130039066, 51.290533173024407],
              [1.334870792376192, 51.293311074913511],
              [1.335417510857995, 51.293352157190931],
              [1.337702506918537, 51.292436947118055],
              [1.340125234611335, 51.29341932520807],
              [1.342995697703681, 51.29645413807097],
              [1.34563838237186, 51.301578665872732],
              [1.347298483732815, 51.30159684301222],
              [1.348146145166098, 51.30141427247883],
              [1.348399651774095, 51.300363583198838],
              [1.349390954704818, 51.299297169011183],
            ],
          ],
          [
            [
              [1.352575174593259, 51.307190931971796],
              [1.348298699118323, 51.301775108165259],
              [1.34599715182557, 51.302052342359787],
              [1.346079313313971, 51.304955413483476],
              [1.345441739652112, 51.307986108342647],
              [1.346474107592442, 51.30813108855908],
              [1.346739264431262, 51.309052698453755],
              [1.350938108588843, 51.311703907049875],
              [1.351685965268064, 51.313023656761189],
              [1.357892567925473, 51.311477531674349],
              [1.353372753417469, 51.308110762575488],
              [1.352847844113345, 51.308056544270144],
              [1.352575174593259, 51.307190931971796],
            ],
          ],
          [
            [
              [1.233997400211974, 51.332039864172337],
              [1.235231529465635, 51.330582765379468],
              [1.241403274812032, 51.330612565909625],
              [1.245782185274355, 51.328556394497056],
              [1.248103214208605, 51.32834537753601],
              [1.250517701802929, 51.329292959342837],
              [1.251963640648555, 51.32834160350221],
              [1.25239641168847, 51.326403767914876],
              [1.25474406059635, 51.32480756144242],
              [1.250252173901767, 51.325824706511732],
              [1.247952815228009, 51.325589019382974],
              [1.242811534283579, 51.324853705298132],
              [1.239996173560708, 51.323956645180495],
              [1.235539880105662, 51.323872164066501],
              [1.228567769646647, 51.321387862886823],
              [1.235527193790392, 51.323773571249411],
              [1.240206787382305, 51.323889648340703],
              [1.24305719411333, 51.324815415254271],
              [1.250308612690705, 51.325729591945041],
              [1.254997806420264, 51.324640394088618],
              [1.257350777207594, 51.323263460997893],
              [1.26332874661739, 51.322033700364145],
              [1.268254142814802, 51.322054546903694],
              [1.270673885898699, 51.321562376178989],
              [1.272543914455882, 51.32056832065534],
              [1.273856392310574, 51.320292340933435],
              [1.276846334904662, 51.319907316707052],
              [1.280648955048648, 51.319968070794303],
              [1.283667231190329, 51.321265935158955],
              [1.289930374093478, 51.321285198243281],
              [1.2951238464703, 51.320220673155696],
              [1.299335936980622, 51.31849637283684],
              [1.310578933955026, 51.316861036369161],
              [1.311951952015918, 51.317270132469567],
              [1.313516477157904, 51.317866283198626],
              [1.31466675204555, 51.317893070095195],
              [1.32533451237106, 51.316698067181427],
              [1.330133661413131, 51.314230952169787],
              [1.331655783309842, 51.312755603780992],
              [1.335342085348068, 51.313632838754138],
              [1.339476718742993, 51.312638778076227],
              [1.343089167870694, 51.310446951850999],
              [1.345001065357675, 51.308142630157946],
              [1.34577765557948, 51.3051484405187],
              [1.34573101645194, 51.302358591480406],
              [1.342937306669767, 51.296716664920382],
              [1.340106461674527, 51.293674422827969],
              [1.338067867875891, 51.292687380177881],
              [1.336978683154727, 51.292742741793106],
              [1.335309358230146, 51.293562128113798],
              [1.33439774330042, 51.293336253027093],
              [1.331147338064247, 51.290689849507125],
              [1.329755033824314, 51.288400659075542],
              [1.32810860873857, 51.28693903565825],
              [1.328198897960623, 51.286144898603283],
              [1.328986648219733, 51.285216676784763],
              [1.331358849189286, 51.283984276779989],
              [1.330575519964867, 51.282615040810867],
              [1.330736852115656, 51.282089630634076],
              [1.332208872692648, 51.280827969713641],
              [1.333015818129547, 51.280642167597073],
              [1.334100753596447, 51.280729983372048],
              [1.335560725109536, 51.281935082294652],
              [1.336419142744327, 51.281845835699642],
              [1.339880815557972, 51.279800501331486],
              [1.338515053564309, 51.278392175545321],
              [1.338698257320326, 51.277715010706842],
              [1.341967190816844, 51.275845118759591],
              [1.345546374855226, 51.275228276556639],
              [1.348388264116005, 51.27382197022834],
              [1.347724091939093, 51.27423317039699],
              [1.34938192996025, 51.274332328984393],
              [1.35194091837861, 51.275127953147759],
              [1.352178490176459, 51.275652758117474],
              [1.35157830023396, 51.276666620571888],
              [1.352289784351645, 51.277495373494119],
              [1.353766714242635, 51.27778675503383],
              [1.356067851197882, 51.277521059498397],
              [1.356672616726284, 51.277691704402045],
              [1.356914524919403, 51.278432259645371],
              [1.356262592548723, 51.279109413631723],
              [1.356906741962181, 51.279151196181736],
              [1.356132124940517, 51.279326342408119],
              [1.356533407173168, 51.279617065067768],
              [1.355782611172981, 51.2794110302207],
              [1.353560498929761, 51.280644130639594],
              [1.351560014285737, 51.280353356238727],
              [1.350968542543364, 51.280838049962121],
              [1.35257635924754, 51.284459298740437],
              [1.35028792943934, 51.285652855867035],
              [1.349784258301796, 51.288171526443449],
              [1.35022023421693, 51.288563821919219],
              [1.351286161852505, 51.288584561063615],
              [1.353020537501375, 51.287601163987318],
              [1.353012114148419, 51.28705270177317],
              [1.353443222486026, 51.287437029337596],
              [1.35320468602301, 51.287608478551199],
              [1.353980710860536, 51.28778502320543],
              [1.354891580459195, 51.288571164334165],
              [1.354585027894951, 51.289580215840978],
              [1.350640438467009, 51.290492037097842],
              [1.346988830724301, 51.29294789964446],
              [1.348514679056436, 51.294968614461204],
              [1.348826296945257, 51.296213614736644],
              [1.349501029151178, 51.296691728068652],
              [1.351622770208235, 51.297199451067264],
              [1.353794699390933, 51.296132448255143],
              [1.355283155808059, 51.295963834260505],
              [1.355936930311919, 51.296311183153115],
              [1.356105938556441, 51.297231034886096],
              [1.355158930615988, 51.298783774305846],
              [1.35441129166513, 51.299195603034512],
              [1.353386891765836, 51.299452548565831],
              [1.351320045377523, 51.29916816207885],
              [1.350169557142331, 51.299427791699486],
              [1.349405435227969, 51.30014589395374],
              [1.34911976442963, 51.301286558173608],
              [1.353389497386826, 51.306805989921742],
              [1.356160532184372, 51.309319825705806],
              [1.360154637423902, 51.311714823339457],
              [1.362971618618266, 51.312474031524324],
              [1.365455853976051, 51.31274531329214],
              [1.365603405014343, 51.31305499930577],
              [1.366523808089208, 51.313256096167784],
              [1.367572487912764, 51.313386030136115],
              [1.370617255730241, 51.311788066444493],
              [1.372006024940008, 51.309707047299639],
              [1.373226058449011, 51.306638708671699],
              [1.374365173167609, 51.30190318512556],
              [1.377343594560378, 51.287421345712609],
              [1.381599622960089, 51.278600022319175],
              [1.388205614062148, 51.268335092753489],
              [1.393905338691641, 51.256529977904279],
              [1.398940647939319, 51.247217554818093],
              [1.402539197927886, 51.238733790340319],
              [1.403931533914665, 51.234556328653767],
              [1.404882218934578, 51.229833999445134],
              [1.405061708722517, 51.218416421405863],
              [1.404122946632608, 51.212743427423177],
              [1.405639308621401, 51.189346472488765],
              [1.405782006919862, 51.18674085972642],
              [1.404674737447824, 51.179917762725019],
              [1.40507420694232, 51.179629067943694],
              [1.404646716911012, 51.175578319468407],
              [1.405395329907363, 51.174358326213827],
              [1.404701927757145, 51.173998025423366],
              [1.403836643863797, 51.171247262483107],
              [1.401999531877339, 51.168160476307335],
              [1.402092781275194, 51.166998259548294],
              [1.401034152059291, 51.165173330871497],
              [1.396488471315438, 51.158727234022344],
              [1.395537480408733, 51.158225045138792],
              [1.392803953878413, 51.154778265082065],
              [1.388490916177966, 51.152704053094368],
              [1.388758579716735, 51.152433626635244],
              [1.38443313316569, 51.149715561391545],
              [1.38416651704384, 51.147734412759299],
              [1.38236505027357, 51.144496977088494],
              [1.380210845847381, 51.143374630748106],
              [1.379548021491216, 51.142171337323511],
              [1.37634133235942, 51.140517150461811],
              [1.364655667558816, 51.136091228205061],
              [1.36180655970542, 51.135625427447621],
              [1.35920852286263, 51.134737655474936],
              [1.35599086322968, 51.134491000943875],
              [1.35503298782921, 51.13386095446598],
              [1.354332567012758, 51.134040282454748],
              [1.35353102561959, 51.133692685540304],
              [1.348378104726203, 51.133155934904217],
              [1.34357238265058, 51.131368572800937],
              [1.34252913459641, 51.13056276238828],
              [1.343366008528256, 51.121768411372514],
              [1.342965389896768, 51.121841943314202],
              [1.342628529053084, 51.126893441113687],
              [1.338388119166299, 51.126886054181753],
              [1.339118013870481, 51.125353088887763],
              [1.338258241186171, 51.126849286587635],
              [1.337882028671691, 51.126766484851295],
              [1.337620676662436, 51.127166145506443],
              [1.336719568426173, 51.126960706396503],
              [1.337980154405087, 51.124936740452561],
              [1.336771348604492, 51.126628201432851],
              [1.336271424145013, 51.126514745764403],
              [1.336088306568931, 51.125997349454302],
              [1.33691739639342, 51.124550619029819],
              [1.335922580454128, 51.125919325158129],
              [1.334415680739415, 51.126590212925464],
              [1.333463974052818, 51.126277350265475],
              [1.335807095319858, 51.123921174114273],
              [1.333955863279879, 51.12514880041828],
              [1.333293344438111, 51.124663070261725],
              [1.328811355026193, 51.123202298623831],
              [1.32736974062376, 51.125079298411443],
              [1.327538491921813, 51.12559711663198],
              [1.326900177013712, 51.125844674337003],
              [1.322455780434556, 51.124906112495424],
              [1.322564771282912, 51.124453250474588],
              [1.322276434907208, 51.124896820268894],
              [1.320031047418417, 51.124382256327202],
              [1.320223082460363, 51.124010692502047],
              [1.319869881015935, 51.124327467593972],
              [1.319407707483968, 51.124181384176694],
              [1.317190256640662, 51.123317856642622],
              [1.315699589845313, 51.122103537849412],
              [1.312882092186862, 51.119224934926443],
              [1.317622554852933, 51.117126748347758],
              [1.322432353490153, 51.114686371619612],
              [1.323017268700897, 51.114094937749655],
              [1.317724029635992, 51.116728075667396],
              [1.316439189698972, 51.115770582927802],
              [1.317096772200449, 51.115354323133964],
              [1.314320202343666, 51.116335352266276],
              [1.312827480680349, 51.117004660090196],
              [1.3123514532501, 51.117781853679297],
              [1.311890075121372, 51.117645613456858],
              [1.312415612482537, 51.117919460696164],
              [1.312565231789921, 51.118851622381229],
              [1.31127513506557, 51.119038416204987],
              [1.311217947618764, 51.119337779296835],
              [1.310947860355231, 51.117880110745503],
              [1.310537920181474, 51.117803574960732],
              [1.310079488971861, 51.11678930944138],
              [1.312897397624427, 51.116444073096304],
              [1.314650740795513, 51.115722393484312],
              [1.314977478067398, 51.115495437457909],
              [1.314607915435939, 51.115244162135404],
              [1.316297262051271, 51.114647507622244],
              [1.31361083833952, 51.115190591464547],
              [1.31358492167124, 51.114748760700692],
              [1.319790439085356, 51.111844317722749],
              [1.327810155083647, 51.111367864321942],
              [1.319279191367425, 51.111570097747915],
              [1.319088047786196, 51.111794113472655],
              [1.318387840412147, 51.111535153073419],
              [1.314419668964281, 51.112854969189009],
              [1.312672770651507, 51.114404025995526],
              [1.301648054207767, 51.113312875693993],
              [1.296858357965366, 51.112156433389202],
              [1.295774060464621, 51.111378359537142],
              [1.295445268067132, 51.110433243258299],
              [1.293830866433899, 51.109413741400402],
              [1.281210346980974, 51.107859230699553],
              [1.28193070451597, 51.106249544986881],
              [1.281617319459991, 51.105759116045093],
              [1.267661962696977, 51.10168871510151],
              [1.262322180367676, 51.101677850545379],
              [1.261447046584838, 51.102640482270331],
              [1.252996893215658, 51.101471061873958],
              [1.251348672193922, 51.100969145340478],
              [1.249903074140495, 51.099791431198121],
              [1.248843991663968, 51.099886556010375],
              [1.24818901369976, 51.100630677756833],
              [1.247094543863343, 51.100934557933719],
              [1.235919628861523, 51.100506153192576],
              [1.226260047972701, 51.099555399997648],
              [1.225898114846223, 51.099240672734709],
              [1.221104774370662, 51.098105537717892],
              [1.220823685308461, 51.099466148675283],
              [1.220435756841214, 51.101201200779307],
              [1.219498542273003, 51.101344869911394],
              [1.215152276203937, 51.100291615783597],
              [1.214064230815306, 51.10027928031203],
              [1.213246120451305, 51.099709911383506],
              [1.209231187474337, 51.099145651117105],
              [1.209165608444058, 51.098426048879602],
              [1.206132310169995, 51.097841907575756],
              [1.204339862253898, 51.097186777172155],
              [1.204234901222129, 51.098973375122235],
              [1.202624455488704, 51.099607613255202],
              [1.197150974541472, 51.101711266386729],
              [1.194894250265786, 51.103065593295447],
              [1.196748787257111, 51.10451523056792],
              [1.195068943098648, 51.105680167749931],
              [1.195513982493158, 51.107656795578805],
              [1.194584432211718, 51.110668565336802],
              [1.193972596175493, 51.111141341562963],
              [1.195538649222703, 51.114038089091814],
              [1.195614615410241, 51.11588358791866],
              [1.19733562306554, 51.118143724119697],
              [1.192400197619235, 51.119152100391737],
              [1.188953220883355, 51.119239038587835],
              [1.187728596217279, 51.118868582116001],
              [1.187719750224419, 51.119985102777513],
              [1.185769010384726, 51.121399252311434],
              [1.180835327325055, 51.119197675334163],
              [1.179119316423167, 51.120868874454899],
              [1.179019388525182, 51.122805514064183],
              [1.17797751585523, 51.123701872367477],
              [1.174839787475214, 51.122259828223392],
              [1.173020835034163, 51.122517923180105],
              [1.17146662915312, 51.124637057649551],
              [1.173932727599075, 51.126727086403143],
              [1.174123599862921, 51.126988152362024],
              [1.173421218779758, 51.127188929112499],
              [1.175116944419871, 51.129110960165931],
              [1.175731950450609, 51.129157211331581],
              [1.175503506131861, 51.128536470873641],
              [1.175832700865149, 51.128459162101343],
              [1.176510050826423, 51.130204662831872],
              [1.176047630642316, 51.13046549402398],
              [1.176907958617839, 51.13123186398176],
              [1.176777078499016, 51.131907346247658],
              [1.177926737777754, 51.133617509829193],
              [1.178895370041642, 51.134523040594999],
              [1.182137927325026, 51.133359882667833],
              [1.184474363339239, 51.134777703757614],
              [1.185890341451751, 51.134142738768695],
              [1.188225469491474, 51.134688907938873],
              [1.190086099238788, 51.135982876315595],
              [1.189180222176305, 51.136509523852339],
              [1.189125338245628, 51.13876156615472],
              [1.193992685668339, 51.141601469399333],
              [1.195323012319881, 51.142726353061001],
              [1.197800864691053, 51.14367410663354],
              [1.198988613853956, 51.14480285122324],
              [1.202238266452514, 51.145659220617617],
              [1.203062882890706, 51.147199951592945],
              [1.204491142359714, 51.148267179844481],
              [1.207958593825731, 51.150257986841979],
              [1.211033389312495, 51.151471520167789],
              [1.210194230264023, 51.153515752351389],
              [1.207646587203383, 51.156829302687093],
              [1.20303585306452, 51.155107291389108],
              [1.20116947724296, 51.155275364193727],
              [1.194147065772585, 51.15422710092998],
              [1.190627933486381, 51.154011105947404],
              [1.187824302781231, 51.154898960926879],
              [1.182961498340891, 51.157935073131235],
              [1.181874490473375, 51.157354815230441],
              [1.181234253159741, 51.156157926625056],
              [1.17951192491948, 51.156716608743153],
              [1.177059397875093, 51.156911932957371],
              [1.177602766305474, 51.159239442386713],
              [1.176538288281483, 51.162440892198724],
              [1.175706486488574, 51.162244020370458],
              [1.175352232804948, 51.162438941079643],
              [1.171674347880775, 51.160647174024959],
              [1.171033547679888, 51.161169177177683],
              [1.170704731311537, 51.163131794962929],
              [1.168931439045597, 51.162660881896272],
              [1.165807137206459, 51.160009298656774],
              [1.165790905024157, 51.158498592635944],
              [1.161918523941122, 51.158014255072345],
              [1.157026320233026, 51.159790775768528],
              [1.156377715246566, 51.160326400205136],
              [1.156310252669503, 51.161265490241],
              [1.15376169745429, 51.162364202715331],
              [1.152905621408559, 51.162879319920037],
              [1.143654289273958, 51.165865656473393],
              [1.142746337453742, 51.169112033452976],
              [1.144090492617877, 51.173515748156952],
              [1.147607959051928, 51.177174637751634],
              [1.149864206593743, 51.185266802901417],
              [1.150018120151786, 51.185592766804909],
              [1.151434053763069, 51.185242481907316],
              [1.151962967771542, 51.188210017747188],
              [1.152402542935296, 51.188233244705813],
              [1.153600044027458, 51.190907499350452],
              [1.157772237074543, 51.191006253987865],
              [1.15955032508623, 51.187872970079574],
              [1.163732665934254, 51.185157635966874],
              [1.163414475776027, 51.184210990545324],
              [1.169886709669779, 51.183943912804864],
              [1.174324401115068, 51.186414022107947],
              [1.177575371941643, 51.18737810132744],
              [1.179465539401015, 51.188283764907325],
              [1.180542933376564, 51.189358131943109],
              [1.180808850543142, 51.190475260432827],
              [1.182416641814948, 51.190313668683785],
              [1.183882598464354, 51.189610825877054],
              [1.188235800535029, 51.18590590324483],
              [1.192107154540838, 51.183832158719191],
              [1.194547182711785, 51.184139690880727],
              [1.195474249508199, 51.184754791637459],
              [1.194300176898013, 51.185224034878793],
              [1.19818032016098, 51.186629091249181],
              [1.197662956139984, 51.187094791054712],
              [1.203055065518195, 51.191349296187745],
              [1.20599891820439, 51.193091874524001],
              [1.207357102191485, 51.194220364752546],
              [1.207755152393646, 51.195127827985345],
              [1.21561461541688, 51.201273143155476],
              [1.219693766406021, 51.204290222034494],
              [1.222164012726678, 51.207390194163736],
              [1.219422153755831, 51.209385253979519],
              [1.208725132158806, 51.213010148381805],
              [1.202429352856923, 51.217586674723897],
              [1.197114154136642, 51.219630957706869],
              [1.193214588785686, 51.220136892455649],
              [1.190351359817815, 51.219557524077715],
              [1.185518692261677, 51.217815649040041],
              [1.187384995942463, 51.2197436643619],
              [1.189020474030483, 51.223302423741991],
              [1.191910626453046, 51.227060786219582],
              [1.193799214921294, 51.230541512596218],
              [1.195245855557062, 51.231693815724626],
              [1.197649515517449, 51.236871235855965],
              [1.198900526310327, 51.238462398612164],
              [1.200909883644885, 51.239932950713694],
              [1.206306683794639, 51.246258738731576],
              [1.208619765180411, 51.248233544482567],
              [1.211515954168505, 51.252422131518678],
              [1.207652257306405, 51.255188851931671],
              [1.205626340251791, 51.254543695045037],
              [1.204346619973389, 51.255434176904785],
              [1.204352013673468, 51.256438757651175],
              [1.200374152574918, 51.257470546531316],
              [1.201015418612366, 51.258694290600211],
              [1.196832460237949, 51.259067747757825],
              [1.196442227423287, 51.25765902317999],
              [1.195233549029471, 51.256085566207858],
              [1.194087732869293, 51.256533325572015],
              [1.19261195088469, 51.25718436983189],
              [1.193417030152542, 51.259057608333499],
              [1.194949698800831, 51.258874532449013],
              [1.196401807275425, 51.261847237283021],
              [1.194557803948499, 51.262234922800914],
              [1.195176973946035, 51.265413883333778],
              [1.197057843697435, 51.267888245227134],
              [1.195719669606902, 51.268295400936069],
              [1.194793762511038, 51.269312825898375],
              [1.203423721854443, 51.275547705544341],
              [1.20576444848943, 51.276540477447185],
              [1.206542715459742, 51.277316091510514],
              [1.206676884121773, 51.278315341103436],
              [1.208041739527905, 51.279131531900489],
              [1.208942517373988, 51.280367006400731],
              [1.210460587563438, 51.280910919661792],
              [1.210554920064438, 51.28168728635714],
              [1.209744265812745, 51.281765300089724],
              [1.210401649569804, 51.282306741704168],
              [1.211358777355998, 51.282251687244035],
              [1.21231695082297, 51.282684118202248],
              [1.212045201680229, 51.282941637457562],
              [1.212380012956084, 51.283464040735034],
              [1.211737380509984, 51.283915238444671],
              [1.211903391646251, 51.284444076238216],
              [1.211403364762172, 51.28506855530555],
              [1.210663012013171, 51.284921572261794],
              [1.210357003361726, 51.28448292555283],
              [1.20841213385189, 51.284402258305484],
              [1.208747873540962, 51.285431956303761],
              [1.206813258496503, 51.285910460908163],
              [1.206231172005363, 51.285173546174725],
              [1.205196890702631, 51.285707399102527],
              [1.2041907183839, 51.287015828853292],
              [1.20361666421489, 51.287217743753708],
              [1.202711259628753, 51.288738377106057],
              [1.203504833429562, 51.289154698386376],
              [1.20344590081818, 51.28962674258679],
              [1.202622459756638, 51.289935323478488],
              [1.202718196958871, 51.290341968023306],
              [1.201934108574022, 51.290062103329007],
              [1.199971759397427, 51.291568461313695],
              [1.200688439345864, 51.292095742547083],
              [1.202993083725926, 51.292785537187889],
              [1.202780718320798, 51.293176330295452],
              [1.204208897466865, 51.294360522572866],
              [1.2030768739124, 51.295039149258507],
              [1.204165136905974, 51.296862286910738],
              [1.203911648644734, 51.29756902737266],
              [1.205223073619399, 51.29771390343975],
              [1.206095703020361, 51.298767575860808],
              [1.204414815579452, 51.299127545062682],
              [1.204943970429952, 51.300646670954009],
              [1.205806482573247, 51.301307548369032],
              [1.207466698435974, 51.301246755354271],
              [1.20807692824227, 51.302013475515537],
              [1.207432218460242, 51.302521370041099],
              [1.208266735310497, 51.303664221267717],
              [1.209903189359502, 51.304433370555948],
              [1.209750242944228, 51.305099586836597],
              [1.210977258190365, 51.305347458303771],
              [1.211928862886727, 51.305124351985611],
              [1.212919954123647, 51.305876993965214],
              [1.212510961047631, 51.306636595362235],
              [1.213220242236207, 51.30740146957536],
              [1.213155217946448, 51.310063930263404],
              [1.21397876715237, 51.311353659545361],
              [1.216484251500239, 51.310405116178949],
              [1.217017526364308, 51.309760815957432],
              [1.218506848712052, 51.309837691273472],
              [1.219778790159494, 51.308860036118929],
              [1.221672846791573, 51.308876260901627],
              [1.221822758371411, 51.310093635141143],
              [1.2197425056727, 51.310636619189331],
              [1.219195957290358, 51.312156496441972],
              [1.221612051324463, 51.31300477590937],
              [1.223558537048143, 51.312292743551495],
              [1.224727773584882, 51.312470105888885],
              [1.2247078839764, 51.313782104938475],
              [1.225692713977149, 51.314235285331364],
              [1.226301813407161, 51.314181613419038],
              [1.229354687289964, 51.316060977178886],
              [1.229267399364871, 51.316452863933414],
              [1.228596510596087, 51.316465080573771],
              [1.227787555956573, 51.317086456350495],
              [1.227841233419757, 51.317646255148816],
              [1.225810725369446, 51.318022466453847],
              [1.22545923023698, 51.318720262488171],
              [1.225920626338576, 51.319260727175312],
              [1.225399558058839, 51.319874148485304],
              [1.225925519094422, 51.320209549756072],
              [1.224810892325768, 51.321815271657748],
              [1.22266947440524, 51.322025379390169],
              [1.222653739989148, 51.322967573323872],
              [1.220820446095898, 51.322577299751771],
              [1.220263104079669, 51.322961427275906],
              [1.2208543229566, 51.323777178997055],
              [1.223127433533914, 51.324174228416567],
              [1.224579132850713, 51.325221711965654],
              [1.227416932406771, 51.325358457185033],
              [1.229908782513491, 51.325005468081791],
              [1.229573478121067, 51.325953785664915],
              [1.228067845765067, 51.32685793528038],
              [1.230472041329376, 51.328503311936025],
              [1.231689513561835, 51.328625307894683],
              [1.231948533054632, 51.328001107182693],
              [1.231163859482589, 51.326785982558405],
              [1.232927886818667, 51.325984391376061],
              [1.233852297999702, 51.325982235710924],
              [1.234487162841859, 51.326618616427645],
              [1.234194467022785, 51.327599950695273],
              [1.233646784984447, 51.328242927433173],
              [1.232379723822193, 51.328823920798875],
              [1.232031078854546, 51.330316802659929],
              [1.230893561413247, 51.331515745644893],
              [1.232615355498344, 51.332253448197562],
              [1.233997400211974, 51.332039864172337],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000109",
        LAD13CDO: "29UG",
        LAD13NM: "Gravesham",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.320533350143323, 51.457608384467406],
            [0.328453473864147, 51.450844016385389],
            [0.326488987828055, 51.450904612010326],
            [0.325134428109643, 51.450335244108061],
            [0.327011645090187, 51.450398711177371],
            [0.327636807194562, 51.450182151603109],
            [0.327569667603783, 51.449909192622087],
            [0.328674840713251, 51.449791911240503],
            [0.329706882195287, 51.450301999508532],
            [0.330388237778927, 51.450228199259776],
            [0.33774664721199, 51.448184089090965],
            [0.337564212283983, 51.447627451011002],
            [0.338577864794626, 51.447466934863257],
            [0.338755148518293, 51.447781756882037],
            [0.348494886242772, 51.446359414745636],
            [0.348364228021348, 51.445944747194993],
            [0.351557258064746, 51.445738628953734],
            [0.353107000118856, 51.445929655675776],
            [0.355864727146812, 51.445599059288355],
            [0.355918865217353, 51.445812010064124],
            [0.357388370751835, 51.445786057455308],
            [0.36375053944634, 51.445526607888922],
            [0.363946144709239, 51.445189912216563],
            [0.364062233422819, 51.445431287609026],
            [0.364760051056239, 51.445232847426446],
            [0.364947059058958, 51.445565421429961],
            [0.369677516203681, 51.445229746909227],
            [0.371843963388692, 51.444684097028514],
            [0.372236737882925, 51.444987314025738],
            [0.380475791087846, 51.444154746576636],
            [0.382192314667505, 51.443290686718548],
            [0.381969398551831, 51.443735888463529],
            [0.382814173447402, 51.443874298794647],
            [0.38782677092733, 51.44362837987935],
            [0.388343910378143, 51.443169076711889],
            [0.388655007285038, 51.4433668838263],
            [0.393323301174627, 51.443363370584215],
            [0.393317062400831, 51.443601820324716],
            [0.400797748621211, 51.443055800536762],
            [0.406535140344964, 51.443296055605792],
            [0.414428655744812, 51.443868448155428],
            [0.428399309573174, 51.446761626572474],
            [0.434241035481878, 51.447517282791381],
            [0.437414585800815, 51.447328111863456],
            [0.439144404558707, 51.447622177690192],
            [0.440214506373963, 51.447420033335497],
            [0.439339769565986, 51.44799313437553],
            [0.44037771610316, 51.448375326907339],
            [0.440471017549351, 51.448802367199647],
            [0.442517233254172, 51.44987221689361],
            [0.443050054869455, 51.449850318758877],
            [0.443269290728123, 51.449508497262691],
            [0.443463189825818, 51.450336340591285],
            [0.44437244122451, 51.450624952450838],
            [0.444353391846497, 51.45032227347015],
            [0.444737040019601, 51.450444674744666],
            [0.444734112031119, 51.45095555880166],
            [0.445705310313461, 51.451756389199964],
            [0.447915589802357, 51.450937712757984],
            [0.446221647626993, 51.451991131642956],
            [0.447472330628247, 51.452930007482706],
            [0.448330430085998, 51.451804101593254],
            [0.449017406241717, 51.451542430348837],
            [0.449842869837857, 51.451716736545791],
            [0.449989132459693, 51.451437582559521],
            [0.450476013355094, 51.451901353765855],
            [0.45390727867699, 51.452835916567828],
            [0.454517314453897, 51.45385018502197],
            [0.454714240964064, 51.453657199681238],
            [0.455066852779788, 51.453978071757589],
            [0.457063504622125, 51.45398029601845],
            [0.459429965799783, 51.45502425358422],
            [0.459604695155898, 51.454739993272739],
            [0.459272008010665, 51.455540190830867],
            [0.459037480093879, 51.45647952376833],
            [0.459358674038449, 51.456616678164458],
            [0.460705214692402, 51.455984958307283],
            [0.462086187146148, 51.454549390190579],
            [0.465578026053201, 51.454329389049043],
            [0.466024495570639, 51.454787651646001],
            [0.467566738925906, 51.454344192091725],
            [0.4687229679771, 51.451202731099521],
            [0.469598732019225, 51.451240937962588],
            [0.469771755949455, 51.450579874218654],
            [0.475735952389834, 51.446766731506656],
            [0.476819300389165, 51.446653006152879],
            [0.477292432854489, 51.446247295066001],
            [0.47888257467878, 51.446376450475825],
            [0.479858902641254, 51.446041024424957],
            [0.480164883826669, 51.445552501631823],
            [0.481096315580573, 51.445690169937109],
            [0.482759949287481, 51.444919273351317],
            [0.484335304053291, 51.444750085921108],
            [0.486061913261832, 51.443497557191925],
            [0.487931274092749, 51.443284310767822],
            [0.487888341647917, 51.440376744243949],
            [0.490944445390584, 51.438676811787381],
            [0.489730679991969, 51.437579325561302],
            [0.486328058213934, 51.436025557844175],
            [0.488827507576996, 51.434941847739154],
            [0.488335761763581, 51.430527513665673],
            [0.48845295575537, 51.430221945428237],
            [0.489357598213714, 51.430030954554155],
            [0.489182938801215, 51.427029951083362],
            [0.489590854778559, 51.425933075771951],
            [0.490127133380621, 51.425847933308667],
            [0.490491258041567, 51.425083843911118],
            [0.491167863292266, 51.424775373847105],
            [0.488531850619919, 51.419443405790567],
            [0.489207094356382, 51.41532624007214],
            [0.488254584122046, 51.414207000706504],
            [0.483650948305885, 51.412749714261281],
            [0.480875403105872, 51.414796976145588],
            [0.475111716155778, 51.412947517410657],
            [0.475477595585274, 51.408646309900554],
            [0.475974320585967, 51.408790500432382],
            [0.477587340418366, 51.407186139795591],
            [0.474607910056578, 51.406229253995228],
            [0.468182315520869, 51.403171231249615],
            [0.469424745606746, 51.399704149669304],
            [0.467928229458152, 51.399520743085269],
            [0.467686946521452, 51.398537444570138],
            [0.467658430583383, 51.398301517201944],
            [0.456688317601988, 51.398589648827027],
            [0.451824523158385, 51.398321018701083],
            [0.453886314809184, 51.397034054275927],
            [0.456680493726835, 51.394285548363172],
            [0.453293261018626, 51.391378805949998],
            [0.451973165548777, 51.391450513969815],
            [0.447921671759914, 51.390361606694697],
            [0.447333700823312, 51.390010557472749],
            [0.44711511632303, 51.388958394550556],
            [0.434612684857216, 51.38888076433048],
            [0.431080595085185, 51.388033196882638],
            [0.431116583648844, 51.38638035824323],
            [0.430157793863905, 51.38366625302546],
            [0.424936833319322, 51.376581517245739],
            [0.423384835214083, 51.37614598092383],
            [0.420907886371567, 51.376100075008203],
            [0.419648132988599, 51.375591897310166],
            [0.419165803691146, 51.374565818550394],
            [0.417876185790112, 51.373326174555068],
            [0.418403472346959, 51.372643479517812],
            [0.412509990841031, 51.363992841325569],
            [0.407165511279937, 51.359824643183615],
            [0.404704687766578, 51.356504464178869],
            [0.404138935017562, 51.356288546402112],
            [0.403428076177245, 51.356456025777163],
            [0.401254151274325, 51.352955414995805],
            [0.39854893001894, 51.352501827009171],
            [0.397072007603324, 51.352685855492531],
            [0.396526499401534, 51.35004935825868],
            [0.391534972784944, 51.347469568031613],
            [0.390994407127142, 51.346796207800139],
            [0.392349767335727, 51.346183953522413],
            [0.394186358998422, 51.345878413458188],
            [0.395025776480852, 51.345121988491037],
            [0.392848492282326, 51.343931703747067],
            [0.39190451896748, 51.343783712119325],
            [0.390103424129781, 51.341789774470904],
            [0.388849342751891, 51.33877019322366],
            [0.38708089148228, 51.33891777703024],
            [0.384491651857668, 51.338688139816732],
            [0.383401252252165, 51.338953161928067],
            [0.38206303919961, 51.338869774245254],
            [0.380697199579046, 51.339333732357844],
            [0.377109846371437, 51.335853318448123],
            [0.381903625586049, 51.336155207293722],
            [0.38343011634882, 51.335773401562292],
            [0.381126309937947, 51.330215575188511],
            [0.377777779685836, 51.32928445643811],
            [0.366154900831812, 51.328459596121895],
            [0.365560159241834, 51.328311531133842],
            [0.365376448251608, 51.329371967398323],
            [0.36347735509253, 51.329541621323827],
            [0.363276652737545, 51.329993543714501],
            [0.361125138369072, 51.329665523047673],
            [0.361120445952485, 51.328596302937072],
            [0.360733706237659, 51.328361279548652],
            [0.36048587810098, 51.328538949338913],
            [0.358703534973819, 51.328269992557395],
            [0.35783719302064, 51.329122928090136],
            [0.356275109793987, 51.328502353708004],
            [0.354953698799691, 51.328459689815062],
            [0.354789151208345, 51.326817209163174],
            [0.351496814383686, 51.32682851864886],
            [0.341053036698094, 51.325007100133618],
            [0.34157613967938, 51.329217228450396],
            [0.343636082010157, 51.332594393697605],
            [0.344697833965163, 51.337533877172632],
            [0.344690651326887, 51.338659090440913],
            [0.340761593195342, 51.339744954574819],
            [0.341506326575215, 51.341285015767156],
            [0.341475160535286, 51.343518687961954],
            [0.340612824522243, 51.343644747127492],
            [0.342261711470101, 51.348354868733423],
            [0.341778013064413, 51.352190309686925],
            [0.341591894945033, 51.354539493527746],
            [0.338863028609954, 51.35645924131984],
            [0.337025614561874, 51.360008714443623],
            [0.335540579384237, 51.360635495029271],
            [0.335230319858541, 51.3650690910981],
            [0.336663146654705, 51.369762901669837],
            [0.335119004187734, 51.369657898446917],
            [0.33264877703561, 51.368293373841105],
            [0.331418334548929, 51.371279367079637],
            [0.33211550852895, 51.372896872186054],
            [0.331781525758354, 51.374694086930887],
            [0.332377126631395, 51.375782108453691],
            [0.332691449887599, 51.378516105605868],
            [0.333810502920797, 51.380880634180187],
            [0.333662592096713, 51.383142695180325],
            [0.334503040692159, 51.384294176491842],
            [0.335991304924689, 51.385380573920102],
            [0.33664995861198, 51.386544658615641],
            [0.337039373331825, 51.387670943643386],
            [0.336397123801596, 51.387591124253689],
            [0.335166269557051, 51.388230807787963],
            [0.334590838016269, 51.38946356484967],
            [0.33570313853835, 51.389966599831588],
            [0.339840821777041, 51.390686227662314],
            [0.341003986850146, 51.391170210261002],
            [0.342601460537594, 51.391235403211546],
            [0.344542720688588, 51.393188580722502],
            [0.344212031611549, 51.394002795912336],
            [0.34373395494254, 51.395477368011122],
            [0.338708410597427, 51.396356621382381],
            [0.33835815177337, 51.398142483139424],
            [0.338855908902539, 51.399501323148939],
            [0.337700921934255, 51.399773489402577],
            [0.337152736469979, 51.399060468410539],
            [0.336527440728829, 51.398949733368724],
            [0.335065637206431, 51.399323321122246],
            [0.335141182219846, 51.402386720562355],
            [0.334084721640658, 51.406345921839169],
            [0.334379766185541, 51.40886355133361],
            [0.332063140814287, 51.411830693292153],
            [0.331054814336894, 51.414709711270213],
            [0.331241849856078, 51.420759339322288],
            [0.330593543241874, 51.420993469580679],
            [0.332574941092356, 51.425611638448778],
            [0.330886956467615, 51.426589516689916],
            [0.328039517070952, 51.427739698295404],
            [0.326564408449427, 51.428974985894783],
            [0.325204455528073, 51.429221413487276],
            [0.324906872462147, 51.430387442650883],
            [0.323824723875837, 51.431658064544344],
            [0.323606983540919, 51.435418845205156],
            [0.325988282551802, 51.439893388366279],
            [0.326334303440584, 51.44265282901128],
            [0.323535824055942, 51.443173294200285],
            [0.322519674320378, 51.445070315524966],
            [0.321426453661001, 51.445619875267809],
            [0.320558187472172, 51.445355583715077],
            [0.321678719265876, 51.446223717074005],
            [0.321689900032726, 51.446897984713274],
            [0.319278854551124, 51.447004178052978],
            [0.31980080484344, 51.448896805603169],
            [0.319309682990068, 51.449486585759715],
            [0.320296943143919, 51.450307907119281],
            [0.31967910458588, 51.451237441464222],
            [0.317715354364755, 51.451938189280078],
            [0.310656488135148, 51.453045230725365],
            [0.312775100622909, 51.458964112719471],
            [0.313709106822037, 51.460267673944202],
            [0.31343822718635, 51.460832395136421],
            [0.314487983987255, 51.462060821010127],
            [0.31536853169734, 51.464950918312439],
            [0.317512966353472, 51.463116202949557],
            [0.317467590521572, 51.462448008881971],
            [0.318983626091203, 51.460858610249161],
            [0.320533350143323, 51.457608384467406],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000110",
        LAD13CDO: "29UH",
        LAD13NM: "Maidstone",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.569612115690639, 51.337012064412747],
            [0.571915622867205, 51.335609732379176],
            [0.573416054110888, 51.336917722817589],
            [0.573868809381187, 51.336798047166042],
            [0.575406268062739, 51.337561079610218],
            [0.577749121005546, 51.33642757733913],
            [0.580259029846038, 51.335944188327311],
            [0.581108244960318, 51.335214966132135],
            [0.583368699246516, 51.334663260300438],
            [0.584633653468701, 51.334715385513718],
            [0.586082107378531, 51.334762544542173],
            [0.586442153935992, 51.335088258092981],
            [0.59178882551448, 51.333763058931488],
            [0.5936945720174, 51.333620124427377],
            [0.594332872276372, 51.333228244711833],
            [0.596782725855264, 51.333339415840058],
            [0.596912134537667, 51.332707882863581],
            [0.599737507682231, 51.332529157349249],
            [0.601374838890726, 51.333301325215039],
            [0.60284743695677, 51.332178552263137],
            [0.607048695133127, 51.3307953662259],
            [0.604595890636951, 51.328604220473466],
            [0.60095126707413, 51.328203185167524],
            [0.601512846695984, 51.327371375987319],
            [0.604395248291655, 51.325697403981899],
            [0.607804894131301, 51.328486913294959],
            [0.610912579306474, 51.332650112376065],
            [0.612896416205732, 51.334664525738347],
            [0.614258859484948, 51.335091893166179],
            [0.615851045524702, 51.335115687957362],
            [0.619416537793484, 51.334469360385782],
            [0.61959218283557, 51.33326925814039],
            [0.618400043755231, 51.33152235446164],
            [0.619199198454965, 51.331563836863857],
            [0.62324528602332, 51.331872568882382],
            [0.629463883573821, 51.33301377778519],
            [0.634669279155536, 51.335258519048935],
            [0.636259779657522, 51.335277577157235],
            [0.639717874800215, 51.336764578732755],
            [0.641905031352881, 51.336197200405095],
            [0.644958484671684, 51.336163331768894],
            [0.656691887387921, 51.331619291281534],
            [0.659061012328092, 51.330557293943194],
            [0.660398352272634, 51.330421682084427],
            [0.664289950259869, 51.328929238915414],
            [0.665241827472921, 51.327990198094888],
            [0.664478529801691, 51.327304254338792],
            [0.663374187635335, 51.325910154657826],
            [0.662323479512686, 51.325242427906566],
            [0.66159512569939, 51.325604354383152],
            [0.660724309323964, 51.325043147619517],
            [0.658765165479154, 51.324725208719634],
            [0.658824921832242, 51.324492709558179],
            [0.662787888856177, 51.31943352434039],
            [0.66531746266945, 51.317240810535012],
            [0.66874864455249, 51.312734047555473],
            [0.669031261616257, 51.311694206620999],
            [0.670925748988331, 51.310092351603984],
            [0.669149284359072, 51.307992330848677],
            [0.673395142299252, 51.30567034035478],
            [0.675152755617023, 51.305619388710213],
            [0.675314494475409, 51.30383669844818],
            [0.671621430791172, 51.299689426557201],
            [0.673034834538195, 51.299274014038943],
            [0.671415424229018, 51.296834068155121],
            [0.675843608897632, 51.295766961689218],
            [0.674239008194895, 51.294371807462063],
            [0.673813571452076, 51.293271679453255],
            [0.680469731946085, 51.291182126173183],
            [0.6928445776799, 51.298120168629922],
            [0.701547285169007, 51.299174260958125],
            [0.705988838664473, 51.298805430349994],
            [0.712702147092671, 51.298790323363839],
            [0.713785854062421, 51.299407368810932],
            [0.716138974657656, 51.296787699954187],
            [0.715557376104035, 51.295587009862828],
            [0.716284702919668, 51.29585434059139],
            [0.717665194956471, 51.293287787641766],
            [0.719837728796014, 51.291795580437991],
            [0.721313642626304, 51.28961080605562],
            [0.722823551662652, 51.289554099174794],
            [0.725272019439339, 51.288657964839871],
            [0.728921917368474, 51.286666178988881],
            [0.733140481217339, 51.285343641421065],
            [0.743843128389327, 51.278469069623668],
            [0.747422543808744, 51.278109863286069],
            [0.75681173783452, 51.278024840964477],
            [0.760032430183699, 51.279314216497802],
            [0.760862927613845, 51.278575998094411],
            [0.764508982342122, 51.276749559846124],
            [0.761493138217927, 51.274858266715967],
            [0.759974929760177, 51.271993498487994],
            [0.758696193363171, 51.271088270839272],
            [0.756865947627939, 51.268969233712312],
            [0.750782452871683, 51.259774771669278],
            [0.749609752803238, 51.25864390125156],
            [0.750736735254865, 51.257833125878534],
            [0.754958595044422, 51.256835276852676],
            [0.757629026093449, 51.25635773506329],
            [0.760730151964386, 51.256610183290491],
            [0.76192453897387, 51.256377826505073],
            [0.765109979792498, 51.258273189520587],
            [0.766355468605806, 51.259869879777035],
            [0.769372982400938, 51.262088390456938],
            [0.775185045789881, 51.264952006129057],
            [0.776753160607424, 51.266564320349651],
            [0.778805641507146, 51.26470237732228],
            [0.782937024893754, 51.264958548896089],
            [0.790516098950874, 51.26178027986213],
            [0.790599376405437, 51.261498575789346],
            [0.78948963880238, 51.260595945926063],
            [0.789539929884828, 51.259130500849011],
            [0.792321224491278, 51.258120665240476],
            [0.793348245306182, 51.257281278140496],
            [0.792157452605385, 51.255111534397379],
            [0.792354716630023, 51.254251482412982],
            [0.79163126004283, 51.251371731950279],
            [0.789347268818306, 51.248634428266847],
            [0.796438212049529, 51.24629484351636],
            [0.794916166794968, 51.24447302378919],
            [0.79262680365098, 51.23807348587944],
            [0.788887681076295, 51.23843611695176],
            [0.787233846145949, 51.239045765878828],
            [0.782424551200641, 51.23936532832451],
            [0.778506081621473, 51.234093459524836],
            [0.777488210547046, 51.231347434716085],
            [0.772334092352613, 51.228373923995598],
            [0.771256107738231, 51.227098016414551],
            [0.769581064888122, 51.226063790408872],
            [0.7657739847606, 51.224706718898574],
            [0.76124889419374, 51.224487147425947],
            [0.76013081188223, 51.224042245111384],
            [0.758699862546664, 51.221862548437535],
            [0.755119085942763, 51.219007670208725],
            [0.754571863726423, 51.21838197623125],
            [0.751190519246922, 51.219366373393974],
            [0.750645293465229, 51.217428371565163],
            [0.749553845037591, 51.21603206440151],
            [0.744553102915033, 51.214661894252046],
            [0.743757964970851, 51.212825963400199],
            [0.744051105915995, 51.212072573751293],
            [0.745983688226645, 51.211750194576879],
            [0.746990048483764, 51.210590602960814],
            [0.746861337310208, 51.210280630432642],
            [0.745447102279634, 51.209326283353697],
            [0.743157408628117, 51.208457215244444],
            [0.740041524152681, 51.208140814339814],
            [0.737834204295702, 51.209212442286223],
            [0.735636981845743, 51.209562463619626],
            [0.733742741073079, 51.209081501160952],
            [0.728978718526514, 51.209271912107482],
            [0.727748511886967, 51.208856303230142],
            [0.725695516011039, 51.209006701797207],
            [0.723948974767165, 51.210588981618244],
            [0.723847398330198, 51.211415206120989],
            [0.716796202203639, 51.211142103592508],
            [0.716204891535461, 51.209440667121108],
            [0.715538072071084, 51.209448057398831],
            [0.713888807354015, 51.207236921503103],
            [0.712063058418075, 51.206064174850702],
            [0.710167101266974, 51.203889288289972],
            [0.709599509670919, 51.201464145861337],
            [0.710147594588689, 51.20016799015626],
            [0.709751721661369, 51.199618631273601],
            [0.708001265026314, 51.199697853331529],
            [0.702320791196485, 51.200749348035018],
            [0.701208969630813, 51.200073497029507],
            [0.699380998886139, 51.199953812409767],
            [0.701807705233836, 51.197339858868133],
            [0.700544559294167, 51.196645012877283],
            [0.699142729761889, 51.196735838554162],
            [0.699269316119003, 51.196305698451432],
            [0.698256966502286, 51.195957606396512],
            [0.69833134185589, 51.195662683630268],
            [0.696189943796495, 51.194217261608578],
            [0.694459088436567, 51.194482007462859],
            [0.692756081558983, 51.195568143357669],
            [0.688347892640557, 51.192580108072839],
            [0.685724833076292, 51.192221258334762],
            [0.683716045028174, 51.191126931323375],
            [0.681829768587746, 51.187975519613431],
            [0.683685013074914, 51.186751137053221],
            [0.683858766253801, 51.185431318622882],
            [0.684904075032674, 51.184382898294217],
            [0.68406386646637, 51.181301934615909],
            [0.684916985953692, 51.178696554308644],
            [0.68078771443406, 51.179220316353728],
            [0.678379814786113, 51.179201746246001],
            [0.677266529970719, 51.178040926323476],
            [0.677602961506516, 51.177500774333552],
            [0.676675304239155, 51.177344831143472],
            [0.675881368755262, 51.175940143645988],
            [0.676644874709524, 51.175189652080398],
            [0.676064496967238, 51.174754133281581],
            [0.676600989296846, 51.173592412244588],
            [0.676136393335578, 51.173129959347378],
            [0.677349533096938, 51.172831465069855],
            [0.676688556431088, 51.172263783475856],
            [0.675369123947598, 51.172134787119404],
            [0.675185284119379, 51.171826000262648],
            [0.673544206930818, 51.171824862708178],
            [0.673197010404215, 51.172278007139077],
            [0.672584125138141, 51.171872895043627],
            [0.672362968385786, 51.172180150339983],
            [0.672133587190962, 51.17152882678468],
            [0.671607422308033, 51.171633489485892],
            [0.671465203270965, 51.171227509540351],
            [0.670491308149027, 51.170813545971363],
            [0.669225160705743, 51.171011546517249],
            [0.66870717337819, 51.171455984608599],
            [0.66793223068291, 51.171340558109627],
            [0.667998816260278, 51.171056625536849],
            [0.667612668509965, 51.171158075092855],
            [0.667624302547711, 51.171656979406102],
            [0.666852675945888, 51.171202394457886],
            [0.665606466611507, 51.17147365196201],
            [0.665325735264856, 51.171220123910196],
            [0.664520547809582, 51.17134910294466],
            [0.665872159112502, 51.170350533611142],
            [0.666049768079627, 51.168593543599904],
            [0.666602142868993, 51.168150131594167],
            [0.663228660174303, 51.166732212793129],
            [0.661377758037792, 51.164723716862994],
            [0.658647573505765, 51.163838760175395],
            [0.658932737743097, 51.16081387296628],
            [0.657574052410055, 51.158129462529864],
            [0.655471238057011, 51.157152822577466],
            [0.65311988054542, 51.156703440689611],
            [0.652173252425467, 51.15533533979783],
            [0.650169143905395, 51.154579418882896],
            [0.647612928760952, 51.152672140217597],
            [0.64756073333951, 51.152210129167749],
            [0.648712841719286, 51.151326899086975],
            [0.648839692579472, 51.150595507268136],
            [0.646784923955766, 51.149349602941165],
            [0.649361070415537, 51.14773977445455],
            [0.648157333169751, 51.146471006546768],
            [0.64997159796202, 51.145392879058846],
            [0.648302820482011, 51.144616734988773],
            [0.647563802439936, 51.144570516635703],
            [0.646386148475218, 51.144330059555251],
            [0.646033229165239, 51.143859565156653],
            [0.643950238070142, 51.143564023017348],
            [0.641151733568057, 51.141304123813242],
            [0.639444098865982, 51.141295025467016],
            [0.63841450798227, 51.14201890436248],
            [0.634349847639288, 51.142398384398462],
            [0.634950962357481, 51.14373933775439],
            [0.633238213278841, 51.144571204998961],
            [0.63251456321158, 51.145297132313353],
            [0.63648209877026, 51.14521581268739],
            [0.637027643700835, 51.14665874204583],
            [0.640974762054075, 51.150775247775442],
            [0.641800850360691, 51.151142426769532],
            [0.640309646346633, 51.152475752963824],
            [0.638192285442011, 51.152411129010964],
            [0.632276769227201, 51.152977085992504],
            [0.631936750516423, 51.152304793695649],
            [0.631591979079342, 51.152302661710529],
            [0.629932724233775, 51.152572945285236],
            [0.626864957241447, 51.152493516548681],
            [0.626088186496603, 51.152841947359619],
            [0.622205928471012, 51.149596811891442],
            [0.621246370355029, 51.148360069895318],
            [0.619560741351275, 51.14846620799495],
            [0.619569555861499, 51.14816740956401],
            [0.618883512288174, 51.148021787811153],
            [0.618360299178299, 51.14868107588606],
            [0.617698471322345, 51.148862286379789],
            [0.617169195516375, 51.148277834514417],
            [0.616945289440418, 51.14955190021545],
            [0.615557464243173, 51.149968801322231],
            [0.616409283764255, 51.150440812325243],
            [0.615366264547559, 51.150304957359445],
            [0.615345215851478, 51.151121183516224],
            [0.614723599886514, 51.150825694908441],
            [0.614296007691334, 51.151002545948515],
            [0.613687967210989, 51.150516975082169],
            [0.613097058071684, 51.151574386972221],
            [0.613692034038092, 51.151727475355898],
            [0.61271056094729, 51.151947281873781],
            [0.612541634080258, 51.152425040207653],
            [0.611703130025833, 51.151783616719932],
            [0.610503136054054, 51.151501924776667],
            [0.605039040555428, 51.150920480070553],
            [0.604222166986963, 51.151854266486723],
            [0.603555849839601, 51.152033697171639],
            [0.604281718502441, 51.153114794604662],
            [0.6052332160917, 51.156448347112566],
            [0.604048313228986, 51.156766149882166],
            [0.603363757667254, 51.158121496570494],
            [0.601825161175532, 51.159017368895249],
            [0.601277985047303, 51.157986244857696],
            [0.598730527033048, 51.157855822813389],
            [0.595890894003784, 51.154279052543473],
            [0.593485186324786, 51.153306233994158],
            [0.593211039680459, 51.153976968676858],
            [0.588832232309994, 51.154568681228788],
            [0.58204359654255, 51.154624372618002],
            [0.578118450231762, 51.155250631344956],
            [0.575642346784505, 51.156114661025491],
            [0.57515517143917, 51.155404979131383],
            [0.576697278077653, 51.149534838586305],
            [0.575561602409189, 51.148836751564076],
            [0.576337118727937, 51.146021649181179],
            [0.576822936282638, 51.145579232478433],
            [0.578593339475378, 51.145480835226778],
            [0.579275536766307, 51.144637443203436],
            [0.577292942230902, 51.144211685420125],
            [0.575856199302704, 51.142983300413476],
            [0.575407347883987, 51.142985095206576],
            [0.573932279204407, 51.141271858437115],
            [0.574094073845261, 51.140839284776561],
            [0.572090598571241, 51.13926267527922],
            [0.572253814138418, 51.138932603818603],
            [0.57160741791152, 51.138440466548296],
            [0.570407963665784, 51.138106189941304],
            [0.568252576577225, 51.136930388312777],
            [0.567975199068998, 51.136466099016431],
            [0.564384333120055, 51.135025852419901],
            [0.560397003074098, 51.134502568618878],
            [0.559986144593471, 51.134414432672699],
            [0.560082836499078, 51.134120911717517],
            [0.559035342759591, 51.133945981561332],
            [0.558818358107037, 51.136226195382108],
            [0.560684309805753, 51.137317671056032],
            [0.559951991952636, 51.137492003631579],
            [0.560119601065541, 51.137782434422597],
            [0.564084333785881, 51.139466442398991],
            [0.565206853056293, 51.140166726313069],
            [0.565582344376011, 51.141137025759384],
            [0.561919775840472, 51.143260769348963],
            [0.559917510442976, 51.142863030432267],
            [0.557667063720334, 51.141777256535534],
            [0.556873324984619, 51.140039906534469],
            [0.555565292936201, 51.138515259292802],
            [0.553999868996069, 51.13833451654709],
            [0.553475915747788, 51.137803629551456],
            [0.552865787090087, 51.137887107921784],
            [0.552556322193571, 51.137330846603838],
            [0.551020982436094, 51.136994712053834],
            [0.550658690951959, 51.137294919867401],
            [0.548884993489844, 51.136731915072467],
            [0.548652676105152, 51.13703018189161],
            [0.548022773112655, 51.136806474392728],
            [0.547371227404134, 51.137046421477386],
            [0.547227448089565, 51.136616048148689],
            [0.545891563563011, 51.136448183255652],
            [0.541882395969832, 51.134432655158079],
            [0.541459596021839, 51.134672991430712],
            [0.540005424124063, 51.134484247256438],
            [0.534740038582477, 51.134944803754536],
            [0.53307568730455, 51.134154342464328],
            [0.528905644708042, 51.134281505508511],
            [0.524266583579514, 51.135480825279174],
            [0.523624524472664, 51.135739321412835],
            [0.518700149724667, 51.136114442762981],
            [0.51674921185115, 51.135701371795037],
            [0.514675931683999, 51.136087756514705],
            [0.513406006527548, 51.13660523298126],
            [0.508993872702037, 51.136622665812581],
            [0.50922382446917, 51.13941301916983],
            [0.509962686196542, 51.140277655623031],
            [0.508422987266505, 51.141386367565033],
            [0.503041906467253, 51.141023233640126],
            [0.502169536482625, 51.142366700841642],
            [0.498785795964266, 51.143169374209641],
            [0.496866658311019, 51.144778002383013],
            [0.495835943338799, 51.144648915136919],
            [0.496018178580598, 51.144448959142771],
            [0.493877537861838, 51.143808414331517],
            [0.492606705842478, 51.143759968838765],
            [0.491215626361775, 51.144266292389531],
            [0.487920677836252, 51.143216728938292],
            [0.483530779330369, 51.142701187775835],
            [0.481277817382623, 51.141347792258479],
            [0.476757996324572, 51.141578543025354],
            [0.468897203892013, 51.143262067563718],
            [0.469378910021798, 51.14505424846832],
            [0.468797309113915, 51.145355232080078],
            [0.469212423174538, 51.145658549668049],
            [0.468828595479662, 51.146128936117861],
            [0.469252890230224, 51.146148755549795],
            [0.470158860123538, 51.147262833975439],
            [0.470407643716777, 51.148922326424071],
            [0.469271783830363, 51.149663126081407],
            [0.46982188048219, 51.15021721523965],
            [0.469041290368426, 51.150515207835554],
            [0.469978821884529, 51.15117443528036],
            [0.469375973787537, 51.15150734806106],
            [0.469648331496913, 51.152349708395036],
            [0.469047657224539, 51.152562956174556],
            [0.46913923529419, 51.153206775198278],
            [0.468383896650372, 51.153309064723075],
            [0.467733706108916, 51.154899397888627],
            [0.467026943718044, 51.155108578333369],
            [0.467672714876086, 51.155444808226392],
            [0.467080512382964, 51.156058988668953],
            [0.467667657916252, 51.156207586534805],
            [0.466662736915296, 51.156431151395445],
            [0.466598703181438, 51.156892983113067],
            [0.466020171478159, 51.156745097548615],
            [0.466143634930578, 51.15742422084184],
            [0.465339467758389, 51.157255908305409],
            [0.465413494006014, 51.158241862573476],
            [0.464071634256864, 51.158262954229123],
            [0.464724755966407, 51.158575661750852],
            [0.464256958609824, 51.159351790305116],
            [0.462372399130602, 51.159898741643595],
            [0.461285801557368, 51.16057816395589],
            [0.461061168172826, 51.16124843001716],
            [0.460134095942537, 51.161620494543406],
            [0.46020646376707, 51.16244279939135],
            [0.458156951415287, 51.163334014744564],
            [0.457950818760525, 51.163600066061157],
            [0.458358209070638, 51.163837030778701],
            [0.45744684381678, 51.163994691971766],
            [0.457103367153553, 51.164744793378603],
            [0.455515106494407, 51.165058762507414],
            [0.454065719887834, 51.16615674726097],
            [0.453405385496019, 51.166140025444136],
            [0.452971590189654, 51.167292230006879],
            [0.452230609845602, 51.167508332098897],
            [0.452070928818081, 51.168246468564483],
            [0.451556739969892, 51.167964953776199],
            [0.451400664992341, 51.168609478802679],
            [0.449310605253469, 51.169337699146972],
            [0.447277031460562, 51.171295939714454],
            [0.445124334410498, 51.171600891000544],
            [0.442884364162722, 51.172637015697589],
            [0.441006992012501, 51.17420155305463],
            [0.440724260883659, 51.176514339926321],
            [0.437339096977002, 51.17768217562876],
            [0.431054293614901, 51.179600941683852],
            [0.416371547443356, 51.180549193347517],
            [0.414179297819883, 51.182354529061008],
            [0.411777458227254, 51.183300753656987],
            [0.412241282993269, 51.185140282610377],
            [0.409400536173995, 51.185439871860837],
            [0.409389421044407, 51.186269315136926],
            [0.410001879979678, 51.186926722565808],
            [0.409373983886215, 51.188442497893973],
            [0.408094468345596, 51.188743156523671],
            [0.407618476947309, 51.188150382330527],
            [0.404447070074978, 51.188562780932777],
            [0.403396959345364, 51.189394691941338],
            [0.404078650177783, 51.190695550649487],
            [0.401293492461644, 51.191273498899605],
            [0.399860438588459, 51.192147414470469],
            [0.396006445270721, 51.192044760449342],
            [0.394196653244999, 51.192777028791511],
            [0.393957961348054, 51.194495198486287],
            [0.391616191779345, 51.194133897625044],
            [0.388814157509386, 51.194613860299555],
            [0.386650758791595, 51.194462864741894],
            [0.383516192040654, 51.195462120040197],
            [0.383260399183942, 51.19619131711422],
            [0.384238212558574, 51.1969709195793],
            [0.38422623305839, 51.198263543649013],
            [0.384751843974569, 51.198823022244852],
            [0.384704265820084, 51.200257571352118],
            [0.385871038719999, 51.200591722318464],
            [0.386596201787008, 51.201521255708521],
            [0.387955882923188, 51.202069094891968],
            [0.390757597664109, 51.201076527890137],
            [0.392001791882726, 51.200417041097111],
            [0.39226080472036, 51.199917994941117],
            [0.393975062555989, 51.199573540763453],
            [0.394110534719817, 51.199287469229539],
            [0.395748457920315, 51.200038174252782],
            [0.399639810629594, 51.19900148323547],
            [0.399990148729442, 51.198416005495403],
            [0.400316141195395, 51.198573899128597],
            [0.401639756667002, 51.20060087089],
            [0.401671127908522, 51.201424042063046],
            [0.402231443604183, 51.201847826721341],
            [0.403481409214823, 51.205453770604194],
            [0.404071598616615, 51.208401439894736],
            [0.403974217835629, 51.211067348327326],
            [0.404814153665463, 51.214133991239692],
            [0.406486963571401, 51.217901186280017],
            [0.408773122640737, 51.221670119417766],
            [0.407330265761548, 51.222288900665205],
            [0.404732403823668, 51.222401689015527],
            [0.401850334529775, 51.222062481292213],
            [0.399947421071051, 51.22253952299284],
            [0.399776151982283, 51.222963936314656],
            [0.398631280226512, 51.223366945308037],
            [0.398823494774896, 51.224446730899302],
            [0.399295095372711, 51.224812092154458],
            [0.399186379812472, 51.225640832005901],
            [0.397399513777181, 51.226507570789153],
            [0.395666729367379, 51.226734630362522],
            [0.393677006079837, 51.225957842620367],
            [0.392896874389196, 51.226105108318336],
            [0.391917359217718, 51.226661156164674],
            [0.391007375565271, 51.228006309167306],
            [0.389467613904588, 51.228532414207884],
            [0.388887225590299, 51.229080283228257],
            [0.386793742327067, 51.229398214941256],
            [0.38622208273093, 51.229949489698079],
            [0.385478512804582, 51.229974546385144],
            [0.38326685545315, 51.231111438558685],
            [0.382003175273462, 51.230968095281916],
            [0.381393588022055, 51.231701787645925],
            [0.380406195281406, 51.231083339714999],
            [0.378147593306061, 51.233424424783905],
            [0.378632201496144, 51.234987543800216],
            [0.381512958014729, 51.23589934415066],
            [0.383136922376713, 51.236857365581841],
            [0.383652772256629, 51.238300214012078],
            [0.385545781641519, 51.238169898089232],
            [0.388250298288464, 51.238687612766547],
            [0.394164873737668, 51.241425784296162],
            [0.39793361986745, 51.244713095517],
            [0.397548642314728, 51.24571387315595],
            [0.399565007107787, 51.24717716076826],
            [0.399632385638464, 51.247673122548974],
            [0.402738882880634, 51.248933224001242],
            [0.406781382556432, 51.252224526695102],
            [0.408186809852348, 51.252325117629063],
            [0.409549907318363, 51.253013843008638],
            [0.409979726759537, 51.253121008195187],
            [0.410777013018018, 51.252663899956296],
            [0.411416812512112, 51.251415899907194],
            [0.417710986058427, 51.250786873059724],
            [0.420823977908817, 51.249947262616075],
            [0.421073341088315, 51.249456449740677],
            [0.422113932683962, 51.249105748501115],
            [0.423615419006036, 51.24942630662931],
            [0.426739323055526, 51.249532436839786],
            [0.430195164059657, 51.248975956803719],
            [0.431283742360786, 51.249101736717037],
            [0.431247006026818, 51.24935611951269],
            [0.429797300476987, 51.249502250726032],
            [0.42944862446726, 51.252320888074074],
            [0.429945897275867, 51.254576047762455],
            [0.431298485887683, 51.254487694207036],
            [0.431638798467282, 51.257079770170662],
            [0.432848798293517, 51.258388368594694],
            [0.433261890554981, 51.260619182680394],
            [0.433141334040574, 51.26216318996137],
            [0.431746811655218, 51.264801215215343],
            [0.432022463802998, 51.267849705583821],
            [0.432711031162443, 51.268943402129679],
            [0.432377980426772, 51.268935036007242],
            [0.432496563942638, 51.270121521648186],
            [0.440173716549241, 51.269926618026332],
            [0.442315726379867, 51.2702794506137],
            [0.445046633966276, 51.270057842279172],
            [0.449705721507348, 51.270373211919697],
            [0.452651411201422, 51.271331386805308],
            [0.452976860963282, 51.271842596949966],
            [0.455365934752125, 51.2715550643792],
            [0.460030360355231, 51.271778177345432],
            [0.461960321595658, 51.271458789262269],
            [0.466823435068311, 51.271322203225267],
            [0.467207891640148, 51.271700824220972],
            [0.469487334033872, 51.271188674273468],
            [0.471065023101303, 51.271199439657188],
            [0.474622815605585, 51.272626196745534],
            [0.476668098009775, 51.272692651797975],
            [0.47762642307441, 51.272276646453342],
            [0.478016970418147, 51.27276662472164],
            [0.477161690966093, 51.273445764222181],
            [0.480645009087812, 51.276011611781477],
            [0.479221095390461, 51.277581495598199],
            [0.480070513098563, 51.278043758069316],
            [0.482654789365444, 51.28116011998646],
            [0.488594482733374, 51.286860047533921],
            [0.489449307087486, 51.289140641053599],
            [0.493633305976255, 51.291366534679817],
            [0.498701088853207, 51.296519733037279],
            [0.502049649403151, 51.295576716240653],
            [0.506126462007232, 51.295681060893209],
            [0.505296502277179, 51.296061272712997],
            [0.501817416606698, 51.295922539446927],
            [0.498993887099421, 51.296733826149918],
            [0.49892986627874, 51.29838102841024],
            [0.497843524618228, 51.299219938794742],
            [0.492664035325227, 51.301354819597762],
            [0.492742783381603, 51.3018019243246],
            [0.494627296097432, 51.303217844070296],
            [0.492489545024628, 51.304568358198999],
            [0.49448693164433, 51.305867658002654],
            [0.49800189361738, 51.306574246162995],
            [0.502061805741096, 51.30534804313649],
            [0.50702535334811, 51.305357858885628],
            [0.510268237319389, 51.306570852368736],
            [0.511586650219645, 51.306435546959939],
            [0.51254629991473, 51.307533761709777],
            [0.512243149778909, 51.308577235311517],
            [0.513594772018658, 51.310124805006289],
            [0.513919915004923, 51.314313355908723],
            [0.517070795110586, 51.316399624980171],
            [0.51895775319382, 51.319066122853364],
            [0.521046859469781, 51.320756025485331],
            [0.522631872089699, 51.321104111339352],
            [0.524388632787013, 51.323376709648151],
            [0.526740697124753, 51.325247010624324],
            [0.526010365475756, 51.326166632005396],
            [0.523307950671197, 51.326281569330362],
            [0.521240938532978, 51.329186976413041],
            [0.523949578562438, 51.331560492234267],
            [0.526046500378896, 51.332804055784216],
            [0.525611865160395, 51.334717384200538],
            [0.532901052574119, 51.337164475226217],
            [0.537303944559161, 51.333875498179488],
            [0.538635399029288, 51.33274221522192],
            [0.538652697558766, 51.331756135794933],
            [0.5401101537052, 51.330254960225147],
            [0.543974851826294, 51.327896548432605],
            [0.545318568804851, 51.328571546107099],
            [0.545891769889379, 51.329563675278031],
            [0.548895052155635, 51.330910333701887],
            [0.553943911654712, 51.331629520770043],
            [0.554734927828112, 51.33222293318925],
            [0.554422932931023, 51.333764061969482],
            [0.556681851221578, 51.33653427220284],
            [0.563428454519626, 51.338743096673205],
            [0.565630976215179, 51.337790088182771],
            [0.569612115690639, 51.337012064412747],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000111",
        LAD13CDO: "29UK",
        LAD13NM: "Sevenoaks",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.210990271441388, 51.407452864573557],
            [0.211372960767942, 51.404953657586411],
            [0.213501725927657, 51.404849609145792],
            [0.212710161650087, 51.402749385130591],
            [0.206830700349189, 51.402414217797165],
            [0.207163326042744, 51.400005897883226],
            [0.206922812783454, 51.397219871875286],
            [0.207504235730088, 51.396844697758922],
            [0.208882462924607, 51.397044447729172],
            [0.212096516660405, 51.396588109878422],
            [0.212812279248155, 51.396763448288965],
            [0.213840247563766, 51.396080336924683],
            [0.215785556853774, 51.396174860912417],
            [0.219315805311727, 51.395571151403999],
            [0.223627352978008, 51.395665662572313],
            [0.227484536398248, 51.395166115411968],
            [0.232670754497741, 51.39589386333185],
            [0.233455077296357, 51.396353751009002],
            [0.235606319300207, 51.396660754450011],
            [0.238285979581623, 51.396306521085727],
            [0.238852562632041, 51.397075400133474],
            [0.240517892835645, 51.397616426962081],
            [0.24015521730186, 51.398373389977266],
            [0.241005923494447, 51.399571194333909],
            [0.240178412781497, 51.40114283239933],
            [0.241866707257046, 51.401003520647791],
            [0.242981690552592, 51.402928294919803],
            [0.244574021436625, 51.403969781990426],
            [0.245615890513406, 51.405425591286438],
            [0.245484133325377, 51.406492903742333],
            [0.246058425578978, 51.40650437878238],
            [0.246126066176886, 51.407033677059836],
            [0.245716441376882, 51.40771241684638],
            [0.258810533101176, 51.407800651624186],
            [0.269098980244784, 51.407419289000678],
            [0.272167156097099, 51.406743795915084],
            [0.272733068023615, 51.407571882620161],
            [0.274026145840483, 51.407086342161513],
            [0.274865643450201, 51.407457651191095],
            [0.277578464287321, 51.406924711787866],
            [0.278478520396901, 51.404522220286417],
            [0.279756046748332, 51.402859709730016],
            [0.277459790156844, 51.401585068940307],
            [0.284015805449721, 51.399618333539159],
            [0.293240000001658, 51.397976743180024],
            [0.296708924100264, 51.397055332827719],
            [0.32556583315628, 51.388905630753918],
            [0.331979508254166, 51.387323915827913],
            [0.33664995861198, 51.386544658615641],
            [0.335991304924689, 51.385380573920102],
            [0.334503040692159, 51.384294176491842],
            [0.333662592096713, 51.383142695180325],
            [0.333810502920797, 51.380880634180187],
            [0.332691449887599, 51.378516105605868],
            [0.332377126631395, 51.375782108453691],
            [0.331781525758354, 51.374694086930887],
            [0.33211550852895, 51.372896872186054],
            [0.331418334548929, 51.371279367079637],
            [0.33264877703561, 51.368293373841105],
            [0.335119004187734, 51.369657898446917],
            [0.336663146654705, 51.369762901669837],
            [0.335230319858541, 51.3650690910981],
            [0.335540579384237, 51.360635495029271],
            [0.337025614561874, 51.360008714443623],
            [0.338863028609954, 51.35645924131984],
            [0.341591894945033, 51.354539493527746],
            [0.341778013064413, 51.352190309686925],
            [0.342261711470101, 51.348354868733423],
            [0.340612824522243, 51.343644747127492],
            [0.341475160535286, 51.343518687961954],
            [0.341506326575215, 51.341285015767156],
            [0.340761593195342, 51.339744954574819],
            [0.344690651326887, 51.338659090440913],
            [0.344697833965163, 51.337533877172632],
            [0.343636082010157, 51.332594393697605],
            [0.34157613967938, 51.329217228450396],
            [0.337302611939318, 51.329781961222736],
            [0.334670758432157, 51.331099833935959],
            [0.329594948918096, 51.332381758332019],
            [0.328285661086793, 51.333061616337595],
            [0.327943929209985, 51.333599018231169],
            [0.321854156922196, 51.3366157857525],
            [0.317412165061804, 51.337815355651863],
            [0.316586152929686, 51.338746320826402],
            [0.314005365054701, 51.339788412678516],
            [0.312457114092309, 51.340978235764204],
            [0.308152913927774, 51.34278986950001],
            [0.306861726275685, 51.343127380155572],
            [0.307460583242412, 51.346244350551352],
            [0.308650787706998, 51.34855285470708],
            [0.308045144298306, 51.349132269785159],
            [0.304646575048367, 51.350807202227031],
            [0.296294116414799, 51.351877798064528],
            [0.293969851565679, 51.349194789561579],
            [0.291504108624783, 51.345368760820577],
            [0.290414976872219, 51.345096887033549],
            [0.288437003186694, 51.345257865391837],
            [0.285738624415187, 51.344995800866286],
            [0.285164030615402, 51.341874658451893],
            [0.285354947980737, 51.34005519052954],
            [0.288317660823562, 51.339576446545792],
            [0.288432013560797, 51.337289018857447],
            [0.288319672965709, 51.335811818979202],
            [0.286772156276502, 51.334113541394302],
            [0.284768389301129, 51.329790912813337],
            [0.284980896222698, 51.327366671613696],
            [0.284447723972189, 51.324899423112008],
            [0.2815982464335, 51.323332594229711],
            [0.280647868323893, 51.321766492009758],
            [0.278536351423344, 51.320574622556414],
            [0.277027006516391, 51.318730683558918],
            [0.276428782599201, 51.319170397126925],
            [0.275257761008612, 51.317896327159403],
            [0.272386508093085, 51.317412492606429],
            [0.267490261571469, 51.315529797752404],
            [0.268128282039391, 51.315226956220464],
            [0.267871978090363, 51.314132941823004],
            [0.265116760797998, 51.313638589032252],
            [0.265808643037246, 51.311778879598279],
            [0.264963133821761, 51.311801535357617],
            [0.264343775365837, 51.308577731619245],
            [0.263678510516157, 51.307101308349992],
            [0.262998795858681, 51.306759214403982],
            [0.263000750028645, 51.30589492152442],
            [0.263658921125573, 51.305303928379402],
            [0.26327057716374, 51.303564040106693],
            [0.265320726200695, 51.301553055799523],
            [0.264639102016052, 51.297061499488258],
            [0.258426182739637, 51.297293044502347],
            [0.25951565910392, 51.295729670153449],
            [0.259817139426636, 51.295121299513291],
            [0.260989429955881, 51.294924192803052],
            [0.260133895343664, 51.293013449629939],
            [0.261178842373209, 51.291510273304489],
            [0.261329305221049, 51.289143026053708],
            [0.258547370078963, 51.285880004099248],
            [0.25799643073201, 51.28379968861482],
            [0.258251277315639, 51.281247865576184],
            [0.257723965483555, 51.27956009998703],
            [0.256373124216546, 51.27853483021682],
            [0.255143556111198, 51.276412721843393],
            [0.258216702473483, 51.274871369545821],
            [0.259411837591251, 51.273864434418243],
            [0.260458220838352, 51.272310867140604],
            [0.260479901484547, 51.270211404771111],
            [0.263118399404401, 51.269201724684962],
            [0.26487084911036, 51.267726191901268],
            [0.265225339221454, 51.265431424432386],
            [0.264629574467322, 51.261973320911466],
            [0.265136474624111, 51.260067712323043],
            [0.264567211035686, 51.257001203678229],
            [0.263813876774827, 51.255787286786671],
            [0.262251059972824, 51.255366042526418],
            [0.261258846812308, 51.254451709249324],
            [0.259127776095706, 51.254949731354891],
            [0.254450976643204, 51.254562376621564],
            [0.253548396668833, 51.254774017592482],
            [0.252480453601509, 51.254622805532222],
            [0.249786742979719, 51.255311351823742],
            [0.24730514013871, 51.255296080954771],
            [0.246427838688362, 51.25188196744319],
            [0.248994045345876, 51.249288459468566],
            [0.250418162930832, 51.247022636029776],
            [0.250350055643699, 51.245656956500895],
            [0.249082457963756, 51.242906878118191],
            [0.24768002402382, 51.242324975736494],
            [0.247310531152587, 51.240006391489601],
            [0.248130539996769, 51.239193828012851],
            [0.248046782495488, 51.238563204203452],
            [0.246985493338008, 51.237687851187673],
            [0.241154496599314, 51.236977690146169],
            [0.230935233642751, 51.2322320408772],
            [0.229428702782938, 51.23336693738873],
            [0.225412074407489, 51.238950018326946],
            [0.224401737703067, 51.240755303816769],
            [0.224854830626244, 51.241682894645173],
            [0.224849420211335, 51.243470869931684],
            [0.223880884195587, 51.243828329252146],
            [0.223056053294733, 51.244907911764933],
            [0.219069137905532, 51.243375582438212],
            [0.21591996296679, 51.241083510193903],
            [0.214070489252026, 51.239088729449662],
            [0.210058667196, 51.23675076768081],
            [0.208094527689906, 51.235852545556156],
            [0.200515201198789, 51.233760495699386],
            [0.200914008509602, 51.231491178867898],
            [0.203038192703163, 51.229449292072928],
            [0.203419775716313, 51.228291862052949],
            [0.204280507707947, 51.227690184528107],
            [0.204732545082005, 51.227768003192153],
            [0.206483313334346, 51.227118956944295],
            [0.208746350218533, 51.225712869399395],
            [0.209253429713287, 51.225833698698743],
            [0.212312291801966, 51.224599571986467],
            [0.21162979361983, 51.223747317088161],
            [0.212221417106926, 51.222853886225465],
            [0.211398288390263, 51.221875684338251],
            [0.20729373638299, 51.221845274711157],
            [0.20519943428658, 51.221145516710074],
            [0.201918916974867, 51.220555288111434],
            [0.201436209569418, 51.218242296244426],
            [0.202348679187399, 51.217227763105576],
            [0.204256090694613, 51.216178292699773],
            [0.204840154193146, 51.21404665904555],
            [0.207108654419028, 51.211988476544725],
            [0.207889471022785, 51.210591466296734],
            [0.208882761314902, 51.210344284597014],
            [0.211128417043414, 51.208892603610828],
            [0.212529091693972, 51.208841838798207],
            [0.214128203976373, 51.208152370838988],
            [0.215321718381113, 51.207335661889609],
            [0.21570927778295, 51.207833748542008],
            [0.21702444689721, 51.207732387620304],
            [0.21781123613985, 51.206705720986996],
            [0.217627650083308, 51.206162405095569],
            [0.218213282340697, 51.205866212135163],
            [0.218836801403842, 51.206000978963175],
            [0.219276356136251, 51.205703953906585],
            [0.222309582095232, 51.206557398158957],
            [0.223704916224083, 51.206635205467862],
            [0.225326580340465, 51.207309447974566],
            [0.226284960016361, 51.205273100294974],
            [0.226451803947316, 51.203389410314067],
            [0.228451209703445, 51.20168846244686],
            [0.231546720662369, 51.201463072869998],
            [0.235329970497073, 51.202315358974964],
            [0.236341981983105, 51.202195289841917],
            [0.237760077741003, 51.200943270069459],
            [0.238740796950114, 51.200621427128624],
            [0.238256762718089, 51.199790706786928],
            [0.2400070900475, 51.198721162177641],
            [0.240516817020697, 51.199018974170038],
            [0.24086094036466, 51.198676928439475],
            [0.241259008885811, 51.19888604287857],
            [0.241794525462147, 51.198623965630624],
            [0.243197142665787, 51.199445153809606],
            [0.245347631605522, 51.199778019733593],
            [0.247486478648822, 51.200665063852767],
            [0.248325982728118, 51.201589641404681],
            [0.24957891130998, 51.201831757549819],
            [0.250081111590164, 51.201708781568477],
            [0.250848226957978, 51.200383683176916],
            [0.250955610733267, 51.198446234313217],
            [0.250529806162651, 51.198226894161003],
            [0.250107505718726, 51.197726890554527],
            [0.249275775321011, 51.19793084943111],
            [0.248135257010374, 51.197731537266286],
            [0.248284561700168, 51.197005597260265],
            [0.247194321449974, 51.196368229852943],
            [0.248049314367035, 51.195613441604081],
            [0.247877803388158, 51.195236316343767],
            [0.247321634606389, 51.195072531074914],
            [0.245818871505016, 51.195369397611181],
            [0.246009772392609, 51.194880087889139],
            [0.247085543983131, 51.194544656727132],
            [0.246737760970798, 51.194250957445604],
            [0.244669534217182, 51.19422141654772],
            [0.245365800840317, 51.191909336213513],
            [0.239739533122427, 51.193028065432436],
            [0.239554137431498, 51.192126878564146],
            [0.238998252530385, 51.191967545332929],
            [0.237381769380646, 51.192368106100176],
            [0.235841906415448, 51.192282435343564],
            [0.235061624239135, 51.192898107120904],
            [0.23228726568747, 51.193448422429476],
            [0.231558742190976, 51.193152051535108],
            [0.231199724895973, 51.191971772151724],
            [0.229038794828384, 51.192124490929835],
            [0.229513524943599, 51.191225996301007],
            [0.228694573741199, 51.190656133542298],
            [0.229086980056843, 51.188822996299187],
            [0.228772207353539, 51.186006865937301],
            [0.226934297637547, 51.184156857134127],
            [0.219241701803748, 51.179993436970477],
            [0.216276177144267, 51.17914133695016],
            [0.218065185337332, 51.177511993513157],
            [0.212183283898899, 51.175449693571188],
            [0.207353497178841, 51.175365578549304],
            [0.205350826938877, 51.172673866395982],
            [0.20342945774363, 51.17123246838397],
            [0.202825553248049, 51.171258234277381],
            [0.202151870557435, 51.172611835344235],
            [0.200675264933012, 51.172667509459693],
            [0.20047381240012, 51.173334112270034],
            [0.199346958822929, 51.173284261081761],
            [0.198828060148589, 51.174465855343449],
            [0.197971645233085, 51.175139369409308],
            [0.197666076809025, 51.175242240163854],
            [0.194753230521333, 51.173827421666665],
            [0.192633362447994, 51.172176430252001],
            [0.190944728440641, 51.171380686784204],
            [0.190614031048731, 51.167964902866068],
            [0.195685322732553, 51.164989218497965],
            [0.195984501744574, 51.164331580333567],
            [0.198985520946374, 51.161748044947998],
            [0.20144802333924, 51.160146755059586],
            [0.202559263740842, 51.161616936015569],
            [0.203119835499562, 51.161734982072204],
            [0.205434472751733, 51.160874751407235],
            [0.205185277329792, 51.159908163126211],
            [0.205590710643759, 51.159802488851525],
            [0.205631873037851, 51.158686530041095],
            [0.207498558533497, 51.15869267637278],
            [0.208705868816497, 51.159026917618526],
            [0.210228270280623, 51.15664727440376],
            [0.212923172896811, 51.155044941561272],
            [0.215427436704393, 51.154362587119309],
            [0.214943854941435, 51.153820451052844],
            [0.212121413914639, 51.151617429336547],
            [0.210891043447472, 51.151694659619892],
            [0.205960307103189, 51.151090793633976],
            [0.203630271518871, 51.149546490156808],
            [0.200216813019306, 51.146023295950961],
            [0.196433275605596, 51.146408299380774],
            [0.194819120440301, 51.144963689955091],
            [0.193927270708641, 51.143251893624196],
            [0.193014225177309, 51.140548514400017],
            [0.193455531933961, 51.139423254036451],
            [0.191135699862879, 51.139552188522053],
            [0.188993751556242, 51.138666887181166],
            [0.187455059660556, 51.136358296480395],
            [0.183143895235328, 51.134937874835025],
            [0.180345542533042, 51.131792936407429],
            [0.175801287617743, 51.133761711253158],
            [0.174250530638587, 51.132943381663871],
            [0.169823944749848, 51.132185633592577],
            [0.167881449800601, 51.132185694077307],
            [0.167292417505497, 51.133556415483909],
            [0.164447249975986, 51.132778132127825],
            [0.162882612214306, 51.134532917982995],
            [0.16240333780932, 51.135808950833599],
            [0.160721459338739, 51.137322153476191],
            [0.160845430238439, 51.137987171100754],
            [0.159720316707044, 51.138904598505057],
            [0.162284066407769, 51.139681008118067],
            [0.160940607150972, 51.140637558777954],
            [0.160096565713016, 51.140533542973046],
            [0.159865759887029, 51.141040536260064],
            [0.1589109213549, 51.141013203462556],
            [0.158468575666616, 51.140663433932602],
            [0.158019935517286, 51.141036845943759],
            [0.157161344160118, 51.140712739919174],
            [0.156569879453264, 51.140905316456362],
            [0.156616707411013, 51.141296563620166],
            [0.155752100666374, 51.141303514154359],
            [0.155424544715269, 51.141975061931056],
            [0.152969918475601, 51.14216148059915],
            [0.153025099658575, 51.14245634615876],
            [0.151868040903759, 51.142876951048649],
            [0.150695598153688, 51.14293629427403],
            [0.150168147721834, 51.142538595568155],
            [0.149744731639228, 51.142995149529277],
            [0.149429423978619, 51.142701467324194],
            [0.149171280254581, 51.143022778555157],
            [0.148419117197372, 51.142742518417556],
            [0.148078293217704, 51.143161575215288],
            [0.144544102169764, 51.143254327655143],
            [0.144847965394516, 51.143733495792425],
            [0.144511270220396, 51.144026559241595],
            [0.143147897939507, 51.144316859712312],
            [0.142544929736333, 51.145004209352017],
            [0.14124935198877, 51.145246479736514],
            [0.141350836810293, 51.145832785947157],
            [0.139461555311605, 51.146284660076553],
            [0.139570241217167, 51.146717949082934],
            [0.137330180778777, 51.146727438722088],
            [0.136899831874861, 51.146449315938739],
            [0.135989539497217, 51.147017239261153],
            [0.134659827466169, 51.146862554122464],
            [0.133090631471678, 51.147281500726919],
            [0.132766326972401, 51.14685303838796],
            [0.130849947293332, 51.146874486219744],
            [0.130574529012997, 51.147165492665358],
            [0.128544736784613, 51.147456970074643],
            [0.125906256957416, 51.146949181579657],
            [0.125129782213617, 51.14719622281546],
            [0.122141827304593, 51.146954607357905],
            [0.121917563648152, 51.14720869139046],
            [0.120032918531533, 51.146425383417316],
            [0.118678890913937, 51.146674757502495],
            [0.117784443411601, 51.146188234180357],
            [0.115320500442778, 51.145897382666618],
            [0.11461578732859, 51.145588168401915],
            [0.114587551528788, 51.145189376029649],
            [0.111739621187661, 51.144628398940782],
            [0.109642194892472, 51.144314620676937],
            [0.108819848374423, 51.144371722890405],
            [0.108604836232155, 51.14467327874884],
            [0.107501112706184, 51.144548384611021],
            [0.107468047597899, 51.144199141057911],
            [0.105453825002707, 51.144078942906347],
            [0.105231624510359, 51.143563130813916],
            [0.104362133860798, 51.143463667729534],
            [0.10426067900621, 51.142961868689952],
            [0.103257441342074, 51.142561730061367],
            [0.10025118259525, 51.1426319539015],
            [0.099480426461749, 51.142124184195275],
            [0.09818990521032, 51.142480999689766],
            [0.097790032282489, 51.142175207678811],
            [0.095979791545849, 51.142326381886704],
            [0.095851750245057, 51.142056180221125],
            [0.095508200310431, 51.142204431515673],
            [0.094396624776027, 51.141714427258968],
            [0.093924681996371, 51.141805617825796],
            [0.093819103138566, 51.141526018455423],
            [0.092530552842834, 51.141674091721349],
            [0.090620840206007, 51.141174050400721],
            [0.090209615512604, 51.141470086595014],
            [0.088812987933032, 51.140997715427631],
            [0.088290291607737, 51.14129483674408],
            [0.087843859591143, 51.140937681577512],
            [0.087052606774027, 51.141493202806707],
            [0.08668087782924, 51.141365837217982],
            [0.086485154813206, 51.141685893353447],
            [0.085998740161923, 51.14167838395862],
            [0.085882610318189, 51.142083354576933],
            [0.085353730436422, 51.141768130500324],
            [0.084623291903997, 51.141865691808377],
            [0.084493230102022, 51.141612601351355],
            [0.084021479270734, 51.141898902516019],
            [0.083548483137415, 51.141490942899757],
            [0.083269679835758, 51.141712648482489],
            [0.081650635344694, 51.14106159107277],
            [0.080934619488265, 51.141130094110679],
            [0.080380238881208, 51.140628241453861],
            [0.078161020186988, 51.139949141662747],
            [0.070528244045872, 51.139687945480794],
            [0.068117820487009, 51.140675795024499],
            [0.066673352051288, 51.140534958797261],
            [0.065431262602918, 51.140732273931235],
            [0.065525817941761, 51.141057058916381],
            [0.064188786799802, 51.140889112961737],
            [0.064004788939068, 51.141154963996428],
            [0.06325707326378, 51.141122294312837],
            [0.062449323293035, 51.14176337405285],
            [0.058736642286969, 51.140833247112397],
            [0.058372787311811, 51.141363059778165],
            [0.05582014189512, 51.142471867517763],
            [0.053930655234276, 51.142483488008004],
            [0.052669778835671, 51.142162986169993],
            [0.051665522059601, 51.142406341194508],
            [0.051220182407783, 51.142228893441683],
            [0.049989478251868, 51.14265337284764],
            [0.04986382776475, 51.143915527819416],
            [0.050890997267922, 51.144704210108095],
            [0.051824560144611, 51.144928851545394],
            [0.053049432664402, 51.147465028618853],
            [0.054222600811555, 51.148318567915545],
            [0.054778365154974, 51.149531883317728],
            [0.054655560245872, 51.150436961563145],
            [0.052387297403036, 51.150749288200288],
            [0.051196319083781, 51.15168749372679],
            [0.050814179634666, 51.153518021721318],
            [0.051407167280648, 51.154378164975505],
            [0.051467279198723, 51.157248645620697],
            [0.048874042767037, 51.158820255759473],
            [0.047486919608655, 51.161245736279639],
            [0.04859732301053, 51.164421577213581],
            [0.046918064097009, 51.164872761010322],
            [0.046365017641285, 51.166208936239336],
            [0.047059915439609, 51.167721122808814],
            [0.046657386758912, 51.168451220604531],
            [0.047001827554271, 51.168929026632576],
            [0.046224035915008, 51.169196245221869],
            [0.046535006888272, 51.171470579010652],
            [0.046149125999515, 51.171834360189955],
            [0.046666940289656, 51.173480946691363],
            [0.047262233141942, 51.174065877542319],
            [0.048204006034399, 51.174333574057727],
            [0.048670485005202, 51.175594343480881],
            [0.050472303664117, 51.176284922996039],
            [0.050876966120624, 51.177182543216439],
            [0.051750939521026, 51.177758068145934],
            [0.051658245119182, 51.17869948246755],
            [0.052573704842033, 51.180626849227117],
            [0.052259932585909, 51.181229502587733],
            [0.049784608116828, 51.182907867786042],
            [0.049410377155982, 51.185019728565528],
            [0.048887702149462, 51.184882296191539],
            [0.047333761994814, 51.18556240945059],
            [0.047332382012431, 51.186338544546516],
            [0.046230653763078, 51.187343474147156],
            [0.046832703476691, 51.187786196730627],
            [0.046228595131862, 51.187846228404361],
            [0.045140813056398, 51.188617980127624],
            [0.047298688306398, 51.190002052926822],
            [0.047796677665601, 51.191744305972691],
            [0.04693974063175, 51.191687358949714],
            [0.045990415366958, 51.192161719004083],
            [0.044518482160993, 51.191855606698255],
            [0.044024427575032, 51.19262506214482],
            [0.042748348625099, 51.192993589375561],
            [0.042487839183595, 51.193607874388064],
            [0.042038738850428, 51.193711941697565],
            [0.042507431076812, 51.194050894990923],
            [0.041372954232645, 51.195843248659415],
            [0.040393044631514, 51.196665231442324],
            [0.04065109994274, 51.197614004214252],
            [0.040418358203601, 51.197886059790008],
            [0.039655592867317, 51.197824718257785],
            [0.039297279552266, 51.198462284049],
            [0.038670497646103, 51.198466014070547],
            [0.038360457589824, 51.198835639121299],
            [0.038282796799372, 51.199767782821787],
            [0.039131873122055, 51.200875330448241],
            [0.03576781075152, 51.204216414702024],
            [0.036670848406629, 51.205183646993973],
            [0.037263419244345, 51.207062787161838],
            [0.036171927681328, 51.207958619299241],
            [0.036352952555709, 51.20816770645942],
            [0.035363502662471, 51.208488892164397],
            [0.033745160179687, 51.210556676647876],
            [0.033961455702243, 51.210883863551963],
            [0.033561430144263, 51.211326084468411],
            [0.034568502661497, 51.211662904871929],
            [0.034025065116169, 51.211742500351477],
            [0.033997796384227, 51.212033452305711],
            [0.034694622317031, 51.213484516960698],
            [0.033572534419562, 51.214340385776303],
            [0.036253213078242, 51.216584309795856],
            [0.041122257723903, 51.216626271804891],
            [0.041423342822759, 51.218253270992747],
            [0.043140424044115, 51.220164015404855],
            [0.042924855460098, 51.22179643383776],
            [0.042287842318891, 51.222579164056327],
            [0.042807508261638, 51.224612429459832],
            [0.043659160940879, 51.225829614592328],
            [0.042275465798309, 51.227379005816339],
            [0.043012763768798, 51.229085617853166],
            [0.043837278921449, 51.229785272058066],
            [0.043783083746886, 51.23088967326742],
            [0.045109192362655, 51.231949274116502],
            [0.046032353891519, 51.233320772818644],
            [0.048026801065196, 51.233847052762734],
            [0.052833278700174, 51.236529112439413],
            [0.053903762596809, 51.238145276106778],
            [0.053530910689415, 51.239327219274173],
            [0.055366669258127, 51.240560320890225],
            [0.056212638284555, 51.243177742413707],
            [0.057338252648623, 51.244064464333903],
            [0.05758116364052, 51.244916337469476],
            [0.058216250980611, 51.247780265405815],
            [0.057961907821785, 51.248919667940598],
            [0.057023209607555, 51.251293262551634],
            [0.055104199755772, 51.252381866600977],
            [0.053817024842101, 51.254079875340224],
            [0.054239802509836, 51.256587822297675],
            [0.053225229875512, 51.258166825353406],
            [0.05191577284993, 51.26268453114173],
            [0.04965311161014, 51.265695487681349],
            [0.049583706758376, 51.268034001361478],
            [0.048005061814309, 51.27012284392363],
            [0.045369450209969, 51.276661914181112],
            [0.045333715800994, 51.277923363980761],
            [0.046300867616777, 51.279553087957204],
            [0.048143830146792, 51.285654113015276],
            [0.046712800967339, 51.288593784775529],
            [0.044760773151959, 51.290722373387474],
            [0.042597501424943, 51.292038046861805],
            [0.042368994859629, 51.292674243173188],
            [0.044739652081475, 51.293087908669399],
            [0.04570414525076, 51.294005434505863],
            [0.048468503392217, 51.293723278739456],
            [0.050762349912617, 51.293987983140269],
            [0.054362504155093, 51.292090283204935],
            [0.056022763995799, 51.29239657718],
            [0.058482828173708, 51.289355064999292],
            [0.067535944870675, 51.290384407987325],
            [0.073340901906363, 51.291606420089458],
            [0.081553070363804, 51.291836899125713],
            [0.085665366541323, 51.293085389857623],
            [0.089234027836609, 51.296087497623503],
            [0.091156843090318, 51.29682837091574],
            [0.086310854396904, 51.300173019920855],
            [0.085404444884815, 51.302520171446901],
            [0.082788071327502, 51.305634277835679],
            [0.08484975938291, 51.306045437761341],
            [0.084058200018264, 51.308049693670533],
            [0.084720177906431, 51.308346370433711],
            [0.083543471444392, 51.310528346862199],
            [0.085042087476937, 51.312598848664109],
            [0.085613319665181, 51.314784777523414],
            [0.08500075644682, 51.316023237844895],
            [0.086793117272677, 51.317192778815489],
            [0.087756667586107, 51.31831232348479],
            [0.093797129076916, 51.320235096550221],
            [0.096151698395388, 51.32129282004616],
            [0.099503635151417, 51.323508010359603],
            [0.100307996919827, 51.324275081934417],
            [0.100764019792728, 51.325585283134927],
            [0.103212844217316, 51.325995474209343],
            [0.104873867826206, 51.327412611212708],
            [0.10721006106662, 51.327523476462623],
            [0.109730877412081, 51.326683999644629],
            [0.110125943353177, 51.328035732767354],
            [0.114183278693762, 51.327397784855414],
            [0.115715775887933, 51.329506858762606],
            [0.116614278467648, 51.330003226391227],
            [0.117335250602446, 51.329481184883257],
            [0.117876189794242, 51.329662955068336],
            [0.117864350568799, 51.330620930893652],
            [0.120218191399938, 51.332136835136652],
            [0.121656932768726, 51.333636001433923],
            [0.119983658600939, 51.334739178007943],
            [0.118271921755193, 51.338404248840682],
            [0.118986891087981, 51.338683588604418],
            [0.118443701015532, 51.339981221542899],
            [0.117255655841466, 51.339780578023166],
            [0.116653808596291, 51.341302290162474],
            [0.117204771966067, 51.343162885373189],
            [0.117559296502436, 51.343128597186052],
            [0.118429038730673, 51.344147068652816],
            [0.122211878286937, 51.344656834562549],
            [0.12518574473221, 51.345867331500806],
            [0.131642288733902, 51.345193321411159],
            [0.131750143620975, 51.345506115528195],
            [0.136930961557784, 51.344174251349287],
            [0.137082175896194, 51.34480100719297],
            [0.136172879572529, 51.345936334445852],
            [0.137169312734812, 51.345871391274862],
            [0.138241048202658, 51.347084778909242],
            [0.139413829042557, 51.346982423299089],
            [0.139465950636091, 51.34729982592129],
            [0.138686589447705, 51.347524498532579],
            [0.141260255301355, 51.349778799159033],
            [0.140957112293769, 51.350145862554157],
            [0.142593049376822, 51.350734669068068],
            [0.142849433679087, 51.352658992140405],
            [0.143113225266923, 51.35304896124255],
            [0.143604380437612, 51.353017490231331],
            [0.144019375736582, 51.354861567026731],
            [0.145087028599637, 51.355763807500566],
            [0.145626939226504, 51.3572053990992],
            [0.145868471647119, 51.358224385169301],
            [0.14482113720903, 51.358435121066769],
            [0.145362479037724, 51.360337132080389],
            [0.144535785228854, 51.360690410366402],
            [0.144474098272935, 51.361061154593642],
            [0.145364561778273, 51.362502623058411],
            [0.145021694053068, 51.362850638819381],
            [0.148320969139466, 51.366371208877048],
            [0.14725344759924, 51.367014002089221],
            [0.148849791460367, 51.368123248673022],
            [0.149411360008319, 51.369162435009699],
            [0.150998352399738, 51.368322129402898],
            [0.151592923790006, 51.368378657456923],
            [0.152030993614946, 51.369693489016335],
            [0.150100173416957, 51.370157015724978],
            [0.15117949664148, 51.372186716620135],
            [0.150807253746483, 51.37292019077298],
            [0.149573842439353, 51.373582237031755],
            [0.151709745248199, 51.375436060076964],
            [0.152510494791998, 51.375229796771343],
            [0.153209781014079, 51.37803626656774],
            [0.152163333981809, 51.37833607963853],
            [0.152326518258417, 51.37869010549754],
            [0.150948243734437, 51.379085034958102],
            [0.15110697945487, 51.379895990767615],
            [0.149587003327425, 51.380089363701188],
            [0.149582131061656, 51.381089479279616],
            [0.150346812464746, 51.381092532056996],
            [0.150439939273109, 51.383446999067758],
            [0.149760583661605, 51.383670804037244],
            [0.149773682052291, 51.384870234740859],
            [0.149965765345046, 51.385840655471199],
            [0.150432574627488, 51.386018243458388],
            [0.148875523207952, 51.387572032577062],
            [0.149390397471064, 51.387854860893071],
            [0.148627923705321, 51.388668327732418],
            [0.149809249351476, 51.39087243075064],
            [0.147507723408661, 51.392156577655726],
            [0.147714720379626, 51.392801180144886],
            [0.158247952465449, 51.392239797745006],
            [0.161287113318842, 51.392369927161489],
            [0.162357953748243, 51.392490459036011],
            [0.15895418720739, 51.395821301733108],
            [0.157702284750458, 51.398147479285953],
            [0.157334670329728, 51.400783809671118],
            [0.156408725853617, 51.402494241301802],
            [0.155487656520912, 51.403575963081863],
            [0.15494091551275, 51.40365976273295],
            [0.155223030920241, 51.403972027267649],
            [0.152796265945422, 51.406273886924552],
            [0.151027499142613, 51.40666878974919],
            [0.148666001457494, 51.408131214494098],
            [0.148876641671875, 51.408483474589666],
            [0.148963755291263, 51.408623966395062],
            [0.149285683510007, 51.409141454529717],
            [0.15156900018033, 51.408677777829865],
            [0.15290633792244, 51.4087089701314],
            [0.156069800461578, 51.408738034749327],
            [0.159355477681798, 51.408369968268886],
            [0.159861275851485, 51.408270715668166],
            [0.159667236912494, 51.407845325333192],
            [0.161427860429098, 51.40715637028952],
            [0.163461707963554, 51.406804078708461],
            [0.163907291684975, 51.408414594799417],
            [0.165588672982541, 51.408240542145798],
            [0.16569316857619, 51.408595633890343],
            [0.166331594541523, 51.410754748176608],
            [0.164903436428454, 51.410994278127838],
            [0.178052011484193, 51.4155133182919],
            [0.179430807018662, 51.415625275059512],
            [0.182582739558306, 51.415794132466807],
            [0.199718682400269, 51.41797646715321],
            [0.200472006172122, 51.416951503189004],
            [0.20017652752673, 51.416638697274081],
            [0.200751852596827, 51.415741177426],
            [0.204122843057597, 51.413406167527228],
            [0.205278067038143, 51.413205469161106],
            [0.204964381638976, 51.4122455165856],
            [0.205817472071856, 51.409682621108608],
            [0.210990271441388, 51.407452864573557],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000112",
        LAD13CDO: "29UL",
        LAD13NM: "Shepway",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [1.08973138401626, 51.189723830151749],
            [1.091168117374844, 51.189013042924316],
            [1.092416472777851, 51.189315531962919],
            [1.094968021232184, 51.188645361783337],
            [1.097279480513012, 51.188920548919022],
            [1.108941643494409, 51.186958648804165],
            [1.112707844629945, 51.188642994255169],
            [1.113555773936797, 51.189521691630667],
            [1.115058778983369, 51.194231798449394],
            [1.114765560930406, 51.196950638006847],
            [1.118116134558574, 51.196324334812722],
            [1.11842838538678, 51.196136110623407],
            [1.117734338178212, 51.193618091258102],
            [1.117935263942779, 51.19258732670847],
            [1.11667101146695, 51.190908429862311],
            [1.116002937950174, 51.187796951005069],
            [1.120326594801801, 51.1865635717553],
            [1.123175000907007, 51.186312108806518],
            [1.126850356673424, 51.185549141887556],
            [1.131308515982848, 51.185694257365313],
            [1.134483413236349, 51.184940854806669],
            [1.138527937415152, 51.183317605710386],
            [1.139974950214138, 51.181593113037593],
            [1.143764716239826, 51.178504962322634],
            [1.147607959051928, 51.177174637751634],
            [1.144090492617877, 51.173515748156952],
            [1.142746337453742, 51.169112033452976],
            [1.143654289273958, 51.165865656473393],
            [1.152905621408559, 51.162879319920037],
            [1.15376169745429, 51.162364202715331],
            [1.156310252669503, 51.161265490241],
            [1.156377715246566, 51.160326400205136],
            [1.157026320233026, 51.159790775768528],
            [1.161918523941122, 51.158014255072345],
            [1.165790905024157, 51.158498592635944],
            [1.165807137206459, 51.160009298656774],
            [1.168931439045597, 51.162660881896272],
            [1.170704731311537, 51.163131794962929],
            [1.171033547679888, 51.161169177177683],
            [1.171674347880775, 51.160647174024959],
            [1.175352232804948, 51.162438941079643],
            [1.175706486488574, 51.162244020370458],
            [1.176538288281483, 51.162440892198724],
            [1.177602766305474, 51.159239442386713],
            [1.177059397875093, 51.156911932957371],
            [1.17951192491948, 51.156716608743153],
            [1.181234253159741, 51.156157926625056],
            [1.181874490473375, 51.157354815230441],
            [1.182961498340891, 51.157935073131235],
            [1.187824302781231, 51.154898960926879],
            [1.190627933486381, 51.154011105947404],
            [1.194147065772585, 51.15422710092998],
            [1.20116947724296, 51.155275364193727],
            [1.20303585306452, 51.155107291389108],
            [1.207646587203383, 51.156829302687093],
            [1.210194230264023, 51.153515752351389],
            [1.211033389312495, 51.151471520167789],
            [1.207958593825731, 51.150257986841979],
            [1.204491142359714, 51.148267179844481],
            [1.203062882890706, 51.147199951592945],
            [1.202238266452514, 51.145659220617617],
            [1.198988613853956, 51.14480285122324],
            [1.197800864691053, 51.14367410663354],
            [1.195323012319881, 51.142726353061001],
            [1.193992685668339, 51.141601469399333],
            [1.189125338245628, 51.13876156615472],
            [1.189180222176305, 51.136509523852339],
            [1.190086099238788, 51.135982876315595],
            [1.188225469491474, 51.134688907938873],
            [1.185890341451751, 51.134142738768695],
            [1.184474363339239, 51.134777703757614],
            [1.182137927325026, 51.133359882667833],
            [1.178895370041642, 51.134523040594999],
            [1.177926737777754, 51.133617509829193],
            [1.176777078499016, 51.131907346247658],
            [1.176907958617839, 51.13123186398176],
            [1.176047630642316, 51.13046549402398],
            [1.176510050826423, 51.130204662831872],
            [1.175832700865149, 51.128459162101343],
            [1.175503506131861, 51.128536470873641],
            [1.175731950450609, 51.129157211331581],
            [1.175116944419871, 51.129110960165931],
            [1.173421218779758, 51.127188929112499],
            [1.174123599862921, 51.126988152362024],
            [1.173932727599075, 51.126727086403143],
            [1.17146662915312, 51.124637057649551],
            [1.173020835034163, 51.122517923180105],
            [1.174839787475214, 51.122259828223392],
            [1.17797751585523, 51.123701872367477],
            [1.179019388525182, 51.122805514064183],
            [1.179119316423167, 51.120868874454899],
            [1.180835327325055, 51.119197675334163],
            [1.185769010384726, 51.121399252311434],
            [1.187719750224419, 51.119985102777513],
            [1.187728596217279, 51.118868582116001],
            [1.188953220883355, 51.119239038587835],
            [1.192400197619235, 51.119152100391737],
            [1.19733562306554, 51.118143724119697],
            [1.195614615410241, 51.11588358791866],
            [1.195538649222703, 51.114038089091814],
            [1.193972596175493, 51.111141341562963],
            [1.194584432211718, 51.110668565336802],
            [1.195513982493158, 51.107656795578805],
            [1.195068943098648, 51.105680167749931],
            [1.196748787257111, 51.10451523056792],
            [1.194894250265786, 51.103065593295447],
            [1.197150974541472, 51.101711266386729],
            [1.202624455488704, 51.099607613255202],
            [1.204234901222129, 51.098973375122235],
            [1.204339862253898, 51.097186777172155],
            [1.206132310169995, 51.097841907575756],
            [1.209165608444058, 51.098426048879602],
            [1.209231187474337, 51.099145651117105],
            [1.213246120451305, 51.099709911383506],
            [1.214064230815306, 51.10027928031203],
            [1.215152276203937, 51.100291615783597],
            [1.219498542273003, 51.101344869911394],
            [1.220435756841214, 51.101201200779307],
            [1.220823685308461, 51.099466148675283],
            [1.221104774370662, 51.098105537717892],
            [1.215625857537894, 51.097174380847342],
            [1.213270889561302, 51.096237133453393],
            [1.210307022544582, 51.094345107846564],
            [1.208870424172871, 51.093773815866022],
            [1.208369669973595, 51.093989060255474],
            [1.205780154680953, 51.092774761772397],
            [1.201725829326503, 51.089212379014874],
            [1.201199655182302, 51.088046649003992],
            [1.200958871055861, 51.084706186459222],
            [1.199260828964813, 51.083217251953528],
            [1.191615730890534, 51.082111327665295],
            [1.190180016748716, 51.081377875511144],
            [1.189625025398258, 51.079352057138443],
            [1.189942829332318, 51.08083655537753],
            [1.186608853344013, 51.08007483204549],
            [1.185687189462713, 51.079567467482448],
            [1.186122749172726, 51.078529252984843],
            [1.189957175718913, 51.079053345659645],
            [1.189624618228157, 51.077976725772899],
            [1.19166621108752, 51.076685933936773],
            [1.192378185034194, 51.076325563430274],
            [1.194919715292149, 51.075951136160825],
            [1.192075763626075, 51.07612244531169],
            [1.189627391200843, 51.077436047738793],
            [1.185702123711072, 51.076523138919164],
            [1.181734527160961, 51.076031320567523],
            [1.176933530345049, 51.075920041828724],
            [1.1727005668961, 51.074740719839696],
            [1.169018353025677, 51.07374059622606],
            [1.163009880734527, 51.073171275182212],
            [1.148428145541769, 51.073194663776256],
            [1.12453980788235, 51.071326569484881],
            [1.1064413036534, 51.069332133807855],
            [1.094622427211533, 51.067586950484781],
            [1.093544525332273, 51.067286292672577],
            [1.093613841513534, 51.06702000711325],
            [1.085072037035796, 51.065658348771592],
            [1.075564518558709, 51.063481383869906],
            [1.061407798843619, 51.05951420742894],
            [1.0511441761136, 51.055743217196138],
            [1.038931514768798, 51.050295966553094],
            [1.037382587054651, 51.048695609397527],
            [1.034597300416798, 51.047105778049264],
            [1.016645838338673, 51.036691680475755],
            [1.006948681900375, 51.031750902310151],
            [0.997312996513924, 51.025078062643672],
            [0.992367639058279, 51.021018742360859],
            [0.98448499556163, 51.013376169068067],
            [0.978271314093561, 51.004972734452551],
            [0.976970953261445, 51.002610596880444],
            [0.973947467428102, 50.996329264298787],
            [0.964104868789755, 50.968080603911737],
            [0.964004897385908, 50.961500767220848],
            [0.966250287550835, 50.951766231145584],
            [0.971751972917265, 50.937854312851343],
            [0.974312878669825, 50.932411960594905],
            [0.977867965274308, 50.926277656926111],
            [0.980994835008109, 50.918156416276233],
            [0.980823054890631, 50.914534978216878],
            [0.980253817076924, 50.913843423888324],
            [0.978378552984355, 50.912841611253683],
            [0.973666980788533, 50.91133642510011],
            [0.971083387213906, 50.910981288640208],
            [0.954999148499504, 50.911069563464984],
            [0.937191727990524, 50.912306269836918],
            [0.89864667515261, 50.917102001675055],
            [0.854701573596926, 50.923726131834371],
            [0.865060476553159, 50.92926120662883],
            [0.865978049548309, 50.931151836955948],
            [0.867855862819715, 50.933263520196306],
            [0.864080482215517, 50.935130884471654],
            [0.859594034860011, 50.939681558588198],
            [0.860937608521132, 50.941832525145664],
            [0.85593278671933, 50.953130019308091],
            [0.852495072771064, 50.951569874385946],
            [0.851113335930974, 50.951305914998272],
            [0.849030279379429, 50.951262339161318],
            [0.848002528288822, 50.951733554816947],
            [0.84607764416196, 50.951855164667101],
            [0.84568257255329, 50.952287543822365],
            [0.846266961517816, 50.952529639850709],
            [0.846882997586055, 50.953568781583456],
            [0.84638534004694, 50.955002064887978],
            [0.844942345458899, 50.955857553194186],
            [0.844240503477459, 50.957353368276188],
            [0.841366626735528, 50.957610452095018],
            [0.839727710600289, 50.956213903773879],
            [0.839967051015833, 50.9557484573495],
            [0.840996559648045, 50.955279969795654],
            [0.841066876582015, 50.954705303258123],
            [0.838834776673703, 50.954298193079019],
            [0.838703060188886, 50.952394545519425],
            [0.836927572385434, 50.950458007995564],
            [0.835700817261474, 50.950118143003337],
            [0.835617203822623, 50.949656953610557],
            [0.834332115353213, 50.949902239819309],
            [0.833283940020478, 50.949501346892248],
            [0.829834394376104, 50.945667000291564],
            [0.82786506767003, 50.945873937751337],
            [0.82639325721188, 50.945270076483993],
            [0.824777001538571, 50.945453120016076],
            [0.822869859704131, 50.944759910801167],
            [0.821261072118706, 50.945724352477932],
            [0.819033778581088, 50.945457066603396],
            [0.818439753448849, 50.945122424491252],
            [0.819351001230364, 50.94429989911152],
            [0.818515306130434, 50.943100421840697],
            [0.816696483910958, 50.942287151267436],
            [0.814631865061133, 50.942399924019362],
            [0.812907956696648, 50.942001653224217],
            [0.81342964548611, 50.949012922127615],
            [0.805515204298827, 50.954983319830703],
            [0.80194445619522, 50.958285533359678],
            [0.790360100010505, 50.969994142097825],
            [0.789461534484333, 50.970973530132767],
            [0.789508260373918, 50.971420338746334],
            [0.785423071183266, 50.975235303255474],
            [0.784294544720874, 50.977232013339169],
            [0.783815650264038, 50.977086031063521],
            [0.78293525675958, 50.977420928726382],
            [0.780715088852902, 50.976793852409827],
            [0.778629681028838, 50.976585366859055],
            [0.777101874210805, 50.975430855651297],
            [0.777051967664998, 50.977339766926804],
            [0.778417385109632, 50.978350634878694],
            [0.777751708931813, 50.979443112081896],
            [0.778801586506788, 50.979845363233721],
            [0.781669289722419, 50.982888251105059],
            [0.779905797137454, 50.982324929689383],
            [0.778979621472664, 50.982396449489428],
            [0.776993507205447, 50.98485781946551],
            [0.781020395235627, 50.986501491297403],
            [0.779505266438682, 50.987497280379799],
            [0.778923473796187, 50.989494404936266],
            [0.785736003100574, 50.997996405324223],
            [0.83083832002264, 51.049372815556104],
            [0.832062968059983, 51.048419396903483],
            [0.833909836485489, 51.047912285305863],
            [0.835461741685883, 51.04818313084597],
            [0.836583943088425, 51.047143105314092],
            [0.836072163789935, 51.047034108128258],
            [0.836153177479484, 51.046678658364328],
            [0.837038966412992, 51.046416993307197],
            [0.83761734385389, 51.045841687910482],
            [0.837176984281863, 51.044846803977805],
            [0.837848741974327, 51.044072249748361],
            [0.834246077835534, 51.044656225250961],
            [0.832769536929374, 51.04458950983225],
            [0.831135090838063, 51.043594777195629],
            [0.831059451925398, 51.041723063842269],
            [0.831285697413763, 51.041292141219401],
            [0.833516297482484, 51.041466505265866],
            [0.833573998545038, 51.040027789015532],
            [0.840083110797215, 51.038942353144279],
            [0.839190166982554, 51.036168578845142],
            [0.839624356248536, 51.034521026056353],
            [0.837484164793474, 51.032646425904296],
            [0.839059889925414, 51.031355197914294],
            [0.84031539409906, 51.031942590841126],
            [0.845316436814659, 51.032617765120627],
            [0.846186125700358, 51.03307597970722],
            [0.849453331116713, 51.033341696899321],
            [0.85258386546423, 51.032055510427504],
            [0.854775511993315, 51.031764500683813],
            [0.854631236609918, 51.03259642223356],
            [0.856719817367822, 51.032554345152228],
            [0.855834645708223, 51.033933266993216],
            [0.856817720024955, 51.035258400167059],
            [0.855889470880595, 51.036053732005506],
            [0.856032919675057, 51.039382690153822],
            [0.855492319511458, 51.040265677887803],
            [0.855411009578649, 51.042214076824159],
            [0.853335652665877, 51.042292681116358],
            [0.85340689785653, 51.043149915291082],
            [0.854028679316728, 51.043562860896174],
            [0.855251048522461, 51.043695784412193],
            [0.855704771757552, 51.045017676547793],
            [0.857966945690575, 51.044472142259728],
            [0.860408538428552, 51.044315229651261],
            [0.861436439225343, 51.043792654086751],
            [0.866531365137903, 51.043951943311725],
            [0.866983056670942, 51.043179919687191],
            [0.869650360188641, 51.044113704566186],
            [0.869307298493726, 51.045142112307119],
            [0.871055506291621, 51.04558575419982],
            [0.870786965731526, 51.046388370700214],
            [0.871388680943519, 51.047073353153372],
            [0.870873750124116, 51.047214633209684],
            [0.870307432285652, 51.04823234084845],
            [0.867732052363005, 51.04772804923784],
            [0.868847852379673, 51.049204530820603],
            [0.870438733681575, 51.049478452986641],
            [0.870583567307028, 51.051035442611536],
            [0.869609407001675, 51.050858802950209],
            [0.868969588138242, 51.05161207114557],
            [0.870401319980013, 51.052310846971942],
            [0.871085794044299, 51.052141116916168],
            [0.871655157067302, 51.052647902487443],
            [0.87218014839459, 51.052414628077884],
            [0.87265800642515, 51.052803132419562],
            [0.875243102589864, 51.050994586168805],
            [0.876027478195407, 51.050917709428113],
            [0.877204909267589, 51.051633457547688],
            [0.87807364270327, 51.051236982086287],
            [0.881415549787793, 51.048338394886898],
            [0.882358436882106, 51.046334531160433],
            [0.881945679171881, 51.045248282029888],
            [0.882640604819044, 51.044950502317313],
            [0.883160264069571, 51.04369193022157],
            [0.882782899415641, 51.042945703375864],
            [0.882096415707816, 51.042712597663062],
            [0.883938010397603, 51.041834241680299],
            [0.885548233046292, 51.040218622465325],
            [0.887906613077683, 51.039990331505848],
            [0.890171926498088, 51.041108983104223],
            [0.891225976863777, 51.039592494157624],
            [0.892055457620989, 51.040036975371109],
            [0.893121937800471, 51.039453796921649],
            [0.893848667414265, 51.037403018313348],
            [0.894447582975434, 51.036987018028881],
            [0.896353340967321, 51.037876104608806],
            [0.896817583762472, 51.037702687728192],
            [0.897980450438132, 51.038253987695974],
            [0.900088551616628, 51.038657682245393],
            [0.899656507976628, 51.043023585321286],
            [0.897940020370069, 51.044919071699887],
            [0.90111500000532, 51.049077591585238],
            [0.903290735070416, 51.050462659141729],
            [0.906667703815731, 51.051225055887556],
            [0.909740598811827, 51.049958557677726],
            [0.913109016807266, 51.0503108283038],
            [0.913646355992252, 51.05025515011517],
            [0.913934190614305, 51.049771255843098],
            [0.914404838715962, 51.049812580659136],
            [0.915836596424462, 51.050433450216929],
            [0.914956433631017, 51.053391255305549],
            [0.915349612172798, 51.053425515435315],
            [0.916256999169377, 51.053444237981836],
            [0.91671811245208, 51.052152793080651],
            [0.919434972220809, 51.052602103475039],
            [0.919639505920097, 51.051883719069593],
            [0.922759660404598, 51.05207461890933],
            [0.921301666283811, 51.056116390736989],
            [0.926476159795353, 51.056493251485634],
            [0.927324066462271, 51.057852675694171],
            [0.930008220181728, 51.058545417437713],
            [0.930130667790194, 51.058879643568361],
            [0.93155023556123, 51.058960952916578],
            [0.932478044176982, 51.060474841722765],
            [0.934240462350956, 51.061146551855231],
            [0.935095022699528, 51.058681243770408],
            [0.935807639546883, 51.058033710813454],
            [0.936531246692897, 51.058349220893277],
            [0.939786665436176, 51.057062948825632],
            [0.943757028705923, 51.056003220973231],
            [0.944094113054085, 51.054905474129264],
            [0.946567485847786, 51.055266749154583],
            [0.949302711810109, 51.057029921495719],
            [0.951778158852668, 51.056950297159659],
            [0.953473383472619, 51.057281619686705],
            [0.95435605058774, 51.057807070629508],
            [0.954357655956779, 51.058639035243758],
            [0.95561091241148, 51.059379968851708],
            [0.958200415322784, 51.060258851722914],
            [0.960660614785627, 51.060161437151102],
            [0.963556284980117, 51.060736516713177],
            [0.966159404760749, 51.062338050460021],
            [0.968894756647717, 51.062925165475555],
            [0.968135081564834, 51.067024453459084],
            [0.968328731290239, 51.068733915426769],
            [0.96664281830707, 51.068801931069174],
            [0.966542834885633, 51.070229224362876],
            [0.966618171758369, 51.070737308146697],
            [0.967710544449915, 51.071588338059264],
            [0.96741430989981, 51.077064614170084],
            [0.968001366877664, 51.077313243148517],
            [0.969697227133312, 51.07694993307053],
            [0.969978523710337, 51.077863834272428],
            [0.971114031989847, 51.078270300300794],
            [0.972752014872952, 51.079698355170954],
            [0.975507045310905, 51.079017474868373],
            [0.977598909985252, 51.081173262515762],
            [0.976554527353185, 51.083166111501363],
            [0.976909320406201, 51.084598914920726],
            [0.980200147682383, 51.086850023983644],
            [0.97990538937393, 51.087485370449748],
            [0.980226645197905, 51.089593619172177],
            [0.983433367953395, 51.091084937695769],
            [0.982356159396327, 51.092722486123101],
            [0.983681481571124, 51.093540440408873],
            [0.985139989363274, 51.097599351187355],
            [0.985382426611725, 51.100119755949748],
            [0.983882612408155, 51.100277699002554],
            [0.983135370157637, 51.101318573256357],
            [0.982072193920529, 51.101557988069573],
            [0.982117756617228, 51.101932801470191],
            [0.980270009699415, 51.103611577324266],
            [0.979768549538203, 51.10458859308941],
            [0.978473338469899, 51.104376064451415],
            [0.977940873939425, 51.105160477501116],
            [0.978625612259247, 51.10618820019797],
            [0.980415013723146, 51.107462982055381],
            [0.983359160899887, 51.108318790280897],
            [0.981658705180422, 51.110046892386741],
            [0.982176520004895, 51.110876475101556],
            [0.979734538414736, 51.112186331393254],
            [0.98098812407064, 51.113457678819287],
            [0.981821835220782, 51.113888836287963],
            [0.983684641044991, 51.113857474548553],
            [0.98452377211121, 51.11412837000217],
            [0.983830635034717, 51.114570619139634],
            [0.986100276805477, 51.115351838971733],
            [0.988820597556684, 51.117578620642945],
            [0.989705932223874, 51.117125128328532],
            [0.991105154381739, 51.117304291354195],
            [0.991728434769326, 51.117683199965661],
            [0.993346045381551, 51.117460080560576],
            [0.994095051857316, 51.117970675667877],
            [0.995388237685577, 51.118166896123419],
            [0.996233474365534, 51.118993421676066],
            [0.99604026411227, 51.120777518104546],
            [0.995349346415529, 51.121938451000901],
            [0.995557321673848, 51.122369360173522],
            [1.000476904959434, 51.121246533455569],
            [0.998944031363822, 51.118896902867341],
            [1.000218646817579, 51.118453126896156],
            [1.003009170944284, 51.118224037361706],
            [1.00400009902759, 51.118981102478259],
            [1.006409733482724, 51.117772309172018],
            [1.007928371649653, 51.116554923265781],
            [1.009245687374327, 51.116264656483956],
            [1.011214648524948, 51.117226732882777],
            [1.012086014431007, 51.117198877266588],
            [1.014847820444665, 51.118790768536513],
            [1.016841319055665, 51.119402225184515],
            [1.017040546714355, 51.120200307084247],
            [1.016520891692011, 51.121261604485177],
            [1.015488416860728, 51.121845024476798],
            [1.016345099578167, 51.122994021630092],
            [1.016755096330268, 51.122859310850174],
            [1.017549653496245, 51.125422068895091],
            [1.02053351658445, 51.124076726432769],
            [1.022098945688793, 51.123841066185868],
            [1.023843461642441, 51.125077679785996],
            [1.022899038602827, 51.125608516288217],
            [1.024419096802851, 51.128570713514236],
            [1.026878204027633, 51.129517146673898],
            [1.029210110169268, 51.132536506244712],
            [1.025057625083178, 51.133920418386857],
            [1.023916771624855, 51.134138833218145],
            [1.022417393829766, 51.133946458217309],
            [1.020537022596696, 51.134662480791022],
            [1.016460955661267, 51.140228427051959],
            [1.017982862796198, 51.141289183163934],
            [1.016530455113843, 51.14225852796578],
            [1.013057587901739, 51.142789752508641],
            [1.011997703363271, 51.143706644168994],
            [1.010194039730738, 51.144159672398544],
            [1.008591517872448, 51.145001460203446],
            [1.008431837008611, 51.145536254797427],
            [1.009731743001812, 51.147953827835863],
            [1.012389442809277, 51.149895661275046],
            [1.013251795323862, 51.151080513574655],
            [1.011898180924826, 51.151377147781893],
            [1.011851140584326, 51.152499092695045],
            [1.011080341938465, 51.153290693127502],
            [1.009466504798838, 51.154095003293413],
            [1.016892568385666, 51.161199117245566],
            [1.015747651681721, 51.161497602202019],
            [1.013536389210189, 51.161347756433109],
            [1.011719001178899, 51.162588185339075],
            [1.009541305970896, 51.163437601310001],
            [1.008182099568228, 51.163302590445248],
            [1.00300978121846, 51.164432190627416],
            [0.998406149506794, 51.164739257496848],
            [0.998573841601458, 51.166595939788635],
            [1.000679224176534, 51.168218507602354],
            [1.00125116950123, 51.169490952562832],
            [0.999459220792665, 51.172036546355777],
            [1.00050141359071, 51.17358745325177],
            [1.001663258273392, 51.17559130515297],
            [1.002759150937401, 51.178846189764855],
            [1.003939374151078, 51.180050827366941],
            [1.007655659908655, 51.181630970581516],
            [1.014153529790337, 51.181049865226925],
            [1.017584296074941, 51.179458357795234],
            [1.021258825591737, 51.179343662081386],
            [1.024040003095868, 51.180250367763506],
            [1.028486941821982, 51.181002333486589],
            [1.039807376167164, 51.182185441712939],
            [1.041177675180712, 51.183043871689762],
            [1.04201924055064, 51.184435037472646],
            [1.042400322461569, 51.185526966735459],
            [1.041819418062317, 51.186507209838773],
            [1.041986632120666, 51.187111797391452],
            [1.049547865622787, 51.184985448952133],
            [1.050858756646839, 51.186456955041166],
            [1.054732417788811, 51.188587382082282],
            [1.055064930282605, 51.191730428751541],
            [1.058227296963547, 51.205001243678026],
            [1.059378702766675, 51.204831704567773],
            [1.060701258485438, 51.203788788483145],
            [1.063881178792019, 51.202148524092827],
            [1.066512927507081, 51.201268208893531],
            [1.070111263104959, 51.200736635348214],
            [1.069937990988414, 51.19935870053159],
            [1.074580453815706, 51.196730784212598],
            [1.077033624909949, 51.194940160319547],
            [1.078257089455319, 51.192979486181962],
            [1.080328116600246, 51.19164498868939],
            [1.085215325041263, 51.190257754897843],
            [1.08973138401626, 51.189723830151749],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000113",
        LAD13CDO: "29UM",
        LAD13NM: "Swale",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [0.823030832436352, 51.358593805555202],
              [0.834292823660804, 51.356373596844413],
              [0.830808216741895, 51.356169190794191],
              [0.827611975837878, 51.356471279709027],
              [0.824258959827946, 51.357331098341518],
              [0.823781186569867, 51.357807616396968],
              [0.823273840914676, 51.357733508320173],
              [0.823323901306339, 51.357986833665549],
              [0.82307997915479, 51.357781354483585],
              [0.822479305347045, 51.357964925140834],
              [0.82201987304333, 51.358342958144462],
              [0.822624660786372, 51.358506462205995],
              [0.822197208089582, 51.3586336894978],
              [0.823030832436352, 51.358593805555202],
            ],
          ],
          [
            [
              [0.855537819678724, 51.36462664008544],
              [0.856951140019737, 51.363234948319544],
              [0.85526032207568, 51.363630562143427],
              [0.85338243990584, 51.364414759328611],
              [0.85266999029748, 51.365087791204203],
              [0.851718410026458, 51.365265670363982],
              [0.851410899366889, 51.36651344873772],
              [0.851568065674936, 51.366846001259454],
              [0.852200167200946, 51.366867481198966],
              [0.855537819678724, 51.36462664008544],
            ],
          ],
          [
            [
              [0.674948137124702, 51.391960490933933],
              [0.672577873744296, 51.389770649514396],
              [0.671548362314696, 51.391788959708073],
              [0.66901026057063, 51.392688643344577],
              [0.669706566115712, 51.392672774063165],
              [0.669617093455677, 51.393703702294594],
              [0.670504035619336, 51.394368809463444],
              [0.672319840486153, 51.394764492714188],
              [0.67483909315192, 51.396078540132649],
              [0.67832805686382, 51.396934165406932],
              [0.681204928272744, 51.396712753010284],
              [0.682030182808059, 51.396319716918143],
              [0.68263254796297, 51.395557637895813],
              [0.680500170120668, 51.394888752567482],
              [0.676477606786139, 51.392549719395696],
              [0.674948137124702, 51.391960490933933],
            ],
          ],
          [
            [
              [0.68293976341249, 51.412643303117036],
              [0.685547231618899, 51.411931505290788],
              [0.689235931842287, 51.412343338899525],
              [0.690021046688296, 51.41199793825507],
              [0.690509397341666, 51.412158504326122],
              [0.691169720289826, 51.411672061274224],
              [0.690442453250192, 51.410920696866874],
              [0.690997863591222, 51.410215416690093],
              [0.691008258331821, 51.409290615410406],
              [0.690351362986812, 51.405328645735821],
              [0.691486177837439, 51.402492910847641],
              [0.695262812694224, 51.398250926587863],
              [0.695413330681586, 51.397656568925356],
              [0.694507297396076, 51.396625138695917],
              [0.691291799569452, 51.396465227851117],
              [0.689977462380354, 51.397146572724637],
              [0.686599632232238, 51.397455240599839],
              [0.686853973018248, 51.397649068950358],
              [0.686290433893117, 51.3976745861574],
              [0.685824957469359, 51.398833769484774],
              [0.684874848813612, 51.398003838895022],
              [0.683685091603639, 51.398089564623987],
              [0.68047469780425, 51.399296295848444],
              [0.680325749105799, 51.399945458733235],
              [0.676555678659291, 51.399549601982322],
              [0.672846229113578, 51.398688161809361],
              [0.672609676758026, 51.398403959772658],
              [0.672404370843149, 51.398680256355945],
              [0.672973682414558, 51.398827355092756],
              [0.672665459431166, 51.399656420092633],
              [0.671169021260797, 51.401265366191488],
              [0.671380532961742, 51.401489883865828],
              [0.67195344795468, 51.400983057956125],
              [0.673742381852347, 51.401406315347032],
              [0.674006073790621, 51.40225380562245],
              [0.672869098177305, 51.402581047292621],
              [0.672935105447578, 51.40287453696434],
              [0.671253651696688, 51.403728629948027],
              [0.671778335082445, 51.404416379055249],
              [0.671570982356045, 51.404978722348325],
              [0.670989907584002, 51.40470507235981],
              [0.67006284833194, 51.405181292660814],
              [0.669762497884902, 51.40664063220504],
              [0.670404396950326, 51.406674566897749],
              [0.670173033971546, 51.406159101865128],
              [0.671399313816225, 51.406819165604816],
              [0.671349036665011, 51.407066741485757],
              [0.668527183896686, 51.407034826690257],
              [0.668295098495828, 51.406407851894706],
              [0.668864256996794, 51.406032436804558],
              [0.668361241739925, 51.405889203650787],
              [0.666530029885069, 51.405853557472909],
              [0.665899155524234, 51.406280730729428],
              [0.665524493800614, 51.407305551857078],
              [0.665772367544271, 51.407858423669417],
              [0.667489911137147, 51.409200766289821],
              [0.669961739735134, 51.409344114040024],
              [0.672073710936268, 51.410171950214654],
              [0.673344165772022, 51.408086987425165],
              [0.674423877963689, 51.408328557001411],
              [0.674108125724724, 51.40772328961237],
              [0.675524929947895, 51.407408532891431],
              [0.675577912410891, 51.406690518727643],
              [0.676264334805954, 51.406723404336525],
              [0.676741873529126, 51.406306873518311],
              [0.677879713299266, 51.406386993458575],
              [0.679256566812448, 51.407062420398631],
              [0.679419504001036, 51.407610911720212],
              [0.67673176248174, 51.407979947343151],
              [0.675041419602913, 51.408708383496922],
              [0.675474747372479, 51.408987187227723],
              [0.679067679034734, 51.409291802012653],
              [0.682464845226213, 51.408978420054666],
              [0.682928129228318, 51.410038070530518],
              [0.682509973273346, 51.410215830792239],
              [0.682173607475854, 51.411913464785158],
              [0.682362232813297, 51.412629547614728],
              [0.68293976341249, 51.412643303117036],
            ],
          ],
          [
            [
              [0.672488477616088, 51.423641435938471],
              [0.67409611790427, 51.423186542195559],
              [0.677876110523258, 51.423645222414024],
              [0.67962697164761, 51.423426212542019],
              [0.680925686892628, 51.422782225822253],
              [0.68343848195046, 51.423046672858391],
              [0.686940536353838, 51.422272983991917],
              [0.687735445972838, 51.421478586497301],
              [0.687186500224281, 51.418766963524135],
              [0.688029632802405, 51.418109061833093],
              [0.687774117770967, 51.417603178235723],
              [0.688352778445575, 51.41523352830292],
              [0.687798624700106, 51.4143441673815],
              [0.686872610140056, 51.414274573296908],
              [0.685902914139923, 51.414759990573401],
              [0.685822731282015, 51.415232203872669],
              [0.684886869363233, 51.415927291158589],
              [0.684418278082913, 51.417233134194916],
              [0.682669949290202, 51.418767949354219],
              [0.680489546398303, 51.419238768049759],
              [0.67628138167351, 51.419274721028707],
              [0.675515617379136, 51.418182382128101],
              [0.676181176141353, 51.414728862950867],
              [0.672282154269849, 51.413474202850871],
              [0.670704240100585, 51.413767408931818],
              [0.670368774049187, 51.414575501110242],
              [0.67072601104663, 51.415250882934934],
              [0.670150628523279, 51.415693900839152],
              [0.670527794931415, 51.416168268171717],
              [0.669869004415933, 51.416587103996768],
              [0.670022841887154, 51.417400230838552],
              [0.67086512891588, 51.417562704416831],
              [0.669096770929596, 51.418070682999208],
              [0.670109523295964, 51.41812225243433],
              [0.669339980681524, 51.418492344766392],
              [0.668709027513332, 51.419957411816384],
              [0.667945312854254, 51.419983800589968],
              [0.668059214440891, 51.420482158371996],
              [0.663397379306137, 51.419051205897858],
              [0.662814127674168, 51.41908874770396],
              [0.662734948141573, 51.419879298476083],
              [0.661662369018942, 51.420011597143436],
              [0.661207709794291, 51.419613610365481],
              [0.661756474285419, 51.418735046198144],
              [0.661761658057971, 51.418428241877599],
              [0.661123212953817, 51.418208909029865],
              [0.661422469241337, 51.417739832605854],
              [0.660084470576273, 51.417585850287047],
              [0.65955779915532, 51.418572729254635],
              [0.66066680740337, 51.42057833298955],
              [0.661410997798808, 51.421181097586256],
              [0.662589228925489, 51.421232570691274],
              [0.663234513102232, 51.421717055683253],
              [0.662981874782113, 51.422270517283977],
              [0.664249085441393, 51.422936022454778],
              [0.664709117473774, 51.423697222578141],
              [0.666972655336551, 51.423906534465317],
              [0.672488477616088, 51.423641435938471],
            ],
          ],
          [
            [
              [0.725488962003961, 51.421044330013345],
              [0.723251542604611, 51.420478486307161],
              [0.722049819918634, 51.419621422213204],
              [0.720365234919814, 51.419110130773959],
              [0.715988748667348, 51.420188408989823],
              [0.715074024659657, 51.420215008950684],
              [0.714673810586493, 51.419772793799979],
              [0.717026564557843, 51.41937558451091],
              [0.71906235848256, 51.418631327831029],
              [0.720720626979766, 51.418506485364382],
              [0.725535181754041, 51.420419082033511],
              [0.728690561485032, 51.420239499330854],
              [0.731142829855528, 51.418979875030324],
              [0.73121165224535, 51.41786663007025],
              [0.731711009865713, 51.417653524153934],
              [0.732314562046326, 51.416390201365836],
              [0.732238067166397, 51.415011428137632],
              [0.730920566108741, 51.414818203323762],
              [0.730619744093787, 51.415248826684142],
              [0.727352171688876, 51.41667852377747],
              [0.723693511271245, 51.417364436663576],
              [0.720193365184873, 51.416943009637151],
              [0.71813941614101, 51.415343914799969],
              [0.717770626013564, 51.414606882452844],
              [0.717348565872518, 51.414644554626108],
              [0.71776666235562, 51.414346153143292],
              [0.717290277827164, 51.414243882244087],
              [0.717133210160056, 51.414481365300809],
              [0.71682931770093, 51.414086389292514],
              [0.716626811294405, 51.413557751314926],
              [0.717066084810798, 51.413980166833269],
              [0.717034490906907, 51.413423281772282],
              [0.716494291575891, 51.413433112035136],
              [0.716284835876799, 51.413078215668641],
              [0.716936930571929, 51.412796875381844],
              [0.716514219456636, 51.413162834822167],
              [0.716794936699995, 51.413239967301728],
              [0.717430587567616, 51.412899646304346],
              [0.716908721901748, 51.412612256590968],
              [0.717246649026061, 51.412417346521039],
              [0.717557109577527, 51.412608008960085],
              [0.717422598191623, 51.412231589435599],
              [0.717996728927361, 51.411800055739278],
              [0.717856915195714, 51.41210998970498],
              [0.718317230786819, 51.411893349698559],
              [0.718492306579224, 51.411571804199475],
              [0.718119058389592, 51.411656013959529],
              [0.717833330954024, 51.410888273970983],
              [0.718472846346316, 51.409813959598026],
              [0.718076039938804, 51.410809792310914],
              [0.718536799427397, 51.410867453184061],
              [0.717996699539374, 51.411023887950876],
              [0.718602472665384, 51.411150134451319],
              [0.719624267129376, 51.4101200046196],
              [0.719544177785724, 51.409861042538736],
              [0.718983670177173, 51.410036842880331],
              [0.719754906139264, 51.409752720718231],
              [0.719560704380842, 51.409364198565562],
              [0.718759560215675, 51.409483526943042],
              [0.719626487848639, 51.409261494089179],
              [0.719939359241154, 51.408935394122302],
              [0.71949677758106, 51.409038306732803],
              [0.721104947945798, 51.407882124869133],
              [0.720126814812161, 51.408849540770554],
              [0.719713453272858, 51.409239220798881],
              [0.719659684874997, 51.409311534967458],
              [0.719858654377706, 51.409562340414205],
              [0.720400707637343, 51.409463412080832],
              [0.720410158592197, 51.409017097754919],
              [0.721282867932996, 51.408504862948199],
              [0.721457375269443, 51.407708013366275],
              [0.722420799396967, 51.406865823465658],
              [0.721722040897823, 51.406698587188139],
              [0.721750930411669, 51.406168177965213],
              [0.722537792704199, 51.405180354023841],
              [0.722314926860006, 51.40590684108534],
              [0.723174421470198, 51.405899453747018],
              [0.723873701209783, 51.404430686415701],
              [0.723578807264637, 51.404743334890355],
              [0.723310212963682, 51.404458178968127],
              [0.722551909936036, 51.404764509739827],
              [0.723055119504342, 51.405126057466092],
              [0.722487935077802, 51.405067290890123],
              [0.722397603545085, 51.404684453367032],
              [0.724272781321037, 51.403451867119706],
              [0.725899342853129, 51.399878536186172],
              [0.726749514916825, 51.398918887771472],
              [0.727521216667303, 51.398839761446588],
              [0.727488578534984, 51.398532931065731],
              [0.730490214818082, 51.396956519769354],
              [0.734967026402026, 51.39574671892251],
              [0.735619631864052, 51.395287179875382],
              [0.735869591998219, 51.394272226822636],
              [0.737811027456565, 51.395461721974101],
              [0.737052522130557, 51.395303169711596],
              [0.736571967691245, 51.396115754484896],
              [0.738034584898056, 51.395786571857272],
              [0.738809081765098, 51.395996009089302],
              [0.74276341036379, 51.394372712221745],
              [0.74531824953586, 51.392886421071779],
              [0.748275834113462, 51.390399492864965],
              [0.749299686754896, 51.390045385860319],
              [0.749491390405501, 51.389334862839419],
              [0.751926706640773, 51.387541837062116],
              [0.754866390668322, 51.386275631823146],
              [0.758897134640497, 51.385252571991103],
              [0.759062003583069, 51.384289933812219],
              [0.758480016728671, 51.383264843413293],
              [0.758957948037645, 51.383181633406743],
              [0.760438592888039, 51.385119113866857],
              [0.762795177112692, 51.384391695157497],
              [0.763626383908031, 51.38289617574987],
              [0.763627727183715, 51.381034395989495],
              [0.763182685837014, 51.379778549107435],
              [0.762771607359733, 51.379754070963138],
              [0.763080496519402, 51.379155880464992],
              [0.76250102390027, 51.378721652640465],
              [0.762903432050225, 51.378196805575023],
              [0.762569739669341, 51.378001414660169],
              [0.762450074975104, 51.378543874823599],
              [0.761557594250328, 51.377851704085167],
              [0.762517613441594, 51.377207578687802],
              [0.762397977635996, 51.376557439667337],
              [0.761203043820135, 51.37668905179553],
              [0.760432091507408, 51.377131742215177],
              [0.760614540500034, 51.376844131784217],
              [0.760007340288464, 51.376855746210197],
              [0.76212359491677, 51.376034167846896],
              [0.76110861529823, 51.373783532402129],
              [0.760724999010226, 51.37368914651384],
              [0.761203288619822, 51.373589728771563],
              [0.761022475647231, 51.372065918607191],
              [0.759981167617564, 51.371739702068496],
              [0.759475787922129, 51.370695721941239],
              [0.759456305727545, 51.370228495195754],
              [0.761497065031596, 51.369661434361404],
              [0.762057843816994, 51.369047411356483],
              [0.761798827195282, 51.368014717783794],
              [0.762809393565226, 51.367879353120045],
              [0.763501841740255, 51.36713809916548],
              [0.763963464568043, 51.365141334795801],
              [0.763656421026977, 51.364933626202983],
              [0.764429743102373, 51.364391022744506],
              [0.764763086849806, 51.363079925276246],
              [0.763612899663996, 51.362278732946727],
              [0.761086653747351, 51.362459749919246],
              [0.759731873442641, 51.361934062843858],
              [0.75892202784357, 51.362251747298139],
              [0.759322689569354, 51.361723354090394],
              [0.758855616273027, 51.361481629646306],
              [0.758429607945331, 51.361731805383052],
              [0.757000035504816, 51.360940728225465],
              [0.75814072887258, 51.361012797348863],
              [0.757355281423563, 51.359895358595701],
              [0.757199772565099, 51.357067719136545],
              [0.755984025503343, 51.356724891034204],
              [0.757032141997651, 51.35647716500339],
              [0.756267927488309, 51.356524837777251],
              [0.755421319068755, 51.355917884860027],
              [0.75621543067218, 51.355626676538847],
              [0.756812906430425, 51.355744821548043],
              [0.755559782387917, 51.353510537806301],
              [0.754073144066454, 51.352529198076766],
              [0.752637934826632, 51.351542133757683],
              [0.75289861109628, 51.351218517649635],
              [0.751458626519314, 51.350965459402737],
              [0.75185678992683, 51.350443445367084],
              [0.750591791222736, 51.350641358416091],
              [0.751283340360989, 51.350988465940262],
              [0.751229122017542, 51.351353995713922],
              [0.75019906847693, 51.350583129348635],
              [0.74851780113894, 51.350425642284208],
              [0.748317411807276, 51.350629112969237],
              [0.743632559060158, 51.349144361889429],
              [0.742631855460167, 51.348212649912156],
              [0.743403232560659, 51.347037054813896],
              [0.742169751132205, 51.345982179796742],
              [0.741469744278652, 51.34592392347605],
              [0.740068937759167, 51.34690108182054],
              [0.738332316255226, 51.347257400416431],
              [0.735933835676602, 51.346244093518756],
              [0.735285306467737, 51.34656773939335],
              [0.735620580821732, 51.34619295279429],
              [0.734704920248893, 51.346130700852093],
              [0.734499526435395, 51.345768545283526],
              [0.735545772108699, 51.345780979134609],
              [0.736902059084765, 51.346248445237187],
              [0.737147862427701, 51.345746232751644],
              [0.737505775415712, 51.346329666494356],
              [0.738689203823306, 51.346595192286706],
              [0.73938268086361, 51.346543891851404],
              [0.74127276957401, 51.345607451394976],
              [0.74210429106451, 51.345487246434779],
              [0.742102802996032, 51.34493325335383],
              [0.742139591210814, 51.345356906621767],
              [0.743445140697025, 51.34598377940199],
              [0.743916380685084, 51.344929436210997],
              [0.744053129578794, 51.345920064019055],
              [0.743419527141638, 51.346228115946843],
              [0.744116563580146, 51.347221802267697],
              [0.743657457281392, 51.348167034536651],
              [0.745002080108845, 51.349013326916392],
              [0.748600170170687, 51.350026175353712],
              [0.748179280197177, 51.349641220516332],
              [0.749446753177683, 51.348357703383634],
              [0.7498108982572, 51.348514640293338],
              [0.749033103585239, 51.349436799440205],
              [0.749270755745962, 51.349783782575592],
              [0.750721429107875, 51.349976356669593],
              [0.752317537872113, 51.349743676792727],
              [0.75235961607741, 51.349487258805851],
              [0.75433140825815, 51.350369965634449],
              [0.757582493592898, 51.354567375446706],
              [0.759131891425892, 51.359410104216181],
              [0.760138325324923, 51.360713893875769],
              [0.763089142704415, 51.360857454072516],
              [0.762562101600429, 51.361003900372559],
              [0.763061567375621, 51.361163000583566],
              [0.76410360074085, 51.360884775887158],
              [0.764387144751355, 51.36125133202615],
              [0.764539086560977, 51.360935653640375],
              [0.765383384084722, 51.361953621380685],
              [0.766862093543826, 51.362579745911347],
              [0.76711482638992, 51.362363314350148],
              [0.770352413143448, 51.362447749826714],
              [0.77458514272386, 51.361478706495632],
              [0.777732550730205, 51.361830397142135],
              [0.779117926411862, 51.361600554523385],
              [0.781992894002678, 51.360893706545191],
              [0.806071504518247, 51.360014219606093],
              [0.810108259703851, 51.35944971345728],
              [0.814293992738298, 51.358294173775818],
              [0.814676872452175, 51.356713706433766],
              [0.81349314120123, 51.355306704762704],
              [0.810403256147276, 51.353334766084387],
              [0.809152646839671, 51.351535387095403],
              [0.809727896392128, 51.351711357848984],
              [0.809987065817141, 51.351111529381129],
              [0.809368422207659, 51.351376411040917],
              [0.810062288778771, 51.350932540788797],
              [0.812841738600631, 51.35002125191329],
              [0.813981386816681, 51.349305818444492],
              [0.81392718350226, 51.347859074988435],
              [0.810918703291244, 51.347326046070215],
              [0.810727140029128, 51.347059024369337],
              [0.811702573998298, 51.346523838789381],
              [0.813401708769918, 51.346971396877315],
              [0.814512585634476, 51.34679539399901],
              [0.814579309357836, 51.347415280743292],
              [0.816374706572147, 51.346293712691732],
              [0.814769420008995, 51.347564508821179],
              [0.815068846910899, 51.349402894189382],
              [0.813735389020597, 51.350471067138379],
              [0.811597687298807, 51.35137776013152],
              [0.811135509755843, 51.352133567255251],
              [0.815069007799309, 51.354441370692776],
              [0.817140213388713, 51.356381940366369],
              [0.819531854333724, 51.35619305579916],
              [0.82157573723943, 51.355651840841972],
              [0.822310559226341, 51.354706839665297],
              [0.82285716389735, 51.354999460736778],
              [0.823397503374547, 51.354839827930242],
              [0.823061583377935, 51.354656354283939],
              [0.824098002813842, 51.354855311734497],
              [0.82806263673381, 51.354669671147498],
              [0.828509968724848, 51.354353065645199],
              [0.831729626069975, 51.354107047229391],
              [0.834338967213765, 51.354399173583019],
              [0.836704223689232, 51.354227663791733],
              [0.837501590806323, 51.353835085822752],
              [0.842864101457131, 51.354536951633278],
              [0.847853247655033, 51.354450775782759],
              [0.848338289280288, 51.354674622438438],
              [0.848456031253104, 51.354442407589822],
              [0.851696810714475, 51.354283467146729],
              [0.851586138615546, 51.354582969214349],
              [0.852010463557875, 51.354730930087925],
              [0.857249630791972, 51.355545771523275],
              [0.858236269230081, 51.355403865276578],
              [0.858578791122482, 51.355852405200253],
              [0.859599117595457, 51.355813097620974],
              [0.860930239604307, 51.356312131613251],
              [0.862728405282573, 51.356306852240174],
              [0.865895192078832, 51.355904687222498],
              [0.871734570832714, 51.354459244869567],
              [0.874396827574115, 51.354284183028071],
              [0.875784788510991, 51.353854354032002],
              [0.878522557485587, 51.352685284656964],
              [0.882498620923608, 51.349553715009293],
              [0.885931910016828, 51.347985986206432],
              [0.887561543137597, 51.346699204757229],
              [0.889185109214312, 51.346641158191225],
              [0.892936522913183, 51.345114835525543],
              [0.897599445411937, 51.34440499264808],
              [0.897020734727131, 51.343814004357569],
              [0.896041452716707, 51.343839134462613],
              [0.895054455358838, 51.343126920983714],
              [0.894064111549201, 51.342953535944453],
              [0.893573305726548, 51.342389139293623],
              [0.893673266766472, 51.341582585026288],
              [0.894277240555978, 51.34150469409721],
              [0.893793804568461, 51.341125397145134],
              [0.894435314208292, 51.340936847904189],
              [0.894082968202178, 51.337920801703106],
              [0.892451199905308, 51.335016424029476],
              [0.890840143250237, 51.334080348967639],
              [0.886054213829362, 51.333457323249071],
              [0.884773959153346, 51.332961820759067],
              [0.883519272202812, 51.331752433393461],
              [0.881986356924216, 51.33114170292852],
              [0.881776937802159, 51.330183578660751],
              [0.880084707161898, 51.328724994116463],
              [0.881106997778474, 51.328978656285273],
              [0.882215746638478, 51.32976353770033],
              [0.884848878051544, 51.332165785049476],
              [0.885901836330713, 51.331968939321825],
              [0.88543675964907, 51.332519158367063],
              [0.892094751807685, 51.334052955441699],
              [0.894054059384018, 51.33602643270082],
              [0.894362687508943, 51.335820029412687],
              [0.89449147091883, 51.336382582018871],
              [0.898816249993874, 51.333969439117254],
              [0.903305025006684, 51.335543710906308],
              [0.905229975217271, 51.335376327144424],
              [0.905551034559639, 51.333846531050291],
              [0.907006651979133, 51.334341050896839],
              [0.906102096632462, 51.333728517698255],
              [0.907904922673872, 51.334218902805851],
              [0.907008767009512, 51.334555961016804],
              [0.90549077016382, 51.334323822811143],
              [0.905917803206534, 51.335286400195322],
              [0.909707638507112, 51.334888248885285],
              [0.910855705832108, 51.334266983052125],
              [0.911958568339846, 51.331619523155553],
              [0.915333910429491, 51.329242863431062],
              [0.915356434220357, 51.327787928251766],
              [0.914920201399767, 51.327021687595874],
              [0.913932673310616, 51.326684707029919],
              [0.912134468835724, 51.327010981293611],
              [0.911163028059022, 51.326882244300236],
              [0.907786682171148, 51.324457671240943],
              [0.90409932944746, 51.323147913923201],
              [0.898170276651001, 51.321591364814182],
              [0.895102691611285, 51.321111438728025],
              [0.892855727633365, 51.319780084531757],
              [0.893517351859416, 51.320713521924127],
              [0.892486473055402, 51.320070730511759],
              [0.892511475180147, 51.31941892930832],
              [0.890394889024627, 51.318104094029884],
              [0.888407987830752, 51.317526246837858],
              [0.887533985341879, 51.318007418617832],
              [0.887820496868612, 51.317473287233234],
              [0.887318719671092, 51.317573810511604],
              [0.888164496444704, 51.317168888406826],
              [0.890637834181136, 51.317904717805092],
              [0.895423510741579, 51.320735637887886],
              [0.902358186803871, 51.322262894997287],
              [0.902395483749557, 51.320262546099748],
              [0.902925458345033, 51.322403528008195],
              [0.909297640086075, 51.32460630538705],
              [0.909577594524026, 51.325468192718674],
              [0.911096368773921, 51.326487254957655],
              [0.911819484360741, 51.326639259537217],
              [0.913968163687471, 51.326270986277287],
              [0.915572304368782, 51.326868732786103],
              [0.915971066184651, 51.327745634797601],
              [0.91567051409495, 51.329728264753385],
              [0.912989950913115, 51.332193777681667],
              [0.911148035178857, 51.334869523934323],
              [0.909907068854084, 51.335475113430938],
              [0.906580718522903, 51.335937314637555],
              [0.903932564536722, 51.33668030728137],
              [0.901844610733936, 51.336291368876189],
              [0.898901367620537, 51.335022355372068],
              [0.89630794882377, 51.336133482178063],
              [0.895616733466454, 51.337035615113059],
              [0.895763476392637, 51.339317418669964],
              [0.895939945165211, 51.340453521150373],
              [0.896701335572428, 51.341460024626166],
              [0.899572080699091, 51.341802686731775],
              [0.904343481858348, 51.343019148003691],
              [0.910710439710278, 51.343869263444795],
              [0.914525741518271, 51.343680794714757],
              [0.917370902745331, 51.344633469902107],
              [0.917139612783305, 51.345084449576007],
              [0.916359521452609, 51.344605604541364],
              [0.917090412571253, 51.345395078091393],
              [0.919012188177519, 51.345714139513397],
              [0.92213155532898, 51.345668694758267],
              [0.926006102590837, 51.344898242773397],
              [0.936664732134095, 51.346932979564833],
              [0.948958209835725, 51.345626466944012],
              [0.950213307163503, 51.345758538803622],
              [0.958120555195798, 51.337949782540221],
              [0.961068188709453, 51.337520876746581],
              [0.96272300430177, 51.336929448616488],
              [0.963875642044189, 51.335944168754672],
              [0.965205850866299, 51.336553574910937],
              [0.96764686999792, 51.336353199110235],
              [0.967561742116075, 51.337146860818052],
              [0.97193310465707, 51.338240299094714],
              [0.97233726657502, 51.336497722070128],
              [0.973694264530528, 51.335301182633074],
              [0.990544115321526, 51.32462546111865],
              [1.000919315092188, 51.318984820788771],
              [1.005080177294766, 51.319683057431803],
              [1.007857319001092, 51.319590128282385],
              [1.009565468183786, 51.318670154816964],
              [1.012430859564171, 51.318172794569755],
              [1.015010523689558, 51.316777881768857],
              [1.015175311115586, 51.316292432330087],
              [1.014815602722763, 51.316300799999148],
              [1.015644089004117, 51.314577700830135],
              [1.016955458460062, 51.312931192232583],
              [1.014481819505133, 51.312437280169299],
              [1.014089992314061, 51.312086694491931],
              [1.013527552765087, 51.312263979175945],
              [1.012110267863825, 51.311989258738294],
              [1.00662888648951, 51.309633354310577],
              [1.006656859189033, 51.308876201646335],
              [1.007548505722977, 51.307830618941608],
              [1.0091568717636, 51.307124580551879],
              [1.009039160658604, 51.305786532513864],
              [1.010253243847651, 51.304977285649805],
              [1.010190618440619, 51.303976912646746],
              [1.010996225822891, 51.302912827369617],
              [1.006202932433079, 51.292320953771913],
              [1.004021420800731, 51.288175662414019],
              [1.005937431281386, 51.28377941997114],
              [0.997535312119835, 51.280628353004417],
              [0.996173081273049, 51.280896203051014],
              [0.993657047647221, 51.279720271630751],
              [0.990539945804698, 51.279047152503374],
              [0.989164578892427, 51.279002249471098],
              [0.988675719986842, 51.279601177222418],
              [0.985544330936857, 51.278153867910909],
              [0.983194279427206, 51.277585101654402],
              [0.981722531983028, 51.27766759183617],
              [0.983162896814006, 51.274667201579717],
              [0.980137099368331, 51.274577019554897],
              [0.977138863473772, 51.272474000314816],
              [0.976969750118217, 51.270169428842358],
              [0.974604888328969, 51.269629653020743],
              [0.973248868411423, 51.268591986161937],
              [0.97194115128557, 51.269190065481219],
              [0.970030005467116, 51.269347432748944],
              [0.968158925203296, 51.269259103498023],
              [0.964876492374375, 51.268049837403979],
              [0.961469840127583, 51.268629018435306],
              [0.958120986418795, 51.268502374968612],
              [0.955002427815866, 51.270243354890994],
              [0.955264000118394, 51.269180803747489],
              [0.954164412544465, 51.267261285051653],
              [0.954313074101624, 51.266606335456878],
              [0.952393296701011, 51.265599755937657],
              [0.950275659952591, 51.262354030187758],
              [0.950118196656713, 51.260612185850619],
              [0.9510820243328, 51.257577444347838],
              [0.949092366916861, 51.256032011927914],
              [0.941893147244768, 51.253439412329264],
              [0.937938284160438, 51.253366115706093],
              [0.933488610710239, 51.25576326203322],
              [0.933393026607114, 51.256513092681764],
              [0.93130184346794, 51.258024401749665],
              [0.926899853383976, 51.259605203573436],
              [0.923698113180454, 51.259412737482286],
              [0.921834044300361, 51.258138937458774],
              [0.920620433179817, 51.257740212071106],
              [0.920077926770479, 51.256870508700956],
              [0.919860442988024, 51.25495474083241],
              [0.920818968364093, 51.252575186166055],
              [0.919500071573666, 51.251100654145489],
              [0.91674468481807, 51.251247642050146],
              [0.910250335023885, 51.250197855485823],
              [0.909755237967859, 51.250686872809716],
              [0.905290633548031, 51.250184429143481],
              [0.903674655784549, 51.249057986935995],
              [0.906367966952467, 51.247792152680937],
              [0.904942397712255, 51.245593367545581],
              [0.90234337987705, 51.245237848103059],
              [0.896694275655583, 51.247014848454043],
              [0.892026863857189, 51.247634019672859],
              [0.891380158941125, 51.246714596777728],
              [0.888620704759807, 51.245079248103522],
              [0.882424658037756, 51.242862991821539],
              [0.881110512296937, 51.241256603776819],
              [0.880021035990703, 51.239024961374696],
              [0.87628783691541, 51.24009360404407],
              [0.871751950857875, 51.239758037620135],
              [0.867730455771707, 51.239881885896985],
              [0.863463640672739, 51.238871125646163],
              [0.861000087283451, 51.235330996169878],
              [0.859843028654763, 51.230836963781115],
              [0.854480830821354, 51.230697224672575],
              [0.851421958539686, 51.231548942723585],
              [0.849568409491671, 51.232757974404386],
              [0.848575141700838, 51.233585357270314],
              [0.845688948926051, 51.234430917877297],
              [0.841737448367704, 51.237101143934147],
              [0.835286492187126, 51.231919606046127],
              [0.825163064856349, 51.228565972346807],
              [0.823258013572996, 51.226603560053604],
              [0.82213874189068, 51.224347835628009],
              [0.82107803739119, 51.224429189296956],
              [0.816270636821322, 51.225197127910626],
              [0.8120311253733, 51.227974913705211],
              [0.811269119496108, 51.227412205312348],
              [0.809752763797695, 51.227116755864735],
              [0.808866928635517, 51.22828299572479],
              [0.808264963517909, 51.227642672016778],
              [0.805308712031546, 51.229014176039882],
              [0.804637185055189, 51.228006740135328],
              [0.803726945583367, 51.228185062213527],
              [0.803916421894014, 51.229651971274286],
              [0.800138586393092, 51.229482541762465],
              [0.799508809524098, 51.228476776085024],
              [0.798207141316504, 51.229074558130115],
              [0.79729556859946, 51.228074622743172],
              [0.795087370732235, 51.228534849758496],
              [0.795508117438954, 51.22942601187772],
              [0.79354427551521, 51.229641123253771],
              [0.794032576300262, 51.230181702679317],
              [0.791215118068724, 51.230826397350469],
              [0.792148107770679, 51.232015645055242],
              [0.792348272909668, 51.23335729495097],
              [0.795733630369476, 51.236877697856343],
              [0.79262680365098, 51.23807348587944],
              [0.794916166794968, 51.24447302378919],
              [0.796438212049529, 51.24629484351636],
              [0.789347268818306, 51.248634428266847],
              [0.79163126004283, 51.251371731950279],
              [0.792354716630023, 51.254251482412982],
              [0.792157452605385, 51.255111534397379],
              [0.793348245306182, 51.257281278140496],
              [0.792321224491278, 51.258120665240476],
              [0.789539929884828, 51.259130500849011],
              [0.78948963880238, 51.260595945926063],
              [0.790599376405437, 51.261498575789346],
              [0.790516098950874, 51.26178027986213],
              [0.782937024893754, 51.264958548896089],
              [0.778805641507146, 51.26470237732228],
              [0.776753160607424, 51.266564320349651],
              [0.775185045789881, 51.264952006129057],
              [0.769372982400938, 51.262088390456938],
              [0.766355468605806, 51.259869879777035],
              [0.765109979792498, 51.258273189520587],
              [0.76192453897387, 51.256377826505073],
              [0.760730151964386, 51.256610183290491],
              [0.757629026093449, 51.25635773506329],
              [0.754958595044422, 51.256835276852676],
              [0.750736735254865, 51.257833125878534],
              [0.749609752803238, 51.25864390125156],
              [0.750782452871683, 51.259774771669278],
              [0.756865947627939, 51.268969233712312],
              [0.758696193363171, 51.271088270839272],
              [0.759974929760177, 51.271993498487994],
              [0.761493138217927, 51.274858266715967],
              [0.764508982342122, 51.276749559846124],
              [0.760862927613845, 51.278575998094411],
              [0.760032430183699, 51.279314216497802],
              [0.75681173783452, 51.278024840964477],
              [0.747422543808744, 51.278109863286069],
              [0.743843128389327, 51.278469069623668],
              [0.733140481217339, 51.285343641421065],
              [0.728921917368474, 51.286666178988881],
              [0.725272019439339, 51.288657964839871],
              [0.722823551662652, 51.289554099174794],
              [0.721313642626304, 51.28961080605562],
              [0.719837728796014, 51.291795580437991],
              [0.717665194956471, 51.293287787641766],
              [0.716284702919668, 51.29585434059139],
              [0.715557376104035, 51.295587009862828],
              [0.716138974657656, 51.296787699954187],
              [0.713785854062421, 51.299407368810932],
              [0.712702147092671, 51.298790323363839],
              [0.705988838664473, 51.298805430349994],
              [0.701547285169007, 51.299174260958125],
              [0.6928445776799, 51.298120168629922],
              [0.680469731946085, 51.291182126173183],
              [0.673813571452076, 51.293271679453255],
              [0.674239008194895, 51.294371807462063],
              [0.675843608897632, 51.295766961689218],
              [0.671415424229018, 51.296834068155121],
              [0.673034834538195, 51.299274014038943],
              [0.671621430791172, 51.299689426557201],
              [0.675314494475409, 51.30383669844818],
              [0.675152755617023, 51.305619388710213],
              [0.673395142299252, 51.30567034035478],
              [0.669149284359072, 51.307992330848677],
              [0.670925748988331, 51.310092351603984],
              [0.669031261616257, 51.311694206620999],
              [0.66874864455249, 51.312734047555473],
              [0.66531746266945, 51.317240810535012],
              [0.662787888856177, 51.31943352434039],
              [0.658824921832242, 51.324492709558179],
              [0.658765165479154, 51.324725208719634],
              [0.660724309323964, 51.325043147619517],
              [0.66159512569939, 51.325604354383152],
              [0.662323479512686, 51.325242427906566],
              [0.663374187635335, 51.325910154657826],
              [0.664478529801691, 51.327304254338792],
              [0.665241827472921, 51.327990198094888],
              [0.664289950259869, 51.328929238915414],
              [0.660398352272634, 51.330421682084427],
              [0.659061012328092, 51.330557293943194],
              [0.656691887387921, 51.331619291281534],
              [0.644958484671684, 51.336163331768894],
              [0.641905031352881, 51.336197200405095],
              [0.639717874800215, 51.336764578732755],
              [0.636259779657522, 51.335277577157235],
              [0.634669279155536, 51.335258519048935],
              [0.629463883573821, 51.33301377778519],
              [0.62324528602332, 51.331872568882382],
              [0.619199198454965, 51.331563836863857],
              [0.618400043755231, 51.33152235446164],
              [0.61959218283557, 51.33326925814039],
              [0.619416537793484, 51.334469360385782],
              [0.615851045524702, 51.335115687957362],
              [0.614258859484948, 51.335091893166179],
              [0.612896416205732, 51.334664525738347],
              [0.610912579306474, 51.332650112376065],
              [0.607804894131301, 51.328486913294959],
              [0.604395248291655, 51.325697403981899],
              [0.601512846695984, 51.327371375987319],
              [0.60095126707413, 51.328203185167524],
              [0.604595890636951, 51.328604220473466],
              [0.607048695133127, 51.3307953662259],
              [0.60284743695677, 51.332178552263137],
              [0.601374838890726, 51.333301325215039],
              [0.602001107881499, 51.334624778271198],
              [0.603849569448282, 51.336257418277029],
              [0.60798070164968, 51.3346446223619],
              [0.610920290398481, 51.336474087847016],
              [0.612023159272689, 51.337941552345846],
              [0.61129305603904, 51.33931859427431],
              [0.613694361752621, 51.341118625335369],
              [0.617904739339868, 51.347393938713616],
              [0.61813385779004, 51.348641645947531],
              [0.620004350588054, 51.349708736079897],
              [0.622055586448177, 51.349688900777075],
              [0.625297716859068, 51.355174396988325],
              [0.628540097753368, 51.359370994324124],
              [0.629269808542866, 51.359219705352807],
              [0.630594942872187, 51.360910449956876],
              [0.634879075185478, 51.366369576631818],
              [0.628352881044991, 51.368368050609625],
              [0.626711739501572, 51.368689075911121],
              [0.626238370796403, 51.368250904933262],
              [0.623263680324205, 51.369222348735832],
              [0.625805605216112, 51.371409318531995],
              [0.625709143821447, 51.37346475187406],
              [0.626898150965305, 51.374730487425204],
              [0.627065685407682, 51.374117853283373],
              [0.627518501589162, 51.374471941094733],
              [0.628512130295865, 51.373078997432998],
              [0.628620649264872, 51.373568519373855],
              [0.627197660589306, 51.375318254854328],
              [0.627896518318517, 51.378160784632321],
              [0.630051828946219, 51.380710692853341],
              [0.63107662463768, 51.380361202131091],
              [0.631021582711787, 51.380929943469688],
              [0.631216824216535, 51.380349958020396],
              [0.631795843965184, 51.382350645681008],
              [0.634590041502902, 51.382528855119233],
              [0.635845509016025, 51.383016847156711],
              [0.638255262506263, 51.386554689881173],
              [0.640018666016384, 51.38664986060671],
              [0.640874706594871, 51.387537131032239],
              [0.641043630484404, 51.388698011836084],
              [0.639560496603941, 51.38975223554614],
              [0.641696380782498, 51.391494734866406],
              [0.641259344064313, 51.393590239165697],
              [0.639910733688413, 51.395085722226995],
              [0.642989648084703, 51.395774459857293],
              [0.644410242302797, 51.396624681856252],
              [0.647988946226732, 51.397599697523411],
              [0.649576305799297, 51.396581684074398],
              [0.651419998451237, 51.396119056169915],
              [0.653043067587819, 51.395223400519662],
              [0.654151265280543, 51.393530851196807],
              [0.65391112280896, 51.393132472955507],
              [0.65450354248741, 51.393035406141934],
              [0.654214478164439, 51.39288456623752],
              [0.655715728775632, 51.39244491786495],
              [0.656961295892887, 51.392951798102793],
              [0.657424576022551, 51.392660682395274],
              [0.656934205503328, 51.391045734526678],
              [0.657519180364814, 51.391440780058225],
              [0.657977451822148, 51.391361129123176],
              [0.658115195958109, 51.391728546335933],
              [0.659617798376324, 51.391833838962569],
              [0.659227077125458, 51.391491953649513],
              [0.658298043973541, 51.391559810567173],
              [0.658093679231299, 51.391233478079513],
              [0.658954498705408, 51.389915238595712],
              [0.65890061845826, 51.389134903099219],
              [0.659562565945442, 51.388998457825771],
              [0.659610753292849, 51.388491014181227],
              [0.660790361845188, 51.387912003746671],
              [0.661477059454394, 51.386988928982134],
              [0.661232638584173, 51.384560766390429],
              [0.660807968833691, 51.384153103854935],
              [0.660602125914395, 51.384469864407684],
              [0.660287275482938, 51.383898716512732],
              [0.66116332091849, 51.383267236946686],
              [0.661725523518562, 51.383295832445185],
              [0.661300540400637, 51.384319089509688],
              [0.661971430671821, 51.385179837065117],
              [0.663176604312046, 51.385364691871189],
              [0.662154963266688, 51.385122602118884],
              [0.662335975675405, 51.384848674025214],
              [0.661774837525231, 51.384615898278902],
              [0.662034173243054, 51.384329397988679],
              [0.661481231175747, 51.384188171160126],
              [0.66170540378355, 51.383642550341598],
              [0.662617088330588, 51.383773821975964],
              [0.663109577019434, 51.383443345011536],
              [0.662562335273669, 51.382731787770588],
              [0.662921286218677, 51.382675959625566],
              [0.663824105494457, 51.383991005580512],
              [0.664168121016673, 51.383257381888363],
              [0.66434096609812, 51.383436923133488],
              [0.665492803095552, 51.383100428053545],
              [0.664953384657742, 51.382993985631195],
              [0.664953697598997, 51.382405784717314],
              [0.666827032145854, 51.383058362281233],
              [0.666840117948775, 51.382690218225065],
              [0.666220612858096, 51.382763682340084],
              [0.666604834042519, 51.382326830170101],
              [0.666308051544096, 51.382265234427848],
              [0.667630932006424, 51.382275581647477],
              [0.666407186383651, 51.381967981056327],
              [0.666795072764476, 51.381371854555169],
              [0.666356516517315, 51.379687409276876],
              [0.666938500908636, 51.378845828926337],
              [0.669112982349185, 51.378501293615486],
              [0.671670259997237, 51.377352033646694],
              [0.671706817178613, 51.376795382403856],
              [0.67097670679592, 51.376448686863611],
              [0.670100057569236, 51.375065644075214],
              [0.670036075159097, 51.373299819715896],
              [0.670296252014031, 51.375053975582702],
              [0.671596866550127, 51.376314023910233],
              [0.672165984408488, 51.37601863375378],
              [0.674803348300508, 51.375802832334053],
              [0.675202921158534, 51.376518606629411],
              [0.676079347088318, 51.376860133926392],
              [0.675864433590609, 51.377191520155947],
              [0.676277114593066, 51.377392545254693],
              [0.676481649292901, 51.378361001521633],
              [0.677772009606731, 51.379613122727385],
              [0.678542636058131, 51.379667450306002],
              [0.679454743237967, 51.379265247576079],
              [0.679255977080077, 51.379700598856829],
              [0.679690064574238, 51.379972173571836],
              [0.680618425518561, 51.379725182891789],
              [0.680746511125372, 51.379974077925972],
              [0.68138951189866, 51.379885608295872],
              [0.681477450670177, 51.38038185238193],
              [0.681890614898901, 51.380049513490832],
              [0.682499860803397, 51.38034224933913],
              [0.682075765323554, 51.381028297808086],
              [0.682422689996955, 51.381086906432742],
              [0.683322714146607, 51.382983770640081],
              [0.682819681509123, 51.385121433125093],
              [0.683473732658112, 51.386817072423561],
              [0.68310692974387, 51.38715644853022],
              [0.683616711697448, 51.387270682077528],
              [0.684135142662076, 51.389128615591815],
              [0.686028347638137, 51.390429787624392],
              [0.685860484276306, 51.390877032211691],
              [0.686459145804806, 51.391550429441409],
              [0.687334364499921, 51.391864921053298],
              [0.691827871568176, 51.392128681070652],
              [0.695936175006831, 51.392857123221368],
              [0.698132215735256, 51.39414123862143],
              [0.700467207175185, 51.393267187732455],
              [0.700356640843012, 51.392976537980353],
              [0.70083486335269, 51.393187658959981],
              [0.703034152811561, 51.392598192253971],
              [0.703115965770192, 51.39176707208803],
              [0.702608127313224, 51.390712122256993],
              [0.702887791698647, 51.391846986884055],
              [0.702461796341478, 51.39181454600002],
              [0.702742206188274, 51.391645287013546],
              [0.702355908865575, 51.391066903006674],
              [0.7008024360018, 51.39073398888322],
              [0.700131830422742, 51.390136071417658],
              [0.699448417272976, 51.390248059083682],
              [0.698780716885324, 51.389943266110834],
              [0.699202210890553, 51.388043947810338],
              [0.698061075331062, 51.386899244142413],
              [0.696538498966285, 51.38615994088579],
              [0.696206133518863, 51.385298788346844],
              [0.694715212834811, 51.384949964929802],
              [0.694722043273846, 51.383133952365107],
              [0.693342565331747, 51.381989291473865],
              [0.694396309230807, 51.381332786171782],
              [0.695256033149631, 51.379820925274892],
              [0.696181940897219, 51.380438175115223],
              [0.697824035355647, 51.380720539622352],
              [0.697327595814413, 51.381003588785056],
              [0.699437968737661, 51.380695041398859],
              [0.699590094987315, 51.381228467451329],
              [0.699975885387383, 51.380823844530646],
              [0.699761210567173, 51.381400802287992],
              [0.700788020839457, 51.381948236338467],
              [0.701052392076267, 51.381727186277168],
              [0.700520609678938, 51.381483125291275],
              [0.701594704121328, 51.381783929916089],
              [0.702117343487552, 51.381457986924445],
              [0.70389989903776, 51.382219995702904],
              [0.709161325542792, 51.382967208414108],
              [0.709532244480387, 51.383820239255094],
              [0.7102665016104, 51.383889590335009],
              [0.710842127730687, 51.383220615114752],
              [0.710750222615478, 51.383805544710903],
              [0.711462057906265, 51.383690133696597],
              [0.711118130620078, 51.38331855491262],
              [0.713357453815749, 51.384144495546394],
              [0.713511146629686, 51.383948465868805],
              [0.713545164827507, 51.384329017597317],
              [0.71400267547166, 51.384166419782957],
              [0.714240467833051, 51.384492782630659],
              [0.713528892257694, 51.384709835157217],
              [0.714664172083991, 51.384803143769858],
              [0.714031699832123, 51.3849724969999],
              [0.716360056787558, 51.387104925606963],
              [0.716593337954227, 51.387936843772096],
              [0.719157821351576, 51.388180424613729],
              [0.718625111712622, 51.389378183469603],
              [0.716983831462101, 51.390208629855934],
              [0.711895223828841, 51.391500264844915],
              [0.708950600855899, 51.39407049808797],
              [0.707322218094306, 51.394127036678171],
              [0.707328397282329, 51.39442638884757],
              [0.708128085962594, 51.394335051115355],
              [0.70711075583742, 51.394806462698398],
              [0.706396342115179, 51.394272549717329],
              [0.705572474210001, 51.394563198019164],
              [0.706934635995655, 51.39557321106065],
              [0.707306374101181, 51.396390253731141],
              [0.707344425064127, 51.397326532409537],
              [0.706449242082795, 51.398017261787366],
              [0.704875678496299, 51.398469126511465],
              [0.704669753227351, 51.398293105755769],
              [0.706636681799952, 51.397052388502047],
              [0.706088759515905, 51.395710576762987],
              [0.705115733790472, 51.395009049677583],
              [0.703600319486275, 51.395007165175542],
              [0.701750728036531, 51.395726181063573],
              [0.700979826770348, 51.397526538227559],
              [0.701103397195213, 51.398159552342392],
              [0.70181731158843, 51.398367932238358],
              [0.70221569206799, 51.397638336171639],
              [0.702640983145484, 51.397950502802011],
              [0.702177943193779, 51.398461243204899],
              [0.704035952853959, 51.398151246798783],
              [0.704554844754155, 51.398514309295898],
              [0.702640085948217, 51.399032481389554],
              [0.70031878903968, 51.398776632563823],
              [0.698869949212886, 51.400884001244457],
              [0.699023875837063, 51.401324749247799],
              [0.698574634760094, 51.401533863583445],
              [0.699137575808968, 51.402351921776564],
              [0.698909137520852, 51.402574836031235],
              [0.698703384125881, 51.402206333198592],
              [0.698014032527251, 51.402196131655622],
              [0.695864461809073, 51.407281260636772],
              [0.697308679700632, 51.408851610031675],
              [0.698554906857078, 51.409598173439072],
              [0.699602018548297, 51.409283546046986],
              [0.699492705166615, 51.408770719756305],
              [0.700644100094252, 51.408444685674809],
              [0.701442069252382, 51.408393008410535],
              [0.70234785554433, 51.408998984680657],
              [0.70248891528381, 51.40809904664804],
              [0.703699570679272, 51.407803093301844],
              [0.704766615599262, 51.407997908677132],
              [0.704900475452331, 51.407512748741517],
              [0.704263206498084, 51.407582325053184],
              [0.70492005522916, 51.407186720169499],
              [0.70550475981416, 51.408418861367522],
              [0.703400502460024, 51.408973796244027],
              [0.704470281011498, 51.408361805606532],
              [0.703223559231945, 51.408144152630243],
              [0.703185127358511, 51.409003949332032],
              [0.702084615404589, 51.409436760502771],
              [0.70103688244835, 51.408740519674843],
              [0.70048814115621, 51.408848506034786],
              [0.699526309629153, 51.410414913658848],
              [0.698907616493216, 51.410287967208447],
              [0.699244287496518, 51.410754183631319],
              [0.698763162921173, 51.411082750632922],
              [0.69936389455856, 51.411539285278288],
              [0.698917151012503, 51.412646823737738],
              [0.698157439361194, 51.412592374383031],
              [0.697696872926443, 51.41515092663883],
              [0.696232545757519, 51.419693230080952],
              [0.69659273128695, 51.420142723865908],
              [0.696928191962897, 51.419341748765191],
              [0.697725816117214, 51.420282122340609],
              [0.697328655302252, 51.420108693479591],
              [0.697487277717104, 51.420359565565896],
              [0.696806663068754, 51.420600080358369],
              [0.698907825516526, 51.42139800861834],
              [0.703648722431347, 51.420538608082182],
              [0.70469405485068, 51.420044102280421],
              [0.707356245026666, 51.420037470196775],
              [0.707973912116183, 51.419755178288263],
              [0.70964806492359, 51.420310958100828],
              [0.710833773378738, 51.420002020040947],
              [0.712761910591096, 51.420206515389992],
              [0.714068654975192, 51.420750953501738],
              [0.714847336344546, 51.420467592755926],
              [0.717156304556361, 51.420834971945922],
              [0.718464833531326, 51.42063283221497],
              [0.718169005193485, 51.420956279468243],
              [0.719532500831876, 51.421171065584943],
              [0.720396484744646, 51.421791366399979],
              [0.723100250431238, 51.42197408085719],
              [0.724926243796556, 51.422925441017142],
              [0.727947787251493, 51.423587219071884],
              [0.729652419728602, 51.420955485928872],
              [0.725488962003961, 51.421044330013345],
            ],
          ],
          [
            [
              [0.745216304913197, 51.447263985043236],
              [0.750863626266455, 51.445411012838328],
              [0.754706241068551, 51.444451027433736],
              [0.757593422766448, 51.444070125408281],
              [0.76318768645225, 51.441901214251367],
              [0.769385655983537, 51.440775443941803],
              [0.76994062268511, 51.441218315265289],
              [0.772751380489041, 51.440341480504721],
              [0.776168661549619, 51.44028394204453],
              [0.779323720695708, 51.440719094928227],
              [0.781399948434627, 51.440356836247346],
              [0.782872290762878, 51.440571904807847],
              [0.787315535648097, 51.440346664016879],
              [0.79131051236012, 51.439379142796973],
              [0.810908465923285, 51.42985006005776],
              [0.816568121245934, 51.427596041088549],
              [0.82517504572655, 51.425505444419414],
              [0.829843008922525, 51.425776818797985],
              [0.832043120264931, 51.425457427636886],
              [0.833387916108056, 51.425730705265728],
              [0.840150952027651, 51.424684617027843],
              [0.843116616967092, 51.424625268164171],
              [0.84399371596986, 51.424270298407023],
              [0.847121642993282, 51.424090885042169],
              [0.848692443189845, 51.423718117859579],
              [0.853131281404499, 51.423452692376244],
              [0.861730718094481, 51.422408275176124],
              [0.868966483297876, 51.421003587967562],
              [0.877794487859972, 51.421708932140298],
              [0.883940907591137, 51.421312228328205],
              [0.887866567246623, 51.420534656741587],
              [0.892706557012781, 51.418907815296237],
              [0.896568423433165, 51.418218766846088],
              [0.900921236725932, 51.416766420643981],
              [0.902924219699079, 51.416006258833988],
              [0.904340283501069, 51.414747050457741],
              [0.904681985785924, 51.412582677476344],
              [0.911463167007161, 51.406423291753924],
              [0.918264660381784, 51.401988072318872],
              [0.924779014352139, 51.398841289533962],
              [0.928250802001183, 51.396738929103769],
              [0.938146645708697, 51.389824541304542],
              [0.939170752074225, 51.387411768399154],
              [0.940541622250875, 51.386216194263717],
              [0.943512320723359, 51.38242423011868],
              [0.944988221592677, 51.37977968222954],
              [0.95099414651557, 51.373475485349921],
              [0.950360493023153, 51.372203472175336],
              [0.948080591757242, 51.370380194875267],
              [0.94608949637402, 51.369404064472562],
              [0.945398632031797, 51.368244994159888],
              [0.943451794892435, 51.36741881043389],
              [0.942282281943725, 51.368406096776127],
              [0.942435024464326, 51.368592037502289],
              [0.94325439552582, 51.368306111652025],
              [0.942793048472234, 51.369312475216709],
              [0.943530769357702, 51.370329172203689],
              [0.941774080070196, 51.370407500789788],
              [0.941337801569943, 51.370012819854715],
              [0.941248311750578, 51.370432402181194],
              [0.940603914372911, 51.370691433411842],
              [0.939184121993211, 51.370084892243348],
              [0.93786949482585, 51.370289676522553],
              [0.937544319575305, 51.370103559155901],
              [0.937242468001301, 51.370550951500057],
              [0.937180400478519, 51.369828470181901],
              [0.936500045178645, 51.369391325217208],
              [0.933362516893032, 51.368144219145208],
              [0.930504909575054, 51.36630539756564],
              [0.927043142449451, 51.365172372200142],
              [0.92686850947769, 51.364867335626741],
              [0.927701395894735, 51.364635146559841],
              [0.925867934906228, 51.36341638768284],
              [0.926264953250499, 51.363243669340349],
              [0.926354851501726, 51.362377973968641],
              [0.925420963844008, 51.362152171792239],
              [0.924818501750541, 51.36146837044835],
              [0.921332739019672, 51.35983749796744],
              [0.920879610570934, 51.359805631329188],
              [0.920721162483281, 51.360205331164813],
              [0.921486021875819, 51.360936367978766],
              [0.921067202878648, 51.361105116965362],
              [0.916347057221204, 51.358245694976887],
              [0.915041523995045, 51.358047056590756],
              [0.912345159608002, 51.356757831195694],
              [0.908621834850474, 51.355558822040571],
              [0.897137300213127, 51.354332556850153],
              [0.894657160282415, 51.354455965159474],
              [0.892212447931067, 51.355118997174131],
              [0.889147451439739, 51.355285516430207],
              [0.88104297575311, 51.357781451146614],
              [0.879806970505419, 51.358527796845344],
              [0.879659011213412, 51.359329219654711],
              [0.878089174645939, 51.359357927034623],
              [0.875915323771326, 51.360545510130947],
              [0.875715220663526, 51.361043305622239],
              [0.876139276160497, 51.361666078719459],
              [0.875275227250099, 51.361862687392417],
              [0.875309859019558, 51.362254881952097],
              [0.876368159327124, 51.362704678761077],
              [0.876506509000954, 51.363124005679872],
              [0.874969898142452, 51.363134764791397],
              [0.874574171431671, 51.36330817496745],
              [0.875260240393661, 51.36359533161297],
              [0.874518423985878, 51.363794329014773],
              [0.874033610780004, 51.363577778961158],
              [0.874188737443556, 51.363233092223837],
              [0.872673138265672, 51.363854908164001],
              [0.87204571631931, 51.363792950656858],
              [0.871596262501894, 51.364478537508099],
              [0.870912651806588, 51.364306423501901],
              [0.868237288785873, 51.365062743716962],
              [0.86666873986607, 51.364885298442765],
              [0.863168732265724, 51.365460220240436],
              [0.862289326634023, 51.365366596571135],
              [0.864469756598655, 51.36482758949483],
              [0.864727785822922, 51.364403948258591],
              [0.864309570788693, 51.364148852780268],
              [0.863350055177753, 51.36403740991166],
              [0.862803175551497, 51.364299021113425],
              [0.860592058276957, 51.363907853700766],
              [0.857670235826049, 51.364607892556641],
              [0.858689647881283, 51.366095820822849],
              [0.858176630641535, 51.367755170381123],
              [0.858719551433009, 51.368005444208336],
              [0.859203270451661, 51.367628471465167],
              [0.859245754387209, 51.368125708903456],
              [0.859701380468589, 51.368243197934611],
              [0.859622967972938, 51.36961312028297],
              [0.857556798166738, 51.370330027649139],
              [0.856528515990295, 51.369946784738552],
              [0.857337722941567, 51.368358464944073],
              [0.857708087620432, 51.368537403571409],
              [0.857557917226831, 51.367856615436203],
              [0.858189935469293, 51.36646958438164],
              [0.857794964129582, 51.365295597631224],
              [0.856822186030593, 51.364971263963398],
              [0.853975130597841, 51.366782857576119],
              [0.851956520869714, 51.368980738030167],
              [0.849384577680949, 51.370647904044077],
              [0.847369978548352, 51.371041385539669],
              [0.842125855048227, 51.371053666489502],
              [0.841855677012298, 51.37140110035476],
              [0.841728550844504, 51.371111877492943],
              [0.84033390038438, 51.371447018192065],
              [0.839244859193754, 51.370312292613995],
              [0.840971894337138, 51.370170568284458],
              [0.841420505944026, 51.369873670546802],
              [0.84534268507187, 51.369771231223602],
              [0.847818557789702, 51.369170494698025],
              [0.849786052885465, 51.367950692551773],
              [0.850901570434287, 51.36664097592184],
              [0.850918613732216, 51.365597243148358],
              [0.850498661464537, 51.365451869451555],
              [0.851048156827583, 51.365416005290221],
              [0.851365582236791, 51.364766994762668],
              [0.850361298703216, 51.363957692578438],
              [0.843753585429229, 51.3635607311153],
              [0.840434057699977, 51.363874255424967],
              [0.839597988772155, 51.363550960247579],
              [0.837352455650055, 51.363760995905977],
              [0.836088045268348, 51.36424763023831],
              [0.836459625732395, 51.364587600103086],
              [0.822094400434919, 51.367824512428697],
              [0.822133122616202, 51.368407294113851],
              [0.82074586200779, 51.369027134532459],
              [0.818389652754826, 51.368984937086871],
              [0.819082641194496, 51.368570717427737],
              [0.820311993906969, 51.368397208274438],
              [0.819587117142196, 51.368315730982367],
              [0.81597446142469, 51.368847874296151],
              [0.814405827965058, 51.36941713661993],
              [0.808699333103485, 51.369900427148615],
              [0.807181844916785, 51.370438684267988],
              [0.806424643367554, 51.371073825659302],
              [0.805830933693389, 51.371727529549567],
              [0.806254315049693, 51.371765951579697],
              [0.806253485256467, 51.372175200088918],
              [0.804691055160716, 51.371721559654148],
              [0.804585065676849, 51.370670884332604],
              [0.805821735087213, 51.371083777015862],
              [0.806215272930579, 51.370258587275849],
              [0.805926787618071, 51.369794213029103],
              [0.80481562900098, 51.37004568536527],
              [0.803774769275998, 51.369778306838718],
              [0.803442701329306, 51.370175699248655],
              [0.801856881109636, 51.369808024955127],
              [0.801681608492961, 51.369973211927565],
              [0.800325897997734, 51.368996511714933],
              [0.796308757593594, 51.368554671108207],
              [0.796689300470563, 51.369234524916436],
              [0.798201047099213, 51.369142645447262],
              [0.797388285507028, 51.369999408821613],
              [0.797707250111612, 51.369409874994467],
              [0.796793095055458, 51.369475783844152],
              [0.796008497085099, 51.370229332797919],
              [0.795910877988777, 51.370443023187462],
              [0.796943385748573, 51.370691783459471],
              [0.79576086106734, 51.37059860401024],
              [0.796265129067273, 51.370856383669611],
              [0.795853402084885, 51.370938167211946],
              [0.794546936089597, 51.37072111905151],
              [0.795046341018535, 51.370451071107574],
              [0.793889415756145, 51.370589307389366],
              [0.793628036602217, 51.370426456077993],
              [0.794259621048595, 51.370344830490779],
              [0.791951668397803, 51.370057210729684],
              [0.791624019898624, 51.370269186630246],
              [0.791698511570898, 51.371493294559052],
              [0.790917218476508, 51.371972412059044],
              [0.791526664328014, 51.371857151884377],
              [0.791405760249892, 51.372208102313266],
              [0.790682705113204, 51.372369240660305],
              [0.791443939522856, 51.372410456806506],
              [0.790732728768703, 51.372600993306634],
              [0.790773936675195, 51.372900411096445],
              [0.790344604290245, 51.372669603098046],
              [0.790395879848085, 51.373016449333633],
              [0.788074787931775, 51.373389225191922],
              [0.787600776660058, 51.373297978635634],
              [0.787037511689491, 51.372352625506899],
              [0.783140012697032, 51.370545792502888],
              [0.782549600371652, 51.370382642159178],
              [0.780597735668383, 51.370658354746467],
              [0.780715729185337, 51.370186965684915],
              [0.781502527487276, 51.370295094050611],
              [0.782687026563719, 51.369805559352343],
              [0.783447468393049, 51.369810869965846],
              [0.782653110817141, 51.369009497034853],
              [0.78284413422487, 51.369529307540603],
              [0.780163327649103, 51.369465273099301],
              [0.778208692079005, 51.367606737501269],
              [0.777549260627603, 51.3679164850749],
              [0.773557300733124, 51.367743087935473],
              [0.771375730315002, 51.368686944316444],
              [0.768439949580879, 51.370801620349297],
              [0.766908333018652, 51.369565174081309],
              [0.766153227763186, 51.369767390648846],
              [0.764983427335137, 51.370789751627264],
              [0.764404399635278, 51.372102151199094],
              [0.764466442735752, 51.374514666356184],
              [0.765470665175565, 51.376488514016273],
              [0.766679720607303, 51.376638934958038],
              [0.765364956549625, 51.376832780875809],
              [0.765476782769019, 51.37749569317549],
              [0.768869359443742, 51.378153930945146],
              [0.770267134939186, 51.37923882305882],
              [0.769465783642305, 51.380176960398927],
              [0.769879237708747, 51.379217419104137],
              [0.769222438900009, 51.379336383034676],
              [0.768480991872737, 51.379076899786561],
              [0.768095167420849, 51.37928029000124],
              [0.768811115269907, 51.379641110985823],
              [0.768630411090439, 51.38105203905662],
              [0.769118146378023, 51.380846237485642],
              [0.76737297902115, 51.383388710524741],
              [0.766904000648894, 51.383524807833268],
              [0.766747901585127, 51.384201243652406],
              [0.766183317911163, 51.384420542198804],
              [0.765355851995549, 51.3856911428594],
              [0.763886734130754, 51.386547870650716],
              [0.762117300258473, 51.386858535232896],
              [0.762489323233981, 51.387545891717231],
              [0.763049444865501, 51.387227783450655],
              [0.76315664604776, 51.387694738121326],
              [0.76423392594342, 51.388229638884972],
              [0.763919560621378, 51.388475397930932],
              [0.761882876615993, 51.388312088613297],
              [0.760570360527531, 51.387928413387463],
              [0.761586222264822, 51.38770659675837],
              [0.760355784622775, 51.387611488052357],
              [0.756715408559353, 51.388101062025484],
              [0.754893982781875, 51.388840051811087],
              [0.752212469653117, 51.390793620711207],
              [0.751465707324339, 51.390782386384686],
              [0.751374974189281, 51.39113887844109],
              [0.751482016113863, 51.39380845711333],
              [0.750828565390617, 51.394706109810478],
              [0.749891290033399, 51.394851336470062],
              [0.749230485487269, 51.394620416404145],
              [0.749050568782096, 51.394233404309347],
              [0.74984730093834, 51.394022231007952],
              [0.749507847660985, 51.393611983439961],
              [0.748706430194255, 51.394296344254002],
              [0.746004411654659, 51.39536524536085],
              [0.742076328860411, 51.397667076628743],
              [0.742140333022629, 51.398232193686816],
              [0.741334446927726, 51.398700753107512],
              [0.740739204197368, 51.398484439602633],
              [0.739770311127296, 51.398679790435409],
              [0.739384734773596, 51.399277009572337],
              [0.738689792864411, 51.398980273637328],
              [0.734111060227856, 51.399762686237899],
              [0.731003500731508, 51.401372238291692],
              [0.728810946104792, 51.404707900703549],
              [0.727951452211844, 51.407637435933083],
              [0.726221683211284, 51.410178899402887],
              [0.726557349217692, 51.410454393214771],
              [0.725703041044979, 51.410477872936639],
              [0.722786773701404, 51.413653070218871],
              [0.721474640669629, 51.414468724159605],
              [0.722489223361672, 51.414551276049075],
              [0.725053670741339, 51.412302544597487],
              [0.725592389883169, 51.412582311173068],
              [0.728909861442855, 51.410135191802375],
              [0.73210530277645, 51.409023712161456],
              [0.734356766128039, 51.409326434778656],
              [0.740565272128054, 51.411227449911536],
              [0.742364669176596, 51.414306213421021],
              [0.742505482734869, 51.415384880225339],
              [0.743556686200134, 51.415501468683054],
              [0.742644357056341, 51.415758472267747],
              [0.743053909008921, 51.41613651879269],
              [0.744112797337665, 51.415948929348353],
              [0.743398342823082, 51.416339808974129],
              [0.743638083273137, 51.416620199456453],
              [0.745139542295343, 51.416665069089156],
              [0.746627126628856, 51.416214681875097],
              [0.746868769665659, 51.415375282797797],
              [0.746883385549257, 51.416362468486874],
              [0.74369650239615, 51.416947107702782],
              [0.742006624949782, 51.416181723724691],
              [0.741903893301341, 51.41516242411231],
              [0.741189377135418, 51.415119786915689],
              [0.740270860764497, 51.416162981217333],
              [0.740152456089653, 51.417261204492917],
              [0.736276181173873, 51.421115743166816],
              [0.735467517814713, 51.423014344943958],
              [0.735441441790687, 51.424287584338693],
              [0.736509073673473, 51.429706629644755],
              [0.737075073731719, 51.43190589462067],
              [0.737935349237564, 51.432528853581061],
              [0.743915619626397, 51.440479815124718],
              [0.745454886548205, 51.440422171276239],
              [0.745522032498502, 51.44067782202999],
              [0.744546244064484, 51.440700683551356],
              [0.7442795411002, 51.441100861260388],
              [0.744212489647365, 51.442934478567821],
              [0.743625596486582, 51.443107413976797],
              [0.743395496822445, 51.444670535669992],
              [0.743775768464096, 51.444651738140927],
              [0.743803970358968, 51.445170022515754],
              [0.743986033778076, 51.44465400919001],
              [0.744951647221039, 51.444724028060946],
              [0.744756532652213, 51.445286217315058],
              [0.745231646632373, 51.445456762239033],
              [0.744420591303209, 51.445444283634515],
              [0.744304103247244, 51.446021718644083],
              [0.743711294520856, 51.445400636324507],
              [0.743658121187491, 51.44693802967813],
              [0.745216304913197, 51.447263985043236],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000114",
        LAD13CDO: "29UN",
        LAD13NM: "Thanet",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [1.367572487912764, 51.313386030136115],
              [1.366523808089208, 51.313256096167784],
              [1.366163356383442, 51.31366495209825],
              [1.367572487912764, 51.313386030136115],
            ],
          ],
          [
            [
              [1.428940006835915, 51.391942650624905],
              [1.434713664717114, 51.389288918337037],
              [1.437536021126946, 51.388658266017273],
              [1.437604457064994, 51.388228983733043],
              [1.442027413730408, 51.387185102245063],
              [1.441083362889434, 51.385966119199225],
              [1.440969505738389, 51.384890039247658],
              [1.442307413410876, 51.383869324138978],
              [1.444893194635005, 51.382965694056487],
              [1.446111293494236, 51.380009086019747],
              [1.449559808049844, 51.37748958656919],
              [1.44952089700732, 51.374641937360131],
              [1.448323427853712, 51.372039814115489],
              [1.448721626215283, 51.370894680047172],
              [1.448337184657518, 51.369509043992863],
              [1.445791690969084, 51.367629343567621],
              [1.445340192308583, 51.366382400420825],
              [1.445285710582402, 51.362998190879892],
              [1.445966083706333, 51.358494024116716],
              [1.445025490480676, 51.358270747666701],
              [1.445343849236634, 51.358721933229553],
              [1.44448325875843, 51.358779647792964],
              [1.443431837474317, 51.358113455641764],
              [1.443236521242383, 51.357310528695926],
              [1.443784768617194, 51.35630761754777],
              [1.442907434579605, 51.355548144823473],
              [1.443576613117385, 51.354280814040095],
              [1.44239336869846, 51.351817641400714],
              [1.442293935170504, 51.350013418358699],
              [1.441212643892089, 51.348773289558167],
              [1.43968390683256, 51.34848091115051],
              [1.438929598339767, 51.346216485576271],
              [1.437391656420616, 51.344687500951785],
              [1.436360323729134, 51.342672269714072],
              [1.433056547027641, 51.338537874211511],
              [1.429965498246113, 51.336224089598403],
              [1.426195093071745, 51.333461467628844],
              [1.42591429367718, 51.332084421626206],
              [1.424420859513445, 51.330465816896776],
              [1.424881910425481, 51.329890119936678],
              [1.424933577133793, 51.329061944521833],
              [1.423408432036339, 51.328229540761974],
              [1.422795352903137, 51.3274397038386],
              [1.424655358504325, 51.325985617614549],
              [1.422486433284935, 51.327321007810774],
              [1.422988622457279, 51.32822203329718],
              [1.424345205452047, 51.328758034985903],
              [1.424877001346827, 51.329458491836846],
              [1.423168342171185, 51.331365110243375],
              [1.421688961304261, 51.331147518708534],
              [1.42196349091747, 51.330920006590816],
              [1.421668704619095, 51.330779306320082],
              [1.420930974449008, 51.330937604719402],
              [1.419763959135638, 51.330132462661368],
              [1.419898019205092, 51.329908161230449],
              [1.419418358293775, 51.330080495711194],
              [1.419110553844524, 51.329764762491507],
              [1.420154011771505, 51.329046131467678],
              [1.419847839754302, 51.328867979570781],
              [1.418811811662522, 51.329589988871753],
              [1.41808114516588, 51.329167870549682],
              [1.419246344157361, 51.327897904745498],
              [1.419617571154193, 51.32764429342334],
              [1.421651153072917, 51.327653074347154],
              [1.419976783326441, 51.327398886693267],
              [1.41947957236365, 51.327643696611048],
              [1.418352267160709, 51.328508695287219],
              [1.414556863525823, 51.327477121427954],
              [1.414178536049313, 51.326405136081775],
              [1.415911972480334, 51.326122465556217],
              [1.414070856062395, 51.326057464228278],
              [1.414209924563467, 51.325089117908398],
              [1.415361272920056, 51.323871743442183],
              [1.416736945557321, 51.323854229231699],
              [1.422135054493996, 51.32378378766856],
              [1.423534688560066, 51.324414560853796],
              [1.422470956156755, 51.323780032654128],
              [1.422052113130159, 51.323746407764425],
              [1.416735312214297, 51.323844025059529],
              [1.411369668507698, 51.323903635585438],
              [1.409776868500696, 51.326118509406236],
              [1.409426084327398, 51.326247770464768],
              [1.409235473135213, 51.326871295856805],
              [1.404853364182362, 51.326112866997548],
              [1.404763784672245, 51.326337655321581],
              [1.400298502670609, 51.326063619732736],
              [1.393106121673693, 51.326459512673665],
              [1.391738074690899, 51.326656592152041],
              [1.391323498791275, 51.327011325117915],
              [1.387914413160118, 51.32713886704034],
              [1.379192149686304, 51.329654268051144],
              [1.37686890996783, 51.329594506066819],
              [1.374081084480246, 51.327354179964509],
              [1.374174580446939, 51.326613882060826],
              [1.372753830682464, 51.325937930255428],
              [1.371897651909049, 51.325852865446564],
              [1.369598767826068, 51.326435411306683],
              [1.366995387020189, 51.325058538970644],
              [1.365819161112312, 51.324974543354678],
              [1.365716214525221, 51.324000633517628],
              [1.361642240935995, 51.319611192175238],
              [1.361270034265901, 51.317388395406923],
              [1.363126044626916, 51.313564300692548],
              [1.362466573718133, 51.313437534138401],
              [1.361928900225661, 51.313798398412715],
              [1.360226990628917, 51.316139248536899],
              [1.361523680906963, 51.314007934463866],
              [1.360212864101638, 51.314611382006269],
              [1.358728203865872, 51.315036304530686],
              [1.358818439015382, 51.314798041826521],
              [1.360165144577478, 51.314508408998925],
              [1.362431438114907, 51.313369281373177],
              [1.360253976115509, 51.312798582143287],
              [1.358829937601496, 51.313482624245999],
              [1.358173744181621, 51.314441451125084],
              [1.35844448498654, 51.31355035855762],
              [1.360070579088182, 51.312802949751813],
              [1.357892567925473, 51.311477531674349],
              [1.351685965268064, 51.313023656761189],
              [1.350938108588843, 51.311703907049875],
              [1.346739264431262, 51.309052698453755],
              [1.346474107592442, 51.30813108855908],
              [1.345441739652112, 51.307986108342647],
              [1.343569337948766, 51.310359470648635],
              [1.341339490277701, 51.311510681219481],
              [1.339970091765063, 51.312693057570982],
              [1.335386692755713, 51.313812368225882],
              [1.331753436371216, 51.312934523494462],
              [1.330311071594831, 51.314361728092933],
              [1.325033524612289, 51.316962080604959],
              [1.313925152694303, 51.318119163144303],
              [1.310996318251686, 51.317095689873348],
              [1.309518477784204, 51.317084429210929],
              [1.299347153354814, 51.318652569611963],
              [1.295931978206975, 51.320179922620326],
              [1.290641545279952, 51.321413617848179],
              [1.284235165871208, 51.321479367803455],
              [1.282905252787335, 51.321229747951307],
              [1.281186129082911, 51.320293917987968],
              [1.276919054258094, 51.320081581375128],
              [1.273020920747787, 51.320654822961075],
              [1.270817260446461, 51.321721177070813],
              [1.267887931648142, 51.322314833989083],
              [1.263626320095419, 51.32218101346276],
              [1.258870732817536, 51.32313748778239],
              [1.256578682703951, 51.32401623745843],
              [1.252913061308846, 51.326178026997908],
              [1.251792216025116, 51.328901352413027],
              [1.250781881068227, 51.32943493624277],
              [1.24971902549941, 51.329388899459218],
              [1.247793549604182, 51.329712200729681],
              [1.246764467122589, 51.33020489295258],
              [1.244717514568704, 51.332142496528512],
              [1.244625601719988, 51.333138079702849],
              [1.242593633424791, 51.334762206147623],
              [1.237851485116181, 51.336116805750414],
              [1.231694457342085, 51.339061000729345],
              [1.230793822968423, 51.340098686581342],
              [1.229719080987691, 51.342540767121122],
              [1.227651938661981, 51.344637822019266],
              [1.227543303848014, 51.345482734309556],
              [1.228581413875538, 51.346846502613786],
              [1.227890690347514, 51.348176101281148],
              [1.225224245416734, 51.349292108778457],
              [1.223224967098203, 51.349642213590755],
              [1.22158710751994, 51.349473229893967],
              [1.22027333147198, 51.349910552487664],
              [1.220557766556955, 51.351528089319601],
              [1.220210175185394, 51.35236787408963],
              [1.217402647649635, 51.353833879674461],
              [1.21393755076155, 51.356538466895827],
              [1.210753256082322, 51.363610307313259],
              [1.211988286237465, 51.365530092217412],
              [1.21310074622623, 51.366149885033245],
              [1.21472693879915, 51.366390372136536],
              [1.217139409849857, 51.366286317281201],
              [1.220529332895783, 51.366739986848231],
              [1.228865900009498, 51.364548565935756],
              [1.230459345677979, 51.368363378431155],
              [1.229843002449408, 51.369018117967187],
              [1.226542308972407, 51.369121733631523],
              [1.22516832277182, 51.369527483873604],
              [1.224834703282588, 51.369973822316688],
              [1.225265509612621, 51.371665569206812],
              [1.225126990098131, 51.374191527325166],
              [1.224235740302041, 51.377254519156565],
              [1.224154663986139, 51.379330951111683],
              [1.235853124999853, 51.378741042411164],
              [1.25124981739984, 51.376838402690588],
              [1.255910235891704, 51.376809620150532],
              [1.263130768615303, 51.377674467872509],
              [1.26588540529395, 51.377927764199342],
              [1.273405531371936, 51.377588201707631],
              [1.276610587445163, 51.377687430405501],
              [1.280129082621363, 51.378189755881387],
              [1.285774884180305, 51.380436666268999],
              [1.286129044001455, 51.381346904871037],
              [1.28690340602555, 51.381902639195253],
              [1.290134188507812, 51.382776140013313],
              [1.294107321839357, 51.382416150845167],
              [1.296810795521013, 51.382591007077018],
              [1.299228290020673, 51.383166040009463],
              [1.301827636298927, 51.382034051851761],
              [1.30280511507384, 51.382034362838112],
              [1.30387601308277, 51.381605668756549],
              [1.308637324408388, 51.381304790834349],
              [1.31152353784601, 51.381641448080643],
              [1.313373805033162, 51.380591552144466],
              [1.314828481665239, 51.380333591679715],
              [1.315725699526819, 51.380615810507912],
              [1.315685716622871, 51.381295163775903],
              [1.316214596819172, 51.381671464222748],
              [1.318162599738682, 51.381993154963816],
              [1.31974889512552, 51.381924794787395],
              [1.324672639201413, 51.382367939045693],
              [1.327962992797768, 51.381932636936014],
              [1.328335036389684, 51.382186512661583],
              [1.328567819667962, 51.381987401173227],
              [1.331126553722771, 51.38246331465232],
              [1.33324041763616, 51.383423178573118],
              [1.333521813782403, 51.383833433713399],
              [1.33312802776353, 51.38547544019071],
              [1.334876563146239, 51.386351229374817],
              [1.340971719570216, 51.384829987386809],
              [1.345182714417448, 51.385131600742959],
              [1.34615476863407, 51.385863900158796],
              [1.348693087106711, 51.385877671852121],
              [1.350922662704527, 51.387056088483298],
              [1.352009422431981, 51.387251651352642],
              [1.353333394928076, 51.387132779285885],
              [1.355841907253795, 51.386227956499106],
              [1.358563250627613, 51.385678574073602],
              [1.363577299188072, 51.385773885041203],
              [1.3664732680717, 51.38619436511599],
              [1.367238373667423, 51.386844292188741],
              [1.370329670975109, 51.386889361645068],
              [1.372292070773789, 51.387222334878473],
              [1.372636884684344, 51.387551515976675],
              [1.372675838770955, 51.38729943098771],
              [1.374292964033038, 51.387413842631183],
              [1.37869670500067, 51.388236709262721],
              [1.378858920428312, 51.387784971334163],
              [1.379475136487997, 51.38937640618321],
              [1.38093193899701, 51.390142102213581],
              [1.3812489410628, 51.390658260330866],
              [1.380788877499023, 51.391204056724632],
              [1.379668645304954, 51.391298468010078],
              [1.378262298150981, 51.390651834377458],
              [1.377864777183379, 51.390739763172881],
              [1.378566579408045, 51.391249322339164],
              [1.380496465243782, 51.391449971198092],
              [1.381395001120418, 51.39115686744627],
              [1.384398685283005, 51.392619927202347],
              [1.388655357354805, 51.392825114861225],
              [1.389771802907952, 51.392815281330535],
              [1.390467935891542, 51.393207098838239],
              [1.391712866756641, 51.39262143651019],
              [1.395428981591978, 51.392516413976864],
              [1.39543697160446, 51.392313791994169],
              [1.402958604142964, 51.392282494111925],
              [1.405357405759808, 51.392251420199131],
              [1.407844512515801, 51.393175709696685],
              [1.408734861572813, 51.392310541148412],
              [1.41018640625093, 51.391993015154831],
              [1.41186095772596, 51.392141217445165],
              [1.418919771971385, 51.391630343678564],
              [1.422712548560766, 51.392399243150358],
              [1.423671588663287, 51.39330399981224],
              [1.424899258358505, 51.393781714890345],
              [1.426870185227458, 51.393219407793758],
              [1.428940006835915, 51.391942650624905],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000115",
        LAD13CDO: "29UP",
        LAD13NM: "Tonbridge and Malling",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [0.401254151274325, 51.352955414995805],
              [0.400936725631114, 51.349933837549926],
              [0.39731738313233, 51.345776582789213],
              [0.399890792710526, 51.344457595032893],
              [0.403934392831975, 51.344892674183463],
              [0.405401315513039, 51.34545521763075],
              [0.40706255863404, 51.346655880207159],
              [0.406685425982817, 51.347762629947376],
              [0.408686652951168, 51.347776342811834],
              [0.410974243518036, 51.348211314387036],
              [0.416610554213368, 51.347519551707876],
              [0.418565697394212, 51.347912670992443],
              [0.420856519105447, 51.347262772359706],
              [0.426625585327789, 51.34691402898175],
              [0.431261988832807, 51.345001174722007],
              [0.444392911436877, 51.342137672645983],
              [0.449614730990934, 51.34093943278743],
              [0.453456905560577, 51.340610767998882],
              [0.456939530176742, 51.336487060441506],
              [0.457133245847993, 51.335625912360193],
              [0.456666902347186, 51.335228291305086],
              [0.454569266813451, 51.33466342328893],
              [0.452154053625195, 51.334253562700738],
              [0.451901725048746, 51.334421629771001],
              [0.450966074334481, 51.333783793490682],
              [0.450338176216218, 51.332536044422618],
              [0.45031893830123, 51.329913936112348],
              [0.451320718156424, 51.328940551752922],
              [0.45239840003896, 51.328529462735958],
              [0.455062151892993, 51.32851860379764],
              [0.456525698002816, 51.32904280618299],
              [0.457894596621532, 51.330614925508939],
              [0.459110986741783, 51.330569597545846],
              [0.459285529097713, 51.329887818778886],
              [0.456629469789706, 51.328674591995743],
              [0.455162972768108, 51.327073926896333],
              [0.456666783960368, 51.325586328059387],
              [0.455880473094038, 51.325189122242584],
              [0.459867431052908, 51.325740364659531],
              [0.459701038343819, 51.324321082035503],
              [0.4572018389758, 51.321740253470324],
              [0.456196626373029, 51.3185614441852],
              [0.456258364403808, 51.317541179981866],
              [0.456732585267717, 51.317122922124064],
              [0.455973912581001, 51.316998541407415],
              [0.456562617783106, 51.316359339100856],
              [0.454558391947266, 51.315699882176879],
              [0.455129730484426, 51.315622248565951],
              [0.456671258722494, 51.316270721235824],
              [0.456302225904225, 51.316911610682787],
              [0.45766196162472, 51.316923544031646],
              [0.458106125289617, 51.316399787515209],
              [0.457390260753571, 51.314928182459504],
              [0.457508623117532, 51.312927330074601],
              [0.460207979113511, 51.309334363426125],
              [0.461217241661525, 51.306394735344618],
              [0.46239670575275, 51.304439010518976],
              [0.463623253784533, 51.303310589994183],
              [0.466392523500699, 51.303047219544482],
              [0.46786045092422, 51.301901076057256],
              [0.469101074187852, 51.301519666510771],
              [0.470472453875608, 51.301649917293787],
              [0.472709917568281, 51.302465158353613],
              [0.474135428621345, 51.302080688764519],
              [0.475480075003963, 51.302193458910196],
              [0.477528936071404, 51.303047672058383],
              [0.478827516467005, 51.303156882185306],
              [0.479891643156114, 51.30274941642508],
              [0.482406643833982, 51.300568219367143],
              [0.485807441291533, 51.299412315852599],
              [0.48713933115567, 51.299475757623377],
              [0.491364658244823, 51.300753829144028],
              [0.49258858705242, 51.300862676420756],
              [0.495466017712695, 51.299504485271626],
              [0.498701088853207, 51.296519733037279],
              [0.493633305976255, 51.291366534679817],
              [0.489449307087486, 51.289140641053599],
              [0.488594482733374, 51.286860047533921],
              [0.482654789365444, 51.28116011998646],
              [0.480070513098563, 51.278043758069316],
              [0.479221095390461, 51.277581495598199],
              [0.480645009087812, 51.276011611781477],
              [0.477161690966093, 51.273445764222181],
              [0.478016970418147, 51.27276662472164],
              [0.47762642307441, 51.272276646453342],
              [0.476668098009775, 51.272692651797975],
              [0.474622815605585, 51.272626196745534],
              [0.471065023101303, 51.271199439657188],
              [0.469487334033872, 51.271188674273468],
              [0.467207891640148, 51.271700824220972],
              [0.466823435068311, 51.271322203225267],
              [0.461960321595658, 51.271458789262269],
              [0.460030360355231, 51.271778177345432],
              [0.455365934752125, 51.2715550643792],
              [0.452976860963282, 51.271842596949966],
              [0.452651411201422, 51.271331386805308],
              [0.449705721507348, 51.270373211919697],
              [0.445046633966276, 51.270057842279172],
              [0.442315726379867, 51.2702794506137],
              [0.440173716549241, 51.269926618026332],
              [0.432496563942638, 51.270121521648186],
              [0.432377980426772, 51.268935036007242],
              [0.432711031162443, 51.268943402129679],
              [0.432022463802998, 51.267849705583821],
              [0.431746811655218, 51.264801215215343],
              [0.433141334040574, 51.26216318996137],
              [0.433261890554981, 51.260619182680394],
              [0.432848798293517, 51.258388368594694],
              [0.431638798467282, 51.257079770170662],
              [0.431298485887683, 51.254487694207036],
              [0.429945897275867, 51.254576047762455],
              [0.42944862446726, 51.252320888074074],
              [0.429797300476987, 51.249502250726032],
              [0.431247006026818, 51.24935611951269],
              [0.431283742360786, 51.249101736717037],
              [0.430195164059657, 51.248975956803719],
              [0.426739323055526, 51.249532436839786],
              [0.423615419006036, 51.24942630662931],
              [0.422113932683962, 51.249105748501115],
              [0.421073341088315, 51.249456449740677],
              [0.420823977908817, 51.249947262616075],
              [0.417710986058427, 51.250786873059724],
              [0.411416812512112, 51.251415899907194],
              [0.410777013018018, 51.252663899956296],
              [0.409979726759537, 51.253121008195187],
              [0.409549907318363, 51.253013843008638],
              [0.408186809852348, 51.252325117629063],
              [0.406781382556432, 51.252224526695102],
              [0.402738882880634, 51.248933224001242],
              [0.399632385638464, 51.247673122548974],
              [0.399565007107787, 51.24717716076826],
              [0.397548642314728, 51.24571387315595],
              [0.39793361986745, 51.244713095517],
              [0.394164873737668, 51.241425784296162],
              [0.388250298288464, 51.238687612766547],
              [0.385545781641519, 51.238169898089232],
              [0.383652772256629, 51.238300214012078],
              [0.383136922376713, 51.236857365581841],
              [0.381512958014729, 51.23589934415066],
              [0.378632201496144, 51.234987543800216],
              [0.378147593306061, 51.233424424783905],
              [0.380406195281406, 51.231083339714999],
              [0.381393588022055, 51.231701787645925],
              [0.382003175273462, 51.230968095281916],
              [0.38326685545315, 51.231111438558685],
              [0.385478512804582, 51.229974546385144],
              [0.38622208273093, 51.229949489698079],
              [0.386793742327067, 51.229398214941256],
              [0.388887225590299, 51.229080283228257],
              [0.389467613904588, 51.228532414207884],
              [0.391007375565271, 51.228006309167306],
              [0.391917359217718, 51.226661156164674],
              [0.392896874389196, 51.226105108318336],
              [0.393677006079837, 51.225957842620367],
              [0.395666729367379, 51.226734630362522],
              [0.397399513777181, 51.226507570789153],
              [0.399186379812472, 51.225640832005901],
              [0.399295095372711, 51.224812092154458],
              [0.398823494774896, 51.224446730899302],
              [0.398631280226512, 51.223366945308037],
              [0.399776151982283, 51.222963936314656],
              [0.399947421071051, 51.22253952299284],
              [0.401850334529775, 51.222062481292213],
              [0.404732403823668, 51.222401689015527],
              [0.407330265761548, 51.222288900665205],
              [0.408773122640737, 51.221670119417766],
              [0.406486963571401, 51.217901186280017],
              [0.404814153665463, 51.214133991239692],
              [0.403974217835629, 51.211067348327326],
              [0.404071598616615, 51.208401439894736],
              [0.403481409214823, 51.205453770604194],
              [0.402231443604183, 51.201847826721341],
              [0.401671127908522, 51.201424042063046],
              [0.401639756667002, 51.20060087089],
              [0.400316141195395, 51.198573899128597],
              [0.399990148729442, 51.198416005495403],
              [0.399639810629594, 51.19900148323547],
              [0.395748457920315, 51.200038174252782],
              [0.394110534719817, 51.199287469229539],
              [0.393975062555989, 51.199573540763453],
              [0.39226080472036, 51.199917994941117],
              [0.392001791882726, 51.200417041097111],
              [0.390757597664109, 51.201076527890137],
              [0.387955882923188, 51.202069094891968],
              [0.386596201787008, 51.201521255708521],
              [0.385871038719999, 51.200591722318464],
              [0.384704265820084, 51.200257571352118],
              [0.384751843974569, 51.198823022244852],
              [0.38422623305839, 51.198263543649013],
              [0.38387641059962, 51.198613330010055],
              [0.38246396618034, 51.198759924986383],
              [0.380801480298466, 51.19799964253783],
              [0.37781923410627, 51.198739324103798],
              [0.376865430792975, 51.198647190025262],
              [0.375999780188263, 51.199322208902906],
              [0.374828676704837, 51.198957469810367],
              [0.374247567540123, 51.199153635495364],
              [0.373055504500244, 51.198993458464791],
              [0.372723984135242, 51.1990883211542],
              [0.373188036278918, 51.199873039617039],
              [0.37113174855297, 51.201142365738221],
              [0.369139170174598, 51.201618930077359],
              [0.368694225707514, 51.200675522298972],
              [0.366505795379747, 51.200319605915318],
              [0.366877570248474, 51.197798380407065],
              [0.367532919353791, 51.197514410419643],
              [0.367877351711075, 51.196694418907072],
              [0.367484849811525, 51.194873970334314],
              [0.364927859160418, 51.194079404529525],
              [0.363848491428799, 51.193964521834388],
              [0.363407180757511, 51.194263033175623],
              [0.362432297246654, 51.194064184567274],
              [0.361513111111776, 51.194702404007671],
              [0.360422299978174, 51.194783780451552],
              [0.360436477920878, 51.195537155228941],
              [0.357810494766284, 51.195323911540122],
              [0.356750129624693, 51.196086351719629],
              [0.354721902909641, 51.195778251778613],
              [0.35411774458763, 51.196056622525461],
              [0.353087611834629, 51.195953240349326],
              [0.352346273504008, 51.196320699687007],
              [0.35181355224772, 51.195444648001917],
              [0.351052385364433, 51.195141578749805],
              [0.350363065759764, 51.195209393947749],
              [0.348596940412654, 51.194537078332466],
              [0.346974462562022, 51.194915897935843],
              [0.346459346152383, 51.194468461515847],
              [0.345099923342054, 51.194390501364751],
              [0.344225292831413, 51.193627394658435],
              [0.341658081031771, 51.192791157432758],
              [0.341386448171804, 51.193107772028341],
              [0.339415325943816, 51.193328883681829],
              [0.338668342429202, 51.193953584031441],
              [0.337454394698518, 51.193525475733537],
              [0.336225649202734, 51.194784837712106],
              [0.335076581396693, 51.19467737730961],
              [0.334530853860705, 51.19506599935788],
              [0.334916070819062, 51.195805672462612],
              [0.334093087001276, 51.195466854045875],
              [0.332757141273092, 51.195542071097378],
              [0.332564030946745, 51.195912858297433],
              [0.333668086094811, 51.197140036560597],
              [0.333292030643169, 51.197459614289905],
              [0.332471001764469, 51.197244856063975],
              [0.332304412491886, 51.197885818903451],
              [0.330567962917468, 51.198166853205329],
              [0.330464721514661, 51.198758885247862],
              [0.329104319214713, 51.199089063649012],
              [0.328262849071429, 51.19866783196322],
              [0.325996633322042, 51.1985780167917],
              [0.322251738137216, 51.199228011755707],
              [0.32169540221171, 51.198981841406962],
              [0.319756792994809, 51.199600385812481],
              [0.317213286197907, 51.198207344466084],
              [0.316261020628104, 51.198513997714166],
              [0.315074852211431, 51.198181338194935],
              [0.314606967431638, 51.19875628895845],
              [0.311664570795252, 51.198912506086998],
              [0.31135736737427, 51.199295404396359],
              [0.309427189544667, 51.19994238893117],
              [0.308677194183824, 51.199915824232143],
              [0.308621232683067, 51.199197449541018],
              [0.305991245782589, 51.197355299416806],
              [0.303803832267536, 51.197637630068442],
              [0.299659612307345, 51.19699970273016],
              [0.298012384350158, 51.195222588460247],
              [0.298385219132524, 51.194632485192479],
              [0.297917336401572, 51.193485116311145],
              [0.298060916901616, 51.192314941111611],
              [0.29604494893183, 51.190370570285019],
              [0.296107732836691, 51.189906173273172],
              [0.29519192272558, 51.189736184424582],
              [0.294737994328062, 51.189212676932968],
              [0.293569296703026, 51.189310246505677],
              [0.292250543556792, 51.186441996509103],
              [0.290708096655968, 51.185205933975332],
              [0.291201237778595, 51.184790668159394],
              [0.290162468717516, 51.183696720332037],
              [0.289621110146573, 51.183370063927399],
              [0.287536281268681, 51.183660878408944],
              [0.283960667453642, 51.18034021692263],
              [0.283232267444561, 51.179411922566608],
              [0.284360473786632, 51.178094829699788],
              [0.283194284763867, 51.17783250909941],
              [0.282281195118955, 51.17690061957321],
              [0.280414071979904, 51.176524240637804],
              [0.279314494127664, 51.17592872335134],
              [0.277813798293663, 51.175322122791655],
              [0.277277891793649, 51.174695821002167],
              [0.274908876654303, 51.175194299382625],
              [0.272931309316489, 51.176467554312168],
              [0.267517670101453, 51.178952313156152],
              [0.265228090575834, 51.179735044581186],
              [0.262212804321094, 51.180017344328199],
              [0.258844000615368, 51.180181385374304],
              [0.257918086230639, 51.180031986522735],
              [0.257878485336254, 51.179690100684446],
              [0.256245350190327, 51.179796263566153],
              [0.255515373107586, 51.179058491708545],
              [0.256103650046786, 51.178593875292997],
              [0.253881164844766, 51.17884897249634],
              [0.252283580221831, 51.179625305759664],
              [0.244346330545876, 51.182003763148586],
              [0.242339174883972, 51.181982905505038],
              [0.240430433676559, 51.181453798536815],
              [0.240570433720907, 51.182334270689246],
              [0.239384431643887, 51.182191506065358],
              [0.239272006280795, 51.182676605266025],
              [0.236598933755375, 51.183591120973134],
              [0.233606111942585, 51.18514571680133],
              [0.231254694504088, 51.185110556944515],
              [0.229653377443879, 51.182916125703009],
              [0.226934297637547, 51.184156857134127],
              [0.228772207353539, 51.186006865937301],
              [0.229086980056843, 51.188822996299187],
              [0.228694573741199, 51.190656133542298],
              [0.229513524943599, 51.191225996301007],
              [0.229038794828384, 51.192124490929835],
              [0.231199724895973, 51.191971772151724],
              [0.231558742190976, 51.193152051535108],
              [0.23228726568747, 51.193448422429476],
              [0.235061624239135, 51.192898107120904],
              [0.235841906415448, 51.192282435343564],
              [0.237381769380646, 51.192368106100176],
              [0.238998252530385, 51.191967545332929],
              [0.239554137431498, 51.192126878564146],
              [0.239739533122427, 51.193028065432436],
              [0.245365800840317, 51.191909336213513],
              [0.244669534217182, 51.19422141654772],
              [0.246737760970798, 51.194250957445604],
              [0.247085543983131, 51.194544656727132],
              [0.246009772392609, 51.194880087889139],
              [0.245818871505016, 51.195369397611181],
              [0.247321634606389, 51.195072531074914],
              [0.247877803388158, 51.195236316343767],
              [0.248049314367035, 51.195613441604081],
              [0.247194321449974, 51.196368229852943],
              [0.248284561700168, 51.197005597260265],
              [0.248135257010374, 51.197731537266286],
              [0.249275775321011, 51.19793084943111],
              [0.250107505718726, 51.197726890554527],
              [0.250529806162651, 51.198226894161003],
              [0.250955610733267, 51.198446234313217],
              [0.250848226957978, 51.200383683176916],
              [0.250081111590164, 51.201708781568477],
              [0.24957891130998, 51.201831757549819],
              [0.248325982728118, 51.201589641404681],
              [0.247486478648822, 51.200665063852767],
              [0.245347631605522, 51.199778019733593],
              [0.243197142665787, 51.199445153809606],
              [0.241794525462147, 51.198623965630624],
              [0.241259008885811, 51.19888604287857],
              [0.24086094036466, 51.198676928439475],
              [0.240516817020697, 51.199018974170038],
              [0.2400070900475, 51.198721162177641],
              [0.238256762718089, 51.199790706786928],
              [0.238740796950114, 51.200621427128624],
              [0.237760077741003, 51.200943270069459],
              [0.236341981983105, 51.202195289841917],
              [0.235329970497073, 51.202315358974964],
              [0.231546720662369, 51.201463072869998],
              [0.228451209703445, 51.20168846244686],
              [0.226451803947316, 51.203389410314067],
              [0.226284960016361, 51.205273100294974],
              [0.225326580340465, 51.207309447974566],
              [0.223704916224083, 51.206635205467862],
              [0.222309582095232, 51.206557398158957],
              [0.219276356136251, 51.205703953906585],
              [0.218836801403842, 51.206000978963175],
              [0.218213282340697, 51.205866212135163],
              [0.217627650083308, 51.206162405095569],
              [0.21781123613985, 51.206705720986996],
              [0.21702444689721, 51.207732387620304],
              [0.21570927778295, 51.207833748542008],
              [0.215321718381113, 51.207335661889609],
              [0.214128203976373, 51.208152370838988],
              [0.212529091693972, 51.208841838798207],
              [0.211128417043414, 51.208892603610828],
              [0.208882761314902, 51.210344284597014],
              [0.207889471022785, 51.210591466296734],
              [0.207108654419028, 51.211988476544725],
              [0.204840154193146, 51.21404665904555],
              [0.204256090694613, 51.216178292699773],
              [0.202348679187399, 51.217227763105576],
              [0.201436209569418, 51.218242296244426],
              [0.201918916974867, 51.220555288111434],
              [0.20519943428658, 51.221145516710074],
              [0.20729373638299, 51.221845274711157],
              [0.211398288390263, 51.221875684338251],
              [0.212221417106926, 51.222853886225465],
              [0.21162979361983, 51.223747317088161],
              [0.212312291801966, 51.224599571986467],
              [0.209253429713287, 51.225833698698743],
              [0.208746350218533, 51.225712869399395],
              [0.206483313334346, 51.227118956944295],
              [0.204732545082005, 51.227768003192153],
              [0.204280507707947, 51.227690184528107],
              [0.203419775716313, 51.228291862052949],
              [0.203038192703163, 51.229449292072928],
              [0.200914008509602, 51.231491178867898],
              [0.200515201198789, 51.233760495699386],
              [0.208094527689906, 51.235852545556156],
              [0.210058667196, 51.23675076768081],
              [0.214070489252026, 51.239088729449662],
              [0.21591996296679, 51.241083510193903],
              [0.219069137905532, 51.243375582438212],
              [0.223056053294733, 51.244907911764933],
              [0.223880884195587, 51.243828329252146],
              [0.224849420211335, 51.243470869931684],
              [0.224854830626244, 51.241682894645173],
              [0.224401737703067, 51.240755303816769],
              [0.225412074407489, 51.238950018326946],
              [0.229428702782938, 51.23336693738873],
              [0.230935233642751, 51.2322320408772],
              [0.241154496599314, 51.236977690146169],
              [0.246985493338008, 51.237687851187673],
              [0.248046782495488, 51.238563204203452],
              [0.248130539996769, 51.239193828012851],
              [0.247310531152587, 51.240006391489601],
              [0.24768002402382, 51.242324975736494],
              [0.249082457963756, 51.242906878118191],
              [0.250350055643699, 51.245656956500895],
              [0.250418162930832, 51.247022636029776],
              [0.248994045345876, 51.249288459468566],
              [0.246427838688362, 51.25188196744319],
              [0.24730514013871, 51.255296080954771],
              [0.249786742979719, 51.255311351823742],
              [0.252480453601509, 51.254622805532222],
              [0.253548396668833, 51.254774017592482],
              [0.254450976643204, 51.254562376621564],
              [0.259127776095706, 51.254949731354891],
              [0.261258846812308, 51.254451709249324],
              [0.262251059972824, 51.255366042526418],
              [0.263813876774827, 51.255787286786671],
              [0.264567211035686, 51.257001203678229],
              [0.265136474624111, 51.260067712323043],
              [0.264629574467322, 51.261973320911466],
              [0.265225339221454, 51.265431424432386],
              [0.26487084911036, 51.267726191901268],
              [0.263118399404401, 51.269201724684962],
              [0.260479901484547, 51.270211404771111],
              [0.260458220838352, 51.272310867140604],
              [0.259411837591251, 51.273864434418243],
              [0.258216702473483, 51.274871369545821],
              [0.255143556111198, 51.276412721843393],
              [0.256373124216546, 51.27853483021682],
              [0.257723965483555, 51.27956009998703],
              [0.258251277315639, 51.281247865576184],
              [0.25799643073201, 51.28379968861482],
              [0.258547370078963, 51.285880004099248],
              [0.261329305221049, 51.289143026053708],
              [0.261178842373209, 51.291510273304489],
              [0.260133895343664, 51.293013449629939],
              [0.260989429955881, 51.294924192803052],
              [0.259817139426636, 51.295121299513291],
              [0.25951565910392, 51.295729670153449],
              [0.258426182739637, 51.297293044502347],
              [0.264639102016052, 51.297061499488258],
              [0.265320726200695, 51.301553055799523],
              [0.26327057716374, 51.303564040106693],
              [0.263658921125573, 51.305303928379402],
              [0.263000750028645, 51.30589492152442],
              [0.262998795858681, 51.306759214403982],
              [0.263678510516157, 51.307101308349992],
              [0.264343775365837, 51.308577731619245],
              [0.264963133821761, 51.311801535357617],
              [0.265808643037246, 51.311778879598279],
              [0.265116760797998, 51.313638589032252],
              [0.267871978090363, 51.314132941823004],
              [0.268128282039391, 51.315226956220464],
              [0.267490261571469, 51.315529797752404],
              [0.272386508093085, 51.317412492606429],
              [0.275257761008612, 51.317896327159403],
              [0.276428782599201, 51.319170397126925],
              [0.277027006516391, 51.318730683558918],
              [0.278536351423344, 51.320574622556414],
              [0.280647868323893, 51.321766492009758],
              [0.2815982464335, 51.323332594229711],
              [0.284447723972189, 51.324899423112008],
              [0.284980896222698, 51.327366671613696],
              [0.284768389301129, 51.329790912813337],
              [0.286772156276502, 51.334113541394302],
              [0.288319672965709, 51.335811818979202],
              [0.288432013560797, 51.337289018857447],
              [0.288317660823562, 51.339576446545792],
              [0.285354947980737, 51.34005519052954],
              [0.285164030615402, 51.341874658451893],
              [0.285738624415187, 51.344995800866286],
              [0.288437003186694, 51.345257865391837],
              [0.290414976872219, 51.345096887033549],
              [0.291504108624783, 51.345368760820577],
              [0.293969851565679, 51.349194789561579],
              [0.296294116414799, 51.351877798064528],
              [0.304646575048367, 51.350807202227031],
              [0.308045144298306, 51.349132269785159],
              [0.308650787706998, 51.34855285470708],
              [0.307460583242412, 51.346244350551352],
              [0.306861726275685, 51.343127380155572],
              [0.308152913927774, 51.34278986950001],
              [0.312457114092309, 51.340978235764204],
              [0.314005365054701, 51.339788412678516],
              [0.316586152929686, 51.338746320826402],
              [0.317412165061804, 51.337815355651863],
              [0.321854156922196, 51.3366157857525],
              [0.327943929209985, 51.333599018231169],
              [0.328285661086793, 51.333061616337595],
              [0.329594948918096, 51.332381758332019],
              [0.334670758432157, 51.331099833935959],
              [0.337302611939318, 51.329781961222736],
              [0.34157613967938, 51.329217228450396],
              [0.341053036698094, 51.325007100133618],
              [0.351496814383686, 51.32682851864886],
              [0.354789151208345, 51.326817209163174],
              [0.354953698799691, 51.328459689815062],
              [0.356275109793987, 51.328502353708004],
              [0.35783719302064, 51.329122928090136],
              [0.358703534973819, 51.328269992557395],
              [0.36048587810098, 51.328538949338913],
              [0.360733706237659, 51.328361279548652],
              [0.361120445952485, 51.328596302937072],
              [0.361125138369072, 51.329665523047673],
              [0.363276652737545, 51.329993543714501],
              [0.36347735509253, 51.329541621323827],
              [0.365376448251608, 51.329371967398323],
              [0.365560159241834, 51.328311531133842],
              [0.366154900831812, 51.328459596121895],
              [0.377777779685836, 51.32928445643811],
              [0.381126309937947, 51.330215575188511],
              [0.38343011634882, 51.335773401562292],
              [0.381903625586049, 51.336155207293722],
              [0.377109846371437, 51.335853318448123],
              [0.380697199579046, 51.339333732357844],
              [0.38206303919961, 51.338869774245254],
              [0.383401252252165, 51.338953161928067],
              [0.384491651857668, 51.338688139816732],
              [0.38708089148228, 51.33891777703024],
              [0.388849342751891, 51.33877019322366],
              [0.390103424129781, 51.341789774470904],
              [0.39190451896748, 51.343783712119325],
              [0.392848492282326, 51.343931703747067],
              [0.395025776480852, 51.345121988491037],
              [0.394186358998422, 51.345878413458188],
              [0.392349767335727, 51.346183953522413],
              [0.390994407127142, 51.346796207800139],
              [0.391534972784944, 51.347469568031613],
              [0.396526499401534, 51.35004935825868],
              [0.397072007603324, 51.352685855492531],
              [0.39854893001894, 51.352501827009171],
              [0.401254151274325, 51.352955414995805],
            ],
          ],
          [
            [
              [0.472736898339738, 51.361814847700501],
              [0.475652010061142, 51.36022256186817],
              [0.475964236001445, 51.360551419390127],
              [0.479097516461292, 51.358427412193713],
              [0.479628985359401, 51.357443059794555],
              [0.481779399531425, 51.357683500478288],
              [0.487618400301233, 51.356010921142143],
              [0.488971178439841, 51.355210529708479],
              [0.489455794462931, 51.354346740393659],
              [0.491217818973915, 51.35402237377],
              [0.494040597049978, 51.352654623930654],
              [0.498867827248171, 51.353681303164933],
              [0.503062502974471, 51.354667346088497],
              [0.503116822498736, 51.353134579701731],
              [0.501959671042028, 51.351430744797881],
              [0.503067418446969, 51.349251310671541],
              [0.502188526639254, 51.348804227585596],
              [0.502976900345273, 51.34764339504747],
              [0.505816419264978, 51.342937475970594],
              [0.507545024751738, 51.343299784932832],
              [0.511130914047491, 51.340913379679016],
              [0.511833427989336, 51.340945977039553],
              [0.514107813944442, 51.341127399431222],
              [0.514776666288846, 51.340466395572456],
              [0.516641160082161, 51.339666375153385],
              [0.519302267439261, 51.339935615060881],
              [0.519522844291194, 51.339166411517446],
              [0.519929056843767, 51.339190046627294],
              [0.520327292013435, 51.3382308497346],
              [0.521706760462063, 51.337465469282527],
              [0.522227893471435, 51.336418178844326],
              [0.523761931215109, 51.335880579559685],
              [0.524463461032974, 51.335138770487873],
              [0.525611865160395, 51.334717384200538],
              [0.526046500378896, 51.332804055784216],
              [0.523949578562438, 51.331560492234267],
              [0.521240938532978, 51.329186976413041],
              [0.523307950671197, 51.326281569330362],
              [0.526010365475756, 51.326166632005396],
              [0.526740697124753, 51.325247010624324],
              [0.524388632787013, 51.323376709648151],
              [0.522631872089699, 51.321104111339352],
              [0.521046859469781, 51.320756025485331],
              [0.51895775319382, 51.319066122853364],
              [0.517070795110586, 51.316399624980171],
              [0.513919915004923, 51.314313355908723],
              [0.513594772018658, 51.310124805006289],
              [0.512243149778909, 51.308577235311517],
              [0.51254629991473, 51.307533761709777],
              [0.511586650219645, 51.306435546959939],
              [0.510268237319389, 51.306570852368736],
              [0.50702535334811, 51.305357858885628],
              [0.502061805741096, 51.30534804313649],
              [0.49800189361738, 51.306574246162995],
              [0.49448693164433, 51.305867658002654],
              [0.492489545024628, 51.304568358198999],
              [0.494627296097432, 51.303217844070296],
              [0.492742783381603, 51.3018019243246],
              [0.492664035325227, 51.301354819597762],
              [0.497843524618228, 51.299219938794742],
              [0.49892986627874, 51.29838102841024],
              [0.498993887099421, 51.296733826149918],
              [0.496566168972134, 51.298971985658476],
              [0.496648097785341, 51.299371354078005],
              [0.49309121210042, 51.301057026595856],
              [0.491512902809482, 51.301159883491898],
              [0.487264023363339, 51.299871525224752],
              [0.485666049984568, 51.299742686056462],
              [0.482920375655847, 51.300730000373939],
              [0.479842313789787, 51.303484339325024],
              [0.47753755283417, 51.303420723961004],
              [0.474741291986818, 51.302461800589008],
              [0.472893187255786, 51.30291186562453],
              [0.470303427562273, 51.301975455028298],
              [0.468704308644209, 51.302012789437619],
              [0.466713482909592, 51.303440670711673],
              [0.46404115286932, 51.303666033715452],
              [0.463438387964681, 51.30395752133694],
              [0.461353514820476, 51.308002618119538],
              [0.460083863134782, 51.311037654537031],
              [0.458751590728635, 51.31271686217535],
              [0.460270769611863, 51.314450379848921],
              [0.459446696910872, 51.314256347216457],
              [0.458777773764757, 51.313045476435839],
              [0.458262551780611, 51.313232570534119],
              [0.458074745102504, 51.31401445670884],
              [0.459111786164252, 51.316178110755132],
              [0.457001036940465, 51.318037328273775],
              [0.458897426771112, 51.317000127334673],
              [0.456950223172335, 51.3181607069751],
              [0.45696263110494, 51.318473422030053],
              [0.457505440376615, 51.318538362363668],
              [0.459151633789423, 51.318237696566122],
              [0.459506413996845, 51.318430799726443],
              [0.457764856633624, 51.318569397884055],
              [0.457004006545578, 51.318629940750078],
              [0.457733080231878, 51.320696643203512],
              [0.459125589416084, 51.320621534283276],
              [0.460961330770174, 51.321506597097958],
              [0.462730636126414, 51.321311104798454],
              [0.462699184583773, 51.320161490959364],
              [0.463024413282829, 51.320231094212687],
              [0.46362628160374, 51.318954835484881],
              [0.464057283560823, 51.318855829059721],
              [0.463036166345121, 51.318736118058574],
              [0.461375872099805, 51.319625424921369],
              [0.46318074260499, 51.318541513459706],
              [0.462033990668768, 51.318684120612843],
              [0.461196138604799, 51.318788306440837],
              [0.46019258736383, 51.318138481144835],
              [0.459748838876307, 51.318589389019046],
              [0.460318360679274, 51.319308597173794],
              [0.459601163200123, 51.318699515001036],
              [0.459671875046041, 51.318197088518481],
              [0.46027771973048, 51.318066542354231],
              [0.461186066147422, 51.318680595555598],
              [0.462382524724977, 51.318597174784117],
              [0.463784986403907, 51.318247300024296],
              [0.463311229860697, 51.318003651233141],
              [0.463918737066914, 51.318118575271008],
              [0.464495307142819, 51.318753975147303],
              [0.463516668747001, 51.320310672283533],
              [0.463250671204989, 51.321641019812439],
              [0.460737048259576, 51.322062616053174],
              [0.458788396009532, 51.321133154659279],
              [0.458177907282202, 51.321392397932613],
              [0.458523558235648, 51.322004795057389],
              [0.460589608264913, 51.323330207775555],
              [0.460973623529795, 51.325569623270241],
              [0.460688245249826, 51.326137718149567],
              [0.459837095778091, 51.326380442877792],
              [0.45708392856681, 51.326300654649323],
              [0.45640365152531, 51.326652187221775],
              [0.456379251505954, 51.327135652045015],
              [0.456478818638103, 51.327360200109617],
              [0.45732706967539, 51.32722368469576],
              [0.456539308684091, 51.327471350112937],
              [0.457720936747358, 51.328361193816946],
              [0.460065382726744, 51.329062916133758],
              [0.45957615003617, 51.329148740572123],
              [0.460371661348667, 51.330171678993331],
              [0.460009780895118, 51.330920350569599],
              [0.458865324693226, 51.331290642422367],
              [0.457199183478738, 51.331060312546818],
              [0.455035216544062, 51.329277323320518],
              [0.452830488325857, 51.329363104804351],
              [0.451419962393775, 51.330074356082179],
              [0.45153489617048, 51.333224179348598],
              [0.452504249130499, 51.33360588914649],
              [0.453406493807017, 51.33342960009557],
              [0.457007612201162, 51.334538533383437],
              [0.457909672383041, 51.335056513898934],
              [0.458116075255071, 51.335989306388775],
              [0.454709446708492, 51.340887597628992],
              [0.453247692956502, 51.342151243600078],
              [0.452969436632746, 51.343232697076246],
              [0.453375114774655, 51.344644273101515],
              [0.452874666452015, 51.345814020646813],
              [0.453202559549271, 51.345930362639805],
              [0.44990696919107, 51.347575921116842],
              [0.449536630949103, 51.348063921624195],
              [0.45523376417388, 51.349352989028063],
              [0.45742361194112, 51.351252349208799],
              [0.457609611357323, 51.352151393797982],
              [0.455854442288205, 51.355694782634757],
              [0.454890784466683, 51.356637720798197],
              [0.452024375757736, 51.357967655444497],
              [0.45082341882852, 51.358988374477896],
              [0.450195419268569, 51.363504552727164],
              [0.4504473376836, 51.364993998261355],
              [0.451624526067606, 51.366134016866908],
              [0.456944182173135, 51.368057762271256],
              [0.466650887419903, 51.364180778588725],
              [0.470362904184183, 51.36222738863772],
              [0.472736898339738, 51.361814847700501],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000116",
        LAD13CDO: "29UQ",
        LAD13NM: "Tunbridge Wells",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.372723984135242, 51.1990883211542],
            [0.373055504500244, 51.198993458464791],
            [0.374247567540123, 51.199153635495364],
            [0.374828676704837, 51.198957469810367],
            [0.375999780188263, 51.199322208902906],
            [0.376865430792975, 51.198647190025262],
            [0.37781923410627, 51.198739324103798],
            [0.380801480298466, 51.19799964253783],
            [0.38246396618034, 51.198759924986383],
            [0.38387641059962, 51.198613330010055],
            [0.38422623305839, 51.198263543649013],
            [0.384238212558574, 51.1969709195793],
            [0.383260399183942, 51.19619131711422],
            [0.383516192040654, 51.195462120040197],
            [0.386650758791595, 51.194462864741894],
            [0.388814157509386, 51.194613860299555],
            [0.391616191779345, 51.194133897625044],
            [0.393957961348054, 51.194495198486287],
            [0.394196653244999, 51.192777028791511],
            [0.396006445270721, 51.192044760449342],
            [0.399860438588459, 51.192147414470469],
            [0.401293492461644, 51.191273498899605],
            [0.404078650177783, 51.190695550649487],
            [0.403396959345364, 51.189394691941338],
            [0.404447070074978, 51.188562780932777],
            [0.407618476947309, 51.188150382330527],
            [0.408094468345596, 51.188743156523671],
            [0.409373983886215, 51.188442497893973],
            [0.410001879979678, 51.186926722565808],
            [0.409389421044407, 51.186269315136926],
            [0.409400536173995, 51.185439871860837],
            [0.412241282993269, 51.185140282610377],
            [0.411777458227254, 51.183300753656987],
            [0.414179297819883, 51.182354529061008],
            [0.416371547443356, 51.180549193347517],
            [0.431054293614901, 51.179600941683852],
            [0.437339096977002, 51.17768217562876],
            [0.440724260883659, 51.176514339926321],
            [0.441006992012501, 51.17420155305463],
            [0.442884364162722, 51.172637015697589],
            [0.445124334410498, 51.171600891000544],
            [0.447277031460562, 51.171295939714454],
            [0.449310605253469, 51.169337699146972],
            [0.451400664992341, 51.168609478802679],
            [0.451556739969892, 51.167964953776199],
            [0.452070928818081, 51.168246468564483],
            [0.452230609845602, 51.167508332098897],
            [0.452971590189654, 51.167292230006879],
            [0.453405385496019, 51.166140025444136],
            [0.454065719887834, 51.16615674726097],
            [0.455515106494407, 51.165058762507414],
            [0.457103367153553, 51.164744793378603],
            [0.45744684381678, 51.163994691971766],
            [0.458358209070638, 51.163837030778701],
            [0.457950818760525, 51.163600066061157],
            [0.458156951415287, 51.163334014744564],
            [0.46020646376707, 51.16244279939135],
            [0.460134095942537, 51.161620494543406],
            [0.461061168172826, 51.16124843001716],
            [0.461285801557368, 51.16057816395589],
            [0.462372399130602, 51.159898741643595],
            [0.464256958609824, 51.159351790305116],
            [0.464724755966407, 51.158575661750852],
            [0.464071634256864, 51.158262954229123],
            [0.465413494006014, 51.158241862573476],
            [0.465339467758389, 51.157255908305409],
            [0.466143634930578, 51.15742422084184],
            [0.466020171478159, 51.156745097548615],
            [0.466598703181438, 51.156892983113067],
            [0.466662736915296, 51.156431151395445],
            [0.467667657916252, 51.156207586534805],
            [0.467080512382964, 51.156058988668953],
            [0.467672714876086, 51.155444808226392],
            [0.467026943718044, 51.155108578333369],
            [0.467733706108916, 51.154899397888627],
            [0.468383896650372, 51.153309064723075],
            [0.46913923529419, 51.153206775198278],
            [0.469047657224539, 51.152562956174556],
            [0.469648331496913, 51.152349708395036],
            [0.469375973787537, 51.15150734806106],
            [0.469978821884529, 51.15117443528036],
            [0.469041290368426, 51.150515207835554],
            [0.46982188048219, 51.15021721523965],
            [0.469271783830363, 51.149663126081407],
            [0.470407643716777, 51.148922326424071],
            [0.470158860123538, 51.147262833975439],
            [0.469252890230224, 51.146148755549795],
            [0.468828595479662, 51.146128936117861],
            [0.469212423174538, 51.145658549668049],
            [0.468797309113915, 51.145355232080078],
            [0.469378910021798, 51.14505424846832],
            [0.468897203892013, 51.143262067563718],
            [0.476757996324572, 51.141578543025354],
            [0.481277817382623, 51.141347792258479],
            [0.483530779330369, 51.142701187775835],
            [0.487920677836252, 51.143216728938292],
            [0.491215626361775, 51.144266292389531],
            [0.492606705842478, 51.143759968838765],
            [0.493877537861838, 51.143808414331517],
            [0.496018178580598, 51.144448959142771],
            [0.495835943338799, 51.144648915136919],
            [0.496866658311019, 51.144778002383013],
            [0.498785795964266, 51.143169374209641],
            [0.502169536482625, 51.142366700841642],
            [0.503041906467253, 51.141023233640126],
            [0.508422987266505, 51.141386367565033],
            [0.509962686196542, 51.140277655623031],
            [0.50922382446917, 51.13941301916983],
            [0.508993872702037, 51.136622665812581],
            [0.513406006527548, 51.13660523298126],
            [0.514675931683999, 51.136087756514705],
            [0.51674921185115, 51.135701371795037],
            [0.518700149724667, 51.136114442762981],
            [0.523624524472664, 51.135739321412835],
            [0.524266583579514, 51.135480825279174],
            [0.528905644708042, 51.134281505508511],
            [0.53307568730455, 51.134154342464328],
            [0.534740038582477, 51.134944803754536],
            [0.540005424124063, 51.134484247256438],
            [0.541459596021839, 51.134672991430712],
            [0.541882395969832, 51.134432655158079],
            [0.545891563563011, 51.136448183255652],
            [0.547227448089565, 51.136616048148689],
            [0.547371227404134, 51.137046421477386],
            [0.548022773112655, 51.136806474392728],
            [0.548652676105152, 51.13703018189161],
            [0.548884993489844, 51.136731915072467],
            [0.550658690951959, 51.137294919867401],
            [0.551020982436094, 51.136994712053834],
            [0.552556322193571, 51.137330846603838],
            [0.552865787090087, 51.137887107921784],
            [0.553475915747788, 51.137803629551456],
            [0.553999868996069, 51.13833451654709],
            [0.555565292936201, 51.138515259292802],
            [0.556873324984619, 51.140039906534469],
            [0.557667063720334, 51.141777256535534],
            [0.559917510442976, 51.142863030432267],
            [0.561919775840472, 51.143260769348963],
            [0.565582344376011, 51.141137025759384],
            [0.565206853056293, 51.140166726313069],
            [0.564084333785881, 51.139466442398991],
            [0.560119601065541, 51.137782434422597],
            [0.559951991952636, 51.137492003631579],
            [0.560684309805753, 51.137317671056032],
            [0.558818358107037, 51.136226195382108],
            [0.559035342759591, 51.133945981561332],
            [0.560082836499078, 51.134120911717517],
            [0.559986144593471, 51.134414432672699],
            [0.560397003074098, 51.134502568618878],
            [0.564384333120055, 51.135025852419901],
            [0.567975199068998, 51.136466099016431],
            [0.568252576577225, 51.136930388312777],
            [0.570407963665784, 51.138106189941304],
            [0.57160741791152, 51.138440466548296],
            [0.572253814138418, 51.138932603818603],
            [0.572090598571241, 51.13926267527922],
            [0.574094073845261, 51.140839284776561],
            [0.573932279204407, 51.141271858437115],
            [0.575407347883987, 51.142985095206576],
            [0.575856199302704, 51.142983300413476],
            [0.577292942230902, 51.144211685420125],
            [0.579275536766307, 51.144637443203436],
            [0.578593339475378, 51.145480835226778],
            [0.576822936282638, 51.145579232478433],
            [0.576337118727937, 51.146021649181179],
            [0.575561602409189, 51.148836751564076],
            [0.576697278077653, 51.149534838586305],
            [0.57515517143917, 51.155404979131383],
            [0.575642346784505, 51.156114661025491],
            [0.578118450231762, 51.155250631344956],
            [0.58204359654255, 51.154624372618002],
            [0.588832232309994, 51.154568681228788],
            [0.593211039680459, 51.153976968676858],
            [0.593485186324786, 51.153306233994158],
            [0.595890894003784, 51.154279052543473],
            [0.598730527033048, 51.157855822813389],
            [0.601277985047303, 51.157986244857696],
            [0.601825161175532, 51.159017368895249],
            [0.603363757667254, 51.158121496570494],
            [0.604048313228986, 51.156766149882166],
            [0.6052332160917, 51.156448347112566],
            [0.604281718502441, 51.153114794604662],
            [0.603555849839601, 51.152033697171639],
            [0.604222166986963, 51.151854266486723],
            [0.605039040555428, 51.150920480070553],
            [0.610503136054054, 51.151501924776667],
            [0.611703130025833, 51.151783616719932],
            [0.612541634080258, 51.152425040207653],
            [0.61271056094729, 51.151947281873781],
            [0.613692034038092, 51.151727475355898],
            [0.613097058071684, 51.151574386972221],
            [0.613687967210989, 51.150516975082169],
            [0.614296007691334, 51.151002545948515],
            [0.614723599886514, 51.150825694908441],
            [0.615345215851478, 51.151121183516224],
            [0.615366264547559, 51.150304957359445],
            [0.616409283764255, 51.150440812325243],
            [0.615557464243173, 51.149968801322231],
            [0.616945289440418, 51.14955190021545],
            [0.617169195516375, 51.148277834514417],
            [0.617698471322345, 51.148862286379789],
            [0.618360299178299, 51.14868107588606],
            [0.618883512288174, 51.148021787811153],
            [0.619569555861499, 51.14816740956401],
            [0.619560741351275, 51.14846620799495],
            [0.621246370355029, 51.148360069895318],
            [0.622205928471012, 51.149596811891442],
            [0.626088186496603, 51.152841947359619],
            [0.626864957241447, 51.152493516548681],
            [0.629932724233775, 51.152572945285236],
            [0.631591979079342, 51.152302661710529],
            [0.631936750516423, 51.152304793695649],
            [0.632276769227201, 51.152977085992504],
            [0.638192285442011, 51.152411129010964],
            [0.640309646346633, 51.152475752963824],
            [0.641800850360691, 51.151142426769532],
            [0.640974762054075, 51.150775247775442],
            [0.637027643700835, 51.14665874204583],
            [0.63648209877026, 51.14521581268739],
            [0.63251456321158, 51.145297132313353],
            [0.633238213278841, 51.144571204998961],
            [0.634950962357481, 51.14373933775439],
            [0.634349847639288, 51.142398384398462],
            [0.63841450798227, 51.14201890436248],
            [0.639444098865982, 51.141295025467016],
            [0.640376430858451, 51.140646185820486],
            [0.642133187228695, 51.140464385669468],
            [0.640075160875699, 51.138122965308128],
            [0.636422273355153, 51.136841014134454],
            [0.633069487563808, 51.136809557035484],
            [0.629554176198349, 51.13731410665153],
            [0.628527869153728, 51.137820168848286],
            [0.627823993074793, 51.13894405879573],
            [0.626255706886742, 51.139020676448091],
            [0.625803851357767, 51.138364370143876],
            [0.624537949708213, 51.137798294489201],
            [0.624430617599003, 51.136410228094476],
            [0.623434584668651, 51.135786815576722],
            [0.6237362644844, 51.135510223727074],
            [0.623108617537267, 51.13482817264002],
            [0.62386716926806, 51.134415410562589],
            [0.621262958181306, 51.13323626124496],
            [0.619879393265783, 51.132153819644671],
            [0.619289877196113, 51.132168830073248],
            [0.617230745413747, 51.133242970203789],
            [0.613744455131248, 51.132715681179299],
            [0.614291235151032, 51.132576637835498],
            [0.61371658440549, 51.132095717416782],
            [0.614235027544299, 51.131885355773754],
            [0.613477961192399, 51.131388727003596],
            [0.613197789069989, 51.132097423474534],
            [0.612675266178712, 51.132286286009929],
            [0.611958239861694, 51.132093649524663],
            [0.611810622611195, 51.132441418667121],
            [0.61114876065791, 51.13226373381886],
            [0.610048510247127, 51.132955662172087],
            [0.609368216894431, 51.132704627937294],
            [0.609135075502658, 51.133310631824401],
            [0.607893640860748, 51.132589134996238],
            [0.606447708429517, 51.132398334562737],
            [0.605901637453412, 51.131585759727493],
            [0.60487381714525, 51.131864098892109],
            [0.60361814968292, 51.131675319264403],
            [0.60375632472517, 51.131158684027014],
            [0.603243425943631, 51.131010914190952],
            [0.602912871762483, 51.130038828945985],
            [0.603765745975042, 51.128506142931762],
            [0.60656103592635, 51.12705066941907],
            [0.607090726897215, 51.127192653551646],
            [0.608472735886584, 51.125541998129648],
            [0.609748356507073, 51.121701858067503],
            [0.608869245867046, 51.119187868132968],
            [0.608993676762719, 51.117871063695404],
            [0.604101696855602, 51.116185856540469],
            [0.599167138251158, 51.115364819009862],
            [0.595507590981225, 51.113018662925633],
            [0.592615391852308, 51.111866867164927],
            [0.590338479135462, 51.111341738236916],
            [0.595568297650465, 51.108340427795518],
            [0.595569261642491, 51.105910222793703],
            [0.596141271612476, 51.105461307241782],
            [0.595181669205643, 51.104092145040973],
            [0.596470417203885, 51.099481962430303],
            [0.595366987774362, 51.097914530837535],
            [0.595752945626963, 51.096721444575479],
            [0.59454880116957, 51.094144413947149],
            [0.596921748101974, 51.090663176141803],
            [0.596593096292314, 51.090252256742069],
            [0.59923662449808, 51.090863540875162],
            [0.600974940883457, 51.090740317344306],
            [0.601187801637759, 51.091046773167193],
            [0.605028645969696, 51.090419769379821],
            [0.606920271131535, 51.090940611150359],
            [0.609792742702765, 51.090668729562005],
            [0.611324996599259, 51.089322264132228],
            [0.618478787970559, 51.089478766485577],
            [0.619095048827768, 51.089289570709866],
            [0.620569861601382, 51.08960997195652],
            [0.620082206097956, 51.090507723320393],
            [0.621472356911545, 51.09112321865652],
            [0.625780696557038, 51.089601821994677],
            [0.628479316847174, 51.091228439943201],
            [0.629347322300323, 51.091423870298897],
            [0.629256294466141, 51.089542556276101],
            [0.631252904158598, 51.088185374751063],
            [0.633530193618245, 51.088000059222104],
            [0.635817867184998, 51.086641633830013],
            [0.638232646097054, 51.086013312332618],
            [0.642550479083821, 51.082797480869239],
            [0.64664360218637, 51.083284059369412],
            [0.643372013819618, 51.080401751585335],
            [0.643558620402957, 51.080243730185103],
            [0.642532800759802, 51.079756968508981],
            [0.642617202684803, 51.078958180282562],
            [0.644679477125459, 51.077890685022489],
            [0.640838548645595, 51.075731730085337],
            [0.638797842151925, 51.07527146893591],
            [0.636550970571381, 51.073821075276449],
            [0.633833459179736, 51.070818072018497],
            [0.628436634986648, 51.067759292572987],
            [0.627263570062335, 51.066580466811686],
            [0.618676732637218, 51.06555455092073],
            [0.613037274847117, 51.0637084155221],
            [0.611686588519385, 51.063548842527958],
            [0.611946379446851, 51.06248442942541],
            [0.609775613032155, 51.061599354991529],
            [0.61049064198632, 51.060964585930108],
            [0.607842036066494, 51.058457724370562],
            [0.607378284500599, 51.058662348206553],
            [0.606832848655877, 51.057032198668793],
            [0.605508441773024, 51.056472631467905],
            [0.604976830114353, 51.054578638119224],
            [0.604104438295399, 51.05370587405104],
            [0.60544616514334, 51.053660666980214],
            [0.604699679821506, 51.052463110381957],
            [0.604267382570817, 51.052566287141595],
            [0.603635683802569, 51.051073856669923],
            [0.601540040043575, 51.04965810572029],
            [0.600360856517899, 51.049750017554921],
            [0.599645241647309, 51.046144921787608],
            [0.597049803507234, 51.044485680796839],
            [0.595644308246079, 51.042186550001631],
            [0.593470044995695, 51.041854394088496],
            [0.592426871293785, 51.039984293594976],
            [0.593215917644209, 51.038632954186703],
            [0.59290716985185, 51.038034505421706],
            [0.593362202938198, 51.037293183207879],
            [0.594089228267093, 51.037156518283339],
            [0.595024028824953, 51.036449503778961],
            [0.594922083851831, 51.035748429662007],
            [0.593950366813324, 51.034875099958988],
            [0.595101614779817, 51.033702778926795],
            [0.593302735524529, 51.031451933500371],
            [0.594167519867206, 51.030565697453007],
            [0.596065884709704, 51.030385908588769],
            [0.597105555128178, 51.029924782084862],
            [0.598158951608213, 51.029887863122802],
            [0.598385611125424, 51.029503269161992],
            [0.59928685861345, 51.029500304663109],
            [0.602426866850031, 51.026764524316704],
            [0.597511354411385, 51.025168662258437],
            [0.595819023714803, 51.024948156182504],
            [0.597225440378297, 51.020820065823976],
            [0.606034418145095, 51.019963666577254],
            [0.606133388025724, 51.017304588412216],
            [0.604699041050622, 51.014076210196521],
            [0.604570421371459, 51.01246822709625],
            [0.605360941485115, 51.012072839438915],
            [0.603580856806027, 51.011881389093872],
            [0.602514180495428, 51.010451710942291],
            [0.601534887865247, 51.009894307719783],
            [0.597316903494592, 51.008935881260228],
            [0.5934941495339, 51.010049769710996],
            [0.587285549706643, 51.008748425345296],
            [0.586733093133717, 51.008427881077573],
            [0.585295612278689, 51.005856812940593],
            [0.581757476812828, 51.005242540748419],
            [0.579642397592378, 51.004453728460163],
            [0.578045355988097, 51.004471008305856],
            [0.575528156352292, 51.005221797208627],
            [0.573117997560385, 51.004482546760904],
            [0.571983621982513, 51.003457923726806],
            [0.568351411372393, 51.003817582229452],
            [0.566785506680563, 51.004269337810207],
            [0.564776771856062, 51.006720293068639],
            [0.563294573549539, 51.007529026517091],
            [0.56165524752989, 51.007789849691072],
            [0.558779916614396, 51.007222400387739],
            [0.557059550705654, 51.007876177152269],
            [0.554684349617719, 51.00828882297337],
            [0.551805167202208, 51.008247439900941],
            [0.54945369061783, 51.009067791146926],
            [0.547667337943744, 51.009171531882387],
            [0.54512717362926, 51.010375460482535],
            [0.541877055068416, 51.011009273924358],
            [0.54128399008969, 51.011661658056354],
            [0.541281907143426, 51.013240160019819],
            [0.540700791549641, 51.014346480319489],
            [0.541024497062769, 51.016928824925976],
            [0.539761521332821, 51.017926754285789],
            [0.539926432764603, 51.018517674425894],
            [0.539017786599945, 51.019633805408581],
            [0.53936864573208, 51.019869915991862],
            [0.5386171852331, 51.020705609236565],
            [0.537668681915668, 51.020910599618858],
            [0.537570754872799, 51.021676321165685],
            [0.53626898610979, 51.021915057380014],
            [0.534725949882642, 51.021704816539312],
            [0.533828919138456, 51.021991403415456],
            [0.531808532051447, 51.021813964052299],
            [0.531279813988302, 51.021506135951093],
            [0.529661472302362, 51.021719280300623],
            [0.528917491495061, 51.021438588814497],
            [0.5274179967023, 51.021660820831485],
            [0.526716395592906, 51.022132897390719],
            [0.526910096328565, 51.022521745560546],
            [0.526206882241312, 51.022413739451132],
            [0.525973320013977, 51.022786646372587],
            [0.522840771906056, 51.02316374604159],
            [0.518296292122318, 51.02238220456811],
            [0.51595593775811, 51.02269436760934],
            [0.515508273376184, 51.02253852025607],
            [0.514049134543971, 51.022954880382301],
            [0.513362352520389, 51.023519194634716],
            [0.509590269474611, 51.023675853732513],
            [0.508426755729096, 51.02412806232163],
            [0.506865618261628, 51.026488324406245],
            [0.504452103779568, 51.027988130985264],
            [0.502104772174801, 51.028071715728053],
            [0.499358675365052, 51.029199892459566],
            [0.498490837525477, 51.029054768735676],
            [0.498643472358052, 51.02926735906388],
            [0.497426614896145, 51.029925659401812],
            [0.496639079255544, 51.029894827085599],
            [0.496506487018613, 51.03053263524091],
            [0.493312023412844, 51.031072150769184],
            [0.489687839387901, 51.030594527783478],
            [0.486564999770007, 51.030928168603594],
            [0.486235958683509, 51.030669851098089],
            [0.48525137712338, 51.030928244119707],
            [0.482862788618889, 51.030643566339428],
            [0.481515419305288, 51.029824968618129],
            [0.480908823071888, 51.029790186084767],
            [0.480808299123039, 51.029376800784874],
            [0.480466166700331, 51.029593625686779],
            [0.47965978143582, 51.029182635300437],
            [0.478721980933649, 51.029088316914859],
            [0.478192869203619, 51.029382853148391],
            [0.478046403695333, 51.029070273105518],
            [0.477692170381921, 51.02932781926792],
            [0.477303632721164, 51.029156181444137],
            [0.477058681812255, 51.029751378843621],
            [0.476351858686448, 51.029548712602065],
            [0.471393690900583, 51.030772567972498],
            [0.470273493229994, 51.031942090114143],
            [0.469260947723319, 51.031998575343238],
            [0.469479272328686, 51.03248322955212],
            [0.468017025282927, 51.032586085295407],
            [0.467349208839424, 51.033010316290337],
            [0.46637578643717, 51.034314306131421],
            [0.466614062386836, 51.036034308646606],
            [0.466303725414633, 51.03642220341289],
            [0.466380992743708, 51.036775831760096],
            [0.466864736691509, 51.036752126180474],
            [0.466969562885196, 51.03786605755402],
            [0.468334293414475, 51.040621744849695],
            [0.467641832957667, 51.040960155370549],
            [0.467286744631808, 51.041768113938808],
            [0.466305738792047, 51.042127000495178],
            [0.466061490096009, 51.042739245799744],
            [0.471080650952181, 51.045744177501135],
            [0.471872452034279, 51.046975781183384],
            [0.471147673105883, 51.04879889062348],
            [0.471372395144255, 51.049080143211832],
            [0.468364094397144, 51.050546792952389],
            [0.466369957624636, 51.05080116534382],
            [0.463782120602922, 51.052344259369697],
            [0.463029089666424, 51.054099556313162],
            [0.463238377837483, 51.054789472177312],
            [0.457282658894725, 51.055261155105931],
            [0.453677878330012, 51.0569603128151],
            [0.451652159768993, 51.056380462958664],
            [0.449571848456923, 51.056711902434216],
            [0.447961135404502, 51.056585571659596],
            [0.447736820821189, 51.05750045062527],
            [0.446829414842578, 51.058127443057444],
            [0.444248577066111, 51.058414405573259],
            [0.442758942328711, 51.058094799846749],
            [0.442716384623523, 51.057638801692306],
            [0.440788829322377, 51.057919222405786],
            [0.439903192036853, 51.058962120795179],
            [0.438344618535371, 51.059747443814423],
            [0.432505091609107, 51.061063549781011],
            [0.430968128799396, 51.062047086498097],
            [0.428541023885128, 51.062522968561964],
            [0.428228022974243, 51.063141953858917],
            [0.426518335859314, 51.063009288571706],
            [0.425544681978226, 51.064069191638708],
            [0.425228552625246, 51.062991098548338],
            [0.422656564940916, 51.062853787810639],
            [0.421988293749982, 51.063668092556668],
            [0.421026270982746, 51.062847108280714],
            [0.418238890794012, 51.062629621418544],
            [0.416218015624988, 51.063892790047944],
            [0.416233839892769, 51.065292799271354],
            [0.417749745129272, 51.067895728519957],
            [0.416951830077858, 51.067896047473369],
            [0.415877725530388, 51.066622252975328],
            [0.41410012376739, 51.065487999945937],
            [0.411283429555986, 51.065889725513465],
            [0.411053611230269, 51.067097838751167],
            [0.409200054100618, 51.068236912743274],
            [0.408977587115901, 51.069395403571143],
            [0.406578269975784, 51.068905217335221],
            [0.405788693885989, 51.069506972742644],
            [0.402383719921507, 51.070462917104848],
            [0.40088502927593, 51.071183543690516],
            [0.400630520714408, 51.072692532928592],
            [0.405408107994961, 51.075435417792022],
            [0.405535543440031, 51.077231550794544],
            [0.405040228446504, 51.077921676007833],
            [0.406585366721443, 51.079688620352712],
            [0.404051900069515, 51.081247225718769],
            [0.400447184974735, 51.082554373995414],
            [0.400233510165652, 51.082398673962444],
            [0.397596593110617, 51.08364807669917],
            [0.396445863391609, 51.083098777946724],
            [0.393314736450716, 51.083075707012789],
            [0.390801781134281, 51.081333791598773],
            [0.390071288963709, 51.081815510320716],
            [0.39046500554142, 51.083663767635734],
            [0.389222099776414, 51.084399691875674],
            [0.391614012698407, 51.085786167261972],
            [0.390052385300105, 51.086185060961007],
            [0.38733457740418, 51.085457258490571],
            [0.386572615293619, 51.086161742660884],
            [0.384100619360343, 51.086047614348743],
            [0.383821161039711, 51.086472423461544],
            [0.382718781209553, 51.086889730160465],
            [0.383241891035532, 51.087565285461672],
            [0.381662423185864, 51.088036379601142],
            [0.375265756817116, 51.087720477770951],
            [0.373875975366884, 51.088467315225429],
            [0.373030254739288, 51.088321704876712],
            [0.371983919159123, 51.086850888453213],
            [0.370342653418425, 51.087790756546966],
            [0.369925243463767, 51.087614850811264],
            [0.368828859703655, 51.088152419424766],
            [0.366594593286262, 51.088416233276632],
            [0.364660862594127, 51.088161282073663],
            [0.362245393247579, 51.086298968774685],
            [0.361653076810693, 51.085435852233068],
            [0.357840549510754, 51.084034270958114],
            [0.356743114062751, 51.0830635028342],
            [0.350792819205269, 51.084071028565013],
            [0.352314143456918, 51.086587430962069],
            [0.352101594605054, 51.089377955299966],
            [0.352401803257209, 51.089327843747625],
            [0.35367447448867, 51.091610900271306],
            [0.35395083598353, 51.093621719499041],
            [0.353261531811729, 51.095797683248129],
            [0.352308145844948, 51.096205403093663],
            [0.351669353859931, 51.097436902134497],
            [0.350618338362974, 51.097666698562371],
            [0.350379077455846, 51.098046546786335],
            [0.351027921817239, 51.098448105211411],
            [0.350744069305138, 51.098990737310103],
            [0.351026948761986, 51.099301624825621],
            [0.352327735667188, 51.099207998980333],
            [0.353817474277169, 51.099628589409882],
            [0.354641094189762, 51.100601304260088],
            [0.355234714692378, 51.100519192790934],
            [0.356091810903698, 51.101039740185357],
            [0.358357087507335, 51.103953874491758],
            [0.358205048405306, 51.104334676776396],
            [0.358866569036118, 51.104308736753893],
            [0.358873212466597, 51.104551432077507],
            [0.358423243503424, 51.104776360782438],
            [0.353942412554156, 51.104871185941263],
            [0.352265202930443, 51.104419293975219],
            [0.350140737573643, 51.105006147201856],
            [0.348985463552713, 51.104620158758095],
            [0.348355311242366, 51.105011454726537],
            [0.346589872628153, 51.105157534190546],
            [0.344239204026899, 51.10474872535255],
            [0.342006356872727, 51.105313320578716],
            [0.336383964936894, 51.105123656154412],
            [0.334676324935487, 51.105566988950272],
            [0.334157763690172, 51.105907412611401],
            [0.334183361221144, 51.106500482041554],
            [0.331981099312621, 51.107988819502644],
            [0.330438927205199, 51.110048541737171],
            [0.329867271461289, 51.110215527608439],
            [0.329343939930789, 51.109866212417266],
            [0.328276941090111, 51.110922637932738],
            [0.326663909854906, 51.111036598545255],
            [0.326109980186821, 51.111357903388701],
            [0.325762226623732, 51.110777539334777],
            [0.324544434509685, 51.112133719791267],
            [0.325431366032, 51.113407562888547],
            [0.324744345838896, 51.114066970660176],
            [0.325042416538981, 51.1148812606014],
            [0.32653390509234, 51.114705887840131],
            [0.327720358742778, 51.115113961446944],
            [0.328338384010349, 51.115583711150336],
            [0.327874270266908, 51.115930215684763],
            [0.327967873782906, 51.116456277317667],
            [0.330051932170437, 51.117805168516604],
            [0.329355740365639, 51.118282215262035],
            [0.329851642235637, 51.118964841418581],
            [0.32950908317312, 51.119256766337259],
            [0.32937279839186, 51.11899057284684],
            [0.328436558701897, 51.11909916175825],
            [0.328860366476454, 51.119371320169712],
            [0.327268806095989, 51.121083924511069],
            [0.326967529669555, 51.121970397150868],
            [0.326347981619569, 51.122069066898518],
            [0.326199454826759, 51.122440760759829],
            [0.325344813840797, 51.122525216481506],
            [0.325238431053424, 51.122882580279857],
            [0.324434147712927, 51.122717803926328],
            [0.324132043302684, 51.123019700407568],
            [0.323052581337573, 51.122354944350668],
            [0.322018110975475, 51.122387188823268],
            [0.320648223668744, 51.12194312653839],
            [0.320279552568179, 51.122285906267834],
            [0.318249153255264, 51.121379162734236],
            [0.317578639561012, 51.121801666964949],
            [0.317626356047546, 51.121527315407299],
            [0.316925518035053, 51.121543906205694],
            [0.316576829477391, 51.121115526339352],
            [0.315878299544267, 51.121206712049663],
            [0.315095970757411, 51.120365118029177],
            [0.314667646443293, 51.120515698893719],
            [0.313984046810759, 51.119990516071077],
            [0.313205411652428, 51.12010845362898],
            [0.313282907751516, 51.119829017851224],
            [0.311794112787374, 51.11940429351408],
            [0.311247879315407, 51.119568885276337],
            [0.310535537878347, 51.119325750422526],
            [0.309656456266651, 51.119721753066642],
            [0.307597719522208, 51.119960270509829],
            [0.305282311594594, 51.11960753851757],
            [0.304756325502598, 51.119203305628552],
            [0.3038752641088, 51.119474292607364],
            [0.303152091579419, 51.118897664370891],
            [0.302764262514744, 51.11923087274787],
            [0.301322143721505, 51.118592847582995],
            [0.298691447507856, 51.118161652596946],
            [0.297451197062024, 51.118566463183171],
            [0.295407179085575, 51.118612013874326],
            [0.292875573612911, 51.119141954248548],
            [0.291616588915031, 51.118765528358644],
            [0.289524942137843, 51.11885957561406],
            [0.287041796052857, 51.117999833204891],
            [0.284656560512207, 51.117602192924629],
            [0.281802916012082, 51.11802127016189],
            [0.279639521070298, 51.11784763360378],
            [0.278982538282125, 51.11834789448146],
            [0.278979736286835, 51.119045849028602],
            [0.275913028635358, 51.120409660191754],
            [0.275289411556369, 51.11990377126196],
            [0.274708638595931, 51.120240640228928],
            [0.274802928196051, 51.119774738343899],
            [0.273907690312858, 51.120080848671542],
            [0.272146671674696, 51.118847899783603],
            [0.272419019598937, 51.11863215686769],
            [0.275638892730268, 51.117477703262544],
            [0.275108768725058, 51.117332433535609],
            [0.278914863847201, 51.114678942609359],
            [0.275643064696189, 51.112357590497346],
            [0.266237417494362, 51.115100781245488],
            [0.266387095774751, 51.114889229140367],
            [0.265016609709728, 51.113657595626535],
            [0.262615371836517, 51.114312961444611],
            [0.259284281240719, 51.11409769135625],
            [0.259390568127555, 51.113699022108008],
            [0.255263783811535, 51.113954986561218],
            [0.252353149471019, 51.114598382513812],
            [0.251955608605781, 51.114415378914174],
            [0.251867042376342, 51.114739053287892],
            [0.251151194231073, 51.114239310810383],
            [0.248904820542129, 51.114939975765552],
            [0.24827674944933, 51.115570811343268],
            [0.247288539272836, 51.115552939936769],
            [0.246960126744823, 51.114880242413612],
            [0.244523914015805, 51.114879379703709],
            [0.243310207826739, 51.115279505885539],
            [0.243468641305449, 51.116072391274038],
            [0.242530339565742, 51.116200115947549],
            [0.243008601395484, 51.11704712243175],
            [0.227670832791809, 51.123791437647021],
            [0.227418179557924, 51.124243227771757],
            [0.225432901198037, 51.124357481057956],
            [0.224799548556287, 51.123549330665377],
            [0.224205996573139, 51.123360969670948],
            [0.224323409367433, 51.122681526814652],
            [0.221628264224507, 51.121879294736978],
            [0.221364423937606, 51.121443628056483],
            [0.220803700547652, 51.121521732430246],
            [0.219518293079325, 51.120582942810309],
            [0.217676081066666, 51.120399362980478],
            [0.216822701636299, 51.120572934388605],
            [0.217375363962635, 51.12086373436464],
            [0.216376805114301, 51.121407888994931],
            [0.215568940255671, 51.121069759076072],
            [0.215235979653427, 51.121487969773291],
            [0.214539093016422, 51.121288927237359],
            [0.214253180543037, 51.121615409778428],
            [0.213695572837724, 51.121011716113578],
            [0.212847641297157, 51.121240015274907],
            [0.212464893031436, 51.120616817603647],
            [0.212127045288375, 51.120963164069551],
            [0.211792274607941, 51.120715882590829],
            [0.210019285287155, 51.120994035833455],
            [0.209816610100136, 51.120340444985573],
            [0.20932661329352, 51.120223797828835],
            [0.209049862663609, 51.120503328334962],
            [0.206664579252748, 51.118698422176863],
            [0.206136514304522, 51.118711986189226],
            [0.206383449155596, 51.119156099572969],
            [0.205912639565308, 51.119291792307187],
            [0.20428631993701, 51.119108424688044],
            [0.203182655936123, 51.118106673738815],
            [0.202375476122293, 51.118260382614558],
            [0.202002493912367, 51.118079445565073],
            [0.202292242776443, 51.117742128844036],
            [0.201706576297398, 51.117445584422413],
            [0.201049475387431, 51.117600054919286],
            [0.201008067910591, 51.117330131513761],
            [0.19953378646705, 51.11691630118878],
            [0.198247992047117, 51.116920701146071],
            [0.197349014920479, 51.117759603798063],
            [0.196264707091919, 51.117945459413811],
            [0.195451576094731, 51.11728082829336],
            [0.194281702281546, 51.116897188522366],
            [0.193219299055987, 51.117183331386656],
            [0.187996142007867, 51.116657961871368],
            [0.185514991867585, 51.116826684832915],
            [0.185040332689455, 51.116245588291136],
            [0.182814195414731, 51.116216128335722],
            [0.18013235508146, 51.115581773360255],
            [0.178852836199051, 51.115688368124154],
            [0.178086447487249, 51.116193691084348],
            [0.17687702038045, 51.115967999982288],
            [0.176443870120653, 51.116146040823082],
            [0.176112288570666, 51.115840141741927],
            [0.175636553771575, 51.115993790687128],
            [0.174856823546821, 51.115455203844967],
            [0.174162192379044, 51.115755015080879],
            [0.172705628680323, 51.115407969099323],
            [0.171601380114253, 51.115413198395238],
            [0.167059243600078, 51.116576696078994],
            [0.165032910825739, 51.116144785074475],
            [0.164815326900945, 51.116350266947798],
            [0.163321519554829, 51.116305972571425],
            [0.163128152533793, 51.116632414125952],
            [0.162571505665266, 51.116400790077911],
            [0.162333995972299, 51.116883632814115],
            [0.160456344149444, 51.116850897659759],
            [0.160369563286131, 51.117226627055551],
            [0.159736177567209, 51.11731027747237],
            [0.159836295813655, 51.11761870023691],
            [0.158402649064119, 51.117700937541926],
            [0.159295523721604, 51.11821775777441],
            [0.158252410921642, 51.118340444100163],
            [0.158278353348033, 51.119081021350667],
            [0.15597852258607, 51.119957145388703],
            [0.156060526704706, 51.12049074008609],
            [0.15483161661502, 51.121044008040769],
            [0.154123069228166, 51.120404145492074],
            [0.153411330307449, 51.120586333560681],
            [0.153775245763195, 51.121528432549205],
            [0.154628143909138, 51.12173845078415],
            [0.152051969733721, 51.122545842774748],
            [0.15117429924967, 51.123832760939635],
            [0.151583200619722, 51.124205656532155],
            [0.151038171799883, 51.127700621198514],
            [0.152142572489682, 51.128122770970641],
            [0.151177893453574, 51.129600149953497],
            [0.150921198830477, 51.131211988932094],
            [0.150036334746689, 51.131178800877457],
            [0.151230006274289, 51.131828648908403],
            [0.151161191101146, 51.132347034044663],
            [0.151853223100818, 51.132935956073254],
            [0.151160356811227, 51.134358866712546],
            [0.152860976705787, 51.135364501092575],
            [0.152379070912278, 51.135913875009287],
            [0.153238653898093, 51.13613907027689],
            [0.154581777340898, 51.137538877457168],
            [0.155734968580085, 51.136946542226291],
            [0.156405066848331, 51.137307410428811],
            [0.158310982477526, 51.137315412089862],
            [0.158620769319552, 51.137950021542295],
            [0.159323478421596, 51.138120510899611],
            [0.160721459338739, 51.137322153476191],
            [0.16240333780932, 51.135808950833599],
            [0.162882612214306, 51.134532917982995],
            [0.164447249975986, 51.132778132127825],
            [0.167292417505497, 51.133556415483909],
            [0.167881449800601, 51.132185694077307],
            [0.169823944749848, 51.132185633592577],
            [0.174250530638587, 51.132943381663871],
            [0.175801287617743, 51.133761711253158],
            [0.180345542533042, 51.131792936407429],
            [0.183143895235328, 51.134937874835025],
            [0.187455059660556, 51.136358296480395],
            [0.188993751556242, 51.138666887181166],
            [0.191135699862879, 51.139552188522053],
            [0.193455531933961, 51.139423254036451],
            [0.193014225177309, 51.140548514400017],
            [0.193927270708641, 51.143251893624196],
            [0.194819120440301, 51.144963689955091],
            [0.196433275605596, 51.146408299380774],
            [0.200216813019306, 51.146023295950961],
            [0.203630271518871, 51.149546490156808],
            [0.205960307103189, 51.151090793633976],
            [0.210891043447472, 51.151694659619892],
            [0.212121413914639, 51.151617429336547],
            [0.214943854941435, 51.153820451052844],
            [0.215427436704393, 51.154362587119309],
            [0.212923172896811, 51.155044941561272],
            [0.210228270280623, 51.15664727440376],
            [0.208705868816497, 51.159026917618526],
            [0.207498558533497, 51.15869267637278],
            [0.205631873037851, 51.158686530041095],
            [0.205590710643759, 51.159802488851525],
            [0.205185277329792, 51.159908163126211],
            [0.205434472751733, 51.160874751407235],
            [0.203119835499562, 51.161734982072204],
            [0.202559263740842, 51.161616936015569],
            [0.20144802333924, 51.160146755059586],
            [0.198985520946374, 51.161748044947998],
            [0.195984501744574, 51.164331580333567],
            [0.195685322732553, 51.164989218497965],
            [0.190614031048731, 51.167964902866068],
            [0.190944728440641, 51.171380686784204],
            [0.192633362447994, 51.172176430252001],
            [0.194753230521333, 51.173827421666665],
            [0.197666076809025, 51.175242240163854],
            [0.197971645233085, 51.175139369409308],
            [0.198828060148589, 51.174465855343449],
            [0.199346958822929, 51.173284261081761],
            [0.20047381240012, 51.173334112270034],
            [0.200675264933012, 51.172667509459693],
            [0.202151870557435, 51.172611835344235],
            [0.202825553248049, 51.171258234277381],
            [0.20342945774363, 51.17123246838397],
            [0.205350826938877, 51.172673866395982],
            [0.207353497178841, 51.175365578549304],
            [0.212183283898899, 51.175449693571188],
            [0.218065185337332, 51.177511993513157],
            [0.216276177144267, 51.17914133695016],
            [0.219241701803748, 51.179993436970477],
            [0.226934297637547, 51.184156857134127],
            [0.229653377443879, 51.182916125703009],
            [0.231254694504088, 51.185110556944515],
            [0.233606111942585, 51.18514571680133],
            [0.236598933755375, 51.183591120973134],
            [0.239272006280795, 51.182676605266025],
            [0.239384431643887, 51.182191506065358],
            [0.240570433720907, 51.182334270689246],
            [0.240430433676559, 51.181453798536815],
            [0.242339174883972, 51.181982905505038],
            [0.244346330545876, 51.182003763148586],
            [0.252283580221831, 51.179625305759664],
            [0.253881164844766, 51.17884897249634],
            [0.256103650046786, 51.178593875292997],
            [0.255515373107586, 51.179058491708545],
            [0.256245350190327, 51.179796263566153],
            [0.257878485336254, 51.179690100684446],
            [0.257918086230639, 51.180031986522735],
            [0.258844000615368, 51.180181385374304],
            [0.262212804321094, 51.180017344328199],
            [0.265228090575834, 51.179735044581186],
            [0.267517670101453, 51.178952313156152],
            [0.272931309316489, 51.176467554312168],
            [0.274908876654303, 51.175194299382625],
            [0.277277891793649, 51.174695821002167],
            [0.277813798293663, 51.175322122791655],
            [0.279314494127664, 51.17592872335134],
            [0.280414071979904, 51.176524240637804],
            [0.282281195118955, 51.17690061957321],
            [0.283194284763867, 51.17783250909941],
            [0.284360473786632, 51.178094829699788],
            [0.283232267444561, 51.179411922566608],
            [0.283960667453642, 51.18034021692263],
            [0.287536281268681, 51.183660878408944],
            [0.289621110146573, 51.183370063927399],
            [0.290162468717516, 51.183696720332037],
            [0.291201237778595, 51.184790668159394],
            [0.290708096655968, 51.185205933975332],
            [0.292250543556792, 51.186441996509103],
            [0.293569296703026, 51.189310246505677],
            [0.294737994328062, 51.189212676932968],
            [0.29519192272558, 51.189736184424582],
            [0.296107732836691, 51.189906173273172],
            [0.29604494893183, 51.190370570285019],
            [0.298060916901616, 51.192314941111611],
            [0.297917336401572, 51.193485116311145],
            [0.298385219132524, 51.194632485192479],
            [0.298012384350158, 51.195222588460247],
            [0.299659612307345, 51.19699970273016],
            [0.303803832267536, 51.197637630068442],
            [0.305991245782589, 51.197355299416806],
            [0.308621232683067, 51.199197449541018],
            [0.308677194183824, 51.199915824232143],
            [0.309427189544667, 51.19994238893117],
            [0.31135736737427, 51.199295404396359],
            [0.311664570795252, 51.198912506086998],
            [0.314606967431638, 51.19875628895845],
            [0.315074852211431, 51.198181338194935],
            [0.316261020628104, 51.198513997714166],
            [0.317213286197907, 51.198207344466084],
            [0.319756792994809, 51.199600385812481],
            [0.32169540221171, 51.198981841406962],
            [0.322251738137216, 51.199228011755707],
            [0.325996633322042, 51.1985780167917],
            [0.328262849071429, 51.19866783196322],
            [0.329104319214713, 51.199089063649012],
            [0.330464721514661, 51.198758885247862],
            [0.330567962917468, 51.198166853205329],
            [0.332304412491886, 51.197885818903451],
            [0.332471001764469, 51.197244856063975],
            [0.333292030643169, 51.197459614289905],
            [0.333668086094811, 51.197140036560597],
            [0.332564030946745, 51.195912858297433],
            [0.332757141273092, 51.195542071097378],
            [0.334093087001276, 51.195466854045875],
            [0.334916070819062, 51.195805672462612],
            [0.334530853860705, 51.19506599935788],
            [0.335076581396693, 51.19467737730961],
            [0.336225649202734, 51.194784837712106],
            [0.337454394698518, 51.193525475733537],
            [0.338668342429202, 51.193953584031441],
            [0.339415325943816, 51.193328883681829],
            [0.341386448171804, 51.193107772028341],
            [0.341658081031771, 51.192791157432758],
            [0.344225292831413, 51.193627394658435],
            [0.345099923342054, 51.194390501364751],
            [0.346459346152383, 51.194468461515847],
            [0.346974462562022, 51.194915897935843],
            [0.348596940412654, 51.194537078332466],
            [0.350363065759764, 51.195209393947749],
            [0.351052385364433, 51.195141578749805],
            [0.35181355224772, 51.195444648001917],
            [0.352346273504008, 51.196320699687007],
            [0.353087611834629, 51.195953240349326],
            [0.35411774458763, 51.196056622525461],
            [0.354721902909641, 51.195778251778613],
            [0.356750129624693, 51.196086351719629],
            [0.357810494766284, 51.195323911540122],
            [0.360436477920878, 51.195537155228941],
            [0.360422299978174, 51.194783780451552],
            [0.361513111111776, 51.194702404007671],
            [0.362432297246654, 51.194064184567274],
            [0.363407180757511, 51.194263033175623],
            [0.363848491428799, 51.193964521834388],
            [0.364927859160418, 51.194079404529525],
            [0.367484849811525, 51.194873970334314],
            [0.367877351711075, 51.196694418907072],
            [0.367532919353791, 51.197514410419643],
            [0.366877570248474, 51.197798380407065],
            [0.366505795379747, 51.200319605915318],
            [0.368694225707514, 51.200675522298972],
            [0.369139170174598, 51.201618930077359],
            [0.37113174855297, 51.201142365738221],
            [0.373188036278918, 51.199873039617039],
            [0.372723984135242, 51.1990883211542],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000117",
        LAD13CDO: "30UD",
        LAD13NM: "Burnley",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.186465411322163, 53.822299177212919],
            [-2.182816182707352, 53.822196047739432],
            [-2.18209182756036, 53.822599810327979],
            [-2.181412585183791, 53.82219818152916],
            [-2.179056294031973, 53.822854250520479],
            [-2.176589894843351, 53.82263231470111],
            [-2.175810058257104, 53.82286265400726],
            [-2.175418646007933, 53.822621452502261],
            [-2.174974836250528, 53.822927691231918],
            [-2.172688988058407, 53.823017292527538],
            [-2.172167492584359, 53.823277794366419],
            [-2.171212941906274, 53.822768648150344],
            [-2.170384393439296, 53.82298194471106],
            [-2.169676689141113, 53.822653092276234],
            [-2.167881614193163, 53.822769768354597],
            [-2.167301594618166, 53.822456002363765],
            [-2.164864485625974, 53.822707448487627],
            [-2.164488571455063, 53.822528206736585],
            [-2.163842593981846, 53.82282119980907],
            [-2.162754630048665, 53.822742690549475],
            [-2.159085160768902, 53.823304872187293],
            [-2.154273576865974, 53.82313859847963],
            [-2.152018219830284, 53.823692442382807],
            [-2.150431184702478, 53.822966424343875],
            [-2.149947674397222, 53.82071914982734],
            [-2.148545254504263, 53.8197537962903],
            [-2.144106157937809, 53.81999829955285],
            [-2.142111698895142, 53.82043300260132],
            [-2.135343773311493, 53.815943282634471],
            [-2.133467926267904, 53.817344809874527],
            [-2.128621066959897, 53.819781350479147],
            [-2.112277221786083, 53.805693923922284],
            [-2.126272317343011, 53.799468400855211],
            [-2.12835698656922, 53.799016785378754],
            [-2.127797467430598, 53.798250711045576],
            [-2.128346346345626, 53.797027760841331],
            [-2.127910659521594, 53.796536585643288],
            [-2.127854806515176, 53.79464827378677],
            [-2.129008520260176, 53.793657460516037],
            [-2.129518811087257, 53.792258381322767],
            [-2.129720237474396, 53.791617320834071],
            [-2.129214088091499, 53.7909060209711],
            [-2.125694782341581, 53.789638869530933],
            [-2.124814213723752, 53.788040830370988],
            [-2.127321827552707, 53.786097684233127],
            [-2.130483306889625, 53.785123577056474],
            [-2.132173165159304, 53.783399625156726],
            [-2.135460997995785, 53.781233439454866],
            [-2.136361758253893, 53.78009004315178],
            [-2.135031015720828, 53.77682891088871],
            [-2.131340633805217, 53.774394574773133],
            [-2.130463412916036, 53.772375032168242],
            [-2.13023095100159, 53.770314336479267],
            [-2.130068348764947, 53.768269742130379],
            [-2.130782285718817, 53.765147428293027],
            [-2.130073493001465, 53.763072868701215],
            [-2.131585306855332, 53.75805320478127],
            [-2.12952092409172, 53.754576193046653],
            [-2.131302000268549, 53.751361929589613],
            [-2.133434860339156, 53.749660831280359],
            [-2.13446973507636, 53.748920856001831],
            [-2.136657703935146, 53.748389880172951],
            [-2.138926018959193, 53.747410267915065],
            [-2.141645234962092, 53.745726315883786],
            [-2.141861942031367, 53.743892498237152],
            [-2.141139521560985, 53.743233630127996],
            [-2.140948198880502, 53.741793071757172],
            [-2.143090459406151, 53.739101304080592],
            [-2.144833477131748, 53.738971576327302],
            [-2.143768125771369, 53.737324452220832],
            [-2.145214278392829, 53.736855326725674],
            [-2.145407022835014, 53.736040774688448],
            [-2.147801404750045, 53.735378117140577],
            [-2.148458194208172, 53.73420166575977],
            [-2.149092214066355, 53.733881801172856],
            [-2.149614779467675, 53.734619068577892],
            [-2.15214579068951, 53.735309754917679],
            [-2.153067350898241, 53.734443928104668],
            [-2.156090215424143, 53.73531006690537],
            [-2.156863281409581, 53.734889313492268],
            [-2.156196221657471, 53.733648036101513],
            [-2.156981074240643, 53.733144576225115],
            [-2.159640425763925, 53.732893885825398],
            [-2.160249071065932, 53.732307050420296],
            [-2.163917759938489, 53.731248679420474],
            [-2.165020211185292, 53.72982615050406],
            [-2.166132870566166, 53.729088487887694],
            [-2.166691589739918, 53.727796124965792],
            [-2.170115662323899, 53.72560091208755],
            [-2.171394687118763, 53.725200016214011],
            [-2.173293701632529, 53.722996999262485],
            [-2.179502383016413, 53.727917807013206],
            [-2.185441337580852, 53.730810101108752],
            [-2.189001359978956, 53.731712326946415],
            [-2.191997051977898, 53.732151569270059],
            [-2.192171751440735, 53.731903218160902],
            [-2.196635803489358, 53.732881958448615],
            [-2.203296953399253, 53.732373789341665],
            [-2.20468044119799, 53.733839183359578],
            [-2.21426445523925, 53.737250460506637],
            [-2.2178432017892, 53.738323468065538],
            [-2.220203086156445, 53.738503405746727],
            [-2.22552294043059, 53.73851866869655],
            [-2.232653963011584, 53.737620597062801],
            [-2.233403899964256, 53.739719648577008],
            [-2.23502096817797, 53.742056976945975],
            [-2.240341549578457, 53.747693612001825],
            [-2.242420679309586, 53.749042122750325],
            [-2.245273761579213, 53.750983124610819],
            [-2.248974250501819, 53.752820734329802],
            [-2.251697554703203, 53.755030594932101],
            [-2.25391959934075, 53.753552762983169],
            [-2.255876055055261, 53.752816072499144],
            [-2.256054395109572, 53.752217087126894],
            [-2.257901016683387, 53.750649203211019],
            [-2.257138212483619, 53.750150209013967],
            [-2.257453272093714, 53.749843039394875],
            [-2.259343926390803, 53.749539657381504],
            [-2.261749937611936, 53.748472932864289],
            [-2.262714148839535, 53.748923819480069],
            [-2.266797063276351, 53.74808249824644],
            [-2.264242308912042, 53.747414058456251],
            [-2.262821766037282, 53.746246040402056],
            [-2.263291988438977, 53.745303058480353],
            [-2.265649230533838, 53.744010761374675],
            [-2.267448328969075, 53.74247339234914],
            [-2.271323901807831, 53.742271429964497],
            [-2.275222470466423, 53.743736576224734],
            [-2.278290815385769, 53.743431979685063],
            [-2.281631189227157, 53.74504022496135],
            [-2.283731118773075, 53.74457418004021],
            [-2.285267180726646, 53.743693295145036],
            [-2.287191491864371, 53.744836472200198],
            [-2.286843990911258, 53.74524086936006],
            [-2.287519588909721, 53.746697113759907],
            [-2.29330467322365, 53.749781271017397],
            [-2.295271739984963, 53.751975816176639],
            [-2.299171100071726, 53.750749155588863],
            [-2.301479374133404, 53.748871153377564],
            [-2.302984675901216, 53.74838110020756],
            [-2.303299036600881, 53.74865623817999],
            [-2.314109436125508, 53.747024002901306],
            [-2.313724746906659, 53.751560390832331],
            [-2.316402744611965, 53.751942529512483],
            [-2.319916699285284, 53.755554486151112],
            [-2.322138936599003, 53.757381195683962],
            [-2.325824906285054, 53.75859989368751],
            [-2.326493701252248, 53.75956428882823],
            [-2.327222986943492, 53.75972138744099],
            [-2.328006664789212, 53.760822977391854],
            [-2.328608853152115, 53.761017266556237],
            [-2.328630517186789, 53.761460318234199],
            [-2.32804306921505, 53.761795385221127],
            [-2.328363581475616, 53.762824537973877],
            [-2.327857828728354, 53.763523396073559],
            [-2.328425661978511, 53.764364020984878],
            [-2.328645992909862, 53.766352471912036],
            [-2.329069747645636, 53.766610164395161],
            [-2.328757235974632, 53.766998407242951],
            [-2.330118439196497, 53.768014807173465],
            [-2.330372092651572, 53.769018072500181],
            [-2.331427556749623, 53.769536463718843],
            [-2.331317504712207, 53.770010437985711],
            [-2.330706586754176, 53.77026738636053],
            [-2.331168410319683, 53.770921338855089],
            [-2.33037409797758, 53.773521075184071],
            [-2.331687996162463, 53.777933265897431],
            [-2.331195320060271, 53.778766920386516],
            [-2.329314192673515, 53.779613385023453],
            [-2.3291872646117, 53.780070325604278],
            [-2.3300547766437, 53.780970336829768],
            [-2.331604459455614, 53.781193454963088],
            [-2.333287976013706, 53.782024670198851],
            [-2.33579719127032, 53.782480547541496],
            [-2.336097497120103, 53.783406370857541],
            [-2.335584058411483, 53.785058010852829],
            [-2.337379260781734, 53.786349042187851],
            [-2.338756686812112, 53.789085602642629],
            [-2.337838991988622, 53.78953939183333],
            [-2.336232014804386, 53.79168305875335],
            [-2.341094644586345, 53.792506100133359],
            [-2.340801367785854, 53.793216987408108],
            [-2.341271351725542, 53.793703698826924],
            [-2.340851506202249, 53.794157889405312],
            [-2.342305065392087, 53.794813466048943],
            [-2.34063037578943, 53.795156187056598],
            [-2.338585568083976, 53.794974132535934],
            [-2.33622104014098, 53.795432886664202],
            [-2.33611467153917, 53.796558483245214],
            [-2.335242840760417, 53.797266483586114],
            [-2.335475390653284, 53.797870725360276],
            [-2.33466323208611, 53.799022561430377],
            [-2.33505889885315, 53.799709936603477],
            [-2.334690038672566, 53.801052875375959],
            [-2.332989809332644, 53.802809371817546],
            [-2.332043089316659, 53.803286564570882],
            [-2.332136139246215, 53.804108708004854],
            [-2.329761370425754, 53.806624712283799],
            [-2.329061731584116, 53.806674271602944],
            [-2.328832193582173, 53.807030825870946],
            [-2.327031254133905, 53.806447939302828],
            [-2.325927704473821, 53.806498581815084],
            [-2.325647493198528, 53.807764851829177],
            [-2.323108180122749, 53.810091522676593],
            [-2.321784926405004, 53.810220015636439],
            [-2.320922830815585, 53.810680714059679],
            [-2.319687719744211, 53.810621099048937],
            [-2.318907835863881, 53.811712519585051],
            [-2.317625650966786, 53.811857934417311],
            [-2.317479147391669, 53.812165711346985],
            [-2.315600992423272, 53.812632653572877],
            [-2.315044038422278, 53.811676000755504],
            [-2.313204626214041, 53.812451091157158],
            [-2.312950482097472, 53.812177621824361],
            [-2.311362812733133, 53.812911580372393],
            [-2.309841495774498, 53.814412020126802],
            [-2.30925353770923, 53.817065887970216],
            [-2.308454937782785, 53.818143805237483],
            [-2.30577551045586, 53.819594137537742],
            [-2.301964380016368, 53.821065241776815],
            [-2.302091904488713, 53.820211064360478],
            [-2.303184354061751, 53.820042028732772],
            [-2.303950544511456, 53.819499012986526],
            [-2.303662560733058, 53.818325016347842],
            [-2.302963768213341, 53.817891767333002],
            [-2.303047115924775, 53.814499498400785],
            [-2.302057484384796, 53.81352949840791],
            [-2.299219915416377, 53.81409117398568],
            [-2.298380577944288, 53.815021723538145],
            [-2.297058598660347, 53.814289791793442],
            [-2.296441533895936, 53.813796981463838],
            [-2.297292179218411, 53.813173805798726],
            [-2.296761826797568, 53.812914469109806],
            [-2.297096567775512, 53.811929457075699],
            [-2.297782162062775, 53.811595200616964],
            [-2.295484177735492, 53.808757992618716],
            [-2.294693698766095, 53.808424687570465],
            [-2.293274416840477, 53.808941382592117],
            [-2.290611737988884, 53.809292108507243],
            [-2.288183816591177, 53.808894411640132],
            [-2.288335647107576, 53.808669347099411],
            [-2.285404306616085, 53.807702067854486],
            [-2.282668574005765, 53.807349931966186],
            [-2.281444086305332, 53.807946020291624],
            [-2.279983232896256, 53.808171445259099],
            [-2.279199970720888, 53.809131390452443],
            [-2.279076191157867, 53.810385500525442],
            [-2.276818768856078, 53.812576609768691],
            [-2.275348263572305, 53.812748072422075],
            [-2.273018338452268, 53.814115978269221],
            [-2.269804172159719, 53.814989697043679],
            [-2.268729971642692, 53.814688317624984],
            [-2.268194303242893, 53.814047777271284],
            [-2.268545623345129, 53.813174259465448],
            [-2.266968220728696, 53.811829589534618],
            [-2.266495180345726, 53.81004023983369],
            [-2.265921046632823, 53.80978535824012],
            [-2.262083999364145, 53.809618546434464],
            [-2.260957724891379, 53.808482229109224],
            [-2.261068600703025, 53.807753063392141],
            [-2.250672881697947, 53.811596045529214],
            [-2.248900851693957, 53.811896342138837],
            [-2.235404222349283, 53.813776934235726],
            [-2.233179343295204, 53.814613569110847],
            [-2.230841997022863, 53.814624386516144],
            [-2.228606925013785, 53.815008862061461],
            [-2.226383013711593, 53.816061978258595],
            [-2.22417175578184, 53.816377118774732],
            [-2.223968424953868, 53.817836242816],
            [-2.217683263470493, 53.815501972377561],
            [-2.212956774896271, 53.814017568367305],
            [-2.210620616216721, 53.813654090971205],
            [-2.210111476458323, 53.814188869155124],
            [-2.206964698595287, 53.813286563728646],
            [-2.207538511613544, 53.811390012151953],
            [-2.204287875777382, 53.814678897399247],
            [-2.19945971533558, 53.818204015597871],
            [-2.196782970264979, 53.820056362516517],
            [-2.195756897823324, 53.821520380768902],
            [-2.194826244776888, 53.821957812655256],
            [-2.195497738050116, 53.823603308212199],
            [-2.19626078121295, 53.824024493486753],
            [-2.196928553022531, 53.825181942043969],
            [-2.195220415628011, 53.825053513704738],
            [-2.193506883601909, 53.82408290066865],
            [-2.192635924242629, 53.823312240916685],
            [-2.191884863261739, 53.823175032050557],
            [-2.186465411322163, 53.822299177212919],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000118",
        LAD13CDO: "30UE",
        LAD13NM: "Chorley",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-2.807713377777829, 53.630779794109642],
              [-2.808047810895497, 53.630396425072114],
              [-2.808129835957994, 53.63073204004332],
              [-2.807713377777829, 53.630779794109642],
            ],
          ],
          [
            [
              [-2.809155746734233, 53.659994201807777],
              [-2.808822644543233, 53.653505005717101],
              [-2.810732281900707, 53.649652223514082],
              [-2.810969585992381, 53.648143253263768],
              [-2.806544091490959, 53.641541478291167],
              [-2.808731013706225, 53.641842215111041],
              [-2.809780767471224, 53.642315999322832],
              [-2.809498433396826, 53.643685952175126],
              [-2.810227837643987, 53.644253581023342],
              [-2.809769609123945, 53.64489126701352],
              [-2.810290915344219, 53.645255366503648],
              [-2.81231079318369, 53.64566862604061],
              [-2.811700617852531, 53.646900588269752],
              [-2.814407771599336, 53.648431807200964],
              [-2.814633389640973, 53.65126702611488],
              [-2.813901709265312, 53.652082761462772],
              [-2.812351573903202, 53.652589454166588],
              [-2.812113106233597, 53.653170826898382],
              [-2.81481079536994, 53.65364056563142],
              [-2.816467995244528, 53.654289024824791],
              [-2.816746772541234, 53.654699692457804],
              [-2.816522482287375, 53.655777139137918],
              [-2.814563716146909, 53.656049351805883],
              [-2.813051515890349, 53.655468193634412],
              [-2.812623270041032, 53.655544806920474],
              [-2.812354659470272, 53.656369072252019],
              [-2.814745351914468, 53.658092086369571],
              [-2.814851160781453, 53.661066537833157],
              [-2.81794952357287, 53.661412134967343],
              [-2.821432276892546, 53.662533413432904],
              [-2.819759007821277, 53.665677351729272],
              [-2.821172432816251, 53.666992558946994],
              [-2.823336880851394, 53.668163266102823],
              [-2.822611936253382, 53.668864852689602],
              [-2.817682922073009, 53.67069900082204],
              [-2.81649026200144, 53.672738523076788],
              [-2.809155746734233, 53.659994201807777],
            ],
          ],
          [
            [
              [-2.564662593146314, 53.743631452204902],
              [-2.564593593710525, 53.742443541473122],
              [-2.56286805853078, 53.741528576074593],
              [-2.563539967774131, 53.740754232189445],
              [-2.563261012813209, 53.739848637496657],
              [-2.563618394333939, 53.739251040720497],
              [-2.561607482939506, 53.737843049707699],
              [-2.560731686405182, 53.737764461112462],
              [-2.559362826452154, 53.735148110792245],
              [-2.557157425832212, 53.734596627515664],
              [-2.55707495914705, 53.732602534012081],
              [-2.556496321809111, 53.732289738439114],
              [-2.556538954082721, 53.730812782013146],
              [-2.555859957421575, 53.728980547539187],
              [-2.55689425944796, 53.727419888043372],
              [-2.556432423587371, 53.726649948927133],
              [-2.555184834126398, 53.725252681616219],
              [-2.550747235124485, 53.726528818253811],
              [-2.546177515329454, 53.724883333149421],
              [-2.547279521099444, 53.721981409202151],
              [-2.546339305849183, 53.72123159231495],
              [-2.54454088267661, 53.72102766103648],
              [-2.543774606542492, 53.721303484706411],
              [-2.540802884487153, 53.719654124346718],
              [-2.541427338249288, 53.718837871504448],
              [-2.539902636699952, 53.71810772251029],
              [-2.539599240521495, 53.717608447351729],
              [-2.53989926719564, 53.716543791567183],
              [-2.53947047259509, 53.714651910376752],
              [-2.538975134920654, 53.713358940893187],
              [-2.540912780504585, 53.712042421306251],
              [-2.540075827985955, 53.711284897708133],
              [-2.541662937232736, 53.71099550004886],
              [-2.543263618135, 53.709880003408706],
              [-2.5420207076461, 53.708891539426006],
              [-2.54157995821848, 53.707956961988053],
              [-2.542780725727499, 53.706616775977601],
              [-2.542213714963844, 53.705169545681855],
              [-2.541038704987936, 53.704501642921336],
              [-2.53958534857471, 53.702084977246933],
              [-2.537466765132014, 53.701058163487723],
              [-2.536416521326339, 53.699347920460468],
              [-2.534946387400823, 53.698797227626855],
              [-2.535295378729452, 53.698250082886716],
              [-2.532719747916171, 53.697347467344848],
              [-2.531934189636851, 53.696426970397141],
              [-2.530860270804557, 53.696418254162403],
              [-2.529993994302179, 53.695939424120176],
              [-2.529060363637353, 53.696005571804044],
              [-2.526467188499348, 53.695010320856589],
              [-2.525298741658708, 53.694948041095287],
              [-2.523386360293599, 53.69546964332433],
              [-2.522355651858759, 53.695034620303929],
              [-2.521360752415304, 53.695044349486835],
              [-2.521163084020794, 53.694490636750423],
              [-2.520155305984733, 53.694437494450739],
              [-2.518473412375673, 53.69350731774194],
              [-2.517607143462949, 53.693629710343181],
              [-2.517507970611985, 53.693199603184269],
              [-2.515700455156969, 53.692390372662828],
              [-2.515377964376906, 53.691652929290832],
              [-2.516010819101414, 53.690897889046227],
              [-2.515436934357282, 53.68978671983151],
              [-2.516589883468865, 53.688967417239176],
              [-2.516998213995048, 53.687442149716631],
              [-2.517931898601524, 53.686654340342166],
              [-2.51740296745061, 53.684886844168744],
              [-2.518467070844725, 53.682396094178259],
              [-2.517821883227784, 53.681392203757653],
              [-2.517767477807747, 53.680166442953634],
              [-2.518348070059965, 53.677639132609407],
              [-2.519243094870752, 53.676804739749322],
              [-2.519172949263054, 53.676271142038424],
              [-2.520920454442085, 53.675212823974],
              [-2.524589704981193, 53.675079054528496],
              [-2.525551794174679, 53.674660476063515],
              [-2.526226822719582, 53.673559145828833],
              [-2.526474052921559, 53.671171676344613],
              [-2.528343862720986, 53.669395446949288],
              [-2.52916080790163, 53.667191511021166],
              [-2.532346395824989, 53.664886275859409],
              [-2.528939329476894, 53.660265228965635],
              [-2.52862413231897, 53.656240771010751],
              [-2.526615452813986, 53.655084744757616],
              [-2.525298391099711, 53.65369015809754],
              [-2.525065714420528, 53.652499332421691],
              [-2.523248796197585, 53.650313249001719],
              [-2.522014646848544, 53.647404634707506],
              [-2.521495430369788, 53.645416890056417],
              [-2.521759301503389, 53.644735325811155],
              [-2.524022732724619, 53.64257633325407],
              [-2.524550331337734, 53.641577218978867],
              [-2.523761636280485, 53.639084621492515],
              [-2.520290790793092, 53.631738337080044],
              [-2.520063422173932, 53.630465681072373],
              [-2.518332550840315, 53.630265561810269],
              [-2.514823694790641, 53.629220988688751],
              [-2.511324724074385, 53.626979026613256],
              [-2.525159583757211, 53.618667812201679],
              [-2.529376256849145, 53.617007956886781],
              [-2.529649999359239, 53.616414413487384],
              [-2.53111506317764, 53.615471332700032],
              [-2.533496607483985, 53.615048170713841],
              [-2.534451474343331, 53.614531572819537],
              [-2.537153658659676, 53.612701126869077],
              [-2.53805037009095, 53.611303902336111],
              [-2.540518880711978, 53.610258214251992],
              [-2.542743869866606, 53.610032419431008],
              [-2.544933225725795, 53.609154190585997],
              [-2.546123052861545, 53.608946526395478],
              [-2.547286606841229, 53.609162322387213],
              [-2.5473930259565, 53.608742977805306],
              [-2.549812344250523, 53.608127864548017],
              [-2.550249658282736, 53.60770340061552],
              [-2.551434052445124, 53.607663790861224],
              [-2.551701478660685, 53.607889064588768],
              [-2.552218760754769, 53.607340185101151],
              [-2.552647261876313, 53.604970669996248],
              [-2.555787190948106, 53.604358395339531],
              [-2.555870233025567, 53.603892411757251],
              [-2.55661499474372, 53.603641768205158],
              [-2.557291557759924, 53.60303280158945],
              [-2.557261494102286, 53.601782658551102],
              [-2.557667585701983, 53.601636951926146],
              [-2.557803721128187, 53.600844441057028],
              [-2.558149253605939, 53.600461721937997],
              [-2.558981422151416, 53.600313122991544],
              [-2.559591319910012, 53.598897297700788],
              [-2.561712124018303, 53.598615907714787],
              [-2.569279408328528, 53.595581604556273],
              [-2.574004519110098, 53.594275479046757],
              [-2.573954104147542, 53.594791654879934],
              [-2.57535139739517, 53.595214586877994],
              [-2.57519748622477, 53.595896647441045],
              [-2.575954872027954, 53.596148273170051],
              [-2.575528575273354, 53.596355260536967],
              [-2.576261196419488, 53.596118933707977],
              [-2.576158715434676, 53.596582329681155],
              [-2.576905645143941, 53.596730633244945],
              [-2.576553960958388, 53.5968689525588],
              [-2.576742864265184, 53.597314764968331],
              [-2.57812584603484, 53.597898625865248],
              [-2.577874917059536, 53.598333977761065],
              [-2.578381362161462, 53.598352203229666],
              [-2.57850723706035, 53.598938536455186],
              [-2.579073291646855, 53.59889534963375],
              [-2.578928931071436, 53.59916749783762],
              [-2.579506704035829, 53.598986729869893],
              [-2.580358636848326, 53.599515610958335],
              [-2.580489686748082, 53.600036301695894],
              [-2.580886720221056, 53.60000830789852],
              [-2.580434221088038, 53.601060348414343],
              [-2.579954341709576, 53.601108516842693],
              [-2.580734865948201, 53.601495724840731],
              [-2.580611328168342, 53.601963720713016],
              [-2.58119066972509, 53.602004950331313],
              [-2.581074313816949, 53.602335389434302],
              [-2.581769791380294, 53.602359872943133],
              [-2.58142773126517, 53.602650962151905],
              [-2.582221271633394, 53.602772939771214],
              [-2.58195007238143, 53.603052000626946],
              [-2.58242056112075, 53.603527892899095],
              [-2.583424390357897, 53.603774675899523],
              [-2.582907211127466, 53.604188865291256],
              [-2.583066610587808, 53.604679753084604],
              [-2.584544143762095, 53.605192070670853],
              [-2.584491225719149, 53.605518607593758],
              [-2.585194215791747, 53.605317426153348],
              [-2.586733512798948, 53.606029855566192],
              [-2.587374513678065, 53.606915660987895],
              [-2.586796246297426, 53.607393085582963],
              [-2.587393971927857, 53.607881816557672],
              [-2.588036686568691, 53.60769170056961],
              [-2.588664901001713, 53.607871973830818],
              [-2.588654731222334, 53.608444583803582],
              [-2.589759395874035, 53.608107471575217],
              [-2.589901857038436, 53.608677531419026],
              [-2.591470486749707, 53.60898707580602],
              [-2.59118946664876, 53.609427097941477],
              [-2.591682099584983, 53.609744648747643],
              [-2.593479573556166, 53.60998652075881],
              [-2.594430595879969, 53.610547168133358],
              [-2.595927482742421, 53.610799485058529],
              [-2.597600150197777, 53.609049192345779],
              [-2.600736226974044, 53.608402500196071],
              [-2.601847996635002, 53.60835916077383],
              [-2.60264696419511, 53.608744335671297],
              [-2.604890866272233, 53.607015329169947],
              [-2.608648691282469, 53.606323037862872],
              [-2.610311933240072, 53.605225170161305],
              [-2.611273922549213, 53.604967684309919],
              [-2.612249391912409, 53.603867009852848],
              [-2.613502006215795, 53.603644870539064],
              [-2.615159752545034, 53.602808525145115],
              [-2.615218502998676, 53.601340416636084],
              [-2.615817803499781, 53.600570621809169],
              [-2.615304055383925, 53.599845204643223],
              [-2.616027937860855, 53.59829277598422],
              [-2.617139570401873, 53.597533816948861],
              [-2.617148362911693, 53.597100530124706],
              [-2.6179401326018, 53.596894203054653],
              [-2.617397146498522, 53.595821093870157],
              [-2.618801812769334, 53.595372503986773],
              [-2.619921480309793, 53.594242255220209],
              [-2.62085697474289, 53.594156507251512],
              [-2.621293923108403, 53.594485911034738],
              [-2.622788792921896, 53.593601765931552],
              [-2.623963758726673, 53.593876080998101],
              [-2.62590820613725, 53.593668176373207],
              [-2.627496700805535, 53.595235523627927],
              [-2.62823350510692, 53.595301765674705],
              [-2.628048394994172, 53.597082444779318],
              [-2.629834924549121, 53.599149375419039],
              [-2.631379838887671, 53.599898954253085],
              [-2.631763484986252, 53.603228034512476],
              [-2.632206065695301, 53.603517819626489],
              [-2.631285174139549, 53.605202616569777],
              [-2.631493925075993, 53.606025751674146],
              [-2.632368858089745, 53.606523581698347],
              [-2.632098594656743, 53.607249475521655],
              [-2.633309879324666, 53.60749744096217],
              [-2.634593993869363, 53.608267234170086],
              [-2.636162937021931, 53.608275080266793],
              [-2.636609432039727, 53.607714525057062],
              [-2.639252282583069, 53.607378646785094],
              [-2.640343679637021, 53.607591228080132],
              [-2.64139057483113, 53.607260239296423],
              [-2.641734446504864, 53.607511868268809],
              [-2.643748475286579, 53.607172970283401],
              [-2.646948943522117, 53.607233904162662],
              [-2.647734538762612, 53.606720010064272],
              [-2.650098125021146, 53.606120245366931],
              [-2.652569531837385, 53.605852417096749],
              [-2.655142492060546, 53.604434362651759],
              [-2.658866442926463, 53.604156841118844],
              [-2.659372086582933, 53.6036318276524],
              [-2.661341002468387, 53.60332793607401],
              [-2.663895005019317, 53.603427043216435],
              [-2.664950918119774, 53.603207253837262],
              [-2.666005974082675, 53.603605863752286],
              [-2.666603472432286, 53.603449732429503],
              [-2.667995404684419, 53.60431744079019],
              [-2.669159948215059, 53.604372952420057],
              [-2.670546419992179, 53.605081566552762],
              [-2.673054468101239, 53.604753785670077],
              [-2.674004146058992, 53.604981239082157],
              [-2.675074576764333, 53.604819703401269],
              [-2.675401833113382, 53.605060543828806],
              [-2.676304455030359, 53.604801071705886],
              [-2.677594590324365, 53.604959158190425],
              [-2.678023621938839, 53.604665502071967],
              [-2.679020982158055, 53.604660743895074],
              [-2.680153298577488, 53.604026019752148],
              [-2.682787739502175, 53.603953486214941],
              [-2.683253745593216, 53.603706340005274],
              [-2.684074516427419, 53.603903889125611],
              [-2.685783778973134, 53.603254125759506],
              [-2.686631230808623, 53.603233085324959],
              [-2.687049839227613, 53.603605499781203],
              [-2.687780790928669, 53.6036687127555],
              [-2.688066766718517, 53.60415514048541],
              [-2.689314174703036, 53.604286381131317],
              [-2.690982782496322, 53.604667751795255],
              [-2.696108946894292, 53.604664099115418],
              [-2.696040891045591, 53.606223090909815],
              [-2.6972258103957, 53.607417945309976],
              [-2.698589853369946, 53.613232696516221],
              [-2.699450676686422, 53.614282908778094],
              [-2.698474432180368, 53.61429849946272],
              [-2.695326279647727, 53.615802624320217],
              [-2.694604604463962, 53.615765469556941],
              [-2.693410450415666, 53.616783594197599],
              [-2.692274975253202, 53.617141616938902],
              [-2.692580009888697, 53.618229248272947],
              [-2.69113237409582, 53.619747673680664],
              [-2.691338027670653, 53.621137892477044],
              [-2.692091382487837, 53.621350158008184],
              [-2.692304274079091, 53.621794751797736],
              [-2.691820678210902, 53.622269440716522],
              [-2.692216460427796, 53.624096293724804],
              [-2.69190876329965, 53.624880964022552],
              [-2.694445537701443, 53.626609120846574],
              [-2.696288205705666, 53.62630179487752],
              [-2.696159366180485, 53.624813164692604],
              [-2.695320037025354, 53.62397493190273],
              [-2.695642223892152, 53.623431956653008],
              [-2.696931123011504, 53.62292559914291],
              [-2.697958480593543, 53.623063425380977],
              [-2.700816609150009, 53.622733922124716],
              [-2.70373285684633, 53.621356857546395],
              [-2.704886622365987, 53.61991821003059],
              [-2.704865548775552, 53.618744445736461],
              [-2.709691790172967, 53.617792804094002],
              [-2.711614289619471, 53.617946772261014],
              [-2.712810096383747, 53.618396266377957],
              [-2.721394552135431, 53.618701656332597],
              [-2.724420405674179, 53.620072087716302],
              [-2.728285899942429, 53.621111953683744],
              [-2.734279632292298, 53.622459540809722],
              [-2.735378568382537, 53.622881540390836],
              [-2.736169397530573, 53.623668558837821],
              [-2.743278904658265, 53.622051629048514],
              [-2.749210142037315, 53.618215219088967],
              [-2.756287057646089, 53.615953242532122],
              [-2.758736540255819, 53.61463620446608],
              [-2.759421977475995, 53.615262845934488],
              [-2.760679367534979, 53.615413954081149],
              [-2.76056459653737, 53.615840736943554],
              [-2.761211910165381, 53.616194361204158],
              [-2.760952827531706, 53.616498021650052],
              [-2.764093847200543, 53.617737287291774],
              [-2.765430572948945, 53.618758821165372],
              [-2.766218039875713, 53.618832879388215],
              [-2.766200901258454, 53.619138596886096],
              [-2.7677841788528, 53.619079011006008],
              [-2.768830042427474, 53.619478573771765],
              [-2.770524812019885, 53.61914858124323],
              [-2.771048010743134, 53.61948677247625],
              [-2.77385397342788, 53.619737412584925],
              [-2.774175568157317, 53.620209923237809],
              [-2.775630870591498, 53.620342515965689],
              [-2.775863122822134, 53.620064165389344],
              [-2.777147137426143, 53.620254477605002],
              [-2.778859355374491, 53.619560220636487],
              [-2.779502666550975, 53.619767260356824],
              [-2.780595637497486, 53.620664374972669],
              [-2.781002461472958, 53.623455336005037],
              [-2.792977370098074, 53.623066473296504],
              [-2.793241178873778, 53.622944279145628],
              [-2.788629175010014, 53.61910073264746],
              [-2.788371913923903, 53.618113695472964],
              [-2.787278939557288, 53.617302940619254],
              [-2.788285502354775, 53.617127329814515],
              [-2.790785733329853, 53.614531136480849],
              [-2.792227418428999, 53.614993494518821],
              [-2.79346391066611, 53.614991592651279],
              [-2.795953409530638, 53.615945813454807],
              [-2.796120121603893, 53.616530753571219],
              [-2.797621469008031, 53.617184104607347],
              [-2.797508953894645, 53.617951572398169],
              [-2.798233546473209, 53.6182163950415],
              [-2.797159179258546, 53.619125102659055],
              [-2.798900304216442, 53.619820883543568],
              [-2.79815442510204, 53.621073462563487],
              [-2.799212953656446, 53.621405262611511],
              [-2.800809037092705, 53.622738368931003],
              [-2.801052316650153, 53.624167706295061],
              [-2.800482355860396, 53.62472970498078],
              [-2.801040851922049, 53.62499921715991],
              [-2.801738720231675, 53.625362172000727],
              [-2.802561203324631, 53.626633920777955],
              [-2.805994705017754, 53.628040909615223],
              [-2.80750464613741, 53.628243756651294],
              [-2.80791746613471, 53.628798255643332],
              [-2.807899360352648, 53.63030394728252],
              [-2.806135137648056, 53.631837593180798],
              [-2.80580530444097, 53.632701808776417],
              [-2.805829243481805, 53.633143455930565],
              [-2.807319181357328, 53.638170074663549],
              [-2.80638040071122, 53.640097237541333],
              [-2.806014484023805, 53.640024199452817],
              [-2.806195272391852, 53.641255299115549],
              [-2.810743978650695, 53.648133096703297],
              [-2.810622198451985, 53.649355451548473],
              [-2.808597425034005, 53.653753709450605],
              [-2.808694067581666, 53.658643664066226],
              [-2.809899989654731, 53.662346833494233],
              [-2.815843147878968, 53.671837801105326],
              [-2.816723534675047, 53.674624498990084],
              [-2.818478659160573, 53.676531539299042],
              [-2.817808589311959, 53.678045272194097],
              [-2.818106338395729, 53.678800960867633],
              [-2.820057058851155, 53.680087339616051],
              [-2.821053262708428, 53.680243202505658],
              [-2.822953175663563, 53.67992545179569],
              [-2.823748153802316, 53.680165368585044],
              [-2.824054647119717, 53.680664812775007],
              [-2.823877740193244, 53.681839916295417],
              [-2.82272100189445, 53.683858576309319],
              [-2.82137164296933, 53.684621967219812],
              [-2.819250610798735, 53.684324600768214],
              [-2.818308611016883, 53.684782259965516],
              [-2.818049691047629, 53.685485124270421],
              [-2.818430066976747, 53.686594389694939],
              [-2.81955151737503, 53.68718804299796],
              [-2.818729866322978, 53.687608028912337],
              [-2.816237858347738, 53.687787732003279],
              [-2.809103979854778, 53.687539532035508],
              [-2.80731924708992, 53.686777678174046],
              [-2.806738910007448, 53.686836418734302],
              [-2.805828927850425, 53.687632625455613],
              [-2.804110297191988, 53.68811157514893],
              [-2.795875676156976, 53.687467294782543],
              [-2.793682896552741, 53.688102946364879],
              [-2.793907761443494, 53.689028157391313],
              [-2.792416773140662, 53.689140501047198],
              [-2.789910043917098, 53.690485545069514],
              [-2.787462742381366, 53.691290842927714],
              [-2.776495030005497, 53.690930125900174],
              [-2.770262268325117, 53.691447662583698],
              [-2.767214324610597, 53.692474824443146],
              [-2.766104873858939, 53.692508892632794],
              [-2.76565251762367, 53.692867724632734],
              [-2.75762595450749, 53.692863948162469],
              [-2.750820251401016, 53.692872658314009],
              [-2.748220498184884, 53.69239007782059],
              [-2.746605407677835, 53.692691380998589],
              [-2.746043233065488, 53.692416246215281],
              [-2.744111610602043, 53.687738147273237],
              [-2.742771380476824, 53.687473222826767],
              [-2.742731907364244, 53.687811429520352],
              [-2.741504165447298, 53.688087790352213],
              [-2.737556877891977, 53.68776342178576],
              [-2.737467837696031, 53.687434098004523],
              [-2.734454933987077, 53.687284534583107],
              [-2.732733937432673, 53.686539159014771],
              [-2.730041836087389, 53.68619786719448],
              [-2.732294437489248, 53.684248921241313],
              [-2.729467935773743, 53.683656765087825],
              [-2.729110350084082, 53.68329131982987],
              [-2.731062056694004, 53.681983299350705],
              [-2.731626887457445, 53.679805567341518],
              [-2.733267741314313, 53.677175423879511],
              [-2.733835823650896, 53.674759467757106],
              [-2.733553267998442, 53.674266839517848],
              [-2.732874940225793, 53.674170324209619],
              [-2.731801117743394, 53.674574178356657],
              [-2.730277573263133, 53.67392193817674],
              [-2.720013062517786, 53.673136508669252],
              [-2.717296602082689, 53.672641377906189],
              [-2.71550685830731, 53.671805384988822],
              [-2.712469706643426, 53.671403742450906],
              [-2.710585162418692, 53.676077199458717],
              [-2.708063310434023, 53.675997763215825],
              [-2.708955343706449, 53.678454391137656],
              [-2.708458025695808, 53.678499580183583],
              [-2.708487633987673, 53.680984680494191],
              [-2.708063781014651, 53.681530083351362],
              [-2.70716164220616, 53.681452722411656],
              [-2.707131177448461, 53.681170668621178],
              [-2.705833868759782, 53.680729805736874],
              [-2.70507529175371, 53.680997633584433],
              [-2.704119402791594, 53.680601480424876],
              [-2.703326150285926, 53.680696925090295],
              [-2.703065985084923, 53.680347908091619],
              [-2.700787318422919, 53.680248913090168],
              [-2.700277479490744, 53.679997526373484],
              [-2.696701525907756, 53.680274556028891],
              [-2.695521562186021, 53.679596505740037],
              [-2.694859498084691, 53.679843933777882],
              [-2.693997372943664, 53.679526253169698],
              [-2.69228331428194, 53.679694371127624],
              [-2.691328157663202, 53.679148905691683],
              [-2.690262582464148, 53.679533462516247],
              [-2.689963923380221, 53.679137899913691],
              [-2.68924621743986, 53.679502465777148],
              [-2.688537994292028, 53.679057124462723],
              [-2.685022405970803, 53.679415252039057],
              [-2.683692222963163, 53.679076804943044],
              [-2.683276962384888, 53.67942342841615],
              [-2.683334429620101, 53.679139069375864],
              [-2.682535396075199, 53.679065428804172],
              [-2.681411120105256, 53.679569786004301],
              [-2.680134755168683, 53.679093471809701],
              [-2.679267016197021, 53.679547814256082],
              [-2.678064798889199, 53.679256216789696],
              [-2.676341620127644, 53.679236302211471],
              [-2.673644666655214, 53.679955292685001],
              [-2.671090952164592, 53.679595720144178],
              [-2.667854913727672, 53.680123452794938],
              [-2.667230718829717, 53.681340355405268],
              [-2.664965641816806, 53.681909333574424],
              [-2.660830616804627, 53.680735884120111],
              [-2.658826562442945, 53.680668730513716],
              [-2.659074723585844, 53.682598952032038],
              [-2.658338358082533, 53.686058107126833],
              [-2.657403036527872, 53.687155324644046],
              [-2.657463059255609, 53.687703281271851],
              [-2.656752721715575, 53.687595724735104],
              [-2.655944522986681, 53.688498085867515],
              [-2.657237027915559, 53.688843339421581],
              [-2.657942910563694, 53.690605662727975],
              [-2.659420743754782, 53.691176379323586],
              [-2.661753813674978, 53.691526635192183],
              [-2.664082422991069, 53.691398693106223],
              [-2.66638496991968, 53.691922502288591],
              [-2.673589427569968, 53.692523056953256],
              [-2.675848339742933, 53.69362038354879],
              [-2.67759852770646, 53.693682398873676],
              [-2.677479084509236, 53.701803099407435],
              [-2.676810843281249, 53.704713689404869],
              [-2.67493929231685, 53.707791951698745],
              [-2.671772276199138, 53.710597021717845],
              [-2.668962450442287, 53.712258501228838],
              [-2.661951896358006, 53.715436154354876],
              [-2.65845191398388, 53.717680040083565],
              [-2.658050165125973, 53.7175707936509],
              [-2.658474859024914, 53.717208929425183],
              [-2.658172511038149, 53.716963414247843],
              [-2.657263144287294, 53.717339622782887],
              [-2.656725842550105, 53.716991129320107],
              [-2.656774599373849, 53.716230455644613],
              [-2.65595944205679, 53.715649787398768],
              [-2.656283284966315, 53.714844463246038],
              [-2.655564983679072, 53.714546391601353],
              [-2.656399528619804, 53.713941405171198],
              [-2.655535848682986, 53.713744798501196],
              [-2.655940289519247, 53.713250926431243],
              [-2.656747067300391, 53.713296839459147],
              [-2.657083548818486, 53.713012762543869],
              [-2.655691771483918, 53.712664455080194],
              [-2.654453047034548, 53.712121148834321],
              [-2.653627011822032, 53.712297335518542],
              [-2.652872427082278, 53.712000344598927],
              [-2.651456280055138, 53.712031425407062],
              [-2.647721376005531, 53.714288859368331],
              [-2.646938282665444, 53.714404546165987],
              [-2.648349044020356, 53.715298439328556],
              [-2.648195824717906, 53.71577924127466],
              [-2.639889050926015, 53.717761774751544],
              [-2.63668792598681, 53.718111398919476],
              [-2.633428189143589, 53.718093628396872],
              [-2.629757974315148, 53.717276177270755],
              [-2.629299169371892, 53.71912477278304],
              [-2.628103549280738, 53.719938192142578],
              [-2.627577749044104, 53.720958416306125],
              [-2.624835933356022, 53.723179361179348],
              [-2.623713854179423, 53.723343402095814],
              [-2.622907672745579, 53.723860825026335],
              [-2.623697749128737, 53.724814858552044],
              [-2.624152945855164, 53.726589459349121],
              [-2.624299793486664, 53.729032591306549],
              [-2.618512941491965, 53.732346904269036],
              [-2.6169336215833, 53.732462906798958],
              [-2.610750205246167, 53.737041724367586],
              [-2.610154015970894, 53.73701420249742],
              [-2.611857654825592, 53.738555078970897],
              [-2.608232520853863, 53.738329060036385],
              [-2.607084163498121, 53.739226513891317],
              [-2.605900518025944, 53.738517944655058],
              [-2.605652958953319, 53.738698061551048],
              [-2.604593079206682, 53.738370851956454],
              [-2.604684458064076, 53.73892765882529],
              [-2.60248639877892, 53.739792611613822],
              [-2.602478777745488, 53.74053147926702],
              [-2.600970732983861, 53.740566917101383],
              [-2.601017440927627, 53.740865090687031],
              [-2.60003495024819, 53.741394925809857],
              [-2.599940946387069, 53.742139618534715],
              [-2.59923062638606, 53.74251348679585],
              [-2.597157795849063, 53.742821345616363],
              [-2.595623862559746, 53.743390743098701],
              [-2.594211707169392, 53.743459773949105],
              [-2.590209789400009, 53.742878246715058],
              [-2.587456234117514, 53.74308772274221],
              [-2.585679741702583, 53.744432962629205],
              [-2.58426980877758, 53.744993518553244],
              [-2.583848896633395, 53.745693951679904],
              [-2.582347950503258, 53.746695348654981],
              [-2.579862448099406, 53.747561280252249],
              [-2.578689415938213, 53.748821700085614],
              [-2.578260252514339, 53.75025558696889],
              [-2.576809077510735, 53.751258470574413],
              [-2.573184966612084, 53.751396304401531],
              [-2.572952805109205, 53.751052269467031],
              [-2.569138818777044, 53.749708741247765],
              [-2.566999343808927, 53.749614622661888],
              [-2.56392188572994, 53.748779766052827],
              [-2.561603784426354, 53.746886063376607],
              [-2.562469771578546, 53.746102727146976],
              [-2.561753833757898, 53.744721908975741],
              [-2.564662593146314, 53.743631452204902],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000119",
        LAD13CDO: "30UF",
        LAD13NM: "Fylde",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.898677083189148, 53.861613113189748],
            [-2.896106755180754, 53.860445897120954],
            [-2.895008880086175, 53.860798340149834],
            [-2.892239356548964, 53.862909621804455],
            [-2.889282803268076, 53.862669117478902],
            [-2.886451652385107, 53.863395641360668],
            [-2.886868095875327, 53.863098650144877],
            [-2.884846675304893, 53.863072229754842],
            [-2.879730557948372, 53.86049519091388],
            [-2.878928602480988, 53.860391412911632],
            [-2.878248878090974, 53.860678619563949],
            [-2.877208720430108, 53.859872798969633],
            [-2.877019898768716, 53.858987045849148],
            [-2.875565342807736, 53.85791908826566],
            [-2.875831198416011, 53.857035406829645],
            [-2.884274070429367, 53.855678274191142],
            [-2.888021404193081, 53.854230459083823],
            [-2.890125864062147, 53.852172743466497],
            [-2.887404529184344, 53.85079883493453],
            [-2.887658006996049, 53.849846906470006],
            [-2.88809618044322, 53.849147077451029],
            [-2.890697251902401, 53.847653726369742],
            [-2.890150428593095, 53.846024627549347],
            [-2.883749064091214, 53.843895914415711],
            [-2.881880346951384, 53.843859333791762],
            [-2.881360151208878, 53.843551266302953],
            [-2.878841365806526, 53.843317178477051],
            [-2.871619776014496, 53.843137975962442],
            [-2.867780013824353, 53.845534206345313],
            [-2.867001922505652, 53.845687239189878],
            [-2.866539906646697, 53.845174655262447],
            [-2.864098550650073, 53.844199071313732],
            [-2.86111026194633, 53.843746883168642],
            [-2.857590917040409, 53.842591946844315],
            [-2.858518307494019, 53.838595452265217],
            [-2.861095472316199, 53.83658429346243],
            [-2.864087157153713, 53.835676540635106],
            [-2.868627589865528, 53.833762510984094],
            [-2.870258491132877, 53.832465373441401],
            [-2.870127612799886, 53.831356278134699],
            [-2.868693288100407, 53.830623350016722],
            [-2.864930221208458, 53.829616003705553],
            [-2.862229879183699, 53.829373890720078],
            [-2.857496123363785, 53.830815383915066],
            [-2.85445784017376, 53.831215472061963],
            [-2.849400983881372, 53.831581259736275],
            [-2.840873574214512, 53.831262946645637],
            [-2.838677763679484, 53.829350353320073],
            [-2.836107297188267, 53.825246312347872],
            [-2.825997991641527, 53.819971983510783],
            [-2.82209850316593, 53.818024071707271],
            [-2.819656192310732, 53.815386573627507],
            [-2.815263256997332, 53.808167530435199],
            [-2.811524060120518, 53.807909767879678],
            [-2.782868527385303, 53.789905465796458],
            [-2.782074634103916, 53.789986148846687],
            [-2.780503573456027, 53.787697201447166],
            [-2.785070117477736, 53.78424825962616],
            [-2.791149378032934, 53.780740628204278],
            [-2.798196659923096, 53.771523194225217],
            [-2.798666997293044, 53.770403719217953],
            [-2.797935451398842, 53.767351684294326],
            [-2.798634849684437, 53.767032436431151],
            [-2.799122572750761, 53.765713303244915],
            [-2.796700958726972, 53.764330851925259],
            [-2.796151705354118, 53.763363769171789],
            [-2.794998892223751, 53.762726064589806],
            [-2.795782034158558, 53.762025174758406],
            [-2.794447346971646, 53.761554056614123],
            [-2.794340303798612, 53.760785370261011],
            [-2.792871511784073, 53.760841836111545],
            [-2.792483049200584, 53.760592734083922],
            [-2.79100206568576, 53.75862869450652],
            [-2.791346551095712, 53.75823453146721],
            [-2.790522631646907, 53.756764993918644],
            [-2.789802568399215, 53.756381448515121],
            [-2.790734587203646, 53.754484168672924],
            [-2.794073009900616, 53.752650827900602],
            [-2.793969351767545, 53.751978418217242],
            [-2.794368781927033, 53.751848137285045],
            [-2.794582035956336, 53.752379728581992],
            [-2.794076490185919, 53.752673401379738],
            [-2.792414707072715, 53.753580533877269],
            [-2.793145872792428, 53.75350828438831],
            [-2.794629398305929, 53.752639175892035],
            [-2.794615672029702, 53.751912114779095],
            [-2.802282956132701, 53.750217965009796],
            [-2.802755455923366, 53.750740615499055],
            [-2.802447889957082, 53.75163071870066],
            [-2.80302728925381, 53.7520322090473],
            [-2.806150681342936, 53.752152352794909],
            [-2.803150832956868, 53.751910039133676],
            [-2.802725315151102, 53.750273426264627],
            [-2.803591251463792, 53.749797534334647],
            [-2.815029046641163, 53.747785099772372],
            [-2.815868459409751, 53.747280536188519],
            [-2.820276017412996, 53.746315659507211],
            [-2.8226106243888, 53.747371959554023],
            [-2.822328861327454, 53.749249753465868],
            [-2.822999006041545, 53.747536475103601],
            [-2.821008549822784, 53.74632232718659],
            [-2.82273659410683, 53.745437708985548],
            [-2.830822344116807, 53.744165795409707],
            [-2.844718895740296, 53.740830133258832],
            [-2.845869033568885, 53.740798649196513],
            [-2.848480776337027, 53.739940671242906],
            [-2.85574267326369, 53.738503919954532],
            [-2.85649938098841, 53.739556440976145],
            [-2.856431119810409, 53.740151058464967],
            [-2.857145991297767, 53.740561207803857],
            [-2.858468819104106, 53.742530969968485],
            [-2.859064259190377, 53.745482068226238],
            [-2.859395627647356, 53.74544733394368],
            [-2.859737451182005, 53.746444384832031],
            [-2.859193613578482, 53.747585310158279],
            [-2.85957854913647, 53.748461602989579],
            [-2.85894224416529, 53.748866152181186],
            [-2.85953096041477, 53.74962503997839],
            [-2.858741003215604, 53.750597848277103],
            [-2.859767593005479, 53.749479529009655],
            [-2.859188876952075, 53.74883921663357],
            [-2.859886355625364, 53.748760509028564],
            [-2.859763062367731, 53.748344291044482],
            [-2.859519789330267, 53.747518254828776],
            [-2.859969510356092, 53.746595521249155],
            [-2.859089475472543, 53.743299518725742],
            [-2.856654107941903, 53.738442577631744],
            [-2.858808940936317, 53.737797067706467],
            [-2.862958659512849, 53.737440073952612],
            [-2.870710694946728, 53.736877043197204],
            [-2.875065932355201, 53.736132534944247],
            [-2.875842647579745, 53.736667958751681],
            [-2.876005995300272, 53.737597059940605],
            [-2.875896690412282, 53.735986245760152],
            [-2.877009841506073, 53.735641937600008],
            [-2.889990597764193, 53.734464910672038],
            [-2.895200531415886, 53.733591972832066],
            [-2.896913376945877, 53.734226325307517],
            [-2.897068361820201, 53.7345990815707],
            [-2.897329474777161, 53.73440297711106],
            [-2.89877999600285, 53.734676136913585],
            [-2.899889854630018, 53.735536083320184],
            [-2.899075655097623, 53.734678412703452],
            [-2.896350959010918, 53.733941108641034],
            [-2.895920232109898, 53.733503899796723],
            [-2.900114489245289, 53.732853160997166],
            [-2.900819108020696, 53.733041212289294],
            [-2.900959155882951, 53.733078588110217],
            [-2.901054000656408, 53.733829427869551],
            [-2.901758405668373, 53.733806151158831],
            [-2.902083942848619, 53.736477744567665],
            [-2.902580779047081, 53.736104580128796],
            [-2.901917551658804, 53.733804953171408],
            [-2.90109796164981, 53.733545963097626],
            [-2.901019134830528, 53.733106134058424],
            [-2.90096025310772, 53.732777592963423],
            [-2.918728267197708, 53.731472363789393],
            [-2.92035807906076, 53.731693552527865],
            [-2.921663678194895, 53.731437233087789],
            [-2.929165305017827, 53.731494337853235],
            [-2.930524866788379, 53.731624903593826],
            [-2.930452249037834, 53.732111740580635],
            [-2.929630413720925, 53.732444399827372],
            [-2.927868742143041, 53.732502998904536],
            [-2.924985691514167, 53.733407055777114],
            [-2.927868304260813, 53.732620750425504],
            [-2.929689030418419, 53.732558996593056],
            [-2.930717704912596, 53.732257088524364],
            [-2.931091556642684, 53.73161420876788],
            [-2.933760253199182, 53.731924211678447],
            [-2.933083938324834, 53.732460696204555],
            [-2.929898059842607, 53.732963650356417],
            [-2.928945888310966, 53.73336742593473],
            [-2.929944017187264, 53.733054076551468],
            [-2.933171998283192, 53.732603824892536],
            [-2.934115978561141, 53.731969975988342],
            [-2.93650859901767, 53.732269486333649],
            [-2.933814677792774, 53.733695403281445],
            [-2.930344722915559, 53.73383205811421],
            [-2.928369464225558, 53.73376288972095],
            [-2.925800782921127, 53.734489255458215],
            [-2.922889573162283, 53.734600701723963],
            [-2.922025995996318, 53.73497048226848],
            [-2.926128889354299, 53.734588288504654],
            [-2.928482920754408, 53.73388964584597],
            [-2.929859186979954, 53.734224126714459],
            [-2.930393726418635, 53.733991671207399],
            [-2.933680514755791, 53.734002953698273],
            [-2.934530784365829, 53.734268673051872],
            [-2.934481132386683, 53.734756984446292],
            [-2.934447630889302, 53.735086448905776],
            [-2.934322960098982, 53.736312460228724],
            [-2.93205870815477, 53.737233431192692],
            [-2.931581518614802, 53.737455560734887],
            [-2.932093845573472, 53.737314053463287],
            [-2.934521663255636, 53.73631720236137],
            [-2.934546191697864, 53.735024275814396],
            [-2.934912319554872, 53.734793315939378],
            [-2.934302977706081, 53.733839905003791],
            [-2.937238193547245, 53.73242647110213],
            [-2.940079894214804, 53.732333197803023],
            [-2.940233191878736, 53.73286679028103],
            [-2.940535175332541, 53.733917877451795],
            [-2.938693781866349, 53.734550722056682],
            [-2.936322302049647, 53.736436171992828],
            [-2.939008704179473, 53.734532973883134],
            [-2.940829294358643, 53.733919163458395],
            [-2.940298005120446, 53.732794801222894],
            [-2.940772697240571, 53.732267537052266],
            [-2.94937266797093, 53.732690463462873],
            [-2.951541466682759, 53.733006727907309],
            [-2.951801767058858, 53.733524191111343],
            [-2.94960367875441, 53.734263404187217],
            [-2.943697912412443, 53.735699678013354],
            [-2.942553675842735, 53.735376114162037],
            [-2.941344406682715, 53.735465618589572],
            [-2.93783177444011, 53.736414478438938],
            [-2.94135393365688, 53.735552731338679],
            [-2.942419747455175, 53.735556037172074],
            [-2.942892055379516, 53.735864218956152],
            [-2.939140282014911, 53.737646426698177],
            [-2.936989459150154, 53.737599449128552],
            [-2.935983415610521, 53.737218114838271],
            [-2.93684730119774, 53.737751566035044],
            [-2.938910562614832, 53.737882824593875],
            [-2.938830434439824, 53.739184973758015],
            [-2.935099893028738, 53.740783522613157],
            [-2.933661775404202, 53.740543062732762],
            [-2.93277045904042, 53.741099196249955],
            [-2.931406588956972, 53.740788021776154],
            [-2.929453957317655, 53.739766823049592],
            [-2.928182938759601, 53.738299882604501],
            [-2.925029919290171, 53.737353520969698],
            [-2.922708369479964, 53.736985820634487],
            [-2.921306866627375, 53.737508944641348],
            [-2.918965694195824, 53.73783882206925],
            [-2.917581369878388, 53.737275073892739],
            [-2.916711195923829, 53.737207131483693],
            [-2.915515588400911, 53.737581202290173],
            [-2.913451021414151, 53.737315624184411],
            [-2.912707919021686, 53.736675022300403],
            [-2.911538301827135, 53.736569774291688],
            [-2.912848974808068, 53.736887871308596],
            [-2.912920359591609, 53.737383486452863],
            [-2.916444591096776, 53.737774540889035],
            [-2.916675622355257, 53.738358816514875],
            [-2.916162855000713, 53.738901143129645],
            [-2.916808316807251, 53.739161363197923],
            [-2.916478953556186, 53.739632178084939],
            [-2.917307319906466, 53.739727407588667],
            [-2.916821909784038, 53.740412442771415],
            [-2.917158317191545, 53.740613005625001],
            [-2.916600597780883, 53.74076288546371],
            [-2.917038536698113, 53.741100194177363],
            [-2.916261415519609, 53.742037336286842],
            [-2.916752986821859, 53.743166112436896],
            [-2.916039131530864, 53.744295119902674],
            [-2.916921888365624, 53.743054262892642],
            [-2.91649086433648, 53.74199063948646],
            [-2.91724256109566, 53.741069869596799],
            [-2.916842392207452, 53.740796090146766],
            [-2.917409226256167, 53.740647038230563],
            [-2.917065453135847, 53.74045641968133],
            [-2.917434701486817, 53.739659019348281],
            [-2.916671470253825, 53.739630705312599],
            [-2.916998473984326, 53.739121257875325],
            [-2.916374331338709, 53.738865369964522],
            [-2.916953343457092, 53.738441182201491],
            [-2.916983845689494, 53.737474696756657],
            [-2.919345951758784, 53.738034548286457],
            [-2.922706244630468, 53.73723481557461],
            [-2.925383261154842, 53.737703137534986],
            [-2.928063650143511, 53.738665735654671],
            [-2.928159809850156, 53.738901385622178],
            [-2.925760739819748, 53.738253906652261],
            [-2.923778698547149, 53.739888913873408],
            [-2.921337985399663, 53.739832204905774],
            [-2.919798820758519, 53.740305135327624],
            [-2.921350307259993, 53.739910309155036],
            [-2.924007703444032, 53.740099274056135],
            [-2.926029665243473, 53.738418113740259],
            [-2.927847505476245, 53.738902006908738],
            [-2.931514183883964, 53.741265368038519],
            [-2.93415258094102, 53.741343698806197],
            [-2.935013211314766, 53.741249798210958],
            [-2.935000094738045, 53.742093012386214],
            [-2.934750300980869, 53.742246865355312],
            [-2.936782995924328, 53.744606623442976],
            [-2.935489060767467, 53.741580452282186],
            [-2.939871620266101, 53.74168098012121],
            [-2.941708541176058, 53.742123167511885],
            [-2.944852708530374, 53.741493494507573],
            [-2.94335486419276, 53.741500802836001],
            [-2.941596929036674, 53.741946074302369],
            [-2.939890760137485, 53.741451625708216],
            [-2.935888260098448, 53.741190833557447],
            [-2.939571253771006, 53.739227703895388],
            [-2.940346827880036, 53.737567752254122],
            [-2.943595039468723, 53.736250579208722],
            [-2.949691158417713, 53.734848755803149],
            [-2.95794651128835, 53.733423082960279],
            [-2.959729904083387, 53.733519367514646],
            [-2.960127455170958, 53.734134586136094],
            [-2.960923949410599, 53.733829784449739],
            [-2.963378771246629, 53.734317017405203],
            [-2.971140575152301, 53.734257947306538],
            [-2.974771152624123, 53.733925552496451],
            [-2.980086633969824, 53.734408010303675],
            [-2.991657062414855, 53.737205282313369],
            [-2.994680879550834, 53.737431892906088],
            [-3.000823153279948, 53.736695820908984],
            [-3.004715959651557, 53.736807054582918],
            [-3.011360609461137, 53.738252222397932],
            [-3.015468222769293, 53.738587803361362],
            [-3.018743830309896, 53.739624230903409],
            [-3.022485950175683, 53.741508696060258],
            [-3.026872094648852, 53.743052257423379],
            [-3.029037305927975, 53.743981965187444],
            [-3.034773503961392, 53.748054677730039],
            [-3.035471497024273, 53.747763713475322],
            [-3.034925026281843, 53.747371144187277],
            [-3.035437812879159, 53.746702466011527],
            [-3.037732586364543, 53.746905528110268],
            [-3.039020559311084, 53.747480415213282],
            [-3.040230496577009, 53.749068967072823],
            [-3.044868443064334, 53.752022675541156],
            [-3.047303115015956, 53.754177530890658],
            [-3.048424849661936, 53.758293429728006],
            [-3.049730374768566, 53.759187139678943],
            [-3.049479737307761, 53.757287375047774],
            [-3.050608831976607, 53.759255843605878],
            [-3.05169569460451, 53.760926254885177],
            [-3.052866309875395, 53.761635952353792],
            [-3.055025716586223, 53.761970208408314],
            [-3.054721482182887, 53.76405461493588],
            [-3.055108724205332, 53.763768069695189],
            [-3.055384635962828, 53.764304948153359],
            [-3.055220722774223, 53.766963380898318],
            [-3.055522820213085, 53.766910385312912],
            [-3.056053255466676, 53.768338473086665],
            [-3.055956601618975, 53.77065295357022],
            [-3.056714363144865, 53.773215175292492],
            [-3.056401589212328, 53.776046597649682],
            [-3.056853159997164, 53.776564845713992],
            [-3.039774388834993, 53.779591412538629],
            [-3.032768434665631, 53.780734186406427],
            [-3.028804324649077, 53.773102049169367],
            [-3.015799635901836, 53.77513469319063],
            [-3.015470297531807, 53.774566719491304],
            [-2.996481211879469, 53.774489698785906],
            [-3.000693318746014, 53.780603569885365],
            [-3.000149222344967, 53.780825630059837],
            [-3.001028525219459, 53.781679378239438],
            [-3.001436756099997, 53.781614847330843],
            [-3.006125282249297, 53.787977118849277],
            [-3.003991499042336, 53.787801758261153],
            [-3.003890527702989, 53.791327847930006],
            [-3.003083753865704, 53.791107195096551],
            [-3.001333520824565, 53.792063809068779],
            [-3.002207303409066, 53.792738725438873],
            [-2.999503554531838, 53.793501041020022],
            [-2.999349823726924, 53.794250155285006],
            [-2.99056404606893, 53.793650740345619],
            [-2.984614241252532, 53.793762672150898],
            [-2.983360916028239, 53.793789138176258],
            [-2.984829483169825, 53.795109158309977],
            [-2.986468447555972, 53.798122064072615],
            [-2.989541506358991, 53.800448093792831],
            [-2.986678897725443, 53.802170484608332],
            [-2.992313250133077, 53.808195616037324],
            [-2.993985033097678, 53.81216540358885],
            [-2.993548233205282, 53.812897079274578],
            [-2.994818802592192, 53.814985322467628],
            [-2.993307637374838, 53.815888593949531],
            [-2.994514519168162, 53.817657382463622],
            [-2.995652280555447, 53.818749012262117],
            [-2.998227983924401, 53.818982868007332],
            [-2.998546989883005, 53.820388684239454],
            [-2.99998499031691, 53.820046829901294],
            [-3.002235037708708, 53.821906608558592],
            [-3.002769525486553, 53.823168596813879],
            [-3.006141812940846, 53.824362765138005],
            [-3.010101745918867, 53.824841812523736],
            [-3.011672070231994, 53.825575506871623],
            [-3.010658072057928, 53.826179084175074],
            [-3.009117112689572, 53.825339965994949],
            [-3.007184329136973, 53.826973214658004],
            [-3.004959665193786, 53.827273211695797],
            [-3.002424231009511, 53.828684924695239],
            [-2.993963713736827, 53.829487889296246],
            [-2.993006326956957, 53.829480539813481],
            [-2.993123135840436, 53.831013878026347],
            [-2.992013736264824, 53.831005082639926],
            [-2.991981337435833, 53.830662895872884],
            [-2.987199212265411, 53.830190019826382],
            [-2.986637533342568, 53.829241880858341],
            [-2.986034791688418, 53.829002355786031],
            [-2.986047041143807, 53.828424306636109],
            [-2.970327300010746, 53.82918709380165],
            [-2.966651878237387, 53.826875322500683],
            [-2.963465488893466, 53.827584073462674],
            [-2.961782514110832, 53.828331925444026],
            [-2.965078533086092, 53.829876601753142],
            [-2.967324492076906, 53.83252623331321],
            [-2.968606526562361, 53.834812394680498],
            [-2.969275485790666, 53.838708805543803],
            [-2.970787902146244, 53.840495129716928],
            [-2.967913277069536, 53.842176698173134],
            [-2.965591200084749, 53.844630336533577],
            [-2.965510425569705, 53.847196238929392],
            [-2.968230672094214, 53.849014211961581],
            [-2.97190962484434, 53.849881490864476],
            [-2.972195992521485, 53.850955964978141],
            [-2.974048853113164, 53.852190304032511],
            [-2.977810116041425, 53.85278888429437],
            [-2.97950639388101, 53.854468436277628],
            [-2.979661144224497, 53.855235669599921],
            [-2.978567050164191, 53.856822039619779],
            [-2.978699365756686, 53.857344976078394],
            [-2.977715708666837, 53.857607364377763],
            [-2.977730727551195, 53.857925426465791],
            [-2.978052395549661, 53.858092682695833],
            [-2.980788743191298, 53.857360270877123],
            [-2.981010112769551, 53.857529237969395],
            [-2.981822231662836, 53.858058295106567],
            [-2.980788507820823, 53.858845134069725],
            [-2.979511479442807, 53.858786357479765],
            [-2.978988844422012, 53.859063866526057],
            [-2.979184932348169, 53.859555036911786],
            [-2.979255308887634, 53.859731317319365],
            [-2.979099674206344, 53.859846103881992],
            [-2.978539324325028, 53.86025937710599],
            [-2.978714316695457, 53.861161643626524],
            [-2.978715637268418, 53.861168449548821],
            [-2.97871078190819, 53.861173712233644],
            [-2.977997848397538, 53.861946394114689],
            [-2.976791666487449, 53.861930154361957],
            [-2.972958129361552, 53.860283196912803],
            [-2.971433939384446, 53.86012387230744],
            [-2.969069970470318, 53.860206806685056],
            [-2.963640918033405, 53.861226659514408],
            [-2.959990229777398, 53.86103211889948],
            [-2.958658532688938, 53.860578973837136],
            [-2.957611726452392, 53.859749629555488],
            [-2.955514770364339, 53.856270820669415],
            [-2.951227031394252, 53.852703304390154],
            [-2.947544641846049, 53.85086828408415],
            [-2.943951266681541, 53.850069694730898],
            [-2.94116900417493, 53.850592200865158],
            [-2.937878764895199, 53.851650718500061],
            [-2.938045137780992, 53.852020631954453],
            [-2.936430428475683, 53.853030042724249],
            [-2.935101635645962, 53.854797607554154],
            [-2.933404506684874, 53.856141981045361],
            [-2.929355612500713, 53.855720408146219],
            [-2.924697490660119, 53.85572764046541],
            [-2.920682761602281, 53.856509933483615],
            [-2.912331031972982, 53.859774438546339],
            [-2.911669897837969, 53.860556045368774],
            [-2.911715720656644, 53.862872856323918],
            [-2.910889666648284, 53.863695259435616],
            [-2.906320164911778, 53.864395889988081],
            [-2.90370258439677, 53.864510909418691],
            [-2.901444718873001, 53.864100056751717],
            [-2.898677083189148, 53.861613113189748],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000120",
        LAD13CDO: "30UG",
        LAD13NM: "Hyndburn",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.396736431871722, 53.816605137137735],
            [-2.393912945872508, 53.815156595856259],
            [-2.39340382890355, 53.81431249751504],
            [-2.395192942340573, 53.812214207052577],
            [-2.39870620822869, 53.810508334991908],
            [-2.399386217700299, 53.80983376865867],
            [-2.39898760792313, 53.807838860288484],
            [-2.397827363306062, 53.806067585268558],
            [-2.398877172151034, 53.803566328252835],
            [-2.397130253677727, 53.803003187788512],
            [-2.393175826501201, 53.803264293821549],
            [-2.389732007611777, 53.802431566940541],
            [-2.387985370494007, 53.802537899328733],
            [-2.386202464395499, 53.802498717377347],
            [-2.383974640610509, 53.802454643487977],
            [-2.381331939427938, 53.802871136121588],
            [-2.379168480470882, 53.802558026720348],
            [-2.378784042657531, 53.802357012114015],
            [-2.379167661718525, 53.800953669739393],
            [-2.378697271720888, 53.800479691193999],
            [-2.377125985422335, 53.800832481637556],
            [-2.374710127236273, 53.800633334749904],
            [-2.37104730140127, 53.801582179529319],
            [-2.369805801773835, 53.801464678519842],
            [-2.369034615825785, 53.798713129835392],
            [-2.369614515506982, 53.797153720414755],
            [-2.371432075735964, 53.795473634060649],
            [-2.369745644872618, 53.794113569230433],
            [-2.36517958089382, 53.793091251458719],
            [-2.363510191700084, 53.79349090076613],
            [-2.362220250689199, 53.794591344440711],
            [-2.360786319323994, 53.794665776420466],
            [-2.359606878148967, 53.794322384034828],
            [-2.35920551241694, 53.793364566586568],
            [-2.356643777164312, 53.792908436184391],
            [-2.356433946192586, 53.79322543859395],
            [-2.357345997256699, 53.794799220044801],
            [-2.358807354207125, 53.795268521878015],
            [-2.358586353080116, 53.796232698192242],
            [-2.3593005725309, 53.797202162939669],
            [-2.356031168259368, 53.79782310479056],
            [-2.354713003298328, 53.797597819328395],
            [-2.347966590527216, 53.794340569793853],
            [-2.344631734916123, 53.793268050206919],
            [-2.343665590535003, 53.793369692941674],
            [-2.342305065392087, 53.794813466048943],
            [-2.340851506202249, 53.794157889405312],
            [-2.341271351725542, 53.793703698826924],
            [-2.340801367785854, 53.793216987408108],
            [-2.341094644586345, 53.792506100133359],
            [-2.336232014804386, 53.79168305875335],
            [-2.337838991988622, 53.78953939183333],
            [-2.338756686812112, 53.789085602642629],
            [-2.337379260781734, 53.786349042187851],
            [-2.335584058411483, 53.785058010852829],
            [-2.336097497120103, 53.783406370857541],
            [-2.33579719127032, 53.782480547541496],
            [-2.333287976013706, 53.782024670198851],
            [-2.331604459455614, 53.781193454963088],
            [-2.3300547766437, 53.780970336829768],
            [-2.3291872646117, 53.780070325604278],
            [-2.329314192673515, 53.779613385023453],
            [-2.331195320060271, 53.778766920386516],
            [-2.331687996162463, 53.777933265897431],
            [-2.33037409797758, 53.773521075184071],
            [-2.331168410319683, 53.770921338855089],
            [-2.330706586754176, 53.77026738636053],
            [-2.331317504712207, 53.770010437985711],
            [-2.331427556749623, 53.769536463718843],
            [-2.330372092651572, 53.769018072500181],
            [-2.330118439196497, 53.768014807173465],
            [-2.328757235974632, 53.766998407242951],
            [-2.329069747645636, 53.766610164395161],
            [-2.328645992909862, 53.766352471912036],
            [-2.328425661978511, 53.764364020984878],
            [-2.327857828728354, 53.763523396073559],
            [-2.328363581475616, 53.762824537973877],
            [-2.32804306921505, 53.761795385221127],
            [-2.328630517186789, 53.761460318234199],
            [-2.328608853152115, 53.761017266556237],
            [-2.328006664789212, 53.760822977391854],
            [-2.327222986943492, 53.75972138744099],
            [-2.326493701252248, 53.75956428882823],
            [-2.325824906285054, 53.75859989368751],
            [-2.322138936599003, 53.757381195683962],
            [-2.319916699285284, 53.755554486151112],
            [-2.316402744611965, 53.751942529512483],
            [-2.313724746906659, 53.751560390832331],
            [-2.314109436125508, 53.747024002901306],
            [-2.326148498731402, 53.74732078688443],
            [-2.325601055183268, 53.743200343741584],
            [-2.324888503832524, 53.743012630321672],
            [-2.324483988234611, 53.742659596579728],
            [-2.323807549998687, 53.737704498335084],
            [-2.326258007349854, 53.737779639108233],
            [-2.327697043376796, 53.735291401441998],
            [-2.325930012138291, 53.735164997602723],
            [-2.325772024627816, 53.734338522838009],
            [-2.327379078517433, 53.734170556912041],
            [-2.329563038335171, 53.732179095760415],
            [-2.331146273391687, 53.732464144888183],
            [-2.331801208388879, 53.731322640933236],
            [-2.335793822166401, 53.730377647850617],
            [-2.33455572232719, 53.729266588381051],
            [-2.336708070108013, 53.728323995088786],
            [-2.338166420081074, 53.729678887221056],
            [-2.33950631648753, 53.727610527741923],
            [-2.342818272392228, 53.725967053374809],
            [-2.342253221830346, 53.724321148604567],
            [-2.342768973947584, 53.72326177219616],
            [-2.344185260885136, 53.722434398088815],
            [-2.349877495963206, 53.722452954716339],
            [-2.350131441551308, 53.721827538114738],
            [-2.352913961864141, 53.722209454006631],
            [-2.355285189179757, 53.722154804870577],
            [-2.360801355289579, 53.716482981542491],
            [-2.36973212464648, 53.714052310289489],
            [-2.382900237700436, 53.711198534699975],
            [-2.39575581949218, 53.709615266226926],
            [-2.404591623125234, 53.706489315496093],
            [-2.408348790697053, 53.705262259948967],
            [-2.41099082703699, 53.705121094078393],
            [-2.413069387849411, 53.704677116382975],
            [-2.414016192778025, 53.704989330833868],
            [-2.419967717777766, 53.708305909297046],
            [-2.419655648815217, 53.709828699152105],
            [-2.418347212165547, 53.711408901910211],
            [-2.425873803180615, 53.714679225726442],
            [-2.432691628179072, 53.718907080422305],
            [-2.433870201140438, 53.719166169082335],
            [-2.4326705725278, 53.719660363512915],
            [-2.432297367237211, 53.720357394063868],
            [-2.433553994063424, 53.72760808095623],
            [-2.438587507864753, 53.732268078992952],
            [-2.439763046666633, 53.73335852317787],
            [-2.439867987342345, 53.73585863562699],
            [-2.439962952715171, 53.738998738032357],
            [-2.440830129628754, 53.740155916406636],
            [-2.440968368912946, 53.741629459431188],
            [-2.441831373470925, 53.742381281391246],
            [-2.441282568914289, 53.743398961744553],
            [-2.441988473284805, 53.745213756331296],
            [-2.441505726293347, 53.745882454618105],
            [-2.440770332862357, 53.746028972557731],
            [-2.440901774306184, 53.746707091367895],
            [-2.439371235370715, 53.747816454839715],
            [-2.439652107217772, 53.748284603681604],
            [-2.440068679850998, 53.749402992469648],
            [-2.441022238400327, 53.750244367913517],
            [-2.440684181160307, 53.750400207400759],
            [-2.442038856483579, 53.751454914205823],
            [-2.44192912755552, 53.751981123190149],
            [-2.444836149552769, 53.753112752360025],
            [-2.444642405801815, 53.753579055367418],
            [-2.445336706119872, 53.753694220635865],
            [-2.445473381699205, 53.754570951336156],
            [-2.446801344864, 53.75537134007434],
            [-2.448396977027506, 53.756093414910637],
            [-2.45024030170801, 53.756003811206782],
            [-2.450634697917091, 53.756292644073845],
            [-2.451043819263537, 53.755973824530216],
            [-2.451781372213735, 53.756439325655904],
            [-2.451146431185168, 53.757339629548802],
            [-2.452195082584808, 53.760922826026302],
            [-2.451126188372479, 53.762807164574504],
            [-2.448914665782392, 53.764384791446716],
            [-2.448061479217114, 53.765890793206211],
            [-2.446318083092179, 53.766575001540012],
            [-2.45233576277136, 53.769632651703738],
            [-2.4536045687626, 53.771081228051798],
            [-2.453126657544988, 53.771645690029743],
            [-2.453534001898552, 53.77297887863233],
            [-2.455548416392741, 53.774048907977914],
            [-2.459473922881959, 53.777258842136888],
            [-2.460793479957299, 53.778033041391502],
            [-2.462802315460153, 53.778557394182485],
            [-2.465809023362407, 53.780799054573492],
            [-2.4654015352817, 53.781002869315834],
            [-2.464909148320683, 53.780665930233269],
            [-2.463912377327528, 53.780965500435265],
            [-2.462003766193865, 53.780873106013672],
            [-2.459808507197451, 53.781350726078493],
            [-2.455042555201214, 53.783387636585118],
            [-2.449661238078461, 53.786822511753442],
            [-2.447479430219189, 53.787465234558972],
            [-2.447602156422366, 53.789012518902162],
            [-2.444361210116083, 53.790993860482743],
            [-2.440055044175856, 53.795218848867542],
            [-2.436965366232021, 53.796789577216906],
            [-2.421137844918806, 53.80412653460349],
            [-2.407447492884465, 53.813167253406569],
            [-2.405944526142834, 53.813541759248487],
            [-2.401305212633345, 53.813908810681845],
            [-2.399968576626021, 53.815808846182009],
            [-2.398709119953898, 53.815772595729818],
            [-2.396736431871722, 53.816605137137735],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000121",
        LAD13CDO: "30UH",
        LAD13NM: "Lancaster",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-2.88339646534952, 53.946316505436428],
              [-2.887307385715094, 53.946163637376095],
              [-2.888719429598862, 53.947078966384531],
              [-2.885820486847267, 53.947104878633603],
              [-2.884235220980232, 53.947432941086809],
              [-2.88339646534952, 53.946316505436428],
            ],
          ],
          [
            [
              [-2.893480751281811, 53.98889731910559],
              [-2.898295949351638, 53.987774764424287],
              [-2.900204314987247, 53.987794635592607],
              [-2.901237058553774, 53.988370215245226],
              [-2.90126782726189, 53.989018019978097],
              [-2.900065415031201, 53.990546905642347],
              [-2.899236453284951, 53.990723884731572],
              [-2.897203769404832, 53.99053235643423],
              [-2.893333384928569, 53.98956532474886],
              [-2.893480751281811, 53.98889731910559],
            ],
          ],
          [
            [
              [-2.839991824984789, 54.022991549499352],
              [-2.838276424702754, 54.02292982460343],
              [-2.837072438047453, 54.022947203485018],
              [-2.836521471190997, 54.022646349094103],
              [-2.837540862908955, 54.022102671601402],
              [-2.837673122366758, 54.021240707654911],
              [-2.837328383076841, 54.022087074994921],
              [-2.836408140982492, 54.022475468657291],
              [-2.836352330873569, 54.022812903739265],
              [-2.834715192405023, 54.022771255668424],
              [-2.834029938522084, 54.022394925181771],
              [-2.834229782889065, 54.020635502551073],
              [-2.835124142592183, 54.021013075552951],
              [-2.83566874326099, 54.020617417207006],
              [-2.835009836642637, 54.020869164308152],
              [-2.834339377653157, 54.020239273585815],
              [-2.834561818314925, 54.01762763820588],
              [-2.835303011817853, 54.017143433068298],
              [-2.836173416941263, 54.017167040690282],
              [-2.836624238576182, 54.018033035230822],
              [-2.836007073639318, 54.01815686904019],
              [-2.835530768818642, 54.019369056127154],
              [-2.836013299331428, 54.019228185581518],
              [-2.835791878351636, 54.018765948990811],
              [-2.836205895373841, 54.018253454222553],
              [-2.836959644902365, 54.01809091906987],
              [-2.838116401886619, 54.020285797055699],
              [-2.839797061660779, 54.02067402807576],
              [-2.840062505194927, 54.021121568976469],
              [-2.841057419953399, 54.021411212363411],
              [-2.841246741272562, 54.022321262228012],
              [-2.839991824984789, 54.022991549499352],
            ],
          ],
          [
            [
              [-2.792987137416435, 54.061138935085602],
              [-2.793239763905264, 54.060125239528887],
              [-2.79456189782246, 54.061197745976109],
              [-2.793575699478067, 54.062199210795072],
              [-2.792987137416435, 54.061138935085602],
            ],
          ],
          [
            [
              [-2.803298459720053, 54.120486070790641],
              [-2.804519206499592, 54.120239739805974],
              [-2.805565566902172, 54.120390024861543],
              [-2.805603259089478, 54.121071043709506],
              [-2.803875030191519, 54.122528729682386],
              [-2.802596205396299, 54.123494460477787],
              [-2.80152740890408, 54.123612133561323],
              [-2.800326761578484, 54.123399026570716],
              [-2.800646517105501, 54.12244509781447],
              [-2.803298459720053, 54.120486070790641],
            ],
          ],
          [
            [
              [-2.827945757755899, 54.140930606980852],
              [-2.828299016457834, 54.140530019823153],
              [-2.831155787603227, 54.140933648037773],
              [-2.828537206566515, 54.141496359189482],
              [-2.827945757755899, 54.140930606980852],
            ],
          ],
          [
            [
              [-2.459581955833722, 54.239557465073247],
              [-2.458776052044503, 54.232473982142565],
              [-2.459467255696333, 54.229514494879851],
              [-2.460859481326116, 54.226705107774272],
              [-2.463966061907489, 54.222098819830983],
              [-2.465608319734042, 54.220705725097595],
              [-2.46901237613051, 54.218888743802133],
              [-2.473695942825445, 54.215270952752128],
              [-2.474767115953417, 54.213738872692112],
              [-2.475690412336573, 54.210287651530123],
              [-2.477046528388195, 54.208885640276321],
              [-2.479626519920056, 54.204616245571863],
              [-2.479320711388547, 54.203361917348339],
              [-2.479955348709061, 54.202301567366007],
              [-2.511435872222731, 54.179038221028762],
              [-2.51533947633687, 54.177910720558671],
              [-2.518197729723121, 54.17663750570096],
              [-2.518728626838477, 54.175968346799593],
              [-2.522535921333692, 54.173680739439369],
              [-2.523649881883313, 54.172367317966973],
              [-2.52327990115017, 54.171212234190698],
              [-2.526563643266464, 54.17044925594363],
              [-2.527748117220614, 54.169637889084498],
              [-2.528719041862581, 54.169500613457352],
              [-2.529298798526231, 54.168479780094529],
              [-2.529357628332538, 54.16696961824691],
              [-2.530240559944462, 54.166424683615851],
              [-2.528904481140497, 54.164226820925542],
              [-2.530448741562145, 54.16402140152055],
              [-2.52995319140065, 54.16263680901109],
              [-2.53079035058208, 54.161515071940578],
              [-2.531556291350919, 54.160933793845949],
              [-2.532993180039393, 54.160844754671587],
              [-2.533295138014338, 54.158246020251433],
              [-2.536519544664538, 54.156541135243906],
              [-2.540324114268062, 54.157545999710145],
              [-2.54297553238564, 54.157715616615967],
              [-2.543760210687465, 54.157530526049676],
              [-2.5440655118861, 54.156523440553222],
              [-2.547220277401587, 54.153297889039614],
              [-2.552285434604255, 54.153645939643546],
              [-2.557672949181378, 54.153478196190903],
              [-2.560502781129578, 54.153040838905085],
              [-2.561512896495988, 54.15051961460275],
              [-2.558493168800054, 54.149917118287334],
              [-2.557376189781906, 54.149968132767775],
              [-2.558196087125104, 54.147410068278212],
              [-2.557872070778385, 54.143352786072825],
              [-2.558314448606338, 54.142895963629584],
              [-2.560949583741257, 54.142830683227139],
              [-2.561328053525965, 54.141952630328667],
              [-2.563408616664558, 54.140394348973238],
              [-2.56266389117242, 54.136497224332963],
              [-2.563583623600767, 54.136149594838571],
              [-2.563713334435398, 54.135445259580955],
              [-2.563240977071746, 54.135149083628114],
              [-2.56222198227387, 54.135172722677879],
              [-2.562053957739656, 54.132824153026284],
              [-2.562487469002751, 54.131724743010032],
              [-2.56138677374768, 54.129888326187682],
              [-2.562470925249165, 54.129148977082195],
              [-2.563188305500741, 54.127884662267157],
              [-2.564736328141541, 54.127055041828392],
              [-2.563298197235655, 54.125382899919202],
              [-2.563412616975111, 54.124681331530503],
              [-2.563007961086872, 54.124403711336939],
              [-2.56332751270126, 54.122694571568346],
              [-2.560763249115929, 54.12236142608392],
              [-2.560633537116836, 54.121706834405622],
              [-2.559211292210516, 54.12107443461155],
              [-2.556871666325363, 54.120597259272401],
              [-2.55308105940858, 54.120531159378338],
              [-2.544762245257153, 54.117118773655108],
              [-2.544668964233817, 54.116538596923341],
              [-2.545651232786523, 54.115966129436615],
              [-2.545436391666752, 54.115682196609129],
              [-2.54605519379328, 54.115159007280162],
              [-2.545833526509068, 54.114355622508235],
              [-2.54473830409949, 54.114010968722681],
              [-2.544677312159432, 54.113209550374684],
              [-2.543996827318999, 54.113227009506979],
              [-2.543796107019905, 54.112617658438786],
              [-2.542077364003201, 54.111726654475007],
              [-2.541783814135337, 54.111854702112311],
              [-2.539089335629833, 54.109909305555227],
              [-2.538585066589551, 54.109711140554744],
              [-2.538209557027897, 54.109888079522754],
              [-2.537457559440975, 54.109103228213698],
              [-2.536663146103459, 54.109058241709647],
              [-2.536198556145379, 54.108614527959475],
              [-2.536951395253405, 54.106863978280025],
              [-2.53672917328455, 54.106474008816086],
              [-2.53812576139568, 54.105663377904214],
              [-2.536764104162271, 54.104087641102332],
              [-2.534424676588344, 54.10341230224055],
              [-2.531532414017338, 54.10326963148686],
              [-2.530040137546422, 54.102697413825865],
              [-2.527500126321794, 54.102589942823947],
              [-2.526303602907535, 54.101938189338277],
              [-2.524890753318513, 54.102317348825487],
              [-2.5241067664067, 54.102117647226493],
              [-2.524490649702919, 54.10030317188162],
              [-2.526389282238918, 54.099165134393559],
              [-2.525882978108096, 54.098428566677072],
              [-2.525587939556947, 54.096017578444766],
              [-2.525188275144902, 54.095971689807484],
              [-2.525196904567145, 54.095323644408992],
              [-2.5240075010317, 54.094610720874492],
              [-2.520520658157475, 54.095530915082399],
              [-2.517239830597146, 54.094956379463831],
              [-2.509523888177335, 54.095413529487629],
              [-2.508547492025878, 54.094217814773224],
              [-2.50875165713376, 54.09241043959981],
              [-2.506303004580215, 54.09146269204664],
              [-2.494203378516756, 54.089791890641735],
              [-2.492563803357017, 54.08986422647957],
              [-2.491564290426159, 54.08730774289814],
              [-2.488395876739209, 54.086713088594699],
              [-2.486355998454782, 54.084834853536236],
              [-2.484724511990383, 54.085191957892889],
              [-2.480748967856214, 54.085366997193184],
              [-2.47072201169025, 54.076552056972588],
              [-2.466884253657356, 54.075726676054906],
              [-2.464345188131777, 54.075267353763422],
              [-2.465610617064844, 54.071718636941362],
              [-2.463988136884606, 54.061071856531385],
              [-2.469013333178924, 54.054868849409281],
              [-2.470060938660507, 54.052273614176265],
              [-2.470544236993534, 54.050842684313039],
              [-2.46954793279007, 54.04619995408104],
              [-2.486655938342506, 54.042543126053651],
              [-2.491072247876486, 54.040726727776843],
              [-2.492255472616776, 54.040062191230561],
              [-2.493987980440637, 54.036532805213163],
              [-2.492832732016316, 54.034131540298823],
              [-2.492888371624061, 54.032124357898603],
              [-2.495592197704771, 54.028918108573045],
              [-2.497888140012351, 54.027380707699947],
              [-2.497759421637882, 54.025806593131676],
              [-2.499185272102742, 54.025052002137144],
              [-2.500491142172872, 54.024860525023847],
              [-2.498934382195055, 54.023986201746844],
              [-2.497753840292459, 54.022017393699635],
              [-2.501416967016082, 54.020741187527939],
              [-2.502679510919441, 54.020386290368023],
              [-2.504206016678013, 54.020530889094736],
              [-2.512879464389286, 54.02235464501512],
              [-2.518677867056293, 54.021233255724781],
              [-2.521849928143152, 54.022482299608065],
              [-2.527308346726729, 54.026233320561943],
              [-2.531983947169866, 54.023837257322036],
              [-2.538725886052017, 54.022639717289302],
              [-2.544143988367949, 54.019679011361212],
              [-2.545857459704715, 54.014861895266229],
              [-2.547104496402913, 54.014065301725786],
              [-2.549194444887493, 54.0136612057739],
              [-2.556889715053043, 54.015447608987763],
              [-2.564306995933894, 54.015252126201347],
              [-2.566950364702385, 54.014815461713845],
              [-2.568110160591489, 54.015037374762734],
              [-2.571424130558853, 54.014652264972298],
              [-2.5721850754998, 54.014610895554902],
              [-2.574774495967012, 54.012926813108614],
              [-2.579259781617487, 54.007569232683437],
              [-2.579587751171377, 54.00646574912092],
              [-2.578946304347612, 54.004772846619801],
              [-2.580491666384166, 54.00147136636209],
              [-2.580652679275254, 54.000106241559244],
              [-2.577418898900004, 53.994403790446356],
              [-2.57722853479415, 53.992992721715396],
              [-2.574325116044858, 53.99171598814241],
              [-2.571314530615755, 53.989394412699269],
              [-2.57091611108084, 53.987808161572111],
              [-2.572241388531193, 53.986805108183688],
              [-2.573967804773976, 53.98614345832177],
              [-2.57382467641151, 53.985161774334642],
              [-2.572189038713625, 53.983543680939022],
              [-2.569293902919455, 53.981808407661305],
              [-2.565802067404218, 53.979006326604498],
              [-2.561054718965796, 53.976116541466318],
              [-2.557525632309743, 53.974899845071263],
              [-2.566733035767342, 53.973454654333572],
              [-2.574108536253426, 53.972983718210806],
              [-2.577423018962083, 53.971998941821461],
              [-2.578350002834164, 53.971234111120765],
              [-2.579305460732422, 53.967469894033698],
              [-2.581642612623408, 53.964756852650673],
              [-2.588846059258145, 53.960203557541085],
              [-2.593811761435519, 53.959361212389659],
              [-2.602918550185112, 53.95707516383986],
              [-2.606576650838885, 53.956693633029666],
              [-2.611458664264464, 53.956849531611937],
              [-2.613737626220306, 53.95575127258784],
              [-2.615078590919947, 53.953364421950241],
              [-2.616902200555249, 53.952200125080154],
              [-2.620047669001316, 53.952736680186788],
              [-2.623652512986556, 53.954947907316864],
              [-2.630742009144599, 53.957794194546238],
              [-2.637314778708732, 53.958475829174141],
              [-2.643043478655427, 53.957576163733378],
              [-2.644643685232824, 53.956885404144813],
              [-2.646162722382147, 53.955285485040207],
              [-2.650833366508193, 53.952243026509343],
              [-2.649699703809125, 53.949894334881051],
              [-2.648218602369951, 53.948909175573675],
              [-2.655268757078362, 53.949302331618753],
              [-2.662672418742242, 53.947381432897323],
              [-2.66415594111527, 53.947254596579398],
              [-2.673650030179774, 53.950208170519993],
              [-2.679876647354945, 53.950247679960306],
              [-2.684581507872161, 53.949384175879111],
              [-2.687918876746386, 53.949689568390824],
              [-2.688856465810148, 53.949165590485151],
              [-2.696834436609941, 53.947331861980189],
              [-2.699005925633152, 53.947077466161524],
              [-2.700332277923658, 53.946034327930128],
              [-2.702051698567683, 53.943543616325087],
              [-2.702645283757573, 53.944868562271139],
              [-2.701589821631691, 53.946341565175963],
              [-2.700553793338049, 53.949159938632889],
              [-2.703375721630084, 53.953507084189134],
              [-2.706503265662134, 53.955884907670139],
              [-2.706376739817856, 53.958315986818512],
              [-2.709335557651689, 53.960419697964426],
              [-2.710516293572288, 53.963618709833838],
              [-2.711831385704328, 53.965746452124186],
              [-2.710406299544006, 53.969696987333052],
              [-2.711042186048919, 53.973199407131339],
              [-2.712505480007095, 53.976129780612119],
              [-2.714861451463145, 53.978553301964787],
              [-2.71565871843134, 53.98023198815828],
              [-2.719801593880477, 53.979430681948116],
              [-2.720209023664013, 53.978476421315705],
              [-2.722549472889767, 53.977239113171159],
              [-2.724183065699021, 53.978592741428493],
              [-2.725165146658196, 53.978775563087972],
              [-2.727453632935516, 53.97850107758476],
              [-2.72827397681531, 53.977890322217746],
              [-2.728990676131819, 53.977806881550464],
              [-2.730854114855745, 53.9756411568248],
              [-2.735240554774156, 53.974514268492939],
              [-2.735143115429886, 53.974002553580291],
              [-2.733957972895297, 53.973105616180796],
              [-2.734507270092643, 53.971831366907644],
              [-2.73536413998374, 53.971489976081315],
              [-2.735134495815153, 53.970569220950445],
              [-2.736446406378514, 53.968024792383467],
              [-2.735773792335972, 53.967398860705266],
              [-2.733907584336995, 53.967550489468557],
              [-2.733106103695795, 53.967135647732213],
              [-2.734407784330855, 53.965482006344871],
              [-2.736021961995365, 53.964441210146276],
              [-2.736212150730173, 53.963729997866885],
              [-2.739988862529922, 53.96242061548147],
              [-2.739440282577218, 53.960103308229264],
              [-2.740522840027683, 53.959010895066925],
              [-2.741606815880303, 53.95860244561797],
              [-2.741831370854249, 53.95700120454547],
              [-2.741479592499653, 53.956844290217582],
              [-2.742438331154597, 53.956166967618231],
              [-2.753069660397592, 53.953388210502418],
              [-2.755378774182324, 53.952318522253137],
              [-2.755700849399089, 53.952685901258754],
              [-2.753180374252628, 53.954795929246629],
              [-2.753460595627249, 53.954870569185566],
              [-2.752421241488295, 53.954952584109115],
              [-2.752307984066521, 53.9554332504621],
              [-2.750782234646812, 53.956604041770241],
              [-2.75258465277274, 53.95717158505331],
              [-2.753546039326645, 53.957833361860111],
              [-2.756023030045758, 53.958279776047775],
              [-2.757385562658162, 53.959206837478739],
              [-2.75645650937527, 53.96041977445654],
              [-2.75754430129212, 53.961320701398407],
              [-2.757872207188139, 53.963096449097073],
              [-2.759859697242141, 53.963510819507171],
              [-2.759779153436022, 53.963859162618093],
              [-2.760702067682966, 53.964486072583568],
              [-2.766040953172515, 53.965200837567657],
              [-2.768867302535682, 53.965052453506644],
              [-2.7700956119634, 53.964624847164124],
              [-2.771011310091951, 53.963530531396273],
              [-2.774488248617347, 53.963868579153967],
              [-2.774991717227533, 53.96479738282968],
              [-2.776199487599903, 53.964744644198468],
              [-2.777831180203472, 53.965192474553149],
              [-2.777949336618714, 53.96564380358771],
              [-2.780539343389257, 53.96663365211581],
              [-2.782113387401335, 53.967985088806316],
              [-2.786725144596315, 53.967362665480714],
              [-2.786849314176907, 53.96764227649976],
              [-2.787937862703124, 53.967811304853505],
              [-2.789775220792234, 53.969851189498669],
              [-2.792922338630309, 53.970927886271035],
              [-2.794290329613507, 53.971916511864357],
              [-2.79489781411996, 53.974196333383922],
              [-2.79595717652169, 53.974902962402361],
              [-2.795851219483052, 53.975183189818608],
              [-2.796387081961314, 53.975460062703156],
              [-2.798623066922435, 53.975104574927073],
              [-2.7985103167616, 53.974470774682004],
              [-2.799955864605835, 53.974003669993976],
              [-2.798900952726839, 53.972496219140538],
              [-2.799786480850023, 53.97152861462142],
              [-2.799354220508058, 53.970132065952093],
              [-2.800269450317866, 53.969603771198287],
              [-2.801893848761191, 53.969251395329415],
              [-2.802945717069995, 53.968062450738351],
              [-2.80372637188415, 53.967912523484536],
              [-2.803878867721768, 53.967598721623396],
              [-2.803775681135896, 53.966593658085095],
              [-2.804283838359107, 53.965830772827687],
              [-2.803110736789247, 53.96491196199247],
              [-2.803432168950361, 53.963713512802286],
              [-2.804047330159511, 53.963601540679058],
              [-2.804684762397564, 53.962826103385893],
              [-2.80615346915626, 53.963188357152283],
              [-2.807383735344088, 53.962017048919932],
              [-2.806267553017737, 53.959781146244183],
              [-2.806516436290039, 53.959410069074472],
              [-2.805635159802278, 53.95923352753772],
              [-2.804309297729865, 53.958050606910938],
              [-2.803107425739209, 53.957605655076279],
              [-2.80447877151743, 53.956483764539897],
              [-2.804409291137686, 53.955882933487118],
              [-2.804812221328656, 53.955756199835243],
              [-2.805563455906456, 53.953934690345534],
              [-2.805341473251634, 53.952933119047984],
              [-2.803143246982092, 53.952096676701018],
              [-2.802935524425223, 53.951199266243179],
              [-2.805129814051325, 53.950173425870013],
              [-2.80595564574057, 53.949381436593498],
              [-2.806462791931325, 53.948026236664013],
              [-2.810840910308579, 53.947115931327417],
              [-2.811369447293622, 53.946715091598129],
              [-2.813180733790035, 53.946308266596205],
              [-2.813649949551403, 53.945835914276607],
              [-2.812560430834008, 53.944966061536689],
              [-2.810842606867027, 53.944376374946259],
              [-2.808396136828207, 53.943841904091776],
              [-2.806993068548504, 53.944083231764488],
              [-2.803921783776453, 53.943377601077927],
              [-2.802325761717963, 53.942059847832468],
              [-2.804709772477453, 53.940309209420114],
              [-2.805376835813621, 53.939272014549687],
              [-2.804748762054835, 53.937111911353639],
              [-2.803692679058694, 53.935741121543302],
              [-2.804851599108098, 53.935253398702628],
              [-2.809668280404563, 53.933539356550853],
              [-2.818715277203406, 53.934916094624683],
              [-2.825924149032157, 53.933354038388849],
              [-2.864371979751443, 53.918066138889422],
              [-2.873716726630277, 53.928448940359289],
              [-2.87327702906815, 53.931168335756787],
              [-2.877891447826729, 53.932452337955652],
              [-2.881936593603316, 53.934824308140215],
              [-2.884034546095283, 53.935280754544507],
              [-2.884018587836999, 53.935894757575525],
              [-2.885249274718726, 53.936825843415832],
              [-2.883320304235233, 53.938099277803857],
              [-2.882729221978297, 53.938025428226553],
              [-2.883036065916123, 53.937696006359182],
              [-2.881952156467448, 53.937078402595198],
              [-2.881489227572816, 53.93708539707356],
              [-2.881378501440821, 53.937469101680193],
              [-2.880241922899032, 53.937459462692011],
              [-2.879328185462419, 53.938846724687295],
              [-2.878515224965009, 53.939088162459747],
              [-2.877967565424098, 53.938906114960034],
              [-2.878084136009545, 53.939669248159568],
              [-2.876786007777951, 53.939658959866769],
              [-2.876053000068399, 53.940736585641019],
              [-2.877201268802973, 53.941662062686895],
              [-2.878366970821047, 53.941384798619019],
              [-2.878583979372241, 53.941779584638432],
              [-2.879965904248449, 53.941425223127894],
              [-2.879185184220557, 53.941825517622561],
              [-2.879309123976203, 53.943299551043808],
              [-2.880331068857443, 53.943573389996509],
              [-2.879961572815974, 53.944186387412188],
              [-2.879201902028145, 53.944068815223496],
              [-2.878751783288097, 53.944326471925287],
              [-2.879654468792163, 53.945447864372433],
              [-2.880743280754615, 53.945277200011077],
              [-2.882382735997785, 53.946291601243637],
              [-2.883180217904343, 53.947402954590508],
              [-2.882880664398303, 53.947287413647551],
              [-2.882795922959404, 53.94760351741197],
              [-2.882168395668452, 53.947253100489846],
              [-2.882146533959781, 53.947733223290605],
              [-2.881340651283669, 53.947811943507602],
              [-2.881430701733191, 53.947458052019208],
              [-2.880628789310503, 53.947652684248105],
              [-2.88078306432266, 53.947237203502446],
              [-2.879970347507102, 53.94720810801806],
              [-2.879798129598656, 53.946626943825358],
              [-2.880457911936426, 53.945978563497825],
              [-2.879695508578787, 53.946094703859977],
              [-2.878643089738704, 53.947127945624146],
              [-2.878236609998437, 53.946781283616133],
              [-2.87923118008678, 53.946257192904667],
              [-2.878015031837181, 53.946677743598244],
              [-2.876614750587295, 53.946674491182954],
              [-2.876336811032586, 53.945190794319821],
              [-2.876047400155789, 53.946429662202732],
              [-2.874408767316416, 53.945811542199515],
              [-2.87584541650365, 53.947260732747452],
              [-2.876470663929057, 53.947432336921906],
              [-2.875499911964814, 53.947714452252789],
              [-2.876730738759842, 53.947845686535032],
              [-2.877536751326497, 53.947337367401403],
              [-2.8783799941695, 53.947368952498856],
              [-2.877576212508767, 53.949216480686509],
              [-2.876438975139355, 53.948754710538189],
              [-2.875037725760736, 53.949145123113667],
              [-2.876717879161622, 53.949048381635997],
              [-2.876269312236498, 53.950763878695895],
              [-2.875692642896427, 53.950804037103943],
              [-2.875667348712646, 53.95025145663687],
              [-2.875219688788532, 53.950994436121128],
              [-2.876083862547381, 53.951003414909728],
              [-2.876217494368321, 53.951272081413748],
              [-2.873365655469694, 53.951087028120249],
              [-2.871830060830928, 53.951397487281241],
              [-2.870547268113985, 53.951262984036006],
              [-2.868733072036575, 53.95268634438257],
              [-2.865816426343535, 53.952759537111568],
              [-2.868766345838392, 53.95296742930357],
              [-2.870436300157361, 53.951568482983198],
              [-2.871321222989998, 53.951695089038985],
              [-2.870973054209425, 53.952171284334263],
              [-2.872311267348118, 53.951674420003805],
              [-2.875448590206262, 53.952392206401264],
              [-2.874689518492405, 53.952964882727102],
              [-2.870753840167093, 53.954302140483492],
              [-2.869500379986681, 53.955434726283976],
              [-2.867969004866474, 53.955223798694398],
              [-2.868193384522522, 53.954727834979138],
              [-2.867404734990573, 53.955057102650933],
              [-2.866711726725706, 53.954487770327482],
              [-2.866850693104318, 53.955456576278756],
              [-2.86571298959026, 53.954901232700344],
              [-2.865060499811313, 53.955109962527004],
              [-2.86579721508834, 53.955582817262879],
              [-2.8652535367562, 53.956483740842486],
              [-2.864546366590707, 53.956555344787276],
              [-2.86435137394684, 53.955969829861111],
              [-2.863559658763286, 53.956521997238788],
              [-2.865648323623605, 53.957002201844496],
              [-2.865563069648005, 53.957521425600142],
              [-2.864491139659703, 53.957716096891154],
              [-2.86570546952971, 53.957921265387448],
              [-2.864816646496007, 53.958053501001814],
              [-2.865338508639742, 53.958378704063229],
              [-2.865308877790813, 53.959155483113499],
              [-2.863837301602276, 53.960588880315292],
              [-2.862347038530279, 53.960974389699331],
              [-2.861744142680697, 53.962114802589831],
              [-2.860017753224989, 53.961917755387844],
              [-2.857885308596836, 53.959314795117947],
              [-2.859291077584086, 53.958378979622957],
              [-2.857653642439651, 53.958572241411531],
              [-2.857582949732774, 53.959582999795074],
              [-2.858618112365451, 53.960561590035439],
              [-2.858906121302708, 53.961596748256227],
              [-2.858661505667773, 53.961782751617541],
              [-2.857533736721009, 53.961343205351909],
              [-2.857289431225491, 53.960579170166973],
              [-2.857184112729719, 53.960943037791985],
              [-2.856302851643238, 53.961299856510763],
              [-2.856573974781328, 53.961662836779922],
              [-2.857094829865981, 53.961197137051698],
              [-2.858370402947578, 53.961857635274605],
              [-2.859098012906365, 53.961885688894554],
              [-2.859235601560206, 53.962350284046977],
              [-2.858681441768273, 53.963049020727098],
              [-2.857345638848316, 53.962575007662039],
              [-2.85797531003468, 53.963325504833698],
              [-2.857097942376232, 53.963354238759614],
              [-2.857018597625933, 53.963721515906336],
              [-2.857437512024076, 53.963634937658355],
              [-2.858062368833799, 53.964521187678493],
              [-2.857267068635697, 53.965281859481294],
              [-2.855566051959253, 53.965361397689648],
              [-2.854191060423614, 53.964836397070428],
              [-2.854375097066702, 53.96451871018256],
              [-2.853798106448224, 53.964627972653659],
              [-2.853276328764029, 53.964082512971139],
              [-2.853772373260221, 53.963568424999522],
              [-2.852606047064491, 53.964027053036546],
              [-2.841312952092227, 53.956541477837895],
              [-2.84223458659651, 53.956144938512232],
              [-2.842598179746984, 53.956340124552028],
              [-2.842448984063074, 53.956045465472037],
              [-2.841197931353387, 53.956429933323399],
              [-2.835653080520737, 53.954265679679558],
              [-2.837494998277961, 53.955306244722024],
              [-2.840656498459023, 53.956257559214443],
              [-2.85417226757606, 53.965333567670847],
              [-2.853088550686505, 53.96570977771232],
              [-2.851374366793308, 53.964471708024448],
              [-2.849723701384927, 53.964552604298461],
              [-2.846750451529757, 53.963981291502648],
              [-2.843712195883958, 53.964262424802747],
              [-2.842060765835164, 53.964834864864571],
              [-2.841671065880162, 53.964104175242397],
              [-2.842027291786486, 53.963477012307294],
              [-2.843795580502872, 53.964089269381489],
              [-2.845312435561049, 53.963346980276086],
              [-2.846215512760107, 53.963458364226526],
              [-2.846615544522266, 53.962514500857864],
              [-2.846092201884173, 53.962262030298312],
              [-2.845824512680144, 53.961462185274371],
              [-2.845975206765499, 53.962355430867738],
              [-2.846429278426471, 53.962574235676627],
              [-2.846141517070403, 53.963342041426706],
              [-2.84522833830859, 53.963258590831501],
              [-2.843922490904386, 53.963958051710151],
              [-2.842237265588481, 53.963307464468862],
              [-2.841617590783976, 53.963795362649201],
              [-2.842647927884474, 53.962281749182608],
              [-2.841733555404949, 53.961910664301584],
              [-2.839122690843476, 53.962368446189913],
              [-2.838922706581768, 53.962883956917999],
              [-2.839665860146996, 53.962396108281247],
              [-2.839038541079581, 53.962959545837066],
              [-2.839428188780965, 53.96323545141442],
              [-2.839238600667299, 53.962978820568026],
              [-2.839901607357996, 53.962371091195322],
              [-2.841852292832195, 53.962054539028095],
              [-2.842269126723481, 53.962393160955735],
              [-2.841467581245767, 53.963385661904276],
              [-2.83587031234914, 53.963751907159526],
              [-2.841123823119837, 53.963421325272243],
              [-2.842007107008985, 53.965121958275915],
              [-2.844376046840658, 53.964301801127476],
              [-2.84747065866092, 53.964244054790981],
              [-2.849192444970459, 53.964962619294944],
              [-2.850825685524799, 53.96484859818198],
              [-2.850942259395944, 53.965632425441207],
              [-2.850270109361995, 53.964962185971942],
              [-2.848752320992486, 53.965282107903036],
              [-2.845187881113326, 53.965176016974318],
              [-2.845008846356005, 53.965367822282808],
              [-2.845726159404971, 53.965562307546485],
              [-2.84559917887974, 53.96571260552011],
              [-2.844913349192, 53.966524351146745],
              [-2.842851585430455, 53.966029216445897],
              [-2.842237196609813, 53.966402932809359],
              [-2.84182205082519, 53.965923187432864],
              [-2.841232292964153, 53.965930914440392],
              [-2.840941815509156, 53.96641830039183],
              [-2.84175578451288, 53.966038698233803],
              [-2.842146519242742, 53.966819712953203],
              [-2.842459953484798, 53.966488554271002],
              [-2.8428193560124, 53.966698149801154],
              [-2.843068605640972, 53.967340840199746],
              [-2.842686635033429, 53.966164294988076],
              [-2.844988362183265, 53.966615501089763],
              [-2.84566579569955, 53.965718381393678],
              [-2.846412001180073, 53.965783075523596],
              [-2.846067669014939, 53.966393090394916],
              [-2.844450968082915, 53.966876337110669],
              [-2.843997362903222, 53.968190874273731],
              [-2.844690033346284, 53.967164968610902],
              [-2.844943131783157, 53.967544279719995],
              [-2.845490362243945, 53.967543125521487],
              [-2.84543259201098, 53.967927319737683],
              [-2.845756254149091, 53.967499908865513],
              [-2.845075039721065, 53.96743369817456],
              [-2.844560654392313, 53.966948368748142],
              [-2.845174564842795, 53.96707887072133],
              [-2.845454496302144, 53.966675137085254],
              [-2.846320046667691, 53.966736451746222],
              [-2.846081396728509, 53.966543992138256],
              [-2.846832888738776, 53.966393090784209],
              [-2.846592810776774, 53.965829437711633],
              [-2.847951259421293, 53.966143423686773],
              [-2.849678851063603, 53.965719564854886],
              [-2.853201331425828, 53.966604182246655],
              [-2.853062537403073, 53.96771968080084],
              [-2.851509959634234, 53.968131561862521],
              [-2.847943271760268, 53.968002199032213],
              [-2.848305662477245, 53.968131763926678],
              [-2.847659944285982, 53.968237886782099],
              [-2.850710466750147, 53.968321480753261],
              [-2.852869681319858, 53.967979904820069],
              [-2.852926256255444, 53.968361493311576],
              [-2.853686739369627, 53.966636685414755],
              [-2.856268414708205, 53.966385520011386],
              [-2.86096183636403, 53.967049440546937],
              [-2.863032016496064, 53.967865076846635],
              [-2.866986730527756, 53.968472949432858],
              [-2.870940582307458, 53.970203301570223],
              [-2.872173241299527, 53.970391195489306],
              [-2.873790767248706, 53.971485865022977],
              [-2.873755683599451, 53.971922038924795],
              [-2.875035481381002, 53.972682996085773],
              [-2.875273300633701, 53.973627701332184],
              [-2.876964738879637, 53.974819759043562],
              [-2.876888203724008, 53.978216886645654],
              [-2.877789056903674, 53.978638136873258],
              [-2.878566033184341, 53.978606391988997],
              [-2.879049300697189, 53.979108881638446],
              [-2.87837887112211, 53.979566780955921],
              [-2.874280049001988, 53.980581764878721],
              [-2.869692143841358, 53.983601776260564],
              [-2.869069073652703, 53.986342229649182],
              [-2.870572195275667, 53.990260895683456],
              [-2.86989327195063, 53.991787478611329],
              [-2.868573340738669, 53.992346193182222],
              [-2.868145947121119, 53.993137527805466],
              [-2.865033551163344, 53.994205276975784],
              [-2.864666307730102, 53.994738210792953],
              [-2.86298279346539, 53.995352508015209],
              [-2.862112658514697, 53.994877897796002],
              [-2.861128847447413, 53.995398165667638],
              [-2.860805426216837, 53.99524858639235],
              [-2.861554838096086, 53.994531367915819],
              [-2.861121865280176, 53.994173155207285],
              [-2.861458780300182, 53.993573039953411],
              [-2.860662952346704, 53.993000816392765],
              [-2.861175817218819, 53.992870411207882],
              [-2.861121227246936, 53.992590377656022],
              [-2.860364267857621, 53.992866338302782],
              [-2.860452770682195, 53.993237806643464],
              [-2.859805059464105, 53.993023957376934],
              [-2.85971800619853, 53.993259244571924],
              [-2.861117207028652, 53.993651886177226],
              [-2.860501391572334, 53.99478159204979],
              [-2.861236023101649, 53.994604658470266],
              [-2.860344180852881, 53.995146730621578],
              [-2.858610589662189, 53.995110595907235],
              [-2.860422533791067, 53.995247733638969],
              [-2.860776702911565, 53.995630780895041],
              [-2.861995938050689, 53.995134892392123],
              [-2.862340453099038, 53.995345435300209],
              [-2.860477544920114, 53.996139845080364],
              [-2.859987041722333, 53.996839025401208],
              [-2.859544021085714, 53.996808041038172],
              [-2.859928705934928, 53.996451162692289],
              [-2.859549167691957, 53.996465562660113],
              [-2.857112807269207, 53.997790715267676],
              [-2.859220627962886, 53.996882257694196],
              [-2.859710337775961, 53.997106150502248],
              [-2.859795867317282, 53.997703238306286],
              [-2.856092132151256, 53.999568619998279],
              [-2.852139111588776, 54.00052427520054],
              [-2.851100261979163, 54.000528042495539],
              [-2.849639175291786, 53.999650372250272],
              [-2.848877336666293, 53.999621602365089],
              [-2.848801032727564, 53.999995141518788],
              [-2.848309193508284, 53.99996535930245],
              [-2.846535812270091, 53.998579339977404],
              [-2.846262194427248, 53.998702604696234],
              [-2.845182513203235, 53.998044196295361],
              [-2.84065256759311, 53.997780269949686],
              [-2.840252015001772, 53.996979548632702],
              [-2.840490214467262, 53.997823649055562],
              [-2.836985110027065, 53.997999112843843],
              [-2.836886319134202, 53.997180998704565],
              [-2.83668343968289, 53.997941893308884],
              [-2.834727985667459, 53.998181989647826],
              [-2.834060191013594, 53.998658494016247],
              [-2.832809045999056, 53.997577832853352],
              [-2.834004296113365, 53.996998141171431],
              [-2.83492740858646, 53.997170358737428],
              [-2.834526524898089, 53.996881035273852],
              [-2.834919832166853, 53.996715621139984],
              [-2.834191312589494, 53.99690743624862],
              [-2.833912119722654, 53.996980944334894],
              [-2.833022995255384, 53.997215034322544],
              [-2.832859111547888, 53.996800028374913],
              [-2.832509359424343, 53.997390263885578],
              [-2.831986970380199, 53.997203337588793],
              [-2.832169090692414, 53.996927045251773],
              [-2.83165806385117, 53.997462669776894],
              [-2.830634279301568, 53.997299879155605],
              [-2.833346357678884, 53.99629692015759],
              [-2.832291386042286, 53.996481294798478],
              [-2.832110287053039, 53.996043937184481],
              [-2.832728878190216, 53.995854499986137],
              [-2.832327010233881, 53.994826366529615],
              [-2.832178735280501, 53.995422395852437],
              [-2.831619187574196, 53.995436156603525],
              [-2.832105124318845, 53.995785120006424],
              [-2.831394018501362, 53.995848464081561],
              [-2.831962814889314, 53.996527611189542],
              [-2.83039960864814, 53.997235889150978],
              [-2.830703066782451, 53.995468558755768],
              [-2.82929866552799, 53.995109754021428],
              [-2.826570043107715, 53.995971624884099],
              [-2.827754930307052, 53.995568897277195],
              [-2.827571714087004, 53.995252884794802],
              [-2.82522297942614, 53.994500569319001],
              [-2.824145931119233, 53.995305678602719],
              [-2.823436595521618, 53.995264217050462],
              [-2.823244705596483, 53.994738838403485],
              [-2.822995391228751, 53.995168372459112],
              [-2.824032184857967, 53.995453374765603],
              [-2.82423536765282, 53.995310905494307],
              [-2.825270423104621, 53.994585628956408],
              [-2.826579067397199, 53.995272300641375],
              [-2.827485552905244, 53.995369422707888],
              [-2.825791040302525, 53.995533875499994],
              [-2.826556133332777, 53.996347416659788],
              [-2.829312526477055, 53.995423337739012],
              [-2.829870065948455, 53.99638209751248],
              [-2.829578716856723, 53.996996188489987],
              [-2.828652404308234, 53.996670925031538],
              [-2.828770913965176, 53.996954126761516],
              [-2.830844362797958, 53.997814334791045],
              [-2.832380745639704, 53.997978067813484],
              [-2.831549942439615, 53.998237280196797],
              [-2.83249171244601, 53.998187617003303],
              [-2.832746739946638, 53.998506719524627],
              [-2.832625285954254, 53.999147502860346],
              [-2.832254162051528, 53.999355897804058],
              [-2.831904405355998, 53.998876565941707],
              [-2.83137720939784, 53.999673850098269],
              [-2.832408612723993, 53.999603794010476],
              [-2.830971203288284, 54.000352551729932],
              [-2.830585819567224, 54.001071555061039],
              [-2.830764522422005, 54.00146489029116],
              [-2.831356781566224, 54.001327772704933],
              [-2.830958006450378, 54.001681060619354],
              [-2.831694521389606, 54.001433290871169],
              [-2.832472723009331, 54.002357255238024],
              [-2.832397092431344, 54.002772123164881],
              [-2.831289811254068, 54.002177596330903],
              [-2.832300969609715, 54.002848287658693],
              [-2.832341432297407, 54.003423235463018],
              [-2.831331223841298, 54.002877470261687],
              [-2.831114616524607, 54.003109059796742],
              [-2.831218641191656, 54.003429209652758],
              [-2.831843748562403, 54.00340780697384],
              [-2.831913715079206, 54.003779423233297],
              [-2.832275923141311, 54.003657375579373],
              [-2.833215876623104, 54.005572482757096],
              [-2.833136410367169, 54.006406215280734],
              [-2.832845454160805, 54.007346572336239],
              [-2.831749019505805, 54.007837716770851],
              [-2.830155881743959, 54.011662310201409],
              [-2.829415320569559, 54.012329833427273],
              [-2.828995554961872, 54.015010231244808],
              [-2.829478117391612, 54.015485957743408],
              [-2.830054149937737, 54.017666044596176],
              [-2.829952101937639, 54.020742413211146],
              [-2.829110343543329, 54.02124165907621],
              [-2.828234760466328, 54.021191970811977],
              [-2.828958002455963, 54.021409884974673],
              [-2.829533118812832, 54.021240539520022],
              [-2.82992857266404, 54.021706978444008],
              [-2.828939683571497, 54.027164966854528],
              [-2.826687279772099, 54.028898066341341],
              [-2.826477714839235, 54.029492708566927],
              [-2.82726366921072, 54.031324427727647],
              [-2.826849836341226, 54.029011993332688],
              [-2.828597514980276, 54.027691320648209],
              [-2.831680049471061, 54.031839518126894],
              [-2.829434849627943, 54.033404543888487],
              [-2.828498137703527, 54.033057779370736],
              [-2.828139004635986, 54.033347866566245],
              [-2.828737126614187, 54.033252068016928],
              [-2.829354936962099, 54.033531824528438],
              [-2.82926629962134, 54.034294609185203],
              [-2.828324588211057, 54.036154404458657],
              [-2.830067718934065, 54.037657738557968],
              [-2.830100017138482, 54.037286315832915],
              [-2.828647676437284, 54.036277109417448],
              [-2.829106002703227, 54.034985984820189],
              [-2.829753657790202, 54.034693004872501],
              [-2.831544541108199, 54.035988368030502],
              [-2.830088424545973, 54.03616830268578],
              [-2.830736508212683, 54.036202473734654],
              [-2.830603185652997, 54.036561112947588],
              [-2.831781319222691, 54.035993920245815],
              [-2.829865806192296, 54.034344399352719],
              [-2.829627011192734, 54.033777114125314],
              [-2.830384280824049, 54.032933315049817],
              [-2.830925612984947, 54.032593427140931],
              [-2.831280935648462, 54.033337864453905],
              [-2.831093536166891, 54.03251586916776],
              [-2.83186395927246, 54.032409875711487],
              [-2.83304257317135, 54.03309500125188],
              [-2.831326650287964, 54.033715938610058],
              [-2.833081019519831, 54.033185191683053],
              [-2.833668004551981, 54.033525358646465],
              [-2.831495247609365, 54.034130911954918],
              [-2.833615998837137, 54.033749517874718],
              [-2.832569493637878, 54.034252003432734],
              [-2.833141542322453, 54.034302866236963],
              [-2.832415502968548, 54.034492147807853],
              [-2.832894665180641, 54.034631736312349],
              [-2.833352088952954, 54.034295115289964],
              [-2.833198562457675, 54.034100243433414],
              [-2.83390312653978, 54.03383021501655],
              [-2.834666591435985, 54.034290489314472],
              [-2.833894865966442, 54.03494297354279],
              [-2.832716483192192, 54.03503652709086],
              [-2.833821538325554, 54.035093579994594],
              [-2.833135687982514, 54.035536944415284],
              [-2.834241696958292, 54.03487855064067],
              [-2.835246270225235, 54.034797874163488],
              [-2.834396256655409, 54.035505731627403],
              [-2.833102448559423, 54.035936237139261],
              [-2.834193937525932, 54.035695881495563],
              [-2.834085720985656, 54.036086706889321],
              [-2.834409769070446, 54.036027834354947],
              [-2.835209326602428, 54.035087541024645],
              [-2.835788145552775, 54.035170700657247],
              [-2.836224411917656, 54.035834568359952],
              [-2.834368396359033, 54.036630310219707],
              [-2.836556518285542, 54.035949100305821],
              [-2.836994894726237, 54.036337022206354],
              [-2.836331152779887, 54.037196390248106],
              [-2.835478341698378, 54.037385673199338],
              [-2.833668721519574, 54.036689091776005],
              [-2.832974579959233, 54.037024657901362],
              [-2.832410055917774, 54.036893749890467],
              [-2.830396183409571, 54.037283371599756],
              [-2.832489768015364, 54.036985773158825],
              [-2.833306979213432, 54.037382768561812],
              [-2.832528098902302, 54.038215949555941],
              [-2.831195377145451, 54.03846155305731],
              [-2.832368274067178, 54.038394117651869],
              [-2.83191325884901, 54.038701057841656],
              [-2.8328469871109, 54.03950170483489],
              [-2.832265350978063, 54.038668960774359],
              [-2.832991585601397, 54.038180382486331],
              [-2.833331568947859, 54.039146021624063],
              [-2.833214222059, 54.038165357767042],
              [-2.832810852331962, 54.038000079354674],
              [-2.833456062362458, 54.037507565491353],
              [-2.833384168631089, 54.036966992996533],
              [-2.834874371257675, 54.037420428040576],
              [-2.833955343622033, 54.038650058643981],
              [-2.83476571793139, 54.038322667845435],
              [-2.835047756073526, 54.037462365651599],
              [-2.836574764114926, 54.037389732434228],
              [-2.837351162777256, 54.036741692673658],
              [-2.837798695797721, 54.036976753693899],
              [-2.837790786951527, 54.037494511398315],
              [-2.836728342023101, 54.037813790821033],
              [-2.83664501294821, 54.038302413216336],
              [-2.836019455737394, 54.038099144925752],
              [-2.835652626893382, 54.038810339220504],
              [-2.834012403357502, 54.038983113411518],
              [-2.833238533244357, 54.039609542618081],
              [-2.834589598752053, 54.038968647584568],
              [-2.834825208384157, 54.038937205130878],
              [-2.83550208588332, 54.039102194978511],
              [-2.835724949518196, 54.038802720756102],
              [-2.836197947997451, 54.038167110247613],
              [-2.836749544408155, 54.038413135513927],
              [-2.837078044839027, 54.038043243867278],
              [-2.837494180489558, 54.038918462392672],
              [-2.834708512143505, 54.040039751492969],
              [-2.833205323795568, 54.039781441399491],
              [-2.834559543489852, 54.040302333200415],
              [-2.837701832116744, 54.03899341266073],
              [-2.837213176071824, 54.037929055430645],
              [-2.838399281786913, 54.037457013982568],
              [-2.839478685840823, 54.038359055876896],
              [-2.836959634148989, 54.039977363226093],
              [-2.835672952219928, 54.040159780442423],
              [-2.835553599788637, 54.040528214572625],
              [-2.835876723474194, 54.040193416552768],
              [-2.836459834273983, 54.040258567505241],
              [-2.837715579158052, 54.039752793480581],
              [-2.839578512776375, 54.038539015356164],
              [-2.839932495867355, 54.038676753759511],
              [-2.840112825386216, 54.039213868377018],
              [-2.839317304056976, 54.039595118886162],
              [-2.83835043912828, 54.0395820919545],
              [-2.838361296608682, 54.040273184868511],
              [-2.836958740100205, 54.040844700531721],
              [-2.83829940545546, 54.040460564527137],
              [-2.838355579918963, 54.040747785204999],
              [-2.83734454528323, 54.040969641949559],
              [-2.837690996704126, 54.040960038061222],
              [-2.837420734713729, 54.041189314467793],
              [-2.837999829284721, 54.041054056102659],
              [-2.83747477167723, 54.041370493343727],
              [-2.837883709557682, 54.041278663839151],
              [-2.838559681879113, 54.04079759267335],
              [-2.838532736979357, 54.039686877633891],
              [-2.83917853442597, 54.039756072080309],
              [-2.840407365233506, 54.039281016445365],
              [-2.841342178719522, 54.04005103156144],
              [-2.841891733559228, 54.04087317138945],
              [-2.840766841422377, 54.040976315320286],
              [-2.840171315656034, 54.040599392234967],
              [-2.840661917745259, 54.041149616527782],
              [-2.841916816381361, 54.04098085040836],
              [-2.841472893475397, 54.041381223141506],
              [-2.839820494758129, 54.041982383028852],
              [-2.83924639802522, 54.041305110283901],
              [-2.838260038851529, 54.04116009665178],
              [-2.837961246285258, 54.041337443464037],
              [-2.83857970903771, 54.041640516760374],
              [-2.837706147444482, 54.042016907859384],
              [-2.83919696549166, 54.041428589410785],
              [-2.839640148742556, 54.04212655011915],
              [-2.839027760675368, 54.042277328094684],
              [-2.838895070010806, 54.042664733136398],
              [-2.842140027348531, 54.041069165916014],
              [-2.842287513557205, 54.041641560018277],
              [-2.841377926591194, 54.041820496764721],
              [-2.839496465676105, 54.043331930157073],
              [-2.841493477976884, 54.041870918971739],
              [-2.842514637730059, 54.04192308702622],
              [-2.842833840756326, 54.042830423890919],
              [-2.842464600009665, 54.043299483264221],
              [-2.84170632242439, 54.043449182213209],
              [-2.841412007744216, 54.043507284573749],
              [-2.840751210411698, 54.04284950035764],
              [-2.841390883007364, 54.043595513695522],
              [-2.840381195349319, 54.043432704807536],
              [-2.839879705556381, 54.043706744586764],
              [-2.841416456527126, 54.043727456627664],
              [-2.841669995205768, 54.043484056979089],
              [-2.842808343843142, 54.043381560113971],
              [-2.842355498053652, 54.045002553204192],
              [-2.840891573024527, 54.04405289528944],
              [-2.841900890103767, 54.044800813797316],
              [-2.84081316782262, 54.044632263376961],
              [-2.842005360560883, 54.044982536217006],
              [-2.84199214280779, 54.045235188314614],
              [-2.841558713302633, 54.045120715447645],
              [-2.841904829257523, 54.045373314390709],
              [-2.841513835122517, 54.045674449472173],
              [-2.840244610859108, 54.04627562797404],
              [-2.839385773256822, 54.04602277622427],
              [-2.838651964813425, 54.046365841487649],
              [-2.839525073061963, 54.046114378761381],
              [-2.839988660825422, 54.046382574690824],
              [-2.837255171782838, 54.046930128869725],
              [-2.8243978061207, 54.051447300084369],
              [-2.815865290143347, 54.05315297540259],
              [-2.811857255874647, 54.054335942763025],
              [-2.809562662293625, 54.054718118404153],
              [-2.807069493982687, 54.054551527057384],
              [-2.805694532318127, 54.054070012032298],
              [-2.803815724671394, 54.052729017655878],
              [-2.802846031902873, 54.051950857595841],
              [-2.799632600617897, 54.051969575596445],
              [-2.797812210202993, 54.052705189893352],
              [-2.795917533397881, 54.054112659669897],
              [-2.793361735408026, 54.057547624112964],
              [-2.792464265109537, 54.05783036753121],
              [-2.793294331894357, 54.057698165610248],
              [-2.792037087002234, 54.060830622736027],
              [-2.791279052008715, 54.061058511447399],
              [-2.792035685524108, 54.060997805522732],
              [-2.791723394507503, 54.062250068474761],
              [-2.793253115743366, 54.06290957978468],
              [-2.793837668914276, 54.062796969530112],
              [-2.795773791102948, 54.061135804973233],
              [-2.794196012820784, 54.059798061998904],
              [-2.79434451706515, 54.057973450329563],
              [-2.798188951341819, 54.053951100555238],
              [-2.799999912181666, 54.053040280670203],
              [-2.801993764099348, 54.052667484027573],
              [-2.804393169774236, 54.054707874339492],
              [-2.808800467136657, 54.055586081023179],
              [-2.811044555910751, 54.055509847621707],
              [-2.814817321344405, 54.054786882969019],
              [-2.819809988638608, 54.053419147875509],
              [-2.826335279535581, 54.052096405411284],
              [-2.832394764068396, 54.050109633159515],
              [-2.838167711159942, 54.049091646381655],
              [-2.838240913209038, 54.049010245168652],
              [-2.837926020839372, 54.048921662630583],
              [-2.838224661265732, 54.048809928857366],
              [-2.838481641724843, 54.048980704169125],
              [-2.838977349760647, 54.049628867057912],
              [-2.838667555527825, 54.048959634146627],
              [-2.838822130203924, 54.048671842574585],
              [-2.839103148063992, 54.04882267553397],
              [-2.843965001386981, 54.04612373889443],
              [-2.845955637626294, 54.043748630780094],
              [-2.846352930402994, 54.043684716920794],
              [-2.846382541944798, 54.044239959957011],
              [-2.847288236289836, 54.04454366257621],
              [-2.846938115982519, 54.044777539800059],
              [-2.846479116434717, 54.045084141445379],
              [-2.847132727290893, 54.045232331827528],
              [-2.846652463776652, 54.045046969274246],
              [-2.846971178675083, 54.044791286642038],
              [-2.847255976745778, 54.044909696957987],
              [-2.847427603020061, 54.044487854184126],
              [-2.84645008150528, 54.043580672231904],
              [-2.845370022735342, 54.043864199856003],
              [-2.845535666470517, 54.043222198554027],
              [-2.847179103472523, 54.043383196396022],
              [-2.84820764274629, 54.043120688064334],
              [-2.848250534384005, 54.043576970285535],
              [-2.849032776595959, 54.043518419979058],
              [-2.84939127072983, 54.043091962125324],
              [-2.849731921259842, 54.043129697518154],
              [-2.849446108504054, 54.042893538160207],
              [-2.848950053141366, 54.04343092282366],
              [-2.848348859575796, 54.043455838768239],
              [-2.848194739429093, 54.043012025619653],
              [-2.846231418775702, 54.043191238385106],
              [-2.846480473892337, 54.043041190275837],
              [-2.847299849907675, 54.042547531325908],
              [-2.849048010848168, 54.041494239020679],
              [-2.850336267359329, 54.041090565308963],
              [-2.848983174140713, 54.041385044601519],
              [-2.847184653925856, 54.042575223234493],
              [-2.845559477704025, 54.042965876247905],
              [-2.845168026495576, 54.041436193544143],
              [-2.84744155085198, 54.040820696325682],
              [-2.845105177811253, 54.041273954532002],
              [-2.844217500995129, 54.03951946326152],
              [-2.845235378679507, 54.03956057509086],
              [-2.847875217662499, 54.040369395205097],
              [-2.848363713875427, 54.040961593819624],
              [-2.848029852866187, 54.041439408909866],
              [-2.848517402827548, 54.041234639943013],
              [-2.848402599249221, 54.040621576996962],
              [-2.846815942759667, 54.039793294447577],
              [-2.846704118918761, 54.039469202935052],
              [-2.847198371942158, 54.038850466111228],
              [-2.84879699961804, 54.038993781382665],
              [-2.847006227689096, 54.038788005983115],
              [-2.846678846575018, 54.039395954317797],
              [-2.846505520153356, 54.039717816265672],
              [-2.846441188102164, 54.039837277949417],
              [-2.84535342230358, 54.03956534184497],
              [-2.844796690013521, 54.039426156462412],
              [-2.843940993297903, 54.039212221162067],
              [-2.840584852818539, 54.035969534355786],
              [-2.841183592810778, 54.034920952534037],
              [-2.840637484042691, 54.032222111324465],
              [-2.841460755389626, 54.032315218108728],
              [-2.842408062492892, 54.031595842444958],
              [-2.841507627598503, 54.032141423479935],
              [-2.840484816155906, 54.032146781705436],
              [-2.840893532762931, 54.034922981893018],
              [-2.840469807999416, 54.035564984631641],
              [-2.836614456723069, 54.032447911701425],
              [-2.83650458142046, 54.032070285826613],
              [-2.839261489172807, 54.031368887575297],
              [-2.840094463794391, 54.030808515385544],
              [-2.840626940220241, 54.028597363299596],
              [-2.841217840815152, 54.028526718913632],
              [-2.84050626753546, 54.028518214783709],
              [-2.839810012873963, 54.030784438106231],
              [-2.838023559701484, 54.031456617761648],
              [-2.837664324806438, 54.031433955524534],
              [-2.83824069208423, 54.030866396277212],
              [-2.83696728582827, 54.031771363152494],
              [-2.836081147729979, 54.031739780652245],
              [-2.835586780544515, 54.031373815493531],
              [-2.83521875067042, 54.030836200459667],
              [-2.836042598954343, 54.030351418217279],
              [-2.835151719935295, 54.030691062211631],
              [-2.834782443742328, 54.030471626145982],
              [-2.834302850975246, 54.029774798379599],
              [-2.834886670286108, 54.029425609420116],
              [-2.834022454580915, 54.029342628601377],
              [-2.833463852922709, 54.028510628302442],
              [-2.8330345996425, 54.025748025964909],
              [-2.834592810016268, 54.025806423570145],
              [-2.83337935919038, 54.025507456602746],
              [-2.833847756904373, 54.023283295980221],
              [-2.834442224298736, 54.023698905812914],
              [-2.834529277068868, 54.023396307653528],
              [-2.836293713252534, 54.023086544133712],
              [-2.836650850616029, 54.02445291757244],
              [-2.837029699270903, 54.024087168338049],
              [-2.836626659421588, 54.023095911377908],
              [-2.83942485977773, 54.023261552707467],
              [-2.839938405908398, 54.023900600912548],
              [-2.839866690212346, 54.025340965576142],
              [-2.841330350856394, 54.02555542733662],
              [-2.842086275873618, 54.026099295268686],
              [-2.841614022724881, 54.025542655940185],
              [-2.840118729587639, 54.024970699846932],
              [-2.840059127233929, 54.02352855640855],
              [-2.841008070024296, 54.023503944568461],
              [-2.839613589479689, 54.023158671069396],
              [-2.843153418603164, 54.023614745234099],
              [-2.842478882987656, 54.025358636505786],
              [-2.84343113711247, 54.023685598882501],
              [-2.84258717325486, 54.023013829273239],
              [-2.842041665102993, 54.023211791130358],
              [-2.840439968354082, 54.02288505574915],
              [-2.841327297296558, 54.022455517130012],
              [-2.841447719892503, 54.021767098492013],
              [-2.842868058392288, 54.022033974476237],
              [-2.843754266336515, 54.021400399829368],
              [-2.842777160457838, 54.021916870144686],
              [-2.841176357912437, 54.021707880099285],
              [-2.841243738036642, 54.021340701474472],
              [-2.840469651017072, 54.021178042866957],
              [-2.84066017368505, 54.020937631827096],
              [-2.84159186703539, 54.021044359433482],
              [-2.841841085544039, 54.020687591327444],
              [-2.842546330054742, 54.020691637532195],
              [-2.841819049748439, 54.020578991842513],
              [-2.841650625278189, 54.02093070021359],
              [-2.840718263584824, 54.020790722445348],
              [-2.840370112327296, 54.021086163319268],
              [-2.840013108446536, 54.020488266004705],
              [-2.841153304450177, 54.02003628857625],
              [-2.839181635119616, 54.020506657356698],
              [-2.838113643112171, 54.019920906839275],
              [-2.837650154258785, 54.01835125218475],
              [-2.836452583412594, 54.016780415102993],
              [-2.836871433834491, 54.016586056588451],
              [-2.838973491752602, 54.016459951015754],
              [-2.838620800937265, 54.01850087317321],
              [-2.839468321789748, 54.018599216449672],
              [-2.839760932694888, 54.018956689154436],
              [-2.842257289408853, 54.019153133743387],
              [-2.839815006282328, 54.018837670778836],
              [-2.839586995897539, 54.018504913123536],
              [-2.838753283281886, 54.018410069493662],
              [-2.839307984638755, 54.017609867296599],
              [-2.839326403685961, 54.016250764693346],
              [-2.836353307839917, 54.016396422944268],
              [-2.835458099969259, 54.015972127792146],
              [-2.835411635563509, 54.015633606118065],
              [-2.836456311969417, 54.015521181248772],
              [-2.837485321035304, 54.014401310087372],
              [-2.838762126263371, 54.014526326080798],
              [-2.8391590517301, 54.013926756914188],
              [-2.837290360775578, 54.014344246980784],
              [-2.836369681555384, 54.015386965298219],
              [-2.835293242550323, 54.015361196178979],
              [-2.834488025807634, 54.014551585252818],
              [-2.834470196453606, 54.014042093783473],
              [-2.83518454206234, 54.013515832051077],
              [-2.834830384237723, 54.012824424852951],
              [-2.835917583570018, 54.012400726716649],
              [-2.837338494278479, 54.013093691066338],
              [-2.837199927355004, 54.012728847775108],
              [-2.836217248685468, 54.012352803577357],
              [-2.836887958347983, 54.01163179696384],
              [-2.837642659866332, 54.011750572522516],
              [-2.836797695380481, 54.01154434378639],
              [-2.835971299439298, 54.012263736583137],
              [-2.8346327687357, 54.012557957224367],
              [-2.834400289999251, 54.012150620878565],
              [-2.835228488555107, 54.01083442795278],
              [-2.837297911082613, 54.009173438653733],
              [-2.8385803458335, 54.008600054435782],
              [-2.839491846836127, 54.00900623674805],
              [-2.838995283379544, 54.01094570528862],
              [-2.8396655230585, 54.009219835041307],
              [-2.840758334122626, 54.008854474777529],
              [-2.841541872501784, 54.009723516675692],
              [-2.841689639504875, 54.009333304222693],
              [-2.840914920292996, 54.008750017915879],
              [-2.841420396739315, 54.008543352452506],
              [-2.840032282828814, 54.00901953736475],
              [-2.839409338764666, 54.008470233350316],
              [-2.840410054267319, 54.00800305691611],
              [-2.838132727052719, 54.008421620807461],
              [-2.838955484257236, 54.00752697296506],
              [-2.838628193495429, 54.00741331302364],
              [-2.842246664770474, 54.005937353494247],
              [-2.848976711527069, 54.005178150653627],
              [-2.852186829933158, 54.005248903686876],
              [-2.850910553726659, 54.005736106443123],
              [-2.85298641689052, 54.005775317394864],
              [-2.853292997139168, 54.00554664453707],
              [-2.853663107439508, 54.005961955607525],
              [-2.853410126493929, 54.006423935432309],
              [-2.853879873872307, 54.006265107054141],
              [-2.85347122873854, 54.006800096534796],
              [-2.853995009585962, 54.006969842885226],
              [-2.853505621890462, 54.006989497972057],
              [-2.853801998953095, 54.007230966033795],
              [-2.852997546704437, 54.008930007791889],
              [-2.852593278453793, 54.009083874041799],
              [-2.851503229281682, 54.008384251322788],
              [-2.850398243391736, 54.009120099050541],
              [-2.850795009107362, 54.009570283069792],
              [-2.850619229170458, 54.009106850763629],
              [-2.851543261057248, 54.008476543496485],
              [-2.852764852611121, 54.00941521060755],
              [-2.850761333410829, 54.009939027089935],
              [-2.849407378515544, 54.009542349505203],
              [-2.849132140249282, 54.008989738591289],
              [-2.850150177677579, 54.007206523363138],
              [-2.848810726905489, 54.009091775342206],
              [-2.849132141991061, 54.009589234268411],
              [-2.84816227415172, 54.009919649002313],
              [-2.847728733886129, 54.009760925069635],
              [-2.847722128433051, 54.010411698461517],
              [-2.846712440440645, 54.010811589350851],
              [-2.846778359164976, 54.01157779591928],
              [-2.845990596558882, 54.012387764084693],
              [-2.844735121839676, 54.012936770041961],
              [-2.844841350091823, 54.013660450682458],
              [-2.844437927677221, 54.01370912438928],
              [-2.843652485865698, 54.014864196851399],
              [-2.844661922292759, 54.013767769463179],
              [-2.845002830956415, 54.014851115078201],
              [-2.84492006044855, 54.013026248114393],
              [-2.845381637813006, 54.01268505586097],
              [-2.845931465828083, 54.013008347762074],
              [-2.845900102847597, 54.013568516752265],
              [-2.847423179533961, 54.01394696444779],
              [-2.846955259217172, 54.013525133666491],
              [-2.846055258987957, 54.013468557323002],
              [-2.845872172290846, 54.012718454842442],
              [-2.847354719767972, 54.011404760305318],
              [-2.847545575111221, 54.012085598864381],
              [-2.846988798814304, 54.012547010044983],
              [-2.848216780609988, 54.01304886413574],
              [-2.847206084795446, 54.012576037474417],
              [-2.847771556968806, 54.012092094100218],
              [-2.847352190753176, 54.011205245932608],
              [-2.848319811946589, 54.011587597261773],
              [-2.848629652187405, 54.012340396478599],
              [-2.849658494833882, 54.012430196820546],
              [-2.849264773697468, 54.013178978441566],
              [-2.849924885987139, 54.013221049196773],
              [-2.849348053671889, 54.013146932137282],
              [-2.849744866855579, 54.01307402121359],
              [-2.849863667161948, 54.012463799060711],
              [-2.848719773311666, 54.012269654165898],
              [-2.848491127115185, 54.011532460236005],
              [-2.847168945187445, 54.011123848793638],
              [-2.847151224522425, 54.010852537998858],
              [-2.847977896183076, 54.010757727250578],
              [-2.849193015806938, 54.009880912210761],
              [-2.849910626316488, 54.009976504231282],
              [-2.849755188291148, 54.010211289869538],
              [-2.851477071559535, 54.010092145856454],
              [-2.853234326184447, 54.009539507134967],
              [-2.855492021278212, 54.005342262913707],
              [-2.858890995772527, 54.003987800268753],
              [-2.860786407632989, 54.003862787449663],
              [-2.863045293806171, 54.004787624775588],
              [-2.864255971128319, 54.0068119997376],
              [-2.863498477898234, 54.007073601790282],
              [-2.864102191433562, 54.007195992494594],
              [-2.86409271421531, 54.007769492159873],
              [-2.864479840561518, 54.00738022590415],
              [-2.86585183220059, 54.008862349100852],
              [-2.865541134417133, 54.009184558388597],
              [-2.865068113850473, 54.008670258158965],
              [-2.862717794662502, 54.008648505456733],
              [-2.865110472570272, 54.008946782210238],
              [-2.8636722129506, 54.009199801916566],
              [-2.865788312502302, 54.009477582763161],
              [-2.866141754047881, 54.010845698689423],
              [-2.86809162928806, 54.010977234711874],
              [-2.867327431437589, 54.011505851129449],
              [-2.869899726471341, 54.010017728987407],
              [-2.870042007074413, 54.010318078633063],
              [-2.868831300103243, 54.011396120920629],
              [-2.869255784893346, 54.01170133815333],
              [-2.868235361351304, 54.011870497634526],
              [-2.869385200376352, 54.011979927343809],
              [-2.870000746890694, 54.01281135242737],
              [-2.868743121797577, 54.012656869181335],
              [-2.868985946331446, 54.012372891987511],
              [-2.867823669963883, 54.012619471075361],
              [-2.868614682437929, 54.01257151322578],
              [-2.868232621076126, 54.012911322119344],
              [-2.86800497528903, 54.0128701506002],
              [-2.86842975021973, 54.013217285711953],
              [-2.868584119972841, 54.012789242516035],
              [-2.869378609539297, 54.012834729208542],
              [-2.869133723667677, 54.013664491069264],
              [-2.868996610248097, 54.014129060303709],
              [-2.868604707758991, 54.014143577689623],
              [-2.869912016311762, 54.014482854637194],
              [-2.869225906628806, 54.013761421833436],
              [-2.869708376588698, 54.012987834809771],
              [-2.870456479141789, 54.013442601843295],
              [-2.869795727151918, 54.013736797717868],
              [-2.870638012072791, 54.013731597705295],
              [-2.871092309085957, 54.014147144279328],
              [-2.870109547812146, 54.01451468011529],
              [-2.870904433684387, 54.014504428672232],
              [-2.871662391385346, 54.013166020638792],
              [-2.870842349050886, 54.013651023288666],
              [-2.870049508661106, 54.013100411455611],
              [-2.870339808405704, 54.01289787818007],
              [-2.871424014585537, 54.013005067385393],
              [-2.87098925317345, 54.012428496089363],
              [-2.870908523885108, 54.012873984770224],
              [-2.869907875793676, 54.012456101810457],
              [-2.870349099569071, 54.011954075915668],
              [-2.870901485923009, 54.01224487910433],
              [-2.872166076410188, 54.011930118022519],
              [-2.869531975676754, 54.011627436536948],
              [-2.870113840896756, 54.010469713854626],
              [-2.870309966970583, 54.010079472058749],
              [-2.870704319038371, 54.010037967355977],
              [-2.870464243850561, 54.009721532854783],
              [-2.86936016318094, 54.009754691114296],
              [-2.868947751782584, 54.010247517319215],
              [-2.867653131054416, 54.010144521709307],
              [-2.866538785060335, 54.010418605246066],
              [-2.866536561501894, 54.009357144016597],
              [-2.86757350830638, 54.009544701217081],
              [-2.866742728573191, 54.009071637910864],
              [-2.867393366858755, 54.008440483077614],
              [-2.868035878667891, 54.008665935556728],
              [-2.867905201614526, 54.008912250565885],
              [-2.868580146283362, 54.008936135708716],
              [-2.868228693053859, 54.008545901668988],
              [-2.869041589769418, 54.00881955145654],
              [-2.87000501904895, 54.008409021340796],
              [-2.867675193240989, 54.008344973854427],
              [-2.868322033978204, 54.007752489469148],
              [-2.866846274264179, 54.008474090856048],
              [-2.867088923105706, 54.007815320751128],
              [-2.867938652767159, 54.007733687889122],
              [-2.868109685897397, 54.007373832997096],
              [-2.867504084908324, 54.007674808793602],
              [-2.866723557329374, 54.007415295948277],
              [-2.866884109220243, 54.007945326136607],
              [-2.866425078139152, 54.008177829370368],
              [-2.865950742378242, 54.007453223840379],
              [-2.865568846749928, 54.007652811336207],
              [-2.865357105961622, 54.007375709610791],
              [-2.866440231953352, 54.00633698653985],
              [-2.866516059501837, 54.006755278644071],
              [-2.867260828002587, 54.006763388062744],
              [-2.866839745699912, 54.006104912441586],
              [-2.868758785906961, 54.006011960409062],
              [-2.867650565022676, 54.005914807831054],
              [-2.867865364094574, 54.005678671012568],
              [-2.865552253609946, 54.006338891587795],
              [-2.864811145284343, 54.004961879252008],
              [-2.866440266486233, 54.004576245013361],
              [-2.867798334173851, 54.004657223913625],
              [-2.86812487219315, 54.004365453019503],
              [-2.868272113927853, 54.004622343749837],
              [-2.867612775523072, 54.004978535191576],
              [-2.869015107372538, 54.004986378309496],
              [-2.86900576122249, 54.005343267982141],
              [-2.869477041608252, 54.004968656196681],
              [-2.868723216138009, 54.004597512415259],
              [-2.868605555200649, 54.004076162304479],
              [-2.870324665340345, 54.00391182736265],
              [-2.870132270739013, 54.00427004280855],
              [-2.871113790419635, 54.004219789512071],
              [-2.871622782590908, 54.004483041288886],
              [-2.871836237481615, 54.005278725296122],
              [-2.869400106550182, 54.00669400257496],
              [-2.869919150818387, 54.007218738430311],
              [-2.86977287467114, 54.006716471465189],
              [-2.870623075918742, 54.006512579834727],
              [-2.871020172518862, 54.00740759958078],
              [-2.871161079355786, 54.006775623370167],
              [-2.870424575175195, 54.006359424832866],
              [-2.872114201045125, 54.005364790405267],
              [-2.871878145643445, 54.00750204308963],
              [-2.872642342916977, 54.007635809706208],
              [-2.872770580378652, 54.007274461197603],
              [-2.872085379706939, 54.006759032598552],
              [-2.872468140881892, 54.006529757675366],
              [-2.873512935586273, 54.006656987247119],
              [-2.873080070742595, 54.006387797600887],
              [-2.873679756043559, 54.005952915790544],
              [-2.874985216392149, 54.006577967985997],
              [-2.874739237704624, 54.007435412833054],
              [-2.875281820336629, 54.007187888376102],
              [-2.875108635515158, 54.006497076418285],
              [-2.874193134019885, 54.005841326733865],
              [-2.875954950516185, 54.005237988040925],
              [-2.874435071011362, 54.005445893638729],
              [-2.874370898319149, 54.004859446409732],
              [-2.875351181375581, 54.004749846960429],
              [-2.876164777989909, 54.00418666373502],
              [-2.877335582012778, 54.005084108871856],
              [-2.877217829771741, 54.006231833107194],
              [-2.877902797342154, 54.00629873485844],
              [-2.878148010407864, 54.005843050891883],
              [-2.877596983049932, 54.005615186666027],
              [-2.87772790676381, 54.005093827127418],
              [-2.877060109821403, 54.004173840893721],
              [-2.876111510406687, 54.004047738586891],
              [-2.875267528500981, 54.004326224218651],
              [-2.871924520253609, 54.002787519485338],
              [-2.873494739155751, 54.001854849005909],
              [-2.874766072888063, 54.002030744105475],
              [-2.874824996644129, 54.001268144168506],
              [-2.875663879387038, 54.001621552924448],
              [-2.876353271875741, 54.001394524338352],
              [-2.876908788166298, 54.000460216443166],
              [-2.87680673798963, 53.999306905078782],
              [-2.87598097844219, 53.998922849506222],
              [-2.875529003349792, 53.997449418935581],
              [-2.876319405294885, 53.995788970109622],
              [-2.876232722587084, 53.994131319544245],
              [-2.881045332530829, 53.989441247069145],
              [-2.882282433908101, 53.989665857000276],
              [-2.882676248126851, 53.990113263022685],
              [-2.882708990147328, 53.993319039148339],
              [-2.884504094363267, 53.997050233935013],
              [-2.884520765861438, 53.998052271491481],
              [-2.884928924133707, 53.998459222622081],
              [-2.885984700622021, 53.99951183335866],
              [-2.887114193695874, 54.000637870716041],
              [-2.887434153209192, 54.001497795374931],
              [-2.887507401363473, 54.001694650243138],
              [-2.888053736807524, 54.001630394481417],
              [-2.887457246550384, 54.001502737190471],
              [-2.887211774483098, 54.000563448835663],
              [-2.885845431477398, 53.999304048620139],
              [-2.884704963459136, 53.997602415035189],
              [-2.888877487794511, 53.998189995146348],
              [-2.889431394325013, 53.998769216774896],
              [-2.891129586714881, 53.999353437258605],
              [-2.889584432751143, 53.998720447571294],
              [-2.888960776945495, 53.998089612342355],
              [-2.887175646977749, 53.997789124863473],
              [-2.88738274406947, 53.99755660438683],
              [-2.889276987987695, 53.997394298717516],
              [-2.889422449009028, 53.997133469237639],
              [-2.887435947094682, 53.99740431449478],
              [-2.886847067633249, 53.997760991237733],
              [-2.884916401515453, 53.997214374740118],
              [-2.883981892980321, 53.99472349183192],
              [-2.885062877502861, 53.995346491068403],
              [-2.887922266076797, 53.995454822274461],
              [-2.888998259902312, 53.995911545370369],
              [-2.889000305888624, 53.995578075783264],
              [-2.887894305723462, 53.995287852268881],
              [-2.885211198105985, 53.995003854903935],
              [-2.884224932163842, 53.994315446475625],
              [-2.884077309240435, 53.993035744173952],
              [-2.885027454069656, 53.991951089113456],
              [-2.885477137798915, 53.992011701277839],
              [-2.885409152516153, 53.991897045413246],
              [-2.886114860876772, 53.99101191827684],
              [-2.887599024809115, 53.990514712814772],
              [-2.890078196373905, 53.990331892309271],
              [-2.893151429138589, 53.990398966481273],
              [-2.893700107817078, 53.990807435857427],
              [-2.894066729919341, 53.990335534619049],
              [-2.897938757779416, 53.99109131249962],
              [-2.900232976325967, 53.992021480824768],
              [-2.900280110786299, 53.993145526931968],
              [-2.898996742087318, 53.994534794071249],
              [-2.900985291739335, 53.994792235984875],
              [-2.901490696803418, 53.996650758802417],
              [-2.902924755932061, 53.996868286491363],
              [-2.903751705796361, 53.998424183865303],
              [-2.902814740278086, 53.99876288430891],
              [-2.900705671905349, 53.998726581444735],
              [-2.899099907612688, 53.999179917571396],
              [-2.896943914209849, 54.000722190847576],
              [-2.899633831085534, 53.999532744727041],
              [-2.900678475962807, 53.999869159353111],
              [-2.904096900593997, 54.002877834937024],
              [-2.903673037347089, 54.003172234070746],
              [-2.904163858831123, 54.003644006784278],
              [-2.904382184577242, 54.00505797224195],
              [-2.903418282757623, 54.004793785249497],
              [-2.90502860153724, 54.005526772717097],
              [-2.905073545390966, 54.006191545334573],
              [-2.905917857069241, 54.00579510479939],
              [-2.906349228998879, 54.005915886378219],
              [-2.906952976098139, 54.007016853936172],
              [-2.906637145695925, 54.010456242499998],
              [-2.906240142250343, 54.010655174871943],
              [-2.905520999680334, 54.010284897971616],
              [-2.905177977683799, 54.010510384020705],
              [-2.906086017427333, 54.012621109455807],
              [-2.905530402055808, 54.013738907532272],
              [-2.905673663206529, 54.015345776868131],
              [-2.903826938930426, 54.017241760171295],
              [-2.902315705618206, 54.017933504524898],
              [-2.903422497487022, 54.02055147416862],
              [-2.904771808866812, 54.021748408283443],
              [-2.905710106700966, 54.022080187784773],
              [-2.909565440962163, 54.021436297634267],
              [-2.910798428543148, 54.024145820847764],
              [-2.911761960567122, 54.024928557996297],
              [-2.912671250538016, 54.025114898443576],
              [-2.912765645775011, 54.024685455692477],
              [-2.913249775853783, 54.02539272643299],
              [-2.918852630412248, 54.027633869291428],
              [-2.924426991907184, 54.031315742563564],
              [-2.923897400672801, 54.03194357882731],
              [-2.923401813374794, 54.031626516465408],
              [-2.919918873985776, 54.031122032309788],
              [-2.920567356582436, 54.03151972859893],
              [-2.919669623169989, 54.032348998617266],
              [-2.912281119979783, 54.03379034151402],
              [-2.912697571943499, 54.035236936949339],
              [-2.913366920405194, 54.035541937476154],
              [-2.920231474523263, 54.034353507710122],
              [-2.922001459865402, 54.033611920571126],
              [-2.922907217834516, 54.03273223904673],
              [-2.923411425232075, 54.033300001347691],
              [-2.920194270491105, 54.035364937667993],
              [-2.91234302797575, 54.037802092353814],
              [-2.912554435595565, 54.038390995835464],
              [-2.911663633433421, 54.038085872790376],
              [-2.905980408296775, 54.039708018182367],
              [-2.906128383106572, 54.042298125211225],
              [-2.906539305182601, 54.042514332978712],
              [-2.905382509124435, 54.044516573716841],
              [-2.905699878601891, 54.046328846415321],
              [-2.906262514715546, 54.046987915820509],
              [-2.905789051592336, 54.048251591138985],
              [-2.905399969151496, 54.048410012872921],
              [-2.905267794058588, 54.0480209321614],
              [-2.90293967749844, 54.047788574706729],
              [-2.902389566568805, 54.0480578485897],
              [-2.900670932689837, 54.047899066170928],
              [-2.898648910813624, 54.048331236090249],
              [-2.89694729097883, 54.049387439906738],
              [-2.894528418631882, 54.055666439668109],
              [-2.892524094680486, 54.057941791789446],
              [-2.89213925137716, 54.059658640300164],
              [-2.890311081507007, 54.061398764951335],
              [-2.888298283576487, 54.063014394300502],
              [-2.887063907989092, 54.064956801224803],
              [-2.883799651686945, 54.065506627280328],
              [-2.881784106060469, 54.066220680693441],
              [-2.877576693965283, 54.06893433945239],
              [-2.876628427679802, 54.071142385461407],
              [-2.876424934752671, 54.072944138782141],
              [-2.878733698160009, 54.074588249635347],
              [-2.878126149945926, 54.074767051775481],
              [-2.876141534852437, 54.073202357934605],
              [-2.873906784047098, 54.073836083859433],
              [-2.87178721127064, 54.073916179582319],
              [-2.871374704508333, 54.072828042457438],
              [-2.868539656757038, 54.07303999181574],
              [-2.865824716957801, 54.073737252790657],
              [-2.864378412493577, 54.074813616257849],
              [-2.864536249128383, 54.075127955500314],
              [-2.864163440708857, 54.07491222994534],
              [-2.860345962107601, 54.076550222370997],
              [-2.860148563870017, 54.076982152314471],
              [-2.859658846151594, 54.076655799607209],
              [-2.857169663013355, 54.077424051229372],
              [-2.853831518303716, 54.077493633923531],
              [-2.850649930229827, 54.077222276320256],
              [-2.84974895229468, 54.077484798897707],
              [-2.848860687877565, 54.077247500999938],
              [-2.847221068849342, 54.077490049664654],
              [-2.846403388610273, 54.07794160367304],
              [-2.846618533852946, 54.078224105092836],
              [-2.842806452266265, 54.078593315343582],
              [-2.841712379538342, 54.079136651983625],
              [-2.841361138925521, 54.078925199156366],
              [-2.840727911533883, 54.079130055884015],
              [-2.835658027795716, 54.082099015141232],
              [-2.833699470733987, 54.083830179634617],
              [-2.833274852677403, 54.084915255545738],
              [-2.829303273625711, 54.085525114617305],
              [-2.829358767714287, 54.086084672839625],
              [-2.827195929033781, 54.086176861631159],
              [-2.827039348688106, 54.086989537763024],
              [-2.826193536445751, 54.086891990580916],
              [-2.825443122375718, 54.086594253435564],
              [-2.825732887568833, 54.087251969846456],
              [-2.823152808162134, 54.087430535913498],
              [-2.819589971235308, 54.088785959016249],
              [-2.81705951146968, 54.091028553490631],
              [-2.817054454937649, 54.092093641841352],
              [-2.815484031823023, 54.092862868399543],
              [-2.816272436815716, 54.093691588334529],
              [-2.816391994605468, 54.094640786727538],
              [-2.81434011495815, 54.094879389433402],
              [-2.815164703426384, 54.095135349196809],
              [-2.816017801766135, 54.094895882625885],
              [-2.815867063693891, 54.095395727491713],
              [-2.81662389322304, 54.094536751847208],
              [-2.816435223894926, 54.093650038518902],
              [-2.815656153203573, 54.092907539172558],
              [-2.817071410875914, 54.09225620578772],
              [-2.817268961040315, 54.091028029401379],
              [-2.820475369360874, 54.088564215610369],
              [-2.823983445638667, 54.087533601114217],
              [-2.825436210205234, 54.087555097757495],
              [-2.824096361238427, 54.088679671791738],
              [-2.826310525194315, 54.088009270526861],
              [-2.826094233708718, 54.088434081968501],
              [-2.826771021432214, 54.088179570926016],
              [-2.827668759383795, 54.089348103665301],
              [-2.827148542476512, 54.09009587250214],
              [-2.82809926518324, 54.089470071199642],
              [-2.828193057571479, 54.089803771923229],
              [-2.828617712197381, 54.089631876570259],
              [-2.829246600712624, 54.093490501759405],
              [-2.828405810533681, 54.093421696133092],
              [-2.828100674459082, 54.093915429879949],
              [-2.823296991775568, 54.093784824812467],
              [-2.821257510884406, 54.094182544921978],
              [-2.824993112273126, 54.093884653105079],
              [-2.827583365137494, 54.094427699487319],
              [-2.827467894017142, 54.098688709829069],
              [-2.826948935225918, 54.099966747366167],
              [-2.824631066016449, 54.101915931459608],
              [-2.820340097923964, 54.103943251859754],
              [-2.818761604815481, 54.104322503842496],
              [-2.813139958593498, 54.105046398466563],
              [-2.812123644973963, 54.106414910900583],
              [-2.808581271104923, 54.108137458985752],
              [-2.809162017383635, 54.10850564812992],
              [-2.808206082543537, 54.110394111462242],
              [-2.806676212429561, 54.111247430565719],
              [-2.80571052766957, 54.112563417830373],
              [-2.8042661970719, 54.112627007669197],
              [-2.803455792771486, 54.114067766714065],
              [-2.801235787266812, 54.115081109970902],
              [-2.800391699888156, 54.118274682454],
              [-2.799820451171731, 54.119197028561381],
              [-2.798761134837222, 54.119881741092165],
              [-2.798989389019729, 54.121009984909527],
              [-2.798561720361286, 54.122400530974012],
              [-2.798041177798893, 54.124692262247791],
              [-2.797279581821077, 54.125032553125692],
              [-2.797794528647277, 54.125074977700535],
              [-2.797472549181981, 54.126156538746145],
              [-2.792985239315796, 54.127557719923679],
              [-2.791837463521508, 54.129638746135676],
              [-2.7925361129289, 54.130563477699432],
              [-2.791028316791676, 54.131024581704054],
              [-2.790062245771668, 54.132370098146076],
              [-2.788079967482508, 54.132914269474846],
              [-2.786904706450124, 54.13396992745335],
              [-2.785606815718806, 54.134129400433594],
              [-2.785089843224911, 54.135036937868335],
              [-2.785933794253057, 54.134423860071998],
              [-2.787072067736524, 54.13432115142357],
              [-2.787943611921298, 54.133551492615574],
              [-2.790440482786468, 54.132622865658085],
              [-2.79155321299914, 54.131424679067386],
              [-2.79335558794745, 54.130842090198271],
              [-2.793412643413215, 54.130304248170816],
              [-2.792579825934622, 54.129406469729247],
              [-2.794636856404156, 54.127742757568448],
              [-2.796304150656487, 54.127946543086736],
              [-2.798426872210178, 54.127641275498526],
              [-2.801710408562744, 54.1284139606678],
              [-2.802426986246914, 54.128672525243225],
              [-2.803408181465049, 54.129873031139297],
              [-2.803749354835099, 54.131448998480202],
              [-2.804245818458411, 54.131559825873765],
              [-2.804192922599118, 54.130883403276869],
              [-2.804778979609968, 54.131353138951717],
              [-2.804513099869466, 54.129479177098865],
              [-2.804017592268986, 54.1290214179648],
              [-2.804312532153979, 54.128842388712023],
              [-2.803853398725849, 54.128840063869482],
              [-2.801843500612875, 54.125955827321057],
              [-2.804290227346816, 54.123031066389593],
              [-2.805928199377924, 54.122598582042997],
              [-2.810758709506035, 54.121114611232692],
              [-2.811593739428543, 54.121096399237082],
              [-2.811307886884023, 54.121971037354612],
              [-2.812846441846882, 54.121155351722614],
              [-2.815188343319722, 54.121283313141312],
              [-2.817887447588176, 54.121834821193374],
              [-2.820722140429321, 54.122972244550624],
              [-2.820814535767362, 54.125091825109386],
              [-2.822558660071083, 54.126005654152301],
              [-2.825325701504291, 54.128260609392008],
              [-2.827933134292771, 54.130938124698247],
              [-2.830512094578754, 54.134623306078559],
              [-2.831586917354258, 54.1381570543601],
              [-2.831412160282415, 54.139671796773719],
              [-2.830394304222765, 54.140133603142836],
              [-2.828738379570872, 54.140302301993927],
              [-2.828255220040186, 54.139179464760431],
              [-2.827959171778825, 54.140068591099983],
              [-2.826814570359434, 54.139933554623774],
              [-2.827988286264348, 54.140300274339332],
              [-2.827613262585966, 54.1408376237939],
              [-2.828277401355108, 54.141594316104275],
              [-2.828006409402554, 54.143509668427711],
              [-2.828622704910566, 54.141715071259348],
              [-2.829898048215995, 54.141495971374795],
              [-2.833939957020778, 54.141911129218407],
              [-2.834379376791141, 54.142448246687806],
              [-2.834430708710521, 54.141879865958352],
              [-2.834942682065742, 54.14176217121414],
              [-2.835479456560195, 54.141735977040135],
              [-2.835914156335427, 54.142417824296977],
              [-2.836034929601292, 54.142106009781926],
              [-2.836990724498079, 54.1422108148776],
              [-2.83825932500543, 54.143098962483649],
              [-2.841707940327949, 54.147232621920722],
              [-2.841876836880845, 54.149670711035618],
              [-2.841188938277694, 54.150479023170284],
              [-2.839128284463067, 54.151170181627926],
              [-2.837007342171369, 54.150622316628969],
              [-2.836350799649983, 54.150561269714188],
              [-2.836701482872505, 54.150716117819009],
              [-2.841054889164914, 54.152476133880299],
              [-2.841289637537141, 54.153179131076065],
              [-2.841863881772287, 54.152049853197738],
              [-2.843170999122808, 54.151799833407729],
              [-2.843494924654689, 54.151917100782256],
              [-2.843167034485472, 54.153111170462864],
              [-2.847536481846391, 54.152183510200949],
              [-2.851072894793994, 54.152454255408408],
              [-2.858815926576848, 54.153632379359877],
              [-2.861140031721735, 54.154430064856655],
              [-2.862985539001342, 54.155477401643282],
              [-2.864885587111208, 54.157960558545462],
              [-2.865990572865941, 54.160275045077938],
              [-2.864808954408349, 54.16397839416998],
              [-2.862463446795343, 54.165273273964658],
              [-2.861714532563856, 54.166528828524662],
              [-2.861922276797894, 54.167238270008959],
              [-2.862271971950553, 54.167849627171506],
              [-2.863044108180829, 54.167635579660178],
              [-2.863574667193626, 54.16810452927195],
              [-2.864242043457855, 54.169331057895292],
              [-2.863601477607328, 54.169758975700219],
              [-2.864072394993582, 54.169643250534897],
              [-2.864607835534685, 54.170197543838988],
              [-2.864405194717651, 54.170690626894952],
              [-2.863266900595836, 54.171052010925976],
              [-2.864844380104449, 54.170962497103666],
              [-2.865702676560693, 54.171571088624432],
              [-2.865394950166511, 54.17260688790595],
              [-2.863997608044673, 54.173624445550935],
              [-2.866209094024166, 54.172566880231237],
              [-2.866989352877949, 54.172811122816526],
              [-2.869492426825583, 54.176676663821098],
              [-2.837217679810891, 54.174162356160323],
              [-2.828801943636971, 54.17895708712944],
              [-2.823467415533026, 54.180056928501408],
              [-2.821140725882284, 54.181020117850863],
              [-2.818786634439018, 54.180834824308356],
              [-2.81562613903304, 54.182103761185942],
              [-2.814985037930557, 54.183318739964591],
              [-2.813773805131165, 54.183906635528032],
              [-2.813524585167211, 54.184480833323413],
              [-2.813973877474644, 54.184886735618939],
              [-2.813687578520546, 54.185757776945465],
              [-2.81408947101946, 54.186714043574739],
              [-2.815408013527486, 54.187292016561642],
              [-2.81633399698348, 54.189799589873658],
              [-2.811076578141677, 54.191950805562932],
              [-2.809041561518633, 54.194006486297639],
              [-2.806176147631925, 54.193808200618435],
              [-2.805499838294663, 54.194891242519333],
              [-2.804639542739014, 54.195500063774681],
              [-2.804049749350227, 54.19543749431886],
              [-2.803987074180421, 54.195998741161468],
              [-2.80286175280723, 54.196854680762883],
              [-2.802127667484723, 54.196940460826319],
              [-2.800071229072567, 54.196415780415315],
              [-2.799933033912279, 54.197275915865283],
              [-2.799230412655557, 54.197721873163097],
              [-2.796998650728293, 54.197007765982406],
              [-2.796142827031351, 54.197220141863681],
              [-2.795013515203363, 54.197000214890977],
              [-2.794896373797268, 54.195768784332159],
              [-2.788628326081975, 54.192841378774723],
              [-2.786928177700383, 54.193221891544042],
              [-2.786458228457617, 54.193091045767552],
              [-2.784347118480811, 54.191682072363221],
              [-2.783148208044231, 54.191498438410306],
              [-2.782493276232563, 54.190741443656634],
              [-2.780205481545893, 54.189539362523846],
              [-2.777742929475818, 54.189469909151917],
              [-2.776459002653177, 54.188670206721874],
              [-2.774797425313642, 54.188405887280638],
              [-2.771836447903351, 54.187325715112934],
              [-2.769761690433591, 54.187335400210728],
              [-2.769220920814908, 54.187015301242894],
              [-2.76911168485855, 54.186166668419339],
              [-2.768232734069348, 54.185953879692619],
              [-2.753426711097358, 54.187723616384368],
              [-2.750787704837865, 54.188732330762136],
              [-2.749301957040546, 54.188944704560576],
              [-2.748603746006791, 54.187028394846998],
              [-2.746787120854628, 54.186990242471019],
              [-2.745532692499248, 54.186599864179549],
              [-2.744942605980744, 54.185912368846935],
              [-2.743054828089752, 54.185492627088458],
              [-2.738768673423166, 54.1850579347795],
              [-2.73438753301277, 54.18410059086775],
              [-2.734985617989877, 54.182313800440603],
              [-2.736142704657415, 54.181119468837196],
              [-2.735707331877832, 54.180071477872765],
              [-2.735935245099984, 54.178842377055538],
              [-2.736587626931184, 54.178221837512503],
              [-2.7362836633326, 54.176894428071748],
              [-2.737059235902982, 54.176483442576739],
              [-2.736550970004098, 54.175556334244078],
              [-2.736528921677808, 54.173971052745813],
              [-2.735827442959662, 54.17320330505882],
              [-2.736489893426897, 54.169016413288347],
              [-2.727437622281326, 54.167909348498625],
              [-2.727889917964972, 54.166800237946994],
              [-2.723448172622623, 54.16593094469912],
              [-2.717759445706284, 54.165390685948275],
              [-2.713580618338901, 54.164470029846726],
              [-2.70878837129726, 54.163850332505085],
              [-2.705592318087342, 54.164860441399725],
              [-2.70143931713758, 54.163364904789802],
              [-2.696345370463636, 54.164003837650093],
              [-2.694546882277013, 54.163339257517137],
              [-2.693126860437799, 54.163231501599618],
              [-2.691080044876111, 54.164569840072232],
              [-2.690003795069389, 54.164781829694043],
              [-2.687964339756252, 54.164636218562464],
              [-2.679876547846808, 54.161123973165431],
              [-2.678838076769906, 54.161864121042754],
              [-2.678179646911419, 54.161496644056598],
              [-2.675775157175075, 54.163748078892603],
              [-2.675827014557309, 54.165050090970077],
              [-2.676978862258469, 54.166367492447272],
              [-2.677141484561024, 54.167508901398897],
              [-2.676407578184078, 54.16824641480374],
              [-2.676444721988623, 54.169302248346973],
              [-2.675590962237905, 54.169549707292525],
              [-2.675137355795361, 54.170195763400777],
              [-2.67246866038038, 54.171027670990469],
              [-2.672581682690172, 54.171667854633995],
              [-2.67184631480609, 54.172135719917982],
              [-2.672985391721818, 54.172567952356054],
              [-2.672293628179369, 54.173363623364942],
              [-2.672825610461524, 54.173671622060027],
              [-2.672640669706512, 54.17473588652728],
              [-2.671898891488298, 54.175000668222573],
              [-2.670499576757391, 54.176572307892066],
              [-2.666979148415795, 54.177822260167417],
              [-2.666297169349272, 54.178476736406886],
              [-2.66477609025308, 54.178495929243823],
              [-2.662879140649143, 54.179728697266405],
              [-2.661507589143822, 54.179615806900195],
              [-2.659494907564656, 54.18022901469125],
              [-2.657728942114058, 54.181620063021533],
              [-2.653070004609853, 54.183211055129277],
              [-2.647741386626236, 54.18727165414704],
              [-2.644666987941171, 54.190893057112618],
              [-2.64151579674519, 54.200375556272633],
              [-2.639964072412974, 54.202034820124894],
              [-2.631224154506262, 54.198892171949467],
              [-2.629986998783201, 54.197775209519016],
              [-2.627370259186218, 54.197356569785661],
              [-2.624956533817012, 54.195558133787536],
              [-2.611772185538293, 54.195265433355999],
              [-2.605751934911306, 54.194343189728066],
              [-2.604465746569421, 54.194955411164493],
              [-2.598947139276811, 54.195215761797201],
              [-2.595550663019125, 54.196071141403401],
              [-2.590869853240188, 54.195872207536709],
              [-2.591015624735225, 54.194917018925288],
              [-2.587002766316756, 54.194469296152931],
              [-2.585310876446938, 54.196327159880127],
              [-2.583791700893038, 54.195225476191005],
              [-2.580970049832511, 54.195996768476476],
              [-2.578243236192559, 54.195444579003883],
              [-2.576076366553924, 54.195584384321684],
              [-2.571018381299638, 54.196810102642871],
              [-2.563938571809891, 54.197605604347586],
              [-2.563298879825354, 54.197906084192176],
              [-2.557792386389486, 54.198657904354846],
              [-2.550749094681243, 54.200282004814497],
              [-2.545531451003058, 54.202985818899386],
              [-2.543362249338956, 54.205672094301939],
              [-2.542117991267491, 54.206305026830982],
              [-2.540984076031278, 54.206233732151766],
              [-2.539768072483542, 54.207391381274739],
              [-2.535897651507152, 54.209468591545132],
              [-2.532408085867518, 54.209119177152651],
              [-2.528361139905158, 54.211702917434415],
              [-2.528122787717732, 54.211288742486545],
              [-2.52653759768568, 54.211790891605524],
              [-2.525994326574984, 54.212839404702571],
              [-2.523857012840632, 54.213703426115515],
              [-2.523541283519189, 54.214321337814049],
              [-2.522685768386967, 54.214574904482397],
              [-2.521381688086273, 54.214400807734286],
              [-2.520514992149694, 54.214863814819253],
              [-2.520542338179968, 54.215940392409856],
              [-2.52006705195368, 54.216435856140251],
              [-2.518847801997094, 54.216426734541436],
              [-2.518256919056169, 54.216877751731502],
              [-2.515078904351437, 54.217694861050646],
              [-2.512440096340778, 54.217853510561056],
              [-2.510867298331954, 54.217656176865361],
              [-2.499600117896, 54.219544064267396],
              [-2.498447368510344, 54.220479038847003],
              [-2.494241096957523, 54.222320818722594],
              [-2.493730609910584, 54.22298348773387],
              [-2.490699899101083, 54.224790659690839],
              [-2.47946481786698, 54.228237638730917],
              [-2.478677750943219, 54.22928420674603],
              [-2.475161354762536, 54.231241204983334],
              [-2.473215469901492, 54.233725791636452],
              [-2.472371514363778, 54.233710230374449],
              [-2.46487926703264, 54.236210009846566],
              [-2.459581955833722, 54.239557465073247],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000122",
        LAD13CDO: "30UJ",
        LAD13NM: "Pendle",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.179463581094709, 53.945971744577427],
            [-2.18139824135485, 53.945564386099115],
            [-2.183158151706717, 53.945616537214462],
            [-2.183840398058225, 53.945202956037228],
            [-2.183711853279207, 53.943688717350987],
            [-2.185484718938226, 53.941873159810257],
            [-2.185635258810743, 53.940440279010325],
            [-2.184383641492381, 53.937120334370746],
            [-2.182278546901693, 53.935376331117837],
            [-2.179313273314094, 53.935451804020644],
            [-2.175941998605494, 53.934246145153118],
            [-2.174424351140073, 53.936650790234431],
            [-2.173495950215704, 53.937199491245238],
            [-2.171676119248041, 53.937673967112275],
            [-2.169784538623211, 53.936996286524163],
            [-2.164116437731773, 53.934621521846232],
            [-2.162072200054674, 53.932610144547802],
            [-2.161645708860271, 53.931814404325237],
            [-2.163785622823467, 53.930274592856151],
            [-2.163738900178463, 53.928845601560532],
            [-2.163212061115415, 53.928479618773984],
            [-2.162873771856943, 53.927255944398212],
            [-2.159659691400368, 53.928631800536422],
            [-2.159334043724979, 53.927900629260847],
            [-2.157815545248002, 53.928256755483069],
            [-2.156883405906639, 53.927007779865939],
            [-2.155178308017114, 53.927552859969381],
            [-2.15171311213127, 53.929469891504574],
            [-2.14811155328622, 53.927811657546556],
            [-2.149489511967454, 53.926464478301142],
            [-2.149430033322401, 53.926014265115903],
            [-2.14495767019514, 53.926499702791304],
            [-2.141794930468934, 53.925660432290492],
            [-2.140874674431749, 53.926420983794053],
            [-2.139974923372873, 53.928311266582],
            [-2.138212376090669, 53.927685965405075],
            [-2.137785577914628, 53.92802889063362],
            [-2.132220633114785, 53.926494654409716],
            [-2.131911442170377, 53.925995275104704],
            [-2.13254346381885, 53.925531707782589],
            [-2.130894363823387, 53.924590702783327],
            [-2.130155361532462, 53.92346623844179],
            [-2.127061746484042, 53.92365290843258],
            [-2.124346844653247, 53.923186594132432],
            [-2.124624734679271, 53.921909142846623],
            [-2.121754812560071, 53.91731664200217],
            [-2.118285942142335, 53.91764008127199],
            [-2.116683225288394, 53.917271356078231],
            [-2.113089687175548, 53.915425116077294],
            [-2.114691223649713, 53.913738384117877],
            [-2.111815156035325, 53.911919278267902],
            [-2.109787587602828, 53.909079214131125],
            [-2.107463523406087, 53.908028850925199],
            [-2.113173004148994, 53.904597445003503],
            [-2.103414148361535, 53.891878632050407],
            [-2.103016192071685, 53.890927165048915],
            [-2.095732262194069, 53.888141592781317],
            [-2.093852474284527, 53.885073738848469],
            [-2.090918766204035, 53.883408760719732],
            [-2.090677077436737, 53.882796872815739],
            [-2.086245983833058, 53.880733845157209],
            [-2.083704546587962, 53.877918862104792],
            [-2.083023123374925, 53.877927425865764],
            [-2.082454999690972, 53.877540442886321],
            [-2.082410561066184, 53.876603041933471],
            [-2.081444405644937, 53.875666271739945],
            [-2.082690739411093, 53.873685395285605],
            [-2.084243667016779, 53.872211204277129],
            [-2.08834238366769, 53.870045772513194],
            [-2.090093922304852, 53.868445528098647],
            [-2.089912358613924, 53.868167940002266],
            [-2.088987782523482, 53.868177618905207],
            [-2.086116645151391, 53.869643840418988],
            [-2.084494818061868, 53.870057538892247],
            [-2.081188433150622, 53.869957366594882],
            [-2.076720698206775, 53.866821757486747],
            [-2.076199077806284, 53.865970941110611],
            [-2.076456334610068, 53.865286801151569],
            [-2.075792897567483, 53.864200591499767],
            [-2.075962103803292, 53.863596500099881],
            [-2.074846832810044, 53.862353283475016],
            [-2.071365743773951, 53.861083629125041],
            [-2.068962680000404, 53.859717086499153],
            [-2.067798267511165, 53.859854368003404],
            [-2.066405192885279, 53.859619667886342],
            [-2.064754378432924, 53.858863795351475],
            [-2.063147906789601, 53.859241246405446],
            [-2.06228710426414, 53.859067333550549],
            [-2.046127656227604, 53.850127242452096],
            [-2.045072742182771, 53.838882003014326],
            [-2.046922251089341, 53.829496123388459],
            [-2.061248290928265, 53.82562123657096],
            [-2.084857137704391, 53.818539606255051],
            [-2.092075874726615, 53.814078050819674],
            [-2.096393032599445, 53.812464011818165],
            [-2.102789612336218, 53.809290438235898],
            [-2.112277221786083, 53.805693923922284],
            [-2.128621066959897, 53.819781350479147],
            [-2.133467926267904, 53.817344809874527],
            [-2.135343773311493, 53.815943282634471],
            [-2.142111698895142, 53.82043300260132],
            [-2.144106157937809, 53.81999829955285],
            [-2.148545254504263, 53.8197537962903],
            [-2.149947674397222, 53.82071914982734],
            [-2.150431184702478, 53.822966424343875],
            [-2.152018219830284, 53.823692442382807],
            [-2.154273576865974, 53.82313859847963],
            [-2.159085160768902, 53.823304872187293],
            [-2.162754630048665, 53.822742690549475],
            [-2.163842593981846, 53.82282119980907],
            [-2.164488571455063, 53.822528206736585],
            [-2.164864485625974, 53.822707448487627],
            [-2.167301594618166, 53.822456002363765],
            [-2.167881614193163, 53.822769768354597],
            [-2.169676689141113, 53.822653092276234],
            [-2.170384393439296, 53.82298194471106],
            [-2.171212941906274, 53.822768648150344],
            [-2.172167492584359, 53.823277794366419],
            [-2.172688988058407, 53.823017292527538],
            [-2.174974836250528, 53.822927691231918],
            [-2.175418646007933, 53.822621452502261],
            [-2.175810058257104, 53.82286265400726],
            [-2.176589894843351, 53.82263231470111],
            [-2.179056294031973, 53.822854250520479],
            [-2.181412585183791, 53.82219818152916],
            [-2.18209182756036, 53.822599810327979],
            [-2.182816182707352, 53.822196047739432],
            [-2.186465411322163, 53.822299177212919],
            [-2.191884863261739, 53.823175032050557],
            [-2.192635924242629, 53.823312240916685],
            [-2.193506883601909, 53.82408290066865],
            [-2.195220415628011, 53.825053513704738],
            [-2.196928553022531, 53.825181942043969],
            [-2.19626078121295, 53.824024493486753],
            [-2.195497738050116, 53.823603308212199],
            [-2.194826244776888, 53.821957812655256],
            [-2.195756897823324, 53.821520380768902],
            [-2.196782970264979, 53.820056362516517],
            [-2.19945971533558, 53.818204015597871],
            [-2.204287875777382, 53.814678897399247],
            [-2.207538511613544, 53.811390012151953],
            [-2.206964698595287, 53.813286563728646],
            [-2.210111476458323, 53.814188869155124],
            [-2.210620616216721, 53.813654090971205],
            [-2.212956774896271, 53.814017568367305],
            [-2.217683263470493, 53.815501972377561],
            [-2.223968424953868, 53.817836242816],
            [-2.22417175578184, 53.816377118774732],
            [-2.226383013711593, 53.816061978258595],
            [-2.228606925013785, 53.815008862061461],
            [-2.230841997022863, 53.814624386516144],
            [-2.233179343295204, 53.814613569110847],
            [-2.235404222349283, 53.813776934235726],
            [-2.248900851693957, 53.811896342138837],
            [-2.250672881697947, 53.811596045529214],
            [-2.261068600703025, 53.807753063392141],
            [-2.260957724891379, 53.808482229109224],
            [-2.262083999364145, 53.809618546434464],
            [-2.265921046632823, 53.80978535824012],
            [-2.266495180345726, 53.81004023983369],
            [-2.266968220728696, 53.811829589534618],
            [-2.268545623345129, 53.813174259465448],
            [-2.268194303242893, 53.814047777271284],
            [-2.268729971642692, 53.814688317624984],
            [-2.269804172159719, 53.814989697043679],
            [-2.273018338452268, 53.814115978269221],
            [-2.275348263572305, 53.812748072422075],
            [-2.276818768856078, 53.812576609768691],
            [-2.279076191157867, 53.810385500525442],
            [-2.279199970720888, 53.809131390452443],
            [-2.279983232896256, 53.808171445259099],
            [-2.281444086305332, 53.807946020291624],
            [-2.282668574005765, 53.807349931966186],
            [-2.285404306616085, 53.807702067854486],
            [-2.288335647107576, 53.808669347099411],
            [-2.288183816591177, 53.808894411640132],
            [-2.290611737988884, 53.809292108507243],
            [-2.293274416840477, 53.808941382592117],
            [-2.294693698766095, 53.808424687570465],
            [-2.295484177735492, 53.808757992618716],
            [-2.297782162062775, 53.811595200616964],
            [-2.297096567775512, 53.811929457075699],
            [-2.296761826797568, 53.812914469109806],
            [-2.297292179218411, 53.813173805798726],
            [-2.296441533895936, 53.813796981463838],
            [-2.297058598660347, 53.814289791793442],
            [-2.298380577944288, 53.815021723538145],
            [-2.299219915416377, 53.81409117398568],
            [-2.302057484384796, 53.81352949840791],
            [-2.303047115924775, 53.814499498400785],
            [-2.302963768213341, 53.817891767333002],
            [-2.303662560733058, 53.818325016347842],
            [-2.303950544511456, 53.819499012986526],
            [-2.303184354061751, 53.820042028732772],
            [-2.302091904488713, 53.820211064360478],
            [-2.301964380016368, 53.821065241776815],
            [-2.30577551045586, 53.819594137537742],
            [-2.308454937782785, 53.818143805237483],
            [-2.30925353770923, 53.817065887970216],
            [-2.309841495774498, 53.814412020126802],
            [-2.311362812733133, 53.812911580372393],
            [-2.312950482097472, 53.812177621824361],
            [-2.313204626214041, 53.812451091157158],
            [-2.314704522083226, 53.813790864169661],
            [-2.316037566817454, 53.813532996934484],
            [-2.317844118655314, 53.817043415120295],
            [-2.316913150677566, 53.817257996282095],
            [-2.317464520111628, 53.818264088275939],
            [-2.317170127014683, 53.819305674029636],
            [-2.315998492975844, 53.820245314380855],
            [-2.316190107858149, 53.820678028866624],
            [-2.315442334313232, 53.820823806477812],
            [-2.316414044555825, 53.822777924356402],
            [-2.314568054976909, 53.823142301353379],
            [-2.317135894729872, 53.827840729654554],
            [-2.314877430519014, 53.82853875551811],
            [-2.30802821471025, 53.828916967723586],
            [-2.305822467104045, 53.829580533084425],
            [-2.30723231337516, 53.830190804691306],
            [-2.307232788540625, 53.830463138219628],
            [-2.305875952582687, 53.831289903741244],
            [-2.302168128262354, 53.832272701502497],
            [-2.302420834621046, 53.833182542625494],
            [-2.301910484554467, 53.834052962746],
            [-2.302573061001115, 53.836380965468038],
            [-2.308560935649644, 53.836277621222195],
            [-2.309085990991993, 53.838448652623697],
            [-2.313800983102092, 53.838994554689535],
            [-2.318817397853491, 53.839193434908935],
            [-2.319091438358873, 53.839458748729633],
            [-2.322941499044468, 53.837970823105508],
            [-2.32703123825719, 53.839387920416634],
            [-2.32850217488753, 53.84073119079595],
            [-2.330431964853747, 53.84069712642119],
            [-2.331634512197486, 53.841521595050892],
            [-2.332577475514255, 53.840844887925918],
            [-2.333644038158398, 53.840617226253158],
            [-2.33337572335113, 53.841275889556428],
            [-2.331742656593907, 53.842512665032757],
            [-2.331253890276042, 53.84220483146953],
            [-2.329293540171518, 53.842033169226177],
            [-2.325215657505339, 53.842188106634481],
            [-2.327068599607712, 53.844178387009066],
            [-2.326460173726733, 53.845092319957608],
            [-2.317927610492815, 53.846908339697684],
            [-2.318533498149859, 53.850033629336316],
            [-2.32082875370131, 53.850822041925213],
            [-2.325172351480186, 53.851106043860902],
            [-2.322133449809319, 53.851870130281164],
            [-2.31699583336529, 53.851927863300205],
            [-2.314265190436339, 53.854297074724251],
            [-2.316267565537243, 53.854732217727587],
            [-2.317778976126706, 53.855573085429739],
            [-2.317486849999923, 53.857147642092919],
            [-2.319464311662766, 53.858281158099118],
            [-2.320141022514888, 53.85989268243307],
            [-2.318980482334555, 53.861630440288543],
            [-2.317584371472893, 53.862192294359524],
            [-2.314606017455998, 53.864260171120939],
            [-2.312377344311046, 53.865143215704073],
            [-2.309336267972415, 53.867635351884161],
            [-2.3079118159955, 53.868937767556254],
            [-2.305092724334048, 53.870660759803407],
            [-2.297261443939858, 53.872075345951778],
            [-2.29594706153623, 53.872670897933013],
            [-2.285840427975052, 53.873958209179285],
            [-2.277671099885096, 53.877798145984237],
            [-2.273695031885278, 53.878964024219677],
            [-2.272866683289458, 53.878598308140546],
            [-2.270463140716598, 53.875976599573775],
            [-2.269326711914964, 53.875703229656821],
            [-2.265687612833695, 53.875804821204753],
            [-2.263539167174297, 53.876403659893526],
            [-2.263511919749778, 53.876666165443794],
            [-2.258930966505095, 53.877713343628585],
            [-2.258548163893861, 53.880258632950948],
            [-2.259303890911561, 53.881439803391864],
            [-2.259194918035066, 53.882020654513703],
            [-2.255702054455299, 53.882497318854071],
            [-2.255146784155406, 53.882993732287495],
            [-2.253498510341247, 53.88312126013755],
            [-2.252595289198974, 53.883945554502908],
            [-2.248902139912953, 53.8855737858417],
            [-2.248604193087215, 53.885357796693334],
            [-2.24642212278676, 53.88579641335658],
            [-2.241944515779243, 53.886807675342226],
            [-2.238379981876413, 53.886825599242727],
            [-2.237048356881173, 53.887292011616417],
            [-2.235119441851726, 53.887063922629387],
            [-2.234081468120136, 53.887269078488103],
            [-2.231937353723215, 53.888562101139314],
            [-2.228853582331437, 53.88888260023802],
            [-2.226627956962083, 53.889498000040597],
            [-2.224016764762985, 53.890861867352207],
            [-2.222790543068266, 53.893174923874113],
            [-2.223110079805966, 53.894029971384747],
            [-2.222915606017752, 53.896370763763862],
            [-2.224730755708942, 53.897449510443927],
            [-2.226580412503683, 53.897857672607287],
            [-2.227348366106384, 53.897755555624073],
            [-2.226939463985619, 53.898682076091269],
            [-2.229563718614251, 53.902123012879201],
            [-2.234953720517678, 53.907948364687144],
            [-2.237576123509472, 53.915399484219165],
            [-2.240784738815643, 53.919246156425437],
            [-2.243735423454859, 53.923934522474724],
            [-2.244427981345803, 53.926488341717217],
            [-2.244611379545659, 53.930763456404129],
            [-2.245838306667475, 53.933248765864015],
            [-2.242800358049394, 53.934664234813468],
            [-2.242363194918641, 53.93518551069662],
            [-2.240472906667373, 53.935706110849182],
            [-2.239490799424885, 53.936562812100966],
            [-2.238131236009821, 53.936678763891152],
            [-2.235297645358668, 53.937686490199582],
            [-2.234036487485671, 53.938518527572846],
            [-2.229914209246677, 53.939296748673186],
            [-2.228712656587265, 53.941027391766134],
            [-2.225219135294711, 53.9417943644684],
            [-2.22629262146747, 53.942825038244763],
            [-2.225820847313744, 53.942915804624384],
            [-2.225503195157537, 53.943618345872835],
            [-2.224180984095679, 53.943079760489546],
            [-2.224294158185527, 53.942594210068144],
            [-2.222817019512258, 53.942703916494757],
            [-2.222766245592475, 53.943181260686167],
            [-2.222006988909046, 53.943358827917919],
            [-2.220679124134731, 53.943470029281961],
            [-2.219976886568272, 53.943195394647994],
            [-2.219181842917221, 53.943523105141153],
            [-2.218133557168779, 53.943495355884409],
            [-2.217399642351623, 53.942400181610097],
            [-2.215614169197463, 53.941510020332032],
            [-2.214924313817252, 53.942148490068725],
            [-2.209720503374029, 53.94339351525597],
            [-2.206948283167692, 53.945025112191551],
            [-2.204068167964053, 53.945822764982452],
            [-2.202405594264093, 53.94668570882304],
            [-2.19011846559339, 53.949807479150543],
            [-2.1872021095636, 53.95082318595491],
            [-2.184519041771085, 53.952250101475016],
            [-2.183521901937168, 53.95142296157146],
            [-2.18367722665226, 53.949323187918424],
            [-2.180707881778555, 53.94798223252959],
            [-2.180458310846224, 53.946642535640997],
            [-2.179463581094709, 53.945971744577427],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000123",
        LAD13CDO: "30UK",
        LAD13NM: "Preston",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-2.788499723114369, 53.753225256495433],
              [-2.793969351767545, 53.751978418217242],
              [-2.794073009900616, 53.752650827900602],
              [-2.790734587203646, 53.754484168672924],
              [-2.789802568399215, 53.756381448515121],
              [-2.788499723114369, 53.753225256495433],
            ],
          ],
          [
            [
              [-2.729404847494556, 53.757663131242829],
              [-2.724147479481676, 53.756941803373969],
              [-2.720965246912238, 53.756137669773764],
              [-2.718887801654948, 53.754831577406101],
              [-2.718254727163798, 53.753284004705257],
              [-2.718543654921572, 53.753506978328488],
              [-2.719238405306092, 53.754846551129198],
              [-2.720924654291403, 53.755981518760535],
              [-2.721490973555222, 53.755573639799579],
              [-2.722729693591952, 53.756344555996193],
              [-2.724291756599419, 53.756776446741434],
              [-2.730647023567281, 53.757572871988046],
              [-2.737490716455021, 53.757577687334347],
              [-2.752469232941748, 53.75684370531954],
              [-2.757039282720486, 53.755670716216088],
              [-2.757202677764639, 53.756406720670213],
              [-2.752486459285333, 53.756956479678337],
              [-2.744562728766213, 53.757542044076196],
              [-2.73286424439608, 53.757818193616032],
              [-2.729404847494556, 53.757663131242829],
            ],
          ],
          [
            [
              [-2.657236647994482, 53.896153031991773],
              [-2.653246691925487, 53.895867426920205],
              [-2.652082573555801, 53.896064302987092],
              [-2.651604192563306, 53.895540205362153],
              [-2.649233116093459, 53.894938277469407],
              [-2.64713365338453, 53.894774350977634],
              [-2.64674580173998, 53.894293787004102],
              [-2.645542730831889, 53.894529457981569],
              [-2.64523238266821, 53.893744678363738],
              [-2.644745465239722, 53.892757718143343],
              [-2.63796504409172, 53.891060172446423],
              [-2.635967411340072, 53.890271743684316],
              [-2.634733861271509, 53.888392595232922],
              [-2.63664841479617, 53.88552517119912],
              [-2.63684888114748, 53.884501273863336],
              [-2.63553115809761, 53.882780767558621],
              [-2.629556006274925, 53.881796622013582],
              [-2.625381962031959, 53.880284198661457],
              [-2.626128608760462, 53.877926348686806],
              [-2.625068896553835, 53.876124387363383],
              [-2.62749319295184, 53.87438244165341],
              [-2.625838175355134, 53.870092122405318],
              [-2.626257523914913, 53.867826752968213],
              [-2.625103182791494, 53.867004078230551],
              [-2.627415081757044, 53.864899599858006],
              [-2.628289226857677, 53.863465031193762],
              [-2.629945392046341, 53.862268129641855],
              [-2.628866216060838, 53.860774589200076],
              [-2.629360499964324, 53.859972961605962],
              [-2.628638888575674, 53.859708902031002],
              [-2.628974649802776, 53.859286502424069],
              [-2.628564959507522, 53.858631624970187],
              [-2.627065920339116, 53.857721792526796],
              [-2.626442615940223, 53.857826613816783],
              [-2.624478816613866, 53.857249035890064],
              [-2.623248138298922, 53.856382698690354],
              [-2.620288466511855, 53.856408824951437],
              [-2.620882092068759, 53.855942870493642],
              [-2.620619384159234, 53.85544988839839],
              [-2.619527419540367, 53.855525639544616],
              [-2.619366202176389, 53.854697775844372],
              [-2.617266785928081, 53.853049407220411],
              [-2.616395908283134, 53.851718264118652],
              [-2.615146154541303, 53.851601543715041],
              [-2.614410343844544, 53.851078616283019],
              [-2.616615522771121, 53.850629583409827],
              [-2.617898982192785, 53.849321511271413],
              [-2.619875236936132, 53.848716306451067],
              [-2.620856462394888, 53.848873016267135],
              [-2.623260879078757, 53.846705214472159],
              [-2.620217119427721, 53.845418624596618],
              [-2.607898923913901, 53.838758633468728],
              [-2.610315140641242, 53.834992952121141],
              [-2.610227172152936, 53.828735014730633],
              [-2.610723669818938, 53.827773460785302],
              [-2.610377885586261, 53.826979777540309],
              [-2.611358980393137, 53.825913289060274],
              [-2.611418175684844, 53.825283822474113],
              [-2.613175515240488, 53.823594978909753],
              [-2.615083523623506, 53.822556971482214],
              [-2.615091893711524, 53.82115838396227],
              [-2.615749442929368, 53.820730771931686],
              [-2.615920043087284, 53.81993445081627],
              [-2.61687984597762, 53.819403714189228],
              [-2.617520331741241, 53.819471423260175],
              [-2.617789427414489, 53.819074561564697],
              [-2.618331475304005, 53.819162547211945],
              [-2.619609496237242, 53.818171754918936],
              [-2.620328366917756, 53.818097032174599],
              [-2.621398406092934, 53.81722144496166],
              [-2.624715122989718, 53.815550402144552],
              [-2.622380355508585, 53.813787396135218],
              [-2.620012204869356, 53.810367112707311],
              [-2.616893730471536, 53.808873198654879],
              [-2.615658128109965, 53.807873781929722],
              [-2.614131542517715, 53.805870973947584],
              [-2.612624678727562, 53.804898081760008],
              [-2.612301612693092, 53.803881380411617],
              [-2.610221822430566, 53.804643389691485],
              [-2.608299140623494, 53.804201059297064],
              [-2.602913539019974, 53.800622239474279],
              [-2.601010184066211, 53.798965402773078],
              [-2.601165601139041, 53.798685992298388],
              [-2.59886399203075, 53.797409517089882],
              [-2.597253504881149, 53.797324075438929],
              [-2.595949143083247, 53.796771506958741],
              [-2.595436833255495, 53.796187129577341],
              [-2.596962357089401, 53.795638458433373],
              [-2.598081603880901, 53.79470620564279],
              [-2.60178340917911, 53.789667817863311],
              [-2.601570387326694, 53.786874480923991],
              [-2.599265072554029, 53.782205912818611],
              [-2.599489613205162, 53.781456078907318],
              [-2.601121099023004, 53.780611112632187],
              [-2.605971739605495, 53.778958935467138],
              [-2.608724214600814, 53.778518949761143],
              [-2.611875149992816, 53.778560429001708],
              [-2.61882339293257, 53.779357956027582],
              [-2.625181942300748, 53.781711309214657],
              [-2.63031293847782, 53.782718971207714],
              [-2.631921033780718, 53.78277881370942],
              [-2.633466551186705, 53.782512695784952],
              [-2.634652158809077, 53.781830515384669],
              [-2.635230445486444, 53.780931333750488],
              [-2.634996990647172, 53.780144310260312],
              [-2.633868718740342, 53.779492350078449],
              [-2.62753272647651, 53.777338875135811],
              [-2.622908414606724, 53.774634196584103],
              [-2.621539079913857, 53.773048605559715],
              [-2.621790962393585, 53.771202031114633],
              [-2.625115493953644, 53.768565586799539],
              [-2.628030740649875, 53.767299192455688],
              [-2.635432802692518, 53.765701623952687],
              [-2.642541565725909, 53.765794946468681],
              [-2.643691256724869, 53.765383409907358],
              [-2.643889435948015, 53.764950912835822],
              [-2.643821857236447, 53.761328141284679],
              [-2.64502261926961, 53.760199958753134],
              [-2.646641805577843, 53.759950348719201],
              [-2.653266749511679, 53.761084677259852],
              [-2.655726318835694, 53.760838449558463],
              [-2.656934505386002, 53.760304225504775],
              [-2.658687961186197, 53.758461907632892],
              [-2.659231272606081, 53.756738579396668],
              [-2.65941805913657, 53.753171933185563],
              [-2.660664967373141, 53.75125686894814],
              [-2.661921972935021, 53.750183930137553],
              [-2.662231927596584, 53.7503179393536],
              [-2.665454845368838, 53.748807141328257],
              [-2.668834836894244, 53.748798208359815],
              [-2.673533479653181, 53.750170453867483],
              [-2.677831146237928, 53.753180639397051],
              [-2.679711136187744, 53.753050446417184],
              [-2.681612552660092, 53.752277444697057],
              [-2.684757444714913, 53.749918981443059],
              [-2.687257812765757, 53.749250323323928],
              [-2.688774610029204, 53.74917869604176],
              [-2.690390205503183, 53.749491176318898],
              [-2.695596779031327, 53.752277972824579],
              [-2.697495305630218, 53.752356818643541],
              [-2.702028600620427, 53.751737121325483],
              [-2.706356188684849, 53.750253797094963],
              [-2.709244432413685, 53.74841392162223],
              [-2.710294951555363, 53.748212653876607],
              [-2.712408801283714, 53.748658504213303],
              [-2.715214670726917, 53.749846212397962],
              [-2.716407736340827, 53.751426407693849],
              [-2.717451674673157, 53.754929168175515],
              [-2.719093338464313, 53.756564173986625],
              [-2.72455296124113, 53.757764472031617],
              [-2.730430190888921, 53.75853773265046],
              [-2.751181110509161, 53.757924983918016],
              [-2.750059037579888, 53.758539617950674],
              [-2.756829929470101, 53.757860678203116],
              [-2.757762003291788, 53.757482674214494],
              [-2.76429318594117, 53.756398558247234],
              [-2.772812190372811, 53.75513769530464],
              [-2.777751775529676, 53.754797439172208],
              [-2.77814950941375, 53.754489255857969],
              [-2.782955374710344, 53.754141563726904],
              [-2.787907358270066, 53.753258814285587],
              [-2.789288194112868, 53.756370456381774],
              [-2.790209080093425, 53.7568677306502],
              [-2.790682904642896, 53.758675742151155],
              [-2.792306060516701, 53.760699067132649],
              [-2.792859647121284, 53.761017185866777],
              [-2.794288044159488, 53.76090975478585],
              [-2.794259504703606, 53.76164788090712],
              [-2.795574373251579, 53.762113739747612],
              [-2.793649948610143, 53.763751578612663],
              [-2.791814189138621, 53.764063031068055],
              [-2.789997429349155, 53.765710884569906],
              [-2.788237223935325, 53.76552653064654],
              [-2.788806889111159, 53.765810406803958],
              [-2.790113034308872, 53.765808094501139],
              [-2.791002298206895, 53.764904413429655],
              [-2.791455581887435, 53.764918386100661],
              [-2.79216959614164, 53.764085849452009],
              [-2.793756557475685, 53.763854237606523],
              [-2.794998892223751, 53.762726064589806],
              [-2.796151705354118, 53.763363769171789],
              [-2.796700958726972, 53.764330851925259],
              [-2.799122572750761, 53.765713303244915],
              [-2.798634849684437, 53.767032436431151],
              [-2.797935451398842, 53.767351684294326],
              [-2.798666997293044, 53.770403719217953],
              [-2.798196659923096, 53.771523194225217],
              [-2.791149378032934, 53.780740628204278],
              [-2.785070117477736, 53.78424825962616],
              [-2.780503573456027, 53.787697201447166],
              [-2.782074634103916, 53.789986148846687],
              [-2.782868527385303, 53.789905465796458],
              [-2.811524060120518, 53.807909767879678],
              [-2.815263256997332, 53.808167530435199],
              [-2.819656192310732, 53.815386573627507],
              [-2.82209850316593, 53.818024071707271],
              [-2.825997991641527, 53.819971983510783],
              [-2.811903334003221, 53.823916084741313],
              [-2.81030051941584, 53.824304429323604],
              [-2.809144445796531, 53.824147751329427],
              [-2.804275430363415, 53.825253693754213],
              [-2.803197320128033, 53.825837061671123],
              [-2.80198417762452, 53.825874841544824],
              [-2.802053289955985, 53.824880286609321],
              [-2.799914702235449, 53.824823563862672],
              [-2.798471860243852, 53.824364895108289],
              [-2.797868144278108, 53.824572944915531],
              [-2.797575730979127, 53.824694432804314],
              [-2.798634121679882, 53.825467562967532],
              [-2.798500311100797, 53.826020328090941],
              [-2.796394797777906, 53.827789719225912],
              [-2.797144775564524, 53.828560415867877],
              [-2.799611660522302, 53.829092272113407],
              [-2.800978460397459, 53.831767019559962],
              [-2.800273420190468, 53.831925423834413],
              [-2.800138223760797, 53.833122651093028],
              [-2.79770010880069, 53.833397751240184],
              [-2.794188380111745, 53.83443312378467],
              [-2.793387433798248, 53.836675577665829],
              [-2.791045852044609, 53.833862470728953],
              [-2.787036710280369, 53.832126268417014],
              [-2.783883092389066, 53.83346907989857],
              [-2.781777910919771, 53.833351593649887],
              [-2.78097570341318, 53.834091152244028],
              [-2.782208557224038, 53.835423251679728],
              [-2.780316573651084, 53.836806268811571],
              [-2.773594629077234, 53.839078872520268],
              [-2.774307774855284, 53.839841858058328],
              [-2.770692042680345, 53.840405332497085],
              [-2.767113424791207, 53.834692042740279],
              [-2.761412016994516, 53.827485726056601],
              [-2.758365356097751, 53.825944687836746],
              [-2.756345313664559, 53.825805545298209],
              [-2.754851486577377, 53.824345394850582],
              [-2.753569049627944, 53.823738670832107],
              [-2.751678833786173, 53.823886254935665],
              [-2.750921711981173, 53.823429007381321],
              [-2.749999384383558, 53.823502190436976],
              [-2.749428407415973, 53.823174097343802],
              [-2.743049794137744, 53.822927967335552],
              [-2.736160297188439, 53.823430651429007],
              [-2.736451164754974, 53.824943367126409],
              [-2.73599255108918, 53.825300315304496],
              [-2.737932357997698, 53.83030017901504],
              [-2.738293168314691, 53.833363809514246],
              [-2.740096862002, 53.839052961253032],
              [-2.740098801903978, 53.83993827907593],
              [-2.739700488181198, 53.840102522338],
              [-2.740351943738419, 53.841537500625513],
              [-2.74172900031998, 53.842576108493041],
              [-2.742016179989975, 53.844634408178536],
              [-2.742597172619911, 53.844742265281113],
              [-2.743500375564628, 53.846478566431337],
              [-2.74666311907295, 53.849620039543197],
              [-2.746482517177737, 53.850917248696021],
              [-2.745840002700612, 53.851030900189116],
              [-2.740367352673394, 53.850160598697705],
              [-2.738148569137361, 53.85053829068049],
              [-2.737789630517658, 53.850873058631102],
              [-2.735261243487692, 53.85093621970168],
              [-2.73425828163425, 53.850694291520462],
              [-2.731391123691264, 53.851553992000355],
              [-2.729016664322408, 53.851205326773673],
              [-2.726028068043831, 53.849712553506585],
              [-2.725870055685458, 53.849365670455413],
              [-2.719187034657168, 53.850383829240386],
              [-2.71775551555883, 53.851385587196816],
              [-2.716737353247455, 53.851494139223263],
              [-2.715031514035225, 53.85308532370464],
              [-2.714008702301598, 53.853370046564194],
              [-2.709818785559449, 53.85362770354952],
              [-2.711925374292866, 53.8563772569047],
              [-2.710941219167164, 53.857602775323905],
              [-2.707777238455843, 53.859758847671976],
              [-2.707300745104403, 53.861165594280429],
              [-2.705726527184436, 53.861406757177107],
              [-2.705814931596914, 53.862412900478226],
              [-2.704477554315749, 53.863178454893422],
              [-2.703263357169265, 53.864944542125144],
              [-2.703932554986131, 53.866400278369355],
              [-2.70608261952589, 53.869006764022537],
              [-2.705110343530514, 53.869158088434311],
              [-2.704906337068822, 53.870049104525215],
              [-2.70419802959804, 53.870525136601628],
              [-2.699922735725798, 53.871513671907074],
              [-2.697280343264071, 53.872480891344452],
              [-2.694364035817719, 53.872895974188204],
              [-2.69288191643629, 53.87404692227129],
              [-2.69082793098907, 53.874189991206201],
              [-2.690610432801091, 53.8774197459432],
              [-2.690034469099204, 53.877821229427681],
              [-2.690580018600562, 53.878066160598259],
              [-2.690753535785544, 53.878995423193246],
              [-2.688336060357844, 53.87999080821681],
              [-2.687241135948235, 53.882236001603488],
              [-2.689193607339713, 53.88320270074577],
              [-2.688173472733053, 53.883798169767871],
              [-2.687775949669922, 53.88451140140694],
              [-2.689374047556293, 53.885477431699208],
              [-2.689344265356622, 53.888104800840104],
              [-2.687562083404459, 53.888628240724572],
              [-2.687149880959105, 53.889933863786425],
              [-2.685990824872926, 53.890791659587407],
              [-2.682941541277209, 53.891254856432887],
              [-2.680929069502118, 53.892507535832607],
              [-2.678644275407796, 53.891799639810884],
              [-2.672209482173857, 53.892089317329976],
              [-2.670329431032001, 53.891655826112967],
              [-2.668180134773118, 53.892368876118788],
              [-2.665672117253786, 53.89425950750163],
              [-2.664263684236194, 53.894109122724288],
              [-2.662374632689103, 53.895127117712924],
              [-2.658056956355381, 53.894608894392043],
              [-2.657236647994482, 53.896153031991773],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000124",
        LAD13CDO: "30UL",
        LAD13NM: "Ribble Valley",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.374070435969392, 54.049076720927999],
            [-2.361997013954495, 54.040657221830905],
            [-2.364177132075265, 54.04008081833382],
            [-2.361845095220084, 54.037936219833725],
            [-2.36265591400122, 54.034433085270372],
            [-2.359255399997814, 54.031737116540313],
            [-2.361931291528178, 54.029232314588214],
            [-2.361308320263177, 54.027986701796124],
            [-2.361726017125365, 54.027216999774915],
            [-2.359511444754559, 54.026380603197936],
            [-2.360348329507528, 54.024305540182901],
            [-2.359275213112034, 54.023415379405037],
            [-2.357917442808237, 54.023320567335475],
            [-2.357792550465433, 54.022463515557995],
            [-2.356539984760389, 54.02145972105189],
            [-2.357524448476144, 54.020213800412371],
            [-2.357321098061523, 54.01910442748401],
            [-2.350148255060562, 54.018609653523519],
            [-2.349389623859412, 54.017687030349094],
            [-2.345648501198315, 54.018182287500473],
            [-2.343538255339189, 54.017314738283162],
            [-2.343219495173424, 54.016042994521811],
            [-2.339557796681991, 54.009420491792959],
            [-2.339378633339529, 54.008780176105176],
            [-2.340757802979294, 54.007449689138255],
            [-2.342810236767963, 54.008776723658904],
            [-2.347976098029854, 54.00997700616773],
            [-2.34921892754786, 54.011523775545619],
            [-2.350286566963081, 54.012187553454552],
            [-2.352308169636681, 54.010526110130861],
            [-2.35356927493766, 54.003773546682844],
            [-2.352355635756587, 53.994660884363725],
            [-2.345420873477354, 53.992194128448254],
            [-2.339921076745583, 53.989414641083172],
            [-2.338319162990341, 53.991231087348901],
            [-2.3359665885101, 53.991711343710534],
            [-2.334022054992274, 53.990965394866855],
            [-2.33366519815517, 53.990398363444754],
            [-2.326932695021554, 53.993851981119619],
            [-2.324600698411367, 53.994072213790723],
            [-2.318826201988108, 53.99371559088793],
            [-2.318357652988947, 53.992080172902277],
            [-2.317357241268472, 53.991096869321893],
            [-2.314845262496778, 53.989686119652013],
            [-2.314248440378298, 53.988609157804362],
            [-2.312793174485402, 53.988160875251914],
            [-2.311548454481148, 53.988128160011478],
            [-2.310784372728938, 53.987512684169751],
            [-2.310262989007552, 53.987743218950378],
            [-2.309461689861213, 53.987452287177668],
            [-2.304473212803817, 53.984529652306577],
            [-2.305316456560383, 53.983896573788314],
            [-2.300076038463235, 53.981462428877983],
            [-2.296730071452469, 53.980541414065335],
            [-2.296762027888035, 53.979246204926767],
            [-2.298518069073212, 53.977686077597902],
            [-2.298852972490952, 53.976547398814098],
            [-2.295244427509714, 53.975087732178068],
            [-2.295403785239019, 53.974554368103696],
            [-2.294317322471605, 53.97441952405503],
            [-2.294032769126655, 53.974713221088678],
            [-2.292090742073322, 53.974975908960573],
            [-2.287060556135116, 53.974460461527308],
            [-2.28614930712313, 53.974749345161221],
            [-2.284502341865545, 53.973796965346118],
            [-2.248208541996183, 53.978669736027996],
            [-2.245865433742171, 53.979258758580038],
            [-2.245228384923927, 53.979828983401681],
            [-2.245217179354942, 53.980516565784498],
            [-2.244407508961134, 53.981037706200034],
            [-2.243249008497037, 53.981101175504975],
            [-2.242420166741661, 53.98069121518094],
            [-2.242295472328939, 53.981012327950232],
            [-2.241988083223513, 53.980857460665852],
            [-2.241754445749744, 53.981061952259374],
            [-2.241257691060592, 53.980857133153435],
            [-2.239145814755829, 53.980857763580204],
            [-2.238102954452434, 53.981145645439007],
            [-2.236904748390433, 53.980932311073815],
            [-2.235535036608904, 53.981128241856894],
            [-2.235136011499374, 53.981484038310782],
            [-2.234645919444038, 53.98137444850645],
            [-2.234574952048019, 53.981770046065783],
            [-2.233685733920957, 53.98172953810559],
            [-2.233687100547086, 53.981157018855633],
            [-2.232681052613083, 53.980945065728783],
            [-2.232424917489729, 53.981225079437735],
            [-2.231527575013573, 53.981091997921261],
            [-2.230342077048385, 53.981516700437531],
            [-2.22819084797325, 53.979904820165643],
            [-2.227101210600175, 53.978332240828308],
            [-2.224056423352422, 53.978634556863348],
            [-2.221059536625812, 53.97791390682044],
            [-2.222690880245973, 53.977038185245341],
            [-2.223474361602873, 53.976146047417579],
            [-2.224236771000984, 53.976161702230762],
            [-2.225275762963954, 53.975445233989298],
            [-2.227693575247359, 53.974842090556727],
            [-2.232012430323929, 53.971216265741788],
            [-2.230935232355288, 53.970774349825845],
            [-2.229727853269755, 53.969664886020006],
            [-2.229166209480576, 53.968434642105393],
            [-2.229735125447623, 53.967663306856224],
            [-2.229300197103259, 53.967296540717058],
            [-2.22867057008758, 53.967003843406189],
            [-2.227515767025139, 53.967398801411207],
            [-2.225944845826019, 53.967239989942868],
            [-2.225298110933439, 53.964903498912051],
            [-2.225986136610253, 53.96276132894112],
            [-2.225733206824163, 53.961085593397272],
            [-2.220608435737521, 53.963539785081487],
            [-2.214417494982978, 53.965085209980423],
            [-2.212971816559477, 53.963754904784565],
            [-2.213108649203927, 53.963388861493257],
            [-2.209635551645103, 53.962885374666136],
            [-2.207035999700111, 53.965171870404831],
            [-2.206653333201788, 53.96638677094807],
            [-2.203169469618606, 53.967776828042176],
            [-2.201663685992357, 53.967832395344757],
            [-2.201480850271414, 53.96879708430783],
            [-2.200399639719331, 53.969660816417814],
            [-2.200631998466367, 53.970112510144972],
            [-2.196014963657814, 53.969562003211543],
            [-2.195585170408942, 53.96632982338194],
            [-2.19361266018863, 53.961386180578131],
            [-2.191282312840832, 53.958065357991224],
            [-2.188151285129622, 53.955194234822919],
            [-2.187764179096675, 53.954169340844722],
            [-2.186165931522408, 53.953546285119415],
            [-2.184519041771085, 53.952250101475016],
            [-2.1872021095636, 53.95082318595491],
            [-2.19011846559339, 53.949807479150543],
            [-2.202405594264093, 53.94668570882304],
            [-2.204068167964053, 53.945822764982452],
            [-2.206948283167692, 53.945025112191551],
            [-2.209720503374029, 53.94339351525597],
            [-2.214924313817252, 53.942148490068725],
            [-2.215614169197463, 53.941510020332032],
            [-2.217399642351623, 53.942400181610097],
            [-2.218133557168779, 53.943495355884409],
            [-2.219181842917221, 53.943523105141153],
            [-2.219976886568272, 53.943195394647994],
            [-2.220679124134731, 53.943470029281961],
            [-2.222006988909046, 53.943358827917919],
            [-2.222766245592475, 53.943181260686167],
            [-2.222817019512258, 53.942703916494757],
            [-2.224294158185527, 53.942594210068144],
            [-2.224180984095679, 53.943079760489546],
            [-2.225503195157537, 53.943618345872835],
            [-2.225820847313744, 53.942915804624384],
            [-2.22629262146747, 53.942825038244763],
            [-2.225219135294711, 53.9417943644684],
            [-2.228712656587265, 53.941027391766134],
            [-2.229914209246677, 53.939296748673186],
            [-2.234036487485671, 53.938518527572846],
            [-2.235297645358668, 53.937686490199582],
            [-2.238131236009821, 53.936678763891152],
            [-2.239490799424885, 53.936562812100966],
            [-2.240472906667373, 53.935706110849182],
            [-2.242363194918641, 53.93518551069662],
            [-2.242800358049394, 53.934664234813468],
            [-2.245838306667475, 53.933248765864015],
            [-2.244611379545659, 53.930763456404129],
            [-2.244427981345803, 53.926488341717217],
            [-2.243735423454859, 53.923934522474724],
            [-2.240784738815643, 53.919246156425437],
            [-2.237576123509472, 53.915399484219165],
            [-2.234953720517678, 53.907948364687144],
            [-2.229563718614251, 53.902123012879201],
            [-2.226939463985619, 53.898682076091269],
            [-2.227348366106384, 53.897755555624073],
            [-2.226580412503683, 53.897857672607287],
            [-2.224730755708942, 53.897449510443927],
            [-2.222915606017752, 53.896370763763862],
            [-2.223110079805966, 53.894029971384747],
            [-2.222790543068266, 53.893174923874113],
            [-2.224016764762985, 53.890861867352207],
            [-2.226627956962083, 53.889498000040597],
            [-2.228853582331437, 53.88888260023802],
            [-2.231937353723215, 53.888562101139314],
            [-2.234081468120136, 53.887269078488103],
            [-2.235119441851726, 53.887063922629387],
            [-2.237048356881173, 53.887292011616417],
            [-2.238379981876413, 53.886825599242727],
            [-2.241944515779243, 53.886807675342226],
            [-2.24642212278676, 53.88579641335658],
            [-2.248604193087215, 53.885357796693334],
            [-2.248902139912953, 53.8855737858417],
            [-2.252595289198974, 53.883945554502908],
            [-2.253498510341247, 53.88312126013755],
            [-2.255146784155406, 53.882993732287495],
            [-2.255702054455299, 53.882497318854071],
            [-2.259194918035066, 53.882020654513703],
            [-2.259303890911561, 53.881439803391864],
            [-2.258548163893861, 53.880258632950948],
            [-2.258930966505095, 53.877713343628585],
            [-2.263511919749778, 53.876666165443794],
            [-2.263539167174297, 53.876403659893526],
            [-2.265687612833695, 53.875804821204753],
            [-2.269326711914964, 53.875703229656821],
            [-2.270463140716598, 53.875976599573775],
            [-2.272866683289458, 53.878598308140546],
            [-2.273695031885278, 53.878964024219677],
            [-2.277671099885096, 53.877798145984237],
            [-2.285840427975052, 53.873958209179285],
            [-2.29594706153623, 53.872670897933013],
            [-2.297261443939858, 53.872075345951778],
            [-2.305092724334048, 53.870660759803407],
            [-2.3079118159955, 53.868937767556254],
            [-2.309336267972415, 53.867635351884161],
            [-2.312377344311046, 53.865143215704073],
            [-2.314606017455998, 53.864260171120939],
            [-2.317584371472893, 53.862192294359524],
            [-2.318980482334555, 53.861630440288543],
            [-2.320141022514888, 53.85989268243307],
            [-2.319464311662766, 53.858281158099118],
            [-2.317486849999923, 53.857147642092919],
            [-2.317778976126706, 53.855573085429739],
            [-2.316267565537243, 53.854732217727587],
            [-2.314265190436339, 53.854297074724251],
            [-2.31699583336529, 53.851927863300205],
            [-2.322133449809319, 53.851870130281164],
            [-2.325172351480186, 53.851106043860902],
            [-2.32082875370131, 53.850822041925213],
            [-2.318533498149859, 53.850033629336316],
            [-2.317927610492815, 53.846908339697684],
            [-2.326460173726733, 53.845092319957608],
            [-2.327068599607712, 53.844178387009066],
            [-2.325215657505339, 53.842188106634481],
            [-2.329293540171518, 53.842033169226177],
            [-2.331253890276042, 53.84220483146953],
            [-2.331742656593907, 53.842512665032757],
            [-2.33337572335113, 53.841275889556428],
            [-2.333644038158398, 53.840617226253158],
            [-2.332577475514255, 53.840844887925918],
            [-2.331634512197486, 53.841521595050892],
            [-2.330431964853747, 53.84069712642119],
            [-2.32850217488753, 53.84073119079595],
            [-2.32703123825719, 53.839387920416634],
            [-2.322941499044468, 53.837970823105508],
            [-2.319091438358873, 53.839458748729633],
            [-2.318817397853491, 53.839193434908935],
            [-2.313800983102092, 53.838994554689535],
            [-2.309085990991993, 53.838448652623697],
            [-2.308560935649644, 53.836277621222195],
            [-2.302573061001115, 53.836380965468038],
            [-2.301910484554467, 53.834052962746],
            [-2.302420834621046, 53.833182542625494],
            [-2.302168128262354, 53.832272701502497],
            [-2.305875952582687, 53.831289903741244],
            [-2.307232788540625, 53.830463138219628],
            [-2.30723231337516, 53.830190804691306],
            [-2.305822467104045, 53.829580533084425],
            [-2.30802821471025, 53.828916967723586],
            [-2.314877430519014, 53.82853875551811],
            [-2.317135894729872, 53.827840729654554],
            [-2.314568054976909, 53.823142301353379],
            [-2.316414044555825, 53.822777924356402],
            [-2.315442334313232, 53.820823806477812],
            [-2.316190107858149, 53.820678028866624],
            [-2.315998492975844, 53.820245314380855],
            [-2.317170127014683, 53.819305674029636],
            [-2.317464520111628, 53.818264088275939],
            [-2.316913150677566, 53.817257996282095],
            [-2.317844118655314, 53.817043415120295],
            [-2.316037566817454, 53.813532996934484],
            [-2.314704522083226, 53.813790864169661],
            [-2.313204626214041, 53.812451091157158],
            [-2.315044038422278, 53.811676000755504],
            [-2.315600992423272, 53.812632653572877],
            [-2.317479147391669, 53.812165711346985],
            [-2.317625650966786, 53.811857934417311],
            [-2.318907835863881, 53.811712519585051],
            [-2.319687719744211, 53.810621099048937],
            [-2.320922830815585, 53.810680714059679],
            [-2.321784926405004, 53.810220015636439],
            [-2.323108180122749, 53.810091522676593],
            [-2.325647493198528, 53.807764851829177],
            [-2.325927704473821, 53.806498581815084],
            [-2.327031254133905, 53.806447939302828],
            [-2.328832193582173, 53.807030825870946],
            [-2.329061731584116, 53.806674271602944],
            [-2.329761370425754, 53.806624712283799],
            [-2.332136139246215, 53.804108708004854],
            [-2.332043089316659, 53.803286564570882],
            [-2.332989809332644, 53.802809371817546],
            [-2.334690038672566, 53.801052875375959],
            [-2.33505889885315, 53.799709936603477],
            [-2.33466323208611, 53.799022561430377],
            [-2.335475390653284, 53.797870725360276],
            [-2.335242840760417, 53.797266483586114],
            [-2.33611467153917, 53.796558483245214],
            [-2.33622104014098, 53.795432886664202],
            [-2.338585568083976, 53.794974132535934],
            [-2.34063037578943, 53.795156187056598],
            [-2.342305065392087, 53.794813466048943],
            [-2.343665590535003, 53.793369692941674],
            [-2.344631734916123, 53.793268050206919],
            [-2.347966590527216, 53.794340569793853],
            [-2.354713003298328, 53.797597819328395],
            [-2.356031168259368, 53.79782310479056],
            [-2.3593005725309, 53.797202162939669],
            [-2.358586353080116, 53.796232698192242],
            [-2.358807354207125, 53.795268521878015],
            [-2.357345997256699, 53.794799220044801],
            [-2.356433946192586, 53.79322543859395],
            [-2.356643777164312, 53.792908436184391],
            [-2.35920551241694, 53.793364566586568],
            [-2.359606878148967, 53.794322384034828],
            [-2.360786319323994, 53.794665776420466],
            [-2.362220250689199, 53.794591344440711],
            [-2.363510191700084, 53.79349090076613],
            [-2.36517958089382, 53.793091251458719],
            [-2.369745644872618, 53.794113569230433],
            [-2.371432075735964, 53.795473634060649],
            [-2.369614515506982, 53.797153720414755],
            [-2.369034615825785, 53.798713129835392],
            [-2.369805801773835, 53.801464678519842],
            [-2.37104730140127, 53.801582179529319],
            [-2.374710127236273, 53.800633334749904],
            [-2.377125985422335, 53.800832481637556],
            [-2.378697271720888, 53.800479691193999],
            [-2.379167661718525, 53.800953669739393],
            [-2.378784042657531, 53.802357012114015],
            [-2.379168480470882, 53.802558026720348],
            [-2.381331939427938, 53.802871136121588],
            [-2.383974640610509, 53.802454643487977],
            [-2.386202464395499, 53.802498717377347],
            [-2.387985370494007, 53.802537899328733],
            [-2.389732007611777, 53.802431566940541],
            [-2.393175826501201, 53.803264293821549],
            [-2.397130253677727, 53.803003187788512],
            [-2.398877172151034, 53.803566328252835],
            [-2.397827363306062, 53.806067585268558],
            [-2.39898760792313, 53.807838860288484],
            [-2.399386217700299, 53.80983376865867],
            [-2.39870620822869, 53.810508334991908],
            [-2.395192942340573, 53.812214207052577],
            [-2.39340382890355, 53.81431249751504],
            [-2.393912945872508, 53.815156595856259],
            [-2.396736431871722, 53.816605137137735],
            [-2.398709119953898, 53.815772595729818],
            [-2.399968576626021, 53.815808846182009],
            [-2.401305212633345, 53.813908810681845],
            [-2.405944526142834, 53.813541759248487],
            [-2.407447492884465, 53.813167253406569],
            [-2.421137844918806, 53.80412653460349],
            [-2.436965366232021, 53.796789577216906],
            [-2.440055044175856, 53.795218848867542],
            [-2.444361210116083, 53.790993860482743],
            [-2.447602156422366, 53.789012518902162],
            [-2.447479430219189, 53.787465234558972],
            [-2.449661238078461, 53.786822511753442],
            [-2.455042555201214, 53.783387636585118],
            [-2.459808507197451, 53.781350726078493],
            [-2.462003766193865, 53.780873106013672],
            [-2.463912377327528, 53.780965500435265],
            [-2.464909148320683, 53.780665930233269],
            [-2.4654015352817, 53.781002869315834],
            [-2.465809023362407, 53.780799054573492],
            [-2.467048727943796, 53.781468334868478],
            [-2.469717076393857, 53.781776982634753],
            [-2.473708306630196, 53.779884563121819],
            [-2.477088764387924, 53.781033311346434],
            [-2.477004793360105, 53.780453914769467],
            [-2.477954886605456, 53.779928820967228],
            [-2.478886520172351, 53.777652016723074],
            [-2.480184152836343, 53.775942683889625],
            [-2.480827813048269, 53.773977105243922],
            [-2.484343328998209, 53.772780115411322],
            [-2.484975273742505, 53.772977095136248],
            [-2.486695113472923, 53.772779574955671],
            [-2.491214465638895, 53.771036319529728],
            [-2.494763365485678, 53.770143582228975],
            [-2.498959870200917, 53.768605395395582],
            [-2.50199138530386, 53.768883049794539],
            [-2.504692630449447, 53.76970220405471],
            [-2.507664872073689, 53.769584488231324],
            [-2.509414080819754, 53.769201362175615],
            [-2.511256034750998, 53.766715496323734],
            [-2.514837463067886, 53.766189637630148],
            [-2.515512884167883, 53.76559801240073],
            [-2.515224816996356, 53.765240176226733],
            [-2.515598974176576, 53.764700628217078],
            [-2.518697423771673, 53.763899896831774],
            [-2.519811346128888, 53.762341922782035],
            [-2.52077268559777, 53.76218854568706],
            [-2.523251736907329, 53.760393603212876],
            [-2.524206225552402, 53.760425383709645],
            [-2.525746761429525, 53.759651048141905],
            [-2.528781029830475, 53.75946062985836],
            [-2.529069652477258, 53.759856631044457],
            [-2.531502193747648, 53.75958161446362],
            [-2.532347681486298, 53.758671857523112],
            [-2.536341900054743, 53.759071094357722],
            [-2.536569425547866, 53.759547345490965],
            [-2.538457705595693, 53.75989120951251],
            [-2.539079675873441, 53.759660114314933],
            [-2.539462361044949, 53.759931631220063],
            [-2.540565502952401, 53.759609378483738],
            [-2.542435683023987, 53.759605420792937],
            [-2.543852125696597, 53.759233183065064],
            [-2.545726183206967, 53.75941251437677],
            [-2.546433272565217, 53.758731586365222],
            [-2.546574140348486, 53.758950254410088],
            [-2.546788096823354, 53.758723675998034],
            [-2.547698966005896, 53.758785129303931],
            [-2.549256430215999, 53.758067038278611],
            [-2.549957254547993, 53.757493076752347],
            [-2.549807170326689, 53.757150418549926],
            [-2.551300063386402, 53.756373275109404],
            [-2.552285330444104, 53.757484166191631],
            [-2.552929198311531, 53.757658263076308],
            [-2.554366628031263, 53.757739705000567],
            [-2.557382600181545, 53.757332931326886],
            [-2.560413143743073, 53.756202467656536],
            [-2.561376081436901, 53.756304020106384],
            [-2.561377964616433, 53.756899925436599],
            [-2.56217493121583, 53.756724515241295],
            [-2.563636601035711, 53.757257836075283],
            [-2.564457968875083, 53.757884038651376],
            [-2.563920860261707, 53.760926358005911],
            [-2.564274996772937, 53.761996976093613],
            [-2.559229769977674, 53.763593552269732],
            [-2.552055787270732, 53.766692669125554],
            [-2.548215616768913, 53.76770438559457],
            [-2.545545053749559, 53.769189726436451],
            [-2.54604246566968, 53.769289925531112],
            [-2.547588924602813, 53.771306993633161],
            [-2.547507048411966, 53.7758562529296],
            [-2.555613528630033, 53.777389168015382],
            [-2.562288463864264, 53.776795380846799],
            [-2.566840056296954, 53.777192785914941],
            [-2.574709442837649, 53.775075456021682],
            [-2.577440411262433, 53.774942786655892],
            [-2.580864111820072, 53.775529345056839],
            [-2.582619059862057, 53.779970847373797],
            [-2.584144742265553, 53.781102216181161],
            [-2.585297643599325, 53.782703667588365],
            [-2.585655218851258, 53.782225550541099],
            [-2.587093133402305, 53.782168182304154],
            [-2.588028078912561, 53.781740258444948],
            [-2.58810910172899, 53.780805994938454],
            [-2.58914655904028, 53.78109031735854],
            [-2.589140740461258, 53.780675094632009],
            [-2.59179365380237, 53.779502556590103],
            [-2.592299881993847, 53.779565669257408],
            [-2.592949033242848, 53.779002499069421],
            [-2.594546186042831, 53.780448865926736],
            [-2.596032213667751, 53.780579003501721],
            [-2.59661253716145, 53.780942831777132],
            [-2.598968081197806, 53.78106680396214],
            [-2.599489613205162, 53.781456078907318],
            [-2.599265072554029, 53.782205912818611],
            [-2.601570387326694, 53.786874480923991],
            [-2.60178340917911, 53.789667817863311],
            [-2.598081603880901, 53.79470620564279],
            [-2.596962357089401, 53.795638458433373],
            [-2.595436833255495, 53.796187129577341],
            [-2.595949143083247, 53.796771506958741],
            [-2.597253504881149, 53.797324075438929],
            [-2.59886399203075, 53.797409517089882],
            [-2.601165601139041, 53.798685992298388],
            [-2.601010184066211, 53.798965402773078],
            [-2.602913539019974, 53.800622239474279],
            [-2.608299140623494, 53.804201059297064],
            [-2.610221822430566, 53.804643389691485],
            [-2.612301612693092, 53.803881380411617],
            [-2.612624678727562, 53.804898081760008],
            [-2.614131542517715, 53.805870973947584],
            [-2.615658128109965, 53.807873781929722],
            [-2.616893730471536, 53.808873198654879],
            [-2.620012204869356, 53.810367112707311],
            [-2.622380355508585, 53.813787396135218],
            [-2.624715122989718, 53.815550402144552],
            [-2.621398406092934, 53.81722144496166],
            [-2.620328366917756, 53.818097032174599],
            [-2.619609496237242, 53.818171754918936],
            [-2.618331475304005, 53.819162547211945],
            [-2.617789427414489, 53.819074561564697],
            [-2.617520331741241, 53.819471423260175],
            [-2.61687984597762, 53.819403714189228],
            [-2.615920043087284, 53.81993445081627],
            [-2.615749442929368, 53.820730771931686],
            [-2.615091893711524, 53.82115838396227],
            [-2.615083523623506, 53.822556971482214],
            [-2.613175515240488, 53.823594978909753],
            [-2.611418175684844, 53.825283822474113],
            [-2.611358980393137, 53.825913289060274],
            [-2.610377885586261, 53.826979777540309],
            [-2.610723669818938, 53.827773460785302],
            [-2.610227172152936, 53.828735014730633],
            [-2.610315140641242, 53.834992952121141],
            [-2.607898923913901, 53.838758633468728],
            [-2.620217119427721, 53.845418624596618],
            [-2.623260879078757, 53.846705214472159],
            [-2.620856462394888, 53.848873016267135],
            [-2.619875236936132, 53.848716306451067],
            [-2.617898982192785, 53.849321511271413],
            [-2.616615522771121, 53.850629583409827],
            [-2.614410343844544, 53.851078616283019],
            [-2.615146154541303, 53.851601543715041],
            [-2.616395908283134, 53.851718264118652],
            [-2.617266785928081, 53.853049407220411],
            [-2.619366202176389, 53.854697775844372],
            [-2.619527419540367, 53.855525639544616],
            [-2.620619384159234, 53.85544988839839],
            [-2.620882092068759, 53.855942870493642],
            [-2.620288466511855, 53.856408824951437],
            [-2.623248138298922, 53.856382698690354],
            [-2.624478816613866, 53.857249035890064],
            [-2.626442615940223, 53.857826613816783],
            [-2.627065920339116, 53.857721792526796],
            [-2.628564959507522, 53.858631624970187],
            [-2.628974649802776, 53.859286502424069],
            [-2.628638888575674, 53.859708902031002],
            [-2.629360499964324, 53.859972961605962],
            [-2.628866216060838, 53.860774589200076],
            [-2.629945392046341, 53.862268129641855],
            [-2.628289226857677, 53.863465031193762],
            [-2.627415081757044, 53.864899599858006],
            [-2.625103182791494, 53.867004078230551],
            [-2.626257523914913, 53.867826752968213],
            [-2.625838175355134, 53.870092122405318],
            [-2.62749319295184, 53.87438244165341],
            [-2.625068896553835, 53.876124387363383],
            [-2.626128608760462, 53.877926348686806],
            [-2.625381962031959, 53.880284198661457],
            [-2.629556006274925, 53.881796622013582],
            [-2.63553115809761, 53.882780767558621],
            [-2.63684888114748, 53.884501273863336],
            [-2.63664841479617, 53.88552517119912],
            [-2.634733861271509, 53.888392595232922],
            [-2.635967411340072, 53.890271743684316],
            [-2.63796504409172, 53.891060172446423],
            [-2.644745465239722, 53.892757718143343],
            [-2.64523238266821, 53.893744678363738],
            [-2.643909481860592, 53.894598451643084],
            [-2.642306335866526, 53.895042959577019],
            [-2.641076234138087, 53.894708892362054],
            [-2.637913584877849, 53.894573848125049],
            [-2.635965913275863, 53.893579329071798],
            [-2.633331281369549, 53.89303510841129],
            [-2.629922733296597, 53.893233706618538],
            [-2.628755520646362, 53.89383483164319],
            [-2.625145765298689, 53.89462755419779],
            [-2.616628747601109, 53.899791162606881],
            [-2.613402919267046, 53.908242886493078],
            [-2.614986155578112, 53.912499564222983],
            [-2.618642694412004, 53.916401313692532],
            [-2.618452929428316, 53.916953252016462],
            [-2.614271938810112, 53.919534482892828],
            [-2.617103438207636, 53.924347376387033],
            [-2.61937507299608, 53.927201926478467],
            [-2.622575350338974, 53.929702889758282],
            [-2.62964819441599, 53.930722085056402],
            [-2.627244261721723, 53.934742380476422],
            [-2.626437700932365, 53.938551173970325],
            [-2.63118949081381, 53.940608787016501],
            [-2.633957820168531, 53.942540084705605],
            [-2.641144214310827, 53.945041012034302],
            [-2.648218602369951, 53.948909175573675],
            [-2.649699703809125, 53.949894334881051],
            [-2.650833366508193, 53.952243026509343],
            [-2.646162722382147, 53.955285485040207],
            [-2.644643685232824, 53.956885404144813],
            [-2.643043478655427, 53.957576163733378],
            [-2.637314778708732, 53.958475829174141],
            [-2.630742009144599, 53.957794194546238],
            [-2.623652512986556, 53.954947907316864],
            [-2.620047669001316, 53.952736680186788],
            [-2.616902200555249, 53.952200125080154],
            [-2.615078590919947, 53.953364421950241],
            [-2.613737626220306, 53.95575127258784],
            [-2.611458664264464, 53.956849531611937],
            [-2.606576650838885, 53.956693633029666],
            [-2.602918550185112, 53.95707516383986],
            [-2.593811761435519, 53.959361212389659],
            [-2.588846059258145, 53.960203557541085],
            [-2.581642612623408, 53.964756852650673],
            [-2.579305460732422, 53.967469894033698],
            [-2.578350002834164, 53.971234111120765],
            [-2.577423018962083, 53.971998941821461],
            [-2.574108536253426, 53.972983718210806],
            [-2.566733035767342, 53.973454654333572],
            [-2.557525632309743, 53.974899845071263],
            [-2.561054718965796, 53.976116541466318],
            [-2.565802067404218, 53.979006326604498],
            [-2.569293902919455, 53.981808407661305],
            [-2.572189038713625, 53.983543680939022],
            [-2.57382467641151, 53.985161774334642],
            [-2.573967804773976, 53.98614345832177],
            [-2.572241388531193, 53.986805108183688],
            [-2.57091611108084, 53.987808161572111],
            [-2.571314530615755, 53.989394412699269],
            [-2.574325116044858, 53.99171598814241],
            [-2.57722853479415, 53.992992721715396],
            [-2.577418898900004, 53.994403790446356],
            [-2.580652679275254, 54.000106241559244],
            [-2.580491666384166, 54.00147136636209],
            [-2.578946304347612, 54.004772846619801],
            [-2.579587751171377, 54.00646574912092],
            [-2.579259781617487, 54.007569232683437],
            [-2.574774495967012, 54.012926813108614],
            [-2.5721850754998, 54.014610895554902],
            [-2.571424130558853, 54.014652264972298],
            [-2.568110160591489, 54.015037374762734],
            [-2.566950364702385, 54.014815461713845],
            [-2.564306995933894, 54.015252126201347],
            [-2.556889715053043, 54.015447608987763],
            [-2.549194444887493, 54.0136612057739],
            [-2.547104496402913, 54.014065301725786],
            [-2.545857459704715, 54.014861895266229],
            [-2.544143988367949, 54.019679011361212],
            [-2.538725886052017, 54.022639717289302],
            [-2.531983947169866, 54.023837257322036],
            [-2.527308346726729, 54.026233320561943],
            [-2.521849928143152, 54.022482299608065],
            [-2.518677867056293, 54.021233255724781],
            [-2.512879464389286, 54.02235464501512],
            [-2.504206016678013, 54.020530889094736],
            [-2.502679510919441, 54.020386290368023],
            [-2.501416967016082, 54.020741187527939],
            [-2.497753840292459, 54.022017393699635],
            [-2.498934382195055, 54.023986201746844],
            [-2.500491142172872, 54.024860525023847],
            [-2.499185272102742, 54.025052002137144],
            [-2.497759421637882, 54.025806593131676],
            [-2.497888140012351, 54.027380707699947],
            [-2.495592197704771, 54.028918108573045],
            [-2.492888371624061, 54.032124357898603],
            [-2.492832732016316, 54.034131540298823],
            [-2.493987980440637, 54.036532805213163],
            [-2.492255472616776, 54.040062191230561],
            [-2.491072247876486, 54.040726727776843],
            [-2.486655938342506, 54.042543126053651],
            [-2.46954793279007, 54.04619995408104],
            [-2.464674558347352, 54.043190045682522],
            [-2.458772974136449, 54.04048315832943],
            [-2.456627033923994, 54.040160583556109],
            [-2.450753312054898, 54.040644721599321],
            [-2.445322477742937, 54.042145235610484],
            [-2.442385588456938, 54.042258540443498],
            [-2.440356130489823, 54.04182829343587],
            [-2.432731986483436, 54.041580962051228],
            [-2.425824463492826, 54.038082467538331],
            [-2.422520900678426, 54.040559443983433],
            [-2.418940470545305, 54.0413601957301],
            [-2.414433113573649, 54.042022035293314],
            [-2.407706758652332, 54.042373090359995],
            [-2.40621864635518, 54.042767294706273],
            [-2.405541591009928, 54.042553877393701],
            [-2.403826343677718, 54.043464709653954],
            [-2.401781906145507, 54.043662099688213],
            [-2.400460719901991, 54.043480464629148],
            [-2.397056035602852, 54.044204481400421],
            [-2.39473845253765, 54.043304362701164],
            [-2.391326950882406, 54.044124407409967],
            [-2.389657281116845, 54.044234088932072],
            [-2.387161801436782, 54.045206528098433],
            [-2.384419708030265, 54.047600650508883],
            [-2.374070435969392, 54.049076720927999],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000125",
        LAD13CDO: "30UM",
        LAD13NM: "Rossendale",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.242420679309586, 53.749042122750325],
            [-2.240341549578457, 53.747693612001825],
            [-2.23502096817797, 53.742056976945975],
            [-2.233403899964256, 53.739719648577008],
            [-2.232653963011584, 53.737620597062801],
            [-2.22552294043059, 53.73851866869655],
            [-2.220203086156445, 53.738503405746727],
            [-2.2178432017892, 53.738323468065538],
            [-2.21426445523925, 53.737250460506637],
            [-2.20468044119799, 53.733839183359578],
            [-2.203296953399253, 53.732373789341665],
            [-2.196635803489358, 53.732881958448615],
            [-2.192171751440735, 53.731903218160902],
            [-2.191997051977898, 53.732151569270059],
            [-2.189001359978956, 53.731712326946415],
            [-2.185441337580852, 53.730810101108752],
            [-2.179502383016413, 53.727917807013206],
            [-2.173293701632529, 53.722996999262485],
            [-2.169734506802636, 53.719403272862372],
            [-2.17134144113459, 53.718822149647337],
            [-2.171130765784224, 53.718065654229939],
            [-2.171998323409171, 53.717150321591909],
            [-2.169275703661421, 53.715721503135597],
            [-2.16683194236641, 53.713498585399257],
            [-2.165912324614094, 53.710825006968214],
            [-2.16575677289552, 53.710181674758076],
            [-2.164055956871278, 53.709155780776243],
            [-2.162532055777033, 53.707237103731302],
            [-2.162046781248334, 53.704350782790989],
            [-2.162582854465896, 53.699206159371215],
            [-2.161106202413229, 53.697649618147288],
            [-2.150446294043097, 53.694572488735936],
            [-2.145254404973328, 53.692279746348881],
            [-2.147271640756871, 53.688840219542456],
            [-2.148176627584853, 53.686104912114843],
            [-2.14632797035525, 53.682216224803035],
            [-2.148137950549277, 53.681994685429515],
            [-2.147951758055837, 53.678553356505525],
            [-2.148602002866408, 53.674483615748336],
            [-2.153452761765762, 53.668867980640144],
            [-2.155029470128714, 53.664232548368467],
            [-2.15891766729745, 53.660310398692964],
            [-2.163853793342739, 53.654850607982112],
            [-2.163910795058564, 53.65238686861295],
            [-2.160599971698388, 53.64828016479661],
            [-2.161354108970397, 53.641358237606845],
            [-2.173977026423834, 53.639691197049146],
            [-2.174103904445976, 53.639282947745322],
            [-2.175685205807099, 53.6394666901679],
            [-2.185397376326131, 53.638208054909875],
            [-2.181835667450252, 53.640333843584067],
            [-2.180469209736599, 53.640533654667053],
            [-2.180925229049716, 53.641414708368615],
            [-2.181849730247055, 53.641842941886175],
            [-2.182707647693597, 53.64295886756944],
            [-2.18267459381447, 53.644408715001987],
            [-2.183528285900453, 53.645231625537306],
            [-2.182696367869074, 53.645595124084778],
            [-2.181075136598983, 53.645684775993089],
            [-2.181584443476178, 53.646981000444462],
            [-2.182531760670254, 53.647408294910775],
            [-2.182408289961821, 53.648241689054146],
            [-2.184475608073914, 53.648433561088822],
            [-2.18534269599858, 53.648146395528556],
            [-2.18958546457056, 53.648009414664315],
            [-2.19034554474145, 53.649162291381401],
            [-2.195418713606285, 53.650610195411268],
            [-2.196564248959676, 53.650671235204101],
            [-2.197632526327944, 53.652002423329506],
            [-2.200390200030481, 53.652874182595227],
            [-2.205229694999464, 53.654332847809052],
            [-2.204457341893349, 53.656059003975912],
            [-2.203604723609162, 53.655902266518261],
            [-2.206878494246707, 53.657031856060073],
            [-2.207899699943827, 53.659459591241003],
            [-2.207162899254415, 53.660718316247838],
            [-2.214842683833897, 53.665190767116556],
            [-2.215563059165033, 53.666078401422467],
            [-2.215338056382785, 53.667065708031579],
            [-2.215997983522497, 53.667076202787435],
            [-2.217730062743416, 53.668965072416171],
            [-2.221629006241184, 53.668977686032044],
            [-2.223639010546361, 53.669838607014327],
            [-2.224813716518044, 53.669871458964657],
            [-2.225824956656131, 53.670752192391816],
            [-2.23308115280777, 53.668830984753953],
            [-2.236576184494902, 53.666991435328796],
            [-2.245878844023864, 53.66350593585905],
            [-2.247505240863578, 53.661107230692892],
            [-2.252725682541294, 53.657519015958087],
            [-2.25351560301294, 53.656522351414345],
            [-2.257296837259958, 53.655282895191348],
            [-2.259588017040786, 53.655105371738664],
            [-2.260664490050521, 53.65423836614324],
            [-2.261442879737172, 53.65410723737309],
            [-2.262908931442401, 53.651669120247469],
            [-2.265624022987981, 53.650611502006392],
            [-2.266646434628207, 53.649606143372942],
            [-2.266374112300348, 53.649124982139213],
            [-2.266974689556417, 53.648404586475309],
            [-2.269867702378532, 53.64612048160695],
            [-2.269064592312467, 53.645431097146123],
            [-2.269711073844547, 53.644571266590191],
            [-2.269590968225117, 53.644002583233636],
            [-2.267210686562235, 53.642666882336293],
            [-2.26766892219832, 53.640983262971517],
            [-2.265449333136587, 53.639428753073041],
            [-2.263829466063191, 53.629984808777458],
            [-2.262511395467573, 53.626417580591323],
            [-2.262999998879349, 53.62499546576764],
            [-2.261241818172176, 53.624300034150792],
            [-2.260464850982613, 53.624327794902868],
            [-2.26020747361523, 53.622306898627379],
            [-2.260583275056053, 53.621955538611552],
            [-2.259629381482098, 53.621723919034615],
            [-2.259956657588434, 53.620611361544583],
            [-2.256771390882161, 53.62038185281159],
            [-2.257143491332219, 53.619917259481234],
            [-2.258380863593931, 53.619789655466398],
            [-2.259237698421293, 53.619240416407187],
            [-2.259121704683035, 53.618321169548658],
            [-2.257919043950521, 53.616449718589323],
            [-2.260611355949739, 53.6167018438802],
            [-2.260581159965056, 53.616217442650978],
            [-2.266479531098901, 53.61452273976164],
            [-2.267238515911711, 53.614787997232675],
            [-2.266858355964558, 53.61562745091485],
            [-2.269045991516963, 53.616150159528921],
            [-2.271789600099509, 53.614498204402281],
            [-2.272553031392821, 53.618496242089613],
            [-2.27581000064471, 53.618833923416872],
            [-2.276025086627026, 53.618203350325274],
            [-2.277747133759005, 53.618692818347171],
            [-2.278291510184554, 53.620322921188269],
            [-2.280729405298478, 53.620909549041343],
            [-2.282845953177801, 53.620231341421508],
            [-2.283166971979078, 53.620759989976747],
            [-2.282425210466686, 53.621745957911024],
            [-2.282459890939961, 53.623089619761089],
            [-2.284498086847927, 53.625358815740377],
            [-2.283514066323189, 53.626978138202958],
            [-2.28486880214496, 53.628092157908128],
            [-2.285837186492782, 53.63064790132799],
            [-2.286676805780483, 53.631593252310417],
            [-2.288900493398977, 53.632808504605975],
            [-2.288788222497042, 53.633640187726854],
            [-2.289995339274043, 53.635229083711181],
            [-2.291306466653996, 53.636316170518384],
            [-2.292319257861648, 53.637992701495008],
            [-2.295042768364972, 53.640103641731613],
            [-2.295739081960767, 53.641479817561702],
            [-2.297595495508649, 53.648421311580854],
            [-2.297619896390666, 53.651883511030562],
            [-2.297190405997028, 53.652554200453494],
            [-2.295599385513379, 53.653073166638201],
            [-2.295612021665263, 53.654877967594963],
            [-2.296420600636024, 53.654965849011759],
            [-2.295359006158016, 53.655694721612186],
            [-2.293865618252708, 53.656130731777168],
            [-2.294264936282106, 53.657196650050167],
            [-2.293826873279337, 53.657955432151347],
            [-2.295902865516603, 53.662697882181845],
            [-2.295693013714979, 53.664281221905206],
            [-2.298960696293375, 53.666674746596748],
            [-2.300105747935389, 53.667010734912154],
            [-2.301137499923132, 53.666101234315882],
            [-2.302983567050151, 53.665212135899594],
            [-2.304099908853125, 53.664295204600037],
            [-2.304012215677664, 53.663886464575896],
            [-2.305989463387594, 53.662952943764758],
            [-2.307737869420485, 53.660947686623985],
            [-2.311775812567665, 53.659796630066005],
            [-2.311893665646787, 53.659158160442445],
            [-2.313472355180546, 53.658604855446789],
            [-2.313968431292324, 53.656944331730003],
            [-2.315583382481039, 53.65579138980781],
            [-2.31556128421261, 53.655061605373746],
            [-2.318443019840919, 53.655180703902012],
            [-2.320158080947087, 53.655682163208951],
            [-2.320408742652995, 53.656014056031374],
            [-2.325329391473475, 53.65543991315225],
            [-2.32656394045187, 53.655621707164549],
            [-2.33340397275459, 53.655170509631994],
            [-2.353340267813498, 53.658466801811322],
            [-2.355401810511114, 53.659437715661966],
            [-2.355571482923806, 53.664491283158831],
            [-2.361608660409863, 53.665054729656582],
            [-2.371237973287006, 53.667064670267834],
            [-2.37406095362262, 53.671372915488021],
            [-2.375936387517499, 53.675625648598391],
            [-2.375708476147631, 53.681821026653466],
            [-2.374425751167199, 53.687238629074876],
            [-2.380894295510171, 53.68856644791915],
            [-2.383156566718565, 53.688564621029876],
            [-2.384796428211034, 53.688225000544371],
            [-2.39152924086916, 53.688606737854627],
            [-2.400151707472352, 53.692471007639107],
            [-2.40134404590697, 53.695070891446086],
            [-2.403136335953963, 53.696406804157498],
            [-2.403011055832029, 53.696926742432439],
            [-2.405508487845424, 53.698967612855839],
            [-2.408608310210262, 53.703123988951575],
            [-2.41099082703699, 53.705121094078393],
            [-2.408348790697053, 53.705262259948967],
            [-2.404591623125234, 53.706489315496093],
            [-2.39575581949218, 53.709615266226926],
            [-2.382900237700436, 53.711198534699975],
            [-2.36973212464648, 53.714052310289489],
            [-2.360801355289579, 53.716482981542491],
            [-2.355285189179757, 53.722154804870577],
            [-2.352913961864141, 53.722209454006631],
            [-2.350131441551308, 53.721827538114738],
            [-2.349877495963206, 53.722452954716339],
            [-2.344185260885136, 53.722434398088815],
            [-2.342768973947584, 53.72326177219616],
            [-2.342253221830346, 53.724321148604567],
            [-2.342818272392228, 53.725967053374809],
            [-2.33950631648753, 53.727610527741923],
            [-2.338166420081074, 53.729678887221056],
            [-2.336708070108013, 53.728323995088786],
            [-2.33455572232719, 53.729266588381051],
            [-2.335793822166401, 53.730377647850617],
            [-2.331801208388879, 53.731322640933236],
            [-2.331146273391687, 53.732464144888183],
            [-2.329563038335171, 53.732179095760415],
            [-2.327379078517433, 53.734170556912041],
            [-2.325772024627816, 53.734338522838009],
            [-2.325930012138291, 53.735164997602723],
            [-2.327697043376796, 53.735291401441998],
            [-2.326258007349854, 53.737779639108233],
            [-2.323807549998687, 53.737704498335084],
            [-2.324483988234611, 53.742659596579728],
            [-2.324888503832524, 53.743012630321672],
            [-2.325601055183268, 53.743200343741584],
            [-2.326148498731402, 53.74732078688443],
            [-2.314109436125508, 53.747024002901306],
            [-2.303299036600881, 53.74865623817999],
            [-2.302984675901216, 53.74838110020756],
            [-2.301479374133404, 53.748871153377564],
            [-2.299171100071726, 53.750749155588863],
            [-2.295271739984963, 53.751975816176639],
            [-2.29330467322365, 53.749781271017397],
            [-2.287519588909721, 53.746697113759907],
            [-2.286843990911258, 53.74524086936006],
            [-2.287191491864371, 53.744836472200198],
            [-2.285267180726646, 53.743693295145036],
            [-2.283731118773075, 53.74457418004021],
            [-2.281631189227157, 53.74504022496135],
            [-2.278290815385769, 53.743431979685063],
            [-2.275222470466423, 53.743736576224734],
            [-2.271323901807831, 53.742271429964497],
            [-2.267448328969075, 53.74247339234914],
            [-2.265649230533838, 53.744010761374675],
            [-2.263291988438977, 53.745303058480353],
            [-2.262821766037282, 53.746246040402056],
            [-2.264242308912042, 53.747414058456251],
            [-2.266797063276351, 53.74808249824644],
            [-2.262714148839535, 53.748923819480069],
            [-2.261749937611936, 53.748472932864289],
            [-2.259343926390803, 53.749539657381504],
            [-2.257453272093714, 53.749843039394875],
            [-2.257138212483619, 53.750150209013967],
            [-2.257901016683387, 53.750649203211019],
            [-2.256054395109572, 53.752217087126894],
            [-2.255876055055261, 53.752816072499144],
            [-2.25391959934075, 53.753552762983169],
            [-2.251697554703203, 53.755030594932101],
            [-2.248974250501819, 53.752820734329802],
            [-2.245273761579213, 53.750983124610819],
            [-2.242420679309586, 53.749042122750325],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000126",
        LAD13CDO: "30UN",
        LAD13NM: "South Ribble",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.611875149992816, 53.778560429001708],
            [-2.608724214600814, 53.778518949761143],
            [-2.605971739605495, 53.778958935467138],
            [-2.601121099023004, 53.780611112632187],
            [-2.599489613205162, 53.781456078907318],
            [-2.598968081197806, 53.78106680396214],
            [-2.59661253716145, 53.780942831777132],
            [-2.596032213667751, 53.780579003501721],
            [-2.594546186042831, 53.780448865926736],
            [-2.592949033242848, 53.779002499069421],
            [-2.592299881993847, 53.779565669257408],
            [-2.59179365380237, 53.779502556590103],
            [-2.589140740461258, 53.780675094632009],
            [-2.58914655904028, 53.78109031735854],
            [-2.58810910172899, 53.780805994938454],
            [-2.588028078912561, 53.781740258444948],
            [-2.587093133402305, 53.782168182304154],
            [-2.585655218851258, 53.782225550541099],
            [-2.585297643599325, 53.782703667588365],
            [-2.584144742265553, 53.781102216181161],
            [-2.582619059862057, 53.779970847373797],
            [-2.580864111820072, 53.775529345056839],
            [-2.577440411262433, 53.774942786655892],
            [-2.574709442837649, 53.775075456021682],
            [-2.566840056296954, 53.777192785914941],
            [-2.562288463864264, 53.776795380846799],
            [-2.555613528630033, 53.777389168015382],
            [-2.547507048411966, 53.7758562529296],
            [-2.547588924602813, 53.771306993633161],
            [-2.54604246566968, 53.769289925531112],
            [-2.545545053749559, 53.769189726436451],
            [-2.548215616768913, 53.76770438559457],
            [-2.552055787270732, 53.766692669125554],
            [-2.559229769977674, 53.763593552269732],
            [-2.564274996772937, 53.761996976093613],
            [-2.563920860261707, 53.760926358005911],
            [-2.564457968875083, 53.757884038651376],
            [-2.563636601035711, 53.757257836075283],
            [-2.56217493121583, 53.756724515241295],
            [-2.561377964616433, 53.756899925436599],
            [-2.561376081436901, 53.756304020106384],
            [-2.560413143743073, 53.756202467656536],
            [-2.557382600181545, 53.757332931326886],
            [-2.554366628031263, 53.757739705000567],
            [-2.552929198311531, 53.757658263076308],
            [-2.552285330444104, 53.757484166191631],
            [-2.551300063386402, 53.756373275109404],
            [-2.552163832636224, 53.755160392290087],
            [-2.551640625659232, 53.754584864558609],
            [-2.552080244165061, 53.754222415058265],
            [-2.552011132181917, 53.753000345457394],
            [-2.552827326425957, 53.752097767349163],
            [-2.552651759313997, 53.751200661067294],
            [-2.553801850573397, 53.750437649310946],
            [-2.55398918630898, 53.749692564130591],
            [-2.55454078852687, 53.749547999460475],
            [-2.555123428987347, 53.749917410876272],
            [-2.556253605136443, 53.749030430982373],
            [-2.558137543148342, 53.749070205400159],
            [-2.559168081874817, 53.747538312017525],
            [-2.561603784426354, 53.746886063376607],
            [-2.56392188572994, 53.748779766052827],
            [-2.566999343808927, 53.749614622661888],
            [-2.569138818777044, 53.749708741247765],
            [-2.572952805109205, 53.751052269467031],
            [-2.573184966612084, 53.751396304401531],
            [-2.576809077510735, 53.751258470574413],
            [-2.578260252514339, 53.75025558696889],
            [-2.578689415938213, 53.748821700085614],
            [-2.579862448099406, 53.747561280252249],
            [-2.582347950503258, 53.746695348654981],
            [-2.583848896633395, 53.745693951679904],
            [-2.58426980877758, 53.744993518553244],
            [-2.585679741702583, 53.744432962629205],
            [-2.587456234117514, 53.74308772274221],
            [-2.590209789400009, 53.742878246715058],
            [-2.594211707169392, 53.743459773949105],
            [-2.595623862559746, 53.743390743098701],
            [-2.597157795849063, 53.742821345616363],
            [-2.59923062638606, 53.74251348679585],
            [-2.599940946387069, 53.742139618534715],
            [-2.60003495024819, 53.741394925809857],
            [-2.601017440927627, 53.740865090687031],
            [-2.600970732983861, 53.740566917101383],
            [-2.602478777745488, 53.74053147926702],
            [-2.60248639877892, 53.739792611613822],
            [-2.604684458064076, 53.73892765882529],
            [-2.604593079206682, 53.738370851956454],
            [-2.605652958953319, 53.738698061551048],
            [-2.605900518025944, 53.738517944655058],
            [-2.607084163498121, 53.739226513891317],
            [-2.608232520853863, 53.738329060036385],
            [-2.611857654825592, 53.738555078970897],
            [-2.610154015970894, 53.73701420249742],
            [-2.610750205246167, 53.737041724367586],
            [-2.6169336215833, 53.732462906798958],
            [-2.618512941491965, 53.732346904269036],
            [-2.624299793486664, 53.729032591306549],
            [-2.624152945855164, 53.726589459349121],
            [-2.623697749128737, 53.724814858552044],
            [-2.622907672745579, 53.723860825026335],
            [-2.623713854179423, 53.723343402095814],
            [-2.624835933356022, 53.723179361179348],
            [-2.627577749044104, 53.720958416306125],
            [-2.628103549280738, 53.719938192142578],
            [-2.629299169371892, 53.71912477278304],
            [-2.629757974315148, 53.717276177270755],
            [-2.633428189143589, 53.718093628396872],
            [-2.63668792598681, 53.718111398919476],
            [-2.639889050926015, 53.717761774751544],
            [-2.648195824717906, 53.71577924127466],
            [-2.648349044020356, 53.715298439328556],
            [-2.646938282665444, 53.714404546165987],
            [-2.647721376005531, 53.714288859368331],
            [-2.651456280055138, 53.712031425407062],
            [-2.652872427082278, 53.712000344598927],
            [-2.653627011822032, 53.712297335518542],
            [-2.654453047034548, 53.712121148834321],
            [-2.655691771483918, 53.712664455080194],
            [-2.657083548818486, 53.713012762543869],
            [-2.656747067300391, 53.713296839459147],
            [-2.655940289519247, 53.713250926431243],
            [-2.655535848682986, 53.713744798501196],
            [-2.656399528619804, 53.713941405171198],
            [-2.655564983679072, 53.714546391601353],
            [-2.656283284966315, 53.714844463246038],
            [-2.65595944205679, 53.715649787398768],
            [-2.656774599373849, 53.716230455644613],
            [-2.656725842550105, 53.716991129320107],
            [-2.657263144287294, 53.717339622782887],
            [-2.658172511038149, 53.716963414247843],
            [-2.658474859024914, 53.717208929425183],
            [-2.658050165125973, 53.7175707936509],
            [-2.65845191398388, 53.717680040083565],
            [-2.661951896358006, 53.715436154354876],
            [-2.668962450442287, 53.712258501228838],
            [-2.671772276199138, 53.710597021717845],
            [-2.67493929231685, 53.707791951698745],
            [-2.676810843281249, 53.704713689404869],
            [-2.677479084509236, 53.701803099407435],
            [-2.67759852770646, 53.693682398873676],
            [-2.675848339742933, 53.69362038354879],
            [-2.673589427569968, 53.692523056953256],
            [-2.66638496991968, 53.691922502288591],
            [-2.664082422991069, 53.691398693106223],
            [-2.661753813674978, 53.691526635192183],
            [-2.659420743754782, 53.691176379323586],
            [-2.657942910563694, 53.690605662727975],
            [-2.657237027915559, 53.688843339421581],
            [-2.655944522986681, 53.688498085867515],
            [-2.656752721715575, 53.687595724735104],
            [-2.657463059255609, 53.687703281271851],
            [-2.657403036527872, 53.687155324644046],
            [-2.658338358082533, 53.686058107126833],
            [-2.659074723585844, 53.682598952032038],
            [-2.658826562442945, 53.680668730513716],
            [-2.660830616804627, 53.680735884120111],
            [-2.664965641816806, 53.681909333574424],
            [-2.667230718829717, 53.681340355405268],
            [-2.667854913727672, 53.680123452794938],
            [-2.671090952164592, 53.679595720144178],
            [-2.673644666655214, 53.679955292685001],
            [-2.676341620127644, 53.679236302211471],
            [-2.678064798889199, 53.679256216789696],
            [-2.679267016197021, 53.679547814256082],
            [-2.680134755168683, 53.679093471809701],
            [-2.681411120105256, 53.679569786004301],
            [-2.682535396075199, 53.679065428804172],
            [-2.683334429620101, 53.679139069375864],
            [-2.683276962384888, 53.67942342841615],
            [-2.683692222963163, 53.679076804943044],
            [-2.685022405970803, 53.679415252039057],
            [-2.688537994292028, 53.679057124462723],
            [-2.68924621743986, 53.679502465777148],
            [-2.689963923380221, 53.679137899913691],
            [-2.690262582464148, 53.679533462516247],
            [-2.691328157663202, 53.679148905691683],
            [-2.69228331428194, 53.679694371127624],
            [-2.693997372943664, 53.679526253169698],
            [-2.694859498084691, 53.679843933777882],
            [-2.695521562186021, 53.679596505740037],
            [-2.696701525907756, 53.680274556028891],
            [-2.700277479490744, 53.679997526373484],
            [-2.700787318422919, 53.680248913090168],
            [-2.703065985084923, 53.680347908091619],
            [-2.703326150285926, 53.680696925090295],
            [-2.704119402791594, 53.680601480424876],
            [-2.70507529175371, 53.680997633584433],
            [-2.705833868759782, 53.680729805736874],
            [-2.707131177448461, 53.681170668621178],
            [-2.70716164220616, 53.681452722411656],
            [-2.708063781014651, 53.681530083351362],
            [-2.708487633987673, 53.680984680494191],
            [-2.708458025695808, 53.678499580183583],
            [-2.708955343706449, 53.678454391137656],
            [-2.708063310434023, 53.675997763215825],
            [-2.710585162418692, 53.676077199458717],
            [-2.712469706643426, 53.671403742450906],
            [-2.71550685830731, 53.671805384988822],
            [-2.717296602082689, 53.672641377906189],
            [-2.720013062517786, 53.673136508669252],
            [-2.730277573263133, 53.67392193817674],
            [-2.731801117743394, 53.674574178356657],
            [-2.732874940225793, 53.674170324209619],
            [-2.733553267998442, 53.674266839517848],
            [-2.733835823650896, 53.674759467757106],
            [-2.733267741314313, 53.677175423879511],
            [-2.731626887457445, 53.679805567341518],
            [-2.731062056694004, 53.681983299350705],
            [-2.729110350084082, 53.68329131982987],
            [-2.729467935773743, 53.683656765087825],
            [-2.732294437489248, 53.684248921241313],
            [-2.730041836087389, 53.68619786719448],
            [-2.732733937432673, 53.686539159014771],
            [-2.734454933987077, 53.687284534583107],
            [-2.737467837696031, 53.687434098004523],
            [-2.737556877891977, 53.68776342178576],
            [-2.741504165447298, 53.688087790352213],
            [-2.742731907364244, 53.687811429520352],
            [-2.742771380476824, 53.687473222826767],
            [-2.744111610602043, 53.687738147273237],
            [-2.746043233065488, 53.692416246215281],
            [-2.746605407677835, 53.692691380998589],
            [-2.748220498184884, 53.69239007782059],
            [-2.750820251401016, 53.692872658314009],
            [-2.75762595450749, 53.692863948162469],
            [-2.76565251762367, 53.692867724632734],
            [-2.766104873858939, 53.692508892632794],
            [-2.767214324610597, 53.692474824443146],
            [-2.770262268325117, 53.691447662583698],
            [-2.776495030005497, 53.690930125900174],
            [-2.787462742381366, 53.691290842927714],
            [-2.789910043917098, 53.690485545069514],
            [-2.792416773140662, 53.689140501047198],
            [-2.793907761443494, 53.689028157391313],
            [-2.793682896552741, 53.688102946364879],
            [-2.795875676156976, 53.687467294782543],
            [-2.804110297191988, 53.68811157514893],
            [-2.805828927850425, 53.687632625455613],
            [-2.806738910007448, 53.686836418734302],
            [-2.80731924708992, 53.686777678174046],
            [-2.809103979854778, 53.687539532035508],
            [-2.816237858347738, 53.687787732003279],
            [-2.818753365199289, 53.687726515052198],
            [-2.81980248556024, 53.687245648427719],
            [-2.822290790195046, 53.687888334833431],
            [-2.824366151317212, 53.68731049229357],
            [-2.825540880183697, 53.687526210175704],
            [-2.827899543181661, 53.689428050019735],
            [-2.828765223576368, 53.690635495421418],
            [-2.829624454561592, 53.693899523882727],
            [-2.831885490198547, 53.694433029015592],
            [-2.832477265019216, 53.694950243450634],
            [-2.833331074714974, 53.697537456129538],
            [-2.832723138936727, 53.699579352657167],
            [-2.83142890772797, 53.700696612893942],
            [-2.829468759241832, 53.700999631169182],
            [-2.825298852588547, 53.700620367489492],
            [-2.82367394439902, 53.702191037888333],
            [-2.82373384181112, 53.703318667124286],
            [-2.823333318677641, 53.703520064372348],
            [-2.823752938321209, 53.703520774216656],
            [-2.824382364157885, 53.707899173515933],
            [-2.823869895728012, 53.708188529932961],
            [-2.823210664436385, 53.70794498391848],
            [-2.822865960505623, 53.708214307219109],
            [-2.824513782127333, 53.70826679199417],
            [-2.824619625916255, 53.70957297194694],
            [-2.824117233322554, 53.70983619364965],
            [-2.824768910317716, 53.71000158794569],
            [-2.825292128636208, 53.714207234370079],
            [-2.826630703031184, 53.715862648018522],
            [-2.825175922955416, 53.71678499731599],
            [-2.824836942896327, 53.715801309356578],
            [-2.824061363058952, 53.715658339862586],
            [-2.823559514284838, 53.715875714362532],
            [-2.822982205204964, 53.715410490148834],
            [-2.823533761251114, 53.716108689860626],
            [-2.823110461786154, 53.716779434295326],
            [-2.823657330865962, 53.717236777651841],
            [-2.821552547955434, 53.718635438705853],
            [-2.82037971782476, 53.718171588657512],
            [-2.819184430831497, 53.71655107988046],
            [-2.818554956853732, 53.716986824157551],
            [-2.817393324608862, 53.717018126620417],
            [-2.817291840431477, 53.717723505772469],
            [-2.816887579020306, 53.717740644813361],
            [-2.817394086338515, 53.717760559151451],
            [-2.817495272530936, 53.717039901749366],
            [-2.818556143813125, 53.717047936836678],
            [-2.819161220019906, 53.716604269899818],
            [-2.820317271238904, 53.718233137122056],
            [-2.821579742424918, 53.718708956663058],
            [-2.823776581222794, 53.717600884772438],
            [-2.823955126621898, 53.717122375085445],
            [-2.82351702142523, 53.716725406159895],
            [-2.824094015046407, 53.715855859073585],
            [-2.824901771792412, 53.717023279419458],
            [-2.827099480915247, 53.716200070716162],
            [-2.829948894256823, 53.719543804622347],
            [-2.828791935684519, 53.72062660759395],
            [-2.83044558813022, 53.719987083405535],
            [-2.834747144895087, 53.723699935364479],
            [-2.833632733874929, 53.724288343410095],
            [-2.830921131051287, 53.722738715942164],
            [-2.830451557838998, 53.723212063007928],
            [-2.830893731329846, 53.722886314961514],
            [-2.833662499862589, 53.72441397317467],
            [-2.834158445587533, 53.724125589949217],
            [-2.83515492558399, 53.724087187572287],
            [-2.835435495570665, 53.724326119000423],
            [-2.833566605779954, 53.725388977642616],
            [-2.833062011686549, 53.726237392530706],
            [-2.824411827564246, 53.730785112727112],
            [-2.832366378970868, 53.726853435660644],
            [-2.833942929723563, 53.726483835797744],
            [-2.834264826188387, 53.725518043470295],
            [-2.835806081752957, 53.724747784122322],
            [-2.842990755080776, 53.728953425146628],
            [-2.842979425484701, 53.72922045887578],
            [-2.841638166030884, 53.729153488491114],
            [-2.840183906649088, 53.728588442317957],
            [-2.838762270737457, 53.728670311248877],
            [-2.836872872488779, 53.729939037047636],
            [-2.83432456039099, 53.730447735239828],
            [-2.836604881439836, 53.730171170469617],
            [-2.837008846705716, 53.729911891460461],
            [-2.838569994281312, 53.728909848549371],
            [-2.840060402841952, 53.728779861439833],
            [-2.840761470527557, 53.729275594248001],
            [-2.843060543035994, 53.729789749420995],
            [-2.841997719798212, 53.731899598564191],
            [-2.843086332557, 53.732895940677345],
            [-2.844644444855605, 53.733338877024373],
            [-2.844942703333348, 53.733698105312605],
            [-2.844593018729921, 53.734100552371764],
            [-2.842489926948224, 53.734454220845947],
            [-2.840994151079019, 53.735691638596336],
            [-2.836885439322354, 53.735900176654084],
            [-2.835865789698103, 53.737076677252851],
            [-2.834355621474251, 53.737678636071323],
            [-2.835804497685476, 53.737273050525872],
            [-2.837046828682682, 53.736011403383891],
            [-2.838062683277758, 53.736246986694958],
            [-2.841215447454239, 53.735917489947134],
            [-2.842737321071421, 53.734620562852157],
            [-2.845380501758074, 53.734136344552383],
            [-2.84538601026722, 53.733353420822169],
            [-2.843158783238515, 53.732501741278703],
            [-2.842619132648972, 53.731592322188789],
            [-2.843605824969485, 53.73037554654308],
            [-2.84468842190488, 53.730089279293843],
            [-2.846068779442028, 53.730365368087973],
            [-2.853144644606671, 53.733366732278014],
            [-2.854896849355436, 53.734702494276327],
            [-2.854835000474292, 53.735315042223796],
            [-2.845723729728731, 53.738470796919437],
            [-2.828207748823357, 53.742674758052345],
            [-2.827286290730175, 53.742772804873802],
            [-2.826342136577267, 53.742487199913228],
            [-2.826472306481416, 53.741471520752576],
            [-2.830338588431298, 53.740625952327996],
            [-2.831107632268504, 53.740182888659426],
            [-2.831328932459371, 53.739565652979316],
            [-2.830236799204978, 53.740538572139634],
            [-2.826386386724552, 53.741265382020082],
            [-2.825863198612502, 53.741801098036056],
            [-2.825966658098494, 53.742824154403266],
            [-2.825231058696819, 53.743276841366907],
            [-2.823871240560648, 53.743522592160154],
            [-2.823747197542336, 53.742994932041981],
            [-2.82445146437528, 53.742567637096158],
            [-2.823411791280211, 53.741660674753682],
            [-2.824218650931058, 53.74252519612255],
            [-2.82352057396606, 53.742881439452319],
            [-2.823267722222724, 53.742744757034174],
            [-2.823208209144866, 53.74357838284002],
            [-2.822333927848918, 53.743686853303664],
            [-2.821523865358705, 53.743434445670381],
            [-2.822213583638785, 53.7428041278188],
            [-2.821319113472871, 53.742265570755414],
            [-2.818541741154665, 53.741580792327255],
            [-2.81863759462876, 53.739882244440011],
            [-2.819007387140253, 53.739485129986541],
            [-2.82246081074836, 53.73861657077147],
            [-2.826596335106773, 53.739293693444473],
            [-2.827027406435357, 53.738932983288656],
            [-2.826957051113265, 53.73789801381465],
            [-2.827595430641202, 53.737286893707036],
            [-2.828265980725768, 53.737079123636065],
            [-2.830041691868022, 53.737562990166992],
            [-2.832025013083396, 53.73696589040437],
            [-2.83221918085875, 53.736741631186732],
            [-2.830151459570064, 53.735363683960223],
            [-2.827330453132147, 53.734911310718559],
            [-2.827263048324403, 53.734103725169362],
            [-2.828758675974989, 53.733611613556477],
            [-2.829415304404821, 53.733777847739645],
            [-2.82898338418436, 53.733321533348494],
            [-2.827118561073127, 53.734079555465016],
            [-2.827244854635312, 53.735106948583621],
            [-2.829653670329876, 53.735335673047217],
            [-2.831743912304071, 53.73693368658175],
            [-2.830349727895991, 53.737423334223578],
            [-2.828230617724802, 53.736976901162386],
            [-2.827428068499863, 53.737179290917254],
            [-2.826621591354741, 53.738031558365599],
            [-2.82682824246611, 53.738904696452096],
            [-2.826316755617205, 53.739185065618159],
            [-2.822537560102133, 53.738354483695737],
            [-2.819275626633519, 53.738855911345901],
            [-2.817796398935352, 53.739505086860682],
            [-2.817388397481233, 53.741292950688681],
            [-2.817641608615364, 53.742152303473112],
            [-2.817079100667616, 53.743324621496377],
            [-2.820071752486719, 53.744314462094302],
            [-2.811975649933512, 53.746482752125651],
            [-2.810643166874744, 53.74634616402637],
            [-2.801100261983167, 53.748952236989432],
            [-2.798716329180865, 53.749268360573964],
            [-2.788592006795259, 53.751675069844836],
            [-2.782587355412355, 53.752783143109603],
            [-2.776139209535787, 53.753462350850064],
            [-2.774960256851698, 53.753350437325189],
            [-2.774834640783818, 53.753117554655688],
            [-2.773945298965554, 53.748053017462233],
            [-2.774413371702852, 53.753383634247029],
            [-2.764300416820026, 53.754875901031376],
            [-2.757202677764639, 53.756406720670213],
            [-2.757039282720486, 53.755670716216088],
            [-2.752469232941748, 53.75684370531954],
            [-2.737490716455021, 53.757577687334347],
            [-2.730647023567281, 53.757572871988046],
            [-2.724291756599419, 53.756776446741434],
            [-2.722729693591952, 53.756344555996193],
            [-2.721490973555222, 53.755573639799579],
            [-2.720924654291403, 53.755981518760535],
            [-2.719238405306092, 53.754846551129198],
            [-2.718543654921572, 53.753506978328488],
            [-2.718254727163798, 53.753284004705257],
            [-2.717034777113396, 53.7500438613733],
            [-2.716817964070539, 53.749512156070992],
            [-2.713829647385667, 53.748297708838535],
            [-2.710093699038527, 53.747608938357423],
            [-2.708293079577234, 53.74802047154855],
            [-2.704269026349015, 53.750225635979803],
            [-2.701294812573342, 53.751113141193052],
            [-2.696290375274088, 53.751576458163697],
            [-2.690353227669676, 53.748900863028886],
            [-2.685000279306031, 53.748812941023111],
            [-2.681094143916899, 53.746295137444662],
            [-2.680896765315704, 53.74618570415214],
            [-2.679996514585513, 53.74578268950355],
            [-2.67890555110586, 53.745605578545089],
            [-2.675560442979685, 53.746019811885141],
            [-2.673289162285615, 53.745988710989472],
            [-2.675161963118643, 53.746185800042049],
            [-2.676371440635289, 53.746030906434399],
            [-2.677364709007192, 53.745903691647833],
            [-2.678825459765104, 53.745716587493355],
            [-2.679837891880835, 53.745877167852228],
            [-2.684800517003235, 53.749024407295124],
            [-2.679680000353845, 53.752531104316184],
            [-2.678041544612003, 53.752496344875837],
            [-2.675527640810771, 53.750641894650315],
            [-2.673059532621364, 53.749361481398928],
            [-2.667998226978292, 53.748158420654029],
            [-2.666025327044252, 53.748251196503375],
            [-2.663743110385993, 53.748827421865833],
            [-2.661886939688709, 53.749884816362503],
            [-2.661921972935021, 53.750183930137553],
            [-2.660664967373141, 53.75125686894814],
            [-2.65941805913657, 53.753171933185563],
            [-2.659231272606081, 53.756738579396668],
            [-2.658687961186197, 53.758461907632892],
            [-2.656934505386002, 53.760304225504775],
            [-2.655726318835694, 53.760838449558463],
            [-2.653266749511679, 53.761084677259852],
            [-2.646641805577843, 53.759950348719201],
            [-2.64502261926961, 53.760199958753134],
            [-2.643821857236447, 53.761328141284679],
            [-2.643889435948015, 53.764950912835822],
            [-2.643691256724869, 53.765383409907358],
            [-2.642541565725909, 53.765794946468681],
            [-2.635432802692518, 53.765701623952687],
            [-2.628030740649875, 53.767299192455688],
            [-2.625115493953644, 53.768565586799539],
            [-2.621790962393585, 53.771202031114633],
            [-2.621539079913857, 53.773048605559715],
            [-2.622908414606724, 53.774634196584103],
            [-2.62753272647651, 53.777338875135811],
            [-2.633868718740342, 53.779492350078449],
            [-2.634996990647172, 53.780144310260312],
            [-2.635230445486444, 53.780931333750488],
            [-2.634652158809077, 53.781830515384669],
            [-2.633466551186705, 53.782512695784952],
            [-2.631921033780718, 53.78277881370942],
            [-2.63031293847782, 53.782718971207714],
            [-2.625181942300748, 53.781711309214657],
            [-2.61882339293257, 53.779357956027582],
            [-2.611875149992816, 53.778560429001708],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000127",
        LAD13CDO: "30UP",
        LAD13NM: "West Lancashire",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-2.806195272391852, 53.641255299115549],
              [-2.806014484023805, 53.640024199452817],
              [-2.80638040071122, 53.640097237541333],
              [-2.806195272391852, 53.641255299115549],
            ],
          ],
          [
            [
              [-2.859796693686137, 53.733454900880396],
              [-2.851066299664253, 53.730813542642437],
              [-2.844772596505293, 53.728164279694852],
              [-2.836814743676382, 53.723643264283872],
              [-2.831156278116281, 53.718986244306208],
              [-2.828105701022511, 53.71543270310201],
              [-2.828409241271745, 53.715149268452329],
              [-2.828639946444024, 53.715400245566371],
              [-2.828896471530423, 53.714960737427518],
              [-2.827765383826919, 53.715078217548509],
              [-2.827928208222099, 53.714496444051889],
              [-2.827465398008269, 53.714771090721534],
              [-2.826311528338844, 53.713185420062075],
              [-2.826097129020469, 53.711909652562127],
              [-2.826536333954594, 53.71213223043457],
              [-2.825957408129859, 53.711274239946725],
              [-2.82621545150002, 53.710757426842058],
              [-2.825498417498471, 53.708957506908604],
              [-2.824472105667889, 53.70218464689971],
              [-2.824938580848467, 53.702025935681526],
              [-2.824987722019496, 53.701285853615417],
              [-2.825667876362407, 53.701055557966093],
              [-2.828228209854458, 53.701540329141793],
              [-2.830084391900151, 53.701340519526994],
              [-2.830495608446754, 53.702069322890914],
              [-2.830415127522223, 53.70136698948221],
              [-2.830738688438973, 53.701490582829003],
              [-2.830950681373339, 53.701256312987418],
              [-2.831249311070298, 53.701575125855321],
              [-2.831151813357743, 53.701239637013785],
              [-2.833194829676567, 53.699917630570326],
              [-2.833936055022695, 53.697800199953953],
              [-2.833360241139324, 53.69533419828673],
              [-2.832389992645872, 53.694288405472136],
              [-2.830093356391274, 53.693561906171247],
              [-2.829419067700147, 53.690540185522529],
              [-2.827530587693555, 53.68852906395383],
              [-2.825383414051648, 53.687062595416236],
              [-2.824096621922683, 53.686923149888266],
              [-2.822123985904456, 53.687487698835639],
              [-2.818925243185577, 53.686361799983089],
              [-2.818574444144525, 53.685372778399163],
              [-2.818898011328472, 53.684880698192806],
              [-2.819494529306662, 53.684643817498497],
              [-2.821557716528638, 53.684924498614713],
              [-2.823173369267585, 53.683995686286764],
              [-2.824159323755357, 53.682613677917644],
              [-2.824415366172014, 53.680762100930259],
              [-2.823550419714327, 53.67980809127797],
              [-2.822485004412348, 53.679674296800265],
              [-2.820917360581419, 53.679950213436832],
              [-2.818599920225842, 53.678802980272863],
              [-2.818347595657432, 53.678204279486998],
              [-2.818834036202393, 53.676512031348494],
              [-2.816932271218608, 53.674381286545064],
              [-2.81649026200144, 53.672738523076788],
              [-2.817682922073009, 53.67069900082204],
              [-2.822611936253382, 53.668864852689602],
              [-2.823336880851394, 53.668163266102823],
              [-2.821172432816251, 53.666992558946994],
              [-2.819759007821277, 53.665677351729272],
              [-2.821432276892546, 53.662533413432904],
              [-2.81794952357287, 53.661412134967343],
              [-2.814851160781453, 53.661066537833157],
              [-2.814745351914468, 53.658092086369571],
              [-2.812354659470272, 53.656369072252019],
              [-2.812623270041032, 53.655544806920474],
              [-2.813051515890349, 53.655468193634412],
              [-2.814563716146909, 53.656049351805883],
              [-2.816522482287375, 53.655777139137918],
              [-2.816746772541234, 53.654699692457804],
              [-2.816467995244528, 53.654289024824791],
              [-2.81481079536994, 53.65364056563142],
              [-2.812113106233597, 53.653170826898382],
              [-2.812351573903202, 53.652589454166588],
              [-2.813901709265312, 53.652082761462772],
              [-2.814633389640973, 53.65126702611488],
              [-2.814407771599336, 53.648431807200964],
              [-2.811700617852531, 53.646900588269752],
              [-2.81231079318369, 53.64566862604061],
              [-2.810290915344219, 53.645255366503648],
              [-2.809769609123945, 53.64489126701352],
              [-2.810227837643987, 53.644253581023342],
              [-2.809498433396826, 53.643685952175126],
              [-2.809780767471224, 53.642315999322832],
              [-2.808731013706225, 53.641842215111041],
              [-2.806544091490959, 53.641541478291167],
              [-2.806517963990343, 53.640330909310045],
              [-2.807545076946561, 53.638277310961172],
              [-2.805983900023499, 53.633346987423764],
              [-2.805922809926746, 53.633154036251618],
              [-2.805925798965117, 53.633137098927136],
              [-2.806087952197654, 53.63221812328014],
              [-2.807713377777829, 53.630779794109642],
              [-2.808129835957994, 53.63073204004332],
              [-2.808047810895497, 53.630396425072114],
              [-2.808227397705773, 53.629111658190638],
              [-2.807726540099944, 53.628144284679365],
              [-2.805967117922629, 53.627942222201192],
              [-2.803683208446581, 53.626875371013298],
              [-2.803480001640284, 53.626844377137225],
              [-2.802455780393093, 53.626417106708033],
              [-2.801816359041479, 53.62523042002455],
              [-2.801040851922049, 53.62499921715991],
              [-2.800482355860396, 53.62472970498078],
              [-2.801052316650153, 53.624167706295061],
              [-2.800809037092705, 53.622738368931003],
              [-2.799212953656446, 53.621405262611511],
              [-2.79815442510204, 53.621073462563487],
              [-2.798900304216442, 53.619820883543568],
              [-2.797159179258546, 53.619125102659055],
              [-2.798233546473209, 53.6182163950415],
              [-2.797508953894645, 53.617951572398169],
              [-2.797621469008031, 53.617184104607347],
              [-2.796120121603893, 53.616530753571219],
              [-2.795953409530638, 53.615945813454807],
              [-2.79346391066611, 53.614991592651279],
              [-2.792227418428999, 53.614993494518821],
              [-2.790785733329853, 53.614531136480849],
              [-2.788285502354775, 53.617127329814515],
              [-2.787278939557288, 53.617302940619254],
              [-2.788371913923903, 53.618113695472964],
              [-2.788629175010014, 53.61910073264746],
              [-2.793241178873778, 53.622944279145628],
              [-2.792977370098074, 53.623066473296504],
              [-2.781002461472958, 53.623455336005037],
              [-2.780595637497486, 53.620664374972669],
              [-2.779502666550975, 53.619767260356824],
              [-2.778859355374491, 53.619560220636487],
              [-2.777147137426143, 53.620254477605002],
              [-2.775863122822134, 53.620064165389344],
              [-2.775630870591498, 53.620342515965689],
              [-2.774175568157317, 53.620209923237809],
              [-2.77385397342788, 53.619737412584925],
              [-2.771048010743134, 53.61948677247625],
              [-2.770524812019885, 53.61914858124323],
              [-2.768830042427474, 53.619478573771765],
              [-2.7677841788528, 53.619079011006008],
              [-2.766200901258454, 53.619138596886096],
              [-2.766218039875713, 53.618832879388215],
              [-2.765430572948945, 53.618758821165372],
              [-2.764093847200543, 53.617737287291774],
              [-2.760952827531706, 53.616498021650052],
              [-2.761211910165381, 53.616194361204158],
              [-2.76056459653737, 53.615840736943554],
              [-2.760679367534979, 53.615413954081149],
              [-2.759421977475995, 53.615262845934488],
              [-2.758736540255819, 53.61463620446608],
              [-2.756287057646089, 53.615953242532122],
              [-2.749210142037315, 53.618215219088967],
              [-2.743278904658265, 53.622051629048514],
              [-2.736169397530573, 53.623668558837821],
              [-2.735378568382537, 53.622881540390836],
              [-2.734279632292298, 53.622459540809722],
              [-2.728285899942429, 53.621111953683744],
              [-2.724420405674179, 53.620072087716302],
              [-2.721394552135431, 53.618701656332597],
              [-2.712810096383747, 53.618396266377957],
              [-2.711614289619471, 53.617946772261014],
              [-2.709691790172967, 53.617792804094002],
              [-2.704865548775552, 53.618744445736461],
              [-2.704886622365987, 53.61991821003059],
              [-2.70373285684633, 53.621356857546395],
              [-2.700816609150009, 53.622733922124716],
              [-2.697958480593543, 53.623063425380977],
              [-2.696931123011504, 53.62292559914291],
              [-2.695642223892152, 53.623431956653008],
              [-2.695320037025354, 53.62397493190273],
              [-2.696159366180485, 53.624813164692604],
              [-2.696288205705666, 53.62630179487752],
              [-2.694445537701443, 53.626609120846574],
              [-2.69190876329965, 53.624880964022552],
              [-2.692216460427796, 53.624096293724804],
              [-2.691820678210902, 53.622269440716522],
              [-2.692304274079091, 53.621794751797736],
              [-2.692091382487837, 53.621350158008184],
              [-2.691338027670653, 53.621137892477044],
              [-2.69113237409582, 53.619747673680664],
              [-2.692580009888697, 53.618229248272947],
              [-2.692274975253202, 53.617141616938902],
              [-2.693410450415666, 53.616783594197599],
              [-2.694604604463962, 53.615765469556941],
              [-2.695326279647727, 53.615802624320217],
              [-2.698474432180368, 53.61429849946272],
              [-2.699450676686422, 53.614282908778094],
              [-2.698589853369946, 53.613232696516221],
              [-2.6972258103957, 53.607417945309976],
              [-2.696040891045591, 53.606223090909815],
              [-2.696108946894292, 53.604664099115418],
              [-2.690982782496322, 53.604667751795255],
              [-2.689314174703036, 53.604286381131317],
              [-2.689915226164122, 53.603796641614835],
              [-2.692589983233955, 53.596123926860265],
              [-2.693660005455741, 53.591805963471728],
              [-2.6933772375069, 53.589392403157007],
              [-2.694227340527453, 53.589365899688971],
              [-2.69676330110863, 53.590250898962822],
              [-2.698182857616005, 53.591316742674934],
              [-2.700070878899487, 53.591196946695256],
              [-2.699923049268534, 53.590753781645404],
              [-2.701405672438763, 53.590604876472923],
              [-2.702814172139085, 53.58981371297326],
              [-2.701736101291075, 53.589393989397045],
              [-2.702522766127785, 53.588822199033174],
              [-2.702762221360477, 53.587229832879508],
              [-2.704693297936436, 53.58707645301503],
              [-2.705995898342345, 53.586649009434041],
              [-2.707481329081555, 53.586677983688745],
              [-2.708200623847591, 53.585879145396845],
              [-2.70970257018387, 53.585543061972416],
              [-2.710408458371847, 53.585029224476436],
              [-2.711646068019065, 53.582808902562917],
              [-2.714375813951249, 53.581661877180743],
              [-2.714944605153714, 53.580639183753],
              [-2.714498244584186, 53.578980778203196],
              [-2.7148030325004, 53.578336279714136],
              [-2.716429983493902, 53.577551736306695],
              [-2.716996619504603, 53.578013944535627],
              [-2.717764651640156, 53.576990940945215],
              [-2.71922352315878, 53.576097708059542],
              [-2.7176800071424, 53.576006309636483],
              [-2.715607072036601, 53.575060557752892],
              [-2.717660589983182, 53.572542258063471],
              [-2.715773254556866, 53.571041703173172],
              [-2.715659830970651, 53.570227124632247],
              [-2.714307591672976, 53.568749409601715],
              [-2.7123915704578, 53.5683096163879],
              [-2.712594943835938, 53.567473372329744],
              [-2.711044374887829, 53.565682204169086],
              [-2.711270334079043, 53.564751448366323],
              [-2.709520183628445, 53.564015799522288],
              [-2.707934846776989, 53.564195078717738],
              [-2.705208091442877, 53.563468740284975],
              [-2.70421436330817, 53.562634171177507],
              [-2.704738712728393, 53.561844587210771],
              [-2.705319495955519, 53.561181405948737],
              [-2.704819650337989, 53.559176319296469],
              [-2.705644818209976, 53.558867640498228],
              [-2.705963746143244, 53.557984883182016],
              [-2.707080642154279, 53.557157631640116],
              [-2.707778200231249, 53.554634021670047],
              [-2.70918646548063, 53.554267933558833],
              [-2.710263447001009, 53.553049887151772],
              [-2.710146562167703, 53.5507576075806],
              [-2.710872500412404, 53.549673770731516],
              [-2.712568226412938, 53.548821450374604],
              [-2.716105433648617, 53.545571632275532],
              [-2.717915119598346, 53.544602599895114],
              [-2.717701888775308, 53.544398042504241],
              [-2.718607984810713, 53.543544080698375],
              [-2.716770344917406, 53.54062304826661],
              [-2.716048338923396, 53.540491647992987],
              [-2.715901278768664, 53.539377948480748],
              [-2.714559173061786, 53.538264207078484],
              [-2.714788955996299, 53.538021041270795],
              [-2.716381345821052, 53.537872186538443],
              [-2.716575396275922, 53.53524636511991],
              [-2.716251969592836, 53.53521414695205],
              [-2.71700174361734, 53.534226303728907],
              [-2.716296951717782, 53.533767618279114],
              [-2.717076470884614, 53.532845212533253],
              [-2.716731459198247, 53.532248643479008],
              [-2.717186730312086, 53.529639229010321],
              [-2.718088792798208, 53.528743046150673],
              [-2.71792532856646, 53.52719529752035],
              [-2.718596123660576, 53.527077111761031],
              [-2.719726693456382, 53.525695057804761],
              [-2.720943536976382, 53.525045041254842],
              [-2.724876913635462, 53.524390253296694],
              [-2.726233013192485, 53.523775292148557],
              [-2.730520750560796, 53.520584281769423],
              [-2.731399780723032, 53.519691732049559],
              [-2.735923634694731, 53.519640592220917],
              [-2.741035291604152, 53.518443187553835],
              [-2.746984584540412, 53.516696520453678],
              [-2.748292910944169, 53.516055538346563],
              [-2.752828701463131, 53.516019862409919],
              [-2.756015810345866, 53.514605610648729],
              [-2.758701076697048, 53.514065456710149],
              [-2.759831623859413, 53.512521220936172],
              [-2.768872688401035, 53.513555514817213],
              [-2.770015177814404, 53.514104554927904],
              [-2.769901786105335, 53.515752000633256],
              [-2.770894714115353, 53.518193200757075],
              [-2.772520727827105, 53.520026266911941],
              [-2.774843663599823, 53.524003971768828],
              [-2.782477047850902, 53.531413890093262],
              [-2.788704159801265, 53.526994613282909],
              [-2.791574262630558, 53.525426003320625],
              [-2.793594521496817, 53.522141647697879],
              [-2.794592101013521, 53.521285594893989],
              [-2.800543947560972, 53.519369052520815],
              [-2.805613538092683, 53.518515225523203],
              [-2.808881705496133, 53.517241933317308],
              [-2.810476807326053, 53.516186646951255],
              [-2.810821829243925, 53.514431520931502],
              [-2.81045161594329, 53.513839882435995],
              [-2.815675868187544, 53.512838960253504],
              [-2.816741007052236, 53.512158435792109],
              [-2.821508264227404, 53.505091234381851],
              [-2.822591076026737, 53.505104459759472],
              [-2.819916315237515, 53.496510794019258],
              [-2.820387402852666, 53.494364659077334],
              [-2.824040326731826, 53.489576416956609],
              [-2.824965062427698, 53.485193439672955],
              [-2.825574978020074, 53.48276587288197],
              [-2.844422035614613, 53.484114593512388],
              [-2.843398078177911, 53.487876410415609],
              [-2.844448332336666, 53.489003367110662],
              [-2.8439384634387, 53.490195276378401],
              [-2.849837465449884, 53.493123333667938],
              [-2.850462398740899, 53.49324383030639],
              [-2.859180191920741, 53.492098323468895],
              [-2.861381031976839, 53.491067652077028],
              [-2.862157284348719, 53.491365872082213],
              [-2.866423917728606, 53.492528711516641],
              [-2.869683447364568, 53.493964799463157],
              [-2.87106843447115, 53.494981222517609],
              [-2.872026857142323, 53.49621647245344],
              [-2.873333667447051, 53.500023536024848],
              [-2.881502129765526, 53.502734779137214],
              [-2.88423517609249, 53.503080428189307],
              [-2.886223948884351, 53.503738051838063],
              [-2.8879955519441, 53.503812990021842],
              [-2.892390451639808, 53.511130322017799],
              [-2.88772080799358, 53.51454485035692],
              [-2.884581594574614, 53.519134382001262],
              [-2.884057726270974, 53.519544546725754],
              [-2.881208746787365, 53.51998625590376],
              [-2.88171773164064, 53.520528115479195],
              [-2.886303555569887, 53.521063182361566],
              [-2.889087469965809, 53.522194856585436],
              [-2.889623057639366, 53.522272668532395],
              [-2.890402736529322, 53.521906416483191],
              [-2.891663992154962, 53.522135214484827],
              [-2.895113395041065, 53.521339101261063],
              [-2.895979140008299, 53.52183777927349],
              [-2.896427792175165, 53.521512620036219],
              [-2.896674605223321, 53.521986270652548],
              [-2.897493021983149, 53.521671815938909],
              [-2.897853532890442, 53.522100565767808],
              [-2.898519507488768, 53.521567924845002],
              [-2.899772671281056, 53.52205377497323],
              [-2.901540327258833, 53.521621582014831],
              [-2.906006328306825, 53.521683992308546],
              [-2.907762753148748, 53.522423020095154],
              [-2.90875082777064, 53.52235708540146],
              [-2.910966080042232, 53.522964937456607],
              [-2.911401708604956, 53.522740494472309],
              [-2.911711526240931, 53.522908917073714],
              [-2.911396736024516, 53.523281652323682],
              [-2.912059472809209, 53.523166934974846],
              [-2.912630319063465, 53.523549092028205],
              [-2.913278768233819, 53.52361155448294],
              [-2.913451586152428, 53.523371134387773],
              [-2.914351821444368, 53.523568294885223],
              [-2.914443810632892, 53.523287143574166],
              [-2.915956914294447, 53.523994654554443],
              [-2.916744657929109, 53.523242552835057],
              [-2.917139040851865, 53.523484020221282],
              [-2.916987881363278, 53.523957985917576],
              [-2.919060162591971, 53.524362741618617],
              [-2.921815584671606, 53.525451628985302],
              [-2.922458121097763, 53.525517682861398],
              [-2.923010947947254, 53.525204203693484],
              [-2.925616707928673, 53.52689370627369],
              [-2.925874974589601, 53.528774840154931],
              [-2.926938035996867, 53.529995357397617],
              [-2.928098447384021, 53.530490619832044],
              [-2.929193648004316, 53.530555819005038],
              [-2.930493138626165, 53.531268402670754],
              [-2.931234592189107, 53.531231168414642],
              [-2.932663075804823, 53.532455978743307],
              [-2.933068398048444, 53.532432141682754],
              [-2.933788216112347, 53.533399098908021],
              [-2.933585894021113, 53.534420897011451],
              [-2.934551813481528, 53.535050648695176],
              [-2.934605479927574, 53.535843032661347],
              [-2.935222224077853, 53.536031466437223],
              [-2.935289889693497, 53.536568461398403],
              [-2.934750437507452, 53.536669758652991],
              [-2.935379529940133, 53.53714393575477],
              [-2.936385806473651, 53.537068642761263],
              [-2.936056100923202, 53.538401552398561],
              [-2.936747805874227, 53.538702649026852],
              [-2.937629986810809, 53.538541127629628],
              [-2.937893257374754, 53.538846476015635],
              [-2.938718695349289, 53.538507415465688],
              [-2.939103696494544, 53.539130904162668],
              [-2.939625916718562, 53.539000060110965],
              [-2.9412374486291, 53.539882657650253],
              [-2.941542528176994, 53.539690593733646],
              [-2.941425470566958, 53.540331511064259],
              [-2.941988071268367, 53.540999434192123],
              [-2.943643416394067, 53.541200310784738],
              [-2.943900302845187, 53.5409448014993],
              [-2.944700295779525, 53.541225222185325],
              [-2.945452477467136, 53.542812972692211],
              [-2.947850989512456, 53.544323857587969],
              [-2.950878723894366, 53.544273747875238],
              [-2.955014747977636, 53.546059195494649],
              [-2.956079570803108, 53.545895180547561],
              [-2.956521741766936, 53.546094788482243],
              [-2.95772425298231, 53.545612355955555],
              [-2.959804565673185, 53.545574100512951],
              [-2.960514924012515, 53.545953112322586],
              [-2.963201360235118, 53.546353968970188],
              [-2.963565138451058, 53.547020696428902],
              [-2.964406594196884, 53.547056158869495],
              [-2.965886434425824, 53.54788555595178],
              [-2.970201019863222, 53.547074896416547],
              [-2.970727268017591, 53.547713317792279],
              [-2.972549738099006, 53.547944797791736],
              [-2.97516559778065, 53.547234955299693],
              [-2.977283527692724, 53.546206431315824],
              [-2.978462178272666, 53.543980172384423],
              [-2.978379198131444, 53.5428689463251],
              [-2.976446087938522, 53.540642063528153],
              [-2.974365734084635, 53.539616344689613],
              [-2.972784139159665, 53.537107886717045],
              [-2.972820533258577, 53.536128717911097],
              [-2.970475474314249, 53.534136999277628],
              [-2.966824083294632, 53.527377395818512],
              [-2.963249642635379, 53.524821092420169],
              [-2.962999101034857, 53.522886936410934],
              [-2.964171392786889, 53.51964513305299],
              [-2.964144414346836, 53.518590071593344],
              [-2.968144894741303, 53.517890768880157],
              [-2.971184930056445, 53.516285882039114],
              [-2.972455052847851, 53.516033757017333],
              [-2.972944942571239, 53.515430219778239],
              [-2.975995324103325, 53.515275012132207],
              [-2.977112127229727, 53.516423639790894],
              [-2.980585482791961, 53.518777216022855],
              [-2.982533450602499, 53.519529757989083],
              [-2.983444589412687, 53.52070788235568],
              [-2.984486755220046, 53.521289864640828],
              [-2.985956446262238, 53.521574379579661],
              [-2.988167445654967, 53.521370053588697],
              [-2.989597388446768, 53.521630582803951],
              [-2.990325219463738, 53.522764330023698],
              [-2.991524793985064, 53.522975508827244],
              [-2.992923432986113, 53.523958055932638],
              [-2.994771508844376, 53.523968757309426],
              [-2.994980393915486, 53.524964771619473],
              [-2.99630633936207, 53.525157772058783],
              [-2.996608920832425, 53.525902215978888],
              [-2.999561559955402, 53.5265643034801],
              [-3.000890773103468, 53.527340596868868],
              [-3.001203703160926, 53.528263819018839],
              [-3.004670304171112, 53.529214508855091],
              [-3.005554763001319, 53.529040779810039],
              [-3.008203228691689, 53.529394201858075],
              [-3.009318130703554, 53.530537153440868],
              [-3.00914946413735, 53.531691836194781],
              [-3.010211272272265, 53.531931856608196],
              [-3.010803774972884, 53.532484151539201],
              [-3.012333934230704, 53.531864461387819],
              [-3.013852057475062, 53.532384629451542],
              [-3.014075431334864, 53.534597566968351],
              [-3.01558795438516, 53.534815738179248],
              [-3.01650202710203, 53.53574909303137],
              [-3.018557646084341, 53.53545833778923],
              [-3.019636947507112, 53.536482845290912],
              [-3.02392954137139, 53.537914021897841],
              [-3.02509327053434, 53.538989892189939],
              [-3.027074163648663, 53.537976934628361],
              [-3.02813838662859, 53.538990709028361],
              [-3.029354099072794, 53.538969461093544],
              [-3.031069737578106, 53.538222091658376],
              [-3.031869794997439, 53.537242603244096],
              [-3.034315189590768, 53.537963045071869],
              [-3.035489901160969, 53.539107034880892],
              [-3.036750876520759, 53.538529813205123],
              [-3.038686852955936, 53.538282895651996],
              [-3.039268990492331, 53.538701207962646],
              [-3.038708716161501, 53.540100236804996],
              [-3.039632473923298, 53.541218501100758],
              [-3.040590857537326, 53.541716231300164],
              [-3.041191028806059, 53.541698421624801],
              [-3.042139049195977, 53.541343194703877],
              [-3.042996304671118, 53.540365829585113],
              [-3.04388887692576, 53.54033466841485],
              [-3.044616302232628, 53.54082809081617],
              [-3.044087636748938, 53.541665968936485],
              [-3.044272040812015, 53.542229752587176],
              [-3.045069172820961, 53.542733349876357],
              [-3.04670113490424, 53.542941994947732],
              [-3.045727685368682, 53.543428716553095],
              [-3.040621597753087, 53.548812598985535],
              [-3.038811821376009, 53.551889010511431],
              [-3.038702053166634, 53.553138502834635],
              [-3.040165728904272, 53.556132518185109],
              [-3.039281616411032, 53.559170314316624],
              [-3.04098975133578, 53.562456124782805],
              [-3.040591380197305, 53.564986328205663],
              [-3.039756980104605, 53.56568661804458],
              [-3.032177398485636, 53.568981032998913],
              [-3.022622802905999, 53.569581766774554],
              [-3.022895362846409, 53.573616269219798],
              [-3.021583181536165, 53.573999617886471],
              [-3.020771260824458, 53.582798404593433],
              [-3.020711946186865, 53.583659129344277],
              [-3.029553518144399, 53.584664691415398],
              [-3.029550993602003, 53.585306506634687],
              [-3.031325815102154, 53.585486271478054],
              [-3.031557678991754, 53.584896410680919],
              [-3.033347857453577, 53.5850867995062],
              [-3.033026154072837, 53.586575411472793],
              [-3.035425542662021, 53.58677938275887],
              [-3.03598879608256, 53.587202368049553],
              [-3.03369665044401, 53.588050957333429],
              [-3.032374597853909, 53.589283040792452],
              [-3.032803459736466, 53.589460910356323],
              [-3.032282107350016, 53.590198888646469],
              [-3.032648162541598, 53.590774601259156],
              [-3.031972825668689, 53.592944008690914],
              [-3.031423721888048, 53.593289416101911],
              [-3.030988827275642, 53.595719213036396],
              [-3.031046819932129, 53.596301180281678],
              [-3.032375150128405, 53.596927022076542],
              [-3.031978759055538, 53.597402348454239],
              [-3.032150916902999, 53.598513662685065],
              [-3.030890361861073, 53.598749249321479],
              [-3.030243883968851, 53.599443351809988],
              [-3.030111948550804, 53.600728071856835],
              [-3.028080313348226, 53.603066425367274],
              [-3.026004521530007, 53.603841991137784],
              [-3.024686087146509, 53.605252827179768],
              [-3.021818535971597, 53.606231051094788],
              [-3.020631087583053, 53.607128368414919],
              [-3.020028037706243, 53.608946524754224],
              [-3.014101155739747, 53.611261151197581],
              [-3.011784726262875, 53.612936472151496],
              [-3.011888554969651, 53.613927940830557],
              [-3.011349456499479, 53.614899677245681],
              [-3.008937878475423, 53.615894406084678],
              [-3.008083852795872, 53.616659346960802],
              [-3.006837785750214, 53.61696017189194],
              [-3.005370407824857, 53.620416955499891],
              [-3.00176646077867, 53.622474117904645],
              [-3.00175391619147, 53.623476454708126],
              [-3.000399170639684, 53.624242832914732],
              [-3.000735217314419, 53.624961809391152],
              [-2.997335811599557, 53.626869710476207],
              [-2.993722706910057, 53.628255251977663],
              [-2.992507903198783, 53.627587592432832],
              [-2.992925277419429, 53.6272668319722],
              [-2.981824923665581, 53.62218907503469],
              [-2.967770174556472, 53.631047676132908],
              [-2.966512156866187, 53.631848838657326],
              [-2.96234002194711, 53.637306169586537],
              [-2.96035301990309, 53.638720752412972],
              [-2.957913191131393, 53.640783406801077],
              [-2.95877803896063, 53.642501387080586],
              [-2.949968211054456, 53.652318819878509],
              [-2.948161868690016, 53.653742545130029],
              [-2.942355278527566, 53.656274627502185],
              [-2.94137216309919, 53.657855355467547],
              [-2.940144774575731, 53.658594869811608],
              [-2.941986281890512, 53.663136675105299],
              [-2.944959367702876, 53.666147767797426],
              [-2.947439351880958, 53.667333526973302],
              [-2.94397339661769, 53.672954456724121],
              [-2.943749703235226, 53.676287359891518],
              [-2.94422139487341, 53.677747867714352],
              [-2.94510835869734, 53.678550733491946],
              [-2.944843652780276, 53.678764052399366],
              [-2.944445291750653, 53.679026064089228],
              [-2.944400990838646, 53.679278990320213],
              [-2.946194412829255, 53.679016272143102],
              [-2.947803078788006, 53.679220647972194],
              [-2.950147609926615, 53.680269899693087],
              [-2.952289637955864, 53.681733288673136],
              [-2.95237212116208, 53.682771703192813],
              [-2.949205928130146, 53.686800319728022],
              [-2.946787408545762, 53.688002352028654],
              [-2.950328963294587, 53.691549917584155],
              [-2.949217335785247, 53.691884117414645],
              [-2.947969533149108, 53.691685469091446],
              [-2.947911532454757, 53.691867495568609],
              [-2.949273245734386, 53.692080521996054],
              [-2.950485697904065, 53.691717657139407],
              [-2.955215690269436, 53.696487061792702],
              [-2.952818153014194, 53.695885956393667],
              [-2.954753547714883, 53.696879948325197],
              [-2.955463456620306, 53.696726875393665],
              [-2.956217984313666, 53.697473188911388],
              [-2.955751615898636, 53.697947009281371],
              [-2.955974864400197, 53.698507906170192],
              [-2.954792009774712, 53.699114177407282],
              [-2.953151858561254, 53.699527229619946],
              [-2.952496927913979, 53.698897854027166],
              [-2.952721287985973, 53.699777838753093],
              [-2.947384248709078, 53.698463817159187],
              [-2.945224740875721, 53.697602707571491],
              [-2.943887368241544, 53.697536853812387],
              [-2.941530714548633, 53.696523528782642],
              [-2.943581884341747, 53.697625550472907],
              [-2.945463975510328, 53.697869574383496],
              [-2.946975967709137, 53.698628838789929],
              [-2.951129066896714, 53.699698812294379],
              [-2.950831707971343, 53.700147900626796],
              [-2.949955398008251, 53.700313950792747],
              [-2.944979917995091, 53.700263428923286],
              [-2.942923563584623, 53.699817629964919],
              [-2.942599153136989, 53.700144667382595],
              [-2.946327772312934, 53.700522440016961],
              [-2.945784979234565, 53.700769416464986],
              [-2.946074943957377, 53.700933412831311],
              [-2.947563047173129, 53.700556716219538],
              [-2.951444640160291, 53.700863009321786],
              [-2.951715046675329, 53.701501738772023],
              [-2.953647443356163, 53.702352855645778],
              [-2.950756090718987, 53.703378058130397],
              [-2.947319725831729, 53.704154926479731],
              [-2.95357871299682, 53.703399660850259],
              [-2.954674864581628, 53.703708221149405],
              [-2.955107636379739, 53.704422949372301],
              [-2.954569273787134, 53.705066321584518],
              [-2.951437462785875, 53.705982897602567],
              [-2.94731297081005, 53.706483890366542],
              [-2.943783775225975, 53.70813596666892],
              [-2.93712037864338, 53.704504820536854],
              [-2.934752286952543, 53.704625794876939],
              [-2.933466103375864, 53.705102327058398],
              [-2.931042950245073, 53.70513287369689],
              [-2.928844336438932, 53.70449109186282],
              [-2.929868616795302, 53.705257049130473],
              [-2.933229851958969, 53.705310902556249],
              [-2.935696552249346, 53.704853019971743],
              [-2.935812285574501, 53.705084916672462],
              [-2.93440999116383, 53.705517423874163],
              [-2.93661453481922, 53.704919549866297],
              [-2.938028191601709, 53.705408244329192],
              [-2.939762595186312, 53.706828306281579],
              [-2.940127664761595, 53.708049668540035],
              [-2.939103050266573, 53.708708470622938],
              [-2.937817621598789, 53.70847315783076],
              [-2.939164555038373, 53.708885060834113],
              [-2.940287587973629, 53.708359413575657],
              [-2.942283274449188, 53.709327066118426],
              [-2.942380793691996, 53.711114101730928],
              [-2.94032241625342, 53.711415213043701],
              [-2.943147678975213, 53.711395695857746],
              [-2.943698355906646, 53.710275891623233],
              [-2.946748458294332, 53.710286875937072],
              [-2.94442686930729, 53.711550104909762],
              [-2.944289294529452, 53.712442843813314],
              [-2.941402950639794, 53.713258345246679],
              [-2.945492644037993, 53.712389307438357],
              [-2.944868816486048, 53.712340299496248],
              [-2.944619347994785, 53.711687908044581],
              [-2.946413947901886, 53.711111064225349],
              [-2.947583465637916, 53.711104513968472],
              [-2.94824532251784, 53.711767118035844],
              [-2.948134772693137, 53.712645266138793],
              [-2.946737325614251, 53.713029340656519],
              [-2.948281381904186, 53.713976193924736],
              [-2.946024990145496, 53.715045684763346],
              [-2.946280247799842, 53.716089024724248],
              [-2.943888070226026, 53.718071873749217],
              [-2.941588660551343, 53.721978368167754],
              [-2.939685270731446, 53.723408992217891],
              [-2.939848418351534, 53.724200491742799],
              [-2.93878314487144, 53.724344570044877],
              [-2.938285250123054, 53.724921932503378],
              [-2.935904540760556, 53.725413346991608],
              [-2.93301047175509, 53.725174359308468],
              [-2.933676486686571, 53.723015543868236],
              [-2.93231311645197, 53.718727896579864],
              [-2.933350354730394, 53.723276053174033],
              [-2.932871607804628, 53.724515690596512],
              [-2.932148193499797, 53.724905127539238],
              [-2.931106437518955, 53.724880870949733],
              [-2.928416196559413, 53.722058713586996],
              [-2.927803217011969, 53.721131363417967],
              [-2.928963330637552, 53.720823057720501],
              [-2.928727138220809, 53.719870318401],
              [-2.926568086627969, 53.718580121472513],
              [-2.928256472561063, 53.71611500582474],
              [-2.92678543239785, 53.717300285646303],
              [-2.926384146655206, 53.718346946232387],
              [-2.926576589341662, 53.719035768903758],
              [-2.928076668886793, 53.719785475605853],
              [-2.928410217753733, 53.720342869791246],
              [-2.927179868044715, 53.720695757130621],
              [-2.926628634628079, 53.720300037682776],
              [-2.925217807503173, 53.720026010043711],
              [-2.923385887469177, 53.718860864555488],
              [-2.923656768278549, 53.71829070769558],
              [-2.92273857272773, 53.716288871326583],
              [-2.923204833401029, 53.718343628057696],
              [-2.922783022624245, 53.718457435928265],
              [-2.921610211187294, 53.717491221239854],
              [-2.919874748952945, 53.71469567851765],
              [-2.919355659103276, 53.714724831473376],
              [-2.920950017620008, 53.717305744369675],
              [-2.917375701110618, 53.716068501218182],
              [-2.916731962639437, 53.714199343324857],
              [-2.915508377162729, 53.713325139676378],
              [-2.915285061562292, 53.713716944025116],
              [-2.916407975037361, 53.714211709815423],
              [-2.917065960394951, 53.716109523273126],
              [-2.919504539374237, 53.717034612742296],
              [-2.917065273832923, 53.717261844233981],
              [-2.915476844696854, 53.716477620797882],
              [-2.915803675004195, 53.716874208318373],
              [-2.917408445730668, 53.717433591760859],
              [-2.920002286820282, 53.717345385586526],
              [-2.920788896375319, 53.717560456593603],
              [-2.923118424761756, 53.719587392932851],
              [-2.924817747773965, 53.720509980009304],
              [-2.926646881579677, 53.720992005133759],
              [-2.927597657013961, 53.722119884198975],
              [-2.927104269773573, 53.722425714731621],
              [-2.928001780402219, 53.722784593890772],
              [-2.929350330940492, 53.724146671141888],
              [-2.928767639064979, 53.725214520257438],
              [-2.923630818176412, 53.725472654841774],
              [-2.921177499004736, 53.72597961605171],
              [-2.920025314337031, 53.725965999409695],
              [-2.919532186815319, 53.725663280846781],
              [-2.919218038925956, 53.726061181870499],
              [-2.917458731380435, 53.726216685521763],
              [-2.915095978267577, 53.726083752407135],
              [-2.913970600400557, 53.725000252365717],
              [-2.913814077420038, 53.722605136601111],
              [-2.91105520678558, 53.719982659279246],
              [-2.910581163306124, 53.718312621166554],
              [-2.911336762466817, 53.716744688023461],
              [-2.910690278186757, 53.716910497600374],
              [-2.91035657768228, 53.718228039447126],
              [-2.909652795037716, 53.717355218002218],
              [-2.910518243115386, 53.718767014388874],
              [-2.910564484669435, 53.719925269351535],
              [-2.909198210644338, 53.719814303875303],
              [-2.908628816064346, 53.719481558917956],
              [-2.908554492048433, 53.718494296168622],
              [-2.906987372404468, 53.717005107471955],
              [-2.905991033603912, 53.715065756964435],
              [-2.90403949987154, 53.715070614885114],
              [-2.904431153430713, 53.714398021269574],
              [-2.903906944376208, 53.714330071030176],
              [-2.903739748799693, 53.715153772623381],
              [-2.905115334081425, 53.715141588206805],
              [-2.906071568186535, 53.715851634155321],
              [-2.90688348188375, 53.717529019754529],
              [-2.908211456040778, 53.718749471979152],
              [-2.908118798842035, 53.719653509845656],
              [-2.909154001467711, 53.720223612133879],
              [-2.910719505422149, 53.720430314621851],
              [-2.913216060642157, 53.722773286146762],
              [-2.913240056770869, 53.723530826143197],
              [-2.912282249425679, 53.724091810212997],
              [-2.908550657278962, 53.723236610114611],
              [-2.90759869498984, 53.7225939647643],
              [-2.906339731341607, 53.720852555233279],
              [-2.903732438818645, 53.719759489552594],
              [-2.90182869014918, 53.719387335945044],
              [-2.901763329969576, 53.717749241252164],
              [-2.900084505193605, 53.716693146430615],
              [-2.900412523257824, 53.715804423870942],
              [-2.897860734258817, 53.713169301242402],
              [-2.895981011826089, 53.710061700991432],
              [-2.895767466339618, 53.710208011763605],
              [-2.894711857732584, 53.708870336022372],
              [-2.895840924687226, 53.710670365751646],
              [-2.897102436809642, 53.712566467583002],
              [-2.897729401931065, 53.713476790523821],
              [-2.899925355312942, 53.715839544457786],
              [-2.899527977387643, 53.716247906640298],
              [-2.898417389049296, 53.717177553602269],
              [-2.896014151654767, 53.716396485505371],
              [-2.898228819958483, 53.717501651617859],
              [-2.899529801266433, 53.716830341860053],
              [-2.900226970771403, 53.717261940939252],
              [-2.901058219651475, 53.718364860380767],
              [-2.900613750999931, 53.719619389188018],
              [-2.901086020061616, 53.720019416879261],
              [-2.90210213822208, 53.720126820442488],
              [-2.903677283854422, 53.720367521979767],
              [-2.905546297100572, 53.721236069028642],
              [-2.906463626847652, 53.722173812414908],
              [-2.906872833144626, 53.723512684097322],
              [-2.912077546084696, 53.724787273985591],
              [-2.912128309561285, 53.726012007141378],
              [-2.91281830402858, 53.726938848979877],
              [-2.907493955342854, 53.728403063602251],
              [-2.904784715032069, 53.729032070515821],
              [-2.904303076576715, 53.728908071966401],
              [-2.904089283030373, 53.727633333482316],
              [-2.904972752446436, 53.727084609375211],
              [-2.905468943027095, 53.726776414822353],
              [-2.906154867743043, 53.726350362680968],
              [-2.905607235354333, 53.724212567944328],
              [-2.904587363397862, 53.723935341356579],
              [-2.904002965917612, 53.723751895338005],
              [-2.903069726670875, 53.722495165065169],
              [-2.900131082320154, 53.721569905427572],
              [-2.899066158629179, 53.721520377159848],
              [-2.9029578147466, 53.722576904323567],
              [-2.903837033246629, 53.723788202202527],
              [-2.904670357472629, 53.724059653396679],
              [-2.905438243342822, 53.724318110846973],
              [-2.905951352485777, 53.726048992878475],
              [-2.905329565302436, 53.726707013564344],
              [-2.904717080306454, 53.727011989864913],
              [-2.902965590895024, 53.726192879102825],
              [-2.901785650377133, 53.726227833618317],
              [-2.900824031986477, 53.725486335203726],
              [-2.897673926632696, 53.724958094595841],
              [-2.89548815357012, 53.72401808875123],
              [-2.896278537199062, 53.72298570280924],
              [-2.895188266361636, 53.723884605391831],
              [-2.894684893126834, 53.723874883412364],
              [-2.895521567232512, 53.724663205709838],
              [-2.897377723104768, 53.725354005553207],
              [-2.899866412228904, 53.725711051701715],
              [-2.900566117307801, 53.726325094084807],
              [-2.900089980353477, 53.726740341036475],
              [-2.8959272687508, 53.727919363152836],
              [-2.894480253557712, 53.729227200645063],
              [-2.894579572671197, 53.730049795212906],
              [-2.889389642029582, 53.730629538305742],
              [-2.890217392070852, 53.729989709317316],
              [-2.891101764651915, 53.727871762758454],
              [-2.89114464106529, 53.725671086437174],
              [-2.890390855777079, 53.729160590092974],
              [-2.888781995748008, 53.730710449146734],
              [-2.887697737352888, 53.730760733620173],
              [-2.886642193609844, 53.730302952338469],
              [-2.886782225166418, 53.727234181144972],
              [-2.886123800511114, 53.726844463260974],
              [-2.886002095084063, 53.727976102075438],
              [-2.884583357280323, 53.729490342563835],
              [-2.884145615799748, 53.730949691229128],
              [-2.883193007516249, 53.73070054913677],
              [-2.881477887946199, 53.731099681262855],
              [-2.88094717444782, 53.730946288473341],
              [-2.879013873121609, 53.731787419631793],
              [-2.877135972735174, 53.732089710275503],
              [-2.876654194135152, 53.731738196076748],
              [-2.875609390580857, 53.731865382275181],
              [-2.874252126345967, 53.730741861299371],
              [-2.874307204679455, 53.731131554588998],
              [-2.873447814549383, 53.731062321080564],
              [-2.874748632727564, 53.731512135585774],
              [-2.874842816811958, 53.73276262870781],
              [-2.871018546599311, 53.73307003210904],
              [-2.869360334377644, 53.732784561755267],
              [-2.86850442917016, 53.731635764959137],
              [-2.868132700627892, 53.732133718105729],
              [-2.868329733788428, 53.733308866839977],
              [-2.864670525633854, 53.733907891309585],
              [-2.859796693686137, 53.733454900880396],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000128",
        LAD13CDO: "30UQ",
        LAD13NM: "Wyre",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-2.94221562212087, 53.851167321983219],
              [-2.942840470022897, 53.851034779553778],
              [-2.944035653699445, 53.851455018158539],
              [-2.943733625715951, 53.852152185821943],
              [-2.940148910588048, 53.853911467571898],
              [-2.937739332112189, 53.854457037412395],
              [-2.938077817026358, 53.853073797958636],
              [-2.939494766498858, 53.852127932669937],
              [-2.94221562212087, 53.851167321983219],
            ],
          ],
          [
            [
              [-3.007974553973636, 53.928651637536397],
              [-3.006815257753837, 53.927943208444908],
              [-3.006095962460131, 53.928050804383268],
              [-3.005883777562507, 53.927713727687838],
              [-3.006539761216604, 53.927506894920874],
              [-3.005957606527096, 53.927554018297656],
              [-3.004891764380005, 53.926422343077078],
              [-3.003953372386329, 53.92399978803715],
              [-3.005714043260025, 53.921415328718339],
              [-3.008071152881984, 53.920591116824582],
              [-3.012521319485624, 53.919808546196194],
              [-3.0106463129265, 53.918964174219667],
              [-3.010068454759494, 53.919058020545833],
              [-3.009361272426091, 53.918398841517643],
              [-3.006498693308077, 53.918965761844788],
              [-3.005723424963543, 53.918638796863277],
              [-3.005901025863327, 53.91494315900119],
              [-3.007760069925355, 53.909320723550962],
              [-3.007561177765613, 53.908520644576832],
              [-3.007243363301341, 53.910495325252775],
              [-3.006298319585387, 53.912407852259435],
              [-3.005941614934674, 53.90314219709645],
              [-3.006705863782286, 53.901883730676545],
              [-3.007908480530224, 53.901956329919003],
              [-3.006940141281054, 53.901562683667152],
              [-3.0078382943882, 53.901316957770241],
              [-3.008608799754611, 53.901648442956898],
              [-3.008367246812273, 53.902235605783332],
              [-3.007968044174788, 53.902154469465088],
              [-3.008430064302328, 53.907559698881087],
              [-3.009408277596157, 53.907346545745824],
              [-3.008612257151531, 53.90665575130577],
              [-3.009477297431973, 53.906295242780793],
              [-3.010831481205469, 53.906650566183743],
              [-3.010277063587779, 53.905816632843482],
              [-3.008617297534214, 53.906233262767941],
              [-3.008497251498061, 53.903272653207686],
              [-3.009152521437738, 53.901411077873071],
              [-3.010239816522382, 53.902444565626901],
              [-3.010193033181179, 53.902839542382274],
              [-3.009877473478646, 53.90275591077252],
              [-3.010265210696748, 53.903938194536913],
              [-3.011691532919515, 53.904825003676407],
              [-3.01335990101978, 53.905207311230136],
              [-3.015068580966484, 53.904426178589276],
              [-3.014806927878433, 53.904181214771405],
              [-3.01311905649315, 53.904815660082015],
              [-3.01210259868427, 53.904577058749638],
              [-3.010829608884372, 53.903610765773209],
              [-3.010754270911985, 53.902381811203355],
              [-3.009781771658651, 53.900933903995444],
              [-3.008347186006716, 53.900075904028306],
              [-3.007899384549289, 53.900693560882296],
              [-3.006505136531882, 53.90049853126375],
              [-3.00595830335743, 53.9007332139902],
              [-3.005109723732311, 53.898035766233946],
              [-3.005395250491846, 53.897694518724585],
              [-3.004553552316972, 53.897119129187224],
              [-3.00290734532993, 53.894595519318827],
              [-3.003246160153423, 53.894255629062272],
              [-3.00222627201721, 53.89386057839917],
              [-3.002982423992749, 53.893727527743536],
              [-3.002686417975233, 53.893567314065244],
              [-3.003269682866353, 53.893268524697731],
              [-3.003131293664846, 53.893018010960134],
              [-3.001935703214015, 53.893609536356252],
              [-3.001116095097308, 53.893316168843626],
              [-3.000432062529041, 53.892327774384306],
              [-3.000417747789148, 53.890900562541781],
              [-2.999996215977097, 53.890898682616736],
              [-2.995485689177559, 53.886910351507908],
              [-2.99274811916147, 53.885094035785393],
              [-2.992126647688821, 53.885127038714472],
              [-2.988763841572905, 53.883623197588442],
              [-2.981158419122611, 53.881730679925511],
              [-2.981977763328239, 53.881444444134203],
              [-2.981793907488043, 53.881258993263401],
              [-2.982959243265934, 53.880288611421108],
              [-2.980657225733264, 53.881376146295914],
              [-2.975894340517628, 53.879882480941006],
              [-2.970515835571047, 53.876489002577877],
              [-2.969386056695404, 53.875388088482595],
              [-2.968816253089418, 53.873966258161175],
              [-2.968953268095941, 53.873511246125176],
              [-2.972246178341358, 53.871806506629419],
              [-2.977656367226455, 53.87149290019515],
              [-2.978996612895159, 53.870790776673218],
              [-2.979114436910265, 53.8678920046044],
              [-2.977687110666459, 53.865900162851048],
              [-2.977176179785284, 53.862770121567273],
              [-2.976734518804661, 53.862414187739276],
              [-2.978536536399734, 53.861768528403282],
              [-2.978846348963813, 53.861202671085351],
              [-2.97878584649801, 53.86091745851725],
              [-2.979055619604193, 53.859996503529942],
              [-2.979372445294765, 53.859753131128571],
              [-2.979270237196685, 53.859263805895125],
              [-2.981690176065495, 53.8587820322224],
              [-2.982581278647968, 53.857749176263276],
              [-2.982208922123283, 53.857625490784109],
              [-2.981822231662836, 53.858058295106567],
              [-2.981010112769551, 53.857529237969395],
              [-2.980788743191298, 53.857360270877123],
              [-2.978052395549661, 53.858092682695833],
              [-2.977730727551195, 53.857925426465791],
              [-2.977715708666837, 53.857607364377763],
              [-2.978699365756686, 53.857344976078394],
              [-2.978567050164191, 53.856822039619779],
              [-2.979661144224497, 53.855235669599921],
              [-2.97950639388101, 53.854468436277628],
              [-2.977810116041425, 53.85278888429437],
              [-2.974048853113164, 53.852190304032511],
              [-2.972195992521485, 53.850955964978141],
              [-2.97190962484434, 53.849881490864476],
              [-2.968230672094214, 53.849014211961581],
              [-2.965510425569705, 53.847196238929392],
              [-2.965591200084749, 53.844630336533577],
              [-2.967913277069536, 53.842176698173134],
              [-2.970787902146244, 53.840495129716928],
              [-2.969275485790666, 53.838708805543803],
              [-2.968606526562361, 53.834812394680498],
              [-2.967324492076906, 53.83252623331321],
              [-2.965078533086092, 53.829876601753142],
              [-2.961782514110832, 53.828331925444026],
              [-2.963465488893466, 53.827584073462674],
              [-2.966651878237387, 53.826875322500683],
              [-2.970327300010746, 53.82918709380165],
              [-2.986047041143807, 53.828424306636109],
              [-2.986034791688418, 53.829002355786031],
              [-2.986637533342568, 53.829241880858341],
              [-2.987199212265411, 53.830190019826382],
              [-2.991981337435833, 53.830662895872884],
              [-2.992013736264824, 53.831005082639926],
              [-2.993123135840436, 53.831013878026347],
              [-2.993006326956957, 53.829480539813481],
              [-2.993963713736827, 53.829487889296246],
              [-3.002424231009511, 53.828684924695239],
              [-3.004959665193786, 53.827273211695797],
              [-3.007184329136973, 53.826973214658004],
              [-3.009117112689572, 53.825339965994949],
              [-3.010658072057928, 53.826179084175074],
              [-3.010048774670433, 53.826428699975111],
              [-3.013778482765985, 53.829191703893486],
              [-3.012119064347988, 53.829496042399519],
              [-3.010836787444741, 53.83050905551621],
              [-3.0088124517127, 53.830363411245791],
              [-3.00720771639461, 53.832886438815777],
              [-3.007645369309771, 53.834026976184802],
              [-3.009854652637481, 53.835031266166794],
              [-3.010195966290091, 53.836458433882932],
              [-3.011541431174332, 53.83717604723266],
              [-3.012611827815777, 53.837765637473289],
              [-3.013283214264992, 53.839011141682604],
              [-3.015561044873471, 53.840696062816932],
              [-3.017340372963456, 53.840368191137401],
              [-3.017361785120101, 53.839308286434793],
              [-3.016494296471631, 53.837500898784938],
              [-3.019116038312707, 53.837075075722851],
              [-3.01936466966735, 53.838927254546704],
              [-3.020004594366602, 53.838994619859292],
              [-3.020469875754556, 53.840754171439919],
              [-3.015235596170921, 53.842759838885513],
              [-3.01781897173277, 53.845186363281968],
              [-3.0186354250314, 53.847011251126048],
              [-3.017943038568152, 53.847355089143107],
              [-3.017380656234537, 53.847607038656179],
              [-3.018022686145367, 53.848256839594512],
              [-3.015230187750637, 53.849004962986186],
              [-3.015088300622427, 53.849925667350405],
              [-3.014349892795001, 53.850192574166904],
              [-3.014019701260791, 53.850305022994739],
              [-3.020062562364033, 53.85931669597894],
              [-3.020636853397055, 53.859423266626052],
              [-3.021730403255713, 53.86043502903415],
              [-3.022624956444829, 53.861701947734034],
              [-3.022844213591563, 53.862711261697669],
              [-3.022778231549738, 53.86430724592001],
              [-3.021427841357762, 53.865490822563743],
              [-3.019755849309903, 53.868585329692444],
              [-3.026902639670991, 53.869227260729929],
              [-3.027148861528229, 53.870151845502079],
              [-3.031327734418669, 53.874706317415438],
              [-3.042879425767669, 53.875418119766863],
              [-3.047956614746288, 53.875718138301387],
              [-3.046867378224135, 53.887522074520987],
              [-3.047221865886473, 53.891658981010032],
              [-3.047718085359344, 53.892577745639983],
              [-3.048168376364702, 53.897496687958039],
              [-3.050773376406283, 53.909060673463493],
              [-3.051077125233408, 53.916123673100323],
              [-3.050886432632698, 53.9181090465447],
              [-3.050172334172189, 53.919201074018822],
              [-3.048574845317837, 53.921135822284263],
              [-3.044778293254646, 53.923192170466763],
              [-3.042390133740753, 53.923969736901377],
              [-3.038656067176395, 53.924456003429789],
              [-3.03377526733841, 53.925622553149367],
              [-3.031607551031869, 53.926522042449925],
              [-3.028508479622438, 53.926524361766667],
              [-3.028060474460022, 53.926141705879843],
              [-3.025827582858259, 53.926004409688751],
              [-3.023407287324746, 53.926236286989237],
              [-3.01824116738884, 53.927156559910451],
              [-3.013961418034687, 53.928651562104776],
              [-3.013333569609956, 53.928447437795931],
              [-3.009393456427047, 53.928882398384417],
              [-3.007974553973636, 53.928651637536397],
            ],
          ],
          [
            [
              [-2.714861451463145, 53.978553301964787],
              [-2.712505480007095, 53.976129780612119],
              [-2.711042186048919, 53.973199407131339],
              [-2.710406299544006, 53.969696987333052],
              [-2.711831385704328, 53.965746452124186],
              [-2.710516293572288, 53.963618709833838],
              [-2.709335557651689, 53.960419697964426],
              [-2.706376739817856, 53.958315986818512],
              [-2.706503265662134, 53.955884907670139],
              [-2.703375721630084, 53.953507084189134],
              [-2.700553793338049, 53.949159938632889],
              [-2.701589821631691, 53.946341565175963],
              [-2.702645283757573, 53.944868562271139],
              [-2.702051698567683, 53.943543616325087],
              [-2.700332277923658, 53.946034327930128],
              [-2.699005925633152, 53.947077466161524],
              [-2.696834436609941, 53.947331861980189],
              [-2.688856465810148, 53.949165590485151],
              [-2.687918876746386, 53.949689568390824],
              [-2.684581507872161, 53.949384175879111],
              [-2.679876647354945, 53.950247679960306],
              [-2.673650030179774, 53.950208170519993],
              [-2.66415594111527, 53.947254596579398],
              [-2.662672418742242, 53.947381432897323],
              [-2.655268757078362, 53.949302331618753],
              [-2.648218602369951, 53.948909175573675],
              [-2.641144214310827, 53.945041012034302],
              [-2.633957820168531, 53.942540084705605],
              [-2.63118949081381, 53.940608787016501],
              [-2.626437700932365, 53.938551173970325],
              [-2.627244261721723, 53.934742380476422],
              [-2.62964819441599, 53.930722085056402],
              [-2.622575350338974, 53.929702889758282],
              [-2.61937507299608, 53.927201926478467],
              [-2.617103438207636, 53.924347376387033],
              [-2.614271938810112, 53.919534482892828],
              [-2.618452929428316, 53.916953252016462],
              [-2.618642694412004, 53.916401313692532],
              [-2.614986155578112, 53.912499564222983],
              [-2.613402919267046, 53.908242886493078],
              [-2.616628747601109, 53.899791162606881],
              [-2.625145765298689, 53.89462755419779],
              [-2.628755520646362, 53.89383483164319],
              [-2.629922733296597, 53.893233706618538],
              [-2.633331281369549, 53.89303510841129],
              [-2.635965913275863, 53.893579329071798],
              [-2.637913584877849, 53.894573848125049],
              [-2.641076234138087, 53.894708892362054],
              [-2.642306335866526, 53.895042959577019],
              [-2.643909481860592, 53.894598451643084],
              [-2.64523238266821, 53.893744678363738],
              [-2.645542730831889, 53.894529457981569],
              [-2.64674580173998, 53.894293787004102],
              [-2.64713365338453, 53.894774350977634],
              [-2.649233116093459, 53.894938277469407],
              [-2.651604192563306, 53.895540205362153],
              [-2.652082573555801, 53.896064302987092],
              [-2.653246691925487, 53.895867426920205],
              [-2.657236647994482, 53.896153031991773],
              [-2.658056956355381, 53.894608894392043],
              [-2.662374632689103, 53.895127117712924],
              [-2.664263684236194, 53.894109122724288],
              [-2.665672117253786, 53.89425950750163],
              [-2.668180134773118, 53.892368876118788],
              [-2.670329431032001, 53.891655826112967],
              [-2.672209482173857, 53.892089317329976],
              [-2.678644275407796, 53.891799639810884],
              [-2.680929069502118, 53.892507535832607],
              [-2.682941541277209, 53.891254856432887],
              [-2.685990824872926, 53.890791659587407],
              [-2.687149880959105, 53.889933863786425],
              [-2.687562083404459, 53.888628240724572],
              [-2.689344265356622, 53.888104800840104],
              [-2.689374047556293, 53.885477431699208],
              [-2.687775949669922, 53.88451140140694],
              [-2.688173472733053, 53.883798169767871],
              [-2.689193607339713, 53.88320270074577],
              [-2.687241135948235, 53.882236001603488],
              [-2.688336060357844, 53.87999080821681],
              [-2.690753535785544, 53.878995423193246],
              [-2.690580018600562, 53.878066160598259],
              [-2.690034469099204, 53.877821229427681],
              [-2.690610432801091, 53.8774197459432],
              [-2.69082793098907, 53.874189991206201],
              [-2.69288191643629, 53.87404692227129],
              [-2.694364035817719, 53.872895974188204],
              [-2.697280343264071, 53.872480891344452],
              [-2.699922735725798, 53.871513671907074],
              [-2.70419802959804, 53.870525136601628],
              [-2.704906337068822, 53.870049104525215],
              [-2.705110343530514, 53.869158088434311],
              [-2.70608261952589, 53.869006764022537],
              [-2.703932554986131, 53.866400278369355],
              [-2.703263357169265, 53.864944542125144],
              [-2.704477554315749, 53.863178454893422],
              [-2.705814931596914, 53.862412900478226],
              [-2.705726527184436, 53.861406757177107],
              [-2.707300745104403, 53.861165594280429],
              [-2.707777238455843, 53.859758847671976],
              [-2.710941219167164, 53.857602775323905],
              [-2.711925374292866, 53.8563772569047],
              [-2.709818785559449, 53.85362770354952],
              [-2.714008702301598, 53.853370046564194],
              [-2.715031514035225, 53.85308532370464],
              [-2.716737353247455, 53.851494139223263],
              [-2.71775551555883, 53.851385587196816],
              [-2.719187034657168, 53.850383829240386],
              [-2.725870055685458, 53.849365670455413],
              [-2.726028068043831, 53.849712553506585],
              [-2.729016664322408, 53.851205326773673],
              [-2.731391123691264, 53.851553992000355],
              [-2.73425828163425, 53.850694291520462],
              [-2.735261243487692, 53.85093621970168],
              [-2.737789630517658, 53.850873058631102],
              [-2.738148569137361, 53.85053829068049],
              [-2.740367352673394, 53.850160598697705],
              [-2.745840002700612, 53.851030900189116],
              [-2.746482517177737, 53.850917248696021],
              [-2.74666311907295, 53.849620039543197],
              [-2.743500375564628, 53.846478566431337],
              [-2.742597172619911, 53.844742265281113],
              [-2.742016179989975, 53.844634408178536],
              [-2.74172900031998, 53.842576108493041],
              [-2.740351943738419, 53.841537500625513],
              [-2.739700488181198, 53.840102522338],
              [-2.740098801903978, 53.83993827907593],
              [-2.740096862002, 53.839052961253032],
              [-2.738293168314691, 53.833363809514246],
              [-2.737932357997698, 53.83030017901504],
              [-2.73599255108918, 53.825300315304496],
              [-2.736451164754974, 53.824943367126409],
              [-2.736160297188439, 53.823430651429007],
              [-2.743049794137744, 53.822927967335552],
              [-2.749428407415973, 53.823174097343802],
              [-2.749999384383558, 53.823502190436976],
              [-2.750921711981173, 53.823429007381321],
              [-2.751678833786173, 53.823886254935665],
              [-2.753569049627944, 53.823738670832107],
              [-2.754851486577377, 53.824345394850582],
              [-2.756345313664559, 53.825805545298209],
              [-2.758365356097751, 53.825944687836746],
              [-2.761412016994516, 53.827485726056601],
              [-2.767113424791207, 53.834692042740279],
              [-2.770692042680345, 53.840405332497085],
              [-2.774307774855284, 53.839841858058328],
              [-2.773594629077234, 53.839078872520268],
              [-2.780316573651084, 53.836806268811571],
              [-2.782208557224038, 53.835423251679728],
              [-2.78097570341318, 53.834091152244028],
              [-2.781777910919771, 53.833351593649887],
              [-2.783883092389066, 53.83346907989857],
              [-2.787036710280369, 53.832126268417014],
              [-2.791045852044609, 53.833862470728953],
              [-2.793387433798248, 53.836675577665829],
              [-2.794188380111745, 53.83443312378467],
              [-2.79770010880069, 53.833397751240184],
              [-2.800138223760797, 53.833122651093028],
              [-2.800273420190468, 53.831925423834413],
              [-2.800978460397459, 53.831767019559962],
              [-2.799611660522302, 53.829092272113407],
              [-2.797144775564524, 53.828560415867877],
              [-2.796394797777906, 53.827789719225912],
              [-2.798500311100797, 53.826020328090941],
              [-2.798634121679882, 53.825467562967532],
              [-2.797575730979127, 53.824694432804314],
              [-2.797868144278108, 53.824572944915531],
              [-2.798471860243852, 53.824364895108289],
              [-2.799914702235449, 53.824823563862672],
              [-2.802053289955985, 53.824880286609321],
              [-2.80198417762452, 53.825874841544824],
              [-2.803197320128033, 53.825837061671123],
              [-2.804275430363415, 53.825253693754213],
              [-2.809144445796531, 53.824147751329427],
              [-2.81030051941584, 53.824304429323604],
              [-2.811903334003221, 53.823916084741313],
              [-2.825997991641527, 53.819971983510783],
              [-2.836107297188267, 53.825246312347872],
              [-2.838677763679484, 53.829350353320073],
              [-2.840873574214512, 53.831262946645637],
              [-2.849400983881372, 53.831581259736275],
              [-2.85445784017376, 53.831215472061963],
              [-2.857496123363785, 53.830815383915066],
              [-2.862229879183699, 53.829373890720078],
              [-2.864930221208458, 53.829616003705553],
              [-2.868693288100407, 53.830623350016722],
              [-2.870127612799886, 53.831356278134699],
              [-2.870258491132877, 53.832465373441401],
              [-2.868627589865528, 53.833762510984094],
              [-2.864087157153713, 53.835676540635106],
              [-2.861095472316199, 53.83658429346243],
              [-2.858518307494019, 53.838595452265217],
              [-2.857590917040409, 53.842591946844315],
              [-2.86111026194633, 53.843746883168642],
              [-2.864098550650073, 53.844199071313732],
              [-2.866539906646697, 53.845174655262447],
              [-2.867001922505652, 53.845687239189878],
              [-2.867780013824353, 53.845534206345313],
              [-2.871619776014496, 53.843137975962442],
              [-2.878841365806526, 53.843317178477051],
              [-2.881360151208878, 53.843551266302953],
              [-2.881880346951384, 53.843859333791762],
              [-2.883749064091214, 53.843895914415711],
              [-2.890150428593095, 53.846024627549347],
              [-2.890697251902401, 53.847653726369742],
              [-2.88809618044322, 53.849147077451029],
              [-2.887658006996049, 53.849846906470006],
              [-2.887404529184344, 53.85079883493453],
              [-2.890125864062147, 53.852172743466497],
              [-2.888021404193081, 53.854230459083823],
              [-2.884274070429367, 53.855678274191142],
              [-2.875831198416011, 53.857035406829645],
              [-2.875565342807736, 53.85791908826566],
              [-2.877019898768716, 53.858987045849148],
              [-2.877208720430108, 53.859872798969633],
              [-2.878248878090974, 53.860678619563949],
              [-2.87723809441722, 53.861785268965129],
              [-2.874995846458567, 53.863195709067419],
              [-2.873334675237193, 53.865352390459016],
              [-2.868965495579351, 53.865851498866107],
              [-2.867084331907645, 53.86511279903651],
              [-2.866633975633645, 53.861350010034457],
              [-2.865850929835324, 53.859731501029295],
              [-2.865153100263735, 53.859218816502256],
              [-2.862449989166468, 53.858787072793547],
              [-2.855392237966536, 53.859014669040711],
              [-2.852464387008733, 53.858366789329764],
              [-2.850607879355591, 53.857613276130238],
              [-2.847600750953542, 53.857397272134278],
              [-2.845890791777111, 53.858023232614997],
              [-2.845362185276672, 53.858801738074341],
              [-2.846279971321514, 53.860205509784571],
              [-2.847916509298031, 53.861583523979959],
              [-2.84747446323264, 53.862808137847935],
              [-2.846874508584974, 53.863217740051567],
              [-2.843777002179491, 53.861703488819188],
              [-2.838473862995178, 53.863780079647128],
              [-2.836506946855379, 53.865036870134851],
              [-2.835367144785308, 53.864766179082601],
              [-2.832608766998942, 53.86277919919273],
              [-2.830570458616279, 53.863432384387593],
              [-2.828135946618447, 53.863430338177665],
              [-2.825743722949569, 53.864108353697567],
              [-2.824873210571956, 53.863221819633026],
              [-2.825361199730268, 53.864219742282351],
              [-2.82826174374051, 53.863563393109011],
              [-2.830917456664227, 53.863524357202493],
              [-2.8324274409744, 53.862989881213352],
              [-2.835413675258482, 53.864964493093623],
              [-2.836919170756339, 53.86511938273145],
              [-2.838769826720081, 53.86382834453763],
              [-2.843946172421508, 53.861873972692507],
              [-2.846006811397443, 53.863245433268489],
              [-2.84714887084477, 53.863325458599427],
              [-2.848243885545536, 53.861606376652169],
              [-2.846567010632254, 53.860189103625508],
              [-2.845675002122603, 53.858706955374039],
              [-2.846034870888152, 53.858156140257925],
              [-2.847667159505019, 53.8575235360411],
              [-2.849991984921838, 53.857681457354253],
              [-2.855164794562946, 53.859194256199366],
              [-2.861655482931709, 53.858968051081163],
              [-2.865028999211102, 53.859467784963407],
              [-2.866346941946066, 53.861513870162462],
              [-2.866558534817072, 53.864982674105448],
              [-2.868270664096822, 53.866077638094616],
              [-2.870058116769614, 53.866181535626701],
              [-2.873287676005913, 53.865651139820088],
              [-2.875323061936268, 53.863136696726521],
              [-2.876493202500425, 53.862738965914794],
              [-2.879153487218646, 53.860601886289714],
              [-2.884276084738391, 53.863126770394743],
              [-2.886632994214134, 53.863557886320827],
              [-2.889862907536685, 53.862920080772071],
              [-2.89098336644766, 53.863124785198956],
              [-2.890296556098584, 53.863219765531788],
              [-2.890596588464849, 53.863313712353545],
              [-2.892274631965288, 53.863209564947837],
              [-2.896294833938415, 53.860708745104283],
              [-2.898924979461554, 53.862679053560761],
              [-2.898437510394399, 53.862637763958197],
              [-2.898940794834362, 53.863203845549251],
              [-2.901198825727584, 53.864616028615636],
              [-2.904048071759019, 53.865088944015689],
              [-2.909415602511624, 53.864929735535668],
              [-2.912014631802022, 53.863891644305497],
              [-2.913662353144583, 53.860304498523],
              [-2.914931686426997, 53.859664751693352],
              [-2.924478811893666, 53.856635338647607],
              [-2.92650839348383, 53.856699676768933],
              [-2.929369217127008, 53.857637491272953],
              [-2.936684243224184, 53.857655203668642],
              [-2.937816437655143, 53.855049658186132],
              [-2.941325323908629, 53.85450894856826],
              [-2.944738681101136, 53.852296176316507],
              [-2.949210772092418, 53.853959652835989],
              [-2.950619313413666, 53.854932712586191],
              [-2.952656887593382, 53.857759531229242],
              [-2.954888840509859, 53.860763634036921],
              [-2.958540208806883, 53.86258609470206],
              [-2.96464002821946, 53.863676915523733],
              [-2.969888439747052, 53.863643591408469],
              [-2.972295127610748, 53.864607426466769],
              [-2.972268609709078, 53.865430964810557],
              [-2.970471137344322, 53.867466074321456],
              [-2.964289289407254, 53.87222664911755],
              [-2.964823280302717, 53.87533767656349],
              [-2.966427023248695, 53.877406444026583],
              [-2.964382156393242, 53.877848038290402],
              [-2.963352014140054, 53.877436562792738],
              [-2.962994053023528, 53.878076700880513],
              [-2.963339223244923, 53.877541827673795],
              [-2.964200283989645, 53.877942977301871],
              [-2.965868053757174, 53.877969113186268],
              [-2.966553285017473, 53.877670579702382],
              [-2.971436959157301, 53.881905943422517],
              [-2.981750858820181, 53.887471108914298],
              [-2.985187855229163, 53.889797857944195],
              [-2.987010648941454, 53.891797141444613],
              [-2.986772095595215, 53.892263793997614],
              [-2.98581211873852, 53.892465828471664],
              [-2.985563261055805, 53.893077273439467],
              [-2.984850318176403, 53.89317300797024],
              [-2.985374889893296, 53.893478795781398],
              [-2.985060535635476, 53.894152795955385],
              [-2.983979597031066, 53.894195818026731],
              [-2.984556073411321, 53.894767234977181],
              [-2.983849559660412, 53.895202665123122],
              [-2.98389042187693, 53.895581632717878],
              [-2.984817404899141, 53.894749810982859],
              [-2.984231922763294, 53.894248577196876],
              [-2.985237320172347, 53.894293358677807],
              [-2.986251800848661, 53.892591646530605],
              [-2.987950304848408, 53.892734072081211],
              [-2.987932116285139, 53.893059595339324],
              [-2.989728807305893, 53.894329209093961],
              [-2.989397786263595, 53.894681580023025],
              [-2.988325362241121, 53.894569973664751],
              [-2.987981010739496, 53.894873914021559],
              [-2.987341488629338, 53.894857604948463],
              [-2.986675205909818, 53.895513830942207],
              [-2.988544575338207, 53.894766807934779],
              [-2.990247271849351, 53.894889392691468],
              [-2.991227300900165, 53.895532948740737],
              [-2.992710732393706, 53.897188901685816],
              [-2.993038295107386, 53.898166806428328],
              [-2.992582926055901, 53.898088780307432],
              [-2.993068880896176, 53.89830137660821],
              [-2.993454772955217, 53.899299470384811],
              [-2.99304713012958, 53.899630014689677],
              [-2.993657488485523, 53.899889216857119],
              [-2.994742715465005, 53.901878307687838],
              [-2.994139175991334, 53.90229227152394],
              [-2.994820033595493, 53.90276210688134],
              [-2.994904811486616, 53.904984188304354],
              [-2.995425882741738, 53.904877402742017],
              [-2.995646274792871, 53.905440034323348],
              [-2.995424419118355, 53.905583887985024],
              [-2.995212910931727, 53.905202744152895],
              [-2.995138884029689, 53.905674339927103],
              [-2.994622512738311, 53.905595029378325],
              [-2.993618044157398, 53.90508832402967],
              [-2.992930788810472, 53.905504771935917],
              [-2.992294536120709, 53.905312292789347],
              [-2.992567812583524, 53.905860111515679],
              [-2.991669535168404, 53.906108419746815],
              [-2.991664850103397, 53.907131315873016],
              [-2.990045381084379, 53.906880430674015],
              [-2.989441176771821, 53.90714247500788],
              [-2.988591670359216, 53.906428620638387],
              [-2.989223318022778, 53.905717843129835],
              [-2.988297209833395, 53.905303925010067],
              [-2.98767790496866, 53.904474018447061],
              [-2.986828187196702, 53.904134961384273],
              [-2.98523659546575, 53.904027593777727],
              [-2.984950479160401, 53.904223188181277],
              [-2.987595492149301, 53.904722770905963],
              [-2.988519136544971, 53.905871050739847],
              [-2.98815935601069, 53.906492401209597],
              [-2.989096886634317, 53.907324177564099],
              [-2.991738170905393, 53.907463273580689],
              [-2.990840373288696, 53.90792638940399],
              [-2.990789303555584, 53.908341166928054],
              [-2.992116196690578, 53.908833546832632],
              [-2.993438151259377, 53.908924180479609],
              [-2.994227820314868, 53.909548614389422],
              [-2.994098827164473, 53.910078188826986],
              [-2.992703706237706, 53.910370165818577],
              [-2.992927509359126, 53.910691889965122],
              [-2.993807280213256, 53.910750223632007],
              [-2.993100613673067, 53.910865727871077],
              [-2.992695883722729, 53.911323878205856],
              [-2.990393048819649, 53.910572610952137],
              [-2.991302520474786, 53.911178099026159],
              [-2.990828573866875, 53.911478622291249],
              [-2.991937206247011, 53.911236672405195],
              [-2.992968038452697, 53.911437575065989],
              [-2.993305327305054, 53.910960207843992],
              [-2.99390574582287, 53.911114329252818],
              [-2.993933558252696, 53.910618849267586],
              [-2.992928160222939, 53.910462685543827],
              [-2.9940666899288, 53.910327428072435],
              [-2.994507463442301, 53.909977731561696],
              [-2.993922592061749, 53.908875229343103],
              [-2.991193780381798, 53.908194016571606],
              [-2.992300086622748, 53.907857705958918],
              [-2.992548415625045, 53.907096149850084],
              [-2.993969529140029, 53.906877662133574],
              [-2.994675366696702, 53.907114496914247],
              [-2.99440479069222, 53.908027243669615],
              [-2.995992240400698, 53.908272943041908],
              [-2.996733981121264, 53.908929217002623],
              [-2.996405792542522, 53.909914352093956],
              [-2.994355536499274, 53.91095151324874],
              [-2.993836588165256, 53.912753450016545],
              [-2.994761432791044, 53.914257604216594],
              [-2.996841575738098, 53.915810586922191],
              [-2.997412838230264, 53.916845775977677],
              [-2.997230136124232, 53.922211444395671],
              [-2.997937671513293, 53.927549937718325],
              [-2.997465360080874, 53.928499418534166],
              [-2.999334074651499, 53.928563874338927],
              [-2.99746184144096, 53.928607305866109],
              [-2.996752816374041, 53.929019459468734],
              [-2.997142856039429, 53.929345188061859],
              [-2.996419359535381, 53.929214574127251],
              [-2.996088266731013, 53.929700885016878],
              [-2.997227771679857, 53.930032078076671],
              [-2.996670632297461, 53.931066748511412],
              [-2.995379152420538, 53.931710228838213],
              [-2.98461737980283, 53.934250929657345],
              [-2.98073376096499, 53.935896085770914],
              [-2.96828547122057, 53.939565402676635],
              [-2.927943993248575, 53.950090801034897],
              [-2.921843249737983, 53.951420383779642],
              [-2.915013786013555, 53.951500473983302],
              [-2.914902288271873, 53.950862271240169],
              [-2.916337091081607, 53.948889234724568],
              [-2.914746436992779, 53.949102694069914],
              [-2.915107800928074, 53.94820922237728],
              [-2.914664526207113, 53.947866558212667],
              [-2.912544255851098, 53.948456127515584],
              [-2.911755388421711, 53.948265280317464],
              [-2.912995855636102, 53.946878882440409],
              [-2.913744178733688, 53.947237208259956],
              [-2.915807475369783, 53.947388669865923],
              [-2.914032612277154, 53.947119067066936],
              [-2.913387525236311, 53.946743779345198],
              [-2.915895027895936, 53.946725580490025],
              [-2.913965084364101, 53.946330427453745],
              [-2.9147041681443, 53.946057854237509],
              [-2.91403785313087, 53.945756433949072],
              [-2.9158598370545, 53.94588456424998],
              [-2.916545530354211, 53.945540482097954],
              [-2.919296500393203, 53.946273555211505],
              [-2.921883578931506, 53.946203389943648],
              [-2.919422011173129, 53.946161142087817],
              [-2.917467787929612, 53.945423785083129],
              [-2.913458866698988, 53.944988763036527],
              [-2.913078601255341, 53.944459560370454],
              [-2.914833434573735, 53.9441675742017],
              [-2.915382699588432, 53.944412358614734],
              [-2.91870007522678, 53.9444400555467],
              [-2.915329758100526, 53.944082000582036],
              [-2.917088227886776, 53.942290740323614],
              [-2.918143758005003, 53.942696126818156],
              [-2.918926760795074, 53.942589021616705],
              [-2.915947406953957, 53.941607364385199],
              [-2.916722247518337, 53.942205451462414],
              [-2.916213021706318, 53.94239629004241],
              [-2.9165482407828, 53.942607199093317],
              [-2.915575704449009, 53.94361708011705],
              [-2.91398126896852, 53.94414440232088],
              [-2.912415316921274, 53.944016096361288],
              [-2.910617350538675, 53.94288825705442],
              [-2.911284034351187, 53.942231563511569],
              [-2.91076097620829, 53.942417989810927],
              [-2.907094699501058, 53.940766765843456],
              [-2.90585342078995, 53.940710522895571],
              [-2.905977475853156, 53.939053981673574],
              [-2.904880595598335, 53.938487913621323],
              [-2.904289289245323, 53.938476189486927],
              [-2.90412193559589, 53.938767764839241],
              [-2.905365577400387, 53.939076572897463],
              [-2.905366549730095, 53.939684160161832],
              [-2.904705579515997, 53.94004417112685],
              [-2.905328956295779, 53.940550894952338],
              [-2.903440318871528, 53.940216381313014],
              [-2.901062995121514, 53.940248624524465],
              [-2.900592419943049, 53.939619393738639],
              [-2.901157213235485, 53.938895211122031],
              [-2.900080517038401, 53.938414334776283],
              [-2.900517465652554, 53.939039325755111],
              [-2.900281797147673, 53.93983833572068],
              [-2.899744638470891, 53.939936736574438],
              [-2.900262066510192, 53.940690553681947],
              [-2.899179637559027, 53.941077960703502],
              [-2.898681454965799, 53.940510048241663],
              [-2.89789727306455, 53.940314581427103],
              [-2.899586766081313, 53.938971700636564],
              [-2.898990917165088, 53.938465639925845],
              [-2.89931177616197, 53.938935111664385],
              [-2.897846617752221, 53.940083966557481],
              [-2.896763005776292, 53.940416533386482],
              [-2.896746404104195, 53.941133905922541],
              [-2.893738176484271, 53.941372945350452],
              [-2.892378718259244, 53.940276623706694],
              [-2.891490479331278, 53.940200530997267],
              [-2.891227825952231, 53.93945647047429],
              [-2.89119033221333, 53.93984143833773],
              [-2.890604100148055, 53.93956805616029],
              [-2.891413709306024, 53.940315249452816],
              [-2.892314346823794, 53.940401137486987],
              [-2.892875282860759, 53.940915579839704],
              [-2.892137738538992, 53.942119171648315],
              [-2.889412826612289, 53.941501231765606],
              [-2.887089866087331, 53.941945347789968],
              [-2.886691136032939, 53.941605847237192],
              [-2.886094388741302, 53.942129762400057],
              [-2.886225723157092, 53.943006927335283],
              [-2.885604635282036, 53.943965142997293],
              [-2.886328303586359, 53.943461865188354],
              [-2.88727810770782, 53.944066938438041],
              [-2.887725826720014, 53.943770618638247],
              [-2.888520878269765, 53.944476593532293],
              [-2.891103775682665, 53.944141973924033],
              [-2.893382059255077, 53.944668824606055],
              [-2.894793803000338, 53.945495103069021],
              [-2.89433143752227, 53.946529478653183],
              [-2.891797126358615, 53.94607645182144],
              [-2.88995358108375, 53.946935904746333],
              [-2.888719429598862, 53.947078966384531],
              [-2.887307385715094, 53.946163637376095],
              [-2.88339646534952, 53.946316505436428],
              [-2.883456439392071, 53.944973248070852],
              [-2.885076240198952, 53.944265644677628],
              [-2.884257827544313, 53.944252798870259],
              [-2.884113851230319, 53.943856586565424],
              [-2.883874880849034, 53.944443468568757],
              [-2.882323941025157, 53.945455244951241],
              [-2.880985382890507, 53.945053419110224],
              [-2.881212407762129, 53.944403714302283],
              [-2.88060465293036, 53.944988801150345],
              [-2.882384151077622, 53.945709164638515],
              [-2.882382735997785, 53.946291601243637],
              [-2.880743280754615, 53.945277200011077],
              [-2.879654468792163, 53.945447864372433],
              [-2.878751783288097, 53.944326471925287],
              [-2.879201902028145, 53.944068815223496],
              [-2.879961572815974, 53.944186387412188],
              [-2.880331068857443, 53.943573389996509],
              [-2.879309123976203, 53.943299551043808],
              [-2.879185184220557, 53.941825517622561],
              [-2.879965904248449, 53.941425223127894],
              [-2.878583979372241, 53.941779584638432],
              [-2.878366970821047, 53.941384798619019],
              [-2.877201268802973, 53.941662062686895],
              [-2.876053000068399, 53.940736585641019],
              [-2.876786007777951, 53.939658959866769],
              [-2.878084136009545, 53.939669248159568],
              [-2.877967565424098, 53.938906114960034],
              [-2.878515224965009, 53.939088162459747],
              [-2.879328185462419, 53.938846724687295],
              [-2.880241922899032, 53.937459462692011],
              [-2.881378501440821, 53.937469101680193],
              [-2.881489227572816, 53.93708539707356],
              [-2.881952156467448, 53.937078402595198],
              [-2.883036065916123, 53.937696006359182],
              [-2.882729221978297, 53.938025428226553],
              [-2.883320304235233, 53.938099277803857],
              [-2.885249274718726, 53.936825843415832],
              [-2.884018587836999, 53.935894757575525],
              [-2.884034546095283, 53.935280754544507],
              [-2.881936593603316, 53.934824308140215],
              [-2.877891447826729, 53.932452337955652],
              [-2.87327702906815, 53.931168335756787],
              [-2.873716726630277, 53.928448940359289],
              [-2.864371979751443, 53.918066138889422],
              [-2.825924149032157, 53.933354038388849],
              [-2.818715277203406, 53.934916094624683],
              [-2.809668280404563, 53.933539356550853],
              [-2.804851599108098, 53.935253398702628],
              [-2.803692679058694, 53.935741121543302],
              [-2.804748762054835, 53.937111911353639],
              [-2.805376835813621, 53.939272014549687],
              [-2.804709772477453, 53.940309209420114],
              [-2.802325761717963, 53.942059847832468],
              [-2.803921783776453, 53.943377601077927],
              [-2.806993068548504, 53.944083231764488],
              [-2.808396136828207, 53.943841904091776],
              [-2.810842606867027, 53.944376374946259],
              [-2.812560430834008, 53.944966061536689],
              [-2.813649949551403, 53.945835914276607],
              [-2.813180733790035, 53.946308266596205],
              [-2.811369447293622, 53.946715091598129],
              [-2.810840910308579, 53.947115931327417],
              [-2.806462791931325, 53.948026236664013],
              [-2.80595564574057, 53.949381436593498],
              [-2.805129814051325, 53.950173425870013],
              [-2.802935524425223, 53.951199266243179],
              [-2.803143246982092, 53.952096676701018],
              [-2.805341473251634, 53.952933119047984],
              [-2.805563455906456, 53.953934690345534],
              [-2.804812221328656, 53.955756199835243],
              [-2.804409291137686, 53.955882933487118],
              [-2.80447877151743, 53.956483764539897],
              [-2.803107425739209, 53.957605655076279],
              [-2.804309297729865, 53.958050606910938],
              [-2.805635159802278, 53.95923352753772],
              [-2.806516436290039, 53.959410069074472],
              [-2.806267553017737, 53.959781146244183],
              [-2.807383735344088, 53.962017048919932],
              [-2.80615346915626, 53.963188357152283],
              [-2.804684762397564, 53.962826103385893],
              [-2.804047330159511, 53.963601540679058],
              [-2.803432168950361, 53.963713512802286],
              [-2.803110736789247, 53.96491196199247],
              [-2.804283838359107, 53.965830772827687],
              [-2.803775681135896, 53.966593658085095],
              [-2.803878867721768, 53.967598721623396],
              [-2.80372637188415, 53.967912523484536],
              [-2.802945717069995, 53.968062450738351],
              [-2.801893848761191, 53.969251395329415],
              [-2.800269450317866, 53.969603771198287],
              [-2.799354220508058, 53.970132065952093],
              [-2.799786480850023, 53.97152861462142],
              [-2.798900952726839, 53.972496219140538],
              [-2.799955864605835, 53.974003669993976],
              [-2.7985103167616, 53.974470774682004],
              [-2.798623066922435, 53.975104574927073],
              [-2.796387081961314, 53.975460062703156],
              [-2.795851219483052, 53.975183189818608],
              [-2.79595717652169, 53.974902962402361],
              [-2.79489781411996, 53.974196333383922],
              [-2.794290329613507, 53.971916511864357],
              [-2.792922338630309, 53.970927886271035],
              [-2.789775220792234, 53.969851189498669],
              [-2.787937862703124, 53.967811304853505],
              [-2.786849314176907, 53.96764227649976],
              [-2.786725144596315, 53.967362665480714],
              [-2.782113387401335, 53.967985088806316],
              [-2.780539343389257, 53.96663365211581],
              [-2.777949336618714, 53.96564380358771],
              [-2.777831180203472, 53.965192474553149],
              [-2.776199487599903, 53.964744644198468],
              [-2.774991717227533, 53.96479738282968],
              [-2.774488248617347, 53.963868579153967],
              [-2.771011310091951, 53.963530531396273],
              [-2.7700956119634, 53.964624847164124],
              [-2.768867302535682, 53.965052453506644],
              [-2.766040953172515, 53.965200837567657],
              [-2.760702067682966, 53.964486072583568],
              [-2.759779153436022, 53.963859162618093],
              [-2.759859697242141, 53.963510819507171],
              [-2.757872207188139, 53.963096449097073],
              [-2.75754430129212, 53.961320701398407],
              [-2.75645650937527, 53.96041977445654],
              [-2.757385562658162, 53.959206837478739],
              [-2.756023030045758, 53.958279776047775],
              [-2.753546039326645, 53.957833361860111],
              [-2.75258465277274, 53.95717158505331],
              [-2.750782234646812, 53.956604041770241],
              [-2.752307984066521, 53.9554332504621],
              [-2.752421241488295, 53.954952584109115],
              [-2.753460595627249, 53.954870569185566],
              [-2.753180374252628, 53.954795929246629],
              [-2.755700849399089, 53.952685901258754],
              [-2.755378774182324, 53.952318522253137],
              [-2.753069660397592, 53.953388210502418],
              [-2.742438331154597, 53.956166967618231],
              [-2.741479592499653, 53.956844290217582],
              [-2.741831370854249, 53.95700120454547],
              [-2.741606815880303, 53.95860244561797],
              [-2.740522840027683, 53.959010895066925],
              [-2.739440282577218, 53.960103308229264],
              [-2.739988862529922, 53.96242061548147],
              [-2.736212150730173, 53.963729997866885],
              [-2.736021961995365, 53.964441210146276],
              [-2.734407784330855, 53.965482006344871],
              [-2.733106103695795, 53.967135647732213],
              [-2.733907584336995, 53.967550489468557],
              [-2.735773792335972, 53.967398860705266],
              [-2.736446406378514, 53.968024792383467],
              [-2.735134495815153, 53.970569220950445],
              [-2.73536413998374, 53.971489976081315],
              [-2.734507270092643, 53.971831366907644],
              [-2.733957972895297, 53.973105616180796],
              [-2.735143115429886, 53.974002553580291],
              [-2.735240554774156, 53.974514268492939],
              [-2.730854114855745, 53.9756411568248],
              [-2.728990676131819, 53.977806881550464],
              [-2.72827397681531, 53.977890322217746],
              [-2.727453632935516, 53.97850107758476],
              [-2.725165146658196, 53.978775563087972],
              [-2.724183065699021, 53.978592741428493],
              [-2.722549472889767, 53.977239113171159],
              [-2.720209023664013, 53.978476421315705],
              [-2.719801593880477, 53.979430681948116],
              [-2.71565871843134, 53.98023198815828],
              [-2.714861451463145, 53.978553301964787],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000129",
        LAD13CDO: "31UB",
        LAD13NM: "Blaby",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.199688310661462, 52.664383229911991],
            [-1.198101918710885, 52.663403398296374],
            [-1.197542911129281, 52.66340860581451],
            [-1.197188444047387, 52.662821861870249],
            [-1.196496613691261, 52.662894488252363],
            [-1.196153716937732, 52.662242193171352],
            [-1.195560160326272, 52.662277722736675],
            [-1.196713020346012, 52.66165534693198],
            [-1.193396967034411, 52.660432678106204],
            [-1.19307232716433, 52.660318094917784],
            [-1.192048717701629, 52.661387216259129],
            [-1.189552656731715, 52.660683349306197],
            [-1.188478854728303, 52.66038562929841],
            [-1.187334165566919, 52.66104124407795],
            [-1.185295616449054, 52.660625402991322],
            [-1.184544268104236, 52.659527062698125],
            [-1.184130367300992, 52.657537441581091],
            [-1.184603401950993, 52.653328015420492],
            [-1.182925821070049, 52.652997321695629],
            [-1.184068827875975, 52.649564746215738],
            [-1.187581598373915, 52.647132822381678],
            [-1.186997146998564, 52.645571757435206],
            [-1.189702642978768, 52.642491449607149],
            [-1.190662420096389, 52.640375480563293],
            [-1.196000987475926, 52.641065382047202],
            [-1.198074373353629, 52.641714117796511],
            [-1.198785503698059, 52.641456420560608],
            [-1.198861751741886, 52.640275657492182],
            [-1.200906392111115, 52.639825578537625],
            [-1.201515367746267, 52.641117941176567],
            [-1.202963883721884, 52.641349741826566],
            [-1.205897464684017, 52.642654093767867],
            [-1.209011531987603, 52.642073488798829],
            [-1.209748758058048, 52.641676556088164],
            [-1.211025017022408, 52.639410604234683],
            [-1.214354790796002, 52.636918236135926],
            [-1.213090671946608, 52.636132220360359],
            [-1.215676167091264, 52.634669613367585],
            [-1.215987750957362, 52.634515249850025],
            [-1.214862545907681, 52.633317532921446],
            [-1.212055400695815, 52.63157463208627],
            [-1.210554230861756, 52.630835557531888],
            [-1.208753622016785, 52.630563740528146],
            [-1.205472029115846, 52.628854372084419],
            [-1.205402129872165, 52.628798165730934],
            [-1.201250957108269, 52.627313887721463],
            [-1.200415117862775, 52.627212957889299],
            [-1.200147479943201, 52.626658269200632],
            [-1.196108757477499, 52.625669016495394],
            [-1.18896360308276, 52.623033019798221],
            [-1.188117752800472, 52.622640659088312],
            [-1.187260675052492, 52.621618916839395],
            [-1.18600436119635, 52.621736153884981],
            [-1.184604089804553, 52.620696275756018],
            [-1.17859568657769, 52.618290415272774],
            [-1.176111525675221, 52.618038531458069],
            [-1.174313903951449, 52.617803967037723],
            [-1.172073230402094, 52.615765584774671],
            [-1.16792052390699, 52.614252249097028],
            [-1.166686663352526, 52.613272658379593],
            [-1.16556670631278, 52.613063401008972],
            [-1.165901688422587, 52.611678601741851],
            [-1.164986834599653, 52.610160938202945],
            [-1.164055491397574, 52.609435170633844],
            [-1.167391919888213, 52.608247707556025],
            [-1.171053199405935, 52.605800226424002],
            [-1.165377874286489, 52.603029218514138],
            [-1.166110282797028, 52.602172232623808],
            [-1.167992590453159, 52.601407828743],
            [-1.170091205887256, 52.598334474895758],
            [-1.171510435846532, 52.59870490750577],
            [-1.172648872284952, 52.597287944244947],
            [-1.171553684503384, 52.596646494622021],
            [-1.170384802058269, 52.596547516263534],
            [-1.170099047695641, 52.595887445061237],
            [-1.170869112907019, 52.594823023470497],
            [-1.170406913289748, 52.593891117966272],
            [-1.17145963156539, 52.593413922163528],
            [-1.174058661269453, 52.593683790588763],
            [-1.174166244921467, 52.592829588874459],
            [-1.17241302000911, 52.592801172764361],
            [-1.172133542215099, 52.591807619759585],
            [-1.169195971053787, 52.591570396175428],
            [-1.168429917981259, 52.592813737221448],
            [-1.160851103511024, 52.592342261733087],
            [-1.160910575070522, 52.59177900758592],
            [-1.159559973573782, 52.591695715741366],
            [-1.159344926726626, 52.591207828843636],
            [-1.158911664758943, 52.591092378162763],
            [-1.159034184570643, 52.590783990537005],
            [-1.158000523155237, 52.590648090931744],
            [-1.158878710948254, 52.589035222570232],
            [-1.157159802023249, 52.588611261809625],
            [-1.156951830560704, 52.587218122815635],
            [-1.155614659737543, 52.58713488105419],
            [-1.155942480895339, 52.585974803854775],
            [-1.158016390439835, 52.585736941898261],
            [-1.15822359753182, 52.584166054671762],
            [-1.160669548859848, 52.584303876627956],
            [-1.160950232497795, 52.582512349854731],
            [-1.160419433645817, 52.58240790046478],
            [-1.159729897873293, 52.582963990709885],
            [-1.158908068613673, 52.582642607594821],
            [-1.159337393090268, 52.581647759137184],
            [-1.153107838427865, 52.58065044027979],
            [-1.149504384388658, 52.581005801911715],
            [-1.147384759178136, 52.585283318026363],
            [-1.146295409603138, 52.586579030735457],
            [-1.145996871471432, 52.588117774215625],
            [-1.143626976723244, 52.588007169758278],
            [-1.143602779892575, 52.586446321189491],
            [-1.144520993974323, 52.585529676710692],
            [-1.14359320181106, 52.58519573492157],
            [-1.143243729646981, 52.584572893861953],
            [-1.143227328786258, 52.582309976770091],
            [-1.143728921987465, 52.581562933247227],
            [-1.143601945848574, 52.581262646199193],
            [-1.140969677086741, 52.58153216798123],
            [-1.139729217448058, 52.57795051250222],
            [-1.140725343253992, 52.57636470181761],
            [-1.136594908697309, 52.575087743208591],
            [-1.136992474285885, 52.574003739951259],
            [-1.134998868021925, 52.574157312535277],
            [-1.134611852689018, 52.573578221453438],
            [-1.132855590945019, 52.573594145447537],
            [-1.133063943583628, 52.572755996820163],
            [-1.132367507488879, 52.569763491768271],
            [-1.134396784871825, 52.565764263954435],
            [-1.133531808086029, 52.565861325311651],
            [-1.133678266795464, 52.564118318249861],
            [-1.129406443559328, 52.563792119483736],
            [-1.130188260405125, 52.56222909131057],
            [-1.126641088303137, 52.561971042504211],
            [-1.126631261296109, 52.563208907202323],
            [-1.126098474443974, 52.563220260335427],
            [-1.124806644341271, 52.564975478319369],
            [-1.124815125144281, 52.565364812323409],
            [-1.12538058766166, 52.565492153720072],
            [-1.125067328319383, 52.56625849348881],
            [-1.124623190591564, 52.56626150482154],
            [-1.125146018912002, 52.566900967745319],
            [-1.124336538437275, 52.567394835235412],
            [-1.124381000967691, 52.568718506094577],
            [-1.123026533626385, 52.568793891013712],
            [-1.122454274541123, 52.568413866482665],
            [-1.12352308704118, 52.568383123129479],
            [-1.123012089621207, 52.567816560567834],
            [-1.122434371665345, 52.56800646762148],
            [-1.121616079860873, 52.567609332595453],
            [-1.12116475504242, 52.567159176699775],
            [-1.121462040250012, 52.566826950490125],
            [-1.120626820973962, 52.566761417606173],
            [-1.120413227603337, 52.566449673091597],
            [-1.11660573409886, 52.567018278615166],
            [-1.116605582823917, 52.566144439458213],
            [-1.115802170296788, 52.565892115523766],
            [-1.115605377784787, 52.566360828686285],
            [-1.115177447078473, 52.566364824617395],
            [-1.115278589511604, 52.565957429424564],
            [-1.114565097709893, 52.56600244219716],
            [-1.114016778379261, 52.565391509604765],
            [-1.11322087239428, 52.566012163557893],
            [-1.109946085584385, 52.565770937617124],
            [-1.107152322179958, 52.564292612742925],
            [-1.106181190202081, 52.56460263877203],
            [-1.106398186756217, 52.565249766247518],
            [-1.106012635558089, 52.565637027516736],
            [-1.10532724619462, 52.565605780800716],
            [-1.105551884284108, 52.565078858899163],
            [-1.105198621817637, 52.564966511136767],
            [-1.104126960024503, 52.565068089206818],
            [-1.103627131376475, 52.56563248255496],
            [-1.103200508572351, 52.565572615272345],
            [-1.103378519054302, 52.565163114833048],
            [-1.102966360152301, 52.564972999528827],
            [-1.101853412134693, 52.565506668835944],
            [-1.100831969975229, 52.565532181979535],
            [-1.097006491074847, 52.565530946881282],
            [-1.096462993197442, 52.564984697776211],
            [-1.095799609828693, 52.565534325374202],
            [-1.095483634334155, 52.565403353975249],
            [-1.094861817905233, 52.564505884614434],
            [-1.095383568216889, 52.564745412025047],
            [-1.095599086149313, 52.564524102988017],
            [-1.094384554841379, 52.563682337175656],
            [-1.094453668050373, 52.563334947913006],
            [-1.093786604463171, 52.563203983989645],
            [-1.093995235634295, 52.562672465869532],
            [-1.09433045030644, 52.563085878355601],
            [-1.094699508050942, 52.562930473808393],
            [-1.094403084891922, 52.562495782690434],
            [-1.094758638496062, 52.561849413942319],
            [-1.094001623905185, 52.561717763557191],
            [-1.094275346266345, 52.561176853628915],
            [-1.09225463145111, 52.561520987820217],
            [-1.092662936946158, 52.561035952772521],
            [-1.09196162962503, 52.560564889210951],
            [-1.091155184043442, 52.56089942914786],
            [-1.089818143611424, 52.559856195291687],
            [-1.088617511985959, 52.559636592856151],
            [-1.088653427133605, 52.559184665610069],
            [-1.087411399822737, 52.559255113578963],
            [-1.086835094015696, 52.558948602839983],
            [-1.086198511958669, 52.5592008105152],
            [-1.085869311893906, 52.5579351560188],
            [-1.085614833082727, 52.558255937331744],
            [-1.085019270086666, 52.557883640939366],
            [-1.085560205639425, 52.557335824614263],
            [-1.085007709281424, 52.556808331213475],
            [-1.085936811403936, 52.556603338761541],
            [-1.085513573347161, 52.556244061819868],
            [-1.084906995330936, 52.55618993051813],
            [-1.084044359798017, 52.556674123295025],
            [-1.083857246305028, 52.55638049642176],
            [-1.082903574023399, 52.556561008929719],
            [-1.08028102276576, 52.555571533496845],
            [-1.077236440789605, 52.555792381340204],
            [-1.076576250681876, 52.556817505868615],
            [-1.074556150845986, 52.556714532618301],
            [-1.073811339752757, 52.556080298738799],
            [-1.07336371946723, 52.556463373415653],
            [-1.074117797880705, 52.55686753481703],
            [-1.07324351578532, 52.557968282108767],
            [-1.073640219969099, 52.559023232576074],
            [-1.072811111062677, 52.561057506361031],
            [-1.071726422336085, 52.561777210764546],
            [-1.064251081284456, 52.562078887476346],
            [-1.063914722370242, 52.559029468792183],
            [-1.059401937932811, 52.558911902267205],
            [-1.060454422508162, 52.555493210263514],
            [-1.060732238428246, 52.555261669996774],
            [-1.0614858219246, 52.555406092972675],
            [-1.06473855368345, 52.552334717645408],
            [-1.06624386693949, 52.549163181290687],
            [-1.06984104265551, 52.544130029653729],
            [-1.06986932004506, 52.542861739110421],
            [-1.072589001457158, 52.542483907928286],
            [-1.074802845029779, 52.540423598761087],
            [-1.075712637140785, 52.539719581687187],
            [-1.075899020091965, 52.538848090936661],
            [-1.083672492174079, 52.539896522780964],
            [-1.087231702115919, 52.540685479702354],
            [-1.087690618033865, 52.540526294512681],
            [-1.090368886491606, 52.5412103732533],
            [-1.090319445171752, 52.541533639102973],
            [-1.093503779927995, 52.541420510402183],
            [-1.094229642263478, 52.541902543252107],
            [-1.097453246442146, 52.542391948810277],
            [-1.102278000636597, 52.538340803511218],
            [-1.10451752744889, 52.539795286909715],
            [-1.106068752278911, 52.54182349832994],
            [-1.109374388650557, 52.542054281943805],
            [-1.113894646923343, 52.541867036914148],
            [-1.117511646451701, 52.543191325522187],
            [-1.121266241613977, 52.545168308412144],
            [-1.123478208626307, 52.544575171251658],
            [-1.139459853134729, 52.543153280673181],
            [-1.139282584028182, 52.539851716833766],
            [-1.140314370216932, 52.537905657053194],
            [-1.143894160017237, 52.53752793645517],
            [-1.143070811387401, 52.536500701835109],
            [-1.142863558065786, 52.534953797872596],
            [-1.172009190358942, 52.534382649053086],
            [-1.172123095218838, 52.533185961729494],
            [-1.173022640249739, 52.532857815375259],
            [-1.175069162657324, 52.527568818170614],
            [-1.18174443458412, 52.529666684293105],
            [-1.184285012740407, 52.52748073623961],
            [-1.190208491297131, 52.527837849758001],
            [-1.194088599884872, 52.527653955068942],
            [-1.196204783827892, 52.528087279498365],
            [-1.197084675513589, 52.527608682475332],
            [-1.198558642513231, 52.528179651712165],
            [-1.202142540893397, 52.527421716626222],
            [-1.202843538894547, 52.529655985217637],
            [-1.204430335369027, 52.531577953950986],
            [-1.205455338629193, 52.535147613083893],
            [-1.206608337470729, 52.535141859059941],
            [-1.209018300649138, 52.539190937489835],
            [-1.210757988743302, 52.541007754796873],
            [-1.211782025464909, 52.542860239634265],
            [-1.213801137879546, 52.543660293971875],
            [-1.212985411403992, 52.544253614827518],
            [-1.217739279462, 52.547668077983246],
            [-1.217832080909999, 52.548254843582832],
            [-1.221147534443495, 52.550156517820092],
            [-1.224366412772289, 52.551445242157833],
            [-1.224136890251956, 52.551664894252646],
            [-1.226671110017524, 52.553402166727771],
            [-1.22889582136327, 52.554295900033054],
            [-1.231850692128682, 52.555360645077094],
            [-1.245969657234553, 52.543275247746891],
            [-1.247978877192699, 52.542015930140977],
            [-1.24912777695986, 52.540618073536756],
            [-1.251358256466017, 52.539080510278687],
            [-1.251683221438719, 52.538351671859218],
            [-1.261183622894515, 52.530303184287483],
            [-1.279581566429069, 52.514296442510201],
            [-1.292856248041901, 52.503421150630132],
            [-1.297005758814454, 52.499575627040613],
            [-1.302112087167934, 52.495815614092798],
            [-1.304376121491845, 52.493195738292869],
            [-1.305491485058799, 52.493638307855115],
            [-1.305958076012181, 52.493377634390534],
            [-1.307837185184511, 52.494522289497901],
            [-1.325098995222379, 52.501024708792706],
            [-1.321901752146352, 52.505560814607577],
            [-1.321342181379611, 52.507839290334381],
            [-1.31906732210856, 52.511080635599711],
            [-1.319137587918977, 52.513494874101333],
            [-1.318781232458913, 52.514527581269775],
            [-1.317976646280741, 52.515099212568146],
            [-1.320803260348878, 52.515562267886409],
            [-1.321164612940458, 52.516020136615552],
            [-1.321907876946457, 52.515697157422068],
            [-1.322134820392777, 52.516175829434978],
            [-1.323566694772889, 52.516976942321797],
            [-1.322436467942333, 52.518984253635466],
            [-1.323799805321602, 52.520512266570812],
            [-1.325227220902353, 52.520942043607711],
            [-1.325115952096379, 52.521182342945821],
            [-1.325713858231497, 52.521405106769237],
            [-1.327248434460115, 52.521576555900893],
            [-1.327136740704018, 52.522520775863804],
            [-1.32764510780871, 52.523204210625615],
            [-1.329275928502962, 52.524028857365764],
            [-1.329256381965726, 52.524441389906862],
            [-1.33041401747159, 52.524595378693299],
            [-1.330337270449895, 52.527122945715078],
            [-1.331231918121871, 52.527322186890743],
            [-1.329602221631257, 52.529318649987474],
            [-1.331668578450852, 52.529824782863074],
            [-1.330711459011942, 52.531113942638186],
            [-1.33237352512942, 52.531748132611909],
            [-1.334857641895211, 52.533007231678447],
            [-1.332613428840854, 52.538679899987415],
            [-1.334388596702211, 52.541021003813995],
            [-1.334961832565009, 52.542897744417026],
            [-1.334562273839411, 52.543772925778427],
            [-1.336261179103369, 52.54708449161474],
            [-1.336576129927041, 52.548593880999832],
            [-1.338064497173321, 52.551707356927885],
            [-1.339123445994822, 52.552884667776688],
            [-1.339000808535096, 52.554568709467404],
            [-1.337887145017639, 52.555554985002281],
            [-1.337296201366091, 52.556304142894163],
            [-1.332472009028741, 52.55841037941056],
            [-1.332528554583049, 52.559350152605354],
            [-1.334896877441131, 52.56017527120904],
            [-1.329281245375659, 52.565970917558012],
            [-1.328615140418261, 52.56582600123955],
            [-1.325834913127508, 52.564912110697882],
            [-1.323719590597779, 52.567775949949386],
            [-1.319152777258353, 52.567047671217118],
            [-1.319763972159012, 52.565877089901988],
            [-1.312933160312972, 52.563523640934314],
            [-1.312944480265739, 52.563176692662559],
            [-1.312163698559413, 52.562824248285295],
            [-1.312447532139541, 52.562314365186026],
            [-1.311840654921385, 52.562068110917707],
            [-1.310147840452213, 52.562682170026363],
            [-1.309353504532882, 52.562537297103646],
            [-1.309063042298921, 52.562903294178753],
            [-1.308335356706682, 52.562553831161758],
            [-1.306756008774163, 52.562890713345546],
            [-1.305653396476139, 52.561809050582738],
            [-1.30365624628153, 52.561887226400898],
            [-1.303182345789557, 52.561444828160219],
            [-1.302032895299125, 52.561370635384066],
            [-1.298398546018714, 52.562637431714379],
            [-1.298565848421092, 52.563339643034446],
            [-1.298104251355503, 52.563700104852465],
            [-1.295475964281088, 52.563386932007759],
            [-1.289340080123853, 52.564598989499608],
            [-1.288923460455407, 52.563631861211789],
            [-1.285579230218557, 52.563260228257157],
            [-1.284955638431335, 52.562961592995151],
            [-1.28452261717608, 52.563372518722502],
            [-1.282284296187872, 52.56321603590235],
            [-1.281635355533049, 52.563658006645355],
            [-1.280421010026701, 52.563852011953713],
            [-1.280218427269417, 52.56433174680172],
            [-1.279425703745097, 52.564452787161862],
            [-1.279706839503883, 52.564672055413467],
            [-1.278167603837632, 52.564883839585185],
            [-1.278091825479733, 52.565186341209703],
            [-1.276840377456808, 52.565031271229934],
            [-1.277037920109355, 52.564770867703423],
            [-1.276085428688392, 52.564469278777587],
            [-1.275595919645508, 52.564810603639209],
            [-1.275261370523745, 52.564431875506173],
            [-1.27361252010128, 52.565920410911012],
            [-1.272925943507983, 52.565953956589048],
            [-1.271805984562602, 52.56774777645947],
            [-1.272513338731493, 52.567886973051159],
            [-1.272588696317992, 52.568326148400743],
            [-1.270215625784913, 52.568897700485834],
            [-1.269877695740466, 52.569701123017616],
            [-1.26939511404222, 52.569619035418832],
            [-1.269733545003814, 52.569852165593772],
            [-1.269690390229356, 52.570318481053341],
            [-1.26921815916941, 52.570234658570719],
            [-1.26937148244489, 52.570775004937623],
            [-1.268122062593611, 52.570933607127806],
            [-1.268223210062579, 52.571327994426106],
            [-1.267602526049092, 52.571652293759442],
            [-1.267609215472847, 52.571870792325576],
            [-1.268904114117044, 52.572084662512523],
            [-1.26903750943886, 52.572403731981865],
            [-1.269119017478735, 52.573003868335491],
            [-1.269771623397957, 52.572979125084323],
            [-1.269294565197569, 52.573272853496462],
            [-1.269476589939172, 52.573773820443677],
            [-1.270095822537801, 52.573804607591029],
            [-1.2701814521136, 52.574868652570885],
            [-1.270628311888294, 52.574616987715395],
            [-1.273538763057209, 52.57485153101323],
            [-1.28072148119783, 52.576059565967668],
            [-1.283997859766764, 52.576939756504622],
            [-1.286434951791823, 52.57807641219258],
            [-1.289368115249448, 52.578294520322139],
            [-1.297100361903815, 52.57999211068595],
            [-1.295636668707173, 52.582461055630262],
            [-1.295324161384078, 52.583818481761313],
            [-1.296052827515101, 52.584122179841984],
            [-1.295577331592313, 52.58469021697362],
            [-1.296317269794267, 52.585489327904959],
            [-1.29835230124399, 52.585972472129271],
            [-1.295912245399485, 52.588383490256057],
            [-1.294126347620511, 52.589293436374767],
            [-1.292063181091875, 52.591283192265252],
            [-1.292425616569994, 52.59151010647328],
            [-1.291256928177683, 52.592581018109414],
            [-1.284905693675981, 52.595247037280679],
            [-1.28155392957027, 52.597448180915819],
            [-1.276982503217348, 52.599366700782753],
            [-1.274593794009782, 52.600965798935967],
            [-1.274707864133453, 52.602731226291745],
            [-1.2780371034663, 52.604641246438185],
            [-1.279846000056542, 52.607250349653903],
            [-1.275900753258931, 52.609010812129846],
            [-1.280336063062476, 52.608974901837072],
            [-1.274269607960634, 52.609773969198599],
            [-1.277295474195202, 52.611483470575543],
            [-1.274140767104929, 52.61283335756174],
            [-1.274005798041859, 52.611528089837329],
            [-1.273367524648225, 52.611276954258948],
            [-1.271784811699822, 52.614544073484034],
            [-1.274394408134555, 52.615001486819253],
            [-1.274489124512446, 52.616151879928239],
            [-1.264629935012602, 52.614399203146014],
            [-1.265049056274056, 52.614948391908371],
            [-1.257422403440498, 52.616234955224996],
            [-1.25779618939629, 52.620771824299617],
            [-1.260009460484347, 52.623809879416356],
            [-1.260517927022438, 52.625371008484024],
            [-1.262819600653772, 52.626953191044741],
            [-1.261731452988753, 52.62831828076218],
            [-1.260476836982752, 52.62886154154706],
            [-1.254334297756427, 52.630215595109959],
            [-1.252482923449354, 52.630221013782453],
            [-1.252463424664972, 52.630758488511674],
            [-1.251399178238184, 52.631650764142314],
            [-1.249403390722774, 52.631543747525114],
            [-1.248361808285652, 52.632488281073208],
            [-1.24640869602256, 52.63238957566427],
            [-1.24076568167267, 52.633598667482133],
            [-1.244085165972521, 52.635867366264698],
            [-1.245319847715011, 52.636162911651901],
            [-1.247160809380645, 52.63609011370243],
            [-1.248468608696547, 52.63651824242762],
            [-1.250681780944973, 52.636109728161031],
            [-1.251549985344395, 52.636495487268725],
            [-1.253445693930422, 52.63624493694914],
            [-1.253858963892475, 52.635925700116942],
            [-1.253734435445025, 52.63508076264462],
            [-1.255049846333772, 52.634453450006447],
            [-1.256120202423376, 52.634333414584745],
            [-1.255975973684169, 52.635519178710155],
            [-1.257277291093222, 52.635810522626386],
            [-1.259211598338005, 52.636858265924943],
            [-1.261380813775509, 52.6362470042287],
            [-1.261810640608312, 52.637047986301774],
            [-1.260600031166895, 52.636982006151122],
            [-1.260571860779643, 52.637425932162202],
            [-1.259314101846425, 52.637176250501703],
            [-1.257501905910521, 52.63816728783835],
            [-1.256881834073643, 52.637871229752811],
            [-1.256626258519622, 52.638302941049758],
            [-1.256072025478068, 52.638133150426299],
            [-1.255435816153932, 52.63844020676931],
            [-1.25504348328537, 52.638221983679969],
            [-1.254636839702656, 52.638585316807927],
            [-1.251240774858066, 52.638941489040278],
            [-1.248600578499656, 52.640201349738987],
            [-1.247837846033772, 52.640211795040329],
            [-1.247375385457244, 52.639775545551785],
            [-1.246603434741288, 52.639892904373333],
            [-1.243968583790175, 52.640660048669744],
            [-1.243865221308199, 52.641083713450286],
            [-1.243064864097652, 52.641475060536372],
            [-1.242134210171827, 52.641452033041496],
            [-1.241893150608092, 52.641034258341449],
            [-1.241679987773597, 52.641824007569731],
            [-1.240953624831328, 52.641268276471983],
            [-1.239752599054051, 52.641497014286678],
            [-1.239585726621995, 52.641063528779576],
            [-1.239361929162484, 52.641441467448857],
            [-1.238696803980309, 52.64127627785961],
            [-1.237321001638148, 52.641781656912073],
            [-1.23716899166733, 52.641344667621254],
            [-1.236679891730515, 52.641340619968013],
            [-1.23683615848313, 52.641617616731978],
            [-1.236457498599929, 52.641721259223985],
            [-1.235650771928865, 52.641286341663978],
            [-1.235841089383761, 52.640965729574283],
            [-1.234958857273383, 52.641048139923008],
            [-1.234668813030434, 52.640392700171816],
            [-1.234246589763946, 52.640706418516324],
            [-1.234059116822566, 52.640186488659566],
            [-1.233742343785334, 52.64072383668298],
            [-1.233441477656678, 52.640435112774931],
            [-1.232843779612281, 52.640895125944382],
            [-1.232205301392345, 52.640729173278494],
            [-1.232380567011693, 52.641607724938794],
            [-1.229261204040609, 52.64242534376109],
            [-1.225088271155419, 52.644062171658078],
            [-1.223151938249804, 52.646243937301136],
            [-1.223433852634876, 52.646432774863733],
            [-1.224272451640126, 52.646234194550857],
            [-1.227053095192674, 52.648554676533898],
            [-1.228633379428413, 52.649047728653642],
            [-1.225655902947171, 52.650501763229236],
            [-1.225248497705785, 52.650218615981025],
            [-1.224167800585037, 52.650478548434549],
            [-1.22069086709352, 52.651076944874056],
            [-1.218334951129612, 52.651142342752379],
            [-1.215954569599008, 52.65199684668049],
            [-1.214112635331481, 52.65332055139006],
            [-1.214249428706039, 52.653853661123385],
            [-1.210545809876535, 52.655196420857251],
            [-1.20962995039891, 52.658519282787516],
            [-1.208295598641219, 52.659791441372683],
            [-1.20310331284399, 52.661898049538365],
            [-1.202600599786369, 52.663929984362447],
            [-1.199688310661462, 52.664383229911991],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000130",
        LAD13CDO: "31UC",
        LAD13NM: "Charnwood",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.073249208349989, 52.824129161988765],
            [-1.073705806077603, 52.823943041289567],
            [-1.07337810596367, 52.823441553256018],
            [-1.072728117760781, 52.82351019476954],
            [-1.071888603746697, 52.823001108405904],
            [-1.071426858521291, 52.823150323900933],
            [-1.070780671049099, 52.822901646246962],
            [-1.07036862271093, 52.822321278676327],
            [-1.069468916486135, 52.822200959971177],
            [-1.069653283856244, 52.821489517269569],
            [-1.068819217221128, 52.821215084084805],
            [-1.068845689893442, 52.820601291370217],
            [-1.070072241705717, 52.820354696388065],
            [-1.07011503560971, 52.819740132220488],
            [-1.069720808455572, 52.819577925274508],
            [-1.070436021037841, 52.819301248680368],
            [-1.069899136018495, 52.819148713011749],
            [-1.070573335239307, 52.818846542974484],
            [-1.070189436509465, 52.817852865689574],
            [-1.071121408504821, 52.817360330172733],
            [-1.070570951732891, 52.816940695354816],
            [-1.071540961239124, 52.816959971679495],
            [-1.070968096267134, 52.816199451481879],
            [-1.072010847252036, 52.815310428328146],
            [-1.071399362828401, 52.814970329012098],
            [-1.072079640422375, 52.814588189108719],
            [-1.072147121916721, 52.814136531414647],
            [-1.071745175605282, 52.813990452147877],
            [-1.062210727050843, 52.816474909225235],
            [-1.061763260997678, 52.815962553051406],
            [-1.059211997512846, 52.81722879976504],
            [-1.058561105002959, 52.816449621141437],
            [-1.054993760635173, 52.815640967558231],
            [-1.055135328539201, 52.815339138952716],
            [-1.054606706749964, 52.81522076056725],
            [-1.054506828638445, 52.815447406477269],
            [-1.05404490447254, 52.81512728681426],
            [-1.052587948218372, 52.814986216388625],
            [-1.051775131533634, 52.813882075454451],
            [-1.050344010963161, 52.813987503583498],
            [-1.050522319851522, 52.813703054777022],
            [-1.049773119534073, 52.813427367222332],
            [-1.04965042743539, 52.813814743640215],
            [-1.048882456588463, 52.81405940847791],
            [-1.047276283806949, 52.813959329087403],
            [-1.046010712558082, 52.814296171819912],
            [-1.044713494091735, 52.814180560408339],
            [-1.043583848765184, 52.814610166768254],
            [-1.042196848564993, 52.814528864706581],
            [-1.040670856490565, 52.815369674230801],
            [-1.040223931082466, 52.816192223125583],
            [-1.037536562157702, 52.816335879600381],
            [-1.0366551008404, 52.815936775850375],
            [-1.036296313250147, 52.815856551852363],
            [-1.036237829088062, 52.812663803193999],
            [-1.037004229752263, 52.810343464198041],
            [-1.042428492996175, 52.801148526428165],
            [-1.046630537517093, 52.780934587263488],
            [-1.040653556624831, 52.777388478018089],
            [-1.037954495153864, 52.775010391034947],
            [-1.043488867129739, 52.771602127599749],
            [-1.042087019609146, 52.769760486894256],
            [-1.045352327973462, 52.767186036412255],
            [-1.045913232326227, 52.766002987961329],
            [-1.041430595434953, 52.766038780834648],
            [-1.041547950916953, 52.763123418689695],
            [-1.038617235110243, 52.763294790445741],
            [-1.038543793442078, 52.761447677371798],
            [-1.036848581015459, 52.758351303501335],
            [-1.034727841911307, 52.757088073561945],
            [-1.030388874102615, 52.756166293793619],
            [-1.033666478080408, 52.753355826978947],
            [-1.032327913522626, 52.751979354376076],
            [-1.033535347175203, 52.751112682247133],
            [-1.03254251895937, 52.750116602353636],
            [-1.033059861208293, 52.749769315185375],
            [-1.031165811521258, 52.748261542276154],
            [-1.031487967784923, 52.747963910225728],
            [-1.02857465353361, 52.744798124147131],
            [-1.023672048905386, 52.740720514253205],
            [-1.024262910799045, 52.740461971173005],
            [-1.021852005552747, 52.73779099298995],
            [-1.019429324864417, 52.737110233727762],
            [-1.019940650510418, 52.735774062757955],
            [-1.019418649463085, 52.735539607162657],
            [-1.019588586083774, 52.735028586450611],
            [-1.021501855502855, 52.735105514934801],
            [-1.02299295750561, 52.734655723255322],
            [-1.025681454276616, 52.734836057615915],
            [-1.026198305493708, 52.734638028475885],
            [-1.027459777149413, 52.734920771449289],
            [-1.019917603461931, 52.726960151858741],
            [-1.018774010431447, 52.726781686689968],
            [-1.01725093335779, 52.724447876930306],
            [-1.008206229137584, 52.724020188855903],
            [-1.004721813607303, 52.723581960071904],
            [-1.001057291298393, 52.723641953034608],
            [-1.000674214995015, 52.722263263955185],
            [-1.001094806731979, 52.720055278680377],
            [-1.003568879627438, 52.720181271555674],
            [-1.00328520931084, 52.717231981292528],
            [-1.004860985714128, 52.717076201865261],
            [-1.007656866756345, 52.71736662883221],
            [-1.01080101956423, 52.714435199024884],
            [-1.01270556346556, 52.713218545733696],
            [-1.013366265200473, 52.713590834014852],
            [-1.01548344616886, 52.710945603138605],
            [-1.015257831042584, 52.710644364482953],
            [-1.013230735926593, 52.710214876780661],
            [-1.013011140860826, 52.709910087850695],
            [-1.013416738640122, 52.709580834096947],
            [-1.011564422095569, 52.706462974224472],
            [-1.009666965666088, 52.705808856715336],
            [-1.006772631932509, 52.705323466172764],
            [-1.00681348922141, 52.704827561934998],
            [-1.004854005426093, 52.70449918327661],
            [-1.006319803700862, 52.698607756506433],
            [-1.007983447727034, 52.696373290950334],
            [-1.007510213219712, 52.696295613215241],
            [-1.006103446353383, 52.698360516839962],
            [-0.99604845071093, 52.69576760818665],
            [-0.992739582470661, 52.695326906946434],
            [-0.971176446550051, 52.691019858115773],
            [-0.961019157860854, 52.684727271387054],
            [-0.956176544800712, 52.6826466949571],
            [-0.954754194734802, 52.684042004695151],
            [-0.947246089600915, 52.682709778003783],
            [-0.948932608263597, 52.680362156231091],
            [-0.949117760935736, 52.678563989126722],
            [-0.950215978472452, 52.678535061254571],
            [-0.952951036030273, 52.677601807072151],
            [-0.956423534899385, 52.675199716118108],
            [-0.957404882954755, 52.673987501193032],
            [-0.960175680997583, 52.673087658691607],
            [-0.961329491260172, 52.673202956760818],
            [-0.961383999769374, 52.673461448794647],
            [-0.962259178714247, 52.673352242150052],
            [-0.962318933436872, 52.673826540858464],
            [-0.963573202941107, 52.674010121606671],
            [-0.964305422327886, 52.674630540874773],
            [-0.964591339168436, 52.674172747864958],
            [-0.965049353215113, 52.674505782851327],
            [-0.965809899545765, 52.67442881236218],
            [-0.96651419306514, 52.675921008522252],
            [-0.967942243865689, 52.675888498416811],
            [-0.968353425645532, 52.67620223359954],
            [-0.968026724739164, 52.676948260776967],
            [-0.969326191065295, 52.677101607397162],
            [-0.970025989379292, 52.677659677399696],
            [-0.970676114834057, 52.677311116057574],
            [-0.971394804207909, 52.678136346001978],
            [-0.972238549842511, 52.67791981134242],
            [-0.972952852972248, 52.678555303847489],
            [-0.972529744612286, 52.678747620596091],
            [-0.972901394189562, 52.679046612977693],
            [-0.973243634504339, 52.678896746764138],
            [-0.974898099471629, 52.679874794775344],
            [-0.976783047773542, 52.680361255992608],
            [-0.978891452346591, 52.681923919945397],
            [-0.980935505669999, 52.681617865483709],
            [-0.982309294871256, 52.680108553153737],
            [-0.983093194344833, 52.678264232732097],
            [-0.988234795887927, 52.6720466663621],
            [-0.991448930228656, 52.673440537768975],
            [-0.995619203197363, 52.669652481812513],
            [-0.99655893187152, 52.66807369892981],
            [-0.997740456452023, 52.668074703880372],
            [-0.996949960822556, 52.666172918765881],
            [-0.998622385023529, 52.665202648847043],
            [-0.998067098277301, 52.664396045601336],
            [-0.999585879045915, 52.662832918884227],
            [-1.003833694212106, 52.661021238493824],
            [-1.004099761103458, 52.660503849855651],
            [-1.005177579786393, 52.660179369655317],
            [-1.00880589969607, 52.657627825008497],
            [-1.011830915265964, 52.657030970517866],
            [-1.013796449791606, 52.655027277911579],
            [-1.014712787179391, 52.655826920713587],
            [-1.016454811859546, 52.655408967081925],
            [-1.021333727729111, 52.65665131687738],
            [-1.02147632993036, 52.656422349305998],
            [-1.022801800398667, 52.65698167561542],
            [-1.024166934844803, 52.656557804161579],
            [-1.024983314352965, 52.656676897413966],
            [-1.025784935769336, 52.657194122022467],
            [-1.026392310489042, 52.657005826623376],
            [-1.02701454586908, 52.657812846176249],
            [-1.028043129570272, 52.65743111735231],
            [-1.029360803288492, 52.658481161228522],
            [-1.030373943667575, 52.657527520205349],
            [-1.032755258039228, 52.658216731758401],
            [-1.032595397457159, 52.658627170254775],
            [-1.034275278011789, 52.658812577256633],
            [-1.034659359342162, 52.658558591090909],
            [-1.038597382431067, 52.658989752079059],
            [-1.039575186557411, 52.658897889167704],
            [-1.03955034964887, 52.658681927530608],
            [-1.03820187044908, 52.656733644891467],
            [-1.037789501355818, 52.653967664161449],
            [-1.04174066719617, 52.653847740622616],
            [-1.041897554150913, 52.654109719471791],
            [-1.045509343557257, 52.654368103470951],
            [-1.045615383719767, 52.6545901117642],
            [-1.048621275505904, 52.65497747204126],
            [-1.058953698995003, 52.656407552082612],
            [-1.05912227041923, 52.657232374784748],
            [-1.056752686413666, 52.663813103957231],
            [-1.057645327641002, 52.663902911066884],
            [-1.058685414284472, 52.663461674521123],
            [-1.058901919074704, 52.663707921369955],
            [-1.059672533265019, 52.663558509529956],
            [-1.0597715963725, 52.663834389135182],
            [-1.062202440866137, 52.663835663837112],
            [-1.0644623031132, 52.664712063649731],
            [-1.066629065154388, 52.665728829791952],
            [-1.067874093647549, 52.666842595969563],
            [-1.068792623270687, 52.666901057388415],
            [-1.06935267766115, 52.667336974463616],
            [-1.069765209967307, 52.667129846556364],
            [-1.074933451865663, 52.667968597459868],
            [-1.075086672689917, 52.669606867897883],
            [-1.085742188145293, 52.669224737018439],
            [-1.08810774504066, 52.669890233498379],
            [-1.088751604550871, 52.669720778732362],
            [-1.089320040022463, 52.670262746500747],
            [-1.090558514799817, 52.668520108748098],
            [-1.105241496621679, 52.67184574255792],
            [-1.105495114920195, 52.671520422017736],
            [-1.109154896801674, 52.672779601084351],
            [-1.11013456882144, 52.67143846564781],
            [-1.11465982883581, 52.672688684181459],
            [-1.118819291101415, 52.673167370685064],
            [-1.118787791260135, 52.672748205217296],
            [-1.119461813102903, 52.672692980386707],
            [-1.119777363652235, 52.671782844250153],
            [-1.121017664819444, 52.671807328999101],
            [-1.120759735163684, 52.670584582017725],
            [-1.121423363143324, 52.669570940821465],
            [-1.125903503562432, 52.667455459501319],
            [-1.128258843091152, 52.665092263765004],
            [-1.129096147765752, 52.665370813086867],
            [-1.129365960456689, 52.669131490822359],
            [-1.12989990638676, 52.669201037253025],
            [-1.129867186763172, 52.669508253116248],
            [-1.132987204124913, 52.669746869305101],
            [-1.133036905794814, 52.673139135752457],
            [-1.135200837404255, 52.673201686937439],
            [-1.135473967559293, 52.675399019888538],
            [-1.137719851961166, 52.675361435053624],
            [-1.136694238011096, 52.67989747974071],
            [-1.140786672523176, 52.684367325189498],
            [-1.14326949502132, 52.687137999998683],
            [-1.147525567227123, 52.690643270397821],
            [-1.151133084822453, 52.688484604023003],
            [-1.151322228796342, 52.688877917473221],
            [-1.152383016636834, 52.688418928938212],
            [-1.154245741026317, 52.689639572187879],
            [-1.155052891196376, 52.689596780746363],
            [-1.154629219252153, 52.68985446814257],
            [-1.157016809438766, 52.691023969370114],
            [-1.156771799326642, 52.691223600999116],
            [-1.157259092069086, 52.691503953683252],
            [-1.161040282417323, 52.690529291167053],
            [-1.163071063389915, 52.687929380744329],
            [-1.165031108180633, 52.688793641954518],
            [-1.166582151463468, 52.686587645845755],
            [-1.170743633163437, 52.683543176600118],
            [-1.171935949304768, 52.683307882811889],
            [-1.172981029706131, 52.682643631248347],
            [-1.173329493180363, 52.681737182675242],
            [-1.172786002023292, 52.681456503540502],
            [-1.173721360006292, 52.681195126282034],
            [-1.173033864118087, 52.679767232594649],
            [-1.17371745082343, 52.679755816371717],
            [-1.173574299935895, 52.679424889419458],
            [-1.17483685717588, 52.679065995595337],
            [-1.174150713388658, 52.67905672366399],
            [-1.174182545109835, 52.678623632178635],
            [-1.175058180453147, 52.678309687057272],
            [-1.176964505419022, 52.678480257947989],
            [-1.177993930522385, 52.678088247484105],
            [-1.178198271081124, 52.676814896121243],
            [-1.179092400002483, 52.676378786815086],
            [-1.178471072758347, 52.675360422506181],
            [-1.177985717151686, 52.675373240424996],
            [-1.177899518653667, 52.674055621837923],
            [-1.178955487791685, 52.673188221107232],
            [-1.177962983284251, 52.671461574700707],
            [-1.180237154289136, 52.669345812946183],
            [-1.179019106512994, 52.669082970284187],
            [-1.177507398550945, 52.667606237777235],
            [-1.177094318363031, 52.665979792438208],
            [-1.177106741620209, 52.665318220691432],
            [-1.177518186364352, 52.665219489714858],
            [-1.176731306466308, 52.664287167413612],
            [-1.185295616449054, 52.660625402991322],
            [-1.187334165566919, 52.66104124407795],
            [-1.188478854728303, 52.66038562929841],
            [-1.189552656731715, 52.660683349306197],
            [-1.192048717701629, 52.661387216259129],
            [-1.19307232716433, 52.660318094917784],
            [-1.193396967034411, 52.660432678106204],
            [-1.196713020346012, 52.66165534693198],
            [-1.195560160326272, 52.662277722736675],
            [-1.196153716937732, 52.662242193171352],
            [-1.196496613691261, 52.662894488252363],
            [-1.197188444047387, 52.662821861870249],
            [-1.197542911129281, 52.66340860581451],
            [-1.198101918710885, 52.663403398296374],
            [-1.199688310661462, 52.664383229911991],
            [-1.20081040800793, 52.664798940988938],
            [-1.202708108021918, 52.664441334029618],
            [-1.202800063874657, 52.666539296851468],
            [-1.201361055062308, 52.66703844061221],
            [-1.20162717955681, 52.668826526179316],
            [-1.207266058369104, 52.670364776515299],
            [-1.207932997987518, 52.671265526153341],
            [-1.212637507038312, 52.672487122117907],
            [-1.212147109657518, 52.674427477917035],
            [-1.213769770972601, 52.674662096956567],
            [-1.21491168256785, 52.672178566412683],
            [-1.215878844002289, 52.672428592415592],
            [-1.216976328903114, 52.671593493068769],
            [-1.216611361747298, 52.670914143744874],
            [-1.221080338765222, 52.66967331616349],
            [-1.226599544926282, 52.669909022002983],
            [-1.229390243020787, 52.671413220151358],
            [-1.227890715465223, 52.672417523098417],
            [-1.230242675414154, 52.67541925216576],
            [-1.23461909376578, 52.675924944827933],
            [-1.234806883766826, 52.677028314779072],
            [-1.23618930702626, 52.677565832093158],
            [-1.2357283442795, 52.677875709207356],
            [-1.236041358708563, 52.678161806084219],
            [-1.239315484338956, 52.679814515530957],
            [-1.242387874292009, 52.683224260497965],
            [-1.244742792225583, 52.68460124519634],
            [-1.249630997128418, 52.684741953561449],
            [-1.252046423293181, 52.68432659949498],
            [-1.25235674209662, 52.684866149250844],
            [-1.254688312431749, 52.684422344162911],
            [-1.256689998562021, 52.684585937472463],
            [-1.257436016632405, 52.683338325874622],
            [-1.259018382795277, 52.682053686206061],
            [-1.265023353012619, 52.684739464946247],
            [-1.265208952378283, 52.685492165701866],
            [-1.265920613618332, 52.685662885231096],
            [-1.265491733905909, 52.686272437540389],
            [-1.269080157598222, 52.687278996305857],
            [-1.271659826186732, 52.68854894960387],
            [-1.279363457234268, 52.691146425796873],
            [-1.281426951233722, 52.692165806688585],
            [-1.281598477287751, 52.692803325089415],
            [-1.280200165997778, 52.695239178011683],
            [-1.275293702198841, 52.698270330545981],
            [-1.27573401964424, 52.698667673089858],
            [-1.278465533326338, 52.69822943766782],
            [-1.279947132716123, 52.698838062429623],
            [-1.282634061341285, 52.700215401767274],
            [-1.285377786094449, 52.702565718405779],
            [-1.286210881248651, 52.705656933276849],
            [-1.285247949260814, 52.710062426794451],
            [-1.285899005850885, 52.712071071207845],
            [-1.284868494470032, 52.714079474157877],
            [-1.282722002239087, 52.714623880814187],
            [-1.280529016420072, 52.714841637461966],
            [-1.277526574598607, 52.716384914743806],
            [-1.266305664827595, 52.723419771811223],
            [-1.262476041238751, 52.724252750996428],
            [-1.266361396992649, 52.728570359971535],
            [-1.262559919630056, 52.729990548605663],
            [-1.263369627905881, 52.730888263334116],
            [-1.260431864152871, 52.731920890598403],
            [-1.26007485914555, 52.732364557243365],
            [-1.257907072136328, 52.732870626298002],
            [-1.255104313529181, 52.734308506011331],
            [-1.252658905731569, 52.734931400983172],
            [-1.25297183711295, 52.735252508636378],
            [-1.250789702000907, 52.736064009807755],
            [-1.249535820757499, 52.735254197474141],
            [-1.247148778389619, 52.736350210288379],
            [-1.249813110348319, 52.738254938470739],
            [-1.25119645252662, 52.738516289624286],
            [-1.251125292962427, 52.74085407839798],
            [-1.258518269133534, 52.744420898893495],
            [-1.263976246711004, 52.744553768242767],
            [-1.264762618933614, 52.744831033023829],
            [-1.267090889645693, 52.745739018179755],
            [-1.265933873758291, 52.747065045385213],
            [-1.266872864007768, 52.747432238448617],
            [-1.267836244299212, 52.745637540714526],
            [-1.272567666340025, 52.742173225818526],
            [-1.275305972640563, 52.739526319225789],
            [-1.279798761967847, 52.738064073807095],
            [-1.286673136602528, 52.734676012560982],
            [-1.287495020279142, 52.733953680057439],
            [-1.293418220900362, 52.737022244803093],
            [-1.294371019524291, 52.737085449557362],
            [-1.295431262262513, 52.738017694084242],
            [-1.297806952635132, 52.740508451446274],
            [-1.297921816337323, 52.743039742195982],
            [-1.299067869515468, 52.745377555596036],
            [-1.300342157695906, 52.746468338638905],
            [-1.301280921448492, 52.746588938750349],
            [-1.30253751417196, 52.747401811022577],
            [-1.30541150432734, 52.748519910176547],
            [-1.306337259671834, 52.74834373308363],
            [-1.30684176201692, 52.74782617621652],
            [-1.308563845138816, 52.74778588080919],
            [-1.311399244903806, 52.750235885803164],
            [-1.312610758743175, 52.750259990412303],
            [-1.313848216263277, 52.749103882782705],
            [-1.319059178108718, 52.749924095853743],
            [-1.321298622822238, 52.75129886686571],
            [-1.321398049066198, 52.753291555130915],
            [-1.323748465353562, 52.754883564932541],
            [-1.324900636271925, 52.754825399178102],
            [-1.325774342706311, 52.75380104518721],
            [-1.328453083126494, 52.753228307639667],
            [-1.329293700347214, 52.752325101496744],
            [-1.332387346200814, 52.752999693609773],
            [-1.331517410804745, 52.755531690123476],
            [-1.334472396047168, 52.756960580247288],
            [-1.333394854612623, 52.760184529618627],
            [-1.335074716558938, 52.761779734687671],
            [-1.334476402459935, 52.761851894397466],
            [-1.334778263601503, 52.763146303857667],
            [-1.334022534057777, 52.763821685805866],
            [-1.32906593760916, 52.766382798306878],
            [-1.328505607415171, 52.76822071657255],
            [-1.329118076577158, 52.769741638264591],
            [-1.327773476100078, 52.772453407987612],
            [-1.328056912169455, 52.774647594501147],
            [-1.327710412948429, 52.775682141607085],
            [-1.322683996230271, 52.779096609591953],
            [-1.318756135578688, 52.785409149048085],
            [-1.315222722273701, 52.785319598033084],
            [-1.314487147662912, 52.784668096637176],
            [-1.313193705114751, 52.784513189515806],
            [-1.31149808909896, 52.784569890334723],
            [-1.308381423433867, 52.785226896282182],
            [-1.305267401689789, 52.784966889872791],
            [-1.303325603026476, 52.78400350325559],
            [-1.299983645335127, 52.784373094856164],
            [-1.296333186900027, 52.784101598249727],
            [-1.293097839148826, 52.783377584380737],
            [-1.291420167526884, 52.782330172540917],
            [-1.290000862810224, 52.783814877235734],
            [-1.288626670676962, 52.784163539956623],
            [-1.285573609546192, 52.783723587859903],
            [-1.284268104673661, 52.783950353915202],
            [-1.283092545257292, 52.783937866026292],
            [-1.282604985001715, 52.783652644341146],
            [-1.279885817065687, 52.783829457479584],
            [-1.279735599164857, 52.783318830991796],
            [-1.279272880169333, 52.78323691311995],
            [-1.278676678164916, 52.783157778229196],
            [-1.274834721365409, 52.787553697544261],
            [-1.275992428102643, 52.78793293703886],
            [-1.278824652385063, 52.788199191606402],
            [-1.279614732745755, 52.788664260406208],
            [-1.27962171409678, 52.792282646390902],
            [-1.279141032632179, 52.794269142362765],
            [-1.27818905609919, 52.795067933161953],
            [-1.278892158136157, 52.796046685952227],
            [-1.276846385168991, 52.796708466692678],
            [-1.276489184879288, 52.798661542288961],
            [-1.275659663568298, 52.798687048835532],
            [-1.276455917925949, 52.800309144538815],
            [-1.274569889946725, 52.800537659014267],
            [-1.274116639402585, 52.802282478513952],
            [-1.272438101153607, 52.802248830699746],
            [-1.271905772634992, 52.804636816327232],
            [-1.270992286831712, 52.804531425544376],
            [-1.268581141425275, 52.807753770910523],
            [-1.261932754567759, 52.810433796154818],
            [-1.260027828371085, 52.807112846629643],
            [-1.255370198545947, 52.804132419533651],
            [-1.253901112248823, 52.80433804935457],
            [-1.251794703967823, 52.805549185827019],
            [-1.250735147529097, 52.805140665610317],
            [-1.24901424108803, 52.805752776451186],
            [-1.245373094692196, 52.806143213401903],
            [-1.243813603305205, 52.805225334142079],
            [-1.241517886642538, 52.805276314372925],
            [-1.239841131143233, 52.804114013069075],
            [-1.2382432692693, 52.803790031718108],
            [-1.231876143642954, 52.800621496741698],
            [-1.231149353821998, 52.79860220331804],
            [-1.231974291006058, 52.797743634110915],
            [-1.231899939296718, 52.79691250576851],
            [-1.230841546543896, 52.796208052951563],
            [-1.229268098367899, 52.79326361145138],
            [-1.22841254464619, 52.7930881482595],
            [-1.226511943474637, 52.792468974647221],
            [-1.225742554018486, 52.791618028013403],
            [-1.224640749219969, 52.792284165036449],
            [-1.2227897215922, 52.792133618776312],
            [-1.221958710993572, 52.79174880949062],
            [-1.218239360030312, 52.793244532212086],
            [-1.21840876294346, 52.793722101960256],
            [-1.217559738791429, 52.79376325526885],
            [-1.21713199298179, 52.79338556446217],
            [-1.215263652016333, 52.794855630283855],
            [-1.211245070785848, 52.79490990437877],
            [-1.206928641255466, 52.794215907164372],
            [-1.206813529161444, 52.79369553289262],
            [-1.206284347346405, 52.793518494386419],
            [-1.200294341448995, 52.793767760635099],
            [-1.198489679412446, 52.793031917209099],
            [-1.198654820352419, 52.79211518217754],
            [-1.19909773379861, 52.79189972124567],
            [-1.199730430239752, 52.79209007549877],
            [-1.200050960307835, 52.791836029081985],
            [-1.19916461227248, 52.790599360369029],
            [-1.197408818562901, 52.790761902371734],
            [-1.19778513207184, 52.790296980762967],
            [-1.198689653353249, 52.790380401833858],
            [-1.198166922553189, 52.789533636234175],
            [-1.197735055754672, 52.789391377501254],
            [-1.196143398147399, 52.790144735623372],
            [-1.191604426658599, 52.792775767182718],
            [-1.191121094971693, 52.793408946771642],
            [-1.191489751117144, 52.793922074273091],
            [-1.19096688940847, 52.794127073357679],
            [-1.191505929115394, 52.79448943562651],
            [-1.191382284349176, 52.794839192390519],
            [-1.189176833265367, 52.795884036304116],
            [-1.188943611525265, 52.796542288228473],
            [-1.186752652366216, 52.797677083368974],
            [-1.184323069568862, 52.79808293083962],
            [-1.183080857240093, 52.798125630310885],
            [-1.182833348695796, 52.79787850752686],
            [-1.182479914093588, 52.798297691785713],
            [-1.180038448722735, 52.799252641586548],
            [-1.178409024091135, 52.801581398745959],
            [-1.176682278361543, 52.80261314167192],
            [-1.175173758966778, 52.803160931417835],
            [-1.172107295647147, 52.803025416933494],
            [-1.170477350404696, 52.803714337812117],
            [-1.170600166078738, 52.804276154150145],
            [-1.163281466734341, 52.806501892853042],
            [-1.163054134340273, 52.806211720526456],
            [-1.154079565846293, 52.806273069171077],
            [-1.149929739145873, 52.804859009474299],
            [-1.14746724454793, 52.805013054993992],
            [-1.145007852094043, 52.803948963351168],
            [-1.143969831468291, 52.803856080469963],
            [-1.137281303846316, 52.805655951712353],
            [-1.132993950747608, 52.805369409316263],
            [-1.130899550374973, 52.805806278803601],
            [-1.128833110899775, 52.807662796078617],
            [-1.127422797174665, 52.808305093528148],
            [-1.125731436492836, 52.810161616096309],
            [-1.123058959760967, 52.810925810643965],
            [-1.12125445645294, 52.812345431653185],
            [-1.120604053948793, 52.814285095035522],
            [-1.121519034520424, 52.817400516827838],
            [-1.120072771714522, 52.81789143382349],
            [-1.119965421071927, 52.818798600476605],
            [-1.116948171391139, 52.81955650646514],
            [-1.111558665797342, 52.819990957939815],
            [-1.102047237030591, 52.820181905214852],
            [-1.094197628674163, 52.821948095723492],
            [-1.091491626849789, 52.820059352955667],
            [-1.08787865607012, 52.821033109474321],
            [-1.083754263118944, 52.822890098571179],
            [-1.080763890725831, 52.823455812951622],
            [-1.07871146656516, 52.824227407012529],
            [-1.077064556715833, 52.823997963976112],
            [-1.074442022645238, 52.824705719244037],
            [-1.073249208349989, 52.824129161988765],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000131",
        LAD13CDO: "31UD",
        LAD13NM: "Harborough",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.855554319701445, 52.68495454277484],
            [-0.854753256518583, 52.683285433363395],
            [-0.856381824247721, 52.67977972278463],
            [-0.859053517627899, 52.676079057706197],
            [-0.858274749267251, 52.674860589696706],
            [-0.858988233755328, 52.674440427798352],
            [-0.858753464272561, 52.673744129805002],
            [-0.857384966494855, 52.673760614968565],
            [-0.855405876173646, 52.673315390710314],
            [-0.843046109092016, 52.675496887122954],
            [-0.841129660078912, 52.675537503401834],
            [-0.833990739732702, 52.672685949087423],
            [-0.831910591078685, 52.670605825480976],
            [-0.830638721964823, 52.667781163316839],
            [-0.829242060953586, 52.662770641496159],
            [-0.830182374702568, 52.660724771630328],
            [-0.8295583536477, 52.658734479343451],
            [-0.827720030729677, 52.658810709045603],
            [-0.825404948175274, 52.658084757481085],
            [-0.823410585146986, 52.658026319081628],
            [-0.816976544166865, 52.656647909755527],
            [-0.809538072028319, 52.657592920310115],
            [-0.809496483326436, 52.657164569201804],
            [-0.811592438191818, 52.655000984914111],
            [-0.813531692316163, 52.652010494630076],
            [-0.817197387963213, 52.649943427094648],
            [-0.821470773258974, 52.65038606177928],
            [-0.822613812458189, 52.65125239001366],
            [-0.823122760295422, 52.653383625497703],
            [-0.823615846983249, 52.653353458857318],
            [-0.823237615669851, 52.649160273505728],
            [-0.822281201913869, 52.647235860858736],
            [-0.820189380013058, 52.647906402006015],
            [-0.816094522921715, 52.645952451517239],
            [-0.808702960386264, 52.646107632586414],
            [-0.808433904067834, 52.645891859638461],
            [-0.809717819539446, 52.644277532595169],
            [-0.80875779134605, 52.642022129927241],
            [-0.809910446589739, 52.640284213776376],
            [-0.807993426013919, 52.639204999401983],
            [-0.804140086163351, 52.640420314083315],
            [-0.801761541340084, 52.638821199875323],
            [-0.802027068967527, 52.637756743913059],
            [-0.804446713851762, 52.634864748376884],
            [-0.804473558105818, 52.6322533555227],
            [-0.805151775909981, 52.631710892596182],
            [-0.80880326794737, 52.630336188517227],
            [-0.809933250666238, 52.629422447067896],
            [-0.809862210032889, 52.627302734141523],
            [-0.810968091241952, 52.625966199348397],
            [-0.805646831503822, 52.621776268093953],
            [-0.805835009535487, 52.620669666576276],
            [-0.806027444580023, 52.620437859302235],
            [-0.807934417606129, 52.620386936669242],
            [-0.808941691068588, 52.619794719628331],
            [-0.809852187273589, 52.616996212589406],
            [-0.809537034620493, 52.615654397118988],
            [-0.810926038217421, 52.614923952200961],
            [-0.811331432757788, 52.614080238772196],
            [-0.814790593546908, 52.61194464543464],
            [-0.815693773401702, 52.611696555900593],
            [-0.817463840455677, 52.611778966610593],
            [-0.818634986549185, 52.610902413033891],
            [-0.81936250677785, 52.609660918476365],
            [-0.820002788603397, 52.605839244363501],
            [-0.818836201111526, 52.604576173896902],
            [-0.818555794031948, 52.602868822962357],
            [-0.818993679378757, 52.600093397381855],
            [-0.820961108053732, 52.596436872324595],
            [-0.819687556481416, 52.596745142396053],
            [-0.819864049969265, 52.596330650996123],
            [-0.819237255925142, 52.59619584389894],
            [-0.819232735834556, 52.595704929617398],
            [-0.817485064907827, 52.595582309778074],
            [-0.816969102740387, 52.593690097619486],
            [-0.816540051980795, 52.593614788562512],
            [-0.815973071703305, 52.594110780464348],
            [-0.814386534785099, 52.594092214342844],
            [-0.813308692262107, 52.594479690981018],
            [-0.812071395282076, 52.594157124263432],
            [-0.812122375670554, 52.594946982705025],
            [-0.811396031030511, 52.594673584152979],
            [-0.811840518649601, 52.595324445003392],
            [-0.810109087637067, 52.594821589852451],
            [-0.809554080523938, 52.595253840511205],
            [-0.809349958594854, 52.596193971105805],
            [-0.808623218693366, 52.595826153617523],
            [-0.807823377493062, 52.595976335573049],
            [-0.807594539291274, 52.596357018550506],
            [-0.805296006023942, 52.596023693811453],
            [-0.80456320577074, 52.596313879440288],
            [-0.804150463713813, 52.595747820200202],
            [-0.803538537074816, 52.596370062444187],
            [-0.802390770821502, 52.596446568827957],
            [-0.801309386796839, 52.597336458316512],
            [-0.800843486605515, 52.59704225545952],
            [-0.798528815858713, 52.597137473042757],
            [-0.797189985261609, 52.596588064511408],
            [-0.796245414473025, 52.596899423168203],
            [-0.794825504179211, 52.596508292061117],
            [-0.793664416289045, 52.596800345591312],
            [-0.793059349669971, 52.59754307412561],
            [-0.792446646756124, 52.597596165800333],
            [-0.79156927704333, 52.596536252483837],
            [-0.791246070308902, 52.596799966811624],
            [-0.789856006702546, 52.596774086767631],
            [-0.79001581996612, 52.596010644500737],
            [-0.788258272580306, 52.596408929809421],
            [-0.786268557974234, 52.595911174059523],
            [-0.786027816233401, 52.595220049512434],
            [-0.78514355871447, 52.59495026498611],
            [-0.783947053033645, 52.59390769798415],
            [-0.784321572660762, 52.593551030748692],
            [-0.784628125795364, 52.593676444751168],
            [-0.784786062471772, 52.593037055882384],
            [-0.784250265955827, 52.592980313076218],
            [-0.784192690366211, 52.593353719144979],
            [-0.783680596683956, 52.59324057808989],
            [-0.783791270141982, 52.592442475587418],
            [-0.782333954648151, 52.592131722097982],
            [-0.782340646324981, 52.591144654102209],
            [-0.780801704576582, 52.590691894299525],
            [-0.780576365635175, 52.590088122247373],
            [-0.779116367050627, 52.590196249991202],
            [-0.778900121560267, 52.589266219593796],
            [-0.778096825446545, 52.589435044888731],
            [-0.778446560203016, 52.58981085161129],
            [-0.77736846104789, 52.589616325655172],
            [-0.77708985079636, 52.588541802721814],
            [-0.776543571604932, 52.588068664364179],
            [-0.775970976441823, 52.588116690467182],
            [-0.776274254447233, 52.587668509733952],
            [-0.777034005238315, 52.587472276277168],
            [-0.776727619868828, 52.587131076067237],
            [-0.77553474762849, 52.587455887668632],
            [-0.775161573634936, 52.587022291520967],
            [-0.776080372879091, 52.586468095627581],
            [-0.774682116741148, 52.586059862229177],
            [-0.774393484011491, 52.585453624622829],
            [-0.773844158387103, 52.585462323641615],
            [-0.774306799247429, 52.585068840381169],
            [-0.773919272668534, 52.584357290144276],
            [-0.771234709974144, 52.58440049335254],
            [-0.77057580672171, 52.583736462036754],
            [-0.771342141127595, 52.583356031563831],
            [-0.769683914709806, 52.583223741729988],
            [-0.769534331590778, 52.582820319590702],
            [-0.768892942225621, 52.582902658852809],
            [-0.768041397789729, 52.582162889652032],
            [-0.767351715621714, 52.582283376687023],
            [-0.767624990548891, 52.582590993103388],
            [-0.767111857942647, 52.582836483354129],
            [-0.765864910503464, 52.582302955815763],
            [-0.765385293108114, 52.582511028397583],
            [-0.765961143369053, 52.581714191880252],
            [-0.764802895305771, 52.581177081842604],
            [-0.765208688776261, 52.580654478850938],
            [-0.764347806290779, 52.57988311877785],
            [-0.764764643950009, 52.579544934626483],
            [-0.764555021404318, 52.579074350907327],
            [-0.763866263369414, 52.579163360941251],
            [-0.763415872146985, 52.578753194379352],
            [-0.763705441151865, 52.577377996329538],
            [-0.762962216540153, 52.576781367396478],
            [-0.763165529980531, 52.576006724105113],
            [-0.762514447053472, 52.57444459079808],
            [-0.763019846373878, 52.572111477922796],
            [-0.76248863933362, 52.571587184281242],
            [-0.763841751383221, 52.570379525701469],
            [-0.763561570356582, 52.569848866348174],
            [-0.762005505954716, 52.569722927201518],
            [-0.762550140178837, 52.5692449377771],
            [-0.762285076207357, 52.568807033957384],
            [-0.761802065174651, 52.56908787842535],
            [-0.761100506955922, 52.568903430826182],
            [-0.761439306638285, 52.568085255016292],
            [-0.760040703831943, 52.566877596682865],
            [-0.759906476154832, 52.564888419435299],
            [-0.755734152508588, 52.565099080601598],
            [-0.755760116961873, 52.564546445090777],
            [-0.754613648990633, 52.564342898289141],
            [-0.754294819416011, 52.563619415185343],
            [-0.75354959994236, 52.563782390668791],
            [-0.752306505090365, 52.562981741738199],
            [-0.751520472897656, 52.562140048278579],
            [-0.75174981599774, 52.561752283277919],
            [-0.750101400464071, 52.560371053162399],
            [-0.749696974583329, 52.559548657943871],
            [-0.749978993738755, 52.559227981297376],
            [-0.747291403645389, 52.557928337106098],
            [-0.747154509190771, 52.557037738442268],
            [-0.746469927511767, 52.556887545805729],
            [-0.745100233531775, 52.555365347374924],
            [-0.744931322080386, 52.554306285656082],
            [-0.743465517639839, 52.553313482325905],
            [-0.74240949565326, 52.551764011919133],
            [-0.741095868001395, 52.55179770141541],
            [-0.741174896896353, 52.551355314363249],
            [-0.740735925210115, 52.551336261855347],
            [-0.740777608706364, 52.55075592451135],
            [-0.741132858364917, 52.550711153885942],
            [-0.740912238001634, 52.550226022808545],
            [-0.739566731002516, 52.549829614285173],
            [-0.740166416434232, 52.549283086161822],
            [-0.739446087168213, 52.549407578444431],
            [-0.739585872054449, 52.548852559804502],
            [-0.738877588821699, 52.548865695669598],
            [-0.739402059018508, 52.548370514601366],
            [-0.738949719880036, 52.547481040453178],
            [-0.740436869575396, 52.54637846252789],
            [-0.74005061321491, 52.54585920162009],
            [-0.740285444691619, 52.545589290664303],
            [-0.740731308091957, 52.545880828153379],
            [-0.740835995344934, 52.544541469775403],
            [-0.74154803623075, 52.544961700729907],
            [-0.74193432573541, 52.544087441916389],
            [-0.74210610490974, 52.544474956203317],
            [-0.742679922020382, 52.544417219385728],
            [-0.743005089223998, 52.543874953587007],
            [-0.742381199440804, 52.543363066363113],
            [-0.743737215387119, 52.543075384518986],
            [-0.743409210325759, 52.541135366436578],
            [-0.741643868332527, 52.540733623936497],
            [-0.742518544196032, 52.540104597217955],
            [-0.742219702294854, 52.539468496567004],
            [-0.739704850910206, 52.539395001526358],
            [-0.738026132137356, 52.538649793019346],
            [-0.737188542569587, 52.537214079617073],
            [-0.735959873057152, 52.537128144644335],
            [-0.73583459353894, 52.536767189430826],
            [-0.733371007110663, 52.536454960732087],
            [-0.732620937056476, 52.53603157480083],
            [-0.732059481434707, 52.536636013763037],
            [-0.731306910885777, 52.536760110633146],
            [-0.730832876000638, 52.5362236944625],
            [-0.730518630185067, 52.536435196711651],
            [-0.728553815984681, 52.535803659832098],
            [-0.729820356520467, 52.534473184050647],
            [-0.729031593178142, 52.533809314802966],
            [-0.729860983462895, 52.533473882203076],
            [-0.729965666758932, 52.532962549766665],
            [-0.729040295933763, 52.532895079575262],
            [-0.72764815756214, 52.531736539686513],
            [-0.72558044805941, 52.531154192421887],
            [-0.724782346484115, 52.53061336267487],
            [-0.722567798943112, 52.526762246457189],
            [-0.721686310206197, 52.526609782185368],
            [-0.720918468388275, 52.525843591450375],
            [-0.719740255741377, 52.526163501680053],
            [-0.71907451158168, 52.525740040770479],
            [-0.71769605610412, 52.525494960240877],
            [-0.716843339093382, 52.525874108199865],
            [-0.714399180425951, 52.524871222854614],
            [-0.713671758077424, 52.524946931690245],
            [-0.713441539700872, 52.524451751640974],
            [-0.715312360176841, 52.523177442132216],
            [-0.715642382292012, 52.522373678678157],
            [-0.716091818714421, 52.522428002052095],
            [-0.716619286369267, 52.522991131646293],
            [-0.723599215999229, 52.52235276003573],
            [-0.723617871794118, 52.522624473388376],
            [-0.72516914648349, 52.522803918161188],
            [-0.72517086583235, 52.52315186777308],
            [-0.726846897224466, 52.523146531329786],
            [-0.726886741220976, 52.522736994620793],
            [-0.730060698088643, 52.521899014228353],
            [-0.72963130984061, 52.520893767837507],
            [-0.730183636562862, 52.520855641054524],
            [-0.730324256966441, 52.521243739535031],
            [-0.733510915636697, 52.520573026020728],
            [-0.739404861818421, 52.51861668503274],
            [-0.739269126801878, 52.517437488839612],
            [-0.74211740520616, 52.517041644525641],
            [-0.744378689646343, 52.516194478601243],
            [-0.746664671095631, 52.516858827282796],
            [-0.74784220405568, 52.516443344384633],
            [-0.74711629160589, 52.515695748101244],
            [-0.747274008445782, 52.5148136553276],
            [-0.748554263101947, 52.514307549937314],
            [-0.749573293585528, 52.514489138573623],
            [-0.749675492449725, 52.51374401014786],
            [-0.750364683421636, 52.513812423654933],
            [-0.750767046417636, 52.51323858458295],
            [-0.751629241109706, 52.513129906481311],
            [-0.751258871841465, 52.512159525156846],
            [-0.754639086124609, 52.511852603255768],
            [-0.756169766970402, 52.510977744786082],
            [-0.758408922626785, 52.511261079178929],
            [-0.76103993754107, 52.514169189906127],
            [-0.766201786916734, 52.513338449603161],
            [-0.766571527963761, 52.51439598325873],
            [-0.767828866521095, 52.514226573067717],
            [-0.770057460280815, 52.515371759014918],
            [-0.77234184549001, 52.516052675433407],
            [-0.774880090540729, 52.51825915427704],
            [-0.775385903615319, 52.518036031529363],
            [-0.775112997458164, 52.517515358925273],
            [-0.775730897948786, 52.517288899363884],
            [-0.776189800141469, 52.517798907123712],
            [-0.776747567231894, 52.517561931051773],
            [-0.777391598843901, 52.517986639968534],
            [-0.778108935763029, 52.51784120987648],
            [-0.77912483207252, 52.518089938864172],
            [-0.779882180856497, 52.517776789751451],
            [-0.780305879438257, 52.517005282608849],
            [-0.78200069010162, 52.51721782679391],
            [-0.78262301743991, 52.51645644660455],
            [-0.784065272866829, 52.516047822247444],
            [-0.783975317389969, 52.515569506494636],
            [-0.787004230749184, 52.515498094631681],
            [-0.786863865065557, 52.514655152331436],
            [-0.787478969372401, 52.514526598710979],
            [-0.787830990986972, 52.515068730730448],
            [-0.788359092926228, 52.51472800654517],
            [-0.787795117956787, 52.513694626419088],
            [-0.788874443896883, 52.513671511755746],
            [-0.793347890411463, 52.515410097065754],
            [-0.795484328102861, 52.515262837547027],
            [-0.796212190839694, 52.515699085116545],
            [-0.795792254223742, 52.515912381632916],
            [-0.796173831679875, 52.516347802982352],
            [-0.7977840444288, 52.5170159734446],
            [-0.798410371197673, 52.516854212496909],
            [-0.800493010934392, 52.518035098768159],
            [-0.801242779047113, 52.518048987610655],
            [-0.800795289017487, 52.517217337231031],
            [-0.801885260733644, 52.517239163458434],
            [-0.801352399381507, 52.516728507608484],
            [-0.801804694074579, 52.516625202023135],
            [-0.803885296600361, 52.5171784772877],
            [-0.803901590904955, 52.517555339988718],
            [-0.80326510689705, 52.517711633006819],
            [-0.803396766083981, 52.51812742178614],
            [-0.804371441228583, 52.517565481111106],
            [-0.806358396977738, 52.518477382664848],
            [-0.808586322438622, 52.518438693558629],
            [-0.808955356148849, 52.519234461680149],
            [-0.810879971506084, 52.519350008893475],
            [-0.811252823398102, 52.519077748681248],
            [-0.811119806808388, 52.517835737684024],
            [-0.812187382026696, 52.517377156639128],
            [-0.81260353925369, 52.515934776569495],
            [-0.814937130612281, 52.515523926764622],
            [-0.816472319920273, 52.516415850734617],
            [-0.817788121340642, 52.516094557090767],
            [-0.818271845291272, 52.515532093343886],
            [-0.820134799038079, 52.515523706605393],
            [-0.820646580224896, 52.515728393670635],
            [-0.820780962749641, 52.516653945065293],
            [-0.82174723593234, 52.51678224066184],
            [-0.821656410099169, 52.51747809193008],
            [-0.822651066150067, 52.517482595194913],
            [-0.822464420522777, 52.517986897578261],
            [-0.824819917897369, 52.517777455556867],
            [-0.825791224355557, 52.517110118566251],
            [-0.827161014363108, 52.517194722966266],
            [-0.826977930120453, 52.518369750397504],
            [-0.828554680131141, 52.517822567084998],
            [-0.829128449462017, 52.518248094990248],
            [-0.828754893409338, 52.518606711807962],
            [-0.829933648123311, 52.519060696283361],
            [-0.830459645950914, 52.518289125418185],
            [-0.830981292938687, 52.518791446494454],
            [-0.830465810067703, 52.519278128397424],
            [-0.83063979834899, 52.519441674184378],
            [-0.83110108842769, 52.519053350598085],
            [-0.831175527412659, 52.519469441264462],
            [-0.831761535182825, 52.51960199055285],
            [-0.831397973878565, 52.518862089700157],
            [-0.833109664155931, 52.519339283272124],
            [-0.833507425239111, 52.519122939397768],
            [-0.833393600012063, 52.518412476655598],
            [-0.834661768099402, 52.517883747521864],
            [-0.836592992878663, 52.519255796351082],
            [-0.837679373743906, 52.518745926033851],
            [-0.838087882398841, 52.519070893033224],
            [-0.838606588524412, 52.51834686395032],
            [-0.840324227434872, 52.518992131323003],
            [-0.839731176226409, 52.519909631141267],
            [-0.839937492735022, 52.520246095118324],
            [-0.840473597289501, 52.520092218195018],
            [-0.841212137729855, 52.520534584301274],
            [-0.842174839452494, 52.519514607079778],
            [-0.842923291305256, 52.520197102351055],
            [-0.843100375653247, 52.519571305335674],
            [-0.845358165950122, 52.519349711143981],
            [-0.84620292138523, 52.520352282883351],
            [-0.844971739589025, 52.521159298475212],
            [-0.84531185090279, 52.521678663643733],
            [-0.846782622093324, 52.521234492582522],
            [-0.847698787663925, 52.521369279567068],
            [-0.847807950766254, 52.520464113350435],
            [-0.848263914544939, 52.520217719422853],
            [-0.84868600129394, 52.520421411568677],
            [-0.848504458698917, 52.520826010217178],
            [-0.849188750547336, 52.520922568423288],
            [-0.849006861312324, 52.521735326920165],
            [-0.850133681988968, 52.521649182069794],
            [-0.851528034795799, 52.523059820246438],
            [-0.851997775108693, 52.522963684660908],
            [-0.851530974924347, 52.523625342322028],
            [-0.853236920407467, 52.524112076404371],
            [-0.853452419475264, 52.523704203581289],
            [-0.855244911097113, 52.524207929461291],
            [-0.855544691895938, 52.523845819686827],
            [-0.856368385658525, 52.524422869545759],
            [-0.856765970645791, 52.524324219862855],
            [-0.85654724424847, 52.523892368423262],
            [-0.857921771737776, 52.523918224016491],
            [-0.85848317334061, 52.524493627244986],
            [-0.858289329169428, 52.525602965179438],
            [-0.859955017894625, 52.525999309912699],
            [-0.858730737988484, 52.526202382621733],
            [-0.859036775715814, 52.527077396364142],
            [-0.859447949751207, 52.527194636931121],
            [-0.860348838769075, 52.526672882753743],
            [-0.860755347545371, 52.52702741900351],
            [-0.861263398375218, 52.526761697757536],
            [-0.861925442442366, 52.527210389239791],
            [-0.861502003659138, 52.527909362621308],
            [-0.862391795035509, 52.527873863840668],
            [-0.863357897495851, 52.528308386949249],
            [-0.863877682910717, 52.52707360830378],
            [-0.865929775498109, 52.526779521797252],
            [-0.866574300504536, 52.526249870110291],
            [-0.867443608754574, 52.526894706175504],
            [-0.86876994877386, 52.526964026993561],
            [-0.868837859371758, 52.525869652499658],
            [-0.871005744982508, 52.524735088810495],
            [-0.871348436868994, 52.5241315085905],
            [-0.870795900797365, 52.522341656163974],
            [-0.871297241304036, 52.520952933952593],
            [-0.872455057106548, 52.520689762509427],
            [-0.871755243988859, 52.519413657560897],
            [-0.872623172055432, 52.518382642585244],
            [-0.873769833290499, 52.518495148771244],
            [-0.874355365671613, 52.517554933653955],
            [-0.87557689758063, 52.517909075672442],
            [-0.876145064864459, 52.517358867928344],
            [-0.878366600435852, 52.517276550095517],
            [-0.877959244555868, 52.516201040107489],
            [-0.878608561128343, 52.515992323962784],
            [-0.880338034334508, 52.516191199744256],
            [-0.881345813233308, 52.515851902633244],
            [-0.880536739177629, 52.515562855858292],
            [-0.881119975950675, 52.515402048903738],
            [-0.881177140729745, 52.51489193754179],
            [-0.882369799365317, 52.515105487353466],
            [-0.883421407065443, 52.513791133912733],
            [-0.882685281491103, 52.513589996126377],
            [-0.882727191692463, 52.513099518714824],
            [-0.881842334262162, 52.513011149053852],
            [-0.882454903506821, 52.51256382054553],
            [-0.881942385642049, 52.51197191065166],
            [-0.881161825647063, 52.512303471289705],
            [-0.880812453160902, 52.51178681929305],
            [-0.880046200358733, 52.511729225621345],
            [-0.880597878287244, 52.511242671950654],
            [-0.880007669746478, 52.510172630789945],
            [-0.881028835581709, 52.509242796908133],
            [-0.880441144970451, 52.508712202732973],
            [-0.881444245484911, 52.507679704256191],
            [-0.880534614299562, 52.507004918784702],
            [-0.880928700543206, 52.506165349733564],
            [-0.879809949820569, 52.506125997604791],
            [-0.880277267945315, 52.505477719326848],
            [-0.87984541635635, 52.504904543073486],
            [-0.880098904218343, 52.504437644905508],
            [-0.88116878718415, 52.504427085546169],
            [-0.881721314117885, 52.503786799277783],
            [-0.882739275363847, 52.503382856345084],
            [-0.882303251032538, 52.501986132120557],
            [-0.881196813783235, 52.502104240362264],
            [-0.881631522816699, 52.501635455620978],
            [-0.880474213073004, 52.501204663947625],
            [-0.881403400693229, 52.501162204569475],
            [-0.881828254273334, 52.500326518923792],
            [-0.880978149599561, 52.50044614746416],
            [-0.879363035144189, 52.49982940975746],
            [-0.879467363713016, 52.498391039057125],
            [-0.878978494330348, 52.497919809068229],
            [-0.87981909051244, 52.497882816451863],
            [-0.880664962643112, 52.497115849466432],
            [-0.881805576626614, 52.496865011977953],
            [-0.882322427364598, 52.494664555388177],
            [-0.882138439596028, 52.493148838105746],
            [-0.883734377267372, 52.492245984745352],
            [-0.888961351869688, 52.491790812309404],
            [-0.891163804176746, 52.491887887219818],
            [-0.892054504705962, 52.492196507853798],
            [-0.894821465779677, 52.492279019375168],
            [-0.895614289177466, 52.49202749802086],
            [-0.896723101540728, 52.490625453211962],
            [-0.895912292685418, 52.490420103279121],
            [-0.895901111084333, 52.489925528500052],
            [-0.895144999348283, 52.489536380867229],
            [-0.894682874436186, 52.488700455832969],
            [-0.895227035492573, 52.48808430222585],
            [-0.896177694948114, 52.487996077369715],
            [-0.896497485943472, 52.487281627879135],
            [-0.897121213347235, 52.487663240147633],
            [-0.89788779693686, 52.487397074584052],
            [-0.898060706792285, 52.486901517079261],
            [-0.897283081327636, 52.486490605557627],
            [-0.897893844420228, 52.486212199430284],
            [-0.897806190223219, 52.485594643204848],
            [-0.896934035396248, 52.485490319648441],
            [-0.893415751081341, 52.485376558427454],
            [-0.892755467008105, 52.484811180386892],
            [-0.891639746643549, 52.484906822046085],
            [-0.890457627454733, 52.484363513360805],
            [-0.890514061151275, 52.484110513631471],
            [-0.887860696541889, 52.483748466824416],
            [-0.888057576399005, 52.483298999524834],
            [-0.888873853434736, 52.482988407271286],
            [-0.884547660308082, 52.481289877624405],
            [-0.885261469614196, 52.480255516575944],
            [-0.885105362196838, 52.478683423636909],
            [-0.884053409582521, 52.477311461934534],
            [-0.882231590563133, 52.476167768779099],
            [-0.882374733926221, 52.471388921663298],
            [-0.8824824827656, 52.471145400226149],
            [-0.884845181770004, 52.471501236219986],
            [-0.8862058643792, 52.469328484330966],
            [-0.889570081286036, 52.470423670900033],
            [-0.892717336610353, 52.467205831491768],
            [-0.895511645340343, 52.465486012463849],
            [-0.897401721681332, 52.462656379031486],
            [-0.898932380001853, 52.461126980459291],
            [-0.901293915359564, 52.459739238387421],
            [-0.901556820359259, 52.460825923291779],
            [-0.904591256286686, 52.462862514525632],
            [-0.906596945136638, 52.463061774647286],
            [-0.90773712034123, 52.461478307682761],
            [-0.909903804724227, 52.461474016984653],
            [-0.911270040780396, 52.463488759694521],
            [-0.914242579326945, 52.462262814559331],
            [-0.916759340861795, 52.46262931752814],
            [-0.922321925427364, 52.464185108044212],
            [-0.923804860414837, 52.464217486057819],
            [-0.930244431816898, 52.465926519827669],
            [-0.936029493600992, 52.466900197418965],
            [-0.935865754257328, 52.467219681289919],
            [-0.942204794235521, 52.467755710408966],
            [-0.942657922069162, 52.470838961346281],
            [-0.944226235254717, 52.470635398744136],
            [-0.943872436688693, 52.472746774636441],
            [-0.944428358270848, 52.47283264854849],
            [-0.945088843069391, 52.472486116736548],
            [-0.945534995400413, 52.472791270587528],
            [-0.9453456348924, 52.473137509851753],
            [-0.9464586853445, 52.47319777144174],
            [-0.946644903256475, 52.473907869539666],
            [-0.946110440227193, 52.47427890741686],
            [-0.946546948776161, 52.474619033765514],
            [-0.946476152421071, 52.475302569540524],
            [-0.947101641477701, 52.475683034682092],
            [-0.948583746470612, 52.475952438406246],
            [-0.948497432458048, 52.476361631508993],
            [-0.948908008822096, 52.47630864006662],
            [-0.94938856763819, 52.476964708266358],
            [-0.950344580504148, 52.476884188063813],
            [-0.950824200852379, 52.477209397094107],
            [-0.951860649233662, 52.477027986845648],
            [-0.951888887829146, 52.477388749953533],
            [-0.952420541572975, 52.477445601899738],
            [-0.958898952630685, 52.475764944276115],
            [-0.964322358297299, 52.476420284086352],
            [-0.964676568179109, 52.476823453897843],
            [-0.967861485348097, 52.477192910030929],
            [-0.969245856563854, 52.476170189310245],
            [-0.96926413013436, 52.475514053817527],
            [-0.970507363595935, 52.475614780151382],
            [-0.970437091869714, 52.475029796789549],
            [-0.971436673446721, 52.474845197534997],
            [-0.971679467129829, 52.474345646891692],
            [-0.972809084059605, 52.473760297968809],
            [-0.972720493260653, 52.473202127872177],
            [-0.971651299270099, 52.473027418341353],
            [-0.972096900127539, 52.472416350825362],
            [-0.975023914453069, 52.471771961448063],
            [-0.975060628301394, 52.471015292814606],
            [-0.975534167067628, 52.471227070572965],
            [-0.976237930175537, 52.470653284780198],
            [-0.976799141940549, 52.471020449852865],
            [-0.978539187397249, 52.470323449092156],
            [-0.979184448728238, 52.470494439946322],
            [-0.97898174629446, 52.470220283728551],
            [-0.979334894726812, 52.470100162879696],
            [-0.979963107093802, 52.470435525618569],
            [-0.982138906906883, 52.470564843663901],
            [-0.982735208749297, 52.471583182511658],
            [-0.984223690118056, 52.471578888237808],
            [-0.984285686982464, 52.471827553636658],
            [-0.985211481819581, 52.471324846171669],
            [-0.989313128179204, 52.47048249551171],
            [-0.98972859330881, 52.470275669782581],
            [-0.989484892419768, 52.47005332564828],
            [-0.990462061827352, 52.46987466796211],
            [-0.990852609246627, 52.469405108014236],
            [-0.992343104932234, 52.469825072603953],
            [-0.992853449895704, 52.469523746412904],
            [-0.994046140143509, 52.470028360423122],
            [-0.994066500007709, 52.469651838726435],
            [-0.994989125287945, 52.469410646731795],
            [-0.996311154637809, 52.470378439141896],
            [-0.997926280798126, 52.47101875404195],
            [-1.000704944424613, 52.470923581005842],
            [-1.001027808423861, 52.469667661957608],
            [-1.001439095571015, 52.46957763422639],
            [-1.002839338166806, 52.466895042365401],
            [-1.003447342464517, 52.466696983404518],
            [-1.003468756736645, 52.466270123210563],
            [-1.004412917566246, 52.46630863854552],
            [-1.004328370424905, 52.46594561691839],
            [-1.005649242214086, 52.465009144228802],
            [-1.010922871320079, 52.464042833405138],
            [-1.010844968163508, 52.463709540086157],
            [-1.013806975386996, 52.462830738059466],
            [-1.014718623850244, 52.462938125869947],
            [-1.016784882518189, 52.461761399364924],
            [-1.020371291247085, 52.460988312327579],
            [-1.022710550050197, 52.460100534155181],
            [-1.024654641462916, 52.458708691879643],
            [-1.025067702823924, 52.45899798862294],
            [-1.02691542178407, 52.458968248186132],
            [-1.027753131634724, 52.45848785958615],
            [-1.028734414421642, 52.456096401889354],
            [-1.03041859924701, 52.454795830564784],
            [-1.034661704656555, 52.453295879573233],
            [-1.035793996879605, 52.452322470552204],
            [-1.037865685048069, 52.45152301518084],
            [-1.041134830370698, 52.44836338497619],
            [-1.044523034759271, 52.445731459329821],
            [-1.044111016682301, 52.444509940968047],
            [-1.042790160823217, 52.443475261662485],
            [-1.039696257402545, 52.443334211394586],
            [-1.038219608127904, 52.443717790010105],
            [-1.037231530620185, 52.444432575772524],
            [-1.035146521399612, 52.443229762839138],
            [-1.033840931429071, 52.440432100606188],
            [-1.034286484954803, 52.436268712513801],
            [-1.035028571115862, 52.435091638331315],
            [-1.037655846864342, 52.433734821094966],
            [-1.039261389779242, 52.430722618385744],
            [-1.042288282562999, 52.430657261593346],
            [-1.044940939094569, 52.429328358692999],
            [-1.049509516836489, 52.425823858683898],
            [-1.050006937551453, 52.425192236723149],
            [-1.049873201831949, 52.422245920786253],
            [-1.050972082999482, 52.421885239412035],
            [-1.051926511755185, 52.422019657949136],
            [-1.053111524708209, 52.421549964746639],
            [-1.053295825192224, 52.421870597874729],
            [-1.054896010187436, 52.421927448183482],
            [-1.056157187233212, 52.423123347313805],
            [-1.058295154310422, 52.423548551076763],
            [-1.059067458617223, 52.42312226154543],
            [-1.059916090737971, 52.423462551813962],
            [-1.059774966628739, 52.423874986278719],
            [-1.061372187116443, 52.424837053406648],
            [-1.06106650580477, 52.4263504000564],
            [-1.062078684857172, 52.427581117765342],
            [-1.06266065996123, 52.427462563431249],
            [-1.063066673423518, 52.428151743548561],
            [-1.064020071372028, 52.427995667505961],
            [-1.064466426056536, 52.428447816847395],
            [-1.066089973566253, 52.428176552081567],
            [-1.066396849885002, 52.428827178271156],
            [-1.068132032015828, 52.42884176032873],
            [-1.068402501779433, 52.429476810728538],
            [-1.068958128953008, 52.429562098567565],
            [-1.06844407592856, 52.429807083572406],
            [-1.068944798290791, 52.429985438545607],
            [-1.068485499099633, 52.430144547462575],
            [-1.069246596276131, 52.430251230591082],
            [-1.069265772104535, 52.430667634060434],
            [-1.068513971127913, 52.431026723724358],
            [-1.069096378971685, 52.432075985625929],
            [-1.069962388100941, 52.432301262969062],
            [-1.069869169630819, 52.4326071003564],
            [-1.069297729016145, 52.432432688246458],
            [-1.069811212705566, 52.432844888426416],
            [-1.069155911732306, 52.433020439143036],
            [-1.069337870921065, 52.433529824673954],
            [-1.070912011990003, 52.433936874665662],
            [-1.070932501857626, 52.433661931487933],
            [-1.071384413658872, 52.433645701330398],
            [-1.071743360039944, 52.433993747340232],
            [-1.072889720182203, 52.433969473486911],
            [-1.072987161675595, 52.434232754759478],
            [-1.073850986442229, 52.434284472900096],
            [-1.074064929328934, 52.43404250989353],
            [-1.074379348153071, 52.434481001548882],
            [-1.076656290194848, 52.434906957575883],
            [-1.076206525101301, 52.435312505753792],
            [-1.077677215496904, 52.435678200759341],
            [-1.077751603424876, 52.436060869468889],
            [-1.078415423791016, 52.435759473025712],
            [-1.079404068852601, 52.436317378929431],
            [-1.080299351579954, 52.435990802097841],
            [-1.080818595018196, 52.436274436976596],
            [-1.082798181044081, 52.436009304727854],
            [-1.084394450410796, 52.436281491194968],
            [-1.084645509384752, 52.435168634656158],
            [-1.085831220962375, 52.434665355755243],
            [-1.086163274981256, 52.434328087381132],
            [-1.085793177752551, 52.434229031229364],
            [-1.086459788422322, 52.434073254545055],
            [-1.086370663487663, 52.43383072659288],
            [-1.087070059026919, 52.433867592548488],
            [-1.08825274276996, 52.433294141588924],
            [-1.088753115744198, 52.433423861777896],
            [-1.088765281509976, 52.432905214037966],
            [-1.090043412893161, 52.433408621719735],
            [-1.09089688095044, 52.433105917111078],
            [-1.091271906635253, 52.432393169369085],
            [-1.09188847058497, 52.432594792391022],
            [-1.092428905266604, 52.432063117123221],
            [-1.092109030436882, 52.431810731545006],
            [-1.092985706535166, 52.431521675525744],
            [-1.094608223347995, 52.430220619983359],
            [-1.094060271714557, 52.428323062658407],
            [-1.093616358188772, 52.428095802802652],
            [-1.093428969389272, 52.428268779023163],
            [-1.093372209636952, 52.427665992223844],
            [-1.094460044123383, 52.426972180980002],
            [-1.095259714688439, 52.42706280940822],
            [-1.09557127654161, 52.426498801645863],
            [-1.096406506892327, 52.42650428615589],
            [-1.097550366008049, 52.425943931375265],
            [-1.098217400619388, 52.425038298797936],
            [-1.101191702622929, 52.425076221332716],
            [-1.101980866926607, 52.424814303867215],
            [-1.102618864601065, 52.424536849841928],
            [-1.102595311986188, 52.424103337513486],
            [-1.103053954216457, 52.424329776449561],
            [-1.103390938049606, 52.424174101699791],
            [-1.103514630425773, 52.424601180616705],
            [-1.103705614802958, 52.423958021785332],
            [-1.104337291384376, 52.424209141660633],
            [-1.104625423776187, 52.423706066975818],
            [-1.105344587559869, 52.423776239188193],
            [-1.105515712107483, 52.423313632732999],
            [-1.106397371995448, 52.423347267967216],
            [-1.107095262649471, 52.422359107725192],
            [-1.108755557493877, 52.421991339279977],
            [-1.109545166726772, 52.422432418137916],
            [-1.109831202195318, 52.421737821156974],
            [-1.110737467673558, 52.421789589949675],
            [-1.11127495229647, 52.421313546520054],
            [-1.111957078453815, 52.421395986774101],
            [-1.111925557887924, 52.421135030738739],
            [-1.113573975449803, 52.421575335604956],
            [-1.115892321564995, 52.421092825802894],
            [-1.115482938903716, 52.421416113655596],
            [-1.116017542999302, 52.421522600040475],
            [-1.116540503181553, 52.421402440965416],
            [-1.116241581398211, 52.421128700150113],
            [-1.116655200057766, 52.420960973865427],
            [-1.118003471892914, 52.420895516977708],
            [-1.118915209064084, 52.419491731054819],
            [-1.119448880931155, 52.419496604431465],
            [-1.119814134972218, 52.418876293882633],
            [-1.120482728179003, 52.418750907911004],
            [-1.119627378956889, 52.418212317160041],
            [-1.120769573156486, 52.417192320607441],
            [-1.121626958588779, 52.417480088325718],
            [-1.122243449819817, 52.417237431298432],
            [-1.12226155754686, 52.417806653085393],
            [-1.122795054767018, 52.41804166235088],
            [-1.123567536685724, 52.417866683650466],
            [-1.123348402337746, 52.417498254507116],
            [-1.12458747712404, 52.41789221783975],
            [-1.125716511014183, 52.417680306221477],
            [-1.129282680709493, 52.416086558154213],
            [-1.128888087794964, 52.415815740961477],
            [-1.12907818598275, 52.415196808781936],
            [-1.132393988253741, 52.41360831548797],
            [-1.132646782094851, 52.413237970331117],
            [-1.132251035084135, 52.412876353492798],
            [-1.133388738993377, 52.412963810276054],
            [-1.134404726695704, 52.412284389885549],
            [-1.134924302962958, 52.411276780109375],
            [-1.134826423158747, 52.409666795904762],
            [-1.136652988212766, 52.407597984346395],
            [-1.136302942758321, 52.406402411697655],
            [-1.138162947383909, 52.405398275767709],
            [-1.138621903146283, 52.404551134223929],
            [-1.137939280349664, 52.403901553343765],
            [-1.138184086413971, 52.403181413835554],
            [-1.138836346903992, 52.40304951234166],
            [-1.138549050200508, 52.402027016880567],
            [-1.140314710859553, 52.401554387632025],
            [-1.142282264216275, 52.399687896466382],
            [-1.143512411979944, 52.399311127034913],
            [-1.143789505289376, 52.399574752410658],
            [-1.144514776612465, 52.398931798178822],
            [-1.145273210436609, 52.399233963554771],
            [-1.146326716033131, 52.398564601156721],
            [-1.146650621584427, 52.398840245185418],
            [-1.147855246155327, 52.398335583736042],
            [-1.14831136838505, 52.39846203729136],
            [-1.149483087579331, 52.39699156023309],
            [-1.150009624183953, 52.397506896223923],
            [-1.149736477577985, 52.397954449330555],
            [-1.150874439145117, 52.397627286505355],
            [-1.151519750393089, 52.397774864757274],
            [-1.151202173296888, 52.398317399928516],
            [-1.15151221819225, 52.398780829032376],
            [-1.152146733919408, 52.398802461667202],
            [-1.151980120528543, 52.39929034104383],
            [-1.153848336895923, 52.399359463487244],
            [-1.154089947665015, 52.39963719517025],
            [-1.155079065969736, 52.39933140294584],
            [-1.15659568825456, 52.400034484579891],
            [-1.158713365214105, 52.399894935257109],
            [-1.158884007940062, 52.399268624262312],
            [-1.160244206911088, 52.39923333947408],
            [-1.160387023363011, 52.399607452106686],
            [-1.16232242892602, 52.399079060915525],
            [-1.162765796488589, 52.399338425004821],
            [-1.163869108926719, 52.398974930716022],
            [-1.164234011075462, 52.399186984774666],
            [-1.164349751035718, 52.398661868689516],
            [-1.165185107143541, 52.398944670772728],
            [-1.165852594182772, 52.398309269838087],
            [-1.166890135842461, 52.398313886795727],
            [-1.167011914928501, 52.398012669762458],
            [-1.166663004386036, 52.397809727266875],
            [-1.165982473421479, 52.397968553549653],
            [-1.165767156049534, 52.397539994298086],
            [-1.166288968724123, 52.396834337551454],
            [-1.167907857744511, 52.396402516869514],
            [-1.168228182913462, 52.396796748638643],
            [-1.168471509933399, 52.396205098369322],
            [-1.168885866509016, 52.396366241050316],
            [-1.169410682014185, 52.396123593491843],
            [-1.169362720726754, 52.395625192512234],
            [-1.17126773658621, 52.395998171545408],
            [-1.173508816910757, 52.395139996530531],
            [-1.173937571904921, 52.39460896708438],
            [-1.174155111854238, 52.395000666666704],
            [-1.17521354749947, 52.394510888830467],
            [-1.175985346011152, 52.394733835347516],
            [-1.177281116784529, 52.394045208402929],
            [-1.177466503838881, 52.393163648112022],
            [-1.178003774087352, 52.392878794612372],
            [-1.180463764492955, 52.393197944167262],
            [-1.184005534626938, 52.39215438701352],
            [-1.184587600321807, 52.39254588512398],
            [-1.187784883003466, 52.393289812016228],
            [-1.188272335784874, 52.393954846609695],
            [-1.188060664406625, 52.394998967414445],
            [-1.189395025953972, 52.396365659418286],
            [-1.190250931078495, 52.396495589666749],
            [-1.190399075104045, 52.395226272526799],
            [-1.194078038136767, 52.396167515536135],
            [-1.196354773371012, 52.395668764082693],
            [-1.197412416385102, 52.396020275902771],
            [-1.197953917809378, 52.395658943196857],
            [-1.199113568368385, 52.396221504810363],
            [-1.201626595613241, 52.396714983963435],
            [-1.224398587759515, 52.422988671988435],
            [-1.236456430814918, 52.435710093313517],
            [-1.236765826168908, 52.436022252721727],
            [-1.240755352760128, 52.440117798471817],
            [-1.241585689466759, 52.439738342896796],
            [-1.242930104485847, 52.439662445704023],
            [-1.257842181594099, 52.453875143933878],
            [-1.293604006601591, 52.484243411868341],
            [-1.305958076012181, 52.493377634390534],
            [-1.305491485058799, 52.493638307855115],
            [-1.304376121491845, 52.493195738292869],
            [-1.302112087167934, 52.495815614092798],
            [-1.297005758814454, 52.499575627040613],
            [-1.292856248041901, 52.503421150630132],
            [-1.279581566429069, 52.514296442510201],
            [-1.261183622894515, 52.530303184287483],
            [-1.251683221438719, 52.538351671859218],
            [-1.251358256466017, 52.539080510278687],
            [-1.24912777695986, 52.540618073536756],
            [-1.247978877192699, 52.542015930140977],
            [-1.245969657234553, 52.543275247746891],
            [-1.231850692128682, 52.555360645077094],
            [-1.22889582136327, 52.554295900033054],
            [-1.226671110017524, 52.553402166727771],
            [-1.224136890251956, 52.551664894252646],
            [-1.224366412772289, 52.551445242157833],
            [-1.221147534443495, 52.550156517820092],
            [-1.217832080909999, 52.548254843582832],
            [-1.217739279462, 52.547668077983246],
            [-1.212985411403992, 52.544253614827518],
            [-1.213801137879546, 52.543660293971875],
            [-1.211782025464909, 52.542860239634265],
            [-1.210757988743302, 52.541007754796873],
            [-1.209018300649138, 52.539190937489835],
            [-1.206608337470729, 52.535141859059941],
            [-1.205455338629193, 52.535147613083893],
            [-1.204430335369027, 52.531577953950986],
            [-1.202843538894547, 52.529655985217637],
            [-1.202142540893397, 52.527421716626222],
            [-1.198558642513231, 52.528179651712165],
            [-1.197084675513589, 52.527608682475332],
            [-1.196204783827892, 52.528087279498365],
            [-1.194088599884872, 52.527653955068942],
            [-1.190208491297131, 52.527837849758001],
            [-1.184285012740407, 52.52748073623961],
            [-1.18174443458412, 52.529666684293105],
            [-1.175069162657324, 52.527568818170614],
            [-1.173022640249739, 52.532857815375259],
            [-1.172123095218838, 52.533185961729494],
            [-1.172009190358942, 52.534382649053086],
            [-1.142863558065786, 52.534953797872596],
            [-1.143070811387401, 52.536500701835109],
            [-1.143894160017237, 52.53752793645517],
            [-1.140314370216932, 52.537905657053194],
            [-1.139282584028182, 52.539851716833766],
            [-1.139459853134729, 52.543153280673181],
            [-1.123478208626307, 52.544575171251658],
            [-1.121266241613977, 52.545168308412144],
            [-1.117511646451701, 52.543191325522187],
            [-1.113894646923343, 52.541867036914148],
            [-1.109374388650557, 52.542054281943805],
            [-1.106068752278911, 52.54182349832994],
            [-1.10451752744889, 52.539795286909715],
            [-1.102278000636597, 52.538340803511218],
            [-1.097453246442146, 52.542391948810277],
            [-1.094229642263478, 52.541902543252107],
            [-1.093503779927995, 52.541420510402183],
            [-1.090319445171752, 52.541533639102973],
            [-1.090368886491606, 52.5412103732533],
            [-1.087690618033865, 52.540526294512681],
            [-1.087231702115919, 52.540685479702354],
            [-1.083672492174079, 52.539896522780964],
            [-1.075899020091965, 52.538848090936661],
            [-1.075712637140785, 52.539719581687187],
            [-1.074802845029779, 52.540423598761087],
            [-1.072589001457158, 52.542483907928286],
            [-1.06986932004506, 52.542861739110421],
            [-1.06984104265551, 52.544130029653729],
            [-1.06624386693949, 52.549163181290687],
            [-1.06473855368345, 52.552334717645408],
            [-1.0614858219246, 52.555406092972675],
            [-1.060732238428246, 52.555261669996774],
            [-1.060454422508162, 52.555493210263514],
            [-1.059401937932811, 52.558911902267205],
            [-1.063914722370242, 52.559029468792183],
            [-1.064251081284456, 52.562078887476346],
            [-1.071726422336085, 52.561777210764546],
            [-1.072811111062677, 52.561057506361031],
            [-1.073640219969099, 52.559023232576074],
            [-1.07324351578532, 52.557968282108767],
            [-1.074117797880705, 52.55686753481703],
            [-1.07336371946723, 52.556463373415653],
            [-1.073811339752757, 52.556080298738799],
            [-1.074556150845986, 52.556714532618301],
            [-1.076576250681876, 52.556817505868615],
            [-1.077236440789605, 52.555792381340204],
            [-1.08028102276576, 52.555571533496845],
            [-1.082903574023399, 52.556561008929719],
            [-1.079611788052602, 52.563095574530017],
            [-1.077985664266519, 52.56367807300574],
            [-1.078972619617237, 52.56600250884955],
            [-1.077708640692975, 52.566406218675063],
            [-1.076961548633871, 52.566292517043301],
            [-1.077274397603331, 52.567835862709444],
            [-1.07632292820717, 52.568038815225677],
            [-1.076772803956225, 52.569028539647647],
            [-1.076054997195355, 52.569344788750087],
            [-1.07688575315931, 52.570254773943184],
            [-1.073868088087101, 52.571530289291033],
            [-1.076279892424471, 52.572756496378574],
            [-1.074811113919159, 52.573976679061929],
            [-1.075431588914279, 52.574644095720096],
            [-1.073331804419144, 52.575972608307552],
            [-1.071076047980179, 52.576784726983696],
            [-1.070670960485479, 52.577169023486988],
            [-1.070948849015032, 52.577287175925917],
            [-1.069281631744621, 52.578386164931963],
            [-1.069070880949417, 52.578233474825375],
            [-1.067371873991653, 52.57978349100167],
            [-1.066493044893552, 52.579400781445862],
            [-1.065119149149889, 52.579883503337342],
            [-1.058381421556699, 52.585357258222828],
            [-1.049864334650359, 52.591325212560605],
            [-1.041739267561548, 52.597692191340251],
            [-1.041366592413556, 52.597991243905653],
            [-1.042181298488512, 52.598274728700382],
            [-1.044752808946955, 52.600059348901716],
            [-1.052451533844267, 52.601549698998554],
            [-1.056490652241341, 52.602887295220874],
            [-1.057663063231413, 52.603231057287921],
            [-1.058424472069443, 52.603048319027025],
            [-1.059904531624662, 52.603230883835984],
            [-1.059771395417155, 52.603931953000625],
            [-1.062487939556957, 52.604228576392146],
            [-1.061623160893455, 52.606000867492263],
            [-1.058977039654155, 52.609028416603088],
            [-1.062290170275671, 52.610102027300535],
            [-1.066969122534215, 52.612168922089324],
            [-1.069531778681335, 52.612644870123518],
            [-1.069154313030094, 52.613242441709268],
            [-1.070964680356686, 52.613886853322128],
            [-1.070045803488048, 52.614733701158876],
            [-1.068009248874536, 52.615350600648476],
            [-1.066668179324073, 52.617015792502066],
            [-1.070498471598081, 52.618048291610009],
            [-1.066363151798365, 52.619551283119165],
            [-1.065654772593694, 52.619382080646439],
            [-1.064317411817974, 52.619685281070787],
            [-1.063495816338199, 52.61935335057106],
            [-1.060144971112038, 52.62152489035477],
            [-1.058063525231056, 52.623661482304712],
            [-1.057294477336019, 52.623632891702172],
            [-1.055763585263863, 52.624957524670847],
            [-1.055253925652819, 52.624819510734916],
            [-1.053276754426872, 52.627514233332668],
            [-1.055392467084332, 52.628334827197797],
            [-1.056046114980848, 52.628992713228648],
            [-1.055703420589574, 52.630293540160309],
            [-1.056191902874668, 52.632174553136949],
            [-1.056049932495546, 52.632592358500602],
            [-1.053140695398798, 52.633134624428962],
            [-1.053131110862225, 52.634468670474327],
            [-1.046970879204908, 52.634601790759028],
            [-1.046784606597883, 52.635217908943112],
            [-1.048145780144395, 52.635343022719077],
            [-1.048048865659873, 52.637289487911318],
            [-1.046554548823273, 52.637644269559708],
            [-1.046212887781558, 52.63806585050893],
            [-1.047662570082478, 52.638134145475817],
            [-1.047442728286273, 52.642337019964025],
            [-1.049497256240013, 52.642598942246721],
            [-1.049194957727702, 52.643385842502617],
            [-1.05132395593151, 52.643214111722386],
            [-1.050976110861648, 52.643921539508334],
            [-1.051802985552832, 52.644026148751863],
            [-1.051027872120835, 52.648086134885006],
            [-1.04802696303905, 52.648170850154372],
            [-1.048203435067097, 52.652025391561573],
            [-1.048845543072757, 52.653566940116697],
            [-1.048621275505904, 52.65497747204126],
            [-1.045615383719767, 52.6545901117642],
            [-1.045509343557257, 52.654368103470951],
            [-1.041897554150913, 52.654109719471791],
            [-1.04174066719617, 52.653847740622616],
            [-1.037789501355818, 52.653967664161449],
            [-1.03820187044908, 52.656733644891467],
            [-1.03955034964887, 52.658681927530608],
            [-1.039575186557411, 52.658897889167704],
            [-1.038597382431067, 52.658989752079059],
            [-1.034659359342162, 52.658558591090909],
            [-1.034275278011789, 52.658812577256633],
            [-1.032595397457159, 52.658627170254775],
            [-1.032755258039228, 52.658216731758401],
            [-1.030373943667575, 52.657527520205349],
            [-1.029360803288492, 52.658481161228522],
            [-1.028043129570272, 52.65743111735231],
            [-1.02701454586908, 52.657812846176249],
            [-1.026392310489042, 52.657005826623376],
            [-1.025784935769336, 52.657194122022467],
            [-1.024983314352965, 52.656676897413966],
            [-1.024166934844803, 52.656557804161579],
            [-1.022801800398667, 52.65698167561542],
            [-1.02147632993036, 52.656422349305998],
            [-1.021333727729111, 52.65665131687738],
            [-1.016454811859546, 52.655408967081925],
            [-1.014712787179391, 52.655826920713587],
            [-1.013796449791606, 52.655027277911579],
            [-1.011830915265964, 52.657030970517866],
            [-1.00880589969607, 52.657627825008497],
            [-1.005177579786393, 52.660179369655317],
            [-1.004099761103458, 52.660503849855651],
            [-1.003833694212106, 52.661021238493824],
            [-0.999585879045915, 52.662832918884227],
            [-0.998067098277301, 52.664396045601336],
            [-0.998622385023529, 52.665202648847043],
            [-0.996949960822556, 52.666172918765881],
            [-0.997740456452023, 52.668074703880372],
            [-0.99655893187152, 52.66807369892981],
            [-0.995619203197363, 52.669652481812513],
            [-0.991448930228656, 52.673440537768975],
            [-0.988234795887927, 52.6720466663621],
            [-0.983093194344833, 52.678264232732097],
            [-0.982309294871256, 52.680108553153737],
            [-0.980935505669999, 52.681617865483709],
            [-0.978891452346591, 52.681923919945397],
            [-0.976783047773542, 52.680361255992608],
            [-0.974898099471629, 52.679874794775344],
            [-0.973243634504339, 52.678896746764138],
            [-0.972901394189562, 52.679046612977693],
            [-0.972529744612286, 52.678747620596091],
            [-0.972952852972248, 52.678555303847489],
            [-0.972238549842511, 52.67791981134242],
            [-0.971394804207909, 52.678136346001978],
            [-0.970676114834057, 52.677311116057574],
            [-0.970025989379292, 52.677659677399696],
            [-0.969326191065295, 52.677101607397162],
            [-0.968026724739164, 52.676948260776967],
            [-0.968353425645532, 52.67620223359954],
            [-0.967942243865689, 52.675888498416811],
            [-0.96651419306514, 52.675921008522252],
            [-0.965809899545765, 52.67442881236218],
            [-0.965049353215113, 52.674505782851327],
            [-0.964591339168436, 52.674172747864958],
            [-0.964305422327886, 52.674630540874773],
            [-0.963573202941107, 52.674010121606671],
            [-0.962318933436872, 52.673826540858464],
            [-0.962259178714247, 52.673352242150052],
            [-0.961383999769374, 52.673461448794647],
            [-0.961329491260172, 52.673202956760818],
            [-0.960175680997583, 52.673087658691607],
            [-0.957404882954755, 52.673987501193032],
            [-0.956423534899385, 52.675199716118108],
            [-0.952951036030273, 52.677601807072151],
            [-0.950215978472452, 52.678535061254571],
            [-0.949117760935736, 52.678563989126722],
            [-0.948932608263597, 52.680362156231091],
            [-0.943407392346541, 52.678048449643875],
            [-0.941312263229306, 52.676791827762614],
            [-0.934757012060405, 52.674539532119283],
            [-0.916058154887632, 52.671121921492585],
            [-0.915836421586548, 52.671536135013149],
            [-0.914980909191479, 52.671385365546818],
            [-0.914268399998514, 52.671730354652787],
            [-0.914039653030917, 52.671949415660663],
            [-0.914509995097287, 52.672066998405782],
            [-0.914057875598874, 52.672347843561695],
            [-0.914403439939153, 52.672487657874697],
            [-0.913829495476468, 52.672492289776834],
            [-0.913243973240572, 52.673500106711536],
            [-0.91259607317573, 52.673623622894475],
            [-0.913191398848509, 52.673950028162018],
            [-0.912450560496305, 52.674301938768451],
            [-0.912680963161176, 52.675387357645675],
            [-0.912120432569417, 52.675743616811815],
            [-0.912795885161917, 52.676537344488224],
            [-0.911934548520697, 52.677153354291114],
            [-0.911852747785849, 52.676635673657408],
            [-0.910886605368369, 52.676885716441134],
            [-0.911017898262358, 52.677197080437494],
            [-0.91041566566927, 52.676849923856196],
            [-0.909774949565557, 52.677098452986939],
            [-0.908716511791276, 52.676718323290871],
            [-0.908545377531151, 52.676344558255821],
            [-0.907932172181315, 52.676557370376536],
            [-0.907394790443069, 52.67622068474153],
            [-0.905971858328939, 52.676045740605559],
            [-0.905438874211304, 52.67559311439431],
            [-0.903715854129886, 52.675708449057431],
            [-0.903109221563074, 52.676307871059166],
            [-0.902609643795859, 52.676057818219945],
            [-0.90315940426903, 52.675957721748652],
            [-0.90307889030272, 52.675688173031872],
            [-0.90262582730027, 52.675825124609226],
            [-0.902435465646042, 52.675394534910424],
            [-0.901529118081889, 52.675500314605081],
            [-0.901563177147507, 52.674731977040089],
            [-0.900268062672292, 52.674947419517281],
            [-0.899561979526406, 52.674205477507378],
            [-0.898192282280607, 52.674446276249697],
            [-0.89826496219504, 52.674143985374783],
            [-0.897435285196564, 52.674024794715017],
            [-0.896809895980972, 52.674661768851813],
            [-0.895841408266839, 52.673947029581882],
            [-0.893940010311619, 52.674392295252233],
            [-0.893371534147611, 52.673652498634489],
            [-0.89222606414786, 52.673944766964127],
            [-0.892426403435261, 52.674181280556155],
            [-0.891918910084769, 52.674422866982603],
            [-0.890644258309586, 52.674471179755379],
            [-0.890215932382018, 52.674156113196325],
            [-0.887652055395308, 52.674535740007407],
            [-0.887661047392845, 52.674997016859528],
            [-0.887235428218983, 52.675100004863673],
            [-0.883509151796677, 52.675587295343746],
            [-0.88256389644505, 52.675940690768151],
            [-0.88063457101507, 52.677585660021869],
            [-0.874427540099553, 52.679855330423251],
            [-0.873324166298527, 52.681116051371419],
            [-0.872614166054029, 52.684332255427137],
            [-0.871155481564593, 52.6841619480685],
            [-0.870499313930424, 52.68453777824562],
            [-0.866702374650956, 52.684915988923301],
            [-0.861784183837877, 52.684319579091778],
            [-0.859747610689351, 52.684357546568847],
            [-0.855554319701445, 52.68495454277484],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000132",
        LAD13CDO: "31UE",
        LAD13NM: "Hinckley and Bosworth",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.284868494470032, 52.714079474157877],
            [-1.285899005850885, 52.712071071207845],
            [-1.285247949260814, 52.710062426794451],
            [-1.286210881248651, 52.705656933276849],
            [-1.285377786094449, 52.702565718405779],
            [-1.282634061341285, 52.700215401767274],
            [-1.279947132716123, 52.698838062429623],
            [-1.278465533326338, 52.69822943766782],
            [-1.27573401964424, 52.698667673089858],
            [-1.275293702198841, 52.698270330545981],
            [-1.280200165997778, 52.695239178011683],
            [-1.281598477287751, 52.692803325089415],
            [-1.281426951233722, 52.692165806688585],
            [-1.279363457234268, 52.691146425796873],
            [-1.271659826186732, 52.68854894960387],
            [-1.269080157598222, 52.687278996305857],
            [-1.265491733905909, 52.686272437540389],
            [-1.265920613618332, 52.685662885231096],
            [-1.265208952378283, 52.685492165701866],
            [-1.265023353012619, 52.684739464946247],
            [-1.259018382795277, 52.682053686206061],
            [-1.257436016632405, 52.683338325874622],
            [-1.256689998562021, 52.684585937472463],
            [-1.254688312431749, 52.684422344162911],
            [-1.25235674209662, 52.684866149250844],
            [-1.252046423293181, 52.68432659949498],
            [-1.249630997128418, 52.684741953561449],
            [-1.244742792225583, 52.68460124519634],
            [-1.242387874292009, 52.683224260497965],
            [-1.239315484338956, 52.679814515530957],
            [-1.236041358708563, 52.678161806084219],
            [-1.2357283442795, 52.677875709207356],
            [-1.23618930702626, 52.677565832093158],
            [-1.234806883766826, 52.677028314779072],
            [-1.23461909376578, 52.675924944827933],
            [-1.230242675414154, 52.67541925216576],
            [-1.227890715465223, 52.672417523098417],
            [-1.229390243020787, 52.671413220151358],
            [-1.226599544926282, 52.669909022002983],
            [-1.221080338765222, 52.66967331616349],
            [-1.216611361747298, 52.670914143744874],
            [-1.216976328903114, 52.671593493068769],
            [-1.215878844002289, 52.672428592415592],
            [-1.21491168256785, 52.672178566412683],
            [-1.213769770972601, 52.674662096956567],
            [-1.212147109657518, 52.674427477917035],
            [-1.212637507038312, 52.672487122117907],
            [-1.207932997987518, 52.671265526153341],
            [-1.207266058369104, 52.670364776515299],
            [-1.20162717955681, 52.668826526179316],
            [-1.201361055062308, 52.66703844061221],
            [-1.202800063874657, 52.666539296851468],
            [-1.202708108021918, 52.664441334029618],
            [-1.20081040800793, 52.664798940988938],
            [-1.199688310661462, 52.664383229911991],
            [-1.202600599786369, 52.663929984362447],
            [-1.20310331284399, 52.661898049538365],
            [-1.208295598641219, 52.659791441372683],
            [-1.20962995039891, 52.658519282787516],
            [-1.210545809876535, 52.655196420857251],
            [-1.214249428706039, 52.653853661123385],
            [-1.214112635331481, 52.65332055139006],
            [-1.215954569599008, 52.65199684668049],
            [-1.218334951129612, 52.651142342752379],
            [-1.22069086709352, 52.651076944874056],
            [-1.224167800585037, 52.650478548434549],
            [-1.225248497705785, 52.650218615981025],
            [-1.225655902947171, 52.650501763229236],
            [-1.228633379428413, 52.649047728653642],
            [-1.227053095192674, 52.648554676533898],
            [-1.224272451640126, 52.646234194550857],
            [-1.223433852634876, 52.646432774863733],
            [-1.223151938249804, 52.646243937301136],
            [-1.225088271155419, 52.644062171658078],
            [-1.229261204040609, 52.64242534376109],
            [-1.232380567011693, 52.641607724938794],
            [-1.232205301392345, 52.640729173278494],
            [-1.232843779612281, 52.640895125944382],
            [-1.233441477656678, 52.640435112774931],
            [-1.233742343785334, 52.64072383668298],
            [-1.234059116822566, 52.640186488659566],
            [-1.234246589763946, 52.640706418516324],
            [-1.234668813030434, 52.640392700171816],
            [-1.234958857273383, 52.641048139923008],
            [-1.235841089383761, 52.640965729574283],
            [-1.235650771928865, 52.641286341663978],
            [-1.236457498599929, 52.641721259223985],
            [-1.23683615848313, 52.641617616731978],
            [-1.236679891730515, 52.641340619968013],
            [-1.23716899166733, 52.641344667621254],
            [-1.237321001638148, 52.641781656912073],
            [-1.238696803980309, 52.64127627785961],
            [-1.239361929162484, 52.641441467448857],
            [-1.239585726621995, 52.641063528779576],
            [-1.239752599054051, 52.641497014286678],
            [-1.240953624831328, 52.641268276471983],
            [-1.241679987773597, 52.641824007569731],
            [-1.241893150608092, 52.641034258341449],
            [-1.242134210171827, 52.641452033041496],
            [-1.243064864097652, 52.641475060536372],
            [-1.243865221308199, 52.641083713450286],
            [-1.243968583790175, 52.640660048669744],
            [-1.246603434741288, 52.639892904373333],
            [-1.247375385457244, 52.639775545551785],
            [-1.247837846033772, 52.640211795040329],
            [-1.248600578499656, 52.640201349738987],
            [-1.251240774858066, 52.638941489040278],
            [-1.254636839702656, 52.638585316807927],
            [-1.25504348328537, 52.638221983679969],
            [-1.255435816153932, 52.63844020676931],
            [-1.256072025478068, 52.638133150426299],
            [-1.256626258519622, 52.638302941049758],
            [-1.256881834073643, 52.637871229752811],
            [-1.257501905910521, 52.63816728783835],
            [-1.259314101846425, 52.637176250501703],
            [-1.260571860779643, 52.637425932162202],
            [-1.260600031166895, 52.636982006151122],
            [-1.261810640608312, 52.637047986301774],
            [-1.261380813775509, 52.6362470042287],
            [-1.259211598338005, 52.636858265924943],
            [-1.257277291093222, 52.635810522626386],
            [-1.255975973684169, 52.635519178710155],
            [-1.256120202423376, 52.634333414584745],
            [-1.255049846333772, 52.634453450006447],
            [-1.253734435445025, 52.63508076264462],
            [-1.253858963892475, 52.635925700116942],
            [-1.253445693930422, 52.63624493694914],
            [-1.251549985344395, 52.636495487268725],
            [-1.250681780944973, 52.636109728161031],
            [-1.248468608696547, 52.63651824242762],
            [-1.247160809380645, 52.63609011370243],
            [-1.245319847715011, 52.636162911651901],
            [-1.244085165972521, 52.635867366264698],
            [-1.24076568167267, 52.633598667482133],
            [-1.24640869602256, 52.63238957566427],
            [-1.248361808285652, 52.632488281073208],
            [-1.249403390722774, 52.631543747525114],
            [-1.251399178238184, 52.631650764142314],
            [-1.252463424664972, 52.630758488511674],
            [-1.252482923449354, 52.630221013782453],
            [-1.254334297756427, 52.630215595109959],
            [-1.260476836982752, 52.62886154154706],
            [-1.261731452988753, 52.62831828076218],
            [-1.262819600653772, 52.626953191044741],
            [-1.260517927022438, 52.625371008484024],
            [-1.260009460484347, 52.623809879416356],
            [-1.25779618939629, 52.620771824299617],
            [-1.257422403440498, 52.616234955224996],
            [-1.265049056274056, 52.614948391908371],
            [-1.264629935012602, 52.614399203146014],
            [-1.274489124512446, 52.616151879928239],
            [-1.274394408134555, 52.615001486819253],
            [-1.271784811699822, 52.614544073484034],
            [-1.273367524648225, 52.611276954258948],
            [-1.274005798041859, 52.611528089837329],
            [-1.274140767104929, 52.61283335756174],
            [-1.277295474195202, 52.611483470575543],
            [-1.274269607960634, 52.609773969198599],
            [-1.280336063062476, 52.608974901837072],
            [-1.275900753258931, 52.609010812129846],
            [-1.279846000056542, 52.607250349653903],
            [-1.2780371034663, 52.604641246438185],
            [-1.274707864133453, 52.602731226291745],
            [-1.274593794009782, 52.600965798935967],
            [-1.276982503217348, 52.599366700782753],
            [-1.28155392957027, 52.597448180915819],
            [-1.284905693675981, 52.595247037280679],
            [-1.291256928177683, 52.592581018109414],
            [-1.292425616569994, 52.59151010647328],
            [-1.292063181091875, 52.591283192265252],
            [-1.294126347620511, 52.589293436374767],
            [-1.295912245399485, 52.588383490256057],
            [-1.29835230124399, 52.585972472129271],
            [-1.296317269794267, 52.585489327904959],
            [-1.295577331592313, 52.58469021697362],
            [-1.296052827515101, 52.584122179841984],
            [-1.295324161384078, 52.583818481761313],
            [-1.295636668707173, 52.582461055630262],
            [-1.297100361903815, 52.57999211068595],
            [-1.289368115249448, 52.578294520322139],
            [-1.286434951791823, 52.57807641219258],
            [-1.283997859766764, 52.576939756504622],
            [-1.28072148119783, 52.576059565967668],
            [-1.273538763057209, 52.57485153101323],
            [-1.270628311888294, 52.574616987715395],
            [-1.2701814521136, 52.574868652570885],
            [-1.270095822537801, 52.573804607591029],
            [-1.269476589939172, 52.573773820443677],
            [-1.269294565197569, 52.573272853496462],
            [-1.269771623397957, 52.572979125084323],
            [-1.269119017478735, 52.573003868335491],
            [-1.26903750943886, 52.572403731981865],
            [-1.268904114117044, 52.572084662512523],
            [-1.267609215472847, 52.571870792325576],
            [-1.267602526049092, 52.571652293759442],
            [-1.268223210062579, 52.571327994426106],
            [-1.268122062593611, 52.570933607127806],
            [-1.26937148244489, 52.570775004937623],
            [-1.26921815916941, 52.570234658570719],
            [-1.269690390229356, 52.570318481053341],
            [-1.269733545003814, 52.569852165593772],
            [-1.26939511404222, 52.569619035418832],
            [-1.269877695740466, 52.569701123017616],
            [-1.270215625784913, 52.568897700485834],
            [-1.272588696317992, 52.568326148400743],
            [-1.272513338731493, 52.567886973051159],
            [-1.271805984562602, 52.56774777645947],
            [-1.272925943507983, 52.565953956589048],
            [-1.27361252010128, 52.565920410911012],
            [-1.275261370523745, 52.564431875506173],
            [-1.275595919645508, 52.564810603639209],
            [-1.276085428688392, 52.564469278777587],
            [-1.277037920109355, 52.564770867703423],
            [-1.276840377456808, 52.565031271229934],
            [-1.278091825479733, 52.565186341209703],
            [-1.278167603837632, 52.564883839585185],
            [-1.279706839503883, 52.564672055413467],
            [-1.279425703745097, 52.564452787161862],
            [-1.280218427269417, 52.56433174680172],
            [-1.280421010026701, 52.563852011953713],
            [-1.281635355533049, 52.563658006645355],
            [-1.282284296187872, 52.56321603590235],
            [-1.28452261717608, 52.563372518722502],
            [-1.284955638431335, 52.562961592995151],
            [-1.285579230218557, 52.563260228257157],
            [-1.288923460455407, 52.563631861211789],
            [-1.289340080123853, 52.564598989499608],
            [-1.295475964281088, 52.563386932007759],
            [-1.298104251355503, 52.563700104852465],
            [-1.298565848421092, 52.563339643034446],
            [-1.298398546018714, 52.562637431714379],
            [-1.302032895299125, 52.561370635384066],
            [-1.303182345789557, 52.561444828160219],
            [-1.30365624628153, 52.561887226400898],
            [-1.305653396476139, 52.561809050582738],
            [-1.306756008774163, 52.562890713345546],
            [-1.308335356706682, 52.562553831161758],
            [-1.309063042298921, 52.562903294178753],
            [-1.309353504532882, 52.562537297103646],
            [-1.310147840452213, 52.562682170026363],
            [-1.311840654921385, 52.562068110917707],
            [-1.312447532139541, 52.562314365186026],
            [-1.312163698559413, 52.562824248285295],
            [-1.312944480265739, 52.563176692662559],
            [-1.312933160312972, 52.563523640934314],
            [-1.319763972159012, 52.565877089901988],
            [-1.319152777258353, 52.567047671217118],
            [-1.323719590597779, 52.567775949949386],
            [-1.325834913127508, 52.564912110697882],
            [-1.328615140418261, 52.56582600123955],
            [-1.329281245375659, 52.565970917558012],
            [-1.334896877441131, 52.56017527120904],
            [-1.332528554583049, 52.559350152605354],
            [-1.332472009028741, 52.55841037941056],
            [-1.337296201366091, 52.556304142894163],
            [-1.337887145017639, 52.555554985002281],
            [-1.339000808535096, 52.554568709467404],
            [-1.339123445994822, 52.552884667776688],
            [-1.338064497173321, 52.551707356927885],
            [-1.336576129927041, 52.548593880999832],
            [-1.336261179103369, 52.54708449161474],
            [-1.334562273839411, 52.543772925778427],
            [-1.334961832565009, 52.542897744417026],
            [-1.334388596702211, 52.541021003813995],
            [-1.332613428840854, 52.538679899987415],
            [-1.334857641895211, 52.533007231678447],
            [-1.33237352512942, 52.531748132611909],
            [-1.330711459011942, 52.531113942638186],
            [-1.331668578450852, 52.529824782863074],
            [-1.329602221631257, 52.529318649987474],
            [-1.331231918121871, 52.527322186890743],
            [-1.330337270449895, 52.527122945715078],
            [-1.33041401747159, 52.524595378693299],
            [-1.329256381965726, 52.524441389906862],
            [-1.329275928502962, 52.524028857365764],
            [-1.32764510780871, 52.523204210625615],
            [-1.327136740704018, 52.522520775863804],
            [-1.327248434460115, 52.521576555900893],
            [-1.325713858231497, 52.521405106769237],
            [-1.325115952096379, 52.521182342945821],
            [-1.325227220902353, 52.520942043607711],
            [-1.323799805321602, 52.520512266570812],
            [-1.322436467942333, 52.518984253635466],
            [-1.323566694772889, 52.516976942321797],
            [-1.322134820392777, 52.516175829434978],
            [-1.321907876946457, 52.515697157422068],
            [-1.321164612940458, 52.516020136615552],
            [-1.320803260348878, 52.515562267886409],
            [-1.317976646280741, 52.515099212568146],
            [-1.318781232458913, 52.514527581269775],
            [-1.319137587918977, 52.513494874101333],
            [-1.31906732210856, 52.511080635599711],
            [-1.321342181379611, 52.507839290334381],
            [-1.321901752146352, 52.505560814607577],
            [-1.325098995222379, 52.501024708792706],
            [-1.338453381753187, 52.505760095835242],
            [-1.341801268916202, 52.507350670150927],
            [-1.364965481639372, 52.515924239232668],
            [-1.365285434805756, 52.515707496736383],
            [-1.368163299772168, 52.516435799629193],
            [-1.368246535436349, 52.517208488432054],
            [-1.373552924924267, 52.518060169904437],
            [-1.373260942006946, 52.519109560143178],
            [-1.380428360748267, 52.521475699597367],
            [-1.395137465847395, 52.52702847037056],
            [-1.415216134675188, 52.534370773401157],
            [-1.415839363742976, 52.534176968314569],
            [-1.416068464341916, 52.534465778027389],
            [-1.417354379201754, 52.53443794955043],
            [-1.417385839308951, 52.536071586156325],
            [-1.418347577013738, 52.536480863156967],
            [-1.418967728404369, 52.537750596931332],
            [-1.424093861156934, 52.539570935251696],
            [-1.426986179374964, 52.540628701287872],
            [-1.427191066825922, 52.54029346708289],
            [-1.42892306119511, 52.540898765899385],
            [-1.459230451175478, 52.551961127180988],
            [-1.46000360563633, 52.551572692460248],
            [-1.464057277694009, 52.5530762425416],
            [-1.483043997675528, 52.558666062503299],
            [-1.502714854387658, 52.564453475560121],
            [-1.522877006086386, 52.570582999714681],
            [-1.521588623272444, 52.571289804498441],
            [-1.521522449579903, 52.571811850429889],
            [-1.522998509262011, 52.573528579536593],
            [-1.524604623392466, 52.574008802077515],
            [-1.524756116858392, 52.573781066489154],
            [-1.525996978171882, 52.574062925818723],
            [-1.526709684370314, 52.575296491181831],
            [-1.526686657133612, 52.57661341820905],
            [-1.525317995778914, 52.576680761176142],
            [-1.525928881408841, 52.577642428080154],
            [-1.525927566828393, 52.578996299881538],
            [-1.526330772268955, 52.579239740319593],
            [-1.525374833589758, 52.579617985261841],
            [-1.525729463575878, 52.580123737779935],
            [-1.525204508760964, 52.580358069438418],
            [-1.525615525889523, 52.581246118202003],
            [-1.527325780900847, 52.58236410216292],
            [-1.527164033603463, 52.582720355111206],
            [-1.527909630758523, 52.58269366133127],
            [-1.528927410586879, 52.58329733677548],
            [-1.531273321116234, 52.583366871675793],
            [-1.53215801982662, 52.584415890573936],
            [-1.531396066114013, 52.584728424517991],
            [-1.531682085993566, 52.585727431376867],
            [-1.534741328529095, 52.587162578932968],
            [-1.535317765578661, 52.587925383753301],
            [-1.536107920979578, 52.588020176201354],
            [-1.5375407986112, 52.589036237852277],
            [-1.537477842466527, 52.589548414570977],
            [-1.542635220053394, 52.589414704692253],
            [-1.544166370187415, 52.589656138611971],
            [-1.546160069466748, 52.590562776875444],
            [-1.546596094056837, 52.591366341528719],
            [-1.547515848634863, 52.591929026162155],
            [-1.549256663591077, 52.592896675890366],
            [-1.549994563789649, 52.592627986416836],
            [-1.550315998127067, 52.593097578093776],
            [-1.550780594245813, 52.592993259754195],
            [-1.552683302509157, 52.593730433031261],
            [-1.553371762428706, 52.59382292812662],
            [-1.553704772228757, 52.593593143370498],
            [-1.556283378141919, 52.594067601301781],
            [-1.557090532649921, 52.59483475804825],
            [-1.55700277068549, 52.595632728861617],
            [-1.558041463483285, 52.596134644540697],
            [-1.560765219820328, 52.596138477505562],
            [-1.557076604160608, 52.601343355446211],
            [-1.55708093967292, 52.609261619506441],
            [-1.554283999267295, 52.613479051153647],
            [-1.554467852252186, 52.614718540058639],
            [-1.556465954047531, 52.615176423366528],
            [-1.560667021781965, 52.617034081985267],
            [-1.561196383944371, 52.619783330230156],
            [-1.563073506309877, 52.620256835017138],
            [-1.563472435054168, 52.619949055176015],
            [-1.564500387461521, 52.619960926889632],
            [-1.566936909119993, 52.620632401995998],
            [-1.567590866855118, 52.622624233759197],
            [-1.566018746553815, 52.62595011056294],
            [-1.566490063638245, 52.629235808750131],
            [-1.565888499732152, 52.630306989229915],
            [-1.56461764514404, 52.631224681986303],
            [-1.566517097064487, 52.632332891866575],
            [-1.571026273929889, 52.634020494742757],
            [-1.571367268221765, 52.635113088218077],
            [-1.57100740018101, 52.636102461075446],
            [-1.565702602162252, 52.636253055236516],
            [-1.559021027306554, 52.637241541272196],
            [-1.556053866849819, 52.63825889984345],
            [-1.553313794548953, 52.640762152369675],
            [-1.550638336793195, 52.64111073502324],
            [-1.546999958441602, 52.642252976078026],
            [-1.544830323839451, 52.644117238583775],
            [-1.544294033871341, 52.645663218797765],
            [-1.542810618040984, 52.647602896237061],
            [-1.542801215602466, 52.648221356590042],
            [-1.544299036908065, 52.649449731810279],
            [-1.544964642435481, 52.650545444294288],
            [-1.544667534699585, 52.651542168339475],
            [-1.542428701049652, 52.65360928785914],
            [-1.547295993420781, 52.655956318735726],
            [-1.546105012483276, 52.659045142658329],
            [-1.55218855304893, 52.666881263706145],
            [-1.551777697938005, 52.667437974736593],
            [-1.543837494306772, 52.66668042906975],
            [-1.538610160133096, 52.667539404691745],
            [-1.538896577903524, 52.665755154474098],
            [-1.536901549004398, 52.66592537780086],
            [-1.536831282142912, 52.665718339115685],
            [-1.527549961167156, 52.667894105018469],
            [-1.517853766080331, 52.670865748744276],
            [-1.508211730653868, 52.674823898933482],
            [-1.506345830517944, 52.675299795377946],
            [-1.504788897310146, 52.676308247008542],
            [-1.504329778987579, 52.676232613151313],
            [-1.503041441559598, 52.677546916418891],
            [-1.502367685502402, 52.677616009300309],
            [-1.502293938401507, 52.676946862766577],
            [-1.50061790992229, 52.676582025497446],
            [-1.499064165912773, 52.675226111690094],
            [-1.497952937700341, 52.675012850620625],
            [-1.497993341783022, 52.674326204663423],
            [-1.496754272344553, 52.67391461650648],
            [-1.496487214286445, 52.673602437519321],
            [-1.496991661715327, 52.673333087599858],
            [-1.495940368222464, 52.672542919432111],
            [-1.496357360787869, 52.672290280972021],
            [-1.49580969580297, 52.671689236133631],
            [-1.496236205731353, 52.671508556591967],
            [-1.495445467806774, 52.669671284327713],
            [-1.494021394312352, 52.669030539808332],
            [-1.487675719741551, 52.67120398893087],
            [-1.485653675955025, 52.673133428421046],
            [-1.481854858995333, 52.674966984025843],
            [-1.478865468905085, 52.676989166378732],
            [-1.477025961181468, 52.677697552448919],
            [-1.473360317982458, 52.670850012176707],
            [-1.469424805790447, 52.671244201022162],
            [-1.467455828669484, 52.669105698951903],
            [-1.467175899675808, 52.668047244345637],
            [-1.459006797829789, 52.6679716014728],
            [-1.458903260289952, 52.667015515808508],
            [-1.456888175154634, 52.667103393384401],
            [-1.456509090726285, 52.666788812267484],
            [-1.45695188548833, 52.664825674953889],
            [-1.445750616021469, 52.66394049511549],
            [-1.444477109744008, 52.665724402206678],
            [-1.441740061297564, 52.666424438050072],
            [-1.441561190785113, 52.667000739999985],
            [-1.44107401447531, 52.667402086024879],
            [-1.440417789644133, 52.66737651666466],
            [-1.437408251546732, 52.670052918671765],
            [-1.437304669403982, 52.670864204418208],
            [-1.437819171936982, 52.670989804650013],
            [-1.438177538524994, 52.671759230171148],
            [-1.437845402879669, 52.672749228656109],
            [-1.438888370858909, 52.673299847624179],
            [-1.439582950431751, 52.672992984808602],
            [-1.439017062851746, 52.67491502000351],
            [-1.436311677542782, 52.676781053990631],
            [-1.43483583013331, 52.6769924812669],
            [-1.434538448879649, 52.67757270190841],
            [-1.433520977245033, 52.677677521323197],
            [-1.431350245447875, 52.678775571336061],
            [-1.430502145345739, 52.679502370033745],
            [-1.430401844564834, 52.680618417668796],
            [-1.429773809330889, 52.681366944232188],
            [-1.430383602639285, 52.681680021598694],
            [-1.429007419628071, 52.682628117032351],
            [-1.424211657974202, 52.683984859779301],
            [-1.421464413316711, 52.683329620819407],
            [-1.419855127180023, 52.680302994480243],
            [-1.418198116048139, 52.678213743987797],
            [-1.413877165946212, 52.67626415896126],
            [-1.41205845634544, 52.674795215160799],
            [-1.409351037385774, 52.673655340791953],
            [-1.406972943739056, 52.673106781681184],
            [-1.402205263128548, 52.673081944087841],
            [-1.401924881185962, 52.672061986824609],
            [-1.399004627135283, 52.672454460085042],
            [-1.396717203954637, 52.673605225339244],
            [-1.394467310008105, 52.673422951757559],
            [-1.391452802469694, 52.673988260911585],
            [-1.383710402304505, 52.67431236233066],
            [-1.383655473890105, 52.67569380862075],
            [-1.382000140302979, 52.675297733204872],
            [-1.373937964182264, 52.677174765157588],
            [-1.371317598968949, 52.678375423915043],
            [-1.368425012548077, 52.678544344532732],
            [-1.366472956288607, 52.679031067950255],
            [-1.365308514180558, 52.680180030487641],
            [-1.364521864259083, 52.679754196144216],
            [-1.363328193413585, 52.680059739584721],
            [-1.362519252274287, 52.678422846111438],
            [-1.360597000419657, 52.676458113776377],
            [-1.359649291360406, 52.67805318509388],
            [-1.360495659225189, 52.678942348920408],
            [-1.360721121272796, 52.680520375500507],
            [-1.358347671340549, 52.682800854587981],
            [-1.353965419681139, 52.684956189486726],
            [-1.348451057677049, 52.685019515520935],
            [-1.349964880708109, 52.689372590218298],
            [-1.342618120651998, 52.690240962814343],
            [-1.351105931426849, 52.694138930782366],
            [-1.344795682507026, 52.694806340712198],
            [-1.341926402918659, 52.695412548254531],
            [-1.331260302365022, 52.698649442566001],
            [-1.330474780840494, 52.698440043819993],
            [-1.329775664910532, 52.69896649266267],
            [-1.324645383972566, 52.699723098774982],
            [-1.323077998741498, 52.700313782451992],
            [-1.32230245972119, 52.700224849719788],
            [-1.318128893418222, 52.701962028875649],
            [-1.311013503458425, 52.705725387804186],
            [-1.308809306147979, 52.706409272142452],
            [-1.299242556503804, 52.707408539408448],
            [-1.291744406954194, 52.709423565000513],
            [-1.289585922712402, 52.711819920260922],
            [-1.289161577397733, 52.712883566672581],
            [-1.288699040788088, 52.712106773395789],
            [-1.285291649131342, 52.715270474002217],
            [-1.282722002239087, 52.714623880814187],
            [-1.284868494470032, 52.714079474157877],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000133",
        LAD13CDO: "31UG",
        LAD13NM: "Melton",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.787569960452925, 52.977657257490876],
            [-0.784349574384056, 52.976397274330964],
            [-0.783180866122739, 52.976913018109087],
            [-0.781805059083112, 52.976424271033764],
            [-0.780096601427348, 52.977277862090133],
            [-0.778652670215759, 52.976670613635733],
            [-0.778284253033863, 52.976901461365358],
            [-0.777556418283475, 52.976621590771742],
            [-0.7777101036452, 52.97624200492028],
            [-0.777283180389438, 52.976012871316172],
            [-0.778228841006163, 52.975011244294024],
            [-0.777114295771831, 52.974556590840393],
            [-0.778083522486331, 52.972137521256322],
            [-0.777678193480083, 52.972041659230385],
            [-0.777720305987638, 52.966906236260442],
            [-0.778304161467166, 52.965315653400452],
            [-0.778014464165649, 52.962810820798893],
            [-0.779262660439514, 52.96131156336029],
            [-0.77779901548782, 52.960683421630364],
            [-0.778373433504338, 52.96032254265522],
            [-0.778442668457474, 52.95929212588613],
            [-0.776423045995714, 52.959113135498882],
            [-0.774318656925378, 52.958089095515518],
            [-0.773679273691904, 52.958483446088422],
            [-0.770327582015277, 52.956878306177636],
            [-0.764545066661411, 52.954914309668744],
            [-0.760004478153576, 52.952690599197879],
            [-0.759475206305847, 52.952824418975958],
            [-0.756951718205894, 52.950971326620561],
            [-0.755318238673397, 52.951356977244615],
            [-0.755289351973588, 52.950704013973507],
            [-0.755966282264012, 52.950559172557064],
            [-0.755473327886013, 52.950517154135781],
            [-0.754955286697045, 52.949534535743794],
            [-0.755826461892318, 52.947754683184804],
            [-0.755511562903648, 52.947110412261225],
            [-0.757927996801231, 52.946299634191718],
            [-0.757162556354973, 52.945588628892295],
            [-0.757444486169245, 52.945462123009676],
            [-0.756852263182693, 52.944575826620799],
            [-0.759540623582508, 52.941328021047397],
            [-0.760876110667959, 52.937710959375231],
            [-0.76209163931331, 52.935943639180522],
            [-0.761633342965384, 52.935318566222143],
            [-0.761875955574362, 52.934168596990773],
            [-0.765253446483703, 52.92988052243696],
            [-0.766111654437365, 52.928004266427592],
            [-0.765519583202613, 52.925859433855628],
            [-0.763102136298368, 52.923671339334625],
            [-0.766581822141933, 52.922924490934001],
            [-0.76748798384178, 52.922443047090454],
            [-0.766742177680935, 52.921468900624447],
            [-0.768440114589766, 52.920602807621492],
            [-0.768475662197294, 52.919874099144522],
            [-0.769449146501947, 52.919585720977643],
            [-0.768716556631806, 52.917411577841094],
            [-0.769500253030184, 52.917100556010183],
            [-0.768881584841129, 52.916259889312421],
            [-0.77198651513302, 52.91249020765656],
            [-0.773024433146537, 52.909020055988066],
            [-0.774547477120316, 52.907321407110992],
            [-0.775433774242263, 52.905736642006715],
            [-0.776864127419438, 52.904787661874288],
            [-0.773805876738523, 52.902514964522403],
            [-0.770013850585146, 52.903695694014452],
            [-0.768794072889658, 52.901932731359608],
            [-0.763971400613503, 52.903120563401686],
            [-0.763700157224227, 52.90105366449923],
            [-0.761777919024722, 52.901310534907687],
            [-0.76171866522036, 52.90046846412227],
            [-0.759114880072607, 52.900695711258315],
            [-0.758473154588143, 52.900468754773108],
            [-0.758680064472019, 52.899471241118384],
            [-0.759836232027646, 52.898706589002025],
            [-0.761582489481798, 52.89570150452073],
            [-0.762542192603219, 52.895096596171477],
            [-0.762494081107909, 52.894437135858702],
            [-0.763993319469553, 52.893623875347622],
            [-0.764322318040838, 52.89245681364531],
            [-0.763570554865357, 52.89197342632847],
            [-0.763944721152922, 52.890838300103312],
            [-0.766210584713385, 52.888408511463432],
            [-0.76675877354582, 52.887474759227224],
            [-0.766597242854675, 52.886777263896647],
            [-0.761665873412788, 52.884534220167232],
            [-0.760347149113987, 52.885176728964758],
            [-0.759454286184224, 52.884592053688024],
            [-0.75776075813043, 52.884255213701074],
            [-0.757162037162908, 52.885963327237441],
            [-0.750082212177391, 52.883929241425847],
            [-0.747739911679821, 52.882953438045696],
            [-0.745536801432723, 52.882771070424617],
            [-0.742727893729994, 52.883136030994777],
            [-0.740429874997058, 52.880023643870615],
            [-0.739180732215668, 52.876928640490824],
            [-0.736115190520094, 52.872944979150965],
            [-0.736155649622797, 52.870070423245579],
            [-0.733679190786787, 52.866901155901409],
            [-0.729110481873303, 52.862141611873909],
            [-0.724597625938821, 52.858803799771316],
            [-0.721846606394664, 52.855981034499059],
            [-0.719212619298133, 52.854391093915225],
            [-0.710196468579126, 52.845816830323571],
            [-0.708224315869179, 52.843474169373707],
            [-0.706547242971726, 52.840351657567744],
            [-0.705838240330395, 52.838031696881785],
            [-0.703660119641208, 52.833651365149215],
            [-0.698395540381164, 52.82597829635543],
            [-0.695611367344167, 52.823544695731997],
            [-0.692407341617904, 52.821759981215777],
            [-0.687926342223855, 52.818234977081751],
            [-0.686504676503437, 52.816268408085968],
            [-0.682049538681654, 52.811775227650621],
            [-0.682183089262566, 52.810695202600989],
            [-0.680637806559023, 52.80719257660941],
            [-0.682601787081287, 52.799739122547024],
            [-0.683244793896792, 52.79514691844458],
            [-0.681593044176321, 52.791109099527155],
            [-0.677313550229084, 52.789970078558419],
            [-0.677502416327802, 52.788338677464061],
            [-0.674118590932326, 52.787748026098456],
            [-0.674558887160278, 52.785507209412245],
            [-0.676967855295078, 52.785673438537017],
            [-0.67551577347623, 52.780998551519474],
            [-0.675668801670123, 52.777520175322245],
            [-0.674364326659865, 52.776734254506827],
            [-0.672766298002017, 52.771417594606774],
            [-0.669519589748446, 52.76627661766036],
            [-0.66771631811072, 52.761257846206412],
            [-0.664111082794406, 52.756690748533146],
            [-0.664909499100472, 52.756457900496621],
            [-0.669544071570535, 52.756622344154231],
            [-0.671330767845754, 52.756391542099166],
            [-0.671563510220156, 52.75677802917879],
            [-0.674095996518814, 52.756923232540537],
            [-0.674524628810733, 52.757935820949072],
            [-0.684438335132351, 52.756460303437308],
            [-0.684086801217341, 52.754848962565212],
            [-0.684360577788578, 52.753089925619996],
            [-0.690050889577759, 52.751984174062258],
            [-0.696976263772739, 52.749200673099409],
            [-0.696253179920842, 52.7464732025079],
            [-0.699947386997514, 52.746592860027484],
            [-0.701717797656816, 52.746043172071403],
            [-0.704289691774147, 52.746140516705964],
            [-0.707553804100721, 52.745574683511059],
            [-0.718206553471976, 52.744777770443342],
            [-0.717711629689378, 52.741955806072767],
            [-0.719374703873889, 52.741718452005109],
            [-0.719531249051533, 52.741481902864663],
            [-0.720373306141226, 52.741610558770567],
            [-0.720219554417037, 52.740942729553161],
            [-0.7237452063376, 52.740378375346111],
            [-0.728889752022343, 52.740018270947544],
            [-0.729078315782995, 52.739391880026218],
            [-0.733820094675534, 52.739737467703428],
            [-0.74297992654833, 52.738353315462561],
            [-0.745150337269674, 52.738388882369492],
            [-0.751524303868437, 52.736716550929152],
            [-0.753328500713508, 52.73655482552337],
            [-0.754629792105749, 52.737720130841936],
            [-0.754006326826832, 52.738443581797512],
            [-0.754277052925196, 52.738663087361054],
            [-0.754866142374181, 52.738938077317748],
            [-0.755620330177751, 52.738721238493255],
            [-0.755849254760967, 52.739212702684],
            [-0.756354928435342, 52.738984262215489],
            [-0.756157955854539, 52.738618995825036],
            [-0.756686847939576, 52.738511265110525],
            [-0.757667377945782, 52.739033076758105],
            [-0.757987162899979, 52.739716078867055],
            [-0.759166209203995, 52.73956030307108],
            [-0.759135134126365, 52.739141937320881],
            [-0.760020532426969, 52.738946221383607],
            [-0.760032394681855, 52.738632590122783],
            [-0.761605052504622, 52.738802750499474],
            [-0.761491139592456, 52.738066169245805],
            [-0.762717088510549, 52.738292029180158],
            [-0.763575065777601, 52.739064236633794],
            [-0.764734708821782, 52.739174310840255],
            [-0.764819146359595, 52.739960027309763],
            [-0.764280144610726, 52.740058698829067],
            [-0.764133779954352, 52.740520164972011],
            [-0.765329316194913, 52.741353412294799],
            [-0.766919826816984, 52.741734955032747],
            [-0.766756170063788, 52.74218006223709],
            [-0.767835949924651, 52.742971624658374],
            [-0.76766241504383, 52.743662060355732],
            [-0.768612051035168, 52.743659335798178],
            [-0.768606132983475, 52.743343721589603],
            [-0.770420145123146, 52.742893254833319],
            [-0.770220767090629, 52.74360859852456],
            [-0.771300269653667, 52.743623380892849],
            [-0.771421250726793, 52.744167636254126],
            [-0.772723981670672, 52.743777465657068],
            [-0.773633191087893, 52.743895649005971],
            [-0.773742567786168, 52.744168280802477],
            [-0.774848685690719, 52.744026878168043],
            [-0.775551838249822, 52.743516308399379],
            [-0.77649320803599, 52.743914393884445],
            [-0.778170880807289, 52.74245730938793],
            [-0.779752611847558, 52.741779555129639],
            [-0.780019366855258, 52.740979482920835],
            [-0.78037826102487, 52.74101733586285],
            [-0.780681475313194, 52.740555664802628],
            [-0.78152802519206, 52.740847551714808],
            [-0.781508763607905, 52.740582145906338],
            [-0.782369589568961, 52.740680886293617],
            [-0.78236081085727, 52.740358051604311],
            [-0.783597382648098, 52.740368041495351],
            [-0.787093701418216, 52.740935157694459],
            [-0.78915181277947, 52.740004133077598],
            [-0.789583278703758, 52.740202722604657],
            [-0.791284235597102, 52.739864059416263],
            [-0.791003359211115, 52.740165062156876],
            [-0.791856418088946, 52.740170157504842],
            [-0.791752137735198, 52.739548779006327],
            [-0.792599720629267, 52.739322767912284],
            [-0.793262161110802, 52.739471552299094],
            [-0.794253321786019, 52.737960507816076],
            [-0.795299772812324, 52.737987322431117],
            [-0.795638202616207, 52.736993757825324],
            [-0.796551803959133, 52.736788167177167],
            [-0.796900625320773, 52.73729874685727],
            [-0.797541995250477, 52.737082296044051],
            [-0.79741760529705, 52.736812231269404],
            [-0.798335902374379, 52.7367586070675],
            [-0.799025810747389, 52.73714677405534],
            [-0.799026634599088, 52.736739531312338],
            [-0.799984265775603, 52.736979369517627],
            [-0.800215751250627, 52.736532206681034],
            [-0.800431320693789, 52.736772624283631],
            [-0.801199912878618, 52.736722857926047],
            [-0.801266055559942, 52.735985440061789],
            [-0.802219555788632, 52.735619286524781],
            [-0.80329317239785, 52.733948976224291],
            [-0.805639539011655, 52.731603736766303],
            [-0.810146499323233, 52.730011039563855],
            [-0.812914564293528, 52.729456217689417],
            [-0.813581531672254, 52.728944162917223],
            [-0.813723976064609, 52.727460424774463],
            [-0.815180581235214, 52.725785746211329],
            [-0.816519158770111, 52.725185087206384],
            [-0.817280552204608, 52.72539316123958],
            [-0.817407294366071, 52.725086963908709],
            [-0.816631919580436, 52.72462972559498],
            [-0.818405296719907, 52.72262193818878],
            [-0.818103236651551, 52.722348326625259],
            [-0.818553985767114, 52.722215269148634],
            [-0.818372124435017, 52.721657869514154],
            [-0.817961540978781, 52.721784134530878],
            [-0.817745995835861, 52.721541950533968],
            [-0.81860781075901, 52.719565524824141],
            [-0.818335395948041, 52.719346149529606],
            [-0.819798934168717, 52.718549815702147],
            [-0.819349974682786, 52.718014931225099],
            [-0.819614206697214, 52.717659755412058],
            [-0.820284832016697, 52.717715873771567],
            [-0.82046692456707, 52.717441688894183],
            [-0.820910735714779, 52.717618712026635],
            [-0.821289455950916, 52.717354571708931],
            [-0.821480122572084, 52.716926739800542],
            [-0.820978109305962, 52.716767120356863],
            [-0.821599845578602, 52.716604285858793],
            [-0.821217238136073, 52.715969378435084],
            [-0.821761604500388, 52.715655639371413],
            [-0.815195733303857, 52.714953725684765],
            [-0.814581454754099, 52.713475906159317],
            [-0.813221682221945, 52.713451518174864],
            [-0.813175120187984, 52.712276044430986],
            [-0.800243694588713, 52.707778519309421],
            [-0.803377460179566, 52.704629474852503],
            [-0.802437035476269, 52.703245389389735],
            [-0.799306870284476, 52.703298242911352],
            [-0.799507428515044, 52.703486369404622],
            [-0.797492688140959, 52.704041324033398],
            [-0.796615504174957, 52.703970393357707],
            [-0.796351691171089, 52.70360721115086],
            [-0.795547544334377, 52.701528615829588],
            [-0.796155139220292, 52.701176079201588],
            [-0.79638080125617, 52.69996649839878],
            [-0.795753791636053, 52.696830661179263],
            [-0.793116272951044, 52.696152957181269],
            [-0.790084857746084, 52.694364486678474],
            [-0.786054612036381, 52.6947215561664],
            [-0.785813762574069, 52.694229126094029],
            [-0.785558140452722, 52.692829436442352],
            [-0.786791830235099, 52.687182754773453],
            [-0.785064844547631, 52.684693670511507],
            [-0.783856025735486, 52.681930281103568],
            [-0.784002343384476, 52.67698088586863],
            [-0.784396069355345, 52.676544404490038],
            [-0.784244882369236, 52.67506396745808],
            [-0.783323919385281, 52.672171362891405],
            [-0.782126327234819, 52.670456321143831],
            [-0.782204877486904, 52.669120284967093],
            [-0.78337567624265, 52.667858395397083],
            [-0.7845466756594, 52.667279751926117],
            [-0.790149988549386, 52.667165361954616],
            [-0.791134520771959, 52.665140924183426],
            [-0.793602803319586, 52.663357242626866],
            [-0.794126086034897, 52.662342179327169],
            [-0.794528844292761, 52.66087008457793],
            [-0.792928239382348, 52.65966439085696],
            [-0.793885776009431, 52.659093370944802],
            [-0.79489816429315, 52.65695680657894],
            [-0.797418221311162, 52.657627899281039],
            [-0.798156727137232, 52.657600328144902],
            [-0.79882448252787, 52.657133313347941],
            [-0.801589482403118, 52.651876857989762],
            [-0.804384719922448, 52.649443569076965],
            [-0.805507784644431, 52.647611899560872],
            [-0.807095078204209, 52.647673737050802],
            [-0.807408571609763, 52.646799447196791],
            [-0.808702960386264, 52.646107632586414],
            [-0.816094522921715, 52.645952451517239],
            [-0.820189380013058, 52.647906402006015],
            [-0.822281201913869, 52.647235860858736],
            [-0.823237615669851, 52.649160273505728],
            [-0.823615846983249, 52.653353458857318],
            [-0.823122760295422, 52.653383625497703],
            [-0.822613812458189, 52.65125239001366],
            [-0.821470773258974, 52.65038606177928],
            [-0.817197387963213, 52.649943427094648],
            [-0.813531692316163, 52.652010494630076],
            [-0.811592438191818, 52.655000984914111],
            [-0.809496483326436, 52.657164569201804],
            [-0.809538072028319, 52.657592920310115],
            [-0.816976544166865, 52.656647909755527],
            [-0.823410585146986, 52.658026319081628],
            [-0.825404948175274, 52.658084757481085],
            [-0.827720030729677, 52.658810709045603],
            [-0.8295583536477, 52.658734479343451],
            [-0.830182374702568, 52.660724771630328],
            [-0.829242060953586, 52.662770641496159],
            [-0.830638721964823, 52.667781163316839],
            [-0.831910591078685, 52.670605825480976],
            [-0.833990739732702, 52.672685949087423],
            [-0.841129660078912, 52.675537503401834],
            [-0.843046109092016, 52.675496887122954],
            [-0.855405876173646, 52.673315390710314],
            [-0.857384966494855, 52.673760614968565],
            [-0.858753464272561, 52.673744129805002],
            [-0.858988233755328, 52.674440427798352],
            [-0.858274749267251, 52.674860589696706],
            [-0.859053517627899, 52.676079057706197],
            [-0.856381824247721, 52.67977972278463],
            [-0.854753256518583, 52.683285433363395],
            [-0.855554319701445, 52.68495454277484],
            [-0.859747610689351, 52.684357546568847],
            [-0.861784183837877, 52.684319579091778],
            [-0.866702374650956, 52.684915988923301],
            [-0.870499313930424, 52.68453777824562],
            [-0.871155481564593, 52.6841619480685],
            [-0.872614166054029, 52.684332255427137],
            [-0.873324166298527, 52.681116051371419],
            [-0.874427540099553, 52.679855330423251],
            [-0.88063457101507, 52.677585660021869],
            [-0.88256389644505, 52.675940690768151],
            [-0.883509151796677, 52.675587295343746],
            [-0.887235428218983, 52.675100004863673],
            [-0.887661047392845, 52.674997016859528],
            [-0.887652055395308, 52.674535740007407],
            [-0.890215932382018, 52.674156113196325],
            [-0.890644258309586, 52.674471179755379],
            [-0.891918910084769, 52.674422866982603],
            [-0.892426403435261, 52.674181280556155],
            [-0.89222606414786, 52.673944766964127],
            [-0.893371534147611, 52.673652498634489],
            [-0.893940010311619, 52.674392295252233],
            [-0.895841408266839, 52.673947029581882],
            [-0.896809895980972, 52.674661768851813],
            [-0.897435285196564, 52.674024794715017],
            [-0.89826496219504, 52.674143985374783],
            [-0.898192282280607, 52.674446276249697],
            [-0.899561979526406, 52.674205477507378],
            [-0.900268062672292, 52.674947419517281],
            [-0.901563177147507, 52.674731977040089],
            [-0.901529118081889, 52.675500314605081],
            [-0.902435465646042, 52.675394534910424],
            [-0.90262582730027, 52.675825124609226],
            [-0.90307889030272, 52.675688173031872],
            [-0.90315940426903, 52.675957721748652],
            [-0.902609643795859, 52.676057818219945],
            [-0.903109221563074, 52.676307871059166],
            [-0.903715854129886, 52.675708449057431],
            [-0.905438874211304, 52.67559311439431],
            [-0.905971858328939, 52.676045740605559],
            [-0.907394790443069, 52.67622068474153],
            [-0.907932172181315, 52.676557370376536],
            [-0.908545377531151, 52.676344558255821],
            [-0.908716511791276, 52.676718323290871],
            [-0.909774949565557, 52.677098452986939],
            [-0.91041566566927, 52.676849923856196],
            [-0.911017898262358, 52.677197080437494],
            [-0.910886605368369, 52.676885716441134],
            [-0.911852747785849, 52.676635673657408],
            [-0.911934548520697, 52.677153354291114],
            [-0.912795885161917, 52.676537344488224],
            [-0.912120432569417, 52.675743616811815],
            [-0.912680963161176, 52.675387357645675],
            [-0.912450560496305, 52.674301938768451],
            [-0.913191398848509, 52.673950028162018],
            [-0.91259607317573, 52.673623622894475],
            [-0.913243973240572, 52.673500106711536],
            [-0.913829495476468, 52.672492289776834],
            [-0.914403439939153, 52.672487657874697],
            [-0.914057875598874, 52.672347843561695],
            [-0.914509995097287, 52.672066998405782],
            [-0.914039653030917, 52.671949415660663],
            [-0.914268399998514, 52.671730354652787],
            [-0.914980909191479, 52.671385365546818],
            [-0.915836421586548, 52.671536135013149],
            [-0.916058154887632, 52.671121921492585],
            [-0.934757012060405, 52.674539532119283],
            [-0.941312263229306, 52.676791827762614],
            [-0.943407392346541, 52.678048449643875],
            [-0.948932608263597, 52.680362156231091],
            [-0.947246089600915, 52.682709778003783],
            [-0.954754194734802, 52.684042004695151],
            [-0.956176544800712, 52.6826466949571],
            [-0.961019157860854, 52.684727271387054],
            [-0.971176446550051, 52.691019858115773],
            [-0.992739582470661, 52.695326906946434],
            [-0.99604845071093, 52.69576760818665],
            [-1.006103446353383, 52.698360516839962],
            [-1.007510213219712, 52.696295613215241],
            [-1.007983447727034, 52.696373290950334],
            [-1.006319803700862, 52.698607756506433],
            [-1.004854005426093, 52.70449918327661],
            [-1.00681348922141, 52.704827561934998],
            [-1.006772631932509, 52.705323466172764],
            [-1.009666965666088, 52.705808856715336],
            [-1.011564422095569, 52.706462974224472],
            [-1.013416738640122, 52.709580834096947],
            [-1.013011140860826, 52.709910087850695],
            [-1.013230735926593, 52.710214876780661],
            [-1.015257831042584, 52.710644364482953],
            [-1.01548344616886, 52.710945603138605],
            [-1.013366265200473, 52.713590834014852],
            [-1.01270556346556, 52.713218545733696],
            [-1.01080101956423, 52.714435199024884],
            [-1.007656866756345, 52.71736662883221],
            [-1.004860985714128, 52.717076201865261],
            [-1.00328520931084, 52.717231981292528],
            [-1.003568879627438, 52.720181271555674],
            [-1.001094806731979, 52.720055278680377],
            [-1.000674214995015, 52.722263263955185],
            [-1.001057291298393, 52.723641953034608],
            [-1.004721813607303, 52.723581960071904],
            [-1.008206229137584, 52.724020188855903],
            [-1.01725093335779, 52.724447876930306],
            [-1.018774010431447, 52.726781686689968],
            [-1.019917603461931, 52.726960151858741],
            [-1.027459777149413, 52.734920771449289],
            [-1.026198305493708, 52.734638028475885],
            [-1.025681454276616, 52.734836057615915],
            [-1.02299295750561, 52.734655723255322],
            [-1.021501855502855, 52.735105514934801],
            [-1.019588586083774, 52.735028586450611],
            [-1.019418649463085, 52.735539607162657],
            [-1.019940650510418, 52.735774062757955],
            [-1.019429324864417, 52.737110233727762],
            [-1.021852005552747, 52.73779099298995],
            [-1.024262910799045, 52.740461971173005],
            [-1.023672048905386, 52.740720514253205],
            [-1.02857465353361, 52.744798124147131],
            [-1.031487967784923, 52.747963910225728],
            [-1.031165811521258, 52.748261542276154],
            [-1.033059861208293, 52.749769315185375],
            [-1.03254251895937, 52.750116602353636],
            [-1.033535347175203, 52.751112682247133],
            [-1.032327913522626, 52.751979354376076],
            [-1.033666478080408, 52.753355826978947],
            [-1.030388874102615, 52.756166293793619],
            [-1.034727841911307, 52.757088073561945],
            [-1.036848581015459, 52.758351303501335],
            [-1.038543793442078, 52.761447677371798],
            [-1.038617235110243, 52.763294790445741],
            [-1.041547950916953, 52.763123418689695],
            [-1.041430595434953, 52.766038780834648],
            [-1.045913232326227, 52.766002987961329],
            [-1.045352327973462, 52.767186036412255],
            [-1.042087019609146, 52.769760486894256],
            [-1.043488867129739, 52.771602127599749],
            [-1.037954495153864, 52.775010391034947],
            [-1.040653556624831, 52.777388478018089],
            [-1.046630537517093, 52.780934587263488],
            [-1.042428492996175, 52.801148526428165],
            [-1.037004229752263, 52.810343464198041],
            [-1.036237829088062, 52.812663803193999],
            [-1.036296313250147, 52.815856551852363],
            [-1.03646357900326, 52.816210309080326],
            [-1.034825999887728, 52.81685595876008],
            [-1.029925752487182, 52.818085378641776],
            [-1.027996976613745, 52.819604160085575],
            [-1.027859075510187, 52.820131630448195],
            [-1.025592414656488, 52.819432522334772],
            [-1.025151532773731, 52.820036612801694],
            [-1.021940047959902, 52.822031104780869],
            [-1.019635875372688, 52.822141554135953],
            [-1.019213417012744, 52.821724534762033],
            [-1.015555792650992, 52.821509073885899],
            [-1.010188046890217, 52.821712563632865],
            [-1.001685081376708, 52.820403475355697],
            [-0.998586278821589, 52.820546386728722],
            [-0.995689682288719, 52.821250992944051],
            [-0.995500163634906, 52.820910473889363],
            [-0.993815921917987, 52.820650790343826],
            [-0.991541366651875, 52.81973790465095],
            [-0.98901065452197, 52.818229465962624],
            [-0.98767463426574, 52.819402040383665],
            [-0.983497577142162, 52.82034893741924],
            [-0.981929777689287, 52.821206617216667],
            [-0.981922629661981, 52.82157603742494],
            [-0.981320947787074, 52.821667966500328],
            [-0.981452994737462, 52.822177024068765],
            [-0.979936905554789, 52.823610469508857],
            [-0.980093070599753, 52.823912969927399],
            [-0.978143601002842, 52.824520100765476],
            [-0.977794161085972, 52.824991755028506],
            [-0.978059804991339, 52.825498370405512],
            [-0.97738376219644, 52.826542559917556],
            [-0.977173408466443, 52.828105874326027],
            [-0.975730782956066, 52.828293910903646],
            [-0.975901100614585, 52.828940848508175],
            [-0.975057706867286, 52.829207760380697],
            [-0.975519988782444, 52.829683715046734],
            [-0.974784207322621, 52.829918291163374],
            [-0.97536563036574, 52.83062990902858],
            [-0.975313990037997, 52.831373819520032],
            [-0.974930071707969, 52.832991367921728],
            [-0.97444102893763, 52.833083335950271],
            [-0.973920955872098, 52.834303254402194],
            [-0.977369819270966, 52.836619113328517],
            [-0.977080572091004, 52.840716867777601],
            [-0.97307920208543, 52.843119464930346],
            [-0.972324180471675, 52.843215416972008],
            [-0.971520013363603, 52.844260260666708],
            [-0.970481032610278, 52.844747494312841],
            [-0.970618441825068, 52.844964440489612],
            [-0.960857696308963, 52.84333588174497],
            [-0.960383076062933, 52.843874711554797],
            [-0.959410161861278, 52.843829331916062],
            [-0.959683218735976, 52.844518545125752],
            [-0.958943206843946, 52.844729613174209],
            [-0.959195493385777, 52.845169627876061],
            [-0.957774731361282, 52.845223691654056],
            [-0.957459511867248, 52.846296104832867],
            [-0.956808078632844, 52.846526815839681],
            [-0.955880211796421, 52.846334370477869],
            [-0.955756711848587, 52.84684030930039],
            [-0.956172742498722, 52.847014773046951],
            [-0.954974690034091, 52.84762363358125],
            [-0.954615360586319, 52.848983324475356],
            [-0.952777364542626, 52.850388417919341],
            [-0.951742969932086, 52.85048537163199],
            [-0.951306528895397, 52.850849201177176],
            [-0.950696834680861, 52.850637949361499],
            [-0.950981660484488, 52.851266155537395],
            [-0.950330682610947, 52.851350301543029],
            [-0.950311304621182, 52.851661177251827],
            [-0.949168923221662, 52.851431723443802],
            [-0.949036595397224, 52.851930385248068],
            [-0.947840026252915, 52.851362422669112],
            [-0.946894037697178, 52.852284482055765],
            [-0.946277110532276, 52.852249343516156],
            [-0.946049030431909, 52.852530498288758],
            [-0.945542972360289, 52.852454986734749],
            [-0.945493757260375, 52.852095856735616],
            [-0.944940968838094, 52.852661799379248],
            [-0.94422630719199, 52.852614994132701],
            [-0.944126318060588, 52.853002464559779],
            [-0.943410933455949, 52.853107575623937],
            [-0.943336636720131, 52.853598656658207],
            [-0.942419116566726, 52.85352666203319],
            [-0.942379548181994, 52.853932648633993],
            [-0.941763185999242, 52.85411774185792],
            [-0.941180916741142, 52.853939048933441],
            [-0.941845189519481, 52.854532003312848],
            [-0.941134919284699, 52.85485290151064],
            [-0.940590469314817, 52.854523514277773],
            [-0.940918104668886, 52.855096389765706],
            [-0.940119892719071, 52.855307721089389],
            [-0.940519253240977, 52.8559846203581],
            [-0.939404193852928, 52.856033101514022],
            [-0.939712608941403, 52.856602212641569],
            [-0.940294820004961, 52.856784507977082],
            [-0.939899502521449, 52.856977856587548],
            [-0.939191163855345, 52.856790836900309],
            [-0.939394757899588, 52.858004479873323],
            [-0.938680573096137, 52.858969896890464],
            [-0.93930405446903, 52.858983557557281],
            [-0.939483848758632, 52.859467915091123],
            [-0.940556634939579, 52.859756173036637],
            [-0.940671910085198, 52.860328952044362],
            [-0.941410303176914, 52.860321151957578],
            [-0.940939548557498, 52.86056147752344],
            [-0.940832472688295, 52.861543106798869],
            [-0.938468448115205, 52.861941824762319],
            [-0.935415230422971, 52.863187451639014],
            [-0.934236237611435, 52.864079453319498],
            [-0.928126748198923, 52.866062461639231],
            [-0.932721841052788, 52.870042266012348],
            [-0.936069336493401, 52.8717902439884],
            [-0.938911498562603, 52.874043333217763],
            [-0.939994591113264, 52.8750814331801],
            [-0.94057888121254, 52.876835155749824],
            [-0.933913070767113, 52.876598420929049],
            [-0.933702466731766, 52.877127826217873],
            [-0.931578681660898, 52.877992438003183],
            [-0.930351182532107, 52.878963973473837],
            [-0.92787938083291, 52.878327688850923],
            [-0.925964288697116, 52.878797634928141],
            [-0.92068762226334, 52.879158844044007],
            [-0.920461685308897, 52.879640441151132],
            [-0.916929855119591, 52.878910685261978],
            [-0.916631850909609, 52.879599282144483],
            [-0.915811625861216, 52.87965742430881],
            [-0.91485521428636, 52.880766121451281],
            [-0.914344081914494, 52.882429954680021],
            [-0.911784244196937, 52.88222493919946],
            [-0.911981667874356, 52.883540154609776],
            [-0.909127174865394, 52.883645227492828],
            [-0.906313014940184, 52.884509340929228],
            [-0.906039544811395, 52.884628185015032],
            [-0.906532007360173, 52.885197277300648],
            [-0.900896710872873, 52.887537455302507],
            [-0.89931220189883, 52.888072061076571],
            [-0.899044558008447, 52.88790057309415],
            [-0.896268871959226, 52.889162154829407],
            [-0.88900004083319, 52.891518047885093],
            [-0.886675146530952, 52.89201814096382],
            [-0.878723940639937, 52.895691657200487],
            [-0.875473621363084, 52.897558682054282],
            [-0.875040318440395, 52.897470975039724],
            [-0.874324297322655, 52.898196861105845],
            [-0.873012818462706, 52.898150267011161],
            [-0.869507915037529, 52.900243055172226],
            [-0.86024689046363, 52.903998613155515],
            [-0.857637955590391, 52.905621387533337],
            [-0.86126057676435, 52.908604811761123],
            [-0.861403143344555, 52.909065555992072],
            [-0.860445227816378, 52.909813313005188],
            [-0.863752023242067, 52.911304021498786],
            [-0.862897700020329, 52.913092905951416],
            [-0.860488977004453, 52.915796431264525],
            [-0.858263026229358, 52.917476829669042],
            [-0.857508213796577, 52.918862091210961],
            [-0.854160571097467, 52.920877730087689],
            [-0.853338100705261, 52.921213206239102],
            [-0.852986261631502, 52.921074964195832],
            [-0.848231832260948, 52.923566793212252],
            [-0.84137738017025, 52.928361835056769],
            [-0.842410701681932, 52.928495963311789],
            [-0.840601453987569, 52.930342805782409],
            [-0.84133732176934, 52.93108804231403],
            [-0.836782734314752, 52.935771272091273],
            [-0.835336151878792, 52.937778904140444],
            [-0.836223919864411, 52.938077070440471],
            [-0.834894377228814, 52.939324409787993],
            [-0.827545464511831, 52.941673089063379],
            [-0.824912852795575, 52.941567981420413],
            [-0.82436574572481, 52.94176843699303],
            [-0.822816655857291, 52.943685905309636],
            [-0.822544386637408, 52.944121909162035],
            [-0.828062212665792, 52.944523445482602],
            [-0.833913680153615, 52.945859337326802],
            [-0.830886562605103, 52.94856428588956],
            [-0.832861094015138, 52.949578855125658],
            [-0.820027248135433, 52.960443023802654],
            [-0.813707122408539, 52.956973008151294],
            [-0.812856150701584, 52.957442768218698],
            [-0.812472451299177, 52.959113727333829],
            [-0.811762782050119, 52.959803342089117],
            [-0.811993514907201, 52.960781935254381],
            [-0.809899093455317, 52.964946615032211],
            [-0.809817716499701, 52.965958045946202],
            [-0.806904096151035, 52.96670555643383],
            [-0.805876195603829, 52.967318226191345],
            [-0.805246499209642, 52.968068836668131],
            [-0.804047246649399, 52.971596941130912],
            [-0.803028700736821, 52.971703557961419],
            [-0.80209433524082, 52.972535587665298],
            [-0.801785300238595, 52.972348183893054],
            [-0.801139861004523, 52.972532260069308],
            [-0.801453034726656, 52.972785332374578],
            [-0.800832512747204, 52.973144958096483],
            [-0.799860010520272, 52.973146832298688],
            [-0.79911692762901, 52.974327773566785],
            [-0.796972104140133, 52.97584064064246],
            [-0.797281068440273, 52.976191669415378],
            [-0.796699352512199, 52.976223540625391],
            [-0.796155646140872, 52.97670977588983],
            [-0.790513739620958, 52.977201792286507],
            [-0.788860494616311, 52.976671648880512],
            [-0.787569960452925, 52.977657257490876],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000134",
        LAD13CDO: "31UH",
        LAD13NM: "North West Leicestershire",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.295941608108902, 52.874755862666092],
            [-1.289492575696654, 52.872438621452453],
            [-1.286007263006212, 52.872611900901759],
            [-1.280611850282119, 52.875267228016149],
            [-1.278433046144174, 52.875574043166203],
            [-1.274658679198051, 52.875510607125904],
            [-1.271480665615646, 52.874971566325421],
            [-1.26789454801181, 52.873349381641709],
            [-1.270240117082718, 52.871601859545336],
            [-1.270788607750876, 52.869192429963974],
            [-1.271553325083728, 52.867971844307711],
            [-1.271070134501866, 52.86755176483193],
            [-1.26966203214313, 52.867187129859303],
            [-1.269509750600329, 52.866524561636574],
            [-1.271584088762525, 52.865247296200906],
            [-1.271532409364927, 52.863986642064255],
            [-1.272666603419661, 52.862870794703937],
            [-1.272760113827542, 52.862163888111176],
            [-1.275288495413241, 52.860360749867809],
            [-1.27391972584805, 52.859519957036746],
            [-1.27149342259561, 52.859040340839606],
            [-1.269012217131933, 52.858914526371564],
            [-1.267950081007033, 52.859720643261184],
            [-1.267248718389454, 52.859827789712682],
            [-1.264925222683899, 52.858716705929957],
            [-1.264549691817624, 52.858087807788003],
            [-1.265089177687806, 52.856923400160468],
            [-1.266764143003464, 52.856236159638115],
            [-1.268220490264751, 52.856182213391882],
            [-1.269798202558297, 52.854913604034252],
            [-1.270609389935301, 52.853830848420031],
            [-1.271092385455717, 52.851149523201883],
            [-1.272242205998623, 52.849257073776641],
            [-1.27109822368015, 52.847519562665667],
            [-1.270846453546208, 52.84615070012638],
            [-1.268638107325029, 52.84564718946244],
            [-1.26777635423535, 52.845151946877607],
            [-1.266218012347474, 52.842734015549055],
            [-1.266403560571756, 52.842207472090344],
            [-1.269078036782482, 52.840314569739299],
            [-1.270519595004255, 52.839959353798136],
            [-1.270739781127307, 52.839576849909029],
            [-1.26757782080601, 52.838859798794651],
            [-1.266750253576527, 52.837665367048714],
            [-1.268441507076661, 52.836757957785018],
            [-1.269539381289389, 52.836619083795767],
            [-1.272336868812674, 52.836930217324216],
            [-1.274163128124903, 52.836286050481284],
            [-1.274029959699964, 52.833677350729182],
            [-1.272098547622065, 52.832448334640297],
            [-1.271359304800488, 52.831563717905638],
            [-1.270894615472758, 52.828288644518977],
            [-1.271258471597482, 52.824459500120263],
            [-1.270890759824597, 52.823729982057756],
            [-1.268950781826521, 52.823042933475719],
            [-1.265236335713342, 52.820664723514135],
            [-1.261545576010954, 52.820415289351345],
            [-1.260082185432946, 52.819206062602206],
            [-1.260116610886864, 52.818664201742536],
            [-1.262137937908293, 52.816893234688017],
            [-1.263383341777353, 52.815020333805215],
            [-1.261932754567759, 52.810433796154818],
            [-1.268581141425275, 52.807753770910523],
            [-1.270992286831712, 52.804531425544376],
            [-1.271905772634992, 52.804636816327232],
            [-1.272438101153607, 52.802248830699746],
            [-1.274116639402585, 52.802282478513952],
            [-1.274569889946725, 52.800537659014267],
            [-1.276455917925949, 52.800309144538815],
            [-1.275659663568298, 52.798687048835532],
            [-1.276489184879288, 52.798661542288961],
            [-1.276846385168991, 52.796708466692678],
            [-1.278892158136157, 52.796046685952227],
            [-1.27818905609919, 52.795067933161953],
            [-1.279141032632179, 52.794269142362765],
            [-1.27962171409678, 52.792282646390902],
            [-1.279614732745755, 52.788664260406208],
            [-1.278824652385063, 52.788199191606402],
            [-1.275992428102643, 52.78793293703886],
            [-1.274834721365409, 52.787553697544261],
            [-1.278676678164916, 52.783157778229196],
            [-1.279272880169333, 52.78323691311995],
            [-1.279735599164857, 52.783318830991796],
            [-1.279885817065687, 52.783829457479584],
            [-1.282604985001715, 52.783652644341146],
            [-1.283092545257292, 52.783937866026292],
            [-1.284268104673661, 52.783950353915202],
            [-1.285573609546192, 52.783723587859903],
            [-1.288626670676962, 52.784163539956623],
            [-1.290000862810224, 52.783814877235734],
            [-1.291420167526884, 52.782330172540917],
            [-1.293097839148826, 52.783377584380737],
            [-1.296333186900027, 52.784101598249727],
            [-1.299983645335127, 52.784373094856164],
            [-1.303325603026476, 52.78400350325559],
            [-1.305267401689789, 52.784966889872791],
            [-1.308381423433867, 52.785226896282182],
            [-1.31149808909896, 52.784569890334723],
            [-1.313193705114751, 52.784513189515806],
            [-1.314487147662912, 52.784668096637176],
            [-1.315222722273701, 52.785319598033084],
            [-1.318756135578688, 52.785409149048085],
            [-1.322683996230271, 52.779096609591953],
            [-1.327710412948429, 52.775682141607085],
            [-1.328056912169455, 52.774647594501147],
            [-1.327773476100078, 52.772453407987612],
            [-1.329118076577158, 52.769741638264591],
            [-1.328505607415171, 52.76822071657255],
            [-1.32906593760916, 52.766382798306878],
            [-1.334022534057777, 52.763821685805866],
            [-1.334778263601503, 52.763146303857667],
            [-1.334476402459935, 52.761851894397466],
            [-1.335074716558938, 52.761779734687671],
            [-1.333394854612623, 52.760184529618627],
            [-1.334472396047168, 52.756960580247288],
            [-1.331517410804745, 52.755531690123476],
            [-1.332387346200814, 52.752999693609773],
            [-1.329293700347214, 52.752325101496744],
            [-1.328453083126494, 52.753228307639667],
            [-1.325774342706311, 52.75380104518721],
            [-1.324900636271925, 52.754825399178102],
            [-1.323748465353562, 52.754883564932541],
            [-1.321398049066198, 52.753291555130915],
            [-1.321298622822238, 52.75129886686571],
            [-1.319059178108718, 52.749924095853743],
            [-1.313848216263277, 52.749103882782705],
            [-1.312610758743175, 52.750259990412303],
            [-1.311399244903806, 52.750235885803164],
            [-1.308563845138816, 52.74778588080919],
            [-1.30684176201692, 52.74782617621652],
            [-1.306337259671834, 52.74834373308363],
            [-1.30541150432734, 52.748519910176547],
            [-1.30253751417196, 52.747401811022577],
            [-1.301280921448492, 52.746588938750349],
            [-1.300342157695906, 52.746468338638905],
            [-1.299067869515468, 52.745377555596036],
            [-1.297921816337323, 52.743039742195982],
            [-1.297806952635132, 52.740508451446274],
            [-1.295431262262513, 52.738017694084242],
            [-1.294371019524291, 52.737085449557362],
            [-1.293418220900362, 52.737022244803093],
            [-1.287495020279142, 52.733953680057439],
            [-1.286673136602528, 52.734676012560982],
            [-1.279798761967847, 52.738064073807095],
            [-1.275305972640563, 52.739526319225789],
            [-1.272567666340025, 52.742173225818526],
            [-1.267836244299212, 52.745637540714526],
            [-1.266872864007768, 52.747432238448617],
            [-1.265933873758291, 52.747065045385213],
            [-1.267090889645693, 52.745739018179755],
            [-1.264762618933614, 52.744831033023829],
            [-1.263976246711004, 52.744553768242767],
            [-1.258518269133534, 52.744420898893495],
            [-1.251125292962427, 52.74085407839798],
            [-1.25119645252662, 52.738516289624286],
            [-1.249813110348319, 52.738254938470739],
            [-1.247148778389619, 52.736350210288379],
            [-1.249535820757499, 52.735254197474141],
            [-1.250789702000907, 52.736064009807755],
            [-1.25297183711295, 52.735252508636378],
            [-1.252658905731569, 52.734931400983172],
            [-1.255104313529181, 52.734308506011331],
            [-1.257907072136328, 52.732870626298002],
            [-1.26007485914555, 52.732364557243365],
            [-1.260431864152871, 52.731920890598403],
            [-1.263369627905881, 52.730888263334116],
            [-1.262559919630056, 52.729990548605663],
            [-1.266361396992649, 52.728570359971535],
            [-1.262476041238751, 52.724252750996428],
            [-1.266305664827595, 52.723419771811223],
            [-1.277526574598607, 52.716384914743806],
            [-1.280529016420072, 52.714841637461966],
            [-1.282722002239087, 52.714623880814187],
            [-1.285291649131342, 52.715270474002217],
            [-1.288699040788088, 52.712106773395789],
            [-1.289161577397733, 52.712883566672581],
            [-1.289585922712402, 52.711819920260922],
            [-1.291744406954194, 52.709423565000513],
            [-1.299242556503804, 52.707408539408448],
            [-1.308809306147979, 52.706409272142452],
            [-1.311013503458425, 52.705725387804186],
            [-1.318128893418222, 52.701962028875649],
            [-1.32230245972119, 52.700224849719788],
            [-1.323077998741498, 52.700313782451992],
            [-1.324645383972566, 52.699723098774982],
            [-1.329775664910532, 52.69896649266267],
            [-1.330474780840494, 52.698440043819993],
            [-1.331260302365022, 52.698649442566001],
            [-1.341926402918659, 52.695412548254531],
            [-1.344795682507026, 52.694806340712198],
            [-1.351105931426849, 52.694138930782366],
            [-1.342618120651998, 52.690240962814343],
            [-1.349964880708109, 52.689372590218298],
            [-1.348451057677049, 52.685019515520935],
            [-1.353965419681139, 52.684956189486726],
            [-1.358347671340549, 52.682800854587981],
            [-1.360721121272796, 52.680520375500507],
            [-1.360495659225189, 52.678942348920408],
            [-1.359649291360406, 52.67805318509388],
            [-1.360597000419657, 52.676458113776377],
            [-1.362519252274287, 52.678422846111438],
            [-1.363328193413585, 52.680059739584721],
            [-1.364521864259083, 52.679754196144216],
            [-1.365308514180558, 52.680180030487641],
            [-1.366472956288607, 52.679031067950255],
            [-1.368425012548077, 52.678544344532732],
            [-1.371317598968949, 52.678375423915043],
            [-1.373937964182264, 52.677174765157588],
            [-1.382000140302979, 52.675297733204872],
            [-1.383655473890105, 52.67569380862075],
            [-1.383710402304505, 52.67431236233066],
            [-1.391452802469694, 52.673988260911585],
            [-1.394467310008105, 52.673422951757559],
            [-1.396717203954637, 52.673605225339244],
            [-1.399004627135283, 52.672454460085042],
            [-1.401924881185962, 52.672061986824609],
            [-1.402205263128548, 52.673081944087841],
            [-1.406972943739056, 52.673106781681184],
            [-1.409351037385774, 52.673655340791953],
            [-1.41205845634544, 52.674795215160799],
            [-1.413877165946212, 52.67626415896126],
            [-1.418198116048139, 52.678213743987797],
            [-1.419855127180023, 52.680302994480243],
            [-1.421464413316711, 52.683329620819407],
            [-1.424211657974202, 52.683984859779301],
            [-1.429007419628071, 52.682628117032351],
            [-1.430383602639285, 52.681680021598694],
            [-1.429773809330889, 52.681366944232188],
            [-1.430401844564834, 52.680618417668796],
            [-1.430502145345739, 52.679502370033745],
            [-1.431350245447875, 52.678775571336061],
            [-1.433520977245033, 52.677677521323197],
            [-1.434538448879649, 52.67757270190841],
            [-1.43483583013331, 52.6769924812669],
            [-1.436311677542782, 52.676781053990631],
            [-1.439017062851746, 52.67491502000351],
            [-1.439582950431751, 52.672992984808602],
            [-1.438888370858909, 52.673299847624179],
            [-1.437845402879669, 52.672749228656109],
            [-1.438177538524994, 52.671759230171148],
            [-1.437819171936982, 52.670989804650013],
            [-1.437304669403982, 52.670864204418208],
            [-1.437408251546732, 52.670052918671765],
            [-1.440417789644133, 52.66737651666466],
            [-1.44107401447531, 52.667402086024879],
            [-1.441561190785113, 52.667000739999985],
            [-1.441740061297564, 52.666424438050072],
            [-1.444477109744008, 52.665724402206678],
            [-1.445750616021469, 52.66394049511549],
            [-1.45695188548833, 52.664825674953889],
            [-1.456509090726285, 52.666788812267484],
            [-1.456888175154634, 52.667103393384401],
            [-1.458903260289952, 52.667015515808508],
            [-1.459006797829789, 52.6679716014728],
            [-1.467175899675808, 52.668047244345637],
            [-1.467455828669484, 52.669105698951903],
            [-1.469424805790447, 52.671244201022162],
            [-1.473360317982458, 52.670850012176707],
            [-1.477025961181468, 52.677697552448919],
            [-1.478865468905085, 52.676989166378732],
            [-1.481854858995333, 52.674966984025843],
            [-1.485653675955025, 52.673133428421046],
            [-1.487675719741551, 52.67120398893087],
            [-1.494021394312352, 52.669030539808332],
            [-1.495445467806774, 52.669671284327713],
            [-1.496236205731353, 52.671508556591967],
            [-1.49580969580297, 52.671689236133631],
            [-1.496357360787869, 52.672290280972021],
            [-1.495940368222464, 52.672542919432111],
            [-1.496991661715327, 52.673333087599858],
            [-1.496487214286445, 52.673602437519321],
            [-1.496754272344553, 52.67391461650648],
            [-1.497993341783022, 52.674326204663423],
            [-1.497952937700341, 52.675012850620625],
            [-1.499064165912773, 52.675226111690094],
            [-1.50061790992229, 52.676582025497446],
            [-1.502293938401507, 52.676946862766577],
            [-1.502367685502402, 52.677616009300309],
            [-1.503041441559598, 52.677546916418891],
            [-1.504329778987579, 52.676232613151313],
            [-1.504788897310146, 52.676308247008542],
            [-1.506345830517944, 52.675299795377946],
            [-1.508211730653868, 52.674823898933482],
            [-1.517853766080331, 52.670865748744276],
            [-1.527549961167156, 52.667894105018469],
            [-1.536831282142912, 52.665718339115685],
            [-1.536901549004398, 52.66592537780086],
            [-1.538896577903524, 52.665755154474098],
            [-1.538610160133096, 52.667539404691745],
            [-1.543837494306772, 52.66668042906975],
            [-1.551777697938005, 52.667437974736593],
            [-1.552812873234826, 52.668445137827078],
            [-1.554797620211555, 52.669437880496837],
            [-1.560027082209747, 52.671303887603074],
            [-1.566046298298986, 52.674407743731727],
            [-1.570433744593398, 52.675826112419074],
            [-1.569164484261763, 52.676757339096667],
            [-1.566480739843944, 52.677584503689609],
            [-1.569112374241338, 52.679650042299009],
            [-1.569916965677437, 52.678751293488048],
            [-1.570315801528305, 52.679709245177563],
            [-1.576869695094272, 52.678342995387894],
            [-1.577614525408376, 52.67856140334176],
            [-1.579432624083267, 52.67960887295272],
            [-1.581436503630865, 52.682430640453433],
            [-1.584465894797515, 52.685086962664982],
            [-1.58965137710357, 52.68724366444782],
            [-1.588469723917915, 52.690668242452034],
            [-1.588620237699419, 52.692757969708332],
            [-1.587087944618814, 52.694270106483827],
            [-1.586291394506066, 52.694481286031994],
            [-1.587995052481224, 52.695517433466541],
            [-1.589753865942413, 52.696044930514688],
            [-1.590887828025551, 52.698047253696764],
            [-1.597003860596064, 52.700432472638383],
            [-1.597547212570677, 52.700404651067529],
            [-1.596130058516753, 52.701471405169471],
            [-1.596567997524366, 52.702124646768262],
            [-1.596296048576906, 52.703049658429705],
            [-1.595768227711818, 52.703154838432049],
            [-1.594961166947393, 52.702729571189202],
            [-1.594716220566647, 52.703291488465446],
            [-1.595241843117608, 52.703424532053354],
            [-1.594453956389311, 52.703964817970366],
            [-1.59396206306007, 52.703705131917935],
            [-1.594447719315015, 52.703519807717811],
            [-1.594102021932974, 52.703294782624312],
            [-1.592992517808687, 52.703725183064293],
            [-1.591560755466963, 52.703637557810687],
            [-1.591823129867332, 52.704385502809004],
            [-1.591214032898224, 52.704157764918179],
            [-1.591082142734854, 52.70465174257351],
            [-1.592170790882868, 52.705034853893721],
            [-1.591602754163089, 52.705957038446577],
            [-1.590425684796668, 52.706164238337294],
            [-1.589384172313495, 52.70675036098153],
            [-1.589560163562068, 52.706448916920927],
            [-1.588938129440967, 52.70633619050502],
            [-1.585777839731661, 52.707476776525304],
            [-1.58482280039326, 52.706894503528865],
            [-1.584734087263625, 52.707509086703737],
            [-1.583829261177679, 52.70787359399371],
            [-1.583982532085151, 52.708409915948856],
            [-1.583412166384804, 52.708309927293513],
            [-1.582830952285618, 52.709037846488997],
            [-1.582316745793061, 52.708635997065869],
            [-1.582308295344981, 52.70921130627238],
            [-1.581530688863179, 52.708493887371823],
            [-1.580831244492046, 52.708581315121464],
            [-1.581270480439677, 52.70939193648865],
            [-1.580517796692732, 52.709627504065672],
            [-1.580340661478756, 52.710040402516867],
            [-1.579287072186011, 52.709549431198887],
            [-1.578014131597011, 52.711394085866445],
            [-1.577125572605771, 52.711746014280109],
            [-1.57461245362935, 52.712022003412244],
            [-1.574206494536518, 52.711452400176015],
            [-1.573005534976635, 52.711353692764362],
            [-1.57273522741531, 52.710992233890671],
            [-1.571716357371749, 52.710892371029743],
            [-1.571654082331084, 52.710600880860419],
            [-1.569291037501176, 52.710370281378374],
            [-1.568854231639882, 52.709785265103207],
            [-1.566936272287483, 52.710053364105505],
            [-1.566098153314419, 52.710238186212742],
            [-1.566543480434365, 52.710407920861975],
            [-1.565930464575114, 52.710430850841007],
            [-1.565017211237083, 52.71117454810765],
            [-1.564339958018963, 52.710958109044533],
            [-1.563875926918593, 52.711180247156143],
            [-1.562234273738736, 52.713115970734158],
            [-1.560558593542564, 52.7137084885128],
            [-1.560458179859784, 52.714273566833917],
            [-1.558732741090353, 52.71553650376871],
            [-1.558463359595742, 52.716559424800728],
            [-1.557543248458465, 52.716345639084821],
            [-1.557326260475045, 52.716649579470243],
            [-1.556529116708356, 52.716645703842794],
            [-1.555579270237312, 52.71761123608016],
            [-1.555159627002326, 52.717387618800025],
            [-1.5547109250059, 52.717546850785212],
            [-1.554032372812084, 52.718324605399332],
            [-1.552558102262827, 52.718880010316383],
            [-1.552392287857307, 52.719314484718552],
            [-1.550086345094957, 52.720261364942729],
            [-1.549774158157314, 52.720961374573562],
            [-1.550181261667811, 52.721253284666247],
            [-1.549964978707225, 52.722128956608692],
            [-1.550442152506378, 52.723095355976376],
            [-1.549919703759556, 52.724226971469555],
            [-1.550247593906611, 52.725601834285051],
            [-1.548667390382965, 52.726350066660324],
            [-1.548503341843799, 52.726750381211765],
            [-1.547541797539066, 52.726804252595628],
            [-1.547774112171676, 52.727249227105688],
            [-1.54666462781102, 52.727289043024697],
            [-1.546807973624374, 52.727602430532912],
            [-1.545766441884342, 52.72765688100705],
            [-1.545323769146379, 52.728219735959073],
            [-1.54535621390968, 52.729799342194674],
            [-1.544751432671216, 52.730284262669301],
            [-1.545232537102067, 52.731012471085819],
            [-1.544643719647637, 52.73166915440757],
            [-1.545142335340196, 52.731996491906258],
            [-1.545340222321594, 52.732904305636865],
            [-1.550921473760775, 52.735391416595647],
            [-1.55331472695679, 52.738296910129421],
            [-1.55470787987622, 52.73937101307336],
            [-1.554515549497417, 52.739785612346495],
            [-1.555053399791256, 52.739912585925502],
            [-1.555611894964479, 52.73961172784896],
            [-1.556181737426602, 52.739797248712321],
            [-1.556738819884932, 52.740511309507809],
            [-1.557454385359838, 52.744885635841698],
            [-1.560764053700326, 52.748091942677583],
            [-1.560483127805849, 52.748626683971516],
            [-1.562499204649443, 52.750569596642748],
            [-1.560394867064255, 52.752099045425403],
            [-1.558148331355052, 52.75551484648561],
            [-1.557635472941627, 52.75714365098802],
            [-1.554432424562888, 52.758402801159917],
            [-1.554849160620672, 52.762003801347802],
            [-1.552704047279364, 52.7620802399666],
            [-1.553237755096226, 52.762783438563368],
            [-1.552561076141137, 52.763017316343351],
            [-1.552793650692032, 52.763315748451291],
            [-1.547448811654654, 52.764117141780247],
            [-1.544753885701853, 52.763044266492976],
            [-1.54185623153484, 52.760542998092703],
            [-1.53919820637013, 52.760079633199844],
            [-1.537327096111104, 52.760455312030452],
            [-1.534787150960335, 52.761988900217148],
            [-1.532945012347664, 52.762554304230832],
            [-1.529591006182153, 52.761076652856971],
            [-1.52693558243595, 52.762297676206224],
            [-1.525010195419562, 52.763966549126181],
            [-1.525355020488812, 52.765510544651974],
            [-1.522020910995171, 52.765873830774758],
            [-1.517697704995723, 52.766953974978939],
            [-1.51179125760143, 52.767382007259833],
            [-1.503950235842142, 52.767444789703362],
            [-1.502128955357956, 52.766645180234171],
            [-1.498769782281065, 52.764260482440392],
            [-1.496460171597305, 52.760333031298202],
            [-1.495764556801509, 52.759873405433233],
            [-1.494071203368682, 52.759071516916336],
            [-1.492920576106915, 52.759645538647526],
            [-1.49248185097733, 52.759399145743515],
            [-1.488259412748056, 52.76249502182003],
            [-1.487800493812333, 52.76232763333725],
            [-1.485911704797213, 52.763026052112181],
            [-1.485693953456751, 52.762766207694085],
            [-1.479934168857351, 52.763841440998149],
            [-1.478596888676455, 52.763637802520627],
            [-1.477147953445634, 52.765953448377431],
            [-1.473352282595614, 52.765472803798836],
            [-1.472555440290138, 52.766536333825321],
            [-1.471838844192082, 52.766231096142626],
            [-1.468764371329411, 52.766773833852575],
            [-1.468349554543003, 52.767368887553602],
            [-1.466460128630161, 52.767954623903456],
            [-1.463557435045312, 52.771027677994745],
            [-1.462163031803876, 52.771358478942361],
            [-1.460428104285185, 52.774654289526154],
            [-1.460422202051062, 52.776211263647504],
            [-1.455890083203211, 52.777076952741993],
            [-1.457103200536991, 52.77739534538177],
            [-1.45876317515673, 52.776826691256645],
            [-1.45950587626305, 52.777065602956824],
            [-1.457100116498295, 52.779073691455423],
            [-1.459377296317198, 52.779787070987602],
            [-1.459871036062783, 52.780141711025223],
            [-1.45952639672614, 52.780317237707713],
            [-1.461218209210422, 52.781379408618733],
            [-1.46105981187846, 52.78172029456551],
            [-1.458300744256775, 52.782792782131715],
            [-1.458850512430066, 52.783652897430784],
            [-1.457655306296116, 52.784383691640414],
            [-1.457995428322884, 52.7846944871836],
            [-1.458848417704887, 52.784419700803952],
            [-1.460883788556742, 52.786008433465348],
            [-1.461520593355861, 52.785830632624283],
            [-1.462786208075646, 52.786845897873974],
            [-1.457546899475844, 52.78857234870074],
            [-1.454880274620383, 52.788969161357635],
            [-1.452190000756562, 52.789946532852554],
            [-1.449967037518964, 52.789840066308457],
            [-1.450181045997702, 52.789684638631641],
            [-1.449775695213368, 52.789609046044227],
            [-1.448053162353979, 52.790268977442771],
            [-1.446818783420991, 52.791487615250091],
            [-1.445170656812035, 52.792351016303378],
            [-1.443793126553485, 52.792456940784703],
            [-1.443397318102501, 52.79291085824466],
            [-1.442509324083247, 52.792886914812712],
            [-1.441897718810686, 52.793515110854464],
            [-1.443121841137599, 52.796600692367115],
            [-1.442355370421619, 52.797158045226631],
            [-1.44197985449276, 52.797064587160662],
            [-1.441009627568425, 52.798706016606076],
            [-1.441696399110365, 52.799627983444289],
            [-1.44110599759822, 52.799982092313385],
            [-1.441000726108035, 52.801020792516979],
            [-1.441425567424403, 52.801550480305337],
            [-1.443707960370718, 52.80227586702442],
            [-1.443568714995285, 52.80272649142357],
            [-1.441386547658611, 52.802049218465385],
            [-1.436234219676616, 52.802576827208604],
            [-1.433551489250503, 52.802076823845539],
            [-1.430104517186174, 52.802063026394684],
            [-1.425997342120336, 52.800595019570125],
            [-1.424980868661683, 52.801304773520414],
            [-1.423970111025114, 52.801242340852049],
            [-1.41771196020428, 52.801597468391904],
            [-1.417072698800067, 52.803038059812316],
            [-1.41403870330726, 52.807057652537125],
            [-1.411783737691481, 52.809155459575365],
            [-1.410171531043667, 52.810113842268727],
            [-1.408760333484817, 52.812326351454594],
            [-1.404140866344744, 52.816626348783636],
            [-1.40461192983227, 52.817226518160325],
            [-1.405797395199631, 52.817342128651276],
            [-1.405212380183807, 52.818675949223241],
            [-1.403574121021726, 52.818465467141614],
            [-1.402325869759872, 52.820113272358704],
            [-1.399319699763632, 52.821820506179492],
            [-1.397800597260907, 52.823504649326431],
            [-1.396986014947116, 52.824888504891014],
            [-1.396064972247482, 52.825597596048702],
            [-1.395581206290378, 52.825594235991389],
            [-1.392085035023974, 52.829505728999344],
            [-1.387942831638966, 52.833079378858422],
            [-1.389518300421323, 52.833567529660492],
            [-1.391509161181676, 52.83354358586238],
            [-1.393129227691488, 52.834031018104781],
            [-1.394329325862366, 52.836754685118322],
            [-1.387583552457004, 52.838891070256821],
            [-1.388219418189865, 52.839385176429388],
            [-1.385499163489286, 52.840686304694081],
            [-1.385956090069175, 52.841130953404033],
            [-1.384935550531419, 52.842225094462535],
            [-1.384792732117472, 52.843086452045767],
            [-1.383134307482908, 52.84361272862116],
            [-1.382777332156043, 52.844705800416342],
            [-1.381539854125004, 52.845799683480323],
            [-1.38003382547374, 52.846457957479323],
            [-1.372421522752401, 52.847881479879504],
            [-1.369219157825385, 52.848977422722292],
            [-1.366030066559381, 52.850884206734548],
            [-1.364031083423991, 52.853848159522059],
            [-1.360725538478865, 52.855352345100812],
            [-1.358062560175472, 52.856069732723476],
            [-1.355148722656861, 52.855970348277211],
            [-1.353933440299156, 52.855000066471476],
            [-1.353119320156499, 52.85342066837066],
            [-1.352208154720129, 52.853077696185039],
            [-1.351179122718411, 52.853270748973543],
            [-1.350462461517602, 52.854523570461666],
            [-1.352101033483941, 52.8563834649729],
            [-1.351901429783789, 52.857620235426992],
            [-1.348266710199831, 52.859338027393889],
            [-1.347476122094448, 52.861249357218185],
            [-1.34341008194614, 52.862775850534412],
            [-1.343465463656096, 52.863240016474208],
            [-1.346421576803838, 52.865203448358528],
            [-1.345931255338729, 52.866792795035821],
            [-1.344868660601404, 52.867514192812258],
            [-1.34361075343733, 52.867787722777251],
            [-1.342429569634767, 52.867505212489363],
            [-1.340392955697884, 52.86505326548636],
            [-1.339254673137195, 52.864592968724409],
            [-1.337528397095131, 52.864899786513817],
            [-1.332801318909338, 52.867155775112728],
            [-1.331054786080946, 52.867127974663262],
            [-1.327912310071936, 52.865822038369402],
            [-1.325338784219323, 52.865962970377339],
            [-1.32434887046291, 52.866574925785294],
            [-1.322955694450206, 52.868374789928346],
            [-1.319284677348654, 52.869527845905523],
            [-1.319312907072995, 52.872850538632271],
            [-1.316165670371227, 52.873450046292326],
            [-1.310689356622206, 52.873163995978651],
            [-1.309337594882034, 52.873335936209926],
            [-1.307807438765096, 52.874074061446365],
            [-1.307464356932177, 52.87565242191814],
            [-1.306000642797273, 52.877061530094487],
            [-1.302335600578679, 52.875545106226902],
            [-1.298898995733909, 52.875357671105327],
            [-1.295941608108902, 52.874755862666092],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000135",
        LAD13CDO: "31UJ",
        LAD13NM: "Oadby and Wigston",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.070964680356686, 52.613886853322128],
            [-1.069154313030094, 52.613242441709268],
            [-1.069531778681335, 52.612644870123518],
            [-1.066969122534215, 52.612168922089324],
            [-1.062290170275671, 52.610102027300535],
            [-1.058977039654155, 52.609028416603088],
            [-1.061623160893455, 52.606000867492263],
            [-1.062487939556957, 52.604228576392146],
            [-1.059771395417155, 52.603931953000625],
            [-1.059904531624662, 52.603230883835984],
            [-1.058424472069443, 52.603048319027025],
            [-1.057663063231413, 52.603231057287921],
            [-1.056490652241341, 52.602887295220874],
            [-1.052451533844267, 52.601549698998554],
            [-1.044752808946955, 52.600059348901716],
            [-1.042181298488512, 52.598274728700382],
            [-1.041366592413556, 52.597991243905653],
            [-1.041739267561548, 52.597692191340251],
            [-1.049864334650359, 52.591325212560605],
            [-1.058381421556699, 52.585357258222828],
            [-1.065119149149889, 52.579883503337342],
            [-1.066493044893552, 52.579400781445862],
            [-1.067371873991653, 52.57978349100167],
            [-1.069070880949417, 52.578233474825375],
            [-1.069281631744621, 52.578386164931963],
            [-1.070948849015032, 52.577287175925917],
            [-1.070670960485479, 52.577169023486988],
            [-1.071076047980179, 52.576784726983696],
            [-1.073331804419144, 52.575972608307552],
            [-1.075431588914279, 52.574644095720096],
            [-1.074811113919159, 52.573976679061929],
            [-1.076279892424471, 52.572756496378574],
            [-1.073868088087101, 52.571530289291033],
            [-1.07688575315931, 52.570254773943184],
            [-1.076054997195355, 52.569344788750087],
            [-1.076772803956225, 52.569028539647647],
            [-1.07632292820717, 52.568038815225677],
            [-1.077274397603331, 52.567835862709444],
            [-1.076961548633871, 52.566292517043301],
            [-1.077708640692975, 52.566406218675063],
            [-1.078972619617237, 52.56600250884955],
            [-1.077985664266519, 52.56367807300574],
            [-1.079611788052602, 52.563095574530017],
            [-1.082903574023399, 52.556561008929719],
            [-1.083857246305028, 52.55638049642176],
            [-1.084044359798017, 52.556674123295025],
            [-1.084906995330936, 52.55618993051813],
            [-1.085513573347161, 52.556244061819868],
            [-1.085936811403936, 52.556603338761541],
            [-1.085007709281424, 52.556808331213475],
            [-1.085560205639425, 52.557335824614263],
            [-1.085019270086666, 52.557883640939366],
            [-1.085614833082727, 52.558255937331744],
            [-1.085869311893906, 52.5579351560188],
            [-1.086198511958669, 52.5592008105152],
            [-1.086835094015696, 52.558948602839983],
            [-1.087411399822737, 52.559255113578963],
            [-1.088653427133605, 52.559184665610069],
            [-1.088617511985959, 52.559636592856151],
            [-1.089818143611424, 52.559856195291687],
            [-1.091155184043442, 52.56089942914786],
            [-1.09196162962503, 52.560564889210951],
            [-1.092662936946158, 52.561035952772521],
            [-1.09225463145111, 52.561520987820217],
            [-1.094275346266345, 52.561176853628915],
            [-1.094001623905185, 52.561717763557191],
            [-1.094758638496062, 52.561849413942319],
            [-1.094403084891922, 52.562495782690434],
            [-1.094699508050942, 52.562930473808393],
            [-1.09433045030644, 52.563085878355601],
            [-1.093995235634295, 52.562672465869532],
            [-1.093786604463171, 52.563203983989645],
            [-1.094453668050373, 52.563334947913006],
            [-1.094384554841379, 52.563682337175656],
            [-1.095599086149313, 52.564524102988017],
            [-1.095383568216889, 52.564745412025047],
            [-1.094861817905233, 52.564505884614434],
            [-1.095483634334155, 52.565403353975249],
            [-1.095799609828693, 52.565534325374202],
            [-1.096462993197442, 52.564984697776211],
            [-1.097006491074847, 52.565530946881282],
            [-1.100831969975229, 52.565532181979535],
            [-1.101853412134693, 52.565506668835944],
            [-1.102966360152301, 52.564972999528827],
            [-1.103378519054302, 52.565163114833048],
            [-1.103200508572351, 52.565572615272345],
            [-1.103627131376475, 52.56563248255496],
            [-1.104126960024503, 52.565068089206818],
            [-1.105198621817637, 52.564966511136767],
            [-1.105551884284108, 52.565078858899163],
            [-1.10532724619462, 52.565605780800716],
            [-1.106012635558089, 52.565637027516736],
            [-1.106398186756217, 52.565249766247518],
            [-1.106181190202081, 52.56460263877203],
            [-1.107152322179958, 52.564292612742925],
            [-1.109946085584385, 52.565770937617124],
            [-1.11322087239428, 52.566012163557893],
            [-1.114016778379261, 52.565391509604765],
            [-1.114565097709893, 52.56600244219716],
            [-1.115278589511604, 52.565957429424564],
            [-1.115177447078473, 52.566364824617395],
            [-1.115605377784787, 52.566360828686285],
            [-1.115802170296788, 52.565892115523766],
            [-1.116605582823917, 52.566144439458213],
            [-1.11660573409886, 52.567018278615166],
            [-1.120413227603337, 52.566449673091597],
            [-1.120626820973962, 52.566761417606173],
            [-1.121462040250012, 52.566826950490125],
            [-1.12116475504242, 52.567159176699775],
            [-1.121616079860873, 52.567609332595453],
            [-1.122434371665345, 52.56800646762148],
            [-1.123012089621207, 52.567816560567834],
            [-1.12352308704118, 52.568383123129479],
            [-1.122454274541123, 52.568413866482665],
            [-1.123026533626385, 52.568793891013712],
            [-1.124381000967691, 52.568718506094577],
            [-1.124336538437275, 52.567394835235412],
            [-1.125146018912002, 52.566900967745319],
            [-1.124623190591564, 52.56626150482154],
            [-1.125067328319383, 52.56625849348881],
            [-1.12538058766166, 52.565492153720072],
            [-1.124815125144281, 52.565364812323409],
            [-1.124806644341271, 52.564975478319369],
            [-1.126098474443974, 52.563220260335427],
            [-1.126631261296109, 52.563208907202323],
            [-1.126641088303137, 52.561971042504211],
            [-1.130188260405125, 52.56222909131057],
            [-1.129406443559328, 52.563792119483736],
            [-1.133678266795464, 52.564118318249861],
            [-1.133531808086029, 52.565861325311651],
            [-1.134396784871825, 52.565764263954435],
            [-1.132367507488879, 52.569763491768271],
            [-1.133063943583628, 52.572755996820163],
            [-1.132855590945019, 52.573594145447537],
            [-1.134611852689018, 52.573578221453438],
            [-1.134998868021925, 52.574157312535277],
            [-1.136992474285885, 52.574003739951259],
            [-1.136594908697309, 52.575087743208591],
            [-1.140725343253992, 52.57636470181761],
            [-1.139729217448058, 52.57795051250222],
            [-1.140969677086741, 52.58153216798123],
            [-1.143601945848574, 52.581262646199193],
            [-1.143728921987465, 52.581562933247227],
            [-1.143227328786258, 52.582309976770091],
            [-1.143243729646981, 52.584572893861953],
            [-1.14359320181106, 52.58519573492157],
            [-1.144520993974323, 52.585529676710692],
            [-1.143602779892575, 52.586446321189491],
            [-1.143626976723244, 52.588007169758278],
            [-1.14086878673654, 52.587675244122643],
            [-1.141273848639853, 52.589985029023744],
            [-1.138215498604896, 52.589447690639247],
            [-1.136075927335194, 52.589406934934551],
            [-1.130860491713224, 52.589945049204346],
            [-1.129678062601308, 52.591512325705096],
            [-1.128031050725377, 52.590970700140545],
            [-1.127756408451448, 52.591346260856533],
            [-1.126614091115524, 52.591036677849786],
            [-1.126449987916108, 52.592905400209588],
            [-1.126806316694165, 52.595625720853349],
            [-1.126485249877174, 52.595661112262178],
            [-1.12193528852009, 52.595804581735756],
            [-1.120794139534136, 52.59550663878187],
            [-1.120179412562744, 52.597525736869386],
            [-1.117948853081743, 52.596997617071992],
            [-1.112895051681053, 52.596932913144094],
            [-1.109860112747891, 52.597346167385986],
            [-1.109448844514937, 52.596367654884446],
            [-1.108793516046407, 52.596359131011667],
            [-1.107451417879769, 52.595637014413967],
            [-1.103659400185539, 52.595893370494721],
            [-1.10479825370999, 52.596371262850703],
            [-1.103910774022732, 52.597972876319439],
            [-1.101930534204585, 52.599291108728387],
            [-1.10163580918652, 52.602223230441751],
            [-1.100214138251557, 52.60242730460746],
            [-1.099952036916139, 52.603472655741761],
            [-1.098828594994734, 52.603244758649822],
            [-1.096982965729398, 52.60625586105121],
            [-1.095376929229838, 52.607373659983125],
            [-1.093601728569582, 52.608669944338111],
            [-1.093390032841381, 52.610268554339676],
            [-1.094855478075824, 52.610247393914349],
            [-1.098312084874491, 52.611069368214125],
            [-1.09710994558792, 52.613725763018635],
            [-1.096642182184678, 52.616360775633446],
            [-1.093242030328946, 52.616147812590746],
            [-1.086233748517868, 52.615003482733293],
            [-1.083922898607512, 52.615044075277694],
            [-1.084017182854323, 52.615905152061195],
            [-1.082181254970597, 52.615693164590624],
            [-1.082432350076622, 52.614915671682454],
            [-1.077030222980945, 52.614321726738545],
            [-1.074338941059697, 52.613423303059584],
            [-1.071548707989624, 52.617488353656434],
            [-1.070498471598081, 52.618048291610009],
            [-1.066668179324073, 52.617015792502066],
            [-1.068009248874536, 52.615350600648476],
            [-1.070045803488048, 52.614733701158876],
            [-1.070964680356686, 52.613886853322128],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000136",
        LAD13CDO: "32UB",
        LAD13NM: "Boston",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-0.059252982234557, 52.863646522920575],
              [-0.065641299000714, 52.862872378371534],
              [-0.065985005509593, 52.863461477832246],
              [-0.068959967992786, 52.864145547187015],
              [-0.061523866304779, 52.866459069360559],
              [-0.036994738065337, 52.872390068061939],
              [-0.034960711826594, 52.870381146549271],
              [-0.040359118391803, 52.86842766002065],
              [-0.059252982234557, 52.863646522920575],
            ],
          ],
          [
            [
              [0.149703414408239, 53.057357843197039],
              [0.150395693803248, 53.056845439648747],
              [0.152173578626629, 53.057216074052157],
              [0.15236163779217, 53.056767624964912],
              [0.155923829757081, 53.05590292831323],
              [0.158237581502212, 53.053977386012455],
              [0.160322124429332, 53.053469321909311],
              [0.16278966346814, 53.053607892428211],
              [0.163387070818774, 53.054333379095837],
              [0.164557824440273, 53.053844554584842],
              [0.171446366900287, 53.049583193456058],
              [0.192887255334776, 53.037655359782313],
              [0.199574685280605, 53.032623701983177],
              [0.19911291862549, 53.032078400290082],
              [0.198648708019289, 53.032393575305647],
              [0.198165995683745, 53.032286516187931],
              [0.197086405146092, 53.030530756879564],
              [0.195425878252368, 53.030401387263957],
              [0.195191741410814, 53.030695218040137],
              [0.194299170363731, 53.030504900440832],
              [0.194259825644517, 53.029935600983627],
              [0.195561612792213, 53.029491695265158],
              [0.194255700495272, 53.028385639126348],
              [0.192275336534981, 53.028064327095393],
              [0.192250090924006, 53.027654805763753],
              [0.190482704307548, 53.027680183969842],
              [0.190006847024239, 53.027265475967845],
              [0.189030947019282, 53.027459667894988],
              [0.185617417172526, 53.027106179422447],
              [0.184933593506675, 53.025201880682346],
              [0.184199970429029, 53.024553626539891],
              [0.184272908385585, 53.023990353625578],
              [0.182943760828384, 53.023300882740671],
              [0.182649117613918, 53.022781221454515],
              [0.181741839714732, 53.022679192105144],
              [0.181224349318703, 53.022190139408501],
              [0.180518301428464, 53.022396843864449],
              [0.180373802836339, 53.021926570978039],
              [0.179700094467459, 53.02196140044019],
              [0.178560104905386, 53.020702884490923],
              [0.177149447675733, 53.020049008925724],
              [0.175155814753909, 53.019661125383706],
              [0.173146557430707, 53.019673590040142],
              [0.172296021297537, 53.019066960830628],
              [0.172534409435149, 53.018203073557572],
              [0.171628876787106, 53.01801462318106],
              [0.171567822791409, 53.017303657655404],
              [0.171019344062267, 53.01739639030135],
              [0.170293113408177, 53.01659147533698],
              [0.167275094811323, 53.01542286329142],
              [0.163111469703882, 53.013105306585587],
              [0.162739504266597, 53.012404488890382],
              [0.162376869434407, 53.012545948546304],
              [0.161561027985217, 53.012239808551527],
              [0.159684712124255, 53.010608790904691],
              [0.157701056887347, 53.010080179260534],
              [0.157711794383067, 53.009578291910856],
              [0.156131119797982, 53.009536839819184],
              [0.155552867165846, 53.008971008547064],
              [0.153498638972217, 53.008998345608404],
              [0.153042692645745, 53.008313405242717],
              [0.151416807514891, 53.00808120346823],
              [0.149583977542083, 53.00419432573495],
              [0.147089266612682, 53.002819745039226],
              [0.147093056775531, 53.002445655280191],
              [0.144989728934941, 53.001626792159392],
              [0.144594599355157, 53.000390480148674],
              [0.143569919892379, 53.000103270652005],
              [0.142278891908253, 52.998830955144761],
              [0.141675866710802, 52.998962298630261],
              [0.140735621981166, 52.998482936941635],
              [0.140084940764435, 52.997921932827325],
              [0.14024949383417, 52.997432450678218],
              [0.140443445492555, 52.99727622580896],
              [0.140391785455531, 52.996683757012633],
              [0.13795807220755, 52.995533561762429],
              [0.136466844751165, 52.995361224089478],
              [0.135546316136229, 52.994855843747459],
              [0.135973485285323, 52.994573946798191],
              [0.136130510986734, 52.993287226005975],
              [0.135594128190233, 52.993318441797179],
              [0.134077579524549, 52.99154300454942],
              [0.133299910847823, 52.991333989555116],
              [0.132593624729873, 52.99063818089256],
              [0.129136321420823, 52.989719251942262],
              [0.128993749604631, 52.989304628529354],
              [0.127781266010124, 52.988735637775726],
              [0.127697924733496, 52.987525158370985],
              [0.126933271110711, 52.986426670871957],
              [0.126136344706618, 52.986247624314203],
              [0.125988655333546, 52.985151579828937],
              [0.123623282548042, 52.98406730616972],
              [0.123287879001327, 52.983455624492954],
              [0.122195395631886, 52.982989627675558],
              [0.121944364649578, 52.981697624154428],
              [0.1212274940485, 52.981569263242648],
              [0.120718480664244, 52.980757478802602],
              [0.119769304880827, 52.980204388852918],
              [0.12000946737917, 52.979511403735337],
              [0.119379923859435, 52.979583769139161],
              [0.118586981880611, 52.978843570886426],
              [0.117845510193406, 52.978881060672769],
              [0.117826320636451, 52.978305986200567],
              [0.11552407059553, 52.977861479495189],
              [0.115205794425836, 52.975980857696854],
              [0.114368303102717, 52.974813458874024],
              [0.11346052988005, 52.974310831094634],
              [0.112639303792178, 52.972986689841079],
              [0.111172421917547, 52.972531743992555],
              [0.110765540364572, 52.970936796346429],
              [0.10949661159754, 52.970355139372373],
              [0.108676499561605, 52.970365198043858],
              [0.109159852151809, 52.969862122587791],
              [0.108510363145653, 52.969369259145694],
              [0.109455848839926, 52.968875964081114],
              [0.108376480808228, 52.9685462716944],
              [0.107624614839191, 52.968795169050239],
              [0.107612328244145, 52.967899895438478],
              [0.10717417855307, 52.967893279595522],
              [0.10749965043916, 52.967053154641043],
              [0.106394254013641, 52.966799429571459],
              [0.106710814333815, 52.966388329627513],
              [0.105217451440715, 52.966179232367963],
              [0.104884615120571, 52.965765254004964],
              [0.104486896389517, 52.965946720712367],
              [0.10385450360712, 52.965128958979065],
              [0.102901922535339, 52.964984882365634],
              [0.102176530099322, 52.965625279231119],
              [0.100006381543559, 52.964847276378592],
              [0.100477885822567, 52.964098994693614],
              [0.100101101560229, 52.964374480870092],
              [0.100223841373177, 52.964018970479515],
              [0.09978012096226, 52.964050187774362],
              [0.100033896761455, 52.963786766354211],
              [0.099570852506898, 52.963757186434265],
              [0.09895494414008, 52.962631620224258],
              [0.097601206438286, 52.962104382562579],
              [0.097362760597562, 52.960562161258672],
              [0.095649978853299, 52.960644525657969],
              [0.096062006652791, 52.960297403900903],
              [0.09562385806994, 52.960258378882692],
              [0.095785520517271, 52.959906682085055],
              [0.093427341788428, 52.959419585218463],
              [0.093865695134917, 52.958785198564655],
              [0.093065872859533, 52.95902585888669],
              [0.093727251374134, 52.958354277996257],
              [0.093519209207716, 52.957900308086295],
              [0.092158520285298, 52.956731184045644],
              [0.093191559451349, 52.956449558995232],
              [0.092146441993447, 52.956326807558412],
              [0.09185989332841, 52.955572123748169],
              [0.090945492261915, 52.95504157248763],
              [0.089230851639283, 52.953011923634357],
              [0.088776958793948, 52.953293225672169],
              [0.088181501094163, 52.952459448856331],
              [0.087361172730265, 52.952427109612884],
              [0.087587163218242, 52.952112055454144],
              [0.086922930007171, 52.951920529656839],
              [0.087065864221054, 52.951333612766547],
              [0.086659956780276, 52.951312869892931],
              [0.086501243902004, 52.950613470122789],
              [0.085542942767246, 52.950342586021961],
              [0.085335138423357, 52.949921864214431],
              [0.08436227538671, 52.94996680570361],
              [0.08440703637908, 52.948297315504682],
              [0.083777907309756, 52.947905559463877],
              [0.083325247392606, 52.946477655353497],
              [0.081384905763493, 52.945684501157054],
              [0.081964933819781, 52.945178942232559],
              [0.080730899532651, 52.944679987954117],
              [0.080208052859872, 52.942872088567718],
              [0.079719175522558, 52.942236900661975],
              [0.078983168728563, 52.942003431715811],
              [0.079305504508948, 52.941648945346003],
              [0.078273203052796, 52.940077421250834],
              [0.078356201081533, 52.939511342736743],
              [0.077685397120503, 52.93923986286844],
              [0.077944466988307, 52.938279602507428],
              [0.077456028210562, 52.93814878571181],
              [0.077921586444581, 52.937833156343402],
              [0.077546883787821, 52.937498056061145],
              [0.07782966695403, 52.93697074108438],
              [0.077053940611702, 52.936434063905047],
              [0.07830419997035, 52.93494848489771],
              [0.078283756482358, 52.93365235798143],
              [0.074095204765517, 52.932474926096354],
              [0.069391857558232, 52.931449271069589],
              [0.065241637785241, 52.931035984502174],
              [0.058187669709891, 52.930854510706681],
              [0.051373072260771, 52.931454284641042],
              [0.046436134949681, 52.932401649467415],
              [0.040937172320544, 52.933932077953443],
              [0.032558912239517, 52.937823078026497],
              [0.031463241532457, 52.939244381973026],
              [0.031048098434046, 52.939182250238787],
              [0.031617916253955, 52.938225776847609],
              [0.025366471583903, 52.939476137460218],
              [0.01249464738231, 52.950436124750276],
              [0.007207126576717, 52.954227946926174],
              [0.004954814391962, 52.95538891290979],
              [0.001354382520771, 52.959308425756078],
              [-0.000812776848418, 52.96035546033638],
              [-0.004958519247774, 52.963900040702313],
              [-0.008922712369435, 52.966937948983748],
              [-0.013020084215816, 52.968350630907118],
              [-0.013476336898026, 52.968078647310158],
              [-0.013167963093502, 52.967951219132978],
              [-0.014800821524717, 52.968098080957837],
              [-0.014755921130629, 52.967810527915141],
              [-0.018422951010056, 52.967730571128605],
              [-0.022653511359717, 52.966494686058816],
              [-0.023975316456777, 52.966637145447066],
              [-0.024966094696121, 52.967181367169871],
              [-0.025511899426165, 52.968699970429725],
              [-0.025424904188975, 52.970281782659704],
              [-0.022255529156907, 52.972243895941531],
              [-0.021722160843735, 52.973161058956201],
              [-0.022242119666646, 52.973842214469848],
              [-0.023949991201107, 52.974766091467359],
              [-0.024062028054834, 52.975509684127552],
              [-0.02340778432897, 52.976464403709478],
              [-0.024234859941086, 52.977238764987902],
              [-0.025445124586207, 52.977874736764207],
              [-0.029260447690717, 52.981558592453553],
              [-0.029706218233617, 52.981485066381126],
              [-0.026068285534473, 52.978026240557966],
              [-0.023898672279493, 52.976641590464467],
              [-0.024458202129262, 52.974987618343505],
              [-0.022209039119492, 52.973229399220052],
              [-0.02268065541415, 52.972359756418051],
              [-0.02578955561959, 52.970613302575835],
              [-0.026124487238231, 52.969538186797948],
              [-0.025632672605937, 52.966964978384574],
              [-0.025951546432571, 52.967026017410568],
              [-0.026087409728061, 52.966560758848004],
              [-0.023729552422876, 52.966116094927507],
              [-0.022091888485613, 52.966173364006359],
              [-0.01857570281062, 52.967353710919539],
              [-0.014947950532561, 52.967097177293233],
              [-0.012576507307433, 52.96730748618242],
              [-0.010225940100527, 52.966770970319601],
              [0.002639264757989, 52.956086079939496],
              [0.009581878678255, 52.951183102892422],
              [0.009199590414887, 52.950803864256905],
              [0.009891318972412, 52.950986366346768],
              [0.010590136966819, 52.950583446544911],
              [0.010179705048347, 52.950303585474693],
              [0.010670502899947, 52.950486784728028],
              [0.011303958400688, 52.950022930365286],
              [0.011717992916597, 52.949321835236169],
              [0.012046314056203, 52.949372018995071],
              [0.018941553453634, 52.943465007109985],
              [0.023039877793821, 52.939560729213227],
              [0.025958059377915, 52.938122827186909],
              [0.031796429701405, 52.936890287400097],
              [0.033952824117199, 52.935432843240676],
              [0.034998737927513, 52.93530164871656],
              [0.03721584029758, 52.934316919643656],
              [0.039785695132096, 52.933273046501263],
              [0.039526499754177, 52.932887294830401],
              [0.040020161022252, 52.933196195035876],
              [0.041470104609864, 52.932703766146098],
              [0.041136086637935, 52.932530587901596],
              [0.041933857162171, 52.932471924840137],
              [0.041880075603965, 52.932688628964478],
              [0.050307313126567, 52.930627543635723],
              [0.056454245197985, 52.930101426108465],
              [0.06176531365373, 52.929960820993934],
              [0.068809959977903, 52.930323859148274],
              [0.076114457212983, 52.931636770632558],
              [0.076289336591443, 52.931559989185899],
              [0.078368698075282, 52.932009137406283],
              [0.076515672515598, 52.931439151862079],
              [0.070963691120807, 52.930214406994374],
              [0.070680570663653, 52.928397791392854],
              [0.069602611342139, 52.928319474294923],
              [0.069832502048149, 52.927867723621496],
              [0.068657898929212, 52.927821650827454],
              [0.069115658118522, 52.926625984509108],
              [0.06715921307207, 52.926032481935948],
              [0.065871677280828, 52.924332228721433],
              [0.065281883517263, 52.924068262531982],
              [0.064749393761079, 52.92422676744507],
              [0.063304697018528, 52.922816027358493],
              [0.062623181064691, 52.922781110257304],
              [0.062336786898345, 52.922358117364055],
              [0.05737640688465, 52.919929387052491],
              [0.055519031825072, 52.919376236636303],
              [0.054549301028502, 52.919724775809328],
              [0.053759743598611, 52.919354519628371],
              [0.053209707678476, 52.918010004669028],
              [0.051664747440556, 52.917171778564636],
              [0.050324064521745, 52.917539277179102],
              [0.048618506988139, 52.919158286853808],
              [0.047988062540118, 52.920739859331384],
              [0.045871826604618, 52.922045832634993],
              [0.044751848155426, 52.925680332726429],
              [0.042633375578314, 52.927799054542596],
              [0.042762584492971, 52.927130612193992],
              [0.044554882326223, 52.92554166758206],
              [0.045047882567838, 52.922681287273015],
              [0.045993731415727, 52.921539346707995],
              [0.047975108042115, 52.92021321891238],
              [0.048144847075398, 52.919081940673664],
              [0.049746676533827, 52.91753395744631],
              [0.049334185295075, 52.916484649054304],
              [0.046998811653598, 52.916640000136582],
              [0.043215958849329, 52.918149958078331],
              [0.042678894644545, 52.919254279474416],
              [0.043274950691024, 52.921077260964864],
              [0.041693111990724, 52.92239374530098],
              [0.040607714495897, 52.923133460173325],
              [0.037704335917525, 52.923556417938144],
              [0.037162434540252, 52.92411585279978],
              [0.036912588027947, 52.924373776400948],
              [0.035652802347061, 52.924113964116103],
              [0.034479917138039, 52.924031288110427],
              [0.03275956893039, 52.924006161481046],
              [0.032240479659702, 52.924612033240805],
              [0.03311969645429, 52.925595869262288],
              [0.032370213311314, 52.925825372537204],
              [0.032411609431126, 52.926645525986459],
              [0.031849792951123, 52.925736274835842],
              [0.032884412267383, 52.92546413439252],
              [0.032119582134562, 52.924762449906602],
              [0.031594434682286, 52.924730973269725],
              [0.033208477740804, 52.923617268226209],
              [0.033039487303094, 52.923820655783658],
              [0.033563323011283, 52.923919579584414],
              [0.035122686944684, 52.92400463060865],
              [0.036024955312104, 52.924053832885086],
              [0.0370669736609, 52.924110647768785],
              [0.037480097311032, 52.923406520987783],
              [0.038411595073618, 52.923126210347867],
              [0.037009966638957, 52.922455262018588],
              [0.036732172063875, 52.921956538760931],
              [0.035009636707629, 52.921388169796181],
              [0.033471817307292, 52.921490926231904],
              [0.030971790543736, 52.92166316324176],
              [0.029605791549363, 52.921400601696739],
              [0.033382205789838, 52.921292863839973],
              [0.035857381479911, 52.921289130254038],
              [0.037528735172077, 52.922034585158237],
              [0.038213002175793, 52.92257218638261],
              [0.039318621957819, 52.922831927062106],
              [0.041051926734932, 52.922330139772455],
              [0.042273598323538, 52.920472314570539],
              [0.042227742027915, 52.91861289781059],
              [0.043803971985233, 52.917180519055329],
              [0.047235815012949, 52.916051510179329],
              [0.052978040028361, 52.916225726499221],
              [0.053161338383635, 52.915855731146749],
              [0.047004357021747, 52.912279339575754],
              [0.038368995499061, 52.905845643015297],
              [0.037410095340435, 52.905756003700496],
              [0.035681070059991, 52.90690680954156],
              [0.037365446598905, 52.908036849419346],
              [0.038207669499285, 52.909282916828793],
              [0.038161681579125, 52.909855523951194],
              [0.037308710034209, 52.91066225105903],
              [0.037085512892013, 52.912434578273007],
              [0.035824608666861, 52.913329206749928],
              [0.036651524245721, 52.912092276611617],
              [0.036440189987926, 52.910916301383622],
              [0.037745803902303, 52.909360071088173],
              [0.036570870796232, 52.907868861091863],
              [0.034983025707948, 52.907052734993329],
              [0.036431051105376, 52.905617252370384],
              [0.036062964585168, 52.903793025428719],
              [0.034121406909347, 52.903517008199351],
              [0.031936264293107, 52.904183771365233],
              [0.030263948267119, 52.904275305544928],
              [0.030479860969772, 52.904440642417264],
              [0.027290799905307, 52.903911613395763],
              [0.025527048637563, 52.904449687927659],
              [0.023654269981695, 52.904212785558556],
              [0.021857298694828, 52.904389939728233],
              [0.020831402102964, 52.904041472436013],
              [0.023766559242734, 52.903942046350075],
              [0.025180105363159, 52.904216447948315],
              [0.027912334415269, 52.903592616986394],
              [0.030856001620024, 52.903962192071262],
              [0.034975237664529, 52.903142747840334],
              [0.03643595097444, 52.90328044568939],
              [0.033325068162105, 52.901531078878513],
              [0.026676490565576, 52.898778436559631],
              [0.01198293050412, 52.893753348681955],
              [0.011002302696365, 52.893412123086797],
              [0.009492966625419, 52.894495891997742],
              [0.007254997355295, 52.894773793481882],
              [0.005750747869561, 52.894296621379063],
              [0.004995513910618, 52.894392988763506],
              [0.005571204376342, 52.894110846336702],
              [0.007161088751106, 52.894511050489172],
              [0.009274720791789, 52.894245144274713],
              [0.010006481548371, 52.893963036738143],
              [0.009734829237938, 52.892943578896492],
              [0.008281500515265, 52.892216531362457],
              [-0.028673289212416, 52.875905163371115],
              [-0.039113243358891, 52.872266835281586],
              [-0.043657348923593, 52.871369888976922],
              [-0.044473670904984, 52.871687224460345],
              [-0.044169138659748, 52.871289311617964],
              [-0.045331782854205, 52.870910165702313],
              [-0.065109268672878, 52.866182197361695],
              [-0.069896486159177, 52.864366663441345],
              [-0.080485468296129, 52.866935258078485],
              [-0.0820621494788, 52.865344203968569],
              [-0.084803655771223, 52.864523549497534],
              [-0.087298281207283, 52.865868317210577],
              [-0.088891127987471, 52.865863397856906],
              [-0.090002251872299, 52.8663065382719],
              [-0.090204717653793, 52.866736854180942],
              [-0.088984427618713, 52.867289928650877],
              [-0.089484541963147, 52.867776283097044],
              [-0.091000270110459, 52.867735929323658],
              [-0.092848436441155, 52.868144138525885],
              [-0.093282442652752, 52.867598180061698],
              [-0.091881622492194, 52.867356303044829],
              [-0.091922868991743, 52.866991942869625],
              [-0.092835742213749, 52.866468964735056],
              [-0.094566271936696, 52.866645095691815],
              [-0.096428868269992, 52.869234626401486],
              [-0.096261313691155, 52.869803748935659],
              [-0.097431139943953, 52.870267539039531],
              [-0.096906321835309, 52.871191464467906],
              [-0.09825011296024, 52.871959222624838],
              [-0.098834060009071, 52.871891253734709],
              [-0.099019894938095, 52.871243302433996],
              [-0.099941712677405, 52.870583754523018],
              [-0.100515151157559, 52.870789825618459],
              [-0.100964310788022, 52.87176620714007],
              [-0.100369231518162, 52.87164700189328],
              [-0.099752509499916, 52.872770860673143],
              [-0.09887524887372, 52.872852119748103],
              [-0.099100999583965, 52.87346620225226],
              [-0.100048619809892, 52.873883252667738],
              [-0.100583770084253, 52.874660518449829],
              [-0.101028010498691, 52.874185722591626],
              [-0.101461702543559, 52.87429245422404],
              [-0.103232252522637, 52.876624163883911],
              [-0.103802454436874, 52.876906587380532],
              [-0.107474645512425, 52.876765576877425],
              [-0.110024488485361, 52.877931820372304],
              [-0.112401144449035, 52.877956137191561],
              [-0.114130777581241, 52.878918648102307],
              [-0.114343450871424, 52.879565757152065],
              [-0.115067712618976, 52.879279664641587],
              [-0.117401749013254, 52.880255315369851],
              [-0.117839305926329, 52.88103005367828],
              [-0.116609604151413, 52.881524822886405],
              [-0.118593297074007, 52.882357334903411],
              [-0.120004413192407, 52.882616137017777],
              [-0.121586691699029, 52.882147588782018],
              [-0.122043521961329, 52.882478477416548],
              [-0.122496441142974, 52.882384044608351],
              [-0.123732535805841, 52.881843463449542],
              [-0.124287411060306, 52.880936081807334],
              [-0.126097559550621, 52.880591547784313],
              [-0.125491227495036, 52.879729664680376],
              [-0.126833159095263, 52.87997200978954],
              [-0.127601943618693, 52.879789036991077],
              [-0.128190392288746, 52.880135463120652],
              [-0.126995982705614, 52.880573355185689],
              [-0.129201320823873, 52.881749929990377],
              [-0.129178733397798, 52.882102007338837],
              [-0.131152976845891, 52.883505963275347],
              [-0.132203578345745, 52.883786816586834],
              [-0.132275824928892, 52.884491919597032],
              [-0.133577847927495, 52.885598458164644],
              [-0.135187273537042, 52.884357860584792],
              [-0.135573758019745, 52.884425964200311],
              [-0.135680877600813, 52.885116328107856],
              [-0.135200378391928, 52.885437841040073],
              [-0.136503542233612, 52.885757684754779],
              [-0.137774585143609, 52.885578929702994],
              [-0.138215620409848, 52.884651718679272],
              [-0.13936868913343, 52.884797456892848],
              [-0.140982865226487, 52.88444872614],
              [-0.14194756017528, 52.884933133933202],
              [-0.142661557928651, 52.884884964729423],
              [-0.143473052089848, 52.886311877677862],
              [-0.145263047414515, 52.887033022925941],
              [-0.144155627563826, 52.887521894234617],
              [-0.145392637993489, 52.888140892050174],
              [-0.145237834461719, 52.888703084898459],
              [-0.143930909438143, 52.889163664644848],
              [-0.144750531893066, 52.88956756275541],
              [-0.144744797406725, 52.890746142107332],
              [-0.145738194946175, 52.891258836663184],
              [-0.146979396267843, 52.891190100899706],
              [-0.147368161451982, 52.891903726473402],
              [-0.151644694686678, 52.894215310860147],
              [-0.151621703187405, 52.89534956600319],
              [-0.151038322763912, 52.896062430302116],
              [-0.149520810574692, 52.896262662668335],
              [-0.148751421919154, 52.897222556307405],
              [-0.147786456834409, 52.897363948315977],
              [-0.148944339175185, 52.897928627490586],
              [-0.148509242360148, 52.898753475693596],
              [-0.146772979876897, 52.898398240861013],
              [-0.146595234050505, 52.898626525671617],
              [-0.146903392021275, 52.899383846680905],
              [-0.14749269467472, 52.899510816136186],
              [-0.148593964703475, 52.90063742617545],
              [-0.149092489491546, 52.900278379726487],
              [-0.150209120881007, 52.900277793936233],
              [-0.150914060786783, 52.901809977604984],
              [-0.149915820987065, 52.902695289963319],
              [-0.150688619485708, 52.904170991407895],
              [-0.150615296260401, 52.905927508448727],
              [-0.154280053913576, 52.907903997569491],
              [-0.15518572842096, 52.909512993833346],
              [-0.156864043288824, 52.910929887328209],
              [-0.156365282490234, 52.911713317200388],
              [-0.156595441580915, 52.912072016335188],
              [-0.159421454562167, 52.9122057426423],
              [-0.15941115509027, 52.911606811775485],
              [-0.160223622598019, 52.911385643193803],
              [-0.161323050339247, 52.912640665348114],
              [-0.166104400347826, 52.912045678720219],
              [-0.16737862704145, 52.91229999669946],
              [-0.168138300310459, 52.914245580355548],
              [-0.169730523174398, 52.914332157318867],
              [-0.171703059975703, 52.916194803701025],
              [-0.171539303161056, 52.917539964751271],
              [-0.173407671308469, 52.918065883218411],
              [-0.176266153350814, 52.918393001122638],
              [-0.177879550733146, 52.919006637477494],
              [-0.178466603637751, 52.918167833605018],
              [-0.178216528459056, 52.916790246513656],
              [-0.179905204503979, 52.915359665488118],
              [-0.18164721166548, 52.91489006538302],
              [-0.185053101689502, 52.914642764788859],
              [-0.186522495522392, 52.915605613252545],
              [-0.186058533758202, 52.916422061208479],
              [-0.187935104641638, 52.916647592398242],
              [-0.188484948258921, 52.915732652268098],
              [-0.189705583969758, 52.915744066901304],
              [-0.189846853277972, 52.915352435075818],
              [-0.19081671406302, 52.915278203254488],
              [-0.190677971698309, 52.914895791528402],
              [-0.192090598479538, 52.914617914140557],
              [-0.192298865238862, 52.915045435622929],
              [-0.191676249922929, 52.915924222234587],
              [-0.192488085618379, 52.917001952453454],
              [-0.198425140093404, 52.918234828381735],
              [-0.201979606850647, 52.920652278024306],
              [-0.208765505005175, 52.922529505480391],
              [-0.213916643145058, 52.923658043398241],
              [-0.213874076422318, 52.923246538974432],
              [-0.214207018633233, 52.923253342005978],
              [-0.21938887517402, 52.924182514416856],
              [-0.229981429407885, 52.924969227327892],
              [-0.239350835039213, 52.92624842906676],
              [-0.245623519665108, 52.927927127640224],
              [-0.246714075467521, 52.928809901965828],
              [-0.224008036418188, 52.956050817459335],
              [-0.215438288947683, 52.960839306343757],
              [-0.201204784876402, 52.977437730062498],
              [-0.1996863237049, 52.981180826326693],
              [-0.19893106633557, 52.981754661629715],
              [-0.199167344627844, 52.982950369447209],
              [-0.198389297718422, 52.983784578008951],
              [-0.198898697519357, 52.984287668335611],
              [-0.198803077245929, 52.985583535173831],
              [-0.196651421252853, 52.9889924426043],
              [-0.18989707324084, 52.996386895390643],
              [-0.193727914552204, 52.997749663196792],
              [-0.199663632252069, 53.003471267341354],
              [-0.201428092000029, 53.003976258524396],
              [-0.20209976482317, 53.005924741262184],
              [-0.203966181593151, 53.007782487716085],
              [-0.203867351251354, 53.009301265915745],
              [-0.206926597552788, 53.011120335624419],
              [-0.207098844753562, 53.012287184183826],
              [-0.209539716605749, 53.014612005749683],
              [-0.212994210820927, 53.016188730595012],
              [-0.214164874008639, 53.017334604455428],
              [-0.214600124944494, 53.01914190477337],
              [-0.213564343768039, 53.019962451334052],
              [-0.213394888101687, 53.020707901776568],
              [-0.215091505614003, 53.021540710167251],
              [-0.216486867641407, 53.024003418226656],
              [-0.218783132514509, 53.02512476243519],
              [-0.219192414500716, 53.025686493542509],
              [-0.220296391862914, 53.025818095008013],
              [-0.220963728247892, 53.026487069748214],
              [-0.223271755041589, 53.027509607694853],
              [-0.224784454776542, 53.029019193748191],
              [-0.224942053698309, 53.030120161777546],
              [-0.224044832545269, 53.032865893314899],
              [-0.222932334946772, 53.033517256499522],
              [-0.223132857529369, 53.034806765041459],
              [-0.219547776800847, 53.039034337319642],
              [-0.220933639095358, 53.03939220989858],
              [-0.218088070362778, 53.043469870679615],
              [-0.218043079962862, 53.044705362782949],
              [-0.220287744716829, 53.047939511610444],
              [-0.217960644808175, 53.051622158491192],
              [-0.21769602826191, 53.052683543384838],
              [-0.214062514301317, 53.062361933617531],
              [-0.211360501686118, 53.067496147301249],
              [-0.210764737876762, 53.070130330379037],
              [-0.206627449885282, 53.070552619125436],
              [-0.204296299616329, 53.070219881875389],
              [-0.198855342244058, 53.070331832210371],
              [-0.19612428327616, 53.069533475851685],
              [-0.192945861368303, 53.066017686669426],
              [-0.189081698987401, 53.062744898200307],
              [-0.164241136474487, 53.050058199313092],
              [-0.138049319768448, 53.030018746695902],
              [-0.118428524037172, 53.011993331024307],
              [-0.114747218788693, 53.010234021778508],
              [-0.112355187176032, 53.009951530615119],
              [-0.063929099350706, 53.008201004176698],
              [-0.063200210986768, 53.008933552136853],
              [-0.055436329995143, 53.006732705316011],
              [-0.051510426318546, 53.004877508181487],
              [-0.032618438689227, 53.004792064051941],
              [-0.023919293953498, 53.005660143032117],
              [-0.021806928354864, 53.005309444869646],
              [-0.019177420261568, 53.004274897515806],
              [-0.014996193649455, 53.004709537557083],
              [-0.010760103398444, 53.001991890564334],
              [-0.00706804408306, 53.001095725516976],
              [-0.006462810996013, 53.002748850168906],
              [-0.004828277638348, 53.004840529439612],
              [0.000496666235415, 53.008827417123818],
              [0.009676816885441, 53.012187056164692],
              [0.017694314490132, 53.015999080032849],
              [0.020474321529368, 53.016149668763525],
              [0.020488158724154, 53.016924430183259],
              [0.023141026136192, 53.017415166990219],
              [0.035700301569051, 53.026756123779528],
              [0.044653772616678, 53.030164630782181],
              [0.04522211550759, 53.030041574873174],
              [0.046044804972426, 53.032172604056669],
              [0.046581829983938, 53.035418894614395],
              [0.045980553768294, 53.035446322590126],
              [0.045489611468442, 53.038056673461533],
              [0.044206985139383, 53.04077143669582],
              [0.03621199649793, 53.069390069846769],
              [0.040643241605564, 53.069883251752721],
              [0.042016095405155, 53.070284053943837],
              [0.048065372002015, 53.070677252075015],
              [0.082750529926211, 53.070887843770443],
              [0.082976588024226, 53.071643600467574],
              [0.08748060246787, 53.071618600518512],
              [0.093057567102142, 53.072786506764494],
              [0.094536803943177, 53.074198089068659],
              [0.096350788378393, 53.074416081386715],
              [0.118330071590822, 53.080731164026453],
              [0.124472738053862, 53.083369213885049],
              [0.129228136395452, 53.084933113861275],
              [0.131586724540519, 53.085219944734028],
              [0.137077400737948, 53.08666516730046],
              [0.139737149839819, 53.085193226819726],
              [0.13974550762064, 53.084699482858163],
              [0.141798271745821, 53.082284486629888],
              [0.144241971927214, 53.069684824327247],
              [0.143709176808808, 53.069206225819791],
              [0.14480999330413, 53.064263915960453],
              [0.146581610361804, 53.060751614634171],
              [0.149703414408239, 53.057357843197039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000137",
        LAD13CDO: "32UC",
        LAD13NM: "East Lindsey",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [0.19264675731742, 53.447561052583225],
              [0.19248530944018, 53.447496588550266],
              [0.192179011274417, 53.447982298071537],
              [0.19264675731742, 53.447561052583225],
            ],
          ],
          [
            [
              [0.191559839580068, 53.449031152492637],
              [0.192345173607702, 53.448313690647041],
              [0.191613304659594, 53.448694829849842],
              [0.191559839580068, 53.449031152492637],
            ],
          ],
          [
            [
              [0.104887334110242, 53.486541942596702],
              [0.103514674081692, 53.486491497031786],
              [0.105268775337776, 53.487852285702303],
              [0.105202511040359, 53.488888224179313],
              [0.105632328791939, 53.48791870024202],
              [0.104887334110242, 53.486541942596702],
            ],
          ],
          [
            [
              [0.040103670491319, 53.526406450189462],
              [0.04538050566248, 53.524075061165405],
              [0.045724169498326, 53.524319103608356],
              [0.048734272078233, 53.52351328708933],
              [0.048896965252252, 53.523320808525469],
              [0.047987656191499, 53.523422695314345],
              [0.049933918724988, 53.522831951594675],
              [0.050111253959995, 53.522536733577986],
              [0.050923113970304, 53.52241672431127],
              [0.049691731026883, 53.523283809267767],
              [0.052505856151799, 53.522265495312112],
              [0.053123673952582, 53.521907869063824],
              [0.052195486420288, 53.521868069543082],
              [0.051652039342157, 53.521111445310261],
              [0.055176237589407, 53.519994572257616],
              [0.056351515742351, 53.51912569692405],
              [0.055032154189163, 53.521131595672479],
              [0.056582451073643, 53.521246076918338],
              [0.063634308030893, 53.518843758242767],
              [0.06322778488333, 53.518114488299545],
              [0.062399523405588, 53.518732915604936],
              [0.061401996290352, 53.518226900373882],
              [0.062398379631123, 53.518492001200826],
              [0.062560588931055, 53.518073860579193],
              [0.063928936918519, 53.517674455539243],
              [0.063569541796945, 53.518131962885001],
              [0.064250144556548, 53.518634443726746],
              [0.066604067442224, 53.517936593646077],
              [0.069930491454101, 53.517541007497613],
              [0.070815013366768, 53.517058201586934],
              [0.069354601023133, 53.515195574361499],
              [0.070575836646634, 53.513762080251482],
              [0.072940316174174, 53.513009978372963],
              [0.076448074377076, 53.512476210160209],
              [0.076569939356575, 53.511822309831146],
              [0.077810742364373, 53.511227176188761],
              [0.079874601985595, 53.511178692735562],
              [0.081827287885973, 53.512000555815582],
              [0.082496254156399, 53.512663160911814],
              [0.082360440961442, 53.514291839850067],
              [0.080921425706851, 53.51558271017992],
              [0.081999443703516, 53.516065585637705],
              [0.079464416968457, 53.51663114229644],
              [0.080284693179995, 53.516963887068755],
              [0.085005111632564, 53.51633144292088],
              [0.085206288239077, 53.515851457025562],
              [0.086096351332767, 53.515823339759613],
              [0.086484501511888, 53.515649348380741],
              [0.086141818903299, 53.515459345220521],
              [0.086892284423637, 53.515475911939731],
              [0.087787023216524, 53.515021568370358],
              [0.087609308158303, 53.514628207881891],
              [0.088242440542588, 53.514866174418898],
              [0.089553444709832, 53.513093782736959],
              [0.089259597651154, 53.512700656745693],
              [0.088976010366348, 53.512791918848151],
              [0.087420115678547, 53.514001304269883],
              [0.085888200923176, 53.514411028014258],
              [0.08494824469433, 53.514313253571736],
              [0.084749228260787, 53.513608303315507],
              [0.086284283075019, 53.512526077671012],
              [0.087414322810861, 53.512502751022843],
              [0.089694391322056, 53.511758980769606],
              [0.089680101135988, 53.510579724906663],
              [0.08869720447018, 53.510373950657169],
              [0.088185450596801, 53.510854874950574],
              [0.085736810965794, 53.511574588133904],
              [0.083768108927641, 53.511749173561171],
              [0.082452857841289, 53.510640240496414],
              [0.082642139071031, 53.510378925819573],
              [0.081639464544264, 53.510322674463701],
              [0.081405795002946, 53.509832287283224],
              [0.080898599676486, 53.509976869856828],
              [0.080769240953633, 53.509306660881414],
              [0.079988418760338, 53.509345424603467],
              [0.080224289420393, 53.508834276582171],
              [0.079535260172027, 53.508868742654762],
              [0.080064521981548, 53.508589828915163],
              [0.079155347752576, 53.508563395157019],
              [0.078285903130807, 53.507283939877262],
              [0.079615435888813, 53.503298864252756],
              [0.081074940898157, 53.501017833891396],
              [0.081702035089542, 53.50082980643181],
              [0.081837576733261, 53.499749527998773],
              [0.08492328237013, 53.496969022865891],
              [0.08911211372454, 53.494279732315825],
              [0.089530155547482, 53.494288605457847],
              [0.089559394824895, 53.493994116037072],
              [0.091499944926028, 53.494826818685539],
              [0.092092135368646, 53.493993852736153],
              [0.093133691443419, 53.493989997410814],
              [0.090512551549084, 53.492714327609157],
              [0.090313858538524, 53.491997832746826],
              [0.08985885281357, 53.491717155882817],
              [0.087435248695353, 53.491751038697778],
              [0.082629846134312, 53.49407283319006],
              [0.08152207820556, 53.494787072553777],
              [0.081478980199449, 53.495415335204157],
              [0.081392456069435, 53.494756965832309],
              [0.082557660216091, 53.493984189783724],
              [0.087284485581313, 53.491631704613248],
              [0.087697126452513, 53.491624496025423],
              [0.089870652011042, 53.491586502287788],
              [0.090290497070123, 53.491913590946687],
              [0.090721025621743, 53.492248995936208],
              [0.092079437326563, 53.493307226666822],
              [0.093076741808309, 53.49362959036727],
              [0.093812874046622, 53.493516004292083],
              [0.094410574361069, 53.49423373330621],
              [0.095763185291529, 53.494698184674554],
              [0.096678578612841, 53.494550870428334],
              [0.095273508572171, 53.494062176745238],
              [0.094867157011851, 53.492486132634099],
              [0.095478057012006, 53.493746631181267],
              [0.096885012881677, 53.493784881826869],
              [0.09739578873043, 53.492923649556118],
              [0.096549383514881, 53.491290604324881],
              [0.097457841506568, 53.491340289926775],
              [0.097476951187069, 53.491788564167429],
              [0.098849175184012, 53.492101601183251],
              [0.099591267213361, 53.492750241401374],
              [0.09984008508342, 53.49250762960002],
              [0.101775808643835, 53.492752293610117],
              [0.100820150421489, 53.49244994739469],
              [0.100204291064862, 53.49170200217128],
              [0.100317433963883, 53.492130643062779],
              [0.099411119138318, 53.492521460034993],
              [0.099064639696789, 53.491916214917836],
              [0.097692997870992, 53.49164542400262],
              [0.097841642986022, 53.491624079841458],
              [0.098755792997632, 53.491492808427481],
              [0.098309541873016, 53.491186888330233],
              [0.093650888046349, 53.490503540550058],
              [0.093806874970222, 53.490303922662406],
              [0.095019728951037, 53.490443588258913],
              [0.098370758093537, 53.491083325345834],
              [0.098966041831259, 53.491507095828133],
              [0.097905635465822, 53.49163416171875],
              [0.099170599999594, 53.491833441954093],
              [0.099476365041215, 53.492407936506304],
              [0.100234138625543, 53.492031417104442],
              [0.099268649781254, 53.491437949712655],
              [0.099444264931446, 53.491025811280316],
              [0.097519329511969, 53.490142618326985],
              [0.096832943975516, 53.489686236383825],
              [0.095745615185858, 53.489695463168523],
              [0.094418290221534, 53.489006714036734],
              [0.093482753487552, 53.489324279091505],
              [0.09257475032624, 53.489160380505055],
              [0.093378657518215, 53.489261373586963],
              [0.094474551997218, 53.488894249735743],
              [0.096109125781368, 53.489669308650491],
              [0.096731227063523, 53.48843752826658],
              [0.097251266533855, 53.488253992340873],
              [0.096557686318751, 53.489503212096103],
              [0.096965128796443, 53.489685114219284],
              [0.099663668459912, 53.490889800614994],
              [0.099575201033248, 53.491387612908355],
              [0.100405614708748, 53.491505174718114],
              [0.101142305994736, 53.492440687101755],
              [0.101891311606566, 53.492191971809333],
              [0.101843492770747, 53.491743303898872],
              [0.102676029637283, 53.491202731308029],
              [0.099311304037798, 53.489676923456429],
              [0.102811754090922, 53.491142805756866],
              [0.103813626675051, 53.489668760630714],
              [0.102470072536484, 53.488837441751045],
              [0.101253548669501, 53.488562164718601],
              [0.102439087743719, 53.488698639057091],
              [0.103461789295128, 53.489291073772492],
              [0.102154254478976, 53.487450418447033],
              [0.103977166401999, 53.48962272825613],
              [0.104394394011942, 53.489098445200774],
              [0.103157776876994, 53.4879910499292],
              [0.103337406851332, 53.487782911769671],
              [0.104534302589887, 53.489184084971939],
              [0.102842959706793, 53.491529733494069],
              [0.104361893640972, 53.489842506133918],
              [0.105097035603661, 53.488249980863245],
              [0.104912473604151, 53.48771921490188],
              [0.103006676954693, 53.486338615622891],
              [0.100949482601423, 53.486161737868706],
              [0.100972664680042, 53.485532916167706],
              [0.100134572800542, 53.485898267057934],
              [0.099171926316885, 53.485878323281128],
              [0.096540865922371, 53.486992550896936],
              [0.09914853123217, 53.485770852081821],
              [0.100141209339597, 53.485819036776448],
              [0.101116651173682, 53.485426997134503],
              [0.101498334351852, 53.485464336656882],
              [0.101069245583136, 53.486084114423988],
              [0.102939024907301, 53.486250803425463],
              [0.100800344728758, 53.484577591996349],
              [0.103277526396017, 53.486268219872009],
              [0.101775127127135, 53.484813073507276],
              [0.102177014942114, 53.484314240040696],
              [0.103959173000258, 53.483625688544514],
              [0.104274629872985, 53.483452016626458],
              [0.105336579115674, 53.48249563164633],
              [0.108589171156355, 53.482892279906302],
              [0.109838747368591, 53.482742560161007],
              [0.110709477939878, 53.482278569984707],
              [0.111488999973606, 53.482315141821445],
              [0.112615593563592, 53.482724060177731],
              [0.111256048439988, 53.482358815282019],
              [0.110433274054125, 53.482513598310632],
              [0.109697412118265, 53.482870918699426],
              [0.108766260619255, 53.483089636084813],
              [0.105682875160606, 53.482640564539878],
              [0.104382672854084, 53.483533722596],
              [0.103325521862277, 53.484070172083662],
              [0.102692663849195, 53.48428718631191],
              [0.102110090303059, 53.484637266315374],
              [0.102141918095012, 53.485128469774253],
              [0.103561633991982, 53.486282996903768],
              [0.104417423003549, 53.486153752190354],
              [0.104985460002574, 53.486392774794041],
              [0.105849789839403, 53.487774620921392],
              [0.105679785904095, 53.48857234991349],
              [0.106305584053486, 53.48809023413007],
              [0.107861633300224, 53.487794886595623],
              [0.107448469009791, 53.487244782275489],
              [0.107999337708292, 53.486650702259638],
              [0.107592033219598, 53.485458594872895],
              [0.108149432026382, 53.486577031612882],
              [0.107636643185395, 53.48727023184901],
              [0.108382595966883, 53.487479129543992],
              [0.109246495110248, 53.486480354903151],
              [0.111107415379188, 53.485890996640101],
              [0.112814898172213, 53.484520376041381],
              [0.113621329274946, 53.484187859827983],
              [0.114902705497301, 53.484192156402948],
              [0.114904112090377, 53.483443814020141],
              [0.115512221595492, 53.484506811869622],
              [0.116749831975818, 53.483934691728962],
              [0.118784180072145, 53.483805135637105],
              [0.1171115793135, 53.483965142362557],
              [0.116310696465009, 53.484347929342732],
              [0.116314910144699, 53.484826134129889],
              [0.117602734898096, 53.485019981234579],
              [0.118461714045796, 53.484774604949756],
              [0.118243809204626, 53.485059862045958],
              [0.116241623959605, 53.484958689290792],
              [0.116124362242837, 53.484450121950196],
              [0.115605824448881, 53.484631017536813],
              [0.114957674460519, 53.484297267926259],
              [0.113651364232818, 53.484306898315594],
              [0.112076649483148, 53.485163641656342],
              [0.111148822165198, 53.486117717207811],
              [0.109573328529667, 53.486566285034272],
              [0.108375261452296, 53.487847857049957],
              [0.109139050571577, 53.487781435113661],
              [0.108533977474323, 53.48800867797754],
              [0.1089932517659, 53.4883332098822],
              [0.108229591558214, 53.488071586153204],
              [0.105982772458271, 53.48911990698133],
              [0.105408073701264, 53.490390459108035],
              [0.106560402174192, 53.489794777602185],
              [0.107583882827469, 53.489854045424849],
              [0.109664939936214, 53.489080125307623],
              [0.110526131067203, 53.488967823168544],
              [0.109898435811244, 53.489228835923647],
              [0.107702928662879, 53.489913977961102],
              [0.106249040997074, 53.490021425028431],
              [0.104194814046601, 53.491735185588084],
              [0.110023339234132, 53.489315633326953],
              [0.11236873209783, 53.488311343237619],
              [0.112436449245266, 53.487247503978558],
              [0.113301693210509, 53.48714977910619],
              [0.112498779759072, 53.487379457037164],
              [0.112558729372519, 53.488221678277533],
              [0.114499913812578, 53.487669506318383],
              [0.121061789366915, 53.483717975786142],
              [0.121507635632209, 53.483802659539109],
              [0.114766229539059, 53.487689965770109],
              [0.110123208824688, 53.489383993272504],
              [0.110178286453075, 53.489430668670117],
              [0.112289208203214, 53.48874337977945],
              [0.113054223472862, 53.488758622276464],
              [0.110543474355242, 53.489681338613849],
              [0.110051381532295, 53.49012335688046],
              [0.111056835414785, 53.490031878027679],
              [0.115851898176025, 53.489725907636291],
              [0.112870625739246, 53.490129278694717],
              [0.112601373568704, 53.490565569028774],
              [0.113273899351535, 53.490842262375359],
              [0.112530182990596, 53.491013640601892],
              [0.11270472345989, 53.491128326346271],
              [0.114108517187291, 53.490892228249656],
              [0.112871644678128, 53.491241348525648],
              [0.113019031112076, 53.491355614938129],
              [0.114038945062478, 53.491463437955183],
              [0.114612460339786, 53.491082893676378],
              [0.114551435817553, 53.491431894448517],
              [0.113695950430817, 53.491720332274873],
              [0.114741321667547, 53.492369808248711],
              [0.117263113298364, 53.492665880893753],
              [0.117025893548385, 53.492957770425882],
              [0.117855566726308, 53.493210077703736],
              [0.1178514047159, 53.494669261746068],
              [0.115873768666248, 53.497038151012319],
              [0.110138920884396, 53.501692184802458],
              [0.108382959857545, 53.502648266598037],
              [0.10817142891454, 53.504037384483354],
              [0.108951897340745, 53.504267248032555],
              [0.111359181567845, 53.503945148320177],
              [0.118657124703454, 53.501938839729817],
              [0.121518782305442, 53.500604271671584],
              [0.121638255802031, 53.499977331510742],
              [0.120989220725987, 53.499601374889856],
              [0.121279001121916, 53.499093678980046],
              [0.120913713185143, 53.498843941851881],
              [0.121805002457343, 53.498123287776217],
              [0.122831105757906, 53.498200360473405],
              [0.123565504971639, 53.499439648225199],
              [0.124523012673512, 53.49925990657762],
              [0.125999783339904, 53.499122164536587],
              [0.125483420471803, 53.499499948454719],
              [0.125812567030043, 53.49959927921693],
              [0.128276363674951, 53.498727426653844],
              [0.130619002459929, 53.498327871156306],
              [0.133938159654415, 53.497048672503013],
              [0.134123406631998, 53.496564387069334],
              [0.135685537516305, 53.496027631434231],
              [0.136447332542146, 53.495406274134091],
              [0.135985125796092, 53.494999188118427],
              [0.132146822865774, 53.496504374322733],
              [0.132831688232842, 53.495640778041476],
              [0.131994496856227, 53.495301502737597],
              [0.133446831317716, 53.494470057415271],
              [0.13313769087191, 53.493717699901872],
              [0.130289494427093, 53.493800875006244],
              [0.130020421021353, 53.490899130974213],
              [0.129705265454446, 53.490716852673906],
              [0.128422669426031, 53.490993230169636],
              [0.127672781273408, 53.490648774149662],
              [0.127529533003125, 53.489654308662445],
              [0.128790646439907, 53.487414856741459],
              [0.129164892106768, 53.48712229911478],
              [0.130566249483697, 53.487112602965738],
              [0.130371051666616, 53.486796930044754],
              [0.128743932936917, 53.486632639732441],
              [0.127885640990165, 53.486048279455659],
              [0.128580190517841, 53.484566903969281],
              [0.132870046732442, 53.483488890048243],
              [0.1374163259494, 53.483168506543876],
              [0.139848289276276, 53.482256516862549],
              [0.13868899526358, 53.482341104796532],
              [0.139500495335927, 53.482054171315362],
              [0.142464558763201, 53.481743935650272],
              [0.143841425489378, 53.48193949944244],
              [0.14509581033762, 53.484605973370925],
              [0.147700422669869, 53.486575700313622],
              [0.148138617191425, 53.487759932268276],
              [0.145884358761338, 53.491447786378131],
              [0.144148337736372, 53.49268544214506],
              [0.146450803198182, 53.493410074589583],
              [0.146977198265138, 53.493892384754297],
              [0.147618673137008, 53.493877261354555],
              [0.147480768032088, 53.494101799109899],
              [0.154301785885805, 53.490158155714091],
              [0.155192010857519, 53.489371635674345],
              [0.153776840760131, 53.49018110440656],
              [0.154452860298429, 53.489302254927509],
              [0.157750453927403, 53.487002373054999],
              [0.161497442790214, 53.483468890521159],
              [0.163576215480161, 53.482112388688535],
              [0.162774124994769, 53.482136800102481],
              [0.165483782029177, 53.479675620846081],
              [0.168145598576717, 53.47681427689848],
              [0.168034995959324, 53.476536685312624],
              [0.166886155078073, 53.476704069996643],
              [0.165009181999555, 53.478376132681376],
              [0.164298486877792, 53.478211001313966],
              [0.162120282167986, 53.479712263451837],
              [0.158200743496043, 53.481294398663863],
              [0.157038319663382, 53.481434961602559],
              [0.156363181336679, 53.480842102298432],
              [0.157363569970398, 53.478904615514821],
              [0.158361043643543, 53.478277057782996],
              [0.162278256864156, 53.473691295341048],
              [0.166759400017858, 53.471022691858494],
              [0.167865823673944, 53.470735606450837],
              [0.170162737821502, 53.470894386890173],
              [0.171398311257798, 53.471573180474337],
              [0.171093074453795, 53.472441799329125],
              [0.172093321416922, 53.471904875285688],
              [0.172839671679775, 53.471058798374465],
              [0.172070878080183, 53.4711114374605],
              [0.171883482333523, 53.470805577272294],
              [0.172963694496393, 53.47056656956488],
              [0.173908033967502, 53.469320410257652],
              [0.174010599614511, 53.469794132370254],
              [0.174545337814824, 53.469702587703011],
              [0.174781893327744, 53.468612248991711],
              [0.17862236589044, 53.464814873674591],
              [0.176021263245344, 53.466272869147915],
              [0.173833126509146, 53.46705890618064],
              [0.172917864355748, 53.467015322952136],
              [0.175046640019492, 53.465745804366762],
              [0.177682289820894, 53.464992043338221],
              [0.178084123420969, 53.464542392111916],
              [0.177715115397595, 53.464514956593703],
              [0.178535232388818, 53.46393720679589],
              [0.177609335376709, 53.463831820590514],
              [0.179761982800745, 53.463151546764713],
              [0.179967147884321, 53.462686597678712],
              [0.179508875768524, 53.462210380212611],
              [0.180189400274031, 53.462584546563562],
              [0.180709630951989, 53.462417719148462],
              [0.18148071010733, 53.461619680745422],
              [0.180951969634999, 53.461238255331367],
              [0.18192427530764, 53.461221397713267],
              [0.183584112599768, 53.459366922360957],
              [0.183125556887675, 53.459472400140768],
              [0.1836100360416, 53.458903444750511],
              [0.183226747710028, 53.458949109618729],
              [0.185028127150819, 53.457008414732627],
              [0.185261239184255, 53.456209401453712],
              [0.184490144391575, 53.456123713392095],
              [0.181719264512022, 53.457869940229003],
              [0.17976410910598, 53.458547547507401],
              [0.179532417289112, 53.458406131622979],
              [0.180268661759338, 53.456752853530993],
              [0.182536503826462, 53.454743444852717],
              [0.182638322214368, 53.454291166878498],
              [0.181739241797089, 53.453882348802452],
              [0.182057317423308, 53.452477636984298],
              [0.184055720227237, 53.451416208210262],
              [0.185635119813541, 53.452110142664367],
              [0.185674861962693, 53.451769579181892],
              [0.186360279903703, 53.451684212153509],
              [0.185329649258841, 53.451235574394772],
              [0.185853814466943, 53.450591261921403],
              [0.187458145073859, 53.45127212951698],
              [0.187133829775386, 53.450060771355695],
              [0.18867544673079, 53.450576448968121],
              [0.189434090909131, 53.450392626004088],
              [0.190281834709757, 53.450681856607979],
              [0.191423573578091, 53.449368095345761],
              [0.191322176407465, 53.44912451814956],
              [0.190044198546743, 53.449254045747203],
              [0.190785588776404, 53.448443001132617],
              [0.189961360156683, 53.447614818877241],
              [0.187962582731704, 53.448721308580637],
              [0.185186462932976, 53.449092191051726],
              [0.183666213907595, 53.447699516558892],
              [0.184642767354496, 53.444367802687985],
              [0.185134666249535, 53.443389639995956],
              [0.187886044219092, 53.442046448203676],
              [0.188924679874374, 53.442010333891623],
              [0.192168481324129, 53.443245451871512],
              [0.193848501145936, 53.442827115457447],
              [0.19701568612407, 53.440531179176482],
              [0.19707456034547, 53.440825880559039],
              [0.198309951197189, 53.439497738034476],
              [0.197611823952103, 53.439653534758499],
              [0.197853123124599, 53.438523494235355],
              [0.19835771943153, 53.438148296413146],
              [0.198538298887612, 53.438846224477885],
              [0.198802819608106, 53.438691214369214],
              [0.200649367094398, 53.436110214398056],
              [0.199899347031277, 53.436222029292068],
              [0.198493596399107, 53.437368123947614],
              [0.197843458777474, 53.436002759349556],
              [0.193318015303314, 53.439457366671569],
              [0.19085915995756, 53.44018756986155],
              [0.188821309921358, 53.440175490668544],
              [0.187105622079289, 53.439517360950227],
              [0.187807828981427, 53.437682145033854],
              [0.189300251718315, 53.436664051616511],
              [0.194777476290281, 53.429972636998144],
              [0.195766027642802, 53.429525619810136],
              [0.195560214826093, 53.430030170781293],
              [0.196326806675095, 53.429628585808189],
              [0.197124680253363, 53.429804504518955],
              [0.198398517696597, 53.429489769162529],
              [0.197791666081555, 53.430186010707004],
              [0.197842297375316, 53.430817105683445],
              [0.196458756540971, 53.430961236537868],
              [0.19608679366653, 53.431542565850123],
              [0.200242341381293, 53.431030032829639],
              [0.200572027915408, 53.430680523173194],
              [0.204366621946327, 53.429944341572643],
              [0.210062692711925, 53.425734012190425],
              [0.209416266129764, 53.425633594732432],
              [0.209775034800179, 53.425034484721245],
              [0.209330218975113, 53.4248161567333],
              [0.206701146214906, 53.42537723002873],
              [0.206938207700398, 53.424665300335782],
              [0.208944040779991, 53.423812774850845],
              [0.210632626027497, 53.423490232677331],
              [0.212513959478484, 53.421257217943008],
              [0.210992659731476, 53.421466107372638],
              [0.210431299465256, 53.421031473593118],
              [0.209294993641276, 53.421379757674863],
              [0.20876366783797, 53.421262822595473],
              [0.209373534378313, 53.420398344457304],
              [0.208992572102853, 53.41998823616666],
              [0.208069389971782, 53.419802128541782],
              [0.207486875369867, 53.420149143074418],
              [0.207290550619361, 53.419903737125431],
              [0.208198476693985, 53.419476084704108],
              [0.207588778680002, 53.419414536788864],
              [0.208390383997007, 53.419142585421554],
              [0.208582663587739, 53.418613086893352],
              [0.207644710553294, 53.418809343893784],
              [0.206554470728337, 53.419813952733996],
              [0.207012829723747, 53.418871372534568],
              [0.20625017420984, 53.419314312570634],
              [0.206900507714633, 53.418562378985385],
              [0.205644775381388, 53.41913220324394],
              [0.20589380427684, 53.418593569537322],
              [0.205498565615676, 53.418604467418426],
              [0.20612081767736, 53.418182107441439],
              [0.205080187465513, 53.418488126917907],
              [0.204825991365293, 53.418258170573644],
              [0.205460382932979, 53.417721411075341],
              [0.210598068655237, 53.41760302003383],
              [0.210593029508444, 53.417302831828465],
              [0.192084869299762, 53.418281503892871],
              [0.189449682570503, 53.417958552406468],
              [0.191070572545065, 53.418018721715747],
              [0.18953890863137, 53.417293422602732],
              [0.19129374784784, 53.417910336888227],
              [0.190212588570699, 53.417254094841873],
              [0.192400389240156, 53.418037464064049],
              [0.195695123330166, 53.417812393050241],
              [0.193760250122007, 53.416038185856713],
              [0.195391154466021, 53.41737475421521],
              [0.196504789506333, 53.41775434606749],
              [0.204468824386567, 53.417346840550557],
              [0.203566471452602, 53.416630776391784],
              [0.203025159879543, 53.416755843764747],
              [0.203328287201972, 53.416448170827657],
              [0.204912318488931, 53.417308982502632],
              [0.208909208530595, 53.417102941806903],
              [0.208386259632953, 53.416964926042574],
              [0.207877790472777, 53.416830729102898],
              [0.207313987041881, 53.417044338448683],
              [0.206409610619943, 53.41643531928348],
              [0.205581958534161, 53.416552204046837],
              [0.204723307600493, 53.41598278467135],
              [0.202589678047725, 53.41607520993746],
              [0.201386414254792, 53.415737784392164],
              [0.197596017116576, 53.416940409481612],
              [0.201356838003924, 53.415631343372475],
              [0.202392586247582, 53.415930503387649],
              [0.204646731403277, 53.415811581988756],
              [0.205759954532354, 53.416414957876789],
              [0.206495599773474, 53.416323147485024],
              [0.2073370916409, 53.416880284930166],
              [0.20780217731175, 53.416678390506362],
              [0.20853234857996, 53.416963437453497],
              [0.210580358196218, 53.416942548808777],
              [0.21031047404912, 53.416120422746843],
              [0.208979134524382, 53.415664972095591],
              [0.207794530436466, 53.416182258482323],
              [0.207173702759882, 53.415469106242178],
              [0.204478662570892, 53.415268065684401],
              [0.203003244661946, 53.415633340610313],
              [0.201356134115176, 53.415122496834506],
              [0.203046756844068, 53.415543532219104],
              [0.204498163400436, 53.415179599134014],
              [0.205921351446912, 53.415259399198874],
              [0.205029704799146, 53.414924343659131],
              [0.204355035522571, 53.415059971666153],
              [0.203412496185375, 53.414147757525171],
              [0.203990584759568, 53.413977955898496],
              [0.203770514352731, 53.414407266958207],
              [0.204544482446098, 53.414940496748791],
              [0.205099064513561, 53.414810681889143],
              [0.206193947153395, 53.415321790663711],
              [0.207387727005478, 53.415417499904969],
              [0.207750397603702, 53.416027539612166],
              [0.20850927432576, 53.415571173366672],
              [0.20817554248021, 53.415142207986371],
              [0.208462189773523, 53.414517462954834],
              [0.207396527727638, 53.413436766147335],
              [0.20715367463727, 53.413190477920828],
              [0.206727861072279, 53.412758632096342],
              [0.20373341370441, 53.412089312740136],
              [0.206685084691545, 53.412629960089554],
              [0.207256069366108, 53.413208842173745],
              [0.207630423269657, 53.413275979372003],
              [0.206945496140497, 53.411789033242862],
              [0.207326740003369, 53.411276720768193],
              [0.206767370138439, 53.411051511594074],
              [0.206355738046141, 53.409959585774402],
              [0.207500326804673, 53.411258227355006],
              [0.207552156649568, 53.412810821491369],
              [0.208846883232064, 53.414768372557987],
              [0.20853977343385, 53.415259539148813],
              [0.210990629016024, 53.416003539456135],
              [0.212098495653812, 53.415893112970899],
              [0.20890356494563, 53.41342324923999],
              [0.209034884406524, 53.412792385830386],
              [0.208514338697461, 53.412561971040169],
              [0.208999500644378, 53.412312949559194],
              [0.208995381907891, 53.411681894876956],
              [0.209334362224512, 53.411690006931764],
              [0.208123475227904, 53.410478018202724],
              [0.209763326400961, 53.411024864329669],
              [0.208819734615446, 53.410499301867212],
              [0.209401044280703, 53.410625200252703],
              [0.209492321845414, 53.410412234886785],
              [0.209721671894757, 53.410685795522319],
              [0.20978226143811, 53.410084110496953],
              [0.210049919455453, 53.410516092788406],
              [0.210241960146112, 53.410186183362953],
              [0.210795220849747, 53.41032248377411],
              [0.211695184074655, 53.409832915343969],
              [0.211625323452033, 53.410313401925521],
              [0.211991419327823, 53.409800455436418],
              [0.212362120166683, 53.410217037730916],
              [0.212410823064796, 53.409763914174412],
              [0.212914869005213, 53.410111494007154],
              [0.213074328309233, 53.409792073268306],
              [0.214703262322055, 53.409664768149845],
              [0.21533071922568, 53.410331008626351],
              [0.214628221603543, 53.41236150604847],
              [0.215124398008167, 53.413077832406678],
              [0.216296307967622, 53.413419298056724],
              [0.21775115649068, 53.41335631935334],
              [0.219165483601129, 53.412168467320804],
              [0.221377916943915, 53.411398192476156],
              [0.222351805185086, 53.411549088549116],
              [0.228359582303381, 53.405840510556843],
              [0.228978845798627, 53.404304158388172],
              [0.227637621776516, 53.40417455768592],
              [0.227997190905667, 53.403282281731798],
              [0.229152121558896, 53.401863594076033],
              [0.229846466005674, 53.402195861643939],
              [0.230362368818607, 53.4013887644441],
              [0.231087891265806, 53.401511862196386],
              [0.231700251181776, 53.400277704229985],
              [0.23107702175307, 53.399809260489427],
              [0.231754848477255, 53.399883797579136],
              [0.232840644176911, 53.398125623260718],
              [0.232654805317336, 53.397334336666034],
              [0.23005807047229, 53.40069049199893],
              [0.229125682330782, 53.401415460991622],
              [0.228438393647486, 53.401419302944674],
              [0.226513523066521, 53.400741374638336],
              [0.226121826006087, 53.399693193708714],
              [0.227042480060702, 53.396640821802151],
              [0.228170386815225, 53.395916713673614],
              [0.22871693752466, 53.396272424210977],
              [0.228664380050393, 53.39446091578575],
              [0.231276382836113, 53.390596534897362],
              [0.232017984633514, 53.391001627914612],
              [0.232396085366043, 53.392191197033284],
              [0.232683147348113, 53.391899928640164],
              [0.233690060768743, 53.392086073563831],
              [0.23380657878568, 53.391841148442886],
              [0.23461489667636, 53.392198226202169],
              [0.238126940740446, 53.384806022994027],
              [0.238603045399756, 53.379120454561019],
              [0.239582302796067, 53.377581776997154],
              [0.240021523743077, 53.3774422712671],
              [0.240555395437562, 53.377788275701292],
              [0.241603917232959, 53.376911784124573],
              [0.241837498164988, 53.376292441288072],
              [0.240972722343059, 53.376287106166529],
              [0.240671600019632, 53.376021245156231],
              [0.242431413798526, 53.373746839604756],
              [0.242665612462741, 53.372767859887119],
              [0.243669572362495, 53.372190672875497],
              [0.246434285129108, 53.369450473403248],
              [0.248386904013601, 53.366480966212961],
              [0.248326486845048, 53.36553359662306],
              [0.249065676142732, 53.365470218045182],
              [0.251736631627145, 53.361251799503904],
              [0.255005226548511, 53.357404121620455],
              [0.260253936775817, 53.34915839177291],
              [0.263301389137573, 53.345166309434454],
              [0.263822568805762, 53.344426379555017],
              [0.265012111441316, 53.343910213304873],
              [0.264610728936537, 53.343791964353791],
              [0.266391086058199, 53.341726265150029],
              [0.267488291189429, 53.33930221028902],
              [0.268987447101594, 53.337943984513096],
              [0.270739452600688, 53.334759414884012],
              [0.271840402073368, 53.333656872806607],
              [0.273782313544739, 53.332524806019265],
              [0.281294784897253, 53.319293590111876],
              [0.283302831484064, 53.315283061944371],
              [0.285129627917882, 53.312944647502086],
              [0.28924616583282, 53.305653322746153],
              [0.294133099924789, 53.299908711923692],
              [0.299981966370039, 53.294440230416377],
              [0.308238380723487, 53.285870681204855],
              [0.315683671413563, 53.277312683907603],
              [0.315679937615083, 53.276688792743322],
              [0.31712290733717, 53.274941705737049],
              [0.316801326133139, 53.27506933249866],
              [0.316851050257515, 53.274851686935015],
              [0.318474603322358, 53.273339329466872],
              [0.318006770851886, 53.273424849903414],
              [0.319491130330298, 53.271614889928948],
              [0.322178957226653, 53.266444065415229],
              [0.32677520266317, 53.257439084150597],
              [0.329658684110148, 53.253279316339963],
              [0.337182856149697, 53.240324448135773],
              [0.33874175195117, 53.235161843473584],
              [0.340146663762286, 53.233253357303539],
              [0.338816726140233, 53.232469387102064],
              [0.340381798184406, 53.221275505287629],
              [0.342881025266832, 53.215795846453538],
              [0.347397830660202, 53.208331637018723],
              [0.34996164895157, 53.203568912174219],
              [0.349963983757705, 53.202571765626246],
              [0.351121156383167, 53.201471809704167],
              [0.351316338210769, 53.199457570866109],
              [0.352397139167447, 53.19691065879114],
              [0.354399444940632, 53.193342125986746],
              [0.355625539684943, 53.192031268643468],
              [0.354968950306956, 53.191823973451577],
              [0.354983513684944, 53.18867593198113],
              [0.355561646227819, 53.18592403658657],
              [0.354473297978665, 53.183927074692647],
              [0.354090075706208, 53.17848878831191],
              [0.352562353933093, 53.171849435104981],
              [0.352141082760556, 53.169975035301874],
              [0.352554410630266, 53.168969764083734],
              [0.351892823134003, 53.168035178482228],
              [0.352280615651839, 53.16757526903227],
              [0.351874820462876, 53.16754192622988],
              [0.351723585353902, 53.167074682165328],
              [0.352016298226461, 53.16618957705429],
              [0.351680639879472, 53.166180023134181],
              [0.351116179716787, 53.162888761963664],
              [0.351307531263809, 53.158070279434078],
              [0.350836596613169, 53.157828728891744],
              [0.350904220135007, 53.160461769676949],
              [0.35041226964376, 53.160246706314986],
              [0.350692063483043, 53.159180239392946],
              [0.350151735219207, 53.158818677089606],
              [0.350334090716679, 53.1578305580014],
              [0.349300262526152, 53.154129568563015],
              [0.349530243315793, 53.151877267649695],
              [0.349006270538824, 53.149210971179372],
              [0.349537406119454, 53.144531433606524],
              [0.349402095624992, 53.137396097786677],
              [0.348367525019379, 53.135369246620712],
              [0.34802460726796, 53.130617036930417],
              [0.347380638211755, 53.127442402312347],
              [0.346811350052387, 53.12662375218401],
              [0.346930799218315, 53.124125473010004],
              [0.34802394717311, 53.120735851148503],
              [0.349303723751619, 53.114085057811145],
              [0.349239956282204, 53.112175707939009],
              [0.348208765942921, 53.109157068159512],
              [0.344909737536434, 53.105122180852128],
              [0.342007405841298, 53.103867537911597],
              [0.338626830500518, 53.101532504911191],
              [0.337610489558656, 53.101495831807902],
              [0.339907396409195, 53.10266628221536],
              [0.341186202045286, 53.104068914557736],
              [0.34341538560489, 53.105390782560427],
              [0.345334298615331, 53.107515336833465],
              [0.342555159547692, 53.105012111988941],
              [0.340673279021111, 53.103977405823422],
              [0.340321798889849, 53.104327778998169],
              [0.340910870642209, 53.104665945496386],
              [0.34091445144793, 53.105364483145514],
              [0.341769476776857, 53.10562008984008],
              [0.340854013496593, 53.105407031127491],
              [0.340547942072729, 53.10455799865894],
              [0.339935954211886, 53.105256055028924],
              [0.343359677855216, 53.111537293162108],
              [0.342866091757734, 53.112737431985401],
              [0.341731459943933, 53.113086140934676],
              [0.341459392183573, 53.113469119904252],
              [0.342227847152547, 53.11498787700566],
              [0.341770915415194, 53.116808572451454],
              [0.342491746844748, 53.119373927472154],
              [0.341690505599392, 53.116758905510373],
              [0.342144657098973, 53.115079425132386],
              [0.341332259724527, 53.113521971294247],
              [0.341571261033185, 53.113025456471043],
              [0.342903188970093, 53.11251282351526],
              [0.343124264465168, 53.111467302581616],
              [0.342214431826097, 53.110398190750274],
              [0.341112933493968, 53.107615544635394],
              [0.339450755652479, 53.105455307139486],
              [0.339266027163924, 53.104529259842764],
              [0.339917161585177, 53.103561603326526],
              [0.33699125849578, 53.101316484977232],
              [0.337080260643161, 53.100919128816876],
              [0.33821641680915, 53.100603698327951],
              [0.338498778132861, 53.099695443254461],
              [0.337431614303792, 53.094450624440327],
              [0.335893314615895, 53.089546218345511],
              [0.334762681772117, 53.087641617309629],
              [0.333894908034867, 53.086563526916301],
              [0.332370002427567, 53.086670754935632],
              [0.331032192747808, 53.087697683679387],
              [0.331168532940188, 53.088313599944833],
              [0.331354014370426, 53.087932339320922],
              [0.33186740593132, 53.08822977368208],
              [0.330157098204754, 53.090434632645682],
              [0.329037473995141, 53.090554558037766],
              [0.328263183232416, 53.091206281839952],
              [0.327682698008203, 53.091049506475002],
              [0.327318167628877, 53.091268827270845],
              [0.326265361495163, 53.090330067420759],
              [0.323680282721775, 53.091164605667977],
              [0.326289010747439, 53.092484768423979],
              [0.323849668939339, 53.091397762848537],
              [0.322114129293841, 53.09161866257741],
              [0.322094970728296, 53.091068782458876],
              [0.320781256766946, 53.091606906265639],
              [0.319284906515476, 53.091665754887188],
              [0.318567922257325, 53.092108603172889],
              [0.318389010106334, 53.09228112165345],
              [0.319193544612134, 53.092549560037703],
              [0.319933607630222, 53.093312860068863],
              [0.32061879782588, 53.093160362062051],
              [0.320474213159138, 53.092506607875734],
              [0.320813198848395, 53.092453801796275],
              [0.320657562731137, 53.093151734294992],
              [0.320637142230976, 53.093243307469393],
              [0.320630477903726, 53.093273196108576],
              [0.319719576031079, 53.093332317956182],
              [0.318429507887927, 53.092395418237686],
              [0.316509286229347, 53.093739221886445],
              [0.316924704643432, 53.094382086410171],
              [0.320132595249968, 53.09509550596114],
              [0.320492323767929, 53.095451730114362],
              [0.319974991689902, 53.09824545303151],
              [0.3192237407237, 53.101239829268074],
              [0.318839520625177, 53.101657217135546],
              [0.317648528417072, 53.102122780604148],
              [0.315737183768611, 53.101499150631334],
              [0.314542084693378, 53.101638387473102],
              [0.313612352260637, 53.103326106280242],
              [0.311462601535586, 53.103800353968218],
              [0.308601975383443, 53.105646901835904],
              [0.308273335274274, 53.106986647730658],
              [0.308576722888189, 53.107797148332899],
              [0.307321310184469, 53.109938884241082],
              [0.303752191540736, 53.111400741163692],
              [0.307171283701877, 53.109843790777091],
              [0.308364106346782, 53.107816557634543],
              [0.30807536548853, 53.106861016786887],
              [0.30847574466479, 53.105549550202021],
              [0.311420907136014, 53.103691473131967],
              [0.313513894117269, 53.103217430676402],
              [0.314327087496624, 53.101585026518428],
              [0.315531611948189, 53.101370982662807],
              [0.317966695146289, 53.10189989863742],
              [0.31896637141814, 53.101276092847044],
              [0.319043496753026, 53.101227964812793],
              [0.319107823562382, 53.100851089745042],
              [0.319646585521156, 53.097694409631906],
              [0.318729313866801, 53.097664635686819],
              [0.319811770215581, 53.097405273303615],
              [0.320248674980893, 53.095448389685195],
              [0.316935420041921, 53.094581479413314],
              [0.316079555664945, 53.093969667024446],
              [0.31927412173762, 53.091409719514338],
              [0.318861688059259, 53.091044628884418],
              [0.317604895474198, 53.090864120384971],
              [0.31416999951129, 53.091730285477638],
              [0.31466612771402, 53.092903858783728],
              [0.311250084712032, 53.094599445112685],
              [0.311946029968785, 53.094764846370076],
              [0.31438059284564, 53.094178951290573],
              [0.314967802296937, 53.094238557175551],
              [0.314809832776474, 53.09469028458156],
              [0.314615777700744, 53.094330820857593],
              [0.31397326654905, 53.094298362823857],
              [0.311632356279781, 53.094876136248885],
              [0.311001000586928, 53.094494592435176],
              [0.314556859011588, 53.092760329222486],
              [0.313986964899615, 53.09171496415042],
              [0.31198889730737, 53.091553306310999],
              [0.30979827535024, 53.091811639245741],
              [0.305752270500516, 53.091420783987679],
              [0.304634403062201, 53.091686998792241],
              [0.305610634320717, 53.091369582423617],
              [0.304377747211502, 53.091244215745952],
              [0.301469874441359, 53.091524376310105],
              [0.300722457143235, 53.091249542033701],
              [0.29917715246148, 53.090681287478269],
              [0.293961617255896, 53.088010023790488],
              [0.301045345115933, 53.091325697189014],
              [0.301718659313985, 53.091484498659632],
              [0.304533744641183, 53.091144990095479],
              [0.309652832424236, 53.091689487003684],
              [0.311998037970144, 53.091445235959526],
              [0.313870392804801, 53.091657890234913],
              [0.316629837185606, 53.090923569568169],
              [0.316968312199555, 53.090551941168812],
              [0.316791619124535, 53.09009863554224],
              [0.315901220328384, 53.089676307200065],
              [0.31566874897212, 53.088684621456103],
              [0.31460488829603, 53.088646881042784],
              [0.314085439226919, 53.088234405262604],
              [0.312556937154185, 53.08799798942087],
              [0.312229673470053, 53.087548503043415],
              [0.310658134471651, 53.087288623586382],
              [0.310988068667511, 53.087007987847237],
              [0.309400484732708, 53.086698952793029],
              [0.308559461473075, 53.086191995151822],
              [0.30878979034533, 53.08586384737908],
              [0.304839049054685, 53.0851771092306],
              [0.304635185312049, 53.083960972080675],
              [0.303044208181922, 53.083585385263497],
              [0.302722067334829, 53.083732780428704],
              [0.302936749030131, 53.083362689042197],
              [0.297405161118895, 53.081220975824053],
              [0.296701814917376, 53.080687001366009],
              [0.297052040495028, 53.080281936170401],
              [0.296151156543363, 53.080440486784447],
              [0.296066803553758, 53.079893660937117],
              [0.294451807059032, 53.080381564683655],
              [0.295119762419347, 53.079970376988186],
              [0.295032843168948, 53.079375048345398],
              [0.292420809267711, 53.077931098445504],
              [0.290544068486134, 53.077369343895484],
              [0.289588510047968, 53.077537888887463],
              [0.288525820248865, 53.07571517380407],
              [0.287358044363593, 53.076019951735674],
              [0.287755369376306, 53.075713808984389],
              [0.287431971416545, 53.07482362126612],
              [0.286416846339994, 53.074909669905807],
              [0.286160086318807, 53.074233983009876],
              [0.279634538898134, 53.070957005787207],
              [0.278227997107901, 53.070668366773219],
              [0.278291451504515, 53.071106812774815],
              [0.277882030578915, 53.071156012134821],
              [0.276830091410466, 53.06952449929706],
              [0.275630471757732, 53.069223777370965],
              [0.275798827537825, 53.068764711523791],
              [0.275300191323612, 53.068990034877324],
              [0.274224678698119, 53.068437873650808],
              [0.273522738166583, 53.068459383592241],
              [0.273787682752259, 53.06824662685348],
              [0.273234600525286, 53.067693460528041],
              [0.272239516112883, 53.06736362113709],
              [0.271614034476167, 53.067532910351865],
              [0.272075283391349, 53.066370552888976],
              [0.270721198925496, 53.066365839898623],
              [0.270217155031847, 53.066005929827206],
              [0.27081715240745, 53.065040529711098],
              [0.26822476156007, 53.064051528876881],
              [0.267344420435497, 53.064284097200854],
              [0.267141011696125, 53.064048813224929],
              [0.268856031662382, 53.063339988106492],
              [0.268640640832301, 53.062621218203937],
              [0.258248435175808, 53.056434455971171],
              [0.254843461006387, 53.054757497290858],
              [0.252326647256134, 53.053968122054037],
              [0.251775954039014, 53.054625002472974],
              [0.25090851339692, 53.053414150057691],
              [0.249923705515399, 53.054417298330179],
              [0.249869788824225, 53.053382554902925],
              [0.249526406475505, 53.053579659220574],
              [0.248644419769328, 53.052574959832533],
              [0.248932049973977, 53.052224266991622],
              [0.24733202996551, 53.051241225038197],
              [0.248233550299237, 53.050988622647921],
              [0.247817425923879, 53.050504680193754],
              [0.240994099177272, 53.046738545356774],
              [0.236618693027463, 53.044991244504743],
              [0.234318946724671, 53.045207998577808],
              [0.233584502379723, 53.045026690056382],
              [0.232442965338867, 53.044416057651993],
              [0.231651322665086, 53.043127222765648],
              [0.229483672035911, 53.043994150210509],
              [0.227919423917227, 53.042156937363764],
              [0.226318777241855, 53.041723877580495],
              [0.225203569178663, 53.040664933381819],
              [0.22399960674459, 53.040488754430655],
              [0.221327094162368, 53.039370776403281],
              [0.221512311588431, 53.038592294779562],
              [0.219434133972575, 53.038587042466972],
              [0.216995496075957, 53.037283885085692],
              [0.216648722678126, 53.0374153238498],
              [0.216179805750835, 53.03693945042864],
              [0.215521439626094, 53.037161207415423],
              [0.214302190455166, 53.036684018773506],
              [0.214468246681018, 53.036140569715442],
              [0.213814855492415, 53.036226461117913],
              [0.213576125984012, 53.035962974436458],
              [0.211868835282276, 53.036178157761363],
              [0.20912049164404, 53.034528155900915],
              [0.205628676584243, 53.034291775328555],
              [0.201785924508572, 53.033226531801333],
              [0.201434047906586, 53.032643240375016],
              [0.200732822491185, 53.032872901672917],
              [0.200427099712825, 53.032551288811419],
              [0.199574685280605, 53.032623701983177],
              [0.192887255334776, 53.037655359782313],
              [0.171446366900287, 53.049583193456058],
              [0.164557824440273, 53.053844554584842],
              [0.163387070818774, 53.054333379095837],
              [0.16278966346814, 53.053607892428211],
              [0.160322124429332, 53.053469321909311],
              [0.158237581502212, 53.053977386012455],
              [0.155923829757081, 53.05590292831323],
              [0.15236163779217, 53.056767624964912],
              [0.152173578626629, 53.057216074052157],
              [0.150395693803248, 53.056845439648747],
              [0.149703414408239, 53.057357843197039],
              [0.146581610361804, 53.060751614634171],
              [0.14480999330413, 53.064263915960453],
              [0.143709176808808, 53.069206225819791],
              [0.144241971927214, 53.069684824327247],
              [0.141798271745821, 53.082284486629888],
              [0.13974550762064, 53.084699482858163],
              [0.139737149839819, 53.085193226819726],
              [0.137077400737948, 53.08666516730046],
              [0.131586724540519, 53.085219944734028],
              [0.129228136395452, 53.084933113861275],
              [0.124472738053862, 53.083369213885049],
              [0.118330071590822, 53.080731164026453],
              [0.096350788378393, 53.074416081386715],
              [0.094536803943177, 53.074198089068659],
              [0.093057567102142, 53.072786506764494],
              [0.08748060246787, 53.071618600518512],
              [0.082976588024226, 53.071643600467574],
              [0.082750529926211, 53.070887843770443],
              [0.048065372002015, 53.070677252075015],
              [0.042016095405155, 53.070284053943837],
              [0.040643241605564, 53.069883251752721],
              [0.03621199649793, 53.069390069846769],
              [0.044206985139383, 53.04077143669582],
              [0.045489611468442, 53.038056673461533],
              [0.045980553768294, 53.035446322590126],
              [0.046581829983938, 53.035418894614395],
              [0.046044804972426, 53.032172604056669],
              [0.04522211550759, 53.030041574873174],
              [0.044653772616678, 53.030164630782181],
              [0.035700301569051, 53.026756123779528],
              [0.023141026136192, 53.017415166990219],
              [0.020488158724154, 53.016924430183259],
              [0.020474321529368, 53.016149668763525],
              [0.017694314490132, 53.015999080032849],
              [0.009676816885441, 53.012187056164692],
              [0.000496666235415, 53.008827417123818],
              [-0.004828277638348, 53.004840529439612],
              [-0.006462810996013, 53.002748850168906],
              [-0.00706804408306, 53.001095725516976],
              [-0.010760103398444, 53.001991890564334],
              [-0.014996193649455, 53.004709537557083],
              [-0.019177420261568, 53.004274897515806],
              [-0.021806928354864, 53.005309444869646],
              [-0.023919293953498, 53.005660143032117],
              [-0.032618438689227, 53.004792064051941],
              [-0.051510426318546, 53.004877508181487],
              [-0.055436329995143, 53.006732705316011],
              [-0.063200210986768, 53.008933552136853],
              [-0.063929099350706, 53.008201004176698],
              [-0.112355187176032, 53.009951530615119],
              [-0.114747218788693, 53.010234021778508],
              [-0.118428524037172, 53.011993331024307],
              [-0.138049319768448, 53.030018746695902],
              [-0.164241136474487, 53.050058199313092],
              [-0.189081698987401, 53.062744898200307],
              [-0.192945861368303, 53.066017686669426],
              [-0.19612428327616, 53.069533475851685],
              [-0.197594650040297, 53.075035339292015],
              [-0.198823490323538, 53.077497505333561],
              [-0.195464303887159, 53.081391545184331],
              [-0.1957193506882, 53.082111937712419],
              [-0.1970231389296, 53.083783212424017],
              [-0.200098132570836, 53.08534282438481],
              [-0.201685600763874, 53.085731829046551],
              [-0.205173947643985, 53.085869899976821],
              [-0.207299035999452, 53.086402689402625],
              [-0.214519387358203, 53.089598489356476],
              [-0.217071473992634, 53.094150747871772],
              [-0.219323052702044, 53.096227036864661],
              [-0.21916049526569, 53.097776317359056],
              [-0.22042873322008, 53.100522919709285],
              [-0.220755306338422, 53.102563187013622],
              [-0.219041588505653, 53.105030543526773],
              [-0.222155866817814, 53.10692458857087],
              [-0.224642701605649, 53.109259602108878],
              [-0.224857854369308, 53.111435741860809],
              [-0.223289311906106, 53.112553196254694],
              [-0.223108662895427, 53.113347031246306],
              [-0.223602674088071, 53.114411651407053],
              [-0.226112318604038, 53.116023258446276],
              [-0.226444362128834, 53.118883489175197],
              [-0.226480354971734, 53.119933179843578],
              [-0.225290818174312, 53.121783612397948],
              [-0.226011047892939, 53.124580403642454],
              [-0.233392665034361, 53.13132041385105],
              [-0.234538169271835, 53.132000878889123],
              [-0.237183766648136, 53.132845596772157],
              [-0.238450316827608, 53.133712113500906],
              [-0.239866100140966, 53.138572445572493],
              [-0.246730815970395, 53.148054919097049],
              [-0.251991316621309, 53.152292876659565],
              [-0.252792748823451, 53.15516978298492],
              [-0.253633753762871, 53.155895033424578],
              [-0.256235025289698, 53.156476152143696],
              [-0.257941283121673, 53.157406425376024],
              [-0.260310791820699, 53.15798945760632],
              [-0.261431624242227, 53.160011512502173],
              [-0.264580039977472, 53.161869830712092],
              [-0.265165367577653, 53.164306576567959],
              [-0.267373296675159, 53.16605312234077],
              [-0.268860032043865, 53.168401368642371],
              [-0.27010312000605, 53.169334617432398],
              [-0.274388405632991, 53.170627516592994],
              [-0.275455216110198, 53.17331211182448],
              [-0.276024682972928, 53.173737493301068],
              [-0.278529849893222, 53.175034128461427],
              [-0.279667577005347, 53.174309786610394],
              [-0.281184101496462, 53.17387768284464],
              [-0.283177698562955, 53.175811421373929],
              [-0.282802063570344, 53.176936952508029],
              [-0.284962423261142, 53.177321384536839],
              [-0.285332923945287, 53.178011758204107],
              [-0.289653931567309, 53.179342370052531],
              [-0.288001252275322, 53.184731517416282],
              [-0.291933691802557, 53.190812166179668],
              [-0.290935410296071, 53.194723780845926],
              [-0.294721691155647, 53.201614917362633],
              [-0.30226619644641, 53.210401566534252],
              [-0.301090468074039, 53.213737164233038],
              [-0.299474096637817, 53.215109349914982],
              [-0.292732598777269, 53.219376682978343],
              [-0.291956598213999, 53.219342189417652],
              [-0.287565985984045, 53.221532063620231],
              [-0.28912686125034, 53.222770823334379],
              [-0.283752819378207, 53.224404342433793],
              [-0.279822342411257, 53.226602358728982],
              [-0.278897484996545, 53.227773883515496],
              [-0.278715917574702, 53.228864440876201],
              [-0.277207045162397, 53.230735925718129],
              [-0.277290289790594, 53.231200111998],
              [-0.276502775134776, 53.231782963057086],
              [-0.27596295954325, 53.23343470257057],
              [-0.276225935882943, 53.234608998835242],
              [-0.279347848313229, 53.237988481607474],
              [-0.281490707871353, 53.238922885749467],
              [-0.282473791009941, 53.240006858659562],
              [-0.281782359249494, 53.241069393755659],
              [-0.282715406721754, 53.243666548216602],
              [-0.282546521670103, 53.245975427408865],
              [-0.281405913075091, 53.248594836850295],
              [-0.280244823521639, 53.249740490113382],
              [-0.28784759360397, 53.253034158153696],
              [-0.290904977110757, 53.254741163262537],
              [-0.28881134195925, 53.255553484790923],
              [-0.287142816265559, 53.257505506458777],
              [-0.293055719115372, 53.259345141838068],
              [-0.291734634127547, 53.260969573932393],
              [-0.296835938968741, 53.262158203763505],
              [-0.300435052021474, 53.262063927841218],
              [-0.300611756972267, 53.263090394777294],
              [-0.299557097435149, 53.263181438157886],
              [-0.295151200319399, 53.263125710050346],
              [-0.291000197006826, 53.262468452724193],
              [-0.28981900210945, 53.263964515477078],
              [-0.294226413745506, 53.264285663734881],
              [-0.297648919702077, 53.263848227203042],
              [-0.300826600184738, 53.263949293931539],
              [-0.300526578935387, 53.264671400441472],
              [-0.298571531269282, 53.264968951016961],
              [-0.298715549293372, 53.265722560548134],
              [-0.299849834793307, 53.265478033702578],
              [-0.300909852775831, 53.265707103583097],
              [-0.301121385240519, 53.266423015455949],
              [-0.300899214259425, 53.267223544910756],
              [-0.300109357067567, 53.267289599407839],
              [-0.299110144042497, 53.268587871998754],
              [-0.300965752940521, 53.269743456964754],
              [-0.303198934416358, 53.268448352444864],
              [-0.304732102073794, 53.268697604900048],
              [-0.304686670262726, 53.269580663388624],
              [-0.302082577025401, 53.273385880519989],
              [-0.301896598254271, 53.275316053897072],
              [-0.303728299700138, 53.275342124932003],
              [-0.30892695299273, 53.276281688446872],
              [-0.311413291676852, 53.277013636809286],
              [-0.314483162682804, 53.277288108187363],
              [-0.316444100776275, 53.279447301929082],
              [-0.315560737425153, 53.279597543305805],
              [-0.314875297583695, 53.280245016673263],
              [-0.315013966626873, 53.281257434476068],
              [-0.313354816512186, 53.283128139097059],
              [-0.314274769229833, 53.285175547530763],
              [-0.311418821694552, 53.287679267425617],
              [-0.313204875492274, 53.288877716488471],
              [-0.314569651639531, 53.290375839256178],
              [-0.314879405454491, 53.291425733479976],
              [-0.314423441535446, 53.294013754055321],
              [-0.316689489731683, 53.296522454092894],
              [-0.317461286566142, 53.29822702585237],
              [-0.305530273558915, 53.301976785109481],
              [-0.300191520525492, 53.302863631823847],
              [-0.296213620029448, 53.302735856220373],
              [-0.295572970235405, 53.302957741964285],
              [-0.299900893659551, 53.31051972101946],
              [-0.308490485191075, 53.319080562707384],
              [-0.309795543514977, 53.319108049855245],
              [-0.310261093169354, 53.319978564214026],
              [-0.308539471888161, 53.320423433992076],
              [-0.308369764326894, 53.320728478711892],
              [-0.307419942410029, 53.320790520916169],
              [-0.301249341041066, 53.323888800303685],
              [-0.301437758404326, 53.32421062096541],
              [-0.300924540907128, 53.324316584444077],
              [-0.300810792761267, 53.32476085853547],
              [-0.300187763595235, 53.324645004698858],
              [-0.299955633502165, 53.325196367857167],
              [-0.299325918797669, 53.325361795079154],
              [-0.298649426364858, 53.325608359131138],
              [-0.299621384017977, 53.326692002106874],
              [-0.297302782468207, 53.329675012152038],
              [-0.298510070082154, 53.331035311102163],
              [-0.293312794358168, 53.334452717642506],
              [-0.285288067926285, 53.328318982138562],
              [-0.278241982700908, 53.324412111014773],
              [-0.27554032843687, 53.321816380596189],
              [-0.274979762404533, 53.321755236214585],
              [-0.271953186589165, 53.322194188779442],
              [-0.270320053818533, 53.323111758218261],
              [-0.269129157521234, 53.324972464295939],
              [-0.267609534054532, 53.32601210795994],
              [-0.265694447186978, 53.326618966806265],
              [-0.264806188572, 53.326454122554296],
              [-0.255441618139004, 53.329930576225109],
              [-0.25260224038488, 53.331538662687564],
              [-0.25126110628741, 53.332788380759744],
              [-0.249777682922207, 53.333586503978324],
              [-0.254068057106491, 53.337971682820005],
              [-0.255665772810344, 53.340447479719906],
              [-0.255105462324147, 53.340485135465542],
              [-0.255899021504609, 53.344117843793292],
              [-0.251179605147679, 53.344692442171763],
              [-0.250788430956957, 53.345440956112547],
              [-0.245693006408814, 53.3466795696636],
              [-0.244565664990626, 53.347389370186384],
              [-0.243403261800254, 53.347306638612871],
              [-0.242397048745589, 53.348393977561699],
              [-0.240771169958584, 53.349058641403133],
              [-0.235082703312447, 53.350487636193279],
              [-0.233792983215965, 53.351084359410649],
              [-0.231635474759044, 53.351374244189131],
              [-0.23052252129153, 53.352163237780324],
              [-0.230482800849616, 53.354536869965187],
              [-0.22731329696129, 53.355021131392192],
              [-0.225052364941714, 53.355877521414612],
              [-0.223791237814474, 53.357333095001643],
              [-0.222535626023323, 53.357967963345068],
              [-0.220651810024155, 53.36177766276041],
              [-0.219499911553591, 53.362371793784966],
              [-0.218650156847611, 53.363720178254546],
              [-0.215458109841818, 53.366630156470016],
              [-0.210338571254179, 53.370115283508063],
              [-0.204870352935188, 53.371862603869637],
              [-0.206832901489154, 53.374239357528538],
              [-0.215932796573203, 53.380520277372682],
              [-0.216622204331547, 53.382012104412205],
              [-0.223440553694618, 53.385846349362552],
              [-0.227533166131741, 53.392516495004855],
              [-0.228329830194799, 53.396038838436851],
              [-0.229393078995909, 53.398034165282318],
              [-0.224676264087878, 53.39958588108599],
              [-0.22379772582386, 53.398902175121009],
              [-0.221755645082706, 53.399682658174676],
              [-0.219501377174445, 53.399490841269063],
              [-0.218563493034832, 53.399797787239628],
              [-0.216502681139047, 53.40156228603],
              [-0.218440665390653, 53.40302599240043],
              [-0.214231231309425, 53.404868049035123],
              [-0.213004409837997, 53.404312103437348],
              [-0.208259451342487, 53.407003563825342],
              [-0.206021806700735, 53.408411032534794],
              [-0.208153263593274, 53.409386967702069],
              [-0.209973658516214, 53.411287747290324],
              [-0.210202522537613, 53.412000473577805],
              [-0.209602921448405, 53.413738203761241],
              [-0.211721008478479, 53.415686567791042],
              [-0.212028034943442, 53.419020985304371],
              [-0.2126824818451, 53.420065507096311],
              [-0.217062149379571, 53.424083782944564],
              [-0.216793596921728, 53.425394078862148],
              [-0.214847752616664, 53.42822645503778],
              [-0.212827867919646, 53.429337048944767],
              [-0.212055340267466, 53.428699797684089],
              [-0.20503987326796, 53.430443764859042],
              [-0.204834459200113, 53.43017457977853],
              [-0.202277925652236, 53.430510100678262],
              [-0.199969015621048, 53.43128979928251],
              [-0.198696037046109, 53.431028781313067],
              [-0.199050021236887, 53.431806342965146],
              [-0.197413729086863, 53.432375883393505],
              [-0.198292791709847, 53.433551527858789],
              [-0.195134092715122, 53.434822639575529],
              [-0.191069983915153, 53.434721600688739],
              [-0.189273338914417, 53.435091725361154],
              [-0.189112147452556, 53.435590013535332],
              [-0.185201048278481, 53.437178481882711],
              [-0.183727679120594, 53.438578273500731],
              [-0.180521462709881, 53.440678039866661],
              [-0.177234714389242, 53.438847944580061],
              [-0.175328858451507, 53.438281253378918],
              [-0.172815197104569, 53.43816191770938],
              [-0.167798129770306, 53.438560623541456],
              [-0.167073276273543, 53.437771881782346],
              [-0.164391029392966, 53.438246706159376],
              [-0.163846263461632, 53.438031566462861],
              [-0.16339076232844, 53.437038377986283],
              [-0.161726256510942, 53.437268981596667],
              [-0.160961054852313, 53.436546105463741],
              [-0.15898162681689, 53.437131415134772],
              [-0.141651532093552, 53.434857389995308],
              [-0.13487912861555, 53.436219869591838],
              [-0.131877017931093, 53.435914940766686],
              [-0.127648146609926, 53.435743538890868],
              [-0.122783678357181, 53.437321321749664],
              [-0.120775722282033, 53.435640098271996],
              [-0.120341476964078, 53.433540415064179],
              [-0.11207667077774, 53.437925688564803],
              [-0.110568068356413, 53.439996482507865],
              [-0.091711775315939, 53.446980346737547],
              [-0.089749653713746, 53.447080236729228],
              [-0.086944592681872, 53.449618152705881],
              [-0.082206630774806, 53.451126205304639],
              [-0.082975668325932, 53.452743253570262],
              [-0.085825412649965, 53.455517396780245],
              [-0.094980006875475, 53.461337270319355],
              [-0.102918492683963, 53.465631308656071],
              [-0.10781743522194, 53.469838049420567],
              [-0.089800384015711, 53.477657540932086],
              [-0.091283257175424, 53.478328510160217],
              [-0.091092346831777, 53.478754278087557],
              [-0.092710984305502, 53.479625175385863],
              [-0.094122996540891, 53.481240712803405],
              [-0.093231479709604, 53.481494388314857],
              [-0.093678195678395, 53.482186544835066],
              [-0.092196578490309, 53.482827250943245],
              [-0.095484810735952, 53.485964131039587],
              [-0.092904231839734, 53.486001186473601],
              [-0.089815293419986, 53.485275809939679],
              [-0.087363241713044, 53.485873970756167],
              [-0.082691688306533, 53.488158953848362],
              [-0.081900791773267, 53.487748009512913],
              [-0.079902911868864, 53.4887974085918],
              [-0.07837597795417, 53.488896924967172],
              [-0.079061920495147, 53.489395205550025],
              [-0.075258101177815, 53.489310625938757],
              [-0.073389714459394, 53.495279477003784],
              [-0.072981052161493, 53.498506552525974],
              [-0.068667593785672, 53.511373373605529],
              [-0.068409901549838, 53.513292144638534],
              [-0.067480727868655, 53.51408981081773],
              [-0.067621492978137, 53.515935011214218],
              [-0.06709132724313, 53.516812841154227],
              [-0.065040793343422, 53.518714287527359],
              [-0.060540294519812, 53.519723734825583],
              [-0.058033393226929, 53.519950933797951],
              [-0.057699604907779, 53.519564339636688],
              [-0.056638777647296, 53.519902198789708],
              [-0.056110377183552, 53.519549294618521],
              [-0.054909550066332, 53.519814740666007],
              [-0.050428866262785, 53.519826253480311],
              [-0.049880511157588, 53.519481985815453],
              [-0.048910493422408, 53.51945087434963],
              [-0.046608617248342, 53.520073139922111],
              [-0.043855734682677, 53.51836858602465],
              [-0.042364365372228, 53.51626480442939],
              [-0.041629660938826, 53.516202426149697],
              [-0.039595262212139, 53.513090929428593],
              [-0.037925781909375, 53.513904094456876],
              [-0.035434500286178, 53.51418950868603],
              [-0.033101143737847, 53.515362978147671],
              [-0.033247853827822, 53.516135829109153],
              [-0.032779672368495, 53.516474237153716],
              [-0.028633516042438, 53.51731211504304],
              [-0.027359022280499, 53.516985431298131],
              [-0.024786379086088, 53.517068806818493],
              [-0.02396261593431, 53.516683907188671],
              [-0.022469255382471, 53.517527639228547],
              [-0.019542903876497, 53.51794936499288],
              [-0.017425133772855, 53.515041069682148],
              [-0.014573691800006, 53.515379409799763],
              [-0.01350607472108, 53.516598690213698],
              [-0.00877316463002, 53.516655689063562],
              [-0.005618037696068, 53.517161364801673],
              [0.004752094066464, 53.51949250030939],
              [0.007624483271445, 53.520710082485401],
              [0.009479882255879, 53.52093152516467],
              [0.01195078585071, 53.52204427737982],
              [0.011506412633217, 53.522440123490746],
              [0.012459010656652, 53.523345564271054],
              [0.01727998200555, 53.525333802965342],
              [0.017620482817448, 53.524847990594914],
              [0.018669588125672, 53.524606428117224],
              [0.020023396660239, 53.523286301333961],
              [0.021985151441782, 53.519877385913581],
              [0.026914453322723, 53.519362381472234],
              [0.029424371884956, 53.518411802110357],
              [0.032396504689501, 53.517918095459102],
              [0.034316796625074, 53.514865675858715],
              [0.038063822305531, 53.513773382572666],
              [0.038487789511323, 53.510854275813152],
              [0.040168368523451, 53.509985031022374],
              [0.039952235184823, 53.510414848698773],
              [0.038598324869416, 53.511020504491157],
              [0.038278428502354, 53.513813774296658],
              [0.041196045449945, 53.513920411194398],
              [0.043310304961665, 53.513662231875514],
              [0.043715227724038, 53.513896242422547],
              [0.038081089166463, 53.516584275028706],
              [0.044089488637584, 53.514088522735058],
              [0.043622799548919, 53.513542715590717],
              [0.040792564640967, 53.513747499939178],
              [0.038831379304447, 53.513173242146301],
              [0.040648586265182, 53.51059444971078],
              [0.040288919418342, 53.509574824701851],
              [0.040633036501719, 53.507979507806027],
              [0.041003934418666, 53.507977669597089],
              [0.040696597280076, 53.509553480572698],
              [0.041113391145298, 53.510443571069494],
              [0.039276118267107, 53.513106320715906],
              [0.041120591668608, 53.513480288212314],
              [0.043748310015628, 53.513298737055862],
              [0.044383085425282, 53.513576462187899],
              [0.04451399147781, 53.514227800182155],
              [0.044868185899715, 53.514191174891025],
              [0.04461607562972, 53.513184106357642],
              [0.04499853723925, 53.512118960427948],
              [0.045206751686861, 53.511539065666895],
              [0.045390057604553, 53.511028532510132],
              [0.047640291990748, 53.510028065626365],
              [0.04939318959804, 53.509943182378898],
              [0.050068205883251, 53.509586387318734],
              [0.049764354291228, 53.508884080157074],
              [0.048125659795719, 53.507583440462959],
              [0.050018873131881, 53.508845552567891],
              [0.050188518012279, 53.50976682204459],
              [0.049655507995915, 53.510034877901823],
              [0.047740848421065, 53.51011174846964],
              [0.045499798238274, 53.511177687492456],
              [0.045261100518507, 53.511625920911214],
              [0.045534924441318, 53.512063506379135],
              [0.044862892886365, 53.512640480472157],
              [0.044945725082148, 53.51395340980951],
              [0.04736720591383, 53.513623375441071],
              [0.045363696495632, 53.514144938330816],
              [0.04889215648942, 53.514031464566258],
              [0.04794670252503, 53.513098317100308],
              [0.048310123062232, 53.512283882844294],
              [0.04954599672231, 53.511797011336512],
              [0.048416742816952, 53.512461856915067],
              [0.0482027552892, 53.513059767330844],
              [0.048730596302432, 53.513465162875484],
              [0.049789590146, 53.513406551016161],
              [0.050162629645812, 53.513146802105886],
              [0.05053086675739, 53.512890392375027],
              [0.049962783595557, 53.512307691543235],
              [0.050195596073431, 53.512069057522083],
              [0.05075255840437, 53.512858719648179],
              [0.050182690393755, 53.5131741555048],
              [0.050287524990128, 53.513317100017609],
              [0.050849363760542, 53.513489060427368],
              [0.051648014378679, 53.513286562731274],
              [0.051362777851245, 53.512190175016507],
              [0.052053258055963, 53.51346390240851],
              [0.050615083760807, 53.513728619825095],
              [0.048973137162468, 53.513584169198417],
              [0.049262162732184, 53.514041303491226],
              [0.052322698938197, 53.513639976613852],
              [0.053904888149381, 53.512579839400061],
              [0.055940304439946, 53.509387529277213],
              [0.054645454723407, 53.507935422866055],
              [0.054873310495486, 53.507127792193678],
              [0.053174926777195, 53.50524928572986],
              [0.052070558155444, 53.504988661520507],
              [0.047944427393863, 53.50704624278665],
              [0.052035520201411, 53.504887675211648],
              [0.053086851508947, 53.505049420936913],
              [0.05362041661928, 53.505480767847018],
              [0.055125031810129, 53.507218758889323],
              [0.055072101397186, 53.508400066800853],
              [0.056017323421304, 53.509109309872059],
              [0.056527321515962, 53.508553938489257],
              [0.05645419315192, 53.508943568364202],
              [0.057091909864191, 53.508876855462958],
              [0.062443166949932, 53.510967426279095],
              [0.057077715610511, 53.50898857680636],
              [0.056286645257114, 53.509065120195977],
              [0.055289586309965, 53.511055592619037],
              [0.056988824180281, 53.510983207958425],
              [0.058812368839656, 53.511484023242488],
              [0.056843814685403, 53.511072008300474],
              [0.055091815630653, 53.511207329610215],
              [0.054138480959319, 53.512794283871671],
              [0.052383166913995, 53.513892458285142],
              [0.054242718113769, 53.513857816087892],
              [0.054363634652729, 53.51348894288148],
              [0.055999650768525, 53.513541722767165],
              [0.054435389866978, 53.513631550716134],
              [0.054520619548825, 53.513959122400607],
              [0.056049143343493, 53.514470445206371],
              [0.057852498713423, 53.513898207963933],
              [0.059072723859818, 53.51427185765511],
              [0.061251945040253, 53.514297226612264],
              [0.059084066095193, 53.51438134123628],
              [0.057887079608113, 53.51398931138116],
              [0.057500761543113, 53.514327695956759],
              [0.0559598964174, 53.514589750355476],
              [0.05359299594576, 53.513927416403824],
              [0.051408917834452, 53.514331716494866],
              [0.05203793915101, 53.514925950557945],
              [0.056336246386735, 53.516630315961962],
              [0.057145861679522, 53.51684023760761],
              [0.057673787562872, 53.516497618794574],
              [0.058637917958242, 53.516721052428046],
              [0.058617442700785, 53.517076513067607],
              [0.05763792712949, 53.516598025955581],
              [0.057497134471927, 53.517708026646645],
              [0.057089073838498, 53.51694460078545],
              [0.050195077974886, 53.514432554965197],
              [0.049677526290126, 53.514615838527398],
              [0.052274817650627, 53.515707615466447],
              [0.052719656204398, 53.516828230189574],
              [0.052033886191272, 53.515684781801582],
              [0.049851064660677, 53.51489874654024],
              [0.049544018675092, 53.515193491958549],
              [0.048401994743287, 53.514657483158601],
              [0.047388759517381, 53.514633489100362],
              [0.050098442560563, 53.517023350733268],
              [0.050871630692866, 53.516948055483873],
              [0.051313488028934, 53.517414249640012],
              [0.050858344279447, 53.518421754614202],
              [0.050008229480658, 53.518653896931838],
              [0.050911424786018, 53.519083411342436],
              [0.050894830262651, 53.519395651150909],
              [0.038957019148427, 53.524867141933349],
              [0.039221739036093, 53.525007365923948],
              [0.040464080335982, 53.524582513874741],
              [0.040684240279231, 53.524831375688251],
              [0.037619785069486, 53.526301377954695],
              [0.037769869142221, 53.526693481907401],
              [0.040103670491319, 53.526406450189462],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000138",
        LAD13CDO: "32UD",
        LAD13NM: "Lincoln",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.526507318446081, 53.253831485666211],
            [-0.523274916000739, 53.253369879655104],
            [-0.523274829035241, 53.253807673299377],
            [-0.514872040020272, 53.25396579751127],
            [-0.514163712805138, 53.253772684630043],
            [-0.50621020878208, 53.249896748323202],
            [-0.502449982375088, 53.250572342575715],
            [-0.500593434750446, 53.248241362776909],
            [-0.497566042370905, 53.249177727873494],
            [-0.496011797344175, 53.247167819000602],
            [-0.495440682608425, 53.244350442434332],
            [-0.495813619748696, 53.243801384961095],
            [-0.494342646831419, 53.241817672866738],
            [-0.496286172905487, 53.240324747584189],
            [-0.495748910355026, 53.240133680854875],
            [-0.493915020899668, 53.233380848947917],
            [-0.496424088133374, 53.233695699838492],
            [-0.495119045630108, 53.225237011730094],
            [-0.502301139569984, 53.225774264135993],
            [-0.501613327055714, 53.222819697529033],
            [-0.501274084607116, 53.22103816946418],
            [-0.505446056331864, 53.21990571719062],
            [-0.50891477072991, 53.218186286273763],
            [-0.51342659347413, 53.218081733488695],
            [-0.513823960074792, 53.220188477491547],
            [-0.52255616791434, 53.219449318882653],
            [-0.526915827697778, 53.218718485250278],
            [-0.52689912492315, 53.21828857256375],
            [-0.530338359230283, 53.217357451341968],
            [-0.528684747643841, 53.21473903340479],
            [-0.531971011619215, 53.214067577079632],
            [-0.533833730779367, 53.213198732470502],
            [-0.535662685733429, 53.212263819571291],
            [-0.538453423771284, 53.210038974125183],
            [-0.539821590475495, 53.208291972676456],
            [-0.539577555529606, 53.203930788562118],
            [-0.540844236037857, 53.203665827785649],
            [-0.54321775608266, 53.203962759939465],
            [-0.54452268358974, 53.204512691900263],
            [-0.544946037926085, 53.205451888583568],
            [-0.548840822319669, 53.205840988405548],
            [-0.549526723555745, 53.197455685342462],
            [-0.552845399941266, 53.18630388650886],
            [-0.558454385798646, 53.186448295289594],
            [-0.564973102890048, 53.18713828589901],
            [-0.574476814078237, 53.191561116176565],
            [-0.574095284645463, 53.191827139574514],
            [-0.575152051407462, 53.192368373059367],
            [-0.574339831192993, 53.193028386776128],
            [-0.574991232336626, 53.193381381654589],
            [-0.574327642682232, 53.193892146578328],
            [-0.578115598440979, 53.195766802243647],
            [-0.577804897611258, 53.196063348424723],
            [-0.58066698381271, 53.197561887021614],
            [-0.582864267024311, 53.197027085819158],
            [-0.583429876829705, 53.198624077942434],
            [-0.583859151809807, 53.198544677803035],
            [-0.58430047156582, 53.199008393141746],
            [-0.586771338003371, 53.198622401910136],
            [-0.589030915577095, 53.199882555868335],
            [-0.588217029902018, 53.200549835039993],
            [-0.600489796059868, 53.203554200051613],
            [-0.607060665200055, 53.201904284825758],
            [-0.608918589763448, 53.201859464799689],
            [-0.613774211983725, 53.202683759726419],
            [-0.615030164645775, 53.203525405921646],
            [-0.614879957312432, 53.205258651616042],
            [-0.61764234907679, 53.207354736367577],
            [-0.619736229442147, 53.208035245778717],
            [-0.619275681903524, 53.208714018099627],
            [-0.617912136578619, 53.210604004088061],
            [-0.61740965980245, 53.214304579257451],
            [-0.620320446811742, 53.216698073512262],
            [-0.62261813882805, 53.217576860402488],
            [-0.616053267074538, 53.219263666787285],
            [-0.616988832872918, 53.220861185322157],
            [-0.615655572143165, 53.221192704738243],
            [-0.615667553520514, 53.221889535409197],
            [-0.610475525529114, 53.224079209407435],
            [-0.608575624304391, 53.222431730823892],
            [-0.606692742068401, 53.224141127475193],
            [-0.60334628307718, 53.22515643931019],
            [-0.596477903688117, 53.229331428513703],
            [-0.595036447809524, 53.227829368540249],
            [-0.592508181432801, 53.228683217276313],
            [-0.578836729575578, 53.230578718251024],
            [-0.575474956808385, 53.231613745766765],
            [-0.570727143735033, 53.229589986315631],
            [-0.572084839780012, 53.234216125535532],
            [-0.572195622853812, 53.236425297194728],
            [-0.595112175682127, 53.245148665264821],
            [-0.561727435533217, 53.251237463847602],
            [-0.554691661296716, 53.252126851165848],
            [-0.544777698516013, 53.253896769525596],
            [-0.538579959686081, 53.254540130313089],
            [-0.526507318446081, 53.253831485666211],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000139",
        LAD13CDO: "32UE",
        LAD13NM: "North Kesteven",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.619466547121862, 53.256570289884934],
            [-0.612204677764165, 53.256073368909831],
            [-0.611474465003293, 53.256402873512258],
            [-0.601996384449944, 53.2504111647063],
            [-0.597432611077551, 53.247241786158554],
            [-0.595112175682127, 53.245148665264821],
            [-0.572195622853812, 53.236425297194728],
            [-0.572084839780012, 53.234216125535532],
            [-0.570727143735033, 53.229589986315631],
            [-0.575474956808385, 53.231613745766765],
            [-0.578836729575578, 53.230578718251024],
            [-0.592508181432801, 53.228683217276313],
            [-0.595036447809524, 53.227829368540249],
            [-0.596477903688117, 53.229331428513703],
            [-0.60334628307718, 53.22515643931019],
            [-0.606692742068401, 53.224141127475193],
            [-0.608575624304391, 53.222431730823892],
            [-0.610475525529114, 53.224079209407435],
            [-0.615667553520514, 53.221889535409197],
            [-0.615655572143165, 53.221192704738243],
            [-0.616988832872918, 53.220861185322157],
            [-0.616053267074538, 53.219263666787285],
            [-0.62261813882805, 53.217576860402488],
            [-0.620320446811742, 53.216698073512262],
            [-0.61740965980245, 53.214304579257451],
            [-0.617912136578619, 53.210604004088061],
            [-0.619275681903524, 53.208714018099627],
            [-0.619736229442147, 53.208035245778717],
            [-0.61764234907679, 53.207354736367577],
            [-0.614879957312432, 53.205258651616042],
            [-0.615030164645775, 53.203525405921646],
            [-0.613774211983725, 53.202683759726419],
            [-0.608918589763448, 53.201859464799689],
            [-0.607060665200055, 53.201904284825758],
            [-0.600489796059868, 53.203554200051613],
            [-0.588217029902018, 53.200549835039993],
            [-0.589030915577095, 53.199882555868335],
            [-0.586771338003371, 53.198622401910136],
            [-0.58430047156582, 53.199008393141746],
            [-0.583859151809807, 53.198544677803035],
            [-0.583429876829705, 53.198624077942434],
            [-0.582864267024311, 53.197027085819158],
            [-0.58066698381271, 53.197561887021614],
            [-0.577804897611258, 53.196063348424723],
            [-0.578115598440979, 53.195766802243647],
            [-0.574327642682232, 53.193892146578328],
            [-0.574991232336626, 53.193381381654589],
            [-0.574339831192993, 53.193028386776128],
            [-0.575152051407462, 53.192368373059367],
            [-0.574095284645463, 53.191827139574514],
            [-0.574476814078237, 53.191561116176565],
            [-0.564973102890048, 53.18713828589901],
            [-0.558454385798646, 53.186448295289594],
            [-0.552845399941266, 53.18630388650886],
            [-0.549526723555745, 53.197455685342462],
            [-0.548840822319669, 53.205840988405548],
            [-0.544946037926085, 53.205451888583568],
            [-0.54452268358974, 53.204512691900263],
            [-0.54321775608266, 53.203962759939465],
            [-0.540844236037857, 53.203665827785649],
            [-0.539577555529606, 53.203930788562118],
            [-0.539821590475495, 53.208291972676456],
            [-0.538453423771284, 53.210038974125183],
            [-0.535662685733429, 53.212263819571291],
            [-0.533833730779367, 53.213198732470502],
            [-0.531971011619215, 53.214067577079632],
            [-0.528684747643841, 53.21473903340479],
            [-0.530338359230283, 53.217357451341968],
            [-0.52689912492315, 53.21828857256375],
            [-0.526915827697778, 53.218718485250278],
            [-0.52255616791434, 53.219449318882653],
            [-0.513823960074792, 53.220188477491547],
            [-0.51342659347413, 53.218081733488695],
            [-0.50891477072991, 53.218186286273763],
            [-0.505446056331864, 53.21990571719062],
            [-0.501274084607116, 53.22103816946418],
            [-0.501613327055714, 53.222819697529033],
            [-0.502301139569984, 53.225774264135993],
            [-0.495119045630108, 53.225237011730094],
            [-0.488318449859669, 53.225271414966613],
            [-0.480842643684456, 53.226140959905315],
            [-0.475685591630986, 53.227135005349822],
            [-0.462028647987922, 53.227235549161762],
            [-0.444432710912354, 53.229705023313791],
            [-0.430851774537288, 53.230487111492749],
            [-0.413600939285065, 53.230140990399832],
            [-0.406141510148071, 53.230624911302144],
            [-0.399549212726974, 53.230510504025411],
            [-0.381403475571865, 53.231929483024217],
            [-0.378791395281595, 53.231192776064525],
            [-0.373905948442069, 53.227706490098612],
            [-0.370813823593108, 53.226207907885964],
            [-0.36972252888662, 53.226384469127311],
            [-0.366657742528599, 53.225394077956373],
            [-0.364253767918684, 53.226013771770027],
            [-0.362898808832261, 53.225640068330364],
            [-0.361710554441621, 53.225687568418813],
            [-0.361731839298718, 53.22666056366711],
            [-0.359553514891538, 53.227173588579433],
            [-0.359522283577158, 53.227637934438285],
            [-0.358272023014903, 53.22816910055932],
            [-0.357496500066438, 53.227490468826609],
            [-0.353257526670916, 53.22798125612281],
            [-0.352199603265083, 53.227831786375475],
            [-0.3513152440779, 53.227298143839334],
            [-0.350083807739226, 53.225388734617773],
            [-0.348070137046225, 53.224034833893725],
            [-0.34653631574907, 53.220337600711481],
            [-0.34653508120796, 53.218619616181158],
            [-0.347727725007482, 53.217013482818672],
            [-0.346741097021337, 53.214630962905638],
            [-0.34228627389846, 53.212324297965417],
            [-0.336820607675071, 53.208445406219695],
            [-0.337550056376692, 53.203720595954309],
            [-0.337122086961238, 53.202979248523789],
            [-0.335738341664615, 53.202329735483048],
            [-0.330792505185955, 53.201610579506976],
            [-0.328973100692479, 53.199770908204364],
            [-0.32552810699681, 53.195547613064136],
            [-0.325123791705622, 53.190596557831967],
            [-0.318910166207775, 53.184597239722883],
            [-0.309637491025592, 53.182000973497573],
            [-0.298587397285596, 53.181654872396081],
            [-0.295815506938428, 53.181172941955722],
            [-0.294544967391797, 53.179853005617623],
            [-0.291248235148822, 53.178913052677288],
            [-0.290299837884012, 53.178867080372648],
            [-0.289653931567309, 53.179342370052531],
            [-0.285332923945287, 53.178011758204107],
            [-0.284962423261142, 53.177321384536839],
            [-0.282802063570344, 53.176936952508029],
            [-0.283177698562955, 53.175811421373929],
            [-0.281184101496462, 53.17387768284464],
            [-0.279667577005347, 53.174309786610394],
            [-0.278529849893222, 53.175034128461427],
            [-0.276024682972928, 53.173737493301068],
            [-0.275455216110198, 53.17331211182448],
            [-0.274388405632991, 53.170627516592994],
            [-0.27010312000605, 53.169334617432398],
            [-0.268860032043865, 53.168401368642371],
            [-0.267373296675159, 53.16605312234077],
            [-0.265165367577653, 53.164306576567959],
            [-0.264580039977472, 53.161869830712092],
            [-0.261431624242227, 53.160011512502173],
            [-0.260310791820699, 53.15798945760632],
            [-0.257941283121673, 53.157406425376024],
            [-0.256235025289698, 53.156476152143696],
            [-0.253633753762871, 53.155895033424578],
            [-0.252792748823451, 53.15516978298492],
            [-0.251991316621309, 53.152292876659565],
            [-0.246730815970395, 53.148054919097049],
            [-0.239866100140966, 53.138572445572493],
            [-0.238450316827608, 53.133712113500906],
            [-0.237183766648136, 53.132845596772157],
            [-0.234538169271835, 53.132000878889123],
            [-0.233392665034361, 53.13132041385105],
            [-0.226011047892939, 53.124580403642454],
            [-0.225290818174312, 53.121783612397948],
            [-0.226480354971734, 53.119933179843578],
            [-0.226444362128834, 53.118883489175197],
            [-0.226112318604038, 53.116023258446276],
            [-0.223602674088071, 53.114411651407053],
            [-0.223108662895427, 53.113347031246306],
            [-0.223289311906106, 53.112553196254694],
            [-0.224857854369308, 53.111435741860809],
            [-0.224642701605649, 53.109259602108878],
            [-0.222155866817814, 53.10692458857087],
            [-0.219041588505653, 53.105030543526773],
            [-0.220755306338422, 53.102563187013622],
            [-0.22042873322008, 53.100522919709285],
            [-0.21916049526569, 53.097776317359056],
            [-0.219323052702044, 53.096227036864661],
            [-0.217071473992634, 53.094150747871772],
            [-0.214519387358203, 53.089598489356476],
            [-0.207299035999452, 53.086402689402625],
            [-0.205173947643985, 53.085869899976821],
            [-0.201685600763874, 53.085731829046551],
            [-0.200098132570836, 53.08534282438481],
            [-0.1970231389296, 53.083783212424017],
            [-0.1957193506882, 53.082111937712419],
            [-0.195464303887159, 53.081391545184331],
            [-0.198823490323538, 53.077497505333561],
            [-0.197594650040297, 53.075035339292015],
            [-0.19612428327616, 53.069533475851685],
            [-0.198855342244058, 53.070331832210371],
            [-0.204296299616329, 53.070219881875389],
            [-0.206627449885282, 53.070552619125436],
            [-0.210764737876762, 53.070130330379037],
            [-0.211360501686118, 53.067496147301249],
            [-0.214062514301317, 53.062361933617531],
            [-0.21769602826191, 53.052683543384838],
            [-0.217960644808175, 53.051622158491192],
            [-0.220287744716829, 53.047939511610444],
            [-0.218043079962862, 53.044705362782949],
            [-0.218088070362778, 53.043469870679615],
            [-0.220933639095358, 53.03939220989858],
            [-0.219547776800847, 53.039034337319642],
            [-0.223132857529369, 53.034806765041459],
            [-0.222932334946772, 53.033517256499522],
            [-0.224044832545269, 53.032865893314899],
            [-0.224942053698309, 53.030120161777546],
            [-0.224784454776542, 53.029019193748191],
            [-0.223271755041589, 53.027509607694853],
            [-0.220963728247892, 53.026487069748214],
            [-0.220296391862914, 53.025818095008013],
            [-0.219192414500716, 53.025686493542509],
            [-0.218783132514509, 53.02512476243519],
            [-0.216486867641407, 53.024003418226656],
            [-0.215091505614003, 53.021540710167251],
            [-0.213394888101687, 53.020707901776568],
            [-0.213564343768039, 53.019962451334052],
            [-0.214600124944494, 53.01914190477337],
            [-0.214164874008639, 53.017334604455428],
            [-0.212994210820927, 53.016188730595012],
            [-0.209539716605749, 53.014612005749683],
            [-0.207098844753562, 53.012287184183826],
            [-0.206926597552788, 53.011120335624419],
            [-0.203867351251354, 53.009301265915745],
            [-0.203966181593151, 53.007782487716085],
            [-0.20209976482317, 53.005924741262184],
            [-0.201428092000029, 53.003976258524396],
            [-0.199663632252069, 53.003471267341354],
            [-0.193727914552204, 52.997749663196792],
            [-0.18989707324084, 52.996386895390643],
            [-0.196651421252853, 52.9889924426043],
            [-0.198803077245929, 52.985583535173831],
            [-0.198898697519357, 52.984287668335611],
            [-0.198389297718422, 52.983784578008951],
            [-0.199167344627844, 52.982950369447209],
            [-0.19893106633557, 52.981754661629715],
            [-0.1996863237049, 52.981180826326693],
            [-0.201204784876402, 52.977437730062498],
            [-0.215438288947683, 52.960839306343757],
            [-0.224008036418188, 52.956050817459335],
            [-0.246714075467521, 52.928809901965828],
            [-0.252432821693612, 52.915333899895188],
            [-0.256504799375069, 52.90586117481255],
            [-0.275422080964738, 52.90957345688323],
            [-0.27886681851318, 52.91000910383714],
            [-0.283724202055495, 52.910036225807652],
            [-0.290554757491747, 52.910787415108906],
            [-0.29288511738284, 52.910749901456597],
            [-0.310393443249575, 52.914989229156006],
            [-0.314439912567166, 52.916379978154474],
            [-0.322838493665243, 52.917507553825367],
            [-0.339269434532795, 52.916927442717473],
            [-0.341836085538396, 52.916512878139294],
            [-0.345151319171477, 52.916514150836562],
            [-0.352031555468677, 52.915814144571243],
            [-0.357901574400055, 52.915628411638309],
            [-0.3575315802426, 52.911845573012236],
            [-0.358559506633524, 52.9116413157181],
            [-0.358153247719625, 52.909957212195167],
            [-0.358949974112298, 52.909888206909159],
            [-0.359030325262665, 52.908431089420674],
            [-0.360714523763779, 52.903800059874122],
            [-0.360833166373722, 52.90191732781129],
            [-0.366076678345268, 52.901141781022048],
            [-0.365832552032766, 52.897909103555818],
            [-0.371495720247258, 52.897701886144347],
            [-0.375076321344516, 52.898439578139005],
            [-0.382554663333432, 52.898395100298345],
            [-0.383466245507724, 52.898628670437418],
            [-0.388792714084092, 52.89747835876139],
            [-0.391305441538596, 52.897231019010739],
            [-0.391291023384563, 52.896496317638793],
            [-0.39872723472297, 52.894398671595724],
            [-0.410414432966932, 52.89184508323391],
            [-0.414338157269583, 52.891250229161919],
            [-0.427128255269826, 52.890412476325558],
            [-0.427882035641203, 52.888641486992306],
            [-0.430761864212367, 52.888827009200043],
            [-0.437349201278105, 52.886974660643993],
            [-0.44074656411615, 52.892617527935961],
            [-0.442378368460364, 52.894410911211438],
            [-0.44189459043286, 52.894801934449696],
            [-0.443556491829793, 52.896256763599624],
            [-0.444168440358029, 52.89896993734564],
            [-0.445339901264813, 52.900550471031615],
            [-0.447599365785032, 52.900479329344698],
            [-0.454675607536237, 52.901378912566955],
            [-0.469763673359872, 52.901166971408657],
            [-0.471827170528174, 52.904171981136287],
            [-0.472071963331285, 52.905413077243566],
            [-0.477143441918276, 52.905426052486824],
            [-0.476933866988062, 52.905593280045885],
            [-0.479891379164605, 52.906400718191897],
            [-0.481793128677366, 52.90797673884569],
            [-0.483486378825389, 52.910842851178565],
            [-0.486652992786006, 52.911766062437991],
            [-0.488251104171998, 52.913149314611985],
            [-0.488606447034052, 52.912981224709057],
            [-0.489214811945092, 52.913448358756199],
            [-0.492444190122829, 52.917755200674925],
            [-0.493982007668761, 52.920716274224162],
            [-0.492007489588485, 52.920863841398244],
            [-0.487616682547238, 52.920559906165678],
            [-0.487912468536858, 52.921889715818388],
            [-0.488287370730684, 52.921888193110746],
            [-0.488369312253677, 52.924019897171647],
            [-0.487569697371955, 52.925152367680226],
            [-0.486293294036882, 52.924940129357317],
            [-0.486004049315051, 52.926067402902504],
            [-0.486872789636085, 52.928031124783416],
            [-0.481663997236636, 52.927997945772638],
            [-0.48158886430194, 52.929804901348831],
            [-0.47985606664738, 52.929749483229052],
            [-0.479494629216176, 52.931402639314321],
            [-0.479887870005126, 52.932663591564236],
            [-0.480947099340059, 52.932327420654147],
            [-0.481539270645875, 52.933900170076811],
            [-0.483392587031818, 52.933747630986545],
            [-0.483731376958002, 52.937677040843894],
            [-0.482726162848259, 52.937657018846494],
            [-0.482880471407767, 52.939371606247441],
            [-0.487185859650621, 52.939490320860237],
            [-0.487876372116066, 52.943757722752771],
            [-0.489153225056601, 52.946493467361016],
            [-0.489679012827214, 52.946909199651266],
            [-0.494141542928109, 52.948310742731408],
            [-0.494282796479043, 52.951700896572312],
            [-0.492867144401965, 52.951959848645394],
            [-0.49415988085714, 52.953825494311118],
            [-0.49460193592992, 52.954041461920561],
            [-0.502110607388776, 52.951020365427439],
            [-0.508048247250664, 52.949288941580384],
            [-0.507647761666621, 52.94773492367564],
            [-0.510013144994374, 52.947551536238677],
            [-0.511317272525065, 52.95053370261175],
            [-0.512190639106527, 52.95162884054114],
            [-0.512621273964074, 52.951572198882609],
            [-0.515218165251327, 52.957499449496204],
            [-0.517852371284319, 52.960266199442152],
            [-0.521156427702641, 52.959076614645831],
            [-0.522507669084278, 52.95987914796077],
            [-0.523794344007539, 52.95842616126734],
            [-0.524740844316363, 52.958698623487585],
            [-0.526718143467492, 52.955713281701065],
            [-0.529907467225966, 52.95626520257531],
            [-0.533817512345856, 52.955097145788692],
            [-0.540507775829957, 52.954334420490241],
            [-0.545031229351434, 52.954656881623855],
            [-0.550008308736072, 52.95414773392708],
            [-0.554105134870388, 52.95440890603593],
            [-0.543162642972152, 52.971720416649745],
            [-0.540393824103122, 52.967311915371965],
            [-0.526179520143485, 52.969365164103408],
            [-0.528464588975716, 52.971184284066155],
            [-0.53106546604831, 52.975729436938863],
            [-0.526754756029366, 52.976928405336629],
            [-0.52684774080506, 52.980806949219321],
            [-0.52606338931328, 52.982101678127286],
            [-0.528311201783208, 52.983677601891252],
            [-0.525576437276902, 52.985291587215833],
            [-0.526775765974884, 52.986425708954414],
            [-0.523548227841015, 52.988154007470314],
            [-0.526097553065318, 52.989632127053071],
            [-0.531948519030625, 52.989794410902086],
            [-0.528920702601464, 53.010633516226619],
            [-0.527855995964935, 53.017699035644533],
            [-0.526408029998318, 53.026768145574586],
            [-0.522819475635118, 53.049619190290421],
            [-0.535654815673615, 53.049732011622673],
            [-0.563966757442633, 53.049494559374324],
            [-0.565637467733131, 53.049275582443016],
            [-0.5736008803729, 53.049596967036912],
            [-0.574191659153112, 53.049235464132352],
            [-0.574097767016181, 53.046972490005089],
            [-0.57984182024608, 53.047087940945381],
            [-0.580260228274709, 53.046113040308704],
            [-0.584531955925174, 53.046224171263937],
            [-0.584944432671431, 53.045654626386366],
            [-0.589416207606142, 53.046582441371775],
            [-0.589359899304067, 53.047576050671495],
            [-0.59133044330935, 53.047763013361148],
            [-0.592080615193113, 53.047311613099168],
            [-0.593355781882341, 53.047412998694284],
            [-0.593348693654297, 53.048043102698777],
            [-0.596499437868719, 53.048134251102816],
            [-0.597658530463368, 53.049376828983881],
            [-0.601779732737571, 53.049540402155436],
            [-0.601762399637527, 53.05109004475532],
            [-0.603797607868108, 53.051129227479592],
            [-0.604147136091796, 53.052132097950668],
            [-0.637350022760112, 53.054657454759493],
            [-0.637089610227254, 53.053784260988067],
            [-0.637422118928219, 53.053317903428145],
            [-0.635959205828789, 53.052633200331456],
            [-0.636013567843346, 53.052329069690366],
            [-0.644146748270075, 53.052883203238103],
            [-0.644292679161102, 53.052559436028247],
            [-0.648636599694553, 53.0531536317283],
            [-0.648212616334197, 53.053734049960191],
            [-0.66200208258618, 53.054974975190383],
            [-0.661408413040364, 53.057088082862563],
            [-0.667384372384509, 53.057474295730742],
            [-0.667338569392502, 53.057749768067879],
            [-0.670147274245164, 53.057830634929289],
            [-0.670449096012384, 53.058108194949028],
            [-0.675795593933992, 53.058250519050461],
            [-0.68094285877826, 53.057979563409042],
            [-0.681458381677243, 53.058884250366916],
            [-0.684845716481954, 53.058783294537875],
            [-0.68751513663432, 53.058933230137043],
            [-0.687950439343815, 53.059192439918981],
            [-0.690269827686681, 53.059027409050422],
            [-0.689385373450383, 53.059308937967288],
            [-0.689893025682646, 53.05974154008841],
            [-0.692787086829978, 53.05937422413394],
            [-0.69316719794653, 53.059042183645417],
            [-0.695201450882861, 53.059127435045362],
            [-0.695234473308955, 53.057737986378804],
            [-0.693749411820156, 53.057454698547552],
            [-0.693513592083471, 53.0567661936497],
            [-0.698294599064555, 53.053290125307392],
            [-0.699364627883573, 53.052983590193904],
            [-0.702459582086519, 53.052963442097663],
            [-0.702710709495747, 53.05195393768917],
            [-0.703549151436815, 53.052153658220618],
            [-0.70593394602282, 53.051981844648992],
            [-0.713928350745715, 53.057022748726716],
            [-0.715513082997956, 53.058825220517967],
            [-0.716208087984799, 53.059037685805002],
            [-0.71367260210605, 53.060299427572851],
            [-0.712944055989983, 53.061061967207728],
            [-0.713192433014705, 53.061941148916894],
            [-0.713853063325151, 53.062306979768863],
            [-0.714076946275607, 53.063658752538032],
            [-0.715222602744377, 53.063774508418973],
            [-0.715944662512072, 53.064384611170063],
            [-0.715063139773331, 53.065372052898404],
            [-0.714411793541879, 53.065293999647622],
            [-0.714084013068233, 53.065576330387074],
            [-0.71349132829139, 53.065332596661911],
            [-0.713470683943377, 53.066025478727006],
            [-0.712101647408233, 53.065841664231456],
            [-0.711047734190907, 53.066200629142024],
            [-0.707833858064845, 53.065375581320772],
            [-0.707184816142855, 53.065569901320195],
            [-0.705702167245985, 53.065393768358824],
            [-0.704268459174373, 53.065029366010009],
            [-0.704280299184185, 53.064336388874786],
            [-0.702766011815739, 53.064271346869539],
            [-0.701543284892835, 53.063642216765153],
            [-0.70050245110263, 53.063813346366501],
            [-0.698482044532016, 53.065043532578855],
            [-0.697219492862405, 53.064993758594916],
            [-0.695679217057163, 53.065739213760537],
            [-0.695136383426582, 53.066269049520194],
            [-0.695304622600601, 53.067023332723295],
            [-0.697591620110834, 53.066015466815841],
            [-0.698810149240431, 53.067725153793006],
            [-0.69838996441278, 53.070919993555677],
            [-0.697437663797651, 53.071662911204683],
            [-0.698070151442154, 53.072220001603249],
            [-0.697719265113386, 53.072768129960721],
            [-0.698573221647616, 53.073555079983954],
            [-0.697948755226716, 53.074661173909462],
            [-0.698759333828873, 53.074660150493983],
            [-0.698486340243997, 53.075446460451865],
            [-0.702764720350429, 53.076633063106549],
            [-0.707480974388112, 53.07870885108855],
            [-0.710362239324146, 53.079356797780363],
            [-0.70816302740497, 53.082405618938665],
            [-0.706153295802536, 53.083357370829873],
            [-0.709604950600215, 53.085211653691111],
            [-0.709421278258441, 53.08596119849598],
            [-0.715394382313192, 53.086490578194201],
            [-0.715396410328402, 53.087174713167357],
            [-0.726866230420534, 53.094227162251123],
            [-0.728978715946033, 53.096124974424612],
            [-0.729589488647708, 53.097799969677119],
            [-0.721254172674906, 53.104247087077304],
            [-0.71837414316195, 53.110330861654553],
            [-0.716758836804279, 53.116163907098965],
            [-0.71688592912863, 53.119423112641208],
            [-0.718563263567012, 53.119548152454911],
            [-0.720840261994058, 53.12086624021903],
            [-0.727261583189421, 53.122021892365929],
            [-0.728158030634514, 53.123192027626743],
            [-0.731095166506852, 53.12398208459922],
            [-0.731109064947313, 53.126351888304193],
            [-0.732018377300699, 53.126685199484498],
            [-0.732410588593197, 53.12778071076049],
            [-0.728086787842192, 53.130364060020092],
            [-0.724003208687911, 53.133398408007942],
            [-0.71893797264828, 53.135744246717593],
            [-0.715378317437829, 53.136861962770311],
            [-0.717158592475069, 53.13892358663751],
            [-0.718675911968027, 53.141990085226404],
            [-0.721051128424752, 53.144269301513518],
            [-0.721212117050563, 53.148161715201745],
            [-0.722066068677255, 53.149717984562457],
            [-0.722124860152793, 53.151918360783647],
            [-0.723234785633578, 53.155082900645048],
            [-0.716030324754158, 53.170391072257686],
            [-0.718724528799889, 53.174916643178236],
            [-0.718662220715167, 53.177058177469213],
            [-0.718162234139852, 53.177266749852294],
            [-0.725905014084261, 53.178443827343742],
            [-0.729713605135506, 53.178225597127032],
            [-0.730268014653293, 53.180074356783336],
            [-0.737729680078165, 53.179284357534932],
            [-0.752221922914164, 53.181032647921754],
            [-0.764140157788613, 53.181522738166116],
            [-0.762549551578388, 53.185731281770408],
            [-0.762169649716645, 53.188218317723965],
            [-0.753768347715604, 53.188135240808904],
            [-0.742486625599518, 53.198416641088322],
            [-0.730570237475156, 53.210735326298199],
            [-0.724414224369733, 53.209626819775806],
            [-0.723759814174505, 53.210081872799094],
            [-0.722253434530408, 53.209778968111195],
            [-0.72246065192528, 53.209407227354255],
            [-0.720313565233639, 53.209300590424348],
            [-0.714754108115682, 53.207586701309424],
            [-0.709011813452255, 53.212270187152185],
            [-0.708260383949111, 53.212209906310548],
            [-0.697617460895884, 53.22021608453754],
            [-0.686876171732754, 53.219945411790647],
            [-0.686684238482831, 53.219609786809009],
            [-0.675873261529919, 53.21762305370742],
            [-0.676183357307528, 53.218203624852478],
            [-0.674977212414564, 53.220354896021057],
            [-0.67473634603769, 53.222578019843219],
            [-0.673146737828566, 53.223753242510021],
            [-0.672974112023097, 53.2244435125819],
            [-0.671478838594559, 53.225906528563542],
            [-0.671021357665089, 53.227328961846453],
            [-0.671308538149454, 53.228020759072855],
            [-0.670183493733118, 53.228504433732951],
            [-0.670144406721557, 53.229282488384378],
            [-0.669062188262064, 53.229977883601954],
            [-0.668919711441747, 53.231142230746869],
            [-0.666509989190094, 53.233430094589082],
            [-0.666407111459934, 53.235294264176304],
            [-0.667153751741028, 53.23648473281883],
            [-0.667115299727484, 53.238783814626309],
            [-0.666577942518685, 53.239681249082061],
            [-0.665517467258999, 53.240678902525239],
            [-0.653647808561244, 53.242351435145466],
            [-0.652926315266578, 53.242707359670732],
            [-0.648689522480812, 53.243288677011726],
            [-0.647831506574709, 53.244075418443906],
            [-0.646441509842326, 53.247757026742974],
            [-0.647287864350767, 53.249101572579399],
            [-0.648377409496025, 53.253146614945315],
            [-0.638439278569289, 53.25419048851456],
            [-0.631515617666942, 53.255267273157948],
            [-0.631791887204472, 53.256380647149506],
            [-0.622481193561606, 53.258109101356112],
            [-0.620901728036339, 53.257018394866002],
            [-0.619466547121862, 53.256570289884934],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000140",
        LAD13CDO: "32UF",
        LAD13NM: "South Holland",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [0.234324148098973, 52.823309524184339],
              [0.238681079959225, 52.822020777161192],
              [0.24481057755441, 52.821954431152705],
              [0.24523520945357, 52.822158591795599],
              [0.245969301067443, 52.821081928522545],
              [0.247260595764942, 52.820449668473408],
              [0.250117875425228, 52.819649242525969],
              [0.252576688584142, 52.819324759088005],
              [0.252984875988597, 52.818264127064907],
              [0.253416271523412, 52.818455542277313],
              [0.253648808232645, 52.818102263044956],
              [0.254265491937563, 52.818031206044886],
              [0.254777429692121, 52.817312963434276],
              [0.256638949664306, 52.816806429720629],
              [0.257643142392123, 52.816968052689823],
              [0.258052027491617, 52.81681101620255],
              [0.25778296839455, 52.81605816788683],
              [0.259324286549027, 52.816132229328396],
              [0.26072009916391, 52.815261357383434],
              [0.262243903071922, 52.81565580571975],
              [0.262393149277633, 52.815443462108121],
              [0.263668850749836, 52.815546807873723],
              [0.264141928392101, 52.815883048656495],
              [0.268739897418378, 52.815805111240962],
              [0.263856389158514, 52.806020503634059],
              [0.26400938152586, 52.805424155448449],
              [0.262689898243845, 52.803586321134681],
              [0.262237393690475, 52.8036435037478],
              [0.253163701362657, 52.797334323598697],
              [0.2608901742624, 52.796035531409217],
              [0.251983065335659, 52.792542778608812],
              [0.24506945630683, 52.784503396909408],
              [0.272193493415003, 52.772785436512741],
              [0.269822711878973, 52.772013514451956],
              [0.265684122693289, 52.771570247632553],
              [0.25396486185434, 52.767126122614123],
              [0.25165969381498, 52.767096181311409],
              [0.24826158732322, 52.765130397153925],
              [0.24393783795649, 52.763980468821899],
              [0.233148435288909, 52.76024501561092],
              [0.227800083565871, 52.757151882745042],
              [0.217539470083081, 52.753233359008242],
              [0.211962705777223, 52.749628591725362],
              [0.196749244243136, 52.742234623022668],
              [0.194867295489466, 52.738777247367047],
              [0.19079927825238, 52.739454900761999],
              [0.192263564938008, 52.738316509478622],
              [0.186308473244142, 52.735328962021278],
              [0.17204233835352, 52.737863266725505],
              [0.19176052308676, 52.761736454553237],
              [0.195381262503692, 52.765591483031088],
              [0.204357969141646, 52.781548153346357],
              [0.20726059098197, 52.787860001101983],
              [0.2076053868137, 52.787874264828623],
              [0.207322299227942, 52.788024292318603],
              [0.211767089397938, 52.804306514190174],
              [0.21679921481351, 52.820861709771705],
              [0.219230507726045, 52.821593108274669],
              [0.22124789655971, 52.82159941893007],
              [0.222491678618247, 52.822966177951997],
              [0.224624402521651, 52.822265356849059],
              [0.227033548368273, 52.82261758083451],
              [0.227636977357459, 52.82324283570658],
              [0.228980405344471, 52.823796645753973],
              [0.234324148098973, 52.823309524184339],
            ],
          ],
          [
            [
              [0.215348041074495, 52.825099409957801],
              [0.214805144797834, 52.824085441708],
              [0.214664753168847, 52.824506154561369],
              [0.213403817543057, 52.825192334823754],
              [0.214596865412554, 52.825652006159999],
              [0.213763597078835, 52.826517225621124],
              [0.215396928127098, 52.827723039615037],
              [0.215348041074495, 52.825099409957801],
            ],
          ],
          [
            [
              [-0.214207018633233, 52.923253342005978],
              [-0.213874076422318, 52.923246538974432],
              [-0.213916643145058, 52.923658043398241],
              [-0.208765505005175, 52.922529505480391],
              [-0.201979606850647, 52.920652278024306],
              [-0.198425140093404, 52.918234828381735],
              [-0.192488085618379, 52.917001952453454],
              [-0.191676249922929, 52.915924222234587],
              [-0.192298865238862, 52.915045435622929],
              [-0.192090598479538, 52.914617914140557],
              [-0.190677971698309, 52.914895791528402],
              [-0.19081671406302, 52.915278203254488],
              [-0.189846853277972, 52.915352435075818],
              [-0.189705583969758, 52.915744066901304],
              [-0.188484948258921, 52.915732652268098],
              [-0.187935104641638, 52.916647592398242],
              [-0.186058533758202, 52.916422061208479],
              [-0.186522495522392, 52.915605613252545],
              [-0.185053101689502, 52.914642764788859],
              [-0.18164721166548, 52.91489006538302],
              [-0.179905204503979, 52.915359665488118],
              [-0.178216528459056, 52.916790246513656],
              [-0.178466603637751, 52.918167833605018],
              [-0.177879550733146, 52.919006637477494],
              [-0.176266153350814, 52.918393001122638],
              [-0.173407671308469, 52.918065883218411],
              [-0.171539303161056, 52.917539964751271],
              [-0.171703059975703, 52.916194803701025],
              [-0.169730523174398, 52.914332157318867],
              [-0.168138300310459, 52.914245580355548],
              [-0.16737862704145, 52.91229999669946],
              [-0.166104400347826, 52.912045678720219],
              [-0.161323050339247, 52.912640665348114],
              [-0.160223622598019, 52.911385643193803],
              [-0.15941115509027, 52.911606811775485],
              [-0.159421454562167, 52.9122057426423],
              [-0.156595441580915, 52.912072016335188],
              [-0.156365282490234, 52.911713317200388],
              [-0.156864043288824, 52.910929887328209],
              [-0.15518572842096, 52.909512993833346],
              [-0.154280053913576, 52.907903997569491],
              [-0.150615296260401, 52.905927508448727],
              [-0.150688619485708, 52.904170991407895],
              [-0.149915820987065, 52.902695289963319],
              [-0.150914060786783, 52.901809977604984],
              [-0.150209120881007, 52.900277793936233],
              [-0.149092489491546, 52.900278379726487],
              [-0.148593964703475, 52.90063742617545],
              [-0.14749269467472, 52.899510816136186],
              [-0.146903392021275, 52.899383846680905],
              [-0.146595234050505, 52.898626525671617],
              [-0.146772979876897, 52.898398240861013],
              [-0.148509242360148, 52.898753475693596],
              [-0.148944339175185, 52.897928627490586],
              [-0.147786456834409, 52.897363948315977],
              [-0.148751421919154, 52.897222556307405],
              [-0.149520810574692, 52.896262662668335],
              [-0.151038322763912, 52.896062430302116],
              [-0.151621703187405, 52.89534956600319],
              [-0.151644694686678, 52.894215310860147],
              [-0.147368161451982, 52.891903726473402],
              [-0.146979396267843, 52.891190100899706],
              [-0.145738194946175, 52.891258836663184],
              [-0.144744797406725, 52.890746142107332],
              [-0.144750531893066, 52.88956756275541],
              [-0.143930909438143, 52.889163664644848],
              [-0.145237834461719, 52.888703084898459],
              [-0.145392637993489, 52.888140892050174],
              [-0.144155627563826, 52.887521894234617],
              [-0.145263047414515, 52.887033022925941],
              [-0.143473052089848, 52.886311877677862],
              [-0.142661557928651, 52.884884964729423],
              [-0.14194756017528, 52.884933133933202],
              [-0.140982865226487, 52.88444872614],
              [-0.13936868913343, 52.884797456892848],
              [-0.138215620409848, 52.884651718679272],
              [-0.137774585143609, 52.885578929702994],
              [-0.136503542233612, 52.885757684754779],
              [-0.135200378391928, 52.885437841040073],
              [-0.135680877600813, 52.885116328107856],
              [-0.135573758019745, 52.884425964200311],
              [-0.135187273537042, 52.884357860584792],
              [-0.133577847927495, 52.885598458164644],
              [-0.132275824928892, 52.884491919597032],
              [-0.132203578345745, 52.883786816586834],
              [-0.131152976845891, 52.883505963275347],
              [-0.129178733397798, 52.882102007338837],
              [-0.129201320823873, 52.881749929990377],
              [-0.126995982705614, 52.880573355185689],
              [-0.128190392288746, 52.880135463120652],
              [-0.127601943618693, 52.879789036991077],
              [-0.126833159095263, 52.87997200978954],
              [-0.125491227495036, 52.879729664680376],
              [-0.126097559550621, 52.880591547784313],
              [-0.124287411060306, 52.880936081807334],
              [-0.123732535805841, 52.881843463449542],
              [-0.122496441142974, 52.882384044608351],
              [-0.122043521961329, 52.882478477416548],
              [-0.121586691699029, 52.882147588782018],
              [-0.120004413192407, 52.882616137017777],
              [-0.118593297074007, 52.882357334903411],
              [-0.116609604151413, 52.881524822886405],
              [-0.117839305926329, 52.88103005367828],
              [-0.117401749013254, 52.880255315369851],
              [-0.115067712618976, 52.879279664641587],
              [-0.114343450871424, 52.879565757152065],
              [-0.114130777581241, 52.878918648102307],
              [-0.112401144449035, 52.877956137191561],
              [-0.110024488485361, 52.877931820372304],
              [-0.107474645512425, 52.876765576877425],
              [-0.103802454436874, 52.876906587380532],
              [-0.103232252522637, 52.876624163883911],
              [-0.101461702543559, 52.87429245422404],
              [-0.101028010498691, 52.874185722591626],
              [-0.100583770084253, 52.874660518449829],
              [-0.100048619809892, 52.873883252667738],
              [-0.099100999583965, 52.87346620225226],
              [-0.09887524887372, 52.872852119748103],
              [-0.099752509499916, 52.872770860673143],
              [-0.100369231518162, 52.87164700189328],
              [-0.100964310788022, 52.87176620714007],
              [-0.100515151157559, 52.870789825618459],
              [-0.099941712677405, 52.870583754523018],
              [-0.099019894938095, 52.871243302433996],
              [-0.098834060009071, 52.871891253734709],
              [-0.09825011296024, 52.871959222624838],
              [-0.096906321835309, 52.871191464467906],
              [-0.097431139943953, 52.870267539039531],
              [-0.096261313691155, 52.869803748935659],
              [-0.096428868269992, 52.869234626401486],
              [-0.094566271936696, 52.866645095691815],
              [-0.092835742213749, 52.866468964735056],
              [-0.091922868991743, 52.866991942869625],
              [-0.091881622492194, 52.867356303044829],
              [-0.093282442652752, 52.867598180061698],
              [-0.092848436441155, 52.868144138525885],
              [-0.091000270110459, 52.867735929323658],
              [-0.089484541963147, 52.867776283097044],
              [-0.088984427618713, 52.867289928650877],
              [-0.090204717653793, 52.866736854180942],
              [-0.090002251872299, 52.8663065382719],
              [-0.088891127987471, 52.865863397856906],
              [-0.087298281207283, 52.865868317210577],
              [-0.084803655771223, 52.864523549497534],
              [-0.0820621494788, 52.865344203968569],
              [-0.080485468296129, 52.866935258078485],
              [-0.069896486159177, 52.864366663441345],
              [-0.082198991107079, 52.856177660501437],
              [-0.089294355137555, 52.854048877932705],
              [-0.090868007391778, 52.853320771011362],
              [-0.095969205031438, 52.84888844925284],
              [-0.097141514848735, 52.847448949213572],
              [-0.101060126761809, 52.845892466840681],
              [-0.097289553245441, 52.847187893569348],
              [-0.097313089523303, 52.846785485900611],
              [-0.097947972136288, 52.845612479411344],
              [-0.097115041430913, 52.846254555245245],
              [-0.097124437902577, 52.844239881659824],
              [-0.098163120821763, 52.842932191494739],
              [-0.099515320265932, 52.841482064180418],
              [-0.106196485067713, 52.834158001213524],
              [-0.110949913797704, 52.828859085131249],
              [-0.113610428428092, 52.826483790209288],
              [-0.11770794425371, 52.821760377900723],
              [-0.120458838774886, 52.817959535085052],
              [-0.123892332129296, 52.814870700533888],
              [-0.126168655098303, 52.811075710285969],
              [-0.135183352433745, 52.799687090186652],
              [-0.134056912236929, 52.800670051824142],
              [-0.13354717182253, 52.79965237386812],
              [-0.133067234143626, 52.799324752868436],
              [-0.132647376371599, 52.799533924110875],
              [-0.13339743531693, 52.80041243389978],
              [-0.133191171485709, 52.801314557257093],
              [-0.125660108429278, 52.810894157729734],
              [-0.123993961488435, 52.814061342247548],
              [-0.11927640179417, 52.818639383206971],
              [-0.117293492296892, 52.821597365079903],
              [-0.112945361718317, 52.826417475316141],
              [-0.112639680441975, 52.826723694402212],
              [-0.111031872069837, 52.827996379369388],
              [-0.0967407721285, 52.843675405952425],
              [-0.09630342352341, 52.845051168737875],
              [-0.096432531566423, 52.847531086198963],
              [-0.095204217137299, 52.848892360464184],
              [-0.090240550231465, 52.85303196619985],
              [-0.084639983873038, 52.854848676248977],
              [-0.081546928640389, 52.855975622475391],
              [-0.068959967992786, 52.864145547187015],
              [-0.065985005509593, 52.863461477832246],
              [-0.065641299000714, 52.862872378371534],
              [-0.059252982234557, 52.863646522920575],
              [-0.040359118391803, 52.86842766002065],
              [-0.034960711826594, 52.870381146549271],
              [-0.036994738065337, 52.872390068061939],
              [-0.024077266051171, 52.877076694428069],
              [-0.002020715166781, 52.88675036349737],
              [-0.000763445496517, 52.887262359168581],
              [-0.000105790329273, 52.886986056291732],
              [-0.000185322983092, 52.887486386225106],
              [0.005547239326055, 52.890017729356067],
              [0.006630449757821, 52.889750385187561],
              [0.006290579410658, 52.890341430384595],
              [0.008816476443014, 52.891472932672272],
              [0.009751175797692, 52.891369008088304],
              [0.009054929788734, 52.891614550338986],
              [0.010925120949857, 52.892451411223142],
              [0.027476702567591, 52.89814800640309],
              [0.032511929410151, 52.900169396877615],
              [0.039372494277597, 52.903782097720878],
              [0.058977955034447, 52.916312482895542],
              [0.059460461483263, 52.916073951885444],
              [0.061679992821444, 52.91714492299981],
              [0.062276257135257, 52.916921488701369],
              [0.064208131515353, 52.918040563228537],
              [0.065167320503602, 52.918160552282146],
              [0.065244772125065, 52.917881387548363],
              [0.065689382929869, 52.918159566511754],
              [0.066138073134004, 52.917929688159447],
              [0.066089871135613, 52.918416933180367],
              [0.067441698063779, 52.918628973690694],
              [0.06745090759984, 52.918385160506688],
              [0.068540434754005, 52.918711442322881],
              [0.068503398058248, 52.918371332798785],
              [0.069065450371089, 52.918335470457514],
              [0.06787479285841, 52.917041736799639],
              [0.068255693346315, 52.917044096092148],
              [0.068115109061635, 52.916747147383596],
              [0.069378280654071, 52.916878892031129],
              [0.068987372804803, 52.91669779202244],
              [0.069246610566903, 52.91648828407795],
              [0.068234312717093, 52.916002424734806],
              [0.068515279449418, 52.915655878533727],
              [0.067083134068936, 52.915254641791691],
              [0.067973278562041, 52.914956834005842],
              [0.067904221140232, 52.914693704182035],
              [0.065448811174376, 52.912073325621755],
              [0.067394139722745, 52.911697828289064],
              [0.067514603575427, 52.911260572355296],
              [0.066412890143, 52.909486960760184],
              [0.068731010096517, 52.908635629332927],
              [0.069147107381473, 52.908192322741691],
              [0.066504126675246, 52.907248439072255],
              [0.06652690571567, 52.906820975205498],
              [0.062414282689805, 52.905270503281919],
              [0.058316047589293, 52.904581867371284],
              [0.05619825726551, 52.903696984863807],
              [0.056365648515166, 52.903369515835344],
              [0.058588638880579, 52.904372150751819],
              [0.061262073839207, 52.904054258028921],
              [0.065079138838716, 52.904584047051017],
              [0.070231943405162, 52.904870161545524],
              [0.073367006886834, 52.906061613688038],
              [0.073295818339319, 52.905473951949418],
              [0.073841924037083, 52.905387994927345],
              [0.07353603555061, 52.905179352031801],
              [0.074180441452503, 52.90506560356453],
              [0.071929567079055, 52.903175442527704],
              [0.073349799617845, 52.903646056849681],
              [0.074164803643109, 52.90355540363246],
              [0.073780829404249, 52.902614466643925],
              [0.074262553744631, 52.90295580080182],
              [0.075080299572511, 52.902860597835307],
              [0.0758768285768, 52.903627988798711],
              [0.077101610971663, 52.903555326923126],
              [0.077802744471235, 52.903167242496778],
              [0.077544245523497, 52.902488454825559],
              [0.077991431190192, 52.902387125924896],
              [0.076809657502186, 52.901306409474628],
              [0.079796320639369, 52.902039951541433],
              [0.081205048509261, 52.900811388891384],
              [0.081779803203203, 52.901014400543154],
              [0.081990521001618, 52.900106219395944],
              [0.082547261566376, 52.899933722257153],
              [0.08253439711745, 52.899448437620812],
              [0.078709198502911, 52.898806068049559],
              [0.076979606250029, 52.898051421240631],
              [0.085900814552894, 52.899823620814786],
              [0.086192367228831, 52.898247783124319],
              [0.085244676195192, 52.897616180300311],
              [0.086228500346556, 52.897081023682986],
              [0.089048665632561, 52.897446830254324],
              [0.089047333786809, 52.896831873149544],
              [0.088436558580097, 52.896466794100746],
              [0.088930564457741, 52.896475188480849],
              [0.089143575967993, 52.895618200783687],
              [0.088370943653668, 52.895102223535787],
              [0.090173393454937, 52.895608171925979],
              [0.090323453576982, 52.895204534446513],
              [0.089697290720296, 52.89479747528555],
              [0.091025756155812, 52.895123839422361],
              [0.09156885588449, 52.894670122520822],
              [0.092265715603218, 52.895093908311239],
              [0.092714315120778, 52.8941293666402],
              [0.093861287327599, 52.894792461625052],
              [0.09536742078337, 52.894116749416732],
              [0.096498143100667, 52.894041948230765],
              [0.098055328161619, 52.894547611514426],
              [0.09922623105691, 52.894535011252181],
              [0.099648963691699, 52.89393053790652],
              [0.100830812638029, 52.894421222233007],
              [0.101328289723819, 52.893917917448888],
              [0.102609585153054, 52.893852081730458],
              [0.103070649008449, 52.894347411744569],
              [0.104136796417257, 52.89447417993415],
              [0.104727265513109, 52.893865816736941],
              [0.105969229409161, 52.894430009948017],
              [0.107706410938779, 52.894445044485337],
              [0.116150958531862, 52.893654708320817],
              [0.11676441823351, 52.893216702958092],
              [0.117191905260522, 52.893294496683694],
              [0.117690279489971, 52.892508788477812],
              [0.119299771320933, 52.892223832262673],
              [0.119472421206802, 52.891435836803822],
              [0.120127721163161, 52.891551811333265],
              [0.120703689515596, 52.891199868802069],
              [0.12075488239664, 52.891395857481072],
              [0.1213597466858, 52.890752986272155],
              [0.123312682982121, 52.891170330263513],
              [0.126730930175057, 52.890332337626894],
              [0.127720084256072, 52.890374858056077],
              [0.130979076577244, 52.888899434634972],
              [0.131950602146596, 52.888855922134994],
              [0.133053372338117, 52.888034118804448],
              [0.133864347954555, 52.888172395763029],
              [0.136925111649621, 52.886965603212985],
              [0.137314098298097, 52.88713752391422],
              [0.139701531590846, 52.886326693275571],
              [0.141241650680718, 52.885214611536099],
              [0.14191993095961, 52.885526055875204],
              [0.142113384345361, 52.885138648612539],
              [0.143186343166265, 52.88543577224916],
              [0.145002461082619, 52.885069411230241],
              [0.145727528288715, 52.883468491684766],
              [0.14685156631221, 52.882483434014553],
              [0.146797654833509, 52.881963826715683],
              [0.144281708094274, 52.88103645699438],
              [0.141904883170055, 52.87946186680751],
              [0.135847074263193, 52.87459085201894],
              [0.137907901425424, 52.875793627269651],
              [0.144637244933305, 52.880952712734469],
              [0.150274836202169, 52.882370241089326],
              [0.150696207004569, 52.882055115306812],
              [0.150099183274088, 52.881673917485514],
              [0.150184142611982, 52.88092791917876],
              [0.151306403508397, 52.879850241874436],
              [0.151678681423716, 52.879954985361792],
              [0.15100142325966, 52.881375256918282],
              [0.152634793158334, 52.881584818346788],
              [0.154790118129347, 52.881230137455418],
              [0.157264202877085, 52.880381410873504],
              [0.15935970401292, 52.880290272515431],
              [0.161124094253982, 52.879757368391815],
              [0.161291451944474, 52.879446827178],
              [0.163842411038686, 52.87910186109702],
              [0.166149770353256, 52.87840253980935],
              [0.16838149455332, 52.877320634590212],
              [0.170855494159605, 52.8771045953157],
              [0.17116313942048, 52.87651005290094],
              [0.172781208553964, 52.875731493977071],
              [0.173472777024724, 52.874999547538003],
              [0.175452238472144, 52.874228720664981],
              [0.175201924278346, 52.874037300739793],
              [0.176319231994613, 52.87409324965251],
              [0.178879951750238, 52.870572114515753],
              [0.178534481295367, 52.867325466220976],
              [0.179760022810519, 52.865868885866966],
              [0.180430157767511, 52.86601841995666],
              [0.179622065348722, 52.865073006587615],
              [0.17949679509447, 52.864233736745959],
              [0.180769429450832, 52.864192383257667],
              [0.181510846960603, 52.862683535568031],
              [0.181033338885178, 52.862374922397031],
              [0.181041073622777, 52.861344396129617],
              [0.182592399844199, 52.859250619099562],
              [0.183232414435949, 52.85918670137638],
              [0.18402001615878, 52.858157113336098],
              [0.185278268419422, 52.857448831441509],
              [0.185233919688532, 52.855679290518353],
              [0.186242076678738, 52.855316365905651],
              [0.186063558818613, 52.854926740266542],
              [0.187265691732473, 52.85458181303342],
              [0.188514100825168, 52.853444798333001],
              [0.18912502016294, 52.853394871193821],
              [0.189842961454325, 52.853818598978862],
              [0.191682494739399, 52.852506104987924],
              [0.192793733518335, 52.85230485941954],
              [0.192656539023199, 52.851527859558921],
              [0.195481896404502, 52.849623452968117],
              [0.195714929489773, 52.848296540378833],
              [0.196637068157426, 52.84758805667758],
              [0.196075981387604, 52.846505111776239],
              [0.197009991149595, 52.846503111922644],
              [0.197329175694994, 52.844236819795626],
              [0.198144435012886, 52.843060961712254],
              [0.200343233732302, 52.841672439312269],
              [0.20124922010437, 52.839415932729487],
              [0.201691943896491, 52.839215309017128],
              [0.201260678748568, 52.838321491339187],
              [0.202355101977657, 52.838118666220488],
              [0.205993370870995, 52.834291871489597],
              [0.205927415245866, 52.833634940698509],
              [0.207721302012457, 52.833702284679802],
              [0.208832267215752, 52.831868083653156],
              [0.213247981532338, 52.829222414796355],
              [0.21425440586844, 52.828929407942901],
              [0.213767758723761, 52.828584232609686],
              [0.211258118333045, 52.829238829557497],
              [0.204985563771225, 52.829520081715629],
              [0.203545555316735, 52.829225842560419],
              [0.201593273459081, 52.829691856978627],
              [0.200281781019232, 52.829414075599146],
              [0.197496833270514, 52.829677870334208],
              [0.200064054699357, 52.829100722527485],
              [0.201540010420852, 52.829431200846322],
              [0.203754814746217, 52.828931540522753],
              [0.2052510356261, 52.829222035152675],
              [0.211661425324296, 52.828937300733664],
              [0.213620398586763, 52.828313648012404],
              [0.214404493291101, 52.82858224317895],
              [0.215066201907886, 52.828458402508694],
              [0.213169009872581, 52.826530114413359],
              [0.213258565971348, 52.826072588570014],
              [0.214090744222496, 52.825682130263857],
              [0.21318860608479, 52.825134309274866],
              [0.214506554950068, 52.824430883129992],
              [0.214683259408316, 52.823206575000945],
              [0.214006926382357, 52.822223865431987],
              [0.212281579331596, 52.822094201876659],
              [0.209374119437954, 52.820049816726815],
              [0.208952709891072, 52.820139482521412],
              [0.209430655507582, 52.819496702124027],
              [0.209649315957084, 52.819272345196524],
              [0.209582394295829, 52.820000982453188],
              [0.211800021592951, 52.82170127041006],
              [0.214101359226681, 52.821979339963619],
              [0.214762305498477, 52.822834660569207],
              [0.214891754742682, 52.822607463396466],
              [0.205838869307263, 52.787548375061334],
              [0.199836466352599, 52.775045985286951],
              [0.19854823747528, 52.773019859470537],
              [0.197540527120279, 52.772793072639466],
              [0.198178347348193, 52.772181535031898],
              [0.195464700561256, 52.767514972241571],
              [0.187970278845974, 52.758596555297366],
              [0.186556907722385, 52.758222511278703],
              [0.187430312494047, 52.758054451652647],
              [0.186957331530414, 52.757399618431961],
              [0.174514324208062, 52.742258751952882],
              [0.172714077503534, 52.741331484554465],
              [0.1727507217382, 52.741085351129172],
              [0.173635823213619, 52.741158142531525],
              [0.17121188662737, 52.738203062152607],
              [0.170138824207183, 52.738937513872123],
              [0.170166135575445, 52.739874804332956],
              [0.167031701206936, 52.739372874651544],
              [0.152486211171808, 52.738120996998298],
              [0.150815381156541, 52.738725858051474],
              [0.148276904610163, 52.738618127631696],
              [0.147598160475505, 52.73942614836745],
              [0.144112705867726, 52.73900999392859],
              [0.143672976367263, 52.739980906179525],
              [0.140638736796518, 52.739733618333524],
              [0.13942827264593, 52.739186320470822],
              [0.137722306106653, 52.738896102837444],
              [0.132960835350504, 52.739284856156019],
              [0.130944357134621, 52.738368043973111],
              [0.129032797503729, 52.738416768937881],
              [0.119698504716119, 52.732857507754922],
              [0.116782182983955, 52.732707307663077],
              [0.115310003969746, 52.732281324425166],
              [0.114130201096069, 52.731377172874495],
              [0.113671114990027, 52.729970142922838],
              [0.111091230729765, 52.729758959630054],
              [0.104926354699678, 52.730483567207493],
              [0.10252585019544, 52.729844628784008],
              [0.100760410434198, 52.730399209868537],
              [0.098774908880621, 52.7303201853573],
              [0.09457269802946, 52.7284281275411],
              [0.091635159301112, 52.725196411098182],
              [0.088997348260017, 52.723610122070298],
              [0.087534369010146, 52.723550475120192],
              [0.082626585639769, 52.724540401564532],
              [0.076025928324071, 52.724502418679769],
              [0.072575373288999, 52.725531136416059],
              [0.067958811508496, 52.726333738627012],
              [0.063889931035427, 52.727416141372053],
              [0.063172025715959, 52.727110350317567],
              [0.063046040903851, 52.726010230075481],
              [0.062292988175687, 52.725366978940862],
              [0.059224379475873, 52.724703723593016],
              [0.055122503864594, 52.721810524106374],
              [0.04800380430294, 52.718765241081016],
              [0.04624814246799, 52.715358253040229],
              [0.044134707865629, 52.714362533505444],
              [0.045093627134068, 52.711978632726478],
              [0.044827338914778, 52.71084315195715],
              [0.045446113457406, 52.70498734416504],
              [0.045030367031522, 52.70321876872012],
              [0.045219706104653, 52.70141188019533],
              [0.044384010484342, 52.70066384839263],
              [0.045891739009514, 52.697774155888112],
              [0.045243399803366, 52.697170350818283],
              [0.04486718306207, 52.695770627246617],
              [0.04521538838723, 52.693690362043917],
              [0.044555729692286, 52.692462763054671],
              [0.044965246707514, 52.691916229111555],
              [0.048404379180238, 52.690085583064445],
              [0.049322802085791, 52.688685067691146],
              [0.049133185222939, 52.686910793780108],
              [0.049608573913319, 52.686130232467249],
              [0.048690032310046, 52.683481133229371],
              [0.048982107349606, 52.681977256251976],
              [0.047996881851155, 52.681030433061771],
              [0.021483747241839, 52.664868221849026],
              [-0.031287512593998, 52.661513848522304],
              [-0.032119609607987, 52.665341684850034],
              [-0.040325960457982, 52.667123048716384],
              [-0.044941937573333, 52.669171033235735],
              [-0.058039893490924, 52.674072525602803],
              [-0.059505989188227, 52.674667471112379],
              [-0.06380138236644, 52.675205260381119],
              [-0.065461671239875, 52.671976728268284],
              [-0.066989341416796, 52.672015143402859],
              [-0.06968826300779, 52.671386608748271],
              [-0.07202393022759, 52.672053102613248],
              [-0.074627026095219, 52.672054964437251],
              [-0.080330758388574, 52.67052731845515],
              [-0.087853341168325, 52.666761234885058],
              [-0.09044702260257, 52.666666395621945],
              [-0.091483585436748, 52.66694113592478],
              [-0.094338054537994, 52.668160397423122],
              [-0.096698077560785, 52.670040577153323],
              [-0.099450852725008, 52.670373367646704],
              [-0.102211964898918, 52.672187039531245],
              [-0.113011054920142, 52.667956614706945],
              [-0.115526149912813, 52.666457385897068],
              [-0.117430593532816, 52.664745209259863],
              [-0.126550367509743, 52.659976189417456],
              [-0.129835376269233, 52.659192822390153],
              [-0.133039501041433, 52.656938970436883],
              [-0.137032743371621, 52.655521013079067],
              [-0.136856164617485, 52.655188269136538],
              [-0.139517346765813, 52.654842574460865],
              [-0.140268309338774, 52.654252869708571],
              [-0.140149916780534, 52.653454414248152],
              [-0.141077508027959, 52.651488266614486],
              [-0.180034863434977, 52.660541789143586],
              [-0.190432820963095, 52.652451009456023],
              [-0.192882347888933, 52.652419163487579],
              [-0.195580735561715, 52.655355345267374],
              [-0.196492237764749, 52.655480708853851],
              [-0.19983209209487, 52.660844191091044],
              [-0.200605422927421, 52.661420561811674],
              [-0.203088658056498, 52.665085148990173],
              [-0.205533357664524, 52.666812470401403],
              [-0.206051879221739, 52.668053863198857],
              [-0.209667746375492, 52.666970305211471],
              [-0.212515417104075, 52.666659952346187],
              [-0.214039482317126, 52.668271608971743],
              [-0.215623782909234, 52.667863015764532],
              [-0.219924699871929, 52.667711017682628],
              [-0.223221992958437, 52.666742739405237],
              [-0.248105772071812, 52.6932385605338],
              [-0.248769906437796, 52.693023605345388],
              [-0.250435310702424, 52.694449853717671],
              [-0.25577895572596, 52.692591097843746],
              [-0.263703004272509, 52.700979790086691],
              [-0.265025012275117, 52.700858890033814],
              [-0.26719318540273, 52.699066386498643],
              [-0.27801980023032, 52.710739419791665],
              [-0.281289821186866, 52.714243782928406],
              [-0.278332603629071, 52.716480913637774],
              [-0.295288745733511, 52.734223494900824],
              [-0.297311518946296, 52.733589046708708],
              [-0.306691208000578, 52.743416122097869],
              [-0.304629212847821, 52.744022299779346],
              [-0.305289561747829, 52.744719518416552],
              [-0.305999934936541, 52.74466223736183],
              [-0.306821518170643, 52.745252064712894],
              [-0.304520932283232, 52.746010371863058],
              [-0.289366738775945, 52.753867237473806],
              [-0.286714481108364, 52.756090993859431],
              [-0.265888916943427, 52.767785446617452],
              [-0.262703795700926, 52.769081142683852],
              [-0.261572414363427, 52.769983409744654],
              [-0.260618029746914, 52.771353076515481],
              [-0.261782234057059, 52.777294056006362],
              [-0.260616130732661, 52.779619018901265],
              [-0.261067136401201, 52.783629146470638],
              [-0.262183808513088, 52.787050247323968],
              [-0.264120088536884, 52.787433720060584],
              [-0.264032466415357, 52.789485889154186],
              [-0.264855209618788, 52.790239647201737],
              [-0.266051949535726, 52.792814963243764],
              [-0.266812173459093, 52.796487034011328],
              [-0.269330006615826, 52.809917925992075],
              [-0.270859654032298, 52.821173010227405],
              [-0.27209501424542, 52.832567615182555],
              [-0.275043318783492, 52.84084490420171],
              [-0.276301772366725, 52.861666275123291],
              [-0.270896297642495, 52.872085857550935],
              [-0.265870509286484, 52.871171961461101],
              [-0.264767894456327, 52.870170498128495],
              [-0.264675339234009, 52.868655149038652],
              [-0.26078676478226, 52.868109175723859],
              [-0.257658772649842, 52.866677885040531],
              [-0.257256414172252, 52.866745702457067],
              [-0.256562634555802, 52.868338522654028],
              [-0.258439538106304, 52.870219004365723],
              [-0.258579105291719, 52.872298751978342],
              [-0.269527981624444, 52.876935161535876],
              [-0.256504799375069, 52.90586117481255],
              [-0.252432821693612, 52.915333899895188],
              [-0.246714075467521, 52.928809901965828],
              [-0.245623519665108, 52.927927127640224],
              [-0.239350835039213, 52.92624842906676],
              [-0.229981429407885, 52.924969227327892],
              [-0.21938887517402, 52.924182514416856],
              [-0.214207018633233, 52.923253342005978],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000141",
        LAD13CDO: "32UG",
        LAD13NM: "South Kesteven",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.689385373450383, 53.059308937967288],
            [-0.690269827686681, 53.059027409050422],
            [-0.687950439343815, 53.059192439918981],
            [-0.68751513663432, 53.058933230137043],
            [-0.684845716481954, 53.058783294537875],
            [-0.681458381677243, 53.058884250366916],
            [-0.68094285877826, 53.057979563409042],
            [-0.675795593933992, 53.058250519050461],
            [-0.670449096012384, 53.058108194949028],
            [-0.670147274245164, 53.057830634929289],
            [-0.667338569392502, 53.057749768067879],
            [-0.667384372384509, 53.057474295730742],
            [-0.661408413040364, 53.057088082862563],
            [-0.66200208258618, 53.054974975190383],
            [-0.648212616334197, 53.053734049960191],
            [-0.648636599694553, 53.0531536317283],
            [-0.644292679161102, 53.052559436028247],
            [-0.644146748270075, 53.052883203238103],
            [-0.636013567843346, 53.052329069690366],
            [-0.635959205828789, 53.052633200331456],
            [-0.637422118928219, 53.053317903428145],
            [-0.637089610227254, 53.053784260988067],
            [-0.637350022760112, 53.054657454759493],
            [-0.604147136091796, 53.052132097950668],
            [-0.603797607868108, 53.051129227479592],
            [-0.601762399637527, 53.05109004475532],
            [-0.601779732737571, 53.049540402155436],
            [-0.597658530463368, 53.049376828983881],
            [-0.596499437868719, 53.048134251102816],
            [-0.593348693654297, 53.048043102698777],
            [-0.593355781882341, 53.047412998694284],
            [-0.592080615193113, 53.047311613099168],
            [-0.59133044330935, 53.047763013361148],
            [-0.589359899304067, 53.047576050671495],
            [-0.589416207606142, 53.046582441371775],
            [-0.584944432671431, 53.045654626386366],
            [-0.584531955925174, 53.046224171263937],
            [-0.580260228274709, 53.046113040308704],
            [-0.57984182024608, 53.047087940945381],
            [-0.574097767016181, 53.046972490005089],
            [-0.574191659153112, 53.049235464132352],
            [-0.5736008803729, 53.049596967036912],
            [-0.565637467733131, 53.049275582443016],
            [-0.563966757442633, 53.049494559374324],
            [-0.535654815673615, 53.049732011622673],
            [-0.522819475635118, 53.049619190290421],
            [-0.526408029998318, 53.026768145574586],
            [-0.527855995964935, 53.017699035644533],
            [-0.528920702601464, 53.010633516226619],
            [-0.531948519030625, 52.989794410902086],
            [-0.526097553065318, 52.989632127053071],
            [-0.523548227841015, 52.988154007470314],
            [-0.526775765974884, 52.986425708954414],
            [-0.525576437276902, 52.985291587215833],
            [-0.528311201783208, 52.983677601891252],
            [-0.52606338931328, 52.982101678127286],
            [-0.52684774080506, 52.980806949219321],
            [-0.526754756029366, 52.976928405336629],
            [-0.53106546604831, 52.975729436938863],
            [-0.528464588975716, 52.971184284066155],
            [-0.526179520143485, 52.969365164103408],
            [-0.540393824103122, 52.967311915371965],
            [-0.543162642972152, 52.971720416649745],
            [-0.554105134870388, 52.95440890603593],
            [-0.550008308736072, 52.95414773392708],
            [-0.545031229351434, 52.954656881623855],
            [-0.540507775829957, 52.954334420490241],
            [-0.533817512345856, 52.955097145788692],
            [-0.529907467225966, 52.95626520257531],
            [-0.526718143467492, 52.955713281701065],
            [-0.524740844316363, 52.958698623487585],
            [-0.523794344007539, 52.95842616126734],
            [-0.522507669084278, 52.95987914796077],
            [-0.521156427702641, 52.959076614645831],
            [-0.517852371284319, 52.960266199442152],
            [-0.515218165251327, 52.957499449496204],
            [-0.512621273964074, 52.951572198882609],
            [-0.512190639106527, 52.95162884054114],
            [-0.511317272525065, 52.95053370261175],
            [-0.510013144994374, 52.947551536238677],
            [-0.507647761666621, 52.94773492367564],
            [-0.508048247250664, 52.949288941580384],
            [-0.502110607388776, 52.951020365427439],
            [-0.49460193592992, 52.954041461920561],
            [-0.49415988085714, 52.953825494311118],
            [-0.492867144401965, 52.951959848645394],
            [-0.494282796479043, 52.951700896572312],
            [-0.494141542928109, 52.948310742731408],
            [-0.489679012827214, 52.946909199651266],
            [-0.489153225056601, 52.946493467361016],
            [-0.487876372116066, 52.943757722752771],
            [-0.487185859650621, 52.939490320860237],
            [-0.482880471407767, 52.939371606247441],
            [-0.482726162848259, 52.937657018846494],
            [-0.483731376958002, 52.937677040843894],
            [-0.483392587031818, 52.933747630986545],
            [-0.481539270645875, 52.933900170076811],
            [-0.480947099340059, 52.932327420654147],
            [-0.479887870005126, 52.932663591564236],
            [-0.479494629216176, 52.931402639314321],
            [-0.47985606664738, 52.929749483229052],
            [-0.48158886430194, 52.929804901348831],
            [-0.481663997236636, 52.927997945772638],
            [-0.486872789636085, 52.928031124783416],
            [-0.486004049315051, 52.926067402902504],
            [-0.486293294036882, 52.924940129357317],
            [-0.487569697371955, 52.925152367680226],
            [-0.488369312253677, 52.924019897171647],
            [-0.488287370730684, 52.921888193110746],
            [-0.487912468536858, 52.921889715818388],
            [-0.487616682547238, 52.920559906165678],
            [-0.492007489588485, 52.920863841398244],
            [-0.493982007668761, 52.920716274224162],
            [-0.492444190122829, 52.917755200674925],
            [-0.489214811945092, 52.913448358756199],
            [-0.488606447034052, 52.912981224709057],
            [-0.488251104171998, 52.913149314611985],
            [-0.486652992786006, 52.911766062437991],
            [-0.483486378825389, 52.910842851178565],
            [-0.481793128677366, 52.90797673884569],
            [-0.479891379164605, 52.906400718191897],
            [-0.476933866988062, 52.905593280045885],
            [-0.477143441918276, 52.905426052486824],
            [-0.472071963331285, 52.905413077243566],
            [-0.471827170528174, 52.904171981136287],
            [-0.469763673359872, 52.901166971408657],
            [-0.454675607536237, 52.901378912566955],
            [-0.447599365785032, 52.900479329344698],
            [-0.445339901264813, 52.900550471031615],
            [-0.444168440358029, 52.89896993734564],
            [-0.443556491829793, 52.896256763599624],
            [-0.44189459043286, 52.894801934449696],
            [-0.442378368460364, 52.894410911211438],
            [-0.44074656411615, 52.892617527935961],
            [-0.437349201278105, 52.886974660643993],
            [-0.430761864212367, 52.888827009200043],
            [-0.427882035641203, 52.888641486992306],
            [-0.427128255269826, 52.890412476325558],
            [-0.414338157269583, 52.891250229161919],
            [-0.410414432966932, 52.89184508323391],
            [-0.39872723472297, 52.894398671595724],
            [-0.391291023384563, 52.896496317638793],
            [-0.391305441538596, 52.897231019010739],
            [-0.388792714084092, 52.89747835876139],
            [-0.383466245507724, 52.898628670437418],
            [-0.382554663333432, 52.898395100298345],
            [-0.375076321344516, 52.898439578139005],
            [-0.371495720247258, 52.897701886144347],
            [-0.365832552032766, 52.897909103555818],
            [-0.366076678345268, 52.901141781022048],
            [-0.360833166373722, 52.90191732781129],
            [-0.360714523763779, 52.903800059874122],
            [-0.359030325262665, 52.908431089420674],
            [-0.358949974112298, 52.909888206909159],
            [-0.358153247719625, 52.909957212195167],
            [-0.358559506633524, 52.9116413157181],
            [-0.3575315802426, 52.911845573012236],
            [-0.357901574400055, 52.915628411638309],
            [-0.352031555468677, 52.915814144571243],
            [-0.345151319171477, 52.916514150836562],
            [-0.341836085538396, 52.916512878139294],
            [-0.339269434532795, 52.916927442717473],
            [-0.322838493665243, 52.917507553825367],
            [-0.314439912567166, 52.916379978154474],
            [-0.310393443249575, 52.914989229156006],
            [-0.29288511738284, 52.910749901456597],
            [-0.290554757491747, 52.910787415108906],
            [-0.283724202055495, 52.910036225807652],
            [-0.27886681851318, 52.91000910383714],
            [-0.275422080964738, 52.90957345688323],
            [-0.256504799375069, 52.90586117481255],
            [-0.269527981624444, 52.876935161535876],
            [-0.258579105291719, 52.872298751978342],
            [-0.258439538106304, 52.870219004365723],
            [-0.256562634555802, 52.868338522654028],
            [-0.257256414172252, 52.866745702457067],
            [-0.257658772649842, 52.866677885040531],
            [-0.26078676478226, 52.868109175723859],
            [-0.264675339234009, 52.868655149038652],
            [-0.264767894456327, 52.870170498128495],
            [-0.265870509286484, 52.871171961461101],
            [-0.270896297642495, 52.872085857550935],
            [-0.276301772366725, 52.861666275123291],
            [-0.275043318783492, 52.84084490420171],
            [-0.27209501424542, 52.832567615182555],
            [-0.270859654032298, 52.821173010227405],
            [-0.269330006615826, 52.809917925992075],
            [-0.266812173459093, 52.796487034011328],
            [-0.266051949535726, 52.792814963243764],
            [-0.264855209618788, 52.790239647201737],
            [-0.264032466415357, 52.789485889154186],
            [-0.264120088536884, 52.787433720060584],
            [-0.262183808513088, 52.787050247323968],
            [-0.261067136401201, 52.783629146470638],
            [-0.260616130732661, 52.779619018901265],
            [-0.261782234057059, 52.777294056006362],
            [-0.260618029746914, 52.771353076515481],
            [-0.261572414363427, 52.769983409744654],
            [-0.262703795700926, 52.769081142683852],
            [-0.265888916943427, 52.767785446617452],
            [-0.286714481108364, 52.756090993859431],
            [-0.289366738775945, 52.753867237473806],
            [-0.304520932283232, 52.746010371863058],
            [-0.306821518170643, 52.745252064712894],
            [-0.305999934936541, 52.74466223736183],
            [-0.305289561747829, 52.744719518416552],
            [-0.304629212847821, 52.744022299779346],
            [-0.306691208000578, 52.743416122097869],
            [-0.297311518946296, 52.733589046708708],
            [-0.295288745733511, 52.734223494900824],
            [-0.278332603629071, 52.716480913637774],
            [-0.281289821186866, 52.714243782928406],
            [-0.27801980023032, 52.710739419791665],
            [-0.26719318540273, 52.699066386498643],
            [-0.265025012275117, 52.700858890033814],
            [-0.263703004272509, 52.700979790086691],
            [-0.25577895572596, 52.692591097843746],
            [-0.250435310702424, 52.694449853717671],
            [-0.248769906437796, 52.693023605345388],
            [-0.248105772071812, 52.6932385605338],
            [-0.223221992958437, 52.666742739405237],
            [-0.219924699871929, 52.667711017682628],
            [-0.215623782909234, 52.667863015764532],
            [-0.214039482317126, 52.668271608971743],
            [-0.212515417104075, 52.666659952346187],
            [-0.218184520678307, 52.66704472005749],
            [-0.224512446442964, 52.665444027141952],
            [-0.230088602219512, 52.66362588357805],
            [-0.238362872766151, 52.660190615665833],
            [-0.238863338199308, 52.660504640847833],
            [-0.249111938467442, 52.656395773808015],
            [-0.256115895506568, 52.653380151739086],
            [-0.258678192332797, 52.651625969713756],
            [-0.258946264079108, 52.651911320548862],
            [-0.260257677615452, 52.651424401122995],
            [-0.261250988067948, 52.651465054373823],
            [-0.263590610491387, 52.652487449647182],
            [-0.263807545827068, 52.65387790567695],
            [-0.266210666924934, 52.654682702208049],
            [-0.267495740497606, 52.655834335188224],
            [-0.268973842643572, 52.656330649301381],
            [-0.270010220179784, 52.657268236865718],
            [-0.269749798823048, 52.657572817917355],
            [-0.270349505924677, 52.659422881327131],
            [-0.270996812474848, 52.659618436405594],
            [-0.273087899612708, 52.65946012201676],
            [-0.274892373828665, 52.660684872382774],
            [-0.276036677264075, 52.661854139504641],
            [-0.276220452771855, 52.662632714688527],
            [-0.277800835197881, 52.663281448405435],
            [-0.278889067057175, 52.664601815338322],
            [-0.279096476368886, 52.665984906503439],
            [-0.282274583935674, 52.667151259714039],
            [-0.286862430968345, 52.668010650453951],
            [-0.28843256456743, 52.669763152698515],
            [-0.289515384317805, 52.670325421666696],
            [-0.291676532002625, 52.670353895746068],
            [-0.293408621798706, 52.669437521747724],
            [-0.294566518860904, 52.669326521446841],
            [-0.29732179032057, 52.670581682610624],
            [-0.309984690633096, 52.673459238782726],
            [-0.320536189995067, 52.67508459665099],
            [-0.32118573173049, 52.675002093977312],
            [-0.321042857563576, 52.674484904254989],
            [-0.320530214103827, 52.67427624989697],
            [-0.320033596099639, 52.674729534512252],
            [-0.319529885787621, 52.674288143805803],
            [-0.320749400663035, 52.673651806137897],
            [-0.322379900022955, 52.673791775337321],
            [-0.322887245686543, 52.674177463066009],
            [-0.324122686999921, 52.674360366917526],
            [-0.325479251675192, 52.674277047077979],
            [-0.326304023935991, 52.673866135278423],
            [-0.327562068334785, 52.67419317316957],
            [-0.330249553714138, 52.67345696873172],
            [-0.330689934189887, 52.673893825116295],
            [-0.331707801982892, 52.673770599652364],
            [-0.331528535768998, 52.674321901270282],
            [-0.333126300710531, 52.6748181907438],
            [-0.334412092954854, 52.674651059190126],
            [-0.335049534402151, 52.674847021156026],
            [-0.336258361477919, 52.674097092654648],
            [-0.336150203283706, 52.67266156370686],
            [-0.33686217602599, 52.672685941671581],
            [-0.337369843583911, 52.67236490526836],
            [-0.336056187588531, 52.671515732272873],
            [-0.337103146693048, 52.670468628921924],
            [-0.33625641137775, 52.668619952162381],
            [-0.337549194652495, 52.668224522716137],
            [-0.338777738435186, 52.667182648929632],
            [-0.339768666630715, 52.667102132703604],
            [-0.339963911849627, 52.666047565211848],
            [-0.341324946759234, 52.665369845025488],
            [-0.342725531515782, 52.665912695787206],
            [-0.343262043703387, 52.665490443350784],
            [-0.34310373055458, 52.665101631508406],
            [-0.344272310147759, 52.665405664704771],
            [-0.344815370784238, 52.664183327695298],
            [-0.346556623285114, 52.663105378766645],
            [-0.34945766450902, 52.66311075482416],
            [-0.349934926712422, 52.662806324058714],
            [-0.349973881751397, 52.66212537433546],
            [-0.350601877219743, 52.661940817182604],
            [-0.350159801634041, 52.661625384808474],
            [-0.350895945437906, 52.661435139163352],
            [-0.35242717676474, 52.661727955069999],
            [-0.355111480170256, 52.660532624291548],
            [-0.355583172089837, 52.660847549991281],
            [-0.356410551115293, 52.660313295016515],
            [-0.356324181901328, 52.659935388602328],
            [-0.356768039469559, 52.659928058043278],
            [-0.356780894463479, 52.659467015597308],
            [-0.359436783258411, 52.660010885492326],
            [-0.360966771517937, 52.659546560960067],
            [-0.362819944357791, 52.660087341429467],
            [-0.363828246469608, 52.660248713475866],
            [-0.365551392773807, 52.659797785425468],
            [-0.366308374921371, 52.660077043591514],
            [-0.367373897584576, 52.660012609964873],
            [-0.367656381857687, 52.659574161618536],
            [-0.370054752728212, 52.658896013324792],
            [-0.370279875809787, 52.657931716322715],
            [-0.371134631475968, 52.658404688041294],
            [-0.373109452399306, 52.657383506923424],
            [-0.373893088056822, 52.657582166837805],
            [-0.373746386870594, 52.65707308163234],
            [-0.374393343772333, 52.657209625238309],
            [-0.374936987966163, 52.657899470979245],
            [-0.375579767242357, 52.65783006511861],
            [-0.375755903793015, 52.657106934941211],
            [-0.376595505964117, 52.656912554113028],
            [-0.377272281904952, 52.655645152894465],
            [-0.378880146043972, 52.656143663093026],
            [-0.380335165459413, 52.655578268410764],
            [-0.382566679341915, 52.656044792726533],
            [-0.383646592349203, 52.655707094611287],
            [-0.383474875319255, 52.655151828480356],
            [-0.383816260818181, 52.655436093546321],
            [-0.384536593482069, 52.655347018563589],
            [-0.384708235701312, 52.654420626157183],
            [-0.386068296734027, 52.654984888460127],
            [-0.387065253277806, 52.654730541478735],
            [-0.38702986228093, 52.654326380545712],
            [-0.387501606268434, 52.654443385403887],
            [-0.389115047370364, 52.653947468975957],
            [-0.39105366855244, 52.654016062247258],
            [-0.391505457676851, 52.653710220849185],
            [-0.393189716112322, 52.654063026214295],
            [-0.39572433228813, 52.653318779213066],
            [-0.395975774329978, 52.652548090567436],
            [-0.398471609466231, 52.651081313654451],
            [-0.398485170363179, 52.650710184310171],
            [-0.399188551372729, 52.650515601402603],
            [-0.399973494428015, 52.649584884834333],
            [-0.401163881729672, 52.649533522605971],
            [-0.403343624737252, 52.648699813723873],
            [-0.40459938130822, 52.648598051391311],
            [-0.405693319130371, 52.648027485736677],
            [-0.407662443554176, 52.648104310507897],
            [-0.410423949680008, 52.647771873109598],
            [-0.413197046876421, 52.648343082833875],
            [-0.416663345210239, 52.64752725093922],
            [-0.417109287212276, 52.647171786490858],
            [-0.416889546460953, 52.646871261915443],
            [-0.417511627178023, 52.646754601980916],
            [-0.419753218240449, 52.647021868920419],
            [-0.420826768606407, 52.646846479210332],
            [-0.423326509174, 52.648033260217069],
            [-0.42371323383382, 52.647555610213388],
            [-0.424243110821244, 52.647824284014959],
            [-0.425047423509662, 52.647572451626722],
            [-0.425382453408266, 52.648492146524468],
            [-0.426665916400808, 52.648769921670329],
            [-0.427279629032156, 52.648556900034713],
            [-0.428228325887657, 52.6490603737059],
            [-0.430247694745021, 52.648974759316999],
            [-0.432101589995724, 52.648266570821853],
            [-0.435284380445457, 52.650206552778435],
            [-0.436087478129754, 52.650319647067398],
            [-0.436322893538438, 52.649853445563004],
            [-0.437383452326218, 52.649793712862369],
            [-0.440293495594522, 52.651390117354836],
            [-0.44084331692475, 52.65226584193335],
            [-0.442383331912339, 52.652235747007303],
            [-0.443250259385079, 52.65267598894831],
            [-0.447423418828588, 52.653099350463954],
            [-0.448230622505384, 52.652848297988378],
            [-0.449029527510945, 52.653040366056956],
            [-0.450489430197281, 52.654105965106119],
            [-0.451340077362208, 52.653880633070798],
            [-0.452706053505389, 52.654258997474336],
            [-0.453047771043488, 52.649226948550726],
            [-0.456058655903438, 52.649989955745809],
            [-0.458980959659545, 52.650206007316505],
            [-0.467647551251222, 52.649880553565623],
            [-0.475842751374648, 52.648696101486223],
            [-0.474525356736554, 52.646666167970949],
            [-0.475178636847873, 52.646115364544841],
            [-0.476012293327981, 52.645945379269655],
            [-0.475972479839698, 52.645432406416226],
            [-0.475255972291402, 52.645039292815774],
            [-0.483461328206885, 52.645408912608268],
            [-0.484331319115221, 52.646110516497046],
            [-0.485568570780632, 52.645295610427922],
            [-0.485058993191499, 52.644429601903923],
            [-0.485742518621971, 52.644196493362898],
            [-0.486160307309362, 52.643568898127626],
            [-0.488353786818269, 52.643738052152379],
            [-0.489111741677297, 52.643488791766302],
            [-0.490749717194061, 52.64232021886847],
            [-0.491841647971375, 52.641985289266302],
            [-0.491499173530071, 52.641457681022089],
            [-0.494784665075286, 52.640295630773188],
            [-0.495037100698806, 52.64021792249202],
            [-0.49934424256471, 52.642492309048606],
            [-0.504410691820253, 52.646221795683999],
            [-0.504857138684813, 52.646653579598862],
            [-0.503886040783928, 52.646782473927217],
            [-0.504385317935298, 52.647962037692373],
            [-0.505134661225125, 52.648268179581713],
            [-0.506472442843351, 52.648180758032211],
            [-0.50744022648788, 52.648887917372996],
            [-0.51256172823507, 52.654739492931661],
            [-0.512752322838962, 52.655004407222535],
            [-0.512034808558476, 52.654931568022],
            [-0.51185601758583, 52.656321949525683],
            [-0.514088148658816, 52.656768015000608],
            [-0.523596957273736, 52.66521472589546],
            [-0.52183945497949, 52.665368133912061],
            [-0.521839325588996, 52.665766409786364],
            [-0.52064093972115, 52.666484180621893],
            [-0.519889810711404, 52.666218574688521],
            [-0.519028270062245, 52.666507195062586],
            [-0.506685241295045, 52.659506968857151],
            [-0.506389997761125, 52.660404095123447],
            [-0.505305006855856, 52.660915460766908],
            [-0.504577925808545, 52.660383941363683],
            [-0.501991060214993, 52.660666838207156],
            [-0.496878326336439, 52.660399817564731],
            [-0.493876873403356, 52.660697956694193],
            [-0.494722678697267, 52.664613274793716],
            [-0.490620145205822, 52.665014219685084],
            [-0.490278913048616, 52.664403014899648],
            [-0.483890570629469, 52.665434490683708],
            [-0.481466914284945, 52.665817025588041],
            [-0.481606084932118, 52.666025590254705],
            [-0.480421165009407, 52.66654893531188],
            [-0.476572435276313, 52.667041655099872],
            [-0.476389903421515, 52.667357572792149],
            [-0.473676633885094, 52.667515958218551],
            [-0.466080640722034, 52.668843789468468],
            [-0.462607455127504, 52.669160207531434],
            [-0.462511188246018, 52.668784953060019],
            [-0.461639952794394, 52.669042464704738],
            [-0.459461640442182, 52.669081600262381],
            [-0.459495655005403, 52.669544155366388],
            [-0.456202432946927, 52.670374286134049],
            [-0.454182413190335, 52.672672908245204],
            [-0.451316079826341, 52.673927429868044],
            [-0.449326793317008, 52.675262587203484],
            [-0.44636278236951, 52.678594322363956],
            [-0.441339063280146, 52.680472121522371],
            [-0.438630896113021, 52.682866620297624],
            [-0.434065856240986, 52.683685675972235],
            [-0.434923264714975, 52.684655390259643],
            [-0.434376786027481, 52.685664103814894],
            [-0.4331885704167, 52.685463199514544],
            [-0.43230065725032, 52.687494108864435],
            [-0.432531690280996, 52.69071037852018],
            [-0.430867183020891, 52.691987488991323],
            [-0.430379139328918, 52.69288817206656],
            [-0.430555635708234, 52.693908236082343],
            [-0.428931822406791, 52.698044844097545],
            [-0.428393117211045, 52.701094479917572],
            [-0.428707522910975, 52.702642318491257],
            [-0.429737332490476, 52.703269115135782],
            [-0.43045411450459, 52.705402164388289],
            [-0.43350864869962, 52.705828253807674],
            [-0.435751428535726, 52.70701853823256],
            [-0.436345174271736, 52.706764746648609],
            [-0.444732875483614, 52.706153132139072],
            [-0.44396836539006, 52.708005031508307],
            [-0.450543177117666, 52.708013816821612],
            [-0.451102703200323, 52.709560297446203],
            [-0.453239337098287, 52.709250147789028],
            [-0.457094209003807, 52.709661789977744],
            [-0.459474996741169, 52.708386431450187],
            [-0.460885782842683, 52.708088288606035],
            [-0.463655044909929, 52.708473932903665],
            [-0.465556343031017, 52.710268775601541],
            [-0.467907048031844, 52.709761543895404],
            [-0.470291978817881, 52.709755475790814],
            [-0.480227452368633, 52.710951304685949],
            [-0.48309086817174, 52.710638243302405],
            [-0.489605216181237, 52.711036967771911],
            [-0.493252675224287, 52.710264345510275],
            [-0.494541516170303, 52.709633405564887],
            [-0.495754175527333, 52.710786085147795],
            [-0.499854220899676, 52.712092192385157],
            [-0.502481930603784, 52.712132600195488],
            [-0.504547545833412, 52.71332922150998],
            [-0.505731994524495, 52.713585096647172],
            [-0.50697223050322, 52.715936427423216],
            [-0.509872261591827, 52.715334587588892],
            [-0.511014820040642, 52.717692737848168],
            [-0.51066577018376, 52.71889936121746],
            [-0.512651975054022, 52.719660603696603],
            [-0.51650432093407, 52.72023299940583],
            [-0.51919696231363, 52.72099397619872],
            [-0.525014182998418, 52.720956791811226],
            [-0.533055911322667, 52.721563600162298],
            [-0.538758724253282, 52.723090446924573],
            [-0.542444427952356, 52.723261669601428],
            [-0.54283332381618, 52.726031882259271],
            [-0.540325775673478, 52.728752992530481],
            [-0.53882229686821, 52.73403518876917],
            [-0.539984863925688, 52.738381951951176],
            [-0.544724461750217, 52.739592761148216],
            [-0.547963976397468, 52.741019672079574],
            [-0.553272148643351, 52.742522088671876],
            [-0.560044652762154, 52.745452630650142],
            [-0.560874742956555, 52.7455732855435],
            [-0.566121236757248, 52.744967944886149],
            [-0.569021821752885, 52.746598750931881],
            [-0.569517852407584, 52.747511854750407],
            [-0.571460791724998, 52.747854425131671],
            [-0.57150979609147, 52.750478382084907],
            [-0.572646812347145, 52.75308936252609],
            [-0.580519301058505, 52.752858378581159],
            [-0.583457735761322, 52.753857260911595],
            [-0.589576210816596, 52.753754854693668],
            [-0.593919543778953, 52.75188025191148],
            [-0.597720264954843, 52.750973622541309],
            [-0.605454136571767, 52.750473235374976],
            [-0.608603678305768, 52.759693753835158],
            [-0.611783358389548, 52.759804723726994],
            [-0.615505396973512, 52.759309704249347],
            [-0.623060257309464, 52.758933735109551],
            [-0.627696742573443, 52.75767398701791],
            [-0.627748269719834, 52.757967664246848],
            [-0.628157684482141, 52.757954418860123],
            [-0.639581235095398, 52.757153682218664],
            [-0.645087622852378, 52.757254457347855],
            [-0.656938705480691, 52.756652919210971],
            [-0.662604294426078, 52.755473581423395],
            [-0.6636904354598, 52.756781307023701],
            [-0.664111082794406, 52.756690748533146],
            [-0.66771631811072, 52.761257846206412],
            [-0.669519589748446, 52.76627661766036],
            [-0.672766298002017, 52.771417594606774],
            [-0.674364326659865, 52.776734254506827],
            [-0.675668801670123, 52.777520175322245],
            [-0.67551577347623, 52.780998551519474],
            [-0.676967855295078, 52.785673438537017],
            [-0.674558887160278, 52.785507209412245],
            [-0.674118590932326, 52.787748026098456],
            [-0.677502416327802, 52.788338677464061],
            [-0.677313550229084, 52.789970078558419],
            [-0.681593044176321, 52.791109099527155],
            [-0.683244793896792, 52.79514691844458],
            [-0.682601787081287, 52.799739122547024],
            [-0.680637806559023, 52.80719257660941],
            [-0.682183089262566, 52.810695202600989],
            [-0.682049538681654, 52.811775227650621],
            [-0.686504676503437, 52.816268408085968],
            [-0.687926342223855, 52.818234977081751],
            [-0.692407341617904, 52.821759981215777],
            [-0.695611367344167, 52.823544695731997],
            [-0.698395540381164, 52.82597829635543],
            [-0.703660119641208, 52.833651365149215],
            [-0.705838240330395, 52.838031696881785],
            [-0.706547242971726, 52.840351657567744],
            [-0.708224315869179, 52.843474169373707],
            [-0.710196468579126, 52.845816830323571],
            [-0.719212619298133, 52.854391093915225],
            [-0.721846606394664, 52.855981034499059],
            [-0.724597625938821, 52.858803799771316],
            [-0.729110481873303, 52.862141611873909],
            [-0.733679190786787, 52.866901155901409],
            [-0.736155649622797, 52.870070423245579],
            [-0.736115190520094, 52.872944979150965],
            [-0.739180732215668, 52.876928640490824],
            [-0.740429874997058, 52.880023643870615],
            [-0.742727893729994, 52.883136030994777],
            [-0.745536801432723, 52.882771070424617],
            [-0.747739911679821, 52.882953438045696],
            [-0.750082212177391, 52.883929241425847],
            [-0.757162037162908, 52.885963327237441],
            [-0.75776075813043, 52.884255213701074],
            [-0.759454286184224, 52.884592053688024],
            [-0.760347149113987, 52.885176728964758],
            [-0.761665873412788, 52.884534220167232],
            [-0.766597242854675, 52.886777263896647],
            [-0.76675877354582, 52.887474759227224],
            [-0.766210584713385, 52.888408511463432],
            [-0.763944721152922, 52.890838300103312],
            [-0.763570554865357, 52.89197342632847],
            [-0.764322318040838, 52.89245681364531],
            [-0.763993319469553, 52.893623875347622],
            [-0.762494081107909, 52.894437135858702],
            [-0.762542192603219, 52.895096596171477],
            [-0.761582489481798, 52.89570150452073],
            [-0.759836232027646, 52.898706589002025],
            [-0.758680064472019, 52.899471241118384],
            [-0.758473154588143, 52.900468754773108],
            [-0.759114880072607, 52.900695711258315],
            [-0.76171866522036, 52.90046846412227],
            [-0.761777919024722, 52.901310534907687],
            [-0.763700157224227, 52.90105366449923],
            [-0.763971400613503, 52.903120563401686],
            [-0.768794072889658, 52.901932731359608],
            [-0.770013850585146, 52.903695694014452],
            [-0.773805876738523, 52.902514964522403],
            [-0.776864127419438, 52.904787661874288],
            [-0.775433774242263, 52.905736642006715],
            [-0.774547477120316, 52.907321407110992],
            [-0.773024433146537, 52.909020055988066],
            [-0.77198651513302, 52.91249020765656],
            [-0.768881584841129, 52.916259889312421],
            [-0.769500253030184, 52.917100556010183],
            [-0.768716556631806, 52.917411577841094],
            [-0.769449146501947, 52.919585720977643],
            [-0.768475662197294, 52.919874099144522],
            [-0.768440114589766, 52.920602807621492],
            [-0.766742177680935, 52.921468900624447],
            [-0.76748798384178, 52.922443047090454],
            [-0.766581822141933, 52.922924490934001],
            [-0.763102136298368, 52.923671339334625],
            [-0.765519583202613, 52.925859433855628],
            [-0.766111654437365, 52.928004266427592],
            [-0.765253446483703, 52.92988052243696],
            [-0.761875955574362, 52.934168596990773],
            [-0.761633342965384, 52.935318566222143],
            [-0.76209163931331, 52.935943639180522],
            [-0.760876110667959, 52.937710959375231],
            [-0.759540623582508, 52.941328021047397],
            [-0.756852263182693, 52.944575826620799],
            [-0.757444486169245, 52.945462123009676],
            [-0.757162556354973, 52.945588628892295],
            [-0.757927996801231, 52.946299634191718],
            [-0.755511562903648, 52.947110412261225],
            [-0.755826461892318, 52.947754683184804],
            [-0.754955286697045, 52.949534535743794],
            [-0.755473327886013, 52.950517154135781],
            [-0.755966282264012, 52.950559172557064],
            [-0.755289351973588, 52.950704013973507],
            [-0.755318238673397, 52.951356977244615],
            [-0.756951718205894, 52.950971326620561],
            [-0.759475206305847, 52.952824418975958],
            [-0.760004478153576, 52.952690599197879],
            [-0.764545066661411, 52.954914309668744],
            [-0.770327582015277, 52.956878306177636],
            [-0.773679273691904, 52.958483446088422],
            [-0.774318656925378, 52.958089095515518],
            [-0.776423045995714, 52.959113135498882],
            [-0.778442668457474, 52.95929212588613],
            [-0.778373433504338, 52.96032254265522],
            [-0.77779901548782, 52.960683421630364],
            [-0.779262660439514, 52.96131156336029],
            [-0.778014464165649, 52.962810820798893],
            [-0.778304161467166, 52.965315653400452],
            [-0.777720305987638, 52.966906236260442],
            [-0.777678193480083, 52.972041659230385],
            [-0.778083522486331, 52.972137521256322],
            [-0.777114295771831, 52.974556590840393],
            [-0.778228841006163, 52.975011244294024],
            [-0.777283180389438, 52.976012871316172],
            [-0.7777101036452, 52.97624200492028],
            [-0.777556418283475, 52.976621590771742],
            [-0.778284253033863, 52.976901461365358],
            [-0.777000824376142, 52.977837585601613],
            [-0.777653304462406, 52.978413346826947],
            [-0.776794211738382, 52.978983452377072],
            [-0.777604260689489, 52.979570723308434],
            [-0.777085163228035, 52.980020267188188],
            [-0.777674134196568, 52.980367034978052],
            [-0.776858262287564, 52.981409547381219],
            [-0.777483933977452, 52.981670391854379],
            [-0.777559805424941, 52.983784662456735],
            [-0.778462542357742, 52.983677072932075],
            [-0.779580941880771, 52.984955212350272],
            [-0.780311439091587, 52.985093058825782],
            [-0.780163900645647, 52.986417532726101],
            [-0.780910563354174, 52.986405412493916],
            [-0.781595993535596, 52.987351865619864],
            [-0.782209614407305, 52.987568511259639],
            [-0.782135622505481, 52.988451444880248],
            [-0.784541861937865, 52.988546184151964],
            [-0.784464821636958, 52.990123095253082],
            [-0.788100619996521, 52.991747790352584],
            [-0.787330352998859, 52.993184587370337],
            [-0.789461322836944, 52.993915587864826],
            [-0.789691347854245, 52.993628460032198],
            [-0.790097975650543, 52.993792614866784],
            [-0.790571833181239, 52.994590328521291],
            [-0.791518205462828, 52.995008082665436],
            [-0.791499618771156, 52.995407037378868],
            [-0.792568649004171, 52.995861988253168],
            [-0.791896973978836, 52.997452639717231],
            [-0.79382758206452, 52.997844410358276],
            [-0.794941779701993, 52.999944914675091],
            [-0.795864608850705, 53.000571854822262],
            [-0.800195335400671, 53.00217174146804],
            [-0.800527094803972, 53.003804920667363],
            [-0.802515875380352, 53.00533882637918],
            [-0.802621668151515, 53.00636291878309],
            [-0.804065810382395, 53.006363065242148],
            [-0.804279432499642, 53.012223793050318],
            [-0.803650185925465, 53.01293304453332],
            [-0.802036861583874, 53.012090654533829],
            [-0.798619818518554, 53.0109891136633],
            [-0.795830648446023, 53.010576149804272],
            [-0.788418956189644, 53.025831899392706],
            [-0.785848613461521, 53.025719392101664],
            [-0.784877247826555, 53.02516110326544],
            [-0.780414351933399, 53.024380075846459],
            [-0.776678166061501, 53.024689613592898],
            [-0.773960591312958, 53.0255138586679],
            [-0.770391113029328, 53.027296530475539],
            [-0.760831511005744, 53.029389024006655],
            [-0.758439227165961, 53.032365741285567],
            [-0.758858896011396, 53.035148834966506],
            [-0.757822134308033, 53.036455903393907],
            [-0.756623304329208, 53.036654638065279],
            [-0.757151361364956, 53.03784409801807],
            [-0.755860647625289, 53.04397955349566],
            [-0.75333522101434, 53.0482214199059],
            [-0.749555974271863, 53.055094824634047],
            [-0.74543107954738, 53.054098500480677],
            [-0.742837735743655, 53.054619500069585],
            [-0.740688614410248, 53.053565658326974],
            [-0.739738894851363, 53.053747083621104],
            [-0.73882957064033, 53.054588772318027],
            [-0.739136255547593, 53.054969589107195],
            [-0.738567761133115, 53.055226062601356],
            [-0.738053315084442, 53.054755841218373],
            [-0.736966051247803, 53.054894433177061],
            [-0.736563413746047, 53.054632154740126],
            [-0.736061661156385, 53.054901910516648],
            [-0.734254469815741, 53.054811682128459],
            [-0.733634458783827, 53.055552128318247],
            [-0.732628498991721, 53.055308585297468],
            [-0.73107881348517, 53.054324778948462],
            [-0.730029656522159, 53.054587771680303],
            [-0.730225517606291, 53.054980014519238],
            [-0.72910652011361, 53.055080439426249],
            [-0.728419771715195, 53.055803964577869],
            [-0.727546252324049, 53.055623822575377],
            [-0.727337518128801, 53.055973087258792],
            [-0.726712659753781, 53.056063491243854],
            [-0.72670673143029, 53.05646706500864],
            [-0.725593427961744, 53.056271757069709],
            [-0.724935411794795, 53.057131314408593],
            [-0.725367124460628, 53.057468558215049],
            [-0.724695923485854, 53.058066372905451],
            [-0.725093720385278, 53.058289085051172],
            [-0.724126180478959, 53.058815399564871],
            [-0.723263657131319, 53.058766593808308],
            [-0.723551871914512, 53.059364803380923],
            [-0.722601897511674, 53.059901182683717],
            [-0.721342229398055, 53.059707859990539],
            [-0.719804233767253, 53.059893591913351],
            [-0.718974574735612, 53.05939023011603],
            [-0.717844994067664, 53.059540778030218],
            [-0.716208087984799, 53.059037685805002],
            [-0.715513082997956, 53.058825220517967],
            [-0.713928350745715, 53.057022748726716],
            [-0.70593394602282, 53.051981844648992],
            [-0.703549151436815, 53.052153658220618],
            [-0.702710709495747, 53.05195393768917],
            [-0.702459582086519, 53.052963442097663],
            [-0.699364627883573, 53.052983590193904],
            [-0.698294599064555, 53.053290125307392],
            [-0.693513592083471, 53.0567661936497],
            [-0.693749411820156, 53.057454698547552],
            [-0.695234473308955, 53.057737986378804],
            [-0.695201450882861, 53.059127435045362],
            [-0.69316719794653, 53.059042183645417],
            [-0.692787086829978, 53.05937422413394],
            [-0.689893025682646, 53.05974154008841],
            [-0.689385373450383, 53.059308937967288],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000142",
        LAD13CDO: "32UH",
        LAD13NM: "West Lindsey",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-0.775651504553511, 53.379358105591265],
              [-0.775765470261775, 53.379053642144783],
              [-0.780564303755212, 53.382520433774864],
              [-0.775651504553511, 53.379358105591265],
            ],
          ],
          [
            [
              [-0.300925417428584, 53.616366192823612],
              [-0.29823249729289, 53.612539796111299],
              [-0.29228539923862, 53.613514032012695],
              [-0.292110294244875, 53.613245446529298],
              [-0.29041326731435, 53.61060978978152],
              [-0.287073602292744, 53.611778301938678],
              [-0.285471923431216, 53.609260799413946],
              [-0.281189761490147, 53.606240946697589],
              [-0.282949917415818, 53.605178494317464],
              [-0.28303437969537, 53.604474934324507],
              [-0.278599864467917, 53.603470927050665],
              [-0.275227047124567, 53.602305868801686],
              [-0.269807822624312, 53.598595889222196],
              [-0.268291533478941, 53.59938480373097],
              [-0.261581572609509, 53.595704298280275],
              [-0.259355959677932, 53.59694754833167],
              [-0.254469229691871, 53.59246611249543],
              [-0.250716165335397, 53.593812761529392],
              [-0.249720881637115, 53.593983389449505],
              [-0.248471775122056, 53.593641487839363],
              [-0.248897902015589, 53.593202744995573],
              [-0.248530688503278, 53.592984318729151],
              [-0.249574153581126, 53.592524943783275],
              [-0.248866337074561, 53.591956337950286],
              [-0.249816494723891, 53.591231303901424],
              [-0.249474963586621, 53.591122928026344],
              [-0.250424515920785, 53.590704422449797],
              [-0.25016600050945, 53.590488489483285],
              [-0.250635984397944, 53.590267029030322],
              [-0.249951708195327, 53.589860584559531],
              [-0.250791406303731, 53.589430580792147],
              [-0.250568848301976, 53.589075837005836],
              [-0.25114491092646, 53.588260820499407],
              [-0.249040038917486, 53.586878011003911],
              [-0.252076351595189, 53.584984273278806],
              [-0.251391467316896, 53.584630865467673],
              [-0.250472776948497, 53.584452026984387],
              [-0.247181224362011, 53.586817530281827],
              [-0.243322948201704, 53.585753214269666],
              [-0.24206609204619, 53.58702474857094],
              [-0.240845383784088, 53.586623851588456],
              [-0.239159757651404, 53.588139858079025],
              [-0.236452273402062, 53.586435117738361],
              [-0.236873840528491, 53.586179736078279],
              [-0.236041004451745, 53.585971487428331],
              [-0.234664565690542, 53.586228062045812],
              [-0.234420146907828, 53.585966455567316],
              [-0.235230748633005, 53.585547817253961],
              [-0.234890726766996, 53.584643847687325],
              [-0.233393630316744, 53.58286789089717],
              [-0.233638252716032, 53.582653057278698],
              [-0.232413048627255, 53.58243719070601],
              [-0.232643202934828, 53.58217090494032],
              [-0.231344679545587, 53.58107027201477],
              [-0.230838370427737, 53.579846499912144],
              [-0.231472676618245, 53.579560123019128],
              [-0.228981703111523, 53.577574336434928],
              [-0.229277535969878, 53.57739892485219],
              [-0.228637532773248, 53.576846480481557],
              [-0.22724080496622, 53.576113816784684],
              [-0.228134614994313, 53.575327888074696],
              [-0.227150760746735, 53.574728090535203],
              [-0.228334447787033, 53.573474504462133],
              [-0.223967491368984, 53.572154802034447],
              [-0.225098332586795, 53.570252314942451],
              [-0.222554679927103, 53.567994119098188],
              [-0.219863810279168, 53.569427493223934],
              [-0.217481932809929, 53.568429236952539],
              [-0.213261382577958, 53.570068892316129],
              [-0.210705949760475, 53.569428390170643],
              [-0.210322368229625, 53.569785830871993],
              [-0.209448364687852, 53.569597453493074],
              [-0.207512047844014, 53.570817106058172],
              [-0.206296241570935, 53.570211859665427],
              [-0.204635425150731, 53.571592016315002],
              [-0.203900055355179, 53.5712681384585],
              [-0.202937047951037, 53.571433466129449],
              [-0.200784716500681, 53.570370902031101],
              [-0.200192754717137, 53.57090317028738],
              [-0.198491886179958, 53.570093671735684],
              [-0.195180916919672, 53.571596263849706],
              [-0.191687816892958, 53.568442088814038],
              [-0.187524908155934, 53.56358940593956],
              [-0.189743367620297, 53.562698870475522],
              [-0.189224537387315, 53.562225353666996],
              [-0.190513087607251, 53.561858301553727],
              [-0.18987982028052, 53.56098841131768],
              [-0.192359004276671, 53.559892315681772],
              [-0.19206468225544, 53.5596775095281],
              [-0.19486647663959, 53.557490403475704],
              [-0.202713158764515, 53.54985862843801],
              [-0.20205703939883, 53.549526037967993],
              [-0.204565901513135, 53.547883570881389],
              [-0.208490619847106, 53.544412207323191],
              [-0.209192192746364, 53.543166865356191],
              [-0.212139235514797, 53.54103098055699],
              [-0.214220103557731, 53.538922544735577],
              [-0.216252031380854, 53.536034841713686],
              [-0.216761193595234, 53.535984003858978],
              [-0.219878110394365, 53.532582928131859],
              [-0.215368752209626, 53.532351195443361],
              [-0.210585131280296, 53.531682786175928],
              [-0.210975722802022, 53.530470534940598],
              [-0.212363664986931, 53.529328035588691],
              [-0.212639283600568, 53.5281572101199],
              [-0.21361758294872, 53.527460750793196],
              [-0.213619686266476, 53.526335278947357],
              [-0.212854752707891, 53.525512082029827],
              [-0.213238452002419, 53.525284985276514],
              [-0.212490786820058, 53.524840507957109],
              [-0.212704662741593, 53.52449041240439],
              [-0.211389818965538, 53.52164620012357],
              [-0.210030520002302, 53.520647781020934],
              [-0.206850371010895, 53.516690518191993],
              [-0.206737189565011, 53.515548930588281],
              [-0.205317278613952, 53.514136020693165],
              [-0.204619564578787, 53.511731593268458],
              [-0.204082170269947, 53.511743297933471],
              [-0.204288474893623, 53.506471252789986],
              [-0.205472722652038, 53.504745938430666],
              [-0.2075045635913, 53.502906572173167],
              [-0.205981108900575, 53.501098363498514],
              [-0.205262221955973, 53.497021532886514],
              [-0.205548358307267, 53.496707594105906],
              [-0.206466508394234, 53.496742951128113],
              [-0.207540348726721, 53.494945839625601],
              [-0.208226078191483, 53.491911311976189],
              [-0.210601352329287, 53.486264511178504],
              [-0.202392831378272, 53.48662316678228],
              [-0.199556485577121, 53.485619467122007],
              [-0.196925538340566, 53.485142000294466],
              [-0.194330330094576, 53.483753456602251],
              [-0.191108104724603, 53.483893508919415],
              [-0.189347031854643, 53.484759518975459],
              [-0.188561701337832, 53.484513884060334],
              [-0.188943237239719, 53.483730368055284],
              [-0.188584113881908, 53.482926636109731],
              [-0.187307015743011, 53.482586336632082],
              [-0.186922987591891, 53.482014156841849],
              [-0.187808802364716, 53.480395955408504],
              [-0.184428250340405, 53.479308122017059],
              [-0.185158941528679, 53.477158984859422],
              [-0.18250074258333, 53.476100941778924],
              [-0.182286771523287, 53.471658543334705],
              [-0.183272734382263, 53.469352381877464],
              [-0.181951526688778, 53.46931430579766],
              [-0.181801690240993, 53.468625207499535],
              [-0.177518971977766, 53.468646259604697],
              [-0.17697690719923, 53.468006008757804],
              [-0.17543101951762, 53.46835998201869],
              [-0.175134531820374, 53.467896076289414],
              [-0.172373785839265, 53.467630931174611],
              [-0.168735161984929, 53.467390925803691],
              [-0.1669695033514, 53.46763444267868],
              [-0.166378376248746, 53.466808199973819],
              [-0.159467712078608, 53.467993775575614],
              [-0.159222724355801, 53.466831215759505],
              [-0.158041306831906, 53.46509775265551],
              [-0.153676962152631, 53.465653380701106],
              [-0.157206780446042, 53.46290485462594],
              [-0.156053490089589, 53.461708495338016],
              [-0.156638995204629, 53.461093637726897],
              [-0.156848833568968, 53.460944048426171],
              [-0.158504803174503, 53.461559316806493],
              [-0.156965420645315, 53.459121813390269],
              [-0.156460212152842, 53.457432023167179],
              [-0.155054884059765, 53.457189178550834],
              [-0.154611555343991, 53.45570710095987],
              [-0.151963393539204, 53.452349815097783],
              [-0.152545985130454, 53.452216787174152],
              [-0.150674287893105, 53.448916423777447],
              [-0.150237180640484, 53.448959998940452],
              [-0.149225135103441, 53.446675286170816],
              [-0.148667781151696, 53.446783517132012],
              [-0.147201495224008, 53.444109675577998],
              [-0.144497905181937, 53.440879005341465],
              [-0.13902306431205, 53.441471665513561],
              [-0.137915758094327, 53.439936917846794],
              [-0.134245652074215, 53.440713904753537],
              [-0.134022301103095, 53.43900773289635],
              [-0.131877017931093, 53.435914940766686],
              [-0.13487912861555, 53.436219869591838],
              [-0.141651532093552, 53.434857389995308],
              [-0.15898162681689, 53.437131415134772],
              [-0.160961054852313, 53.436546105463741],
              [-0.161726256510942, 53.437268981596667],
              [-0.16339076232844, 53.437038377986283],
              [-0.163846263461632, 53.438031566462861],
              [-0.164391029392966, 53.438246706159376],
              [-0.167073276273543, 53.437771881782346],
              [-0.167798129770306, 53.438560623541456],
              [-0.172815197104569, 53.43816191770938],
              [-0.175328858451507, 53.438281253378918],
              [-0.177234714389242, 53.438847944580061],
              [-0.180521462709881, 53.440678039866661],
              [-0.183727679120594, 53.438578273500731],
              [-0.185201048278481, 53.437178481882711],
              [-0.189112147452556, 53.435590013535332],
              [-0.189273338914417, 53.435091725361154],
              [-0.191069983915153, 53.434721600688739],
              [-0.195134092715122, 53.434822639575529],
              [-0.198292791709847, 53.433551527858789],
              [-0.197413729086863, 53.432375883393505],
              [-0.199050021236887, 53.431806342965146],
              [-0.198696037046109, 53.431028781313067],
              [-0.199969015621048, 53.43128979928251],
              [-0.202277925652236, 53.430510100678262],
              [-0.204834459200113, 53.43017457977853],
              [-0.20503987326796, 53.430443764859042],
              [-0.212055340267466, 53.428699797684089],
              [-0.212827867919646, 53.429337048944767],
              [-0.214847752616664, 53.42822645503778],
              [-0.216793596921728, 53.425394078862148],
              [-0.217062149379571, 53.424083782944564],
              [-0.2126824818451, 53.420065507096311],
              [-0.212028034943442, 53.419020985304371],
              [-0.211721008478479, 53.415686567791042],
              [-0.209602921448405, 53.413738203761241],
              [-0.210202522537613, 53.412000473577805],
              [-0.209973658516214, 53.411287747290324],
              [-0.208153263593274, 53.409386967702069],
              [-0.206021806700735, 53.408411032534794],
              [-0.208259451342487, 53.407003563825342],
              [-0.213004409837997, 53.404312103437348],
              [-0.214231231309425, 53.404868049035123],
              [-0.218440665390653, 53.40302599240043],
              [-0.216502681139047, 53.40156228603],
              [-0.218563493034832, 53.399797787239628],
              [-0.219501377174445, 53.399490841269063],
              [-0.221755645082706, 53.399682658174676],
              [-0.22379772582386, 53.398902175121009],
              [-0.224676264087878, 53.39958588108599],
              [-0.229393078995909, 53.398034165282318],
              [-0.228329830194799, 53.396038838436851],
              [-0.227533166131741, 53.392516495004855],
              [-0.223440553694618, 53.385846349362552],
              [-0.216622204331547, 53.382012104412205],
              [-0.215932796573203, 53.380520277372682],
              [-0.206832901489154, 53.374239357528538],
              [-0.204870352935188, 53.371862603869637],
              [-0.210338571254179, 53.370115283508063],
              [-0.215458109841818, 53.366630156470016],
              [-0.218650156847611, 53.363720178254546],
              [-0.219499911553591, 53.362371793784966],
              [-0.220651810024155, 53.36177766276041],
              [-0.222535626023323, 53.357967963345068],
              [-0.223791237814474, 53.357333095001643],
              [-0.225052364941714, 53.355877521414612],
              [-0.22731329696129, 53.355021131392192],
              [-0.230482800849616, 53.354536869965187],
              [-0.23052252129153, 53.352163237780324],
              [-0.231635474759044, 53.351374244189131],
              [-0.233792983215965, 53.351084359410649],
              [-0.235082703312447, 53.350487636193279],
              [-0.240771169958584, 53.349058641403133],
              [-0.242397048745589, 53.348393977561699],
              [-0.243403261800254, 53.347306638612871],
              [-0.244565664990626, 53.347389370186384],
              [-0.245693006408814, 53.3466795696636],
              [-0.250788430956957, 53.345440956112547],
              [-0.251179605147679, 53.344692442171763],
              [-0.255899021504609, 53.344117843793292],
              [-0.255105462324147, 53.340485135465542],
              [-0.255665772810344, 53.340447479719906],
              [-0.254068057106491, 53.337971682820005],
              [-0.249777682922207, 53.333586503978324],
              [-0.25126110628741, 53.332788380759744],
              [-0.25260224038488, 53.331538662687564],
              [-0.255441618139004, 53.329930576225109],
              [-0.264806188572, 53.326454122554296],
              [-0.265694447186978, 53.326618966806265],
              [-0.267609534054532, 53.32601210795994],
              [-0.269129157521234, 53.324972464295939],
              [-0.270320053818533, 53.323111758218261],
              [-0.271953186589165, 53.322194188779442],
              [-0.274979762404533, 53.321755236214585],
              [-0.27554032843687, 53.321816380596189],
              [-0.278241982700908, 53.324412111014773],
              [-0.285288067926285, 53.328318982138562],
              [-0.293312794358168, 53.334452717642506],
              [-0.298510070082154, 53.331035311102163],
              [-0.297302782468207, 53.329675012152038],
              [-0.299621384017977, 53.326692002106874],
              [-0.298649426364858, 53.325608359131138],
              [-0.299325918797669, 53.325361795079154],
              [-0.299955633502165, 53.325196367857167],
              [-0.300187763595235, 53.324645004698858],
              [-0.300810792761267, 53.32476085853547],
              [-0.300924540907128, 53.324316584444077],
              [-0.301437758404326, 53.32421062096541],
              [-0.301249341041066, 53.323888800303685],
              [-0.307419942410029, 53.320790520916169],
              [-0.308369764326894, 53.320728478711892],
              [-0.308539471888161, 53.320423433992076],
              [-0.310261093169354, 53.319978564214026],
              [-0.309795543514977, 53.319108049855245],
              [-0.308490485191075, 53.319080562707384],
              [-0.299900893659551, 53.31051972101946],
              [-0.295572970235405, 53.302957741964285],
              [-0.296213620029448, 53.302735856220373],
              [-0.300191520525492, 53.302863631823847],
              [-0.305530273558915, 53.301976785109481],
              [-0.317461286566142, 53.29822702585237],
              [-0.316689489731683, 53.296522454092894],
              [-0.314423441535446, 53.294013754055321],
              [-0.314879405454491, 53.291425733479976],
              [-0.314569651639531, 53.290375839256178],
              [-0.313204875492274, 53.288877716488471],
              [-0.311418821694552, 53.287679267425617],
              [-0.314274769229833, 53.285175547530763],
              [-0.313354816512186, 53.283128139097059],
              [-0.315013966626873, 53.281257434476068],
              [-0.314875297583695, 53.280245016673263],
              [-0.315560737425153, 53.279597543305805],
              [-0.316444100776275, 53.279447301929082],
              [-0.314483162682804, 53.277288108187363],
              [-0.311413291676852, 53.277013636809286],
              [-0.30892695299273, 53.276281688446872],
              [-0.303728299700138, 53.275342124932003],
              [-0.301896598254271, 53.275316053897072],
              [-0.302082577025401, 53.273385880519989],
              [-0.304686670262726, 53.269580663388624],
              [-0.304732102073794, 53.268697604900048],
              [-0.303198934416358, 53.268448352444864],
              [-0.300965752940521, 53.269743456964754],
              [-0.299110144042497, 53.268587871998754],
              [-0.300109357067567, 53.267289599407839],
              [-0.300899214259425, 53.267223544910756],
              [-0.301121385240519, 53.266423015455949],
              [-0.300909852775831, 53.265707103583097],
              [-0.299849834793307, 53.265478033702578],
              [-0.298715549293372, 53.265722560548134],
              [-0.298571531269282, 53.264968951016961],
              [-0.300526578935387, 53.264671400441472],
              [-0.300826600184738, 53.263949293931539],
              [-0.297648919702077, 53.263848227203042],
              [-0.294226413745506, 53.264285663734881],
              [-0.28981900210945, 53.263964515477078],
              [-0.291000197006826, 53.262468452724193],
              [-0.295151200319399, 53.263125710050346],
              [-0.299557097435149, 53.263181438157886],
              [-0.300611756972267, 53.263090394777294],
              [-0.300435052021474, 53.262063927841218],
              [-0.296835938968741, 53.262158203763505],
              [-0.291734634127547, 53.260969573932393],
              [-0.293055719115372, 53.259345141838068],
              [-0.287142816265559, 53.257505506458777],
              [-0.28881134195925, 53.255553484790923],
              [-0.290904977110757, 53.254741163262537],
              [-0.28784759360397, 53.253034158153696],
              [-0.280244823521639, 53.249740490113382],
              [-0.281405913075091, 53.248594836850295],
              [-0.282546521670103, 53.245975427408865],
              [-0.282715406721754, 53.243666548216602],
              [-0.281782359249494, 53.241069393755659],
              [-0.282473791009941, 53.240006858659562],
              [-0.281490707871353, 53.238922885749467],
              [-0.279347848313229, 53.237988481607474],
              [-0.276225935882943, 53.234608998835242],
              [-0.27596295954325, 53.23343470257057],
              [-0.276502775134776, 53.231782963057086],
              [-0.277290289790594, 53.231200111998],
              [-0.277207045162397, 53.230735925718129],
              [-0.278715917574702, 53.228864440876201],
              [-0.278897484996545, 53.227773883515496],
              [-0.279822342411257, 53.226602358728982],
              [-0.283752819378207, 53.224404342433793],
              [-0.28912686125034, 53.222770823334379],
              [-0.287565985984045, 53.221532063620231],
              [-0.291956598213999, 53.219342189417652],
              [-0.292732598777269, 53.219376682978343],
              [-0.299474096637817, 53.215109349914982],
              [-0.301090468074039, 53.213737164233038],
              [-0.30226619644641, 53.210401566534252],
              [-0.294721691155647, 53.201614917362633],
              [-0.290935410296071, 53.194723780845926],
              [-0.291933691802557, 53.190812166179668],
              [-0.288001252275322, 53.184731517416282],
              [-0.289653931567309, 53.179342370052531],
              [-0.290299837884012, 53.178867080372648],
              [-0.291248235148822, 53.178913052677288],
              [-0.294544967391797, 53.179853005617623],
              [-0.295815506938428, 53.181172941955722],
              [-0.298587397285596, 53.181654872396081],
              [-0.309637491025592, 53.182000973497573],
              [-0.318910166207775, 53.184597239722883],
              [-0.325123791705622, 53.190596557831967],
              [-0.32552810699681, 53.195547613064136],
              [-0.328973100692479, 53.199770908204364],
              [-0.330792505185955, 53.201610579506976],
              [-0.335738341664615, 53.202329735483048],
              [-0.337122086961238, 53.202979248523789],
              [-0.337550056376692, 53.203720595954309],
              [-0.336820607675071, 53.208445406219695],
              [-0.34228627389846, 53.212324297965417],
              [-0.346741097021337, 53.214630962905638],
              [-0.347727725007482, 53.217013482818672],
              [-0.34653508120796, 53.218619616181158],
              [-0.34653631574907, 53.220337600711481],
              [-0.348070137046225, 53.224034833893725],
              [-0.350083807739226, 53.225388734617773],
              [-0.3513152440779, 53.227298143839334],
              [-0.352199603265083, 53.227831786375475],
              [-0.353257526670916, 53.22798125612281],
              [-0.357496500066438, 53.227490468826609],
              [-0.358272023014903, 53.22816910055932],
              [-0.359522283577158, 53.227637934438285],
              [-0.359553514891538, 53.227173588579433],
              [-0.361731839298718, 53.22666056366711],
              [-0.361710554441621, 53.225687568418813],
              [-0.362898808832261, 53.225640068330364],
              [-0.364253767918684, 53.226013771770027],
              [-0.366657742528599, 53.225394077956373],
              [-0.36972252888662, 53.226384469127311],
              [-0.370813823593108, 53.226207907885964],
              [-0.373905948442069, 53.227706490098612],
              [-0.378791395281595, 53.231192776064525],
              [-0.381403475571865, 53.231929483024217],
              [-0.399549212726974, 53.230510504025411],
              [-0.406141510148071, 53.230624911302144],
              [-0.413600939285065, 53.230140990399832],
              [-0.430851774537288, 53.230487111492749],
              [-0.444432710912354, 53.229705023313791],
              [-0.462028647987922, 53.227235549161762],
              [-0.475685591630986, 53.227135005349822],
              [-0.480842643684456, 53.226140959905315],
              [-0.488318449859669, 53.225271414966613],
              [-0.495119045630108, 53.225237011730094],
              [-0.496424088133374, 53.233695699838492],
              [-0.493915020899668, 53.233380848947917],
              [-0.495748910355026, 53.240133680854875],
              [-0.496286172905487, 53.240324747584189],
              [-0.494342646831419, 53.241817672866738],
              [-0.495813619748696, 53.243801384961095],
              [-0.495440682608425, 53.244350442434332],
              [-0.496011797344175, 53.247167819000602],
              [-0.497566042370905, 53.249177727873494],
              [-0.500593434750446, 53.248241362776909],
              [-0.502449982375088, 53.250572342575715],
              [-0.50621020878208, 53.249896748323202],
              [-0.514163712805138, 53.253772684630043],
              [-0.514872040020272, 53.25396579751127],
              [-0.523274829035241, 53.253807673299377],
              [-0.523274916000739, 53.253369879655104],
              [-0.526507318446081, 53.253831485666211],
              [-0.538579959686081, 53.254540130313089],
              [-0.544777698516013, 53.253896769525596],
              [-0.554691661296716, 53.252126851165848],
              [-0.561727435533217, 53.251237463847602],
              [-0.595112175682127, 53.245148665264821],
              [-0.597432611077551, 53.247241786158554],
              [-0.601996384449944, 53.2504111647063],
              [-0.611474465003293, 53.256402873512258],
              [-0.612204677764165, 53.256073368909831],
              [-0.619466547121862, 53.256570289884934],
              [-0.620901728036339, 53.257018394866002],
              [-0.622481193561606, 53.258109101356112],
              [-0.631791887204472, 53.256380647149506],
              [-0.631515617666942, 53.255267273157948],
              [-0.638439278569289, 53.25419048851456],
              [-0.648377409496025, 53.253146614945315],
              [-0.647287864350767, 53.249101572579399],
              [-0.646441509842326, 53.247757026742974],
              [-0.647831506574709, 53.244075418443906],
              [-0.648689522480812, 53.243288677011726],
              [-0.652926315266578, 53.242707359670732],
              [-0.653647808561244, 53.242351435145466],
              [-0.665517467258999, 53.240678902525239],
              [-0.666577942518685, 53.239681249082061],
              [-0.680610693849059, 53.251971358810771],
              [-0.682352906397939, 53.251515981853821],
              [-0.683542095888482, 53.254524403255971],
              [-0.688027801486821, 53.258370086554699],
              [-0.69075968058396, 53.25766657557056],
              [-0.691782254869245, 53.257856688823622],
              [-0.693524380806314, 53.257900061098539],
              [-0.694948750096128, 53.25843434932959],
              [-0.712976145997931, 53.2581160964871],
              [-0.719520567372743, 53.257133894333087],
              [-0.721767731084919, 53.256075680063184],
              [-0.724069037147487, 53.255561862315112],
              [-0.725926367527421, 53.25525720507558],
              [-0.727129669287164, 53.255421977425193],
              [-0.734151509874785, 53.253982914369949],
              [-0.734822966110993, 53.253020084632858],
              [-0.735076031776544, 53.251453220127694],
              [-0.734180251414249, 53.249048037154552],
              [-0.734865849020737, 53.247653865338584],
              [-0.735084348542121, 53.243852764010484],
              [-0.740578239011616, 53.243647535624866],
              [-0.747215372887697, 53.24437009271648],
              [-0.760367597432074, 53.245186265586554],
              [-0.760753629016999, 53.245622668111785],
              [-0.763212169015562, 53.245856750464888],
              [-0.771054075134189, 53.246139201070577],
              [-0.771050566774597, 53.246732463648044],
              [-0.776511252625944, 53.246751767878862],
              [-0.776304035389406, 53.248446834022097],
              [-0.777702072157615, 53.2505610895792],
              [-0.777911538045954, 53.252099518512267],
              [-0.776695948137439, 53.252467303329539],
              [-0.772087185965812, 53.252298574327639],
              [-0.76980544210449, 53.252679576251332],
              [-0.769783740051026, 53.253906400819211],
              [-0.772085817020266, 53.255853853214028],
              [-0.771666838498244, 53.257816409718551],
              [-0.772557115244618, 53.258503374688125],
              [-0.772415085836683, 53.261107926393485],
              [-0.764939220161252, 53.26993288830171],
              [-0.763721873060258, 53.271847590843635],
              [-0.763653633770959, 53.27306943425706],
              [-0.764809775602851, 53.274372283400929],
              [-0.767963698228352, 53.275515089366309],
              [-0.772157472181754, 53.278058270921399],
              [-0.774203857382741, 53.280446209545936],
              [-0.777150297689941, 53.28210352143131],
              [-0.777223647920672, 53.283842807150258],
              [-0.774085663898731, 53.28641120536777],
              [-0.766497679604682, 53.291275303667625],
              [-0.764757850215574, 53.293183709795763],
              [-0.76411091814255, 53.294961387451963],
              [-0.764248338595424, 53.298154015731335],
              [-0.762553627114951, 53.299163027153448],
              [-0.76076360738163, 53.299055451584302],
              [-0.759342247842302, 53.297928700173451],
              [-0.759039806793546, 53.297181239299654],
              [-0.759604589768793, 53.293638131719639],
              [-0.758154159655413, 53.292538029862385],
              [-0.75669070383433, 53.292302546917135],
              [-0.753523584631722, 53.292457370918314],
              [-0.750037488858924, 53.293310835348059],
              [-0.745220163237392, 53.29294473325708],
              [-0.7500926023101, 53.293735709080927],
              [-0.746225781775908, 53.303486300171016],
              [-0.746848977294641, 53.305368917923964],
              [-0.751886679403051, 53.309394101867802],
              [-0.752952159511709, 53.311562675417875],
              [-0.753296874027441, 53.313486391642698],
              [-0.753213459386458, 53.31892494002939],
              [-0.752690241313734, 53.320548330370769],
              [-0.749529071637259, 53.322584577263292],
              [-0.748512101359763, 53.322654818950852],
              [-0.749407088962125, 53.322751398495697],
              [-0.749225677346788, 53.323821019774186],
              [-0.749681938526261, 53.324597081961087],
              [-0.751498179743385, 53.325488059233884],
              [-0.753695736437595, 53.32561081272879],
              [-0.758196400339202, 53.324756110602991],
              [-0.759906821045685, 53.325004922625695],
              [-0.761836213980583, 53.327233621322698],
              [-0.76593220872453, 53.330100476745564],
              [-0.765202446450419, 53.331347828263702],
              [-0.761549246993494, 53.332010234844056],
              [-0.760096977763889, 53.333517036305992],
              [-0.759497071239294, 53.33644040095011],
              [-0.756180351237875, 53.339405593823038],
              [-0.756322857343398, 53.340702430386926],
              [-0.757700662601052, 53.342081348384376],
              [-0.763249012198929, 53.344735065437362],
              [-0.764290632124743, 53.34632346565823],
              [-0.76363596364007, 53.348234988820991],
              [-0.762321279356996, 53.350057863891237],
              [-0.759436657869112, 53.351254048793756],
              [-0.758326470064951, 53.352182778861028],
              [-0.758091519004891, 53.353856826478626],
              [-0.759660479834792, 53.354878136243329],
              [-0.763735664496483, 53.355537972453334],
              [-0.765449559223517, 53.356334177514341],
              [-0.772829161854055, 53.364158071617538],
              [-0.774569937408653, 53.364925653092264],
              [-0.775809036556384, 53.365003987341261],
              [-0.776787745353276, 53.364729062171676],
              [-0.778599562863397, 53.363436089470426],
              [-0.779570661564807, 53.361952915508461],
              [-0.780615838694796, 53.361662458204641],
              [-0.785198221136474, 53.362910134695483],
              [-0.789887538317959, 53.363204066118612],
              [-0.790407735468399, 53.363461035435762],
              [-0.789490552957936, 53.365121929356569],
              [-0.790104631978181, 53.368272570605562],
              [-0.788731982529382, 53.369560284816522],
              [-0.785154941032679, 53.374575857531518],
              [-0.781097355887271, 53.376068054893082],
              [-0.77515172435693, 53.377076020222475],
              [-0.774673083217333, 53.377725521339393],
              [-0.775577979355189, 53.378933961303538],
              [-0.775327749147897, 53.379114774133875],
              [-0.774405983717499, 53.378494051556196],
              [-0.774643183722139, 53.379133819031118],
              [-0.780032200775707, 53.382989626335778],
              [-0.781056881470437, 53.383276957868553],
              [-0.780815322107298, 53.382575134337685],
              [-0.781709593370621, 53.38311192640009],
              [-0.780932867919254, 53.383513005785787],
              [-0.782108542537566, 53.384041880408098],
              [-0.783001753581545, 53.383721085391812],
              [-0.789712771290776, 53.38581006075313],
              [-0.788755515462946, 53.386162617161546],
              [-0.783955711862397, 53.386069784688118],
              [-0.777228107846375, 53.389291113986332],
              [-0.77571980999787, 53.391233498668193],
              [-0.776579509048083, 53.393557017996407],
              [-0.776490910194787, 53.396489271361673],
              [-0.777501983598605, 53.397944182601009],
              [-0.778996250180067, 53.399282672442943],
              [-0.78524881576394, 53.402231950064909],
              [-0.787654600544735, 53.404431766401039],
              [-0.790974380367953, 53.410853989252111],
              [-0.790695478570871, 53.411776145849004],
              [-0.787874586197235, 53.414262706196581],
              [-0.788036620450861, 53.415231579216702],
              [-0.789409155444718, 53.415749787440092],
              [-0.793730928367887, 53.41493505179465],
              [-0.796509478891514, 53.415270507979805],
              [-0.804258297365257, 53.417788872345497],
              [-0.808427377479028, 53.420119157077444],
              [-0.817378966502572, 53.426531033294943],
              [-0.817504398846988, 53.427814116218592],
              [-0.815451844677272, 53.432187636810113],
              [-0.818546282704952, 53.436648086817584],
              [-0.820015520722485, 53.440017320698004],
              [-0.81991816092596, 53.44070581904127],
              [-0.818966843375773, 53.44124295307541],
              [-0.811586990231001, 53.442583753101353],
              [-0.809019955389657, 53.443500244898267],
              [-0.804679704035727, 53.447294329485324],
              [-0.801961183551338, 53.451064077864253],
              [-0.799920427378487, 53.453104788082122],
              [-0.793097393684294, 53.456808772857578],
              [-0.785220999079412, 53.461913847574166],
              [-0.783596563118246, 53.464362120812417],
              [-0.78494686377662, 53.473625578887621],
              [-0.784005883787192, 53.476184175170751],
              [-0.78257707183915, 53.477251010293386],
              [-0.779582687929325, 53.477998922693722],
              [-0.777642914839754, 53.47822539982009],
              [-0.772569687166393, 53.478110484856224],
              [-0.771509614495047, 53.478606575533867],
              [-0.770620469323712, 53.479652746443001],
              [-0.770897486232937, 53.482894344801011],
              [-0.774710017688471, 53.487712952993952],
              [-0.774995409761847, 53.488740626479668],
              [-0.772870821262481, 53.491018226307069],
              [-0.769007947771147, 53.493245540545274],
              [-0.76779871928189, 53.494296483327034],
              [-0.767758431348439, 53.497610318643154],
              [-0.767233049477483, 53.498884038792625],
              [-0.765904842402246, 53.499871711281337],
              [-0.763784846926258, 53.500296562092537],
              [-0.761160460930357, 53.500438389171052],
              [-0.754595746486966, 53.49968342045598],
              [-0.752256967358401, 53.499905332590771],
              [-0.75105552742594, 53.500516625529826],
              [-0.748124878701936, 53.504399795332787],
              [-0.742109613886944, 53.510384473900686],
              [-0.738576527566451, 53.519826170765931],
              [-0.73011533843655, 53.519405815550229],
              [-0.726353211402611, 53.518916350697168],
              [-0.713884133501143, 53.516825070466744],
              [-0.701575520347556, 53.51416162245409],
              [-0.699935177790021, 53.514588756188907],
              [-0.700162943973898, 53.515129677120527],
              [-0.699754589285986, 53.515364346026416],
              [-0.698118280073206, 53.515510141368601],
              [-0.697719281110595, 53.516127839170906],
              [-0.695438983152529, 53.516271975622821],
              [-0.6921921533845, 53.515918293476382],
              [-0.691016317099498, 53.516193975535472],
              [-0.688879718893517, 53.515737292488524],
              [-0.687352049413681, 53.516367743887336],
              [-0.686452711557496, 53.515992012083643],
              [-0.684709050063621, 53.514098621680979],
              [-0.683860605619638, 53.51417558029064],
              [-0.683590434527127, 53.514523179965558],
              [-0.681662962416773, 53.514573855491406],
              [-0.680744023093066, 53.513520986327954],
              [-0.678642574140012, 53.513687450139464],
              [-0.678037827365971, 53.513251087032444],
              [-0.677591005788088, 53.513555370331758],
              [-0.676125736144872, 53.513003405420818],
              [-0.675703948635319, 53.51250433457183],
              [-0.658347590535716, 53.51383897781453],
              [-0.644248816208508, 53.513226886772038],
              [-0.624488528987901, 53.512817803731174],
              [-0.628190635055445, 53.506801656613064],
              [-0.625768709099698, 53.49865124079917],
              [-0.625723957448802, 53.495559353102422],
              [-0.628641013951735, 53.49074409792258],
              [-0.628448789457589, 53.488759780264239],
              [-0.631291468006095, 53.488290769624619],
              [-0.630131741503211, 53.485972660165707],
              [-0.634055857661015, 53.485377540828715],
              [-0.631991640669026, 53.481332175436876],
              [-0.632408469939356, 53.480085651952827],
              [-0.634334358522166, 53.479990814291718],
              [-0.632596010495031, 53.47537746567626],
              [-0.633539660037321, 53.474942395196663],
              [-0.632545589697665, 53.473805578724424],
              [-0.634434982132206, 53.47300826765737],
              [-0.634121863086942, 53.472624446705993],
              [-0.634797693705842, 53.472363392247523],
              [-0.633469302559912, 53.471222763212424],
              [-0.633344180215796, 53.470380842901946],
              [-0.634804243962589, 53.4695300741799],
              [-0.629025396328655, 53.465032240994248],
              [-0.626646975007497, 53.462039389765565],
              [-0.627158344387175, 53.460382261488732],
              [-0.629750132401361, 53.458197068354522],
              [-0.621670431479736, 53.457799405878923],
              [-0.613399939166429, 53.456928845202725],
              [-0.599477399047125, 53.456379929819349],
              [-0.597168107512822, 53.456713308655182],
              [-0.584480860769852, 53.456842346177119],
              [-0.566050192891255, 53.458103114480863],
              [-0.551790552217539, 53.459497968269204],
              [-0.551707374424999, 53.460791419170235],
              [-0.529710117217544, 53.463793084695411],
              [-0.512695583708205, 53.467758961788938],
              [-0.508776393261133, 53.467737085219106],
              [-0.508912515776664, 53.467506858926626],
              [-0.50528704773419, 53.467958675120137],
              [-0.505317212963231, 53.468172099847912],
              [-0.500030165254584, 53.469599880633901],
              [-0.483549348956996, 53.472397015097229],
              [-0.47174668945244, 53.474850878482769],
              [-0.471841223902195, 53.475323130515456],
              [-0.473560617627824, 53.47662069652074],
              [-0.481504677096314, 53.478350773371233],
              [-0.48615758307645, 53.479885854921193],
              [-0.486627328837977, 53.480448244917213],
              [-0.487777249655628, 53.482945657710516],
              [-0.48768653953497, 53.483801194172941],
              [-0.488640769997798, 53.485066378818502],
              [-0.487445701185951, 53.486038289897024],
              [-0.487208299433763, 53.487246149012826],
              [-0.487769139114785, 53.487792606549782],
              [-0.487219012168184, 53.488724131402748],
              [-0.48605721085693, 53.489223610839979],
              [-0.485005004009228, 53.490326753780991],
              [-0.483180541365816, 53.493427408172735],
              [-0.480683769335037, 53.496223742110672],
              [-0.482400696806251, 53.50036536167196],
              [-0.483784990510823, 53.50154165184523],
              [-0.485143577986093, 53.503567988944667],
              [-0.486492665307606, 53.504510077836755],
              [-0.488056416152194, 53.504590993449661],
              [-0.488380833098445, 53.504838706499207],
              [-0.481316709952349, 53.506945261554684],
              [-0.478561899153922, 53.508372795025196],
              [-0.475835635782225, 53.508957431536146],
              [-0.470686917284541, 53.509095734584662],
              [-0.466964571866375, 53.509697986696565],
              [-0.461556515098081, 53.509956623900571],
              [-0.45236604073856, 53.50868916027617],
              [-0.449969713612707, 53.508651820821946],
              [-0.447546350999527, 53.509148049879514],
              [-0.444033623802486, 53.509370263834988],
              [-0.439005664532289, 53.510895833206369],
              [-0.437704778802276, 53.51083928656336],
              [-0.435557328847589, 53.511329889929215],
              [-0.427318664260601, 53.512226804142024],
              [-0.425011543830137, 53.512707027981477],
              [-0.421599711401618, 53.514017629052631],
              [-0.419467674025202, 53.5141656531028],
              [-0.417665930067137, 53.514677586690574],
              [-0.415527586539788, 53.514669940848265],
              [-0.413478492487806, 53.515503050105352],
              [-0.405071459394894, 53.517595388647578],
              [-0.406853643914647, 53.52014516998284],
              [-0.405823960708919, 53.522219680034127],
              [-0.406168901989137, 53.524483311759305],
              [-0.408509776753803, 53.527270637686051],
              [-0.40948678326143, 53.529098593050513],
              [-0.407821812016017, 53.530086839853936],
              [-0.408142069307393, 53.532087643747843],
              [-0.411999203419658, 53.534531899903151],
              [-0.412347076712915, 53.535235891646138],
              [-0.414397001343766, 53.535820431557106],
              [-0.418283351084791, 53.534786886025465],
              [-0.42185928477101, 53.534426915818578],
              [-0.427395993642013, 53.540927237239579],
              [-0.427683816845564, 53.544237298408582],
              [-0.429297827792951, 53.545076536619774],
              [-0.430661641006598, 53.546316090256482],
              [-0.433724922450816, 53.545842958249501],
              [-0.437040530937984, 53.545933979417171],
              [-0.439320397500967, 53.546404229628912],
              [-0.442584338795363, 53.546218450658898],
              [-0.446402020732948, 53.544552946393239],
              [-0.450337952920737, 53.54402150607288],
              [-0.450676994571101, 53.543126968669171],
              [-0.454176062068607, 53.542941223993523],
              [-0.463415049496495, 53.540824654431738],
              [-0.473927438887023, 53.539066124177182],
              [-0.481364015104876, 53.538224111321668],
              [-0.501226297722874, 53.537440033556528],
              [-0.499757791721872, 53.538898553307],
              [-0.49657971478931, 53.540091966713284],
              [-0.49406757043115, 53.541779103645169],
              [-0.491870367251283, 53.543941197271721],
              [-0.490905894806955, 53.545852730602824],
              [-0.483638789183957, 53.545257412309034],
              [-0.483267794942224, 53.545587108271846],
              [-0.481100140692829, 53.545806802506661],
              [-0.47652846093366, 53.545891582285662],
              [-0.475072672659109, 53.547384124610616],
              [-0.474856092468324, 53.548118482749551],
              [-0.473041338010212, 53.548802782031373],
              [-0.467015382694768, 53.548939639029314],
              [-0.466709816499279, 53.549709698479624],
              [-0.467152564666931, 53.553664360355796],
              [-0.466503688711548, 53.55536939696519],
              [-0.465668604698779, 53.556002318778802],
              [-0.462727112907509, 53.555937581055161],
              [-0.462450653612459, 53.556484170809952],
              [-0.460902200489325, 53.557302954485515],
              [-0.460851868942824, 53.558024145195809],
              [-0.459035777231268, 53.55851944270016],
              [-0.458478129450722, 53.558991385259752],
              [-0.457654524573482, 53.558846827790937],
              [-0.456905396248394, 53.559269549656705],
              [-0.455273460367523, 53.559141512632152],
              [-0.453260547933589, 53.5595245117972],
              [-0.452379607100334, 53.559420527188955],
              [-0.450772743776892, 53.557945259854272],
              [-0.449416472574757, 53.561262708557095],
              [-0.447671115746629, 53.562663972203254],
              [-0.446418253103559, 53.562983905434116],
              [-0.445087696455461, 53.563816103655839],
              [-0.442522227660069, 53.566243093770595],
              [-0.441958570427304, 53.567610214450362],
              [-0.44035042524026, 53.568756965419624],
              [-0.439403678143518, 53.57027189657375],
              [-0.434091230925475, 53.572796780725291],
              [-0.428997265717979, 53.57450357918237],
              [-0.428189530849697, 53.572581846324368],
              [-0.424324231661078, 53.569631049385329],
              [-0.419665629223288, 53.563751714508783],
              [-0.408839465708897, 53.562097983346433],
              [-0.400103949339288, 53.561398949169266],
              [-0.388345124906815, 53.559729975381721],
              [-0.373228966860595, 53.558941027500452],
              [-0.368751890848182, 53.559308844376389],
              [-0.368368229197296, 53.558960216669483],
              [-0.362798803956451, 53.558603641278978],
              [-0.35988995054711, 53.557810476843613],
              [-0.356320399890585, 53.556031913425777],
              [-0.349376283244576, 53.555787953618477],
              [-0.347930850582023, 53.556037678847083],
              [-0.345851890055246, 53.55793717406231],
              [-0.348655361290421, 53.559491528840951],
              [-0.335935265395139, 53.558605066022544],
              [-0.330400346726458, 53.566236367533079],
              [-0.329863143239559, 53.566303480132639],
              [-0.328779207146273, 53.568517718364994],
              [-0.326804142870604, 53.571114120982052],
              [-0.322828567544353, 53.575328423813886],
              [-0.323603193151331, 53.576020679970306],
              [-0.320010482208508, 53.57894038493815],
              [-0.320942497450267, 53.579549468466567],
              [-0.317617117146924, 53.580936562418756],
              [-0.318143408447222, 53.581301741495913],
              [-0.316294726471442, 53.5822447973663],
              [-0.316934278365624, 53.582687086756458],
              [-0.315400273670432, 53.583198568699892],
              [-0.315676855870563, 53.583765200829717],
              [-0.314281951073919, 53.583930736265003],
              [-0.31439094707131, 53.585557559597284],
              [-0.312437964423066, 53.585736784309859],
              [-0.312870613521451, 53.587049948284353],
              [-0.312089294338051, 53.587093763240865],
              [-0.312292918668753, 53.587708815470272],
              [-0.310790028163661, 53.587699291778371],
              [-0.310913708815237, 53.588272765253109],
              [-0.310327154735309, 53.588319318366139],
              [-0.310863699660584, 53.590132869387183],
              [-0.306976385017282, 53.590239734480967],
              [-0.303419668206101, 53.590768271292127],
              [-0.304573467139018, 53.593839233609366],
              [-0.300738768953389, 53.595261796991913],
              [-0.304048838581291, 53.600524421170832],
              [-0.303160552758697, 53.601420657687633],
              [-0.303833229683325, 53.601869777177392],
              [-0.303074476782979, 53.602361527647723],
              [-0.303419607068039, 53.602725099125877],
              [-0.303126561110973, 53.602871965987511],
              [-0.305088986645295, 53.604704851949535],
              [-0.305003087421921, 53.605793151356266],
              [-0.306186895668702, 53.607371373121318],
              [-0.305750206784875, 53.607971975879131],
              [-0.306805472799481, 53.609553764111695],
              [-0.308550358350386, 53.61430686041453],
              [-0.306294946982679, 53.614038540846479],
              [-0.304939117616438, 53.614259361514122],
              [-0.30439492991666, 53.614446722340418],
              [-0.304417690495114, 53.614821902582719],
              [-0.300925417428584, 53.616366192823612],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000143",
        LAD13CDO: "33UB",
        LAD13NM: "Breckland",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.85301365040479, 52.807182721359581],
            [0.854865136752743, 52.807085113770903],
            [0.858406014150024, 52.807631100450301],
            [0.865401189901738, 52.807402956136471],
            [0.866819274333397, 52.804035172887815],
            [0.86996129449591, 52.801411606979933],
            [0.871720955900433, 52.798079553356004],
            [0.873300641449988, 52.797871331954703],
            [0.87414885753905, 52.798247347483596],
            [0.876108197225874, 52.797295198353332],
            [0.87783746037718, 52.797510432819159],
            [0.880180713601947, 52.796397823502843],
            [0.880575656697506, 52.793911688968649],
            [0.881880213420544, 52.793449250508331],
            [0.883598344211484, 52.793430864334141],
            [0.88559517537956, 52.792737526079108],
            [0.886697178060569, 52.792376182540707],
            [0.88738216435696, 52.792684136705823],
            [0.888314253398996, 52.79255712422885],
            [0.887741652399102, 52.792064789235994],
            [0.888553833612239, 52.791583690519097],
            [0.890770211102355, 52.790691575110785],
            [0.892115085341146, 52.793030118986245],
            [0.89184688423151, 52.793481791130496],
            [0.893059915564149, 52.794391026214207],
            [0.900498579635822, 52.796000635654408],
            [0.90450594280878, 52.797339566011928],
            [0.906872532049523, 52.79849917427007],
            [0.912859365356676, 52.800145395715887],
            [0.915056550869251, 52.801162425454109],
            [0.915633741806342, 52.802115839510485],
            [0.917627755552846, 52.803287094921693],
            [0.917996371883401, 52.802787029893956],
            [0.919672609542586, 52.802270964794346],
            [0.920136582474213, 52.8026183460616],
            [0.922019460933035, 52.801710476926402],
            [0.922101435074675, 52.801185087306145],
            [0.923783601711631, 52.80082528815057],
            [0.923971823239727, 52.799164207999667],
            [0.924817667090008, 52.799144248802136],
            [0.925336243128671, 52.798691718723724],
            [0.924430207800828, 52.798500041062972],
            [0.92483510679567, 52.798122257328153],
            [0.923953511664212, 52.797520809303457],
            [0.924746967536216, 52.797361857120848],
            [0.925137499018601, 52.79681446599038],
            [0.926214722054469, 52.796595447331924],
            [0.927206383536058, 52.795831779192518],
            [0.926755703846135, 52.795085724093063],
            [0.926964863239111, 52.792854886396654],
            [0.928337013672735, 52.791374994097545],
            [0.929749341849573, 52.791597297422761],
            [0.930510863322236, 52.789333018091263],
            [0.932204434843968, 52.789084320225236],
            [0.932730703512385, 52.78963064780973],
            [0.933239470048919, 52.789475979748559],
            [0.93300818960085, 52.789059046361963],
            [0.934956055635809, 52.789040525277095],
            [0.938423098729575, 52.787947481761037],
            [0.938553936048579, 52.791295802773064],
            [0.936825040833605, 52.795555287959232],
            [0.950765213490929, 52.804675812586041],
            [0.952761233696129, 52.804131535094044],
            [0.95796117255794, 52.804072993026239],
            [0.96034004441631, 52.803770831957287],
            [0.963163072460006, 52.802974617966044],
            [0.963281557943909, 52.803342156439555],
            [0.966530460588712, 52.803070284773227],
            [0.967643562463575, 52.807712346026754],
            [0.973838532048222, 52.807549971624653],
            [0.97378905159662, 52.807238265909554],
            [0.975741610062706, 52.806994146919905],
            [0.975444105989546, 52.804703072325651],
            [0.977106366710267, 52.804816029633805],
            [0.977124884877484, 52.804348842341746],
            [0.980928911450759, 52.804577922033886],
            [0.980685516242018, 52.801368238016678],
            [0.984905316731231, 52.801129005595904],
            [0.985974075758699, 52.801416845563942],
            [0.987943595227349, 52.800667601253089],
            [0.988517227495179, 52.799512865051391],
            [0.989969263614889, 52.798273031319333],
            [0.993575887245257, 52.798334915387279],
            [0.993654595844176, 52.797385989854547],
            [0.994940353983207, 52.796320253657122],
            [0.996930635837955, 52.792513684962572],
            [0.996165738380454, 52.792263231416314],
            [0.996632264899382, 52.791734357347742],
            [0.996026007783675, 52.790856694006457],
            [0.993524542264685, 52.788907289001848],
            [0.991929285384954, 52.786607632616537],
            [0.991374736572938, 52.785465153500986],
            [0.991681120686115, 52.785369294046411],
            [0.990348359313182, 52.784038710434039],
            [0.992256581495794, 52.783986071565664],
            [0.992150083750965, 52.780079596469662],
            [0.999080599989293, 52.779025890518774],
            [1.002228839001289, 52.776180022555678],
            [1.002270242387643, 52.774240123633206],
            [1.001693114825427, 52.772426498439486],
            [1.001086288184433, 52.772464343557687],
            [1.00022024892423, 52.765760537367044],
            [1.003186701148527, 52.765241177024329],
            [1.011239714357873, 52.762961338539739],
            [1.017426998375239, 52.76475275547169],
            [1.02820587732019, 52.768585840152149],
            [1.03154519566823, 52.769272979928715],
            [1.033090398117869, 52.769169605256607],
            [1.03383592826266, 52.769655928277224],
            [1.036362546343144, 52.769720737723048],
            [1.040468271909885, 52.769062441432396],
            [1.041359061650072, 52.770205970801833],
            [1.042027459493429, 52.770201410927932],
            [1.044750190550961, 52.769018188576467],
            [1.046598516975531, 52.767587589973942],
            [1.051528488358464, 52.763436447281059],
            [1.051549870638307, 52.763085172339828],
            [1.05330751232059, 52.761932880635634],
            [1.052726836334609, 52.75963035566604],
            [1.054962029880526, 52.757065534759086],
            [1.055063949969188, 52.756107856465512],
            [1.054350589689374, 52.755932892652012],
            [1.051067980603676, 52.750387852235207],
            [1.053680335628745, 52.749215340552965],
            [1.055564667860154, 52.747425738623292],
            [1.055784374717917, 52.746393977001922],
            [1.057876507727737, 52.745563922962468],
            [1.058288231129125, 52.745073973919453],
            [1.059566527826526, 52.744917776940774],
            [1.060344722890579, 52.743758274607039],
            [1.061548784653493, 52.743157021516538],
            [1.06231585933852, 52.743022992349083],
            [1.063559878267069, 52.743435094443768],
            [1.067303398110004, 52.743208766481551],
            [1.075627589905089, 52.741662963360525],
            [1.078128135452806, 52.741589948105279],
            [1.079701949053454, 52.741235203628946],
            [1.080917072908252, 52.740186508755563],
            [1.080702966283107, 52.73696357764706],
            [1.081273278822788, 52.735576413460606],
            [1.082513245096414, 52.734819327078355],
            [1.082163857284456, 52.732094531667592],
            [1.082825663078649, 52.729717542136285],
            [1.087403295992619, 52.728588440613585],
            [1.088531045008831, 52.72316951071997],
            [1.090532520813481, 52.722589429874837],
            [1.090513063631484, 52.721665449959239],
            [1.091879345996621, 52.721235908314412],
            [1.093904192397014, 52.719691101015172],
            [1.096285960601466, 52.719506577128563],
            [1.098111651420925, 52.717683616964443],
            [1.099519823143153, 52.717262781749653],
            [1.099806409010626, 52.716355075320109],
            [1.103497612740332, 52.716281825757392],
            [1.105180796990169, 52.714668426105185],
            [1.103974288707274, 52.71279799920157],
            [1.099687053664895, 52.711938090467449],
            [1.096444641258866, 52.70952099971516],
            [1.092442864702183, 52.707624562768707],
            [1.089919911722989, 52.705551837689413],
            [1.087364327291083, 52.704543792805062],
            [1.085951385070823, 52.70434768640343],
            [1.08470256746417, 52.703686844358081],
            [1.084272927148123, 52.702297809093182],
            [1.087054069663983, 52.699207289115712],
            [1.087384808462394, 52.698377600869996],
            [1.087109525848454, 52.697644644134982],
            [1.087650625205125, 52.696691663015564],
            [1.089639710783653, 52.696141582425881],
            [1.094584183083854, 52.693638334862108],
            [1.100751838923315, 52.68971162332074],
            [1.10169846508287, 52.68845118271615],
            [1.102027369034473, 52.686703297769832],
            [1.101740437995782, 52.685954489593506],
            [1.102449346222132, 52.685829804034029],
            [1.102674201871307, 52.685286124587918],
            [1.105226221120569, 52.684316334282336],
            [1.10595995576061, 52.684771034162111],
            [1.108299419019224, 52.682498246733566],
            [1.105910917315057, 52.679724467086871],
            [1.106541425561496, 52.679497491079708],
            [1.104672763108395, 52.678228095256934],
            [1.102996782027593, 52.67589782741684],
            [1.099871806768435, 52.672825767561136],
            [1.099767103802463, 52.67038686951976],
            [1.100629967470063, 52.666842564021174],
            [1.100462167431775, 52.664849578375566],
            [1.098416290498524, 52.660103481055998],
            [1.097672236581585, 52.649733152827231],
            [1.093342778655483, 52.651032538108048],
            [1.089620929619895, 52.647344498444447],
            [1.082084678156542, 52.648585915056479],
            [1.078962066708731, 52.648715719776646],
            [1.076679373445527, 52.644994349700752],
            [1.076740544062857, 52.640328926100572],
            [1.076299163792021, 52.639495941061462],
            [1.075981494851163, 52.639624702769645],
            [1.074626963050058, 52.638144545962575],
            [1.073695801266916, 52.639389990442808],
            [1.074201051705721, 52.639911962870997],
            [1.073997723880157, 52.642281533399519],
            [1.073016254104715, 52.643696448150337],
            [1.071590083490013, 52.644484380527096],
            [1.0712903438145, 52.644321287122381],
            [1.068120682681261, 52.645358533752209],
            [1.06602552366479, 52.647215923063051],
            [1.064022253811772, 52.650262479232417],
            [1.062607060536487, 52.653940390970064],
            [1.061498855069201, 52.655074297929261],
            [1.059985121520899, 52.655717759795174],
            [1.058404628482289, 52.658560831604113],
            [1.057308492822484, 52.659214154962761],
            [1.055820869989805, 52.661251710129996],
            [1.055082697460055, 52.662563957688668],
            [1.055398632694801, 52.663318417608231],
            [1.054408187417201, 52.663305297806893],
            [1.054782493925417, 52.661441275235042],
            [1.054342184963179, 52.660578507884033],
            [1.054962509959312, 52.660566103595627],
            [1.054782802189888, 52.658923211628867],
            [1.053358264170118, 52.659046284994304],
            [1.052845604020259, 52.656487489201687],
            [1.051698407855247, 52.65653594501677],
            [1.05146654740812, 52.655988847401446],
            [1.050791790466387, 52.65600443504767],
            [1.049046870802619, 52.651864911799699],
            [1.047315809115276, 52.652212539857992],
            [1.044960030286834, 52.648701105790714],
            [1.042735388761413, 52.649179168746713],
            [1.04307332154731, 52.646487859377622],
            [1.037127369631854, 52.644216903502979],
            [1.033753208417529, 52.643550689972557],
            [1.03158246082759, 52.642562200503086],
            [1.026054727722498, 52.641513831079671],
            [1.023530644924797, 52.639728453001062],
            [1.024193791440679, 52.639012759254072],
            [1.023277383934324, 52.636465061551831],
            [1.02522659673088, 52.635986303749739],
            [1.025579771659744, 52.630011308244988],
            [1.021579580603847, 52.62324640312859],
            [1.020093103538596, 52.619580109785417],
            [1.019627117641297, 52.61624659220417],
            [1.018843416404128, 52.615722503705442],
            [1.022316769042978, 52.614604167851915],
            [1.024379433323535, 52.614927392679064],
            [1.025556343220804, 52.614596948623316],
            [1.028187127846812, 52.615572868568869],
            [1.028517861714613, 52.616361191037271],
            [1.029972388758358, 52.61731140457173],
            [1.032406627260614, 52.616937010753283],
            [1.033034563123295, 52.616552207384814],
            [1.034326945204805, 52.616502897671388],
            [1.034536311005902, 52.616069460907788],
            [1.036878442461662, 52.615774675413611],
            [1.036918677622941, 52.615288916893995],
            [1.035746624171078, 52.615048290678821],
            [1.036198678734896, 52.61212384198349],
            [1.037310451725308, 52.610236450594513],
            [1.037288015572617, 52.608995082954102],
            [1.039409535313203, 52.606012476318057],
            [1.038021699831883, 52.604188338784205],
            [1.037679376411414, 52.602593658967372],
            [1.037587547879314, 52.595976205953363],
            [1.039119133926022, 52.595636515607438],
            [1.03913339139944, 52.594306970312012],
            [1.040304171677993, 52.593833538685395],
            [1.039791353760822, 52.592676710257535],
            [1.040041752249559, 52.590833887409005],
            [1.039463102261372, 52.588149921652466],
            [1.038132265192728, 52.588442198282749],
            [1.037603105528648, 52.587431468620778],
            [1.032101858649884, 52.588847772357802],
            [1.027005138762316, 52.589390134473852],
            [1.021789529370707, 52.586534134966563],
            [1.009893483276458, 52.587872405599832],
            [1.002376129964167, 52.591280168920484],
            [1.000002409542951, 52.59180980151006],
            [0.995103623935945, 52.591865536052389],
            [0.993100763252159, 52.591620338396105],
            [0.987518918724033, 52.593030288965906],
            [0.984789849104702, 52.593332966840684],
            [0.979588611450904, 52.592633979759938],
            [0.975385762744194, 52.594396221574613],
            [0.971851761813537, 52.595136105449335],
            [0.963755241139476, 52.594621500682891],
            [0.961860292143181, 52.593107766965566],
            [0.961602064720725, 52.592454148286414],
            [0.961548036147647, 52.591062495563591],
            [0.96274216873859, 52.589020887802853],
            [0.962322970173257, 52.586725593074085],
            [0.962857780421229, 52.585920825939304],
            [0.954563314121263, 52.586913282558683],
            [0.95368946328119, 52.584919782916508],
            [0.962557169241844, 52.583457092127119],
            [0.962165623637027, 52.581154808535075],
            [0.961571803056482, 52.580646282352781],
            [0.960822968922049, 52.580619157114732],
            [0.960325984499387, 52.579948127581837],
            [0.958478659051266, 52.579809072629331],
            [0.955416007777758, 52.580278593539752],
            [0.950057331029672, 52.57786461114268],
            [0.947005458876417, 52.575810234039764],
            [0.949930161677358, 52.574301188466151],
            [0.952684077600458, 52.567597514479566],
            [0.960016306563946, 52.562408831896136],
            [0.960848396548283, 52.561289071623008],
            [0.964271006275361, 52.560619725440937],
            [0.966026772034029, 52.559894953996761],
            [0.967337410441453, 52.559855794230423],
            [0.968257710493288, 52.55873466584822],
            [0.973486018336467, 52.554771462559735],
            [0.976005017263574, 52.554429339680631],
            [0.976195828541536, 52.553935320215523],
            [0.977332768593388, 52.55342558657874],
            [0.981213157518411, 52.552535580173839],
            [0.983140645021299, 52.552415047315833],
            [0.986151953372473, 52.55120055708575],
            [0.989569162073558, 52.551705091775283],
            [1.001825253959983, 52.549595218557847],
            [1.001817786075635, 52.549313025890704],
            [1.004890822510165, 52.549767395956117],
            [1.007324326435205, 52.548183028980645],
            [1.011333530770246, 52.543287721975283],
            [1.012042402032201, 52.54321391659969],
            [1.012051249555257, 52.542912422736585],
            [1.015999771780396, 52.542931421092888],
            [1.016379024967151, 52.542331803166647],
            [1.023775924162769, 52.542347990084373],
            [1.028199512594993, 52.541233891343396],
            [1.029225621916594, 52.53970666393365],
            [1.031965540689355, 52.538051871877805],
            [1.037944694787685, 52.53664210993449],
            [1.041817177066588, 52.536145085705321],
            [1.056007679341257, 52.535440245230291],
            [1.061376321054226, 52.537161204056488],
            [1.062481632298101, 52.536556125597201],
            [1.063752700995637, 52.535197608299036],
            [1.07004007924034, 52.531736758767273],
            [1.073630205211505, 52.530692028344099],
            [1.079062006733693, 52.52796248188222],
            [1.081181044008985, 52.525205718348914],
            [1.085186667380242, 52.522611951614557],
            [1.089696029387865, 52.522096721084147],
            [1.092882735531139, 52.52120312485016],
            [1.095231647949885, 52.518000309279572],
            [1.091657465232802, 52.516480410612026],
            [1.089504426784764, 52.514559056608157],
            [1.085475604048665, 52.512455497481831],
            [1.083915453626144, 52.512762397564543],
            [1.080529930972117, 52.508155253406564],
            [1.079025793186815, 52.506858928572754],
            [1.080914155822411, 52.504989500367046],
            [1.080962316849616, 52.503129333591815],
            [1.079344880311166, 52.500863791999933],
            [1.079364496064264, 52.499268771666301],
            [1.082478541597028, 52.484661646818942],
            [1.087671889472946, 52.481222870594621],
            [1.087407149931102, 52.48074504502236],
            [1.084471539219223, 52.478978048657304],
            [1.085588825531528, 52.470995192469218],
            [1.087978834376509, 52.470404869759655],
            [1.090613304626417, 52.468681240273497],
            [1.091716092774861, 52.468783702790006],
            [1.095249215939276, 52.470362199491149],
            [1.09595976384638, 52.469156467854624],
            [1.096793921570143, 52.4656649781734],
            [1.095260715983508, 52.463982011757977],
            [1.094622315905832, 52.460256591465225],
            [1.073148527878037, 52.454758997252284],
            [1.064067362043652, 52.442089386565094],
            [1.065934919271252, 52.44083944037105],
            [1.065172463940136, 52.436726788764467],
            [1.062847969936471, 52.43319243221957],
            [1.065549521246155, 52.432049470751245],
            [1.067473730194343, 52.428805100644247],
            [1.058742267509794, 52.426835286860531],
            [1.059086825191045, 52.426499909042711],
            [1.058891922782643, 52.424514732781759],
            [1.057047519489495, 52.420036129866965],
            [1.051862361126764, 52.41918361420494],
            [1.045391954558911, 52.419932440313204],
            [1.045696377189286, 52.420891372834312],
            [1.042772253488882, 52.425633333816634],
            [1.03484075005877, 52.422027771790034],
            [1.028256765376647, 52.419671396127974],
            [1.021240392488344, 52.416358942984914],
            [1.021552886777777, 52.415422847743301],
            [1.023917715117797, 52.415463068287707],
            [1.024364689508243, 52.415912082035305],
            [1.028190060745424, 52.417261112300544],
            [1.028224857637736, 52.414700736589722],
            [1.034074530545702, 52.415756653565097],
            [1.03137413999613, 52.410248340881012],
            [1.029508377049368, 52.410812414543166],
            [1.028748990922584, 52.410010799430012],
            [1.02646751316089, 52.408778733731694],
            [1.02310978435065, 52.408406947559861],
            [1.02138899704443, 52.405951741935006],
            [1.022869010825673, 52.404629641537966],
            [1.02309414518942, 52.403557278688176],
            [1.021328937567872, 52.400944028602851],
            [1.021713287059328, 52.40039370213546],
            [1.023962362834275, 52.399291130994591],
            [1.024328344906964, 52.398623453654331],
            [1.02319592203936, 52.397758509354865],
            [1.023581167081469, 52.397093039175495],
            [1.020710675379208, 52.395339944908265],
            [1.020206268494616, 52.394197207133047],
            [1.0229223875119, 52.392576387665933],
            [1.023550118383972, 52.389632107599788],
            [1.025399045641918, 52.386170011191631],
            [1.027215307255494, 52.384789768342962],
            [1.029928311941614, 52.378716269265709],
            [1.02994204650093, 52.378230278044292],
            [1.029078248097589, 52.378256032893809],
            [1.029069144037313, 52.378573730252178],
            [1.027656970392195, 52.37855328260116],
            [1.021436235319976, 52.378013810842418],
            [1.014173012561998, 52.375673188133618],
            [1.006785108646918, 52.375723911814767],
            [1.00343886898557, 52.374572483198349],
            [0.998634225425648, 52.371737590594535],
            [0.997783498731298, 52.371520867030966],
            [0.990888945565986, 52.370795451405371],
            [0.988372087100034, 52.371535456040803],
            [0.987107921058166, 52.371505384246909],
            [0.980290461616373, 52.369500364121407],
            [0.977957018425529, 52.369998096040099],
            [0.967442116446307, 52.370093697052617],
            [0.965274884719488, 52.370377458600395],
            [0.963147371482318, 52.371583783154762],
            [0.956985275165422, 52.371893809871835],
            [0.955307946990849, 52.372827016501809],
            [0.954553782029017, 52.372799121167041],
            [0.954668459408382, 52.373636213479152],
            [0.95377170703291, 52.373827715898578],
            [0.954084280459366, 52.374525861025631],
            [0.950869720719778, 52.375127823416271],
            [0.949622239694477, 52.376164418872044],
            [0.948878224802309, 52.376267533158199],
            [0.948277133550662, 52.375759138498033],
            [0.946931525683998, 52.375963590565121],
            [0.946511436663908, 52.376870687663292],
            [0.945371496638403, 52.377383850898276],
            [0.945116504470374, 52.377857852620785],
            [0.943532260914818, 52.37820940747357],
            [0.942245595084475, 52.378925843756818],
            [0.941659596914204, 52.379837054767073],
            [0.941657620091319, 52.381088947107266],
            [0.940883995519897, 52.381831259052021],
            [0.940595370333969, 52.382419400728367],
            [0.940004485916886, 52.382484471397952],
            [0.940303703295611, 52.382917686098146],
            [0.937348936828439, 52.384256534408046],
            [0.936513766168712, 52.385202691587601],
            [0.936779253663637, 52.386633189973672],
            [0.936199609843862, 52.386558568287391],
            [0.935400733356524, 52.38712250677726],
            [0.93367259192156, 52.38726076682569],
            [0.932694058337728, 52.387953253087723],
            [0.931458268033558, 52.387869716158477],
            [0.931666995972734, 52.388313291435821],
            [0.930978965439835, 52.388222448476832],
            [0.931067040202244, 52.388575490287124],
            [0.93042237388581, 52.388340577827613],
            [0.930204204884732, 52.388819026739164],
            [0.928482901787245, 52.388262773767181],
            [0.9272472832863, 52.388248435240989],
            [0.925271562372083, 52.388800093286164],
            [0.925243987903332, 52.389272013669313],
            [0.92417701580892, 52.389141052696708],
            [0.923951599026875, 52.389599883923012],
            [0.923362744372863, 52.389319485533953],
            [0.922859310091814, 52.390019913962291],
            [0.922303677024545, 52.3894859828522],
            [0.921301237768293, 52.389553045637761],
            [0.921548241730267, 52.388864362424918],
            [0.920690430738038, 52.389162562186996],
            [0.920354495647514, 52.388905572286937],
            [0.919964429986922, 52.38934238607451],
            [0.918802381223802, 52.388661552391135],
            [0.915790856446126, 52.388954459375796],
            [0.914779393923412, 52.388617001752735],
            [0.913090384829897, 52.388748597612377],
            [0.910977100460684, 52.387883403466915],
            [0.911083615870236, 52.387447313248103],
            [0.910401417846533, 52.387555852897258],
            [0.910058502583935, 52.387281020228428],
            [0.909184580419087, 52.387491400937137],
            [0.907687058388835, 52.386198201121893],
            [0.906987556737287, 52.386311643627842],
            [0.904899785727891, 52.385764068614634],
            [0.903129056541319, 52.386642156135629],
            [0.901782598941629, 52.386371276055662],
            [0.900708328537675, 52.386619789581445],
            [0.899565148690557, 52.387543562843824],
            [0.898984724558155, 52.387456187756534],
            [0.897412783549312, 52.388230385383643],
            [0.89450135197749, 52.388122801194626],
            [0.893430404669593, 52.38844580819184],
            [0.892488238516937, 52.389030046628086],
            [0.892898223980431, 52.389522716414241],
            [0.892299146332897, 52.390296396626979],
            [0.891097517978856, 52.39077995895903],
            [0.883565002262057, 52.391197889177398],
            [0.880914714572237, 52.391739182213705],
            [0.874712111441852, 52.389893845353164],
            [0.865555892759511, 52.390523692342207],
            [0.863430500694204, 52.391273983027517],
            [0.861675102874595, 52.393017986181398],
            [0.85968040262937, 52.393405323509086],
            [0.856845450302468, 52.39516728610942],
            [0.856891294386688, 52.396945879489152],
            [0.857080196649441, 52.398071723034846],
            [0.855412083182603, 52.399422323072855],
            [0.849224241676013, 52.399539344605067],
            [0.840903139065171, 52.400777513433908],
            [0.838288850428686, 52.400705455275798],
            [0.835417048080745, 52.400010916989046],
            [0.834276619657972, 52.399169568955415],
            [0.833104966363123, 52.398895510829632],
            [0.82983890103966, 52.397275033143565],
            [0.829356242005794, 52.396119317591427],
            [0.82761915619422, 52.394886616334091],
            [0.824360527785587, 52.394548196820615],
            [0.823110369302197, 52.393709367050327],
            [0.821018711057735, 52.393642425810725],
            [0.820472011911005, 52.393391089111368],
            [0.819669628822004, 52.393809526325484],
            [0.818313197516694, 52.393653942894382],
            [0.816578007583635, 52.393944427773491],
            [0.81598805797793, 52.393521438811845],
            [0.814785111823387, 52.393398127761131],
            [0.812756357853928, 52.390899672658627],
            [0.808144973700507, 52.389505775385686],
            [0.804361630971089, 52.389483238579423],
            [0.803629870226521, 52.38923172176851],
            [0.800697536815913, 52.389765308286961],
            [0.796103824925129, 52.391223049540635],
            [0.794825796180097, 52.391095917522684],
            [0.791972839294306, 52.389840534904543],
            [0.787874637137165, 52.387598290965151],
            [0.786544537910204, 52.387202515054589],
            [0.785900225908026, 52.386470409426799],
            [0.783734139629243, 52.386241796595158],
            [0.779522070164211, 52.387250149733703],
            [0.777326470166977, 52.386738840803012],
            [0.77522708068822, 52.386940157752974],
            [0.773407596797984, 52.38630842942127],
            [0.772541957113285, 52.386660582745748],
            [0.770268618905739, 52.389786746853282],
            [0.768119596410943, 52.389996293685286],
            [0.7672739833191, 52.390388405299568],
            [0.765939180586242, 52.390338729829857],
            [0.762653822263041, 52.388947087071962],
            [0.762411205075753, 52.388098448457214],
            [0.760673389022033, 52.386747892682649],
            [0.759109014682579, 52.386479586142357],
            [0.754283395811509, 52.384253181169477],
            [0.752513943766419, 52.38404082830521],
            [0.751205135403412, 52.383389667187785],
            [0.750841326039974, 52.383542023512241],
            [0.750457519897531, 52.383041971211526],
            [0.749817232579821, 52.382907598044966],
            [0.746883164461238, 52.383345462238694],
            [0.746717447185983, 52.383800750828705],
            [0.744850777733058, 52.384886396401043],
            [0.744610054059364, 52.385933347428001],
            [0.743370911976454, 52.387375786452992],
            [0.740939222128891, 52.388170533212573],
            [0.740536302420218, 52.38892627640324],
            [0.741234657106006, 52.389474816539689],
            [0.741363594379758, 52.390179551451588],
            [0.740859461504633, 52.39117595094298],
            [0.741790243682719, 52.392675021013453],
            [0.741097585222758, 52.392646130522365],
            [0.740970887382086, 52.392214722053495],
            [0.738586984701524, 52.393073954979727],
            [0.737562651821886, 52.395119255161788],
            [0.735233441991833, 52.395321582901808],
            [0.733318033193496, 52.395369509353934],
            [0.722192878739538, 52.393673160749287],
            [0.716006899879773, 52.394001680690884],
            [0.712411565780586, 52.394604054411239],
            [0.708157975341783, 52.396028947817165],
            [0.694711282108965, 52.39684358970996],
            [0.690743139338184, 52.397512252576298],
            [0.684517787389551, 52.399116960472632],
            [0.672409084548608, 52.404770816020147],
            [0.668437944176475, 52.409157209148034],
            [0.671972989587717, 52.412441442825944],
            [0.673794657193758, 52.415303036023779],
            [0.680585724942215, 52.420967373026954],
            [0.683129406871848, 52.422550791046454],
            [0.684717132908323, 52.424835714360277],
            [0.690077657331373, 52.426723657517584],
            [0.69138799323993, 52.427707287589314],
            [0.692196419750489, 52.429047652903328],
            [0.69612419068719, 52.43326196380923],
            [0.705031839020443, 52.438290316504045],
            [0.716201159351052, 52.445481838862861],
            [0.71818879159379, 52.44757458878],
            [0.718879242424478, 52.449560462886922],
            [0.716835701788212, 52.449717159082013],
            [0.715835183997117, 52.449268039385544],
            [0.714858935909282, 52.449930738027753],
            [0.713284125926381, 52.450099087593536],
            [0.707479192698778, 52.451993836445013],
            [0.706485765904721, 52.452571427875533],
            [0.705562681210235, 52.452568278971953],
            [0.701132270222446, 52.451318914983283],
            [0.696904161998969, 52.451352506083275],
            [0.694446594933582, 52.450998488813347],
            [0.68394895576208, 52.453146419866158],
            [0.681480102074661, 52.454156555495075],
            [0.67859257766469, 52.454600604367855],
            [0.67476067493635, 52.4576539983427],
            [0.672591514732622, 52.458928735758498],
            [0.671951230562814, 52.460291185035217],
            [0.668302994099469, 52.462064172700103],
            [0.666132012407242, 52.462487239605231],
            [0.663518099948113, 52.462110023347336],
            [0.660559895904621, 52.460124549863053],
            [0.658777267456683, 52.459492017139723],
            [0.658146154439448, 52.458741848694466],
            [0.656673865015268, 52.458225503480087],
            [0.656307348661912, 52.457248164217674],
            [0.654655551461037, 52.457367998540647],
            [0.65137525556024, 52.456942531866815],
            [0.649878549827578, 52.455649701759476],
            [0.648494312800514, 52.455346202290428],
            [0.649305271346002, 52.454854128092251],
            [0.649251875101115, 52.45445516003263],
            [0.647149006579663, 52.45289441508563],
            [0.646620734965076, 52.453534816514136],
            [0.64538855525097, 52.453605560256619],
            [0.645182638309617, 52.452576934465583],
            [0.642952178590018, 52.452297698901695],
            [0.642196176577926, 52.451028677616065],
            [0.638656835623808, 52.451011514225428],
            [0.636093208896401, 52.451361863626111],
            [0.633523411412811, 52.450351738260437],
            [0.628822904807272, 52.450063393095974],
            [0.627182765291043, 52.450845327840057],
            [0.628054930008054, 52.45115596367642],
            [0.62939442837846, 52.452565865330314],
            [0.6297741135478, 52.454046566046323],
            [0.622457513374228, 52.453682144563182],
            [0.622153326210038, 52.454334547549657],
            [0.620375806761246, 52.454418923052479],
            [0.619382184108579, 52.452707206426588],
            [0.621893665364395, 52.451674055337634],
            [0.621843223661123, 52.451246235742332],
            [0.61900758018566, 52.449520501774956],
            [0.615576248740533, 52.448889670247411],
            [0.614994579968906, 52.449058107616537],
            [0.609835920918573, 52.450300656856456],
            [0.608456215372927, 52.451609827389674],
            [0.60934489672209, 52.451977787187644],
            [0.609455161353882, 52.452697442535033],
            [0.601867895064306, 52.452927623373284],
            [0.600837126977001, 52.453294713364521],
            [0.599638393275008, 52.453209574507305],
            [0.598389628935765, 52.453974403143619],
            [0.597285824882827, 52.454201886679783],
            [0.595823569348381, 52.454266376154287],
            [0.59320913762829, 52.453610659965094],
            [0.591458218532868, 52.453972779659843],
            [0.588406785004898, 52.453518972956928],
            [0.586825770187669, 52.452913323412233],
            [0.586919729618147, 52.452507510747765],
            [0.586291728846426, 52.452189433660081],
            [0.582476981410336, 52.451842994871235],
            [0.582554895881578, 52.463886529729692],
            [0.583650291231611, 52.468008960981017],
            [0.590781576082204, 52.484306181800932],
            [0.596871881927057, 52.495149524800624],
            [0.601972586115579, 52.508795662026841],
            [0.60449723717761, 52.519062369199879],
            [0.608434560881872, 52.526723383771596],
            [0.613344247392384, 52.531614748817759],
            [0.612468851397943, 52.532814772449555],
            [0.612103845415149, 52.534713912803355],
            [0.610688229465716, 52.535658776796801],
            [0.609802829527716, 52.538780651958611],
            [0.608717610080921, 52.540102172353301],
            [0.604545965165555, 52.542104938547865],
            [0.605113481433112, 52.543145439805208],
            [0.602204474245173, 52.543760680496263],
            [0.601156121012663, 52.543442026306828],
            [0.597739653363588, 52.543258108708812],
            [0.596742311776643, 52.543827630259756],
            [0.595881339438688, 52.543759297939047],
            [0.594290339536863, 52.545792272012349],
            [0.592943984918019, 52.545697691173288],
            [0.593090896317061, 52.546713293498918],
            [0.591532375500941, 52.547048679130413],
            [0.590270803692234, 52.548118504509709],
            [0.58789295323756, 52.55217837402644],
            [0.585726976970713, 52.553250838744681],
            [0.586395104009376, 52.555279271201073],
            [0.583994461552672, 52.554998106176221],
            [0.582900184057827, 52.556009342252942],
            [0.581344628265977, 52.555974944682319],
            [0.580116431756842, 52.557973722522433],
            [0.579458900872581, 52.558334257112023],
            [0.579852077636874, 52.559527944572451],
            [0.577944078173174, 52.55995169366156],
            [0.576841964609372, 52.561085335263449],
            [0.575873525551063, 52.561076744728148],
            [0.572496110690327, 52.561909152833508],
            [0.571453326704963, 52.562572060196025],
            [0.568976220925157, 52.562578200724012],
            [0.567421574263114, 52.564912138746287],
            [0.565324985767868, 52.564917139617641],
            [0.562215979971151, 52.565932266262358],
            [0.561290746340645, 52.566590737925672],
            [0.559751101150132, 52.565492834945879],
            [0.558368438193362, 52.566000216809904],
            [0.557523249197152, 52.565651604592404],
            [0.556780083451757, 52.565788157374293],
            [0.556299867501914, 52.564635844940135],
            [0.555040939184093, 52.564682814286805],
            [0.553511778052905, 52.565158232500309],
            [0.550545589715341, 52.565155653806585],
            [0.54895700903565, 52.566032443502635],
            [0.543621461170339, 52.565071780344958],
            [0.541165962900718, 52.565504897970371],
            [0.539013293125103, 52.565229182597882],
            [0.536594316072596, 52.565784613341584],
            [0.535812140447728, 52.566780617309995],
            [0.535050068216111, 52.567000165307],
            [0.535167463611466, 52.567730508223292],
            [0.532628813036066, 52.574559515621416],
            [0.530499529267065, 52.576105840306539],
            [0.532868932029589, 52.578058599650525],
            [0.535762396528149, 52.57982923686329],
            [0.538057621450764, 52.586238171754118],
            [0.539842568410716, 52.586672870806225],
            [0.541533245072749, 52.585911818823085],
            [0.54288088521548, 52.585958409613582],
            [0.543943650575633, 52.58620983342179],
            [0.544023200507562, 52.586538134209952],
            [0.545883964064357, 52.58670045062091],
            [0.546494343240645, 52.587373419507024],
            [0.547537889315519, 52.587877903348897],
            [0.548357187584123, 52.587825198777963],
            [0.549062343916419, 52.588527586770425],
            [0.550567666191685, 52.588999619171801],
            [0.552430721171787, 52.58914829611254],
            [0.553226945049151, 52.589790247447041],
            [0.554802863807199, 52.590281385813448],
            [0.556110685830392, 52.590300810911877],
            [0.557206725237503, 52.590892200191689],
            [0.557973573908174, 52.590521341822189],
            [0.559072709138717, 52.590785333550137],
            [0.55933026371315, 52.591632219507758],
            [0.561563861346933, 52.593371543515836],
            [0.562201908865202, 52.595170549330625],
            [0.564154692336091, 52.59644561507038],
            [0.564856720270915, 52.597593090020375],
            [0.56558438729172, 52.60162917764756],
            [0.565024356634523, 52.60541440974901],
            [0.566085024392626, 52.607993746103737],
            [0.567086524888383, 52.614078585575328],
            [0.561629215164493, 52.613164487362646],
            [0.575451128921934, 52.64517600682894],
            [0.578487776605311, 52.6534932660362],
            [0.573046418754619, 52.653795035059808],
            [0.562157022533132, 52.655273791553149],
            [0.56437899084566, 52.663044302081595],
            [0.564219180213225, 52.666376619065488],
            [0.564944550294121, 52.668508216770348],
            [0.564717021449741, 52.671567740979228],
            [0.563370622386706, 52.673204658773457],
            [0.553181678188397, 52.678613077842137],
            [0.550962914025454, 52.680498838703009],
            [0.55507877121554, 52.681914511821986],
            [0.556059830239845, 52.682752087968659],
            [0.557763422052966, 52.683166722029561],
            [0.55914658293294, 52.682832923854782],
            [0.560157478403406, 52.682964847901445],
            [0.562814220567072, 52.684141116113828],
            [0.566246293743752, 52.686474894215507],
            [0.568677963192015, 52.687062434164616],
            [0.571019112043129, 52.689079824168601],
            [0.57237621903038, 52.688527931286657],
            [0.575155967881175, 52.689595151006252],
            [0.577928825897824, 52.68958880615758],
            [0.578785239787155, 52.690460382624806],
            [0.5820757928346, 52.691225880564083],
            [0.584048081957118, 52.692095577845784],
            [0.584926760111531, 52.692213990499233],
            [0.586092948870438, 52.691788396796014],
            [0.588566849896982, 52.69285478731603],
            [0.590174958082766, 52.693044446624377],
            [0.592225026841025, 52.693898821307229],
            [0.595661296137677, 52.694018723755875],
            [0.598193546882987, 52.694964942420093],
            [0.601589854913409, 52.695481201627913],
            [0.604314948449381, 52.696281881175629],
            [0.605694086826637, 52.697220908683555],
            [0.607478100266193, 52.69745232633845],
            [0.610424847320341, 52.698788414473491],
            [0.611971343390297, 52.699948485980478],
            [0.614357121625861, 52.699868896582046],
            [0.618348680746402, 52.700329282605644],
            [0.622652177632828, 52.701670153113781],
            [0.626000412523086, 52.701791129592884],
            [0.630439811318263, 52.69765789476083],
            [0.629811354381352, 52.697298670776462],
            [0.634610597742731, 52.693133003348805],
            [0.636399404172083, 52.693836865800094],
            [0.638278133617124, 52.691834757936789],
            [0.640564873785932, 52.692034698754469],
            [0.643952458795443, 52.691642629820969],
            [0.647730182717415, 52.690655455643423],
            [0.647949116571191, 52.693294262920716],
            [0.649000312102245, 52.693374175916176],
            [0.648525697218811, 52.695312708316216],
            [0.649343935514817, 52.701026026147403],
            [0.64984985350078, 52.701386989386897],
            [0.651337828071162, 52.701294356788104],
            [0.652524955962309, 52.701879256511418],
            [0.654549786897388, 52.701510194829474],
            [0.655223834003533, 52.701884454098888],
            [0.653967601595779, 52.702405368160512],
            [0.653739763809258, 52.704041651301182],
            [0.654102103468472, 52.704109070467965],
            [0.6594089813055, 52.704762517106296],
            [0.659984733524325, 52.703860267203524],
            [0.662625191510615, 52.703949336747662],
            [0.662832877922798, 52.703057140534007],
            [0.671495699252466, 52.702837874042899],
            [0.671614643593043, 52.701756127132093],
            [0.672352758266983, 52.701940908533466],
            [0.672490331945638, 52.701479202960776],
            [0.672843580446346, 52.701494615060881],
            [0.672673339220018, 52.700622613425736],
            [0.673021828879949, 52.700366567372534],
            [0.672360268302384, 52.700126110537923],
            [0.674609416589904, 52.698370439045526],
            [0.676777675444701, 52.69945539567081],
            [0.67988406465227, 52.6990353820051],
            [0.681636594396651, 52.699131514916061],
            [0.681911059166997, 52.698731443125318],
            [0.683609987772268, 52.698701969398883],
            [0.683917452022063, 52.698188742351888],
            [0.688329953150109, 52.697997889856879],
            [0.689196979533566, 52.69897905243964],
            [0.689963162162014, 52.699090250115404],
            [0.689713190670455, 52.699598591454567],
            [0.69081915327957, 52.70058870613007],
            [0.69138240915531, 52.700312440848862],
            [0.692907808946994, 52.700949504694535],
            [0.694242533594585, 52.70103786120584],
            [0.696519653154634, 52.70080531804048],
            [0.699592200536132, 52.701732598026013],
            [0.699013087744489, 52.702279031749228],
            [0.700107290670767, 52.703769299716193],
            [0.698818613455579, 52.703733014034263],
            [0.699034807597869, 52.706587628638566],
            [0.69963589659294, 52.707762712702937],
            [0.705447017007693, 52.710300885031188],
            [0.707087390963736, 52.708768907633434],
            [0.708913469745657, 52.70904100771638],
            [0.709805285636963, 52.708404646365814],
            [0.711038615646755, 52.709002299123156],
            [0.710834955216939, 52.709943953729706],
            [0.710178589871458, 52.709984145374214],
            [0.709501879345661, 52.710651560121001],
            [0.708047926983418, 52.711272889899732],
            [0.708555984734862, 52.712088567512438],
            [0.708232544502663, 52.712412487651576],
            [0.71126460401819, 52.713982447126057],
            [0.710938632839303, 52.714551921590946],
            [0.711388859550001, 52.714863544752326],
            [0.710844197765635, 52.715536037948269],
            [0.711668072069944, 52.716380447158691],
            [0.713262688915571, 52.717426618682715],
            [0.717056698606732, 52.718619295641567],
            [0.717122756936368, 52.721348736119474],
            [0.718209382549963, 52.721770734629466],
            [0.716699680769313, 52.722928486353759],
            [0.715439536420958, 52.724623633423732],
            [0.704515013195117, 52.731607720248064],
            [0.700496120640743, 52.735655036283156],
            [0.701110056487316, 52.736167991937052],
            [0.69410474298217, 52.736032477585638],
            [0.688512428182619, 52.736252072381397],
            [0.680327593132038, 52.737680154600717],
            [0.675275612842601, 52.73913051991741],
            [0.668116541976495, 52.740533694432635],
            [0.668894783566201, 52.742067322240757],
            [0.673373457856587, 52.745957124270667],
            [0.68870853677755, 52.751947994442965],
            [0.687720750457222, 52.753668116962501],
            [0.691411180000405, 52.759269252371993],
            [0.688389836707751, 52.760656054046308],
            [0.689139438929524, 52.762067910765616],
            [0.690064816172711, 52.770007686839584],
            [0.695149495431388, 52.769780717267231],
            [0.695989546351944, 52.772913377299574],
            [0.699437299937293, 52.773136174794139],
            [0.700470549873241, 52.773420180248223],
            [0.699491010093783, 52.775680637896045],
            [0.699819044743448, 52.776165041664171],
            [0.697722671240714, 52.77895087013755],
            [0.695896078001758, 52.780515683730847],
            [0.698360332420846, 52.78093981718159],
            [0.698854022320492, 52.783740433321249],
            [0.700418138959635, 52.783923335897235],
            [0.699899995943495, 52.786189469990333],
            [0.701981195791441, 52.7866492280126],
            [0.70792179089962, 52.788447046104729],
            [0.708370647353083, 52.786504376373827],
            [0.717885315681843, 52.784031451784635],
            [0.7183274358206, 52.782868517191531],
            [0.7178562672226, 52.782828061317986],
            [0.717815753870043, 52.782320930215342],
            [0.730346232412118, 52.781780400431877],
            [0.730307357264639, 52.780970196173563],
            [0.741490806537033, 52.779982000908994],
            [0.751818852990565, 52.778464969281202],
            [0.752074975186164, 52.776954616666984],
            [0.75286255862708, 52.775689104898468],
            [0.755921720316208, 52.775784399932306],
            [0.758275977072878, 52.776292575967162],
            [0.75857357619233, 52.776027573959468],
            [0.75999965654387, 52.776161644942214],
            [0.763523414485019, 52.776918553406482],
            [0.765824734356241, 52.776683251356246],
            [0.769458377992122, 52.778140620514591],
            [0.769830936268507, 52.779149848539248],
            [0.773088192639828, 52.775826589561696],
            [0.774500674157965, 52.775303465169571],
            [0.777377372946925, 52.771555553953945],
            [0.779755883863564, 52.771862206153223],
            [0.78026219170955, 52.769384633652166],
            [0.787942766756563, 52.769834644576328],
            [0.795812657980199, 52.76907201846236],
            [0.799085836839909, 52.769388655571241],
            [0.801873185454257, 52.770035015392772],
            [0.79996436599421, 52.774204915614561],
            [0.799747857974465, 52.77667573888565],
            [0.801009891687357, 52.77884726737598],
            [0.802726473887035, 52.779667276055413],
            [0.803024474734084, 52.781349900350534],
            [0.806752448895786, 52.781436168868694],
            [0.80910126262447, 52.780027193110534],
            [0.810883717439776, 52.781129684618918],
            [0.810572729406468, 52.782539869942859],
            [0.809409349495727, 52.782483809814224],
            [0.809117372935203, 52.783200226778099],
            [0.809631545359127, 52.783952398229999],
            [0.810586123500194, 52.78392078414408],
            [0.811130026331559, 52.784259493831016],
            [0.811572097688793, 52.786422474432364],
            [0.816059554524208, 52.786491344054355],
            [0.816048996319861, 52.786913338394406],
            [0.819307908069623, 52.786114711082192],
            [0.819942782748551, 52.789600359373345],
            [0.819275476705925, 52.792423654050097],
            [0.818057003701716, 52.794364398896533],
            [0.821434407678269, 52.796854122544936],
            [0.824378560245604, 52.797231864138951],
            [0.829127344044164, 52.797343484413616],
            [0.832057100060317, 52.798890394315215],
            [0.832722164247252, 52.79978634220992],
            [0.831910215251274, 52.799963100485471],
            [0.832727179275977, 52.801574814669593],
            [0.830392044876047, 52.802994715398228],
            [0.829782197721154, 52.804730415028381],
            [0.831400338202003, 52.805403995089613],
            [0.832520080634362, 52.806558861335844],
            [0.83767257795013, 52.809662177448338],
            [0.839425532467917, 52.811101277182935],
            [0.840436531340079, 52.809832518663832],
            [0.842020225081443, 52.809176801596493],
            [0.85301365040479, 52.807182721359581],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000144",
        LAD13CDO: "33UC",
        LAD13NM: "Broadland",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [1.335040440303224, 52.624853584088754],
              [1.329621288217801, 52.62417783913488],
              [1.331266664952439, 52.625486894625489],
              [1.333345144645536, 52.62599857819459],
              [1.341162631333998, 52.625695408606518],
              [1.34303544802059, 52.624865463553981],
              [1.335040440303224, 52.624853584088754],
            ],
          ],
          [
            [
              [1.214449874650567, 52.829105856355127],
              [1.215402994589664, 52.828883068099898],
              [1.218087129170051, 52.829379540644716],
              [1.217826259536389, 52.828763386470932],
              [1.219321880099944, 52.828227270166593],
              [1.221472482985727, 52.828275893664141],
              [1.222096955272398, 52.82748550929454],
              [1.223722059861301, 52.827515991379634],
              [1.224286343011365, 52.826575244613025],
              [1.223447606822546, 52.82603596500968],
              [1.224418908237609, 52.825979883444937],
              [1.224305294056033, 52.825187967349876],
              [1.225205574057882, 52.825295688845323],
              [1.22828363657207, 52.824379163611205],
              [1.229662062484188, 52.824828169881862],
              [1.23006093877291, 52.824441387485777],
              [1.231956424120451, 52.823995840563768],
              [1.231208281386691, 52.823557569118705],
              [1.232428813968552, 52.82351981147017],
              [1.233208346404274, 52.822961662082179],
              [1.234118811356662, 52.823666192679646],
              [1.235765104099147, 52.823823634998533],
              [1.237579834308301, 52.823251596164965],
              [1.237844563251822, 52.822938596432365],
              [1.237474021307007, 52.822328174547977],
              [1.237969669994808, 52.821686909899341],
              [1.237560986158403, 52.820922844860505],
              [1.238201865872027, 52.820398124271833],
              [1.240058298049901, 52.8206477945579],
              [1.241176923069474, 52.820239507375803],
              [1.242693494210713, 52.820749338361829],
              [1.244435418561771, 52.819880604184817],
              [1.247522523632369, 52.821045277834578],
              [1.248151087804322, 52.820536127958363],
              [1.248885689718198, 52.82093059754127],
              [1.251062162575087, 52.820495028959925],
              [1.251191812526066, 52.820024720276649],
              [1.250136368495681, 52.819727193377936],
              [1.251540425538958, 52.818570831663699],
              [1.251292619164315, 52.818174724374217],
              [1.25068116042528, 52.8180970572391],
              [1.251089990528237, 52.817807056059202],
              [1.250655416341731, 52.817374698362919],
              [1.251326049044755, 52.815621497086838],
              [1.251014297866877, 52.815422298102],
              [1.252208902496536, 52.814365198969398],
              [1.252155278687024, 52.814024922431045],
              [1.252969145702633, 52.814329057380306],
              [1.25397972697218, 52.813531499361083],
              [1.254762460989017, 52.813419184710668],
              [1.256893808039179, 52.813893970802681],
              [1.257715585396812, 52.813450507886856],
              [1.258694525852722, 52.813619664921077],
              [1.259802358372397, 52.812695274628481],
              [1.260473971225482, 52.813121082075689],
              [1.261938884248347, 52.812644614850747],
              [1.262736129815969, 52.815340478413098],
              [1.267449798452756, 52.819029278918542],
              [1.270368915673229, 52.819294156812383],
              [1.274104796038929, 52.818792661589029],
              [1.273867709465154, 52.818127400618664],
              [1.27569198904837, 52.817297293848718],
              [1.276540705427764, 52.817410545840566],
              [1.27765190974673, 52.814878770505608],
              [1.281298255322075, 52.809296436109435],
              [1.28341375342343, 52.807690105233853],
              [1.286627816505346, 52.8088628382379],
              [1.288861620601897, 52.804640532219672],
              [1.292004299176984, 52.805007486716001],
              [1.291951318859519, 52.805229297309559],
              [1.29463140114838, 52.80560093153268],
              [1.295085333030698, 52.805931867445508],
              [1.29639695726931, 52.805967356862631],
              [1.296616107829212, 52.806784165787796],
              [1.298799010596218, 52.806938397296442],
              [1.299283136389485, 52.807255886695138],
              [1.299769395812374, 52.807190192977487],
              [1.299810815740865, 52.806346352028655],
              [1.300656714023209, 52.80624816133534],
              [1.299612994021361, 52.804715045506065],
              [1.302601701687262, 52.80463543131291],
              [1.303215138716549, 52.805425057901601],
              [1.305837567349109, 52.806540907888646],
              [1.306571253882296, 52.80732986320799],
              [1.308265998685912, 52.806703408095615],
              [1.30886415881811, 52.805239663582171],
              [1.308879321416273, 52.804365072709366],
              [1.308225486263839, 52.804001993838945],
              [1.308568960484324, 52.803698322303866],
              [1.307555124116124, 52.802952269628243],
              [1.308095801743476, 52.802001861583001],
              [1.312593243570701, 52.801529139863788],
              [1.314919656957931, 52.800861551658869],
              [1.311367029974326, 52.796018896603989],
              [1.308908109842374, 52.796287207177997],
              [1.308345626004272, 52.795679655160029],
              [1.30927106447235, 52.79491276169145],
              [1.312481314445245, 52.793638662267917],
              [1.314828231759482, 52.793265484882923],
              [1.313781616625201, 52.790448295625332],
              [1.317073188978763, 52.789137616888375],
              [1.315903619252624, 52.788662202762914],
              [1.314543019093787, 52.785519242450242],
              [1.314546853555615, 52.783112466650493],
              [1.315584593208755, 52.780629109619433],
              [1.314789335574206, 52.779728614443833],
              [1.315361322926246, 52.779370863309481],
              [1.316108382368012, 52.779462385330518],
              [1.316336005067704, 52.778652894110991],
              [1.316859238384386, 52.778453886384476],
              [1.317041126901261, 52.77731650957157],
              [1.318654417844418, 52.777351394419163],
              [1.31950553217752, 52.776667435443422],
              [1.320072512852803, 52.775349289344817],
              [1.320868413333856, 52.775109349147833],
              [1.321287431653616, 52.774458166849584],
              [1.320830310639506, 52.773382754669612],
              [1.321378421632141, 52.77329724702728],
              [1.32335918459433, 52.771345882241725],
              [1.324255726138875, 52.771240697695745],
              [1.324563022851728, 52.770603428170539],
              [1.326982939301762, 52.768911285821595],
              [1.326574255249796, 52.767052992704301],
              [1.327598834719678, 52.766858748837002],
              [1.333931665222016, 52.768042406275129],
              [1.336218366891893, 52.769382860222954],
              [1.33776633771542, 52.768335590845339],
              [1.339180287361064, 52.763893391301572],
              [1.341768574026931, 52.759883030993969],
              [1.345014282712575, 52.755772209488889],
              [1.348311237599434, 52.753244522016921],
              [1.348704842055992, 52.752888043955132],
              [1.356965876566854, 52.751293566653409],
              [1.357151877542514, 52.748798849646015],
              [1.356616460544208, 52.747522526545723],
              [1.357029071893695, 52.746220245771561],
              [1.357634923553428, 52.746370362157428],
              [1.364030861229916, 52.745593570679844],
              [1.363601752494681, 52.744775634899334],
              [1.364579237312601, 52.744421411909805],
              [1.371523553865297, 52.743228417315024],
              [1.374178560913305, 52.742069996907077],
              [1.3798514593882, 52.741191511466539],
              [1.38480431566276, 52.739648899082866],
              [1.385002140570097, 52.739352749407161],
              [1.386509389619727, 52.739336659563435],
              [1.387638148075242, 52.739980721988047],
              [1.388806207398417, 52.739088415496546],
              [1.39068266158713, 52.738503199112998],
              [1.390783598319322, 52.736884130934541],
              [1.392123932573746, 52.734644988501728],
              [1.392491258060553, 52.73245078284193],
              [1.394815281214123, 52.73105223291023],
              [1.396543203965555, 52.731019794945524],
              [1.397309294879714, 52.729079453841486],
              [1.401018805615124, 52.730808653636053],
              [1.405878862726707, 52.732487581635723],
              [1.404799990513666, 52.728817640811862],
              [1.399838496867283, 52.728569799366973],
              [1.399725728763711, 52.728091867440135],
              [1.400342296580753, 52.727372655704492],
              [1.401348099239886, 52.726991225113423],
              [1.4066348813553, 52.72588507226294],
              [1.409680506095493, 52.725637361724331],
              [1.40854315224457, 52.722756992737054],
              [1.407537671641341, 52.722210314986995],
              [1.407323605528805, 52.720947444130381],
              [1.405266336768593, 52.72140502678635],
              [1.403179591748268, 52.718671515687376],
              [1.403744962595854, 52.718514068780635],
              [1.402421782231318, 52.715674061082737],
              [1.403836579110932, 52.715375291921411],
              [1.408116620173401, 52.712640415830251],
              [1.408616393925208, 52.709024003675992],
              [1.410621634541652, 52.708723446285632],
              [1.41202306257395, 52.707972570014903],
              [1.413740220561681, 52.707605608354221],
              [1.414276630102492, 52.706662880609095],
              [1.417240970207214, 52.705942425299142],
              [1.419072369207495, 52.704804012223498],
              [1.42196110897741, 52.704633345377765],
              [1.423932880527091, 52.703493497088836],
              [1.423936265150436, 52.701779165285942],
              [1.422588299809017, 52.698726929745476],
              [1.419528506214017, 52.697235990595189],
              [1.418208723937681, 52.695257663427462],
              [1.415849189595074, 52.694634114374665],
              [1.415585545942471, 52.694215408978309],
              [1.416064114338444, 52.693482095900578],
              [1.418610052670049, 52.691644031074183],
              [1.421551516155513, 52.691477237777143],
              [1.422695594092574, 52.690700382440468],
              [1.424134904364036, 52.690291823521235],
              [1.425919828333466, 52.690138568772312],
              [1.429418423788209, 52.691282959980619],
              [1.431704779374505, 52.689842457380401],
              [1.433035817594267, 52.689451316526544],
              [1.434115909542191, 52.689374745812898],
              [1.43588324624546, 52.689917364392734],
              [1.439428369495673, 52.691141957237818],
              [1.439716613934576, 52.691532016420609],
              [1.438996645331962, 52.692879531180239],
              [1.439190268138207, 52.693383864116626],
              [1.439831551771996, 52.693895880731482],
              [1.441816168244864, 52.694378753677071],
              [1.442717131591563, 52.695367981001631],
              [1.442297554575051, 52.698499273785771],
              [1.441386189076044, 52.70091364807223],
              [1.441758647507278, 52.701300356396558],
              [1.446253517342098, 52.702350551474588],
              [1.45016244795839, 52.702840237304493],
              [1.456712562438562, 52.702614165854264],
              [1.461030968509699, 52.705393100128418],
              [1.462232728320292, 52.704376732692488],
              [1.46294366421958, 52.702398857776764],
              [1.465042428927746, 52.699084346780083],
              [1.46816985262905, 52.69759963195979],
              [1.467192899589203, 52.695585701464999],
              [1.467173830204076, 52.69459871874075],
              [1.468409716312714, 52.693682015615359],
              [1.471420909007675, 52.693312271799897],
              [1.471861165280086, 52.692499797626041],
              [1.471511845585402, 52.691064700602965],
              [1.471797479801283, 52.690225279853379],
              [1.473532961482667, 52.689209327896343],
              [1.474950336288629, 52.688922206681291],
              [1.477162856493048, 52.689402297553237],
              [1.481659450233108, 52.692615957951752],
              [1.483894572393278, 52.69313483515036],
              [1.488800000103479, 52.692871695953059],
              [1.489385614179942, 52.691844408951837],
              [1.488847040996119, 52.68926908950673],
              [1.489401997058067, 52.68847025443997],
              [1.491163155634772, 52.687744688702494],
              [1.495056635991647, 52.686921100990524],
              [1.498239020284849, 52.686756973343904],
              [1.500065628849601, 52.687535848657532],
              [1.500522911858498, 52.689531610955129],
              [1.501805877072084, 52.690192518634525],
              [1.50282169676509, 52.68999428480204],
              [1.504707585282554, 52.688684706285038],
              [1.506065331829052, 52.688801010537155],
              [1.50774034625507, 52.689698483409153],
              [1.509818678578314, 52.690124380641556],
              [1.5114441852429, 52.689979952477756],
              [1.512985420074194, 52.689081596400293],
              [1.512838473832247, 52.688523823221772],
              [1.511584630655264, 52.687563565318179],
              [1.509571602625243, 52.686069062814504],
              [1.508899270433253, 52.684588769946309],
              [1.509329172925532, 52.683324947940989],
              [1.5103724148988, 52.682346043708982],
              [1.511839775647714, 52.681581203121404],
              [1.512935079449339, 52.681491149438365],
              [1.512697976547289, 52.679902625422038],
              [1.511185404880553, 52.67975321648732],
              [1.509620861780233, 52.678734697849166],
              [1.507270764801292, 52.675724749082015],
              [1.507818621499036, 52.67565545360857],
              [1.510022130381982, 52.678571701673967],
              [1.511161777314115, 52.679422033924304],
              [1.512882755394034, 52.679623721855741],
              [1.513482557129203, 52.681305815518655],
              [1.516408498052831, 52.681988854427637],
              [1.516433565641474, 52.68176685420454],
              [1.517672653503344, 52.682967642396498],
              [1.517273293066853, 52.684295348380047],
              [1.518872738628154, 52.686680741145913],
              [1.523023140047042, 52.685415970606151],
              [1.524237786836242, 52.683096209038183],
              [1.525748612187489, 52.682157211581533],
              [1.527112297140293, 52.681743339280018],
              [1.530632405792804, 52.681552082923353],
              [1.532793189451179, 52.682945587787273],
              [1.535212687740146, 52.683418421036357],
              [1.536870995273025, 52.68295156714337],
              [1.537769731024283, 52.681383113141372],
              [1.539938550399203, 52.680880273314983],
              [1.542991643604932, 52.679456012070901],
              [1.546083815103542, 52.679702523570846],
              [1.549165737077284, 52.68121621137351],
              [1.549814600398894, 52.681125142060992],
              [1.553144564054229, 52.673202971908154],
              [1.55606366278102, 52.671361450291357],
              [1.559801707335099, 52.669932351982567],
              [1.560977004597219, 52.668412053151201],
              [1.561715479057272, 52.66643358484955],
              [1.561742838147423, 52.665224831435587],
              [1.560555032969489, 52.662244784884329],
              [1.559850398748817, 52.658619699958663],
              [1.556531919457119, 52.654114385902169],
              [1.55684954927508, 52.651306725104362],
              [1.558137086261691, 52.650207603438282],
              [1.559360817694195, 52.649688717613536],
              [1.566006082785443, 52.649044381091976],
              [1.566044716560803, 52.64848377193892],
              [1.56650858635627, 52.648477890345426],
              [1.566226063464097, 52.649003575054032],
              [1.56698271578588, 52.64856164072922],
              [1.567040036096974, 52.648847730855806],
              [1.56754484971225, 52.648836114181144],
              [1.568973892388304, 52.648352312189878],
              [1.569662868023883, 52.647153281371587],
              [1.56919151537467, 52.646268964893061],
              [1.566946223809645, 52.644464937242255],
              [1.566228334807274, 52.643003409823265],
              [1.565912251749143, 52.640320039333425],
              [1.567436990246553, 52.637928374322755],
              [1.569191221287928, 52.637266568332358],
              [1.57150051188752, 52.637266158192865],
              [1.574642042614944, 52.639275085627816],
              [1.577634478190225, 52.637156442346942],
              [1.580723659833123, 52.636550339309458],
              [1.583802723778498, 52.636345608946492],
              [1.585917315782501, 52.636712385376903],
              [1.588643389663806, 52.638190276277079],
              [1.590109877119325, 52.638116117991515],
              [1.591739092329365, 52.637303961550529],
              [1.592245666013148, 52.636522265652978],
              [1.591242043402423, 52.634595549281329],
              [1.591642967337276, 52.633661458745472],
              [1.593326078406191, 52.632102907031822],
              [1.592517355788966, 52.629054979638866],
              [1.594015302031414, 52.628485122517155],
              [1.596453045320332, 52.628524390022243],
              [1.597822999441526, 52.62816074750129],
              [1.598324258962963, 52.626236894826249],
              [1.599757685432695, 52.625027623370691],
              [1.606200818098898, 52.623828564839812],
              [1.609296720293046, 52.624199186817016],
              [1.61115214568688, 52.624779384597424],
              [1.612266407673304, 52.625440645160026],
              [1.613927758804478, 52.627250868099544],
              [1.61627118495048, 52.627813384879119],
              [1.617791706130534, 52.628756297774117],
              [1.618020790571019, 52.628272588042257],
              [1.617278352431222, 52.625653615508575],
              [1.619929507750408, 52.623554186163929],
              [1.621127706404648, 52.6237990514418],
              [1.621435108867774, 52.623512614403545],
              [1.620478493969526, 52.624760636924684],
              [1.621233773418267, 52.625493959961247],
              [1.622380911353002, 52.62581772821197],
              [1.62276512693533, 52.626531819230905],
              [1.624491263083027, 52.626385418163338],
              [1.625955582728915, 52.627076305046678],
              [1.627299742950833, 52.626743688543861],
              [1.629557855994924, 52.626970366225059],
              [1.62953514789722, 52.627409993437986],
              [1.630725375144917, 52.627842093075685],
              [1.631661740489713, 52.630043991981715],
              [1.633645850913497, 52.631420403243197],
              [1.633375372469252, 52.632526035667738],
              [1.635443109713464, 52.631060289715954],
              [1.637482725730919, 52.630325723391472],
              [1.643123389098277, 52.630180084399349],
              [1.643165287134691, 52.629901763436898],
              [1.643335654025765, 52.630190638869756],
              [1.644059442298011, 52.630219621108871],
              [1.64186025224734, 52.628855397336928],
              [1.64232203060055, 52.628755736723974],
              [1.641188106961055, 52.627259767863499],
              [1.641591466210598, 52.626670807061181],
              [1.648872521032885, 52.624503690790377],
              [1.651898706549097, 52.624209755108026],
              [1.654243420350862, 52.624145462572848],
              [1.655708019739571, 52.624409635414374],
              [1.658636905340674, 52.625163700427933],
              [1.659321915292675, 52.625631822387589],
              [1.662957620289814, 52.621171354557319],
              [1.664814098125346, 52.619582990580241],
              [1.665759767141008, 52.619492532856576],
              [1.667461576813725, 52.618283091556769],
              [1.668080618436937, 52.617609995040731],
              [1.668052963294598, 52.616926363922389],
              [1.669121555592356, 52.616680958310027],
              [1.669604368393721, 52.617240739565574],
              [1.671110038267464, 52.617379320544721],
              [1.672845971580505, 52.616579793131322],
              [1.673284344648656, 52.615462545774797],
              [1.674155578580189, 52.614732115159505],
              [1.67679044224376, 52.614310265546884],
              [1.677903042946276, 52.61312887010768],
              [1.677964186630233, 52.612149253848834],
              [1.675144242331484, 52.60958617084443],
              [1.673695255095255, 52.609345144714958],
              [1.671330869018853, 52.610223557096759],
              [1.668162247654575, 52.610179727249324],
              [1.666808160071242, 52.609624461480806],
              [1.665192458125684, 52.607971850242528],
              [1.663242739039151, 52.607271294723304],
              [1.661890748265113, 52.60705770369642],
              [1.659276295731806, 52.607384150606691],
              [1.6556583844379, 52.606502997663412],
              [1.65389778168573, 52.605108362446593],
              [1.654363064438252, 52.604539925750636],
              [1.65379630809419, 52.603677762584404],
              [1.650659425984497, 52.601950506552569],
              [1.645546698297446, 52.602805245863408],
              [1.642712902502024, 52.604579911204944],
              [1.641953186383586, 52.604202144690817],
              [1.642315071868589, 52.60297404288692],
              [1.640342166711146, 52.60139956173699],
              [1.639382518858897, 52.599163365054785],
              [1.637026895999726, 52.598064722616115],
              [1.634763735742608, 52.594657897127313],
              [1.634798985327146, 52.592837221412246],
              [1.630986633466152, 52.592476693380476],
              [1.645437252016222, 52.589349171026981],
              [1.644526269707705, 52.588356360323409],
              [1.640907397195511, 52.58836616699967],
              [1.638637885167537, 52.587408824526761],
              [1.637712468658837, 52.585811974428474],
              [1.637833081016104, 52.584683947344949],
              [1.63730743319589, 52.584755878738015],
              [1.637868465750497, 52.584451699331638],
              [1.637829246411602, 52.583764823011371],
              [1.636065374964061, 52.581813276187681],
              [1.633208618934458, 52.580926953358023],
              [1.629624173916719, 52.580988432217573],
              [1.627264688061819, 52.579863643029505],
              [1.626515008480433, 52.578617504645997],
              [1.626356598211903, 52.576539231001554],
              [1.623657737708874, 52.574733100152002],
              [1.619812354362687, 52.573593420322041],
              [1.616822827216864, 52.573916037526615],
              [1.615340862427499, 52.574743867963406],
              [1.613067710627964, 52.57523337300222],
              [1.610664660265074, 52.575149353549655],
              [1.607120210455703, 52.574164675795309],
              [1.606762213322864, 52.57248463272451],
              [1.608326869359597, 52.570326803109758],
              [1.608112807406439, 52.569392511338123],
              [1.60633105021465, 52.568619363558525],
              [1.603535811114345, 52.568338490350591],
              [1.601170733536678, 52.5674346295392],
              [1.596880400324154, 52.564397343309949],
              [1.595055440017313, 52.562189829603163],
              [1.594954002808197, 52.560680046288233],
              [1.594391074611058, 52.559788717578591],
              [1.591899539405854, 52.558286793462507],
              [1.589646384884635, 52.557839826566649],
              [1.58403267572309, 52.558293418682716],
              [1.582187163216481, 52.557813268192241],
              [1.58044657588474, 52.556917968211735],
              [1.578995442680342, 52.556712749597892],
              [1.578371335983577, 52.556877345745271],
              [1.578605477649421, 52.557378444978269],
              [1.578125922546164, 52.556908156975695],
              [1.577486802591673, 52.557305258434901],
              [1.577715655574877, 52.557650915985946],
              [1.577273239611149, 52.557364785878462],
              [1.573690499777494, 52.558802480763305],
              [1.568645276388236, 52.559299319094897],
              [1.565893625233226, 52.559072014767317],
              [1.562841029578814, 52.557644869884555],
              [1.561222753213115, 52.557397698306893],
              [1.559683721959318, 52.557782222227331],
              [1.556614589136118, 52.559482755569057],
              [1.554795869146321, 52.559804569891703],
              [1.553055440109759, 52.559489002890189],
              [1.546572157382323, 52.556271157586181],
              [1.543733126043199, 52.555450536575542],
              [1.541823964031548, 52.555491541118563],
              [1.540449690218576, 52.555925743917655],
              [1.539725888368455, 52.556521248178299],
              [1.538446002340336, 52.560619581966684],
              [1.536369615041756, 52.56217923971456],
              [1.534538556770712, 52.562589251720127],
              [1.53309132038231, 52.562340180312113],
              [1.530982025132067, 52.561015355253389],
              [1.530052317983424, 52.559730842618507],
              [1.528446523735709, 52.559508934044999],
              [1.526111753259417, 52.560225257733308],
              [1.522381749007481, 52.562683913009629],
              [1.521602118913904, 52.563655125372932],
              [1.521442430447146, 52.564884898594698],
              [1.524804449295119, 52.568228654312549],
              [1.524851475887968, 52.569973039815665],
              [1.522951020545219, 52.572908723512484],
              [1.518413434308702, 52.57580591952317],
              [1.514780044264301, 52.57656062580196],
              [1.512782983351612, 52.575902211039349],
              [1.509568774574516, 52.573923554391072],
              [1.507251187542294, 52.572983145653083],
              [1.500116797068846, 52.571097928110099],
              [1.497769070442086, 52.571294196149942],
              [1.496334494741146, 52.572094820683212],
              [1.492754037925162, 52.575538342674143],
              [1.491690478508892, 52.578741988040562],
              [1.488155083339372, 52.580775541266874],
              [1.487833504606832, 52.581578308401312],
              [1.488271181322506, 52.584837478255253],
              [1.486910019210046, 52.586253713188697],
              [1.484660520618029, 52.587753663896422],
              [1.484173805757889, 52.58791010706679],
              [1.481004783277617, 52.58748263747983],
              [1.476350250603626, 52.586402600423305],
              [1.473375260755895, 52.586427926421109],
              [1.468978519343966, 52.587017434399648],
              [1.467311485322362, 52.588122213318002],
              [1.467269084555605, 52.590220880530772],
              [1.466279572682748, 52.591622376202324],
              [1.46248095566248, 52.59418449972916],
              [1.461117419322333, 52.594498716920299],
              [1.456688455622281, 52.593885291131357],
              [1.454515371428837, 52.594535175685245],
              [1.453416745024185, 52.595832713396028],
              [1.454764411127698, 52.599667111239008],
              [1.453644398696385, 52.601351118321091],
              [1.451094814853944, 52.602496702713026],
              [1.447857213370762, 52.603122638584608],
              [1.443889697942818, 52.604575596706695],
              [1.438292466220487, 52.609854169767438],
              [1.433464531014703, 52.612425432978256],
              [1.4337648440668, 52.613222580885662],
              [1.437099343994694, 52.616327639023815],
              [1.437656437027846, 52.617552629041263],
              [1.437033257400391, 52.618745346516612],
              [1.434673575345939, 52.620031643339978],
              [1.432799200972923, 52.620138194318955],
              [1.430633540442092, 52.618600066264058],
              [1.429395953233335, 52.618288769345845],
              [1.428233766265765, 52.618582368771342],
              [1.424493053379142, 52.621507585016531],
              [1.4220709994117, 52.622304358308071],
              [1.420257195245534, 52.622222782491768],
              [1.418371195773986, 52.621120652353788],
              [1.415483738707807, 52.621445958295794],
              [1.413680158615525, 52.621172416159133],
              [1.410019826712748, 52.619561001596999],
              [1.408000246369034, 52.618009265263254],
              [1.407050113219159, 52.613940789506316],
              [1.406027300866929, 52.613727396986469],
              [1.404643279731518, 52.614532607218692],
              [1.403323156226409, 52.614657821531075],
              [1.402424615449288, 52.614427332944288],
              [1.400727425754071, 52.613177397006133],
              [1.399973084579555, 52.612081129421007],
              [1.399637845880129, 52.609300849092676],
              [1.398939959533088, 52.608512344240332],
              [1.393868235199436, 52.606338387599259],
              [1.391437798383995, 52.604749596441557],
              [1.387624455239684, 52.604589913217147],
              [1.383741794030637, 52.60521635583752],
              [1.381371178500435, 52.606371481562],
              [1.380926311308731, 52.608860205595633],
              [1.378440650292619, 52.611804736510095],
              [1.377190446969042, 52.614429753429761],
              [1.377032007517301, 52.619489718781914],
              [1.376608899532443, 52.620653903381793],
              [1.37590014076152, 52.621064456002195],
              [1.373428732788224, 52.621422730574089],
              [1.370101553207808, 52.62379835744445],
              [1.367673875773585, 52.624420581640123],
              [1.363553611810727, 52.624172601811658],
              [1.360174368597876, 52.623160554448944],
              [1.350452916861045, 52.621265961249698],
              [1.349640845196297, 52.621507496828734],
              [1.349270383764221, 52.622732141466798],
              [1.347810852016734, 52.623842795888329],
              [1.345307330454355, 52.62399812691708],
              [1.345338710248066, 52.624331808091881],
              [1.347345187485999, 52.62442167531367],
              [1.345344290805565, 52.624424286154522],
              [1.34535971528225, 52.62516853404945],
              [1.34507082757552, 52.623997616510785],
              [1.344387912659386, 52.624150923115778],
              [1.343260198895404, 52.625273727443435],
              [1.341831811201671, 52.625826711626765],
              [1.333662317762631, 52.62630172772829],
              [1.331897121724066, 52.626217426654613],
              [1.328886646553766, 52.624292938282643],
              [1.325261052382327, 52.623159119862393],
              [1.32250062940269, 52.625891587673415],
              [1.326522618656338, 52.626988270245377],
              [1.327203157669709, 52.628865916882233],
              [1.328333091640093, 52.62971193536324],
              [1.328744105470672, 52.630588956552295],
              [1.329841423466458, 52.637559702074014],
              [1.342220735733974, 52.64129397360167],
              [1.341113250087817, 52.64706863802801],
              [1.34054818885665, 52.649682873652552],
              [1.322953397815645, 52.644999486829974],
              [1.314081674632204, 52.649394267093193],
              [1.31058432255678, 52.651681176432184],
              [1.309102614957911, 52.652973618267914],
              [1.307228194947075, 52.652748990906929],
              [1.307117788214645, 52.652246632158644],
              [1.302218233164466, 52.652793506053641],
              [1.302323050009294, 52.653046001597957],
              [1.299957095366749, 52.653264864411589],
              [1.299734779213313, 52.652708959160655],
              [1.295492156482376, 52.653301095741426],
              [1.294009016313766, 52.653932351640705],
              [1.294407447064698, 52.654254928553918],
              [1.292577977433381, 52.654902997223154],
              [1.292380384132617, 52.660161657438948],
              [1.293016960492892, 52.660739330770632],
              [1.292525745811216, 52.661268325180309],
              [1.291683198744612, 52.661315135405275],
              [1.292004839333553, 52.662936732693488],
              [1.293829927888474, 52.666589539980968],
              [1.293676729209034, 52.668485155374078],
              [1.292082106398709, 52.668624833016978],
              [1.29300227310629, 52.671455611993139],
              [1.292042621756633, 52.671639683520027],
              [1.295216782678842, 52.672886044289804],
              [1.294399899740505, 52.673021193644352],
              [1.294797837333345, 52.673590208829481],
              [1.296662459401922, 52.674341440724042],
              [1.299637557865142, 52.674238790923759],
              [1.300161521187688, 52.676830536522743],
              [1.297170492776631, 52.677077538536757],
              [1.297052438003779, 52.677435173244547],
              [1.296258505835492, 52.677463571022201],
              [1.295379720459145, 52.678703063559276],
              [1.296048508734522, 52.68133396282294],
              [1.295405712341408, 52.681562301864723],
              [1.295310932522392, 52.682425625646516],
              [1.292307336327956, 52.684909552466173],
              [1.282922389896114, 52.677309711975354],
              [1.262836625779331, 52.677380085987188],
              [1.264613079208188, 52.674892982986748],
              [1.266009025338076, 52.673316597696306],
              [1.269549518911359, 52.670722289550383],
              [1.271043278681466, 52.668677274085589],
              [1.272257150586272, 52.666649865695085],
              [1.273036015415359, 52.666758745389458],
              [1.277039893559506, 52.665979781029939],
              [1.277924892082444, 52.66284441055727],
              [1.27863359522723, 52.662096309424044],
              [1.280829806418012, 52.661933847286036],
              [1.280390831453237, 52.660635646464904],
              [1.282365438903266, 52.660260750398727],
              [1.284965803705129, 52.660203022501641],
              [1.284239367088093, 52.657617733425845],
              [1.27913471480341, 52.657155753249462],
              [1.277163863802831, 52.656492634823145],
              [1.261563758569777, 52.65206600019787],
              [1.2549001392984, 52.649796852521405],
              [1.253320257771445, 52.649510215293802],
              [1.253394410787666, 52.64913494641759],
              [1.251226150695899, 52.648212418979369],
              [1.248486474452063, 52.647718335573309],
              [1.246600363964839, 52.649801706931278],
              [1.246617696189465, 52.650653815898607],
              [1.247858253718932, 52.652067744649287],
              [1.247078551190457, 52.653359014111857],
              [1.244079685340761, 52.655797517757186],
              [1.242588019017398, 52.656564154184956],
              [1.239574033236099, 52.657165583349347],
              [1.234299459092823, 52.658960085724132],
              [1.233436222745458, 52.660048489000921],
              [1.233445304214105, 52.661152638270188],
              [1.233261800456428, 52.662653261923943],
              [1.235120724358918, 52.664649399221325],
              [1.234714684512191, 52.665840434283872],
              [1.235425575352495, 52.666312055186637],
              [1.234307572582667, 52.667938037379763],
              [1.231525021325957, 52.668600379655615],
              [1.230830386040505, 52.669611311595304],
              [1.229224262834732, 52.669217141323209],
              [1.2275829004119, 52.670511079380503],
              [1.225289747724236, 52.671273248888319],
              [1.224315730405693, 52.672229694467333],
              [1.221810374630701, 52.673145963806363],
              [1.219668352213617, 52.672647529105348],
              [1.218034683822237, 52.672643370643399],
              [1.219224465470288, 52.67069633377114],
              [1.218927176593907, 52.669249280083328],
              [1.218128856033435, 52.669196332998091],
              [1.216499516720988, 52.666149564504067],
              [1.215136753241441, 52.664574054801093],
              [1.213728744648046, 52.663690452424618],
              [1.213021157580676, 52.662615156438278],
              [1.210994916814139, 52.662335566404344],
              [1.209795600296317, 52.661310458747437],
              [1.207488757214897, 52.660591443698046],
              [1.206584139359252, 52.66084167955362],
              [1.205745048861276, 52.660088269722714],
              [1.205075721473575, 52.659996669836474],
              [1.202981250400144, 52.661046215908577],
              [1.202339831994863, 52.662124784799033],
              [1.202587242234892, 52.663585814413345],
              [1.201952834910545, 52.6640733252964],
              [1.202410632414278, 52.666448689016597],
              [1.20358175624298, 52.667978262923619],
              [1.203765783597832, 52.668996731414801],
              [1.203603459402445, 52.669891468015848],
              [1.202636824779237, 52.670307927591779],
              [1.201775943915707, 52.672897917055394],
              [1.200402419237179, 52.673739952150093],
              [1.19962268100409, 52.674791662438132],
              [1.197726574447332, 52.675691850694385],
              [1.196810125448857, 52.675542122046529],
              [1.196216564625166, 52.677078021194959],
              [1.193653050705492, 52.678040220047556],
              [1.191968614539372, 52.677930946806491],
              [1.190459653404629, 52.678503114650347],
              [1.18719374395022, 52.67744350315315],
              [1.184764041255422, 52.675368454522072],
              [1.184426303587707, 52.674224591269933],
              [1.182062305462888, 52.671746620408058],
              [1.176352366439775, 52.667986019127007],
              [1.174266717927519, 52.667274814686031],
              [1.17423178137953, 52.665793652196342],
              [1.173742247152465, 52.665142167323573],
              [1.167439772911595, 52.66484236659894],
              [1.165715251664389, 52.665193355618996],
              [1.163664461124339, 52.665185210607014],
              [1.159928382576059, 52.666584497003598],
              [1.152003168467464, 52.668114125824715],
              [1.14952864555999, 52.669208793777464],
              [1.147273648215552, 52.67134169101714],
              [1.145673822174271, 52.668267137286527],
              [1.14633156251679, 52.660007342863899],
              [1.145646108056168, 52.659360052999538],
              [1.13816157080153, 52.659742109761794],
              [1.138864906615034, 52.657772832554585],
              [1.137658737204733, 52.657678894948099],
              [1.13798732800925, 52.655309460039867],
              [1.140290924277878, 52.655075717417276],
              [1.140049673907261, 52.653835650524407],
              [1.138648939066927, 52.653934842762744],
              [1.137673012565999, 52.649787839969328],
              [1.137681810206858, 52.646917859211129],
              [1.137009935866731, 52.646909582514667],
              [1.13645823165019, 52.644738838155426],
              [1.135195918953949, 52.644846916173087],
              [1.135221972618367, 52.642835334632487],
              [1.132215207051949, 52.642551593335384],
              [1.131994181160588, 52.645767130301408],
              [1.124512986076552, 52.645128427751317],
              [1.116040944990532, 52.645418254603278],
              [1.109748438397434, 52.647252834157939],
              [1.100632188173436, 52.648746467577581],
              [1.097679407091003, 52.64941640588858],
              [1.097672236581585, 52.649733152827231],
              [1.098416290498524, 52.660103481055998],
              [1.100462167431775, 52.664849578375566],
              [1.100629967470063, 52.666842564021174],
              [1.099767103802463, 52.67038686951976],
              [1.099871806768435, 52.672825767561136],
              [1.102996782027593, 52.67589782741684],
              [1.104672763108395, 52.678228095256934],
              [1.106541425561496, 52.679497491079708],
              [1.105910917315057, 52.679724467086871],
              [1.108299419019224, 52.682498246733566],
              [1.10595995576061, 52.684771034162111],
              [1.105226221120569, 52.684316334282336],
              [1.102674201871307, 52.685286124587918],
              [1.102449346222132, 52.685829804034029],
              [1.101740437995782, 52.685954489593506],
              [1.102027369034473, 52.686703297769832],
              [1.10169846508287, 52.68845118271615],
              [1.100751838923315, 52.68971162332074],
              [1.094584183083854, 52.693638334862108],
              [1.089639710783653, 52.696141582425881],
              [1.087650625205125, 52.696691663015564],
              [1.087109525848454, 52.697644644134982],
              [1.087384808462394, 52.698377600869996],
              [1.087054069663983, 52.699207289115712],
              [1.084272927148123, 52.702297809093182],
              [1.08470256746417, 52.703686844358081],
              [1.085951385070823, 52.70434768640343],
              [1.087364327291083, 52.704543792805062],
              [1.089919911722989, 52.705551837689413],
              [1.092442864702183, 52.707624562768707],
              [1.096444641258866, 52.70952099971516],
              [1.099687053664895, 52.711938090467449],
              [1.103974288707274, 52.71279799920157],
              [1.105180796990169, 52.714668426105185],
              [1.103497612740332, 52.716281825757392],
              [1.099806409010626, 52.716355075320109],
              [1.099519823143153, 52.717262781749653],
              [1.098111651420925, 52.717683616964443],
              [1.096285960601466, 52.719506577128563],
              [1.093904192397014, 52.719691101015172],
              [1.091879345996621, 52.721235908314412],
              [1.090513063631484, 52.721665449959239],
              [1.090532520813481, 52.722589429874837],
              [1.088531045008831, 52.72316951071997],
              [1.087403295992619, 52.728588440613585],
              [1.082825663078649, 52.729717542136285],
              [1.082163857284456, 52.732094531667592],
              [1.082513245096414, 52.734819327078355],
              [1.081273278822788, 52.735576413460606],
              [1.080702966283107, 52.73696357764706],
              [1.080917072908252, 52.740186508755563],
              [1.079701949053454, 52.741235203628946],
              [1.078128135452806, 52.741589948105279],
              [1.075627589905089, 52.741662963360525],
              [1.067303398110004, 52.743208766481551],
              [1.063559878267069, 52.743435094443768],
              [1.06231585933852, 52.743022992349083],
              [1.061548784653493, 52.743157021516538],
              [1.060344722890579, 52.743758274607039],
              [1.059566527826526, 52.744917776940774],
              [1.058288231129125, 52.745073973919453],
              [1.057876507727737, 52.745563922962468],
              [1.055784374717917, 52.746393977001922],
              [1.055564667860154, 52.747425738623292],
              [1.053680335628745, 52.749215340552965],
              [1.051067980603676, 52.750387852235207],
              [1.054350589689374, 52.755932892652012],
              [1.055063949969188, 52.756107856465512],
              [1.054962029880526, 52.757065534759086],
              [1.052726836334609, 52.75963035566604],
              [1.05330751232059, 52.761932880635634],
              [1.051549870638307, 52.763085172339828],
              [1.051528488358464, 52.763436447281059],
              [1.046598516975531, 52.767587589973942],
              [1.044750190550961, 52.769018188576467],
              [1.042027459493429, 52.770201410927932],
              [1.041359061650072, 52.770205970801833],
              [1.040468271909885, 52.769062441432396],
              [1.036362546343144, 52.769720737723048],
              [1.03383592826266, 52.769655928277224],
              [1.033090398117869, 52.769169605256607],
              [1.03154519566823, 52.769272979928715],
              [1.02820587732019, 52.768585840152149],
              [1.017426998375239, 52.76475275547169],
              [1.011239714357873, 52.762961338539739],
              [1.003186701148527, 52.765241177024329],
              [1.00022024892423, 52.765760537367044],
              [1.001086288184433, 52.772464343557687],
              [1.001693114825427, 52.772426498439486],
              [1.002270242387643, 52.774240123633206],
              [1.002228839001289, 52.776180022555678],
              [0.999080599989293, 52.779025890518774],
              [0.992150083750965, 52.780079596469662],
              [0.992256581495794, 52.783986071565664],
              [0.990348359313182, 52.784038710434039],
              [0.991681120686115, 52.785369294046411],
              [0.991374736572938, 52.785465153500986],
              [0.991929285384954, 52.786607632616537],
              [0.993524542264685, 52.788907289001848],
              [0.996026007783675, 52.790856694006457],
              [0.996632264899382, 52.791734357347742],
              [0.996165738380454, 52.792263231416314],
              [0.996930635837955, 52.792513684962572],
              [0.994940353983207, 52.796320253657122],
              [0.993654595844176, 52.797385989854547],
              [0.993575887245257, 52.798334915387279],
              [0.993496125492909, 52.799333327521929],
              [0.99500097823487, 52.800762949834201],
              [0.995513035140055, 52.800571060071867],
              [0.998196850446307, 52.800954672620556],
              [0.999479012570161, 52.801517570641956],
              [1.001029638331402, 52.80136772820326],
              [1.002432523363858, 52.802023762369977],
              [1.004406566170208, 52.802268729469311],
              [1.006507101727081, 52.801873767323507],
              [1.010361583407398, 52.801953140045995],
              [1.013561922810604, 52.801027429653146],
              [1.01705369890118, 52.800648174256274],
              [1.01692202839153, 52.800225264653669],
              [1.018890769925283, 52.799944045187857],
              [1.022260183389626, 52.800005700703537],
              [1.019287852744363, 52.804645274266043],
              [1.019980163881993, 52.804721171124825],
              [1.020739256033234, 52.806530077904661],
              [1.022568228294272, 52.806861174617865],
              [1.022111951765662, 52.807428559431457],
              [1.028666087464851, 52.807077002730125],
              [1.030462039693403, 52.807614757753342],
              [1.03120435294556, 52.808130860436322],
              [1.031962441070818, 52.810237388904319],
              [1.034597313218428, 52.811204173758519],
              [1.035243019928866, 52.811801861059237],
              [1.037494523401805, 52.811908785434149],
              [1.040162438934006, 52.811453731096677],
              [1.042716199137014, 52.811978169848977],
              [1.044322090925463, 52.811829942475036],
              [1.047577040658595, 52.813338081660007],
              [1.060384105956731, 52.815002909041823],
              [1.062005010587485, 52.814835170047417],
              [1.062229770107096, 52.815139622013639],
              [1.073367473135715, 52.81685873862223],
              [1.079916874923818, 52.815438816467356],
              [1.081665942922434, 52.81555165645527],
              [1.083113024367996, 52.816094990400899],
              [1.084688748272555, 52.815960489674033],
              [1.085364154081527, 52.815584101550797],
              [1.087881991113925, 52.815820734724404],
              [1.088478859924785, 52.816137032837972],
              [1.088245677796886, 52.816448865799472],
              [1.089838880810329, 52.816853419232345],
              [1.090471814033071, 52.81663188794667],
              [1.092383966182235, 52.818287111300293],
              [1.092753876805133, 52.818980715678357],
              [1.092206761804774, 52.819512077310797],
              [1.092923671872316, 52.81963637163048],
              [1.093884454958544, 52.820831657811105],
              [1.094808146799778, 52.820919970526916],
              [1.095253469291904, 52.822046859527376],
              [1.095829713174244, 52.822092072411053],
              [1.095364490127516, 52.823106931194019],
              [1.095562174618432, 52.824362586133624],
              [1.097514122117387, 52.825776580061302],
              [1.098388169592229, 52.827230396559756],
              [1.100093029700919, 52.828549183612658],
              [1.101769142913966, 52.829317429301405],
              [1.104514566355407, 52.828444327366654],
              [1.1066394939494, 52.826825708047991],
              [1.110390746505072, 52.824823574951033],
              [1.112406231644443, 52.824108816630037],
              [1.11442367056299, 52.822073797444261],
              [1.117261073062228, 52.821251034840074],
              [1.118758579077485, 52.819505688025068],
              [1.124360049881388, 52.818377113099643],
              [1.127012737005001, 52.815960013660721],
              [1.126851604811862, 52.815473241670617],
              [1.128315249192487, 52.81456591257583],
              [1.131480054984292, 52.815189240605278],
              [1.134718097547132, 52.814663932905852],
              [1.136074554838911, 52.813734158343834],
              [1.14362570338414, 52.813065688952115],
              [1.144386265222835, 52.812540113087671],
              [1.149580307100083, 52.812078525620954],
              [1.150449296462935, 52.815472823423114],
              [1.148286403664076, 52.815880094122605],
              [1.14826012728591, 52.818882687891033],
              [1.147220274414074, 52.818975037910633],
              [1.14703506645094, 52.820293843620185],
              [1.143699209887133, 52.820619733093103],
              [1.144965372536785, 52.823597048717687],
              [1.145670443239143, 52.824108942086809],
              [1.14685193889928, 52.824207999015343],
              [1.147457508382331, 52.823672129280396],
              [1.154181001119153, 52.821763234210067],
              [1.155402784085231, 52.821517597083727],
              [1.156570319182119, 52.821711355679412],
              [1.162826345991232, 52.82500253773339],
              [1.163958392525402, 52.826115369433111],
              [1.165817987470188, 52.825778865344482],
              [1.166357987406782, 52.826201516130276],
              [1.165991050639281, 52.826647478613225],
              [1.167217611967705, 52.829872941628466],
              [1.16818395150651, 52.829644787421138],
              [1.169554740356436, 52.829913929719361],
              [1.169745848851065, 52.83021368977667],
              [1.171616845757601, 52.83005215779643],
              [1.172576423939181, 52.830586766750784],
              [1.172837419567844, 52.83027671627493],
              [1.173492208405444, 52.830561367808357],
              [1.173843892860471, 52.830171547438205],
              [1.17454827941514, 52.830382920806464],
              [1.174803906658293, 52.829796020573689],
              [1.175623939652866, 52.829354087197039],
              [1.175519986952173, 52.828743537655825],
              [1.176086650679019, 52.827983731419003],
              [1.177397601824155, 52.827494463718537],
              [1.179624347815563, 52.827123624187124],
              [1.180239241035251, 52.827451575918211],
              [1.180817302313727, 52.827153688834649],
              [1.18048430113128, 52.823979038777566],
              [1.181064754717761, 52.82343017626026],
              [1.180980388499344, 52.821788487766923],
              [1.181992396995894, 52.821457370017868],
              [1.18107695367095, 52.820367674543824],
              [1.184701691479994, 52.820443060707078],
              [1.18516062945095, 52.819857869864286],
              [1.188129269054624, 52.820096460006134],
              [1.189655586079125, 52.819694791232642],
              [1.191285092161098, 52.818433273955911],
              [1.192749047099449, 52.816534974810267],
              [1.193252905181278, 52.816584360834575],
              [1.193367302870649, 52.815290755358298],
              [1.195932975555017, 52.815381717023421],
              [1.198134376751322, 52.815908726219831],
              [1.201949062594043, 52.815644809170315],
              [1.202964059245791, 52.815923168747382],
              [1.203741949827898, 52.821772934525598],
              [1.203946089959448, 52.822186503875848],
              [1.206107912881242, 52.823218059535655],
              [1.206846022973964, 52.824269196759161],
              [1.20657124427026, 52.824569805688164],
              [1.207089377736801, 52.824750948541954],
              [1.206386932875612, 52.825251978879926],
              [1.207421559697652, 52.82488675623425],
              [1.20778135479395, 52.825074876206649],
              [1.207243428345589, 52.826196491056471],
              [1.207879148789226, 52.826502154589434],
              [1.206640640394046, 52.826498775207952],
              [1.207821811479038, 52.828000178587629],
              [1.209869434486533, 52.827981650556886],
              [1.212794471548905, 52.828625505969995],
              [1.214368035560183, 52.829686339827916],
              [1.214449874650567, 52.829105856355127],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000145",
        LAD13CDO: "33UD",
        LAD13NM: "Great Yarmouth",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [1.71494299728705, 52.611216051755079],
              [1.71823257113746, 52.609617785397084],
              [1.718815572697653, 52.609610254269086],
              [1.719604222398063, 52.608177784543869],
              [1.722850154186497, 52.60615710339281],
              [1.724935455523584, 52.603312081952197],
              [1.726058308361749, 52.597835788383655],
              [1.72542760007591, 52.592671070817758],
              [1.726613762576234, 52.590551416429001],
              [1.727656017496076, 52.589555233848728],
              [1.727873592669645, 52.588552654840257],
              [1.728154797270929, 52.588629242045712],
              [1.728796370427538, 52.585021899022514],
              [1.731284063177038, 52.580047579391831],
              [1.732459265810919, 52.572423382410797],
              [1.73374188475688, 52.571777543823281],
              [1.738012071003798, 52.572202124658787],
              [1.738631438660513, 52.571951378917632],
              [1.736039614671141, 52.571628520895722],
              [1.734718198589893, 52.570863424826968],
              [1.734376610981462, 52.570058382570075],
              [1.734315409751896, 52.5705190505549],
              [1.733542830799801, 52.570547941561799],
              [1.732138643849112, 52.569850193281177],
              [1.732016537417987, 52.569334147035747],
              [1.731594585558753, 52.568416500059428],
              [1.731770814621565, 52.5646888875428],
              [1.732820140514258, 52.556855464351102],
              [1.734187884100471, 52.549178369112738],
              [1.735679511704454, 52.543900752647183],
              [1.738982406289204, 52.534687258499247],
              [1.74043370329299, 52.532085008754493],
              [1.725979965459944, 52.53061656024731],
              [1.724378781936522, 52.533530967009071],
              [1.716733276073385, 52.535443718195815],
              [1.710210327139924, 52.537853231871388],
              [1.707971158198904, 52.539428307016081],
              [1.705570633247181, 52.539948811921171],
              [1.704113318674111, 52.542121739316414],
              [1.70397791779158, 52.543508475133251],
              [1.702552572911555, 52.544399526144787],
              [1.70050004227946, 52.544921617710159],
              [1.69815326081285, 52.544226894252454],
              [1.696096915045926, 52.544107703593589],
              [1.69312055693197, 52.545048913312847],
              [1.690401244037102, 52.544941334827385],
              [1.689681913328282, 52.54607373267271],
              [1.689681647345201, 52.547072154166948],
              [1.686090062902142, 52.549354600104138],
              [1.684356485066714, 52.549884450851287],
              [1.682657893023141, 52.548191496895399],
              [1.680248925226786, 52.547739413539354],
              [1.678223539572081, 52.548079474082897],
              [1.676319995341296, 52.54910020604995],
              [1.674412797499949, 52.549548958587955],
              [1.667951569033291, 52.550221788284738],
              [1.665653402035457, 52.549629251824378],
              [1.662323533302301, 52.54782827037959],
              [1.659153330554402, 52.544858351385066],
              [1.658843803968841, 52.544170845983025],
              [1.659130330764686, 52.542225433833153],
              [1.654266481240586, 52.541605921499311],
              [1.651282174717241, 52.540097977913177],
              [1.648855903017141, 52.539481196277812],
              [1.646116974976199, 52.539753680418741],
              [1.642912479614936, 52.539570928315158],
              [1.638865013300742, 52.537598029712861],
              [1.637837516139998, 52.537570285061207],
              [1.63772069099742, 52.537281555581984],
              [1.636715018627847, 52.537410534949181],
              [1.638088029484993, 52.536515606839458],
              [1.637841742137544, 52.535660604334218],
              [1.638268303741113, 52.533756815729681],
              [1.631238940288008, 52.53227924858389],
              [1.636512129196612, 52.527913994851453],
              [1.633516028334565, 52.526601164830261],
              [1.630936753853143, 52.526155128888135],
              [1.630550247207261, 52.526671595136477],
              [1.628839048256787, 52.526093614888794],
              [1.62877472928368, 52.527262185199355],
              [1.628108125369851, 52.527977025627955],
              [1.629617240120978, 52.528518047784232],
              [1.628032343421365, 52.528077392103548],
              [1.627651522922776, 52.52844886107556],
              [1.628635408931424, 52.52875506298669],
              [1.62753740796475, 52.528531514868796],
              [1.621963339243254, 52.531798344174923],
              [1.620577897866765, 52.533243030208503],
              [1.622379150139023, 52.533766204463646],
              [1.622639180099741, 52.533538770084817],
              [1.622446072510703, 52.533898172784056],
              [1.620442359837967, 52.53335241700988],
              [1.620232790231334, 52.534228606762056],
              [1.621140565845243, 52.535579670185498],
              [1.622793863924438, 52.535648648772209],
              [1.621297735523077, 52.53566120390515],
              [1.622146345413162, 52.53588165904015],
              [1.621475223739643, 52.535971475937053],
              [1.621693926092897, 52.536316463416377],
              [1.622555712583463, 52.536338632115225],
              [1.62175612703182, 52.536409000748662],
              [1.621814164351728, 52.538653160493546],
              [1.621273714932477, 52.539846200724412],
              [1.619764855008748, 52.540691119132504],
              [1.618877356094688, 52.540823522516064],
              [1.613785409839023, 52.540219226374575],
              [1.610512232566973, 52.540419961877568],
              [1.608081798069675, 52.541111176845909],
              [1.606389146690115, 52.542326705426781],
              [1.605844405788255, 52.543437050213512],
              [1.605973363848903, 52.544667162406007],
              [1.607432305395174, 52.547990200618131],
              [1.609141151053069, 52.549772033121045],
              [1.611316174339377, 52.550904594141116],
              [1.614500128012001, 52.551654595357199],
              [1.618547482868391, 52.551366210348164],
              [1.620540861933326, 52.551633493435588],
              [1.624525668729803, 52.5531987932807],
              [1.627119153678714, 52.5557465759723],
              [1.628071365086839, 52.55555456383167],
              [1.627263903730685, 52.555980491325791],
              [1.628832932555241, 52.557663802244569],
              [1.633552116639749, 52.561031264385441],
              [1.634129102194279, 52.56108369211325],
              [1.633739463579664, 52.561262064588398],
              [1.634684011476943, 52.564043835531272],
              [1.633319249644309, 52.570347776315572],
              [1.634303163245326, 52.572300842489383],
              [1.636154387827534, 52.573851267629529],
              [1.63775624433373, 52.574528768773526],
              [1.639665668749111, 52.574712877101327],
              [1.643891023135177, 52.574303940003134],
              [1.644400862348876, 52.573776439948134],
              [1.644247323417937, 52.574381108755802],
              [1.647241260295258, 52.575126214679337],
              [1.649281292073462, 52.576560884581873],
              [1.649202709406439, 52.576059612728507],
              [1.650533341324579, 52.576188547323916],
              [1.650222708914957, 52.576625377370405],
              [1.650578118854173, 52.576940011852528],
              [1.650213220424698, 52.577043918272224],
              [1.650594384433433, 52.577135591710444],
              [1.649870523427955, 52.577184016199276],
              [1.649741229572881, 52.576712192753725],
              [1.64936486532674, 52.576766980181155],
              [1.649752787005637, 52.577259606068779],
              [1.650662531425612, 52.577369146359686],
              [1.649805360596843, 52.577394701340062],
              [1.650159723390458, 52.577554662616954],
              [1.650160510064293, 52.579304084225321],
              [1.648095375402058, 52.583005203995704],
              [1.64826219030669, 52.584141469876762],
              [1.649562082011334, 52.585797745560996],
              [1.650781463769188, 52.586289896526409],
              [1.651451202004619, 52.586973498802671],
              [1.652530748063416, 52.586867312191458],
              [1.651049461131333, 52.587664990238203],
              [1.650757454472358, 52.589355988171292],
              [1.649886853133688, 52.590927222169348],
              [1.650330232902844, 52.591889451341153],
              [1.649619189672334, 52.592094880461168],
              [1.649488813833174, 52.592711432803625],
              [1.650199064416715, 52.593987434257592],
              [1.652791157511238, 52.594944494985938],
              [1.6563974916454, 52.595628200844232],
              [1.658684243362934, 52.596817638214183],
              [1.661389734195199, 52.59764238455503],
              [1.66464086419412, 52.597853826367206],
              [1.670484219979096, 52.598980479773438],
              [1.673819402512244, 52.598659279353804],
              [1.676769319461151, 52.599593017406754],
              [1.683598445039521, 52.600682989499312],
              [1.689336003012887, 52.603386081124341],
              [1.689964707398458, 52.604045569543921],
              [1.691315467073083, 52.603752478517897],
              [1.692194337542329, 52.604013780756517],
              [1.696049431582962, 52.606247306178943],
              [1.695494733404745, 52.606379766778723],
              [1.697543773391791, 52.606973253040451],
              [1.698772814409188, 52.607198377449222],
              [1.699277084017155, 52.606888484126081],
              [1.700255679967204, 52.607017085827451],
              [1.700028955570478, 52.607149203183859],
              [1.700691587154759, 52.607402814986209],
              [1.701446635761965, 52.607175913639836],
              [1.7057336692784, 52.608130062682392],
              [1.706517798403979, 52.608789095540452],
              [1.711303144770351, 52.611088332740977],
              [1.713418283182522, 52.611514872081266],
              [1.71494299728705, 52.611216051755079],
            ],
          ],
          [
            [
              [1.62453330924368, 52.63162880383193],
              [1.627054933787863, 52.631232239181294],
              [1.629912664450406, 52.631538572881226],
              [1.633375372469252, 52.632526035667738],
              [1.633645850913497, 52.631420403243197],
              [1.631661740489713, 52.630043991981715],
              [1.630725375144917, 52.627842093075685],
              [1.62953514789722, 52.627409993437986],
              [1.629557855994924, 52.626970366225059],
              [1.627299742950833, 52.626743688543861],
              [1.625955582728915, 52.627076305046678],
              [1.624491263083027, 52.626385418163338],
              [1.62276512693533, 52.626531819230905],
              [1.622380911353002, 52.62581772821197],
              [1.621233773418267, 52.625493959961247],
              [1.620478493969526, 52.624760636924684],
              [1.621435108867774, 52.623512614403545],
              [1.621127706404648, 52.6237990514418],
              [1.619929507750408, 52.623554186163929],
              [1.617278352431222, 52.625653615508575],
              [1.618020790571019, 52.628272588042257],
              [1.617791706130534, 52.628756297774117],
              [1.617871815866444, 52.629422136175521],
              [1.616698649670487, 52.631613066340137],
              [1.616939388687664, 52.632040140218955],
              [1.618226310581663, 52.632533270752617],
              [1.62234994848511, 52.633274184346703],
              [1.62453330924368, 52.63162880383193],
            ],
          ],
          [
            [
              [1.725380142521527, 52.621621283865267],
              [1.721822938977011, 52.619954075255741],
              [1.721399321837522, 52.616166225391105],
              [1.723402462564742, 52.6138437218343],
              [1.722484964979846, 52.611931528395722],
              [1.721420725701157, 52.611509875732089],
              [1.719718112212117, 52.611383525819015],
              [1.715286453385247, 52.613019502038192],
              [1.714664024387029, 52.615090742315125],
              [1.71604074373392, 52.616792484797365],
              [1.713721238275317, 52.618021135191618],
              [1.71067720210225, 52.619002622298055],
              [1.706006926257459, 52.619524979324851],
              [1.704926156452577, 52.619047928714245],
              [1.699052929333194, 52.617733182307646],
              [1.697200611820416, 52.617621079973993],
              [1.695657224277531, 52.618070460749301],
              [1.694904551183273, 52.617959947949714],
              [1.693179213290259, 52.616899377822506],
              [1.690020847190033, 52.613578156358031],
              [1.689145601101199, 52.613010904682262],
              [1.688189835839544, 52.612891392079817],
              [1.685334438920459, 52.610617478033546],
              [1.684245727784977, 52.610655883883148],
              [1.684069089305879, 52.610382550569717],
              [1.683103725497521, 52.610728319402035],
              [1.681735278575198, 52.608280298295576],
              [1.678387206244478, 52.607681064097605],
              [1.67764328624849, 52.607267052988142],
              [1.675669460730865, 52.607391357141253],
              [1.667075787545876, 52.606570595578923],
              [1.663335443134337, 52.60581669745055],
              [1.658985147878981, 52.603895163811842],
              [1.657899123745232, 52.603857691114747],
              [1.658095872958182, 52.602904478410558],
              [1.657411195364266, 52.601762648850801],
              [1.654996529946457, 52.600388250610834],
              [1.653369835570469, 52.598735825837103],
              [1.652983991246021, 52.597579346527915],
              [1.649585009061638, 52.595554345205919],
              [1.647879315942623, 52.593853017172499],
              [1.646442402248764, 52.591207946731139],
              [1.645597236791453, 52.590616970394976],
              [1.646524977984703, 52.59099312960096],
              [1.645437252016222, 52.589349171026981],
              [1.630986633466152, 52.592476693380476],
              [1.634798985327146, 52.592837221412246],
              [1.634763735742608, 52.594657897127313],
              [1.637026895999726, 52.598064722616115],
              [1.639382518858897, 52.599163365054785],
              [1.640342166711146, 52.60139956173699],
              [1.642315071868589, 52.60297404288692],
              [1.641953186383586, 52.604202144690817],
              [1.642712902502024, 52.604579911204944],
              [1.645546698297446, 52.602805245863408],
              [1.650659425984497, 52.601950506552569],
              [1.65379630809419, 52.603677762584404],
              [1.654363064438252, 52.604539925750636],
              [1.65389778168573, 52.605108362446593],
              [1.6556583844379, 52.606502997663412],
              [1.659276295731806, 52.607384150606691],
              [1.661890748265113, 52.60705770369642],
              [1.663242739039151, 52.607271294723304],
              [1.665192458125684, 52.607971850242528],
              [1.666808160071242, 52.609624461480806],
              [1.668162247654575, 52.610179727249324],
              [1.671330869018853, 52.610223557096759],
              [1.673695255095255, 52.609345144714958],
              [1.675144242331484, 52.60958617084443],
              [1.677964186630233, 52.612149253848834],
              [1.677903042946276, 52.61312887010768],
              [1.67679044224376, 52.614310265546884],
              [1.674155578580189, 52.614732115159505],
              [1.673284344648656, 52.615462545774797],
              [1.672845971580505, 52.616579793131322],
              [1.671110038267464, 52.617379320544721],
              [1.669604368393721, 52.617240739565574],
              [1.669121555592356, 52.616680958310027],
              [1.668052963294598, 52.616926363922389],
              [1.668080618436937, 52.617609995040731],
              [1.667461576813725, 52.618283091556769],
              [1.665759767141008, 52.619492532856576],
              [1.664814098125346, 52.619582990580241],
              [1.662957620289814, 52.621171354557319],
              [1.659321915292675, 52.625631822387589],
              [1.658636905340674, 52.625163700427933],
              [1.655708019739571, 52.624409635414374],
              [1.654243420350862, 52.624145462572848],
              [1.651898706549097, 52.624209755108026],
              [1.648872521032885, 52.624503690790377],
              [1.641591466210598, 52.626670807061181],
              [1.641188106961055, 52.627259767863499],
              [1.64232203060055, 52.628755736723974],
              [1.64186025224734, 52.628855397336928],
              [1.644059442298011, 52.630219621108871],
              [1.649791198079934, 52.63035148749109],
              [1.653068038610409, 52.631800133409612],
              [1.656888564383373, 52.630408502029333],
              [1.660067411605952, 52.62995583700323],
              [1.660236641505129, 52.630141287228184],
              [1.66702099014396, 52.63009674117577],
              [1.668563252442053, 52.629025354193054],
              [1.669701586810467, 52.628691437177245],
              [1.675456329448559, 52.629221603565313],
              [1.67663370518894, 52.629194921401833],
              [1.676880889264011, 52.628811266418076],
              [1.676865168704406, 52.62922280835781],
              [1.679162426239874, 52.629259338993869],
              [1.681937227711552, 52.627854420223969],
              [1.685914145624174, 52.627826848972312],
              [1.687324187071799, 52.627456412598761],
              [1.688229172400621, 52.626284090088348],
              [1.687318667649869, 52.624896711310036],
              [1.687297912124875, 52.623982603515707],
              [1.688045000830551, 52.623024776663293],
              [1.689252581818593, 52.622568879909814],
              [1.69240675589709, 52.6212724033442],
              [1.694271699118501, 52.621567679493452],
              [1.696866944821897, 52.623021110232983],
              [1.6982857773439, 52.625051841021559],
              [1.703022501502665, 52.625727609061578],
              [1.703922293673577, 52.626265211496943],
              [1.705787337613645, 52.628934902786916],
              [1.707502160248293, 52.629896685559856],
              [1.710069431204402, 52.630641931941078],
              [1.716859854926881, 52.630981105845279],
              [1.720201453542058, 52.630379586085489],
              [1.724873241496704, 52.626838000808398],
              [1.726055624796698, 52.624124837708301],
              [1.725380142521527, 52.621621283865267],
            ],
          ],
          [
            [
              [1.691053060629423, 52.728166913549835],
              [1.696877421967383, 52.72124203292001],
              [1.699316598312472, 52.719171770651577],
              [1.704307443262486, 52.708524466970921],
              [1.706681207454301, 52.701419096255478],
              [1.710966523586387, 52.69350631162115],
              [1.714759634192463, 52.68788353818428],
              [1.717186627912642, 52.682758658966208],
              [1.726610386872589, 52.668937965495999],
              [1.728373239453669, 52.663390283330457],
              [1.729968270997042, 52.658499072659374],
              [1.731872815631378, 52.654691833588586],
              [1.736916564640655, 52.647519485475684],
              [1.741584876349059, 52.6343619519632],
              [1.745211231146297, 52.626249965665252],
              [1.742273529146067, 52.619666086305003],
              [1.742117830772944, 52.618033059789575],
              [1.740696083841748, 52.608352852433072],
              [1.739146561285495, 52.603056232924558],
              [1.737727594803234, 52.592353193941001],
              [1.737456929294437, 52.587061109447006],
              [1.737954643628718, 52.582432843319253],
              [1.739062803173586, 52.582596600327307],
              [1.744863842925009, 52.579991654338805],
              [1.745488834450289, 52.579615667485562],
              [1.745778834551331, 52.578740280413307],
              [1.744957910849965, 52.579865446532004],
              [1.73880192123408, 52.579950478081614],
              [1.738620835548762, 52.575571229615456],
              [1.739105764927415, 52.574764354628378],
              [1.74294342358973, 52.574752694272014],
              [1.744737760236267, 52.575139336264044],
              [1.745620813365195, 52.576456099613303],
              [1.745196006711573, 52.575336203868176],
              [1.744027164145911, 52.57447642849813],
              [1.739540098047576, 52.573225099745166],
              [1.739799482142852, 52.573011815491967],
              [1.735236912873767, 52.573003998248929],
              [1.734446332164855, 52.574140724129414],
              [1.73365583711136, 52.574133302070706],
              [1.732561752057149, 52.580243825906201],
              [1.730302541795119, 52.584777419455747],
              [1.729181439104766, 52.589187835118445],
              [1.726682416708233, 52.592524505308681],
              [1.727219026915521, 52.597501494061675],
              [1.726724970273829, 52.600640042913184],
              [1.725677886291253, 52.604034367367859],
              [1.72414824877578, 52.606011007183639],
              [1.721045236068563, 52.608182838764073],
              [1.719977761594455, 52.610716947064461],
              [1.722069872466439, 52.611376120753427],
              [1.723164335706125, 52.612170997819078],
              [1.723762134786445, 52.614041979254679],
              [1.721815307122103, 52.616122558054812],
              [1.722264109725299, 52.619597497260393],
              [1.725380739601809, 52.621002426809547],
              [1.726218242492507, 52.621865633994723],
              [1.726661562217727, 52.623133422628769],
              [1.726259282861963, 52.625841816493605],
              [1.725190411040498, 52.627317329407994],
              [1.721366327321915, 52.630396925301653],
              [1.718554050129486, 52.631312850648797],
              [1.716767938704542, 52.631431030105723],
              [1.710084027956485, 52.631023748704244],
              [1.706048749614637, 52.62978840620611],
              [1.704279876460394, 52.628309301905929],
              [1.703694021201426, 52.626589869073619],
              [1.702834955793048, 52.625974535617686],
              [1.700433134148468, 52.625555866101315],
              [1.698416596957333, 52.625568543263014],
              [1.698233618014762, 52.625957435153154],
              [1.696408965964425, 52.623286369620928],
              [1.695927277305924, 52.622990201432593],
              [1.694942903476137, 52.623112691001197],
              [1.695768442193739, 52.62289442253698],
              [1.694436962962727, 52.621950189185462],
              [1.69284070197544, 52.621528700054668],
              [1.690512764551218, 52.622615030054448],
              [1.688654796823765, 52.623087619324316],
              [1.688049555165747, 52.623570609362211],
              [1.687715148914807, 52.624584833762093],
              [1.68881588225895, 52.626317969773758],
              [1.687704294032157, 52.627757580399383],
              [1.686193610507996, 52.628161738226964],
              [1.682208737957859, 52.628130201536869],
              [1.679149342187187, 52.629649212454964],
              [1.674192825443086, 52.629543272728945],
              [1.669791181504208, 52.628984580501516],
              [1.667134161130003, 52.630389155669199],
              [1.663564727578167, 52.630254166725827],
              [1.657675828874849, 52.630542474545855],
              [1.653027377701159, 52.632074822611706],
              [1.649794846417532, 52.630643697252644],
              [1.638960851805331, 52.630413397003416],
              [1.636085409468684, 52.63114039460163],
              [1.633641593665482, 52.632794891704577],
              [1.632683994254676, 52.632799106813621],
              [1.62888029678293, 52.63160351503516],
              [1.62657952583463, 52.631453682348976],
              [1.624781291010377, 52.631820885344467],
              [1.62311851845652, 52.633272242874803],
              [1.622281642123471, 52.633502935472258],
              [1.616237608742356, 52.632367398487652],
              [1.616622640704269, 52.63221441924469],
              [1.616301244519152, 52.631186282458643],
              [1.61738533213517, 52.628918928173732],
              [1.616293432276001, 52.628172482845748],
              [1.613638174443795, 52.627467483227427],
              [1.611364047453956, 52.625234332303812],
              [1.607886620798987, 52.624288060431603],
              [1.60427806378814, 52.62431528055847],
              [1.599628448717657, 52.625523548719265],
              [1.598769446493983, 52.626402345252565],
              [1.598621650003288, 52.627932290275965],
              [1.598020928837533, 52.628483025147624],
              [1.593425277590563, 52.629040904920942],
              [1.593167950109237, 52.629670230561288],
              [1.593817123664309, 52.632070007103714],
              [1.591750253457552, 52.634411031931549],
              [1.592864082236527, 52.636635710191541],
              [1.592129941196906, 52.637526852152547],
              [1.589806391779834, 52.638634405036434],
              [1.588026123192582, 52.638415862142978],
              [1.585548478831304, 52.636938525692202],
              [1.58334564513652, 52.636685944369546],
              [1.580371997759484, 52.63689646771553],
              [1.578879840580834, 52.637180849839197],
              [1.578889965722321, 52.637594283283029],
              [1.578494947692715, 52.637283331271945],
              [1.577549461570334, 52.637581746147148],
              [1.574775391548217, 52.63962273611638],
              [1.573635412967122, 52.639350456878738],
              [1.571291950887648, 52.637645716171846],
              [1.569601373981638, 52.637564502088544],
              [1.568305386720385, 52.637989439843345],
              [1.566881691711111, 52.639442663591375],
              [1.566587299360009, 52.641891690491875],
              [1.567533988006572, 52.644310508923006],
              [1.570137920170836, 52.64639602335145],
              [1.56994822188734, 52.648041407391517],
              [1.568294171280099, 52.649139120379068],
              [1.567901720636007, 52.649005243284243],
              [1.566048873918209, 52.649515292392806],
              [1.561145585023385, 52.649704289983802],
              [1.558886714478924, 52.650296595739796],
              [1.557572488360669, 52.6513218754734],
              [1.55715802838972, 52.653610781152587],
              [1.557551113148701, 52.654953504610553],
              [1.560480120693784, 52.658396590824935],
              [1.561356494700251, 52.66262271996964],
              [1.562370361442277, 52.665173570186376],
              [1.562016198820794, 52.667547021606858],
              [1.560093579818207, 52.670337311268248],
              [1.554852393550722, 52.672514912163884],
              [1.553342037143197, 52.673867116088296],
              [1.55088191531225, 52.679779980736249],
              [1.554515144956742, 52.680364417538193],
              [1.550792567849436, 52.680155920708138],
              [1.549563355970828, 52.682515086328245],
              [1.549796571065184, 52.684239482187948],
              [1.550149174453102, 52.684335042367863],
              [1.549785879685113, 52.684363023533976],
              [1.550254563990466, 52.685924758896086],
              [1.550012135115683, 52.687076991569306],
              [1.553128339252576, 52.687193088495412],
              [1.553548780457564, 52.687016620816649],
              [1.55409562472524, 52.687147869094325],
              [1.553454431983498, 52.687180604547279],
              [1.554089337566029, 52.687234402402844],
              [1.550138250188076, 52.687463559503193],
              [1.548547978337217, 52.689790362041698],
              [1.545117373145144, 52.692772164320459],
              [1.54488957103045, 52.694088539415716],
              [1.545450419815116, 52.695655627110206],
              [1.550473351683808, 52.699117208090058],
              [1.555412936099138, 52.701052969072187],
              [1.561538807431096, 52.70202375391689],
              [1.567868909413211, 52.701212606843491],
              [1.570069663410532, 52.701273999964208],
              [1.570196041200242, 52.700990468599358],
              [1.570384969384272, 52.701309465354029],
              [1.570545366968958, 52.701061784364306],
              [1.570601538603544, 52.701350605271458],
              [1.573362935738879, 52.702401490016435],
              [1.576581857573381, 52.704174226282881],
              [1.57782042551572, 52.705376224591639],
              [1.578863475255327, 52.707896383942689],
              [1.57968490932442, 52.708704427798246],
              [1.580914477087926, 52.709450658016401],
              [1.581559639908543, 52.709263855347686],
              [1.581252429279552, 52.709761534864022],
              [1.585324513950145, 52.711326556642035],
              [1.60254143579352, 52.715017036744321],
              [1.608559750573462, 52.715383374379172],
              [1.609292019530947, 52.71537275357953],
              [1.609346621261614, 52.714922271666453],
              [1.609566219643042, 52.715357201600391],
              [1.609968307350185, 52.7151686572182],
              [1.612105631594971, 52.715331048216974],
              [1.61939247398288, 52.717366172325889],
              [1.623891111901562, 52.720261515371746],
              [1.62866238431586, 52.724041484851284],
              [1.633017436819197, 52.725818393130645],
              [1.632833564904048, 52.726030004551731],
              [1.630149999789215, 52.725024859277113],
              [1.627575421431698, 52.723457765236816],
              [1.626565556523467, 52.724354849446179],
              [1.62662521934818, 52.724930462125194],
              [1.632922446532946, 52.727062533796968],
              [1.634685317012664, 52.731046937405011],
              [1.637127313512686, 52.731014253026359],
              [1.637181124060759, 52.73272693445923],
              [1.638117039178476, 52.733698370892334],
              [1.645812645087484, 52.736600816987888],
              [1.651349788164906, 52.737852287905504],
              [1.663376336141035, 52.739294252746816],
              [1.667572568139276, 52.74124393140066],
              [1.675364036248339, 52.742675424041543],
              [1.68457272652738, 52.734662158307707],
              [1.686370739929354, 52.732476308790176],
              [1.691053060629423, 52.728166913549835],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000146",
        LAD13CDO: "33UE",
        LAD13NM: "King's Lynn and West Norfolk",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [0.315473867060134, 52.81881446485518],
              [0.322301812235773, 52.818328559364083],
              [0.327978661290166, 52.818439402664218],
              [0.331627592529202, 52.817984718165299],
              [0.334510756209577, 52.8172275918701],
              [0.33819912393427, 52.815077937045388],
              [0.341203292780101, 52.815335194990688],
              [0.342923550632066, 52.814456938894622],
              [0.344685366951692, 52.814186558491279],
              [0.344739079199958, 52.81347517075492],
              [0.345874382735671, 52.813558828145204],
              [0.347993640862086, 52.811413799895803],
              [0.35019600490429, 52.810565455695233],
              [0.351907564180176, 52.809422888064709],
              [0.353830633948181, 52.806264700931067],
              [0.362430788103121, 52.796639173831771],
              [0.366652649049315, 52.791094460031381],
              [0.366451303118152, 52.790664185794611],
              [0.366947191996734, 52.790591350833097],
              [0.367156644633768, 52.789859755490461],
              [0.368613529337699, 52.788392924335056],
              [0.374805620905394, 52.778998811761568],
              [0.375537015710311, 52.777943840026573],
              [0.387852152745212, 52.759374341487629],
              [0.390053256656795, 52.752772405852888],
              [0.387908340071851, 52.75222037608328],
              [0.388834577490175, 52.752085726934304],
              [0.390159897144772, 52.752446557629632],
              [0.39222160163273, 52.747990986220863],
              [0.392107461500715, 52.747097721942907],
              [0.391023028007016, 52.745694421429882],
              [0.386698886319566, 52.741509620770209],
              [0.386029995179939, 52.741577933266441],
              [0.386476964670783, 52.741271313883978],
              [0.384742037021262, 52.739968268611115],
              [0.364217620608179, 52.725493724841755],
              [0.357005621476432, 52.720230688620248],
              [0.354284714497105, 52.716974949397013],
              [0.353074165786204, 52.714044343709133],
              [0.352808703633456, 52.710927724932844],
              [0.353439881678625, 52.70766382432334],
              [0.352975628586888, 52.707399696636003],
              [0.353585387250457, 52.707341732401048],
              [0.354232156313033, 52.706101530447825],
              [0.349393342912079, 52.702472320154698],
              [0.35010942489784, 52.702218046450518],
              [0.351351343895551, 52.703292189732004],
              [0.351147003738747, 52.70177665914111],
              [0.349724677508846, 52.700594594743379],
              [0.35020353841029, 52.700498779986589],
              [0.351535511649637, 52.702147498567314],
              [0.351618065769661, 52.703627680258151],
              [0.353858639189287, 52.705432779082521],
              [0.354660500043793, 52.705616465036918],
              [0.359624625411261, 52.701046195495607],
              [0.360380425977187, 52.699554717215719],
              [0.361632781722564, 52.698297012858099],
              [0.367402644354244, 52.695872834274752],
              [0.370569471235664, 52.693162381602534],
              [0.37127493674147, 52.692143897572606],
              [0.370869768369512, 52.690047053880377],
              [0.371890759986325, 52.687507154217606],
              [0.371705834526864, 52.686170196221283],
              [0.363769850981322, 52.674573055260012],
              [0.361960078271262, 52.671490832256126],
              [0.359739819035677, 52.666220989777187],
              [0.360099651006367, 52.664802127359003],
              [0.363020180598438, 52.660601436967191],
              [0.364001525597649, 52.656590441812682],
              [0.365785581278164, 52.654003878295406],
              [0.36532411334097, 52.651563751270523],
              [0.365608095907832, 52.648334550314331],
              [0.365061138973032, 52.646390672772],
              [0.367410511218452, 52.643003314297196],
              [0.36771255792094, 52.640987625329991],
              [0.366558505734081, 52.637736800166685],
              [0.367063777290532, 52.635324111609393],
              [0.366750813106256, 52.632538431366342],
              [0.364501951641946, 52.628518185406698],
              [0.365265719459878, 52.625144517467291],
              [0.363549187466449, 52.623919045012059],
              [0.362255732879683, 52.62163127711289],
              [0.362814037423616, 52.616252063960168],
              [0.364643503958372, 52.61294614205962],
              [0.364179223386231, 52.6108270626614],
              [0.361347775931483, 52.607893801371731],
              [0.360196125324557, 52.60474356997404],
              [0.358445461901218, 52.602425309693736],
              [0.352498512020406, 52.597250171018437],
              [0.348608711567349, 52.596196234110884],
              [0.34459033597189, 52.593809442011292],
              [0.340979518856326, 52.59072299252707],
              [0.339913531747117, 52.588284822845637],
              [0.340365606117989, 52.588346022330114],
              [0.340969574813338, 52.584748169210229],
              [0.340287210528912, 52.583107177559995],
              [0.320188386782261, 52.56515247379923],
              [0.318584433764569, 52.563865714765917],
              [0.316968742278534, 52.56319419964511],
              [0.317862783192383, 52.563191980678717],
              [0.317698174398136, 52.56295242701772],
              [0.307860254010193, 52.554141239125315],
              [0.299992715519968, 52.54708482635121],
              [0.260073541043537, 52.511466328235599],
              [0.245564435139604, 52.500540755542048],
              [0.238769983133388, 52.509167588785871],
              [0.2362982233601, 52.507273033933984],
              [0.213621451619175, 52.518455329770447],
              [0.206103417996801, 52.519617198231778],
              [0.215043665753683, 52.52243628396247],
              [0.216626331284199, 52.520844797221081],
              [0.219686667094076, 52.523128846630442],
              [0.223153268988033, 52.521366139352274],
              [0.224805196887528, 52.522405993892583],
              [0.222366864494151, 52.52389227918372],
              [0.225665602005191, 52.52592442858478],
              [0.22626262502969, 52.525602985188115],
              [0.230571316271275, 52.527892036745122],
              [0.228803124723, 52.530822464367468],
              [0.225584985914372, 52.532551884729173],
              [0.220272739616398, 52.528903069108118],
              [0.214920712031573, 52.532033549932812],
              [0.211036169299817, 52.530695413275723],
              [0.208114952321992, 52.530069296711638],
              [0.207667693328847, 52.531851686761414],
              [0.205828118720225, 52.533832675459529],
              [0.210064714551489, 52.535454837844796],
              [0.209355014743797, 52.536092107878268],
              [0.213125958983563, 52.538153553654453],
              [0.203853006446774, 52.545386807786691],
              [0.2063026328858, 52.544736930240575],
              [0.208534157453778, 52.54462067662449],
              [0.211217449355386, 52.545217965834091],
              [0.214339977012096, 52.546950708586778],
              [0.214606908096446, 52.549088411781185],
              [0.213527249149187, 52.5489134923181],
              [0.20892458363893, 52.546961993846999],
              [0.206924495230108, 52.547224996996107],
              [0.205121163049621, 52.54788262739752],
              [0.204184454506975, 52.549440334736389],
              [0.204689873911464, 52.554637943243449],
              [0.205973588649949, 52.555849466222931],
              [0.211581920318035, 52.555399526858324],
              [0.214078983976733, 52.556180966156205],
              [0.21695399848973, 52.558750894981451],
              [0.217220118276691, 52.56057569815988],
              [0.216507328424924, 52.561040425929455],
              [0.214846341010764, 52.56081256103684],
              [0.212290562254667, 52.561127408047561],
              [0.209416168008232, 52.562853530054902],
              [0.206717709074923, 52.563591723819997],
              [0.205613489380576, 52.565550885747228],
              [0.206805381189388, 52.567571553561294],
              [0.213773794097317, 52.568548297070585],
              [0.216105996964477, 52.569468553997325],
              [0.218308521729058, 52.570945975983733],
              [0.218468161616599, 52.572208098706142],
              [0.218018669340903, 52.573389921893863],
              [0.215260100717509, 52.574702194483741],
              [0.214612747707727, 52.575503766160999],
              [0.214586235822132, 52.577905908127043],
              [0.215762591244565, 52.579427746949243],
              [0.202593586525264, 52.580077215460697],
              [0.199526844257416, 52.582739979240934],
              [0.198069986397401, 52.58607501920271],
              [0.198136930500372, 52.588769434359918],
              [0.197183105275663, 52.589062279787022],
              [0.199130372328695, 52.591773934542559],
              [0.193835182827392, 52.592085267468988],
              [0.193909923206238, 52.595383765392171],
              [0.195142864910252, 52.595147814839301],
              [0.191609285365222, 52.598871014969298],
              [0.191653765809252, 52.599140835693063],
              [0.192542174478792, 52.599366259660719],
              [0.191582467397395, 52.601498825961386],
              [0.194612299677979, 52.601607254081088],
              [0.195088828502919, 52.602247607238681],
              [0.197050507294379, 52.602198637364857],
              [0.198317354626391, 52.602754177526705],
              [0.20150185778914, 52.603117614648177],
              [0.201370342397789, 52.604588372458629],
              [0.202624125858148, 52.605587391326551],
              [0.203416021818246, 52.60762272160688],
              [0.204156156432995, 52.608037842062927],
              [0.204861130153885, 52.609163941466896],
              [0.204972905987896, 52.610947572619736],
              [0.205596956567878, 52.611784748946349],
              [0.209295706883221, 52.613516800217631],
              [0.217369749860378, 52.614506918398568],
              [0.216920139435511, 52.614964012721202],
              [0.212522384175021, 52.616778921332028],
              [0.210848000583005, 52.617909852272483],
              [0.2170453799718, 52.621564134653404],
              [0.219283508635518, 52.62179375907165],
              [0.215192276394834, 52.624452713119382],
              [0.203166069897026, 52.630207696329556],
              [0.196558510328176, 52.632171871839581],
              [0.192011613370718, 52.634246814216304],
              [0.189702153211375, 52.635705683795535],
              [0.188076517184407, 52.638461030487797],
              [0.185657949404254, 52.640495486333457],
              [0.183101602343318, 52.641637782811912],
              [0.18048104909898, 52.641967481090646],
              [0.179167614671942, 52.642455607984708],
              [0.176171921720302, 52.64437100293393],
              [0.17395066344711, 52.647182040318128],
              [0.172043760639577, 52.648675424346507],
              [0.169909500230155, 52.650416607776634],
              [0.169579391157931, 52.652737038206084],
              [0.17832458313309, 52.652617022296027],
              [0.176263055680846, 52.655672423944971],
              [0.18382603614781, 52.655642950487639],
              [0.183047531905023, 52.659069536893426],
              [0.18412225843817, 52.660072051779252],
              [0.183565234756567, 52.662899325267553],
              [0.183974099644202, 52.663210084848693],
              [0.183616737422359, 52.663843372123807],
              [0.184395806290217, 52.666980496203692],
              [0.184414136540802, 52.67212143639015],
              [0.181063280320894, 52.672079761977599],
              [0.180820785344128, 52.673239617986916],
              [0.182658392795157, 52.673486314274776],
              [0.182918450042942, 52.674014712782224],
              [0.183351594337336, 52.674009429891299],
              [0.183562765075155, 52.674803074495721],
              [0.184487534170263, 52.676319959816119],
              [0.184701239104533, 52.677875125738595],
              [0.180399948392306, 52.678065842086191],
              [0.179800495503612, 52.677613814909492],
              [0.178362399909497, 52.678382947692853],
              [0.17461813371765, 52.678382485889827],
              [0.174355875019985, 52.678761339181335],
              [0.171915463604627, 52.678891511325347],
              [0.169855446909892, 52.678637930379466],
              [0.166372507978185, 52.680540386836519],
              [0.162385374796803, 52.679460519570299],
              [0.161904080631024, 52.679630246235533],
              [0.161797994183435, 52.6793993058035],
              [0.159745283879691, 52.679622856159632],
              [0.159620205928316, 52.680446045008821],
              [0.157129243652419, 52.679673203366889],
              [0.157157336820255, 52.680663537148973],
              [0.15484451318788, 52.680773950115189],
              [0.154878421740533, 52.682123831331367],
              [0.154046531859716, 52.68214524940295],
              [0.156972254700643, 52.708215400863665],
              [0.166108628125931, 52.721086184745765],
              [0.168332015795357, 52.725189627013108],
              [0.169601573751752, 52.724571176209182],
              [0.170157795745846, 52.724588868622689],
              [0.169161297326551, 52.725039587148622],
              [0.168532632253327, 52.72574072041985],
              [0.170715713571257, 52.735700881813329],
              [0.17204233835352, 52.737863266725505],
              [0.186308473244142, 52.735328962021278],
              [0.192263564938008, 52.738316509478622],
              [0.19079927825238, 52.739454900761999],
              [0.194867295489466, 52.738777247367047],
              [0.196749244243136, 52.742234623022668],
              [0.211962705777223, 52.749628591725362],
              [0.217539470083081, 52.753233359008242],
              [0.227800083565871, 52.757151882745042],
              [0.233148435288909, 52.76024501561092],
              [0.24393783795649, 52.763980468821899],
              [0.24826158732322, 52.765130397153925],
              [0.25165969381498, 52.767096181311409],
              [0.25396486185434, 52.767126122614123],
              [0.265684122693289, 52.771570247632553],
              [0.269822711878973, 52.772013514451956],
              [0.272193493415003, 52.772785436512741],
              [0.24506945630683, 52.784503396909408],
              [0.251983065335659, 52.792542778608812],
              [0.2608901742624, 52.796035531409217],
              [0.253163701362657, 52.797334323598697],
              [0.262237393690475, 52.8036435037478],
              [0.262689898243845, 52.803586321134681],
              [0.26400938152586, 52.805424155448449],
              [0.263856389158514, 52.806020503634059],
              [0.268739897418378, 52.815805111240962],
              [0.275252803751286, 52.816816906512194],
              [0.278806237051448, 52.816288356511905],
              [0.280658406651786, 52.817281381232725],
              [0.285946737411591, 52.818336804258685],
              [0.286630707957814, 52.819039337953498],
              [0.287542167199178, 52.819212386515382],
              [0.290765200117389, 52.818016394096993],
              [0.295765577269164, 52.818389100018855],
              [0.302611265625767, 52.818152148898086],
              [0.305246646426654, 52.818508287159069],
              [0.312494536346115, 52.818355561815196],
              [0.315473867060134, 52.81881446485518],
            ],
          ],
          [
            [
              [0.736733154703902, 52.96485605050254],
              [0.735556391397449, 52.964640368775243],
              [0.735005477108549, 52.963698999376852],
              [0.734321195027217, 52.963899986986668],
              [0.734362099758495, 52.964288400165721],
              [0.733329118513136, 52.964911040379164],
              [0.732101353844196, 52.964689304366729],
              [0.731500718993206, 52.964257111983066],
              [0.731967954505814, 52.963330081142921],
              [0.730815784357686, 52.962771191520837],
              [0.731372407857147, 52.962482339878022],
              [0.731694592250601, 52.961640472115398],
              [0.731217718674963, 52.961375381823778],
              [0.73012875633429, 52.961841921375957],
              [0.729894557828835, 52.962260936276785],
              [0.730687908558184, 52.96381360828903],
              [0.734557859031967, 52.966893379449289],
              [0.735675467495692, 52.96702051352387],
              [0.738059885894367, 52.965700404096367],
              [0.737477548030735, 52.964899146347605],
              [0.736733154703902, 52.96485605050254],
            ],
          ],
          [
            [
              [0.712693068678485, 52.97664918240288],
              [0.71507792712295, 52.976528168235959],
              [0.718002971823559, 52.976588077341205],
              [0.722214188928301, 52.977367485419485],
              [0.726030551733781, 52.977599201217522],
              [0.734519299007192, 52.975999582942322],
              [0.733689804492281, 52.97567337812044],
              [0.731545875279077, 52.976140815792739],
              [0.731149410948427, 52.975665260979568],
              [0.732340706784826, 52.975776347356536],
              [0.733385834681508, 52.97538903054874],
              [0.732380506756847, 52.974282758751507],
              [0.730870095057621, 52.973882272327295],
              [0.728544127813212, 52.974178496477258],
              [0.726539389279251, 52.973733556451087],
              [0.725273572801824, 52.973833635388409],
              [0.723532761338592, 52.974623485497929],
              [0.721071961808401, 52.974861507507171],
              [0.718645629632059, 52.973917143338554],
              [0.718326022738369, 52.972599038194794],
              [0.717271908865152, 52.972132189309214],
              [0.716580491251203, 52.972032903385994],
              [0.716089900216308, 52.972546771390626],
              [0.715074076252264, 52.972713861348282],
              [0.71450669958595, 52.972763692975789],
              [0.71031925157502, 52.974591144288873],
              [0.71056069297939, 52.97535444917613],
              [0.710099512573186, 52.975459385107555],
              [0.70982592679548, 52.974491795771698],
              [0.708590551052407, 52.974031701986902],
              [0.706090906346625, 52.974077870670158],
              [0.705951462067534, 52.975374089613943],
              [0.705308974605237, 52.975986685204894],
              [0.705531869329218, 52.97669287319377],
              [0.707748841017892, 52.976959785832328],
              [0.705586930730026, 52.977097156358589],
              [0.70606803536071, 52.977810951231568],
              [0.708711650357944, 52.978086112696225],
              [0.711255152222427, 52.977904010912447],
              [0.711852130176147, 52.976993888286664],
              [0.712693068678485, 52.97664918240288],
            ],
          ],
          [
            [
              [0.781366146998483, 52.976659288091739],
              [0.781276340362133, 52.972363161842985],
              [0.783310010430932, 52.970075598369874],
              [0.781707727314283, 52.968621334037316],
              [0.781382975720605, 52.967600239409293],
              [0.780185604878288, 52.966998818787609],
              [0.780236242021015, 52.965653312175434],
              [0.781807850488436, 52.964059085183223],
              [0.781034229904127, 52.963607809773073],
              [0.781435378935726, 52.959859492247908],
              [0.781065466749284, 52.957484339952771],
              [0.778429994936082, 52.951613007471046],
              [0.782850087799051, 52.935866789646973],
              [0.78408048938074, 52.926056272954362],
              [0.784958606317482, 52.926013201700798],
              [0.784879905239159, 52.924787615543636],
              [0.787516010728381, 52.92422400009869],
              [0.787245307415342, 52.922998425503984],
              [0.785055232317581, 52.922814216824889],
              [0.786441954948711, 52.915750694679929],
              [0.786421863124406, 52.913770186614364],
              [0.787861338375137, 52.907859095907526],
              [0.789098947380971, 52.902815974107511],
              [0.789882102584457, 52.899822049502475],
              [0.805155046906968, 52.900775015024898],
              [0.808731505017988, 52.900774081296952],
              [0.811716798926641, 52.900278979772239],
              [0.810795651896685, 52.89886832608758],
              [0.813228436398341, 52.89827116432766],
              [0.814573047656572, 52.899812939498794],
              [0.816974684193097, 52.900041930020272],
              [0.817097795404721, 52.898478849354156],
              [0.815978696881106, 52.898557555661256],
              [0.815217159797482, 52.896995675499419],
              [0.816928289735659, 52.896737471191393],
              [0.816913899908641, 52.89495734193796],
              [0.8211969702962, 52.895037273667768],
              [0.821553259631688, 52.892831989671393],
              [0.818271365364776, 52.892092553425087],
              [0.816480983660182, 52.888329512723949],
              [0.815035862862658, 52.888558026117856],
              [0.81377671398612, 52.885392923234399],
              [0.81104194866927, 52.882622454832749],
              [0.806880451895153, 52.87941268073736],
              [0.805448073560391, 52.880778300900765],
              [0.8018941240424, 52.882231770362722],
              [0.797161871838342, 52.885243384356038],
              [0.794856003543986, 52.883484863608437],
              [0.794150584185368, 52.882062712321598],
              [0.794371805573391, 52.880556698607165],
              [0.797771582566004, 52.864428117553111],
              [0.794719168142531, 52.866061995730078],
              [0.791126253548699, 52.865530581022441],
              [0.791356856671372, 52.862110796122714],
              [0.79017936137208, 52.861937060119516],
              [0.790953676641326, 52.859857825571204],
              [0.789646447456724, 52.859606205596378],
              [0.780370775331326, 52.858786186795534],
              [0.765263709269881, 52.856484673492744],
              [0.757840952683723, 52.855769880021285],
              [0.750542814533886, 52.856011201931771],
              [0.747489400232172, 52.855649412087544],
              [0.742596340867119, 52.855749110455683],
              [0.736819608389718, 52.857103612884373],
              [0.731506282576146, 52.857634274808177],
              [0.730912733063758, 52.854901734197924],
              [0.727758052637255, 52.855313294490301],
              [0.727033660616797, 52.853640320385423],
              [0.724416921244269, 52.850355350650823],
              [0.724898116413277, 52.847604396322609],
              [0.723509812481237, 52.84704188855325],
              [0.724437345740399, 52.845654689282767],
              [0.725067982266305, 52.842848146151319],
              [0.728110891623133, 52.841544498547137],
              [0.72967180131971, 52.839416123312368],
              [0.736154785692074, 52.835181651408071],
              [0.73637404084371, 52.833001385916177],
              [0.737441920258699, 52.831850030471507],
              [0.73940122539773, 52.830544101180124],
              [0.741061054692783, 52.831132519757517],
              [0.742462454918367, 52.829834031779669],
              [0.742478504201499, 52.829075618313603],
              [0.744756551455307, 52.828002320479207],
              [0.744287519727728, 52.827650785185561],
              [0.744494320108065, 52.826998562390415],
              [0.744046886767145, 52.826824572251951],
              [0.74498794077686, 52.825056514609521],
              [0.749068593307264, 52.822607860288642],
              [0.746643528689309, 52.821603850653176],
              [0.747274577406534, 52.820642357315208],
              [0.748883181557163, 52.819695079717583],
              [0.752418843298755, 52.818802895746806],
              [0.752213828465803, 52.817438135375617],
              [0.753409159403953, 52.816789032427643],
              [0.753927925067206, 52.817059346165664],
              [0.755007565043289, 52.81695154775597],
              [0.75620139948567, 52.816701706414115],
              [0.756319729152737, 52.816413902821758],
              [0.754528871200426, 52.816141673010613],
              [0.754551390588808, 52.815606111765192],
              [0.753352650256456, 52.81434625365717],
              [0.751881707932861, 52.81408544224351],
              [0.751170695662578, 52.814905840975705],
              [0.749406233638267, 52.815896330845831],
              [0.747992748710091, 52.814782575166262],
              [0.746665914286598, 52.815229645699098],
              [0.745281443043476, 52.813797761151314],
              [0.74608120459184, 52.813465420325237],
              [0.742654259485519, 52.810659102600894],
              [0.738950407256398, 52.8082088809035],
              [0.73843173110647, 52.808597630659705],
              [0.735423247323689, 52.806935149501506],
              [0.733769687468495, 52.805673898061251],
              [0.732209803822638, 52.803633539552436],
              [0.730921833877618, 52.80363262823343],
              [0.73106607268365, 52.801696881455676],
              [0.736167290289259, 52.799267408640475],
              [0.725686746305559, 52.795184380168408],
              [0.722545167596711, 52.79329174365369],
              [0.713715663463134, 52.789810008503437],
              [0.70792179089962, 52.788447046104729],
              [0.701981195791441, 52.7866492280126],
              [0.699899995943495, 52.786189469990333],
              [0.700418138959635, 52.783923335897235],
              [0.698854022320492, 52.783740433321249],
              [0.698360332420846, 52.78093981718159],
              [0.695896078001758, 52.780515683730847],
              [0.697722671240714, 52.77895087013755],
              [0.699819044743448, 52.776165041664171],
              [0.699491010093783, 52.775680637896045],
              [0.700470549873241, 52.773420180248223],
              [0.699437299937293, 52.773136174794139],
              [0.695989546351944, 52.772913377299574],
              [0.695149495431388, 52.769780717267231],
              [0.690064816172711, 52.770007686839584],
              [0.689139438929524, 52.762067910765616],
              [0.688389836707751, 52.760656054046308],
              [0.691411180000405, 52.759269252371993],
              [0.687720750457222, 52.753668116962501],
              [0.68870853677755, 52.751947994442965],
              [0.673373457856587, 52.745957124270667],
              [0.668894783566201, 52.742067322240757],
              [0.668116541976495, 52.740533694432635],
              [0.675275612842601, 52.73913051991741],
              [0.680327593132038, 52.737680154600717],
              [0.688512428182619, 52.736252072381397],
              [0.69410474298217, 52.736032477585638],
              [0.701110056487316, 52.736167991937052],
              [0.700496120640743, 52.735655036283156],
              [0.704515013195117, 52.731607720248064],
              [0.715439536420958, 52.724623633423732],
              [0.716699680769313, 52.722928486353759],
              [0.718209382549963, 52.721770734629466],
              [0.717122756936368, 52.721348736119474],
              [0.717056698606732, 52.718619295641567],
              [0.713262688915571, 52.717426618682715],
              [0.711668072069944, 52.716380447158691],
              [0.710844197765635, 52.715536037948269],
              [0.711388859550001, 52.714863544752326],
              [0.710938632839303, 52.714551921590946],
              [0.71126460401819, 52.713982447126057],
              [0.708232544502663, 52.712412487651576],
              [0.708555984734862, 52.712088567512438],
              [0.708047926983418, 52.711272889899732],
              [0.709501879345661, 52.710651560121001],
              [0.710178589871458, 52.709984145374214],
              [0.710834955216939, 52.709943953729706],
              [0.711038615646755, 52.709002299123156],
              [0.709805285636963, 52.708404646365814],
              [0.708913469745657, 52.70904100771638],
              [0.707087390963736, 52.708768907633434],
              [0.705447017007693, 52.710300885031188],
              [0.69963589659294, 52.707762712702937],
              [0.699034807597869, 52.706587628638566],
              [0.698818613455579, 52.703733014034263],
              [0.700107290670767, 52.703769299716193],
              [0.699013087744489, 52.702279031749228],
              [0.699592200536132, 52.701732598026013],
              [0.696519653154634, 52.70080531804048],
              [0.694242533594585, 52.70103786120584],
              [0.692907808946994, 52.700949504694535],
              [0.69138240915531, 52.700312440848862],
              [0.69081915327957, 52.70058870613007],
              [0.689713190670455, 52.699598591454567],
              [0.689963162162014, 52.699090250115404],
              [0.689196979533566, 52.69897905243964],
              [0.688329953150109, 52.697997889856879],
              [0.683917452022063, 52.698188742351888],
              [0.683609987772268, 52.698701969398883],
              [0.681911059166997, 52.698731443125318],
              [0.681636594396651, 52.699131514916061],
              [0.67988406465227, 52.6990353820051],
              [0.676777675444701, 52.69945539567081],
              [0.674609416589904, 52.698370439045526],
              [0.672360268302384, 52.700126110537923],
              [0.673021828879949, 52.700366567372534],
              [0.672673339220018, 52.700622613425736],
              [0.672843580446346, 52.701494615060881],
              [0.672490331945638, 52.701479202960776],
              [0.672352758266983, 52.701940908533466],
              [0.671614643593043, 52.701756127132093],
              [0.671495699252466, 52.702837874042899],
              [0.662832877922798, 52.703057140534007],
              [0.662625191510615, 52.703949336747662],
              [0.659984733524325, 52.703860267203524],
              [0.6594089813055, 52.704762517106296],
              [0.654102103468472, 52.704109070467965],
              [0.653739763809258, 52.704041651301182],
              [0.653967601595779, 52.702405368160512],
              [0.655223834003533, 52.701884454098888],
              [0.654549786897388, 52.701510194829474],
              [0.652524955962309, 52.701879256511418],
              [0.651337828071162, 52.701294356788104],
              [0.64984985350078, 52.701386989386897],
              [0.649343935514817, 52.701026026147403],
              [0.648525697218811, 52.695312708316216],
              [0.649000312102245, 52.693374175916176],
              [0.647949116571191, 52.693294262920716],
              [0.647730182717415, 52.690655455643423],
              [0.643952458795443, 52.691642629820969],
              [0.640564873785932, 52.692034698754469],
              [0.638278133617124, 52.691834757936789],
              [0.636399404172083, 52.693836865800094],
              [0.634610597742731, 52.693133003348805],
              [0.629811354381352, 52.697298670776462],
              [0.630439811318263, 52.69765789476083],
              [0.626000412523086, 52.701791129592884],
              [0.622652177632828, 52.701670153113781],
              [0.618348680746402, 52.700329282605644],
              [0.614357121625861, 52.699868896582046],
              [0.611971343390297, 52.699948485980478],
              [0.610424847320341, 52.698788414473491],
              [0.607478100266193, 52.69745232633845],
              [0.605694086826637, 52.697220908683555],
              [0.604314948449381, 52.696281881175629],
              [0.601589854913409, 52.695481201627913],
              [0.598193546882987, 52.694964942420093],
              [0.595661296137677, 52.694018723755875],
              [0.592225026841025, 52.693898821307229],
              [0.590174958082766, 52.693044446624377],
              [0.588566849896982, 52.69285478731603],
              [0.586092948870438, 52.691788396796014],
              [0.584926760111531, 52.692213990499233],
              [0.584048081957118, 52.692095577845784],
              [0.5820757928346, 52.691225880564083],
              [0.578785239787155, 52.690460382624806],
              [0.577928825897824, 52.68958880615758],
              [0.575155967881175, 52.689595151006252],
              [0.57237621903038, 52.688527931286657],
              [0.571019112043129, 52.689079824168601],
              [0.568677963192015, 52.687062434164616],
              [0.566246293743752, 52.686474894215507],
              [0.562814220567072, 52.684141116113828],
              [0.560157478403406, 52.682964847901445],
              [0.55914658293294, 52.682832923854782],
              [0.557763422052966, 52.683166722029561],
              [0.556059830239845, 52.682752087968659],
              [0.55507877121554, 52.681914511821986],
              [0.550962914025454, 52.680498838703009],
              [0.553181678188397, 52.678613077842137],
              [0.563370622386706, 52.673204658773457],
              [0.564717021449741, 52.671567740979228],
              [0.564944550294121, 52.668508216770348],
              [0.564219180213225, 52.666376619065488],
              [0.56437899084566, 52.663044302081595],
              [0.562157022533132, 52.655273791553149],
              [0.573046418754619, 52.653795035059808],
              [0.578487776605311, 52.6534932660362],
              [0.575451128921934, 52.64517600682894],
              [0.561629215164493, 52.613164487362646],
              [0.567086524888383, 52.614078585575328],
              [0.566085024392626, 52.607993746103737],
              [0.565024356634523, 52.60541440974901],
              [0.56558438729172, 52.60162917764756],
              [0.564856720270915, 52.597593090020375],
              [0.564154692336091, 52.59644561507038],
              [0.562201908865202, 52.595170549330625],
              [0.561563861346933, 52.593371543515836],
              [0.55933026371315, 52.591632219507758],
              [0.559072709138717, 52.590785333550137],
              [0.557973573908174, 52.590521341822189],
              [0.557206725237503, 52.590892200191689],
              [0.556110685830392, 52.590300810911877],
              [0.554802863807199, 52.590281385813448],
              [0.553226945049151, 52.589790247447041],
              [0.552430721171787, 52.58914829611254],
              [0.550567666191685, 52.588999619171801],
              [0.549062343916419, 52.588527586770425],
              [0.548357187584123, 52.587825198777963],
              [0.547537889315519, 52.587877903348897],
              [0.546494343240645, 52.587373419507024],
              [0.545883964064357, 52.58670045062091],
              [0.544023200507562, 52.586538134209952],
              [0.543943650575633, 52.58620983342179],
              [0.54288088521548, 52.585958409613582],
              [0.541533245072749, 52.585911818823085],
              [0.539842568410716, 52.586672870806225],
              [0.538057621450764, 52.586238171754118],
              [0.535762396528149, 52.57982923686329],
              [0.532868932029589, 52.578058599650525],
              [0.530499529267065, 52.576105840306539],
              [0.532628813036066, 52.574559515621416],
              [0.535167463611466, 52.567730508223292],
              [0.535050068216111, 52.567000165307],
              [0.535812140447728, 52.566780617309995],
              [0.536594316072596, 52.565784613341584],
              [0.539013293125103, 52.565229182597882],
              [0.541165962900718, 52.565504897970371],
              [0.543621461170339, 52.565071780344958],
              [0.54895700903565, 52.566032443502635],
              [0.550545589715341, 52.565155653806585],
              [0.553511778052905, 52.565158232500309],
              [0.555040939184093, 52.564682814286805],
              [0.556299867501914, 52.564635844940135],
              [0.556780083451757, 52.565788157374293],
              [0.557523249197152, 52.565651604592404],
              [0.558368438193362, 52.566000216809904],
              [0.559751101150132, 52.565492834945879],
              [0.561290746340645, 52.566590737925672],
              [0.562215979971151, 52.565932266262358],
              [0.565324985767868, 52.564917139617641],
              [0.567421574263114, 52.564912138746287],
              [0.568976220925157, 52.562578200724012],
              [0.571453326704963, 52.562572060196025],
              [0.572496110690327, 52.561909152833508],
              [0.575873525551063, 52.561076744728148],
              [0.576841964609372, 52.561085335263449],
              [0.577944078173174, 52.55995169366156],
              [0.579852077636874, 52.559527944572451],
              [0.579458900872581, 52.558334257112023],
              [0.580116431756842, 52.557973722522433],
              [0.581344628265977, 52.555974944682319],
              [0.582900184057827, 52.556009342252942],
              [0.583994461552672, 52.554998106176221],
              [0.586395104009376, 52.555279271201073],
              [0.585726976970713, 52.553250838744681],
              [0.58789295323756, 52.55217837402644],
              [0.590270803692234, 52.548118504509709],
              [0.591532375500941, 52.547048679130413],
              [0.593090896317061, 52.546713293498918],
              [0.592943984918019, 52.545697691173288],
              [0.594290339536863, 52.545792272012349],
              [0.595881339438688, 52.543759297939047],
              [0.596742311776643, 52.543827630259756],
              [0.597739653363588, 52.543258108708812],
              [0.601156121012663, 52.543442026306828],
              [0.602204474245173, 52.543760680496263],
              [0.605113481433112, 52.543145439805208],
              [0.604545965165555, 52.542104938547865],
              [0.608717610080921, 52.540102172353301],
              [0.609802829527716, 52.538780651958611],
              [0.610688229465716, 52.535658776796801],
              [0.612103845415149, 52.534713912803355],
              [0.612468851397943, 52.532814772449555],
              [0.613344247392384, 52.531614748817759],
              [0.608434560881872, 52.526723383771596],
              [0.60449723717761, 52.519062369199879],
              [0.601972586115579, 52.508795662026841],
              [0.596871881927057, 52.495149524800624],
              [0.590781576082204, 52.484306181800932],
              [0.583650291231611, 52.468008960981017],
              [0.582554895881578, 52.463886529729692],
              [0.582476981410336, 52.451842994871235],
              [0.577926902977417, 52.452513317941879],
              [0.572235509746703, 52.451963764978785],
              [0.567934309137199, 52.453448385520844],
              [0.565772924053312, 52.453310972242193],
              [0.563370185930242, 52.453655345417637],
              [0.563127648603539, 52.453458277167954],
              [0.559128360217757, 52.455841551150733],
              [0.554797236188839, 52.456009861812248],
              [0.549494940451949, 52.454554272349235],
              [0.549011019491534, 52.453260826308906],
              [0.547797184758466, 52.452019979592052],
              [0.544478684433155, 52.451595077578531],
              [0.542596559818519, 52.450298421036429],
              [0.54034768078836, 52.450324287128936],
              [0.537472876668179, 52.451162060244855],
              [0.533578201722832, 52.451593592101439],
              [0.531913271068245, 52.453307216045047],
              [0.530353775746908, 52.453576201748881],
              [0.528045393533402, 52.452694888798035],
              [0.526970338823753, 52.449908695144288],
              [0.523811787447383, 52.44818221566122],
              [0.523075767766208, 52.448205120440974],
              [0.520681337344689, 52.449339764569764],
              [0.516384238589233, 52.450508579146472],
              [0.51537835902651, 52.450368125543854],
              [0.51350775384648, 52.449231728906483],
              [0.507793834019062, 52.450567925153734],
              [0.503514666037074, 52.450011182036164],
              [0.500900725207466, 52.450217576705967],
              [0.499926639641577, 52.449883886325701],
              [0.498040273997872, 52.448123522982868],
              [0.496017972747959, 52.447261623407286],
              [0.493285621844108, 52.447922657953775],
              [0.485486195354666, 52.447723609803475],
              [0.480902028561349, 52.446888311531175],
              [0.478137088378233, 52.445557013870172],
              [0.475706024460822, 52.446712187810391],
              [0.473043225057055, 52.445887589381201],
              [0.469548019311035, 52.446122513981024],
              [0.46724261321978, 52.445265141530413],
              [0.466810111206009, 52.445926097911205],
              [0.459363300610278, 52.447239439331966],
              [0.458041594100419, 52.445571911025951],
              [0.45698431093703, 52.444951851609034],
              [0.452805810818643, 52.446396432334751],
              [0.451435959154792, 52.447752082667989],
              [0.449855743070806, 52.447652655392282],
              [0.44940477500954, 52.445811422349614],
              [0.445515415929153, 52.447354059627763],
              [0.44442551519798, 52.445531419751063],
              [0.440981404603991, 52.444776209345733],
              [0.439104909635309, 52.443467024398593],
              [0.440018599702105, 52.442133523533499],
              [0.439118018802959, 52.440721464584747],
              [0.436252006827783, 52.440301285381778],
              [0.433332285154425, 52.438905600871628],
              [0.431185429806149, 52.438395860038092],
              [0.430646144448344, 52.43733958826973],
              [0.42932078440588, 52.436409174829485],
              [0.428344428150226, 52.436580302946034],
              [0.427744644661342, 52.437204983931579],
              [0.424532800371008, 52.442698523633609],
              [0.425787003389519, 52.443724867683038],
              [0.425214238592836, 52.444361568574983],
              [0.423754906001086, 52.444905843813856],
              [0.423447941232544, 52.451027643488437],
              [0.423332006391519, 52.453687174827451],
              [0.428357317646392, 52.454281849488247],
              [0.428506273174045, 52.454634875934907],
              [0.426274702566808, 52.45506015756137],
              [0.423908398171085, 52.4563828689734],
              [0.416471860792724, 52.459443120123538],
              [0.405886298431344, 52.463384384393429],
              [0.404104291761999, 52.46453384152467],
              [0.402067422229327, 52.465330566446625],
              [0.40037698086875, 52.467272998833103],
              [0.397947635639772, 52.468734932538929],
              [0.396281396661219, 52.470884527093851],
              [0.39441470114463, 52.472219884189343],
              [0.391894142761401, 52.475510706230637],
              [0.384254981118213, 52.477673777567894],
              [0.380684889990837, 52.479521613260303],
              [0.379138384554739, 52.48238430148065],
              [0.377102356330586, 52.484257801689807],
              [0.375998998728888, 52.486030704704419],
              [0.373068032679441, 52.48775755502156],
              [0.371714262415514, 52.490985837312799],
              [0.367122778223196, 52.495925361878193],
              [0.367041353545088, 52.501155787276929],
              [0.364972534970549, 52.50085187952304],
              [0.363968678728477, 52.499443132653987],
              [0.362072088000953, 52.498914531924655],
              [0.361069137463142, 52.499197119584757],
              [0.359386601630487, 52.500821359013401],
              [0.355200581187618, 52.500738431081601],
              [0.352793562063228, 52.501466119125148],
              [0.34973713643732, 52.501070086024498],
              [0.347779511789148, 52.502131333549627],
              [0.345012689932138, 52.501667385993322],
              [0.341678416109735, 52.502889793208219],
              [0.337913842512371, 52.505864130313668],
              [0.33234995392749, 52.508542931582369],
              [0.330917020134704, 52.509117878511546],
              [0.328744959236974, 52.509065343193662],
              [0.323308887913418, 52.507703865023331],
              [0.318284791000041, 52.507639678412154],
              [0.317722748750854, 52.508720717692512],
              [0.318373248354135, 52.509572084629966],
              [0.318236803769266, 52.51187936041314],
              [0.317272432256983, 52.512989860104113],
              [0.315540575329731, 52.512679384320506],
              [0.309340124573062, 52.513604450219972],
              [0.289510581618902, 52.510703377495965],
              [0.278613781233241, 52.508539041283676],
              [0.268481895351107, 52.508959453132135],
              [0.257283662552827, 52.507065995637547],
              [0.256455392333792, 52.507696830544823],
              [0.246019604530783, 52.499873920931506],
              [0.245685457894589, 52.500342438822777],
              [0.261159857811338, 52.512014746189656],
              [0.299817866427939, 52.546448911922717],
              [0.3405452787219, 52.582548183122725],
              [0.341131550723555, 52.583494212530262],
              [0.342193559293016, 52.583810396125486],
              [0.341234415063843, 52.58739010464231],
              [0.341785723467426, 52.590613533929535],
              [0.345244398046397, 52.593532129860158],
              [0.349314718932457, 52.595785690345778],
              [0.352140012106798, 52.596539751352033],
              [0.353953041785613, 52.597360633081983],
              [0.359840184725735, 52.602985581236851],
              [0.360805788138242, 52.60416133774978],
              [0.362308841266243, 52.607967246425133],
              [0.36488912380455, 52.61010343639888],
              [0.365551335480069, 52.613290377598084],
              [0.363820063423856, 52.616032369005922],
              [0.363372847055634, 52.621880538749167],
              [0.366399127490179, 52.625091303119099],
              [0.365584775007589, 52.628456997804982],
              [0.367755991400454, 52.632214419130008],
              [0.368035760870826, 52.634603326187069],
              [0.367654690286291, 52.637426257546259],
              [0.368765801716917, 52.640017938331503],
              [0.368433603136278, 52.642924414914638],
              [0.36610366957568, 52.646177429487565],
              [0.366185540338813, 52.647200850223939],
              [0.366732553731268, 52.654700702498467],
              [0.365222123541367, 52.658136928560779],
              [0.364495146143151, 52.6606673191434],
              [0.362314335846597, 52.663718547909554],
              [0.361490884875475, 52.664766311641614],
              [0.3610612442638, 52.666590296662505],
              [0.362556029084685, 52.669678814914853],
              [0.36409618601433, 52.673192615200954],
              [0.371461604438703, 52.683711363565834],
              [0.373151295992778, 52.68643978017019],
              [0.373380059753358, 52.687629293807454],
              [0.37230019849635, 52.689818810968788],
              [0.372889893957956, 52.691715037877621],
              [0.372151108532837, 52.693128034206758],
              [0.368029770691236, 52.696685739078532],
              [0.363824925339176, 52.698080668557438],
              [0.361478105376696, 52.699736061018179],
              [0.360714268159371, 52.7012699673556],
              [0.355509961216836, 52.707288212951035],
              [0.354359930512151, 52.710289088405155],
              [0.354563483478327, 52.713931145924327],
              [0.356416761408942, 52.717548509149047],
              [0.358576210904388, 52.719891035718653],
              [0.366564300501423, 52.72550804192575],
              [0.373469115589945, 52.730313591014976],
              [0.374205797787373, 52.730541613275854],
              [0.375121534479769, 52.729731107091752],
              [0.376757619361201, 52.729665946153986],
              [0.376227800500953, 52.730245737815139],
              [0.376610958915315, 52.730618402952601],
              [0.376002077302855, 52.730425598977668],
              [0.375516865233936, 52.730927165168453],
              [0.375766307619687, 52.731510219048978],
              [0.377048667914107, 52.731095175179313],
              [0.377028785118891, 52.73219974624935],
              [0.376588130898104, 52.732159127066083],
              [0.377344981706368, 52.732892954648698],
              [0.390541473217154, 52.742365535957688],
              [0.393096343611475, 52.744810074536318],
              [0.394606974302297, 52.74747257127661],
              [0.39550598996699, 52.747546125610533],
              [0.395081273312505, 52.747853228430444],
              [0.395395720509131, 52.749074235248393],
              [0.395829905638204, 52.749020504439372],
              [0.396194550859112, 52.748347754005962],
              [0.396419757816528, 52.748860221951745],
              [0.395843010940867, 52.749367317026348],
              [0.395116641239502, 52.749335235740993],
              [0.394623118841779, 52.747929020638082],
              [0.394083487640253, 52.749761624361113],
              [0.396641727929562, 52.750366331463503],
              [0.393845141592448, 52.750117110613786],
              [0.392251123220721, 52.753465573831257],
              [0.394995892104172, 52.753670745278619],
              [0.392145713256575, 52.753772339617861],
              [0.391641958427003, 52.75545134777569],
              [0.39175125609663, 52.75755408287305],
              [0.3910286416382, 52.757890556239687],
              [0.391130385946882, 52.758503232180331],
              [0.390709517484065, 52.758693648430707],
              [0.389690635525629, 52.761090670855928],
              [0.393463223446248, 52.760286262692674],
              [0.393650594722015, 52.760459615578355],
              [0.388891813124765, 52.761823398012723],
              [0.374592110308481, 52.784282034155247],
              [0.374619482177799, 52.784730165041879],
              [0.375006393864223, 52.784781762569132],
              [0.376181046366406, 52.783831205626988],
              [0.380247809968748, 52.782290330825198],
              [0.376280210424165, 52.783909243876053],
              [0.37499508748383, 52.785363742942607],
              [0.376679663966218, 52.785760162528327],
              [0.376512039753895, 52.785904322775323],
              [0.378496549679654, 52.786614440678285],
              [0.379901495531308, 52.787519583874648],
              [0.380880046319965, 52.787025190912225],
              [0.380965391175914, 52.78538341544985],
              [0.381086186798529, 52.786970700121636],
              [0.38053177614054, 52.787491650277929],
              [0.381426744323443, 52.787883700586136],
              [0.380049952876218, 52.787739595906466],
              [0.381423326265124, 52.788744262255591],
              [0.380442724817128, 52.788441148605152],
              [0.379384983812471, 52.787566811706455],
              [0.378971504873145, 52.787681205879458],
              [0.379101867069563, 52.787460995318533],
              [0.377534921595638, 52.786549199416712],
              [0.376981588301293, 52.786900170569318],
              [0.377305931903933, 52.786456680344585],
              [0.374806145692568, 52.785679533191754],
              [0.374294542206407, 52.785435314210304],
              [0.373867610026339, 52.78560211100644],
              [0.371887788018372, 52.788874181601045],
              [0.371089674814344, 52.790398021056966],
              [0.371882451675149, 52.790684287189514],
              [0.370948436588615, 52.790637321938767],
              [0.370765795366709, 52.791397161487076],
              [0.370304811623222, 52.791566423579134],
              [0.3627600400183, 52.80172721234819],
              [0.359634510284358, 52.805241263192634],
              [0.358380884769653, 52.807874626548276],
              [0.35916371144818, 52.808465988792463],
              [0.359018438053253, 52.809756460659017],
              [0.358448117489766, 52.810158030566527],
              [0.357580592478884, 52.810113231537258],
              [0.357749759575286, 52.812525885145838],
              [0.358574950331115, 52.813185644158324],
              [0.361634344651098, 52.813804543145139],
              [0.36221591420088, 52.814841374564821],
              [0.363252019443028, 52.81484591756152],
              [0.365980484120246, 52.816226583443139],
              [0.36716329099031, 52.817564304925448],
              [0.368670413050654, 52.818259841384325],
              [0.370447619939946, 52.817835915281705],
              [0.370746987137433, 52.818264223942741],
              [0.37172877356632, 52.818250896713131],
              [0.373401576614726, 52.818880121210022],
              [0.375076596832189, 52.820447093268776],
              [0.377142297074211, 52.821147525955965],
              [0.379391200903071, 52.822809039673331],
              [0.380661247752486, 52.822853698247037],
              [0.386092144657064, 52.826424842087754],
              [0.388731052485958, 52.827526259537159],
              [0.389285108861587, 52.82839807289983],
              [0.39148831513565, 52.82926633033869],
              [0.393023573247154, 52.83143111171961],
              [0.396843864861029, 52.832192918857729],
              [0.398088523363706, 52.83276840693874],
              [0.398337975127697, 52.833684998463568],
              [0.400138763838217, 52.834436248389942],
              [0.400107801484426, 52.835220937102932],
              [0.400564741794971, 52.83521439182433],
              [0.402649297416475, 52.836827547667106],
              [0.404730584322966, 52.836766508039965],
              [0.405067112452501, 52.837670531886815],
              [0.406341900963663, 52.838651742529429],
              [0.408169824200213, 52.838545427868574],
              [0.40973172047179, 52.839695212366287],
              [0.411104934702388, 52.839501875520241],
              [0.413867398454254, 52.840288226263418],
              [0.415431939463229, 52.842738962220324],
              [0.418936311169391, 52.846313663828674],
              [0.422061049651148, 52.848101297093407],
              [0.423036331697758, 52.848199178882687],
              [0.430113227985692, 52.851122409120194],
              [0.429941945343607, 52.851831754696526],
              [0.430724936426245, 52.851676351807882],
              [0.432655185165915, 52.852965758852136],
              [0.441026051559938, 52.854568829411399],
              [0.442246744609835, 52.854589574433192],
              [0.44475974126588, 52.853285244892994],
              [0.44618519884055, 52.857874921132279],
              [0.445589222625963, 52.859850966575081],
              [0.446056809078225, 52.864412655943148],
              [0.443844804561354, 52.866029947312377],
              [0.443416540793657, 52.867133938207004],
              [0.445445256517784, 52.873878105416679],
              [0.446834589030034, 52.876198076352132],
              [0.451199865961099, 52.881422044570968],
              [0.45298644957635, 52.884449472682363],
              [0.460479991389205, 52.891961531019156],
              [0.465453176558018, 52.898092231857895],
              [0.471987302284975, 52.908653668131706],
              [0.472497806799425, 52.913098366909928],
              [0.475270051658549, 52.917238783484876],
              [0.476350718313559, 52.923844842330126],
              [0.477681972110062, 52.926756421771508],
              [0.479400591447452, 52.93154723089382],
              [0.484250854158816, 52.936779684500209],
              [0.485922008030187, 52.939917846298364],
              [0.486464835406558, 52.942038385348987],
              [0.488473152577146, 52.944897909202915],
              [0.489483880467121, 52.947230723911389],
              [0.497714776840349, 52.953694631592221],
              [0.504685073767406, 52.956443171025619],
              [0.508539996351414, 52.958373255290589],
              [0.514924356981158, 52.962110061953744],
              [0.524724583441186, 52.96908920269216],
              [0.5352312840127, 52.972102467845716],
              [0.536046792409014, 52.972039207612461],
              [0.536387400008202, 52.971674969949888],
              [0.535695109067385, 52.971087385402448],
              [0.535262674888959, 52.970720345158895],
              [0.534885888440306, 52.9704005298005],
              [0.533729508193546, 52.97003405895439],
              [0.533258172001331, 52.970088469115126],
              [0.533638001928557, 52.969742883419116],
              [0.532501685867062, 52.969286056807853],
              [0.53212154569077, 52.969483881708058],
              [0.531452488641186, 52.969325499013088],
              [0.531185358533744, 52.969724123616501],
              [0.533069214641732, 52.970110281230738],
              [0.53214356867999, 52.970217128341396],
              [0.531017423575393, 52.969730398498662],
              [0.531429844089843, 52.969192905534861],
              [0.532472755881609, 52.969173379028582],
              [0.535051326941285, 52.970224361068553],
              [0.535604066883482, 52.970965341891578],
              [0.536319298868315, 52.97147860793774],
              [0.536064346967249, 52.971016485441112],
              [0.536614264013873, 52.971479504579172],
              [0.53685675822436, 52.97218646408011],
              [0.539424192672295, 52.973855315847715],
              [0.538029767837406, 52.973775410842961],
              [0.538004946339997, 52.97398724417409],
              [0.540932399282358, 52.974884085726188],
              [0.536372556038908, 52.97434579837531],
              [0.534218337234565, 52.974442115191117],
              [0.543410960335881, 52.975491044019122],
              [0.540566286939335, 52.97549884892257],
              [0.541656038995353, 52.975843301733271],
              [0.550340237340351, 52.976345090291197],
              [0.555686074528058, 52.976211349640145],
              [0.562270410193764, 52.976541612959146],
              [0.562664555928848, 52.976556491478448],
              [0.562293520205226, 52.976353188784451],
              [0.561992199103734, 52.976195138511336],
              [0.562313070801973, 52.976205303856609],
              [0.563010388051486, 52.976107541552324],
              [0.56233634124678, 52.976095103709525],
              [0.560728061555239, 52.975776401574954],
              [0.562452575305362, 52.975013596959208],
              [0.567607691939234, 52.974706294317421],
              [0.569376654955148, 52.973866896826891],
              [0.569770981586466, 52.972981679963944],
              [0.569640123551469, 52.972106921476382],
              [0.569408710202829, 52.972775512326898],
              [0.569166334168163, 52.972456153509832],
              [0.568523259546295, 52.972412509875994],
              [0.566947473281357, 52.972891651520897],
              [0.566734907153801, 52.973376399237935],
              [0.566700358517602, 52.972793585541226],
              [0.565004819838116, 52.972358136817391],
              [0.563590485975664, 52.972344601047851],
              [0.563006724678291, 52.972824761573996],
              [0.561222959786524, 52.973667080073007],
              [0.563054696455349, 52.972704137538372],
              [0.563224369061177, 52.972098035280268],
              [0.563276686931717, 52.971723752153331],
              [0.562554266262232, 52.972251860110205],
              [0.561558694758764, 52.971914559579716],
              [0.562593363803033, 52.972006443393248],
              [0.564225139595163, 52.971089155834171],
              [0.56373405574706, 52.97039210801973],
              [0.563024135802452, 52.970527013246716],
              [0.563110183205571, 52.969967673154116],
              [0.566067859450145, 52.971219316249382],
              [0.568274527475588, 52.970755324025482],
              [0.566913498565078, 52.970459236424368],
              [0.566359874959164, 52.970994511472583],
              [0.56552077449304, 52.970809420413339],
              [0.566363371568822, 52.970902720796971],
              [0.566735423984923, 52.970495453464302],
              [0.565988383479335, 52.969601630806146],
              [0.566214133265935, 52.969289239659311],
              [0.56355168985394, 52.969475295059844],
              [0.569912342445239, 52.968662612093354],
              [0.570101407457959, 52.967531863499858],
              [0.57059964072743, 52.967520185768763],
              [0.570553720347359, 52.968201850468219],
              [0.570940146791931, 52.968238447920683],
              [0.572286204373293, 52.96632735149467],
              [0.573881371715243, 52.966129156191869],
              [0.573733920764869, 52.966002877111443],
              [0.573096694293024, 52.96600678119966],
              [0.572293334979095, 52.965268874110741],
              [0.572063084261765, 52.963999734989827],
              [0.572435194869316, 52.965174986525263],
              [0.572886207982125, 52.96549701425657],
              [0.573515596241647, 52.965186656771124],
              [0.573144464334672, 52.965858282739667],
              [0.573655967572987, 52.965936116410987],
              [0.574080750749581, 52.966000751257148],
              [0.574602348421848, 52.965480519082888],
              [0.57390004858417, 52.96566657808507],
              [0.574027743429752, 52.964883331805147],
              [0.574561739909562, 52.964847483893173],
              [0.574306276925559, 52.965360151516769],
              [0.575575662268117, 52.964968476357271],
              [0.574907568029158, 52.965278784631835],
              [0.575125680963731, 52.965465580708603],
              [0.575857210759103, 52.965019919494118],
              [0.575599220064289, 52.96471350036142],
              [0.574663940040813, 52.964662737594239],
              [0.575453401461051, 52.964690585439094],
              [0.57558225646338, 52.963977447267034],
              [0.575868205047729, 52.964353395601933],
              [0.576273384059466, 52.964004723719803],
              [0.575913319651784, 52.96473636339784],
              [0.576763754469986, 52.964737703778283],
              [0.577117774109228, 52.9642300861018],
              [0.577003471269605, 52.964711823570504],
              [0.577530516159447, 52.964709382371097],
              [0.576198482611114, 52.964798517400681],
              [0.575640903651511, 52.96533931867679],
              [0.57585283106847, 52.965772620295475],
              [0.577196153388683, 52.965372131435984],
              [0.578113391622326, 52.965995138713723],
              [0.582199390895219, 52.966509749135184],
              [0.582462685252038, 52.9662297790846],
              [0.583504318055049, 52.96631503428614],
              [0.582551118290126, 52.966314177374855],
              [0.582154281353379, 52.966602445040287],
              [0.578009838940271, 52.966081907767048],
              [0.577205166352425, 52.96547354224333],
              [0.575980562693506, 52.965866961392464],
              [0.57481008805064, 52.965618086144858],
              [0.574516357263553, 52.966063246520513],
              [0.575276749355025, 52.966829045420198],
              [0.5747529402728, 52.967262108798366],
              [0.5752376945857, 52.9680006116672],
              [0.578641556043589, 52.967612075468928],
              [0.576509030014026, 52.967991032505381],
              [0.57746937977017, 52.968561857349087],
              [0.577287538172117, 52.969009992204136],
              [0.578697673739146, 52.969029749129135],
              [0.57856777440174, 52.968682350316605],
              [0.579045556739549, 52.968694000942541],
              [0.577853746481058, 52.970615316585658],
              [0.581370097704077, 52.9705866292935],
              [0.582743551826987, 52.969790687929461],
              [0.582664881807696, 52.96914589369193],
              [0.583595458340337, 52.968192321793353],
              [0.58440970240884, 52.967835625364934],
              [0.584886707364869, 52.96796731778791],
              [0.584955610605604, 52.967375062602692],
              [0.585032756073121, 52.967993812943909],
              [0.583726081580853, 52.968284793527332],
              [0.582832132672827, 52.969178224903423],
              [0.582886791215903, 52.969819944346554],
              [0.581550603301868, 52.970666330447372],
              [0.581652574728553, 52.971177541550851],
              [0.581877766268371, 52.971606948013104],
              [0.583193731773499, 52.971719514899775],
              [0.584161862511256, 52.972417800089744],
              [0.586408381078874, 52.97314670311242],
              [0.58607365748573, 52.971807029455306],
              [0.584304563538534, 52.971090216743058],
              [0.583714274576192, 52.970358540257166],
              [0.584550403015965, 52.969719027499217],
              [0.583909390545848, 52.970433424288402],
              [0.584486618370756, 52.971070970919783],
              [0.586097655627685, 52.971685118925294],
              [0.587281365173265, 52.970930120496192],
              [0.586925089507677, 52.97050756212515],
              [0.586709322092309, 52.970251647285231],
              [0.586162466756574, 52.969603024190533],
              [0.58625939368453, 52.969181000983966],
              [0.586914481290625, 52.968927559838107],
              [0.586250782747306, 52.968513103427142],
              [0.58705290102875, 52.967978616523695],
              [0.586578124843999, 52.966363249529188],
              [0.58692224874205, 52.965916061783069],
              [0.588716916336887, 52.965917434617531],
              [0.589158106005251, 52.966297162816112],
              [0.590006465196058, 52.965839875442768],
              [0.589196250216054, 52.966387148044994],
              [0.58872127965612, 52.96601534941378],
              [0.587032853631792, 52.966022453369796],
              [0.587208543479619, 52.967966235698633],
              [0.586463275429339, 52.968604689270563],
              [0.587677092680741, 52.968778774780795],
              [0.58708735653301, 52.968580311849273],
              [0.58723059322506, 52.968335315465296],
              [0.588097196618021, 52.968605975031423],
              [0.588297240966655, 52.968140341707795],
              [0.589195413083088, 52.968091998361203],
              [0.588443376801807, 52.968218083641908],
              [0.58812429999863, 52.968710587838224],
              [0.587325096912654, 52.968421376454856],
              [0.587861282694289, 52.968795444071461],
              [0.586727797251502, 52.969193283487741],
              [0.588867448723539, 52.968884122869945],
              [0.589091370201771, 52.968469220741468],
              [0.590828712305914, 52.967908929379149],
              [0.590587162946853, 52.968228007828458],
              [0.589188944190593, 52.968531834882413],
              [0.589012245920982, 52.968939413977331],
              [0.586381840880125, 52.96936086754112],
              [0.586821456738562, 52.970288818996714],
              [0.588573573608433, 52.970869604727845],
              [0.589616696750021, 52.970604097582196],
              [0.589723030453389, 52.970813085549807],
              [0.592744396308936, 52.970850597896828],
              [0.593326734695109, 52.970078986526183],
              [0.593455654567254, 52.968749891502995],
              [0.594364508744086, 52.968133897213782],
              [0.59084685273169, 52.965324309363439],
              [0.594569838332587, 52.96800622665571],
              [0.594870660580951, 52.967782056914459],
              [0.594379951313892, 52.967546399407233],
              [0.594844949335821, 52.967154095748384],
              [0.594567992897551, 52.967602537823538],
              [0.595050901262799, 52.967707984706216],
              [0.598012505913801, 52.967497594867019],
              [0.598933070264392, 52.966954141744814],
              [0.599516152459168, 52.967041194047923],
              [0.599393546700059, 52.966487287162259],
              [0.599869340284051, 52.966598265164073],
              [0.599681951470879, 52.967049255607549],
              [0.599022592752932, 52.967031310839758],
              [0.598267107245625, 52.967545977780574],
              [0.599094032290721, 52.967824618703474],
              [0.599663771767592, 52.967540585898952],
              [0.600971010638149, 52.966888864041508],
              [0.602210340980704, 52.967016384591616],
              [0.601035242904246, 52.967016039943339],
              [0.599614423403646, 52.967607864726467],
              [0.599381564828061, 52.967925330175689],
              [0.600447438349926, 52.967892115086848],
              [0.601472034465793, 52.967394920547363],
              [0.602614733601005, 52.968293347920444],
              [0.603410476698669, 52.967876911291924],
              [0.60327365641899, 52.96827891215441],
              [0.60202749915032, 52.968335882831077],
              [0.601809732836457, 52.967686052104412],
              [0.60016987242485, 52.968131076241129],
              [0.598514600654955, 52.968170886641133],
              [0.598470663609547, 52.968605249779969],
              [0.600243019726496, 52.970043818911584],
              [0.599462467756648, 52.969694934590372],
              [0.598868789773499, 52.970545499387811],
              [0.599566143760644, 52.971372324537086],
              [0.598607794941629, 52.970816018386643],
              [0.598831159175942, 52.970500881803005],
              [0.599468761251017, 52.969601282724391],
              [0.598262423849254, 52.968609803873676],
              [0.598373631224838, 52.968129910112864],
              [0.598946283997624, 52.967993298893695],
              [0.597492926271127, 52.967699580255001],
              [0.595017514290036, 52.968020727341127],
              [0.594028846495822, 52.96901971902335],
              [0.594009547857746, 52.970214243018631],
              [0.596331447736019, 52.971081584323038],
              [0.596646850767728, 52.971795829319376],
              [0.597744390019723, 52.971519170374151],
              [0.598300688142283, 52.971903542250104],
              [0.597416174146287, 52.972553202668642],
              [0.597247892709972, 52.973120970850673],
              [0.597241018697422, 52.972563325758856],
              [0.598175367283649, 52.971974619964818],
              [0.597812921171178, 52.97164355640416],
              [0.596546297386707, 52.971882549145548],
              [0.59561489495109, 52.970831084652076],
              [0.593813063053197, 52.970464906144421],
              [0.592057514251503, 52.971742278537427],
              [0.590594903249636, 52.971718428548456],
              [0.590458184150361, 52.972644859737329],
              [0.589700186736493, 52.97344456230163],
              [0.59115368028876, 52.972867076147971],
              [0.591904099153248, 52.973061115286171],
              [0.592208047747334, 52.973510365209961],
              [0.593901806873322, 52.972642565164556],
              [0.593513381292231, 52.973070958109965],
              [0.592058713056559, 52.9736035403564],
              [0.591847049687452, 52.973153176207425],
              [0.591176008851699, 52.972966397383573],
              [0.589811726736616, 52.973640848292661],
              [0.58990242339242, 52.974136113728179],
              [0.588919420134678, 52.973988491726203],
              [0.58822128145825, 52.974320209723153],
              [0.588338709681066, 52.974938979592316],
              [0.586498066473103, 52.975096852538066],
              [0.588376205037511, 52.976288718934832],
              [0.590071353992484, 52.976686080528133],
              [0.604826980784744, 52.974430296135921],
              [0.614944266267619, 52.973604718941161],
              [0.617846082095907, 52.973607359142761],
              [0.618332320016737, 52.971819869188835],
              [0.615562556911445, 52.97247253311938],
              [0.615292718139186, 52.971654826279035],
              [0.614313737687999, 52.971426399710552],
              [0.614176230362539, 52.970579702532532],
              [0.616036360989423, 52.971464014787365],
              [0.61751814060282, 52.971607618930832],
              [0.618562407886624, 52.971289678398435],
              [0.618629941443947, 52.97230785813651],
              [0.619096849607559, 52.972565521939515],
              [0.618891162100069, 52.971134967238569],
              [0.619760520359515, 52.970462098889577],
              [0.618662769832078, 52.969919816118605],
              [0.619172373351096, 52.96906155695212],
              [0.617855247503477, 52.969201184289261],
              [0.616264013812212, 52.968723702710115],
              [0.614816860258712, 52.969225830654892],
              [0.612560033979909, 52.969120818896656],
              [0.612566034911957, 52.969952875561212],
              [0.612202562747776, 52.969713144019295],
              [0.612201008887012, 52.968971356924555],
              [0.615124719136048, 52.968971780978762],
              [0.61627641034496, 52.968485147518159],
              [0.617973035690303, 52.969006164743547],
              [0.619414011362032, 52.968845822029429],
              [0.619226570722643, 52.970031475104228],
              [0.621778360542423, 52.969988681151008],
              [0.624816120253856, 52.971181360136278],
              [0.626017707329395, 52.97095339249222],
              [0.624151160353225, 52.971490080127815],
              [0.622620484713768, 52.971154314410242],
              [0.621839143384696, 52.970377583590697],
              [0.620420906661766, 52.970372904094319],
              [0.619734736670096, 52.971242255233157],
              [0.620196566860458, 52.972228361291911],
              [0.619527883860712, 52.973485770066866],
              [0.619869636319833, 52.973742593452904],
              [0.62674683940116, 52.974158136866386],
              [0.629238760206743, 52.973836863335926],
              [0.630127054293114, 52.974188564807207],
              [0.649979014762171, 52.976650926301907],
              [0.654356786708205, 52.976863427974934],
              [0.661126658836081, 52.97789352906527],
              [0.663188634653704, 52.977921063562263],
              [0.662280807615656, 52.977814622671985],
              [0.662691713261857, 52.977665142080582],
              [0.66210465830941, 52.977421129070528],
              [0.664620510234449, 52.977150728871962],
              [0.670152563919572, 52.977816024739688],
              [0.672747356997808, 52.977014955245359],
              [0.674027687982807, 52.976133716938065],
              [0.674132446292503, 52.97577977642117],
              [0.673255945229793, 52.97485355364217],
              [0.674822757752997, 52.973740163244578],
              [0.674604261791752, 52.973096766577072],
              [0.672559156270805, 52.972928779577899],
              [0.669427148569574, 52.970738629759957],
              [0.667555810889801, 52.970125152041312],
              [0.666356835623975, 52.970536918235297],
              [0.666661878632462, 52.970822308099876],
              [0.666230002218874, 52.970460636050007],
              [0.664447569376852, 52.971000570665751],
              [0.661757416608212, 52.971119316557285],
              [0.661588984123906, 52.971408132107008],
              [0.661208276055151, 52.971127121888891],
              [0.660489069328249, 52.971712414077935],
              [0.660518007550629, 52.971286450809593],
              [0.66009567740669, 52.971224871462105],
              [0.659249100589786, 52.97208905779712],
              [0.657267252806596, 52.973216917654831],
              [0.656543714466934, 52.97332031833794],
              [0.655661342590864, 52.973165601867713],
              [0.65560703206492, 52.972571554994012],
              [0.65585797122253, 52.973068590672682],
              [0.656921928018585, 52.973148209534386],
              [0.65804105934555, 52.972355273377858],
              [0.657662015493831, 52.971979800203897],
              [0.658581448699078, 52.972180430054024],
              [0.657903183939726, 52.971298217446005],
              [0.658224800523822, 52.971223583249163],
              [0.657439423694225, 52.970998372010179],
              [0.654276325893059, 52.97049719651644],
              [0.651117126239711, 52.970666642335637],
              [0.649270338317289, 52.970036145739897],
              [0.646029761268856, 52.969604817624784],
              [0.644887664939724, 52.969999812271595],
              [0.645584305891342, 52.970943731153739],
              [0.644892236604536, 52.971440202603091],
              [0.645327501899699, 52.970836151980983],
              [0.644571229656693, 52.970208273236473],
              [0.64494718484233, 52.96970625256359],
              [0.639688899579395, 52.969965266087328],
              [0.642479389262519, 52.969636178159909],
              [0.641290929110333, 52.969247182290999],
              [0.642775962655198, 52.969541461711614],
              [0.646598537405167, 52.969431195087289],
              [0.647003522079265, 52.968551762697842],
              [0.645571944154668, 52.967986590660679],
              [0.642846791736142, 52.967505034068154],
              [0.640208114919392, 52.967927870291092],
              [0.636189354443236, 52.967847162933424],
              [0.635623424316119, 52.968239173533142],
              [0.636165219960329, 52.967744292484888],
              [0.63996664413649, 52.967851410438627],
              [0.641914606640138, 52.967556338778287],
              [0.641622733873221, 52.966995444040208],
              [0.640688631478823, 52.966893021519816],
              [0.641003530959173, 52.967416540066985],
              [0.639807878484598, 52.967299243089535],
              [0.639523792318744, 52.967598688513348],
              [0.639189200671377, 52.967045031435347],
              [0.638851135051666, 52.967191914055832],
              [0.638560396419947, 52.966917826286021],
              [0.641600988934396, 52.966321539961868],
              [0.643618945344643, 52.967467174033899],
              [0.645358092463782, 52.967582223681994],
              [0.64393774375567, 52.966663403088454],
              [0.642550156658069, 52.966646617662676],
              [0.643530274109335, 52.96613655667224],
              [0.644430343418345, 52.966364700850619],
              [0.645054505451224, 52.96719603948943],
              [0.647113886480711, 52.96818873087858],
              [0.648179146773085, 52.969095647533102],
              [0.649763993400575, 52.969681648532656],
              [0.649170910145367, 52.96901958664197],
              [0.649261736167396, 52.966828046435886],
              [0.650180000124481, 52.966866013645948],
              [0.65119762193684, 52.967480831929969],
              [0.653406611569353, 52.966901897229533],
              [0.655207665975045, 52.967224930314558],
              [0.656228737900532, 52.96660774391281],
              [0.658366804018994, 52.966767634599087],
              [0.658964722654797, 52.967264093206296],
              [0.659758815365301, 52.96697746137805],
              [0.659871036262956, 52.966403965214937],
              [0.660443006189233, 52.967350591022402],
              [0.661028938440493, 52.967215179315048],
              [0.662148191775133, 52.967857302058938],
              [0.662747138539086, 52.967424858387943],
              [0.662495906039861, 52.967117571598997],
              [0.663227167273686, 52.967141644942046],
              [0.663505139329358, 52.966866556305781],
              [0.663526457307966, 52.96721316484814],
              [0.664522815831854, 52.967067634499266],
              [0.665663073729982, 52.967566282587306],
              [0.665790573673962, 52.967193855469738],
              [0.665897934191769, 52.967703983710408],
              [0.666408204693031, 52.967742887228056],
              [0.666060429477077, 52.966400111160468],
              [0.667429743140098, 52.968054454304209],
              [0.668608255806238, 52.968230306197505],
              [0.667776458019003, 52.967203230592872],
              [0.669101886693065, 52.967274169988322],
              [0.669597555333453, 52.968042630011283],
              [0.670759722659667, 52.967905010915381],
              [0.66999221368343, 52.96828373588118],
              [0.671314364527162, 52.968976963448476],
              [0.670040482634833, 52.968680092594568],
              [0.671577720814419, 52.969720965473364],
              [0.673596494824008, 52.968886065026339],
              [0.675355796083825, 52.969083847553961],
              [0.673737952171488, 52.968451270636706],
              [0.67233967071479, 52.968721015686803],
              [0.672394522822818, 52.968378989121909],
              [0.67331916936235, 52.968133389542245],
              [0.674953587470572, 52.968142460268801],
              [0.674632926753949, 52.968400553530302],
              [0.675586352615508, 52.96874145934607],
              [0.67709123966267, 52.968774998447664],
              [0.677114152165569, 52.968302407248984],
              [0.678418926389453, 52.967195768721751],
              [0.679818820925551, 52.967121938412362],
              [0.678793037491896, 52.967465183897374],
              [0.680072928719331, 52.967834660005799],
              [0.68147810748348, 52.967148341819538],
              [0.680987028855162, 52.968526195215375],
              [0.682107665523647, 52.96853597014865],
              [0.682044316421069, 52.968162437483294],
              [0.682499199008766, 52.968220506209285],
              [0.6824699173225, 52.968998068696024],
              [0.683347652950726, 52.969221928524078],
              [0.68339789542274, 52.96993655014235],
              [0.685862867004192, 52.969743274420019],
              [0.687315495287169, 52.97075798514112],
              [0.688407442646805, 52.970856470929192],
              [0.688851471212007, 52.970643205146231],
              [0.688633151979257, 52.970197650501213],
              [0.684644036488311, 52.96812620023438],
              [0.684248377572884, 52.967389710198049],
              [0.684688741205133, 52.967237687602974],
              [0.688923876988313, 52.970226139536777],
              [0.692543058552226, 52.971827462802551],
              [0.697996420406865, 52.97324137176583],
              [0.702005769174118, 52.973494650211762],
              [0.69816467314485, 52.973527091381705],
              [0.695797206319808, 52.973088073966494],
              [0.693191434712001, 52.972361274937846],
              [0.690848548545025, 52.971309252433045],
              [0.689302196951528, 52.971712933701184],
              [0.690103429555636, 52.97120743245825],
              [0.689813365537857, 52.970901979967955],
              [0.688651664095428, 52.971239395528656],
              [0.687229969248334, 52.971107906932403],
              [0.68527501187779, 52.970063186870895],
              [0.684794844390129, 52.970294340478759],
              [0.685518294434929, 52.970983855523933],
              [0.685083885471189, 52.971112367222496],
              [0.684791709668849, 52.970724224908253],
              [0.683180152148137, 52.970508857606994],
              [0.68214572209737, 52.96991986550001],
              [0.681574503273947, 52.970196222586267],
              [0.681974757051628, 52.969876967043035],
              [0.680791052562382, 52.969354276395187],
              [0.680155211230155, 52.969525081083766],
              [0.679552644785961, 52.970329960425282],
              [0.677869158371021, 52.970853479606511],
              [0.675378943630838, 52.970374562872983],
              [0.6752730567044, 52.971505430216489],
              [0.676072406894343, 52.971714024280971],
              [0.680541857093148, 52.970982938396304],
              [0.680789664839589, 52.971497079424836],
              [0.682161003537767, 52.971537165227431],
              [0.684084087390718, 52.972284124860728],
              [0.684613835546935, 52.972011390527257],
              [0.685917330549866, 52.973118508121381],
              [0.68730477451997, 52.973417138185937],
              [0.68838866335765, 52.974224370284887],
              [0.689713812563807, 52.974334652642298],
              [0.690622693024817, 52.976135837214493],
              [0.691448329585117, 52.976836491236135],
              [0.695132149829443, 52.978149433762063],
              [0.696429683149647, 52.978220704341645],
              [0.697722021706102, 52.977754361105681],
              [0.700419100540768, 52.977502464357165],
              [0.699609622751911, 52.976752947638062],
              [0.70044672588434, 52.976010972761216],
              [0.700346516328005, 52.975479128986628],
              [0.699573672683293, 52.97529257427518],
              [0.700386363663197, 52.975329856304704],
              [0.699748425341055, 52.974678949964321],
              [0.700779471333985, 52.975494465623164],
              [0.700760986871869, 52.976272688792498],
              [0.70006952413882, 52.976650779513427],
              [0.700260441062793, 52.977036690976377],
              [0.70095890654656, 52.977033403974197],
              [0.700477952933903, 52.97713245722727],
              [0.701170441183812, 52.977367591293941],
              [0.703118382029307, 52.976618322115613],
              [0.704076893132117, 52.97486376288257],
              [0.703501704728254, 52.974001935605642],
              [0.702194612429357, 52.973584770839437],
              [0.702333056401662, 52.973320855548387],
              [0.711286707020876, 52.972247345141668],
              [0.70836480708907, 52.973137652108385],
              [0.710471644705221, 52.973932153656953],
              [0.711380271648501, 52.973955482053739],
              [0.711640795461438, 52.973643809500615],
              [0.716182971191494, 52.972083355198713],
              [0.716419392018722, 52.971744347532045],
              [0.717685318460819, 52.971670436825214],
              [0.717515684070851, 52.972052879428929],
              [0.718622421907575, 52.972527513997584],
              [0.718888756825832, 52.973803676028879],
              [0.721192532882412, 52.974694192747044],
              [0.723500187425002, 52.974437198795783],
              [0.725106199651703, 52.973659432998964],
              [0.730892444896531, 52.973551751974526],
              [0.733293887703534, 52.974121474029928],
              [0.734774746316649, 52.975161942287336],
              [0.736171172266122, 52.975313228426174],
              [0.740417150235894, 52.975061605560484],
              [0.740691102258364, 52.97484757063372],
              [0.739337178227106, 52.974449875498308],
              [0.7387657733688, 52.973941511439378],
              [0.742790901435815, 52.974767642140328],
              [0.749306988618942, 52.973812247721341],
              [0.752121087306945, 52.972855082675053],
              [0.752279645928438, 52.972185997769337],
              [0.751227134401121, 52.971023425016696],
              [0.749805858255773, 52.970596836058853],
              [0.749240639647345, 52.970796951660745],
              [0.749029724703577, 52.970548256486424],
              [0.748421484759357, 52.970751162034318],
              [0.749149002445298, 52.971042756670663],
              [0.747900580926302, 52.970992508594811],
              [0.74823753689048, 52.970903786083937],
              [0.746850418140333, 52.968667172622787],
              [0.747463338814052, 52.967974999245826],
              [0.746547893008078, 52.966363211164541],
              [0.745943778766054, 52.966113709293609],
              [0.745522556056767, 52.966352741901865],
              [0.745452944048154, 52.965982979181611],
              [0.743694209714981, 52.965351005780917],
              [0.742671701429665, 52.965643478562626],
              [0.742316492317617, 52.965372925395613],
              [0.739402426666879, 52.965710817283757],
              [0.737143819796769, 52.966593740562786],
              [0.736814286348112, 52.967294617747022],
              [0.738919131987898, 52.968289185427409],
              [0.744292689986459, 52.968896232293424],
              [0.74510148628631, 52.969202159373687],
              [0.745779960515708, 52.970177403400449],
              [0.746216406218182, 52.970036930933674],
              [0.746444033686841, 52.969163936807945],
              [0.746690409626567, 52.970394646915821],
              [0.747826443936321, 52.970528435402592],
              [0.74735635418674, 52.970702063164687],
              [0.747613070863552, 52.970991964574949],
              [0.747015023930275, 52.970674886959962],
              [0.746054989645485, 52.970991120830703],
              [0.74567089170194, 52.970500939985889],
              [0.7448506112458, 52.971330077163131],
              [0.743014302775107, 52.971240305570646],
              [0.744072231625319, 52.971128652372684],
              [0.744139417828912, 52.970872625896924],
              [0.744229547854232, 52.97111962348297],
              [0.744722261869902, 52.971091156621981],
              [0.745060495925306, 52.970411636705606],
              [0.744691651387191, 52.96985635769142],
              [0.743900691400196, 52.969385461504437],
              [0.741916065436397, 52.969492427944481],
              [0.740611180272503, 52.969161057053036],
              [0.743837499586159, 52.96899936412057],
              [0.737340662567826, 52.968411889043395],
              [0.735755226196499, 52.967410729113752],
              [0.735714257790747, 52.969166915355409],
              [0.734948236134419, 52.969631455585478],
              [0.73550095988652, 52.970222994021661],
              [0.73486457515263, 52.970523591348687],
              [0.73493608163019, 52.971467006351411],
              [0.73562426005674, 52.971797355140957],
              [0.734983517427425, 52.972076472326229],
              [0.734991778555605, 52.972560951839334],
              [0.738023154594417, 52.973198802019205],
              [0.737359017532442, 52.973343590761537],
              [0.73483520921811, 52.972864889333259],
              [0.734503856430963, 52.972052444456956],
              [0.735148339218879, 52.971738174914215],
              [0.734657048404119, 52.971647874515952],
              [0.734658305802995, 52.970818781600975],
              [0.734237210447385, 52.970636943625252],
              [0.733776287214188, 52.970885839014279],
              [0.733787488965225, 52.972006885528415],
              [0.732725811309628, 52.972083463944401],
              [0.732420545654925, 52.972580549669438],
              [0.731650097956407, 52.972740340146565],
              [0.734926913576016, 52.969081634829045],
              [0.735284451773778, 52.967974578526622],
              [0.734967013830743, 52.967193286412503],
              [0.730783135869955, 52.964615305703184],
              [0.729948573486448, 52.963425060436926],
              [0.729331241850889, 52.96161656333345],
              [0.731453421581974, 52.960720738503035],
              [0.732173908110243, 52.961849753826897],
              [0.731717187207017, 52.962730684769873],
              [0.733041599624411, 52.963257727993806],
              [0.732751849736754, 52.964117736521153],
              [0.733344499012494, 52.964140070195306],
              [0.733769545355775, 52.963205909728231],
              [0.734889190427834, 52.962989508002586],
              [0.733651809365283, 52.962567487660777],
              [0.734224195439698, 52.962481479502713],
              [0.736772414744751, 52.964133985317588],
              [0.738614605562951, 52.964557319277027],
              [0.739667902569847, 52.964492569091483],
              [0.740433753775452, 52.964639459135256],
              [0.740420260965434, 52.96489694263213],
              [0.741969845044696, 52.964273107512732],
              [0.74644740195691, 52.965530173169817],
              [0.74669020258209, 52.964920294039814],
              [0.750130095691669, 52.968220824651546],
              [0.751220419731757, 52.968784559356195],
              [0.753845538144834, 52.969615750048021],
              [0.757863168575358, 52.969320232000989],
              [0.759869732618565, 52.971988340779987],
              [0.759822496403359, 52.972485800227176],
              [0.759309191091895, 52.972845715994389],
              [0.759530394955208, 52.972249799471754],
              [0.758026744619713, 52.97123354323422],
              [0.755339040681735, 52.970404794146653],
              [0.754520042370373, 52.970548775433549],
              [0.755120376518945, 52.972282012012322],
              [0.75585328194859, 52.972610308702997],
              [0.755259354180444, 52.972733787172025],
              [0.754278474622808, 52.974665544037698],
              [0.754519927918755, 52.974971970592406],
              [0.75578244911737, 52.974680125050384],
              [0.757260429320153, 52.975549535910226],
              [0.759009634659716, 52.975344373845886],
              [0.759936057087752, 52.975856086123606],
              [0.760596658347977, 52.975633021914561],
              [0.761320782812966, 52.975798733302597],
              [0.760607019527198, 52.975748778812822],
              [0.759333921552101, 52.976201879207146],
              [0.75872427608241, 52.975962460332482],
              [0.759349757779521, 52.975725831303286],
              [0.759121426215819, 52.975509030121124],
              [0.758373400848746, 52.97550571744739],
              [0.758592289320625, 52.975784784508896],
              [0.756648099667404, 52.975712113821537],
              [0.755299475194877, 52.974862175745727],
              [0.753997838536559, 52.975475939022957],
              [0.754638507519497, 52.975828861650989],
              [0.752554033620595, 52.97579894139578],
              [0.752309645930962, 52.976500587518956],
              [0.750756210131038, 52.976294677290355],
              [0.750533095087099, 52.976557914370815],
              [0.751635024775881, 52.977113285285185],
              [0.757406204341952, 52.978212297813933],
              [0.772217686016641, 52.978820789503786],
              [0.774186429069379, 52.978646250216727],
              [0.781366146998483, 52.976659288091739],
            ],
          ],
          [
            [
              [0.696254731505597, 52.987585268116625],
              [0.701371867513171, 52.986944818841508],
              [0.709905801274914, 52.98535765203026],
              [0.725802923240727, 52.983305343981478],
              [0.737948862784228, 52.982175445510919],
              [0.746420127714532, 52.980551106002892],
              [0.748974138799705, 52.979713332726703],
              [0.751079223573908, 52.978376949317742],
              [0.748759893962041, 52.977747234821273],
              [0.747617108087113, 52.977064196173657],
              [0.746751132447188, 52.978160565829249],
              [0.745295904146643, 52.977929833354615],
              [0.744190423373048, 52.978092949394018],
              [0.743369515491461, 52.977763016051618],
              [0.740038806436896, 52.978757060431533],
              [0.74124131050592, 52.977984783448022],
              [0.742852332354663, 52.977524079001626],
              [0.740182015481253, 52.977112713989953],
              [0.73777750988463, 52.978006200613748],
              [0.736227070037713, 52.979237854060266],
              [0.734055574480137, 52.979011787307464],
              [0.733067820603576, 52.980073088771015],
              [0.731487561780713, 52.979773127080811],
              [0.72941293541872, 52.980138220154679],
              [0.729511461473009, 52.9798814833309],
              [0.730990109787854, 52.979610117214413],
              [0.7331106716164, 52.979784358973781],
              [0.733250717728183, 52.979123821915863],
              [0.733885550126348, 52.97877291542752],
              [0.736269620562395, 52.978709043088507],
              [0.738061416829775, 52.977124735124207],
              [0.738041288191066, 52.97673584463945],
              [0.736612937935729, 52.976481007955996],
              [0.738804924606301, 52.976302812849923],
              [0.734076472713159, 52.976630222674473],
              [0.725745846257534, 52.978069719773593],
              [0.721323108595352, 52.977814117985638],
              [0.720768333941071, 52.977449159966788],
              [0.718963112329715, 52.977217128350567],
              [0.714486362660379, 52.977024553639893],
              [0.711774812436161, 52.978613307002995],
              [0.71278313551151, 52.979631558873265],
              [0.711988932629234, 52.979343962347549],
              [0.711865588930302, 52.978849522137786],
              [0.710923553154918, 52.978674994602059],
              [0.706290617143226, 52.979703183118801],
              [0.70389794549633, 52.979320647652294],
              [0.699519801417296, 52.979660283243717],
              [0.69967426609614, 52.980225963255592],
              [0.698315176549002, 52.980721717948043],
              [0.698629792697627, 52.981490576536913],
              [0.697976284795009, 52.982359650304858],
              [0.698667906357723, 52.983153218001746],
              [0.698364848064831, 52.984075478850158],
              [0.697972583665559, 52.983829914558193],
              [0.698291388038998, 52.983112313150727],
              [0.697511090137628, 52.982354026634731],
              [0.69787512105117, 52.981453762443664],
              [0.697333499153175, 52.980628011139217],
              [0.696605477919845, 52.981496970434208],
              [0.695937007267608, 52.981600260127379],
              [0.695828947152679, 52.982137731391624],
              [0.694973806280487, 52.982401710006506],
              [0.695997402465749, 52.98357441562073],
              [0.694509075278791, 52.983911202712065],
              [0.694456316730949, 52.983638145516622],
              [0.695726127175471, 52.983405227792048],
              [0.694405506936606, 52.982080098578066],
              [0.695522500660258, 52.982001712046184],
              [0.695548012284197, 52.981334831682581],
              [0.6961031870206, 52.980871740297793],
              [0.694904997687085, 52.980698401913401],
              [0.692755203248903, 52.981105918434579],
              [0.690427550143629, 52.981097500498066],
              [0.688720109081355, 52.980472546124005],
              [0.68763507477571, 52.980441343923324],
              [0.687545922843465, 52.979989268978144],
              [0.686593094826115, 52.979495580437728],
              [0.685716832984921, 52.98006749318926],
              [0.685859155679293, 52.980944583794724],
              [0.685395846833155, 52.980200634749174],
              [0.685994723629192, 52.979527991531157],
              [0.685264771039289, 52.978904266582781],
              [0.685172359048979, 52.97823106278468],
              [0.682445197203596, 52.977542737932055],
              [0.681609637188327, 52.977834053354464],
              [0.681316610474312, 52.977432434046612],
              [0.680033349683299, 52.979491756418732],
              [0.679023972502924, 52.979623320957884],
              [0.679488091588932, 52.979732447810456],
              [0.679004665589149, 52.981044475322541],
              [0.679165440708522, 52.982775386128353],
              [0.68076718832082, 52.985399061755501],
              [0.68157412255299, 52.984811667585973],
              [0.682201053752014, 52.985767743141793],
              [0.682992831763649, 52.985993548614466],
              [0.684295892215924, 52.987183420910114],
              [0.68400161500614, 52.987627973405758],
              [0.680843952286735, 52.987407916350563],
              [0.679991000139008, 52.986314859845749],
              [0.679968328901819, 52.987368321121089],
              [0.679099497649821, 52.986836710210682],
              [0.679462549513991, 52.9862026890466],
              [0.67861375819677, 52.986523952796915],
              [0.678812924980253, 52.987045488296076],
              [0.678368402276462, 52.987254229141705],
              [0.676862406049374, 52.986827779875156],
              [0.677347654597154, 52.98672333210925],
              [0.677895671939113, 52.987055372499505],
              [0.677668806078135, 52.986663942849496],
              [0.676193473086224, 52.986226903294757],
              [0.675490520644253, 52.986400989828283],
              [0.675795882862465, 52.986640491518081],
              [0.675365806602253, 52.98689025836169],
              [0.674192432368829, 52.986709857228647],
              [0.673279701796084, 52.986165704616717],
              [0.671650685245592, 52.986062068178185],
              [0.670673795389506, 52.985346694525795],
              [0.670710573594063, 52.98475959797284],
              [0.672081453836268, 52.984375398002697],
              [0.672257238015842, 52.984061225293814],
              [0.669685170340709, 52.984537162111913],
              [0.66845712476382, 52.98576426119525],
              [0.66938212439196, 52.985928717867083],
              [0.66799777338938, 52.986095585231418],
              [0.666372479119702, 52.985374951279262],
              [0.66838034712073, 52.985533995204229],
              [0.668473365921046, 52.98532689182214],
              [0.666848097566921, 52.985283351194091],
              [0.666220661274129, 52.984700371464704],
              [0.666569224701215, 52.984505519211467],
              [0.666005246497452, 52.984226837665425],
              [0.665113226728529, 52.984742299228259],
              [0.665800437931919, 52.984578518439434],
              [0.666012322579807, 52.984801258860912],
              [0.665157954931193, 52.98595249942521],
              [0.663873509977158, 52.984668489703964],
              [0.663376420900256, 52.982934312950313],
              [0.663610607998226, 52.982236690446506],
              [0.662980916892266, 52.982511568043513],
              [0.663533898295018, 52.985081635355925],
              [0.664874848086634, 52.986436316389657],
              [0.663059029716216, 52.986420375479916],
              [0.66268972128524, 52.985600501718842],
              [0.663001923002025, 52.985322850723442],
              [0.662694364702477, 52.985336936354109],
              [0.662260548664809, 52.986083988398704],
              [0.663306955182561, 52.986986700905156],
              [0.665586614904279, 52.987033596842394],
              [0.672860344221376, 52.988060733764897],
              [0.678419852243714, 52.988374357235799],
              [0.696254731505597, 52.987585268116625],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000147",
        LAD13CDO: "33UF",
        LAD13NM: "North Norfolk",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [1.51591669586247, 52.682167152243892],
              [1.51358996357814, 52.681648002925613],
              [1.511809212752411, 52.681845637458856],
              [1.51045452044019, 52.682668297265913],
              [1.509569687639601, 52.683647903492449],
              [1.509332815946453, 52.684956372663002],
              [1.509978843279739, 52.68612714600372],
              [1.511584630655264, 52.687563565318179],
              [1.512838473832247, 52.688523823221772],
              [1.512985420074194, 52.689081596400293],
              [1.518564541718359, 52.686915652463057],
              [1.51693923689486, 52.684466265985165],
              [1.517093911444401, 52.682893094337977],
              [1.51591669586247, 52.682167152243892],
            ],
          ],
          [
            [
              [1.482290207815864, 52.693324129811579],
              [1.481671957704097, 52.693163327094268],
              [1.482355272819792, 52.693936509313787],
              [1.482290207815864, 52.693324129811579],
            ],
          ],
          [
            [
              [1.541810729397543, 52.703829629042175],
              [1.541490289029099, 52.7037672585218],
              [1.541006102691159, 52.704297103151752],
              [1.539644665221353, 52.704544668147399],
              [1.540497738570966, 52.70483936085914],
              [1.541020499221241, 52.704474757431427],
              [1.541810729397543, 52.703829629042175],
            ],
          ],
          [
            [
              [0.864121932819426, 52.961343099211284],
              [0.865803798078835, 52.960450963149491],
              [0.866767071991185, 52.960492463718928],
              [0.867125075439295, 52.960973903830777],
              [0.869994644746312, 52.960454989944125],
              [0.870701948603977, 52.960409122871354],
              [0.870468764417409, 52.960620682161412],
              [0.871320928222863, 52.96024399034382],
              [0.871124452489308, 52.959414252410646],
              [0.870312894955608, 52.958830481556461],
              [0.870773413488754, 52.95834186027308],
              [0.869878157969148, 52.958269973880697],
              [0.868929385424849, 52.957659834436768],
              [0.863811292099229, 52.957996460019707],
              [0.863737226957502, 52.958205068704828],
              [0.862989430898557, 52.957975806869065],
              [0.861718426837817, 52.958144017328948],
              [0.860254697664392, 52.957603765880144],
              [0.857288509682703, 52.957627495674323],
              [0.857593877553911, 52.958983380928579],
              [0.861681841962133, 52.959596255793322],
              [0.861288280197587, 52.959815257211964],
              [0.862968693757687, 52.961221628098698],
              [0.864121932819426, 52.961343099211284],
            ],
          ],
          [
            [
              [0.997939808922198, 52.958810706335626],
              [0.997591850938085, 52.958065009605484],
              [0.998811402523628, 52.958107063914639],
              [0.998286386067818, 52.957514217372186],
              [0.99840898544476, 52.956252157988104],
              [0.996016743731062, 52.956477991499249],
              [0.993977115167113, 52.957389116409466],
              [0.992043763400333, 52.95768423355279],
              [0.994032277202092, 52.957670093261591],
              [0.994404411055639, 52.958120233241885],
              [0.995100315759297, 52.958148548648978],
              [0.995064002123577, 52.958850886657828],
              [0.996338259803813, 52.95884392631524],
              [0.997939808922198, 52.958810706335626],
            ],
          ],
          [
            [
              [0.952162960741949, 52.963490470825263],
              [0.95397359726622, 52.96287353303557],
              [0.961783065108349, 52.96156039138102],
              [0.963289150627234, 52.960609186967559],
              [0.963347909899725, 52.960118527908918],
              [0.962391648198413, 52.959658584315008],
              [0.960919777064579, 52.959912004785913],
              [0.960646399058627, 52.959267761305469],
              [0.960116396311804, 52.959204534817736],
              [0.959746638528335, 52.959612117663504],
              [0.960055506847725, 52.96034000823505],
              [0.95913689767143, 52.960670441343716],
              [0.956812913602443, 52.960331747244467],
              [0.954872194198963, 52.959694814629884],
              [0.953889794371588, 52.959898201053392],
              [0.949500463798173, 52.958992027012229],
              [0.946182711315778, 52.959037499357088],
              [0.946231584483628, 52.960500263264258],
              [0.947339364265442, 52.961131929219867],
              [0.946377629997787, 52.961224132366254],
              [0.946462763899189, 52.961971993472829],
              [0.945660989904779, 52.962533226863322],
              [0.945839992764551, 52.96369780975575],
              [0.946751551724953, 52.963983641246948],
              [0.947343411918372, 52.9635984666754],
              [0.950155921799485, 52.964001650951758],
              [0.952162960741949, 52.963490470825263],
            ],
          ],
          [
            [
              [1.002017582229452, 52.964079891338841],
              [1.002324972819679, 52.963701626621976],
              [1.003064024077006, 52.96372790644925],
              [1.004792904910088, 52.962994449202341],
              [1.006353557165436, 52.963185168674968],
              [1.009041816136479, 52.962778073941827],
              [1.010746018857363, 52.962869777189063],
              [1.011409670031951, 52.962432997407141],
              [1.012565830204191, 52.962867698314426],
              [1.013641089441375, 52.962738804414357],
              [1.013652949008343, 52.963208818078826],
              [1.014474421883492, 52.96349911743917],
              [1.014561114223911, 52.962626430390017],
              [1.013077675212096, 52.961747722309156],
              [1.013039701036138, 52.96120193368683],
              [1.014117204370574, 52.960335582060424],
              [1.013949681535645, 52.959447763856069],
              [1.014658360078297, 52.959552078162304],
              [1.015110620099396, 52.959887713856126],
              [1.014398615222558, 52.959928268038361],
              [1.013876444398201, 52.961198689590447],
              [1.015008109555061, 52.960297682555364],
              [1.014185576812296, 52.961767272765542],
              [1.014989246570078, 52.962358374133508],
              [1.01545196598578, 52.96233044080175],
              [1.015734024876148, 52.9593233356835],
              [1.014933740172001, 52.959528901409286],
              [1.014358121244381, 52.959025535845832],
              [1.015767530879364, 52.959120150538361],
              [1.016828501639671, 52.956435880097992],
              [1.015155338638754, 52.955759856346944],
              [1.013888487790482, 52.957241624530759],
              [1.013612521732148, 52.957084961149498],
              [1.012561282222404, 52.957385905735755],
              [1.013239141120788, 52.957925354157894],
              [1.01306005008351, 52.958647509849762],
              [1.011859582260709, 52.958430660722016],
              [1.012266593537876, 52.958994068695468],
              [1.011998839713221, 52.959255350600863],
              [1.010363375777564, 52.959464976143543],
              [1.009835987401841, 52.960061963407021],
              [1.00782506768297, 52.960462718276943],
              [1.006592847857557, 52.96036802006175],
              [1.009738181586976, 52.959940343655163],
              [1.010100694561326, 52.959370916289586],
              [1.011957660040056, 52.959048665121571],
              [1.011780327472986, 52.95825461638853],
              [1.012594139891751, 52.958329298841093],
              [1.012984967157639, 52.958081979375493],
              [1.012190232246406, 52.957660599448594],
              [1.011214424374416, 52.958104400080707],
              [1.010303108098326, 52.95750521153137],
              [1.010002002101067, 52.957715177035205],
              [1.010308459675825, 52.958782029096646],
              [1.007905436612531, 52.958875283884737],
              [1.007829140842607, 52.95655442152615],
              [1.006888335580051, 52.956044082013136],
              [1.005631540541426, 52.956601962257068],
              [1.004796247712989, 52.957762462246798],
              [1.003688718516058, 52.957274300655406],
              [1.002543234104441, 52.958085621586399],
              [1.001428899890296, 52.957778362569094],
              [1.001081395600107, 52.95727636363911],
              [1.000257432309956, 52.957548973657389],
              [0.999924426946376, 52.958180572760646],
              [0.997826932186904, 52.958148103045467],
              [0.998157610324196, 52.958880746425081],
              [0.996906765319225, 52.958881739987419],
              [0.998225897818954, 52.960641572049994],
              [0.996514748986528, 52.958941090123112],
              [0.994926451625006, 52.958993740352312],
              [0.994136195948468, 52.959431819981184],
              [0.99458680896733, 52.959745991475991],
              [0.992378400011106, 52.960135275531229],
              [0.991920664610941, 52.960450756118526],
              [0.992482418466484, 52.960950980118163],
              [0.991918258322209, 52.960566821066777],
              [0.990339239361994, 52.960452812703657],
              [0.990154601807275, 52.960863026455506],
              [0.989450357926542, 52.960951795012207],
              [0.989773923242365, 52.96182042714652],
              [0.988922977012892, 52.96204957215334],
              [0.989228069526813, 52.962413287733597],
              [0.988745012156028, 52.962664646199471],
              [0.989369159750742, 52.963198005379311],
              [0.990213799099266, 52.963187016956681],
              [0.991047460895265, 52.962707405180566],
              [0.991313601725542, 52.962054136382498],
              [0.991678524975723, 52.96283089705922],
              [0.991352412018036, 52.962508183550426],
              [0.990752243259992, 52.963090728770254],
              [0.989564839898698, 52.963365218978794],
              [0.989265811197839, 52.963199349217945],
              [0.988460675681757, 52.962752733659897],
              [0.987544725676261, 52.962709230530358],
              [0.986623173917544, 52.963405050400389],
              [0.987255919572341, 52.963077995705362],
              [0.987105559985819, 52.963553887068812],
              [0.987914106380392, 52.963961596964481],
              [0.987927244643236, 52.963267039940334],
              [0.988428034269386, 52.963941473814401],
              [0.990205025003195, 52.963685426604272],
              [0.99244523584933, 52.964012092916185],
              [0.992999029877523, 52.963749944359527],
              [0.993165199197366, 52.963956183523564],
              [0.994948420524511, 52.963725986464702],
              [0.994483630526689, 52.963358217913424],
              [0.995635388854479, 52.963494639201919],
              [0.995657813649897, 52.963129873766796],
              [0.997026873578802, 52.962919981555494],
              [0.998994650976372, 52.963269584841363],
              [0.996974546663349, 52.963627220376715],
              [0.997606171193185, 52.9636292668892],
              [0.998086809800948, 52.964096441274314],
              [0.998640144914186, 52.963871147254125],
              [0.998297458177661, 52.964191841166425],
              [0.998612440426237, 52.964331368283091],
              [1.002017582229452, 52.964079891338841],
            ],
          ],
          [
            [
              [0.868574563763592, 52.967861186689213],
              [0.867855248747027, 52.967457714607576],
              [0.869850874026598, 52.967521923669054],
              [0.872113910289777, 52.968024753564471],
              [0.875304153050426, 52.967259665955432],
              [0.875447511370902, 52.966143845976063],
              [0.874493939679487, 52.964881922036838],
              [0.875071059084621, 52.964466897734923],
              [0.874941563531973, 52.963952074174621],
              [0.873768773497431, 52.963245780195194],
              [0.872432334007842, 52.961643301294728],
              [0.872719030577746, 52.960622034386731],
              [0.872387865607253, 52.960344087190784],
              [0.870501485757684, 52.960957103272442],
              [0.869388325861155, 52.960788865121522],
              [0.867988981393126, 52.961043870734244],
              [0.867208543859473, 52.961739832214249],
              [0.865817507393141, 52.960838200777943],
              [0.864371135932613, 52.961823573735813],
              [0.864690130958215, 52.962683638235134],
              [0.863724714913865, 52.963736534693879],
              [0.864295709542067, 52.964713719151995],
              [0.865589169662937, 52.96492711418874],
              [0.866040514356086, 52.9646131848422],
              [0.866612196722154, 52.96503731552675],
              [0.867465782568555, 52.964860247707911],
              [0.866804128473418, 52.965169366697992],
              [0.866132498904849, 52.965057882875783],
              [0.866060369303109, 52.964778164148932],
              [0.865496846456487, 52.965084908086013],
              [0.86459421723615, 52.964858490871421],
              [0.864160465022462, 52.966271748256702],
              [0.866016690412184, 52.966931075464409],
              [0.865685789116836, 52.967017291459875],
              [0.865839194794888, 52.967849874390296],
              [0.868319023238889, 52.96816320390792],
              [0.868574563763592, 52.967861186689213],
            ],
          ],
          [
            [
              [1.026055912576639, 52.96550674023689],
              [1.025460735632442, 52.964619938517806],
              [1.023557761856681, 52.964669294638519],
              [1.024089963608847, 52.965486666896972],
              [1.02563272751922, 52.965504031806304],
              [1.026055912576639, 52.96550674023689],
            ],
          ],
          [
            [
              [1.028309644660298, 52.965084199364583],
              [1.027292511376029, 52.964830455818202],
              [1.026157495081481, 52.965276638015787],
              [1.02665662531616, 52.965833155576796],
              [1.027940099528206, 52.965741991405373],
              [1.028309644660298, 52.965084199364583],
            ],
          ],
          [
            [
              [0.89738854567761, 52.969494510006754],
              [0.900874917837974, 52.969330292006674],
              [0.897583579272514, 52.969292820118945],
              [0.897563002241226, 52.968495699227063],
              [0.896752268759829, 52.968065853705845],
              [0.896560024998602, 52.967329570642754],
              [0.89752580584641, 52.966226033849694],
              [0.899478796891293, 52.96652098917879],
              [0.898494457282192, 52.966345380931827],
              [0.898171102418858, 52.965786751192681],
              [0.898768770786131, 52.965214640801491],
              [0.902966980058701, 52.963988069588815],
              [0.903959301269599, 52.963194965395495],
              [0.905063341101036, 52.963046572200483],
              [0.90414794013753, 52.962587863632898],
              [0.903813135801808, 52.96197017870896],
              [0.900151672679201, 52.961045291277706],
              [0.898144141194019, 52.960999869674922],
              [0.897466255521985, 52.959615395333486],
              [0.895704371263233, 52.961060271275969],
              [0.893072280296094, 52.961162175659538],
              [0.891526532717442, 52.961876938545814],
              [0.890617093719043, 52.961594232384279],
              [0.89017666465703, 52.960930522643288],
              [0.888398517706679, 52.960461210298341],
              [0.887681102008498, 52.959484098995965],
              [0.886634797313286, 52.958956501342946],
              [0.885179119246688, 52.959838947932745],
              [0.883391670612566, 52.959183645910436],
              [0.881269947907015, 52.959412280493694],
              [0.879794308896356, 52.959212466464237],
              [0.878840745736728, 52.960213048099014],
              [0.87773298141936, 52.960014183380906],
              [0.875421997128775, 52.960600696136829],
              [0.873358029868784, 52.959681268794625],
              [0.874837883007697, 52.959002510400872],
              [0.871925681556109, 52.959055872449063],
              [0.871683516781517, 52.960030200142022],
              [0.87253241033085, 52.95994133270883],
              [0.873140353573639, 52.960193701192182],
              [0.872886588563548, 52.961554082543941],
              [0.874123961294214, 52.963212008217333],
              [0.875123983160714, 52.963559190711905],
              [0.875313267964659, 52.964166986280219],
              [0.878116169322813, 52.963741212853357],
              [0.879816559132282, 52.963063344341272],
              [0.880162428410343, 52.963359798794585],
              [0.881200057668968, 52.963174571707562],
              [0.882774628315038, 52.963560807866166],
              [0.88291278550376, 52.964092499093233],
              [0.884094972411993, 52.963975677050577],
              [0.884669350337742, 52.964325919873865],
              [0.885304164345472, 52.96404073180593],
              [0.88472353515735, 52.964490962354269],
              [0.883891558957512, 52.964141578577326],
              [0.882882395237664, 52.96428477346398],
              [0.882497636446713, 52.964098077696619],
              [0.882676370726233, 52.963740341472096],
              [0.881171452166287, 52.963393779307545],
              [0.880090936170373, 52.963583642852775],
              [0.879736138556517, 52.963264923803528],
              [0.878271921091484, 52.963933471781026],
              [0.877268650476636, 52.963874148638233],
              [0.875433385546563, 52.964383491383629],
              [0.874734164860019, 52.964821255573504],
              [0.875553747900588, 52.96579237230447],
              [0.875537137295325, 52.967224351768991],
              [0.875893056214783, 52.967470218292561],
              [0.878839785990523, 52.967703682445084],
              [0.880117272847737, 52.967428125130787],
              [0.880822349651691, 52.965421924522929],
              [0.883497731040849, 52.964937069700461],
              [0.887573325450973, 52.966913501008356],
              [0.889714778009626, 52.966953148756119],
              [0.892753523346277, 52.968853010632678],
              [0.892994594766037, 52.970097976497819],
              [0.893476114014426, 52.969658212897414],
              [0.895033398799313, 52.969914321521166],
              [0.894943815399011, 52.969621555306105],
              [0.895839694329988, 52.969498105939444],
              [0.896979529326061, 52.970037732483114],
              [0.89738854567761, 52.969494510006754],
            ],
          ],
          [
            [
              [0.88258401443472, 52.969758454940184],
              [0.885417320390345, 52.968622279069592],
              [0.885965985878128, 52.968898504285001],
              [0.88559894746975, 52.96944786018063],
              [0.886630474150501, 52.969750120142663],
              [0.887497983256631, 52.969022238725962],
              [0.887380031986369, 52.968481968179439],
              [0.888421677735032, 52.968824432095957],
              [0.888856118902559, 52.968638517150175],
              [0.888776590686616, 52.968897632243909],
              [0.889897410223651, 52.969198591718069],
              [0.890519058472006, 52.9688048891663],
              [0.891304349440616, 52.968899082034916],
              [0.891856940787762, 52.969322659461568],
              [0.891745204733982, 52.969946750992875],
              [0.892251680418779, 52.969545951310437],
              [0.892312397688662, 52.97038795569113],
              [0.892426991006092, 52.968803410641826],
              [0.889545623342084, 52.967096644960492],
              [0.887514162306138, 52.967098382993811],
              [0.883307521612845, 52.965122432760616],
              [0.881119793800427, 52.965594557309174],
              [0.880505389180243, 52.967418714535199],
              [0.879467966692513, 52.967899776909711],
              [0.875138611522762, 52.96763775315393],
              [0.871626479650381, 52.968490649088473],
              [0.871293642811301, 52.968031094333121],
              [0.869278661713031, 52.968034819075541],
              [0.868593773807938, 52.968623270116694],
              [0.865586176813471, 52.968370337479797],
              [0.865608216148318, 52.968770862252263],
              [0.869201346434428, 52.969205686022882],
              [0.868132229635433, 52.969368186250733],
              [0.865350991213506, 52.968980292764343],
              [0.865720296167477, 52.969451573039947],
              [0.865328465856798, 52.969495195353204],
              [0.864815199819719, 52.969166766279017],
              [0.864891324195659, 52.968673951184904],
              [0.864024782823439, 52.968498813977604],
              [0.864072375927359, 52.96899314186949],
              [0.863773716986896, 52.96889153528349],
              [0.863694604269071, 52.968370990255721],
              [0.86501149571572, 52.968509191696775],
              [0.865041536974181, 52.96779268044147],
              [0.863629143803024, 52.967110949548399],
              [0.863458914769088, 52.967532295408986],
              [0.864626996862476, 52.967853034439834],
              [0.863236109204316, 52.967879372964724],
              [0.862802254948869, 52.965642655385587],
              [0.86024987811306, 52.964363388945181],
              [0.859348336951737, 52.964513682902712],
              [0.858941259814831, 52.964032501998524],
              [0.857421295939108, 52.964087058474604],
              [0.858686802540322, 52.963832702497193],
              [0.859154936393345, 52.96388528100929],
              [0.859268333061908, 52.964271917640581],
              [0.860651619293621, 52.964222427534409],
              [0.863446820126956, 52.965772795034695],
              [0.863737013911234, 52.965341363191193],
              [0.863065263057161, 52.963515933435581],
              [0.86380950334876, 52.962451289566353],
              [0.86339680613326, 52.961975654381753],
              [0.861001612022056, 52.961494729162588],
              [0.859471032796003, 52.960214213186745],
              [0.859323189769581, 52.960456067840774],
              [0.857400202670371, 52.960449286997481],
              [0.857696726234559, 52.960023112995863],
              [0.854543873976768, 52.9578949134578],
              [0.852983003707569, 52.957845188652293],
              [0.852293426618757, 52.958385999296119],
              [0.852774012511026, 52.959306957552812],
              [0.855200675490165, 52.959116465961401],
              [0.855877751029141, 52.959312407049239],
              [0.856038951656247, 52.959792316222178],
              [0.856672980628559, 52.959583735755153],
              [0.857053005224129, 52.960220242049836],
              [0.856603930957161, 52.961043045964622],
              [0.855742780113497, 52.960766107594907],
              [0.855873360434131, 52.960058872312381],
              [0.855282402693557, 52.960378816609435],
              [0.853950358039097, 52.960141958925192],
              [0.854630042215334, 52.962184859071876],
              [0.853927870642603, 52.962038071786644],
              [0.853434630460071, 52.962575969401065],
              [0.853868359174396, 52.963507940523073],
              [0.854684960234372, 52.963921746852272],
              [0.855246628922523, 52.963473021399302],
              [0.855260101565307, 52.964129133969081],
              [0.857096550187525, 52.964514809276181],
              [0.857564120477955, 52.964242786226301],
              [0.85758449427687, 52.964574111389751],
              [0.858999053170006, 52.965156046105754],
              [0.858895871717561, 52.964833907874166],
              [0.860425934256728, 52.965270068586278],
              [0.860702806316019, 52.964974748774637],
              [0.861219534544598, 52.965108878100558],
              [0.86121321055421, 52.965554148805985],
              [0.861616872961871, 52.965351990374096],
              [0.862759512715665, 52.96589726812347],
              [0.863014256316059, 52.967745339498727],
              [0.861572106023778, 52.967356551038471],
              [0.861159530386428, 52.967401558708609],
              [0.861236806566948, 52.96769194682313],
              [0.859511446174098, 52.967457428029952],
              [0.858373001566439, 52.966772637784246],
              [0.85696939572276, 52.967079768327793],
              [0.855915607339014, 52.966909974925244],
              [0.855034642479691, 52.967418532450914],
              [0.8555005697078, 52.968769663266045],
              [0.860392883030411, 52.971330765578294],
              [0.863207214187527, 52.973433706956463],
              [0.865146155087591, 52.976103530691852],
              [0.865755955831533, 52.978248769948635],
              [0.866598223664003, 52.978999089218178],
              [0.868136600838992, 52.979484412707734],
              [0.871851241988644, 52.97906466114118],
              [0.874670689637913, 52.97878335644598],
              [0.879401225993466, 52.976481868368289],
              [0.879883992020276, 52.972743749053485],
              [0.880576790814168, 52.971626291708183],
              [0.881065761104131, 52.971564976326469],
              [0.881147927421768, 52.970583719160651],
              [0.881982147537887, 52.970540103722669],
              [0.881973511825259, 52.970119472193147],
              [0.88258401443472, 52.969758454940184],
            ],
          ],
          [
            [
              [0.987933866094711, 52.978815914674179],
              [0.993979200582747, 52.977731180985337],
              [1.028013252120342, 52.970429797435088],
              [1.05646382743331, 52.964089663937543],
              [1.069089079990559, 52.96181626830348],
              [1.09205982611828, 52.957033409081468],
              [1.115048361897218, 52.952814824172435],
              [1.124599813608165, 52.951237445193485],
              [1.139933520369729, 52.949289808403286],
              [1.165022894039066, 52.947737036955736],
              [1.179415978224178, 52.946624096528694],
              [1.186408575130699, 52.946354822794866],
              [1.206754986604395, 52.946013927587245],
              [1.211381955279893, 52.945427532607951],
              [1.21959526239629, 52.944920930544768],
              [1.229766904625571, 52.943525939520768],
              [1.241969368388541, 52.942401087262084],
              [1.25116517241775, 52.941766531054029],
              [1.254289095558058, 52.940942605738819],
              [1.269205707714068, 52.938932015666168],
              [1.276382866233046, 52.9370695154091],
              [1.283322286575503, 52.935750947271529],
              [1.295455504588001, 52.933936270996263],
              [1.300991265151341, 52.932747343159512],
              [1.302406657778879, 52.931808643993136],
              [1.306422517108608, 52.930894655851034],
              [1.308803072973817, 52.930045905894623],
              [1.313119565219244, 52.929291483525759],
              [1.312913092840718, 52.928926718579483],
              [1.320803564919448, 52.92641563491518],
              [1.321532851840722, 52.925872714437503],
              [1.328986584735984, 52.92359812803371],
              [1.343172084383435, 52.920020263660781],
              [1.350194967251765, 52.91637505168076],
              [1.352309091801291, 52.914718116339593],
              [1.368352451074851, 52.908687908706135],
              [1.389040510442568, 52.900041586710294],
              [1.406479286267562, 52.893845702387466],
              [1.413873654494903, 52.890675179723317],
              [1.433122418305865, 52.881474141930376],
              [1.440568336068799, 52.877315591206752],
              [1.446453682180377, 52.872633493165971],
              [1.45843122074451, 52.865746425620451],
              [1.466981579870488, 52.860313687680943],
              [1.497291988351277, 52.844571311447872],
              [1.518159277112558, 52.835404423082053],
              [1.524017614989893, 52.832391061808764],
              [1.529550405440369, 52.828870832876497],
              [1.54104159829651, 52.822495003489351],
              [1.563374951699084, 52.811128274959742],
              [1.573411390283994, 52.806002604616218],
              [1.580766488801013, 52.802748721094545],
              [1.584230256282801, 52.801612404264766],
              [1.586690869104865, 52.801631516814119],
              [1.586453996973798, 52.800629533814309],
              [1.587144223106821, 52.799676848135618],
              [1.58867318968654, 52.79880040230325],
              [1.591278416611347, 52.798433684101354],
              [1.591537998594831, 52.79693907397467],
              [1.592497739424197, 52.796071727750984],
              [1.5946096391087, 52.795249486264211],
              [1.596662323546737, 52.795130553553555],
              [1.597174859342709, 52.793539208878869],
              [1.598804613813289, 52.792468889293403],
              [1.600559170287295, 52.792047737684314],
              [1.602205304177881, 52.79205618263132],
              [1.602228704200608, 52.790796275379257],
              [1.602995420794608, 52.789967084698958],
              [1.608618704902413, 52.787337871280123],
              [1.610036531510032, 52.786204586280917],
              [1.612324489796128, 52.785475591625968],
              [1.613902577366942, 52.784219547944026],
              [1.61596057729114, 52.783510084671455],
              [1.616971724166983, 52.782556413710985],
              [1.619128151177364, 52.78149760852385],
              [1.629463014928721, 52.774769480357435],
              [1.640018032678999, 52.768513041051037],
              [1.642060327245194, 52.766440040926916],
              [1.647433471017837, 52.763489198501972],
              [1.658622099936146, 52.755587119283653],
              [1.669363241299771, 52.74648262951159],
              [1.675364036248339, 52.742675424041543],
              [1.667572568139276, 52.74124393140066],
              [1.663376336141035, 52.739294252746816],
              [1.651349788164906, 52.737852287905504],
              [1.645812645087484, 52.736600816987888],
              [1.638117039178476, 52.733698370892334],
              [1.637181124060759, 52.73272693445923],
              [1.637127313512686, 52.731014253026359],
              [1.634685317012664, 52.731046937405011],
              [1.632922446532946, 52.727062533796968],
              [1.62662521934818, 52.724930462125194],
              [1.626565556523467, 52.724354849446179],
              [1.627575421431698, 52.723457765236816],
              [1.623699995651114, 52.720386088605018],
              [1.619433701444922, 52.717792145074917],
              [1.613186154000767, 52.715788281312243],
              [1.611219772814443, 52.717854894124137],
              [1.611285403513621, 52.718576938138206],
              [1.608116890153326, 52.719363371641244],
              [1.607471356735698, 52.720765464867064],
              [1.607144434146143, 52.720734947225623],
              [1.607855532666057, 52.71915816740308],
              [1.61091953056976, 52.718451379193837],
              [1.612276634002635, 52.715715288314939],
              [1.605605069925679, 52.715582193715036],
              [1.599282219756696, 52.714737468715633],
              [1.597513188309452, 52.714262350861262],
              [1.597237737938861, 52.714460497512384],
              [1.596221231039995, 52.713896843350717],
              [1.595691154001527, 52.714060444423559],
              [1.595807720655295, 52.713767288535585],
              [1.584166620193565, 52.711347213067249],
              [1.580507297337282, 52.709614072749801],
              [1.580171503194788, 52.710070340313187],
              [1.580361003950259, 52.709545641875231],
              [1.578622613727312, 52.708101536381506],
              [1.577536371678039, 52.705740979062988],
              [1.576330184849255, 52.70430145061183],
              [1.572747201269725, 52.7023679010968],
              [1.570599291557234, 52.701649282393461],
              [1.567876602615702, 52.701415645138354],
              [1.563481936422201, 52.702284594984917],
              [1.558892223563599, 52.70201517519952],
              [1.554575393455696, 52.701095200492141],
              [1.549304916969769, 52.698912987932665],
              [1.548927930446894, 52.700068348968863],
              [1.545203611540863, 52.702048894473997],
              [1.545275424389049, 52.703463329756701],
              [1.544915109753827, 52.70345702672433],
              [1.544822056714555, 52.702711495488821],
              [1.541873677931423, 52.703985144605078],
              [1.541940988430722, 52.704431941145756],
              [1.541500260314183, 52.70458453129929],
              [1.541595709304543, 52.70420931790234],
              [1.540460611517102, 52.705568999064113],
              [1.540206431507485, 52.705374228529372],
              [1.539610184107726, 52.70578239767017],
              [1.539944814522008, 52.7060746001399],
              [1.539432542577002, 52.705965791447802],
              [1.53821901405381, 52.707126323971089],
              [1.53836589263521, 52.707422341055697],
              [1.534827470853509, 52.707687200729367],
              [1.537230680003332, 52.707209811771264],
              [1.536885983777166, 52.706939488379504],
              [1.538502505965954, 52.706329964901585],
              [1.539722357480088, 52.705339229907764],
              [1.539894367259768, 52.704940143769718],
              [1.539318944317599, 52.704508532650856],
              [1.540128515877072, 52.704174935724744],
              [1.539029982758915, 52.70353680313297],
              [1.540555379369597, 52.704180163293913],
              [1.541321381949647, 52.703656284500454],
              [1.542463372994012, 52.703642816930895],
              [1.5446501914904, 52.702600614700565],
              [1.545209422898748, 52.701755509853697],
              [1.548778200392183, 52.699974799202032],
              [1.548985025548285, 52.698711211389337],
              [1.546226146521515, 52.696878095941095],
              [1.544596472307158, 52.694800659399831],
              [1.544432256853563, 52.693578766279749],
              [1.544882525442518, 52.692450007260057],
              [1.548958694787969, 52.688650178623348],
              [1.549501432236008, 52.686328692881766],
              [1.549069539961112, 52.686222915287452],
              [1.549477409944308, 52.686087468542091],
              [1.549418447923036, 52.685397580667995],
              [1.548805138150938, 52.685335915495251],
              [1.549434238260926, 52.685136274991898],
              [1.548805613169986, 52.681676153768166],
              [1.545675756526834, 52.680035837664882],
              [1.543273073586255, 52.679879312221487],
              [1.540302631292901, 52.681136515295712],
              [1.538478329333839, 52.681507644644164],
              [1.537445021131462, 52.683095414971092],
              [1.535647333967264, 52.683778701700248],
              [1.532811371942268, 52.683427133195323],
              [1.530280095501947, 52.681880981516294],
              [1.526378072730992, 52.682365119255735],
              [1.524700586040693, 52.683384637008075],
              [1.52381024858088, 52.685228855487317],
              [1.522915784750553, 52.68590215054693],
              [1.513481062360166, 52.689410466573207],
              [1.51453288458616, 52.69047113976319],
              [1.513921269336122, 52.69190406604703],
              [1.514950518456997, 52.69334585731287],
              [1.514893016322412, 52.69710440799291],
              [1.510920830560364, 52.698871758383639],
              [1.509776634828523, 52.698893082781339],
              [1.508912362539016, 52.700501655756973],
              [1.505677248112672, 52.702586058865514],
              [1.505302395104394, 52.703671947889802],
              [1.505648903990601, 52.704669029294678],
              [1.504747164354692, 52.705462913155408],
              [1.501425973208794, 52.705574640183414],
              [1.498123760217563, 52.704499395668464],
              [1.495584419303934, 52.705740002646259],
              [1.495018353518842, 52.707752490150206],
              [1.496861860159617, 52.710397192197966],
              [1.49818932639386, 52.711199836282276],
              [1.500511743716436, 52.711943384132681],
              [1.503880323138426, 52.711973292817184],
              [1.503335642729807, 52.712069452306196],
              [1.503909170124656, 52.712443728977171],
              [1.504039671439474, 52.712944435847973],
              [1.50606334084429, 52.713930560846372],
              [1.506979244588941, 52.714842427443671],
              [1.507241673460855, 52.714679958749784],
              [1.508070730944393, 52.715561110771148],
              [1.507706519262787, 52.717904961940512],
              [1.506422259541552, 52.719766985609034],
              [1.506620409871751, 52.720740574405276],
              [1.506154136821466, 52.721353382295796],
              [1.506554223673241, 52.721890177547266],
              [1.505856420882672, 52.722663636370406],
              [1.5060737145141, 52.723635759800686],
              [1.505035249467497, 52.72441030166268],
              [1.505110249583393, 52.725049359362494],
              [1.506538675824426, 52.725810253976839],
              [1.504722578842923, 52.725036545932745],
              [1.504871740284102, 52.724052678838923],
              [1.505754981416581, 52.723485098982941],
              [1.505406815582243, 52.722598694087878],
              [1.506168524051454, 52.721938470535413],
              [1.505806574870212, 52.721396947653879],
              [1.506280171738002, 52.720746149301888],
              [1.506135193013822, 52.719715222892965],
              [1.507228051063055, 52.71803335514965],
              [1.507711970577484, 52.715722836499594],
              [1.50598270546416, 52.714272923915402],
              [1.503815443130434, 52.713066193458353],
              [1.503157212818255, 52.712226729746845],
              [1.500523553560533, 52.712183176680838],
              [1.497919321109839, 52.71134182220392],
              [1.496582488154275, 52.710533155792888],
              [1.494738908269577, 52.707924427952854],
              [1.494793267152538, 52.706530543951644],
              [1.495598935688145, 52.705403196329378],
              [1.498067314648442, 52.704312187448153],
              [1.501804880504148, 52.705461804327307],
              [1.504618233837591, 52.705276953303745],
              [1.505376275117195, 52.704648317841119],
              [1.505004752881617, 52.703819266001879],
              [1.505445168707644, 52.702498490556565],
              [1.508636770663092, 52.700351524883814],
              [1.509541649165139, 52.698566365616763],
              [1.510718622509858, 52.698695172675045],
              [1.514524079422507, 52.69715583131994],
              [1.51468913052134, 52.693390491558176],
              [1.513671912547052, 52.691877287582351],
              [1.514216036801457, 52.690414886207037],
              [1.513940286396391, 52.689985955012034],
              [1.51304838448828, 52.689699423335647],
              [1.511183255758527, 52.690361844920126],
              [1.509136515389163, 52.690366752462687],
              [1.508919983451171, 52.692002901447239],
              [1.508830261424815, 52.690405508184853],
              [1.507171018826723, 52.689926708394367],
              [1.505659624701845, 52.689057661819653],
              [1.504455305800053, 52.68917515587956],
              [1.502725600275899, 52.690457624957595],
              [1.501528200144655, 52.690495735956738],
              [1.500060928827709, 52.69001026122686],
              [1.499714417122828, 52.68797434788933],
              [1.499060499746604, 52.687497199034141],
              [1.497163325158941, 52.687075658792395],
              [1.494436446987964, 52.687383712104676],
              [1.491031212662371, 52.688205477207191],
              [1.489609465846367, 52.688974103948361],
              [1.489414435187109, 52.68997999068538],
              [1.490065483744978, 52.691961083354869],
              [1.489742608591667, 52.693071471471661],
              [1.489062397116428, 52.693414401350118],
              [1.484894035174919, 52.693472384167166],
              [1.48468138104844, 52.694277313062024],
              [1.484440147306656, 52.693535207529663],
              [1.483611806654147, 52.694119007172525],
              [1.483156087176036, 52.693861691551831],
              [1.483873137631567, 52.693627439872195],
              [1.482547775798754, 52.693398401252473],
              [1.482804857911948, 52.693912494960088],
              [1.482216477774034, 52.694294056360505],
              [1.48181297405137, 52.693446990213616],
              [1.482029174179992, 52.694116984934752],
              [1.481158818204294, 52.694601269021433],
              [1.481840396256768, 52.694051482630776],
              [1.481504598585491, 52.693069313059389],
              [1.48032777520833, 52.692195507622763],
              [1.476605117673928, 52.689759547142536],
              [1.475077601221428, 52.689350185176259],
              [1.474033621226433, 52.689437479562443],
              [1.472243019346833, 52.690598059956443],
              [1.472166548008626, 52.693325488473135],
              [1.471489675751056, 52.693842701396051],
              [1.468769090057741, 52.694106799380442],
              [1.467780004215447, 52.694738364855247],
              [1.46760662017853, 52.695314560856794],
              [1.468855614077342, 52.697659593906565],
              [1.465499344417586, 52.699394760968431],
              [1.462898418509009, 52.704126125430705],
              [1.461076724337783, 52.705952087516863],
              [1.460048864733179, 52.705612467792271],
              [1.457759542025059, 52.703645790717168],
              [1.456327978845755, 52.70289970587605],
              [1.451232506676155, 52.703284840248322],
              [1.445933977461173, 52.702729499952646],
              [1.441889891433515, 52.70183887617646],
              [1.440887126684697, 52.701363454570803],
              [1.440681962702105, 52.700600434399405],
              [1.441572481297066, 52.698014887583369],
              [1.441786446559149, 52.695054162583496],
              [1.438684402517171, 52.693689957186749],
              [1.438322380536708, 52.693058302142809],
              [1.438814702913451, 52.691883790207065],
              [1.438484626324383, 52.691262900153262],
              [1.433919467964504, 52.689658638648396],
              [1.431631348948822, 52.690340149929547],
              [1.429669160938046, 52.6915922841781],
              [1.428612844432639, 52.691523938463554],
              [1.42581672558283, 52.690560667150045],
              [1.423660530062183, 52.690774120714742],
              [1.422720101265159, 52.691126883937898],
              [1.422498091010089, 52.691788954890882],
              [1.418780319505152, 52.6920231544755],
              [1.416459957678931, 52.694001315490993],
              [1.416638202973699, 52.694520516002726],
              [1.418608637953054, 52.694862980919076],
              [1.419832694699883, 52.696866552710944],
              [1.423321087757587, 52.698745323694382],
              [1.424456614523371, 52.701277551891508],
              [1.423936265150436, 52.701779165285942],
              [1.423932880527091, 52.703493497088836],
              [1.42196110897741, 52.704633345377765],
              [1.419072369207495, 52.704804012223498],
              [1.417240970207214, 52.705942425299142],
              [1.414276630102492, 52.706662880609095],
              [1.413740220561681, 52.707605608354221],
              [1.41202306257395, 52.707972570014903],
              [1.410621634541652, 52.708723446285632],
              [1.408616393925208, 52.709024003675992],
              [1.408116620173401, 52.712640415830251],
              [1.403836579110932, 52.715375291921411],
              [1.402421782231318, 52.715674061082737],
              [1.403744962595854, 52.718514068780635],
              [1.403179591748268, 52.718671515687376],
              [1.405266336768593, 52.72140502678635],
              [1.407323605528805, 52.720947444130381],
              [1.407537671641341, 52.722210314986995],
              [1.40854315224457, 52.722756992737054],
              [1.409680506095493, 52.725637361724331],
              [1.4066348813553, 52.72588507226294],
              [1.401348099239886, 52.726991225113423],
              [1.400342296580753, 52.727372655704492],
              [1.399725728763711, 52.728091867440135],
              [1.399838496867283, 52.728569799366973],
              [1.404799990513666, 52.728817640811862],
              [1.405878862726707, 52.732487581635723],
              [1.401018805615124, 52.730808653636053],
              [1.397309294879714, 52.729079453841486],
              [1.396543203965555, 52.731019794945524],
              [1.394815281214123, 52.73105223291023],
              [1.392491258060553, 52.73245078284193],
              [1.392123932573746, 52.734644988501728],
              [1.390783598319322, 52.736884130934541],
              [1.39068266158713, 52.738503199112998],
              [1.388806207398417, 52.739088415496546],
              [1.387638148075242, 52.739980721988047],
              [1.386509389619727, 52.739336659563435],
              [1.385002140570097, 52.739352749407161],
              [1.38480431566276, 52.739648899082866],
              [1.3798514593882, 52.741191511466539],
              [1.374178560913305, 52.742069996907077],
              [1.371523553865297, 52.743228417315024],
              [1.364579237312601, 52.744421411909805],
              [1.363601752494681, 52.744775634899334],
              [1.364030861229916, 52.745593570679844],
              [1.357634923553428, 52.746370362157428],
              [1.357029071893695, 52.746220245771561],
              [1.356616460544208, 52.747522526545723],
              [1.357151877542514, 52.748798849646015],
              [1.356965876566854, 52.751293566653409],
              [1.348704842055992, 52.752888043955132],
              [1.348311237599434, 52.753244522016921],
              [1.345014282712575, 52.755772209488889],
              [1.341768574026931, 52.759883030993969],
              [1.339180287361064, 52.763893391301572],
              [1.33776633771542, 52.768335590845339],
              [1.336218366891893, 52.769382860222954],
              [1.333931665222016, 52.768042406275129],
              [1.327598834719678, 52.766858748837002],
              [1.326574255249796, 52.767052992704301],
              [1.326982939301762, 52.768911285821595],
              [1.324563022851728, 52.770603428170539],
              [1.324255726138875, 52.771240697695745],
              [1.32335918459433, 52.771345882241725],
              [1.321378421632141, 52.77329724702728],
              [1.320830310639506, 52.773382754669612],
              [1.321287431653616, 52.774458166849584],
              [1.320868413333856, 52.775109349147833],
              [1.320072512852803, 52.775349289344817],
              [1.31950553217752, 52.776667435443422],
              [1.318654417844418, 52.777351394419163],
              [1.317041126901261, 52.77731650957157],
              [1.316859238384386, 52.778453886384476],
              [1.316336005067704, 52.778652894110991],
              [1.316108382368012, 52.779462385330518],
              [1.315361322926246, 52.779370863309481],
              [1.314789335574206, 52.779728614443833],
              [1.315584593208755, 52.780629109619433],
              [1.314546853555615, 52.783112466650493],
              [1.314543019093787, 52.785519242450242],
              [1.315903619252624, 52.788662202762914],
              [1.317073188978763, 52.789137616888375],
              [1.313781616625201, 52.790448295625332],
              [1.314828231759482, 52.793265484882923],
              [1.312481314445245, 52.793638662267917],
              [1.30927106447235, 52.79491276169145],
              [1.308345626004272, 52.795679655160029],
              [1.308908109842374, 52.796287207177997],
              [1.311367029974326, 52.796018896603989],
              [1.314919656957931, 52.800861551658869],
              [1.312593243570701, 52.801529139863788],
              [1.308095801743476, 52.802001861583001],
              [1.307555124116124, 52.802952269628243],
              [1.308568960484324, 52.803698322303866],
              [1.308225486263839, 52.804001993838945],
              [1.308879321416273, 52.804365072709366],
              [1.30886415881811, 52.805239663582171],
              [1.308265998685912, 52.806703408095615],
              [1.306571253882296, 52.80732986320799],
              [1.305837567349109, 52.806540907888646],
              [1.303215138716549, 52.805425057901601],
              [1.302601701687262, 52.80463543131291],
              [1.299612994021361, 52.804715045506065],
              [1.300656714023209, 52.80624816133534],
              [1.299810815740865, 52.806346352028655],
              [1.299769395812374, 52.807190192977487],
              [1.299283136389485, 52.807255886695138],
              [1.298799010596218, 52.806938397296442],
              [1.296616107829212, 52.806784165787796],
              [1.29639695726931, 52.805967356862631],
              [1.295085333030698, 52.805931867445508],
              [1.29463140114838, 52.80560093153268],
              [1.291951318859519, 52.805229297309559],
              [1.292004299176984, 52.805007486716001],
              [1.288861620601897, 52.804640532219672],
              [1.286627816505346, 52.8088628382379],
              [1.28341375342343, 52.807690105233853],
              [1.281298255322075, 52.809296436109435],
              [1.27765190974673, 52.814878770505608],
              [1.276540705427764, 52.817410545840566],
              [1.27569198904837, 52.817297293848718],
              [1.273867709465154, 52.818127400618664],
              [1.274104796038929, 52.818792661589029],
              [1.270368915673229, 52.819294156812383],
              [1.267449798452756, 52.819029278918542],
              [1.262736129815969, 52.815340478413098],
              [1.261938884248347, 52.812644614850747],
              [1.260473971225482, 52.813121082075689],
              [1.259802358372397, 52.812695274628481],
              [1.258694525852722, 52.813619664921077],
              [1.257715585396812, 52.813450507886856],
              [1.256893808039179, 52.813893970802681],
              [1.254762460989017, 52.813419184710668],
              [1.25397972697218, 52.813531499361083],
              [1.252969145702633, 52.814329057380306],
              [1.252155278687024, 52.814024922431045],
              [1.252208902496536, 52.814365198969398],
              [1.251014297866877, 52.815422298102],
              [1.251326049044755, 52.815621497086838],
              [1.250655416341731, 52.817374698362919],
              [1.251089990528237, 52.817807056059202],
              [1.25068116042528, 52.8180970572391],
              [1.251292619164315, 52.818174724374217],
              [1.251540425538958, 52.818570831663699],
              [1.250136368495681, 52.819727193377936],
              [1.251191812526066, 52.820024720276649],
              [1.251062162575087, 52.820495028959925],
              [1.248885689718198, 52.82093059754127],
              [1.248151087804322, 52.820536127958363],
              [1.247522523632369, 52.821045277834578],
              [1.244435418561771, 52.819880604184817],
              [1.242693494210713, 52.820749338361829],
              [1.241176923069474, 52.820239507375803],
              [1.240058298049901, 52.8206477945579],
              [1.238201865872027, 52.820398124271833],
              [1.237560986158403, 52.820922844860505],
              [1.237969669994808, 52.821686909899341],
              [1.237474021307007, 52.822328174547977],
              [1.237844563251822, 52.822938596432365],
              [1.237579834308301, 52.823251596164965],
              [1.235765104099147, 52.823823634998533],
              [1.234118811356662, 52.823666192679646],
              [1.233208346404274, 52.822961662082179],
              [1.232428813968552, 52.82351981147017],
              [1.231208281386691, 52.823557569118705],
              [1.231956424120451, 52.823995840563768],
              [1.23006093877291, 52.824441387485777],
              [1.229662062484188, 52.824828169881862],
              [1.22828363657207, 52.824379163611205],
              [1.225205574057882, 52.825295688845323],
              [1.224305294056033, 52.825187967349876],
              [1.224418908237609, 52.825979883444937],
              [1.223447606822546, 52.82603596500968],
              [1.224286343011365, 52.826575244613025],
              [1.223722059861301, 52.827515991379634],
              [1.222096955272398, 52.82748550929454],
              [1.221472482985727, 52.828275893664141],
              [1.219321880099944, 52.828227270166593],
              [1.217826259536389, 52.828763386470932],
              [1.218087129170051, 52.829379540644716],
              [1.215402994589664, 52.828883068099898],
              [1.214449874650567, 52.829105856355127],
              [1.214368035560183, 52.829686339827916],
              [1.212794471548905, 52.828625505969995],
              [1.209869434486533, 52.827981650556886],
              [1.207821811479038, 52.828000178587629],
              [1.206640640394046, 52.826498775207952],
              [1.207879148789226, 52.826502154589434],
              [1.207243428345589, 52.826196491056471],
              [1.20778135479395, 52.825074876206649],
              [1.207421559697652, 52.82488675623425],
              [1.206386932875612, 52.825251978879926],
              [1.207089377736801, 52.824750948541954],
              [1.20657124427026, 52.824569805688164],
              [1.206846022973964, 52.824269196759161],
              [1.206107912881242, 52.823218059535655],
              [1.203946089959448, 52.822186503875848],
              [1.203741949827898, 52.821772934525598],
              [1.202964059245791, 52.815923168747382],
              [1.201949062594043, 52.815644809170315],
              [1.198134376751322, 52.815908726219831],
              [1.195932975555017, 52.815381717023421],
              [1.193367302870649, 52.815290755358298],
              [1.193252905181278, 52.816584360834575],
              [1.192749047099449, 52.816534974810267],
              [1.191285092161098, 52.818433273955911],
              [1.189655586079125, 52.819694791232642],
              [1.188129269054624, 52.820096460006134],
              [1.18516062945095, 52.819857869864286],
              [1.184701691479994, 52.820443060707078],
              [1.18107695367095, 52.820367674543824],
              [1.181992396995894, 52.821457370017868],
              [1.180980388499344, 52.821788487766923],
              [1.181064754717761, 52.82343017626026],
              [1.18048430113128, 52.823979038777566],
              [1.180817302313727, 52.827153688834649],
              [1.180239241035251, 52.827451575918211],
              [1.179624347815563, 52.827123624187124],
              [1.177397601824155, 52.827494463718537],
              [1.176086650679019, 52.827983731419003],
              [1.175519986952173, 52.828743537655825],
              [1.175623939652866, 52.829354087197039],
              [1.174803906658293, 52.829796020573689],
              [1.17454827941514, 52.830382920806464],
              [1.173843892860471, 52.830171547438205],
              [1.173492208405444, 52.830561367808357],
              [1.172837419567844, 52.83027671627493],
              [1.172576423939181, 52.830586766750784],
              [1.171616845757601, 52.83005215779643],
              [1.169745848851065, 52.83021368977667],
              [1.169554740356436, 52.829913929719361],
              [1.16818395150651, 52.829644787421138],
              [1.167217611967705, 52.829872941628466],
              [1.165991050639281, 52.826647478613225],
              [1.166357987406782, 52.826201516130276],
              [1.165817987470188, 52.825778865344482],
              [1.163958392525402, 52.826115369433111],
              [1.162826345991232, 52.82500253773339],
              [1.156570319182119, 52.821711355679412],
              [1.155402784085231, 52.821517597083727],
              [1.154181001119153, 52.821763234210067],
              [1.147457508382331, 52.823672129280396],
              [1.14685193889928, 52.824207999015343],
              [1.145670443239143, 52.824108942086809],
              [1.144965372536785, 52.823597048717687],
              [1.143699209887133, 52.820619733093103],
              [1.14703506645094, 52.820293843620185],
              [1.147220274414074, 52.818975037910633],
              [1.14826012728591, 52.818882687891033],
              [1.148286403664076, 52.815880094122605],
              [1.150449296462935, 52.815472823423114],
              [1.149580307100083, 52.812078525620954],
              [1.144386265222835, 52.812540113087671],
              [1.14362570338414, 52.813065688952115],
              [1.136074554838911, 52.813734158343834],
              [1.134718097547132, 52.814663932905852],
              [1.131480054984292, 52.815189240605278],
              [1.128315249192487, 52.81456591257583],
              [1.126851604811862, 52.815473241670617],
              [1.127012737005001, 52.815960013660721],
              [1.124360049881388, 52.818377113099643],
              [1.118758579077485, 52.819505688025068],
              [1.117261073062228, 52.821251034840074],
              [1.11442367056299, 52.822073797444261],
              [1.112406231644443, 52.824108816630037],
              [1.110390746505072, 52.824823574951033],
              [1.1066394939494, 52.826825708047991],
              [1.104514566355407, 52.828444327366654],
              [1.101769142913966, 52.829317429301405],
              [1.100093029700919, 52.828549183612658],
              [1.098388169592229, 52.827230396559756],
              [1.097514122117387, 52.825776580061302],
              [1.095562174618432, 52.824362586133624],
              [1.095364490127516, 52.823106931194019],
              [1.095829713174244, 52.822092072411053],
              [1.095253469291904, 52.822046859527376],
              [1.094808146799778, 52.820919970526916],
              [1.093884454958544, 52.820831657811105],
              [1.092923671872316, 52.81963637163048],
              [1.092206761804774, 52.819512077310797],
              [1.092753876805133, 52.818980715678357],
              [1.092383966182235, 52.818287111300293],
              [1.090471814033071, 52.81663188794667],
              [1.089838880810329, 52.816853419232345],
              [1.088245677796886, 52.816448865799472],
              [1.088478859924785, 52.816137032837972],
              [1.087881991113925, 52.815820734724404],
              [1.085364154081527, 52.815584101550797],
              [1.084688748272555, 52.815960489674033],
              [1.083113024367996, 52.816094990400899],
              [1.081665942922434, 52.81555165645527],
              [1.079916874923818, 52.815438816467356],
              [1.073367473135715, 52.81685873862223],
              [1.062229770107096, 52.815139622013639],
              [1.062005010587485, 52.814835170047417],
              [1.060384105956731, 52.815002909041823],
              [1.047577040658595, 52.813338081660007],
              [1.044322090925463, 52.811829942475036],
              [1.042716199137014, 52.811978169848977],
              [1.040162438934006, 52.811453731096677],
              [1.037494523401805, 52.811908785434149],
              [1.035243019928866, 52.811801861059237],
              [1.034597313218428, 52.811204173758519],
              [1.031962441070818, 52.810237388904319],
              [1.03120435294556, 52.808130860436322],
              [1.030462039693403, 52.807614757753342],
              [1.028666087464851, 52.807077002730125],
              [1.022111951765662, 52.807428559431457],
              [1.022568228294272, 52.806861174617865],
              [1.020739256033234, 52.806530077904661],
              [1.019980163881993, 52.804721171124825],
              [1.019287852744363, 52.804645274266043],
              [1.022260183389626, 52.800005700703537],
              [1.018890769925283, 52.799944045187857],
              [1.01692202839153, 52.800225264653669],
              [1.01705369890118, 52.800648174256274],
              [1.013561922810604, 52.801027429653146],
              [1.010361583407398, 52.801953140045995],
              [1.006507101727081, 52.801873767323507],
              [1.004406566170208, 52.802268729469311],
              [1.002432523363858, 52.802023762369977],
              [1.001029638331402, 52.80136772820326],
              [0.999479012570161, 52.801517570641956],
              [0.998196850446307, 52.800954672620556],
              [0.995513035140055, 52.800571060071867],
              [0.99500097823487, 52.800762949834201],
              [0.993496125492909, 52.799333327521929],
              [0.993575887245257, 52.798334915387279],
              [0.989969263614889, 52.798273031319333],
              [0.988517227495179, 52.799512865051391],
              [0.987943595227349, 52.800667601253089],
              [0.985974075758699, 52.801416845563942],
              [0.984905316731231, 52.801129005595904],
              [0.980685516242018, 52.801368238016678],
              [0.980928911450759, 52.804577922033886],
              [0.977124884877484, 52.804348842341746],
              [0.977106366710267, 52.804816029633805],
              [0.975444105989546, 52.804703072325651],
              [0.975741610062706, 52.806994146919905],
              [0.97378905159662, 52.807238265909554],
              [0.973838532048222, 52.807549971624653],
              [0.967643562463575, 52.807712346026754],
              [0.966530460588712, 52.803070284773227],
              [0.963281557943909, 52.803342156439555],
              [0.963163072460006, 52.802974617966044],
              [0.96034004441631, 52.803770831957287],
              [0.95796117255794, 52.804072993026239],
              [0.952761233696129, 52.804131535094044],
              [0.950765213490929, 52.804675812586041],
              [0.936825040833605, 52.795555287959232],
              [0.938553936048579, 52.791295802773064],
              [0.938423098729575, 52.787947481761037],
              [0.934956055635809, 52.789040525277095],
              [0.93300818960085, 52.789059046361963],
              [0.933239470048919, 52.789475979748559],
              [0.932730703512385, 52.78963064780973],
              [0.932204434843968, 52.789084320225236],
              [0.930510863322236, 52.789333018091263],
              [0.929749341849573, 52.791597297422761],
              [0.928337013672735, 52.791374994097545],
              [0.926964863239111, 52.792854886396654],
              [0.926755703846135, 52.795085724093063],
              [0.927206383536058, 52.795831779192518],
              [0.926214722054469, 52.796595447331924],
              [0.925137499018601, 52.79681446599038],
              [0.924746967536216, 52.797361857120848],
              [0.923953511664212, 52.797520809303457],
              [0.92483510679567, 52.798122257328153],
              [0.924430207800828, 52.798500041062972],
              [0.925336243128671, 52.798691718723724],
              [0.924817667090008, 52.799144248802136],
              [0.923971823239727, 52.799164207999667],
              [0.923783601711631, 52.80082528815057],
              [0.922101435074675, 52.801185087306145],
              [0.922019460933035, 52.801710476926402],
              [0.920136582474213, 52.8026183460616],
              [0.919672609542586, 52.802270964794346],
              [0.917996371883401, 52.802787029893956],
              [0.917627755552846, 52.803287094921693],
              [0.915633741806342, 52.802115839510485],
              [0.915056550869251, 52.801162425454109],
              [0.912859365356676, 52.800145395715887],
              [0.906872532049523, 52.79849917427007],
              [0.90450594280878, 52.797339566011928],
              [0.900498579635822, 52.796000635654408],
              [0.893059915564149, 52.794391026214207],
              [0.89184688423151, 52.793481791130496],
              [0.892115085341146, 52.793030118986245],
              [0.890770211102355, 52.790691575110785],
              [0.888553833612239, 52.791583690519097],
              [0.887741652399102, 52.792064789235994],
              [0.888314253398996, 52.79255712422885],
              [0.88738216435696, 52.792684136705823],
              [0.886697178060569, 52.792376182540707],
              [0.88559517537956, 52.792737526079108],
              [0.883598344211484, 52.793430864334141],
              [0.881880213420544, 52.793449250508331],
              [0.880575656697506, 52.793911688968649],
              [0.880180713601947, 52.796397823502843],
              [0.87783746037718, 52.797510432819159],
              [0.876108197225874, 52.797295198353332],
              [0.87414885753905, 52.798247347483596],
              [0.873300641449988, 52.797871331954703],
              [0.871720955900433, 52.798079553356004],
              [0.86996129449591, 52.801411606979933],
              [0.866819274333397, 52.804035172887815],
              [0.865401189901738, 52.807402956136471],
              [0.858406014150024, 52.807631100450301],
              [0.854865136752743, 52.807085113770903],
              [0.85301365040479, 52.807182721359581],
              [0.842020225081443, 52.809176801596493],
              [0.840436531340079, 52.809832518663832],
              [0.839425532467917, 52.811101277182935],
              [0.83767257795013, 52.809662177448338],
              [0.832520080634362, 52.806558861335844],
              [0.831400338202003, 52.805403995089613],
              [0.829782197721154, 52.804730415028381],
              [0.830392044876047, 52.802994715398228],
              [0.832727179275977, 52.801574814669593],
              [0.831910215251274, 52.799963100485471],
              [0.832722164247252, 52.79978634220992],
              [0.832057100060317, 52.798890394315215],
              [0.829127344044164, 52.797343484413616],
              [0.824378560245604, 52.797231864138951],
              [0.821434407678269, 52.796854122544936],
              [0.818057003701716, 52.794364398896533],
              [0.819275476705925, 52.792423654050097],
              [0.819942782748551, 52.789600359373345],
              [0.819307908069623, 52.786114711082192],
              [0.816048996319861, 52.786913338394406],
              [0.816059554524208, 52.786491344054355],
              [0.811572097688793, 52.786422474432364],
              [0.811130026331559, 52.784259493831016],
              [0.810586123500194, 52.78392078414408],
              [0.809631545359127, 52.783952398229999],
              [0.809117372935203, 52.783200226778099],
              [0.809409349495727, 52.782483809814224],
              [0.810572729406468, 52.782539869942859],
              [0.810883717439776, 52.781129684618918],
              [0.80910126262447, 52.780027193110534],
              [0.806752448895786, 52.781436168868694],
              [0.803024474734084, 52.781349900350534],
              [0.802726473887035, 52.779667276055413],
              [0.801009891687357, 52.77884726737598],
              [0.799747857974465, 52.77667573888565],
              [0.79996436599421, 52.774204915614561],
              [0.801873185454257, 52.770035015392772],
              [0.799085836839909, 52.769388655571241],
              [0.795812657980199, 52.76907201846236],
              [0.787942766756563, 52.769834644576328],
              [0.78026219170955, 52.769384633652166],
              [0.779755883863564, 52.771862206153223],
              [0.777377372946925, 52.771555553953945],
              [0.774500674157965, 52.775303465169571],
              [0.773088192639828, 52.775826589561696],
              [0.769830936268507, 52.779149848539248],
              [0.769458377992122, 52.778140620514591],
              [0.765824734356241, 52.776683251356246],
              [0.763523414485019, 52.776918553406482],
              [0.75999965654387, 52.776161644942214],
              [0.75857357619233, 52.776027573959468],
              [0.758275977072878, 52.776292575967162],
              [0.755921720316208, 52.775784399932306],
              [0.75286255862708, 52.775689104898468],
              [0.752074975186164, 52.776954616666984],
              [0.751818852990565, 52.778464969281202],
              [0.741490806537033, 52.779982000908994],
              [0.730307357264639, 52.780970196173563],
              [0.730346232412118, 52.781780400431877],
              [0.717815753870043, 52.782320930215342],
              [0.7178562672226, 52.782828061317986],
              [0.7183274358206, 52.782868517191531],
              [0.717885315681843, 52.784031451784635],
              [0.708370647353083, 52.786504376373827],
              [0.70792179089962, 52.788447046104729],
              [0.713715663463134, 52.789810008503437],
              [0.722545167596711, 52.79329174365369],
              [0.725686746305559, 52.795184380168408],
              [0.736167290289259, 52.799267408640475],
              [0.73106607268365, 52.801696881455676],
              [0.730921833877618, 52.80363262823343],
              [0.732209803822638, 52.803633539552436],
              [0.733769687468495, 52.805673898061251],
              [0.735423247323689, 52.806935149501506],
              [0.73843173110647, 52.808597630659705],
              [0.738950407256398, 52.8082088809035],
              [0.742654259485519, 52.810659102600894],
              [0.74608120459184, 52.813465420325237],
              [0.745281443043476, 52.813797761151314],
              [0.746665914286598, 52.815229645699098],
              [0.747992748710091, 52.814782575166262],
              [0.749406233638267, 52.815896330845831],
              [0.751170695662578, 52.814905840975705],
              [0.751881707932861, 52.81408544224351],
              [0.753352650256456, 52.81434625365717],
              [0.754551390588808, 52.815606111765192],
              [0.754528871200426, 52.816141673010613],
              [0.756319729152737, 52.816413902821758],
              [0.75620139948567, 52.816701706414115],
              [0.755007565043289, 52.81695154775597],
              [0.753927925067206, 52.817059346165664],
              [0.753409159403953, 52.816789032427643],
              [0.752213828465803, 52.817438135375617],
              [0.752418843298755, 52.818802895746806],
              [0.748883181557163, 52.819695079717583],
              [0.747274577406534, 52.820642357315208],
              [0.746643528689309, 52.821603850653176],
              [0.749068593307264, 52.822607860288642],
              [0.74498794077686, 52.825056514609521],
              [0.744046886767145, 52.826824572251951],
              [0.744494320108065, 52.826998562390415],
              [0.744287519727728, 52.827650785185561],
              [0.744756551455307, 52.828002320479207],
              [0.742478504201499, 52.829075618313603],
              [0.742462454918367, 52.829834031779669],
              [0.741061054692783, 52.831132519757517],
              [0.73940122539773, 52.830544101180124],
              [0.737441920258699, 52.831850030471507],
              [0.73637404084371, 52.833001385916177],
              [0.736154785692074, 52.835181651408071],
              [0.72967180131971, 52.839416123312368],
              [0.728110891623133, 52.841544498547137],
              [0.725067982266305, 52.842848146151319],
              [0.724437345740399, 52.845654689282767],
              [0.723509812481237, 52.84704188855325],
              [0.724898116413277, 52.847604396322609],
              [0.724416921244269, 52.850355350650823],
              [0.727033660616797, 52.853640320385423],
              [0.727758052637255, 52.855313294490301],
              [0.730912733063758, 52.854901734197924],
              [0.731506282576146, 52.857634274808177],
              [0.736819608389718, 52.857103612884373],
              [0.742596340867119, 52.855749110455683],
              [0.747489400232172, 52.855649412087544],
              [0.750542814533886, 52.856011201931771],
              [0.757840952683723, 52.855769880021285],
              [0.765263709269881, 52.856484673492744],
              [0.780370775331326, 52.858786186795534],
              [0.789646447456724, 52.859606205596378],
              [0.790953676641326, 52.859857825571204],
              [0.79017936137208, 52.861937060119516],
              [0.791356856671372, 52.862110796122714],
              [0.791126253548699, 52.865530581022441],
              [0.794719168142531, 52.866061995730078],
              [0.797771582566004, 52.864428117553111],
              [0.794371805573391, 52.880556698607165],
              [0.794150584185368, 52.882062712321598],
              [0.794856003543986, 52.883484863608437],
              [0.797161871838342, 52.885243384356038],
              [0.8018941240424, 52.882231770362722],
              [0.805448073560391, 52.880778300900765],
              [0.806880451895153, 52.87941268073736],
              [0.81104194866927, 52.882622454832749],
              [0.81377671398612, 52.885392923234399],
              [0.815035862862658, 52.888558026117856],
              [0.816480983660182, 52.888329512723949],
              [0.818271365364776, 52.892092553425087],
              [0.821553259631688, 52.892831989671393],
              [0.8211969702962, 52.895037273667768],
              [0.816913899908641, 52.89495734193796],
              [0.816928289735659, 52.896737471191393],
              [0.815217159797482, 52.896995675499419],
              [0.815978696881106, 52.898557555661256],
              [0.817097795404721, 52.898478849354156],
              [0.816974684193097, 52.900041930020272],
              [0.814573047656572, 52.899812939498794],
              [0.813228436398341, 52.89827116432766],
              [0.810795651896685, 52.89886832608758],
              [0.811716798926641, 52.900278979772239],
              [0.808731505017988, 52.900774081296952],
              [0.805155046906968, 52.900775015024898],
              [0.789882102584457, 52.899822049502475],
              [0.789098947380971, 52.902815974107511],
              [0.787861338375137, 52.907859095907526],
              [0.786421863124406, 52.913770186614364],
              [0.786441954948711, 52.915750694679929],
              [0.785055232317581, 52.922814216824889],
              [0.787245307415342, 52.922998425503984],
              [0.787516010728381, 52.92422400009869],
              [0.784879905239159, 52.924787615543636],
              [0.784958606317482, 52.926013201700798],
              [0.78408048938074, 52.926056272954362],
              [0.782850087799051, 52.935866789646973],
              [0.778429994936082, 52.951613007471046],
              [0.781065466749284, 52.957484339952771],
              [0.781435378935726, 52.959859492247908],
              [0.781034229904127, 52.963607809773073],
              [0.781807850488436, 52.964059085183223],
              [0.780236242021015, 52.965653312175434],
              [0.780185604878288, 52.966998818787609],
              [0.781382975720605, 52.967600239409293],
              [0.781707727314283, 52.968621334037316],
              [0.783310010430932, 52.970075598369874],
              [0.781276340362133, 52.972363161842985],
              [0.781366146998483, 52.976659288091739],
              [0.78758867269439, 52.97561783816974],
              [0.795395405451837, 52.975260863246866],
              [0.798128480919105, 52.974838638032089],
              [0.79867703962755, 52.974636881994002],
              [0.798760593414729, 52.973993773623278],
              [0.800903050321232, 52.973792214957015],
              [0.801277436005859, 52.973293315602078],
              [0.804258768817923, 52.972544073186327],
              [0.804080999600154, 52.973111178389345],
              [0.803385645965997, 52.973046659128215],
              [0.803187394504891, 52.973458681808367],
              [0.804341501379285, 52.974120244391706],
              [0.806170632890907, 52.974095038636463],
              [0.80910796547354, 52.973563424766652],
              [0.811037786323142, 52.973001627739968],
              [0.810647050789938, 52.972512705988279],
              [0.812361270891915, 52.970260961395397],
              [0.813060823407173, 52.970642752360696],
              [0.812232687586913, 52.972202719118705],
              [0.812438691727102, 52.973539475395789],
              [0.815192159916431, 52.974099217136256],
              [0.818704883457808, 52.975954643006737],
              [0.824104871282874, 52.977275946042838],
              [0.822438976760615, 52.977270572524098],
              [0.82140902330871, 52.976896682001957],
              [0.821103692498145, 52.977057699035754],
              [0.820550626881068, 52.976617624039186],
              [0.818423724155325, 52.976921678749697],
              [0.814427978006043, 52.976436405807277],
              [0.813624198926995, 52.976057090491189],
              [0.812996863421481, 52.974495617199366],
              [0.812054772123054, 52.975242689102728],
              [0.811657435982602, 52.975134295671786],
              [0.812926981466484, 52.974107910544006],
              [0.810266082464439, 52.975203184055594],
              [0.810448736598702, 52.975951508010425],
              [0.811370898969363, 52.976273190999464],
              [0.816602657152952, 52.97698105129804],
              [0.830770950883169, 52.978110042602637],
              [0.835806032261218, 52.978194989873167],
              [0.841338586047136, 52.977324542136941],
              [0.844181810124448, 52.977177378330467],
              [0.846177395681421, 52.976532502515354],
              [0.846681678672475, 52.975343335285075],
              [0.845119425310389, 52.975485967921998],
              [0.845222982352527, 52.975746960640429],
              [0.844383654689693, 52.976258934318416],
              [0.842554062354658, 52.976777513958588],
              [0.842445351397403, 52.976006782518112],
              [0.841650009004161, 52.975850460167557],
              [0.843492275277536, 52.974663467231927],
              [0.846786195230431, 52.97382923383698],
              [0.849965736998237, 52.973612721951994],
              [0.852192896988494, 52.973140233287801],
              [0.851862197587071, 52.972507024099166],
              [0.850544470830265, 52.972312044791018],
              [0.850425992661425, 52.95856571094324],
              [0.8509785737338, 52.95775303597928],
              [0.852374501870399, 52.957265412773936],
              [0.854936373384475, 52.956981755563881],
              [0.864656049186668, 52.956877230005659],
              [0.865037787516099, 52.95733562288995],
              [0.866135095562061, 52.957333429438499],
              [0.86806484573567, 52.957062041476298],
              [0.867943431575396, 52.956397743323144],
              [0.869529826839109, 52.956001534538551],
              [0.868363084795439, 52.956641193272496],
              [0.868784050007795, 52.956994316352947],
              [0.87066104024865, 52.955567747543711],
              [0.869900106376433, 52.954870346872077],
              [0.871269472973382, 52.955132200419939],
              [0.870610955466396, 52.955104054937522],
              [0.870938567055795, 52.955576326803424],
              [0.870417667308825, 52.956299309534266],
              [0.871343162808324, 52.956603361599008],
              [0.872315972863811, 52.956430568455076],
              [0.873920024250595, 52.956907028666606],
              [0.871372271231375, 52.957177267318215],
              [0.871176777152459, 52.956676624543071],
              [0.869955852177299, 52.956498408392676],
              [0.869497603140276, 52.957021142706765],
              [0.87021771838496, 52.957866107239866],
              [0.871425703803895, 52.957758678069702],
              [0.871856231708703, 52.958300398157618],
              [0.871570203879373, 52.958658013834487],
              [0.875390545517691, 52.958624044623569],
              [0.876210751225521, 52.959337061577081],
              [0.87554086943779, 52.959318210277146],
              [0.875921268035809, 52.959844044323717],
              [0.875368488992407, 52.960310640025298],
              [0.877787149117825, 52.959663968545698],
              [0.878840127474174, 52.959845276593356],
              [0.879373628547762, 52.959022135783734],
              [0.880313267858479, 52.958596497473359],
              [0.880130289213742, 52.95886800685193],
              [0.880819493797973, 52.95911027282267],
              [0.883818254197409, 52.959015024597043],
              [0.8850644437059, 52.959637607005305],
              [0.88606877603102, 52.958842564183193],
              [0.886720246036481, 52.958786267712981],
              [0.887653027699459, 52.959197026157405],
              [0.888679349554321, 52.960340178131297],
              [0.890387244398841, 52.960783319437958],
              [0.891484844659853, 52.961654043572885],
              [0.893268087534819, 52.96088313928734],
              [0.895443979370764, 52.96092993486095],
              [0.896872894909098, 52.959583107603194],
              [0.897557474627534, 52.959420733305194],
              [0.898485425977662, 52.96087014515934],
              [0.900010971993888, 52.96081222691722],
              [0.904141581956859, 52.96171575750941],
              [0.904978677417712, 52.96229508059583],
              [0.907866974811952, 52.962348503860525],
              [0.908175810150992, 52.962135017149947],
              [0.907576386413022, 52.960549044956345],
              [0.907591183106425, 52.958462446438638],
              [0.907865339602865, 52.959995233694023],
              [0.908658288031089, 52.959562168435333],
              [0.909494880607197, 52.959712534772507],
              [0.908540095994935, 52.959731422041052],
              [0.907944278926125, 52.960283755604912],
              [0.908412512253259, 52.961934086691699],
              [0.910186369754001, 52.963177452650406],
              [0.911771347698185, 52.963315760683244],
              [0.913593177639085, 52.963233319739203],
              [0.916595824119715, 52.962396175163292],
              [0.918044073298791, 52.962494592895261],
              [0.918598121441521, 52.962274155066495],
              [0.917887826078757, 52.961855473356081],
              [0.916813309511097, 52.961954702675335],
              [0.916198930771496, 52.961721597156043],
              [0.91719749943439, 52.960074845080499],
              [0.914677861676493, 52.959766212515142],
              [0.913733856289795, 52.958816394353484],
              [0.914423669773869, 52.958843533472113],
              [0.914794578222913, 52.958446858969857],
              [0.914417887637249, 52.959001042583019],
              [0.914766780738174, 52.959494258131031],
              [0.917295459085456, 52.959871008998803],
              [0.918799606940032, 52.959054413621089],
              [0.918342820037176, 52.959524251102238],
              [0.918728439274655, 52.959744082756089],
              [0.918233761507669, 52.959585381306908],
              [0.917709606073527, 52.959917488814803],
              [0.917764566487475, 52.960401729401013],
              [0.916574705244711, 52.961308611507249],
              [0.916726027417403, 52.961653801548223],
              [0.918075174920978, 52.961652138515667],
              [0.919125167881579, 52.962006718340454],
              [0.919217985207181, 52.961373169320282],
              [0.921025975773528, 52.960844029783516],
              [0.921542933778726, 52.960228823075518],
              [0.923260934655927, 52.960132599480097],
              [0.919696885108262, 52.961496285023969],
              [0.920024397534482, 52.962753467970451],
              [0.918699289918025, 52.963001853612901],
              [0.916685058688147, 52.962902954705129],
              [0.915654092100976, 52.963428243386112],
              [0.915596795628524, 52.964125662347556],
              [0.916333257894547, 52.964689392446253],
              [0.917789280285154, 52.965388316474616],
              [0.919684470919599, 52.965619614672711],
              [0.922621664972079, 52.964584295299176],
              [0.92328762214489, 52.964719879980592],
              [0.925529010646784, 52.964255542674657],
              [0.928053266782033, 52.963217666418664],
              [0.929590081145043, 52.96341357667179],
              [0.930614567777852, 52.962552906783934],
              [0.930648211807375, 52.962874006233449],
              [0.931493855923261, 52.962979932566356],
              [0.931704475638993, 52.962747225457463],
              [0.932937746876639, 52.963277009756005],
              [0.933477527148964, 52.963066745763371],
              [0.933619625625077, 52.96243826099338],
              [0.933945259626678, 52.962804304165388],
              [0.934355717175269, 52.962465940480222],
              [0.935100001876948, 52.962834226995192],
              [0.935770238248107, 52.962769104263202],
              [0.937113496613075, 52.961011143191598],
              [0.936847138733319, 52.959806444723597],
              [0.934906074300274, 52.959093660736769],
              [0.934368697465028, 52.958217583676699],
              [0.93309698178557, 52.958305647091471],
              [0.932664815082071, 52.959554579218093],
              [0.931314330858201, 52.959403570187973],
              [0.930874146151856, 52.958994485288308],
              [0.930373996662122, 52.959657878848006],
              [0.92958720140671, 52.959782498860449],
              [0.930607653500611, 52.958819413317705],
              [0.931214799104883, 52.958879068903904],
              [0.931484770032598, 52.959237499690666],
              [0.932514202797099, 52.959287624959764],
              [0.932265410074301, 52.958736235105],
              [0.932775095756384, 52.957466506128853],
              [0.931709322122886, 52.957340844715532],
              [0.92928718203213, 52.957306184486555],
              [0.928754973776712, 52.958574654303582],
              [0.927630019811094, 52.958454911550113],
              [0.927145905147869, 52.95926806962639],
              [0.926880176119272, 52.957693746712309],
              [0.928472169675454, 52.95842246095863],
              [0.928993827160403, 52.957174036020717],
              [0.932315419124465, 52.957143336152484],
              [0.933276082431038, 52.957410071782377],
              [0.933056405226327, 52.957970331260519],
              [0.934037014637872, 52.957761766625893],
              [0.934983390483853, 52.958080997868095],
              [0.935377181596325, 52.958815842243141],
              [0.937027769079636, 52.95943958216354],
              [0.93770329592093, 52.960046054130281],
              [0.937735085407495, 52.962951631598045],
              [0.938586425230545, 52.963075351636085],
              [0.939133525083223, 52.962556439000039],
              [0.938803691744523, 52.961492698303687],
              [0.940106105227512, 52.961000052987352],
              [0.940188788190035, 52.960177893208488],
              [0.941012134763149, 52.960591845855333],
              [0.940580450207784, 52.960638503659354],
              [0.940245326958706, 52.96125199287777],
              [0.939233522134955, 52.9615063424799],
              [0.939415137691447, 52.961900214439993],
              [0.939792605508737, 52.961822530022253],
              [0.939432479339633, 52.962067944389602],
              [0.93973660642921, 52.963147606871281],
              [0.941326140541582, 52.962869057051151],
              [0.944403700007906, 52.963755003648195],
              [0.944646234653278, 52.963445045728491],
              [0.944857644491988, 52.963838171260697],
              [0.945239140023969, 52.963578721735963],
              [0.94528291802324, 52.962164018827245],
              [0.94602374819484, 52.961779652881717],
              [0.946117752122823, 52.960731495797589],
              [0.945547708577636, 52.959816710251033],
              [0.945991284414043, 52.958872289514773],
              [0.94951389304652, 52.958730012040718],
              [0.952693843497754, 52.959525073564812],
              [0.954963109100192, 52.959477632044269],
              [0.957104858774086, 52.960158117888348],
              [0.958282771359939, 52.960091917057319],
              [0.959383206337893, 52.960461073967892],
              [0.959709050512594, 52.959127458593628],
              [0.960847145279359, 52.959089201694901],
              [0.961196808848605, 52.959781900990428],
              [0.962065099844304, 52.959325911878061],
              [0.96041560048407, 52.95878881749789],
              [0.960622769532994, 52.957963536227155],
              [0.960258195915014, 52.957662381420484],
              [0.957720496958118, 52.957484603403074],
              [0.958197826964953, 52.957104022886121],
              [0.960948086211494, 52.957276491661105],
              [0.961711745319581, 52.957773622220657],
              [0.961186405122737, 52.958497129728677],
              [0.962947925525269, 52.959191485851086],
              [0.962990919288309, 52.958970995820266],
              [0.96421724712752, 52.959090572316533],
              [0.964242805486748, 52.959355213905397],
              [0.963213724849297, 52.959397977712214],
              [0.964114493889266, 52.960178534127728],
              [0.965169161041319, 52.960161202274627],
              [0.965070474011015, 52.959720334434181],
              [0.965661530201068, 52.959261347259314],
              [0.966994194389304, 52.959324284910274],
              [0.965299676842236, 52.959696627429842],
              [0.966409486957397, 52.961093331472867],
              [0.966177910854871, 52.961626076745048],
              [0.967531788989157, 52.962040986453111],
              [0.969150057165649, 52.963075152333104],
              [0.969318711612876, 52.96371030521658],
              [0.969545964684504, 52.963223523641183],
              [0.969832940458054, 52.963585941793035],
              [0.970476128522807, 52.963648093565496],
              [0.973324142364017, 52.963523779575482],
              [0.974796055571033, 52.96307506664084],
              [0.977168580257215, 52.963140619872064],
              [0.978425173617466, 52.963502990655698],
              [0.983100678015599, 52.963401845264428],
              [0.983970579875087, 52.963771171159095],
              [0.984538920140529, 52.963503301636273],
              [0.984097136750613, 52.961890344393787],
              [0.981839340422769, 52.961392205811727],
              [0.9812377644626, 52.960787719295176],
              [0.979065708843492, 52.960365611960526],
              [0.979916903240645, 52.960248039300147],
              [0.981497327999804, 52.960685882898112],
              [0.982037951769985, 52.961291898432442],
              [0.98272684539498, 52.961282694134091],
              [0.982602802840748, 52.960562807010916],
              [0.983444256294501, 52.9594391855005],
              [0.983303172434028, 52.959276885657644],
              [0.983252153112359, 52.959218193905102],
              [0.982396232937194, 52.958233524492705],
              [0.981399048401744, 52.958609269684715],
              [0.982684460856386, 52.957639971386556],
              [0.982314433461008, 52.958062021955165],
              [0.982759113769548, 52.958464513456008],
              [0.9832672833408, 52.958426571443283],
              [0.983300980852872, 52.959224985255133],
              [0.983767550279493, 52.959289881040732],
              [0.985230024928906, 52.959053497476575],
              [0.98537483174269, 52.958367321674473],
              [0.985909764825782, 52.958177620367977],
              [0.990904484562118, 52.957337937604755],
              [0.992702220815782, 52.957429340474981],
              [0.995880445248985, 52.956272801352789],
              [0.998644276576508, 52.956209347983886],
              [0.998875542281433, 52.956689109455354],
              [0.998453805582232, 52.957501897512415],
              [0.998925701466329, 52.957929721475629],
              [0.999825963509622, 52.958027487652238],
              [1.000057952999512, 52.9574209219683],
              [1.001057013821936, 52.957139392808351],
              [1.001575848382926, 52.957664039880996],
              [1.002613543517319, 52.957918380092927],
              [1.003627648497304, 52.957102287647601],
              [1.004614413901753, 52.957652856140122],
              [1.005186479810284, 52.956668978610885],
              [1.007052593569461, 52.955900537669351],
              [1.008062467532876, 52.956569195308901],
              [1.008235734118408, 52.958527896702378],
              [1.009157586771851, 52.95876443125907],
              [1.009970486634479, 52.958611641422792],
              [1.009304964866078, 52.957906397788491],
              [1.009566530878451, 52.957513087183393],
              [1.011986039815104, 52.957358229884981],
              [1.013482947053832, 52.956656602309444],
              [1.014101592574257, 52.955938578841533],
              [1.015432444203218, 52.955462358753906],
              [1.017650561546283, 52.956565173250425],
              [1.016509304768072, 52.959827918897112],
              [1.016404027171298, 52.962827839055578],
              [1.017267464870941, 52.96258738705032],
              [1.018329673808255, 52.962698885468768],
              [1.018104670005964, 52.961778361018041],
              [1.017559133206762, 52.961707691281134],
              [1.018213753096224, 52.961505809847672],
              [1.018067951285679, 52.960717262986201],
              [1.018842758832548, 52.961620217564281],
              [1.019105192457158, 52.962714246662529],
              [1.018534069475208, 52.962127154708156],
              [1.019118817593075, 52.963144647924928],
              [1.018754510855602, 52.963238438389084],
              [1.019994039529263, 52.964119681953072],
              [1.0179866221827, 52.963161730979557],
              [1.017746927565064, 52.963312602659578],
              [1.018579231142589, 52.9640783094315],
              [1.023730858834978, 52.965889687246772],
              [1.02584392403208, 52.966011233148947],
              [1.026249960987905, 52.9658111437599],
              [1.025667437217863, 52.965595771986621],
              [1.024072587949766, 52.965600416686556],
              [1.023332701342153, 52.964517652115596],
              [1.025484493362149, 52.964512320761855],
              [1.026657866339619, 52.964709254890941],
              [1.025694466928831, 52.964831607006744],
              [1.026126868503785, 52.965179398346159],
              [1.027055640088047, 52.964787033139487],
              [1.026987825639351, 52.964764631383325],
              [1.028703614979762, 52.96505257177084],
              [1.028322721497953, 52.965824861235802],
              [1.03079161674173, 52.965986706150119],
              [1.028848938262817, 52.965107318386494],
              [1.031414001329361, 52.96626038738971],
              [1.035883893535492, 52.967089556151286],
              [1.043421249888872, 52.965868803479253],
              [1.044032543031826, 52.965602242514358],
              [1.043365796008176, 52.965376525385899],
              [1.043244477668088, 52.964921905614496],
              [1.043916063373359, 52.965110629292354],
              [1.044121127542701, 52.965001081053423],
              [1.043766412440952, 52.965270038007034],
              [1.044134603521332, 52.965528584536237],
              [1.04435251796692, 52.965365627177803],
              [1.044696842255008, 52.964564544523647],
              [1.044420210810089, 52.964774302044944],
              [1.0442280421151, 52.964633214852263],
              [1.043033063228585, 52.964838294544641],
              [1.043258080774205, 52.965242596314347],
              [1.042779613261257, 52.965512944852343],
              [1.042687960766543, 52.964653792618201],
              [1.044384274313819, 52.964440363826441],
              [1.043898959431277, 52.964197654143263],
              [1.043791687971045, 52.964144005482204],
              [1.04307063063164, 52.96378338808087],
              [1.043078823529405, 52.964578132522966],
              [1.042918546445583, 52.963780989576989],
              [1.043550839972796, 52.963728818198184],
              [1.043879015465748, 52.964170794179097],
              [1.044593718350455, 52.964390031786046],
              [1.044241034017237, 52.962847831036328],
              [1.043235411370729, 52.962271090757731],
              [1.043387870620432, 52.961452445722252],
              [1.04371466095127, 52.960995335873022],
              [1.045630041774602, 52.960171963489898],
              [1.045814596330239, 52.959492777751969],
              [1.044696204453671, 52.959175234266858],
              [1.044769667381343, 52.958271382565087],
              [1.043705208038578, 52.957618818130648],
              [1.043329687271348, 52.955623067953141],
              [1.041890535657431, 52.954256176207323],
              [1.04090660273961, 52.95313660258693],
              [1.040718850951553, 52.952540697251152],
              [1.043450038047133, 52.95538617354994],
              [1.043922304975085, 52.957571887835421],
              [1.045012878715768, 52.958299319561341],
              [1.04486226792345, 52.95908015070804],
              [1.046031545148999, 52.959422466442888],
              [1.045510881695916, 52.960701092358285],
              [1.044003620701907, 52.961038289564037],
              [1.043548253710055, 52.961848511139081],
              [1.044358607089838, 52.962655970785519],
              [1.046030518951823, 52.962372100533663],
              [1.044571135222753, 52.962840269131583],
              [1.045311799305169, 52.964371622863275],
              [1.044597994019805, 52.963391732480396],
              [1.044810565818432, 52.964127281858822],
              [1.046375607326553, 52.965731916837811],
              [1.045706332071872, 52.965851597258791],
              [1.045912473410577, 52.965457826461275],
              [1.045251861133412, 52.964768838457125],
              [1.043704812128916, 52.966025204494734],
              [1.037358050823919, 52.967253304908702],
              [1.037023270808504, 52.967428229812512],
              [1.037866336624816, 52.967492101266082],
              [1.03140828907988, 52.969157070787745],
              [1.028876143662092, 52.968604769700498],
              [1.027233675312905, 52.968824695125249],
              [1.027435634944224, 52.969966111358296],
              [1.026453886646844, 52.969985733589176],
              [1.027250467780872, 52.969853026818363],
              [1.026526192144433, 52.968804055280472],
              [1.025192368812919, 52.969028677372059],
              [1.025737482379383, 52.969134395213644],
              [1.024886535090237, 52.969512177205488],
              [1.025475165776471, 52.970283143444171],
              [1.024885353223435, 52.969942955168555],
              [1.0239820443303, 52.970488427726949],
              [1.023644607969984, 52.970093247870857],
              [1.024906096613244, 52.969856097499729],
              [1.024667663428131, 52.969534836752295],
              [1.025597920737021, 52.969162230337062],
              [1.023024469825125, 52.969083870433991],
              [1.022047025041968, 52.969591647361909],
              [1.022087773129097, 52.970196714724516],
              [1.021595596249323, 52.970529373615435],
              [1.020736817668894, 52.970304817535627],
              [1.020598307072246, 52.970668045363766],
              [1.020844108617003, 52.9707348408689],
              [1.02102497522449, 52.970783990962715],
              [1.020363706771206, 52.971127239736866],
              [1.020823523285983, 52.970751654542418],
              [1.02033336353519, 52.970735033043361],
              [1.020454123915153, 52.9702454607162],
              [1.019677202604209, 52.9702760049733],
              [1.02018391901841, 52.970066183614598],
              [1.021560435439326, 52.970409766641005],
              [1.021963235845045, 52.970034418521557],
              [1.021194099543788, 52.969706867637171],
              [1.01915636599611, 52.969898963938718],
              [1.017885581909187, 52.97134939496857],
              [1.016638858653696, 52.970901755778044],
              [1.015853827244617, 52.971350637928545],
              [1.016751423675942, 52.971533776614713],
              [1.017556374740669, 52.9722255471575],
              [1.016696411793847, 52.972197930364445],
              [1.017032818371091, 52.971959175254938],
              [1.016687102382337, 52.971615444524041],
              [1.015988108834758, 52.971567547920408],
              [1.016124970191843, 52.971842844336912],
              [1.015720046885297, 52.971333351366276],
              [1.016378720958062, 52.970845413491858],
              [1.017940158432974, 52.970978411235407],
              [1.017835235958288, 52.970669931882014],
              [1.014568680663801, 52.971056827345741],
              [1.012742479549098, 52.97088643694584],
              [1.012432006369274, 52.971433868157177],
              [1.0128573749829, 52.971360131650023],
              [1.014418642653447, 52.972110973283598],
              [1.012732254699455, 52.971424454912864],
              [1.01238484303804, 52.971740813230504],
              [1.012611824477009, 52.972433783260435],
              [1.01359109437295, 52.972720088197754],
              [1.015457292409528, 52.972522552002467],
              [1.013873433618184, 52.972795657136174],
              [1.014256359785227, 52.97301075645499],
              [1.011778200401659, 52.972420750262948],
              [1.011636614856597, 52.972184237155545],
              [1.012293976249671, 52.972340227339032],
              [1.012121558240016, 52.971596413780368],
              [1.011051877922523, 52.971337568187515],
              [1.009972038380216, 52.97288198924759],
              [1.011656621839194, 52.973413919653119],
              [1.005029962629314, 52.974951313374156],
              [1.002823352622339, 52.973701388468001],
              [1.000180453734328, 52.974751956617197],
              [1.000318695345366, 52.975607257144944],
              [0.999927003900805, 52.976017319449774],
              [0.99566509045741, 52.976926109419907],
              [0.993125077620169, 52.976152027086727],
              [0.992318697418872, 52.975434951402242],
              [0.99256638994673, 52.976151714407983],
              [0.991807947051157, 52.976408221954941],
              [0.989895899347153, 52.976312480306675],
              [0.989762159775401, 52.97597233043718],
              [0.987229125024769, 52.974843641171205],
              [0.985696870439331, 52.973514394822544],
              [0.984863002538189, 52.972266500996319],
              [0.984986960454116, 52.971731026896748],
              [0.984194179782756, 52.973196049017638],
              [0.982038748844495, 52.971921977718161],
              [0.982420976746326, 52.971480739370399],
              [0.981776401667229, 52.970665110137986],
              [0.981249277814248, 52.972138730486577],
              [0.983969767408717, 52.97372235513086],
              [0.987858130396056, 52.976726151069656],
              [0.987735807271263, 52.976982817711736],
              [0.988448066469501, 52.977243671236451],
              [0.985823500452279, 52.976710762763062],
              [0.984849347193608, 52.976216373331631],
              [0.983685682369683, 52.976170968037081],
              [0.980845568669036, 52.974647889862574],
              [0.980612299587393, 52.97411598895193],
              [0.980347208758641, 52.974651121005223],
              [0.980803243945519, 52.975050019326602],
              [0.980395466623617, 52.974969426083462],
              [0.98032487582535, 52.9746962033839],
              [0.980303306987579, 52.974612719996387],
              [0.980143628612929, 52.973994657855982],
              [0.978475138368557, 52.97371277237955],
              [0.978622419553068, 52.972865579240342],
              [0.977364190040618, 52.974021189286141],
              [0.976394243923202, 52.972502376767324],
              [0.978014625560745, 52.971825992985252],
              [0.978826574622013, 52.972022355105111],
              [0.978697639762028, 52.971707252300924],
              [0.980113905928028, 52.972156428364265],
              [0.980091674088416, 52.971746926062181],
              [0.978881214498804, 52.971474238346829],
              [0.974565941624404, 52.972126422692895],
              [0.972231399737696, 52.973098498656796],
              [0.970700655291213, 52.974106182834916],
              [0.97076820763899, 52.974547824793376],
              [0.971701254030777, 52.974750199872673],
              [0.971530121187963, 52.975208603134057],
              [0.970398722858851, 52.974525590451343],
              [0.968893481694536, 52.972409511161743],
              [0.970043052942412, 52.970905101785569],
              [0.971092499107076, 52.971111764514696],
              [0.970247727742544, 52.970544779479098],
              [0.969622725324656, 52.971335561168559],
              [0.968601983549027, 52.971786420554679],
              [0.968167023786125, 52.973003186152987],
              [0.972494403321907, 52.977557605208311],
              [0.973119535307809, 52.977397182049337],
              [0.972663608740929, 52.978003896601933],
              [0.973695348788191, 52.978730749012854],
              [0.97049894270383, 52.977418675448874],
              [0.969417141028586, 52.977459209676695],
              [0.969319214948443, 52.977204471462677],
              [0.970033421958562, 52.976995976320637],
              [0.96878162421526, 52.976816841241501],
              [0.967898951582496, 52.975611418518341],
              [0.967862601659955, 52.976232810046575],
              [0.967416456633239, 52.976461574028619],
              [0.965804457676668, 52.975330105670444],
              [0.965727325389519, 52.976030749218786],
              [0.965408423362424, 52.976072881723809],
              [0.965230321923979, 52.975495512211431],
              [0.965036799649181, 52.975736845493152],
              [0.964524665961387, 52.975589560286885],
              [0.963851685461392, 52.97474666916991],
              [0.963155797231148, 52.974569791151168],
              [0.963589885724249, 52.973969054229961],
              [0.962725418600094, 52.974201941241112],
              [0.961335026982724, 52.973258225651556],
              [0.961671593644724, 52.972668919351314],
              [0.963232170484953, 52.972225338458145],
              [0.961607358140287, 52.972535633295415],
              [0.961242597613362, 52.973126541494054],
              [0.960602321731669, 52.972956355791098],
              [0.960352794168911, 52.972574997673291],
              [0.960753921707641, 52.972101886856585],
              [0.95983705772667, 52.971568099600788],
              [0.961036803850923, 52.971555285806197],
              [0.959171399533619, 52.971222285914912],
              [0.9582912530028, 52.96915435144907],
              [0.957846415459769, 52.969401931573593],
              [0.958496092781334, 52.970320972526721],
              [0.958169768242774, 52.971474743447388],
              [0.963145657634243, 52.976053807061597],
              [0.965779809257891, 52.977622911216017],
              [0.970174108613543, 52.978785566568035],
              [0.978289983838586, 52.980006793750476],
              [0.987933866094711, 52.978815914674179],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000148",
        LAD13CDO: "33UG",
        LAD13NM: "Norwich",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [1.31949658937092, 52.615713157400947],
              [1.318529096715005, 52.614975825173182],
              [1.315202889403998, 52.615110419601116],
              [1.313466406052701, 52.61362022846987],
              [1.315189403056, 52.615166558354879],
              [1.31852171628458, 52.615034491255578],
              [1.319497737679035, 52.615786873643074],
              [1.320337216972491, 52.61598456948709],
              [1.32116821079549, 52.617767190392307],
              [1.320786398102085, 52.618376263447523],
              [1.320647885532379, 52.618467111937832],
              [1.320513453404462, 52.619189481418367],
              [1.320891782474593, 52.618367670035205],
              [1.321377498946034, 52.618169631727731],
              [1.320342174741271, 52.615854920889795],
              [1.31949658937092, 52.615713157400947],
            ],
          ],
          [
            [
              [1.300161521187688, 52.676830536522743],
              [1.299637557865142, 52.674238790923759],
              [1.296662459401922, 52.674341440724042],
              [1.294797837333345, 52.673590208829481],
              [1.294399899740505, 52.673021193644352],
              [1.295216782678842, 52.672886044289804],
              [1.292042621756633, 52.671639683520027],
              [1.29300227310629, 52.671455611993139],
              [1.292082106398709, 52.668624833016978],
              [1.293676729209034, 52.668485155374078],
              [1.293829927888474, 52.666589539980968],
              [1.292004839333553, 52.662936732693488],
              [1.291683198744612, 52.661315135405275],
              [1.292525745811216, 52.661268325180309],
              [1.293016960492892, 52.660739330770632],
              [1.292380384132617, 52.660161657438948],
              [1.292577977433381, 52.654902997223154],
              [1.294407447064698, 52.654254928553918],
              [1.294009016313766, 52.653932351640705],
              [1.295492156482376, 52.653301095741426],
              [1.299734779213313, 52.652708959160655],
              [1.299957095366749, 52.653264864411589],
              [1.302323050009294, 52.653046001597957],
              [1.302218233164466, 52.652793506053641],
              [1.307117788214645, 52.652246632158644],
              [1.307228194947075, 52.652748990906929],
              [1.309102614957911, 52.652973618267914],
              [1.31058432255678, 52.651681176432184],
              [1.314081674632204, 52.649394267093193],
              [1.322953397815645, 52.644999486829974],
              [1.34054818885665, 52.649682873652552],
              [1.341113250087817, 52.64706863802801],
              [1.342220735733974, 52.64129397360167],
              [1.329841423466458, 52.637559702074014],
              [1.328744105470672, 52.630588956552295],
              [1.328333091640093, 52.62971193536324],
              [1.327203157669709, 52.628865916882233],
              [1.326522618656338, 52.626988270245377],
              [1.32250062940269, 52.625891587673415],
              [1.325261052382327, 52.623159119862393],
              [1.322557636455569, 52.621496558673215],
              [1.320874155542165, 52.622663524619163],
              [1.322423626455079, 52.621424773508288],
              [1.321916110237654, 52.621104453400889],
              [1.314643954657739, 52.619952095085331],
              [1.310830366015691, 52.620154172902737],
              [1.306463765529063, 52.621312316882936],
              [1.304484260266494, 52.622713954574763],
              [1.303314345334905, 52.625682145441701],
              [1.305535664472296, 52.627406271765032],
              [1.307727399268372, 52.630263487992003],
              [1.309117349220763, 52.632932631844852],
              [1.309040520792074, 52.633774788499636],
              [1.308672831269661, 52.63438674336026],
              [1.305042972219105, 52.63433705705939],
              [1.302438412065442, 52.635397254933203],
              [1.295178208026766, 52.63193198078897],
              [1.293358516055554, 52.632127422169198],
              [1.292160962642427, 52.631691268359575],
              [1.290834571986939, 52.631783013257198],
              [1.289200160514124, 52.63265495588719],
              [1.288626330865271, 52.633399383408666],
              [1.288140957385908, 52.6332347937797],
              [1.290608377509255, 52.631684076245399],
              [1.29207131391234, 52.631521084016647],
              [1.293461856860334, 52.631870026983862],
              [1.294870624815647, 52.631733689432458],
              [1.296296603068135, 52.631963796290925],
              [1.29722279006324, 52.632780704407068],
              [1.298506709524127, 52.633086691349099],
              [1.302391672009304, 52.635189006830963],
              [1.305020621232591, 52.634119135194538],
              [1.308381392252333, 52.634266279948392],
              [1.308884738123473, 52.633082133965495],
              [1.307049199332656, 52.629698749504364],
              [1.304793324687356, 52.627048367866429],
              [1.302847569515977, 52.626116974713483],
              [1.304109528540321, 52.622800862681451],
              [1.306124892596956, 52.621188677134064],
              [1.310602258603786, 52.619990573158042],
              [1.314264622201656, 52.619723486022622],
              [1.319604301201655, 52.620316715165345],
              [1.31990049785857, 52.619212972933951],
              [1.320224161079741, 52.61919400016712],
              [1.320960021148904, 52.617574271454167],
              [1.32029037105525, 52.616244003412746],
              [1.318235314873741, 52.615154943266148],
              [1.315199075062418, 52.615548519820372],
              [1.312061894322072, 52.613198167642885],
              [1.312325196108327, 52.612978612746979],
              [1.313386581577136, 52.613541520099815],
              [1.312363851156297, 52.612944779368163],
              [1.311133358259252, 52.612348164638938],
              [1.309071235492949, 52.610533356080559],
              [1.308864120791726, 52.610059783393233],
              [1.306767027526652, 52.608895258752611],
              [1.305269547168928, 52.608902917402588],
              [1.302711597926154, 52.608110041392088],
              [1.301957776834861, 52.606711874581919],
              [1.299770415010058, 52.605520968305456],
              [1.299662835917859, 52.604876423325777],
              [1.298320644298222, 52.605572178212867],
              [1.297866464629565, 52.605327617383928],
              [1.297250437249084, 52.603716029625346],
              [1.292663367976693, 52.601290452824884],
              [1.291694160238742, 52.599830765199421],
              [1.289208839349583, 52.59873519645808],
              [1.287414448136538, 52.596687741114081],
              [1.286378964781293, 52.596153502007603],
              [1.283443683366979, 52.595198021660231],
              [1.281473618872902, 52.595239182368289],
              [1.280369560465019, 52.595741961615403],
              [1.280590914509689, 52.596332103224093],
              [1.279708816881219, 52.596584094865946],
              [1.279476794958743, 52.597095968062284],
              [1.278103305253434, 52.597097152501249],
              [1.277844783644992, 52.597373223788864],
              [1.276865990356971, 52.598091044259256],
              [1.276684372847789, 52.598467510496171],
              [1.274430740501782, 52.598926513186427],
              [1.27480298246927, 52.599817373114917],
              [1.274240571327717, 52.600316793218681],
              [1.272796626241857, 52.600325260450504],
              [1.272741194175307, 52.60075039296251],
              [1.268800933037249, 52.601749670249838],
              [1.268405460423387, 52.601417937467033],
              [1.26694593566075, 52.601396184835615],
              [1.264655092457218, 52.600286642414318],
              [1.263790789532659, 52.600224145677416],
              [1.262450653835296, 52.597804949951929],
              [1.260586718331149, 52.597576624416469],
              [1.257763430418637, 52.598122951911385],
              [1.255672655887619, 52.599843407046393],
              [1.255817468626862, 52.600401521430321],
              [1.254919612324324, 52.600762581177584],
              [1.25487670758273, 52.601836694828691],
              [1.252869569430405, 52.604031454962609],
              [1.250970825312346, 52.605299561735265],
              [1.250831482800981, 52.606090326190056],
              [1.248638874515004, 52.60668385470278],
              [1.247733630575977, 52.606548616134674],
              [1.246004191410042, 52.607173451478012],
              [1.245497952814416, 52.608018339661065],
              [1.243608641492489, 52.608480243207012],
              [1.241663831746857, 52.609970698058881],
              [1.242396771855502, 52.61073215785855],
              [1.242110618114183, 52.611258025005156],
              [1.243085212158267, 52.612369903873422],
              [1.243194450922514, 52.613127763511656],
              [1.243924496740621, 52.613349681575329],
              [1.244511007568299, 52.614427218259792],
              [1.243516471766452, 52.614864583778278],
              [1.24370805949816, 52.615297318147704],
              [1.243195327547179, 52.615756549514153],
              [1.243524544444454, 52.617110947154949],
              [1.241224004674043, 52.617752255377802],
              [1.235013228647323, 52.61672783945766],
              [1.234274958531562, 52.617055594567873],
              [1.233356513096407, 52.616943987982772],
              [1.231432176488685, 52.618080260802806],
              [1.230586501649233, 52.620199731696758],
              [1.228451762681622, 52.621692441977402],
              [1.228716326985994, 52.62365929673927],
              [1.231832417493709, 52.627678885923899],
              [1.232710251259405, 52.627938209854904],
              [1.230829658680875, 52.629915061665749],
              [1.231110545951498, 52.630096257963295],
              [1.230295953877941, 52.630394590919146],
              [1.230544739633746, 52.630902228846281],
              [1.228408442305815, 52.631919217921961],
              [1.227841006541659, 52.631841160254602],
              [1.227422170120908, 52.632195232136816],
              [1.225339139465853, 52.632090124956527],
              [1.225208966571449, 52.631265372786814],
              [1.223355393788192, 52.630139515549352],
              [1.223338719998138, 52.628023805525828],
              [1.221694318673524, 52.627790675324839],
              [1.21854132561879, 52.626637150921745],
              [1.219466044295781, 52.626208196436167],
              [1.219370177804007, 52.625989564613604],
              [1.218180444083327, 52.62562710441513],
              [1.216250993402609, 52.625576122588704],
              [1.215229272282168, 52.626435775232657],
              [1.21280309101284, 52.627330849321353],
              [1.213091839424326, 52.628547919491787],
              [1.212457547121266, 52.629149709464564],
              [1.213389328946043, 52.629662226358285],
              [1.214421015478131, 52.631576800072999],
              [1.214046534835419, 52.634391125203464],
              [1.211325566226524, 52.636433634758504],
              [1.209815480997882, 52.637129311945579],
              [1.209998449892156, 52.637709823141655],
              [1.20802868162205, 52.638932364454973],
              [1.206322477722874, 52.638283396956368],
              [1.206129510492827, 52.639484748405891],
              [1.205063184258436, 52.639458762809774],
              [1.203869047803756, 52.644679386785519],
              [1.205021847116717, 52.645077169584631],
              [1.209136540895168, 52.645216621611134],
              [1.211150781493061, 52.644778884931227],
              [1.211471526759146, 52.645342166656725],
              [1.214845723975337, 52.644719076306977],
              [1.223566850376848, 52.643642840653619],
              [1.235432577224498, 52.640908894148453],
              [1.238414845397142, 52.64371246250267],
              [1.241314442895486, 52.645433606808233],
              [1.243669593009104, 52.646408750358248],
              [1.248025747930873, 52.647587080461975],
              [1.248486474452063, 52.647718335573309],
              [1.251226150695899, 52.648212418979369],
              [1.253394410787666, 52.64913494641759],
              [1.253320257771445, 52.649510215293802],
              [1.2549001392984, 52.649796852521405],
              [1.261563758569777, 52.65206600019787],
              [1.277163863802831, 52.656492634823145],
              [1.27913471480341, 52.657155753249462],
              [1.284239367088093, 52.657617733425845],
              [1.284965803705129, 52.660203022501641],
              [1.282365438903266, 52.660260750398727],
              [1.280390831453237, 52.660635646464904],
              [1.280829806418012, 52.661933847286036],
              [1.27863359522723, 52.662096309424044],
              [1.277924892082444, 52.66284441055727],
              [1.277039893559506, 52.665979781029939],
              [1.273036015415359, 52.666758745389458],
              [1.272257150586272, 52.666649865695085],
              [1.271043278681466, 52.668677274085589],
              [1.269549518911359, 52.670722289550383],
              [1.266009025338076, 52.673316597696306],
              [1.264613079208188, 52.674892982986748],
              [1.262836625779331, 52.677380085987188],
              [1.282922389896114, 52.677309711975354],
              [1.292307336327956, 52.684909552466173],
              [1.295310932522392, 52.682425625646516],
              [1.295405712341408, 52.681562301864723],
              [1.296048508734522, 52.68133396282294],
              [1.295379720459145, 52.678703063559276],
              [1.296258505835492, 52.677463571022201],
              [1.297052438003779, 52.677435173244547],
              [1.297170492776631, 52.677077538536757],
              [1.300161521187688, 52.676830536522743],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000149",
        LAD13CDO: "33UH",
        LAD13NM: "South Norfolk",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [1.640611776980956, 52.58781130781135],
              [1.643545348954176, 52.587587862846583],
              [1.645428615798588, 52.587609889511945],
              [1.645379265929884, 52.586482593127485],
              [1.646335454116328, 52.583878876537923],
              [1.649470824738779, 52.57946208438333],
              [1.649595082840445, 52.578523713586591],
              [1.648579976829581, 52.576756116697211],
              [1.647283223172141, 52.575773429527011],
              [1.644574342609847, 52.574870230571307],
              [1.642531988706125, 52.574685798034295],
              [1.639505470981501, 52.575184624305393],
              [1.637666414626668, 52.574994751056828],
              [1.634575576318438, 52.573308854763049],
              [1.632828728727923, 52.570735218713793],
              [1.634162656112905, 52.563700975400586],
              [1.633844868107316, 52.562214946713354],
              [1.632878293231683, 52.560914349636477],
              [1.627675230938391, 52.557280177159825],
              [1.625094671627971, 52.554175247446025],
              [1.621908556083293, 52.552419105880382],
              [1.619337778331272, 52.551717095177125],
              [1.613766806879944, 52.551852401485114],
              [1.610069652767216, 52.550872499546699],
              [1.607322763274722, 52.548417183284954],
              [1.605776408442237, 52.54543140471359],
              [1.605342731050453, 52.54332822419633],
              [1.605739893931812, 52.542522798642594],
              [1.6064843298907, 52.541724768500906],
              [1.608731574093927, 52.540562469676345],
              [1.613048211246197, 52.539954830892114],
              [1.619112632874134, 52.54054558561301],
              [1.6205261167718, 52.539814229058294],
              [1.619772781379652, 52.53978422722485],
              [1.619926101522158, 52.539550172498906],
              [1.620963545074818, 52.539501315313878],
              [1.621401808009029, 52.538500291859492],
              [1.620719563274005, 52.535840813302414],
              [1.61986634928408, 52.53461489927831],
              [1.619626238037534, 52.534851611437375],
              [1.618163621823496, 52.53394475891772],
              [1.619445800271834, 52.533358644955797],
              [1.619794490185563, 52.534440806391643],
              [1.620481076983995, 52.532586695079125],
              [1.580630154537226, 52.555223257748835],
              [1.580479423748692, 52.555492257570506],
              [1.581211511084808, 52.555258720618269],
              [1.582479080013247, 52.554480085789898],
              [1.582051138999785, 52.556326113108824],
              [1.582072057265232, 52.554809019498556],
              [1.581370041601938, 52.556135373034358],
              [1.580220332473299, 52.555518090752066],
              [1.579170944740204, 52.55613179533556],
              [1.584515083550351, 52.557802992196443],
              [1.589837225381124, 52.557430183068234],
              [1.593116764585876, 52.558265106367877],
              [1.595089476393124, 52.559756694816421],
              [1.596612849849836, 52.563166943904903],
              [1.601742630471945, 52.567154575850871],
              [1.604008680826287, 52.567967000989732],
              [1.607451999421036, 52.568302750683927],
              [1.608599001042631, 52.569237363780587],
              [1.608957929532957, 52.570443364886863],
              [1.607385184766448, 52.572430554881201],
              [1.607702529925269, 52.573831208800698],
              [1.610198839535153, 52.574619407060965],
              [1.612563459619631, 52.574864711278295],
              [1.614135020890282, 52.574622418269939],
              [1.61713043455546, 52.57338494950686],
              [1.619078902925467, 52.573149051083654],
              [1.620327539513976, 52.573198982853945],
              [1.623797101454062, 52.574221539601609],
              [1.625782536117241, 52.57513029982259],
              [1.627006431900018, 52.576273754665657],
              [1.627377468918745, 52.579012900556464],
              [1.6287371854607, 52.580064926625361],
              [1.630022383969666, 52.580554368543702],
              [1.632091820974548, 52.580367613832344],
              [1.634025259159561, 52.580642823060877],
              [1.63580387985047, 52.581152120766554],
              [1.637535053635491, 52.582139562190157],
              [1.638758918547496, 52.583970984493575],
              [1.638593858639573, 52.585827139340907],
              [1.639734030275813, 52.587537901910835],
              [1.640611776980956, 52.58781130781135],
            ],
          ],
          [
            [
              [1.320805521799085, 52.619904381091494],
              [1.320224161079741, 52.61919400016712],
              [1.31990049785857, 52.619212972933951],
              [1.319604301201655, 52.620316715165345],
              [1.322927519297672, 52.621073362657206],
              [1.320805521799085, 52.619904381091494],
            ],
          ],
          [
            [
              [1.190459653404629, 52.678503114650347],
              [1.191968614539372, 52.677930946806491],
              [1.193653050705492, 52.678040220047556],
              [1.196216564625166, 52.677078021194959],
              [1.196810125448857, 52.675542122046529],
              [1.197726574447332, 52.675691850694385],
              [1.19962268100409, 52.674791662438132],
              [1.200402419237179, 52.673739952150093],
              [1.201775943915707, 52.672897917055394],
              [1.202636824779237, 52.670307927591779],
              [1.203603459402445, 52.669891468015848],
              [1.203765783597832, 52.668996731414801],
              [1.20358175624298, 52.667978262923619],
              [1.202410632414278, 52.666448689016597],
              [1.201952834910545, 52.6640733252964],
              [1.202587242234892, 52.663585814413345],
              [1.202339831994863, 52.662124784799033],
              [1.202981250400144, 52.661046215908577],
              [1.205075721473575, 52.659996669836474],
              [1.205745048861276, 52.660088269722714],
              [1.206584139359252, 52.66084167955362],
              [1.207488757214897, 52.660591443698046],
              [1.209795600296317, 52.661310458747437],
              [1.210994916814139, 52.662335566404344],
              [1.213021157580676, 52.662615156438278],
              [1.213728744648046, 52.663690452424618],
              [1.215136753241441, 52.664574054801093],
              [1.216499516720988, 52.666149564504067],
              [1.218128856033435, 52.669196332998091],
              [1.218927176593907, 52.669249280083328],
              [1.219224465470288, 52.67069633377114],
              [1.218034683822237, 52.672643370643399],
              [1.219668352213617, 52.672647529105348],
              [1.221810374630701, 52.673145963806363],
              [1.224315730405693, 52.672229694467333],
              [1.225289747724236, 52.671273248888319],
              [1.2275829004119, 52.670511079380503],
              [1.229224262834732, 52.669217141323209],
              [1.230830386040505, 52.669611311595304],
              [1.231525021325957, 52.668600379655615],
              [1.234307572582667, 52.667938037379763],
              [1.235425575352495, 52.666312055186637],
              [1.234714684512191, 52.665840434283872],
              [1.235120724358918, 52.664649399221325],
              [1.233261800456428, 52.662653261923943],
              [1.233445304214105, 52.661152638270188],
              [1.233436222745458, 52.660048489000921],
              [1.234299459092823, 52.658960085724132],
              [1.239574033236099, 52.657165583349347],
              [1.242588019017398, 52.656564154184956],
              [1.244079685340761, 52.655797517757186],
              [1.247078551190457, 52.653359014111857],
              [1.247858253718932, 52.652067744649287],
              [1.246617696189465, 52.650653815898607],
              [1.246600363964839, 52.649801706931278],
              [1.248486474452063, 52.647718335573309],
              [1.248025747930873, 52.647587080461975],
              [1.243669593009104, 52.646408750358248],
              [1.241314442895486, 52.645433606808233],
              [1.238414845397142, 52.64371246250267],
              [1.235432577224498, 52.640908894148453],
              [1.223566850376848, 52.643642840653619],
              [1.214845723975337, 52.644719076306977],
              [1.211471526759146, 52.645342166656725],
              [1.211150781493061, 52.644778884931227],
              [1.209136540895168, 52.645216621611134],
              [1.205021847116717, 52.645077169584631],
              [1.203869047803756, 52.644679386785519],
              [1.205063184258436, 52.639458762809774],
              [1.206129510492827, 52.639484748405891],
              [1.206322477722874, 52.638283396956368],
              [1.20802868162205, 52.638932364454973],
              [1.209998449892156, 52.637709823141655],
              [1.209815480997882, 52.637129311945579],
              [1.211325566226524, 52.636433634758504],
              [1.214046534835419, 52.634391125203464],
              [1.214421015478131, 52.631576800072999],
              [1.213389328946043, 52.629662226358285],
              [1.212457547121266, 52.629149709464564],
              [1.213091839424326, 52.628547919491787],
              [1.21280309101284, 52.627330849321353],
              [1.215229272282168, 52.626435775232657],
              [1.216250993402609, 52.625576122588704],
              [1.218180444083327, 52.62562710441513],
              [1.219370177804007, 52.625989564613604],
              [1.219466044295781, 52.626208196436167],
              [1.21854132561879, 52.626637150921745],
              [1.221694318673524, 52.627790675324839],
              [1.223338719998138, 52.628023805525828],
              [1.223355393788192, 52.630139515549352],
              [1.225208966571449, 52.631265372786814],
              [1.225339139465853, 52.632090124956527],
              [1.227422170120908, 52.632195232136816],
              [1.227841006541659, 52.631841160254602],
              [1.228408442305815, 52.631919217921961],
              [1.230544739633746, 52.630902228846281],
              [1.230295953877941, 52.630394590919146],
              [1.231110545951498, 52.630096257963295],
              [1.230829658680875, 52.629915061665749],
              [1.232710251259405, 52.627938209854904],
              [1.231832417493709, 52.627678885923899],
              [1.228716326985994, 52.62365929673927],
              [1.228451762681622, 52.621692441977402],
              [1.230586501649233, 52.620199731696758],
              [1.231432176488685, 52.618080260802806],
              [1.233356513096407, 52.616943987982772],
              [1.234274958531562, 52.617055594567873],
              [1.235013228647323, 52.61672783945766],
              [1.241224004674043, 52.617752255377802],
              [1.243524544444454, 52.617110947154949],
              [1.243195327547179, 52.615756549514153],
              [1.24370805949816, 52.615297318147704],
              [1.243516471766452, 52.614864583778278],
              [1.244511007568299, 52.614427218259792],
              [1.243924496740621, 52.613349681575329],
              [1.243194450922514, 52.613127763511656],
              [1.243085212158267, 52.612369903873422],
              [1.242110618114183, 52.611258025005156],
              [1.242396771855502, 52.61073215785855],
              [1.241663831746857, 52.609970698058881],
              [1.243608641492489, 52.608480243207012],
              [1.245497952814416, 52.608018339661065],
              [1.246004191410042, 52.607173451478012],
              [1.247733630575977, 52.606548616134674],
              [1.248638874515004, 52.60668385470278],
              [1.250831482800981, 52.606090326190056],
              [1.250970825312346, 52.605299561735265],
              [1.252869569430405, 52.604031454962609],
              [1.25487670758273, 52.601836694828691],
              [1.254919612324324, 52.600762581177584],
              [1.255817468626862, 52.600401521430321],
              [1.255672655887619, 52.599843407046393],
              [1.257763430418637, 52.598122951911385],
              [1.260586718331149, 52.597576624416469],
              [1.262450653835296, 52.597804949951929],
              [1.263790789532659, 52.600224145677416],
              [1.264655092457218, 52.600286642414318],
              [1.26694593566075, 52.601396184835615],
              [1.268405460423387, 52.601417937467033],
              [1.268800933037249, 52.601749670249838],
              [1.272741194175307, 52.60075039296251],
              [1.272796626241857, 52.600325260450504],
              [1.274240571327717, 52.600316793218681],
              [1.27480298246927, 52.599817373114917],
              [1.274430740501782, 52.598926513186427],
              [1.276684372847789, 52.598467510496171],
              [1.276865990356971, 52.598091044259256],
              [1.277844783644992, 52.597373223788864],
              [1.278103305253434, 52.597097152501249],
              [1.279476794958743, 52.597095968062284],
              [1.279708816881219, 52.596584094865946],
              [1.280590914509689, 52.596332103224093],
              [1.280369560465019, 52.595741961615403],
              [1.281473618872902, 52.595239182368289],
              [1.283443683366979, 52.595198021660231],
              [1.286378964781293, 52.596153502007603],
              [1.287414448136538, 52.596687741114081],
              [1.289208839349583, 52.59873519645808],
              [1.291694160238742, 52.599830765199421],
              [1.292663367976693, 52.601290452824884],
              [1.297250437249084, 52.603716029625346],
              [1.297866464629565, 52.605327617383928],
              [1.298320644298222, 52.605572178212867],
              [1.299662835917859, 52.604876423325777],
              [1.299770415010058, 52.605520968305456],
              [1.301957776834861, 52.606711874581919],
              [1.302711597926154, 52.608110041392088],
              [1.305269547168928, 52.608902917402588],
              [1.306767027526652, 52.608895258752611],
              [1.308864120791726, 52.610059783393233],
              [1.309071235492949, 52.610533356080559],
              [1.311133358259252, 52.612348164638938],
              [1.312363851156297, 52.612944779368163],
              [1.313386581577136, 52.613541520099815],
              [1.313908792022594, 52.613452250436964],
              [1.313466406052701, 52.61362022846987],
              [1.315202889403998, 52.615110419601116],
              [1.318529096715005, 52.614975825173182],
              [1.31949658937092, 52.615713157400947],
              [1.320342174741271, 52.615854920889795],
              [1.321377498946034, 52.618169631727731],
              [1.320891782474593, 52.618367670035205],
              [1.320513453404462, 52.619189481418367],
              [1.32113096178455, 52.619850280561842],
              [1.323032932840814, 52.620748427891975],
              [1.324376944153812, 52.622355634871802],
              [1.329621288217801, 52.62417783913488],
              [1.335040440303224, 52.624853584088754],
              [1.34303544802059, 52.624865463553981],
              [1.343583071604935, 52.624062140547899],
              [1.344474331277979, 52.623656522137964],
              [1.347022966374672, 52.623550297872846],
              [1.348134146808402, 52.62323826264592],
              [1.348923670957864, 52.621153651464233],
              [1.35064368642037, 52.620881022814764],
              [1.359965698129918, 52.622616957314889],
              [1.364672950210431, 52.623968103817283],
              [1.367820615401969, 52.623887569395144],
              [1.369505525923073, 52.623542815132275],
              [1.3730787108459, 52.620983914677133],
              [1.3759938953085, 52.620247838595546],
              [1.376501173429844, 52.618008288734771],
              [1.375964134161294, 52.616522536576582],
              [1.37616853607834, 52.614782691000407],
              [1.377455408630383, 52.611903911881811],
              [1.379769667262027, 52.609130684716646],
              [1.380018413914555, 52.607316711936022],
              [1.381138250231159, 52.605649633746019],
              [1.385042321217109, 52.604419175575053],
              [1.387523945557177, 52.60401538295865],
              [1.390516922076539, 52.604054675909566],
              [1.392073528710222, 52.604393203149073],
              [1.393945342634755, 52.605616663712098],
              [1.39926407649742, 52.607776325584268],
              [1.400498802449299, 52.609086339366705],
              [1.400873333965362, 52.611762958077456],
              [1.401755302950181, 52.613209012261329],
              [1.403373916643275, 52.614074455152902],
              [1.404211246790428, 52.614049471227631],
              [1.405937345425351, 52.613006874456637],
              [1.407763728943993, 52.613521810457065],
              [1.408263001644332, 52.614527343475174],
              [1.408205340343707, 52.616845840322995],
              [1.409103947130769, 52.618292263197695],
              [1.41004569717434, 52.619019720945154],
              [1.414753036175538, 52.620963383823671],
              [1.418677629013064, 52.620767332348485],
              [1.420704136437155, 52.62179524346832],
              [1.421812317301025, 52.621943985269922],
              [1.424334937507401, 52.620864594899651],
              [1.427354537707349, 52.618393779533662],
              [1.428974986936798, 52.617781115925958],
              [1.430606005409607, 52.617927215242901],
              [1.432817340742584, 52.619501793325036],
              [1.434071054121043, 52.619716351437354],
              [1.436029456874002, 52.618683657047448],
              [1.43654854645376, 52.617501163198625],
              [1.43599427436632, 52.616142075865184],
              [1.432881317983459, 52.613619667286805],
              [1.43226813024125, 52.612751546167786],
              [1.432547624466822, 52.611980748516253],
              [1.437869758950148, 52.609117250666472],
              [1.442088918076298, 52.604877130581386],
              [1.444005245552197, 52.603733090631387],
              [1.451680291055082, 52.601489379479894],
              [1.453499461841145, 52.600523398683627],
              [1.453821729278612, 52.599063250266475],
              [1.452464273523568, 52.596510781609872],
              [1.452531526043156, 52.59571374437477],
              [1.453634840200061, 52.594344125820022],
              [1.455268691687957, 52.59346716734504],
              [1.457081927627178, 52.593349439912039],
              [1.461022605877208, 52.593764875361018],
              [1.46285278927676, 52.593225640280593],
              [1.465963749880383, 52.590821261038755],
              [1.466398609797036, 52.590100684742907],
              [1.466489241599796, 52.587901669957731],
              [1.467089571420041, 52.587182537558895],
              [1.468737698650999, 52.586399405332415],
              [1.475075275536962, 52.585761003535509],
              [1.477885751640288, 52.58591495698456],
              [1.482152338238227, 52.58709359640411],
              [1.483507879228601, 52.587167014553131],
              [1.484980209890969, 52.586702724368671],
              [1.487481902181276, 52.584474900114053],
              [1.486986508405281, 52.581454875130035],
              [1.487896048426729, 52.580191363461239],
              [1.487569790731972, 52.579913169742724],
              [1.488164015848967, 52.580033259466923],
              [1.490812931852931, 52.578660857686067],
              [1.491437920810178, 52.577942665516737],
              [1.491677188649836, 52.575846256219251],
              [1.492249179890942, 52.574982118788981],
              [1.495658582508794, 52.57178201328184],
              [1.497827542661901, 52.570768105396354],
              [1.500565890045411, 52.570583660723706],
              [1.506194775246182, 52.572047587142976],
              [1.509513623320464, 52.573196658730303],
              [1.514677885641721, 52.575874703079421],
              [1.516848022969075, 52.575781418535094],
              [1.518370176337923, 52.575210887915773],
              [1.522375538242138, 52.572451865412113],
              [1.523795984193704, 52.5703219879911],
              [1.523680954891883, 52.56809753608799],
              [1.521467642163327, 52.566248577264858],
              [1.520684530147417, 52.565115229458669],
              [1.52073238676221, 52.563705303772913],
              [1.521778096646818, 52.562370901020351],
              [1.526024951317123, 52.559566766316053],
              [1.528832520322262, 52.559041406881427],
              [1.530868582765976, 52.559640817024359],
              [1.533432976437142, 52.561963905503923],
              [1.534622942073763, 52.562152305213075],
              [1.536015465638602, 52.561886720866688],
              [1.537973120086903, 52.560227186781937],
              [1.538715654889466, 52.556877075909696],
              [1.540312451482611, 52.555394690574609],
              [1.539861591027728, 52.554212842300558],
              [1.541065672047963, 52.553406879935942],
              [1.540876872851518, 52.552803617590385],
              [1.540247322448214, 52.552645278081741],
              [1.537906078754856, 52.552999565349808],
              [1.536400435221107, 52.551874440538406],
              [1.535272521995695, 52.551995409915456],
              [1.534118304289509, 52.552871776756994],
              [1.5331348056018, 52.553100838149916],
              [1.530651511122821, 52.552477946763254],
              [1.530230471528253, 52.552030908009499],
              [1.530639470442031, 52.551226300120675],
              [1.530468238768885, 52.550068449627936],
              [1.529855141194518, 52.549581272570656],
              [1.52704772410119, 52.548942798812647],
              [1.523476121451402, 52.543593339683234],
              [1.520019842009133, 52.542786056656716],
              [1.519060480393378, 52.541914284375792],
              [1.518124391366961, 52.542093864526045],
              [1.517447182770586, 52.5425298246099],
              [1.516763047417927, 52.542589748200406],
              [1.516499555552177, 52.542312412962353],
              [1.516292675320846, 52.542094660886562],
              [1.515811712811569, 52.542000046216913],
              [1.515021703732909, 52.541844632447344],
              [1.514539385903705, 52.541052183333186],
              [1.512870191488157, 52.540968669035927],
              [1.512426107313538, 52.540949481869838],
              [1.506704394651893, 52.54152854233751],
              [1.504537643949178, 52.541088202472849],
              [1.501095144176263, 52.539743007718748],
              [1.498533754102469, 52.539105577455906],
              [1.490742950038492, 52.538278109966228],
              [1.486960967452141, 52.538986014522948],
              [1.48635437695866, 52.538721483033839],
              [1.484001730987106, 52.538629829315525],
              [1.482355640551783, 52.537904818410361],
              [1.480690472893814, 52.537702009476455],
              [1.482035291650539, 52.537510413348905],
              [1.482273680614489, 52.537745336742489],
              [1.482226788552866, 52.537358168730592],
              [1.482716142652125, 52.537353650063444],
              [1.48258531342054, 52.537862077448537],
              [1.483160731193468, 52.538105960115992],
              [1.486898480443954, 52.538718930637707],
              [1.487385782409327, 52.538744134171466],
              [1.487145179452912, 52.538444527776662],
              [1.487925186196881, 52.538738117972983],
              [1.49088108212244, 52.538067165187812],
              [1.498650816867297, 52.538982490582818],
              [1.506754681111383, 52.541382243664941],
              [1.511251516908872, 52.540797286174922],
              [1.512210680637077, 52.54083805601843],
              [1.513070960018684, 52.5408412818107],
              [1.51455411223019, 52.540867363161908],
              [1.514927214753674, 52.54130868462623],
              [1.515133415143925, 52.541692006783023],
              [1.516391573501999, 52.542051715188833],
              [1.516772918696338, 52.542436552015708],
              [1.51828767287906, 52.541988748080406],
              [1.519044140484467, 52.541765465658941],
              [1.520297676185158, 52.542792176479331],
              [1.523749036132898, 52.543556425461048],
              [1.526903262636454, 52.5478965743212],
              [1.527142404244674, 52.54883564104172],
              [1.529853995841663, 52.549475174053377],
              [1.530572886021, 52.549938505730665],
              [1.530916227923883, 52.551071429832668],
              [1.530414659992419, 52.552046096276825],
              [1.530828935999674, 52.552445666568111],
              [1.533158171554297, 52.552987711272671],
              [1.535171932354373, 52.551937255720802],
              [1.536286779528857, 52.551745622955536],
              [1.537930832119, 52.552885496435039],
              [1.540327572504133, 52.552524150561453],
              [1.541118336799362, 52.552760410029045],
              [1.541245737703753, 52.553480637969116],
              [1.540054111556795, 52.554276335592803],
              [1.540589442193874, 52.555224500258269],
              [1.542978844767478, 52.55502701837306],
              [1.545319770596073, 52.555399382902628],
              [1.5477857022395, 52.556241053463047],
              [1.554199275318941, 52.559271147452669],
              [1.555932877963531, 52.55922892756265],
              [1.559147201302309, 52.557426003870951],
              [1.561224916817071, 52.556987489898276],
              [1.56325089451896, 52.557294332568105],
              [1.566525517023513, 52.558675195762198],
              [1.568937159540986, 52.55898650056119],
              [1.570744640986766, 52.558927442255744],
              [1.574296654857245, 52.558253492127456],
              [1.578688426226216, 52.556096029615894],
              [1.626955094811251, 52.528427055245572],
              [1.628194784698618, 52.526596397965243],
              [1.627877546463961, 52.523859191761346],
              [1.628184317092724, 52.523019577869682],
              [1.629194147231948, 52.521747328750926],
              [1.63088853060882, 52.520498119380541],
              [1.639209034738036, 52.516894467509751],
              [1.645016799634772, 52.515702727352647],
              [1.646273639440823, 52.515159365632449],
              [1.646525169911751, 52.514102832591362],
              [1.646904714280126, 52.514003875252207],
              [1.646869181846738, 52.512881542095549],
              [1.644909745399094, 52.509997138975933],
              [1.644808089741307, 52.50860970397131],
              [1.646889926180321, 52.506639525146277],
              [1.649992756015914, 52.505323173201816],
              [1.64991697194615, 52.504889273148741],
              [1.650564081111263, 52.505067170182869],
              [1.651519209737586, 52.504515975906791],
              [1.653965378660843, 52.501312034048901],
              [1.657203476567136, 52.500584050888314],
              [1.65884262047065, 52.50071051255668],
              [1.663894400674981, 52.501764695627116],
              [1.669185009514145, 52.503576699985949],
              [1.671828148726015, 52.503686201320491],
              [1.673086771200283, 52.503119103086796],
              [1.674112706797107, 52.502078007653772],
              [1.675572908224031, 52.497730431143765],
              [1.676941537502595, 52.497053729513887],
              [1.68033996079695, 52.496418156447419],
              [1.682038938337694, 52.49523459636211],
              [1.682202031703572, 52.493220089708004],
              [1.681567544059333, 52.492910650178771],
              [1.682056065758599, 52.49274341901706],
              [1.681985362675011, 52.490717307463079],
              [1.680760739141663, 52.489499791340258],
              [1.676948990536524, 52.487331994537342],
              [1.675772852925322, 52.484256407776698],
              [1.673545206633387, 52.483302980586146],
              [1.672347651032507, 52.483146817442389],
              [1.671943717667753, 52.483479582290975],
              [1.672207719123745, 52.48307650992237],
              [1.67168643082536, 52.482849851128613],
              [1.671657324482844, 52.483295994334767],
              [1.670576230178207, 52.482981485362743],
              [1.670370241976459, 52.482754920506657],
              [1.670892219313022, 52.482548018027302],
              [1.671489363188175, 52.482748037629527],
              [1.671019903933139, 52.482383045823575],
              [1.670280127078276, 52.482681264423974],
              [1.669649603239716, 52.482098200908034],
              [1.670305200755, 52.481974400058881],
              [1.670813226612465, 52.482289625028002],
              [1.670589438602613, 52.481690332565684],
              [1.669058479170574, 52.481934863624602],
              [1.670478573012388, 52.481491395130071],
              [1.671087255341386, 52.479213911098199],
              [1.670495794199832, 52.47909916337855],
              [1.671129606864049, 52.479032700789375],
              [1.671334689487083, 52.478188020441252],
              [1.671057768592218, 52.477056992353106],
              [1.669819979675942, 52.476221155075578],
              [1.663614551789207, 52.475112187764289],
              [1.662776890397115, 52.474745988110321],
              [1.661350977065224, 52.472955261642795],
              [1.660649775849063, 52.470614079015881],
              [1.659016798121556, 52.46875688452333],
              [1.657900656339938, 52.468298535305493],
              [1.65610376955144, 52.46810230090594],
              [1.654771221120354, 52.468070671209681],
              [1.65369606895553, 52.468407944573869],
              [1.651277386512822, 52.470803342779455],
              [1.649386969712544, 52.471683859821965],
              [1.648697498250351, 52.473603029073047],
              [1.649002005900324, 52.471801886467119],
              [1.646857537482382, 52.472176611717096],
              [1.64454771655131, 52.471460847957843],
              [1.643487389221353, 52.472709627360523],
              [1.643135187732133, 52.472582869019497],
              [1.643640009229133, 52.472489049236387],
              [1.644306509449707, 52.471411621441035],
              [1.642849648091535, 52.468790529467576],
              [1.641646882673077, 52.468190784750973],
              [1.638093040934607, 52.467762386247401],
              [1.635734959819668, 52.466689053990549],
              [1.634969626528807, 52.465534303937361],
              [1.63497435216625, 52.463520257499042],
              [1.633521875201049, 52.462871449019438],
              [1.630631459441348, 52.462910835514229],
              [1.626681039003926, 52.462523968023241],
              [1.618223037854807, 52.463445295930512],
              [1.616267233034672, 52.463873953166576],
              [1.614210663802611, 52.465175436897944],
              [1.611267020327495, 52.466166702363616],
              [1.607203164540788, 52.466734273880114],
              [1.606022089841128, 52.467454812511079],
              [1.605258624339075, 52.46843783078333],
              [1.604621026277779, 52.471446176941072],
              [1.6040840732612, 52.471814243523639],
              [1.602086492407475, 52.472044254010768],
              [1.602210980676531, 52.472269820073066],
              [1.601838482782781, 52.472073401197854],
              [1.598392862152893, 52.473022970169225],
              [1.59794902941168, 52.473540179797361],
              [1.597592232349449, 52.473463796411821],
              [1.596377485832474, 52.475255608781481],
              [1.587598490166029, 52.478765894900882],
              [1.586281352188029, 52.478890424100832],
              [1.585211920230682, 52.478655738338674],
              [1.583107423505005, 52.477087067596521],
              [1.580034376901799, 52.480147492001073],
              [1.582961250201272, 52.477028538313014],
              [1.575880151642136, 52.476754631432698],
              [1.573125063218113, 52.475204574198813],
              [1.569362593030806, 52.475344368076051],
              [1.567668231384838, 52.474666085060861],
              [1.566834106718684, 52.473223369732843],
              [1.565260089072515, 52.471973870350823],
              [1.565908389543165, 52.469974626591863],
              [1.56571583942912, 52.468687033389223],
              [1.562016967665826, 52.466527496393567],
              [1.561440256886076, 52.465514116027343],
              [1.560671748069962, 52.465498601548887],
              [1.560546752183318, 52.465209149043709],
              [1.561518514398353, 52.465206845094222],
              [1.561709681246758, 52.464674906913316],
              [1.561169220775688, 52.464893572723938],
              [1.561063340466412, 52.464585555676528],
              [1.56184885043162, 52.46448362694268],
              [1.562102901951292, 52.464073915824144],
              [1.562320426351175, 52.461499439020834],
              [1.559234624125856, 52.458479455267295],
              [1.557444533020876, 52.457772437402546],
              [1.554531391412213, 52.459242562412449],
              [1.551250567223478, 52.45927645994778],
              [1.549939106647886, 52.458843663480117],
              [1.549193566001802, 52.45892902267277],
              [1.548101870069394, 52.459836070272566],
              [1.547937716708015, 52.461181169376637],
              [1.545406548192307, 52.46182289806049],
              [1.544094903023555, 52.463164640317018],
              [1.543095753145891, 52.462788957766357],
              [1.542365706357947, 52.461840350283744],
              [1.541336249464069, 52.461911684675329],
              [1.538675100731599, 52.46319216762415],
              [1.53844400484019, 52.464275718264439],
              [1.536420881620918, 52.465361648179048],
              [1.535959646598862, 52.466679638633401],
              [1.535486576778117, 52.466381689039004],
              [1.532436451556283, 52.467720433897114],
              [1.529907225821293, 52.468412132594096],
              [1.528085099316715, 52.468499830663255],
              [1.525100919013494, 52.468126583821892],
              [1.522216535354325, 52.465758037317748],
              [1.519316485111621, 52.466214116498847],
              [1.517673975984597, 52.470082923674767],
              [1.517256543239661, 52.470204181049041],
              [1.517616777397397, 52.470141290765596],
              [1.517445798219615, 52.470766990007718],
              [1.516048358373517, 52.471600980338707],
              [1.515638554749539, 52.471578095361679],
              [1.515793363466103, 52.471321648067885],
              [1.517332175211365, 52.470505938536874],
              [1.515753526552093, 52.470453080948324],
              [1.517138311977382, 52.470291347571951],
              [1.517677651699435, 52.469723040549255],
              [1.518844018668399, 52.466291147977799],
              [1.523275076399986, 52.465183219299512],
              [1.523142090312615, 52.463832633777251],
              [1.521163627793867, 52.461905655169041],
              [1.518984518624919, 52.462056020936728],
              [1.516677016593716, 52.463402818065205],
              [1.51661737696419, 52.464002717073583],
              [1.516081470979237, 52.464078028057841],
              [1.512549552263328, 52.462979633104894],
              [1.508775788293639, 52.462633046813444],
              [1.508033505858313, 52.461798827210743],
              [1.506768767002708, 52.462522636828375],
              [1.501662033897302, 52.463375643190055],
              [1.501559826489536, 52.463998379922096],
              [1.499779424431296, 52.464275984569063],
              [1.49789707471143, 52.465217660964278],
              [1.498419215385494, 52.46597031618812],
              [1.498187609006723, 52.466354933598062],
              [1.497092309003841, 52.466359472302706],
              [1.496379609363783, 52.465766252604453],
              [1.49512284835026, 52.466037284502967],
              [1.496442041797023, 52.467333009932993],
              [1.496204631038766, 52.467737582286922],
              [1.494398575152901, 52.467749631516732],
              [1.492807991345854, 52.468455039375925],
              [1.490430073814446, 52.468889571334344],
              [1.488157010197723, 52.468621205439483],
              [1.483380748643562, 52.471823744332376],
              [1.481448497170344, 52.471843810243229],
              [1.481219875873149, 52.472380306807231],
              [1.481152525153313, 52.471863322671076],
              [1.480367514593509, 52.471826183993812],
              [1.480050686751055, 52.471842710199397],
              [1.477543022589136, 52.471028800998383],
              [1.47475069950367, 52.469610702765813],
              [1.473492511843502, 52.469231260075325],
              [1.47180360432567, 52.467440672950524],
              [1.470276169242841, 52.466745315090634],
              [1.469312775701145, 52.46586248883758],
              [1.46626988543357, 52.465295210543857],
              [1.46408933260326, 52.464325719993219],
              [1.463995603430627, 52.463451531710142],
              [1.462377177615342, 52.460656794112296],
              [1.460663996801373, 52.459484668886176],
              [1.459444049684487, 52.459080575943496],
              [1.458744168694639, 52.458117098695084],
              [1.455884181437292, 52.458240357132205],
              [1.453943088511536, 52.457632443274491],
              [1.45233442833388, 52.457546342254538],
              [1.450587851073878, 52.456619692715265],
              [1.446487393927396, 52.455997349604651],
              [1.441071764036972, 52.456688547594418],
              [1.439199081094872, 52.457935696356373],
              [1.438453515989877, 52.457909725833012],
              [1.438401132678574, 52.458314188497461],
              [1.436922019365863, 52.459228754584501],
              [1.436129678951708, 52.46035987813665],
              [1.434521009705801, 52.461578584379929],
              [1.434241409073666, 52.462280159460143],
              [1.434753359080872, 52.462708690725798],
              [1.433979680198154, 52.463438116805179],
              [1.434704269066714, 52.463666195217996],
              [1.434868360707637, 52.464581526407983],
              [1.432669985267121, 52.465661745956467],
              [1.432467740217918, 52.466109232925248],
              [1.430964823086554, 52.466363341680278],
              [1.427773242853673, 52.467892325799838],
              [1.427190895559163, 52.469153103432134],
              [1.426602769601387, 52.469525426296762],
              [1.427178893077901, 52.469983608803417],
              [1.426150485380484, 52.470641218873659],
              [1.422587089634725, 52.471200477763901],
              [1.420122635014584, 52.471023590469407],
              [1.417930208363709, 52.470138144343395],
              [1.417439823068455, 52.469037058710015],
              [1.416404755282181, 52.468375341388885],
              [1.414035827741106, 52.465246404875501],
              [1.410796066706955, 52.463932386379341],
              [1.411580960746306, 52.462886201314575],
              [1.410826976541315, 52.462181244889614],
              [1.410990553070129, 52.461573919254633],
              [1.414613553803776, 52.459815266170914],
              [1.417365045528317, 52.459011697174006],
              [1.420269447066612, 52.45870011183456],
              [1.421146640526395, 52.458050525809341],
              [1.425757591705249, 52.457248668950037],
              [1.427883338240238, 52.457472132532516],
              [1.430979403961143, 52.456863310705181],
              [1.433240894356213, 52.455936881755612],
              [1.433829778153607, 52.455407102768859],
              [1.433021165372535, 52.454627422232505],
              [1.432769077789079, 52.453204676696103],
              [1.433428570412216, 52.452750197437119],
              [1.433706809402185, 52.451124063564549],
              [1.434288123227551, 52.450857130528817],
              [1.434086526968422, 52.450234298658003],
              [1.434836805665779, 52.449944463252386],
              [1.43493350802888, 52.448010605927315],
              [1.434302501920572, 52.447430838001956],
              [1.433397382957689, 52.447376197059519],
              [1.433118135594682, 52.446587429449472],
              [1.432077976315139, 52.44608339713885],
              [1.432661360401213, 52.445578965416843],
              [1.432192784982886, 52.445156362152176],
              [1.430051425970155, 52.445141200886098],
              [1.427738673159242, 52.445685912278904],
              [1.426213479107142, 52.445398268623549],
              [1.425162688449028, 52.44625349922881],
              [1.423841094136078, 52.446559830994289],
              [1.421531342391689, 52.44612037412643],
              [1.422096729479582, 52.445439332895397],
              [1.421859665018106, 52.445210552976249],
              [1.420763277090948, 52.445668624208501],
              [1.419482336272283, 52.445208329896452],
              [1.418666102365197, 52.445616000228164],
              [1.416205654763759, 52.444920870652368],
              [1.414188056444868, 52.445477466221917],
              [1.412385401306287, 52.444912549376205],
              [1.411821105731273, 52.444201223080128],
              [1.41026429502054, 52.444721059167215],
              [1.407886368013493, 52.444386737762166],
              [1.406264676718957, 52.443628532209821],
              [1.407353311829921, 52.442096925012883],
              [1.405779281789789, 52.442279020922321],
              [1.404796141202867, 52.4414403275347],
              [1.402356108949474, 52.441557391319485],
              [1.401863111293597, 52.442073457888384],
              [1.39798099889007, 52.441048324678363],
              [1.397294544044667, 52.44071549835229],
              [1.39669248176635, 52.439356715986328],
              [1.395269660207227, 52.439628762628345],
              [1.393239151605391, 52.438945988428848],
              [1.393138846265737, 52.437947829482567],
              [1.392026339338512, 52.437776510902822],
              [1.3909495330983, 52.436920608306792],
              [1.3909375572624, 52.436611556205222],
              [1.392454984284914, 52.43600136546538],
              [1.392092219733892, 52.435551285711213],
              [1.393154799674049, 52.43505487812061],
              [1.392990672952223, 52.434604492034758],
              [1.391952564367835, 52.434308717527635],
              [1.392043895914269, 52.434022781947462],
              [1.386258283597678, 52.432053572865534],
              [1.385214201366479, 52.431985461063604],
              [1.383022350055285, 52.43247366809269],
              [1.382367823613144, 52.431690140660777],
              [1.379940819651973, 52.432028526401176],
              [1.378556309082121, 52.431050004860673],
              [1.377693245720272, 52.430943371785524],
              [1.375707968906815, 52.429842585889133],
              [1.374969232051547, 52.430322389081304],
              [1.373716749508822, 52.430048770089023],
              [1.372571941586324, 52.43043312508668],
              [1.371258583419535, 52.430017313645173],
              [1.371483512252234, 52.42926349092042],
              [1.370469062429758, 52.428483875989848],
              [1.368247164600938, 52.428828761741215],
              [1.368419997921325, 52.427892952644214],
              [1.367751904838004, 52.427621494074941],
              [1.368520318592704, 52.42731807230669],
              [1.368116933226567, 52.427025576341364],
              [1.364274993876778, 52.427078392216245],
              [1.363731394449084, 52.427494105701278],
              [1.36348171913104, 52.426939988636917],
              [1.357912202956618, 52.424223054154034],
              [1.357474029398979, 52.423781311947906],
              [1.357412464393096, 52.422139858088308],
              [1.356343306700947, 52.421157517000822],
              [1.356794536937614, 52.419217280763853],
              [1.358549596193473, 52.418135780128466],
              [1.358890271994248, 52.417393081532119],
              [1.358568626055392, 52.416974112685232],
              [1.359756878748793, 52.416835090563282],
              [1.360634389766156, 52.416382012657593],
              [1.360753794717469, 52.415496302582824],
              [1.360206176488805, 52.414620575616048],
              [1.361227328958818, 52.413175863057866],
              [1.357252761357057, 52.411526074703488],
              [1.356578984925691, 52.410653018336696],
              [1.355611437798254, 52.411120317365203],
              [1.354919635921032, 52.410667784112761],
              [1.353894868379914, 52.410865974638803],
              [1.352352197979447, 52.410674126596362],
              [1.350254393869909, 52.409484382730668],
              [1.348372385956815, 52.408850609758517],
              [1.346281783319538, 52.408976413488844],
              [1.346599152305181, 52.407788309670039],
              [1.346071322207131, 52.406392107414788],
              [1.34716418568971, 52.405676698423456],
              [1.346607485573744, 52.404889310381229],
              [1.347072552007486, 52.40438955666302],
              [1.346378632699884, 52.403906455630597],
              [1.344786317240831, 52.403696131494094],
              [1.34178828045671, 52.402203453373104],
              [1.340251038361504, 52.402331483043604],
              [1.337863570281619, 52.4016965516631],
              [1.335696517853999, 52.40189628375078],
              [1.335183944809399, 52.399722526689438],
              [1.333428283037823, 52.398494050118217],
              [1.333336557837176, 52.397044113173166],
              [1.331701359866478, 52.395441660765975],
              [1.33143906934202, 52.394692667423101],
              [1.330222738934473, 52.394296161920252],
              [1.330576843682396, 52.393532481687004],
              [1.325954897243662, 52.391621111749295],
              [1.325000614849234, 52.39099322380082],
              [1.32468889706657, 52.390288779307205],
              [1.322675545249358, 52.389084492049122],
              [1.321684082031097, 52.388997261380666],
              [1.319982729675601, 52.389537972716262],
              [1.317560842982451, 52.388391853049924],
              [1.316142608493662, 52.388415497919134],
              [1.31634323228604, 52.387386353823949],
              [1.31316154844335, 52.388220359419307],
              [1.311599100511118, 52.387829777977771],
              [1.308573177353918, 52.388527079725307],
              [1.308119720247223, 52.389440973848089],
              [1.306468893811517, 52.390325438996328],
              [1.304601588517911, 52.391009078082611],
              [1.302630743270121, 52.390602823208731],
              [1.30148757019604, 52.391692470059979],
              [1.300029013126224, 52.391374379583922],
              [1.299029501711477, 52.391789941995881],
              [1.296236684915215, 52.391715929101693],
              [1.295426927536048, 52.390615191142459],
              [1.293139565182596, 52.389510691479941],
              [1.292023468366764, 52.389545406617074],
              [1.291314678412349, 52.390134475214261],
              [1.290574155498396, 52.389981529299845],
              [1.289057118358885, 52.390910585445681],
              [1.28753294680947, 52.390934137319192],
              [1.284943696717216, 52.390176053549048],
              [1.283126909816942, 52.388971031930375],
              [1.28124428997054, 52.388541284175126],
              [1.28102078138839, 52.387629221324765],
              [1.279177494294821, 52.386777436240408],
              [1.279254934873095, 52.385958643888344],
              [1.27845788075058, 52.385061602396313],
              [1.276479571527628, 52.384388905170304],
              [1.275787668134993, 52.383541093230711],
              [1.274820263930083, 52.383497772788296],
              [1.272483201519695, 52.382285434302425],
              [1.272286798610001, 52.381538092196884],
              [1.271476082718711, 52.381148636830254],
              [1.2708036805288, 52.380025944556735],
              [1.269755011287278, 52.380214177240454],
              [1.268170110012027, 52.379371259321957],
              [1.268193283781171, 52.378193324706778],
              [1.267572602264308, 52.37743073583907],
              [1.266505878914223, 52.376896335500199],
              [1.265552726609856, 52.375551138771286],
              [1.264335285524459, 52.37502448500819],
              [1.264020754192273, 52.374525927624056],
              [1.262831792961989, 52.374324947553653],
              [1.262131419490785, 52.372758686332247],
              [1.259302893612772, 52.370866248128898],
              [1.258314886685747, 52.370539159406164],
              [1.255784420930043, 52.37101548038315],
              [1.253860864386495, 52.370534270778812],
              [1.253011730978268, 52.370036925604509],
              [1.252079313195867, 52.368366377340919],
              [1.249988354120835, 52.366464198236727],
              [1.248305341860253, 52.365793705132951],
              [1.247165441669268, 52.366317020429292],
              [1.247328621900939, 52.365817873517024],
              [1.246834971133197, 52.365437520705434],
              [1.245407540137482, 52.365305882289277],
              [1.244443366390984, 52.366041990702016],
              [1.243371517493176, 52.365730573505417],
              [1.242414677994478, 52.366005981233762],
              [1.240349252765932, 52.365789270591655],
              [1.238327431981809, 52.364883303678731],
              [1.236634239294952, 52.365375821428195],
              [1.234421688620096, 52.364833868359732],
              [1.232989168793781, 52.364933359546988],
              [1.233133482150318, 52.361921890213971],
              [1.231779142850694, 52.36170264612219],
              [1.230311358479825, 52.360230960232215],
              [1.227954986987021, 52.360008505193335],
              [1.226027555841172, 52.360450614764197],
              [1.225136685875313, 52.359897553595097],
              [1.224300644566248, 52.359995935383068],
              [1.222980814751395, 52.359522924403457],
              [1.222036496068282, 52.358456856718291],
              [1.220851911927945, 52.359094457370155],
              [1.220295509077746, 52.358453090872288],
              [1.220650902444423, 52.358027885654984],
              [1.219781978172569, 52.357492174006403],
              [1.217963337863959, 52.357005728919091],
              [1.216984669377143, 52.357404740136211],
              [1.216285307679452, 52.357341950732923],
              [1.214944671702874, 52.355730810806328],
              [1.214050488866789, 52.3553918093425],
              [1.212612158875003, 52.355367100559789],
              [1.208295068363989, 52.35706738805635],
              [1.206020293990059, 52.357338733606653],
              [1.20257699554904, 52.359731875010276],
              [1.201755009818876, 52.359558105225062],
              [1.200884303165209, 52.358730908867017],
              [1.20014636864911, 52.35980047738505],
              [1.196828219405941, 52.360577483301057],
              [1.19590688709038, 52.360695959555486],
              [1.195312289716157, 52.361433346360798],
              [1.19289397486135, 52.361042786929936],
              [1.191358385972211, 52.361729149140665],
              [1.190176782308697, 52.361783564620218],
              [1.189042266849679, 52.361023668078928],
              [1.18785115227804, 52.360925424852155],
              [1.186988750361211, 52.35982269794124],
              [1.182348434204592, 52.358763392808989],
              [1.182206540264629, 52.359343711034391],
              [1.180442870605749, 52.35949465866431],
              [1.179481700878314, 52.360347062457613],
              [1.177273195693117, 52.360973112925592],
              [1.175409580662301, 52.360039341835176],
              [1.174791667008817, 52.360026279201151],
              [1.174174074137625, 52.360468283130551],
              [1.173263473912846, 52.35987669489753],
              [1.172831763971276, 52.360329884377748],
              [1.172150804643815, 52.36042102950001],
              [1.170838092157614, 52.35989598591221],
              [1.167546296192344, 52.360227169064913],
              [1.163030747895076, 52.362140649217125],
              [1.161334414031233, 52.362167192088776],
              [1.160943198315955, 52.361441088790571],
              [1.15702737730633, 52.361285038096732],
              [1.155431919210677, 52.361530941145269],
              [1.150834226217955, 52.363475820813576],
              [1.150474913244993, 52.364578129270896],
              [1.147657173618306, 52.364785481326976],
              [1.14658135103338, 52.36453175627593],
              [1.146083274474671, 52.365047768954668],
              [1.144616834600984, 52.364982505625058],
              [1.143575481393031, 52.366413230625511],
              [1.141520386179721, 52.366520985471773],
              [1.141072796023411, 52.366838673750941],
              [1.139092381106636, 52.367150351959964],
              [1.136793872171547, 52.367244708713194],
              [1.135929222310751, 52.366943906395441],
              [1.133917928270008, 52.367131306985399],
              [1.132661219195331, 52.367808591025373],
              [1.13184453884709, 52.367479505733819],
              [1.131435875313784, 52.367600964249561],
              [1.130305516255719, 52.367718171798799],
              [1.127805574990347, 52.367125190251016],
              [1.122909021805676, 52.367275473419483],
              [1.120843979615528, 52.369367999607057],
              [1.119424259639336, 52.369716687525688],
              [1.118354005657604, 52.369478746786392],
              [1.117301290741014, 52.369698103101129],
              [1.116190133375565, 52.371027891469581],
              [1.115029041937029, 52.371336424412718],
              [1.113628402731262, 52.371101759201402],
              [1.111431500928158, 52.371662400153433],
              [1.104655860839939, 52.371237266814539],
              [1.102137798484359, 52.370718865226202],
              [1.100512153093616, 52.371423478084459],
              [1.096318469298083, 52.371798817111213],
              [1.082454373196034, 52.373074346120944],
              [1.080652993698087, 52.372653693237034],
              [1.079154672432175, 52.372364509730623],
              [1.075392837020417, 52.373121752669],
              [1.073038076038328, 52.375627459790948],
              [1.069685495254095, 52.376718313622291],
              [1.070477982556948, 52.377719362498695],
              [1.069493281624593, 52.377802517333961],
              [1.068463388534002, 52.378441730550868],
              [1.065187626090961, 52.378631118169039],
              [1.064619939409029, 52.377946165001013],
              [1.062142838478072, 52.377338612149451],
              [1.059011301081686, 52.377232703880559],
              [1.056158562768356, 52.377014275875098],
              [1.053205576744316, 52.377280415151965],
              [1.051286755535093, 52.378095340715376],
              [1.050114402465225, 52.378164295287483],
              [1.048734777627544, 52.378019151136591],
              [1.048235901191166, 52.377667797535935],
              [1.044138353697045, 52.377788773766099],
              [1.037693763514368, 52.377308089463725],
              [1.037061613247696, 52.379080744155502],
              [1.035236684125681, 52.379020637771319],
              [1.033975698892821, 52.378355156240985],
              [1.029928311941614, 52.378716269265709],
              [1.027215307255494, 52.384789768342962],
              [1.025399045641918, 52.386170011191631],
              [1.023550118383972, 52.389632107599788],
              [1.0229223875119, 52.392576387665933],
              [1.020206268494616, 52.394197207133047],
              [1.020710675379208, 52.395339944908265],
              [1.023581167081469, 52.397093039175495],
              [1.02319592203936, 52.397758509354865],
              [1.024328344906964, 52.398623453654331],
              [1.023962362834275, 52.399291130994591],
              [1.021713287059328, 52.40039370213546],
              [1.021328937567872, 52.400944028602851],
              [1.02309414518942, 52.403557278688176],
              [1.022869010825673, 52.404629641537966],
              [1.02138899704443, 52.405951741935006],
              [1.02310978435065, 52.408406947559861],
              [1.02646751316089, 52.408778733731694],
              [1.028748990922584, 52.410010799430012],
              [1.029508377049368, 52.410812414543166],
              [1.03137413999613, 52.410248340881012],
              [1.034074530545702, 52.415756653565097],
              [1.028224857637736, 52.414700736589722],
              [1.028190060745424, 52.417261112300544],
              [1.024364689508243, 52.415912082035305],
              [1.023917715117797, 52.415463068287707],
              [1.021552886777777, 52.415422847743301],
              [1.021240392488344, 52.416358942984914],
              [1.028256765376647, 52.419671396127974],
              [1.03484075005877, 52.422027771790034],
              [1.042772253488882, 52.425633333816634],
              [1.045696377189286, 52.420891372834312],
              [1.045391954558911, 52.419932440313204],
              [1.051862361126764, 52.41918361420494],
              [1.057047519489495, 52.420036129866965],
              [1.058891922782643, 52.424514732781759],
              [1.059086825191045, 52.426499909042711],
              [1.058742267509794, 52.426835286860531],
              [1.067473730194343, 52.428805100644247],
              [1.065549521246155, 52.432049470751245],
              [1.062847969936471, 52.43319243221957],
              [1.065172463940136, 52.436726788764467],
              [1.065934919271252, 52.44083944037105],
              [1.064067362043652, 52.442089386565094],
              [1.073148527878037, 52.454758997252284],
              [1.094622315905832, 52.460256591465225],
              [1.095260715983508, 52.463982011757977],
              [1.096793921570143, 52.4656649781734],
              [1.09595976384638, 52.469156467854624],
              [1.095249215939276, 52.470362199491149],
              [1.091716092774861, 52.468783702790006],
              [1.090613304626417, 52.468681240273497],
              [1.087978834376509, 52.470404869759655],
              [1.085588825531528, 52.470995192469218],
              [1.084471539219223, 52.478978048657304],
              [1.087407149931102, 52.48074504502236],
              [1.087671889472946, 52.481222870594621],
              [1.082478541597028, 52.484661646818942],
              [1.079364496064264, 52.499268771666301],
              [1.079344880311166, 52.500863791999933],
              [1.080962316849616, 52.503129333591815],
              [1.080914155822411, 52.504989500367046],
              [1.079025793186815, 52.506858928572754],
              [1.080529930972117, 52.508155253406564],
              [1.083915453626144, 52.512762397564543],
              [1.085475604048665, 52.512455497481831],
              [1.089504426784764, 52.514559056608157],
              [1.091657465232802, 52.516480410612026],
              [1.095231647949885, 52.518000309279572],
              [1.092882735531139, 52.52120312485016],
              [1.089696029387865, 52.522096721084147],
              [1.085186667380242, 52.522611951614557],
              [1.081181044008985, 52.525205718348914],
              [1.079062006733693, 52.52796248188222],
              [1.073630205211505, 52.530692028344099],
              [1.07004007924034, 52.531736758767273],
              [1.063752700995637, 52.535197608299036],
              [1.062481632298101, 52.536556125597201],
              [1.061376321054226, 52.537161204056488],
              [1.056007679341257, 52.535440245230291],
              [1.041817177066588, 52.536145085705321],
              [1.037944694787685, 52.53664210993449],
              [1.031965540689355, 52.538051871877805],
              [1.029225621916594, 52.53970666393365],
              [1.028199512594993, 52.541233891343396],
              [1.023775924162769, 52.542347990084373],
              [1.016379024967151, 52.542331803166647],
              [1.015999771780396, 52.542931421092888],
              [1.012051249555257, 52.542912422736585],
              [1.012042402032201, 52.54321391659969],
              [1.011333530770246, 52.543287721975283],
              [1.007324326435205, 52.548183028980645],
              [1.004890822510165, 52.549767395956117],
              [1.001817786075635, 52.549313025890704],
              [1.001825253959983, 52.549595218557847],
              [0.989569162073558, 52.551705091775283],
              [0.986151953372473, 52.55120055708575],
              [0.983140645021299, 52.552415047315833],
              [0.981213157518411, 52.552535580173839],
              [0.977332768593388, 52.55342558657874],
              [0.976195828541536, 52.553935320215523],
              [0.976005017263574, 52.554429339680631],
              [0.973486018336467, 52.554771462559735],
              [0.968257710493288, 52.55873466584822],
              [0.967337410441453, 52.559855794230423],
              [0.966026772034029, 52.559894953996761],
              [0.964271006275361, 52.560619725440937],
              [0.960848396548283, 52.561289071623008],
              [0.960016306563946, 52.562408831896136],
              [0.952684077600458, 52.567597514479566],
              [0.949930161677358, 52.574301188466151],
              [0.947005458876417, 52.575810234039764],
              [0.950057331029672, 52.57786461114268],
              [0.955416007777758, 52.580278593539752],
              [0.958478659051266, 52.579809072629331],
              [0.960325984499387, 52.579948127581837],
              [0.960822968922049, 52.580619157114732],
              [0.961571803056482, 52.580646282352781],
              [0.962165623637027, 52.581154808535075],
              [0.962557169241844, 52.583457092127119],
              [0.95368946328119, 52.584919782916508],
              [0.954563314121263, 52.586913282558683],
              [0.962857780421229, 52.585920825939304],
              [0.962322970173257, 52.586725593074085],
              [0.96274216873859, 52.589020887802853],
              [0.961548036147647, 52.591062495563591],
              [0.961602064720725, 52.592454148286414],
              [0.961860292143181, 52.593107766965566],
              [0.963755241139476, 52.594621500682891],
              [0.971851761813537, 52.595136105449335],
              [0.975385762744194, 52.594396221574613],
              [0.979588611450904, 52.592633979759938],
              [0.984789849104702, 52.593332966840684],
              [0.987518918724033, 52.593030288965906],
              [0.993100763252159, 52.591620338396105],
              [0.995103623935945, 52.591865536052389],
              [1.000002409542951, 52.59180980151006],
              [1.002376129964167, 52.591280168920484],
              [1.009893483276458, 52.587872405599832],
              [1.021789529370707, 52.586534134966563],
              [1.027005138762316, 52.589390134473852],
              [1.032101858649884, 52.588847772357802],
              [1.037603105528648, 52.587431468620778],
              [1.038132265192728, 52.588442198282749],
              [1.039463102261372, 52.588149921652466],
              [1.040041752249559, 52.590833887409005],
              [1.039791353760822, 52.592676710257535],
              [1.040304171677993, 52.593833538685395],
              [1.03913339139944, 52.594306970312012],
              [1.039119133926022, 52.595636515607438],
              [1.037587547879314, 52.595976205953363],
              [1.037679376411414, 52.602593658967372],
              [1.038021699831883, 52.604188338784205],
              [1.039409535313203, 52.606012476318057],
              [1.037288015572617, 52.608995082954102],
              [1.037310451725308, 52.610236450594513],
              [1.036198678734896, 52.61212384198349],
              [1.035746624171078, 52.615048290678821],
              [1.036918677622941, 52.615288916893995],
              [1.036878442461662, 52.615774675413611],
              [1.034536311005902, 52.616069460907788],
              [1.034326945204805, 52.616502897671388],
              [1.033034563123295, 52.616552207384814],
              [1.032406627260614, 52.616937010753283],
              [1.029972388758358, 52.61731140457173],
              [1.028517861714613, 52.616361191037271],
              [1.028187127846812, 52.615572868568869],
              [1.025556343220804, 52.614596948623316],
              [1.024379433323535, 52.614927392679064],
              [1.022316769042978, 52.614604167851915],
              [1.018843416404128, 52.615722503705442],
              [1.019627117641297, 52.61624659220417],
              [1.020093103538596, 52.619580109785417],
              [1.021579580603847, 52.62324640312859],
              [1.025579771659744, 52.630011308244988],
              [1.02522659673088, 52.635986303749739],
              [1.023277383934324, 52.636465061551831],
              [1.024193791440679, 52.639012759254072],
              [1.023530644924797, 52.639728453001062],
              [1.026054727722498, 52.641513831079671],
              [1.03158246082759, 52.642562200503086],
              [1.033753208417529, 52.643550689972557],
              [1.037127369631854, 52.644216903502979],
              [1.04307332154731, 52.646487859377622],
              [1.042735388761413, 52.649179168746713],
              [1.044960030286834, 52.648701105790714],
              [1.047315809115276, 52.652212539857992],
              [1.049046870802619, 52.651864911799699],
              [1.050791790466387, 52.65600443504767],
              [1.05146654740812, 52.655988847401446],
              [1.051698407855247, 52.65653594501677],
              [1.052845604020259, 52.656487489201687],
              [1.053358264170118, 52.659046284994304],
              [1.054782802189888, 52.658923211628867],
              [1.054962509959312, 52.660566103595627],
              [1.054342184963179, 52.660578507884033],
              [1.054782493925417, 52.661441275235042],
              [1.054408187417201, 52.663305297806893],
              [1.055398632694801, 52.663318417608231],
              [1.055082697460055, 52.662563957688668],
              [1.055820869989805, 52.661251710129996],
              [1.057308492822484, 52.659214154962761],
              [1.058404628482289, 52.658560831604113],
              [1.059985121520899, 52.655717759795174],
              [1.061498855069201, 52.655074297929261],
              [1.062607060536487, 52.653940390970064],
              [1.064022253811772, 52.650262479232417],
              [1.06602552366479, 52.647215923063051],
              [1.068120682681261, 52.645358533752209],
              [1.0712903438145, 52.644321287122381],
              [1.071590083490013, 52.644484380527096],
              [1.073016254104715, 52.643696448150337],
              [1.073997723880157, 52.642281533399519],
              [1.074201051705721, 52.639911962870997],
              [1.073695801266916, 52.639389990442808],
              [1.074626963050058, 52.638144545962575],
              [1.075981494851163, 52.639624702769645],
              [1.076299163792021, 52.639495941061462],
              [1.076740544062857, 52.640328926100572],
              [1.076679373445527, 52.644994349700752],
              [1.078962066708731, 52.648715719776646],
              [1.082084678156542, 52.648585915056479],
              [1.089620929619895, 52.647344498444447],
              [1.093342778655483, 52.651032538108048],
              [1.097672236581585, 52.649733152827231],
              [1.097679407091003, 52.64941640588858],
              [1.100632188173436, 52.648746467577581],
              [1.109748438397434, 52.647252834157939],
              [1.116040944990532, 52.645418254603278],
              [1.124512986076552, 52.645128427751317],
              [1.131994181160588, 52.645767130301408],
              [1.132215207051949, 52.642551593335384],
              [1.135221972618367, 52.642835334632487],
              [1.135195918953949, 52.644846916173087],
              [1.13645823165019, 52.644738838155426],
              [1.137009935866731, 52.646909582514667],
              [1.137681810206858, 52.646917859211129],
              [1.137673012565999, 52.649787839969328],
              [1.138648939066927, 52.653934842762744],
              [1.140049673907261, 52.653835650524407],
              [1.140290924277878, 52.655075717417276],
              [1.13798732800925, 52.655309460039867],
              [1.137658737204733, 52.657678894948099],
              [1.138864906615034, 52.657772832554585],
              [1.13816157080153, 52.659742109761794],
              [1.145646108056168, 52.659360052999538],
              [1.14633156251679, 52.660007342863899],
              [1.145673822174271, 52.668267137286527],
              [1.147273648215552, 52.67134169101714],
              [1.14952864555999, 52.669208793777464],
              [1.152003168467464, 52.668114125824715],
              [1.159928382576059, 52.666584497003598],
              [1.163664461124339, 52.665185210607014],
              [1.165715251664389, 52.665193355618996],
              [1.167439772911595, 52.66484236659894],
              [1.173742247152465, 52.665142167323573],
              [1.17423178137953, 52.665793652196342],
              [1.174266717927519, 52.667274814686031],
              [1.176352366439775, 52.667986019127007],
              [1.182062305462888, 52.671746620408058],
              [1.184426303587707, 52.674224591269933],
              [1.184764041255422, 52.675368454522072],
              [1.18719374395022, 52.67744350315315],
              [1.190459653404629, 52.678503114650347],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000150",
        LAD13CDO: "34UB",
        LAD13NM: "Corby",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.665593771774741, 52.552234907916237],
            [-0.666601155266862, 52.551018157587819],
            [-0.667403125112868, 52.547524494489977],
            [-0.668300559552924, 52.545939680049116],
            [-0.669475009627548, 52.54489650244345],
            [-0.669210500766163, 52.544670565414194],
            [-0.666788669205936, 52.543467361574876],
            [-0.664356177147589, 52.544174467146931],
            [-0.661697570389632, 52.543343390522871],
            [-0.659553462020327, 52.543246310556079],
            [-0.658276853593354, 52.542216821877453],
            [-0.658430820173256, 52.54142290727556],
            [-0.653730106203161, 52.54074110868055],
            [-0.651402837779262, 52.539359750360681],
            [-0.646747500911197, 52.537644279889456],
            [-0.645050133982551, 52.536647595050951],
            [-0.643148781671309, 52.536339918865878],
            [-0.640690099000541, 52.534457865503697],
            [-0.638160193499164, 52.533121564647054],
            [-0.640444376962601, 52.530336480482788],
            [-0.639373967071059, 52.528909970043379],
            [-0.640799938160721, 52.52889488535763],
            [-0.635857837863161, 52.523875253303771],
            [-0.635910668160012, 52.523026255138291],
            [-0.638318544786797, 52.521805192623681],
            [-0.641758973562984, 52.520648079952977],
            [-0.649389647675627, 52.518904023915034],
            [-0.652962564787812, 52.518843147045104],
            [-0.657854121268298, 52.519376112690836],
            [-0.661686461483993, 52.519121020992841],
            [-0.664775256732563, 52.518208303620348],
            [-0.668227062389585, 52.516008551145575],
            [-0.669648801027611, 52.514460187736212],
            [-0.671104819722498, 52.513823832097728],
            [-0.67468228458011, 52.513204939635806],
            [-0.673758923202533, 52.509317879141989],
            [-0.672148214940505, 52.506928114789609],
            [-0.671448153507423, 52.506690100339256],
            [-0.669799563880591, 52.507514895034213],
            [-0.667826588108201, 52.507857719189303],
            [-0.664176956167721, 52.508243632716976],
            [-0.663177119422642, 52.508028257891155],
            [-0.66061602486308, 52.508569302063485],
            [-0.658194066884547, 52.509619834620842],
            [-0.657987079234733, 52.510416747495015],
            [-0.656901046424845, 52.511481495522574],
            [-0.653846613805028, 52.512244255537581],
            [-0.651545836706896, 52.513464154573946],
            [-0.649463687757461, 52.514085935363155],
            [-0.645713573177012, 52.514458447718901],
            [-0.64537468759766, 52.514264868851576],
            [-0.643148140548343, 52.514977488954088],
            [-0.639607400533505, 52.514817270152029],
            [-0.638314973216328, 52.515055941679378],
            [-0.638193873588759, 52.51549328769952],
            [-0.636032733068102, 52.515896352776274],
            [-0.632000109649956, 52.515438942658861],
            [-0.63124769095885, 52.513426246011335],
            [-0.629688251984609, 52.51125765610108],
            [-0.627125046420962, 52.512367056734149],
            [-0.624046460502926, 52.512563262480626],
            [-0.624056391864384, 52.511537552954628],
            [-0.62219825012741, 52.511442213079405],
            [-0.615845313196248, 52.513236327927196],
            [-0.612385148830455, 52.513519471502327],
            [-0.610356532615135, 52.513173806836768],
            [-0.608878610585075, 52.513543039991404],
            [-0.607324503645374, 52.513431260699271],
            [-0.605584501579406, 52.51425768703605],
            [-0.60304051614884, 52.51362082186948],
            [-0.602017796098816, 52.512924561439881],
            [-0.599706817252211, 52.512100674192602],
            [-0.598903699251415, 52.512286265322956],
            [-0.597721801610978, 52.512010641019153],
            [-0.598113585323466, 52.511692519715858],
            [-0.59769797728513, 52.511648037371543],
            [-0.598192866448043, 52.510960724498382],
            [-0.598550121511655, 52.511079136181856],
            [-0.598757135595991, 52.510686000756323],
            [-0.599178440597881, 52.510690089135259],
            [-0.599743264123514, 52.509887618775998],
            [-0.603364348086704, 52.509542012574627],
            [-0.603875528246053, 52.509174034879749],
            [-0.605123953147076, 52.509069180652084],
            [-0.606115728300263, 52.509065581740018],
            [-0.606587602091221, 52.509660022698746],
            [-0.608593268601273, 52.509279942127158],
            [-0.609312695677764, 52.508615002404674],
            [-0.608601349666234, 52.508230834324713],
            [-0.608144551386999, 52.506179202148829],
            [-0.603665855595709, 52.502631793577734],
            [-0.603313161409947, 52.501859833191325],
            [-0.600591863757067, 52.50089176104386],
            [-0.598486534552052, 52.496956824195408],
            [-0.596403041725508, 52.496927634300341],
            [-0.597017645442634, 52.495899202426678],
            [-0.597934575152757, 52.491477689399325],
            [-0.599559767809808, 52.488770075807551],
            [-0.601395431087588, 52.48680305902559],
            [-0.609650949676045, 52.485798101689682],
            [-0.612656637020673, 52.485006244113144],
            [-0.612101674164204, 52.484249917263881],
            [-0.621733023209154, 52.482313537634596],
            [-0.623793927888628, 52.481629065591719],
            [-0.627998666095697, 52.479339437471381],
            [-0.629457322713295, 52.476941462096967],
            [-0.632059007481883, 52.474712227874249],
            [-0.633557803841296, 52.471528885219833],
            [-0.635487776368132, 52.469486013412116],
            [-0.633363156247487, 52.468592095301865],
            [-0.632576873758532, 52.466222069034643],
            [-0.631516180235362, 52.465251401946695],
            [-0.631910809224059, 52.464582567462358],
            [-0.634432230667011, 52.463444715037454],
            [-0.637287528283442, 52.464122268920669],
            [-0.63834898325541, 52.464928364137194],
            [-0.640321204854993, 52.465701765772813],
            [-0.643664465691547, 52.463777489712818],
            [-0.648860062476845, 52.462687043545259],
            [-0.652608772269542, 52.460865144487812],
            [-0.657934332219716, 52.459983465291749],
            [-0.663335614599062, 52.456594020032981],
            [-0.664158106342237, 52.454460845916039],
            [-0.670283012868834, 52.455100759547506],
            [-0.669664805537107, 52.455641337185959],
            [-0.669096910584911, 52.457539161502623],
            [-0.670758198941332, 52.458429930051913],
            [-0.672411125296283, 52.461900885059926],
            [-0.669039342933879, 52.462572356622452],
            [-0.669893200705146, 52.463739950056997],
            [-0.673545858297459, 52.463471685376021],
            [-0.676468094118303, 52.462476789537874],
            [-0.676486453172248, 52.461962732593271],
            [-0.676962694340797, 52.461788247744465],
            [-0.681848645875107, 52.462306705263885],
            [-0.683772189249729, 52.462167191017095],
            [-0.683858507812808, 52.462431575772889],
            [-0.681600550402672, 52.462678848429405],
            [-0.681788550053236, 52.463185315796693],
            [-0.676605745813066, 52.463924017799371],
            [-0.672872700852222, 52.465151671394743],
            [-0.672946869383911, 52.465671260967937],
            [-0.676207378568226, 52.467193935739004],
            [-0.675629515026021, 52.468005612225781],
            [-0.674479132686452, 52.468517779369435],
            [-0.671326006866227, 52.468903169123678],
            [-0.672895473532534, 52.471296099523542],
            [-0.676935876682864, 52.473435259987077],
            [-0.675723013780303, 52.474504156828111],
            [-0.674376192628092, 52.474933209058342],
            [-0.684822098116189, 52.47825402684574],
            [-0.685575523885087, 52.477232080003262],
            [-0.686647488733847, 52.476785464970916],
            [-0.688197200825163, 52.476760401520103],
            [-0.689576058556582, 52.475547569645876],
            [-0.692889985151692, 52.476553410292851],
            [-0.693274108486309, 52.476608899526262],
            [-0.69612872983095, 52.470642786130639],
            [-0.698278158778088, 52.467897359507425],
            [-0.702359771350261, 52.464160764609737],
            [-0.70480836793395, 52.462883065367329],
            [-0.704816176164601, 52.462366192863378],
            [-0.705730010078268, 52.462223354432318],
            [-0.707919796984452, 52.460427599581031],
            [-0.709389580455868, 52.459843972427571],
            [-0.716771308083214, 52.457397026226253],
            [-0.720236528449219, 52.456762969377955],
            [-0.72280614905173, 52.459764819044857],
            [-0.728650756379905, 52.458436935640094],
            [-0.731400909820537, 52.458099644939495],
            [-0.732949648020735, 52.458545991668714],
            [-0.734487029695209, 52.457900743310219],
            [-0.736658648676941, 52.458028245138756],
            [-0.737894955688223, 52.458130448068765],
            [-0.738559878782169, 52.462931299546312],
            [-0.739815973872196, 52.464098172732783],
            [-0.739796753416378, 52.465544545737004],
            [-0.741581506648227, 52.470115450462536],
            [-0.749050747545598, 52.469615671350098],
            [-0.755240353647664, 52.469852673278425],
            [-0.758134693859342, 52.470059303338573],
            [-0.761605350216304, 52.471186231230185],
            [-0.767083359523116, 52.471820633384418],
            [-0.768811428331456, 52.472307033593552],
            [-0.771647266219171, 52.472031731359266],
            [-0.771851831174385, 52.473779809940098],
            [-0.774811154523267, 52.477172036425834],
            [-0.775734735167431, 52.477370396584583],
            [-0.776967221936087, 52.476942608714417],
            [-0.778114897167301, 52.477130679746189],
            [-0.779664897499077, 52.477731956855358],
            [-0.781452259315788, 52.479070180546465],
            [-0.778597358209045, 52.480711176812129],
            [-0.777128280475188, 52.482127288010084],
            [-0.778472586861537, 52.483089668965128],
            [-0.778089318807967, 52.483475898546487],
            [-0.782489472187381, 52.486356849709864],
            [-0.781589088425355, 52.486963429506311],
            [-0.782182963704863, 52.487376811136343],
            [-0.78137606435463, 52.488010423725498],
            [-0.779885714857997, 52.488113740585263],
            [-0.781320012106682, 52.489345830025762],
            [-0.78075701216032, 52.489785957766536],
            [-0.782459273303888, 52.491024389484281],
            [-0.782209781938258, 52.491365257970685],
            [-0.784972238849396, 52.492351137753943],
            [-0.785757651125056, 52.493568415884852],
            [-0.785565334492987, 52.494400756814059],
            [-0.786676750000664, 52.495549449605342],
            [-0.791566165853024, 52.49547810707486],
            [-0.79285992884503, 52.495676514291766],
            [-0.793813315859738, 52.496202287011066],
            [-0.794951389647113, 52.497786309795586],
            [-0.798008000625783, 52.498814434016118],
            [-0.801424755662788, 52.501826718845891],
            [-0.807945738366551, 52.506546921428743],
            [-0.805728202021669, 52.508399068555121],
            [-0.804962174991954, 52.510294607991597],
            [-0.80263102495584, 52.509971667218451],
            [-0.80169102369042, 52.513056655669367],
            [-0.799327577225412, 52.512562505549219],
            [-0.796173831679875, 52.516347802982352],
            [-0.795792254223742, 52.515912381632916],
            [-0.796212190839694, 52.515699085116545],
            [-0.795484328102861, 52.515262837547027],
            [-0.793347890411463, 52.515410097065754],
            [-0.788874443896883, 52.513671511755746],
            [-0.787795117956787, 52.513694626419088],
            [-0.788359092926228, 52.51472800654517],
            [-0.787830990986972, 52.515068730730448],
            [-0.787478969372401, 52.514526598710979],
            [-0.786863865065557, 52.514655152331436],
            [-0.787004230749184, 52.515498094631681],
            [-0.783975317389969, 52.515569506494636],
            [-0.784065272866829, 52.516047822247444],
            [-0.78262301743991, 52.51645644660455],
            [-0.78200069010162, 52.51721782679391],
            [-0.780305879438257, 52.517005282608849],
            [-0.779882180856497, 52.517776789751451],
            [-0.77912483207252, 52.518089938864172],
            [-0.778108935763029, 52.51784120987648],
            [-0.777391598843901, 52.517986639968534],
            [-0.776747567231894, 52.517561931051773],
            [-0.776189800141469, 52.517798907123712],
            [-0.775730897948786, 52.517288899363884],
            [-0.775112997458164, 52.517515358925273],
            [-0.775385903615319, 52.518036031529363],
            [-0.774880090540729, 52.51825915427704],
            [-0.77234184549001, 52.516052675433407],
            [-0.770057460280815, 52.515371759014918],
            [-0.767828866521095, 52.514226573067717],
            [-0.766571527963761, 52.51439598325873],
            [-0.766201786916734, 52.513338449603161],
            [-0.76103993754107, 52.514169189906127],
            [-0.758408922626785, 52.511261079178929],
            [-0.756169766970402, 52.510977744786082],
            [-0.754639086124609, 52.511852603255768],
            [-0.751258871841465, 52.512159525156846],
            [-0.751629241109706, 52.513129906481311],
            [-0.750767046417636, 52.51323858458295],
            [-0.750364683421636, 52.513812423654933],
            [-0.749675492449725, 52.51374401014786],
            [-0.749573293585528, 52.514489138573623],
            [-0.748554263101947, 52.514307549937314],
            [-0.747274008445782, 52.5148136553276],
            [-0.74711629160589, 52.515695748101244],
            [-0.74784220405568, 52.516443344384633],
            [-0.746664671095631, 52.516858827282796],
            [-0.744378689646343, 52.516194478601243],
            [-0.74211740520616, 52.517041644525641],
            [-0.739269126801878, 52.517437488839612],
            [-0.739404861818421, 52.51861668503274],
            [-0.733510915636697, 52.520573026020728],
            [-0.730324256966441, 52.521243739535031],
            [-0.730183636562862, 52.520855641054524],
            [-0.72963130984061, 52.520893767837507],
            [-0.730060698088643, 52.521899014228353],
            [-0.726886741220976, 52.522736994620793],
            [-0.726846897224466, 52.523146531329786],
            [-0.72517086583235, 52.52315186777308],
            [-0.72516914648349, 52.522803918161188],
            [-0.723617871794118, 52.522624473388376],
            [-0.723599215999229, 52.52235276003573],
            [-0.716619286369267, 52.522991131646293],
            [-0.716091818714421, 52.522428002052095],
            [-0.715642382292012, 52.522373678678157],
            [-0.715312360176841, 52.523177442132216],
            [-0.713441539700872, 52.524451751640974],
            [-0.713671758077424, 52.524946931690245],
            [-0.711928528177493, 52.525684973465644],
            [-0.711930247760437, 52.526029327385402],
            [-0.71003516854298, 52.526564302364697],
            [-0.709573797110232, 52.527720843150725],
            [-0.708000210839383, 52.527896074841379],
            [-0.707845382450878, 52.528550689589707],
            [-0.70836638994373, 52.528729891794242],
            [-0.708222260664641, 52.529170650399436],
            [-0.708921281004403, 52.5292618878008],
            [-0.708908547292496, 52.529796682052549],
            [-0.706750491073857, 52.530143532756831],
            [-0.7076094804793, 52.530510734785892],
            [-0.707642984849678, 52.53082576709874],
            [-0.706361350428245, 52.531195661453438],
            [-0.706111454314272, 52.530619338114917],
            [-0.705518900739874, 52.530917636976227],
            [-0.705720688124784, 52.531224620437094],
            [-0.704667053445752, 52.531109705439562],
            [-0.704877095676152, 52.53163704728324],
            [-0.703755449287287, 52.532179482817661],
            [-0.702830958505777, 52.532234087494295],
            [-0.702894899044007, 52.533166201203073],
            [-0.701452484114249, 52.533535179989066],
            [-0.701746694465117, 52.534557027471813],
            [-0.700692619769416, 52.53445465929687],
            [-0.699639423206394, 52.53567029475559],
            [-0.700288029559214, 52.535724167965789],
            [-0.700591657105177, 52.536376613075859],
            [-0.699994235579784, 52.536735966281142],
            [-0.700609768353361, 52.536811950626323],
            [-0.700557663940695, 52.53732833071426],
            [-0.70122043234061, 52.537252891384092],
            [-0.701143538263351, 52.53766021503445],
            [-0.700595776585379, 52.537885259065249],
            [-0.700288972970121, 52.537589700934873],
            [-0.699201498689343, 52.537717109185174],
            [-0.698684748788764, 52.538638347505952],
            [-0.69717276965389, 52.538311546730988],
            [-0.696443839305742, 52.538529183479341],
            [-0.696704045224009, 52.538953701418222],
            [-0.697964860990547, 52.53934787165425],
            [-0.697053537864133, 52.539799056351775],
            [-0.696191781030573, 52.539721241322397],
            [-0.696250464192074, 52.540079708274625],
            [-0.69828425637946, 52.540962475699729],
            [-0.699250563049667, 52.542243449150568],
            [-0.696980332333793, 52.542122286423485],
            [-0.696410595033994, 52.542339877026045],
            [-0.697494414370946, 52.542937984231436],
            [-0.697727265713614, 52.543839592074455],
            [-0.696765677163844, 52.544192225705679],
            [-0.698041228920756, 52.545289610330819],
            [-0.697846671161201, 52.545684848969557],
            [-0.696635121962938, 52.546309840247488],
            [-0.693978158200825, 52.545349148862435],
            [-0.693185207093254, 52.545880725885084],
            [-0.69362285997658, 52.546438469285434],
            [-0.692567935178767, 52.546852073347019],
            [-0.691996784148002, 52.5455268636575],
            [-0.689621134531103, 52.546316029138268],
            [-0.68846194852786, 52.546119788137524],
            [-0.688502273737718, 52.546745971358291],
            [-0.687631015198547, 52.546982655732904],
            [-0.687214700526088, 52.547837528929833],
            [-0.68638995454029, 52.547849059262248],
            [-0.685883221618227, 52.548619313242938],
            [-0.684768400057618, 52.548960259647011],
            [-0.685989925712502, 52.549540222171977],
            [-0.683612770335374, 52.552340418243254],
            [-0.681842890003118, 52.551650038646862],
            [-0.679747042577836, 52.553419385847214],
            [-0.682319047911741, 52.553578399048384],
            [-0.677575960330927, 52.558274281721708],
            [-0.674877830679337, 52.558620817423609],
            [-0.665593771774741, 52.552234907916237],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000151",
        LAD13CDO: "34UC",
        LAD13NM: "Daventry",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.951888887829146, 52.477388749953533],
            [-0.951860649233662, 52.477027986845648],
            [-0.950824200852379, 52.477209397094107],
            [-0.950344580504148, 52.476884188063813],
            [-0.94938856763819, 52.476964708266358],
            [-0.948908008822096, 52.47630864006662],
            [-0.948497432458048, 52.476361631508993],
            [-0.948583746470612, 52.475952438406246],
            [-0.947101641477701, 52.475683034682092],
            [-0.946476152421071, 52.475302569540524],
            [-0.946546948776161, 52.474619033765514],
            [-0.946110440227193, 52.47427890741686],
            [-0.946644903256475, 52.473907869539666],
            [-0.9464586853445, 52.47319777144174],
            [-0.9453456348924, 52.473137509851753],
            [-0.945534995400413, 52.472791270587528],
            [-0.945088843069391, 52.472486116736548],
            [-0.944428358270848, 52.47283264854849],
            [-0.943872436688693, 52.472746774636441],
            [-0.944226235254717, 52.470635398744136],
            [-0.942657922069162, 52.470838961346281],
            [-0.942204794235521, 52.467755710408966],
            [-0.935865754257328, 52.467219681289919],
            [-0.936029493600992, 52.466900197418965],
            [-0.930244431816898, 52.465926519827669],
            [-0.923804860414837, 52.464217486057819],
            [-0.922321925427364, 52.464185108044212],
            [-0.916759340861795, 52.46262931752814],
            [-0.914242579326945, 52.462262814559331],
            [-0.911270040780396, 52.463488759694521],
            [-0.909903804724227, 52.461474016984653],
            [-0.90773712034123, 52.461478307682761],
            [-0.906596945136638, 52.463061774647286],
            [-0.904591256286686, 52.462862514525632],
            [-0.901556820359259, 52.460825923291779],
            [-0.901293915359564, 52.459739238387421],
            [-0.900795638718694, 52.459121463728039],
            [-0.9014256621901, 52.459117426203818],
            [-0.901454824249601, 52.458832700879981],
            [-0.900986662464172, 52.458838242270268],
            [-0.901557102231165, 52.458093739444628],
            [-0.904162577075487, 52.456414218445879],
            [-0.90408924567729, 52.455751844556325],
            [-0.904555748756034, 52.455574558698942],
            [-0.904686923909246, 52.454914078591216],
            [-0.905790573752137, 52.454561078815786],
            [-0.90626363389031, 52.453643036525818],
            [-0.905864223913608, 52.453311192903286],
            [-0.905355454848562, 52.4483680471822],
            [-0.903873087148242, 52.445489070943808],
            [-0.904397191155061, 52.442154879183903],
            [-0.89843703851564, 52.441516045672586],
            [-0.893259352044444, 52.441537840125967],
            [-0.879303623598377, 52.439157927697089],
            [-0.872969786167549, 52.436762023975263],
            [-0.865229316902864, 52.434579749434128],
            [-0.864341557185725, 52.434208912557445],
            [-0.868720334561748, 52.432840269052946],
            [-0.870995413741038, 52.429287393370778],
            [-0.873421072605988, 52.426563032233531],
            [-0.875060476676408, 52.423517377153431],
            [-0.876677631429345, 52.42353724356559],
            [-0.87829458194075, 52.422927752011766],
            [-0.882015885785446, 52.42260786448324],
            [-0.885273949719375, 52.423627571242889],
            [-0.88695036004339, 52.423338583182606],
            [-0.886261888510962, 52.42083814264236],
            [-0.886364023724871, 52.420286189805118],
            [-0.88727447694428, 52.419576420121977],
            [-0.888018459903492, 52.416305487208994],
            [-0.888978476392896, 52.4151987908376],
            [-0.888262597194619, 52.413730209467644],
            [-0.888185405934552, 52.411712916384928],
            [-0.889295578618585, 52.409540456905198],
            [-0.888702712745331, 52.407976833575184],
            [-0.889179042062881, 52.407975914796907],
            [-0.889302246674279, 52.406289555348692],
            [-0.890399668936245, 52.403680026065075],
            [-0.890273111712753, 52.400404496036536],
            [-0.890681047547671, 52.399024684525934],
            [-0.890551116498576, 52.398579334220287],
            [-0.889399938535635, 52.398359048115218],
            [-0.889357503834953, 52.397824613069503],
            [-0.888670501665522, 52.397732748029739],
            [-0.888769662745843, 52.398056439441348],
            [-0.888201254247477, 52.398158083184768],
            [-0.888472003853129, 52.398789065575208],
            [-0.887230834054185, 52.399108242165212],
            [-0.88532622940119, 52.400323800838613],
            [-0.880623128902378, 52.401529951290343],
            [-0.877024393829588, 52.401723288075651],
            [-0.873709516622157, 52.402659139260351],
            [-0.866151889994053, 52.399652401874633],
            [-0.861821906164909, 52.39616472861487],
            [-0.857267619333525, 52.395299963183213],
            [-0.854412338281728, 52.39409726818441],
            [-0.850106569458913, 52.393296667621456],
            [-0.849845519345343, 52.393671731549439],
            [-0.840583978959198, 52.392832359392933],
            [-0.839857183541975, 52.391126013911546],
            [-0.833753282580349, 52.384247510604659],
            [-0.832973044161602, 52.381128171213561],
            [-0.831712421046514, 52.376843393038691],
            [-0.829523320555287, 52.375073067726014],
            [-0.826308843244522, 52.373560893520356],
            [-0.81904511555187, 52.371542532135848],
            [-0.81673411956034, 52.370294900041593],
            [-0.805556072601461, 52.366380340673906],
            [-0.805781626463904, 52.366185725026256],
            [-0.799756602561363, 52.3638698472854],
            [-0.796331884352956, 52.361649396457274],
            [-0.791218117599679, 52.355953777316792],
            [-0.790180942105342, 52.35349860541379],
            [-0.788670852342792, 52.347293065291552],
            [-0.787690592900119, 52.345345523967367],
            [-0.786677215571972, 52.344396497525089],
            [-0.785971076369549, 52.341825104279607],
            [-0.786104086414947, 52.338629384484491],
            [-0.787555504465536, 52.332840797306588],
            [-0.786866906461898, 52.331349366393674],
            [-0.785795484599269, 52.33060292259492],
            [-0.789309537168009, 52.329176194758766],
            [-0.790603793443414, 52.327563931469804],
            [-0.792589233591237, 52.326161908846991],
            [-0.792863806711643, 52.323783078519021],
            [-0.794459840689167, 52.322987513929547],
            [-0.794977964346487, 52.32303505565757],
            [-0.805548524423577, 52.316919931218287],
            [-0.809383593271987, 52.315164997342272],
            [-0.814249057201006, 52.311509762424961],
            [-0.817966172801002, 52.30958164525385],
            [-0.816762766185729, 52.308559043597647],
            [-0.81717286987558, 52.307976057543897],
            [-0.815724897637628, 52.306776576150668],
            [-0.815944904652172, 52.305841949886378],
            [-0.814921811321041, 52.304443525609805],
            [-0.814882938244627, 52.302768166194952],
            [-0.814136694822899, 52.302264389975072],
            [-0.813657523900739, 52.300273529772063],
            [-0.812299688969288, 52.298896892435074],
            [-0.812550149757131, 52.298634184185367],
            [-0.811001624947684, 52.2953459841982],
            [-0.808396632048491, 52.29285987032862],
            [-0.805913792370353, 52.288240530259834],
            [-0.805955286010669, 52.287296921883062],
            [-0.807146516568159, 52.286285811947316],
            [-0.807610898357414, 52.284496847621632],
            [-0.807751429331208, 52.282920390362257],
            [-0.807088425750928, 52.281515634401586],
            [-0.807649208427119, 52.278426680442095],
            [-0.809157409944698, 52.275313114590404],
            [-0.815340606682892, 52.275004880546931],
            [-0.816016132775129, 52.275615833268539],
            [-0.819003269460437, 52.274235992414383],
            [-0.819950073302876, 52.274236465876157],
            [-0.824740004685298, 52.277832875831002],
            [-0.8253281669882, 52.278705434493624],
            [-0.826826118180105, 52.279764148145482],
            [-0.827177550600296, 52.280888783138394],
            [-0.827591735002469, 52.280969316547967],
            [-0.830745949616364, 52.2803703347275],
            [-0.832658491313159, 52.279214164658939],
            [-0.837519392645513, 52.281390213960456],
            [-0.839994134651666, 52.280929046494322],
            [-0.844523555534987, 52.280878143839281],
            [-0.845053250089636, 52.279899739894567],
            [-0.846532522713495, 52.280116487303303],
            [-0.84932691138814, 52.277720745943931],
            [-0.85223312503364, 52.279098531499343],
            [-0.85149875855743, 52.280912911731718],
            [-0.855828012859603, 52.281860294949517],
            [-0.857495199119272, 52.279790588966158],
            [-0.859817348467219, 52.281094205215659],
            [-0.864841773573691, 52.278593736938085],
            [-0.869593652327101, 52.281541511429246],
            [-0.871778412486557, 52.282500130741163],
            [-0.873951923089033, 52.282691695650364],
            [-0.875769821198827, 52.282222616474186],
            [-0.87965887709494, 52.280304993356907],
            [-0.882332881632499, 52.278079057959161],
            [-0.885132957150001, 52.275374149129519],
            [-0.888440071822549, 52.276290904146791],
            [-0.888375215209643, 52.277711722143465],
            [-0.894696879622564, 52.280311819644758],
            [-0.898056933348197, 52.280653394360243],
            [-0.898104556047472, 52.280033481754302],
            [-0.899191631587705, 52.279346840786886],
            [-0.899447220770119, 52.278733361032423],
            [-0.902848438128872, 52.280135184188772],
            [-0.902653629926454, 52.279060786603964],
            [-0.904932536447168, 52.279197018397504],
            [-0.905096334995254, 52.277724964955816],
            [-0.90567066836371, 52.27767544468874],
            [-0.905666179062611, 52.277322069437695],
            [-0.910428692255373, 52.27671247855374],
            [-0.916142794129904, 52.276383849816597],
            [-0.92149538373787, 52.276634239489859],
            [-0.922666637575082, 52.276688983271015],
            [-0.922924422666085, 52.275366109998537],
            [-0.922561181537036, 52.273784035203619],
            [-0.92415651049193, 52.273633149844322],
            [-0.925171142347479, 52.272646221737297],
            [-0.924678006005519, 52.271705803860932],
            [-0.92825827668675, 52.27116294490871],
            [-0.929022459641249, 52.269920173857201],
            [-0.932329655159088, 52.270975953535263],
            [-0.932923570718163, 52.269006970215059],
            [-0.93717109029445, 52.26916664559235],
            [-0.944849092402849, 52.263409541347706],
            [-0.946198391959262, 52.264037450134659],
            [-0.955054176597968, 52.26194040009949],
            [-0.958542033809479, 52.26141468861524],
            [-0.960047511320737, 52.260821085982705],
            [-0.961770159141434, 52.259458873870983],
            [-0.967834604695306, 52.258315378190289],
            [-0.969357094491383, 52.258305299906127],
            [-0.971490101441651, 52.257617230747883],
            [-0.972660402014226, 52.258391623692887],
            [-0.973471462218304, 52.258545224488117],
            [-0.975082226115272, 52.258265220896227],
            [-0.975510754471335, 52.257598236361218],
            [-0.976147765239788, 52.257484185281434],
            [-0.979344406669619, 52.258002746878226],
            [-0.983109516980713, 52.257755619293441],
            [-0.987248018134965, 52.258030334984682],
            [-0.989544204321396, 52.257369412767602],
            [-0.991226892813574, 52.256172758675199],
            [-0.991824035449912, 52.255940505113706],
            [-0.992390800872205, 52.254785548782344],
            [-0.997606383341917, 52.251266942486502],
            [-0.997419584933247, 52.24766100347852],
            [-1.000939089017084, 52.247479553454184],
            [-1.001605686894993, 52.248452585840624],
            [-1.003737164303652, 52.247789097071838],
            [-1.008832548758472, 52.24833904990458],
            [-1.011093519023053, 52.246852972196628],
            [-1.012471055411003, 52.246690082243148],
            [-1.013321190587811, 52.244880178932803],
            [-1.013807848855287, 52.244862666944947],
            [-1.014116754843751, 52.24281987342507],
            [-1.013572110747928, 52.241622264888562],
            [-1.015207652193997, 52.241572982689213],
            [-1.017542246991196, 52.240972076000993],
            [-1.019624973355786, 52.241098174146437],
            [-1.025058226520596, 52.239904249971048],
            [-1.029886249840011, 52.239320065549869],
            [-1.03547914859321, 52.237899511178078],
            [-1.038498745762222, 52.2383772677519],
            [-1.039780693198668, 52.237944461373431],
            [-1.04014439327472, 52.238191964055481],
            [-1.04068009644922, 52.237865462131168],
            [-1.041320075028709, 52.238068454231829],
            [-1.041335857982363, 52.237675691331489],
            [-1.041920836672553, 52.23784856449133],
            [-1.041624954563643, 52.237307623778229],
            [-1.042447006456436, 52.237283725219264],
            [-1.042772902428371, 52.23697439247767],
            [-1.042357146023973, 52.236695907472644],
            [-1.04295198330163, 52.236818507941393],
            [-1.043211250824015, 52.236270381865815],
            [-1.042111646190801, 52.232521357149807],
            [-1.043265951305097, 52.232295161499287],
            [-1.043918831153889, 52.231621656201447],
            [-1.043215907917562, 52.231081018246414],
            [-1.04347600301332, 52.230560768911801],
            [-1.04511266497434, 52.231598951315874],
            [-1.046148966400182, 52.231547989821294],
            [-1.045915268291489, 52.231930901285757],
            [-1.046280860510224, 52.232021064317237],
            [-1.04714613418675, 52.231957024548578],
            [-1.048407986048997, 52.23067075025638],
            [-1.049701574158568, 52.23115227922171],
            [-1.050343214275279, 52.230965941202079],
            [-1.050691378242652, 52.231461428513704],
            [-1.052212042663568, 52.231905196489052],
            [-1.053157752170655, 52.231501012970966],
            [-1.053651135248116, 52.231985968058687],
            [-1.054511941939714, 52.231580194107472],
            [-1.0549770506022, 52.231811381070372],
            [-1.055251714878569, 52.231219297746371],
            [-1.055632828572709, 52.231406654912675],
            [-1.055909081964403, 52.231084302046213],
            [-1.057117866509876, 52.230838627415196],
            [-1.057407081797012, 52.230110896586837],
            [-1.056963077610704, 52.229989573542682],
            [-1.058360313859797, 52.228585598306253],
            [-1.058548182424786, 52.227386845376962],
            [-1.060264173520952, 52.227105623375984],
            [-1.060536203647073, 52.227604971006286],
            [-1.06155129583932, 52.227582475875465],
            [-1.062162560849075, 52.228311978912004],
            [-1.062846611426293, 52.228158276315611],
            [-1.064022062728498, 52.22879604667461],
            [-1.065203923639744, 52.228223715770277],
            [-1.06511568088546, 52.227893959590062],
            [-1.066070464323987, 52.227256887208718],
            [-1.057357953482569, 52.21804774655125],
            [-1.057155302485946, 52.216557274548151],
            [-1.055151389396564, 52.215525307515207],
            [-1.043958540877311, 52.203247421416954],
            [-1.042312797898801, 52.200910884596688],
            [-1.042117541610015, 52.199103068998284],
            [-1.038437372477502, 52.195659374783745],
            [-1.042127855215637, 52.194062065602814],
            [-1.04334628965442, 52.193868757922978],
            [-1.044412814975338, 52.193186012519313],
            [-1.046679620755677, 52.193428212466515],
            [-1.046679037637565, 52.194002713754308],
            [-1.047156399762143, 52.194256510099663],
            [-1.04930770538676, 52.193880964638716],
            [-1.050617337642213, 52.194074916696906],
            [-1.051281570977322, 52.194485738396928],
            [-1.052496071816159, 52.194544943360228],
            [-1.054689224941112, 52.195574881816569],
            [-1.057263056979949, 52.195835519639779],
            [-1.057768500599015, 52.196287292263548],
            [-1.060013470730327, 52.196673810977728],
            [-1.060581754276498, 52.19636725750761],
            [-1.064725995683378, 52.195909277960467],
            [-1.066206214491522, 52.195439097172084],
            [-1.067465342849809, 52.195465235406836],
            [-1.06969542146976, 52.194744698568172],
            [-1.072401431378962, 52.194561008842207],
            [-1.078748743682746, 52.193319660390294],
            [-1.079787519435423, 52.193812361743412],
            [-1.083165884566123, 52.193511392305602],
            [-1.084575436337378, 52.193049423436193],
            [-1.084688818682002, 52.192657410004557],
            [-1.085422712595873, 52.192681979592216],
            [-1.086236737338065, 52.191723584467127],
            [-1.088072895130916, 52.191284655502066],
            [-1.090460515320161, 52.189914019130512],
            [-1.08945380312073, 52.186345945245193],
            [-1.08985854838198, 52.186152171163059],
            [-1.089232510047123, 52.182427892610683],
            [-1.0896651440272, 52.181869312198998],
            [-1.09136997731354, 52.181537207800041],
            [-1.091332205739435, 52.180806872565022],
            [-1.093152381135511, 52.1804099984776],
            [-1.093138818325358, 52.180141971545886],
            [-1.095478407594432, 52.180020570858773],
            [-1.095599077412547, 52.178902154184499],
            [-1.097742991946559, 52.179413052964257],
            [-1.102600744154028, 52.179665868905722],
            [-1.108292634695566, 52.178799156233794],
            [-1.11275872199731, 52.178816634189324],
            [-1.120226993036977, 52.181666848143216],
            [-1.12468848578482, 52.183944993385154],
            [-1.124465357583203, 52.18420946215349],
            [-1.126908227132244, 52.185692139117833],
            [-1.127084377218424, 52.183493425613058],
            [-1.128212241552893, 52.18206325510679],
            [-1.128022487486975, 52.180996458610124],
            [-1.125187208944742, 52.176789416250749],
            [-1.120908783457601, 52.174012766781836],
            [-1.123840201491938, 52.172849595048412],
            [-1.125180224595591, 52.171478566908412],
            [-1.126438867434173, 52.170824378098835],
            [-1.127599065526728, 52.169818811247339],
            [-1.128600504698381, 52.167880625074964],
            [-1.133573319900204, 52.172090718831925],
            [-1.134816656562553, 52.172748069170147],
            [-1.137247339137288, 52.173258554010417],
            [-1.138542071705464, 52.170804566190661],
            [-1.144215188913206, 52.166017847594745],
            [-1.147925167526191, 52.167358233886638],
            [-1.151240436406928, 52.169321415386712],
            [-1.152340584318699, 52.168982282983578],
            [-1.153025408603129, 52.169724435660513],
            [-1.157533286416258, 52.169494176560853],
            [-1.156491589534353, 52.165814942856066],
            [-1.156621505137203, 52.16358258634056],
            [-1.15431622693457, 52.162565424159347],
            [-1.152003787380076, 52.162007589134952],
            [-1.150723735638096, 52.162129647105864],
            [-1.147461802766725, 52.161076690558637],
            [-1.145013078379271, 52.159781395259806],
            [-1.143550294568056, 52.157924996824782],
            [-1.144483343900415, 52.155691283305224],
            [-1.145310972552031, 52.155141655957742],
            [-1.144866865275788, 52.154518079668954],
            [-1.145555781732434, 52.154035774508735],
            [-1.144420538083642, 52.152943268950338],
            [-1.140455490319961, 52.151253866120626],
            [-1.140582950198215, 52.150478896276468],
            [-1.139723297295159, 52.14927866467491],
            [-1.138740424620128, 52.149240925546501],
            [-1.138079852941994, 52.148709245736327],
            [-1.137303772640286, 52.148861810156362],
            [-1.137423987730946, 52.146421709326603],
            [-1.138443241179071, 52.146161233437532],
            [-1.139856891057612, 52.146364848647487],
            [-1.140774979031278, 52.145816811146638],
            [-1.141578277187845, 52.145993482302714],
            [-1.148998962288553, 52.145628274269903],
            [-1.169322369547262, 52.143204472957287],
            [-1.172541476734142, 52.143213608325908],
            [-1.176447215767689, 52.143787571532997],
            [-1.176518731089269, 52.143316957966157],
            [-1.17690806475342, 52.143124576589074],
            [-1.177273410432133, 52.143440900358641],
            [-1.17771135190242, 52.143148159439924],
            [-1.178380758279999, 52.143776777749586],
            [-1.179249547326103, 52.143656055961536],
            [-1.179276276985343, 52.144348525745563],
            [-1.180272591869355, 52.144207105785974],
            [-1.180916163558187, 52.144573900660731],
            [-1.180541066687492, 52.144787072699089],
            [-1.181063728288765, 52.144974111982997],
            [-1.181537953769938, 52.144774212983698],
            [-1.181976100043452, 52.145267244619042],
            [-1.182609251979543, 52.145007306397076],
            [-1.182837580563253, 52.145307379149699],
            [-1.183306774952366, 52.145222519113496],
            [-1.183761967241179, 52.144864243209604],
            [-1.184673386752137, 52.145290410630636],
            [-1.185043327881709, 52.144958512729247],
            [-1.184779097402566, 52.144703149674193],
            [-1.185870655631354, 52.144467039158208],
            [-1.185876473540814, 52.144147909628444],
            [-1.188222315361963, 52.144609112995106],
            [-1.187952398944563, 52.144906645605118],
            [-1.188839901603207, 52.145123132969772],
            [-1.18843172016954, 52.145309130449121],
            [-1.187309878371503, 52.148092118688858],
            [-1.189503441940063, 52.149144732897547],
            [-1.186576286859965, 52.152500591008447],
            [-1.187122278417528, 52.153740572467612],
            [-1.185544450312261, 52.155461296728994],
            [-1.186663506769997, 52.156858974414178],
            [-1.186540912319088, 52.159415076976451],
            [-1.188466947398831, 52.159532630859829],
            [-1.189988571468898, 52.158977572891729],
            [-1.190013892250364, 52.159354455801072],
            [-1.191034766510642, 52.159326395484733],
            [-1.192831666566827, 52.160199107861331],
            [-1.19474160180606, 52.15999638127586],
            [-1.196007104653412, 52.158967487715039],
            [-1.195969302459172, 52.158144584001676],
            [-1.197998880017336, 52.155182484351464],
            [-1.200727348411187, 52.157089937731342],
            [-1.202591486047006, 52.157068385624243],
            [-1.204930292966951, 52.157479757180901],
            [-1.206337130056651, 52.158267819620804],
            [-1.211517004724422, 52.159717675927787],
            [-1.212616884509915, 52.157696727620639],
            [-1.217243468105714, 52.15877851734988],
            [-1.217050253597653, 52.15920968605009],
            [-1.218073294109674, 52.159640828611266],
            [-1.223191182787302, 52.160399277776612],
            [-1.223760170618992, 52.15962443169812],
            [-1.223354628124171, 52.159659523963846],
            [-1.223517033485973, 52.159315351719528],
            [-1.223049846672948, 52.159280809249189],
            [-1.224225477448121, 52.158758095639421],
            [-1.22497269345137, 52.157818987187213],
            [-1.224115163849901, 52.156274151504839],
            [-1.225554718160476, 52.156289002868149],
            [-1.225893386074502, 52.156568137338489],
            [-1.226089018037472, 52.156244857937644],
            [-1.227443586009213, 52.156018179258091],
            [-1.22755551481082, 52.154702679967464],
            [-1.228394170753186, 52.153961942606799],
            [-1.229586864226746, 52.154047954712091],
            [-1.230217853751929, 52.153650190518668],
            [-1.231489406165004, 52.153994729680477],
            [-1.231659129284387, 52.153475275968042],
            [-1.232097234218918, 52.153667830805013],
            [-1.232934492172907, 52.153087984936974],
            [-1.23321399278427, 52.153239046506457],
            [-1.232654922126451, 52.151835566637054],
            [-1.23143232283551, 52.151367287548531],
            [-1.230681066954155, 52.150434555437101],
            [-1.230920458697774, 52.149603580717667],
            [-1.231597549481523, 52.149070350508339],
            [-1.232490188560046, 52.14917505769796],
            [-1.233944886867084, 52.147782866969393],
            [-1.234338471790133, 52.148949712599624],
            [-1.235910483207915, 52.149494847592486],
            [-1.235698247438174, 52.149684074997943],
            [-1.237664374939467, 52.149661730925047],
            [-1.238633143744617, 52.150103134025173],
            [-1.239422452337191, 52.152672359691024],
            [-1.241478464028655, 52.153819315854435],
            [-1.241517722189647, 52.154175597996172],
            [-1.242909142303001, 52.154697899997366],
            [-1.247032707164919, 52.154775532292824],
            [-1.250631720958026, 52.153536156872327],
            [-1.253727739901762, 52.155794448767345],
            [-1.253035889919309, 52.156782638030691],
            [-1.254881156426565, 52.158852261722501],
            [-1.253555800732324, 52.159957825247773],
            [-1.254169390363301, 52.160943482333586],
            [-1.254376450128842, 52.162637726999378],
            [-1.255065524814712, 52.163045758255265],
            [-1.254570419590144, 52.163190977184804],
            [-1.255288971374383, 52.164636711832827],
            [-1.259124140552989, 52.164265264476754],
            [-1.261183898684732, 52.164365387121187],
            [-1.264307400362374, 52.165832391420857],
            [-1.275099619883489, 52.167670329280135],
            [-1.274460045542489, 52.169327864139831],
            [-1.273521721095942, 52.169483922698127],
            [-1.274038434348128, 52.170707128211021],
            [-1.273568563346618, 52.171187032638954],
            [-1.274777104846368, 52.171590951263141],
            [-1.274969326199479, 52.172719554427317],
            [-1.275715529902455, 52.172962387333293],
            [-1.276913348851623, 52.172859147888708],
            [-1.27609051519312, 52.174274619063887],
            [-1.27694083876887, 52.174681711641611],
            [-1.277308070562222, 52.175392420331079],
            [-1.277851520583946, 52.175245603818119],
            [-1.278049603095738, 52.175565981999647],
            [-1.277763413204462, 52.1759004794392],
            [-1.278194096366481, 52.176215987090721],
            [-1.277709558308362, 52.176878327049337],
            [-1.27860882505397, 52.177699274252468],
            [-1.278044628028312, 52.178041962358563],
            [-1.278420924271577, 52.178556727540276],
            [-1.278148543487836, 52.180205735079923],
            [-1.279110485385997, 52.180139689676743],
            [-1.279305523588887, 52.180559842390451],
            [-1.280790981732907, 52.180951902719116],
            [-1.281123988000715, 52.182250374475416],
            [-1.279439175317577, 52.184146105437058],
            [-1.278924715825057, 52.186111901805702],
            [-1.28120915139147, 52.191939143548502],
            [-1.281151906137, 52.195761585783238],
            [-1.277587432275898, 52.195794667807995],
            [-1.274088672099355, 52.195385710154859],
            [-1.271437955640849, 52.196353847274324],
            [-1.268017594476703, 52.196516993965943],
            [-1.262553719939744, 52.195948046721384],
            [-1.2549966176751, 52.197817384560786],
            [-1.254794581742459, 52.198875199303629],
            [-1.257374691373369, 52.205600222631212],
            [-1.257322395584629, 52.208562284140534],
            [-1.259875830050937, 52.211839195039204],
            [-1.260931167462049, 52.215978762579716],
            [-1.264166873123734, 52.216542002501349],
            [-1.267753717699826, 52.217742963086991],
            [-1.270970313689197, 52.219218436915604],
            [-1.27225034974014, 52.220252153999901],
            [-1.273379644072299, 52.220659188118205],
            [-1.273795992147179, 52.221339635913324],
            [-1.275290108442328, 52.221999730719077],
            [-1.27637415198473, 52.223123902413064],
            [-1.276922153679865, 52.225167210346143],
            [-1.275689949601537, 52.226307748119837],
            [-1.273524745336218, 52.226750265893322],
            [-1.27260777776374, 52.227496228476099],
            [-1.27844613959121, 52.228692726624523],
            [-1.278632015024781, 52.230052329707362],
            [-1.280161122195932, 52.233370170677553],
            [-1.28272754595129, 52.236305005844223],
            [-1.283986022125906, 52.237087624797994],
            [-1.284657901683442, 52.238562543059388],
            [-1.279983994888397, 52.238617749383629],
            [-1.275386655526173, 52.238016933525238],
            [-1.266905284474608, 52.235227872938466],
            [-1.266367207835941, 52.236689985133204],
            [-1.267635004373332, 52.237423389132964],
            [-1.266079341460283, 52.238872890833484],
            [-1.266782946351812, 52.239454452384557],
            [-1.266165933364096, 52.240815378939054],
            [-1.264812942549058, 52.241866045249793],
            [-1.258083646164255, 52.244659565899553],
            [-1.254493680258485, 52.247552578557844],
            [-1.251760014294464, 52.248625838288724],
            [-1.250993386025302, 52.248658738732217],
            [-1.250497232456185, 52.248286980287169],
            [-1.246695863372886, 52.24908452594692],
            [-1.240582244746274, 52.247945831749966],
            [-1.237780854377277, 52.248419565106659],
            [-1.235173844963067, 52.249255909665756],
            [-1.234718562412233, 52.250432515194404],
            [-1.232383354311938, 52.251212120944373],
            [-1.232592128435144, 52.251507467385906],
            [-1.232072217953104, 52.251670412285975],
            [-1.232354498210051, 52.252035463977769],
            [-1.231714183621712, 52.252299217363401],
            [-1.231946477468534, 52.252674733408746],
            [-1.232440447068998, 52.252402835326606],
            [-1.233013374233159, 52.253243573670034],
            [-1.232822078538849, 52.253548008377322],
            [-1.232394111243735, 52.253475101617632],
            [-1.232472929991082, 52.254086069191644],
            [-1.231507789342755, 52.254579665032828],
            [-1.232094519871274, 52.254791162068869],
            [-1.231096425237491, 52.254817034051491],
            [-1.231158492393156, 52.255124913366558],
            [-1.230156157284583, 52.254802817278716],
            [-1.230179781776612, 52.255384656918082],
            [-1.228026318060643, 52.255886655577775],
            [-1.227033586761661, 52.257035442304691],
            [-1.226448967812966, 52.256870684660377],
            [-1.226559334856566, 52.257685048021145],
            [-1.225785657596115, 52.258196932792281],
            [-1.225362767459418, 52.25833531077479],
            [-1.22469394849232, 52.258041428028335],
            [-1.223955461291273, 52.258718058042852],
            [-1.224211179757028, 52.258924721467444],
            [-1.223715815013428, 52.25901766498054],
            [-1.224355132270736, 52.259239435445039],
            [-1.223722966788044, 52.259950926032104],
            [-1.221870175907779, 52.260334315883334],
            [-1.221725021855226, 52.260591386856824],
            [-1.22064446841452, 52.260632810317475],
            [-1.219875705422446, 52.261525886074786],
            [-1.219337196099061, 52.261320041668426],
            [-1.217912126744492, 52.261788009442007],
            [-1.218384324511193, 52.262098610377535],
            [-1.217095274065532, 52.262661868625408],
            [-1.215899144979258, 52.26401959083659],
            [-1.216330622325271, 52.264230134981041],
            [-1.215880328884868, 52.264504952835146],
            [-1.21657033941205, 52.265344748810293],
            [-1.215921754658397, 52.265728834497239],
            [-1.215744996595085, 52.266362389987115],
            [-1.216633026230061, 52.266693738889138],
            [-1.216917834925362, 52.26749578139821],
            [-1.217605101578955, 52.267745776278247],
            [-1.217727566937922, 52.268197910109791],
            [-1.217470281860399, 52.268411977652505],
            [-1.217056698713951, 52.268264489999176],
            [-1.217019486529168, 52.268796480418267],
            [-1.217883634702557, 52.26898920776857],
            [-1.217689267813512, 52.2692917991683],
            [-1.218707276004485, 52.269652762470926],
            [-1.218573735055163, 52.27016343788091],
            [-1.21935018694241, 52.270344785584591],
            [-1.2187886988292, 52.271107062864765],
            [-1.219577890653449, 52.27214978173582],
            [-1.21874878339037, 52.27221083051559],
            [-1.22014688564319, 52.272964481328032],
            [-1.21975430267565, 52.273454567258398],
            [-1.2209751245483, 52.273539044070191],
            [-1.220161713326712, 52.273957128462897],
            [-1.220880250435384, 52.274018510037948],
            [-1.220489539324477, 52.274736069828641],
            [-1.220897867980153, 52.275020165741886],
            [-1.22060836299732, 52.275565776106788],
            [-1.219972147492369, 52.275739588097487],
            [-1.220038751040314, 52.276036713686594],
            [-1.221705574633963, 52.276435197266807],
            [-1.221579712982382, 52.27742871461156],
            [-1.220665380191666, 52.277747242060215],
            [-1.221787751982771, 52.278689650818563],
            [-1.221187713905301, 52.279389650381738],
            [-1.222311451503832, 52.280508275993668],
            [-1.223527582392392, 52.280788687146242],
            [-1.222893605661287, 52.280998491486507],
            [-1.223384877845729, 52.281657127400891],
            [-1.222557034700465, 52.282222576853776],
            [-1.222850368401698, 52.283386974313068],
            [-1.222434753435941, 52.284686957820625],
            [-1.222831240593646, 52.284648210710529],
            [-1.223848626861783, 52.285402905891594],
            [-1.223496689802956, 52.28624749464565],
            [-1.224245202476122, 52.286282979769659],
            [-1.223581931408099, 52.286822545345792],
            [-1.224211985680819, 52.286841968279738],
            [-1.225430941764493, 52.288148188869606],
            [-1.227686574779569, 52.289282267945019],
            [-1.227604241937801, 52.288620931241951],
            [-1.228224585024992, 52.28894774275485],
            [-1.228713041174694, 52.288680320017846],
            [-1.229818289240505, 52.288860147307631],
            [-1.229605919038348, 52.289270525404703],
            [-1.230965081089072, 52.28875793442856],
            [-1.231117473792485, 52.289101462602702],
            [-1.231850278119928, 52.289200629340257],
            [-1.230762870145697, 52.289513614100706],
            [-1.231600740572987, 52.289811256362491],
            [-1.231697936706687, 52.290210165485121],
            [-1.232466187722232, 52.289786314609586],
            [-1.233797163843315, 52.290311905032553],
            [-1.231844872686218, 52.294261317690946],
            [-1.228873353131537, 52.295154490419584],
            [-1.2289701551427, 52.297940361384626],
            [-1.226505560734323, 52.298168792021833],
            [-1.226429418108067, 52.300603803683416],
            [-1.224808452041882, 52.301472444569413],
            [-1.223896147597049, 52.302738602355056],
            [-1.223185000254452, 52.302814842927276],
            [-1.222980112511569, 52.303125463840587],
            [-1.220915813658347, 52.303340227461902],
            [-1.220920419013686, 52.305836002591988],
            [-1.217550651530013, 52.305967472151728],
            [-1.217129145759865, 52.30724761508413],
            [-1.215422950483802, 52.308930994784625],
            [-1.212555649424679, 52.310407030126754],
            [-1.210504728334664, 52.313558874982917],
            [-1.209275747074687, 52.31469513876656],
            [-1.209247902293662, 52.315187627768502],
            [-1.212026906225075, 52.316064777106533],
            [-1.21300588301052, 52.315706289961334],
            [-1.215174471362002, 52.316929025316341],
            [-1.215675076632545, 52.316577227474951],
            [-1.216266062149317, 52.317088208198506],
            [-1.223112295115978, 52.318535920567356],
            [-1.225908495866877, 52.318707107359742],
            [-1.225904450274525, 52.318939033461241],
            [-1.231324501169065, 52.320004714220069],
            [-1.234229942250103, 52.320144048719847],
            [-1.235091677613226, 52.320538914124242],
            [-1.237048495429721, 52.320303428702353],
            [-1.242121216493748, 52.3218869138393],
            [-1.242557764527554, 52.32166854887997],
            [-1.243721560302038, 52.321907954656687],
            [-1.244741766747743, 52.321446978495388],
            [-1.246397141988705, 52.321595998418601],
            [-1.246446522823576, 52.321971212805984],
            [-1.247648692869477, 52.321939314574863],
            [-1.248793819824313, 52.32328347073036],
            [-1.24928483533043, 52.323140047478994],
            [-1.250058265884469, 52.323388597691967],
            [-1.250738056242819, 52.32310072185377],
            [-1.251360520263505, 52.323688143048749],
            [-1.251992914963421, 52.323600445463747],
            [-1.252376993366394, 52.324055991472008],
            [-1.253408850909138, 52.324033745417751],
            [-1.254373825767223, 52.324760866722229],
            [-1.255140526298155, 52.324714456308094],
            [-1.257291641285515, 52.325498470611691],
            [-1.2609060967622, 52.327847840816489],
            [-1.265279213043569, 52.328360588516304],
            [-1.264434114924791, 52.329061076298395],
            [-1.264209985172723, 52.330630299555381],
            [-1.262465921762425, 52.332172965270843],
            [-1.263086950411911, 52.332863703373398],
            [-1.262456453212385, 52.334066278182611],
            [-1.258028429390841, 52.337242730647965],
            [-1.255045983439986, 52.338296521342507],
            [-1.253289402744151, 52.338279143761234],
            [-1.252995259745998, 52.338837384981083],
            [-1.250756284753544, 52.339136977128341],
            [-1.249267923144139, 52.339920483445788],
            [-1.249154632179273, 52.340630002765913],
            [-1.24858977560189, 52.34096175383246],
            [-1.245586080598555, 52.341856041718223],
            [-1.244955391051843, 52.341655125572011],
            [-1.243558400008624, 52.341976139873402],
            [-1.242672268970108, 52.342551243224349],
            [-1.241346079724166, 52.342592185610791],
            [-1.24087084595761, 52.342999994163236],
            [-1.239605506431797, 52.343257975683116],
            [-1.239298863067614, 52.343933876246126],
            [-1.238280996546537, 52.343797859904484],
            [-1.238151882136893, 52.344135964601492],
            [-1.233403380444405, 52.346499397995643],
            [-1.231496280576293, 52.34684752790313],
            [-1.231135403024118, 52.346663575688964],
            [-1.230313725863235, 52.346978285176476],
            [-1.230298310739423, 52.346682401741973],
            [-1.229835548933131, 52.347039901169033],
            [-1.227549221181549, 52.346485560155983],
            [-1.225912505717299, 52.346647467989598],
            [-1.223932337298554, 52.347475982192186],
            [-1.224423208323356, 52.347780381348706],
            [-1.224206595238914, 52.348078339128726],
            [-1.223959823869996, 52.347918488786306],
            [-1.223475763617563, 52.348147261304781],
            [-1.223795185955241, 52.348601574766818],
            [-1.222600064295239, 52.348858936643005],
            [-1.222513684665854, 52.349180223666622],
            [-1.220275652187769, 52.349718389558362],
            [-1.216907674966498, 52.349559471562536],
            [-1.216581532741315, 52.349904338724293],
            [-1.215009435691838, 52.349723991021385],
            [-1.21460724407086, 52.349968554597147],
            [-1.214100811391068, 52.349633443933101],
            [-1.213673868749403, 52.349948863713479],
            [-1.212896959656657, 52.349716236596244],
            [-1.213146812606724, 52.349535395513094],
            [-1.212625398452397, 52.349135448047711],
            [-1.212122401455627, 52.348938803424375],
            [-1.211335049710024, 52.349129542917815],
            [-1.210792625933794, 52.34875641822299],
            [-1.210676178402902, 52.349112558526627],
            [-1.209658786134249, 52.349277476510593],
            [-1.209447739842978, 52.349092661582155],
            [-1.209330648059801, 52.349566570158778],
            [-1.208152194269748, 52.349624311335198],
            [-1.207788397291083, 52.350182873048297],
            [-1.206412823944327, 52.350425376402704],
            [-1.206195532652083, 52.350835676506698],
            [-1.204219234563009, 52.351253926197508],
            [-1.203460982041156, 52.351781948265426],
            [-1.202492920788808, 52.35181138247389],
            [-1.20232020092989, 52.352353235627483],
            [-1.200379655321185, 52.352492960938349],
            [-1.199999186130922, 52.352260233022811],
            [-1.199758200727829, 52.352596639920073],
            [-1.198485473305805, 52.352666233178944],
            [-1.198234307208723, 52.352915361316832],
            [-1.196994200544223, 52.352723539864662],
            [-1.196748409188076, 52.352999672503493],
            [-1.195654799751036, 52.353068654257186],
            [-1.192423849760077, 52.351745717171667],
            [-1.19263820022915, 52.351423527735307],
            [-1.192033177280154, 52.351028312215924],
            [-1.191509073648113, 52.35175115141098],
            [-1.189378777449348, 52.352551989158876],
            [-1.18897976689571, 52.352453055126006],
            [-1.188271745197784, 52.353193497763087],
            [-1.187839378244364, 52.353071854884512],
            [-1.186629858588957, 52.354255662880604],
            [-1.184256519188018, 52.353651337557395],
            [-1.183832170744929, 52.354609480112124],
            [-1.179248421356457, 52.354360175452754],
            [-1.178206980439725, 52.355096444520392],
            [-1.17498712753419, 52.356273333185101],
            [-1.174706723108287, 52.360528320672067],
            [-1.172140387580708, 52.361284447832865],
            [-1.174820593113554, 52.364349125354401],
            [-1.187096848356657, 52.378582296168702],
            [-1.202199583646556, 52.395991535095256],
            [-1.202598282908014, 52.396783573184649],
            [-1.201626595613241, 52.396714983963435],
            [-1.199113568368385, 52.396221504810363],
            [-1.197953917809378, 52.395658943196857],
            [-1.197412416385102, 52.396020275902771],
            [-1.196354773371012, 52.395668764082693],
            [-1.194078038136767, 52.396167515536135],
            [-1.190399075104045, 52.395226272526799],
            [-1.190250931078495, 52.396495589666749],
            [-1.189395025953972, 52.396365659418286],
            [-1.188060664406625, 52.394998967414445],
            [-1.188272335784874, 52.393954846609695],
            [-1.187784883003466, 52.393289812016228],
            [-1.184587600321807, 52.39254588512398],
            [-1.184005534626938, 52.39215438701352],
            [-1.180463764492955, 52.393197944167262],
            [-1.178003774087352, 52.392878794612372],
            [-1.177466503838881, 52.393163648112022],
            [-1.177281116784529, 52.394045208402929],
            [-1.175985346011152, 52.394733835347516],
            [-1.17521354749947, 52.394510888830467],
            [-1.174155111854238, 52.395000666666704],
            [-1.173937571904921, 52.39460896708438],
            [-1.173508816910757, 52.395139996530531],
            [-1.17126773658621, 52.395998171545408],
            [-1.169362720726754, 52.395625192512234],
            [-1.169410682014185, 52.396123593491843],
            [-1.168885866509016, 52.396366241050316],
            [-1.168471509933399, 52.396205098369322],
            [-1.168228182913462, 52.396796748638643],
            [-1.167907857744511, 52.396402516869514],
            [-1.166288968724123, 52.396834337551454],
            [-1.165767156049534, 52.397539994298086],
            [-1.165982473421479, 52.397968553549653],
            [-1.166663004386036, 52.397809727266875],
            [-1.167011914928501, 52.398012669762458],
            [-1.166890135842461, 52.398313886795727],
            [-1.165852594182772, 52.398309269838087],
            [-1.165185107143541, 52.398944670772728],
            [-1.164349751035718, 52.398661868689516],
            [-1.164234011075462, 52.399186984774666],
            [-1.163869108926719, 52.398974930716022],
            [-1.162765796488589, 52.399338425004821],
            [-1.16232242892602, 52.399079060915525],
            [-1.160387023363011, 52.399607452106686],
            [-1.160244206911088, 52.39923333947408],
            [-1.158884007940062, 52.399268624262312],
            [-1.158713365214105, 52.399894935257109],
            [-1.15659568825456, 52.400034484579891],
            [-1.155079065969736, 52.39933140294584],
            [-1.154089947665015, 52.39963719517025],
            [-1.153848336895923, 52.399359463487244],
            [-1.151980120528543, 52.39929034104383],
            [-1.152146733919408, 52.398802461667202],
            [-1.15151221819225, 52.398780829032376],
            [-1.151202173296888, 52.398317399928516],
            [-1.151519750393089, 52.397774864757274],
            [-1.150874439145117, 52.397627286505355],
            [-1.149736477577985, 52.397954449330555],
            [-1.150009624183953, 52.397506896223923],
            [-1.149483087579331, 52.39699156023309],
            [-1.14831136838505, 52.39846203729136],
            [-1.147855246155327, 52.398335583736042],
            [-1.146650621584427, 52.398840245185418],
            [-1.146326716033131, 52.398564601156721],
            [-1.145273210436609, 52.399233963554771],
            [-1.144514776612465, 52.398931798178822],
            [-1.143789505289376, 52.399574752410658],
            [-1.143512411979944, 52.399311127034913],
            [-1.142282264216275, 52.399687896466382],
            [-1.140314710859553, 52.401554387632025],
            [-1.138549050200508, 52.402027016880567],
            [-1.138836346903992, 52.40304951234166],
            [-1.138184086413971, 52.403181413835554],
            [-1.137939280349664, 52.403901553343765],
            [-1.138621903146283, 52.404551134223929],
            [-1.138162947383909, 52.405398275767709],
            [-1.136302942758321, 52.406402411697655],
            [-1.136652988212766, 52.407597984346395],
            [-1.134826423158747, 52.409666795904762],
            [-1.134924302962958, 52.411276780109375],
            [-1.134404726695704, 52.412284389885549],
            [-1.133388738993377, 52.412963810276054],
            [-1.132251035084135, 52.412876353492798],
            [-1.132646782094851, 52.413237970331117],
            [-1.132393988253741, 52.41360831548797],
            [-1.12907818598275, 52.415196808781936],
            [-1.128888087794964, 52.415815740961477],
            [-1.129282680709493, 52.416086558154213],
            [-1.125716511014183, 52.417680306221477],
            [-1.12458747712404, 52.41789221783975],
            [-1.123348402337746, 52.417498254507116],
            [-1.123567536685724, 52.417866683650466],
            [-1.122795054767018, 52.41804166235088],
            [-1.12226155754686, 52.417806653085393],
            [-1.122243449819817, 52.417237431298432],
            [-1.121626958588779, 52.417480088325718],
            [-1.120769573156486, 52.417192320607441],
            [-1.119627378956889, 52.418212317160041],
            [-1.120482728179003, 52.418750907911004],
            [-1.119814134972218, 52.418876293882633],
            [-1.119448880931155, 52.419496604431465],
            [-1.118915209064084, 52.419491731054819],
            [-1.118003471892914, 52.420895516977708],
            [-1.116655200057766, 52.420960973865427],
            [-1.116241581398211, 52.421128700150113],
            [-1.116540503181553, 52.421402440965416],
            [-1.116017542999302, 52.421522600040475],
            [-1.115482938903716, 52.421416113655596],
            [-1.115892321564995, 52.421092825802894],
            [-1.113573975449803, 52.421575335604956],
            [-1.111925557887924, 52.421135030738739],
            [-1.111957078453815, 52.421395986774101],
            [-1.11127495229647, 52.421313546520054],
            [-1.110737467673558, 52.421789589949675],
            [-1.109831202195318, 52.421737821156974],
            [-1.109545166726772, 52.422432418137916],
            [-1.108755557493877, 52.421991339279977],
            [-1.107095262649471, 52.422359107725192],
            [-1.106397371995448, 52.423347267967216],
            [-1.105515712107483, 52.423313632732999],
            [-1.105344587559869, 52.423776239188193],
            [-1.104625423776187, 52.423706066975818],
            [-1.104337291384376, 52.424209141660633],
            [-1.103705614802958, 52.423958021785332],
            [-1.103514630425773, 52.424601180616705],
            [-1.103390938049606, 52.424174101699791],
            [-1.103053954216457, 52.424329776449561],
            [-1.102595311986188, 52.424103337513486],
            [-1.102618864601065, 52.424536849841928],
            [-1.101980866926607, 52.424814303867215],
            [-1.101191702622929, 52.425076221332716],
            [-1.098217400619388, 52.425038298797936],
            [-1.097550366008049, 52.425943931375265],
            [-1.096406506892327, 52.42650428615589],
            [-1.09557127654161, 52.426498801645863],
            [-1.095259714688439, 52.42706280940822],
            [-1.094460044123383, 52.426972180980002],
            [-1.093372209636952, 52.427665992223844],
            [-1.093428969389272, 52.428268779023163],
            [-1.093616358188772, 52.428095802802652],
            [-1.094060271714557, 52.428323062658407],
            [-1.094608223347995, 52.430220619983359],
            [-1.092985706535166, 52.431521675525744],
            [-1.092109030436882, 52.431810731545006],
            [-1.092428905266604, 52.432063117123221],
            [-1.09188847058497, 52.432594792391022],
            [-1.091271906635253, 52.432393169369085],
            [-1.09089688095044, 52.433105917111078],
            [-1.090043412893161, 52.433408621719735],
            [-1.088765281509976, 52.432905214037966],
            [-1.088753115744198, 52.433423861777896],
            [-1.08825274276996, 52.433294141588924],
            [-1.087070059026919, 52.433867592548488],
            [-1.086370663487663, 52.43383072659288],
            [-1.086459788422322, 52.434073254545055],
            [-1.085793177752551, 52.434229031229364],
            [-1.086163274981256, 52.434328087381132],
            [-1.085831220962375, 52.434665355755243],
            [-1.084645509384752, 52.435168634656158],
            [-1.084394450410796, 52.436281491194968],
            [-1.082798181044081, 52.436009304727854],
            [-1.080818595018196, 52.436274436976596],
            [-1.080299351579954, 52.435990802097841],
            [-1.079404068852601, 52.436317378929431],
            [-1.078415423791016, 52.435759473025712],
            [-1.077751603424876, 52.436060869468889],
            [-1.077677215496904, 52.435678200759341],
            [-1.076206525101301, 52.435312505753792],
            [-1.076656290194848, 52.434906957575883],
            [-1.074379348153071, 52.434481001548882],
            [-1.074064929328934, 52.43404250989353],
            [-1.073850986442229, 52.434284472900096],
            [-1.072987161675595, 52.434232754759478],
            [-1.072889720182203, 52.433969473486911],
            [-1.071743360039944, 52.433993747340232],
            [-1.071384413658872, 52.433645701330398],
            [-1.070932501857626, 52.433661931487933],
            [-1.070912011990003, 52.433936874665662],
            [-1.069337870921065, 52.433529824673954],
            [-1.069155911732306, 52.433020439143036],
            [-1.069811212705566, 52.432844888426416],
            [-1.069297729016145, 52.432432688246458],
            [-1.069869169630819, 52.4326071003564],
            [-1.069962388100941, 52.432301262969062],
            [-1.069096378971685, 52.432075985625929],
            [-1.068513971127913, 52.431026723724358],
            [-1.069265772104535, 52.430667634060434],
            [-1.069246596276131, 52.430251230591082],
            [-1.068485499099633, 52.430144547462575],
            [-1.068944798290791, 52.429985438545607],
            [-1.06844407592856, 52.429807083572406],
            [-1.068958128953008, 52.429562098567565],
            [-1.068402501779433, 52.429476810728538],
            [-1.068132032015828, 52.42884176032873],
            [-1.066396849885002, 52.428827178271156],
            [-1.066089973566253, 52.428176552081567],
            [-1.064466426056536, 52.428447816847395],
            [-1.064020071372028, 52.427995667505961],
            [-1.063066673423518, 52.428151743548561],
            [-1.06266065996123, 52.427462563431249],
            [-1.062078684857172, 52.427581117765342],
            [-1.06106650580477, 52.4263504000564],
            [-1.061372187116443, 52.424837053406648],
            [-1.059774966628739, 52.423874986278719],
            [-1.059916090737971, 52.423462551813962],
            [-1.059067458617223, 52.42312226154543],
            [-1.058295154310422, 52.423548551076763],
            [-1.056157187233212, 52.423123347313805],
            [-1.054896010187436, 52.421927448183482],
            [-1.053295825192224, 52.421870597874729],
            [-1.053111524708209, 52.421549964746639],
            [-1.051926511755185, 52.422019657949136],
            [-1.050972082999482, 52.421885239412035],
            [-1.049873201831949, 52.422245920786253],
            [-1.050006937551453, 52.425192236723149],
            [-1.049509516836489, 52.425823858683898],
            [-1.044940939094569, 52.429328358692999],
            [-1.042288282562999, 52.430657261593346],
            [-1.039261389779242, 52.430722618385744],
            [-1.037655846864342, 52.433734821094966],
            [-1.035028571115862, 52.435091638331315],
            [-1.034286484954803, 52.436268712513801],
            [-1.033840931429071, 52.440432100606188],
            [-1.035146521399612, 52.443229762839138],
            [-1.037231530620185, 52.444432575772524],
            [-1.038219608127904, 52.443717790010105],
            [-1.039696257402545, 52.443334211394586],
            [-1.042790160823217, 52.443475261662485],
            [-1.044111016682301, 52.444509940968047],
            [-1.044523034759271, 52.445731459329821],
            [-1.041134830370698, 52.44836338497619],
            [-1.037865685048069, 52.45152301518084],
            [-1.035793996879605, 52.452322470552204],
            [-1.034661704656555, 52.453295879573233],
            [-1.03041859924701, 52.454795830564784],
            [-1.028734414421642, 52.456096401889354],
            [-1.027753131634724, 52.45848785958615],
            [-1.02691542178407, 52.458968248186132],
            [-1.025067702823924, 52.45899798862294],
            [-1.024654641462916, 52.458708691879643],
            [-1.022710550050197, 52.460100534155181],
            [-1.020371291247085, 52.460988312327579],
            [-1.016784882518189, 52.461761399364924],
            [-1.014718623850244, 52.462938125869947],
            [-1.013806975386996, 52.462830738059466],
            [-1.010844968163508, 52.463709540086157],
            [-1.010922871320079, 52.464042833405138],
            [-1.005649242214086, 52.465009144228802],
            [-1.004328370424905, 52.46594561691839],
            [-1.004412917566246, 52.46630863854552],
            [-1.003468756736645, 52.466270123210563],
            [-1.003447342464517, 52.466696983404518],
            [-1.002839338166806, 52.466895042365401],
            [-1.001439095571015, 52.46957763422639],
            [-1.001027808423861, 52.469667661957608],
            [-1.000704944424613, 52.470923581005842],
            [-0.997926280798126, 52.47101875404195],
            [-0.996311154637809, 52.470378439141896],
            [-0.994989125287945, 52.469410646731795],
            [-0.994066500007709, 52.469651838726435],
            [-0.994046140143509, 52.470028360423122],
            [-0.992853449895704, 52.469523746412904],
            [-0.992343104932234, 52.469825072603953],
            [-0.990852609246627, 52.469405108014236],
            [-0.990462061827352, 52.46987466796211],
            [-0.989484892419768, 52.47005332564828],
            [-0.98972859330881, 52.470275669782581],
            [-0.989313128179204, 52.47048249551171],
            [-0.985211481819581, 52.471324846171669],
            [-0.984285686982464, 52.471827553636658],
            [-0.984223690118056, 52.471578888237808],
            [-0.982735208749297, 52.471583182511658],
            [-0.982138906906883, 52.470564843663901],
            [-0.979963107093802, 52.470435525618569],
            [-0.979334894726812, 52.470100162879696],
            [-0.97898174629446, 52.470220283728551],
            [-0.979184448728238, 52.470494439946322],
            [-0.978539187397249, 52.470323449092156],
            [-0.976799141940549, 52.471020449852865],
            [-0.976237930175537, 52.470653284780198],
            [-0.975534167067628, 52.471227070572965],
            [-0.975060628301394, 52.471015292814606],
            [-0.975023914453069, 52.471771961448063],
            [-0.972096900127539, 52.472416350825362],
            [-0.971651299270099, 52.473027418341353],
            [-0.972720493260653, 52.473202127872177],
            [-0.972809084059605, 52.473760297968809],
            [-0.971679467129829, 52.474345646891692],
            [-0.971436673446721, 52.474845197534997],
            [-0.970437091869714, 52.475029796789549],
            [-0.970507363595935, 52.475614780151382],
            [-0.96926413013436, 52.475514053817527],
            [-0.969245856563854, 52.476170189310245],
            [-0.967861485348097, 52.477192910030929],
            [-0.964676568179109, 52.476823453897843],
            [-0.964322358297299, 52.476420284086352],
            [-0.958898952630685, 52.475764944276115],
            [-0.952420541572975, 52.477445601899738],
            [-0.951888887829146, 52.477388749953533],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000152",
        LAD13CDO: "34UD",
        LAD13NM: "East Northamptonshire",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.511958237897254, 52.643600783162555],
            [-0.511339110697563, 52.643160563392129],
            [-0.5105459989301, 52.64336097469738],
            [-0.510432804703896, 52.642951382823838],
            [-0.509555115331877, 52.643161512882777],
            [-0.509647573897205, 52.642441637058781],
            [-0.509045973025131, 52.642399905019325],
            [-0.506949832438982, 52.641021338727469],
            [-0.504023015639753, 52.640082674735581],
            [-0.503233734850106, 52.640056282508816],
            [-0.502275807592864, 52.64048135932974],
            [-0.499174992399991, 52.639276053860598],
            [-0.497093995646547, 52.640056127379871],
            [-0.495037100698806, 52.64021792249202],
            [-0.494784665075286, 52.640295630773188],
            [-0.493915157813898, 52.640051727382598],
            [-0.492207261807196, 52.636854550505781],
            [-0.491125138656382, 52.636349891838456],
            [-0.490816645778169, 52.635351609387101],
            [-0.488945398304595, 52.633433452700615],
            [-0.488835275186454, 52.632812599714057],
            [-0.488070744162848, 52.632486378947426],
            [-0.486119083459401, 52.63440341614006],
            [-0.485164562275046, 52.632817870474177],
            [-0.476771998130355, 52.630545344477412],
            [-0.475322624936218, 52.628883226665877],
            [-0.47351516430245, 52.628216228119705],
            [-0.470971434860428, 52.623749275560719],
            [-0.471074401197374, 52.621345624636916],
            [-0.472106830152561, 52.621266341451616],
            [-0.470973130015204, 52.617935088568068],
            [-0.47480275369763, 52.616892100036516],
            [-0.473741167197243, 52.614415007095054],
            [-0.477231850359689, 52.614658621814485],
            [-0.479648797185992, 52.614431636312638],
            [-0.481036117342025, 52.602768861926023],
            [-0.485451588914456, 52.602161897522087],
            [-0.485341297957183, 52.600136704615551],
            [-0.485860440299779, 52.598912529708471],
            [-0.48864091801276, 52.596890993611375],
            [-0.490718849351505, 52.590516184612],
            [-0.491440407565127, 52.59013878176934],
            [-0.492671910807894, 52.590129288023192],
            [-0.494161888140698, 52.589685225918345],
            [-0.494552979597861, 52.588654480663195],
            [-0.497663586529066, 52.586832926033978],
            [-0.49173746188984, 52.581271531676691],
            [-0.489435183598776, 52.580536423656547],
            [-0.485344548596375, 52.579962717992061],
            [-0.478847118300218, 52.577420497454995],
            [-0.480748408642736, 52.576937833572089],
            [-0.481467495238468, 52.576286245424441],
            [-0.478827882406485, 52.573627100601122],
            [-0.475399799622763, 52.574352612303564],
            [-0.468921283007803, 52.574908258985978],
            [-0.461018672945698, 52.576006968354513],
            [-0.447706333255686, 52.578666167302337],
            [-0.435408115862931, 52.580487660654391],
            [-0.425040802425812, 52.582394713879488],
            [-0.424645737487575, 52.581475106071856],
            [-0.419995556019196, 52.581336732320956],
            [-0.418189779713942, 52.579715877454603],
            [-0.415394757927695, 52.578724595593933],
            [-0.415092008960566, 52.575988268672738],
            [-0.415905231525235, 52.573365772055951],
            [-0.414731698846749, 52.572271181377268],
            [-0.415709125417182, 52.570086033743792],
            [-0.414647190005194, 52.569500010728845],
            [-0.411945414036306, 52.568888399581049],
            [-0.407373866957331, 52.56580251694367],
            [-0.407305045581504, 52.564036710614396],
            [-0.408741873591779, 52.562125719551091],
            [-0.409864202382321, 52.561605849605499],
            [-0.411786081801737, 52.561780891890614],
            [-0.413381194740255, 52.561485804653678],
            [-0.415267915713715, 52.562104461095799],
            [-0.416596580330566, 52.561956808130461],
            [-0.417073145108572, 52.561422837953579],
            [-0.416922452862388, 52.560732133551774],
            [-0.419608256329924, 52.558943796720158],
            [-0.417115238861796, 52.55586802911445],
            [-0.416382895765089, 52.555641558779762],
            [-0.41605823559845, 52.552696345632874],
            [-0.415353722073856, 52.551535203805962],
            [-0.414092892345356, 52.551528210231623],
            [-0.41175519456009, 52.550790198638808],
            [-0.411238868159734, 52.54993544303958],
            [-0.403320619726713, 52.548906460519788],
            [-0.402075614264025, 52.548303464473562],
            [-0.402219087374686, 52.546789558551097],
            [-0.403693073358197, 52.545145253650908],
            [-0.4038189420546, 52.543748887035946],
            [-0.403672104149451, 52.542796583016852],
            [-0.402542672831118, 52.541857995023825],
            [-0.402405269696232, 52.54068734054551],
            [-0.401299012134714, 52.538666563414871],
            [-0.402658333015438, 52.534893503912905],
            [-0.404617332405505, 52.533564114727163],
            [-0.404684179044904, 52.532289224066638],
            [-0.407155657173833, 52.53145939416774],
            [-0.410081722805284, 52.531184057572332],
            [-0.411932819899015, 52.529729026458789],
            [-0.412250801124082, 52.528760490414591],
            [-0.413361623721088, 52.528412158983684],
            [-0.41383649175304, 52.52742504292317],
            [-0.413618465952364, 52.525661726459099],
            [-0.413080268476175, 52.525175302978738],
            [-0.411691110191322, 52.525477639990655],
            [-0.411862338124485, 52.524735500890593],
            [-0.408735566138393, 52.522500650336454],
            [-0.40631016031772, 52.522357429075811],
            [-0.406512479822786, 52.522061658928649],
            [-0.404324413282461, 52.521674345161493],
            [-0.40048690737375, 52.521859002048885],
            [-0.398508261123664, 52.5208621367494],
            [-0.396765363300234, 52.520598485007227],
            [-0.394663600356061, 52.519788693543916],
            [-0.390765297716893, 52.519421971915683],
            [-0.390847643851073, 52.51870112913943],
            [-0.386640417292175, 52.516712621154447],
            [-0.387478524449052, 52.516162117351293],
            [-0.383457034745508, 52.513781326353651],
            [-0.380911286935845, 52.512757532529463],
            [-0.381050633238488, 52.512534668041248],
            [-0.379496473884958, 52.511919101948223],
            [-0.377943211864411, 52.510640003358354],
            [-0.375268946972726, 52.510911703145361],
            [-0.373027910042226, 52.510783812446526],
            [-0.371481135843873, 52.510331874883569],
            [-0.368697996278483, 52.508767793775952],
            [-0.362274279171162, 52.507357409204609],
            [-0.358200329260596, 52.50576981096718],
            [-0.355803891032868, 52.505905565697972],
            [-0.354362154850916, 52.506477123910003],
            [-0.354095714314594, 52.504813702513943],
            [-0.352192432463895, 52.503307314829577],
            [-0.353057767372284, 52.502860827079701],
            [-0.353243146246942, 52.50231586242883],
            [-0.354664233243462, 52.501860013419908],
            [-0.355311704933727, 52.501130863401144],
            [-0.355700136975918, 52.501263033209526],
            [-0.356234576683086, 52.500403737835398],
            [-0.357347892079681, 52.49994178286326],
            [-0.357992063199594, 52.498192107508231],
            [-0.358607163521324, 52.498210531789816],
            [-0.358531805727861, 52.497662840782269],
            [-0.359211781121742, 52.497323425762652],
            [-0.359185065675049, 52.496141653299397],
            [-0.359706938492027, 52.495853988222947],
            [-0.358929138780254, 52.494746314990834],
            [-0.359195151942819, 52.494366992504233],
            [-0.358158870039987, 52.493872503824129],
            [-0.358431780107724, 52.492952027207707],
            [-0.357678247479556, 52.492104518216664],
            [-0.358157630577388, 52.491018778145069],
            [-0.359468950108814, 52.49005785975924],
            [-0.358351698952951, 52.489406706672511],
            [-0.358246379848072, 52.48883342438959],
            [-0.356818618440498, 52.487781448638962],
            [-0.35639505109178, 52.486698455398319],
            [-0.355412672979944, 52.486225368768821],
            [-0.35466344917628, 52.485109070553349],
            [-0.353333595255708, 52.484753409627132],
            [-0.352098126976238, 52.483527829256346],
            [-0.351319690477848, 52.483472029262217],
            [-0.350112253275574, 52.482562399066978],
            [-0.349849710415532, 52.481589516809166],
            [-0.350291795972996, 52.480715471757186],
            [-0.34925445588657, 52.479276840429826],
            [-0.34846231583495, 52.479077874296493],
            [-0.347531165947576, 52.478069576369592],
            [-0.347817587038076, 52.477223934663122],
            [-0.349011046974485, 52.473545326607287],
            [-0.349735666131379, 52.472870328893826],
            [-0.348297946181353, 52.471238189568666],
            [-0.342296835653301, 52.46908632868935],
            [-0.343082187565543, 52.468480553358248],
            [-0.341608109204969, 52.466925142170837],
            [-0.344832200257117, 52.465823055656514],
            [-0.349489032714669, 52.462146996105865],
            [-0.350577765101406, 52.462909330364276],
            [-0.353527955177589, 52.460257646677924],
            [-0.354045694033453, 52.458018016181875],
            [-0.355477081101934, 52.456140837655887],
            [-0.360273871703597, 52.452487882808384],
            [-0.36349931690601, 52.450593210149854],
            [-0.361850437706508, 52.448986178636559],
            [-0.361896893569361, 52.448210004244558],
            [-0.363505607857074, 52.44708862679726],
            [-0.363080542277995, 52.44656756292644],
            [-0.367304810476286, 52.443550152745537],
            [-0.368816178324252, 52.441192003578038],
            [-0.370234588749935, 52.439913266347439],
            [-0.368877798160604, 52.439603253388519],
            [-0.370058927427106, 52.437895973130033],
            [-0.371296686346004, 52.438041597336998],
            [-0.371882251277834, 52.437042674052357],
            [-0.371884352518397, 52.436826021032353],
            [-0.37032479326452, 52.436830612908615],
            [-0.36935954773617, 52.43492291242022],
            [-0.367751898620464, 52.434479057021882],
            [-0.366512422097725, 52.435019381627725],
            [-0.364439464672701, 52.434032292013974],
            [-0.362425697684396, 52.433514414513184],
            [-0.365957492567644, 52.431648195174404],
            [-0.372383404966993, 52.430428632603167],
            [-0.379476100119751, 52.427942944729367],
            [-0.386956914837323, 52.423868034054642],
            [-0.388073343983556, 52.422734215130411],
            [-0.392040266840231, 52.420050483107339],
            [-0.397906104616759, 52.418312139184607],
            [-0.400963964958708, 52.415447658993806],
            [-0.407476812476857, 52.413476635493531],
            [-0.410851370283727, 52.412777590894017],
            [-0.413018188820857, 52.412584619055195],
            [-0.417090052001378, 52.411582769267959],
            [-0.418448413835542, 52.408134941055167],
            [-0.419629093192402, 52.407308278395519],
            [-0.420296361314244, 52.405049680760534],
            [-0.415953416043258, 52.403788581351776],
            [-0.416174286611349, 52.403573060511782],
            [-0.421923455271305, 52.400896016444619],
            [-0.421548316007144, 52.400739960274002],
            [-0.422452729197707, 52.400110078903872],
            [-0.424571683460303, 52.399384894843813],
            [-0.424851671981011, 52.399612500346421],
            [-0.431897311937949, 52.396363335954995],
            [-0.432936738838388, 52.39631328362232],
            [-0.437014919385269, 52.388171130778112],
            [-0.439272011741588, 52.386297570328935],
            [-0.443778428529786, 52.383841319366837],
            [-0.446238674548217, 52.38319756601134],
            [-0.448772833671481, 52.38371276522652],
            [-0.450902827274315, 52.38473599621512],
            [-0.454954511146808, 52.385686321082709],
            [-0.454763237806558, 52.384557256800683],
            [-0.456872338493378, 52.384077721255338],
            [-0.456848633918225, 52.382313390516394],
            [-0.462490811152234, 52.383029768204779],
            [-0.464073389357864, 52.381586620097181],
            [-0.468307213833311, 52.38287781196815],
            [-0.469905129322187, 52.379880254764267],
            [-0.474883255173552, 52.380251182985987],
            [-0.475844740267758, 52.38010534495843],
            [-0.476997607602382, 52.380674045880298],
            [-0.477972980494423, 52.380722573244149],
            [-0.478864329044362, 52.380137949953109],
            [-0.484777298165826, 52.381615580284411],
            [-0.485457875544237, 52.381306024622226],
            [-0.486754193838395, 52.379015561059262],
            [-0.489625662115197, 52.379210528494454],
            [-0.494212778997624, 52.381187712844429],
            [-0.495962704827346, 52.379499924081671],
            [-0.490477349165255, 52.375087389521006],
            [-0.488535394339107, 52.374007937615275],
            [-0.492320342184182, 52.371698862614487],
            [-0.4985464924203, 52.366823231389276],
            [-0.49950331310196, 52.366977439626766],
            [-0.4999018400503, 52.365185215216478],
            [-0.498716313904914, 52.360080402105467],
            [-0.496022226409842, 52.358372935233049],
            [-0.493005376543806, 52.357439904634745],
            [-0.48803029571683, 52.353694566070644],
            [-0.484294574917473, 52.352371792536331],
            [-0.484174464130319, 52.351762463678412],
            [-0.48207494950627, 52.350473183189074],
            [-0.480625394081251, 52.349800012189341],
            [-0.478288322687499, 52.349320390972451],
            [-0.477355550222281, 52.348393994412518],
            [-0.478445192088723, 52.346333815428522],
            [-0.480520775900979, 52.344415779036787],
            [-0.483247289399917, 52.340929947914262],
            [-0.472452494111819, 52.339559118831367],
            [-0.467790058426981, 52.338426124083369],
            [-0.466673828236788, 52.338912441410393],
            [-0.467115316820443, 52.335271424973939],
            [-0.465246925447448, 52.332599321256382],
            [-0.464813043058963, 52.330294685692458],
            [-0.46452728066346, 52.326086778540777],
            [-0.465383137982795, 52.322938458820978],
            [-0.474168068600777, 52.319737268028952],
            [-0.476278691428734, 52.318049026856315],
            [-0.478150080227633, 52.319267155597551],
            [-0.48189799487724, 52.320747644967753],
            [-0.487493715346123, 52.321059480716961],
            [-0.507047309051726, 52.319854371136493],
            [-0.516856413920078, 52.317636682170011],
            [-0.519846539102409, 52.317677781572371],
            [-0.520045049878457, 52.31678476886038],
            [-0.514068035151711, 52.314682273424012],
            [-0.518328232616475, 52.311653680877207],
            [-0.540621700860253, 52.291477664032435],
            [-0.542056304395581, 52.28971786586753],
            [-0.541980984350879, 52.28884121460932],
            [-0.541168068489193, 52.28787903103742],
            [-0.53599542318584, 52.282854693030799],
            [-0.53493753407537, 52.282437878100154],
            [-0.535252730408469, 52.282215214836299],
            [-0.534020406574826, 52.281176748022553],
            [-0.53447125540721, 52.279730297381256],
            [-0.534182360380118, 52.277601238743522],
            [-0.53254119701888, 52.272525211204751],
            [-0.531207729949062, 52.270388550717378],
            [-0.536449078994944, 52.268194188578953],
            [-0.539342544870399, 52.266081164161513],
            [-0.537620876365996, 52.261754054210783],
            [-0.539916992559194, 52.257292352617199],
            [-0.540921294138544, 52.256512658513863],
            [-0.542699097976354, 52.255957391420367],
            [-0.54453709324537, 52.256113130743678],
            [-0.546161293126776, 52.256713961889197],
            [-0.549986488564599, 52.256451710856069],
            [-0.552343659908941, 52.256907709557652],
            [-0.554755372541297, 52.258166326843053],
            [-0.558062990118991, 52.256336637768804],
            [-0.565710046854882, 52.253454658182456],
            [-0.5715997029298, 52.25823374735279],
            [-0.579370133204761, 52.266421178701208],
            [-0.58090034242583, 52.265218604492297],
            [-0.587634404957714, 52.270319899434377],
            [-0.588127364080012, 52.270764553430872],
            [-0.586351728655267, 52.272817524552245],
            [-0.588364980600896, 52.273803640932456],
            [-0.595524106741679, 52.275820260192887],
            [-0.602138898463985, 52.278572650875859],
            [-0.605712820060111, 52.278845967734142],
            [-0.607636111860013, 52.278842585803098],
            [-0.610632761318145, 52.279483867507601],
            [-0.614743583032751, 52.282898359458329],
            [-0.61526955990974, 52.284883428388198],
            [-0.615926291519841, 52.285685024339017],
            [-0.620208022409352, 52.288726400040524],
            [-0.621269236617141, 52.290105410239192],
            [-0.622373380883233, 52.290717088271037],
            [-0.627554266506345, 52.292165598957737],
            [-0.634274334518989, 52.295070229220137],
            [-0.636246696824626, 52.296260934037058],
            [-0.63812215456658, 52.299275635234054],
            [-0.638202590148655, 52.301721180251313],
            [-0.636950917924522, 52.304322186730005],
            [-0.637016626715802, 52.305191462522188],
            [-0.639424458826073, 52.313724570761067],
            [-0.640968841353185, 52.315795857136884],
            [-0.641266831217408, 52.317179379416444],
            [-0.640095108951493, 52.318359881349302],
            [-0.635130167463122, 52.328950476756539],
            [-0.634885157775521, 52.333000712034661],
            [-0.633493020270807, 52.336980142182298],
            [-0.632217087690189, 52.336756792049542],
            [-0.62966468007447, 52.33917811695823],
            [-0.622811995771465, 52.341992593609199],
            [-0.624157327166283, 52.342584577397702],
            [-0.627087548376375, 52.344237006248463],
            [-0.62621729501751, 52.346075404046964],
            [-0.625633338302384, 52.345875311193979],
            [-0.622728443866722, 52.353373993809491],
            [-0.626711683700647, 52.354804033454982],
            [-0.626701989469858, 52.355116800728588],
            [-0.629447443698163, 52.35707540051515],
            [-0.627154197059475, 52.359146336341382],
            [-0.632376554030719, 52.361559774738105],
            [-0.629925799557214, 52.363259419250497],
            [-0.627992558156923, 52.363750366634996],
            [-0.628825247304911, 52.364463109681061],
            [-0.628366788886509, 52.365087145031289],
            [-0.62226047295342, 52.368045118290382],
            [-0.621116506070549, 52.369358818127992],
            [-0.62095757501694, 52.371962493461226],
            [-0.6239370845519, 52.375395732951276],
            [-0.624269706423878, 52.380859700211616],
            [-0.624737156617288, 52.380662847373884],
            [-0.62505965488284, 52.381159293017561],
            [-0.625577319342264, 52.380954030165007],
            [-0.626440258216077, 52.381174444533869],
            [-0.626946127859094, 52.381775510196803],
            [-0.628092310768866, 52.381959642710491],
            [-0.627744529068577, 52.382277475178256],
            [-0.628392687101233, 52.382270612504655],
            [-0.627978619723149, 52.382404264964251],
            [-0.628386307871902, 52.382666131974077],
            [-0.627408230062977, 52.382982941787418],
            [-0.626505982818964, 52.384170031263366],
            [-0.626941983729348, 52.384229940014258],
            [-0.626411281270171, 52.384475515810216],
            [-0.627025538665128, 52.386320362751661],
            [-0.626808809379711, 52.387196242270839],
            [-0.625671519145851, 52.388472300251621],
            [-0.624405642838835, 52.391755385361726],
            [-0.621738029246144, 52.395823198316499],
            [-0.61622047810798, 52.39991876534927],
            [-0.618293141258392, 52.400760265165147],
            [-0.616386470095662, 52.402318539955068],
            [-0.62499180506055, 52.405947795201833],
            [-0.624700289571335, 52.407049365372337],
            [-0.623334175916414, 52.408856786912942],
            [-0.62153945287813, 52.408374652474699],
            [-0.621177744790759, 52.408517882941553],
            [-0.620728665254227, 52.409388342269146],
            [-0.621363506452866, 52.411141745447409],
            [-0.621105219328404, 52.412258079004204],
            [-0.619336711762656, 52.411968619200202],
            [-0.618998727152331, 52.411539410739707],
            [-0.618268207876857, 52.412709560741625],
            [-0.617601682612979, 52.412866300383179],
            [-0.618154531963196, 52.414465020175555],
            [-0.61755029521278, 52.417236989843715],
            [-0.615255871426976, 52.418005821868597],
            [-0.613792019075688, 52.419624082701219],
            [-0.620925719938823, 52.419662540686161],
            [-0.620238061969354, 52.421812290217765],
            [-0.620657337182972, 52.424681621686545],
            [-0.623920062007158, 52.426310088958587],
            [-0.627961940962199, 52.429094719601643],
            [-0.628386286168134, 52.430776406202853],
            [-0.627393255250437, 52.434096834462686],
            [-0.627619670087037, 52.434858276009528],
            [-0.630831748531227, 52.434783122201246],
            [-0.63284537697835, 52.433933421538725],
            [-0.634744497669904, 52.433549880442321],
            [-0.637191226070529, 52.434391756523155],
            [-0.643973734511025, 52.435598047253478],
            [-0.646481306832608, 52.43672003672696],
            [-0.653017783423817, 52.434628811560465],
            [-0.658727435349645, 52.434266646257939],
            [-0.658590784351301, 52.436054237029268],
            [-0.659430144772055, 52.43709228481935],
            [-0.660046005813275, 52.439146435018728],
            [-0.660113163133289, 52.440957912320236],
            [-0.659261428636885, 52.442651088679106],
            [-0.660168543210392, 52.445825174709327],
            [-0.654499172597927, 52.448148733223618],
            [-0.664158106342237, 52.454460845916039],
            [-0.663335614599062, 52.456594020032981],
            [-0.657934332219716, 52.459983465291749],
            [-0.652608772269542, 52.460865144487812],
            [-0.648860062476845, 52.462687043545259],
            [-0.643664465691547, 52.463777489712818],
            [-0.640321204854993, 52.465701765772813],
            [-0.63834898325541, 52.464928364137194],
            [-0.637287528283442, 52.464122268920669],
            [-0.634432230667011, 52.463444715037454],
            [-0.631910809224059, 52.464582567462358],
            [-0.631516180235362, 52.465251401946695],
            [-0.632576873758532, 52.466222069034643],
            [-0.633363156247487, 52.468592095301865],
            [-0.635487776368132, 52.469486013412116],
            [-0.633557803841296, 52.471528885219833],
            [-0.632059007481883, 52.474712227874249],
            [-0.629457322713295, 52.476941462096967],
            [-0.627998666095697, 52.479339437471381],
            [-0.623793927888628, 52.481629065591719],
            [-0.621733023209154, 52.482313537634596],
            [-0.612101674164204, 52.484249917263881],
            [-0.612656637020673, 52.485006244113144],
            [-0.609650949676045, 52.485798101689682],
            [-0.601395431087588, 52.48680305902559],
            [-0.599559767809808, 52.488770075807551],
            [-0.597934575152757, 52.491477689399325],
            [-0.597017645442634, 52.495899202426678],
            [-0.596403041725508, 52.496927634300341],
            [-0.598486534552052, 52.496956824195408],
            [-0.600591863757067, 52.50089176104386],
            [-0.603313161409947, 52.501859833191325],
            [-0.603665855595709, 52.502631793577734],
            [-0.608144551386999, 52.506179202148829],
            [-0.608601349666234, 52.508230834324713],
            [-0.609312695677764, 52.508615002404674],
            [-0.608593268601273, 52.509279942127158],
            [-0.606587602091221, 52.509660022698746],
            [-0.606115728300263, 52.509065581740018],
            [-0.605123953147076, 52.509069180652084],
            [-0.603875528246053, 52.509174034879749],
            [-0.603364348086704, 52.509542012574627],
            [-0.599743264123514, 52.509887618775998],
            [-0.599178440597881, 52.510690089135259],
            [-0.598757135595991, 52.510686000756323],
            [-0.598550121511655, 52.511079136181856],
            [-0.598192866448043, 52.510960724498382],
            [-0.59769797728513, 52.511648037371543],
            [-0.598113585323466, 52.511692519715858],
            [-0.597721801610978, 52.512010641019153],
            [-0.598903699251415, 52.512286265322956],
            [-0.599706817252211, 52.512100674192602],
            [-0.602017796098816, 52.512924561439881],
            [-0.60304051614884, 52.51362082186948],
            [-0.605584501579406, 52.51425768703605],
            [-0.607324503645374, 52.513431260699271],
            [-0.608878610585075, 52.513543039991404],
            [-0.610356532615135, 52.513173806836768],
            [-0.612385148830455, 52.513519471502327],
            [-0.615845313196248, 52.513236327927196],
            [-0.62219825012741, 52.511442213079405],
            [-0.624056391864384, 52.511537552954628],
            [-0.624046460502926, 52.512563262480626],
            [-0.627125046420962, 52.512367056734149],
            [-0.629688251984609, 52.51125765610108],
            [-0.63124769095885, 52.513426246011335],
            [-0.632000109649956, 52.515438942658861],
            [-0.636032733068102, 52.515896352776274],
            [-0.638193873588759, 52.51549328769952],
            [-0.638314973216328, 52.515055941679378],
            [-0.639607400533505, 52.514817270152029],
            [-0.643148140548343, 52.514977488954088],
            [-0.64537468759766, 52.514264868851576],
            [-0.645713573177012, 52.514458447718901],
            [-0.649463687757461, 52.514085935363155],
            [-0.651545836706896, 52.513464154573946],
            [-0.653846613805028, 52.512244255537581],
            [-0.656901046424845, 52.511481495522574],
            [-0.657987079234733, 52.510416747495015],
            [-0.658194066884547, 52.509619834620842],
            [-0.66061602486308, 52.508569302063485],
            [-0.663177119422642, 52.508028257891155],
            [-0.664176956167721, 52.508243632716976],
            [-0.667826588108201, 52.507857719189303],
            [-0.669799563880591, 52.507514895034213],
            [-0.671448153507423, 52.506690100339256],
            [-0.672148214940505, 52.506928114789609],
            [-0.673758923202533, 52.509317879141989],
            [-0.67468228458011, 52.513204939635806],
            [-0.671104819722498, 52.513823832097728],
            [-0.669648801027611, 52.514460187736212],
            [-0.668227062389585, 52.516008551145575],
            [-0.664775256732563, 52.518208303620348],
            [-0.661686461483993, 52.519121020992841],
            [-0.657854121268298, 52.519376112690836],
            [-0.652962564787812, 52.518843147045104],
            [-0.649389647675627, 52.518904023915034],
            [-0.641758973562984, 52.520648079952977],
            [-0.638318544786797, 52.521805192623681],
            [-0.635910668160012, 52.523026255138291],
            [-0.635857837863161, 52.523875253303771],
            [-0.640799938160721, 52.52889488535763],
            [-0.639373967071059, 52.528909970043379],
            [-0.640444376962601, 52.530336480482788],
            [-0.638160193499164, 52.533121564647054],
            [-0.640690099000541, 52.534457865503697],
            [-0.643148781671309, 52.536339918865878],
            [-0.645050133982551, 52.536647595050951],
            [-0.646747500911197, 52.537644279889456],
            [-0.651402837779262, 52.539359750360681],
            [-0.653730106203161, 52.54074110868055],
            [-0.658430820173256, 52.54142290727556],
            [-0.658276853593354, 52.542216821877453],
            [-0.659553462020327, 52.543246310556079],
            [-0.661697570389632, 52.543343390522871],
            [-0.664356177147589, 52.544174467146931],
            [-0.666788669205936, 52.543467361574876],
            [-0.669210500766163, 52.544670565414194],
            [-0.669475009627548, 52.54489650244345],
            [-0.668300559552924, 52.545939680049116],
            [-0.667403125112868, 52.547524494489977],
            [-0.666601155266862, 52.551018157587819],
            [-0.665593771774741, 52.552234907916237],
            [-0.674877830679337, 52.558620817423609],
            [-0.674332849702373, 52.559327660833532],
            [-0.673119374234322, 52.558868141877682],
            [-0.672676872749935, 52.559013323107479],
            [-0.672657302983367, 52.560394937870711],
            [-0.673590253929275, 52.560325377267922],
            [-0.673843136779786, 52.560793915620373],
            [-0.673096856561953, 52.561081341292159],
            [-0.673322575166729, 52.561472258347386],
            [-0.672114448504641, 52.561811142326022],
            [-0.671873910301593, 52.562155476400157],
            [-0.671393202203167, 52.562050289586324],
            [-0.671560135676119, 52.562579902517449],
            [-0.670482318901585, 52.562559714361157],
            [-0.670777030340132, 52.563254389365532],
            [-0.671590794685341, 52.563127765441727],
            [-0.671593013209044, 52.562858975603746],
            [-0.671894629613819, 52.562983730101934],
            [-0.671300645074206, 52.564088287189627],
            [-0.670372512040915, 52.564239697360591],
            [-0.669875704492597, 52.563788191047827],
            [-0.669604415197242, 52.564562818090316],
            [-0.668954196368312, 52.564744310651477],
            [-0.66855214927356, 52.565353839063739],
            [-0.666290144340243, 52.566335315117016],
            [-0.664128684680138, 52.566772161621365],
            [-0.663216016922741, 52.566557778666223],
            [-0.66305663425797, 52.567238355335121],
            [-0.66194451891772, 52.567711279072036],
            [-0.660926291592087, 52.568927866258171],
            [-0.658629313767238, 52.568384019081122],
            [-0.654526420388783, 52.57034774084142],
            [-0.651600505919587, 52.570056434266164],
            [-0.649359811077851, 52.570564033868905],
            [-0.647969320609866, 52.571156821241807],
            [-0.6474732314802, 52.572125723149007],
            [-0.647746014931521, 52.572420129557145],
            [-0.646710277602432, 52.572559337848446],
            [-0.646753473587233, 52.573169385240071],
            [-0.647322555688395, 52.573281974447696],
            [-0.646909583340664, 52.573754650390363],
            [-0.6449361491897, 52.573866043670698],
            [-0.643726009545135, 52.57339097925999],
            [-0.643006566458356, 52.573656047736534],
            [-0.642246681333317, 52.572984740055723],
            [-0.641668741364411, 52.572920573315137],
            [-0.640633914721877, 52.571693186792196],
            [-0.64025324235528, 52.57183536115236],
            [-0.638963808380811, 52.571494194053898],
            [-0.637861101433125, 52.571794383864976],
            [-0.636286015490447, 52.572718458119034],
            [-0.633439803006524, 52.573659316659359],
            [-0.633595077330174, 52.574170868584048],
            [-0.633025604247179, 52.574261393619793],
            [-0.633465146436029, 52.574695312357875],
            [-0.63293306964251, 52.575243883872972],
            [-0.633990320682366, 52.575318118161057],
            [-0.633030667702152, 52.575569566750652],
            [-0.632521759637547, 52.576321588640745],
            [-0.631307067065939, 52.576516135236503],
            [-0.630595474754712, 52.576098842119031],
            [-0.629577241842476, 52.576524000767847],
            [-0.628116102554735, 52.576528660551169],
            [-0.627781959462569, 52.577163113409931],
            [-0.628406748655354, 52.577476926641246],
            [-0.627822949053191, 52.578213676420049],
            [-0.626040663168607, 52.578056356931739],
            [-0.625171146476331, 52.578583896634157],
            [-0.625515026545042, 52.578866593153116],
            [-0.624847877652342, 52.579151937961278],
            [-0.621691410051892, 52.579799419706468],
            [-0.620001810690192, 52.579697031128489],
            [-0.618862185258453, 52.581302033323055],
            [-0.617603228003606, 52.581442880013917],
            [-0.617896640264989, 52.582015402183643],
            [-0.616511607989283, 52.58226894514285],
            [-0.617217324654854, 52.583294010706446],
            [-0.614131616304472, 52.584071581538232],
            [-0.612727146640302, 52.583909492667686],
            [-0.611885814630355, 52.584423780031052],
            [-0.610750393167286, 52.584155136897145],
            [-0.608493759569675, 52.584708531082882],
            [-0.607362259686082, 52.585434249645616],
            [-0.607367796831371, 52.585864959166351],
            [-0.605505022533595, 52.586468778926722],
            [-0.605021705646414, 52.586907217407862],
            [-0.605150829151085, 52.587489523769086],
            [-0.603821206075094, 52.587710306966954],
            [-0.60303208874622, 52.588573079397953],
            [-0.597982462916541, 52.588748063453231],
            [-0.596778904215505, 52.588347218800266],
            [-0.594126735061098, 52.588286098046503],
            [-0.591938109254448, 52.587210906845826],
            [-0.590667922368902, 52.587233559101527],
            [-0.58894724783079, 52.586759050800978],
            [-0.586985211232839, 52.587410845617121],
            [-0.585299915277986, 52.588451606831967],
            [-0.583310668127572, 52.591270627278604],
            [-0.582803653876448, 52.593258655435392],
            [-0.583808386229435, 52.593141209885182],
            [-0.584507508333402, 52.593390512530384],
            [-0.583709886468983, 52.595051412478121],
            [-0.582845776515447, 52.595156155168439],
            [-0.581485265386873, 52.595865402587172],
            [-0.579024650055628, 52.59539177746182],
            [-0.577560417589005, 52.593283909307104],
            [-0.577967379607631, 52.591794573558303],
            [-0.578329076177864, 52.591246898371793],
            [-0.578988966265262, 52.591064218621888],
            [-0.579197072836904, 52.590426590963204],
            [-0.577874109935212, 52.590389135936775],
            [-0.579013896585693, 52.589608954072332],
            [-0.578472946604851, 52.589264419388797],
            [-0.578779742451847, 52.588815878479359],
            [-0.577968613331843, 52.587880119308906],
            [-0.576795275379147, 52.587874116948797],
            [-0.576521643400578, 52.587526491685821],
            [-0.57701640446618, 52.587153936446398],
            [-0.575581266572472, 52.586436323257814],
            [-0.571916620921422, 52.585785331047482],
            [-0.56995592823325, 52.586071845629448],
            [-0.568577951951299, 52.585688385456052],
            [-0.567781485218534, 52.586303598097153],
            [-0.565295066796199, 52.586369702327595],
            [-0.564487713907268, 52.58591578702935],
            [-0.562531077711663, 52.586257070323505],
            [-0.561636943786313, 52.587057160622209],
            [-0.560654619517973, 52.588926045799965],
            [-0.561069438578162, 52.591043860747945],
            [-0.560574548229422, 52.591279692409181],
            [-0.561768808770459, 52.592182464617842],
            [-0.559936868322105, 52.593619367361697],
            [-0.558130093857916, 52.594465870864042],
            [-0.555045059574644, 52.594717769668449],
            [-0.553188657678869, 52.594556653482179],
            [-0.54717363185535, 52.593271100429043],
            [-0.546728631580626, 52.592576066657784],
            [-0.545188476949689, 52.593478689196971],
            [-0.544676953994086, 52.592876332101056],
            [-0.544185541285989, 52.592870292868511],
            [-0.542693080566726, 52.59473635652293],
            [-0.542941294650716, 52.59566543598087],
            [-0.543579878687524, 52.596131807476375],
            [-0.544597584578657, 52.596118244600348],
            [-0.544948153241904, 52.596633214277361],
            [-0.549019859352001, 52.598341017948293],
            [-0.549239925071414, 52.599232876896316],
            [-0.552347708768786, 52.600003628481439],
            [-0.552950705435873, 52.601330805359588],
            [-0.552114612253346, 52.602236722385079],
            [-0.551416244806439, 52.604293306340594],
            [-0.550217084149246, 52.60483245152912],
            [-0.549578411968334, 52.605703905736334],
            [-0.547570561430216, 52.606937976298077],
            [-0.547666329704028, 52.607614338448585],
            [-0.548487218263755, 52.608201591254847],
            [-0.548481666080249, 52.609888143860587],
            [-0.549342181217135, 52.611199611992795],
            [-0.550066871026086, 52.611616653404369],
            [-0.550142529922227, 52.612232530259043],
            [-0.544944189722484, 52.614576452869244],
            [-0.54463163069229, 52.615049110484321],
            [-0.543792341966576, 52.614702551521496],
            [-0.542947961459138, 52.614954692054802],
            [-0.543035125346565, 52.616022038766943],
            [-0.541552733755168, 52.616625941580899],
            [-0.540644476044052, 52.617509312425732],
            [-0.539068585525477, 52.617857601121436],
            [-0.538896167866895, 52.618777002231148],
            [-0.539565292033495, 52.619710376264635],
            [-0.538074070115839, 52.621106191075128],
            [-0.539129550413635, 52.623125891870174],
            [-0.541169418367821, 52.624602094327706],
            [-0.540272198259405, 52.625947709682272],
            [-0.538521503113846, 52.627010381229688],
            [-0.538125265946961, 52.627905441831359],
            [-0.537433435875857, 52.627827676709849],
            [-0.536959812369599, 52.627365111680284],
            [-0.537325203377596, 52.626155006420483],
            [-0.535648941405086, 52.627065716220926],
            [-0.53456416323588, 52.628092504976152],
            [-0.534698236381396, 52.628590438920931],
            [-0.534097797803026, 52.628828452172208],
            [-0.535650024228846, 52.629334932102275],
            [-0.534995629578539, 52.630436270010485],
            [-0.533854938128316, 52.63052465181012],
            [-0.533523320648871, 52.631427689171545],
            [-0.530903845718646, 52.632079415826709],
            [-0.528863745930368, 52.631975885383341],
            [-0.523641935452432, 52.634868810840558],
            [-0.52076514846726, 52.637486927973633],
            [-0.520841408762337, 52.638862527841695],
            [-0.521444497185866, 52.639516471831342],
            [-0.520739976186424, 52.640073181103233],
            [-0.521079156466625, 52.640708547994002],
            [-0.520064458563909, 52.641433999117531],
            [-0.518289957696821, 52.641724692504894],
            [-0.517408267430545, 52.64236098017426],
            [-0.516882454717201, 52.642438011760404],
            [-0.515897354645144, 52.642023802402079],
            [-0.514685357046143, 52.643116246282013],
            [-0.513463732195924, 52.642616339026539],
            [-0.512637314430552, 52.642840620780731],
            [-0.511958237897254, 52.643600783162555],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000153",
        LAD13CDO: "34UE",
        LAD13NM: "Kettering",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.863357897495851, 52.528308386949249],
            [-0.862391795035509, 52.527873863840668],
            [-0.861502003659138, 52.527909362621308],
            [-0.861925442442366, 52.527210389239791],
            [-0.861263398375218, 52.526761697757536],
            [-0.860755347545371, 52.52702741900351],
            [-0.860348838769075, 52.526672882753743],
            [-0.859447949751207, 52.527194636931121],
            [-0.859036775715814, 52.527077396364142],
            [-0.858730737988484, 52.526202382621733],
            [-0.859955017894625, 52.525999309912699],
            [-0.858289329169428, 52.525602965179438],
            [-0.85848317334061, 52.524493627244986],
            [-0.857921771737776, 52.523918224016491],
            [-0.85654724424847, 52.523892368423262],
            [-0.856765970645791, 52.524324219862855],
            [-0.856368385658525, 52.524422869545759],
            [-0.855544691895938, 52.523845819686827],
            [-0.855244911097113, 52.524207929461291],
            [-0.853452419475264, 52.523704203581289],
            [-0.853236920407467, 52.524112076404371],
            [-0.851530974924347, 52.523625342322028],
            [-0.851997775108693, 52.522963684660908],
            [-0.851528034795799, 52.523059820246438],
            [-0.850133681988968, 52.521649182069794],
            [-0.849006861312324, 52.521735326920165],
            [-0.849188750547336, 52.520922568423288],
            [-0.848504458698917, 52.520826010217178],
            [-0.84868600129394, 52.520421411568677],
            [-0.848263914544939, 52.520217719422853],
            [-0.847807950766254, 52.520464113350435],
            [-0.847698787663925, 52.521369279567068],
            [-0.846782622093324, 52.521234492582522],
            [-0.84531185090279, 52.521678663643733],
            [-0.844971739589025, 52.521159298475212],
            [-0.84620292138523, 52.520352282883351],
            [-0.845358165950122, 52.519349711143981],
            [-0.843100375653247, 52.519571305335674],
            [-0.842923291305256, 52.520197102351055],
            [-0.842174839452494, 52.519514607079778],
            [-0.841212137729855, 52.520534584301274],
            [-0.840473597289501, 52.520092218195018],
            [-0.839937492735022, 52.520246095118324],
            [-0.839731176226409, 52.519909631141267],
            [-0.840324227434872, 52.518992131323003],
            [-0.838606588524412, 52.51834686395032],
            [-0.838087882398841, 52.519070893033224],
            [-0.837679373743906, 52.518745926033851],
            [-0.836592992878663, 52.519255796351082],
            [-0.834661768099402, 52.517883747521864],
            [-0.833393600012063, 52.518412476655598],
            [-0.833507425239111, 52.519122939397768],
            [-0.833109664155931, 52.519339283272124],
            [-0.831397973878565, 52.518862089700157],
            [-0.831761535182825, 52.51960199055285],
            [-0.831175527412659, 52.519469441264462],
            [-0.83110108842769, 52.519053350598085],
            [-0.83063979834899, 52.519441674184378],
            [-0.830465810067703, 52.519278128397424],
            [-0.830981292938687, 52.518791446494454],
            [-0.830459645950914, 52.518289125418185],
            [-0.829933648123311, 52.519060696283361],
            [-0.828754893409338, 52.518606711807962],
            [-0.829128449462017, 52.518248094990248],
            [-0.828554680131141, 52.517822567084998],
            [-0.826977930120453, 52.518369750397504],
            [-0.827161014363108, 52.517194722966266],
            [-0.825791224355557, 52.517110118566251],
            [-0.824819917897369, 52.517777455556867],
            [-0.822464420522777, 52.517986897578261],
            [-0.822651066150067, 52.517482595194913],
            [-0.821656410099169, 52.51747809193008],
            [-0.82174723593234, 52.51678224066184],
            [-0.820780962749641, 52.516653945065293],
            [-0.820646580224896, 52.515728393670635],
            [-0.820134799038079, 52.515523706605393],
            [-0.818271845291272, 52.515532093343886],
            [-0.817788121340642, 52.516094557090767],
            [-0.816472319920273, 52.516415850734617],
            [-0.814937130612281, 52.515523926764622],
            [-0.81260353925369, 52.515934776569495],
            [-0.812187382026696, 52.517377156639128],
            [-0.811119806808388, 52.517835737684024],
            [-0.811252823398102, 52.519077748681248],
            [-0.810879971506084, 52.519350008893475],
            [-0.808955356148849, 52.519234461680149],
            [-0.808586322438622, 52.518438693558629],
            [-0.806358396977738, 52.518477382664848],
            [-0.804371441228583, 52.517565481111106],
            [-0.803396766083981, 52.51812742178614],
            [-0.80326510689705, 52.517711633006819],
            [-0.803901590904955, 52.517555339988718],
            [-0.803885296600361, 52.5171784772877],
            [-0.801804694074579, 52.516625202023135],
            [-0.801352399381507, 52.516728507608484],
            [-0.801885260733644, 52.517239163458434],
            [-0.800795289017487, 52.517217337231031],
            [-0.801242779047113, 52.518048987610655],
            [-0.800493010934392, 52.518035098768159],
            [-0.798410371197673, 52.516854212496909],
            [-0.7977840444288, 52.5170159734446],
            [-0.796173831679875, 52.516347802982352],
            [-0.799327577225412, 52.512562505549219],
            [-0.80169102369042, 52.513056655669367],
            [-0.80263102495584, 52.509971667218451],
            [-0.804962174991954, 52.510294607991597],
            [-0.805728202021669, 52.508399068555121],
            [-0.807945738366551, 52.506546921428743],
            [-0.801424755662788, 52.501826718845891],
            [-0.798008000625783, 52.498814434016118],
            [-0.794951389647113, 52.497786309795586],
            [-0.793813315859738, 52.496202287011066],
            [-0.79285992884503, 52.495676514291766],
            [-0.791566165853024, 52.49547810707486],
            [-0.786676750000664, 52.495549449605342],
            [-0.785565334492987, 52.494400756814059],
            [-0.785757651125056, 52.493568415884852],
            [-0.784972238849396, 52.492351137753943],
            [-0.782209781938258, 52.491365257970685],
            [-0.782459273303888, 52.491024389484281],
            [-0.78075701216032, 52.489785957766536],
            [-0.781320012106682, 52.489345830025762],
            [-0.779885714857997, 52.488113740585263],
            [-0.78137606435463, 52.488010423725498],
            [-0.782182963704863, 52.487376811136343],
            [-0.781589088425355, 52.486963429506311],
            [-0.782489472187381, 52.486356849709864],
            [-0.778089318807967, 52.483475898546487],
            [-0.778472586861537, 52.483089668965128],
            [-0.777128280475188, 52.482127288010084],
            [-0.778597358209045, 52.480711176812129],
            [-0.781452259315788, 52.479070180546465],
            [-0.779664897499077, 52.477731956855358],
            [-0.778114897167301, 52.477130679746189],
            [-0.776967221936087, 52.476942608714417],
            [-0.775734735167431, 52.477370396584583],
            [-0.774811154523267, 52.477172036425834],
            [-0.771851831174385, 52.473779809940098],
            [-0.771647266219171, 52.472031731359266],
            [-0.768811428331456, 52.472307033593552],
            [-0.767083359523116, 52.471820633384418],
            [-0.761605350216304, 52.471186231230185],
            [-0.758134693859342, 52.470059303338573],
            [-0.755240353647664, 52.469852673278425],
            [-0.749050747545598, 52.469615671350098],
            [-0.741581506648227, 52.470115450462536],
            [-0.739796753416378, 52.465544545737004],
            [-0.739815973872196, 52.464098172732783],
            [-0.738559878782169, 52.462931299546312],
            [-0.737894955688223, 52.458130448068765],
            [-0.736658648676941, 52.458028245138756],
            [-0.734487029695209, 52.457900743310219],
            [-0.732949648020735, 52.458545991668714],
            [-0.731400909820537, 52.458099644939495],
            [-0.728650756379905, 52.458436935640094],
            [-0.72280614905173, 52.459764819044857],
            [-0.720236528449219, 52.456762969377955],
            [-0.716771308083214, 52.457397026226253],
            [-0.709389580455868, 52.459843972427571],
            [-0.707919796984452, 52.460427599581031],
            [-0.705730010078268, 52.462223354432318],
            [-0.704816176164601, 52.462366192863378],
            [-0.70480836793395, 52.462883065367329],
            [-0.702359771350261, 52.464160764609737],
            [-0.698278158778088, 52.467897359507425],
            [-0.69612872983095, 52.470642786130639],
            [-0.693274108486309, 52.476608899526262],
            [-0.692889985151692, 52.476553410292851],
            [-0.689576058556582, 52.475547569645876],
            [-0.688197200825163, 52.476760401520103],
            [-0.686647488733847, 52.476785464970916],
            [-0.685575523885087, 52.477232080003262],
            [-0.684822098116189, 52.47825402684574],
            [-0.674376192628092, 52.474933209058342],
            [-0.675723013780303, 52.474504156828111],
            [-0.676935876682864, 52.473435259987077],
            [-0.672895473532534, 52.471296099523542],
            [-0.671326006866227, 52.468903169123678],
            [-0.674479132686452, 52.468517779369435],
            [-0.675629515026021, 52.468005612225781],
            [-0.676207378568226, 52.467193935739004],
            [-0.672946869383911, 52.465671260967937],
            [-0.672872700852222, 52.465151671394743],
            [-0.676605745813066, 52.463924017799371],
            [-0.681788550053236, 52.463185315796693],
            [-0.681600550402672, 52.462678848429405],
            [-0.683858507812808, 52.462431575772889],
            [-0.683772189249729, 52.462167191017095],
            [-0.681848645875107, 52.462306705263885],
            [-0.676962694340797, 52.461788247744465],
            [-0.676486453172248, 52.461962732593271],
            [-0.676468094118303, 52.462476789537874],
            [-0.673545858297459, 52.463471685376021],
            [-0.669893200705146, 52.463739950056997],
            [-0.669039342933879, 52.462572356622452],
            [-0.672411125296283, 52.461900885059926],
            [-0.670758198941332, 52.458429930051913],
            [-0.669096910584911, 52.457539161502623],
            [-0.669664805537107, 52.455641337185959],
            [-0.670283012868834, 52.455100759547506],
            [-0.664158106342237, 52.454460845916039],
            [-0.654499172597927, 52.448148733223618],
            [-0.660168543210392, 52.445825174709327],
            [-0.659261428636885, 52.442651088679106],
            [-0.660113163133289, 52.440957912320236],
            [-0.660046005813275, 52.439146435018728],
            [-0.659430144772055, 52.43709228481935],
            [-0.658590784351301, 52.436054237029268],
            [-0.658727435349645, 52.434266646257939],
            [-0.653017783423817, 52.434628811560465],
            [-0.646481306832608, 52.43672003672696],
            [-0.643973734511025, 52.435598047253478],
            [-0.637191226070529, 52.434391756523155],
            [-0.634744497669904, 52.433549880442321],
            [-0.63284537697835, 52.433933421538725],
            [-0.630831748531227, 52.434783122201246],
            [-0.627619670087037, 52.434858276009528],
            [-0.627393255250437, 52.434096834462686],
            [-0.628386286168134, 52.430776406202853],
            [-0.627961940962199, 52.429094719601643],
            [-0.623920062007158, 52.426310088958587],
            [-0.620657337182972, 52.424681621686545],
            [-0.620238061969354, 52.421812290217765],
            [-0.620925719938823, 52.419662540686161],
            [-0.613792019075688, 52.419624082701219],
            [-0.615255871426976, 52.418005821868597],
            [-0.61755029521278, 52.417236989843715],
            [-0.618154531963196, 52.414465020175555],
            [-0.617601682612979, 52.412866300383179],
            [-0.618268207876857, 52.412709560741625],
            [-0.618998727152331, 52.411539410739707],
            [-0.619336711762656, 52.411968619200202],
            [-0.621105219328404, 52.412258079004204],
            [-0.621363506452866, 52.411141745447409],
            [-0.620728665254227, 52.409388342269146],
            [-0.621177744790759, 52.408517882941553],
            [-0.62153945287813, 52.408374652474699],
            [-0.623334175916414, 52.408856786912942],
            [-0.624700289571335, 52.407049365372337],
            [-0.62499180506055, 52.405947795201833],
            [-0.616386470095662, 52.402318539955068],
            [-0.618293141258392, 52.400760265165147],
            [-0.61622047810798, 52.39991876534927],
            [-0.621738029246144, 52.395823198316499],
            [-0.624405642838835, 52.391755385361726],
            [-0.625671519145851, 52.388472300251621],
            [-0.626808809379711, 52.387196242270839],
            [-0.627025538665128, 52.386320362751661],
            [-0.626411281270171, 52.384475515810216],
            [-0.626941983729348, 52.384229940014258],
            [-0.626505982818964, 52.384170031263366],
            [-0.627408230062977, 52.382982941787418],
            [-0.628386307871902, 52.382666131974077],
            [-0.627978619723149, 52.382404264964251],
            [-0.628392687101233, 52.382270612504655],
            [-0.627744529068577, 52.382277475178256],
            [-0.628092310768866, 52.381959642710491],
            [-0.626946127859094, 52.381775510196803],
            [-0.626440258216077, 52.381174444533869],
            [-0.625577319342264, 52.380954030165007],
            [-0.62505965488284, 52.381159293017561],
            [-0.624737156617288, 52.380662847373884],
            [-0.624269706423878, 52.380859700211616],
            [-0.6239370845519, 52.375395732951276],
            [-0.62095757501694, 52.371962493461226],
            [-0.621116506070549, 52.369358818127992],
            [-0.62226047295342, 52.368045118290382],
            [-0.628366788886509, 52.365087145031289],
            [-0.628825247304911, 52.364463109681061],
            [-0.627992558156923, 52.363750366634996],
            [-0.629925799557214, 52.363259419250497],
            [-0.632376554030719, 52.361559774738105],
            [-0.634213157852657, 52.360435587056735],
            [-0.636370745971985, 52.357944878990608],
            [-0.641193450627128, 52.355589097847911],
            [-0.645387362491956, 52.35385257326147],
            [-0.645642740809437, 52.354000251906619],
            [-0.64752869492534, 52.352825176945728],
            [-0.648339946528935, 52.352800293502469],
            [-0.648715390289198, 52.351755362400965],
            [-0.650454451476007, 52.350962467929719],
            [-0.650381289983894, 52.35042398339219],
            [-0.653276471366821, 52.349606484248973],
            [-0.653596635494393, 52.348105074878646],
            [-0.658087946708174, 52.347852273077443],
            [-0.659082204365298, 52.349199589355969],
            [-0.659796790616299, 52.349358740707565],
            [-0.660651535379311, 52.349009699147686],
            [-0.661117137320369, 52.350072290729138],
            [-0.663316723458988, 52.349869724255839],
            [-0.663338302073503, 52.34915430256688],
            [-0.664431813688951, 52.34887086967197],
            [-0.664578750352925, 52.34920788614707],
            [-0.667463917570315, 52.349004896404537],
            [-0.66804508217617, 52.349598545627508],
            [-0.674814355917621, 52.348976066155011],
            [-0.674904363173805, 52.348469996221091],
            [-0.677151444855623, 52.348589574671479],
            [-0.677773776426184, 52.350464819934615],
            [-0.68035143040478, 52.350136699057948],
            [-0.680610711739527, 52.350403023360968],
            [-0.68236827366502, 52.350003663475292],
            [-0.682755734083812, 52.349563839503638],
            [-0.683475722962867, 52.349791237163828],
            [-0.684377952839801, 52.349616974426702],
            [-0.686449624115084, 52.350505829343184],
            [-0.6859423326317, 52.351323742615577],
            [-0.686437074081504, 52.351969384309179],
            [-0.686214643666032, 52.352637621775557],
            [-0.688280155585261, 52.353393311823524],
            [-0.688763169616161, 52.353891364899447],
            [-0.69110596220707, 52.353912840100193],
            [-0.690636613272949, 52.354541488403115],
            [-0.691191592517116, 52.355388270301482],
            [-0.69046508105206, 52.356221755752806],
            [-0.692410300003597, 52.356085955936607],
            [-0.691198031408405, 52.356810676809495],
            [-0.691565373371412, 52.357548388594175],
            [-0.691879371689914, 52.357805403300873],
            [-0.692341204799629, 52.357581250364255],
            [-0.692998176328018, 52.358109081286365],
            [-0.693036462147484, 52.358852139450455],
            [-0.693681140318775, 52.359050769531827],
            [-0.694558844635979, 52.358816818705399],
            [-0.695773096117045, 52.35957106099525],
            [-0.696764656782186, 52.36160671122834],
            [-0.695859139969638, 52.362534455826683],
            [-0.696213788056197, 52.363757511027565],
            [-0.696514947370198, 52.363954133355008],
            [-0.698947331342872, 52.362593665398265],
            [-0.700080517518013, 52.364214574402453],
            [-0.700503749768788, 52.364355887454749],
            [-0.70294999029003, 52.363622143140127],
            [-0.704569129120174, 52.363748688357347],
            [-0.706055756364606, 52.363082577634046],
            [-0.707319464352486, 52.363008296787861],
            [-0.711094804990298, 52.363478385080818],
            [-0.711178490579885, 52.363125962662394],
            [-0.711652768024719, 52.363280379297095],
            [-0.711848267388918, 52.362971431537424],
            [-0.712568390337351, 52.363206742862239],
            [-0.713241107966529, 52.362900292876681],
            [-0.714009825206176, 52.363384268022507],
            [-0.71500823654635, 52.363291733887245],
            [-0.715114157398, 52.363639926772123],
            [-0.715992643913063, 52.363631492246732],
            [-0.716009051397871, 52.363318793860309],
            [-0.718832568966642, 52.363493284976023],
            [-0.719081706043348, 52.362952946672571],
            [-0.72004855644339, 52.363138739826489],
            [-0.721006839214331, 52.362604276915917],
            [-0.722667065172164, 52.3623758858934],
            [-0.723688193893025, 52.361697330236041],
            [-0.72550260441509, 52.361212531285091],
            [-0.727053791315497, 52.361198679859577],
            [-0.728372710881307, 52.360630280283836],
            [-0.728326779863596, 52.360286341304793],
            [-0.730092978100044, 52.359174301589135],
            [-0.732583535941865, 52.358198572798194],
            [-0.734343050352921, 52.357930625070118],
            [-0.73424371888726, 52.357655344945506],
            [-0.735802076259802, 52.357488614111027],
            [-0.735585222264947, 52.357214775182861],
            [-0.735996323904257, 52.357165228439243],
            [-0.737158780307709, 52.355174527304911],
            [-0.737537856363017, 52.355320630337701],
            [-0.737454966079692, 52.354880099580434],
            [-0.738032769867239, 52.355008544314543],
            [-0.738033945969411, 52.354657919536237],
            [-0.73846799781922, 52.354781230800356],
            [-0.739999013041444, 52.352680253392862],
            [-0.740844510249288, 52.352171400263217],
            [-0.741361457916707, 52.352270410232649],
            [-0.742917970303264, 52.351074131355709],
            [-0.743481526724754, 52.351289607917593],
            [-0.744820260937568, 52.350729328290299],
            [-0.745665009875803, 52.350036123574725],
            [-0.745930133973501, 52.349539054270146],
            [-0.745252266133731, 52.349109295988526],
            [-0.745556770302427, 52.347895187384957],
            [-0.746888146186528, 52.347902119863292],
            [-0.747860747277182, 52.34720126444136],
            [-0.748308847046591, 52.34734536735359],
            [-0.748791016991583, 52.347062770904813],
            [-0.750786445353604, 52.348041390626534],
            [-0.752127088470518, 52.34829290899399],
            [-0.753736195611428, 52.348186716573124],
            [-0.754422167282395, 52.347860393797042],
            [-0.756809250220869, 52.348071636759244],
            [-0.760804951180174, 52.34887691239328],
            [-0.764154047539335, 52.350288456887192],
            [-0.765752583154755, 52.350299768041268],
            [-0.77034147216157, 52.349529452320255],
            [-0.771693903455484, 52.350791422828955],
            [-0.776239948991911, 52.349922434600778],
            [-0.777095569055338, 52.350785405483762],
            [-0.776684454182963, 52.350945679999683],
            [-0.778151605980178, 52.35226180772586],
            [-0.778834698522404, 52.353740640415921],
            [-0.779644243988961, 52.353561996554703],
            [-0.779224831021721, 52.352797054506397],
            [-0.780871644983359, 52.350683265294741],
            [-0.782623263646969, 52.351001609874885],
            [-0.786450356527154, 52.348232285923473],
            [-0.788670852342792, 52.347293065291552],
            [-0.790180942105342, 52.35349860541379],
            [-0.791218117599679, 52.355953777316792],
            [-0.796331884352956, 52.361649396457274],
            [-0.799756602561363, 52.3638698472854],
            [-0.805781626463904, 52.366185725026256],
            [-0.805556072601461, 52.366380340673906],
            [-0.81673411956034, 52.370294900041593],
            [-0.81904511555187, 52.371542532135848],
            [-0.826308843244522, 52.373560893520356],
            [-0.829523320555287, 52.375073067726014],
            [-0.831712421046514, 52.376843393038691],
            [-0.832973044161602, 52.381128171213561],
            [-0.833753282580349, 52.384247510604659],
            [-0.839857183541975, 52.391126013911546],
            [-0.840583978959198, 52.392832359392933],
            [-0.849845519345343, 52.393671731549439],
            [-0.850106569458913, 52.393296667621456],
            [-0.854412338281728, 52.39409726818441],
            [-0.857267619333525, 52.395299963183213],
            [-0.861821906164909, 52.39616472861487],
            [-0.866151889994053, 52.399652401874633],
            [-0.873709516622157, 52.402659139260351],
            [-0.877024393829588, 52.401723288075651],
            [-0.880623128902378, 52.401529951290343],
            [-0.88532622940119, 52.400323800838613],
            [-0.887230834054185, 52.399108242165212],
            [-0.888472003853129, 52.398789065575208],
            [-0.888201254247477, 52.398158083184768],
            [-0.888769662745843, 52.398056439441348],
            [-0.888670501665522, 52.397732748029739],
            [-0.889357503834953, 52.397824613069503],
            [-0.889399938535635, 52.398359048115218],
            [-0.890551116498576, 52.398579334220287],
            [-0.890681047547671, 52.399024684525934],
            [-0.890273111712753, 52.400404496036536],
            [-0.890399668936245, 52.403680026065075],
            [-0.889302246674279, 52.406289555348692],
            [-0.889179042062881, 52.407975914796907],
            [-0.888702712745331, 52.407976833575184],
            [-0.889295578618585, 52.409540456905198],
            [-0.888185405934552, 52.411712916384928],
            [-0.888262597194619, 52.413730209467644],
            [-0.888978476392896, 52.4151987908376],
            [-0.888018459903492, 52.416305487208994],
            [-0.88727447694428, 52.419576420121977],
            [-0.886364023724871, 52.420286189805118],
            [-0.886261888510962, 52.42083814264236],
            [-0.88695036004339, 52.423338583182606],
            [-0.885273949719375, 52.423627571242889],
            [-0.882015885785446, 52.42260786448324],
            [-0.87829458194075, 52.422927752011766],
            [-0.876677631429345, 52.42353724356559],
            [-0.875060476676408, 52.423517377153431],
            [-0.873421072605988, 52.426563032233531],
            [-0.870995413741038, 52.429287393370778],
            [-0.868720334561748, 52.432840269052946],
            [-0.864341557185725, 52.434208912557445],
            [-0.865229316902864, 52.434579749434128],
            [-0.872969786167549, 52.436762023975263],
            [-0.879303623598377, 52.439157927697089],
            [-0.893259352044444, 52.441537840125967],
            [-0.89843703851564, 52.441516045672586],
            [-0.904397191155061, 52.442154879183903],
            [-0.903873087148242, 52.445489070943808],
            [-0.905355454848562, 52.4483680471822],
            [-0.905864223913608, 52.453311192903286],
            [-0.90626363389031, 52.453643036525818],
            [-0.905790573752137, 52.454561078815786],
            [-0.904686923909246, 52.454914078591216],
            [-0.904555748756034, 52.455574558698942],
            [-0.90408924567729, 52.455751844556325],
            [-0.904162577075487, 52.456414218445879],
            [-0.901557102231165, 52.458093739444628],
            [-0.900986662464172, 52.458838242270268],
            [-0.901454824249601, 52.458832700879981],
            [-0.9014256621901, 52.459117426203818],
            [-0.900795638718694, 52.459121463728039],
            [-0.901293915359564, 52.459739238387421],
            [-0.898932380001853, 52.461126980459291],
            [-0.897401721681332, 52.462656379031486],
            [-0.895511645340343, 52.465486012463849],
            [-0.892717336610353, 52.467205831491768],
            [-0.889570081286036, 52.470423670900033],
            [-0.8862058643792, 52.469328484330966],
            [-0.884845181770004, 52.471501236219986],
            [-0.8824824827656, 52.471145400226149],
            [-0.882374733926221, 52.471388921663298],
            [-0.882231590563133, 52.476167768779099],
            [-0.884053409582521, 52.477311461934534],
            [-0.885105362196838, 52.478683423636909],
            [-0.885261469614196, 52.480255516575944],
            [-0.884547660308082, 52.481289877624405],
            [-0.888873853434736, 52.482988407271286],
            [-0.888057576399005, 52.483298999524834],
            [-0.887860696541889, 52.483748466824416],
            [-0.890514061151275, 52.484110513631471],
            [-0.890457627454733, 52.484363513360805],
            [-0.891639746643549, 52.484906822046085],
            [-0.892755467008105, 52.484811180386892],
            [-0.893415751081341, 52.485376558427454],
            [-0.896934035396248, 52.485490319648441],
            [-0.897806190223219, 52.485594643204848],
            [-0.897893844420228, 52.486212199430284],
            [-0.897283081327636, 52.486490605557627],
            [-0.898060706792285, 52.486901517079261],
            [-0.89788779693686, 52.487397074584052],
            [-0.897121213347235, 52.487663240147633],
            [-0.896497485943472, 52.487281627879135],
            [-0.896177694948114, 52.487996077369715],
            [-0.895227035492573, 52.48808430222585],
            [-0.894682874436186, 52.488700455832969],
            [-0.895144999348283, 52.489536380867229],
            [-0.895901111084333, 52.489925528500052],
            [-0.895912292685418, 52.490420103279121],
            [-0.896723101540728, 52.490625453211962],
            [-0.895614289177466, 52.49202749802086],
            [-0.894821465779677, 52.492279019375168],
            [-0.892054504705962, 52.492196507853798],
            [-0.891163804176746, 52.491887887219818],
            [-0.888961351869688, 52.491790812309404],
            [-0.883734377267372, 52.492245984745352],
            [-0.882138439596028, 52.493148838105746],
            [-0.882322427364598, 52.494664555388177],
            [-0.881805576626614, 52.496865011977953],
            [-0.880664962643112, 52.497115849466432],
            [-0.87981909051244, 52.497882816451863],
            [-0.878978494330348, 52.497919809068229],
            [-0.879467363713016, 52.498391039057125],
            [-0.879363035144189, 52.49982940975746],
            [-0.880978149599561, 52.50044614746416],
            [-0.881828254273334, 52.500326518923792],
            [-0.881403400693229, 52.501162204569475],
            [-0.880474213073004, 52.501204663947625],
            [-0.881631522816699, 52.501635455620978],
            [-0.881196813783235, 52.502104240362264],
            [-0.882303251032538, 52.501986132120557],
            [-0.882739275363847, 52.503382856345084],
            [-0.881721314117885, 52.503786799277783],
            [-0.88116878718415, 52.504427085546169],
            [-0.880098904218343, 52.504437644905508],
            [-0.87984541635635, 52.504904543073486],
            [-0.880277267945315, 52.505477719326848],
            [-0.879809949820569, 52.506125997604791],
            [-0.880928700543206, 52.506165349733564],
            [-0.880534614299562, 52.507004918784702],
            [-0.881444245484911, 52.507679704256191],
            [-0.880441144970451, 52.508712202732973],
            [-0.881028835581709, 52.509242796908133],
            [-0.880007669746478, 52.510172630789945],
            [-0.880597878287244, 52.511242671950654],
            [-0.880046200358733, 52.511729225621345],
            [-0.880812453160902, 52.51178681929305],
            [-0.881161825647063, 52.512303471289705],
            [-0.881942385642049, 52.51197191065166],
            [-0.882454903506821, 52.51256382054553],
            [-0.881842334262162, 52.513011149053852],
            [-0.882727191692463, 52.513099518714824],
            [-0.882685281491103, 52.513589996126377],
            [-0.883421407065443, 52.513791133912733],
            [-0.882369799365317, 52.515105487353466],
            [-0.881177140729745, 52.51489193754179],
            [-0.881119975950675, 52.515402048903738],
            [-0.880536739177629, 52.515562855858292],
            [-0.881345813233308, 52.515851902633244],
            [-0.880338034334508, 52.516191199744256],
            [-0.878608561128343, 52.515992323962784],
            [-0.877959244555868, 52.516201040107489],
            [-0.878366600435852, 52.517276550095517],
            [-0.876145064864459, 52.517358867928344],
            [-0.87557689758063, 52.517909075672442],
            [-0.874355365671613, 52.517554933653955],
            [-0.873769833290499, 52.518495148771244],
            [-0.872623172055432, 52.518382642585244],
            [-0.871755243988859, 52.519413657560897],
            [-0.872455057106548, 52.520689762509427],
            [-0.871297241304036, 52.520952933952593],
            [-0.870795900797365, 52.522341656163974],
            [-0.871348436868994, 52.5241315085905],
            [-0.871005744982508, 52.524735088810495],
            [-0.868837859371758, 52.525869652499658],
            [-0.86876994877386, 52.526964026993561],
            [-0.867443608754574, 52.526894706175504],
            [-0.866574300504536, 52.526249870110291],
            [-0.865929775498109, 52.526779521797252],
            [-0.863877682910717, 52.52707360830378],
            [-0.863357897495851, 52.528308386949249],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000154",
        LAD13CDO: "34UF",
        LAD13NM: "Northampton",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.869593652327101, 52.281541511429246],
            [-0.864841773573691, 52.278593736938085],
            [-0.859817348467219, 52.281094205215659],
            [-0.857495199119272, 52.279790588966158],
            [-0.855828012859603, 52.281860294949517],
            [-0.85149875855743, 52.280912911731718],
            [-0.85223312503364, 52.279098531499343],
            [-0.84932691138814, 52.277720745943931],
            [-0.846532522713495, 52.280116487303303],
            [-0.845053250089636, 52.279899739894567],
            [-0.844523555534987, 52.280878143839281],
            [-0.839994134651666, 52.280929046494322],
            [-0.837519392645513, 52.281390213960456],
            [-0.832658491313159, 52.279214164658939],
            [-0.830745949616364, 52.2803703347275],
            [-0.827591735002469, 52.280969316547967],
            [-0.827177550600296, 52.280888783138394],
            [-0.826826118180105, 52.279764148145482],
            [-0.8253281669882, 52.278705434493624],
            [-0.824740004685298, 52.277832875831002],
            [-0.819950073302876, 52.274236465876157],
            [-0.819003269460437, 52.274235992414383],
            [-0.816016132775129, 52.275615833268539],
            [-0.815340606682892, 52.275004880546931],
            [-0.809157409944698, 52.275313114590404],
            [-0.807318619903013, 52.272175664268843],
            [-0.805706070019701, 52.270826936420484],
            [-0.803559808716267, 52.267912881061854],
            [-0.802974402297571, 52.266686900609365],
            [-0.803481967206577, 52.265850507890583],
            [-0.802856086086199, 52.264115238157153],
            [-0.803069119957875, 52.263441291058776],
            [-0.802589718066899, 52.26296081861112],
            [-0.80181303241875, 52.262257053902587],
            [-0.80139516563484, 52.261342945920639],
            [-0.801706114625175, 52.261059295742704],
            [-0.801144432577568, 52.260588771027564],
            [-0.801539656016004, 52.260439939539602],
            [-0.801749358307186, 52.259128513838569],
            [-0.801436579465216, 52.258991376756789],
            [-0.80170651282054, 52.258543677875778],
            [-0.801087292578287, 52.258415117705759],
            [-0.801384531355434, 52.258150209766782],
            [-0.800892717547416, 52.257861107534303],
            [-0.801438621970469, 52.257284945811435],
            [-0.801241469314229, 52.253511306904812],
            [-0.801417403932795, 52.252636490312206],
            [-0.795099884051088, 52.254066463417487],
            [-0.791432397560383, 52.242227642889048],
            [-0.791369856177347, 52.241941993657669],
            [-0.792617759604626, 52.241669752980364],
            [-0.798944232276221, 52.241202025093145],
            [-0.802852497308624, 52.240519756796381],
            [-0.803277375341892, 52.242092960917532],
            [-0.804570520107393, 52.241442539592136],
            [-0.805533438135273, 52.241526010114093],
            [-0.806742273491894, 52.241177702404784],
            [-0.808422640759667, 52.241541718908223],
            [-0.809633086907057, 52.241187104371136],
            [-0.81162093475528, 52.241555074049707],
            [-0.81352142778653, 52.240861217469039],
            [-0.815622585038704, 52.239504024133922],
            [-0.818065394604781, 52.238842506352782],
            [-0.821675473544324, 52.238562116001027],
            [-0.822447944306513, 52.237982723898284],
            [-0.822771597137971, 52.238259271938375],
            [-0.822863599958535, 52.237992263435025],
            [-0.828880696582597, 52.238452194296343],
            [-0.830544081717532, 52.237960702703319],
            [-0.832727142652981, 52.237866325555892],
            [-0.834065473117496, 52.238152870101032],
            [-0.834923176951146, 52.237443872189935],
            [-0.836932251311318, 52.237953679531103],
            [-0.839475411177455, 52.237584010094501],
            [-0.840994290608033, 52.23695608934851],
            [-0.838794679821039, 52.233141259907903],
            [-0.83645864036451, 52.233383488009665],
            [-0.834173055259241, 52.233263839035807],
            [-0.833945067216838, 52.233023331223251],
            [-0.833689373098499, 52.232219725144944],
            [-0.833209890178737, 52.232021684967336],
            [-0.834956218828769, 52.231315172325914],
            [-0.834722995907743, 52.230547753265888],
            [-0.835421405103666, 52.227974284383293],
            [-0.834104982848583, 52.226247642497832],
            [-0.831903082534914, 52.225184739197658],
            [-0.830150936524608, 52.223445647404453],
            [-0.833934112881788, 52.22270986851607],
            [-0.833788211024312, 52.221126043627073],
            [-0.836584538295727, 52.220555747624992],
            [-0.835647738738766, 52.2181450634546],
            [-0.837572096632442, 52.217493314579237],
            [-0.837879040297349, 52.217959364046749],
            [-0.838351591013317, 52.217857024684704],
            [-0.838582677838687, 52.214216218129536],
            [-0.837024362813867, 52.211674452611874],
            [-0.840837536785118, 52.209236787245992],
            [-0.847094202162727, 52.20770489273707],
            [-0.847207069887268, 52.20769790342289],
            [-0.851059363784213, 52.207850548278586],
            [-0.854357575571761, 52.207526571458367],
            [-0.865684668690033, 52.204770600186208],
            [-0.865072756325732, 52.204434754783996],
            [-0.868985430520267, 52.202430499252088],
            [-0.869429667170145, 52.202569617701528],
            [-0.872156155287193, 52.201456567062735],
            [-0.875889818021252, 52.199391033585755],
            [-0.877672084425901, 52.1973535939036],
            [-0.878202685993891, 52.195048897118994],
            [-0.879823641866256, 52.195111045947748],
            [-0.881142941185161, 52.194950937080861],
            [-0.881471514993201, 52.195558234601862],
            [-0.881917369302842, 52.195631688664534],
            [-0.882555837431582, 52.19555052472171],
            [-0.882662875819972, 52.195191006395845],
            [-0.883049599642134, 52.195755694014139],
            [-0.883601501084924, 52.195392292562055],
            [-0.884169145101727, 52.195451605731918],
            [-0.887125447212576, 52.197230929838582],
            [-0.888831267555024, 52.197707331949786],
            [-0.890604389398245, 52.197538801913183],
            [-0.892306306667795, 52.196938018264539],
            [-0.892048471535509, 52.192419513770751],
            [-0.894163811042105, 52.188885284730496],
            [-0.894339929269738, 52.18608989090589],
            [-0.895425817433021, 52.186599949521344],
            [-0.902495407092731, 52.190103995838058],
            [-0.907864962901575, 52.19371416741933],
            [-0.917492930824596, 52.200160257994483],
            [-0.925873525326548, 52.204254802741318],
            [-0.930918298605385, 52.206301949123223],
            [-0.936502737341855, 52.208076825721839],
            [-0.950556417305902, 52.211679333910972],
            [-0.967674991574532, 52.218666834207383],
            [-0.967364936294493, 52.218970705939149],
            [-0.967795513678976, 52.221171803178379],
            [-0.965746839967311, 52.221327393159072],
            [-0.966791039937849, 52.222507128116078],
            [-0.96716778532005, 52.224188991103183],
            [-0.96849372136347, 52.22547187051812],
            [-0.96719691530255, 52.225836344253437],
            [-0.96939727418924, 52.228117635290438],
            [-0.967558642140394, 52.22804133266991],
            [-0.968565443201468, 52.228316257945345],
            [-0.969216640029271, 52.228900047729041],
            [-0.969418450474145, 52.231187246085767],
            [-0.968230617862426, 52.231391749040014],
            [-0.967166419381483, 52.232009994901404],
            [-0.965249247605437, 52.235368316181912],
            [-0.967851524109768, 52.235489991993433],
            [-0.971040919801033, 52.234982884758992],
            [-0.968721608110541, 52.237122213475303],
            [-0.96876693228275, 52.238199694183017],
            [-0.969378304655431, 52.23880201421931],
            [-0.96893557348582, 52.240212384122373],
            [-0.964638647466099, 52.240905725776287],
            [-0.964207581859838, 52.24262545999234],
            [-0.964780429779942, 52.245449962604063],
            [-0.965361724905489, 52.246276805894503],
            [-0.9645314952987, 52.249600574808682],
            [-0.968295082416674, 52.250276351361642],
            [-0.966153395897167, 52.253871869676381],
            [-0.965537940446455, 52.256146513109393],
            [-0.961770159141434, 52.259458873870983],
            [-0.960047511320737, 52.260821085982705],
            [-0.958542033809479, 52.26141468861524],
            [-0.955054176597968, 52.26194040009949],
            [-0.946198391959262, 52.264037450134659],
            [-0.944849092402849, 52.263409541347706],
            [-0.93717109029445, 52.26916664559235],
            [-0.932923570718163, 52.269006970215059],
            [-0.932329655159088, 52.270975953535263],
            [-0.929022459641249, 52.269920173857201],
            [-0.92825827668675, 52.27116294490871],
            [-0.924678006005519, 52.271705803860932],
            [-0.925171142347479, 52.272646221737297],
            [-0.92415651049193, 52.273633149844322],
            [-0.922561181537036, 52.273784035203619],
            [-0.922924422666085, 52.275366109998537],
            [-0.922666637575082, 52.276688983271015],
            [-0.92149538373787, 52.276634239489859],
            [-0.916142794129904, 52.276383849816597],
            [-0.910428692255373, 52.27671247855374],
            [-0.905666179062611, 52.277322069437695],
            [-0.90567066836371, 52.27767544468874],
            [-0.905096334995254, 52.277724964955816],
            [-0.904932536447168, 52.279197018397504],
            [-0.902653629926454, 52.279060786603964],
            [-0.902848438128872, 52.280135184188772],
            [-0.899447220770119, 52.278733361032423],
            [-0.899191631587705, 52.279346840786886],
            [-0.898104556047472, 52.280033481754302],
            [-0.898056933348197, 52.280653394360243],
            [-0.894696879622564, 52.280311819644758],
            [-0.888375215209643, 52.277711722143465],
            [-0.888440071822549, 52.276290904146791],
            [-0.885132957150001, 52.275374149129519],
            [-0.882332881632499, 52.278079057959161],
            [-0.87965887709494, 52.280304993356907],
            [-0.875769821198827, 52.282222616474186],
            [-0.873951923089033, 52.282691695650364],
            [-0.871778412486557, 52.282500130741163],
            [-0.869593652327101, 52.281541511429246],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000155",
        LAD13CDO: "34UG",
        LAD13NM: "South Northamptonshire",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.961770159141434, 52.259458873870983],
            [-0.965537940446455, 52.256146513109393],
            [-0.966153395897167, 52.253871869676381],
            [-0.968295082416674, 52.250276351361642],
            [-0.9645314952987, 52.249600574808682],
            [-0.965361724905489, 52.246276805894503],
            [-0.964780429779942, 52.245449962604063],
            [-0.964207581859838, 52.24262545999234],
            [-0.964638647466099, 52.240905725776287],
            [-0.96893557348582, 52.240212384122373],
            [-0.969378304655431, 52.23880201421931],
            [-0.96876693228275, 52.238199694183017],
            [-0.968721608110541, 52.237122213475303],
            [-0.971040919801033, 52.234982884758992],
            [-0.967851524109768, 52.235489991993433],
            [-0.965249247605437, 52.235368316181912],
            [-0.967166419381483, 52.232009994901404],
            [-0.968230617862426, 52.231391749040014],
            [-0.969418450474145, 52.231187246085767],
            [-0.969216640029271, 52.228900047729041],
            [-0.968565443201468, 52.228316257945345],
            [-0.967558642140394, 52.22804133266991],
            [-0.96939727418924, 52.228117635290438],
            [-0.96719691530255, 52.225836344253437],
            [-0.96849372136347, 52.22547187051812],
            [-0.96716778532005, 52.224188991103183],
            [-0.966791039937849, 52.222507128116078],
            [-0.965746839967311, 52.221327393159072],
            [-0.967795513678976, 52.221171803178379],
            [-0.967364936294493, 52.218970705939149],
            [-0.967674991574532, 52.218666834207383],
            [-0.950556417305902, 52.211679333910972],
            [-0.936502737341855, 52.208076825721839],
            [-0.930918298605385, 52.206301949123223],
            [-0.925873525326548, 52.204254802741318],
            [-0.917492930824596, 52.200160257994483],
            [-0.907864962901575, 52.19371416741933],
            [-0.902495407092731, 52.190103995838058],
            [-0.895425817433021, 52.186599949521344],
            [-0.894339929269738, 52.18608989090589],
            [-0.894163811042105, 52.188885284730496],
            [-0.892048471535509, 52.192419513770751],
            [-0.892306306667795, 52.196938018264539],
            [-0.890604389398245, 52.197538801913183],
            [-0.888831267555024, 52.197707331949786],
            [-0.887125447212576, 52.197230929838582],
            [-0.884169145101727, 52.195451605731918],
            [-0.883601501084924, 52.195392292562055],
            [-0.883049599642134, 52.195755694014139],
            [-0.882662875819972, 52.195191006395845],
            [-0.882555837431582, 52.19555052472171],
            [-0.881917369302842, 52.195631688664534],
            [-0.881471514993201, 52.195558234601862],
            [-0.881142941185161, 52.194950937080861],
            [-0.879823641866256, 52.195111045947748],
            [-0.878202685993891, 52.195048897118994],
            [-0.877672084425901, 52.1973535939036],
            [-0.875889818021252, 52.199391033585755],
            [-0.872156155287193, 52.201456567062735],
            [-0.869429667170145, 52.202569617701528],
            [-0.868985430520267, 52.202430499252088],
            [-0.865072756325732, 52.204434754783996],
            [-0.865684668690033, 52.204770600186208],
            [-0.854357575571761, 52.207526571458367],
            [-0.851059363784213, 52.207850548278586],
            [-0.847207069887268, 52.20769790342289],
            [-0.847094202162727, 52.20770489273707],
            [-0.840837536785118, 52.209236787245992],
            [-0.837024362813867, 52.211674452611874],
            [-0.838582677838687, 52.214216218129536],
            [-0.838351591013317, 52.217857024684704],
            [-0.837879040297349, 52.217959364046749],
            [-0.837572096632442, 52.217493314579237],
            [-0.835647738738766, 52.2181450634546],
            [-0.836584538295727, 52.220555747624992],
            [-0.833788211024312, 52.221126043627073],
            [-0.833934112881788, 52.22270986851607],
            [-0.830150936524608, 52.223445647404453],
            [-0.831903082534914, 52.225184739197658],
            [-0.834104982848583, 52.226247642497832],
            [-0.835421405103666, 52.227974284383293],
            [-0.834722995907743, 52.230547753265888],
            [-0.834956218828769, 52.231315172325914],
            [-0.833209890178737, 52.232021684967336],
            [-0.833689373098499, 52.232219725144944],
            [-0.833945067216838, 52.233023331223251],
            [-0.834173055259241, 52.233263839035807],
            [-0.83645864036451, 52.233383488009665],
            [-0.838794679821039, 52.233141259907903],
            [-0.840994290608033, 52.23695608934851],
            [-0.839475411177455, 52.237584010094501],
            [-0.836932251311318, 52.237953679531103],
            [-0.834923176951146, 52.237443872189935],
            [-0.834065473117496, 52.238152870101032],
            [-0.832727142652981, 52.237866325555892],
            [-0.830544081717532, 52.237960702703319],
            [-0.828880696582597, 52.238452194296343],
            [-0.822863599958535, 52.237992263435025],
            [-0.822771597137971, 52.238259271938375],
            [-0.822447944306513, 52.237982723898284],
            [-0.821675473544324, 52.238562116001027],
            [-0.818065394604781, 52.238842506352782],
            [-0.815622585038704, 52.239504024133922],
            [-0.81352142778653, 52.240861217469039],
            [-0.81162093475528, 52.241555074049707],
            [-0.809633086907057, 52.241187104371136],
            [-0.808422640759667, 52.241541718908223],
            [-0.806742273491894, 52.241177702404784],
            [-0.805533438135273, 52.241526010114093],
            [-0.804570520107393, 52.241442539592136],
            [-0.803277375341892, 52.242092960917532],
            [-0.802852497308624, 52.240519756796381],
            [-0.798944232276221, 52.241202025093145],
            [-0.792617759604626, 52.241669752980364],
            [-0.791369856177347, 52.241941993657669],
            [-0.791432397560383, 52.242227642889048],
            [-0.784216221160463, 52.243515663559364],
            [-0.783454655775735, 52.244210002210636],
            [-0.782858108671134, 52.244118441072509],
            [-0.781403429922336, 52.244555674303307],
            [-0.781379190834036, 52.245387075385374],
            [-0.78030186689509, 52.245521602294595],
            [-0.779167695121505, 52.246499770030738],
            [-0.777812567917496, 52.246508227197225],
            [-0.777061535110602, 52.24606799171093],
            [-0.775349766049391, 52.24599630388731],
            [-0.774379203955544, 52.245284948076012],
            [-0.772726665334623, 52.245350497532527],
            [-0.769910210605742, 52.245926270602276],
            [-0.769033290299289, 52.245856892497578],
            [-0.76797818714023, 52.246826787214452],
            [-0.76668815034501, 52.24617766680192],
            [-0.765915823093343, 52.247406735517743],
            [-0.764491648326166, 52.248159658310527],
            [-0.762305567872589, 52.248759827190213],
            [-0.760906741029666, 52.248651650651013],
            [-0.761008799560636, 52.248353326819384],
            [-0.759393641228368, 52.247493925073329],
            [-0.753724952145904, 52.247858592717535],
            [-0.748667923700515, 52.245951191515339],
            [-0.744121231168065, 52.243569788183059],
            [-0.741889300212905, 52.24397668742035],
            [-0.736808893642737, 52.242719462398171],
            [-0.727492241894112, 52.238114982564312],
            [-0.726032006567233, 52.235357913517319],
            [-0.724611159252606, 52.233889644460724],
            [-0.724860848269177, 52.232841306762133],
            [-0.723542457944501, 52.23040850835325],
            [-0.720667010879403, 52.228309474192791],
            [-0.720445520396284, 52.226897300156324],
            [-0.717992089593245, 52.224899873650116],
            [-0.718012831023573, 52.223467847129008],
            [-0.715765372781114, 52.221495985147868],
            [-0.714320340056246, 52.221449685451709],
            [-0.714644437560735, 52.221277892247379],
            [-0.714349201796913, 52.221109244084772],
            [-0.714881071220636, 52.220890263040687],
            [-0.716954592122819, 52.21792244115624],
            [-0.717416325553969, 52.215766042158798],
            [-0.716965480288151, 52.213276949957447],
            [-0.711726830095045, 52.197342782628816],
            [-0.707450761960716, 52.192980364519357],
            [-0.705473136739614, 52.191557894133595],
            [-0.705061417977029, 52.190773859878398],
            [-0.708679982849475, 52.188778857313153],
            [-0.711636337913026, 52.188174611677077],
            [-0.716045452487278, 52.185428276672127],
            [-0.719025778554615, 52.182552989446997],
            [-0.720756127867365, 52.181889347054337],
            [-0.72550683108462, 52.180863638225375],
            [-0.729277889875998, 52.179461260480153],
            [-0.734749127499524, 52.173488017851909],
            [-0.737448262196955, 52.173656291367834],
            [-0.738768312967121, 52.172224662151393],
            [-0.738967876217054, 52.171165861091858],
            [-0.742556733389749, 52.170943426360743],
            [-0.74481301806968, 52.170267954435666],
            [-0.746083811205982, 52.168859995529793],
            [-0.749752824616594, 52.166865176436396],
            [-0.750674555458289, 52.167627484098389],
            [-0.756828227589919, 52.167438041322043],
            [-0.759865397272924, 52.165692492461588],
            [-0.760994486861342, 52.163995174055216],
            [-0.762065635221472, 52.163434593300479],
            [-0.762764343401457, 52.164968587419651],
            [-0.762734735148892, 52.16629983752685],
            [-0.764158566698207, 52.167787478685511],
            [-0.765349564974681, 52.171087038815095],
            [-0.773607861923028, 52.168875981874848],
            [-0.777896760993236, 52.168759547090033],
            [-0.779532282733633, 52.165577500298454],
            [-0.783212010776593, 52.165912209118126],
            [-0.783113182993731, 52.16417503929145],
            [-0.780330227071149, 52.163344309842074],
            [-0.783430366987814, 52.160263653388192],
            [-0.78420493196745, 52.158663157682213],
            [-0.786420826005996, 52.156523649905637],
            [-0.787733008729235, 52.154228264945033],
            [-0.790487855466274, 52.152088823407162],
            [-0.792109247984003, 52.151316929753442],
            [-0.793580173510623, 52.152270637025879],
            [-0.79427670867978, 52.152349685808289],
            [-0.793891758562094, 52.153234955579372],
            [-0.794114252546238, 52.15403472721227],
            [-0.796870844897754, 52.153391249212142],
            [-0.798934522632657, 52.155355220666834],
            [-0.799725019955006, 52.157051764504217],
            [-0.801973802956722, 52.158666018004098],
            [-0.802689712039001, 52.157536831717991],
            [-0.805569802629897, 52.15736193222461],
            [-0.806588049240907, 52.156893919478769],
            [-0.807604993969715, 52.156966239872645],
            [-0.806964789095782, 52.152807753311173],
            [-0.807228521177629, 52.151690149132946],
            [-0.809296503560205, 52.150000064501143],
            [-0.809199673719192, 52.148479617880284],
            [-0.810714432039668, 52.147633566652118],
            [-0.811548813985082, 52.146540585190003],
            [-0.812823707106216, 52.145697484746115],
            [-0.812701983863722, 52.144339525972477],
            [-0.813569367779193, 52.14376024530997],
            [-0.814058398302255, 52.142459677385759],
            [-0.816561518030277, 52.143512481959185],
            [-0.831983526113747, 52.143670690940759],
            [-0.831379680310938, 52.141281213545987],
            [-0.828649212986432, 52.135684242100034],
            [-0.828221718736573, 52.132597898607393],
            [-0.832057094631786, 52.131247736697368],
            [-0.835785130882919, 52.132830137271931],
            [-0.838245329161974, 52.130701063610672],
            [-0.846755368439739, 52.129397276303159],
            [-0.851281476739857, 52.12933085583655],
            [-0.852956423696622, 52.130325370646482],
            [-0.853545966162366, 52.131660861639162],
            [-0.866009818085838, 52.130152194681735],
            [-0.868653091897105, 52.1300759808748],
            [-0.869000333286616, 52.130613371906854],
            [-0.878880755964456, 52.126125928189218],
            [-0.879818318216866, 52.126660805311538],
            [-0.880736964939521, 52.126318880682682],
            [-0.880631708492762, 52.125389119637312],
            [-0.879670535711796, 52.124165319777163],
            [-0.879836124920779, 52.123794668313735],
            [-0.880436645971363, 52.123846223991933],
            [-0.880832985911793, 52.123476862222262],
            [-0.880038608383997, 52.122359841332369],
            [-0.880783088242628, 52.121736644596822],
            [-0.880699440951856, 52.120177722915976],
            [-0.881806227272636, 52.119841171891402],
            [-0.88156160854855, 52.119052145999788],
            [-0.882442207920489, 52.11882313318668],
            [-0.881973431459532, 52.118178535899247],
            [-0.883029793852476, 52.118045589602367],
            [-0.882904692245176, 52.11749955426663],
            [-0.88372131113462, 52.116556045937848],
            [-0.885229043803976, 52.116183697277165],
            [-0.88632134771372, 52.115602413898259],
            [-0.886531484183623, 52.114964243708215],
            [-0.887348092663447, 52.114720204807512],
            [-0.887032535832471, 52.114196652200306],
            [-0.885776324460811, 52.113387294273188],
            [-0.883692512312785, 52.113557296625075],
            [-0.881486774474553, 52.11264629407605],
            [-0.880913343987226, 52.112860232907494],
            [-0.880611919829543, 52.112650580567653],
            [-0.880394941638511, 52.113034232168104],
            [-0.878477819886484, 52.112497238543895],
            [-0.878209769119089, 52.112878602296412],
            [-0.876517366576102, 52.112930822667487],
            [-0.875278598272423, 52.112191647409162],
            [-0.874953060460394, 52.112477151574446],
            [-0.87391567688416, 52.112266752307541],
            [-0.872983018890737, 52.112652545421241],
            [-0.87146683179112, 52.111740749352045],
            [-0.870451423112514, 52.110257411101806],
            [-0.869820256275838, 52.11044377025069],
            [-0.86916906123617, 52.109863005712619],
            [-0.869323571723764, 52.109469784395095],
            [-0.868634407968242, 52.108428315760172],
            [-0.869002164161507, 52.107975102851498],
            [-0.870259607879759, 52.107735409332918],
            [-0.869037035579567, 52.107058359691713],
            [-0.869811150762464, 52.106413936982491],
            [-0.869879788088205, 52.105084831977713],
            [-0.868434107137323, 52.104284259088864],
            [-0.867461087292366, 52.104711878775149],
            [-0.866836747567919, 52.103767224263656],
            [-0.865287093729632, 52.102925153700959],
            [-0.866039176168733, 52.101939785718528],
            [-0.86800149973282, 52.101071231667035],
            [-0.868794138972597, 52.101076140987182],
            [-0.869547771390721, 52.100025131534352],
            [-0.868932796809839, 52.100154098752888],
            [-0.868641159833071, 52.099620833843439],
            [-0.867471802230051, 52.099273346222631],
            [-0.866670101400446, 52.099454453887169],
            [-0.865399028565207, 52.099088885103484],
            [-0.864862232303291, 52.099243758041453],
            [-0.863539317728886, 52.098391258331375],
            [-0.862164156426794, 52.098274600263281],
            [-0.861348983915283, 52.097837861692078],
            [-0.86030986542326, 52.09799775218822],
            [-0.858469626120531, 52.097400032906457],
            [-0.857934341592277, 52.097495548516939],
            [-0.856391460489621, 52.095033249007372],
            [-0.856051222140391, 52.093096886439383],
            [-0.854796219219511, 52.09234204796828],
            [-0.854349495298763, 52.092572373061508],
            [-0.851698119457967, 52.092026002683376],
            [-0.850514437731788, 52.092078304869929],
            [-0.850095937368581, 52.092402395409231],
            [-0.846729894434575, 52.091496493852297],
            [-0.846295428849394, 52.091023812428922],
            [-0.846802517949123, 52.09077432793697],
            [-0.845945721138323, 52.089019892455816],
            [-0.846353112133988, 52.088107696221542],
            [-0.845594396455366, 52.087758611804638],
            [-0.845113532841772, 52.086427729307978],
            [-0.843728461068699, 52.085693974098113],
            [-0.843822296578193, 52.084886602622731],
            [-0.841309665184984, 52.084077020366557],
            [-0.841717547874779, 52.083711497667892],
            [-0.840669580283091, 52.083772226299523],
            [-0.839224580880792, 52.082822942650509],
            [-0.837461128004372, 52.082281390692152],
            [-0.836797538445316, 52.080580937180017],
            [-0.835266674468419, 52.0797979896115],
            [-0.835263821590012, 52.07760864677185],
            [-0.83604796970384, 52.076801804327886],
            [-0.836043881357525, 52.075051210663609],
            [-0.834955594187958, 52.074313985234234],
            [-0.834771152510265, 52.073608164457397],
            [-0.834194565160193, 52.07342174410627],
            [-0.834089191808251, 52.072733786966303],
            [-0.832553568133104, 52.072033472391979],
            [-0.831437060196028, 52.071935196964475],
            [-0.832768107141878, 52.070855972659032],
            [-0.832895987743029, 52.069196593736557],
            [-0.834211839433525, 52.06830421631026],
            [-0.835639183404249, 52.066376258391827],
            [-0.838786273886839, 52.064111888847179],
            [-0.842779709739776, 52.063121686451787],
            [-0.845250557710519, 52.062961601133104],
            [-0.846901795050354, 52.06358645889997],
            [-0.847936483338508, 52.063626246328859],
            [-0.849803315306288, 52.064435688034791],
            [-0.850828137663574, 52.064690239428202],
            [-0.853093961108293, 52.064335582436328],
            [-0.855258166890127, 52.063960114928584],
            [-0.857376179239922, 52.064078666686932],
            [-0.859255301579041, 52.063611350998045],
            [-0.859287579924708, 52.063145928040143],
            [-0.862696225504438, 52.060922116417444],
            [-0.863033939057698, 52.060260038056462],
            [-0.86202474955062, 52.059334113290653],
            [-0.86064338640311, 52.058705782417384],
            [-0.860217623745948, 52.058069596388684],
            [-0.859887081855377, 52.056214245834134],
            [-0.860990509352973, 52.055437298685021],
            [-0.860420401295769, 52.05451380287726],
            [-0.858017949804544, 52.053212923444271],
            [-0.855646370328825, 52.053163847861676],
            [-0.8533016506341, 52.052236560278303],
            [-0.852462668295283, 52.050829387227971],
            [-0.852953552444131, 52.049940454808244],
            [-0.855722994088602, 52.047937194698711],
            [-0.855604113848626, 52.047339034386347],
            [-0.857901031812532, 52.046014454461513],
            [-0.859359738333016, 52.044618783264326],
            [-0.862612868378444, 52.045249026699508],
            [-0.863248331308433, 52.046007708585257],
            [-0.864034392791039, 52.045888513685505],
            [-0.864219924364495, 52.045587303310015],
            [-0.865025296395437, 52.045856701329811],
            [-0.865620923793503, 52.046635665953069],
            [-0.866133128047455, 52.046678357102458],
            [-0.86775191248733, 52.046209301906494],
            [-0.870624204850169, 52.043792194302505],
            [-0.870471789527623, 52.043360062371285],
            [-0.87180789362107, 52.041834491105782],
            [-0.871343557390969, 52.040240427029623],
            [-0.872138612776406, 52.039758925046591],
            [-0.873187556522107, 52.03735486039777],
            [-0.872084461955338, 52.036233013273261],
            [-0.873352996120683, 52.035471017116855],
            [-0.873148214418869, 52.035092333773392],
            [-0.873971185031779, 52.035003995692151],
            [-0.876053662993854, 52.033579910940865],
            [-0.876286693112498, 52.032483425491321],
            [-0.877321873401933, 52.031020555805497],
            [-0.876847849132237, 52.030682470444013],
            [-0.877729137427937, 52.029833119011755],
            [-0.879851624198794, 52.029126835254289],
            [-0.880311667004541, 52.028682553155981],
            [-0.883159056450079, 52.028245634620347],
            [-0.887176831614494, 52.030098939189564],
            [-0.889254027925246, 52.031509452451822],
            [-0.890064486544314, 52.031569236699355],
            [-0.890774428519777, 52.031155139896846],
            [-0.89135819356792, 52.029889298142116],
            [-0.893677562060923, 52.028756643908054],
            [-0.8957740622004, 52.028205375510609],
            [-0.897282239799526, 52.027274538268863],
            [-0.898319783582669, 52.027462265508568],
            [-0.899716536227177, 52.02637660849669],
            [-0.90026461217484, 52.025007891484464],
            [-0.903893827527618, 52.023693047115302],
            [-0.904596257323793, 52.022323048291462],
            [-0.90604874410879, 52.021209962222734],
            [-0.908328946827629, 52.022191353499323],
            [-0.911794462057007, 52.024961182467038],
            [-0.912126981407856, 52.026574549001971],
            [-0.914112074433511, 52.029586874623341],
            [-0.922582613424833, 52.035339759397189],
            [-0.924253496599604, 52.035998780264137],
            [-0.927283290584179, 52.039393533057741],
            [-0.928195667781316, 52.042210626107426],
            [-0.930225533055324, 52.043115581529328],
            [-0.930420990152907, 52.043723349313304],
            [-0.929835501398512, 52.045038813299549],
            [-0.930301645877201, 52.045729957576981],
            [-0.930204650895596, 52.048632271521051],
            [-0.930718335484592, 52.048799669881525],
            [-0.930130701254752, 52.049530700146221],
            [-0.931851238478838, 52.052170776140521],
            [-0.931890391453658, 52.054015183248758],
            [-0.933466595327395, 52.055071511228419],
            [-0.935729869275222, 52.05790614585537],
            [-0.93661168515797, 52.05817753998555],
            [-0.937898164473108, 52.061190326804017],
            [-0.933762259439601, 52.06262481224897],
            [-0.937657636064369, 52.062844296773349],
            [-0.939334139038719, 52.062148207599606],
            [-0.941664785022782, 52.064261353851208],
            [-0.940499876532342, 52.065035801197325],
            [-0.942060804241624, 52.066578284088237],
            [-0.940477122180244, 52.067415505303885],
            [-0.939297185514946, 52.067341957721737],
            [-0.941558214573598, 52.068214623393871],
            [-0.941767022689472, 52.068762249343209],
            [-0.944408618083729, 52.070020390963691],
            [-0.944507896767975, 52.073173508656339],
            [-0.940073681292826, 52.075830112553831],
            [-0.941832105883251, 52.075946607110517],
            [-0.941989793668446, 52.076251017349122],
            [-0.945119596429627, 52.076873364729003],
            [-0.947220805030572, 52.079168653535994],
            [-0.948845830428443, 52.079752279563728],
            [-0.950340656527712, 52.079853708699908],
            [-0.951890733539841, 52.081521831109477],
            [-0.959566552097149, 52.079271055820485],
            [-0.960780877025724, 52.07863262036021],
            [-0.962745485258612, 52.076344645716617],
            [-0.965121046836858, 52.074827173094093],
            [-0.967823403499164, 52.070899346623747],
            [-0.970166550546149, 52.072356590126546],
            [-0.972242079580995, 52.072321655808089],
            [-0.973375543734141, 52.07271184808836],
            [-0.975316889661408, 52.072484184162242],
            [-0.977481844051047, 52.071664127372834],
            [-0.981158783569442, 52.071331828642052],
            [-0.982400907335627, 52.06996065075483],
            [-0.983178597161653, 52.069828900649789],
            [-0.983978509737986, 52.070067763273691],
            [-0.984297483555332, 52.069705480102769],
            [-0.986386140281517, 52.070180195861035],
            [-0.987442594477963, 52.070753005505416],
            [-0.987901431148984, 52.070462942203626],
            [-0.989140743150468, 52.070821523990055],
            [-0.990031154152451, 52.070607978657982],
            [-0.991082417405909, 52.070895698691039],
            [-0.99345654275562, 52.072111784995975],
            [-0.993961492682152, 52.072820084042398],
            [-0.995509189113517, 52.073225284982442],
            [-0.998261044768589, 52.072568986014353],
            [-1.000463285013105, 52.072477988526202],
            [-1.002452362975325, 52.072720507546656],
            [-1.004323818058779, 52.073398058809317],
            [-1.005895360370916, 52.073391540641218],
            [-1.007067021248567, 52.072271256917233],
            [-1.006290088826445, 52.07158140153917],
            [-1.006525655488776, 52.071419752251032],
            [-1.007558485356804, 52.072083888703837],
            [-1.010917739050995, 52.071989842843251],
            [-1.01288313806716, 52.071587338963049],
            [-1.013524862494736, 52.072855932743828],
            [-1.016278586241847, 52.072632591204524],
            [-1.020094172109351, 52.073288335301811],
            [-1.021473173308078, 52.07494782069584],
            [-1.023099080958163, 52.074926239629569],
            [-1.026355325994311, 52.075417105917062],
            [-1.026335223721167, 52.075670483105611],
            [-1.028704532434163, 52.075012112819465],
            [-1.042129622265499, 52.073925262444668],
            [-1.042344575303496, 52.073698640143064],
            [-1.031770316676297, 52.071156860321835],
            [-1.029568397588679, 52.070094912771751],
            [-1.028453930871299, 52.06786857740304],
            [-1.026138958197212, 52.065710540129139],
            [-1.026422618269977, 52.064405604858841],
            [-1.027686136880277, 52.063722833748052],
            [-1.03096451618745, 52.062885801424237],
            [-1.033175528725167, 52.063511740787035],
            [-1.034635474222701, 52.064537879320198],
            [-1.037069763720663, 52.065233904030521],
            [-1.038220126020887, 52.066077650299604],
            [-1.040066133230855, 52.065912878131272],
            [-1.048271658402769, 52.062835290667024],
            [-1.049788890082158, 52.061382912463252],
            [-1.051814723293834, 52.060705126785408],
            [-1.052976244615332, 52.05964904194272],
            [-1.057835138349252, 52.061021325727914],
            [-1.06208205612359, 52.062723900803192],
            [-1.064827414304578, 52.06153194093735],
            [-1.068122505774051, 52.061041957227872],
            [-1.070729526972587, 52.05954487465636],
            [-1.071673519466311, 52.058123659841279],
            [-1.07666886004641, 52.058839905847343],
            [-1.077495479822921, 52.057867269860715],
            [-1.078266342281924, 52.058043227539116],
            [-1.078181939473446, 52.057750363725908],
            [-1.078957860140427, 52.057254737157081],
            [-1.080888840383908, 52.056761823346967],
            [-1.080416985314852, 52.056088322258319],
            [-1.081122758347076, 52.054459282110919],
            [-1.082249053147375, 52.052873971217991],
            [-1.083146522907334, 52.052774862586411],
            [-1.083292306346284, 52.052349828106017],
            [-1.085395915713686, 52.052174662581692],
            [-1.088916867357316, 52.052365562886273],
            [-1.090345185289078, 52.051402880447924],
            [-1.09246266487431, 52.051115309879279],
            [-1.09453480047483, 52.051266108343036],
            [-1.094993377639358, 52.05159420097889],
            [-1.096143303721419, 52.05133959095344],
            [-1.098089099439579, 52.050233331291409],
            [-1.098196125325327, 52.049770220363349],
            [-1.098551303199679, 52.049874534115318],
            [-1.098461975736845, 52.049023312987224],
            [-1.098886942587742, 52.04863006428868],
            [-1.102435926800643, 52.047919888827288],
            [-1.10291600148232, 52.048049415832679],
            [-1.103619018102048, 52.047749972970017],
            [-1.105083348898265, 52.048319433442536],
            [-1.106051464474895, 52.048107401878013],
            [-1.105637010041383, 52.048854097772562],
            [-1.106275783486521, 52.049070228399096],
            [-1.11100331867446, 52.048193402805886],
            [-1.111187680966297, 52.047941250215914],
            [-1.113596676712545, 52.048087060621164],
            [-1.117241008379364, 52.047334000721399],
            [-1.119706096738864, 52.046479421526499],
            [-1.122219445682823, 52.044942742528285],
            [-1.123159348553585, 52.041618616211331],
            [-1.125162684995954, 52.040570779659305],
            [-1.125199558200358, 52.040100830019163],
            [-1.126355934094265, 52.039341585183536],
            [-1.126509462445921, 52.038869802417395],
            [-1.125930468221906, 52.038730646847362],
            [-1.127118987551027, 52.037965339230688],
            [-1.126618226016042, 52.037178523242012],
            [-1.129975646268383, 52.033499107207902],
            [-1.130821996125849, 52.033379479234426],
            [-1.131273781486709, 52.032858641050382],
            [-1.131776679260996, 52.031956064656661],
            [-1.132955963366178, 52.031729184635353],
            [-1.132489823011375, 52.029678527539865],
            [-1.133693821285602, 52.026584624410553],
            [-1.13354851889671, 52.026033313063834],
            [-1.134302910438019, 52.025686413524923],
            [-1.135467227388252, 52.024260911344363],
            [-1.13530123206415, 52.023723835632822],
            [-1.135791908000855, 52.022916455171838],
            [-1.136320451939309, 52.022941908018943],
            [-1.136077673872889, 52.022456417816223],
            [-1.136680015519302, 52.022281912831275],
            [-1.135997240438584, 52.021108990263038],
            [-1.136175540876147, 52.020023296447128],
            [-1.134465536945309, 52.019600764127951],
            [-1.134200644959009, 52.019281439130332],
            [-1.13362865639275, 52.019395016385694],
            [-1.133420423361849, 52.019085097041071],
            [-1.132588118407941, 52.019321728420621],
            [-1.132038013713274, 52.018831269658293],
            [-1.130747877875114, 52.018629354877596],
            [-1.13052103617505, 52.018152961098856],
            [-1.129996123885245, 52.018319015360582],
            [-1.128914046152253, 52.017910026965112],
            [-1.128443435196648, 52.01798117197378],
            [-1.128418433206998, 52.018292971880939],
            [-1.12758436792522, 52.01816991826604],
            [-1.127440110704456, 52.017794828050647],
            [-1.125857125379956, 52.017443239726433],
            [-1.125708055535598, 52.017016863351721],
            [-1.12459380318145, 52.01669211092868],
            [-1.124580291850355, 52.017085812901335],
            [-1.123132685677695, 52.017265657779852],
            [-1.121540166572202, 52.016957096749032],
            [-1.120021177727832, 52.017135472184009],
            [-1.119452468270374, 52.016339123626906],
            [-1.11894502148487, 52.016653609515501],
            [-1.118904630631922, 52.016335927874188],
            [-1.118275255923648, 52.016329422244908],
            [-1.118494482527427, 52.015925571593044],
            [-1.118103786674718, 52.015415560121625],
            [-1.119843015258753, 52.015669520036553],
            [-1.121447244372604, 52.012400701715279],
            [-1.121955325660133, 52.012124870740429],
            [-1.122507076037193, 52.012223385443328],
            [-1.123147818217707, 52.011347043935992],
            [-1.125362325111862, 52.007094607111071],
            [-1.126345433646086, 52.007034468283834],
            [-1.126984121547192, 52.006031318151628],
            [-1.12814369730954, 52.005360188953347],
            [-1.128586593922259, 52.005509118348066],
            [-1.131270704482194, 52.004874397767438],
            [-1.131547150498718, 52.003085441042764],
            [-1.133761027958317, 51.999633033126763],
            [-1.134346695922037, 51.997286205781016],
            [-1.14019090861738, 51.997640983980894],
            [-1.143924500222191, 51.9970091343543],
            [-1.148740749939509, 51.995041747323775],
            [-1.151498167696973, 51.993099814113471],
            [-1.156127680004608, 51.993434259691313],
            [-1.156668895216057, 51.992224358037397],
            [-1.163841556503351, 51.993402044230805],
            [-1.172201506411524, 51.985974324862688],
            [-1.174379362658911, 51.983313902764529],
            [-1.176072963460917, 51.982035558866116],
            [-1.176710903690291, 51.98250215319117],
            [-1.180125755283214, 51.981685314280988],
            [-1.181007302916188, 51.981810126343142],
            [-1.1830211627938, 51.981482453239181],
            [-1.184744192555593, 51.981693988188312],
            [-1.188358151773562, 51.981208256157295],
            [-1.190774266538654, 51.98150089869651],
            [-1.194795032435208, 51.979028967531384],
            [-1.196278296102878, 51.977421626057144],
            [-1.198019155323124, 51.977281543056527],
            [-1.200660189130212, 51.977935151800374],
            [-1.203553531845017, 51.978568828798977],
            [-1.206916484140252, 51.978255255093714],
            [-1.217240893252027, 51.980323888108607],
            [-1.221058271822686, 51.980171169221251],
            [-1.223662063971767, 51.980396945455745],
            [-1.227127449886591, 51.981237903749317],
            [-1.229718595968225, 51.981357370009974],
            [-1.230398769598572, 51.9820253447635],
            [-1.232112819256228, 51.982350311953219],
            [-1.234460375624861, 51.983524517988251],
            [-1.236372025328878, 51.98370865028695],
            [-1.239307383177723, 51.984578179178371],
            [-1.241630257982553, 51.986283444181424],
            [-1.247396981639391, 51.985055401779043],
            [-1.248218862833612, 51.985627974860051],
            [-1.249025765585441, 51.985704148899558],
            [-1.250168305782046, 51.985301440756928],
            [-1.251189645246662, 51.984219138215941],
            [-1.251988113848944, 51.98410103493719],
            [-1.252804714955128, 51.983330301729268],
            [-1.254538138889412, 51.982675955527149],
            [-1.254698944341118, 51.982900846363371],
            [-1.257278280939205, 51.982511645134998],
            [-1.259435073078959, 51.981253908363385],
            [-1.260933059534499, 51.980916266042414],
            [-1.267670013550591, 51.982616256486573],
            [-1.270262437550335, 51.982937133273758],
            [-1.28088225543436, 51.982354187832918],
            [-1.2812162628353, 51.982227656272244],
            [-1.281593148839651, 51.979966048245004],
            [-1.282743676618638, 51.979217823090295],
            [-1.283522307799855, 51.979693683650815],
            [-1.282476441652024, 51.98063495891337],
            [-1.283463591386023, 51.982005783975048],
            [-1.284467899482169, 51.982577414542547],
            [-1.284306121299263, 51.983592402292643],
            [-1.285005842627058, 51.983542705329434],
            [-1.287392834834154, 51.987645321147319],
            [-1.287363354348531, 51.988677295761114],
            [-1.286847999016163, 51.989308933630234],
            [-1.287345548005475, 51.989524128737642],
            [-1.288452345939658, 51.989247605219795],
            [-1.288875992251637, 51.989715890238266],
            [-1.287183749077939, 51.990267594586662],
            [-1.286935028374157, 51.990614935624535],
            [-1.286101962292398, 51.990342862127136],
            [-1.285444485608846, 51.990659851018705],
            [-1.284089896395997, 51.990672307434096],
            [-1.28389469565515, 51.991314867997367],
            [-1.283013670534794, 51.991492924099013],
            [-1.28325457011276, 51.991911566362369],
            [-1.282352146238201, 51.993063199096909],
            [-1.284687705391447, 51.994833318011302],
            [-1.283515932035731, 51.996159544765881],
            [-1.282432603675998, 51.996321980934326],
            [-1.283722729405752, 51.998078553997232],
            [-1.283418256217836, 51.999450506971272],
            [-1.282720833329367, 52.000343551941171],
            [-1.281893675182501, 52.000512037752003],
            [-1.281636599122661, 52.000283001996522],
            [-1.28059732943832, 52.000961765559857],
            [-1.280557372696766, 52.003182264340595],
            [-1.278833784779045, 52.004377403045389],
            [-1.277579616595318, 52.004732052696198],
            [-1.277082068962492, 52.00532329719578],
            [-1.277631221217189, 52.007228230868471],
            [-1.278508874205307, 52.00782430660847],
            [-1.278480967285424, 52.008200852498611],
            [-1.279792165445123, 52.008746526100126],
            [-1.283729341345396, 52.009349532833731],
            [-1.284587340854832, 52.010637738537717],
            [-1.284025665951429, 52.01114051211745],
            [-1.282174095552971, 52.011305469165023],
            [-1.280040845485526, 52.011045207717189],
            [-1.279936283240936, 52.011568735290481],
            [-1.279410472086784, 52.011285906010649],
            [-1.278648762419494, 52.011357668243136],
            [-1.278180962705917, 52.014262443542151],
            [-1.280649628678708, 52.016513559997833],
            [-1.280227755402768, 52.017014471226481],
            [-1.281690254916332, 52.017775028094768],
            [-1.280998340558748, 52.018122350676258],
            [-1.281128680107462, 52.018536724240306],
            [-1.282847936589358, 52.01919274036257],
            [-1.281897683549268, 52.019655376761882],
            [-1.282976497584243, 52.01990200027091],
            [-1.282993907205001, 52.020727464608669],
            [-1.283782701124996, 52.020429269664547],
            [-1.28443679480942, 52.020900765965116],
            [-1.284524150239829, 52.02145423237247],
            [-1.283478768115932, 52.021666358820774],
            [-1.284198239729187, 52.021877519703914],
            [-1.283997179802195, 52.022604555584131],
            [-1.284589989629395, 52.022808650882808],
            [-1.283598738080495, 52.023188336379143],
            [-1.284471597377744, 52.023743877309229],
            [-1.283846277267891, 52.025027565655073],
            [-1.286099035440136, 52.025605855939645],
            [-1.285333259503992, 52.026467031371553],
            [-1.290206339936972, 52.02743781704995],
            [-1.293455484059162, 52.027510384543156],
            [-1.294329186367694, 52.028025397935068],
            [-1.293475389326338, 52.029670097632462],
            [-1.29309243861856, 52.029922241555369],
            [-1.292345813340943, 52.029666018585466],
            [-1.292816330488092, 52.030238860080743],
            [-1.291691082335638, 52.030790432996682],
            [-1.292085336029191, 52.032777972544686],
            [-1.293319325880149, 52.034301227351172],
            [-1.294470886114796, 52.0370844858355],
            [-1.293978052824934, 52.037460047783775],
            [-1.294615403550903, 52.037813605619988],
            [-1.293817538403099, 52.038580239109088],
            [-1.294766724981695, 52.039037259778063],
            [-1.294969027625228, 52.039800889589586],
            [-1.296462906951287, 52.040854545430612],
            [-1.296437205706593, 52.041470261468547],
            [-1.297177387605, 52.041779464755173],
            [-1.297593939056474, 52.042737668779708],
            [-1.298229009892937, 52.042870918549141],
            [-1.298587330650601, 52.043359452908959],
            [-1.299253378036158, 52.043190790879848],
            [-1.300151844913668, 52.04363757608089],
            [-1.299812213273854, 52.04437819842687],
            [-1.300413565338443, 52.04489873897262],
            [-1.30014817892776, 52.045366482818309],
            [-1.302354462424684, 52.045918108253183],
            [-1.302230223068137, 52.046405572535448],
            [-1.306165934476225, 52.046864855139283],
            [-1.306702824855201, 52.047317552860889],
            [-1.30741155854844, 52.0472192238825],
            [-1.30869239779141, 52.048600532803029],
            [-1.311350332135383, 52.050523931482331],
            [-1.312880596489904, 52.05115502183672],
            [-1.31178260160025, 52.052762460649504],
            [-1.307395804272126, 52.057579198519711],
            [-1.309102038179999, 52.060714398254099],
            [-1.308475817700116, 52.062351540362407],
            [-1.307662217318593, 52.063179309739013],
            [-1.307784288769379, 52.064341632047416],
            [-1.303615276247998, 52.064339559282452],
            [-1.302191352243838, 52.065008144527454],
            [-1.301268539833168, 52.066093258038563],
            [-1.301200583835759, 52.067643760284213],
            [-1.300669434190897, 52.067652299151561],
            [-1.300082667868815, 52.069352564934661],
            [-1.305384436620218, 52.070282991686604],
            [-1.306918915435732, 52.070302810153592],
            [-1.305486368822578, 52.072082640193983],
            [-1.303503931614191, 52.071968452463722],
            [-1.303940483882665, 52.073543511303832],
            [-1.302006527555943, 52.073778425557052],
            [-1.30190583887923, 52.07518308241486],
            [-1.298919492414282, 52.075344280505718],
            [-1.298509897732978, 52.075690685304416],
            [-1.297524530699253, 52.078045787577487],
            [-1.299631963483444, 52.078301073131314],
            [-1.299376325030401, 52.080090509080705],
            [-1.308778657205654, 52.07949506841895],
            [-1.313532738713435, 52.079879771381968],
            [-1.316100817991965, 52.079508990843131],
            [-1.317261979312498, 52.079725203283921],
            [-1.31726127779557, 52.079962554401284],
            [-1.318175555596539, 52.079910303596129],
            [-1.319521096853111, 52.080765901090025],
            [-1.320000034395635, 52.080746187005801],
            [-1.319750498232682, 52.081130449858669],
            [-1.320557532495759, 52.0821843197673],
            [-1.31949025570085, 52.08385043974377],
            [-1.321361917008314, 52.08485919512902],
            [-1.321183097837826, 52.085583716777478],
            [-1.320627092249525, 52.085773815422208],
            [-1.321276715934773, 52.08653187700488],
            [-1.32083992342978, 52.087617238711786],
            [-1.316744713729536, 52.088948484110411],
            [-1.316841721025568, 52.089764503695427],
            [-1.312193023516929, 52.090714773819926],
            [-1.307978623653577, 52.092261696196829],
            [-1.304396846618924, 52.092062608480887],
            [-1.303924802118785, 52.091630966243748],
            [-1.299842353742492, 52.091437774534839],
            [-1.297780815205682, 52.091938887394171],
            [-1.296301276354634, 52.092833635521977],
            [-1.295608784196898, 52.092872656941537],
            [-1.295161608358801, 52.093368970015113],
            [-1.287140824968437, 52.092627576551479],
            [-1.287074324281111, 52.093045243086586],
            [-1.285662826954758, 52.093502421336439],
            [-1.285172251434427, 52.093236020750687],
            [-1.280692476844496, 52.094146515076346],
            [-1.276211951159306, 52.0968064292394],
            [-1.274886400622041, 52.097170502654187],
            [-1.274342555889607, 52.096769767839355],
            [-1.273707641723621, 52.09739341188336],
            [-1.271591165674099, 52.0979162060611],
            [-1.269680247873329, 52.098005983554216],
            [-1.265388563203192, 52.097459665818434],
            [-1.262489276948342, 52.09693449406695],
            [-1.262215904581352, 52.09651561449494],
            [-1.258160981807847, 52.096406564227671],
            [-1.256294619723574, 52.095750174263443],
            [-1.25480954557217, 52.095605039879757],
            [-1.247975280897868, 52.095761252634432],
            [-1.248034476863137, 52.096842316409294],
            [-1.248813142526246, 52.099067090366319],
            [-1.249928822871212, 52.099743101481728],
            [-1.250040661301433, 52.100473859817455],
            [-1.251855195241168, 52.101354787389312],
            [-1.251863270931749, 52.102006666158978],
            [-1.254019117483273, 52.102475252200044],
            [-1.252943296959555, 52.103862903714777],
            [-1.257051733868502, 52.106151818589908],
            [-1.259935418733506, 52.108710723020245],
            [-1.264837239113606, 52.110753497361735],
            [-1.270230895717469, 52.113557025937844],
            [-1.272677965713372, 52.114303083782296],
            [-1.276844977999761, 52.117064571518831],
            [-1.276550623639943, 52.117294726374418],
            [-1.278372279665581, 52.118623020256578],
            [-1.281572433190936, 52.122609242519189],
            [-1.282216321090754, 52.122624850024046],
            [-1.284159578305859, 52.124045497103154],
            [-1.288540594934336, 52.127963162328776],
            [-1.290647951031165, 52.129288489586379],
            [-1.293640428761185, 52.132573650778681],
            [-1.293428535664583, 52.133038997046924],
            [-1.295565459567787, 52.133810587147124],
            [-1.295113117468599, 52.13470065738381],
            [-1.297700369564226, 52.135272608352338],
            [-1.297937024803533, 52.137134182373089],
            [-1.29918268146901, 52.137176651721433],
            [-1.299356663896287, 52.138380633638505],
            [-1.302126083415388, 52.139280827618322],
            [-1.301975048511255, 52.139782511113268],
            [-1.305389703433564, 52.141201607112535],
            [-1.30492864094827, 52.141626846268856],
            [-1.30844761974683, 52.143495998997423],
            [-1.308693321539677, 52.144332668681344],
            [-1.306444560587764, 52.145540399699193],
            [-1.308631517879619, 52.146531414949472],
            [-1.308369445662758, 52.146845450161869],
            [-1.309425762530551, 52.147620335860275],
            [-1.311768438152588, 52.147906439358216],
            [-1.310792625759933, 52.149487589018477],
            [-1.312401459759405, 52.149790968510793],
            [-1.312368386961073, 52.151274228371122],
            [-1.313270461054298, 52.151065504973587],
            [-1.314545539645815, 52.151407369886932],
            [-1.314379438790175, 52.151754341852666],
            [-1.315977917949497, 52.152263497178446],
            [-1.31692844200611, 52.1530916447674],
            [-1.317762170865996, 52.15333921465362],
            [-1.318068468170098, 52.154242743759525],
            [-1.31772499574047, 52.154528457964858],
            [-1.318564486614773, 52.155453947505798],
            [-1.31828297748025, 52.155797561006658],
            [-1.31992152103954, 52.156951520225284],
            [-1.31939042336258, 52.157654220987901],
            [-1.321301487982564, 52.158292772102321],
            [-1.32088872093528, 52.159193054186154],
            [-1.324394591240109, 52.160904309954283],
            [-1.324679611831927, 52.161197236802671],
            [-1.323910289375294, 52.162090093626922],
            [-1.325322626971405, 52.162758985474504],
            [-1.328679498877461, 52.165463619455785],
            [-1.328386565284566, 52.165790109345856],
            [-1.330088218244693, 52.166287970086699],
            [-1.329952573922372, 52.167337300831775],
            [-1.331057968606989, 52.167918969190055],
            [-1.330883902737192, 52.168216469908117],
            [-1.331910882299326, 52.168471327252981],
            [-1.331604403618779, 52.169507105421175],
            [-1.332305615325468, 52.170526111452141],
            [-1.331130033806272, 52.171402329975436],
            [-1.332346181915166, 52.17231007222302],
            [-1.331495765751834, 52.174123153348575],
            [-1.328652043474487, 52.177006472746129],
            [-1.326850440257287, 52.178454482401577],
            [-1.326522460895178, 52.178383383972026],
            [-1.322826355228648, 52.181786744791069],
            [-1.319959119541167, 52.181346804057341],
            [-1.318204656851419, 52.181403212816321],
            [-1.318208160270056, 52.18299186697719],
            [-1.317460252094215, 52.184841399766],
            [-1.316174693013207, 52.186645558147482],
            [-1.314400014928501, 52.188098028403026],
            [-1.313148166394612, 52.190453470529121],
            [-1.308872682007471, 52.189765011252327],
            [-1.300608569846626, 52.191760789578858],
            [-1.294907690904293, 52.191666637220472],
            [-1.290801461607985, 52.192119437079981],
            [-1.283657853858109, 52.191587213969569],
            [-1.28120915139147, 52.191939143548502],
            [-1.278924715825057, 52.186111901805702],
            [-1.279439175317577, 52.184146105437058],
            [-1.281123988000715, 52.182250374475416],
            [-1.280790981732907, 52.180951902719116],
            [-1.279305523588887, 52.180559842390451],
            [-1.279110485385997, 52.180139689676743],
            [-1.278148543487836, 52.180205735079923],
            [-1.278420924271577, 52.178556727540276],
            [-1.278044628028312, 52.178041962358563],
            [-1.27860882505397, 52.177699274252468],
            [-1.277709558308362, 52.176878327049337],
            [-1.278194096366481, 52.176215987090721],
            [-1.277763413204462, 52.1759004794392],
            [-1.278049603095738, 52.175565981999647],
            [-1.277851520583946, 52.175245603818119],
            [-1.277308070562222, 52.175392420331079],
            [-1.27694083876887, 52.174681711641611],
            [-1.27609051519312, 52.174274619063887],
            [-1.276913348851623, 52.172859147888708],
            [-1.275715529902455, 52.172962387333293],
            [-1.274969326199479, 52.172719554427317],
            [-1.274777104846368, 52.171590951263141],
            [-1.273568563346618, 52.171187032638954],
            [-1.274038434348128, 52.170707128211021],
            [-1.273521721095942, 52.169483922698127],
            [-1.274460045542489, 52.169327864139831],
            [-1.275099619883489, 52.167670329280135],
            [-1.264307400362374, 52.165832391420857],
            [-1.261183898684732, 52.164365387121187],
            [-1.259124140552989, 52.164265264476754],
            [-1.255288971374383, 52.164636711832827],
            [-1.254570419590144, 52.163190977184804],
            [-1.255065524814712, 52.163045758255265],
            [-1.254376450128842, 52.162637726999378],
            [-1.254169390363301, 52.160943482333586],
            [-1.253555800732324, 52.159957825247773],
            [-1.254881156426565, 52.158852261722501],
            [-1.253035889919309, 52.156782638030691],
            [-1.253727739901762, 52.155794448767345],
            [-1.250631720958026, 52.153536156872327],
            [-1.247032707164919, 52.154775532292824],
            [-1.242909142303001, 52.154697899997366],
            [-1.241517722189647, 52.154175597996172],
            [-1.241478464028655, 52.153819315854435],
            [-1.239422452337191, 52.152672359691024],
            [-1.238633143744617, 52.150103134025173],
            [-1.237664374939467, 52.149661730925047],
            [-1.235698247438174, 52.149684074997943],
            [-1.235910483207915, 52.149494847592486],
            [-1.234338471790133, 52.148949712599624],
            [-1.233944886867084, 52.147782866969393],
            [-1.232490188560046, 52.14917505769796],
            [-1.231597549481523, 52.149070350508339],
            [-1.230920458697774, 52.149603580717667],
            [-1.230681066954155, 52.150434555437101],
            [-1.23143232283551, 52.151367287548531],
            [-1.232654922126451, 52.151835566637054],
            [-1.23321399278427, 52.153239046506457],
            [-1.232934492172907, 52.153087984936974],
            [-1.232097234218918, 52.153667830805013],
            [-1.231659129284387, 52.153475275968042],
            [-1.231489406165004, 52.153994729680477],
            [-1.230217853751929, 52.153650190518668],
            [-1.229586864226746, 52.154047954712091],
            [-1.228394170753186, 52.153961942606799],
            [-1.22755551481082, 52.154702679967464],
            [-1.227443586009213, 52.156018179258091],
            [-1.226089018037472, 52.156244857937644],
            [-1.225893386074502, 52.156568137338489],
            [-1.225554718160476, 52.156289002868149],
            [-1.224115163849901, 52.156274151504839],
            [-1.22497269345137, 52.157818987187213],
            [-1.224225477448121, 52.158758095639421],
            [-1.223049846672948, 52.159280809249189],
            [-1.223517033485973, 52.159315351719528],
            [-1.223354628124171, 52.159659523963846],
            [-1.223760170618992, 52.15962443169812],
            [-1.223191182787302, 52.160399277776612],
            [-1.218073294109674, 52.159640828611266],
            [-1.217050253597653, 52.15920968605009],
            [-1.217243468105714, 52.15877851734988],
            [-1.212616884509915, 52.157696727620639],
            [-1.211517004724422, 52.159717675927787],
            [-1.206337130056651, 52.158267819620804],
            [-1.204930292966951, 52.157479757180901],
            [-1.202591486047006, 52.157068385624243],
            [-1.200727348411187, 52.157089937731342],
            [-1.197998880017336, 52.155182484351464],
            [-1.195969302459172, 52.158144584001676],
            [-1.196007104653412, 52.158967487715039],
            [-1.19474160180606, 52.15999638127586],
            [-1.192831666566827, 52.160199107861331],
            [-1.191034766510642, 52.159326395484733],
            [-1.190013892250364, 52.159354455801072],
            [-1.189988571468898, 52.158977572891729],
            [-1.188466947398831, 52.159532630859829],
            [-1.186540912319088, 52.159415076976451],
            [-1.186663506769997, 52.156858974414178],
            [-1.185544450312261, 52.155461296728994],
            [-1.187122278417528, 52.153740572467612],
            [-1.186576286859965, 52.152500591008447],
            [-1.189503441940063, 52.149144732897547],
            [-1.187309878371503, 52.148092118688858],
            [-1.18843172016954, 52.145309130449121],
            [-1.188839901603207, 52.145123132969772],
            [-1.187952398944563, 52.144906645605118],
            [-1.188222315361963, 52.144609112995106],
            [-1.185876473540814, 52.144147909628444],
            [-1.185870655631354, 52.144467039158208],
            [-1.184779097402566, 52.144703149674193],
            [-1.185043327881709, 52.144958512729247],
            [-1.184673386752137, 52.145290410630636],
            [-1.183761967241179, 52.144864243209604],
            [-1.183306774952366, 52.145222519113496],
            [-1.182837580563253, 52.145307379149699],
            [-1.182609251979543, 52.145007306397076],
            [-1.181976100043452, 52.145267244619042],
            [-1.181537953769938, 52.144774212983698],
            [-1.181063728288765, 52.144974111982997],
            [-1.180541066687492, 52.144787072699089],
            [-1.180916163558187, 52.144573900660731],
            [-1.180272591869355, 52.144207105785974],
            [-1.179276276985343, 52.144348525745563],
            [-1.179249547326103, 52.143656055961536],
            [-1.178380758279999, 52.143776777749586],
            [-1.17771135190242, 52.143148159439924],
            [-1.177273410432133, 52.143440900358641],
            [-1.17690806475342, 52.143124576589074],
            [-1.176518731089269, 52.143316957966157],
            [-1.176447215767689, 52.143787571532997],
            [-1.172541476734142, 52.143213608325908],
            [-1.169322369547262, 52.143204472957287],
            [-1.148998962288553, 52.145628274269903],
            [-1.141578277187845, 52.145993482302714],
            [-1.140774979031278, 52.145816811146638],
            [-1.139856891057612, 52.146364848647487],
            [-1.138443241179071, 52.146161233437532],
            [-1.137423987730946, 52.146421709326603],
            [-1.137303772640286, 52.148861810156362],
            [-1.138079852941994, 52.148709245736327],
            [-1.138740424620128, 52.149240925546501],
            [-1.139723297295159, 52.14927866467491],
            [-1.140582950198215, 52.150478896276468],
            [-1.140455490319961, 52.151253866120626],
            [-1.144420538083642, 52.152943268950338],
            [-1.145555781732434, 52.154035774508735],
            [-1.144866865275788, 52.154518079668954],
            [-1.145310972552031, 52.155141655957742],
            [-1.144483343900415, 52.155691283305224],
            [-1.143550294568056, 52.157924996824782],
            [-1.145013078379271, 52.159781395259806],
            [-1.147461802766725, 52.161076690558637],
            [-1.150723735638096, 52.162129647105864],
            [-1.152003787380076, 52.162007589134952],
            [-1.15431622693457, 52.162565424159347],
            [-1.156621505137203, 52.16358258634056],
            [-1.156491589534353, 52.165814942856066],
            [-1.157533286416258, 52.169494176560853],
            [-1.153025408603129, 52.169724435660513],
            [-1.152340584318699, 52.168982282983578],
            [-1.151240436406928, 52.169321415386712],
            [-1.147925167526191, 52.167358233886638],
            [-1.144215188913206, 52.166017847594745],
            [-1.138542071705464, 52.170804566190661],
            [-1.137247339137288, 52.173258554010417],
            [-1.134816656562553, 52.172748069170147],
            [-1.133573319900204, 52.172090718831925],
            [-1.128600504698381, 52.167880625074964],
            [-1.127599065526728, 52.169818811247339],
            [-1.126438867434173, 52.170824378098835],
            [-1.125180224595591, 52.171478566908412],
            [-1.123840201491938, 52.172849595048412],
            [-1.120908783457601, 52.174012766781836],
            [-1.125187208944742, 52.176789416250749],
            [-1.128022487486975, 52.180996458610124],
            [-1.128212241552893, 52.18206325510679],
            [-1.127084377218424, 52.183493425613058],
            [-1.126908227132244, 52.185692139117833],
            [-1.124465357583203, 52.18420946215349],
            [-1.12468848578482, 52.183944993385154],
            [-1.120226993036977, 52.181666848143216],
            [-1.11275872199731, 52.178816634189324],
            [-1.108292634695566, 52.178799156233794],
            [-1.102600744154028, 52.179665868905722],
            [-1.097742991946559, 52.179413052964257],
            [-1.095599077412547, 52.178902154184499],
            [-1.095478407594432, 52.180020570858773],
            [-1.093138818325358, 52.180141971545886],
            [-1.093152381135511, 52.1804099984776],
            [-1.091332205739435, 52.180806872565022],
            [-1.09136997731354, 52.181537207800041],
            [-1.0896651440272, 52.181869312198998],
            [-1.089232510047123, 52.182427892610683],
            [-1.08985854838198, 52.186152171163059],
            [-1.08945380312073, 52.186345945245193],
            [-1.090460515320161, 52.189914019130512],
            [-1.088072895130916, 52.191284655502066],
            [-1.086236737338065, 52.191723584467127],
            [-1.085422712595873, 52.192681979592216],
            [-1.084688818682002, 52.192657410004557],
            [-1.084575436337378, 52.193049423436193],
            [-1.083165884566123, 52.193511392305602],
            [-1.079787519435423, 52.193812361743412],
            [-1.078748743682746, 52.193319660390294],
            [-1.072401431378962, 52.194561008842207],
            [-1.06969542146976, 52.194744698568172],
            [-1.067465342849809, 52.195465235406836],
            [-1.066206214491522, 52.195439097172084],
            [-1.064725995683378, 52.195909277960467],
            [-1.060581754276498, 52.19636725750761],
            [-1.060013470730327, 52.196673810977728],
            [-1.057768500599015, 52.196287292263548],
            [-1.057263056979949, 52.195835519639779],
            [-1.054689224941112, 52.195574881816569],
            [-1.052496071816159, 52.194544943360228],
            [-1.051281570977322, 52.194485738396928],
            [-1.050617337642213, 52.194074916696906],
            [-1.04930770538676, 52.193880964638716],
            [-1.047156399762143, 52.194256510099663],
            [-1.046679037637565, 52.194002713754308],
            [-1.046679620755677, 52.193428212466515],
            [-1.044412814975338, 52.193186012519313],
            [-1.04334628965442, 52.193868757922978],
            [-1.042127855215637, 52.194062065602814],
            [-1.038437372477502, 52.195659374783745],
            [-1.042117541610015, 52.199103068998284],
            [-1.042312797898801, 52.200910884596688],
            [-1.043958540877311, 52.203247421416954],
            [-1.055151389396564, 52.215525307515207],
            [-1.057155302485946, 52.216557274548151],
            [-1.057357953482569, 52.21804774655125],
            [-1.066070464323987, 52.227256887208718],
            [-1.06511568088546, 52.227893959590062],
            [-1.065203923639744, 52.228223715770277],
            [-1.064022062728498, 52.22879604667461],
            [-1.062846611426293, 52.228158276315611],
            [-1.062162560849075, 52.228311978912004],
            [-1.06155129583932, 52.227582475875465],
            [-1.060536203647073, 52.227604971006286],
            [-1.060264173520952, 52.227105623375984],
            [-1.058548182424786, 52.227386845376962],
            [-1.058360313859797, 52.228585598306253],
            [-1.056963077610704, 52.229989573542682],
            [-1.057407081797012, 52.230110896586837],
            [-1.057117866509876, 52.230838627415196],
            [-1.055909081964403, 52.231084302046213],
            [-1.055632828572709, 52.231406654912675],
            [-1.055251714878569, 52.231219297746371],
            [-1.0549770506022, 52.231811381070372],
            [-1.054511941939714, 52.231580194107472],
            [-1.053651135248116, 52.231985968058687],
            [-1.053157752170655, 52.231501012970966],
            [-1.052212042663568, 52.231905196489052],
            [-1.050691378242652, 52.231461428513704],
            [-1.050343214275279, 52.230965941202079],
            [-1.049701574158568, 52.23115227922171],
            [-1.048407986048997, 52.23067075025638],
            [-1.04714613418675, 52.231957024548578],
            [-1.046280860510224, 52.232021064317237],
            [-1.045915268291489, 52.231930901285757],
            [-1.046148966400182, 52.231547989821294],
            [-1.04511266497434, 52.231598951315874],
            [-1.04347600301332, 52.230560768911801],
            [-1.043215907917562, 52.231081018246414],
            [-1.043918831153889, 52.231621656201447],
            [-1.043265951305097, 52.232295161499287],
            [-1.042111646190801, 52.232521357149807],
            [-1.043211250824015, 52.236270381865815],
            [-1.04295198330163, 52.236818507941393],
            [-1.042357146023973, 52.236695907472644],
            [-1.042772902428371, 52.23697439247767],
            [-1.042447006456436, 52.237283725219264],
            [-1.041624954563643, 52.237307623778229],
            [-1.041920836672553, 52.23784856449133],
            [-1.041335857982363, 52.237675691331489],
            [-1.041320075028709, 52.238068454231829],
            [-1.04068009644922, 52.237865462131168],
            [-1.04014439327472, 52.238191964055481],
            [-1.039780693198668, 52.237944461373431],
            [-1.038498745762222, 52.2383772677519],
            [-1.03547914859321, 52.237899511178078],
            [-1.029886249840011, 52.239320065549869],
            [-1.025058226520596, 52.239904249971048],
            [-1.019624973355786, 52.241098174146437],
            [-1.017542246991196, 52.240972076000993],
            [-1.015207652193997, 52.241572982689213],
            [-1.013572110747928, 52.241622264888562],
            [-1.014116754843751, 52.24281987342507],
            [-1.013807848855287, 52.244862666944947],
            [-1.013321190587811, 52.244880178932803],
            [-1.012471055411003, 52.246690082243148],
            [-1.011093519023053, 52.246852972196628],
            [-1.008832548758472, 52.24833904990458],
            [-1.003737164303652, 52.247789097071838],
            [-1.001605686894993, 52.248452585840624],
            [-1.000939089017084, 52.247479553454184],
            [-0.997419584933247, 52.24766100347852],
            [-0.997606383341917, 52.251266942486502],
            [-0.992390800872205, 52.254785548782344],
            [-0.991824035449912, 52.255940505113706],
            [-0.991226892813574, 52.256172758675199],
            [-0.989544204321396, 52.257369412767602],
            [-0.987248018134965, 52.258030334984682],
            [-0.983109516980713, 52.257755619293441],
            [-0.979344406669619, 52.258002746878226],
            [-0.976147765239788, 52.257484185281434],
            [-0.975510754471335, 52.257598236361218],
            [-0.975082226115272, 52.258265220896227],
            [-0.973471462218304, 52.258545224488117],
            [-0.972660402014226, 52.258391623692887],
            [-0.971490101441651, 52.257617230747883],
            [-0.969357094491383, 52.258305299906127],
            [-0.967834604695306, 52.258315378190289],
            [-0.961770159141434, 52.259458873870983],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000156",
        LAD13CDO: "34UH",
        LAD13NM: "Wellingborough",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.700080517518013, 52.364214574402453],
            [-0.698947331342872, 52.362593665398265],
            [-0.696514947370198, 52.363954133355008],
            [-0.696213788056197, 52.363757511027565],
            [-0.695859139969638, 52.362534455826683],
            [-0.696764656782186, 52.36160671122834],
            [-0.695773096117045, 52.35957106099525],
            [-0.694558844635979, 52.358816818705399],
            [-0.693681140318775, 52.359050769531827],
            [-0.693036462147484, 52.358852139450455],
            [-0.692998176328018, 52.358109081286365],
            [-0.692341204799629, 52.357581250364255],
            [-0.691879371689914, 52.357805403300873],
            [-0.691565373371412, 52.357548388594175],
            [-0.691198031408405, 52.356810676809495],
            [-0.692410300003597, 52.356085955936607],
            [-0.69046508105206, 52.356221755752806],
            [-0.691191592517116, 52.355388270301482],
            [-0.690636613272949, 52.354541488403115],
            [-0.69110596220707, 52.353912840100193],
            [-0.688763169616161, 52.353891364899447],
            [-0.688280155585261, 52.353393311823524],
            [-0.686214643666032, 52.352637621775557],
            [-0.686437074081504, 52.351969384309179],
            [-0.6859423326317, 52.351323742615577],
            [-0.686449624115084, 52.350505829343184],
            [-0.684377952839801, 52.349616974426702],
            [-0.683475722962867, 52.349791237163828],
            [-0.682755734083812, 52.349563839503638],
            [-0.68236827366502, 52.350003663475292],
            [-0.680610711739527, 52.350403023360968],
            [-0.68035143040478, 52.350136699057948],
            [-0.677773776426184, 52.350464819934615],
            [-0.677151444855623, 52.348589574671479],
            [-0.674904363173805, 52.348469996221091],
            [-0.674814355917621, 52.348976066155011],
            [-0.66804508217617, 52.349598545627508],
            [-0.667463917570315, 52.349004896404537],
            [-0.664578750352925, 52.34920788614707],
            [-0.664431813688951, 52.34887086967197],
            [-0.663338302073503, 52.34915430256688],
            [-0.663316723458988, 52.349869724255839],
            [-0.661117137320369, 52.350072290729138],
            [-0.660651535379311, 52.349009699147686],
            [-0.659796790616299, 52.349358740707565],
            [-0.659082204365298, 52.349199589355969],
            [-0.658087946708174, 52.347852273077443],
            [-0.653596635494393, 52.348105074878646],
            [-0.653276471366821, 52.349606484248973],
            [-0.650381289983894, 52.35042398339219],
            [-0.650454451476007, 52.350962467929719],
            [-0.648715390289198, 52.351755362400965],
            [-0.648339946528935, 52.352800293502469],
            [-0.64752869492534, 52.352825176945728],
            [-0.645642740809437, 52.354000251906619],
            [-0.645387362491956, 52.35385257326147],
            [-0.641193450627128, 52.355589097847911],
            [-0.636370745971985, 52.357944878990608],
            [-0.634213157852657, 52.360435587056735],
            [-0.632376554030719, 52.361559774738105],
            [-0.627154197059475, 52.359146336341382],
            [-0.629447443698163, 52.35707540051515],
            [-0.626701989469858, 52.355116800728588],
            [-0.626711683700647, 52.354804033454982],
            [-0.622728443866722, 52.353373993809491],
            [-0.625633338302384, 52.345875311193979],
            [-0.62621729501751, 52.346075404046964],
            [-0.627087548376375, 52.344237006248463],
            [-0.624157327166283, 52.342584577397702],
            [-0.622811995771465, 52.341992593609199],
            [-0.62966468007447, 52.33917811695823],
            [-0.632217087690189, 52.336756792049542],
            [-0.633493020270807, 52.336980142182298],
            [-0.634885157775521, 52.333000712034661],
            [-0.635130167463122, 52.328950476756539],
            [-0.640095108951493, 52.318359881349302],
            [-0.641266831217408, 52.317179379416444],
            [-0.640968841353185, 52.315795857136884],
            [-0.639424458826073, 52.313724570761067],
            [-0.637016626715802, 52.305191462522188],
            [-0.636950917924522, 52.304322186730005],
            [-0.638202590148655, 52.301721180251313],
            [-0.63812215456658, 52.299275635234054],
            [-0.636246696824626, 52.296260934037058],
            [-0.634274334518989, 52.295070229220137],
            [-0.627554266506345, 52.292165598957737],
            [-0.622373380883233, 52.290717088271037],
            [-0.621269236617141, 52.290105410239192],
            [-0.620208022409352, 52.288726400040524],
            [-0.615926291519841, 52.285685024339017],
            [-0.61526955990974, 52.284883428388198],
            [-0.614743583032751, 52.282898359458329],
            [-0.610632761318145, 52.279483867507601],
            [-0.610595770259623, 52.278885535649593],
            [-0.611090980878243, 52.278518237360743],
            [-0.620072268820988, 52.276908956180016],
            [-0.626993848958553, 52.274863280782299],
            [-0.62693747069992, 52.273367435439255],
            [-0.63142832374785, 52.270033586675211],
            [-0.635490112340772, 52.271918334437771],
            [-0.637678561101865, 52.272386853602505],
            [-0.639924103862832, 52.272428022259533],
            [-0.646915456098662, 52.271165995211291],
            [-0.651095318711522, 52.269040714066996],
            [-0.653671426220578, 52.268275317264276],
            [-0.652776292271723, 52.266573918995498],
            [-0.650049547654871, 52.256764258115254],
            [-0.649805407004751, 52.254092960080392],
            [-0.648983395982941, 52.25269895407321],
            [-0.645511585755893, 52.242447266386804],
            [-0.643101629328886, 52.238530105824928],
            [-0.643464646611084, 52.235845981634817],
            [-0.642765225466879, 52.233426566519583],
            [-0.639672098744989, 52.228937735987088],
            [-0.638638409311466, 52.22793141738903],
            [-0.6373486500801, 52.227304250103465],
            [-0.643636132089141, 52.223348708546453],
            [-0.648651155975438, 52.220862683347818],
            [-0.652382265532412, 52.217249610657504],
            [-0.653592538223563, 52.215226067183444],
            [-0.658515224698133, 52.210070948787084],
            [-0.661477782837398, 52.208113082605728],
            [-0.66868154465604, 52.197718269251368],
            [-0.668153476023584, 52.195021306322921],
            [-0.67344247847728, 52.196053705285941],
            [-0.675273316961594, 52.195791055769696],
            [-0.680040332492999, 52.196128587580588],
            [-0.681253887311917, 52.19571148718498],
            [-0.684671175539636, 52.196323250345969],
            [-0.686647130614417, 52.195801303579138],
            [-0.694097193791187, 52.195252815762785],
            [-0.696356507788162, 52.194291486545126],
            [-0.701689057114379, 52.193295634669298],
            [-0.705473136739614, 52.191557894133595],
            [-0.707450761960716, 52.192980364519357],
            [-0.711726830095045, 52.197342782628816],
            [-0.716965480288151, 52.213276949957447],
            [-0.717416325553969, 52.215766042158798],
            [-0.716954592122819, 52.21792244115624],
            [-0.714881071220636, 52.220890263040687],
            [-0.714349201796913, 52.221109244084772],
            [-0.714644437560735, 52.221277892247379],
            [-0.714320340056246, 52.221449685451709],
            [-0.715765372781114, 52.221495985147868],
            [-0.718012831023573, 52.223467847129008],
            [-0.717992089593245, 52.224899873650116],
            [-0.720445520396284, 52.226897300156324],
            [-0.720667010879403, 52.228309474192791],
            [-0.723542457944501, 52.23040850835325],
            [-0.724860848269177, 52.232841306762133],
            [-0.724611159252606, 52.233889644460724],
            [-0.726032006567233, 52.235357913517319],
            [-0.727492241894112, 52.238114982564312],
            [-0.736808893642737, 52.242719462398171],
            [-0.741889300212905, 52.24397668742035],
            [-0.744121231168065, 52.243569788183059],
            [-0.748667923700515, 52.245951191515339],
            [-0.753724952145904, 52.247858592717535],
            [-0.759393641228368, 52.247493925073329],
            [-0.761008799560636, 52.248353326819384],
            [-0.760906741029666, 52.248651650651013],
            [-0.762305567872589, 52.248759827190213],
            [-0.764491648326166, 52.248159658310527],
            [-0.765915823093343, 52.247406735517743],
            [-0.76668815034501, 52.24617766680192],
            [-0.76797818714023, 52.246826787214452],
            [-0.769033290299289, 52.245856892497578],
            [-0.769910210605742, 52.245926270602276],
            [-0.772726665334623, 52.245350497532527],
            [-0.774379203955544, 52.245284948076012],
            [-0.775349766049391, 52.24599630388731],
            [-0.777061535110602, 52.24606799171093],
            [-0.777812567917496, 52.246508227197225],
            [-0.779167695121505, 52.246499770030738],
            [-0.78030186689509, 52.245521602294595],
            [-0.781379190834036, 52.245387075385374],
            [-0.781403429922336, 52.244555674303307],
            [-0.782858108671134, 52.244118441072509],
            [-0.783454655775735, 52.244210002210636],
            [-0.784216221160463, 52.243515663559364],
            [-0.791432397560383, 52.242227642889048],
            [-0.795099884051088, 52.254066463417487],
            [-0.801417403932795, 52.252636490312206],
            [-0.801241469314229, 52.253511306904812],
            [-0.801438621970469, 52.257284945811435],
            [-0.800892717547416, 52.257861107534303],
            [-0.801384531355434, 52.258150209766782],
            [-0.801087292578287, 52.258415117705759],
            [-0.80170651282054, 52.258543677875778],
            [-0.801436579465216, 52.258991376756789],
            [-0.801749358307186, 52.259128513838569],
            [-0.801539656016004, 52.260439939539602],
            [-0.801144432577568, 52.260588771027564],
            [-0.801706114625175, 52.261059295742704],
            [-0.80139516563484, 52.261342945920639],
            [-0.80181303241875, 52.262257053902587],
            [-0.802589718066899, 52.26296081861112],
            [-0.803069119957875, 52.263441291058776],
            [-0.802856086086199, 52.264115238157153],
            [-0.803481967206577, 52.265850507890583],
            [-0.802974402297571, 52.266686900609365],
            [-0.803559808716267, 52.267912881061854],
            [-0.805706070019701, 52.270826936420484],
            [-0.807318619903013, 52.272175664268843],
            [-0.809157409944698, 52.275313114590404],
            [-0.807649208427119, 52.278426680442095],
            [-0.807088425750928, 52.281515634401586],
            [-0.807751429331208, 52.282920390362257],
            [-0.807610898357414, 52.284496847621632],
            [-0.807146516568159, 52.286285811947316],
            [-0.805955286010669, 52.287296921883062],
            [-0.805913792370353, 52.288240530259834],
            [-0.808396632048491, 52.29285987032862],
            [-0.811001624947684, 52.2953459841982],
            [-0.812550149757131, 52.298634184185367],
            [-0.812299688969288, 52.298896892435074],
            [-0.813657523900739, 52.300273529772063],
            [-0.814136694822899, 52.302264389975072],
            [-0.814882938244627, 52.302768166194952],
            [-0.814921811321041, 52.304443525609805],
            [-0.815944904652172, 52.305841949886378],
            [-0.815724897637628, 52.306776576150668],
            [-0.81717286987558, 52.307976057543897],
            [-0.816762766185729, 52.308559043597647],
            [-0.817966172801002, 52.30958164525385],
            [-0.814249057201006, 52.311509762424961],
            [-0.809383593271987, 52.315164997342272],
            [-0.805548524423577, 52.316919931218287],
            [-0.794977964346487, 52.32303505565757],
            [-0.794459840689167, 52.322987513929547],
            [-0.792863806711643, 52.323783078519021],
            [-0.792589233591237, 52.326161908846991],
            [-0.790603793443414, 52.327563931469804],
            [-0.789309537168009, 52.329176194758766],
            [-0.785795484599269, 52.33060292259492],
            [-0.786866906461898, 52.331349366393674],
            [-0.787555504465536, 52.332840797306588],
            [-0.786104086414947, 52.338629384484491],
            [-0.785971076369549, 52.341825104279607],
            [-0.786677215571972, 52.344396497525089],
            [-0.787690592900119, 52.345345523967367],
            [-0.788670852342792, 52.347293065291552],
            [-0.786450356527154, 52.348232285923473],
            [-0.782623263646969, 52.351001609874885],
            [-0.780871644983359, 52.350683265294741],
            [-0.779224831021721, 52.352797054506397],
            [-0.779644243988961, 52.353561996554703],
            [-0.778834698522404, 52.353740640415921],
            [-0.778151605980178, 52.35226180772586],
            [-0.776684454182963, 52.350945679999683],
            [-0.777095569055338, 52.350785405483762],
            [-0.776239948991911, 52.349922434600778],
            [-0.771693903455484, 52.350791422828955],
            [-0.77034147216157, 52.349529452320255],
            [-0.765752583154755, 52.350299768041268],
            [-0.764154047539335, 52.350288456887192],
            [-0.760804951180174, 52.34887691239328],
            [-0.756809250220869, 52.348071636759244],
            [-0.754422167282395, 52.347860393797042],
            [-0.753736195611428, 52.348186716573124],
            [-0.752127088470518, 52.34829290899399],
            [-0.750786445353604, 52.348041390626534],
            [-0.748791016991583, 52.347062770904813],
            [-0.748308847046591, 52.34734536735359],
            [-0.747860747277182, 52.34720126444136],
            [-0.746888146186528, 52.347902119863292],
            [-0.745556770302427, 52.347895187384957],
            [-0.745252266133731, 52.349109295988526],
            [-0.745930133973501, 52.349539054270146],
            [-0.745665009875803, 52.350036123574725],
            [-0.744820260937568, 52.350729328290299],
            [-0.743481526724754, 52.351289607917593],
            [-0.742917970303264, 52.351074131355709],
            [-0.741361457916707, 52.352270410232649],
            [-0.740844510249288, 52.352171400263217],
            [-0.739999013041444, 52.352680253392862],
            [-0.73846799781922, 52.354781230800356],
            [-0.738033945969411, 52.354657919536237],
            [-0.738032769867239, 52.355008544314543],
            [-0.737454966079692, 52.354880099580434],
            [-0.737537856363017, 52.355320630337701],
            [-0.737158780307709, 52.355174527304911],
            [-0.735996323904257, 52.357165228439243],
            [-0.735585222264947, 52.357214775182861],
            [-0.735802076259802, 52.357488614111027],
            [-0.73424371888726, 52.357655344945506],
            [-0.734343050352921, 52.357930625070118],
            [-0.732583535941865, 52.358198572798194],
            [-0.730092978100044, 52.359174301589135],
            [-0.728326779863596, 52.360286341304793],
            [-0.728372710881307, 52.360630280283836],
            [-0.727053791315497, 52.361198679859577],
            [-0.72550260441509, 52.361212531285091],
            [-0.723688193893025, 52.361697330236041],
            [-0.722667065172164, 52.3623758858934],
            [-0.721006839214331, 52.362604276915917],
            [-0.72004855644339, 52.363138739826489],
            [-0.719081706043348, 52.362952946672571],
            [-0.718832568966642, 52.363493284976023],
            [-0.716009051397871, 52.363318793860309],
            [-0.715992643913063, 52.363631492246732],
            [-0.715114157398, 52.363639926772123],
            [-0.71500823654635, 52.363291733887245],
            [-0.714009825206176, 52.363384268022507],
            [-0.713241107966529, 52.362900292876681],
            [-0.712568390337351, 52.363206742862239],
            [-0.711848267388918, 52.362971431537424],
            [-0.711652768024719, 52.363280379297095],
            [-0.711178490579885, 52.363125962662394],
            [-0.711094804990298, 52.363478385080818],
            [-0.707319464352486, 52.363008296787861],
            [-0.706055756364606, 52.363082577634046],
            [-0.704569129120174, 52.363748688357347],
            [-0.70294999029003, 52.363622143140127],
            [-0.700503749768788, 52.364355887454749],
            [-0.700080517518013, 52.364214574402453],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000163",
        LAD13CDO: "36UB",
        LAD13NM: "Craven",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.307325015358187, 54.25006389931945],
            [-2.305103445325104, 54.249331688274161],
            [-2.296967579332602, 54.250988611228706],
            [-2.294270465228047, 54.253285196969607],
            [-2.292756917033741, 54.252610343324889],
            [-2.291330070953281, 54.251600034365666],
            [-2.28970079822269, 54.245132199418315],
            [-2.286891724428945, 54.243243497772141],
            [-2.287404199305924, 54.241472672214464],
            [-2.286403047498103, 54.240484654077612],
            [-2.2860014582529, 54.239436787557423],
            [-2.283316407998047, 54.23854889512517],
            [-2.282750607728103, 54.239498387748689],
            [-2.281293726718479, 54.239833431711219],
            [-2.280457661948349, 54.239404888782808],
            [-2.277877043110461, 54.241023196634103],
            [-2.275667911063108, 54.24108399500949],
            [-2.27509097916407, 54.241545465052297],
            [-2.275017573742859, 54.242504579701929],
            [-2.274020407992627, 54.243002053922744],
            [-2.270130246864532, 54.243899688003516],
            [-2.267788298373027, 54.244801852224832],
            [-2.26508479274235, 54.244857263513452],
            [-2.263662210928773, 54.245328625368742],
            [-2.258794841716541, 54.247328973576295],
            [-2.258114860364906, 54.247552419355202],
            [-2.257542977798741, 54.247392770876345],
            [-2.256393325631465, 54.248100727498354],
            [-2.252132960947114, 54.249334691865712],
            [-2.246660520866747, 54.24939006238661],
            [-2.242584371014842, 54.248090689679884],
            [-2.240588279933853, 54.248440705221093],
            [-2.239294594411754, 54.249243152668321],
            [-2.237561928806821, 54.249779530055569],
            [-2.23565333614465, 54.251230235022639],
            [-2.231404366413603, 54.250283125002497],
            [-2.230379544551089, 54.250627505974855],
            [-2.229213999404309, 54.252166557677818],
            [-2.224130875135993, 54.251067992353676],
            [-2.222694202343452, 54.251044594659149],
            [-2.220887717644953, 54.251033542263357],
            [-2.215949204476845, 54.250225555085812],
            [-2.214640704476534, 54.249756960416775],
            [-2.214228724485725, 54.248443751409731],
            [-2.212213464007105, 54.247801133408323],
            [-2.207058413777009, 54.247463199177524],
            [-2.1977948780738, 54.247366432767272],
            [-2.194654303691594, 54.245912913264029],
            [-2.194927662229801, 54.245305828365261],
            [-2.192935591951618, 54.243617627619891],
            [-2.191100865398315, 54.243068733812976],
            [-2.189650606671489, 54.241024618537779],
            [-2.187681741608276, 54.239622988583051],
            [-2.187153309138025, 54.238809561382702],
            [-2.185018233232567, 54.238279015991331],
            [-2.179824271187842, 54.235022696963483],
            [-2.174745083453107, 54.230418787621254],
            [-2.171941996768054, 54.228465387068908],
            [-2.167753973180594, 54.228747208637373],
            [-2.166637001595648, 54.228302090758845],
            [-2.165997479156988, 54.229096554874594],
            [-2.160749348082988, 54.231340622854319],
            [-2.160784763923808, 54.232162914714962],
            [-2.159150577495919, 54.23365068968706],
            [-2.156679832763464, 54.234651523345768],
            [-2.15159902603118, 54.235578331852125],
            [-2.152531969767914, 54.234373752396685],
            [-2.150690492936721, 54.232556142453831],
            [-2.147879676940565, 54.234164763287517],
            [-2.143832531486305, 54.235023463538766],
            [-2.143074582461957, 54.233233196851117],
            [-2.143619610909818, 54.231589667191713],
            [-2.143531368613667, 54.230034967428153],
            [-2.142927524621089, 54.22930681512711],
            [-2.142168617397366, 54.226305051758963],
            [-2.140468700228642, 54.225282494209679],
            [-2.128927837852126, 54.225628847018598],
            [-2.126261358380608, 54.226406382512927],
            [-2.124689125706063, 54.227919689258329],
            [-2.121317975665196, 54.228572919790786],
            [-2.118187086347297, 54.229599692203905],
            [-2.116050085490286, 54.230628124230513],
            [-2.113452152302707, 54.232550292221774],
            [-2.108763650289516, 54.231836538468556],
            [-2.100027488024983, 54.231953769142152],
            [-2.099209865369549, 54.230052732389034],
            [-2.096352566519376, 54.228306126069619],
            [-2.09503873461346, 54.227962059308652],
            [-2.090521057426188, 54.223627369968028],
            [-2.088445489603366, 54.2220085019134],
            [-2.086626451472956, 54.221239613704341],
            [-2.087093641131295, 54.220966061401271],
            [-2.086376374555103, 54.221196654598444],
            [-2.083934447684163, 54.220276286111137],
            [-2.082500412871167, 54.218695510496104],
            [-2.080537458756512, 54.218702234952922],
            [-2.079176538489523, 54.218366115028836],
            [-2.076178431744587, 54.215872273372632],
            [-2.071120901530491, 54.213107277546641],
            [-2.069965643029924, 54.212731387101996],
            [-2.068057962331411, 54.212612053490787],
            [-2.060867789975856, 54.210312461650382],
            [-2.060428548626836, 54.208768659157762],
            [-2.061157487576664, 54.207152366171947],
            [-2.061336220513079, 54.202596590178544],
            [-2.060386715183803, 54.201142918841143],
            [-2.059319211236327, 54.196468230323973],
            [-2.054257539275661, 54.194887054693538],
            [-2.051791642158881, 54.191383074405522],
            [-2.050332493523291, 54.190001439296047],
            [-2.050368576265202, 54.189430726370844],
            [-2.051100682353236, 54.189090693989527],
            [-2.049389528047181, 54.185768780768534],
            [-2.045781108956855, 54.181867895711122],
            [-2.044350763534555, 54.182549677137636],
            [-2.034173458694322, 54.180424805191215],
            [-2.034727237848393, 54.17577637098421],
            [-2.032491724352173, 54.175326731522802],
            [-2.031388300911656, 54.174789580727996],
            [-2.02834020340876, 54.175469789591048],
            [-2.026954216068771, 54.17614596219439],
            [-2.023882461683564, 54.175960578194328],
            [-2.02283043324299, 54.176761560532306],
            [-2.020487587873414, 54.175976489404711],
            [-2.020017406853947, 54.176260570876437],
            [-2.018551244218521, 54.176170036200297],
            [-2.017199431928005, 54.174761017496671],
            [-2.015097168732794, 54.173576766074561],
            [-2.014710843783206, 54.172783228160611],
            [-2.011118542425334, 54.172532872944018],
            [-2.004504011374094, 54.172872140943305],
            [-1.999111904192624, 54.16737554300861],
            [-1.997747292241213, 54.165182602465585],
            [-1.997990945351643, 54.162081951701204],
            [-1.997569864222156, 54.160663731895553],
            [-1.992540081640554, 54.155267493552785],
            [-1.988413430088791, 54.15529683468138],
            [-1.98688333105481, 54.156610641308333],
            [-1.972488417038621, 54.158072294249422],
            [-1.966607432497622, 54.153188855076849],
            [-1.963582822580681, 54.152117583064985],
            [-1.954225414020064, 54.143093727117915],
            [-1.955288451530977, 54.136740003480966],
            [-1.957377294144458, 54.135575983454977],
            [-1.957140110232507, 54.132550724207576],
            [-1.949602731342273, 54.126408489596322],
            [-1.94726028270311, 54.125020725794812],
            [-1.937722538903908, 54.123364294118822],
            [-1.934939545548064, 54.122280737164516],
            [-1.93174018202134, 54.122354467215324],
            [-1.92269389511857, 54.120351102700575],
            [-1.916956149571452, 54.120596246633845],
            [-1.905611978970937, 54.12246000116388],
            [-1.903087916716119, 54.119632339767577],
            [-1.90055754440617, 54.11638759973647],
            [-1.89510581498785, 54.113782905714423],
            [-1.894258308692968, 54.110792029294473],
            [-1.893227166658689, 54.109677571283449],
            [-1.890997127745143, 54.104403512893512],
            [-1.887341147715966, 54.102450765334012],
            [-1.879765798160108, 54.102212473440446],
            [-1.87596279060038, 54.100063304889218],
            [-1.871320340609115, 54.099513797739696],
            [-1.867108756266102, 54.097675778051041],
            [-1.861720551895977, 54.080526016001151],
            [-1.846266711067275, 54.073752258313391],
            [-1.842392695209807, 54.071319716395095],
            [-1.840583228770898, 54.070561482926351],
            [-1.842213241950626, 54.067861069865579],
            [-1.846063989517179, 54.064503803836324],
            [-1.849080471866581, 54.063115445702572],
            [-1.850004586813505, 54.06190327808941],
            [-1.852780125118268, 54.057703214477257],
            [-1.853720457723176, 54.053108113131728],
            [-1.859677885939372, 54.052105892439364],
            [-1.8639837521955, 54.050217145886144],
            [-1.866649259555143, 54.049655704605904],
            [-1.867319087033097, 54.048906879420379],
            [-1.87202254315877, 54.04712972758081],
            [-1.873521053014056, 54.046028532512715],
            [-1.872435263358888, 54.046011210502627],
            [-1.870358433797005, 54.044482002887683],
            [-1.86866641551199, 54.042035545597635],
            [-1.872416700437558, 54.035595465830511],
            [-1.853465344365958, 54.028759501381195],
            [-1.84804143875927, 54.013656261965586],
            [-1.83736208987303, 54.014899683967556],
            [-1.835934850242449, 54.014216484831714],
            [-1.834909071841915, 54.013514945659431],
            [-1.833556455013629, 54.011359644268957],
            [-1.831403994309006, 54.009311960063627],
            [-1.830639374079397, 54.004459352326087],
            [-1.830971502655472, 54.003444213851139],
            [-1.829767911311957, 54.003034478455064],
            [-1.827478829618433, 54.001340641314791],
            [-1.826156613688651, 53.999764999650893],
            [-1.823847633090874, 53.998164535210918],
            [-1.822884554281062, 53.99792674650967],
            [-1.822764688332238, 53.997415171829644],
            [-1.821071768478506, 53.997344361221515],
            [-1.819522957262173, 53.996401939010049],
            [-1.818760353722821, 53.992511827284275],
            [-1.8201218786255, 53.991215150316961],
            [-1.821617757562715, 53.990937859605452],
            [-1.821629755570594, 53.98991328205463],
            [-1.823158578478206, 53.98904553040007],
            [-1.823261581249388, 53.987769430959354],
            [-1.824500241998723, 53.987334441925626],
            [-1.824891959747867, 53.986295137450384],
            [-1.826755161488383, 53.985648922684035],
            [-1.825486961285975, 53.984775285230185],
            [-1.822198536080339, 53.983482536974478],
            [-1.817742113717156, 53.980125259784202],
            [-1.816729268760503, 53.978124858118569],
            [-1.824885247235905, 53.97624598968293],
            [-1.849895336166424, 53.965708438989616],
            [-1.860132954683701, 53.962324312501821],
            [-1.861678228053201, 53.961926144595701],
            [-1.863159643896567, 53.961937724088038],
            [-1.862465264816123, 53.960355993390074],
            [-1.861182485595403, 53.959748748768433],
            [-1.861149918565455, 53.958999135378633],
            [-1.861564939013219, 53.958391144662528],
            [-1.863439470755141, 53.95745317002779],
            [-1.863983025572424, 53.956669157189928],
            [-1.865204497376142, 53.955947918092086],
            [-1.870401184664358, 53.954422116205585],
            [-1.872093891620311, 53.954519196356664],
            [-1.873322201098856, 53.954996844478053],
            [-1.875044731114741, 53.954812598616897],
            [-1.875990513718605, 53.95499512850337],
            [-1.878878860670796, 53.954260176446823],
            [-1.879937841566176, 53.955888015125055],
            [-1.879811810761934, 53.956792054261093],
            [-1.878136734007106, 53.958977981723287],
            [-1.878719732489597, 53.962377724492974],
            [-1.879509478963065, 53.962824307861005],
            [-1.882238413493386, 53.963122703125656],
            [-1.885454745940071, 53.963002673578742],
            [-1.885526276562142, 53.961926013626304],
            [-1.886375006699879, 53.960879748673115],
            [-1.88741338622459, 53.960136541033691],
            [-1.889701788307683, 53.959260558628422],
            [-1.89051000193263, 53.95732893662035],
            [-1.893134689939264, 53.955369274520926],
            [-1.895707775138424, 53.955064149862913],
            [-1.89883706646652, 53.955376895027996],
            [-1.903569071535821, 53.95722146089696],
            [-1.903888557723444, 53.957458991961495],
            [-1.903496574858734, 53.95826128183041],
            [-1.906270015613203, 53.958364133761826],
            [-1.908495321500803, 53.957543468104916],
            [-1.911956006906135, 53.956935780235568],
            [-1.912901718839999, 53.956519437293828],
            [-1.913160385584383, 53.955977664470979],
            [-1.916992440527778, 53.955502217966057],
            [-1.919117906077343, 53.954902384440203],
            [-1.920097867367376, 53.95564362617916],
            [-1.92276625816106, 53.955620199660657],
            [-1.927976209717753, 53.950444698134739],
            [-1.930859382543589, 53.9502540483169],
            [-1.934231806372153, 53.95189889074971],
            [-1.941609023381508, 53.952503965269685],
            [-1.943985803532579, 53.956173879258913],
            [-1.950679725761993, 53.956728644741823],
            [-1.949358396101037, 53.952998194339592],
            [-1.958201611159135, 53.953938996363377],
            [-1.966171674611011, 53.951547158162164],
            [-1.965045336402832, 53.948221379385686],
            [-1.963889070784298, 53.948025107431164],
            [-1.963511608466467, 53.947568417535862],
            [-1.963050000011472, 53.94578241522256],
            [-1.9623815911783, 53.945306758156903],
            [-1.962285978552467, 53.943193711865412],
            [-1.968825591615704, 53.943919984139406],
            [-1.969348167750786, 53.943885065301494],
            [-1.96892651095397, 53.943417595634521],
            [-1.970523158792224, 53.943319129428929],
            [-1.969957464299006, 53.941874661131401],
            [-1.967774050363642, 53.940386629111707],
            [-1.963073055084427, 53.933390123448845],
            [-1.968015150652964, 53.931810584628138],
            [-1.965912976071921, 53.930412415183724],
            [-1.965886094085099, 53.927873366045475],
            [-1.967237178910613, 53.927507937402019],
            [-1.970411729660264, 53.928422809576233],
            [-1.970214817999032, 53.929069879930871],
            [-1.971918381331598, 53.929966365949163],
            [-1.97686386833142, 53.926403763129372],
            [-1.97394300710639, 53.924041187124466],
            [-1.961702313102261, 53.918273178859863],
            [-1.959643432254278, 53.915522249581763],
            [-1.95745004545683, 53.91357384534696],
            [-1.956833333219861, 53.913723722550785],
            [-1.953223001180984, 53.911692038277053],
            [-1.953406842588826, 53.90651244690963],
            [-1.952563921766807, 53.903519183142315],
            [-1.964781180772286, 53.903334617562358],
            [-1.968424466131243, 53.903829052918965],
            [-1.974882424853627, 53.901164782526031],
            [-1.976972153660525, 53.900909943394311],
            [-1.979346964388619, 53.901165174284415],
            [-1.978903377342712, 53.898002740004593],
            [-1.979466731999102, 53.897406944974676],
            [-1.980707170579697, 53.896991911367856],
            [-1.98110436496684, 53.896960516343633],
            [-1.980774979048576, 53.895112571592101],
            [-1.981309479369184, 53.894237242186755],
            [-1.978619390994439, 53.890795365701415],
            [-1.9772486115819, 53.887723979492471],
            [-1.978094831065665, 53.884186528067332],
            [-1.976124695070213, 53.878882438686958],
            [-1.976976783314686, 53.875586763911564],
            [-1.977084860314793, 53.875425902049415],
            [-1.978720734019586, 53.873792210360598],
            [-1.980195761500995, 53.87110149939523],
            [-1.982356266146978, 53.868847679765125],
            [-1.987650294480227, 53.867723050923111],
            [-1.995478770322453, 53.868665510560419],
            [-1.999452429762402, 53.872051307308404],
            [-2.013034727165961, 53.87050556063209],
            [-2.013481586732708, 53.869723567667037],
            [-2.021627771654927, 53.871483077431449],
            [-2.031705651254165, 53.858579650373301],
            [-2.038348085352561, 53.855208145975617],
            [-2.04506936956049, 53.851235852047232],
            [-2.046127656227604, 53.850127242452096],
            [-2.06228710426414, 53.859067333550549],
            [-2.063147906789601, 53.859241246405446],
            [-2.064754378432924, 53.858863795351475],
            [-2.066405192885279, 53.859619667886342],
            [-2.067798267511165, 53.859854368003404],
            [-2.068962680000404, 53.859717086499153],
            [-2.071365743773951, 53.861083629125041],
            [-2.074846832810044, 53.862353283475016],
            [-2.075962103803292, 53.863596500099881],
            [-2.075792897567483, 53.864200591499767],
            [-2.076456334610068, 53.865286801151569],
            [-2.076199077806284, 53.865970941110611],
            [-2.076720698206775, 53.866821757486747],
            [-2.081188433150622, 53.869957366594882],
            [-2.084494818061868, 53.870057538892247],
            [-2.086116645151391, 53.869643840418988],
            [-2.088987782523482, 53.868177618905207],
            [-2.089912358613924, 53.868167940002266],
            [-2.090093922304852, 53.868445528098647],
            [-2.08834238366769, 53.870045772513194],
            [-2.084243667016779, 53.872211204277129],
            [-2.082690739411093, 53.873685395285605],
            [-2.081444405644937, 53.875666271739945],
            [-2.082410561066184, 53.876603041933471],
            [-2.082454999690972, 53.877540442886321],
            [-2.083023123374925, 53.877927425865764],
            [-2.083704546587962, 53.877918862104792],
            [-2.086245983833058, 53.880733845157209],
            [-2.090677077436737, 53.882796872815739],
            [-2.090918766204035, 53.883408760719732],
            [-2.093852474284527, 53.885073738848469],
            [-2.095732262194069, 53.888141592781317],
            [-2.103016192071685, 53.890927165048915],
            [-2.103414148361535, 53.891878632050407],
            [-2.113173004148994, 53.904597445003503],
            [-2.107463523406087, 53.908028850925199],
            [-2.109787587602828, 53.909079214131125],
            [-2.111815156035325, 53.911919278267902],
            [-2.114691223649713, 53.913738384117877],
            [-2.113089687175548, 53.915425116077294],
            [-2.116683225288394, 53.917271356078231],
            [-2.118285942142335, 53.91764008127199],
            [-2.121754812560071, 53.91731664200217],
            [-2.124624734679271, 53.921909142846623],
            [-2.124346844653247, 53.923186594132432],
            [-2.127061746484042, 53.92365290843258],
            [-2.130155361532462, 53.92346623844179],
            [-2.130894363823387, 53.924590702783327],
            [-2.13254346381885, 53.925531707782589],
            [-2.131911442170377, 53.925995275104704],
            [-2.132220633114785, 53.926494654409716],
            [-2.137785577914628, 53.92802889063362],
            [-2.138212376090669, 53.927685965405075],
            [-2.139974923372873, 53.928311266582],
            [-2.140874674431749, 53.926420983794053],
            [-2.141794930468934, 53.925660432290492],
            [-2.14495767019514, 53.926499702791304],
            [-2.149430033322401, 53.926014265115903],
            [-2.149489511967454, 53.926464478301142],
            [-2.14811155328622, 53.927811657546556],
            [-2.15171311213127, 53.929469891504574],
            [-2.155178308017114, 53.927552859969381],
            [-2.156883405906639, 53.927007779865939],
            [-2.157815545248002, 53.928256755483069],
            [-2.159334043724979, 53.927900629260847],
            [-2.159659691400368, 53.928631800536422],
            [-2.162873771856943, 53.927255944398212],
            [-2.163212061115415, 53.928479618773984],
            [-2.163738900178463, 53.928845601560532],
            [-2.163785622823467, 53.930274592856151],
            [-2.161645708860271, 53.931814404325237],
            [-2.162072200054674, 53.932610144547802],
            [-2.164116437731773, 53.934621521846232],
            [-2.169784538623211, 53.936996286524163],
            [-2.171676119248041, 53.937673967112275],
            [-2.173495950215704, 53.937199491245238],
            [-2.174424351140073, 53.936650790234431],
            [-2.175941998605494, 53.934246145153118],
            [-2.179313273314094, 53.935451804020644],
            [-2.182278546901693, 53.935376331117837],
            [-2.184383641492381, 53.937120334370746],
            [-2.185635258810743, 53.940440279010325],
            [-2.185484718938226, 53.941873159810257],
            [-2.183711853279207, 53.943688717350987],
            [-2.183840398058225, 53.945202956037228],
            [-2.183158151706717, 53.945616537214462],
            [-2.18139824135485, 53.945564386099115],
            [-2.179463581094709, 53.945971744577427],
            [-2.180458310846224, 53.946642535640997],
            [-2.180707881778555, 53.94798223252959],
            [-2.18367722665226, 53.949323187918424],
            [-2.183521901937168, 53.95142296157146],
            [-2.184519041771085, 53.952250101475016],
            [-2.186165931522408, 53.953546285119415],
            [-2.187764179096675, 53.954169340844722],
            [-2.188151285129622, 53.955194234822919],
            [-2.191282312840832, 53.958065357991224],
            [-2.19361266018863, 53.961386180578131],
            [-2.195585170408942, 53.96632982338194],
            [-2.196014963657814, 53.969562003211543],
            [-2.200631998466367, 53.970112510144972],
            [-2.200399639719331, 53.969660816417814],
            [-2.201480850271414, 53.96879708430783],
            [-2.201663685992357, 53.967832395344757],
            [-2.203169469618606, 53.967776828042176],
            [-2.206653333201788, 53.96638677094807],
            [-2.207035999700111, 53.965171870404831],
            [-2.209635551645103, 53.962885374666136],
            [-2.213108649203927, 53.963388861493257],
            [-2.212971816559477, 53.963754904784565],
            [-2.214417494982978, 53.965085209980423],
            [-2.220608435737521, 53.963539785081487],
            [-2.225733206824163, 53.961085593397272],
            [-2.225986136610253, 53.96276132894112],
            [-2.225298110933439, 53.964903498912051],
            [-2.225944845826019, 53.967239989942868],
            [-2.227515767025139, 53.967398801411207],
            [-2.22867057008758, 53.967003843406189],
            [-2.229300197103259, 53.967296540717058],
            [-2.229735125447623, 53.967663306856224],
            [-2.229166209480576, 53.968434642105393],
            [-2.229727853269755, 53.969664886020006],
            [-2.230935232355288, 53.970774349825845],
            [-2.232012430323929, 53.971216265741788],
            [-2.227693575247359, 53.974842090556727],
            [-2.225275762963954, 53.975445233989298],
            [-2.224236771000984, 53.976161702230762],
            [-2.223474361602873, 53.976146047417579],
            [-2.222690880245973, 53.977038185245341],
            [-2.221059536625812, 53.97791390682044],
            [-2.224056423352422, 53.978634556863348],
            [-2.227101210600175, 53.978332240828308],
            [-2.22819084797325, 53.979904820165643],
            [-2.230342077048385, 53.981516700437531],
            [-2.231527575013573, 53.981091997921261],
            [-2.232424917489729, 53.981225079437735],
            [-2.232681052613083, 53.980945065728783],
            [-2.233687100547086, 53.981157018855633],
            [-2.233685733920957, 53.98172953810559],
            [-2.234574952048019, 53.981770046065783],
            [-2.234645919444038, 53.98137444850645],
            [-2.235136011499374, 53.981484038310782],
            [-2.235535036608904, 53.981128241856894],
            [-2.236904748390433, 53.980932311073815],
            [-2.238102954452434, 53.981145645439007],
            [-2.239145814755829, 53.980857763580204],
            [-2.241257691060592, 53.980857133153435],
            [-2.241754445749744, 53.981061952259374],
            [-2.241988083223513, 53.980857460665852],
            [-2.242295472328939, 53.981012327950232],
            [-2.242420166741661, 53.98069121518094],
            [-2.243249008497037, 53.981101175504975],
            [-2.244407508961134, 53.981037706200034],
            [-2.245217179354942, 53.980516565784498],
            [-2.245228384923927, 53.979828983401681],
            [-2.245865433742171, 53.979258758580038],
            [-2.248208541996183, 53.978669736027996],
            [-2.284502341865545, 53.973796965346118],
            [-2.28614930712313, 53.974749345161221],
            [-2.287060556135116, 53.974460461527308],
            [-2.292090742073322, 53.974975908960573],
            [-2.294032769126655, 53.974713221088678],
            [-2.294317322471605, 53.97441952405503],
            [-2.295403785239019, 53.974554368103696],
            [-2.295244427509714, 53.975087732178068],
            [-2.298852972490952, 53.976547398814098],
            [-2.298518069073212, 53.977686077597902],
            [-2.296762027888035, 53.979246204926767],
            [-2.296730071452469, 53.980541414065335],
            [-2.300076038463235, 53.981462428877983],
            [-2.305316456560383, 53.983896573788314],
            [-2.304473212803817, 53.984529652306577],
            [-2.309461689861213, 53.987452287177668],
            [-2.310262989007552, 53.987743218950378],
            [-2.310784372728938, 53.987512684169751],
            [-2.311548454481148, 53.988128160011478],
            [-2.312793174485402, 53.988160875251914],
            [-2.314248440378298, 53.988609157804362],
            [-2.314845262496778, 53.989686119652013],
            [-2.317357241268472, 53.991096869321893],
            [-2.318357652988947, 53.992080172902277],
            [-2.318826201988108, 53.99371559088793],
            [-2.324600698411367, 53.994072213790723],
            [-2.326932695021554, 53.993851981119619],
            [-2.33366519815517, 53.990398363444754],
            [-2.334022054992274, 53.990965394866855],
            [-2.3359665885101, 53.991711343710534],
            [-2.338319162990341, 53.991231087348901],
            [-2.339921076745583, 53.989414641083172],
            [-2.345420873477354, 53.992194128448254],
            [-2.352355635756587, 53.994660884363725],
            [-2.35356927493766, 54.003773546682844],
            [-2.352308169636681, 54.010526110130861],
            [-2.350286566963081, 54.012187553454552],
            [-2.34921892754786, 54.011523775545619],
            [-2.347976098029854, 54.00997700616773],
            [-2.342810236767963, 54.008776723658904],
            [-2.340757802979294, 54.007449689138255],
            [-2.339378633339529, 54.008780176105176],
            [-2.339557796681991, 54.009420491792959],
            [-2.343219495173424, 54.016042994521811],
            [-2.343538255339189, 54.017314738283162],
            [-2.345648501198315, 54.018182287500473],
            [-2.349389623859412, 54.017687030349094],
            [-2.350148255060562, 54.018609653523519],
            [-2.357321098061523, 54.01910442748401],
            [-2.357524448476144, 54.020213800412371],
            [-2.356539984760389, 54.02145972105189],
            [-2.357792550465433, 54.022463515557995],
            [-2.357917442808237, 54.023320567335475],
            [-2.359275213112034, 54.023415379405037],
            [-2.360348329507528, 54.024305540182901],
            [-2.359511444754559, 54.026380603197936],
            [-2.361726017125365, 54.027216999774915],
            [-2.361308320263177, 54.027986701796124],
            [-2.361931291528178, 54.029232314588214],
            [-2.359255399997814, 54.031737116540313],
            [-2.36265591400122, 54.034433085270372],
            [-2.361845095220084, 54.037936219833725],
            [-2.364177132075265, 54.04008081833382],
            [-2.361997013954495, 54.040657221830905],
            [-2.374070435969392, 54.049076720927999],
            [-2.384419708030265, 54.047600650508883],
            [-2.387161801436782, 54.045206528098433],
            [-2.389657281116845, 54.044234088932072],
            [-2.391326950882406, 54.044124407409967],
            [-2.39473845253765, 54.043304362701164],
            [-2.397056035602852, 54.044204481400421],
            [-2.400460719901991, 54.043480464629148],
            [-2.401781906145507, 54.043662099688213],
            [-2.403826343677718, 54.043464709653954],
            [-2.405541591009928, 54.042553877393701],
            [-2.40621864635518, 54.042767294706273],
            [-2.407706758652332, 54.042373090359995],
            [-2.414433113573649, 54.042022035293314],
            [-2.418940470545305, 54.0413601957301],
            [-2.422520900678426, 54.040559443983433],
            [-2.425824463492826, 54.038082467538331],
            [-2.432731986483436, 54.041580962051228],
            [-2.440356130489823, 54.04182829343587],
            [-2.442385588456938, 54.042258540443498],
            [-2.445322477742937, 54.042145235610484],
            [-2.450753312054898, 54.040644721599321],
            [-2.456627033923994, 54.040160583556109],
            [-2.458772974136449, 54.04048315832943],
            [-2.464674558347352, 54.043190045682522],
            [-2.46954793279007, 54.04619995408104],
            [-2.470544236993534, 54.050842684313039],
            [-2.470060938660507, 54.052273614176265],
            [-2.469013333178924, 54.054868849409281],
            [-2.463988136884606, 54.061071856531385],
            [-2.465610617064844, 54.071718636941362],
            [-2.464345188131777, 54.075267353763422],
            [-2.466884253657356, 54.075726676054906],
            [-2.47072201169025, 54.076552056972588],
            [-2.480748967856214, 54.085366997193184],
            [-2.484724511990383, 54.085191957892889],
            [-2.486355998454782, 54.084834853536236],
            [-2.488395876739209, 54.086713088594699],
            [-2.491564290426159, 54.08730774289814],
            [-2.492563803357017, 54.08986422647957],
            [-2.494203378516756, 54.089791890641735],
            [-2.506303004580215, 54.09146269204664],
            [-2.50875165713376, 54.09241043959981],
            [-2.508547492025878, 54.094217814773224],
            [-2.509523888177335, 54.095413529487629],
            [-2.517239830597146, 54.094956379463831],
            [-2.520520658157475, 54.095530915082399],
            [-2.5240075010317, 54.094610720874492],
            [-2.525196904567145, 54.095323644408992],
            [-2.525188275144902, 54.095971689807484],
            [-2.525587939556947, 54.096017578444766],
            [-2.525882978108096, 54.098428566677072],
            [-2.526389282238918, 54.099165134393559],
            [-2.524490649702919, 54.10030317188162],
            [-2.5241067664067, 54.102117647226493],
            [-2.524890753318513, 54.102317348825487],
            [-2.526303602907535, 54.101938189338277],
            [-2.527500126321794, 54.102589942823947],
            [-2.530040137546422, 54.102697413825865],
            [-2.531532414017338, 54.10326963148686],
            [-2.534424676588344, 54.10341230224055],
            [-2.536764104162271, 54.104087641102332],
            [-2.53812576139568, 54.105663377904214],
            [-2.53672917328455, 54.106474008816086],
            [-2.536951395253405, 54.106863978280025],
            [-2.536198556145379, 54.108614527959475],
            [-2.536663146103459, 54.109058241709647],
            [-2.537457559440975, 54.109103228213698],
            [-2.538209557027897, 54.109888079522754],
            [-2.538585066589551, 54.109711140554744],
            [-2.539089335629833, 54.109909305555227],
            [-2.541783814135337, 54.111854702112311],
            [-2.542077364003201, 54.111726654475007],
            [-2.543796107019905, 54.112617658438786],
            [-2.543996827318999, 54.113227009506979],
            [-2.544677312159432, 54.113209550374684],
            [-2.54473830409949, 54.114010968722681],
            [-2.545833526509068, 54.114355622508235],
            [-2.54605519379328, 54.115159007280162],
            [-2.545436391666752, 54.115682196609129],
            [-2.545651232786523, 54.115966129436615],
            [-2.544668964233817, 54.116538596923341],
            [-2.544762245257153, 54.117118773655108],
            [-2.55308105940858, 54.120531159378338],
            [-2.556871666325363, 54.120597259272401],
            [-2.559211292210516, 54.12107443461155],
            [-2.560633537116836, 54.121706834405622],
            [-2.560763249115929, 54.12236142608392],
            [-2.56332751270126, 54.122694571568346],
            [-2.563007961086872, 54.124403711336939],
            [-2.563412616975111, 54.124681331530503],
            [-2.563298197235655, 54.125382899919202],
            [-2.564736328141541, 54.127055041828392],
            [-2.563188305500741, 54.127884662267157],
            [-2.562470925249165, 54.129148977082195],
            [-2.56138677374768, 54.129888326187682],
            [-2.562487469002751, 54.131724743010032],
            [-2.562053957739656, 54.132824153026284],
            [-2.56222198227387, 54.135172722677879],
            [-2.563240977071746, 54.135149083628114],
            [-2.563713334435398, 54.135445259580955],
            [-2.563583623600767, 54.136149594838571],
            [-2.56266389117242, 54.136497224332963],
            [-2.563408616664558, 54.140394348973238],
            [-2.561328053525965, 54.141952630328667],
            [-2.560949583741257, 54.142830683227139],
            [-2.558314448606338, 54.142895963629584],
            [-2.557872070778385, 54.143352786072825],
            [-2.558196087125104, 54.147410068278212],
            [-2.557376189781906, 54.149968132767775],
            [-2.558493168800054, 54.149917118287334],
            [-2.561512896495988, 54.15051961460275],
            [-2.560502781129578, 54.153040838905085],
            [-2.557672949181378, 54.153478196190903],
            [-2.552285434604255, 54.153645939643546],
            [-2.547220277401587, 54.153297889039614],
            [-2.5440655118861, 54.156523440553222],
            [-2.543760210687465, 54.157530526049676],
            [-2.54297553238564, 54.157715616615967],
            [-2.540324114268062, 54.157545999710145],
            [-2.536519544664538, 54.156541135243906],
            [-2.533295138014338, 54.158246020251433],
            [-2.532993180039393, 54.160844754671587],
            [-2.531556291350919, 54.160933793845949],
            [-2.53079035058208, 54.161515071940578],
            [-2.52995319140065, 54.16263680901109],
            [-2.530448741562145, 54.16402140152055],
            [-2.528904481140497, 54.164226820925542],
            [-2.530240559944462, 54.166424683615851],
            [-2.529357628332538, 54.16696961824691],
            [-2.529298798526231, 54.168479780094529],
            [-2.528719041862581, 54.169500613457352],
            [-2.527748117220614, 54.169637889084498],
            [-2.526563643266464, 54.17044925594363],
            [-2.52327990115017, 54.171212234190698],
            [-2.523649881883313, 54.172367317966973],
            [-2.522535921333692, 54.173680739439369],
            [-2.518728626838477, 54.175968346799593],
            [-2.518197729723121, 54.17663750570096],
            [-2.51533947633687, 54.177910720558671],
            [-2.511435872222731, 54.179038221028762],
            [-2.479955348709061, 54.202301567366007],
            [-2.479320711388547, 54.203361917348339],
            [-2.479626519920056, 54.204616245571863],
            [-2.477046528388195, 54.208885640276321],
            [-2.475690412336573, 54.210287651530123],
            [-2.474767115953417, 54.213738872692112],
            [-2.473695942825445, 54.215270952752128],
            [-2.46901237613051, 54.218888743802133],
            [-2.465608319734042, 54.220705725097595],
            [-2.463966061907489, 54.222098819830983],
            [-2.460859481326116, 54.226705107774272],
            [-2.441414799630323, 54.225728206722408],
            [-2.437648311958766, 54.224193422703216],
            [-2.435424150653213, 54.222865956565911],
            [-2.43347420084449, 54.223250460730718],
            [-2.431941002020149, 54.224184366730199],
            [-2.429414471043066, 54.223556194554618],
            [-2.426491067055276, 54.22369959507963],
            [-2.424047679598308, 54.224437998418736],
            [-2.414791068491632, 54.225950454730466],
            [-2.411966806679741, 54.226675543517366],
            [-2.410550951557737, 54.226357730820517],
            [-2.409211355947976, 54.226613936204679],
            [-2.407494041354167, 54.225898970405538],
            [-2.406446608909552, 54.225917788111744],
            [-2.405628327512922, 54.224904073651096],
            [-2.403625558632903, 54.225572273266721],
            [-2.401373312533178, 54.230411414006539],
            [-2.400926743956738, 54.234032115604769],
            [-2.396775948456446, 54.239369973548058],
            [-2.385082077303355, 54.239138302661026],
            [-2.373016953491154, 54.240087585643316],
            [-2.372321532271538, 54.248223283138692],
            [-2.362313431295483, 54.24974120217545],
            [-2.360403241465501, 54.246637314431979],
            [-2.350546114686642, 54.241355784202241],
            [-2.348048782690438, 54.237795050874631],
            [-2.342505663320781, 54.237455041908774],
            [-2.337653843095592, 54.237587373875328],
            [-2.335807286572345, 54.23805268819163],
            [-2.331306062202128, 54.240589697005234],
            [-2.328267438534101, 54.241874218252129],
            [-2.325643095809627, 54.241426582860377],
            [-2.325465097708523, 54.242789543580713],
            [-2.322653230919801, 54.245448370629767],
            [-2.323249905895826, 54.246982704844719],
            [-2.322534573438584, 54.248136797244221],
            [-2.322642389763714, 54.252902483877143],
            [-2.32208786013706, 54.255009695463009],
            [-2.319092833406692, 54.257144063484724],
            [-2.318111346148603, 54.255867765144885],
            [-2.310469071424443, 54.252227171967604],
            [-2.307325015358187, 54.25006389931945],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000164",
        LAD13CDO: "36UC",
        LAD13NM: "Hambleton",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.227598823223792, 54.511984003530515],
            [-1.220088245030884, 54.510892406677279],
            [-1.219052052874112, 54.510153268518373],
            [-1.214093546634853, 54.510024992960815],
            [-1.208487538747035, 54.50887762806876],
            [-1.206853304053686, 54.507930460903985],
            [-1.203898781068827, 54.506899096874676],
            [-1.202132737859166, 54.506940485232818],
            [-1.196666749447723, 54.506172751690933],
            [-1.195471903261041, 54.506619561688645],
            [-1.189711904119686, 54.506280043337895],
            [-1.185080675747698, 54.507272571342945],
            [-1.181149521292554, 54.505759542171646],
            [-1.17999824154562, 54.506182229316003],
            [-1.179365883996843, 54.505986513659586],
            [-1.176130377937967, 54.504300989771266],
            [-1.175536012572822, 54.504675300840432],
            [-1.173850587762052, 54.504704243310165],
            [-1.173457906979346, 54.504036508022445],
            [-1.174195670686253, 54.503826753209346],
            [-1.173894891955166, 54.503196494167078],
            [-1.174378411401159, 54.50288164875461],
            [-1.173527672143594, 54.502492085270468],
            [-1.172713985014641, 54.502868478636479],
            [-1.172295967712488, 54.502539383433295],
            [-1.171779212894916, 54.502664363067325],
            [-1.170640044792241, 54.502335714220884],
            [-1.168959045977526, 54.503521271526303],
            [-1.166963803989343, 54.503371839622147],
            [-1.166624888031909, 54.503711018697771],
            [-1.166019686426489, 54.503096616203244],
            [-1.16535600880575, 54.503534208778454],
            [-1.164179679504514, 54.503136041096134],
            [-1.164060030135146, 54.503399438079803],
            [-1.163099932437686, 54.503529404363327],
            [-1.158282680935207, 54.502359993723907],
            [-1.152654249406476, 54.501899193530626],
            [-1.148045701632181, 54.50111279188355],
            [-1.146680146326294, 54.50135930120117],
            [-1.146183588348026, 54.502809030517291],
            [-1.130838843623861, 54.500024236682279],
            [-1.126640573702629, 54.498708818475997],
            [-1.122797754576723, 54.498977586920084],
            [-1.118366233445479, 54.498185941898498],
            [-1.114310388794198, 54.498951679952505],
            [-1.113187230914792, 54.500334676031414],
            [-1.112252538874197, 54.500784371267962],
            [-1.111623382587569, 54.502026283036052],
            [-1.111768870107726, 54.504361339337386],
            [-1.106172700440608, 54.505687972600334],
            [-1.09450946874436, 54.506747095845881],
            [-1.08723710112077, 54.503341983113728],
            [-1.07818334207616, 54.503808959870128],
            [-1.073798500954585, 54.502606216563763],
            [-1.067911813074933, 54.500435500981396],
            [-1.064785688662708, 54.498506062815892],
            [-1.060003722939377, 54.500319473865261],
            [-1.057724411126516, 54.49898240215569],
            [-1.051064055922056, 54.496408489152792],
            [-1.049120225230042, 54.496404904686131],
            [-1.046695878837249, 54.495950832890323],
            [-1.044591832470809, 54.495998937622723],
            [-1.042962817282074, 54.495540286499626],
            [-1.042865980151527, 54.495257318472355],
            [-1.039489759907339, 54.495106525366573],
            [-1.036836252928276, 54.494031208338555],
            [-1.033893685256998, 54.492911282971946],
            [-1.031570819304384, 54.491314528616904],
            [-1.031619951770859, 54.49073793549929],
            [-1.030475056051673, 54.48914609258734],
            [-1.030573240369196, 54.48832363994719],
            [-1.02995635478881, 54.487580832640738],
            [-1.028986604750958, 54.487398695064066],
            [-1.027429139424149, 54.48622321676315],
            [-1.027280117530171, 54.485672891548695],
            [-1.026018577038459, 54.485820015198442],
            [-1.025781873512232, 54.485127880439187],
            [-1.024826068114156, 54.485271163321684],
            [-1.02491092383559, 54.484883594354059],
            [-1.023060096319084, 54.483979806478338],
            [-1.022984206937107, 54.483342889074748],
            [-1.019054655088156, 54.482493224904864],
            [-1.018686537224387, 54.481364571793335],
            [-1.019075427843619, 54.480141402676836],
            [-1.018513798518436, 54.479682983460528],
            [-1.018279491972377, 54.47799505269505],
            [-1.016438635339948, 54.476630189815474],
            [-1.009517817026941, 54.475512282778766],
            [-1.004112948732636, 54.475467883566559],
            [-0.999428448595852, 54.476006196179796],
            [-0.994685315972377, 54.477357198290491],
            [-0.991609540546082, 54.478799224277189],
            [-0.991138511036116, 54.478560724191972],
            [-0.989512157458513, 54.479486324469114],
            [-0.983500655618715, 54.478864336146884],
            [-0.979945985255027, 54.471928482106648],
            [-0.976105176749056, 54.467664742204484],
            [-0.972076479789395, 54.460833366751984],
            [-0.973362306588887, 54.461357476074639],
            [-0.974041238848733, 54.461228428941823],
            [-0.976409317106375, 54.459743111073074],
            [-0.978162070704782, 54.45948292535212],
            [-0.981713750204787, 54.457658792016112],
            [-0.984787343310959, 54.457439288923894],
            [-0.9883864378192, 54.458803195793294],
            [-0.99431792968825, 54.458104038749475],
            [-0.995975413490199, 54.457442862929724],
            [-0.996890904802977, 54.457979822063272],
            [-1.000647174701491, 54.458672408221183],
            [-1.003441675082401, 54.458319805741823],
            [-1.006836358844952, 54.45846997345506],
            [-1.007878206601097, 54.458253844029777],
            [-1.010057994196665, 54.458824439995553],
            [-1.011225097901178, 54.459619490552591],
            [-1.013134896967875, 54.460003580174849],
            [-1.019306909418077, 54.45962151698587],
            [-1.021479328169979, 54.459993224912289],
            [-1.023165476423717, 54.459862165398057],
            [-1.023632319981976, 54.459497454339186],
            [-1.025917613313649, 54.45914471237964],
            [-1.02658614334941, 54.459510491622822],
            [-1.027575476499867, 54.459293769819148],
            [-1.028285103599862, 54.459618528241094],
            [-1.028497851454345, 54.459298490091498],
            [-1.029267526744981, 54.459367585030172],
            [-1.029193810099851, 54.459092877996405],
            [-1.029872213593223, 54.459303236616648],
            [-1.031007662243269, 54.458907911811394],
            [-1.03116684035759, 54.459091631441424],
            [-1.032864704119192, 54.458722365277339],
            [-1.033926183868741, 54.45897710730064],
            [-1.034810104986563, 54.458583331421238],
            [-1.035675231301181, 54.458594729741606],
            [-1.035930532570333, 54.458430499726596],
            [-1.035417390954929, 54.458341924815279],
            [-1.035723099936136, 54.45806755275769],
            [-1.03617114614094, 54.458169088430154],
            [-1.036583757205192, 54.457414742028234],
            [-1.036991542138722, 54.457785575010149],
            [-1.037749789935958, 54.457420434829722],
            [-1.037375140381195, 54.457280843793441],
            [-1.03765940938524, 54.457064714062597],
            [-1.039030184737481, 54.457219417286446],
            [-1.038730611638791, 54.456772160376865],
            [-1.039248219174618, 54.456208273470359],
            [-1.04214606737426, 54.455371179728978],
            [-1.041585531702779, 54.455067457073731],
            [-1.042288007424806, 54.454246186524998],
            [-1.043201182005516, 54.4541114178135],
            [-1.04252747236423, 54.453832866916493],
            [-1.043398187786246, 54.45333556922261],
            [-1.043475082434103, 54.452815808739835],
            [-1.04837559614196, 54.452133702287178],
            [-1.047879596497062, 54.451640883181057],
            [-1.04883423029408, 54.451776918620048],
            [-1.049365793870169, 54.450801476836858],
            [-1.049907540977042, 54.450784166725043],
            [-1.05026915665167, 54.450219905408645],
            [-1.051451103779031, 54.450062021645664],
            [-1.052552387137658, 54.449389416390758],
            [-1.052720627530692, 54.448447959608025],
            [-1.053574316248008, 54.447675441967711],
            [-1.053330873447429, 54.4472646099195],
            [-1.054989545719547, 54.44602205374256],
            [-1.055482038901531, 54.444054073043688],
            [-1.056997275984751, 54.442401442625822],
            [-1.057492693929079, 54.440904414705066],
            [-1.052649858747346, 54.436946251795788],
            [-1.053928311428315, 54.433987731376824],
            [-1.05555002907836, 54.427422540372355],
            [-1.064682892616681, 54.42112780211054],
            [-1.059367628342085, 54.412565572572973],
            [-1.070928971061857, 54.405668215163217],
            [-1.081798677836561, 54.405012555281949],
            [-1.070415118273769, 54.395756520688757],
            [-1.07881225130053, 54.388200405310521],
            [-1.067222004560128, 54.375458007349742],
            [-1.06719128537525, 54.3651293628212],
            [-1.066199293677678, 54.36265463038486],
            [-1.070477577785874, 54.36102315947663],
            [-1.07815140162825, 54.360234472691985],
            [-1.078017221342874, 54.359697794222825],
            [-1.078582240924415, 54.359205099675783],
            [-1.078259989678966, 54.358545654947513],
            [-1.085297894756423, 54.347075872488709],
            [-1.099022566899813, 54.329178473947366],
            [-1.102970784580156, 54.328503261291786],
            [-1.113485986609932, 54.329774493010014],
            [-1.115112811934685, 54.330281641869576],
            [-1.116654904567967, 54.328644630768004],
            [-1.122560574714213, 54.331422601308795],
            [-1.120783897408559, 54.333176613024733],
            [-1.121440718305082, 54.33389140699235],
            [-1.120815170166777, 54.334738865953341],
            [-1.12105105329375, 54.337019809611469],
            [-1.120685409340247, 54.337072869007756],
            [-1.120373216454877, 54.336639195264347],
            [-1.119182534285529, 54.337299181458448],
            [-1.119552218030979, 54.337849216121668],
            [-1.118572423083693, 54.338714750145911],
            [-1.119048583955039, 54.338960888062523],
            [-1.118729094464705, 54.340954675166962],
            [-1.121063858893743, 54.341972902047253],
            [-1.121619175540696, 54.342621345868871],
            [-1.120886008678285, 54.343171434740576],
            [-1.120869513327213, 54.34380043768472],
            [-1.121612857661475, 54.343928078350828],
            [-1.121148023713853, 54.344517868302788],
            [-1.121789725276555, 54.345228953333446],
            [-1.124139872837732, 54.344814628547915],
            [-1.12494962357106, 54.344279455200855],
            [-1.127114575907655, 54.343744196858886],
            [-1.12785619174855, 54.343881672718176],
            [-1.130551496567666, 54.343360069494551],
            [-1.130835309348582, 54.34383395513656],
            [-1.132926590592447, 54.343578471243866],
            [-1.133291772316166, 54.34457330942854],
            [-1.136629850674267, 54.345236236960851],
            [-1.137005165798166, 54.346045994149122],
            [-1.140654622523382, 54.345876100634037],
            [-1.141668235776022, 54.346917767322488],
            [-1.142827098418856, 54.347265726654321],
            [-1.145549860048091, 54.348908160887973],
            [-1.149418561085477, 54.3487107933503],
            [-1.153030798562859, 54.349237688139105],
            [-1.157533608082875, 54.349253015901844],
            [-1.159306475164292, 54.35970606853428],
            [-1.16305990823633, 54.372496059872667],
            [-1.166022220532936, 54.377860456855984],
            [-1.172181588967401, 54.381858178956527],
            [-1.180890730206174, 54.376721057463669],
            [-1.190152879517377, 54.377242775873292],
            [-1.208730813938296, 54.373770078770903],
            [-1.222249385218018, 54.376964822317106],
            [-1.225028240789519, 54.378025217617179],
            [-1.227159771443344, 54.377490647949969],
            [-1.229218904180676, 54.377530768804114],
            [-1.238470139078246, 54.375937582188648],
            [-1.250857488067799, 54.361257837222524],
            [-1.254558589246168, 54.353859881326557],
            [-1.264778926471077, 54.354078926630073],
            [-1.26371284675771, 54.348988281135213],
            [-1.265507652869641, 54.347639424967198],
            [-1.26598380282386, 54.345868214796546],
            [-1.265628641534125, 54.343699200494392],
            [-1.263713523153842, 54.342493114284657],
            [-1.260603828879469, 54.33825992738133],
            [-1.2584903456622, 54.336646311102292],
            [-1.249096325155446, 54.332337205501517],
            [-1.246582961619268, 54.331525260997076],
            [-1.246711821140992, 54.323293601072365],
            [-1.249493260194589, 54.315345381045518],
            [-1.249930410683436, 54.311762116312316],
            [-1.247124214236374, 54.308197303195136],
            [-1.240954496749368, 54.304629285870874],
            [-1.236213711651977, 54.301460056306937],
            [-1.231670043547375, 54.299462058938374],
            [-1.227869895769938, 54.296346114412465],
            [-1.219737767590235, 54.286561261178413],
            [-1.218927509080211, 54.283106620404112],
            [-1.210839080414489, 54.26540970387186],
            [-1.205446315998102, 54.251305210388118],
            [-1.214004108744537, 54.249225851644965],
            [-1.212124060389264, 54.245438813105316],
            [-1.215054628681061, 54.241504301443179],
            [-1.208378309714993, 54.236366504856079],
            [-1.203504171526457, 54.238824859840541],
            [-1.197008376995893, 54.241081693600343],
            [-1.197286845336759, 54.240411277711722],
            [-1.198348680916127, 54.234154903255479],
            [-1.197316849332281, 54.230482004463802],
            [-1.196411647257064, 54.228986731708567],
            [-1.196664155346153, 54.226330785034683],
            [-1.193366385540358, 54.221917426363767],
            [-1.192037454319042, 54.22108975650977],
            [-1.193094071580664, 54.220072247309758],
            [-1.193704834188815, 54.218557429371508],
            [-1.196206173008169, 54.217292502156425],
            [-1.200190813450242, 54.216316888611559],
            [-1.199514460475689, 54.2144960044822],
            [-1.199888785109688, 54.212900493490373],
            [-1.199397656466466, 54.212606033988735],
            [-1.199651765226069, 54.212311130452093],
            [-1.197034682455747, 54.210630118267908],
            [-1.196055676777976, 54.210826712486416],
            [-1.192146554332303, 54.209682516544916],
            [-1.190174792625117, 54.20983735342999],
            [-1.188584043703909, 54.208465929503681],
            [-1.18920555395312, 54.207811319299807],
            [-1.187812366971945, 54.206221915175796],
            [-1.187578042828967, 54.205349435014362],
            [-1.190116819912346, 54.203706506716557],
            [-1.19072686218698, 54.202692306294331],
            [-1.189308876992668, 54.200182417749502],
            [-1.188314205780086, 54.200093935372756],
            [-1.186822425494155, 54.201512014074908],
            [-1.184457359587969, 54.201184162405823],
            [-1.182241257920545, 54.201621224083027],
            [-1.181655498861209, 54.201318858644484],
            [-1.175475783187342, 54.20248917189808],
            [-1.174344512949612, 54.203253465436759],
            [-1.168703465151089, 54.202461524128957],
            [-1.167192042513453, 54.201616137310893],
            [-1.16515487737302, 54.200998971816119],
            [-1.164185721821036, 54.199571306976075],
            [-1.162596763427656, 54.199462311251011],
            [-1.160727122364376, 54.198377977173962],
            [-1.155607596127119, 54.196485364967572],
            [-1.150701669018363, 54.19608419383286],
            [-1.146746062949727, 54.195091874846668],
            [-1.144546671992501, 54.193750600397102],
            [-1.142832937777934, 54.191388145210745],
            [-1.139487323754431, 54.191089277678024],
            [-1.137659461284062, 54.189959041922386],
            [-1.135797337543892, 54.189741681190803],
            [-1.134973656694761, 54.189041022886606],
            [-1.135793086184044, 54.187961189735056],
            [-1.135703613307462, 54.187401513712366],
            [-1.134551332417431, 54.185927350645549],
            [-1.133011445400649, 54.185991777330443],
            [-1.132561400983196, 54.185529267129034],
            [-1.130760497481611, 54.184960849349757],
            [-1.13040288150798, 54.184111627816215],
            [-1.129027631496766, 54.183563331526003],
            [-1.128966316014096, 54.183199785172995],
            [-1.125954208736646, 54.182380763583396],
            [-1.126475546995316, 54.18127276516519],
            [-1.12610202697555, 54.180529469579881],
            [-1.124503654612275, 54.180397430646053],
            [-1.12265901201394, 54.181187514014404],
            [-1.121943035981727, 54.180918059801961],
            [-1.120129650241265, 54.181026165159238],
            [-1.12030168540212, 54.181367156800626],
            [-1.119642838393604, 54.18151064191089],
            [-1.119360252397011, 54.182262645749077],
            [-1.117639063865924, 54.182931322004002],
            [-1.112842738873778, 54.183729263600725],
            [-1.111883100718633, 54.183676356772153],
            [-1.109724627017911, 54.182579205484231],
            [-1.107266084691911, 54.183040958784467],
            [-1.105531732589029, 54.182888786469164],
            [-1.104877422161592, 54.183386340867116],
            [-1.102674330725679, 54.18351016236948],
            [-1.101484521321269, 54.18417986858546],
            [-1.101253857258764, 54.184712018875871],
            [-1.100003802764484, 54.184695498075556],
            [-1.099144967881032, 54.185292156166412],
            [-1.097682444444517, 54.183138539495914],
            [-1.098153604502763, 54.178455865462283],
            [-1.097491319005363, 54.177559323151357],
            [-1.09425165181213, 54.176263246919852],
            [-1.092607906568843, 54.176256270887528],
            [-1.088796083136472, 54.175492304382054],
            [-1.087451564729027, 54.174420675256883],
            [-1.083664149881004, 54.174019835110393],
            [-1.082347216824346, 54.173475040153754],
            [-1.081894027378907, 54.172459566442733],
            [-1.080860812255159, 54.172491234178381],
            [-1.080595590540079, 54.172085660227118],
            [-1.082110725475366, 54.171113054561431],
            [-1.08888078193746, 54.163549121282969],
            [-1.088697374561755, 54.162904210209824],
            [-1.082631427875259, 54.160626953999632],
            [-1.077656770522288, 54.157441895618646],
            [-1.077083937919569, 54.156758031743976],
            [-1.075046893964224, 54.155743863707855],
            [-1.069161318126252, 54.154618191232252],
            [-1.067649695925011, 54.153539648825358],
            [-1.065407327077785, 54.153364983763844],
            [-1.063168278652719, 54.153586664496679],
            [-1.060394877582722, 54.152926028446828],
            [-1.057103920860382, 54.151393954512407],
            [-1.056323008103518, 54.149913838402654],
            [-1.053936301174329, 54.14916350666477],
            [-1.05319383868103, 54.147816690795153],
            [-1.052359888918961, 54.147327486302423],
            [-1.04837683491765, 54.146834106357019],
            [-1.047167982847852, 54.14694049136876],
            [-1.046329035939148, 54.147472221409089],
            [-1.044331994200082, 54.145105177150512],
            [-1.040735369103214, 54.143603478236251],
            [-1.034201311665721, 54.143355320341321],
            [-1.033698486587003, 54.14253069746362],
            [-1.034063644296914, 54.14137689232961],
            [-1.033775993857293, 54.140976423542632],
            [-1.029306149492034, 54.140453336303828],
            [-1.023029391024865, 54.137230766175236],
            [-1.018407032515771, 54.136318657730108],
            [-1.017875615402445, 54.135748988409091],
            [-1.01371689699536, 54.135111013836543],
            [-1.013071340269434, 54.134581732133],
            [-1.012805859391845, 54.133065999306858],
            [-1.01367802365505, 54.131528131995999],
            [-1.012656265805585, 54.129122692908581],
            [-1.012034242042972, 54.128830877560276],
            [-1.012210041553588, 54.128388318905358],
            [-1.011616455961255, 54.127866644987613],
            [-1.012526260361187, 54.126738042359165],
            [-1.008526990853073, 54.123756252680266],
            [-1.012361396273842, 54.11977018287147],
            [-1.015619374925556, 54.115480881401794],
            [-1.015927672153738, 54.114671795294328],
            [-1.01470875361109, 54.113948186802858],
            [-1.015574846354297, 54.112065117940084],
            [-1.015612510585886, 54.109312432915871],
            [-1.016192001452705, 54.108934285662208],
            [-1.016246896860387, 54.107907417277715],
            [-1.019456303936547, 54.10782484859233],
            [-1.021138253446855, 54.109406924931385],
            [-1.023396689548083, 54.108268525921034],
            [-1.025092050636822, 54.107916460628729],
            [-1.025043070278088, 54.106613718013357],
            [-1.03393164672574, 54.107374750538618],
            [-1.043768854691397, 54.107471269729174],
            [-1.043979480119887, 54.106293732833564],
            [-1.042352118139798, 54.104766339381278],
            [-1.040988228078851, 54.101568376378339],
            [-1.040129390803174, 54.100878451159588],
            [-1.039454856742255, 54.098368144305056],
            [-1.041199217243754, 54.098122301737732],
            [-1.042789458800494, 54.098259890262767],
            [-1.045747788692923, 54.096222456104258],
            [-1.045471408049483, 54.095872433128015],
            [-1.04680993262655, 54.094980656095977],
            [-1.046770337603787, 54.094506680454408],
            [-1.054571231060093, 54.089139520768185],
            [-1.057754663475734, 54.088178513465238],
            [-1.060185902959948, 54.085114674604441],
            [-1.054099227591155, 54.083272105079743],
            [-1.054779365907025, 54.082872993144356],
            [-1.054298275165772, 54.082249048045369],
            [-1.05222531620923, 54.081653020250265],
            [-1.046440455382592, 54.07908351547426],
            [-1.049452287050082, 54.07570631476861],
            [-1.04514233389314, 54.075039442856649],
            [-1.042105738780733, 54.07768652066936],
            [-1.039085753624034, 54.075692289897937],
            [-1.039812470228141, 54.073341455423787],
            [-1.040984544030941, 54.07301465361008],
            [-1.042136796704124, 54.072153799519477],
            [-1.042788222155879, 54.071006730363422],
            [-1.045111568056436, 54.069785754184529],
            [-1.048969964513903, 54.0683881293143],
            [-1.054152306852538, 54.067016974190771],
            [-1.055644378128659, 54.067390008553055],
            [-1.05815642627646, 54.063948446599461],
            [-1.059345435800271, 54.059066514615893],
            [-1.060595218258477, 54.056760087793151],
            [-1.059702311915697, 54.056590430789129],
            [-1.058410859229526, 54.05242700117477],
            [-1.058955813279738, 54.049671065953703],
            [-1.058724042439312, 54.047828519994518],
            [-1.062160857154678, 54.050121224473138],
            [-1.071131705255907, 54.048804883690082],
            [-1.083780610939104, 54.047588746777528],
            [-1.088997838624177, 54.048082259401163],
            [-1.09174870196657, 54.047305830279008],
            [-1.093070332780507, 54.046159953605823],
            [-1.094268630805946, 54.046609390147545],
            [-1.09609779373103, 54.040438552653228],
            [-1.09747912818992, 54.041103246170749],
            [-1.09842980931789, 54.04092342619068],
            [-1.099447425464025, 54.041532341489287],
            [-1.101068071666813, 54.040947664788433],
            [-1.099768425116876, 54.039397413766203],
            [-1.09977208872456, 54.03767265632893],
            [-1.107066398486472, 54.037385484560566],
            [-1.111574881876322, 54.036051804229452],
            [-1.114894613556839, 54.035823717195619],
            [-1.115491944628904, 54.035233110277709],
            [-1.115435699521775, 54.034573881458321],
            [-1.116963874220326, 54.033573967942473],
            [-1.120273720312229, 54.029028757248447],
            [-1.127576812695192, 54.031014356425409],
            [-1.140788669952947, 54.029842164206727],
            [-1.140628260552504, 54.02777379671695],
            [-1.142041667580777, 54.027190684516],
            [-1.143880799378836, 54.025647980525022],
            [-1.144805994322069, 54.025492780071822],
            [-1.145025967443052, 54.024517356871193],
            [-1.145090044298673, 54.022881111433612],
            [-1.142373278003165, 54.018935841277674],
            [-1.147907120779982, 54.016116116332448],
            [-1.148060712065442, 54.015694771639922],
            [-1.145915271470179, 54.01329415533344],
            [-1.143740377476958, 54.01263784885473],
            [-1.142867691210832, 54.010771127598439],
            [-1.144092283366513, 54.008204803649441],
            [-1.147174955083158, 54.006616964871569],
            [-1.148551101338555, 54.004829127496777],
            [-1.149082641913095, 54.002930141126519],
            [-1.148385635706733, 54.001648018460173],
            [-1.147362856916417, 54.001603020722804],
            [-1.146856211470534, 54.00056491323766],
            [-1.145084710281978, 53.999908792755249],
            [-1.143779217727616, 53.99877421061867],
            [-1.142255906416551, 53.998517981770981],
            [-1.14187538019455, 53.997957113992278],
            [-1.140562353871531, 53.997343744326152],
            [-1.140986605935192, 53.996001277574877],
            [-1.139392095842578, 53.995208820220519],
            [-1.139577882212745, 53.994039916164645],
            [-1.138907341653109, 53.992605129032519],
            [-1.136989840873857, 53.991065221693759],
            [-1.139373811798913, 53.990768630606368],
            [-1.143561632138902, 53.989527636646073],
            [-1.147208514352319, 53.988997205320153],
            [-1.150011136701717, 53.988878638172594],
            [-1.151904234724699, 53.989160748047823],
            [-1.158926721816905, 53.992841218738334],
            [-1.161337440539056, 53.994626888877193],
            [-1.167184445778993, 53.996296148515697],
            [-1.171360541801901, 54.000549335211971],
            [-1.172958976164311, 54.001492389407204],
            [-1.175502344124483, 54.00217945738266],
            [-1.180443775018867, 54.003041027407598],
            [-1.184659033064687, 54.006174112765642],
            [-1.186596250914761, 54.007062655798379],
            [-1.190735049455324, 54.008203284504113],
            [-1.192685817299306, 54.011005345719227],
            [-1.194308520778719, 54.012159490286763],
            [-1.195914095975055, 54.012385946214344],
            [-1.19925886742727, 54.011842924932346],
            [-1.202075055856971, 54.012523153260062],
            [-1.203877772887088, 54.013537251626602],
            [-1.205488720180956, 54.015490189498188],
            [-1.206772642975011, 54.016188934979667],
            [-1.208700934067776, 54.016229500478687],
            [-1.217239167308341, 54.01439162858226],
            [-1.218427491925256, 54.014418231842292],
            [-1.21932853342489, 54.015851360413166],
            [-1.218407731209796, 54.018567809327322],
            [-1.218812514248363, 54.02132073001146],
            [-1.217616651364167, 54.024126161582622],
            [-1.218412906748179, 54.025784208050943],
            [-1.221298923001523, 54.027614881219051],
            [-1.223239113769089, 54.030569153652593],
            [-1.223578198098314, 54.031790096503144],
            [-1.222905589810329, 54.033473672610498],
            [-1.224066383246167, 54.034516566816421],
            [-1.227681886132597, 54.035094384696066],
            [-1.230373578623149, 54.034853685066146],
            [-1.233341279868094, 54.035045202551871],
            [-1.236283723986036, 54.035616672155939],
            [-1.237531730315449, 54.035085318451486],
            [-1.238933557032451, 54.033235509554324],
            [-1.241611219062731, 54.03308434851408],
            [-1.244633411411067, 54.03338198764493],
            [-1.246972716653742, 54.034211851704093],
            [-1.24991095782146, 54.034599609362147],
            [-1.254450758587036, 54.036020047128147],
            [-1.258865215943251, 54.035919698414162],
            [-1.26643494322037, 54.035080796441164],
            [-1.267655928166328, 54.035282372938646],
            [-1.269624163158999, 54.036320748623126],
            [-1.27055427068564, 54.036481881464738],
            [-1.271735917570109, 54.03622929285379],
            [-1.273220145071295, 54.034647424838468],
            [-1.274174268007997, 54.034392538751945],
            [-1.275403314716955, 54.034482636219686],
            [-1.277534236493624, 54.035217174915026],
            [-1.278637475295042, 54.036491077827513],
            [-1.279006018960623, 54.038400501413712],
            [-1.280300481412019, 54.040899673543066],
            [-1.280105304218155, 54.043888748883724],
            [-1.281809081541951, 54.046755254974286],
            [-1.287047819313089, 54.049777550753859],
            [-1.288581648474313, 54.051725295944102],
            [-1.287904355611871, 54.05417495488819],
            [-1.28898761629749, 54.056756360391695],
            [-1.286632876535489, 54.059381234967148],
            [-1.287320391478501, 54.061521703039347],
            [-1.289829518494378, 54.063196578661803],
            [-1.292360156433173, 54.064246877680198],
            [-1.296059037258642, 54.065071179390088],
            [-1.298089679046035, 54.06624875348075],
            [-1.300454512809825, 54.069460367352583],
            [-1.30325583940545, 54.071997682416082],
            [-1.307479034253699, 54.07498082328901],
            [-1.309446429478217, 54.077992234767386],
            [-1.309841454513378, 54.080162342044225],
            [-1.311549529831308, 54.081318059868877],
            [-1.31306087023417, 54.081618796183243],
            [-1.317104857327422, 54.081651694793884],
            [-1.32158668958432, 54.081268098196098],
            [-1.325099153526224, 54.082499389411225],
            [-1.330147484044006, 54.081109328234263],
            [-1.333724603310084, 54.080704063628495],
            [-1.337617836247532, 54.081656679111049],
            [-1.33934318588927, 54.08341337277573],
            [-1.339959294198765, 54.084853887529171],
            [-1.342950446789885, 54.08768879633547],
            [-1.345138116701432, 54.090504879766314],
            [-1.344012093596637, 54.091814547629184],
            [-1.343890480610683, 54.093321121228826],
            [-1.345132215683727, 54.093987579209696],
            [-1.345228596863776, 54.094566910790959],
            [-1.345859923717041, 54.094965801327156],
            [-1.346127116174711, 54.096838489608032],
            [-1.346949101090198, 54.097270765232132],
            [-1.347234803989937, 54.099137259206039],
            [-1.345959839546432, 54.10115526424913],
            [-1.345540891487005, 54.1014423908723],
            [-1.343529065277859, 54.101107881155379],
            [-1.342107187380182, 54.100506932479533],
            [-1.331162692907088, 54.099065219053081],
            [-1.331382309055175, 54.099767478899068],
            [-1.330895423784354, 54.100659947825861],
            [-1.328190648605672, 54.101389065657187],
            [-1.327734763937394, 54.101777485384481],
            [-1.32849085909365, 54.102780237261278],
            [-1.331195192294299, 54.103224902059068],
            [-1.330669008178545, 54.105700781228911],
            [-1.330922160825262, 54.106886764745973],
            [-1.333432394222437, 54.109057730964096],
            [-1.334954283465313, 54.109154225990963],
            [-1.336069175865556, 54.10868493096298],
            [-1.336421013048766, 54.107628122392768],
            [-1.335472934911882, 54.106562347425516],
            [-1.335889067016348, 54.10617188194125],
            [-1.339346288617257, 54.106706794732538],
            [-1.340697340732833, 54.107541066683005],
            [-1.340592658970674, 54.108548011600213],
            [-1.338372656894205, 54.111472105193805],
            [-1.335402700039088, 54.111817053116255],
            [-1.334914215785623, 54.112232282565962],
            [-1.335256204559178, 54.113038567537309],
            [-1.336662865311708, 54.113750958297132],
            [-1.336849939711685, 54.114202270851962],
            [-1.334237036029998, 54.117360498007237],
            [-1.334512544352517, 54.120886981377041],
            [-1.332792923694118, 54.122120458805192],
            [-1.333194299679478, 54.122851580932362],
            [-1.336404277835444, 54.123623376716999],
            [-1.337407393511494, 54.124142095160224],
            [-1.337975840478816, 54.12486153725478],
            [-1.338038279199922, 54.1270890197261],
            [-1.340012175967064, 54.129020513079972],
            [-1.340528979618083, 54.133963849140464],
            [-1.340008181320025, 54.135821436542685],
            [-1.338796111275975, 54.136775566426962],
            [-1.334591072989185, 54.139007405040793],
            [-1.333076725167877, 54.141232455403092],
            [-1.333486217208694, 54.142995398196284],
            [-1.332008558627518, 54.145972002457697],
            [-1.332430680953384, 54.148282363482544],
            [-1.334731897683059, 54.149611780503626],
            [-1.336914580615498, 54.149265209363584],
            [-1.337569149219356, 54.148062672846656],
            [-1.338528668145736, 54.147896282252781],
            [-1.339465664098073, 54.148182736311782],
            [-1.33989423125172, 54.14963388886008],
            [-1.338119832659339, 54.150204752058798],
            [-1.337541956037078, 54.151301659160147],
            [-1.338071323237949, 54.15209458008146],
            [-1.339376294714592, 54.151874357153929],
            [-1.341248164500905, 54.151436126368353],
            [-1.343729911687405, 54.151447874774],
            [-1.34930987269869, 54.153070747863339],
            [-1.351593620258894, 54.154099559322312],
            [-1.352224129386984, 54.15529920059975],
            [-1.351648768156835, 54.155862324026948],
            [-1.350898522680122, 54.155661455595066],
            [-1.351144027630528, 54.154845805543765],
            [-1.350554559712625, 54.154352805019904],
            [-1.34901930848253, 54.154414621892315],
            [-1.347930840097114, 54.154789809358178],
            [-1.34761837584474, 54.157230946862555],
            [-1.348038358327261, 54.157595420561023],
            [-1.351559663312537, 54.157644983293061],
            [-1.351877934756139, 54.157954970417229],
            [-1.351172306443647, 54.158325954820683],
            [-1.347564666642744, 54.158991325905845],
            [-1.347379436420959, 54.159473855171527],
            [-1.34877671982469, 54.160408935761943],
            [-1.349077982484016, 54.161412680150832],
            [-1.351944814315336, 54.160634531083971],
            [-1.357706896930252, 54.162266975682172],
            [-1.359581533659142, 54.16375091840748],
            [-1.359324808473735, 54.165994650662611],
            [-1.360807885660045, 54.167059466647601],
            [-1.365056209155531, 54.167697578205704],
            [-1.368222616946237, 54.167673778831976],
            [-1.371735892199924, 54.168513613086496],
            [-1.373858985572997, 54.167593552930491],
            [-1.375524417825695, 54.167664210378142],
            [-1.378655377904866, 54.168574659594789],
            [-1.38025210520747, 54.169556235910143],
            [-1.381560765818841, 54.172295177423962],
            [-1.382896452323652, 54.173641170921059],
            [-1.385845814868534, 54.174441751309395],
            [-1.389266926448742, 54.174320731064789],
            [-1.3902844352455, 54.174613485400855],
            [-1.391297192639489, 54.175752833922736],
            [-1.391810716679635, 54.178343839286249],
            [-1.391663297849866, 54.178680127962529],
            [-1.389988022309319, 54.179142606336512],
            [-1.389468998215716, 54.179637887637625],
            [-1.391428110756891, 54.180089984223038],
            [-1.394048112434408, 54.181453114918014],
            [-1.39511715204233, 54.18158880482283],
            [-1.398391029478208, 54.180882595413003],
            [-1.401831160814476, 54.181707699284154],
            [-1.4068495642047, 54.181789125585745],
            [-1.410301503640899, 54.181262320996133],
            [-1.412231798745399, 54.181282536106025],
            [-1.413911751204794, 54.181669991570601],
            [-1.417731382175584, 54.184084580254861],
            [-1.41935259258706, 54.184629855768115],
            [-1.425879394211574, 54.184807621438168],
            [-1.430807258961778, 54.185598529400885],
            [-1.432169283780406, 54.186064216062739],
            [-1.435320878133796, 54.190039816962397],
            [-1.438217880327092, 54.192602209526761],
            [-1.438088414027554, 54.19435147138924],
            [-1.436463980603542, 54.1965898620467],
            [-1.436503470534198, 54.199434587086024],
            [-1.438249283152178, 54.201819026703625],
            [-1.438330991974536, 54.20540361458157],
            [-1.439592442414471, 54.208484999283378],
            [-1.443091999129041, 54.211050961587503],
            [-1.444005997151058, 54.213324515161773],
            [-1.445997380672595, 54.214049084434542],
            [-1.44561775203068, 54.214458066332774],
            [-1.44241521666358, 54.21553707152421],
            [-1.442617248619036, 54.21602153107419],
            [-1.443678624586549, 54.216120801611389],
            [-1.448838169632082, 54.21490333109675],
            [-1.449262749251552, 54.215147932713251],
            [-1.450082479440169, 54.214967431602872],
            [-1.461003262146563, 54.211232131629473],
            [-1.46335792880927, 54.211034126883355],
            [-1.467674493126144, 54.208981654982047],
            [-1.472822960598043, 54.207183419488238],
            [-1.474768806391209, 54.206195203652463],
            [-1.484619989079471, 54.220297678814362],
            [-1.488195634898303, 54.218998946601374],
            [-1.490727238683917, 54.218654662412291],
            [-1.49313203075175, 54.217766951093068],
            [-1.492570925324801, 54.217377232559862],
            [-1.492090892174392, 54.215879702007783],
            [-1.492099574117401, 54.2111703196476],
            [-1.495090531809415, 54.203288307646538],
            [-1.497116455750864, 54.203853099162949],
            [-1.501087015873085, 54.204235386031215],
            [-1.502726785966759, 54.202752041658364],
            [-1.50979627888487, 54.20151376510011],
            [-1.510006004509742, 54.200371413867657],
            [-1.514993674111027, 54.199620460440599],
            [-1.515144336545617, 54.199844854044308],
            [-1.51737816684425, 54.199677663823643],
            [-1.516885710780571, 54.199054658394182],
            [-1.520428720494969, 54.197680244069019],
            [-1.520271888669201, 54.196795254673376],
            [-1.524580936779101, 54.195555880021708],
            [-1.52603837576935, 54.195974143278185],
            [-1.528057693833386, 54.194836165668292],
            [-1.530179012870205, 54.195506827318034],
            [-1.533715811729957, 54.19557178829573],
            [-1.536502561532083, 54.195001047438311],
            [-1.539296848203232, 54.195400016733309],
            [-1.539921036151339, 54.194676214782994],
            [-1.540744108608117, 54.194536452879241],
            [-1.542688292065542, 54.19502557129109],
            [-1.545905700185835, 54.192623705472108],
            [-1.550006146962945, 54.191571373230829],
            [-1.551318380864256, 54.191822520786914],
            [-1.552807460770466, 54.191451480035681],
            [-1.553039806967103, 54.191081160127432],
            [-1.555160859544195, 54.191653417759767],
            [-1.557146017489595, 54.191753297801718],
            [-1.558314363894918, 54.190985562681867],
            [-1.560626167986173, 54.191063214485808],
            [-1.561562127200326, 54.192408447843299],
            [-1.561769271273275, 54.194415198186256],
            [-1.566082726840856, 54.195832841850745],
            [-1.5669272528421, 54.198621084682607],
            [-1.56805133508441, 54.199748546362628],
            [-1.56931302079792, 54.200326458248789],
            [-1.573131673671354, 54.200902652423238],
            [-1.574379456672923, 54.201351941550904],
            [-1.577247428680575, 54.201517514669732],
            [-1.578701964625614, 54.202444715832975],
            [-1.579335758949518, 54.204341477212623],
            [-1.580482789019248, 54.204765186246412],
            [-1.581918531075975, 54.204838477741212],
            [-1.596362210163476, 54.20178074736959],
            [-1.597858785261516, 54.201106299611304],
            [-1.598373736590843, 54.200485188027528],
            [-1.598497048761299, 54.199149169465315],
            [-1.597962624285558, 54.197987112412953],
            [-1.59696975233003, 54.197158751931205],
            [-1.593753790039707, 54.196552091828678],
            [-1.589562927996869, 54.197201169154823],
            [-1.588859842386946, 54.196690983852612],
            [-1.592872467940325, 54.192961337171759],
            [-1.594314918079378, 54.19089998911992],
            [-1.595352562613371, 54.190260875029935],
            [-1.596570538688814, 54.190142728100177],
            [-1.599661432559513, 54.190957407550052],
            [-1.602114942463032, 54.191128202858536],
            [-1.610369071868262, 54.188869659339751],
            [-1.612542298494827, 54.189178641424533],
            [-1.613770150987614, 54.188978566833725],
            [-1.61799810815602, 54.186499823693225],
            [-1.622193714600014, 54.185834494660455],
            [-1.624011012269466, 54.186181698800496],
            [-1.626965310569106, 54.188239111828203],
            [-1.630115958141073, 54.189449539777897],
            [-1.632086632086676, 54.189807876938758],
            [-1.635917661443278, 54.189311718014203],
            [-1.637849133781117, 54.189776792779533],
            [-1.640359314184305, 54.190667770408055],
            [-1.640637786294162, 54.192664706289563],
            [-1.639670862174223, 54.193175900536438],
            [-1.636605836278525, 54.193498336836129],
            [-1.634837812242981, 54.195686822915043],
            [-1.632880858536273, 54.19616799781241],
            [-1.63285149569289, 54.19671434312032],
            [-1.630885210776004, 54.197891082907489],
            [-1.631037138575015, 54.199592864695525],
            [-1.629904033520914, 54.199971356821109],
            [-1.629784502674658, 54.200482373402501],
            [-1.629739540798144, 54.201397153413701],
            [-1.630333792926808, 54.201628155652102],
            [-1.630453176370034, 54.202336729171222],
            [-1.628172826675877, 54.204649367627461],
            [-1.628043976045219, 54.206365563834851],
            [-1.624606628766147, 54.20992919987053],
            [-1.624834634455994, 54.210656090977572],
            [-1.62378466176021, 54.213153112448673],
            [-1.624380330840807, 54.215610324036049],
            [-1.622828898691337, 54.216236396260406],
            [-1.621979638123724, 54.218220848038712],
            [-1.621013908613918, 54.218846035122212],
            [-1.623085222716663, 54.220592483680626],
            [-1.624685040771931, 54.222767924837271],
            [-1.625824030353283, 54.225886487701693],
            [-1.625906410179587, 54.229161740473238],
            [-1.625377519146428, 54.230312279181085],
            [-1.625818740757071, 54.230375661610935],
            [-1.630884010190314, 54.230035369606419],
            [-1.634629519595236, 54.229224430052668],
            [-1.640949339508767, 54.233130441256144],
            [-1.643295592313138, 54.234129606356362],
            [-1.646797920396498, 54.23684240872511],
            [-1.647590262542618, 54.237845017880389],
            [-1.645557679252598, 54.238521202347535],
            [-1.636363567363158, 54.239452787158498],
            [-1.638305316286746, 54.243574828473953],
            [-1.64440703232351, 54.246547106068462],
            [-1.648620732204864, 54.250201118738019],
            [-1.655821138108697, 54.249906419316183],
            [-1.660390823849988, 54.247941258247799],
            [-1.664203496350732, 54.247303045165225],
            [-1.66542173167906, 54.250752169955376],
            [-1.665328975709528, 54.251783657187275],
            [-1.666551967478721, 54.252195067632663],
            [-1.667834787421856, 54.252230960403693],
            [-1.67097341124664, 54.251469346938833],
            [-1.672306201816825, 54.250813304820262],
            [-1.671969418635029, 54.249929834767144],
            [-1.669543131194992, 54.248568824235903],
            [-1.670089310205058, 54.247627548593123],
            [-1.672991173862895, 54.246870627276259],
            [-1.674165798639331, 54.246789324390875],
            [-1.675093569506168, 54.247070433482939],
            [-1.675960821076341, 54.247634473584561],
            [-1.677781826401336, 54.250867596192329],
            [-1.677997207327935, 54.253168025492997],
            [-1.679459681593478, 54.257359107823426],
            [-1.684162653692883, 54.259609348100689],
            [-1.684592152204939, 54.260256209189706],
            [-1.684462237264456, 54.261590034457775],
            [-1.684621912097537, 54.262996964219433],
            [-1.68692821783424, 54.264362747486743],
            [-1.69119498282154, 54.268029771320819],
            [-1.690768394601477, 54.269227583283744],
            [-1.694746283314195, 54.273657655778749],
            [-1.696156990856589, 54.274564436889122],
            [-1.697817718546559, 54.274469746124758],
            [-1.69981170952446, 54.275427373163417],
            [-1.70464502620202, 54.276857488846559],
            [-1.703553107692396, 54.27771579268137],
            [-1.702732886475619, 54.277305753304738],
            [-1.701656885446023, 54.278717695041372],
            [-1.70381755825489, 54.281746332933714],
            [-1.704618539989589, 54.283572710130827],
            [-1.704250305845732, 54.284160474162199],
            [-1.704509231127505, 54.284897164916828],
            [-1.705254289065249, 54.285531689478589],
            [-1.700661986259959, 54.286310369389504],
            [-1.696626006593201, 54.286403647321272],
            [-1.695361706774191, 54.287042153661602],
            [-1.693917606851871, 54.287270371945397],
            [-1.691464852327156, 54.286801283898789],
            [-1.687482962143889, 54.286639155946517],
            [-1.68431415959067, 54.285813069406132],
            [-1.679749095842511, 54.285400212104463],
            [-1.676069680265316, 54.285473076875036],
            [-1.67581570282304, 54.285148853399264],
            [-1.660200759948337, 54.290508151601166],
            [-1.660534464804914, 54.291401525784032],
            [-1.661644279161886, 54.29188815644487],
            [-1.660329458589425, 54.292979112213082],
            [-1.654256498608302, 54.293509194906591],
            [-1.65105373031551, 54.294355566252385],
            [-1.650763100079207, 54.29511235183422],
            [-1.651329515183651, 54.295538187870498],
            [-1.650132352295647, 54.29665992881673],
            [-1.649410449292252, 54.296631771309514],
            [-1.649056754892087, 54.297391065563374],
            [-1.650243069954834, 54.297556279206781],
            [-1.64838803162404, 54.2977926474396],
            [-1.648130791732326, 54.298228678562261],
            [-1.64847106696616, 54.298326732298349],
            [-1.648483851221588, 54.300435179403287],
            [-1.649828702197868, 54.300579289040726],
            [-1.647015316505551, 54.300919800874844],
            [-1.647732468371848, 54.302234034391127],
            [-1.64705374962811, 54.302535821033018],
            [-1.648036637398982, 54.304588681957057],
            [-1.647666871372353, 54.304699045230777],
            [-1.647908507320927, 54.305737777089917],
            [-1.649575578887049, 54.307772845670712],
            [-1.646928796060409, 54.307803779376613],
            [-1.644423301119219, 54.306954321350695],
            [-1.643101038924603, 54.307197568896143],
            [-1.641981109007896, 54.307500717961126],
            [-1.641738185573826, 54.309991260938631],
            [-1.640619812812711, 54.310105669915586],
            [-1.641092684235891, 54.313346975961736],
            [-1.640770427736008, 54.315404992963458],
            [-1.641578284874693, 54.316724023272634],
            [-1.642266767749321, 54.31710083257844],
            [-1.642425190236672, 54.319574586973673],
            [-1.643089938879059, 54.32074669042315],
            [-1.637784112666461, 54.321313262741924],
            [-1.637641252522755, 54.321825105907152],
            [-1.641624959868972, 54.323209343914684],
            [-1.639055605001975, 54.324820289372255],
            [-1.637390421273432, 54.326574997798083],
            [-1.637559166252974, 54.32714439521947],
            [-1.640179685132933, 54.329386453495985],
            [-1.640277996265019, 54.330632373857163],
            [-1.638939526458048, 54.335448218540066],
            [-1.638891103851269, 54.338343750023348],
            [-1.639826804078395, 54.340918679923355],
            [-1.643166205797287, 54.343391083051777],
            [-1.641890910400399, 54.343581431869801],
            [-1.641938840861488, 54.344970094725383],
            [-1.638662172954493, 54.345513048567668],
            [-1.638469163254021, 54.345244652767342],
            [-1.632647807487694, 54.346680316047141],
            [-1.632251826830756, 54.346233346457986],
            [-1.626362078267805, 54.346688894887038],
            [-1.619808992483976, 54.347830488704986],
            [-1.613474745647408, 54.349220474079097],
            [-1.608610327884991, 54.350828794390665],
            [-1.615226183000521, 54.357055701730836],
            [-1.612277391420784, 54.358426703284941],
            [-1.613102110440231, 54.359908636890999],
            [-1.612737935980585, 54.360179781340356],
            [-1.610452614028906, 54.361008240485518],
            [-1.606215874180366, 54.363767945393008],
            [-1.603901862392725, 54.364344546214788],
            [-1.602047380893348, 54.364821062383079],
            [-1.597770967315912, 54.364452804270982],
            [-1.596051198821898, 54.362326990821273],
            [-1.59438587054451, 54.362504748818303],
            [-1.594028420891094, 54.362859440272999],
            [-1.592771862066259, 54.362626936374276],
            [-1.591444535037692, 54.363014295366106],
            [-1.58933970609427, 54.361579095161275],
            [-1.588674409009821, 54.361782637979459],
            [-1.588467530115713, 54.362619538571685],
            [-1.587185684819824, 54.362610671098878],
            [-1.585912176533419, 54.362997254540765],
            [-1.584314440773317, 54.364400057981037],
            [-1.586425857237096, 54.36670892117413],
            [-1.586207536799466, 54.368218018689873],
            [-1.586932301211915, 54.368542239848246],
            [-1.585207596447307, 54.370918829082235],
            [-1.585412769932652, 54.371944070521664],
            [-1.585017939200497, 54.371715339562328],
            [-1.58237806247935, 54.373083076613419],
            [-1.583790989397164, 54.37508040704914],
            [-1.581959561685016, 54.374731672766821],
            [-1.584112451821013, 54.375412241677651],
            [-1.582544168402321, 54.376133894250543],
            [-1.581904293166263, 54.375626603006666],
            [-1.579657863913736, 54.375968408551962],
            [-1.579434459983037, 54.377041596983304],
            [-1.578119665121823, 54.377049594921964],
            [-1.57825657877633, 54.377510215631084],
            [-1.576406704751724, 54.379696611031179],
            [-1.576112909933296, 54.381016692843062],
            [-1.574863390936157, 54.381102174087104],
            [-1.575142747435445, 54.384914608963975],
            [-1.573481646927072, 54.386667542025855],
            [-1.574450515389356, 54.388573537437765],
            [-1.573678087235614, 54.389258331243056],
            [-1.574237112887879, 54.39029741883548],
            [-1.573629538418964, 54.39037705947711],
            [-1.572869033297021, 54.393766120868506],
            [-1.571884169103764, 54.39576137511169],
            [-1.567755655660024, 54.397013864236961],
            [-1.565767278084027, 54.398030370325621],
            [-1.565164002318835, 54.397982366617647],
            [-1.561617892310745, 54.400624354028594],
            [-1.560904268438447, 54.400809594238126],
            [-1.563597090027071, 54.402091926495402],
            [-1.564325378419631, 54.403431839098552],
            [-1.565748032043298, 54.403939339939505],
            [-1.569807543203113, 54.40420190340501],
            [-1.571341839727734, 54.405535652081369],
            [-1.574080260979152, 54.409616498544501],
            [-1.574541471402244, 54.41150002459451],
            [-1.576456440323866, 54.413314956993901],
            [-1.574097983214779, 54.414153255042763],
            [-1.574750785953025, 54.414799929202196],
            [-1.572863760151974, 54.415487080035696],
            [-1.574604702498098, 54.418494013822155],
            [-1.573425713386496, 54.418800812520267],
            [-1.571981292659598, 54.418549459772031],
            [-1.567332656047058, 54.420244053635308],
            [-1.564765496088217, 54.420651838823893],
            [-1.563500076497295, 54.421683488646288],
            [-1.5616913082745, 54.422210777215362],
            [-1.560775081013102, 54.423148400870247],
            [-1.558361607587074, 54.424290854609588],
            [-1.550636365039415, 54.421517721199152],
            [-1.548756413335798, 54.422197332543291],
            [-1.546555919973128, 54.423707019885043],
            [-1.544038141779584, 54.42420981366859],
            [-1.544592022728406, 54.42540988268162],
            [-1.542975852420885, 54.425767760640596],
            [-1.544256236862188, 54.426502347510336],
            [-1.547455916901945, 54.427213565033171],
            [-1.548406337391991, 54.428109538251789],
            [-1.549940648832135, 54.428052350717003],
            [-1.551373975558648, 54.429479437139285],
            [-1.549781290117628, 54.432468015216017],
            [-1.550620861348115, 54.433507350466471],
            [-1.549752774214016, 54.434648176790596],
            [-1.550792115730527, 54.437583630244362],
            [-1.54658037153381, 54.442164799380087],
            [-1.546148785878124, 54.444523186150178],
            [-1.533551881611689, 54.446444312624116],
            [-1.530078973037085, 54.447938896000217],
            [-1.527629422661446, 54.449602744214111],
            [-1.524548540508421, 54.449831522232898],
            [-1.522835286326425, 54.450615635737648],
            [-1.520155605614906, 54.449893254483641],
            [-1.514842359115822, 54.452139491078007],
            [-1.513207467460132, 54.450973587085088],
            [-1.510426858520705, 54.450320682690375],
            [-1.508409273576623, 54.448160090101602],
            [-1.505694005869157, 54.447353662441436],
            [-1.499039368266748, 54.448018278135592],
            [-1.494342139986182, 54.44763115204983],
            [-1.486173866028582, 54.447600308227059],
            [-1.482747173102341, 54.447851714422491],
            [-1.485016255852232, 54.44927956438999],
            [-1.484908306326616, 54.450508538763295],
            [-1.486329821580231, 54.453377872874832],
            [-1.48390584541083, 54.45210396598479],
            [-1.479827596467506, 54.451406166355149],
            [-1.477059971167857, 54.452436730357242],
            [-1.475616657076505, 54.453863021650278],
            [-1.472042401764482, 54.455588266813116],
            [-1.470716915499005, 54.454553449897112],
            [-1.466979262027338, 54.45328063467614],
            [-1.463720768839649, 54.451591030537898],
            [-1.461720970576093, 54.451138180989091],
            [-1.460348581400498, 54.451179694782269],
            [-1.457100482304183, 54.452002751269752],
            [-1.454906214229218, 54.453481142497324],
            [-1.454883505461468, 54.454726652192001],
            [-1.455776209087158, 54.45591607187518],
            [-1.457473513344334, 54.457153134265013],
            [-1.460955754745151, 54.458733361376979],
            [-1.461911919547013, 54.459573419528404],
            [-1.462790102319759, 54.461306439860401],
            [-1.461959500817658, 54.462886271217762],
            [-1.454772460165855, 54.465060408106069],
            [-1.453411970570375, 54.465968252605911],
            [-1.453474869875059, 54.466458332914087],
            [-1.454517322731092, 54.467192790830715],
            [-1.458705003386996, 54.468453622525026],
            [-1.459699886298038, 54.469062003188483],
            [-1.460181173578511, 54.471298339095121],
            [-1.463412754432204, 54.473030149903913],
            [-1.463788780830543, 54.473539587039191],
            [-1.462577750025464, 54.475871747316127],
            [-1.461362523565166, 54.478395300577461],
            [-1.457574729174907, 54.481471718029752],
            [-1.458103940097438, 54.482302696962719],
            [-1.461333782168254, 54.484695997750045],
            [-1.461817554570171, 54.485940161651861],
            [-1.461241445355418, 54.487611884059035],
            [-1.45839795794219, 54.491242533807785],
            [-1.458463462697438, 54.492828141315677],
            [-1.459893671726028, 54.495124433081799],
            [-1.462479125280503, 54.496348300146209],
            [-1.467056193511286, 54.496527621822878],
            [-1.472031029751141, 54.495915850825135],
            [-1.474020969314098, 54.496413413784865],
            [-1.475456316767273, 54.497903404167161],
            [-1.475551250885214, 54.498568855602919],
            [-1.474937727205361, 54.500297993704606],
            [-1.473155063184713, 54.501437886211129],
            [-1.467370780956791, 54.502497291833713],
            [-1.46210389566136, 54.504233648151242],
            [-1.459974032632658, 54.504258310489959],
            [-1.458107321974683, 54.503768259097981],
            [-1.456494094852505, 54.502798511872086],
            [-1.451888051095399, 54.497347765555205],
            [-1.446500496410422, 54.494414129092306],
            [-1.442881467005536, 54.490903350780805],
            [-1.434835107666399, 54.487451839569026],
            [-1.432240986930214, 54.486507732127954],
            [-1.426975211021052, 54.485222912655978],
            [-1.426572531947039, 54.484768055960338],
            [-1.426821143059601, 54.483987808728074],
            [-1.42798976639824, 54.482978251719949],
            [-1.433193321598768, 54.480929432770282],
            [-1.432952149234409, 54.479954103679177],
            [-1.431073422189802, 54.479209239157072],
            [-1.4231285577698, 54.478868739578246],
            [-1.417097751194899, 54.476991179628989],
            [-1.412458446083205, 54.477229355101215],
            [-1.411303955568465, 54.477561655243484],
            [-1.409455142038821, 54.47884678199928],
            [-1.408020075959139, 54.482894738336945],
            [-1.407673774114459, 54.483895551639854],
            [-1.408497842965061, 54.487223455050618],
            [-1.40993027118312, 54.489586862029874],
            [-1.408622662969973, 54.492053717229474],
            [-1.406658823550248, 54.493554825787122],
            [-1.405362104087517, 54.493971748877108],
            [-1.402347798419296, 54.494122255051082],
            [-1.399614088860048, 54.492927816158797],
            [-1.39817749721098, 54.490985760179399],
            [-1.398880874346225, 54.487867147577063],
            [-1.397179472258805, 54.486207752977407],
            [-1.395887996232649, 54.485649502286662],
            [-1.394762402448991, 54.48556748465267],
            [-1.39032202453218, 54.486470852526452],
            [-1.384812007766904, 54.486634376760229],
            [-1.383165804489277, 54.486989969358405],
            [-1.381783774071442, 54.488148538424163],
            [-1.382695604963233, 54.491460442756633],
            [-1.380914056831065, 54.493914696206794],
            [-1.379738836723594, 54.492610042274677],
            [-1.378393877457979, 54.492119632757223],
            [-1.377491896987638, 54.491230664845418],
            [-1.375748945185555, 54.490624040596138],
            [-1.373719401137196, 54.488368573755821],
            [-1.372691400300297, 54.488463901753271],
            [-1.371963869097776, 54.487990101226075],
            [-1.370286739479041, 54.48612734313032],
            [-1.37086418270602, 54.485313421155077],
            [-1.371918450673123, 54.484808433604684],
            [-1.37437236806751, 54.484221713005532],
            [-1.374931016529466, 54.479874845086492],
            [-1.373836613690246, 54.474427489718991],
            [-1.374331551267833, 54.474049896640715],
            [-1.364405227063525, 54.465941174588529],
            [-1.361812166551057, 54.467797737975189],
            [-1.361251935842616, 54.468699783657883],
            [-1.357324425551114, 54.471406566211726],
            [-1.356281597541756, 54.472962981674691],
            [-1.354375109799393, 54.472423476113278],
            [-1.353032876284491, 54.472408213900309],
            [-1.35090463239263, 54.47340516425416],
            [-1.349990190898115, 54.473171080539082],
            [-1.349795255523656, 54.472716182580136],
            [-1.347889436845369, 54.472528873529754],
            [-1.347818661712247, 54.472321787850689],
            [-1.346335071044362, 54.472656186859481],
            [-1.345344994472957, 54.472232929221995],
            [-1.34490260315325, 54.472396795308633],
            [-1.344718314323538, 54.471568980622664],
            [-1.343813163820864, 54.47104821068028],
            [-1.344668851779119, 54.470705049044874],
            [-1.344096591881476, 54.470499735776386],
            [-1.344891412829369, 54.47010231958518],
            [-1.344115152161195, 54.469531921963522],
            [-1.344776023352844, 54.469018744523801],
            [-1.343914357007526, 54.468773217008348],
            [-1.343739604348256, 54.468219559708366],
            [-1.34268691271119, 54.468438520223501],
            [-1.343096594994501, 54.468195397715306],
            [-1.342496105650408, 54.468117540682144],
            [-1.342783055296238, 54.467830613514295],
            [-1.342296821669369, 54.467651821753705],
            [-1.343235238180483, 54.467245311501159],
            [-1.343922830251145, 54.466217320570706],
            [-1.344709869962803, 54.466012188108415],
            [-1.344105721729658, 54.46548945561868],
            [-1.344023151973277, 54.464188568073517],
            [-1.343616573044205, 54.464144121994458],
            [-1.324118678603659, 54.470913669229084],
            [-1.323503894939636, 54.471421599899358],
            [-1.318300377600986, 54.472187738838208],
            [-1.316900149408229, 54.472968908362596],
            [-1.315552481631639, 54.475313223874011],
            [-1.313103149873762, 54.477265722760457],
            [-1.310785780006671, 54.477505076030759],
            [-1.30879223863256, 54.476476352604855],
            [-1.308358345975924, 54.47694839305958],
            [-1.307342116237193, 54.47708547205773],
            [-1.307283710462637, 54.477528203831149],
            [-1.30664146352044, 54.477816605345325],
            [-1.303174298501893, 54.47841768790947],
            [-1.302049393651868, 54.477954653451775],
            [-1.302309768573598, 54.476801307237743],
            [-1.301568010866445, 54.476406982955396],
            [-1.302812235344315, 54.476202964845086],
            [-1.303078129902612, 54.475903427999022],
            [-1.30041941520717, 54.475799102968963],
            [-1.299137818021147, 54.476564576936013],
            [-1.296936415435635, 54.479488799169054],
            [-1.296146780963855, 54.479636088603343],
            [-1.294582513236608, 54.479326802985241],
            [-1.293531450154526, 54.479598370197088],
            [-1.29157767578664, 54.483433440719828],
            [-1.290380045328678, 54.483426419167202],
            [-1.291036158143649, 54.482169370378955],
            [-1.290734705942783, 54.481929443268726],
            [-1.288597870032772, 54.481792869151171],
            [-1.286697163034299, 54.482722627786018],
            [-1.286645017785196, 54.483945471412326],
            [-1.287688475536114, 54.484831463774086],
            [-1.287813330412054, 54.4863519255968],
            [-1.289347873908717, 54.487319880797621],
            [-1.289578879273169, 54.48797460324225],
            [-1.287704997639174, 54.48796717615155],
            [-1.287419557123996, 54.487515238775721],
            [-1.285483231309159, 54.487814769605187],
            [-1.284002125664951, 54.489270914711469],
            [-1.281047574181631, 54.490408235247294],
            [-1.278853612560973, 54.490248682800008],
            [-1.277686343561993, 54.489733943319585],
            [-1.27670799519827, 54.490062418171405],
            [-1.276254852404069, 54.49072116133636],
            [-1.274505757439594, 54.491070167723095],
            [-1.272814118956086, 54.490267341912684],
            [-1.273091258723943, 54.489264244583225],
            [-1.27094183376967, 54.489122790232763],
            [-1.269286278963593, 54.488295866558154],
            [-1.268009882431679, 54.488542484353353],
            [-1.26711683685841, 54.488155121758481],
            [-1.265624023479675, 54.488159546927811],
            [-1.26422549510985, 54.487555197974615],
            [-1.260176898056122, 54.487789329180067],
            [-1.257390019443178, 54.487309409770447],
            [-1.256653315586504, 54.491161274775649],
            [-1.257212347303969, 54.493195806851688],
            [-1.254763678451172, 54.495238802136626],
            [-1.25429883806114, 54.495838975095673],
            [-1.254539849140712, 54.496594482879345],
            [-1.25331552163258, 54.497269055583736],
            [-1.253249261643638, 54.49777012956546],
            [-1.253704669229945, 54.49879208317595],
            [-1.255164086012195, 54.499457140888723],
            [-1.256661476868045, 54.501095721911369],
            [-1.25162978015484, 54.501393624689449],
            [-1.251495465928745, 54.501138457342165],
            [-1.249199671096732, 54.501370476609956],
            [-1.248491529930555, 54.501167462996612],
            [-1.246082597803224, 54.502176108627062],
            [-1.244735905259586, 54.501346281762395],
            [-1.242307558257207, 54.501645644931187],
            [-1.23849950888317, 54.506219557711177],
            [-1.234366938032131, 54.506129662569229],
            [-1.234852661683684, 54.510303671394581],
            [-1.233391446229126, 54.510556844830575],
            [-1.231714997421907, 54.511414367526676],
            [-1.230428614677735, 54.510990984682827],
            [-1.230591283773219, 54.511869164897227],
            [-1.227598823223792, 54.511984003530515],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000165",
        LAD13CDO: "36UD",
        LAD13NM: "Harrogate",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.684162653692883, 54.259609348100689],
            [-1.679459681593478, 54.257359107823426],
            [-1.677997207327935, 54.253168025492997],
            [-1.677781826401336, 54.250867596192329],
            [-1.675960821076341, 54.247634473584561],
            [-1.675093569506168, 54.247070433482939],
            [-1.674165798639331, 54.246789324390875],
            [-1.672991173862895, 54.246870627276259],
            [-1.670089310205058, 54.247627548593123],
            [-1.669543131194992, 54.248568824235903],
            [-1.671969418635029, 54.249929834767144],
            [-1.672306201816825, 54.250813304820262],
            [-1.67097341124664, 54.251469346938833],
            [-1.667834787421856, 54.252230960403693],
            [-1.666551967478721, 54.252195067632663],
            [-1.665328975709528, 54.251783657187275],
            [-1.66542173167906, 54.250752169955376],
            [-1.664203496350732, 54.247303045165225],
            [-1.660390823849988, 54.247941258247799],
            [-1.655821138108697, 54.249906419316183],
            [-1.648620732204864, 54.250201118738019],
            [-1.64440703232351, 54.246547106068462],
            [-1.638305316286746, 54.243574828473953],
            [-1.636363567363158, 54.239452787158498],
            [-1.645557679252598, 54.238521202347535],
            [-1.647590262542618, 54.237845017880389],
            [-1.646797920396498, 54.23684240872511],
            [-1.643295592313138, 54.234129606356362],
            [-1.640949339508767, 54.233130441256144],
            [-1.634629519595236, 54.229224430052668],
            [-1.630884010190314, 54.230035369606419],
            [-1.625818740757071, 54.230375661610935],
            [-1.625377519146428, 54.230312279181085],
            [-1.625906410179587, 54.229161740473238],
            [-1.625824030353283, 54.225886487701693],
            [-1.624685040771931, 54.222767924837271],
            [-1.623085222716663, 54.220592483680626],
            [-1.621013908613918, 54.218846035122212],
            [-1.621979638123724, 54.218220848038712],
            [-1.622828898691337, 54.216236396260406],
            [-1.624380330840807, 54.215610324036049],
            [-1.62378466176021, 54.213153112448673],
            [-1.624834634455994, 54.210656090977572],
            [-1.624606628766147, 54.20992919987053],
            [-1.628043976045219, 54.206365563834851],
            [-1.628172826675877, 54.204649367627461],
            [-1.630453176370034, 54.202336729171222],
            [-1.630333792926808, 54.201628155652102],
            [-1.629739540798144, 54.201397153413701],
            [-1.629784502674658, 54.200482373402501],
            [-1.629904033520914, 54.199971356821109],
            [-1.631037138575015, 54.199592864695525],
            [-1.630885210776004, 54.197891082907489],
            [-1.63285149569289, 54.19671434312032],
            [-1.632880858536273, 54.19616799781241],
            [-1.634837812242981, 54.195686822915043],
            [-1.636605836278525, 54.193498336836129],
            [-1.639670862174223, 54.193175900536438],
            [-1.640637786294162, 54.192664706289563],
            [-1.640359314184305, 54.190667770408055],
            [-1.637849133781117, 54.189776792779533],
            [-1.635917661443278, 54.189311718014203],
            [-1.632086632086676, 54.189807876938758],
            [-1.630115958141073, 54.189449539777897],
            [-1.626965310569106, 54.188239111828203],
            [-1.624011012269466, 54.186181698800496],
            [-1.622193714600014, 54.185834494660455],
            [-1.61799810815602, 54.186499823693225],
            [-1.613770150987614, 54.188978566833725],
            [-1.612542298494827, 54.189178641424533],
            [-1.610369071868262, 54.188869659339751],
            [-1.602114942463032, 54.191128202858536],
            [-1.599661432559513, 54.190957407550052],
            [-1.596570538688814, 54.190142728100177],
            [-1.595352562613371, 54.190260875029935],
            [-1.594314918079378, 54.19089998911992],
            [-1.592872467940325, 54.192961337171759],
            [-1.588859842386946, 54.196690983852612],
            [-1.589562927996869, 54.197201169154823],
            [-1.593753790039707, 54.196552091828678],
            [-1.59696975233003, 54.197158751931205],
            [-1.597962624285558, 54.197987112412953],
            [-1.598497048761299, 54.199149169465315],
            [-1.598373736590843, 54.200485188027528],
            [-1.597858785261516, 54.201106299611304],
            [-1.596362210163476, 54.20178074736959],
            [-1.581918531075975, 54.204838477741212],
            [-1.580482789019248, 54.204765186246412],
            [-1.579335758949518, 54.204341477212623],
            [-1.578701964625614, 54.202444715832975],
            [-1.577247428680575, 54.201517514669732],
            [-1.574379456672923, 54.201351941550904],
            [-1.573131673671354, 54.200902652423238],
            [-1.56931302079792, 54.200326458248789],
            [-1.56805133508441, 54.199748546362628],
            [-1.5669272528421, 54.198621084682607],
            [-1.566082726840856, 54.195832841850745],
            [-1.561769271273275, 54.194415198186256],
            [-1.561562127200326, 54.192408447843299],
            [-1.560626167986173, 54.191063214485808],
            [-1.558314363894918, 54.190985562681867],
            [-1.557146017489595, 54.191753297801718],
            [-1.555160859544195, 54.191653417759767],
            [-1.553039806967103, 54.191081160127432],
            [-1.552807460770466, 54.191451480035681],
            [-1.551318380864256, 54.191822520786914],
            [-1.550006146962945, 54.191571373230829],
            [-1.545905700185835, 54.192623705472108],
            [-1.542688292065542, 54.19502557129109],
            [-1.540744108608117, 54.194536452879241],
            [-1.539921036151339, 54.194676214782994],
            [-1.539296848203232, 54.195400016733309],
            [-1.536502561532083, 54.195001047438311],
            [-1.533715811729957, 54.19557178829573],
            [-1.530179012870205, 54.195506827318034],
            [-1.528057693833386, 54.194836165668292],
            [-1.52603837576935, 54.195974143278185],
            [-1.524580936779101, 54.195555880021708],
            [-1.520271888669201, 54.196795254673376],
            [-1.520428720494969, 54.197680244069019],
            [-1.516885710780571, 54.199054658394182],
            [-1.51737816684425, 54.199677663823643],
            [-1.515144336545617, 54.199844854044308],
            [-1.514993674111027, 54.199620460440599],
            [-1.510006004509742, 54.200371413867657],
            [-1.50979627888487, 54.20151376510011],
            [-1.502726785966759, 54.202752041658364],
            [-1.501087015873085, 54.204235386031215],
            [-1.497116455750864, 54.203853099162949],
            [-1.495090531809415, 54.203288307646538],
            [-1.492099574117401, 54.2111703196476],
            [-1.492090892174392, 54.215879702007783],
            [-1.492570925324801, 54.217377232559862],
            [-1.49313203075175, 54.217766951093068],
            [-1.490727238683917, 54.218654662412291],
            [-1.488195634898303, 54.218998946601374],
            [-1.484619989079471, 54.220297678814362],
            [-1.474768806391209, 54.206195203652463],
            [-1.472822960598043, 54.207183419488238],
            [-1.467674493126144, 54.208981654982047],
            [-1.46335792880927, 54.211034126883355],
            [-1.461003262146563, 54.211232131629473],
            [-1.450082479440169, 54.214967431602872],
            [-1.449262749251552, 54.215147932713251],
            [-1.448838169632082, 54.21490333109675],
            [-1.443678624586549, 54.216120801611389],
            [-1.442617248619036, 54.21602153107419],
            [-1.44241521666358, 54.21553707152421],
            [-1.44561775203068, 54.214458066332774],
            [-1.445997380672595, 54.214049084434542],
            [-1.444005997151058, 54.213324515161773],
            [-1.443091999129041, 54.211050961587503],
            [-1.439592442414471, 54.208484999283378],
            [-1.438330991974536, 54.20540361458157],
            [-1.438249283152178, 54.201819026703625],
            [-1.436503470534198, 54.199434587086024],
            [-1.436463980603542, 54.1965898620467],
            [-1.438088414027554, 54.19435147138924],
            [-1.438217880327092, 54.192602209526761],
            [-1.435320878133796, 54.190039816962397],
            [-1.432169283780406, 54.186064216062739],
            [-1.430807258961778, 54.185598529400885],
            [-1.425879394211574, 54.184807621438168],
            [-1.41935259258706, 54.184629855768115],
            [-1.417731382175584, 54.184084580254861],
            [-1.413911751204794, 54.181669991570601],
            [-1.412231798745399, 54.181282536106025],
            [-1.410301503640899, 54.181262320996133],
            [-1.4068495642047, 54.181789125585745],
            [-1.401831160814476, 54.181707699284154],
            [-1.398391029478208, 54.180882595413003],
            [-1.39511715204233, 54.18158880482283],
            [-1.394048112434408, 54.181453114918014],
            [-1.391428110756891, 54.180089984223038],
            [-1.389468998215716, 54.179637887637625],
            [-1.389988022309319, 54.179142606336512],
            [-1.391663297849866, 54.178680127962529],
            [-1.391810716679635, 54.178343839286249],
            [-1.391297192639489, 54.175752833922736],
            [-1.3902844352455, 54.174613485400855],
            [-1.389266926448742, 54.174320731064789],
            [-1.385845814868534, 54.174441751309395],
            [-1.382896452323652, 54.173641170921059],
            [-1.381560765818841, 54.172295177423962],
            [-1.38025210520747, 54.169556235910143],
            [-1.378655377904866, 54.168574659594789],
            [-1.375524417825695, 54.167664210378142],
            [-1.373858985572997, 54.167593552930491],
            [-1.371735892199924, 54.168513613086496],
            [-1.368222616946237, 54.167673778831976],
            [-1.365056209155531, 54.167697578205704],
            [-1.360807885660045, 54.167059466647601],
            [-1.359324808473735, 54.165994650662611],
            [-1.359581533659142, 54.16375091840748],
            [-1.357706896930252, 54.162266975682172],
            [-1.351944814315336, 54.160634531083971],
            [-1.349077982484016, 54.161412680150832],
            [-1.34877671982469, 54.160408935761943],
            [-1.347379436420959, 54.159473855171527],
            [-1.347564666642744, 54.158991325905845],
            [-1.351172306443647, 54.158325954820683],
            [-1.351877934756139, 54.157954970417229],
            [-1.351559663312537, 54.157644983293061],
            [-1.348038358327261, 54.157595420561023],
            [-1.34761837584474, 54.157230946862555],
            [-1.347930840097114, 54.154789809358178],
            [-1.34901930848253, 54.154414621892315],
            [-1.350554559712625, 54.154352805019904],
            [-1.351144027630528, 54.154845805543765],
            [-1.350898522680122, 54.155661455595066],
            [-1.351648768156835, 54.155862324026948],
            [-1.352224129386984, 54.15529920059975],
            [-1.351593620258894, 54.154099559322312],
            [-1.34930987269869, 54.153070747863339],
            [-1.343729911687405, 54.151447874774],
            [-1.341248164500905, 54.151436126368353],
            [-1.339376294714592, 54.151874357153929],
            [-1.338071323237949, 54.15209458008146],
            [-1.337541956037078, 54.151301659160147],
            [-1.338119832659339, 54.150204752058798],
            [-1.33989423125172, 54.14963388886008],
            [-1.339465664098073, 54.148182736311782],
            [-1.338528668145736, 54.147896282252781],
            [-1.337569149219356, 54.148062672846656],
            [-1.336914580615498, 54.149265209363584],
            [-1.334731897683059, 54.149611780503626],
            [-1.332430680953384, 54.148282363482544],
            [-1.332008558627518, 54.145972002457697],
            [-1.333486217208694, 54.142995398196284],
            [-1.333076725167877, 54.141232455403092],
            [-1.334591072989185, 54.139007405040793],
            [-1.338796111275975, 54.136775566426962],
            [-1.340008181320025, 54.135821436542685],
            [-1.340528979618083, 54.133963849140464],
            [-1.340012175967064, 54.129020513079972],
            [-1.338038279199922, 54.1270890197261],
            [-1.337975840478816, 54.12486153725478],
            [-1.337407393511494, 54.124142095160224],
            [-1.336404277835444, 54.123623376716999],
            [-1.333194299679478, 54.122851580932362],
            [-1.332792923694118, 54.122120458805192],
            [-1.334512544352517, 54.120886981377041],
            [-1.334237036029998, 54.117360498007237],
            [-1.336849939711685, 54.114202270851962],
            [-1.336662865311708, 54.113750958297132],
            [-1.335256204559178, 54.113038567537309],
            [-1.334914215785623, 54.112232282565962],
            [-1.335402700039088, 54.111817053116255],
            [-1.338372656894205, 54.111472105193805],
            [-1.340592658970674, 54.108548011600213],
            [-1.340697340732833, 54.107541066683005],
            [-1.339346288617257, 54.106706794732538],
            [-1.335889067016348, 54.10617188194125],
            [-1.335472934911882, 54.106562347425516],
            [-1.336421013048766, 54.107628122392768],
            [-1.336069175865556, 54.10868493096298],
            [-1.334954283465313, 54.109154225990963],
            [-1.333432394222437, 54.109057730964096],
            [-1.330922160825262, 54.106886764745973],
            [-1.330669008178545, 54.105700781228911],
            [-1.331195192294299, 54.103224902059068],
            [-1.32849085909365, 54.102780237261278],
            [-1.327734763937394, 54.101777485384481],
            [-1.328190648605672, 54.101389065657187],
            [-1.330895423784354, 54.100659947825861],
            [-1.331382309055175, 54.099767478899068],
            [-1.331162692907088, 54.099065219053081],
            [-1.342107187380182, 54.100506932479533],
            [-1.343529065277859, 54.101107881155379],
            [-1.345540891487005, 54.1014423908723],
            [-1.345959839546432, 54.10115526424913],
            [-1.347234803989937, 54.099137259206039],
            [-1.346949101090198, 54.097270765232132],
            [-1.346127116174711, 54.096838489608032],
            [-1.345859923717041, 54.094965801327156],
            [-1.345228596863776, 54.094566910790959],
            [-1.345132215683727, 54.093987579209696],
            [-1.343890480610683, 54.093321121228826],
            [-1.344012093596637, 54.091814547629184],
            [-1.345138116701432, 54.090504879766314],
            [-1.342950446789885, 54.08768879633547],
            [-1.339959294198765, 54.084853887529171],
            [-1.33934318588927, 54.08341337277573],
            [-1.337617836247532, 54.081656679111049],
            [-1.333724603310084, 54.080704063628495],
            [-1.330147484044006, 54.081109328234263],
            [-1.325099153526224, 54.082499389411225],
            [-1.32158668958432, 54.081268098196098],
            [-1.317104857327422, 54.081651694793884],
            [-1.31306087023417, 54.081618796183243],
            [-1.311549529831308, 54.081318059868877],
            [-1.309841454513378, 54.080162342044225],
            [-1.309446429478217, 54.077992234767386],
            [-1.307479034253699, 54.07498082328901],
            [-1.30325583940545, 54.071997682416082],
            [-1.300454512809825, 54.069460367352583],
            [-1.298089679046035, 54.06624875348075],
            [-1.296059037258642, 54.065071179390088],
            [-1.292360156433173, 54.064246877680198],
            [-1.289829518494378, 54.063196578661803],
            [-1.287320391478501, 54.061521703039347],
            [-1.286632876535489, 54.059381234967148],
            [-1.28898761629749, 54.056756360391695],
            [-1.287904355611871, 54.05417495488819],
            [-1.288581648474313, 54.051725295944102],
            [-1.287047819313089, 54.049777550753859],
            [-1.281809081541951, 54.046755254974286],
            [-1.280105304218155, 54.043888748883724],
            [-1.280300481412019, 54.040899673543066],
            [-1.279006018960623, 54.038400501413712],
            [-1.278637475295042, 54.036491077827513],
            [-1.277534236493624, 54.035217174915026],
            [-1.275403314716955, 54.034482636219686],
            [-1.274174268007997, 54.034392538751945],
            [-1.273220145071295, 54.034647424838468],
            [-1.271735917570109, 54.03622929285379],
            [-1.27055427068564, 54.036481881464738],
            [-1.269624163158999, 54.036320748623126],
            [-1.267655928166328, 54.035282372938646],
            [-1.26643494322037, 54.035080796441164],
            [-1.258865215943251, 54.035919698414162],
            [-1.254450758587036, 54.036020047128147],
            [-1.24991095782146, 54.034599609362147],
            [-1.246972716653742, 54.034211851704093],
            [-1.244633411411067, 54.03338198764493],
            [-1.241611219062731, 54.03308434851408],
            [-1.238933557032451, 54.033235509554324],
            [-1.237531730315449, 54.035085318451486],
            [-1.236283723986036, 54.035616672155939],
            [-1.233341279868094, 54.035045202551871],
            [-1.230373578623149, 54.034853685066146],
            [-1.227681886132597, 54.035094384696066],
            [-1.224066383246167, 54.034516566816421],
            [-1.222905589810329, 54.033473672610498],
            [-1.223578198098314, 54.031790096503144],
            [-1.223239113769089, 54.030569153652593],
            [-1.221298923001523, 54.027614881219051],
            [-1.218412906748179, 54.025784208050943],
            [-1.217616651364167, 54.024126161582622],
            [-1.218812514248363, 54.02132073001146],
            [-1.218407731209796, 54.018567809327322],
            [-1.21932853342489, 54.015851360413166],
            [-1.218427491925256, 54.014418231842292],
            [-1.217239167308341, 54.01439162858226],
            [-1.208700934067776, 54.016229500478687],
            [-1.206772642975011, 54.016188934979667],
            [-1.205488720180956, 54.015490189498188],
            [-1.203877772887088, 54.013537251626602],
            [-1.202075055856971, 54.012523153260062],
            [-1.19925886742727, 54.011842924932346],
            [-1.195914095975055, 54.012385946214344],
            [-1.194308520778719, 54.012159490286763],
            [-1.192685817299306, 54.011005345719227],
            [-1.190735049455324, 54.008203284504113],
            [-1.186596250914761, 54.007062655798379],
            [-1.184659033064687, 54.006174112765642],
            [-1.180443775018867, 54.003041027407598],
            [-1.175502344124483, 54.00217945738266],
            [-1.176093614080994, 54.001750291841354],
            [-1.175131943883006, 54.001622361421077],
            [-1.175069781572239, 54.001371171571989],
            [-1.176321842320702, 54.001472330711536],
            [-1.176011105616384, 54.000829361924609],
            [-1.177479247308783, 54.000416082179171],
            [-1.177597416902876, 54.000612827461325],
            [-1.178197827090881, 54.000492000278456],
            [-1.177955494930805, 54.000247669528839],
            [-1.178618629082501, 54.000040086098927],
            [-1.178814672692192, 53.999148024371806],
            [-1.179507148903205, 53.998843566756328],
            [-1.180758562990225, 53.998974335702314],
            [-1.182247906543447, 54.00050972630941],
            [-1.183432117532497, 54.001037276654934],
            [-1.18438570689506, 54.000954768277488],
            [-1.188562404051438, 54.002466034621932],
            [-1.188999219727618, 54.002362924080671],
            [-1.18884790338266, 54.001906215620643],
            [-1.189285031002507, 54.001629639201859],
            [-1.190985674997066, 54.001258207916358],
            [-1.191360866780372, 54.000555179600688],
            [-1.191927881532223, 54.000502367596496],
            [-1.191921095883634, 53.999907321323136],
            [-1.190460790675039, 53.999059823555527],
            [-1.190356458326964, 53.998618712563371],
            [-1.189515133251129, 53.998422500312444],
            [-1.189396665932183, 53.998002864156909],
            [-1.186625126497844, 53.998145031316533],
            [-1.185904554164564, 53.997859731162407],
            [-1.185552776808431, 53.997203027263254],
            [-1.184155467435394, 53.997046151989437],
            [-1.183954314929075, 53.996489332476294],
            [-1.183275076064705, 53.99619890409604],
            [-1.183153719686625, 53.994839103393666],
            [-1.181623636895445, 53.992667098364294],
            [-1.180613721552466, 53.989800255254359],
            [-1.18209535636127, 53.985328052952084],
            [-1.185743072001392, 53.98377183366334],
            [-1.186441815374736, 53.98438235239518],
            [-1.187409077413513, 53.98396915691648],
            [-1.192211831049681, 53.984174991681094],
            [-1.19503280036725, 53.985295835805239],
            [-1.197286035523499, 53.986842447267925],
            [-1.197531249620558, 53.98836709077613],
            [-1.200309927013369, 53.989010716616178],
            [-1.201984877897432, 53.988195854695618],
            [-1.202248084612767, 53.987111858695066],
            [-1.203386985047847, 53.986650240237076],
            [-1.204863178647072, 53.986814605153555],
            [-1.207169971415879, 53.986527845165682],
            [-1.210827822424347, 53.985440104414828],
            [-1.213282612250274, 53.985224304680635],
            [-1.214630530421074, 53.985559379704043],
            [-1.216512159040235, 53.985573454600441],
            [-1.217450705573081, 53.984399452118595],
            [-1.217345637898511, 53.983251907947782],
            [-1.218998191100387, 53.982788991042334],
            [-1.220735401679115, 53.981464657471427],
            [-1.222098863245345, 53.979324472745411],
            [-1.222380209033528, 53.976497788872415],
            [-1.22350744507196, 53.975487640662656],
            [-1.223692311143679, 53.974646664554058],
            [-1.221601099433855, 53.972651299332497],
            [-1.221469706251743, 53.970457389071115],
            [-1.220885826889122, 53.969149454242711],
            [-1.219179765932444, 53.96720327711926],
            [-1.217478622710656, 53.965979738623396],
            [-1.219812782150067, 53.963752416507326],
            [-1.219913185803864, 53.962619686255728],
            [-1.218344191453787, 53.961103109759364],
            [-1.216039526697415, 53.959649125386719],
            [-1.213029560916588, 53.956648143269263],
            [-1.211824819549679, 53.954184732731235],
            [-1.208991348518714, 53.951234235948711],
            [-1.207375263894008, 53.945492844235233],
            [-1.20681440332944, 53.945502625454971],
            [-1.205415943352551, 53.944129907137594],
            [-1.204125867598027, 53.943471087782882],
            [-1.203436246370036, 53.942083714069334],
            [-1.203873036024409, 53.941226456822847],
            [-1.20334331355691, 53.940884098166848],
            [-1.20267445208174, 53.93897060902669],
            [-1.20179204844095, 53.938272673680856],
            [-1.199889440120065, 53.934529094665287],
            [-1.199752853981296, 53.93291034171839],
            [-1.200143978725795, 53.932369170959902],
            [-1.197727627920333, 53.929308816657333],
            [-1.197985629490399, 53.928640931229999],
            [-1.200066634245765, 53.928448080360475],
            [-1.20010778139165, 53.926454809834169],
            [-1.199488098047249, 53.925333470236616],
            [-1.197603482265085, 53.923869330410149],
            [-1.196048450165109, 53.923486829526325],
            [-1.195631502689666, 53.922362331544832],
            [-1.198804978261899, 53.922436567294262],
            [-1.20211886739474, 53.923053630178352],
            [-1.205123016557849, 53.92442983833589],
            [-1.20513571150781, 53.925605556349758],
            [-1.214300910121953, 53.926298601907909],
            [-1.217602373766143, 53.925979501619345],
            [-1.217962261877948, 53.92497518672149],
            [-1.218754995772016, 53.925173586747412],
            [-1.221782083260387, 53.925026056057888],
            [-1.222207618839997, 53.925481807769508],
            [-1.223589677148158, 53.925764881757253],
            [-1.226833323820778, 53.927909666969136],
            [-1.226611840438206, 53.928522122449102],
            [-1.228029372427885, 53.929200845450318],
            [-1.227185987584635, 53.929924350827903],
            [-1.227673880322377, 53.931793397769603],
            [-1.228149109993137, 53.931626578381],
            [-1.229195745265056, 53.9320242756],
            [-1.233545550578958, 53.93452470035438],
            [-1.23446005362876, 53.934491882078895],
            [-1.235271911714427, 53.934991391617594],
            [-1.236121462424824, 53.934845797346945],
            [-1.238741869276348, 53.933058537870764],
            [-1.238678205755471, 53.932623115572383],
            [-1.24134704961326, 53.932518658779131],
            [-1.244941507248532, 53.931593067832388],
            [-1.251896037139255, 53.935584093616036],
            [-1.255787409451768, 53.935436588213818],
            [-1.262567156619336, 53.934314445669671],
            [-1.26544587500805, 53.935011569709388],
            [-1.269487647488782, 53.934967901733607],
            [-1.270850486562756, 53.934641827072802],
            [-1.268929021692605, 53.931459194005051],
            [-1.265956490246991, 53.930390375813097],
            [-1.262691888138188, 53.927754883162514],
            [-1.262428370454645, 53.92706658349411],
            [-1.263427428548571, 53.925853042551765],
            [-1.26260375808889, 53.925064237258916],
            [-1.261953762385873, 53.923236582356751],
            [-1.263268577328122, 53.921727478928027],
            [-1.263760481707041, 53.920338253874839],
            [-1.260836835721596, 53.92000212593485],
            [-1.260800901465776, 53.919795180846137],
            [-1.266584752247823, 53.917658230840587],
            [-1.270264788589618, 53.91695800618831],
            [-1.269093253555954, 53.916213868113502],
            [-1.267547768699675, 53.914133619311649],
            [-1.267985441819462, 53.911130697952117],
            [-1.269264953763501, 53.907510028608783],
            [-1.270124680953589, 53.906654203978874],
            [-1.269226765204385, 53.902021713234241],
            [-1.268537693341123, 53.901999542826132],
            [-1.268134112956157, 53.900881670825513],
            [-1.266784421094666, 53.899474902472761],
            [-1.269203973779183, 53.898268175888205],
            [-1.270333171183954, 53.896958295329071],
            [-1.272094178632325, 53.895803233793409],
            [-1.273532787071284, 53.893577520040601],
            [-1.275577338131732, 53.893347192947161],
            [-1.275519496025446, 53.892908228165837],
            [-1.278547462332837, 53.891412871400206],
            [-1.278571346498013, 53.892058355461231],
            [-1.279243016430192, 53.892552235861331],
            [-1.281681888357279, 53.892659424220483],
            [-1.281243619589264, 53.893903441419646],
            [-1.281707615537266, 53.894886810822918],
            [-1.282547486569209, 53.895071591309886],
            [-1.284045892822248, 53.894566417981061],
            [-1.284555325289926, 53.894675512383245],
            [-1.283477431177541, 53.897019454209882],
            [-1.283704020154883, 53.897384820548552],
            [-1.286840984019423, 53.898070398724442],
            [-1.289028036922998, 53.899405501603802],
            [-1.291646402653021, 53.89972296924342],
            [-1.291468593659013, 53.900877778572863],
            [-1.288775417295774, 53.901018252909942],
            [-1.286964059456797, 53.902194829793572],
            [-1.286162077409772, 53.902099287358936],
            [-1.285447192387958, 53.900913113081742],
            [-1.283853418985836, 53.90084160372858],
            [-1.282140636428213, 53.901498289953317],
            [-1.281954192336117, 53.901898040919313],
            [-1.282451205870015, 53.902203907121283],
            [-1.285125814397781, 53.902800480769471],
            [-1.287732437999125, 53.904087769193573],
            [-1.289186682846347, 53.904406469998492],
            [-1.296719396053826, 53.902503109885018],
            [-1.297675644968766, 53.902840362295429],
            [-1.296603564514017, 53.904415977324426],
            [-1.297270672980158, 53.904843216812353],
            [-1.303865038304932, 53.904434911515288],
            [-1.306645363952065, 53.905292268788507],
            [-1.308476826593247, 53.905453824699613],
            [-1.313717857314877, 53.90468485037028],
            [-1.313884933604617, 53.904985104895843],
            [-1.310841889631163, 53.905933893685322],
            [-1.306105857276669, 53.906436021361046],
            [-1.302726284301793, 53.907375470145411],
            [-1.300583034735065, 53.907468169571722],
            [-1.300697338873221, 53.908553685838498],
            [-1.301658307204642, 53.908709375775686],
            [-1.301467692242748, 53.909459663537561],
            [-1.302009145365058, 53.909670433476272],
            [-1.301189695870342, 53.914533562895272],
            [-1.300107988803001, 53.917130185241859],
            [-1.300076771689073, 53.91908848389761],
            [-1.297198435403714, 53.921664711489569],
            [-1.298446490816517, 53.921970406637811],
            [-1.300326335024197, 53.920645756209801],
            [-1.301415246465693, 53.921180585764247],
            [-1.300929810708205, 53.922347996139656],
            [-1.305303328033107, 53.921139316385791],
            [-1.307373175829644, 53.921107226097647],
            [-1.307304624928467, 53.921477135222005],
            [-1.305760374079442, 53.923144477870039],
            [-1.305784061234686, 53.923644345728491],
            [-1.304238121401986, 53.924946747418446],
            [-1.297083791162652, 53.926406094558267],
            [-1.294254807121517, 53.926556686616358],
            [-1.294174963480686, 53.927046960701986],
            [-1.296755349128484, 53.928642176239066],
            [-1.300414518561741, 53.929399651871542],
            [-1.302217763701052, 53.927387846316471],
            [-1.303813860787224, 53.927016015645179],
            [-1.305271276887114, 53.927107140871186],
            [-1.30659133172801, 53.924275468813711],
            [-1.308986849705943, 53.924080749706135],
            [-1.306956537890471, 53.934407004539118],
            [-1.308614824591287, 53.934341058853811],
            [-1.312328654364425, 53.935244986872583],
            [-1.315391499060778, 53.935076425842183],
            [-1.31637360886739, 53.935470296511085],
            [-1.320576117673991, 53.93603250972415],
            [-1.321101147368465, 53.937000781867305],
            [-1.322809941807501, 53.936548444032944],
            [-1.325561712030387, 53.9379157058451],
            [-1.329205985981875, 53.937974752473814],
            [-1.331097638762463, 53.938467949131201],
            [-1.332060610335156, 53.939025164491973],
            [-1.332452954796292, 53.93973559342249],
            [-1.335028815508937, 53.939455969756459],
            [-1.336267507246684, 53.938482235053485],
            [-1.337522748805635, 53.940115077480733],
            [-1.339533548453959, 53.940793049792518],
            [-1.342045254006915, 53.94052550613322],
            [-1.344706140738073, 53.939484863617956],
            [-1.343928715517707, 53.939906649210506],
            [-1.342852418521751, 53.943255059815201],
            [-1.34155910738018, 53.943924770802916],
            [-1.339834788893655, 53.94554751646028],
            [-1.340399491629117, 53.945871485751994],
            [-1.34278654578861, 53.9449264519046],
            [-1.348202504016662, 53.944940668822284],
            [-1.352383407770974, 53.94423165597258],
            [-1.354865220381651, 53.943638320663652],
            [-1.358554610454201, 53.941684324751634],
            [-1.361947534409509, 53.941466903908555],
            [-1.366272253442067, 53.941885255739699],
            [-1.370374415235377, 53.94302671077822],
            [-1.371794489141659, 53.942810343653939],
            [-1.373515151321773, 53.941747967970493],
            [-1.373586978722203, 53.941325910955818],
            [-1.374187389944458, 53.941516886289008],
            [-1.374963656963776, 53.940950194393942],
            [-1.38167670883898, 53.940470814160427],
            [-1.386466157537911, 53.943173754462826],
            [-1.389675470027316, 53.942800021258172],
            [-1.390106727216549, 53.943000843048182],
            [-1.391436299586589, 53.942819736211021],
            [-1.391435030990978, 53.942488076571408],
            [-1.396650979518771, 53.941877134844155],
            [-1.397176453754733, 53.942518810796315],
            [-1.399896544632807, 53.941405342935084],
            [-1.399730112899116, 53.940157891315906],
            [-1.400441107116966, 53.940080549572784],
            [-1.400651968241011, 53.937484100268755],
            [-1.402868789824638, 53.936490289426224],
            [-1.4076640510643, 53.936157202128385],
            [-1.407842029137755, 53.935635882640447],
            [-1.409191167972511, 53.935449283460557],
            [-1.408464259452699, 53.934935195040254],
            [-1.410577734861437, 53.931307279540199],
            [-1.41062312526943, 53.930139075155104],
            [-1.408800103889346, 53.929599833484296],
            [-1.406703643776961, 53.927833263877346],
            [-1.413613905634238, 53.928318377793126],
            [-1.418829854313354, 53.928104640324406],
            [-1.4281077291484, 53.929423638780918],
            [-1.429778182834349, 53.928882417042253],
            [-1.432338091211058, 53.927434903644979],
            [-1.433546875930427, 53.926338692152832],
            [-1.433147640583842, 53.924895150770027],
            [-1.431464758230372, 53.923227134751919],
            [-1.432111216743471, 53.920809749622435],
            [-1.431502182020023, 53.91710744676012],
            [-1.430902079130832, 53.916228284414956],
            [-1.431277176096325, 53.916074569682792],
            [-1.430129675307966, 53.913804177989888],
            [-1.431394624757182, 53.913255616938578],
            [-1.432428965566283, 53.911186096495484],
            [-1.434401990483881, 53.910162689552322],
            [-1.436429593730352, 53.913334167042322],
            [-1.438056872040079, 53.91402576945643],
            [-1.440439771293014, 53.913886793129315],
            [-1.442870441790784, 53.912902228687457],
            [-1.445510560730627, 53.909657223011109],
            [-1.447221430147253, 53.909090779448533],
            [-1.450921958106431, 53.90711692615136],
            [-1.454896864589094, 53.907424438404817],
            [-1.457050674343741, 53.907311057278328],
            [-1.46055029859885, 53.906824398284634],
            [-1.463093733693517, 53.90599811876217],
            [-1.465242709567101, 53.906971207674609],
            [-1.464954958560254, 53.909002092616092],
            [-1.465508941885677, 53.909606748233003],
            [-1.466637328889215, 53.910411686498051],
            [-1.468289152459658, 53.910998728029831],
            [-1.470971774311353, 53.911333236033627],
            [-1.473453110644372, 53.911213811872884],
            [-1.4754262718069, 53.911557691912058],
            [-1.481610808303278, 53.910746863458655],
            [-1.486020552129121, 53.910896133730901],
            [-1.489238272101101, 53.911927285424689],
            [-1.49186419125525, 53.914444243314037],
            [-1.493430027142284, 53.915133934586244],
            [-1.498774959776043, 53.915291168492921],
            [-1.500957145497616, 53.914864339471869],
            [-1.504354904340906, 53.913475395220736],
            [-1.506325130418452, 53.912161388904067],
            [-1.507719801947742, 53.911754567377336],
            [-1.510278742383158, 53.911370456576975],
            [-1.5163679518392, 53.911766320913571],
            [-1.521442479672327, 53.911310282116709],
            [-1.526466477271661, 53.909793260168009],
            [-1.528590320816271, 53.908683519608026],
            [-1.532557198795537, 53.908955172919626],
            [-1.536288233238368, 53.910184791484347],
            [-1.543508773937697, 53.910002125019609],
            [-1.548787420881858, 53.910898381317587],
            [-1.549410817726729, 53.910684113061521],
            [-1.549099318077713, 53.908651685566845],
            [-1.550478658683424, 53.906928485867354],
            [-1.550230455672114, 53.904101768400011],
            [-1.551221939124717, 53.903047602513283],
            [-1.552571018941841, 53.90299241538559],
            [-1.555213053535242, 53.904440285975411],
            [-1.557041195412522, 53.904856887027137],
            [-1.55930569271312, 53.904888582853182],
            [-1.561557850740177, 53.903918042914711],
            [-1.562877484343646, 53.90391476218705],
            [-1.566205948978209, 53.909962179873489],
            [-1.567733410023316, 53.910784681458303],
            [-1.568927364818574, 53.910885140891615],
            [-1.573062559743481, 53.910221321354477],
            [-1.582894418328266, 53.909344483852415],
            [-1.584312244944943, 53.908973704645469],
            [-1.585473538427165, 53.907143294155169],
            [-1.58624108960417, 53.904483734420239],
            [-1.585178668815964, 53.902488359685549],
            [-1.585652809691461, 53.901483354327482],
            [-1.586853007636996, 53.901236725782631],
            [-1.592933567790521, 53.901973813731409],
            [-1.594469874990457, 53.902692645439409],
            [-1.598298134190021, 53.903437117457209],
            [-1.599242800451253, 53.903966960726642],
            [-1.601080667629569, 53.905323952019991],
            [-1.601770998832534, 53.906837999665257],
            [-1.601313293644518, 53.907909631921591],
            [-1.598889886052046, 53.908874951474132],
            [-1.599059609720253, 53.909431866097904],
            [-1.599988068000086, 53.909761219543917],
            [-1.607181939096169, 53.908974247688811],
            [-1.608624092968524, 53.908419010313473],
            [-1.612030274816704, 53.906169611100637],
            [-1.620532159614618, 53.903379967286426],
            [-1.623064861961767, 53.903539833761322],
            [-1.626071097254604, 53.904874934913707],
            [-1.627921084705898, 53.905312094459632],
            [-1.631155429405927, 53.905146802836391],
            [-1.637922311478006, 53.905526897200019],
            [-1.641076127686657, 53.907119118296336],
            [-1.642255345295796, 53.907349128299536],
            [-1.645787127334894, 53.907122311471809],
            [-1.648601131098407, 53.9059037325285],
            [-1.652024430713905, 53.905707872581843],
            [-1.653613113178555, 53.90685841225352],
            [-1.654412380583545, 53.908316743438547],
            [-1.653947496195668, 53.909313055651943],
            [-1.652407357808971, 53.910371871914549],
            [-1.652257554344627, 53.910996991885739],
            [-1.653208945773195, 53.91173674346242],
            [-1.655078398691481, 53.912469239146262],
            [-1.66267859914582, 53.91313793534465],
            [-1.665190873442861, 53.913090134624873],
            [-1.666635838422294, 53.912867658086306],
            [-1.669393097134271, 53.911481262327669],
            [-1.670504708992721, 53.911245238835413],
            [-1.680016972135271, 53.910317332700629],
            [-1.684622914486066, 53.91057307755144],
            [-1.68456672525262, 53.911970536986601],
            [-1.688194689870762, 53.911727445958668],
            [-1.689166832628231, 53.912630543934199],
            [-1.687628483920059, 53.914369294225367],
            [-1.687015203256625, 53.916373779952785],
            [-1.690161242316663, 53.91670369482069],
            [-1.690319068860442, 53.917390770663964],
            [-1.696342545997534, 53.917123022653143],
            [-1.696439648915896, 53.917589735444068],
            [-1.696912565013611, 53.91767092038701],
            [-1.697677007653086, 53.916808217881993],
            [-1.698608975114005, 53.916779999780118],
            [-1.700774330543361, 53.917366921238454],
            [-1.702239008384087, 53.918434715305452],
            [-1.703711227893363, 53.918662149674837],
            [-1.704771253947406, 53.918183910029278],
            [-1.707078996175025, 53.919116198961241],
            [-1.706108348136374, 53.917055621240181],
            [-1.708030204786669, 53.916989301059942],
            [-1.708953291031365, 53.918019745151128],
            [-1.712484276293404, 53.917542901018301],
            [-1.713192646358945, 53.917929271478648],
            [-1.71642859796892, 53.917619679139342],
            [-1.716084648801879, 53.917149703650495],
            [-1.717532199393046, 53.913362953813404],
            [-1.717579277218026, 53.911799184542048],
            [-1.718202234740411, 53.910617849861389],
            [-1.718698037081724, 53.910459028055477],
            [-1.717954474939141, 53.908527600616381],
            [-1.720544778927095, 53.90996630947518],
            [-1.721561430149405, 53.911600856454804],
            [-1.722309655273041, 53.91194322555986],
            [-1.723571611377766, 53.911718742587084],
            [-1.725648101564059, 53.910546096084964],
            [-1.727212312294888, 53.910182054424176],
            [-1.729013849531492, 53.910306566109128],
            [-1.733693940283003, 53.911875512762812],
            [-1.738697572529351, 53.912884140633757],
            [-1.7421597006523, 53.914565146305407],
            [-1.745993484193382, 53.918523447473014],
            [-1.746067452175236, 53.920889192266564],
            [-1.745038315716143, 53.922362810217898],
            [-1.745622273510134, 53.922988698874981],
            [-1.7497268619003, 53.922587469912223],
            [-1.754478851750232, 53.921089116123326],
            [-1.755502434341065, 53.921026489913437],
            [-1.757231543436933, 53.921394898196731],
            [-1.762425237943227, 53.924094419833523],
            [-1.765421626924798, 53.924707877388798],
            [-1.767823943328322, 53.92399621346491],
            [-1.768959691232787, 53.921551924945405],
            [-1.772276694319545, 53.920872484416023],
            [-1.775975008350788, 53.921473524734601],
            [-1.777532691795863, 53.922906373913854],
            [-1.779925457246421, 53.925403089754525],
            [-1.780975621879099, 53.927841594567234],
            [-1.782440025726528, 53.929426101722271],
            [-1.785083912076959, 53.929713071906988],
            [-1.794138774440335, 53.929223799676983],
            [-1.798006268949877, 53.929699519306901],
            [-1.798492972175906, 53.930149724400749],
            [-1.797197724697962, 53.930298541864907],
            [-1.796361756613517, 53.930894816176178],
            [-1.796269647162579, 53.933549647427526],
            [-1.797384434246257, 53.934204042502074],
            [-1.797040744837251, 53.934722057151653],
            [-1.79743277415226, 53.935231425580632],
            [-1.797172045993234, 53.935601282099974],
            [-1.797840591886662, 53.936258514423976],
            [-1.797492280462987, 53.936470039704936],
            [-1.799033952867275, 53.936747651024227],
            [-1.802859485179096, 53.935942386957684],
            [-1.803535661831222, 53.937915408214863],
            [-1.805085819508455, 53.93900814531419],
            [-1.806222325444319, 53.938651369982345],
            [-1.811308054357818, 53.938764611960032],
            [-1.815064850008741, 53.938335438718376],
            [-1.817050172382984, 53.93856945980199],
            [-1.820153844633098, 53.938039368279718],
            [-1.823389519185507, 53.938336264084583],
            [-1.824015729323371, 53.939026542629861],
            [-1.825936848688564, 53.938234819925107],
            [-1.830196842721344, 53.938002732396541],
            [-1.830121744854182, 53.940369102059357],
            [-1.835562094324279, 53.939468897487465],
            [-1.838434312811562, 53.939662430172078],
            [-1.841719718319167, 53.939363010804797],
            [-1.845020373687523, 53.93986972256991],
            [-1.847686008786821, 53.940758419590949],
            [-1.847735071597326, 53.938576255716981],
            [-1.848653731672984, 53.936012309502978],
            [-1.845495934903091, 53.935249729513586],
            [-1.836995814414375, 53.932349847596484],
            [-1.836331427466962, 53.932046057960314],
            [-1.83619685643139, 53.931405945976387],
            [-1.839889770386214, 53.931511580200876],
            [-1.843330993884711, 53.932948759845353],
            [-1.847150662439947, 53.932586073323563],
            [-1.84998019342408, 53.93139785712615],
            [-1.852226670037147, 53.930958439033326],
            [-1.854618940369264, 53.931502416454165],
            [-1.855865342248948, 53.93222203801389],
            [-1.859204612826757, 53.932451584089158],
            [-1.860828255834864, 53.932890277870406],
            [-1.862976848154522, 53.934428751284116],
            [-1.862490260483915, 53.937430106445632],
            [-1.86173223534393, 53.939110846274204],
            [-1.862103737761823, 53.940554705002825],
            [-1.864345354567668, 53.941258296661211],
            [-1.865100073470066, 53.942003330277984],
            [-1.866394914134408, 53.942497302941796],
            [-1.872010674171445, 53.943852465304815],
            [-1.874147482231088, 53.945073455851357],
            [-1.875012999248561, 53.946040988897344],
            [-1.876293507285327, 53.948890974442286],
            [-1.876269680621395, 53.951283484245835],
            [-1.877696088516647, 53.953307180614033],
            [-1.878878860670796, 53.954260176446823],
            [-1.875990513718605, 53.95499512850337],
            [-1.875044731114741, 53.954812598616897],
            [-1.873322201098856, 53.954996844478053],
            [-1.872093891620311, 53.954519196356664],
            [-1.870401184664358, 53.954422116205585],
            [-1.865204497376142, 53.955947918092086],
            [-1.863983025572424, 53.956669157189928],
            [-1.863439470755141, 53.95745317002779],
            [-1.861564939013219, 53.958391144662528],
            [-1.861149918565455, 53.958999135378633],
            [-1.861182485595403, 53.959748748768433],
            [-1.862465264816123, 53.960355993390074],
            [-1.863159643896567, 53.961937724088038],
            [-1.861678228053201, 53.961926144595701],
            [-1.860132954683701, 53.962324312501821],
            [-1.849895336166424, 53.965708438989616],
            [-1.824885247235905, 53.97624598968293],
            [-1.816729268760503, 53.978124858118569],
            [-1.817742113717156, 53.980125259784202],
            [-1.822198536080339, 53.983482536974478],
            [-1.825486961285975, 53.984775285230185],
            [-1.826755161488383, 53.985648922684035],
            [-1.824891959747867, 53.986295137450384],
            [-1.824500241998723, 53.987334441925626],
            [-1.823261581249388, 53.987769430959354],
            [-1.823158578478206, 53.98904553040007],
            [-1.821629755570594, 53.98991328205463],
            [-1.821617757562715, 53.990937859605452],
            [-1.8201218786255, 53.991215150316961],
            [-1.818760353722821, 53.992511827284275],
            [-1.819522957262173, 53.996401939010049],
            [-1.821071768478506, 53.997344361221515],
            [-1.822764688332238, 53.997415171829644],
            [-1.822884554281062, 53.99792674650967],
            [-1.823847633090874, 53.998164535210918],
            [-1.826156613688651, 53.999764999650893],
            [-1.827478829618433, 54.001340641314791],
            [-1.829767911311957, 54.003034478455064],
            [-1.830971502655472, 54.003444213851139],
            [-1.830639374079397, 54.004459352326087],
            [-1.831403994309006, 54.009311960063627],
            [-1.833556455013629, 54.011359644268957],
            [-1.834909071841915, 54.013514945659431],
            [-1.835934850242449, 54.014216484831714],
            [-1.83736208987303, 54.014899683967556],
            [-1.84804143875927, 54.013656261965586],
            [-1.853465344365958, 54.028759501381195],
            [-1.872416700437558, 54.035595465830511],
            [-1.86866641551199, 54.042035545597635],
            [-1.870358433797005, 54.044482002887683],
            [-1.872435263358888, 54.046011210502627],
            [-1.873521053014056, 54.046028532512715],
            [-1.87202254315877, 54.04712972758081],
            [-1.867319087033097, 54.048906879420379],
            [-1.866649259555143, 54.049655704605904],
            [-1.8639837521955, 54.050217145886144],
            [-1.859677885939372, 54.052105892439364],
            [-1.853720457723176, 54.053108113131728],
            [-1.852780125118268, 54.057703214477257],
            [-1.850004586813505, 54.06190327808941],
            [-1.849080471866581, 54.063115445702572],
            [-1.846063989517179, 54.064503803836324],
            [-1.842213241950626, 54.067861069865579],
            [-1.840583228770898, 54.070561482926351],
            [-1.842392695209807, 54.071319716395095],
            [-1.846266711067275, 54.073752258313391],
            [-1.861720551895977, 54.080526016001151],
            [-1.867108756266102, 54.097675778051041],
            [-1.871320340609115, 54.099513797739696],
            [-1.87596279060038, 54.100063304889218],
            [-1.879765798160108, 54.102212473440446],
            [-1.887341147715966, 54.102450765334012],
            [-1.890997127745143, 54.104403512893512],
            [-1.893227166658689, 54.109677571283449],
            [-1.894258308692968, 54.110792029294473],
            [-1.89510581498785, 54.113782905714423],
            [-1.90055754440617, 54.11638759973647],
            [-1.903087916716119, 54.119632339767577],
            [-1.905611978970937, 54.12246000116388],
            [-1.916956149571452, 54.120596246633845],
            [-1.92269389511857, 54.120351102700575],
            [-1.93174018202134, 54.122354467215324],
            [-1.934939545548064, 54.122280737164516],
            [-1.937722538903908, 54.123364294118822],
            [-1.94726028270311, 54.125020725794812],
            [-1.949602731342273, 54.126408489596322],
            [-1.957140110232507, 54.132550724207576],
            [-1.957377294144458, 54.135575983454977],
            [-1.955288451530977, 54.136740003480966],
            [-1.954225414020064, 54.143093727117915],
            [-1.963582822580681, 54.152117583064985],
            [-1.966607432497622, 54.153188855076849],
            [-1.972488417038621, 54.158072294249422],
            [-1.98688333105481, 54.156610641308333],
            [-1.988413430088791, 54.15529683468138],
            [-1.992540081640554, 54.155267493552785],
            [-1.997569864222156, 54.160663731895553],
            [-1.997990945351643, 54.162081951701204],
            [-1.997747292241213, 54.165182602465585],
            [-1.999111904192624, 54.16737554300861],
            [-2.004504011374094, 54.172872140943305],
            [-2.003778058190008, 54.174454846757811],
            [-2.001919926000653, 54.176349435889982],
            [-1.99489525222215, 54.18020674288173],
            [-1.980187735851015, 54.187706116221044],
            [-1.969919449372449, 54.191320528346459],
            [-1.942158673342886, 54.199101600088596],
            [-1.937730863181979, 54.199743795900908],
            [-1.928006913837384, 54.200309096108413],
            [-1.924401861410617, 54.200145123632524],
            [-1.91237307802007, 54.204630676908984],
            [-1.895610165787118, 54.21034499080595],
            [-1.886443753832841, 54.209999696118672],
            [-1.881604800475273, 54.218606713265309],
            [-1.879440719366127, 54.220265430525743],
            [-1.862160624071748, 54.222788558767768],
            [-1.846694544712106, 54.227360635662947],
            [-1.841083144155709, 54.229472589337774],
            [-1.837400114274777, 54.231419729972281],
            [-1.82806000672155, 54.231627876406506],
            [-1.822568580030697, 54.235458411121989],
            [-1.818483240558365, 54.239261158280897],
            [-1.81629816481722, 54.240686834289455],
            [-1.823327378299734, 54.247475545522256],
            [-1.799801953686003, 54.245136279223061],
            [-1.755957079486953, 54.239352240417013],
            [-1.741224806850959, 54.246890662158904],
            [-1.738798573988195, 54.24859302342859],
            [-1.737151181392757, 54.25039140118276],
            [-1.739491740216743, 54.251843432081003],
            [-1.737116088208159, 54.254224408694334],
            [-1.730580809476695, 54.254887638301831],
            [-1.726126318955716, 54.255840151261118],
            [-1.72618309973473, 54.256073949859818],
            [-1.724246891925626, 54.256167502787235],
            [-1.724216878186875, 54.255373855701421],
            [-1.722986342525362, 54.254843482830466],
            [-1.719682820805648, 54.255127043040467],
            [-1.719031473245545, 54.256110536430867],
            [-1.718135772237776, 54.25623067498811],
            [-1.718285337445753, 54.25680800884323],
            [-1.717303095628222, 54.257235304784508],
            [-1.715495576742689, 54.25646174423057],
            [-1.712663813641267, 54.256423579417692],
            [-1.709280554666543, 54.255575163532299],
            [-1.70800815971761, 54.256434872521872],
            [-1.70757698765946, 54.257932910269993],
            [-1.706780830395262, 54.258075673937917],
            [-1.706975716572295, 54.259168104136613],
            [-1.704563063760727, 54.260819479424775],
            [-1.702656814566933, 54.258416087681788],
            [-1.702232957032569, 54.257162212741918],
            [-1.701125230634347, 54.256449477355815],
            [-1.698815384052597, 54.256409582593484],
            [-1.697087101381112, 54.25723478477375],
            [-1.695204855734827, 54.257487980879766],
            [-1.692101770861078, 54.258629579286925],
            [-1.691064730394321, 54.258541549424343],
            [-1.689539465295738, 54.259282682346473],
            [-1.687358783390857, 54.259194369097045],
            [-1.685431844452179, 54.260258401010063],
            [-1.685176054929236, 54.261594595014458],
            [-1.684462237264456, 54.261590034457775],
            [-1.684592152204939, 54.260256209189706],
            [-1.684162653692883, 54.259609348100689],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000166",
        LAD13CDO: "36UE",
        LAD13NM: "Richmondshire",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.683620794765446, 54.533469100676442],
            [-1.682249610731247, 54.533328908875355],
            [-1.679284864042556, 54.533624848425859],
            [-1.677402034751392, 54.534483486676294],
            [-1.673234754094104, 54.535371898944511],
            [-1.665267943486611, 54.535579299802549],
            [-1.660631784872302, 54.535189841479237],
            [-1.657927777603272, 54.534578311644317],
            [-1.655570701866645, 54.532966567768298],
            [-1.655025594672797, 54.531819185296797],
            [-1.654969493002796, 54.530408081085191],
            [-1.656908738159466, 54.528198319789169],
            [-1.657024465122023, 54.52637250729164],
            [-1.656320862497191, 54.525522147030102],
            [-1.654665829329813, 54.524639415091954],
            [-1.652496279470534, 54.524133533506991],
            [-1.646304445307216, 54.524251301602582],
            [-1.640037866752471, 54.525661748349926],
            [-1.636752392077413, 54.525912554035393],
            [-1.63433069234873, 54.525631161265217],
            [-1.632284246621122, 54.523998328560317],
            [-1.631727241839276, 54.522541651853878],
            [-1.632670800962996, 54.520691414575055],
            [-1.632921981982907, 54.518693483269992],
            [-1.633745858347871, 54.517946469041931],
            [-1.635083952257002, 54.517563174064229],
            [-1.639619041337296, 54.517773576417483],
            [-1.640182795161756, 54.517079665209536],
            [-1.639317513986854, 54.516403968428016],
            [-1.633319943122651, 54.514100568251131],
            [-1.628548399820468, 54.513782258811936],
            [-1.623568436405536, 54.514637706211644],
            [-1.618712296319579, 54.516439663783238],
            [-1.614286932750606, 54.519531527047533],
            [-1.612098287376047, 54.519981068565215],
            [-1.609511469413804, 54.51989725979648],
            [-1.603946015328497, 54.519354337924085],
            [-1.600614315710187, 54.517086767603288],
            [-1.60043938842514, 54.51633308411877],
            [-1.60105519201262, 54.514801942419417],
            [-1.603678436624881, 54.512043479760145],
            [-1.603585939535896, 54.511227161511961],
            [-1.602628528672621, 54.510570669922252],
            [-1.601340429081587, 54.510570028108212],
            [-1.594644582265756, 54.512335289787586],
            [-1.590418670156005, 54.512026288443465],
            [-1.583853368500723, 54.513034693365519],
            [-1.581059715527712, 54.512834530598766],
            [-1.579687039087003, 54.511887038532279],
            [-1.579554781888055, 54.511078651247217],
            [-1.581296361736495, 54.509477820884101],
            [-1.58434358506072, 54.507953352923693],
            [-1.587504608998186, 54.506760815204885],
            [-1.591348390227589, 54.505841909018997],
            [-1.591919913215248, 54.505363933834808],
            [-1.591871927354832, 54.504289829456916],
            [-1.590807043078366, 54.503605919491768],
            [-1.589502330464226, 54.50358981718896],
            [-1.581119585342692, 54.505317142072521],
            [-1.579901747876406, 54.505408181932737],
            [-1.579106475634042, 54.505103456025523],
            [-1.578736153441591, 54.503871850820708],
            [-1.580210029170546, 54.500489782320038],
            [-1.583516117645901, 54.497740407870992],
            [-1.58292855296892, 54.497064360290565],
            [-1.580296191549547, 54.496297654745732],
            [-1.576990171979831, 54.494392582997698],
            [-1.57485048886467, 54.492443893008677],
            [-1.574182271977437, 54.491081810910693],
            [-1.573295061395308, 54.490148530911831],
            [-1.568074207803598, 54.488681299297525],
            [-1.56437266280087, 54.487212132300641],
            [-1.556653680410557, 54.485583503205511],
            [-1.555186275119341, 54.484966999600019],
            [-1.554271680377961, 54.482912796849945],
            [-1.554317526778933, 54.480540393696828],
            [-1.553705316637266, 54.478791958686379],
            [-1.551119547952023, 54.474488390148366],
            [-1.547839702121156, 54.472310298924981],
            [-1.545663645700208, 54.471227294912133],
            [-1.542740470267963, 54.471152470543224],
            [-1.533699138737106, 54.469189330440308],
            [-1.531331351402435, 54.469270048355675],
            [-1.527393605401805, 54.469953916627759],
            [-1.522407259093551, 54.470174282406532],
            [-1.520139159223583, 54.470827649672728],
            [-1.519450744960618, 54.471638243865158],
            [-1.519537142740095, 54.472561556551071],
            [-1.520629462018444, 54.473500543399446],
            [-1.524464839049672, 54.474631874550738],
            [-1.528588068192652, 54.474770239538742],
            [-1.530141715431734, 54.475739695281511],
            [-1.530263315094409, 54.47778831233466],
            [-1.531299328915112, 54.480581905084641],
            [-1.530603610727068, 54.483518862950405],
            [-1.5291498983252, 54.484707588213062],
            [-1.526399683277813, 54.485212706662161],
            [-1.523661966667669, 54.484901790194129],
            [-1.518065232909733, 54.485024312199904],
            [-1.515929809223782, 54.484561940489378],
            [-1.514122546369126, 54.483763835977875],
            [-1.512925630735463, 54.482914241329283],
            [-1.511991302409993, 54.481143624733186],
            [-1.512194751924598, 54.480128910510793],
            [-1.51436336900394, 54.477895371658114],
            [-1.514300447534082, 54.476562341166002],
            [-1.513119019302764, 54.475335353455925],
            [-1.511331382142351, 54.4750010155444],
            [-1.509732476463781, 54.475533767342554],
            [-1.506859220656739, 54.47847432949348],
            [-1.50622176891503, 54.481556978925575],
            [-1.505414775128898, 54.482307694073015],
            [-1.501344986938693, 54.484318459359116],
            [-1.496884414851003, 54.485814303398406],
            [-1.494304762357152, 54.486343666855831],
            [-1.491765934499253, 54.486189233550292],
            [-1.490522389450751, 54.485148695518156],
            [-1.491309747091685, 54.483270123497086],
            [-1.493229639592154, 54.481411574336725],
            [-1.499338899705849, 54.478084850353873],
            [-1.499578772402958, 54.477144899500693],
            [-1.499042799690432, 54.475667007660348],
            [-1.49834441030075, 54.474981097198373],
            [-1.496669820273102, 54.474373804686223],
            [-1.494603335646751, 54.474016489235943],
            [-1.490704539054061, 54.473962388221352],
            [-1.487016391567854, 54.472982489598749],
            [-1.483921674111635, 54.473416878684112],
            [-1.479080059699045, 54.475311263493765],
            [-1.477614909467239, 54.475001181331038],
            [-1.475362937088618, 54.47383389992445],
            [-1.474578669257122, 54.472778110196316],
            [-1.473953241107456, 54.467881933367387],
            [-1.472877663589279, 54.466575023048279],
            [-1.467971226656502, 54.463139345441633],
            [-1.467609321298242, 54.462139287858022],
            [-1.467834537986834, 54.460831759951525],
            [-1.46884272911462, 54.459335349688168],
            [-1.472195898211995, 54.45744566940256],
            [-1.472042401764482, 54.455588266813116],
            [-1.475616657076505, 54.453863021650278],
            [-1.477059971167857, 54.452436730357242],
            [-1.479827596467506, 54.451406166355149],
            [-1.48390584541083, 54.45210396598479],
            [-1.486329821580231, 54.453377872874832],
            [-1.484908306326616, 54.450508538763295],
            [-1.485016255852232, 54.44927956438999],
            [-1.482747173102341, 54.447851714422491],
            [-1.486173866028582, 54.447600308227059],
            [-1.494342139986182, 54.44763115204983],
            [-1.499039368266748, 54.448018278135592],
            [-1.505694005869157, 54.447353662441436],
            [-1.508409273576623, 54.448160090101602],
            [-1.510426858520705, 54.450320682690375],
            [-1.513207467460132, 54.450973587085088],
            [-1.514842359115822, 54.452139491078007],
            [-1.520155605614906, 54.449893254483641],
            [-1.522835286326425, 54.450615635737648],
            [-1.524548540508421, 54.449831522232898],
            [-1.527629422661446, 54.449602744214111],
            [-1.530078973037085, 54.447938896000217],
            [-1.533551881611689, 54.446444312624116],
            [-1.546148785878124, 54.444523186150178],
            [-1.54658037153381, 54.442164799380087],
            [-1.550792115730527, 54.437583630244362],
            [-1.549752774214016, 54.434648176790596],
            [-1.550620861348115, 54.433507350466471],
            [-1.549781290117628, 54.432468015216017],
            [-1.551373975558648, 54.429479437139285],
            [-1.549940648832135, 54.428052350717003],
            [-1.548406337391991, 54.428109538251789],
            [-1.547455916901945, 54.427213565033171],
            [-1.544256236862188, 54.426502347510336],
            [-1.542975852420885, 54.425767760640596],
            [-1.544592022728406, 54.42540988268162],
            [-1.544038141779584, 54.42420981366859],
            [-1.546555919973128, 54.423707019885043],
            [-1.548756413335798, 54.422197332543291],
            [-1.550636365039415, 54.421517721199152],
            [-1.558361607587074, 54.424290854609588],
            [-1.560775081013102, 54.423148400870247],
            [-1.5616913082745, 54.422210777215362],
            [-1.563500076497295, 54.421683488646288],
            [-1.564765496088217, 54.420651838823893],
            [-1.567332656047058, 54.420244053635308],
            [-1.571981292659598, 54.418549459772031],
            [-1.573425713386496, 54.418800812520267],
            [-1.574604702498098, 54.418494013822155],
            [-1.572863760151974, 54.415487080035696],
            [-1.574750785953025, 54.414799929202196],
            [-1.574097983214779, 54.414153255042763],
            [-1.576456440323866, 54.413314956993901],
            [-1.574541471402244, 54.41150002459451],
            [-1.574080260979152, 54.409616498544501],
            [-1.571341839727734, 54.405535652081369],
            [-1.569807543203113, 54.40420190340501],
            [-1.565748032043298, 54.403939339939505],
            [-1.564325378419631, 54.403431839098552],
            [-1.563597090027071, 54.402091926495402],
            [-1.560904268438447, 54.400809594238126],
            [-1.561617892310745, 54.400624354028594],
            [-1.565164002318835, 54.397982366617647],
            [-1.565767278084027, 54.398030370325621],
            [-1.567755655660024, 54.397013864236961],
            [-1.571884169103764, 54.39576137511169],
            [-1.572869033297021, 54.393766120868506],
            [-1.573629538418964, 54.39037705947711],
            [-1.574237112887879, 54.39029741883548],
            [-1.573678087235614, 54.389258331243056],
            [-1.574450515389356, 54.388573537437765],
            [-1.573481646927072, 54.386667542025855],
            [-1.575142747435445, 54.384914608963975],
            [-1.574863390936157, 54.381102174087104],
            [-1.576112909933296, 54.381016692843062],
            [-1.576406704751724, 54.379696611031179],
            [-1.57825657877633, 54.377510215631084],
            [-1.578119665121823, 54.377049594921964],
            [-1.579434459983037, 54.377041596983304],
            [-1.579657863913736, 54.375968408551962],
            [-1.581904293166263, 54.375626603006666],
            [-1.582544168402321, 54.376133894250543],
            [-1.584112451821013, 54.375412241677651],
            [-1.581959561685016, 54.374731672766821],
            [-1.583790989397164, 54.37508040704914],
            [-1.58237806247935, 54.373083076613419],
            [-1.585017939200497, 54.371715339562328],
            [-1.585412769932652, 54.371944070521664],
            [-1.585207596447307, 54.370918829082235],
            [-1.586932301211915, 54.368542239848246],
            [-1.586207536799466, 54.368218018689873],
            [-1.586425857237096, 54.36670892117413],
            [-1.584314440773317, 54.364400057981037],
            [-1.585912176533419, 54.362997254540765],
            [-1.587185684819824, 54.362610671098878],
            [-1.588467530115713, 54.362619538571685],
            [-1.588674409009821, 54.361782637979459],
            [-1.58933970609427, 54.361579095161275],
            [-1.591444535037692, 54.363014295366106],
            [-1.592771862066259, 54.362626936374276],
            [-1.594028420891094, 54.362859440272999],
            [-1.59438587054451, 54.362504748818303],
            [-1.596051198821898, 54.362326990821273],
            [-1.597770967315912, 54.364452804270982],
            [-1.602047380893348, 54.364821062383079],
            [-1.603901862392725, 54.364344546214788],
            [-1.606215874180366, 54.363767945393008],
            [-1.610452614028906, 54.361008240485518],
            [-1.612737935980585, 54.360179781340356],
            [-1.613102110440231, 54.359908636890999],
            [-1.612277391420784, 54.358426703284941],
            [-1.615226183000521, 54.357055701730836],
            [-1.608610327884991, 54.350828794390665],
            [-1.613474745647408, 54.349220474079097],
            [-1.619808992483976, 54.347830488704986],
            [-1.626362078267805, 54.346688894887038],
            [-1.632251826830756, 54.346233346457986],
            [-1.632647807487694, 54.346680316047141],
            [-1.638469163254021, 54.345244652767342],
            [-1.638662172954493, 54.345513048567668],
            [-1.641938840861488, 54.344970094725383],
            [-1.641890910400399, 54.343581431869801],
            [-1.643166205797287, 54.343391083051777],
            [-1.639826804078395, 54.340918679923355],
            [-1.638891103851269, 54.338343750023348],
            [-1.638939526458048, 54.335448218540066],
            [-1.640277996265019, 54.330632373857163],
            [-1.640179685132933, 54.329386453495985],
            [-1.637559166252974, 54.32714439521947],
            [-1.637390421273432, 54.326574997798083],
            [-1.639055605001975, 54.324820289372255],
            [-1.641624959868972, 54.323209343914684],
            [-1.637641252522755, 54.321825105907152],
            [-1.637784112666461, 54.321313262741924],
            [-1.643089938879059, 54.32074669042315],
            [-1.642425190236672, 54.319574586973673],
            [-1.642266767749321, 54.31710083257844],
            [-1.641578284874693, 54.316724023272634],
            [-1.640770427736008, 54.315404992963458],
            [-1.641092684235891, 54.313346975961736],
            [-1.640619812812711, 54.310105669915586],
            [-1.641738185573826, 54.309991260938631],
            [-1.641981109007896, 54.307500717961126],
            [-1.643101038924603, 54.307197568896143],
            [-1.644423301119219, 54.306954321350695],
            [-1.646928796060409, 54.307803779376613],
            [-1.649575578887049, 54.307772845670712],
            [-1.647908507320927, 54.305737777089917],
            [-1.647666871372353, 54.304699045230777],
            [-1.648036637398982, 54.304588681957057],
            [-1.64705374962811, 54.302535821033018],
            [-1.647732468371848, 54.302234034391127],
            [-1.647015316505551, 54.300919800874844],
            [-1.649828702197868, 54.300579289040726],
            [-1.648483851221588, 54.300435179403287],
            [-1.64847106696616, 54.298326732298349],
            [-1.648130791732326, 54.298228678562261],
            [-1.64838803162404, 54.2977926474396],
            [-1.650243069954834, 54.297556279206781],
            [-1.649056754892087, 54.297391065563374],
            [-1.649410449292252, 54.296631771309514],
            [-1.650132352295647, 54.29665992881673],
            [-1.651329515183651, 54.295538187870498],
            [-1.650763100079207, 54.29511235183422],
            [-1.65105373031551, 54.294355566252385],
            [-1.654256498608302, 54.293509194906591],
            [-1.660329458589425, 54.292979112213082],
            [-1.661644279161886, 54.29188815644487],
            [-1.660534464804914, 54.291401525784032],
            [-1.660200759948337, 54.290508151601166],
            [-1.67581570282304, 54.285148853399264],
            [-1.676069680265316, 54.285473076875036],
            [-1.679749095842511, 54.285400212104463],
            [-1.68431415959067, 54.285813069406132],
            [-1.687482962143889, 54.286639155946517],
            [-1.691464852327156, 54.286801283898789],
            [-1.693917606851871, 54.287270371945397],
            [-1.695361706774191, 54.287042153661602],
            [-1.696626006593201, 54.286403647321272],
            [-1.700661986259959, 54.286310369389504],
            [-1.705254289065249, 54.285531689478589],
            [-1.704509231127505, 54.284897164916828],
            [-1.704250305845732, 54.284160474162199],
            [-1.704618539989589, 54.283572710130827],
            [-1.70381755825489, 54.281746332933714],
            [-1.701656885446023, 54.278717695041372],
            [-1.702732886475619, 54.277305753304738],
            [-1.703553107692396, 54.27771579268137],
            [-1.70464502620202, 54.276857488846559],
            [-1.69981170952446, 54.275427373163417],
            [-1.697817718546559, 54.274469746124758],
            [-1.696156990856589, 54.274564436889122],
            [-1.694746283314195, 54.273657655778749],
            [-1.690768394601477, 54.269227583283744],
            [-1.69119498282154, 54.268029771320819],
            [-1.68692821783424, 54.264362747486743],
            [-1.684621912097537, 54.262996964219433],
            [-1.684462237264456, 54.261590034457775],
            [-1.685176054929236, 54.261594595014458],
            [-1.685431844452179, 54.260258401010063],
            [-1.687358783390857, 54.259194369097045],
            [-1.689539465295738, 54.259282682346473],
            [-1.691064730394321, 54.258541549424343],
            [-1.692101770861078, 54.258629579286925],
            [-1.695204855734827, 54.257487980879766],
            [-1.697087101381112, 54.25723478477375],
            [-1.698815384052597, 54.256409582593484],
            [-1.701125230634347, 54.256449477355815],
            [-1.702232957032569, 54.257162212741918],
            [-1.702656814566933, 54.258416087681788],
            [-1.704563063760727, 54.260819479424775],
            [-1.706975716572295, 54.259168104136613],
            [-1.706780830395262, 54.258075673937917],
            [-1.70757698765946, 54.257932910269993],
            [-1.70800815971761, 54.256434872521872],
            [-1.709280554666543, 54.255575163532299],
            [-1.712663813641267, 54.256423579417692],
            [-1.715495576742689, 54.25646174423057],
            [-1.717303095628222, 54.257235304784508],
            [-1.718285337445753, 54.25680800884323],
            [-1.718135772237776, 54.25623067498811],
            [-1.719031473245545, 54.256110536430867],
            [-1.719682820805648, 54.255127043040467],
            [-1.722986342525362, 54.254843482830466],
            [-1.724216878186875, 54.255373855701421],
            [-1.724246891925626, 54.256167502787235],
            [-1.72618309973473, 54.256073949859818],
            [-1.726126318955716, 54.255840151261118],
            [-1.730580809476695, 54.254887638301831],
            [-1.737116088208159, 54.254224408694334],
            [-1.739491740216743, 54.251843432081003],
            [-1.737151181392757, 54.25039140118276],
            [-1.738798573988195, 54.24859302342859],
            [-1.741224806850959, 54.246890662158904],
            [-1.755957079486953, 54.239352240417013],
            [-1.799801953686003, 54.245136279223061],
            [-1.823327378299734, 54.247475545522256],
            [-1.81629816481722, 54.240686834289455],
            [-1.818483240558365, 54.239261158280897],
            [-1.822568580030697, 54.235458411121989],
            [-1.82806000672155, 54.231627876406506],
            [-1.837400114274777, 54.231419729972281],
            [-1.841083144155709, 54.229472589337774],
            [-1.846694544712106, 54.227360635662947],
            [-1.862160624071748, 54.222788558767768],
            [-1.879440719366127, 54.220265430525743],
            [-1.881604800475273, 54.218606713265309],
            [-1.886443753832841, 54.209999696118672],
            [-1.895610165787118, 54.21034499080595],
            [-1.91237307802007, 54.204630676908984],
            [-1.924401861410617, 54.200145123632524],
            [-1.928006913837384, 54.200309096108413],
            [-1.937730863181979, 54.199743795900908],
            [-1.942158673342886, 54.199101600088596],
            [-1.969919449372449, 54.191320528346459],
            [-1.980187735851015, 54.187706116221044],
            [-1.99489525222215, 54.18020674288173],
            [-2.001919926000653, 54.176349435889982],
            [-2.003778058190008, 54.174454846757811],
            [-2.004504011374094, 54.172872140943305],
            [-2.011118542425334, 54.172532872944018],
            [-2.014710843783206, 54.172783228160611],
            [-2.015097168732794, 54.173576766074561],
            [-2.017199431928005, 54.174761017496671],
            [-2.018551244218521, 54.176170036200297],
            [-2.020017406853947, 54.176260570876437],
            [-2.020487587873414, 54.175976489404711],
            [-2.02283043324299, 54.176761560532306],
            [-2.023882461683564, 54.175960578194328],
            [-2.026954216068771, 54.17614596219439],
            [-2.02834020340876, 54.175469789591048],
            [-2.031388300911656, 54.174789580727996],
            [-2.032491724352173, 54.175326731522802],
            [-2.034727237848393, 54.17577637098421],
            [-2.034173458694322, 54.180424805191215],
            [-2.044350763534555, 54.182549677137636],
            [-2.045781108956855, 54.181867895711122],
            [-2.049389528047181, 54.185768780768534],
            [-2.051100682353236, 54.189090693989527],
            [-2.050368576265202, 54.189430726370844],
            [-2.050332493523291, 54.190001439296047],
            [-2.051791642158881, 54.191383074405522],
            [-2.054257539275661, 54.194887054693538],
            [-2.059319211236327, 54.196468230323973],
            [-2.060386715183803, 54.201142918841143],
            [-2.061336220513079, 54.202596590178544],
            [-2.061157487576664, 54.207152366171947],
            [-2.060428548626836, 54.208768659157762],
            [-2.060867789975856, 54.210312461650382],
            [-2.068057962331411, 54.212612053490787],
            [-2.069965643029924, 54.212731387101996],
            [-2.071120901530491, 54.213107277546641],
            [-2.076178431744587, 54.215872273372632],
            [-2.079176538489523, 54.218366115028836],
            [-2.080537458756512, 54.218702234952922],
            [-2.082500412871167, 54.218695510496104],
            [-2.083934447684163, 54.220276286111137],
            [-2.086376374555103, 54.221196654598444],
            [-2.087093641131295, 54.220966061401271],
            [-2.086626451472956, 54.221239613704341],
            [-2.088445489603366, 54.2220085019134],
            [-2.090521057426188, 54.223627369968028],
            [-2.09503873461346, 54.227962059308652],
            [-2.096352566519376, 54.228306126069619],
            [-2.099209865369549, 54.230052732389034],
            [-2.100027488024983, 54.231953769142152],
            [-2.108763650289516, 54.231836538468556],
            [-2.113452152302707, 54.232550292221774],
            [-2.116050085490286, 54.230628124230513],
            [-2.118187086347297, 54.229599692203905],
            [-2.121317975665196, 54.228572919790786],
            [-2.124689125706063, 54.227919689258329],
            [-2.126261358380608, 54.226406382512927],
            [-2.128927837852126, 54.225628847018598],
            [-2.140468700228642, 54.225282494209679],
            [-2.142168617397366, 54.226305051758963],
            [-2.142927524621089, 54.22930681512711],
            [-2.143531368613667, 54.230034967428153],
            [-2.143619610909818, 54.231589667191713],
            [-2.143074582461957, 54.233233196851117],
            [-2.143832531486305, 54.235023463538766],
            [-2.147879676940565, 54.234164763287517],
            [-2.150690492936721, 54.232556142453831],
            [-2.152531969767914, 54.234373752396685],
            [-2.15159902603118, 54.235578331852125],
            [-2.156679832763464, 54.234651523345768],
            [-2.159150577495919, 54.23365068968706],
            [-2.160784763923808, 54.232162914714962],
            [-2.160749348082988, 54.231340622854319],
            [-2.165997479156988, 54.229096554874594],
            [-2.166637001595648, 54.228302090758845],
            [-2.167753973180594, 54.228747208637373],
            [-2.171941996768054, 54.228465387068908],
            [-2.174745083453107, 54.230418787621254],
            [-2.179824271187842, 54.235022696963483],
            [-2.185018233232567, 54.238279015991331],
            [-2.187153309138025, 54.238809561382702],
            [-2.187681741608276, 54.239622988583051],
            [-2.189650606671489, 54.241024618537779],
            [-2.191100865398315, 54.243068733812976],
            [-2.192935591951618, 54.243617627619891],
            [-2.194927662229801, 54.245305828365261],
            [-2.194654303691594, 54.245912913264029],
            [-2.1977948780738, 54.247366432767272],
            [-2.207058413777009, 54.247463199177524],
            [-2.212213464007105, 54.247801133408323],
            [-2.214228724485725, 54.248443751409731],
            [-2.214640704476534, 54.249756960416775],
            [-2.215949204476845, 54.250225555085812],
            [-2.220887717644953, 54.251033542263357],
            [-2.222694202343452, 54.251044594659149],
            [-2.224130875135993, 54.251067992353676],
            [-2.229213999404309, 54.252166557677818],
            [-2.230379544551089, 54.250627505974855],
            [-2.231404366413603, 54.250283125002497],
            [-2.23565333614465, 54.251230235022639],
            [-2.237561928806821, 54.249779530055569],
            [-2.239294594411754, 54.249243152668321],
            [-2.240588279933853, 54.248440705221093],
            [-2.242584371014842, 54.248090689679884],
            [-2.246660520866747, 54.24939006238661],
            [-2.252132960947114, 54.249334691865712],
            [-2.256393325631465, 54.248100727498354],
            [-2.257542977798741, 54.247392770876345],
            [-2.258114860364906, 54.247552419355202],
            [-2.258794841716541, 54.247328973576295],
            [-2.263662210928773, 54.245328625368742],
            [-2.26508479274235, 54.244857263513452],
            [-2.267788298373027, 54.244801852224832],
            [-2.270130246864532, 54.243899688003516],
            [-2.274020407992627, 54.243002053922744],
            [-2.275017573742859, 54.242504579701929],
            [-2.27509097916407, 54.241545465052297],
            [-2.275667911063108, 54.24108399500949],
            [-2.277877043110461, 54.241023196634103],
            [-2.280457661948349, 54.239404888782808],
            [-2.281293726718479, 54.239833431711219],
            [-2.282750607728103, 54.239498387748689],
            [-2.283316407998047, 54.23854889512517],
            [-2.2860014582529, 54.239436787557423],
            [-2.286403047498103, 54.240484654077612],
            [-2.287404199305924, 54.241472672214464],
            [-2.286891724428945, 54.243243497772141],
            [-2.28970079822269, 54.245132199418315],
            [-2.291330070953281, 54.251600034365666],
            [-2.292756917033741, 54.252610343324889],
            [-2.294270465228047, 54.253285196969607],
            [-2.296967579332602, 54.250988611228706],
            [-2.305103445325104, 54.249331688274161],
            [-2.307325015358187, 54.25006389931945],
            [-2.310469071424443, 54.252227171967604],
            [-2.318111346148603, 54.255867765144885],
            [-2.319092833406692, 54.257144063484724],
            [-2.320816723621826, 54.259921056415237],
            [-2.318052530375713, 54.263577229942079],
            [-2.318282201895409, 54.265887257392997],
            [-2.316101600480052, 54.267835152306567],
            [-2.315510681607779, 54.270273155383478],
            [-2.31939868639199, 54.27445907506717],
            [-2.321180503509932, 54.275111309096246],
            [-2.321741460215895, 54.27733416447871],
            [-2.323785645240899, 54.279304092685571],
            [-2.325888097414041, 54.279681283106392],
            [-2.324127232208111, 54.280166851579892],
            [-2.321703486348929, 54.281721853783267],
            [-2.321119090100666, 54.282217712437287],
            [-2.321651437821622, 54.283123108301616],
            [-2.319077884370979, 54.283812982506142],
            [-2.317914345967475, 54.286700071340746],
            [-2.317188189078695, 54.287154044140955],
            [-2.319853500329258, 54.288956135415333],
            [-2.324168220611515, 54.293593715581878],
            [-2.322286356092281, 54.295188607681609],
            [-2.320386485455143, 54.299033025770747],
            [-2.320433817401043, 54.29998285177335],
            [-2.324341377594406, 54.30076417639885],
            [-2.324518603773927, 54.303172280432946],
            [-2.321244280207031, 54.305394608249976],
            [-2.320397354898289, 54.306759327297854],
            [-2.323028419831187, 54.311071568874077],
            [-2.321637659148635, 54.311298170923564],
            [-2.320146633043656, 54.312074142797712],
            [-2.318668003426013, 54.311279994915338],
            [-2.317054924080501, 54.311981657126161],
            [-2.314973652292748, 54.313575155694764],
            [-2.309837402810599, 54.32430392103204],
            [-2.312457248156985, 54.324904694826841],
            [-2.313794563293663, 54.325668728877254],
            [-2.314987988146496, 54.327113455348979],
            [-2.318050719388557, 54.328085918765346],
            [-2.320197995781851, 54.331739823117871],
            [-2.322466034571733, 54.331519883545873],
            [-2.328157671418883, 54.33214261887138],
            [-2.334745201718329, 54.334933909202562],
            [-2.335206223515833, 54.335835841703279],
            [-2.334504546525885, 54.3378715920825],
            [-2.335763457116411, 54.340424050347366],
            [-2.340227343601515, 54.342695183603574],
            [-2.347920429877052, 54.345133027824936],
            [-2.348131142083194, 54.346766289816053],
            [-2.35324022848087, 54.34840956938158],
            [-2.357337381699002, 54.349340255481458],
            [-2.359669965261507, 54.350256305705422],
            [-2.361559755416566, 54.352420160797358],
            [-2.367151638648325, 54.354922375144646],
            [-2.367708025473684, 54.356060254212011],
            [-2.366147833353123, 54.355026981481906],
            [-2.363685135503787, 54.35475582948294],
            [-2.360744846209951, 54.35493721276319],
            [-2.35643284520526, 54.355920646515528],
            [-2.355726861424432, 54.357198010124563],
            [-2.352871358437751, 54.358319008905944],
            [-2.3446436532929, 54.359618076416048],
            [-2.342709273663421, 54.361640306634087],
            [-2.338250559380439, 54.36382149272557],
            [-2.334552276215252, 54.363806642414602],
            [-2.333949924373047, 54.364489537679233],
            [-2.332879224683204, 54.36454462259556],
            [-2.329954195594285, 54.367115793882661],
            [-2.32692547464897, 54.368112626247559],
            [-2.326087966834839, 54.368085235537805],
            [-2.324327998806598, 54.369163045162722],
            [-2.323486008254588, 54.370525060439974],
            [-2.322198214591636, 54.370986851000417],
            [-2.320843359402705, 54.372326849438217],
            [-2.320300832685753, 54.372435238065037],
            [-2.320397256968735, 54.372957134602153],
            [-2.318882626527671, 54.374354153614377],
            [-2.318136475649565, 54.374610459484693],
            [-2.317038072573619, 54.376300235590385],
            [-2.297435988199853, 54.376888487166426],
            [-2.292056645873219, 54.384191979676849],
            [-2.293222375307041, 54.386802603412654],
            [-2.295417529648017, 54.387140555802787],
            [-2.293790834224852, 54.38748513932542],
            [-2.292268816788658, 54.38869490556182],
            [-2.291717383195846, 54.39019259281428],
            [-2.291982137929578, 54.391476209134062],
            [-2.295520331820229, 54.392442695168683],
            [-2.298780523929048, 54.394760527143077],
            [-2.299948903086851, 54.395102732848507],
            [-2.30163874831311, 54.396193148605029],
            [-2.30550219449566, 54.3971099983643],
            [-2.305337132218663, 54.397489671567143],
            [-2.306338420652961, 54.398747124303739],
            [-2.304781404024323, 54.401889358990374],
            [-2.307410385942949, 54.404294824477311],
            [-2.305677099632023, 54.40757501885971],
            [-2.306042919340894, 54.410355592269148],
            [-2.306860878320299, 54.412807885173983],
            [-2.307739488806115, 54.41388229922191],
            [-2.308208905604487, 54.41647836433544],
            [-2.307754581801354, 54.417745801263465],
            [-2.307975366447699, 54.420677716745054],
            [-2.305604222548252, 54.422416448560341],
            [-2.303525083627384, 54.426154018423162],
            [-2.302680195733006, 54.426538089657662],
            [-2.299433161954995, 54.427034184936034],
            [-2.294525044545725, 54.431262083241599],
            [-2.291848813056026, 54.431452817716256],
            [-2.2923414511215, 54.432660382462423],
            [-2.291488400491553, 54.43605145664084],
            [-2.292539342189658, 54.437496725379319],
            [-2.29206999934966, 54.438487333906565],
            [-2.292860548648306, 54.439297846148513],
            [-2.284309856589612, 54.438429469753679],
            [-2.280414155143302, 54.438931969729452],
            [-2.274978510728598, 54.440646616252131],
            [-2.271342998599782, 54.440906479393803],
            [-2.270327263010743, 54.441895533091035],
            [-2.265373579294351, 54.442916665327232],
            [-2.267898116680283, 54.447223957296444],
            [-2.267162211844788, 54.44717436214021],
            [-2.262716545544161, 54.448649899784435],
            [-2.262048937249581, 54.448431169092039],
            [-2.261163618628843, 54.448653269258564],
            [-2.259858057634749, 54.448511395754423],
            [-2.256762468230782, 54.450155451801621],
            [-2.25349684165064, 54.450529921668341],
            [-2.249534162091826, 54.45193385890029],
            [-2.247881197230123, 54.451482522113601],
            [-2.246234264522708, 54.451772577895341],
            [-2.24411268951318, 54.450058572269967],
            [-2.242567354216351, 54.449790279926361],
            [-2.240285642969662, 54.449916167801838],
            [-2.239614933340549, 54.449677548089689],
            [-2.238726198429268, 54.449868035960499],
            [-2.238035669801551, 54.449136060328684],
            [-2.237340960055894, 54.449275828068728],
            [-2.235612289434373, 54.44822144450999],
            [-2.192414208514595, 54.448596409831957],
            [-2.189310041302598, 54.448968887300573],
            [-2.186156325090788, 54.452025775118081],
            [-2.183571463079767, 54.455811457686806],
            [-2.18218830235932, 54.457286519714486],
            [-2.179909022622183, 54.45845375270035],
            [-2.177502513965273, 54.461616237596793],
            [-2.170572510571237, 54.461131948097758],
            [-2.171927615170539, 54.459457549499973],
            [-2.170207021876254, 54.458189230371929],
            [-2.167320509424381, 54.457694490146068],
            [-2.164786074421144, 54.456513494358873],
            [-2.159867167385838, 54.456501239157696],
            [-2.159261619421903, 54.455473929768338],
            [-2.155366657220925, 54.456998705914906],
            [-2.150114603417449, 54.459953980532831],
            [-2.14148414709878, 54.460918826520562],
            [-2.138881135597655, 54.461643504693626],
            [-2.136043548238962, 54.461440037916212],
            [-2.126556842785292, 54.462091134958349],
            [-2.123003910298719, 54.461511556746686],
            [-2.116590215860774, 54.462253960044414],
            [-2.114298586881691, 54.464184755699833],
            [-2.108584782669895, 54.464365282004231],
            [-2.10442538310199, 54.464901882767499],
            [-2.101793006689002, 54.465349887137791],
            [-2.094747008596736, 54.467288724925162],
            [-2.086390162742621, 54.468383324607359],
            [-2.081617872891774, 54.469864106034542],
            [-2.075734495063049, 54.473127513260231],
            [-2.0711674312129, 54.474254564669394],
            [-2.069912191617745, 54.475654566398575],
            [-2.067698507282113, 54.477041616961273],
            [-2.06316891192877, 54.478871122434761],
            [-2.06309750824909, 54.479602696222997],
            [-2.06128244879873, 54.480548160397909],
            [-2.059199795886592, 54.48114593488836],
            [-2.053065867054417, 54.480704841955927],
            [-2.050459996457294, 54.481395260876468],
            [-2.048606268912587, 54.482582298930808],
            [-2.0431914601106, 54.483590003504332],
            [-2.044722602374179, 54.475176776554179],
            [-2.036950510770755, 54.474285218560759],
            [-2.029561114947106, 54.472945517325421],
            [-2.014639199517013, 54.47154721357424],
            [-1.996757605172801, 54.466893755872526],
            [-1.986533421613959, 54.461550327355418],
            [-1.986263865262251, 54.460319084269109],
            [-1.985532818901286, 54.459915486059266],
            [-1.98004421022852, 54.460204995150946],
            [-1.97785995533762, 54.460126432948925],
            [-1.976118617637016, 54.459718995888757],
            [-1.971104086893763, 54.454803839736257],
            [-1.971744753062231, 54.453955619982473],
            [-1.970053023546139, 54.451511658802573],
            [-1.968434978933898, 54.451797934954769],
            [-1.965964424665112, 54.451607644638131],
            [-1.965233037990394, 54.452030724441677],
            [-1.963476615670584, 54.451771385371721],
            [-1.960496593499736, 54.45215329949751],
            [-1.958507766465055, 54.452977641353307],
            [-1.956548757121919, 54.453238477988648],
            [-1.955417575452232, 54.453850081130902],
            [-1.953775426086764, 54.453451344956136],
            [-1.953221461357185, 54.453688389024421],
            [-1.951613106474132, 54.453429833201561],
            [-1.949224561790304, 54.454259254502809],
            [-1.946989636147873, 54.454296044969226],
            [-1.94253656844921, 54.453385436578728],
            [-1.934018183056704, 54.457310207230663],
            [-1.928318639544309, 54.456482868677817],
            [-1.913175665597036, 54.461984676615977],
            [-1.893423205247362, 54.466888376952568],
            [-1.885462418658779, 54.472892484218953],
            [-1.877595991933761, 54.478039702380968],
            [-1.867247669644168, 54.482107076081363],
            [-1.859122514991176, 54.48184806164835],
            [-1.858522023859849, 54.482775712130582],
            [-1.860823455978369, 54.486367759053238],
            [-1.860037040888376, 54.487037277777304],
            [-1.859208869039482, 54.489072755585894],
            [-1.859307825971345, 54.492628995817],
            [-1.858642540779965, 54.493498155077987],
            [-1.857815448972325, 54.493381255824865],
            [-1.857711084241254, 54.493648943456932],
            [-1.858594677709192, 54.496636330397216],
            [-1.858662129912526, 54.499017940846386],
            [-1.857792636737377, 54.503530148048064],
            [-1.85536521950896, 54.503504807044358],
            [-1.852759979100854, 54.503977075795156],
            [-1.850968654564185, 54.503556095532907],
            [-1.848208779957661, 54.503653322000979],
            [-1.84596570655321, 54.504706451697736],
            [-1.844274810364042, 54.505095220004478],
            [-1.839425097443174, 54.5084275218654],
            [-1.836464594267564, 54.508011961043835],
            [-1.832294050102099, 54.505162802317834],
            [-1.83155595697734, 54.503641193540872],
            [-1.831845747406096, 54.502638657308573],
            [-1.827778735523342, 54.502919624601056],
            [-1.827218614247847, 54.501353307040333],
            [-1.826440218724652, 54.500659303082926],
            [-1.824379496337186, 54.500144078138142],
            [-1.8245420194263, 54.497885901928456],
            [-1.822711280691742, 54.496352763316274],
            [-1.822435410626948, 54.495167882998935],
            [-1.820268660231681, 54.494260601936972],
            [-1.817511034595, 54.49362919003336],
            [-1.813070286985418, 54.491400847411569],
            [-1.802725759129894, 54.487826507264671],
            [-1.801561092034665, 54.487667332421857],
            [-1.792968397980253, 54.484482349842999],
            [-1.791081803175653, 54.489419228818754],
            [-1.789013459584492, 54.491782799957043],
            [-1.790259825793715, 54.49288496613633],
            [-1.786878595763582, 54.498880530221747],
            [-1.787168535224006, 54.501028914212327],
            [-1.785048181037048, 54.501375658035684],
            [-1.785124142460173, 54.502792131067949],
            [-1.783925915616762, 54.503366059856241],
            [-1.783903275203167, 54.505025003870287],
            [-1.782478466317075, 54.505793529555561],
            [-1.782166270785663, 54.507002606558238],
            [-1.777870094677561, 54.506395373703469],
            [-1.776123242946015, 54.506718381060494],
            [-1.777580391833052, 54.515672923122565],
            [-1.777340979103814, 54.519366996016373],
            [-1.776341395951269, 54.519100040505933],
            [-1.776849581022487, 54.525351359363732],
            [-1.781549469263597, 54.526823891732882],
            [-1.780313574700344, 54.52880685785766],
            [-1.779686795845678, 54.53186305457313],
            [-1.773280955792993, 54.532813726267207],
            [-1.769714231750691, 54.532834855911403],
            [-1.767195437295268, 54.532276448582671],
            [-1.764598004951767, 54.529818009695063],
            [-1.761203653084535, 54.52894143365819],
            [-1.754004935798353, 54.528640342104026],
            [-1.751732041253404, 54.530162574868122],
            [-1.751237679602474, 54.530144485213292],
            [-1.750822111364541, 54.529620594188614],
            [-1.747526739629033, 54.529281253388575],
            [-1.743124636397629, 54.529750995694343],
            [-1.738990367795634, 54.528427372952621],
            [-1.733081616010926, 54.52772609574744],
            [-1.732549658952955, 54.52944860721334],
            [-1.731356469522508, 54.530671777161778],
            [-1.727295335223805, 54.530474872648476],
            [-1.724789015999248, 54.535985351238914],
            [-1.724467957916712, 54.537764023089494],
            [-1.722821351366592, 54.539684355361921],
            [-1.722842059540283, 54.540963234928959],
            [-1.723874998808713, 54.541582992828644],
            [-1.721301294421423, 54.542291553121551],
            [-1.719409808780274, 54.542228770433063],
            [-1.718710170660085, 54.541473148637884],
            [-1.715836591656447, 54.539944961419593],
            [-1.710645551717194, 54.538327611529652],
            [-1.704103034744559, 54.53665461883584],
            [-1.698606899025588, 54.53608477418225],
            [-1.69691683390583, 54.535996078614062],
            [-1.689521938365329, 54.535376118464093],
            [-1.683620794765446, 54.533469100676442],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000167",
        LAD13CDO: "36UF",
        LAD13NM: "Ryedale",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.009153482536084, 54.407151867784478],
            [-0.98782626669756, 54.39594110505174],
            [-0.975885504006342, 54.396899182407338],
            [-0.961515626076011, 54.40253210101168],
            [-0.950427718330344, 54.408844374411792],
            [-0.926875313544867, 54.400607072187782],
            [-0.918554609547839, 54.400814173442079],
            [-0.917548883594966, 54.400631704912087],
            [-0.866537096392686, 54.387336650848859],
            [-0.859850346419822, 54.386514251763529],
            [-0.847075947586522, 54.383190671274441],
            [-0.834402370601822, 54.380891295895026],
            [-0.82361987971903, 54.382503384836866],
            [-0.816745131669835, 54.371515265342452],
            [-0.813564197954559, 54.368439013950962],
            [-0.812349874042926, 54.368167330251161],
            [-0.812038299583499, 54.367816440372678],
            [-0.811567918665088, 54.365448939921613],
            [-0.8108314826332, 54.364888044945459],
            [-0.810908122501942, 54.364472668240431],
            [-0.809841366517773, 54.363839307727119],
            [-0.808825292599951, 54.36129564661298],
            [-0.808408406357999, 54.359800468037818],
            [-0.807680227572035, 54.359703401180795],
            [-0.805979549609183, 54.358435499926131],
            [-0.804825465919702, 54.357902791040729],
            [-0.803897907748182, 54.357890009368106],
            [-0.804033558271155, 54.357458144245456],
            [-0.802543912482495, 54.357618638362929],
            [-0.801451718578091, 54.357393891515663],
            [-0.799435048876227, 54.356365427645578],
            [-0.799212061300773, 54.355832932737165],
            [-0.797411684732163, 54.355569645452498],
            [-0.797022480070199, 54.354933928730752],
            [-0.79622704197571, 54.355088677699008],
            [-0.794205459049685, 54.354341392620235],
            [-0.792699859113452, 54.35457620395519],
            [-0.789373881478831, 54.354079126284844],
            [-0.789124784946126, 54.35449186243747],
            [-0.787346070470294, 54.354960245421275],
            [-0.78645773361289, 54.354454295165226],
            [-0.785210923689111, 54.355043036142597],
            [-0.784448008335538, 54.354772014935975],
            [-0.782758881787848, 54.35507046511394],
            [-0.782218741225708, 54.354811562416145],
            [-0.778331250847679, 54.355766330455452],
            [-0.774006465953427, 54.357292649953877],
            [-0.773764714171538, 54.356936076309658],
            [-0.772705569768931, 54.357621865599839],
            [-0.772807735928329, 54.35801477111319],
            [-0.771438415895508, 54.358212056144893],
            [-0.770985739512611, 54.359148468349055],
            [-0.770078489590314, 54.359124848236952],
            [-0.768591870149523, 54.359692101178332],
            [-0.768884242529043, 54.360156155906992],
            [-0.768207652131881, 54.360703799929489],
            [-0.766060231481863, 54.362109128606889],
            [-0.76402527932042, 54.362326488330879],
            [-0.763928199772904, 54.3628944205972],
            [-0.76166248090227, 54.362646507228611],
            [-0.760953294527975, 54.362943017684941],
            [-0.759845107541109, 54.362689867793087],
            [-0.758951434447619, 54.362775056010754],
            [-0.758086888399112, 54.363476200259285],
            [-0.757472075509897, 54.363245179652722],
            [-0.756604913481773, 54.363369270884021],
            [-0.756135491201629, 54.364897750878882],
            [-0.755094310931035, 54.366143511009767],
            [-0.753556513045472, 54.366809817034145],
            [-0.753107394863839, 54.368577569710432],
            [-0.75034152707829, 54.368216425531195],
            [-0.74771706567706, 54.366902181580123],
            [-0.747285469186865, 54.365708619720692],
            [-0.745392612571886, 54.364738954756803],
            [-0.74383411844344, 54.363154378618169],
            [-0.744386954394933, 54.362740399541281],
            [-0.743239382435605, 54.36221795181158],
            [-0.742694325713189, 54.360814673556654],
            [-0.743486015471221, 54.359935820270707],
            [-0.742774829952152, 54.359740570853369],
            [-0.743052268348839, 54.359121503889099],
            [-0.742496632290478, 54.35862048930332],
            [-0.742650595415414, 54.358265277144802],
            [-0.741205297547151, 54.356915525640915],
            [-0.74023683390508, 54.356731958275624],
            [-0.740000597477333, 54.356405931593684],
            [-0.738226937122256, 54.356487170429894],
            [-0.737512056099362, 54.356164223883489],
            [-0.735903261613454, 54.356138400078549],
            [-0.73505951339135, 54.355653207413262],
            [-0.732968671657389, 54.355241208625905],
            [-0.711178007483769, 54.357163112449058],
            [-0.711375591914994, 54.359012222924896],
            [-0.710231986228256, 54.360836225467807],
            [-0.707891577047568, 54.362540458474101],
            [-0.703233538059129, 54.364865101266417],
            [-0.699924411993959, 54.365693243260104],
            [-0.696244967292482, 54.365330900907203],
            [-0.691714292357919, 54.365651289059556],
            [-0.689636757757995, 54.367381388932657],
            [-0.688151134006695, 54.369273376313487],
            [-0.687604265207594, 54.370831318881436],
            [-0.688108343937447, 54.372837491954073],
            [-0.687218041391056, 54.373094754021146],
            [-0.684171952254753, 54.373465149995681],
            [-0.681317265062107, 54.372953155252425],
            [-0.680247228418371, 54.373149083130357],
            [-0.6778284330834, 54.374596628099056],
            [-0.674821666738522, 54.37517394036832],
            [-0.672956243545902, 54.37468158947955],
            [-0.671537964826067, 54.374789126634006],
            [-0.669510631700402, 54.374354260504127],
            [-0.668165879433457, 54.373759717030801],
            [-0.668433261539256, 54.37334652789864],
            [-0.667390425305548, 54.373460852531728],
            [-0.666925272634679, 54.372992842348616],
            [-0.666261102151094, 54.373061005432611],
            [-0.666229348502529, 54.372615755039021],
            [-0.665050042047519, 54.372618899693101],
            [-0.665212939625769, 54.37229533970276],
            [-0.664222301531769, 54.37217742800437],
            [-0.66455126017898, 54.371812564196283],
            [-0.663646271221591, 54.371807044680146],
            [-0.663400395159124, 54.371130232231231],
            [-0.662575895268763, 54.371016842320294],
            [-0.662731912369198, 54.370431662046244],
            [-0.661911475071066, 54.369814991212742],
            [-0.662390824536677, 54.369464382893582],
            [-0.661888466592023, 54.369291641540784],
            [-0.661143908899376, 54.367668264414441],
            [-0.661305193865042, 54.366495336346979],
            [-0.632600878691795, 54.375937201726323],
            [-0.634408073868618, 54.366016126221545],
            [-0.63391228383319, 54.364490659749272],
            [-0.627613241100184, 54.359069598569413],
            [-0.629247356313639, 54.353436549781179],
            [-0.620942327293375, 54.343434523768401],
            [-0.617044182150856, 54.34127954357718],
            [-0.614788175134818, 54.339155877010789],
            [-0.61059383270814, 54.336637763209026],
            [-0.607682291189769, 54.335952578089895],
            [-0.607591272965621, 54.335459884705315],
            [-0.60447638949811, 54.334651835797736],
            [-0.604301830409908, 54.334220190700783],
            [-0.603499791380364, 54.333742637215536],
            [-0.603004005743739, 54.333804308886783],
            [-0.601956406480931, 54.332904162239302],
            [-0.600863895706123, 54.332875322032422],
            [-0.600558946423989, 54.332299249990307],
            [-0.598138634417318, 54.331835239392227],
            [-0.595894607046851, 54.330938211296193],
            [-0.593210776475889, 54.330674163685238],
            [-0.590085404760978, 54.32938206099648],
            [-0.587123482619311, 54.326332832240141],
            [-0.587271708752096, 54.325372848252222],
            [-0.586362779566565, 54.325377482824948],
            [-0.586251874556151, 54.324844990615482],
            [-0.584844723900165, 54.323864977976918],
            [-0.584596120678677, 54.322327803666212],
            [-0.58584932282577, 54.320845989163189],
            [-0.58480070750609, 54.320757296118451],
            [-0.583149453995519, 54.319413978051685],
            [-0.581885521274919, 54.319596866219776],
            [-0.581239784670624, 54.31919469954925],
            [-0.580019469853166, 54.319361003766588],
            [-0.579819247861445, 54.318969464595661],
            [-0.578404938764906, 54.318965392303731],
            [-0.577869821828018, 54.318611248496978],
            [-0.576886438047233, 54.318639200963489],
            [-0.576282660910567, 54.319205515883063],
            [-0.575534894215612, 54.318020153298789],
            [-0.574532363374524, 54.31842446011823],
            [-0.574062913053974, 54.318306562129429],
            [-0.573614115407501, 54.31714719019125],
            [-0.573119061661545, 54.317547596895928],
            [-0.572438884415843, 54.317477533318666],
            [-0.572251118166918, 54.317039390451562],
            [-0.572770637400238, 54.316642871904001],
            [-0.572396998243064, 54.316333756029287],
            [-0.57063251455319, 54.316366793215778],
            [-0.568717760623211, 54.315856940267643],
            [-0.568344273889222, 54.314348804672058],
            [-0.56768638097437, 54.31386013411926],
            [-0.567770370693537, 54.312680997036431],
            [-0.568381041842821, 54.312315239915613],
            [-0.567440275049524, 54.312049710168985],
            [-0.567280799456317, 54.31137460942611],
            [-0.56827854026003, 54.312035393211474],
            [-0.570269482651776, 54.31220461063883],
            [-0.578968308779366, 54.310915133479917],
            [-0.58092963379458, 54.309621468428489],
            [-0.584075029832887, 54.306248355145414],
            [-0.586532481240891, 54.305355897086002],
            [-0.587770912250057, 54.304243298284135],
            [-0.592541712528587, 54.303243856321622],
            [-0.594655262376205, 54.303368264568405],
            [-0.597758480481826, 54.302771617421385],
            [-0.598783373892045, 54.302188517498507],
            [-0.598757106111974, 54.301287609528387],
            [-0.60079292078389, 54.299532504893421],
            [-0.604492021932923, 54.298071654596647],
            [-0.605298971465085, 54.297065337698797],
            [-0.604894640862571, 54.296616652266053],
            [-0.605509097314575, 54.293029434567451],
            [-0.609084698250578, 54.286319789977782],
            [-0.609882578301909, 54.286028787553562],
            [-0.60975254580473, 54.282727770631226],
            [-0.613326658072341, 54.278621824644773],
            [-0.61305039243164, 54.276969337127916],
            [-0.61032323657451, 54.274944386963966],
            [-0.613099878101061, 54.269641029770412],
            [-0.610477046484137, 54.264802208669472],
            [-0.607540679965806, 54.260948393869022],
            [-0.607812991887548, 54.255644962543741],
            [-0.60692097741817, 54.248588877629935],
            [-0.604801624417277, 54.245443692794197],
            [-0.601652668342558, 54.243938546875768],
            [-0.599707482981697, 54.241922399166434],
            [-0.597891559422302, 54.237049492558256],
            [-0.597934910135584, 54.234820037713583],
            [-0.598981657350904, 54.232091722386343],
            [-0.602669532739487, 54.229785929436943],
            [-0.60361626545357, 54.22296231003088],
            [-0.605882638718294, 54.217974054169822],
            [-0.607228640361936, 54.213205217957217],
            [-0.607408961882952, 54.21299608000237],
            [-0.611937305953465, 54.213181350355967],
            [-0.614212316501635, 54.211003636961159],
            [-0.614735712688533, 54.209842991460434],
            [-0.616579371478, 54.208542013192776],
            [-0.618430367955983, 54.20545514313158],
            [-0.618078330829631, 54.204885750632144],
            [-0.617170915816753, 54.205062294160143],
            [-0.616724310520355, 54.20475426672337],
            [-0.6155754019233, 54.204954091352306],
            [-0.614700397207618, 54.205673874128934],
            [-0.612122697140509, 54.204128805508191],
            [-0.61155350868317, 54.204871861804989],
            [-0.610263145105285, 54.205128428993675],
            [-0.608932091943523, 54.204861400822097],
            [-0.60809898281771, 54.204331364411402],
            [-0.606990278330749, 54.204246646441966],
            [-0.604555095790025, 54.202441504819632],
            [-0.603899556917471, 54.202377285410655],
            [-0.60310693392023, 54.202741108573484],
            [-0.601959066020331, 54.202183111205585],
            [-0.599930543274415, 54.202008555585579],
            [-0.599563219348078, 54.201128836792478],
            [-0.598074634750972, 54.200584817474528],
            [-0.599055900008864, 54.200218726750684],
            [-0.599017508669028, 54.199722131948441],
            [-0.597780697605866, 54.199803019167142],
            [-0.596814659138852, 54.199493364328298],
            [-0.593561602550818, 54.200667943539472],
            [-0.589615484449588, 54.200039380341245],
            [-0.587544634611286, 54.198862833038682],
            [-0.585806478281948, 54.198631220822783],
            [-0.584499532423322, 54.199553344100316],
            [-0.579682383254543, 54.20014740134237],
            [-0.579634412565841, 54.200518049280248],
            [-0.578589503907608, 54.200410451600014],
            [-0.576238336241464, 54.201465771991828],
            [-0.571956693292974, 54.201992145199583],
            [-0.5685484362585, 54.201908558243581],
            [-0.566404516568808, 54.20237023057549],
            [-0.565017903578079, 54.20119514090225],
            [-0.56338125479076, 54.201131595097536],
            [-0.56330452304628, 54.20047094347062],
            [-0.562196161576963, 54.199542719102205],
            [-0.562049605728522, 54.198376992681681],
            [-0.561479201335033, 54.197939646686265],
            [-0.560572423748473, 54.198095099128125],
            [-0.560156951513942, 54.196817398896826],
            [-0.559003939064054, 54.197221567442945],
            [-0.557308932842491, 54.19654963434639],
            [-0.556028704420629, 54.19554558941168],
            [-0.556354192521212, 54.194870876819216],
            [-0.555837997700486, 54.194332585514076],
            [-0.554662747747639, 54.194759815405043],
            [-0.553442038052005, 54.194117784094544],
            [-0.552461106171632, 54.194554516243329],
            [-0.551537336295192, 54.194276462121898],
            [-0.551638118748532, 54.192884493006588],
            [-0.551018756784208, 54.192931873746282],
            [-0.550397252870866, 54.1925585747017],
            [-0.548939802514944, 54.192859213731943],
            [-0.548628116231239, 54.193705749267401],
            [-0.547806854080718, 54.193565522785654],
            [-0.547292893983133, 54.193928744284449],
            [-0.546400686467341, 54.193669906398881],
            [-0.544736594300542, 54.193916768334766],
            [-0.544663101148283, 54.194525285323692],
            [-0.543520479165896, 54.195721298439096],
            [-0.540801266069286, 54.197371898037566],
            [-0.540770707522285, 54.197978236204868],
            [-0.5398309396409, 54.198240992610977],
            [-0.540077969911797, 54.199061020012479],
            [-0.538378126168886, 54.199575212621959],
            [-0.537775619697162, 54.20040111464678],
            [-0.537191759369491, 54.200003936359749],
            [-0.535073526283672, 54.200685577116644],
            [-0.532915666183074, 54.200190133058584],
            [-0.532666483514543, 54.200689543288334],
            [-0.53209938074067, 54.200815661919314],
            [-0.53061062666645, 54.19973959416923],
            [-0.52961312355299, 54.199725626983025],
            [-0.528979021175039, 54.200184378506734],
            [-0.527197807729106, 54.199661970428657],
            [-0.524783031890083, 54.200099818474435],
            [-0.523334525123762, 54.199835794820906],
            [-0.522491105693243, 54.199026395576759],
            [-0.521806351933373, 54.199184279648669],
            [-0.521105510665687, 54.198933893848512],
            [-0.521219988297623, 54.198561385960851],
            [-0.520421286375497, 54.198304401908359],
            [-0.520004259672828, 54.197748297228046],
            [-0.518799249943294, 54.198565804988654],
            [-0.518541356988518, 54.198232763609603],
            [-0.517489458520146, 54.198497563004288],
            [-0.51744091299523, 54.198825937387674],
            [-0.516175527717464, 54.198844515261705],
            [-0.51571429618938, 54.199480598575036],
            [-0.516252647926991, 54.200072367916704],
            [-0.515003546509017, 54.200063270505524],
            [-0.514999242427668, 54.200739137597409],
            [-0.514028911857117, 54.200479995744537],
            [-0.513020642728132, 54.201149768180656],
            [-0.512068654120456, 54.200124134628226],
            [-0.510854239773401, 54.200301480478799],
            [-0.505919438391806, 54.2036136984172],
            [-0.505463405326378, 54.204397215935693],
            [-0.504861036248884, 54.204604563167173],
            [-0.504940571534347, 54.205161926260672],
            [-0.503833003789217, 54.20517783959842],
            [-0.50343276253932, 54.205476673759037],
            [-0.502668118275545, 54.204997984168322],
            [-0.502053188844265, 54.20533998602658],
            [-0.501813580727805, 54.204970283879469],
            [-0.500165172213203, 54.205102577179389],
            [-0.500134561120462, 54.20565138199008],
            [-0.499057337347247, 54.205675718700668],
            [-0.498089328604211, 54.206282052273174],
            [-0.496959035664141, 54.205993814271629],
            [-0.496724530241386, 54.204977905488789],
            [-0.495763837416269, 54.204707051990098],
            [-0.495446220557451, 54.203743133737383],
            [-0.493709978419973, 54.202961028086939],
            [-0.492650470191799, 54.203511345573062],
            [-0.490984010371596, 54.203550707475294],
            [-0.490915588859526, 54.204081959422105],
            [-0.489083365920253, 54.204461674181154],
            [-0.488831670850415, 54.204972649985486],
            [-0.487190389701954, 54.204866664615913],
            [-0.486556762190476, 54.206052357460813],
            [-0.484237027194748, 54.206510364951832],
            [-0.483973240942172, 54.207182069885363],
            [-0.483294791069943, 54.207160049162098],
            [-0.48301501755968, 54.207514262718718],
            [-0.481996223645471, 54.207490645798941],
            [-0.481707878986041, 54.208579993954096],
            [-0.480704748986633, 54.208546676476871],
            [-0.480136240228756, 54.208996114121526],
            [-0.475467438962649, 54.209819787659143],
            [-0.474583627596115, 54.210584295758132],
            [-0.473011956795512, 54.210415182392516],
            [-0.472535587666434, 54.211481453586437],
            [-0.471123760057684, 54.211924650265473],
            [-0.469103875188685, 54.211641938209937],
            [-0.467547632950918, 54.211718331538016],
            [-0.456988690674577, 54.214458058645626],
            [-0.451482675107997, 54.214223839478286],
            [-0.448310885323107, 54.21496951964118],
            [-0.4472066744264, 54.214356670207124],
            [-0.446072390715419, 54.214668325945404],
            [-0.445220664997513, 54.214357127709164],
            [-0.442934975069392, 54.215515877194328],
            [-0.441408118427712, 54.215743318340309],
            [-0.43748227701271, 54.21500486524716],
            [-0.43459515970184, 54.205740809272903],
            [-0.431431995578114, 54.200925029504177],
            [-0.428883702487347, 54.199558843576284],
            [-0.426711471935482, 54.193099280537176],
            [-0.423950228687746, 54.188684921759695],
            [-0.423150095450801, 54.184056189855738],
            [-0.421641805928841, 54.179800179814464],
            [-0.418646532308648, 54.175532721601328],
            [-0.418235989417029, 54.174045133076618],
            [-0.418094748657596, 54.171202023784218],
            [-0.422523575937998, 54.171246631058544],
            [-0.422630688957833, 54.170082230304899],
            [-0.424542600546006, 54.169557161985196],
            [-0.422912568007432, 54.166296384472773],
            [-0.433154714220232, 54.164054425088047],
            [-0.433099711599419, 54.16060483714319],
            [-0.430917288735182, 54.158132456028838],
            [-0.431547001957734, 54.15804448304722],
            [-0.431358870954167, 54.157633057154698],
            [-0.432645664318643, 54.157502401932724],
            [-0.427080370644403, 54.137424154986114],
            [-0.430639058437339, 54.136622083075537],
            [-0.438515707767442, 54.133092253917738],
            [-0.443711768259884, 54.131670146418152],
            [-0.452108502162758, 54.127968188205351],
            [-0.458526683377951, 54.125955332093689],
            [-0.453691093216851, 54.120688978256567],
            [-0.453590884598408, 54.120092652737078],
            [-0.45469019868586, 54.119950371367423],
            [-0.454338233768381, 54.116744150834137],
            [-0.459426494735492, 54.115311902912488],
            [-0.458666810175742, 54.114015024629751],
            [-0.468312749178136, 54.111232188692256],
            [-0.465929838002272, 54.107614532620538],
            [-0.489838490945353, 54.100866359583975],
            [-0.493998816390022, 54.099166642925361],
            [-0.496877268907159, 54.097213483510799],
            [-0.502871711315789, 54.094076632705402],
            [-0.513263584493391, 54.090273025933257],
            [-0.519913012497869, 54.086969050910227],
            [-0.524600664635062, 54.085456339735813],
            [-0.525094480970594, 54.086533816872382],
            [-0.526298914463351, 54.087362028793663],
            [-0.527915939664926, 54.089320629364984],
            [-0.534019169223198, 54.087161515435923],
            [-0.535821264260499, 54.08765175506845],
            [-0.541804573608804, 54.091510336905749],
            [-0.545508971014311, 54.091041931809222],
            [-0.550617365371757, 54.092665755755476],
            [-0.55666377144192, 54.092696185434349],
            [-0.563125536063563, 54.093981556548719],
            [-0.566454857954687, 54.090287488089857],
            [-0.583947389652782, 54.088460541993861],
            [-0.590993183843938, 54.087043992291598],
            [-0.592280011912059, 54.086244708042138],
            [-0.594093622796105, 54.084196790697355],
            [-0.598736800478252, 54.082189023456813],
            [-0.60272638471022, 54.079056237007862],
            [-0.605846102352591, 54.07778102492157],
            [-0.607973541672882, 54.07481882572425],
            [-0.609688815854403, 54.073251314930268],
            [-0.61551245173071, 54.070475455075581],
            [-0.618445782142378, 54.069811679756313],
            [-0.621504481566577, 54.069650820060737],
            [-0.627737531881836, 54.070175990247186],
            [-0.627452333911062, 54.068814597636525],
            [-0.632186135056637, 54.068736383585055],
            [-0.642912166383121, 54.066539765189255],
            [-0.648102466428312, 54.065994187682669],
            [-0.653665383150633, 54.064978872916434],
            [-0.660306129263902, 54.063081871261474],
            [-0.656482871066202, 54.061055509843207],
            [-0.656220004679291, 54.061294359676985],
            [-0.652420286517948, 54.059826304972198],
            [-0.644643293433156, 54.055094654550309],
            [-0.653643994236568, 54.045937741793644],
            [-0.656288798985513, 54.0454334110362],
            [-0.655727925977236, 54.044213716441703],
            [-0.658007208994043, 54.043626164988297],
            [-0.657963164702033, 54.042148887738094],
            [-0.656308089663323, 54.040621263427866],
            [-0.658918708567523, 54.03836825965314],
            [-0.659474010129086, 54.037151135535296],
            [-0.664250224100642, 54.037635720499381],
            [-0.669721162139423, 54.037408734202799],
            [-0.670607113222915, 54.037543468781394],
            [-0.670563023767303, 54.037968129999854],
            [-0.674237083018107, 54.038159692245813],
            [-0.678491851177732, 54.037231286278313],
            [-0.679858460208901, 54.036654863145088],
            [-0.680464594500257, 54.035885822094507],
            [-0.680233490924801, 54.034504475539535],
            [-0.680683434702572, 54.033651925253707],
            [-0.680888163992501, 54.033503167045254],
            [-0.682594267410201, 54.034112402775165],
            [-0.682664056473925, 54.033740151405382],
            [-0.684325334451655, 54.032868492910879],
            [-0.687291074777788, 54.030425516260593],
            [-0.687742998613882, 54.028825132089793],
            [-0.68720872481924, 54.026059880710292],
            [-0.688216314305474, 54.025136988182773],
            [-0.688473688488877, 54.023991984464992],
            [-0.687499168121981, 54.023186781757204],
            [-0.684135634147649, 54.022627797657812],
            [-0.683813744675958, 54.020977608910918],
            [-0.681791805844724, 54.019801354540803],
            [-0.679102134132771, 54.01898895091508],
            [-0.67840607495884, 54.018219988037352],
            [-0.679408453302179, 54.015967732073364],
            [-0.683061936896919, 54.012449312218443],
            [-0.6815822547512, 54.011222362273017],
            [-0.681788798359858, 54.010336576281759],
            [-0.683671758859023, 54.008745592761599],
            [-0.687945534416565, 54.008274580352577],
            [-0.688232005221537, 54.007996370577835],
            [-0.698590524034096, 54.007100453552709],
            [-0.704892932061776, 54.006053956621436],
            [-0.708654800130704, 54.008054780943382],
            [-0.709360728768487, 54.008961195446467],
            [-0.70963515583961, 54.010449014757626],
            [-0.710736548250597, 54.011152932491505],
            [-0.713280676644507, 54.011047159995236],
            [-0.715051900561081, 54.010469277827653],
            [-0.718050044411436, 54.010087820233814],
            [-0.719252487980903, 54.010685775630193],
            [-0.719688097286375, 54.012303819656317],
            [-0.726187738777528, 54.012252413714101],
            [-0.72782845987437, 54.012696730451466],
            [-0.730479726039054, 54.012682509178795],
            [-0.730581847776046, 54.021711441638857],
            [-0.732262195915907, 54.026538805396129],
            [-0.734247352377809, 54.030240420358183],
            [-0.744641547802364, 54.027548628454753],
            [-0.749427484858184, 54.026940553571592],
            [-0.752598063917487, 54.026080050448357],
            [-0.761554941887481, 54.02498707727618],
            [-0.766333724997544, 54.023673578566651],
            [-0.769696576140559, 54.023467146712512],
            [-0.771640974912738, 54.023893293792668],
            [-0.772263246660515, 54.023652471812674],
            [-0.774873830988469, 54.02405840150923],
            [-0.775082661715081, 54.023808857959615],
            [-0.778827572513446, 54.023534156089262],
            [-0.779949276625379, 54.023021527465417],
            [-0.792959856938783, 54.02057859931093],
            [-0.793961452673236, 54.019935204577024],
            [-0.795537977431037, 54.01994201829234],
            [-0.7991815087401, 54.019036492718378],
            [-0.800291893739715, 54.019965274694471],
            [-0.798978458637273, 54.024072366542832],
            [-0.80324390255566, 54.023952205697348],
            [-0.803311930103637, 54.023337189591999],
            [-0.808466012631952, 54.022351130511559],
            [-0.81566027264534, 54.021754378185818],
            [-0.82035654718398, 54.020854981093663],
            [-0.823505154016477, 54.020812124764134],
            [-0.826983571934751, 54.021253272475299],
            [-0.836531286308547, 54.021248958025659],
            [-0.836543487767984, 54.021741628916409],
            [-0.836992774471382, 54.021887089651884],
            [-0.842394123417658, 54.021085330308587],
            [-0.843147061333427, 54.02167501061075],
            [-0.845402046942112, 54.020059036439321],
            [-0.848493389090497, 54.018659558270606],
            [-0.850948335274498, 54.018358569049845],
            [-0.852067438774077, 54.017902771282785],
            [-0.854870918625856, 54.017690416735427],
            [-0.859721106882098, 54.016360419130173],
            [-0.861297987204981, 54.016964087819034],
            [-0.863507005761124, 54.017335525336343],
            [-0.866599582752379, 54.017208320842037],
            [-0.86815996730821, 54.016850009618473],
            [-0.868808189829103, 54.017090700590501],
            [-0.870391227416996, 54.016684037514864],
            [-0.873959003144347, 54.017438351661013],
            [-0.876161549825838, 54.017601868929802],
            [-0.877884010163494, 54.017003165058718],
            [-0.878435165430903, 54.017166498664814],
            [-0.878709386088332, 54.016844583647341],
            [-0.877233765620772, 54.014908247112928],
            [-0.877659786208465, 54.013538833301183],
            [-0.880502079801529, 54.011978020840161],
            [-0.886692771472994, 54.010509311625334],
            [-0.88854637166331, 54.009496417543204],
            [-0.88991151763838, 54.007052565500267],
            [-0.890870096639914, 54.003079656334748],
            [-0.892464161272543, 54.001596923299616],
            [-0.893526183306508, 53.999749747091762],
            [-0.894875886024373, 53.999075465660191],
            [-0.897407925108182, 53.998513584947041],
            [-0.898803449815556, 53.998758270365499],
            [-0.899200540610766, 53.999551067655482],
            [-0.899950043728656, 53.999708030332066],
            [-0.901336439221175, 53.998794927444322],
            [-0.90369316688921, 53.99868880980609],
            [-0.907482482542527, 53.997529677845129],
            [-0.909546385789622, 53.996308050544599],
            [-0.911888001302412, 53.995429553174418],
            [-0.911927126667077, 53.992875472702053],
            [-0.91323787010242, 53.992037049376862],
            [-0.915238175801624, 53.993128302135936],
            [-0.920606274008195, 53.991933560029395],
            [-0.925286399214759, 53.99150001861819],
            [-0.93278919737498, 53.990072131711493],
            [-0.934149022188578, 53.989262679847236],
            [-0.935106456675094, 53.989756524964768],
            [-0.944353462074174, 53.989110045127994],
            [-0.953254665421399, 53.986488747117896],
            [-0.956568004905596, 53.985052488174688],
            [-0.957677094259218, 53.983937695167391],
            [-0.96223360904697, 53.98449843096062],
            [-0.967036311056093, 53.985900599790895],
            [-0.970072341339279, 53.984804034513324],
            [-0.972684407083238, 53.984569326188819],
            [-0.973375521259177, 53.984868243298436],
            [-0.976303662701942, 53.982066452825585],
            [-0.97676170394872, 53.981981370456772],
            [-0.979234095863443, 53.983022542661494],
            [-0.980344850865888, 53.982312022044717],
            [-0.984648972491223, 53.981529638840364],
            [-0.991050645236795, 53.981905317547799],
            [-0.993171237468625, 53.982751801621546],
            [-0.994827885182886, 53.983046095001448],
            [-0.995809266229769, 53.983962994348509],
            [-0.994060274461052, 53.984461591245065],
            [-0.994768774267564, 53.985311500641274],
            [-0.992830079211391, 53.985831862330102],
            [-0.990496784691958, 53.987721362484123],
            [-0.987928161018497, 53.989054270943448],
            [-0.981253251202947, 53.991144248359817],
            [-0.98318119556528, 53.993335690652685],
            [-0.978855346232886, 53.994518709388728],
            [-0.9786192934968, 53.99506407930209],
            [-0.979093716992236, 53.9959363591501],
            [-0.971921115121012, 53.996677873822122],
            [-0.972747620243345, 54.001710183694946],
            [-0.973741354597944, 54.003487526445547],
            [-0.975410443664432, 54.004732230778593],
            [-0.980159249119319, 54.003208678114134],
            [-0.980038706509103, 54.00283465005564],
            [-0.984505801372696, 54.002213636450442],
            [-0.98477257264338, 54.002661698210268],
            [-0.980870730232425, 54.004000269472108],
            [-0.97933433664629, 54.005371401523142],
            [-0.979915384283148, 54.007379775641915],
            [-0.982744213356343, 54.010012979823543],
            [-0.976931360148799, 54.015756474995086],
            [-0.979412310602004, 54.017402599380269],
            [-0.971756746716767, 54.020373551553561],
            [-0.966835884165585, 54.022958587491708],
            [-0.970609887015339, 54.023055665533533],
            [-0.970134173251916, 54.025938558134953],
            [-0.971124801350299, 54.02767454199725],
            [-0.973307790733417, 54.029288577286778],
            [-0.975773895565592, 54.030136505055985],
            [-0.98230464537078, 54.030905595050434],
            [-0.981350109613933, 54.033855478491283],
            [-0.98224339014431, 54.039416746890026],
            [-0.982511701497252, 54.039560128951038],
            [-0.984598108520796, 54.038851527461873],
            [-0.985556515229526, 54.039627191940099],
            [-0.987261328256461, 54.040292290977767],
            [-0.988702586418466, 54.040933578740237],
            [-0.98925622998023, 54.041717493680181],
            [-0.990575401208376, 54.041917319673352],
            [-0.992312309432062, 54.041704486203678],
            [-0.993266606340229, 54.042279620847196],
            [-0.992916604268723, 54.043681517837072],
            [-0.993958865761061, 54.045675691267071],
            [-0.99488080975984, 54.046203804049824],
            [-0.993777132817013, 54.047256060938885],
            [-0.99596848614811, 54.048157892053695],
            [-0.996496705789084, 54.049055707263484],
            [-0.998167922260741, 54.04936264570884],
            [-0.998745744128935, 54.050423546487416],
            [-0.999781828953002, 54.050975939375469],
            [-1.000393142806666, 54.05096214575898],
            [-1.000899423543121, 54.051442715388184],
            [-1.000660703587061, 54.051970125535064],
            [-1.002093747701749, 54.0538083815755],
            [-1.001551795891289, 54.054750320422059],
            [-1.002330790946888, 54.055327520419176],
            [-1.004419818811146, 54.055550651131377],
            [-1.006199169300417, 54.053836074129677],
            [-1.007101704289826, 54.054167093948649],
            [-1.008010902695543, 54.053256920882767],
            [-1.012424736954675, 54.051291611043801],
            [-1.014367331079953, 54.04930501918188],
            [-1.024451772957325, 54.052018900983747],
            [-1.028453621257886, 54.052746955110486],
            [-1.035850011579379, 54.053598299285603],
            [-1.047448082020446, 54.054425904279974],
            [-1.059702192490938, 54.05686635962217],
            [-1.059702311915697, 54.056590430789129],
            [-1.060595218258477, 54.056760087793151],
            [-1.059345435800271, 54.059066514615893],
            [-1.05815642627646, 54.063948446599461],
            [-1.055644378128659, 54.067390008553055],
            [-1.054152306852538, 54.067016974190771],
            [-1.048969964513903, 54.0683881293143],
            [-1.045111568056436, 54.069785754184529],
            [-1.042788222155879, 54.071006730363422],
            [-1.042136796704124, 54.072153799519477],
            [-1.040984544030941, 54.07301465361008],
            [-1.039812470228141, 54.073341455423787],
            [-1.039085753624034, 54.075692289897937],
            [-1.042105738780733, 54.07768652066936],
            [-1.04514233389314, 54.075039442856649],
            [-1.049452287050082, 54.07570631476861],
            [-1.046440455382592, 54.07908351547426],
            [-1.05222531620923, 54.081653020250265],
            [-1.054298275165772, 54.082249048045369],
            [-1.054779365907025, 54.082872993144356],
            [-1.054099227591155, 54.083272105079743],
            [-1.060185902959948, 54.085114674604441],
            [-1.057754663475734, 54.088178513465238],
            [-1.054571231060093, 54.089139520768185],
            [-1.046770337603787, 54.094506680454408],
            [-1.04680993262655, 54.094980656095977],
            [-1.045471408049483, 54.095872433128015],
            [-1.045747788692923, 54.096222456104258],
            [-1.042789458800494, 54.098259890262767],
            [-1.041199217243754, 54.098122301737732],
            [-1.039454856742255, 54.098368144305056],
            [-1.040129390803174, 54.100878451159588],
            [-1.040988228078851, 54.101568376378339],
            [-1.042352118139798, 54.104766339381278],
            [-1.043979480119887, 54.106293732833564],
            [-1.043768854691397, 54.107471269729174],
            [-1.03393164672574, 54.107374750538618],
            [-1.025043070278088, 54.106613718013357],
            [-1.025092050636822, 54.107916460628729],
            [-1.023396689548083, 54.108268525921034],
            [-1.021138253446855, 54.109406924931385],
            [-1.019456303936547, 54.10782484859233],
            [-1.016246896860387, 54.107907417277715],
            [-1.016192001452705, 54.108934285662208],
            [-1.015612510585886, 54.109312432915871],
            [-1.015574846354297, 54.112065117940084],
            [-1.01470875361109, 54.113948186802858],
            [-1.015927672153738, 54.114671795294328],
            [-1.015619374925556, 54.115480881401794],
            [-1.012361396273842, 54.11977018287147],
            [-1.008526990853073, 54.123756252680266],
            [-1.012526260361187, 54.126738042359165],
            [-1.011616455961255, 54.127866644987613],
            [-1.012210041553588, 54.128388318905358],
            [-1.012034242042972, 54.128830877560276],
            [-1.012656265805585, 54.129122692908581],
            [-1.01367802365505, 54.131528131995999],
            [-1.012805859391845, 54.133065999306858],
            [-1.013071340269434, 54.134581732133],
            [-1.01371689699536, 54.135111013836543],
            [-1.017875615402445, 54.135748988409091],
            [-1.018407032515771, 54.136318657730108],
            [-1.023029391024865, 54.137230766175236],
            [-1.029306149492034, 54.140453336303828],
            [-1.033775993857293, 54.140976423542632],
            [-1.034063644296914, 54.14137689232961],
            [-1.033698486587003, 54.14253069746362],
            [-1.034201311665721, 54.143355320341321],
            [-1.040735369103214, 54.143603478236251],
            [-1.044331994200082, 54.145105177150512],
            [-1.046329035939148, 54.147472221409089],
            [-1.047167982847852, 54.14694049136876],
            [-1.04837683491765, 54.146834106357019],
            [-1.052359888918961, 54.147327486302423],
            [-1.05319383868103, 54.147816690795153],
            [-1.053936301174329, 54.14916350666477],
            [-1.056323008103518, 54.149913838402654],
            [-1.057103920860382, 54.151393954512407],
            [-1.060394877582722, 54.152926028446828],
            [-1.063168278652719, 54.153586664496679],
            [-1.065407327077785, 54.153364983763844],
            [-1.067649695925011, 54.153539648825358],
            [-1.069161318126252, 54.154618191232252],
            [-1.075046893964224, 54.155743863707855],
            [-1.077083937919569, 54.156758031743976],
            [-1.077656770522288, 54.157441895618646],
            [-1.082631427875259, 54.160626953999632],
            [-1.088697374561755, 54.162904210209824],
            [-1.08888078193746, 54.163549121282969],
            [-1.082110725475366, 54.171113054561431],
            [-1.080595590540079, 54.172085660227118],
            [-1.080860812255159, 54.172491234178381],
            [-1.081894027378907, 54.172459566442733],
            [-1.082347216824346, 54.173475040153754],
            [-1.083664149881004, 54.174019835110393],
            [-1.087451564729027, 54.174420675256883],
            [-1.088796083136472, 54.175492304382054],
            [-1.092607906568843, 54.176256270887528],
            [-1.09425165181213, 54.176263246919852],
            [-1.097491319005363, 54.177559323151357],
            [-1.098153604502763, 54.178455865462283],
            [-1.097682444444517, 54.183138539495914],
            [-1.099144967881032, 54.185292156166412],
            [-1.100003802764484, 54.184695498075556],
            [-1.101253857258764, 54.184712018875871],
            [-1.101484521321269, 54.18417986858546],
            [-1.102674330725679, 54.18351016236948],
            [-1.104877422161592, 54.183386340867116],
            [-1.105531732589029, 54.182888786469164],
            [-1.107266084691911, 54.183040958784467],
            [-1.109724627017911, 54.182579205484231],
            [-1.111883100718633, 54.183676356772153],
            [-1.112842738873778, 54.183729263600725],
            [-1.117639063865924, 54.182931322004002],
            [-1.119360252397011, 54.182262645749077],
            [-1.119642838393604, 54.18151064191089],
            [-1.12030168540212, 54.181367156800626],
            [-1.120129650241265, 54.181026165159238],
            [-1.121943035981727, 54.180918059801961],
            [-1.12265901201394, 54.181187514014404],
            [-1.124503654612275, 54.180397430646053],
            [-1.12610202697555, 54.180529469579881],
            [-1.126475546995316, 54.18127276516519],
            [-1.125954208736646, 54.182380763583396],
            [-1.128966316014096, 54.183199785172995],
            [-1.129027631496766, 54.183563331526003],
            [-1.13040288150798, 54.184111627816215],
            [-1.130760497481611, 54.184960849349757],
            [-1.132561400983196, 54.185529267129034],
            [-1.133011445400649, 54.185991777330443],
            [-1.134551332417431, 54.185927350645549],
            [-1.135703613307462, 54.187401513712366],
            [-1.135793086184044, 54.187961189735056],
            [-1.134973656694761, 54.189041022886606],
            [-1.135797337543892, 54.189741681190803],
            [-1.137659461284062, 54.189959041922386],
            [-1.139487323754431, 54.191089277678024],
            [-1.142832937777934, 54.191388145210745],
            [-1.144546671992501, 54.193750600397102],
            [-1.146746062949727, 54.195091874846668],
            [-1.150701669018363, 54.19608419383286],
            [-1.155607596127119, 54.196485364967572],
            [-1.160727122364376, 54.198377977173962],
            [-1.162596763427656, 54.199462311251011],
            [-1.164185721821036, 54.199571306976075],
            [-1.16515487737302, 54.200998971816119],
            [-1.167192042513453, 54.201616137310893],
            [-1.168703465151089, 54.202461524128957],
            [-1.174344512949612, 54.203253465436759],
            [-1.175475783187342, 54.20248917189808],
            [-1.181655498861209, 54.201318858644484],
            [-1.182241257920545, 54.201621224083027],
            [-1.184457359587969, 54.201184162405823],
            [-1.186822425494155, 54.201512014074908],
            [-1.188314205780086, 54.200093935372756],
            [-1.189308876992668, 54.200182417749502],
            [-1.19072686218698, 54.202692306294331],
            [-1.190116819912346, 54.203706506716557],
            [-1.187578042828967, 54.205349435014362],
            [-1.187812366971945, 54.206221915175796],
            [-1.18920555395312, 54.207811319299807],
            [-1.188584043703909, 54.208465929503681],
            [-1.190174792625117, 54.20983735342999],
            [-1.192146554332303, 54.209682516544916],
            [-1.196055676777976, 54.210826712486416],
            [-1.197034682455747, 54.210630118267908],
            [-1.199651765226069, 54.212311130452093],
            [-1.199397656466466, 54.212606033988735],
            [-1.199888785109688, 54.212900493490373],
            [-1.199514460475689, 54.2144960044822],
            [-1.200190813450242, 54.216316888611559],
            [-1.196206173008169, 54.217292502156425],
            [-1.193704834188815, 54.218557429371508],
            [-1.193094071580664, 54.220072247309758],
            [-1.192037454319042, 54.22108975650977],
            [-1.193366385540358, 54.221917426363767],
            [-1.196664155346153, 54.226330785034683],
            [-1.196411647257064, 54.228986731708567],
            [-1.197316849332281, 54.230482004463802],
            [-1.198348680916127, 54.234154903255479],
            [-1.197286845336759, 54.240411277711722],
            [-1.197008376995893, 54.241081693600343],
            [-1.203504171526457, 54.238824859840541],
            [-1.208378309714993, 54.236366504856079],
            [-1.215054628681061, 54.241504301443179],
            [-1.212124060389264, 54.245438813105316],
            [-1.214004108744537, 54.249225851644965],
            [-1.205446315998102, 54.251305210388118],
            [-1.210839080414489, 54.26540970387186],
            [-1.218927509080211, 54.283106620404112],
            [-1.219737767590235, 54.286561261178413],
            [-1.227869895769938, 54.296346114412465],
            [-1.231670043547375, 54.299462058938374],
            [-1.236213711651977, 54.301460056306937],
            [-1.240954496749368, 54.304629285870874],
            [-1.247124214236374, 54.308197303195136],
            [-1.249930410683436, 54.311762116312316],
            [-1.249493260194589, 54.315345381045518],
            [-1.246711821140992, 54.323293601072365],
            [-1.246582961619268, 54.331525260997076],
            [-1.249096325155446, 54.332337205501517],
            [-1.2584903456622, 54.336646311102292],
            [-1.260603828879469, 54.33825992738133],
            [-1.263713523153842, 54.342493114284657],
            [-1.265628641534125, 54.343699200494392],
            [-1.26598380282386, 54.345868214796546],
            [-1.265507652869641, 54.347639424967198],
            [-1.26371284675771, 54.348988281135213],
            [-1.264778926471077, 54.354078926630073],
            [-1.254558589246168, 54.353859881326557],
            [-1.250857488067799, 54.361257837222524],
            [-1.238470139078246, 54.375937582188648],
            [-1.229218904180676, 54.377530768804114],
            [-1.227159771443344, 54.377490647949969],
            [-1.225028240789519, 54.378025217617179],
            [-1.222249385218018, 54.376964822317106],
            [-1.208730813938296, 54.373770078770903],
            [-1.190152879517377, 54.377242775873292],
            [-1.180890730206174, 54.376721057463669],
            [-1.172181588967401, 54.381858178956527],
            [-1.166022220532936, 54.377860456855984],
            [-1.16305990823633, 54.372496059872667],
            [-1.159306475164292, 54.35970606853428],
            [-1.157533608082875, 54.349253015901844],
            [-1.153030798562859, 54.349237688139105],
            [-1.149418561085477, 54.3487107933503],
            [-1.145549860048091, 54.348908160887973],
            [-1.142827098418856, 54.347265726654321],
            [-1.141668235776022, 54.346917767322488],
            [-1.140654622523382, 54.345876100634037],
            [-1.137005165798166, 54.346045994149122],
            [-1.136629850674267, 54.345236236960851],
            [-1.133291772316166, 54.34457330942854],
            [-1.132926590592447, 54.343578471243866],
            [-1.130835309348582, 54.34383395513656],
            [-1.130551496567666, 54.343360069494551],
            [-1.12785619174855, 54.343881672718176],
            [-1.127114575907655, 54.343744196858886],
            [-1.12494962357106, 54.344279455200855],
            [-1.124139872837732, 54.344814628547915],
            [-1.121789725276555, 54.345228953333446],
            [-1.121148023713853, 54.344517868302788],
            [-1.121612857661475, 54.343928078350828],
            [-1.120869513327213, 54.34380043768472],
            [-1.120886008678285, 54.343171434740576],
            [-1.121619175540696, 54.342621345868871],
            [-1.121063858893743, 54.341972902047253],
            [-1.118729094464705, 54.340954675166962],
            [-1.119048583955039, 54.338960888062523],
            [-1.118572423083693, 54.338714750145911],
            [-1.119552218030979, 54.337849216121668],
            [-1.119182534285529, 54.337299181458448],
            [-1.120373216454877, 54.336639195264347],
            [-1.120685409340247, 54.337072869007756],
            [-1.12105105329375, 54.337019809611469],
            [-1.120815170166777, 54.334738865953341],
            [-1.121440718305082, 54.33389140699235],
            [-1.120783897408559, 54.333176613024733],
            [-1.122560574714213, 54.331422601308795],
            [-1.116654904567967, 54.328644630768004],
            [-1.115112811934685, 54.330281641869576],
            [-1.113485986609932, 54.329774493010014],
            [-1.102970784580156, 54.328503261291786],
            [-1.099022566899813, 54.329178473947366],
            [-1.085297894756423, 54.347075872488709],
            [-1.078259989678966, 54.358545654947513],
            [-1.078582240924415, 54.359205099675783],
            [-1.078017221342874, 54.359697794222825],
            [-1.07815140162825, 54.360234472691985],
            [-1.070477577785874, 54.36102315947663],
            [-1.066199293677678, 54.36265463038486],
            [-1.06719128537525, 54.3651293628212],
            [-1.067222004560128, 54.375458007349742],
            [-1.07881225130053, 54.388200405310521],
            [-1.070415118273769, 54.395756520688757],
            [-1.081798677836561, 54.405012555281949],
            [-1.070928971061857, 54.405668215163217],
            [-1.059367628342085, 54.412565572572973],
            [-1.026859274690606, 54.415331472661599],
            [-1.009153482536084, 54.407151867784478],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000168",
        LAD13CDO: "36UG",
        LAD13NM: "Scarborough",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-0.340905317750318, 54.24343158280405],
              [-0.337158500646086, 54.242760645233538],
              [-0.338943301540594, 54.242913789769098],
              [-0.340905317750318, 54.24343158280405],
            ],
          ],
          [
            [
              [-0.792211091266402, 54.559451973368112],
              [-0.78960553323179, 54.558557751400954],
              [-0.788307369185949, 54.558738889415508],
              [-0.78857685190095, 54.558986046525533],
              [-0.787666118591675, 54.558721682042211],
              [-0.785558557939191, 54.558767057219896],
              [-0.783668978498823, 54.559334062810244],
              [-0.781605317236027, 54.558009220419912],
              [-0.778843076875041, 54.55709071848672],
              [-0.77626724620778, 54.556922695463136],
              [-0.775625090794056, 54.557762820961649],
              [-0.774519908058239, 54.557385843273167],
              [-0.775320025583153, 54.55473184982899],
              [-0.774167979826618, 54.553239046371836],
              [-0.771891111411361, 54.552083534998296],
              [-0.769378305139903, 54.55204182805641],
              [-0.76824625280996, 54.550818794500039],
              [-0.765120497924254, 54.548652402148107],
              [-0.765186014176473, 54.548325925459494],
              [-0.765870337126069, 54.548959336494178],
              [-0.767224678146115, 54.548548932249631],
              [-0.767516066605623, 54.548114209115987],
              [-0.765578312636925, 54.548236458239977],
              [-0.767698186562944, 54.547252364632776],
              [-0.767241232318407, 54.546410074054435],
              [-0.76219803142632, 54.543968805565129],
              [-0.755109007107507, 54.543502419790457],
              [-0.753109596746225, 54.542407821132073],
              [-0.750931860032343, 54.541891942490068],
              [-0.749544104800883, 54.54011065772827],
              [-0.74896844662521, 54.537655601828313],
              [-0.747801586936207, 54.536996426893459],
              [-0.747103118833891, 54.53567970997009],
              [-0.747847332461536, 54.534239521187757],
              [-0.749614102994393, 54.533320393926175],
              [-0.749539027284022, 54.532692287072663],
              [-0.747544614400093, 54.530345676593576],
              [-0.744641098854298, 54.528526157549564],
              [-0.741234556153162, 54.527847248073023],
              [-0.736078448373442, 54.527433981319092],
              [-0.731273717927505, 54.527901367504448],
              [-0.727304091862755, 54.530480445668182],
              [-0.725823983072039, 54.530667983965934],
              [-0.720988759200191, 54.530117248154653],
              [-0.719045370591346, 54.530540671718335],
              [-0.717894888585889, 54.531599804010746],
              [-0.716277101857985, 54.532183017957621],
              [-0.716409421604818, 54.534319872270849],
              [-0.715778552856443, 54.53437878622001],
              [-0.714932394649574, 54.533665174046597],
              [-0.71296462280009, 54.533628077318376],
              [-0.712132840214598, 54.532852583700816],
              [-0.709651545645791, 54.53189952150661],
              [-0.707961512257597, 54.531634323852103],
              [-0.705441717339697, 54.530145101906463],
              [-0.704792316624845, 54.527373563221239],
              [-0.704067770398569, 54.527270534409624],
              [-0.702409337749416, 54.526064593459687],
              [-0.700095719880212, 54.525738667432485],
              [-0.697129438474665, 54.522714775256979],
              [-0.694738437897664, 54.521673395499803],
              [-0.693696778876533, 54.520376020480533],
              [-0.692172053818931, 54.520225627995515],
              [-0.688741439160538, 54.520828404681531],
              [-0.687278195036884, 54.520254400516848],
              [-0.682361160209667, 54.519658980053244],
              [-0.681813833448532, 54.519431920202528],
              [-0.681324524361243, 54.517979575378959],
              [-0.67832800871183, 54.516047777009099],
              [-0.678636463261271, 54.51496274241368],
              [-0.677500176347849, 54.514081216270206],
              [-0.677732761450518, 54.513815028381913],
              [-0.676838902607669, 54.513750427787365],
              [-0.676871768577754, 54.513498234132655],
              [-0.675752622923254, 54.513187594962389],
              [-0.67324469127281, 54.512928226664265],
              [-0.672652839786729, 54.512506502314821],
              [-0.672771864568229, 54.510883737230586],
              [-0.674024691406799, 54.510164086210395],
              [-0.674349138200002, 54.507203509842],
              [-0.67507278413015, 54.506032257759372],
              [-0.672489779887442, 54.504481418298624],
              [-0.672259164523034, 54.503925245516456],
              [-0.673428316397196, 54.503589354828243],
              [-0.671817373525977, 54.503690309435179],
              [-0.669376859228512, 54.502277579178667],
              [-0.669121069542259, 54.501784935470212],
              [-0.67026222629555, 54.500740537824399],
              [-0.668794405716603, 54.501477556040037],
              [-0.669215289944401, 54.501451630526788],
              [-0.668765723286625, 54.501742377007893],
              [-0.669099717617772, 54.502204425345077],
              [-0.664397097865153, 54.500269655540194],
              [-0.654226413783834, 54.497602584454391],
              [-0.639884456929541, 54.49560502053037],
              [-0.630536724230048, 54.493295715557792],
              [-0.630260372546996, 54.493433692775206],
              [-0.629597657712854, 54.492936351274452],
              [-0.625658328546772, 54.49194432935851],
              [-0.621362373656721, 54.491370536517827],
              [-0.614220277970538, 54.490959935572391],
              [-0.613067204633591, 54.492732583206163],
              [-0.613573699362122, 54.493505947184119],
              [-0.613021454565629, 54.49431210738485],
              [-0.613118485010227, 54.4929965129501],
              [-0.612730232768846, 54.493108899839058],
              [-0.612521191969127, 54.492716432152882],
              [-0.614014022682104, 54.490530652434927],
              [-0.614551003598775, 54.488803965210479],
              [-0.61427505841663, 54.487787881077971],
              [-0.613394684309981, 54.486887997855121],
              [-0.613335092722021, 54.485638013760386],
              [-0.613910556034841, 54.485556532594487],
              [-0.613980860711293, 54.485212208065427],
              [-0.612633133846824, 54.485488852374509],
              [-0.612594264910842, 54.484220230969683],
              [-0.613256356136859, 54.483220297430123],
              [-0.613503309593421, 54.480346141410557],
              [-0.614627129122094, 54.478468894700356],
              [-0.614171222975054, 54.476340754165463],
              [-0.615283458330612, 54.474528080230805],
              [-0.616509774426626, 54.474362365863811],
              [-0.618447081455702, 54.474713475704576],
              [-0.62109085693695, 54.475122047807147],
              [-0.623105603332642, 54.474594062341197],
              [-0.623870203372418, 54.473792071387251],
              [-0.623192564316877, 54.472831650418087],
              [-0.623114172181512, 54.471535618111794],
              [-0.624807845265987, 54.470130334947022],
              [-0.627013791428672, 54.469505595063758],
              [-0.628573018502183, 54.469576323711784],
              [-0.632249361752247, 54.469045457541043],
              [-0.633562593944409, 54.468209167963273],
              [-0.631942966964794, 54.468886501637691],
              [-0.625487999495607, 54.46949634339628],
              [-0.623063219105258, 54.470617384988948],
              [-0.622369645192354, 54.471327606315434],
              [-0.620614575803028, 54.471067617300726],
              [-0.620475738522167, 54.470699331166522],
              [-0.620647184467232, 54.471199211093726],
              [-0.622291627197365, 54.471582868974757],
              [-0.622251284791322, 54.472599826234578],
              [-0.6231523250431, 54.473754237148519],
              [-0.622672093724203, 54.474359935355359],
              [-0.620597458453405, 54.474787465823226],
              [-0.617559669366905, 54.473661645884732],
              [-0.615449205093344, 54.47377859962846],
              [-0.613110143037842, 54.475817180016399],
              [-0.613628132700329, 54.47788761647567],
              [-0.613310607549081, 54.47951795387052],
              [-0.612872246213508, 54.479564155109003],
              [-0.612374142769837, 54.480417670831912],
              [-0.611238879012385, 54.480987941730469],
              [-0.608468773675464, 54.480654996931044],
              [-0.60725013340203, 54.480224828736084],
              [-0.608919452615639, 54.480883080837565],
              [-0.611339231425367, 54.481123911268149],
              [-0.61086400417899, 54.481300903492517],
              [-0.609793474690031, 54.483465438861302],
              [-0.611393364985213, 54.486405752709288],
              [-0.61243284994836, 54.4867538301989],
              [-0.612930037939421, 54.488210161978742],
              [-0.613537019042529, 54.488247682588373],
              [-0.612562666805809, 54.488391094791446],
              [-0.612281996909438, 54.489157226862247],
              [-0.613092400406438, 54.489707589659147],
              [-0.612752432731368, 54.489534719280499],
              [-0.610561223071651, 54.489914007276475],
              [-0.609616096580294, 54.490605087210767],
              [-0.61218384464868, 54.492009717081352],
              [-0.612002615327525, 54.493075383457899],
              [-0.611528698874482, 54.492981861994025],
              [-0.612396662859315, 54.494205174541854],
              [-0.611113545105144, 54.49306787025828],
              [-0.611049220309533, 54.492870299227548],
              [-0.611665242703944, 54.492825245237803],
              [-0.611403342196593, 54.492307238582136],
              [-0.609329650977676, 54.49049304075082],
              [-0.6075116553056, 54.490563791469945],
              [-0.60712563458677, 54.490338245679553],
              [-0.606699361950216, 54.490615550261644],
              [-0.606267689146479, 54.490370601276688],
              [-0.604968889694275, 54.490382585673871],
              [-0.600281738773087, 54.489071021871681],
              [-0.598427779825823, 54.489382092063302],
              [-0.596827644907986, 54.488501602658047],
              [-0.595049198415755, 54.4886364378849],
              [-0.593315653836115, 54.487822658161448],
              [-0.592750733064115, 54.487639923103821],
              [-0.591478247275573, 54.487921702316306],
              [-0.588537698381671, 54.489256250537963],
              [-0.588387944232266, 54.488942625446988],
              [-0.589447556951346, 54.48861255733425],
              [-0.589600324525025, 54.488255726326962],
              [-0.588306541019569, 54.485883125360495],
              [-0.585203778752454, 54.483868637876888],
              [-0.583744520804146, 54.483303294980047],
              [-0.583000836642602, 54.483427600155558],
              [-0.580171391994621, 54.483026801049654],
              [-0.579809540443448, 54.483312857323121],
              [-0.578845632409043, 54.483332987337533],
              [-0.577627496030481, 54.482672436913788],
              [-0.572230639284669, 54.481002693226927],
              [-0.570903188948122, 54.479940823945185],
              [-0.56889157949483, 54.479732775846493],
              [-0.568626506890848, 54.478837145009088],
              [-0.56618102677786, 54.477664910207977],
              [-0.564012986721663, 54.475890139874622],
              [-0.562090064063153, 54.473028018119628],
              [-0.561407577059199, 54.471059645433265],
              [-0.559646193767448, 54.46997989899338],
              [-0.559308834982169, 54.467986863621057],
              [-0.557335141720125, 54.46667536031859],
              [-0.556932104939693, 54.46510576021047],
              [-0.554718830196056, 54.464688341874918],
              [-0.553449292219324, 54.463499337008869],
              [-0.550164601112879, 54.462056970213204],
              [-0.549726233412937, 54.461233812884934],
              [-0.547224299390044, 54.461308024059782],
              [-0.545328985858738, 54.460503279827819],
              [-0.544828333394038, 54.45987349275763],
              [-0.542599577944447, 54.459512287058303],
              [-0.540173727511737, 54.457971243789949],
              [-0.53860459110321, 54.455856298984131],
              [-0.537155626014445, 54.454744939443735],
              [-0.534000770576704, 54.453718937201835],
              [-0.534618113409784, 54.452888750450377],
              [-0.533142955443825, 54.451221569643415],
              [-0.530729419868166, 54.450178410528821],
              [-0.52875035955185, 54.450183991877928],
              [-0.526275340194592, 54.449483333160444],
              [-0.525568682098354, 54.448374589365066],
              [-0.522767665747883, 54.447737289018875],
              [-0.521460313657278, 54.446952830354185],
              [-0.520824119509992, 54.446204437436855],
              [-0.52159445669662, 54.445455282063008],
              [-0.521147349888605, 54.443996459442047],
              [-0.521819629289517, 54.441686692822344],
              [-0.522532077785703, 54.440872110084442],
              [-0.526528830060526, 54.438299130653569],
              [-0.531650945982015, 54.436277182101591],
              [-0.531423958094019, 54.434734787602714],
              [-0.532698493954348, 54.43323939866243],
              [-0.532204338604808, 54.432011034740988],
              [-0.531833273934866, 54.432040680311509],
              [-0.532353565272138, 54.431200336617728],
              [-0.531824668719416, 54.430384095688204],
              [-0.532428149234761, 54.429329051558085],
              [-0.531093110540686, 54.425493842055097],
              [-0.530388270080269, 54.424255716024618],
              [-0.529132414038487, 54.423513305071822],
              [-0.52930043492639, 54.422824175898391],
              [-0.522768465889691, 54.416259646417934],
              [-0.517860437857717, 54.414198796069002],
              [-0.512937886396829, 54.411317857599528],
              [-0.507942832380741, 54.409858620417097],
              [-0.505280619724122, 54.408110789684308],
              [-0.502771960219788, 54.407356181401362],
              [-0.500777798406713, 54.407156177756995],
              [-0.500149884618086, 54.406840991176644],
              [-0.494899156181477, 54.406668714233916],
              [-0.491453664944454, 54.407896636992099],
              [-0.490860431956751, 54.407645650028869],
              [-0.490068134729564, 54.406438540193513],
              [-0.486893112176459, 54.40508744827671],
              [-0.484755214219168, 54.403023951751258],
              [-0.482231401886051, 54.401738426160421],
              [-0.478514808013623, 54.39897007354498],
              [-0.477690790161161, 54.39871701123058],
              [-0.476454844372025, 54.399146334680182],
              [-0.475113611161011, 54.398841788152723],
              [-0.473875901577457, 54.397865327889726],
              [-0.471705161652569, 54.397002883247943],
              [-0.468599623139792, 54.393077091669944],
              [-0.463678353807809, 54.389165876013045],
              [-0.460942616922295, 54.384776298279803],
              [-0.457532145130979, 54.381355042821873],
              [-0.454574086763398, 54.375946808452582],
              [-0.449810090074927, 54.37248551761531],
              [-0.447079236122335, 54.367462894722131],
              [-0.447292140592549, 54.365328249475503],
              [-0.445809366119506, 54.363222116657653],
              [-0.445994747161001, 54.361165314693849],
              [-0.446968403617098, 54.359344259649184],
              [-0.444773970851364, 54.357628925746717],
              [-0.441359208938545, 54.357260406079995],
              [-0.439176887825288, 54.35657427102516],
              [-0.435955192724482, 54.352665848379004],
              [-0.43306790927099, 54.350295961480171],
              [-0.432747089020816, 54.348820427407702],
              [-0.431317205030257, 54.346733664779158],
              [-0.430982312586369, 54.3448624604993],
              [-0.43261784849952, 54.340127151815928],
              [-0.431232120644057, 54.339878148640139],
              [-0.428458099395062, 54.340174619453904],
              [-0.427784707831859, 54.339882719274435],
              [-0.425767790342439, 54.339849234869469],
              [-0.423301674018984, 54.339012592747395],
              [-0.421399107582436, 54.33563420967667],
              [-0.421099587162866, 54.333776916434935],
              [-0.42048556186463, 54.333344635632649],
              [-0.420760608511187, 54.332829614168546],
              [-0.417036323249192, 54.331448756450143],
              [-0.41824940828242, 54.330456183194421],
              [-0.419791547792025, 54.327297245846985],
              [-0.4208620740377, 54.326809716995385],
              [-0.421584248877342, 54.325981461702575],
              [-0.420858024512383, 54.324747759393063],
              [-0.421141263614216, 54.323655798191396],
              [-0.420645536242137, 54.322587797315578],
              [-0.418476490876543, 54.321010707597125],
              [-0.417161738347275, 54.320493718735655],
              [-0.418853864748534, 54.319870549400534],
              [-0.419074420555484, 54.318890123866836],
              [-0.418683655480132, 54.318575806488681],
              [-0.419192587876506, 54.317492199689703],
              [-0.418756485222144, 54.316877080271958],
              [-0.419214994920041, 54.316947804466686],
              [-0.418404471397649, 54.314951673287496],
              [-0.418006407700719, 54.31482870770985],
              [-0.418589724954307, 54.314209873144904],
              [-0.417679968034061, 54.312758921846608],
              [-0.418131670258814, 54.312043986202148],
              [-0.417508072249068, 54.311389550633848],
              [-0.41758880454465, 54.310487287977864],
              [-0.416971364425679, 54.309793381626953],
              [-0.415753748260729, 54.309598530379866],
              [-0.415952631610572, 54.309062744143482],
              [-0.414871050137344, 54.308728553991841],
              [-0.415351846622645, 54.308416685634306],
              [-0.413959613877104, 54.307007901165704],
              [-0.412386286978364, 54.306832606155687],
              [-0.412264473223984, 54.306283616101695],
              [-0.411460295581441, 54.305892823959745],
              [-0.409967711654126, 54.306056520111397],
              [-0.409562004308376, 54.305696134080151],
              [-0.407639252897819, 54.305217757613164],
              [-0.407596388783689, 54.304456781787735],
              [-0.40947377501695, 54.302523001101171],
              [-0.408709277073444, 54.301504430772738],
              [-0.408960447405809, 54.301008893379574],
              [-0.410187149792924, 54.300402178735254],
              [-0.409593881404921, 54.297333840558942],
              [-0.407750259278432, 54.29425708679927],
              [-0.405873503888991, 54.292084987350925],
              [-0.400302389250991, 54.28902799659415],
              [-0.39692567581078, 54.287993544269554],
              [-0.394991314394999, 54.288036127897989],
              [-0.39099756916736, 54.289751817239114],
              [-0.388670770146007, 54.290556668359208],
              [-0.387314758427173, 54.290461244036869],
              [-0.386496989256037, 54.290078188712513],
              [-0.384670528339419, 54.287831818927586],
              [-0.384551687395021, 54.285804251940014],
              [-0.386943077472054, 54.282476414641962],
              [-0.389500778736605, 54.28133312500276],
              [-0.389364722712647, 54.281591970202754],
              [-0.390146129794003, 54.281761498088223],
              [-0.389950980602181, 54.282157076846175],
              [-0.389284137989212, 54.281688866950518],
              [-0.388399542819604, 54.281959282767865],
              [-0.386757167274292, 54.282909863085465],
              [-0.386398709267437, 54.283591777751617],
              [-0.386886586092772, 54.283843686862816],
              [-0.389826145903768, 54.282285741223781],
              [-0.387347207969502, 54.283810298641718],
              [-0.392245111253715, 54.283795699967271],
              [-0.390197787176392, 54.282367103866868],
              [-0.391512021395564, 54.282424189245141],
              [-0.39278868310513, 54.283249263338995],
              [-0.395693507732287, 54.281550495031098],
              [-0.397181932516222, 54.279414881904785],
              [-0.397608642340861, 54.277008080089786],
              [-0.397077235274046, 54.276651370504872],
              [-0.396581648377045, 54.274383311954679],
              [-0.393680590825355, 54.271774029402359],
              [-0.392551539812086, 54.271386864783437],
              [-0.392375220977871, 54.270353548537798],
              [-0.392091931792227, 54.269977652737225],
              [-0.39170580192652, 54.270034522698616],
              [-0.391855147920876, 54.26959069160268],
              [-0.391389804941442, 54.269000240259594],
              [-0.391004943026184, 54.269024766714594],
              [-0.391168672891403, 54.268803141073356],
              [-0.389796107341538, 54.268205068862493],
              [-0.389466464859214, 54.267601141608338],
              [-0.386165709485604, 54.265420468581787],
              [-0.381487414141602, 54.263177174305305],
              [-0.378673008384331, 54.262668367783952],
              [-0.376233671584341, 54.26273620936869],
              [-0.375529634846973, 54.261546548168631],
              [-0.374628592409783, 54.261580244919891],
              [-0.375447592050353, 54.260587278909384],
              [-0.374885433954419, 54.259148748377896],
              [-0.370559149910748, 54.256771342188003],
              [-0.36963717746098, 54.255431287794075],
              [-0.36797599085365, 54.253725278899353],
              [-0.366547267855256, 54.254141866946995],
              [-0.365970593218073, 54.25389585841917],
              [-0.366360744591636, 54.2527245611005],
              [-0.367900316482067, 54.25249284019057],
              [-0.369304577645352, 54.251449412353168],
              [-0.369488849142021, 54.249307266396492],
              [-0.363874314076525, 54.245076735833884],
              [-0.359755093537259, 54.243760572755193],
              [-0.356501465120492, 54.243183238443478],
              [-0.353297935166183, 54.241735518219912],
              [-0.350374054430743, 54.240861407527774],
              [-0.347443791021125, 54.241148447921063],
              [-0.345715166111521, 54.241970550416198],
              [-0.343711542152943, 54.24203202042731],
              [-0.340108676705843, 54.24278512943777],
              [-0.338667576628296, 54.242595392910772],
              [-0.337842146914265, 54.242131892385025],
              [-0.336193286349473, 54.241843084007151],
              [-0.337406450067546, 54.24140320918643],
              [-0.337374199277677, 54.240564138655849],
              [-0.336689576574906, 54.239538993794454],
              [-0.334875545103994, 54.238362520598443],
              [-0.330535504307405, 54.236967691121009],
              [-0.324253555471652, 54.235715601326071],
              [-0.322722168584773, 54.234884434133541],
              [-0.319983451190155, 54.234125409554871],
              [-0.318455450273926, 54.234007023998103],
              [-0.317714702274501, 54.233017836272396],
              [-0.315739291764004, 54.232734971745167],
              [-0.313954289706398, 54.231997222359965],
              [-0.312572812365143, 54.230771626147614],
              [-0.313233656603851, 54.229126087956388],
              [-0.312694928058571, 54.228605300919881],
              [-0.310203713826613, 54.228486801980161],
              [-0.305412545895896, 54.227476626991518],
              [-0.302543626663971, 54.227180975817966],
              [-0.2993042078767, 54.226457565556913],
              [-0.296308661651859, 54.224955510074714],
              [-0.295337594244998, 54.224844700886159],
              [-0.293802036794087, 54.225179815571764],
              [-0.292221860995219, 54.224811372136649],
              [-0.29112320885275, 54.224187270765135],
              [-0.28636946961746, 54.223148098415137],
              [-0.284680302889681, 54.222155994708913],
              [-0.280607084175921, 54.220695723454732],
              [-0.277359331323094, 54.220083055947413],
              [-0.275729157984963, 54.219302899497144],
              [-0.275046116379494, 54.219388402713335],
              [-0.27503261534297, 54.218865971400689],
              [-0.274411848453295, 54.2191869651191],
              [-0.272727498927685, 54.218567786451779],
              [-0.26921483731819, 54.218391541683239],
              [-0.268187026845693, 54.218102621093749],
              [-0.268327103991828, 54.217650709043696],
              [-0.26712996502203, 54.217047440469976],
              [-0.265291080717366, 54.216789972998939],
              [-0.265222264089743, 54.216382696407408],
              [-0.266304153053079, 54.216367706598284],
              [-0.263403284985786, 54.216090430733061],
              [-0.262026943056599, 54.216251257741675],
              [-0.259244412226848, 54.215859634895658],
              [-0.264941268418432, 54.216171013226301],
              [-0.265711659501429, 54.215903456837914],
              [-0.267021117949903, 54.216093979971966],
              [-0.267341458705562, 54.216281954734683],
              [-0.266831918220591, 54.216363613929452],
              [-0.270982976788761, 54.216746841437072],
              [-0.273366222692443, 54.217448870033863],
              [-0.275379300026905, 54.217650281001347],
              [-0.277075630328733, 54.217281746234235],
              [-0.280118063054242, 54.21489469259911],
              [-0.282278024723363, 54.212293641283452],
              [-0.283935810221163, 54.209370799342068],
              [-0.284773213524919, 54.205407062306875],
              [-0.284421675587606, 54.205047006846399],
              [-0.284881822014143, 54.204561881319826],
              [-0.28473618102774, 54.203525493599031],
              [-0.285210901053046, 54.203168836453543],
              [-0.282735344856692, 54.195058196533552],
              [-0.279483413595926, 54.18957456841688],
              [-0.275469610489783, 54.184742399919678],
              [-0.272373807839261, 54.181951101351125],
              [-0.264437722659207, 54.176490541142648],
              [-0.251391741394307, 54.169676258497056],
              [-0.244388999904034, 54.166457983462514],
              [-0.232837826432216, 54.161807236620085],
              [-0.226117977457419, 54.159630228864884],
              [-0.220274181091653, 54.158646916972678],
              [-0.218244806489371, 54.157934656227972],
              [-0.212527746566732, 54.157573965372812],
              [-0.216913366919044, 54.148327509235266],
              [-0.221396038576853, 54.14202076505218],
              [-0.221294875737446, 54.138844428047932],
              [-0.221677510484276, 54.137952999159026],
              [-0.222671109818131, 54.137021152795263],
              [-0.226540685341489, 54.136417546666031],
              [-0.229052331926461, 54.135707554727858],
              [-0.229829518780624, 54.134281678525198],
              [-0.229732860718811, 54.132420476085514],
              [-0.237210769098085, 54.137229551268319],
              [-0.245189718220988, 54.1371088236275],
              [-0.252082828948758, 54.138780342600924],
              [-0.265405673117544, 54.140538884734532],
              [-0.269054753546802, 54.138313664886908],
              [-0.272766336453834, 54.139248768645061],
              [-0.276437369423778, 54.139579134592374],
              [-0.283102800925559, 54.139762468735633],
              [-0.288208314775134, 54.139389334089969],
              [-0.29055153171739, 54.139736335458842],
              [-0.291549399286092, 54.138738374114347],
              [-0.299993632376203, 54.136715013274205],
              [-0.304069066175408, 54.1362511531675],
              [-0.307647256152996, 54.13788171759235],
              [-0.311658408051813, 54.142724500966402],
              [-0.31375106997212, 54.146383447409988],
              [-0.322638700208293, 54.145257277947508],
              [-0.32328407940281, 54.147472977742247],
              [-0.323086927293108, 54.149244588145251],
              [-0.323827632032431, 54.150277808310946],
              [-0.328792422896697, 54.149048865557162],
              [-0.335268012570511, 54.149655419966955],
              [-0.336932298512848, 54.149544489261835],
              [-0.342478226842582, 54.148512670219027],
              [-0.346382979661542, 54.146854033878348],
              [-0.350738551529004, 54.149754172179186],
              [-0.355262202950911, 54.149588640091146],
              [-0.360023216167029, 54.153101600415766],
              [-0.363931228656846, 54.153139483989079],
              [-0.374913326360842, 54.154094476622738],
              [-0.37499297138644, 54.156205164230236],
              [-0.373303317785132, 54.156256056226951],
              [-0.374511726917251, 54.162545480399622],
              [-0.380695467745701, 54.170342744498306],
              [-0.382854733883973, 54.171895311437211],
              [-0.387985607663504, 54.174532112959483],
              [-0.390732826195784, 54.176502277800765],
              [-0.396401216176861, 54.175253876252903],
              [-0.403243488907757, 54.174704802117752],
              [-0.404062747107779, 54.175830232492359],
              [-0.408256816557196, 54.175816522107013],
              [-0.408538392930333, 54.175346550365937],
              [-0.411903687087454, 54.174728520252557],
              [-0.418235989417029, 54.174045133076618],
              [-0.418646532308648, 54.175532721601328],
              [-0.421641805928841, 54.179800179814464],
              [-0.423150095450801, 54.184056189855738],
              [-0.423950228687746, 54.188684921759695],
              [-0.426711471935482, 54.193099280537176],
              [-0.428883702487347, 54.199558843576284],
              [-0.431431995578114, 54.200925029504177],
              [-0.43459515970184, 54.205740809272903],
              [-0.43748227701271, 54.21500486524716],
              [-0.441408118427712, 54.215743318340309],
              [-0.442934975069392, 54.215515877194328],
              [-0.445220664997513, 54.214357127709164],
              [-0.446072390715419, 54.214668325945404],
              [-0.4472066744264, 54.214356670207124],
              [-0.448310885323107, 54.21496951964118],
              [-0.451482675107997, 54.214223839478286],
              [-0.456988690674577, 54.214458058645626],
              [-0.467547632950918, 54.211718331538016],
              [-0.469103875188685, 54.211641938209937],
              [-0.471123760057684, 54.211924650265473],
              [-0.472535587666434, 54.211481453586437],
              [-0.473011956795512, 54.210415182392516],
              [-0.474583627596115, 54.210584295758132],
              [-0.475467438962649, 54.209819787659143],
              [-0.480136240228756, 54.208996114121526],
              [-0.480704748986633, 54.208546676476871],
              [-0.481707878986041, 54.208579993954096],
              [-0.481996223645471, 54.207490645798941],
              [-0.48301501755968, 54.207514262718718],
              [-0.483294791069943, 54.207160049162098],
              [-0.483973240942172, 54.207182069885363],
              [-0.484237027194748, 54.206510364951832],
              [-0.486556762190476, 54.206052357460813],
              [-0.487190389701954, 54.204866664615913],
              [-0.488831670850415, 54.204972649985486],
              [-0.489083365920253, 54.204461674181154],
              [-0.490915588859526, 54.204081959422105],
              [-0.490984010371596, 54.203550707475294],
              [-0.492650470191799, 54.203511345573062],
              [-0.493709978419973, 54.202961028086939],
              [-0.495446220557451, 54.203743133737383],
              [-0.495763837416269, 54.204707051990098],
              [-0.496724530241386, 54.204977905488789],
              [-0.496959035664141, 54.205993814271629],
              [-0.498089328604211, 54.206282052273174],
              [-0.499057337347247, 54.205675718700668],
              [-0.500134561120462, 54.20565138199008],
              [-0.500165172213203, 54.205102577179389],
              [-0.501813580727805, 54.204970283879469],
              [-0.502053188844265, 54.20533998602658],
              [-0.502668118275545, 54.204997984168322],
              [-0.50343276253932, 54.205476673759037],
              [-0.503833003789217, 54.20517783959842],
              [-0.504940571534347, 54.205161926260672],
              [-0.504861036248884, 54.204604563167173],
              [-0.505463405326378, 54.204397215935693],
              [-0.505919438391806, 54.2036136984172],
              [-0.510854239773401, 54.200301480478799],
              [-0.512068654120456, 54.200124134628226],
              [-0.513020642728132, 54.201149768180656],
              [-0.514028911857117, 54.200479995744537],
              [-0.514999242427668, 54.200739137597409],
              [-0.515003546509017, 54.200063270505524],
              [-0.516252647926991, 54.200072367916704],
              [-0.51571429618938, 54.199480598575036],
              [-0.516175527717464, 54.198844515261705],
              [-0.51744091299523, 54.198825937387674],
              [-0.517489458520146, 54.198497563004288],
              [-0.518541356988518, 54.198232763609603],
              [-0.518799249943294, 54.198565804988654],
              [-0.520004259672828, 54.197748297228046],
              [-0.520421286375497, 54.198304401908359],
              [-0.521219988297623, 54.198561385960851],
              [-0.521105510665687, 54.198933893848512],
              [-0.521806351933373, 54.199184279648669],
              [-0.522491105693243, 54.199026395576759],
              [-0.523334525123762, 54.199835794820906],
              [-0.524783031890083, 54.200099818474435],
              [-0.527197807729106, 54.199661970428657],
              [-0.528979021175039, 54.200184378506734],
              [-0.52961312355299, 54.199725626983025],
              [-0.53061062666645, 54.19973959416923],
              [-0.53209938074067, 54.200815661919314],
              [-0.532666483514543, 54.200689543288334],
              [-0.532915666183074, 54.200190133058584],
              [-0.535073526283672, 54.200685577116644],
              [-0.537191759369491, 54.200003936359749],
              [-0.537775619697162, 54.20040111464678],
              [-0.538378126168886, 54.199575212621959],
              [-0.540077969911797, 54.199061020012479],
              [-0.5398309396409, 54.198240992610977],
              [-0.540770707522285, 54.197978236204868],
              [-0.540801266069286, 54.197371898037566],
              [-0.543520479165896, 54.195721298439096],
              [-0.544663101148283, 54.194525285323692],
              [-0.544736594300542, 54.193916768334766],
              [-0.546400686467341, 54.193669906398881],
              [-0.547292893983133, 54.193928744284449],
              [-0.547806854080718, 54.193565522785654],
              [-0.548628116231239, 54.193705749267401],
              [-0.548939802514944, 54.192859213731943],
              [-0.550397252870866, 54.1925585747017],
              [-0.551018756784208, 54.192931873746282],
              [-0.551638118748532, 54.192884493006588],
              [-0.551537336295192, 54.194276462121898],
              [-0.552461106171632, 54.194554516243329],
              [-0.553442038052005, 54.194117784094544],
              [-0.554662747747639, 54.194759815405043],
              [-0.555837997700486, 54.194332585514076],
              [-0.556354192521212, 54.194870876819216],
              [-0.556028704420629, 54.19554558941168],
              [-0.557308932842491, 54.19654963434639],
              [-0.559003939064054, 54.197221567442945],
              [-0.560156951513942, 54.196817398896826],
              [-0.560572423748473, 54.198095099128125],
              [-0.561479201335033, 54.197939646686265],
              [-0.562049605728522, 54.198376992681681],
              [-0.562196161576963, 54.199542719102205],
              [-0.56330452304628, 54.20047094347062],
              [-0.56338125479076, 54.201131595097536],
              [-0.565017903578079, 54.20119514090225],
              [-0.566404516568808, 54.20237023057549],
              [-0.5685484362585, 54.201908558243581],
              [-0.571956693292974, 54.201992145199583],
              [-0.576238336241464, 54.201465771991828],
              [-0.578589503907608, 54.200410451600014],
              [-0.579634412565841, 54.200518049280248],
              [-0.579682383254543, 54.20014740134237],
              [-0.584499532423322, 54.199553344100316],
              [-0.585806478281948, 54.198631220822783],
              [-0.587544634611286, 54.198862833038682],
              [-0.589615484449588, 54.200039380341245],
              [-0.593561602550818, 54.200667943539472],
              [-0.596814659138852, 54.199493364328298],
              [-0.597780697605866, 54.199803019167142],
              [-0.599017508669028, 54.199722131948441],
              [-0.599055900008864, 54.200218726750684],
              [-0.598074634750972, 54.200584817474528],
              [-0.599563219348078, 54.201128836792478],
              [-0.599930543274415, 54.202008555585579],
              [-0.601959066020331, 54.202183111205585],
              [-0.60310693392023, 54.202741108573484],
              [-0.603899556917471, 54.202377285410655],
              [-0.604555095790025, 54.202441504819632],
              [-0.606990278330749, 54.204246646441966],
              [-0.60809898281771, 54.204331364411402],
              [-0.608932091943523, 54.204861400822097],
              [-0.610263145105285, 54.205128428993675],
              [-0.61155350868317, 54.204871861804989],
              [-0.612122697140509, 54.204128805508191],
              [-0.614700397207618, 54.205673874128934],
              [-0.6155754019233, 54.204954091352306],
              [-0.616724310520355, 54.20475426672337],
              [-0.617170915816753, 54.205062294160143],
              [-0.618078330829631, 54.204885750632144],
              [-0.618430367955983, 54.20545514313158],
              [-0.616579371478, 54.208542013192776],
              [-0.614735712688533, 54.209842991460434],
              [-0.614212316501635, 54.211003636961159],
              [-0.611937305953465, 54.213181350355967],
              [-0.607408961882952, 54.21299608000237],
              [-0.607228640361936, 54.213205217957217],
              [-0.605882638718294, 54.217974054169822],
              [-0.60361626545357, 54.22296231003088],
              [-0.602669532739487, 54.229785929436943],
              [-0.598981657350904, 54.232091722386343],
              [-0.597934910135584, 54.234820037713583],
              [-0.597891559422302, 54.237049492558256],
              [-0.599707482981697, 54.241922399166434],
              [-0.601652668342558, 54.243938546875768],
              [-0.604801624417277, 54.245443692794197],
              [-0.60692097741817, 54.248588877629935],
              [-0.607812991887548, 54.255644962543741],
              [-0.607540679965806, 54.260948393869022],
              [-0.610477046484137, 54.264802208669472],
              [-0.613099878101061, 54.269641029770412],
              [-0.61032323657451, 54.274944386963966],
              [-0.61305039243164, 54.276969337127916],
              [-0.613326658072341, 54.278621824644773],
              [-0.60975254580473, 54.282727770631226],
              [-0.609882578301909, 54.286028787553562],
              [-0.609084698250578, 54.286319789977782],
              [-0.605509097314575, 54.293029434567451],
              [-0.604894640862571, 54.296616652266053],
              [-0.605298971465085, 54.297065337698797],
              [-0.604492021932923, 54.298071654596647],
              [-0.60079292078389, 54.299532504893421],
              [-0.598757106111974, 54.301287609528387],
              [-0.598783373892045, 54.302188517498507],
              [-0.597758480481826, 54.302771617421385],
              [-0.594655262376205, 54.303368264568405],
              [-0.592541712528587, 54.303243856321622],
              [-0.587770912250057, 54.304243298284135],
              [-0.586532481240891, 54.305355897086002],
              [-0.584075029832887, 54.306248355145414],
              [-0.58092963379458, 54.309621468428489],
              [-0.578968308779366, 54.310915133479917],
              [-0.570269482651776, 54.31220461063883],
              [-0.56827854026003, 54.312035393211474],
              [-0.567280799456317, 54.31137460942611],
              [-0.567440275049524, 54.312049710168985],
              [-0.568381041842821, 54.312315239915613],
              [-0.567770370693537, 54.312680997036431],
              [-0.56768638097437, 54.31386013411926],
              [-0.568344273889222, 54.314348804672058],
              [-0.568717760623211, 54.315856940267643],
              [-0.57063251455319, 54.316366793215778],
              [-0.572396998243064, 54.316333756029287],
              [-0.572770637400238, 54.316642871904001],
              [-0.572251118166918, 54.317039390451562],
              [-0.572438884415843, 54.317477533318666],
              [-0.573119061661545, 54.317547596895928],
              [-0.573614115407501, 54.31714719019125],
              [-0.574062913053974, 54.318306562129429],
              [-0.574532363374524, 54.31842446011823],
              [-0.575534894215612, 54.318020153298789],
              [-0.576282660910567, 54.319205515883063],
              [-0.576886438047233, 54.318639200963489],
              [-0.577869821828018, 54.318611248496978],
              [-0.578404938764906, 54.318965392303731],
              [-0.579819247861445, 54.318969464595661],
              [-0.580019469853166, 54.319361003766588],
              [-0.581239784670624, 54.31919469954925],
              [-0.581885521274919, 54.319596866219776],
              [-0.583149453995519, 54.319413978051685],
              [-0.58480070750609, 54.320757296118451],
              [-0.58584932282577, 54.320845989163189],
              [-0.584596120678677, 54.322327803666212],
              [-0.584844723900165, 54.323864977976918],
              [-0.586251874556151, 54.324844990615482],
              [-0.586362779566565, 54.325377482824948],
              [-0.587271708752096, 54.325372848252222],
              [-0.587123482619311, 54.326332832240141],
              [-0.590085404760978, 54.32938206099648],
              [-0.593210776475889, 54.330674163685238],
              [-0.595894607046851, 54.330938211296193],
              [-0.598138634417318, 54.331835239392227],
              [-0.600558946423989, 54.332299249990307],
              [-0.600863895706123, 54.332875322032422],
              [-0.601956406480931, 54.332904162239302],
              [-0.603004005743739, 54.333804308886783],
              [-0.603499791380364, 54.333742637215536],
              [-0.604301830409908, 54.334220190700783],
              [-0.60447638949811, 54.334651835797736],
              [-0.607591272965621, 54.335459884705315],
              [-0.607682291189769, 54.335952578089895],
              [-0.61059383270814, 54.336637763209026],
              [-0.614788175134818, 54.339155877010789],
              [-0.617044182150856, 54.34127954357718],
              [-0.620942327293375, 54.343434523768401],
              [-0.629247356313639, 54.353436549781179],
              [-0.627613241100184, 54.359069598569413],
              [-0.63391228383319, 54.364490659749272],
              [-0.634408073868618, 54.366016126221545],
              [-0.632600878691795, 54.375937201726323],
              [-0.661305193865042, 54.366495336346979],
              [-0.661143908899376, 54.367668264414441],
              [-0.661888466592023, 54.369291641540784],
              [-0.662390824536677, 54.369464382893582],
              [-0.661911475071066, 54.369814991212742],
              [-0.662731912369198, 54.370431662046244],
              [-0.662575895268763, 54.371016842320294],
              [-0.663400395159124, 54.371130232231231],
              [-0.663646271221591, 54.371807044680146],
              [-0.66455126017898, 54.371812564196283],
              [-0.664222301531769, 54.37217742800437],
              [-0.665212939625769, 54.37229533970276],
              [-0.665050042047519, 54.372618899693101],
              [-0.666229348502529, 54.372615755039021],
              [-0.666261102151094, 54.373061005432611],
              [-0.666925272634679, 54.372992842348616],
              [-0.667390425305548, 54.373460852531728],
              [-0.668433261539256, 54.37334652789864],
              [-0.668165879433457, 54.373759717030801],
              [-0.669510631700402, 54.374354260504127],
              [-0.671537964826067, 54.374789126634006],
              [-0.672956243545902, 54.37468158947955],
              [-0.674821666738522, 54.37517394036832],
              [-0.6778284330834, 54.374596628099056],
              [-0.680247228418371, 54.373149083130357],
              [-0.681317265062107, 54.372953155252425],
              [-0.684171952254753, 54.373465149995681],
              [-0.687218041391056, 54.373094754021146],
              [-0.688108343937447, 54.372837491954073],
              [-0.687604265207594, 54.370831318881436],
              [-0.688151134006695, 54.369273376313487],
              [-0.689636757757995, 54.367381388932657],
              [-0.691714292357919, 54.365651289059556],
              [-0.696244967292482, 54.365330900907203],
              [-0.699924411993959, 54.365693243260104],
              [-0.703233538059129, 54.364865101266417],
              [-0.707891577047568, 54.362540458474101],
              [-0.710231986228256, 54.360836225467807],
              [-0.711375591914994, 54.359012222924896],
              [-0.711178007483769, 54.357163112449058],
              [-0.732968671657389, 54.355241208625905],
              [-0.73505951339135, 54.355653207413262],
              [-0.735903261613454, 54.356138400078549],
              [-0.737512056099362, 54.356164223883489],
              [-0.738226937122256, 54.356487170429894],
              [-0.740000597477333, 54.356405931593684],
              [-0.74023683390508, 54.356731958275624],
              [-0.741205297547151, 54.356915525640915],
              [-0.742650595415414, 54.358265277144802],
              [-0.742496632290478, 54.35862048930332],
              [-0.743052268348839, 54.359121503889099],
              [-0.742774829952152, 54.359740570853369],
              [-0.743486015471221, 54.359935820270707],
              [-0.742694325713189, 54.360814673556654],
              [-0.743239382435605, 54.36221795181158],
              [-0.744386954394933, 54.362740399541281],
              [-0.74383411844344, 54.363154378618169],
              [-0.745392612571886, 54.364738954756803],
              [-0.747285469186865, 54.365708619720692],
              [-0.74771706567706, 54.366902181580123],
              [-0.75034152707829, 54.368216425531195],
              [-0.753107394863839, 54.368577569710432],
              [-0.753556513045472, 54.366809817034145],
              [-0.755094310931035, 54.366143511009767],
              [-0.756135491201629, 54.364897750878882],
              [-0.756604913481773, 54.363369270884021],
              [-0.757472075509897, 54.363245179652722],
              [-0.758086888399112, 54.363476200259285],
              [-0.758951434447619, 54.362775056010754],
              [-0.759845107541109, 54.362689867793087],
              [-0.760953294527975, 54.362943017684941],
              [-0.76166248090227, 54.362646507228611],
              [-0.763928199772904, 54.3628944205972],
              [-0.76402527932042, 54.362326488330879],
              [-0.766060231481863, 54.362109128606889],
              [-0.768207652131881, 54.360703799929489],
              [-0.768884242529043, 54.360156155906992],
              [-0.768591870149523, 54.359692101178332],
              [-0.770078489590314, 54.359124848236952],
              [-0.770985739512611, 54.359148468349055],
              [-0.771438415895508, 54.358212056144893],
              [-0.772807735928329, 54.35801477111319],
              [-0.772705569768931, 54.357621865599839],
              [-0.773764714171538, 54.356936076309658],
              [-0.774006465953427, 54.357292649953877],
              [-0.778331250847679, 54.355766330455452],
              [-0.782218741225708, 54.354811562416145],
              [-0.782758881787848, 54.35507046511394],
              [-0.784448008335538, 54.354772014935975],
              [-0.785210923689111, 54.355043036142597],
              [-0.78645773361289, 54.354454295165226],
              [-0.787346070470294, 54.354960245421275],
              [-0.789124784946126, 54.35449186243747],
              [-0.789373881478831, 54.354079126284844],
              [-0.792699859113452, 54.35457620395519],
              [-0.794205459049685, 54.354341392620235],
              [-0.79622704197571, 54.355088677699008],
              [-0.797022480070199, 54.354933928730752],
              [-0.797411684732163, 54.355569645452498],
              [-0.799212061300773, 54.355832932737165],
              [-0.799435048876227, 54.356365427645578],
              [-0.801451718578091, 54.357393891515663],
              [-0.802543912482495, 54.357618638362929],
              [-0.804033558271155, 54.357458144245456],
              [-0.803897907748182, 54.357890009368106],
              [-0.804825465919702, 54.357902791040729],
              [-0.805979549609183, 54.358435499926131],
              [-0.807680227572035, 54.359703401180795],
              [-0.808408406357999, 54.359800468037818],
              [-0.808825292599951, 54.36129564661298],
              [-0.809841366517773, 54.363839307727119],
              [-0.810908122501942, 54.364472668240431],
              [-0.8108314826332, 54.364888044945459],
              [-0.811567918665088, 54.365448939921613],
              [-0.812038299583499, 54.367816440372678],
              [-0.812349874042926, 54.368167330251161],
              [-0.813564197954559, 54.368439013950962],
              [-0.816745131669835, 54.371515265342452],
              [-0.82361987971903, 54.382503384836866],
              [-0.834402370601822, 54.380891295895026],
              [-0.847075947586522, 54.383190671274441],
              [-0.859850346419822, 54.386514251763529],
              [-0.866537096392686, 54.387336650848859],
              [-0.917548883594966, 54.400631704912087],
              [-0.918554609547839, 54.400814173442079],
              [-0.926875313544867, 54.400607072187782],
              [-0.950427718330344, 54.408844374411792],
              [-0.961515626076011, 54.40253210101168],
              [-0.975885504006342, 54.396899182407338],
              [-0.98782626669756, 54.39594110505174],
              [-1.009153482536084, 54.407151867784478],
              [-1.026859274690606, 54.415331472661599],
              [-1.059367628342085, 54.412565572572973],
              [-1.064682892616681, 54.42112780211054],
              [-1.05555002907836, 54.427422540372355],
              [-1.053928311428315, 54.433987731376824],
              [-1.052649858747346, 54.436946251795788],
              [-1.057492693929079, 54.440904414705066],
              [-1.056997275984751, 54.442401442625822],
              [-1.055482038901531, 54.444054073043688],
              [-1.054989545719547, 54.44602205374256],
              [-1.053330873447429, 54.4472646099195],
              [-1.053574316248008, 54.447675441967711],
              [-1.052720627530692, 54.448447959608025],
              [-1.052552387137658, 54.449389416390758],
              [-1.051451103779031, 54.450062021645664],
              [-1.05026915665167, 54.450219905408645],
              [-1.049907540977042, 54.450784166725043],
              [-1.049365793870169, 54.450801476836858],
              [-1.04883423029408, 54.451776918620048],
              [-1.047879596497062, 54.451640883181057],
              [-1.04837559614196, 54.452133702287178],
              [-1.043475082434103, 54.452815808739835],
              [-1.043398187786246, 54.45333556922261],
              [-1.04252747236423, 54.453832866916493],
              [-1.043201182005516, 54.4541114178135],
              [-1.042288007424806, 54.454246186524998],
              [-1.041585531702779, 54.455067457073731],
              [-1.04214606737426, 54.455371179728978],
              [-1.039248219174618, 54.456208273470359],
              [-1.038730611638791, 54.456772160376865],
              [-1.039030184737481, 54.457219417286446],
              [-1.03765940938524, 54.457064714062597],
              [-1.037375140381195, 54.457280843793441],
              [-1.037749789935958, 54.457420434829722],
              [-1.036991542138722, 54.457785575010149],
              [-1.036583757205192, 54.457414742028234],
              [-1.03617114614094, 54.458169088430154],
              [-1.035723099936136, 54.45806755275769],
              [-1.035417390954929, 54.458341924815279],
              [-1.035930532570333, 54.458430499726596],
              [-1.035675231301181, 54.458594729741606],
              [-1.034810104986563, 54.458583331421238],
              [-1.033926183868741, 54.45897710730064],
              [-1.032864704119192, 54.458722365277339],
              [-1.03116684035759, 54.459091631441424],
              [-1.031007662243269, 54.458907911811394],
              [-1.029872213593223, 54.459303236616648],
              [-1.029193810099851, 54.459092877996405],
              [-1.029267526744981, 54.459367585030172],
              [-1.028497851454345, 54.459298490091498],
              [-1.028285103599862, 54.459618528241094],
              [-1.027575476499867, 54.459293769819148],
              [-1.02658614334941, 54.459510491622822],
              [-1.025917613313649, 54.45914471237964],
              [-1.023632319981976, 54.459497454339186],
              [-1.023165476423717, 54.459862165398057],
              [-1.021479328169979, 54.459993224912289],
              [-1.019306909418077, 54.45962151698587],
              [-1.013134896967875, 54.460003580174849],
              [-1.011225097901178, 54.459619490552591],
              [-1.010057994196665, 54.458824439995553],
              [-1.007878206601097, 54.458253844029777],
              [-1.006836358844952, 54.45846997345506],
              [-1.003441675082401, 54.458319805741823],
              [-1.000647174701491, 54.458672408221183],
              [-0.996890904802977, 54.457979822063272],
              [-0.995975413490199, 54.457442862929724],
              [-0.99431792968825, 54.458104038749475],
              [-0.9883864378192, 54.458803195793294],
              [-0.984787343310959, 54.457439288923894],
              [-0.981713750204787, 54.457658792016112],
              [-0.978162070704782, 54.45948292535212],
              [-0.976409317106375, 54.459743111073074],
              [-0.974041238848733, 54.461228428941823],
              [-0.973362306588887, 54.461357476074639],
              [-0.972076479789395, 54.460833366751984],
              [-0.976105176749056, 54.467664742204484],
              [-0.979945985255027, 54.471928482106648],
              [-0.983500655618715, 54.478864336146884],
              [-0.989512157458513, 54.479486324469114],
              [-0.991138511036116, 54.478560724191972],
              [-0.991609540546082, 54.478799224277189],
              [-0.994685315972377, 54.477357198290491],
              [-0.999428448595852, 54.476006196179796],
              [-1.004112948732636, 54.475467883566559],
              [-1.009517817026941, 54.475512282778766],
              [-1.016438635339948, 54.476630189815474],
              [-1.018279491972377, 54.47799505269505],
              [-1.018513798518436, 54.479682983460528],
              [-1.019075427843619, 54.480141402676836],
              [-1.018686537224387, 54.481364571793335],
              [-1.019054655088156, 54.482493224904864],
              [-1.022984206937107, 54.483342889074748],
              [-1.023060096319084, 54.483979806478338],
              [-1.02491092383559, 54.484883594354059],
              [-1.024826068114156, 54.485271163321684],
              [-1.025781873512232, 54.485127880439187],
              [-1.026018577038459, 54.485820015198442],
              [-1.027280117530171, 54.485672891548695],
              [-1.027429139424149, 54.48622321676315],
              [-1.028986604750958, 54.487398695064066],
              [-1.02995635478881, 54.487580832640738],
              [-1.030573240369196, 54.48832363994719],
              [-1.030475056051673, 54.48914609258734],
              [-1.031619951770859, 54.49073793549929],
              [-1.031570819304384, 54.491314528616904],
              [-1.033893685256998, 54.492911282971946],
              [-1.036836252928276, 54.494031208338555],
              [-1.023784718733176, 54.502032224921869],
              [-1.0033850586227, 54.50299914779724],
              [-0.987370174133265, 54.500844863568773],
              [-0.981254762217599, 54.499743780729183],
              [-0.967812309915715, 54.497255333237959],
              [-0.966174790805157, 54.49618622725243],
              [-0.953358542069429, 54.488181453169851],
              [-0.952572741783517, 54.488002090792413],
              [-0.943970792086799, 54.488675924157093],
              [-0.932593962923443, 54.491441220936075],
              [-0.929018261233348, 54.491624404156944],
              [-0.925380186142906, 54.491064566096284],
              [-0.92293016914338, 54.491004122314415],
              [-0.916349331719803, 54.492210757310858],
              [-0.901399422174136, 54.494188808033833],
              [-0.895769485974059, 54.495589002298253],
              [-0.89420169860064, 54.49635568542589],
              [-0.894196313132046, 54.496898478061738],
              [-0.88080873423539, 54.497033257433856],
              [-0.880628374232315, 54.496457289271447],
              [-0.878170637724344, 54.495315574699255],
              [-0.877157025912131, 54.494948465961414],
              [-0.876320820593791, 54.495375692000067],
              [-0.875765097814505, 54.491150908909582],
              [-0.866962794096606, 54.489227214343558],
              [-0.863478238583584, 54.489069586220289],
              [-0.856473967064697, 54.487859439652489],
              [-0.848514813686882, 54.48795916124088],
              [-0.844123204265057, 54.489535909747005],
              [-0.843926199618043, 54.491211095321141],
              [-0.845042752690642, 54.492622917271987],
              [-0.848598827907319, 54.495518449769179],
              [-0.853304696630094, 54.502700128200239],
              [-0.852330418246835, 54.505615412103914],
              [-0.852310110447693, 54.510363301911063],
              [-0.850189854800859, 54.51144772401905],
              [-0.851273594451257, 54.513670736639],
              [-0.852218572775938, 54.517596444236837],
              [-0.8521673406361, 54.518046229823845],
              [-0.851174473508363, 54.518359453735279],
              [-0.849931216676718, 54.519941115985695],
              [-0.850632503746096, 54.523411547221684],
              [-0.851411963945812, 54.523990552802992],
              [-0.85039016724598, 54.524173177921526],
              [-0.849910849683509, 54.524955023874021],
              [-0.850683546468596, 54.526325763952968],
              [-0.850056689957383, 54.526473492052467],
              [-0.850128304603893, 54.527609287594771],
              [-0.84863044043657, 54.52999019554634],
              [-0.844579365297761, 54.533466562823968],
              [-0.843885279292278, 54.534785580118502],
              [-0.842136772768642, 54.536006429783484],
              [-0.841855830980749, 54.53735904721853],
              [-0.838649365350536, 54.539786376560031],
              [-0.836645948308628, 54.542619743797204],
              [-0.83478432060502, 54.5433423950508],
              [-0.832481983960358, 54.545204867500388],
              [-0.830834261008164, 54.545522391236766],
              [-0.829755560597974, 54.54680705367754],
              [-0.827985322057726, 54.5473507362642],
              [-0.826968900832905, 54.548561381582793],
              [-0.82525862494761, 54.549058871226805],
              [-0.821827837533953, 54.549063262236658],
              [-0.821332960582703, 54.549390079496199],
              [-0.820383338842252, 54.549348470821741],
              [-0.818905234177491, 54.550689359385231],
              [-0.815660267244786, 54.552493778395068],
              [-0.814717731760955, 54.551672084283588],
              [-0.813049916783344, 54.552028715314776],
              [-0.810511909879474, 54.551204808531736],
              [-0.8095837636605, 54.551698978733526],
              [-0.808103951042847, 54.551226950075126],
              [-0.806448652435687, 54.5514182443343],
              [-0.804908950835248, 54.550880875871542],
              [-0.801831035191973, 54.551395078094131],
              [-0.800403261138462, 54.550997167213183],
              [-0.800564518803146, 54.551824713889964],
              [-0.797235816195352, 54.554132009489663],
              [-0.797368419583808, 54.554617751803846],
              [-0.798489914884277, 54.55507556937286],
              [-0.798799990746482, 54.555573856903834],
              [-0.798294661375248, 54.555668600564985],
              [-0.795533658529466, 54.554858342371794],
              [-0.796083832198442, 54.55660109418816],
              [-0.79547115970252, 54.557136941360888],
              [-0.794313813801292, 54.55742200220066],
              [-0.794235739602587, 54.558338844322655],
              [-0.793813938742092, 54.558850521208306],
              [-0.792211091266402, 54.559451973368112],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000169",
        LAD13CDO: "36UH",
        LAD13NM: "Selby",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-0.998537566646538, 53.880947147959432],
              [-0.992826662785934, 53.875190936077146],
              [-0.986901158711869, 53.877122093669989],
              [-0.980932553905861, 53.878363194459901],
              [-0.967702824845768, 53.882818013534497],
              [-0.963662257122169, 53.882876683958528],
              [-0.957496146933274, 53.884290190636335],
              [-0.948020717393054, 53.884846612188859],
              [-0.948242497149809, 53.886072759365383],
              [-0.945148801387346, 53.886942650988061],
              [-0.944065213220419, 53.888101603509575],
              [-0.941577532642596, 53.887499057410992],
              [-0.939583912947939, 53.887877850740566],
              [-0.938605481286972, 53.887521358941306],
              [-0.938163428303171, 53.885812371721713],
              [-0.935596008257387, 53.884224776885773],
              [-0.934513941345192, 53.882922662024811],
              [-0.934446458653403, 53.881328437406808],
              [-0.934967043316693, 53.879999192356912],
              [-0.934427592823289, 53.879796663116053],
              [-0.93267111129478, 53.879477256251313],
              [-0.93046305491291, 53.879507940954596],
              [-0.929793956320923, 53.881286157568852],
              [-0.928576900279837, 53.882239748172978],
              [-0.928237113883073, 53.882379629577564],
              [-0.927665166903606, 53.882079706208692],
              [-0.926828774809708, 53.882542321616327],
              [-0.925744540595758, 53.881633794614366],
              [-0.925378243737721, 53.88073078677008],
              [-0.924472803663584, 53.880386516257396],
              [-0.923511262335481, 53.880746419578593],
              [-0.922680417553137, 53.879453638277134],
              [-0.924891225749139, 53.878011057208212],
              [-0.929752760570483, 53.876524682697841],
              [-0.938167907625553, 53.87572753926581],
              [-0.940739753285634, 53.874053266299669],
              [-0.940777347948903, 53.872926464199864],
              [-0.938719110643607, 53.869035222410815],
              [-0.939046033404637, 53.867715031923012],
              [-0.940591518534648, 53.866436160932096],
              [-0.944640567606633, 53.865495695405336],
              [-0.946132881470173, 53.864749293295695],
              [-0.947404578462578, 53.863520962349313],
              [-0.948126132870127, 53.862095448423382],
              [-0.948049834926199, 53.861075504459762],
              [-0.944965865594412, 53.857521419438292],
              [-0.941990830575799, 53.854792441532517],
              [-0.945268836182497, 53.852546344491657],
              [-0.946308646309067, 53.85115509494598],
              [-0.946346671835825, 53.85000402288874],
              [-0.944982222982473, 53.848104485418858],
              [-0.946947001472071, 53.844981214069307],
              [-0.947020308052229, 53.843634504372972],
              [-0.943493502091765, 53.835265910475265],
              [-0.940297641828193, 53.832682338670189],
              [-0.940605331266198, 53.823498038314099],
              [-0.939078543019768, 53.822536273593101],
              [-0.933202905076795, 53.820665824656317],
              [-0.930256611614625, 53.820816663155938],
              [-0.928923121548034, 53.820448825956831],
              [-0.927260861089307, 53.819512664493146],
              [-0.924215968991505, 53.816041943831081],
              [-0.923327679391044, 53.816031278902628],
              [-0.922341420601781, 53.816997666127662],
              [-0.92051880289922, 53.816520175557713],
              [-0.9200806574523, 53.815956254591732],
              [-0.921767638466444, 53.814023639322834],
              [-0.924839288419289, 53.812339812975111],
              [-0.927587676363905, 53.809863836704857],
              [-0.929930451683881, 53.804995059592265],
              [-0.932880345542215, 53.803821383637448],
              [-0.934471906377113, 53.801836507437926],
              [-0.934227085876345, 53.801072114632163],
              [-0.93106618493716, 53.798252184491787],
              [-0.928457021186545, 53.797627578780912],
              [-0.928038465603791, 53.797072848220587],
              [-0.931170423031766, 53.791285281674583],
              [-0.933490224001925, 53.789957666368622],
              [-0.934104496995692, 53.788360488707433],
              [-0.934187699288795, 53.786155465112316],
              [-0.934273943066217, 53.784668644367621],
              [-0.936447043849441, 53.780840880981955],
              [-0.936615670622315, 53.779333215947759],
              [-0.935429478173224, 53.776972212014442],
              [-0.933152236920585, 53.774737219176821],
              [-0.932451970660007, 53.774407402758207],
              [-0.930464906432444, 53.77431868807632],
              [-0.929433577005324, 53.773762087989731],
              [-0.92829080241606, 53.769896726944346],
              [-0.928363602727064, 53.765309660940623],
              [-0.930083299086491, 53.763068011561629],
              [-0.932088796502131, 53.762169964329019],
              [-0.934261510889157, 53.761614033450854],
              [-0.936327278710315, 53.759945240142315],
              [-0.939741897107616, 53.758133745164905],
              [-0.944560805225887, 53.756682377759702],
              [-0.95157455954404, 53.753748955927115],
              [-0.956695371465245, 53.752868713163153],
              [-0.964186848771795, 53.750613747627838],
              [-0.971383369617639, 53.750468074492112],
              [-0.973298486046903, 53.750168096630063],
              [-0.976124851438493, 53.750934714237047],
              [-0.980027790182923, 53.752778256445396],
              [-0.98497037431816, 53.757060051547818],
              [-0.987593550411997, 53.760879843699314],
              [-0.987381992306043, 53.761792180714266],
              [-0.985422605295748, 53.763722511513102],
              [-0.98617872125463, 53.76520750329118],
              [-0.987669782395924, 53.765594918119433],
              [-0.994640778848941, 53.76502258037754],
              [-0.997743827645632, 53.76556808763759],
              [-0.999061815069564, 53.766348504950038],
              [-0.999450568359077, 53.76818358791067],
              [-1.000039170449204, 53.769080148007554],
              [-1.000933391799461, 53.769566683668081],
              [-1.001681859211562, 53.769674486107263],
              [-1.003179551777597, 53.769153932718233],
              [-1.01081639453788, 53.764538707686462],
              [-1.017957565533738, 53.762047395146979],
              [-1.02104015525304, 53.760605694491247],
              [-1.024134731716382, 53.760142849767732],
              [-1.025850302259651, 53.760537009339082],
              [-1.032409114177556, 53.764242998800185],
              [-1.03327655460907, 53.765511066247441],
              [-1.030175695523553, 53.769328563068683],
              [-1.02839722336996, 53.770656132536239],
              [-1.026880978444742, 53.770940454812774],
              [-1.02273477340272, 53.770527427541019],
              [-1.021571386973662, 53.770838826502704],
              [-1.020758054129727, 53.772015958582898],
              [-1.021330348486113, 53.773570228355759],
              [-1.024044083223605, 53.77609201299542],
              [-1.026775106287889, 53.777486732024471],
              [-1.028191153202189, 53.777608771988056],
              [-1.03269869576145, 53.777122988600524],
              [-1.036471339010312, 53.777645920767668],
              [-1.044741608990302, 53.779590647395473],
              [-1.051123467701863, 53.781723910151456],
              [-1.062382595407544, 53.78432649743614],
              [-1.064407153549497, 53.78528698123845],
              [-1.065825165382275, 53.786498875249684],
              [-1.065862930506494, 53.787053749425503],
              [-1.064542525352536, 53.787526132030159],
              [-1.054530843381398, 53.788735660347101],
              [-1.049373339295097, 53.788854887645542],
              [-1.047528469528966, 53.789216863389804],
              [-1.046264105677958, 53.790084078751562],
              [-1.045717628594918, 53.791422590444313],
              [-1.047497362284631, 53.794946681770995],
              [-1.047779639522178, 53.796425705914423],
              [-1.047541656066938, 53.803848173830154],
              [-1.047016434773688, 53.805582343723671],
              [-1.045849917752191, 53.807200855987624],
              [-1.04268751784334, 53.809299602288561],
              [-1.042135250744353, 53.810076278892168],
              [-1.042324337322645, 53.810642254606854],
              [-1.043997152867666, 53.811211073762514],
              [-1.051158708199754, 53.811377583373329],
              [-1.054749411718264, 53.812100723128104],
              [-1.061399883822417, 53.813942505284814],
              [-1.06366268744747, 53.815111586183086],
              [-1.067127940512261, 53.817609459267068],
              [-1.06983277269514, 53.818794441253274],
              [-1.074362601254481, 53.819681560961605],
              [-1.079376463276418, 53.819839679375725],
              [-1.081555768170688, 53.820835203190512],
              [-1.083857712058191, 53.822550684550599],
              [-1.084120384535684, 53.823811046728139],
              [-1.083651346212356, 53.824768311042909],
              [-1.07939145494225, 53.828166524322633],
              [-1.075289493474287, 53.83284842828354],
              [-1.075599849596218, 53.832900254766841],
              [-1.075253096011333, 53.834225148553209],
              [-1.075535809380278, 53.835394900859306],
              [-1.07623486882312, 53.836083391008216],
              [-1.078696822892761, 53.836213770736151],
              [-1.08359953536786, 53.834871612340557],
              [-1.088467165367207, 53.831911103533422],
              [-1.089526521974771, 53.832107898707541],
              [-1.092892618162304, 53.834196191590486],
              [-1.098714985750176, 53.836698342155415],
              [-1.100019589870251, 53.837088334803063],
              [-1.104799194568265, 53.837572594679706],
              [-1.114605596889615, 53.83728401505487],
              [-1.118135593126852, 53.836929803865672],
              [-1.120310926783339, 53.836314795761261],
              [-1.123802540335106, 53.834079801847892],
              [-1.126252690720279, 53.833171879102721],
              [-1.12773705951169, 53.833766012406585],
              [-1.128668372062905, 53.834579020691521],
              [-1.129245403885309, 53.835477536014828],
              [-1.129776055686211, 53.838321659367459],
              [-1.133594627430055, 53.842538687638132],
              [-1.133468891235251, 53.843255038154915],
              [-1.132737650938798, 53.843631754999137],
              [-1.130695775341872, 53.843811128571168],
              [-1.127067934286621, 53.843512455500864],
              [-1.125673573646265, 53.8439076740868],
              [-1.124151859486477, 53.844941907291926],
              [-1.123548065823489, 53.845906427909931],
              [-1.12389810162832, 53.848870594926247],
              [-1.123555588465039, 53.849767812858737],
              [-1.119590245418935, 53.8527093919744],
              [-1.118070770176099, 53.854916523741913],
              [-1.117856160194671, 53.85622991914979],
              [-1.118456998849714, 53.857373136612907],
              [-1.12182463969608, 53.858390115853332],
              [-1.12388875080718, 53.859366034817256],
              [-1.127233016260549, 53.862032020646694],
              [-1.127708811760823, 53.86298912404736],
              [-1.127059117101138, 53.864542950615849],
              [-1.125810393889414, 53.865431782472861],
              [-1.121712757850374, 53.865985207036573],
              [-1.119318859860822, 53.866652568653002],
              [-1.117053779909432, 53.867843043084079],
              [-1.112336400760664, 53.871721707412092],
              [-1.106350625975631, 53.875052361404315],
              [-1.104537409863564, 53.875629374646124],
              [-1.101492166041357, 53.875847507135731],
              [-1.100430527390948, 53.876341097747357],
              [-1.099784777749067, 53.877370796581147],
              [-1.099714832495169, 53.880344457695465],
              [-1.096378632504103, 53.882187138247374],
              [-1.095606727239362, 53.882991160652224],
              [-1.095312275076262, 53.884439632561673],
              [-1.093316830723806, 53.884553112516045],
              [-1.091193405172634, 53.885797201036617],
              [-1.090044174507298, 53.884912151213364],
              [-1.087465509474705, 53.884279588764507],
              [-1.085774281192134, 53.882442124691906],
              [-1.083656015940579, 53.882192285074105],
              [-1.080938113600668, 53.882579575709904],
              [-1.079294806642145, 53.883493667569944],
              [-1.077663696487528, 53.883573727681068],
              [-1.077274574875399, 53.882452608302565],
              [-1.076296048042319, 53.882191617824162],
              [-1.076161138925343, 53.881065259249475],
              [-1.075077138942372, 53.881173760318795],
              [-1.07322519975434, 53.879823835490555],
              [-1.071104483089658, 53.879762506595434],
              [-1.068544512723303, 53.879068559868017],
              [-1.064953026423431, 53.874567217075366],
              [-1.061149042047406, 53.875786891495267],
              [-1.05680147625035, 53.876412550527562],
              [-1.053468518381691, 53.877300421812976],
              [-1.05114377724381, 53.878800180546328],
              [-1.046513322445071, 53.88080741662661],
              [-1.036058313882074, 53.883167831588878],
              [-1.029394880085586, 53.885007969287358],
              [-1.027073419272153, 53.886376063288949],
              [-1.023740974164181, 53.886396652866942],
              [-1.021536374249935, 53.888352521153585],
              [-1.018201560313817, 53.888467314549729],
              [-1.008198353104352, 53.88997602366166],
              [-1.00299800739936, 53.886185763740023],
              [-0.998537566646538, 53.880947147959432],
            ],
          ],
          [
            [
              [-1.251896037139255, 53.935584093616036],
              [-1.244941507248532, 53.931593067832388],
              [-1.24134704961326, 53.932518658779131],
              [-1.238678205755471, 53.932623115572383],
              [-1.238741869276348, 53.933058537870764],
              [-1.236121462424824, 53.934845797346945],
              [-1.235271911714427, 53.934991391617594],
              [-1.23446005362876, 53.934491882078895],
              [-1.233545550578958, 53.93452470035438],
              [-1.229195745265056, 53.9320242756],
              [-1.228149109993137, 53.931626578381],
              [-1.227673880322377, 53.931793397769603],
              [-1.227185987584635, 53.929924350827903],
              [-1.228029372427885, 53.929200845450318],
              [-1.226611840438206, 53.928522122449102],
              [-1.226833323820778, 53.927909666969136],
              [-1.223589677148158, 53.925764881757253],
              [-1.222207618839997, 53.925481807769508],
              [-1.221782083260387, 53.925026056057888],
              [-1.218754995772016, 53.925173586747412],
              [-1.217962261877948, 53.92497518672149],
              [-1.217602373766143, 53.925979501619345],
              [-1.214300910121953, 53.926298601907909],
              [-1.20513571150781, 53.925605556349758],
              [-1.205123016557849, 53.92442983833589],
              [-1.20211886739474, 53.923053630178352],
              [-1.198804978261899, 53.922436567294262],
              [-1.195631502689666, 53.922362331544832],
              [-1.191126408201529, 53.922563974240276],
              [-1.186704092915107, 53.918424781979532],
              [-1.185707371629374, 53.91604698162255],
              [-1.179298052586387, 53.91687969922036],
              [-1.177376769180737, 53.914314847235993],
              [-1.176909658146067, 53.913913476256475],
              [-1.176401945052327, 53.913951341346298],
              [-1.17306306229953, 53.911263440174309],
              [-1.170589287493153, 53.908803425919224],
              [-1.164909820515777, 53.910558094067468],
              [-1.164522278972758, 53.90915236017625],
              [-1.162195889585533, 53.905519351326483],
              [-1.161513751366245, 53.902565599412448],
              [-1.160117068053305, 53.900413978045655],
              [-1.155776248456836, 53.897330286234343],
              [-1.154316699661323, 53.896657594221054],
              [-1.154389611979619, 53.895910297300418],
              [-1.1523223350908, 53.895719556585298],
              [-1.150231292110205, 53.894975843316509],
              [-1.150000073014776, 53.893621496326439],
              [-1.146760394778203, 53.894592607826084],
              [-1.143554853767944, 53.892471963822068],
              [-1.141986076871734, 53.892915564358923],
              [-1.141562902841131, 53.892625819149714],
              [-1.138810661025829, 53.894159260996219],
              [-1.136812802181385, 53.895924565178724],
              [-1.134809034397831, 53.894725511966008],
              [-1.134310811145519, 53.892545896943858],
              [-1.133349881789105, 53.891037948920655],
              [-1.129108133849996, 53.888965158370425],
              [-1.125234796415986, 53.885589822146578],
              [-1.124978037711011, 53.882657816508043],
              [-1.121046047821165, 53.882630896289413],
              [-1.11864552012145, 53.881397203444131],
              [-1.117430939223906, 53.880342959785175],
              [-1.115598483375132, 53.879665251841104],
              [-1.111959280024992, 53.879043375305727],
              [-1.108983541936142, 53.877341441826204],
              [-1.10533180408578, 53.875824048322997],
              [-1.112921152744102, 53.871831190952079],
              [-1.116472744642797, 53.868714216538656],
              [-1.119517578194257, 53.866965915849832],
              [-1.12132060683649, 53.866339167948958],
              [-1.125784018919437, 53.865821677015731],
              [-1.127168949107304, 53.865110902770788],
              [-1.128196501302654, 53.86330185979908],
              [-1.127720184488337, 53.861930399808813],
              [-1.123887549739171, 53.858913920523378],
              [-1.118864055389658, 53.857102884304787],
              [-1.118366811825373, 53.856021549451548],
              [-1.118581792602079, 53.854906794903719],
              [-1.12007206166129, 53.85300369840229],
              [-1.124102743272286, 53.84984461121627],
              [-1.124571310556446, 53.848587884532769],
              [-1.124045003856262, 53.846493389827948],
              [-1.125202694056529, 53.844696103601237],
              [-1.126545209813086, 53.844018283622553],
              [-1.128192699244641, 53.843875664648699],
              [-1.129329994547723, 53.844075368829486],
              [-1.129386124192351, 53.844740003909592],
              [-1.130452788471046, 53.845269051814093],
              [-1.132466019881886, 53.845670112575128],
              [-1.133963881195045, 53.845488582236428],
              [-1.135358279994106, 53.844573750137052],
              [-1.135822961718737, 53.84319111702127],
              [-1.138041938638242, 53.840083674888866],
              [-1.138939829024586, 53.839726996811308],
              [-1.141319250498469, 53.83945372535149],
              [-1.142954532332012, 53.8398977462234],
              [-1.141710490311556, 53.842022653763493],
              [-1.141517131606526, 53.843228385212001],
              [-1.142465796471649, 53.844777540389487],
              [-1.144681256275703, 53.846521776759893],
              [-1.144526359544784, 53.84763341009667],
              [-1.143389624571611, 53.849036447763396],
              [-1.144481419539681, 53.850061696523895],
              [-1.148888718923831, 53.849062982908279],
              [-1.151981563664225, 53.849012968143199],
              [-1.155143720272901, 53.850125532361957],
              [-1.156328098328179, 53.851249294759441],
              [-1.157798689994955, 53.851749477052152],
              [-1.157594275936238, 53.851481992870042],
              [-1.160559144008137, 53.85031452786076],
              [-1.162423287693409, 53.848234205531497],
              [-1.164551440322149, 53.847236968991417],
              [-1.166144289256447, 53.847013453941038],
              [-1.167218898642993, 53.84755121643051],
              [-1.170044428210669, 53.851480637744245],
              [-1.171265267237823, 53.855552618090869],
              [-1.173006359948287, 53.856152450578186],
              [-1.177520889775669, 53.85627334843138],
              [-1.180195417097646, 53.855326316251308],
              [-1.182387497908961, 53.853853728285983],
              [-1.183094576903819, 53.852049227835877],
              [-1.182837359277862, 53.85019142373428],
              [-1.183629237464866, 53.849884024253967],
              [-1.185206342045695, 53.850158092791453],
              [-1.190731709149973, 53.853875218046412],
              [-1.192132038733577, 53.854339450286545],
              [-1.196765158694711, 53.853816871954656],
              [-1.197926612051737, 53.854135627551884],
              [-1.198656828282797, 53.854985389154912],
              [-1.200125830060731, 53.855362801132316],
              [-1.200809268005854, 53.85523972157246],
              [-1.201636821142657, 53.854333831395195],
              [-1.202238051956745, 53.854215589860402],
              [-1.204671833650101, 53.854303644377268],
              [-1.20609409071801, 53.854742691632971],
              [-1.204892541242557, 53.854128939051364],
              [-1.202119642419626, 53.854046724775053],
              [-1.201198941401654, 53.854256316876928],
              [-1.20047869485595, 53.855115281699135],
              [-1.199603770932681, 53.855075296360504],
              [-1.197305611002528, 53.853697349585779],
              [-1.195650813800697, 53.853579313966534],
              [-1.191859630626627, 53.854038311595083],
              [-1.185568322523839, 53.85006976901682],
              [-1.183174862904436, 53.849642747073197],
              [-1.182482350993654, 53.850077553321633],
              [-1.182763571593306, 53.852028998456149],
              [-1.18200770964908, 53.853605764434214],
              [-1.179633254014204, 53.85538629159943],
              [-1.177402591904056, 53.856103560668693],
              [-1.172940526478242, 53.855944370727208],
              [-1.171527464168043, 53.855362980274684],
              [-1.170357137994888, 53.851426174069438],
              [-1.167517757180585, 53.847427455209775],
              [-1.166230553369021, 53.846802831210034],
              [-1.164790407566336, 53.846915057914892],
              [-1.162072204365907, 53.848003455633041],
              [-1.160298547150956, 53.850271359555997],
              [-1.157766922335247, 53.851287262295429],
              [-1.15657405224617, 53.851113504206737],
              [-1.155362240936683, 53.84999314645367],
              [-1.151531196419167, 53.848808450316113],
              [-1.14882257851866, 53.848876458609986],
              [-1.144362908426978, 53.849760646567908],
              [-1.143765709010615, 53.848931272938138],
              [-1.144932751324609, 53.847532042916576],
              [-1.145003372968922, 53.846527667004253],
              [-1.14251865776056, 53.844421985746862],
              [-1.14190820751686, 53.843279718034609],
              [-1.142011035436286, 53.842115583376916],
              [-1.14321073489913, 53.839705430437121],
              [-1.141481199549149, 53.83918883325984],
              [-1.13773735740111, 53.839744429550059],
              [-1.134528178621508, 53.844953360891203],
              [-1.132638944004769, 53.845466431476908],
              [-1.129921793473581, 53.844781638694009],
              [-1.129853146573838, 53.844280498375745],
              [-1.132082773366417, 53.844284058474337],
              [-1.133574326946, 53.843890366061615],
              [-1.134455211702826, 53.842668934012266],
              [-1.13067639470924, 53.83850884722311],
              [-1.129910458869228, 53.83528282120831],
              [-1.128669966125748, 53.833330569186181],
              [-1.126516148947057, 53.832705510806356],
              [-1.125133725143037, 53.832836555860503],
              [-1.120138851764965, 53.835751770692873],
              [-1.117976001264887, 53.836497196566526],
              [-1.116157429436147, 53.836766047447774],
              [-1.10410919182859, 53.837077587409539],
              [-1.101024207031913, 53.836869360861762],
              [-1.098911259339235, 53.836333096089099],
              [-1.092918711786303, 53.8335510330771],
              [-1.089644869034443, 53.831485012195181],
              [-1.088695102197097, 53.831350170352927],
              [-1.087454601651402, 53.831729035995629],
              [-1.08459563569628, 53.833859947335306],
              [-1.08223486821687, 53.834806355482158],
              [-1.078380832356974, 53.835794289720482],
              [-1.076626722365043, 53.835609130501837],
              [-1.076020221380711, 53.834581599282856],
              [-1.076638749164051, 53.832505579052466],
              [-1.080335070839347, 53.828091966673753],
              [-1.084687853820793, 53.824470621037129],
              [-1.084721102114676, 53.822805349550045],
              [-1.085123100474174, 53.822622358184027],
              [-1.083585059750243, 53.821330692464358],
              [-1.079343900670175, 53.819315413222334],
              [-1.074371240176913, 53.819220529003502],
              [-1.069353029593629, 53.818028514848088],
              [-1.067105677542856, 53.816901908089989],
              [-1.06292053441551, 53.813928325700942],
              [-1.054143279790843, 53.811365196987673],
              [-1.051448469147463, 53.810920572167653],
              [-1.044534773658636, 53.810676956612838],
              [-1.043202757760429, 53.810215127708865],
              [-1.043437868676843, 53.809693882589251],
              [-1.04577562754502, 53.808198867055843],
              [-1.046936867532868, 53.80701534827341],
              [-1.048396645717602, 53.803575422212944],
              [-1.048641674166996, 53.795834821778293],
              [-1.046562576840531, 53.79131425896869],
              [-1.047093900443447, 53.790040339177139],
              [-1.049518242157873, 53.789361180738126],
              [-1.054156852716255, 53.789301673510529],
              [-1.064229364760826, 53.788093549432496],
              [-1.066449195513601, 53.787857376452564],
              [-1.067061487676558, 53.786735902772868],
              [-1.0681393808925, 53.786108807049281],
              [-1.067065175259998, 53.786569647338531],
              [-1.064761020956492, 53.784867289312835],
              [-1.062359266708672, 53.783875100494321],
              [-1.057243652638418, 53.782423829254036],
              [-1.049387986716451, 53.7807520088327],
              [-1.045171793613002, 53.77915813766559],
              [-1.037586933154557, 53.777304332130164],
              [-1.033484765070129, 53.776660133233662],
              [-1.031230343286003, 53.776636550234521],
              [-1.027869718289556, 53.777071358572961],
              [-1.026319514189913, 53.776780142943828],
              [-1.024718153189832, 53.775881775558148],
              [-1.022360491247215, 53.773707164672658],
              [-1.021784538534458, 53.771655812308197],
              [-1.02285587238996, 53.771062324653741],
              [-1.025733042486815, 53.771568410615146],
              [-1.027650015875141, 53.771475209908822],
              [-1.029681901702485, 53.770806753095336],
              [-1.030842379957666, 53.769828314108302],
              [-1.033962945875476, 53.76607657442657],
              [-1.034056151413329, 53.764725471763079],
              [-1.032762639497447, 53.763514195946655],
              [-1.027179284534771, 53.760332077437603],
              [-1.024540920625836, 53.75950258523136],
              [-1.02131236807945, 53.759858283637449],
              [-1.011020889559298, 53.763583129005021],
              [-1.002756641703857, 53.768176971851865],
              [-1.001483301796986, 53.768578943609626],
              [-1.000955117493391, 53.768331857245542],
              [-1.000499603654668, 53.766622060800046],
              [-0.999549539444595, 53.765615525132603],
              [-0.995380136200941, 53.764427451800358],
              [-0.988714837839419, 53.764593437391198],
              [-0.986991765485004, 53.764203176702367],
              [-0.988602181819831, 53.762084266488628],
              [-0.988742228498258, 53.760927288464728],
              [-0.986379220858596, 53.757321848790433],
              [-0.982062970816095, 53.753222504531209],
              [-0.979805689965213, 53.751741796014585],
              [-0.97708289043829, 53.750515041699018],
              [-0.974549526521581, 53.74967365235684],
              [-0.967097938466246, 53.748105913713921],
              [-0.96506476334615, 53.747349526907911],
              [-0.963902716371312, 53.746505359341974],
              [-0.962989009271755, 53.745061100812315],
              [-0.962041922092581, 53.741097074585412],
              [-0.963027028198278, 53.737110218787826],
              [-0.961621849680811, 53.735686861789823],
              [-0.958380493802289, 53.735078981411178],
              [-0.955933516639387, 53.735392064881786],
              [-0.952602636578115, 53.735340534911444],
              [-0.944515950460439, 53.732449905989156],
              [-0.936325308745185, 53.73131955289805],
              [-0.930908223358649, 53.731117654375957],
              [-0.924333024442783, 53.731333875883585],
              [-0.916823829110842, 53.732381728027804],
              [-0.911798863120919, 53.732373065685003],
              [-0.911330160936093, 53.731504111953114],
              [-0.912469982536427, 53.728336110869655],
              [-0.911164920427235, 53.72722136749659],
              [-0.908782967234126, 53.726665784816852],
              [-0.905892155266831, 53.725221036583001],
              [-0.903835330368401, 53.72085357728993],
              [-0.903645569168882, 53.719758832129543],
              [-0.904256072519742, 53.718017937292366],
              [-0.905401070374819, 53.717341674343174],
              [-0.906548410714868, 53.717099569654458],
              [-0.91047966492083, 53.718548376463502],
              [-0.912406214430021, 53.718882272903478],
              [-0.920432066170332, 53.719050118051392],
              [-0.923947953720646, 53.718807587143807],
              [-0.935264829690333, 53.71641344532339],
              [-0.940496354477979, 53.71355291708884],
              [-0.944160553328001, 53.712689099359935],
              [-0.949712286194139, 53.713049787004742],
              [-0.954193888092697, 53.714835452101021],
              [-0.956520165530073, 53.715127183568285],
              [-0.957829754156325, 53.714988470898859],
              [-0.958796523428747, 53.714496213515183],
              [-0.95925257416273, 53.713638175444366],
              [-0.958969434729842, 53.71099039263305],
              [-0.959362910501327, 53.7099035015328],
              [-0.961647397434367, 53.707636636102819],
              [-0.960879627992915, 53.706700566517526],
              [-0.958081236312376, 53.705054735129771],
              [-0.961717023678079, 53.704253955153789],
              [-0.962916048862458, 53.703545255638716],
              [-0.963206306435418, 53.702706440377384],
              [-0.962778357321512, 53.700950870454967],
              [-0.963904962073401, 53.700657703600392],
              [-0.966576929306721, 53.700783247427545],
              [-0.969596094357422, 53.702260896704985],
              [-0.9693271277974, 53.702791603479731],
              [-0.967108579227286, 53.703506863392946],
              [-0.966029397716721, 53.704582472051641],
              [-0.966250636934988, 53.705939851398711],
              [-0.967990255887023, 53.706973246405603],
              [-0.974460301143939, 53.70630431816155],
              [-0.980922222009694, 53.705147794369964],
              [-0.982904207964655, 53.704247804755425],
              [-0.983722310722893, 53.702546927297256],
              [-0.982714208583498, 53.699122733940172],
              [-0.981902893353287, 53.698283505435683],
              [-0.98034074186841, 53.69749091626597],
              [-0.980288079660713, 53.697100365846424],
              [-0.985949928154349, 53.697784804645721],
              [-0.994489076929338, 53.697610482707738],
              [-0.998666525783205, 53.698947003259953],
              [-1.001050743409866, 53.699070271939554],
              [-1.003139691270952, 53.698613075786618],
              [-1.004344168573772, 53.6966096639621],
              [-1.005853832263222, 53.695726044597698],
              [-1.006888398685568, 53.695527888286641],
              [-1.011007207603174, 53.697047756506535],
              [-1.016987473329382, 53.697932898734287],
              [-1.021357911083901, 53.69822934029051],
              [-1.034048243985492, 53.696615514411313],
              [-1.036258977331152, 53.695711996149264],
              [-1.037947458162304, 53.694327855257733],
              [-1.038871488859641, 53.69405393130392],
              [-1.040010789621761, 53.694569116715826],
              [-1.040983437905777, 53.696220899544308],
              [-1.04557280514828, 53.698689826021528],
              [-1.048007427858966, 53.698665150057643],
              [-1.052143811594611, 53.696919123012165],
              [-1.053475294726025, 53.696979983772437],
              [-1.053909985332176, 53.701573827989051],
              [-1.058503484805677, 53.704460247934463],
              [-1.060842789116467, 53.706622350865295],
              [-1.06238204730389, 53.707028098380519],
              [-1.063678536386521, 53.706701168399164],
              [-1.067837904134433, 53.704401092224643],
              [-1.069010182774615, 53.704342792069689],
              [-1.071014255149307, 53.708268318940746],
              [-1.072621593247694, 53.709167927335798],
              [-1.075431903798849, 53.709240876011918],
              [-1.079688110123517, 53.708108735833434],
              [-1.080596245538434, 53.708287385613964],
              [-1.081180772065044, 53.708925553312945],
              [-1.080926918839582, 53.711050269952274],
              [-1.084377412918078, 53.714930919055988],
              [-1.084475461020916, 53.71592848410971],
              [-1.083871808738847, 53.717070793844073],
              [-1.08433697775559, 53.717421301642126],
              [-1.091140013870309, 53.714927564119883],
              [-1.09378105252611, 53.713024940595673],
              [-1.094773736794117, 53.713154685167069],
              [-1.095857835921836, 53.714109348943964],
              [-1.094533164526537, 53.715820629723879],
              [-1.094961218664478, 53.716209465850532],
              [-1.097199697374148, 53.71604927687445],
              [-1.101834809630653, 53.714561465674151],
              [-1.103857380508193, 53.714644917976621],
              [-1.104491007989097, 53.714989415936266],
              [-1.104042041314063, 53.715510084621719],
              [-1.100878471278327, 53.716633314084248],
              [-1.098855908363387, 53.717821671747949],
              [-1.09819451334623, 53.718775760163489],
              [-1.098357932151047, 53.719354045345405],
              [-1.09917551858064, 53.719530073942998],
              [-1.103244633821798, 53.718734553862824],
              [-1.104469482559306, 53.718785957466309],
              [-1.107966863079682, 53.721370136256134],
              [-1.108277990769016, 53.722996654303216],
              [-1.109377015894344, 53.723342789210015],
              [-1.112460947767962, 53.722632211945829],
              [-1.114236193721425, 53.721234166789905],
              [-1.1149980174867, 53.719956251924202],
              [-1.116456698090566, 53.720060492978988],
              [-1.117182150188318, 53.720877490243424],
              [-1.116474178901088, 53.722408389330369],
              [-1.116700921762608, 53.722870265966698],
              [-1.118531417757769, 53.724259863378165],
              [-1.120120802591769, 53.726750536679859],
              [-1.120865631985442, 53.726647242418508],
              [-1.120910304514717, 53.726249384854256],
              [-1.117063122583821, 53.722653617963751],
              [-1.117776707033563, 53.720781198029648],
              [-1.11739632276884, 53.720057528650564],
              [-1.115429764110838, 53.719313173622055],
              [-1.114773076312771, 53.719337986979113],
              [-1.113727974946391, 53.721043451370491],
              [-1.112172927007446, 53.722419750508564],
              [-1.111165761097489, 53.722820358971141],
              [-1.109132957318475, 53.722986832822336],
              [-1.10859260643235, 53.722667322811382],
              [-1.108504965865567, 53.721293244267862],
              [-1.104860773063335, 53.71862798807782],
              [-1.103414607037439, 53.718366402103598],
              [-1.099489508219169, 53.719161212323016],
              [-1.098880650434975, 53.719074841715923],
              [-1.098773067105971, 53.718719889594006],
              [-1.100262496428809, 53.717237206713705],
              [-1.104880404139972, 53.715347366947121],
              [-1.104898587448034, 53.714704831603321],
              [-1.104155812855107, 53.714434123772499],
              [-1.101703380415418, 53.714329478491457],
              [-1.097798237241493, 53.715624137938946],
              [-1.095764008378583, 53.715941378755893],
              [-1.095283174471876, 53.71554046214797],
              [-1.096126383602677, 53.713951380793894],
              [-1.095366114031797, 53.713157360375],
              [-1.09408708872757, 53.712744119111797],
              [-1.09306152009611, 53.713016801150879],
              [-1.09191590031565, 53.71414965542413],
              [-1.089553262493194, 53.715362245045888],
              [-1.084761712980061, 53.716912205953314],
              [-1.084748773492061, 53.714576016387952],
              [-1.081386145874002, 53.710973793843948],
              [-1.081297011133063, 53.710127299150656],
              [-1.081779799329256, 53.710081562845879],
              [-1.081332959927487, 53.710007129733334],
              [-1.081640754959995, 53.708952449070459],
              [-1.081315099130784, 53.708253349376974],
              [-1.079795832232158, 53.707823730759344],
              [-1.075394847982453, 53.708996104598036],
              [-1.072314899223201, 53.708784443703912],
              [-1.071496309205313, 53.708186664640408],
              [-1.069685914733104, 53.704338152663823],
              [-1.068933754299683, 53.703961986812203],
              [-1.069704940946503, 53.702857003688969],
              [-1.071894322211252, 53.703523845579383],
              [-1.074930719601518, 53.703976971854829],
              [-1.075892331090398, 53.701432571094514],
              [-1.078157315515898, 53.697738685772336],
              [-1.076354214035861, 53.696732475059129],
              [-1.08501491058594, 53.686939483974705],
              [-1.080681498048405, 53.685255130122776],
              [-1.081064287342248, 53.684573145351443],
              [-1.087315025498668, 53.68329782365447],
              [-1.093144596842239, 53.678174621846871],
              [-1.097479641933982, 53.675273522101826],
              [-1.100210271516728, 53.674363756254635],
              [-1.101987209638742, 53.674375292052581],
              [-1.103567332094658, 53.669537845858038],
              [-1.091515083177403, 53.66866325133639],
              [-1.08700564962473, 53.666584084567319],
              [-1.088769331483695, 53.66445825312217],
              [-1.085636225789626, 53.662374213513864],
              [-1.084466506172499, 53.662020120000754],
              [-1.082688231373463, 53.662024491421171],
              [-1.078798698549655, 53.660438730097802],
              [-1.075379338564843, 53.660546311594452],
              [-1.074263404405239, 53.660091861681487],
              [-1.073295629468147, 53.660265945815404],
              [-1.071057225757483, 53.660002321016606],
              [-1.071186979412012, 53.657885633074116],
              [-1.067899046668771, 53.657256960826345],
              [-1.063280851508479, 53.657460924848586],
              [-1.061872935690028, 53.659194577740486],
              [-1.060226824828708, 53.659249087127542],
              [-1.059201096930294, 53.659101712462061],
              [-1.059621400945493, 53.658370650709273],
              [-1.057541044591214, 53.657610048852376],
              [-1.050999633669205, 53.657537723080971],
              [-1.048659204673737, 53.656037845119847],
              [-1.050478680484779, 53.654929615551119],
              [-1.051713085292876, 53.652514288138228],
              [-1.053274146242629, 53.651517225462349],
              [-1.066068789511373, 53.648871687115111],
              [-1.071169097496098, 53.648922138780001],
              [-1.08256005927132, 53.6515474013908],
              [-1.084813942165047, 53.651707563173446],
              [-1.087010136352823, 53.651317145626493],
              [-1.090459492797776, 53.649849500251769],
              [-1.095118177776374, 53.649970180985513],
              [-1.10159749328283, 53.648569114607461],
              [-1.11382776929451, 53.646893996060477],
              [-1.116047614100183, 53.645781445651252],
              [-1.116995903830621, 53.645863944733449],
              [-1.119343339047436, 53.646825920971722],
              [-1.12174071675647, 53.646699710125795],
              [-1.123116784326603, 53.645997904707166],
              [-1.124996940490462, 53.643961380465882],
              [-1.128033828727557, 53.643550285431402],
              [-1.128992711625325, 53.64297031456509],
              [-1.128476258362053, 53.64079943017407],
              [-1.127072401852753, 53.638536686302508],
              [-1.128869349798258, 53.634933695894503],
              [-1.130567304804764, 53.633851235944874],
              [-1.133439131587423, 53.633486446185124],
              [-1.135404385157525, 53.632709663605084],
              [-1.138896361010555, 53.633447614654102],
              [-1.140121435587425, 53.635600175707424],
              [-1.143512579889851, 53.636329176949104],
              [-1.1448378777154, 53.637206038426818],
              [-1.145739757914597, 53.63853108744253],
              [-1.149656183586659, 53.638803448598154],
              [-1.152294884390165, 53.642758213614869],
              [-1.153293772700258, 53.642655620660939],
              [-1.155544283652066, 53.6432009324406],
              [-1.158789289862418, 53.643411629298427],
              [-1.161380045114543, 53.641756145340288],
              [-1.163129928371776, 53.641842990830753],
              [-1.165399147023939, 53.640831444272756],
              [-1.167770249424071, 53.640785925044014],
              [-1.170369890278306, 53.639345133714073],
              [-1.17239108496296, 53.639398668359682],
              [-1.174448186373764, 53.638155379957382],
              [-1.17969591086099, 53.637873249973765],
              [-1.18856680886766, 53.635967010764887],
              [-1.190310398443223, 53.6358988153398],
              [-1.193692155386244, 53.636372854036587],
              [-1.194517048040476, 53.638087111701743],
              [-1.196111228867539, 53.638960715736246],
              [-1.197226144846885, 53.638950217816834],
              [-1.197624223422332, 53.639732184747054],
              [-1.198464739841632, 53.640234874828323],
              [-1.205319234684034, 53.642589687989222],
              [-1.208124535315091, 53.642704446290047],
              [-1.209824065934514, 53.643145319309838],
              [-1.210889330139511, 53.640937590773532],
              [-1.215643981810437, 53.641963845971588],
              [-1.216297593816535, 53.639996060989823],
              [-1.217678076085417, 53.640184855823883],
              [-1.21859742031015, 53.636598177111843],
              [-1.21938408484973, 53.636668923240052],
              [-1.219633347788906, 53.634471074848015],
              [-1.219001113131446, 53.634393247880389],
              [-1.218951732545489, 53.633299930308752],
              [-1.218467420591067, 53.633156550628946],
              [-1.218367118065039, 53.631382474270765],
              [-1.217361631056252, 53.629085648181849],
              [-1.21746548104004, 53.624206491137279],
              [-1.218841445583474, 53.62354763282908],
              [-1.221429937469398, 53.623463821336387],
              [-1.226663291199411, 53.621947226480387],
              [-1.227910037106011, 53.622144030099882],
              [-1.232841229507838, 53.621094414734756],
              [-1.238055089080165, 53.626835374730526],
              [-1.242687150997217, 53.633560249254437],
              [-1.242884856129622, 53.634596967058989],
              [-1.243551895818239, 53.634253330060758],
              [-1.245300788768179, 53.635319606585547],
              [-1.250638670849198, 53.638778616351239],
              [-1.253659088607037, 53.641180307749664],
              [-1.254949572730308, 53.643312305419556],
              [-1.254856844883191, 53.644454155793291],
              [-1.255365471044448, 53.644777306645985],
              [-1.255213430052134, 53.648943387268105],
              [-1.254328725278055, 53.648496551861633],
              [-1.253016375834954, 53.648793980109168],
              [-1.252613791740019, 53.648717763693099],
              [-1.252686195712788, 53.64838744209478],
              [-1.251680254235302, 53.648382060135546],
              [-1.250072198308645, 53.648803445971886],
              [-1.248773429686262, 53.649611454433852],
              [-1.251157141118486, 53.651609223726723],
              [-1.246957286508356, 53.655595315513771],
              [-1.247967654640442, 53.656211975590374],
              [-1.252293979821094, 53.661753438133957],
              [-1.252501495174534, 53.66242706459505],
              [-1.251188475949939, 53.662566273600639],
              [-1.252316532503766, 53.666636968004319],
              [-1.251763377321762, 53.667960199166636],
              [-1.250215758081375, 53.66936888911443],
              [-1.248777484578565, 53.671602478813519],
              [-1.247782061140997, 53.671825436403928],
              [-1.247759699097881, 53.672146180993771],
              [-1.24654697619831, 53.672245521821694],
              [-1.24643913498436, 53.672945037977236],
              [-1.245650957064926, 53.672834014926984],
              [-1.245159046780671, 53.673339658608818],
              [-1.243954540263153, 53.673231393570546],
              [-1.242429137540346, 53.680465485990162],
              [-1.244413700295657, 53.692410959165478],
              [-1.234726811274977, 53.69269913322163],
              [-1.209764335231178, 53.694486688220806],
              [-1.198988833333416, 53.694878942423777],
              [-1.199674907023891, 53.698712571780533],
              [-1.198814457174408, 53.700713924027397],
              [-1.201207994380212, 53.701819291857447],
              [-1.203982242280578, 53.702102009458152],
              [-1.215127330444079, 53.705471548546157],
              [-1.214944720801109, 53.706004260217078],
              [-1.215660777970536, 53.706112314650056],
              [-1.21563806631768, 53.706926509995682],
              [-1.218439433619733, 53.707076940107889],
              [-1.218792298866309, 53.709292169990569],
              [-1.217831967839052, 53.709526793938601],
              [-1.217135242134485, 53.711230927876386],
              [-1.21980833092205, 53.71440056661455],
              [-1.221725075391702, 53.712840070417847],
              [-1.224631365445143, 53.712333089914431],
              [-1.224923794195309, 53.711837926711077],
              [-1.223938023038054, 53.710654076306326],
              [-1.224788830557555, 53.710113091803962],
              [-1.226072067019037, 53.70986611643238],
              [-1.227229918447421, 53.709929315247514],
              [-1.228005442529455, 53.710435862002214],
              [-1.227589829300083, 53.712861830710452],
              [-1.228752717535149, 53.714560023336801],
              [-1.229569504376614, 53.714887058323121],
              [-1.239013029129282, 53.71233178720064],
              [-1.242801444327846, 53.712299154444963],
              [-1.248181547927478, 53.711711031630891],
              [-1.252937699570123, 53.712810380534535],
              [-1.25424916947457, 53.712675633570264],
              [-1.256339683001141, 53.711752040639858],
              [-1.257697290886401, 53.711834160570767],
              [-1.263587549850617, 53.714032183290215],
              [-1.269025995540399, 53.715144069523909],
              [-1.269544866055039, 53.715893261437053],
              [-1.269633629981951, 53.718379964753304],
              [-1.27081103168165, 53.721098001557316],
              [-1.270956614940364, 53.723371125956781],
              [-1.271957636548697, 53.724906116824251],
              [-1.2730693578491, 53.725650801453021],
              [-1.276114785440231, 53.725882252628175],
              [-1.277680484317034, 53.726497506940873],
              [-1.279707761918451, 53.729285283933343],
              [-1.283760285908421, 53.73199435991198],
              [-1.286652992163486, 53.735368722499487],
              [-1.289211730432313, 53.736638680285928],
              [-1.292170264310917, 53.738846620598764],
              [-1.293664356327787, 53.739344392796269],
              [-1.296466671747108, 53.739582878962182],
              [-1.299737783049039, 53.741628877524761],
              [-1.302039565337176, 53.741707014216097],
              [-1.302345195288186, 53.742478186217127],
              [-1.303827361284008, 53.741877398731404],
              [-1.307221862426248, 53.742722180257203],
              [-1.310032286847658, 53.744520752208309],
              [-1.311563397534612, 53.744541237713619],
              [-1.315353209946025, 53.743663215708317],
              [-1.31279968281074, 53.749529604173112],
              [-1.312829177017939, 53.751161135234966],
              [-1.312084890506723, 53.752162649414522],
              [-1.312612136395841, 53.754145777038467],
              [-1.312235136828986, 53.755871150121173],
              [-1.308504729161769, 53.756477973349476],
              [-1.307732176945024, 53.755127081389212],
              [-1.305958881580517, 53.755238172168511],
              [-1.305559472296869, 53.754718138505631],
              [-1.301496475793837, 53.755717400069827],
              [-1.298269455640861, 53.755596992741168],
              [-1.297528627149418, 53.75582005726681],
              [-1.294938325760645, 53.755445326444551],
              [-1.294692615374209, 53.756260910960471],
              [-1.296745546507768, 53.758936177185831],
              [-1.292695343156074, 53.760130260739693],
              [-1.292461975860689, 53.761470824861419],
              [-1.291964145730834, 53.761397778608114],
              [-1.292224844083142, 53.760507686408602],
              [-1.290544269920803, 53.762752000741777],
              [-1.29039463536731, 53.764249900107274],
              [-1.291957971259778, 53.767177162342634],
              [-1.295578648076276, 53.771295305679168],
              [-1.298122445828852, 53.772788777350939],
              [-1.2986472523707, 53.772617476333011],
              [-1.30678383402187, 53.773027003312826],
              [-1.303460098526573, 53.774910547201536],
              [-1.312594641536959, 53.779281167864582],
              [-1.311600172952868, 53.779681730303324],
              [-1.313806757518719, 53.781538747197416],
              [-1.313632623534078, 53.786073181687442],
              [-1.311917461371177, 53.788118949459594],
              [-1.31126503456556, 53.788010942737095],
              [-1.311639928658141, 53.788661141210767],
              [-1.312823298559623, 53.789346532025171],
              [-1.311724979486233, 53.795429713065147],
              [-1.313620461435142, 53.80086402156978],
              [-1.314164436024983, 53.804840789777394],
              [-1.313937926864506, 53.806810593360375],
              [-1.314598105645778, 53.807765310992117],
              [-1.314868333103267, 53.809540212872434],
              [-1.31361986552123, 53.809642732980713],
              [-1.312431086762242, 53.814358289958818],
              [-1.310632389320469, 53.814199666031456],
              [-1.309387741612366, 53.813690971654765],
              [-1.307598367571441, 53.813614147741745],
              [-1.307289191129288, 53.814495896399301],
              [-1.305102334065274, 53.815033325771786],
              [-1.303619647457239, 53.816518557394446],
              [-1.30708762968211, 53.817601029050579],
              [-1.310394555000653, 53.819900364525679],
              [-1.309669682192183, 53.822877555150612],
              [-1.314025305288541, 53.823136222127822],
              [-1.315022179211263, 53.823674911119511],
              [-1.315365491537347, 53.823582494994312],
              [-1.322309571801183, 53.827436459932962],
              [-1.328299784788598, 53.83011443293605],
              [-1.332768548441877, 53.832773768398468],
              [-1.336092671775997, 53.833572367475959],
              [-1.33401658535148, 53.833792758381541],
              [-1.334371362314918, 53.834417600629529],
              [-1.333535618616743, 53.834513629456367],
              [-1.33349463141974, 53.83479832411021],
              [-1.331795249835706, 53.834832018203748],
              [-1.331181688101825, 53.835097344557127],
              [-1.331247318405906, 53.835460828838308],
              [-1.329735898317939, 53.835807427470201],
              [-1.328745965728978, 53.835470235004429],
              [-1.328436367685386, 53.834970563224203],
              [-1.327696576036473, 53.835146183416278],
              [-1.32737452240576, 53.834855861485075],
              [-1.326203846015784, 53.835179158080322],
              [-1.326023660303488, 53.834949849013711],
              [-1.325586457484342, 53.835207148929499],
              [-1.325338410283016, 53.835040374112459],
              [-1.325204610002701, 53.83532903836408],
              [-1.325996647417987, 53.835875470248297],
              [-1.325418140743407, 53.838117443817033],
              [-1.3265530944575, 53.840405890681886],
              [-1.327958916478076, 53.840687007396014],
              [-1.328153110805254, 53.841277712349871],
              [-1.332339356643445, 53.840941547258645],
              [-1.3382207209144, 53.844226898951909],
              [-1.342133542854102, 53.845155302284539],
              [-1.340456107773451, 53.849086463652604],
              [-1.338424735759027, 53.85172676285125],
              [-1.342745509324296, 53.851500628357407],
              [-1.347010472771726, 53.854174478345122],
              [-1.349430530645633, 53.854969576953458],
              [-1.352965325609722, 53.856733258575808],
              [-1.349921604699956, 53.857617417932204],
              [-1.341966086948539, 53.858939361419473],
              [-1.331549712922527, 53.86151711346448],
              [-1.323257678892186, 53.861654915717757],
              [-1.318888391416899, 53.862269258538667],
              [-1.315408910284134, 53.863324439990009],
              [-1.312598459167607, 53.865299235430712],
              [-1.3117791133634, 53.866857563429775],
              [-1.314792686120657, 53.868654432115306],
              [-1.318402892447191, 53.877456290363519],
              [-1.31707678647734, 53.8784662007713],
              [-1.319424343167425, 53.878625134534779],
              [-1.319688468846616, 53.878940313270618],
              [-1.315755223573399, 53.879473426007394],
              [-1.315602321534421, 53.88166473405569],
              [-1.320799749044004, 53.883770474820842],
              [-1.319991679394009, 53.885380149733578],
              [-1.320015437756635, 53.888510809313615],
              [-1.317948686815829, 53.888732778024412],
              [-1.317542548149091, 53.890128106542058],
              [-1.317925638637439, 53.891831712835376],
              [-1.316772419332577, 53.892008513371508],
              [-1.31781774351742, 53.894066417051327],
              [-1.317726076212059, 53.896331771760998],
              [-1.318140332826528, 53.897526831778357],
              [-1.322266030294349, 53.900439822503458],
              [-1.321781188679111, 53.901151631188426],
              [-1.321777178714497, 53.902903367787921],
              [-1.321048199765771, 53.903462796400234],
              [-1.319478722757017, 53.903947355450903],
              [-1.313717857314877, 53.90468485037028],
              [-1.308476826593247, 53.905453824699613],
              [-1.306645363952065, 53.905292268788507],
              [-1.303865038304932, 53.904434911515288],
              [-1.297270672980158, 53.904843216812353],
              [-1.296603564514017, 53.904415977324426],
              [-1.297675644968766, 53.902840362295429],
              [-1.296719396053826, 53.902503109885018],
              [-1.289186682846347, 53.904406469998492],
              [-1.287732437999125, 53.904087769193573],
              [-1.285125814397781, 53.902800480769471],
              [-1.282451205870015, 53.902203907121283],
              [-1.281954192336117, 53.901898040919313],
              [-1.282140636428213, 53.901498289953317],
              [-1.283853418985836, 53.90084160372858],
              [-1.285447192387958, 53.900913113081742],
              [-1.286162077409772, 53.902099287358936],
              [-1.286964059456797, 53.902194829793572],
              [-1.288775417295774, 53.901018252909942],
              [-1.291468593659013, 53.900877778572863],
              [-1.291646402653021, 53.89972296924342],
              [-1.289028036922998, 53.899405501603802],
              [-1.286840984019423, 53.898070398724442],
              [-1.283704020154883, 53.897384820548552],
              [-1.283477431177541, 53.897019454209882],
              [-1.284555325289926, 53.894675512383245],
              [-1.284045892822248, 53.894566417981061],
              [-1.282547486569209, 53.895071591309886],
              [-1.281707615537266, 53.894886810822918],
              [-1.281243619589264, 53.893903441419646],
              [-1.281681888357279, 53.892659424220483],
              [-1.279243016430192, 53.892552235861331],
              [-1.278571346498013, 53.892058355461231],
              [-1.278547462332837, 53.891412871400206],
              [-1.275519496025446, 53.892908228165837],
              [-1.275577338131732, 53.893347192947161],
              [-1.273532787071284, 53.893577520040601],
              [-1.272094178632325, 53.895803233793409],
              [-1.270333171183954, 53.896958295329071],
              [-1.269203973779183, 53.898268175888205],
              [-1.266784421094666, 53.899474902472761],
              [-1.268134112956157, 53.900881670825513],
              [-1.268537693341123, 53.901999542826132],
              [-1.269226765204385, 53.902021713234241],
              [-1.270124680953589, 53.906654203978874],
              [-1.269264953763501, 53.907510028608783],
              [-1.267985441819462, 53.911130697952117],
              [-1.267547768699675, 53.914133619311649],
              [-1.269093253555954, 53.916213868113502],
              [-1.270264788589618, 53.91695800618831],
              [-1.266584752247823, 53.917658230840587],
              [-1.260800901465776, 53.919795180846137],
              [-1.260836835721596, 53.92000212593485],
              [-1.263760481707041, 53.920338253874839],
              [-1.263268577328122, 53.921727478928027],
              [-1.261953762385873, 53.923236582356751],
              [-1.26260375808889, 53.925064237258916],
              [-1.263427428548571, 53.925853042551765],
              [-1.262428370454645, 53.92706658349411],
              [-1.262691888138188, 53.927754883162514],
              [-1.265956490246991, 53.930390375813097],
              [-1.268929021692605, 53.931459194005051],
              [-1.270850486562756, 53.934641827072802],
              [-1.269487647488782, 53.934967901733607],
              [-1.26544587500805, 53.935011569709388],
              [-1.262567156619336, 53.934314445669671],
              [-1.255787409451768, 53.935436588213818],
              [-1.251896037139255, 53.935584093616036],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000170",
        LAD13CDO: "37UB",
        LAD13NM: "Ashfield",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.284699489549888, 53.168733098736951],
            [-1.281991550570774, 53.1674088979942],
            [-1.27657470162304, 53.167339280104592],
            [-1.274914340912293, 53.168135511717772],
            [-1.273737906790437, 53.167909013113686],
            [-1.273434284107599, 53.168172340993237],
            [-1.272468839416883, 53.167853629359712],
            [-1.271303305064525, 53.168221353537653],
            [-1.271163900854402, 53.167973300377056],
            [-1.269272505931393, 53.1679203669134],
            [-1.268980959255879, 53.168175667364359],
            [-1.26869692608138, 53.167900655500198],
            [-1.265778342255102, 53.167469208716128],
            [-1.26513223161031, 53.166937564679159],
            [-1.262348007206664, 53.166614731471782],
            [-1.261780034569952, 53.165891185528956],
            [-1.260344688021385, 53.165332150169021],
            [-1.260285993020491, 53.164743897155603],
            [-1.259927381544873, 53.164461209709458],
            [-1.2586504068926, 53.163482446044526],
            [-1.257996846898739, 53.161309301276908],
            [-1.257023932228235, 53.160139146071273],
            [-1.256843762445938, 53.156367979134117],
            [-1.255326564382867, 53.154597533553734],
            [-1.254188034388469, 53.154713563283842],
            [-1.251278088545059, 53.154360915941574],
            [-1.247525506945152, 53.153025758074442],
            [-1.243597733934183, 53.154185656060172],
            [-1.24093922990111, 53.152979483484224],
            [-1.239771119467558, 53.149859091063306],
            [-1.239849960837198, 53.148696397032495],
            [-1.237823120336402, 53.148742775825475],
            [-1.23715194136476, 53.146285337509283],
            [-1.2395461448963, 53.140584432954576],
            [-1.239919869984949, 53.139568344898429],
            [-1.237916898000254, 53.139641843973529],
            [-1.229302816628836, 53.134152463335511],
            [-1.224605793660394, 53.131613096911828],
            [-1.222934883854621, 53.130282594055409],
            [-1.22029497036792, 53.129648683306307],
            [-1.217365183220038, 53.128274795878056],
            [-1.210589250141751, 53.125899177972592],
            [-1.202489444237743, 53.124445596986689],
            [-1.201918213152365, 53.122690670711812],
            [-1.200684243319027, 53.121786170137767],
            [-1.198845423181279, 53.121751341918902],
            [-1.193783252258006, 53.119888773021444],
            [-1.18847001061199, 53.119718747156291],
            [-1.186449069031238, 53.117304832979698],
            [-1.185854614061092, 53.115790578351735],
            [-1.182990515890505, 53.115156998640536],
            [-1.177893769402798, 53.11479201820665],
            [-1.175585492345878, 53.112290533316347],
            [-1.172753411971031, 53.111176002259739],
            [-1.169994380649144, 53.109456036236878],
            [-1.167780954140794, 53.107496210387367],
            [-1.164254217109779, 53.103251048324431],
            [-1.167675043436334, 53.102535191885046],
            [-1.175236443955253, 53.101640337134995],
            [-1.180013579384953, 53.100546525763988],
            [-1.183029121624467, 53.099399997832656],
            [-1.185645688921929, 53.09908711696017],
            [-1.196047068569612, 53.094485181384989],
            [-1.192929810642068, 53.092604212343367],
            [-1.194916069475918, 53.092433379614981],
            [-1.199722108441923, 53.090785685950586],
            [-1.199703378363473, 53.089138725631202],
            [-1.201789420909341, 53.088003001535945],
            [-1.202508726028056, 53.086892253092017],
            [-1.205906576243052, 53.086426847323757],
            [-1.210719236194463, 53.084847073712382],
            [-1.213482117926021, 53.0850478405088],
            [-1.215039939975033, 53.084685966050984],
            [-1.218798301269052, 53.084659447737117],
            [-1.221168054156632, 53.083977406632044],
            [-1.226312042789842, 53.081023813349034],
            [-1.226366114849479, 53.077090459665982],
            [-1.227360903464719, 53.075053658009146],
            [-1.228007055751777, 53.07063872021596],
            [-1.229724218996001, 53.068085201379034],
            [-1.229982700855589, 53.065222886697114],
            [-1.231291295542417, 53.062046438852377],
            [-1.228887524210669, 53.058190662445234],
            [-1.228121440313367, 53.054552220094116],
            [-1.217300865627578, 53.049083467958759],
            [-1.215279723533466, 53.049236460902328],
            [-1.210949026931351, 53.050220923872828],
            [-1.208770525897995, 53.051142249151219],
            [-1.208334939188167, 53.050814837886733],
            [-1.206788164215065, 53.051369967342673],
            [-1.2060733651709, 53.050487843007737],
            [-1.203968569651184, 53.050560080960786],
            [-1.202349655090754, 53.051787970902346],
            [-1.202027328986456, 53.050823053252508],
            [-1.199906730035101, 53.051586392802797],
            [-1.19710792397621, 53.048619942401942],
            [-1.196416191035234, 53.048997320364158],
            [-1.191719090687237, 53.047775323428795],
            [-1.189957701496992, 53.048632616482514],
            [-1.189598833172008, 53.048041371577078],
            [-1.189992751613613, 53.047876851638961],
            [-1.189425353997837, 53.0473489098678],
            [-1.185830915311737, 53.048663785397068],
            [-1.185387239589427, 53.048301175621482],
            [-1.183985917094869, 53.048647553288419],
            [-1.182245051159573, 53.04848907758069],
            [-1.181584086378247, 53.047865168176273],
            [-1.181256324658621, 53.043354755533002],
            [-1.178318996811002, 53.039845741156164],
            [-1.177134309269295, 53.037128161433273],
            [-1.176903804903818, 53.03521363034826],
            [-1.17775975378523, 53.033020748745017],
            [-1.178032552535816, 53.029966247264426],
            [-1.179459114265356, 53.026924200733916],
            [-1.17934261650552, 53.024734483489901],
            [-1.18007428065749, 53.023810924819244],
            [-1.182995737552849, 53.021954038994302],
            [-1.183647007791466, 53.020860006847698],
            [-1.182749956009648, 53.018549868682058],
            [-1.183210567780517, 53.017919279993237],
            [-1.184038192745966, 53.017587857040311],
            [-1.184529281787697, 53.016366869367644],
            [-1.186815193517159, 53.017754296527492],
            [-1.188315769577605, 53.017942533484813],
            [-1.189208871563173, 53.017296892873006],
            [-1.195188378833298, 53.015431743651781],
            [-1.198024235163614, 53.015956097291749],
            [-1.199949534233905, 53.016794285214928],
            [-1.201035812297973, 53.017865927573389],
            [-1.203025048297782, 53.018316153353808],
            [-1.207224273039223, 53.018647150658531],
            [-1.210163391601138, 53.018025756740499],
            [-1.210974781621543, 53.015140158047501],
            [-1.212978380045022, 53.012668765642729],
            [-1.222387892095963, 53.008360905764221],
            [-1.224742950279608, 53.008594718370595],
            [-1.22515015050907, 53.008076886344597],
            [-1.227746535167617, 53.008567509355501],
            [-1.228236712626812, 53.008910488718712],
            [-1.229330989595688, 53.008807912294387],
            [-1.231089123956049, 53.009507874754071],
            [-1.231491923963242, 53.010493015795738],
            [-1.232599048375696, 53.011348759008619],
            [-1.232548057750117, 53.012711218228205],
            [-1.230665851135342, 53.016214802199137],
            [-1.23112204178216, 53.016966565095146],
            [-1.230958683473214, 53.018441562206235],
            [-1.231969157266416, 53.019462089015974],
            [-1.2316255683199, 53.020090024457048],
            [-1.231915801658522, 53.02112387645419],
            [-1.2290666620943, 53.022725331101533],
            [-1.234819911275907, 53.02685352196216],
            [-1.235363521483459, 53.027899780393128],
            [-1.241262009050516, 53.031132349158412],
            [-1.238778562040313, 53.032611414125292],
            [-1.24711517409216, 53.035048422165588],
            [-1.247464526524523, 53.035711347312933],
            [-1.251257380557899, 53.038009570573379],
            [-1.253435644742381, 53.039774374755346],
            [-1.25769610655774, 53.040938174242712],
            [-1.258246572164106, 53.040410338313947],
            [-1.262904937314257, 53.039242805667094],
            [-1.263797513604234, 53.038413223964632],
            [-1.266395772468063, 53.038398705955977],
            [-1.267094483507283, 53.038883040255676],
            [-1.268592468057931, 53.039023500360358],
            [-1.269297465177049, 53.039402685332497],
            [-1.270331964586218, 53.040495839752133],
            [-1.270723471588681, 53.041866411440864],
            [-1.272830451913916, 53.042075271133804],
            [-1.275720535984215, 53.04326150396065],
            [-1.278695219273198, 53.044035570116812],
            [-1.277715261849848, 53.044480889254977],
            [-1.276762795111661, 53.045510671464285],
            [-1.277463916710403, 53.046221488466713],
            [-1.278167711781171, 53.046416332146528],
            [-1.278754922123007, 53.047072514659206],
            [-1.280501060696228, 53.047021057660722],
            [-1.281515392727631, 53.047740035969561],
            [-1.28482820132996, 53.048208554337691],
            [-1.288037944776115, 53.048236787833936],
            [-1.2952885180872, 53.046086555913568],
            [-1.298117568506789, 53.046492509611717],
            [-1.299105082764931, 53.046306857670373],
            [-1.300946352785517, 53.044151270418276],
            [-1.301106873291211, 53.043264072077065],
            [-1.303527841675459, 53.041865149336751],
            [-1.305140189853297, 53.044832946559531],
            [-1.307847123406927, 53.042991535269103],
            [-1.306956705117806, 53.04503590896423],
            [-1.305773927899301, 53.046145483722349],
            [-1.306822879227845, 53.047241996781032],
            [-1.308097801236477, 53.047831021333913],
            [-1.310689911780007, 53.048329685008397],
            [-1.31101602146416, 53.047991778848889],
            [-1.310444052971415, 53.046348823125378],
            [-1.314411881605651, 53.048229827993126],
            [-1.317451664407454, 53.047969531058392],
            [-1.31900301065651, 53.047309616172413],
            [-1.320140883194975, 53.047802440766503],
            [-1.32105563620754, 53.049015819737754],
            [-1.322606809183544, 53.04922242203525],
            [-1.325785880550462, 53.051700833293602],
            [-1.329609942199092, 53.054460560067007],
            [-1.331046826620984, 53.054065925838245],
            [-1.332628946769578, 53.05305632497263],
            [-1.333487137070981, 53.053130350949857],
            [-1.335195982787867, 53.052605047328306],
            [-1.335871027702202, 53.052946811643935],
            [-1.336387938283316, 53.052997337759066],
            [-1.336165141920463, 53.053321506234589],
            [-1.336574432657099, 53.053677065735847],
            [-1.336048716590577, 53.054393274270872],
            [-1.337397202423806, 53.054700130603543],
            [-1.337516600539717, 53.055767818747313],
            [-1.336967549162959, 53.056156692719611],
            [-1.337744131388436, 53.056303046056662],
            [-1.33760374023529, 53.056604303482928],
            [-1.338088164171967, 53.056635763866943],
            [-1.3394431777583, 53.058570584642865],
            [-1.340117827629299, 53.058946481134782],
            [-1.340238222470077, 53.059660894069751],
            [-1.341137120600568, 53.060108143414013],
            [-1.340945057662805, 53.060765092317418],
            [-1.341928050328094, 53.060493662242813],
            [-1.343514607267903, 53.062136665520818],
            [-1.344114788903341, 53.06231256724066],
            [-1.344592804793904, 53.063262665092275],
            [-1.344430160433345, 53.065523459542128],
            [-1.343684633926542, 53.065874426035698],
            [-1.34420618591652, 53.066514636530151],
            [-1.343456751194488, 53.066826926381616],
            [-1.3440661495499, 53.06777595221984],
            [-1.34383704564409, 53.06871046782102],
            [-1.342633328323451, 53.070234691581533],
            [-1.343036910867488, 53.070979429051341],
            [-1.342194176571701, 53.070944212120438],
            [-1.339442455977538, 53.072084090434082],
            [-1.338535093867906, 53.072174338115332],
            [-1.339067793885656, 53.072573721320197],
            [-1.338342278774654, 53.07267935972741],
            [-1.337591112168367, 53.073192062642896],
            [-1.337586151532943, 53.073612730492243],
            [-1.337045071834751, 53.073564772533999],
            [-1.337032698548703, 53.073981803381024],
            [-1.336669330360467, 53.07383055828808],
            [-1.336556611514212, 53.074366586531404],
            [-1.335759446652022, 53.074567095655198],
            [-1.335885546334321, 53.075199741153405],
            [-1.336591851039761, 53.075667522623021],
            [-1.335642596791364, 53.076243831537759],
            [-1.335813944997684, 53.077137416638479],
            [-1.334719343977236, 53.077552899192469],
            [-1.334089104346213, 53.078927422188123],
            [-1.333129490207807, 53.079297800091204],
            [-1.332503454393026, 53.079912749668289],
            [-1.332721148315262, 53.080315787715051],
            [-1.331637690197194, 53.080968619589441],
            [-1.331538108553206, 53.081325831104223],
            [-1.329681226073687, 53.082367128754022],
            [-1.326140667384053, 53.083857329892886],
            [-1.322308514452223, 53.084885519069225],
            [-1.30777538972467, 53.086299514583963],
            [-1.30588579705308, 53.086818877414053],
            [-1.30323788992262, 53.088064598309224],
            [-1.302563183277723, 53.088515502338957],
            [-1.303457293495456, 53.089457412306523],
            [-1.304108697958877, 53.089433354674703],
            [-1.30376740458038, 53.089671371458017],
            [-1.305361087858055, 53.09075489029],
            [-1.306104164573863, 53.091902650321003],
            [-1.30739189589794, 53.092426128189821],
            [-1.307395682280755, 53.092841451519284],
            [-1.309133011689658, 53.0944794890667],
            [-1.308155144360666, 53.094928665096639],
            [-1.306913786590166, 53.094766831812983],
            [-1.307088810131172, 53.095398892427816],
            [-1.308493236209244, 53.095823256658221],
            [-1.309253447857711, 53.09675085977014],
            [-1.30739719862846, 53.099444927010701],
            [-1.305994171136498, 53.100319497338532],
            [-1.30552832795049, 53.100125311620502],
            [-1.304982553994809, 53.100535630435147],
            [-1.304026126008159, 53.100385318338013],
            [-1.302155973676682, 53.101046765328313],
            [-1.301580300630077, 53.100994848701212],
            [-1.300700335750338, 53.101655783838204],
            [-1.301421695393244, 53.104148226544005],
            [-1.302520344259519, 53.103913756053544],
            [-1.303610679442713, 53.10317583209951],
            [-1.308803162745781, 53.107704272412086],
            [-1.311834819865795, 53.108686368515912],
            [-1.312056370667387, 53.109125421544178],
            [-1.310664264393107, 53.11023742344311],
            [-1.30969090821663, 53.111689830005027],
            [-1.309675252340133, 53.113134299664857],
            [-1.312294479110958, 53.1163010557867],
            [-1.311058769150132, 53.116868322036289],
            [-1.310996137155775, 53.118450053002604],
            [-1.314249493313064, 53.124895178452029],
            [-1.314936889123954, 53.125366569911741],
            [-1.314805203841743, 53.125659757752132],
            [-1.317858213185265, 53.126986101246494],
            [-1.318714032502159, 53.128152397109211],
            [-1.320158467688797, 53.128388974124107],
            [-1.321782079982974, 53.130631132726819],
            [-1.320182909420239, 53.132144777420564],
            [-1.319284965279023, 53.133921296858368],
            [-1.317694637359762, 53.135052021140467],
            [-1.31498183300237, 53.135795135386878],
            [-1.315140532110114, 53.136061227402664],
            [-1.318816942822886, 53.139699533270125],
            [-1.320261260191129, 53.142525874195741],
            [-1.321672265178063, 53.144251737834495],
            [-1.322629222388389, 53.152513684067721],
            [-1.323422380057013, 53.154281853351137],
            [-1.324949884025069, 53.155286510063341],
            [-1.325618170618048, 53.15758880743364],
            [-1.323458243440095, 53.159517309442002],
            [-1.323448532825355, 53.16232364977288],
            [-1.3190826929351, 53.163170723758533],
            [-1.316738350118048, 53.165278730349343],
            [-1.315506506299815, 53.16523570431459],
            [-1.315214047494714, 53.164720746770357],
            [-1.313923230718427, 53.164437356570801],
            [-1.314137144029227, 53.164154532610489],
            [-1.313598107877628, 53.164190081901943],
            [-1.312897249573944, 53.16358736885045],
            [-1.311667053494358, 53.163351046870382],
            [-1.309304001120004, 53.163153096458188],
            [-1.308064943343878, 53.163376927715802],
            [-1.304506059787494, 53.165447972135276],
            [-1.302841406426446, 53.163960436489262],
            [-1.30203916422798, 53.163808318855196],
            [-1.300713624103958, 53.164196067734437],
            [-1.300837280622218, 53.165791459843504],
            [-1.300264477170472, 53.168085711115388],
            [-1.298923097578802, 53.167783883406265],
            [-1.297522084718968, 53.168845335146969],
            [-1.296098935663745, 53.171164214050741],
            [-1.288759569850054, 53.171437051638854],
            [-1.287911925590847, 53.170587913117075],
            [-1.285399326977944, 53.169648792637666],
            [-1.284699489549888, 53.168733098736951],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000171",
        LAD13CDO: "37UC",
        LAD13NM: "Bassetlaw",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-0.771666838498244, 53.257816409718551],
              [-0.772085817020266, 53.255853853214028],
              [-0.772557115244618, 53.258503374688125],
              [-0.771666838498244, 53.257816409718551],
            ],
          ],
          [
            [
              [-0.920456768228896, 53.464462359873821],
              [-0.916246254669013, 53.460274962853809],
              [-0.914814257440279, 53.460850743195671],
              [-0.91264228542538, 53.460439083300052],
              [-0.910039883704135, 53.461015837372699],
              [-0.900609178642532, 53.461028331087803],
              [-0.896586827807888, 53.461871278052492],
              [-0.889985571643516, 53.461790370536946],
              [-0.886348445873425, 53.462118787457257],
              [-0.873732997415024, 53.461457628153973],
              [-0.871916429187322, 53.466290041316391],
              [-0.818442713395288, 53.45953764700289],
              [-0.813538990493602, 53.458443637146068],
              [-0.805436863509035, 53.457178164275597],
              [-0.797951514619774, 53.455314281549384],
              [-0.80137855582281, 53.453091557464759],
              [-0.805148222553447, 53.448090951004239],
              [-0.809862274072268, 53.4437989846055],
              [-0.812073595034666, 53.44316658820226],
              [-0.812707219225208, 53.44371492605562],
              [-0.812580687021172, 53.443123088033914],
              [-0.818744884932596, 53.442189103226895],
              [-0.820291350380577, 53.441527507681378],
              [-0.821105925872432, 53.440273485932259],
              [-0.820014819734862, 53.437053623968289],
              [-0.816634263371986, 53.432049237778472],
              [-0.818524531355193, 53.428041745247441],
              [-0.818297843764158, 53.425958533727965],
              [-0.814166243122142, 53.423323344211582],
              [-0.809289594779997, 53.419581218531462],
              [-0.804125045869093, 53.416960541525796],
              [-0.799465771598656, 53.415600494638085],
              [-0.796718748677705, 53.414466293531234],
              [-0.794531817917814, 53.414227604373345],
              [-0.794890566241131, 53.414204257192885],
              [-0.794671093013447, 53.413984506156922],
              [-0.790795583700089, 53.414771433348129],
              [-0.789053355975331, 53.414821201040375],
              [-0.788893426341614, 53.414254164215684],
              [-0.791309914206518, 53.412603970545995],
              [-0.792037239362961, 53.411519151194291],
              [-0.792461227954677, 53.411528832020537],
              [-0.792036495158876, 53.411386104754079],
              [-0.79173812093642, 53.40927963315719],
              [-0.790772760517336, 53.407247310834961],
              [-0.788077450155082, 53.403420286348982],
              [-0.78676351380648, 53.402284202823886],
              [-0.784395723382116, 53.400762534844901],
              [-0.77945889225112, 53.398725580128421],
              [-0.777648502527499, 53.396872377922115],
              [-0.777046258923747, 53.391386422874312],
              [-0.778215786645611, 53.389614945659829],
              [-0.78239710051707, 53.387629701165928],
              [-0.785220241193187, 53.386967189389843],
              [-0.789229122131222, 53.386852394052369],
              [-0.790738859888479, 53.386105412940402],
              [-0.79040278314695, 53.385124887775142],
              [-0.787016958769407, 53.383955209544993],
              [-0.783991220221745, 53.383240358020061],
              [-0.784272204627831, 53.382929497458555],
              [-0.785025430739537, 53.383141218357004],
              [-0.785496488169315, 53.38296622743249],
              [-0.783406528496143, 53.382126936752265],
              [-0.782993730704846, 53.382315098117331],
              [-0.784088004297495, 53.382851213909532],
              [-0.783902998580639, 53.383065069727984],
              [-0.782244426177504, 53.382388360861022],
              [-0.775855058946259, 53.377816750753617],
              [-0.776770580664565, 53.377973556596103],
              [-0.782404750870015, 53.382038519451463],
              [-0.783087139781084, 53.382044577332735],
              [-0.779480010447739, 53.37932359239533],
              [-0.779530611197947, 53.377606278204233],
              [-0.778322681275086, 53.377686512927184],
              [-0.777956187128698, 53.378148401695114],
              [-0.77615702863593, 53.377666131483629],
              [-0.77992874576884, 53.377141112324225],
              [-0.783211836912086, 53.376246035035038],
              [-0.78653282354764, 53.374647400783985],
              [-0.789018969694462, 53.371100345706729],
              [-0.791225967844983, 53.368905081858209],
              [-0.790608183117848, 53.365829019795846],
              [-0.791501807117993, 53.363512567507762],
              [-0.790085995124737, 53.362617285889357],
              [-0.786779501420912, 53.36236798698571],
              [-0.782970097446387, 53.361205561290284],
              [-0.780915766238291, 53.360894248254901],
              [-0.779056688229854, 53.361375946626126],
              [-0.778069889905484, 53.362147011220827],
              [-0.777467272479319, 53.362200169965313],
              [-0.777895333137655, 53.362365459723968],
              [-0.776781970199074, 53.3636188369371],
              [-0.774408918049957, 53.364095195795912],
              [-0.77339708258206, 53.363430389451096],
              [-0.766348581289594, 53.35595244119299],
              [-0.763945418973493, 53.354987307406532],
              [-0.760359278269506, 53.35440716860068],
              [-0.75914804418458, 53.353639487921917],
              [-0.759475509565191, 53.351934038667572],
              [-0.762399392276707, 53.350836242474188],
              [-0.763685140610123, 53.349754678672802],
              [-0.764735293128064, 53.348142096177675],
              [-0.765211982282958, 53.345889831806552],
              [-0.763854312967529, 53.344321538678649],
              [-0.760343155371883, 53.342844145279308],
              [-0.757808149652997, 53.341277030395091],
              [-0.757223479590324, 53.340567085191388],
              [-0.75721517418866, 53.339664477744002],
              [-0.760736920774147, 53.336515308110407],
              [-0.761368097901452, 53.333857445327446],
              [-0.761965853456642, 53.333129225714373],
              [-0.765797702074756, 53.331795358585879],
              [-0.766769985429276, 53.330937947059013],
              [-0.766953133942903, 53.330054397796772],
              [-0.766013076758812, 53.328911135056913],
              [-0.76315448675687, 53.327235606396655],
              [-0.760769416096165, 53.324304630440757],
              [-0.757147068623011, 53.324026942700016],
              [-0.753051342909928, 53.324795944546651],
              [-0.750468324066257, 53.324146865898648],
              [-0.750262173361015, 53.323597259653283],
              [-0.750687827710827, 53.323005727482489],
              [-0.753175836517896, 53.321658188522505],
              [-0.754362749329718, 53.320184656444759],
              [-0.754828112353714, 53.314666495287909],
              [-0.754095596445494, 53.31023251921814],
              [-0.753130939033977, 53.308775164176133],
              [-0.748619140640889, 53.305496269397459],
              [-0.747870982341578, 53.30444565919408],
              [-0.749332361985863, 53.301837007643293],
              [-0.750282128473436, 53.296341003198862],
              [-0.750930819649655, 53.296272287005323],
              [-0.750340764856789, 53.296131267324178],
              [-0.750783214453925, 53.294542993383722],
              [-0.751491541926732, 53.293714401893261],
              [-0.752560305917565, 53.293232968264917],
              [-0.756368544499058, 53.292805287461199],
              [-0.757673304686391, 53.293081371419916],
              [-0.758354168027376, 53.294022450658026],
              [-0.757888667058393, 53.296104923854692],
              [-0.758898617456129, 53.29858390100059],
              [-0.760079287186184, 53.299628151263313],
              [-0.76194605091696, 53.300040372022679],
              [-0.763737276821392, 53.299899837323949],
              [-0.764669985298429, 53.299505879637607],
              [-0.765729392527499, 53.298359921963694],
              [-0.765076075247068, 53.295779521952639],
              [-0.765585815688414, 53.294023792024674],
              [-0.768971477118169, 53.291122870754656],
              [-0.770814911895145, 53.289672128525297],
              [-0.774057053266196, 53.288039774808261],
              [-0.778030353352013, 53.28511497191635],
              [-0.780242122036555, 53.281612900986985],
              [-0.781043558121609, 53.281595924169835],
              [-0.780115444570821, 53.281531600534997],
              [-0.77899978940313, 53.283469068271216],
              [-0.778303571832112, 53.28184655920122],
              [-0.775780732833696, 53.28000844451315],
              [-0.773802935392155, 53.277422574132771],
              [-0.770250450968474, 53.275525206149986],
              [-0.766042524439776, 53.274163007714328],
              [-0.764909436607719, 53.272787595706212],
              [-0.765447472189611, 53.27123711117612],
              [-0.773107487831312, 53.262268385990595],
              [-0.773522019941312, 53.261299103560376],
              [-0.774304441720359, 53.261094999648371],
              [-0.773517727031823, 53.260925102395767],
              [-0.773645301486677, 53.260238729040751],
              [-0.774407243119078, 53.259911259762006],
              [-0.773598442205942, 53.259990140988123],
              [-0.772932041338137, 53.255459841132357],
              [-0.770790292439077, 53.253304605747402],
              [-0.771664854743067, 53.252757176665874],
              [-0.776499713966319, 53.252988470693893],
              [-0.777879319711934, 53.252809347703483],
              [-0.778788991453307, 53.252341332971113],
              [-0.779018190416986, 53.251187648549049],
              [-0.777238522941076, 53.248367426453434],
              [-0.779254126451675, 53.243740577085717],
              [-0.778991944010273, 53.241789895280085],
              [-0.782064519852514, 53.241398822510313],
              [-0.783198194391007, 53.240789232741868],
              [-0.781761891536675, 53.241343592238344],
              [-0.779747768738589, 53.241506377841077],
              [-0.779598104162988, 53.241289100948691],
              [-0.77895508738708, 53.241610629266361],
              [-0.77780511639556, 53.238896639245347],
              [-0.778668203379346, 53.237001533488332],
              [-0.77878369483175, 53.235049323755575],
              [-0.782127360969762, 53.231054486935506],
              [-0.783543109957815, 53.228041316357839],
              [-0.783979119530724, 53.224336741748814],
              [-0.784334996290153, 53.224200137087024],
              [-0.783901111413539, 53.224182227283777],
              [-0.778835916742549, 53.219944079490709],
              [-0.777784488997846, 53.2182603741444],
              [-0.777699525463044, 53.216351949935813],
              [-0.776109487972866, 53.214759789219414],
              [-0.776338121257643, 53.214356714040854],
              [-0.779060886450314, 53.212927452188964],
              [-0.778658236638662, 53.210972623201982],
              [-0.777434926961824, 53.208864646930522],
              [-0.776005262043425, 53.207854843565833],
              [-0.775940983885981, 53.207325605130812],
              [-0.776552060419666, 53.206741274369882],
              [-0.779773918302412, 53.205276666754145],
              [-0.780928991580329, 53.203774668192729],
              [-0.780522002442017, 53.202447260447258],
              [-0.778211384092449, 53.200728187101895],
              [-0.777563700776081, 53.199761473387255],
              [-0.778037120697138, 53.199012114985315],
              [-0.779598598384343, 53.19800781500831],
              [-0.780116638328936, 53.197055744029718],
              [-0.781400609878506, 53.196648175053731],
              [-0.790145093498443, 53.199223750062906],
              [-0.788931652564975, 53.201643963054124],
              [-0.788049532698586, 53.202239084776814],
              [-0.786994809635333, 53.202424323517135],
              [-0.787398545388578, 53.20307836746364],
              [-0.78571490207855, 53.20432603647788],
              [-0.790584350288937, 53.204839405171704],
              [-0.798859087602958, 53.204640822021297],
              [-0.803262143737493, 53.205510344259515],
              [-0.805850242562558, 53.205479671210696],
              [-0.815879173351326, 53.204868675284303],
              [-0.817377001065192, 53.204085289641512],
              [-0.817613734380923, 53.203317247518875],
              [-0.818548597026427, 53.203076613682782],
              [-0.819865811053021, 53.201615403935897],
              [-0.820679672783393, 53.201636045554004],
              [-0.820711480491071, 53.201404433946998],
              [-0.821572876936201, 53.20132935333698],
              [-0.823147496172347, 53.202215985049712],
              [-0.824921291729829, 53.202719813757376],
              [-0.82476687277114, 53.203384404742152],
              [-0.826548765843883, 53.205841681749114],
              [-0.827477729364887, 53.206311080955473],
              [-0.832064977076313, 53.20671120275658],
              [-0.830521448152653, 53.212809739415711],
              [-0.836956268310377, 53.213636799064027],
              [-0.836507172206675, 53.215510293452581],
              [-0.835510617330491, 53.216197236919591],
              [-0.831484326235496, 53.215672407789391],
              [-0.830799849498027, 53.217545371295962],
              [-0.83982114474103, 53.218899220908838],
              [-0.848215251403759, 53.218640829484869],
              [-0.848499862563301, 53.215287856292854],
              [-0.852633377377695, 53.21544907345271],
              [-0.85309222739588, 53.214754118423443],
              [-0.854426384599832, 53.214648289165368],
              [-0.857840420262577, 53.212185610985529],
              [-0.862076355717314, 53.213783979684216],
              [-0.862780966491057, 53.213132684853527],
              [-0.865437306843582, 53.214275374013305],
              [-0.867298742210565, 53.216376805003691],
              [-0.86942918862446, 53.216686487570506],
              [-0.87045248516928, 53.217069246118797],
              [-0.87112747589803, 53.216972263352432],
              [-0.871130234833935, 53.217437037637758],
              [-0.872377086461704, 53.217805715661029],
              [-0.872598657039221, 53.218151203686361],
              [-0.875460964391616, 53.218542308931617],
              [-0.877492350323983, 53.219783104161692],
              [-0.877668932904921, 53.220361880993032],
              [-0.878713128666278, 53.220351931425832],
              [-0.879343390241357, 53.221051833413867],
              [-0.880198652583802, 53.22093581837332],
              [-0.883712328400039, 53.222322564236386],
              [-0.886928305900954, 53.222721198231625],
              [-0.88884996225469, 53.223476248848186],
              [-0.89424976316164, 53.223650511582179],
              [-0.896303069743993, 53.223194905361872],
              [-0.898305335249153, 53.223325793101239],
              [-0.899276639873061, 53.22236392213032],
              [-0.899974642779674, 53.222246313073263],
              [-0.900014170958105, 53.221931153611564],
              [-0.901704866824776, 53.221124222474089],
              [-0.907270473596117, 53.220482297342542],
              [-0.91655012357638, 53.218303500037216],
              [-0.920014960904472, 53.218334942393767],
              [-0.92168414960047, 53.218661981864109],
              [-0.929975828574088, 53.216419265578942],
              [-0.934895687032456, 53.216169379007383],
              [-0.935804671935744, 53.215748709413987],
              [-0.936547641193806, 53.215923439630316],
              [-0.939852170049228, 53.215464758180914],
              [-0.944654486485156, 53.214628222508793],
              [-0.947251119700773, 53.213838554342722],
              [-0.949293287477339, 53.214285361144945],
              [-0.949714983114815, 53.217984562947798],
              [-0.948265790484504, 53.219185335395409],
              [-0.948660270912048, 53.22081317390716],
              [-0.949657509487927, 53.221069169383078],
              [-0.94829870233617, 53.222733684932493],
              [-0.949149109943477, 53.224278346442489],
              [-0.947219877397766, 53.225742752952563],
              [-0.950236195491141, 53.22613161894423],
              [-0.952935221205047, 53.22706416794928],
              [-0.957538593061378, 53.231872401222056],
              [-0.961289444753329, 53.233255321618088],
              [-0.964642890600501, 53.233856205569431],
              [-0.967904586112357, 53.234911953950274],
              [-0.967544339097736, 53.235313349713728],
              [-0.969980770770006, 53.235739837264468],
              [-0.983929219369854, 53.236584953475045],
              [-0.996580595217747, 53.236727156062791],
              [-1.00659737544164, 53.236119767130305],
              [-1.010133480877589, 53.239491355088333],
              [-1.01179988149007, 53.240459828485712],
              [-1.012548583478932, 53.241654400275891],
              [-1.012923918074126, 53.241623349162076],
              [-1.016897761807021, 53.243917854878255],
              [-1.017254863715091, 53.24363674088751],
              [-1.019317594049432, 53.245375148868412],
              [-1.023550314396374, 53.247783023198785],
              [-1.02317867565034, 53.24791301891549],
              [-1.025906780521681, 53.252116190307234],
              [-1.027317588516232, 53.258047057128579],
              [-1.027536257560872, 53.260831877195514],
              [-1.027956343282769, 53.261015983486253],
              [-1.031225797096899, 53.259764331948631],
              [-1.046007306954855, 53.253485028864276],
              [-1.052147116665263, 53.252180225192859],
              [-1.058438224591755, 53.249086565664754],
              [-1.06622022879226, 53.244722410176855],
              [-1.0662611409837, 53.244288555559514],
              [-1.068919073776811, 53.242655339300093],
              [-1.072484805615141, 53.240886201772881],
              [-1.086311832931228, 53.237678665840583],
              [-1.09295178959596, 53.237213386761788],
              [-1.103297153524001, 53.235588199994297],
              [-1.104985304187781, 53.235103785758618],
              [-1.108989751840231, 53.23252243948204],
              [-1.111460249451131, 53.230421242073142],
              [-1.116447054227925, 53.231818354840932],
              [-1.118360987314775, 53.232069835282722],
              [-1.119728889402857, 53.232064660820733],
              [-1.119691851788579, 53.231021650385117],
              [-1.122083593768125, 53.230837030471641],
              [-1.123068669447767, 53.226689511441911],
              [-1.122107878155686, 53.22554711496209],
              [-1.123674952221444, 53.224653439558537],
              [-1.132598814946546, 53.22571469325505],
              [-1.139789470390063, 53.225755105775086],
              [-1.146603130994315, 53.224942916249326],
              [-1.149944481962628, 53.223980682283383],
              [-1.152189589557036, 53.223685645905967],
              [-1.156897949141183, 53.222278981248522],
              [-1.16175501592808, 53.221524886580319],
              [-1.169488128874683, 53.219192403095988],
              [-1.171122484159236, 53.218939497989219],
              [-1.17577060453278, 53.219207238480152],
              [-1.17913466501472, 53.219114489672108],
              [-1.181559055855932, 53.218661028574616],
              [-1.188267885646059, 53.218697008631274],
              [-1.188571960715094, 53.218377269348629],
              [-1.196790121375176, 53.217145684542224],
              [-1.196935092948865, 53.21676102951109],
              [-1.20223839196082, 53.216098184094186],
              [-1.202718042297821, 53.216639838764166],
              [-1.206609336982674, 53.216609167832679],
              [-1.208095965322392, 53.216959738142421],
              [-1.209426092587141, 53.217677804172766],
              [-1.208993914896884, 53.224392469374266],
              [-1.208305337789593, 53.224856229306489],
              [-1.207955923673345, 53.225771692046138],
              [-1.207483113173779, 53.225824282371306],
              [-1.207027892080465, 53.226952079749402],
              [-1.206208822822705, 53.227178547271315],
              [-1.20576342650657, 53.230525803867444],
              [-1.203258839003836, 53.231080798190433],
              [-1.203484867500953, 53.233028439133356],
              [-1.201998531581988, 53.235535436198901],
              [-1.205092258399007, 53.236595234716454],
              [-1.205786212356089, 53.237796301115317],
              [-1.205188672289936, 53.238670549563125],
              [-1.207158058670938, 53.239208620291727],
              [-1.208731962630099, 53.240882047297575],
              [-1.209974119183554, 53.240965791151353],
              [-1.212461013510652, 53.245565749933789],
              [-1.213028175901287, 53.247652252177787],
              [-1.209394955229133, 53.250279085396208],
              [-1.209045233112185, 53.250794534347264],
              [-1.210350458172549, 53.251213083273981],
              [-1.207837677027186, 53.253204567007835],
              [-1.207055114218784, 53.255158973225164],
              [-1.205832283191513, 53.256195360951196],
              [-1.20580599386997, 53.256724639114324],
              [-1.204505759302849, 53.257161829113087],
              [-1.202691603256047, 53.257262077864141],
              [-1.203755054058481, 53.258922262766731],
              [-1.202618782752569, 53.261098103470452],
              [-1.201115185782491, 53.261739745355491],
              [-1.198194787849081, 53.262302636977189],
              [-1.196089030712684, 53.26339051583755],
              [-1.193879183400031, 53.263230877738515],
              [-1.191354079192164, 53.264127967922533],
              [-1.187748178654285, 53.264534939348955],
              [-1.185343649192905, 53.264362131501358],
              [-1.182981100908481, 53.264977000931502],
              [-1.181772876333994, 53.264574100570812],
              [-1.180595461472071, 53.26467838043142],
              [-1.179181019921916, 53.264319880839189],
              [-1.178107868285827, 53.264459910803531],
              [-1.177286094964945, 53.265172468365485],
              [-1.174798824943203, 53.265092362976546],
              [-1.174628237130129, 53.265776146370456],
              [-1.175553969880134, 53.266521446475679],
              [-1.180206288421986, 53.268408846252939],
              [-1.181562495680906, 53.269468975545379],
              [-1.181543152869738, 53.270010880396818],
              [-1.178247835324026, 53.273192807221797],
              [-1.17733917636898, 53.273657568236104],
              [-1.173300241237374, 53.27407550010274],
              [-1.170719974032244, 53.276140344275248],
              [-1.168335749857642, 53.276668479728613],
              [-1.166488843091692, 53.277634492574862],
              [-1.16927876325755, 53.278540270266234],
              [-1.171017841973476, 53.27981352591474],
              [-1.172079661352129, 53.28004832175251],
              [-1.173991923608479, 53.281214867029149],
              [-1.175764275828633, 53.281628934125614],
              [-1.176004631957117, 53.282701184396899],
              [-1.176967381261696, 53.28349167090748],
              [-1.17793744431728, 53.283746455696487],
              [-1.177532920877616, 53.284269523766675],
              [-1.17830519387594, 53.286235344928755],
              [-1.180118414944024, 53.287113459564225],
              [-1.178700467058288, 53.2888556605742],
              [-1.176397925905255, 53.289629020739902],
              [-1.171564018257854, 53.294616801299],
              [-1.173819028220969, 53.296170452286994],
              [-1.173779022849295, 53.297466386064293],
              [-1.174326277364274, 53.298343004069089],
              [-1.174853474606499, 53.299093635011175],
              [-1.174176858625825, 53.302511954831871],
              [-1.175721180001073, 53.303280403151724],
              [-1.176676973577551, 53.305158504723586],
              [-1.178993629751406, 53.305883695953767],
              [-1.179428237536794, 53.306384673900951],
              [-1.180477013330516, 53.305848049244766],
              [-1.180957474855742, 53.30622798551402],
              [-1.182142139208122, 53.305332720486469],
              [-1.185552971791512, 53.304635135028377],
              [-1.187221061147665, 53.303957954542284],
              [-1.190633985213601, 53.306552893193285],
              [-1.193675588587744, 53.308246328496736],
              [-1.194960138524439, 53.30857231129248],
              [-1.198287420924235, 53.310815879928342],
              [-1.199744007805193, 53.311418926888443],
              [-1.197205374635534, 53.314652276581768],
              [-1.194373844074288, 53.314844435195624],
              [-1.192416735780735, 53.318968815051889],
              [-1.191524079902394, 53.318940298870658],
              [-1.191535649213715, 53.31920195524728],
              [-1.190113362699748, 53.319475463292449],
              [-1.190324293459456, 53.319911060058907],
              [-1.186610743882352, 53.319572993179257],
              [-1.180416036686342, 53.321025472918784],
              [-1.174482485412902, 53.321370203244378],
              [-1.174238754294878, 53.321786502901119],
              [-1.174704439724517, 53.322627493391991],
              [-1.172037401607416, 53.322742955959313],
              [-1.174052689250023, 53.32357041896099],
              [-1.175309582032828, 53.324490588956316],
              [-1.175111435012278, 53.325656882448548],
              [-1.173880543437657, 53.325721179207108],
              [-1.173458176840726, 53.326367256105691],
              [-1.172480611349441, 53.326529474182514],
              [-1.172189625734087, 53.326902294912728],
              [-1.171478153050983, 53.326503642696544],
              [-1.171075593104848, 53.32721007457485],
              [-1.169777829444825, 53.327390720860507],
              [-1.168904369172233, 53.328220612902498],
              [-1.165872374257758, 53.329092063080559],
              [-1.163882631249576, 53.329257020693184],
              [-1.160867521797955, 53.331095672028695],
              [-1.160511829547112, 53.332310272751265],
              [-1.159384959579287, 53.333488881163412],
              [-1.148620520325343, 53.338486839857723],
              [-1.14541619200844, 53.340709377528022],
              [-1.14090861877592, 53.340946689562394],
              [-1.138717900970279, 53.341363270657268],
              [-1.139359474826445, 53.344709084612028],
              [-1.140286126064645, 53.346508154317085],
              [-1.141551518956119, 53.346777939597146],
              [-1.144906837362977, 53.345675708863823],
              [-1.148179075113689, 53.345191229330169],
              [-1.149565060157704, 53.345289202788223],
              [-1.152670249147839, 53.344664980727622],
              [-1.156843401308462, 53.344949814757172],
              [-1.156876845628558, 53.345843549433091],
              [-1.158148951799774, 53.347683569336624],
              [-1.160102978407689, 53.348922521828818],
              [-1.159867963282391, 53.349950098344721],
              [-1.161820907235498, 53.350869009072824],
              [-1.162419702637277, 53.351590526400344],
              [-1.161954029887172, 53.354193141519119],
              [-1.161616897506711, 53.355678437575627],
              [-1.162622736115986, 53.356716523331642],
              [-1.161949153501065, 53.357889342535266],
              [-1.15169317421734, 53.354465846822755],
              [-1.149101154654356, 53.354332342730949],
              [-1.14527935936487, 53.354840781436394],
              [-1.144951412198925, 53.355312148747458],
              [-1.13853936557111, 53.355888086023107],
              [-1.139236533987522, 53.359840145558174],
              [-1.138975805137026, 53.361369975363054],
              [-1.140115544782043, 53.362603377042227],
              [-1.140553980104041, 53.36404026378829],
              [-1.145177983122859, 53.363363335850281],
              [-1.146416872346609, 53.363933106526737],
              [-1.146439897018408, 53.365713971471706],
              [-1.145875516388359, 53.365830386696743],
              [-1.145661965544176, 53.366589319359129],
              [-1.144224847735292, 53.367359263831808],
              [-1.144214498352302, 53.368250887869884],
              [-1.146032631789209, 53.369021668515046],
              [-1.144915302934834, 53.371231231219689],
              [-1.142803228657282, 53.37124844766975],
              [-1.141403127199265, 53.37069006603739],
              [-1.140455612431087, 53.371244155874919],
              [-1.140739424769114, 53.372003961987161],
              [-1.138218718508689, 53.372232990689426],
              [-1.13413624538356, 53.373206617393848],
              [-1.134193849746187, 53.374146374235266],
              [-1.133659554033751, 53.374847228013131],
              [-1.131957339106411, 53.374879810470603],
              [-1.13190067726884, 53.37559671202979],
              [-1.130536719386478, 53.375551731792953],
              [-1.129995533612562, 53.377546020285386],
              [-1.130994191623924, 53.377964084149212],
              [-1.13049926536421, 53.378867460101631],
              [-1.133046456135957, 53.381575453504617],
              [-1.132444731522303, 53.383961226547669],
              [-1.130946762795275, 53.385537770649307],
              [-1.130934453444693, 53.388210073327706],
              [-1.131310416610225, 53.389536869281031],
              [-1.134692306420638, 53.389751085383288],
              [-1.133492249982387, 53.391461055825957],
              [-1.133277790000928, 53.391358824367551],
              [-1.132899846149881, 53.392272046377677],
              [-1.133207255079339, 53.392388436407401],
              [-1.131511492364187, 53.393916808535387],
              [-1.129207081428401, 53.393576430179827],
              [-1.122810714311844, 53.39829282297729],
              [-1.119469887797932, 53.398026439939024],
              [-1.115578935082004, 53.397166221127016],
              [-1.115918103925852, 53.397999303612238],
              [-1.115140578646333, 53.401841679232447],
              [-1.115956387222502, 53.40732912583745],
              [-1.111632685745604, 53.406047596825452],
              [-1.108226447934016, 53.405862213449886],
              [-1.105040789848494, 53.40964696795654],
              [-1.098736892138477, 53.418715170662892],
              [-1.092921649496864, 53.421526016697719],
              [-1.087045585828722, 53.425524441897743],
              [-1.084866113130414, 53.424838996925402],
              [-1.084146839087494, 53.423504932883858],
              [-1.083329201092454, 53.422968319665173],
              [-1.081199505565146, 53.422565438860666],
              [-1.079122519000878, 53.422789449618278],
              [-1.078979822106538, 53.423543413596995],
              [-1.08003455644193, 53.425155151861752],
              [-1.080438971602, 53.426848169457067],
              [-1.074669865389656, 53.427288125622503],
              [-1.073267465763287, 53.428037712783784],
              [-1.069357146869194, 53.427774505921739],
              [-1.067942669137258, 53.427350890806764],
              [-1.067856717229567, 53.427702583494977],
              [-1.057198800137231, 53.425363646626579],
              [-1.055766147765677, 53.42529390452146],
              [-1.044588548979175, 53.428068894969634],
              [-1.040263169398218, 53.429736720307289],
              [-1.03652512904651, 53.430509340980549],
              [-1.030948980917933, 53.430959530818434],
              [-1.030708167373908, 53.429900484781946],
              [-1.028839715380656, 53.429267775007133],
              [-1.030514644896499, 53.425175254508929],
              [-1.027824717371732, 53.425400589914595],
              [-1.027114600888122, 53.424995702358821],
              [-1.023168152935695, 53.424494278774077],
              [-1.022430102744682, 53.426294111011401],
              [-1.018174338229985, 53.426739218968699],
              [-1.01434585547577, 53.426275328099216],
              [-1.014295990334349, 53.427260100189358],
              [-1.012532189963906, 53.430699968418395],
              [-1.012813040011143, 53.432220513689622],
              [-1.012456990739458, 53.43277578154305],
              [-1.009891459179718, 53.432804886198817],
              [-1.008677324039433, 53.433880676974788],
              [-1.005094225616715, 53.433778982610043],
              [-1.002962197503883, 53.434705943111659],
              [-1.000375781952632, 53.434978268712612],
              [-0.99566848814796, 53.436906462754301],
              [-0.995917558620934, 53.437835311665218],
              [-0.998482058287122, 53.438824040419135],
              [-0.997130504904685, 53.442455902602021],
              [-0.997516780214951, 53.443634890800539],
              [-0.994820231026403, 53.447081948115979],
              [-0.994307142890975, 53.448915857945849],
              [-0.991176872545394, 53.451702993022145],
              [-0.993644408786845, 53.452938196220899],
              [-0.985545857168204, 53.461333649693493],
              [-0.983295492383538, 53.468021122224791],
              [-0.98597629170892, 53.471652923711886],
              [-0.981623242795326, 53.474697294743166],
              [-0.976867651229441, 53.475156463320261],
              [-0.953256108132435, 53.484402922279479],
              [-0.935561236081607, 53.502495865213191],
              [-0.900467646337624, 53.475135161291355],
              [-0.902172331843527, 53.47371080532676],
              [-0.912089349309453, 53.469932651610584],
              [-0.92024398760993, 53.465486972506568],
              [-0.920456768228896, 53.464462359873821],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000172",
        LAD13CDO: "37UD",
        LAD13NM: "Broxtowe",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.329609942199092, 53.054460560067007],
            [-1.325785880550462, 53.051700833293602],
            [-1.322606809183544, 53.04922242203525],
            [-1.32105563620754, 53.049015819737754],
            [-1.320140883194975, 53.047802440766503],
            [-1.31900301065651, 53.047309616172413],
            [-1.317451664407454, 53.047969531058392],
            [-1.314411881605651, 53.048229827993126],
            [-1.310444052971415, 53.046348823125378],
            [-1.31101602146416, 53.047991778848889],
            [-1.310689911780007, 53.048329685008397],
            [-1.308097801236477, 53.047831021333913],
            [-1.306822879227845, 53.047241996781032],
            [-1.305773927899301, 53.046145483722349],
            [-1.306956705117806, 53.04503590896423],
            [-1.307847123406927, 53.042991535269103],
            [-1.305140189853297, 53.044832946559531],
            [-1.303527841675459, 53.041865149336751],
            [-1.301106873291211, 53.043264072077065],
            [-1.300946352785517, 53.044151270418276],
            [-1.299105082764931, 53.046306857670373],
            [-1.298117568506789, 53.046492509611717],
            [-1.2952885180872, 53.046086555913568],
            [-1.288037944776115, 53.048236787833936],
            [-1.28482820132996, 53.048208554337691],
            [-1.281515392727631, 53.047740035969561],
            [-1.280501060696228, 53.047021057660722],
            [-1.278754922123007, 53.047072514659206],
            [-1.278167711781171, 53.046416332146528],
            [-1.277463916710403, 53.046221488466713],
            [-1.276762795111661, 53.045510671464285],
            [-1.277715261849848, 53.044480889254977],
            [-1.278695219273198, 53.044035570116812],
            [-1.275720535984215, 53.04326150396065],
            [-1.272830451913916, 53.042075271133804],
            [-1.270723471588681, 53.041866411440864],
            [-1.270331964586218, 53.040495839752133],
            [-1.269297465177049, 53.039402685332497],
            [-1.268592468057931, 53.039023500360358],
            [-1.267094483507283, 53.038883040255676],
            [-1.266395772468063, 53.038398705955977],
            [-1.263797513604234, 53.038413223964632],
            [-1.262904937314257, 53.039242805667094],
            [-1.258246572164106, 53.040410338313947],
            [-1.25769610655774, 53.040938174242712],
            [-1.253435644742381, 53.039774374755346],
            [-1.251257380557899, 53.038009570573379],
            [-1.247464526524523, 53.035711347312933],
            [-1.24711517409216, 53.035048422165588],
            [-1.238778562040313, 53.032611414125292],
            [-1.241262009050516, 53.031132349158412],
            [-1.235363521483459, 53.027899780393128],
            [-1.234819911275907, 53.02685352196216],
            [-1.2290666620943, 53.022725331101533],
            [-1.231915801658522, 53.02112387645419],
            [-1.2316255683199, 53.020090024457048],
            [-1.231969157266416, 53.019462089015974],
            [-1.230958683473214, 53.018441562206235],
            [-1.23112204178216, 53.016966565095146],
            [-1.230665851135342, 53.016214802199137],
            [-1.232548057750117, 53.012711218228205],
            [-1.232599048375696, 53.011348759008619],
            [-1.231491923963242, 53.010493015795738],
            [-1.231089123956049, 53.009507874754071],
            [-1.229330989595688, 53.008807912294387],
            [-1.228236712626812, 53.008910488718712],
            [-1.227746535167617, 53.008567509355501],
            [-1.22515015050907, 53.008076886344597],
            [-1.222880009422448, 53.007348321623333],
            [-1.22118139773846, 53.007410926201118],
            [-1.218703638361424, 53.006466075678375],
            [-1.220003868723431, 53.005352732950627],
            [-1.220599600995234, 53.003387063080517],
            [-1.216393256797082, 53.000397424098587],
            [-1.213940275455482, 52.997376982792311],
            [-1.217925039093124, 52.994047505559671],
            [-1.219109604396995, 52.991479823924159],
            [-1.212129835359989, 52.990340682454168],
            [-1.208420086079052, 52.988807627025842],
            [-1.203355166176059, 52.987509897645573],
            [-1.203598963505628, 52.987051271974835],
            [-1.203614717377953, 52.987004632467638],
            [-1.205297237129108, 52.987370968699842],
            [-1.205491361462674, 52.987101684068307],
            [-1.210528888299946, 52.986143695783497],
            [-1.213165935020104, 52.984724661646389],
            [-1.213877186939673, 52.983699177414842],
            [-1.215053892457025, 52.983124434935419],
            [-1.217938781144019, 52.983964173997855],
            [-1.222531542454141, 52.986007914299591],
            [-1.224094779240349, 52.98414203221072],
            [-1.22201253717662, 52.983305898191226],
            [-1.222766143610797, 52.982231196450776],
            [-1.223698322719179, 52.981661961974417],
            [-1.224864859800201, 52.982227813431514],
            [-1.226081566764678, 52.981154310229954],
            [-1.23505943214208, 52.982526644968999],
            [-1.23938297162851, 52.982634379809511],
            [-1.242132582211177, 52.983568852034047],
            [-1.245462996738419, 52.985200928025868],
            [-1.246840701325629, 52.984523768995857],
            [-1.246508141129689, 52.983090549812012],
            [-1.244889832784394, 52.981166441818743],
            [-1.243759252302756, 52.978783357411366],
            [-1.242610163379691, 52.977806986414812],
            [-1.240866842697459, 52.978024202419547],
            [-1.239855879552126, 52.977583556266005],
            [-1.239820114934862, 52.976481225278455],
            [-1.237116476983569, 52.971864025653893],
            [-1.236801546050377, 52.971482651164507],
            [-1.235077640562011, 52.971456293013361],
            [-1.231981246957369, 52.967623018957184],
            [-1.237826773299169, 52.966445257758394],
            [-1.239169143743524, 52.9653787178855],
            [-1.239544811252006, 52.963994051160611],
            [-1.240764642027919, 52.963031885830347],
            [-1.240997574819618, 52.962397819701771],
            [-1.241923789911794, 52.962131348236213],
            [-1.243292723174371, 52.960894131694033],
            [-1.242955182623597, 52.960785907789834],
            [-1.24335368855077, 52.959448118902408],
            [-1.245726034297337, 52.955888989512907],
            [-1.246825751355301, 52.953079567362984],
            [-1.24579457631477, 52.950920956483323],
            [-1.24507479724777, 52.950373425517597],
            [-1.244722315571434, 52.949503704632228],
            [-1.241844989408948, 52.947651542190393],
            [-1.237873656073869, 52.945738364066571],
            [-1.23566160740948, 52.94526390271286],
            [-1.234196830708593, 52.943515913461582],
            [-1.234285057802927, 52.942058389667181],
            [-1.233167856219478, 52.942291207590721],
            [-1.232431419327763, 52.942029356213538],
            [-1.231405115174554, 52.942254653904413],
            [-1.226901064180944, 52.9410631102702],
            [-1.226344522079897, 52.94239892173038],
            [-1.224263361524005, 52.94244379164698],
            [-1.222795701038898, 52.941386032711371],
            [-1.221034214569446, 52.940784793883083],
            [-1.21808873238195, 52.940573082346098],
            [-1.217129165500486, 52.93891180302753],
            [-1.216226195464949, 52.937169084108142],
            [-1.214719586983516, 52.936064221290017],
            [-1.215296039015686, 52.933971678138029],
            [-1.209351315669764, 52.933287740174713],
            [-1.208893872583848, 52.933978686020801],
            [-1.206053860551473, 52.932747067959191],
            [-1.205112464193141, 52.933377233324464],
            [-1.203109472808739, 52.932266204220049],
            [-1.200468960901262, 52.930233924671541],
            [-1.199000454566147, 52.931210187224721],
            [-1.196634498794255, 52.929629146804686],
            [-1.197423808190738, 52.929100501753155],
            [-1.19577799799764, 52.927841633386073],
            [-1.182305213769884, 52.917316464455055],
            [-1.187535930237285, 52.914767844480295],
            [-1.190957228507362, 52.913684571491032],
            [-1.193352183390164, 52.912445911184264],
            [-1.197568176758353, 52.90994299754724],
            [-1.199013526602526, 52.90945922200634],
            [-1.205713156479203, 52.912422190613334],
            [-1.208301363550732, 52.912628240262158],
            [-1.210509111520435, 52.912250084168988],
            [-1.212083268515667, 52.911465856335425],
            [-1.213360490725966, 52.910160047778618],
            [-1.213829186561592, 52.905625232278048],
            [-1.214427578063856, 52.904600786217628],
            [-1.213924298773596, 52.904395193622236],
            [-1.214848022019624, 52.903713600404728],
            [-1.220168588121259, 52.902338175488929],
            [-1.220802438110028, 52.900403289511651],
            [-1.219224304015988, 52.896889705464879],
            [-1.220497129670771, 52.895544235915978],
            [-1.222551536078493, 52.895290705340798],
            [-1.227751272547646, 52.895540360651069],
            [-1.230256252367659, 52.894687324844078],
            [-1.230819311072959, 52.894032936231426],
            [-1.233461365556395, 52.892900246442444],
            [-1.235741066198828, 52.892333307269844],
            [-1.239710810576362, 52.89226977702365],
            [-1.239627673528141, 52.894218177619585],
            [-1.240461988689905, 52.894704453768547],
            [-1.240297455834538, 52.895018035827839],
            [-1.241054310844495, 52.895344697633597],
            [-1.241758797063331, 52.895265592307815],
            [-1.243042928084075, 52.896385784876422],
            [-1.244647066694656, 52.896546117708922],
            [-1.244524743397913, 52.897083813135147],
            [-1.246218065229657, 52.897503590105494],
            [-1.246840232990502, 52.898487393834024],
            [-1.247428561467489, 52.898679901283408],
            [-1.246986514678019, 52.899310862751612],
            [-1.247517279495913, 52.899909331560018],
            [-1.24512161628911, 52.902173881618573],
            [-1.246620857858046, 52.903080550053097],
            [-1.24763440421049, 52.903190350618026],
            [-1.250803742030248, 52.902610772288149],
            [-1.251338754033245, 52.902019037667849],
            [-1.2534075972732, 52.902403320251494],
            [-1.254011965397187, 52.901926176288271],
            [-1.25647965920871, 52.901766345629056],
            [-1.259644095104914, 52.903468040534584],
            [-1.26525393168892, 52.905032871187366],
            [-1.267621497348528, 52.905264132263063],
            [-1.273108471244771, 52.905138705194183],
            [-1.274984224539959, 52.905442322101884],
            [-1.280172697043114, 52.908705586143284],
            [-1.281806181866009, 52.910274246684523],
            [-1.282058078587519, 52.911137860866944],
            [-1.28353981212634, 52.916776917536758],
            [-1.283528816393661, 52.918615200043071],
            [-1.282826420554161, 52.91926989284466],
            [-1.282524337716904, 52.921439927459602],
            [-1.283687165464192, 52.922629955544629],
            [-1.285563781828234, 52.925100768813017],
            [-1.285781768582392, 52.926045972886385],
            [-1.284978170317087, 52.92687896008826],
            [-1.281472960283899, 52.928977540156254],
            [-1.280618440221843, 52.930451138761875],
            [-1.278215201759444, 52.932815182728554],
            [-1.278159570495411, 52.93365535946721],
            [-1.278381621940421, 52.934348895924458],
            [-1.277846651671064, 52.935740811158951],
            [-1.27916352671101, 52.936808660164331],
            [-1.278843441962982, 52.937625658148093],
            [-1.279416853824895, 52.938039953146436],
            [-1.279049775919368, 52.938908805500674],
            [-1.280479377682465, 52.939467620812529],
            [-1.281137549295717, 52.940785859752864],
            [-1.281228841765444, 52.941113627601091],
            [-1.280269859534091, 52.941603144389269],
            [-1.282204664736813, 52.944371904034639],
            [-1.281715985760699, 52.945035070243648],
            [-1.282904807293316, 52.945139332532101],
            [-1.282776190109877, 52.94553768782464],
            [-1.281914119674402, 52.946371198872875],
            [-1.281371640227424, 52.946417363097929],
            [-1.281263595238703, 52.947189801674213],
            [-1.28209172821572, 52.947509435304099],
            [-1.282145705080948, 52.947934961554303],
            [-1.281034836206484, 52.949139126234627],
            [-1.282407748391997, 52.950177609357794],
            [-1.282417360118369, 52.951215946733988],
            [-1.28415231904054, 52.952509196487568],
            [-1.285753106678391, 52.952827160693225],
            [-1.286433082598272, 52.953837160142044],
            [-1.287437512477277, 52.954398730171576],
            [-1.288227739261009, 52.954499650045761],
            [-1.288999520305241, 52.954000859854766],
            [-1.290223070874447, 52.954545737452648],
            [-1.290657037903914, 52.954132116931788],
            [-1.291851867557769, 52.954978850872529],
            [-1.291663473191283, 52.955394837808335],
            [-1.292504868833044, 52.955554465191767],
            [-1.291695939343048, 52.956141153003763],
            [-1.290566548699207, 52.959754458040038],
            [-1.289467086892917, 52.960803250552949],
            [-1.288646069470306, 52.960938576701835],
            [-1.289165942996405, 52.964461039643908],
            [-1.288668446416369, 52.96492821074699],
            [-1.289289169780701, 52.965390381903475],
            [-1.288531913213705, 52.96589914878539],
            [-1.289420140181414, 52.966301791340044],
            [-1.288257315118762, 52.967570433766014],
            [-1.287713498327686, 52.96768224208185],
            [-1.287627977214055, 52.968809000898815],
            [-1.288406244622657, 52.969019517720277],
            [-1.287851599459045, 52.969789286023463],
            [-1.288379520546161, 52.969740307730227],
            [-1.288667897015931, 52.969316834384173],
            [-1.288813875426312, 52.969857970412455],
            [-1.289626305092146, 52.969711804295883],
            [-1.290679965413835, 52.970656586793936],
            [-1.293461268847986, 52.977066398426281],
            [-1.297473965490126, 52.984654731182005],
            [-1.296749641375221, 52.98513857366035],
            [-1.296184073048535, 52.984926674872689],
            [-1.295655439804765, 52.985649886893725],
            [-1.295358434571905, 52.985410808281948],
            [-1.294651354322128, 52.985748213391354],
            [-1.29473345513866, 52.986377956291008],
            [-1.294681786686676, 52.986898134511137],
            [-1.295273971059375, 52.986848607348023],
            [-1.295479894069751, 52.987834163247129],
            [-1.296711672199963, 52.987752458109874],
            [-1.297273288283005, 52.988394022610791],
            [-1.298097543415434, 52.988549011894364],
            [-1.297765095426443, 52.988840102979609],
            [-1.298142468191654, 52.989361915678757],
            [-1.297690731965311, 52.989659494087732],
            [-1.299239829986084, 52.989487944314732],
            [-1.300492790792429, 52.990126372740669],
            [-1.301237964287647, 52.991030587781012],
            [-1.30072330737616, 52.991811435583337],
            [-1.30155874961778, 52.992293679037736],
            [-1.30050589846165, 52.992635380518202],
            [-1.300373499308863, 52.993176660204057],
            [-1.301409353421741, 52.993612439527553],
            [-1.300676753245536, 52.994048613279162],
            [-1.300751712002489, 52.994388851627662],
            [-1.301057572646242, 52.994638755437059],
            [-1.301440810400523, 52.994340762165031],
            [-1.301582129359757, 52.994816229977737],
            [-1.302343648446713, 52.994989698591134],
            [-1.301741871974043, 52.995904879679848],
            [-1.303139739432916, 52.996811113221078],
            [-1.302470717716389, 52.997182946991487],
            [-1.30361092337619, 52.997993275349494],
            [-1.302948586581687, 52.998041534318816],
            [-1.303006024161638, 52.998360992783418],
            [-1.302358333617771, 52.998424616186639],
            [-1.30249875199308, 52.998772428490689],
            [-1.301916285172278, 52.999041389075828],
            [-1.302452153378467, 52.999167686597282],
            [-1.302692975663174, 52.999665310498408],
            [-1.303548766988832, 52.999726956753314],
            [-1.302842569242341, 53.000276563950266],
            [-1.304232258404811, 53.000125588638966],
            [-1.304291864684265, 53.000496298294223],
            [-1.303682492299694, 53.000584424285336],
            [-1.303741343826405, 53.00109446462784],
            [-1.304925964542295, 53.001373769274856],
            [-1.304552569182796, 53.001705990571374],
            [-1.306341924676136, 53.002467946681612],
            [-1.305744208959761, 53.002664023335832],
            [-1.306334922517375, 53.003276946529319],
            [-1.307268952091701, 53.003017203586374],
            [-1.307029528550077, 53.003451792149789],
            [-1.307446817512898, 53.003635806808447],
            [-1.307988743544512, 53.003853805617744],
            [-1.308682765070477, 53.00350635756962],
            [-1.309291632192717, 53.00382542000542],
            [-1.310022936989934, 53.003381994705975],
            [-1.312319680157869, 53.004578290032619],
            [-1.313370677753783, 53.004553796957126],
            [-1.31335862274914, 53.005219837042084],
            [-1.313496239950924, 53.006596898071365],
            [-1.314564145313323, 53.007201745040156],
            [-1.315544185494687, 53.008464095632426],
            [-1.319193649849368, 53.010196603005944],
            [-1.319605927513815, 53.011749619517886],
            [-1.318277262817039, 53.013778094640124],
            [-1.320191015703898, 53.015492520201306],
            [-1.318580091155053, 53.016128737797544],
            [-1.321896421736285, 53.019952859772673],
            [-1.322701411485376, 53.021572822965425],
            [-1.324462297694978, 53.025853649832015],
            [-1.325887958757376, 53.027364745162792],
            [-1.327789499140927, 53.028387696688362],
            [-1.328025619970186, 53.029216943994946],
            [-1.329181603866075, 53.03072917205558],
            [-1.330451395335623, 53.030722839433352],
            [-1.330999576783995, 53.030421185717245],
            [-1.33116044042312, 53.030914702870369],
            [-1.331812244570195, 53.030903082806908],
            [-1.331270419806652, 53.031806159140366],
            [-1.331946158831537, 53.032466172315139],
            [-1.331629790917968, 53.032770930404148],
            [-1.332123831196475, 53.032934612321547],
            [-1.331845363101419, 53.033199131895714],
            [-1.332272699186152, 53.034013262595757],
            [-1.333113087098884, 53.033863359450585],
            [-1.332718936417268, 53.034280050456474],
            [-1.333050237519122, 53.034554282868939],
            [-1.332643651838641, 53.034616726291063],
            [-1.333213746379089, 53.035555705866081],
            [-1.332641908409025, 53.036372322588775],
            [-1.333102552992599, 53.036963702504828],
            [-1.332572173304782, 53.037218721354051],
            [-1.332575158967342, 53.038281270411233],
            [-1.333192040673679, 53.039670874322411],
            [-1.334619773557594, 53.040611951593696],
            [-1.334281090770342, 53.041297736668469],
            [-1.334731553610181, 53.0411986768503],
            [-1.334954589621455, 53.041437240091675],
            [-1.334950814688183, 53.041682626017518],
            [-1.333607954079395, 53.041715566124914],
            [-1.333605223220607, 53.042182992782415],
            [-1.332404569812158, 53.042181657381313],
            [-1.332615340325529, 53.042537915595005],
            [-1.33381906510989, 53.042921309882878],
            [-1.333045362436041, 53.043378125925599],
            [-1.333597747700503, 53.044119238915783],
            [-1.33302656711229, 53.044016258474684],
            [-1.3329643168016, 53.044570547253016],
            [-1.333985861604889, 53.044680543701482],
            [-1.333744992982233, 53.045694982304461],
            [-1.334639510053119, 53.04727580388078],
            [-1.333240241129047, 53.04814981958318],
            [-1.334440451138623, 53.050224965247722],
            [-1.335775397354428, 53.050724134843854],
            [-1.336121195240377, 53.051326546239203],
            [-1.335804758851513, 53.051628617576938],
            [-1.336323628457551, 53.051647692569844],
            [-1.336394918665354, 53.05215418494344],
            [-1.335871027702202, 53.052946811643935],
            [-1.335195982787867, 53.052605047328306],
            [-1.333487137070981, 53.053130350949857],
            [-1.332628946769578, 53.05305632497263],
            [-1.331046826620984, 53.054065925838245],
            [-1.329609942199092, 53.054460560067007],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000173",
        LAD13CDO: "37UE",
        LAD13NM: "Gedling",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.159959966745054, 53.103941770665983],
            [-1.15767125394478, 53.098991386599927],
            [-1.149502578650239, 53.099936522976684],
            [-1.13928998969339, 53.099626718936889],
            [-1.139268731593628, 53.096051521719303],
            [-1.139599860448194, 53.096071893643582],
            [-1.140150763978319, 53.092333626922304],
            [-1.135651768746328, 53.090532852868932],
            [-1.131262594865907, 53.088190652645231],
            [-1.128956377318859, 53.08741780003367],
            [-1.127539944489774, 53.087022685390174],
            [-1.120646314976558, 53.086731052507474],
            [-1.130184713479412, 53.073588607616088],
            [-1.127334818312425, 53.07398125848205],
            [-1.125456222408206, 53.074907756569203],
            [-1.122423161042777, 53.075199162604996],
            [-1.120008238581563, 53.075993072277669],
            [-1.113961650680734, 53.076992781070629],
            [-1.111448042055023, 53.076186578225723],
            [-1.106537039204649, 53.075607761002573],
            [-1.100830850573217, 53.076018757273424],
            [-1.094318916531462, 53.074131964408622],
            [-1.089505621186792, 53.078001123016584],
            [-1.088275724579776, 53.077286943558569],
            [-1.086433226742691, 53.075261896429879],
            [-1.086676567246527, 53.073214187899076],
            [-1.09161712921384, 53.069596017456909],
            [-1.09343470003328, 53.067334664636647],
            [-1.093258674952317, 53.067046562782949],
            [-1.085654826553306, 53.060493563350448],
            [-1.084453231243567, 53.058752070413135],
            [-1.084540112286098, 53.057539170654834],
            [-1.082682123388241, 53.055658665600447],
            [-1.082820382993621, 53.054557630902458],
            [-1.081754684770488, 53.054086458909445],
            [-1.081616895769177, 53.053339275337123],
            [-1.080434603559292, 53.052880675571195],
            [-1.080485896639276, 53.052293164863187],
            [-1.079139081582378, 53.051995988942174],
            [-1.07844563511119, 53.051003584179156],
            [-1.076536872867451, 53.050058383821828],
            [-1.076024764747076, 53.048821960407587],
            [-1.073694453820344, 53.047437453083887],
            [-1.071303554608594, 53.047074523464431],
            [-1.071118964279002, 53.046779128740241],
            [-1.070240958911014, 53.046681478869843],
            [-1.070271045804037, 53.046323036065722],
            [-1.069333834064249, 53.045858148458827],
            [-1.066652409731397, 53.045484761847391],
            [-1.065201519289984, 53.044772197854151],
            [-1.064216665192443, 53.044376113832747],
            [-1.063859614032664, 53.043093210766962],
            [-1.062898201433727, 53.04244379799615],
            [-1.063436508168983, 53.041762142414932],
            [-1.063106182755342, 53.041587843889403],
            [-1.0635190203178, 53.041533561293043],
            [-1.062971653605939, 53.041326091037035],
            [-1.062504722388816, 53.041536361701176],
            [-1.061978358719689, 53.041048581986466],
            [-1.062836134216211, 53.040078189261131],
            [-1.060699387570374, 53.040396647201526],
            [-1.061014247240073, 53.040526782231893],
            [-1.06070681498479, 53.041084397661585],
            [-1.059921621227562, 53.040017443443787],
            [-1.060240294336674, 53.039628920145624],
            [-1.059511300847897, 53.039476632636585],
            [-1.059474577726487, 53.039039455651057],
            [-1.058914762927009, 53.039474612982858],
            [-1.057718281967477, 53.03791626341409],
            [-1.056765121382581, 53.035531003345049],
            [-1.041879463286126, 53.031394678493434],
            [-1.038805074712705, 53.028625396850543],
            [-1.037110380062152, 53.02805973996896],
            [-1.036915440952773, 53.027305743311814],
            [-1.036103437960546, 53.026676196920526],
            [-1.035668591572828, 53.026919883635415],
            [-1.034697926651227, 53.026452649839079],
            [-1.034799780285176, 53.026030970836736],
            [-1.033409130013351, 53.025277149775995],
            [-1.030206255821217, 53.024557995699553],
            [-1.029733377419689, 53.024236811343577],
            [-1.02975702654918, 53.023249060015424],
            [-1.029394766100858, 53.02318138078379],
            [-1.030014247480998, 53.021286056036423],
            [-1.030508912147427, 53.02096646671356],
            [-1.032454203906781, 53.020410122815882],
            [-1.042416633693868, 53.019392664897907],
            [-1.048490782905895, 53.017052898144883],
            [-1.053898661364045, 53.014340754679971],
            [-1.052595257612379, 53.013008028645871],
            [-1.05250366556549, 53.012017557039357],
            [-1.052911545367607, 53.011156016729451],
            [-1.052452062526159, 53.010494327212136],
            [-1.051159715759476, 53.010431886819546],
            [-1.048896475653444, 53.009515762540246],
            [-1.048636494946712, 53.00839269398255],
            [-1.047037627321753, 53.007363183897517],
            [-1.046197839593426, 53.007125423008951],
            [-1.043169626432403, 53.007253025248644],
            [-1.041168469717041, 53.00683238371581],
            [-1.038186184103454, 53.007240701680018],
            [-1.037154598130882, 53.00678377976103],
            [-1.036583753995715, 53.006852871571873],
            [-1.034215599420368, 53.004983627017531],
            [-1.036640926800494, 53.003547893432327],
            [-1.037965919510087, 53.00105863497172],
            [-1.039209919569068, 53.000197606656037],
            [-1.037259864182828, 52.999036574434513],
            [-1.039361368294659, 52.998409018038025],
            [-1.03812125161768, 52.995610447462028],
            [-1.036946295781356, 52.995449016474808],
            [-1.03694749730399, 52.995930864443388],
            [-1.03419248258808, 52.996135060501636],
            [-1.031169743340655, 52.992654902692685],
            [-1.02897062197264, 52.99365638070487],
            [-1.027498773295448, 52.992654607426402],
            [-1.024728477988543, 52.99308139916068],
            [-1.023308356699316, 52.991839078129424],
            [-1.021521918844279, 52.992702671611767],
            [-1.016810038131184, 52.988743473406018],
            [-1.01459278806069, 52.989815554100588],
            [-1.012892922145058, 52.988445833601268],
            [-1.011021494054641, 52.989505435653577],
            [-1.008768903923505, 52.989184633270014],
            [-1.007257041298079, 52.987875729187877],
            [-1.007236613229773, 52.987532157733874],
            [-1.00783975281033, 52.98734481468577],
            [-1.007089494901457, 52.985437262174507],
            [-1.013257096068207, 52.981092704621638],
            [-1.016556588236718, 52.980223778356276],
            [-1.018392108832804, 52.980330637498653],
            [-1.020023989909909, 52.980946396936702],
            [-1.021134166169282, 52.981911129891536],
            [-1.022645179815134, 52.98425724482],
            [-1.023930628953201, 52.985118213291734],
            [-1.0259456223179, 52.985799061674989],
            [-1.032779567737261, 52.985882706874399],
            [-1.034363693282396, 52.985585441176859],
            [-1.036390400193377, 52.984542913064026],
            [-1.037673006667511, 52.983399042440908],
            [-1.038160997208021, 52.981546650713625],
            [-1.036455032004245, 52.971793564119253],
            [-1.03360100522285, 52.969027688203468],
            [-1.032664942602877, 52.967202386903111],
            [-1.032638288048669, 52.966261861513182],
            [-1.03349024288054, 52.964513127495927],
            [-1.032904466698163, 52.962073089770378],
            [-1.033186291964824, 52.958849023610348],
            [-1.037231176830647, 52.956429491153749],
            [-1.041560075140274, 52.951241523143729],
            [-1.042920982797176, 52.951286647457863],
            [-1.046968115363181, 52.952500390754494],
            [-1.048284609151386, 52.952604428627218],
            [-1.051161354148963, 52.952149177843381],
            [-1.054058243735572, 52.951108796699657],
            [-1.056683806167843, 52.950698159537268],
            [-1.062355890983635, 52.950294451651807],
            [-1.063714782738273, 52.950570353853287],
            [-1.064624504190021, 52.951107899425459],
            [-1.065669993305819, 52.952736939039077],
            [-1.067904150888108, 52.954608123542613],
            [-1.07180864667228, 52.955786637091286],
            [-1.073833544954683, 52.955835689834579],
            [-1.075105799640328, 52.955439266266559],
            [-1.078261951624345, 52.953039295309004],
            [-1.079900819122153, 52.951142609024203],
            [-1.083156404227023, 52.948587758500508],
            [-1.084795127798401, 52.948160796087635],
            [-1.086118974698574, 52.948549440536169],
            [-1.088110989811436, 52.951562752274114],
            [-1.089732554388019, 52.952523576265691],
            [-1.091876335374539, 52.953144068288481],
            [-1.092855100803527, 52.954384905732184],
            [-1.092041144477208, 52.954514434428127],
            [-1.092165828669533, 52.95481563725744],
            [-1.096640412745007, 52.95545651429974],
            [-1.094583972809083, 52.956703901309666],
            [-1.09694133845647, 52.95801812106653],
            [-1.101498796942647, 52.958917442463729],
            [-1.102077184515942, 52.962473573604989],
            [-1.103889259959866, 52.962103391837459],
            [-1.104061663548443, 52.964062608722323],
            [-1.109473685269978, 52.964132931016209],
            [-1.112204927093475, 52.965262661335167],
            [-1.114645276710607, 52.964603946943384],
            [-1.115192903354029, 52.964836356894772],
            [-1.120109954278975, 52.967805221494054],
            [-1.119754291346455, 52.969408114236835],
            [-1.120461246482679, 52.973753474817912],
            [-1.120498685623835, 52.974550220853601],
            [-1.121324304263252, 52.974888036534267],
            [-1.125840728487245, 52.980590990756966],
            [-1.125276498304174, 52.981463316290494],
            [-1.125671691119089, 52.981586681418378],
            [-1.122789166129545, 52.983446952304014],
            [-1.123727585418489, 52.983445779928381],
            [-1.126000158369791, 52.984648214727564],
            [-1.131192850995834, 52.986054459617698],
            [-1.132819615061318, 52.986495129911852],
            [-1.136203864214282, 52.986503576879521],
            [-1.139823165147686, 52.985927514777842],
            [-1.142491734170228, 52.986268608249226],
            [-1.14017344952583, 52.989517741418069],
            [-1.140041287178565, 52.992572301865707],
            [-1.139179904351237, 52.993484792824468],
            [-1.138257849545345, 52.994823836901631],
            [-1.140492040584087, 52.994782470414741],
            [-1.140749236635491, 52.996086899831099],
            [-1.141323961917503, 52.99655760259693],
            [-1.146096565631414, 52.998358386589068],
            [-1.148206452541446, 52.997221967290521],
            [-1.149079957494106, 52.997657914730475],
            [-1.152387208434961, 52.996620774672017],
            [-1.153659419902531, 52.997165604345511],
            [-1.153445894944886, 52.99741758698179],
            [-1.150152078348897, 52.999058942659005],
            [-1.14970305165942, 53.000223461439191],
            [-1.147866638569643, 53.001087688711962],
            [-1.147319282015068, 53.002776476840296],
            [-1.143516050509743, 53.006481565874509],
            [-1.146399623084443, 53.008228262789117],
            [-1.14751250146266, 53.007266284160373],
            [-1.150741616585491, 53.008351931253578],
            [-1.151539556613274, 53.007637572396057],
            [-1.154256742388731, 53.008233143529843],
            [-1.154160902913182, 53.009019036399209],
            [-1.15464037889361, 53.009498883882884],
            [-1.158510060518138, 53.009622506656243],
            [-1.157987272860589, 53.010600451659691],
            [-1.16215222629171, 53.010644241481572],
            [-1.162279548430533, 53.011612398827005],
            [-1.165291294870989, 53.010402918808687],
            [-1.166659450309228, 53.010174291409442],
            [-1.166786858512296, 53.010601281878465],
            [-1.167776330300513, 53.014253412251811],
            [-1.170794661489777, 53.013946375382503],
            [-1.184485357046953, 53.01442665762589],
            [-1.183159763803982, 53.016741321195546],
            [-1.182749956009648, 53.018549868682058],
            [-1.183647007791466, 53.020860006847698],
            [-1.182995737552849, 53.021954038994302],
            [-1.18007428065749, 53.023810924819244],
            [-1.17934261650552, 53.024734483489901],
            [-1.179459114265356, 53.026924200733916],
            [-1.178032552535816, 53.029966247264426],
            [-1.17775975378523, 53.033020748745017],
            [-1.176903804903818, 53.03521363034826],
            [-1.177134309269295, 53.037128161433273],
            [-1.178318996811002, 53.039845741156164],
            [-1.181256324658621, 53.043354755533002],
            [-1.181584086378247, 53.047865168176273],
            [-1.182245051159573, 53.04848907758069],
            [-1.183985917094869, 53.048647553288419],
            [-1.185387239589427, 53.048301175621482],
            [-1.185830915311737, 53.048663785397068],
            [-1.189425353997837, 53.0473489098678],
            [-1.189992751613613, 53.047876851638961],
            [-1.189598833172008, 53.048041371577078],
            [-1.189957701496992, 53.048632616482514],
            [-1.191719090687237, 53.047775323428795],
            [-1.196416191035234, 53.048997320364158],
            [-1.19710792397621, 53.048619942401942],
            [-1.199906730035101, 53.051586392802797],
            [-1.202027328986456, 53.050823053252508],
            [-1.202349655090754, 53.051787970902346],
            [-1.203968569651184, 53.050560080960786],
            [-1.2060733651709, 53.050487843007737],
            [-1.206788164215065, 53.051369967342673],
            [-1.208334939188167, 53.050814837886733],
            [-1.208770525897995, 53.051142249151219],
            [-1.210949026931351, 53.050220923872828],
            [-1.215279723533466, 53.049236460902328],
            [-1.217300865627578, 53.049083467958759],
            [-1.228121440313367, 53.054552220094116],
            [-1.228887524210669, 53.058190662445234],
            [-1.231291295542417, 53.062046438852377],
            [-1.229982700855589, 53.065222886697114],
            [-1.229724218996001, 53.068085201379034],
            [-1.228007055751777, 53.07063872021596],
            [-1.227360903464719, 53.075053658009146],
            [-1.226366114849479, 53.077090459665982],
            [-1.226312042789842, 53.081023813349034],
            [-1.221168054156632, 53.083977406632044],
            [-1.218798301269052, 53.084659447737117],
            [-1.215039939975033, 53.084685966050984],
            [-1.213482117926021, 53.0850478405088],
            [-1.210719236194463, 53.084847073712382],
            [-1.205906576243052, 53.086426847323757],
            [-1.202508726028056, 53.086892253092017],
            [-1.201789420909341, 53.088003001535945],
            [-1.199703378363473, 53.089138725631202],
            [-1.199722108441923, 53.090785685950586],
            [-1.194916069475918, 53.092433379614981],
            [-1.192929810642068, 53.092604212343367],
            [-1.196047068569612, 53.094485181384989],
            [-1.185645688921929, 53.09908711696017],
            [-1.183029121624467, 53.099399997832656],
            [-1.180013579384953, 53.100546525763988],
            [-1.175236443955253, 53.101640337134995],
            [-1.167675043436334, 53.102535191885046],
            [-1.164254217109779, 53.103251048324431],
            [-1.159959966745054, 53.103941770665983],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000174",
        LAD13CDO: "37UF",
        LAD13NM: "Mansfield",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.095439984868931, 53.232403331792796],
            [-1.094776424827238, 53.229413904498777],
            [-1.095253203844052, 53.227688913198335],
            [-1.097321323523154, 53.219523574089692],
            [-1.102855184195854, 53.219709174121668],
            [-1.103941279886075, 53.219230131189668],
            [-1.103593440452285, 53.216808537779414],
            [-1.103892970143194, 53.212931972599165],
            [-1.104553379470134, 53.210488290371892],
            [-1.10499094159693, 53.210328872857048],
            [-1.104240429995231, 53.208836429152896],
            [-1.108711618822764, 53.203125873600001],
            [-1.111228705791018, 53.198907177532796],
            [-1.113492613896762, 53.196271334651044],
            [-1.116640235877115, 53.194102247236422],
            [-1.120069718088796, 53.192186855044341],
            [-1.124845600316881, 53.191168490016324],
            [-1.127842143253135, 53.190055124602495],
            [-1.127743736798423, 53.189667870177963],
            [-1.136121192220152, 53.188665471624894],
            [-1.138803133514281, 53.187843496312013],
            [-1.140059409981448, 53.187063319271402],
            [-1.141917605982684, 53.184990327965259],
            [-1.147933954421396, 53.180133501341956],
            [-1.152986307598772, 53.175307277467688],
            [-1.152001737038358, 53.172732067451548],
            [-1.151550052236461, 53.172042977835922],
            [-1.146171013372372, 53.169217908226528],
            [-1.132480737401778, 53.162808601154346],
            [-1.131654994505144, 53.162887983347019],
            [-1.128682335290653, 53.159522294920372],
            [-1.126137003323683, 53.157376804320641],
            [-1.122035950084514, 53.155925457054941],
            [-1.118880396137896, 53.154395566371115],
            [-1.116782669377591, 53.153649208703541],
            [-1.111058744919331, 53.153408872423356],
            [-1.111160832478672, 53.152810052226556],
            [-1.111956789218115, 53.152354840476235],
            [-1.114152573217543, 53.15159901318782],
            [-1.118056836180907, 53.149338423419238],
            [-1.122213373858773, 53.147957813553425],
            [-1.127455644545418, 53.14492830086094],
            [-1.130143791297954, 53.141599470155285],
            [-1.129973663100081, 53.140252538539542],
            [-1.128838195765956, 53.138666627685943],
            [-1.129929946443867, 53.137902431308767],
            [-1.130258300194018, 53.135928993215757],
            [-1.127202407446096, 53.134775792613908],
            [-1.128586051732282, 53.132730980638179],
            [-1.126891645428832, 53.132113595205929],
            [-1.127159580592622, 53.130766271752705],
            [-1.126268569465797, 53.128345223901604],
            [-1.126256709741653, 53.126866403347009],
            [-1.127295835702484, 53.125367421511953],
            [-1.12724506880803, 53.12447980904448],
            [-1.12021995502468, 53.119062423374345],
            [-1.120943845737004, 53.118735165610801],
            [-1.121890397114654, 53.118772712291552],
            [-1.125386926756725, 53.117514773841251],
            [-1.126205979912671, 53.117649332963907],
            [-1.127643478171567, 53.118529134527272],
            [-1.130818962172548, 53.119549263294005],
            [-1.134483651457959, 53.119208292194578],
            [-1.136299087621478, 53.118538287929738],
            [-1.13690216574036, 53.119462262251268],
            [-1.140442433820253, 53.119887889276292],
            [-1.143502887805261, 53.12137788514103],
            [-1.145212043912943, 53.121845021099219],
            [-1.14712591079639, 53.121936948690795],
            [-1.150354268546074, 53.121372126707008],
            [-1.152714531058486, 53.120495409935856],
            [-1.158532368454239, 53.119768976094321],
            [-1.160950009986717, 53.11907857951563],
            [-1.161161188794153, 53.119436940427143],
            [-1.165386950693966, 53.117963627159227],
            [-1.167634605659687, 53.115928909730883],
            [-1.169123540396916, 53.115266012217759],
            [-1.170797701230243, 53.114990923832885],
            [-1.177893769402798, 53.11479201820665],
            [-1.182990515890505, 53.115156998640536],
            [-1.185854614061092, 53.115790578351735],
            [-1.186449069031238, 53.117304832979698],
            [-1.18847001061199, 53.119718747156291],
            [-1.193783252258006, 53.119888773021444],
            [-1.198845423181279, 53.121751341918902],
            [-1.200684243319027, 53.121786170137767],
            [-1.201918213152365, 53.122690670711812],
            [-1.202489444237743, 53.124445596986689],
            [-1.210589250141751, 53.125899177972592],
            [-1.217365183220038, 53.128274795878056],
            [-1.22029497036792, 53.129648683306307],
            [-1.222934883854621, 53.130282594055409],
            [-1.224605793660394, 53.131613096911828],
            [-1.229302816628836, 53.134152463335511],
            [-1.237916898000254, 53.139641843973529],
            [-1.239919869984949, 53.139568344898429],
            [-1.2395461448963, 53.140584432954576],
            [-1.23715194136476, 53.146285337509283],
            [-1.237823120336402, 53.148742775825475],
            [-1.239849960837198, 53.148696397032495],
            [-1.239771119467558, 53.149859091063306],
            [-1.24093922990111, 53.152979483484224],
            [-1.243597733934183, 53.154185656060172],
            [-1.247525506945152, 53.153025758074442],
            [-1.251278088545059, 53.154360915941574],
            [-1.254188034388469, 53.154713563283842],
            [-1.255326564382867, 53.154597533553734],
            [-1.256843762445938, 53.156367979134117],
            [-1.257023932228235, 53.160139146071273],
            [-1.257996846898739, 53.161309301276908],
            [-1.2586504068926, 53.163482446044526],
            [-1.259927381544873, 53.164461209709458],
            [-1.254679645597971, 53.164754805905382],
            [-1.253755697233677, 53.165160725035818],
            [-1.253667591464093, 53.165583561075444],
            [-1.25317393839663, 53.165499565942611],
            [-1.253397888657513, 53.165868624621638],
            [-1.252368649071539, 53.166219938493953],
            [-1.252905510359516, 53.166917266177926],
            [-1.251694110289449, 53.167247655927433],
            [-1.249849709356644, 53.16853498751582],
            [-1.25023256459447, 53.168798081675718],
            [-1.24945815461791, 53.168942425959081],
            [-1.249674119076614, 53.16925391078103],
            [-1.249072645773716, 53.16994588004308],
            [-1.24949161564264, 53.170883388164199],
            [-1.248236859139443, 53.171632362857913],
            [-1.246310773448593, 53.172100215337949],
            [-1.244608290980844, 53.173293078586553],
            [-1.245272080390155, 53.175195786231534],
            [-1.245761308242519, 53.17536698013194],
            [-1.243673102720418, 53.176279624182463],
            [-1.243334261244929, 53.1770001980803],
            [-1.241690957402674, 53.178130471364192],
            [-1.238767276164995, 53.179061981054424],
            [-1.237803309644231, 53.178913791635523],
            [-1.237420478220153, 53.179154947548106],
            [-1.236184360146264, 53.17882881654041],
            [-1.236204368911487, 53.178460390995319],
            [-1.234564141735719, 53.178226936990967],
            [-1.234014233440349, 53.17843914250065],
            [-1.231652717060574, 53.178128198136825],
            [-1.229915345008682, 53.178475649762937],
            [-1.229126387111304, 53.178833709374544],
            [-1.226735354431816, 53.178667201445705],
            [-1.222596983378692, 53.18014146055377],
            [-1.220949717245235, 53.179553601716165],
            [-1.22104105273573, 53.179137103374792],
            [-1.220467195798244, 53.178767493577553],
            [-1.219576877166709, 53.178769756606663],
            [-1.219036752153608, 53.179097916983991],
            [-1.218399754069063, 53.178910362651742],
            [-1.215662906957837, 53.180888866278416],
            [-1.213424357421273, 53.180890292643674],
            [-1.212809932421836, 53.180613865105229],
            [-1.212265522223116, 53.181008486134104],
            [-1.209402779403371, 53.18110279197456],
            [-1.204293806851366, 53.182339852417194],
            [-1.201193463461407, 53.182410800688338],
            [-1.200846515911306, 53.182721296436618],
            [-1.199273121134721, 53.182757478722245],
            [-1.198803399169913, 53.183070739226103],
            [-1.199300927763886, 53.183353645027012],
            [-1.196938007647157, 53.184799378765561],
            [-1.195307152874567, 53.187022177151817],
            [-1.195057226176138, 53.189340583164132],
            [-1.195818168598365, 53.192426292466742],
            [-1.196738726841586, 53.19354445422988],
            [-1.197317533564658, 53.195251791301068],
            [-1.201392032408798, 53.198630301741694],
            [-1.20159676694866, 53.200099594421204],
            [-1.201439697418526, 53.203154837378349],
            [-1.200972984923556, 53.203939155019285],
            [-1.202194314091191, 53.20677980018332],
            [-1.201816621619869, 53.207042450083826],
            [-1.202843432444235, 53.208350042939053],
            [-1.20474060598805, 53.208263834647717],
            [-1.205543957563353, 53.209435076329314],
            [-1.205603513268995, 53.210506073651288],
            [-1.208200510755611, 53.211694629289717],
            [-1.208459307965336, 53.212602447555327],
            [-1.209028896271342, 53.21257206963039],
            [-1.21105909155941, 53.21577035333484],
            [-1.209426092587141, 53.217677804172766],
            [-1.208095965322392, 53.216959738142421],
            [-1.206609336982674, 53.216609167832679],
            [-1.202718042297821, 53.216639838764166],
            [-1.20223839196082, 53.216098184094186],
            [-1.196935092948865, 53.21676102951109],
            [-1.196790121375176, 53.217145684542224],
            [-1.188571960715094, 53.218377269348629],
            [-1.188267885646059, 53.218697008631274],
            [-1.181559055855932, 53.218661028574616],
            [-1.17913466501472, 53.219114489672108],
            [-1.17577060453278, 53.219207238480152],
            [-1.171122484159236, 53.218939497989219],
            [-1.169488128874683, 53.219192403095988],
            [-1.16175501592808, 53.221524886580319],
            [-1.156897949141183, 53.222278981248522],
            [-1.152189589557036, 53.223685645905967],
            [-1.149944481962628, 53.223980682283383],
            [-1.146603130994315, 53.224942916249326],
            [-1.139789470390063, 53.225755105775086],
            [-1.132598814946546, 53.22571469325505],
            [-1.123674952221444, 53.224653439558537],
            [-1.122107878155686, 53.22554711496209],
            [-1.123068669447767, 53.226689511441911],
            [-1.122083593768125, 53.230837030471641],
            [-1.119691851788579, 53.231021650385117],
            [-1.119728889402857, 53.232064660820733],
            [-1.118360987314775, 53.232069835282722],
            [-1.116447054227925, 53.231818354840932],
            [-1.111460249451131, 53.230421242073142],
            [-1.108989751840231, 53.23252243948204],
            [-1.104985304187781, 53.235103785758618],
            [-1.103297153524001, 53.235588199994297],
            [-1.102147681425836, 53.235602921471028],
            [-1.101258707165259, 53.234983166069014],
            [-1.099493283559108, 53.234792760037458],
            [-1.099234851837839, 53.234113927074475],
            [-1.098296361371516, 53.234071775717467],
            [-1.098287938819278, 53.232547156580921],
            [-1.095439984868931, 53.232403331792796],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000175",
        LAD13CDO: "37UG",
        LAD13NM: "Newark and Sherwood",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.688027801486821, 53.258370086554699],
            [-0.683542095888482, 53.254524403255971],
            [-0.682352906397939, 53.251515981853821],
            [-0.680610693849059, 53.251971358810771],
            [-0.666577942518685, 53.239681249082061],
            [-0.667115299727484, 53.238783814626309],
            [-0.667153751741028, 53.23648473281883],
            [-0.666407111459934, 53.235294264176304],
            [-0.666509989190094, 53.233430094589082],
            [-0.668919711441747, 53.231142230746869],
            [-0.669062188262064, 53.229977883601954],
            [-0.670144406721557, 53.229282488384378],
            [-0.670183493733118, 53.228504433732951],
            [-0.671308538149454, 53.228020759072855],
            [-0.671021357665089, 53.227328961846453],
            [-0.671478838594559, 53.225906528563542],
            [-0.672974112023097, 53.2244435125819],
            [-0.673146737828566, 53.223753242510021],
            [-0.67473634603769, 53.222578019843219],
            [-0.674977212414564, 53.220354896021057],
            [-0.676183357307528, 53.218203624852478],
            [-0.675873261529919, 53.21762305370742],
            [-0.686684238482831, 53.219609786809009],
            [-0.686876171732754, 53.219945411790647],
            [-0.697617460895884, 53.22021608453754],
            [-0.708260383949111, 53.212209906310548],
            [-0.709011813452255, 53.212270187152185],
            [-0.714754108115682, 53.207586701309424],
            [-0.720313565233639, 53.209300590424348],
            [-0.72246065192528, 53.209407227354255],
            [-0.722253434530408, 53.209778968111195],
            [-0.723759814174505, 53.210081872799094],
            [-0.724414224369733, 53.209626819775806],
            [-0.730570237475156, 53.210735326298199],
            [-0.742486625599518, 53.198416641088322],
            [-0.753768347715604, 53.188135240808904],
            [-0.762169649716645, 53.188218317723965],
            [-0.762549551578388, 53.185731281770408],
            [-0.764140157788613, 53.181522738166116],
            [-0.752221922914164, 53.181032647921754],
            [-0.737729680078165, 53.179284357534932],
            [-0.730268014653293, 53.180074356783336],
            [-0.729713605135506, 53.178225597127032],
            [-0.725905014084261, 53.178443827343742],
            [-0.718162234139852, 53.177266749852294],
            [-0.718662220715167, 53.177058177469213],
            [-0.718724528799889, 53.174916643178236],
            [-0.716030324754158, 53.170391072257686],
            [-0.723234785633578, 53.155082900645048],
            [-0.722124860152793, 53.151918360783647],
            [-0.722066068677255, 53.149717984562457],
            [-0.721212117050563, 53.148161715201745],
            [-0.721051128424752, 53.144269301513518],
            [-0.718675911968027, 53.141990085226404],
            [-0.717158592475069, 53.13892358663751],
            [-0.715378317437829, 53.136861962770311],
            [-0.71893797264828, 53.135744246717593],
            [-0.724003208687911, 53.133398408007942],
            [-0.728086787842192, 53.130364060020092],
            [-0.732410588593197, 53.12778071076049],
            [-0.732018377300699, 53.126685199484498],
            [-0.731109064947313, 53.126351888304193],
            [-0.731095166506852, 53.12398208459922],
            [-0.728158030634514, 53.123192027626743],
            [-0.727261583189421, 53.122021892365929],
            [-0.720840261994058, 53.12086624021903],
            [-0.718563263567012, 53.119548152454911],
            [-0.71688592912863, 53.119423112641208],
            [-0.716758836804279, 53.116163907098965],
            [-0.71837414316195, 53.110330861654553],
            [-0.721254172674906, 53.104247087077304],
            [-0.729589488647708, 53.097799969677119],
            [-0.728978715946033, 53.096124974424612],
            [-0.726866230420534, 53.094227162251123],
            [-0.715396410328402, 53.087174713167357],
            [-0.715394382313192, 53.086490578194201],
            [-0.709421278258441, 53.08596119849598],
            [-0.709604950600215, 53.085211653691111],
            [-0.706153295802536, 53.083357370829873],
            [-0.70816302740497, 53.082405618938665],
            [-0.710362239324146, 53.079356797780363],
            [-0.707480974388112, 53.07870885108855],
            [-0.702764720350429, 53.076633063106549],
            [-0.698486340243997, 53.075446460451865],
            [-0.698759333828873, 53.074660150493983],
            [-0.697948755226716, 53.074661173909462],
            [-0.698573221647616, 53.073555079983954],
            [-0.697719265113386, 53.072768129960721],
            [-0.698070151442154, 53.072220001603249],
            [-0.697437663797651, 53.071662911204683],
            [-0.69838996441278, 53.070919993555677],
            [-0.698810149240431, 53.067725153793006],
            [-0.697591620110834, 53.066015466815841],
            [-0.695304622600601, 53.067023332723295],
            [-0.695136383426582, 53.066269049520194],
            [-0.695679217057163, 53.065739213760537],
            [-0.697219492862405, 53.064993758594916],
            [-0.698482044532016, 53.065043532578855],
            [-0.70050245110263, 53.063813346366501],
            [-0.701543284892835, 53.063642216765153],
            [-0.702766011815739, 53.064271346869539],
            [-0.704280299184185, 53.064336388874786],
            [-0.704268459174373, 53.065029366010009],
            [-0.705702167245985, 53.065393768358824],
            [-0.707184816142855, 53.065569901320195],
            [-0.707833858064845, 53.065375581320772],
            [-0.711047734190907, 53.066200629142024],
            [-0.712101647408233, 53.065841664231456],
            [-0.713470683943377, 53.066025478727006],
            [-0.71349132829139, 53.065332596661911],
            [-0.714084013068233, 53.065576330387074],
            [-0.714411793541879, 53.065293999647622],
            [-0.715063139773331, 53.065372052898404],
            [-0.715944662512072, 53.064384611170063],
            [-0.715222602744377, 53.063774508418973],
            [-0.714076946275607, 53.063658752538032],
            [-0.713853063325151, 53.062306979768863],
            [-0.713192433014705, 53.061941148916894],
            [-0.712944055989983, 53.061061967207728],
            [-0.71367260210605, 53.060299427572851],
            [-0.716208087984799, 53.059037685805002],
            [-0.717844994067664, 53.059540778030218],
            [-0.718974574735612, 53.05939023011603],
            [-0.719804233767253, 53.059893591913351],
            [-0.721342229398055, 53.059707859990539],
            [-0.722601897511674, 53.059901182683717],
            [-0.723551871914512, 53.059364803380923],
            [-0.723263657131319, 53.058766593808308],
            [-0.724126180478959, 53.058815399564871],
            [-0.725093720385278, 53.058289085051172],
            [-0.724695923485854, 53.058066372905451],
            [-0.725367124460628, 53.057468558215049],
            [-0.724935411794795, 53.057131314408593],
            [-0.725593427961744, 53.056271757069709],
            [-0.72670673143029, 53.05646706500864],
            [-0.726712659753781, 53.056063491243854],
            [-0.727337518128801, 53.055973087258792],
            [-0.727546252324049, 53.055623822575377],
            [-0.728419771715195, 53.055803964577869],
            [-0.72910652011361, 53.055080439426249],
            [-0.730225517606291, 53.054980014519238],
            [-0.730029656522159, 53.054587771680303],
            [-0.73107881348517, 53.054324778948462],
            [-0.732628498991721, 53.055308585297468],
            [-0.733634458783827, 53.055552128318247],
            [-0.734254469815741, 53.054811682128459],
            [-0.736061661156385, 53.054901910516648],
            [-0.736563413746047, 53.054632154740126],
            [-0.736966051247803, 53.054894433177061],
            [-0.738053315084442, 53.054755841218373],
            [-0.738567761133115, 53.055226062601356],
            [-0.739136255547593, 53.054969589107195],
            [-0.73882957064033, 53.054588772318027],
            [-0.739738894851363, 53.053747083621104],
            [-0.740688614410248, 53.053565658326974],
            [-0.742837735743655, 53.054619500069585],
            [-0.74543107954738, 53.054098500480677],
            [-0.749555974271863, 53.055094824634047],
            [-0.75333522101434, 53.0482214199059],
            [-0.755860647625289, 53.04397955349566],
            [-0.757151361364956, 53.03784409801807],
            [-0.756623304329208, 53.036654638065279],
            [-0.757822134308033, 53.036455903393907],
            [-0.758858896011396, 53.035148834966506],
            [-0.758439227165961, 53.032365741285567],
            [-0.760831511005744, 53.029389024006655],
            [-0.770391113029328, 53.027296530475539],
            [-0.773960591312958, 53.0255138586679],
            [-0.776678166061501, 53.024689613592898],
            [-0.780414351933399, 53.024380075846459],
            [-0.784877247826555, 53.02516110326544],
            [-0.785848613461521, 53.025719392101664],
            [-0.788418956189644, 53.025831899392706],
            [-0.795830648446023, 53.010576149804272],
            [-0.798619818518554, 53.0109891136633],
            [-0.802036861583874, 53.012090654533829],
            [-0.803650185925465, 53.01293304453332],
            [-0.804279432499642, 53.012223793050318],
            [-0.804065810382395, 53.006363065242148],
            [-0.802621668151515, 53.00636291878309],
            [-0.802515875380352, 53.00533882637918],
            [-0.800527094803972, 53.003804920667363],
            [-0.800195335400671, 53.00217174146804],
            [-0.795864608850705, 53.000571854822262],
            [-0.794941779701993, 52.999944914675091],
            [-0.79382758206452, 52.997844410358276],
            [-0.791896973978836, 52.997452639717231],
            [-0.792568649004171, 52.995861988253168],
            [-0.791499618771156, 52.995407037378868],
            [-0.791518205462828, 52.995008082665436],
            [-0.790571833181239, 52.994590328521291],
            [-0.790097975650543, 52.993792614866784],
            [-0.789691347854245, 52.993628460032198],
            [-0.789461322836944, 52.993915587864826],
            [-0.787330352998859, 52.993184587370337],
            [-0.788100619996521, 52.991747790352584],
            [-0.784464821636958, 52.990123095253082],
            [-0.784541861937865, 52.988546184151964],
            [-0.782135622505481, 52.988451444880248],
            [-0.782209614407305, 52.987568511259639],
            [-0.781595993535596, 52.987351865619864],
            [-0.780910563354174, 52.986405412493916],
            [-0.780163900645647, 52.986417532726101],
            [-0.780311439091587, 52.985093058825782],
            [-0.779580941880771, 52.984955212350272],
            [-0.778462542357742, 52.983677072932075],
            [-0.777559805424941, 52.983784662456735],
            [-0.777483933977452, 52.981670391854379],
            [-0.776858262287564, 52.981409547381219],
            [-0.777674134196568, 52.980367034978052],
            [-0.777085163228035, 52.980020267188188],
            [-0.777604260689489, 52.979570723308434],
            [-0.776794211738382, 52.978983452377072],
            [-0.777653304462406, 52.978413346826947],
            [-0.777000824376142, 52.977837585601613],
            [-0.778284253033863, 52.976901461365358],
            [-0.778652670215759, 52.976670613635733],
            [-0.780096601427348, 52.977277862090133],
            [-0.781805059083112, 52.976424271033764],
            [-0.783180866122739, 52.976913018109087],
            [-0.784349574384056, 52.976397274330964],
            [-0.787569960452925, 52.977657257490876],
            [-0.788860494616311, 52.976671648880512],
            [-0.790513739620958, 52.977201792286507],
            [-0.796155646140872, 52.97670977588983],
            [-0.796699352512199, 52.976223540625391],
            [-0.797281068440273, 52.976191669415378],
            [-0.796972104140133, 52.97584064064246],
            [-0.79911692762901, 52.974327773566785],
            [-0.799860010520272, 52.973146832298688],
            [-0.800832512747204, 52.973144958096483],
            [-0.801453034726656, 52.972785332374578],
            [-0.801139861004523, 52.972532260069308],
            [-0.801785300238595, 52.972348183893054],
            [-0.80209433524082, 52.972535587665298],
            [-0.803028700736821, 52.971703557961419],
            [-0.804047246649399, 52.971596941130912],
            [-0.805246499209642, 52.968068836668131],
            [-0.805876195603829, 52.967318226191345],
            [-0.806904096151035, 52.96670555643383],
            [-0.809817716499701, 52.965958045946202],
            [-0.809899093455317, 52.964946615032211],
            [-0.811993514907201, 52.960781935254381],
            [-0.811762782050119, 52.959803342089117],
            [-0.812472451299177, 52.959113727333829],
            [-0.812856150701584, 52.957442768218698],
            [-0.813707122408539, 52.956973008151294],
            [-0.820027248135433, 52.960443023802654],
            [-0.82237850770971, 52.962452172151757],
            [-0.836076863433908, 52.967899999866894],
            [-0.831594980704485, 52.97054846069495],
            [-0.830480962505316, 52.971602789699354],
            [-0.828284783861038, 52.972196962649953],
            [-0.826139623667933, 52.973489201330928],
            [-0.825451954459554, 52.973311605381397],
            [-0.822084311039077, 52.976892158351859],
            [-0.815188777121759, 52.985602108068036],
            [-0.818152629646344, 52.986603389869003],
            [-0.820415659451216, 52.987798117647976],
            [-0.820600716927668, 52.989645539396562],
            [-0.822370583694037, 52.992602710945512],
            [-0.823510055636301, 52.99705399832348],
            [-0.823051676091869, 52.999445214670779],
            [-0.822146253047925, 53.000081710438003],
            [-0.822922456116439, 53.000964091555048],
            [-0.824022485155383, 53.001337257837619],
            [-0.825054750685191, 53.002231145886853],
            [-0.828908367528176, 53.003169020231233],
            [-0.829918871457226, 53.006526719834625],
            [-0.833359693201895, 53.007702207512736],
            [-0.835764594437725, 53.005674330717667],
            [-0.843637233052635, 53.008154966845716],
            [-0.84727318249794, 53.008850101043784],
            [-0.848970761009925, 53.009635156054273],
            [-0.850601606450453, 53.008502049851359],
            [-0.851604636350371, 53.008502748822202],
            [-0.855083020336262, 53.006652951321477],
            [-0.859510873659427, 53.009366314162783],
            [-0.860836295293911, 53.009803326565894],
            [-0.860885181565252, 53.010097755910458],
            [-0.864558327895358, 53.011323104034112],
            [-0.868638214872796, 53.007945920230561],
            [-0.872454388687928, 53.007115557601509],
            [-0.876259920715561, 53.00697627493507],
            [-0.879784907194809, 53.006331708517266],
            [-0.881949095589384, 53.009149642477553],
            [-0.884302353977945, 53.011235755573693],
            [-0.895427019009994, 53.015515253342805],
            [-0.896961488655228, 53.016602850343553],
            [-0.903938681857468, 53.025455562536123],
            [-0.905772570952045, 53.027224512979863],
            [-0.909905556680918, 53.030131026807425],
            [-0.910432519452629, 53.03206051994102],
            [-0.910153733978661, 53.033181661852097],
            [-0.909278728932657, 53.034113052539446],
            [-0.910079339347513, 53.035067889190721],
            [-0.910125270753638, 53.035907934752551],
            [-0.915121199239934, 53.033475173622655],
            [-0.917422777834511, 53.031547202397249],
            [-0.919397387678251, 53.030999710554973],
            [-0.922445054787384, 53.029216856196534],
            [-0.924797996388367, 53.02699974447448],
            [-0.927007537448267, 53.023822106821491],
            [-0.928864322954344, 53.021089831283909],
            [-0.931529387441873, 53.018818762213506],
            [-0.938024318815758, 53.014094473570253],
            [-0.944435258963378, 53.0112272303829],
            [-0.94976410785567, 53.010353868162746],
            [-0.952034502588805, 53.009598093249423],
            [-0.954766601153893, 53.00829707098103],
            [-0.957401430858447, 53.005892114274182],
            [-0.960439522087478, 53.004243950123175],
            [-0.966451027151144, 52.996637229412066],
            [-0.970797854503824, 52.989657664148105],
            [-0.975966091169732, 52.986306016859558],
            [-0.97878143361754, 52.985613757193128],
            [-0.98737395105418, 52.986294928916422],
            [-0.992074542445468, 52.986580276893278],
            [-0.997727816547835, 52.985996989391545],
            [-1.004088771521822, 52.986114254976847],
            [-1.007089494901457, 52.985437262174507],
            [-1.00783975281033, 52.98734481468577],
            [-1.007236613229773, 52.987532157733874],
            [-1.007257041298079, 52.987875729187877],
            [-1.008768903923505, 52.989184633270014],
            [-1.011021494054641, 52.989505435653577],
            [-1.012892922145058, 52.988445833601268],
            [-1.01459278806069, 52.989815554100588],
            [-1.016810038131184, 52.988743473406018],
            [-1.021521918844279, 52.992702671611767],
            [-1.023308356699316, 52.991839078129424],
            [-1.024728477988543, 52.99308139916068],
            [-1.027498773295448, 52.992654607426402],
            [-1.02897062197264, 52.99365638070487],
            [-1.031169743340655, 52.992654902692685],
            [-1.03419248258808, 52.996135060501636],
            [-1.03694749730399, 52.995930864443388],
            [-1.036946295781356, 52.995449016474808],
            [-1.03812125161768, 52.995610447462028],
            [-1.039361368294659, 52.998409018038025],
            [-1.037259864182828, 52.999036574434513],
            [-1.039209919569068, 53.000197606656037],
            [-1.037965919510087, 53.00105863497172],
            [-1.036640926800494, 53.003547893432327],
            [-1.034215599420368, 53.004983627017531],
            [-1.036583753995715, 53.006852871571873],
            [-1.037154598130882, 53.00678377976103],
            [-1.038186184103454, 53.007240701680018],
            [-1.041168469717041, 53.00683238371581],
            [-1.043169626432403, 53.007253025248644],
            [-1.046197839593426, 53.007125423008951],
            [-1.047037627321753, 53.007363183897517],
            [-1.048636494946712, 53.00839269398255],
            [-1.048896475653444, 53.009515762540246],
            [-1.051159715759476, 53.010431886819546],
            [-1.052452062526159, 53.010494327212136],
            [-1.052911545367607, 53.011156016729451],
            [-1.05250366556549, 53.012017557039357],
            [-1.052595257612379, 53.013008028645871],
            [-1.053898661364045, 53.014340754679971],
            [-1.048490782905895, 53.017052898144883],
            [-1.042416633693868, 53.019392664897907],
            [-1.032454203906781, 53.020410122815882],
            [-1.030508912147427, 53.02096646671356],
            [-1.030014247480998, 53.021286056036423],
            [-1.029394766100858, 53.02318138078379],
            [-1.02975702654918, 53.023249060015424],
            [-1.029733377419689, 53.024236811343577],
            [-1.030206255821217, 53.024557995699553],
            [-1.033409130013351, 53.025277149775995],
            [-1.034799780285176, 53.026030970836736],
            [-1.034697926651227, 53.026452649839079],
            [-1.035668591572828, 53.026919883635415],
            [-1.036103437960546, 53.026676196920526],
            [-1.036915440952773, 53.027305743311814],
            [-1.037110380062152, 53.02805973996896],
            [-1.038805074712705, 53.028625396850543],
            [-1.041879463286126, 53.031394678493434],
            [-1.056765121382581, 53.035531003345049],
            [-1.057718281967477, 53.03791626341409],
            [-1.058914762927009, 53.039474612982858],
            [-1.059474577726487, 53.039039455651057],
            [-1.059511300847897, 53.039476632636585],
            [-1.060240294336674, 53.039628920145624],
            [-1.059921621227562, 53.040017443443787],
            [-1.06070681498479, 53.041084397661585],
            [-1.061014247240073, 53.040526782231893],
            [-1.060699387570374, 53.040396647201526],
            [-1.062836134216211, 53.040078189261131],
            [-1.061978358719689, 53.041048581986466],
            [-1.062504722388816, 53.041536361701176],
            [-1.062971653605939, 53.041326091037035],
            [-1.0635190203178, 53.041533561293043],
            [-1.063106182755342, 53.041587843889403],
            [-1.063436508168983, 53.041762142414932],
            [-1.062898201433727, 53.04244379799615],
            [-1.063859614032664, 53.043093210766962],
            [-1.064216665192443, 53.044376113832747],
            [-1.065201519289984, 53.044772197854151],
            [-1.066652409731397, 53.045484761847391],
            [-1.069333834064249, 53.045858148458827],
            [-1.070271045804037, 53.046323036065722],
            [-1.070240958911014, 53.046681478869843],
            [-1.071118964279002, 53.046779128740241],
            [-1.071303554608594, 53.047074523464431],
            [-1.073694453820344, 53.047437453083887],
            [-1.076024764747076, 53.048821960407587],
            [-1.076536872867451, 53.050058383821828],
            [-1.07844563511119, 53.051003584179156],
            [-1.079139081582378, 53.051995988942174],
            [-1.080485896639276, 53.052293164863187],
            [-1.080434603559292, 53.052880675571195],
            [-1.081616895769177, 53.053339275337123],
            [-1.081754684770488, 53.054086458909445],
            [-1.082820382993621, 53.054557630902458],
            [-1.082682123388241, 53.055658665600447],
            [-1.084540112286098, 53.057539170654834],
            [-1.084453231243567, 53.058752070413135],
            [-1.085654826553306, 53.060493563350448],
            [-1.093258674952317, 53.067046562782949],
            [-1.09343470003328, 53.067334664636647],
            [-1.09161712921384, 53.069596017456909],
            [-1.086676567246527, 53.073214187899076],
            [-1.086433226742691, 53.075261896429879],
            [-1.088275724579776, 53.077286943558569],
            [-1.089505621186792, 53.078001123016584],
            [-1.094318916531462, 53.074131964408622],
            [-1.100830850573217, 53.076018757273424],
            [-1.106537039204649, 53.075607761002573],
            [-1.111448042055023, 53.076186578225723],
            [-1.113961650680734, 53.076992781070629],
            [-1.120008238581563, 53.075993072277669],
            [-1.122423161042777, 53.075199162604996],
            [-1.125456222408206, 53.074907756569203],
            [-1.127334818312425, 53.07398125848205],
            [-1.130184713479412, 53.073588607616088],
            [-1.120646314976558, 53.086731052507474],
            [-1.127539944489774, 53.087022685390174],
            [-1.128956377318859, 53.08741780003367],
            [-1.131262594865907, 53.088190652645231],
            [-1.135651768746328, 53.090532852868932],
            [-1.140150763978319, 53.092333626922304],
            [-1.139599860448194, 53.096071893643582],
            [-1.139268731593628, 53.096051521719303],
            [-1.13928998969339, 53.099626718936889],
            [-1.149502578650239, 53.099936522976684],
            [-1.15767125394478, 53.098991386599927],
            [-1.159959966745054, 53.103941770665983],
            [-1.164254217109779, 53.103251048324431],
            [-1.167780954140794, 53.107496210387367],
            [-1.169994380649144, 53.109456036236878],
            [-1.172753411971031, 53.111176002259739],
            [-1.175585492345878, 53.112290533316347],
            [-1.177893769402798, 53.11479201820665],
            [-1.170797701230243, 53.114990923832885],
            [-1.169123540396916, 53.115266012217759],
            [-1.167634605659687, 53.115928909730883],
            [-1.165386950693966, 53.117963627159227],
            [-1.161161188794153, 53.119436940427143],
            [-1.160950009986717, 53.11907857951563],
            [-1.158532368454239, 53.119768976094321],
            [-1.152714531058486, 53.120495409935856],
            [-1.150354268546074, 53.121372126707008],
            [-1.14712591079639, 53.121936948690795],
            [-1.145212043912943, 53.121845021099219],
            [-1.143502887805261, 53.12137788514103],
            [-1.140442433820253, 53.119887889276292],
            [-1.13690216574036, 53.119462262251268],
            [-1.136299087621478, 53.118538287929738],
            [-1.134483651457959, 53.119208292194578],
            [-1.130818962172548, 53.119549263294005],
            [-1.127643478171567, 53.118529134527272],
            [-1.126205979912671, 53.117649332963907],
            [-1.125386926756725, 53.117514773841251],
            [-1.121890397114654, 53.118772712291552],
            [-1.120943845737004, 53.118735165610801],
            [-1.12021995502468, 53.119062423374345],
            [-1.12724506880803, 53.12447980904448],
            [-1.127295835702484, 53.125367421511953],
            [-1.126256709741653, 53.126866403347009],
            [-1.126268569465797, 53.128345223901604],
            [-1.127159580592622, 53.130766271752705],
            [-1.126891645428832, 53.132113595205929],
            [-1.128586051732282, 53.132730980638179],
            [-1.127202407446096, 53.134775792613908],
            [-1.130258300194018, 53.135928993215757],
            [-1.129929946443867, 53.137902431308767],
            [-1.128838195765956, 53.138666627685943],
            [-1.129973663100081, 53.140252538539542],
            [-1.130143791297954, 53.141599470155285],
            [-1.127455644545418, 53.14492830086094],
            [-1.122213373858773, 53.147957813553425],
            [-1.118056836180907, 53.149338423419238],
            [-1.114152573217543, 53.15159901318782],
            [-1.111956789218115, 53.152354840476235],
            [-1.111160832478672, 53.152810052226556],
            [-1.111058744919331, 53.153408872423356],
            [-1.116782669377591, 53.153649208703541],
            [-1.118880396137896, 53.154395566371115],
            [-1.122035950084514, 53.155925457054941],
            [-1.126137003323683, 53.157376804320641],
            [-1.128682335290653, 53.159522294920372],
            [-1.131654994505144, 53.162887983347019],
            [-1.132480737401778, 53.162808601154346],
            [-1.146171013372372, 53.169217908226528],
            [-1.151550052236461, 53.172042977835922],
            [-1.152001737038358, 53.172732067451548],
            [-1.152986307598772, 53.175307277467688],
            [-1.147933954421396, 53.180133501341956],
            [-1.141917605982684, 53.184990327965259],
            [-1.140059409981448, 53.187063319271402],
            [-1.138803133514281, 53.187843496312013],
            [-1.136121192220152, 53.188665471624894],
            [-1.127743736798423, 53.189667870177963],
            [-1.127842143253135, 53.190055124602495],
            [-1.124845600316881, 53.191168490016324],
            [-1.120069718088796, 53.192186855044341],
            [-1.116640235877115, 53.194102247236422],
            [-1.113492613896762, 53.196271334651044],
            [-1.111228705791018, 53.198907177532796],
            [-1.108711618822764, 53.203125873600001],
            [-1.104240429995231, 53.208836429152896],
            [-1.10499094159693, 53.210328872857048],
            [-1.104553379470134, 53.210488290371892],
            [-1.103892970143194, 53.212931972599165],
            [-1.103593440452285, 53.216808537779414],
            [-1.103941279886075, 53.219230131189668],
            [-1.102855184195854, 53.219709174121668],
            [-1.097321323523154, 53.219523574089692],
            [-1.095253203844052, 53.227688913198335],
            [-1.094776424827238, 53.229413904498777],
            [-1.095439984868931, 53.232403331792796],
            [-1.098287938819278, 53.232547156580921],
            [-1.098296361371516, 53.234071775717467],
            [-1.099234851837839, 53.234113927074475],
            [-1.099493283559108, 53.234792760037458],
            [-1.101258707165259, 53.234983166069014],
            [-1.102147681425836, 53.235602921471028],
            [-1.103297153524001, 53.235588199994297],
            [-1.09295178959596, 53.237213386761788],
            [-1.086311832931228, 53.237678665840583],
            [-1.072484805615141, 53.240886201772881],
            [-1.068919073776811, 53.242655339300093],
            [-1.0662611409837, 53.244288555559514],
            [-1.06622022879226, 53.244722410176855],
            [-1.058438224591755, 53.249086565664754],
            [-1.052147116665263, 53.252180225192859],
            [-1.046007306954855, 53.253485028864276],
            [-1.031225797096899, 53.259764331948631],
            [-1.027956343282769, 53.261015983486253],
            [-1.027536257560872, 53.260831877195514],
            [-1.027317588516232, 53.258047057128579],
            [-1.025906780521681, 53.252116190307234],
            [-1.02317867565034, 53.24791301891549],
            [-1.023550314396374, 53.247783023198785],
            [-1.019317594049432, 53.245375148868412],
            [-1.017254863715091, 53.24363674088751],
            [-1.016897761807021, 53.243917854878255],
            [-1.012923918074126, 53.241623349162076],
            [-1.012548583478932, 53.241654400275891],
            [-1.01179988149007, 53.240459828485712],
            [-1.010133480877589, 53.239491355088333],
            [-1.00659737544164, 53.236119767130305],
            [-0.996580595217747, 53.236727156062791],
            [-0.983929219369854, 53.236584953475045],
            [-0.969980770770006, 53.235739837264468],
            [-0.967544339097736, 53.235313349713728],
            [-0.967904586112357, 53.234911953950274],
            [-0.964642890600501, 53.233856205569431],
            [-0.961289444753329, 53.233255321618088],
            [-0.957538593061378, 53.231872401222056],
            [-0.952935221205047, 53.22706416794928],
            [-0.950236195491141, 53.22613161894423],
            [-0.947219877397766, 53.225742752952563],
            [-0.949149109943477, 53.224278346442489],
            [-0.94829870233617, 53.222733684932493],
            [-0.949657509487927, 53.221069169383078],
            [-0.948660270912048, 53.22081317390716],
            [-0.948265790484504, 53.219185335395409],
            [-0.949714983114815, 53.217984562947798],
            [-0.949293287477339, 53.214285361144945],
            [-0.947251119700773, 53.213838554342722],
            [-0.944654486485156, 53.214628222508793],
            [-0.939852170049228, 53.215464758180914],
            [-0.936547641193806, 53.215923439630316],
            [-0.935804671935744, 53.215748709413987],
            [-0.934895687032456, 53.216169379007383],
            [-0.929975828574088, 53.216419265578942],
            [-0.92168414960047, 53.218661981864109],
            [-0.920014960904472, 53.218334942393767],
            [-0.91655012357638, 53.218303500037216],
            [-0.907270473596117, 53.220482297342542],
            [-0.901704866824776, 53.221124222474089],
            [-0.900014170958105, 53.221931153611564],
            [-0.899974642779674, 53.222246313073263],
            [-0.899276639873061, 53.22236392213032],
            [-0.898305335249153, 53.223325793101239],
            [-0.896303069743993, 53.223194905361872],
            [-0.89424976316164, 53.223650511582179],
            [-0.88884996225469, 53.223476248848186],
            [-0.886928305900954, 53.222721198231625],
            [-0.883712328400039, 53.222322564236386],
            [-0.880198652583802, 53.22093581837332],
            [-0.879343390241357, 53.221051833413867],
            [-0.878713128666278, 53.220351931425832],
            [-0.877668932904921, 53.220361880993032],
            [-0.877492350323983, 53.219783104161692],
            [-0.875460964391616, 53.218542308931617],
            [-0.872598657039221, 53.218151203686361],
            [-0.872377086461704, 53.217805715661029],
            [-0.871130234833935, 53.217437037637758],
            [-0.87112747589803, 53.216972263352432],
            [-0.87045248516928, 53.217069246118797],
            [-0.86942918862446, 53.216686487570506],
            [-0.867298742210565, 53.216376805003691],
            [-0.865437306843582, 53.214275374013305],
            [-0.862780966491057, 53.213132684853527],
            [-0.862076355717314, 53.213783979684216],
            [-0.857840420262577, 53.212185610985529],
            [-0.854426384599832, 53.214648289165368],
            [-0.85309222739588, 53.214754118423443],
            [-0.852633377377695, 53.21544907345271],
            [-0.848499862563301, 53.215287856292854],
            [-0.848215251403759, 53.218640829484869],
            [-0.83982114474103, 53.218899220908838],
            [-0.830799849498027, 53.217545371295962],
            [-0.831484326235496, 53.215672407789391],
            [-0.835510617330491, 53.216197236919591],
            [-0.836507172206675, 53.215510293452581],
            [-0.836956268310377, 53.213636799064027],
            [-0.830521448152653, 53.212809739415711],
            [-0.832064977076313, 53.20671120275658],
            [-0.827477729364887, 53.206311080955473],
            [-0.826548765843883, 53.205841681749114],
            [-0.82476687277114, 53.203384404742152],
            [-0.824921291729829, 53.202719813757376],
            [-0.823147496172347, 53.202215985049712],
            [-0.821572876936201, 53.20132935333698],
            [-0.820711480491071, 53.201404433946998],
            [-0.820679672783393, 53.201636045554004],
            [-0.819865811053021, 53.201615403935897],
            [-0.818548597026427, 53.203076613682782],
            [-0.817613734380923, 53.203317247518875],
            [-0.817377001065192, 53.204085289641512],
            [-0.815879173351326, 53.204868675284303],
            [-0.805850242562558, 53.205479671210696],
            [-0.803262143737493, 53.205510344259515],
            [-0.798859087602958, 53.204640822021297],
            [-0.790584350288937, 53.204839405171704],
            [-0.78571490207855, 53.20432603647788],
            [-0.787398545388578, 53.20307836746364],
            [-0.786994809635333, 53.202424323517135],
            [-0.788049532698586, 53.202239084776814],
            [-0.788931652564975, 53.201643963054124],
            [-0.790145093498443, 53.199223750062906],
            [-0.781400609878506, 53.196648175053731],
            [-0.7809726382736, 53.196431647143505],
            [-0.782174271165302, 53.194280175428808],
            [-0.781751795244173, 53.19138844819549],
            [-0.783600505021684, 53.190640534693678],
            [-0.788036472048935, 53.191077674423688],
            [-0.789739802234674, 53.190863957937843],
            [-0.791009692962899, 53.190361754108437],
            [-0.793192815644664, 53.186544494873097],
            [-0.792995703699071, 53.185835928254065],
            [-0.791244098160637, 53.183995112639195],
            [-0.791197210188814, 53.183214352969237],
            [-0.791787532654256, 53.182709739772612],
            [-0.794548529035141, 53.182067991096531],
            [-0.795805694054707, 53.181153891450393],
            [-0.795544013375191, 53.180345791995457],
            [-0.793411542945489, 53.17928413655865],
            [-0.788926204396922, 53.178888040664894],
            [-0.786733831988176, 53.178260745290295],
            [-0.785795862111009, 53.17745831975099],
            [-0.785278278687886, 53.17586460586049],
            [-0.784922892672997, 53.175786369413437],
            [-0.784470931372179, 53.176372366996617],
            [-0.784604244118017, 53.177214241812742],
            [-0.786516900399206, 53.179001964282342],
            [-0.787992424393132, 53.17936847086235],
            [-0.792731257101694, 53.179637722427891],
            [-0.794437013687336, 53.180494608490093],
            [-0.79472319520754, 53.181175308141675],
            [-0.794385900068119, 53.181517090464112],
            [-0.791773736849043, 53.182029098646211],
            [-0.79001888737116, 53.182916528466158],
            [-0.790178488710122, 53.183998680783766],
            [-0.791928152552449, 53.186759112543463],
            [-0.790331011866, 53.189747180291732],
            [-0.789365970049986, 53.190266857565717],
            [-0.788038307463538, 53.190428655554278],
            [-0.783358268366303, 53.18989193776482],
            [-0.782016766695176, 53.190119150351499],
            [-0.78071285591431, 53.191078473479159],
            [-0.779894549403029, 53.191222019158424],
            [-0.780530445638982, 53.191493717149406],
            [-0.781081581656751, 53.193487820997831],
            [-0.78045980016423, 53.195566110617989],
            [-0.776345073945457, 53.199552098017747],
            [-0.77649184958246, 53.200653912099206],
            [-0.779236575643584, 53.202741538659218],
            [-0.77953163979941, 53.203843967979488],
            [-0.778915807642893, 53.204651203020227],
            [-0.7759321699777, 53.20611104291315],
            [-0.775090646438106, 53.206900658074474],
            [-0.775078550410465, 53.207954993605533],
            [-0.776819238708942, 53.209347354291239],
            [-0.778204251798839, 53.212455718901488],
            [-0.775133425316004, 53.214589750971484],
            [-0.775056663276425, 53.215128327099471],
            [-0.776800799029008, 53.216462289380551],
            [-0.77691055239661, 53.217393819814582],
            [-0.776675988193368, 53.218635547062831],
            [-0.77521284672353, 53.218001151019145],
            [-0.776306214800729, 53.218524777428321],
            [-0.782159275260394, 53.223751830986181],
            [-0.782848641543333, 53.225589112895882],
            [-0.782517313105299, 53.227500470009403],
            [-0.781123261508059, 53.230539021044102],
            [-0.777555728005384, 53.23476435998937],
            [-0.777934005414171, 53.236843887001044],
            [-0.777009099597184, 53.238909151704476],
            [-0.777997127367597, 53.242729879732387],
            [-0.777954071951651, 53.244346626534714],
            [-0.776511252625944, 53.246751767878862],
            [-0.771050566774597, 53.246732463648044],
            [-0.771054075134189, 53.246139201070577],
            [-0.763212169015562, 53.245856750464888],
            [-0.760753629016999, 53.245622668111785],
            [-0.760367597432074, 53.245186265586554],
            [-0.747215372887697, 53.24437009271648],
            [-0.740578239011616, 53.243647535624866],
            [-0.735084348542121, 53.243852764010484],
            [-0.734865849020737, 53.247653865338584],
            [-0.734180251414249, 53.249048037154552],
            [-0.735076031776544, 53.251453220127694],
            [-0.734822966110993, 53.253020084632858],
            [-0.734151509874785, 53.253982914369949],
            [-0.727129669287164, 53.255421977425193],
            [-0.725926367527421, 53.25525720507558],
            [-0.724069037147487, 53.255561862315112],
            [-0.721767731084919, 53.256075680063184],
            [-0.719520567372743, 53.257133894333087],
            [-0.712976145997931, 53.2581160964871],
            [-0.694948750096128, 53.25843434932959],
            [-0.693524380806314, 53.257900061098539],
            [-0.691782254869245, 53.257856688823622],
            [-0.69075968058396, 53.25766657557056],
            [-0.688027801486821, 53.258370086554699],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000176",
        LAD13CDO: "37UJ",
        LAD13NM: "Rushcliffe",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.881949095589384, 53.009149642477553],
            [-0.879784907194809, 53.006331708517266],
            [-0.876259920715561, 53.00697627493507],
            [-0.872454388687928, 53.007115557601509],
            [-0.868638214872796, 53.007945920230561],
            [-0.864558327895358, 53.011323104034112],
            [-0.860885181565252, 53.010097755910458],
            [-0.860836295293911, 53.009803326565894],
            [-0.859510873659427, 53.009366314162783],
            [-0.855083020336262, 53.006652951321477],
            [-0.851604636350371, 53.008502748822202],
            [-0.850601606450453, 53.008502049851359],
            [-0.848970761009925, 53.009635156054273],
            [-0.84727318249794, 53.008850101043784],
            [-0.843637233052635, 53.008154966845716],
            [-0.835764594437725, 53.005674330717667],
            [-0.833359693201895, 53.007702207512736],
            [-0.829918871457226, 53.006526719834625],
            [-0.828908367528176, 53.003169020231233],
            [-0.825054750685191, 53.002231145886853],
            [-0.824022485155383, 53.001337257837619],
            [-0.822922456116439, 53.000964091555048],
            [-0.822146253047925, 53.000081710438003],
            [-0.823051676091869, 52.999445214670779],
            [-0.823510055636301, 52.99705399832348],
            [-0.822370583694037, 52.992602710945512],
            [-0.820600716927668, 52.989645539396562],
            [-0.820415659451216, 52.987798117647976],
            [-0.818152629646344, 52.986603389869003],
            [-0.815188777121759, 52.985602108068036],
            [-0.822084311039077, 52.976892158351859],
            [-0.825451954459554, 52.973311605381397],
            [-0.826139623667933, 52.973489201330928],
            [-0.828284783861038, 52.972196962649953],
            [-0.830480962505316, 52.971602789699354],
            [-0.831594980704485, 52.97054846069495],
            [-0.836076863433908, 52.967899999866894],
            [-0.82237850770971, 52.962452172151757],
            [-0.820027248135433, 52.960443023802654],
            [-0.832861094015138, 52.949578855125658],
            [-0.830886562605103, 52.94856428588956],
            [-0.833913680153615, 52.945859337326802],
            [-0.828062212665792, 52.944523445482602],
            [-0.822544386637408, 52.944121909162035],
            [-0.822816655857291, 52.943685905309636],
            [-0.82436574572481, 52.94176843699303],
            [-0.824912852795575, 52.941567981420413],
            [-0.827545464511831, 52.941673089063379],
            [-0.834894377228814, 52.939324409787993],
            [-0.836223919864411, 52.938077070440471],
            [-0.835336151878792, 52.937778904140444],
            [-0.836782734314752, 52.935771272091273],
            [-0.84133732176934, 52.93108804231403],
            [-0.840601453987569, 52.930342805782409],
            [-0.842410701681932, 52.928495963311789],
            [-0.84137738017025, 52.928361835056769],
            [-0.848231832260948, 52.923566793212252],
            [-0.852986261631502, 52.921074964195832],
            [-0.853338100705261, 52.921213206239102],
            [-0.854160571097467, 52.920877730087689],
            [-0.857508213796577, 52.918862091210961],
            [-0.858263026229358, 52.917476829669042],
            [-0.860488977004453, 52.915796431264525],
            [-0.862897700020329, 52.913092905951416],
            [-0.863752023242067, 52.911304021498786],
            [-0.860445227816378, 52.909813313005188],
            [-0.861403143344555, 52.909065555992072],
            [-0.86126057676435, 52.908604811761123],
            [-0.857637955590391, 52.905621387533337],
            [-0.86024689046363, 52.903998613155515],
            [-0.869507915037529, 52.900243055172226],
            [-0.873012818462706, 52.898150267011161],
            [-0.874324297322655, 52.898196861105845],
            [-0.875040318440395, 52.897470975039724],
            [-0.875473621363084, 52.897558682054282],
            [-0.878723940639937, 52.895691657200487],
            [-0.886675146530952, 52.89201814096382],
            [-0.88900004083319, 52.891518047885093],
            [-0.896268871959226, 52.889162154829407],
            [-0.899044558008447, 52.88790057309415],
            [-0.89931220189883, 52.888072061076571],
            [-0.900896710872873, 52.887537455302507],
            [-0.906532007360173, 52.885197277300648],
            [-0.906039544811395, 52.884628185015032],
            [-0.906313014940184, 52.884509340929228],
            [-0.909127174865394, 52.883645227492828],
            [-0.911981667874356, 52.883540154609776],
            [-0.911784244196937, 52.88222493919946],
            [-0.914344081914494, 52.882429954680021],
            [-0.91485521428636, 52.880766121451281],
            [-0.915811625861216, 52.87965742430881],
            [-0.916631850909609, 52.879599282144483],
            [-0.916929855119591, 52.878910685261978],
            [-0.920461685308897, 52.879640441151132],
            [-0.92068762226334, 52.879158844044007],
            [-0.925964288697116, 52.878797634928141],
            [-0.92787938083291, 52.878327688850923],
            [-0.930351182532107, 52.878963973473837],
            [-0.931578681660898, 52.877992438003183],
            [-0.933702466731766, 52.877127826217873],
            [-0.933913070767113, 52.876598420929049],
            [-0.94057888121254, 52.876835155749824],
            [-0.939994591113264, 52.8750814331801],
            [-0.938911498562603, 52.874043333217763],
            [-0.936069336493401, 52.8717902439884],
            [-0.932721841052788, 52.870042266012348],
            [-0.928126748198923, 52.866062461639231],
            [-0.934236237611435, 52.864079453319498],
            [-0.935415230422971, 52.863187451639014],
            [-0.938468448115205, 52.861941824762319],
            [-0.940832472688295, 52.861543106798869],
            [-0.940939548557498, 52.86056147752344],
            [-0.941410303176914, 52.860321151957578],
            [-0.940671910085198, 52.860328952044362],
            [-0.940556634939579, 52.859756173036637],
            [-0.939483848758632, 52.859467915091123],
            [-0.93930405446903, 52.858983557557281],
            [-0.938680573096137, 52.858969896890464],
            [-0.939394757899588, 52.858004479873323],
            [-0.939191163855345, 52.856790836900309],
            [-0.939899502521449, 52.856977856587548],
            [-0.940294820004961, 52.856784507977082],
            [-0.939712608941403, 52.856602212641569],
            [-0.939404193852928, 52.856033101514022],
            [-0.940519253240977, 52.8559846203581],
            [-0.940119892719071, 52.855307721089389],
            [-0.940918104668886, 52.855096389765706],
            [-0.940590469314817, 52.854523514277773],
            [-0.941134919284699, 52.85485290151064],
            [-0.941845189519481, 52.854532003312848],
            [-0.941180916741142, 52.853939048933441],
            [-0.941763185999242, 52.85411774185792],
            [-0.942379548181994, 52.853932648633993],
            [-0.942419116566726, 52.85352666203319],
            [-0.943336636720131, 52.853598656658207],
            [-0.943410933455949, 52.853107575623937],
            [-0.944126318060588, 52.853002464559779],
            [-0.94422630719199, 52.852614994132701],
            [-0.944940968838094, 52.852661799379248],
            [-0.945493757260375, 52.852095856735616],
            [-0.945542972360289, 52.852454986734749],
            [-0.946049030431909, 52.852530498288758],
            [-0.946277110532276, 52.852249343516156],
            [-0.946894037697178, 52.852284482055765],
            [-0.947840026252915, 52.851362422669112],
            [-0.949036595397224, 52.851930385248068],
            [-0.949168923221662, 52.851431723443802],
            [-0.950311304621182, 52.851661177251827],
            [-0.950330682610947, 52.851350301543029],
            [-0.950981660484488, 52.851266155537395],
            [-0.950696834680861, 52.850637949361499],
            [-0.951306528895397, 52.850849201177176],
            [-0.951742969932086, 52.85048537163199],
            [-0.952777364542626, 52.850388417919341],
            [-0.954615360586319, 52.848983324475356],
            [-0.954974690034091, 52.84762363358125],
            [-0.956172742498722, 52.847014773046951],
            [-0.955756711848587, 52.84684030930039],
            [-0.955880211796421, 52.846334370477869],
            [-0.956808078632844, 52.846526815839681],
            [-0.957459511867248, 52.846296104832867],
            [-0.957774731361282, 52.845223691654056],
            [-0.959195493385777, 52.845169627876061],
            [-0.958943206843946, 52.844729613174209],
            [-0.959683218735976, 52.844518545125752],
            [-0.959410161861278, 52.843829331916062],
            [-0.960383076062933, 52.843874711554797],
            [-0.960857696308963, 52.84333588174497],
            [-0.970618441825068, 52.844964440489612],
            [-0.970481032610278, 52.844747494312841],
            [-0.971520013363603, 52.844260260666708],
            [-0.972324180471675, 52.843215416972008],
            [-0.97307920208543, 52.843119464930346],
            [-0.977080572091004, 52.840716867777601],
            [-0.977369819270966, 52.836619113328517],
            [-0.973920955872098, 52.834303254402194],
            [-0.97444102893763, 52.833083335950271],
            [-0.974930071707969, 52.832991367921728],
            [-0.975313990037997, 52.831373819520032],
            [-0.97536563036574, 52.83062990902858],
            [-0.974784207322621, 52.829918291163374],
            [-0.975519988782444, 52.829683715046734],
            [-0.975057706867286, 52.829207760380697],
            [-0.975901100614585, 52.828940848508175],
            [-0.975730782956066, 52.828293910903646],
            [-0.977173408466443, 52.828105874326027],
            [-0.97738376219644, 52.826542559917556],
            [-0.978059804991339, 52.825498370405512],
            [-0.977794161085972, 52.824991755028506],
            [-0.978143601002842, 52.824520100765476],
            [-0.980093070599753, 52.823912969927399],
            [-0.979936905554789, 52.823610469508857],
            [-0.981452994737462, 52.822177024068765],
            [-0.981320947787074, 52.821667966500328],
            [-0.981922629661981, 52.82157603742494],
            [-0.981929777689287, 52.821206617216667],
            [-0.983497577142162, 52.82034893741924],
            [-0.98767463426574, 52.819402040383665],
            [-0.98901065452197, 52.818229465962624],
            [-0.991541366651875, 52.81973790465095],
            [-0.993815921917987, 52.820650790343826],
            [-0.995500163634906, 52.820910473889363],
            [-0.995689682288719, 52.821250992944051],
            [-0.998586278821589, 52.820546386728722],
            [-1.001685081376708, 52.820403475355697],
            [-1.010188046890217, 52.821712563632865],
            [-1.015555792650992, 52.821509073885899],
            [-1.019213417012744, 52.821724534762033],
            [-1.019635875372688, 52.822141554135953],
            [-1.021940047959902, 52.822031104780869],
            [-1.025151532773731, 52.820036612801694],
            [-1.025592414656488, 52.819432522334772],
            [-1.027859075510187, 52.820131630448195],
            [-1.027996976613745, 52.819604160085575],
            [-1.029925752487182, 52.818085378641776],
            [-1.034825999887728, 52.81685595876008],
            [-1.03646357900326, 52.816210309080326],
            [-1.036296313250147, 52.815856551852363],
            [-1.0366551008404, 52.815936775850375],
            [-1.037536562157702, 52.816335879600381],
            [-1.040223931082466, 52.816192223125583],
            [-1.040670856490565, 52.815369674230801],
            [-1.042196848564993, 52.814528864706581],
            [-1.043583848765184, 52.814610166768254],
            [-1.044713494091735, 52.814180560408339],
            [-1.046010712558082, 52.814296171819912],
            [-1.047276283806949, 52.813959329087403],
            [-1.048882456588463, 52.81405940847791],
            [-1.04965042743539, 52.813814743640215],
            [-1.049773119534073, 52.813427367222332],
            [-1.050522319851522, 52.813703054777022],
            [-1.050344010963161, 52.813987503583498],
            [-1.051775131533634, 52.813882075454451],
            [-1.052587948218372, 52.814986216388625],
            [-1.05404490447254, 52.81512728681426],
            [-1.054506828638445, 52.815447406477269],
            [-1.054606706749964, 52.81522076056725],
            [-1.055135328539201, 52.815339138952716],
            [-1.054993760635173, 52.815640967558231],
            [-1.058561105002959, 52.816449621141437],
            [-1.059211997512846, 52.81722879976504],
            [-1.061763260997678, 52.815962553051406],
            [-1.062210727050843, 52.816474909225235],
            [-1.071745175605282, 52.813990452147877],
            [-1.072147121916721, 52.814136531414647],
            [-1.072079640422375, 52.814588189108719],
            [-1.071399362828401, 52.814970329012098],
            [-1.072010847252036, 52.815310428328146],
            [-1.070968096267134, 52.816199451481879],
            [-1.071540961239124, 52.816959971679495],
            [-1.070570951732891, 52.816940695354816],
            [-1.071121408504821, 52.817360330172733],
            [-1.070189436509465, 52.817852865689574],
            [-1.070573335239307, 52.818846542974484],
            [-1.069899136018495, 52.819148713011749],
            [-1.070436021037841, 52.819301248680368],
            [-1.069720808455572, 52.819577925274508],
            [-1.07011503560971, 52.819740132220488],
            [-1.070072241705717, 52.820354696388065],
            [-1.068845689893442, 52.820601291370217],
            [-1.068819217221128, 52.821215084084805],
            [-1.069653283856244, 52.821489517269569],
            [-1.069468916486135, 52.822200959971177],
            [-1.07036862271093, 52.822321278676327],
            [-1.070780671049099, 52.822901646246962],
            [-1.071426858521291, 52.823150323900933],
            [-1.071888603746697, 52.823001108405904],
            [-1.072728117760781, 52.82351019476954],
            [-1.07337810596367, 52.823441553256018],
            [-1.073705806077603, 52.823943041289567],
            [-1.073249208349989, 52.824129161988765],
            [-1.074442022645238, 52.824705719244037],
            [-1.077064556715833, 52.823997963976112],
            [-1.07871146656516, 52.824227407012529],
            [-1.080763890725831, 52.823455812951622],
            [-1.083754263118944, 52.822890098571179],
            [-1.08787865607012, 52.821033109474321],
            [-1.091491626849789, 52.820059352955667],
            [-1.094197628674163, 52.821948095723492],
            [-1.102047237030591, 52.820181905214852],
            [-1.111558665797342, 52.819990957939815],
            [-1.116948171391139, 52.81955650646514],
            [-1.119965421071927, 52.818798600476605],
            [-1.120072771714522, 52.81789143382349],
            [-1.121519034520424, 52.817400516827838],
            [-1.120604053948793, 52.814285095035522],
            [-1.12125445645294, 52.812345431653185],
            [-1.123058959760967, 52.810925810643965],
            [-1.125731436492836, 52.810161616096309],
            [-1.127422797174665, 52.808305093528148],
            [-1.128833110899775, 52.807662796078617],
            [-1.130899550374973, 52.805806278803601],
            [-1.132993950747608, 52.805369409316263],
            [-1.137281303846316, 52.805655951712353],
            [-1.143969831468291, 52.803856080469963],
            [-1.145007852094043, 52.803948963351168],
            [-1.14746724454793, 52.805013054993992],
            [-1.149929739145873, 52.804859009474299],
            [-1.154079565846293, 52.806273069171077],
            [-1.163054134340273, 52.806211720526456],
            [-1.163281466734341, 52.806501892853042],
            [-1.170600166078738, 52.804276154150145],
            [-1.170477350404696, 52.803714337812117],
            [-1.172107295647147, 52.803025416933494],
            [-1.175173758966778, 52.803160931417835],
            [-1.176682278361543, 52.80261314167192],
            [-1.178409024091135, 52.801581398745959],
            [-1.180038448722735, 52.799252641586548],
            [-1.182479914093588, 52.798297691785713],
            [-1.182833348695796, 52.79787850752686],
            [-1.183080857240093, 52.798125630310885],
            [-1.184323069568862, 52.79808293083962],
            [-1.186752652366216, 52.797677083368974],
            [-1.188943611525265, 52.796542288228473],
            [-1.189176833265367, 52.795884036304116],
            [-1.191382284349176, 52.794839192390519],
            [-1.191505929115394, 52.79448943562651],
            [-1.19096688940847, 52.794127073357679],
            [-1.191489751117144, 52.793922074273091],
            [-1.191121094971693, 52.793408946771642],
            [-1.191604426658599, 52.792775767182718],
            [-1.196143398147399, 52.790144735623372],
            [-1.197735055754672, 52.789391377501254],
            [-1.198166922553189, 52.789533636234175],
            [-1.198689653353249, 52.790380401833858],
            [-1.19778513207184, 52.790296980762967],
            [-1.197408818562901, 52.790761902371734],
            [-1.19916461227248, 52.790599360369029],
            [-1.200050960307835, 52.791836029081985],
            [-1.199730430239752, 52.79209007549877],
            [-1.19909773379861, 52.79189972124567],
            [-1.198654820352419, 52.79211518217754],
            [-1.198489679412446, 52.793031917209099],
            [-1.200294341448995, 52.793767760635099],
            [-1.206284347346405, 52.793518494386419],
            [-1.206813529161444, 52.79369553289262],
            [-1.206928641255466, 52.794215907164372],
            [-1.211245070785848, 52.79490990437877],
            [-1.215263652016333, 52.794855630283855],
            [-1.21713199298179, 52.79338556446217],
            [-1.217559738791429, 52.79376325526885],
            [-1.21840876294346, 52.793722101960256],
            [-1.218239360030312, 52.793244532212086],
            [-1.221958710993572, 52.79174880949062],
            [-1.2227897215922, 52.792133618776312],
            [-1.224640749219969, 52.792284165036449],
            [-1.225742554018486, 52.791618028013403],
            [-1.226511943474637, 52.792468974647221],
            [-1.22841254464619, 52.7930881482595],
            [-1.229268098367899, 52.79326361145138],
            [-1.230841546543896, 52.796208052951563],
            [-1.231899939296718, 52.79691250576851],
            [-1.231974291006058, 52.797743634110915],
            [-1.231149353821998, 52.79860220331804],
            [-1.231876143642954, 52.800621496741698],
            [-1.2382432692693, 52.803790031718108],
            [-1.239841131143233, 52.804114013069075],
            [-1.241517886642538, 52.805276314372925],
            [-1.243813603305205, 52.805225334142079],
            [-1.245373094692196, 52.806143213401903],
            [-1.24901424108803, 52.805752776451186],
            [-1.250735147529097, 52.805140665610317],
            [-1.251794703967823, 52.805549185827019],
            [-1.253901112248823, 52.80433804935457],
            [-1.255370198545947, 52.804132419533651],
            [-1.260027828371085, 52.807112846629643],
            [-1.261932754567759, 52.810433796154818],
            [-1.263383341777353, 52.815020333805215],
            [-1.262137937908293, 52.816893234688017],
            [-1.260116610886864, 52.818664201742536],
            [-1.260082185432946, 52.819206062602206],
            [-1.261545576010954, 52.820415289351345],
            [-1.265236335713342, 52.820664723514135],
            [-1.268950781826521, 52.823042933475719],
            [-1.270890759824597, 52.823729982057756],
            [-1.271258471597482, 52.824459500120263],
            [-1.270894615472758, 52.828288644518977],
            [-1.271359304800488, 52.831563717905638],
            [-1.272098547622065, 52.832448334640297],
            [-1.274029959699964, 52.833677350729182],
            [-1.274163128124903, 52.836286050481284],
            [-1.272336868812674, 52.836930217324216],
            [-1.269539381289389, 52.836619083795767],
            [-1.268441507076661, 52.836757957785018],
            [-1.266750253576527, 52.837665367048714],
            [-1.26757782080601, 52.838859798794651],
            [-1.270739781127307, 52.839576849909029],
            [-1.270519595004255, 52.839959353798136],
            [-1.269078036782482, 52.840314569739299],
            [-1.266403560571756, 52.842207472090344],
            [-1.266218012347474, 52.842734015549055],
            [-1.26777635423535, 52.845151946877607],
            [-1.268638107325029, 52.84564718946244],
            [-1.270846453546208, 52.84615070012638],
            [-1.27109822368015, 52.847519562665667],
            [-1.272242205998623, 52.849257073776641],
            [-1.271092385455717, 52.851149523201883],
            [-1.270609389935301, 52.853830848420031],
            [-1.269798202558297, 52.854913604034252],
            [-1.268220490264751, 52.856182213391882],
            [-1.266764143003464, 52.856236159638115],
            [-1.265089177687806, 52.856923400160468],
            [-1.264549691817624, 52.858087807788003],
            [-1.264925222683899, 52.858716705929957],
            [-1.267248718389454, 52.859827789712682],
            [-1.267950081007033, 52.859720643261184],
            [-1.269012217131933, 52.858914526371564],
            [-1.27149342259561, 52.859040340839606],
            [-1.27391972584805, 52.859519957036746],
            [-1.275288495413241, 52.860360749867809],
            [-1.272760113827542, 52.862163888111176],
            [-1.272666603419661, 52.862870794703937],
            [-1.271532409364927, 52.863986642064255],
            [-1.271584088762525, 52.865247296200906],
            [-1.269509750600329, 52.866524561636574],
            [-1.26966203214313, 52.867187129859303],
            [-1.271070134501866, 52.86755176483193],
            [-1.271553325083728, 52.867971844307711],
            [-1.270788607750876, 52.869192429963974],
            [-1.270240117082718, 52.871601859545336],
            [-1.26789454801181, 52.873349381641709],
            [-1.266109662800947, 52.873292522598327],
            [-1.259913945302271, 52.875052899007095],
            [-1.25583369947147, 52.878108132954004],
            [-1.253616184893619, 52.879266460845301],
            [-1.25025652754288, 52.879523084870222],
            [-1.244831524417848, 52.878743492609907],
            [-1.242621363633027, 52.879119570957705],
            [-1.240060507800162, 52.880089373552394],
            [-1.238452683959206, 52.881787095182801],
            [-1.238990277883827, 52.88350484837796],
            [-1.240692480548131, 52.884914513430289],
            [-1.245242592200935, 52.886992222336232],
            [-1.246249840008081, 52.888214901820014],
            [-1.246403607584646, 52.88911842741468],
            [-1.245861493762977, 52.890296213941944],
            [-1.243863749644406, 52.891500702088408],
            [-1.241286719100534, 52.892420086669738],
            [-1.24125377343307, 52.892093555932234],
            [-1.239710810576362, 52.89226977702365],
            [-1.235741066198828, 52.892333307269844],
            [-1.233461365556395, 52.892900246442444],
            [-1.230819311072959, 52.894032936231426],
            [-1.230256252367659, 52.894687324844078],
            [-1.227751272547646, 52.895540360651069],
            [-1.222551536078493, 52.895290705340798],
            [-1.220497129670771, 52.895544235915978],
            [-1.219224304015988, 52.896889705464879],
            [-1.220802438110028, 52.900403289511651],
            [-1.220168588121259, 52.902338175488929],
            [-1.214848022019624, 52.903713600404728],
            [-1.213924298773596, 52.904395193622236],
            [-1.214153958964311, 52.903288302231807],
            [-1.217503895133876, 52.90063152432252],
            [-1.216577548028319, 52.90014537925807],
            [-1.213236964395309, 52.900724709664544],
            [-1.211306069881217, 52.902592522896398],
            [-1.205009519670863, 52.900086531298477],
            [-1.204313310529129, 52.90011153712161],
            [-1.202817667434193, 52.899143226689802],
            [-1.198926339605066, 52.897738059734209],
            [-1.195888320526455, 52.897227609339637],
            [-1.195460772175265, 52.897838702335491],
            [-1.191025880264201, 52.896585113165038],
            [-1.192050623277755, 52.895037789361616],
            [-1.178208042980237, 52.891291294009207],
            [-1.178813142155562, 52.889867033075376],
            [-1.17681693875976, 52.889009098041029],
            [-1.176036979227933, 52.889540370009861],
            [-1.17493081503733, 52.889637872107727],
            [-1.17355818783153, 52.891711219309741],
            [-1.173816967099161, 52.892172386584221],
            [-1.171939279850917, 52.893363020853641],
            [-1.170906825629183, 52.894889443727529],
            [-1.171115239431906, 52.895496795368125],
            [-1.170061883234438, 52.895856217608554],
            [-1.170233189843998, 52.896771654040336],
            [-1.168721723625389, 52.89784163696671],
            [-1.167030384771828, 52.89813184262789],
            [-1.166305113474524, 52.898897163062493],
            [-1.164218104715272, 52.899273548954255],
            [-1.16411863095961, 52.899807729076819],
            [-1.163381483200999, 52.899564317672464],
            [-1.162545470847701, 52.900285685543992],
            [-1.158607567465928, 52.900042117892625],
            [-1.158666634764054, 52.905513591629202],
            [-1.1600136272462, 52.909670007816175],
            [-1.159018744768847, 52.909828379634284],
            [-1.158896337877393, 52.912770696093531],
            [-1.155497588401271, 52.930473085341681],
            [-1.15416232867671, 52.934178066677994],
            [-1.152291936752631, 52.937006333384844],
            [-1.150355554896042, 52.935821173012926],
            [-1.148002768310406, 52.932964538838782],
            [-1.146397016054299, 52.932154747536494],
            [-1.143805283276771, 52.931856532809185],
            [-1.141886837976755, 52.931969451256577],
            [-1.140064763209766, 52.93247408111376],
            [-1.139256620732693, 52.933238635702509],
            [-1.137595897312027, 52.937298860180533],
            [-1.134616694375309, 52.940748968061037],
            [-1.131603250932337, 52.94283773552025],
            [-1.131594269536141, 52.942840366771357],
            [-1.129578666995934, 52.943245436934767],
            [-1.126248375749072, 52.94338551460271],
            [-1.121391672501645, 52.942695280371751],
            [-1.117617230003467, 52.943162647756672],
            [-1.115108810495851, 52.943864064644778],
            [-1.111960434681552, 52.944563346806284],
            [-1.109165075657621, 52.944445345213857],
            [-1.103943717455321, 52.942324123672854],
            [-1.099680517283199, 52.941954803979783],
            [-1.097597780001591, 52.942570078652835],
            [-1.095334562345632, 52.944415512704381],
            [-1.090071109155892, 52.94710007551965],
            [-1.086118974698574, 52.948549440536169],
            [-1.084795127798401, 52.948160796087635],
            [-1.083156404227023, 52.948587758500508],
            [-1.079900819122153, 52.951142609024203],
            [-1.078261951624345, 52.953039295309004],
            [-1.075105799640328, 52.955439266266559],
            [-1.073833544954683, 52.955835689834579],
            [-1.07180864667228, 52.955786637091286],
            [-1.067904150888108, 52.954608123542613],
            [-1.065669993305819, 52.952736939039077],
            [-1.064624504190021, 52.951107899425459],
            [-1.063714782738273, 52.950570353853287],
            [-1.062355890983635, 52.950294451651807],
            [-1.056683806167843, 52.950698159537268],
            [-1.054058243735572, 52.951108796699657],
            [-1.051161354148963, 52.952149177843381],
            [-1.048284609151386, 52.952604428627218],
            [-1.046968115363181, 52.952500390754494],
            [-1.042920982797176, 52.951286647457863],
            [-1.041560075140274, 52.951241523143729],
            [-1.037231176830647, 52.956429491153749],
            [-1.033186291964824, 52.958849023610348],
            [-1.032904466698163, 52.962073089770378],
            [-1.03349024288054, 52.964513127495927],
            [-1.032638288048669, 52.966261861513182],
            [-1.032664942602877, 52.967202386903111],
            [-1.03360100522285, 52.969027688203468],
            [-1.036455032004245, 52.971793564119253],
            [-1.038160997208021, 52.981546650713625],
            [-1.037673006667511, 52.983399042440908],
            [-1.036390400193377, 52.984542913064026],
            [-1.034363693282396, 52.985585441176859],
            [-1.032779567737261, 52.985882706874399],
            [-1.0259456223179, 52.985799061674989],
            [-1.023930628953201, 52.985118213291734],
            [-1.022645179815134, 52.98425724482],
            [-1.021134166169282, 52.981911129891536],
            [-1.020023989909909, 52.980946396936702],
            [-1.018392108832804, 52.980330637498653],
            [-1.016556588236718, 52.980223778356276],
            [-1.013257096068207, 52.981092704621638],
            [-1.007089494901457, 52.985437262174507],
            [-1.004088771521822, 52.986114254976847],
            [-0.997727816547835, 52.985996989391545],
            [-0.992074542445468, 52.986580276893278],
            [-0.98737395105418, 52.986294928916422],
            [-0.97878143361754, 52.985613757193128],
            [-0.975966091169732, 52.986306016859558],
            [-0.970797854503824, 52.989657664148105],
            [-0.966451027151144, 52.996637229412066],
            [-0.960439522087478, 53.004243950123175],
            [-0.957401430858447, 53.005892114274182],
            [-0.954766601153893, 53.00829707098103],
            [-0.952034502588805, 53.009598093249423],
            [-0.94976410785567, 53.010353868162746],
            [-0.944435258963378, 53.0112272303829],
            [-0.938024318815758, 53.014094473570253],
            [-0.931529387441873, 53.018818762213506],
            [-0.928864322954344, 53.021089831283909],
            [-0.927007537448267, 53.023822106821491],
            [-0.924797996388367, 53.02699974447448],
            [-0.922445054787384, 53.029216856196534],
            [-0.919397387678251, 53.030999710554973],
            [-0.917422777834511, 53.031547202397249],
            [-0.915121199239934, 53.033475173622655],
            [-0.910125270753638, 53.035907934752551],
            [-0.910079339347513, 53.035067889190721],
            [-0.909278728932657, 53.034113052539446],
            [-0.910153733978661, 53.033181661852097],
            [-0.910432519452629, 53.03206051994102],
            [-0.909905556680918, 53.030131026807425],
            [-0.905772570952045, 53.027224512979863],
            [-0.903938681857468, 53.025455562536123],
            [-0.896961488655228, 53.016602850343553],
            [-0.895427019009994, 53.015515253342805],
            [-0.884302353977945, 53.011235755573693],
            [-0.881949095589384, 53.009149642477553],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000177",
        LAD13CDO: "38UB",
        LAD13NM: "Cherwell",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.32088872093528, 52.159193054186154],
            [-1.321301487982564, 52.158292772102321],
            [-1.31939042336258, 52.157654220987901],
            [-1.31992152103954, 52.156951520225284],
            [-1.31828297748025, 52.155797561006658],
            [-1.318564486614773, 52.155453947505798],
            [-1.31772499574047, 52.154528457964858],
            [-1.318068468170098, 52.154242743759525],
            [-1.317762170865996, 52.15333921465362],
            [-1.31692844200611, 52.1530916447674],
            [-1.315977917949497, 52.152263497178446],
            [-1.314379438790175, 52.151754341852666],
            [-1.314545539645815, 52.151407369886932],
            [-1.313270461054298, 52.151065504973587],
            [-1.312368386961073, 52.151274228371122],
            [-1.312401459759405, 52.149790968510793],
            [-1.310792625759933, 52.149487589018477],
            [-1.311768438152588, 52.147906439358216],
            [-1.309425762530551, 52.147620335860275],
            [-1.308369445662758, 52.146845450161869],
            [-1.308631517879619, 52.146531414949472],
            [-1.306444560587764, 52.145540399699193],
            [-1.308693321539677, 52.144332668681344],
            [-1.30844761974683, 52.143495998997423],
            [-1.30492864094827, 52.141626846268856],
            [-1.305389703433564, 52.141201607112535],
            [-1.301975048511255, 52.139782511113268],
            [-1.302126083415388, 52.139280827618322],
            [-1.299356663896287, 52.138380633638505],
            [-1.29918268146901, 52.137176651721433],
            [-1.297937024803533, 52.137134182373089],
            [-1.297700369564226, 52.135272608352338],
            [-1.295113117468599, 52.13470065738381],
            [-1.295565459567787, 52.133810587147124],
            [-1.293428535664583, 52.133038997046924],
            [-1.293640428761185, 52.132573650778681],
            [-1.290647951031165, 52.129288489586379],
            [-1.288540594934336, 52.127963162328776],
            [-1.284159578305859, 52.124045497103154],
            [-1.282216321090754, 52.122624850024046],
            [-1.281572433190936, 52.122609242519189],
            [-1.278372279665581, 52.118623020256578],
            [-1.276550623639943, 52.117294726374418],
            [-1.276844977999761, 52.117064571518831],
            [-1.272677965713372, 52.114303083782296],
            [-1.270230895717469, 52.113557025937844],
            [-1.264837239113606, 52.110753497361735],
            [-1.259935418733506, 52.108710723020245],
            [-1.257051733868502, 52.106151818589908],
            [-1.252943296959555, 52.103862903714777],
            [-1.254019117483273, 52.102475252200044],
            [-1.251863270931749, 52.102006666158978],
            [-1.251855195241168, 52.101354787389312],
            [-1.250040661301433, 52.100473859817455],
            [-1.249928822871212, 52.099743101481728],
            [-1.248813142526246, 52.099067090366319],
            [-1.248034476863137, 52.096842316409294],
            [-1.247975280897868, 52.095761252634432],
            [-1.25480954557217, 52.095605039879757],
            [-1.256294619723574, 52.095750174263443],
            [-1.258160981807847, 52.096406564227671],
            [-1.262215904581352, 52.09651561449494],
            [-1.262489276948342, 52.09693449406695],
            [-1.265388563203192, 52.097459665818434],
            [-1.269680247873329, 52.098005983554216],
            [-1.271591165674099, 52.0979162060611],
            [-1.273707641723621, 52.09739341188336],
            [-1.274342555889607, 52.096769767839355],
            [-1.274886400622041, 52.097170502654187],
            [-1.276211951159306, 52.0968064292394],
            [-1.280692476844496, 52.094146515076346],
            [-1.285172251434427, 52.093236020750687],
            [-1.285662826954758, 52.093502421336439],
            [-1.287074324281111, 52.093045243086586],
            [-1.287140824968437, 52.092627576551479],
            [-1.295161608358801, 52.093368970015113],
            [-1.295608784196898, 52.092872656941537],
            [-1.296301276354634, 52.092833635521977],
            [-1.297780815205682, 52.091938887394171],
            [-1.299842353742492, 52.091437774534839],
            [-1.303924802118785, 52.091630966243748],
            [-1.304396846618924, 52.092062608480887],
            [-1.307978623653577, 52.092261696196829],
            [-1.312193023516929, 52.090714773819926],
            [-1.316841721025568, 52.089764503695427],
            [-1.316744713729536, 52.088948484110411],
            [-1.32083992342978, 52.087617238711786],
            [-1.321276715934773, 52.08653187700488],
            [-1.320627092249525, 52.085773815422208],
            [-1.321183097837826, 52.085583716777478],
            [-1.321361917008314, 52.08485919512902],
            [-1.31949025570085, 52.08385043974377],
            [-1.320557532495759, 52.0821843197673],
            [-1.319750498232682, 52.081130449858669],
            [-1.320000034395635, 52.080746187005801],
            [-1.319521096853111, 52.080765901090025],
            [-1.318175555596539, 52.079910303596129],
            [-1.31726127779557, 52.079962554401284],
            [-1.317261979312498, 52.079725203283921],
            [-1.316100817991965, 52.079508990843131],
            [-1.313532738713435, 52.079879771381968],
            [-1.308778657205654, 52.07949506841895],
            [-1.299376325030401, 52.080090509080705],
            [-1.299631963483444, 52.078301073131314],
            [-1.297524530699253, 52.078045787577487],
            [-1.298509897732978, 52.075690685304416],
            [-1.298919492414282, 52.075344280505718],
            [-1.30190583887923, 52.07518308241486],
            [-1.302006527555943, 52.073778425557052],
            [-1.303940483882665, 52.073543511303832],
            [-1.303503931614191, 52.071968452463722],
            [-1.305486368822578, 52.072082640193983],
            [-1.306918915435732, 52.070302810153592],
            [-1.305384436620218, 52.070282991686604],
            [-1.300082667868815, 52.069352564934661],
            [-1.300669434190897, 52.067652299151561],
            [-1.301200583835759, 52.067643760284213],
            [-1.301268539833168, 52.066093258038563],
            [-1.302191352243838, 52.065008144527454],
            [-1.303615276247998, 52.064339559282452],
            [-1.307784288769379, 52.064341632047416],
            [-1.307662217318593, 52.063179309739013],
            [-1.308475817700116, 52.062351540362407],
            [-1.309102038179999, 52.060714398254099],
            [-1.307395804272126, 52.057579198519711],
            [-1.31178260160025, 52.052762460649504],
            [-1.312880596489904, 52.05115502183672],
            [-1.311350332135383, 52.050523931482331],
            [-1.30869239779141, 52.048600532803029],
            [-1.30741155854844, 52.0472192238825],
            [-1.306702824855201, 52.047317552860889],
            [-1.306165934476225, 52.046864855139283],
            [-1.302230223068137, 52.046405572535448],
            [-1.302354462424684, 52.045918108253183],
            [-1.30014817892776, 52.045366482818309],
            [-1.300413565338443, 52.04489873897262],
            [-1.299812213273854, 52.04437819842687],
            [-1.300151844913668, 52.04363757608089],
            [-1.299253378036158, 52.043190790879848],
            [-1.298587330650601, 52.043359452908959],
            [-1.298229009892937, 52.042870918549141],
            [-1.297593939056474, 52.042737668779708],
            [-1.297177387605, 52.041779464755173],
            [-1.296437205706593, 52.041470261468547],
            [-1.296462906951287, 52.040854545430612],
            [-1.294969027625228, 52.039800889589586],
            [-1.294766724981695, 52.039037259778063],
            [-1.293817538403099, 52.038580239109088],
            [-1.294615403550903, 52.037813605619988],
            [-1.293978052824934, 52.037460047783775],
            [-1.294470886114796, 52.0370844858355],
            [-1.293319325880149, 52.034301227351172],
            [-1.292085336029191, 52.032777972544686],
            [-1.291691082335638, 52.030790432996682],
            [-1.292816330488092, 52.030238860080743],
            [-1.292345813340943, 52.029666018585466],
            [-1.29309243861856, 52.029922241555369],
            [-1.293475389326338, 52.029670097632462],
            [-1.294329186367694, 52.028025397935068],
            [-1.293455484059162, 52.027510384543156],
            [-1.290206339936972, 52.02743781704995],
            [-1.285333259503992, 52.026467031371553],
            [-1.286099035440136, 52.025605855939645],
            [-1.283846277267891, 52.025027565655073],
            [-1.284471597377744, 52.023743877309229],
            [-1.283598738080495, 52.023188336379143],
            [-1.284589989629395, 52.022808650882808],
            [-1.283997179802195, 52.022604555584131],
            [-1.284198239729187, 52.021877519703914],
            [-1.283478768115932, 52.021666358820774],
            [-1.284524150239829, 52.02145423237247],
            [-1.28443679480942, 52.020900765965116],
            [-1.283782701124996, 52.020429269664547],
            [-1.282993907205001, 52.020727464608669],
            [-1.282976497584243, 52.01990200027091],
            [-1.281897683549268, 52.019655376761882],
            [-1.282847936589358, 52.01919274036257],
            [-1.281128680107462, 52.018536724240306],
            [-1.280998340558748, 52.018122350676258],
            [-1.281690254916332, 52.017775028094768],
            [-1.280227755402768, 52.017014471226481],
            [-1.280649628678708, 52.016513559997833],
            [-1.278180962705917, 52.014262443542151],
            [-1.278648762419494, 52.011357668243136],
            [-1.279410472086784, 52.011285906010649],
            [-1.279936283240936, 52.011568735290481],
            [-1.280040845485526, 52.011045207717189],
            [-1.282174095552971, 52.011305469165023],
            [-1.284025665951429, 52.01114051211745],
            [-1.284587340854832, 52.010637738537717],
            [-1.283729341345396, 52.009349532833731],
            [-1.279792165445123, 52.008746526100126],
            [-1.278480967285424, 52.008200852498611],
            [-1.278508874205307, 52.00782430660847],
            [-1.277631221217189, 52.007228230868471],
            [-1.277082068962492, 52.00532329719578],
            [-1.277579616595318, 52.004732052696198],
            [-1.278833784779045, 52.004377403045389],
            [-1.280557372696766, 52.003182264340595],
            [-1.28059732943832, 52.000961765559857],
            [-1.281636599122661, 52.000283001996522],
            [-1.281893675182501, 52.000512037752003],
            [-1.282720833329367, 52.000343551941171],
            [-1.283418256217836, 51.999450506971272],
            [-1.283722729405752, 51.998078553997232],
            [-1.282432603675998, 51.996321980934326],
            [-1.283515932035731, 51.996159544765881],
            [-1.284687705391447, 51.994833318011302],
            [-1.282352146238201, 51.993063199096909],
            [-1.28325457011276, 51.991911566362369],
            [-1.283013670534794, 51.991492924099013],
            [-1.28389469565515, 51.991314867997367],
            [-1.284089896395997, 51.990672307434096],
            [-1.285444485608846, 51.990659851018705],
            [-1.286101962292398, 51.990342862127136],
            [-1.286935028374157, 51.990614935624535],
            [-1.287183749077939, 51.990267594586662],
            [-1.288875992251637, 51.989715890238266],
            [-1.288452345939658, 51.989247605219795],
            [-1.287345548005475, 51.989524128737642],
            [-1.286847999016163, 51.989308933630234],
            [-1.287363354348531, 51.988677295761114],
            [-1.287392834834154, 51.987645321147319],
            [-1.285005842627058, 51.983542705329434],
            [-1.284306121299263, 51.983592402292643],
            [-1.284467899482169, 51.982577414542547],
            [-1.283463591386023, 51.982005783975048],
            [-1.282476441652024, 51.98063495891337],
            [-1.283522307799855, 51.979693683650815],
            [-1.282743676618638, 51.979217823090295],
            [-1.281593148839651, 51.979966048245004],
            [-1.2812162628353, 51.982227656272244],
            [-1.28088225543436, 51.982354187832918],
            [-1.270262437550335, 51.982937133273758],
            [-1.267670013550591, 51.982616256486573],
            [-1.260933059534499, 51.980916266042414],
            [-1.259435073078959, 51.981253908363385],
            [-1.257278280939205, 51.982511645134998],
            [-1.254698944341118, 51.982900846363371],
            [-1.254538138889412, 51.982675955527149],
            [-1.252804714955128, 51.983330301729268],
            [-1.251988113848944, 51.98410103493719],
            [-1.251189645246662, 51.984219138215941],
            [-1.250168305782046, 51.985301440756928],
            [-1.249025765585441, 51.985704148899558],
            [-1.248218862833612, 51.985627974860051],
            [-1.247396981639391, 51.985055401779043],
            [-1.241630257982553, 51.986283444181424],
            [-1.239307383177723, 51.984578179178371],
            [-1.236372025328878, 51.98370865028695],
            [-1.234460375624861, 51.983524517988251],
            [-1.232112819256228, 51.982350311953219],
            [-1.230398769598572, 51.9820253447635],
            [-1.229718595968225, 51.981357370009974],
            [-1.227127449886591, 51.981237903749317],
            [-1.223662063971767, 51.980396945455745],
            [-1.221058271822686, 51.980171169221251],
            [-1.217240893252027, 51.980323888108607],
            [-1.206916484140252, 51.978255255093714],
            [-1.203553531845017, 51.978568828798977],
            [-1.200660189130212, 51.977935151800374],
            [-1.198019155323124, 51.977281543056527],
            [-1.196278296102878, 51.977421626057144],
            [-1.194795032435208, 51.979028967531384],
            [-1.190774266538654, 51.98150089869651],
            [-1.188358151773562, 51.981208256157295],
            [-1.184744192555593, 51.981693988188312],
            [-1.1830211627938, 51.981482453239181],
            [-1.181007302916188, 51.981810126343142],
            [-1.180125755283214, 51.981685314280988],
            [-1.176710903690291, 51.98250215319117],
            [-1.176072963460917, 51.982035558866116],
            [-1.174379362658911, 51.983313902764529],
            [-1.172201506411524, 51.985974324862688],
            [-1.163841556503351, 51.993402044230805],
            [-1.156668895216057, 51.992224358037397],
            [-1.156127680004608, 51.993434259691313],
            [-1.151498167696973, 51.993099814113471],
            [-1.148740749939509, 51.995041747323775],
            [-1.143924500222191, 51.9970091343543],
            [-1.14019090861738, 51.997640983980894],
            [-1.134346695922037, 51.997286205781016],
            [-1.133761027958317, 51.999633033126763],
            [-1.131547150498718, 52.003085441042764],
            [-1.131270704482194, 52.004874397767438],
            [-1.128586593922259, 52.005509118348066],
            [-1.12814369730954, 52.005360188953347],
            [-1.126984121547192, 52.006031318151628],
            [-1.126345433646086, 52.007034468283834],
            [-1.125362325111862, 52.007094607111071],
            [-1.123147818217707, 52.011347043935992],
            [-1.122507076037193, 52.012223385443328],
            [-1.121955325660133, 52.012124870740429],
            [-1.121447244372604, 52.012400701715279],
            [-1.119843015258753, 52.015669520036553],
            [-1.118103786674718, 52.015415560121625],
            [-1.116234592675029, 52.015176783509268],
            [-1.116440123779833, 52.015395006107276],
            [-1.115643403986487, 52.015599417434252],
            [-1.11516648880017, 52.016507517481024],
            [-1.1119970943094, 52.016581678216134],
            [-1.112245587195939, 52.016248188824378],
            [-1.111611259547707, 52.016197554715134],
            [-1.111847641938497, 52.015666174375497],
            [-1.110366960130349, 52.015676583637543],
            [-1.109806879819561, 52.015115815341083],
            [-1.109091801752395, 52.015243477127839],
            [-1.107283511038484, 52.014656170488664],
            [-1.106554639708179, 52.01423256806163],
            [-1.106746344761415, 52.013752107227873],
            [-1.106327131517358, 52.013437842318275],
            [-1.105076237413147, 52.013400477162506],
            [-1.103433342650817, 52.012709172856113],
            [-1.102895165559725, 52.012079307457242],
            [-1.102000044025451, 52.012468090830261],
            [-1.100139716310786, 52.01252402855539],
            [-1.09934106291613, 52.013622014599584],
            [-1.097624476982965, 52.014425259412519],
            [-1.097718613547503, 52.014816187050201],
            [-1.096758817596494, 52.015083057802642],
            [-1.095936574986478, 52.014958971131811],
            [-1.094581504640499, 52.014305714909611],
            [-1.093755573278444, 52.013355316849335],
            [-1.094579348865212, 52.013040672586129],
            [-1.093858176199418, 52.012820245568236],
            [-1.094098390968749, 52.012541575023782],
            [-1.093687232578487, 52.012482669561336],
            [-1.094139961362069, 52.012214624173048],
            [-1.092819361966558, 52.011086889981819],
            [-1.093416422403095, 52.010669809576946],
            [-1.091434734781862, 52.010318284932715],
            [-1.090529551354193, 52.009331288285445],
            [-1.090909140074408, 52.008861293331535],
            [-1.089925143436305, 52.008314240376762],
            [-1.089132187164188, 52.006682548476974],
            [-1.089579424488927, 52.0063245684265],
            [-1.088079895059875, 52.005771716987304],
            [-1.086462224963153, 52.005871573237634],
            [-1.086206915599182, 52.006390170116909],
            [-1.085359073285693, 52.006173203439118],
            [-1.085293525091314, 52.006601563405965],
            [-1.082822645223493, 52.006603035487181],
            [-1.080995620228694, 52.007248736562623],
            [-1.080912700300042, 52.007811823066575],
            [-1.080100924779324, 52.007683211332044],
            [-1.080138528509239, 52.008194191472413],
            [-1.078679380851, 52.008586485653012],
            [-1.077666120939526, 52.008265673222489],
            [-1.077172624537924, 52.008812955275708],
            [-1.076693459623065, 52.008238275529955],
            [-1.075800522651136, 52.008301404768488],
            [-1.075582384354052, 52.007503993335582],
            [-1.073946126660105, 52.006311530274587],
            [-1.069869961602402, 52.007218980286794],
            [-1.067409072586188, 52.008210113675176],
            [-1.064607955469558, 52.008603293312937],
            [-1.063430703914163, 52.008396140587074],
            [-1.060865145188965, 52.008600494821749],
            [-1.058952173998911, 52.007906414065303],
            [-1.058454126368415, 52.006715626650561],
            [-1.059042262803709, 52.005208042558699],
            [-1.057552887696156, 52.003219921666442],
            [-1.055391797401623, 52.002769250554564],
            [-1.054802191132689, 52.003166419147199],
            [-1.05318995835434, 52.002525903949063],
            [-1.057892227613672, 51.99940686164927],
            [-1.062252944504754, 51.994826575371],
            [-1.078204714807735, 51.981047891898051],
            [-1.079416160449268, 51.979697026364263],
            [-1.078442997415884, 51.979369337646204],
            [-1.079729120105452, 51.975934038218291],
            [-1.082163127404372, 51.97280887199949],
            [-1.08419631620005, 51.972334686529884],
            [-1.083784707004537, 51.970317498705008],
            [-1.084938938816914, 51.968465330645977],
            [-1.085763464340958, 51.968433970834546],
            [-1.087189528612891, 51.966879694578978],
            [-1.08770096829544, 51.966996943700934],
            [-1.088812470496076, 51.964799151256329],
            [-1.091411910797457, 51.962644303685266],
            [-1.093543285498658, 51.960160322325464],
            [-1.095254979463382, 51.957113837057577],
            [-1.088871967393981, 51.955617075529247],
            [-1.089544837993562, 51.954008388183517],
            [-1.086191057422323, 51.952343347945281],
            [-1.083804084625688, 51.951872553832487],
            [-1.081930473947324, 51.950990327768366],
            [-1.081068419731367, 51.950239146045334],
            [-1.078833424263266, 51.946690001226401],
            [-1.080427860999094, 51.944705548838051],
            [-1.078971492987936, 51.94335809672009],
            [-1.077531642929076, 51.943124747785106],
            [-1.076040027459404, 51.943287480817801],
            [-1.072847756953836, 51.944789073653176],
            [-1.070944885778272, 51.946122736250011],
            [-1.070134669226614, 51.945833123341693],
            [-1.066630456260107, 51.946853760263451],
            [-1.064876516124489, 51.948219074991457],
            [-1.063521462979077, 51.948241574541946],
            [-1.059285867585035, 51.94929213964874],
            [-1.056517043000988, 51.949219640447069],
            [-1.055038979884023, 51.948724070721731],
            [-1.054636207097977, 51.947950303905479],
            [-1.054807223864099, 51.946528390647735],
            [-1.055865348161724, 51.94595515628437],
            [-1.0559388852556, 51.942590387944314],
            [-1.05871950283374, 51.940063676967391],
            [-1.059142001541722, 51.939129284102485],
            [-1.060565849857734, 51.938167817106184],
            [-1.060850116279005, 51.937023722104442],
            [-1.062131861471568, 51.935128727721185],
            [-1.064172778998497, 51.93463067526239],
            [-1.070053710052319, 51.934197142076208],
            [-1.077569388084032, 51.93163535753569],
            [-1.077508649858122, 51.931334579748309],
            [-1.075042235357657, 51.93068315871011],
            [-1.075555112761267, 51.929930137408405],
            [-1.078085269758717, 51.927468435856923],
            [-1.081520672994168, 51.928022160645121],
            [-1.082137936841207, 51.927705992337529],
            [-1.081269933357642, 51.927552668357691],
            [-1.082651997668415, 51.925674417617984],
            [-1.082606975603088, 51.923031587091607],
            [-1.085984234016164, 51.923140570261715],
            [-1.086693882730968, 51.922638080662601],
            [-1.086349720052531, 51.920393932696356],
            [-1.085062050206672, 51.919861551292755],
            [-1.083369239805388, 51.918161655778142],
            [-1.081201638290723, 51.917433564800135],
            [-1.079377013449097, 51.917214314732711],
            [-1.080058041469487, 51.91611912755242],
            [-1.081882795664738, 51.915688311883706],
            [-1.082656815208358, 51.915804935700159],
            [-1.082298167043667, 51.911929673774054],
            [-1.082732814828566, 51.911860233028996],
            [-1.083565568478577, 51.910016348935784],
            [-1.085583048025837, 51.907454285880938],
            [-1.086299554611443, 51.905311872930127],
            [-1.085024339812202, 51.904616846014925],
            [-1.085840267845735, 51.902874413103255],
            [-1.085399381567401, 51.901895452588768],
            [-1.086019278123817, 51.900857297441348],
            [-1.084274359549569, 51.899537327374162],
            [-1.087079797711212, 51.897439010748755],
            [-1.091336134479979, 51.89753488010841],
            [-1.092925666689624, 51.895678784172986],
            [-1.092921523638644, 51.894657360766416],
            [-1.093539265587296, 51.893929342732697],
            [-1.092638356487353, 51.893419797982915],
            [-1.090624772740088, 51.892960098048654],
            [-1.090038369125994, 51.892350463540559],
            [-1.085809917850437, 51.891119182337725],
            [-1.083146417238562, 51.890757702481501],
            [-1.082057721581396, 51.890915553565677],
            [-1.080890553952807, 51.890360683926076],
            [-1.078734447615635, 51.890132537059749],
            [-1.077289168731308, 51.889466661650893],
            [-1.076528182823147, 51.888594847006829],
            [-1.077277459472927, 51.888051364252064],
            [-1.076829820619249, 51.887772725353223],
            [-1.076103662014631, 51.887826369456938],
            [-1.075479505100823, 51.888290806418368],
            [-1.073562609814255, 51.888095012822482],
            [-1.071813619494262, 51.888778047836212],
            [-1.070437550949773, 51.888708746380047],
            [-1.069997290000066, 51.887581375433463],
            [-1.06899563351367, 51.887255170436063],
            [-1.068626517706495, 51.886554537151376],
            [-1.069653906975099, 51.88605556128843],
            [-1.070013537400716, 51.885455996218361],
            [-1.07144022779482, 51.885108512984338],
            [-1.069820977788508, 51.884296414343304],
            [-1.069711691897631, 51.883115012216678],
            [-1.070524676466096, 51.882652096785534],
            [-1.070185379915165, 51.882056000691669],
            [-1.070969466959967, 51.881866185014424],
            [-1.07105687641294, 51.881290541841743],
            [-1.071971621968979, 51.881386770285843],
            [-1.072870071269599, 51.881074664804672],
            [-1.074152856817011, 51.880015713986147],
            [-1.076556591676106, 51.878768642977256],
            [-1.076586521845763, 51.87759103618469],
            [-1.077625515775584, 51.876723444388951],
            [-1.076119202454378, 51.876505732349692],
            [-1.076158348053524, 51.875445981750843],
            [-1.074587273432613, 51.8755550198658],
            [-1.071777586326182, 51.874863951623801],
            [-1.069186448001306, 51.874637589730007],
            [-1.064662942675141, 51.868690030208526],
            [-1.064568454891145, 51.868203755538794],
            [-1.065613206383337, 51.868180585957383],
            [-1.064472021986507, 51.866340915415883],
            [-1.065584255183751, 51.866002692148363],
            [-1.065156574214511, 51.86519548381645],
            [-1.066147732909023, 51.864881468729465],
            [-1.064536427640283, 51.86106450013331],
            [-1.064535565855931, 51.859985550352775],
            [-1.063500441219031, 51.858574691059779],
            [-1.064316986504555, 51.857641606612113],
            [-1.063140778535827, 51.85631503790286],
            [-1.063463713550746, 51.856146775719751],
            [-1.063065563138352, 51.855741699698711],
            [-1.063520383006341, 51.855515145450582],
            [-1.063103896237015, 51.854315101435844],
            [-1.06603468398513, 51.853226188035308],
            [-1.066052008360509, 51.852810032912949],
            [-1.061617868229546, 51.84862261624744],
            [-1.063587831108497, 51.847786842427013],
            [-1.062218643609355, 51.846538747324473],
            [-1.062027150248022, 51.844157246277817],
            [-1.05577615749432, 51.841812688844733],
            [-1.051603295541081, 51.839862197479668],
            [-1.049763094930082, 51.839779915604822],
            [-1.047298322279376, 51.840131321380554],
            [-1.047356693866247, 51.839703811352727],
            [-1.048350329086505, 51.839595865512379],
            [-1.049017860545941, 51.838982666058548],
            [-1.059237860376602, 51.835415287351132],
            [-1.061927882684063, 51.832377948840261],
            [-1.066343633039737, 51.833168337870092],
            [-1.067435816084358, 51.834603908206695],
            [-1.06860940242138, 51.834629388873076],
            [-1.071627862874649, 51.83393664480419],
            [-1.07213543317831, 51.831271153147497],
            [-1.072899051940905, 51.830479650927472],
            [-1.075939295786445, 51.829343700737603],
            [-1.078021129489395, 51.829228762001406],
            [-1.080886475282279, 51.829546094362712],
            [-1.086216964023746, 51.831318427715324],
            [-1.08832529871446, 51.83363204050562],
            [-1.089907865495906, 51.834174774635059],
            [-1.092277468478339, 51.834481698403394],
            [-1.097348751384496, 51.837437462858702],
            [-1.100297156625733, 51.838499428765715],
            [-1.102338952240157, 51.8401972776607],
            [-1.103882888947015, 51.840658606479934],
            [-1.108447622624458, 51.841332557179221],
            [-1.112852420022443, 51.84143598210089],
            [-1.117117781603377, 51.843771606679226],
            [-1.12120263639718, 51.845335182300062],
            [-1.12161925268179, 51.845335595710893],
            [-1.12415929671538, 51.842585245869373],
            [-1.125191171724264, 51.842070534425702],
            [-1.127110085593451, 51.841171281342483],
            [-1.129138421086996, 51.839110246775967],
            [-1.13301807119612, 51.838282937038898],
            [-1.140696391065761, 51.834683449047901],
            [-1.140604080216895, 51.834348302704278],
            [-1.137857921400562, 51.833527996259605],
            [-1.133757318818809, 51.831751787385095],
            [-1.130510347219299, 51.831104746416763],
            [-1.130133585431434, 51.829720014298076],
            [-1.127451464251486, 51.829064475345263],
            [-1.126491160373685, 51.828521473737993],
            [-1.123244841644621, 51.825316237142282],
            [-1.123417423150491, 51.824573050974962],
            [-1.119028562598183, 51.820759461307311],
            [-1.117474107292694, 51.820417832600768],
            [-1.114259510019367, 51.818246569113867],
            [-1.11011732498391, 51.817299102235587],
            [-1.113463242364539, 51.815869584376522],
            [-1.116065859702794, 51.815199542260409],
            [-1.117244191964543, 51.814214859702929],
            [-1.11792908495158, 51.811083855670688],
            [-1.120398267400783, 51.810246379628239],
            [-1.122502019973679, 51.809922223229592],
            [-1.12295221709884, 51.810193519451396],
            [-1.124064379861277, 51.808043011191778],
            [-1.124910250682246, 51.807505337260665],
            [-1.120807954168959, 51.804322418254245],
            [-1.119892801102017, 51.800224557395467],
            [-1.117426456869783, 51.79916129358147],
            [-1.121254509538501, 51.796868414197462],
            [-1.123561598157276, 51.794175664210783],
            [-1.123808067301165, 51.794551536813955],
            [-1.125933604324854, 51.794636584663088],
            [-1.125226771508457, 51.795795697056221],
            [-1.125026909912296, 51.798256014981916],
            [-1.126838826742924, 51.79949678512596],
            [-1.132431358308901, 51.797120422525545],
            [-1.136827514448098, 51.796009979505278],
            [-1.142138870358147, 51.797704125945188],
            [-1.142603913723381, 51.797268744266574],
            [-1.146242935385756, 51.797769053064883],
            [-1.149322737779059, 51.797320226872323],
            [-1.151616064797659, 51.796586921639182],
            [-1.153551135936203, 51.796396762204751],
            [-1.154096386728779, 51.796628163100884],
            [-1.153143138801185, 51.797431413957156],
            [-1.152207364288311, 51.799775880374781],
            [-1.150770081943034, 51.801173538954501],
            [-1.151604494996506, 51.802690985936295],
            [-1.153948685841497, 51.804599624201671],
            [-1.159659426128448, 51.807212049152881],
            [-1.164353952614152, 51.810063338392624],
            [-1.167105456693996, 51.809676449626721],
            [-1.167171397532828, 51.81089972079301],
            [-1.165695399684368, 51.811807264076947],
            [-1.167430361709089, 51.813771546498856],
            [-1.170474039357149, 51.813046778650772],
            [-1.172072395634315, 51.812345935481858],
            [-1.187336463954299, 51.812129589201774],
            [-1.186624999434349, 51.808620802388553],
            [-1.188500585093664, 51.808855838688906],
            [-1.188039642856134, 51.806496963322942],
            [-1.188505641298304, 51.80493840689131],
            [-1.188357346322553, 51.803585106380538],
            [-1.191534082753866, 51.800228087071972],
            [-1.190698688770261, 51.798531095279259],
            [-1.194538592046698, 51.796988486037691],
            [-1.198194987365283, 51.7981751319954],
            [-1.202519616500354, 51.797991440342805],
            [-1.203834805202978, 51.798572190580884],
            [-1.205831651139063, 51.800553864172713],
            [-1.208360865083832, 51.798148683010879],
            [-1.210466779907835, 51.800953706377982],
            [-1.210376726577099, 51.802456430288473],
            [-1.215852190623707, 51.80308467905401],
            [-1.221359230501997, 51.801551398943097],
            [-1.222866029749182, 51.802479364139224],
            [-1.221895357128971, 51.802757968077188],
            [-1.222086144687159, 51.803638568366289],
            [-1.221134694130734, 51.805072660935075],
            [-1.224592291984571, 51.806935099805187],
            [-1.225407671324013, 51.806412689195959],
            [-1.226068121007439, 51.80663642110207],
            [-1.23092074246073, 51.809388977549204],
            [-1.236617025636775, 51.809633776162059],
            [-1.236600444158834, 51.808296680913301],
            [-1.239365202569173, 51.808371234534263],
            [-1.239420773544355, 51.809464920497533],
            [-1.241982245132365, 51.80998945464848],
            [-1.244769934118769, 51.80791693714022],
            [-1.243670533513583, 51.806527930474438],
            [-1.243726940744975, 51.805497005298413],
            [-1.242812551785708, 51.804583913350889],
            [-1.243555056358454, 51.803721041655209],
            [-1.245422952491207, 51.802770982270516],
            [-1.245105594571296, 51.80197412574298],
            [-1.246249590890971, 51.801467166180913],
            [-1.248248048418413, 51.799545951648611],
            [-1.250237305501404, 51.798869024173271],
            [-1.252502465279377, 51.79869192824669],
            [-1.253827439392594, 51.797840783589706],
            [-1.253349680136889, 51.796388373043975],
            [-1.248909647803933, 51.793851563614574],
            [-1.247414603488556, 51.793596547889827],
            [-1.246367552875445, 51.792997324263808],
            [-1.244365007658467, 51.790662949506299],
            [-1.244110111287189, 51.789431315596183],
            [-1.246846156027709, 51.788375317984368],
            [-1.247046483736407, 51.787401954370587],
            [-1.247925063819577, 51.78661634973767],
            [-1.252722891277693, 51.786493181108071],
            [-1.252499467305479, 51.787029435152782],
            [-1.252968771093919, 51.787318336964589],
            [-1.253048912828485, 51.788874323218316],
            [-1.258112613903881, 51.793504466796584],
            [-1.26145869233616, 51.792556273928525],
            [-1.261837521234412, 51.792798716629648],
            [-1.263693487229578, 51.792351798853147],
            [-1.263973808675612, 51.793213110660815],
            [-1.266107229115463, 51.792823636003789],
            [-1.266499001620733, 51.793878947364547],
            [-1.267682616610926, 51.793763143149135],
            [-1.26866311922334, 51.795626821478933],
            [-1.271258149841704, 51.794760892632134],
            [-1.271573061435627, 51.794744860857065],
            [-1.271553512738356, 51.7952383555183],
            [-1.277015811888731, 51.79460131416316],
            [-1.27723635555241, 51.795227556274618],
            [-1.280060471821128, 51.795079437618881],
            [-1.279716901056991, 51.795984543772882],
            [-1.282878287164415, 51.795876185042211],
            [-1.283668193306721, 51.796265820209335],
            [-1.284557233789813, 51.795064615637507],
            [-1.28465989761557, 51.793982702724911],
            [-1.285697643424278, 51.793643748064717],
            [-1.286433721580326, 51.792389451199803],
            [-1.289232733905538, 51.790503865088908],
            [-1.289626244342836, 51.790558391141587],
            [-1.292340707964194, 51.789057042805908],
            [-1.292232049459165, 51.78886667515723],
            [-1.297285853301901, 51.786365061059826],
            [-1.300207294175641, 51.784102315382718],
            [-1.302837587537705, 51.780959332698998],
            [-1.303989336600057, 51.781567664330062],
            [-1.304823092349668, 51.78315594258185],
            [-1.304224877945009, 51.784161215841188],
            [-1.305402213277479, 51.785281282493791],
            [-1.303370169172104, 51.78591123490488],
            [-1.305141188854609, 51.786702144973894],
            [-1.304046656474691, 51.787642444623515],
            [-1.305281250211952, 51.788438269056904],
            [-1.305200053658909, 51.788816317847377],
            [-1.305404776854599, 51.788513625321755],
            [-1.305630762983798, 51.788714563659909],
            [-1.304811225522912, 51.789494652157273],
            [-1.305003786835341, 51.789795196112593],
            [-1.305804432683746, 51.789679442415093],
            [-1.306326966523025, 51.789073822843015],
            [-1.306509711657617, 51.790109784229074],
            [-1.313185586716856, 51.788249120399414],
            [-1.314973220750949, 51.788151650982527],
            [-1.316347999681294, 51.787167923518794],
            [-1.317778209150401, 51.787599711675895],
            [-1.317432622621406, 51.78830800907631],
            [-1.317841781726, 51.788575622165773],
            [-1.320389188990193, 51.787894451915079],
            [-1.322360363076025, 51.787833895231834],
            [-1.324668841251342, 51.791787998639137],
            [-1.325936552169922, 51.791852813496554],
            [-1.325890122705691, 51.796816564379697],
            [-1.326835955671683, 51.800882383346526],
            [-1.326420636036965, 51.805008738491637],
            [-1.325649745610838, 51.805351380029734],
            [-1.333136961903587, 51.809545266193886],
            [-1.335530149400784, 51.81132916023509],
            [-1.337341769312503, 51.811732290350726],
            [-1.336041698767689, 51.813140163355705],
            [-1.334074020521184, 51.814465123078747],
            [-1.332391704863207, 51.816989286522855],
            [-1.338358767103717, 51.819946005385006],
            [-1.332987728278761, 51.82005144463497],
            [-1.331162627433153, 51.820441188283176],
            [-1.328986000734061, 51.821834105830462],
            [-1.328207907346544, 51.823423789802767],
            [-1.326241586507131, 51.82548769451801],
            [-1.326712399260315, 51.82660978238507],
            [-1.33255930050041, 51.831081101140398],
            [-1.337100992204908, 51.830224748578999],
            [-1.342018210520834, 51.830175024376665],
            [-1.340584301503638, 51.832274509654432],
            [-1.340363338525382, 51.833989670336436],
            [-1.337818841928873, 51.834315242190058],
            [-1.339455191631449, 51.835553520890123],
            [-1.338342043577558, 51.835651563692686],
            [-1.338556109562756, 51.8365932348215],
            [-1.337483041057908, 51.83672745916094],
            [-1.337634436667211, 51.837389156228753],
            [-1.336725892738728, 51.837585439026952],
            [-1.336736628914821, 51.837842644571523],
            [-1.336955345911474, 51.838268256327488],
            [-1.341378367216324, 51.840417687099013],
            [-1.340814728330406, 51.840449596375102],
            [-1.340551036459035, 51.840913856866486],
            [-1.339882565661944, 51.845236614506568],
            [-1.337012557246933, 51.849891341583032],
            [-1.337046048062581, 51.851861472560103],
            [-1.338770487278402, 51.85456129916863],
            [-1.339192736057462, 51.856165877112957],
            [-1.334011920130763, 51.862471770848046],
            [-1.328201942190502, 51.860603678567244],
            [-1.326476757966939, 51.860510202021956],
            [-1.324578697175891, 51.859646073784049],
            [-1.323051102293064, 51.861290752734739],
            [-1.316425460787082, 51.860393812019055],
            [-1.311160766709208, 51.860443137511886],
            [-1.30960020013905, 51.861741293935843],
            [-1.307519842501317, 51.862743263974927],
            [-1.304569582104173, 51.861667620994766],
            [-1.302137714980828, 51.861728751670171],
            [-1.298070201667971, 51.861075189016482],
            [-1.296763000594969, 51.861092560648842],
            [-1.295742493776742, 51.861907343040741],
            [-1.294548491608124, 51.863970834782066],
            [-1.299431487031234, 51.866459055687663],
            [-1.30133019578043, 51.868206514527195],
            [-1.302306384091585, 51.868475742886339],
            [-1.302131021712789, 51.869108572522983],
            [-1.296229699937709, 51.872659067180471],
            [-1.294311820551046, 51.872973060565798],
            [-1.294064279133385, 51.872084159483684],
            [-1.293022017992725, 51.87204373796925],
            [-1.291778052234412, 51.87263866026926],
            [-1.288147054041241, 51.873540138223298],
            [-1.286150464963538, 51.875150032362306],
            [-1.288689790827821, 51.881676719309894],
            [-1.293930079976427, 51.884088194761503],
            [-1.294047363356942, 51.884763225641436],
            [-1.295378762149666, 51.885193780892834],
            [-1.296528302604619, 51.886106951781137],
            [-1.298009706109586, 51.888385130184695],
            [-1.298112359839787, 51.889630999127107],
            [-1.297177234700502, 51.889309835135542],
            [-1.297198183184981, 51.890762010427771],
            [-1.296208397460962, 51.890869384287086],
            [-1.289998947697216, 51.89356536987173],
            [-1.291350962241378, 51.896672719859943],
            [-1.292247040339031, 51.896433554518509],
            [-1.293895732492737, 51.897225672520825],
            [-1.294930252969389, 51.896962142175411],
            [-1.297016365891433, 51.897294697015603],
            [-1.300762509036719, 51.898637785056771],
            [-1.301490607032971, 51.899483665378774],
            [-1.302241409819126, 51.89980370174743],
            [-1.30277998063272, 51.900596302614112],
            [-1.303900583045825, 51.903981751723201],
            [-1.302206842399366, 51.90616463022365],
            [-1.300375848331288, 51.906691426241231],
            [-1.298806843484141, 51.908687983861746],
            [-1.298396802951554, 51.91009442989629],
            [-1.2986796751505, 51.912000406898279],
            [-1.299232822947517, 51.912702300023518],
            [-1.298936586156577, 51.913147388708524],
            [-1.299681837883758, 51.913744329516994],
            [-1.301949065589554, 51.914356591681482],
            [-1.306409494020403, 51.916340286140297],
            [-1.305287411514009, 51.91744855281032],
            [-1.306143333235687, 51.918705143333369],
            [-1.30624199588435, 51.919756769598365],
            [-1.306860378128801, 51.920013057524073],
            [-1.311762845757574, 51.920811439531043],
            [-1.314431341268887, 51.92193918811143],
            [-1.318041535963544, 51.921770441223231],
            [-1.324738407962521, 51.922496841834068],
            [-1.32513801835663, 51.923194133877352],
            [-1.325246566001921, 51.926721009453459],
            [-1.330557014595555, 51.927342022382859],
            [-1.329478541301, 51.930523178492606],
            [-1.330286735865574, 51.932214478607463],
            [-1.330190831098961, 51.93347895894054],
            [-1.340008783140553, 51.934951359214054],
            [-1.338349527555154, 51.937696871647724],
            [-1.337713948975497, 51.938086203114082],
            [-1.336453869120929, 51.938111478040874],
            [-1.332482602709395, 51.942140351004646],
            [-1.339087117243183, 51.941948327842745],
            [-1.340620468373407, 51.942143933266323],
            [-1.343305030876954, 51.941210389166258],
            [-1.345284307678581, 51.941152177982204],
            [-1.344581011283569, 51.942695604359216],
            [-1.344953467590107, 51.944191967423819],
            [-1.346433196554941, 51.945081297519309],
            [-1.347890592583238, 51.945407653755183],
            [-1.348357876353858, 51.94579055565616],
            [-1.349607942487255, 51.945868493135023],
            [-1.352466091747219, 51.947027884707182],
            [-1.356288727168784, 51.946784504649401],
            [-1.361874960547697, 51.948365832435229],
            [-1.360776334918435, 51.951446453034187],
            [-1.360750577142132, 51.953770460998946],
            [-1.361175651875679, 51.956216492702936],
            [-1.361794404689641, 51.956913943612065],
            [-1.361856486577564, 51.958486786982228],
            [-1.360634507354171, 51.961012894586922],
            [-1.361981463939036, 51.964652514640022],
            [-1.361627079607139, 51.966136787394312],
            [-1.362157803306004, 51.967816460792349],
            [-1.361412633743756, 51.968527198652382],
            [-1.36305829374084, 51.968572070865555],
            [-1.36322570257942, 51.967851907610424],
            [-1.364963475024282, 51.96745400091654],
            [-1.365158786767565, 51.968259736898034],
            [-1.365636111793112, 51.968267702590637],
            [-1.36598378072253, 51.969315212733918],
            [-1.367167754338781, 51.97041396932741],
            [-1.366988718484744, 51.970723192535516],
            [-1.368182256069848, 51.971663750645043],
            [-1.368262660060805, 51.972985839302439],
            [-1.368995983625525, 51.973842103181205],
            [-1.370254957921635, 51.973758031531865],
            [-1.371849462856902, 51.97476902474861],
            [-1.37314181568133, 51.974902679009794],
            [-1.37460671516797, 51.975923733875199],
            [-1.37493245361889, 51.975741149847551],
            [-1.375572273410782, 51.976319058410439],
            [-1.375101386185466, 51.976784086463596],
            [-1.375786153297367, 51.978319758255054],
            [-1.377334613171783, 51.979627130024966],
            [-1.382968607125612, 51.979989430756838],
            [-1.384165823604692, 51.980278906065038],
            [-1.384169826967842, 51.980624175569268],
            [-1.396556564197544, 51.978632957914215],
            [-1.399793623231929, 51.979139493171772],
            [-1.400357546675225, 51.979540658836996],
            [-1.401325417707386, 51.979894425265819],
            [-1.402104041120364, 51.979809369529171],
            [-1.404086213540087, 51.978447408574347],
            [-1.406697939227746, 51.977912147278353],
            [-1.406392927253848, 51.975322146610033],
            [-1.406943754022082, 51.97484121287625],
            [-1.408820432455377, 51.974830864323486],
            [-1.409563396038096, 51.972687575217556],
            [-1.41000485046167, 51.972770704322812],
            [-1.410639858020701, 51.973746692323189],
            [-1.410379345353609, 51.97607132089194],
            [-1.412547999688463, 51.978564524930214],
            [-1.415205578201606, 51.979197213739823],
            [-1.418102641331667, 51.980905430117851],
            [-1.42145135064641, 51.981931590510499],
            [-1.42234060899088, 51.982640834536618],
            [-1.423136554299918, 51.982794880760942],
            [-1.423141886247998, 51.98317342077771],
            [-1.426594570982034, 51.982920548268019],
            [-1.426918905848265, 51.984205117139268],
            [-1.426220163572758, 51.985330065339475],
            [-1.424468649026081, 51.986675541681294],
            [-1.426902140270954, 51.987345530293211],
            [-1.427779356676942, 51.987986343441591],
            [-1.431239720438694, 51.988189206110015],
            [-1.433614042041407, 51.988022623938491],
            [-1.435531814631321, 51.989072962549628],
            [-1.43738718922692, 51.989182532563461],
            [-1.437700260238197, 51.9880215144508],
            [-1.441040235389147, 51.985146856990077],
            [-1.444333758137965, 51.984227388719852],
            [-1.444892192803622, 51.983813745805996],
            [-1.446422782782373, 51.98367709317813],
            [-1.446678127381702, 51.983870696016744],
            [-1.447145334417632, 51.983532136910632],
            [-1.447659280089713, 51.983655024078608],
            [-1.44788912724358, 51.983317147349169],
            [-1.448171214343551, 51.9834677163199],
            [-1.448194736039898, 51.983093808377753],
            [-1.450090672972662, 51.982369919739064],
            [-1.454092793998645, 51.979660713794992],
            [-1.456162506627529, 51.979716137633368],
            [-1.458519974270663, 51.978969967384515],
            [-1.460731022487579, 51.97865014403996],
            [-1.46105042615135, 51.978968082316079],
            [-1.462782504901416, 51.979252909172359],
            [-1.467356102905548, 51.97872343398501],
            [-1.470077665410574, 51.978811227813658],
            [-1.470930174341335, 51.978620856980044],
            [-1.472218861997931, 51.977365224221053],
            [-1.47395839171107, 51.977014269206954],
            [-1.478007326352318, 51.977002605759395],
            [-1.480751672793239, 51.976119245647126],
            [-1.484377414226271, 51.976314084654284],
            [-1.487701610267314, 51.975505924348944],
            [-1.492020841622077, 51.975396055222951],
            [-1.49368328433304, 51.97488893565523],
            [-1.495397643480318, 51.97636718697364],
            [-1.496973828001255, 51.977192989180594],
            [-1.500937061224745, 51.981490364536135],
            [-1.501360903538748, 51.981477773500139],
            [-1.501404910831386, 51.983678908095719],
            [-1.501886777752995, 51.98408193559321],
            [-1.503450103000989, 51.984788916513608],
            [-1.510867459723946, 51.986141583804979],
            [-1.513429301235538, 51.987177139440085],
            [-1.518207495191102, 51.989857144898821],
            [-1.521245500311834, 51.992983033473351],
            [-1.522840128144793, 51.996833057418186],
            [-1.521624290161422, 52.001585145335433],
            [-1.52117796001389, 52.005874624426511],
            [-1.515593582620392, 52.014418198251825],
            [-1.513585111918805, 52.01837214948209],
            [-1.510960763442643, 52.021437923224831],
            [-1.510351255903359, 52.025475830286503],
            [-1.510222898139598, 52.025485186883657],
            [-1.510129449366427, 52.02670214385163],
            [-1.507413817276412, 52.033368241665201],
            [-1.507493088471557, 52.0353087705058],
            [-1.506912274710967, 52.037221365809685],
            [-1.504957530248292, 52.041428021236257],
            [-1.503474753294789, 52.04353640115869],
            [-1.501853444508575, 52.049569517591323],
            [-1.499648812498493, 52.052423720368083],
            [-1.497366336675364, 52.058942154408015],
            [-1.497251497722174, 52.060857579978155],
            [-1.499278347861285, 52.063522051146343],
            [-1.501847307555917, 52.066258005275657],
            [-1.502631961768344, 52.069163506127879],
            [-1.500082045867059, 52.069930417407321],
            [-1.501867979207593, 52.071613834957887],
            [-1.50082782431655, 52.072915780662484],
            [-1.499102052862378, 52.073939692747587],
            [-1.496821511427801, 52.07433636278671],
            [-1.496324848691218, 52.07412925556806],
            [-1.495697375655246, 52.074525759606381],
            [-1.493801418173313, 52.074712734539354],
            [-1.492506921276781, 52.075255601789365],
            [-1.490677899630392, 52.077122270386681],
            [-1.490854182084871, 52.077531207563304],
            [-1.493304168494904, 52.079587133619924],
            [-1.493700423373523, 52.081580265503327],
            [-1.494378039383314, 52.082181050819742],
            [-1.490572851556159, 52.083790186719106],
            [-1.490752023692424, 52.084716997492833],
            [-1.490412004807211, 52.084715528927056],
            [-1.489965800188927, 52.086220433058536],
            [-1.488917920093951, 52.086221291504252],
            [-1.487802820089107, 52.08776014855632],
            [-1.48561989883581, 52.08772008074272],
            [-1.484745428571317, 52.091290944286115],
            [-1.486832056289282, 52.091469964013704],
            [-1.488155133367995, 52.09281441904168],
            [-1.487816704519904, 52.093943972244155],
            [-1.484757924456453, 52.094135637356153],
            [-1.480160404735511, 52.093464542897316],
            [-1.47827643869166, 52.099434099173514],
            [-1.477116780243291, 52.101352959455269],
            [-1.474153031014913, 52.100989150361613],
            [-1.471788635319264, 52.099449279975346],
            [-1.470750992549153, 52.097941394638696],
            [-1.467571988327622, 52.097043308516596],
            [-1.466943441323706, 52.09772645548346],
            [-1.465094820091205, 52.097402515476496],
            [-1.459750567942487, 52.097518413375298],
            [-1.458531465195454, 52.097653077470291],
            [-1.458219392155707, 52.097987894500193],
            [-1.453547211550096, 52.098529156491558],
            [-1.453620335350231, 52.09732295092747],
            [-1.447200967118291, 52.097612208879049],
            [-1.446991512087509, 52.098850137959275],
            [-1.448341023486162, 52.100457690520606],
            [-1.448074382895187, 52.10514865701689],
            [-1.449234258157087, 52.108301698179595],
            [-1.453471204659444, 52.1129956290264],
            [-1.448753223162719, 52.116053863811544],
            [-1.44786025619755, 52.11610722789635],
            [-1.444756407199948, 52.115205286334685],
            [-1.443196187690018, 52.115248280247222],
            [-1.437083857729538, 52.116691037788883],
            [-1.43528444724541, 52.116704015570491],
            [-1.428465884272876, 52.117946948642761],
            [-1.424975661111163, 52.118264439481074],
            [-1.419642938858345, 52.117628761873412],
            [-1.417795875782312, 52.116923786369284],
            [-1.414430196674622, 52.115426371499147],
            [-1.413586130191258, 52.114310037516191],
            [-1.412919501587746, 52.114252778057164],
            [-1.411472242957368, 52.113036326315523],
            [-1.403907856387148, 52.110077286090124],
            [-1.402811108047907, 52.10888407468164],
            [-1.402646868775798, 52.106312816866591],
            [-1.402245815643493, 52.106277519812508],
            [-1.401992465280205, 52.105569570547047],
            [-1.400628495587306, 52.105044786217654],
            [-1.400352011703532, 52.104322330618274],
            [-1.398933087465189, 52.103436721784796],
            [-1.398023503354995, 52.103328690748512],
            [-1.397888723357095, 52.102712142844076],
            [-1.396730119823561, 52.101908751545288],
            [-1.395550523566448, 52.101689634001609],
            [-1.390912523918275, 52.0988849631077],
            [-1.387567735972289, 52.097412054110897],
            [-1.387160992586697, 52.096093707258483],
            [-1.385745250714661, 52.095226834365924],
            [-1.385414310827558, 52.094133642499152],
            [-1.377714138560385, 52.096043344668878],
            [-1.378321776901101, 52.096751417935032],
            [-1.374438211660408, 52.099713989753894],
            [-1.371567018298389, 52.099063086362314],
            [-1.367587443407014, 52.099993029193847],
            [-1.366169293204771, 52.099932371902746],
            [-1.362569039184528, 52.101071867942778],
            [-1.360591041525161, 52.100955970800847],
            [-1.359356877311992, 52.101299011143375],
            [-1.357784539633418, 52.101298552093908],
            [-1.35883973647471, 52.102238423878994],
            [-1.358534257688519, 52.102364430518818],
            [-1.35892501586357, 52.10260390877265],
            [-1.358529819676003, 52.102572090951021],
            [-1.358381118286775, 52.102964174584123],
            [-1.359476255126223, 52.104279168534909],
            [-1.35899163318348, 52.104067952298436],
            [-1.358419097459178, 52.104286009992883],
            [-1.35931641767292, 52.10463343194089],
            [-1.358917637758232, 52.104952232144136],
            [-1.359736494223363, 52.104968367705681],
            [-1.359081588241932, 52.105228237604855],
            [-1.359680952614097, 52.105686420668889],
            [-1.360993756050307, 52.105876949603889],
            [-1.360632517293757, 52.106123134501644],
            [-1.36138956010783, 52.106277379729882],
            [-1.361242065272311, 52.106585860033306],
            [-1.361624964560703, 52.106663454509942],
            [-1.361297264349537, 52.106912520123643],
            [-1.36175701288374, 52.107334872744154],
            [-1.361238801672246, 52.107223280583604],
            [-1.361376584241439, 52.107492847524718],
            [-1.362329329360641, 52.108055423098754],
            [-1.362392984846191, 52.107789643546269],
            [-1.363158925235707, 52.108039226426193],
            [-1.363020800475728, 52.10892046464997],
            [-1.365306566885857, 52.110010765965896],
            [-1.365072416527779, 52.110355646246973],
            [-1.365409114574545, 52.110604701193978],
            [-1.364831353378143, 52.110570124274417],
            [-1.365197880207909, 52.111289551845374],
            [-1.366323478770281, 52.111719962871071],
            [-1.367252142421662, 52.111621555371592],
            [-1.367244922246012, 52.111925400818848],
            [-1.36795435017887, 52.111634310611315],
            [-1.36910417579861, 52.112316563130847],
            [-1.369572365823173, 52.112045750326914],
            [-1.369535681333381, 52.112472610351219],
            [-1.370380832927884, 52.112705486951064],
            [-1.369830831596951, 52.112978563118752],
            [-1.370371317142234, 52.113173849183369],
            [-1.370160003066939, 52.113765205089209],
            [-1.370590187704121, 52.113808858178373],
            [-1.370910681701066, 52.114383272251651],
            [-1.370255660026762, 52.114231431535949],
            [-1.370163518542661, 52.115070666366194],
            [-1.370340565757825, 52.115357514178633],
            [-1.371060825270467, 52.115129397737775],
            [-1.37075772982975, 52.115497297057018],
            [-1.371455230149881, 52.116160929126671],
            [-1.370638438313539, 52.116709499111579],
            [-1.371069537031754, 52.116690220717473],
            [-1.371412044428448, 52.117155064570149],
            [-1.370856297569952, 52.11752161752058],
            [-1.374597246941913, 52.118305714100138],
            [-1.377703362806374, 52.118119850599584],
            [-1.37901299496614, 52.117518984362341],
            [-1.381623636210979, 52.116933022111731],
            [-1.385393286728278, 52.117445409083082],
            [-1.390620657483685, 52.116773055670748],
            [-1.39213337586601, 52.117009222278782],
            [-1.39276483520446, 52.11761394797233],
            [-1.393316282593204, 52.11895009642496],
            [-1.392092639762436, 52.119682827460238],
            [-1.392438371011674, 52.120143130800201],
            [-1.392151701058851, 52.120709862074385],
            [-1.391439665669482, 52.121515348075491],
            [-1.389187334485674, 52.121950542451557],
            [-1.390105724292147, 52.122723992771512],
            [-1.387569307217088, 52.125978047403315],
            [-1.386842486053196, 52.126039004703458],
            [-1.385476505379011, 52.128390138450996],
            [-1.385292292296081, 52.128188687893605],
            [-1.384683056052615, 52.128406681383218],
            [-1.384425911497106, 52.127985477844618],
            [-1.383778695592219, 52.128203268503299],
            [-1.382742059094028, 52.127529846039089],
            [-1.375212781031277, 52.13090125670319],
            [-1.371163677607222, 52.13325326935982],
            [-1.370179939203197, 52.133081695577445],
            [-1.369614940080988, 52.134710475968511],
            [-1.364062577416471, 52.136694578692705],
            [-1.362834744086181, 52.134784639812281],
            [-1.36002889011396, 52.135444649573266],
            [-1.359837319238501, 52.135048921927989],
            [-1.357792669704927, 52.135501726578447],
            [-1.357672885294109, 52.135296987343892],
            [-1.355745843898257, 52.136001235196829],
            [-1.352088781713983, 52.134793547502852],
            [-1.349763718202886, 52.13483290210781],
            [-1.348722213753723, 52.135119352979324],
            [-1.349394573137955, 52.135902549949712],
            [-1.348326803314436, 52.137787385811244],
            [-1.349128774838729, 52.138502071878989],
            [-1.350269367036314, 52.14085131422663],
            [-1.349950463237249, 52.141578696902066],
            [-1.346842998597326, 52.142336527345321],
            [-1.343387300615591, 52.144210771575771],
            [-1.34105444756872, 52.144843292930652],
            [-1.342824133555609, 52.148717328346159],
            [-1.341624741213661, 52.150666999060988],
            [-1.343472572042905, 52.153221635832473],
            [-1.342357603385749, 52.154891275627946],
            [-1.338250362119142, 52.158829572898732],
            [-1.337160708128982, 52.158870208576488],
            [-1.336443354941059, 52.159433483822149],
            [-1.335827100304321, 52.158997569289525],
            [-1.33468203324756, 52.159322872166683],
            [-1.334019250745968, 52.159067396852436],
            [-1.333961544179213, 52.165383862660946],
            [-1.333101949357881, 52.167335360275956],
            [-1.331910882299326, 52.168471327252981],
            [-1.330883902737192, 52.168216469908117],
            [-1.331057968606989, 52.167918969190055],
            [-1.329952573922372, 52.167337300831775],
            [-1.330088218244693, 52.166287970086699],
            [-1.328386565284566, 52.165790109345856],
            [-1.328679498877461, 52.165463619455785],
            [-1.325322626971405, 52.162758985474504],
            [-1.323910289375294, 52.162090093626922],
            [-1.324679611831927, 52.161197236802671],
            [-1.324394591240109, 52.160904309954283],
            [-1.32088872093528, 52.159193054186154],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000178",
        LAD13CDO: "38UC",
        LAD13NM: "Oxford",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.283668193306721, 51.796265820209335],
            [-1.282878287164415, 51.795876185042211],
            [-1.279716901056991, 51.795984543772882],
            [-1.280060471821128, 51.795079437618881],
            [-1.27723635555241, 51.795227556274618],
            [-1.277015811888731, 51.79460131416316],
            [-1.271553512738356, 51.7952383555183],
            [-1.271573061435627, 51.794744860857065],
            [-1.271258149841704, 51.794760892632134],
            [-1.26866311922334, 51.795626821478933],
            [-1.267682616610926, 51.793763143149135],
            [-1.266499001620733, 51.793878947364547],
            [-1.266107229115463, 51.792823636003789],
            [-1.263973808675612, 51.793213110660815],
            [-1.263693487229578, 51.792351798853147],
            [-1.261837521234412, 51.792798716629648],
            [-1.26145869233616, 51.792556273928525],
            [-1.258112613903881, 51.793504466796584],
            [-1.253048912828485, 51.788874323218316],
            [-1.252968771093919, 51.787318336964589],
            [-1.252499467305479, 51.787029435152782],
            [-1.252722891277693, 51.786493181108071],
            [-1.25400439518466, 51.786146163755141],
            [-1.231717015103602, 51.776870309872152],
            [-1.227999749097363, 51.776412590047691],
            [-1.227950383245917, 51.775228121140735],
            [-1.220834359144563, 51.772252744619401],
            [-1.215740478472766, 51.772815003614319],
            [-1.21072323779554, 51.772299506625096],
            [-1.203826499318891, 51.770047463138162],
            [-1.201661346865248, 51.770129889790198],
            [-1.200129332203763, 51.769483797755093],
            [-1.198241890796612, 51.769188619105407],
            [-1.198250696389816, 51.768855103615728],
            [-1.197529998795002, 51.768553476357454],
            [-1.192368889720614, 51.76809825167728],
            [-1.191562131652357, 51.768236568254977],
            [-1.189014000361611, 51.767697529558909],
            [-1.188261997273219, 51.767044970091241],
            [-1.188224649634062, 51.765571943344952],
            [-1.180707913862064, 51.765990055401865],
            [-1.180227719605626, 51.765859931879568],
            [-1.179746332909402, 51.763076474113539],
            [-1.179683889438805, 51.762844063673874],
            [-1.182685504952782, 51.762401914744146],
            [-1.184294696232529, 51.760024107482423],
            [-1.185958612761518, 51.759992480418553],
            [-1.186133461846011, 51.756874617025893],
            [-1.187821188469495, 51.755508822910848],
            [-1.190002059855384, 51.756048957489071],
            [-1.191438745622356, 51.755265815078339],
            [-1.194304645637085, 51.757124203718227],
            [-1.195080910435573, 51.751353523251105],
            [-1.195124117677755, 51.749248060340413],
            [-1.195266482813379, 51.745148108133996],
            [-1.194678808440346, 51.745370664786996],
            [-1.192226944897401, 51.741858029115505],
            [-1.193386558361448, 51.740014682313898],
            [-1.193568860434429, 51.737176479643779],
            [-1.194007660896448, 51.736524921816397],
            [-1.193183929744585, 51.736265716707848],
            [-1.179248817475534, 51.73624208480522],
            [-1.178819848892416, 51.735380418675661],
            [-1.177989110569909, 51.735673124536362],
            [-1.17662386385707, 51.734458734060794],
            [-1.175813415148613, 51.733476597741578],
            [-1.175931193909127, 51.732573795762995],
            [-1.190159821779959, 51.727209551702572],
            [-1.190137742142774, 51.726257218584038],
            [-1.18903460858257, 51.724308386444093],
            [-1.185447828418362, 51.722733481658764],
            [-1.184173085619949, 51.721568356078819],
            [-1.186455408727108, 51.719991809180186],
            [-1.18771408234534, 51.718498960976447],
            [-1.188325868256609, 51.71674987645541],
            [-1.189605586891123, 51.714556727492109],
            [-1.1944332434117, 51.713964115956927],
            [-1.200221880987854, 51.71213884269428],
            [-1.207381711684331, 51.714974656085502],
            [-1.210162305581058, 51.715805290533275],
            [-1.212428334273889, 51.716098327429641],
            [-1.213762687637489, 51.715945416504908],
            [-1.214170763608468, 51.715534544364068],
            [-1.215472169428494, 51.715778810406391],
            [-1.216592343482567, 51.715538120950747],
            [-1.217683078910643, 51.715073340900076],
            [-1.218797121244148, 51.71300195548551],
            [-1.220540290397061, 51.712467754461684],
            [-1.2212895774501, 51.711482775627552],
            [-1.222307024012157, 51.710978804407702],
            [-1.223980449639698, 51.712737778736162],
            [-1.223672652725399, 51.712877809172156],
            [-1.225226011002327, 51.714216077043716],
            [-1.232126379221038, 51.718808266553417],
            [-1.23404085004251, 51.721162993499775],
            [-1.233807906472675, 51.722012053138577],
            [-1.241328188413744, 51.725154736242786],
            [-1.245931743938301, 51.727442969778927],
            [-1.246144711300574, 51.727260913279366],
            [-1.246686207726165, 51.727522434590647],
            [-1.247663611375063, 51.72830194488688],
            [-1.248688765117345, 51.72803696411421],
            [-1.25352728642751, 51.727967085033633],
            [-1.253079379066576, 51.728261851709661],
            [-1.254006660157644, 51.729279259480805],
            [-1.252963719001102, 51.729920908459555],
            [-1.253311586797481, 51.730690075125715],
            [-1.255824322545787, 51.733128259258542],
            [-1.256754516574066, 51.733447939351365],
            [-1.258682451113059, 51.735803238686707],
            [-1.259481580517185, 51.735729151692169],
            [-1.261337630203021, 51.737533687455802],
            [-1.262750534042898, 51.73836705542476],
            [-1.263210107168439, 51.739316716913876],
            [-1.263797283866906, 51.739188223550876],
            [-1.264235097562329, 51.739428333689212],
            [-1.265480671999641, 51.741210098501973],
            [-1.267505330982864, 51.741841328715076],
            [-1.267859222258624, 51.742706693980018],
            [-1.269307744604955, 51.742695924340204],
            [-1.269687704961466, 51.743204492945353],
            [-1.271529496712171, 51.743327410373851],
            [-1.272039574088063, 51.742951138554048],
            [-1.275868083104451, 51.743644628882031],
            [-1.278772858593635, 51.745506588912733],
            [-1.281071433113094, 51.745330051540854],
            [-1.282326050740329, 51.745952717632328],
            [-1.28416002322541, 51.746304665799478],
            [-1.285284322073357, 51.747204336054175],
            [-1.287197767872718, 51.747941544845148],
            [-1.288805054802812, 51.74899246339325],
            [-1.290854153210596, 51.750881310981605],
            [-1.290740740612704, 51.753313655161378],
            [-1.293245978502175, 51.753469901965438],
            [-1.293362280769707, 51.755393825125743],
            [-1.295248109181359, 51.756539835598204],
            [-1.295558549162535, 51.759122177113731],
            [-1.296827759976729, 51.762294682718654],
            [-1.301217552667174, 51.768154347225384],
            [-1.303687619010128, 51.772631331582893],
            [-1.304216512293245, 51.776597780534424],
            [-1.302837587537705, 51.780959332698998],
            [-1.300207294175641, 51.784102315382718],
            [-1.297285853301901, 51.786365061059826],
            [-1.292232049459165, 51.78886667515723],
            [-1.292340707964194, 51.789057042805908],
            [-1.289626244342836, 51.790558391141587],
            [-1.289232733905538, 51.790503865088908],
            [-1.286433721580326, 51.792389451199803],
            [-1.285697643424278, 51.793643748064717],
            [-1.28465989761557, 51.793982702724911],
            [-1.284557233789813, 51.795064615637507],
            [-1.283668193306721, 51.796265820209335],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000179",
        LAD13CDO: "38UD",
        LAD13NM: "South Oxfordshire",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.167171397532828, 51.81089972079301],
            [-1.167105456693996, 51.809676449626721],
            [-1.164353952614152, 51.810063338392624],
            [-1.159659426128448, 51.807212049152881],
            [-1.153948685841497, 51.804599624201671],
            [-1.151604494996506, 51.802690985936295],
            [-1.150770081943034, 51.801173538954501],
            [-1.152207364288311, 51.799775880374781],
            [-1.153143138801185, 51.797431413957156],
            [-1.154096386728779, 51.796628163100884],
            [-1.153551135936203, 51.796396762204751],
            [-1.151616064797659, 51.796586921639182],
            [-1.149322737779059, 51.797320226872323],
            [-1.146242935385756, 51.797769053064883],
            [-1.142603913723381, 51.797268744266574],
            [-1.142138870358147, 51.797704125945188],
            [-1.136827514448098, 51.796009979505278],
            [-1.132431358308901, 51.797120422525545],
            [-1.126838826742924, 51.79949678512596],
            [-1.125026909912296, 51.798256014981916],
            [-1.125226771508457, 51.795795697056221],
            [-1.125933604324854, 51.794636584663088],
            [-1.123808067301165, 51.794551536813955],
            [-1.123561598157276, 51.794175664210783],
            [-1.120459068639228, 51.792957565768049],
            [-1.120012474309566, 51.792287073667609],
            [-1.122883817012031, 51.78852589804977],
            [-1.122110801306447, 51.78838975712177],
            [-1.121963792727746, 51.787075035612155],
            [-1.115402726905025, 51.786893697417696],
            [-1.110671522611858, 51.783826157477009],
            [-1.105917705367359, 51.781935211071023],
            [-1.106381912951438, 51.781624943507268],
            [-1.097623665000465, 51.780447641740153],
            [-1.096949479815978, 51.781180651639708],
            [-1.097168919141913, 51.781661572276981],
            [-1.096729952556284, 51.782229148398706],
            [-1.085760575006964, 51.781298302556721],
            [-1.083376017335957, 51.780581116574417],
            [-1.079966211178021, 51.777464178314752],
            [-1.082726544291951, 51.773505289919797],
            [-1.080781326938953, 51.772872397086829],
            [-1.080839369907659, 51.772155343995408],
            [-1.078869131229184, 51.771260576240543],
            [-1.07994858644086, 51.770132524700045],
            [-1.079296816890474, 51.769400924722213],
            [-1.081428434064588, 51.767124809328593],
            [-1.082821299168151, 51.764109202881308],
            [-1.08274127327351, 51.760477880575507],
            [-1.083108203634199, 51.760389030930469],
            [-1.080872223030119, 51.756548457933974],
            [-1.077804566885796, 51.758495338128355],
            [-1.076293417573733, 51.758352202445352],
            [-1.075078924432763, 51.758581824454126],
            [-1.074032473370613, 51.758245404014509],
            [-1.072912475479993, 51.758317500249639],
            [-1.068682992752408, 51.756004769207102],
            [-1.067972819587245, 51.755384133622563],
            [-1.068261314827742, 51.754882005672371],
            [-1.066405412943292, 51.753619284131567],
            [-1.065831784910138, 51.753826923617119],
            [-1.065119239163863, 51.753605467488867],
            [-1.064133974504042, 51.754032810325981],
            [-1.058264407902753, 51.752327936243056],
            [-1.056333522965279, 51.751970782334254],
            [-1.054993577370949, 51.752175812279418],
            [-1.053270311960052, 51.749718995203068],
            [-1.053443105664487, 51.749283406235577],
            [-1.052897518073232, 51.749396799181845],
            [-1.050751401073461, 51.748327500798517],
            [-1.048180615500724, 51.747619774936986],
            [-1.047293634465287, 51.746540818979369],
            [-1.04558765799611, 51.74590567781997],
            [-1.045806219119116, 51.745431808260015],
            [-1.045260693784454, 51.745199897437161],
            [-1.045992470409271, 51.745047589835238],
            [-1.045492176486621, 51.744593960113903],
            [-1.044123483672504, 51.74507107115685],
            [-1.043263004813401, 51.74452728635309],
            [-1.039061804782133, 51.744699822727767],
            [-1.038465477070608, 51.744065550198336],
            [-1.037671955447616, 51.744601237547592],
            [-1.037208393699576, 51.744127192753496],
            [-1.036088472888687, 51.744073058612159],
            [-1.034132921499443, 51.744693598137452],
            [-1.031706880450106, 51.744350850265093],
            [-1.030456230834082, 51.74492318432015],
            [-1.029628995584186, 51.746013307985677],
            [-1.030033821060958, 51.74658939988133],
            [-1.029635322851487, 51.748895997182707],
            [-1.030572497861587, 51.749168974449866],
            [-1.031240621600269, 51.75213354363725],
            [-1.032581163227147, 51.753126439881498],
            [-1.032653595126785, 51.754618704187145],
            [-1.032158638292634, 51.755340234209399],
            [-1.032679452583548, 51.755849835237356],
            [-1.031991541000876, 51.756989673572114],
            [-1.029848579846402, 51.757875642814945],
            [-1.028238394249539, 51.757021652565896],
            [-1.027106172228732, 51.757255955165334],
            [-1.025239499164996, 51.755801874625213],
            [-1.023631768089921, 51.75551339822637],
            [-1.012258621365467, 51.755988453000256],
            [-1.007415055572869, 51.75593327495524],
            [-1.007526537162716, 51.754945163889325],
            [-1.005633559586934, 51.754985814624533],
            [-1.00377335678275, 51.754458456943922],
            [-1.003576424228012, 51.75504662305196],
            [-1.001540527782182, 51.754800969527743],
            [-0.999424492467617, 51.754511439981691],
            [-0.998295476533313, 51.754858784195072],
            [-0.998006953856631, 51.754410352598853],
            [-0.998472445484211, 51.753604194523447],
            [-0.997369592826488, 51.753424857110595],
            [-0.995078141567019, 51.754385354887184],
            [-0.99343387591587, 51.754708466187076],
            [-0.992605598443532, 51.754038706155001],
            [-0.990585945682567, 51.754043861631295],
            [-0.990030695401908, 51.753411493511116],
            [-0.989003525886008, 51.753672406978588],
            [-0.989008100667583, 51.753078114879216],
            [-0.986765051668758, 51.753163105030289],
            [-0.987226263881828, 51.752362348686766],
            [-0.986811938896385, 51.752168161028465],
            [-0.98340619696937, 51.752421989966635],
            [-0.982218193904726, 51.75294130494833],
            [-0.980840892054111, 51.753373548791082],
            [-0.980463662965727, 51.753913358841892],
            [-0.981310445921365, 51.754589659381729],
            [-0.981678763265494, 51.755863335050513],
            [-0.98075863681257, 51.757092578318627],
            [-0.981237332347279, 51.758032731714323],
            [-0.980614116424951, 51.759264545942891],
            [-0.981288187987704, 51.759443023180623],
            [-0.981441897896953, 51.760017106696459],
            [-0.979706351848558, 51.760712378646147],
            [-0.978224247199035, 51.760255332825921],
            [-0.977340799909025, 51.760301600233873],
            [-0.977066727775565, 51.75999081112959],
            [-0.976220771796558, 51.760109327465585],
            [-0.975266504631845, 51.758867394358816],
            [-0.973874672340572, 51.75801186175925],
            [-0.972699509894179, 51.758209300766843],
            [-0.972418565956207, 51.758011732194802],
            [-0.97221717201796, 51.75826712591968],
            [-0.971453158672345, 51.758095901901022],
            [-0.970718809995161, 51.758659530225216],
            [-0.970090472742633, 51.758511064434714],
            [-0.969782579258035, 51.758797890096439],
            [-0.969137089771407, 51.758575539401065],
            [-0.966747974111183, 51.758698429819148],
            [-0.963086066206486, 51.759166112957899],
            [-0.961851159612194, 51.758616629931282],
            [-0.9616434089044, 51.758830589109529],
            [-0.960622555515931, 51.758740646069377],
            [-0.953695725579851, 51.757849298089162],
            [-0.951140184093276, 51.756587510015819],
            [-0.950706317098034, 51.755866123290915],
            [-0.95033541894225, 51.756116370464795],
            [-0.949768304400914, 51.755893713420512],
            [-0.94936483386724, 51.756236278203701],
            [-0.947699116160873, 51.75534561631099],
            [-0.947044247904287, 51.755471027747362],
            [-0.944950272574219, 51.753626096372315],
            [-0.944109882066426, 51.753320935269514],
            [-0.943679099624715, 51.753529263167145],
            [-0.942376446409509, 51.752981662380279],
            [-0.941027908371361, 51.753344465634356],
            [-0.940426461016231, 51.752858904084469],
            [-0.936122722692255, 51.753460226796591],
            [-0.933292608991141, 51.752385281472797],
            [-0.932961784517023, 51.751116284800183],
            [-0.932244354243838, 51.750455194086754],
            [-0.932198727925956, 51.749502586056252],
            [-0.928401396859324, 51.745913714345065],
            [-0.924096027828723, 51.747702351379182],
            [-0.920912620133498, 51.744581012049167],
            [-0.920318613771348, 51.742642394257047],
            [-0.920377315358254, 51.740604569043306],
            [-0.919345549074085, 51.739717521487449],
            [-0.91987766711882, 51.739510213569034],
            [-0.919145823029332, 51.739286792288027],
            [-0.919716814743263, 51.738967449207593],
            [-0.918725027239369, 51.738774005339849],
            [-0.919026249327459, 51.738228296257581],
            [-0.918657115921981, 51.738099919715054],
            [-0.918837611817423, 51.737758105904241],
            [-0.918356306515533, 51.737778854147656],
            [-0.918321144076798, 51.737249832199574],
            [-0.917697131966704, 51.737424816866557],
            [-0.916994729453171, 51.736940900382557],
            [-0.916500926308581, 51.73718181689437],
            [-0.916325465891822, 51.73694911863047],
            [-0.914867584271303, 51.737897756689129],
            [-0.914599292660935, 51.737429521440816],
            [-0.913813176347883, 51.737781019738939],
            [-0.912804558786815, 51.737023604790224],
            [-0.912580729300708, 51.736695143853254],
            [-0.912216266907544, 51.737037044261108],
            [-0.91101431059965, 51.73685417794578],
            [-0.91108327030491, 51.737118267005904],
            [-0.9104329144223, 51.736821815840614],
            [-0.909406294492931, 51.736574920357022],
            [-0.908777142095372, 51.73509807553787],
            [-0.906116989048008, 51.733355077586921],
            [-0.905405690879217, 51.733307095067083],
            [-0.90405024124391, 51.731991598484313],
            [-0.90316561376965, 51.731388110381168],
            [-0.903577277906208, 51.731250784755957],
            [-0.903358858050217, 51.731061724148702],
            [-0.900615697593671, 51.729356487848968],
            [-0.901678973315998, 51.728141786860711],
            [-0.899722495766312, 51.724994446916945],
            [-0.897187556384736, 51.722844198361379],
            [-0.895468782123661, 51.721846185700421],
            [-0.89348643271828, 51.722041537121171],
            [-0.891362200860387, 51.72146674268631],
            [-0.889418623640081, 51.720138329596814],
            [-0.887705299818289, 51.718339109160254],
            [-0.887646234747071, 51.713665655925737],
            [-0.888275805131836, 51.712369640141397],
            [-0.888349409853651, 51.7103706203957],
            [-0.888872966673756, 51.709972751181553],
            [-0.88866374357364, 51.709001485722396],
            [-0.889699856455898, 51.708121117775605],
            [-0.88929040506522, 51.707111095927715],
            [-0.888599826506692, 51.707357228953732],
            [-0.887093875743583, 51.705207486733173],
            [-0.88658037297091, 51.70507943841929],
            [-0.887257376359, 51.703320811617225],
            [-0.889585760092023, 51.70125320226709],
            [-0.889278900511626, 51.699897074781958],
            [-0.890775419188838, 51.697280284369128],
            [-0.89479522061501, 51.692518472742989],
            [-0.893838687566078, 51.690449506672977],
            [-0.89266915944822, 51.689050194145686],
            [-0.885685933886315, 51.686757863100965],
            [-0.881987212896811, 51.684633984470842],
            [-0.879917732770056, 51.680861189605686],
            [-0.881858097043775, 51.680537992042204],
            [-0.886785420051121, 51.681386830499846],
            [-0.891038323860553, 51.678645955991094],
            [-0.892486243360961, 51.678284665279079],
            [-0.893834511478091, 51.679103911364493],
            [-0.894343155755378, 51.678885708213564],
            [-0.895785996002161, 51.677305071765993],
            [-0.897918176290437, 51.676397161665541],
            [-0.895091018118633, 51.67466130843367],
            [-0.892163984476729, 51.674775810809912],
            [-0.888954728076218, 51.673952446981055],
            [-0.884156384100865, 51.673771197571718],
            [-0.881389929466421, 51.672416831354873],
            [-0.878621982985339, 51.670016662196815],
            [-0.877728225005905, 51.668381547282664],
            [-0.883992611051097, 51.667723695396958],
            [-0.894766880350298, 51.668250857105285],
            [-0.897063684658605, 51.670015012332286],
            [-0.901225157410063, 51.67098373736021],
            [-0.90472432502595, 51.672198804646563],
            [-0.907043480395569, 51.673418979768144],
            [-0.910402817541595, 51.673845812992944],
            [-0.915716008012683, 51.672456319056963],
            [-0.917750696546457, 51.672396853576103],
            [-0.91865781442269, 51.671226412055049],
            [-0.924932089519808, 51.666903303939755],
            [-0.92491070653758, 51.665423994871752],
            [-0.927837972071868, 51.665576629372573],
            [-0.928633206019096, 51.665270977856004],
            [-0.929374347806571, 51.666278496176062],
            [-0.930817489993309, 51.666090228245871],
            [-0.931408690836532, 51.665114627411768],
            [-0.932234385868988, 51.664864976391769],
            [-0.929557458645765, 51.663355207218821],
            [-0.928364008633373, 51.661052373988696],
            [-0.926568381778226, 51.660010939806241],
            [-0.925072387432336, 51.659690647229546],
            [-0.923119517328727, 51.656993269121685],
            [-0.923236297739573, 51.656457541964286],
            [-0.924629958318672, 51.655721309574602],
            [-0.925193564045924, 51.654995451863456],
            [-0.927672888353376, 51.654682725205674],
            [-0.927272381994723, 51.651440298137715],
            [-0.929206363122833, 51.65377417736687],
            [-0.929451925508149, 51.6557455726551],
            [-0.930979833380458, 51.655196614407622],
            [-0.931455747661222, 51.655306146084286],
            [-0.931517738773602, 51.653161318026342],
            [-0.933190413176642, 51.652598362207307],
            [-0.93955742592273, 51.654289784882216],
            [-0.939813578292898, 51.654029542962824],
            [-0.946338775013327, 51.653780742883612],
            [-0.948201760801019, 51.652107922051613],
            [-0.944474127047705, 51.652158106603387],
            [-0.944022954325986, 51.651170372727371],
            [-0.945004722324596, 51.649787297893752],
            [-0.949972321803848, 51.650926084748654],
            [-0.950747090968159, 51.650737890802922],
            [-0.948361746100338, 51.649441553298772],
            [-0.945164284223917, 51.648330296202239],
            [-0.94462753996771, 51.646546939231442],
            [-0.941641466928643, 51.644190350134657],
            [-0.940232456101822, 51.643628259186478],
            [-0.937558881023464, 51.641645735966499],
            [-0.934606808610272, 51.636695192917657],
            [-0.935420148293213, 51.635654146501814],
            [-0.938237519727779, 51.635110481440108],
            [-0.940131607708651, 51.632764594657452],
            [-0.942337892592718, 51.631772029059377],
            [-0.943359247392482, 51.631094263586007],
            [-0.943611476918602, 51.63049859050556],
            [-0.948893935170527, 51.631362424817162],
            [-0.950050204181417, 51.628903672104776],
            [-0.949509569410239, 51.625600714108323],
            [-0.95009137998929, 51.622737591326874],
            [-0.949813979433509, 51.620674231578512],
            [-0.947636341259043, 51.620458719789148],
            [-0.945538707216316, 51.618412291576362],
            [-0.939605966832127, 51.618558483417118],
            [-0.938586194561478, 51.61793963780196],
            [-0.940058816040762, 51.614185443969646],
            [-0.943919487692982, 51.610757548678315],
            [-0.94414332773654, 51.60945037865055],
            [-0.942621958193631, 51.607490896767729],
            [-0.943175837176088, 51.605030373377367],
            [-0.942065116739114, 51.60448356950166],
            [-0.93894965538037, 51.604332272079233],
            [-0.938282536137217, 51.603837095710411],
            [-0.936025512709871, 51.603286157558465],
            [-0.936251123252256, 51.602282035261545],
            [-0.933524571892856, 51.601623389671225],
            [-0.931358555884908, 51.602246599540194],
            [-0.9298063927191, 51.604149484521912],
            [-0.929272427031192, 51.604320853004133],
            [-0.924020986672808, 51.604247670692381],
            [-0.921999521653781, 51.603633880733618],
            [-0.920091738383077, 51.603586677224357],
            [-0.917089705023594, 51.60000731777437],
            [-0.91688828369528, 51.599309504204882],
            [-0.919902357831587, 51.595621909567178],
            [-0.919455790960705, 51.595519791194967],
            [-0.921029929794, 51.594644990956894],
            [-0.924899639962843, 51.5908851017793],
            [-0.9282565009579, 51.590674816609202],
            [-0.928525036405655, 51.590059539202137],
            [-0.93325970079804, 51.588645103354743],
            [-0.934565467148933, 51.588546362958461],
            [-0.941550002527234, 51.585457957760703],
            [-0.941372786127044, 51.584326105602962],
            [-0.939910832134808, 51.582086572398616],
            [-0.93954036435363, 51.580330746584913],
            [-0.940125501850879, 51.576456116115921],
            [-0.939359852724442, 51.574010652755263],
            [-0.938622419069224, 51.571038524473387],
            [-0.936776165955735, 51.571380585353289],
            [-0.933868950082431, 51.569764483176037],
            [-0.93040715980721, 51.569040628693315],
            [-0.93052584503559, 51.56853277920581],
            [-0.92783952833056, 51.568092856023391],
            [-0.928356330047113, 51.565994412999665],
            [-0.924155377448371, 51.563411326729906],
            [-0.921413984849173, 51.564644110097859],
            [-0.919612764614145, 51.561873378703751],
            [-0.918510925718043, 51.561494570601752],
            [-0.917099341809992, 51.562556966772775],
            [-0.915936248868874, 51.561720789399104],
            [-0.914729932458389, 51.561490243439685],
            [-0.913644039841186, 51.561715781414534],
            [-0.911784714436276, 51.559832772436245],
            [-0.91113421132725, 51.558439314149467],
            [-0.910140093948814, 51.557724235681434],
            [-0.906311884137233, 51.556754383444542],
            [-0.901720811844267, 51.556082989030578],
            [-0.902804979249656, 51.552928032563614],
            [-0.902166364868111, 51.548598823591334],
            [-0.903215410761407, 51.545876035471778],
            [-0.898317694749977, 51.545289755891567],
            [-0.896901839556156, 51.544860137217938],
            [-0.900079768212, 51.540451626842824],
            [-0.900153878694581, 51.536658690622225],
            [-0.899371554906986, 51.535797136541817],
            [-0.895812563494583, 51.533796299531083],
            [-0.892638340426343, 51.532706256214922],
            [-0.888017021586468, 51.530128666613585],
            [-0.883926281969089, 51.527155804488999],
            [-0.878815216088162, 51.523726158144456],
            [-0.878237361794274, 51.523015676842263],
            [-0.878554973543347, 51.518065119920763],
            [-0.879974967031874, 51.517168711234078],
            [-0.880561703457829, 51.516338970376978],
            [-0.880293455054625, 51.51505327730824],
            [-0.880868443455817, 51.513584103722046],
            [-0.880256100754669, 51.511756516596542],
            [-0.879576245488035, 51.511097219114639],
            [-0.874519068023841, 51.509308894345565],
            [-0.871984941177391, 51.507886325514008],
            [-0.871078825321158, 51.506885809523439],
            [-0.871044613924663, 51.505587957051759],
            [-0.870075196440721, 51.504120146572134],
            [-0.870688264797297, 51.502983185623656],
            [-0.871710017329576, 51.502448110737873],
            [-0.880191176190583, 51.501824411796278],
            [-0.882409849059841, 51.501355513247269],
            [-0.885949732356988, 51.500053893592742],
            [-0.887472780373037, 51.499866030628873],
            [-0.889758162495425, 51.49877898730599],
            [-0.894020707315402, 51.496469682056087],
            [-0.893878637698911, 51.49483991939028],
            [-0.895302511600898, 51.493919083823322],
            [-0.893466246910278, 51.492535915340298],
            [-0.894286283323725, 51.492041902506166],
            [-0.896403433942007, 51.491814563531584],
            [-0.896646096567545, 51.489936650273307],
            [-0.898281167360339, 51.488311904395275],
            [-0.897670807924353, 51.48704371387872],
            [-0.901887108333654, 51.485337941435574],
            [-0.9058283748527, 51.485061855104448],
            [-0.90820511695128, 51.483959120467148],
            [-0.910229991457495, 51.482146309601553],
            [-0.910604741507747, 51.479472915113533],
            [-0.910098507775512, 51.477753464655819],
            [-0.910627384877316, 51.476829518565935],
            [-0.913359409115226, 51.475961969046175],
            [-0.914592534668104, 51.474785561840605],
            [-0.916888821753179, 51.474373381448238],
            [-0.919296516450586, 51.472717709123053],
            [-0.920470183751043, 51.47130510558069],
            [-0.921717380641611, 51.467930241780394],
            [-0.923687010729883, 51.467188520693028],
            [-0.926886539290906, 51.467283479645495],
            [-0.929826217635614, 51.467778815961381],
            [-0.930661607511422, 51.467638969009741],
            [-0.932679884200164, 51.465792440879817],
            [-0.942367596664521, 51.460592987767626],
            [-0.946008551657395, 51.459502681952294],
            [-0.949187951866246, 51.459512329595164],
            [-0.955472868668262, 51.461255346397785],
            [-0.955182339411334, 51.46219060903482],
            [-0.954566039627771, 51.462199503969558],
            [-0.954413700960461, 51.462629754971474],
            [-0.954383352477155, 51.464524967064186],
            [-0.95482606913917, 51.464554090373049],
            [-0.955204171654776, 51.465332556766107],
            [-0.951580966456756, 51.465700361738001],
            [-0.949460871081266, 51.464929671758576],
            [-0.948900484810322, 51.465510919780634],
            [-0.94926610905891, 51.466139129735218],
            [-0.947201433971118, 51.466652032976874],
            [-0.949242111957417, 51.470130396832516],
            [-0.946549604764426, 51.47151526685564],
            [-0.946775160359774, 51.471724104956841],
            [-0.947343241194033, 51.472940406249506],
            [-0.941156736700879, 51.474976224047268],
            [-0.944932083374898, 51.479222022177453],
            [-0.945587931567155, 51.481692581220479],
            [-0.944520620035673, 51.481950938802633],
            [-0.945511034846576, 51.483590964558296],
            [-0.946085008940149, 51.483431572489621],
            [-0.948055986239532, 51.485209864204577],
            [-0.947031140275642, 51.486060287599059],
            [-0.94919454910832, 51.486992357362361],
            [-0.949831737755129, 51.486464848511496],
            [-0.953923439549989, 51.488606392799937],
            [-0.956019624012198, 51.4910429652872],
            [-0.955490299383835, 51.491388934038753],
            [-0.956667461844734, 51.492067500964247],
            [-0.957495819884196, 51.49307295600525],
            [-0.962109766638515, 51.493128254881753],
            [-0.962446591718779, 51.492758074531018],
            [-0.965115307441997, 51.492076690244573],
            [-0.964620603859428, 51.491468974938677],
            [-0.966181410094222, 51.490921651712966],
            [-0.967165530888932, 51.490651574487863],
            [-0.967543254297979, 51.490891383963017],
            [-0.969208034632067, 51.490201965944138],
            [-0.969610864695679, 51.490861906570295],
            [-0.970453362296735, 51.490419713523941],
            [-0.970038172334535, 51.489157216340075],
            [-0.971541519181879, 51.487700245959282],
            [-0.974341153867305, 51.486749146900465],
            [-0.975367140505514, 51.487432498477361],
            [-0.97610426129493, 51.488346207622449],
            [-0.97766999643946, 51.488404822443179],
            [-0.97939588759793, 51.487547646156301],
            [-0.978646491833859, 51.487310034457458],
            [-0.978633746323768, 51.485623960288578],
            [-0.980103915071941, 51.485701492759887],
            [-0.982283347980726, 51.485037044365399],
            [-0.984141458477094, 51.484960534809339],
            [-0.986957102635245, 51.485737498244376],
            [-0.989049894139711, 51.485730375801751],
            [-0.991171421251973, 51.48526937737256],
            [-0.992080933954702, 51.485366214493531],
            [-0.993395583850087, 51.486293766936349],
            [-0.994351794725824, 51.485971070258273],
            [-0.993857723432604, 51.48518904328445],
            [-0.99710483206393, 51.4843599312075],
            [-0.997399621642872, 51.484642995148789],
            [-0.998419519489799, 51.484302826132847],
            [-0.997547792640841, 51.483468134604209],
            [-0.998369933957487, 51.483279136418297],
            [-0.997854024141282, 51.482374651128019],
            [-0.998071439728609, 51.481719207909407],
            [-0.999996695616299, 51.481462285928977],
            [-1.000027261810073, 51.480854700929861],
            [-1.000639664941029, 51.480837440467006],
            [-1.000445727775267, 51.479893447465308],
            [-0.999696369639777, 51.4803132708739],
            [-0.998731897396379, 51.480296949565627],
            [-0.998201690638581, 51.477548124323064],
            [-0.999836321702319, 51.475230498497595],
            [-1.000823032277175, 51.474619372357367],
            [-1.002462408536232, 51.474252073265397],
            [-1.00328498963376, 51.474430809542099],
            [-1.003827714992837, 51.473311444212491],
            [-1.006949779984597, 51.473419743576549],
            [-1.004826876079869, 51.472719273014327],
            [-1.004857606295921, 51.472431795872978],
            [-1.005982935682374, 51.472591501196234],
            [-1.006759430830221, 51.472305845374613],
            [-1.007119236633517, 51.471980690680873],
            [-1.006833962326442, 51.471392908769005],
            [-1.007958883469483, 51.47157057537148],
            [-1.007876810893737, 51.46957909456593],
            [-1.007316249820146, 51.468209394516414],
            [-1.00752031534245, 51.467828070197541],
            [-1.011227439120348, 51.467160716794261],
            [-1.013584158986978, 51.466290371458037],
            [-1.018224914911578, 51.466582878309268],
            [-1.021792316773593, 51.467994725888097],
            [-1.02661471212989, 51.470722494840743],
            [-1.036577909454445, 51.475225116878867],
            [-1.03827943749047, 51.47599798652832],
            [-1.039094362618604, 51.476960491616268],
            [-1.038619150718844, 51.480181948954986],
            [-1.037458558133451, 51.481957298866448],
            [-1.037600972515802, 51.484017583408757],
            [-1.03968187818367, 51.48518918021324],
            [-1.03982424073761, 51.486639818463182],
            [-1.042218958653286, 51.488652868764873],
            [-1.046166816921752, 51.49017227128077],
            [-1.048697792831837, 51.490781782524948],
            [-1.051370389083018, 51.491858156900882],
            [-1.05544305880447, 51.492035785768493],
            [-1.059729068609978, 51.491786976044821],
            [-1.071168557625501, 51.489738969251349],
            [-1.073065792838521, 51.489510299039082],
            [-1.077290368907015, 51.487863945391233],
            [-1.082663976833434, 51.487077049440792],
            [-1.088382272787431, 51.485751284005929],
            [-1.089692139599838, 51.485787531648612],
            [-1.089921488616153, 51.486203830273674],
            [-1.100248600782349, 51.488874907887769],
            [-1.102797021891852, 51.490169452237637],
            [-1.104385174619776, 51.492329709830855],
            [-1.108051580180468, 51.500717237455341],
            [-1.106989625133978, 51.501094903585617],
            [-1.107290934993261, 51.50182192804688],
            [-1.109396199076225, 51.505424717135156],
            [-1.113207019341996, 51.509590659473282],
            [-1.116255275377909, 51.51104783204083],
            [-1.119306465746368, 51.511839561970184],
            [-1.127490707093586, 51.511880922572793],
            [-1.133956415830992, 51.511282320829949],
            [-1.138694694615651, 51.514173851313871],
            [-1.141083726256067, 51.515186740506891],
            [-1.1420736028769, 51.516193855192689],
            [-1.14328484636749, 51.519769696761621],
            [-1.142555919609701, 51.52082538850852],
            [-1.143268433968615, 51.52095197995726],
            [-1.143036575711086, 51.521402567321353],
            [-1.14405828985296, 51.522027750900712],
            [-1.143555919812801, 51.523452859401097],
            [-1.138915831724976, 51.526982295499707],
            [-1.13856649424648, 51.526855646930713],
            [-1.137752519366376, 51.528891672725202],
            [-1.134684329958677, 51.532799338382759],
            [-1.134466474593165, 51.53372027446688],
            [-1.134698928537919, 51.536516590375797],
            [-1.13633479946843, 51.539976032788182],
            [-1.140394774765581, 51.542909235396614],
            [-1.146957057548953, 51.540610299937235],
            [-1.15269408710326, 51.539390332521577],
            [-1.154671748076644, 51.538723028148524],
            [-1.156057386885911, 51.537865308304703],
            [-1.16017694989125, 51.536600064896156],
            [-1.15939464088546, 51.534923820155811],
            [-1.160818060248354, 51.534587829077751],
            [-1.165776107003015, 51.533962291645956],
            [-1.172780736774593, 51.533761905213758],
            [-1.175003790871224, 51.533446661514482],
            [-1.180090049657594, 51.532005881036497],
            [-1.190589407970629, 51.52998641257912],
            [-1.200118201788658, 51.528842371787341],
            [-1.204669813910289, 51.528393153544734],
            [-1.20502363782489, 51.530343134197594],
            [-1.205991325751205, 51.53164807357885],
            [-1.205457878684442, 51.533307011682957],
            [-1.20422134246149, 51.534274223262358],
            [-1.20325158262321, 51.536722351946104],
            [-1.204872585060579, 51.537609126902041],
            [-1.206148563923373, 51.537528747430038],
            [-1.210154051737351, 51.536440813005797],
            [-1.214964164452455, 51.536089143748278],
            [-1.216596785371329, 51.536226834032043],
            [-1.218878533059325, 51.5369361931713],
            [-1.22145691794734, 51.536150375398265],
            [-1.22681123348921, 51.535157132086248],
            [-1.22872387444718, 51.541419769342021],
            [-1.228722495409649, 51.543719806828264],
            [-1.227992966899348, 51.546865659650344],
            [-1.223693534245037, 51.552063205721929],
            [-1.22280651672856, 51.551979109481991],
            [-1.220750035382285, 51.55728578573693],
            [-1.22178352836008, 51.560373150271914],
            [-1.220651434105489, 51.560523891295503],
            [-1.219167961079545, 51.562576696551638],
            [-1.217220011319043, 51.56402934730967],
            [-1.214252594357793, 51.569713763597484],
            [-1.213911051852485, 51.569773518861162],
            [-1.213979768985526, 51.570558041841174],
            [-1.211790765471253, 51.570901229181118],
            [-1.212653552079894, 51.572324985303638],
            [-1.212179963857298, 51.572509731550085],
            [-1.212407697105409, 51.572858333106169],
            [-1.211712022028581, 51.573035293312742],
            [-1.207666997720135, 51.579535023101407],
            [-1.20269775574073, 51.589294059202487],
            [-1.203110223162974, 51.58972755319688],
            [-1.202232039170704, 51.589841177149864],
            [-1.203486844733912, 51.590808192063001],
            [-1.203890964840634, 51.592133585231025],
            [-1.207291088008306, 51.5915217875677],
            [-1.208010578232027, 51.591071671862117],
            [-1.208869810917472, 51.591218631000309],
            [-1.209108592662068, 51.590518901178115],
            [-1.208695886689331, 51.589679909342003],
            [-1.209661912414613, 51.589327653934859],
            [-1.209841197714205, 51.589643563744247],
            [-1.210682913599461, 51.589134010914911],
            [-1.214139728939247, 51.588815519926342],
            [-1.215607215976453, 51.587922583931821],
            [-1.2164648026048, 51.586904175724754],
            [-1.216777438570597, 51.587105873308758],
            [-1.219058746211153, 51.585883835373345],
            [-1.220241877650478, 51.585824262104111],
            [-1.220214281311975, 51.585159605462444],
            [-1.220853670445224, 51.585075733170946],
            [-1.220271382600194, 51.584606106950382],
            [-1.220909482824195, 51.584850416340501],
            [-1.220833648329804, 51.584474067526287],
            [-1.221698480207493, 51.584457324584768],
            [-1.22365578786048, 51.583176402652796],
            [-1.224287012442884, 51.582637486684405],
            [-1.224387671614856, 51.58147195103313],
            [-1.224831632897198, 51.581334615178378],
            [-1.226833220866612, 51.582279334639011],
            [-1.226979644740137, 51.581304718515455],
            [-1.227797904677153, 51.581562764747147],
            [-1.230080356205716, 51.580324335616162],
            [-1.232752426143023, 51.582595102274475],
            [-1.233428579335549, 51.582724500806293],
            [-1.253013006202608, 51.584521351693468],
            [-1.258016517412026, 51.583929066120781],
            [-1.263329664396736, 51.584262837037798],
            [-1.264735823173571, 51.58459714311909],
            [-1.269506945956907, 51.584537007654077],
            [-1.270493115297147, 51.584062093573991],
            [-1.271152148372795, 51.583104095782524],
            [-1.273650013553962, 51.581229560893078],
            [-1.282575049396148, 51.576263578640649],
            [-1.284147045025433, 51.574852515637133],
            [-1.285431074904608, 51.575420508221939],
            [-1.285729190990001, 51.575273962356846],
            [-1.286408938222729, 51.575913795054014],
            [-1.286977456251963, 51.578222670188218],
            [-1.287654422542428, 51.578213292034718],
            [-1.287676796348928, 51.577889733751711],
            [-1.290242543347855, 51.57789538095728],
            [-1.288694203126203, 51.581309973478],
            [-1.283985131435446, 51.586905530505476],
            [-1.270582970782616, 51.598284488938155],
            [-1.271543741084244, 51.600228115755883],
            [-1.265080714117478, 51.603750268988904],
            [-1.266102728719485, 51.604126209778521],
            [-1.265748887249189, 51.607175702430531],
            [-1.269138235836091, 51.608512295325674],
            [-1.268946656618298, 51.608841988755437],
            [-1.270499431173747, 51.609719320196277],
            [-1.272803750487558, 51.610111252226019],
            [-1.273287793647282, 51.61163290549829],
            [-1.269845477543522, 51.613667001914216],
            [-1.266377023238693, 51.61479449560084],
            [-1.266569977020063, 51.616174989644598],
            [-1.266072639479032, 51.617728303099],
            [-1.264939360734592, 51.619214695722491],
            [-1.263828773930487, 51.618579237647083],
            [-1.257547079606537, 51.617164004071554],
            [-1.255459901096264, 51.621142996518401],
            [-1.253204516297381, 51.62267340890557],
            [-1.250394435947046, 51.623316379785592],
            [-1.247312284343632, 51.623460313150794],
            [-1.244598344875353, 51.623263960057677],
            [-1.24300665695524, 51.622644091874115],
            [-1.241761427539907, 51.624467618598175],
            [-1.235684696141864, 51.629590224203262],
            [-1.235341948047932, 51.630298317659189],
            [-1.221768631561211, 51.639909142364253],
            [-1.221073888734649, 51.639973771703225],
            [-1.219074649726946, 51.641865783442491],
            [-1.219341701241266, 51.642640821874238],
            [-1.220292369844092, 51.642992406775249],
            [-1.221895907467895, 51.642352059396188],
            [-1.222516744386541, 51.641540646981674],
            [-1.223227156657718, 51.64140597949541],
            [-1.227977081656627, 51.641076735866562],
            [-1.232603698014878, 51.641542236357722],
            [-1.236703219144123, 51.642679386698056],
            [-1.237349085875876, 51.643375921287543],
            [-1.23848991990829, 51.643254744813781],
            [-1.242258691943319, 51.645178103199029],
            [-1.2424773287118, 51.644546517280865],
            [-1.244370267883544, 51.645347255867421],
            [-1.251223618037161, 51.646576206513295],
            [-1.253359875463449, 51.647536598772419],
            [-1.258305643445995, 51.649048812460677],
            [-1.263098939568988, 51.650089610088706],
            [-1.265630418902267, 51.649605539506823],
            [-1.266137965073896, 51.648607068810691],
            [-1.267270874298615, 51.647904718536907],
            [-1.268438700129668, 51.647635061300761],
            [-1.269707461473712, 51.647739163197201],
            [-1.271076139497534, 51.64729090010217],
            [-1.272744870615756, 51.647328216401505],
            [-1.273356923509876, 51.646832980875878],
            [-1.272966083582843, 51.646148115254235],
            [-1.27415591840186, 51.645671735177139],
            [-1.273878725896382, 51.645200670887753],
            [-1.274904584086658, 51.644854544095665],
            [-1.275762835062981, 51.644958743710902],
            [-1.276796026790627, 51.644513740186589],
            [-1.280047433174293, 51.64416955936786],
            [-1.280445496120077, 51.643033686409247],
            [-1.281685963802403, 51.643184241192301],
            [-1.287183414774296, 51.642814912061816],
            [-1.287118964537419, 51.643976210072239],
            [-1.28775786417215, 51.644706590439682],
            [-1.287786406136128, 51.645836982273202],
            [-1.289471410623384, 51.646865011483222],
            [-1.289139682472802, 51.647930283256159],
            [-1.288651071010494, 51.648582798332868],
            [-1.286963638687113, 51.649181286225058],
            [-1.286573792012254, 51.650168871786981],
            [-1.285741572480716, 51.650331954163939],
            [-1.285171224593298, 51.649743145621009],
            [-1.284232960109633, 51.650399199491488],
            [-1.283217224808114, 51.650008175125677],
            [-1.281144135438484, 51.653049884461367],
            [-1.279673534987129, 51.656550215788279],
            [-1.278661198506792, 51.658033877189702],
            [-1.279571818902752, 51.658589737051365],
            [-1.279401777674354, 51.658828764173869],
            [-1.281588094452554, 51.659988553848713],
            [-1.283197601851222, 51.663810727318058],
            [-1.283815826919392, 51.665866328015163],
            [-1.280134597495431, 51.667233898080752],
            [-1.280211745411005, 51.667657864149511],
            [-1.2792007192797, 51.667859367020064],
            [-1.278129638239062, 51.668654821680576],
            [-1.275270052150184, 51.669434753301388],
            [-1.273081584895201, 51.669565995859088],
            [-1.269559158527729, 51.670281444003429],
            [-1.268972750554484, 51.669969393220448],
            [-1.268212815326322, 51.670286553923624],
            [-1.264138330170808, 51.669812438432977],
            [-1.263276892177223, 51.67023413061947],
            [-1.262512305696294, 51.669944307745567],
            [-1.261648462104923, 51.66944525632033],
            [-1.260161612933564, 51.669200329820207],
            [-1.25837408299526, 51.667356618426574],
            [-1.251910448957419, 51.666581946627396],
            [-1.249746450546848, 51.666636483298454],
            [-1.246707009988745, 51.667166409833776],
            [-1.242895403933781, 51.668300900751632],
            [-1.241570967146875, 51.669249042499239],
            [-1.234331481907949, 51.67126831053713],
            [-1.23088760658414, 51.672851679967536],
            [-1.230194088332253, 51.674863006315078],
            [-1.227822005795438, 51.675393218901711],
            [-1.226883118593144, 51.67600565225306],
            [-1.226267361951217, 51.678702609429955],
            [-1.224718460015663, 51.681662251584996],
            [-1.223068212691057, 51.682924530735107],
            [-1.221839033232389, 51.684679605373368],
            [-1.222417705337793, 51.686111265160612],
            [-1.22214899398673, 51.688286296457107],
            [-1.223853203375949, 51.692172840690141],
            [-1.228927442522123, 51.696485227416687],
            [-1.230583368144786, 51.698577576739311],
            [-1.230892685262783, 51.699194609256686],
            [-1.230083947107102, 51.70004079424352],
            [-1.230308495640894, 51.702157927490795],
            [-1.231285333048793, 51.70360563200267],
            [-1.233221045099281, 51.704149664403012],
            [-1.232913464476338, 51.707106704918786],
            [-1.232995287189129, 51.707403053658886],
            [-1.234305754849115, 51.707274030722026],
            [-1.234770975949304, 51.70766368764864],
            [-1.234504392732775, 51.709825265042795],
            [-1.235417631053224, 51.711371422932231],
            [-1.233644307636355, 51.711733014249127],
            [-1.234979494816887, 51.713063436228957],
            [-1.235685094641764, 51.714996664329036],
            [-1.236950578507127, 51.71566125223773],
            [-1.237607690412464, 51.717300136958364],
            [-1.237441347043977, 51.717981498553968],
            [-1.239444312429557, 51.717749911600627],
            [-1.242604646107487, 51.720850739049474],
            [-1.242432661862471, 51.722653286303533],
            [-1.241633314999602, 51.723704610132181],
            [-1.241328188413744, 51.725154736242786],
            [-1.233807906472675, 51.722012053138577],
            [-1.23404085004251, 51.721162993499775],
            [-1.232126379221038, 51.718808266553417],
            [-1.225226011002327, 51.714216077043716],
            [-1.223672652725399, 51.712877809172156],
            [-1.223980449639698, 51.712737778736162],
            [-1.222307024012157, 51.710978804407702],
            [-1.2212895774501, 51.711482775627552],
            [-1.220540290397061, 51.712467754461684],
            [-1.218797121244148, 51.71300195548551],
            [-1.217683078910643, 51.715073340900076],
            [-1.216592343482567, 51.715538120950747],
            [-1.215472169428494, 51.715778810406391],
            [-1.214170763608468, 51.715534544364068],
            [-1.213762687637489, 51.715945416504908],
            [-1.212428334273889, 51.716098327429641],
            [-1.210162305581058, 51.715805290533275],
            [-1.207381711684331, 51.714974656085502],
            [-1.200221880987854, 51.71213884269428],
            [-1.1944332434117, 51.713964115956927],
            [-1.189605586891123, 51.714556727492109],
            [-1.188325868256609, 51.71674987645541],
            [-1.18771408234534, 51.718498960976447],
            [-1.186455408727108, 51.719991809180186],
            [-1.184173085619949, 51.721568356078819],
            [-1.185447828418362, 51.722733481658764],
            [-1.18903460858257, 51.724308386444093],
            [-1.190137742142774, 51.726257218584038],
            [-1.190159821779959, 51.727209551702572],
            [-1.175931193909127, 51.732573795762995],
            [-1.175813415148613, 51.733476597741578],
            [-1.17662386385707, 51.734458734060794],
            [-1.177989110569909, 51.735673124536362],
            [-1.178819848892416, 51.735380418675661],
            [-1.179248817475534, 51.73624208480522],
            [-1.193183929744585, 51.736265716707848],
            [-1.194007660896448, 51.736524921816397],
            [-1.193568860434429, 51.737176479643779],
            [-1.193386558361448, 51.740014682313898],
            [-1.192226944897401, 51.741858029115505],
            [-1.194678808440346, 51.745370664786996],
            [-1.195266482813379, 51.745148108133996],
            [-1.195124117677755, 51.749248060340413],
            [-1.195080910435573, 51.751353523251105],
            [-1.194304645637085, 51.757124203718227],
            [-1.191438745622356, 51.755265815078339],
            [-1.190002059855384, 51.756048957489071],
            [-1.187821188469495, 51.755508822910848],
            [-1.186133461846011, 51.756874617025893],
            [-1.185958612761518, 51.759992480418553],
            [-1.184294696232529, 51.760024107482423],
            [-1.182685504952782, 51.762401914744146],
            [-1.179683889438805, 51.762844063673874],
            [-1.179746332909402, 51.763076474113539],
            [-1.180227719605626, 51.765859931879568],
            [-1.180707913862064, 51.765990055401865],
            [-1.188224649634062, 51.765571943344952],
            [-1.188261997273219, 51.767044970091241],
            [-1.189014000361611, 51.767697529558909],
            [-1.191562131652357, 51.768236568254977],
            [-1.192368889720614, 51.76809825167728],
            [-1.197529998795002, 51.768553476357454],
            [-1.198250696389816, 51.768855103615728],
            [-1.198241890796612, 51.769188619105407],
            [-1.200129332203763, 51.769483797755093],
            [-1.201661346865248, 51.770129889790198],
            [-1.203826499318891, 51.770047463138162],
            [-1.21072323779554, 51.772299506625096],
            [-1.215740478472766, 51.772815003614319],
            [-1.220834359144563, 51.772252744619401],
            [-1.227950383245917, 51.775228121140735],
            [-1.227999749097363, 51.776412590047691],
            [-1.231717015103602, 51.776870309872152],
            [-1.25400439518466, 51.786146163755141],
            [-1.252722891277693, 51.786493181108071],
            [-1.247925063819577, 51.78661634973767],
            [-1.247046483736407, 51.787401954370587],
            [-1.246846156027709, 51.788375317984368],
            [-1.244110111287189, 51.789431315596183],
            [-1.244365007658467, 51.790662949506299],
            [-1.246367552875445, 51.792997324263808],
            [-1.247414603488556, 51.793596547889827],
            [-1.248909647803933, 51.793851563614574],
            [-1.253349680136889, 51.796388373043975],
            [-1.253827439392594, 51.797840783589706],
            [-1.252502465279377, 51.79869192824669],
            [-1.250237305501404, 51.798869024173271],
            [-1.248248048418413, 51.799545951648611],
            [-1.246249590890971, 51.801467166180913],
            [-1.245105594571296, 51.80197412574298],
            [-1.245422952491207, 51.802770982270516],
            [-1.243555056358454, 51.803721041655209],
            [-1.242812551785708, 51.804583913350889],
            [-1.243726940744975, 51.805497005298413],
            [-1.243670533513583, 51.806527930474438],
            [-1.244769934118769, 51.80791693714022],
            [-1.241982245132365, 51.80998945464848],
            [-1.239420773544355, 51.809464920497533],
            [-1.239365202569173, 51.808371234534263],
            [-1.236600444158834, 51.808296680913301],
            [-1.236617025636775, 51.809633776162059],
            [-1.23092074246073, 51.809388977549204],
            [-1.226068121007439, 51.80663642110207],
            [-1.225407671324013, 51.806412689195959],
            [-1.224592291984571, 51.806935099805187],
            [-1.221134694130734, 51.805072660935075],
            [-1.222086144687159, 51.803638568366289],
            [-1.221895357128971, 51.802757968077188],
            [-1.222866029749182, 51.802479364139224],
            [-1.221359230501997, 51.801551398943097],
            [-1.215852190623707, 51.80308467905401],
            [-1.210376726577099, 51.802456430288473],
            [-1.210466779907835, 51.800953706377982],
            [-1.208360865083832, 51.798148683010879],
            [-1.205831651139063, 51.800553864172713],
            [-1.203834805202978, 51.798572190580884],
            [-1.202519616500354, 51.797991440342805],
            [-1.198194987365283, 51.7981751319954],
            [-1.194538592046698, 51.796988486037691],
            [-1.190698688770261, 51.798531095279259],
            [-1.191534082753866, 51.800228087071972],
            [-1.188357346322553, 51.803585106380538],
            [-1.188505641298304, 51.80493840689131],
            [-1.188039642856134, 51.806496963322942],
            [-1.188500585093664, 51.808855838688906],
            [-1.186624999434349, 51.808620802388553],
            [-1.187336463954299, 51.812129589201774],
            [-1.172072395634315, 51.812345935481858],
            [-1.170474039357149, 51.813046778650772],
            [-1.167430361709089, 51.813771546498856],
            [-1.165695399684368, 51.811807264076947],
            [-1.167171397532828, 51.81089972079301],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000180",
        LAD13CDO: "38UE",
        LAD13NM: "Vale of White Horse",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.306509711657617, 51.790109784229074],
            [-1.306326966523025, 51.789073822843015],
            [-1.305804432683746, 51.789679442415093],
            [-1.305003786835341, 51.789795196112593],
            [-1.304811225522912, 51.789494652157273],
            [-1.305630762983798, 51.788714563659909],
            [-1.305404776854599, 51.788513625321755],
            [-1.305200053658909, 51.788816317847377],
            [-1.305281250211952, 51.788438269056904],
            [-1.304046656474691, 51.787642444623515],
            [-1.305141188854609, 51.786702144973894],
            [-1.303370169172104, 51.78591123490488],
            [-1.305402213277479, 51.785281282493791],
            [-1.304224877945009, 51.784161215841188],
            [-1.304823092349668, 51.78315594258185],
            [-1.303989336600057, 51.781567664330062],
            [-1.302837587537705, 51.780959332698998],
            [-1.304216512293245, 51.776597780534424],
            [-1.303687619010128, 51.772631331582893],
            [-1.301217552667174, 51.768154347225384],
            [-1.296827759976729, 51.762294682718654],
            [-1.295558549162535, 51.759122177113731],
            [-1.295248109181359, 51.756539835598204],
            [-1.293362280769707, 51.755393825125743],
            [-1.293245978502175, 51.753469901965438],
            [-1.290740740612704, 51.753313655161378],
            [-1.290854153210596, 51.750881310981605],
            [-1.288805054802812, 51.74899246339325],
            [-1.287197767872718, 51.747941544845148],
            [-1.285284322073357, 51.747204336054175],
            [-1.28416002322541, 51.746304665799478],
            [-1.282326050740329, 51.745952717632328],
            [-1.281071433113094, 51.745330051540854],
            [-1.278772858593635, 51.745506588912733],
            [-1.275868083104451, 51.743644628882031],
            [-1.272039574088063, 51.742951138554048],
            [-1.271529496712171, 51.743327410373851],
            [-1.269687704961466, 51.743204492945353],
            [-1.269307744604955, 51.742695924340204],
            [-1.267859222258624, 51.742706693980018],
            [-1.267505330982864, 51.741841328715076],
            [-1.265480671999641, 51.741210098501973],
            [-1.264235097562329, 51.739428333689212],
            [-1.263797283866906, 51.739188223550876],
            [-1.263210107168439, 51.739316716913876],
            [-1.262750534042898, 51.73836705542476],
            [-1.261337630203021, 51.737533687455802],
            [-1.259481580517185, 51.735729151692169],
            [-1.258682451113059, 51.735803238686707],
            [-1.256754516574066, 51.733447939351365],
            [-1.255824322545787, 51.733128259258542],
            [-1.253311586797481, 51.730690075125715],
            [-1.252963719001102, 51.729920908459555],
            [-1.254006660157644, 51.729279259480805],
            [-1.253079379066576, 51.728261851709661],
            [-1.25352728642751, 51.727967085033633],
            [-1.248688765117345, 51.72803696411421],
            [-1.247663611375063, 51.72830194488688],
            [-1.246686207726165, 51.727522434590647],
            [-1.246144711300574, 51.727260913279366],
            [-1.245931743938301, 51.727442969778927],
            [-1.241328188413744, 51.725154736242786],
            [-1.241633314999602, 51.723704610132181],
            [-1.242432661862471, 51.722653286303533],
            [-1.242604646107487, 51.720850739049474],
            [-1.239444312429557, 51.717749911600627],
            [-1.237441347043977, 51.717981498553968],
            [-1.237607690412464, 51.717300136958364],
            [-1.236950578507127, 51.71566125223773],
            [-1.235685094641764, 51.714996664329036],
            [-1.234979494816887, 51.713063436228957],
            [-1.233644307636355, 51.711733014249127],
            [-1.235417631053224, 51.711371422932231],
            [-1.234504392732775, 51.709825265042795],
            [-1.234770975949304, 51.70766368764864],
            [-1.234305754849115, 51.707274030722026],
            [-1.232995287189129, 51.707403053658886],
            [-1.232913464476338, 51.707106704918786],
            [-1.233221045099281, 51.704149664403012],
            [-1.231285333048793, 51.70360563200267],
            [-1.230308495640894, 51.702157927490795],
            [-1.230083947107102, 51.70004079424352],
            [-1.230892685262783, 51.699194609256686],
            [-1.230583368144786, 51.698577576739311],
            [-1.228927442522123, 51.696485227416687],
            [-1.223853203375949, 51.692172840690141],
            [-1.22214899398673, 51.688286296457107],
            [-1.222417705337793, 51.686111265160612],
            [-1.221839033232389, 51.684679605373368],
            [-1.223068212691057, 51.682924530735107],
            [-1.224718460015663, 51.681662251584996],
            [-1.226267361951217, 51.678702609429955],
            [-1.226883118593144, 51.67600565225306],
            [-1.227822005795438, 51.675393218901711],
            [-1.230194088332253, 51.674863006315078],
            [-1.23088760658414, 51.672851679967536],
            [-1.234331481907949, 51.67126831053713],
            [-1.241570967146875, 51.669249042499239],
            [-1.242895403933781, 51.668300900751632],
            [-1.246707009988745, 51.667166409833776],
            [-1.249746450546848, 51.666636483298454],
            [-1.251910448957419, 51.666581946627396],
            [-1.25837408299526, 51.667356618426574],
            [-1.260161612933564, 51.669200329820207],
            [-1.261648462104923, 51.66944525632033],
            [-1.262512305696294, 51.669944307745567],
            [-1.263276892177223, 51.67023413061947],
            [-1.264138330170808, 51.669812438432977],
            [-1.268212815326322, 51.670286553923624],
            [-1.268972750554484, 51.669969393220448],
            [-1.269559158527729, 51.670281444003429],
            [-1.273081584895201, 51.669565995859088],
            [-1.275270052150184, 51.669434753301388],
            [-1.278129638239062, 51.668654821680576],
            [-1.2792007192797, 51.667859367020064],
            [-1.280211745411005, 51.667657864149511],
            [-1.280134597495431, 51.667233898080752],
            [-1.283815826919392, 51.665866328015163],
            [-1.283197601851222, 51.663810727318058],
            [-1.281588094452554, 51.659988553848713],
            [-1.279401777674354, 51.658828764173869],
            [-1.279571818902752, 51.658589737051365],
            [-1.278661198506792, 51.658033877189702],
            [-1.279673534987129, 51.656550215788279],
            [-1.281144135438484, 51.653049884461367],
            [-1.283217224808114, 51.650008175125677],
            [-1.284232960109633, 51.650399199491488],
            [-1.285171224593298, 51.649743145621009],
            [-1.285741572480716, 51.650331954163939],
            [-1.286573792012254, 51.650168871786981],
            [-1.286963638687113, 51.649181286225058],
            [-1.288651071010494, 51.648582798332868],
            [-1.289139682472802, 51.647930283256159],
            [-1.289471410623384, 51.646865011483222],
            [-1.287786406136128, 51.645836982273202],
            [-1.28775786417215, 51.644706590439682],
            [-1.287118964537419, 51.643976210072239],
            [-1.287183414774296, 51.642814912061816],
            [-1.281685963802403, 51.643184241192301],
            [-1.280445496120077, 51.643033686409247],
            [-1.280047433174293, 51.64416955936786],
            [-1.276796026790627, 51.644513740186589],
            [-1.275762835062981, 51.644958743710902],
            [-1.274904584086658, 51.644854544095665],
            [-1.273878725896382, 51.645200670887753],
            [-1.27415591840186, 51.645671735177139],
            [-1.272966083582843, 51.646148115254235],
            [-1.273356923509876, 51.646832980875878],
            [-1.272744870615756, 51.647328216401505],
            [-1.271076139497534, 51.64729090010217],
            [-1.269707461473712, 51.647739163197201],
            [-1.268438700129668, 51.647635061300761],
            [-1.267270874298615, 51.647904718536907],
            [-1.266137965073896, 51.648607068810691],
            [-1.265630418902267, 51.649605539506823],
            [-1.263098939568988, 51.650089610088706],
            [-1.258305643445995, 51.649048812460677],
            [-1.253359875463449, 51.647536598772419],
            [-1.251223618037161, 51.646576206513295],
            [-1.244370267883544, 51.645347255867421],
            [-1.2424773287118, 51.644546517280865],
            [-1.242258691943319, 51.645178103199029],
            [-1.23848991990829, 51.643254744813781],
            [-1.237349085875876, 51.643375921287543],
            [-1.236703219144123, 51.642679386698056],
            [-1.232603698014878, 51.641542236357722],
            [-1.227977081656627, 51.641076735866562],
            [-1.223227156657718, 51.64140597949541],
            [-1.222516744386541, 51.641540646981674],
            [-1.221895907467895, 51.642352059396188],
            [-1.220292369844092, 51.642992406775249],
            [-1.219341701241266, 51.642640821874238],
            [-1.219074649726946, 51.641865783442491],
            [-1.221073888734649, 51.639973771703225],
            [-1.221768631561211, 51.639909142364253],
            [-1.235341948047932, 51.630298317659189],
            [-1.235684696141864, 51.629590224203262],
            [-1.241761427539907, 51.624467618598175],
            [-1.24300665695524, 51.622644091874115],
            [-1.244598344875353, 51.623263960057677],
            [-1.247312284343632, 51.623460313150794],
            [-1.250394435947046, 51.623316379785592],
            [-1.253204516297381, 51.62267340890557],
            [-1.255459901096264, 51.621142996518401],
            [-1.257547079606537, 51.617164004071554],
            [-1.263828773930487, 51.618579237647083],
            [-1.264939360734592, 51.619214695722491],
            [-1.266072639479032, 51.617728303099],
            [-1.266569977020063, 51.616174989644598],
            [-1.266377023238693, 51.61479449560084],
            [-1.269845477543522, 51.613667001914216],
            [-1.273287793647282, 51.61163290549829],
            [-1.272803750487558, 51.610111252226019],
            [-1.270499431173747, 51.609719320196277],
            [-1.268946656618298, 51.608841988755437],
            [-1.269138235836091, 51.608512295325674],
            [-1.265748887249189, 51.607175702430531],
            [-1.266102728719485, 51.604126209778521],
            [-1.265080714117478, 51.603750268988904],
            [-1.271543741084244, 51.600228115755883],
            [-1.270582970782616, 51.598284488938155],
            [-1.283985131435446, 51.586905530505476],
            [-1.288694203126203, 51.581309973478],
            [-1.290242543347855, 51.57789538095728],
            [-1.287676796348928, 51.577889733751711],
            [-1.287654422542428, 51.578213292034718],
            [-1.286977456251963, 51.578222670188218],
            [-1.286408938222729, 51.575913795054014],
            [-1.285729190990001, 51.575273962356846],
            [-1.285431074904608, 51.575420508221939],
            [-1.284147045025433, 51.574852515637133],
            [-1.282575049396148, 51.576263578640649],
            [-1.273650013553962, 51.581229560893078],
            [-1.271152148372795, 51.583104095782524],
            [-1.270493115297147, 51.584062093573991],
            [-1.269506945956907, 51.584537007654077],
            [-1.264735823173571, 51.58459714311909],
            [-1.263329664396736, 51.584262837037798],
            [-1.258016517412026, 51.583929066120781],
            [-1.253013006202608, 51.584521351693468],
            [-1.233428579335549, 51.582724500806293],
            [-1.232752426143023, 51.582595102274475],
            [-1.230080356205716, 51.580324335616162],
            [-1.227797904677153, 51.581562764747147],
            [-1.226979644740137, 51.581304718515455],
            [-1.226833220866612, 51.582279334639011],
            [-1.224831632897198, 51.581334615178378],
            [-1.224387671614856, 51.58147195103313],
            [-1.224287012442884, 51.582637486684405],
            [-1.22365578786048, 51.583176402652796],
            [-1.221698480207493, 51.584457324584768],
            [-1.220833648329804, 51.584474067526287],
            [-1.220909482824195, 51.584850416340501],
            [-1.220271382600194, 51.584606106950382],
            [-1.220853670445224, 51.585075733170946],
            [-1.220214281311975, 51.585159605462444],
            [-1.220241877650478, 51.585824262104111],
            [-1.219058746211153, 51.585883835373345],
            [-1.216777438570597, 51.587105873308758],
            [-1.2164648026048, 51.586904175724754],
            [-1.215607215976453, 51.587922583931821],
            [-1.214139728939247, 51.588815519926342],
            [-1.210682913599461, 51.589134010914911],
            [-1.209841197714205, 51.589643563744247],
            [-1.209661912414613, 51.589327653934859],
            [-1.208695886689331, 51.589679909342003],
            [-1.209108592662068, 51.590518901178115],
            [-1.208869810917472, 51.591218631000309],
            [-1.208010578232027, 51.591071671862117],
            [-1.207291088008306, 51.5915217875677],
            [-1.203890964840634, 51.592133585231025],
            [-1.203486844733912, 51.590808192063001],
            [-1.202232039170704, 51.589841177149864],
            [-1.203110223162974, 51.58972755319688],
            [-1.20269775574073, 51.589294059202487],
            [-1.207666997720135, 51.579535023101407],
            [-1.211712022028581, 51.573035293312742],
            [-1.212407697105409, 51.572858333106169],
            [-1.212179963857298, 51.572509731550085],
            [-1.212653552079894, 51.572324985303638],
            [-1.211790765471253, 51.570901229181118],
            [-1.213979768985526, 51.570558041841174],
            [-1.213911051852485, 51.569773518861162],
            [-1.214252594357793, 51.569713763597484],
            [-1.217220011319043, 51.56402934730967],
            [-1.219167961079545, 51.562576696551638],
            [-1.220651434105489, 51.560523891295503],
            [-1.22178352836008, 51.560373150271914],
            [-1.220750035382285, 51.55728578573693],
            [-1.22280651672856, 51.551979109481991],
            [-1.223693534245037, 51.552063205721929],
            [-1.227992966899348, 51.546865659650344],
            [-1.228722495409649, 51.543719806828264],
            [-1.22872387444718, 51.541419769342021],
            [-1.22681123348921, 51.535157132086248],
            [-1.229960565501796, 51.534612265725656],
            [-1.232536095179419, 51.53586368080677],
            [-1.235679402509205, 51.536796838723269],
            [-1.242291380628559, 51.537847666649121],
            [-1.256790801874561, 51.537032224964818],
            [-1.260674324450728, 51.537788665791354],
            [-1.266362630843078, 51.541180911013562],
            [-1.267911319562325, 51.54216706451804],
            [-1.270218201209698, 51.5446190422143],
            [-1.28034305232901, 51.546754107341151],
            [-1.283775536596824, 51.548621965318297],
            [-1.285539567367372, 51.548329700222475],
            [-1.286554619222202, 51.551572829716662],
            [-1.287578956057219, 51.552845059669792],
            [-1.289522850890497, 51.552311054482665],
            [-1.294209639938785, 51.553845409227165],
            [-1.297156827431937, 51.552877619578624],
            [-1.297470333735609, 51.553405500955222],
            [-1.302150111646242, 51.555140006558993],
            [-1.302763472866488, 51.556249609087772],
            [-1.30339297092006, 51.556587830979815],
            [-1.306101499407945, 51.556895197367162],
            [-1.307881555141446, 51.556794210590887],
            [-1.309400408207274, 51.557177202788132],
            [-1.310831819672938, 51.556952734206213],
            [-1.311782532316021, 51.557229855500275],
            [-1.315324666549735, 51.557264054750341],
            [-1.320424738511753, 51.558938237069789],
            [-1.326315069331563, 51.559580911830565],
            [-1.328738303903391, 51.563711104747476],
            [-1.333151082820674, 51.560376126667585],
            [-1.334802297026896, 51.554871000150897],
            [-1.336460374231314, 51.552833015322584],
            [-1.337019357978212, 51.550891307882097],
            [-1.338088011017387, 51.549304040727549],
            [-1.338317379531906, 51.547509726443984],
            [-1.3379690183776, 51.545775994213713],
            [-1.342621677248656, 51.543638780246575],
            [-1.344005450835164, 51.543376775635309],
            [-1.346721229294428, 51.543947597574217],
            [-1.349372213970944, 51.546336982697738],
            [-1.35045588841641, 51.546898660075826],
            [-1.358798337880504, 51.546604645306992],
            [-1.361188809641082, 51.547982590429505],
            [-1.363193493210192, 51.550042648199138],
            [-1.365527570677242, 51.551142359949871],
            [-1.367337993298329, 51.552465789910627],
            [-1.369750201652049, 51.551997719335525],
            [-1.372843240363766, 51.550575642966244],
            [-1.377968082214821, 51.549685776029705],
            [-1.376977278036622, 51.545778206604901],
            [-1.382174631212273, 51.541190331115921],
            [-1.383795596019372, 51.540425580857267],
            [-1.386577553833997, 51.539958206197397],
            [-1.388920111367194, 51.539889500730638],
            [-1.392475925835506, 51.540423171427214],
            [-1.395866896743873, 51.540344462981565],
            [-1.39823141533476, 51.543422713400709],
            [-1.400303242901027, 51.544764957951713],
            [-1.404343897200468, 51.546308690810989],
            [-1.404874047906695, 51.547234806753238],
            [-1.409900883840093, 51.544701201562752],
            [-1.413803553372551, 51.542079950025041],
            [-1.416226855810298, 51.539649033874305],
            [-1.417506302649066, 51.538819179411036],
            [-1.418039249395005, 51.538974678406539],
            [-1.419352831257046, 51.537724170406598],
            [-1.422784632160388, 51.535764761691198],
            [-1.425663115521902, 51.54200999395259],
            [-1.426607217198319, 51.545598620094921],
            [-1.431008500703443, 51.542584505940248],
            [-1.437149916854693, 51.540008376206458],
            [-1.439664249142239, 51.539565434851518],
            [-1.4478473908477, 51.536426614121702],
            [-1.454943373194664, 51.535378099127556],
            [-1.456771992883516, 51.53545850754054],
            [-1.461856556739718, 51.536478184704471],
            [-1.463221958802168, 51.537210948941905],
            [-1.464437942243452, 51.538516672804285],
            [-1.464802172808167, 51.537949172413349],
            [-1.464814720721238, 51.534787819685647],
            [-1.463003185264052, 51.530191183081115],
            [-1.470759956877936, 51.528607041644825],
            [-1.470726404054221, 51.530009565094112],
            [-1.476423075614023, 51.530802087458426],
            [-1.476378899596101, 51.531763081744799],
            [-1.477086705295166, 51.532647403698221],
            [-1.485871914577162, 51.534078991481998],
            [-1.488031824175226, 51.53624369142323],
            [-1.487598390500459, 51.53889338976245],
            [-1.486046864950205, 51.540566216658178],
            [-1.489352484935771, 51.542689143845301],
            [-1.496669219143518, 51.545879445698475],
            [-1.501243949490925, 51.546694714283511],
            [-1.504755852388924, 51.546659228696534],
            [-1.508958217151681, 51.549064113821728],
            [-1.513755253364476, 51.550925529568339],
            [-1.516694665440648, 51.551184931118399],
            [-1.521321532613672, 51.550895478756438],
            [-1.5307334922619, 51.548095769855998],
            [-1.530198927058277, 51.546929238207085],
            [-1.530973524630318, 51.546069154982511],
            [-1.532327668078409, 51.547192195123024],
            [-1.534798840533987, 51.548384388964188],
            [-1.540287811455477, 51.549516453210458],
            [-1.540257492519027, 51.550951370149193],
            [-1.541041774639416, 51.551840995338502],
            [-1.542119486970228, 51.552238129275167],
            [-1.542969567707118, 51.552324162521856],
            [-1.54708927095536, 51.551396023768056],
            [-1.547379514766552, 51.55310551777734],
            [-1.555022576952867, 51.553370307031756],
            [-1.556384784481739, 51.553005013979075],
            [-1.561065160904634, 51.550293700218859],
            [-1.566114627406866, 51.545561386674621],
            [-1.569883087476666, 51.547513808360435],
            [-1.570271966401198, 51.544655951285883],
            [-1.569373558422307, 51.543282362269061],
            [-1.576696191264404, 51.539061427103221],
            [-1.581200769285474, 51.536994260231403],
            [-1.581531039488917, 51.534466139446749],
            [-1.583354694471035, 51.528315267552479],
            [-1.585154700616433, 51.525666459277566],
            [-1.584504244399608, 51.52523166890952],
            [-1.584721927058816, 51.524911443368516],
            [-1.587928096429621, 51.524858901497964],
            [-1.589113369902513, 51.524489909561325],
            [-1.59004614279272, 51.521738181135795],
            [-1.592112361577435, 51.521484625389157],
            [-1.594136432801749, 51.520132128411603],
            [-1.594055981790224, 51.519778484939813],
            [-1.598073610618758, 51.518696247089977],
            [-1.602825901206881, 51.518294317471891],
            [-1.60339763530307, 51.520335518566299],
            [-1.60450462017921, 51.520491209104563],
            [-1.604811066676563, 51.522720329425098],
            [-1.607905023318057, 51.527492587207277],
            [-1.608286711418919, 51.528873152413354],
            [-1.612836319236547, 51.534130265441348],
            [-1.613763391680725, 51.536509760385201],
            [-1.615455696653388, 51.536721219320647],
            [-1.615357551696333, 51.538102883951829],
            [-1.617799609503271, 51.539183538328899],
            [-1.622274874026203, 51.542353118155518],
            [-1.6236054832871, 51.543616192694969],
            [-1.623016507299698, 51.544217628893229],
            [-1.625397966654471, 51.54553979848783],
            [-1.624816945832319, 51.546050455400795],
            [-1.627161249809413, 51.547349092176113],
            [-1.626901339140751, 51.547565859348666],
            [-1.629237874923941, 51.549660171025103],
            [-1.628984489583811, 51.54978345246721],
            [-1.630018015433614, 51.550916036677734],
            [-1.631199219261263, 51.551806306893617],
            [-1.630790330881402, 51.552074764828738],
            [-1.631993307374281, 51.55313233575427],
            [-1.633049524789459, 51.552537711208835],
            [-1.63408365253357, 51.553255756189813],
            [-1.633867434686029, 51.553419625314419],
            [-1.638925370042341, 51.558919151064742],
            [-1.6386813059973, 51.559319417449451],
            [-1.643799664110312, 51.564074423834114],
            [-1.645991629145976, 51.567717173722961],
            [-1.646573269958575, 51.569180027361476],
            [-1.646111750988539, 51.570086768609791],
            [-1.646885146458449, 51.571200432505641],
            [-1.647704671541925, 51.571950977228489],
            [-1.651383553367524, 51.573904093869835],
            [-1.655057847341297, 51.576505359446173],
            [-1.65832774450671, 51.574197836724835],
            [-1.662436241769598, 51.573562335884127],
            [-1.673254984442301, 51.570605090829112],
            [-1.676552959600937, 51.569438144902804],
            [-1.678185250949814, 51.570189812616974],
            [-1.679306109198158, 51.571453470437682],
            [-1.680322259936621, 51.571813198231006],
            [-1.67990169738281, 51.572115961729196],
            [-1.680417823255224, 51.572177609326324],
            [-1.680397988802011, 51.572537210711189],
            [-1.681452633501662, 51.572141757151748],
            [-1.681697983899635, 51.572753836306376],
            [-1.682536557033771, 51.572512439126136],
            [-1.682614038779393, 51.572782389985662],
            [-1.683319407378285, 51.572813965267763],
            [-1.683159396218325, 51.572999655399613],
            [-1.684063361471395, 51.57289958838696],
            [-1.683532616290234, 51.573279394314575],
            [-1.684716438491501, 51.573816665675778],
            [-1.684635115649963, 51.574934975024568],
            [-1.685226825710334, 51.574713575861708],
            [-1.685431601999393, 51.57598730354492],
            [-1.688322859695648, 51.578813806103391],
            [-1.689149997647833, 51.581954884846247],
            [-1.691123790817938, 51.583522790293244],
            [-1.690442484991766, 51.58438596625934],
            [-1.689031773809489, 51.586169723424078],
            [-1.687379787590002, 51.586707518154455],
            [-1.686984260974645, 51.587344851545232],
            [-1.685386148790306, 51.587595937346812],
            [-1.685671059354063, 51.587932976806087],
            [-1.685178273084522, 51.588017974354393],
            [-1.685741355439821, 51.588414202535716],
            [-1.684969726789436, 51.588327617191808],
            [-1.684965891241556, 51.588673774189758],
            [-1.685699983996797, 51.588971556032156],
            [-1.6857565597835, 51.589558842963704],
            [-1.684769708892554, 51.590325859285109],
            [-1.685442341456341, 51.590538958972715],
            [-1.684931560402942, 51.590926016806435],
            [-1.685517316813774, 51.590969845565944],
            [-1.685058128615994, 51.59182818892544],
            [-1.685454744569474, 51.592297700909015],
            [-1.684853808719919, 51.592359029968279],
            [-1.684978109908769, 51.592754083668822],
            [-1.685681641338036, 51.592469143839558],
            [-1.685445207678568, 51.593679646025464],
            [-1.686511386319822, 51.593561112462986],
            [-1.686332403823945, 51.594187331816364],
            [-1.686860318556273, 51.594251679472293],
            [-1.687057079827368, 51.594819557804506],
            [-1.687593589068952, 51.594684317117554],
            [-1.687929018168056, 51.595453069604694],
            [-1.687504449413889, 51.5956839178736],
            [-1.687832214264406, 51.597361675045875],
            [-1.687525508452176, 51.597455268866966],
            [-1.688105270376103, 51.597965719347911],
            [-1.687979798701445, 51.598368198354372],
            [-1.688458020532438, 51.598528614112297],
            [-1.687843115225057, 51.599353286324309],
            [-1.688411633443034, 51.599400651205947],
            [-1.688203860582692, 51.599803811203699],
            [-1.687508138774485, 51.599567288933237],
            [-1.687312184882335, 51.600140415105017],
            [-1.688771946568372, 51.601183689591657],
            [-1.688588940754997, 51.601553647805773],
            [-1.689270435255586, 51.601552754636849],
            [-1.689554145796948, 51.601235211244116],
            [-1.689681295210906, 51.601645552099129],
            [-1.690176422626315, 51.601449049303604],
            [-1.690886940588231, 51.601851934293968],
            [-1.690377139263103, 51.602507858664957],
            [-1.689811938461253, 51.602603474358993],
            [-1.690362864889081, 51.603758515672247],
            [-1.690932250353959, 51.603899380406176],
            [-1.690630061496262, 51.605448690796351],
            [-1.688290291674228, 51.606586204342833],
            [-1.688460290327467, 51.607065893035603],
            [-1.686957579534768, 51.607413460175522],
            [-1.686549222390078, 51.607999505276553],
            [-1.685269845012313, 51.608620982700522],
            [-1.68381421650698, 51.608831967519748],
            [-1.682510223265389, 51.609454247735265],
            [-1.681772134560335, 51.611751332835659],
            [-1.682337329717315, 51.611882337185783],
            [-1.682673728799278, 51.612517134323141],
            [-1.6813334542088, 51.613560097014677],
            [-1.680881038449252, 51.613407815053876],
            [-1.679236219585428, 51.613798954707669],
            [-1.678716415625592, 51.61337314428215],
            [-1.678222055483274, 51.613435629943851],
            [-1.677828549107014, 51.612511142956571],
            [-1.677411272169292, 51.612498309415379],
            [-1.677420844975372, 51.612777965821877],
            [-1.676611538213764, 51.612450255410558],
            [-1.676455510467329, 51.611848306364777],
            [-1.674968730326682, 51.61174979421709],
            [-1.672467917960792, 51.612890142880993],
            [-1.671830490060751, 51.613566310092132],
            [-1.670033159452903, 51.614065689845376],
            [-1.669405537519508, 51.615174353859658],
            [-1.66702320917481, 51.616262769859617],
            [-1.667087746659414, 51.617905666461319],
            [-1.668457392137672, 51.618644129343139],
            [-1.669705009076821, 51.618887710903813],
            [-1.669724486296234, 51.619388581837143],
            [-1.670690560790781, 51.619420065355392],
            [-1.67231644831416, 51.62029317127827],
            [-1.672133937636856, 51.620765605173276],
            [-1.673355245159878, 51.621465832917373],
            [-1.672943178556771, 51.621721838237328],
            [-1.673124643271834, 51.622601693128559],
            [-1.673749791282816, 51.622847993949129],
            [-1.672687746962269, 51.623094998962323],
            [-1.67081718912143, 51.625080449142651],
            [-1.669276021588898, 51.625649767131648],
            [-1.668671976681375, 51.626471669010144],
            [-1.669132052304196, 51.627569904105741],
            [-1.668523455665882, 51.627822642847043],
            [-1.668493687219835, 51.628339558653821],
            [-1.667558806740704, 51.628560801086515],
            [-1.667443797507854, 51.629076576278884],
            [-1.666900266721738, 51.628923083956487],
            [-1.665184313192509, 51.630053808662069],
            [-1.663897353437206, 51.630404392132967],
            [-1.663494674011559, 51.630740413438978],
            [-1.663719031804312, 51.631074632781363],
            [-1.662838120355523, 51.631215969489155],
            [-1.660134161540579, 51.634610486626386],
            [-1.659999852761201, 51.635181944292363],
            [-1.661533016202058, 51.636913596608665],
            [-1.662341043500873, 51.638626063702773],
            [-1.663233916730708, 51.639231041681789],
            [-1.662765289960906, 51.639485050396289],
            [-1.663183037904305, 51.63985758837336],
            [-1.664604061040905, 51.640372357102137],
            [-1.670605154592015, 51.640807423968596],
            [-1.672409442806134, 51.641494905001238],
            [-1.674262883521586, 51.641178168612406],
            [-1.675650023652543, 51.641401392059187],
            [-1.675717054380437, 51.64172885955206],
            [-1.67488147014861, 51.64217701414794],
            [-1.677303887031107, 51.643140361835975],
            [-1.67807017112128, 51.644315824523005],
            [-1.679763291184025, 51.644817667150235],
            [-1.680012131752037, 51.646629186853218],
            [-1.680895752768761, 51.64696066955517],
            [-1.680780487257016, 51.647321805568936],
            [-1.681444776862052, 51.647399134451327],
            [-1.681827958925525, 51.648613094260632],
            [-1.682630102258936, 51.648822061956558],
            [-1.682964719321454, 51.649337265340762],
            [-1.683357371680582, 51.649198959140797],
            [-1.683223847146986, 51.650108515320177],
            [-1.684105676773425, 51.650502907594792],
            [-1.684463324580297, 51.650202660903119],
            [-1.684918978875448, 51.650985223904364],
            [-1.685864323676557, 51.65076836732797],
            [-1.685617702270212, 51.651526570455481],
            [-1.685860984663236, 51.651879678295998],
            [-1.686544579838806, 51.651893191841644],
            [-1.686247956357495, 51.652357248611814],
            [-1.689098409234111, 51.652563529207185],
            [-1.691951559354631, 51.65216193859446],
            [-1.691630215829312, 51.652655615251895],
            [-1.692710077577033, 51.653388533229254],
            [-1.69146741253234, 51.65406861365566],
            [-1.690842592073806, 51.653909625044975],
            [-1.690557701151084, 51.654778329856256],
            [-1.688942597911212, 51.65569027595572],
            [-1.688508661089839, 51.65678516065153],
            [-1.68923190670933, 51.657353523764357],
            [-1.689103845643469, 51.658323341085712],
            [-1.689604514436629, 51.658464927646492],
            [-1.689854917954523, 51.659267607961404],
            [-1.689168862984772, 51.659597572986556],
            [-1.689585011641778, 51.661110101169804],
            [-1.690993497285363, 51.662334822902579],
            [-1.690636677144723, 51.663780575325511],
            [-1.691439817465332, 51.664314068410192],
            [-1.690984834189617, 51.664460329669616],
            [-1.6915888454301, 51.664937552381701],
            [-1.692343763988129, 51.664909859339467],
            [-1.694221898323709, 51.666417195201554],
            [-1.694112169234647, 51.668757328129985],
            [-1.695850196967137, 51.668760938252198],
            [-1.697747460786561, 51.669607410539513],
            [-1.699035039955396, 51.669510011926079],
            [-1.700202527932982, 51.670738499409431],
            [-1.700170749564511, 51.672267826167541],
            [-1.699035713749385, 51.67312089081318],
            [-1.698493393100896, 51.674215531566354],
            [-1.698756118581203, 51.676468506092434],
            [-1.697781988165749, 51.677463132425665],
            [-1.695576873853061, 51.678049964955107],
            [-1.694899413871918, 51.678571497819924],
            [-1.695209478462189, 51.679781620649116],
            [-1.696369984732669, 51.680130785057365],
            [-1.696861628938983, 51.681014091757298],
            [-1.696531752738675, 51.68211286612749],
            [-1.695046137096621, 51.683215840515601],
            [-1.693294398310534, 51.683875734597883],
            [-1.692795950211144, 51.685287851367001],
            [-1.693025957861899, 51.685713736190785],
            [-1.691186665452122, 51.686679970203294],
            [-1.691117335771251, 51.687302878633353],
            [-1.688740332270266, 51.687559153516219],
            [-1.686520931907727, 51.689289458928926],
            [-1.683924755852352, 51.688800580247865],
            [-1.683853589695251, 51.689672535652257],
            [-1.683072272949797, 51.690106505633707],
            [-1.681351645016647, 51.690393170271037],
            [-1.679126515866125, 51.689393591120833],
            [-1.679197628955194, 51.689156417371535],
            [-1.677647315014779, 51.688887835890689],
            [-1.677338806949696, 51.689145037274493],
            [-1.676029123142517, 51.688403257966215],
            [-1.675021334739751, 51.687324229315337],
            [-1.676038802233051, 51.686843310140908],
            [-1.673782663573353, 51.684763698926503],
            [-1.673876525253735, 51.683979925549494],
            [-1.674403555238667, 51.683705355975462],
            [-1.676277458849727, 51.684207744513643],
            [-1.675221954818334, 51.682531568657076],
            [-1.674662988107487, 51.682218927192274],
            [-1.673553475846036, 51.682236531588643],
            [-1.672666402580441, 51.682698011966416],
            [-1.672042652227701, 51.681944608026562],
            [-1.669092615190632, 51.681267395406486],
            [-1.668472420299205, 51.680433960392115],
            [-1.667127251645794, 51.681036167714026],
            [-1.665876078359153, 51.681046105429232],
            [-1.665324001414881, 51.681772823911949],
            [-1.663306973104437, 51.681291429339751],
            [-1.662181324847525, 51.681536357963729],
            [-1.662294175316093, 51.680753546887736],
            [-1.661903700438196, 51.680359508249168],
            [-1.660564706931465, 51.681285342224839],
            [-1.659576495607514, 51.680752002900235],
            [-1.655591058189628, 51.681804056616791],
            [-1.652668633047583, 51.681521226798147],
            [-1.648267728541082, 51.684083228081704],
            [-1.646814295074377, 51.684794571749208],
            [-1.642860670066824, 51.685581048220122],
            [-1.641412869104098, 51.685378834425705],
            [-1.63915513441399, 51.686804227427665],
            [-1.63661969398868, 51.686760453242286],
            [-1.63535918519812, 51.68755227313433],
            [-1.634076017194661, 51.687021400976377],
            [-1.633523350178096, 51.686314767995],
            [-1.631498204594458, 51.685780653116929],
            [-1.629928418980622, 51.684932345640462],
            [-1.630307634385873, 51.684014635828511],
            [-1.628315795252816, 51.683846513264918],
            [-1.624580835431046, 51.684749052845902],
            [-1.623725293819695, 51.685354124325869],
            [-1.622191088579387, 51.685812251806155],
            [-1.614858773453908, 51.687682010564217],
            [-1.611971096955318, 51.689920319864193],
            [-1.609923399423995, 51.690675099605365],
            [-1.608346495667816, 51.690846983859466],
            [-1.607203237411875, 51.691895142052886],
            [-1.606058691053112, 51.692251860199299],
            [-1.603890525129716, 51.691535167960311],
            [-1.603984471225657, 51.690881822886567],
            [-1.602426940048081, 51.690489943517946],
            [-1.600799427223204, 51.688681688377962],
            [-1.598458232623099, 51.687930140785518],
            [-1.594164298127538, 51.690250416557738],
            [-1.591703847002115, 51.691020536802689],
            [-1.588095035049558, 51.692702792947259],
            [-1.585531118531365, 51.693211675970439],
            [-1.584647076844572, 51.693061999023271],
            [-1.584604265267603, 51.693471847395791],
            [-1.585028176351038, 51.693470647124393],
            [-1.583955892137522, 51.695075386292885],
            [-1.580132475244848, 51.696116463229302],
            [-1.578898738540587, 51.696851128580725],
            [-1.579128631962866, 51.697648573497517],
            [-1.576996715532302, 51.699581226050519],
            [-1.574866253485626, 51.700103123759533],
            [-1.572072609532951, 51.700361825668921],
            [-1.570829372221614, 51.699473457554021],
            [-1.567313605530678, 51.699667368610051],
            [-1.565633720876243, 51.698681131371259],
            [-1.563739222156227, 51.699182120175472],
            [-1.562822229344446, 51.698693188039755],
            [-1.560505796649002, 51.699580076425676],
            [-1.560071263427405, 51.699327596219121],
            [-1.560923152071732, 51.698916285827266],
            [-1.560821751426866, 51.698629087212609],
            [-1.558209245759726, 51.699009517519315],
            [-1.557116096734275, 51.698489308481783],
            [-1.556600472675475, 51.699431442859506],
            [-1.555453363008919, 51.699694150000298],
            [-1.555069144141189, 51.699330350394163],
            [-1.55275472513471, 51.6999941115293],
            [-1.551926061944302, 51.700388367960045],
            [-1.552152798698564, 51.701019516267223],
            [-1.551633494824527, 51.701295365483233],
            [-1.549758048295362, 51.700870106274202],
            [-1.549709772255984, 51.701506499576034],
            [-1.548762540334526, 51.701449819498237],
            [-1.548363794443118, 51.702113638390465],
            [-1.547496893391075, 51.701836072766788],
            [-1.545202359919348, 51.703235243212788],
            [-1.54446512804439, 51.703013902370827],
            [-1.54426734964851, 51.702534803131961],
            [-1.543899862982554, 51.702960460800348],
            [-1.543283139876828, 51.702987737562651],
            [-1.542893280885925, 51.702185104913113],
            [-1.541101259925025, 51.702672640532803],
            [-1.539977666061821, 51.701740355525679],
            [-1.537447529395835, 51.701244901907856],
            [-1.53670853516918, 51.700210698802906],
            [-1.535672300944021, 51.699664439771666],
            [-1.536171692733391, 51.699079284232901],
            [-1.534291217164433, 51.698179920163483],
            [-1.53153532631832, 51.698414430788702],
            [-1.527119577287307, 51.698251992340232],
            [-1.526083296937019, 51.699665735881617],
            [-1.524045038783171, 51.699479474971639],
            [-1.517125413195741, 51.701683777621525],
            [-1.515865848672622, 51.701745132257521],
            [-1.513366595821225, 51.701227707900358],
            [-1.512846583628598, 51.7011428348412],
            [-1.511417348237514, 51.699626376797667],
            [-1.506947658578285, 51.700029403740679],
            [-1.50722393570876, 51.69870525444464],
            [-1.506033112666781, 51.698306440516724],
            [-1.504115703301663, 51.698328938012892],
            [-1.502660206779549, 51.697791407099032],
            [-1.501534987723776, 51.698275764434143],
            [-1.498655692208468, 51.698269813819117],
            [-1.497243329699722, 51.698677378737621],
            [-1.496816054235454, 51.698455265247283],
            [-1.497277621785692, 51.697671405875155],
            [-1.49662129585648, 51.697098552625306],
            [-1.49704199659214, 51.69647636133859],
            [-1.49587910758434, 51.696441708892728],
            [-1.494827666780251, 51.695623487635132],
            [-1.495476330225701, 51.69466870610534],
            [-1.492141574972031, 51.69388199787614],
            [-1.491148511537089, 51.694191497683036],
            [-1.489881692260917, 51.694012475863858],
            [-1.489078889737093, 51.694255349367687],
            [-1.487923736082966, 51.695592715804999],
            [-1.486588563506019, 51.69569838325063],
            [-1.484748169711816, 51.696847503834796],
            [-1.482718332610435, 51.70166149059029],
            [-1.480712668677979, 51.703313329196504],
            [-1.479053998530585, 51.703845464627264],
            [-1.4758943445161, 51.703901551814866],
            [-1.474267122212026, 51.702087046935134],
            [-1.473252213352695, 51.7018837971765],
            [-1.473754069600679, 51.701157754420144],
            [-1.47310972232524, 51.700945372181096],
            [-1.472757030582808, 51.700290127517015],
            [-1.471336417494182, 51.700759383910103],
            [-1.47093227165805, 51.699927673909713],
            [-1.469427726996554, 51.700149270276995],
            [-1.469030161767908, 51.69937692550014],
            [-1.467629187669104, 51.698911136306627],
            [-1.46743578408548, 51.699732959237501],
            [-1.46573091205252, 51.699147984878138],
            [-1.464851253508858, 51.699754489120672],
            [-1.464572063580151, 51.699625542071026],
            [-1.463120900038013, 51.70095951794611],
            [-1.462862239842874, 51.701785530781258],
            [-1.464428265630015, 51.707642375987511],
            [-1.463287755938234, 51.709975794138941],
            [-1.46319690399758, 51.710682090042432],
            [-1.463878024062063, 51.711464737758668],
            [-1.463156354157701, 51.712029690097602],
            [-1.463321420828374, 51.712390991982474],
            [-1.458955442912052, 51.713300672880095],
            [-1.455390301615778, 51.713196996423015],
            [-1.454443689157534, 51.712716971354808],
            [-1.452854009390754, 51.712634956304967],
            [-1.451541593313468, 51.711647897420399],
            [-1.450503523856433, 51.711794102586701],
            [-1.450407788968068, 51.711572470737806],
            [-1.448963666038738, 51.712260732893114],
            [-1.447927821994434, 51.711984337811693],
            [-1.445259521003378, 51.712051798904291],
            [-1.444067461999987, 51.711651452560076],
            [-1.442298054881813, 51.712419015369889],
            [-1.439996975541903, 51.711344413294867],
            [-1.43942192596326, 51.710444347028258],
            [-1.438297924035554, 51.709931875892458],
            [-1.438622426409283, 51.709322021633085],
            [-1.439445792496825, 51.709566915884189],
            [-1.440014175195328, 51.709364624505845],
            [-1.439934291278476, 51.708215163356854],
            [-1.439263027563596, 51.707843322416878],
            [-1.438126167026625, 51.707790240496571],
            [-1.430102613689097, 51.708954645066527],
            [-1.421252611507241, 51.710316739394109],
            [-1.419179066948374, 51.710282236268071],
            [-1.417890728199162, 51.709849678617701],
            [-1.416397290570047, 51.709836879131394],
            [-1.411531204270543, 51.708967442954787],
            [-1.408908381495335, 51.707886123018135],
            [-1.406334522062134, 51.707496418950264],
            [-1.406544351366736, 51.707055108694334],
            [-1.404308036665911, 51.706423400597387],
            [-1.403611453997171, 51.705910064204147],
            [-1.402244259623411, 51.705881540725585],
            [-1.401165175929498, 51.705733986545141],
            [-1.392207935864622, 51.706223892958931],
            [-1.38577357125087, 51.709019997798734],
            [-1.382292913449829, 51.711782755740593],
            [-1.378944015936601, 51.71355976554387],
            [-1.378044922542724, 51.714422668279724],
            [-1.377590540988415, 51.71557474049208],
            [-1.376331472385305, 51.71618936315938],
            [-1.376183520721149, 51.716737943708694],
            [-1.3747023759697, 51.716867640854346],
            [-1.374234181369158, 51.717229295294594],
            [-1.368731354339426, 51.723732916752432],
            [-1.368667040349768, 51.724192923121961],
            [-1.369584409916355, 51.725270501243038],
            [-1.369066705245549, 51.725955554041697],
            [-1.368761804299427, 51.725601460667541],
            [-1.368163753798658, 51.725713335957963],
            [-1.368158911839712, 51.727099759323814],
            [-1.367228571366627, 51.727885085676739],
            [-1.367010195183322, 51.729630008558402],
            [-1.368400605865228, 51.731991391437781],
            [-1.36948186156394, 51.732748861975132],
            [-1.371033007207886, 51.734709166022576],
            [-1.373633716846111, 51.736284825599178],
            [-1.374105100406038, 51.737279966883627],
            [-1.371210370057108, 51.741130746630219],
            [-1.368755610966404, 51.7420077241974],
            [-1.368412708318566, 51.743489433474451],
            [-1.367211461588063, 51.745292007232024],
            [-1.365374458240724, 51.746354758618388],
            [-1.365076760349095, 51.747442886325672],
            [-1.365413391902564, 51.748332135913067],
            [-1.367725935559072, 51.748489351751111],
            [-1.368239236983551, 51.749386735750754],
            [-1.366577730087582, 51.751666958174056],
            [-1.367734128089092, 51.752884296510956],
            [-1.367605942849851, 51.753764744862018],
            [-1.365194824488301, 51.754626597354509],
            [-1.364574203374562, 51.75518339713804],
            [-1.364737450040842, 51.755732742234102],
            [-1.366245643562791, 51.755005400027457],
            [-1.366670546531312, 51.75507871966758],
            [-1.366634517172279, 51.755894027234909],
            [-1.365297294250306, 51.756722099556335],
            [-1.366102606950684, 51.757358524363376],
            [-1.366348402516736, 51.758326402414717],
            [-1.363395473818739, 51.758546023335818],
            [-1.362651803018275, 51.758987059294192],
            [-1.363958475612331, 51.759826718274404],
            [-1.363184225085616, 51.760792677711237],
            [-1.364613033654896, 51.7605738281841],
            [-1.364733632763646, 51.760859500449016],
            [-1.363510593212212, 51.761797861377524],
            [-1.36281204273692, 51.761911863126727],
            [-1.362691156148794, 51.762573858692484],
            [-1.362022693458441, 51.762813895449895],
            [-1.359660815340002, 51.762201346416127],
            [-1.360109189437872, 51.763078629686461],
            [-1.359114292994079, 51.763653143744328],
            [-1.360366112938172, 51.764380153087473],
            [-1.360370687364998, 51.765492387638929],
            [-1.362087902540164, 51.765899125062056],
            [-1.36423772491799, 51.767800712764036],
            [-1.363212808811331, 51.768445230070853],
            [-1.36404883355439, 51.76938393717635],
            [-1.362812024352001, 51.769944586099456],
            [-1.363399277212038, 51.770746182733795],
            [-1.362512444041676, 51.771562273430099],
            [-1.363506551863168, 51.771568556735403],
            [-1.363828624084897, 51.77206031828181],
            [-1.363247078693878, 51.772293639064259],
            [-1.362146123994827, 51.772052104531106],
            [-1.361263488599617, 51.7728754014285],
            [-1.361529658240591, 51.773630306928446],
            [-1.359538090566546, 51.774462850597956],
            [-1.360010240321301, 51.774999496294463],
            [-1.358721519121193, 51.774810855052444],
            [-1.35724446504763, 51.775535572714162],
            [-1.355785407224722, 51.774992615242276],
            [-1.350881136104732, 51.776075161119884],
            [-1.348470230754473, 51.776917790131805],
            [-1.3435644802751, 51.780074286234111],
            [-1.3416424620948, 51.780866454990104],
            [-1.33893736369755, 51.782865304112995],
            [-1.337628355145327, 51.784548279080873],
            [-1.33554986758221, 51.78541229545565],
            [-1.333091635533362, 51.785281497967873],
            [-1.331529875949722, 51.786193327914312],
            [-1.330267486937265, 51.786268865470937],
            [-1.328459303216906, 51.786390726059281],
            [-1.324728471220581, 51.787696462703011],
            [-1.322360363076025, 51.787833895231834],
            [-1.320389188990193, 51.787894451915079],
            [-1.317841781726, 51.788575622165773],
            [-1.317432622621406, 51.78830800907631],
            [-1.317778209150401, 51.787599711675895],
            [-1.316347999681294, 51.787167923518794],
            [-1.314973220750949, 51.788151650982527],
            [-1.313185586716856, 51.788249120399414],
            [-1.306509711657617, 51.790109784229074],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000181",
        LAD13CDO: "38UF",
        LAD13NM: "West Oxfordshire",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.521245500311834, 51.992983033473351],
            [-1.518207495191102, 51.989857144898821],
            [-1.513429301235538, 51.987177139440085],
            [-1.510867459723946, 51.986141583804979],
            [-1.503450103000989, 51.984788916513608],
            [-1.501886777752995, 51.98408193559321],
            [-1.501404910831386, 51.983678908095719],
            [-1.501360903538748, 51.981477773500139],
            [-1.500937061224745, 51.981490364536135],
            [-1.496973828001255, 51.977192989180594],
            [-1.495397643480318, 51.97636718697364],
            [-1.49368328433304, 51.97488893565523],
            [-1.492020841622077, 51.975396055222951],
            [-1.487701610267314, 51.975505924348944],
            [-1.484377414226271, 51.976314084654284],
            [-1.480751672793239, 51.976119245647126],
            [-1.478007326352318, 51.977002605759395],
            [-1.47395839171107, 51.977014269206954],
            [-1.472218861997931, 51.977365224221053],
            [-1.470930174341335, 51.978620856980044],
            [-1.470077665410574, 51.978811227813658],
            [-1.467356102905548, 51.97872343398501],
            [-1.462782504901416, 51.979252909172359],
            [-1.46105042615135, 51.978968082316079],
            [-1.460731022487579, 51.97865014403996],
            [-1.458519974270663, 51.978969967384515],
            [-1.456162506627529, 51.979716137633368],
            [-1.454092793998645, 51.979660713794992],
            [-1.450090672972662, 51.982369919739064],
            [-1.448194736039898, 51.983093808377753],
            [-1.448171214343551, 51.9834677163199],
            [-1.44788912724358, 51.983317147349169],
            [-1.447659280089713, 51.983655024078608],
            [-1.447145334417632, 51.983532136910632],
            [-1.446678127381702, 51.983870696016744],
            [-1.446422782782373, 51.98367709317813],
            [-1.444892192803622, 51.983813745805996],
            [-1.444333758137965, 51.984227388719852],
            [-1.441040235389147, 51.985146856990077],
            [-1.437700260238197, 51.9880215144508],
            [-1.43738718922692, 51.989182532563461],
            [-1.435531814631321, 51.989072962549628],
            [-1.433614042041407, 51.988022623938491],
            [-1.431239720438694, 51.988189206110015],
            [-1.427779356676942, 51.987986343441591],
            [-1.426902140270954, 51.987345530293211],
            [-1.424468649026081, 51.986675541681294],
            [-1.426220163572758, 51.985330065339475],
            [-1.426918905848265, 51.984205117139268],
            [-1.426594570982034, 51.982920548268019],
            [-1.423141886247998, 51.98317342077771],
            [-1.423136554299918, 51.982794880760942],
            [-1.42234060899088, 51.982640834536618],
            [-1.42145135064641, 51.981931590510499],
            [-1.418102641331667, 51.980905430117851],
            [-1.415205578201606, 51.979197213739823],
            [-1.412547999688463, 51.978564524930214],
            [-1.410379345353609, 51.97607132089194],
            [-1.410639858020701, 51.973746692323189],
            [-1.41000485046167, 51.972770704322812],
            [-1.409563396038096, 51.972687575217556],
            [-1.408820432455377, 51.974830864323486],
            [-1.406943754022082, 51.97484121287625],
            [-1.406392927253848, 51.975322146610033],
            [-1.406697939227746, 51.977912147278353],
            [-1.404086213540087, 51.978447408574347],
            [-1.402104041120364, 51.979809369529171],
            [-1.401325417707386, 51.979894425265819],
            [-1.400357546675225, 51.979540658836996],
            [-1.399793623231929, 51.979139493171772],
            [-1.396556564197544, 51.978632957914215],
            [-1.384169826967842, 51.980624175569268],
            [-1.384165823604692, 51.980278906065038],
            [-1.382968607125612, 51.979989430756838],
            [-1.377334613171783, 51.979627130024966],
            [-1.375786153297367, 51.978319758255054],
            [-1.375101386185466, 51.976784086463596],
            [-1.375572273410782, 51.976319058410439],
            [-1.37493245361889, 51.975741149847551],
            [-1.37460671516797, 51.975923733875199],
            [-1.37314181568133, 51.974902679009794],
            [-1.371849462856902, 51.97476902474861],
            [-1.370254957921635, 51.973758031531865],
            [-1.368995983625525, 51.973842103181205],
            [-1.368262660060805, 51.972985839302439],
            [-1.368182256069848, 51.971663750645043],
            [-1.366988718484744, 51.970723192535516],
            [-1.367167754338781, 51.97041396932741],
            [-1.36598378072253, 51.969315212733918],
            [-1.365636111793112, 51.968267702590637],
            [-1.365158786767565, 51.968259736898034],
            [-1.364963475024282, 51.96745400091654],
            [-1.36322570257942, 51.967851907610424],
            [-1.36305829374084, 51.968572070865555],
            [-1.361412633743756, 51.968527198652382],
            [-1.362157803306004, 51.967816460792349],
            [-1.361627079607139, 51.966136787394312],
            [-1.361981463939036, 51.964652514640022],
            [-1.360634507354171, 51.961012894586922],
            [-1.361856486577564, 51.958486786982228],
            [-1.361794404689641, 51.956913943612065],
            [-1.361175651875679, 51.956216492702936],
            [-1.360750577142132, 51.953770460998946],
            [-1.360776334918435, 51.951446453034187],
            [-1.361874960547697, 51.948365832435229],
            [-1.356288727168784, 51.946784504649401],
            [-1.352466091747219, 51.947027884707182],
            [-1.349607942487255, 51.945868493135023],
            [-1.348357876353858, 51.94579055565616],
            [-1.347890592583238, 51.945407653755183],
            [-1.346433196554941, 51.945081297519309],
            [-1.344953467590107, 51.944191967423819],
            [-1.344581011283569, 51.942695604359216],
            [-1.345284307678581, 51.941152177982204],
            [-1.343305030876954, 51.941210389166258],
            [-1.340620468373407, 51.942143933266323],
            [-1.339087117243183, 51.941948327842745],
            [-1.332482602709395, 51.942140351004646],
            [-1.336453869120929, 51.938111478040874],
            [-1.337713948975497, 51.938086203114082],
            [-1.338349527555154, 51.937696871647724],
            [-1.340008783140553, 51.934951359214054],
            [-1.330190831098961, 51.93347895894054],
            [-1.330286735865574, 51.932214478607463],
            [-1.329478541301, 51.930523178492606],
            [-1.330557014595555, 51.927342022382859],
            [-1.325246566001921, 51.926721009453459],
            [-1.32513801835663, 51.923194133877352],
            [-1.324738407962521, 51.922496841834068],
            [-1.318041535963544, 51.921770441223231],
            [-1.314431341268887, 51.92193918811143],
            [-1.311762845757574, 51.920811439531043],
            [-1.306860378128801, 51.920013057524073],
            [-1.30624199588435, 51.919756769598365],
            [-1.306143333235687, 51.918705143333369],
            [-1.305287411514009, 51.91744855281032],
            [-1.306409494020403, 51.916340286140297],
            [-1.301949065589554, 51.914356591681482],
            [-1.299681837883758, 51.913744329516994],
            [-1.298936586156577, 51.913147388708524],
            [-1.299232822947517, 51.912702300023518],
            [-1.2986796751505, 51.912000406898279],
            [-1.298396802951554, 51.91009442989629],
            [-1.298806843484141, 51.908687983861746],
            [-1.300375848331288, 51.906691426241231],
            [-1.302206842399366, 51.90616463022365],
            [-1.303900583045825, 51.903981751723201],
            [-1.30277998063272, 51.900596302614112],
            [-1.302241409819126, 51.89980370174743],
            [-1.301490607032971, 51.899483665378774],
            [-1.300762509036719, 51.898637785056771],
            [-1.297016365891433, 51.897294697015603],
            [-1.294930252969389, 51.896962142175411],
            [-1.293895732492737, 51.897225672520825],
            [-1.292247040339031, 51.896433554518509],
            [-1.291350962241378, 51.896672719859943],
            [-1.289998947697216, 51.89356536987173],
            [-1.296208397460962, 51.890869384287086],
            [-1.297198183184981, 51.890762010427771],
            [-1.297177234700502, 51.889309835135542],
            [-1.298112359839787, 51.889630999127107],
            [-1.298009706109586, 51.888385130184695],
            [-1.296528302604619, 51.886106951781137],
            [-1.295378762149666, 51.885193780892834],
            [-1.294047363356942, 51.884763225641436],
            [-1.293930079976427, 51.884088194761503],
            [-1.288689790827821, 51.881676719309894],
            [-1.286150464963538, 51.875150032362306],
            [-1.288147054041241, 51.873540138223298],
            [-1.291778052234412, 51.87263866026926],
            [-1.293022017992725, 51.87204373796925],
            [-1.294064279133385, 51.872084159483684],
            [-1.294311820551046, 51.872973060565798],
            [-1.296229699937709, 51.872659067180471],
            [-1.302131021712789, 51.869108572522983],
            [-1.302306384091585, 51.868475742886339],
            [-1.30133019578043, 51.868206514527195],
            [-1.299431487031234, 51.866459055687663],
            [-1.294548491608124, 51.863970834782066],
            [-1.295742493776742, 51.861907343040741],
            [-1.296763000594969, 51.861092560648842],
            [-1.298070201667971, 51.861075189016482],
            [-1.302137714980828, 51.861728751670171],
            [-1.304569582104173, 51.861667620994766],
            [-1.307519842501317, 51.862743263974927],
            [-1.30960020013905, 51.861741293935843],
            [-1.311160766709208, 51.860443137511886],
            [-1.316425460787082, 51.860393812019055],
            [-1.323051102293064, 51.861290752734739],
            [-1.324578697175891, 51.859646073784049],
            [-1.326476757966939, 51.860510202021956],
            [-1.328201942190502, 51.860603678567244],
            [-1.334011920130763, 51.862471770848046],
            [-1.339192736057462, 51.856165877112957],
            [-1.338770487278402, 51.85456129916863],
            [-1.337046048062581, 51.851861472560103],
            [-1.337012557246933, 51.849891341583032],
            [-1.339882565661944, 51.845236614506568],
            [-1.340551036459035, 51.840913856866486],
            [-1.340814728330406, 51.840449596375102],
            [-1.341378367216324, 51.840417687099013],
            [-1.336955345911474, 51.838268256327488],
            [-1.336736628914821, 51.837842644571523],
            [-1.336725892738728, 51.837585439026952],
            [-1.337634436667211, 51.837389156228753],
            [-1.337483041057908, 51.83672745916094],
            [-1.338556109562756, 51.8365932348215],
            [-1.338342043577558, 51.835651563692686],
            [-1.339455191631449, 51.835553520890123],
            [-1.337818841928873, 51.834315242190058],
            [-1.340363338525382, 51.833989670336436],
            [-1.340584301503638, 51.832274509654432],
            [-1.342018210520834, 51.830175024376665],
            [-1.337100992204908, 51.830224748578999],
            [-1.33255930050041, 51.831081101140398],
            [-1.326712399260315, 51.82660978238507],
            [-1.326241586507131, 51.82548769451801],
            [-1.328207907346544, 51.823423789802767],
            [-1.328986000734061, 51.821834105830462],
            [-1.331162627433153, 51.820441188283176],
            [-1.332987728278761, 51.82005144463497],
            [-1.338358767103717, 51.819946005385006],
            [-1.332391704863207, 51.816989286522855],
            [-1.334074020521184, 51.814465123078747],
            [-1.336041698767689, 51.813140163355705],
            [-1.337341769312503, 51.811732290350726],
            [-1.335530149400784, 51.81132916023509],
            [-1.333136961903587, 51.809545266193886],
            [-1.325649745610838, 51.805351380029734],
            [-1.326420636036965, 51.805008738491637],
            [-1.326835955671683, 51.800882383346526],
            [-1.325890122705691, 51.796816564379697],
            [-1.325936552169922, 51.791852813496554],
            [-1.324668841251342, 51.791787998639137],
            [-1.322360363076025, 51.787833895231834],
            [-1.324728471220581, 51.787696462703011],
            [-1.328459303216906, 51.786390726059281],
            [-1.330267486937265, 51.786268865470937],
            [-1.331529875949722, 51.786193327914312],
            [-1.333091635533362, 51.785281497967873],
            [-1.33554986758221, 51.78541229545565],
            [-1.337628355145327, 51.784548279080873],
            [-1.33893736369755, 51.782865304112995],
            [-1.3416424620948, 51.780866454990104],
            [-1.3435644802751, 51.780074286234111],
            [-1.348470230754473, 51.776917790131805],
            [-1.350881136104732, 51.776075161119884],
            [-1.355785407224722, 51.774992615242276],
            [-1.35724446504763, 51.775535572714162],
            [-1.358721519121193, 51.774810855052444],
            [-1.360010240321301, 51.774999496294463],
            [-1.359538090566546, 51.774462850597956],
            [-1.361529658240591, 51.773630306928446],
            [-1.361263488599617, 51.7728754014285],
            [-1.362146123994827, 51.772052104531106],
            [-1.363247078693878, 51.772293639064259],
            [-1.363828624084897, 51.77206031828181],
            [-1.363506551863168, 51.771568556735403],
            [-1.362512444041676, 51.771562273430099],
            [-1.363399277212038, 51.770746182733795],
            [-1.362812024352001, 51.769944586099456],
            [-1.36404883355439, 51.76938393717635],
            [-1.363212808811331, 51.768445230070853],
            [-1.36423772491799, 51.767800712764036],
            [-1.362087902540164, 51.765899125062056],
            [-1.360370687364998, 51.765492387638929],
            [-1.360366112938172, 51.764380153087473],
            [-1.359114292994079, 51.763653143744328],
            [-1.360109189437872, 51.763078629686461],
            [-1.359660815340002, 51.762201346416127],
            [-1.362022693458441, 51.762813895449895],
            [-1.362691156148794, 51.762573858692484],
            [-1.36281204273692, 51.761911863126727],
            [-1.363510593212212, 51.761797861377524],
            [-1.364733632763646, 51.760859500449016],
            [-1.364613033654896, 51.7605738281841],
            [-1.363184225085616, 51.760792677711237],
            [-1.363958475612331, 51.759826718274404],
            [-1.362651803018275, 51.758987059294192],
            [-1.363395473818739, 51.758546023335818],
            [-1.366348402516736, 51.758326402414717],
            [-1.366102606950684, 51.757358524363376],
            [-1.365297294250306, 51.756722099556335],
            [-1.366634517172279, 51.755894027234909],
            [-1.366670546531312, 51.75507871966758],
            [-1.366245643562791, 51.755005400027457],
            [-1.364737450040842, 51.755732742234102],
            [-1.364574203374562, 51.75518339713804],
            [-1.365194824488301, 51.754626597354509],
            [-1.367605942849851, 51.753764744862018],
            [-1.367734128089092, 51.752884296510956],
            [-1.366577730087582, 51.751666958174056],
            [-1.368239236983551, 51.749386735750754],
            [-1.367725935559072, 51.748489351751111],
            [-1.365413391902564, 51.748332135913067],
            [-1.365076760349095, 51.747442886325672],
            [-1.365374458240724, 51.746354758618388],
            [-1.367211461588063, 51.745292007232024],
            [-1.368412708318566, 51.743489433474451],
            [-1.368755610966404, 51.7420077241974],
            [-1.371210370057108, 51.741130746630219],
            [-1.374105100406038, 51.737279966883627],
            [-1.373633716846111, 51.736284825599178],
            [-1.371033007207886, 51.734709166022576],
            [-1.36948186156394, 51.732748861975132],
            [-1.368400605865228, 51.731991391437781],
            [-1.367010195183322, 51.729630008558402],
            [-1.367228571366627, 51.727885085676739],
            [-1.368158911839712, 51.727099759323814],
            [-1.368163753798658, 51.725713335957963],
            [-1.368761804299427, 51.725601460667541],
            [-1.369066705245549, 51.725955554041697],
            [-1.369584409916355, 51.725270501243038],
            [-1.368667040349768, 51.724192923121961],
            [-1.368731354339426, 51.723732916752432],
            [-1.374234181369158, 51.717229295294594],
            [-1.3747023759697, 51.716867640854346],
            [-1.376183520721149, 51.716737943708694],
            [-1.376331472385305, 51.71618936315938],
            [-1.377590540988415, 51.71557474049208],
            [-1.378044922542724, 51.714422668279724],
            [-1.378944015936601, 51.71355976554387],
            [-1.382292913449829, 51.711782755740593],
            [-1.38577357125087, 51.709019997798734],
            [-1.392207935864622, 51.706223892958931],
            [-1.401165175929498, 51.705733986545141],
            [-1.402244259623411, 51.705881540725585],
            [-1.403611453997171, 51.705910064204147],
            [-1.404308036665911, 51.706423400597387],
            [-1.406544351366736, 51.707055108694334],
            [-1.406334522062134, 51.707496418950264],
            [-1.408908381495335, 51.707886123018135],
            [-1.411531204270543, 51.708967442954787],
            [-1.416397290570047, 51.709836879131394],
            [-1.417890728199162, 51.709849678617701],
            [-1.419179066948374, 51.710282236268071],
            [-1.421252611507241, 51.710316739394109],
            [-1.430102613689097, 51.708954645066527],
            [-1.438126167026625, 51.707790240496571],
            [-1.439263027563596, 51.707843322416878],
            [-1.439934291278476, 51.708215163356854],
            [-1.440014175195328, 51.709364624505845],
            [-1.439445792496825, 51.709566915884189],
            [-1.438622426409283, 51.709322021633085],
            [-1.438297924035554, 51.709931875892458],
            [-1.43942192596326, 51.710444347028258],
            [-1.439996975541903, 51.711344413294867],
            [-1.442298054881813, 51.712419015369889],
            [-1.444067461999987, 51.711651452560076],
            [-1.445259521003378, 51.712051798904291],
            [-1.447927821994434, 51.711984337811693],
            [-1.448963666038738, 51.712260732893114],
            [-1.450407788968068, 51.711572470737806],
            [-1.450503523856433, 51.711794102586701],
            [-1.451541593313468, 51.711647897420399],
            [-1.452854009390754, 51.712634956304967],
            [-1.454443689157534, 51.712716971354808],
            [-1.455390301615778, 51.713196996423015],
            [-1.458955442912052, 51.713300672880095],
            [-1.463321420828374, 51.712390991982474],
            [-1.463156354157701, 51.712029690097602],
            [-1.463878024062063, 51.711464737758668],
            [-1.46319690399758, 51.710682090042432],
            [-1.463287755938234, 51.709975794138941],
            [-1.464428265630015, 51.707642375987511],
            [-1.462862239842874, 51.701785530781258],
            [-1.463120900038013, 51.70095951794611],
            [-1.464572063580151, 51.699625542071026],
            [-1.464851253508858, 51.699754489120672],
            [-1.46573091205252, 51.699147984878138],
            [-1.46743578408548, 51.699732959237501],
            [-1.467629187669104, 51.698911136306627],
            [-1.469030161767908, 51.69937692550014],
            [-1.469427726996554, 51.700149270276995],
            [-1.47093227165805, 51.699927673909713],
            [-1.471336417494182, 51.700759383910103],
            [-1.472757030582808, 51.700290127517015],
            [-1.47310972232524, 51.700945372181096],
            [-1.473754069600679, 51.701157754420144],
            [-1.473252213352695, 51.7018837971765],
            [-1.474267122212026, 51.702087046935134],
            [-1.4758943445161, 51.703901551814866],
            [-1.479053998530585, 51.703845464627264],
            [-1.480712668677979, 51.703313329196504],
            [-1.482718332610435, 51.70166149059029],
            [-1.484748169711816, 51.696847503834796],
            [-1.486588563506019, 51.69569838325063],
            [-1.487923736082966, 51.695592715804999],
            [-1.489078889737093, 51.694255349367687],
            [-1.489881692260917, 51.694012475863858],
            [-1.491148511537089, 51.694191497683036],
            [-1.492141574972031, 51.69388199787614],
            [-1.495476330225701, 51.69466870610534],
            [-1.494827666780251, 51.695623487635132],
            [-1.49587910758434, 51.696441708892728],
            [-1.49704199659214, 51.69647636133859],
            [-1.49662129585648, 51.697098552625306],
            [-1.497277621785692, 51.697671405875155],
            [-1.496816054235454, 51.698455265247283],
            [-1.497243329699722, 51.698677378737621],
            [-1.498655692208468, 51.698269813819117],
            [-1.501534987723776, 51.698275764434143],
            [-1.502660206779549, 51.697791407099032],
            [-1.504115703301663, 51.698328938012892],
            [-1.506033112666781, 51.698306440516724],
            [-1.50722393570876, 51.69870525444464],
            [-1.506947658578285, 51.700029403740679],
            [-1.511417348237514, 51.699626376797667],
            [-1.512846583628598, 51.7011428348412],
            [-1.513366595821225, 51.701227707900358],
            [-1.515865848672622, 51.701745132257521],
            [-1.517125413195741, 51.701683777621525],
            [-1.524045038783171, 51.699479474971639],
            [-1.526083296937019, 51.699665735881617],
            [-1.527119577287307, 51.698251992340232],
            [-1.53153532631832, 51.698414430788702],
            [-1.534291217164433, 51.698179920163483],
            [-1.536171692733391, 51.699079284232901],
            [-1.535672300944021, 51.699664439771666],
            [-1.53670853516918, 51.700210698802906],
            [-1.537447529395835, 51.701244901907856],
            [-1.539977666061821, 51.701740355525679],
            [-1.541101259925025, 51.702672640532803],
            [-1.542893280885925, 51.702185104913113],
            [-1.543283139876828, 51.702987737562651],
            [-1.543899862982554, 51.702960460800348],
            [-1.54426734964851, 51.702534803131961],
            [-1.54446512804439, 51.703013902370827],
            [-1.545202359919348, 51.703235243212788],
            [-1.547496893391075, 51.701836072766788],
            [-1.548363794443118, 51.702113638390465],
            [-1.548762540334526, 51.701449819498237],
            [-1.549709772255984, 51.701506499576034],
            [-1.549758048295362, 51.700870106274202],
            [-1.551633494824527, 51.701295365483233],
            [-1.552152798698564, 51.701019516267223],
            [-1.551926061944302, 51.700388367960045],
            [-1.55275472513471, 51.6999941115293],
            [-1.555069144141189, 51.699330350394163],
            [-1.555453363008919, 51.699694150000298],
            [-1.556600472675475, 51.699431442859506],
            [-1.557116096734275, 51.698489308481783],
            [-1.558209245759726, 51.699009517519315],
            [-1.560821751426866, 51.698629087212609],
            [-1.560923152071732, 51.698916285827266],
            [-1.560071263427405, 51.699327596219121],
            [-1.560505796649002, 51.699580076425676],
            [-1.562822229344446, 51.698693188039755],
            [-1.563739222156227, 51.699182120175472],
            [-1.565633720876243, 51.698681131371259],
            [-1.567313605530678, 51.699667368610051],
            [-1.570829372221614, 51.699473457554021],
            [-1.572072609532951, 51.700361825668921],
            [-1.574866253485626, 51.700103123759533],
            [-1.576996715532302, 51.699581226050519],
            [-1.579128631962866, 51.697648573497517],
            [-1.578898738540587, 51.696851128580725],
            [-1.580132475244848, 51.696116463229302],
            [-1.583955892137522, 51.695075386292885],
            [-1.585028176351038, 51.693470647124393],
            [-1.584604265267603, 51.693471847395791],
            [-1.584647076844572, 51.693061999023271],
            [-1.585531118531365, 51.693211675970439],
            [-1.588095035049558, 51.692702792947259],
            [-1.591703847002115, 51.691020536802689],
            [-1.594164298127538, 51.690250416557738],
            [-1.598458232623099, 51.687930140785518],
            [-1.600799427223204, 51.688681688377962],
            [-1.602426940048081, 51.690489943517946],
            [-1.603984471225657, 51.690881822886567],
            [-1.603890525129716, 51.691535167960311],
            [-1.606058691053112, 51.692251860199299],
            [-1.607203237411875, 51.691895142052886],
            [-1.608346495667816, 51.690846983859466],
            [-1.609923399423995, 51.690675099605365],
            [-1.611971096955318, 51.689920319864193],
            [-1.614858773453908, 51.687682010564217],
            [-1.622191088579387, 51.685812251806155],
            [-1.623725293819695, 51.685354124325869],
            [-1.624580835431046, 51.684749052845902],
            [-1.628315795252816, 51.683846513264918],
            [-1.630307634385873, 51.684014635828511],
            [-1.629928418980622, 51.684932345640462],
            [-1.631498204594458, 51.685780653116929],
            [-1.633523350178096, 51.686314767995],
            [-1.634076017194661, 51.687021400976377],
            [-1.63535918519812, 51.68755227313433],
            [-1.63661969398868, 51.686760453242286],
            [-1.63915513441399, 51.686804227427665],
            [-1.641412869104098, 51.685378834425705],
            [-1.642860670066824, 51.685581048220122],
            [-1.646814295074377, 51.684794571749208],
            [-1.648267728541082, 51.684083228081704],
            [-1.648851963887461, 51.685410280009933],
            [-1.653405078806522, 51.687177077641188],
            [-1.651830035334263, 51.687521282977109],
            [-1.650858443612588, 51.688208926839287],
            [-1.651105627312537, 51.688797685844754],
            [-1.654234948243728, 51.690276993104703],
            [-1.655497648744556, 51.691451354823172],
            [-1.655081858689512, 51.692481427040946],
            [-1.655306102323187, 51.693624866622443],
            [-1.656229166326485, 51.693811888717725],
            [-1.657452059597889, 51.69644718199222],
            [-1.659041475295571, 51.69750017519609],
            [-1.6624553734271, 51.69643287971698],
            [-1.66294645614962, 51.696741787360246],
            [-1.663498883864498, 51.696583326311334],
            [-1.664713538194935, 51.696874514416329],
            [-1.666537247900401, 51.698360550181],
            [-1.675323371344597, 51.703756473420391],
            [-1.681905357713324, 51.709032502517182],
            [-1.686179897863348, 51.71017147974662],
            [-1.688013790686141, 51.712040228437374],
            [-1.687331390464816, 51.71277209541406],
            [-1.687692892240638, 51.712821608246493],
            [-1.687848538479366, 51.713550305897783],
            [-1.688152071910845, 51.713611351946],
            [-1.688222352834189, 51.713283360746587],
            [-1.688781727379239, 51.713605826921302],
            [-1.688372515951086, 51.713968885510909],
            [-1.687613025079834, 51.713700732141128],
            [-1.687915947670485, 51.714061182958964],
            [-1.687314795410242, 51.714550503770035],
            [-1.687688718207014, 51.715110747684363],
            [-1.688893056530373, 51.71510135201671],
            [-1.688489267397355, 51.715726967206216],
            [-1.689031396957582, 51.716035899606567],
            [-1.688930985005866, 51.716536441727598],
            [-1.689791958702457, 51.716789569134534],
            [-1.688832576551365, 51.717377753966332],
            [-1.690993719133624, 51.719078285061798],
            [-1.690732198857545, 51.719218758830564],
            [-1.692599962117434, 51.720015776060237],
            [-1.693719958192394, 51.721583158569743],
            [-1.695120298182666, 51.722376219816638],
            [-1.694865780079439, 51.722550887231982],
            [-1.69560129203487, 51.722969083272524],
            [-1.69583190946448, 51.72376809388242],
            [-1.690121602488057, 51.726383997822417],
            [-1.686813139279422, 51.728514228113866],
            [-1.685521452465844, 51.730629094542579],
            [-1.685804950762884, 51.733389232206434],
            [-1.687186394152979, 51.733385721191105],
            [-1.687405017270327, 51.734020177363661],
            [-1.686639685063837, 51.734130530177048],
            [-1.68749885526299, 51.736793291737833],
            [-1.689053988306013, 51.739140500707784],
            [-1.687034429541995, 51.739878711032944],
            [-1.688319047521633, 51.741980649900988],
            [-1.692691780756718, 51.745994999840207],
            [-1.691657450990468, 51.746655837588236],
            [-1.694878964187469, 51.748569453680652],
            [-1.693938410773306, 51.749067815334492],
            [-1.694745492424464, 51.750751249457387],
            [-1.694388861820715, 51.751007469227211],
            [-1.695665064494332, 51.752302799310776],
            [-1.693691570579052, 51.753656233429957],
            [-1.696443921083041, 51.75713662882066],
            [-1.698493227822073, 51.761600581268517],
            [-1.699661959598228, 51.764525673645501],
            [-1.699262816253125, 51.764625354284533],
            [-1.700717432413697, 51.770578460669185],
            [-1.708415940919123, 51.777515517293075],
            [-1.717538414587599, 51.781513613083526],
            [-1.719500791596, 51.783205927433777],
            [-1.713860740728883, 51.786119835864412],
            [-1.707733252403527, 51.788294098609349],
            [-1.702159940797506, 51.790989127902307],
            [-1.693462542489442, 51.794143330941203],
            [-1.686889063141425, 51.798233132183583],
            [-1.685751055940906, 51.799856577209823],
            [-1.683765634959235, 51.80133118316558],
            [-1.680286281132664, 51.80712099571857],
            [-1.677826955764058, 51.812950360088415],
            [-1.67687242039222, 51.817017974534124],
            [-1.678105536399525, 51.81701595858523],
            [-1.681132750589911, 51.816045976394904],
            [-1.683138327823687, 51.82028705508926],
            [-1.684175703909103, 51.825679946248336],
            [-1.684619055573724, 51.825565151050576],
            [-1.686052945560557, 51.830741500228662],
            [-1.686474179086998, 51.834448705926413],
            [-1.685439603034496, 51.836924758865536],
            [-1.683066075680095, 51.840316981414674],
            [-1.681140453158017, 51.841896890083291],
            [-1.679220093175167, 51.845987058891879],
            [-1.678697476306068, 51.849459741239485],
            [-1.6763976735607, 51.850302189302447],
            [-1.678706283451374, 51.852914989608472],
            [-1.680601248311976, 51.854721931880391],
            [-1.68092485627461, 51.858033276097366],
            [-1.680619325094007, 51.858734640542934],
            [-1.681169118646952, 51.859632527512538],
            [-1.687203656851396, 51.862818917153248],
            [-1.685064368236345, 51.864308407729489],
            [-1.686880156445374, 51.865491961257426],
            [-1.685912118288293, 51.866863197603585],
            [-1.683559510212091, 51.868708131643615],
            [-1.680793943545292, 51.869154709603308],
            [-1.678877895371894, 51.869816627172362],
            [-1.675581087919562, 51.872623548930378],
            [-1.674474683846803, 51.87256834840732],
            [-1.673585256017104, 51.87404309510304],
            [-1.671661533695534, 51.875117556939642],
            [-1.668053125809795, 51.876330206239089],
            [-1.667124378852119, 51.876992013624047],
            [-1.667137444885249, 51.877977455165883],
            [-1.668964363567182, 51.878050935674658],
            [-1.668485248894602, 51.879596025426395],
            [-1.669274356953214, 51.879557784983589],
            [-1.668969587578842, 51.881496268631317],
            [-1.666885199967123, 51.881448134066375],
            [-1.666990308590917, 51.882953509785771],
            [-1.666026325642083, 51.885017789891144],
            [-1.663937836618878, 51.886083564986841],
            [-1.66263245436611, 51.887335862296894],
            [-1.66256199550445, 51.891703439917642],
            [-1.663033316380593, 51.892218169512489],
            [-1.661281171468869, 51.893528511633193],
            [-1.661682683028876, 51.89443864500435],
            [-1.660329905335781, 51.894804277203683],
            [-1.660266161762665, 51.895163729062382],
            [-1.658979327708783, 51.895644619337482],
            [-1.658664440573071, 51.896542796337009],
            [-1.657653724539852, 51.896634267288576],
            [-1.65760162267823, 51.897179862878886],
            [-1.65558312707527, 51.89659316621578],
            [-1.654596764182666, 51.896919344390646],
            [-1.653105531466589, 51.896941035398541],
            [-1.651380101181503, 51.895863327489849],
            [-1.648728373723653, 51.895432876688957],
            [-1.647484251877577, 51.89489510038927],
            [-1.645038519738722, 51.894891351142654],
            [-1.644895552782824, 51.894592422414505],
            [-1.644580299955189, 51.894949309170059],
            [-1.644236647720425, 51.894852968119942],
            [-1.643517909944068, 51.895879353359817],
            [-1.642721471504582, 51.89570341578478],
            [-1.642833033451314, 51.895379182818424],
            [-1.641561724785779, 51.895528165871383],
            [-1.639556670157925, 51.897112536079106],
            [-1.637805402247488, 51.897300467785236],
            [-1.634190051038179, 51.89849946858795],
            [-1.633063690522191, 51.899217034435715],
            [-1.633265704388456, 51.89957460204495],
            [-1.63426162955989, 51.899529148066151],
            [-1.634474870331965, 51.899758178686568],
            [-1.633596221067819, 51.900036863450694],
            [-1.635781656271229, 51.901891269572651],
            [-1.635179334079456, 51.902683300811567],
            [-1.635449113257916, 51.903831373123744],
            [-1.635025484270545, 51.903912777339663],
            [-1.635646711394078, 51.904380428480643],
            [-1.633758484668036, 51.905466065592968],
            [-1.635979429759998, 51.907096703514213],
            [-1.634972643706238, 51.907385791347338],
            [-1.635201810577916, 51.907807274457916],
            [-1.634707998350317, 51.908293049667144],
            [-1.635036710130029, 51.908808346825786],
            [-1.634674933437275, 51.909323301662184],
            [-1.635311615432191, 51.909327072785302],
            [-1.635127621259145, 51.90978863400899],
            [-1.635902146707517, 51.91000501349734],
            [-1.635108177265648, 51.910394558769539],
            [-1.636081541341593, 51.910652011768185],
            [-1.634195807057694, 51.911578524283541],
            [-1.635377167382319, 51.912004758094092],
            [-1.634718083413826, 51.912249065978394],
            [-1.634854031876405, 51.912874353055599],
            [-1.636264832234434, 51.912797800080618],
            [-1.636916910599952, 51.912340378316969],
            [-1.637455734181129, 51.912948022630083],
            [-1.63744156033049, 51.912180158628416],
            [-1.637964687582371, 51.912028023706569],
            [-1.638359575277589, 51.912457202192122],
            [-1.639129693856479, 51.912505417463606],
            [-1.639161594700171, 51.913060251879969],
            [-1.63988291229773, 51.912849375656265],
            [-1.640669562746686, 51.913377743799025],
            [-1.640775807561823, 51.912816138514195],
            [-1.641451990655357, 51.913708294665064],
            [-1.642676890256621, 51.912693351223211],
            [-1.642910246555575, 51.912965583112054],
            [-1.64392869564884, 51.913049583888821],
            [-1.643718122804541, 51.913572215720301],
            [-1.644348370579781, 51.913477917967832],
            [-1.644233708615735, 51.91400623473475],
            [-1.644901921984403, 51.913707056902119],
            [-1.64460173206885, 51.914164685822371],
            [-1.645385719129703, 51.914856647956661],
            [-1.643984932326359, 51.914947725931505],
            [-1.644719385896069, 51.91546871626619],
            [-1.643586351081273, 51.916078470542161],
            [-1.644026660910756, 51.916474500841154],
            [-1.643412742399848, 51.916704608339074],
            [-1.644474892906171, 51.91697304920077],
            [-1.644563160330917, 51.917763612323121],
            [-1.64514342084665, 51.917562168228876],
            [-1.645516881841491, 51.917772779695866],
            [-1.6448819531959, 51.918084648425683],
            [-1.644849607533095, 51.91868334220802],
            [-1.643735285466237, 51.918755500687702],
            [-1.643540880834791, 51.919068693600074],
            [-1.643229525402756, 51.918910411006877],
            [-1.643015973122931, 51.91943752750781],
            [-1.642623745295432, 51.919026355066599],
            [-1.641472121279612, 51.919764601648545],
            [-1.641857789870196, 51.920085849355985],
            [-1.641254734379707, 51.920587502462737],
            [-1.641683215709678, 51.921193891224782],
            [-1.641194697763771, 51.921552937661097],
            [-1.642832752128627, 51.922189972558741],
            [-1.643857997168178, 51.921992580422632],
            [-1.645542437732003, 51.922285368022791],
            [-1.64338194621027, 51.923716485126235],
            [-1.640033579986019, 51.925105278108738],
            [-1.637846791151117, 51.926693552808615],
            [-1.636130534600857, 51.927444394777133],
            [-1.634034164124138, 51.92932867639292],
            [-1.633776441085168, 51.929187618317819],
            [-1.630019695416004, 51.931516197240057],
            [-1.628269870874132, 51.933123646217638],
            [-1.623611300184807, 51.935728786024526],
            [-1.619922493722142, 51.937463863263773],
            [-1.617297049119355, 51.937281841007838],
            [-1.615202015886552, 51.937672412005739],
            [-1.622794709112703, 51.946387501571913],
            [-1.625366592248955, 51.949923704614996],
            [-1.628881101907934, 51.953190399883169],
            [-1.632503190457941, 51.955641857305508],
            [-1.635325422318699, 51.956819429745863],
            [-1.638848209334442, 51.956674738097838],
            [-1.643399421204924, 51.957129147874454],
            [-1.645779934907507, 51.956974493103381],
            [-1.648938627281015, 51.958915169023939],
            [-1.652346304496108, 51.957674651354395],
            [-1.655378963168062, 51.957493853170824],
            [-1.655190978259243, 51.959805736075175],
            [-1.653479284726826, 51.959837577638858],
            [-1.652386576695502, 51.961502151771036],
            [-1.657235296913545, 51.96310412765061],
            [-1.660971052520236, 51.96333790523272],
            [-1.662540893143321, 51.963995141906679],
            [-1.664291010565308, 51.966440238973803],
            [-1.666091669921041, 51.971121458099979],
            [-1.668955068515996, 51.975262592832124],
            [-1.667532908270467, 51.978389174515058],
            [-1.66534748905537, 51.980552460859812],
            [-1.6649407817198, 51.983156827278286],
            [-1.664110767022867, 51.984917552141894],
            [-1.664651146416656, 51.985091713200724],
            [-1.665766266163563, 51.987478328601249],
            [-1.657346403777898, 51.984430563341931],
            [-1.656593180444142, 51.983531992848206],
            [-1.643105132793452, 51.977893375019647],
            [-1.638327011279961, 51.975161798109191],
            [-1.635924979818463, 51.973716777313399],
            [-1.636540343365984, 51.972869048116664],
            [-1.635023234386037, 51.972538893457006],
            [-1.634871552736207, 51.972215654626822],
            [-1.634315337397229, 51.972410828172904],
            [-1.634144072330783, 51.97181151065616],
            [-1.63297549086385, 51.971412282822399],
            [-1.633313161846568, 51.970882877780362],
            [-1.632836559547936, 51.970956017412924],
            [-1.632371997823982, 51.970446589935442],
            [-1.62973596588696, 51.969896193282125],
            [-1.626297264357921, 51.967501881553304],
            [-1.623581685953912, 51.964833762627258],
            [-1.622624541922418, 51.963379587017783],
            [-1.621051843276437, 51.962269571084029],
            [-1.62191547274996, 51.961854272991637],
            [-1.620298405947768, 51.960664086124858],
            [-1.619905162718862, 51.960699680645561],
            [-1.617996536586296, 51.959065274366473],
            [-1.617574876041645, 51.959192475178348],
            [-1.61622774111088, 51.957943768014296],
            [-1.617251694433092, 51.957494860845742],
            [-1.616659391951026, 51.956984954831221],
            [-1.615878035149717, 51.956632667872761],
            [-1.615284713380958, 51.956925630757944],
            [-1.613621913540317, 51.95567945841438],
            [-1.613349781407313, 51.955850290250787],
            [-1.612355456656021, 51.955393886443474],
            [-1.605801197397692, 51.959563657398377],
            [-1.59693009790913, 51.964384185852751],
            [-1.594733795094437, 51.968260678150997],
            [-1.594302780299281, 51.968117140483244],
            [-1.591647071901118, 51.970440180020034],
            [-1.595811634464341, 51.972553887243762],
            [-1.596701719865982, 51.974267883643776],
            [-1.600314582358589, 51.976931574213253],
            [-1.588420240419713, 51.974215875662814],
            [-1.580586501302438, 51.972986186827583],
            [-1.568654367006754, 51.97626161711166],
            [-1.563552882341245, 51.977030427149991],
            [-1.549685253459091, 51.980849686243737],
            [-1.553631896231374, 51.985597450248378],
            [-1.553366939251874, 51.985737601685386],
            [-1.554090560898102, 51.986626832769076],
            [-1.556699732907883, 51.989315028360686],
            [-1.557237408996481, 51.99164745785562],
            [-1.556331779857989, 51.991772620211016],
            [-1.556207542294828, 51.991523108097766],
            [-1.555594100888796, 51.991849858846052],
            [-1.554049993075137, 51.99188718212033],
            [-1.553574898481008, 51.992359197168433],
            [-1.550629123007505, 51.9933315927289],
            [-1.549689582527535, 51.993928590189626],
            [-1.546846234426128, 51.994690898456263],
            [-1.543943078662077, 51.996313323274606],
            [-1.541755764160268, 51.996421719298517],
            [-1.539913948424656, 51.996357003006942],
            [-1.536015088607455, 51.995350935791926],
            [-1.534769981164298, 51.994349849870069],
            [-1.529144416993925, 51.992620160810233],
            [-1.529369498833412, 51.992265924100003],
            [-1.527943370188648, 51.991744149772998],
            [-1.526877910446137, 51.992517579763543],
            [-1.525402410432765, 51.992811040156724],
            [-1.524942481832066, 51.993196689710423],
            [-1.522840128144793, 51.996833057418186],
            [-1.521245500311834, 51.992983033473351],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000187",
        LAD13CDO: "40UB",
        LAD13NM: "Mendip",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.754785157610931, 51.322867787268656],
            [-2.75534793460817, 51.321396678462143],
            [-2.754930408662988, 51.32052715600777],
            [-2.753936577082352, 51.319778239301179],
            [-2.7528473311314, 51.320123344160535],
            [-2.748983870549917, 51.319127540956515],
            [-2.747754656531695, 51.31977921866077],
            [-2.744581066722185, 51.31928510694754],
            [-2.73938787095984, 51.319818867169559],
            [-2.735996616568663, 51.319616335930313],
            [-2.736260102671727, 51.319223531461525],
            [-2.73386121121557, 51.318528231809466],
            [-2.733802885382775, 51.318200392848766],
            [-2.733019512828994, 51.318386936522849],
            [-2.733038703889031, 51.318870580820246],
            [-2.732555559473055, 51.318807963883437],
            [-2.729810117455031, 51.318061696960726],
            [-2.728059241605296, 51.317230056501955],
            [-2.725402438517655, 51.318362442869393],
            [-2.721445101684679, 51.319474914622759],
            [-2.720926326919203, 51.321531859228152],
            [-2.716837366391266, 51.316804748029185],
            [-2.708619774198127, 51.317180280508829],
            [-2.703138325676449, 51.317079349742791],
            [-2.699528928202605, 51.316850097450704],
            [-2.699542397017691, 51.316603639109914],
            [-2.696850756798793, 51.316144019800831],
            [-2.697494275586154, 51.318081537103055],
            [-2.696388860520559, 51.317573781302102],
            [-2.69487577144969, 51.318098006772104],
            [-2.691070598513885, 51.317543250021217],
            [-2.686394233212623, 51.316103283740375],
            [-2.685497302815572, 51.316094151822988],
            [-2.685883365816763, 51.31513785131235],
            [-2.683071954107981, 51.314424146828436],
            [-2.678911394671223, 51.312800134533845],
            [-2.676455719364431, 51.311576166131069],
            [-2.676419878394422, 51.310992799450723],
            [-2.674491037540796, 51.310463515482887],
            [-2.673005592231062, 51.311799267397426],
            [-2.670456511236396, 51.311239311822369],
            [-2.666604670677692, 51.309186878879849],
            [-2.666978721125758, 51.307873730516732],
            [-2.665883717104022, 51.307450151588746],
            [-2.664863730908581, 51.307550363772485],
            [-2.663413795956719, 51.307183627574418],
            [-2.662482428022364, 51.306563965369918],
            [-2.664886460708493, 51.30535171649035],
            [-2.665811713798009, 51.30336824016522],
            [-2.666792478968419, 51.302449080720351],
            [-2.662882265995623, 51.302952354833884],
            [-2.659988158750286, 51.3025964427734],
            [-2.655759524184297, 51.301296599867342],
            [-2.653660407054045, 51.300650133106473],
            [-2.652219582024657, 51.299791350732086],
            [-2.650730868844675, 51.299700725074032],
            [-2.648975449873748, 51.298903896961271],
            [-2.646820976052367, 51.298386198796443],
            [-2.644666344767419, 51.297034912752693],
            [-2.643889846278493, 51.297097633971134],
            [-2.641042115246785, 51.295503713651009],
            [-2.638696365932697, 51.294826857695071],
            [-2.640347549321009, 51.293310793377501],
            [-2.639698794626236, 51.291313641531673],
            [-2.638579039652409, 51.289989852575857],
            [-2.637232945509367, 51.289092602565759],
            [-2.638292920739485, 51.288282050997374],
            [-2.63572256617267, 51.287302433426753],
            [-2.635042912470742, 51.286266657775926],
            [-2.633608270979594, 51.286528004743175],
            [-2.633445692973285, 51.285863483144659],
            [-2.631974332061602, 51.284599081041698],
            [-2.630996009860977, 51.284673597125696],
            [-2.630200296262228, 51.284261558560715],
            [-2.629978499768686, 51.283561383177449],
            [-2.624982665968384, 51.282483959394902],
            [-2.624696614061784, 51.285388975353733],
            [-2.621040393387842, 51.284344689534954],
            [-2.61845032901642, 51.286229620297632],
            [-2.612992351081661, 51.284152427846799],
            [-2.608784643231253, 51.285831597834928],
            [-2.606955379910138, 51.287277105105545],
            [-2.603924637614043, 51.287764857117025],
            [-2.600335611566036, 51.288991831745136],
            [-2.598612882617439, 51.290609305086527],
            [-2.596539877910507, 51.290770952499912],
            [-2.594875418905988, 51.292362895721425],
            [-2.595318058289906, 51.293099778555721],
            [-2.594589865218892, 51.29512485610627],
            [-2.5954641830247, 51.296190445819562],
            [-2.591632049917351, 51.29653808158146],
            [-2.589374213028782, 51.295729412281119],
            [-2.589506700374713, 51.295435609724493],
            [-2.588460938168595, 51.295194494271662],
            [-2.587752858331785, 51.29421703743855],
            [-2.585894448842887, 51.293338855530578],
            [-2.582177264251525, 51.293123614172238],
            [-2.582279814998686, 51.29193437685354],
            [-2.580182625253952, 51.293144308443878],
            [-2.574496508283302, 51.294889804404363],
            [-2.571248282188209, 51.295140390194959],
            [-2.567220405387454, 51.297649828548465],
            [-2.562666433424996, 51.298813775643588],
            [-2.559396695845018, 51.302041341831988],
            [-2.558440710462342, 51.302105252823466],
            [-2.557822097155155, 51.30266569788057],
            [-2.5528011711686, 51.301321855345066],
            [-2.55416227730656, 51.298690694274235],
            [-2.551397714978589, 51.298279334053817],
            [-2.551128775851049, 51.299535864981628],
            [-2.55047631741776, 51.299430133671471],
            [-2.550010281962699, 51.300520339843665],
            [-2.541504753897873, 51.298476582987888],
            [-2.540460692884334, 51.297876256118222],
            [-2.539233943435824, 51.296248991620772],
            [-2.534562420206551, 51.295491724607714],
            [-2.531722879031113, 51.294021000970112],
            [-2.528001422450131, 51.293165637136482],
            [-2.524899672658751, 51.292941304122031],
            [-2.523467918229227, 51.293150932479335],
            [-2.523210329354038, 51.293578298619337],
            [-2.52163219191005, 51.293528693885349],
            [-2.513616512564269, 51.292742287301046],
            [-2.512554179596291, 51.292259585408033],
            [-2.509374985686208, 51.292022591863471],
            [-2.508061530650702, 51.291520260615343],
            [-2.506376577579503, 51.291139114307697],
            [-2.506222274847038, 51.290018496435167],
            [-2.505278807626539, 51.289900284039142],
            [-2.503298547512232, 51.290045492843987],
            [-2.502762514809587, 51.290466817532703],
            [-2.502110873189313, 51.290413865438772],
            [-2.500489482127028, 51.290989995060194],
            [-2.499699653209384, 51.29088996352899],
            [-2.499551499442258, 51.290584872703391],
            [-2.498288803507269, 51.290915760805802],
            [-2.497972608593372, 51.290586206102503],
            [-2.497416585796124, 51.290887999507085],
            [-2.497173142594843, 51.290522165988527],
            [-2.496669819359701, 51.290525202199085],
            [-2.496967550141474, 51.290208324025244],
            [-2.496536295836712, 51.287454148357689],
            [-2.496969868597002, 51.28630674428728],
            [-2.498344933203334, 51.286432178426857],
            [-2.498662188892527, 51.285671014226629],
            [-2.50043443357412, 51.284609602360149],
            [-2.499848796725922, 51.284024935911269],
            [-2.500123125109944, 51.282747817191478],
            [-2.506354814444838, 51.278995685424825],
            [-2.499807535120928, 51.27679204617764],
            [-2.495947261650592, 51.275907482937072],
            [-2.495193564415653, 51.276076125317388],
            [-2.494831308600791, 51.276651339725099],
            [-2.493865596540648, 51.276703076169618],
            [-2.49276986787058, 51.276126819703869],
            [-2.49350836421911, 51.275475392958867],
            [-2.490608524135558, 51.274429241595961],
            [-2.489944446991752, 51.274801590217841],
            [-2.487907365367042, 51.274127617375363],
            [-2.487161158202718, 51.275139615415917],
            [-2.485177498834533, 51.274635319311649],
            [-2.485000979038758, 51.274208936644953],
            [-2.482832936030518, 51.273887000901453],
            [-2.48266369890377, 51.274152959577556],
            [-2.478000901827533, 51.273101165844899],
            [-2.473753325421321, 51.277971355376259],
            [-2.472303108618956, 51.277057348739639],
            [-2.461583774228502, 51.274198443841598],
            [-2.455875099330088, 51.273737059971623],
            [-2.453287237595827, 51.273849620543338],
            [-2.451722928497572, 51.274255808118056],
            [-2.452404755463403, 51.277117984155247],
            [-2.449970589316907, 51.277427697876909],
            [-2.447460208674688, 51.27712710461833],
            [-2.444311849550471, 51.277416050565989],
            [-2.446015238516218, 51.280840865824857],
            [-2.442498331954485, 51.282044739463778],
            [-2.442409142304829, 51.282759929395169],
            [-2.439072955383943, 51.283844323599638],
            [-2.438729301393578, 51.284194496441266],
            [-2.439047083994243, 51.285190501192517],
            [-2.43561489393522, 51.285703274508215],
            [-2.435342495829148, 51.285404859519396],
            [-2.434544308208422, 51.285457281312098],
            [-2.432599737976739, 51.286046258600791],
            [-2.430064399624791, 51.284647479653422],
            [-2.430232768907055, 51.286399372346281],
            [-2.430686077591083, 51.286419285351045],
            [-2.429955281705, 51.286780747363331],
            [-2.427709201431971, 51.286724236714505],
            [-2.427610545065232, 51.28706358989114],
            [-2.426226095317501, 51.2869895122491],
            [-2.422055175069253, 51.287506374212491],
            [-2.42099065617568, 51.289017241591026],
            [-2.421369322885712, 51.291062421739419],
            [-2.417390920403468, 51.29213861477821],
            [-2.417766225447443, 51.29367038358302],
            [-2.420259977449478, 51.296626062212198],
            [-2.420002734798724, 51.296884152022592],
            [-2.419388056453188, 51.296794639676897],
            [-2.419542765124327, 51.297245475024461],
            [-2.418435894562178, 51.297445459596695],
            [-2.418655967752565, 51.297672165812777],
            [-2.417369959520976, 51.298048121597134],
            [-2.417459635633183, 51.298288782730296],
            [-2.416198682591309, 51.298741965909684],
            [-2.415367287712963, 51.298480559893193],
            [-2.41403600641575, 51.299568792157409],
            [-2.412738624494283, 51.299806262998302],
            [-2.412583944630201, 51.300631358570499],
            [-2.41164620011563, 51.301309946455007],
            [-2.410146179576956, 51.300867420142502],
            [-2.40747341209613, 51.300971170416005],
            [-2.407044285592374, 51.30061928514148],
            [-2.406960716116693, 51.30090101975847],
            [-2.404367202669975, 51.302025894156472],
            [-2.400918702504432, 51.304804539858011],
            [-2.399827820390457, 51.304893693899317],
            [-2.397467958261944, 51.303056611869174],
            [-2.393665413490031, 51.297547588516657],
            [-2.39231766170991, 51.296425438932843],
            [-2.391892788132846, 51.295375718177766],
            [-2.389627468038517, 51.29461538036221],
            [-2.387182454129391, 51.294647773268586],
            [-2.384882780939423, 51.295595904093396],
            [-2.384158822523555, 51.296321225430702],
            [-2.38518543132463, 51.296969757994674],
            [-2.383146099756219, 51.299058058771053],
            [-2.381576897968928, 51.300089152057829],
            [-2.379717921492377, 51.300604135304532],
            [-2.37762497741976, 51.300580335309967],
            [-2.374272880988453, 51.301112628975766],
            [-2.369258929178779, 51.301017058512677],
            [-2.362888249695803, 51.302943250308097],
            [-2.35961795124875, 51.304440590094188],
            [-2.357609492484403, 51.304971866035409],
            [-2.354480023139155, 51.306913725153798],
            [-2.353868719734868, 51.306704267902447],
            [-2.350485611475225, 51.30702646421404],
            [-2.348672168852041, 51.308142368175076],
            [-2.341782930864451, 51.309265085088164],
            [-2.341606825111988, 51.309699003691179],
            [-2.34055775836404, 51.309460182007058],
            [-2.339053160120218, 51.309896156821402],
            [-2.337493741672249, 51.308926853295517],
            [-2.338098957157516, 51.308704807415829],
            [-2.337351251534828, 51.30769089249047],
            [-2.332523585183667, 51.308447431894251],
            [-2.330387545204926, 51.309260045626338],
            [-2.32857944806635, 51.310802732311458],
            [-2.326158142115349, 51.311876830802767],
            [-2.324732849125716, 51.313008364322279],
            [-2.325110105296253, 51.313402056193716],
            [-2.324819990285906, 51.313563814715337],
            [-2.320185299146228, 51.314952329531486],
            [-2.319540278410249, 51.314426275008501],
            [-2.316382593128294, 51.316347405814831],
            [-2.312282946916563, 51.317577702174766],
            [-2.310731538753202, 51.318809208838225],
            [-2.305357622436205, 51.319445584855806],
            [-2.303930002521126, 51.320120986429501],
            [-2.299220897292138, 51.321298454787879],
            [-2.297939213720448, 51.322371744952314],
            [-2.294095741285472, 51.322755524340479],
            [-2.291162028320804, 51.323816694749809],
            [-2.289093195113625, 51.325275791300044],
            [-2.285164456757493, 51.324459088021982],
            [-2.282160260995522, 51.32531159534534],
            [-2.281266992030274, 51.324039613584176],
            [-2.279269151054932, 51.324717881103496],
            [-2.277765231064043, 51.3244948681903],
            [-2.278418376249541, 51.325469820890468],
            [-2.277749603287951, 51.325707893298926],
            [-2.274939874335349, 51.325038348329386],
            [-2.275105267598944, 51.324618044573377],
            [-2.273097268393699, 51.322882842754552],
            [-2.275211277186231, 51.32219091950742],
            [-2.272333688250311, 51.31850383543356],
            [-2.275139275492205, 51.319034080672147],
            [-2.275521742913512, 51.318684300221506],
            [-2.274711139378207, 51.317746566382404],
            [-2.273124922187885, 51.316913145939075],
            [-2.271957001169071, 51.31425789879534],
            [-2.273666062452631, 51.312849398974159],
            [-2.275806700336001, 51.312369607035414],
            [-2.276655036894353, 51.310530584114623],
            [-2.27827109289303, 51.310631056598737],
            [-2.27921474809062, 51.310334777398538],
            [-2.281559706587167, 51.310918119821707],
            [-2.283279026968854, 51.310776395814486],
            [-2.285069965366348, 51.309461044623326],
            [-2.284694942799071, 51.3086724791454],
            [-2.283123281673494, 51.308317520426741],
            [-2.283547521991983, 51.30778687704403],
            [-2.281264571050761, 51.307233997254052],
            [-2.281158358178411, 51.306990575141931],
            [-2.28203928042515, 51.306772652183049],
            [-2.28226368511942, 51.30640524617251],
            [-2.281093000312163, 51.306624766494075],
            [-2.278380566846506, 51.305491992012172],
            [-2.278594032254969, 51.30497265778093],
            [-2.277123188673507, 51.30441776066187],
            [-2.276781758172956, 51.303937508132044],
            [-2.278113224992729, 51.302560405210151],
            [-2.27879635003613, 51.300960038607094],
            [-2.279822285296476, 51.300311079382176],
            [-2.279864966884359, 51.299311089544673],
            [-2.280527989596946, 51.298900374914851],
            [-2.281250044812428, 51.298997551199001],
            [-2.281494767875597, 51.298674157112472],
            [-2.280179961741494, 51.297101050669085],
            [-2.279499146608216, 51.295524617885533],
            [-2.279963583243501, 51.294777187746377],
            [-2.279669611063487, 51.294075630899933],
            [-2.280701869279682, 51.293549835691884],
            [-2.279708617258394, 51.293179054609688],
            [-2.279996956364627, 51.292249511199614],
            [-2.279102434849333, 51.292309196197174],
            [-2.277561771498795, 51.293188663521583],
            [-2.275836933206029, 51.29349936351943],
            [-2.272966917287706, 51.294900723863819],
            [-2.269850356390991, 51.294645393325972],
            [-2.268346929647125, 51.295067869445347],
            [-2.267064553151498, 51.296020338036868],
            [-2.264971445331808, 51.296169865407435],
            [-2.262992220601327, 51.295169048195291],
            [-2.265289411167064, 51.292862859173937],
            [-2.260984861477477, 51.29071181048959],
            [-2.257441027544439, 51.291688080511513],
            [-2.255587100267728, 51.289428907977886],
            [-2.253499993315347, 51.289838979996794],
            [-2.251061517382994, 51.285259314647561],
            [-2.250619439742227, 51.282809992775874],
            [-2.248595767520746, 51.280318182046621],
            [-2.247273293215769, 51.277811367416348],
            [-2.247399409022567, 51.275403089451281],
            [-2.245096226575415, 51.272283277226826],
            [-2.244715447112684, 51.267530090350938],
            [-2.245127725898095, 51.264202244959783],
            [-2.244434046650488, 51.263123776069499],
            [-2.247969294494356, 51.261442051441534],
            [-2.247922881930351, 51.260807325160222],
            [-2.248925029061541, 51.260349309511653],
            [-2.248609009383267, 51.260213305569991],
            [-2.248928416004602, 51.25938537565154],
            [-2.249586823220742, 51.258971246254262],
            [-2.248123674484308, 51.25817678216243],
            [-2.247810754475304, 51.256483379828893],
            [-2.245345034313629, 51.25388093944774],
            [-2.248156878073356, 51.251042569122262],
            [-2.252864965898703, 51.247356607024656],
            [-2.252435517180554, 51.246377420626743],
            [-2.255000342910228, 51.243330802976267],
            [-2.254705821168694, 51.24317858290582],
            [-2.257859271116162, 51.241703297519173],
            [-2.260792841261708, 51.239126012003908],
            [-2.261963892909272, 51.235979829872605],
            [-2.263768089269484, 51.234377914594731],
            [-2.262785721449664, 51.233909850320778],
            [-2.26269481385051, 51.233540487680067],
            [-2.265563968280559, 51.228122681079917],
            [-2.267027442595619, 51.226366825601779],
            [-2.267967477392316, 51.224006996380893],
            [-2.268130036508754, 51.222411458422769],
            [-2.267555304362284, 51.221827401376771],
            [-2.267619614986177, 51.221065638976413],
            [-2.269014917085417, 51.220438397989696],
            [-2.269765152603558, 51.21895839807825],
            [-2.269391165520954, 51.217685105246311],
            [-2.26670465932471, 51.216614032076151],
            [-2.266668374127658, 51.21553868080624],
            [-2.269183245912019, 51.213462077163015],
            [-2.269476093851982, 51.212617060467274],
            [-2.27132317485495, 51.210960071717963],
            [-2.272040847206205, 51.209579940660767],
            [-2.273253731611764, 51.209400870253631],
            [-2.272943248672383, 51.20846013987282],
            [-2.274785395485676, 51.20698474374089],
            [-2.274641595355921, 51.206634396014834],
            [-2.275856113616102, 51.205546209461644],
            [-2.276460776103999, 51.205647289309262],
            [-2.278639059792041, 51.201799860235319],
            [-2.279626959118533, 51.202086143032766],
            [-2.28017006963089, 51.201249490802589],
            [-2.280358825794226, 51.19933645055054],
            [-2.280876685190039, 51.199296542122653],
            [-2.280868218803429, 51.197905507391894],
            [-2.281342085935777, 51.197460165431622],
            [-2.284537217099878, 51.19735352102618],
            [-2.284053110964658, 51.195665109671218],
            [-2.284934095177877, 51.194644176860187],
            [-2.284822868591915, 51.193085241934575],
            [-2.28841200008532, 51.192187137213359],
            [-2.288135579274793, 51.191690563223581],
            [-2.289058135301174, 51.191164953369984],
            [-2.288127692716554, 51.189510030861982],
            [-2.288137965651337, 51.188175597357386],
            [-2.28850035185095, 51.188006553739662],
            [-2.29101609324599, 51.188276371548184],
            [-2.291575436501824, 51.185763519913422],
            [-2.293063198468881, 51.183917343752363],
            [-2.29778826980978, 51.180040649123129],
            [-2.297318824103311, 51.178231757402145],
            [-2.298468833328037, 51.175328010983975],
            [-2.303869230684823, 51.172770261929628],
            [-2.30566354643763, 51.170698323291916],
            [-2.308888497019629, 51.169346440709411],
            [-2.310126535373449, 51.168405296611944],
            [-2.311230830115802, 51.167150674382071],
            [-2.312733283000311, 51.163030122162652],
            [-2.315320252842175, 51.160922637268719],
            [-2.316280346316317, 51.160583741839268],
            [-2.316259112902876, 51.159988527706716],
            [-2.316995524516674, 51.159357091514686],
            [-2.319530456426576, 51.158260356249663],
            [-2.32241705648268, 51.154092718438498],
            [-2.328872710725117, 51.148465501351779],
            [-2.332290137920765, 51.144963326316528],
            [-2.33090894236201, 51.140931614180211],
            [-2.332177144918767, 51.13798941358084],
            [-2.341399610806091, 51.135339853246251],
            [-2.345861310812211, 51.133374557537564],
            [-2.346307259975607, 51.130516457121018],
            [-2.348198301705417, 51.131892019921878],
            [-2.349759566936424, 51.132544677975936],
            [-2.351613366666696, 51.132944657539774],
            [-2.354669820923022, 51.133060411686841],
            [-2.358380771148043, 51.134825923474345],
            [-2.3598705265127, 51.136359888018958],
            [-2.364462954076861, 51.137650405607019],
            [-2.36409845234138, 51.137828685309742],
            [-2.366352367793259, 51.139798097117129],
            [-2.368290066701058, 51.140627369730673],
            [-2.369441521605224, 51.142693707399573],
            [-2.37098635421798, 51.14335332718975],
            [-2.37502807376966, 51.143772946842098],
            [-2.376560526248137, 51.142722243719398],
            [-2.381330343534321, 51.143548436294672],
            [-2.3818168436163, 51.143259102280574],
            [-2.384633463070649, 51.142978307458804],
            [-2.387221193434162, 51.143705313830104],
            [-2.39045677938028, 51.144117177018259],
            [-2.393385300575053, 51.141866536333701],
            [-2.395329969748941, 51.14260811488225],
            [-2.401291195068003, 51.140339785088401],
            [-2.402960106910348, 51.141580343849974],
            [-2.405264905998062, 51.142458093942139],
            [-2.403585238506615, 51.145237956417901],
            [-2.405480835692923, 51.146869749293138],
            [-2.40803301076239, 51.147717811483147],
            [-2.409998003697499, 51.146666058819577],
            [-2.410292161205102, 51.146144386482959],
            [-2.411165401050741, 51.146278896690994],
            [-2.41178853893079, 51.143852443377625],
            [-2.412183322694835, 51.143718868621143],
            [-2.413388643302743, 51.144386319350751],
            [-2.414476365526504, 51.142774686433604],
            [-2.416615263252058, 51.141866976791277],
            [-2.417811193459458, 51.140860092539377],
            [-2.424763991199673, 51.141806177414196],
            [-2.425849070484522, 51.142286002746879],
            [-2.425616743796907, 51.14282547382436],
            [-2.428155543001933, 51.143293681141735],
            [-2.431071875168359, 51.139937013058123],
            [-2.434496169147467, 51.13729415224627],
            [-2.434361083919449, 51.135237266239642],
            [-2.433726367841933, 51.134460908799007],
            [-2.434816521183746, 51.133207857989014],
            [-2.436471777690894, 51.132932825321788],
            [-2.438731610647498, 51.133266963378205],
            [-2.440672299362498, 51.132501629947491],
            [-2.442507592958022, 51.132527967025965],
            [-2.443553818184901, 51.132238053120112],
            [-2.445019522074295, 51.130986178201432],
            [-2.446928233340783, 51.130185792979681],
            [-2.448186855170494, 51.128963444060901],
            [-2.448833606352591, 51.127721851419786],
            [-2.448247789319135, 51.1268446723168],
            [-2.449004024851229, 51.125992016262167],
            [-2.451596623672823, 51.125161049084397],
            [-2.453538829522677, 51.12300261742751],
            [-2.454401723307799, 51.122551458958476],
            [-2.455991078485553, 51.12216400370351],
            [-2.456980990633097, 51.123149269293286],
            [-2.460262906948229, 51.12355991974426],
            [-2.462742814251673, 51.123496175210875],
            [-2.466376612694551, 51.124219984281396],
            [-2.464822243485784, 51.121828879853801],
            [-2.468560207994463, 51.121842729259072],
            [-2.468561626706053, 51.120853591187789],
            [-2.470010724439403, 51.120178759572298],
            [-2.470785722893249, 51.119113671590853],
            [-2.471981035985984, 51.119480225171429],
            [-2.472946931467981, 51.118646348261287],
            [-2.474216192213474, 51.118839031905146],
            [-2.474940604883837, 51.118564528489792],
            [-2.478472575663629, 51.118440418936054],
            [-2.48207471717064, 51.119294255950486],
            [-2.482698339544535, 51.118674822694025],
            [-2.48355211591189, 51.118343081154727],
            [-2.483797535007211, 51.118585752033454],
            [-2.484523341765112, 51.118177200772166],
            [-2.485503779926089, 51.116846794077233],
            [-2.487380666707613, 51.116130403068844],
            [-2.489766909465043, 51.115761642198237],
            [-2.491940957434565, 51.114383909796004],
            [-2.501744705576225, 51.112731742987975],
            [-2.500322260261592, 51.110652564431163],
            [-2.498587423610675, 51.109530565599705],
            [-2.500385575381716, 51.10725776031726],
            [-2.50092237862224, 51.107100795884456],
            [-2.50109706422429, 51.1074039814768],
            [-2.502533448985281, 51.107351054864253],
            [-2.504451079665521, 51.106762800216451],
            [-2.505126090666165, 51.106052202837319],
            [-2.506099173978703, 51.106348330713367],
            [-2.506613519035265, 51.105577273690741],
            [-2.510296280517315, 51.104716888695044],
            [-2.510871781580493, 51.104318719812859],
            [-2.511609053546076, 51.104343370319235],
            [-2.511894132858639, 51.104807915409637],
            [-2.513038199370759, 51.104291245377333],
            [-2.514627892883744, 51.104677213111209],
            [-2.515420923738305, 51.104192638253807],
            [-2.516613222482209, 51.104291682854893],
            [-2.517387900877604, 51.103189414733322],
            [-2.520152430218899, 51.103392055797229],
            [-2.520650785633904, 51.103128164984199],
            [-2.51996466109739, 51.102569209909724],
            [-2.520645301288713, 51.102131858507477],
            [-2.520563728649061, 51.101608878323667],
            [-2.521775692920095, 51.101176346082433],
            [-2.524521793393179, 51.101652327665057],
            [-2.524925286331541, 51.101465277559569],
            [-2.525652941443278, 51.101778530972084],
            [-2.526523230066152, 51.101194619040719],
            [-2.527810712566707, 51.10112856402165],
            [-2.527644433996577, 51.10057359931043],
            [-2.528620749549183, 51.100900098832142],
            [-2.529224021577084, 51.100575449155059],
            [-2.529592896618735, 51.101110610215422],
            [-2.530159994462901, 51.100495672795773],
            [-2.53118019049308, 51.100661891419257],
            [-2.531406560308448, 51.101346964281987],
            [-2.532210273927987, 51.101439523139781],
            [-2.532417669612161, 51.100471921042356],
            [-2.53313386245532, 51.100530702010353],
            [-2.533434734363161, 51.099993396137371],
            [-2.534181043096645, 51.099939631165853],
            [-2.535063772596337, 51.099084934199794],
            [-2.53757227086499, 51.098529396291731],
            [-2.538679027280889, 51.098649291260102],
            [-2.539470511330546, 51.098307534844338],
            [-2.540951832766429, 51.098461647347449],
            [-2.540922034937443, 51.09786650366464],
            [-2.54143477700725, 51.09774902951898],
            [-2.542695472767238, 51.098332169148037],
            [-2.543383767756099, 51.097353320390418],
            [-2.544875777479516, 51.097203398073454],
            [-2.545372642338442, 51.096466419935638],
            [-2.546267872271712, 51.096695136071304],
            [-2.546289968069719, 51.097479150238705],
            [-2.546919914616051, 51.097613784113079],
            [-2.549054321928961, 51.096961734766936],
            [-2.549069583055826, 51.096562410542106],
            [-2.549932254021592, 51.096693236915172],
            [-2.550436272954264, 51.095724205253184],
            [-2.551240738910237, 51.095884970282356],
            [-2.551735452838256, 51.095336810617127],
            [-2.552873048000647, 51.09529186878293],
            [-2.553516977939438, 51.094087465616269],
            [-2.555305624760662, 51.093917120944354],
            [-2.55505510251685, 51.093149479621381],
            [-2.555745561438548, 51.092379165040235],
            [-2.55688445959001, 51.092686671403378],
            [-2.55876300637469, 51.092161553211973],
            [-2.559473738865782, 51.092248973858645],
            [-2.559680748542999, 51.091890992906102],
            [-2.560903931510791, 51.091846462041651],
            [-2.561503993750054, 51.091285165657247],
            [-2.561886877494792, 51.091891197118883],
            [-2.562984407462702, 51.092199742245541],
            [-2.562459756791198, 51.092856000377864],
            [-2.563112222448438, 51.093199956592777],
            [-2.562943803232947, 51.093794252107088],
            [-2.565264241864985, 51.093207551641946],
            [-2.56613408875507, 51.092524431033041],
            [-2.566397181429128, 51.091735440665722],
            [-2.567543317090482, 51.091346812916363],
            [-2.567927596079455, 51.090778438149826],
            [-2.569912126137765, 51.091108681691175],
            [-2.571711360839868, 51.090768980837687],
            [-2.572200052067527, 51.091029161028132],
            [-2.573000389923058, 51.090509987041223],
            [-2.57923720698855, 51.088786010160014],
            [-2.580677694573991, 51.090025179674967],
            [-2.582290309097475, 51.09041101381527],
            [-2.585259416975881, 51.09068213390114],
            [-2.587726665576621, 51.090221940420712],
            [-2.589380706275917, 51.090716274093211],
            [-2.591356664890231, 51.09282485333727],
            [-2.592907018242456, 51.093240530447993],
            [-2.595309812564998, 51.096428636737876],
            [-2.598095849180677, 51.098412476524466],
            [-2.602437094902759, 51.094001090275214],
            [-2.601121872625842, 51.091685178088952],
            [-2.601156824246, 51.088985539537056],
            [-2.598640021921834, 51.088689127320819],
            [-2.59635760864003, 51.088851865845676],
            [-2.593334468585168, 51.090044332899808],
            [-2.591701168804767, 51.090169512544641],
            [-2.590904545354519, 51.089507221362958],
            [-2.590898653361082, 51.089045052116369],
            [-2.589970891277151, 51.08918282405785],
            [-2.589214707542566, 51.088441185801052],
            [-2.588596255158953, 51.088312117036836],
            [-2.587736625683199, 51.085714999627172],
            [-2.586396031803603, 51.085718137463054],
            [-2.586044714453932, 51.084468185036556],
            [-2.587489628217754, 51.083123788779979],
            [-2.588294030574446, 51.0837366073767],
            [-2.590108471032996, 51.083166345160556],
            [-2.591028462590006, 51.083548360721728],
            [-2.595425418904167, 51.083752643405866],
            [-2.597665103082073, 51.084502850309086],
            [-2.60041956229772, 51.08486820171639],
            [-2.601300543559694, 51.084655052704299],
            [-2.600703255267645, 51.084284947804022],
            [-2.601738670191489, 51.083431655266025],
            [-2.601485971258899, 51.081890791688679],
            [-2.601999029745448, 51.081603096558574],
            [-2.599217787374551, 51.075285070908237],
            [-2.598012201590529, 51.071565767786041],
            [-2.598008560540466, 51.070287091352611],
            [-2.598741237253814, 51.070325602786802],
            [-2.599091906046547, 51.069849914786623],
            [-2.600467571214276, 51.069945364869092],
            [-2.601672045830607, 51.070266486200573],
            [-2.601709528165511, 51.070845392902783],
            [-2.60243733942084, 51.070838045862324],
            [-2.602768322598163, 51.070277921323502],
            [-2.603835883061215, 51.070495416693369],
            [-2.604429143839188, 51.07023966763483],
            [-2.606601542217599, 51.07112223800123],
            [-2.611502650871769, 51.070027515067466],
            [-2.613234613572165, 51.069326036045553],
            [-2.613256334634489, 51.068378140140659],
            [-2.614331662862234, 51.06778080205752],
            [-2.614192473931679, 51.066861627536809],
            [-2.613312430177963, 51.065928359314128],
            [-2.613343411536844, 51.065141375399349],
            [-2.617628109623396, 51.065076541726064],
            [-2.627320322366233, 51.064293837510704],
            [-2.631144962854261, 51.063671659181281],
            [-2.632241823802612, 51.063736767982199],
            [-2.632701306149558, 51.063316141030803],
            [-2.634897853174304, 51.064800537098087],
            [-2.636757773405762, 51.068158912994157],
            [-2.637586561764879, 51.071451850009318],
            [-2.639541089901221, 51.074494934084989],
            [-2.639990403548548, 51.076127262973564],
            [-2.639844986215964, 51.079037047134968],
            [-2.64051665342196, 51.080433457959032],
            [-2.641878048084699, 51.08101587837313],
            [-2.642523080019048, 51.082647120812283],
            [-2.63999211370516, 51.083594402209513],
            [-2.638969960932569, 51.085152053372148],
            [-2.638580739666235, 51.08498422786154],
            [-2.637882407191227, 51.085282087386986],
            [-2.636999225335452, 51.084180861438718],
            [-2.634395012750333, 51.08824062708905],
            [-2.63543991843473, 51.088951629833097],
            [-2.636968042002277, 51.089080891823045],
            [-2.639219705714149, 51.08794007436699],
            [-2.640800184838438, 51.088863012723458],
            [-2.641632509565523, 51.090712640985053],
            [-2.64397138294883, 51.091946097609643],
            [-2.643868540378468, 51.092354910744596],
            [-2.644583353681647, 51.092929169747507],
            [-2.645600677595785, 51.093281444459286],
            [-2.646692676836183, 51.095607087432775],
            [-2.646093825684195, 51.098745982024766],
            [-2.647030925792617, 51.100093225359714],
            [-2.647344372555004, 51.10167501638756],
            [-2.648387307746051, 51.102107155464346],
            [-2.651808613618166, 51.101648406938857],
            [-2.658434476851091, 51.102075265717701],
            [-2.661331293571711, 51.100899815363519],
            [-2.663955865926542, 51.099273531433909],
            [-2.669498131201691, 51.094700846432502],
            [-2.670480222528073, 51.09052911957037],
            [-2.672697157209071, 51.086850247882921],
            [-2.674236025808653, 51.085163428864824],
            [-2.674510650382597, 51.083431737688052],
            [-2.677784546567898, 51.082669137306517],
            [-2.680235797508494, 51.082766398557546],
            [-2.682699358640589, 51.081565955761846],
            [-2.68596962206388, 51.080865193098305],
            [-2.685738769740519, 51.080121090646173],
            [-2.687522521074865, 51.079496439377948],
            [-2.688220992790586, 51.079331366710605],
            [-2.689451855552841, 51.080025506867933],
            [-2.693757579570735, 51.078799559145857],
            [-2.694438890199558, 51.077681372146728],
            [-2.697339216581188, 51.076236130405924],
            [-2.699837570049224, 51.078150034372754],
            [-2.70234368609323, 51.078489294086992],
            [-2.704873474655683, 51.079444326960164],
            [-2.704887754392438, 51.08000805529494],
            [-2.707239826727251, 51.080627798443579],
            [-2.70815001092714, 51.08021403810617],
            [-2.708996565001257, 51.078814205914398],
            [-2.708740588017807, 51.078411106972723],
            [-2.710623905468796, 51.077601156975057],
            [-2.709452280956568, 51.07631698687662],
            [-2.708633127493199, 51.077077307153004],
            [-2.707607343553361, 51.076552979547934],
            [-2.705992578510727, 51.076250714695682],
            [-2.705815204830225, 51.075382234360511],
            [-2.706462052132841, 51.074460215462572],
            [-2.705557055061662, 51.07380115476343],
            [-2.705087605869047, 51.072872389506578],
            [-2.705848562177408, 51.072040506902084],
            [-2.710347824908257, 51.074581434785323],
            [-2.713790641960347, 51.075969544167052],
            [-2.715465001731934, 51.077726284978816],
            [-2.714308507969096, 51.078452740786531],
            [-2.715621884682078, 51.079752180258225],
            [-2.714270034492846, 51.080403397257413],
            [-2.714889583412944, 51.080587547113971],
            [-2.714688300792981, 51.081328841120218],
            [-2.715259206180707, 51.081597813714367],
            [-2.713941994462994, 51.082648070741044],
            [-2.714559492451821, 51.083253072407572],
            [-2.715632499152468, 51.083029793334994],
            [-2.715426610484443, 51.088190807887742],
            [-2.708604257330261, 51.08966663529845],
            [-2.708454792369927, 51.090070393884574],
            [-2.707913887614685, 51.090180679197637],
            [-2.70788362235915, 51.091564768325448],
            [-2.707409255718044, 51.091638679246316],
            [-2.710080548186194, 51.093387656299356],
            [-2.712813514739635, 51.096533589399648],
            [-2.717752759778731, 51.099363790929893],
            [-2.720212616948155, 51.101881770551444],
            [-2.72846262575391, 51.104187507491737],
            [-2.734442183933285, 51.106753338331799],
            [-2.736802700301522, 51.10716471413928],
            [-2.737626411244999, 51.107045317655164],
            [-2.738580988055399, 51.106444004260254],
            [-2.74034358062352, 51.09991797910196],
            [-2.743292730166006, 51.099026114786106],
            [-2.742779644924245, 51.096864044172918],
            [-2.746696728938352, 51.095349069264998],
            [-2.772005294673427, 51.097122472001594],
            [-2.789689827286177, 51.098409816784738],
            [-2.790923940193519, 51.098505782459888],
            [-2.794415161729563, 51.10780258232964],
            [-2.819867720022896, 51.107938860823566],
            [-2.821371748034828, 51.111880400900588],
            [-2.823176167923043, 51.114702966729197],
            [-2.822831935599023, 51.114787219790728],
            [-2.825116843584785, 51.117943581163892],
            [-2.824307114980201, 51.120371805539733],
            [-2.81918280600133, 51.119045500885889],
            [-2.819015083267794, 51.119337125641103],
            [-2.817475989846192, 51.118996301684255],
            [-2.81007142540125, 51.116097491240481],
            [-2.809692389070009, 51.116235900171787],
            [-2.803140817897051, 51.114196693630767],
            [-2.798368894040861, 51.11351359936738],
            [-2.79376222830009, 51.113795854727393],
            [-2.790301510192394, 51.113674527980479],
            [-2.786445904731735, 51.112170951146254],
            [-2.786107547330172, 51.112435800536979],
            [-2.787085856030531, 51.113009216913824],
            [-2.787845755737587, 51.11409485657029],
            [-2.789253331414239, 51.114788554533646],
            [-2.789037560242191, 51.11671435002242],
            [-2.789589588185567, 51.116662963782673],
            [-2.789751874075488, 51.118136594119925],
            [-2.788842637365579, 51.121374541233472],
            [-2.788973425012811, 51.123515607194697],
            [-2.788536482286815, 51.123701097441533],
            [-2.788982193913356, 51.124617095574123],
            [-2.791116040254335, 51.12380686295419],
            [-2.792145552876212, 51.12384755028868],
            [-2.793380442828097, 51.125199699969606],
            [-2.792825652013619, 51.125846407800331],
            [-2.793513317233838, 51.126865956483442],
            [-2.794960492943728, 51.127840773126806],
            [-2.796780351660844, 51.128213251261045],
            [-2.793163402994483, 51.129796197991567],
            [-2.795108112738268, 51.130444815393979],
            [-2.796588388548093, 51.131507510258743],
            [-2.793937576927012, 51.132689134444945],
            [-2.793615026915298, 51.13338192639489],
            [-2.790023943634433, 51.134584227402783],
            [-2.79016554112113, 51.134841346906533],
            [-2.788652013453886, 51.135179781908327],
            [-2.788870893089898, 51.136281649064742],
            [-2.787093573407799, 51.136492355526492],
            [-2.786632425063674, 51.139465583717822],
            [-2.78934049629675, 51.138925784567157],
            [-2.789870226852465, 51.139646079309372],
            [-2.786094722465464, 51.139955678191811],
            [-2.785550242439953, 51.14248884281777],
            [-2.788735799848458, 51.142946680860938],
            [-2.786008265154901, 51.152956267981864],
            [-2.796863585463238, 51.15653541883357],
            [-2.842681474425456, 51.172946876175644],
            [-2.838436716379692, 51.189545442514778],
            [-2.837210745586618, 51.191380529259682],
            [-2.825333270740615, 51.186807910556524],
            [-2.819176853723947, 51.18482346200512],
            [-2.816759418727894, 51.184372776894243],
            [-2.807038188176175, 51.17975894796988],
            [-2.805350053511678, 51.181976358095874],
            [-2.79678872840287, 51.198651550097175],
            [-2.793830991240103, 51.204347479783678],
            [-2.785651517460844, 51.204658077102216],
            [-2.785764363240097, 51.204896509029624],
            [-2.781149956172586, 51.205263718540145],
            [-2.781790967466728, 51.206177529432146],
            [-2.772471821146341, 51.207011860739421],
            [-2.768654981756536, 51.20706757612858],
            [-2.765157559877383, 51.20670384522046],
            [-2.761117769256566, 51.205564825241773],
            [-2.759105787310922, 51.205776624128831],
            [-2.758716457292618, 51.206213467770652],
            [-2.757369436487267, 51.206125082250942],
            [-2.75678539704158, 51.206648604681959],
            [-2.752415345423354, 51.207277466075794],
            [-2.750807758746018, 51.206572025440792],
            [-2.745938871675759, 51.206277661180906],
            [-2.746386170701588, 51.206674056380287],
            [-2.745442525256349, 51.207541514781788],
            [-2.746643227835128, 51.208760376645884],
            [-2.745985225057391, 51.209677270363031],
            [-2.747157170160285, 51.210795598818557],
            [-2.747496114297429, 51.211746593333274],
            [-2.748243452195887, 51.212101498522202],
            [-2.750041096518272, 51.211806736313854],
            [-2.75142634220449, 51.212474949715364],
            [-2.751371623881104, 51.212895231007842],
            [-2.750648366233086, 51.213318904747496],
            [-2.751317952912982, 51.213643716151388],
            [-2.750777498809223, 51.214744218554053],
            [-2.751639239476067, 51.214910433520799],
            [-2.751640656356451, 51.215612705055555],
            [-2.75259182640681, 51.216608306340596],
            [-2.751697051057702, 51.219162410528412],
            [-2.749866951316084, 51.22047260898497],
            [-2.751456638272199, 51.221368808605511],
            [-2.750857301453175, 51.222815882173769],
            [-2.751605080639751, 51.224239918412053],
            [-2.753112766891266, 51.223699691493913],
            [-2.754898643596184, 51.224672812086467],
            [-2.756211282952282, 51.224465610539525],
            [-2.758752641371762, 51.224697337290912],
            [-2.759900137751687, 51.225506368632402],
            [-2.759948210573667, 51.228771970857139],
            [-2.760848536618114, 51.22926248075656],
            [-2.762124596262094, 51.229088721753158],
            [-2.763615372988627, 51.229341569376629],
            [-2.765029121574846, 51.229094044311019],
            [-2.766628657877439, 51.229340747150644],
            [-2.769951148625001, 51.228130179617118],
            [-2.772236170611843, 51.228004517516283],
            [-2.773513920024611, 51.228790075716695],
            [-2.777123367200466, 51.229409089233926],
            [-2.779087469207575, 51.229443684901469],
            [-2.779801417135395, 51.229994637955272],
            [-2.780027510260664, 51.230999342267758],
            [-2.778872011506369, 51.232968207602191],
            [-2.779605588629092, 51.235863259115504],
            [-2.776513393507007, 51.237497894153172],
            [-2.777961771977234, 51.239292077563022],
            [-2.778015009936779, 51.246945747335538],
            [-2.77142809772701, 51.248531490095438],
            [-2.77119156718434, 51.248866653395424],
            [-2.76602981657649, 51.250969622204558],
            [-2.766448922235441, 51.252304890625311],
            [-2.767351315202016, 51.253059701606347],
            [-2.759887789143424, 51.257783352007209],
            [-2.757963525627894, 51.256932598349167],
            [-2.755148628168461, 51.258305891965101],
            [-2.751216939926832, 51.259577488201217],
            [-2.751663655532187, 51.260342536732061],
            [-2.750542277414044, 51.260653663572036],
            [-2.749327345483881, 51.261910437714292],
            [-2.750000556971809, 51.262238829155073],
            [-2.749488800444099, 51.262672824375976],
            [-2.749185386376203, 51.262522802392567],
            [-2.74481860767348, 51.263953426787836],
            [-2.738685675728808, 51.266452506040352],
            [-2.733777776612289, 51.2639215641289],
            [-2.727195247212853, 51.261221893960872],
            [-2.72596474614449, 51.263259924382574],
            [-2.721986617144696, 51.266218617873008],
            [-2.716134049541242, 51.267672611630417],
            [-2.711465672641356, 51.269255796691695],
            [-2.709889221697143, 51.270112413028379],
            [-2.71353427158836, 51.272783341022006],
            [-2.715147671355529, 51.273658302985027],
            [-2.720586722781861, 51.275592384013969],
            [-2.719959904580439, 51.276121372185862],
            [-2.720651308518072, 51.278070171227313],
            [-2.71638597998709, 51.278616091167017],
            [-2.713423084954823, 51.280318382012005],
            [-2.714965065464289, 51.281747685550528],
            [-2.715756096432094, 51.282089041087765],
            [-2.717888943257092, 51.281310763626436],
            [-2.719002761464866, 51.280469473307086],
            [-2.722678083468774, 51.280050292776671],
            [-2.724734770067504, 51.280458399573412],
            [-2.729593466821662, 51.280618843485172],
            [-2.732046847093871, 51.281533293525015],
            [-2.733070702087675, 51.282351448232944],
            [-2.734674844319915, 51.2872734785049],
            [-2.734282011329987, 51.288536615078428],
            [-2.73709785421832, 51.2901131899918],
            [-2.737233316387632, 51.290512478636771],
            [-2.735541079455168, 51.291049153416346],
            [-2.7346504770058, 51.292574385171946],
            [-2.735572437113202, 51.29498473040973],
            [-2.735021818450401, 51.297057234382223],
            [-2.735338523651475, 51.298565890770803],
            [-2.733715898886683, 51.29970995515901],
            [-2.733487158491403, 51.301106936491685],
            [-2.733914970480019, 51.301129432579884],
            [-2.733298703646117, 51.301702483752464],
            [-2.733611662477779, 51.301988264705841],
            [-2.732277368715689, 51.303322928152916],
            [-2.732480195211231, 51.303898940953076],
            [-2.736299193664578, 51.30652130724269],
            [-2.752613376938802, 51.307584716318765],
            [-2.765626468997189, 51.309034359952413],
            [-2.763549673241636, 51.313390125875024],
            [-2.763877188716326, 51.315054193086191],
            [-2.764857910058065, 51.316822795777952],
            [-2.763349307502267, 51.319902482328899],
            [-2.763554374956789, 51.321622197631903],
            [-2.764639431956225, 51.322580847974557],
            [-2.761477427805999, 51.322115891944286],
            [-2.75649616573185, 51.323937572089008],
            [-2.756278982315994, 51.324606174543888],
            [-2.754801330508936, 51.32455186704842],
            [-2.754785157610931, 51.322867787268656],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000188",
        LAD13CDO: "40UC",
        LAD13NM: "Sedgemoor",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-3.020264601259044, 51.215809387364367],
              [-3.02304843833894, 51.214086448062034],
              [-3.02423922832346, 51.212907040953979],
              [-3.025811629776675, 51.213054224652964],
              [-3.02572087863755, 51.213802273465511],
              [-3.026362009808965, 51.214948553176839],
              [-3.025940580392988, 51.213767075801933],
              [-3.026528603544706, 51.213619840649294],
              [-3.025875281438361, 51.213341417416757],
              [-3.026834441281224, 51.212951732658425],
              [-3.026145533183846, 51.212939792879475],
              [-3.025749860700255, 51.212529620818579],
              [-3.030286406052864, 51.208213055474822],
              [-3.030539805390231, 51.207316096937859],
              [-3.030079635524959, 51.208249046784154],
              [-3.026262016876793, 51.21199728596806],
              [-3.025507620985654, 51.212387869047056],
              [-3.02446974447289, 51.21219823437805],
              [-3.028170861981604, 51.201027093269907],
              [-3.02913515719645, 51.200109498548407],
              [-3.030194157082882, 51.201128888892875],
              [-3.029678517785709, 51.200278267125512],
              [-3.031076652245276, 51.200587873988376],
              [-3.028898728366331, 51.199583733998416],
              [-3.029921415980778, 51.197692658360822],
              [-3.032700866532934, 51.194430045644296],
              [-3.033557206818021, 51.193942296116496],
              [-3.03557913018484, 51.193613281111212],
              [-3.03925818050688, 51.194109396074509],
              [-3.041860524209647, 51.194960293139495],
              [-3.04356162766795, 51.195000881216934],
              [-3.04518103148924, 51.194656406862244],
              [-3.04827683636918, 51.192083894638145],
              [-3.053682192123623, 51.189148809712044],
              [-3.05641145345033, 51.186539812630777],
              [-3.058766448535795, 51.182034968509086],
              [-3.059803272514935, 51.178896262881921],
              [-3.058071735476389, 51.17537707123568],
              [-3.059779982425166, 51.174761818532637],
              [-3.06013900245635, 51.174261290829577],
              [-3.059609150800346, 51.173737345569997],
              [-3.061373632244076, 51.173581066044854],
              [-3.059425271468216, 51.173640995370988],
              [-3.059690487387577, 51.174539620561603],
              [-3.058213644812125, 51.175077243210417],
              [-3.058284205108198, 51.174660261589281],
              [-3.057438476807463, 51.174772221877014],
              [-3.056173107307464, 51.173360175873519],
              [-3.043056643019273, 51.16376531619693],
              [-3.040709530936221, 51.162930160252913],
              [-3.036854804457881, 51.16239788612851],
              [-3.033502907768626, 51.162987777154328],
              [-3.030277702765885, 51.164774228991966],
              [-3.0290754024006, 51.166081503110163],
              [-3.028308953472319, 51.169110552205119],
              [-3.029115721831267, 51.173481797078587],
              [-3.028639357371165, 51.174380719323203],
              [-3.027402349795029, 51.175040834801472],
              [-3.022913721909553, 51.175895778595169],
              [-3.020225855871671, 51.175966016135312],
              [-3.010234374903345, 51.173453083673188],
              [-3.00666336132673, 51.17079968405011],
              [-3.005721049956998, 51.169039012040656],
              [-3.003594225902177, 51.166934207647031],
              [-3.003619612997111, 51.165929552916111],
              [-3.005890493457795, 51.163876888690162],
              [-3.005519978765105, 51.162688598159455],
              [-3.004354572444068, 51.161905493910034],
              [-3.001500566857163, 51.160871579351159],
              [-2.996042894959833, 51.160781509572082],
              [-2.991340604038927, 51.16183849272818],
              [-2.990714621617626, 51.161857286030347],
              [-2.990366013781689, 51.161539214742064],
              [-2.992346009229533, 51.156776305786302],
              [-2.992495714906406, 51.15555658110204],
              [-2.991913545520946, 51.153947405918032],
              [-2.997555736942879, 51.15315035169121],
              [-2.998761939766915, 51.152527680500313],
              [-2.999481539377109, 51.151686148088608],
              [-2.99929940094021, 51.150469249031588],
              [-2.998594995330938, 51.149762177815639],
              [-2.999089556260877, 51.149623968013934],
              [-2.998108337336719, 51.14953792858288],
              [-2.997186815613409, 51.148705910587722],
              [-2.999230396151804, 51.145951209216406],
              [-2.99918877265145, 51.144950721737438],
              [-2.99761887298807, 51.144410200836198],
              [-2.993769584494326, 51.144767601698732],
              [-2.993027254098902, 51.144483457197289],
              [-2.992763791016317, 51.14399741254946],
              [-2.993439696834521, 51.141654572440821],
              [-2.997977915689476, 51.140178954792233],
              [-3.002711272912812, 51.137299584571693],
              [-3.002419904336898, 51.135330067827049],
              [-3.003664596786709, 51.133540708558876],
              [-3.001647893431314, 51.131490668497385],
              [-3.001395913075651, 51.129091876067193],
              [-2.998382892070322, 51.125225764935671],
              [-2.99614025061712, 51.120350367829907],
              [-2.995560647495696, 51.118512774982641],
              [-2.994076630646979, 51.117203529625158],
              [-2.992931482589196, 51.116635953507448],
              [-2.987987481557413, 51.115867632320722],
              [-2.984342882331931, 51.116471189744857],
              [-2.982046661058911, 51.11544649183638],
              [-2.979507735561847, 51.114965121500738],
              [-2.974154114572822, 51.111479466086664],
              [-2.97288266652549, 51.107736651828255],
              [-2.970967673661911, 51.10633088482934],
              [-2.970535643468452, 51.104799479373469],
              [-2.968595535363993, 51.103686133197264],
              [-2.966332184981586, 51.103025944202706],
              [-2.966272697979146, 51.100447430388463],
              [-2.965658662959098, 51.09969804661818],
              [-2.959813285127694, 51.095982893462534],
              [-2.958564173217598, 51.094623608692544],
              [-2.953770531202415, 51.092067630419109],
              [-2.948998600591581, 51.090685681458744],
              [-2.945170454337871, 51.091178908675587],
              [-2.94307059585906, 51.087769783961718],
              [-2.940170578480787, 51.085862489372076],
              [-2.939374881851304, 51.084502048994686],
              [-2.938265668821283, 51.083943541283638],
              [-2.934881617020186, 51.083356481957622],
              [-2.934135645262767, 51.08055683022156],
              [-2.937444212332891, 51.080104099752042],
              [-2.937337675697894, 51.078647291674798],
              [-2.935839045690606, 51.076538901470876],
              [-2.938856019693711, 51.075658629168231],
              [-2.946479233551456, 51.075901979244179],
              [-2.946929194493319, 51.07486870692906],
              [-2.948548528294469, 51.073923064670296],
              [-2.948703404041175, 51.073330108789754],
              [-2.948053879855617, 51.072767960619032],
              [-2.946685305918446, 51.072360909561695],
              [-2.947367133297305, 51.07115130400863],
              [-2.946268693583606, 51.068591079153762],
              [-2.941455988230192, 51.067319760770438],
              [-2.931980990609227, 51.061923845172544],
              [-2.929835096041472, 51.06315131597708],
              [-2.926923006639919, 51.061606179886901],
              [-2.930185090964495, 51.059895083904067],
              [-2.931701042959401, 51.058713095913795],
              [-2.932102683557344, 51.057965322388881],
              [-2.933314971819692, 51.057298301417688],
              [-2.935820964282809, 51.057176643968369],
              [-2.942745375710614, 51.057658727601321],
              [-2.943759064267428, 51.05803901642723],
              [-2.946398668622768, 51.056981742163529],
              [-2.947078019759452, 51.056989726445387],
              [-2.948358323540323, 51.055124208591543],
              [-2.950856938918324, 51.054301779294917],
              [-2.951234526467268, 51.052327568994592],
              [-2.955274312768082, 51.051461913018628],
              [-2.957776463168478, 51.047438002794607],
              [-2.959688319127385, 51.047380949779885],
              [-2.962792636141193, 51.048142247916893],
              [-2.963370866436425, 51.048026874854116],
              [-2.963371851680821, 51.046424415874178],
              [-2.967291406144349, 51.047012509923491],
              [-2.968495389982272, 51.046600574402248],
              [-2.96925027592652, 51.045655504484536],
              [-2.972583035338255, 51.044040644167026],
              [-2.973190518098955, 51.042602190719634],
              [-2.974212532570211, 51.042499252636162],
              [-2.974828163399863, 51.042743208319372],
              [-2.976971390031841, 51.042371004143497],
              [-2.977765936445175, 51.042575679904822],
              [-2.979420230539093, 51.042085224200882],
              [-2.980699853640094, 51.040875796950061],
              [-2.982146605557364, 51.040827674192975],
              [-2.985477635049838, 51.044142117976328],
              [-2.987458297527169, 51.0441757526782],
              [-2.987147440924004, 51.045925611677035],
              [-2.987907782998022, 51.046260898086281],
              [-2.989721802459617, 51.0465701731504],
              [-2.989827297651244, 51.046834556587108],
              [-2.994104408768882, 51.046514981763444],
              [-2.994081607352684, 51.0487812720527],
              [-2.995009982500101, 51.050894687017752],
              [-2.997443348460662, 51.053790188055693],
              [-2.999301682866923, 51.053954158500787],
              [-3.000088411058845, 51.053451044806366],
              [-3.002277753225654, 51.053020431660663],
              [-3.002515311266863, 51.052591252652462],
              [-3.005593666866823, 51.053224845455844],
              [-3.006715923975083, 51.053197196778349],
              [-3.009283184489143, 51.052499716755264],
              [-3.01544486228268, 51.0546341934946],
              [-3.016231727241229, 51.054796410660366],
              [-3.017848903818248, 51.05456381811851],
              [-3.019691585260836, 51.054140393961198],
              [-3.020273772311635, 51.053558895739386],
              [-3.022412935968666, 51.053643610542295],
              [-3.025554682077639, 51.050414762829156],
              [-3.026141484384448, 51.049272963804583],
              [-3.027289637443663, 51.048357333668619],
              [-3.028694177949638, 51.048198399017437],
              [-3.031204522212724, 51.047919985042796],
              [-3.031971842663681, 51.050863638080543],
              [-3.029450724555962, 51.050856202595682],
              [-3.029802594451019, 51.054048122600527],
              [-3.03298233835444, 51.054151345033041],
              [-3.032963184197659, 51.054444668500601],
              [-3.030024610928106, 51.054537153801725],
              [-3.029870156150909, 51.055356828689085],
              [-3.032428509021171, 51.05555186920585],
              [-3.032332449958257, 51.056108451666276],
              [-3.03104193707146, 51.055758349369036],
              [-3.031139786723482, 51.056373469046484],
              [-3.032222129687516, 51.056601313546807],
              [-3.032203069353105, 51.057091570658677],
              [-3.024444978315875, 51.057019563458844],
              [-3.024672974199465, 51.059849285910111],
              [-3.026186891214297, 51.059393572115404],
              [-3.028045076729849, 51.059793588974095],
              [-3.029765575321522, 51.059389066477799],
              [-3.032694562243405, 51.060006177727665],
              [-3.035865342064063, 51.059880993835328],
              [-3.036051644629401, 51.060300188865114],
              [-3.035542995284562, 51.060974635188238],
              [-3.040512319629298, 51.061252421122539],
              [-3.043153570190911, 51.062388898647008],
              [-3.04338641395425, 51.06328786345518],
              [-3.045393156005443, 51.065200607237728],
              [-3.04615868141207, 51.065479716222328],
              [-3.051415480800974, 51.066264338038536],
              [-3.051568975937013, 51.065543560718709],
              [-3.052715397342407, 51.064996389157955],
              [-3.054378893468729, 51.065287134862281],
              [-3.056866260809201, 51.065093808449738],
              [-3.062433917431076, 51.065424601084828],
              [-3.065213638260615, 51.066093513236822],
              [-3.070670110343913, 51.068030081837151],
              [-3.071164912311532, 51.067949111438423],
              [-3.077980539778625, 51.070005062562302],
              [-3.084377178244523, 51.071513268049046],
              [-3.086301971094358, 51.070143824104093],
              [-3.090349882278184, 51.070696031637844],
              [-3.090703724577719, 51.069173002221561],
              [-3.089556177358607, 51.066072311660626],
              [-3.094582071407868, 51.064959734016895],
              [-3.095813266080475, 51.06766122574583],
              [-3.100939354572209, 51.067522220365099],
              [-3.100721419882048, 51.067961307865971],
              [-3.099526793055573, 51.068561561593384],
              [-3.099480883188015, 51.069153698538869],
              [-3.100383493466106, 51.072427456037843],
              [-3.102306674104769, 51.07305049473095],
              [-3.104556190274878, 51.073289137045698],
              [-3.114572840138466, 51.072902676832491],
              [-3.115476419121317, 51.072133291537639],
              [-3.121330297007638, 51.070965796810796],
              [-3.129115442438531, 51.071869218839375],
              [-3.13117831536488, 51.072001242323282],
              [-3.131286778620469, 51.071649485164507],
              [-3.131881681935088, 51.071695881106237],
              [-3.134863743802111, 51.072352187926846],
              [-3.136528215943881, 51.072068035786486],
              [-3.137737096050436, 51.072122817169443],
              [-3.138855610914843, 51.072798949014626],
              [-3.138764690889628, 51.073746744812553],
              [-3.141912524918097, 51.075850847051356],
              [-3.143454533823773, 51.077312337100416],
              [-3.143842695066132, 51.079275196476829],
              [-3.145870953869724, 51.079088966294464],
              [-3.147581409616053, 51.082155708069074],
              [-3.147491294627647, 51.083187131952648],
              [-3.149693687098338, 51.083050383423576],
              [-3.153038007965158, 51.084857299989551],
              [-3.150687708109953, 51.088184302291253],
              [-3.149918151171518, 51.088704447703371],
              [-3.15709675739058, 51.089763954095346],
              [-3.159941528697525, 51.090864312811632],
              [-3.159332529873363, 51.091407206347725],
              [-3.155500518100449, 51.091488317450086],
              [-3.151278689867493, 51.093295202319347],
              [-3.154069963442787, 51.094939375930998],
              [-3.153790663527651, 51.096512218988813],
              [-3.150926574050118, 51.097862348921019],
              [-3.147648173697924, 51.097944942065574],
              [-3.142212733299754, 51.098817397314861],
              [-3.140848976910891, 51.099519538367595],
              [-3.140016473010867, 51.100569886637174],
              [-3.142295875466198, 51.102653666277305],
              [-3.142815415231786, 51.104028929565565],
              [-3.143040508990746, 51.10580004006907],
              [-3.141593590663049, 51.107308724812455],
              [-3.142156511741983, 51.109283362473498],
              [-3.146775699440138, 51.107587124555572],
              [-3.147782138899454, 51.106812888276018],
              [-3.151511498312171, 51.106113465937497],
              [-3.153603996741642, 51.108633202372403],
              [-3.155035963786072, 51.108658628655661],
              [-3.156441173683524, 51.107726605613649],
              [-3.158197125277149, 51.107570736769013],
              [-3.158357262584971, 51.106548505968419],
              [-3.159698219179163, 51.106478557453997],
              [-3.161466978303508, 51.104955657880019],
              [-3.162341357295442, 51.105249113297788],
              [-3.162728118348022, 51.104894560688336],
              [-3.165954423397569, 51.104085474585609],
              [-3.168415419889022, 51.104310891453814],
              [-3.168038656296435, 51.106136530843031],
              [-3.168621278720797, 51.108395010254419],
              [-3.175041451519813, 51.107518629926993],
              [-3.179521087684331, 51.108866447428397],
              [-3.181879869139051, 51.108712231576312],
              [-3.183189370402862, 51.110261875881335],
              [-3.186316376645267, 51.111710344110428],
              [-3.190507052150626, 51.111791836536874],
              [-3.193546008153922, 51.112360645684511],
              [-3.193864364314033, 51.112518360071093],
              [-3.193701237992707, 51.114004683275567],
              [-3.194323640178704, 51.114983001696935],
              [-3.197726633715781, 51.118072169525483],
              [-3.199427517009942, 51.119696755567418],
              [-3.20538339958134, 51.123230686138342],
              [-3.208443256713697, 51.125987661070567],
              [-3.215646590950007, 51.129402958036714],
              [-3.216458264063327, 51.130379190388744],
              [-3.215538826822595, 51.133135950146482],
              [-3.213859999221929, 51.134245975837288],
              [-3.212590390895936, 51.135812147128838],
              [-3.205774571829256, 51.14137612097246],
              [-3.205457393875035, 51.142809189190238],
              [-3.204533897610059, 51.14214966913535],
              [-3.20172417236808, 51.142727119228603],
              [-3.198297801695152, 51.142680453240253],
              [-3.196681762032327, 51.143152020984928],
              [-3.196236847767397, 51.142918274232088],
              [-3.193799689919075, 51.14309245721644],
              [-3.192507055288734, 51.143634402541473],
              [-3.189995919669782, 51.14554749608137],
              [-3.186827917172889, 51.146774787163054],
              [-3.185122038464674, 51.146930572647371],
              [-3.180617313998442, 51.146487855004814],
              [-3.178888302875624, 51.146633891427008],
              [-3.177356599932496, 51.147455044318214],
              [-3.177419338874148, 51.148852732388676],
              [-3.175742728823506, 51.148870502044197],
              [-3.175277245395077, 51.150526187418009],
              [-3.174659792208747, 51.150421785189636],
              [-3.173704877842319, 51.151555424484513],
              [-3.17364120102678, 51.152314123116184],
              [-3.172891444152516, 51.152575231580741],
              [-3.169447973231954, 51.152445159282934],
              [-3.167407167583026, 51.152824348068798],
              [-3.165983973119168, 51.152747724604225],
              [-3.163318556879015, 51.154104246756219],
              [-3.161757948878549, 51.156584575691674],
              [-3.161580901900765, 51.15921390941201],
              [-3.158974809489232, 51.159533817894896],
              [-3.157668212188273, 51.16160871242883],
              [-3.158255981231022, 51.16217930369767],
              [-3.157529686339097, 51.163672934576233],
              [-3.156654606172031, 51.162708618118806],
              [-3.152178616791117, 51.161862558436809],
              [-3.144009050402115, 51.163047026501104],
              [-3.142574825569666, 51.162998101562067],
              [-3.140674322522769, 51.162367405949183],
              [-3.13813082141764, 51.162454235172596],
              [-3.135272287325864, 51.162990988081724],
              [-3.133304995008143, 51.163948871906726],
              [-3.134278381278821, 51.166490566181139],
              [-3.136491596310936, 51.16767855073445],
              [-3.131333561874207, 51.168130520486301],
              [-3.128005144537409, 51.16894770343881],
              [-3.127304064418344, 51.168762926480788],
              [-3.124809603364298, 51.168940713811139],
              [-3.123221895463871, 51.169527886527227],
              [-3.120311987748954, 51.169327392595314],
              [-3.119025229899941, 51.1697048009528],
              [-3.113630137933663, 51.169951432762964],
              [-3.112787310607577, 51.170531367833952],
              [-3.114361947070108, 51.17230225198135],
              [-3.114438643771411, 51.17346602809549],
              [-3.115464470564019, 51.173473333986223],
              [-3.116517765771441, 51.174499199780421],
              [-3.116339372594122, 51.175937876920628],
              [-3.114317039053813, 51.176075861335512],
              [-3.112372602374308, 51.176716640075597],
              [-3.112684886851307, 51.17786288849706],
              [-3.108850524854787, 51.177968545157839],
              [-3.107900291137899, 51.17765742370684],
              [-3.106053487262145, 51.177602065602244],
              [-3.106350609860755, 51.179376137380977],
              [-3.107281292318183, 51.18095986920185],
              [-3.10725636684499, 51.182964491819639],
              [-3.106229302627873, 51.183272755073247],
              [-3.103544468132422, 51.183303512868505],
              [-3.099086307023657, 51.184331946832181],
              [-3.09682015239671, 51.1845231741734],
              [-3.09828759160157, 51.18810992143483],
              [-3.096472209974611, 51.190118746866929],
              [-3.092937254353201, 51.190403614654137],
              [-3.088375105924359, 51.191248265846447],
              [-3.088055072376276, 51.1918168580928],
              [-3.085609622809689, 51.193286438589247],
              [-3.08502892449567, 51.194762064575031],
              [-3.085486055590362, 51.196066200006968],
              [-3.083587635553712, 51.194939084475557],
              [-3.081456833187615, 51.19751261022769],
              [-3.07912619197239, 51.196628612379534],
              [-3.070126913491781, 51.195394819444452],
              [-3.067464930866761, 51.19446416335159],
              [-3.059188543610432, 51.199415002856909],
              [-3.058799061284249, 51.200312359093829],
              [-3.0569022513726, 51.201985885295066],
              [-3.056077159478617, 51.201645336943805],
              [-3.05256523212765, 51.203177795946573],
              [-3.051587757588838, 51.20331068000052],
              [-3.053707382555527, 51.204553218742305],
              [-3.054547452940717, 51.205673276385461],
              [-3.04582810995592, 51.209957221462062],
              [-3.032565996562692, 51.217389300758299],
              [-3.030819007831614, 51.218087215323571],
              [-3.028286141356333, 51.218668820176042],
              [-3.021868738520687, 51.219131495748208],
              [-3.021187031183712, 51.218802038796326],
              [-3.019772576190115, 51.218812577914541],
              [-3.019143286711267, 51.21826234318911],
              [-3.019992791974513, 51.21795729799814],
              [-3.020264601259044, 51.215809387364367],
            ],
          ],
          [
            [
              [-3.01450367774932, 51.225277014619408],
              [-3.014812294013797, 51.223096429124425],
              [-3.015144394161555, 51.223806629087953],
              [-3.015831358394234, 51.223983208131628],
              [-3.015693005976694, 51.224463693180105],
              [-3.01639867886323, 51.225748843793525],
              [-3.018173958059046, 51.227153275264307],
              [-3.017499241149062, 51.227729249658161],
              [-3.018134966427593, 51.227852307716759],
              [-3.018674603958046, 51.228423110746299],
              [-3.015815242920028, 51.228519008821095],
              [-3.013324705845257, 51.22675925286353],
              [-3.013397076872908, 51.226077018157191],
              [-3.01450367774932, 51.225277014619408],
            ],
          ],
          [
            [
              [-3.016022241463836, 51.233364897535957],
              [-3.015524998582804, 51.231663397556233],
              [-3.016789624255888, 51.23224499951975],
              [-3.017829549992309, 51.232119059539627],
              [-3.01880427034512, 51.232737331601825],
              [-3.018828370382174, 51.233568898565096],
              [-3.01992981513465, 51.233956757123842],
              [-3.019643257210007, 51.234406166005961],
              [-3.018797917499092, 51.234265158501195],
              [-3.01745588839122, 51.234203999273383],
              [-3.016022241463836, 51.233364897535957],
            ],
          ],
          [
            [
              [-3.022175868415357, 51.326439926124387],
              [-3.02024094677993, 51.326428925839252],
              [-3.018973892616589, 51.326957900490726],
              [-3.017684205214732, 51.326854917577862],
              [-3.014609441973876, 51.326312404457177],
              [-3.011106340483472, 51.325215104695395],
              [-3.008632355852971, 51.325685144384607],
              [-3.006522372269572, 51.325280675004365],
              [-3.002964695998877, 51.325155640452955],
              [-3.001434397217404, 51.3243468664677],
              [-3.002061937805158, 51.323969227428407],
              [-3.000839957358387, 51.322224433263862],
              [-2.99640822292463, 51.321752381933258],
              [-2.997402678882398, 51.321287119595617],
              [-2.997177469620331, 51.321093011389905],
              [-2.995528064593329, 51.321532370140737],
              [-2.99410303905515, 51.320934817547375],
              [-2.993205078875535, 51.31942458229544],
              [-2.995665542537795, 51.319928820245849],
              [-2.997552718581685, 51.319141240214279],
              [-2.995362000800031, 51.319761451541702],
              [-2.993058892291036, 51.318901586469423],
              [-2.992615133424389, 51.317954890890839],
              [-2.991770581484033, 51.317319116918455],
              [-2.987198806678138, 51.314642157331825],
              [-2.987865776783817, 51.314364072908582],
              [-2.986769037652849, 51.314206069415611],
              [-2.986306283785981, 51.312566223303094],
              [-2.987136230443343, 51.310791392656455],
              [-2.988986967813262, 51.309053796373156],
              [-2.991535999867672, 51.308237338725412],
              [-2.992959471234252, 51.308118269054049],
              [-2.994281654526346, 51.307295964355575],
              [-2.995208814497802, 51.307579429261288],
              [-2.994947725471171, 51.302738525089893],
              [-2.993567510840148, 51.3009338500406],
              [-2.993179787322241, 51.301046843190775],
              [-2.991964347438116, 51.299893575073611],
              [-2.98889433873131, 51.298739789207623],
              [-2.986632766859119, 51.298290384774049],
              [-2.983343899925212, 51.298293778329807],
              [-2.981297746211988, 51.297584389159518],
              [-2.977204348006913, 51.297388433355039],
              [-2.975314794935306, 51.296346724746812],
              [-2.973926644113512, 51.295747720794239],
              [-2.972094116258355, 51.295769246725236],
              [-2.969792019563017, 51.29617230139565],
              [-2.964300019632944, 51.29787490503174],
              [-2.962289293090454, 51.299941637895515],
              [-2.962972382693148, 51.302149862978354],
              [-2.962777413297402, 51.304485801063464],
              [-2.961771726608853, 51.304824974380502],
              [-2.958216654402255, 51.304742624578729],
              [-2.953282361540122, 51.30442142690562],
              [-2.952115957705149, 51.30323677626059],
              [-2.950701088157793, 51.302728534790205],
              [-2.939016015306244, 51.30259534462062],
              [-2.937609404596818, 51.303047227982333],
              [-2.936422493470049, 51.303018062088796],
              [-2.93375835122914, 51.301942763745956],
              [-2.931642288343055, 51.299097009680779],
              [-2.927913560212779, 51.297989132257058],
              [-2.925287232471427, 51.298555736354018],
              [-2.924158852242271, 51.298435164340361],
              [-2.919203815121894, 51.295737002573922],
              [-2.917949941223831, 51.294641720891711],
              [-2.914644731562149, 51.294242269043899],
              [-2.913162961775684, 51.293652270376327],
              [-2.89709685484029, 51.290618495732311],
              [-2.893518863237087, 51.295053750223722],
              [-2.888116971181058, 51.304906036518368],
              [-2.886790036638364, 51.305055471216384],
              [-2.886194396043317, 51.30593310453974],
              [-2.884913127827283, 51.306668450308635],
              [-2.882224969270529, 51.304662841547383],
              [-2.881427294216691, 51.303535858215056],
              [-2.880299672656618, 51.303904921785325],
              [-2.879183395750561, 51.303067163206009],
              [-2.87713942992654, 51.300066578761992],
              [-2.867939555201226, 51.302209601664828],
              [-2.864371885310508, 51.300138165668663],
              [-2.856542857141693, 51.300130068488713],
              [-2.853154250655998, 51.299479514437458],
              [-2.82957139322923, 51.300884503979177],
              [-2.821747843689715, 51.301108731678333],
              [-2.820645811556691, 51.300844904628796],
              [-2.810230834832637, 51.301428194822812],
              [-2.80995817444753, 51.305576275581437],
              [-2.81076740577614, 51.307293533355008],
              [-2.812186581178698, 51.308938216504401],
              [-2.812062963457298, 51.310712287364758],
              [-2.810310611543168, 51.311331385682884],
              [-2.808304534596227, 51.311721116511393],
              [-2.807434694458583, 51.311617416944344],
              [-2.807100824882785, 51.311968606660237],
              [-2.807667130188075, 51.312843214628714],
              [-2.807803437198896, 51.314885244333098],
              [-2.81014329816593, 51.315597424337319],
              [-2.81050955662304, 51.3171648827309],
              [-2.811876325603252, 51.317287594708922],
              [-2.81271375976474, 51.31831136211315],
              [-2.814331528369587, 51.318344181930946],
              [-2.814897941748755, 51.318807822048633],
              [-2.81792154717479, 51.319057396236801],
              [-2.818185569603088, 51.320346794416359],
              [-2.818671119913486, 51.320375771383127],
              [-2.818900845647006, 51.321268862990948],
              [-2.818361118737173, 51.32134097535846],
              [-2.818992983610004, 51.322654775541771],
              [-2.818466747085157, 51.322760064145626],
              [-2.818906415671295, 51.32407880464028],
              [-2.818667353720594, 51.326368923571259],
              [-2.815456779853533, 51.326771685771639],
              [-2.814915451163081, 51.327081180112351],
              [-2.812305605522641, 51.327209016248524],
              [-2.807991358277091, 51.326231770631985],
              [-2.806464336649108, 51.326151479592411],
              [-2.801958968956667, 51.326545697704489],
              [-2.799780753544843, 51.326157758062713],
              [-2.798461596747118, 51.326971538169005],
              [-2.797617366312861, 51.326787562557584],
              [-2.797207899374042, 51.325852493670133],
              [-2.795642427432547, 51.324958554388374],
              [-2.795231084068599, 51.325321924942578],
              [-2.791209620942571, 51.325147749434336],
              [-2.789322752789357, 51.324667727263943],
              [-2.786519551540854, 51.324814276141495],
              [-2.785412576400303, 51.324198566843307],
              [-2.778662529488715, 51.323568358126799],
              [-2.773311339725192, 51.323417690329684],
              [-2.768316177100296, 51.323701449364862],
              [-2.765155298815431, 51.322448893551901],
              [-2.764639431956225, 51.322580847974557],
              [-2.763554374956789, 51.321622197631903],
              [-2.763349307502267, 51.319902482328899],
              [-2.764857910058065, 51.316822795777952],
              [-2.763877188716326, 51.315054193086191],
              [-2.763549673241636, 51.313390125875024],
              [-2.765626468997189, 51.309034359952413],
              [-2.752613376938802, 51.307584716318765],
              [-2.736299193664578, 51.30652130724269],
              [-2.732480195211231, 51.303898940953076],
              [-2.732277368715689, 51.303322928152916],
              [-2.733611662477779, 51.301988264705841],
              [-2.733298703646117, 51.301702483752464],
              [-2.733914970480019, 51.301129432579884],
              [-2.733487158491403, 51.301106936491685],
              [-2.733715898886683, 51.29970995515901],
              [-2.735338523651475, 51.298565890770803],
              [-2.735021818450401, 51.297057234382223],
              [-2.735572437113202, 51.29498473040973],
              [-2.7346504770058, 51.292574385171946],
              [-2.735541079455168, 51.291049153416346],
              [-2.737233316387632, 51.290512478636771],
              [-2.73709785421832, 51.2901131899918],
              [-2.734282011329987, 51.288536615078428],
              [-2.734674844319915, 51.2872734785049],
              [-2.733070702087675, 51.282351448232944],
              [-2.732046847093871, 51.281533293525015],
              [-2.729593466821662, 51.280618843485172],
              [-2.724734770067504, 51.280458399573412],
              [-2.722678083468774, 51.280050292776671],
              [-2.719002761464866, 51.280469473307086],
              [-2.717888943257092, 51.281310763626436],
              [-2.715756096432094, 51.282089041087765],
              [-2.714965065464289, 51.281747685550528],
              [-2.713423084954823, 51.280318382012005],
              [-2.71638597998709, 51.278616091167017],
              [-2.720651308518072, 51.278070171227313],
              [-2.719959904580439, 51.276121372185862],
              [-2.720586722781861, 51.275592384013969],
              [-2.715147671355529, 51.273658302985027],
              [-2.71353427158836, 51.272783341022006],
              [-2.709889221697143, 51.270112413028379],
              [-2.711465672641356, 51.269255796691695],
              [-2.716134049541242, 51.267672611630417],
              [-2.721986617144696, 51.266218617873008],
              [-2.72596474614449, 51.263259924382574],
              [-2.727195247212853, 51.261221893960872],
              [-2.733777776612289, 51.2639215641289],
              [-2.738685675728808, 51.266452506040352],
              [-2.74481860767348, 51.263953426787836],
              [-2.749185386376203, 51.262522802392567],
              [-2.749488800444099, 51.262672824375976],
              [-2.750000556971809, 51.262238829155073],
              [-2.749327345483881, 51.261910437714292],
              [-2.750542277414044, 51.260653663572036],
              [-2.751663655532187, 51.260342536732061],
              [-2.751216939926832, 51.259577488201217],
              [-2.755148628168461, 51.258305891965101],
              [-2.757963525627894, 51.256932598349167],
              [-2.759887789143424, 51.257783352007209],
              [-2.767351315202016, 51.253059701606347],
              [-2.766448922235441, 51.252304890625311],
              [-2.76602981657649, 51.250969622204558],
              [-2.77119156718434, 51.248866653395424],
              [-2.77142809772701, 51.248531490095438],
              [-2.778015009936779, 51.246945747335538],
              [-2.777961771977234, 51.239292077563022],
              [-2.776513393507007, 51.237497894153172],
              [-2.779605588629092, 51.235863259115504],
              [-2.778872011506369, 51.232968207602191],
              [-2.780027510260664, 51.230999342267758],
              [-2.779801417135395, 51.229994637955272],
              [-2.779087469207575, 51.229443684901469],
              [-2.777123367200466, 51.229409089233926],
              [-2.773513920024611, 51.228790075716695],
              [-2.772236170611843, 51.228004517516283],
              [-2.769951148625001, 51.228130179617118],
              [-2.766628657877439, 51.229340747150644],
              [-2.765029121574846, 51.229094044311019],
              [-2.763615372988627, 51.229341569376629],
              [-2.762124596262094, 51.229088721753158],
              [-2.760848536618114, 51.22926248075656],
              [-2.759948210573667, 51.228771970857139],
              [-2.759900137751687, 51.225506368632402],
              [-2.758752641371762, 51.224697337290912],
              [-2.756211282952282, 51.224465610539525],
              [-2.754898643596184, 51.224672812086467],
              [-2.753112766891266, 51.223699691493913],
              [-2.751605080639751, 51.224239918412053],
              [-2.750857301453175, 51.222815882173769],
              [-2.751456638272199, 51.221368808605511],
              [-2.749866951316084, 51.22047260898497],
              [-2.751697051057702, 51.219162410528412],
              [-2.75259182640681, 51.216608306340596],
              [-2.751640656356451, 51.215612705055555],
              [-2.751639239476067, 51.214910433520799],
              [-2.750777498809223, 51.214744218554053],
              [-2.751317952912982, 51.213643716151388],
              [-2.750648366233086, 51.213318904747496],
              [-2.751371623881104, 51.212895231007842],
              [-2.75142634220449, 51.212474949715364],
              [-2.750041096518272, 51.211806736313854],
              [-2.748243452195887, 51.212101498522202],
              [-2.747496114297429, 51.211746593333274],
              [-2.747157170160285, 51.210795598818557],
              [-2.745985225057391, 51.209677270363031],
              [-2.746643227835128, 51.208760376645884],
              [-2.745442525256349, 51.207541514781788],
              [-2.746386170701588, 51.206674056380287],
              [-2.745938871675759, 51.206277661180906],
              [-2.750807758746018, 51.206572025440792],
              [-2.752415345423354, 51.207277466075794],
              [-2.75678539704158, 51.206648604681959],
              [-2.757369436487267, 51.206125082250942],
              [-2.758716457292618, 51.206213467770652],
              [-2.759105787310922, 51.205776624128831],
              [-2.761117769256566, 51.205564825241773],
              [-2.765157559877383, 51.20670384522046],
              [-2.768654981756536, 51.20706757612858],
              [-2.772471821146341, 51.207011860739421],
              [-2.781790967466728, 51.206177529432146],
              [-2.781149956172586, 51.205263718540145],
              [-2.785764363240097, 51.204896509029624],
              [-2.785651517460844, 51.204658077102216],
              [-2.793830991240103, 51.204347479783678],
              [-2.79678872840287, 51.198651550097175],
              [-2.805350053511678, 51.181976358095874],
              [-2.807038188176175, 51.17975894796988],
              [-2.816759418727894, 51.184372776894243],
              [-2.819176853723947, 51.18482346200512],
              [-2.825333270740615, 51.186807910556524],
              [-2.837210745586618, 51.191380529259682],
              [-2.838436716379692, 51.189545442514778],
              [-2.842681474425456, 51.172946876175644],
              [-2.796863585463238, 51.15653541883357],
              [-2.786008265154901, 51.152956267981864],
              [-2.788735799848458, 51.142946680860938],
              [-2.785550242439953, 51.14248884281777],
              [-2.786094722465464, 51.139955678191811],
              [-2.789870226852465, 51.139646079309372],
              [-2.78934049629675, 51.138925784567157],
              [-2.786632425063674, 51.139465583717822],
              [-2.787093573407799, 51.136492355526492],
              [-2.788870893089898, 51.136281649064742],
              [-2.788652013453886, 51.135179781908327],
              [-2.79016554112113, 51.134841346906533],
              [-2.790023943634433, 51.134584227402783],
              [-2.793615026915298, 51.13338192639489],
              [-2.793937576927012, 51.132689134444945],
              [-2.796588388548093, 51.131507510258743],
              [-2.795108112738268, 51.130444815393979],
              [-2.793163402994483, 51.129796197991567],
              [-2.796780351660844, 51.128213251261045],
              [-2.794960492943728, 51.127840773126806],
              [-2.793513317233838, 51.126865956483442],
              [-2.792825652013619, 51.125846407800331],
              [-2.793380442828097, 51.125199699969606],
              [-2.792145552876212, 51.12384755028868],
              [-2.791116040254335, 51.12380686295419],
              [-2.788982193913356, 51.124617095574123],
              [-2.788536482286815, 51.123701097441533],
              [-2.788973425012811, 51.123515607194697],
              [-2.788842637365579, 51.121374541233472],
              [-2.789751874075488, 51.118136594119925],
              [-2.789589588185567, 51.116662963782673],
              [-2.789037560242191, 51.11671435002242],
              [-2.789253331414239, 51.114788554533646],
              [-2.787845755737587, 51.11409485657029],
              [-2.787085856030531, 51.113009216913824],
              [-2.786107547330172, 51.112435800536979],
              [-2.786445904731735, 51.112170951146254],
              [-2.790301510192394, 51.113674527980479],
              [-2.79376222830009, 51.113795854727393],
              [-2.798368894040861, 51.11351359936738],
              [-2.803140817897051, 51.114196693630767],
              [-2.809692389070009, 51.116235900171787],
              [-2.81007142540125, 51.116097491240481],
              [-2.817475989846192, 51.118996301684255],
              [-2.819015083267794, 51.119337125641103],
              [-2.81918280600133, 51.119045500885889],
              [-2.824307114980201, 51.120371805539733],
              [-2.825116843584785, 51.117943581163892],
              [-2.822831935599023, 51.114787219790728],
              [-2.823176167923043, 51.114702966729197],
              [-2.821371748034828, 51.111880400900588],
              [-2.819867720022896, 51.107938860823566],
              [-2.845161226642124, 51.102009052648796],
              [-2.84993877972312, 51.103181175094782],
              [-2.857289050800326, 51.07795352995376],
              [-2.859253761733507, 51.07797866709592],
              [-2.864683620417106, 51.078800982206573],
              [-2.877428559697269, 51.081287687362767],
              [-2.877594761478106, 51.079131876193841],
              [-2.879527737158732, 51.078157862343794],
              [-2.882079644622361, 51.075505671304541],
              [-2.883105971553153, 51.073481836260967],
              [-2.88572878022868, 51.071203108280599],
              [-2.887482029695173, 51.070186259838877],
              [-2.888187813476079, 51.069105411930678],
              [-2.890474789396121, 51.070546567352793],
              [-2.894942182445931, 51.072322601348816],
              [-2.896802611441295, 51.073018735057872],
              [-2.897866041800719, 51.073095097506979],
              [-2.900101230990424, 51.074195652563624],
              [-2.901307568651402, 51.074278976099151],
              [-2.9019503244686, 51.075475395610496],
              [-2.904385979346499, 51.075132847106246],
              [-2.912188645886304, 51.085167017354856],
              [-2.92157781790562, 51.081812048528896],
              [-2.922175181042325, 51.08291969064571],
              [-2.92325239503347, 51.082886007253464],
              [-2.925258905773154, 51.08513621196478],
              [-2.93457953785524, 51.083314835417667],
              [-2.935038642762441, 51.083709524704688],
              [-2.937875853452548, 51.084011415130163],
              [-2.939236002025928, 51.084623662796183],
              [-2.939839812774427, 51.085885832124127],
              [-2.943065996546388, 51.088175376355494],
              [-2.945070486360501, 51.091388339636268],
              [-2.949263094075859, 51.090911040181936],
              [-2.954501037383261, 51.092593115226698],
              [-2.958080987696111, 51.094598794845353],
              [-2.959550092496277, 51.096100156568085],
              [-2.965577029334516, 51.099961297661423],
              [-2.966048635922085, 51.103195545628807],
              [-2.968623685338459, 51.10400782594543],
              [-2.970206642005165, 51.104912816446685],
              [-2.970638376402426, 51.106362395186473],
              [-2.972692637903707, 51.108074546874924],
              [-2.973125232993567, 51.110239899092456],
              [-2.97394712116929, 51.111758154887617],
              [-2.979506431197958, 51.115241197143703],
              [-2.981655048393901, 51.115573875253716],
              [-2.984264527674741, 51.116750611915933],
              [-2.986325441166579, 51.116381641264681],
              [-2.988162750042836, 51.116180882788157],
              [-2.992596770550525, 51.116950830216517],
              [-2.994941215795111, 51.118410140416799],
              [-2.998010502785258, 51.125252324539453],
              [-2.999711164609105, 51.127775429888096],
              [-3.000901733074953, 51.12884072574014],
              [-3.00105010703347, 51.13147240835935],
              [-3.003055137214298, 51.133512667248993],
              [-3.00182554065328, 51.13540620151295],
              [-3.002030251943257, 51.137269453788093],
              [-2.996981991947764, 51.140130799583623],
              [-2.993557805857214, 51.141030402166663],
              [-2.992668944623425, 51.141575691680991],
              [-2.991954072084556, 51.143236342246972],
              [-2.992396940566877, 51.144755879870985],
              [-2.993997406777791, 51.145324086981361],
              [-2.997547978411019, 51.14503397220264],
              [-2.998559647179611, 51.14521057832102],
              [-2.998616440771715, 51.145656111435919],
              [-2.996691371723813, 51.147608578427274],
              [-2.996413165702383, 51.148763761828491],
              [-2.998655188650108, 51.151623068496988],
              [-2.997139639624653, 51.152685402366764],
              [-2.99212045141115, 51.152861180988772],
              [-2.991118903892321, 51.153230264924183],
              [-2.990324466118046, 51.153990549186304],
              [-2.98991013751144, 51.153939204476629],
              [-2.99081479367571, 51.157122004175534],
              [-2.98836208774345, 51.158645369667248],
              [-2.987700619498321, 51.159678775443965],
              [-2.988383535757536, 51.161321289007461],
              [-2.989885458447493, 51.162672712786694],
              [-2.990746088137947, 51.162925301281213],
              [-2.993349835878427, 51.162700887137959],
              [-2.996041325921269, 51.161837216140881],
              [-3.001543747788491, 51.161874747886465],
              [-3.001321620400977, 51.165485250239755],
              [-3.002073718962211, 51.167389665901865],
              [-3.00647681043182, 51.172808363509816],
              [-3.009311226193061, 51.174543722828354],
              [-3.018031589674876, 51.177143340486907],
              [-3.023319346193003, 51.177223086060685],
              [-3.028798914286205, 51.176214639069755],
              [-3.029995462476418, 51.175728416209608],
              [-3.031208737389518, 51.174454310089473],
              [-3.031599316497204, 51.172662301385571],
              [-3.03090573200562, 51.167411528277817],
              [-3.032019120835849, 51.165774103950781],
              [-3.033775871742029, 51.164450209290436],
              [-3.035155916058323, 51.163935332465215],
              [-3.036488529099208, 51.163794039920937],
              [-3.038956170619668, 51.164139028665012],
              [-3.042379972487566, 51.165595887003136],
              [-3.04379211427359, 51.166885376164288],
              [-3.046035131451214, 51.169935304521843],
              [-3.054520536251068, 51.175725673057556],
              [-3.055943994044328, 51.178170422278427],
              [-3.055542475475923, 51.181636075927123],
              [-3.053546439345447, 51.185016285199112],
              [-3.051031685709251, 51.187119721433483],
              [-3.044887033050055, 51.188240358303979],
              [-3.034621175938289, 51.188925103976338],
              [-3.031760289078535, 51.189802840485051],
              [-3.027075276007525, 51.192698212611987],
              [-3.022050040400278, 51.197706845424783],
              [-3.021384313224808, 51.198991356089309],
              [-3.021228116778629, 51.200730019247892],
              [-3.02050581169696, 51.19881018656686],
              [-3.02024567090833, 51.198955432282197],
              [-3.021073271476681, 51.201300579198069],
              [-3.020675498469149, 51.201824700708009],
              [-3.020880958037273, 51.202312984338221],
              [-3.0180059489722, 51.207208227697294],
              [-3.017670630932843, 51.207387392704177],
              [-3.016646765888347, 51.206592390645582],
              [-3.015480799965069, 51.20642896788106],
              [-3.016293891999105, 51.204478687818984],
              [-3.017434331896457, 51.203745799643656],
              [-3.017197076260601, 51.203567119206596],
              [-3.018282736940284, 51.203272620287798],
              [-3.017542811367662, 51.203277260376503],
              [-3.016391325450929, 51.204092974141176],
              [-3.016201076938217, 51.202928335440589],
              [-3.016025956467372, 51.204401883686415],
              [-3.015076221405272, 51.206453161268762],
              [-3.014011830231574, 51.206221400822137],
              [-3.013756997305266, 51.206480786909047],
              [-3.015388476260533, 51.207047534731821],
              [-3.015925537896202, 51.207709195330388],
              [-3.015767180383777, 51.208709605787732],
              [-3.015410906095416, 51.208759719033843],
              [-3.015734219945632, 51.20909835571522],
              [-3.0156263679602, 51.209726948732843],
              [-3.015015888359989, 51.209763719840012],
              [-3.015685017524954, 51.210050159274644],
              [-3.013286673276331, 51.2129655500109],
              [-3.010432329279454, 51.217628416342599],
              [-3.007089118734549, 51.220586000994444],
              [-3.003721813775839, 51.221270918340338],
              [-3.0020523623818, 51.222796366060727],
              [-3.003534960872869, 51.224398655408606],
              [-3.002322772276503, 51.226015050439642],
              [-2.999364481794514, 51.225901876572713],
              [-3.000080733359939, 51.223269139709302],
              [-2.998249996954717, 51.2221148909248],
              [-2.99683805587078, 51.22204779822961],
              [-2.995836205146398, 51.222868324137011],
              [-2.994861179543005, 51.222942262022229],
              [-2.992407932517702, 51.221732966328553],
              [-2.989976674518044, 51.221475705449123],
              [-2.987957813861324, 51.220414608219983],
              [-2.984797427638003, 51.220206557219761],
              [-2.984300952101238, 51.220426547542502],
              [-2.985542454045303, 51.220897177678275],
              [-2.983978413004607, 51.22128261851779],
              [-2.985086669745186, 51.221413570447972],
              [-2.986406349444839, 51.220917773021384],
              [-2.987450390115338, 51.221059135361166],
              [-2.987748566000366, 51.221409111871886],
              [-2.990091093049732, 51.222136561806934],
              [-2.992068321422423, 51.222254695476586],
              [-2.993842163130402, 51.223433802698473],
              [-2.995809418504877, 51.223617600969547],
              [-2.998062503784039, 51.222648827900521],
              [-2.999166042603953, 51.223550317172197],
              [-2.998676251830137, 51.224342214239265],
              [-2.997973933534039, 51.224578406819987],
              [-2.9977663599384, 51.225442527384324],
              [-2.998387745826629, 51.226230336937512],
              [-2.999995048388951, 51.226781319451653],
              [-3.002332402109245, 51.226919581445024],
              [-3.004183990899583, 51.225531497352989],
              [-3.003426207140572, 51.227430853196623],
              [-3.002475473665259, 51.227572087235345],
              [-3.002658853602378, 51.228036310820457],
              [-3.00185205347267, 51.230057469774472],
              [-3.00021833100692, 51.232917469949413],
              [-3.001551531509973, 51.233959948114155],
              [-3.000951821469113, 51.234178194330759],
              [-3.000149150410421, 51.233888316479593],
              [-2.99933381049287, 51.236913054093478],
              [-2.99934557889668, 51.238910112989281],
              [-3.000197580063095, 51.242064476702005],
              [-3.001814586254067, 51.244881374800379],
              [-3.005664620262396, 51.248184439616061],
              [-3.01158190030164, 51.252292307833393],
              [-3.013379829059147, 51.254165103593358],
              [-3.015604297453889, 51.257633873219554],
              [-3.018426869476693, 51.260330366835774],
              [-3.021485127056952, 51.264914882590304],
              [-3.021888892590697, 51.269030653953152],
              [-3.018874008155764, 51.278538230589163],
              [-3.011644231742169, 51.306940405022068],
              [-3.011025936304455, 51.317448470541983],
              [-3.01122917590956, 51.320295396443811],
              [-3.012201910266458, 51.322240962916645],
              [-3.013802079168243, 51.32354445351794],
              [-3.017583327776698, 51.3232059238697],
              [-3.018823648930494, 51.323481984485426],
              [-3.024461047298467, 51.323555988020317],
              [-3.028133158354243, 51.324180221853922],
              [-3.033674450929392, 51.326294023583571],
              [-3.034974709949157, 51.3270567536104],
              [-3.035231168177115, 51.328120044510229],
              [-3.035922574983436, 51.328548245781661],
              [-3.034986619123754, 51.328796609968663],
              [-3.032259345994764, 51.328084235376537],
              [-3.02558553631705, 51.327707663670196],
              [-3.025364824760489, 51.327400270260625],
              [-3.022175868415357, 51.326439926124387],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000189",
        LAD13CDO: "40UD",
        LAD13NM: "South Somerset",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.405480835692923, 51.146869749293138],
            [-2.403585238506615, 51.145237956417901],
            [-2.405264905998062, 51.142458093942139],
            [-2.402960106910348, 51.141580343849974],
            [-2.401291195068003, 51.140339785088401],
            [-2.395329969748941, 51.14260811488225],
            [-2.393385300575053, 51.141866536333701],
            [-2.39045677938028, 51.144117177018259],
            [-2.387221193434162, 51.143705313830104],
            [-2.384633463070649, 51.142978307458804],
            [-2.3818168436163, 51.143259102280574],
            [-2.381330343534321, 51.143548436294672],
            [-2.376560526248137, 51.142722243719398],
            [-2.37502807376966, 51.143772946842098],
            [-2.37098635421798, 51.14335332718975],
            [-2.369441521605224, 51.142693707399573],
            [-2.368290066701058, 51.140627369730673],
            [-2.366352367793259, 51.139798097117129],
            [-2.36409845234138, 51.137828685309742],
            [-2.364462954076861, 51.137650405607019],
            [-2.3598705265127, 51.136359888018958],
            [-2.358380771148043, 51.134825923474345],
            [-2.354669820923022, 51.133060411686841],
            [-2.351613366666696, 51.132944657539774],
            [-2.349759566936424, 51.132544677975936],
            [-2.348198301705417, 51.131892019921878],
            [-2.346307259975607, 51.130516457121018],
            [-2.3440343237982, 51.128695981658737],
            [-2.343189070635844, 51.127446770410607],
            [-2.344541769828549, 51.124684021162331],
            [-2.346532118099664, 51.123712386702742],
            [-2.347462247853637, 51.123702431164482],
            [-2.349311625856971, 51.122998233033563],
            [-2.350215334377811, 51.122897514526997],
            [-2.350812248702975, 51.123228431158374],
            [-2.352540904443161, 51.122816786872114],
            [-2.356788354570158, 51.120336467892727],
            [-2.357330016678369, 51.120356393875156],
            [-2.357657886144906, 51.120813987313852],
            [-2.359517866456515, 51.120589772197356],
            [-2.361145220801791, 51.120218776358975],
            [-2.361658849953379, 51.119759490578687],
            [-2.363708300815675, 51.119472576755257],
            [-2.364529491254788, 51.118884632933323],
            [-2.365591762551966, 51.114995830436179],
            [-2.362884974327165, 51.11578118456454],
            [-2.363453367973014, 51.113389318528135],
            [-2.362616425261681, 51.112502599983991],
            [-2.360779385216043, 51.108844004153546],
            [-2.362174165954654, 51.106916277529535],
            [-2.362293255019468, 51.105341389799683],
            [-2.363453526096438, 51.104309988045571],
            [-2.362836821795484, 51.103438770413419],
            [-2.362531295438656, 51.101629605784737],
            [-2.360871411011686, 51.09902343272293],
            [-2.360113889787465, 51.099311719364103],
            [-2.35440948460813, 51.096454380375206],
            [-2.353510977622283, 51.094548074261731],
            [-2.3470535103995, 51.088242370103409],
            [-2.3453264559123, 51.088319424868061],
            [-2.341616302277362, 51.087831269243637],
            [-2.340672864051121, 51.087486927782443],
            [-2.340418623684306, 51.086890591106147],
            [-2.339401996186903, 51.08707608939882],
            [-2.33902052426257, 51.086454941245009],
            [-2.337124042020625, 51.086785944692664],
            [-2.335708268603327, 51.086665030990268],
            [-2.330494527735423, 51.085316689392499],
            [-2.329143552990854, 51.085264752335981],
            [-2.328809000492584, 51.083588660851213],
            [-2.32753388934803, 51.082547356359065],
            [-2.327382235247255, 51.080075841670499],
            [-2.327133117869065, 51.079770806675555],
            [-2.326362630620318, 51.080027439503233],
            [-2.325856279144683, 51.079681756317086],
            [-2.326536101026063, 51.078916424875956],
            [-2.328475455601357, 51.078022563561198],
            [-2.329960738875679, 51.07671811292029],
            [-2.334375332363873, 51.075267714730387],
            [-2.337746322048905, 51.074609682467248],
            [-2.341727359724205, 51.075009944882922],
            [-2.343179174668934, 51.074839333905395],
            [-2.347007496616322, 51.072753533712515],
            [-2.347641267821396, 51.071807472548521],
            [-2.349118982879271, 51.070732998251088],
            [-2.3499807795228, 51.068806995529471],
            [-2.3494175085439, 51.066596608679276],
            [-2.349460246823431, 51.064124533082612],
            [-2.349941972815454, 51.06366449004787],
            [-2.349524642158254, 51.063012008541392],
            [-2.349550246481483, 51.061675694651299],
            [-2.346511960076315, 51.05858514368316],
            [-2.344489907431675, 51.055488821956189],
            [-2.342198525368157, 51.056073755232923],
            [-2.340112505462306, 51.056069057672836],
            [-2.333173861171554, 51.0514850579628],
            [-2.332542112388585, 51.049531057046877],
            [-2.333318135670606, 51.046743962316356],
            [-2.331452498679935, 51.044381626370658],
            [-2.330371401583421, 51.043801095905465],
            [-2.329633965682976, 51.042198071760396],
            [-2.330080860341101, 51.042059228784389],
            [-2.33015316241837, 51.041194873633309],
            [-2.332092816843227, 51.039774899985495],
            [-2.335423516793414, 51.039617009521194],
            [-2.33608195654186, 51.039940634397283],
            [-2.336549918390756, 51.038188503680189],
            [-2.337732185801987, 51.036999017861476],
            [-2.33892534588657, 51.036919127305495],
            [-2.341540471226718, 51.035487141583509],
            [-2.342759354617574, 51.035227292267862],
            [-2.343414455393843, 51.033365777072362],
            [-2.344258181752687, 51.032525217969258],
            [-2.347296162384778, 51.031820224647092],
            [-2.347531494760044, 51.031253014512686],
            [-2.349772984997103, 51.030084526927183],
            [-2.353473519628579, 51.027843311947059],
            [-2.356774056906154, 51.02684952476352],
            [-2.360678063495328, 51.025181162565225],
            [-2.360463302597878, 51.02341395202069],
            [-2.361568113038942, 51.023023870167158],
            [-2.363482034710491, 51.019992039194676],
            [-2.367252661996485, 51.01778523400445],
            [-2.369101230414973, 51.015931498176784],
            [-2.369150851037892, 51.014282162238196],
            [-2.371165723547567, 51.013466472100454],
            [-2.37805091898361, 51.011720567552352],
            [-2.378161503210018, 51.011121324955432],
            [-2.375917165614541, 51.010856106441949],
            [-2.37626681595032, 51.009676094794251],
            [-2.375659279671452, 51.009283290635331],
            [-2.376476836637071, 51.009208719398892],
            [-2.377142746636451, 51.008363096108312],
            [-2.377850704758199, 51.008134202219573],
            [-2.377679897620321, 51.007285884960453],
            [-2.378543456819927, 51.007264204761157],
            [-2.379552306936641, 51.006533455327599],
            [-2.379644044787633, 51.006248102190376],
            [-2.379022976715345, 51.005941685084068],
            [-2.37969126215851, 51.005223729213739],
            [-2.381352101191229, 51.004928765516127],
            [-2.381759504936188, 51.001952790280711],
            [-2.38091385493937, 51.001191208740956],
            [-2.381502366238784, 51.000315237960514],
            [-2.381007649101161, 50.999941875391691],
            [-2.380949875181517, 50.996717432034522],
            [-2.378880709067699, 50.994782730860869],
            [-2.379388574581515, 50.994331467341048],
            [-2.378516586928851, 50.994163444182661],
            [-2.378240010506809, 50.993444957561202],
            [-2.377460582268566, 50.993618333541875],
            [-2.377844780501128, 50.992677396681742],
            [-2.377231328991868, 50.992407813287635],
            [-2.377034108881878, 50.991807765420681],
            [-2.377763319745839, 50.99141963895319],
            [-2.376915373230605, 50.991221851475217],
            [-2.376821670297738, 50.990735670996912],
            [-2.376325825374782, 50.990909922344102],
            [-2.374635256344499, 50.990463047221958],
            [-2.374059995654191, 50.990684303680517],
            [-2.373571833319065, 50.990397214184192],
            [-2.372932060624991, 50.990747261254391],
            [-2.372300213115508, 50.990486702990559],
            [-2.371623157064488, 50.991343127686406],
            [-2.371024174742122, 50.991273094469179],
            [-2.37061056985269, 50.99175819332293],
            [-2.369799305651152, 50.991511679422914],
            [-2.368687096790588, 50.991764283448113],
            [-2.367546051875715, 50.991254421605198],
            [-2.366001567037185, 50.991437321092619],
            [-2.364877993977212, 50.990425608692554],
            [-2.361239959484272, 50.991272305957125],
            [-2.354953705588672, 50.990903121285591],
            [-2.354845989504862, 50.990230825652958],
            [-2.352695160426622, 50.98993340406993],
            [-2.351945093836752, 50.988719910297597],
            [-2.352470626070473, 50.988506106729552],
            [-2.352295506055133, 50.988146044491302],
            [-2.351487135131021, 50.987884107820932],
            [-2.350389064636525, 50.988123906016519],
            [-2.346986907315941, 50.985269123720727],
            [-2.345854665775472, 50.984740139879626],
            [-2.344024813806439, 50.984845361319707],
            [-2.34385617123767, 50.984387251185048],
            [-2.343909011918706, 50.983826875541503],
            [-2.344634554721792, 50.983500113907404],
            [-2.343879217490786, 50.982301869795336],
            [-2.344770196835757, 50.98181185913446],
            [-2.344487006974686, 50.981274056468528],
            [-2.344934400716904, 50.980326745319154],
            [-2.344138216207731, 50.979762580281601],
            [-2.344560116616424, 50.978836027765183],
            [-2.345919177414058, 50.979047823531715],
            [-2.351186143413616, 50.978588776143937],
            [-2.353210655289225, 50.977891159569623],
            [-2.358265125242553, 50.97742434241993],
            [-2.362560132481605, 50.976570300670168],
            [-2.365568591444444, 50.97550791826044],
            [-2.374785639772596, 50.973232409232708],
            [-2.38107606993035, 50.972818180015842],
            [-2.383544842401965, 50.972365873987336],
            [-2.392740471038412, 50.969248233104686],
            [-2.397039912197348, 50.968408192906864],
            [-2.403141356710372, 50.965180614501762],
            [-2.41492976310333, 50.960590081011837],
            [-2.41621515655275, 50.96166368713277],
            [-2.418348504810868, 50.962334083798062],
            [-2.41944399989347, 50.963652926360524],
            [-2.419598698762351, 50.965175673093341],
            [-2.421345519800261, 50.967171075887727],
            [-2.424951161847134, 50.970276548369768],
            [-2.427993498714874, 50.97139307280959],
            [-2.431140127029479, 50.970683689171565],
            [-2.431768615632381, 50.971343202207159],
            [-2.433896017034734, 50.970234652783311],
            [-2.436663325890979, 50.969338589364817],
            [-2.441362665319316, 50.968401000732399],
            [-2.441454735418615, 50.968648841209891],
            [-2.447179230250728, 50.967856409990603],
            [-2.444850921100412, 50.963992302193823],
            [-2.444758069180084, 50.963067343247673],
            [-2.44287754920651, 50.962972879227415],
            [-2.442272425750631, 50.962529152850244],
            [-2.444337424036921, 50.961067247695524],
            [-2.443667126859036, 50.961254140417928],
            [-2.442377051075643, 50.960802224913124],
            [-2.44347969706692, 50.958469021164426],
            [-2.444756069999005, 50.958088284550229],
            [-2.44573564578747, 50.955857140714471],
            [-2.448606067653238, 50.952919130097513],
            [-2.45248691196548, 50.950280177392393],
            [-2.455365484007112, 50.949273527475732],
            [-2.457408500817373, 50.949588365707591],
            [-2.457994456899572, 50.949967344735448],
            [-2.458664268374006, 50.949753393923601],
            [-2.460421772752587, 50.951134897236919],
            [-2.457906938822089, 50.95279399040367],
            [-2.459030316363494, 50.953528750919347],
            [-2.458644927453577, 50.954294617040375],
            [-2.461446842084468, 50.956315849216637],
            [-2.463809213458681, 50.955352396784583],
            [-2.466818154093672, 50.959816801523125],
            [-2.470242990382341, 50.959157404111316],
            [-2.47302659556888, 50.958036500143066],
            [-2.474742897513694, 50.957957593953104],
            [-2.47545295903402, 50.960008560099652],
            [-2.476416161311608, 50.960769883203213],
            [-2.474578612781286, 50.962513792580808],
            [-2.475460811241052, 50.962868997981367],
            [-2.475921017561949, 50.96359190678254],
            [-2.480195085611617, 50.965871052262763],
            [-2.485087560909361, 50.971149119733205],
            [-2.48778001255867, 50.973118915758988],
            [-2.490345795922981, 50.972778148705068],
            [-2.490484370356377, 50.9741695817847],
            [-2.488218489388746, 50.974457841624584],
            [-2.488333301749764, 50.975762150418205],
            [-2.492141289064082, 50.978522995857062],
            [-2.497175621941887, 50.987358216133799],
            [-2.502231282507337, 50.993767889474057],
            [-2.508533430008893, 50.994664125059167],
            [-2.510859216160163, 50.994432762219731],
            [-2.512810745169013, 50.993989879206538],
            [-2.51628468314778, 50.994505118504193],
            [-2.518139778494544, 50.993340489205451],
            [-2.518461069294371, 50.993785981591536],
            [-2.517297061685284, 50.99443859108959],
            [-2.518051607728076, 50.994507184624936],
            [-2.520100841184968, 50.994015187700455],
            [-2.523515055107427, 50.992255419921783],
            [-2.526529216104946, 50.991930733266656],
            [-2.52686189146819, 50.992117172394771],
            [-2.52646558081156, 50.992602747047648],
            [-2.529361408178953, 50.992647206240576],
            [-2.535559795620946, 50.99402804067936],
            [-2.535853206825665, 50.99686197484953],
            [-2.535165396467512, 50.999378482729959],
            [-2.547432970682685, 50.999547327004343],
            [-2.546920087701659, 50.997257586674749],
            [-2.547289329623407, 50.99642406654948],
            [-2.54853394127641, 50.995991990560853],
            [-2.551487874341288, 50.996350360206783],
            [-2.551922431047319, 50.993703662631113],
            [-2.547373739069498, 50.992215260162816],
            [-2.545617278678231, 50.992126363124541],
            [-2.54472285060627, 50.991671929853609],
            [-2.545426298965019, 50.989813527456256],
            [-2.545422161586569, 50.985086273310309],
            [-2.548090659893018, 50.98485796193031],
            [-2.550216601735096, 50.983807549145261],
            [-2.553332000780058, 50.982833343814406],
            [-2.555127294322114, 50.981429209453445],
            [-2.556205879451588, 50.979541077557222],
            [-2.560100219513613, 50.978700560297639],
            [-2.562370280250653, 50.977716675612953],
            [-2.569103127092, 50.985376116014081],
            [-2.574628573167776, 50.986699707079964],
            [-2.574348100391299, 50.985099549022394],
            [-2.574937330073514, 50.983324256165119],
            [-2.576873607094592, 50.98231205848618],
            [-2.578334697326661, 50.982157356342704],
            [-2.579357224267627, 50.981561489364488],
            [-2.580821893518881, 50.981353683349077],
            [-2.583179686011855, 50.978869930573396],
            [-2.584255481126059, 50.978896925104124],
            [-2.58672602152315, 50.977810848452741],
            [-2.586021979089832, 50.97687558072036],
            [-2.58929016277817, 50.976926575144788],
            [-2.591548426548874, 50.978767584432106],
            [-2.595843378960572, 50.981346339607967],
            [-2.603385550936535, 50.976351014135972],
            [-2.602693574257602, 50.976041653212555],
            [-2.60274776854747, 50.975607041655742],
            [-2.602066024876443, 50.974986487902946],
            [-2.601074421354919, 50.974634599301829],
            [-2.598975396157315, 50.974556361339722],
            [-2.59802057277246, 50.973517240445801],
            [-2.598949590764446, 50.972661799421928],
            [-2.598559007551211, 50.971528064584909],
            [-2.599581770538084, 50.970767456807707],
            [-2.599222412177923, 50.970067895669317],
            [-2.599650293907029, 50.969563923833668],
            [-2.599881710657025, 50.967408159076314],
            [-2.599081508059098, 50.96664431765128],
            [-2.597255812291475, 50.965753532940674],
            [-2.597354916442846, 50.964820514944648],
            [-2.5959967978132, 50.964628723825264],
            [-2.594908080824172, 50.962116406194419],
            [-2.59546464191236, 50.960876215129559],
            [-2.596724883615611, 50.961216887226207],
            [-2.597944989770473, 50.960759226364026],
            [-2.59837750593581, 50.959513361527883],
            [-2.598731354012535, 50.958573639934613],
            [-2.598148492251389, 50.957757419749242],
            [-2.599554171805184, 50.954359172969191],
            [-2.598556033835291, 50.953019929509132],
            [-2.599298868495116, 50.952663616038599],
            [-2.599354654648804, 50.952241585537067],
            [-2.598481686610256, 50.951887266828209],
            [-2.598658771923083, 50.950601342993451],
            [-2.597988323713855, 50.950162352387757],
            [-2.599203907935215, 50.949265870234726],
            [-2.598159742550243, 50.948988863178954],
            [-2.598200061966093, 50.948581300091284],
            [-2.599192139078581, 50.947686862076964],
            [-2.601683988432616, 50.947413265253701],
            [-2.601688314680487, 50.946866503778118],
            [-2.604472466605392, 50.945977157884833],
            [-2.604960131389304, 50.945384728703416],
            [-2.605574459292145, 50.945339279098761],
            [-2.60675038669168, 50.94589969581444],
            [-2.607174231662443, 50.945764404207779],
            [-2.607305436407741, 50.944036276674851],
            [-2.608143739561114, 50.944031013258162],
            [-2.608619935379117, 50.943655345754898],
            [-2.608611129490584, 50.942218401434317],
            [-2.611587859195472, 50.940200229080396],
            [-2.613926049034099, 50.940290469206289],
            [-2.614003989341522, 50.941344871851854],
            [-2.615732961967062, 50.941211669338671],
            [-2.616035078554976, 50.941455568850195],
            [-2.615858267120088, 50.939600463069667],
            [-2.614596742503578, 50.938157530824576],
            [-2.612784621325574, 50.937262418299468],
            [-2.610359428916743, 50.93671129836325],
            [-2.610520524818395, 50.936515319968045],
            [-2.607860537963404, 50.935633554928849],
            [-2.606267060804537, 50.93468144935575],
            [-2.604628649419023, 50.932564134129599],
            [-2.604031829145792, 50.930982755254831],
            [-2.604472072366419, 50.927732403696176],
            [-2.604039359541116, 50.926731985403116],
            [-2.605198789099719, 50.926607279132888],
            [-2.606593137661171, 50.925838380212546],
            [-2.60978044997655, 50.925308303052844],
            [-2.613408380677775, 50.92356004334718],
            [-2.614156212107094, 50.923627151215193],
            [-2.614804470755387, 50.923264039925073],
            [-2.615187736983839, 50.922442807142708],
            [-2.614629594813681, 50.922193958650489],
            [-2.614903518943621, 50.921928137428225],
            [-2.614530372917435, 50.9211423628049],
            [-2.615339702752556, 50.919943000019266],
            [-2.61519001129822, 50.919487871020692],
            [-2.616044710866232, 50.919249558724331],
            [-2.615303806348101, 50.917653706714141],
            [-2.616073304647032, 50.917537241574514],
            [-2.615625008494701, 50.916661042578532],
            [-2.616132637896251, 50.915998316380701],
            [-2.616993322253382, 50.915679932262094],
            [-2.616433877666019, 50.915111866273413],
            [-2.6170436353532, 50.914321802519495],
            [-2.616608743853828, 50.913382588630093],
            [-2.617335617170518, 50.913275332623009],
            [-2.61733500009714, 50.912368894040824],
            [-2.618871358836949, 50.911629668696904],
            [-2.619415043663528, 50.911761671653665],
            [-2.620023189391206, 50.911499458966958],
            [-2.619995067241108, 50.910560793212341],
            [-2.620433178890317, 50.910027908452442],
            [-2.622097077159709, 50.910116169922901],
            [-2.622399039427542, 50.908762090128214],
            [-2.623871750755243, 50.908170614393704],
            [-2.624065588569149, 50.90777570704995],
            [-2.622353889920145, 50.906123934743576],
            [-2.622212518710844, 50.905441259229534],
            [-2.619976757079982, 50.904687896887367],
            [-2.619326900149021, 50.904051081951025],
            [-2.618946695392057, 50.902620594787166],
            [-2.619661232658216, 50.90234702805617],
            [-2.618696876476892, 50.901369264832105],
            [-2.617631467842016, 50.901563750761177],
            [-2.617519500239755, 50.900087775271487],
            [-2.616999794837671, 50.899607627006105],
            [-2.616203476693092, 50.899501226797796],
            [-2.616209281242449, 50.898540797212057],
            [-2.615634354906312, 50.898076221624166],
            [-2.615527123973213, 50.897277353912521],
            [-2.616380507218657, 50.896754881384076],
            [-2.615479754997128, 50.896381051255773],
            [-2.615704787461659, 50.895869989206211],
            [-2.614988015918022, 50.894457449305726],
            [-2.615403728608388, 50.894281702346873],
            [-2.614533873733135, 50.893766519558255],
            [-2.614844634708839, 50.893287380462965],
            [-2.612558221070977, 50.892196024295487],
            [-2.610299781112476, 50.892366125980537],
            [-2.609757876791969, 50.891915736503478],
            [-2.60944128509936, 50.890862569110546],
            [-2.610363433537977, 50.890166225287501],
            [-2.610151275265651, 50.888945252461752],
            [-2.611609428905951, 50.886518632270388],
            [-2.611226298705303, 50.886138457826803],
            [-2.611758315287152, 50.885621297774648],
            [-2.611237324418804, 50.885026025557572],
            [-2.612317595300484, 50.884799145751572],
            [-2.612910813710344, 50.885047821827897],
            [-2.613097776343401, 50.884454232542886],
            [-2.614503948586319, 50.883716642862581],
            [-2.614949120920798, 50.883841991387371],
            [-2.615177806534715, 50.882533273058336],
            [-2.61609997409191, 50.881641744574573],
            [-2.617894015842759, 50.881007277384882],
            [-2.620520118648352, 50.881271210382927],
            [-2.620935941635272, 50.880900304622294],
            [-2.622185070592064, 50.880768654432352],
            [-2.622661352745127, 50.880242748538102],
            [-2.623005082501457, 50.880329939805868],
            [-2.623327666228701, 50.879578239885092],
            [-2.624128033411571, 50.87926731612874],
            [-2.627179804820642, 50.879902910953014],
            [-2.627959672929205, 50.886313985837887],
            [-2.63078616492555, 50.885810446648613],
            [-2.637598650978234, 50.886965830023783],
            [-2.640766328263987, 50.886984448653457],
            [-2.64251118033667, 50.886710473920935],
            [-2.643023240829934, 50.887246305676818],
            [-2.647103186629322, 50.88836040707023],
            [-2.646989228821742, 50.887831379125373],
            [-2.646151368276681, 50.887384596768285],
            [-2.647358054857283, 50.88541483945172],
            [-2.646833181917359, 50.88416149342882],
            [-2.649575106670624, 50.884142664835089],
            [-2.650417948938629, 50.883316052156609],
            [-2.658425009196611, 50.886611872710219],
            [-2.660610042737173, 50.88711299682204],
            [-2.661411345077824, 50.886374666918186],
            [-2.664456890913955, 50.885105603509807],
            [-2.666315878317465, 50.883579760179487],
            [-2.668859933555908, 50.882452827699915],
            [-2.671791290359626, 50.880589818329959],
            [-2.678964327709126, 50.877488134951882],
            [-2.680563846590199, 50.877131703920377],
            [-2.684627113190205, 50.877009914888625],
            [-2.695725542380253, 50.875999149677718],
            [-2.696502475184649, 50.875487333957828],
            [-2.695683290617909, 50.874689184930013],
            [-2.697133513799348, 50.873486316411316],
            [-2.69957676080372, 50.87283142215076],
            [-2.7029019976811, 50.873276353604325],
            [-2.704202491507623, 50.87252662480784],
            [-2.70714394277825, 50.869774192868526],
            [-2.711209986849018, 50.870067816407335],
            [-2.711759934144895, 50.870905268988828],
            [-2.714388585028249, 50.871151787477167],
            [-2.713807651426172, 50.866336218306166],
            [-2.714419739782681, 50.865475478512685],
            [-2.714554840558435, 50.863902746749964],
            [-2.715394513611429, 50.863895802694358],
            [-2.717995541769611, 50.864033598487119],
            [-2.721384276171607, 50.864938932248748],
            [-2.723090477316577, 50.866199023524679],
            [-2.724030960427303, 50.866092474120158],
            [-2.724988141789743, 50.865413884586047],
            [-2.729910871522515, 50.864712344601784],
            [-2.733144712236316, 50.864965443884728],
            [-2.737065138796542, 50.864241119281424],
            [-2.738238689111743, 50.864680631845928],
            [-2.75045223610452, 50.863814062222467],
            [-2.753828289454446, 50.864335451877174],
            [-2.754823197930623, 50.865493561866003],
            [-2.754987610061089, 50.866786533610707],
            [-2.756961500990434, 50.86661547456066],
            [-2.757497750545649, 50.866910548726807],
            [-2.759915588940233, 50.865989271225928],
            [-2.760955675503616, 50.863191187145674],
            [-2.760967492225853, 50.86129906471934],
            [-2.761686972161623, 50.86080786950177],
            [-2.767395713418232, 50.86070571385244],
            [-2.771698068949412, 50.860276265609038],
            [-2.775411928022888, 50.860691381221883],
            [-2.780060556511004, 50.860326767313474],
            [-2.782285131592618, 50.859704870714786],
            [-2.784079989625824, 50.856846655952893],
            [-2.786198976291826, 50.854521289634022],
            [-2.787537181886692, 50.853758681271593],
            [-2.788976787925973, 50.85345129835563],
            [-2.792431393318388, 50.85284246415052],
            [-2.79371952446622, 50.853406538783808],
            [-2.795073425473168, 50.854661684865519],
            [-2.799052118945585, 50.85545732928729],
            [-2.800415235873458, 50.856160202398108],
            [-2.80114153746948, 50.856178598172718],
            [-2.801686320553809, 50.856796247247551],
            [-2.802903163164311, 50.857250101035483],
            [-2.80784095586119, 50.858687211185135],
            [-2.807051174136647, 50.858944470814158],
            [-2.805605101499422, 50.861517365255452],
            [-2.805588972893059, 50.861900562707035],
            [-2.806498233347945, 50.862058846247201],
            [-2.807808390583881, 50.862886097285482],
            [-2.80731896351528, 50.863260880449033],
            [-2.808067225319903, 50.863638786593611],
            [-2.810150374103018, 50.862575799874683],
            [-2.811423919973059, 50.863417652854316],
            [-2.812935886071622, 50.862115785992941],
            [-2.81237365129525, 50.861242020922688],
            [-2.813899356494125, 50.859102830588917],
            [-2.817728643506397, 50.8556228798479],
            [-2.820475218833444, 50.853943554045848],
            [-2.822164605648331, 50.853472136641372],
            [-2.823157342868918, 50.852487635221244],
            [-2.824418524639748, 50.852066866428487],
            [-2.826319080066061, 50.850689234809884],
            [-2.827155251062861, 50.850659029196372],
            [-2.827425666244901, 50.851249727768284],
            [-2.828119934858493, 50.851227715716405],
            [-2.82849052487462, 50.851939101560809],
            [-2.828524241091086, 50.851597141171474],
            [-2.828956647467656, 50.851708276310703],
            [-2.829374671629581, 50.850608201073676],
            [-2.828580382706264, 50.848753263645371],
            [-2.829639571225717, 50.848564983667458],
            [-2.830998664533005, 50.849906008506451],
            [-2.831588663089386, 50.849773209428292],
            [-2.831442913418774, 50.84940554912955],
            [-2.837156081427672, 50.848763083438826],
            [-2.838845371005144, 50.848068405839754],
            [-2.842843917605655, 50.84735434516147],
            [-2.843157995809534, 50.848781010760888],
            [-2.843766494625155, 50.849203763145212],
            [-2.843489372664574, 50.849590653232866],
            [-2.843978388833504, 50.850239984209772],
            [-2.844735330967596, 50.850149974836427],
            [-2.844923826285944, 50.851147695040936],
            [-2.845392546384128, 50.851222535709553],
            [-2.845193434138243, 50.851602569548888],
            [-2.846342155973091, 50.851337950996928],
            [-2.846593347181633, 50.850774986125039],
            [-2.847306223437106, 50.850839953721156],
            [-2.847609450640569, 50.851345836750035],
            [-2.848553290203451, 50.851221171782527],
            [-2.8488182823189, 50.85063741852796],
            [-2.848996546850888, 50.850910397768857],
            [-2.850688646373597, 50.850765879619239],
            [-2.85257401951062, 50.849379835387609],
            [-2.853670246731761, 50.84966857730214],
            [-2.85402473750332, 50.849322462790198],
            [-2.854415171194816, 50.849388847613596],
            [-2.854971705827427, 50.849605090887543],
            [-2.855353504410532, 50.850363971589211],
            [-2.856058960866698, 50.850023370631739],
            [-2.857996665269874, 50.850415605021468],
            [-2.85831007153067, 50.850076973749871],
            [-2.860323284059281, 50.849785170791115],
            [-2.861924739344619, 50.848965807926632],
            [-2.862925672568028, 50.849091500310386],
            [-2.863667733453267, 50.848583318533429],
            [-2.864148634632514, 50.848855832642244],
            [-2.864421735508577, 50.848491404503328],
            [-2.86508884937459, 50.848770628276966],
            [-2.865765483025192, 50.848489533716524],
            [-2.866016387591273, 50.849074893410744],
            [-2.866940663694848, 50.849355794463314],
            [-2.867503190422861, 50.848896583408013],
            [-2.867935559292554, 50.849155054541264],
            [-2.869637874538814, 50.848878892133705],
            [-2.869219918133693, 50.848479134812123],
            [-2.869739404896094, 50.848151526644536],
            [-2.871166124763188, 50.848257786500035],
            [-2.871456424019683, 50.849047873638689],
            [-2.872533961912457, 50.848938202442689],
            [-2.87260075256559, 50.849396329647249],
            [-2.873194637302037, 50.849478215903389],
            [-2.873949635270049, 50.848907822171718],
            [-2.876256030417132, 50.849260121206733],
            [-2.876785612627005, 50.84909427408995],
            [-2.877021609046901, 50.849331705622603],
            [-2.877426576952307, 50.848656907118432],
            [-2.878379605367257, 50.848952789051694],
            [-2.878933054641618, 50.84824359425432],
            [-2.878578345729868, 50.847811919307247],
            [-2.877990810581509, 50.847840622711153],
            [-2.877873057607295, 50.847470110897085],
            [-2.878839815654, 50.847212835517972],
            [-2.878945477099734, 50.84648632959415],
            [-2.879323291417867, 50.846485281045915],
            [-2.88065860161996, 50.846792650704096],
            [-2.880853957811382, 50.847963822374957],
            [-2.881990544325116, 50.848200735605232],
            [-2.882093692442121, 50.848623511316433],
            [-2.882862521911108, 50.848340720954702],
            [-2.883033790588146, 50.84883492181924],
            [-2.882600360852814, 50.849277044723372],
            [-2.88343632235518, 50.849539598810679],
            [-2.883417406484262, 50.849215106072243],
            [-2.883918633795539, 50.849654649028579],
            [-2.884883848969519, 50.849690496976855],
            [-2.884907761502197, 50.850278437274028],
            [-2.886240614130724, 50.850598351626694],
            [-2.886273708147276, 50.850170947635682],
            [-2.887117153227762, 50.850376764631321],
            [-2.890089547905399, 50.849662587301403],
            [-2.890310666508193, 50.849040404502325],
            [-2.892467176359711, 50.848380050830663],
            [-2.892455214498438, 50.847753351654511],
            [-2.893187732000399, 50.847581380931565],
            [-2.893531816043941, 50.846781094087419],
            [-2.894341569538593, 50.847306357619644],
            [-2.895006161943451, 50.846703246730563],
            [-2.895750237581887, 50.847210119712173],
            [-2.896500345958309, 50.847067668957507],
            [-2.897340606626026, 50.847552210184524],
            [-2.898510981226913, 50.846808498851608],
            [-2.899466799212981, 50.847318213608197],
            [-2.899684298349068, 50.846810247625058],
            [-2.901030829888886, 50.846586728732767],
            [-2.900648081029643, 50.845962893048188],
            [-2.901616572794388, 50.845950915836568],
            [-2.902547887618975, 50.84482232464439],
            [-2.901285251438875, 50.84460547002093],
            [-2.901718634316656, 50.844172269864799],
            [-2.902812440265098, 50.844105357737547],
            [-2.903076433869297, 50.843801159598826],
            [-2.906468074837479, 50.843720000021278],
            [-2.906742941351436, 50.844270915375553],
            [-2.908454528499778, 50.844127205399914],
            [-2.908932840458585, 50.844620776426474],
            [-2.910218971696555, 50.844802293521688],
            [-2.91055680069996, 50.843988515031789],
            [-2.911110761327858, 50.843916744672995],
            [-2.91104428611197, 50.844441538685267],
            [-2.91212302186034, 50.844112069867109],
            [-2.913256702788067, 50.842451239739283],
            [-2.913882840170018, 50.842440041536058],
            [-2.91425235824241, 50.842016287308027],
            [-2.916493254241631, 50.841781979673186],
            [-2.917300435911502, 50.841805311513376],
            [-2.916998624630867, 50.841624232902319],
            [-2.917365222189432, 50.841560200234511],
            [-2.917701072199017, 50.841811153317522],
            [-2.919238224229153, 50.841831424987674],
            [-2.919086090595012, 50.841534065488176],
            [-2.920119435270133, 50.841502538777981],
            [-2.92074265895898, 50.840622630195497],
            [-2.921669279895894, 50.840221431959577],
            [-2.922062336165982, 50.840419762834287],
            [-2.922343969972234, 50.840081208766748],
            [-2.92496510243075, 50.839977722626287],
            [-2.925200537504995, 50.839458774157357],
            [-2.926298435318797, 50.839892503619637],
            [-2.927291947826285, 50.839716446705125],
            [-2.927523137449529, 50.840059030074066],
            [-2.928617272914575, 50.839872269806428],
            [-2.927872423670337, 50.840770274591378],
            [-2.929023447207923, 50.840443670892178],
            [-2.930066389754515, 50.840967729835754],
            [-2.93026398083242, 50.839119948158093],
            [-2.930665243204956, 50.838730060466609],
            [-2.931794768538224, 50.83896744460165],
            [-2.93283123383249, 50.83881078129275],
            [-2.932485314992482, 50.838200242859266],
            [-2.934549359050824, 50.837657651187754],
            [-2.934376790761588, 50.836483683653057],
            [-2.938020820786207, 50.835137003497721],
            [-2.939710959400078, 50.83642105247938],
            [-2.941677392865921, 50.835905199303461],
            [-2.942078439906248, 50.835017075917357],
            [-2.943014160243109, 50.8347999846523],
            [-2.944255127453132, 50.833243194934084],
            [-2.943818725885412, 50.832657702032961],
            [-2.944436296646613, 50.832299288922094],
            [-2.944109590314211, 50.831942223822487],
            [-2.944638282059286, 50.83203506533237],
            [-2.944603515507256, 50.83136808526779],
            [-2.943868770280923, 50.830211270774299],
            [-2.944702603685917, 50.829664956073515],
            [-2.944093606296514, 50.82917978181522],
            [-2.944708130351689, 50.828743154673845],
            [-2.945382425941472, 50.828946323551591],
            [-2.945166345375358, 50.828511926266451],
            [-2.94602650280548, 50.828848476537289],
            [-2.946125575501145, 50.826164236108376],
            [-2.947317425184159, 50.826054743314685],
            [-2.947052875849078, 50.825330276946467],
            [-2.948033408164565, 50.825303427061499],
            [-2.947363481003298, 50.823700965125212],
            [-2.948212757473199, 50.823293886645146],
            [-2.949507285393391, 50.823700620125152],
            [-2.949434195970737, 50.822477300506669],
            [-2.951477016700814, 50.822889609540816],
            [-2.95296864093186, 50.822307291554594],
            [-2.953848757845583, 50.820839672866072],
            [-2.954315708072596, 50.821182074874599],
            [-2.956188056827173, 50.823326803113922],
            [-2.957651883989201, 50.824302199595778],
            [-2.95750769731458, 50.824818668502708],
            [-2.959049358481111, 50.826124342045702],
            [-2.962923375455839, 50.827747993481161],
            [-2.962596491771211, 50.828837016652599],
            [-2.961659470217776, 50.829809668019706],
            [-2.965603486785906, 50.831847219465985],
            [-2.966320141764282, 50.833682101727547],
            [-2.969821144977818, 50.834189915364206],
            [-2.968476405868751, 50.840503205106934],
            [-2.96924932814278, 50.8416829286992],
            [-2.970898107710469, 50.842769030808547],
            [-2.972258760212127, 50.84433952208888],
            [-2.972481761325062, 50.846047182946869],
            [-2.970881821515927, 50.847799703592337],
            [-2.970080785459667, 50.849516784594833],
            [-2.968016895501523, 50.851307293472409],
            [-2.970966672340045, 50.851406881050295],
            [-2.972108498584803, 50.851937835656607],
            [-2.974646008736544, 50.852361805123486],
            [-2.973820039348465, 50.853901962239448],
            [-2.973584021542439, 50.855666505969708],
            [-2.976603608648819, 50.855557637276917],
            [-2.979812718376768, 50.85479872330734],
            [-2.98412207850633, 50.85495309939575],
            [-2.987922374659004, 50.855891297827192],
            [-2.990643413244225, 50.855639810354717],
            [-2.993476395629843, 50.854907993415083],
            [-2.994164687427685, 50.855206984206269],
            [-2.996310692158427, 50.854641919926372],
            [-2.998503044859901, 50.850994606841986],
            [-3.00164801080596, 50.851781491770552],
            [-3.003722400373797, 50.853384156430899],
            [-3.006254944430031, 50.853890215410821],
            [-3.011434752720335, 50.857241985440034],
            [-3.012564414670134, 50.856986685169893],
            [-3.012900027940943, 50.855821010281936],
            [-3.014084029706009, 50.855125478075166],
            [-3.018420206794433, 50.855786445071359],
            [-3.021057913182521, 50.855499903504274],
            [-3.031854424524455, 50.851725081526482],
            [-3.03476499295505, 50.850943911214614],
            [-3.036799308353599, 50.851074231214277],
            [-3.036077497976444, 50.852862109924693],
            [-3.037081986868991, 50.854403537427707],
            [-3.035726568022322, 50.856259093164063],
            [-3.036358991351775, 50.858254365456936],
            [-3.034585089007842, 50.859055175873003],
            [-3.033301991352316, 50.861171748980979],
            [-3.032624805822474, 50.861648064896535],
            [-3.034719931995495, 50.862450541587364],
            [-3.034588170577897, 50.864008354265835],
            [-3.036644969566899, 50.865190630133526],
            [-3.036977417138143, 50.866788384281875],
            [-3.038435444838798, 50.86832396746216],
            [-3.039273945624902, 50.8687094848923],
            [-3.042018726725537, 50.868863961083314],
            [-3.043725775344323, 50.86811309781968],
            [-3.045319060149974, 50.869584436316437],
            [-3.046099435925226, 50.87187688660066],
            [-3.046753592833873, 50.871387210809871],
            [-3.051571402967773, 50.872773738358632],
            [-3.054054899812654, 50.87305168857695],
            [-3.053768521749688, 50.873713443072575],
            [-3.054600687820473, 50.874123188310271],
            [-3.054778353182447, 50.874626974506718],
            [-3.054294538723875, 50.874790518308842],
            [-3.054705617655141, 50.875245433179465],
            [-3.054547272678054, 50.875786428611868],
            [-3.053886279710884, 50.876035204167536],
            [-3.054246245627176, 50.876744177380097],
            [-3.053450640738797, 50.877768440973334],
            [-3.053662185193566, 50.878576776395668],
            [-3.052447077896889, 50.879027486385802],
            [-3.051933205900942, 50.880193980707467],
            [-3.051808864992485, 50.882688785074343],
            [-3.05328173026739, 50.883451572802599],
            [-3.052251497743506, 50.88485743706012],
            [-3.052208364801139, 50.885782277875727],
            [-3.053170735914672, 50.886598229333018],
            [-3.053728103032502, 50.888617459276183],
            [-3.05544939426179, 50.890359980983696],
            [-3.055499379899945, 50.891753399899031],
            [-3.054855510228776, 50.892014614540365],
            [-3.055314119866638, 50.893181319710223],
            [-3.055008823738259, 50.894583345893878],
            [-3.053793674930868, 50.895490897453641],
            [-3.054659377870897, 50.896046021020034],
            [-3.054658548134599, 50.896575699002462],
            [-3.052966169877173, 50.897699780891763],
            [-3.053263386678578, 50.898645829258641],
            [-3.052266643891386, 50.900037897781587],
            [-3.052325654544586, 50.900826025530641],
            [-3.051775654733074, 50.900904723421498],
            [-3.051346775000481, 50.901878000449585],
            [-3.050166815701228, 50.902970444883614],
            [-3.049891813672327, 50.903958518263039],
            [-3.050515715474168, 50.904570702381889],
            [-3.050374601895669, 50.906009903827702],
            [-3.051296967872136, 50.906358609271564],
            [-3.052415358224968, 50.908272066628967],
            [-3.051735889467419, 50.908668473889954],
            [-3.052556443982946, 50.908851722063332],
            [-3.052794599247, 50.909446688600887],
            [-3.054120657700928, 50.90990593696462],
            [-3.055521409051268, 50.909893278043754],
            [-3.055724846432664, 50.910460675256921],
            [-3.057615085949382, 50.911082942946528],
            [-3.058449308271079, 50.91205378535409],
            [-3.05989238846, 50.912273600922731],
            [-3.060061586196189, 50.912771158503148],
            [-3.061789090023026, 50.913236565382881],
            [-3.061874972691441, 50.913010067663166],
            [-3.062353939857495, 50.913117218616712],
            [-3.063868374225399, 50.913781473719638],
            [-3.065786438135164, 50.913556244693609],
            [-3.066072957310564, 50.913271257724453],
            [-3.069079731359636, 50.913380445803732],
            [-3.069275228446774, 50.913841778610283],
            [-3.071226861735803, 50.914088268884761],
            [-3.071482647588587, 50.914448326567886],
            [-3.073165047706605, 50.914491323054818],
            [-3.077984751274937, 50.917640215736299],
            [-3.079100024295653, 50.918806154468783],
            [-3.08016371206347, 50.919339474625659],
            [-3.080841604626829, 50.919185719537616],
            [-3.0820567007742, 50.919682548951869],
            [-3.083811502019236, 50.921363189319685],
            [-3.084563749179938, 50.921652961116486],
            [-3.084799173468241, 50.923516755922989],
            [-3.088866176387009, 50.925495050084209],
            [-3.091745554188169, 50.928391677822539],
            [-3.092379789011717, 50.929650115551233],
            [-3.090550498281985, 50.93180747636805],
            [-3.088368724611041, 50.938013895510423],
            [-3.086209339555414, 50.939648189847141],
            [-3.080347223761349, 50.939849164663634],
            [-3.076601994922823, 50.938531266890003],
            [-3.068221125553301, 50.936882563159138],
            [-3.065037428292561, 50.934640106632258],
            [-3.058898774810606, 50.933043098892242],
            [-3.058577711243847, 50.932628751487115],
            [-3.056028622655262, 50.93298906302536],
            [-3.052402921727695, 50.932750226838245],
            [-3.048257231182599, 50.933370250219539],
            [-3.047158164476401, 50.932907100753567],
            [-3.045565130555308, 50.933831435338888],
            [-3.042607554463507, 50.934642045480594],
            [-3.041618125344896, 50.934435054409775],
            [-3.039116779655934, 50.935415062092147],
            [-3.037068814423611, 50.935348742270719],
            [-3.034960204315901, 50.936076074799566],
            [-3.032812251819993, 50.936315416965854],
            [-3.032154565939828, 50.937464197369977],
            [-3.029309307645165, 50.939192522396198],
            [-3.030128470238325, 50.939807587073709],
            [-3.028767840656377, 50.941549766224568],
            [-3.021076594328163, 50.944494026327831],
            [-3.018033980420707, 50.944589840168355],
            [-3.017224095050996, 50.944917935711295],
            [-3.014937220127416, 50.944945032632418],
            [-3.013668516406062, 50.946103511983956],
            [-3.012062590699853, 50.946550887828607],
            [-3.010934787032709, 50.947385280813094],
            [-3.010688217473562, 50.947765104984143],
            [-3.011785696698535, 50.950581075330547],
            [-3.008736695336939, 50.953688321783311],
            [-3.008877595732538, 50.955723926833549],
            [-3.007699527100181, 50.957077593211601],
            [-3.005260223971988, 50.958316216433722],
            [-3.002717827356572, 50.957087208939775],
            [-3.000799348503658, 50.957522723233339],
            [-3.000479906195727, 50.957896854592555],
            [-2.997044200935803, 50.958828199219234],
            [-2.996426222230718, 50.959359540520005],
            [-2.995753795027075, 50.959276250416181],
            [-2.991382018843688, 50.961260343910311],
            [-2.989519529939769, 50.96159627809164],
            [-2.986735728185749, 50.962940844802006],
            [-2.984732016083443, 50.963269802265216],
            [-2.984353936400887, 50.963639886879044],
            [-2.984591335200159, 50.964630664595752],
            [-2.980453719676132, 50.964999104257778],
            [-2.98016690893794, 50.967407022030848],
            [-2.980817462907229, 50.967461809570509],
            [-2.980929791200617, 50.96880525338829],
            [-2.982014793764592, 50.968795234517842],
            [-2.982779603847775, 50.972102556750052],
            [-2.980700488734538, 50.972455458535364],
            [-2.98019030673344, 50.972171081200848],
            [-2.97938510856111, 50.97268951335095],
            [-2.978694112372936, 50.972475888501322],
            [-2.978839996630329, 50.971556526299864],
            [-2.978153667193274, 50.971564075909185],
            [-2.977152985456145, 50.97179456988934],
            [-2.976492132721685, 50.972473639964846],
            [-2.975474714552686, 50.972586457025479],
            [-2.975558298555932, 50.97304078168883],
            [-2.975099469380958, 50.973166912599893],
            [-2.975724456856269, 50.974852290748572],
            [-2.975735429366398, 50.977549961897459],
            [-2.978039672502393, 50.984847013829686],
            [-2.983914151851322, 50.990266026419171],
            [-2.982668354280385, 50.991321446618272],
            [-2.980587601418364, 50.992110493879643],
            [-2.97949326565489, 50.993124141414945],
            [-2.980690606653652, 50.994360456716777],
            [-2.97960572058788, 50.995351544140846],
            [-2.979015474415854, 50.997164887983473],
            [-2.978113954942045, 50.998070793272205],
            [-2.971925747809122, 50.999610712657116],
            [-2.970860904840378, 50.999460401534655],
            [-2.968153752767872, 51.000034113461759],
            [-2.964789722669391, 50.999998103247158],
            [-2.961012813474721, 51.000602062970678],
            [-2.9601098393019, 51.000353202960916],
            [-2.960033320537385, 50.999753133057943],
            [-2.956699451044384, 51.000489097456644],
            [-2.957310477276364, 51.001994830056503],
            [-2.954513201378134, 51.002497922689649],
            [-2.952905028267888, 51.00240134559408],
            [-2.952491598292218, 51.0021169581116],
            [-2.949234175605961, 51.003330485266339],
            [-2.944304734459612, 51.003279618657771],
            [-2.946451778981243, 51.004222637955252],
            [-2.948413988544748, 51.006161687539503],
            [-2.950734792698384, 51.009596832155147],
            [-2.949749174707798, 51.00977390897377],
            [-2.950471196529894, 51.01127517517719],
            [-2.949730152378868, 51.011284799759508],
            [-2.947209787969178, 51.012396951297724],
            [-2.946009877311542, 51.013535230468911],
            [-2.9258383561147, 51.021752292857499],
            [-2.912402607759331, 51.02999885699343],
            [-2.886731595897916, 51.045708424501115],
            [-2.883838830812129, 51.045794189271938],
            [-2.884368141017348, 51.047220872981761],
            [-2.884049870170298, 51.047288026784386],
            [-2.883497735800317, 51.046835389910896],
            [-2.87936902944982, 51.046594967178095],
            [-2.8805153406349, 51.046937936216523],
            [-2.883492548456702, 51.047012579166889],
            [-2.883866235488793, 51.047458472975734],
            [-2.883801445159158, 51.049075793995719],
            [-2.887426146395075, 51.049580668965845],
            [-2.887713816283004, 51.051046040825561],
            [-2.889414158827029, 51.051926053628684],
            [-2.89040786782484, 51.053302412831819],
            [-2.892232690610768, 51.053971014265976],
            [-2.89283094868245, 51.05451318114924],
            [-2.892557310563099, 51.057719256045488],
            [-2.893157601388495, 51.058957415318289],
            [-2.892513572497045, 51.061232011012066],
            [-2.892146188622617, 51.061272584492841],
            [-2.892455245019941, 51.062576815709953],
            [-2.890886743620499, 51.065242431635234],
            [-2.890819141037493, 51.066255487041417],
            [-2.888187813476079, 51.069105411930678],
            [-2.887482029695173, 51.070186259838877],
            [-2.88572878022868, 51.071203108280599],
            [-2.883105971553153, 51.073481836260967],
            [-2.882079644622361, 51.075505671304541],
            [-2.879527737158732, 51.078157862343794],
            [-2.877594761478106, 51.079131876193841],
            [-2.877428559697269, 51.081287687362767],
            [-2.864683620417106, 51.078800982206573],
            [-2.859253761733507, 51.07797866709592],
            [-2.857289050800326, 51.07795352995376],
            [-2.84993877972312, 51.103181175094782],
            [-2.845161226642124, 51.102009052648796],
            [-2.819867720022896, 51.107938860823566],
            [-2.794415161729563, 51.10780258232964],
            [-2.790923940193519, 51.098505782459888],
            [-2.789689827286177, 51.098409816784738],
            [-2.772005294673427, 51.097122472001594],
            [-2.746696728938352, 51.095349069264998],
            [-2.742779644924245, 51.096864044172918],
            [-2.743292730166006, 51.099026114786106],
            [-2.74034358062352, 51.09991797910196],
            [-2.738580988055399, 51.106444004260254],
            [-2.737626411244999, 51.107045317655164],
            [-2.736802700301522, 51.10716471413928],
            [-2.734442183933285, 51.106753338331799],
            [-2.72846262575391, 51.104187507491737],
            [-2.720212616948155, 51.101881770551444],
            [-2.717752759778731, 51.099363790929893],
            [-2.712813514739635, 51.096533589399648],
            [-2.710080548186194, 51.093387656299356],
            [-2.707409255718044, 51.091638679246316],
            [-2.70788362235915, 51.091564768325448],
            [-2.707913887614685, 51.090180679197637],
            [-2.708454792369927, 51.090070393884574],
            [-2.708604257330261, 51.08966663529845],
            [-2.715426610484443, 51.088190807887742],
            [-2.715632499152468, 51.083029793334994],
            [-2.714559492451821, 51.083253072407572],
            [-2.713941994462994, 51.082648070741044],
            [-2.715259206180707, 51.081597813714367],
            [-2.714688300792981, 51.081328841120218],
            [-2.714889583412944, 51.080587547113971],
            [-2.714270034492846, 51.080403397257413],
            [-2.715621884682078, 51.079752180258225],
            [-2.714308507969096, 51.078452740786531],
            [-2.715465001731934, 51.077726284978816],
            [-2.713790641960347, 51.075969544167052],
            [-2.710347824908257, 51.074581434785323],
            [-2.705848562177408, 51.072040506902084],
            [-2.705087605869047, 51.072872389506578],
            [-2.705557055061662, 51.07380115476343],
            [-2.706462052132841, 51.074460215462572],
            [-2.705815204830225, 51.075382234360511],
            [-2.705992578510727, 51.076250714695682],
            [-2.707607343553361, 51.076552979547934],
            [-2.708633127493199, 51.077077307153004],
            [-2.709452280956568, 51.07631698687662],
            [-2.710623905468796, 51.077601156975057],
            [-2.708740588017807, 51.078411106972723],
            [-2.708996565001257, 51.078814205914398],
            [-2.70815001092714, 51.08021403810617],
            [-2.707239826727251, 51.080627798443579],
            [-2.704887754392438, 51.08000805529494],
            [-2.704873474655683, 51.079444326960164],
            [-2.70234368609323, 51.078489294086992],
            [-2.699837570049224, 51.078150034372754],
            [-2.697339216581188, 51.076236130405924],
            [-2.694438890199558, 51.077681372146728],
            [-2.693757579570735, 51.078799559145857],
            [-2.689451855552841, 51.080025506867933],
            [-2.688220992790586, 51.079331366710605],
            [-2.687522521074865, 51.079496439377948],
            [-2.685738769740519, 51.080121090646173],
            [-2.68596962206388, 51.080865193098305],
            [-2.682699358640589, 51.081565955761846],
            [-2.680235797508494, 51.082766398557546],
            [-2.677784546567898, 51.082669137306517],
            [-2.674510650382597, 51.083431737688052],
            [-2.674236025808653, 51.085163428864824],
            [-2.672697157209071, 51.086850247882921],
            [-2.670480222528073, 51.09052911957037],
            [-2.669498131201691, 51.094700846432502],
            [-2.663955865926542, 51.099273531433909],
            [-2.661331293571711, 51.100899815363519],
            [-2.658434476851091, 51.102075265717701],
            [-2.651808613618166, 51.101648406938857],
            [-2.648387307746051, 51.102107155464346],
            [-2.647344372555004, 51.10167501638756],
            [-2.647030925792617, 51.100093225359714],
            [-2.646093825684195, 51.098745982024766],
            [-2.646692676836183, 51.095607087432775],
            [-2.645600677595785, 51.093281444459286],
            [-2.644583353681647, 51.092929169747507],
            [-2.643868540378468, 51.092354910744596],
            [-2.64397138294883, 51.091946097609643],
            [-2.641632509565523, 51.090712640985053],
            [-2.640800184838438, 51.088863012723458],
            [-2.639219705714149, 51.08794007436699],
            [-2.636968042002277, 51.089080891823045],
            [-2.63543991843473, 51.088951629833097],
            [-2.634395012750333, 51.08824062708905],
            [-2.636999225335452, 51.084180861438718],
            [-2.637882407191227, 51.085282087386986],
            [-2.638580739666235, 51.08498422786154],
            [-2.638969960932569, 51.085152053372148],
            [-2.63999211370516, 51.083594402209513],
            [-2.642523080019048, 51.082647120812283],
            [-2.641878048084699, 51.08101587837313],
            [-2.64051665342196, 51.080433457959032],
            [-2.639844986215964, 51.079037047134968],
            [-2.639990403548548, 51.076127262973564],
            [-2.639541089901221, 51.074494934084989],
            [-2.637586561764879, 51.071451850009318],
            [-2.636757773405762, 51.068158912994157],
            [-2.634897853174304, 51.064800537098087],
            [-2.632701306149558, 51.063316141030803],
            [-2.632241823802612, 51.063736767982199],
            [-2.631144962854261, 51.063671659181281],
            [-2.627320322366233, 51.064293837510704],
            [-2.617628109623396, 51.065076541726064],
            [-2.613343411536844, 51.065141375399349],
            [-2.613312430177963, 51.065928359314128],
            [-2.614192473931679, 51.066861627536809],
            [-2.614331662862234, 51.06778080205752],
            [-2.613256334634489, 51.068378140140659],
            [-2.613234613572165, 51.069326036045553],
            [-2.611502650871769, 51.070027515067466],
            [-2.606601542217599, 51.07112223800123],
            [-2.604429143839188, 51.07023966763483],
            [-2.603835883061215, 51.070495416693369],
            [-2.602768322598163, 51.070277921323502],
            [-2.60243733942084, 51.070838045862324],
            [-2.601709528165511, 51.070845392902783],
            [-2.601672045830607, 51.070266486200573],
            [-2.600467571214276, 51.069945364869092],
            [-2.599091906046547, 51.069849914786623],
            [-2.598741237253814, 51.070325602786802],
            [-2.598008560540466, 51.070287091352611],
            [-2.598012201590529, 51.071565767786041],
            [-2.599217787374551, 51.075285070908237],
            [-2.601999029745448, 51.081603096558574],
            [-2.601485971258899, 51.081890791688679],
            [-2.601738670191489, 51.083431655266025],
            [-2.600703255267645, 51.084284947804022],
            [-2.601300543559694, 51.084655052704299],
            [-2.60041956229772, 51.08486820171639],
            [-2.597665103082073, 51.084502850309086],
            [-2.595425418904167, 51.083752643405866],
            [-2.591028462590006, 51.083548360721728],
            [-2.590108471032996, 51.083166345160556],
            [-2.588294030574446, 51.0837366073767],
            [-2.587489628217754, 51.083123788779979],
            [-2.586044714453932, 51.084468185036556],
            [-2.586396031803603, 51.085718137463054],
            [-2.587736625683199, 51.085714999627172],
            [-2.588596255158953, 51.088312117036836],
            [-2.589214707542566, 51.088441185801052],
            [-2.589970891277151, 51.08918282405785],
            [-2.590898653361082, 51.089045052116369],
            [-2.590904545354519, 51.089507221362958],
            [-2.591701168804767, 51.090169512544641],
            [-2.593334468585168, 51.090044332899808],
            [-2.59635760864003, 51.088851865845676],
            [-2.598640021921834, 51.088689127320819],
            [-2.601156824246, 51.088985539537056],
            [-2.601121872625842, 51.091685178088952],
            [-2.602437094902759, 51.094001090275214],
            [-2.598095849180677, 51.098412476524466],
            [-2.595309812564998, 51.096428636737876],
            [-2.592907018242456, 51.093240530447993],
            [-2.591356664890231, 51.09282485333727],
            [-2.589380706275917, 51.090716274093211],
            [-2.587726665576621, 51.090221940420712],
            [-2.585259416975881, 51.09068213390114],
            [-2.582290309097475, 51.09041101381527],
            [-2.580677694573991, 51.090025179674967],
            [-2.57923720698855, 51.088786010160014],
            [-2.573000389923058, 51.090509987041223],
            [-2.572200052067527, 51.091029161028132],
            [-2.571711360839868, 51.090768980837687],
            [-2.569912126137765, 51.091108681691175],
            [-2.567927596079455, 51.090778438149826],
            [-2.567543317090482, 51.091346812916363],
            [-2.566397181429128, 51.091735440665722],
            [-2.56613408875507, 51.092524431033041],
            [-2.565264241864985, 51.093207551641946],
            [-2.562943803232947, 51.093794252107088],
            [-2.563112222448438, 51.093199956592777],
            [-2.562459756791198, 51.092856000377864],
            [-2.562984407462702, 51.092199742245541],
            [-2.561886877494792, 51.091891197118883],
            [-2.561503993750054, 51.091285165657247],
            [-2.560903931510791, 51.091846462041651],
            [-2.559680748542999, 51.091890992906102],
            [-2.559473738865782, 51.092248973858645],
            [-2.55876300637469, 51.092161553211973],
            [-2.55688445959001, 51.092686671403378],
            [-2.555745561438548, 51.092379165040235],
            [-2.55505510251685, 51.093149479621381],
            [-2.555305624760662, 51.093917120944354],
            [-2.553516977939438, 51.094087465616269],
            [-2.552873048000647, 51.09529186878293],
            [-2.551735452838256, 51.095336810617127],
            [-2.551240738910237, 51.095884970282356],
            [-2.550436272954264, 51.095724205253184],
            [-2.549932254021592, 51.096693236915172],
            [-2.549069583055826, 51.096562410542106],
            [-2.549054321928961, 51.096961734766936],
            [-2.546919914616051, 51.097613784113079],
            [-2.546289968069719, 51.097479150238705],
            [-2.546267872271712, 51.096695136071304],
            [-2.545372642338442, 51.096466419935638],
            [-2.544875777479516, 51.097203398073454],
            [-2.543383767756099, 51.097353320390418],
            [-2.542695472767238, 51.098332169148037],
            [-2.54143477700725, 51.09774902951898],
            [-2.540922034937443, 51.09786650366464],
            [-2.540951832766429, 51.098461647347449],
            [-2.539470511330546, 51.098307534844338],
            [-2.538679027280889, 51.098649291260102],
            [-2.53757227086499, 51.098529396291731],
            [-2.535063772596337, 51.099084934199794],
            [-2.534181043096645, 51.099939631165853],
            [-2.533434734363161, 51.099993396137371],
            [-2.53313386245532, 51.100530702010353],
            [-2.532417669612161, 51.100471921042356],
            [-2.532210273927987, 51.101439523139781],
            [-2.531406560308448, 51.101346964281987],
            [-2.53118019049308, 51.100661891419257],
            [-2.530159994462901, 51.100495672795773],
            [-2.529592896618735, 51.101110610215422],
            [-2.529224021577084, 51.100575449155059],
            [-2.528620749549183, 51.100900098832142],
            [-2.527644433996577, 51.10057359931043],
            [-2.527810712566707, 51.10112856402165],
            [-2.526523230066152, 51.101194619040719],
            [-2.525652941443278, 51.101778530972084],
            [-2.524925286331541, 51.101465277559569],
            [-2.524521793393179, 51.101652327665057],
            [-2.521775692920095, 51.101176346082433],
            [-2.520563728649061, 51.101608878323667],
            [-2.520645301288713, 51.102131858507477],
            [-2.51996466109739, 51.102569209909724],
            [-2.520650785633904, 51.103128164984199],
            [-2.520152430218899, 51.103392055797229],
            [-2.517387900877604, 51.103189414733322],
            [-2.516613222482209, 51.104291682854893],
            [-2.515420923738305, 51.104192638253807],
            [-2.514627892883744, 51.104677213111209],
            [-2.513038199370759, 51.104291245377333],
            [-2.511894132858639, 51.104807915409637],
            [-2.511609053546076, 51.104343370319235],
            [-2.510871781580493, 51.104318719812859],
            [-2.510296280517315, 51.104716888695044],
            [-2.506613519035265, 51.105577273690741],
            [-2.506099173978703, 51.106348330713367],
            [-2.505126090666165, 51.106052202837319],
            [-2.504451079665521, 51.106762800216451],
            [-2.502533448985281, 51.107351054864253],
            [-2.50109706422429, 51.1074039814768],
            [-2.50092237862224, 51.107100795884456],
            [-2.500385575381716, 51.10725776031726],
            [-2.498587423610675, 51.109530565599705],
            [-2.500322260261592, 51.110652564431163],
            [-2.501744705576225, 51.112731742987975],
            [-2.491940957434565, 51.114383909796004],
            [-2.489766909465043, 51.115761642198237],
            [-2.487380666707613, 51.116130403068844],
            [-2.485503779926089, 51.116846794077233],
            [-2.484523341765112, 51.118177200772166],
            [-2.483797535007211, 51.118585752033454],
            [-2.48355211591189, 51.118343081154727],
            [-2.482698339544535, 51.118674822694025],
            [-2.48207471717064, 51.119294255950486],
            [-2.478472575663629, 51.118440418936054],
            [-2.474940604883837, 51.118564528489792],
            [-2.474216192213474, 51.118839031905146],
            [-2.472946931467981, 51.118646348261287],
            [-2.471981035985984, 51.119480225171429],
            [-2.470785722893249, 51.119113671590853],
            [-2.470010724439403, 51.120178759572298],
            [-2.468561626706053, 51.120853591187789],
            [-2.468560207994463, 51.121842729259072],
            [-2.464822243485784, 51.121828879853801],
            [-2.466376612694551, 51.124219984281396],
            [-2.462742814251673, 51.123496175210875],
            [-2.460262906948229, 51.12355991974426],
            [-2.456980990633097, 51.123149269293286],
            [-2.455991078485553, 51.12216400370351],
            [-2.454401723307799, 51.122551458958476],
            [-2.453538829522677, 51.12300261742751],
            [-2.451596623672823, 51.125161049084397],
            [-2.449004024851229, 51.125992016262167],
            [-2.448247789319135, 51.1268446723168],
            [-2.448833606352591, 51.127721851419786],
            [-2.448186855170494, 51.128963444060901],
            [-2.446928233340783, 51.130185792979681],
            [-2.445019522074295, 51.130986178201432],
            [-2.443553818184901, 51.132238053120112],
            [-2.442507592958022, 51.132527967025965],
            [-2.440672299362498, 51.132501629947491],
            [-2.438731610647498, 51.133266963378205],
            [-2.436471777690894, 51.132932825321788],
            [-2.434816521183746, 51.133207857989014],
            [-2.433726367841933, 51.134460908799007],
            [-2.434361083919449, 51.135237266239642],
            [-2.434496169147467, 51.13729415224627],
            [-2.431071875168359, 51.139937013058123],
            [-2.428155543001933, 51.143293681141735],
            [-2.425616743796907, 51.14282547382436],
            [-2.425849070484522, 51.142286002746879],
            [-2.424763991199673, 51.141806177414196],
            [-2.417811193459458, 51.140860092539377],
            [-2.416615263252058, 51.141866976791277],
            [-2.414476365526504, 51.142774686433604],
            [-2.413388643302743, 51.144386319350751],
            [-2.412183322694835, 51.143718868621143],
            [-2.41178853893079, 51.143852443377625],
            [-2.411165401050741, 51.146278896690994],
            [-2.410292161205102, 51.146144386482959],
            [-2.409998003697499, 51.146666058819577],
            [-2.40803301076239, 51.147717811483147],
            [-2.405480835692923, 51.146869749293138],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000190",
        LAD13CDO: "40UE",
        LAD13NM: "Taunton Deane",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-2.927958080880314, 51.075829301215158],
              [-2.926849421275079, 51.074352560172485],
              [-2.923838319601553, 51.073241565890484],
              [-2.922410948387476, 51.07209282555101],
              [-2.919866645661674, 51.070828770359206],
              [-2.917190755945094, 51.0678832199957],
              [-2.917316385432914, 51.067307621213558],
              [-2.918509693133487, 51.066524004220554],
              [-2.919180145003712, 51.065571835128132],
              [-2.920969546914863, 51.064913891668084],
              [-2.921446197665075, 51.064123300765381],
              [-2.92337052462289, 51.063238947458814],
              [-2.926923006639919, 51.061606179886901],
              [-2.929835096041472, 51.06315131597708],
              [-2.931980990609227, 51.061923845172544],
              [-2.941455988230192, 51.067319760770438],
              [-2.946268693583606, 51.068591079153762],
              [-2.947367133297305, 51.07115130400863],
              [-2.946685305918446, 51.072360909561695],
              [-2.948053879855617, 51.072767960619032],
              [-2.948703404041175, 51.073330108789754],
              [-2.948548528294469, 51.073923064670296],
              [-2.946929194493319, 51.07486870692906],
              [-2.946479233551456, 51.075901979244179],
              [-2.938856019693711, 51.075658629168231],
              [-2.935839045690606, 51.076538901470876],
              [-2.937337675697894, 51.078647291674798],
              [-2.937444212332891, 51.080104099752042],
              [-2.934135645262767, 51.08055683022156],
              [-2.933619973467953, 51.079690490104667],
              [-2.927958080880314, 51.075829301215158],
            ],
          ],
          [
            [
              [-2.925258905773154, 51.08513621196478],
              [-2.92325239503347, 51.082886007253464],
              [-2.922175181042325, 51.08291969064571],
              [-2.92157781790562, 51.081812048528896],
              [-2.912188645886304, 51.085167017354856],
              [-2.904385979346499, 51.075132847106246],
              [-2.9019503244686, 51.075475395610496],
              [-2.901307568651402, 51.074278976099151],
              [-2.900101230990424, 51.074195652563624],
              [-2.897866041800719, 51.073095097506979],
              [-2.896802611441295, 51.073018735057872],
              [-2.894942182445931, 51.072322601348816],
              [-2.890474789396121, 51.070546567352793],
              [-2.888187813476079, 51.069105411930678],
              [-2.890819141037493, 51.066255487041417],
              [-2.890886743620499, 51.065242431635234],
              [-2.892455245019941, 51.062576815709953],
              [-2.892146188622617, 51.061272584492841],
              [-2.892513572497045, 51.061232011012066],
              [-2.892779359651834, 51.061268569567929],
              [-2.894792816230563, 51.061545499022202],
              [-2.896691195913169, 51.062160412661292],
              [-2.901770064232736, 51.061137562496725],
              [-2.905637648357299, 51.061306382792445],
              [-2.907828200073431, 51.063062681381965],
              [-2.911541707000139, 51.065185647645073],
              [-2.916790535795314, 51.067274879186726],
              [-2.917315962905019, 51.068585440736584],
              [-2.919656331050184, 51.070875387521454],
              [-2.922381414751893, 51.072258518258813],
              [-2.923387875684764, 51.073200164115121],
              [-2.926803207237046, 51.07453996795001],
              [-2.927867776093784, 51.075953213666388],
              [-2.933700531026412, 51.080004579259743],
              [-2.93457953785524, 51.083314835417667],
              [-2.925258905773154, 51.08513621196478],
            ],
          ],
          [
            [
              [-3.183189370402862, 51.110261875881335],
              [-3.181879869139051, 51.108712231576312],
              [-3.179521087684331, 51.108866447428397],
              [-3.175041451519813, 51.107518629926993],
              [-3.168621278720797, 51.108395010254419],
              [-3.168038656296435, 51.106136530843031],
              [-3.168415419889022, 51.104310891453814],
              [-3.165954423397569, 51.104085474585609],
              [-3.162728118348022, 51.104894560688336],
              [-3.162341357295442, 51.105249113297788],
              [-3.161466978303508, 51.104955657880019],
              [-3.159698219179163, 51.106478557453997],
              [-3.158357262584971, 51.106548505968419],
              [-3.158197125277149, 51.107570736769013],
              [-3.156441173683524, 51.107726605613649],
              [-3.155035963786072, 51.108658628655661],
              [-3.153603996741642, 51.108633202372403],
              [-3.151511498312171, 51.106113465937497],
              [-3.147782138899454, 51.106812888276018],
              [-3.146775699440138, 51.107587124555572],
              [-3.142156511741983, 51.109283362473498],
              [-3.141593590663049, 51.107308724812455],
              [-3.143040508990746, 51.10580004006907],
              [-3.142815415231786, 51.104028929565565],
              [-3.142295875466198, 51.102653666277305],
              [-3.140016473010867, 51.100569886637174],
              [-3.140848976910891, 51.099519538367595],
              [-3.142212733299754, 51.098817397314861],
              [-3.147648173697924, 51.097944942065574],
              [-3.150926574050118, 51.097862348921019],
              [-3.153790663527651, 51.096512218988813],
              [-3.154069963442787, 51.094939375930998],
              [-3.151278689867493, 51.093295202319347],
              [-3.155500518100449, 51.091488317450086],
              [-3.159332529873363, 51.091407206347725],
              [-3.159941528697525, 51.090864312811632],
              [-3.15709675739058, 51.089763954095346],
              [-3.149918151171518, 51.088704447703371],
              [-3.150687708109953, 51.088184302291253],
              [-3.153038007965158, 51.084857299989551],
              [-3.149693687098338, 51.083050383423576],
              [-3.147491294627647, 51.083187131952648],
              [-3.147581409616053, 51.082155708069074],
              [-3.145870953869724, 51.079088966294464],
              [-3.143842695066132, 51.079275196476829],
              [-3.143454533823773, 51.077312337100416],
              [-3.141912524918097, 51.075850847051356],
              [-3.138764690889628, 51.073746744812553],
              [-3.138855610914843, 51.072798949014626],
              [-3.137737096050436, 51.072122817169443],
              [-3.136528215943881, 51.072068035786486],
              [-3.134863743802111, 51.072352187926846],
              [-3.131881681935088, 51.071695881106237],
              [-3.131286778620469, 51.071649485164507],
              [-3.13117831536488, 51.072001242323282],
              [-3.129115442438531, 51.071869218839375],
              [-3.121330297007638, 51.070965796810796],
              [-3.115476419121317, 51.072133291537639],
              [-3.114572840138466, 51.072902676832491],
              [-3.104556190274878, 51.073289137045698],
              [-3.102306674104769, 51.07305049473095],
              [-3.100383493466106, 51.072427456037843],
              [-3.099480883188015, 51.069153698538869],
              [-3.099526793055573, 51.068561561593384],
              [-3.100721419882048, 51.067961307865971],
              [-3.100939354572209, 51.067522220365099],
              [-3.095813266080475, 51.06766122574583],
              [-3.094582071407868, 51.064959734016895],
              [-3.089556177358607, 51.066072311660626],
              [-3.090703724577719, 51.069173002221561],
              [-3.090349882278184, 51.070696031637844],
              [-3.086301971094358, 51.070143824104093],
              [-3.084377178244523, 51.071513268049046],
              [-3.077980539778625, 51.070005062562302],
              [-3.071164912311532, 51.067949111438423],
              [-3.070670110343913, 51.068030081837151],
              [-3.065213638260615, 51.066093513236822],
              [-3.062433917431076, 51.065424601084828],
              [-3.056866260809201, 51.065093808449738],
              [-3.054378893468729, 51.065287134862281],
              [-3.052715397342407, 51.064996389157955],
              [-3.051568975937013, 51.065543560718709],
              [-3.051415480800974, 51.066264338038536],
              [-3.04615868141207, 51.065479716222328],
              [-3.045393156005443, 51.065200607237728],
              [-3.04338641395425, 51.06328786345518],
              [-3.043153570190911, 51.062388898647008],
              [-3.040512319629298, 51.061252421122539],
              [-3.035542995284562, 51.060974635188238],
              [-3.036051644629401, 51.060300188865114],
              [-3.035865342064063, 51.059880993835328],
              [-3.032694562243405, 51.060006177727665],
              [-3.029765575321522, 51.059389066477799],
              [-3.028045076729849, 51.059793588974095],
              [-3.026186891214297, 51.059393572115404],
              [-3.024672974199465, 51.059849285910111],
              [-3.024444978315875, 51.057019563458844],
              [-3.032203069353105, 51.057091570658677],
              [-3.032222129687516, 51.056601313546807],
              [-3.031139786723482, 51.056373469046484],
              [-3.03104193707146, 51.055758349369036],
              [-3.032332449958257, 51.056108451666276],
              [-3.032428509021171, 51.05555186920585],
              [-3.029870156150909, 51.055356828689085],
              [-3.030024610928106, 51.054537153801725],
              [-3.032963184197659, 51.054444668500601],
              [-3.03298233835444, 51.054151345033041],
              [-3.029802594451019, 51.054048122600527],
              [-3.029450724555962, 51.050856202595682],
              [-3.031971842663681, 51.050863638080543],
              [-3.031204522212724, 51.047919985042796],
              [-3.028694177949638, 51.048198399017437],
              [-3.027289637443663, 51.048357333668619],
              [-3.026141484384448, 51.049272963804583],
              [-3.025554682077639, 51.050414762829156],
              [-3.022412935968666, 51.053643610542295],
              [-3.020273772311635, 51.053558895739386],
              [-3.019691585260836, 51.054140393961198],
              [-3.017848903818248, 51.05456381811851],
              [-3.016231727241229, 51.054796410660366],
              [-3.01544486228268, 51.0546341934946],
              [-3.009283184489143, 51.052499716755264],
              [-3.006715923975083, 51.053197196778349],
              [-3.005593666866823, 51.053224845455844],
              [-3.002515311266863, 51.052591252652462],
              [-3.002277753225654, 51.053020431660663],
              [-3.000088411058845, 51.053451044806366],
              [-2.999301682866923, 51.053954158500787],
              [-2.997443348460662, 51.053790188055693],
              [-2.995009982500101, 51.050894687017752],
              [-2.994081607352684, 51.0487812720527],
              [-2.994104408768882, 51.046514981763444],
              [-2.989827297651244, 51.046834556587108],
              [-2.989721802459617, 51.0465701731504],
              [-2.987907782998022, 51.046260898086281],
              [-2.987147440924004, 51.045925611677035],
              [-2.987458297527169, 51.0441757526782],
              [-2.985477635049838, 51.044142117976328],
              [-2.982146605557364, 51.040827674192975],
              [-2.980699853640094, 51.040875796950061],
              [-2.979420230539093, 51.042085224200882],
              [-2.977765936445175, 51.042575679904822],
              [-2.976971390031841, 51.042371004143497],
              [-2.974828163399863, 51.042743208319372],
              [-2.974212532570211, 51.042499252636162],
              [-2.973190518098955, 51.042602190719634],
              [-2.972583035338255, 51.044040644167026],
              [-2.96925027592652, 51.045655504484536],
              [-2.968495389982272, 51.046600574402248],
              [-2.967291406144349, 51.047012509923491],
              [-2.963371851680821, 51.046424415874178],
              [-2.963370866436425, 51.048026874854116],
              [-2.962792636141193, 51.048142247916893],
              [-2.959688319127385, 51.047380949779885],
              [-2.957776463168478, 51.047438002794607],
              [-2.955274312768082, 51.051461913018628],
              [-2.951234526467268, 51.052327568994592],
              [-2.950856938918324, 51.054301779294917],
              [-2.948358323540323, 51.055124208591543],
              [-2.947078019759452, 51.056989726445387],
              [-2.946398668622768, 51.056981742163529],
              [-2.943759064267428, 51.05803901642723],
              [-2.942745375710614, 51.057658727601321],
              [-2.935820964282809, 51.057176643968369],
              [-2.933314971819692, 51.057298301417688],
              [-2.94382189373692, 51.047656771698819],
              [-2.94487491826884, 51.04619508570223],
              [-2.947268674298492, 51.045707197571389],
              [-2.949321223221637, 51.043673537403969],
              [-2.949963074124471, 51.041997527255646],
              [-2.950724266223606, 51.041345679127865],
              [-2.95245155238365, 51.040925152796987],
              [-2.954320206192835, 51.041069070263013],
              [-2.959060695966895, 51.040202049428579],
              [-2.962888259856069, 51.039331208095398],
              [-2.964542629303719, 51.039018643017307],
              [-2.971134451923933, 51.037595220345828],
              [-2.97614365461854, 51.037533691479425],
              [-2.976088311820434, 51.03734351390883],
              [-2.971378518226109, 51.037398954454666],
              [-2.959762520167184, 51.039921687693329],
              [-2.954241033028255, 51.040964505467414],
              [-2.952620438295295, 51.040814068201641],
              [-2.951164554673826, 51.041044446012073],
              [-2.950003082772381, 51.041722033591938],
              [-2.949141262147906, 51.043662410097305],
              [-2.947084078466816, 51.045679019053487],
              [-2.944808995737519, 51.046040049925878],
              [-2.943500616749866, 51.047782562634104],
              [-2.932820054389858, 51.057450628047363],
              [-2.931730052213456, 51.058025845617529],
              [-2.931338428014019, 51.058775336480444],
              [-2.929942821037964, 51.059839460934931],
              [-2.921434424072062, 51.064033469794815],
              [-2.920817851051253, 51.064892606188103],
              [-2.919191599571765, 51.065429665458396],
              [-2.917116362256406, 51.067296601889026],
              [-2.911791042821945, 51.065164818410061],
              [-2.908321511100636, 51.063258478541158],
              [-2.906899825302639, 51.061926959279937],
              [-2.905281889368366, 51.061064546860614],
              [-2.901753594921082, 51.061024385665426],
              [-2.896707485616606, 51.062043386832116],
              [-2.89495966889799, 51.06146598759068],
              [-2.892795103067979, 51.061206312901319],
              [-2.893388320705204, 51.058860332371033],
              [-2.892653978337316, 51.057552158641904],
              [-2.893206691530098, 51.055206319661679],
              [-2.892943690918047, 51.054440380565659],
              [-2.892457395469143, 51.05393332865858],
              [-2.890550930524108, 51.053248267483404],
              [-2.8895050548261, 51.051830042426175],
              [-2.887931309646669, 51.051004821711118],
              [-2.887599070167069, 51.049521804178383],
              [-2.883913777691883, 51.048981423435983],
              [-2.884049870170298, 51.047288026784386],
              [-2.884368141017348, 51.047220872981761],
              [-2.883838830812129, 51.045794189271938],
              [-2.886731595897916, 51.045708424501115],
              [-2.912402607759331, 51.02999885699343],
              [-2.9258383561147, 51.021752292857499],
              [-2.946009877311542, 51.013535230468911],
              [-2.947209787969178, 51.012396951297724],
              [-2.949730152378868, 51.011284799759508],
              [-2.950471196529894, 51.01127517517719],
              [-2.949749174707798, 51.00977390897377],
              [-2.950734792698384, 51.009596832155147],
              [-2.948413988544748, 51.006161687539503],
              [-2.946451778981243, 51.004222637955252],
              [-2.944304734459612, 51.003279618657771],
              [-2.949234175605961, 51.003330485266339],
              [-2.952491598292218, 51.0021169581116],
              [-2.952905028267888, 51.00240134559408],
              [-2.954513201378134, 51.002497922689649],
              [-2.957310477276364, 51.001994830056503],
              [-2.956699451044384, 51.000489097456644],
              [-2.960033320537385, 50.999753133057943],
              [-2.9601098393019, 51.000353202960916],
              [-2.961012813474721, 51.000602062970678],
              [-2.964789722669391, 50.999998103247158],
              [-2.968153752767872, 51.000034113461759],
              [-2.970860904840378, 50.999460401534655],
              [-2.971925747809122, 50.999610712657116],
              [-2.978113954942045, 50.998070793272205],
              [-2.979015474415854, 50.997164887983473],
              [-2.97960572058788, 50.995351544140846],
              [-2.980690606653652, 50.994360456716777],
              [-2.97949326565489, 50.993124141414945],
              [-2.980587601418364, 50.992110493879643],
              [-2.982668354280385, 50.991321446618272],
              [-2.983914151851322, 50.990266026419171],
              [-2.978039672502393, 50.984847013829686],
              [-2.975735429366398, 50.977549961897459],
              [-2.975724456856269, 50.974852290748572],
              [-2.975099469380958, 50.973166912599893],
              [-2.975558298555932, 50.97304078168883],
              [-2.975474714552686, 50.972586457025479],
              [-2.976492132721685, 50.972473639964846],
              [-2.977152985456145, 50.97179456988934],
              [-2.978153667193274, 50.971564075909185],
              [-2.978839996630329, 50.971556526299864],
              [-2.978694112372936, 50.972475888501322],
              [-2.97938510856111, 50.97268951335095],
              [-2.98019030673344, 50.972171081200848],
              [-2.980700488734538, 50.972455458535364],
              [-2.982779603847775, 50.972102556750052],
              [-2.982014793764592, 50.968795234517842],
              [-2.980929791200617, 50.96880525338829],
              [-2.980817462907229, 50.967461809570509],
              [-2.98016690893794, 50.967407022030848],
              [-2.980453719676132, 50.964999104257778],
              [-2.984591335200159, 50.964630664595752],
              [-2.984353936400887, 50.963639886879044],
              [-2.984732016083443, 50.963269802265216],
              [-2.986735728185749, 50.962940844802006],
              [-2.989519529939769, 50.96159627809164],
              [-2.991382018843688, 50.961260343910311],
              [-2.995753795027075, 50.959276250416181],
              [-2.996426222230718, 50.959359540520005],
              [-2.997044200935803, 50.958828199219234],
              [-3.000479906195727, 50.957896854592555],
              [-3.000799348503658, 50.957522723233339],
              [-3.002717827356572, 50.957087208939775],
              [-3.005260223971988, 50.958316216433722],
              [-3.007699527100181, 50.957077593211601],
              [-3.008877595732538, 50.955723926833549],
              [-3.008736695336939, 50.953688321783311],
              [-3.011785696698535, 50.950581075330547],
              [-3.010688217473562, 50.947765104984143],
              [-3.010934787032709, 50.947385280813094],
              [-3.012062590699853, 50.946550887828607],
              [-3.013668516406062, 50.946103511983956],
              [-3.014937220127416, 50.944945032632418],
              [-3.017224095050996, 50.944917935711295],
              [-3.018033980420707, 50.944589840168355],
              [-3.021076594328163, 50.944494026327831],
              [-3.028767840656377, 50.941549766224568],
              [-3.030128470238325, 50.939807587073709],
              [-3.029309307645165, 50.939192522396198],
              [-3.032154565939828, 50.937464197369977],
              [-3.032812251819993, 50.936315416965854],
              [-3.034960204315901, 50.936076074799566],
              [-3.037068814423611, 50.935348742270719],
              [-3.039116779655934, 50.935415062092147],
              [-3.041618125344896, 50.934435054409775],
              [-3.042607554463507, 50.934642045480594],
              [-3.045565130555308, 50.933831435338888],
              [-3.047158164476401, 50.932907100753567],
              [-3.048257231182599, 50.933370250219539],
              [-3.052402921727695, 50.932750226838245],
              [-3.056028622655262, 50.93298906302536],
              [-3.058577711243847, 50.932628751487115],
              [-3.058898774810606, 50.933043098892242],
              [-3.065037428292561, 50.934640106632258],
              [-3.068221125553301, 50.936882563159138],
              [-3.076601994922823, 50.938531266890003],
              [-3.080347223761349, 50.939849164663634],
              [-3.086209339555414, 50.939648189847141],
              [-3.088368724611041, 50.938013895510423],
              [-3.090550498281985, 50.93180747636805],
              [-3.092379789011717, 50.929650115551233],
              [-3.091745554188169, 50.928391677822539],
              [-3.088866176387009, 50.925495050084209],
              [-3.084799173468241, 50.923516755922989],
              [-3.084563749179938, 50.921652961116486],
              [-3.083811502019236, 50.921363189319685],
              [-3.0820567007742, 50.919682548951869],
              [-3.080841604626829, 50.919185719537616],
              [-3.08016371206347, 50.919339474625659],
              [-3.079100024295653, 50.918806154468783],
              [-3.077984751274937, 50.917640215736299],
              [-3.073165047706605, 50.914491323054818],
              [-3.071482647588587, 50.914448326567886],
              [-3.071226861735803, 50.914088268884761],
              [-3.069275228446774, 50.913841778610283],
              [-3.069079731359636, 50.913380445803732],
              [-3.066072957310564, 50.913271257724453],
              [-3.065786438135164, 50.913556244693609],
              [-3.063868374225399, 50.913781473719638],
              [-3.062353939857495, 50.913117218616712],
              [-3.061874972691441, 50.913010067663166],
              [-3.061789090023026, 50.913236565382881],
              [-3.060061586196189, 50.912771158503148],
              [-3.05989238846, 50.912273600922731],
              [-3.058449308271079, 50.91205378535409],
              [-3.057615085949382, 50.911082942946528],
              [-3.055724846432664, 50.910460675256921],
              [-3.055521409051268, 50.909893278043754],
              [-3.054120657700928, 50.90990593696462],
              [-3.052794599247, 50.909446688600887],
              [-3.052556443982946, 50.908851722063332],
              [-3.051735889467419, 50.908668473889954],
              [-3.052415358224968, 50.908272066628967],
              [-3.052872386517914, 50.90873376609008],
              [-3.056266458724552, 50.908059223460278],
              [-3.057199188965756, 50.908422176916488],
              [-3.060023829007918, 50.90848019000785],
              [-3.078101242444024, 50.905445939476358],
              [-3.088750638618369, 50.90622387482329],
              [-3.094636521023726, 50.905034840119278],
              [-3.099209305445444, 50.904686111259664],
              [-3.101390440960189, 50.904060338862301],
              [-3.103415968952865, 50.904133832205005],
              [-3.106983047027053, 50.902823087163277],
              [-3.109511083538854, 50.902440272305903],
              [-3.110659498041276, 50.902650570673885],
              [-3.111977664384876, 50.902344856270197],
              [-3.115862638617026, 50.900784408035527],
              [-3.118478740245823, 50.899218010751596],
              [-3.122069310612661, 50.89814848074046],
              [-3.126332103106772, 50.901725201117749],
              [-3.127879562301155, 50.899849658046669],
              [-3.131018030525224, 50.897206891885311],
              [-3.13518912501032, 50.895000939454697],
              [-3.139176998824424, 50.891712096940012],
              [-3.13962572997271, 50.89145591841951],
              [-3.140084073892488, 50.892059352759503],
              [-3.141480483695123, 50.892356852579603],
              [-3.141842537653297, 50.891754392467988],
              [-3.143137434653922, 50.891796571750675],
              [-3.144168555740419, 50.891402475887176],
              [-3.145068100900051, 50.891673328369272],
              [-3.147702677433067, 50.891728383737984],
              [-3.152865460614987, 50.893806086477106],
              [-3.153876574710097, 50.895192671299725],
              [-3.15435338484524, 50.897108811643818],
              [-3.154099990248019, 50.897845127480601],
              [-3.156086175425749, 50.900069164971406],
              [-3.159338068468147, 50.90079679658546],
              [-3.162307205769158, 50.900902156785008],
              [-3.163772961759449, 50.900656436251396],
              [-3.167531861957522, 50.900838317898724],
              [-3.17042380408942, 50.900359713642551],
              [-3.17171463831007, 50.902438480467623],
              [-3.171837041489735, 50.904254690375829],
              [-3.171274229370252, 50.906479754570846],
              [-3.170488539850488, 50.907590149315318],
              [-3.172846874154104, 50.907878524061204],
              [-3.175766193040613, 50.907680987772622],
              [-3.177454620301081, 50.908193640948646],
              [-3.180120958504072, 50.908453581635406],
              [-3.187860409948946, 50.910428122106111],
              [-3.186236841808555, 50.91308940669537],
              [-3.184238728095835, 50.914508970139913],
              [-3.182852059332442, 50.916540106503916],
              [-3.180139107049031, 50.918786079581494],
              [-3.179953710755679, 50.919499280509875],
              [-3.179183049924572, 50.920079007202936],
              [-3.18027017546859, 50.922897138737596],
              [-3.177479725236258, 50.923896536158146],
              [-3.176374423174565, 50.92379257310089],
              [-3.172494329919001, 50.925599583161386],
              [-3.173443277553191, 50.926952447486855],
              [-3.172891171669923, 50.928648630158818],
              [-3.173541089701652, 50.930612405940657],
              [-3.173088788627667, 50.931412808554256],
              [-3.172488447528947, 50.935720060391667],
              [-3.171296004148323, 50.936859717710952],
              [-3.170194046130989, 50.939734948964997],
              [-3.16521141043057, 50.944130961920628],
              [-3.163935209700778, 50.945979102684866],
              [-3.16536078341513, 50.9465179330222],
              [-3.166468467786829, 50.947719986746215],
              [-3.171817654095409, 50.947418221934093],
              [-3.184531787331628, 50.948672999181952],
              [-3.188478874417139, 50.948598714446561],
              [-3.198620421481954, 50.94710656232855],
              [-3.218336013024211, 50.94328740747126],
              [-3.226291527349381, 50.943993678901862],
              [-3.228062192190367, 50.943828485148309],
              [-3.232503351086338, 50.944153984502968],
              [-3.240250701273302, 50.9429082766653],
              [-3.244317656245117, 50.942972914186349],
              [-3.25481923755075, 50.941852424459064],
              [-3.256694182183843, 50.943343941591223],
              [-3.262126485318453, 50.944100085947305],
              [-3.264282184819527, 50.946588442404966],
              [-3.272488299519317, 50.949021763358921],
              [-3.276492425299234, 50.950876427940713],
              [-3.284397991369493, 50.953769932922782],
              [-3.290057799997321, 50.955122997020673],
              [-3.292449393763911, 50.954905001577515],
              [-3.29383569633611, 50.955145042512363],
              [-3.295617554388369, 50.95665495497208],
              [-3.298271465034826, 50.958132665145513],
              [-3.298252799835991, 50.95853574906117],
              [-3.300083047240078, 50.958708729001337],
              [-3.301547916646145, 50.960582690736004],
              [-3.300266579021315, 50.961763329502595],
              [-3.301806654400829, 50.963063610403871],
              [-3.305375914507497, 50.964915841604636],
              [-3.312140668651524, 50.967506377300978],
              [-3.31574441047427, 50.969644775055492],
              [-3.317361611054966, 50.971179593330859],
              [-3.317653577071698, 50.970374143845888],
              [-3.318924432587758, 50.970741090030828],
              [-3.319859197918496, 50.970556068312604],
              [-3.32030206044049, 50.971005196895462],
              [-3.320399275925617, 50.972424056512097],
              [-3.321359346923329, 50.972778303147045],
              [-3.323446123226767, 50.974486679205221],
              [-3.323544449405526, 50.974989159962988],
              [-3.322830177985632, 50.975617754562435],
              [-3.323295174944545, 50.976141260958528],
              [-3.322424882540409, 50.976393025285773],
              [-3.3236606370474, 50.976870928882882],
              [-3.322901616197361, 50.977178989079242],
              [-3.323165285440504, 50.977534813197259],
              [-3.325869315277322, 50.979098560910522],
              [-3.327142329039331, 50.979130862873888],
              [-3.327828395570828, 50.97986047085115],
              [-3.330822707071561, 50.981103358774412],
              [-3.331329660493331, 50.981793620584597],
              [-3.333707764259349, 50.98280335137774],
              [-3.335204508190234, 50.982784449561258],
              [-3.339397656960169, 50.98111773334837],
              [-3.350748373371551, 50.980124587251211],
              [-3.351531425178125, 50.979765707950172],
              [-3.355286567524226, 50.979327404087748],
              [-3.359803740018804, 50.978108526620964],
              [-3.362261672843867, 50.978132037609761],
              [-3.364629728496396, 50.978831006935465],
              [-3.366158921684343, 50.978951616226695],
              [-3.369145365439352, 50.978586604359286],
              [-3.372438910254396, 50.977285353298299],
              [-3.378650668251268, 50.977514341043808],
              [-3.379221633819832, 50.980582239598554],
              [-3.380455105762489, 50.9825442834751],
              [-3.380457853503173, 50.984317631480138],
              [-3.381288044909832, 50.9867997196353],
              [-3.382983298447249, 50.98928504956168],
              [-3.383059601372975, 50.991036841480032],
              [-3.382642917370343, 50.991879004224771],
              [-3.383414640135003, 50.994542522444668],
              [-3.382087861729518, 50.99592963073561],
              [-3.381670742677949, 50.997046973399236],
              [-3.381897561524418, 50.998253819074101],
              [-3.381405910830035, 50.999213769173871],
              [-3.382396583125205, 51.004541958205408],
              [-3.380854453625707, 51.006703177084106],
              [-3.380181442320732, 51.007031274154961],
              [-3.38047526761262, 51.008142005572246],
              [-3.380127940485968, 51.011566957038532],
              [-3.38083230514076, 51.013395853210099],
              [-3.378883773604627, 51.015614002919811],
              [-3.379066843492693, 51.01659474960281],
              [-3.380474701487294, 51.018490878202932],
              [-3.389890319619542, 51.019781196301182],
              [-3.399765169143763, 51.019732527787873],
              [-3.402913619144585, 51.02012191387999],
              [-3.407520864046284, 51.019527823436022],
              [-3.414160418107275, 51.019707523111258],
              [-3.412951175690432, 51.021389416417151],
              [-3.415755532961176, 51.023627935612815],
              [-3.41598398789735, 51.024480379105441],
              [-3.415347554402735, 51.025260570709136],
              [-3.415607655549192, 51.025654000138736],
              [-3.41504598097123, 51.027203963937467],
              [-3.415688554506644, 51.027840959854956],
              [-3.413876559092393, 51.028746893216194],
              [-3.414324143134526, 51.03042671870908],
              [-3.413762700883358, 51.031518940543748],
              [-3.409045217184052, 51.032417656888327],
              [-3.407128504707819, 51.033219531411959],
              [-3.406162074614857, 51.034791409703757],
              [-3.405084651545696, 51.035336743577943],
              [-3.403110015278628, 51.037248951990875],
              [-3.401583234049463, 51.037795152522854],
              [-3.400344861963288, 51.037264143385279],
              [-3.398558576815247, 51.037245972749041],
              [-3.394572364592583, 51.039013933779771],
              [-3.397051717325358, 51.040969007964755],
              [-3.392330020054275, 51.048572065662746],
              [-3.385827276613437, 51.046228537867741],
              [-3.381296360601211, 51.043252547473919],
              [-3.378953012698233, 51.042304519334465],
              [-3.377984787708616, 51.042412165097716],
              [-3.375885799842289, 51.042052913886629],
              [-3.370380407019616, 51.043962022745824],
              [-3.363857289000973, 51.045186719489386],
              [-3.359545627983779, 51.045457292184473],
              [-3.357492489817306, 51.044860669323299],
              [-3.355664990194714, 51.044954729240366],
              [-3.355279468986681, 51.044307231660298],
              [-3.352859179038614, 51.045059193380943],
              [-3.353479164701267, 51.046850550687559],
              [-3.350703178774795, 51.050043603783827],
              [-3.351205153240681, 51.050428082120078],
              [-3.351009928127091, 51.050779255647001],
              [-3.350129699095074, 51.050977377256501],
              [-3.349992476595292, 51.051998732558403],
              [-3.350700058916416, 51.05258407174977],
              [-3.350108976632168, 51.052913740262042],
              [-3.350733300715625, 51.053285116271461],
              [-3.351069630883434, 51.054791097966444],
              [-3.3518199539236, 51.05517629874754],
              [-3.351755817588735, 51.056109581016599],
              [-3.352838151412814, 51.056380323996649],
              [-3.35339777719254, 51.056977251718983],
              [-3.353003428645473, 51.057301957820457],
              [-3.354556891498914, 51.058117580251661],
              [-3.355203577846682, 51.057687426092699],
              [-3.356124140359028, 51.057986992937487],
              [-3.35670901403219, 51.058859686928081],
              [-3.357918784165532, 51.058995811812032],
              [-3.359234142612312, 51.060300642828039],
              [-3.359311295377817, 51.061424278967316],
              [-3.360001191152667, 51.061640618573847],
              [-3.359877243309604, 51.062572802524635],
              [-3.360300663596335, 51.06314250573331],
              [-3.358948291658585, 51.064183405313521],
              [-3.359021218621089, 51.064626794910744],
              [-3.358357699368534, 51.065263996436457],
              [-3.355207765773393, 51.065502005789284],
              [-3.347738399391478, 51.067236746240241],
              [-3.345216596574971, 51.0673386443734],
              [-3.34399334942382, 51.067778072072166],
              [-3.342611708384338, 51.067627590512956],
              [-3.339144759973446, 51.068164665733235],
              [-3.337364022231853, 51.067735426705113],
              [-3.334880398617856, 51.068521004699925],
              [-3.333903169449773, 51.068337922646819],
              [-3.333565922771421, 51.067926311745182],
              [-3.330868962876705, 51.067745731293655],
              [-3.322723617125469, 51.071145703920472],
              [-3.322208412860491, 51.071949180431552],
              [-3.314045807861724, 51.072016970579931],
              [-3.312223789413153, 51.071639071151196],
              [-3.311757406055682, 51.071096656680361],
              [-3.310292506821611, 51.071889154112043],
              [-3.305172483774113, 51.072850207712115],
              [-3.300565951273107, 51.071310772444448],
              [-3.298648089336393, 51.071430120570909],
              [-3.296451520396769, 51.071076820495179],
              [-3.295078135065637, 51.069890636312827],
              [-3.293005594655544, 51.069323671578942],
              [-3.286497959112034, 51.066520559372158],
              [-3.287960066633674, 51.064899272454134],
              [-3.287774784414936, 51.061565953849261],
              [-3.285832296597432, 51.061401197986008],
              [-3.283947998947353, 51.062049602051964],
              [-3.279279360358227, 51.061815741232223],
              [-3.274842373826665, 51.062272496944864],
              [-3.268251876131621, 51.061477325736604],
              [-3.268045305144394, 51.061698987073328],
              [-3.269795651906564, 51.063135881949215],
              [-3.271831665310719, 51.063484234865456],
              [-3.274534687190703, 51.066137277130174],
              [-3.277453289397869, 51.067589186931201],
              [-3.277058752816538, 51.069225655925081],
              [-3.27741455963667, 51.069396223426686],
              [-3.277523611339846, 51.070920175668576],
              [-3.278058460230262, 51.070957491880456],
              [-3.277508487624623, 51.072081284955324],
              [-3.281829017404805, 51.073317217011699],
              [-3.28478811251175, 51.074557174342992],
              [-3.287840322962062, 51.076420117120897],
              [-3.290005231144449, 51.078451047879206],
              [-3.290707195893128, 51.07869329138822],
              [-3.292184179639722, 51.082362134072689],
              [-3.294942984820194, 51.084630104423951],
              [-3.293743058854813, 51.085477905507844],
              [-3.292246337291326, 51.085770536507077],
              [-3.292718126953489, 51.086253616379672],
              [-3.289005497629127, 51.088397986621914],
              [-3.283463742989114, 51.09267109484351],
              [-3.277974625739274, 51.091587388357041],
              [-3.274036844317491, 51.08890563164654],
              [-3.273568419299415, 51.087395489338839],
              [-3.270649319970571, 51.085966869059142],
              [-3.267606482826916, 51.084862351537183],
              [-3.262887454401197, 51.085008760362904],
              [-3.260078829436532, 51.086103794947299],
              [-3.258137777135361, 51.087446612401408],
              [-3.257927576481963, 51.089908338767245],
              [-3.257017196631499, 51.092511585068593],
              [-3.254321104618694, 51.096098000396069],
              [-3.25099445251875, 51.098085932793538],
              [-3.249575049216728, 51.098569626874934],
              [-3.24876580837165, 51.097317522027005],
              [-3.245692247981111, 51.096725337913583],
              [-3.241848133059433, 51.094074778402991],
              [-3.240311069625717, 51.094643241029452],
              [-3.23543233442554, 51.0945708139866],
              [-3.231382888859022, 51.095933649182605],
              [-3.232559332350407, 51.096673923567138],
              [-3.232194475424947, 51.097238904007526],
              [-3.225376423687761, 51.103444375415918],
              [-3.223490652714572, 51.104565711331944],
              [-3.220651134230973, 51.105055814274209],
              [-3.221234831582345, 51.105795196049009],
              [-3.218013177779752, 51.107944745313851],
              [-3.211796644783723, 51.11007942701869],
              [-3.208806438937057, 51.112148084254969],
              [-3.205762097383995, 51.113519405235962],
              [-3.20487745780954, 51.114825242149649],
              [-3.197726633715781, 51.118072169525483],
              [-3.194323640178704, 51.114983001696935],
              [-3.193701237992707, 51.114004683275567],
              [-3.193864364314033, 51.112518360071093],
              [-3.193546008153922, 51.112360645684511],
              [-3.190507052150626, 51.111791836536874],
              [-3.186316376645267, 51.111710344110428],
              [-3.183189370402862, 51.110261875881335],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000191",
        LAD13CDO: "40UF",
        LAD13NM: "West Somerset",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-3.716071811172211, 51.231730028934237],
            [-3.712800461159449, 51.231580126424532],
            [-3.709190656992618, 51.231959354171721],
            [-3.706994567946964, 51.231681166713308],
            [-3.703998508148774, 51.230801287668598],
            [-3.700695533674831, 51.231227941800782],
            [-3.69840045925382, 51.231059844269076],
            [-3.693895610500592, 51.229144955251229],
            [-3.6865344330521, 51.227911386893666],
            [-3.674197088552074, 51.226686623732185],
            [-3.671610350483028, 51.226720914358275],
            [-3.666870871889711, 51.225879374506782],
            [-3.665168769133762, 51.225860448300658],
            [-3.664440003736293, 51.226183764742316],
            [-3.661947673868607, 51.225339710333252],
            [-3.658882756283543, 51.225018105999546],
            [-3.656857734686051, 51.225138527865866],
            [-3.656171222390517, 51.224645556693581],
            [-3.655019165353727, 51.224468517060366],
            [-3.649667233266543, 51.224506336788131],
            [-3.646421454901724, 51.223968434428123],
            [-3.645963886018045, 51.224188898902952],
            [-3.643081169135886, 51.223227635729209],
            [-3.640913183857595, 51.222910946593906],
            [-3.636513745991001, 51.222988757719321],
            [-3.633711589356867, 51.223687984541606],
            [-3.631967422859062, 51.223732111759361],
            [-3.630644618870967, 51.223096789441762],
            [-3.627853826489875, 51.220549362338097],
            [-3.627092175536073, 51.220107626498098],
            [-3.626357731041249, 51.220152910454878],
            [-3.626781352597925, 51.219699185326405],
            [-3.627914464009012, 51.219467598977545],
            [-3.628659508534079, 51.220290850149347],
            [-3.629340917320234, 51.220446827087336],
            [-3.630669239040279, 51.222294299729548],
            [-3.629431668473749, 51.220340348807305],
            [-3.628745491145832, 51.220170949777156],
            [-3.627803470108997, 51.219081559090633],
            [-3.626151700502005, 51.218939065651014],
            [-3.625248866728913, 51.219162939699878],
            [-3.625107025804857, 51.218307008995318],
            [-3.62445235798112, 51.217729779050558],
            [-3.622725793564215, 51.216924616687486],
            [-3.621699637906773, 51.216776078851389],
            [-3.61726546931535, 51.21703170507827],
            [-3.611301627615879, 51.219737159882541],
            [-3.609908515005709, 51.220071082332794],
            [-3.605936759672864, 51.220457500381208],
            [-3.601555508802026, 51.220476194893266],
            [-3.592161136028318, 51.222702407729422],
            [-3.587098078574012, 51.224970800288872],
            [-3.581603972503987, 51.22854962765701],
            [-3.578686127278042, 51.231049415279202],
            [-3.579059762374838, 51.231922052183215],
            [-3.578782365427558, 51.232145215411244],
            [-3.576984491760769, 51.231760298116981],
            [-3.574781525654614, 51.232011188326119],
            [-3.572405960764175, 51.231485596969371],
            [-3.570488611050519, 51.231880046140056],
            [-3.567628312321656, 51.231377050301994],
            [-3.56350005514528, 51.231410708054746],
            [-3.560992788320648, 51.232151903819243],
            [-3.560028463445577, 51.232099119718136],
            [-3.559147256471012, 51.232598264567237],
            [-3.557342176583975, 51.232504502109322],
            [-3.555376735375439, 51.233261744158078],
            [-3.548460077363786, 51.232332829117361],
            [-3.542918235234623, 51.232373668666952],
            [-3.533555977546825, 51.230638890961302],
            [-3.528808086120689, 51.228959166245346],
            [-3.520850629297479, 51.227224794945464],
            [-3.51800776873336, 51.226312100220056],
            [-3.515219527336602, 51.225968757922246],
            [-3.512734556907096, 51.22605037362306],
            [-3.507864997533652, 51.223852505764448],
            [-3.502964165602237, 51.223010016632919],
            [-3.498205965304745, 51.224040443458165],
            [-3.496883881342378, 51.223988123697545],
            [-3.493034840086723, 51.22262907408237],
            [-3.489589201212642, 51.220362810449359],
            [-3.486204973304512, 51.219859104376795],
            [-3.48398879130792, 51.219295527470273],
            [-3.479275596817732, 51.217130463096566],
            [-3.475845035215618, 51.214629800678551],
            [-3.473094045075423, 51.214075462038565],
            [-3.47240320539047, 51.213648920048634],
            [-3.472652047820527, 51.213460541410171],
            [-3.473805458352436, 51.213980173284412],
            [-3.474735719523819, 51.213564684738351],
            [-3.473407972735714, 51.211441189206951],
            [-3.473051726562383, 51.20938548283236],
            [-3.471188421973768, 51.208239000458441],
            [-3.469204428384777, 51.207750460538918],
            [-3.467150300360826, 51.207260968246679],
            [-3.463716504132987, 51.207142118442945],
            [-3.455008276810001, 51.208911677926388],
            [-3.453211393807047, 51.208677726449416],
            [-3.449479169073228, 51.208904780686787],
            [-3.444031838380156, 51.207484786010802],
            [-3.441981251045145, 51.206503817242712],
            [-3.437461470191253, 51.202261016678563],
            [-3.43569731733986, 51.198741423729466],
            [-3.433210571674987, 51.196878091796137],
            [-3.432872537423382, 51.196869643597338],
            [-3.433021971222909, 51.197211327736518],
            [-3.432615536855294, 51.197074224266956],
            [-3.433105277173942, 51.19629846419371],
            [-3.432925678572389, 51.195860929531364],
            [-3.426517661779047, 51.192415980498566],
            [-3.424264394616882, 51.1908148915415],
            [-3.422935737526057, 51.18995788766609],
            [-3.41840427427793, 51.18839247304917],
            [-3.415181440826314, 51.185534117479087],
            [-3.411630518229282, 51.183865749239743],
            [-3.407762102702962, 51.182944778253926],
            [-3.403587909463601, 51.182503054654156],
            [-3.393107479990187, 51.182506059652567],
            [-3.383800174277019, 51.183030205281149],
            [-3.377098509962239, 51.184189303897703],
            [-3.376127758533825, 51.184599996775837],
            [-3.37361143892661, 51.18466644528511],
            [-3.364567545472753, 51.184199486858837],
            [-3.355994455211092, 51.182558247879633],
            [-3.352658214778851, 51.181543859256692],
            [-3.350228067955171, 51.181518883701514],
            [-3.345837516349686, 51.18138604139282],
            [-3.336431605258488, 51.183308578502285],
            [-3.334719313151185, 51.183371295143559],
            [-3.334384083180062, 51.183059484396651],
            [-3.332578946201703, 51.182928991545317],
            [-3.330472144449151, 51.183650792052369],
            [-3.328845386642981, 51.184051465646199],
            [-3.330611644144463, 51.183520471601518],
            [-3.331246823199728, 51.182927075766216],
            [-3.328130033468412, 51.181863629957796],
            [-3.327157001387659, 51.182855753583596],
            [-3.32844568739106, 51.183636057867858],
            [-3.326909503782809, 51.182856763211298],
            [-3.325643767578589, 51.182774896732646],
            [-3.325601507580969, 51.182997489647683],
            [-3.324705771463786, 51.182546324769035],
            [-3.322659308720288, 51.182630634228211],
            [-3.319791479986984, 51.182261067281381],
            [-3.320085450113546, 51.181082434559308],
            [-3.318975007355569, 51.180424124724304],
            [-3.313204784803739, 51.179562831649811],
            [-3.311685782743334, 51.179374849154598],
            [-3.307844738603643, 51.179588720539627],
            [-3.304265075243325, 51.180548622584332],
            [-3.302591035087924, 51.181458430779976],
            [-3.302241519978961, 51.181291466286716],
            [-3.300053318673162, 51.181727663854446],
            [-3.29892538411712, 51.181608006911006],
            [-3.297682248561538, 51.182327713426545],
            [-3.295501828400544, 51.182330303239603],
            [-3.293943657765315, 51.182685667762499],
            [-3.288688412168732, 51.181660100029177],
            [-3.285812597090721, 51.181865305217151],
            [-3.284130041926361, 51.180630244688778],
            [-3.282362821170094, 51.180155949468464],
            [-3.280161602829968, 51.180488504137578],
            [-3.278060160050986, 51.179567280792696],
            [-3.275836992800293, 51.179778596077448],
            [-3.268958425970903, 51.182077256028968],
            [-3.266050225519101, 51.183349726716628],
            [-3.261956450341573, 51.185854293458469],
            [-3.256893412091226, 51.18772527735733],
            [-3.255935125857925, 51.187794021706573],
            [-3.253905114099665, 51.187337405688162],
            [-3.250276258590057, 51.187335786199917],
            [-3.243719113971435, 51.188692506019791],
            [-3.237084834662043, 51.191083797131512],
            [-3.233126852516353, 51.190986208699918],
            [-3.228964622792282, 51.191816847973108],
            [-3.225949276030042, 51.19305257008606],
            [-3.221555675876191, 51.193101233972463],
            [-3.2196600939488, 51.194154238325098],
            [-3.218666570299161, 51.193979350727844],
            [-3.216261051258343, 51.194312826198882],
            [-3.206510492307112, 51.19978044459311],
            [-3.202173105945529, 51.201463506614282],
            [-3.199672302273924, 51.201564716107647],
            [-3.198601144810955, 51.20204149997214],
            [-3.195702640523406, 51.202429058302464],
            [-3.190113549436929, 51.202668598176025],
            [-3.188168392210825, 51.202394322038742],
            [-3.185311504592803, 51.201507885037358],
            [-3.183044476398616, 51.200343845943635],
            [-3.180420372826486, 51.199764271858548],
            [-3.172504522945514, 51.202044306196349],
            [-3.164277943557213, 51.202648028287634],
            [-3.15909203580649, 51.205555487010493],
            [-3.156298122911085, 51.208197200683088],
            [-3.154812323768709, 51.208603941695294],
            [-3.150295782955522, 51.209065682162382],
            [-3.144609765894177, 51.209074693581805],
            [-3.138052350280474, 51.209565802821182],
            [-3.131432424121206, 51.210841275994909],
            [-3.125747135119217, 51.210888928898441],
            [-3.125606771163508, 51.211179830995398],
            [-3.125265514918986, 51.210688538422154],
            [-3.123101281902767, 51.210057395170445],
            [-3.120776480062919, 51.20847097164576],
            [-3.114438700719032, 51.206977653263984],
            [-3.106715309988877, 51.207737984641291],
            [-3.103742373676249, 51.208411717101598],
            [-3.100737397596605, 51.208405859570874],
            [-3.097761816369871, 51.207468949236578],
            [-3.094264920261244, 51.206818299028505],
            [-3.092865943869703, 51.206076026233283],
            [-3.090236091777609, 51.205703106851935],
            [-3.086266927795726, 51.204377702544932],
            [-3.085662585982706, 51.203696303628746],
            [-3.083695279369293, 51.2028800627563],
            [-3.076370242213259, 51.20137854219049],
            [-3.075386936920241, 51.201379492843493],
            [-3.075494550408144, 51.201636581474069],
            [-3.074989036534452, 51.201810282592817],
            [-3.07170643084206, 51.201849394629484],
            [-3.067157682825362, 51.202595972701893],
            [-3.065092149197307, 51.202655262651149],
            [-3.061141513308936, 51.203649740342932],
            [-3.059262379956024, 51.203725220677327],
            [-3.058949672684737, 51.204195648827245],
            [-3.057889128550204, 51.20432663976473],
            [-3.054547452940717, 51.205673276385461],
            [-3.053707382555527, 51.204553218742305],
            [-3.051587757588838, 51.20331068000052],
            [-3.05256523212765, 51.203177795946573],
            [-3.056077159478617, 51.201645336943805],
            [-3.0569022513726, 51.201985885295066],
            [-3.058799061284249, 51.200312359093829],
            [-3.059188543610432, 51.199415002856909],
            [-3.067464930866761, 51.19446416335159],
            [-3.070126913491781, 51.195394819444452],
            [-3.07912619197239, 51.196628612379534],
            [-3.081456833187615, 51.19751261022769],
            [-3.083587635553712, 51.194939084475557],
            [-3.085486055590362, 51.196066200006968],
            [-3.08502892449567, 51.194762064575031],
            [-3.085609622809689, 51.193286438589247],
            [-3.088055072376276, 51.1918168580928],
            [-3.088375105924359, 51.191248265846447],
            [-3.092937254353201, 51.190403614654137],
            [-3.096472209974611, 51.190118746866929],
            [-3.09828759160157, 51.18810992143483],
            [-3.09682015239671, 51.1845231741734],
            [-3.099086307023657, 51.184331946832181],
            [-3.103544468132422, 51.183303512868505],
            [-3.106229302627873, 51.183272755073247],
            [-3.10725636684499, 51.182964491819639],
            [-3.107281292318183, 51.18095986920185],
            [-3.106350609860755, 51.179376137380977],
            [-3.106053487262145, 51.177602065602244],
            [-3.107900291137899, 51.17765742370684],
            [-3.108850524854787, 51.177968545157839],
            [-3.112684886851307, 51.17786288849706],
            [-3.112372602374308, 51.176716640075597],
            [-3.114317039053813, 51.176075861335512],
            [-3.116339372594122, 51.175937876920628],
            [-3.116517765771441, 51.174499199780421],
            [-3.115464470564019, 51.173473333986223],
            [-3.114438643771411, 51.17346602809549],
            [-3.114361947070108, 51.17230225198135],
            [-3.112787310607577, 51.170531367833952],
            [-3.113630137933663, 51.169951432762964],
            [-3.119025229899941, 51.1697048009528],
            [-3.120311987748954, 51.169327392595314],
            [-3.123221895463871, 51.169527886527227],
            [-3.124809603364298, 51.168940713811139],
            [-3.127304064418344, 51.168762926480788],
            [-3.128005144537409, 51.16894770343881],
            [-3.131333561874207, 51.168130520486301],
            [-3.136491596310936, 51.16767855073445],
            [-3.134278381278821, 51.166490566181139],
            [-3.133304995008143, 51.163948871906726],
            [-3.135272287325864, 51.162990988081724],
            [-3.13813082141764, 51.162454235172596],
            [-3.140674322522769, 51.162367405949183],
            [-3.142574825569666, 51.162998101562067],
            [-3.144009050402115, 51.163047026501104],
            [-3.152178616791117, 51.161862558436809],
            [-3.156654606172031, 51.162708618118806],
            [-3.157529686339097, 51.163672934576233],
            [-3.158255981231022, 51.16217930369767],
            [-3.157668212188273, 51.16160871242883],
            [-3.158974809489232, 51.159533817894896],
            [-3.161580901900765, 51.15921390941201],
            [-3.161757948878549, 51.156584575691674],
            [-3.163318556879015, 51.154104246756219],
            [-3.165983973119168, 51.152747724604225],
            [-3.167407167583026, 51.152824348068798],
            [-3.169447973231954, 51.152445159282934],
            [-3.172891444152516, 51.152575231580741],
            [-3.17364120102678, 51.152314123116184],
            [-3.173704877842319, 51.151555424484513],
            [-3.174659792208747, 51.150421785189636],
            [-3.175277245395077, 51.150526187418009],
            [-3.175742728823506, 51.148870502044197],
            [-3.177419338874148, 51.148852732388676],
            [-3.177356599932496, 51.147455044318214],
            [-3.178888302875624, 51.146633891427008],
            [-3.180617313998442, 51.146487855004814],
            [-3.185122038464674, 51.146930572647371],
            [-3.186827917172889, 51.146774787163054],
            [-3.189995919669782, 51.14554749608137],
            [-3.192507055288734, 51.143634402541473],
            [-3.193799689919075, 51.14309245721644],
            [-3.196236847767397, 51.142918274232088],
            [-3.196681762032327, 51.143152020984928],
            [-3.198297801695152, 51.142680453240253],
            [-3.20172417236808, 51.142727119228603],
            [-3.204533897610059, 51.14214966913535],
            [-3.205457393875035, 51.142809189190238],
            [-3.205774571829256, 51.14137612097246],
            [-3.212590390895936, 51.135812147128838],
            [-3.213859999221929, 51.134245975837288],
            [-3.215538826822595, 51.133135950146482],
            [-3.216458264063327, 51.130379190388744],
            [-3.215646590950007, 51.129402958036714],
            [-3.208443256713697, 51.125987661070567],
            [-3.20538339958134, 51.123230686138342],
            [-3.199427517009942, 51.119696755567418],
            [-3.197726633715781, 51.118072169525483],
            [-3.20487745780954, 51.114825242149649],
            [-3.205762097383995, 51.113519405235962],
            [-3.208806438937057, 51.112148084254969],
            [-3.211796644783723, 51.11007942701869],
            [-3.218013177779752, 51.107944745313851],
            [-3.221234831582345, 51.105795196049009],
            [-3.220651134230973, 51.105055814274209],
            [-3.223490652714572, 51.104565711331944],
            [-3.225376423687761, 51.103444375415918],
            [-3.232194475424947, 51.097238904007526],
            [-3.232559332350407, 51.096673923567138],
            [-3.231382888859022, 51.095933649182605],
            [-3.23543233442554, 51.0945708139866],
            [-3.240311069625717, 51.094643241029452],
            [-3.241848133059433, 51.094074778402991],
            [-3.245692247981111, 51.096725337913583],
            [-3.24876580837165, 51.097317522027005],
            [-3.249575049216728, 51.098569626874934],
            [-3.25099445251875, 51.098085932793538],
            [-3.254321104618694, 51.096098000396069],
            [-3.257017196631499, 51.092511585068593],
            [-3.257927576481963, 51.089908338767245],
            [-3.258137777135361, 51.087446612401408],
            [-3.260078829436532, 51.086103794947299],
            [-3.262887454401197, 51.085008760362904],
            [-3.267606482826916, 51.084862351537183],
            [-3.270649319970571, 51.085966869059142],
            [-3.273568419299415, 51.087395489338839],
            [-3.274036844317491, 51.08890563164654],
            [-3.277974625739274, 51.091587388357041],
            [-3.283463742989114, 51.09267109484351],
            [-3.289005497629127, 51.088397986621914],
            [-3.292718126953489, 51.086253616379672],
            [-3.292246337291326, 51.085770536507077],
            [-3.293743058854813, 51.085477905507844],
            [-3.294942984820194, 51.084630104423951],
            [-3.292184179639722, 51.082362134072689],
            [-3.290707195893128, 51.07869329138822],
            [-3.290005231144449, 51.078451047879206],
            [-3.287840322962062, 51.076420117120897],
            [-3.28478811251175, 51.074557174342992],
            [-3.281829017404805, 51.073317217011699],
            [-3.277508487624623, 51.072081284955324],
            [-3.278058460230262, 51.070957491880456],
            [-3.277523611339846, 51.070920175668576],
            [-3.27741455963667, 51.069396223426686],
            [-3.277058752816538, 51.069225655925081],
            [-3.277453289397869, 51.067589186931201],
            [-3.274534687190703, 51.066137277130174],
            [-3.271831665310719, 51.063484234865456],
            [-3.269795651906564, 51.063135881949215],
            [-3.268045305144394, 51.061698987073328],
            [-3.268251876131621, 51.061477325736604],
            [-3.274842373826665, 51.062272496944864],
            [-3.279279360358227, 51.061815741232223],
            [-3.283947998947353, 51.062049602051964],
            [-3.285832296597432, 51.061401197986008],
            [-3.287774784414936, 51.061565953849261],
            [-3.287960066633674, 51.064899272454134],
            [-3.286497959112034, 51.066520559372158],
            [-3.293005594655544, 51.069323671578942],
            [-3.295078135065637, 51.069890636312827],
            [-3.296451520396769, 51.071076820495179],
            [-3.298648089336393, 51.071430120570909],
            [-3.300565951273107, 51.071310772444448],
            [-3.305172483774113, 51.072850207712115],
            [-3.310292506821611, 51.071889154112043],
            [-3.311757406055682, 51.071096656680361],
            [-3.312223789413153, 51.071639071151196],
            [-3.314045807861724, 51.072016970579931],
            [-3.322208412860491, 51.071949180431552],
            [-3.322723617125469, 51.071145703920472],
            [-3.330868962876705, 51.067745731293655],
            [-3.333565922771421, 51.067926311745182],
            [-3.333903169449773, 51.068337922646819],
            [-3.334880398617856, 51.068521004699925],
            [-3.337364022231853, 51.067735426705113],
            [-3.339144759973446, 51.068164665733235],
            [-3.342611708384338, 51.067627590512956],
            [-3.34399334942382, 51.067778072072166],
            [-3.345216596574971, 51.0673386443734],
            [-3.347738399391478, 51.067236746240241],
            [-3.355207765773393, 51.065502005789284],
            [-3.358357699368534, 51.065263996436457],
            [-3.359021218621089, 51.064626794910744],
            [-3.358948291658585, 51.064183405313521],
            [-3.360300663596335, 51.06314250573331],
            [-3.359877243309604, 51.062572802524635],
            [-3.360001191152667, 51.061640618573847],
            [-3.359311295377817, 51.061424278967316],
            [-3.359234142612312, 51.060300642828039],
            [-3.357918784165532, 51.058995811812032],
            [-3.35670901403219, 51.058859686928081],
            [-3.356124140359028, 51.057986992937487],
            [-3.355203577846682, 51.057687426092699],
            [-3.354556891498914, 51.058117580251661],
            [-3.353003428645473, 51.057301957820457],
            [-3.35339777719254, 51.056977251718983],
            [-3.352838151412814, 51.056380323996649],
            [-3.351755817588735, 51.056109581016599],
            [-3.3518199539236, 51.05517629874754],
            [-3.351069630883434, 51.054791097966444],
            [-3.350733300715625, 51.053285116271461],
            [-3.350108976632168, 51.052913740262042],
            [-3.350700058916416, 51.05258407174977],
            [-3.349992476595292, 51.051998732558403],
            [-3.350129699095074, 51.050977377256501],
            [-3.351009928127091, 51.050779255647001],
            [-3.351205153240681, 51.050428082120078],
            [-3.350703178774795, 51.050043603783827],
            [-3.353479164701267, 51.046850550687559],
            [-3.352859179038614, 51.045059193380943],
            [-3.355279468986681, 51.044307231660298],
            [-3.355664990194714, 51.044954729240366],
            [-3.357492489817306, 51.044860669323299],
            [-3.359545627983779, 51.045457292184473],
            [-3.363857289000973, 51.045186719489386],
            [-3.370380407019616, 51.043962022745824],
            [-3.375885799842289, 51.042052913886629],
            [-3.377984787708616, 51.042412165097716],
            [-3.378953012698233, 51.042304519334465],
            [-3.381296360601211, 51.043252547473919],
            [-3.385827276613437, 51.046228537867741],
            [-3.392330020054275, 51.048572065662746],
            [-3.397051717325358, 51.040969007964755],
            [-3.394572364592583, 51.039013933779771],
            [-3.398558576815247, 51.037245972749041],
            [-3.400344861963288, 51.037264143385279],
            [-3.401583234049463, 51.037795152522854],
            [-3.403110015278628, 51.037248951990875],
            [-3.405084651545696, 51.035336743577943],
            [-3.406162074614857, 51.034791409703757],
            [-3.407128504707819, 51.033219531411959],
            [-3.409045217184052, 51.032417656888327],
            [-3.413762700883358, 51.031518940543748],
            [-3.414324143134526, 51.03042671870908],
            [-3.413876559092393, 51.028746893216194],
            [-3.415688554506644, 51.027840959854956],
            [-3.417807571294242, 51.028680365212139],
            [-3.418733597801586, 51.029631348573076],
            [-3.421290131498389, 51.030782821487421],
            [-3.424245349364009, 51.031025605858105],
            [-3.425371843635226, 51.03148578555745],
            [-3.426801350909922, 51.031409882152246],
            [-3.429259151701312, 51.030640632346383],
            [-3.430838064714235, 51.030925260138801],
            [-3.436767415006946, 51.030265258386095],
            [-3.440216292778638, 51.029133764298642],
            [-3.445432627625917, 51.028072937358637],
            [-3.446392146943849, 51.027425270785493],
            [-3.44642210115342, 51.025868247658508],
            [-3.447210627430448, 51.025729884572208],
            [-3.450477539783512, 51.025594042457278],
            [-3.454295345496254, 51.026703948808816],
            [-3.459737056084431, 51.025255681347438],
            [-3.461824479059322, 51.025567707778194],
            [-3.463674443451329, 51.02526666520474],
            [-3.468510982636393, 51.028054886849276],
            [-3.472444162544517, 51.029185233909658],
            [-3.475324347742286, 51.029664183420913],
            [-3.478265641964643, 51.031568548874631],
            [-3.48367619202811, 51.033838089855898],
            [-3.484991627998371, 51.033718858132538],
            [-3.488511438921686, 51.032425860169845],
            [-3.495577237958853, 51.031025361474761],
            [-3.502946924536983, 51.030347144890143],
            [-3.505034852884161, 51.029910190667685],
            [-3.509734981037778, 51.027049171222899],
            [-3.513080851085104, 51.025880876140647],
            [-3.514834158406088, 51.025555093828864],
            [-3.519547642413456, 51.025401248990157],
            [-3.519922050628522, 51.025905373649167],
            [-3.520413037878832, 51.025875602817536],
            [-3.520477577566651, 51.024100472150465],
            [-3.51935563839086, 51.022538622133418],
            [-3.520975183673669, 51.020832283526765],
            [-3.520315700466396, 51.018935280796441],
            [-3.521724782906973, 51.017903436459576],
            [-3.520549180055054, 51.016658844928763],
            [-3.520685285970771, 51.015851311974856],
            [-3.51886600473262, 51.014800334804313],
            [-3.523016522962752, 51.012988183400985],
            [-3.522605414763579, 51.010795683893932],
            [-3.523220126072998, 51.009804747237574],
            [-3.526260771974415, 51.009764154944676],
            [-3.526678571476334, 51.009507793274992],
            [-3.527308850321185, 51.007648817348397],
            [-3.52702662791879, 51.006086845554343],
            [-3.527548446959131, 51.005526959318765],
            [-3.535033865583729, 51.003352360128737],
            [-3.534018084060383, 51.00422992364792],
            [-3.535402472654377, 51.004587635877456],
            [-3.541977164247421, 51.004233930810265],
            [-3.548114851060957, 51.004756162129489],
            [-3.550027901879264, 51.004464599115352],
            [-3.554293058873652, 51.00461025790316],
            [-3.55960981437232, 51.005256118493371],
            [-3.563460489315256, 51.004941143422329],
            [-3.569169424245581, 51.005039045502443],
            [-3.570967764652338, 51.0047306941429],
            [-3.576472169128357, 51.005354378827242],
            [-3.576526747473262, 51.006249338332701],
            [-3.578234884400453, 51.005593166756611],
            [-3.591630235990908, 51.006170395506373],
            [-3.603191255536685, 51.007238016645076],
            [-3.609728520881484, 51.007988043806193],
            [-3.610167845393464, 51.008696029188684],
            [-3.610091601489025, 51.009992965651087],
            [-3.610671488776778, 51.010725090860042],
            [-3.612068737609003, 51.011718421540813],
            [-3.61460826531617, 51.012721131206526],
            [-3.614195664169976, 51.013770916403708],
            [-3.614637625618556, 51.015495052392836],
            [-3.611905888300294, 51.0187378697293],
            [-3.610880621600061, 51.021654036741381],
            [-3.611188505932257, 51.023666007792755],
            [-3.610725405106268, 51.024497946217707],
            [-3.60268186012805, 51.029960272362352],
            [-3.6024442781756, 51.032664105716655],
            [-3.598152890947425, 51.036975640828793],
            [-3.598133257185774, 51.040508322936411],
            [-3.597496623009978, 51.041697798103456],
            [-3.598265499093823, 51.042734055708749],
            [-3.599155128912813, 51.046405373122745],
            [-3.599931653749333, 51.047494572917493],
            [-3.600314205825987, 51.05064583911193],
            [-3.599809907955749, 51.051744482289131],
            [-3.597535882059813, 51.053534606163737],
            [-3.596595494998712, 51.053739008757155],
            [-3.596001932527699, 51.054900905014804],
            [-3.594756353570455, 51.054944891344938],
            [-3.594455420223627, 51.055198101188942],
            [-3.596858932714443, 51.055921578190045],
            [-3.601412802938671, 51.055401513763272],
            [-3.602942905292606, 51.055787010541707],
            [-3.603815191349464, 51.057079908057062],
            [-3.604829466721964, 51.057566884988979],
            [-3.607569135889898, 51.057316991118412],
            [-3.610313730682874, 51.057950066806207],
            [-3.611205702458395, 51.0575834503462],
            [-3.612396958886009, 51.056220781112557],
            [-3.613036660847659, 51.056110331753047],
            [-3.614676506322949, 51.056570599877539],
            [-3.61933401548864, 51.059964811079489],
            [-3.621293578846728, 51.060666061658587],
            [-3.624287739652043, 51.061242289285595],
            [-3.628074074745574, 51.059786700143455],
            [-3.631262902839192, 51.059526394156045],
            [-3.633639140748763, 51.057020144318088],
            [-3.635337528087387, 51.056300333834116],
            [-3.638056013563213, 51.056629164177451],
            [-3.639953215827117, 51.057822889679073],
            [-3.642431592219331, 51.058540784805423],
            [-3.644504634428605, 51.057885718489054],
            [-3.645394878151952, 51.057880379486399],
            [-3.64881978453696, 51.058567719796706],
            [-3.649808956704404, 51.059417976914467],
            [-3.652314338743831, 51.059645167536544],
            [-3.652272838746867, 51.060443426944701],
            [-3.650839654367142, 51.061875573710552],
            [-3.650240735563231, 51.064095393059247],
            [-3.65314361998753, 51.06473692169655],
            [-3.653301390039611, 51.064999081319563],
            [-3.656298837469341, 51.065128395923786],
            [-3.658320825168876, 51.065637493739402],
            [-3.659254342981983, 51.066239357369113],
            [-3.659919055254549, 51.066148980159397],
            [-3.664316497542823, 51.067187162488736],
            [-3.664751245789068, 51.067648601635277],
            [-3.66483700018822, 51.06944146859734],
            [-3.666090902294818, 51.070176304663882],
            [-3.670665280590839, 51.070603805060323],
            [-3.673107873287622, 51.071286492787806],
            [-3.676050124877005, 51.072877444445353],
            [-3.677636008819089, 51.072601086339247],
            [-3.677932720391382, 51.072832440968433],
            [-3.679694193861387, 51.072714506901015],
            [-3.682404523827424, 51.073333799006647],
            [-3.68419224859142, 51.07476400294545],
            [-3.685552214616264, 51.075144579940471],
            [-3.687210407731684, 51.076188111576684],
            [-3.688664856599826, 51.078455804988636],
            [-3.692390502246746, 51.080595285173601],
            [-3.694304014635284, 51.080645796108044],
            [-3.694999154184957, 51.081110542587879],
            [-3.696448261257972, 51.08099329055662],
            [-3.697873132129561, 51.080448308664813],
            [-3.701322967609485, 51.080774938753066],
            [-3.704296432451985, 51.080414160110706],
            [-3.706089730160868, 51.0806505866667],
            [-3.707713915129065, 51.080403837888547],
            [-3.709224498092776, 51.080677621423007],
            [-3.714899444901381, 51.080211379800133],
            [-3.718437594486134, 51.080452574466669],
            [-3.719937370664968, 51.080818106944683],
            [-3.722434320761651, 51.082891079220175],
            [-3.730030905496386, 51.086779835550466],
            [-3.738612551382828, 51.088685790226428],
            [-3.741326718755202, 51.089854934626629],
            [-3.745473271978922, 51.092539530824475],
            [-3.75085641356679, 51.09513082063858],
            [-3.757532560461661, 51.100542408756461],
            [-3.767377956221906, 51.105786132275206],
            [-3.776891467598858, 51.106937777040116],
            [-3.780103753746729, 51.107707259432452],
            [-3.787017158093824, 51.110170161688572],
            [-3.791419530284454, 51.111373481117106],
            [-3.797483388559518, 51.114078107415288],
            [-3.804271137300985, 51.115687593789531],
            [-3.807158708883843, 51.117920912749497],
            [-3.8137097092696, 51.121050634219841],
            [-3.816390971952746, 51.122667310507751],
            [-3.821505760079923, 51.126381869360095],
            [-3.825089003640359, 51.131068889344199],
            [-3.831029338098883, 51.135231485086869],
            [-3.834473307020289, 51.138382598461881],
            [-3.83408358510103, 51.140199003664399],
            [-3.834719557656349, 51.141384193894204],
            [-3.834454263775401, 51.144860551491547],
            [-3.832051333677804, 51.151187023920961],
            [-3.835333042716855, 51.154108634597492],
            [-3.836444972324252, 51.165039219332684],
            [-3.837478347750824, 51.167359357255847],
            [-3.838905050656474, 51.174960260446476],
            [-3.83980306802249, 51.176674581067282],
            [-3.838429343020533, 51.177099069542784],
            [-3.832262910667492, 51.177563659952639],
            [-3.824341214801627, 51.176116443346046],
            [-3.817501832102508, 51.178531425121946],
            [-3.815552145357885, 51.178505054802571],
            [-3.811872463613855, 51.177633155205847],
            [-3.806250682226124, 51.177874799755408],
            [-3.795973293002361, 51.174545638099303],
            [-3.793364369395639, 51.172752022988497],
            [-3.786287116152894, 51.171928679713339],
            [-3.782093563685925, 51.172281334584369],
            [-3.777897811718797, 51.17339646930855],
            [-3.757233537856292, 51.177350921184726],
            [-3.751130414624766, 51.177128617702174],
            [-3.745859169700291, 51.175559040294843],
            [-3.742099396710775, 51.175973924508149],
            [-3.739572897992757, 51.17711405717224],
            [-3.738523662684245, 51.177061314337408],
            [-3.738083159785735, 51.177331352440561],
            [-3.735476487221905, 51.176950998202699],
            [-3.734223675208376, 51.177191706507607],
            [-3.73371577892142, 51.176962725469316],
            [-3.732835557227336, 51.177067498431398],
            [-3.730169557884758, 51.178043125682741],
            [-3.72873196228456, 51.178126436000404],
            [-3.728074638760382, 51.178721584300241],
            [-3.726016703606535, 51.178936328419418],
            [-3.725278598910168, 51.179361789149404],
            [-3.723810157924369, 51.180728770024764],
            [-3.725074236799827, 51.182471825346624],
            [-3.724405809306985, 51.183922333215456],
            [-3.725986314073488, 51.186356756716982],
            [-3.729434610208621, 51.188297726514712],
            [-3.732072050548826, 51.190970931532931],
            [-3.732005539394784, 51.193623898341599],
            [-3.728984840580662, 51.194631721401521],
            [-3.726992620022802, 51.197464212413216],
            [-3.726195686433043, 51.199424716918529],
            [-3.727052771859925, 51.201215121420937],
            [-3.726356962595632, 51.202742478166442],
            [-3.727971763655689, 51.204581042296454],
            [-3.728468735932989, 51.205772439270795],
            [-3.728447783380636, 51.207047927055619],
            [-3.727719266917126, 51.208429191102553],
            [-3.729396157519659, 51.211535700636432],
            [-3.730807992129042, 51.213028305639149],
            [-3.731557016753669, 51.215132324002951],
            [-3.730871263313036, 51.216012974064768],
            [-3.729030510046517, 51.216756021041718],
            [-3.728721007767661, 51.218852315384417],
            [-3.733185332826269, 51.222725994952611],
            [-3.729237078899998, 51.223910317405917],
            [-3.728287817596127, 51.225101498241266],
            [-3.726684129118576, 51.225645859733277],
            [-3.727302064682938, 51.227040508386949],
            [-3.727089272035413, 51.229389861510136],
            [-3.723102923555328, 51.230532268265833],
            [-3.722495368535665, 51.231024125676115],
            [-3.722007088274624, 51.232409003091583],
            [-3.720764943222556, 51.233092742432135],
            [-3.717832533772585, 51.231931706213985],
            [-3.716071811172211, 51.231730028934237],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000192",
        LAD13CDO: "41UB",
        LAD13NM: "Cannock Chase",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.94660616738645, 52.774179822783346],
            [-1.944693331418338, 52.773518221848391],
            [-1.943948933007133, 52.773742611944328],
            [-1.94281441352538, 52.77303459859457],
            [-1.937391644625538, 52.771377802781181],
            [-1.93250731887604, 52.767792813200138],
            [-1.931637652576413, 52.767583760360836],
            [-1.930409662991277, 52.768098148663306],
            [-1.929716108284251, 52.768052793781997],
            [-1.928813463244478, 52.767165890939424],
            [-1.92859735165014, 52.766089715797058],
            [-1.925486090894208, 52.763743341466835],
            [-1.923003069481282, 52.76247513708158],
            [-1.917831155585017, 52.760903905238372],
            [-1.913120769124396, 52.759922498648649],
            [-1.911379928508157, 52.759035745825855],
            [-1.911751836824453, 52.758312364671575],
            [-1.914632620812984, 52.755925047258614],
            [-1.917204314508654, 52.754638663221847],
            [-1.912346002261039, 52.752403080297668],
            [-1.910812109081003, 52.751173968479904],
            [-1.911513597156432, 52.750816708299517],
            [-1.91384363416916, 52.747253162965009],
            [-1.913227713340394, 52.747080115976871],
            [-1.913871392623642, 52.74594340516154],
            [-1.912343683172906, 52.743195979989139],
            [-1.913146428463596, 52.741751946492727],
            [-1.914127319832216, 52.741585452667962],
            [-1.913926221541679, 52.74140911196158],
            [-1.914910143773358, 52.740438947014617],
            [-1.916200159089405, 52.738934110651648],
            [-1.918741273924712, 52.739197469420844],
            [-1.919493014358737, 52.737951127692419],
            [-1.9198986264648, 52.738063770981192],
            [-1.925745381643674, 52.734914920904878],
            [-1.927100708971491, 52.732996485098219],
            [-1.92716552127512, 52.73142245029927],
            [-1.933928422582446, 52.727630096728248],
            [-1.934502866256226, 52.723716349217788],
            [-1.933430385798831, 52.721132146566489],
            [-1.935867746147382, 52.720838625695237],
            [-1.935546388131668, 52.71989005022845],
            [-1.935832398384259, 52.718686498102919],
            [-1.937707115708537, 52.716255807705068],
            [-1.937391964730516, 52.714063977984274],
            [-1.933950903837975, 52.71367466708962],
            [-1.93247940704411, 52.713748455323497],
            [-1.930547870078686, 52.714550115512154],
            [-1.927612767570995, 52.714355090247395],
            [-1.92406297598979, 52.713534824274291],
            [-1.919028696136443, 52.710279964358612],
            [-1.917179725746263, 52.711987615794385],
            [-1.915517121713725, 52.710621824287209],
            [-1.916625315441892, 52.709299335757159],
            [-1.921247266385961, 52.70790280282214],
            [-1.9256332425502, 52.707590089550699],
            [-1.926429854764495, 52.70650374052456],
            [-1.929416014587782, 52.70498900107647],
            [-1.930161481536525, 52.704334097812904],
            [-1.930122240135379, 52.702953269483899],
            [-1.931497703844643, 52.701597535426195],
            [-1.932286372701882, 52.699738932817141],
            [-1.933264763040157, 52.698602297868675],
            [-1.93414130280814, 52.698252190648631],
            [-1.934213030379904, 52.697784769747173],
            [-1.932627564540443, 52.696296998520985],
            [-1.933554382660864, 52.695939732257131],
            [-1.936598577606487, 52.695576413502422],
            [-1.941324387067708, 52.69683558604396],
            [-1.942753702638989, 52.696889320405774],
            [-1.946634960356798, 52.695659543167139],
            [-1.949638669557324, 52.695651860788288],
            [-1.947508905779491, 52.693624678957335],
            [-1.948167671253404, 52.690816605518521],
            [-1.949209549639572, 52.689260947833368],
            [-1.95078365034474, 52.687958110467605],
            [-1.953666908909006, 52.686630596900159],
            [-1.954593690996133, 52.68450849838689],
            [-1.959256724120408, 52.684132617189562],
            [-1.958027658489337, 52.679259800734954],
            [-1.958316920230148, 52.676811119923336],
            [-1.962005867200429, 52.678208445136505],
            [-1.963232048563882, 52.674616561689852],
            [-1.962477960792878, 52.674412261760921],
            [-1.962692951057929, 52.673768669364954],
            [-1.96047820407286, 52.673022713508836],
            [-1.959494294950294, 52.671899574497573],
            [-1.959618145467128, 52.67069230433188],
            [-1.961029954099861, 52.667861926967184],
            [-1.954576320093504, 52.662624955818764],
            [-1.959951359544571, 52.662020977524165],
            [-1.956331000961266, 52.657003463238048],
            [-1.955815897629538, 52.656164535522358],
            [-1.95630961319177, 52.65620876693449],
            [-1.955080297498289, 52.654120903910979],
            [-1.9554725699387, 52.653590659195224],
            [-1.96056351706605, 52.653694933771092],
            [-1.960777368501356, 52.649260387098572],
            [-1.95996447788826, 52.649196290210391],
            [-1.960286810363384, 52.644146881428313],
            [-1.960969321835857, 52.642798650398369],
            [-1.963021284441064, 52.643754010985624],
            [-1.963998324647959, 52.64176129107512],
            [-1.964573989854509, 52.640779786766451],
            [-1.967396438061744, 52.640990050955658],
            [-1.967449220210095, 52.641559114097582],
            [-1.969485677529926, 52.641546166894656],
            [-1.971052222610677, 52.641474638756456],
            [-1.973334521736218, 52.640585187749906],
            [-1.975171005175376, 52.641329930843497],
            [-1.97621227619409, 52.63968052985085],
            [-1.978733166394957, 52.640052278650003],
            [-1.981322334035939, 52.639813582897943],
            [-1.982836481910927, 52.641283626508525],
            [-1.984774465367116, 52.639636971692092],
            [-1.986556421696227, 52.640393218173074],
            [-1.985122564612026, 52.641596772431029],
            [-1.983743020820905, 52.643593208725356],
            [-1.988355387439436, 52.644177173458694],
            [-1.989746008981836, 52.644609703737395],
            [-1.991541691206898, 52.644635911660643],
            [-1.991683490682093, 52.645082709761709],
            [-1.992889458957886, 52.645320113794185],
            [-1.992787358762851, 52.646085131708766],
            [-1.994260667411941, 52.647998217632626],
            [-1.99363232575331, 52.64923427069894],
            [-1.994131788486637, 52.650258222015694],
            [-1.993326119204531, 52.650891954823216],
            [-1.993457363392459, 52.653060276275653],
            [-1.994461053062228, 52.653222139677737],
            [-1.994125429310929, 52.653748021284464],
            [-1.995000491775407, 52.654262269855678],
            [-1.996267288868794, 52.655138808556934],
            [-1.996734365377827, 52.656045880971341],
            [-1.997754372320343, 52.656755188815225],
            [-1.997652315859141, 52.657825858767424],
            [-2.000314902326283, 52.659995985477906],
            [-2.000748118270813, 52.661290496738005],
            [-2.000431736392782, 52.662292848333308],
            [-2.001070463848996, 52.662400719012801],
            [-2.000783692728665, 52.665079645637356],
            [-2.001710831972809, 52.666036135153149],
            [-2.001298311793805, 52.666721154387744],
            [-2.002259502112735, 52.667586843214089],
            [-2.005841198302493, 52.669511401120765],
            [-2.013514547879223, 52.669519747189327],
            [-2.015807013267425, 52.670524503362671],
            [-2.016799783740627, 52.671786513677475],
            [-2.0191382625964, 52.672732765603534],
            [-2.022258601904601, 52.672540733952395],
            [-2.022727254421737, 52.672253873698018],
            [-2.023916277175478, 52.67228240403486],
            [-2.025246961613619, 52.671776905966837],
            [-2.02644190434281, 52.67183147972429],
            [-2.028269196606717, 52.670961753286178],
            [-2.029813248866005, 52.671199597699136],
            [-2.031013915811428, 52.671000615334592],
            [-2.035514098347765, 52.671155762618362],
            [-2.04108127979845, 52.673649482317835],
            [-2.041656561786211, 52.675179320622426],
            [-2.042940994982863, 52.675881850814115],
            [-2.045795565504362, 52.676001237442897],
            [-2.047591252833487, 52.677530565888603],
            [-2.050049037504485, 52.677196031915351],
            [-2.051320422842605, 52.677957808371843],
            [-2.051914749867872, 52.678985964841665],
            [-2.051547333688322, 52.679727772462385],
            [-2.049500759157669, 52.68148972213833],
            [-2.049780522075325, 52.682957614157097],
            [-2.049149097787326, 52.683139469928349],
            [-2.049693597467552, 52.684560504939057],
            [-2.05087641860399, 52.685310635991819],
            [-2.051636245138131, 52.686105889311499],
            [-2.05165353272227, 52.686961695771217],
            [-2.052968416118494, 52.687957164961524],
            [-2.054638310251103, 52.690042898718325],
            [-2.052024058844467, 52.691251388777751],
            [-2.047670099565395, 52.694161375562921],
            [-2.044839564061205, 52.698418176077524],
            [-2.040783736879358, 52.701747607701357],
            [-2.039224432615192, 52.702267737254914],
            [-2.036529229700113, 52.703618844802584],
            [-2.031095999832004, 52.705087511504757],
            [-2.030628865910368, 52.712136386415892],
            [-2.02985303394411, 52.713994736735387],
            [-2.02625947412666, 52.724662645277014],
            [-2.027039005325755, 52.725809539215348],
            [-2.026040044938307, 52.726633210683978],
            [-2.025253328830932, 52.728377361059565],
            [-2.025795906967909, 52.73189935890889],
            [-2.025330999668653, 52.734581049217589],
            [-2.025697046610561, 52.734996287738674],
            [-2.026807114167203, 52.73630132476967],
            [-2.017911242385912, 52.741500786508283],
            [-2.017021451927223, 52.742550000883647],
            [-2.018027939540048, 52.744278541464212],
            [-2.016827497122068, 52.746561167866041],
            [-2.015473626433955, 52.750977020840359],
            [-2.015069195649225, 52.751052585973177],
            [-2.015164732296714, 52.75308690699746],
            [-2.014785582995256, 52.753494181882488],
            [-2.017793277501873, 52.757121040510825],
            [-2.018431162228169, 52.758844235231322],
            [-2.015703475258211, 52.759660883156528],
            [-2.012723516746152, 52.75962079391816],
            [-2.007337296425616, 52.760782708537427],
            [-2.003791188836735, 52.760638151323647],
            [-1.999385611719296, 52.762876612423547],
            [-1.996781805692239, 52.763627201502182],
            [-1.988342268239048, 52.762812240180295],
            [-1.986694315150089, 52.762968488215257],
            [-1.982702341944421, 52.762042959960148],
            [-1.977399895796727, 52.762420539230241],
            [-1.973135474475228, 52.761541382901726],
            [-1.972055465003711, 52.763427138498535],
            [-1.970541335039464, 52.765008931776812],
            [-1.967731483383045, 52.764968653165724],
            [-1.965758520139335, 52.765503880075798],
            [-1.959710723508425, 52.768372347673626],
            [-1.95594091305007, 52.769151306134923],
            [-1.950603862260393, 52.771646503351448],
            [-1.94915079325794, 52.773342213644533],
            [-1.948644599759762, 52.773968565615732],
            [-1.947716888994674, 52.773788372318947],
            [-1.94660616738645, 52.774179822783346],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000193",
        LAD13CDO: "41UC",
        LAD13NM: "East Staffordshire",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.780981445972499, 53.045915173317944],
            [-1.780109105857896, 53.044690147807259],
            [-1.778985247844911, 53.044826500196315],
            [-1.779659882, 53.043569273888771],
            [-1.778738057587773, 53.042995857269489],
            [-1.774438657408574, 53.043440844638788],
            [-1.77229866422445, 53.04422152279826],
            [-1.768197934805375, 53.044020346807812],
            [-1.767447676949263, 53.043466053556074],
            [-1.766004755523878, 53.041095493160483],
            [-1.760057066874329, 53.038899301949492],
            [-1.75904157885613, 53.037534499908084],
            [-1.759367814936996, 53.034650547301631],
            [-1.761547540837183, 53.033381171327456],
            [-1.759682999639778, 53.032943248147994],
            [-1.757016710258388, 53.031361143073596],
            [-1.756793209229691, 53.030800663452105],
            [-1.757418675370586, 53.030144833047352],
            [-1.757192243564941, 53.028778022039425],
            [-1.758988617643735, 53.026961369622484],
            [-1.762715595154991, 53.025139563126423],
            [-1.76373485729504, 53.021410193624767],
            [-1.762947962143383, 53.020550165559584],
            [-1.758315767829756, 53.018224348073311],
            [-1.757722661567884, 53.017126465275886],
            [-1.758972046256387, 53.014674060772805],
            [-1.759409426021446, 53.0145571873121],
            [-1.762026448225605, 53.015167417223829],
            [-1.762874069119159, 53.013355095020934],
            [-1.763560075370206, 53.011375246936716],
            [-1.764194092065594, 53.010447024238218],
            [-1.766071182392294, 53.009218311773274],
            [-1.766244233052023, 53.006977650929954],
            [-1.765712768271246, 53.004944153643216],
            [-1.764519101722691, 53.004122884587851],
            [-1.764104931620704, 53.002735933131241],
            [-1.764427674294831, 53.001753155687773],
            [-1.762653437631136, 53.000578341144262],
            [-1.762573583483594, 52.999651396910878],
            [-1.76781923811606, 52.999511623370346],
            [-1.771052628579238, 52.995838610769091],
            [-1.772293682704121, 52.995844586639002],
            [-1.773910780816781, 52.996308814026513],
            [-1.777624839248888, 52.996367047598575],
            [-1.779135479133551, 52.995808934238937],
            [-1.780445680774757, 52.994811759733018],
            [-1.784456650425499, 52.994759760173366],
            [-1.785999983055777, 52.993585859183462],
            [-1.785112759325849, 52.992841753884321],
            [-1.785355755425231, 52.99250869317197],
            [-1.786303878680594, 52.992382752202275],
            [-1.787178058483724, 52.993054911812841],
            [-1.789356178527921, 52.992742365795706],
            [-1.790396972226159, 52.991936074242261],
            [-1.789869661869329, 52.99161512946192],
            [-1.787656260763781, 52.991539288805043],
            [-1.787092882198907, 52.991287484133764],
            [-1.787122147317493, 52.990788636144202],
            [-1.788149488809205, 52.989990430223052],
            [-1.787525178073557, 52.989408615545926],
            [-1.788759359648513, 52.988953261617219],
            [-1.789963065235692, 52.988941008564424],
            [-1.791633208823429, 52.988272450206956],
            [-1.792212311218969, 52.989592177571815],
            [-1.793394968388883, 52.989623900090066],
            [-1.793412842176586, 52.988999181941843],
            [-1.794980151144307, 52.987700255332854],
            [-1.796023099692019, 52.986387825751578],
            [-1.796158305663323, 52.985510711273825],
            [-1.796782922194973, 52.985086589808247],
            [-1.798243615226871, 52.98552145471492],
            [-1.79883424734897, 52.986317998422905],
            [-1.802690590645274, 52.98536979859918],
            [-1.80471279677412, 52.985499880364472],
            [-1.804697550354016, 52.98458565357496],
            [-1.802622971992649, 52.983863096208729],
            [-1.801561547747483, 52.98308556350662],
            [-1.803227264934544, 52.981333635177641],
            [-1.806879863194855, 52.980197087690087],
            [-1.81528903179974, 52.979440055428697],
            [-1.821233956844679, 52.978475598200937],
            [-1.823526477567702, 52.97872172663579],
            [-1.824761099155937, 52.978356790323524],
            [-1.826115446047963, 52.977469743003169],
            [-1.828378793452937, 52.975295839693864],
            [-1.828370945150078, 52.973891713150849],
            [-1.829631120198361, 52.973041345252952],
            [-1.830188794048404, 52.971328797025024],
            [-1.829457422044032, 52.968360409842191],
            [-1.827557800465124, 52.966416001136842],
            [-1.828225489846641, 52.965494671600517],
            [-1.828344707239222, 52.963961281067753],
            [-1.825738520861042, 52.962302577413332],
            [-1.825548475491084, 52.961804295555332],
            [-1.826482973032359, 52.960420420141432],
            [-1.827693617232899, 52.961082887249901],
            [-1.828477313001491, 52.960921313749836],
            [-1.829165878327859, 52.959208056458237],
            [-1.829156082582587, 52.957914491008481],
            [-1.830557158250575, 52.95707240372078],
            [-1.831421199883473, 52.955748616163291],
            [-1.834480361328472, 52.954900728384239],
            [-1.834181948138015, 52.953918687687718],
            [-1.832286346538362, 52.952198187406999],
            [-1.830703988097315, 52.951483999218773],
            [-1.828858750106639, 52.948753123048739],
            [-1.82875853389495, 52.94774798032681],
            [-1.829699805977786, 52.946809953447762],
            [-1.829935514415117, 52.945523927735138],
            [-1.832463818862586, 52.945933827337349],
            [-1.835585648358173, 52.945246906026995],
            [-1.836469045717044, 52.943421505378566],
            [-1.836376875697722, 52.942606951675792],
            [-1.835343345236803, 52.94242843814105],
            [-1.832837187928303, 52.943656474041823],
            [-1.832339231354311, 52.943126306265306],
            [-1.833097915139796, 52.941252212214259],
            [-1.83552399911904, 52.940698254682751],
            [-1.837975978632628, 52.9415627887442],
            [-1.839461774396362, 52.941362543284313],
            [-1.840289313155269, 52.939689855356491],
            [-1.84113395298052, 52.939426701122017],
            [-1.841831595009527, 52.939893274038177],
            [-1.843039616048029, 52.93954069586372],
            [-1.845191619299664, 52.937337547623081],
            [-1.846538475493083, 52.936826004182542],
            [-1.84708508490204, 52.935820808231412],
            [-1.849281929814308, 52.93558988869718],
            [-1.850593743022682, 52.933989653955727],
            [-1.850755631799234, 52.933204194489754],
            [-1.850434623818135, 52.932671626565472],
            [-1.848881059541128, 52.932352343182203],
            [-1.849547455795155, 52.931091991724436],
            [-1.85006698303439, 52.930996461907768],
            [-1.852044109644026, 52.931866400774503],
            [-1.852955185845461, 52.931687744045938],
            [-1.854079747332469, 52.930841438903037],
            [-1.854302478698458, 52.930078521981997],
            [-1.854114164561185, 52.929451738610759],
            [-1.852711715571833, 52.928902564588221],
            [-1.852822267292859, 52.927885114658139],
            [-1.853171730821763, 52.927478331692441],
            [-1.854563845035127, 52.927547460147977],
            [-1.855659883835126, 52.927185627053021],
            [-1.855707530851444, 52.926723635651257],
            [-1.854634632428064, 52.925506979123952],
            [-1.854682561391094, 52.92407324641178],
            [-1.856397420894238, 52.923668112618941],
            [-1.856578370290403, 52.923373482094782],
            [-1.855731423024725, 52.922661405933837],
            [-1.855599252412053, 52.920807653829335],
            [-1.854571381846754, 52.9199578132556],
            [-1.851732545549591, 52.920276127916637],
            [-1.849946573399462, 52.921057756333852],
            [-1.84751059670152, 52.920133257972893],
            [-1.848193495997556, 52.9187893322649],
            [-1.848164772764678, 52.917218865312861],
            [-1.848955078754982, 52.916616688842907],
            [-1.84893437621457, 52.916154612543586],
            [-1.847605714197186, 52.91590930876329],
            [-1.847170587834049, 52.915312760160248],
            [-1.84781916768922, 52.914408368415316],
            [-1.847965469437806, 52.912970266690301],
            [-1.843578609832494, 52.913399664469082],
            [-1.842610592423924, 52.912971396255834],
            [-1.842312974841305, 52.911387985290752],
            [-1.842804956226381, 52.910624544862664],
            [-1.843674060615992, 52.910426126949602],
            [-1.846630865088055, 52.910643919534955],
            [-1.847581993229603, 52.909947570958806],
            [-1.847607648189358, 52.90899114114174],
            [-1.846919933926666, 52.908784403342814],
            [-1.845390904013869, 52.908921761170951],
            [-1.845326157203475, 52.908313100595095],
            [-1.84409604071857, 52.907195921673768],
            [-1.84260564358177, 52.906531447351483],
            [-1.840987469971459, 52.905041564204595],
            [-1.839960526580678, 52.904926922148789],
            [-1.838289374738792, 52.905330980146353],
            [-1.836152741755543, 52.904177424571472],
            [-1.836351494271858, 52.902326796755759],
            [-1.836004011981701, 52.901825613054555],
            [-1.835172015700791, 52.901683331102916],
            [-1.833941679364751, 52.902299187017221],
            [-1.833389364797967, 52.902107841294011],
            [-1.832514925880961, 52.899088896919373],
            [-1.83000204321855, 52.896571017432478],
            [-1.829899085888646, 52.896290403101368],
            [-1.830625740740253, 52.89596872268865],
            [-1.833127021656241, 52.896085522642672],
            [-1.834251563323303, 52.895493798487671],
            [-1.833989268750108, 52.894502808158698],
            [-1.832644211789881, 52.893303544257016],
            [-1.834023815684825, 52.892849717383704],
            [-1.835214165933774, 52.891339365171781],
            [-1.834688170492715, 52.890906248244796],
            [-1.833157437850743, 52.890866356319783],
            [-1.832711291998926, 52.890546608238537],
            [-1.833007547613681, 52.8900328340779],
            [-1.834089969890737, 52.889532743109363],
            [-1.832134473335198, 52.88865623591888],
            [-1.832118556192951, 52.888159102697706],
            [-1.83295956779882, 52.887431250542058],
            [-1.832719698934541, 52.886812446474167],
            [-1.829855419748569, 52.887309091253655],
            [-1.827957212642492, 52.887024994791254],
            [-1.827544341841083, 52.886578525358019],
            [-1.828353283571034, 52.88457327245883],
            [-1.8267120859698, 52.883968606571699],
            [-1.825866123853679, 52.884067153083869],
            [-1.82355758641069, 52.886068376688776],
            [-1.822648909956484, 52.88622793482422],
            [-1.822027064126431, 52.886021150020973],
            [-1.821773116094983, 52.885251281442542],
            [-1.823625562241767, 52.882862874645376],
            [-1.822846901735155, 52.882494052994957],
            [-1.818486546834326, 52.882590856981643],
            [-1.815813962782548, 52.882061770323389],
            [-1.813540186295813, 52.882456456232489],
            [-1.81198401856125, 52.881841831412984],
            [-1.812310063341068, 52.880665639354916],
            [-1.811056053665086, 52.880635786934782],
            [-1.804161074666977, 52.882989734178366],
            [-1.803002335242025, 52.883894846658059],
            [-1.802918161334427, 52.885746514158043],
            [-1.801381131057278, 52.886155669771462],
            [-1.79443542127623, 52.883850683647694],
            [-1.793665789558359, 52.883193127825798],
            [-1.79249633029728, 52.88068935428003],
            [-1.791155067312519, 52.880265405393672],
            [-1.788151803832454, 52.87999940190408],
            [-1.78697998347563, 52.880792867322796],
            [-1.786829247554043, 52.881533320663259],
            [-1.785602531022529, 52.882899296162421],
            [-1.783733851481968, 52.883946768724286],
            [-1.782514656554534, 52.883774646485122],
            [-1.780276216853566, 52.88239066501589],
            [-1.777224188549612, 52.883797215338433],
            [-1.77532859520541, 52.882239388645047],
            [-1.775389239707952, 52.881718120166845],
            [-1.776260898074893, 52.881259507947796],
            [-1.776225961023, 52.880540293071832],
            [-1.774235587399551, 52.879242062252473],
            [-1.773118262760854, 52.878943286571044],
            [-1.770688749668589, 52.878959301844837],
            [-1.768784496647346, 52.877996448691398],
            [-1.764658278605224, 52.878544795723201],
            [-1.758588489101104, 52.877203099197125],
            [-1.75600720517919, 52.875153646429908],
            [-1.754070304369316, 52.874533883564553],
            [-1.751596770200095, 52.87423478880519],
            [-1.750706952204335, 52.873692664717687],
            [-1.750518153852846, 52.872675569771609],
            [-1.752278957974156, 52.871060263822514],
            [-1.751847340846272, 52.870155930622488],
            [-1.752271076486954, 52.868790427663235],
            [-1.750652618937415, 52.868912895680367],
            [-1.747590261610733, 52.870561379460391],
            [-1.74606499270566, 52.869975619214763],
            [-1.745673084057932, 52.869163938784524],
            [-1.746007212029065, 52.868675630400297],
            [-1.746431655009625, 52.868237854284935],
            [-1.747400533326556, 52.868183283682768],
            [-1.749185936447971, 52.867185645800959],
            [-1.749749138784581, 52.867408870579588],
            [-1.749500543678609, 52.866724254460905],
            [-1.746788170683258, 52.865940925960928],
            [-1.744566151220602, 52.865905608506537],
            [-1.743176950174817, 52.866210052724917],
            [-1.74040394594824, 52.867647726092969],
            [-1.739788928418763, 52.867650876215954],
            [-1.739424741639745, 52.867194316377073],
            [-1.739660465196053, 52.865285485737388],
            [-1.739168793045474, 52.864789092314552],
            [-1.735542800676687, 52.8650525608953],
            [-1.734829769669682, 52.863838302900824],
            [-1.731219298730353, 52.862799110176447],
            [-1.729784994870802, 52.862713155938607],
            [-1.727233170670411, 52.863190058593638],
            [-1.726427584939263, 52.863037185396898],
            [-1.725728235035146, 52.862290352463447],
            [-1.725840652417519, 52.861656858934438],
            [-1.72647898885849, 52.861474945668206],
            [-1.727815162625222, 52.861807024867318],
            [-1.728086721661334, 52.861371660350066],
            [-1.727517874270984, 52.860419278847544],
            [-1.725326058305847, 52.859645640129493],
            [-1.7228950843728, 52.860290830765628],
            [-1.722348201351285, 52.861504023153429],
            [-1.721070364939008, 52.862043092512536],
            [-1.721202976475859, 52.862441634166167],
            [-1.722298633192901, 52.862988057729019],
            [-1.722173722265837, 52.863243064842436],
            [-1.72129524009989, 52.863110662038089],
            [-1.719859530138024, 52.861859558017237],
            [-1.717752674331716, 52.86196604255796],
            [-1.717587198253799, 52.861601578644787],
            [-1.71835083178891, 52.860883339181548],
            [-1.717875697098074, 52.860630509037669],
            [-1.716973243507277, 52.860768598362895],
            [-1.71626388287997, 52.862044301651288],
            [-1.71480037286558, 52.86234374208906],
            [-1.714418743840251, 52.862781508712253],
            [-1.715496827732874, 52.863505044078927],
            [-1.717324175796101, 52.863450972613123],
            [-1.717669380618943, 52.864503552945322],
            [-1.716533151670469, 52.865400687699719],
            [-1.714477863279056, 52.865997159460399],
            [-1.712064630458676, 52.865903242263364],
            [-1.710679567967354, 52.866444636711456],
            [-1.708067521099566, 52.866091259534841],
            [-1.705906423386083, 52.866716089205234],
            [-1.703465132731819, 52.866376517249236],
            [-1.70062442967078, 52.864289248781439],
            [-1.7000462629387, 52.863262100039535],
            [-1.701524011975038, 52.862205070127146],
            [-1.702253989743499, 52.862321965385824],
            [-1.702915698331331, 52.862866581265642],
            [-1.703567991121727, 52.862833151152145],
            [-1.703470461356627, 52.861232793448664],
            [-1.70061290106653, 52.860565807389101],
            [-1.698882791264328, 52.860748416577259],
            [-1.699228480692225, 52.862093208470895],
            [-1.698443407701185, 52.862641370989607],
            [-1.697540208021543, 52.862871901695236],
            [-1.694369588799439, 52.862788280082263],
            [-1.69175138691436, 52.864192855731993],
            [-1.68998960253842, 52.864417503502295],
            [-1.687930016226999, 52.863911403771425],
            [-1.687316158899222, 52.863346154555174],
            [-1.687369170800484, 52.862789849591231],
            [-1.686282866409853, 52.862449884394572],
            [-1.68496786629597, 52.862311565008376],
            [-1.682739572947022, 52.862761400962],
            [-1.679453009496297, 52.861484176482747],
            [-1.674621123502488, 52.861115964498346],
            [-1.672322953912595, 52.861371242450424],
            [-1.670280405632987, 52.86121097544909],
            [-1.668806313393523, 52.860129944577885],
            [-1.664791995635437, 52.858353167758679],
            [-1.662786829669352, 52.858182089709231],
            [-1.658685405871512, 52.856772525672625],
            [-1.654060641513012, 52.856965909816353],
            [-1.645967150708878, 52.855804012071424],
            [-1.642636373449219, 52.856309136699252],
            [-1.641914869408925, 52.855735236971832],
            [-1.639905232065549, 52.855411837323842],
            [-1.636929183573196, 52.853265993181601],
            [-1.635155619240098, 52.852965707475022],
            [-1.630736363580416, 52.852775863658906],
            [-1.629679533296649, 52.853765907739422],
            [-1.627296162882166, 52.854447047158587],
            [-1.62657082953935, 52.854346785083742],
            [-1.626161816836706, 52.852689644361483],
            [-1.628239123738817, 52.851843962631179],
            [-1.625496460857545, 52.849763283540867],
            [-1.622665674167883, 52.849486442296339],
            [-1.6226985284436, 52.84792238280459],
            [-1.620716414462981, 52.847394684188778],
            [-1.619086422887758, 52.845997902823562],
            [-1.617582939745025, 52.844747135023745],
            [-1.614726043818266, 52.845114563657582],
            [-1.612675923599578, 52.844579318152824],
            [-1.609204497590849, 52.842940858824221],
            [-1.608362089001215, 52.842842796696786],
            [-1.607062855110688, 52.843353602977082],
            [-1.604506482045343, 52.842181884611072],
            [-1.602990836303744, 52.840859873200294],
            [-1.602985461601523, 52.839820673021123],
            [-1.601872106880523, 52.837214496130443],
            [-1.601000606638985, 52.837065044016207],
            [-1.599397556349003, 52.837853419625546],
            [-1.59890869856847, 52.837742098656996],
            [-1.597527591620726, 52.837317619545161],
            [-1.594183831869263, 52.835164052877595],
            [-1.593217800348163, 52.835448412227592],
            [-1.592440205111485, 52.836375258860059],
            [-1.591290996395693, 52.836243660233293],
            [-1.591110139186976, 52.835426793475051],
            [-1.591953648169766, 52.834595470270962],
            [-1.591406606900691, 52.833413271494308],
            [-1.591899888741273, 52.832252627290117],
            [-1.589608170481963, 52.830987103725917],
            [-1.587980845292757, 52.831519041823675],
            [-1.586466531271527, 52.831399613524304],
            [-1.58535643433786, 52.831696891666631],
            [-1.585787100129739, 52.830736519264519],
            [-1.588627220102151, 52.828927815453646],
            [-1.590366816760388, 52.828598519086903],
            [-1.593694723696577, 52.828716923165807],
            [-1.597599610721824, 52.828394907146638],
            [-1.600735959210244, 52.827390577055517],
            [-1.602871334256797, 52.82628303869582],
            [-1.604418760874033, 52.824865165943855],
            [-1.605517290383533, 52.82318868349212],
            [-1.607117261711716, 52.822010969031922],
            [-1.607604746071041, 52.819455069340584],
            [-1.610328519743058, 52.817032377025711],
            [-1.610344293138112, 52.815599502488666],
            [-1.607701159673453, 52.814841976716316],
            [-1.604963977448903, 52.814649518930082],
            [-1.60079748858348, 52.812732510084132],
            [-1.598911584539842, 52.811464029377738],
            [-1.596658198359992, 52.810961975256646],
            [-1.593443094143802, 52.809073103786368],
            [-1.59183122346843, 52.809473002397823],
            [-1.590581043088881, 52.806898596140094],
            [-1.589877079501138, 52.806354099185775],
            [-1.590911812503552, 52.802474193653929],
            [-1.588345282753397, 52.80236374340879],
            [-1.587981156519127, 52.802126056100072],
            [-1.588505890771595, 52.8016909843682],
            [-1.589313408544097, 52.802097409445167],
            [-1.589732516737806, 52.801694329290257],
            [-1.589297990181972, 52.801371901199836],
            [-1.590029004167373, 52.800764936948781],
            [-1.589059381727808, 52.800406499259502],
            [-1.588950844195528, 52.799804724024078],
            [-1.592704341758403, 52.798266962423249],
            [-1.592861040373553, 52.797368546442343],
            [-1.593517844437667, 52.796914128097789],
            [-1.593589615244089, 52.797966149150788],
            [-1.595708162658315, 52.797097799965123],
            [-1.596792694758389, 52.798019318470381],
            [-1.597854752765601, 52.798326765831014],
            [-1.603554039945799, 52.795437815093777],
            [-1.600859014447788, 52.794176545230044],
            [-1.601720717325047, 52.79319328621213],
            [-1.601479392201706, 52.792824804490714],
            [-1.602663184558739, 52.791967573542991],
            [-1.604136306247069, 52.791250633458191],
            [-1.606334364105444, 52.790714073412616],
            [-1.611729429784655, 52.789844568436024],
            [-1.614350325785204, 52.788256566605668],
            [-1.612396766103162, 52.787501377207391],
            [-1.613725543310907, 52.78613749849444],
            [-1.609421570746248, 52.784436081775276],
            [-1.611270562556269, 52.782760204557526],
            [-1.610308546828152, 52.782239253102155],
            [-1.611134540784548, 52.781386155686306],
            [-1.61172410226173, 52.781609227991567],
            [-1.612467533871488, 52.78120083412287],
            [-1.615414937018381, 52.782034758845896],
            [-1.615016654706725, 52.782478450938456],
            [-1.617747404173767, 52.783857280266716],
            [-1.619294395107971, 52.78515045204901],
            [-1.62288916594757, 52.782150424749894],
            [-1.623758506634035, 52.782431858448284],
            [-1.626963050625518, 52.779754992733139],
            [-1.638248126764871, 52.786710957943299],
            [-1.64193402669534, 52.784568235723761],
            [-1.644329239844535, 52.782481769834185],
            [-1.648490014178516, 52.779831456448548],
            [-1.649935444368911, 52.779464458160795],
            [-1.653245501434604, 52.779698009680736],
            [-1.656831484572183, 52.781045171796627],
            [-1.65876205359648, 52.783475213686565],
            [-1.662274958204996, 52.785131239797693],
            [-1.664014527126807, 52.78546878682009],
            [-1.665609432897546, 52.785145170439343],
            [-1.66969785916288, 52.782317717217751],
            [-1.670745215522348, 52.781053101019282],
            [-1.669739454234763, 52.777798791498554],
            [-1.67005301582692, 52.773577273451224],
            [-1.670184962761294, 52.773378072015042],
            [-1.672025902550392, 52.772400613175854],
            [-1.676353357208284, 52.772301915293156],
            [-1.678651995437256, 52.771945879232092],
            [-1.682072575560388, 52.770174256763788],
            [-1.683014790306602, 52.768194579337013],
            [-1.685185709515202, 52.766397050013573],
            [-1.6850125435251, 52.765336721977306],
            [-1.683557979928941, 52.764819550669827],
            [-1.683172301197108, 52.764255775764269],
            [-1.683933978074341, 52.761601390119822],
            [-1.685974012017344, 52.758995334225787],
            [-1.687917768607458, 52.757387730604499],
            [-1.690977458464653, 52.756986708021394],
            [-1.692757471564793, 52.756291039998594],
            [-1.696770163013883, 52.752843966417643],
            [-1.696581191443353, 52.751893285544426],
            [-1.693691100599368, 52.750772954830957],
            [-1.693865854879663, 52.750147730883221],
            [-1.695660073195957, 52.749067301451156],
            [-1.696058580437305, 52.748003056950594],
            [-1.695545549370809, 52.747004797709444],
            [-1.692543317509018, 52.744817985101506],
            [-1.692241700232194, 52.744105228208632],
            [-1.69317707938367, 52.7437975085356],
            [-1.696363226116154, 52.744239897337906],
            [-1.697625751511699, 52.743745995448599],
            [-1.697804922638042, 52.742255078810203],
            [-1.697108654084678, 52.740343016136421],
            [-1.699159131127715, 52.738973721100237],
            [-1.699769881896055, 52.738034056606281],
            [-1.697984151907708, 52.736035634113293],
            [-1.697892519013439, 52.735364776771007],
            [-1.702358985347552, 52.732571303686115],
            [-1.704164699705445, 52.732061612116546],
            [-1.707288962211205, 52.730575284670564],
            [-1.710801946568153, 52.729838656682588],
            [-1.711996721865278, 52.729875721892206],
            [-1.714301789398213, 52.730642711445718],
            [-1.717645945999011, 52.731267399404295],
            [-1.716935552236738, 52.731431116318824],
            [-1.717183157378118, 52.732301000978218],
            [-1.717941865021889, 52.732684864095333],
            [-1.717407159133177, 52.733387478154107],
            [-1.719974932548582, 52.733414238850884],
            [-1.720584545595345, 52.734193275487669],
            [-1.719824686562144, 52.734675124978821],
            [-1.721515378797628, 52.735000031596115],
            [-1.722303283085913, 52.735463942438457],
            [-1.721371427813214, 52.736208794424385],
            [-1.721935613008818, 52.737853414826589],
            [-1.723061457901277, 52.738052918435756],
            [-1.724357851855749, 52.737043707340554],
            [-1.724680713853638, 52.737751038441203],
            [-1.723149784918971, 52.738605984282955],
            [-1.723585516089752, 52.739264138587401],
            [-1.725597974914005, 52.739092610988187],
            [-1.725904633155491, 52.738609679432805],
            [-1.72555744439331, 52.737998484184729],
            [-1.726232688035886, 52.737783394019694],
            [-1.727239897010922, 52.738009553099516],
            [-1.727260634364973, 52.738484251105952],
            [-1.72805600092604, 52.738958927988143],
            [-1.728862014126703, 52.73843577942889],
            [-1.731087247926664, 52.738336561173021],
            [-1.730876946680323, 52.739056150726391],
            [-1.731255022180325, 52.739234102007408],
            [-1.731812242506059, 52.738941402462004],
            [-1.733998555014858, 52.73891305939835],
            [-1.733111494772421, 52.739862167036321],
            [-1.733950537813162, 52.740232623171337],
            [-1.734298016717895, 52.7400868710411],
            [-1.733815652476408, 52.73976216547976],
            [-1.734829153070928, 52.73920887689291],
            [-1.737094262579056, 52.739133007906382],
            [-1.740754559019995, 52.738312216570904],
            [-1.744242155205503, 52.738380909563666],
            [-1.744092814812926, 52.739350564118546],
            [-1.746466199263488, 52.739246881767734],
            [-1.749194666928773, 52.739725528609569],
            [-1.750609595220795, 52.739130701565337],
            [-1.754003527214215, 52.74032980021623],
            [-1.753343329734637, 52.740783303467794],
            [-1.756137948448312, 52.741648479648596],
            [-1.756953777048143, 52.743572124103224],
            [-1.758091257693393, 52.744133598459634],
            [-1.757463250851377, 52.74519039038497],
            [-1.757802871574288, 52.745654046603129],
            [-1.759223118327055, 52.746259236185232],
            [-1.760179390991747, 52.747196086680958],
            [-1.763816788013554, 52.748465512202877],
            [-1.767223579987791, 52.750792445709543],
            [-1.767710341519905, 52.750637879344225],
            [-1.767738712076689, 52.750038330886717],
            [-1.769785598694885, 52.750142100578586],
            [-1.769843589190617, 52.749820386565553],
            [-1.768965477266653, 52.749461795789891],
            [-1.769044222739105, 52.749138324588486],
            [-1.76961471998084, 52.749113362953167],
            [-1.768747311594945, 52.748134511138723],
            [-1.769100463937023, 52.748026424530586],
            [-1.770310937013072, 52.749127296926218],
            [-1.771918422768245, 52.748825648756061],
            [-1.772736474617861, 52.749055551998516],
            [-1.771801048009048, 52.74974235894026],
            [-1.772804766740664, 52.75073852908951],
            [-1.773933963655511, 52.750971715653925],
            [-1.77586404624407, 52.750751533764266],
            [-1.778524927494588, 52.748983783734069],
            [-1.778434168342407, 52.74818354313539],
            [-1.777623446800524, 52.74884006407008],
            [-1.777286780713928, 52.748622784683178],
            [-1.778488792346074, 52.746173577748706],
            [-1.779847260882351, 52.745868660512741],
            [-1.784568514890309, 52.745835067575918],
            [-1.786746926858836, 52.747186535201834],
            [-1.786200252912287, 52.749315180212271],
            [-1.790147892605614, 52.75157860048558],
            [-1.788931628611231, 52.752480794924452],
            [-1.789357936381187, 52.753176444491892],
            [-1.79053741863617, 52.753769144193157],
            [-1.793321856736289, 52.754298112865996],
            [-1.793892485526935, 52.753942219076876],
            [-1.794049170012328, 52.752762162199183],
            [-1.795257229879467, 52.75232556033513],
            [-1.798661191296973, 52.753765213065257],
            [-1.801358462834303, 52.754260583906593],
            [-1.801557815482783, 52.755060987282377],
            [-1.802465207060137, 52.755253977117292],
            [-1.803139717941438, 52.756163940426951],
            [-1.807862497637707, 52.757380767319511],
            [-1.811852530554642, 52.756420777248678],
            [-1.813195872843465, 52.756555043534028],
            [-1.814850578149831, 52.755593954311763],
            [-1.816699542286851, 52.755637274487675],
            [-1.81753122185585, 52.755173795091565],
            [-1.818980109885994, 52.757008980430946],
            [-1.820545774965254, 52.762914787775188],
            [-1.822501960178226, 52.767287538592356],
            [-1.823322641311416, 52.767018176846101],
            [-1.823494934472132, 52.769128275382982],
            [-1.826208569056972, 52.768799662769339],
            [-1.828424959299974, 52.769024925013369],
            [-1.826982259994922, 52.771416743754735],
            [-1.827203487097844, 52.771696639832285],
            [-1.825455117287237, 52.772643378157227],
            [-1.823528854017862, 52.774309887122108],
            [-1.821088731786722, 52.77544970508589],
            [-1.820279866196663, 52.776408564953734],
            [-1.820620016564916, 52.776964631027887],
            [-1.818389796402382, 52.77783951910358],
            [-1.814591222191561, 52.778319985570931],
            [-1.812390021606966, 52.779297286530294],
            [-1.809509153672256, 52.783125830487812],
            [-1.810004761343148, 52.783372037521019],
            [-1.810160259024401, 52.785118947105822],
            [-1.814186045067226, 52.790280788799777],
            [-1.817582267511406, 52.793010766049306],
            [-1.823305187168868, 52.794064889952267],
            [-1.827557351114828, 52.793207245393525],
            [-1.832133077190039, 52.79493348501299],
            [-1.835111783170518, 52.795508486751565],
            [-1.837262978026805, 52.795298400504393],
            [-1.838151856137601, 52.795953149666076],
            [-1.842360912861734, 52.795914757360656],
            [-1.84466195217438, 52.794016510216451],
            [-1.845155258007957, 52.793329458009794],
            [-1.844380772454426, 52.790104815366448],
            [-1.844954250807854, 52.787707167543758],
            [-1.847008605392694, 52.786658058152291],
            [-1.848586086138722, 52.784991626914668],
            [-1.849426585177139, 52.785044833631517],
            [-1.85168511067454, 52.783181452486687],
            [-1.852461920126897, 52.783206690798636],
            [-1.852731302654215, 52.78246179501874],
            [-1.853784445789843, 52.78231297032692],
            [-1.854213066060933, 52.783156712141576],
            [-1.857707728517109, 52.784030231188922],
            [-1.858068443245091, 52.784354284398077],
            [-1.859796728743918, 52.784510055586253],
            [-1.860278809429512, 52.784907060047168],
            [-1.860068548943522, 52.785282573812651],
            [-1.860584272395336, 52.785362287280506],
            [-1.860488750724264, 52.786023802279125],
            [-1.860996459486342, 52.78582932486232],
            [-1.860830607899616, 52.786228264523814],
            [-1.861394184549362, 52.786183975399069],
            [-1.861750997899555, 52.786818150349163],
            [-1.862432453381591, 52.787011315997667],
            [-1.861819203827503, 52.788225085612638],
            [-1.862582515437808, 52.789272347277212],
            [-1.86361233524671, 52.789511754571187],
            [-1.863717812397276, 52.78992449317478],
            [-1.864484759392139, 52.78982378850953],
            [-1.864870138728775, 52.790355506017143],
            [-1.866589048251885, 52.790753883564143],
            [-1.8670924230711, 52.79150596855537],
            [-1.868320235424056, 52.791517223004945],
            [-1.869073629089012, 52.791986407228499],
            [-1.870470540142154, 52.791987038100679],
            [-1.87171272070893, 52.793170502114187],
            [-1.872384453425684, 52.793187405938667],
            [-1.872442963914302, 52.793972250733624],
            [-1.873637773040576, 52.794651333352419],
            [-1.876407947340841, 52.795208895512083],
            [-1.876598034293032, 52.795642385909325],
            [-1.878227902940234, 52.796174445212081],
            [-1.882223045496939, 52.796261171607604],
            [-1.88338216381292, 52.797056983641546],
            [-1.883569529023097, 52.796871084981035],
            [-1.884221321718338, 52.797160283447276],
            [-1.8847088108977, 52.798455241660506],
            [-1.885697093170823, 52.79882746076148],
            [-1.886145585650346, 52.798604951866956],
            [-1.887332063363212, 52.799195791110463],
            [-1.887735818756502, 52.799065825436742],
            [-1.889883151867901, 52.800356923023607],
            [-1.892061400285843, 52.799391651879318],
            [-1.89261144019129, 52.798272959990989],
            [-1.894037803854577, 52.797219773299929],
            [-1.897253724674693, 52.795719553135001],
            [-1.901947020179761, 52.795305502383151],
            [-1.90282182637628, 52.796722961817053],
            [-1.904636229437776, 52.797811257323829],
            [-1.907659757515956, 52.798073437445488],
            [-1.909265269344176, 52.799133632259363],
            [-1.911371515335153, 52.799790551725067],
            [-1.912839219990582, 52.80011705431032],
            [-1.915346231760997, 52.79914080883821],
            [-1.916200149638657, 52.799341878198476],
            [-1.917863703587976, 52.800432561427016],
            [-1.918558544221395, 52.800057278832107],
            [-1.922912009512575, 52.801579397137814],
            [-1.926075247125321, 52.80189333709739],
            [-1.927038127535429, 52.802635561869337],
            [-1.928959597610417, 52.803168899682341],
            [-1.928868265721917, 52.803692032084122],
            [-1.930544041769838, 52.803928544843828],
            [-1.930491359936821, 52.804410349414091],
            [-1.931207938950746, 52.804331658034258],
            [-1.931286088739074, 52.804629254387166],
            [-1.93065673788052, 52.804887786306772],
            [-1.930784742121612, 52.805550384150187],
            [-1.933032650207223, 52.805169616612794],
            [-1.933314736701702, 52.805981523245094],
            [-1.936214251246091, 52.805325980801925],
            [-1.935844604799431, 52.806533067071996],
            [-1.938590128012734, 52.80673318032273],
            [-1.94178159100338, 52.807382921603235],
            [-1.948874948808189, 52.807792496717269],
            [-1.949818565384888, 52.807673337527824],
            [-1.949910835528261, 52.807414479894213],
            [-1.95133198052853, 52.807411472185805],
            [-1.95376141714157, 52.812055491585184],
            [-1.954866406696295, 52.812342677930957],
            [-1.954944578991796, 52.812789483151889],
            [-1.956333123036472, 52.812923042873152],
            [-1.957230408186633, 52.813244290940595],
            [-1.957009060482873, 52.813534570681874],
            [-1.958327683107064, 52.813899110689775],
            [-1.958254429764323, 52.814480702400772],
            [-1.959697147230747, 52.815470935911478],
            [-1.960360281120126, 52.817211514235872],
            [-1.962024250698148, 52.818172127013135],
            [-1.96259008898348, 52.819319358098397],
            [-1.964418862186867, 52.820325834541357],
            [-1.964452071264471, 52.821462109335577],
            [-1.965585439898335, 52.821908316849147],
            [-1.967889745661603, 52.824283965920145],
            [-1.96729542378358, 52.825242078410845],
            [-1.966639549495984, 52.825140316795313],
            [-1.960843889565768, 52.827300517323373],
            [-1.955831294985334, 52.830948476000131],
            [-1.954860871652635, 52.832122131623528],
            [-1.954393263103688, 52.832177688358684],
            [-1.953063068258415, 52.832430674523856],
            [-1.952120914672153, 52.834801709025584],
            [-1.950796488352248, 52.836442637753166],
            [-1.952269880176656, 52.837041031979794],
            [-1.948737030333643, 52.840710842334239],
            [-1.949212730238417, 52.841443682412581],
            [-1.948549481224979, 52.842391780672095],
            [-1.94876748925084, 52.842591439253532],
            [-1.95050036290042, 52.842332376675927],
            [-1.951149093014036, 52.843712517483034],
            [-1.949720351283061, 52.844127234648731],
            [-1.949245988553285, 52.844768877197836],
            [-1.94691092128935, 52.845665902457874],
            [-1.946291749052325, 52.846900768871571],
            [-1.936788864849402, 52.84807641926183],
            [-1.937474716046408, 52.8492130405673],
            [-1.938507709883386, 52.849551578982719],
            [-1.93818481804165, 52.850026951015529],
            [-1.939127352416321, 52.850297117670813],
            [-1.938532961106753, 52.850601553229545],
            [-1.939202879470688, 52.851510724224632],
            [-1.939876827100493, 52.85167017811402],
            [-1.938766546505876, 52.852397753899204],
            [-1.939080908341658, 52.852723330821675],
            [-1.937936005633115, 52.852705660637483],
            [-1.93776584761066, 52.853315951514006],
            [-1.934491143732057, 52.853485894697506],
            [-1.93408474861103, 52.85315486117841],
            [-1.934690904456687, 52.852971811212058],
            [-1.934125488920343, 52.852726988735583],
            [-1.933579856757759, 52.853148288388226],
            [-1.933363901793285, 52.854534331596838],
            [-1.934760212063571, 52.85727596744416],
            [-1.935577679801745, 52.857850833484278],
            [-1.937052047980755, 52.858129392811563],
            [-1.937640636914938, 52.858847953613868],
            [-1.936475476516953, 52.859373217384835],
            [-1.935969894354171, 52.860801359292694],
            [-1.93659119225485, 52.861510054211351],
            [-1.936043869959805, 52.862012268064177],
            [-1.936727533779682, 52.862746165801816],
            [-1.935562165066542, 52.863334345798656],
            [-1.935608923669619, 52.863858451538626],
            [-1.936797344277933, 52.863772789339727],
            [-1.93699699615161, 52.864377879773954],
            [-1.935207889587065, 52.864848863155878],
            [-1.935047675938934, 52.865706361995379],
            [-1.934327978907332, 52.866203079959803],
            [-1.934889151852918, 52.86642272832119],
            [-1.934987638967467, 52.867120357076786],
            [-1.937225894591742, 52.867375056458286],
            [-1.936817374788382, 52.868408617936844],
            [-1.937165910571499, 52.868799839491928],
            [-1.936571148116376, 52.869176179060652],
            [-1.936974622585725, 52.869589004806585],
            [-1.936054963508785, 52.870667238883314],
            [-1.944988866411429, 52.869896810077073],
            [-1.94643484326727, 52.869480361349559],
            [-1.950017696134895, 52.86976418131799],
            [-1.951358580399446, 52.870292410702852],
            [-1.952425789356789, 52.868416760326994],
            [-1.960541089693002, 52.870209498241714],
            [-1.964210096331261, 52.872511029836531],
            [-1.964990617004234, 52.873687068778082],
            [-1.965954468670733, 52.874165580620598],
            [-1.966339432345525, 52.873955338548974],
            [-1.967487652870549, 52.874275676957367],
            [-1.968380174972404, 52.874829660258463],
            [-1.970329753926474, 52.885397136645203],
            [-1.972123841923553, 52.887173860400516],
            [-1.973483305850547, 52.887801624287654],
            [-1.975981984360434, 52.889797782369627],
            [-1.9796633291824, 52.893262050218134],
            [-1.982014412960269, 52.894150569669755],
            [-1.985954633246873, 52.894913386899148],
            [-1.985945598237677, 52.895280150276655],
            [-1.98666073336694, 52.894764243930048],
            [-1.991282135303196, 52.89424777652431],
            [-1.993944378570014, 52.893615986547829],
            [-1.996649705801425, 52.892184986716664],
            [-1.999707104512568, 52.892251547844999],
            [-2.002732124728399, 52.896838762591287],
            [-2.007409070476382, 52.899235105201583],
            [-2.012425195719754, 52.900619929052311],
            [-2.016971964282106, 52.902288672123362],
            [-2.016523526892022, 52.903689271172716],
            [-2.01275254847656, 52.906564525146919],
            [-2.017463505667386, 52.908223341424538],
            [-2.022299302402105, 52.909066617095711],
            [-2.023617074634675, 52.909728871131065],
            [-2.023905479765492, 52.909619143327021],
            [-2.027204722428756, 52.911547530324626],
            [-2.032971413478521, 52.913619888210839],
            [-2.033110102286552, 52.914128643484709],
            [-2.034059316699226, 52.914699193314313],
            [-2.036936275279437, 52.915736590868654],
            [-2.040639966489576, 52.916479687028819],
            [-2.04057402703111, 52.915943947873778],
            [-2.042652833939833, 52.915709493588658],
            [-2.043543744972265, 52.917293980675012],
            [-2.041528751096995, 52.917460109436881],
            [-2.040573215494344, 52.916662192616798],
            [-2.03802046709577, 52.91749455197187],
            [-2.034963018288835, 52.917796638965875],
            [-2.034758107485595, 52.91819941971572],
            [-2.034176674397394, 52.91829217934503],
            [-2.032883360562636, 52.920997423663216],
            [-2.033886922282187, 52.922401265638555],
            [-2.033220476539788, 52.92226841391561],
            [-2.030836597802729, 52.922789541221],
            [-2.030311903144992, 52.923290380389609],
            [-2.025901180012663, 52.92481062181087],
            [-2.021904313503682, 52.924668505113658],
            [-2.019522653030575, 52.924116983781758],
            [-2.017282634972569, 52.924252174853791],
            [-2.015605096017089, 52.925044366212738],
            [-2.013298178993661, 52.925490520087607],
            [-2.009212452110243, 52.927223149724689],
            [-2.007297970156196, 52.927109123173402],
            [-2.005066763755383, 52.927886816469645],
            [-2.004202476503663, 52.927749316198792],
            [-2.003658081496671, 52.92831026610542],
            [-2.002546871582596, 52.928452328101301],
            [-2.001524942833351, 52.92940251398651],
            [-2.000790066895059, 52.929608377096315],
            [-2.001954893068701, 52.929989506159821],
            [-2.000984991790777, 52.93122914064201],
            [-1.993651861469995, 52.933910489791757],
            [-1.988337357289637, 52.934662500297698],
            [-1.985318294371213, 52.935654587019222],
            [-1.983958757698112, 52.93472582342271],
            [-1.980159267460731, 52.933971957897924],
            [-1.97969634931831, 52.934451907471008],
            [-1.978910893099208, 52.934254008606885],
            [-1.977408067897115, 52.934580046049717],
            [-1.976297750649278, 52.935367291740761],
            [-1.976697811285705, 52.935673004901645],
            [-1.976288405903285, 52.936140366461288],
            [-1.976272517609774, 52.938007432702435],
            [-1.975229115625212, 52.938676921876009],
            [-1.974022336873167, 52.938789032623049],
            [-1.97111806570946, 52.940774095483484],
            [-1.970301109349397, 52.940836820739399],
            [-1.969911551162407, 52.940401643672921],
            [-1.967957899703214, 52.940292367357458],
            [-1.967888118787843, 52.940082000092914],
            [-1.968699068765702, 52.940080416789151],
            [-1.968511939292185, 52.939585958675288],
            [-1.966781594950819, 52.939380536413282],
            [-1.966609245476474, 52.939048784903477],
            [-1.965225740988428, 52.938692416603764],
            [-1.965295939985864, 52.938357137629552],
            [-1.964449494841745, 52.938133955704288],
            [-1.964483932331579, 52.937868782777677],
            [-1.962257695278327, 52.936532297461689],
            [-1.961400665594921, 52.936558991882308],
            [-1.960912990784874, 52.936175891018962],
            [-1.959884686656632, 52.936396686490767],
            [-1.958990823224552, 52.936038610760306],
            [-1.958892954977335, 52.935688894849832],
            [-1.958060967886018, 52.935991544155769],
            [-1.957732850063549, 52.935274085451866],
            [-1.956634652996225, 52.93546516354305],
            [-1.95688056166032, 52.935041858158542],
            [-1.956344771619626, 52.935221449014854],
            [-1.955446455098228, 52.934905594462244],
            [-1.953802158479706, 52.935165658948506],
            [-1.952431361842232, 52.934280577576175],
            [-1.95224805003964, 52.934568160947983],
            [-1.951377629866097, 52.93462444231389],
            [-1.951667097107952, 52.933870360390451],
            [-1.95057997209282, 52.933488770803969],
            [-1.949813545646827, 52.933691608732332],
            [-1.947641791044524, 52.933378749021507],
            [-1.94661558437427, 52.933100526392089],
            [-1.9466992966703, 52.932774253371306],
            [-1.944529346404275, 52.932266270399651],
            [-1.944409764430543, 52.932709385713309],
            [-1.942617253561062, 52.932557517971098],
            [-1.942033550102315, 52.931827307355796],
            [-1.941109442139603, 52.932011135074987],
            [-1.940512139370718, 52.931458897751554],
            [-1.939483837152362, 52.931684011509645],
            [-1.937004149393518, 52.931480469827314],
            [-1.936746282923588, 52.931822824607416],
            [-1.935179660886056, 52.93187502058494],
            [-1.934367714172126, 52.931651642843804],
            [-1.932211453292022, 52.933947195263357],
            [-1.930477752911727, 52.937956308048619],
            [-1.928196965774302, 52.940557347411428],
            [-1.926895244784856, 52.941279292932492],
            [-1.923529160227473, 52.940535568578596],
            [-1.923524747056012, 52.942200375919334],
            [-1.920443620112568, 52.941855869626693],
            [-1.919134124590873, 52.941065732093577],
            [-1.914184522434711, 52.940548985671974],
            [-1.912584804787488, 52.939869134370511],
            [-1.910187840414648, 52.9397918427184],
            [-1.906216034997391, 52.9441243018588],
            [-1.903210553878009, 52.945228475896457],
            [-1.903551128620003, 52.945995535078474],
            [-1.908904114699947, 52.94884124765003],
            [-1.910908466381633, 52.949018052765581],
            [-1.917802789310425, 52.951861807944965],
            [-1.915922735063369, 52.953518112794484],
            [-1.91748704069457, 52.955895959481097],
            [-1.916980142971719, 52.957094771355528],
            [-1.917397976373147, 52.957333276165862],
            [-1.918427528984894, 52.957620741349096],
            [-1.923301526940768, 52.957404638101551],
            [-1.928032839269661, 52.959727706362358],
            [-1.927236638775923, 52.960480520652489],
            [-1.927390994707981, 52.960760179629922],
            [-1.925821670873696, 52.961776794497439],
            [-1.925469427281728, 52.963173500291425],
            [-1.922182971756646, 52.963584006969803],
            [-1.918780140007813, 52.965688811613902],
            [-1.916870945725687, 52.965938295331284],
            [-1.916256610504966, 52.965643918054198],
            [-1.916273978213034, 52.96512974688514],
            [-1.915037473357591, 52.965522600150472],
            [-1.910969513501716, 52.96281657318319],
            [-1.90967042362925, 52.962528839771032],
            [-1.906175214813255, 52.964422866327894],
            [-1.904901441360265, 52.965485281842668],
            [-1.903364025118782, 52.965899347396274],
            [-1.901240963769505, 52.969147209417436],
            [-1.899898220418888, 52.970303001222121],
            [-1.900525689327835, 52.970694557274314],
            [-1.899101013229735, 52.971194957877003],
            [-1.899198284812996, 52.971628320178624],
            [-1.895618255647072, 52.971761874763558],
            [-1.895702858501648, 52.97125585620433],
            [-1.8932581454688, 52.971130539548255],
            [-1.893139935425054, 52.970759179125693],
            [-1.888350327415153, 52.96936235876003],
            [-1.888554376828234, 52.969919881108773],
            [-1.887928863930732, 52.970547640554912],
            [-1.883756620443124, 52.97054454237923],
            [-1.879971776315922, 52.969911544702967],
            [-1.879725663152886, 52.971141021069577],
            [-1.877537292880078, 52.970953614027216],
            [-1.87712680744444, 52.972359104322244],
            [-1.873446949473191, 52.97195792907003],
            [-1.872389314347791, 52.971584647432728],
            [-1.870914224143924, 52.974397580380199],
            [-1.867951942627872, 52.974021281471785],
            [-1.864485362089836, 52.974449769346222],
            [-1.862530747331502, 52.974243474581961],
            [-1.861246579158168, 52.974871230347105],
            [-1.861351489481658, 52.976060624840628],
            [-1.860651849040463, 52.97643375946685],
            [-1.860937889617392, 52.977329418739686],
            [-1.859944888815898, 52.977686924649696],
            [-1.859950022021454, 52.97794222411973],
            [-1.861291865860252, 52.978407637161375],
            [-1.861585591413898, 52.97923678340927],
            [-1.86435685135545, 52.980786113888087],
            [-1.865732081196753, 52.981095102460685],
            [-1.86398069131862, 52.981528192899937],
            [-1.864489980344641, 52.982033067370317],
            [-1.865768088601382, 52.981973387798561],
            [-1.865277902129453, 52.982986815981974],
            [-1.86801898663417, 52.984785026491267],
            [-1.867703132100209, 52.985298858049291],
            [-1.8697742472712, 52.98656681810435],
            [-1.870198436207075, 52.987676547065412],
            [-1.870139717080896, 52.990871240134027],
            [-1.869172126704789, 52.99112277771858],
            [-1.869385402751183, 52.991536514169148],
            [-1.871176900117146, 52.992765487962131],
            [-1.873768856076707, 52.993885617115907],
            [-1.875936864533588, 52.993778226002853],
            [-1.877582391340167, 52.994037018302656],
            [-1.87872905307515, 52.993686713787419],
            [-1.880597798092608, 52.994584821884118],
            [-1.88116272230722, 52.996118041166383],
            [-1.878409750202812, 52.999705539950021],
            [-1.879365866407549, 53.001486367018437],
            [-1.878915218637365, 53.00331700317745],
            [-1.878135936036682, 53.003823197783859],
            [-1.877308150946116, 53.00355806705695],
            [-1.876208844390515, 53.004450453177341],
            [-1.876960113273351, 53.005951522303988],
            [-1.879217007100188, 53.007318385280847],
            [-1.883392191716911, 53.010871461661736],
            [-1.885341211072078, 53.013293231711309],
            [-1.884813799903162, 53.015452815397893],
            [-1.886549699273351, 53.016258109588641],
            [-1.886236504126716, 53.017458760707086],
            [-1.885003988902016, 53.018490430596465],
            [-1.884179983378744, 53.020037562818928],
            [-1.882418271428322, 53.019366147334246],
            [-1.880381944004475, 53.020994751209521],
            [-1.879242184917575, 53.020752693165292],
            [-1.877503704665958, 53.019789980357942],
            [-1.876221118353845, 53.021554122217616],
            [-1.872704027847496, 53.020653302002877],
            [-1.871598291606129, 53.020014785942642],
            [-1.866997119261583, 53.024640943494461],
            [-1.863035092054952, 53.028646507432939],
            [-1.863984860962311, 53.030075967330347],
            [-1.860665786932495, 53.031447469423433],
            [-1.856036046632733, 53.034079375281465],
            [-1.854664977822599, 53.032937890594738],
            [-1.851160012672047, 53.031407211427918],
            [-1.847864994423345, 53.031304169975286],
            [-1.845293350867555, 53.030319246379058],
            [-1.844012123221922, 53.03042634447575],
            [-1.842275422187864, 53.031130603673603],
            [-1.839339062212228, 53.031629172794048],
            [-1.836681661520619, 53.03168398930773],
            [-1.833744029656425, 53.032487149945538],
            [-1.830485506790922, 53.032954487524762],
            [-1.830198885431012, 53.032274501491713],
            [-1.825425992370677, 53.030724170233775],
            [-1.824792457764901, 53.029942084835476],
            [-1.821148842712671, 53.028722238789904],
            [-1.819708325617552, 53.028765911974425],
            [-1.818533116607261, 53.029187515726214],
            [-1.816509814081771, 53.029176326207107],
            [-1.814194775146527, 53.029741749896836],
            [-1.811646130820596, 53.028465784942895],
            [-1.80820873472231, 53.025906486276227],
            [-1.807381612179051, 53.023817873196684],
            [-1.806197638214463, 53.022536797583669],
            [-1.802993574394965, 53.022274451530528],
            [-1.801413637559643, 53.021554493907317],
            [-1.800722607785234, 53.020107884785105],
            [-1.800872531450141, 53.018950333079822],
            [-1.799990232654137, 53.017294850726984],
            [-1.799325705366438, 53.017230808801855],
            [-1.799178240954223, 53.016564463941421],
            [-1.798748484530073, 53.01666351811236],
            [-1.798733437126761, 53.016373142995675],
            [-1.797724611667769, 53.015986697998152],
            [-1.797650272724693, 53.014669660300356],
            [-1.796877386054252, 53.013565374844362],
            [-1.796004221553497, 53.013190832228233],
            [-1.794844520455757, 53.014469795216968],
            [-1.794098081643664, 53.01471570883411],
            [-1.793482752062173, 53.01591020124738],
            [-1.794725463115821, 53.020406027125922],
            [-1.792445199234595, 53.020597139560834],
            [-1.792250678836089, 53.022612167313333],
            [-1.790965244739308, 53.022992855729434],
            [-1.790260187707296, 53.022355184586779],
            [-1.787345604737627, 53.023578832319423],
            [-1.786779195972205, 53.02355534673783],
            [-1.784868864720154, 53.024565886819964],
            [-1.786093940675207, 53.025860732261933],
            [-1.787287247724103, 53.025443076583741],
            [-1.788817183769707, 53.025390965500179],
            [-1.790436977784136, 53.025562822524556],
            [-1.791110003691248, 53.02636134167706],
            [-1.791569634439381, 53.027203530426284],
            [-1.788135379794899, 53.028835291941256],
            [-1.787924488331115, 53.029882151024701],
            [-1.788283398726767, 53.03027651369203],
            [-1.7909708831539, 53.029545050363694],
            [-1.793456363307821, 53.032381862306245],
            [-1.79341588237206, 53.03336520296839],
            [-1.793911847434676, 53.033804731630866],
            [-1.793644651656195, 53.036676295029544],
            [-1.79466410032184, 53.039318162001337],
            [-1.790919668832242, 53.038295877742485],
            [-1.789929958413322, 53.040946825930838],
            [-1.790638808877208, 53.041786758609945],
            [-1.790193534086649, 53.042877253921759],
            [-1.781663443224278, 53.045850804808737],
            [-1.780981445972499, 53.045915173317944],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000194",
        LAD13CDO: "41UD",
        LAD13NM: "Lichfield",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.935844604799431, 52.806533067071996],
            [-1.936214251246091, 52.805325980801925],
            [-1.933314736701702, 52.805981523245094],
            [-1.933032650207223, 52.805169616612794],
            [-1.930784742121612, 52.805550384150187],
            [-1.93065673788052, 52.804887786306772],
            [-1.931286088739074, 52.804629254387166],
            [-1.931207938950746, 52.804331658034258],
            [-1.930491359936821, 52.804410349414091],
            [-1.930544041769838, 52.803928544843828],
            [-1.928868265721917, 52.803692032084122],
            [-1.928959597610417, 52.803168899682341],
            [-1.927038127535429, 52.802635561869337],
            [-1.926075247125321, 52.80189333709739],
            [-1.922912009512575, 52.801579397137814],
            [-1.918558544221395, 52.800057278832107],
            [-1.917863703587976, 52.800432561427016],
            [-1.916200149638657, 52.799341878198476],
            [-1.915346231760997, 52.79914080883821],
            [-1.912839219990582, 52.80011705431032],
            [-1.911371515335153, 52.799790551725067],
            [-1.909265269344176, 52.799133632259363],
            [-1.907659757515956, 52.798073437445488],
            [-1.904636229437776, 52.797811257323829],
            [-1.90282182637628, 52.796722961817053],
            [-1.901947020179761, 52.795305502383151],
            [-1.897253724674693, 52.795719553135001],
            [-1.894037803854577, 52.797219773299929],
            [-1.89261144019129, 52.798272959990989],
            [-1.892061400285843, 52.799391651879318],
            [-1.889883151867901, 52.800356923023607],
            [-1.887735818756502, 52.799065825436742],
            [-1.887332063363212, 52.799195791110463],
            [-1.886145585650346, 52.798604951866956],
            [-1.885697093170823, 52.79882746076148],
            [-1.8847088108977, 52.798455241660506],
            [-1.884221321718338, 52.797160283447276],
            [-1.883569529023097, 52.796871084981035],
            [-1.88338216381292, 52.797056983641546],
            [-1.882223045496939, 52.796261171607604],
            [-1.878227902940234, 52.796174445212081],
            [-1.876598034293032, 52.795642385909325],
            [-1.876407947340841, 52.795208895512083],
            [-1.873637773040576, 52.794651333352419],
            [-1.872442963914302, 52.793972250733624],
            [-1.872384453425684, 52.793187405938667],
            [-1.87171272070893, 52.793170502114187],
            [-1.870470540142154, 52.791987038100679],
            [-1.869073629089012, 52.791986407228499],
            [-1.868320235424056, 52.791517223004945],
            [-1.8670924230711, 52.79150596855537],
            [-1.866589048251885, 52.790753883564143],
            [-1.864870138728775, 52.790355506017143],
            [-1.864484759392139, 52.78982378850953],
            [-1.863717812397276, 52.78992449317478],
            [-1.86361233524671, 52.789511754571187],
            [-1.862582515437808, 52.789272347277212],
            [-1.861819203827503, 52.788225085612638],
            [-1.862432453381591, 52.787011315997667],
            [-1.861750997899555, 52.786818150349163],
            [-1.861394184549362, 52.786183975399069],
            [-1.860830607899616, 52.786228264523814],
            [-1.860996459486342, 52.78582932486232],
            [-1.860488750724264, 52.786023802279125],
            [-1.860584272395336, 52.785362287280506],
            [-1.860068548943522, 52.785282573812651],
            [-1.860278809429512, 52.784907060047168],
            [-1.859796728743918, 52.784510055586253],
            [-1.858068443245091, 52.784354284398077],
            [-1.857707728517109, 52.784030231188922],
            [-1.854213066060933, 52.783156712141576],
            [-1.853784445789843, 52.78231297032692],
            [-1.852731302654215, 52.78246179501874],
            [-1.852461920126897, 52.783206690798636],
            [-1.85168511067454, 52.783181452486687],
            [-1.849426585177139, 52.785044833631517],
            [-1.848586086138722, 52.784991626914668],
            [-1.847008605392694, 52.786658058152291],
            [-1.844954250807854, 52.787707167543758],
            [-1.844380772454426, 52.790104815366448],
            [-1.845155258007957, 52.793329458009794],
            [-1.84466195217438, 52.794016510216451],
            [-1.842360912861734, 52.795914757360656],
            [-1.838151856137601, 52.795953149666076],
            [-1.837262978026805, 52.795298400504393],
            [-1.835111783170518, 52.795508486751565],
            [-1.832133077190039, 52.79493348501299],
            [-1.827557351114828, 52.793207245393525],
            [-1.823305187168868, 52.794064889952267],
            [-1.817582267511406, 52.793010766049306],
            [-1.814186045067226, 52.790280788799777],
            [-1.810160259024401, 52.785118947105822],
            [-1.810004761343148, 52.783372037521019],
            [-1.809509153672256, 52.783125830487812],
            [-1.812390021606966, 52.779297286530294],
            [-1.814591222191561, 52.778319985570931],
            [-1.818389796402382, 52.77783951910358],
            [-1.820620016564916, 52.776964631027887],
            [-1.820279866196663, 52.776408564953734],
            [-1.821088731786722, 52.77544970508589],
            [-1.823528854017862, 52.774309887122108],
            [-1.825455117287237, 52.772643378157227],
            [-1.827203487097844, 52.771696639832285],
            [-1.826982259994922, 52.771416743754735],
            [-1.828424959299974, 52.769024925013369],
            [-1.826208569056972, 52.768799662769339],
            [-1.823494934472132, 52.769128275382982],
            [-1.823322641311416, 52.767018176846101],
            [-1.822501960178226, 52.767287538592356],
            [-1.820545774965254, 52.762914787775188],
            [-1.818980109885994, 52.757008980430946],
            [-1.81753122185585, 52.755173795091565],
            [-1.816699542286851, 52.755637274487675],
            [-1.814850578149831, 52.755593954311763],
            [-1.813195872843465, 52.756555043534028],
            [-1.811852530554642, 52.756420777248678],
            [-1.807862497637707, 52.757380767319511],
            [-1.803139717941438, 52.756163940426951],
            [-1.802465207060137, 52.755253977117292],
            [-1.801557815482783, 52.755060987282377],
            [-1.801358462834303, 52.754260583906593],
            [-1.798661191296973, 52.753765213065257],
            [-1.795257229879467, 52.75232556033513],
            [-1.794049170012328, 52.752762162199183],
            [-1.793892485526935, 52.753942219076876],
            [-1.793321856736289, 52.754298112865996],
            [-1.79053741863617, 52.753769144193157],
            [-1.789357936381187, 52.753176444491892],
            [-1.788931628611231, 52.752480794924452],
            [-1.790147892605614, 52.75157860048558],
            [-1.786200252912287, 52.749315180212271],
            [-1.786746926858836, 52.747186535201834],
            [-1.784568514890309, 52.745835067575918],
            [-1.779847260882351, 52.745868660512741],
            [-1.778488792346074, 52.746173577748706],
            [-1.777286780713928, 52.748622784683178],
            [-1.777623446800524, 52.74884006407008],
            [-1.778434168342407, 52.74818354313539],
            [-1.778524927494588, 52.748983783734069],
            [-1.77586404624407, 52.750751533764266],
            [-1.773933963655511, 52.750971715653925],
            [-1.772804766740664, 52.75073852908951],
            [-1.771801048009048, 52.74974235894026],
            [-1.772736474617861, 52.749055551998516],
            [-1.771918422768245, 52.748825648756061],
            [-1.770310937013072, 52.749127296926218],
            [-1.769100463937023, 52.748026424530586],
            [-1.768747311594945, 52.748134511138723],
            [-1.76961471998084, 52.749113362953167],
            [-1.769044222739105, 52.749138324588486],
            [-1.768965477266653, 52.749461795789891],
            [-1.769843589190617, 52.749820386565553],
            [-1.769785598694885, 52.750142100578586],
            [-1.767738712076689, 52.750038330886717],
            [-1.767710341519905, 52.750637879344225],
            [-1.767223579987791, 52.750792445709543],
            [-1.763816788013554, 52.748465512202877],
            [-1.760179390991747, 52.747196086680958],
            [-1.759223118327055, 52.746259236185232],
            [-1.757802871574288, 52.745654046603129],
            [-1.757463250851377, 52.74519039038497],
            [-1.758091257693393, 52.744133598459634],
            [-1.756953777048143, 52.743572124103224],
            [-1.756137948448312, 52.741648479648596],
            [-1.753343329734637, 52.740783303467794],
            [-1.754003527214215, 52.74032980021623],
            [-1.750609595220795, 52.739130701565337],
            [-1.749194666928773, 52.739725528609569],
            [-1.746466199263488, 52.739246881767734],
            [-1.744092814812926, 52.739350564118546],
            [-1.744242155205503, 52.738380909563666],
            [-1.740754559019995, 52.738312216570904],
            [-1.737094262579056, 52.739133007906382],
            [-1.734829153070928, 52.73920887689291],
            [-1.733815652476408, 52.73976216547976],
            [-1.734298016717895, 52.7400868710411],
            [-1.733950537813162, 52.740232623171337],
            [-1.733111494772421, 52.739862167036321],
            [-1.733998555014858, 52.73891305939835],
            [-1.731812242506059, 52.738941402462004],
            [-1.731255022180325, 52.739234102007408],
            [-1.730876946680323, 52.739056150726391],
            [-1.731087247926664, 52.738336561173021],
            [-1.728862014126703, 52.73843577942889],
            [-1.72805600092604, 52.738958927988143],
            [-1.727260634364973, 52.738484251105952],
            [-1.727239897010922, 52.738009553099516],
            [-1.726232688035886, 52.737783394019694],
            [-1.72555744439331, 52.737998484184729],
            [-1.725904633155491, 52.738609679432805],
            [-1.725597974914005, 52.739092610988187],
            [-1.723585516089752, 52.739264138587401],
            [-1.723149784918971, 52.738605984282955],
            [-1.724680713853638, 52.737751038441203],
            [-1.724357851855749, 52.737043707340554],
            [-1.723061457901277, 52.738052918435756],
            [-1.721935613008818, 52.737853414826589],
            [-1.721371427813214, 52.736208794424385],
            [-1.722303283085913, 52.735463942438457],
            [-1.721515378797628, 52.735000031596115],
            [-1.719824686562144, 52.734675124978821],
            [-1.720584545595345, 52.734193275487669],
            [-1.719974932548582, 52.733414238850884],
            [-1.717407159133177, 52.733387478154107],
            [-1.717941865021889, 52.732684864095333],
            [-1.717183157378118, 52.732301000978218],
            [-1.716935552236738, 52.731431116318824],
            [-1.717645945999011, 52.731267399404295],
            [-1.714301789398213, 52.730642711445718],
            [-1.711996721865278, 52.729875721892206],
            [-1.710801946568153, 52.729838656682588],
            [-1.707288962211205, 52.730575284670564],
            [-1.704164699705445, 52.732061612116546],
            [-1.704516639913786, 52.731041269527083],
            [-1.703247841403653, 52.729469417983289],
            [-1.701332939453933, 52.728417325888913],
            [-1.696958451501552, 52.727177358586765],
            [-1.694027415016879, 52.726850708110057],
            [-1.68782911420678, 52.72726876704391],
            [-1.682096202764587, 52.726999144871705],
            [-1.677951033006799, 52.727486894083491],
            [-1.675440037457333, 52.726842690553255],
            [-1.675601383228255, 52.724852829693994],
            [-1.671402227216932, 52.724265942531581],
            [-1.671406835749377, 52.724046608467255],
            [-1.669176212534148, 52.723565759714646],
            [-1.666382231335824, 52.72357591820753],
            [-1.666290736556213, 52.723924458466527],
            [-1.664846317820301, 52.72403096081365],
            [-1.664559526892999, 52.723586063079701],
            [-1.66302974017259, 52.72362757668138],
            [-1.662620100528088, 52.72298814938798],
            [-1.656614340509406, 52.721731254932379],
            [-1.655934945594805, 52.719826181994527],
            [-1.656220460885181, 52.717780968298754],
            [-1.655684281392514, 52.715933841038655],
            [-1.657851947724326, 52.715309936610083],
            [-1.657557369973339, 52.714930623063438],
            [-1.659297546005246, 52.714066333381297],
            [-1.658215138793336, 52.712590717303868],
            [-1.657739563061906, 52.710562181387786],
            [-1.655916972364777, 52.710438247360003],
            [-1.656349167940526, 52.708554370163661],
            [-1.654308017147183, 52.708191552331634],
            [-1.654157776027269, 52.703040940449085],
            [-1.655061607694767, 52.701963913036856],
            [-1.654427742968116, 52.699961868607176],
            [-1.655596270258334, 52.699463642764151],
            [-1.655048201717562, 52.698781532981727],
            [-1.653938387135169, 52.698780099267267],
            [-1.653937354113466, 52.699285315505676],
            [-1.652616928024239, 52.699529571842618],
            [-1.649385801924396, 52.699621651325906],
            [-1.648638484204126, 52.699255359176576],
            [-1.648957951374894, 52.698722318505922],
            [-1.648527478289725, 52.698336286326622],
            [-1.644689749377806, 52.699337991746916],
            [-1.642365209677529, 52.700399879536242],
            [-1.641620302207246, 52.700287059039049],
            [-1.640947861688198, 52.699638668932948],
            [-1.637659588856704, 52.699658338584591],
            [-1.637527962396568, 52.700006735717636],
            [-1.638004396310311, 52.699835588589636],
            [-1.638214266700879, 52.700225481366282],
            [-1.637719624074956, 52.701344983683434],
            [-1.636326122243964, 52.702351159269739],
            [-1.635346959563826, 52.702465918712441],
            [-1.634883087103203, 52.702368302776122],
            [-1.634916297101307, 52.701935103036973],
            [-1.634442119966902, 52.702186252726264],
            [-1.633708283642807, 52.701987115871766],
            [-1.634282790944521, 52.701772237061384],
            [-1.634155029437733, 52.701476982103124],
            [-1.633289250944327, 52.701484198084366],
            [-1.632930399756717, 52.700864599262616],
            [-1.631901821612703, 52.700347203591377],
            [-1.630315092432696, 52.700039318429447],
            [-1.629457638719896, 52.700290152419385],
            [-1.626295977067946, 52.700206521089193],
            [-1.627309138173957, 52.699571441168438],
            [-1.626298238416287, 52.698901227721095],
            [-1.625257439068077, 52.697405658872896],
            [-1.623233777449526, 52.697508024928489],
            [-1.622377831191387, 52.698614629748576],
            [-1.620914484009869, 52.699453194748386],
            [-1.618409264164299, 52.699784973608288],
            [-1.618052745783005, 52.699602235005486],
            [-1.616612203753357, 52.70052712120259],
            [-1.615381032379343, 52.700690344422547],
            [-1.612553921386325, 52.699925114324039],
            [-1.612117174768327, 52.69911281844287],
            [-1.61142223630121, 52.699216622642894],
            [-1.61142079665438, 52.698878606047295],
            [-1.610688200239917, 52.69855796978802],
            [-1.610141024361665, 52.698520214326962],
            [-1.609863888609808, 52.698899566746356],
            [-1.608003837641058, 52.698566211419035],
            [-1.607561968397001, 52.698336412793687],
            [-1.607828334600665, 52.697343933866669],
            [-1.606642533538357, 52.697569245118679],
            [-1.606768480496521, 52.696726430471308],
            [-1.605500741387712, 52.696521751860303],
            [-1.605212904504201, 52.696936117752969],
            [-1.604009027648208, 52.696705565606251],
            [-1.601008879395488, 52.697442556581329],
            [-1.599649770644696, 52.69833065355396],
            [-1.598763627695625, 52.698467000049305],
            [-1.598322952300436, 52.698757673557601],
            [-1.598546976898326, 52.699340064199085],
            [-1.599257814438984, 52.699119523275257],
            [-1.599683478526911, 52.699823054338417],
            [-1.598967266556492, 52.699821534541535],
            [-1.598574072278677, 52.70041981645776],
            [-1.597547212570677, 52.700404651067529],
            [-1.597003860596064, 52.700432472638383],
            [-1.590887828025551, 52.698047253696764],
            [-1.589753865942413, 52.696044930514688],
            [-1.587995052481224, 52.695517433466541],
            [-1.586291394506066, 52.694481286031994],
            [-1.587087944618814, 52.694270106483827],
            [-1.588620237699419, 52.692757969708332],
            [-1.588469723917915, 52.690668242452034],
            [-1.58965137710357, 52.68724366444782],
            [-1.602110582467838, 52.679603507296505],
            [-1.604289029809403, 52.678981514830291],
            [-1.603917436100772, 52.677873641180526],
            [-1.606211779198126, 52.677015570852959],
            [-1.605071844111514, 52.676150566434025],
            [-1.603936031178328, 52.674179829467946],
            [-1.604271605538977, 52.673704494465106],
            [-1.606089550144733, 52.673172061600219],
            [-1.615550358859613, 52.671962522173757],
            [-1.615596731102863, 52.668698505078062],
            [-1.620036849730501, 52.668082632392824],
            [-1.625558737356176, 52.665623524297736],
            [-1.628147003293658, 52.66380585384907],
            [-1.627984212848882, 52.663474521411025],
            [-1.631258708824231, 52.662485089133561],
            [-1.639204003821403, 52.659298393421331],
            [-1.639406344743204, 52.658783897188002],
            [-1.64495045711317, 52.65749980927869],
            [-1.647268362051946, 52.657155226914696],
            [-1.650478559972659, 52.656328685589422],
            [-1.651583063939265, 52.655746703788118],
            [-1.656123351995478, 52.654754902515769],
            [-1.658408385324778, 52.653286290331017],
            [-1.662696107006572, 52.652214399955774],
            [-1.665402667388196, 52.652022494328662],
            [-1.666481648403875, 52.652229600354666],
            [-1.670984988190326, 52.65153738318768],
            [-1.673211457875613, 52.650697604586121],
            [-1.681316869847189, 52.648762594846808],
            [-1.684316571966249, 52.647229776010604],
            [-1.68966721904339, 52.650963848744695],
            [-1.69418640997066, 52.651106836731472],
            [-1.701545793518905, 52.650724640884917],
            [-1.704429578342725, 52.650954807202396],
            [-1.711434208516683, 52.650105444986465],
            [-1.714098937045863, 52.651289551816873],
            [-1.720506282058915, 52.651597886894095],
            [-1.722559557355482, 52.651349196783073],
            [-1.721542929798599, 52.649896772058483],
            [-1.722870406663499, 52.649408142813783],
            [-1.72141372692811, 52.6476643193024],
            [-1.724504805601678, 52.646878644585314],
            [-1.724709675915642, 52.646498854639184],
            [-1.725689480145212, 52.646516407079005],
            [-1.72571714548213, 52.646110135230259],
            [-1.727229098658384, 52.645638963919374],
            [-1.734473148934972, 52.644576633074919],
            [-1.73413629025953, 52.643582513121103],
            [-1.733623168257182, 52.642656318310507],
            [-1.734155334510886, 52.641902374444307],
            [-1.733459374432307, 52.640668319698079],
            [-1.733629431683354, 52.63943261265517],
            [-1.732635281267587, 52.638399252680692],
            [-1.733037813992532, 52.637573103100181],
            [-1.735808969000064, 52.637003964318396],
            [-1.735778381257005, 52.636684760305045],
            [-1.734963170631223, 52.635873864547627],
            [-1.733135157315117, 52.635415783565328],
            [-1.732131225793324, 52.634791430052793],
            [-1.731235201691703, 52.632716367000121],
            [-1.730285537752877, 52.632412155582237],
            [-1.727393727292152, 52.632459482605235],
            [-1.726127973361634, 52.632144622142988],
            [-1.720950166950386, 52.62851777171737],
            [-1.711841572046375, 52.625435874212478],
            [-1.709533263390824, 52.621310231701806],
            [-1.707528666059854, 52.619107304753385],
            [-1.702212707792847, 52.615832581950762],
            [-1.700261514854621, 52.615633486343533],
            [-1.691459887920762, 52.617374705370125],
            [-1.690592907258585, 52.614225122664024],
            [-1.692071701017228, 52.612060635488916],
            [-1.690334508296061, 52.610654602624649],
            [-1.690962008803713, 52.609204388251754],
            [-1.690758352081208, 52.606877297973774],
            [-1.688969790410157, 52.606296373225831],
            [-1.688757884835159, 52.605567643325799],
            [-1.690300546350011, 52.605206697242146],
            [-1.691300534974946, 52.603899491150706],
            [-1.692953924668687, 52.603106389239429],
            [-1.693251549575063, 52.601505176531205],
            [-1.694335571419328, 52.60019096973744],
            [-1.693932555743495, 52.599759318167784],
            [-1.695132458889819, 52.598757349272425],
            [-1.696262834571845, 52.598835765141864],
            [-1.698625629781289, 52.597031247455753],
            [-1.699535171869326, 52.597013778566243],
            [-1.69935801618118, 52.597444840358357],
            [-1.700434740308173, 52.597376546482302],
            [-1.703750728666239, 52.596045397319905],
            [-1.70370714956866, 52.595282952330585],
            [-1.70430337993982, 52.595310511435947],
            [-1.704349590066415, 52.595024750636178],
            [-1.702308786181903, 52.594895584133646],
            [-1.701899668805816, 52.594053109457143],
            [-1.701122555599502, 52.593926194365132],
            [-1.700360448702576, 52.593330942673795],
            [-1.700753553084548, 52.592183036970951],
            [-1.700477329458802, 52.59177599957858],
            [-1.701006708371714, 52.59164158967927],
            [-1.700399186995382, 52.590895698777913],
            [-1.704110500285693, 52.590628133857287],
            [-1.704244073171191, 52.590081886106752],
            [-1.703116752137088, 52.590238188044232],
            [-1.704264698861005, 52.589209924893765],
            [-1.703301903249654, 52.588612392812621],
            [-1.704204632975649, 52.588484296349634],
            [-1.703949217430782, 52.587616140749262],
            [-1.704792873139258, 52.587489690221233],
            [-1.705444056420004, 52.587671106559796],
            [-1.707670002559066, 52.587432992449536],
            [-1.713885446057825, 52.58880112487266],
            [-1.715679607649249, 52.58891331933458],
            [-1.71755733403932, 52.5886562041334],
            [-1.71759139431911, 52.589098584409349],
            [-1.723239514465194, 52.587817370726178],
            [-1.724305835373751, 52.588423072190984],
            [-1.727516928815142, 52.588310931553643],
            [-1.729317173253754, 52.587916806617251],
            [-1.730058791046102, 52.588291575041943],
            [-1.733177549260338, 52.588553946062312],
            [-1.733539034612423, 52.589065380666135],
            [-1.737544118714393, 52.588979022538261],
            [-1.739008043402978, 52.58928341304668],
            [-1.740369398095643, 52.590213251896039],
            [-1.73918365278217, 52.593521596745362],
            [-1.741285605482297, 52.594304719297888],
            [-1.74241490604111, 52.594308976050279],
            [-1.745053421851101, 52.593718654659895],
            [-1.751053160869099, 52.593392487864904],
            [-1.755755724700146, 52.591648356607813],
            [-1.762655414826432, 52.590526056708399],
            [-1.768478813668068, 52.58910009970473],
            [-1.770706222533911, 52.589378617065996],
            [-1.773090449355778, 52.591024742404656],
            [-1.774044355271191, 52.592110734464988],
            [-1.776324559896638, 52.592204056504173],
            [-1.781027992985976, 52.591243734064712],
            [-1.783930321198972, 52.589091504363424],
            [-1.788088491828848, 52.587846727659063],
            [-1.788120673969842, 52.587907016883428],
            [-1.789729949028841, 52.591527379135691],
            [-1.792764708072306, 52.593763090710262],
            [-1.795321289602508, 52.595082737944431],
            [-1.79427877660589, 52.59608689237087],
            [-1.79653535529729, 52.599106860596308],
            [-1.797578956667969, 52.599153595240331],
            [-1.801816339137775, 52.601096252695527],
            [-1.803942861267078, 52.60133621813867],
            [-1.811824503850143, 52.604392924025724],
            [-1.819070255777701, 52.606144617618028],
            [-1.828066263264436, 52.608704799042087],
            [-1.831950368756844, 52.607848244950347],
            [-1.836840123645328, 52.607338157904877],
            [-1.838141827482303, 52.606733131071181],
            [-1.838664113252075, 52.606856103303535],
            [-1.845732248314236, 52.60493539870194],
            [-1.84870877236545, 52.604592225699847],
            [-1.85396103631072, 52.601909064394427],
            [-1.863787753059594, 52.591209419766464],
            [-1.867990944994105, 52.588537008388712],
            [-1.867811532081694, 52.58831565964627],
            [-1.870952728100357, 52.586741407080844],
            [-1.872571225704478, 52.584929013916486],
            [-1.873242877978444, 52.584891976000932],
            [-1.875160828357445, 52.585677020710335],
            [-1.878194236812975, 52.587691190778017],
            [-1.88440538633381, 52.594411878064584],
            [-1.88757649836315, 52.596668662600294],
            [-1.890633481053544, 52.598047853560608],
            [-1.890395222807993, 52.598274176355993],
            [-1.890188071414223, 52.59845827546696],
            [-1.892272535886237, 52.599136216413321],
            [-1.892246691171602, 52.600044160652672],
            [-1.886913333718824, 52.607128536139186],
            [-1.886611868971874, 52.608361644681814],
            [-1.885021730365923, 52.610514962095387],
            [-1.885653076832466, 52.614768627087713],
            [-1.892790721591403, 52.621577829821589],
            [-1.894334738075023, 52.622646296179738],
            [-1.897492528453996, 52.624740077331175],
            [-1.904693569454474, 52.627752240926917],
            [-1.908536709418057, 52.630347001416396],
            [-1.909981734867446, 52.631753199613918],
            [-1.915991396747901, 52.635269885331176],
            [-1.914141162988819, 52.636158545745225],
            [-1.913673319221064, 52.63586783884854],
            [-1.913126150269019, 52.636092183221201],
            [-1.912743695135322, 52.636708597948378],
            [-1.913159794728176, 52.63701365423092],
            [-1.908017032204234, 52.64005460278652],
            [-1.907518086375242, 52.639796210682555],
            [-1.907535634029434, 52.640587319345514],
            [-1.905882411531384, 52.641766371009133],
            [-1.906478699331461, 52.642122834430076],
            [-1.90509017070289, 52.643191509321859],
            [-1.909053628774628, 52.644730950088402],
            [-1.909746263221689, 52.644263114238527],
            [-1.912353371466714, 52.646459453052998],
            [-1.915266587562639, 52.647947563587742],
            [-1.918021019361084, 52.649878684946586],
            [-1.91587474124645, 52.65145667579089],
            [-1.916590160691195, 52.652241980485393],
            [-1.908666402066408, 52.653983753335339],
            [-1.912848842926286, 52.658217442219851],
            [-1.918977522431548, 52.65700906777009],
            [-1.92246524670274, 52.656002749783184],
            [-1.923690144479361, 52.655511805960394],
            [-1.925812069315603, 52.656053428945356],
            [-1.928596069328831, 52.658583932168249],
            [-1.930278793618665, 52.65932657936353],
            [-1.933575222490302, 52.661593865622521],
            [-1.935480835597235, 52.659699888827944],
            [-1.936584750756709, 52.660033100491688],
            [-1.936405830969313, 52.659028857224015],
            [-1.936958646941284, 52.659107361593932],
            [-1.938642134449693, 52.657297718430634],
            [-1.940492083932068, 52.656912101014967],
            [-1.940365764588414, 52.656307930692918],
            [-1.945408367639228, 52.656282481458234],
            [-1.945731115998484, 52.657097993740798],
            [-1.949524282260372, 52.659929614595619],
            [-1.950223937524228, 52.659620663816625],
            [-1.953669878394293, 52.662717200677811],
            [-1.954576320093504, 52.662624955818764],
            [-1.961029954099861, 52.667861926967184],
            [-1.959618145467128, 52.67069230433188],
            [-1.959494294950294, 52.671899574497573],
            [-1.96047820407286, 52.673022713508836],
            [-1.962692951057929, 52.673768669364954],
            [-1.962477960792878, 52.674412261760921],
            [-1.963232048563882, 52.674616561689852],
            [-1.962005867200429, 52.678208445136505],
            [-1.958316920230148, 52.676811119923336],
            [-1.958027658489337, 52.679259800734954],
            [-1.959256724120408, 52.684132617189562],
            [-1.954593690996133, 52.68450849838689],
            [-1.953666908909006, 52.686630596900159],
            [-1.95078365034474, 52.687958110467605],
            [-1.949209549639572, 52.689260947833368],
            [-1.948167671253404, 52.690816605518521],
            [-1.947508905779491, 52.693624678957335],
            [-1.949638669557324, 52.695651860788288],
            [-1.946634960356798, 52.695659543167139],
            [-1.942753702638989, 52.696889320405774],
            [-1.941324387067708, 52.69683558604396],
            [-1.936598577606487, 52.695576413502422],
            [-1.933554382660864, 52.695939732257131],
            [-1.932627564540443, 52.696296998520985],
            [-1.934213030379904, 52.697784769747173],
            [-1.93414130280814, 52.698252190648631],
            [-1.933264763040157, 52.698602297868675],
            [-1.932286372701882, 52.699738932817141],
            [-1.931497703844643, 52.701597535426195],
            [-1.930122240135379, 52.702953269483899],
            [-1.930161481536525, 52.704334097812904],
            [-1.929416014587782, 52.70498900107647],
            [-1.926429854764495, 52.70650374052456],
            [-1.9256332425502, 52.707590089550699],
            [-1.921247266385961, 52.70790280282214],
            [-1.916625315441892, 52.709299335757159],
            [-1.915517121713725, 52.710621824287209],
            [-1.917179725746263, 52.711987615794385],
            [-1.919028696136443, 52.710279964358612],
            [-1.92406297598979, 52.713534824274291],
            [-1.927612767570995, 52.714355090247395],
            [-1.930547870078686, 52.714550115512154],
            [-1.93247940704411, 52.713748455323497],
            [-1.933950903837975, 52.71367466708962],
            [-1.937391964730516, 52.714063977984274],
            [-1.937707115708537, 52.716255807705068],
            [-1.935832398384259, 52.718686498102919],
            [-1.935546388131668, 52.71989005022845],
            [-1.935867746147382, 52.720838625695237],
            [-1.933430385798831, 52.721132146566489],
            [-1.934502866256226, 52.723716349217788],
            [-1.933928422582446, 52.727630096728248],
            [-1.92716552127512, 52.73142245029927],
            [-1.927100708971491, 52.732996485098219],
            [-1.925745381643674, 52.734914920904878],
            [-1.9198986264648, 52.738063770981192],
            [-1.919493014358737, 52.737951127692419],
            [-1.918741273924712, 52.739197469420844],
            [-1.916200159089405, 52.738934110651648],
            [-1.914910143773358, 52.740438947014617],
            [-1.913926221541679, 52.74140911196158],
            [-1.914127319832216, 52.741585452667962],
            [-1.913146428463596, 52.741751946492727],
            [-1.912343683172906, 52.743195979989139],
            [-1.913871392623642, 52.74594340516154],
            [-1.913227713340394, 52.747080115976871],
            [-1.91384363416916, 52.747253162965009],
            [-1.911513597156432, 52.750816708299517],
            [-1.910812109081003, 52.751173968479904],
            [-1.912346002261039, 52.752403080297668],
            [-1.917204314508654, 52.754638663221847],
            [-1.914632620812984, 52.755925047258614],
            [-1.911751836824453, 52.758312364671575],
            [-1.911379928508157, 52.759035745825855],
            [-1.913120769124396, 52.759922498648649],
            [-1.917831155585017, 52.760903905238372],
            [-1.923003069481282, 52.76247513708158],
            [-1.925486090894208, 52.763743341466835],
            [-1.92859735165014, 52.766089715797058],
            [-1.928813463244478, 52.767165890939424],
            [-1.929716108284251, 52.768052793781997],
            [-1.930409662991277, 52.768098148663306],
            [-1.931637652576413, 52.767583760360836],
            [-1.93250731887604, 52.767792813200138],
            [-1.937391644625538, 52.771377802781181],
            [-1.94281441352538, 52.77303459859457],
            [-1.943948933007133, 52.773742611944328],
            [-1.945734771582752, 52.776408827323749],
            [-1.947724862792275, 52.77829121966078],
            [-1.95207089212871, 52.780134993952949],
            [-1.951304432308109, 52.780132885691259],
            [-1.948390049468135, 52.783700490915706],
            [-1.947112257878731, 52.785956293923363],
            [-1.946402099685845, 52.785904736767968],
            [-1.945281332329259, 52.789335515706092],
            [-1.949194477687589, 52.789375901629739],
            [-1.949288647959563, 52.790007902544389],
            [-1.950697638846514, 52.789759484404883],
            [-1.951379004744067, 52.791767115786968],
            [-1.948047918701526, 52.793407190964921],
            [-1.951768873712291, 52.795936594495863],
            [-1.956055911854923, 52.797892561552686],
            [-1.958136177767881, 52.798474028885671],
            [-1.952635849961026, 52.800621355857331],
            [-1.953050242260889, 52.801469226445128],
            [-1.952423993529077, 52.801763832980541],
            [-1.952710044233497, 52.801965310727823],
            [-1.951174193056623, 52.803903719938759],
            [-1.95133198052853, 52.807411472185805],
            [-1.949910835528261, 52.807414479894213],
            [-1.949818565384888, 52.807673337527824],
            [-1.948874948808189, 52.807792496717269],
            [-1.94178159100338, 52.807382921603235],
            [-1.938590128012734, 52.80673318032273],
            [-1.935844604799431, 52.806533067071996],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000195",
        LAD13CDO: "41UE",
        LAD13NM: "Newcastle-under-Lyme",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.195213378144396, 53.103799431053218],
            [-2.194701549715627, 53.102915750666071],
            [-2.1936593470933, 53.102324177483482],
            [-2.192261745357532, 53.102075652242014],
            [-2.19137848359496, 53.102284723869772],
            [-2.191259138692112, 53.101980188057979],
            [-2.189271711069951, 53.10141526266785],
            [-2.188942176881577, 53.100516884442598],
            [-2.186229661398656, 53.097685126867823],
            [-2.185798531053336, 53.096062382674667],
            [-2.184318059670008, 53.095234999917778],
            [-2.183742779247084, 53.093403026599589],
            [-2.182672561381214, 53.092453636200517],
            [-2.181150324457354, 53.090527817359742],
            [-2.181206904594221, 53.08977984300433],
            [-2.181788962150584, 53.089362761591083],
            [-2.184950158721336, 53.090116559626125],
            [-2.188911277329212, 53.090068081551379],
            [-2.188967690637145, 53.090334067486026],
            [-2.192177986628013, 53.090669605135105],
            [-2.194053697119795, 53.09139646785502],
            [-2.196051676742592, 53.091742863022006],
            [-2.196778788424162, 53.092378984459707],
            [-2.198615458669454, 53.092702232001876],
            [-2.200387672937359, 53.091682597420046],
            [-2.199585939442151, 53.089451967135957],
            [-2.201544362829026, 53.08901269416085],
            [-2.201991968456419, 53.087987183250057],
            [-2.201370725036436, 53.086683027659738],
            [-2.205124410953434, 53.086725150366973],
            [-2.20713699840326, 53.087030883710696],
            [-2.210051926674364, 53.085602802160899],
            [-2.211517574929915, 53.086722935702845],
            [-2.214636312564745, 53.085481351139855],
            [-2.21584919448564, 53.085297576204717],
            [-2.215879031410171, 53.085293027499034],
            [-2.21664010255406, 53.084629149769654],
            [-2.216794469117096, 53.082669254358557],
            [-2.220716605375321, 53.082145166046402],
            [-2.221645294253483, 53.081007221086267],
            [-2.22484537073244, 53.079467677593044],
            [-2.226593738198032, 53.078960071385971],
            [-2.227387869595705, 53.079522169303964],
            [-2.230071280795152, 53.077728182338447],
            [-2.231419228767449, 53.078009620421533],
            [-2.234066226113161, 53.075605255749181],
            [-2.234894683579935, 53.07588587845126],
            [-2.236066168569337, 53.074749137146036],
            [-2.237441496431036, 53.07432031603566],
            [-2.238761405940762, 53.073322581906169],
            [-2.237614468985744, 53.072393611808209],
            [-2.238295436637775, 53.071920322199901],
            [-2.23850761398708, 53.071154027306257],
            [-2.237031581923008, 53.069533552034272],
            [-2.236641843739619, 53.067863258357086],
            [-2.23351077806865, 53.066201076603036],
            [-2.234139585080983, 53.064375954124294],
            [-2.232789930090673, 53.063702625669229],
            [-2.230542519034979, 53.063404972635482],
            [-2.227917828484996, 53.063713865728673],
            [-2.221990967872459, 53.049857603735383],
            [-2.219165730848691, 53.043720587938239],
            [-2.215011880789213, 53.040449845403479],
            [-2.211463826128854, 53.037724418650591],
            [-2.209238670571617, 53.035863114623723],
            [-2.208325114878154, 53.03444174110232],
            [-2.206573934293803, 53.031536815109654],
            [-2.205742818217255, 53.025787007601537],
            [-2.204629960505343, 53.022508791267555],
            [-2.20255344685042, 53.020007074661457],
            [-2.208330470488497, 53.016698963466901],
            [-2.2136557014021, 53.015946101686779],
            [-2.215487333540697, 53.015287483635433],
            [-2.214874503787903, 53.014047190792674],
            [-2.215560266373261, 53.013772677885505],
            [-2.215170550561594, 53.012436696057343],
            [-2.215651573584869, 53.01086361895387],
            [-2.215219373882123, 53.010269320028307],
            [-2.214421421931699, 53.010150308270674],
            [-2.215036084555879, 53.009975706232417],
            [-2.214586334920164, 53.009445259788741],
            [-2.213025875788497, 53.009738418632999],
            [-2.21223966055939, 53.008364483379474],
            [-2.212575998916346, 53.00826769782811],
            [-2.211496845148715, 53.007017432890613],
            [-2.211952501852918, 53.006942010567691],
            [-2.214537048645561, 53.00612205270275],
            [-2.217782407993599, 53.00397043080774],
            [-2.215959734115834, 53.002442002917157],
            [-2.21719091314834, 53.001619045976206],
            [-2.216103608511889, 53.001414278073263],
            [-2.213218229908616, 52.996466452881513],
            [-2.213446691664815, 52.994456059979107],
            [-2.212402732869446, 52.993329788174563],
            [-2.212779544799711, 52.993004603845016],
            [-2.212497158682565, 52.992535873595543],
            [-2.213196983345132, 52.992460009071316],
            [-2.211891040274648, 52.988629353215451],
            [-2.212495197958469, 52.988195893253845],
            [-2.211535183699412, 52.987141376147918],
            [-2.210677313554397, 52.987169868745326],
            [-2.210313971268017, 52.986888251871669],
            [-2.210673702442383, 52.985817000304735],
            [-2.21007998451487, 52.98502340654035],
            [-2.210346829052463, 52.984762247260349],
            [-2.208853782679578, 52.984031363441979],
            [-2.208419251437781, 52.983187141553309],
            [-2.206414310564053, 52.982849053733133],
            [-2.204341506780356, 52.981381105518103],
            [-2.208037515421355, 52.980619594151648],
            [-2.212514293425612, 52.980562226073665],
            [-2.216491103245895, 52.980301546837637],
            [-2.217926275671423, 52.9804832006079],
            [-2.220065416745125, 52.979684609331464],
            [-2.221095405275937, 52.97868579227395],
            [-2.223724958843638, 52.977136519980888],
            [-2.224505046267657, 52.975934088096658],
            [-2.225767615061414, 52.975879556960585],
            [-2.226353830382658, 52.976346779379163],
            [-2.227104459860287, 52.976357931144754],
            [-2.229464062901351, 52.975360086288873],
            [-2.229996437173028, 52.975777954121909],
            [-2.23203411034955, 52.975026086928288],
            [-2.232137745454501, 52.973802451062262],
            [-2.233361953484645, 52.973275080934776],
            [-2.233498480885516, 52.972911647842764],
            [-2.234393060624528, 52.972567395970664],
            [-2.236057056924053, 52.972690848044174],
            [-2.238544710923307, 52.972035056999715],
            [-2.245925400590485, 52.968246322298263],
            [-2.251084195440496, 52.966377451633129],
            [-2.25228875540606, 52.965619802541667],
            [-2.252451437963519, 52.964411302751863],
            [-2.253575563753623, 52.963914499329455],
            [-2.254282070510007, 52.964053220801894],
            [-2.255849957757369, 52.965594205049314],
            [-2.258702410689359, 52.965062154749496],
            [-2.262566681056575, 52.965673032721959],
            [-2.263653654449395, 52.963489833846239],
            [-2.264404457438361, 52.962832848518779],
            [-2.26735582463839, 52.963176821458262],
            [-2.268375564656286, 52.963645557920806],
            [-2.269313421129561, 52.96315352083829],
            [-2.270225812673397, 52.963355504193132],
            [-2.269751490108246, 52.961778969386266],
            [-2.273328724132502, 52.957740907614934],
            [-2.274576684435647, 52.95430143476375],
            [-2.27541646067987, 52.953876994701098],
            [-2.278020648956309, 52.951230778987792],
            [-2.27703139101304, 52.950146288465682],
            [-2.277387454726661, 52.949271700225502],
            [-2.277141275786969, 52.948478522414852],
            [-2.276548456712751, 52.948635418931794],
            [-2.276360561029305, 52.948343705061426],
            [-2.276278657095308, 52.94786926209445],
            [-2.276855297286349, 52.947743867070628],
            [-2.276180629306174, 52.947199789310226],
            [-2.276526486297294, 52.94705875171767],
            [-2.27533104907498, 52.946528465701263],
            [-2.276051735461599, 52.946356895137598],
            [-2.275432198745915, 52.946052698494022],
            [-2.275289932302066, 52.945444454471456],
            [-2.275725988491109, 52.945214216166306],
            [-2.274508087557535, 52.945121751692383],
            [-2.274794933008152, 52.944359696457816],
            [-2.274118209145048, 52.943948652828311],
            [-2.274039961008634, 52.943341158235064],
            [-2.273444521393984, 52.943309270664038],
            [-2.273149635480989, 52.941146229650577],
            [-2.272124348603356, 52.940665859475878],
            [-2.270699590136978, 52.939118464977795],
            [-2.269794621722044, 52.939310198712271],
            [-2.269318824765905, 52.938408754756196],
            [-2.268203629247955, 52.938049010979],
            [-2.268294019859191, 52.937748564607389],
            [-2.267363898774522, 52.937719200300343],
            [-2.267047289042993, 52.937045716378741],
            [-2.267432934216576, 52.936850679732359],
            [-2.266456489593807, 52.93654364369538],
            [-2.265164509690633, 52.935000378658785],
            [-2.263265631112669, 52.933949261925484],
            [-2.264071263018314, 52.933583406396799],
            [-2.263822862843689, 52.932614912949994],
            [-2.264426203043111, 52.93250390127853],
            [-2.264651288139431, 52.930382825056675],
            [-2.263279097183134, 52.930287892767446],
            [-2.262585781685072, 52.92757466021942],
            [-2.264113578720389, 52.923680700383699],
            [-2.266536974957614, 52.923743602640613],
            [-2.266802882141115, 52.923446358168611],
            [-2.269349836453313, 52.92328778831051],
            [-2.2693425873206, 52.923557483999843],
            [-2.269973808675786, 52.923646842162604],
            [-2.270109266242887, 52.923424498119566],
            [-2.27177288011371, 52.924008601931185],
            [-2.27169849231224, 52.924243393130823],
            [-2.272538426673855, 52.9241686544147],
            [-2.272671100087265, 52.924451512935498],
            [-2.276121844172633, 52.924663774927232],
            [-2.276593392760286, 52.92490089385781],
            [-2.276164393643712, 52.925272252043399],
            [-2.276881317892733, 52.925266087476381],
            [-2.277651438022386, 52.925901630664484],
            [-2.27805813007534, 52.925764041360573],
            [-2.279091220704892, 52.926576947224703],
            [-2.279864838068512, 52.926362978429957],
            [-2.282240862165389, 52.926891318265476],
            [-2.285475828523562, 52.926602218274653],
            [-2.286001190039236, 52.927093567821132],
            [-2.286761965557601, 52.926965882393723],
            [-2.287118452458749, 52.927561011781712],
            [-2.288903002677146, 52.92837650728471],
            [-2.293197370598904, 52.929654147183086],
            [-2.293503808670728, 52.930311407033102],
            [-2.296411344386955, 52.931932146483227],
            [-2.297816928194999, 52.931239149718373],
            [-2.301155525354581, 52.930631141990204],
            [-2.302052835696712, 52.930026579227658],
            [-2.305954817587831, 52.932331334327131],
            [-2.308799109949028, 52.93062858837758],
            [-2.310629395351511, 52.931108337728489],
            [-2.312368592089162, 52.930296535296165],
            [-2.319913234615307, 52.932416801338377],
            [-2.322817951305916, 52.930510398063355],
            [-2.323104044325306, 52.930772107730711],
            [-2.325543204715346, 52.929897976999648],
            [-2.328078493196659, 52.929944934865084],
            [-2.330350214337312, 52.928786206461282],
            [-2.332857642946107, 52.928118489294221],
            [-2.333191114045288, 52.926602854193483],
            [-2.336452185802977, 52.924356216665188],
            [-2.339000630384647, 52.923081473355197],
            [-2.34005294229314, 52.92203480352903],
            [-2.341415215721906, 52.92163716163536],
            [-2.343188968777273, 52.920049926113883],
            [-2.346153496072843, 52.916737740280809],
            [-2.347371159659015, 52.914094914249972],
            [-2.348288082490119, 52.914944417356743],
            [-2.350677303598812, 52.915791371704941],
            [-2.350846728561188, 52.916329332519972],
            [-2.353261863744317, 52.917055701161551],
            [-2.354654443933272, 52.918034084137197],
            [-2.357917485970344, 52.918581631435586],
            [-2.361511057453711, 52.917555848158443],
            [-2.365273963612023, 52.917585682469237],
            [-2.366554484133891, 52.916708871190146],
            [-2.36870411472326, 52.916259939741003],
            [-2.369149552547469, 52.915651775017089],
            [-2.371732597837526, 52.91459465945146],
            [-2.373500829654856, 52.914600796358449],
            [-2.377108733236418, 52.91325087806073],
            [-2.379172448755049, 52.912872146361032],
            [-2.379203193828888, 52.910604933508708],
            [-2.38267479518001, 52.906333738064703],
            [-2.382449551227868, 52.905575761706032],
            [-2.380874805119813, 52.903856664164813],
            [-2.38116892756107, 52.902812054859133],
            [-2.38245902008233, 52.901419947406509],
            [-2.382132361216819, 52.899439742088731],
            [-2.382699885893771, 52.897881853443003],
            [-2.382262159791289, 52.897129055190604],
            [-2.380801720454223, 52.896543146929673],
            [-2.380573581551339, 52.894936577919353],
            [-2.379692543013054, 52.893964950522076],
            [-2.378938538745721, 52.891705631576613],
            [-2.380266182652702, 52.891066735523019],
            [-2.382637111739108, 52.891096872155366],
            [-2.384428890741129, 52.89034496401414],
            [-2.385270001084446, 52.889325537393212],
            [-2.384914487683095, 52.888621023724546],
            [-2.385555247122278, 52.887973506057484],
            [-2.386603773564862, 52.888558900798131],
            [-2.388921524167636, 52.88897203301908],
            [-2.390473878864311, 52.889052335990428],
            [-2.392235461616124, 52.888601553464753],
            [-2.39399492866012, 52.888576848934179],
            [-2.394921773435276, 52.889332473667899],
            [-2.396032360116123, 52.889534630748528],
            [-2.398982340523061, 52.888699526119844],
            [-2.400554363724044, 52.888816487771003],
            [-2.403080177340644, 52.887931472048223],
            [-2.404738682358727, 52.8880804464482],
            [-2.405081132448541, 52.887828474201896],
            [-2.405410115798892, 52.888204006796627],
            [-2.407443298207417, 52.887574979389576],
            [-2.411858648104855, 52.88787527017827],
            [-2.414810343124407, 52.884929054099047],
            [-2.415897920569501, 52.885058291793392],
            [-2.416577144265057, 52.885840685016738],
            [-2.419291951355537, 52.886727370359466],
            [-2.420339853793571, 52.885208050949181],
            [-2.424083072584825, 52.88365575068439],
            [-2.42585147109159, 52.881987277836885],
            [-2.428038330949203, 52.882234711581319],
            [-2.431547411462024, 52.881697923445721],
            [-2.435608344035319, 52.881817931741992],
            [-2.438350233720616, 52.881251393371407],
            [-2.44041237301105, 52.881212297464991],
            [-2.442020456322618, 52.880653471124113],
            [-2.444861553604631, 52.874843780301418],
            [-2.4454753995626, 52.873996471765452],
            [-2.446142456962771, 52.873993067449035],
            [-2.449070125123951, 52.874931311078022],
            [-2.451436299367243, 52.878158529675687],
            [-2.45485260946594, 52.877476674638849],
            [-2.454826480853002, 52.878103337836116],
            [-2.455903719589271, 52.879517736801048],
            [-2.457594643338288, 52.879089626447474],
            [-2.457321151756635, 52.879928494870235],
            [-2.457713261990533, 52.880052835540688],
            [-2.457684316053582, 52.880831431283575],
            [-2.456174029728088, 52.884317049379135],
            [-2.456346973726404, 52.88521892216005],
            [-2.457193594131893, 52.886163149052059],
            [-2.462389539935821, 52.888990871173945],
            [-2.465244235382361, 52.892146687804726],
            [-2.468231098495106, 52.89704406017011],
            [-2.469788376340399, 52.903858164766156],
            [-2.470841832625254, 52.905854131669329],
            [-2.468784439045486, 52.906300954394844],
            [-2.467139362122627, 52.907070636857902],
            [-2.466053757675402, 52.907052429379661],
            [-2.463921677098127, 52.908033430215347],
            [-2.463414991851328, 52.907787301762525],
            [-2.462042954824551, 52.908379655374915],
            [-2.46243293681186, 52.908557924898012],
            [-2.462198228572205, 52.908858185187704],
            [-2.460855010092446, 52.909368609340234],
            [-2.460200690402732, 52.911605906829834],
            [-2.459436377388547, 52.911536951510072],
            [-2.459977339979651, 52.912138943375041],
            [-2.459463004447673, 52.912436685124895],
            [-2.459853984085863, 52.912706650810996],
            [-2.459288806967196, 52.912978519455812],
            [-2.459585583259292, 52.913339643022063],
            [-2.458316653899278, 52.91357827116915],
            [-2.4580625320108, 52.914439534763389],
            [-2.457286157994552, 52.914571075099431],
            [-2.457449665555329, 52.914140753310868],
            [-2.456824568112759, 52.914091920323514],
            [-2.455713473334926, 52.915485946427019],
            [-2.45559273214234, 52.916592102189696],
            [-2.456748141317819, 52.917850669951036],
            [-2.455993174335391, 52.918751609063428],
            [-2.456816199871959, 52.919088243902344],
            [-2.456458564580316, 52.919870794909251],
            [-2.457578837539017, 52.920184707050851],
            [-2.457309941457457, 52.920919274901919],
            [-2.456884210657335, 52.921026988061733],
            [-2.457507108889291, 52.921421919766011],
            [-2.45704494820898, 52.921883954670271],
            [-2.457436537704396, 52.922064032040062],
            [-2.456772814422715, 52.922312895275034],
            [-2.456724086868611, 52.922911774064502],
            [-2.456255507485072, 52.922907282692258],
            [-2.456182722297601, 52.923765147130872],
            [-2.45677487169521, 52.924344482486795],
            [-2.456242593164703, 52.924366305161563],
            [-2.455835321033751, 52.925667730002459],
            [-2.453383321530807, 52.925894658583502],
            [-2.451862039704599, 52.925222659291336],
            [-2.451231165816942, 52.925348212166973],
            [-2.450648182878957, 52.925072654778774],
            [-2.450218943544735, 52.92585096296817],
            [-2.450477807692701, 52.927573240047437],
            [-2.448883682775873, 52.928344269717485],
            [-2.448114817667091, 52.92807749284669],
            [-2.446882673241884, 52.928613406097412],
            [-2.446589743521291, 52.92934803869845],
            [-2.445354760283279, 52.930192281110102],
            [-2.445901715742236, 52.930432941115356],
            [-2.445492209492439, 52.931986937485668],
            [-2.444974550166348, 52.932573185300555],
            [-2.444104967952226, 52.932645658052301],
            [-2.443562775534103, 52.934181265522035],
            [-2.441015150121878, 52.936908228849497],
            [-2.439423493577468, 52.940468509304644],
            [-2.438163420571278, 52.941230964630719],
            [-2.438065065333489, 52.941658320373612],
            [-2.437286310695942, 52.941755577900388],
            [-2.437709975239246, 52.942597215978438],
            [-2.437123204776879, 52.943583707670101],
            [-2.435135046825272, 52.944024293041458],
            [-2.434721136145458, 52.944448306686013],
            [-2.433908874903548, 52.944474648949694],
            [-2.43227847899994, 52.945579987324216],
            [-2.431482526043184, 52.945751880543675],
            [-2.431239905735776, 52.946195934932391],
            [-2.430532684461613, 52.946013320533602],
            [-2.429379542625481, 52.946334817468056],
            [-2.429279084897366, 52.946710034995562],
            [-2.428355760715779, 52.947098110977471],
            [-2.428416764076423, 52.947849397537077],
            [-2.427615392463133, 52.948533675006374],
            [-2.427681535344341, 52.948901998622247],
            [-2.426050713409369, 52.949391484110038],
            [-2.425269203281864, 52.94998308436692],
            [-2.421636881521219, 52.949289473678739],
            [-2.420662306947656, 52.948856947637736],
            [-2.418951907972913, 52.949590227617918],
            [-2.418177413297002, 52.948914264090121],
            [-2.416686002067157, 52.949054344936428],
            [-2.416788192061874, 52.948692616258796],
            [-2.41607117206042, 52.948417360428955],
            [-2.414968165813847, 52.948707977877127],
            [-2.41517392494684, 52.948281165700379],
            [-2.414975456810286, 52.948070610510477],
            [-2.41416858482859, 52.948201973794447],
            [-2.414603403559164, 52.947916394724096],
            [-2.414042954626244, 52.947670244760289],
            [-2.415001360382692, 52.947045738821345],
            [-2.413541494646235, 52.94658698211844],
            [-2.411015797680392, 52.946879810984996],
            [-2.410355382968385, 52.947231778511366],
            [-2.408768361790743, 52.94638326651139],
            [-2.408583823570626, 52.946856739059285],
            [-2.407510484623514, 52.947454619592548],
            [-2.405602398891611, 52.947278667733855],
            [-2.405947869841185, 52.947935504700006],
            [-2.405435547749192, 52.94837413477056],
            [-2.404893446860076, 52.948334633124134],
            [-2.404661240323943, 52.948968271435405],
            [-2.405603630765935, 52.950590323010374],
            [-2.405306583775077, 52.953202728362172],
            [-2.407027156923829, 52.955730927584476],
            [-2.407478593139129, 52.957357340793983],
            [-2.407175197944503, 52.957861781425464],
            [-2.408284608131784, 52.958852189048073],
            [-2.410362065377616, 52.959252245549237],
            [-2.41379340941395, 52.960811670434559],
            [-2.413452104932781, 52.961394465707095],
            [-2.412898469187013, 52.961724500239193],
            [-2.41076807301145, 52.96109454534718],
            [-2.410989549898607, 52.961686173298581],
            [-2.410584774597076, 52.962331205819943],
            [-2.409460575927776, 52.961996189929458],
            [-2.409363924054993, 52.96248104547103],
            [-2.408771519126588, 52.962493872522671],
            [-2.405531333171627, 52.962265862734768],
            [-2.40282006273124, 52.96159819424723],
            [-2.402874709946399, 52.961231246145054],
            [-2.4004361394644, 52.960903292045089],
            [-2.400347005650651, 52.960601552521602],
            [-2.399153201416321, 52.96061545802587],
            [-2.397951838187904, 52.957376154207253],
            [-2.393766540866547, 52.950563632940614],
            [-2.393039778628603, 52.951334624902714],
            [-2.392434107903575, 52.951181112683173],
            [-2.392457030496979, 52.951575667044878],
            [-2.391793816549158, 52.951642579422952],
            [-2.391775774924849, 52.952116374783877],
            [-2.387109046829602, 52.952716878467641],
            [-2.386164252131332, 52.953257513476345],
            [-2.386275208853245, 52.954350250433315],
            [-2.384750344302025, 52.959806301988813],
            [-2.382579944695412, 52.961668699187207],
            [-2.380822934688235, 52.962310785673445],
            [-2.377131490917011, 52.964951025952729],
            [-2.379096846894998, 52.967851900040252],
            [-2.379131505755097, 52.969259508518434],
            [-2.382831957109578, 52.970641876399341],
            [-2.381807558209605, 52.971492860587389],
            [-2.379763718291031, 52.975980556913662],
            [-2.379067364218503, 52.975198917677425],
            [-2.376382910489296, 52.97474989940622],
            [-2.374377144298122, 52.975629093559441],
            [-2.373621633875697, 52.976436010080661],
            [-2.370736732176816, 52.977450045799237],
            [-2.368730428492549, 52.979343132778624],
            [-2.370269059232441, 52.982145688854906],
            [-2.371535907456456, 52.982936383137073],
            [-2.373507302443042, 52.983551358779224],
            [-2.375076574540494, 52.986227006686278],
            [-2.37598323573586, 52.986513594618373],
            [-2.377572236926414, 52.989371627631968],
            [-2.378660738617253, 52.989830210442278],
            [-2.379806587946583, 52.991049089093153],
            [-2.37984471995455, 52.991659336045075],
            [-2.3788165735405, 52.991975443463922],
            [-2.378620866628706, 52.992934319114738],
            [-2.376697885206246, 52.993659569043551],
            [-2.376250653396411, 52.994483500226515],
            [-2.376474233799355, 52.995351151265744],
            [-2.377284184383826, 52.995629945165611],
            [-2.376524014491646, 52.996791067183715],
            [-2.376608775493937, 52.997627695154691],
            [-2.377990862352684, 52.998240866449059],
            [-2.379875325204106, 52.998029004142481],
            [-2.380793391616861, 52.998394623898612],
            [-2.380244345631013, 52.998989670577693],
            [-2.380804930323947, 52.999705215264342],
            [-2.379991977202008, 53.000791917308746],
            [-2.380502539375706, 53.001408741786442],
            [-2.380512205628178, 53.00199930234956],
            [-2.380147801079601, 53.002075978003056],
            [-2.380666329681477, 53.00274940823784],
            [-2.380513202105902, 53.003806131278424],
            [-2.38097717176615, 53.004034768651671],
            [-2.38080549829744, 53.005185039092567],
            [-2.381808729207378, 53.00623265893465],
            [-2.381790711267578, 53.00689252478557],
            [-2.382951432065167, 53.007205210428921],
            [-2.383044365787592, 53.007770332136872],
            [-2.382276827244673, 53.008104505220686],
            [-2.38168989960806, 53.007965261118024],
            [-2.381728840704335, 53.008324704323933],
            [-2.380997422887609, 53.008532005263064],
            [-2.3811818070285, 53.008822664294215],
            [-2.380703235659279, 53.008803523101484],
            [-2.380780806867467, 53.009150257919103],
            [-2.379283759879657, 53.009577537583603],
            [-2.378323453736755, 53.010371648268787],
            [-2.376357315779853, 53.010166644492223],
            [-2.374705113170964, 53.01058536837207],
            [-2.373300095718646, 53.011672089531423],
            [-2.373094597076127, 53.013247642299206],
            [-2.370467646713371, 53.014559293087757],
            [-2.377518733191208, 53.019940508607789],
            [-2.381439225719497, 53.0238095223242],
            [-2.381291531422923, 53.024136303523171],
            [-2.384227098118354, 53.026151214444972],
            [-2.379784657566773, 53.027574994263887],
            [-2.381307866966082, 53.030549130511822],
            [-2.382636091356023, 53.031520187773566],
            [-2.3841609894048, 53.031795731776967],
            [-2.386170307558078, 53.033547503271791],
            [-2.384286891601368, 53.033879913424158],
            [-2.383844125141647, 53.036578992771567],
            [-2.384551099255208, 53.038432071277924],
            [-2.383866736153323, 53.040801128815609],
            [-2.38445922758785, 53.041681949536368],
            [-2.383227687448923, 53.041729972612153],
            [-2.382535313916328, 53.042872027350143],
            [-2.382475529766533, 53.044205311166237],
            [-2.383528481058436, 53.045537707053313],
            [-2.382158809564809, 53.045833366021341],
            [-2.383579444733219, 53.047412679206133],
            [-2.38512327433889, 53.048074683201598],
            [-2.383277275494271, 53.048493250257089],
            [-2.382270746070235, 53.049210228859344],
            [-2.380727632081485, 53.049643962416567],
            [-2.382192393860934, 53.051144045494809],
            [-2.381248426969118, 53.05253499940229],
            [-2.377461079635267, 53.052590231037584],
            [-2.377173049389078, 53.052920147535403],
            [-2.376200649699001, 53.052954690202739],
            [-2.375059490808907, 53.054514314837071],
            [-2.373618441121599, 53.054187152687746],
            [-2.372298318532628, 53.054558949170939],
            [-2.370747769348339, 53.054334571223386],
            [-2.370292202786049, 53.054794436684347],
            [-2.366600450211537, 53.054536202750228],
            [-2.36171894991548, 53.055080613542238],
            [-2.361500279398009, 53.054088878543091],
            [-2.359428822615147, 53.054723498900373],
            [-2.3575786475945, 53.054702110243433],
            [-2.35667956212118, 53.058198884387259],
            [-2.354644576631685, 53.058401833352548],
            [-2.354471190499345, 53.058727756635641],
            [-2.353734964936593, 53.058832425212501],
            [-2.352790843161955, 53.058329142672406],
            [-2.3527273419412, 53.057861896665152],
            [-2.351649899164382, 53.057655641555094],
            [-2.351645294412302, 53.05727361740967],
            [-2.350000766462053, 53.057050146031884],
            [-2.350083115901267, 53.05653392784609],
            [-2.349041423903686, 53.056314959082279],
            [-2.348594219096296, 53.05580928442776],
            [-2.346552017267397, 53.05696226672881],
            [-2.344481202364218, 53.059030388966363],
            [-2.341447848477393, 53.059979400478284],
            [-2.339094363830382, 53.063153899702939],
            [-2.338847526452361, 53.064404988726984],
            [-2.336252502541407, 53.066323439959113],
            [-2.335874296473814, 53.067390616799692],
            [-2.33179909164688, 53.069584612165521],
            [-2.33051479020904, 53.070843966062377],
            [-2.32996088514325, 53.073160196656957],
            [-2.328824145634492, 53.074021803918853],
            [-2.329500855412539, 53.075267616703989],
            [-2.329009212883921, 53.076563406950349],
            [-2.323709295242417, 53.077639563581883],
            [-2.322905173864585, 53.078099294278793],
            [-2.322407170395098, 53.078974384525068],
            [-2.318419989073379, 53.081199138438834],
            [-2.31775089302848, 53.081360933759626],
            [-2.316727853828347, 53.081098485098963],
            [-2.314958270914751, 53.081021384462467],
            [-2.309646509617018, 53.082443922682131],
            [-2.306250732894759, 53.082521033402294],
            [-2.303396317136532, 53.082944544388482],
            [-2.301892619643448, 53.082043171642916],
            [-2.301468707308297, 53.081198375153257],
            [-2.299803920004234, 53.081375174319355],
            [-2.296737775060058, 53.080966671070193],
            [-2.295766393395986, 53.080380306738384],
            [-2.29526319617147, 53.079709174413601],
            [-2.295442080389753, 53.079236803565827],
            [-2.294460756388986, 53.078279204248751],
            [-2.293375738423898, 53.078309752374857],
            [-2.291949983917679, 53.078944294781337],
            [-2.290656862036364, 53.078887235918643],
            [-2.288486134003773, 53.079742888812696],
            [-2.285025669013293, 53.079717974551166],
            [-2.281690086293667, 53.080299426309033],
            [-2.278496335048342, 53.080896632648447],
            [-2.275288914799072, 53.082172459471387],
            [-2.274555153952129, 53.083928821844573],
            [-2.2754700565337, 53.084821117776002],
            [-2.276268068902757, 53.084940618739836],
            [-2.277105751522231, 53.085888813577206],
            [-2.271112038497666, 53.086714342532495],
            [-2.269367314477909, 53.087532716516534],
            [-2.268732582807733, 53.088461823488394],
            [-2.267976325580312, 53.088581286855955],
            [-2.267037357425678, 53.08813035074872],
            [-2.266195613282792, 53.088187970265807],
            [-2.260977665828676, 53.088992371833719],
            [-2.258101739669833, 53.090207676887694],
            [-2.257125465914026, 53.089999448174467],
            [-2.254796513805629, 53.091575747683564],
            [-2.253284880616148, 53.093267120503199],
            [-2.253248636313258, 53.093960253180427],
            [-2.250675017079528, 53.093072195381254],
            [-2.250915550109807, 53.092836175024694],
            [-2.249853217949714, 53.093242020199334],
            [-2.24942607215617, 53.09297144777193],
            [-2.249844727515752, 53.092807867085675],
            [-2.249736763067884, 53.092214816760844],
            [-2.249088241823267, 53.091618405376856],
            [-2.249672733154443, 53.090699397562162],
            [-2.247605561702651, 53.08981200644034],
            [-2.246580323412223, 53.090169204527818],
            [-2.246377936958004, 53.091594388377651],
            [-2.243460828663461, 53.093817095882066],
            [-2.242570780795592, 53.095134011967254],
            [-2.240519324885683, 53.096538671862845],
            [-2.238995155190355, 53.098255954697969],
            [-2.238921205561184, 53.100001773976835],
            [-2.237769396093007, 53.100754666981764],
            [-2.237951634632933, 53.101030265739539],
            [-2.233999580620816, 53.102923108243587],
            [-2.23195277733287, 53.1030934155119],
            [-2.230353654132124, 53.103750026422787],
            [-2.227015814570702, 53.104111512304236],
            [-2.224696453218969, 53.103588261981749],
            [-2.223073873604659, 53.103778288858223],
            [-2.222076189163762, 53.10465838411811],
            [-2.223154823507357, 53.104988059675634],
            [-2.222867435890883, 53.106025931915617],
            [-2.221920041729087, 53.10682053552231],
            [-2.222430469764774, 53.107605222169575],
            [-2.218724094863432, 53.109004496475279],
            [-2.217043999444476, 53.109980186560165],
            [-2.217153860340355, 53.110733265398025],
            [-2.216500579056601, 53.111532681013294],
            [-2.212860129027914, 53.114286295003382],
            [-2.21290864280395, 53.115033194657087],
            [-2.211315340540571, 53.115807293470993],
            [-2.209472159386482, 53.115289192369509],
            [-2.205878934785259, 53.112457638370984],
            [-2.203815628339448, 53.109576619306061],
            [-2.201644943470785, 53.107629704305815],
            [-2.201444421191806, 53.106914518492346],
            [-2.200293223362597, 53.106373526774881],
            [-2.199303738417357, 53.10526594472045],
            [-2.196770405259494, 53.10459958371824],
            [-2.195213378144396, 53.103799431053218],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000196",
        LAD13CDO: "41UF",
        LAD13NM: "South Staffordshire",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.095093005499738, 52.781074421629754],
            [-2.0916734358856, 52.780691470756288],
            [-2.089281261776342, 52.781004336832297],
            [-2.087151615545524, 52.780651738481694],
            [-2.086036754508551, 52.779905526945576],
            [-2.081610784676786, 52.779546386456559],
            [-2.079332593355112, 52.77977087620112],
            [-2.077667438173576, 52.780432709873743],
            [-2.074735963430476, 52.778408365082811],
            [-2.069992436339992, 52.775860945734358],
            [-2.068474030907112, 52.774631171360888],
            [-2.065968701839552, 52.773530482345727],
            [-2.067807989359555, 52.772426428262868],
            [-2.069385573167168, 52.770858640382365],
            [-2.073232489250627, 52.769453056256225],
            [-2.076168705644778, 52.766951215645129],
            [-2.074367245799439, 52.766516370692344],
            [-2.072680814705333, 52.76660551684936],
            [-2.066428531423154, 52.765979030649412],
            [-2.064139297106452, 52.765323161276172],
            [-2.063289008157403, 52.765589709683567],
            [-2.060545915673289, 52.764622975662483],
            [-2.055976397160218, 52.765356078046608],
            [-2.053487588647513, 52.763744511080283],
            [-2.053892862027249, 52.7631123631026],
            [-2.051601416364211, 52.761557299767134],
            [-2.047631711472242, 52.763156407804445],
            [-2.04714760380752, 52.763605179588012],
            [-2.044520006097178, 52.762075284184341],
            [-2.042771534200055, 52.760750874802881],
            [-2.037360240213494, 52.754406103133689],
            [-2.033712754834201, 52.747205679772115],
            [-2.032353161443507, 52.745667050251853],
            [-2.031290018760169, 52.742209954994394],
            [-2.028970251158477, 52.739758197609859],
            [-2.026807114167203, 52.73630132476967],
            [-2.025697046610561, 52.734996287738674],
            [-2.025330999668653, 52.734581049217589],
            [-2.025795906967909, 52.73189935890889],
            [-2.025253328830932, 52.728377361059565],
            [-2.026040044938307, 52.726633210683978],
            [-2.027039005325755, 52.725809539215348],
            [-2.02625947412666, 52.724662645277014],
            [-2.02985303394411, 52.713994736735387],
            [-2.030628865910368, 52.712136386415892],
            [-2.031095999832004, 52.705087511504757],
            [-2.036529229700113, 52.703618844802584],
            [-2.039224432615192, 52.702267737254914],
            [-2.040783736879358, 52.701747607701357],
            [-2.044839564061205, 52.698418176077524],
            [-2.047670099565395, 52.694161375562921],
            [-2.052024058844467, 52.691251388777751],
            [-2.054638310251103, 52.690042898718325],
            [-2.052968416118494, 52.687957164961524],
            [-2.05165353272227, 52.686961695771217],
            [-2.051636245138131, 52.686105889311499],
            [-2.05087641860399, 52.685310635991819],
            [-2.049693597467552, 52.684560504939057],
            [-2.049149097787326, 52.683139469928349],
            [-2.049780522075325, 52.682957614157097],
            [-2.049500759157669, 52.68148972213833],
            [-2.051547333688322, 52.679727772462385],
            [-2.051914749867872, 52.678985964841665],
            [-2.051320422842605, 52.677957808371843],
            [-2.050049037504485, 52.677196031915351],
            [-2.047591252833487, 52.677530565888603],
            [-2.045795565504362, 52.676001237442897],
            [-2.042940994982863, 52.675881850814115],
            [-2.041656561786211, 52.675179320622426],
            [-2.04108127979845, 52.673649482317835],
            [-2.035514098347765, 52.671155762618362],
            [-2.031013915811428, 52.671000615334592],
            [-2.029813248866005, 52.671199597699136],
            [-2.028269196606717, 52.670961753286178],
            [-2.02644190434281, 52.67183147972429],
            [-2.025246961613619, 52.671776905966837],
            [-2.023916277175478, 52.67228240403486],
            [-2.022727254421737, 52.672253873698018],
            [-2.022258601904601, 52.672540733952395],
            [-2.0191382625964, 52.672732765603534],
            [-2.016799783740627, 52.671786513677475],
            [-2.015807013267425, 52.670524503362671],
            [-2.013514547879223, 52.669519747189327],
            [-2.005841198302493, 52.669511401120765],
            [-2.002259502112735, 52.667586843214089],
            [-2.001298311793805, 52.666721154387744],
            [-2.001710831972809, 52.666036135153149],
            [-2.000783692728665, 52.665079645637356],
            [-2.001070463848996, 52.662400719012801],
            [-2.000431736392782, 52.662292848333308],
            [-2.000748118270813, 52.661290496738005],
            [-2.000314902326283, 52.659995985477906],
            [-1.997652315859141, 52.657825858767424],
            [-1.997754372320343, 52.656755188815225],
            [-1.996734365377827, 52.656045880971341],
            [-1.996267288868794, 52.655138808556934],
            [-1.995000491775407, 52.654262269855678],
            [-1.994125429310929, 52.653748021284464],
            [-1.994461053062228, 52.653222139677737],
            [-1.993457363392459, 52.653060276275653],
            [-1.993326119204531, 52.650891954823216],
            [-1.994131788486637, 52.650258222015694],
            [-1.99363232575331, 52.64923427069894],
            [-1.994260667411941, 52.647998217632626],
            [-1.992787358762851, 52.646085131708766],
            [-1.992889458957886, 52.645320113794185],
            [-1.991683490682093, 52.645082709761709],
            [-1.991541691206898, 52.644635911660643],
            [-1.989746008981836, 52.644609703737395],
            [-1.988355387439436, 52.644177173458694],
            [-1.983743020820905, 52.643593208725356],
            [-1.985122564612026, 52.641596772431029],
            [-1.986556421696227, 52.640393218173074],
            [-1.988693625836802, 52.639121392122554],
            [-1.990861979367305, 52.636655695112069],
            [-1.993285127429994, 52.638154437668668],
            [-1.995742552509269, 52.638304674226667],
            [-2.003852166360919, 52.637136905828143],
            [-2.007575500054088, 52.63478860327762],
            [-2.009443067894103, 52.634248182729785],
            [-2.010023840510274, 52.634600530823064],
            [-2.012533938779133, 52.633453197719838],
            [-2.014377827573773, 52.633150929506577],
            [-2.016117288848304, 52.630238929532304],
            [-2.018889722606011, 52.628554737446301],
            [-2.019432196243237, 52.629158758394013],
            [-2.020230970822165, 52.628078057278486],
            [-2.024085360497526, 52.62526983399524],
            [-2.025351233654415, 52.625002571914635],
            [-2.026645512230149, 52.62531063297623],
            [-2.026508279747262, 52.625564174613309],
            [-2.028979737309623, 52.625490773521634],
            [-2.029366923231621, 52.625692946373384],
            [-2.030861247334186, 52.624709086094747],
            [-2.030534265910454, 52.623987295621902],
            [-2.035515189584006, 52.621783409885296],
            [-2.032014073385668, 52.61944888017586],
            [-2.032096250489813, 52.618717092468401],
            [-2.030129052298575, 52.616938542443783],
            [-2.031269282397292, 52.616919365965416],
            [-2.031894410615846, 52.61740734127072],
            [-2.047385261466216, 52.62140615627785],
            [-2.047649004764864, 52.62079564636516],
            [-2.048492155806437, 52.620534599818775],
            [-2.050724053156066, 52.620506691071405],
            [-2.050597821120101, 52.621197157625744],
            [-2.05187165521077, 52.621648787973278],
            [-2.052111593201458, 52.622183571786138],
            [-2.052863496310099, 52.622200317512799],
            [-2.059347574056023, 52.621663241811412],
            [-2.064006644471728, 52.62073036754046],
            [-2.064527646520954, 52.619442752252247],
            [-2.068713071520479, 52.618100917015354],
            [-2.067362405218131, 52.613843250370429],
            [-2.067331598934021, 52.61206059929593],
            [-2.069884537330537, 52.612665024737801],
            [-2.071752935676121, 52.612771780733496],
            [-2.081008259717801, 52.61188929139103],
            [-2.081787978289949, 52.612653782312961],
            [-2.083118427404123, 52.612549471135274],
            [-2.084927180296852, 52.613869678482381],
            [-2.086274163258037, 52.616892855289379],
            [-2.088402515371101, 52.619815646893926],
            [-2.093181025805328, 52.619863215836759],
            [-2.094816536800327, 52.62071234459102],
            [-2.09738169416398, 52.623755983399626],
            [-2.097325426719142, 52.625688823861545],
            [-2.097636194776143, 52.626591137165065],
            [-2.09814729796498, 52.626568239401799],
            [-2.098459773466079, 52.626905993559028],
            [-2.097987902624488, 52.627297439333162],
            [-2.098928580593445, 52.628430261166642],
            [-2.098827843169789, 52.628962537714663],
            [-2.098577368216316, 52.631888904494872],
            [-2.099254531897127, 52.632745957781616],
            [-2.1008608639133, 52.633497037928024],
            [-2.101247116718812, 52.635037546639616],
            [-2.105000863557422, 52.635294973384589],
            [-2.106640629468464, 52.634528479709388],
            [-2.109375696845018, 52.634545757918275],
            [-2.110257112270101, 52.635437618789368],
            [-2.111640843887715, 52.635123477634217],
            [-2.113806844043876, 52.637894741872536],
            [-2.116119200260361, 52.637799000185638],
            [-2.116996032953537, 52.637453829388683],
            [-2.118251238260957, 52.637692606189141],
            [-2.125978313331143, 52.637243230440887],
            [-2.128677645616419, 52.637610700452541],
            [-2.13164928426654, 52.637607429558216],
            [-2.131549502343725, 52.635382583696249],
            [-2.133928729072058, 52.633534321900434],
            [-2.135091700972651, 52.632619642489004],
            [-2.137243955967955, 52.629630774810693],
            [-2.140291300193156, 52.629907678798709],
            [-2.152987804504086, 52.629737313660776],
            [-2.153027831922438, 52.627662430130677],
            [-2.152432195313458, 52.627163369668331],
            [-2.151558992636969, 52.624575445786647],
            [-2.156057458465286, 52.623806366479997],
            [-2.160292628159345, 52.621772615548863],
            [-2.162791363040006, 52.621206444550275],
            [-2.16173634313564, 52.619114178383732],
            [-2.164616616887718, 52.61910840693691],
            [-2.167503312239646, 52.620396182013963],
            [-2.168467354482414, 52.619877902106545],
            [-2.168718762711885, 52.619188928568427],
            [-2.168361966933144, 52.618975480762728],
            [-2.168961852287201, 52.618259939794157],
            [-2.169528857410121, 52.618213280767506],
            [-2.165021840240224, 52.616497216868332],
            [-2.165369043382811, 52.616135343630674],
            [-2.166010418087955, 52.616232433566424],
            [-2.16586874141262, 52.615872142961614],
            [-2.169295117932188, 52.615060007982507],
            [-2.170968550593392, 52.614318642579484],
            [-2.167615239196657, 52.611427838732098],
            [-2.168629024572515, 52.61122322899179],
            [-2.168694556111212, 52.610599245652402],
            [-2.169557783826916, 52.610420013910911],
            [-2.169162202380768, 52.60932292946746],
            [-2.170719365101915, 52.609116624361526],
            [-2.171025044600199, 52.610629161017044],
            [-2.172459028849805, 52.610259397354099],
            [-2.173754501039652, 52.609227273752417],
            [-2.177692705254516, 52.607339863641251],
            [-2.180318484479604, 52.604886175802136],
            [-2.183907073880316, 52.606052018894516],
            [-2.184259520253736, 52.606642997727782],
            [-2.186621353425473, 52.606451408820831],
            [-2.187214012958161, 52.60899457870368],
            [-2.188492647654825, 52.608944003453672],
            [-2.188539175633068, 52.608088102810449],
            [-2.19195873389909, 52.607976523997159],
            [-2.192097301299229, 52.60691011160376],
            [-2.193795032138119, 52.606812050920993],
            [-2.194538780569748, 52.607034675639291],
            [-2.194670693899162, 52.606812411136872],
            [-2.195203352710923, 52.607050661484195],
            [-2.195426688969714, 52.606468654130822],
            [-2.196401866585769, 52.606600087867896],
            [-2.196701061120865, 52.606144707973179],
            [-2.196388857175897, 52.605014312389798],
            [-2.194606476988517, 52.605319314552546],
            [-2.193049915840597, 52.603594029077335],
            [-2.193190197693576, 52.602925859200155],
            [-2.191936973573895, 52.603343227236721],
            [-2.1909427671943, 52.602213022520225],
            [-2.189877288849817, 52.599273279575137],
            [-2.188429121036394, 52.594849920975257],
            [-2.188557465791511, 52.592081757916333],
            [-2.188502363164513, 52.590594932772646],
            [-2.189314446241885, 52.588593404529732],
            [-2.193481647229444, 52.588664872297812],
            [-2.197841027624009, 52.588143443942911],
            [-2.197621191025114, 52.589147073765169],
            [-2.198205782466446, 52.58916227725009],
            [-2.198436067791889, 52.588840056105347],
            [-2.199313292425316, 52.588938368012904],
            [-2.199421842759269, 52.588464422278861],
            [-2.20056771305904, 52.58856496860156],
            [-2.202478960365045, 52.586914779561042],
            [-2.204205702359792, 52.586561208515029],
            [-2.206881688861147, 52.586568245177901],
            [-2.20670091002063, 52.585164352704332],
            [-2.201257877847893, 52.58521059757971],
            [-2.199905536453055, 52.583830257793473],
            [-2.200061705335815, 52.583446128696849],
            [-2.203967263710894, 52.583847595714758],
            [-2.202193379537798, 52.581634651906626],
            [-2.203327678360637, 52.581185913453844],
            [-2.201808277928792, 52.578764860343362],
            [-2.198126080413951, 52.579029992860605],
            [-2.197488363067683, 52.578328954436166],
            [-2.196944768151252, 52.578538424750199],
            [-2.196410667822825, 52.577902833589256],
            [-2.189436714165684, 52.57725525621462],
            [-2.189510715702517, 52.576622254150877],
            [-2.188955295833783, 52.576488295558555],
            [-2.188282745188157, 52.574160104657594],
            [-2.183450039240708, 52.576928475358947],
            [-2.182968918247667, 52.576914837033648],
            [-2.183495531018088, 52.576514872967145],
            [-2.182594172806843, 52.574094409578755],
            [-2.180678374933675, 52.574685286874697],
            [-2.180342614793551, 52.574489821237279],
            [-2.178578607130038, 52.571054788403828],
            [-2.179211729532833, 52.571091587819993],
            [-2.176980338626505, 52.566943443862513],
            [-2.180964339109014, 52.566380942602542],
            [-2.180432855287761, 52.565571770427681],
            [-2.179933152604613, 52.565673218108238],
            [-2.179719322774254, 52.565333727415833],
            [-2.18020968720309, 52.565114527969236],
            [-2.179637048087789, 52.563974588647987],
            [-2.175532017081427, 52.564471599523166],
            [-2.174812269336687, 52.560067642848288],
            [-2.175941011632891, 52.559773798485331],
            [-2.176396806745954, 52.556467551666401],
            [-2.175540403776838, 52.554392170203499],
            [-2.173054642722681, 52.554625975335838],
            [-2.170148567533068, 52.556018226996301],
            [-2.169050068340832, 52.554191266806463],
            [-2.16708682871436, 52.553762544981709],
            [-2.165012008818893, 52.55385176205337],
            [-2.165574903089488, 52.555672319235576],
            [-2.164547752172259, 52.55590838858673],
            [-2.163690512283067, 52.555434015584162],
            [-2.16203640180368, 52.555242113737549],
            [-2.161961535093451, 52.554938359329043],
            [-2.160039394209756, 52.555254723172851],
            [-2.157726751275802, 52.554856884287361],
            [-2.157899516917852, 52.554502453437252],
            [-2.157276782036206, 52.554411587175395],
            [-2.15736670435256, 52.553575411115155],
            [-2.155347394243755, 52.553933181527171],
            [-2.153060371308317, 52.553660176757866],
            [-2.153254529203422, 52.554777363860978],
            [-2.148922615691456, 52.554749638947037],
            [-2.147883376328747, 52.554919953049613],
            [-2.146798852143462, 52.55566476570867],
            [-2.145279493944522, 52.554722708820584],
            [-2.143700507349636, 52.555820501986716],
            [-2.14224215489162, 52.557755982442202],
            [-2.141577167359777, 52.557831396650059],
            [-2.14096283606637, 52.558524349085054],
            [-2.139071832210978, 52.558057319565229],
            [-2.134927981969564, 52.554531810285731],
            [-2.133492914547103, 52.554051584190418],
            [-2.133463003297035, 52.553430419244158],
            [-2.134571010655275, 52.551630292946228],
            [-2.134895751591523, 52.551722518176653],
            [-2.137478638380542, 52.549525117437177],
            [-2.138047599211504, 52.549433656559266],
            [-2.138157701489066, 52.548805136743582],
            [-2.139156637487677, 52.548049715346238],
            [-2.136833083232757, 52.546807332985239],
            [-2.136060723533764, 52.545493906847177],
            [-2.136967423440503, 52.544963356285002],
            [-2.136794544527674, 52.543429885356389],
            [-2.1354790583303, 52.543363976994158],
            [-2.135710157310932, 52.54180217159935],
            [-2.136104928750068, 52.541676758325842],
            [-2.13581885360278, 52.540714272346733],
            [-2.136491246227944, 52.540255014052249],
            [-2.136894240332687, 52.538042139829983],
            [-2.136591118773162, 52.537759309550431],
            [-2.13566220029632, 52.53773251117051],
            [-2.135656941664496, 52.537461921931659],
            [-2.136314539009036, 52.537476447864101],
            [-2.136608252134415, 52.536157293594592],
            [-2.135396000419615, 52.536031931725717],
            [-2.136754742067054, 52.534908429858461],
            [-2.135429188178844, 52.534841633631984],
            [-2.135569641596829, 52.534493564165139],
            [-2.13647765977351, 52.534458357218334],
            [-2.136520383690107, 52.533974652231343],
            [-2.137167685516411, 52.534013457985765],
            [-2.137402637223571, 52.533718316846254],
            [-2.137382098031152, 52.533278735464947],
            [-2.139392415243498, 52.532662371767053],
            [-2.144235070938056, 52.530417178759897],
            [-2.145980060622048, 52.529427046961935],
            [-2.145949566628451, 52.528678226762764],
            [-2.147030745859559, 52.528008937391242],
            [-2.151994817497792, 52.526184000664124],
            [-2.152215643520409, 52.525681181229622],
            [-2.15157442580373, 52.525237904615949],
            [-2.149852823148706, 52.524787908709122],
            [-2.148104085378821, 52.523710426860866],
            [-2.147573465387636, 52.524141706923807],
            [-2.146071801389237, 52.523322795222299],
            [-2.145750999191069, 52.523473322712221],
            [-2.145540744462079, 52.522736410141654],
            [-2.146048915361973, 52.522644985078912],
            [-2.144588568961903, 52.52136931630718],
            [-2.144522345057555, 52.519605578299995],
            [-2.145294231757241, 52.517715852269063],
            [-2.144129664704945, 52.517154510494407],
            [-2.148698787942023, 52.514776410659493],
            [-2.150422105297013, 52.514122460428659],
            [-2.152675360676228, 52.515027551426343],
            [-2.155181221743343, 52.515246337660898],
            [-2.156229639398688, 52.515027400165927],
            [-2.1569168930524, 52.515185610918934],
            [-2.15745365864054, 52.514880138804202],
            [-2.162077983627689, 52.514145703130218],
            [-2.16339296612728, 52.514696771870256],
            [-2.1646422919839, 52.514626714406774],
            [-2.167158832501802, 52.513380774954385],
            [-2.168391155524525, 52.513499490181701],
            [-2.169988319142253, 52.513080970268071],
            [-2.17038109080898, 52.513291667669073],
            [-2.172810572684594, 52.512825159075739],
            [-2.173822287308692, 52.512304057958126],
            [-2.178248886477595, 52.511634007915674],
            [-2.180260932346803, 52.510740953746947],
            [-2.180521125982517, 52.510234423925795],
            [-2.185651696003772, 52.508515692785807],
            [-2.185854700036328, 52.508788667466369],
            [-2.187987737471902, 52.508348383321746],
            [-2.187108920981134, 52.507492138872898],
            [-2.188006171430397, 52.50714550164173],
            [-2.187689213042298, 52.506063617919402],
            [-2.186624447174228, 52.504772552043825],
            [-2.189426440510306, 52.504747417125984],
            [-2.191754103552835, 52.503382587265826],
            [-2.1847892985084, 52.500332609785922],
            [-2.184614045700688, 52.499992165018277],
            [-2.188665011951696, 52.494492911110918],
            [-2.185181426530876, 52.494798685960639],
            [-2.184274861107203, 52.492865466255274],
            [-2.182253768161067, 52.493135602434876],
            [-2.179521552293614, 52.488782355196143],
            [-2.17763636824508, 52.485102018597928],
            [-2.178488017501535, 52.482681535972354],
            [-2.177878204469986, 52.479351672738836],
            [-2.173769098446051, 52.474211927491226],
            [-2.171810538093394, 52.472571424267521],
            [-2.169102471155876, 52.472373956517195],
            [-2.168084983987772, 52.471542936593551],
            [-2.167425408728762, 52.471526792358226],
            [-2.167518386873115, 52.471201223222977],
            [-2.169367476270112, 52.469315184981056],
            [-2.171559250448289, 52.465883240872522],
            [-2.174265265755346, 52.463798995535953],
            [-2.173894105318028, 52.461125018929906],
            [-2.174928905240864, 52.458564038091936],
            [-2.176354880582516, 52.455964705570508],
            [-2.176425693511771, 52.454537885760857],
            [-2.175374745882301, 52.452994967408976],
            [-2.171794426847165, 52.441632351463312],
            [-2.171268390707217, 52.441415556891023],
            [-2.17032365320295, 52.439058838703346],
            [-2.16485645636654, 52.430189835608132],
            [-2.164065161002937, 52.427808575788703],
            [-2.164175602154011, 52.423894154435381],
            [-2.163815846019509, 52.423243774271832],
            [-2.167711767598083, 52.42376602056197],
            [-2.172047389888291, 52.425020187935552],
            [-2.180696376638203, 52.425144816881371],
            [-2.181705624646126, 52.424891546669954],
            [-2.184033343360382, 52.425901120889776],
            [-2.185814021115918, 52.426195903104151],
            [-2.189051125527065, 52.425229728454099],
            [-2.191391404947542, 52.423667083080524],
            [-2.192880827009054, 52.42327808730203],
            [-2.196350730927369, 52.424812365696752],
            [-2.196952876221438, 52.425624064010407],
            [-2.196331769521952, 52.427161498332268],
            [-2.197000561877112, 52.427741141963686],
            [-2.200842033312767, 52.427112560034118],
            [-2.202643512930025, 52.427738786438979],
            [-2.20370334220458, 52.428581129086169],
            [-2.206446003599906, 52.429483462992316],
            [-2.20859727051455, 52.429396974696296],
            [-2.210432376851967, 52.429938516488157],
            [-2.211356841638476, 52.430420530495226],
            [-2.212116384360727, 52.432693650979935],
            [-2.214677457719632, 52.431858343662746],
            [-2.215689229377572, 52.432129797892109],
            [-2.215999569026554, 52.433331200012567],
            [-2.217352500029065, 52.433876212002659],
            [-2.2166423718034, 52.434537385820775],
            [-2.217881957979963, 52.43535409880127],
            [-2.219213334598799, 52.435096318313789],
            [-2.226751856793331, 52.435111755256784],
            [-2.240555922185265, 52.436371826808347],
            [-2.242140653806568, 52.436732686125517],
            [-2.244004920114738, 52.437363547521535],
            [-2.248833468688866, 52.437758028180447],
            [-2.254281289806674, 52.437622365896758],
            [-2.256096622209044, 52.437135679416691],
            [-2.260228983389825, 52.437197671194014],
            [-2.261507137556886, 52.436926945655962],
            [-2.261931760543936, 52.439322751305546],
            [-2.262401716662172, 52.439438579092652],
            [-2.262735749686704, 52.440691048790384],
            [-2.26476686662215, 52.440366469258791],
            [-2.266681089283439, 52.439204246540271],
            [-2.267817041513752, 52.439011088188259],
            [-2.268536487441853, 52.438544669853037],
            [-2.269509188310204, 52.438601789428475],
            [-2.272046186163686, 52.439708044052153],
            [-2.275235837962257, 52.440239160831624],
            [-2.279435188500868, 52.443395597755689],
            [-2.286071606900489, 52.443828320722353],
            [-2.285956927106032, 52.444520831483608],
            [-2.287191217460198, 52.445639795158542],
            [-2.286653834474017, 52.446035762676836],
            [-2.287144530549137, 52.446604539760884],
            [-2.2910909147334, 52.448442337144961],
            [-2.294968295367315, 52.448933467635953],
            [-2.286099684163765, 52.454737658525865],
            [-2.287386576601721, 52.455302705657736],
            [-2.291001400209014, 52.456731364858491],
            [-2.290734797633549, 52.458481479265288],
            [-2.289065443039112, 52.459310857911099],
            [-2.288903342131667, 52.460626493892597],
            [-2.288389417162609, 52.461034097798624],
            [-2.289364420313063, 52.46136254724167],
            [-2.28963286990319, 52.462795797034545],
            [-2.289888185894435, 52.46267650289974],
            [-2.291155644397151, 52.464045263346669],
            [-2.291243177784333, 52.465487045448192],
            [-2.292695697070386, 52.467662635383853],
            [-2.294774863924554, 52.469528288544865],
            [-2.294589590080347, 52.47066059190567],
            [-2.295445552945903, 52.471639264715037],
            [-2.294957896479638, 52.472022557662633],
            [-2.295634275130066, 52.473877302781105],
            [-2.30397225189439, 52.481471579029645],
            [-2.306280904082741, 52.48271792541837],
            [-2.307251060542982, 52.482474476259931],
            [-2.309024214095371, 52.483142305505183],
            [-2.310178356669701, 52.484980431973867],
            [-2.310413566272455, 52.486177280393193],
            [-2.311667480873589, 52.486694501336338],
            [-2.312752890617396, 52.489194445420381],
            [-2.308670731091223, 52.494481389262361],
            [-2.30769876375569, 52.495134796567335],
            [-2.307840481142918, 52.498341148441767],
            [-2.306447126561623, 52.499841596681904],
            [-2.302083446900561, 52.501283128561319],
            [-2.298424423030553, 52.503272008652338],
            [-2.296298336332098, 52.505639914948105],
            [-2.296090996328143, 52.506793395276802],
            [-2.29307102058159, 52.5094956552893],
            [-2.293246585626327, 52.509972585973152],
            [-2.29139392077782, 52.511665477032345],
            [-2.288304638831697, 52.512880398433559],
            [-2.286257329256256, 52.514147559465691],
            [-2.281421073167328, 52.51552294145835],
            [-2.27323142915814, 52.519184867885599],
            [-2.270664396356032, 52.519935137380379],
            [-2.261595789730039, 52.523547020443409],
            [-2.260947547463716, 52.524087847683759],
            [-2.261139059545452, 52.526803277474365],
            [-2.263301240291733, 52.527517675619194],
            [-2.269219517834158, 52.530674191531837],
            [-2.264232250807872, 52.536993653381856],
            [-2.261544982956313, 52.539046625785708],
            [-2.256409421616557, 52.541781808304101],
            [-2.260441185820862, 52.544361186338435],
            [-2.262544568074362, 52.546692107131754],
            [-2.265733759570698, 52.549298342501821],
            [-2.267934745967647, 52.550136627476505],
            [-2.276510489098432, 52.552013754199777],
            [-2.281413019685828, 52.552508322094816],
            [-2.282111826030983, 52.552696343877543],
            [-2.282480516257062, 52.553381391412863],
            [-2.279048674467606, 52.55317737618391],
            [-2.278593148263683, 52.554373204018489],
            [-2.274218247656088, 52.55739143710624],
            [-2.271668664850877, 52.558697254573282],
            [-2.269953759461775, 52.560231255251999],
            [-2.268710134965287, 52.560453439436131],
            [-2.267216173026255, 52.561476273795179],
            [-2.263813706841358, 52.56257258273255],
            [-2.262194488177648, 52.562660687527966],
            [-2.260840185390408, 52.563142840948302],
            [-2.261789759573006, 52.563809588420916],
            [-2.261773860890066, 52.566340266999717],
            [-2.263384521899849, 52.56800071289338],
            [-2.262010619586898, 52.568685194316288],
            [-2.262806632819536, 52.570295307904438],
            [-2.270291424715027, 52.575553682681239],
            [-2.271954777781372, 52.577275919498284],
            [-2.272190621150741, 52.578661609623822],
            [-2.272812929306017, 52.578590055593679],
            [-2.273995110162945, 52.579793759323593],
            [-2.278125049675024, 52.581779865646034],
            [-2.278096999211709, 52.582943215450214],
            [-2.2786592753537, 52.583403969696327],
            [-2.281055811594947, 52.584281102331047],
            [-2.289505018965728, 52.585569649993971],
            [-2.294424552782586, 52.584938112242618],
            [-2.29505996823398, 52.584612895651901],
            [-2.294913917019271, 52.586160409120453],
            [-2.294376678929007, 52.586162645852475],
            [-2.294102079138447, 52.587253794527307],
            [-2.294961825587022, 52.587359532460184],
            [-2.294886823288395, 52.587839776019109],
            [-2.296058933103388, 52.588298926216474],
            [-2.300133253574747, 52.588795688533132],
            [-2.299950811847011, 52.589313964945717],
            [-2.300687658313977, 52.590217370029897],
            [-2.299955047140395, 52.591229685159625],
            [-2.29884219429244, 52.59190314126041],
            [-2.301019765584979, 52.593228115209193],
            [-2.302344761524907, 52.59334969586623],
            [-2.306991710097466, 52.591426496608712],
            [-2.308549555288912, 52.591079033859636],
            [-2.318006634417995, 52.593451584216893],
            [-2.322027616439355, 52.593621406527767],
            [-2.323352326580282, 52.597701869025627],
            [-2.325074046935281, 52.599135522878619],
            [-2.325478240454051, 52.600084635242986],
            [-2.323927075145428, 52.602962033991695],
            [-2.324163631237147, 52.609598557495275],
            [-2.323631452030804, 52.610125017903648],
            [-2.323353926429253, 52.611942613956835],
            [-2.323802852225389, 52.612938354784092],
            [-2.322037376766648, 52.614061501156364],
            [-2.321080441657914, 52.614277158754092],
            [-2.320974938273898, 52.61459298649234],
            [-2.315812194074736, 52.6146751958156],
            [-2.313465246975577, 52.614040462461865],
            [-2.311007127190283, 52.611047952754234],
            [-2.309604897237648, 52.60738648962819],
            [-2.297737075144577, 52.606993519971716],
            [-2.290660174252959, 52.607779738930034],
            [-2.283390627025593, 52.605986824845665],
            [-2.279309484163604, 52.605798751646539],
            [-2.274126041909746, 52.607613324869163],
            [-2.270863213997899, 52.609632752122131],
            [-2.268004828701307, 52.610217302877153],
            [-2.263063231595051, 52.610659900074168],
            [-2.257322210674295, 52.609780736027695],
            [-2.25732669342037, 52.609538002129021],
            [-2.255808146158414, 52.609968309105675],
            [-2.255570486265297, 52.610999051594604],
            [-2.253155466535019, 52.613991545788608],
            [-2.252061757786984, 52.616935334263999],
            [-2.250515412316787, 52.61824123535596],
            [-2.250905525796002, 52.619819911151211],
            [-2.250115630709328, 52.620917435573915],
            [-2.250122380954923, 52.621841569067442],
            [-2.248960639807397, 52.622760974370514],
            [-2.247796983630685, 52.625440566904075],
            [-2.246531180342125, 52.626795269697176],
            [-2.246004586331301, 52.628256301476775],
            [-2.243610724642663, 52.630026840089734],
            [-2.243679858068205, 52.631034448648457],
            [-2.242458589360158, 52.632211916702651],
            [-2.240863091436839, 52.633327205820002],
            [-2.238807244027975, 52.634036167548388],
            [-2.236891380612548, 52.636051921644359],
            [-2.236481669613946, 52.638714602194277],
            [-2.235561181710828, 52.639840154486258],
            [-2.234770945599784, 52.643218267419229],
            [-2.232898537320636, 52.647679071783479],
            [-2.235487950104864, 52.648483023648161],
            [-2.236569374285071, 52.650575471878355],
            [-2.237473748185197, 52.651075287300785],
            [-2.235582617850394, 52.652617206212028],
            [-2.234797331622495, 52.655857759415376],
            [-2.237374190956086, 52.655886781382179],
            [-2.24220276045456, 52.6564918972458],
            [-2.247691210318421, 52.65664505324159],
            [-2.247827600366565, 52.656973791189579],
            [-2.246378114730786, 52.657900059622278],
            [-2.238920748855301, 52.661889692175407],
            [-2.238719119648329, 52.663686240775903],
            [-2.23977717906839, 52.664925579804411],
            [-2.239490227040933, 52.666798713965903],
            [-2.240346376134458, 52.668950909182591],
            [-2.24022787862899, 52.671608501410169],
            [-2.24254758140354, 52.673099655870686],
            [-2.243848355384681, 52.675900866019873],
            [-2.244224647856464, 52.677867931131281],
            [-2.243708044582759, 52.679917742515968],
            [-2.244688711983061, 52.680976501587239],
            [-2.24668744793053, 52.681045171001678],
            [-2.247732792537239, 52.683059371991114],
            [-2.250074877503938, 52.682880948611341],
            [-2.251791526604709, 52.681725734119475],
            [-2.25387683770501, 52.681666444879163],
            [-2.257441342441826, 52.681861017203296],
            [-2.261460406964641, 52.682867146115228],
            [-2.263114366167847, 52.682897643738549],
            [-2.264206132592822, 52.68266867332256],
            [-2.266694882934714, 52.681328128181036],
            [-2.273680100815898, 52.67976147226188],
            [-2.277582364672969, 52.68098038035081],
            [-2.280566403552664, 52.681529809819295],
            [-2.287180817799116, 52.681229881892385],
            [-2.292065387132042, 52.682167247419727],
            [-2.29571304361591, 52.683252240267521],
            [-2.303043465032791, 52.682937951069398],
            [-2.303881916558079, 52.684176378734087],
            [-2.304186541129333, 52.685862959361998],
            [-2.30769743738283, 52.690552793480435],
            [-2.30799800957892, 52.693317234308218],
            [-2.313208801859951, 52.693689227912202],
            [-2.313582051530876, 52.695394478974876],
            [-2.319910439853732, 52.695382951671569],
            [-2.320659060597388, 52.697792854529794],
            [-2.322976180861019, 52.700778318997656],
            [-2.323331981386749, 52.702465606072153],
            [-2.324836051245941, 52.705321100567183],
            [-2.323635848823947, 52.706129857945989],
            [-2.321847153483783, 52.709031195065208],
            [-2.319008019052043, 52.711021089949284],
            [-2.317060331985013, 52.71329710108688],
            [-2.308605797239339, 52.720731374401275],
            [-2.314681116671341, 52.724540480046592],
            [-2.316454808866919, 52.728203520536432],
            [-2.315780342023895, 52.730561497402434],
            [-2.314878154873192, 52.73153927082766],
            [-2.314823525638736, 52.732383540256862],
            [-2.315578312016158, 52.732932595617235],
            [-2.315636775038272, 52.735079157842812],
            [-2.315006063877995, 52.736337582579601],
            [-2.31555252516346, 52.737151485661641],
            [-2.316263579307813, 52.73717116525863],
            [-2.3167195534185, 52.737961032411143],
            [-2.315682192498946, 52.738288324780711],
            [-2.314071041318984, 52.737299254750369],
            [-2.313049010260865, 52.737906060045965],
            [-2.31301073729317, 52.739384950077351],
            [-2.312209566256153, 52.740852367568024],
            [-2.310645903070665, 52.740294626902518],
            [-2.307500886734168, 52.739804805477654],
            [-2.306797250344902, 52.740213857790039],
            [-2.305654706161393, 52.739921951393747],
            [-2.301590173065477, 52.740745923047911],
            [-2.299394777536053, 52.740705642822391],
            [-2.297885971424472, 52.742301501536659],
            [-2.295911730255566, 52.743431045058287],
            [-2.292304344196874, 52.742254271034746],
            [-2.290934546200563, 52.743195255762309],
            [-2.287667208055339, 52.74324728098555],
            [-2.285245687141805, 52.742622065425252],
            [-2.28320567908199, 52.740517102070591],
            [-2.281842817257397, 52.740719021230944],
            [-2.280636093000055, 52.740557375684823],
            [-2.277719522403041, 52.741235769079694],
            [-2.277093461196378, 52.742245866218035],
            [-2.276578778714152, 52.742370225904182],
            [-2.274957882114078, 52.742774034453198],
            [-2.273958236805819, 52.739974295791448],
            [-2.273902753253, 52.737741409968251],
            [-2.269835703562334, 52.737742650648364],
            [-2.269521941860098, 52.738259367213459],
            [-2.267917657596498, 52.737977136464622],
            [-2.266595161134729, 52.737269042737665],
            [-2.264798627677552, 52.734095250135574],
            [-2.265262886807502, 52.732994784080844],
            [-2.265043166605795, 52.731685491367735],
            [-2.264376639566299, 52.7309300562179],
            [-2.263216014488032, 52.730744757658627],
            [-2.262671428659458, 52.729820936341724],
            [-2.255730315653095, 52.730488766535224],
            [-2.253961730421493, 52.728117518322776],
            [-2.256275221344733, 52.727706206528758],
            [-2.257084275366127, 52.725790566817018],
            [-2.258283285301105, 52.724731680074626],
            [-2.258380635930269, 52.720664567643745],
            [-2.256852397091722, 52.717834363848077],
            [-2.254818430495924, 52.716839110800635],
            [-2.250656995798012, 52.715531908897049],
            [-2.248655678481071, 52.715547813270391],
            [-2.24702143916188, 52.715032531348385],
            [-2.245349339749025, 52.715149275665809],
            [-2.243367540500646, 52.716274369387136],
            [-2.242622882893791, 52.717600067561413],
            [-2.241328008179586, 52.717954206616547],
            [-2.239829544451391, 52.720328709921361],
            [-2.236098823314507, 52.721720604302575],
            [-2.234192517019777, 52.723218461166461],
            [-2.231040779636705, 52.72442116663106],
            [-2.231186150857279, 52.724751700423191],
            [-2.229326619304453, 52.724781384775781],
            [-2.228209608250286, 52.725232123133424],
            [-2.22807989227832, 52.725627016479038],
            [-2.226045029591528, 52.726118153312406],
            [-2.225433371902245, 52.725805581862865],
            [-2.224077162330229, 52.7261120035784],
            [-2.219862184212841, 52.72592572655406],
            [-2.220041749081319, 52.726299360463408],
            [-2.217478920931664, 52.729620355550715],
            [-2.217668050843918, 52.731022384620211],
            [-2.218251664132613, 52.731053673318087],
            [-2.218274448560707, 52.732352627183225],
            [-2.216675826803034, 52.732205435885909],
            [-2.216805175404622, 52.732904589252669],
            [-2.206830069009791, 52.732043244560558],
            [-2.205532119302211, 52.732525547810816],
            [-2.205165854649865, 52.732108166946261],
            [-2.201945723615808, 52.731962675680272],
            [-2.201556630468022, 52.733007029455642],
            [-2.20089081219222, 52.732814884462854],
            [-2.200104048591893, 52.733697195599738],
            [-2.196782062043272, 52.734632284099746],
            [-2.195460379631836, 52.734156226691567],
            [-2.194231569384885, 52.733244906393814],
            [-2.191205043363716, 52.733357705889652],
            [-2.188708922051939, 52.732842111268916],
            [-2.187732084945528, 52.732974012622293],
            [-2.186410724994678, 52.734965494313272],
            [-2.18523581342227, 52.734850473135609],
            [-2.18375474753297, 52.735182697873938],
            [-2.183777673386131, 52.735703158976939],
            [-2.183113683791526, 52.735937916482897],
            [-2.180371802196267, 52.736172257186482],
            [-2.179825789485001, 52.737009118205243],
            [-2.178506333003956, 52.736687489489526],
            [-2.177045187398414, 52.739036860882749],
            [-2.178068824605501, 52.740895268748027],
            [-2.177903726827279, 52.743996019003802],
            [-2.173307529086206, 52.744433433807998],
            [-2.173371801770072, 52.744951138651693],
            [-2.17302234992323, 52.74498670897156],
            [-2.168988552408115, 52.744567326893076],
            [-2.1681063363059, 52.743197673761948],
            [-2.167031130607769, 52.742474636039091],
            [-2.165559204063941, 52.742197127378056],
            [-2.163847698250414, 52.741226836473096],
            [-2.163108643986841, 52.741244037934159],
            [-2.163154219623395, 52.741548721513432],
            [-2.160828420665219, 52.742672902013339],
            [-2.159595268368376, 52.74368140163994],
            [-2.159631086130956, 52.745373189119505],
            [-2.158535821738911, 52.746850746279122],
            [-2.15877650356666, 52.74826268343471],
            [-2.159808866352574, 52.749420947600569],
            [-2.159256510375704, 52.749890946453256],
            [-2.160514539882533, 52.751163966787857],
            [-2.160361709855062, 52.752309442763682],
            [-2.159608453204806, 52.752950516319729],
            [-2.160149867448147, 52.753122384790245],
            [-2.159743673663302, 52.753462738345583],
            [-2.161139577399942, 52.754725676799794],
            [-2.158023158961136, 52.754589639025454],
            [-2.157637555000176, 52.757354438095454],
            [-2.155391192618775, 52.758591670882495],
            [-2.157525899514165, 52.759259470553943],
            [-2.159325801955284, 52.760339403523133],
            [-2.157873433663492, 52.762341520383586],
            [-2.156174592675181, 52.763010796158248],
            [-2.155664716394999, 52.764230448122866],
            [-2.155779841236483, 52.768659437766743],
            [-2.154518740839424, 52.771221306013707],
            [-2.154581412400374, 52.771756999709645],
            [-2.156176213007844, 52.772560368705008],
            [-2.154225854671008, 52.77306364044064],
            [-2.151395550813295, 52.775014421286819],
            [-2.150577886344679, 52.778615763443028],
            [-2.149350233276079, 52.780295658985168],
            [-2.14600269381932, 52.781623087854825],
            [-2.147046650227188, 52.782568391240652],
            [-2.144789172844216, 52.783618449313977],
            [-2.144321336262772, 52.78337540477068],
            [-2.141272452496684, 52.784105432220365],
            [-2.13844826517077, 52.783724016536851],
            [-2.137913520646833, 52.782474200719939],
            [-2.138304480609481, 52.781866953345734],
            [-2.137314716807441, 52.780176280200436],
            [-2.136981741857857, 52.778479446785759],
            [-2.137693826380766, 52.778169381899588],
            [-2.137361825679134, 52.777721190908792],
            [-2.135488752875757, 52.777478833970918],
            [-2.134273661190466, 52.777641130144801],
            [-2.134728052564105, 52.775972160805644],
            [-2.132775312231265, 52.775825140447701],
            [-2.130487346472484, 52.776098268046283],
            [-2.129401484760383, 52.775843258554175],
            [-2.128702165123836, 52.775463764340863],
            [-2.128711345959797, 52.775059226282281],
            [-2.124088730569327, 52.773268056062861],
            [-2.116759887901545, 52.769794769555197],
            [-2.116664890737789, 52.769189868478826],
            [-2.114585867829441, 52.7687900675907],
            [-2.114405850939394, 52.76909588543311],
            [-2.1134695292579, 52.769246911675175],
            [-2.112007749715879, 52.76967530612923],
            [-2.112303563826071, 52.770584765189973],
            [-2.111592765594934, 52.77141876573679],
            [-2.111007715143733, 52.771586520868851],
            [-2.109063256532261, 52.771084915526096],
            [-2.108352181290483, 52.7712401883038],
            [-2.107640254431888, 52.77165255776741],
            [-2.105596014821316, 52.770330243351012],
            [-2.102852731566756, 52.769805873457166],
            [-2.100229432779214, 52.773084803063931],
            [-2.098965349103386, 52.773217113863595],
            [-2.098878326264702, 52.774712142515789],
            [-2.100081737699422, 52.775895049406408],
            [-2.099845872923177, 52.777760571566127],
            [-2.09941184048735, 52.779848300028689],
            [-2.098663640809336, 52.780708322631284],
            [-2.098740886768367, 52.782082752912018],
            [-2.097547374366592, 52.782730087717901],
            [-2.099123391701261, 52.784684892830555],
            [-2.098738464436992, 52.784935122584777],
            [-2.096228677697137, 52.785113384070755],
            [-2.095168441827, 52.786416819160742],
            [-2.093864633269442, 52.786191325299043],
            [-2.096165147217671, 52.783873784199507],
            [-2.094971150044903, 52.78230158628363],
            [-2.095093005499738, 52.781074421629754],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000197",
        LAD13CDO: "41UG",
        LAD13NM: "Stafford",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.212514293425612, 52.980562226073665],
            [-2.212213377554033, 52.979638673263409],
            [-2.209846333186893, 52.977390182908174],
            [-2.208746420032207, 52.973821604276708],
            [-2.207169961999413, 52.971757743185961],
            [-2.205417964687147, 52.972205753727067],
            [-2.2046893497189, 52.969906669586329],
            [-2.202225084479164, 52.968640714698225],
            [-2.200438691587342, 52.96839743917085],
            [-2.198722519311275, 52.966580904234185],
            [-2.195376684672467, 52.964207008030094],
            [-2.194237298918714, 52.960790271401436],
            [-2.194291918420043, 52.958034080936194],
            [-2.193208111355839, 52.955675274396725],
            [-2.191774150350818, 52.953877952471906],
            [-2.191799519095047, 52.95154070644228],
            [-2.191254231226769, 52.950070045588042],
            [-2.185998444246124, 52.950989908229495],
            [-2.185155145394948, 52.950433893099785],
            [-2.184603029735936, 52.949400090039539],
            [-2.182093586365747, 52.947590833829153],
            [-2.18209965902714, 52.947265413414613],
            [-2.182925153127858, 52.947153576112811],
            [-2.181609986964117, 52.946190149556351],
            [-2.178607133503722, 52.948070764529682],
            [-2.17743441378271, 52.947713852300836],
            [-2.175727529447281, 52.947746056482245],
            [-2.174539260562291, 52.947215646070518],
            [-2.174345955516387, 52.949474030914672],
            [-2.176163017006374, 52.953093116567345],
            [-2.176899253930487, 52.953690705357772],
            [-2.175647997924006, 52.953821109951271],
            [-2.172214083519775, 52.954930021612221],
            [-2.17112071974571, 52.951712553950095],
            [-2.16689390082883, 52.952917738970577],
            [-2.162595470280805, 52.953348897463769],
            [-2.161854799496818, 52.954695599829932],
            [-2.162426863291386, 52.95641176343338],
            [-2.161365004414892, 52.957467649844652],
            [-2.161813098033004, 52.95787425222575],
            [-2.159856959156595, 52.959048200912726],
            [-2.159589075698335, 52.961085522042694],
            [-2.158325325185286, 52.961128565208419],
            [-2.158167404181118, 52.963132475468711],
            [-2.159397140614145, 52.963135324792447],
            [-2.159500099788114, 52.963602626407791],
            [-2.154169776865043, 52.964309032562582],
            [-2.153772517946358, 52.963542766817255],
            [-2.152498860944189, 52.96375385938763],
            [-2.150985978786045, 52.96410367701705],
            [-2.151371336483215, 52.964885248843522],
            [-2.14984212813681, 52.965263838168241],
            [-2.151482791105418, 52.968652490226873],
            [-2.15149312061387, 52.971176649679052],
            [-2.14902844654362, 52.972818504245467],
            [-2.148012312009508, 52.974393787070689],
            [-2.145711333001549, 52.976093797396615],
            [-2.139363956237252, 52.973680627078032],
            [-2.136651613261371, 52.973481524376716],
            [-2.133981083374563, 52.973792899801502],
            [-2.131357777894801, 52.971525157939126],
            [-2.130403676431708, 52.97115945047554],
            [-2.128955113099511, 52.969256218910708],
            [-2.128158985157952, 52.966403004394074],
            [-2.127922534607005, 52.964988355931645],
            [-2.121657734590222, 52.956069539730436],
            [-2.117974189396449, 52.956276417592541],
            [-2.117719146418548, 52.9560914927441],
            [-2.115118169774142, 52.956457211045752],
            [-2.110184204915185, 52.957842644292292],
            [-2.109143260401659, 52.959445488039506],
            [-2.109597196157758, 52.96234229769582],
            [-2.108729269253534, 52.962346692333682],
            [-2.106114530090716, 52.962141409838772],
            [-2.103009032438887, 52.963394549521446],
            [-2.100741902106168, 52.960960417277839],
            [-2.099994037165845, 52.960727330460088],
            [-2.08681129371608, 52.96497164225066],
            [-2.084432953145958, 52.96538956142119],
            [-2.084507517358969, 52.966212920744269],
            [-2.081219856710177, 52.966887613156999],
            [-2.081957852443249, 52.968194137075905],
            [-2.081448750856982, 52.968256513678092],
            [-2.08446165293754, 52.970169107170392],
            [-2.088129123777037, 52.973834034542953],
            [-2.075899258389151, 52.970691780664993],
            [-2.068448900347557, 52.968349236857627],
            [-2.067372882983305, 52.967711619668648],
            [-2.067258889683497, 52.967175927218193],
            [-2.068378194787146, 52.965071809257957],
            [-2.068882543227662, 52.962975230652056],
            [-2.067728715644424, 52.962953423142139],
            [-2.065017631129872, 52.960947649175836],
            [-2.063661011247706, 52.960664326919456],
            [-2.060988286828918, 52.961340824956778],
            [-2.060606677554565, 52.960985047671159],
            [-2.05982807076653, 52.960971959978039],
            [-2.059778337366118, 52.960534209828786],
            [-2.058490837769553, 52.959590984389351],
            [-2.05853120304673, 52.958620127817127],
            [-2.057707552932766, 52.958287031961262],
            [-2.057844173076936, 52.958039761448909],
            [-2.056024704808539, 52.957728710677877],
            [-2.055591014776231, 52.957331591278795],
            [-2.055113348908238, 52.957461259684401],
            [-2.05471383043362, 52.957003893297774],
            [-2.053467743371241, 52.956873220318258],
            [-2.053471802317478, 52.956545111539441],
            [-2.052684809188799, 52.955692386566035],
            [-2.050718037360595, 52.955315698592003],
            [-2.049962446335205, 52.954521371983326],
            [-2.048958911417721, 52.954254811516066],
            [-2.048996932281073, 52.953657011613821],
            [-2.048122254445189, 52.954130204668857],
            [-2.047743736401013, 52.953724943633375],
            [-2.047976913482441, 52.953267297114643],
            [-2.045721152247478, 52.952525680239638],
            [-2.045570235006242, 52.95196930470717],
            [-2.044991476736699, 52.952184369260173],
            [-2.044657407693089, 52.951539068665468],
            [-2.04303338984809, 52.951335615605437],
            [-2.043774336426242, 52.951080048997738],
            [-2.0438912977497, 52.950472332980503],
            [-2.043191607993404, 52.948815873595606],
            [-2.046284814260749, 52.946566492330284],
            [-2.046056487643876, 52.945975089082488],
            [-2.044612640251073, 52.945674503213347],
            [-2.046655585079288, 52.942632650952639],
            [-2.049934474664922, 52.93936102029015],
            [-2.045822281973377, 52.938444882776771],
            [-2.046129050462007, 52.937293239248319],
            [-2.046266910652448, 52.936814956667568],
            [-2.047834921024217, 52.936632749323792],
            [-2.049289290911958, 52.935968744257373],
            [-2.051832085154047, 52.933555837091262],
            [-2.055950296529821, 52.931339004715319],
            [-2.062959089930978, 52.922378566838773],
            [-2.05378670226469, 52.918987839658868],
            [-2.041528751096995, 52.917460109436881],
            [-2.043543744972265, 52.917293980675012],
            [-2.042652833939833, 52.915709493588658],
            [-2.04057402703111, 52.915943947873778],
            [-2.040639966489576, 52.916479687028819],
            [-2.036936275279437, 52.915736590868654],
            [-2.034059316699226, 52.914699193314313],
            [-2.033110102286552, 52.914128643484709],
            [-2.032971413478521, 52.913619888210839],
            [-2.027204722428756, 52.911547530324626],
            [-2.023905479765492, 52.909619143327021],
            [-2.023617074634675, 52.909728871131065],
            [-2.022299302402105, 52.909066617095711],
            [-2.017463505667386, 52.908223341424538],
            [-2.01275254847656, 52.906564525146919],
            [-2.016523526892022, 52.903689271172716],
            [-2.016971964282106, 52.902288672123362],
            [-2.012425195719754, 52.900619929052311],
            [-2.007409070476382, 52.899235105201583],
            [-2.002732124728399, 52.896838762591287],
            [-1.999707104512568, 52.892251547844999],
            [-1.996649705801425, 52.892184986716664],
            [-1.993944378570014, 52.893615986547829],
            [-1.991282135303196, 52.89424777652431],
            [-1.98666073336694, 52.894764243930048],
            [-1.985945598237677, 52.895280150276655],
            [-1.985954633246873, 52.894913386899148],
            [-1.982014412960269, 52.894150569669755],
            [-1.9796633291824, 52.893262050218134],
            [-1.975981984360434, 52.889797782369627],
            [-1.973483305850547, 52.887801624287654],
            [-1.972123841923553, 52.887173860400516],
            [-1.970329753926474, 52.885397136645203],
            [-1.968380174972404, 52.874829660258463],
            [-1.967487652870549, 52.874275676957367],
            [-1.966339432345525, 52.873955338548974],
            [-1.965954468670733, 52.874165580620598],
            [-1.964990617004234, 52.873687068778082],
            [-1.964210096331261, 52.872511029836531],
            [-1.960541089693002, 52.870209498241714],
            [-1.952425789356789, 52.868416760326994],
            [-1.951358580399446, 52.870292410702852],
            [-1.950017696134895, 52.86976418131799],
            [-1.94643484326727, 52.869480361349559],
            [-1.944988866411429, 52.869896810077073],
            [-1.936054963508785, 52.870667238883314],
            [-1.936974622585725, 52.869589004806585],
            [-1.936571148116376, 52.869176179060652],
            [-1.937165910571499, 52.868799839491928],
            [-1.936817374788382, 52.868408617936844],
            [-1.937225894591742, 52.867375056458286],
            [-1.934987638967467, 52.867120357076786],
            [-1.934889151852918, 52.86642272832119],
            [-1.934327978907332, 52.866203079959803],
            [-1.935047675938934, 52.865706361995379],
            [-1.935207889587065, 52.864848863155878],
            [-1.93699699615161, 52.864377879773954],
            [-1.936797344277933, 52.863772789339727],
            [-1.935608923669619, 52.863858451538626],
            [-1.935562165066542, 52.863334345798656],
            [-1.936727533779682, 52.862746165801816],
            [-1.936043869959805, 52.862012268064177],
            [-1.93659119225485, 52.861510054211351],
            [-1.935969894354171, 52.860801359292694],
            [-1.936475476516953, 52.859373217384835],
            [-1.937640636914938, 52.858847953613868],
            [-1.937052047980755, 52.858129392811563],
            [-1.935577679801745, 52.857850833484278],
            [-1.934760212063571, 52.85727596744416],
            [-1.933363901793285, 52.854534331596838],
            [-1.933579856757759, 52.853148288388226],
            [-1.934125488920343, 52.852726988735583],
            [-1.934690904456687, 52.852971811212058],
            [-1.93408474861103, 52.85315486117841],
            [-1.934491143732057, 52.853485894697506],
            [-1.93776584761066, 52.853315951514006],
            [-1.937936005633115, 52.852705660637483],
            [-1.939080908341658, 52.852723330821675],
            [-1.938766546505876, 52.852397753899204],
            [-1.939876827100493, 52.85167017811402],
            [-1.939202879470688, 52.851510724224632],
            [-1.938532961106753, 52.850601553229545],
            [-1.939127352416321, 52.850297117670813],
            [-1.93818481804165, 52.850026951015529],
            [-1.938507709883386, 52.849551578982719],
            [-1.937474716046408, 52.8492130405673],
            [-1.936788864849402, 52.84807641926183],
            [-1.946291749052325, 52.846900768871571],
            [-1.94691092128935, 52.845665902457874],
            [-1.949245988553285, 52.844768877197836],
            [-1.949720351283061, 52.844127234648731],
            [-1.951149093014036, 52.843712517483034],
            [-1.95050036290042, 52.842332376675927],
            [-1.94876748925084, 52.842591439253532],
            [-1.948549481224979, 52.842391780672095],
            [-1.949212730238417, 52.841443682412581],
            [-1.948737030333643, 52.840710842334239],
            [-1.952269880176656, 52.837041031979794],
            [-1.950796488352248, 52.836442637753166],
            [-1.952120914672153, 52.834801709025584],
            [-1.953063068258415, 52.832430674523856],
            [-1.954393263103688, 52.832177688358684],
            [-1.954860871652635, 52.832122131623528],
            [-1.955831294985334, 52.830948476000131],
            [-1.960843889565768, 52.827300517323373],
            [-1.966639549495984, 52.825140316795313],
            [-1.96729542378358, 52.825242078410845],
            [-1.967889745661603, 52.824283965920145],
            [-1.965585439898335, 52.821908316849147],
            [-1.964452071264471, 52.821462109335577],
            [-1.964418862186867, 52.820325834541357],
            [-1.96259008898348, 52.819319358098397],
            [-1.962024250698148, 52.818172127013135],
            [-1.960360281120126, 52.817211514235872],
            [-1.959697147230747, 52.815470935911478],
            [-1.958254429764323, 52.814480702400772],
            [-1.958327683107064, 52.813899110689775],
            [-1.957009060482873, 52.813534570681874],
            [-1.957230408186633, 52.813244290940595],
            [-1.956333123036472, 52.812923042873152],
            [-1.954944578991796, 52.812789483151889],
            [-1.954866406696295, 52.812342677930957],
            [-1.95376141714157, 52.812055491585184],
            [-1.95133198052853, 52.807411472185805],
            [-1.951174193056623, 52.803903719938759],
            [-1.952710044233497, 52.801965310727823],
            [-1.952423993529077, 52.801763832980541],
            [-1.953050242260889, 52.801469226445128],
            [-1.952635849961026, 52.800621355857331],
            [-1.958136177767881, 52.798474028885671],
            [-1.956055911854923, 52.797892561552686],
            [-1.951768873712291, 52.795936594495863],
            [-1.948047918701526, 52.793407190964921],
            [-1.951379004744067, 52.791767115786968],
            [-1.950697638846514, 52.789759484404883],
            [-1.949288647959563, 52.790007902544389],
            [-1.949194477687589, 52.789375901629739],
            [-1.945281332329259, 52.789335515706092],
            [-1.946402099685845, 52.785904736767968],
            [-1.947112257878731, 52.785956293923363],
            [-1.948390049468135, 52.783700490915706],
            [-1.951304432308109, 52.780132885691259],
            [-1.95207089212871, 52.780134993952949],
            [-1.947724862792275, 52.77829121966078],
            [-1.945734771582752, 52.776408827323749],
            [-1.943948933007133, 52.773742611944328],
            [-1.944693331418338, 52.773518221848391],
            [-1.94660616738645, 52.774179822783346],
            [-1.947716888994674, 52.773788372318947],
            [-1.948644599759762, 52.773968565615732],
            [-1.94915079325794, 52.773342213644533],
            [-1.950603862260393, 52.771646503351448],
            [-1.95594091305007, 52.769151306134923],
            [-1.959710723508425, 52.768372347673626],
            [-1.965758520139335, 52.765503880075798],
            [-1.967731483383045, 52.764968653165724],
            [-1.970541335039464, 52.765008931776812],
            [-1.972055465003711, 52.763427138498535],
            [-1.973135474475228, 52.761541382901726],
            [-1.977399895796727, 52.762420539230241],
            [-1.982702341944421, 52.762042959960148],
            [-1.986694315150089, 52.762968488215257],
            [-1.988342268239048, 52.762812240180295],
            [-1.996781805692239, 52.763627201502182],
            [-1.999385611719296, 52.762876612423547],
            [-2.003791188836735, 52.760638151323647],
            [-2.007337296425616, 52.760782708537427],
            [-2.012723516746152, 52.75962079391816],
            [-2.015703475258211, 52.759660883156528],
            [-2.018431162228169, 52.758844235231322],
            [-2.017793277501873, 52.757121040510825],
            [-2.014785582995256, 52.753494181882488],
            [-2.015164732296714, 52.75308690699746],
            [-2.015069195649225, 52.751052585973177],
            [-2.015473626433955, 52.750977020840359],
            [-2.016827497122068, 52.746561167866041],
            [-2.018027939540048, 52.744278541464212],
            [-2.017021451927223, 52.742550000883647],
            [-2.017911242385912, 52.741500786508283],
            [-2.026807114167203, 52.73630132476967],
            [-2.028970251158477, 52.739758197609859],
            [-2.031290018760169, 52.742209954994394],
            [-2.032353161443507, 52.745667050251853],
            [-2.033712754834201, 52.747205679772115],
            [-2.037360240213494, 52.754406103133689],
            [-2.042771534200055, 52.760750874802881],
            [-2.044520006097178, 52.762075284184341],
            [-2.04714760380752, 52.763605179588012],
            [-2.047631711472242, 52.763156407804445],
            [-2.051601416364211, 52.761557299767134],
            [-2.053892862027249, 52.7631123631026],
            [-2.053487588647513, 52.763744511080283],
            [-2.055976397160218, 52.765356078046608],
            [-2.060545915673289, 52.764622975662483],
            [-2.063289008157403, 52.765589709683567],
            [-2.064139297106452, 52.765323161276172],
            [-2.066428531423154, 52.765979030649412],
            [-2.072680814705333, 52.76660551684936],
            [-2.074367245799439, 52.766516370692344],
            [-2.076168705644778, 52.766951215645129],
            [-2.073232489250627, 52.769453056256225],
            [-2.069385573167168, 52.770858640382365],
            [-2.067807989359555, 52.772426428262868],
            [-2.065968701839552, 52.773530482345727],
            [-2.068474030907112, 52.774631171360888],
            [-2.069992436339992, 52.775860945734358],
            [-2.074735963430476, 52.778408365082811],
            [-2.077667438173576, 52.780432709873743],
            [-2.079332593355112, 52.77977087620112],
            [-2.081610784676786, 52.779546386456559],
            [-2.086036754508551, 52.779905526945576],
            [-2.087151615545524, 52.780651738481694],
            [-2.089281261776342, 52.781004336832297],
            [-2.0916734358856, 52.780691470756288],
            [-2.095093005499738, 52.781074421629754],
            [-2.094971150044903, 52.78230158628363],
            [-2.096165147217671, 52.783873784199507],
            [-2.093864633269442, 52.786191325299043],
            [-2.095168441827, 52.786416819160742],
            [-2.096228677697137, 52.785113384070755],
            [-2.098738464436992, 52.784935122584777],
            [-2.099123391701261, 52.784684892830555],
            [-2.097547374366592, 52.782730087717901],
            [-2.098740886768367, 52.782082752912018],
            [-2.098663640809336, 52.780708322631284],
            [-2.09941184048735, 52.779848300028689],
            [-2.099845872923177, 52.777760571566127],
            [-2.100081737699422, 52.775895049406408],
            [-2.098878326264702, 52.774712142515789],
            [-2.098965349103386, 52.773217113863595],
            [-2.100229432779214, 52.773084803063931],
            [-2.102852731566756, 52.769805873457166],
            [-2.105596014821316, 52.770330243351012],
            [-2.107640254431888, 52.77165255776741],
            [-2.108352181290483, 52.7712401883038],
            [-2.109063256532261, 52.771084915526096],
            [-2.111007715143733, 52.771586520868851],
            [-2.111592765594934, 52.77141876573679],
            [-2.112303563826071, 52.770584765189973],
            [-2.112007749715879, 52.76967530612923],
            [-2.1134695292579, 52.769246911675175],
            [-2.114405850939394, 52.76909588543311],
            [-2.114585867829441, 52.7687900675907],
            [-2.116664890737789, 52.769189868478826],
            [-2.116759887901545, 52.769794769555197],
            [-2.124088730569327, 52.773268056062861],
            [-2.128711345959797, 52.775059226282281],
            [-2.128702165123836, 52.775463764340863],
            [-2.129401484760383, 52.775843258554175],
            [-2.130487346472484, 52.776098268046283],
            [-2.132775312231265, 52.775825140447701],
            [-2.134728052564105, 52.775972160805644],
            [-2.134273661190466, 52.777641130144801],
            [-2.135488752875757, 52.777478833970918],
            [-2.137361825679134, 52.777721190908792],
            [-2.137693826380766, 52.778169381899588],
            [-2.136981741857857, 52.778479446785759],
            [-2.137314716807441, 52.780176280200436],
            [-2.138304480609481, 52.781866953345734],
            [-2.137913520646833, 52.782474200719939],
            [-2.13844826517077, 52.783724016536851],
            [-2.141272452496684, 52.784105432220365],
            [-2.144321336262772, 52.78337540477068],
            [-2.144789172844216, 52.783618449313977],
            [-2.147046650227188, 52.782568391240652],
            [-2.14600269381932, 52.781623087854825],
            [-2.149350233276079, 52.780295658985168],
            [-2.150577886344679, 52.778615763443028],
            [-2.151395550813295, 52.775014421286819],
            [-2.154225854671008, 52.77306364044064],
            [-2.156176213007844, 52.772560368705008],
            [-2.154581412400374, 52.771756999709645],
            [-2.154518740839424, 52.771221306013707],
            [-2.155779841236483, 52.768659437766743],
            [-2.155664716394999, 52.764230448122866],
            [-2.156174592675181, 52.763010796158248],
            [-2.157873433663492, 52.762341520383586],
            [-2.159325801955284, 52.760339403523133],
            [-2.157525899514165, 52.759259470553943],
            [-2.155391192618775, 52.758591670882495],
            [-2.157637555000176, 52.757354438095454],
            [-2.158023158961136, 52.754589639025454],
            [-2.161139577399942, 52.754725676799794],
            [-2.159743673663302, 52.753462738345583],
            [-2.160149867448147, 52.753122384790245],
            [-2.159608453204806, 52.752950516319729],
            [-2.160361709855062, 52.752309442763682],
            [-2.160514539882533, 52.751163966787857],
            [-2.159256510375704, 52.749890946453256],
            [-2.159808866352574, 52.749420947600569],
            [-2.15877650356666, 52.74826268343471],
            [-2.158535821738911, 52.746850746279122],
            [-2.159631086130956, 52.745373189119505],
            [-2.159595268368376, 52.74368140163994],
            [-2.160828420665219, 52.742672902013339],
            [-2.163154219623395, 52.741548721513432],
            [-2.163108643986841, 52.741244037934159],
            [-2.163847698250414, 52.741226836473096],
            [-2.165559204063941, 52.742197127378056],
            [-2.167031130607769, 52.742474636039091],
            [-2.1681063363059, 52.743197673761948],
            [-2.168988552408115, 52.744567326893076],
            [-2.17302234992323, 52.74498670897156],
            [-2.173371801770072, 52.744951138651693],
            [-2.173307529086206, 52.744433433807998],
            [-2.177903726827279, 52.743996019003802],
            [-2.178068824605501, 52.740895268748027],
            [-2.177045187398414, 52.739036860882749],
            [-2.178506333003956, 52.736687489489526],
            [-2.179825789485001, 52.737009118205243],
            [-2.180371802196267, 52.736172257186482],
            [-2.183113683791526, 52.735937916482897],
            [-2.183777673386131, 52.735703158976939],
            [-2.18375474753297, 52.735182697873938],
            [-2.18523581342227, 52.734850473135609],
            [-2.186410724994678, 52.734965494313272],
            [-2.187732084945528, 52.732974012622293],
            [-2.188708922051939, 52.732842111268916],
            [-2.191205043363716, 52.733357705889652],
            [-2.194231569384885, 52.733244906393814],
            [-2.195460379631836, 52.734156226691567],
            [-2.196782062043272, 52.734632284099746],
            [-2.200104048591893, 52.733697195599738],
            [-2.20089081219222, 52.732814884462854],
            [-2.201556630468022, 52.733007029455642],
            [-2.201945723615808, 52.731962675680272],
            [-2.205165854649865, 52.732108166946261],
            [-2.205532119302211, 52.732525547810816],
            [-2.206830069009791, 52.732043244560558],
            [-2.216805175404622, 52.732904589252669],
            [-2.216675826803034, 52.732205435885909],
            [-2.218274448560707, 52.732352627183225],
            [-2.218251664132613, 52.731053673318087],
            [-2.217668050843918, 52.731022384620211],
            [-2.217478920931664, 52.729620355550715],
            [-2.220041749081319, 52.726299360463408],
            [-2.219862184212841, 52.72592572655406],
            [-2.224077162330229, 52.7261120035784],
            [-2.225433371902245, 52.725805581862865],
            [-2.226045029591528, 52.726118153312406],
            [-2.22807989227832, 52.725627016479038],
            [-2.228209608250286, 52.725232123133424],
            [-2.229326619304453, 52.724781384775781],
            [-2.231186150857279, 52.724751700423191],
            [-2.231040779636705, 52.72442116663106],
            [-2.234192517019777, 52.723218461166461],
            [-2.236098823314507, 52.721720604302575],
            [-2.239829544451391, 52.720328709921361],
            [-2.241328008179586, 52.717954206616547],
            [-2.242622882893791, 52.717600067561413],
            [-2.243367540500646, 52.716274369387136],
            [-2.245349339749025, 52.715149275665809],
            [-2.24702143916188, 52.715032531348385],
            [-2.248655678481071, 52.715547813270391],
            [-2.250656995798012, 52.715531908897049],
            [-2.254818430495924, 52.716839110800635],
            [-2.256852397091722, 52.717834363848077],
            [-2.258380635930269, 52.720664567643745],
            [-2.258283285301105, 52.724731680074626],
            [-2.257084275366127, 52.725790566817018],
            [-2.256275221344733, 52.727706206528758],
            [-2.253961730421493, 52.728117518322776],
            [-2.255730315653095, 52.730488766535224],
            [-2.262671428659458, 52.729820936341724],
            [-2.263216014488032, 52.730744757658627],
            [-2.264376639566299, 52.7309300562179],
            [-2.265043166605795, 52.731685491367735],
            [-2.265262886807502, 52.732994784080844],
            [-2.264798627677552, 52.734095250135574],
            [-2.266595161134729, 52.737269042737665],
            [-2.267917657596498, 52.737977136464622],
            [-2.269521941860098, 52.738259367213459],
            [-2.269835703562334, 52.737742650648364],
            [-2.273902753253, 52.737741409968251],
            [-2.273958236805819, 52.739974295791448],
            [-2.274957882114078, 52.742774034453198],
            [-2.276578778714152, 52.742370225904182],
            [-2.277093461196378, 52.742245866218035],
            [-2.277719522403041, 52.741235769079694],
            [-2.280636093000055, 52.740557375684823],
            [-2.281842817257397, 52.740719021230944],
            [-2.28320567908199, 52.740517102070591],
            [-2.285245687141805, 52.742622065425252],
            [-2.287667208055339, 52.74324728098555],
            [-2.290934546200563, 52.743195255762309],
            [-2.292304344196874, 52.742254271034746],
            [-2.295911730255566, 52.743431045058287],
            [-2.297885971424472, 52.742301501536659],
            [-2.299394777536053, 52.740705642822391],
            [-2.301590173065477, 52.740745923047911],
            [-2.305654706161393, 52.739921951393747],
            [-2.306797250344902, 52.740213857790039],
            [-2.307500886734168, 52.739804805477654],
            [-2.310645903070665, 52.740294626902518],
            [-2.312209566256153, 52.740852367568024],
            [-2.316085841213863, 52.74453411837095],
            [-2.3157022680744, 52.744955853536801],
            [-2.31599154746774, 52.745218477583165],
            [-2.315209712847252, 52.745466874248002],
            [-2.316922510052326, 52.745924371412109],
            [-2.317799288131686, 52.746712208429983],
            [-2.318619032020563, 52.746785520086654],
            [-2.319577927217042, 52.74745176369872],
            [-2.320126897167276, 52.747567146835785],
            [-2.320998067468119, 52.747177339424383],
            [-2.320774996434653, 52.747866545706408],
            [-2.321326773225608, 52.748160808456461],
            [-2.321634247255959, 52.749879682008611],
            [-2.323706682540748, 52.75065433591265],
            [-2.324634471099978, 52.751498617065735],
            [-2.326819056974899, 52.751206744923671],
            [-2.330477016811869, 52.751602032628966],
            [-2.330856603367288, 52.753010540115312],
            [-2.330411221760789, 52.753674314523067],
            [-2.32974453095953, 52.753679766891878],
            [-2.328654555153746, 52.754533208212365],
            [-2.32905543144701, 52.754446695040592],
            [-2.32928264597084, 52.75471215556901],
            [-2.32931880030038, 52.755968797968769],
            [-2.33005986214426, 52.756192375158712],
            [-2.329931949620597, 52.757499815419948],
            [-2.33046340134596, 52.757629582778996],
            [-2.330135499235923, 52.757964009635728],
            [-2.334943225648912, 52.758847681781852],
            [-2.34244277759183, 52.762075984551913],
            [-2.344027946065771, 52.763894479440275],
            [-2.343968595028386, 52.765012953745497],
            [-2.345233997652097, 52.766293884305767],
            [-2.348246497981289, 52.766414523059062],
            [-2.348698028514818, 52.766166881748816],
            [-2.349968275274947, 52.766182915800933],
            [-2.352731989612754, 52.76889316926858],
            [-2.354393093449745, 52.771582386735716],
            [-2.35758652255057, 52.772209258860741],
            [-2.359033440010743, 52.773858065666637],
            [-2.359859498022613, 52.773907700749028],
            [-2.360901063501587, 52.774563469319595],
            [-2.363243614559511, 52.775152323203784],
            [-2.365069691843436, 52.776723482575804],
            [-2.366350736989268, 52.777105183180254],
            [-2.368093531006892, 52.776866057141561],
            [-2.368868009626528, 52.777294249899867],
            [-2.367237290225878, 52.778344796770625],
            [-2.36682787540899, 52.779547967009499],
            [-2.36723744271693, 52.781706887614924],
            [-2.365859198777813, 52.78318633250143],
            [-2.367192901467103, 52.783811477995755],
            [-2.368403809841678, 52.783586579261055],
            [-2.370107324657835, 52.784105364829969],
            [-2.371930617868157, 52.784234503081841],
            [-2.376278121139105, 52.783756926594563],
            [-2.377298798761259, 52.783656596256165],
            [-2.378935226730196, 52.784120632436995],
            [-2.378556818476977, 52.785107095224234],
            [-2.378813138528561, 52.785938708427167],
            [-2.379437160364771, 52.785915138425359],
            [-2.379491296875805, 52.786342867437142],
            [-2.380218186192466, 52.786552692205099],
            [-2.379601740051069, 52.78710662502759],
            [-2.379902147316346, 52.787548847390418],
            [-2.380735792796674, 52.787755630055031],
            [-2.381099003381654, 52.788764887674127],
            [-2.383269203076635, 52.788522367491019],
            [-2.383409801166688, 52.788997459668998],
            [-2.38405620890955, 52.788985479467485],
            [-2.38500094045606, 52.789508304163746],
            [-2.384746507144024, 52.790417972288409],
            [-2.385623869052423, 52.790696495081619],
            [-2.383590262711433, 52.791137285120357],
            [-2.381683471120961, 52.790654406772397],
            [-2.380876213240938, 52.791098388472228],
            [-2.380902033585417, 52.792355941480544],
            [-2.382111368186425, 52.792778154930716],
            [-2.381755812567268, 52.792987857212758],
            [-2.382171558218517, 52.79321575125217],
            [-2.38065122735977, 52.794711105254926],
            [-2.379875414244998, 52.79417871581331],
            [-2.379079350142834, 52.794555227580041],
            [-2.378626299253667, 52.794300473282583],
            [-2.378963545694439, 52.794028811204555],
            [-2.377502384305208, 52.794153032168516],
            [-2.375764836358861, 52.793354886435878],
            [-2.375326076259149, 52.793894748762689],
            [-2.374515348668217, 52.794122050104669],
            [-2.374656521808139, 52.793810566456102],
            [-2.373131541899795, 52.793929541485369],
            [-2.371415276161855, 52.795269874039796],
            [-2.371708219541158, 52.796578730153236],
            [-2.373069863693889, 52.796598726222761],
            [-2.373302631780286, 52.796935999900043],
            [-2.372908114809751, 52.79762763655188],
            [-2.37259954093941, 52.797444321193289],
            [-2.37181864028767, 52.797700276630273],
            [-2.372124643209788, 52.798277343178633],
            [-2.371328102182245, 52.79913654129691],
            [-2.371810230572692, 52.799495510540908],
            [-2.371393114165781, 52.799455465713237],
            [-2.370919899730678, 52.800317243725175],
            [-2.371297909791557, 52.800292688120692],
            [-2.371598447138775, 52.800749313350089],
            [-2.37216411883358, 52.800641463275078],
            [-2.372393512343155, 52.80127630217045],
            [-2.37387497445613, 52.801759772033549],
            [-2.373674225050471, 52.802046271367317],
            [-2.374819848221611, 52.80245347723654],
            [-2.375368304692658, 52.802928187036159],
            [-2.375062593013034, 52.803082874937793],
            [-2.376452594233779, 52.803614246230744],
            [-2.376845390955916, 52.804095735788124],
            [-2.377036742469286, 52.804438527104267],
            [-2.376452139585122, 52.8047631076643],
            [-2.37661060226902, 52.80524803832504],
            [-2.377005225268941, 52.805253077256303],
            [-2.37691261423322, 52.80586465847496],
            [-2.377498211613512, 52.805995840850187],
            [-2.377653440990863, 52.807132519888597],
            [-2.375980681962028, 52.80805746114013],
            [-2.376491458742349, 52.808282376515017],
            [-2.375904705592208, 52.80922364113075],
            [-2.377118597478606, 52.810294932724325],
            [-2.378707678386544, 52.810829239969557],
            [-2.37834234072, 52.811468660715839],
            [-2.377655336852768, 52.811628166424285],
            [-2.397754446571128, 52.819999118518638],
            [-2.416347617012193, 52.826991175161041],
            [-2.41554253118121, 52.827696077071785],
            [-2.415337999297154, 52.829125223153497],
            [-2.413782553784005, 52.829592717996633],
            [-2.412718665186503, 52.831032044647884],
            [-2.409705080672389, 52.832551823921186],
            [-2.409345113073151, 52.833575170375759],
            [-2.407656531369274, 52.83459050276084],
            [-2.403609441937619, 52.83483896472341],
            [-2.400875244381906, 52.833716459542224],
            [-2.397091264071916, 52.833838856389846],
            [-2.395735268747356, 52.834406128265648],
            [-2.395594716047851, 52.834946864386986],
            [-2.394503324356377, 52.835561782590936],
            [-2.393757200299596, 52.836605241979541],
            [-2.394068774204378, 52.837898691139713],
            [-2.393522148228715, 52.838682589144639],
            [-2.394269327577768, 52.841027258076764],
            [-2.393191939884138, 52.842389141120123],
            [-2.392529122282886, 52.845605966264095],
            [-2.392760516197638, 52.846896086368574],
            [-2.391433512585949, 52.849911038883668],
            [-2.389779860118085, 52.851792580179179],
            [-2.389725723412638, 52.854533639534729],
            [-2.388371999988316, 52.855770530140781],
            [-2.389333864795713, 52.8572092842521],
            [-2.386739981641933, 52.859956847021508],
            [-2.388202223319285, 52.861206098487123],
            [-2.388496452588153, 52.862219143437684],
            [-2.382223707084827, 52.865740893914356],
            [-2.381156736231726, 52.867047791689416],
            [-2.380545873437627, 52.873117607772912],
            [-2.382404783802639, 52.8756844019927],
            [-2.382512353512607, 52.876595581260226],
            [-2.381179784069983, 52.880005953438712],
            [-2.379481560817621, 52.880878872201606],
            [-2.378945952127878, 52.88165457062933],
            [-2.379167082959214, 52.883494892855843],
            [-2.376871275801109, 52.88453868155009],
            [-2.377586151314336, 52.88762336643056],
            [-2.375379608071731, 52.891458045022972],
            [-2.378938538745721, 52.891705631576613],
            [-2.379692543013054, 52.893964950522076],
            [-2.380573581551339, 52.894936577919353],
            [-2.380801720454223, 52.896543146929673],
            [-2.382262159791289, 52.897129055190604],
            [-2.382699885893771, 52.897881853443003],
            [-2.382132361216819, 52.899439742088731],
            [-2.38245902008233, 52.901419947406509],
            [-2.38116892756107, 52.902812054859133],
            [-2.380874805119813, 52.903856664164813],
            [-2.382449551227868, 52.905575761706032],
            [-2.38267479518001, 52.906333738064703],
            [-2.379203193828888, 52.910604933508708],
            [-2.379172448755049, 52.912872146361032],
            [-2.377108733236418, 52.91325087806073],
            [-2.373500829654856, 52.914600796358449],
            [-2.371732597837526, 52.91459465945146],
            [-2.369149552547469, 52.915651775017089],
            [-2.36870411472326, 52.916259939741003],
            [-2.366554484133891, 52.916708871190146],
            [-2.365273963612023, 52.917585682469237],
            [-2.361511057453711, 52.917555848158443],
            [-2.357917485970344, 52.918581631435586],
            [-2.354654443933272, 52.918034084137197],
            [-2.353261863744317, 52.917055701161551],
            [-2.350846728561188, 52.916329332519972],
            [-2.350677303598812, 52.915791371704941],
            [-2.348288082490119, 52.914944417356743],
            [-2.347371159659015, 52.914094914249972],
            [-2.346153496072843, 52.916737740280809],
            [-2.343188968777273, 52.920049926113883],
            [-2.341415215721906, 52.92163716163536],
            [-2.34005294229314, 52.92203480352903],
            [-2.339000630384647, 52.923081473355197],
            [-2.336452185802977, 52.924356216665188],
            [-2.333191114045288, 52.926602854193483],
            [-2.332857642946107, 52.928118489294221],
            [-2.330350214337312, 52.928786206461282],
            [-2.328078493196659, 52.929944934865084],
            [-2.325543204715346, 52.929897976999648],
            [-2.323104044325306, 52.930772107730711],
            [-2.322817951305916, 52.930510398063355],
            [-2.319913234615307, 52.932416801338377],
            [-2.312368592089162, 52.930296535296165],
            [-2.310629395351511, 52.931108337728489],
            [-2.308799109949028, 52.93062858837758],
            [-2.305954817587831, 52.932331334327131],
            [-2.302052835696712, 52.930026579227658],
            [-2.301155525354581, 52.930631141990204],
            [-2.297816928194999, 52.931239149718373],
            [-2.296411344386955, 52.931932146483227],
            [-2.293503808670728, 52.930311407033102],
            [-2.293197370598904, 52.929654147183086],
            [-2.288903002677146, 52.92837650728471],
            [-2.287118452458749, 52.927561011781712],
            [-2.286761965557601, 52.926965882393723],
            [-2.286001190039236, 52.927093567821132],
            [-2.285475828523562, 52.926602218274653],
            [-2.282240862165389, 52.926891318265476],
            [-2.279864838068512, 52.926362978429957],
            [-2.279091220704892, 52.926576947224703],
            [-2.27805813007534, 52.925764041360573],
            [-2.277651438022386, 52.925901630664484],
            [-2.276881317892733, 52.925266087476381],
            [-2.276164393643712, 52.925272252043399],
            [-2.276593392760286, 52.92490089385781],
            [-2.276121844172633, 52.924663774927232],
            [-2.272671100087265, 52.924451512935498],
            [-2.272538426673855, 52.9241686544147],
            [-2.27169849231224, 52.924243393130823],
            [-2.27177288011371, 52.924008601931185],
            [-2.270109266242887, 52.923424498119566],
            [-2.269973808675786, 52.923646842162604],
            [-2.2693425873206, 52.923557483999843],
            [-2.269349836453313, 52.92328778831051],
            [-2.266802882141115, 52.923446358168611],
            [-2.266536974957614, 52.923743602640613],
            [-2.264113578720389, 52.923680700383699],
            [-2.262585781685072, 52.92757466021942],
            [-2.263279097183134, 52.930287892767446],
            [-2.264651288139431, 52.930382825056675],
            [-2.264426203043111, 52.93250390127853],
            [-2.263822862843689, 52.932614912949994],
            [-2.264071263018314, 52.933583406396799],
            [-2.263265631112669, 52.933949261925484],
            [-2.265164509690633, 52.935000378658785],
            [-2.266456489593807, 52.93654364369538],
            [-2.267432934216576, 52.936850679732359],
            [-2.267047289042993, 52.937045716378741],
            [-2.267363898774522, 52.937719200300343],
            [-2.268294019859191, 52.937748564607389],
            [-2.268203629247955, 52.938049010979],
            [-2.269318824765905, 52.938408754756196],
            [-2.269794621722044, 52.939310198712271],
            [-2.270699590136978, 52.939118464977795],
            [-2.272124348603356, 52.940665859475878],
            [-2.273149635480989, 52.941146229650577],
            [-2.273444521393984, 52.943309270664038],
            [-2.274039961008634, 52.943341158235064],
            [-2.274118209145048, 52.943948652828311],
            [-2.274794933008152, 52.944359696457816],
            [-2.274508087557535, 52.945121751692383],
            [-2.275725988491109, 52.945214216166306],
            [-2.275289932302066, 52.945444454471456],
            [-2.275432198745915, 52.946052698494022],
            [-2.276051735461599, 52.946356895137598],
            [-2.27533104907498, 52.946528465701263],
            [-2.276526486297294, 52.94705875171767],
            [-2.276180629306174, 52.947199789310226],
            [-2.276855297286349, 52.947743867070628],
            [-2.276278657095308, 52.94786926209445],
            [-2.276360561029305, 52.948343705061426],
            [-2.276548456712751, 52.948635418931794],
            [-2.277141275786969, 52.948478522414852],
            [-2.277387454726661, 52.949271700225502],
            [-2.27703139101304, 52.950146288465682],
            [-2.278020648956309, 52.951230778987792],
            [-2.27541646067987, 52.953876994701098],
            [-2.274576684435647, 52.95430143476375],
            [-2.273328724132502, 52.957740907614934],
            [-2.269751490108246, 52.961778969386266],
            [-2.270225812673397, 52.963355504193132],
            [-2.269313421129561, 52.96315352083829],
            [-2.268375564656286, 52.963645557920806],
            [-2.26735582463839, 52.963176821458262],
            [-2.264404457438361, 52.962832848518779],
            [-2.263653654449395, 52.963489833846239],
            [-2.262566681056575, 52.965673032721959],
            [-2.258702410689359, 52.965062154749496],
            [-2.255849957757369, 52.965594205049314],
            [-2.254282070510007, 52.964053220801894],
            [-2.253575563753623, 52.963914499329455],
            [-2.252451437963519, 52.964411302751863],
            [-2.25228875540606, 52.965619802541667],
            [-2.251084195440496, 52.966377451633129],
            [-2.245925400590485, 52.968246322298263],
            [-2.238544710923307, 52.972035056999715],
            [-2.236057056924053, 52.972690848044174],
            [-2.234393060624528, 52.972567395970664],
            [-2.233498480885516, 52.972911647842764],
            [-2.233361953484645, 52.973275080934776],
            [-2.232137745454501, 52.973802451062262],
            [-2.23203411034955, 52.975026086928288],
            [-2.229996437173028, 52.975777954121909],
            [-2.229464062901351, 52.975360086288873],
            [-2.227104459860287, 52.976357931144754],
            [-2.226353830382658, 52.976346779379163],
            [-2.225767615061414, 52.975879556960585],
            [-2.224505046267657, 52.975934088096658],
            [-2.223724958843638, 52.977136519980888],
            [-2.221095405275937, 52.97868579227395],
            [-2.220065416745125, 52.979684609331464],
            [-2.217926275671423, 52.9804832006079],
            [-2.216491103245895, 52.980301546837637],
            [-2.212514293425612, 52.980562226073665],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000198",
        LAD13CDO: "41UH",
        LAD13NM: "Staffordshire Moorlands",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.950729099098517, 53.211886149791013],
            [-1.948809742884474, 53.211646241839858],
            [-1.939288866472672, 53.212065167643914],
            [-1.937696089185679, 53.211759627338949],
            [-1.936151900960869, 53.21101453714968],
            [-1.935552933491778, 53.211033092652244],
            [-1.932788776828556, 53.209143015253964],
            [-1.930531769461208, 53.20858621513667],
            [-1.929789613010573, 53.207367792671064],
            [-1.928449284642665, 53.206655081398765],
            [-1.924958246663996, 53.205550009682014],
            [-1.922971081742823, 53.203279958971258],
            [-1.921759089658353, 53.202925909950906],
            [-1.919125565575386, 53.203023035038314],
            [-1.918124603085399, 53.2035068531723],
            [-1.917156164778953, 53.203414499656326],
            [-1.916586943676303, 53.202810951454175],
            [-1.915385993239344, 53.202966513572569],
            [-1.915119484167148, 53.202222047096939],
            [-1.913966719558298, 53.202242796235659],
            [-1.913409995506818, 53.201410025762769],
            [-1.912381376051321, 53.201493771458026],
            [-1.912436748039123, 53.200765714254146],
            [-1.911454974589283, 53.199913745659906],
            [-1.910386589750345, 53.199699014409454],
            [-1.909211842618075, 53.198816320166493],
            [-1.908126650778472, 53.198779535369319],
            [-1.908047864470725, 53.198521494331786],
            [-1.907464691375733, 53.19892823979135],
            [-1.906706937096692, 53.198379329819289],
            [-1.90534532871361, 53.198797140180439],
            [-1.903944727464812, 53.198564109761342],
            [-1.903750643772402, 53.199003509115101],
            [-1.901740469946447, 53.198913782073184],
            [-1.900906155461288, 53.199793102919664],
            [-1.900386967462211, 53.199682108157269],
            [-1.89924676329692, 53.200118909776563],
            [-1.898636423766738, 53.199938616236111],
            [-1.897274251704282, 53.199911385057113],
            [-1.896956730259438, 53.199354700876668],
            [-1.896056925995729, 53.199413247895755],
            [-1.89557995484786, 53.199802049801832],
            [-1.894668321879516, 53.199183714441617],
            [-1.894011638427226, 53.199600216980322],
            [-1.893081919707076, 53.199042078807778],
            [-1.891362124927656, 53.198970413963131],
            [-1.890668009723302, 53.199378774074155],
            [-1.889332033808223, 53.19906922567128],
            [-1.888429657783411, 53.198385232308738],
            [-1.8870691634337, 53.198310245012514],
            [-1.884621517366388, 53.196709684684365],
            [-1.884937782050105, 53.196549089013061],
            [-1.884742160002419, 53.196375415183788],
            [-1.883837498977736, 53.196586676058729],
            [-1.882723635828797, 53.196113671733912],
            [-1.882506849531739, 53.196569194420839],
            [-1.881377434098928, 53.196313692891565],
            [-1.880890649683488, 53.195885338090619],
            [-1.880468796254836, 53.196334360180735],
            [-1.879978991959759, 53.195918583051274],
            [-1.879055322928645, 53.195964393294112],
            [-1.876375966167805, 53.194935120275147],
            [-1.875884918991781, 53.195489224387224],
            [-1.874741115898546, 53.195057463001156],
            [-1.873976916787681, 53.195342505093386],
            [-1.874150669497717, 53.194788074829468],
            [-1.873483945373289, 53.194501524058687],
            [-1.873848059576097, 53.193854710283354],
            [-1.871728667892703, 53.193851553407548],
            [-1.871409044011786, 53.193122211725637],
            [-1.871863251469862, 53.192887191448968],
            [-1.871045224485966, 53.192160908304196],
            [-1.870025708447153, 53.192249691607834],
            [-1.870113397418476, 53.191461462335006],
            [-1.869474959403643, 53.191250426295916],
            [-1.869303399436823, 53.191560354973596],
            [-1.868579718431011, 53.190837753500176],
            [-1.867492982024286, 53.190893182269434],
            [-1.866245631929428, 53.189654022305568],
            [-1.865344093687703, 53.189839977616685],
            [-1.863988339729721, 53.188836180208597],
            [-1.862926900738748, 53.188480804118448],
            [-1.860934962436563, 53.188483893616343],
            [-1.860464854220977, 53.187617715330859],
            [-1.859336338872935, 53.187656840861948],
            [-1.859116863804945, 53.186584207765534],
            [-1.855970241584416, 53.185535044671006],
            [-1.855496214135326, 53.184984351637318],
            [-1.854287390687349, 53.184894790934756],
            [-1.854398426373406, 53.184363682930282],
            [-1.852474205048541, 53.183875021033394],
            [-1.851750597058943, 53.182816131628442],
            [-1.850812175836322, 53.182873390142376],
            [-1.850914174650835, 53.182370139463465],
            [-1.849689755681923, 53.182056689036386],
            [-1.848238103803279, 53.180839553068608],
            [-1.848522240368238, 53.180459683633352],
            [-1.847497460314577, 53.179987360233895],
            [-1.847450012891604, 53.179442572254558],
            [-1.846957954514058, 53.179805093840471],
            [-1.846238928922834, 53.179615402717943],
            [-1.846538463340003, 53.179116005385247],
            [-1.846162048188232, 53.178518657701318],
            [-1.845662104071764, 53.178576440058507],
            [-1.845142782186746, 53.178197334525464],
            [-1.843954053195579, 53.178401631948404],
            [-1.844462449101471, 53.178075099583758],
            [-1.8437682697426, 53.17764632102196],
            [-1.843063368043926, 53.178111021297546],
            [-1.840805473120322, 53.176929585274053],
            [-1.839298917673084, 53.176907790349752],
            [-1.839405595707073, 53.176385678547035],
            [-1.838630499003492, 53.176410700728844],
            [-1.837178777561027, 53.175740852940699],
            [-1.837186138065386, 53.175376812227967],
            [-1.836414548896866, 53.175270586715911],
            [-1.835951381363251, 53.174320722072864],
            [-1.83448777332718, 53.174048134689897],
            [-1.834289217610949, 53.173549873458654],
            [-1.832016667285396, 53.17279702044921],
            [-1.833014336278562, 53.170526922296943],
            [-1.830251860255314, 53.167735560561397],
            [-1.828793739345886, 53.167276839898811],
            [-1.829024764992894, 53.166731543658749],
            [-1.828100252366886, 53.166382343876109],
            [-1.826988885232596, 53.166379838492375],
            [-1.826441190421854, 53.165323743341261],
            [-1.825690752314233, 53.165216579938296],
            [-1.825692156003847, 53.164869609484228],
            [-1.82483826639421, 53.164825211978403],
            [-1.825205179463499, 53.163975397730688],
            [-1.823739309132847, 53.163627167245693],
            [-1.823849372890304, 53.163046645075326],
            [-1.822749160994471, 53.162898495907648],
            [-1.822151268043048, 53.162082310154034],
            [-1.821020959029453, 53.16162577978676],
            [-1.821135868834144, 53.161326620732659],
            [-1.820135615804181, 53.161250508635156],
            [-1.8199311599774, 53.160786371298535],
            [-1.819232199025399, 53.160552500807363],
            [-1.819170951819922, 53.159822506598331],
            [-1.818157186214322, 53.159767930799525],
            [-1.817972056496495, 53.159337977376822],
            [-1.817034855037573, 53.159217889598281],
            [-1.816889862117382, 53.158848221870763],
            [-1.81607577517116, 53.158618650434221],
            [-1.816433344984643, 53.157883906226431],
            [-1.815614542882556, 53.15736308274451],
            [-1.815055706280005, 53.156207138920813],
            [-1.815381968517712, 53.155100206857774],
            [-1.81472295529802, 53.154645243450759],
            [-1.812576829892292, 53.154356940982176],
            [-1.812027660309269, 53.152726381164321],
            [-1.812814506505663, 53.151622877127345],
            [-1.814285956792553, 53.151254832712922],
            [-1.814711913322732, 53.150604695359007],
            [-1.816624086925084, 53.149960446249004],
            [-1.816563487259698, 53.149443488466581],
            [-1.81702486697372, 53.14924194578208],
            [-1.816770316161962, 53.147921078073303],
            [-1.816101414904028, 53.14770341484104],
            [-1.81735602034628, 53.147308929854141],
            [-1.816804621662467, 53.146533236386972],
            [-1.817233001975303, 53.145643987063877],
            [-1.818116642454023, 53.145277689586628],
            [-1.817733006838262, 53.144429445309484],
            [-1.818568523358792, 53.143768233045954],
            [-1.817629627177408, 53.143421625068257],
            [-1.817655253848262, 53.143018059982246],
            [-1.818478981270833, 53.14231008758734],
            [-1.819178609409678, 53.142669809868821],
            [-1.819657651991015, 53.142514127284706],
            [-1.821215542775672, 53.141400043854574],
            [-1.820643628949997, 53.140526356934046],
            [-1.821270922779426, 53.140301675662052],
            [-1.821398869699363, 53.139367015091636],
            [-1.821909094781538, 53.139613176795493],
            [-1.822160719862448, 53.138044977824208],
            [-1.821820515551115, 53.137534795622592],
            [-1.821502043558508, 53.137915451600037],
            [-1.82103700539347, 53.137592050763949],
            [-1.82187932714885, 53.136686324901497],
            [-1.820866940360757, 53.13678997950587],
            [-1.820717269952446, 53.13647693848884],
            [-1.821456886445553, 53.135824549969932],
            [-1.820058104977315, 53.13467994944596],
            [-1.819804094529941, 53.133943369067651],
            [-1.817317966598911, 53.133353505498029],
            [-1.816235412641987, 53.134155456490419],
            [-1.815267089354868, 53.133404279983971],
            [-1.815401809034158, 53.133008074816281],
            [-1.814118016528021, 53.132984507072933],
            [-1.813784586067472, 53.132667574928988],
            [-1.813332780982989, 53.133111822837215],
            [-1.812766182818315, 53.132797219319087],
            [-1.813577156881023, 53.132592642334536],
            [-1.814104679504424, 53.131917494823199],
            [-1.812281549795304, 53.132195993789139],
            [-1.811612370806684, 53.131760770895063],
            [-1.813317305333948, 53.131162089573643],
            [-1.812538044754128, 53.130265564779663],
            [-1.811524835953122, 53.129902610635057],
            [-1.81088576164561, 53.130110143149807],
            [-1.81025518230664, 53.128045271204201],
            [-1.809366527701909, 53.127243834210162],
            [-1.809343999324517, 53.126930981516175],
            [-1.810448409850762, 53.126244186002189],
            [-1.809920082535107, 53.125748051800997],
            [-1.809653786802221, 53.123782634133129],
            [-1.808635047833859, 53.123695608054682],
            [-1.807858355482401, 53.122945576027064],
            [-1.805743419789264, 53.122396517218341],
            [-1.805480776852065, 53.121656294877681],
            [-1.801879236152203, 53.118166229357207],
            [-1.800936953496606, 53.115140759483069],
            [-1.800114830345263, 53.114584762301099],
            [-1.797810661244227, 53.114037041806064],
            [-1.795364256732242, 53.112621593246523],
            [-1.793790971181663, 53.110469609644099],
            [-1.789759069995964, 53.109545687334879],
            [-1.788032398070764, 53.109202844637586],
            [-1.786248839664733, 53.107985243524681],
            [-1.786076962992979, 53.106798384754633],
            [-1.78822991094696, 53.105786470005434],
            [-1.787992700964989, 53.105423790790667],
            [-1.784521353896583, 53.103933478188935],
            [-1.783729382323404, 53.102810213489981],
            [-1.78354853486853, 53.101643109543922],
            [-1.784494720604986, 53.101489313935936],
            [-1.786370047251086, 53.099792864660046],
            [-1.782526965808524, 53.096598403246269],
            [-1.783037961093073, 53.095952124005095],
            [-1.785491800067471, 53.094998334512582],
            [-1.783210171654978, 53.09377529240853],
            [-1.782975046286825, 53.093316423436626],
            [-1.783563395885786, 53.092719722184079],
            [-1.787440867067213, 53.091906900355696],
            [-1.78861862083633, 53.091376843818232],
            [-1.79079232498477, 53.091765410349886],
            [-1.792298716446963, 53.09150826181979],
            [-1.792861067364302, 53.091007651986843],
            [-1.792881723265314, 53.089813942964454],
            [-1.794242851012474, 53.088376255651738],
            [-1.793132834022221, 53.086641245733034],
            [-1.791131751353436, 53.086419321511173],
            [-1.790833248857808, 53.085781474331505],
            [-1.7910863251978, 53.085007062054366],
            [-1.791919317571656, 53.08440535402265],
            [-1.791986998120907, 53.083681853419286],
            [-1.788608734819616, 53.080887503540708],
            [-1.788588490356531, 53.079228087246271],
            [-1.790253906777187, 53.079027877483547],
            [-1.791359653393614, 53.078182150136847],
            [-1.79084558782863, 53.07675648247502],
            [-1.789756247012519, 53.07638331710401],
            [-1.79005377011612, 53.075362683819236],
            [-1.789161012639504, 53.074783110529353],
            [-1.788811939827386, 53.07321479879684],
            [-1.787523563820999, 53.07236304117842],
            [-1.786007821151797, 53.07041778996463],
            [-1.785678719287916, 53.068162738114559],
            [-1.785071017317358, 53.067922533958551],
            [-1.784226450110582, 53.066702088268627],
            [-1.784234454215555, 53.065098450590739],
            [-1.785647143746232, 53.063093738045808],
            [-1.783062407004599, 53.06135236797811],
            [-1.778110142416917, 53.060439843813874],
            [-1.775656841863898, 53.059336782111963],
            [-1.777017494941298, 53.058204913041052],
            [-1.779304851140533, 53.057611397626239],
            [-1.782168147126488, 53.056111892916974],
            [-1.78246407146981, 53.055713317734558],
            [-1.78190473063253, 53.055388687529323],
            [-1.783207614813301, 53.054413950613743],
            [-1.783815184035143, 53.054939119176915],
            [-1.78414913000264, 53.054689827775171],
            [-1.784215398530632, 53.05187366252909],
            [-1.783363893735221, 53.050616339244826],
            [-1.783648374729337, 53.050109870705953],
            [-1.784699559604796, 53.049913115960884],
            [-1.780790880945302, 53.047904113065499],
            [-1.779973245320768, 53.047061224885766],
            [-1.778985247844911, 53.044826500196315],
            [-1.780109105857896, 53.044690147807259],
            [-1.780981445972499, 53.045915173317944],
            [-1.781663443224278, 53.045850804808737],
            [-1.790193534086649, 53.042877253921759],
            [-1.790638808877208, 53.041786758609945],
            [-1.789929958413322, 53.040946825930838],
            [-1.790919668832242, 53.038295877742485],
            [-1.79466410032184, 53.039318162001337],
            [-1.793644651656195, 53.036676295029544],
            [-1.793911847434676, 53.033804731630866],
            [-1.79341588237206, 53.03336520296839],
            [-1.793456363307821, 53.032381862306245],
            [-1.7909708831539, 53.029545050363694],
            [-1.788283398726767, 53.03027651369203],
            [-1.787924488331115, 53.029882151024701],
            [-1.788135379794899, 53.028835291941256],
            [-1.791569634439381, 53.027203530426284],
            [-1.791110003691248, 53.02636134167706],
            [-1.790436977784136, 53.025562822524556],
            [-1.788817183769707, 53.025390965500179],
            [-1.787287247724103, 53.025443076583741],
            [-1.786093940675207, 53.025860732261933],
            [-1.784868864720154, 53.024565886819964],
            [-1.786779195972205, 53.02355534673783],
            [-1.787345604737627, 53.023578832319423],
            [-1.790260187707296, 53.022355184586779],
            [-1.790965244739308, 53.022992855729434],
            [-1.792250678836089, 53.022612167313333],
            [-1.792445199234595, 53.020597139560834],
            [-1.794725463115821, 53.020406027125922],
            [-1.793482752062173, 53.01591020124738],
            [-1.794098081643664, 53.01471570883411],
            [-1.794844520455757, 53.014469795216968],
            [-1.796004221553497, 53.013190832228233],
            [-1.796877386054252, 53.013565374844362],
            [-1.797650272724693, 53.014669660300356],
            [-1.797724611667769, 53.015986697998152],
            [-1.798733437126761, 53.016373142995675],
            [-1.798748484530073, 53.01666351811236],
            [-1.799178240954223, 53.016564463941421],
            [-1.799325705366438, 53.017230808801855],
            [-1.799990232654137, 53.017294850726984],
            [-1.800872531450141, 53.018950333079822],
            [-1.800722607785234, 53.020107884785105],
            [-1.801413637559643, 53.021554493907317],
            [-1.802993574394965, 53.022274451530528],
            [-1.806197638214463, 53.022536797583669],
            [-1.807381612179051, 53.023817873196684],
            [-1.80820873472231, 53.025906486276227],
            [-1.811646130820596, 53.028465784942895],
            [-1.814194775146527, 53.029741749896836],
            [-1.816509814081771, 53.029176326207107],
            [-1.818533116607261, 53.029187515726214],
            [-1.819708325617552, 53.028765911974425],
            [-1.821148842712671, 53.028722238789904],
            [-1.824792457764901, 53.029942084835476],
            [-1.825425992370677, 53.030724170233775],
            [-1.830198885431012, 53.032274501491713],
            [-1.830485506790922, 53.032954487524762],
            [-1.833744029656425, 53.032487149945538],
            [-1.836681661520619, 53.03168398930773],
            [-1.839339062212228, 53.031629172794048],
            [-1.842275422187864, 53.031130603673603],
            [-1.844012123221922, 53.03042634447575],
            [-1.845293350867555, 53.030319246379058],
            [-1.847864994423345, 53.031304169975286],
            [-1.851160012672047, 53.031407211427918],
            [-1.854664977822599, 53.032937890594738],
            [-1.856036046632733, 53.034079375281465],
            [-1.860665786932495, 53.031447469423433],
            [-1.863984860962311, 53.030075967330347],
            [-1.863035092054952, 53.028646507432939],
            [-1.866997119261583, 53.024640943494461],
            [-1.871598291606129, 53.020014785942642],
            [-1.872704027847496, 53.020653302002877],
            [-1.876221118353845, 53.021554122217616],
            [-1.877503704665958, 53.019789980357942],
            [-1.879242184917575, 53.020752693165292],
            [-1.880381944004475, 53.020994751209521],
            [-1.882418271428322, 53.019366147334246],
            [-1.884179983378744, 53.020037562818928],
            [-1.885003988902016, 53.018490430596465],
            [-1.886236504126716, 53.017458760707086],
            [-1.886549699273351, 53.016258109588641],
            [-1.884813799903162, 53.015452815397893],
            [-1.885341211072078, 53.013293231711309],
            [-1.883392191716911, 53.010871461661736],
            [-1.879217007100188, 53.007318385280847],
            [-1.876960113273351, 53.005951522303988],
            [-1.876208844390515, 53.004450453177341],
            [-1.877308150946116, 53.00355806705695],
            [-1.878135936036682, 53.003823197783859],
            [-1.878915218637365, 53.00331700317745],
            [-1.879365866407549, 53.001486367018437],
            [-1.878409750202812, 52.999705539950021],
            [-1.88116272230722, 52.996118041166383],
            [-1.880597798092608, 52.994584821884118],
            [-1.87872905307515, 52.993686713787419],
            [-1.877582391340167, 52.994037018302656],
            [-1.875936864533588, 52.993778226002853],
            [-1.873768856076707, 52.993885617115907],
            [-1.871176900117146, 52.992765487962131],
            [-1.869385402751183, 52.991536514169148],
            [-1.869172126704789, 52.99112277771858],
            [-1.870139717080896, 52.990871240134027],
            [-1.870198436207075, 52.987676547065412],
            [-1.8697742472712, 52.98656681810435],
            [-1.867703132100209, 52.985298858049291],
            [-1.86801898663417, 52.984785026491267],
            [-1.865277902129453, 52.982986815981974],
            [-1.865768088601382, 52.981973387798561],
            [-1.864489980344641, 52.982033067370317],
            [-1.86398069131862, 52.981528192899937],
            [-1.865732081196753, 52.981095102460685],
            [-1.86435685135545, 52.980786113888087],
            [-1.861585591413898, 52.97923678340927],
            [-1.861291865860252, 52.978407637161375],
            [-1.859950022021454, 52.97794222411973],
            [-1.859944888815898, 52.977686924649696],
            [-1.860937889617392, 52.977329418739686],
            [-1.860651849040463, 52.97643375946685],
            [-1.861351489481658, 52.976060624840628],
            [-1.861246579158168, 52.974871230347105],
            [-1.862530747331502, 52.974243474581961],
            [-1.864485362089836, 52.974449769346222],
            [-1.867951942627872, 52.974021281471785],
            [-1.870914224143924, 52.974397580380199],
            [-1.872389314347791, 52.971584647432728],
            [-1.873446949473191, 52.97195792907003],
            [-1.87712680744444, 52.972359104322244],
            [-1.877537292880078, 52.970953614027216],
            [-1.879725663152886, 52.971141021069577],
            [-1.879971776315922, 52.969911544702967],
            [-1.883756620443124, 52.97054454237923],
            [-1.887928863930732, 52.970547640554912],
            [-1.888554376828234, 52.969919881108773],
            [-1.888350327415153, 52.96936235876003],
            [-1.893139935425054, 52.970759179125693],
            [-1.8932581454688, 52.971130539548255],
            [-1.895702858501648, 52.97125585620433],
            [-1.895618255647072, 52.971761874763558],
            [-1.899198284812996, 52.971628320178624],
            [-1.899101013229735, 52.971194957877003],
            [-1.900525689327835, 52.970694557274314],
            [-1.899898220418888, 52.970303001222121],
            [-1.901240963769505, 52.969147209417436],
            [-1.903364025118782, 52.965899347396274],
            [-1.904901441360265, 52.965485281842668],
            [-1.906175214813255, 52.964422866327894],
            [-1.90967042362925, 52.962528839771032],
            [-1.910969513501716, 52.96281657318319],
            [-1.915037473357591, 52.965522600150472],
            [-1.916273978213034, 52.96512974688514],
            [-1.916256610504966, 52.965643918054198],
            [-1.916870945725687, 52.965938295331284],
            [-1.918780140007813, 52.965688811613902],
            [-1.922182971756646, 52.963584006969803],
            [-1.925469427281728, 52.963173500291425],
            [-1.925821670873696, 52.961776794497439],
            [-1.927390994707981, 52.960760179629922],
            [-1.927236638775923, 52.960480520652489],
            [-1.928032839269661, 52.959727706362358],
            [-1.923301526940768, 52.957404638101551],
            [-1.918427528984894, 52.957620741349096],
            [-1.917397976373147, 52.957333276165862],
            [-1.916980142971719, 52.957094771355528],
            [-1.91748704069457, 52.955895959481097],
            [-1.915922735063369, 52.953518112794484],
            [-1.917802789310425, 52.951861807944965],
            [-1.910908466381633, 52.949018052765581],
            [-1.908904114699947, 52.94884124765003],
            [-1.903551128620003, 52.945995535078474],
            [-1.903210553878009, 52.945228475896457],
            [-1.906216034997391, 52.9441243018588],
            [-1.910187840414648, 52.9397918427184],
            [-1.912584804787488, 52.939869134370511],
            [-1.914184522434711, 52.940548985671974],
            [-1.919134124590873, 52.941065732093577],
            [-1.920443620112568, 52.941855869626693],
            [-1.923524747056012, 52.942200375919334],
            [-1.923529160227473, 52.940535568578596],
            [-1.926895244784856, 52.941279292932492],
            [-1.928196965774302, 52.940557347411428],
            [-1.930477752911727, 52.937956308048619],
            [-1.932211453292022, 52.933947195263357],
            [-1.934367714172126, 52.931651642843804],
            [-1.935179660886056, 52.93187502058494],
            [-1.936746282923588, 52.931822824607416],
            [-1.937004149393518, 52.931480469827314],
            [-1.939483837152362, 52.931684011509645],
            [-1.940512139370718, 52.931458897751554],
            [-1.941109442139603, 52.932011135074987],
            [-1.942033550102315, 52.931827307355796],
            [-1.942617253561062, 52.932557517971098],
            [-1.944409764430543, 52.932709385713309],
            [-1.944529346404275, 52.932266270399651],
            [-1.9466992966703, 52.932774253371306],
            [-1.94661558437427, 52.933100526392089],
            [-1.947641791044524, 52.933378749021507],
            [-1.949813545646827, 52.933691608732332],
            [-1.95057997209282, 52.933488770803969],
            [-1.951667097107952, 52.933870360390451],
            [-1.951377629866097, 52.93462444231389],
            [-1.95224805003964, 52.934568160947983],
            [-1.952431361842232, 52.934280577576175],
            [-1.953802158479706, 52.935165658948506],
            [-1.955446455098228, 52.934905594462244],
            [-1.956344771619626, 52.935221449014854],
            [-1.95688056166032, 52.935041858158542],
            [-1.956634652996225, 52.93546516354305],
            [-1.957732850063549, 52.935274085451866],
            [-1.958060967886018, 52.935991544155769],
            [-1.958892954977335, 52.935688894849832],
            [-1.958990823224552, 52.936038610760306],
            [-1.959884686656632, 52.936396686490767],
            [-1.960912990784874, 52.936175891018962],
            [-1.961400665594921, 52.936558991882308],
            [-1.962257695278327, 52.936532297461689],
            [-1.964483932331579, 52.937868782777677],
            [-1.964449494841745, 52.938133955704288],
            [-1.965295939985864, 52.938357137629552],
            [-1.965225740988428, 52.938692416603764],
            [-1.966609245476474, 52.939048784903477],
            [-1.966781594950819, 52.939380536413282],
            [-1.968511939292185, 52.939585958675288],
            [-1.968699068765702, 52.940080416789151],
            [-1.967888118787843, 52.940082000092914],
            [-1.967957899703214, 52.940292367357458],
            [-1.969911551162407, 52.940401643672921],
            [-1.970301109349397, 52.940836820739399],
            [-1.97111806570946, 52.940774095483484],
            [-1.974022336873167, 52.938789032623049],
            [-1.975229115625212, 52.938676921876009],
            [-1.976272517609774, 52.938007432702435],
            [-1.976288405903285, 52.936140366461288],
            [-1.976697811285705, 52.935673004901645],
            [-1.976297750649278, 52.935367291740761],
            [-1.977408067897115, 52.934580046049717],
            [-1.978910893099208, 52.934254008606885],
            [-1.97969634931831, 52.934451907471008],
            [-1.980159267460731, 52.933971957897924],
            [-1.983958757698112, 52.93472582342271],
            [-1.985318294371213, 52.935654587019222],
            [-1.988337357289637, 52.934662500297698],
            [-1.993651861469995, 52.933910489791757],
            [-2.000984991790777, 52.93122914064201],
            [-2.001954893068701, 52.929989506159821],
            [-2.000790066895059, 52.929608377096315],
            [-2.001524942833351, 52.92940251398651],
            [-2.002546871582596, 52.928452328101301],
            [-2.003658081496671, 52.92831026610542],
            [-2.004202476503663, 52.927749316198792],
            [-2.005066763755383, 52.927886816469645],
            [-2.007297970156196, 52.927109123173402],
            [-2.009212452110243, 52.927223149724689],
            [-2.013298178993661, 52.925490520087607],
            [-2.015605096017089, 52.925044366212738],
            [-2.017282634972569, 52.924252174853791],
            [-2.019522653030575, 52.924116983781758],
            [-2.021904313503682, 52.924668505113658],
            [-2.025901180012663, 52.92481062181087],
            [-2.030311903144992, 52.923290380389609],
            [-2.030836597802729, 52.922789541221],
            [-2.033220476539788, 52.92226841391561],
            [-2.033886922282187, 52.922401265638555],
            [-2.032883360562636, 52.920997423663216],
            [-2.034176674397394, 52.91829217934503],
            [-2.034758107485595, 52.91819941971572],
            [-2.034963018288835, 52.917796638965875],
            [-2.03802046709577, 52.91749455197187],
            [-2.040573215494344, 52.916662192616798],
            [-2.041528751096995, 52.917460109436881],
            [-2.05378670226469, 52.918987839658868],
            [-2.062959089930978, 52.922378566838773],
            [-2.055950296529821, 52.931339004715319],
            [-2.051832085154047, 52.933555837091262],
            [-2.049289290911958, 52.935968744257373],
            [-2.047834921024217, 52.936632749323792],
            [-2.046266910652448, 52.936814956667568],
            [-2.046129050462007, 52.937293239248319],
            [-2.045822281973377, 52.938444882776771],
            [-2.049934474664922, 52.93936102029015],
            [-2.046655585079288, 52.942632650952639],
            [-2.044612640251073, 52.945674503213347],
            [-2.046056487643876, 52.945975089082488],
            [-2.046284814260749, 52.946566492330284],
            [-2.043191607993404, 52.948815873595606],
            [-2.0438912977497, 52.950472332980503],
            [-2.043774336426242, 52.951080048997738],
            [-2.04303338984809, 52.951335615605437],
            [-2.044657407693089, 52.951539068665468],
            [-2.044991476736699, 52.952184369260173],
            [-2.045570235006242, 52.95196930470717],
            [-2.045721152247478, 52.952525680239638],
            [-2.047976913482441, 52.953267297114643],
            [-2.047743736401013, 52.953724943633375],
            [-2.048122254445189, 52.954130204668857],
            [-2.048996932281073, 52.953657011613821],
            [-2.048958911417721, 52.954254811516066],
            [-2.049962446335205, 52.954521371983326],
            [-2.050718037360595, 52.955315698592003],
            [-2.052684809188799, 52.955692386566035],
            [-2.053471802317478, 52.956545111539441],
            [-2.053467743371241, 52.956873220318258],
            [-2.05471383043362, 52.957003893297774],
            [-2.055113348908238, 52.957461259684401],
            [-2.055591014776231, 52.957331591278795],
            [-2.056024704808539, 52.957728710677877],
            [-2.057844173076936, 52.958039761448909],
            [-2.057707552932766, 52.958287031961262],
            [-2.05853120304673, 52.958620127817127],
            [-2.058490837769553, 52.959590984389351],
            [-2.059778337366118, 52.960534209828786],
            [-2.05982807076653, 52.960971959978039],
            [-2.060606677554565, 52.960985047671159],
            [-2.060988286828918, 52.961340824956778],
            [-2.063661011247706, 52.960664326919456],
            [-2.065017631129872, 52.960947649175836],
            [-2.067728715644424, 52.962953423142139],
            [-2.068882543227662, 52.962975230652056],
            [-2.068378194787146, 52.965071809257957],
            [-2.067258889683497, 52.967175927218193],
            [-2.067372882983305, 52.967711619668648],
            [-2.068448900347557, 52.968349236857627],
            [-2.075899258389151, 52.970691780664993],
            [-2.088129123777037, 52.973834034542953],
            [-2.087877772413212, 52.973987936594838],
            [-2.085875359187314, 52.973483311915984],
            [-2.084211999583203, 52.975008175906687],
            [-2.082594495162736, 52.974874476124135],
            [-2.079459664360406, 52.974001070931031],
            [-2.079240153808525, 52.974482140443733],
            [-2.080777323438799, 52.97546811726793],
            [-2.079655103611144, 52.975811365592449],
            [-2.080999307072662, 52.977899544977411],
            [-2.08225174888672, 52.977095946678993],
            [-2.084938071949394, 52.978464905068812],
            [-2.083363816162074, 52.979972612476871],
            [-2.084437378558357, 52.981363380136528],
            [-2.084694765410825, 52.981221167209235],
            [-2.089912764764352, 52.983682161203049],
            [-2.089194436341431, 52.984209470468031],
            [-2.089825744964018, 52.984811269607306],
            [-2.091772129692146, 52.985317668190127],
            [-2.091522025469854, 52.988193502660437],
            [-2.09030436330162, 52.988603444285658],
            [-2.089761601788901, 52.989781439540955],
            [-2.090231299739614, 52.989995025555146],
            [-2.090020280766614, 52.99024328740019],
            [-2.088244365475215, 52.989422112321051],
            [-2.088031763887816, 52.989629021594432],
            [-2.088292852723599, 52.990550218630233],
            [-2.088765276132822, 52.990639757673485],
            [-2.089219882786044, 52.991478107018366],
            [-2.087734306906325, 52.993529647342299],
            [-2.088599952028448, 52.995011318723655],
            [-2.088040269768145, 52.995990656717773],
            [-2.088460878837263, 52.996940499193052],
            [-2.089985215575852, 52.997719612186408],
            [-2.09013664756574, 52.998172551361357],
            [-2.091169579181906, 52.998351545051875],
            [-2.091653352053561, 52.99883119376738],
            [-2.095078659550885, 52.998740403012334],
            [-2.095709486913747, 52.997648610267767],
            [-2.096817898901212, 52.997596472269862],
            [-2.096988486200746, 52.997259238939414],
            [-2.098456726964564, 52.99754208695613],
            [-2.101272703571178, 52.995620527364856],
            [-2.104948786632925, 52.997676751364438],
            [-2.107866500321831, 53.002597501728005],
            [-2.107186341810152, 53.003532092358355],
            [-2.107253656955229, 53.004835460059645],
            [-2.103430476778986, 53.0069881630506],
            [-2.104108231131811, 53.007472085769493],
            [-2.102628918297841, 53.00895748444389],
            [-2.104020603913312, 53.010070028466515],
            [-2.103468028490348, 53.010840882072614],
            [-2.108361154902162, 53.012662210251548],
            [-2.108236784913514, 53.012993124468608],
            [-2.108948308278593, 53.013215404137156],
            [-2.10927967486016, 53.012815082266307],
            [-2.109909315549869, 53.013073388177908],
            [-2.10948138958948, 53.013599649000035],
            [-2.111926543741843, 53.013877829092628],
            [-2.113278125828775, 53.013756990824731],
            [-2.113743442352875, 53.013296301291049],
            [-2.114953776824292, 53.013874935045742],
            [-2.115960723113326, 53.014745003734099],
            [-2.114639030137342, 53.016026342312983],
            [-2.114898110366213, 53.016480043546942],
            [-2.116183987967911, 53.016841952984798],
            [-2.118521022567774, 53.018376785398594],
            [-2.120489552989969, 53.01867863616981],
            [-2.12150427500376, 53.017995325788419],
            [-2.121968390019519, 53.018706789791302],
            [-2.122494605005867, 53.018708045686608],
            [-2.122489859226099, 53.01913683262233],
            [-2.121130704169569, 53.01926587335393],
            [-2.120969561949975, 53.02027731593796],
            [-2.122417174959789, 53.020840349571948],
            [-2.122905816838919, 53.020725682520279],
            [-2.123738733728685, 53.021621034156794],
            [-2.122250691260872, 53.022588908311633],
            [-2.119538285025885, 53.026136086165074],
            [-2.118804055643784, 53.026428071181819],
            [-2.11967965269834, 53.027650611917622],
            [-2.120483917800847, 53.027872728604173],
            [-2.121293801307133, 53.027438627785493],
            [-2.122198357462155, 53.028324926878405],
            [-2.123251143876918, 53.027848313546947],
            [-2.124355953029096, 53.02991016572453],
            [-2.123706581749814, 53.030686605648071],
            [-2.123731451536283, 53.0315594235528],
            [-2.125021048057549, 53.03302419801966],
            [-2.125572687885784, 53.032991254937784],
            [-2.127021231410763, 53.033705247770037],
            [-2.129168268482804, 53.03559963500723],
            [-2.128184924555075, 53.036401631044711],
            [-2.127412515330009, 53.035924241585207],
            [-2.125952098583056, 53.037300235634568],
            [-2.126931875635142, 53.037798986807204],
            [-2.126509172869319, 53.040118628681569],
            [-2.129042947189494, 53.043556931875194],
            [-2.131779481991011, 53.04731676419253],
            [-2.129323920540101, 53.048221070619974],
            [-2.127097354114795, 53.047899867394548],
            [-2.127182913200555, 53.048080456635702],
            [-2.125690598913681, 53.04838048383197],
            [-2.123897700983676, 53.048427313826252],
            [-2.118209256971979, 53.046592146946061],
            [-2.117968189059866, 53.046803630567325],
            [-2.119584389749936, 53.047608335591399],
            [-2.117255777169579, 53.047572005339759],
            [-2.116584229988499, 53.048015829235872],
            [-2.119819696835624, 53.049618959072717],
            [-2.121749376892669, 53.0509752478699],
            [-2.121429477047343, 53.051272215532855],
            [-2.12399178447206, 53.052613436377115],
            [-2.127438259323196, 53.053721737666024],
            [-2.127367010984675, 53.059398420964008],
            [-2.125400543224561, 53.064562041422079],
            [-2.12784042839732, 53.065577901651892],
            [-2.134641690965875, 53.064892609613651],
            [-2.137408500220675, 53.06540002010037],
            [-2.139158407942244, 53.065228089262824],
            [-2.140513261598187, 53.067471964087311],
            [-2.139917302464952, 53.068235839529422],
            [-2.140462260422881, 53.068765551330564],
            [-2.142434089473574, 53.068420719743628],
            [-2.143032529396869, 53.069316210658492],
            [-2.143654121685333, 53.068643078322403],
            [-2.145031085215737, 53.068485894227088],
            [-2.146610658160325, 53.069104200867955],
            [-2.148023785891338, 53.070350130838946],
            [-2.149137213602692, 53.06993254446806],
            [-2.149491787070237, 53.070181095936199],
            [-2.151249059618177, 53.069514579571795],
            [-2.151664842031696, 53.070190027309017],
            [-2.153411176740234, 53.069394949296317],
            [-2.153828089614948, 53.069959822525142],
            [-2.15505911554942, 53.069890804170903],
            [-2.155352423306897, 53.070523251286986],
            [-2.156635581450804, 53.070421787818077],
            [-2.157502428432427, 53.070754136150839],
            [-2.161147617640808, 53.070102933946529],
            [-2.161769250913149, 53.071103471227659],
            [-2.161369564284153, 53.071184017440075],
            [-2.16146305772227, 53.071838294389359],
            [-2.162193928075289, 53.07171504762546],
            [-2.162612223213091, 53.072214267880327],
            [-2.161718604427788, 53.072720672954581],
            [-2.16189812143941, 53.073227411649057],
            [-2.162880323827777, 53.07325213752047],
            [-2.163863316447165, 53.075839635535196],
            [-2.163671336984883, 53.076776560030602],
            [-2.165511104628636, 53.079309824325485],
            [-2.166305316823173, 53.082418028481818],
            [-2.167346032911301, 53.083231874186957],
            [-2.168994569967472, 53.085226907500186],
            [-2.168627745307275, 53.085341589460988],
            [-2.169298277704126, 53.086907426517897],
            [-2.172104101809978, 53.088876491002409],
            [-2.171466296762139, 53.089189332615504],
            [-2.171700182290353, 53.089429901207339],
            [-2.174270133704644, 53.090236971909434],
            [-2.174879268670723, 53.089851346295745],
            [-2.175552865900364, 53.089897995142366],
            [-2.177626124653062, 53.091172257081091],
            [-2.178532484956023, 53.091531359485991],
            [-2.179089097637087, 53.091446924046359],
            [-2.181206904594221, 53.08977984300433],
            [-2.181150324457354, 53.090527817359742],
            [-2.182672561381214, 53.092453636200517],
            [-2.183742779247084, 53.093403026599589],
            [-2.184318059670008, 53.095234999917778],
            [-2.185798531053336, 53.096062382674667],
            [-2.186229661398656, 53.097685126867823],
            [-2.188942176881577, 53.100516884442598],
            [-2.189271711069951, 53.10141526266785],
            [-2.191259138692112, 53.101980188057979],
            [-2.19137848359496, 53.102284723869772],
            [-2.192261745357532, 53.102075652242014],
            [-2.1936593470933, 53.102324177483482],
            [-2.194701549715627, 53.102915750666071],
            [-2.195213378144396, 53.103799431053218],
            [-2.196770405259494, 53.10459958371824],
            [-2.199303738417357, 53.10526594472045],
            [-2.200293223362597, 53.106373526774881],
            [-2.201444421191806, 53.106914518492346],
            [-2.201644943470785, 53.107629704305815],
            [-2.203815628339448, 53.109576619306061],
            [-2.205878934785259, 53.112457638370984],
            [-2.209472159386482, 53.115289192369509],
            [-2.211315340540571, 53.115807293470993],
            [-2.202645865401123, 53.121614895461775],
            [-2.193510127000708, 53.129603345797022],
            [-2.186534371325432, 53.135959811094075],
            [-2.180094209050291, 53.142545166994282],
            [-2.17658126299669, 53.145513195618996],
            [-2.174368091748088, 53.146504347408055],
            [-2.173281029785118, 53.147587307352808],
            [-2.172409532992325, 53.148002964703551],
            [-2.171669957769556, 53.14738649371953],
            [-2.16928986690524, 53.148188124700901],
            [-2.168059166909941, 53.148142228962229],
            [-2.163861034978622, 53.151354445535567],
            [-2.159543891713749, 53.152888436581762],
            [-2.158612357017301, 53.154118470986582],
            [-2.155655671634122, 53.156501749940098],
            [-2.155076785783576, 53.157704326886396],
            [-2.155645964239319, 53.159601146864539],
            [-2.154757841041742, 53.159252636605807],
            [-2.152157339682386, 53.159747687176079],
            [-2.151011352587309, 53.159638584249151],
            [-2.148634816573467, 53.158316610297305],
            [-2.14705160714151, 53.158474986305706],
            [-2.145423502943415, 53.157303034200446],
            [-2.141411918161904, 53.156634602180908],
            [-2.144497099940753, 53.171319691743975],
            [-2.144462641520066, 53.173527409965075],
            [-2.144867324263973, 53.173749842579078],
            [-2.144509936046075, 53.174688719372519],
            [-2.143521971867217, 53.175419815057673],
            [-2.144456734265392, 53.176217796861266],
            [-2.144298950648398, 53.176902942906501],
            [-2.142648589557213, 53.177775059843469],
            [-2.142386639831366, 53.179093146650665],
            [-2.141601912474691, 53.179334986170822],
            [-2.141362509838436, 53.179788311514365],
            [-2.142272185068776, 53.18112657243892],
            [-2.142175577668347, 53.182226928839825],
            [-2.140732983879305, 53.183560798867305],
            [-2.140166886253007, 53.183877876472046],
            [-2.138221949252467, 53.183998806395543],
            [-2.134609009082818, 53.18332788526898],
            [-2.133465615500842, 53.182797031666652],
            [-2.129856543320758, 53.178401862094006],
            [-2.13003394391453, 53.176706361494105],
            [-2.129349508187338, 53.175483718384733],
            [-2.127871739827199, 53.174610696568742],
            [-2.125250548008342, 53.175104281192873],
            [-2.124616465236851, 53.174679772370922],
            [-2.124983894921058, 53.17395757755019],
            [-2.124488465442993, 53.173361234005007],
            [-2.123513397703304, 53.173482702436928],
            [-2.121650377903526, 53.174380817430176],
            [-2.119769187367941, 53.174692844897379],
            [-2.118855290809899, 53.174229035828048],
            [-2.119410713828453, 53.173828472888609],
            [-2.11930775689882, 53.172849684375201],
            [-2.117870148534046, 53.171785034175493],
            [-2.11632471844117, 53.17175599437936],
            [-2.115098776384625, 53.172598549624105],
            [-2.114414896860431, 53.172522804201378],
            [-2.113925607199681, 53.171923714072463],
            [-2.115852092148801, 53.171240492624342],
            [-2.116036650703315, 53.170892441794969],
            [-2.115365709571583, 53.169498914869706],
            [-2.111789668408476, 53.168606137719387],
            [-2.110777536266221, 53.169407997534357],
            [-2.110198566198656, 53.169386063523895],
            [-2.109942419040353, 53.168666288913961],
            [-2.108741907518376, 53.168342893741055],
            [-2.103752343172054, 53.169844008702803],
            [-2.1017380656191, 53.168886633660314],
            [-2.100382848404536, 53.169548471314137],
            [-2.098627051227698, 53.170371528140677],
            [-2.09767529326348, 53.171534580233704],
            [-2.096908470097811, 53.171143291824912],
            [-2.095771605790794, 53.171172978234999],
            [-2.094612348196951, 53.172580670990421],
            [-2.093333198168233, 53.172552918347407],
            [-2.092731057256869, 53.172900361643144],
            [-2.090704115439876, 53.172272703293359],
            [-2.08914511386208, 53.170752061653452],
            [-2.0879371984624, 53.170417675834457],
            [-2.087238908400828, 53.169835709515958],
            [-2.085567361343562, 53.170280078787222],
            [-2.082934214929533, 53.170121045402205],
            [-2.08209806227398, 53.170938718138665],
            [-2.081372701247563, 53.17103180316402],
            [-2.079535683070038, 53.171041137887379],
            [-2.078052066531717, 53.170424584544598],
            [-2.075580675462917, 53.171213611862754],
            [-2.071578655387125, 53.171919024966968],
            [-2.070178018910281, 53.171679857635915],
            [-2.069409799579751, 53.172120765747223],
            [-2.06914326566077, 53.172894865958391],
            [-2.069810235980138, 53.172738068587016],
            [-2.070404747687768, 53.173099071320465],
            [-2.069320181934773, 53.174057926730036],
            [-2.067482859599354, 53.174907537957871],
            [-2.063262290808665, 53.174844244298107],
            [-2.058244894568819, 53.176303015677945],
            [-2.056865744324722, 53.177648423929625],
            [-2.058228684788354, 53.17868508102331],
            [-2.058414028935531, 53.179644104793645],
            [-2.059599491386738, 53.179950935158871],
            [-2.059217735457495, 53.180882375514045],
            [-2.057022119769167, 53.181597169319375],
            [-2.056332449913777, 53.182837066659573],
            [-2.054254355376175, 53.18312118563356],
            [-2.052292110029744, 53.183940954911051],
            [-2.053414559586062, 53.185255528347589],
            [-2.054820259240992, 53.185670173174508],
            [-2.054988890447928, 53.186469206759263],
            [-2.052206978496078, 53.187753177649647],
            [-2.049583550955478, 53.190338606456642],
            [-2.048283657258398, 53.190937802275826],
            [-2.047560626368258, 53.192148896000553],
            [-2.046420706132998, 53.192663511584975],
            [-2.04385673898191, 53.192562914287244],
            [-2.042777891410956, 53.192840166134452],
            [-2.04044118404819, 53.192487724615845],
            [-2.039319519308623, 53.193424742929487],
            [-2.038339355151906, 53.19364529183467],
            [-2.036369187828152, 53.193114671548983],
            [-2.0344878240084, 53.193139505045373],
            [-2.033688962907501, 53.193634123244699],
            [-2.03156628342272, 53.193232906655105],
            [-2.028557925653586, 53.193335246531092],
            [-2.023860146465796, 53.19149896477542],
            [-2.021325437020428, 53.189809542548701],
            [-2.018182222699453, 53.189034330560894],
            [-2.016386423105838, 53.189293474687574],
            [-2.011158014469432, 53.191871200937435],
            [-2.010077623063713, 53.192756703491575],
            [-2.009110769280955, 53.192861054384565],
            [-2.009167599930516, 53.192657901408147],
            [-2.007013784494414, 53.1923596218735],
            [-2.005409380274914, 53.192792972667746],
            [-2.004990372611027, 53.193384459577622],
            [-2.001669102515201, 53.19303669043618],
            [-2.000081086170254, 53.19383491728744],
            [-2.000338538037595, 53.194605262344396],
            [-1.998808800925639, 53.196028197961823],
            [-1.998759383029908, 53.197022366098594],
            [-1.996778999037983, 53.197549080854877],
            [-1.99644510621919, 53.198705938785473],
            [-1.994683113885382, 53.199703640715327],
            [-1.994274324299866, 53.200654644069083],
            [-1.994538973103353, 53.203283896669447],
            [-1.994095802952157, 53.203547249970683],
            [-1.994122668443202, 53.204175572164552],
            [-1.992802115384125, 53.204803823496022],
            [-1.993778181501739, 53.205267701093653],
            [-1.993035416372738, 53.206417335739239],
            [-1.992913961903565, 53.207564306068299],
            [-1.990804222783075, 53.208255409045307],
            [-1.990413150816325, 53.209586627299039],
            [-1.989004112229554, 53.209906511636405],
            [-1.988550092148192, 53.211192773505893],
            [-1.987217225337654, 53.211949501505273],
            [-1.987411420612017, 53.21356751312188],
            [-1.986348037258107, 53.214216392601116],
            [-1.984044848807361, 53.214473192394202],
            [-1.983531031518136, 53.214963914192815],
            [-1.982795763762149, 53.2149683063667],
            [-1.981221564397642, 53.215758190989668],
            [-1.98077054894492, 53.216354978784473],
            [-1.978190053136046, 53.216838139544045],
            [-1.976294589352262, 53.218780268713068],
            [-1.970775914135335, 53.220920194936305],
            [-1.962367733229862, 53.226223958756265],
            [-1.961034766373509, 53.219416295317295],
            [-1.956710781431083, 53.213729374161773],
            [-1.950729099098517, 53.211886149791013],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000199",
        LAD13CDO: "41UK",
        LAD13NM: "Tamworth",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.642065292496985, 52.651989418311096],
            [-1.64260841826354, 52.651004881608756],
            [-1.640603938569108, 52.649573949704752],
            [-1.64027215005438, 52.648216391070768],
            [-1.639552976808284, 52.647418614714468],
            [-1.635975689322824, 52.645334651282454],
            [-1.634520736737994, 52.643991601762735],
            [-1.632950373461961, 52.643558839158821],
            [-1.634255369290313, 52.643026182801385],
            [-1.64243043756832, 52.640923239428751],
            [-1.641056840312216, 52.639564332943358],
            [-1.638958210007043, 52.638510654705641],
            [-1.638196603758563, 52.637683972127718],
            [-1.64019087518926, 52.634156163171959],
            [-1.642516882746252, 52.631399743084593],
            [-1.642101243129404, 52.630006872345497],
            [-1.640989400886742, 52.629201623175646],
            [-1.639788861640101, 52.627147413764916],
            [-1.638189140001238, 52.625503705913829],
            [-1.643094868942093, 52.623186624830609],
            [-1.644126314609042, 52.620774169694911],
            [-1.641538692997117, 52.620191926949843],
            [-1.640164555206634, 52.620062806272401],
            [-1.638687892444903, 52.620726256720559],
            [-1.636023614863847, 52.619599777403458],
            [-1.637071136348957, 52.61706606896059],
            [-1.639058402835554, 52.61572456539831],
            [-1.639849785215013, 52.614676963776844],
            [-1.639095380785147, 52.610684995672216],
            [-1.639786257878268, 52.60837581776201],
            [-1.640986704289195, 52.606945584845356],
            [-1.642202010466736, 52.606400880748261],
            [-1.642921543168599, 52.605624532680856],
            [-1.643715917938414, 52.603627588500558],
            [-1.643786191872388, 52.603337428687858],
            [-1.64518929081547, 52.603107901458557],
            [-1.649146097244923, 52.600744569352209],
            [-1.64887242161431, 52.598593394343645],
            [-1.651586075637065, 52.598400928534559],
            [-1.653016642890705, 52.597284993041661],
            [-1.655517105807987, 52.596591079943479],
            [-1.655106652147957, 52.595654944846274],
            [-1.659881676847658, 52.595564460078663],
            [-1.660789006257595, 52.594492776147696],
            [-1.665715326406099, 52.595648478505233],
            [-1.665304860061818, 52.594298846966474],
            [-1.66564680900881, 52.592234849289966],
            [-1.666670881031589, 52.592092098288262],
            [-1.669072786181277, 52.592657096377366],
            [-1.673652499200279, 52.592579895096506],
            [-1.677671692075503, 52.5932471491762],
            [-1.6792301894, 52.592692209798088],
            [-1.680327116473216, 52.593488075253667],
            [-1.681438494801697, 52.593721206521252],
            [-1.682726941689929, 52.593762419245849],
            [-1.682888377086341, 52.593280097870881],
            [-1.685906860771042, 52.593331285305297],
            [-1.685707497353034, 52.589418386042958],
            [-1.688664981144569, 52.588666550329442],
            [-1.691499263568652, 52.589050634195274],
            [-1.691572460712639, 52.589347488885764],
            [-1.693876826489788, 52.589317502863764],
            [-1.694632050160899, 52.590243604591116],
            [-1.697808062983652, 52.590970929205724],
            [-1.69877579321766, 52.590844837750652],
            [-1.699258446743016, 52.590203290996847],
            [-1.700399186995382, 52.590895698777913],
            [-1.701006708371714, 52.59164158967927],
            [-1.700477329458802, 52.59177599957858],
            [-1.700753553084548, 52.592183036970951],
            [-1.700360448702576, 52.593330942673795],
            [-1.701122555599502, 52.593926194365132],
            [-1.701899668805816, 52.594053109457143],
            [-1.702308786181903, 52.594895584133646],
            [-1.704349590066415, 52.595024750636178],
            [-1.70430337993982, 52.595310511435947],
            [-1.70370714956866, 52.595282952330585],
            [-1.703750728666239, 52.596045397319905],
            [-1.700434740308173, 52.597376546482302],
            [-1.69935801618118, 52.597444840358357],
            [-1.699535171869326, 52.597013778566243],
            [-1.698625629781289, 52.597031247455753],
            [-1.696262834571845, 52.598835765141864],
            [-1.695132458889819, 52.598757349272425],
            [-1.693932555743495, 52.599759318167784],
            [-1.694335571419328, 52.60019096973744],
            [-1.693251549575063, 52.601505176531205],
            [-1.692953924668687, 52.603106389239429],
            [-1.691300534974946, 52.603899491150706],
            [-1.690300546350011, 52.605206697242146],
            [-1.688757884835159, 52.605567643325799],
            [-1.688969790410157, 52.606296373225831],
            [-1.690758352081208, 52.606877297973774],
            [-1.690962008803713, 52.609204388251754],
            [-1.690334508296061, 52.610654602624649],
            [-1.692071701017228, 52.612060635488916],
            [-1.690592907258585, 52.614225122664024],
            [-1.691459887920762, 52.617374705370125],
            [-1.700261514854621, 52.615633486343533],
            [-1.702212707792847, 52.615832581950762],
            [-1.707528666059854, 52.619107304753385],
            [-1.709533263390824, 52.621310231701806],
            [-1.711841572046375, 52.625435874212478],
            [-1.720950166950386, 52.62851777171737],
            [-1.726127973361634, 52.632144622142988],
            [-1.727393727292152, 52.632459482605235],
            [-1.730285537752877, 52.632412155582237],
            [-1.731235201691703, 52.632716367000121],
            [-1.732131225793324, 52.634791430052793],
            [-1.733135157315117, 52.635415783565328],
            [-1.734963170631223, 52.635873864547627],
            [-1.735778381257005, 52.636684760305045],
            [-1.735808969000064, 52.637003964318396],
            [-1.733037813992532, 52.637573103100181],
            [-1.732635281267587, 52.638399252680692],
            [-1.733629431683354, 52.63943261265517],
            [-1.733459374432307, 52.640668319698079],
            [-1.734155334510886, 52.641902374444307],
            [-1.733623168257182, 52.642656318310507],
            [-1.73413629025953, 52.643582513121103],
            [-1.734473148934972, 52.644576633074919],
            [-1.727229098658384, 52.645638963919374],
            [-1.72571714548213, 52.646110135230259],
            [-1.725689480145212, 52.646516407079005],
            [-1.724709675915642, 52.646498854639184],
            [-1.724504805601678, 52.646878644585314],
            [-1.72141372692811, 52.6476643193024],
            [-1.722870406663499, 52.649408142813783],
            [-1.721542929798599, 52.649896772058483],
            [-1.722559557355482, 52.651349196783073],
            [-1.720506282058915, 52.651597886894095],
            [-1.714098937045863, 52.651289551816873],
            [-1.711434208516683, 52.650105444986465],
            [-1.704429578342725, 52.650954807202396],
            [-1.701545793518905, 52.650724640884917],
            [-1.69418640997066, 52.651106836731472],
            [-1.68966721904339, 52.650963848744695],
            [-1.684316571966249, 52.647229776010604],
            [-1.681316869847189, 52.648762594846808],
            [-1.673211457875613, 52.650697604586121],
            [-1.670984988190326, 52.65153738318768],
            [-1.666481648403875, 52.652229600354666],
            [-1.665402667388196, 52.652022494328662],
            [-1.662696107006572, 52.652214399955774],
            [-1.658408385324778, 52.653286290331017],
            [-1.656123351995478, 52.654754902515769],
            [-1.651583063939265, 52.655746703788118],
            [-1.650478559972659, 52.656328685589422],
            [-1.647268362051946, 52.657155226914696],
            [-1.64495045711317, 52.65749980927869],
            [-1.64288804178537, 52.65442362294624],
            [-1.642065292496985, 52.651989418311096],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000200",
        LAD13CDO: "42UB",
        LAD13NM: "Babergh",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [1.166224131497776, 52.029820394126091],
              [1.163939811956865, 52.029580513263383],
              [1.162655295350172, 52.030015261750378],
              [1.160610946376684, 52.031605437217564],
              [1.16418923412868, 52.031088394787609],
              [1.165414210167636, 52.030594971120308],
              [1.166224131497776, 52.029820394126091],
            ],
          ],
          [
            [
              [0.704432645735238, 52.180114289840823],
              [0.705967136200893, 52.179847967436658],
              [0.707467038466601, 52.179060830677017],
              [0.711834960386548, 52.178216740128619],
              [0.713862347978188, 52.177398502205662],
              [0.714906229672626, 52.177269253879118],
              [0.715354844457315, 52.179230171379061],
              [0.715934821504412, 52.179265375520529],
              [0.715937277283447, 52.177673572102499],
              [0.715670599191916, 52.175169789956257],
              [0.716606597824017, 52.174351454757613],
              [0.719678704874115, 52.174118759052725],
              [0.719860949118711, 52.173893329009047],
              [0.720009365268009, 52.172274774270946],
              [0.71957352751604, 52.171532119535222],
              [0.720348142099043, 52.171283131043729],
              [0.719649462231008, 52.170259666130306],
              [0.720256279900815, 52.170041527300427],
              [0.720070318107265, 52.168887527265326],
              [0.720599446534785, 52.167660372224411],
              [0.721226505893609, 52.16743906297539],
              [0.722272032782222, 52.166215267398911],
              [0.722769190124917, 52.165088661286653],
              [0.723464592460239, 52.164957489319328],
              [0.726814102643888, 52.165971810433597],
              [0.726398120172902, 52.166630722203713],
              [0.728132336840488, 52.168084335160685],
              [0.728996836468116, 52.167656946776134],
              [0.729799800953299, 52.16569947558532],
              [0.731175848655215, 52.1661037647428],
              [0.732746215716215, 52.164739113997634],
              [0.732892851078657, 52.165198852784265],
              [0.732025058878104, 52.166477980735905],
              [0.732973937141727, 52.167022545280709],
              [0.732839511994489, 52.167668658288612],
              [0.734387410289933, 52.16863188674705],
              [0.73830637916946, 52.169955524467284],
              [0.738685727750237, 52.168464671213172],
              [0.738071917288433, 52.166114675026314],
              [0.739467928446933, 52.165343022141734],
              [0.741778546015784, 52.165033907860924],
              [0.742233100401496, 52.161820033879351],
              [0.740877449300154, 52.160652794479404],
              [0.74196209982848, 52.159315494571842],
              [0.743089766457443, 52.159676858725561],
              [0.747575469518756, 52.159575095333452],
              [0.749839291615838, 52.159129317034882],
              [0.752976656778276, 52.159996761991685],
              [0.755333425422552, 52.159493849823868],
              [0.756868420069866, 52.15951192109285],
              [0.759056124709782, 52.157506555762716],
              [0.761512029229338, 52.157922983172647],
              [0.761987352847428, 52.160427197649895],
              [0.76156161966387, 52.161722274710904],
              [0.762871917140054, 52.163540539807819],
              [0.765019918724269, 52.173887913313287],
              [0.768484654632679, 52.174258057551249],
              [0.768658485138641, 52.174984209130706],
              [0.77646418909436, 52.174499385639095],
              [0.777301714114976, 52.175686525601925],
              [0.777102809465025, 52.176532059761698],
              [0.779636920875803, 52.176775409619331],
              [0.782203566825301, 52.177609679864624],
              [0.786888278776284, 52.177878548040468],
              [0.789370610771821, 52.176731687341068],
              [0.792154772927243, 52.176248508092456],
              [0.793047480146463, 52.175624828190486],
              [0.795435990465245, 52.175132057043314],
              [0.796592619264565, 52.175195457739584],
              [0.798369903401166, 52.174357399966851],
              [0.800036141512181, 52.174430253371192],
              [0.804700425753567, 52.173634114086262],
              [0.807652795125176, 52.172134839592609],
              [0.809574927200213, 52.172068365238268],
              [0.814137910048869, 52.170716689017901],
              [0.813689105034939, 52.170418937293533],
              [0.814354622659164, 52.168913792188519],
              [0.81511413372531, 52.169106097577206],
              [0.816569503067994, 52.168205299008569],
              [0.820708373449508, 52.164367000110559],
              [0.825546960019048, 52.162651247140381],
              [0.829738805840628, 52.162708979516545],
              [0.832879436890131, 52.16196445835368],
              [0.837974468162798, 52.161807735277442],
              [0.843293933033847, 52.161249678427112],
              [0.846933177435887, 52.161746402014515],
              [0.853650824920935, 52.161947301127832],
              [0.856263896052513, 52.162835497200959],
              [0.859503941495642, 52.163373882034655],
              [0.857625895988284, 52.165444692386878],
              [0.859408429431358, 52.165920377372274],
              [0.861889635312108, 52.163815111600179],
              [0.863507387287104, 52.164002455193952],
              [0.868790472490904, 52.16556113530136],
              [0.869857538790288, 52.164530619451874],
              [0.872288814126485, 52.163474955427958],
              [0.873989950145207, 52.160100599360845],
              [0.877756193557621, 52.159411563427227],
              [0.877472901302926, 52.159304264809755],
              [0.878712454446312, 52.157576074003458],
              [0.881547986996707, 52.154684706714306],
              [0.882101145270998, 52.154646900993988],
              [0.88294821574904, 52.155446372078515],
              [0.883472974549221, 52.155014450218246],
              [0.888743797895749, 52.155621944045635],
              [0.893850415513177, 52.157662267532039],
              [0.892603839234305, 52.150437082159677],
              [0.897346336248361, 52.150434815395407],
              [0.905608887031538, 52.152334056829076],
              [0.907478697250711, 52.153004716173591],
              [0.911711875003032, 52.153639471617495],
              [0.913023897628169, 52.153468556476362],
              [0.917183129266082, 52.155563662362241],
              [0.91816296629733, 52.155611355107105],
              [0.918713750246239, 52.155961948011381],
              [0.921875601548141, 52.155280159280395],
              [0.92257523652428, 52.153751932371378],
              [0.927245365578676, 52.153019998611327],
              [0.928763059071627, 52.154022828385266],
              [0.929840553033579, 52.153977164235442],
              [0.930704629316303, 52.154354988087704],
              [0.932300565732681, 52.15623447926707],
              [0.933233942156338, 52.156508936081771],
              [0.933348457610531, 52.155893638279572],
              [0.934890079805255, 52.155834578352383],
              [0.936804572261705, 52.15646409402315],
              [0.936508122489307, 52.157047055416065],
              [0.938017886940348, 52.157524753472956],
              [0.939912381500403, 52.15538745644983],
              [0.941643987524122, 52.153709257097397],
              [0.939417022428119, 52.15337360281417],
              [0.938383918073871, 52.152450560913415],
              [0.942923650291158, 52.151099808816504],
              [0.944147618119346, 52.150062881190088],
              [0.944535254445667, 52.147320105289168],
              [0.946140796287427, 52.146197177504057],
              [0.946518878151847, 52.145480844629923],
              [0.944001543502992, 52.144638117147281],
              [0.945383500773735, 52.143473118283701],
              [0.945348238578005, 52.14254048440899],
              [0.946695595234848, 52.142557167732953],
              [0.947125722881914, 52.140903317188233],
              [0.948761305207621, 52.138027681679922],
              [0.948503637120179, 52.137291272547301],
              [0.94645000393356, 52.137089919984049],
              [0.945628363111791, 52.13615625881787],
              [0.94716988520199, 52.135442313012298],
              [0.946332549841888, 52.134669131972508],
              [0.94688674134211, 52.131464411078291],
              [0.947605170141882, 52.131035444038375],
              [0.944777756808112, 52.129467531730512],
              [0.945310555584224, 52.128816582973208],
              [0.944703306299423, 52.127630238398687],
              [0.946558674748617, 52.125941660895379],
              [0.949293569741023, 52.125205030686324],
              [0.951244197104921, 52.123790090288487],
              [0.953623540192215, 52.120266200416268],
              [0.950536884866845, 52.11960966707845],
              [0.951156000680885, 52.114697232951478],
              [0.951221410333494, 52.112658578934983],
              [0.95174692869474, 52.112741644574164],
              [0.952395114670448, 52.111321526557731],
              [0.951515301231446, 52.110562938219744],
              [0.953289286552713, 52.108519239918621],
              [0.95711761105731, 52.10651210407871],
              [0.957295367551916, 52.106351156046919],
              [0.956560681013996, 52.106063817194944],
              [0.959383637442734, 52.104803105637821],
              [0.962048211367635, 52.104504112269929],
              [0.963627392086583, 52.105258522755214],
              [0.96406873100463, 52.10504236866548],
              [0.965208416587101, 52.105423792920789],
              [0.966188924592511, 52.104529440885422],
              [0.970021846486959, 52.106537354677485],
              [0.973088853303484, 52.10690787449051],
              [0.973642039572911, 52.10527868087599],
              [0.971955106149734, 52.105058637065056],
              [0.972206568924061, 52.104777092379997],
              [0.972801190998001, 52.10488979742194],
              [0.973477736668619, 52.103819585092666],
              [0.974100254148746, 52.103912693074463],
              [0.975331265193352, 52.101410194736452],
              [0.97329842753497, 52.100730345274386],
              [0.974765012636845, 52.099924377083987],
              [0.973039688464411, 52.09765390102536],
              [0.970747115094135, 52.095655820510324],
              [0.972975516010325, 52.095350484010389],
              [0.976352977188479, 52.096841762960167],
              [0.977465025320071, 52.096875716968071],
              [0.979843409922136, 52.096498108934298],
              [0.979751586724388, 52.095230545985807],
              [0.984405116652859, 52.097109310049582],
              [0.985690081224685, 52.097239533320213],
              [0.987797433531458, 52.096805678116439],
              [0.990778803220942, 52.096667970024768],
              [0.992755850280234, 52.095968426189764],
              [0.994300498092573, 52.097075852496424],
              [0.996787185267731, 52.098097243229553],
              [1.002741396690751, 52.098714575557494],
              [1.003777458005422, 52.098594629597969],
              [1.005973160185551, 52.096154426461894],
              [1.007074649850467, 52.093774493250152],
              [1.006579454781868, 52.093511030175584],
              [1.010785721689416, 52.091619270332451],
              [1.016729289050476, 52.089622619262272],
              [1.020272653638234, 52.086855320142178],
              [1.019085045035011, 52.08567792317438],
              [1.019773624804145, 52.08526005618355],
              [1.0204710235727, 52.08356846241076],
              [1.027436484095185, 52.077929600979772],
              [1.028175729554945, 52.076732430011965],
              [1.027309886183242, 52.074615100539724],
              [1.027878934362186, 52.074246122969484],
              [1.02644736382864, 52.072825947635209],
              [1.026575025115141, 52.071693065323473],
              [1.025991195300098, 52.070872557554154],
              [1.028909882270334, 52.069836109445525],
              [1.032862692315322, 52.069353052188454],
              [1.035952478520658, 52.06834709550143],
              [1.041405956090388, 52.068603906749772],
              [1.040990014618453, 52.069505021736518],
              [1.041475042396992, 52.069727231756112],
              [1.040860480115298, 52.070969838744546],
              [1.041893064418379, 52.072247256288762],
              [1.041433217459214, 52.074563311598972],
              [1.043511656384491, 52.075207529396629],
              [1.0450887477719, 52.0760976104797],
              [1.045415628643318, 52.077896887928411],
              [1.046427624389735, 52.078096457869272],
              [1.047843250565048, 52.077621841254235],
              [1.049032627791911, 52.076162859791197],
              [1.04902928970954, 52.075300451660681],
              [1.051320218693357, 52.075035165730014],
              [1.05202688944845, 52.074397189840184],
              [1.053294042626941, 52.071622167467602],
              [1.053360475505708, 52.069987189065913],
              [1.057585644670493, 52.068496136077606],
              [1.058159819869957, 52.066520597868582],
              [1.064833292525328, 52.065373091148288],
              [1.069625658961373, 52.065142146080312],
              [1.081771955741476, 52.065792644300679],
              [1.086815866672748, 52.06576037284561],
              [1.091202771975001, 52.065385381349721],
              [1.096523234002605, 52.066502934861731],
              [1.096535630375283, 52.067187936032838],
              [1.097587775924817, 52.068683814832831],
              [1.100091539391346, 52.069539796362392],
              [1.103336777980234, 52.070143249378859],
              [1.104793993289443, 52.070700230649756],
              [1.105724733280572, 52.071766638174658],
              [1.107501191629913, 52.072085825181773],
              [1.107615428620902, 52.071692479383628],
              [1.109167595594709, 52.070989566478772],
              [1.108697257630017, 52.070689897481138],
              [1.11026323407421, 52.069430786901606],
              [1.121990980484372, 52.061507171698729],
              [1.120699121551819, 52.060077215668784],
              [1.121973562489192, 52.057410032990902],
              [1.116790132920144, 52.055907768678459],
              [1.113478742277948, 52.054215490733398],
              [1.110220045892636, 52.053487668110442],
              [1.110498911806966, 52.051643759930769],
              [1.1113302830881, 52.051641581313582],
              [1.112569204882057, 52.049591518219593],
              [1.113686100029475, 52.049332644806462],
              [1.114283075761243, 52.048582958398903],
              [1.107246358075596, 52.046504218137443],
              [1.108649491160031, 52.043604440393104],
              [1.11110457223298, 52.041888322466946],
              [1.111876271935764, 52.04042171664306],
              [1.112731595940869, 52.037599318294006],
              [1.112912856479068, 52.034563594368073],
              [1.116235842203799, 52.035417341471124],
              [1.117577148806005, 52.035308080500577],
              [1.11972447804315, 52.036155981781981],
              [1.122567056557527, 52.035491522875269],
              [1.124965385287359, 52.033895328307864],
              [1.125628143361417, 52.034177230713489],
              [1.126173542900212, 52.033592536556625],
              [1.125949944013512, 52.032708078555693],
              [1.126893373394702, 52.032853908162238],
              [1.126934682852448, 52.032213345022264],
              [1.127578866517776, 52.031561263841702],
              [1.128355046777506, 52.031379649829894],
              [1.128477558707866, 52.030879031548828],
              [1.129308188755184, 52.030725643945381],
              [1.128909087179553, 52.03012646558129],
              [1.12954640886053, 52.029940439421999],
              [1.130155348697751, 52.030433304780807],
              [1.131544729778418, 52.030576232393358],
              [1.131981307642735, 52.030313688225775],
              [1.134000697242707, 52.030437125861937],
              [1.134438578180293, 52.030068409535453],
              [1.136265260788122, 52.030917351717456],
              [1.136506627239688, 52.032115206591797],
              [1.139345215371721, 52.032066526385279],
              [1.141088766949031, 52.032808785554685],
              [1.144284293996663, 52.0332118509168],
              [1.146172007504964, 52.033928600042572],
              [1.146682207983768, 52.033863698630448],
              [1.150004273464162, 52.033990711061442],
              [1.150349103312207, 52.033544378280645],
              [1.151433041831776, 52.033198779998663],
              [1.153480836840304, 52.033992073115662],
              [1.15291267247485, 52.033419986206816],
              [1.15684287219109, 52.028955289666449],
              [1.158864914532684, 52.027606816262875],
              [1.161858995201091, 52.02679072757401],
              [1.162019912008362, 52.02634390170936],
              [1.161333243407034, 52.02516704529183],
              [1.163422945526333, 52.023459611525503],
              [1.164503251960867, 52.021147901205737],
              [1.166902691827082, 52.018815086178577],
              [1.166850889795872, 52.018247160079383],
              [1.172382098416082, 52.014980893429552],
              [1.172728287208121, 52.014579423537491],
              [1.172333443661432, 52.014170045712433],
              [1.17306669461948, 52.014562206595023],
              [1.176860843655891, 52.012340871024271],
              [1.177571279201099, 52.01132155813962],
              [1.178971816288216, 52.0110319064054],
              [1.182286509512651, 52.008531930743615],
              [1.185157575123836, 52.007402863926707],
              [1.188038672933908, 52.006457831604621],
              [1.190110934919598, 52.006251480150198],
              [1.193870836880421, 52.006332086493167],
              [1.195722103279081, 52.006664980981341],
              [1.198184047107232, 52.00597661581773],
              [1.200081300700448, 52.006034772225931],
              [1.199990227524106, 52.005759331493067],
              [1.203464110276095, 52.004774412651834],
              [1.204652270608413, 52.003251745180734],
              [1.203927388299495, 52.002957587608343],
              [1.204948053260581, 52.002965773311232],
              [1.204123247544593, 52.002598787724231],
              [1.205162279779618, 52.002823229034718],
              [1.206794485672135, 52.001581651714247],
              [1.209362061455633, 51.998262991041905],
              [1.211502051533631, 51.996608157051945],
              [1.214572615247896, 51.9964532686487],
              [1.221018106617603, 51.996730331717963],
              [1.222184174575098, 51.996513130669769],
              [1.225256716154173, 51.996831899716923],
              [1.231202563313072, 51.995835034553266],
              [1.233495093821123, 51.994954495525214],
              [1.233299034473028, 51.99423585263321],
              [1.233893743451532, 51.994885978369084],
              [1.234430344191652, 51.994851443773683],
              [1.235875598723958, 51.994547283043637],
              [1.234933349095208, 51.994209824765157],
              [1.235956743247184, 51.994379559062153],
              [1.236698810080012, 51.994123503798662],
              [1.236056471156627, 51.993843462551553],
              [1.238365931672685, 51.994027270643628],
              [1.238909084366506, 51.993881906809825],
              [1.238493357736292, 51.993653204018521],
              [1.23932766173687, 51.993444061375719],
              [1.239757654463802, 51.993729031791325],
              [1.240153984386785, 51.993265712684313],
              [1.238837446125133, 51.993454856467885],
              [1.240000628785194, 51.993139519595097],
              [1.238871400365153, 51.99271460081988],
              [1.239977128558109, 51.992975573199253],
              [1.241376276641047, 51.99189371712454],
              [1.240280922823925, 51.992945622394849],
              [1.240594499875073, 51.99325268306783],
              [1.242977259330375, 51.992717545487544],
              [1.243222834769119, 51.99222509155404],
              [1.241951287615671, 51.991867083882724],
              [1.242936576145106, 51.991691530866326],
              [1.240835250649186, 51.990697102492383],
              [1.242911497274052, 51.991163363950058],
              [1.242911577781373, 51.990701060618811],
              [1.242119413715152, 51.990484543011668],
              [1.242953406229673, 51.990554202072957],
              [1.243848250925857, 51.991706876703475],
              [1.243928533546482, 51.991004016425101],
              [1.243016409273782, 51.990035300062466],
              [1.243888064864286, 51.990766786064675],
              [1.243637470086721, 51.990303297936855],
              [1.244049357733501, 51.99037828778539],
              [1.244271585389392, 51.991114181646367],
              [1.244843968015554, 51.990910421058118],
              [1.244091069945014, 51.9898069062899],
              [1.244634136625338, 51.98998350954453],
              [1.244857046240472, 51.990708590582884],
              [1.24490808854648, 51.989964262977864],
              [1.247629734930831, 51.989947642143584],
              [1.245968349440181, 51.990301983712662],
              [1.245218628101561, 51.990068126581818],
              [1.245403834213303, 51.990513627720908],
              [1.244261479911384, 51.991417564377862],
              [1.24436894546281, 51.991876003328038],
              [1.24516962014195, 51.991766681776269],
              [1.245193764846347, 51.991435929174592],
              [1.245409555929751, 51.991740277466221],
              [1.246219372623043, 51.991556044782897],
              [1.247158867529786, 51.990869050325706],
              [1.248023643141841, 51.990961209012575],
              [1.247635135147775, 51.989418634345562],
              [1.248509719557817, 51.990793091093522],
              [1.25119509778909, 51.990396035207908],
              [1.251193004317013, 51.990085793023091],
              [1.252825713970193, 51.990280816391873],
              [1.257658241122434, 51.989720804677582],
              [1.261232365886586, 51.988605454984679],
              [1.262815099187069, 51.988574170529674],
              [1.262100781474617, 51.988447369484035],
              [1.262682574165224, 51.98815421694006],
              [1.263400934750842, 51.988416715200096],
              [1.267272998881596, 51.987855055581555],
              [1.267977459784629, 51.986526830468407],
              [1.270197860977618, 51.984173407117588],
              [1.270959407556, 51.980952982979048],
              [1.271078247123543, 51.975810359581033],
              [1.270057294359136, 51.976249777464282],
              [1.270545501353337, 51.976733590113795],
              [1.269910801733265, 51.976737739997859],
              [1.270055498912613, 51.97708449456367],
              [1.270219559562608, 51.976877563871888],
              [1.270788269788114, 51.977047037189848],
              [1.270348899658002, 51.977453199109483],
              [1.270692775449525, 51.978294497270461],
              [1.270366996483826, 51.97838090352009],
              [1.270243921523991, 51.978091111590309],
              [1.269816403640849, 51.978260392938445],
              [1.270734558372346, 51.978627922175853],
              [1.270079780852659, 51.97867670267388],
              [1.270690180162285, 51.979158018166849],
              [1.268938176322927, 51.978940477417943],
              [1.269835875679452, 51.97864750423831],
              [1.268309735154225, 51.976851481634895],
              [1.267920942653996, 51.977012485025448],
              [1.268008082347269, 51.977529033120796],
              [1.267538238252971, 51.976397113983914],
              [1.26868309931051, 51.975599002849755],
              [1.269637242544492, 51.976081566191972],
              [1.270858701484933, 51.975455793333076],
              [1.27076703111671, 51.974397918283032],
              [1.269672621278052, 51.975048042095004],
              [1.270539872840533, 51.97407864073638],
              [1.273047514661316, 51.967935974763918],
              [1.275752913027188, 51.964253070592811],
              [1.275786281293446, 51.963473236433174],
              [1.276665971015203, 51.962439578916303],
              [1.278156426157364, 51.961575977541933],
              [1.279057249641135, 51.960374418093018],
              [1.277830185153472, 51.958303950074395],
              [1.276407170568421, 51.957556591705796],
              [1.275805681708707, 51.9565156141581],
              [1.274990596075563, 51.955998651692646],
              [1.269355427703413, 51.954479766921203],
              [1.255853954497243, 51.959641870027468],
              [1.25382068456025, 51.960111861050493],
              [1.245927209084147, 51.960982001847412],
              [1.242931687960189, 51.960185879496287],
              [1.24251626997411, 51.960340338280481],
              [1.242946629923129, 51.961400586283844],
              [1.242395444062348, 51.96104611673568],
              [1.242280213393427, 51.959773013387078],
              [1.241790504767435, 51.960068028017844],
              [1.242253613367449, 51.960392548560165],
              [1.242336184929372, 51.961253717509258],
              [1.241916829203193, 51.961091686219376],
              [1.242187689260296, 51.960790110906238],
              [1.241724925659505, 51.960025007961114],
              [1.241252725059807, 51.961068611876016],
              [1.241193036299024, 51.960342624547202],
              [1.2417035214106, 51.959989619114502],
              [1.241757976181602, 51.959951962714904],
              [1.242131793961382, 51.959693457628681],
              [1.241919320675008, 51.959513132820092],
              [1.241479672054889, 51.959473081254266],
              [1.240936044365246, 51.960594347317887],
              [1.239909148965231, 51.960728764510051],
              [1.239516211267239, 51.960529120963187],
              [1.239101907216871, 51.961001931811815],
              [1.238439229554748, 51.960998589571744],
              [1.238896731118083, 51.960297038367052],
              [1.237970179198832, 51.960369314187062],
              [1.236088260214784, 51.959023397571876],
              [1.234689513852573, 51.958765057510661],
              [1.232924376423273, 51.957886277156788],
              [1.232909670023444, 51.957075404583613],
              [1.22907967255551, 51.956389107413301],
              [1.225181519023962, 51.955279130229805],
              [1.224339137524559, 51.955184398245933],
              [1.223955720308251, 51.955602343127055],
              [1.223379582977424, 51.955586651472089],
              [1.221476280230736, 51.953750910042487],
              [1.219691742697087, 51.952858975194381],
              [1.216756503584186, 51.953459138728384],
              [1.212334477765832, 51.9558842779484],
              [1.21014943554343, 51.955986196035617],
              [1.209709166000396, 51.956017996369603],
              [1.209864370357583, 51.955725049390935],
              [1.209244346207011, 51.955319240367963],
              [1.206592860878434, 51.955167589613282],
              [1.199763353959476, 51.955564910119485],
              [1.195259803038698, 51.956266539415104],
              [1.191054872969095, 51.955436292362684],
              [1.187816015264899, 51.955929736957799],
              [1.187305033311357, 51.955744812562607],
              [1.186271974019827, 51.956264774387598],
              [1.185220347254862, 51.957857326022413],
              [1.18604921213797, 51.956346357997091],
              [1.184581744703445, 51.956981500735466],
              [1.183593861765458, 51.957872570874081],
              [1.17966304799073, 51.963605575587849],
              [1.177841148260681, 51.964812338234303],
              [1.174515884934524, 51.965824899627087],
              [1.171650731568078, 51.966138716019969],
              [1.169413383118562, 51.965961565022923],
              [1.167373920206572, 51.966360060146442],
              [1.165595427914784, 51.967094171299323],
              [1.16301831601043, 51.967282203409631],
              [1.163908801860442, 51.967665690555918],
              [1.164405114009954, 51.969129164801153],
              [1.165202129761527, 51.969549333773372],
              [1.163512408160813, 51.970512170098793],
              [1.162506545813336, 51.969194602103968],
              [1.162977915880757, 51.968713341820681],
              [1.16158857714019, 51.96788365814988],
              [1.159897499672302, 51.965228178966207],
              [1.158222291736091, 51.96612759219623],
              [1.156393175835551, 51.9658951641076],
              [1.156248686687025, 51.965662497383889],
              [1.155889959067028, 51.965882580464822],
              [1.156007504720509, 51.964924261673147],
              [1.153904353401487, 51.963733187747692],
              [1.153858147753587, 51.964339724246344],
              [1.153159435191083, 51.964466380415757],
              [1.153218591149506, 51.964790378625963],
              [1.151939074795588, 51.965250980319183],
              [1.152885759822103, 51.964612222656953],
              [1.153254577324189, 51.963706533741458],
              [1.154302997244346, 51.963583992643343],
              [1.155272477633451, 51.964244240841495],
              [1.155385257876336, 51.963653006750093],
              [1.152492401075452, 51.963281754548049],
              [1.152870065247371, 51.963559443172045],
              [1.151636437780778, 51.963408118223299],
              [1.151271800682877, 51.963772249834157],
              [1.151847006128652, 51.964582492914715],
              [1.150944810324404, 51.964797331752024],
              [1.150516180419527, 51.964104578745953],
              [1.151201700267036, 51.964578192735999],
              [1.151627579351291, 51.96450562600063],
              [1.151062236444645, 51.963690620734582],
              [1.151408313577717, 51.963248738946781],
              [1.14435660541186, 51.962450770152032],
              [1.139089076940961, 51.960638834747776],
              [1.136878204735693, 51.959104966909869],
              [1.132213320747255, 51.957553674401019],
              [1.131075946726956, 51.956524458471378],
              [1.130283346827013, 51.953528068007138],
              [1.128108300604741, 51.953857540039081],
              [1.12215542847632, 51.95558422129843],
              [1.118510898104131, 51.956220522402269],
              [1.113965830601241, 51.955690648871524],
              [1.110580444362627, 51.95477378418407],
              [1.109327989617124, 51.953945272668022],
              [1.107360259662359, 51.953802093881144],
              [1.102923633212302, 51.954014529460274],
              [1.103848028627933, 51.954205099890181],
              [1.102452324273448, 51.95439840855687],
              [1.102568519005426, 51.955363990474737],
              [1.101608869462161, 51.955548486578749],
              [1.101612238203328, 51.955049234852531],
              [1.101108875124092, 51.955230915476214],
              [1.100835349522036, 51.955329638269717],
              [1.100788692778977, 51.955117711969628],
              [1.10108979419369, 51.955224169329639],
              [1.101467928195565, 51.955067429572999],
              [1.100893291052204, 51.954461096692398],
              [1.101314029466868, 51.95454624130555],
              [1.100943581416906, 51.953692589231203],
              [1.099771180206072, 51.953704598504267],
              [1.098722038728714, 51.954258381233622],
              [1.097362540532552, 51.955779077223518],
              [1.09474864853227, 51.956526005498027],
              [1.095607734403245, 51.956106772732049],
              [1.095274392338502, 51.956043591915886],
              [1.093863169093146, 51.956625742718209],
              [1.093802639022962, 51.956930429200952],
              [1.094567985818855, 51.956946276311164],
              [1.094379377553483, 51.957483676590797],
              [1.091899222121193, 51.957722464297291],
              [1.089215204296649, 51.95861681124012],
              [1.08610180054876, 51.958920668181399],
              [1.084228071740801, 51.958537196234197],
              [1.081466981623077, 51.95667765890456],
              [1.076100894102723, 51.955312508271767],
              [1.073237020646921, 51.955814561544258],
              [1.071001156075596, 51.955432245551577],
              [1.070339778590108, 51.955595208040059],
              [1.070713227149986, 51.955138466513866],
              [1.069419094713721, 51.955244187612486],
              [1.070909494919501, 51.954985844315161],
              [1.07089681066021, 51.95463001837193],
              [1.072311683478226, 51.955374645790791],
              [1.071672737460906, 51.954545906068809],
              [1.072534202156736, 51.955137692353929],
              [1.073099920404536, 51.954746973044429],
              [1.072899400492518, 51.954135231212618],
              [1.071578286994761, 51.953143530016369],
              [1.070632489308091, 51.953020719885409],
              [1.0698298759858, 51.953757577042602],
              [1.070391848184563, 51.953695244577546],
              [1.069166655262993, 51.953935870828367],
              [1.070150321232164, 51.954335615083778],
              [1.069914854209705, 51.954723998703649],
              [1.069317542033286, 51.954418502401076],
              [1.068951900752573, 51.954542262953176],
              [1.068345995206867, 51.953748619356013],
              [1.06869382761213, 51.953619928831927],
              [1.068467163720852, 51.953221117064246],
              [1.068132773924665, 51.953418709106181],
              [1.067498194033983, 51.952545763768391],
              [1.067993386200213, 51.952912392210003],
              [1.068491135737185, 51.952804975432244],
              [1.06828444788587, 51.953018124272461],
              [1.068929330988357, 51.953402430084637],
              [1.069291892516173, 51.953297636950893],
              [1.068886924510083, 51.952760475129317],
              [1.067381145014944, 51.952238527432208],
              [1.067015226964041, 51.949586783919266],
              [1.066020689779528, 51.948983137201772],
              [1.064513726405114, 51.949444213649528],
              [1.063480962431175, 51.952547908735362],
              [1.062459850543123, 51.953113224988279],
              [1.061005200334875, 51.952806616999865],
              [1.060833180405029, 51.953037737448554],
              [1.061627251932386, 51.953518932309528],
              [1.060834437703297, 51.954551368868493],
              [1.058783409180462, 51.955918711204092],
              [1.05795753918915, 51.955441021795451],
              [1.059213622357145, 51.953985541748061],
              [1.059408160140315, 51.953016343157422],
              [1.060285326804196, 51.952345972670784],
              [1.060430557560214, 51.951379853242258],
              [1.059456037419076, 51.950855676086448],
              [1.060300566085303, 51.950582782330514],
              [1.059917257026434, 51.950361610755884],
              [1.060662640063966, 51.949872742424397],
              [1.061381924124259, 51.950154421942386],
              [1.062048685520322, 51.949964387602201],
              [1.063608637598112, 51.948444285889806],
              [1.060274667339549, 51.949903521124781],
              [1.058541523635232, 51.950314641489335],
              [1.05789162032489, 51.951051942545746],
              [1.059000928562925, 51.950905284675379],
              [1.059036738219723, 51.951131897828503],
              [1.056864317592233, 51.95165514494434],
              [1.055159624857786, 51.953684365330133],
              [1.053889504277045, 51.952987042133621],
              [1.05360686894445, 51.954053851594566],
              [1.054343273583044, 51.954479930607143],
              [1.053909428429406, 51.954628798510711],
              [1.053078076665186, 51.954176400504991],
              [1.052829612810516, 51.954654124580671],
              [1.05320982194381, 51.954958142847971],
              [1.052235870532215, 51.955406123991118],
              [1.051667992910191, 51.95621770787919],
              [1.052789913136783, 51.956618507107443],
              [1.053167469752411, 51.957247271547544],
              [1.052694091680324, 51.957350392605875],
              [1.051833892444272, 51.956923916757979],
              [1.050319754977431, 51.958442665801194],
              [1.048301128792293, 51.959308024031571],
              [1.045075341989246, 51.959633488582838],
              [1.042445203284178, 51.959296808678943],
              [1.041682544335647, 51.957386457761181],
              [1.042052551551286, 51.956530574583084],
              [1.039826282014739, 51.95592077586106],
              [1.037886540333426, 51.956920622082826],
              [1.036416005795569, 51.957024237351028],
              [1.035428675510245, 51.957729644602544],
              [1.034260481477989, 51.95722374762682],
              [1.033106459946832, 51.957746362971498],
              [1.031804120049632, 51.957344630354157],
              [1.029007512512038, 51.95723408776302],
              [1.028116050773261, 51.956621174854945],
              [1.028056783701967, 51.956022815323671],
              [1.025769837562709, 51.955870298285859],
              [1.025520932502543, 51.95496293195275],
              [1.024681945660717, 51.954890069354605],
              [1.023681829088305, 51.954975135377381],
              [1.022827568461728, 51.954588769605238],
              [1.021088225529406, 51.954439179793702],
              [1.019930070846357, 51.95399224365233],
              [1.019410612181262, 51.953323848872387],
              [1.018666244551771, 51.953207144074192],
              [1.017175456707532, 51.953918117663392],
              [1.017067470135406, 51.954737520009139],
              [1.016161091739704, 51.95537323396848],
              [1.011531339394611, 51.955057431890097],
              [1.011033041159922, 51.955506382032084],
              [1.008972029883321, 51.955987211390372],
              [1.007562826301342, 51.957528793693939],
              [1.00667553340036, 51.957626119329731],
              [1.006529020216582, 51.958199166525254],
              [1.004913433492512, 51.959486969255849],
              [1.005426454780274, 51.96062686189542],
              [1.004889595437045, 51.960936467308052],
              [1.004364191672908, 51.962222496019329],
              [1.001233660943738, 51.963392426789873],
              [1.000112215831213, 51.963430019649955],
              [0.998679718455926, 51.96395489411637],
              [0.99758314416721, 51.963907287996285],
              [0.995205706115857, 51.963340969545158],
              [0.992464711176961, 51.961753172285562],
              [0.989052695686087, 51.961366830594471],
              [0.982447209921011, 51.962106445564245],
              [0.980261010860612, 51.961455835580111],
              [0.975816230037532, 51.961256251567299],
              [0.973860545080951, 51.962369646569805],
              [0.971454239962731, 51.962263156760081],
              [0.968654411380742, 51.963404972749501],
              [0.968193474848429, 51.964096520913323],
              [0.971468287934837, 51.966985370266578],
              [0.971998639122691, 51.968486507429212],
              [0.971274005363846, 51.969791798349874],
              [0.967848956858985, 51.970514082361795],
              [0.964859619129639, 51.971948376519869],
              [0.965165060061109, 51.972780685884487],
              [0.964145412553536, 51.973106761442608],
              [0.964166208800183, 51.973730395584759],
              [0.965308295176143, 51.97422145406437],
              [0.965330269043394, 51.974708355202679],
              [0.964584204394561, 51.975671483734629],
              [0.963297497781014, 51.976144579142485],
              [0.963309041899772, 51.97700317969116],
              [0.962458252442065, 51.977153209061733],
              [0.961874749812756, 51.976917873786547],
              [0.959010349744297, 51.976832543780517],
              [0.957827037096956, 51.976246228810261],
              [0.955904438488192, 51.976330510857146],
              [0.953145995727417, 51.975176634533483],
              [0.952656852781351, 51.974669977347325],
              [0.95310161933069, 51.974037358670671],
              [0.95152459640302, 51.972820516149667],
              [0.948788998665846, 51.972496976114016],
              [0.948142378359897, 51.972030221158811],
              [0.945210283773942, 51.971484882543784],
              [0.943262752574078, 51.970719689122596],
              [0.941989771372918, 51.970714647812983],
              [0.940648788719395, 51.971383113042911],
              [0.939929645931903, 51.971408276521522],
              [0.94006309720373, 51.971956249225443],
              [0.939541699157675, 51.972355099489597],
              [0.937232042041005, 51.972619623344393],
              [0.93520019656102, 51.971922054839034],
              [0.93203038474304, 51.971747457063408],
              [0.930757042403597, 51.971670354282551],
              [0.930146915055053, 51.97122417790721],
              [0.927750199261361, 51.972252437879334],
              [0.926623347794995, 51.973953269437168],
              [0.926504430320012, 51.97492123535342],
              [0.925137619172599, 51.976181937827455],
              [0.922533411236311, 51.976176489763361],
              [0.920697294401325, 51.974815483174062],
              [0.919687346025693, 51.974803670819327],
              [0.917012427176471, 51.973955362308779],
              [0.915482792404594, 51.974102989186513],
              [0.912772297412395, 51.973826555854878],
              [0.910979151535663, 51.973324118500152],
              [0.909861020266837, 51.973484875672426],
              [0.907641182373612, 51.972958762366737],
              [0.903657384387593, 51.972873658236381],
              [0.900270728184028, 51.973511171681487],
              [0.897300207711192, 51.972249706921815],
              [0.895660949413265, 51.972120076857024],
              [0.894689532031664, 51.972614335507089],
              [0.893697945892898, 51.972615338955329],
              [0.892786654646782, 51.973387800698958],
              [0.891428430740029, 51.973416683032511],
              [0.890750684819295, 51.972796594102491],
              [0.889656457349206, 51.973374768481975],
              [0.888788128387823, 51.972983285837032],
              [0.887867786525325, 51.971737792905458],
              [0.888132933384623, 51.970585515805098],
              [0.886534064962006, 51.968433032522952],
              [0.883987903821677, 51.967646475412643],
              [0.882995139351524, 51.967763434390747],
              [0.879973758718011, 51.96644432751981],
              [0.878775578590942, 51.966740751520874],
              [0.877543716360563, 51.966511868715912],
              [0.87672959592334, 51.967121745211386],
              [0.875834307259945, 51.966782989949444],
              [0.872681691928091, 51.967163072235323],
              [0.872380766347694, 51.967625487003495],
              [0.873169420572369, 51.968550547652669],
              [0.870643299303034, 51.969189576489306],
              [0.867526789077186, 51.969135153390731],
              [0.863062011502871, 51.96825908080276],
              [0.861858819446706, 51.967428574680021],
              [0.861542583542049, 51.966715890315484],
              [0.85886913286909, 51.965499304882975],
              [0.859444961683592, 51.964184862833939],
              [0.858299425526012, 51.962953611784712],
              [0.853852505913318, 51.962483265502698],
              [0.850208063019073, 51.960754922826546],
              [0.842058303315098, 51.96180731313936],
              [0.841188879951821, 51.96214757417922],
              [0.840179318227779, 51.96337975950415],
              [0.83582643413708, 51.96471863226143],
              [0.8334363491626, 51.966041574799242],
              [0.830468049637141, 51.966324351498194],
              [0.829895906497351, 51.966948762085764],
              [0.828870551221072, 51.966966219967631],
              [0.826118972897744, 51.967709532855544],
              [0.824844327051175, 51.968509069749928],
              [0.821609866780817, 51.968707176277768],
              [0.819805876363534, 51.968104692386639],
              [0.818135580838221, 51.968193266930101],
              [0.816367135138675, 51.967042184305917],
              [0.815170856818244, 51.966980874196281],
              [0.814111980395335, 51.965720150459561],
              [0.81383206734897, 51.96429690055681],
              [0.814520848027923, 51.963625712682109],
              [0.813930610064431, 51.963104723358569],
              [0.814150803702783, 51.962320633136422],
              [0.812487190089578, 51.961024892283369],
              [0.811549517953775, 51.960743323904573],
              [0.809623783582657, 51.960747049911667],
              [0.807655872793989, 51.961752710126078],
              [0.799702317470272, 51.962350502715225],
              [0.7991123615323, 51.963209010614868],
              [0.796817225457432, 51.963430856307852],
              [0.79573444860977, 51.963717391459724],
              [0.792761817489754, 51.963463316588403],
              [0.79101977084098, 51.963636847573817],
              [0.78993302728561, 51.96315719287815],
              [0.789394120531508, 51.963410988736456],
              [0.787128428808904, 51.963122925663093],
              [0.785684223401217, 51.963967418089894],
              [0.78639967794159, 51.966323812486394],
              [0.785404018778898, 51.966726000605483],
              [0.785089928233521, 51.967483475330994],
              [0.78275526135584, 51.967635831534615],
              [0.781437222285609, 51.966501459869363],
              [0.776047046910176, 51.967509174925212],
              [0.774280445927217, 51.9696687503004],
              [0.772820052232446, 51.969786805068644],
              [0.772228263950206, 51.970413188393401],
              [0.772454215173473, 51.970889005693778],
              [0.773679415615167, 51.971512148764084],
              [0.773915609124493, 51.97212981567948],
              [0.773016928180947, 51.974035083719215],
              [0.772539437099595, 51.978092402844609],
              [0.772669286170508, 51.97901564988134],
              [0.774001929533181, 51.9801245939857],
              [0.773841514435299, 51.98082805041431],
              [0.774600839940082, 51.982580026521724],
              [0.772663752133892, 51.982556426282123],
              [0.771028686694837, 51.982069726023688],
              [0.767066416537548, 51.983781727756927],
              [0.762229210983348, 51.983920527387617],
              [0.760436738723509, 51.984520159631543],
              [0.759635498363286, 51.985417586565482],
              [0.760094299802582, 51.986364607824918],
              [0.759618131447414, 51.987006197913118],
              [0.761656819916908, 51.98825160970749],
              [0.762880438751208, 51.990854313942606],
              [0.761508462878146, 51.993579979795733],
              [0.759552337463478, 51.993751758785329],
              [0.760308587227099, 51.994725080425845],
              [0.759542341622473, 51.995436422954135],
              [0.760447712711711, 51.995707474803595],
              [0.760181042796381, 51.996582472833076],
              [0.761070973259583, 51.99688625772378],
              [0.761117200411007, 51.997471531279842],
              [0.759728184069937, 51.997841348866871],
              [0.758843555254515, 51.998452938620872],
              [0.757399778009524, 51.998738576078289],
              [0.752424166804406, 51.998571732189767],
              [0.751996590414237, 51.999415398352177],
              [0.75131032882215, 51.999248877582275],
              [0.749831693796501, 51.999846400640173],
              [0.749603309860139, 52.002157589897415],
              [0.750447039854628, 52.003109144921609],
              [0.749275041156405, 52.004384775878641],
              [0.749127197283856, 52.005538457095746],
              [0.74637945075806, 52.006688966385468],
              [0.745300339510091, 52.006819355458909],
              [0.74519218309807, 52.007766161137489],
              [0.744007396383385, 52.008027604543294],
              [0.743436387851785, 52.007507616949681],
              [0.742539422442218, 52.007733559928326],
              [0.741117984464652, 52.009320683834694],
              [0.739665877149012, 52.00981133704439],
              [0.738495183937855, 52.01068662902771],
              [0.738507704395522, 52.011511909940864],
              [0.737784377212755, 52.012267970174506],
              [0.73960080589748, 52.014303136792122],
              [0.739401139562997, 52.014947196211303],
              [0.741136227897755, 52.014958073688717],
              [0.742378315177262, 52.016344672583827],
              [0.741461523953617, 52.020252865813546],
              [0.742296388297646, 52.020651605904888],
              [0.742608451347747, 52.022701971309083],
              [0.742259840236808, 52.023079709574432],
              [0.741248300790529, 52.023201289212722],
              [0.740885221702189, 52.025392378918831],
              [0.741754292667078, 52.027277793869992],
              [0.741695082689054, 52.029431232365539],
              [0.740786382595863, 52.031021690109512],
              [0.73914485141453, 52.032264966869533],
              [0.738018283157186, 52.032082526413653],
              [0.737015281938173, 52.032323477687491],
              [0.735472487859216, 52.032010393855288],
              [0.719000603915071, 52.027777525414407],
              [0.714365057847649, 52.02730621755974],
              [0.713480996140788, 52.027994780391069],
              [0.711939844436045, 52.028329754673379],
              [0.710563531315886, 52.028232843165561],
              [0.706497422457084, 52.029512558882836],
              [0.707395063890225, 52.030542315677295],
              [0.70812688806262, 52.032422128292922],
              [0.71062130557456, 52.032995151324769],
              [0.712476403015313, 52.034856271041846],
              [0.703750351895551, 52.036175681794496],
              [0.702592222831689, 52.037740979699343],
              [0.699983818196259, 52.036852044487048],
              [0.69866189488747, 52.036742053712217],
              [0.696480680185398, 52.039289389406605],
              [0.696495066651808, 52.041344871170899],
              [0.697016026204884, 52.043118061771516],
              [0.698651465353557, 52.044506894910548],
              [0.698360743947213, 52.046846352623497],
              [0.698959971670575, 52.046866795382662],
              [0.699637629186776, 52.048284755382603],
              [0.699368950525903, 52.048424910157955],
              [0.700533195925163, 52.048478258133152],
              [0.701165893415894, 52.048086040771459],
              [0.702629296547492, 52.047876204341655],
              [0.70309906987803, 52.047370805260577],
              [0.703953120091009, 52.047311630701756],
              [0.70699830351112, 52.048151774222951],
              [0.709247673875721, 52.048300596612073],
              [0.709828665010681, 52.04862177208031],
              [0.71194960954262, 52.048431761377003],
              [0.712677600319514, 52.049012135541425],
              [0.713691582520341, 52.048583188674556],
              [0.714124709465682, 52.048557919688548],
              [0.714360723520899, 52.048938283210163],
              [0.716232110467054, 52.048673908273969],
              [0.716033926940432, 52.051153372710196],
              [0.715002320706478, 52.051484721109077],
              [0.714219281493313, 52.052259086304396],
              [0.713057398341252, 52.052391075487243],
              [0.713014449077412, 52.053198742736654],
              [0.711359474706708, 52.053354662455703],
              [0.710875466926736, 52.053696748336904],
              [0.710810205683779, 52.055533733652886],
              [0.708980961250475, 52.056041661014845],
              [0.70856328542435, 52.056660995798694],
              [0.70883428420174, 52.056968621014391],
              [0.707725710267411, 52.057694670049578],
              [0.70694455121938, 52.059975276055063],
              [0.705920712440567, 52.060873824469084],
              [0.704941777687427, 52.062660743839103],
              [0.703536740874831, 52.063409746148594],
              [0.703780256915136, 52.064783688129786],
              [0.70314656933046, 52.065259574680546],
              [0.703449357528815, 52.065851561667152],
              [0.70400911916731, 52.065866591625323],
              [0.704123989013118, 52.066415227539366],
              [0.705127060660502, 52.066884115035904],
              [0.707140395591854, 52.066782104539541],
              [0.707620833987827, 52.066281840717657],
              [0.709848546451351, 52.066973437777115],
              [0.711307452305797, 52.068702502820962],
              [0.710914142877662, 52.06996608430493],
              [0.711802563126248, 52.070852133715512],
              [0.71272677375245, 52.070980138297365],
              [0.712020004190895, 52.071742834654714],
              [0.712087404223937, 52.072228705675499],
              [0.709447236980133, 52.073261608081957],
              [0.708875819912101, 52.073103881959909],
              [0.707694717203514, 52.073771340062407],
              [0.706560623316762, 52.073841468160019],
              [0.706248606372179, 52.074184076511344],
              [0.706676580775873, 52.074672457532316],
              [0.706264233744114, 52.075069530594462],
              [0.706852264022293, 52.076731420975705],
              [0.704774994938565, 52.078148770565548],
              [0.703043602983556, 52.078480783240231],
              [0.702996067016594, 52.07904663531454],
              [0.702050238845526, 52.079481112353704],
              [0.69764436558218, 52.079588379569387],
              [0.694683175829721, 52.078697496397304],
              [0.692938022890443, 52.078487396536076],
              [0.692397074979936, 52.078714693482638],
              [0.69172206027991, 52.079406391830574],
              [0.689938054985076, 52.080079347492088],
              [0.688111014952911, 52.083388202009012],
              [0.688222775014369, 52.084761578225994],
              [0.687662176845069, 52.085562153515696],
              [0.684257045709084, 52.086932974210626],
              [0.683086017241949, 52.087026196558028],
              [0.681059661571523, 52.086440120964149],
              [0.680835224075861, 52.085034231623951],
              [0.678914605119675, 52.083871962729773],
              [0.6759418947342, 52.084494386564302],
              [0.675686894401551, 52.083916535122988],
              [0.673764693689599, 52.084702094003354],
              [0.672412294548639, 52.086156363031343],
              [0.671653760051809, 52.085823740679494],
              [0.670850678282139, 52.086089255234207],
              [0.670212018768259, 52.085759301863106],
              [0.66890381167209, 52.08580423232879],
              [0.668436217313861, 52.086212312089302],
              [0.665742488117887, 52.086009798962536],
              [0.664372157591754, 52.085477838678926],
              [0.664437320688595, 52.084979055522261],
              [0.663222567336828, 52.084092839075794],
              [0.660830647829503, 52.083685547376817],
              [0.658580269517491, 52.085412632691096],
              [0.656870318779221, 52.085427824707665],
              [0.656505331881203, 52.086188762670773],
              [0.654161208294742, 52.086046447792462],
              [0.654091030807082, 52.08624137647535],
              [0.653922216918128, 52.087553648765613],
              [0.654747115853121, 52.087604310025945],
              [0.65469101869652, 52.089265666506208],
              [0.655235420237212, 52.090885613178152],
              [0.653497857401741, 52.095358262393475],
              [0.653736691387662, 52.096601998187651],
              [0.653158361482349, 52.096575453229612],
              [0.652891697515845, 52.097593159095553],
              [0.651225493654744, 52.099879785395665],
              [0.649300126607441, 52.099522007645334],
              [0.648816464251786, 52.100649798495652],
              [0.650516120043213, 52.101189821860203],
              [0.648611274644393, 52.102140036395632],
              [0.647784174672647, 52.102993164588156],
              [0.646786062933788, 52.102685549312461],
              [0.643505261716395, 52.10743459318843],
              [0.643094920024521, 52.107336782485234],
              [0.642567339040692, 52.108985317334991],
              [0.643347543355962, 52.109064042593573],
              [0.642363083458483, 52.110223719225857],
              [0.638580992532115, 52.110089037495072],
              [0.637948412272783, 52.113499802497017],
              [0.637256616704444, 52.114647486456924],
              [0.635673683257503, 52.114333976797212],
              [0.634920740554842, 52.115362509624354],
              [0.632645318138965, 52.115220915710864],
              [0.633817459874556, 52.116866495355815],
              [0.633958584478478, 52.118144821709905],
              [0.636298279925146, 52.119397369025002],
              [0.634933959864515, 52.121132923194047],
              [0.63596508340779, 52.122216884426599],
              [0.635797708831592, 52.122945450873438],
              [0.634332452736756, 52.123046556912982],
              [0.63179721168904, 52.122370281112588],
              [0.630930593012518, 52.122514630362566],
              [0.628583330191439, 52.124608377176166],
              [0.630651414169885, 52.12541385598697],
              [0.6307669938931, 52.127325849362016],
              [0.633322592482426, 52.127698635989205],
              [0.629507596711233, 52.127848558716444],
              [0.628724768152259, 52.128723036989662],
              [0.626865000284792, 52.128976731489253],
              [0.626886799652146, 52.130986140294254],
              [0.632808656443045, 52.130554549192453],
              [0.634315341865197, 52.132216032200802],
              [0.635181282913458, 52.132626532498541],
              [0.636300396809651, 52.13242434811945],
              [0.637178029597117, 52.13337863873317],
              [0.637915364425365, 52.135395418467006],
              [0.636862703462464, 52.136055664430941],
              [0.636078899613214, 52.136121757421343],
              [0.637987012792699, 52.137868641480644],
              [0.636277844563605, 52.140157783862918],
              [0.633690300759088, 52.141361310693398],
              [0.633343915412271, 52.142476064836139],
              [0.634033250459089, 52.143457067134342],
              [0.634584750739668, 52.143266684672149],
              [0.63676207778925, 52.143999477123067],
              [0.637755108319759, 52.143959274814613],
              [0.638576942387855, 52.144608149208793],
              [0.638461443630869, 52.144929080262834],
              [0.640232194881338, 52.145804002910452],
              [0.639557290291388, 52.146898255537415],
              [0.637902241242893, 52.148054908531435],
              [0.639099221434476, 52.148767326853282],
              [0.637511200546572, 52.150218336268289],
              [0.638849135714163, 52.150940193602644],
              [0.639679591307804, 52.152967466213084],
              [0.640152801503963, 52.152910106817266],
              [0.641357598894672, 52.15626981220003],
              [0.644175473708877, 52.155734521265479],
              [0.644659946429999, 52.156335165615467],
              [0.645581203112317, 52.156362157412467],
              [0.646345968610949, 52.156930425533659],
              [0.644586031172073, 52.15826937982245],
              [0.645352517389993, 52.15911279596795],
              [0.645344597597703, 52.160162435093795],
              [0.649879655824022, 52.160396022191534],
              [0.653570428908526, 52.161503658987613],
              [0.656252064105817, 52.160985536027262],
              [0.657824719224739, 52.161243259201456],
              [0.661117924583094, 52.16051159498101],
              [0.663388956500337, 52.160686031867783],
              [0.663060935525419, 52.158645770752599],
              [0.663683573352604, 52.156316045610112],
              [0.664210011608192, 52.156341919749387],
              [0.664148702070498, 52.157665254403369],
              [0.666884294955954, 52.15812858565306],
              [0.668290618883122, 52.158075181341964],
              [0.673440739731274, 52.159228296665027],
              [0.675510759053852, 52.1592451862074],
              [0.676758371711574, 52.157545989978814],
              [0.674102044406839, 52.157332889828695],
              [0.674224419482851, 52.156715900960272],
              [0.677057240290096, 52.157409706274244],
              [0.682014305853145, 52.155982277172512],
              [0.684832143375447, 52.157810240313651],
              [0.685213560929941, 52.158875304110595],
              [0.686152736194697, 52.15929545961108],
              [0.686988704590585, 52.158681979153634],
              [0.687959125240317, 52.158649965037036],
              [0.688577544577319, 52.157993770598772],
              [0.689559923682378, 52.157626934675086],
              [0.689996206832697, 52.157878673737955],
              [0.691617360253672, 52.157557495725399],
              [0.691918724773235, 52.157802415909359],
              [0.692484888274926, 52.161585389531915],
              [0.693011109322917, 52.162429494267101],
              [0.692577295207212, 52.164423235534372],
              [0.692917734406368, 52.165435254130166],
              [0.692525065929434, 52.165858794788903],
              [0.690842246630019, 52.166326175209853],
              [0.692156591492246, 52.16711901969331],
              [0.693440398906758, 52.166631060354959],
              [0.694474084184665, 52.167255828670307],
              [0.695004604676271, 52.168217633460593],
              [0.695031484759106, 52.168928356899443],
              [0.694368642726301, 52.168729475661614],
              [0.694438590603074, 52.169015649685456],
              [0.69729954537607, 52.17010761850792],
              [0.697157142696911, 52.170606384743962],
              [0.696094812570839, 52.170985001822046],
              [0.696157245309467, 52.172089699873915],
              [0.695491495998009, 52.172399888624234],
              [0.696468256182226, 52.173168928767105],
              [0.69499054444274, 52.173065123055913],
              [0.693126549884268, 52.175427877695938],
              [0.695710443128806, 52.175584660411253],
              [0.695155414213611, 52.176952576260554],
              [0.69583439732566, 52.177585441271816],
              [0.694233561423359, 52.179754242684325],
              [0.69441231591514, 52.180194406339041],
              [0.695104382544294, 52.180148014280896],
              [0.695823269576483, 52.180690038648436],
              [0.697301032779488, 52.180838799358469],
              [0.697269138951547, 52.181133596194478],
              [0.698115539498394, 52.18129229100348],
              [0.703324132783188, 52.181060581700471],
              [0.704432645735238, 52.180114289840823],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000201",
        LAD13CDO: "42UC",
        LAD13NM: "Forest Heath",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.67476067493635, 52.4576539983427],
            [0.67859257766469, 52.454600604367855],
            [0.681480102074661, 52.454156555495075],
            [0.68394895576208, 52.453146419866158],
            [0.694446594933582, 52.450998488813347],
            [0.696904161998969, 52.451352506083275],
            [0.701132270222446, 52.451318914983283],
            [0.705562681210235, 52.452568278971953],
            [0.706485765904721, 52.452571427875533],
            [0.707479192698778, 52.451993836445013],
            [0.713284125926381, 52.450099087593536],
            [0.714858935909282, 52.449930738027753],
            [0.715835183997117, 52.449268039385544],
            [0.716835701788212, 52.449717159082013],
            [0.718879242424478, 52.449560462886922],
            [0.71818879159379, 52.44757458878],
            [0.716201159351052, 52.445481838862861],
            [0.705031839020443, 52.438290316504045],
            [0.69612419068719, 52.43326196380923],
            [0.692196419750489, 52.429047652903328],
            [0.69138799323993, 52.427707287589314],
            [0.690077657331373, 52.426723657517584],
            [0.684717132908323, 52.424835714360277],
            [0.683129406871848, 52.422550791046454],
            [0.680585724942215, 52.420967373026954],
            [0.673794657193758, 52.415303036023779],
            [0.671972989587717, 52.412441442825944],
            [0.668437944176475, 52.409157209148034],
            [0.672409084548608, 52.404770816020147],
            [0.684517787389551, 52.399116960472632],
            [0.690743139338184, 52.397512252576298],
            [0.694711282108965, 52.39684358970996],
            [0.708157975341783, 52.396028947817165],
            [0.712411565780586, 52.394604054411239],
            [0.708755153567133, 52.392219460378755],
            [0.705935688000332, 52.388781434379226],
            [0.700769850827983, 52.381135393114],
            [0.693144392537702, 52.371792571637243],
            [0.691163756882983, 52.369906093237383],
            [0.68216603039576, 52.364368953859227],
            [0.675690235539856, 52.361200249721485],
            [0.669448333560495, 52.358435974580701],
            [0.663453462444958, 52.354808808837085],
            [0.653777950656983, 52.343975604124445],
            [0.644587883765078, 52.331356521246882],
            [0.639376497782371, 52.324989458844293],
            [0.62478849564166, 52.310247574044148],
            [0.622902460380995, 52.308821000788846],
            [0.622317012355706, 52.307852015274953],
            [0.620895760400417, 52.307758579216717],
            [0.619499799418246, 52.308341706776282],
            [0.617947569198547, 52.308539803209598],
            [0.616577393235374, 52.309337246449289],
            [0.614444572883636, 52.309393490444066],
            [0.614205602509033, 52.310591197841056],
            [0.61261433749387, 52.311124611123475],
            [0.613922358197474, 52.311884306715292],
            [0.613841397227121, 52.312352813714362],
            [0.613183062054103, 52.312783743217523],
            [0.612371305273095, 52.312279239550833],
            [0.611710786557889, 52.31419308335024],
            [0.609487123536663, 52.314604652720142],
            [0.606112392772115, 52.316528062743906],
            [0.603354047796787, 52.317607771713156],
            [0.603053681694287, 52.318615264635504],
            [0.602728649637535, 52.318627825654296],
            [0.601693790650891, 52.317772062186791],
            [0.601523687261449, 52.316173338964724],
            [0.600768701264981, 52.315309602091972],
            [0.600732086509772, 52.315085595013294],
            [0.602181495065633, 52.314680467926443],
            [0.60106801516632, 52.312682580115165],
            [0.591719657918844, 52.306470192401655],
            [0.590948094721141, 52.304989870388148],
            [0.589939556273838, 52.304349250317465],
            [0.58864256081468, 52.302259055400889],
            [0.587972027531575, 52.2997414577626],
            [0.588525967080581, 52.298349856483519],
            [0.589827366177064, 52.297024587279147],
            [0.589521266951348, 52.296026834816125],
            [0.594422933125969, 52.298009076717577],
            [0.596365261038629, 52.294029429453573],
            [0.598365169085275, 52.291314631445324],
            [0.600305914716128, 52.286066991118311],
            [0.600921265605139, 52.283129944559903],
            [0.596370107656915, 52.282579982577936],
            [0.596014324838692, 52.280632821983872],
            [0.594653724047603, 52.278826456013569],
            [0.594694570347852, 52.276063037363571],
            [0.596180364634692, 52.27276515518809],
            [0.595053094196061, 52.264636364446012],
            [0.58936396214162, 52.264871040152123],
            [0.589418142948931, 52.264467882706782],
            [0.588497486471475, 52.264463800296561],
            [0.574109925635291, 52.265131011229876],
            [0.568378785703911, 52.26583320155428],
            [0.562908284314084, 52.26582263824131],
            [0.563240270262593, 52.262837997866406],
            [0.561158906309826, 52.25800293495233],
            [0.560713493207429, 52.254672769602244],
            [0.561892318949477, 52.251329830104666],
            [0.566659471492962, 52.247589639940209],
            [0.563932173425548, 52.245975417489689],
            [0.563523464824992, 52.246204615634149],
            [0.562742894919647, 52.245752159973186],
            [0.562401516035854, 52.245929533470843],
            [0.560306983902484, 52.245326637398179],
            [0.556874557527775, 52.243901109289176],
            [0.558957691562431, 52.242577207361336],
            [0.555790274953059, 52.239131568631564],
            [0.55808603217259, 52.238196947840457],
            [0.55935277413732, 52.236486966936162],
            [0.559942669437505, 52.234342929949918],
            [0.557010332210022, 52.233295937181836],
            [0.556190413207974, 52.232459410817221],
            [0.55491867740749, 52.232170419712176],
            [0.554048744450565, 52.231584055976406],
            [0.554013714134359, 52.231203529571651],
            [0.554957601580218, 52.230502354915572],
            [0.5563187622565, 52.230054714657292],
            [0.556634169265016, 52.228993950558419],
            [0.55818502438004, 52.228408187700772],
            [0.558158029920267, 52.227353044570386],
            [0.555763865076054, 52.226335718807391],
            [0.553921290585494, 52.225920597981649],
            [0.55507229841096, 52.224034211768021],
            [0.556798494794608, 52.22420903685326],
            [0.557598097777109, 52.222096432655981],
            [0.560354251600846, 52.221875678298666],
            [0.560391757563635, 52.221178837937018],
            [0.56294752509145, 52.221191682525358],
            [0.562958676523086, 52.220953136526006],
            [0.561280747434055, 52.220851993003812],
            [0.562130004624503, 52.218106097167393],
            [0.565902275518154, 52.21750972706969],
            [0.563804033182979, 52.215549099995762],
            [0.560498263932892, 52.213614654365678],
            [0.558848173974911, 52.210886136666296],
            [0.563609688561201, 52.202795509368144],
            [0.558178535977733, 52.20407347827765],
            [0.557907788412908, 52.206545122173821],
            [0.555142879615949, 52.207795669844046],
            [0.555505403000669, 52.208985629902273],
            [0.55482280973233, 52.20964068917867],
            [0.556443549247378, 52.210996728262501],
            [0.554225927369475, 52.212376572143114],
            [0.551306187314601, 52.213385779083318],
            [0.54718399110922, 52.2131088672446],
            [0.5445389848153, 52.213780145980863],
            [0.545091277183978, 52.21522053112686],
            [0.543060562306733, 52.216447749210637],
            [0.541896134791867, 52.216336159459992],
            [0.54105917232744, 52.215446842176924],
            [0.532595028373884, 52.216694484198889],
            [0.532259843897032, 52.215203521707416],
            [0.523554716930812, 52.21512209320089],
            [0.521428529870745, 52.214829440154617],
            [0.520969286198659, 52.215121620908768],
            [0.518952182339327, 52.214841879360414],
            [0.512537705206241, 52.215035331222964],
            [0.513029011239671, 52.225162119853131],
            [0.514454993157386, 52.226763874885442],
            [0.513199956662067, 52.228084637855396],
            [0.507330929584856, 52.229987330905743],
            [0.500861682527794, 52.235523719072212],
            [0.494902632808973, 52.23924236390156],
            [0.492723708005096, 52.237973720883829],
            [0.491725517599951, 52.238876065131223],
            [0.489992592157368, 52.237696869790625],
            [0.48935868876458, 52.23809512239599],
            [0.48977645378756, 52.238362375660941],
            [0.489298776339812, 52.238516333588194],
            [0.486087234027551, 52.236484310764865],
            [0.484134973018107, 52.23478269538748],
            [0.485671116184478, 52.23404443589672],
            [0.484066677266436, 52.232825579569031],
            [0.482974765084298, 52.232181324777812],
            [0.480308522727138, 52.232666345202233],
            [0.476099393567922, 52.233929167101934],
            [0.476585758849442, 52.234378472110784],
            [0.477892257518409, 52.234511106099482],
            [0.471828300229613, 52.240326904748599],
            [0.470754568154296, 52.240959078833534],
            [0.465083252859316, 52.240360104495288],
            [0.459603458483931, 52.24380344188917],
            [0.455532938974773, 52.247578691699424],
            [0.453308313434914, 52.247539536030494],
            [0.450393248719866, 52.243319737308717],
            [0.449248369815583, 52.243651045920039],
            [0.446027538414791, 52.243580272544357],
            [0.446234756994232, 52.242926728046342],
            [0.443376125320535, 52.242621765775496],
            [0.434167366881287, 52.249488006766448],
            [0.432244972205435, 52.25154642430865],
            [0.429731881325977, 52.251486689773294],
            [0.42693675933712, 52.253587262594074],
            [0.414489854590368, 52.247845614865618],
            [0.417332960041323, 52.246246125461191],
            [0.420903133328106, 52.242694704834406],
            [0.420648524298, 52.241946371439852],
            [0.421203363853311, 52.240890978892658],
            [0.421112062948394, 52.240236412397913],
            [0.420724804934421, 52.240172419131241],
            [0.421353589045471, 52.239964384230305],
            [0.421307114996285, 52.239675784924565],
            [0.415243984582695, 52.238103195705598],
            [0.41147965083903, 52.240101845698725],
            [0.408226699567808, 52.238809543298565],
            [0.402540933305959, 52.235399568604969],
            [0.394932094686643, 52.230664244010057],
            [0.384620453816975, 52.23399072296948],
            [0.380449592341318, 52.231641634269508],
            [0.371220872261898, 52.226540913709186],
            [0.36941656965077, 52.227470992894155],
            [0.370087156982449, 52.227799229957924],
            [0.369615797960413, 52.228187271712187],
            [0.36891306245459, 52.227777847892725],
            [0.357394866492098, 52.23414304894348],
            [0.342655969698815, 52.242328152082088],
            [0.346284685809836, 52.24361118205794],
            [0.35138221857583, 52.244686797513396],
            [0.356636005126853, 52.246273428714808],
            [0.355999028998169, 52.246804099113781],
            [0.360864774671598, 52.24940721801805],
            [0.360279274411961, 52.250217438654971],
            [0.35807063846345, 52.252496156154358],
            [0.35073676626461, 52.258329131922231],
            [0.342747018639006, 52.263460673614134],
            [0.344724536379019, 52.264553518992955],
            [0.339974755915008, 52.267672745685651],
            [0.344029266145977, 52.269792677644013],
            [0.342387997849396, 52.269938561613174],
            [0.349040817940691, 52.273676577184126],
            [0.355198430216817, 52.279306167521533],
            [0.355431953116323, 52.279839239775079],
            [0.352628777804952, 52.282301444010301],
            [0.350623408144971, 52.282944767374062],
            [0.353051120732617, 52.285069805330664],
            [0.359195560919047, 52.289142014537191],
            [0.358637074823655, 52.289587495591093],
            [0.358626586005198, 52.290218055708053],
            [0.360607837658934, 52.293556810742182],
            [0.35874416717606, 52.297342890314532],
            [0.36071023961958, 52.29779816250921],
            [0.368842851132275, 52.296504950655525],
            [0.37095202496511, 52.2948332297684],
            [0.376233205406912, 52.293546376101062],
            [0.377842892133829, 52.291236243666162],
            [0.378406815025003, 52.291798585645076],
            [0.378700768323554, 52.293507473974202],
            [0.380591095553144, 52.293534114665725],
            [0.380736372038452, 52.292988955802244],
            [0.382696491489025, 52.293250649908032],
            [0.38627507378729, 52.289954664242082],
            [0.385509667915622, 52.289628429656183],
            [0.385917280606839, 52.285862347887708],
            [0.385335851981046, 52.28298220240908],
            [0.393411479273299, 52.281156976858604],
            [0.393454530913911, 52.280840476653964],
            [0.39532711387939, 52.280439206374211],
            [0.394636234813608, 52.278883179830565],
            [0.403686805502383, 52.274479161436957],
            [0.409519627536164, 52.269019727163077],
            [0.416261930249154, 52.262604375431373],
            [0.424178394330476, 52.255856066802473],
            [0.424841130109638, 52.256400873921315],
            [0.425258777672222, 52.256240321585793],
            [0.432615915334266, 52.258083394826841],
            [0.453772857658745, 52.26367836223698],
            [0.459517515663569, 52.265630623353111],
            [0.494182793993891, 52.2719729475306],
            [0.495161871493768, 52.272336229070604],
            [0.495773302970884, 52.27327829557747],
            [0.497631167590078, 52.273236297179267],
            [0.497720523373848, 52.272896291402937],
            [0.498774763609686, 52.273005265742491],
            [0.498650758094461, 52.27395389329309],
            [0.49833914572243, 52.274095375973857],
            [0.498144647517502, 52.273686740964159],
            [0.498057219270769, 52.274243423445128],
            [0.496189155977129, 52.275278406067727],
            [0.497105008767446, 52.275741026464566],
            [0.495051941147029, 52.275780847574509],
            [0.495983589241982, 52.276863618921496],
            [0.496400907526777, 52.277115573052292],
            [0.496855389865837, 52.276933305443272],
            [0.496843934009511, 52.2776943055853],
            [0.498185002518736, 52.278182992567501],
            [0.496657618135457, 52.278682915400239],
            [0.496796187590706, 52.280234771305992],
            [0.495817772838612, 52.28035617869066],
            [0.496642125619995, 52.280545570342497],
            [0.496302384787105, 52.280787457709764],
            [0.496678182989008, 52.280978241554237],
            [0.497343424306089, 52.280710582671752],
            [0.498052722821174, 52.281304359350656],
            [0.497752623157144, 52.281495054462916],
            [0.498534235795663, 52.2817320980365],
            [0.497689058239422, 52.281876778275098],
            [0.500136624411263, 52.282823118760888],
            [0.500002240130356, 52.283352920137929],
            [0.498841024921189, 52.283770476708085],
            [0.49907666025121, 52.284596386281912],
            [0.501183238336584, 52.284848517214122],
            [0.504740823701116, 52.284699342455212],
            [0.504672658451973, 52.286349997292632],
            [0.501321520241834, 52.289280640964314],
            [0.496316852429716, 52.291162579776469],
            [0.48836898761319, 52.296063071161811],
            [0.485497892609197, 52.295262086017502],
            [0.485321699865099, 52.296332290144747],
            [0.485759355836933, 52.296787980831503],
            [0.485201853015033, 52.297073086335629],
            [0.485404164258758, 52.297516646957881],
            [0.48393769727849, 52.298228233302787],
            [0.482473886613451, 52.299696002308806],
            [0.481505591556791, 52.299718157970517],
            [0.480871129772925, 52.300018348496579],
            [0.48093843354399, 52.300435078565322],
            [0.479979486272016, 52.300546049389503],
            [0.480074299760754, 52.301008962195894],
            [0.479345577759439, 52.30106653242936],
            [0.479632349974447, 52.301289814479688],
            [0.479116891137887, 52.301830289084144],
            [0.47729995814041, 52.3019439644941],
            [0.476992734563464, 52.302195002490755],
            [0.477363517151095, 52.302425519689947],
            [0.476650940304251, 52.302352345272887],
            [0.476172369604109, 52.30276613989502],
            [0.476480946963298, 52.303143640456653],
            [0.474083001602707, 52.303966357990582],
            [0.473902460349178, 52.303596060042892],
            [0.473514820852414, 52.303852370635227],
            [0.473815807254353, 52.304225540854794],
            [0.472845601392677, 52.303925739003773],
            [0.47232707648435, 52.304123639101682],
            [0.472577727552779, 52.304409741583122],
            [0.471441487103192, 52.304420943133806],
            [0.47127821226752, 52.304202250323641],
            [0.470328266414273, 52.304819221476535],
            [0.471170293014867, 52.30484566245066],
            [0.471084799943667, 52.305496698110908],
            [0.469329385974616, 52.3070612222348],
            [0.46829030958584, 52.307369804978499],
            [0.467880750977581, 52.307180535444282],
            [0.467249137108605, 52.307693709181642],
            [0.466263500979521, 52.307908536159026],
            [0.464526769748594, 52.307807219130929],
            [0.462065815217372, 52.308798260055951],
            [0.461379877249935, 52.310977909624391],
            [0.46047079045496, 52.311648799709786],
            [0.460433974906992, 52.312519122634953],
            [0.459676220570802, 52.312903594979474],
            [0.4589861516222, 52.314297387107253],
            [0.458965840714286, 52.315465909523134],
            [0.4574732010853, 52.316279314482586],
            [0.454056214779485, 52.31647177727168],
            [0.453589478286985, 52.316814191397796],
            [0.452998749363279, 52.31658637101112],
            [0.452448359272221, 52.317193992282881],
            [0.450956024479608, 52.316609007119347],
            [0.449467728251442, 52.316654278366592],
            [0.447962439572381, 52.316896813949839],
            [0.44659605575217, 52.31760944569622],
            [0.445626916815507, 52.317650204616143],
            [0.442778607093674, 52.317140852848333],
            [0.441599159526173, 52.31719941777947],
            [0.44192403646747, 52.316810528285359],
            [0.441107814797994, 52.316456924147928],
            [0.438803898731905, 52.316799478499988],
            [0.431349911735962, 52.315809384909414],
            [0.430755211672812, 52.317112915745874],
            [0.429602088963381, 52.320035747315266],
            [0.429063583187122, 52.32389370550171],
            [0.424195296197234, 52.329200244494892],
            [0.430224956203547, 52.331345952333862],
            [0.43178813779563, 52.332549306138404],
            [0.435035624672747, 52.334029953523299],
            [0.436297728646963, 52.333517443565029],
            [0.436102579634563, 52.334587049073889],
            [0.436507329023245, 52.334953675419001],
            [0.435759124768616, 52.335736146862637],
            [0.436173630979082, 52.335879565766163],
            [0.437304127724202, 52.3351620396435],
            [0.437615830245316, 52.33527790072511],
            [0.436920488301095, 52.337101488276083],
            [0.437747258112999, 52.337667122219742],
            [0.437457047158248, 52.338636180194598],
            [0.438451665517919, 52.34001034362133],
            [0.438309009170518, 52.340539334411183],
            [0.4403533320973, 52.342071650605369],
            [0.440997995703758, 52.343433240729667],
            [0.440832718983568, 52.344244158782644],
            [0.441557156031191, 52.344734548586786],
            [0.44154733868954, 52.345888454591417],
            [0.442481921183897, 52.346914028392831],
            [0.442475152390821, 52.348815125363302],
            [0.440828443109704, 52.349096452667148],
            [0.437274570785786, 52.350584316254249],
            [0.435418823320529, 52.352166557327088],
            [0.432946567514269, 52.352205816363146],
            [0.431343883823524, 52.352730690724556],
            [0.429503691966087, 52.352835097331464],
            [0.427495959767896, 52.3536874722951],
            [0.425105472076836, 52.352083810745434],
            [0.422616003336309, 52.352345313804271],
            [0.42078240275425, 52.352006134441915],
            [0.420027491731732, 52.352138502104332],
            [0.419532751827068, 52.35254429351275],
            [0.419542547993497, 52.35363754225839],
            [0.420506681793215, 52.35582806750233],
            [0.418735707491841, 52.356691624679783],
            [0.419199318426598, 52.357919454179672],
            [0.415571467420076, 52.359773247520764],
            [0.409931306173671, 52.360174399223915],
            [0.407666368060599, 52.36149567214585],
            [0.407302831637663, 52.36307580820592],
            [0.400189018933364, 52.372933861548667],
            [0.389082102774042, 52.387357510350604],
            [0.384581906659362, 52.392234898422259],
            [0.382061736637633, 52.396256626410043],
            [0.374754090507972, 52.409711934784667],
            [0.417630278375853, 52.428112665108742],
            [0.425289889548333, 52.430309881778228],
            [0.423184314901331, 52.433948102298594],
            [0.425314081276867, 52.434125629582404],
            [0.42797657798445, 52.435054707152602],
            [0.42932078440588, 52.436409174829485],
            [0.430646144448344, 52.43733958826973],
            [0.431185429806149, 52.438395860038092],
            [0.433332285154425, 52.438905600871628],
            [0.436252006827783, 52.440301285381778],
            [0.439118018802959, 52.440721464584747],
            [0.440018599702105, 52.442133523533499],
            [0.439104909635309, 52.443467024398593],
            [0.440981404603991, 52.444776209345733],
            [0.44442551519798, 52.445531419751063],
            [0.445515415929153, 52.447354059627763],
            [0.44940477500954, 52.445811422349614],
            [0.449855743070806, 52.447652655392282],
            [0.451435959154792, 52.447752082667989],
            [0.452805810818643, 52.446396432334751],
            [0.45698431093703, 52.444951851609034],
            [0.458041594100419, 52.445571911025951],
            [0.459363300610278, 52.447239439331966],
            [0.466810111206009, 52.445926097911205],
            [0.46724261321978, 52.445265141530413],
            [0.469548019311035, 52.446122513981024],
            [0.473043225057055, 52.445887589381201],
            [0.475706024460822, 52.446712187810391],
            [0.478137088378233, 52.445557013870172],
            [0.480902028561349, 52.446888311531175],
            [0.485486195354666, 52.447723609803475],
            [0.493285621844108, 52.447922657953775],
            [0.496017972747959, 52.447261623407286],
            [0.498040273997872, 52.448123522982868],
            [0.499926639641577, 52.449883886325701],
            [0.500900725207466, 52.450217576705967],
            [0.503514666037074, 52.450011182036164],
            [0.507793834019062, 52.450567925153734],
            [0.51350775384648, 52.449231728906483],
            [0.51537835902651, 52.450368125543854],
            [0.516384238589233, 52.450508579146472],
            [0.520681337344689, 52.449339764569764],
            [0.523075767766208, 52.448205120440974],
            [0.523811787447383, 52.44818221566122],
            [0.526970338823753, 52.449908695144288],
            [0.528045393533402, 52.452694888798035],
            [0.530353775746908, 52.453576201748881],
            [0.531913271068245, 52.453307216045047],
            [0.533578201722832, 52.451593592101439],
            [0.537472876668179, 52.451162060244855],
            [0.54034768078836, 52.450324287128936],
            [0.542596559818519, 52.450298421036429],
            [0.544478684433155, 52.451595077578531],
            [0.547797184758466, 52.452019979592052],
            [0.549011019491534, 52.453260826308906],
            [0.549494940451949, 52.454554272349235],
            [0.554797236188839, 52.456009861812248],
            [0.559128360217757, 52.455841551150733],
            [0.563127648603539, 52.453458277167954],
            [0.563370185930242, 52.453655345417637],
            [0.565772924053312, 52.453310972242193],
            [0.567934309137199, 52.453448385520844],
            [0.572235509746703, 52.451963764978785],
            [0.577926902977417, 52.452513317941879],
            [0.582476981410336, 52.451842994871235],
            [0.586291728846426, 52.452189433660081],
            [0.586919729618147, 52.452507510747765],
            [0.586825770187669, 52.452913323412233],
            [0.588406785004898, 52.453518972956928],
            [0.591458218532868, 52.453972779659843],
            [0.59320913762829, 52.453610659965094],
            [0.595823569348381, 52.454266376154287],
            [0.597285824882827, 52.454201886679783],
            [0.598389628935765, 52.453974403143619],
            [0.599638393275008, 52.453209574507305],
            [0.600837126977001, 52.453294713364521],
            [0.601867895064306, 52.452927623373284],
            [0.609455161353882, 52.452697442535033],
            [0.60934489672209, 52.451977787187644],
            [0.608456215372927, 52.451609827389674],
            [0.609835920918573, 52.450300656856456],
            [0.614994579968906, 52.449058107616537],
            [0.615576248740533, 52.448889670247411],
            [0.61900758018566, 52.449520501774956],
            [0.621843223661123, 52.451246235742332],
            [0.621893665364395, 52.451674055337634],
            [0.619382184108579, 52.452707206426588],
            [0.620375806761246, 52.454418923052479],
            [0.622153326210038, 52.454334547549657],
            [0.622457513374228, 52.453682144563182],
            [0.6297741135478, 52.454046566046323],
            [0.62939442837846, 52.452565865330314],
            [0.628054930008054, 52.45115596367642],
            [0.627182765291043, 52.450845327840057],
            [0.628822904807272, 52.450063393095974],
            [0.633523411412811, 52.450351738260437],
            [0.636093208896401, 52.451361863626111],
            [0.638656835623808, 52.451011514225428],
            [0.642196176577926, 52.451028677616065],
            [0.642952178590018, 52.452297698901695],
            [0.645182638309617, 52.452576934465583],
            [0.64538855525097, 52.453605560256619],
            [0.646620734965076, 52.453534816514136],
            [0.647149006579663, 52.45289441508563],
            [0.649251875101115, 52.45445516003263],
            [0.649305271346002, 52.454854128092251],
            [0.648494312800514, 52.455346202290428],
            [0.649878549827578, 52.455649701759476],
            [0.65137525556024, 52.456942531866815],
            [0.654655551461037, 52.457367998540647],
            [0.656307348661912, 52.457248164217674],
            [0.656673865015268, 52.458225503480087],
            [0.658146154439448, 52.458741848694466],
            [0.658777267456683, 52.459492017139723],
            [0.660559895904621, 52.460124549863053],
            [0.663518099948113, 52.462110023347336],
            [0.666132012407242, 52.462487239605231],
            [0.668302994099469, 52.462064172700103],
            [0.671951230562814, 52.460291185035217],
            [0.672591514732622, 52.458928735758498],
            [0.67476067493635, 52.4576539983427],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000202",
        LAD13CDO: "42UD",
        LAD13NM: "Ipswich",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [1.144555793961689, 52.094329828977884],
            [1.144866564249528, 52.093973469491011],
            [1.149439147491074, 52.09371369002897],
            [1.14930509430842, 52.09286825235192],
            [1.151925562322268, 52.092405126495962],
            [1.151596533312186, 52.091963337207687],
            [1.153560164268666, 52.090966421935924],
            [1.153695377634841, 52.091206536043273],
            [1.154404164086639, 52.090925834003166],
            [1.157807811925241, 52.088737254407278],
            [1.159523074026827, 52.087969044371732],
            [1.158145762143494, 52.086990571629123],
            [1.158701896213621, 52.086782289318784],
            [1.159063768488726, 52.087171913188662],
            [1.159583166336999, 52.087084230949976],
            [1.164101957668182, 52.085869262743685],
            [1.16336972177928, 52.085053397129663],
            [1.163559936715155, 52.084213652644117],
            [1.162303643570814, 52.082757998400581],
            [1.16565869726729, 52.08232969510901],
            [1.165263850031906, 52.081866327781306],
            [1.165680837503204, 52.08167344299514],
            [1.164965286355805, 52.081051407586777],
            [1.165555496023944, 52.080995971980215],
            [1.164554517888577, 52.080404090447331],
            [1.164226753508848, 52.079485623593833],
            [1.164620820553324, 52.079422870637444],
            [1.165803097812578, 52.080616968117404],
            [1.170951482911202, 52.080798648256966],
            [1.171331163794889, 52.081232725474919],
            [1.189863397526666, 52.08043543738389],
            [1.189972802540871, 52.079309126285771],
            [1.188208801374219, 52.078706629244124],
            [1.186795689739446, 52.077271663229077],
            [1.192122768930132, 52.075114537938028],
            [1.190963648687056, 52.074089157820715],
            [1.196584580140016, 52.073151541543304],
            [1.196616261659956, 52.072002147839839],
            [1.197335191656586, 52.071136294853318],
            [1.200085160794517, 52.069488545730358],
            [1.199484633113809, 52.069152298320745],
            [1.200183749032177, 52.068520809194595],
            [1.200993137948474, 52.067737916923988],
            [1.201809760451419, 52.067484569583662],
            [1.202263858796045, 52.067936313006108],
            [1.20351958783994, 52.067375108679435],
            [1.206424314244792, 52.064004236510449],
            [1.205505848118255, 52.063649694942697],
            [1.206027158227929, 52.063003222175873],
            [1.205188505117855, 52.062194105595644],
            [1.204864894610714, 52.058410131341496],
            [1.206166352926791, 52.053726582134068],
            [1.205675029914871, 52.052203778088952],
            [1.203827464468442, 52.04946411601631],
            [1.207018199749267, 52.048675712012482],
            [1.209182313820366, 52.048462945592441],
            [1.205924856459659, 52.041190960753895],
            [1.205716514493656, 52.04068487243547],
            [1.204401401967778, 52.040813314027474],
            [1.204009624786752, 52.040270842959252],
            [1.203427050372607, 52.040465678048136],
            [1.202895462226083, 52.039905421930222],
            [1.203402253801464, 52.039691065793008],
            [1.202070531250293, 52.038710068195599],
            [1.203265822765281, 52.038069553317293],
            [1.201913510698292, 52.037391314555897],
            [1.204649603286321, 52.035824752131546],
            [1.212033754224817, 52.034166469610852],
            [1.223579992102786, 52.029420366874554],
            [1.219338399443284, 52.02286912021269],
            [1.218312021075258, 52.023782206499114],
            [1.215002227616121, 52.025606713719363],
            [1.211280287488696, 52.023150393269212],
            [1.212126903854819, 52.022676689666397],
            [1.209446647585979, 52.020754912698344],
            [1.204201112952036, 52.023442244267059],
            [1.201648093376035, 52.024316680912435],
            [1.195403525139619, 52.024518740034239],
            [1.193827668109724, 52.023703475615598],
            [1.191487271787858, 52.023104092688882],
            [1.190663273109246, 52.022486055163242],
            [1.190055235341672, 52.023016994067461],
            [1.186580159135585, 52.021979670562871],
            [1.184779358090173, 52.024312889701577],
            [1.183073600697423, 52.024457062249205],
            [1.18281844692088, 52.022692139214989],
            [1.180956852919873, 52.022591360915683],
            [1.178766068240614, 52.021508302915663],
            [1.173663528883716, 52.024285725897073],
            [1.16968967402873, 52.030329730152431],
            [1.166224131497776, 52.029820394126091],
            [1.165414210167636, 52.030594971120308],
            [1.16418923412868, 52.031088394787609],
            [1.160610946376684, 52.031605437217564],
            [1.159898059563493, 52.03185571953918],
            [1.157929747699116, 52.034609422140129],
            [1.157981829100901, 52.037002216287121],
            [1.158386087248488, 52.03822624170801],
            [1.160961267160768, 52.041836556172584],
            [1.163634722630058, 52.044994478463707],
            [1.162678855593619, 52.045421289702219],
            [1.163028481617538, 52.046034279206211],
            [1.16241152369573, 52.046200151900159],
            [1.16206789696243, 52.045919775839998],
            [1.161032791043236, 52.046195804188471],
            [1.160602474631043, 52.046815347643694],
            [1.160441252064173, 52.048911667918254],
            [1.157620570987909, 52.051028073094422],
            [1.154936846667337, 52.051986816161367],
            [1.153948347253621, 52.052119427083774],
            [1.153649996311979, 52.051736182500882],
            [1.149449133093707, 52.051371045899508],
            [1.145163210747239, 52.051413657512853],
            [1.143303591136301, 52.05193819496656],
            [1.141735805113295, 52.051799260410036],
            [1.141129478447563, 52.053386672029355],
            [1.138306205051997, 52.055004407628857],
            [1.138460792284396, 52.054254693467939],
            [1.137574252795767, 52.054219859723077],
            [1.136736731756371, 52.054592935570177],
            [1.135248109516711, 52.055876436726791],
            [1.135224639904027, 52.056790840503076],
            [1.134701899146581, 52.057544954506149],
            [1.134372402114506, 52.05747547890725],
            [1.134921677581598, 52.056652306441492],
            [1.13489519138473, 52.055753622996896],
            [1.13644445007916, 52.054377672901111],
            [1.13758442254676, 52.053969558484205],
            [1.139862737547995, 52.053983476146037],
            [1.141551717483774, 52.051566733106817],
            [1.143295763180223, 52.05163980618282],
            [1.14497013393925, 52.051115719936519],
            [1.149264960520584, 52.051034203039308],
            [1.155652548791569, 52.051606080678233],
            [1.158227706766058, 52.050184346816231],
            [1.159875811369144, 52.047968989018543],
            [1.160086936964915, 52.044306382664573],
            [1.157805504715118, 52.041677505375461],
            [1.156707299026266, 52.041471314587987],
            [1.155477232148703, 52.04001489178934],
            [1.157235448910909, 52.039157399384116],
            [1.156504389669077, 52.038510550569413],
            [1.154075072644828, 52.039033450643991],
            [1.153215482205119, 52.038880195161475],
            [1.151918690284584, 52.035830897680306],
            [1.150761621733929, 52.034599120745447],
            [1.150031855353344, 52.034465752546971],
            [1.150004273464162, 52.033990711061442],
            [1.146682207983768, 52.033863698630448],
            [1.146172007504964, 52.033928600042572],
            [1.144284293996663, 52.0332118509168],
            [1.141088766949031, 52.032808785554685],
            [1.139345215371721, 52.032066526385279],
            [1.136506627239688, 52.032115206591797],
            [1.136265260788122, 52.030917351717456],
            [1.134438578180293, 52.030068409535453],
            [1.134000697242707, 52.030437125861937],
            [1.131981307642735, 52.030313688225775],
            [1.131544729778418, 52.030576232393358],
            [1.130155348697751, 52.030433304780807],
            [1.12954640886053, 52.029940439421999],
            [1.128909087179553, 52.03012646558129],
            [1.129308188755184, 52.030725643945381],
            [1.128477558707866, 52.030879031548828],
            [1.128355046777506, 52.031379649829894],
            [1.127578866517776, 52.031561263841702],
            [1.126934682852448, 52.032213345022264],
            [1.126893373394702, 52.032853908162238],
            [1.125949944013512, 52.032708078555693],
            [1.126173542900212, 52.033592536556625],
            [1.125628143361417, 52.034177230713489],
            [1.124965385287359, 52.033895328307864],
            [1.122567056557527, 52.035491522875269],
            [1.11972447804315, 52.036155981781981],
            [1.117577148806005, 52.035308080500577],
            [1.116235842203799, 52.035417341471124],
            [1.112912856479068, 52.034563594368073],
            [1.112731595940869, 52.037599318294006],
            [1.111876271935764, 52.04042171664306],
            [1.11110457223298, 52.041888322466946],
            [1.108649491160031, 52.043604440393104],
            [1.107246358075596, 52.046504218137443],
            [1.114283075761243, 52.048582958398903],
            [1.113686100029475, 52.049332644806462],
            [1.112569204882057, 52.049591518219593],
            [1.1113302830881, 52.051641581313582],
            [1.110498911806966, 52.051643759930769],
            [1.110220045892636, 52.053487668110442],
            [1.113478742277948, 52.054215490733398],
            [1.116790132920144, 52.055907768678459],
            [1.121973562489192, 52.057410032990902],
            [1.120699121551819, 52.060077215668784],
            [1.121990980484372, 52.061507171698729],
            [1.11026323407421, 52.069430786901606],
            [1.108697257630017, 52.070689897481138],
            [1.109167595594709, 52.070989566478772],
            [1.109162018157225, 52.073512478752328],
            [1.110819682022019, 52.073318507596959],
            [1.110867666463732, 52.074176149596738],
            [1.111414236264143, 52.074689647967851],
            [1.110439935991239, 52.075308076449588],
            [1.111883670088186, 52.07638067390787],
            [1.111497485701626, 52.07733342908039],
            [1.110280086291274, 52.078507799475844],
            [1.10928580190692, 52.078378460015124],
            [1.110221373262645, 52.079384447666385],
            [1.109417291362615, 52.079639517991225],
            [1.108513889331822, 52.080607714703518],
            [1.109283480106389, 52.082018314729154],
            [1.109921222882037, 52.081784726336814],
            [1.110506718915446, 52.082101136481143],
            [1.110313072183164, 52.082653973256193],
            [1.111155670668493, 52.083098497598748],
            [1.11175345415159, 52.084828403679978],
            [1.111189366099967, 52.084862192306957],
            [1.111281154797025, 52.085593662180898],
            [1.108800717160255, 52.085614188791645],
            [1.109106021714052, 52.088051543970373],
            [1.109509679119985, 52.088376356869702],
            [1.11044682891879, 52.088394782904679],
            [1.110960398166697, 52.089207749884665],
            [1.112656486799438, 52.088781603538322],
            [1.113727463483765, 52.090050203403031],
            [1.115704668267129, 52.089216356949322],
            [1.12084493342151, 52.08738943466399],
            [1.123333270502129, 52.087605882624423],
            [1.129590287336552, 52.08730668679776],
            [1.129837800218599, 52.087588812240178],
            [1.132415667717191, 52.088019441223835],
            [1.133481959347987, 52.087859768536973],
            [1.138502811831379, 52.088378142445059],
            [1.138443495244168, 52.089113621838969],
            [1.139773396841868, 52.089256256623578],
            [1.139389752185422, 52.091485267700584],
            [1.14000163136983, 52.091777446938181],
            [1.13952164578959, 52.09367445444893],
            [1.140185858273881, 52.093692724235524],
            [1.140269517090173, 52.094052046948136],
            [1.141070235088038, 52.094267235514963],
            [1.142193207230493, 52.094185119687261],
            [1.142261825599638, 52.09445490376153],
            [1.144555793961689, 52.094329828977884],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000203",
        LAD13CDO: "42UE",
        LAD13NM: "Mid Suffolk",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [1.347072552007486, 52.40438955666302],
            [1.34983847706112, 52.403320900334414],
            [1.351466260424904, 52.403862928100047],
            [1.352947279471816, 52.403516894844785],
            [1.357380960109652, 52.404060096893396],
            [1.358984370308472, 52.4038930867491],
            [1.359468769908233, 52.403320780306927],
            [1.3609353507793, 52.403501207075216],
            [1.364215679837285, 52.398455695367332],
            [1.36476682430583, 52.398162977496746],
            [1.367363936767656, 52.398864241100362],
            [1.368387584021596, 52.397247599534154],
            [1.370895477028913, 52.395584094199904],
            [1.371503059590878, 52.39470242067469],
            [1.374828093019922, 52.394656014586069],
            [1.375888458621096, 52.3933323596832],
            [1.375700453801918, 52.389619583696636],
            [1.378365021639311, 52.388341774166975],
            [1.379690560066493, 52.386240600396036],
            [1.382571725167636, 52.384647095156978],
            [1.388489002315125, 52.380114396357413],
            [1.391399130093557, 52.378581894250836],
            [1.393145873466231, 52.378093696530016],
            [1.396475261796528, 52.375058687094977],
            [1.398685586132712, 52.373969750528175],
            [1.399961072450734, 52.372783575728441],
            [1.400391589419213, 52.371636115492628],
            [1.403716851622437, 52.369438363300048],
            [1.409478481287035, 52.363244218454184],
            [1.406987395090305, 52.360886807260378],
            [1.400384949294557, 52.357499286476063],
            [1.397696283200427, 52.355465771346758],
            [1.393758751749699, 52.355378466094962],
            [1.387679980176473, 52.354221932887945],
            [1.383792047186576, 52.352168554455126],
            [1.380078196677272, 52.348600852488005],
            [1.37740672171321, 52.347696958637492],
            [1.374644891465265, 52.347725580185738],
            [1.369474974481614, 52.346313688424374],
            [1.367383078074015, 52.344703178153509],
            [1.365212636321534, 52.342289000304561],
            [1.361947073719281, 52.341287474245078],
            [1.35988140935037, 52.339802905319011],
            [1.357374009009253, 52.337254275191988],
            [1.362130778939725, 52.334345994723613],
            [1.3608316815753, 52.333265926277257],
            [1.359598271117012, 52.330379758526306],
            [1.357978453691482, 52.323012920377764],
            [1.357445297984157, 52.322136773854595],
            [1.360344044929596, 52.320373272612827],
            [1.365028415392172, 52.318412190439076],
            [1.367292570202115, 52.317845735199633],
            [1.370986261494296, 52.313710907716377],
            [1.372989690851116, 52.313174272135143],
            [1.375004832622814, 52.313100465718072],
            [1.376993494948917, 52.3135652330512],
            [1.379276193681899, 52.313247150194101],
            [1.380516856533214, 52.312062160762963],
            [1.380215697748811, 52.311249620614724],
            [1.381955922827287, 52.310401076131711],
            [1.383990913048696, 52.310646738136498],
            [1.384239891870245, 52.309352531171371],
            [1.38225860985516, 52.305482481322379],
            [1.380693539754522, 52.303526128856873],
            [1.383269481136517, 52.300001228582367],
            [1.382235501170066, 52.299332024709678],
            [1.384109131930167, 52.294085955598703],
            [1.387124168182452, 52.291634861661514],
            [1.391238289216212, 52.29072968307117],
            [1.398032837263599, 52.287709993576811],
            [1.401614166493938, 52.285678421167397],
            [1.393827816490857, 52.28333752146925],
            [1.390758043003563, 52.28304533828905],
            [1.387975269362374, 52.281652036790518],
            [1.388663960465114, 52.281318358105374],
            [1.386685189095562, 52.280715906020355],
            [1.384678433270427, 52.280745617503307],
            [1.384146876077021, 52.280294068103949],
            [1.381469438938834, 52.279797015037005],
            [1.376279467589634, 52.275435980455264],
            [1.375971380947526, 52.276069894176096],
            [1.371936338991016, 52.27539651218693],
            [1.37104124950538, 52.276063379399382],
            [1.36719402245429, 52.277130236939456],
            [1.363005025096699, 52.277810967203528],
            [1.359648325907918, 52.278931045455089],
            [1.355448573780876, 52.278755567740617],
            [1.356200855060774, 52.277185376463144],
            [1.355669992458355, 52.27631185712147],
            [1.356304424629507, 52.275433068678637],
            [1.355625721870281, 52.274058284446824],
            [1.353516341333566, 52.2754233190253],
            [1.352718614873977, 52.276470426185973],
            [1.350418885721169, 52.27663739761433],
            [1.350542025278555, 52.278054972831484],
            [1.35000197006757, 52.279039877191956],
            [1.349337306318235, 52.279287200622875],
            [1.349327856263602, 52.279822619379694],
            [1.34611387624124, 52.280080215242187],
            [1.344400075179398, 52.282070641492645],
            [1.344924928901929, 52.283411174066003],
            [1.344977875716808, 52.28603955063425],
            [1.346998115324956, 52.288810003090077],
            [1.346523282376039, 52.290934389913083],
            [1.347642662416742, 52.292278732719232],
            [1.347264157812534, 52.293385853300087],
            [1.344229357527589, 52.294963133251443],
            [1.339896658239244, 52.296060818069854],
            [1.338410094225425, 52.296813421095813],
            [1.336653837918387, 52.297062767994476],
            [1.33610493734301, 52.297488418967859],
            [1.331708975009374, 52.298394213530827],
            [1.327559360430984, 52.29846004537243],
            [1.327606069282184, 52.29747927536296],
            [1.328270189303364, 52.296633090541334],
            [1.328231691897478, 52.294516974664909],
            [1.32896252001868, 52.294501753556496],
            [1.330045797458603, 52.29292870224451],
            [1.330370814018957, 52.290947129227263],
            [1.332578581149251, 52.290931558604584],
            [1.332655875207383, 52.289910346548623],
            [1.333861122031996, 52.287875113075998],
            [1.336454142563761, 52.287445645954911],
            [1.339470044986411, 52.287393596333025],
            [1.341237066268684, 52.284429478295365],
            [1.340615861877763, 52.280405120355162],
            [1.338966628510158, 52.276688677665526],
            [1.337441221455945, 52.276994470531832],
            [1.336830218583466, 52.276649292563732],
            [1.337718041996416, 52.274377449587419],
            [1.337281831469247, 52.274074997285041],
            [1.338219562412707, 52.272740721857019],
            [1.338137149112478, 52.271878720343629],
            [1.336999378273805, 52.270473653598081],
            [1.338397985761338, 52.270200233461573],
            [1.339736112030971, 52.265907240079059],
            [1.338502650964027, 52.26626954267639],
            [1.333668998712805, 52.265424092659551],
            [1.328916439582189, 52.265403614288097],
            [1.326421629773473, 52.264623154341365],
            [1.326138495673103, 52.263798286011777],
            [1.324591945322057, 52.263327424246995],
            [1.322177336651967, 52.260109018633088],
            [1.316692896820566, 52.258602158180395],
            [1.313911823281955, 52.258966338519429],
            [1.311047286494101, 52.258958642782225],
            [1.309403697365833, 52.259639749167746],
            [1.303523805977433, 52.259410636081959],
            [1.302056484065902, 52.259172007981554],
            [1.301480564187315, 52.258798687564671],
            [1.300986769320687, 52.258997784988445],
            [1.297269596871066, 52.256369368413573],
            [1.295249751204316, 52.256089439030411],
            [1.292433252074055, 52.254828882489512],
            [1.291821456096606, 52.253079533188163],
            [1.293337451305429, 52.250340804035844],
            [1.293485297250601, 52.24759079868835],
            [1.288521903365689, 52.24192275601326],
            [1.286578974013091, 52.2404740160922],
            [1.281990741005558, 52.239645728280458],
            [1.279766070315586, 52.238285665560241],
            [1.277367752574741, 52.238264198808075],
            [1.274059997347324, 52.237232725781524],
            [1.270432335631039, 52.235833185301551],
            [1.269702175336409, 52.235007996883446],
            [1.268094900382082, 52.234107281273126],
            [1.260232085182071, 52.231357806426836],
            [1.258049701854921, 52.230859595959075],
            [1.257808024908435, 52.230359912406087],
            [1.256289518349496, 52.230645589445594],
            [1.254132125095041, 52.230467701500636],
            [1.247421954183433, 52.228879969495104],
            [1.246227795556256, 52.226807366030265],
            [1.24884472262872, 52.225820611722874],
            [1.246052149000573, 52.22231432807488],
            [1.246051027309099, 52.221335817158618],
            [1.24502214979746, 52.220340624652614],
            [1.245369675100332, 52.21928326497936],
            [1.243514401810072, 52.217677628088786],
            [1.248317159878508, 52.215810481643544],
            [1.243496931179662, 52.214385423316941],
            [1.244305978356629, 52.21315167915747],
            [1.241591404316906, 52.212126381031332],
            [1.243954973860985, 52.210626839942599],
            [1.242705855262115, 52.208192359811882],
            [1.243008148352459, 52.207237880951411],
            [1.242654786402225, 52.205100741986527],
            [1.24165390425429, 52.204362879764382],
            [1.241954251181022, 52.201090712730746],
            [1.242233532825257, 52.200323042569707],
            [1.243022366674371, 52.200361605578834],
            [1.245653897873969, 52.200137189975699],
            [1.24707157510859, 52.200509176904077],
            [1.247717493489437, 52.20005337837366],
            [1.249221140231876, 52.200319540505902],
            [1.250645918554234, 52.199746021440866],
            [1.250558459279963, 52.199269952985119],
            [1.252569227705244, 52.198143310219315],
            [1.253904233995158, 52.19790500483191],
            [1.254522021891347, 52.198227025174091],
            [1.256700003361189, 52.198347658484508],
            [1.257210017692792, 52.197056420325467],
            [1.25692659708947, 52.19490299216649],
            [1.255154771289412, 52.194530111411638],
            [1.25542617812149, 52.191136375327666],
            [1.255127379853226, 52.189971808463973],
            [1.253985750356178, 52.190029409856095],
            [1.254142895325418, 52.188114745833296],
            [1.251226720540557, 52.186053741320229],
            [1.247472067963673, 52.184750579159093],
            [1.247590409351831, 52.183463876011317],
            [1.244202116520631, 52.181320373352712],
            [1.243226643766274, 52.179999917706979],
            [1.241446003395113, 52.175856801235945],
            [1.239812476733582, 52.170412682411431],
            [1.237104700487274, 52.169644346505407],
            [1.228750095051592, 52.170417646017953],
            [1.22696320803539, 52.169738970942234],
            [1.224073818933538, 52.169388132935374],
            [1.224035121555876, 52.168974570066105],
            [1.220853775688791, 52.169278304713508],
            [1.219782764681072, 52.167735425393595],
            [1.220487028655849, 52.167239504481373],
            [1.219280773619575, 52.166739117527136],
            [1.216035652133829, 52.166400499127761],
            [1.213935375234893, 52.166522546803748],
            [1.215012758960434, 52.164608932025445],
            [1.21539500973621, 52.161554953334097],
            [1.211691554691299, 52.16053259220746],
            [1.211216767477767, 52.160031976006849],
            [1.205173637778057, 52.158133274198192],
            [1.20221690164959, 52.15579338571542],
            [1.201042394716447, 52.155422365913708],
            [1.196637684909536, 52.154987899666708],
            [1.196601416671456, 52.148567227834945],
            [1.195451432159977, 52.147270015189477],
            [1.193943301037996, 52.14736036738578],
            [1.193650315381793, 52.146943795286766],
            [1.192154181688395, 52.144137759720131],
            [1.191372014767619, 52.13996868075904],
            [1.186989196781556, 52.139592636860016],
            [1.176671706015735, 52.136914045703115],
            [1.176748883152912, 52.135719375355897],
            [1.175322154690198, 52.132450779049748],
            [1.171736958173443, 52.13224244912859],
            [1.169438055424722, 52.131234038247136],
            [1.170456640859773, 52.129299931704871],
            [1.170097804932812, 52.129247529056627],
            [1.170172761730329, 52.127607726157756],
            [1.171040343138621, 52.12776605599565],
            [1.172421746660154, 52.127546293254049],
            [1.173276025502869, 52.128277875673476],
            [1.175604081917123, 52.129142422558168],
            [1.177921829928972, 52.127870251806115],
            [1.176601664252111, 52.126938108726513],
            [1.176449659665085, 52.125972664561623],
            [1.177766247820653, 52.122113853091626],
            [1.177668780277576, 52.120477792128078],
            [1.176577718938341, 52.119764318370336],
            [1.172239201922212, 52.118151689990036],
            [1.172307748845534, 52.117430332326357],
            [1.171194943450568, 52.117272319084798],
            [1.171860369793483, 52.115533872540908],
            [1.170338857940338, 52.114289599277093],
            [1.170540833753702, 52.113615917003045],
            [1.169061423312563, 52.11318983900027],
            [1.165482765212883, 52.113298643204544],
            [1.164141908689029, 52.108765768681963],
            [1.165057741334766, 52.106003424634281],
            [1.165194476040079, 52.103563303825418],
            [1.167199493496925, 52.099789532969247],
            [1.166390239301432, 52.098365069053784],
            [1.164603959905602, 52.097144932373446],
            [1.162652013345732, 52.094634995117481],
            [1.161478218452793, 52.094493823122775],
            [1.160262880045848, 52.093063129574894],
            [1.161202395981653, 52.092555845099369],
            [1.162731286448389, 52.092268369023351],
            [1.160479821009608, 52.091485174834396],
            [1.157807811925241, 52.088737254407278],
            [1.154404164086639, 52.090925834003166],
            [1.153695377634841, 52.091206536043273],
            [1.153560164268666, 52.090966421935924],
            [1.151596533312186, 52.091963337207687],
            [1.151925562322268, 52.092405126495962],
            [1.14930509430842, 52.09286825235192],
            [1.149439147491074, 52.09371369002897],
            [1.144866564249528, 52.093973469491011],
            [1.144555793961689, 52.094329828977884],
            [1.142261825599638, 52.09445490376153],
            [1.142193207230493, 52.094185119687261],
            [1.141070235088038, 52.094267235514963],
            [1.140269517090173, 52.094052046948136],
            [1.140185858273881, 52.093692724235524],
            [1.13952164578959, 52.09367445444893],
            [1.14000163136983, 52.091777446938181],
            [1.139389752185422, 52.091485267700584],
            [1.139773396841868, 52.089256256623578],
            [1.138443495244168, 52.089113621838969],
            [1.138502811831379, 52.088378142445059],
            [1.133481959347987, 52.087859768536973],
            [1.132415667717191, 52.088019441223835],
            [1.129837800218599, 52.087588812240178],
            [1.129590287336552, 52.08730668679776],
            [1.123333270502129, 52.087605882624423],
            [1.12084493342151, 52.08738943466399],
            [1.115704668267129, 52.089216356949322],
            [1.113727463483765, 52.090050203403031],
            [1.112656486799438, 52.088781603538322],
            [1.110960398166697, 52.089207749884665],
            [1.11044682891879, 52.088394782904679],
            [1.109509679119985, 52.088376356869702],
            [1.109106021714052, 52.088051543970373],
            [1.108800717160255, 52.085614188791645],
            [1.111281154797025, 52.085593662180898],
            [1.111189366099967, 52.084862192306957],
            [1.11175345415159, 52.084828403679978],
            [1.111155670668493, 52.083098497598748],
            [1.110313072183164, 52.082653973256193],
            [1.110506718915446, 52.082101136481143],
            [1.109921222882037, 52.081784726336814],
            [1.109283480106389, 52.082018314729154],
            [1.108513889331822, 52.080607714703518],
            [1.109417291362615, 52.079639517991225],
            [1.110221373262645, 52.079384447666385],
            [1.10928580190692, 52.078378460015124],
            [1.110280086291274, 52.078507799475844],
            [1.111497485701626, 52.07733342908039],
            [1.111883670088186, 52.07638067390787],
            [1.110439935991239, 52.075308076449588],
            [1.111414236264143, 52.074689647967851],
            [1.110867666463732, 52.074176149596738],
            [1.110819682022019, 52.073318507596959],
            [1.109162018157225, 52.073512478752328],
            [1.109167595594709, 52.070989566478772],
            [1.107615428620902, 52.071692479383628],
            [1.107501191629913, 52.072085825181773],
            [1.105724733280572, 52.071766638174658],
            [1.104793993289443, 52.070700230649756],
            [1.103336777980234, 52.070143249378859],
            [1.100091539391346, 52.069539796362392],
            [1.097587775924817, 52.068683814832831],
            [1.096535630375283, 52.067187936032838],
            [1.096523234002605, 52.066502934861731],
            [1.091202771975001, 52.065385381349721],
            [1.086815866672748, 52.06576037284561],
            [1.081771955741476, 52.065792644300679],
            [1.069625658961373, 52.065142146080312],
            [1.064833292525328, 52.065373091148288],
            [1.058159819869957, 52.066520597868582],
            [1.057585644670493, 52.068496136077606],
            [1.053360475505708, 52.069987189065913],
            [1.053294042626941, 52.071622167467602],
            [1.05202688944845, 52.074397189840184],
            [1.051320218693357, 52.075035165730014],
            [1.04902928970954, 52.075300451660681],
            [1.049032627791911, 52.076162859791197],
            [1.047843250565048, 52.077621841254235],
            [1.046427624389735, 52.078096457869272],
            [1.045415628643318, 52.077896887928411],
            [1.0450887477719, 52.0760976104797],
            [1.043511656384491, 52.075207529396629],
            [1.041433217459214, 52.074563311598972],
            [1.041893064418379, 52.072247256288762],
            [1.040860480115298, 52.070969838744546],
            [1.041475042396992, 52.069727231756112],
            [1.040990014618453, 52.069505021736518],
            [1.041405956090388, 52.068603906749772],
            [1.035952478520658, 52.06834709550143],
            [1.032862692315322, 52.069353052188454],
            [1.028909882270334, 52.069836109445525],
            [1.025991195300098, 52.070872557554154],
            [1.026575025115141, 52.071693065323473],
            [1.02644736382864, 52.072825947635209],
            [1.027878934362186, 52.074246122969484],
            [1.027309886183242, 52.074615100539724],
            [1.028175729554945, 52.076732430011965],
            [1.027436484095185, 52.077929600979772],
            [1.0204710235727, 52.08356846241076],
            [1.019773624804145, 52.08526005618355],
            [1.019085045035011, 52.08567792317438],
            [1.020272653638234, 52.086855320142178],
            [1.016729289050476, 52.089622619262272],
            [1.010785721689416, 52.091619270332451],
            [1.006579454781868, 52.093511030175584],
            [1.007074649850467, 52.093774493250152],
            [1.005973160185551, 52.096154426461894],
            [1.003777458005422, 52.098594629597969],
            [1.002741396690751, 52.098714575557494],
            [0.996787185267731, 52.098097243229553],
            [0.994300498092573, 52.097075852496424],
            [0.992755850280234, 52.095968426189764],
            [0.990778803220942, 52.096667970024768],
            [0.987797433531458, 52.096805678116439],
            [0.985690081224685, 52.097239533320213],
            [0.984405116652859, 52.097109310049582],
            [0.979751586724388, 52.095230545985807],
            [0.979843409922136, 52.096498108934298],
            [0.977465025320071, 52.096875716968071],
            [0.976352977188479, 52.096841762960167],
            [0.972975516010325, 52.095350484010389],
            [0.970747115094135, 52.095655820510324],
            [0.973039688464411, 52.09765390102536],
            [0.974765012636845, 52.099924377083987],
            [0.97329842753497, 52.100730345274386],
            [0.975331265193352, 52.101410194736452],
            [0.974100254148746, 52.103912693074463],
            [0.973477736668619, 52.103819585092666],
            [0.972801190998001, 52.10488979742194],
            [0.972206568924061, 52.104777092379997],
            [0.971955106149734, 52.105058637065056],
            [0.973642039572911, 52.10527868087599],
            [0.973088853303484, 52.10690787449051],
            [0.970021846486959, 52.106537354677485],
            [0.966188924592511, 52.104529440885422],
            [0.965208416587101, 52.105423792920789],
            [0.96406873100463, 52.10504236866548],
            [0.963627392086583, 52.105258522755214],
            [0.962048211367635, 52.104504112269929],
            [0.959383637442734, 52.104803105637821],
            [0.956560681013996, 52.106063817194944],
            [0.957295367551916, 52.106351156046919],
            [0.95711761105731, 52.10651210407871],
            [0.953289286552713, 52.108519239918621],
            [0.951515301231446, 52.110562938219744],
            [0.952395114670448, 52.111321526557731],
            [0.95174692869474, 52.112741644574164],
            [0.951221410333494, 52.112658578934983],
            [0.951156000680885, 52.114697232951478],
            [0.950536884866845, 52.11960966707845],
            [0.953623540192215, 52.120266200416268],
            [0.951244197104921, 52.123790090288487],
            [0.949293569741023, 52.125205030686324],
            [0.946558674748617, 52.125941660895379],
            [0.944703306299423, 52.127630238398687],
            [0.945310555584224, 52.128816582973208],
            [0.944777756808112, 52.129467531730512],
            [0.947605170141882, 52.131035444038375],
            [0.94688674134211, 52.131464411078291],
            [0.946332549841888, 52.134669131972508],
            [0.94716988520199, 52.135442313012298],
            [0.945628363111791, 52.13615625881787],
            [0.94645000393356, 52.137089919984049],
            [0.948503637120179, 52.137291272547301],
            [0.948761305207621, 52.138027681679922],
            [0.947125722881914, 52.140903317188233],
            [0.946695595234848, 52.142557167732953],
            [0.945348238578005, 52.14254048440899],
            [0.945383500773735, 52.143473118283701],
            [0.944001543502992, 52.144638117147281],
            [0.946518878151847, 52.145480844629923],
            [0.946140796287427, 52.146197177504057],
            [0.944535254445667, 52.147320105289168],
            [0.944147618119346, 52.150062881190088],
            [0.942923650291158, 52.151099808816504],
            [0.938383918073871, 52.152450560913415],
            [0.939417022428119, 52.15337360281417],
            [0.941643987524122, 52.153709257097397],
            [0.939912381500403, 52.15538745644983],
            [0.938017886940348, 52.157524753472956],
            [0.936508122489307, 52.157047055416065],
            [0.936804572261705, 52.15646409402315],
            [0.934890079805255, 52.155834578352383],
            [0.933348457610531, 52.155893638279572],
            [0.933233942156338, 52.156508936081771],
            [0.932300565732681, 52.15623447926707],
            [0.930704629316303, 52.154354988087704],
            [0.929840553033579, 52.153977164235442],
            [0.928763059071627, 52.154022828385266],
            [0.927245365578676, 52.153019998611327],
            [0.92257523652428, 52.153751932371378],
            [0.921875601548141, 52.155280159280395],
            [0.918713750246239, 52.155961948011381],
            [0.91816296629733, 52.155611355107105],
            [0.917183129266082, 52.155563662362241],
            [0.913023897628169, 52.153468556476362],
            [0.911711875003032, 52.153639471617495],
            [0.907478697250711, 52.153004716173591],
            [0.905608887031538, 52.152334056829076],
            [0.897346336248361, 52.150434815395407],
            [0.892603839234305, 52.150437082159677],
            [0.893850415513177, 52.157662267532039],
            [0.888743797895749, 52.155621944045635],
            [0.883472974549221, 52.155014450218246],
            [0.88294821574904, 52.155446372078515],
            [0.882101145270998, 52.154646900993988],
            [0.881547986996707, 52.154684706714306],
            [0.878712454446312, 52.157576074003458],
            [0.877472901302926, 52.159304264809755],
            [0.877756193557621, 52.159411563427227],
            [0.873989950145207, 52.160100599360845],
            [0.872288814126485, 52.163474955427958],
            [0.869857538790288, 52.164530619451874],
            [0.868790472490904, 52.16556113530136],
            [0.863507387287104, 52.164002455193952],
            [0.861889635312108, 52.163815111600179],
            [0.859408429431358, 52.165920377372274],
            [0.857625895988284, 52.165444692386878],
            [0.859503941495642, 52.163373882034655],
            [0.856263896052513, 52.162835497200959],
            [0.853650824920935, 52.161947301127832],
            [0.846933177435887, 52.161746402014515],
            [0.843293933033847, 52.161249678427112],
            [0.837974468162798, 52.161807735277442],
            [0.832879436890131, 52.16196445835368],
            [0.829738805840628, 52.162708979516545],
            [0.825546960019048, 52.162651247140381],
            [0.820708373449508, 52.164367000110559],
            [0.816569503067994, 52.168205299008569],
            [0.81511413372531, 52.169106097577206],
            [0.814354622659164, 52.168913792188519],
            [0.813689105034939, 52.170418937293533],
            [0.814137910048869, 52.170716689017901],
            [0.817210496246095, 52.172353797884554],
            [0.822462632788002, 52.174543008420088],
            [0.824540359888853, 52.17969937057002],
            [0.826965484159883, 52.179301308338495],
            [0.829829926258584, 52.179233492092791],
            [0.830091587845924, 52.180266817845698],
            [0.836241890307373, 52.182153278483803],
            [0.833318042813784, 52.184671507367462],
            [0.832697103512157, 52.18682499352812],
            [0.833313496384179, 52.186808385037004],
            [0.834647628328334, 52.187976902591224],
            [0.835919385463379, 52.189957187058894],
            [0.83573571658381, 52.191898724861922],
            [0.836293374384163, 52.192015712973379],
            [0.836009839914818, 52.19266464214455],
            [0.838015053434972, 52.194554418006106],
            [0.838097818045803, 52.195324936044692],
            [0.83719857050291, 52.195199088439516],
            [0.837194712313959, 52.195666823994024],
            [0.835327623459483, 52.195808857699454],
            [0.836661363174549, 52.196782212754499],
            [0.83593109022984, 52.197218857234446],
            [0.836314784941961, 52.198279810904801],
            [0.832524853992963, 52.198634404666265],
            [0.832606810511825, 52.197599126859103],
            [0.83200622777215, 52.197613551882988],
            [0.831875794896438, 52.197127458710597],
            [0.830051863008266, 52.19682141385281],
            [0.829744671959667, 52.197307219464925],
            [0.832074635783477, 52.197676659429575],
            [0.831883361257378, 52.198949282313507],
            [0.830225529841997, 52.19902415574753],
            [0.829252113818036, 52.19761671018928],
            [0.827562608148802, 52.19859791245883],
            [0.826385227810725, 52.200200830113708],
            [0.825321182331432, 52.199439431465471],
            [0.824469605348079, 52.199650484730789],
            [0.821323971771338, 52.198973962216691],
            [0.819384578928605, 52.198970883795852],
            [0.819888157730473, 52.200151326883798],
            [0.818778096134975, 52.202013354914563],
            [0.8151237295017, 52.203893866420898],
            [0.814177185398513, 52.207027161664477],
            [0.81709442173541, 52.213269751695314],
            [0.819775102559685, 52.221311145330148],
            [0.820604493419638, 52.222752687626915],
            [0.821943536062166, 52.223247652575289],
            [0.820809503726255, 52.223584140016754],
            [0.822361296829161, 52.226047091038737],
            [0.822743854766708, 52.227898601773823],
            [0.820883987598609, 52.229354105717313],
            [0.821825002218404, 52.229966508137686],
            [0.819921119220507, 52.233362849839665],
            [0.824132039982045, 52.238851295610303],
            [0.827098870221794, 52.238951100563263],
            [0.82494309690523, 52.240319327333722],
            [0.821367163213651, 52.241518243245302],
            [0.816892118439695, 52.241408450687707],
            [0.813559050462771, 52.243787507668294],
            [0.813395922278728, 52.245978501889326],
            [0.806628507181208, 52.24644005806649],
            [0.807740075935515, 52.249378604805706],
            [0.806405858354663, 52.249851906221352],
            [0.803619888574783, 52.250237394771013],
            [0.801259155400191, 52.251047082961044],
            [0.800096873387752, 52.251111544658031],
            [0.797547023471792, 52.251386066263969],
            [0.798194855374828, 52.256182850290607],
            [0.802155444571088, 52.258127565295609],
            [0.798963677102863, 52.262339195324927],
            [0.800801627126548, 52.263641831855914],
            [0.799411209269019, 52.264696429360505],
            [0.801573806461671, 52.265892429312366],
            [0.803737553947527, 52.265966938336042],
            [0.809386623180257, 52.267034039762095],
            [0.812959882083389, 52.265084662244533],
            [0.817680764814254, 52.264364996478015],
            [0.820678621406536, 52.266473277761172],
            [0.822949676686955, 52.266251697046144],
            [0.82579575963621, 52.266498329050215],
            [0.833029423459287, 52.264798755964435],
            [0.836212873864061, 52.263121516342537],
            [0.836900796309722, 52.261959263914044],
            [0.842300866905653, 52.260000115438693],
            [0.843834624170235, 52.259783296525939],
            [0.845926613947229, 52.261760792859704],
            [0.845262334379976, 52.262830800903906],
            [0.842471220852076, 52.263968247230416],
            [0.840169226880335, 52.266316009628639],
            [0.842578834611221, 52.26800893362131],
            [0.849695826366269, 52.264816496181311],
            [0.852028638422066, 52.265468787129088],
            [0.85352937542743, 52.267496414914937],
            [0.856296455104506, 52.268424999653639],
            [0.855549116290404, 52.269591504074036],
            [0.852763531210327, 52.271707504017172],
            [0.854409244518765, 52.275142629305975],
            [0.853857468010083, 52.27520273891141],
            [0.854181665884202, 52.276209316197736],
            [0.853652385920239, 52.277053076054877],
            [0.850434935246609, 52.278411448765105],
            [0.849071278495881, 52.280077518255304],
            [0.849015058317938, 52.281943135476666],
            [0.848399363034238, 52.282333907334767],
            [0.845954611927179, 52.282693257200201],
            [0.843867567959929, 52.284425267492509],
            [0.842868616051787, 52.28476319247499],
            [0.849757187170584, 52.285326388999387],
            [0.852669121541169, 52.286165235555941],
            [0.855526181153909, 52.286331758761001],
            [0.855231414188371, 52.291528783370403],
            [0.859633234504391, 52.291946506139261],
            [0.863595097291942, 52.293249766469977],
            [0.868972552747664, 52.293441122820219],
            [0.868656080906416, 52.295737553295382],
            [0.866763828154294, 52.297846540259968],
            [0.874047917874967, 52.299474304489287],
            [0.874298085353721, 52.298264038810458],
            [0.876858810854403, 52.297534363202892],
            [0.87695814137963, 52.295808865897783],
            [0.879991851044613, 52.295996576773263],
            [0.880308336935892, 52.295290989182618],
            [0.879568809251299, 52.29338810878243],
            [0.882786874657608, 52.29311979421233],
            [0.883753951983928, 52.292767020743291],
            [0.887325395138213, 52.2927012847302],
            [0.886843543920294, 52.295890338760614],
            [0.885603193447371, 52.296078949551045],
            [0.885695365090338, 52.297405877319534],
            [0.884617979563005, 52.297394443023201],
            [0.883594098551264, 52.298251324679839],
            [0.882834626884284, 52.298166456685173],
            [0.882904299478472, 52.300118955400833],
            [0.889655827595439, 52.299703845036589],
            [0.892853517626983, 52.300084162491864],
            [0.895903437409502, 52.299975196254657],
            [0.898281972471861, 52.299693783618231],
            [0.89952862703736, 52.298950008835156],
            [0.9002403859051, 52.298955003957758],
            [0.902808388758411, 52.300763345212253],
            [0.903318740337968, 52.301792197816944],
            [0.903129215709009, 52.303827509669262],
            [0.908887716648054, 52.301574140869604],
            [0.911646980557391, 52.302042096971448],
            [0.911304512226366, 52.303462469667366],
            [0.908982700405405, 52.3044541035894],
            [0.908153780516995, 52.303469096424287],
            [0.904546028710119, 52.305569696750126],
            [0.908699736214806, 52.306112218346229],
            [0.916782609504593, 52.308083703803476],
            [0.919440888424449, 52.309394837961158],
            [0.918514112311092, 52.311319809505569],
            [0.925105072864951, 52.311718736498158],
            [0.931093138135471, 52.312850823774696],
            [0.930095980065606, 52.314303697068034],
            [0.927510366871902, 52.316413796739127],
            [0.925909456008898, 52.319261154757697],
            [0.926778500016327, 52.320065183924399],
            [0.926183088126685, 52.321405536617526],
            [0.926088795850027, 52.323334208701624],
            [0.925109041371273, 52.325568107659471],
            [0.925661889794174, 52.327317066266843],
            [0.926821404773898, 52.328316243047048],
            [0.927751331853508, 52.328305775373479],
            [0.928478206336985, 52.327932517233769],
            [0.931401343523142, 52.330383451734718],
            [0.932240926817401, 52.331979572439899],
            [0.932191930497431, 52.332925071219172],
            [0.933161155545808, 52.334127662632483],
            [0.932667000503207, 52.335711043298531],
            [0.936085604655616, 52.335303222452374],
            [0.937910426161584, 52.334683163924424],
            [0.940548362492654, 52.33549882236256],
            [0.946590394774755, 52.336438155791555],
            [0.953200427399547, 52.336842269674804],
            [0.952857878292826, 52.337121532795408],
            [0.955050833018836, 52.338126963831847],
            [0.955800891890726, 52.339312378012849],
            [0.957442840184159, 52.340506926581604],
            [0.955675590219308, 52.341747276066926],
            [0.956729109027041, 52.345475558521514],
            [0.957673962512877, 52.345771152738962],
            [0.958037195559689, 52.347559793605107],
            [0.957396972323262, 52.347956242945244],
            [0.957954554728452, 52.35076344119021],
            [0.956957967206763, 52.350860347658575],
            [0.956149940973026, 52.351493915078329],
            [0.956020092598255, 52.354388475205511],
            [0.955069003050338, 52.355608373579173],
            [0.953141557303574, 52.356042399509541],
            [0.953797823763271, 52.357116857844929],
            [0.959483162298171, 52.361833526666487],
            [0.964591753475234, 52.364040921473112],
            [0.967223479518422, 52.365715908344733],
            [0.967189358278468, 52.366762674182503],
            [0.966626425718879, 52.36710956479299],
            [0.966565769040824, 52.367738813290423],
            [0.966620365426015, 52.368728491486266],
            [0.967217842067851, 52.36880880920021],
            [0.967442116446307, 52.370093697052617],
            [0.977957018425529, 52.369998096040099],
            [0.980290461616373, 52.369500364121407],
            [0.987107921058166, 52.371505384246909],
            [0.988372087100034, 52.371535456040803],
            [0.990888945565986, 52.370795451405371],
            [0.997783498731298, 52.371520867030966],
            [0.998634225425648, 52.371737590594535],
            [1.00343886898557, 52.374572483198349],
            [1.006785108646918, 52.375723911814767],
            [1.014173012561998, 52.375673188133618],
            [1.021436235319976, 52.378013810842418],
            [1.027656970392195, 52.37855328260116],
            [1.029069144037313, 52.378573730252178],
            [1.029078248097589, 52.378256032893809],
            [1.02994204650093, 52.378230278044292],
            [1.029928311941614, 52.378716269265709],
            [1.033975698892821, 52.378355156240985],
            [1.035236684125681, 52.379020637771319],
            [1.037061613247696, 52.379080744155502],
            [1.037693763514368, 52.377308089463725],
            [1.044138353697045, 52.377788773766099],
            [1.048235901191166, 52.377667797535935],
            [1.048734777627544, 52.378019151136591],
            [1.050114402465225, 52.378164295287483],
            [1.051286755535093, 52.378095340715376],
            [1.053205576744316, 52.377280415151965],
            [1.056158562768356, 52.377014275875098],
            [1.059011301081686, 52.377232703880559],
            [1.062142838478072, 52.377338612149451],
            [1.064619939409029, 52.377946165001013],
            [1.065187626090961, 52.378631118169039],
            [1.068463388534002, 52.378441730550868],
            [1.069493281624593, 52.377802517333961],
            [1.070477982556948, 52.377719362498695],
            [1.069685495254095, 52.376718313622291],
            [1.073038076038328, 52.375627459790948],
            [1.075392837020417, 52.373121752669],
            [1.079154672432175, 52.372364509730623],
            [1.080652993698087, 52.372653693237034],
            [1.082454373196034, 52.373074346120944],
            [1.096318469298083, 52.371798817111213],
            [1.100512153093616, 52.371423478084459],
            [1.102137798484359, 52.370718865226202],
            [1.104655860839939, 52.371237266814539],
            [1.111431500928158, 52.371662400153433],
            [1.113628402731262, 52.371101759201402],
            [1.115029041937029, 52.371336424412718],
            [1.116190133375565, 52.371027891469581],
            [1.117301290741014, 52.369698103101129],
            [1.118354005657604, 52.369478746786392],
            [1.119424259639336, 52.369716687525688],
            [1.120843979615528, 52.369367999607057],
            [1.122909021805676, 52.367275473419483],
            [1.127805574990347, 52.367125190251016],
            [1.130305516255719, 52.367718171798799],
            [1.131435875313784, 52.367600964249561],
            [1.13184453884709, 52.367479505733819],
            [1.132661219195331, 52.367808591025373],
            [1.133917928270008, 52.367131306985399],
            [1.135929222310751, 52.366943906395441],
            [1.136793872171547, 52.367244708713194],
            [1.139092381106636, 52.367150351959964],
            [1.141072796023411, 52.366838673750941],
            [1.141520386179721, 52.366520985471773],
            [1.143575481393031, 52.366413230625511],
            [1.144616834600984, 52.364982505625058],
            [1.146083274474671, 52.365047768954668],
            [1.14658135103338, 52.36453175627593],
            [1.147657173618306, 52.364785481326976],
            [1.150474913244993, 52.364578129270896],
            [1.150834226217955, 52.363475820813576],
            [1.155431919210677, 52.361530941145269],
            [1.15702737730633, 52.361285038096732],
            [1.160943198315955, 52.361441088790571],
            [1.161334414031233, 52.362167192088776],
            [1.163030747895076, 52.362140649217125],
            [1.167546296192344, 52.360227169064913],
            [1.170838092157614, 52.35989598591221],
            [1.172150804643815, 52.36042102950001],
            [1.172831763971276, 52.360329884377748],
            [1.173263473912846, 52.35987669489753],
            [1.174174074137625, 52.360468283130551],
            [1.174791667008817, 52.360026279201151],
            [1.175409580662301, 52.360039341835176],
            [1.177273195693117, 52.360973112925592],
            [1.179481700878314, 52.360347062457613],
            [1.180442870605749, 52.35949465866431],
            [1.182206540264629, 52.359343711034391],
            [1.182348434204592, 52.358763392808989],
            [1.186988750361211, 52.35982269794124],
            [1.18785115227804, 52.360925424852155],
            [1.189042266849679, 52.361023668078928],
            [1.190176782308697, 52.361783564620218],
            [1.191358385972211, 52.361729149140665],
            [1.19289397486135, 52.361042786929936],
            [1.195312289716157, 52.361433346360798],
            [1.19590688709038, 52.360695959555486],
            [1.196828219405941, 52.360577483301057],
            [1.20014636864911, 52.35980047738505],
            [1.200884303165209, 52.358730908867017],
            [1.201755009818876, 52.359558105225062],
            [1.20257699554904, 52.359731875010276],
            [1.206020293990059, 52.357338733606653],
            [1.208295068363989, 52.35706738805635],
            [1.212612158875003, 52.355367100559789],
            [1.214050488866789, 52.3553918093425],
            [1.214944671702874, 52.355730810806328],
            [1.216285307679452, 52.357341950732923],
            [1.216984669377143, 52.357404740136211],
            [1.217963337863959, 52.357005728919091],
            [1.219781978172569, 52.357492174006403],
            [1.220650902444423, 52.358027885654984],
            [1.220295509077746, 52.358453090872288],
            [1.220851911927945, 52.359094457370155],
            [1.222036496068282, 52.358456856718291],
            [1.222980814751395, 52.359522924403457],
            [1.224300644566248, 52.359995935383068],
            [1.225136685875313, 52.359897553595097],
            [1.226027555841172, 52.360450614764197],
            [1.227954986987021, 52.360008505193335],
            [1.230311358479825, 52.360230960232215],
            [1.231779142850694, 52.36170264612219],
            [1.233133482150318, 52.361921890213971],
            [1.232989168793781, 52.364933359546988],
            [1.234421688620096, 52.364833868359732],
            [1.236634239294952, 52.365375821428195],
            [1.238327431981809, 52.364883303678731],
            [1.240349252765932, 52.365789270591655],
            [1.242414677994478, 52.366005981233762],
            [1.243371517493176, 52.365730573505417],
            [1.244443366390984, 52.366041990702016],
            [1.245407540137482, 52.365305882289277],
            [1.246834971133197, 52.365437520705434],
            [1.247328621900939, 52.365817873517024],
            [1.247165441669268, 52.366317020429292],
            [1.248305341860253, 52.365793705132951],
            [1.249988354120835, 52.366464198236727],
            [1.252079313195867, 52.368366377340919],
            [1.253011730978268, 52.370036925604509],
            [1.253860864386495, 52.370534270778812],
            [1.255784420930043, 52.37101548038315],
            [1.258314886685747, 52.370539159406164],
            [1.259302893612772, 52.370866248128898],
            [1.262131419490785, 52.372758686332247],
            [1.262831792961989, 52.374324947553653],
            [1.264020754192273, 52.374525927624056],
            [1.264335285524459, 52.37502448500819],
            [1.265552726609856, 52.375551138771286],
            [1.266505878914223, 52.376896335500199],
            [1.267572602264308, 52.37743073583907],
            [1.268193283781171, 52.378193324706778],
            [1.268170110012027, 52.379371259321957],
            [1.269755011287278, 52.380214177240454],
            [1.2708036805288, 52.380025944556735],
            [1.271476082718711, 52.381148636830254],
            [1.272286798610001, 52.381538092196884],
            [1.272483201519695, 52.382285434302425],
            [1.274820263930083, 52.383497772788296],
            [1.275787668134993, 52.383541093230711],
            [1.276479571527628, 52.384388905170304],
            [1.27845788075058, 52.385061602396313],
            [1.279254934873095, 52.385958643888344],
            [1.279177494294821, 52.386777436240408],
            [1.28102078138839, 52.387629221324765],
            [1.28124428997054, 52.388541284175126],
            [1.283126909816942, 52.388971031930375],
            [1.284943696717216, 52.390176053549048],
            [1.28753294680947, 52.390934137319192],
            [1.289057118358885, 52.390910585445681],
            [1.290574155498396, 52.389981529299845],
            [1.291314678412349, 52.390134475214261],
            [1.292023468366764, 52.389545406617074],
            [1.293139565182596, 52.389510691479941],
            [1.295426927536048, 52.390615191142459],
            [1.296236684915215, 52.391715929101693],
            [1.299029501711477, 52.391789941995881],
            [1.300029013126224, 52.391374379583922],
            [1.30148757019604, 52.391692470059979],
            [1.302630743270121, 52.390602823208731],
            [1.304601588517911, 52.391009078082611],
            [1.306468893811517, 52.390325438996328],
            [1.308119720247223, 52.389440973848089],
            [1.308573177353918, 52.388527079725307],
            [1.311599100511118, 52.387829777977771],
            [1.31316154844335, 52.388220359419307],
            [1.31634323228604, 52.387386353823949],
            [1.316142608493662, 52.388415497919134],
            [1.317560842982451, 52.388391853049924],
            [1.319982729675601, 52.389537972716262],
            [1.321684082031097, 52.388997261380666],
            [1.322675545249358, 52.389084492049122],
            [1.32468889706657, 52.390288779307205],
            [1.325000614849234, 52.39099322380082],
            [1.325954897243662, 52.391621111749295],
            [1.330576843682396, 52.393532481687004],
            [1.330222738934473, 52.394296161920252],
            [1.33143906934202, 52.394692667423101],
            [1.331701359866478, 52.395441660765975],
            [1.333336557837176, 52.397044113173166],
            [1.333428283037823, 52.398494050118217],
            [1.335183944809399, 52.399722526689438],
            [1.335696517853999, 52.40189628375078],
            [1.337863570281619, 52.4016965516631],
            [1.340251038361504, 52.402331483043604],
            [1.34178828045671, 52.402203453373104],
            [1.344786317240831, 52.403696131494094],
            [1.346378632699884, 52.403906455630597],
            [1.347072552007486, 52.40438955666302],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000204",
        LAD13CDO: "42UF",
        LAD13NM: "St Edmundsbury",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.840903139065171, 52.400777513433908],
            [0.849224241676013, 52.399539344605067],
            [0.855412083182603, 52.399422323072855],
            [0.857080196649441, 52.398071723034846],
            [0.856891294386688, 52.396945879489152],
            [0.856845450302468, 52.39516728610942],
            [0.85968040262937, 52.393405323509086],
            [0.861675102874595, 52.393017986181398],
            [0.863430500694204, 52.391273983027517],
            [0.865555892759511, 52.390523692342207],
            [0.874712111441852, 52.389893845353164],
            [0.880914714572237, 52.391739182213705],
            [0.883565002262057, 52.391197889177398],
            [0.891097517978856, 52.39077995895903],
            [0.892299146332897, 52.390296396626979],
            [0.892898223980431, 52.389522716414241],
            [0.892488238516937, 52.389030046628086],
            [0.893430404669593, 52.38844580819184],
            [0.89450135197749, 52.388122801194626],
            [0.897412783549312, 52.388230385383643],
            [0.898984724558155, 52.387456187756534],
            [0.899565148690557, 52.387543562843824],
            [0.900708328537675, 52.386619789581445],
            [0.901782598941629, 52.386371276055662],
            [0.903129056541319, 52.386642156135629],
            [0.904899785727891, 52.385764068614634],
            [0.906987556737287, 52.386311643627842],
            [0.907687058388835, 52.386198201121893],
            [0.909184580419087, 52.387491400937137],
            [0.910058502583935, 52.387281020228428],
            [0.910401417846533, 52.387555852897258],
            [0.911083615870236, 52.387447313248103],
            [0.910977100460684, 52.387883403466915],
            [0.913090384829897, 52.388748597612377],
            [0.914779393923412, 52.388617001752735],
            [0.915790856446126, 52.388954459375796],
            [0.918802381223802, 52.388661552391135],
            [0.919964429986922, 52.38934238607451],
            [0.920354495647514, 52.388905572286937],
            [0.920690430738038, 52.389162562186996],
            [0.921548241730267, 52.388864362424918],
            [0.921301237768293, 52.389553045637761],
            [0.922303677024545, 52.3894859828522],
            [0.922859310091814, 52.390019913962291],
            [0.923362744372863, 52.389319485533953],
            [0.923951599026875, 52.389599883923012],
            [0.92417701580892, 52.389141052696708],
            [0.925243987903332, 52.389272013669313],
            [0.925271562372083, 52.388800093286164],
            [0.9272472832863, 52.388248435240989],
            [0.928482901787245, 52.388262773767181],
            [0.930204204884732, 52.388819026739164],
            [0.93042237388581, 52.388340577827613],
            [0.931067040202244, 52.388575490287124],
            [0.930978965439835, 52.388222448476832],
            [0.931666995972734, 52.388313291435821],
            [0.931458268033558, 52.387869716158477],
            [0.932694058337728, 52.387953253087723],
            [0.93367259192156, 52.38726076682569],
            [0.935400733356524, 52.38712250677726],
            [0.936199609843862, 52.386558568287391],
            [0.936779253663637, 52.386633189973672],
            [0.936513766168712, 52.385202691587601],
            [0.937348936828439, 52.384256534408046],
            [0.940303703295611, 52.382917686098146],
            [0.940004485916886, 52.382484471397952],
            [0.940595370333969, 52.382419400728367],
            [0.940883995519897, 52.381831259052021],
            [0.941657620091319, 52.381088947107266],
            [0.941659596914204, 52.379837054767073],
            [0.942245595084475, 52.378925843756818],
            [0.943532260914818, 52.37820940747357],
            [0.945116504470374, 52.377857852620785],
            [0.945371496638403, 52.377383850898276],
            [0.946511436663908, 52.376870687663292],
            [0.946931525683998, 52.375963590565121],
            [0.948277133550662, 52.375759138498033],
            [0.948878224802309, 52.376267533158199],
            [0.949622239694477, 52.376164418872044],
            [0.950869720719778, 52.375127823416271],
            [0.954084280459366, 52.374525861025631],
            [0.95377170703291, 52.373827715898578],
            [0.954668459408382, 52.373636213479152],
            [0.954553782029017, 52.372799121167041],
            [0.955307946990849, 52.372827016501809],
            [0.956985275165422, 52.371893809871835],
            [0.963147371482318, 52.371583783154762],
            [0.965274884719488, 52.370377458600395],
            [0.967442116446307, 52.370093697052617],
            [0.967217842067851, 52.36880880920021],
            [0.966620365426015, 52.368728491486266],
            [0.966565769040824, 52.367738813290423],
            [0.966626425718879, 52.36710956479299],
            [0.967189358278468, 52.366762674182503],
            [0.967223479518422, 52.365715908344733],
            [0.964591753475234, 52.364040921473112],
            [0.959483162298171, 52.361833526666487],
            [0.953797823763271, 52.357116857844929],
            [0.953141557303574, 52.356042399509541],
            [0.955069003050338, 52.355608373579173],
            [0.956020092598255, 52.354388475205511],
            [0.956149940973026, 52.351493915078329],
            [0.956957967206763, 52.350860347658575],
            [0.957954554728452, 52.35076344119021],
            [0.957396972323262, 52.347956242945244],
            [0.958037195559689, 52.347559793605107],
            [0.957673962512877, 52.345771152738962],
            [0.956729109027041, 52.345475558521514],
            [0.955675590219308, 52.341747276066926],
            [0.957442840184159, 52.340506926581604],
            [0.955800891890726, 52.339312378012849],
            [0.955050833018836, 52.338126963831847],
            [0.952857878292826, 52.337121532795408],
            [0.953200427399547, 52.336842269674804],
            [0.946590394774755, 52.336438155791555],
            [0.940548362492654, 52.33549882236256],
            [0.937910426161584, 52.334683163924424],
            [0.936085604655616, 52.335303222452374],
            [0.932667000503207, 52.335711043298531],
            [0.933161155545808, 52.334127662632483],
            [0.932191930497431, 52.332925071219172],
            [0.932240926817401, 52.331979572439899],
            [0.931401343523142, 52.330383451734718],
            [0.928478206336985, 52.327932517233769],
            [0.927751331853508, 52.328305775373479],
            [0.926821404773898, 52.328316243047048],
            [0.925661889794174, 52.327317066266843],
            [0.925109041371273, 52.325568107659471],
            [0.926088795850027, 52.323334208701624],
            [0.926183088126685, 52.321405536617526],
            [0.926778500016327, 52.320065183924399],
            [0.925909456008898, 52.319261154757697],
            [0.927510366871902, 52.316413796739127],
            [0.930095980065606, 52.314303697068034],
            [0.931093138135471, 52.312850823774696],
            [0.925105072864951, 52.311718736498158],
            [0.918514112311092, 52.311319809505569],
            [0.919440888424449, 52.309394837961158],
            [0.916782609504593, 52.308083703803476],
            [0.908699736214806, 52.306112218346229],
            [0.904546028710119, 52.305569696750126],
            [0.908153780516995, 52.303469096424287],
            [0.908982700405405, 52.3044541035894],
            [0.911304512226366, 52.303462469667366],
            [0.911646980557391, 52.302042096971448],
            [0.908887716648054, 52.301574140869604],
            [0.903129215709009, 52.303827509669262],
            [0.903318740337968, 52.301792197816944],
            [0.902808388758411, 52.300763345212253],
            [0.9002403859051, 52.298955003957758],
            [0.89952862703736, 52.298950008835156],
            [0.898281972471861, 52.299693783618231],
            [0.895903437409502, 52.299975196254657],
            [0.892853517626983, 52.300084162491864],
            [0.889655827595439, 52.299703845036589],
            [0.882904299478472, 52.300118955400833],
            [0.882834626884284, 52.298166456685173],
            [0.883594098551264, 52.298251324679839],
            [0.884617979563005, 52.297394443023201],
            [0.885695365090338, 52.297405877319534],
            [0.885603193447371, 52.296078949551045],
            [0.886843543920294, 52.295890338760614],
            [0.887325395138213, 52.2927012847302],
            [0.883753951983928, 52.292767020743291],
            [0.882786874657608, 52.29311979421233],
            [0.879568809251299, 52.29338810878243],
            [0.880308336935892, 52.295290989182618],
            [0.879991851044613, 52.295996576773263],
            [0.87695814137963, 52.295808865897783],
            [0.876858810854403, 52.297534363202892],
            [0.874298085353721, 52.298264038810458],
            [0.874047917874967, 52.299474304489287],
            [0.866763828154294, 52.297846540259968],
            [0.868656080906416, 52.295737553295382],
            [0.868972552747664, 52.293441122820219],
            [0.863595097291942, 52.293249766469977],
            [0.859633234504391, 52.291946506139261],
            [0.855231414188371, 52.291528783370403],
            [0.855526181153909, 52.286331758761001],
            [0.852669121541169, 52.286165235555941],
            [0.849757187170584, 52.285326388999387],
            [0.842868616051787, 52.28476319247499],
            [0.843867567959929, 52.284425267492509],
            [0.845954611927179, 52.282693257200201],
            [0.848399363034238, 52.282333907334767],
            [0.849015058317938, 52.281943135476666],
            [0.849071278495881, 52.280077518255304],
            [0.850434935246609, 52.278411448765105],
            [0.853652385920239, 52.277053076054877],
            [0.854181665884202, 52.276209316197736],
            [0.853857468010083, 52.27520273891141],
            [0.854409244518765, 52.275142629305975],
            [0.852763531210327, 52.271707504017172],
            [0.855549116290404, 52.269591504074036],
            [0.856296455104506, 52.268424999653639],
            [0.85352937542743, 52.267496414914937],
            [0.852028638422066, 52.265468787129088],
            [0.849695826366269, 52.264816496181311],
            [0.842578834611221, 52.26800893362131],
            [0.840169226880335, 52.266316009628639],
            [0.842471220852076, 52.263968247230416],
            [0.845262334379976, 52.262830800903906],
            [0.845926613947229, 52.261760792859704],
            [0.843834624170235, 52.259783296525939],
            [0.842300866905653, 52.260000115438693],
            [0.836900796309722, 52.261959263914044],
            [0.836212873864061, 52.263121516342537],
            [0.833029423459287, 52.264798755964435],
            [0.82579575963621, 52.266498329050215],
            [0.822949676686955, 52.266251697046144],
            [0.820678621406536, 52.266473277761172],
            [0.817680764814254, 52.264364996478015],
            [0.812959882083389, 52.265084662244533],
            [0.809386623180257, 52.267034039762095],
            [0.803737553947527, 52.265966938336042],
            [0.801573806461671, 52.265892429312366],
            [0.799411209269019, 52.264696429360505],
            [0.800801627126548, 52.263641831855914],
            [0.798963677102863, 52.262339195324927],
            [0.802155444571088, 52.258127565295609],
            [0.798194855374828, 52.256182850290607],
            [0.797547023471792, 52.251386066263969],
            [0.800096873387752, 52.251111544658031],
            [0.801259155400191, 52.251047082961044],
            [0.803619888574783, 52.250237394771013],
            [0.806405858354663, 52.249851906221352],
            [0.807740075935515, 52.249378604805706],
            [0.806628507181208, 52.24644005806649],
            [0.813395922278728, 52.245978501889326],
            [0.813559050462771, 52.243787507668294],
            [0.816892118439695, 52.241408450687707],
            [0.821367163213651, 52.241518243245302],
            [0.82494309690523, 52.240319327333722],
            [0.827098870221794, 52.238951100563263],
            [0.824132039982045, 52.238851295610303],
            [0.819921119220507, 52.233362849839665],
            [0.821825002218404, 52.229966508137686],
            [0.820883987598609, 52.229354105717313],
            [0.822743854766708, 52.227898601773823],
            [0.822361296829161, 52.226047091038737],
            [0.820809503726255, 52.223584140016754],
            [0.821943536062166, 52.223247652575289],
            [0.820604493419638, 52.222752687626915],
            [0.819775102559685, 52.221311145330148],
            [0.81709442173541, 52.213269751695314],
            [0.814177185398513, 52.207027161664477],
            [0.8151237295017, 52.203893866420898],
            [0.818778096134975, 52.202013354914563],
            [0.819888157730473, 52.200151326883798],
            [0.819384578928605, 52.198970883795852],
            [0.821323971771338, 52.198973962216691],
            [0.824469605348079, 52.199650484730789],
            [0.825321182331432, 52.199439431465471],
            [0.826385227810725, 52.200200830113708],
            [0.827562608148802, 52.19859791245883],
            [0.829252113818036, 52.19761671018928],
            [0.830225529841997, 52.19902415574753],
            [0.831883361257378, 52.198949282313507],
            [0.832074635783477, 52.197676659429575],
            [0.829744671959667, 52.197307219464925],
            [0.830051863008266, 52.19682141385281],
            [0.831875794896438, 52.197127458710597],
            [0.83200622777215, 52.197613551882988],
            [0.832606810511825, 52.197599126859103],
            [0.832524853992963, 52.198634404666265],
            [0.836314784941961, 52.198279810904801],
            [0.83593109022984, 52.197218857234446],
            [0.836661363174549, 52.196782212754499],
            [0.835327623459483, 52.195808857699454],
            [0.837194712313959, 52.195666823994024],
            [0.83719857050291, 52.195199088439516],
            [0.838097818045803, 52.195324936044692],
            [0.838015053434972, 52.194554418006106],
            [0.836009839914818, 52.19266464214455],
            [0.836293374384163, 52.192015712973379],
            [0.83573571658381, 52.191898724861922],
            [0.835919385463379, 52.189957187058894],
            [0.834647628328334, 52.187976902591224],
            [0.833313496384179, 52.186808385037004],
            [0.832697103512157, 52.18682499352812],
            [0.833318042813784, 52.184671507367462],
            [0.836241890307373, 52.182153278483803],
            [0.830091587845924, 52.180266817845698],
            [0.829829926258584, 52.179233492092791],
            [0.826965484159883, 52.179301308338495],
            [0.824540359888853, 52.17969937057002],
            [0.822462632788002, 52.174543008420088],
            [0.817210496246095, 52.172353797884554],
            [0.814137910048869, 52.170716689017901],
            [0.809574927200213, 52.172068365238268],
            [0.807652795125176, 52.172134839592609],
            [0.804700425753567, 52.173634114086262],
            [0.800036141512181, 52.174430253371192],
            [0.798369903401166, 52.174357399966851],
            [0.796592619264565, 52.175195457739584],
            [0.795435990465245, 52.175132057043314],
            [0.793047480146463, 52.175624828190486],
            [0.792154772927243, 52.176248508092456],
            [0.789370610771821, 52.176731687341068],
            [0.786888278776284, 52.177878548040468],
            [0.782203566825301, 52.177609679864624],
            [0.779636920875803, 52.176775409619331],
            [0.777102809465025, 52.176532059761698],
            [0.777301714114976, 52.175686525601925],
            [0.77646418909436, 52.174499385639095],
            [0.768658485138641, 52.174984209130706],
            [0.768484654632679, 52.174258057551249],
            [0.765019918724269, 52.173887913313287],
            [0.762871917140054, 52.163540539807819],
            [0.76156161966387, 52.161722274710904],
            [0.761987352847428, 52.160427197649895],
            [0.761512029229338, 52.157922983172647],
            [0.759056124709782, 52.157506555762716],
            [0.756868420069866, 52.15951192109285],
            [0.755333425422552, 52.159493849823868],
            [0.752976656778276, 52.159996761991685],
            [0.749839291615838, 52.159129317034882],
            [0.747575469518756, 52.159575095333452],
            [0.743089766457443, 52.159676858725561],
            [0.74196209982848, 52.159315494571842],
            [0.740877449300154, 52.160652794479404],
            [0.742233100401496, 52.161820033879351],
            [0.741778546015784, 52.165033907860924],
            [0.739467928446933, 52.165343022141734],
            [0.738071917288433, 52.166114675026314],
            [0.738685727750237, 52.168464671213172],
            [0.73830637916946, 52.169955524467284],
            [0.734387410289933, 52.16863188674705],
            [0.732839511994489, 52.167668658288612],
            [0.732973937141727, 52.167022545280709],
            [0.732025058878104, 52.166477980735905],
            [0.732892851078657, 52.165198852784265],
            [0.732746215716215, 52.164739113997634],
            [0.731175848655215, 52.1661037647428],
            [0.729799800953299, 52.16569947558532],
            [0.728996836468116, 52.167656946776134],
            [0.728132336840488, 52.168084335160685],
            [0.726398120172902, 52.166630722203713],
            [0.726814102643888, 52.165971810433597],
            [0.723464592460239, 52.164957489319328],
            [0.722769190124917, 52.165088661286653],
            [0.722272032782222, 52.166215267398911],
            [0.721226505893609, 52.16743906297539],
            [0.720599446534785, 52.167660372224411],
            [0.720070318107265, 52.168887527265326],
            [0.720256279900815, 52.170041527300427],
            [0.719649462231008, 52.170259666130306],
            [0.720348142099043, 52.171283131043729],
            [0.71957352751604, 52.171532119535222],
            [0.720009365268009, 52.172274774270946],
            [0.719860949118711, 52.173893329009047],
            [0.719678704874115, 52.174118759052725],
            [0.716606597824017, 52.174351454757613],
            [0.715670599191916, 52.175169789956257],
            [0.715937277283447, 52.177673572102499],
            [0.715934821504412, 52.179265375520529],
            [0.715354844457315, 52.179230171379061],
            [0.714906229672626, 52.177269253879118],
            [0.713862347978188, 52.177398502205662],
            [0.711834960386548, 52.178216740128619],
            [0.707467038466601, 52.179060830677017],
            [0.705967136200893, 52.179847967436658],
            [0.704432645735238, 52.180114289840823],
            [0.703324132783188, 52.181060581700471],
            [0.698115539498394, 52.18129229100348],
            [0.697269138951547, 52.181133596194478],
            [0.697301032779488, 52.180838799358469],
            [0.695823269576483, 52.180690038648436],
            [0.695104382544294, 52.180148014280896],
            [0.69441231591514, 52.180194406339041],
            [0.694233561423359, 52.179754242684325],
            [0.69583439732566, 52.177585441271816],
            [0.695155414213611, 52.176952576260554],
            [0.695710443128806, 52.175584660411253],
            [0.693126549884268, 52.175427877695938],
            [0.69499054444274, 52.173065123055913],
            [0.696468256182226, 52.173168928767105],
            [0.695491495998009, 52.172399888624234],
            [0.696157245309467, 52.172089699873915],
            [0.696094812570839, 52.170985001822046],
            [0.697157142696911, 52.170606384743962],
            [0.69729954537607, 52.17010761850792],
            [0.694438590603074, 52.169015649685456],
            [0.694368642726301, 52.168729475661614],
            [0.695031484759106, 52.168928356899443],
            [0.695004604676271, 52.168217633460593],
            [0.694474084184665, 52.167255828670307],
            [0.693440398906758, 52.166631060354959],
            [0.692156591492246, 52.16711901969331],
            [0.690842246630019, 52.166326175209853],
            [0.692525065929434, 52.165858794788903],
            [0.692917734406368, 52.165435254130166],
            [0.692577295207212, 52.164423235534372],
            [0.693011109322917, 52.162429494267101],
            [0.692484888274926, 52.161585389531915],
            [0.691918724773235, 52.157802415909359],
            [0.691617360253672, 52.157557495725399],
            [0.689996206832697, 52.157878673737955],
            [0.689559923682378, 52.157626934675086],
            [0.688577544577319, 52.157993770598772],
            [0.687959125240317, 52.158649965037036],
            [0.686988704590585, 52.158681979153634],
            [0.686152736194697, 52.15929545961108],
            [0.685213560929941, 52.158875304110595],
            [0.684832143375447, 52.157810240313651],
            [0.682014305853145, 52.155982277172512],
            [0.677057240290096, 52.157409706274244],
            [0.674224419482851, 52.156715900960272],
            [0.674102044406839, 52.157332889828695],
            [0.676758371711574, 52.157545989978814],
            [0.675510759053852, 52.1592451862074],
            [0.673440739731274, 52.159228296665027],
            [0.668290618883122, 52.158075181341964],
            [0.666884294955954, 52.15812858565306],
            [0.664148702070498, 52.157665254403369],
            [0.664210011608192, 52.156341919749387],
            [0.663683573352604, 52.156316045610112],
            [0.663060935525419, 52.158645770752599],
            [0.663388956500337, 52.160686031867783],
            [0.661117924583094, 52.16051159498101],
            [0.657824719224739, 52.161243259201456],
            [0.656252064105817, 52.160985536027262],
            [0.653570428908526, 52.161503658987613],
            [0.649879655824022, 52.160396022191534],
            [0.645344597597703, 52.160162435093795],
            [0.645352517389993, 52.15911279596795],
            [0.644586031172073, 52.15826937982245],
            [0.646345968610949, 52.156930425533659],
            [0.645581203112317, 52.156362157412467],
            [0.644659946429999, 52.156335165615467],
            [0.644175473708877, 52.155734521265479],
            [0.641357598894672, 52.15626981220003],
            [0.640152801503963, 52.152910106817266],
            [0.639679591307804, 52.152967466213084],
            [0.638849135714163, 52.150940193602644],
            [0.637511200546572, 52.150218336268289],
            [0.639099221434476, 52.148767326853282],
            [0.637902241242893, 52.148054908531435],
            [0.639557290291388, 52.146898255537415],
            [0.640232194881338, 52.145804002910452],
            [0.638461443630869, 52.144929080262834],
            [0.638576942387855, 52.144608149208793],
            [0.637755108319759, 52.143959274814613],
            [0.63676207778925, 52.143999477123067],
            [0.634584750739668, 52.143266684672149],
            [0.634033250459089, 52.143457067134342],
            [0.633343915412271, 52.142476064836139],
            [0.633690300759088, 52.141361310693398],
            [0.636277844563605, 52.140157783862918],
            [0.637987012792699, 52.137868641480644],
            [0.636078899613214, 52.136121757421343],
            [0.636862703462464, 52.136055664430941],
            [0.637915364425365, 52.135395418467006],
            [0.637178029597117, 52.13337863873317],
            [0.636300396809651, 52.13242434811945],
            [0.635181282913458, 52.132626532498541],
            [0.634315341865197, 52.132216032200802],
            [0.632808656443045, 52.130554549192453],
            [0.626886799652146, 52.130986140294254],
            [0.626865000284792, 52.128976731489253],
            [0.628724768152259, 52.128723036989662],
            [0.629507596711233, 52.127848558716444],
            [0.633322592482426, 52.127698635989205],
            [0.6307669938931, 52.127325849362016],
            [0.630651414169885, 52.12541385598697],
            [0.628583330191439, 52.124608377176166],
            [0.630930593012518, 52.122514630362566],
            [0.63179721168904, 52.122370281112588],
            [0.634332452736756, 52.123046556912982],
            [0.635797708831592, 52.122945450873438],
            [0.63596508340779, 52.122216884426599],
            [0.634933959864515, 52.121132923194047],
            [0.636298279925146, 52.119397369025002],
            [0.633958584478478, 52.118144821709905],
            [0.633817459874556, 52.116866495355815],
            [0.632645318138965, 52.115220915710864],
            [0.634920740554842, 52.115362509624354],
            [0.635673683257503, 52.114333976797212],
            [0.637256616704444, 52.114647486456924],
            [0.637948412272783, 52.113499802497017],
            [0.638580992532115, 52.110089037495072],
            [0.642363083458483, 52.110223719225857],
            [0.643347543355962, 52.109064042593573],
            [0.642567339040692, 52.108985317334991],
            [0.643094920024521, 52.107336782485234],
            [0.643505261716395, 52.10743459318843],
            [0.646786062933788, 52.102685549312461],
            [0.647784174672647, 52.102993164588156],
            [0.648611274644393, 52.102140036395632],
            [0.650516120043213, 52.101189821860203],
            [0.648816464251786, 52.100649798495652],
            [0.649300126607441, 52.099522007645334],
            [0.651225493654744, 52.099879785395665],
            [0.652891697515845, 52.097593159095553],
            [0.653158361482349, 52.096575453229612],
            [0.653736691387662, 52.096601998187651],
            [0.653497857401741, 52.095358262393475],
            [0.655235420237212, 52.090885613178152],
            [0.65469101869652, 52.089265666506208],
            [0.654747115853121, 52.087604310025945],
            [0.653922216918128, 52.087553648765613],
            [0.654091030807082, 52.08624137647535],
            [0.654161208294742, 52.086046447792462],
            [0.656505331881203, 52.086188762670773],
            [0.656870318779221, 52.085427824707665],
            [0.654688500206365, 52.08504354798999],
            [0.652871718923487, 52.083405492785332],
            [0.651847097841095, 52.083136290354489],
            [0.647757603212952, 52.084132056403483],
            [0.646885934655488, 52.084523050024224],
            [0.646592822761769, 52.085095288666132],
            [0.645688744865709, 52.085185738833275],
            [0.645371636081647, 52.08485922265757],
            [0.643765601306463, 52.085174951906694],
            [0.6434479089869, 52.085554386489946],
            [0.642311904693968, 52.085549293594603],
            [0.641752475342232, 52.086235406730204],
            [0.637854488395304, 52.086155476511479],
            [0.637235722544861, 52.086628865194655],
            [0.634543081449455, 52.086269138524393],
            [0.632546304012352, 52.08451249754723],
            [0.632514232712465, 52.083298275644559],
            [0.632921288893471, 52.082799067271083],
            [0.631745889712422, 52.081850500618131],
            [0.62808227285168, 52.081653502903883],
            [0.626763980378903, 52.08102822139908],
            [0.626250212214272, 52.080382296019934],
            [0.6267501107997, 52.079847765857906],
            [0.625690968550034, 52.079259860258283],
            [0.624265311015419, 52.079340187101693],
            [0.624092426291034, 52.079055366742921],
            [0.622652648796727, 52.079268183799385],
            [0.620225772769365, 52.078776317373276],
            [0.620395450172167, 52.076964077491141],
            [0.619317826501981, 52.076556384093159],
            [0.618229024174752, 52.07695469029558],
            [0.618172667886314, 52.077537781189363],
            [0.61708049296769, 52.077829136293722],
            [0.615698144824067, 52.077651201719505],
            [0.615390347312103, 52.078103181811358],
            [0.612243072519754, 52.078793515810169],
            [0.611196770066944, 52.079396749752199],
            [0.609152873988583, 52.079938438059614],
            [0.609369846926766, 52.080305037249481],
            [0.608631859310671, 52.080690086753101],
            [0.607346625650831, 52.080901991283334],
            [0.60621183801866, 52.080264327378714],
            [0.605458702591848, 52.080214438397718],
            [0.604365937030786, 52.079445431522053],
            [0.603673655714418, 52.077231408138815],
            [0.601083083711473, 52.076353377452619],
            [0.599938538342048, 52.075594466597394],
            [0.598470207030063, 52.07531571004759],
            [0.595750832947008, 52.076363970641289],
            [0.59506195055078, 52.076162419843882],
            [0.595673083429135, 52.075954711452674],
            [0.595336536390837, 52.07508442598067],
            [0.595831788351398, 52.075017758982227],
            [0.595960436068514, 52.074568879878683],
            [0.594772365992971, 52.074164297015379],
            [0.594133051627534, 52.074741327432989],
            [0.593403392088892, 52.074641384715001],
            [0.59322151674761, 52.075028482680374],
            [0.591276211703415, 52.074652221242168],
            [0.589827894567389, 52.075272199295924],
            [0.589349917940951, 52.074553388174202],
            [0.585898057558181, 52.074445753367407],
            [0.584929525461904, 52.074126181010904],
            [0.584291178105575, 52.075530474141125],
            [0.582102346958727, 52.075839259402855],
            [0.58254753021323, 52.07447518465446],
            [0.578945768315411, 52.073513618091006],
            [0.577807377465729, 52.073891950130751],
            [0.57733914639092, 52.073136008159778],
            [0.576745622330559, 52.073599530643868],
            [0.576269546566562, 52.072859043907307],
            [0.576231135698185, 52.071153050550492],
            [0.571746850567133, 52.069072974379431],
            [0.570546067073022, 52.06910098070562],
            [0.568367134751453, 52.069679979632504],
            [0.567813815832857, 52.069548157080632],
            [0.567403772734573, 52.070183891375279],
            [0.566638733751505, 52.070456856156035],
            [0.567033673266758, 52.070854724851401],
            [0.566458318243138, 52.071178410894035],
            [0.565055405445231, 52.071296195372909],
            [0.564903553713826, 52.070989250650811],
            [0.563062198202339, 52.070815298320312],
            [0.56230692891787, 52.070749893638371],
            [0.56146943646854, 52.071108932378088],
            [0.558412079977224, 52.070034185847469],
            [0.556676007126995, 52.069760725745127],
            [0.555112637511386, 52.069138172307568],
            [0.555477829302621, 52.068685109009763],
            [0.555777679801791, 52.06887644504156],
            [0.556037549371838, 52.068699944291787],
            [0.555813792493531, 52.068384656411695],
            [0.553346291221746, 52.068844636771757],
            [0.551341252409995, 52.068740596186714],
            [0.550049723427584, 52.067150762640743],
            [0.550298619132212, 52.066269479666836],
            [0.549433125598759, 52.065112859549146],
            [0.549640674858527, 52.064043624502041],
            [0.551713560649457, 52.062808098810457],
            [0.551499285144401, 52.062043858426904],
            [0.551005786080365, 52.061704727829934],
            [0.550276053916269, 52.062212432844852],
            [0.547574144225728, 52.06198402757493],
            [0.546637089589326, 52.061284868171477],
            [0.546182791165502, 52.059684984217085],
            [0.543077842013847, 52.058496668077552],
            [0.5422328850023, 52.057746925244359],
            [0.538493991975158, 52.057903097499221],
            [0.53765883452105, 52.058173788578451],
            [0.537070651063418, 52.059095625248183],
            [0.534182113030148, 52.059740532272556],
            [0.529720555800948, 52.05934902339142],
            [0.524822911377781, 52.057070127234809],
            [0.523122034758372, 52.05676125090028],
            [0.519264196751604, 52.057317737596392],
            [0.518359932893527, 52.057710271223726],
            [0.518159299118505, 52.058474445046016],
            [0.517237862688913, 52.058847552836959],
            [0.516531073489053, 52.058549707812368],
            [0.515772995091591, 52.05863694285415],
            [0.5148145207929, 52.059285999420531],
            [0.513281444918874, 52.05897789475334],
            [0.513145265966634, 52.059644461963408],
            [0.511023630480564, 52.059819201372086],
            [0.509021050467739, 52.058136172425506],
            [0.509178249292781, 52.057557291073422],
            [0.506526985773215, 52.056322384029386],
            [0.506084050630431, 52.05539386963752],
            [0.505359497821138, 52.054932669234546],
            [0.504558643902513, 52.054463194857277],
            [0.500788863346249, 52.053802290652854],
            [0.50024022026419, 52.05403425824143],
            [0.500471457087034, 52.056189394415703],
            [0.500060676960344, 52.05669630840989],
            [0.498372290605707, 52.057130505965432],
            [0.495048893857675, 52.057414072087354],
            [0.494659208992251, 52.058091380612971],
            [0.493220397576651, 52.058477052857384],
            [0.493195607788238, 52.058765343141566],
            [0.492289723936073, 52.058766527230418],
            [0.492092645090225, 52.059392989657091],
            [0.4913022005576, 52.059266725689767],
            [0.488499199806646, 52.061461730067663],
            [0.487217823726566, 52.063843069903939],
            [0.489257483643099, 52.065573341411401],
            [0.489438281517589, 52.066410334679638],
            [0.490216075108289, 52.067067446402731],
            [0.487115492063264, 52.069614917839374],
            [0.484108794569716, 52.070311436543655],
            [0.482954512534427, 52.072467025670186],
            [0.480997288869833, 52.072251068933944],
            [0.475437001638703, 52.07351009342689],
            [0.475805254960798, 52.074442084956225],
            [0.474690332821574, 52.075181321670442],
            [0.470763864728699, 52.073450817796626],
            [0.469840779792683, 52.0743352624023],
            [0.47023769537863, 52.075920432656112],
            [0.466636364775747, 52.078443672779471],
            [0.465240262803162, 52.076548474988506],
            [0.462555663818648, 52.076190957019676],
            [0.458129223872479, 52.07611073373149],
            [0.458085546929751, 52.074864371917407],
            [0.459035332708094, 52.074673692912775],
            [0.45983562563504, 52.073914198046673],
            [0.460737502783768, 52.073755080156744],
            [0.460212388678863, 52.072936928695043],
            [0.461792603456874, 52.072322997903342],
            [0.455996613908896, 52.070919719465074],
            [0.4562349196689, 52.071326612409841],
            [0.453798807939029, 52.071129180532047],
            [0.453592312805379, 52.068135349679508],
            [0.45271732823904, 52.068011486266542],
            [0.452685228181622, 52.069369137239242],
            [0.451844381586388, 52.069389337228017],
            [0.451772979820186, 52.067899851058172],
            [0.449513190862164, 52.067561075815505],
            [0.446987695824807, 52.067806000002783],
            [0.446766116211664, 52.068476952766559],
            [0.447335885673544, 52.068996580057565],
            [0.447261861988148, 52.069590729157873],
            [0.446713853715821, 52.068828749014756],
            [0.443920898721158, 52.06850633349611],
            [0.444229920631381, 52.070183333827863],
            [0.446068557372249, 52.072105556912099],
            [0.442276010114602, 52.071706730232343],
            [0.441203964839185, 52.07179639277642],
            [0.441200481680537, 52.072240698038598],
            [0.438095470062721, 52.072300497909161],
            [0.437028387649025, 52.070284859666359],
            [0.435349905117882, 52.069071399472328],
            [0.431039863993572, 52.068379860331611],
            [0.42895919024922, 52.068354432723183],
            [0.428764236297345, 52.06766602502217],
            [0.427267603974526, 52.067862332436469],
            [0.426107983879584, 52.067681187931392],
            [0.424534735172188, 52.067002264479441],
            [0.422940482129156, 52.066877693370813],
            [0.420627549708676, 52.066063752960481],
            [0.418755051191508, 52.064893613755352],
            [0.414552123557605, 52.065066157332673],
            [0.409667991952824, 52.064266904329934],
            [0.406236930173906, 52.065335203093973],
            [0.404608237033184, 52.065490755397548],
            [0.403295912806532, 52.066239632749564],
            [0.406172811981015, 52.069162830723357],
            [0.405677957581387, 52.069349189598498],
            [0.407062470669241, 52.070782195475452],
            [0.401940983700794, 52.072474795946079],
            [0.400863437749953, 52.073007532998609],
            [0.400878406260214, 52.074020683339825],
            [0.403648891223171, 52.075119732761813],
            [0.40163329852823, 52.076420684876695],
            [0.402965979898254, 52.078191114316269],
            [0.400574894381457, 52.080112992029804],
            [0.400206321148093, 52.081023347199476],
            [0.400589894573181, 52.081507422505148],
            [0.399935455025711, 52.081850778485048],
            [0.400491004595533, 52.082275597712815],
            [0.400990671708597, 52.082012728024829],
            [0.401681039041835, 52.082583170659767],
            [0.399477726753839, 52.084146891090455],
            [0.397152320672204, 52.083000019668297],
            [0.395327616701596, 52.084059572906753],
            [0.394189895297724, 52.084320096979994],
            [0.393351735784712, 52.086496218333053],
            [0.393843578572171, 52.0881579287989],
            [0.390819582623268, 52.088471111767298],
            [0.390953749310631, 52.090352311865914],
            [0.39244662759367, 52.095007087346758],
            [0.392691370424781, 52.095297071182117],
            [0.394636459371699, 52.094867288948784],
            [0.394966340406244, 52.095955867733686],
            [0.392341225667729, 52.096065841657698],
            [0.389162411257602, 52.097125796403247],
            [0.386833726879144, 52.097292583933935],
            [0.386270031727011, 52.097673589336388],
            [0.388181480664243, 52.098250852007553],
            [0.388657086313494, 52.099199808416913],
            [0.38588942705294, 52.099542732630134],
            [0.383973412049908, 52.100714555551484],
            [0.382556085397992, 52.101135288443054],
            [0.381772969191141, 52.102688824965881],
            [0.383663880274663, 52.105396885595525],
            [0.3852002513493, 52.106602262143426],
            [0.38546335201135, 52.110326999728798],
            [0.388550681859522, 52.113519734325017],
            [0.388138594128477, 52.114390459147479],
            [0.389291843221732, 52.116207837756889],
            [0.389558823714542, 52.117457768957443],
            [0.39830953213137, 52.11877907805777],
            [0.395197115320908, 52.119660678470844],
            [0.39484907292125, 52.121533680937816],
            [0.396331634755905, 52.122346140916221],
            [0.405764678018789, 52.12275198723561],
            [0.408545819205167, 52.123691555173679],
            [0.409158918422753, 52.124250945951076],
            [0.409407035736064, 52.125816856225448],
            [0.407700796278708, 52.126200627156585],
            [0.406868325670034, 52.126830013838173],
            [0.406509368798472, 52.127868776302506],
            [0.405544305975096, 52.128234683601541],
            [0.404675301680652, 52.129272157175009],
            [0.406815821876626, 52.130870492137468],
            [0.409971756219664, 52.134385006826115],
            [0.411700237084429, 52.133947695349626],
            [0.413715644704541, 52.134088093555192],
            [0.417280001742457, 52.133094230108284],
            [0.418252129815727, 52.134342228170738],
            [0.419661647413288, 52.139425515972164],
            [0.41855308415612, 52.142431958518955],
            [0.418422416895581, 52.145522644595616],
            [0.419658696703812, 52.146757119248242],
            [0.419426603778402, 52.150617840843054],
            [0.419757941536118, 52.152818684318312],
            [0.420856101005514, 52.155625161560856],
            [0.420446833220479, 52.156731540021305],
            [0.417246325579715, 52.159292596011959],
            [0.421807922137666, 52.160365309307231],
            [0.42613286279554, 52.159905909890647],
            [0.427089605588816, 52.160371811567124],
            [0.430610293949907, 52.159482774315407],
            [0.435885577627335, 52.159480920100165],
            [0.438156014118607, 52.160706425702983],
            [0.438249026360156, 52.163022757171568],
            [0.437041835973792, 52.164131304151525],
            [0.438253672626301, 52.165220416333064],
            [0.438676042815217, 52.165697274100921],
            [0.439360212407221, 52.165746968783225],
            [0.441064969348289, 52.164966214892779],
            [0.444275990459105, 52.164668590144146],
            [0.443506775821852, 52.166509102837388],
            [0.443273647811299, 52.168579500714806],
            [0.444492274324408, 52.171067635500386],
            [0.444717112906745, 52.17092718832594],
            [0.446567225383526, 52.171753891686024],
            [0.452525051987349, 52.173205610807074],
            [0.454643327413464, 52.172523071009138],
            [0.457233504406902, 52.171195791288532],
            [0.460980171926845, 52.171401820331248],
            [0.462787449078379, 52.170997195329321],
            [0.468231378545302, 52.167834915059537],
            [0.470160259176962, 52.164930411597695],
            [0.4707000415099, 52.163244697787633],
            [0.472426710975955, 52.163978263234512],
            [0.474425358481063, 52.165843649117349],
            [0.477211266605628, 52.167556678539746],
            [0.47861552920011, 52.16758921704433],
            [0.479233720061475, 52.165951272981218],
            [0.481685789150879, 52.166287267938955],
            [0.482405534752003, 52.165661525103509],
            [0.485899870659397, 52.165150870076985],
            [0.48742035396913, 52.164489326955675],
            [0.488301895616751, 52.164668562598003],
            [0.489700507034527, 52.16564890089461],
            [0.491302621661202, 52.165088098651026],
            [0.492637017382123, 52.167403353061204],
            [0.489758976821065, 52.168031587194385],
            [0.488732826178013, 52.169140446822915],
            [0.487434902497957, 52.169782922585263],
            [0.488720313315007, 52.171588477141135],
            [0.491226698978714, 52.171973488226392],
            [0.491054159988297, 52.174277422162675],
            [0.493191721982875, 52.175570335710304],
            [0.493001805510189, 52.176175951075571],
            [0.494141607123545, 52.178168873319606],
            [0.493134121708093, 52.181051598820659],
            [0.493267642257263, 52.1813725067775],
            [0.493934964847741, 52.181481593769412],
            [0.494546019608837, 52.184139448922274],
            [0.493817819107177, 52.184564009610384],
            [0.495026169439913, 52.185503348760903],
            [0.494593612679778, 52.186583508281913],
            [0.494945820379154, 52.188718974573824],
            [0.495760553105604, 52.18884561630243],
            [0.49563708433222, 52.189514574562004],
            [0.496791978588415, 52.189810264064377],
            [0.496800860836459, 52.19130013607203],
            [0.499131483841369, 52.192130241349986],
            [0.500624098878782, 52.193279321412533],
            [0.499742508891403, 52.194544372897916],
            [0.50252624541075, 52.196012269608929],
            [0.501320524252316, 52.197290504082737],
            [0.500662582614716, 52.197457328329541],
            [0.501672174944704, 52.199017702192819],
            [0.502104107360053, 52.203032689549261],
            [0.502861829332824, 52.203988703930889],
            [0.504044207529703, 52.204192917766576],
            [0.503101105614655, 52.207021295951463],
            [0.506669996827447, 52.208509305053468],
            [0.507345232273146, 52.212337453418016],
            [0.508643765328648, 52.21231254265394],
            [0.508621295547958, 52.213965843662599],
            [0.512791700498259, 52.213673849808529],
            [0.512537705206241, 52.215035331222964],
            [0.518952182339327, 52.214841879360414],
            [0.520969286198659, 52.215121620908768],
            [0.521428529870745, 52.214829440154617],
            [0.523554716930812, 52.21512209320089],
            [0.532259843897032, 52.215203521707416],
            [0.532595028373884, 52.216694484198889],
            [0.54105917232744, 52.215446842176924],
            [0.541896134791867, 52.216336159459992],
            [0.543060562306733, 52.216447749210637],
            [0.545091277183978, 52.21522053112686],
            [0.5445389848153, 52.213780145980863],
            [0.54718399110922, 52.2131088672446],
            [0.551306187314601, 52.213385779083318],
            [0.554225927369475, 52.212376572143114],
            [0.556443549247378, 52.210996728262501],
            [0.55482280973233, 52.20964068917867],
            [0.555505403000669, 52.208985629902273],
            [0.555142879615949, 52.207795669844046],
            [0.557907788412908, 52.206545122173821],
            [0.558178535977733, 52.20407347827765],
            [0.563609688561201, 52.202795509368144],
            [0.558848173974911, 52.210886136666296],
            [0.560498263932892, 52.213614654365678],
            [0.563804033182979, 52.215549099995762],
            [0.565902275518154, 52.21750972706969],
            [0.562130004624503, 52.218106097167393],
            [0.561280747434055, 52.220851993003812],
            [0.562958676523086, 52.220953136526006],
            [0.56294752509145, 52.221191682525358],
            [0.560391757563635, 52.221178837937018],
            [0.560354251600846, 52.221875678298666],
            [0.557598097777109, 52.222096432655981],
            [0.556798494794608, 52.22420903685326],
            [0.55507229841096, 52.224034211768021],
            [0.553921290585494, 52.225920597981649],
            [0.555763865076054, 52.226335718807391],
            [0.558158029920267, 52.227353044570386],
            [0.55818502438004, 52.228408187700772],
            [0.556634169265016, 52.228993950558419],
            [0.5563187622565, 52.230054714657292],
            [0.554957601580218, 52.230502354915572],
            [0.554013714134359, 52.231203529571651],
            [0.554048744450565, 52.231584055976406],
            [0.55491867740749, 52.232170419712176],
            [0.556190413207974, 52.232459410817221],
            [0.557010332210022, 52.233295937181836],
            [0.559942669437505, 52.234342929949918],
            [0.55935277413732, 52.236486966936162],
            [0.55808603217259, 52.238196947840457],
            [0.555790274953059, 52.239131568631564],
            [0.558957691562431, 52.242577207361336],
            [0.556874557527775, 52.243901109289176],
            [0.560306983902484, 52.245326637398179],
            [0.562401516035854, 52.245929533470843],
            [0.562742894919647, 52.245752159973186],
            [0.563523464824992, 52.246204615634149],
            [0.563932173425548, 52.245975417489689],
            [0.566659471492962, 52.247589639940209],
            [0.561892318949477, 52.251329830104666],
            [0.560713493207429, 52.254672769602244],
            [0.561158906309826, 52.25800293495233],
            [0.563240270262593, 52.262837997866406],
            [0.562908284314084, 52.26582263824131],
            [0.568378785703911, 52.26583320155428],
            [0.574109925635291, 52.265131011229876],
            [0.588497486471475, 52.264463800296561],
            [0.589418142948931, 52.264467882706782],
            [0.58936396214162, 52.264871040152123],
            [0.595053094196061, 52.264636364446012],
            [0.596180364634692, 52.27276515518809],
            [0.594694570347852, 52.276063037363571],
            [0.594653724047603, 52.278826456013569],
            [0.596014324838692, 52.280632821983872],
            [0.596370107656915, 52.282579982577936],
            [0.600921265605139, 52.283129944559903],
            [0.600305914716128, 52.286066991118311],
            [0.598365169085275, 52.291314631445324],
            [0.596365261038629, 52.294029429453573],
            [0.594422933125969, 52.298009076717577],
            [0.589521266951348, 52.296026834816125],
            [0.589827366177064, 52.297024587279147],
            [0.588525967080581, 52.298349856483519],
            [0.587972027531575, 52.2997414577626],
            [0.58864256081468, 52.302259055400889],
            [0.589939556273838, 52.304349250317465],
            [0.590948094721141, 52.304989870388148],
            [0.591719657918844, 52.306470192401655],
            [0.60106801516632, 52.312682580115165],
            [0.602181495065633, 52.314680467926443],
            [0.600732086509772, 52.315085595013294],
            [0.600768701264981, 52.315309602091972],
            [0.601523687261449, 52.316173338964724],
            [0.601693790650891, 52.317772062186791],
            [0.602728649637535, 52.318627825654296],
            [0.603053681694287, 52.318615264635504],
            [0.603354047796787, 52.317607771713156],
            [0.606112392772115, 52.316528062743906],
            [0.609487123536663, 52.314604652720142],
            [0.611710786557889, 52.31419308335024],
            [0.612371305273095, 52.312279239550833],
            [0.613183062054103, 52.312783743217523],
            [0.613841397227121, 52.312352813714362],
            [0.613922358197474, 52.311884306715292],
            [0.61261433749387, 52.311124611123475],
            [0.614205602509033, 52.310591197841056],
            [0.614444572883636, 52.309393490444066],
            [0.616577393235374, 52.309337246449289],
            [0.617947569198547, 52.308539803209598],
            [0.619499799418246, 52.308341706776282],
            [0.620895760400417, 52.307758579216717],
            [0.622317012355706, 52.307852015274953],
            [0.622902460380995, 52.308821000788846],
            [0.62478849564166, 52.310247574044148],
            [0.639376497782371, 52.324989458844293],
            [0.644587883765078, 52.331356521246882],
            [0.653777950656983, 52.343975604124445],
            [0.663453462444958, 52.354808808837085],
            [0.669448333560495, 52.358435974580701],
            [0.675690235539856, 52.361200249721485],
            [0.68216603039576, 52.364368953859227],
            [0.691163756882983, 52.369906093237383],
            [0.693144392537702, 52.371792571637243],
            [0.700769850827983, 52.381135393114],
            [0.705935688000332, 52.388781434379226],
            [0.708755153567133, 52.392219460378755],
            [0.712411565780586, 52.394604054411239],
            [0.716006899879773, 52.394001680690884],
            [0.722192878739538, 52.393673160749287],
            [0.733318033193496, 52.395369509353934],
            [0.735233441991833, 52.395321582901808],
            [0.737562651821886, 52.395119255161788],
            [0.738586984701524, 52.393073954979727],
            [0.740970887382086, 52.392214722053495],
            [0.741097585222758, 52.392646130522365],
            [0.741790243682719, 52.392675021013453],
            [0.740859461504633, 52.39117595094298],
            [0.741363594379758, 52.390179551451588],
            [0.741234657106006, 52.389474816539689],
            [0.740536302420218, 52.38892627640324],
            [0.740939222128891, 52.388170533212573],
            [0.743370911976454, 52.387375786452992],
            [0.744610054059364, 52.385933347428001],
            [0.744850777733058, 52.384886396401043],
            [0.746717447185983, 52.383800750828705],
            [0.746883164461238, 52.383345462238694],
            [0.749817232579821, 52.382907598044966],
            [0.750457519897531, 52.383041971211526],
            [0.750841326039974, 52.383542023512241],
            [0.751205135403412, 52.383389667187785],
            [0.752513943766419, 52.38404082830521],
            [0.754283395811509, 52.384253181169477],
            [0.759109014682579, 52.386479586142357],
            [0.760673389022033, 52.386747892682649],
            [0.762411205075753, 52.388098448457214],
            [0.762653822263041, 52.388947087071962],
            [0.765939180586242, 52.390338729829857],
            [0.7672739833191, 52.390388405299568],
            [0.768119596410943, 52.389996293685286],
            [0.770268618905739, 52.389786746853282],
            [0.772541957113285, 52.386660582745748],
            [0.773407596797984, 52.38630842942127],
            [0.77522708068822, 52.386940157752974],
            [0.777326470166977, 52.386738840803012],
            [0.779522070164211, 52.387250149733703],
            [0.783734139629243, 52.386241796595158],
            [0.785900225908026, 52.386470409426799],
            [0.786544537910204, 52.387202515054589],
            [0.787874637137165, 52.387598290965151],
            [0.791972839294306, 52.389840534904543],
            [0.794825796180097, 52.391095917522684],
            [0.796103824925129, 52.391223049540635],
            [0.800697536815913, 52.389765308286961],
            [0.803629870226521, 52.38923172176851],
            [0.804361630971089, 52.389483238579423],
            [0.808144973700507, 52.389505775385686],
            [0.812756357853928, 52.390899672658627],
            [0.814785111823387, 52.393398127761131],
            [0.81598805797793, 52.393521438811845],
            [0.816578007583635, 52.393944427773491],
            [0.818313197516694, 52.393653942894382],
            [0.819669628822004, 52.393809526325484],
            [0.820472011911005, 52.393391089111368],
            [0.821018711057735, 52.393642425810725],
            [0.823110369302197, 52.393709367050327],
            [0.824360527785587, 52.394548196820615],
            [0.82761915619422, 52.394886616334091],
            [0.829356242005794, 52.396119317591427],
            [0.82983890103966, 52.397275033143565],
            [0.833104966363123, 52.398895510829632],
            [0.834276619657972, 52.399169568955415],
            [0.835417048080745, 52.400010916989046],
            [0.838288850428686, 52.400705455275798],
            [0.840903139065171, 52.400777513433908],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000205",
        LAD13CDO: "42UG",
        LAD13NM: "Suffolk Coastal",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [1.463703854406639, 52.047930562385218],
              [1.462905991051448, 52.046763148837734],
              [1.46229803322543, 52.046861983820463],
              [1.461740409731938, 52.045989715575857],
              [1.460920137174208, 52.045757489047162],
              [1.460570995501892, 52.045298234845824],
              [1.461248459962248, 52.046532166028321],
              [1.463029745513456, 52.047928808765271],
              [1.462852216528912, 52.048217361835256],
              [1.463297968481957, 52.048322077689434],
              [1.463703854406639, 52.047930562385218],
            ],
          ],
          [
            [
              [1.332436348730601, 52.055165350502193],
              [1.3319414963563, 52.055044441552901],
              [1.332188303529119, 52.055968367954328],
              [1.332793098642673, 52.055743480382738],
              [1.332436348730601, 52.055165350502193],
            ],
          ],
          [
            [
              [1.475596867372417, 52.054757739246156],
              [1.474887386303311, 52.05462127770096],
              [1.473785037267491, 52.054911957901254],
              [1.475494940056839, 52.055643122843769],
              [1.476917330801844, 52.057217458394845],
              [1.479573181948334, 52.058871357745161],
              [1.481660642946662, 52.059182018875937],
              [1.484463257877516, 52.060805385749006],
              [1.485469213455894, 52.060475185491065],
              [1.483763412450356, 52.059538962457253],
              [1.483409941338115, 52.05891619820688],
              [1.478347338545215, 52.056655324226853],
              [1.475596867372417, 52.054757739246156],
            ],
          ],
          [
            [
              [1.532074451212558, 52.079338704716648],
              [1.535638259259193, 52.078677664215824],
              [1.538244582279723, 52.078978948894481],
              [1.537590535371943, 52.07706022995562],
              [1.536249201169451, 52.07612189390813],
              [1.528811205036049, 52.073804041979699],
              [1.522619445843356, 52.071380086901748],
              [1.514989642032175, 52.067698119049709],
              [1.51171338842075, 52.067553032448274],
              [1.509577034883452, 52.06668308596339],
              [1.504517051995969, 52.065750855034317],
              [1.500028891697738, 52.06546521807671],
              [1.499360936940935, 52.06683247811624],
              [1.50119540890545, 52.068509414915454],
              [1.502939040813386, 52.069472150263778],
              [1.506493826145918, 52.067863315997101],
              [1.50815265799591, 52.067762628145459],
              [1.510808607510423, 52.067963191791286],
              [1.513787020909112, 52.06907243267873],
              [1.520046101354967, 52.073789386904139],
              [1.524421761933612, 52.079745221108347],
              [1.52518613658869, 52.080218847704003],
              [1.526161332155357, 52.080533245537417],
              [1.527412655814712, 52.080461584341755],
              [1.532074451212558, 52.079338704716648],
            ],
          ],
          [
            [
              [1.639312777467258, 52.331028523388589],
              [1.639195123566158, 52.330668762866438],
              [1.638366894381752, 52.330988432055044],
              [1.639074505490673, 52.33077322423263],
              [1.638731155349376, 52.33118677844994],
              [1.639312777467258, 52.331028523388589],
            ],
          ],
          [
            [
              [1.634373556256254, 52.332082027090735],
              [1.635510200456364, 52.331883073629115],
              [1.635545985445161, 52.331876809935466],
              [1.635604525626622, 52.331823764056537],
              [1.636208768751484, 52.331276225655742],
              [1.637479843432746, 52.331575254321578],
              [1.638671329921028, 52.330512215968469],
              [1.637075166585231, 52.330080203995323],
              [1.637047928895004, 52.328193040282606],
              [1.636055805931136, 52.327946577132181],
              [1.634273514068336, 52.329073660420768],
              [1.63267474157594, 52.328841354062256],
              [1.629023073476753, 52.326399205092933],
              [1.629107656047454, 52.32553220749768],
              [1.630618423708333, 52.324710383917719],
              [1.62780583662384, 52.323456672661351],
              [1.627414063543502, 52.32396073045259],
              [1.630051450498676, 52.324742219479567],
              [1.628875935285912, 52.325337851532197],
              [1.628637292520214, 52.32595503507077],
              [1.628975553312372, 52.326625535494948],
              [1.630343365372164, 52.327480238452743],
              [1.630300376292014, 52.328711144570235],
              [1.634063819199931, 52.329613508319902],
              [1.635293411127969, 52.32917536676996],
              [1.635800907220254, 52.328221576176944],
              [1.636973313074052, 52.328625290392473],
              [1.636438402250965, 52.329924430901471],
              [1.637816658774069, 52.331099841462567],
              [1.637254906503692, 52.331464356382931],
              [1.636157098907998, 52.33118337275004],
              [1.635523164866414, 52.331777671082591],
              [1.634468187510297, 52.331964878979328],
              [1.63343273855533, 52.331083797596925],
              [1.634373556256254, 52.332082027090735],
            ],
          ],
          [
            [
              [1.631702123047519, 52.332646393288499],
              [1.630835920048407, 52.332109976757067],
              [1.626485886340786, 52.334337718687351],
              [1.628603236275378, 52.334553266767173],
              [1.629203280471903, 52.335371326028984],
              [1.630033401631327, 52.335216269013792],
              [1.630438707247789, 52.334697396030592],
              [1.630324512327215, 52.333805931226067],
              [1.631702123047519, 52.332646393288499],
            ],
          ],
          [
            [
              [1.596157761661751, 52.334388850869026],
              [1.601488261277872, 52.333059680253513],
              [1.606781810520851, 52.333011338852508],
              [1.607256498967157, 52.332512015247879],
              [1.610946615884469, 52.331490690792869],
              [1.613519908744339, 52.331520755622918],
              [1.618972965430214, 52.333175077914454],
              [1.621210968359651, 52.333269226543308],
              [1.620870957188527, 52.33528818294684],
              [1.621326970280767, 52.335675354972558],
              [1.625640815177028, 52.334148711506295],
              [1.626908312675876, 52.333901983202196],
              [1.628211477085892, 52.33276456381045],
              [1.630623166745669, 52.3314311223385],
              [1.631102661100883, 52.331441553922083],
              [1.626127734110173, 52.330214740145017],
              [1.623708510495616, 52.330164929303827],
              [1.621134997624247, 52.329053927628188],
              [1.620392717108569, 52.329510250036989],
              [1.619707471824187, 52.329389155261921],
              [1.615958573925043, 52.327599910812722],
              [1.614316517768228, 52.327482916410503],
              [1.612364194063135, 52.32674756688364],
              [1.612202279398358, 52.327174375639231],
              [1.613119778738221, 52.32424009230467],
              [1.611325925728347, 52.322439397757172],
              [1.611563759383743, 52.321953599463541],
              [1.610999434712495, 52.321243198454319],
              [1.610111904416098, 52.320565170979229],
              [1.610649190721348, 52.319845344053014],
              [1.615115195344564, 52.320161929871908],
              [1.610557909573794, 52.319751893832063],
              [1.609842106836963, 52.320730832920127],
              [1.609587058673359, 52.320609111653425],
              [1.609238092109463, 52.320974178260471],
              [1.609026549328639, 52.320719802216203],
              [1.609297834411967, 52.321509335129754],
              [1.608188562592077, 52.323227070714715],
              [1.606678745524629, 52.323199473153423],
              [1.605740792700366, 52.323578932396622],
              [1.607091297270708, 52.323254322583061],
              [1.608261454480736, 52.323419127415157],
              [1.606077579331506, 52.326508108488589],
              [1.606162252693524, 52.328215413030598],
              [1.604581326130766, 52.329084032152082],
              [1.601878274223468, 52.329803394148307],
              [1.600585244481675, 52.329862643166344],
              [1.599127048898745, 52.329407023207381],
              [1.596808830726, 52.327960305127732],
              [1.596188957640886, 52.326853064700195],
              [1.597548812496986, 52.323734580990163],
              [1.597730345325065, 52.323769508767562],
              [1.598350526634575, 52.324041092771537],
              [1.598310517373708, 52.325102785802137],
              [1.599039209102761, 52.325832517885196],
              [1.599862633330817, 52.325812796943907],
              [1.601139252828016, 52.32517389784006],
              [1.599006521022154, 52.325719282421289],
              [1.59847431453883, 52.325077103002464],
              [1.598367788294529, 52.323892154116642],
              [1.597987098825959, 52.323818909638071],
              [1.597565129946631, 52.323697158533236],
              [1.597150597511095, 52.323657960876467],
              [1.595361447609612, 52.323313691838891],
              [1.593436994615779, 52.321974542243481],
              [1.59165978239499, 52.322486480583251],
              [1.591025744502171, 52.321292397785925],
              [1.589608688253208, 52.321375991495394],
              [1.588136287375934, 52.32012374520513],
              [1.586303787399852, 52.319771098914828],
              [1.585982493575505, 52.320516626644391],
              [1.584563519092033, 52.320957305784731],
              [1.582897776608943, 52.320815411944096],
              [1.581270761686727, 52.3212614683342],
              [1.579852099353665, 52.323796031857626],
              [1.57590169614525, 52.327158375196788],
              [1.573511590633645, 52.328576374183662],
              [1.568829859335688, 52.330344281339379],
              [1.562557619191008, 52.331746272858318],
              [1.563739603117934, 52.333863888414456],
              [1.564609783399598, 52.333711682962374],
              [1.565553197571841, 52.335672786004139],
              [1.567425062698263, 52.336544458072822],
              [1.568668488222115, 52.337962189695865],
              [1.58370738171403, 52.338952802820941],
              [1.590057026630402, 52.338754417741761],
              [1.589919312886651, 52.336802271017568],
              [1.59080675551001, 52.335650040773025],
              [1.59478931834574, 52.33523916454692],
              [1.596157761661751, 52.334388850869026],
            ],
          ],
          [
            [
              [1.441199305771887, 52.364068730509857],
              [1.44312872641009, 52.362581486421291],
              [1.443906680596516, 52.362685613228216],
              [1.450196230205031, 52.361215780799419],
              [1.446735114370096, 52.359369625106588],
              [1.455085979360451, 52.356292301108653],
              [1.4681024443536, 52.353647625639937],
              [1.472915593156988, 52.349598970438969],
              [1.47837255089659, 52.347643012002472],
              [1.48101421488484, 52.347337576711709],
              [1.481205332061994, 52.346462184449301],
              [1.486724857952435, 52.347473030862098],
              [1.486811359464462, 52.348627153312016],
              [1.487242016107817, 52.348537978342968],
              [1.487377120657905, 52.347661531317499],
              [1.487859336750658, 52.347779500351884],
              [1.488687172801449, 52.343710257512285],
              [1.489477993613766, 52.342098469545377],
              [1.488576334133291, 52.341595353991664],
              [1.490015556695305, 52.339491288911795],
              [1.487309815780124, 52.338473052284655],
              [1.484837366029121, 52.338066680707001],
              [1.485551738609685, 52.336844834154903],
              [1.484466748314564, 52.336547677804759],
              [1.483635206940636, 52.335869763722286],
              [1.486249815150443, 52.334054841480018],
              [1.484527261663959, 52.33347250780232],
              [1.484915220209895, 52.332935779913655],
              [1.484658681904001, 52.33258627751308],
              [1.486995494775348, 52.330956727193993],
              [1.488572063899686, 52.328493336667343],
              [1.489429871957021, 52.328610986811093],
              [1.489454627283195, 52.32827296448437],
              [1.491198493686882, 52.328382387601096],
              [1.491112068823377, 52.329018151000774],
              [1.49223451347269, 52.329382493934069],
              [1.492757424696375, 52.329013538176191],
              [1.493673005515298, 52.329193308157436],
              [1.494186950999477, 52.328803924135741],
              [1.495271041536267, 52.32927730706912],
              [1.495299490785178, 52.32885642491074],
              [1.495947888158174, 52.328757168347998],
              [1.496516858504461, 52.329251195307407],
              [1.497850768780326, 52.329002095806416],
              [1.497462144043682, 52.328784256034893],
              [1.498264108989915, 52.328900798864986],
              [1.498468545977341, 52.328626704434782],
              [1.498893232258384, 52.329728523808477],
              [1.499752185164142, 52.329395444353544],
              [1.499819618676846, 52.329765814072722],
              [1.50070734717619, 52.330056987453297],
              [1.500644999128242, 52.33043840206718],
              [1.501480455863772, 52.330588108823946],
              [1.501005018033237, 52.330999768270253],
              [1.501820023025579, 52.331392029818922],
              [1.501592288476525, 52.33181702841658],
              [1.502875458440173, 52.331872490772312],
              [1.503070692837744, 52.332948726666714],
              [1.502382556128519, 52.332991636807755],
              [1.502638468847164, 52.333238584296268],
              [1.503893005097084, 52.333339858098668],
              [1.504529281117798, 52.333921793982967],
              [1.505357829059232, 52.333964645428374],
              [1.505635474058736, 52.333632598439614],
              [1.506908817796224, 52.333896980598531],
              [1.50722660110743, 52.334312974429302],
              [1.507805147796766, 52.334258003027188],
              [1.507895850662165, 52.334698733335124],
              [1.509419539480007, 52.33497183745191],
              [1.509683709278581, 52.33534084920575],
              [1.509981084617193, 52.335109842299651],
              [1.512082435176611, 52.336612362285976],
              [1.513031008950361, 52.336557146763617],
              [1.515531821846269, 52.337577305391484],
              [1.51649053237561, 52.337316686430995],
              [1.516102959997159, 52.337706001432814],
              [1.517279078854091, 52.337631380309759],
              [1.517497897187629, 52.337040218264868],
              [1.518051742846923, 52.336969742995635],
              [1.519619253210516, 52.337773883418286],
              [1.521902684533365, 52.33808892866027],
              [1.523510585475314, 52.337572322558174],
              [1.524454716852119, 52.337830155887886],
              [1.524452299181627, 52.338371696113377],
              [1.52510568233966, 52.338629212313435],
              [1.525739115069461, 52.337843060876395],
              [1.527452591820906, 52.338062593585597],
              [1.527664907400176, 52.338442111280486],
              [1.528632648349425, 52.337724202593378],
              [1.531025495140601, 52.337642740594781],
              [1.53273720886884, 52.33691962514358],
              [1.53417926913867, 52.337050043602844],
              [1.535215054606775, 52.336579189405853],
              [1.539173504482786, 52.337145929162375],
              [1.538848531710336, 52.337452493439663],
              [1.540320965069088, 52.337833773082224],
              [1.542224200444337, 52.337781161200141],
              [1.544178892790385, 52.338206381895787],
              [1.545489263552963, 52.337534706952447],
              [1.546451254745054, 52.336253762551465],
              [1.54831065163757, 52.335636611834246],
              [1.550416649689889, 52.335477030759279],
              [1.550733192995139, 52.334956616615948],
              [1.551546898554318, 52.334758536501042],
              [1.552660256076796, 52.333628588592234],
              [1.554162433229992, 52.332807126241384],
              [1.555136955762659, 52.332948669019849],
              [1.555953555734602, 52.332622747671557],
              [1.555799448561831, 52.332147980720428],
              [1.556747153946721, 52.331900853190405],
              [1.559451284930671, 52.331750962243795],
              [1.560574965158417, 52.330935434793282],
              [1.560270119703535, 52.329919250018833],
              [1.561241075075309, 52.329943919839401],
              [1.562502161965328, 52.331640911468789],
              [1.568931498429985, 52.33020269058531],
              [1.5733961778069, 52.328510610614728],
              [1.576733643198561, 52.326389305778804],
              [1.579640434865814, 52.323792559055775],
              [1.580872500475145, 52.321437257677125],
              [1.58047667665609, 52.321207313411939],
              [1.584079927376071, 52.320586125303194],
              [1.587443890737397, 52.319041153928985],
              [1.589481630722433, 52.321057847805818],
              [1.590994603810676, 52.321071177249358],
              [1.591933842539688, 52.321883590674823],
              [1.59392519248062, 52.321786076462011],
              [1.595727456379296, 52.322958042885048],
              [1.598871728777999, 52.323897472961065],
              [1.597955645696752, 52.323354247325213],
              [1.598192306325836, 52.322888302018178],
              [1.59841083554436, 52.323120896524841],
              [1.598403266204705, 52.322612028283096],
              [1.600936951096568, 52.321727909939092],
              [1.602291128801407, 52.319787795486107],
              [1.601572041203261, 52.318706097900396],
              [1.602157222643494, 52.318574001687054],
              [1.602937287783498, 52.318925263369344],
              [1.602438318338488, 52.319648381272771],
              [1.604442410272872, 52.317796331724566],
              [1.605497114787466, 52.31730177924716],
              [1.606202076114441, 52.316225155749223],
              [1.60800173834374, 52.31548923707264],
              [1.608523724056454, 52.315573115140616],
              [1.608535420968965, 52.315837986372806],
              [1.608458317014359, 52.316283899072552],
              [1.608574078004384, 52.316713343547107],
              [1.608621112528092, 52.317778339027271],
              [1.608981785263383, 52.316818365144584],
              [1.608559683092106, 52.316387395289681],
              [1.608550092670684, 52.316170242270793],
              [1.608871621267866, 52.315772051421263],
              [1.608727224953652, 52.316053505212864],
              [1.609195933056276, 52.316095835271128],
              [1.608735995757178, 52.316143184107332],
              [1.609449512105915, 52.316596279636137],
              [1.609748019904357, 52.316134713121833],
              [1.614089194409901, 52.316635046838115],
              [1.615673085801467, 52.316242021707723],
              [1.616316689891723, 52.316664837889782],
              [1.617204706140456, 52.316431637431023],
              [1.617848208268343, 52.317338365454283],
              [1.619120850056968, 52.318058491922649],
              [1.619807424247217, 52.319311985165449],
              [1.623078776413435, 52.320617457432569],
              [1.628672724038989, 52.322140167929959],
              [1.628619124283197, 52.32282991540648],
              [1.629869388293114, 52.322584753769306],
              [1.630094191990444, 52.322325810845228],
              [1.629648087667776, 52.321807944113694],
              [1.628795325770895, 52.321576023855719],
              [1.629199601496016, 52.320902474796128],
              [1.632267997891387, 52.321750708224393],
              [1.629462708874448, 52.321103959768159],
              [1.629025522052337, 52.321411535708656],
              [1.630454452106495, 52.322279647157586],
              [1.630992751519391, 52.322202818330382],
              [1.630803771579299, 52.321938790708664],
              [1.631312054411419, 52.32167489338142],
              [1.631128147519738, 52.322278705118265],
              [1.630343122847562, 52.322491856304815],
              [1.629828803482353, 52.322631501397737],
              [1.628669750098337, 52.322946189861398],
              [1.629478174995004, 52.323640006890244],
              [1.630653236203858, 52.323773857388552],
              [1.631404038307207, 52.324337211685311],
              [1.631313758503227, 52.32511623826862],
              [1.629888413526362, 52.325654806417703],
              [1.629807247921863, 52.326187094599476],
              [1.633809976202051, 52.328648991393173],
              [1.634856625511752, 52.327538282000212],
              [1.637239143543354, 52.32753592452697],
              [1.637911210090489, 52.328193412662117],
              [1.63805262036504, 52.330040178068046],
              [1.640545654383698, 52.329219420671649],
              [1.645391565826795, 52.328286366604466],
              [1.648362108632683, 52.325708393669181],
              [1.649915522615601, 52.324927275197297],
              [1.648402576562372, 52.32548676963598],
              [1.647018056844939, 52.326608047868142],
              [1.648271729345296, 52.325377480809244],
              [1.651587692689107, 52.324127169150472],
              [1.654384981053782, 52.323869664929767],
              [1.652048014482935, 52.324155198049134],
              [1.650141822783022, 52.324823130836549],
              [1.652906227097366, 52.324148420571184],
              [1.658481456423398, 52.323666263792909],
              [1.659314326104293, 52.32168226054479],
              [1.666622726788496, 52.316126816237272],
              [1.665402070398924, 52.315511718259344],
              [1.666741258415232, 52.31443749516334],
              [1.666409955386572, 52.313537511139494],
              [1.665507639910235, 52.313257024805665],
              [1.666020151581241, 52.312994641215916],
              [1.667127049726807, 52.312962939346136],
              [1.66716020785839, 52.311643261220382],
              [1.6607055602498, 52.307802329750309],
              [1.651756933291795, 52.301245387278577],
              [1.644960314363808, 52.294725656897086],
              [1.637626173917734, 52.28518548306225],
              [1.634080838959643, 52.278645803518152],
              [1.631450819796085, 52.272240651833869],
              [1.630119747933731, 52.267134798023378],
              [1.62928054002783, 52.245874380250847],
              [1.628259049425379, 52.237241993874655],
              [1.624527728374277, 52.220825153333138],
              [1.623048388328377, 52.204033174163442],
              [1.622542607628998, 52.19291128577995],
              [1.622831722955215, 52.189378210016294],
              [1.62225598127532, 52.186205427780223],
              [1.620665279266012, 52.183272512064661],
              [1.611681598314018, 52.171774824862958],
              [1.608178944331574, 52.164942604849614],
              [1.604589411688762, 52.157130708917414],
              [1.597301074210751, 52.135299905864592],
              [1.596414262021192, 52.133764578471236],
              [1.588842824356383, 52.112887297060546],
              [1.586582786924288, 52.100804094467605],
              [1.583422817860235, 52.09141071906307],
              [1.582726124474013, 52.089806547587678],
              [1.579659787667994, 52.08603561653149],
              [1.575884970969863, 52.083767581719094],
              [1.566324228540678, 52.080102529205128],
              [1.530260536042023, 52.069901843735892],
              [1.521899788693935, 52.067154302222235],
              [1.493583420064755, 52.058898060636579],
              [1.485490230469061, 52.055913370203896],
              [1.476486315396575, 52.052046574005715],
              [1.471315792902142, 52.049113098403694],
              [1.46900912678431, 52.047266139940845],
              [1.464064059987013, 52.041125422961414],
              [1.462988166814919, 52.040510367357143],
              [1.462122167925451, 52.040704041735552],
              [1.462081525117438, 52.040989467285272],
              [1.466661602599345, 52.047412651761363],
              [1.468111135466716, 52.048723646133581],
              [1.47263755392523, 52.051143779962686],
              [1.479395608610162, 52.053961929714724],
              [1.4833503887598, 52.056783528170314],
              [1.48799752324877, 52.05927057327127],
              [1.495515566112306, 52.062112072204577],
              [1.496624724477152, 52.062187969706329],
              [1.499220713273633, 52.063063374527822],
              [1.503216702177138, 52.063664109078509],
              [1.508036409382641, 52.065145933960082],
              [1.509951825533182, 52.065507997027552],
              [1.510324283062574, 52.065270225264321],
              [1.510585207808615, 52.065675302317842],
              [1.51243278210043, 52.066306491515135],
              [1.514714446325481, 52.066350830411288],
              [1.51742053943261, 52.067003980828304],
              [1.525941458343844, 52.070935246462128],
              [1.537524713442425, 52.074844997429956],
              [1.542573241278412, 52.078667992590859],
              [1.550145333606702, 52.081239097836921],
              [1.556939807015309, 52.084741694088684],
              [1.55970888763655, 52.085558391310983],
              [1.561126070014798, 52.08545977033144],
              [1.562500353054799, 52.085867039456168],
              [1.564506261619562, 52.087181640020248],
              [1.565093267789194, 52.088389862604117],
              [1.564996295244721, 52.089791493263448],
              [1.567716500036554, 52.091069122244264],
              [1.570289155037794, 52.09141210349285],
              [1.578023486225464, 52.090983049214621],
              [1.581351507629957, 52.091530403674753],
              [1.577785739978457, 52.0911323919251],
              [1.570055878278643, 52.09169082026785],
              [1.567700552008273, 52.09139971553838],
              [1.564642757907816, 52.090009077303186],
              [1.564023616135904, 52.087556042779553],
              [1.561816180795581, 52.086149486860073],
              [1.558605078450881, 52.08588858540346],
              [1.548111310867307, 52.08114300115691],
              [1.542090612544022, 52.079172728275367],
              [1.541502405073745, 52.079174231786808],
              [1.541664088562843, 52.083204423325711],
              [1.539474821087342, 52.088024885998109],
              [1.540806073794029, 52.089585929816131],
              [1.542026428392325, 52.089986372778988],
              [1.545788739421392, 52.089278585839715],
              [1.548564548616646, 52.089131102992724],
              [1.551363168085891, 52.089178952797873],
              [1.554805885391037, 52.089834230107193],
              [1.557139435071384, 52.090917791569353],
              [1.56199690707572, 52.094753854079975],
              [1.567869962578417, 52.098037258828548],
              [1.568770253326896, 52.098099926276262],
              [1.56811193619889, 52.098726126210856],
              [1.569948103718136, 52.100356105385515],
              [1.570765350800467, 52.102284103468499],
              [1.570800061130485, 52.1035585166997],
              [1.568453055786895, 52.107876110017159],
              [1.568715689275589, 52.10979124237501],
              [1.570640950979448, 52.111487774850971],
              [1.575326174588276, 52.11239982678832],
              [1.577599500681219, 52.113384985244345],
              [1.580668389860305, 52.115762602516469],
              [1.581349628922578, 52.115676226880261],
              [1.582309548003071, 52.116258682370514],
              [1.582184123895527, 52.116643878311145],
              [1.583381958816509, 52.117677825678328],
              [1.586843017528563, 52.11974562642115],
              [1.587936767501195, 52.121990706872083],
              [1.588894991798024, 52.122149505206465],
              [1.588607381247951, 52.12278880730716],
              [1.588706945674336, 52.122155234967899],
              [1.588043002725112, 52.12220334690592],
              [1.588903327603444, 52.125923488460344],
              [1.589949981066559, 52.126835151802823],
              [1.589810656881392, 52.127337712524692],
              [1.593296847155035, 52.129903782150052],
              [1.594397531255199, 52.132154891920422],
              [1.594655630308724, 52.134958806131891],
              [1.595701350543971, 52.135346949530991],
              [1.594783046600876, 52.135433443784564],
              [1.595327760119878, 52.13673276591927],
              [1.596865659586086, 52.136407875804551],
              [1.595394963682262, 52.136986168469377],
              [1.597225307243146, 52.13689790688818],
              [1.595570974502363, 52.137084236899042],
              [1.598332013354984, 52.140124740454475],
              [1.598496177580373, 52.141978062412321],
              [1.597416116861755, 52.142507565824815],
              [1.598443482393721, 52.1424483038708],
              [1.598054902604636, 52.142959387183573],
              [1.598845507828967, 52.143299528536275],
              [1.598103990742235, 52.143187256554988],
              [1.597925866696503, 52.143788155970796],
              [1.599116237878733, 52.143592585063267],
              [1.597783354433692, 52.143960712126507],
              [1.597515174903833, 52.143681067005353],
              [1.597294325539608, 52.144133056106057],
              [1.596882567984433, 52.143976525803964],
              [1.594479069095364, 52.144738891484195],
              [1.594816986000405, 52.144850010784637],
              [1.593632190183846, 52.144915841776822],
              [1.593247705125768, 52.145406095114026],
              [1.59493340129137, 52.145024556166305],
              [1.593157554931849, 52.145539269913705],
              [1.592897279497541, 52.145139742149446],
              [1.593402260054865, 52.14492915192546],
              [1.592297253732353, 52.144969149011686],
              [1.58927735277736, 52.143651708000206],
              [1.589115082778995, 52.144432905564216],
              [1.589152510772367, 52.143844403022776],
              [1.588224050570159, 52.143171090365826],
              [1.586686339854503, 52.142647646153542],
              [1.583529307214059, 52.14222112510388],
              [1.580454864658747, 52.142476514735804],
              [1.579883535398591, 52.142716949167237],
              [1.580087909540789, 52.142854364750889],
              [1.57978182660287, 52.142852263542508],
              [1.579685501633907, 52.143072864875755],
              [1.579261605395856, 52.142873467517362],
              [1.57541527769772, 52.144169485462704],
              [1.57332795973425, 52.145360763501394],
              [1.571235895853016, 52.148137027258223],
              [1.57165011390898, 52.148708231462649],
              [1.57656292316058, 52.150019942542627],
              [1.579034689090178, 52.151321082084081],
              [1.58023513743309, 52.152995418307398],
              [1.579888726017784, 52.154836385690963],
              [1.578716424927583, 52.155753489981656],
              [1.577367407946086, 52.155398687875817],
              [1.578290245873373, 52.155891461955058],
              [1.57795935068156, 52.156505961011582],
              [1.576022117813361, 52.157951771052744],
              [1.572087406631252, 52.159676695974447],
              [1.568246765730567, 52.159931590947807],
              [1.566608271906982, 52.160364377484335],
              [1.565144418334841, 52.159927460807125],
              [1.56329811490858, 52.160377282326834],
              [1.562293638031812, 52.159886860034661],
              [1.558742593267433, 52.158970567502209],
              [1.555547434881945, 52.158682056334008],
              [1.553452363453096, 52.161074894564457],
              [1.554218579973907, 52.16138458900793],
              [1.556853992656563, 52.161456173896845],
              [1.557390691019551, 52.162591297054959],
              [1.555271899452905, 52.164797791529054],
              [1.554050263378553, 52.16550924973172],
              [1.553131412840642, 52.16548748989208],
              [1.552986807931743, 52.166003649825157],
              [1.552547394704374, 52.165720972490142],
              [1.551519640195721, 52.166094652967793],
              [1.551694000576759, 52.166557122706486],
              [1.550960000104437, 52.165496281111835],
              [1.55146863995277, 52.166005343514612],
              [1.553018722961119, 52.165376655012999],
              [1.552848050185653, 52.165162330141229],
              [1.544181914202928, 52.164871938697026],
              [1.537197317743958, 52.165273404383704],
              [1.535502310178052, 52.165055333782782],
              [1.534261598581804, 52.164184092187973],
              [1.531037291258466, 52.163050288302642],
              [1.529952615183309, 52.163685430638076],
              [1.530483234918989, 52.163884505765154],
              [1.530064606817712, 52.164452918396023],
              [1.528949883949709, 52.165060168050594],
              [1.530245808116379, 52.163970772435619],
              [1.52974423378534, 52.163732149098756],
              [1.530428047199424, 52.163021771803578],
              [1.52877858273625, 52.162675416348947],
              [1.527760067491819, 52.161116557745117],
              [1.524989759462305, 52.161342559178586],
              [1.525826435628295, 52.161059302862668],
              [1.523908662468765, 52.160925087410163],
              [1.51955790108795, 52.15883614688039],
              [1.518447614549516, 52.157332122090772],
              [1.518209859409769, 52.157654933591232],
              [1.516113260774458, 52.158273386939889],
              [1.515876961363111, 52.157224467785511],
              [1.514222359822562, 52.156753039766954],
              [1.513829550543656, 52.156370790015821],
              [1.512979092636213, 52.15637906000164],
              [1.513311685091569, 52.156034542821324],
              [1.512198471040905, 52.156345665752283],
              [1.512779067427708, 52.155826457023849],
              [1.512698102543984, 52.155355753221876],
              [1.511198926232401, 52.155073933936912],
              [1.50993599837517, 52.15612076008064],
              [1.510795894236059, 52.156418048136551],
              [1.511397330338245, 52.156088911600868],
              [1.511840659639456, 52.156444478476303],
              [1.513043343077121, 52.158864159783178],
              [1.512204681433136, 52.158744348179361],
              [1.511154651538772, 52.159747062399731],
              [1.510995533322431, 52.159435193075545],
              [1.509114680541582, 52.159879790590558],
              [1.509960425102914, 52.160405070784059],
              [1.510046794387706, 52.160981751512509],
              [1.508621149889871, 52.162081978537373],
              [1.508776437422726, 52.162605338446852],
              [1.510025679721091, 52.162923419395696],
              [1.509541369100597, 52.16319958863383],
              [1.508948285943486, 52.162930323442154],
              [1.509118314615212, 52.164197998122603],
              [1.506349012395682, 52.164470222655744],
              [1.504348785772942, 52.164274279165681],
              [1.503053401889916, 52.163778517194636],
              [1.502513385442195, 52.164779478687123],
              [1.501804986217022, 52.165071270418579],
              [1.499302915528973, 52.16451957662494],
              [1.495849870158486, 52.165268780787294],
              [1.494717095212635, 52.165204340696235],
              [1.494713267547667, 52.164987684837776],
              [1.499702768677737, 52.16389337089759],
              [1.501518522563177, 52.164199212170111],
              [1.502276362122237, 52.163345588981642],
              [1.504922870994603, 52.163614090289492],
              [1.507116938336863, 52.162816646177106],
              [1.508138242801408, 52.160963934863062],
              [1.507708824028087, 52.159651812261188],
              [1.509830019329087, 52.158154903132406],
              [1.5092367989827, 52.157623001156807],
              [1.508775138791003, 52.157591776855917],
              [1.509605333663627, 52.158217362531325],
              [1.507483137767173, 52.15964593818051],
              [1.507936977505895, 52.160933948487852],
              [1.506932281125311, 52.162793359366958],
              [1.505038363311059, 52.1635018201953],
              [1.502600292596282, 52.163205538828379],
              [1.501894869573624, 52.163404598618172],
              [1.501515664920636, 52.164069774888461],
              [1.500126340461335, 52.163736876385194],
              [1.499202474161388, 52.163855160676469],
              [1.499018091229527, 52.162866733584906],
              [1.502377644276194, 52.16217777782142],
              [1.500080689604837, 52.160660281785525],
              [1.5002197491159, 52.160177638421693],
              [1.499205325371434, 52.159439629801874],
              [1.50240223851194, 52.160853041409666],
              [1.50342286212606, 52.160943227496084],
              [1.50370579712054, 52.160058740088743],
              [1.502438107968052, 52.160025371208988],
              [1.504151157965304, 52.158989529268105],
              [1.50471177748556, 52.157526522004957],
              [1.503834092677729, 52.157095699052832],
              [1.504768709145224, 52.155646753555821],
              [1.505482812802983, 52.155782915632116],
              [1.505548446685373, 52.155594774363713],
              [1.50431422760983, 52.153809180217543],
              [1.503856050620924, 52.153691485224748],
              [1.505042032453609, 52.153580655197686],
              [1.504564778415683, 52.15383410859949],
              [1.505030130546926, 52.154080210419011],
              [1.505620811601408, 52.153409627962453],
              [1.506017011388872, 52.153520165752347],
              [1.508896182308769, 52.152237270614364],
              [1.508953682623247, 52.153562265431795],
              [1.507626910464965, 52.156046509585188],
              [1.508196720210643, 52.157154772022309],
              [1.507805939918782, 52.156091549629693],
              [1.509291815030982, 52.153195108076979],
              [1.509032179453079, 52.152163062217944],
              [1.510753970293736, 52.151039609605881],
              [1.512153440966688, 52.150800018055186],
              [1.513090200858897, 52.151034733074617],
              [1.51425322735594, 52.150429788283319],
              [1.517554883843424, 52.150096500363261],
              [1.521505890956673, 52.150709738361854],
              [1.522938420907068, 52.151333416471346],
              [1.523376762692865, 52.151199780122333],
              [1.524052605709573, 52.151869457593783],
              [1.524178367678149, 52.153071880127584],
              [1.524436076569423, 52.152502904411378],
              [1.524718525098992, 52.153670956884085],
              [1.523881597727808, 52.154571171847316],
              [1.524050105780737, 52.156332682978956],
              [1.525028571630087, 52.157184890122622],
              [1.525367005314917, 52.156896829460543],
              [1.526836714137399, 52.157007553871722],
              [1.527634942552445, 52.156764187833502],
              [1.528994030272949, 52.157066185842446],
              [1.529994363643319, 52.156512730212413],
              [1.53137366957337, 52.156386847991676],
              [1.53270971617765, 52.156582457146897],
              [1.534097891807963, 52.157380030822118],
              [1.537189868062658, 52.156297831039879],
              [1.538319582334798, 52.156454597482536],
              [1.53933935385337, 52.157013120834719],
              [1.539911807515448, 52.1568187277039],
              [1.539841411129964, 52.157368619671111],
              [1.540371381672745, 52.157191691137683],
              [1.540140494524173, 52.156107571932658],
              [1.543741531204417, 52.15508545573465],
              [1.542810966064543, 52.153551960230281],
              [1.544840576902629, 52.152686785108855],
              [1.545914025048171, 52.15307453600375],
              [1.546899948757444, 52.152325281637893],
              [1.548217161096735, 52.152450222806159],
              [1.549070577045726, 52.152186157836582],
              [1.551425312269946, 52.150760594872537],
              [1.553235939990553, 52.150196899294329],
              [1.554381572673226, 52.150554512409705],
              [1.5543647913986, 52.151829572830877],
              [1.554884109217147, 52.152712478687405],
              [1.555946090864918, 52.153358634040416],
              [1.557749181182851, 52.153634306699026],
              [1.557673391927436, 52.152006747889025],
              [1.558860425987697, 52.151782906954317],
              [1.560110692763579, 52.152680587356848],
              [1.561889097618357, 52.152976730933162],
              [1.561728875909453, 52.153808192444281],
              [1.563040715709041, 52.154889273405956],
              [1.567523483188244, 52.156084846072858],
              [1.56978728428085, 52.154971085019994],
              [1.571414484028867, 52.154582667950244],
              [1.572905704385744, 52.152771779819027],
              [1.570520339302632, 52.151690065335181],
              [1.56925908932075, 52.151643731528075],
              [1.568850814298718, 52.152772350765019],
              [1.569340449834983, 52.152933815315414],
              [1.570172370761584, 52.152548817627078],
              [1.570606216592034, 52.153200383756676],
              [1.568428310293492, 52.153120653286287],
              [1.568501750216015, 52.153837110905847],
              [1.568084173241167, 52.152730808868618],
              [1.568922036409745, 52.152383418173876],
              [1.56905502265545, 52.151523986867161],
              [1.56986453627461, 52.151315967385081],
              [1.568754548393057, 52.150327789637231],
              [1.568192606728037, 52.148513474950249],
              [1.571122256652456, 52.144071232721792],
              [1.573383959503943, 52.142720893236103],
              [1.580382910422105, 52.140742703608396],
              [1.580086663602675, 52.140439587271921],
              [1.580768270506607, 52.140662630077138],
              [1.58352165249805, 52.140288371901235],
              [1.582923909791761, 52.139726388116273],
              [1.584192705425568, 52.139852395853303],
              [1.583799503358177, 52.140234945237289],
              [1.586059551844881, 52.140398233696537],
              [1.586348268978369, 52.140080022359975],
              [1.586392220119363, 52.140444773646649],
              [1.588017582237609, 52.140768564469965],
              [1.587953330762354, 52.14042422056491],
              [1.588727575748092, 52.141016782072285],
              [1.58885834473117, 52.140697979184452],
              [1.589302346912326, 52.14094710070848],
              [1.589046317617392, 52.14107183420348],
              [1.59168623335372, 52.141848582386828],
              [1.591993552257154, 52.141779846172447],
              [1.591160390610526, 52.141509317735597],
              [1.594669481741108, 52.142149762933592],
              [1.595465239638986, 52.14166584153925],
              [1.595317508489829, 52.141102775677268],
              [1.590295715056441, 52.137415140239547],
              [1.589614584675954, 52.136489641437329],
              [1.589238596254413, 52.13370640273444],
              [1.588562503314929, 52.135240832448716],
              [1.589139557767369, 52.13347465519518],
              [1.589108651403895, 52.13125567078648],
              [1.587747833295977, 52.128964765892668],
              [1.586837114857509, 52.128144288994442],
              [1.586414317740431, 52.128157163090926],
              [1.584774631097011, 52.126284881110294],
              [1.584184772825807, 52.126310027578228],
              [1.584683475272088, 52.126168923562872],
              [1.583858816252003, 52.124544365974124],
              [1.58394999713808, 52.122477277117063],
              [1.581703775378917, 52.119670857013986],
              [1.576437638721452, 52.115933427276119],
              [1.572543627899564, 52.114415453122703],
              [1.568320556757809, 52.113200553992868],
              [1.56624022261968, 52.111848683349251],
              [1.565981835523133, 52.109985588284914],
              [1.565260209504614, 52.108559273019701],
              [1.564600579129005, 52.108348073931694],
              [1.565109095634783, 52.107910824937584],
              [1.566792300233081, 52.103251817702656],
              [1.566713584962348, 52.101681009116909],
              [1.565489429574026, 52.100142188492455],
              [1.558019449530225, 52.096045224341978],
              [1.552626644230016, 52.092048854835262],
              [1.549223736316263, 52.091455275768716],
              [1.543900503785595, 52.092074337557129],
              [1.542195769642316, 52.091993407581562],
              [1.539920654295279, 52.091274782739333],
              [1.539308464768713, 52.090643763404621],
              [1.5388172491966, 52.090765564513802],
              [1.53745108197189, 52.090330791422204],
              [1.535890343347346, 52.08784294696521],
              [1.536409314155357, 52.085433857526404],
              [1.537936663489012, 52.082041924262995],
              [1.537871138817347, 52.081011294314997],
              [1.53636020605719, 52.080588057309676],
              [1.534556411170423, 52.080596346681013],
              [1.52817125648795, 52.081656743152166],
              [1.525175071524984, 52.08168891842908],
              [1.52495636413039, 52.081988694412686],
              [1.521374397295998, 52.079700578845035],
              [1.517249224898319, 52.074400064186989],
              [1.51544488287708, 52.072653210144232],
              [1.514981399653328, 52.072624773084414],
              [1.514792909966196, 52.072102410363257],
              [1.512692817248463, 52.070582916316049],
              [1.510798415770097, 52.069636511974117],
              [1.509793053019017, 52.069435323565877],
              [1.508654325767945, 52.069673444981966],
              [1.505851574247522, 52.070896566518137],
              [1.503395523617713, 52.071144172914728],
              [1.502122169708386, 52.071695653296757],
              [1.493552984509059, 52.07220208615103],
              [1.491815697186396, 52.07260891203331],
              [1.490600070100046, 52.073434689347408],
              [1.490220981850967, 52.074805020302811],
              [1.491129931075646, 52.078350750319942],
              [1.491180726131307, 52.08042161265876],
              [1.492545475918496, 52.0815207627532],
              [1.496205743658073, 52.083315407811938],
              [1.49824555929939, 52.085612325751768],
              [1.498170925754359, 52.091336047696828],
              [1.497058842607998, 52.092799229077421],
              [1.494217960327586, 52.0949199481622],
              [1.494559618564217, 52.095553826694101],
              [1.495888238120148, 52.096445338852156],
              [1.496121290911785, 52.097389154669344],
              [1.495706935208636, 52.098738059756087],
              [1.494249196345265, 52.09953867078044],
              [1.493512977626072, 52.101329758549504],
              [1.49211323907619, 52.102500099281379],
              [1.493138589266274, 52.101230229906093],
              [1.493625707968336, 52.100829010860487],
              [1.493275219305878, 52.100288036377307],
              [1.4936005554572, 52.099704527540588],
              [1.492891704574585, 52.099978303213355],
              [1.489944042948247, 52.099749980662892],
              [1.487254639916137, 52.098919392603975],
              [1.486267604306463, 52.099034964695463],
              [1.485976913817108, 52.099389862730249],
              [1.487661789732684, 52.101720854516785],
              [1.488048115490568, 52.101561901185498],
              [1.489990408892179, 52.102156469329081],
              [1.489636269392602, 52.102297385547466],
              [1.487962130604142, 52.10173264733421],
              [1.4876988422565, 52.101989595960106],
              [1.487331113499239, 52.101694668357652],
              [1.486890768869345, 52.101910085882601],
              [1.487482244534321, 52.103424356425457],
              [1.487930093195445, 52.103566700644187],
              [1.487056191182136, 52.105226002222118],
              [1.4872855675278, 52.103318647041689],
              [1.485775034812501, 52.103502782062783],
              [1.484608543639979, 52.104071582161978],
              [1.485640323684641, 52.103386241272339],
              [1.48700666691228, 52.103039077297744],
              [1.486879238475642, 52.102361057857976],
              [1.483632218222846, 52.103165025688945],
              [1.483171786805339, 52.103591496964576],
              [1.483391673686017, 52.104275779679419],
              [1.48568500177058, 52.104332951669953],
              [1.486204438608197, 52.105092912877453],
              [1.486530445799338, 52.104871888685281],
              [1.486647059507335, 52.105093301873254],
              [1.486309475413504, 52.105521545284091],
              [1.486733842330698, 52.105699668344471],
              [1.48472819754715, 52.108843281951181],
              [1.486646371857422, 52.109387352370049],
              [1.485749858299226, 52.110609273108189],
              [1.486084925283719, 52.111607652033392],
              [1.485476662708448, 52.11193776703422],
              [1.485899097473943, 52.112240076460211],
              [1.485672483702863, 52.112630853087602],
              [1.486327817289909, 52.112866004379498],
              [1.486332109838233, 52.113085344020384],
              [1.486334455987288, 52.113205187123327],
              [1.486344918177374, 52.113739774317075],
              [1.485636194749982, 52.113981118345002],
              [1.483601677061434, 52.113596077320231],
              [1.485734613029194, 52.113875667180217],
              [1.486271719405355, 52.113165225475811],
              [1.485508225618298, 52.112678888256063],
              [1.485810661944633, 52.11232364531584],
              [1.484913348251436, 52.111458833008868],
              [1.484019141657273, 52.11162560369381],
              [1.484222051160031, 52.11216647337433],
              [1.483128529502137, 52.112194320000079],
              [1.484080768557417, 52.112115785673623],
              [1.48393211417811, 52.111540030865264],
              [1.484864902355431, 52.111362225205063],
              [1.485764182623667, 52.111597356930695],
              [1.485393663903368, 52.11070616270338],
              [1.485821680573714, 52.109639327170306],
              [1.48338736605937, 52.109538653173871],
              [1.483180941899888, 52.108896247040384],
              [1.483845764309156, 52.108898170475577],
              [1.485483408696368, 52.105792448037775],
              [1.484883789736898, 52.104791102294236],
              [1.483756243554097, 52.104829857749934],
              [1.482843315783854, 52.104511465927473],
              [1.482544089334003, 52.103465246677274],
              [1.483280377643593, 52.10273649540688],
              [1.48583640243741, 52.101706539089378],
              [1.485489059626035, 52.100980160178828],
              [1.484999315395906, 52.101011743488399],
              [1.485332695989081, 52.100848069615736],
              [1.484825562467325, 52.100619322899611],
              [1.484484827934689, 52.099677774286462],
              [1.484980859983007, 52.098734851785281],
              [1.485911186719209, 52.098210814227002],
              [1.489206298947355, 52.098008893268087],
              [1.491312061994147, 52.098267592087822],
              [1.493074751669448, 52.097687335900815],
              [1.493312459382934, 52.097047961513205],
              [1.492321801342188, 52.094818789603714],
              [1.492942313025327, 52.093658965708698],
              [1.49511049192376, 52.091525864338969],
              [1.496519729617442, 52.086750140476013],
              [1.496046081058229, 52.084895113652642],
              [1.489903998492607, 52.080916389017673],
              [1.489387214164598, 52.079514148104387],
              [1.489692251930986, 52.077830303726167],
              [1.488791900937792, 52.075945621514094],
              [1.489368917611143, 52.073519753976107],
              [1.490756362998521, 52.072370484690154],
              [1.493428447183609, 52.071486208032269],
              [1.500281449648108, 52.071003833327175],
              [1.501287086243545, 52.0706681120366],
              [1.499694765733228, 52.070072334925264],
              [1.497042487588914, 52.068184008577539],
              [1.49529214901832, 52.064332296392891],
              [1.493020174964783, 52.063401318976709],
              [1.489412432650971, 52.062500003951229],
              [1.48605778436388, 52.060837333059894],
              [1.484042648163055, 52.060932066350233],
              [1.481290182822433, 52.059221757386453],
              [1.479381909761053, 52.059001137131062],
              [1.478446722521652, 52.058515178871993],
              [1.477077705600601, 52.057422296843193],
              [1.476287534283237, 52.057243253285726],
              [1.476222775376008, 52.056766650634529],
              [1.47556192537367, 52.056787062431056],
              [1.475165451451269, 52.056116073843263],
              [1.472936824515843, 52.055074602379968],
              [1.474739460230872, 52.054463740581348],
              [1.474520120859474, 52.054269634123237],
              [1.471192100615867, 52.052878302500019],
              [1.470092221982183, 52.051940208835937],
              [1.468557866913246, 52.05153209921513],
              [1.464293341143335, 52.048723630929111],
              [1.463198844683097, 52.048700069604408],
              [1.462278950123555, 52.048218034073237],
              [1.460164004155359, 52.045788712661235],
              [1.457820153590034, 52.044773736316131],
              [1.457475550086318, 52.044070586072841],
              [1.457736804209154, 52.043336147466356],
              [1.454481070828185, 52.043695281379485],
              [1.453156711952905, 52.044188367439951],
              [1.451801242357293, 52.044316270416807],
              [1.450985071696248, 52.04406046921509],
              [1.450697015533649, 52.04372981766479],
              [1.45107276612641, 52.043268172682033],
              [1.450706859936298, 52.043157472806215],
              [1.451335649293075, 52.043121048852271],
              [1.45101296790416, 52.043760131333379],
              [1.45177416270171, 52.044135373640756],
              [1.453600140339, 52.043885732115115],
              [1.453293641564847, 52.043410814731168],
              [1.453622025850439, 52.042997322764968],
              [1.454790310334989, 52.043506313568024],
              [1.457568459088027, 52.043026281080067],
              [1.458337586751455, 52.043220456651603],
              [1.458534127871567, 52.044612444951703],
              [1.460038602961843, 52.045487481322979],
              [1.460395798518975, 52.045390633042153],
              [1.460708545053261, 52.044360549728161],
              [1.460276432982385, 52.043717966418392],
              [1.460636669294319, 52.043563041325179],
              [1.460578369340608, 52.044129617311853],
              [1.461125229970181, 52.043957931972379],
              [1.461637842362972, 52.04430534133796],
              [1.461173020533976, 52.044330702892275],
              [1.461710220198047, 52.044505591778908],
              [1.460859396267103, 52.045481341245406],
              [1.461770289008884, 52.044923873971882],
              [1.462026493747607, 52.045331890424315],
              [1.461628906646459, 52.045509983640677],
              [1.46219338103185, 52.045320685745985],
              [1.46219281554554, 52.04470996764752],
              [1.460522918214219, 52.043299245352117],
              [1.455480652049843, 52.034630855784499],
              [1.454009330211881, 52.032856230507463],
              [1.450655404479538, 52.031534358482546],
              [1.447685603243484, 52.029725326922453],
              [1.444062201193507, 52.025955639881552],
              [1.438404606180402, 52.0172730851938],
              [1.434620493288834, 52.00854910016524],
              [1.431223964929679, 52.004906437735904],
              [1.424329459605795, 52.000314963167583],
              [1.412961582873485, 51.994095400558805],
              [1.407139336242977, 51.991527097602692],
              [1.397255925126242, 51.98797671305347],
              [1.394431923357015, 51.987255908041789],
              [1.3937207211046, 51.987579539553906],
              [1.394036181390166, 51.98999626211392],
              [1.394622226805695, 51.990520825633347],
              [1.3943830300183, 51.993577767447206],
              [1.392424872816031, 51.996140110972725],
              [1.393495626652763, 51.996368277305479],
              [1.39423955175465, 51.99620560637144],
              [1.394090300574658, 51.995331147651385],
              [1.395057006343113, 51.995205218603502],
              [1.394773099647278, 51.996033924707149],
              [1.394699361617813, 51.99624915912019],
              [1.394321888262, 51.99735093109642],
              [1.395558288472554, 51.998515126792554],
              [1.394133592833281, 51.997385145080848],
              [1.394140413206197, 51.996686074740886],
              [1.394516685200981, 51.996298229808431],
              [1.393420806737901, 51.996592599506904],
              [1.392087217096576, 51.99624158936583],
              [1.393236923850219, 51.997686238125418],
              [1.394435212150019, 51.998424305124097],
              [1.393119652603195, 51.997773268795086],
              [1.3918990602112, 51.99627759471398],
              [1.389997855916897, 51.998555825035496],
              [1.38953357039914, 51.998640256518499],
              [1.389641246205603, 51.998946565620891],
              [1.389274987089673, 51.998901349757936],
              [1.389531254269176, 51.999110735588914],
              [1.388502929397726, 51.99934812529785],
              [1.390193585287043, 51.999737460169534],
              [1.394576171883841, 51.998669386697998],
              [1.395092974462456, 51.998817958141537],
              [1.395420962601652, 51.998912246894008],
              [1.395149027298703, 51.999306760343359],
              [1.392292834425013, 51.999710239793423],
              [1.394989504210022, 51.999259194821157],
              [1.395080997389785, 51.998874023820051],
              [1.394794145549311, 51.998785300420003],
              [1.39403337808435, 51.998852342125069],
              [1.390038410956625, 51.999882244983262],
              [1.389679587211624, 52.00116890135245],
              [1.3879452411649, 52.001011975009916],
              [1.387011689821188, 52.001270008014238],
              [1.387769821676821, 52.000946868402636],
              [1.389593736827246, 52.001074233763035],
              [1.389891434457184, 51.999827115517633],
              [1.388218010271042, 51.999530822885809],
              [1.386686258453602, 52.00083954395388],
              [1.383879940660548, 52.001577775141627],
              [1.378222078777968, 52.003714626155343],
              [1.378682970200256, 52.004531583634311],
              [1.379920365333686, 52.004223516946077],
              [1.379162460562328, 52.00462844769708],
              [1.378473766337969, 52.004656317028022],
              [1.378037057582822, 52.003754116564863],
              [1.371246945744232, 52.005629003004373],
              [1.367129427200082, 52.007273246262805],
              [1.364860419068933, 52.008790747674361],
              [1.363801608483752, 52.011170362653665],
              [1.364914853853084, 52.014329761296075],
              [1.365952533241519, 52.015336245952938],
              [1.365520545390201, 52.015504203838212],
              [1.365644032907336, 52.017497435579784],
              [1.364675870312613, 52.018717779135187],
              [1.36438449034218, 52.020603244947182],
              [1.362988792215905, 52.022460905540562],
              [1.361700232830456, 52.022950136008411],
              [1.360985139544964, 52.022649458053628],
              [1.361104442522995, 52.023384492350814],
              [1.359672100582624, 52.024503816669963],
              [1.359051335281471, 52.026199890503364],
              [1.357706065007518, 52.026420861717263],
              [1.357533084371985, 52.026758589089596],
              [1.356552971738639, 52.026638135996734],
              [1.351924002270807, 52.028358460246672],
              [1.351316316146467, 52.028889343513143],
              [1.348352323450308, 52.029304691105565],
              [1.345226182536845, 52.030731941052977],
              [1.344268570218592, 52.032026458665541],
              [1.344816614635053, 52.03364336389695],
              [1.351506390907203, 52.038033913973656],
              [1.352631629225868, 52.038161071610773],
              [1.351743673185559, 52.038592904112214],
              [1.351714093330576, 52.039327687321297],
              [1.352297409075436, 52.039735611554562],
              [1.351368605120501, 52.040401558264342],
              [1.351294039929983, 52.042464291539822],
              [1.351945252492426, 52.044323775099073],
              [1.351614522867003, 52.045595101651593],
              [1.344152415687549, 52.052338111554405],
              [1.343632932822377, 52.05266856799269],
              [1.342658258259344, 52.052569425447082],
              [1.341008537880602, 52.053120836137879],
              [1.343966412634872, 52.055725267482494],
              [1.344651726159587, 52.057554137538915],
              [1.343661578187752, 52.060490130776543],
              [1.341696286003351, 52.061137754032679],
              [1.342874515996705, 52.061341757559624],
              [1.343382188329377, 52.061808539278971],
              [1.343673230198696, 52.064120812050341],
              [1.341397945235728, 52.064996692650418],
              [1.339147940700576, 52.064941798554329],
              [1.339307091548721, 52.065763862984937],
              [1.33762699200441, 52.066540938965261],
              [1.335229114435609, 52.067400384724159],
              [1.334362700394582, 52.067387147228551],
              [1.334215898904374, 52.067669228377248],
              [1.333359612386105, 52.067576546775136],
              [1.332907706859082, 52.067877156940398],
              [1.331474583658745, 52.067995064905126],
              [1.329916334914729, 52.068961058337472],
              [1.329359003125019, 52.068787038800508],
              [1.32990110539294, 52.069050532551252],
              [1.329834757784991, 52.070256744858213],
              [1.328989957865769, 52.071436398401119],
              [1.330001144925546, 52.070472399671225],
              [1.330046026180763, 52.069154365656402],
              [1.330174073838112, 52.070481899910824],
              [1.329508150026662, 52.071260749320849],
              [1.329822951856497, 52.071450621418627],
              [1.32941243999046, 52.07138667737901],
              [1.329711368671007, 52.071598584893827],
              [1.32923069714007, 52.071944964947377],
              [1.328976091124779, 52.0717794729023],
              [1.329052997898465, 52.072420390362772],
              [1.328427384209199, 52.072172744451102],
              [1.328824550325335, 52.071782857642198],
              [1.328158360805893, 52.072127282221281],
              [1.327656781658947, 52.072016437356218],
              [1.327657423150474, 52.072691890164094],
              [1.328287920747964, 52.072318796281749],
              [1.328955093638175, 52.072634524061378],
              [1.328874306316781, 52.072902139497863],
              [1.326962024771476, 52.074291835713275],
              [1.325864266184495, 52.075761931012174],
              [1.324657086199117, 52.075388579518389],
              [1.324693442623947, 52.074169727839902],
              [1.324250868198501, 52.075245346304051],
              [1.325004584081697, 52.075762823504057],
              [1.324337047066221, 52.075992136610921],
              [1.323764718480333, 52.077266591413981],
              [1.32088276957775, 52.079048716505817],
              [1.321228537522729, 52.079281810281309],
              [1.319758423854714, 52.079594890192112],
              [1.318993704702191, 52.080620205216164],
              [1.319548710522136, 52.080820423762589],
              [1.318957798060905, 52.080726450038206],
              [1.320348756748083, 52.084496308326749],
              [1.319344009352246, 52.086969270989705],
              [1.319679090104741, 52.086232190892119],
              [1.319981194857311, 52.087783278346116],
              [1.320514207856773, 52.088177489677015],
              [1.321755991041626, 52.088265692084619],
              [1.320707037701948, 52.088375322800616],
              [1.323737484085642, 52.088444536060507],
              [1.325762004282434, 52.088985500085826],
              [1.327212496461038, 52.090150661056008],
              [1.327064224136147, 52.091885346763476],
              [1.327634967519249, 52.092961125777514],
              [1.328719953918603, 52.093294732739231],
              [1.330644977018092, 52.093182745208104],
              [1.331472562596818, 52.093824014851528],
              [1.331094546877037, 52.093826612351506],
              [1.331351968309181, 52.094204286083617],
              [1.331939078967527, 52.094087841196576],
              [1.331950991389792, 52.094502140213301],
              [1.331216539152971, 52.094503128877342],
              [1.331848680057612, 52.094794650258336],
              [1.331221709380577, 52.094944601390189],
              [1.331834356044839, 52.095287941642979],
              [1.33164616517132, 52.096230468491967],
              [1.33077734691356, 52.096760520271189],
              [1.331906808975401, 52.097747624089372],
              [1.331620688385571, 52.098414997971147],
              [1.332164842787928, 52.097937298621851],
              [1.334647968178567, 52.098471435349637],
              [1.338266145566319, 52.099888072909302],
              [1.337264006457302, 52.100001918266891],
              [1.337097928950018, 52.100300736862863],
              [1.335519209361908, 52.100008171228623],
              [1.334994230770103, 52.100194828065561],
              [1.33585199892439, 52.100885581840778],
              [1.337553545808571, 52.100925521314444],
              [1.337449908859735, 52.101630011811523],
              [1.343325113663959, 52.101681946566245],
              [1.343213855368763, 52.101912660265121],
              [1.337978451902962, 52.101784126313738],
              [1.334857754140243, 52.101046852425021],
              [1.336574861403365, 52.10174474662476],
              [1.344083580692775, 52.101980611168749],
              [1.346514063600305, 52.102274948633386],
              [1.347835186072463, 52.102947954043891],
              [1.348277421413855, 52.10261248766561],
              [1.348654635997861, 52.102715091878594],
              [1.349368098997917, 52.103347791714818],
              [1.349022761919378, 52.103489691082125],
              [1.349110813308478, 52.103150037731631],
              [1.348451019388253, 52.102804527746784],
              [1.348234151918581, 52.103009467004654],
              [1.348939467321911, 52.103523916370193],
              [1.348469760631669, 52.103716916606544],
              [1.348555107924556, 52.103959135225608],
              [1.348990461634558, 52.104252563649929],
              [1.3500051834326, 52.103916101948258],
              [1.349119018882209, 52.104389219365345],
              [1.350474653601624, 52.105248296303891],
              [1.351115874081958, 52.105150904578359],
              [1.350598243783871, 52.105435459868758],
              [1.351283857935023, 52.107837221194863],
              [1.353309976826336, 52.109161082031207],
              [1.353276014325046, 52.110751342804463],
              [1.35246977164766, 52.111414686795428],
              [1.353979911071651, 52.11162353714569],
              [1.354567811081861, 52.112040321366187],
              [1.352407464289674, 52.111557670678074],
              [1.352107247487894, 52.111255911260152],
              [1.35227132740762, 52.112487958237651],
              [1.351947117390287, 52.111224490964702],
              [1.353059556291758, 52.110767398536225],
              [1.353089810031414, 52.109224913317512],
              [1.351209905379765, 52.108155924759814],
              [1.350296761373422, 52.105524984452458],
              [1.348382348469454, 52.104499207531234],
              [1.348068835682137, 52.103512450681109],
              [1.346582461108092, 52.102836947758007],
              [1.344170420733189, 52.102282152667378],
              [1.342468142150604, 52.102291797649464],
              [1.340664428081698, 52.102702741891989],
              [1.339634117012869, 52.102303832258869],
              [1.338286857264811, 52.103663284873797],
              [1.337206697425107, 52.103750558342874],
              [1.336536979060071, 52.103329711754903],
              [1.33638282651193, 52.102595648461694],
              [1.3336077132336, 52.101851249602511],
              [1.333418303893365, 52.102092260200394],
              [1.333400690421935, 52.101777959256012],
              [1.333015550154276, 52.101901287014122],
              [1.330925548023526, 52.100902686109265],
              [1.330033544515707, 52.099771246841343],
              [1.329989191131476, 52.098823605946521],
              [1.330475997505705, 52.098164053048322],
              [1.329521646582722, 52.097743121424323],
              [1.328219632864116, 52.095928902111254],
              [1.326164342963839, 52.095665869177068],
              [1.326255166384659, 52.095473525275317],
              [1.32605732636752, 52.095503397054898],
              [1.325724775341652, 52.095567652986176],
              [1.32522934009339, 52.094149755967258],
              [1.324612343503138, 52.094061042290882],
              [1.325041704419261, 52.093963475782651],
              [1.324898284178246, 52.093723780368528],
              [1.324018424856944, 52.09379178893105],
              [1.3247741914807, 52.09356718577822],
              [1.324391050666411, 52.093088713626976],
              [1.324662998859062, 52.092622329820855],
              [1.323564431356008, 52.092772960209317],
              [1.323118711087932, 52.092513011646261],
              [1.323764343709378, 52.092005504890118],
              [1.323059599508675, 52.092346486954568],
              [1.322633063092242, 52.092148055688845],
              [1.324290701221124, 52.091543634199574],
              [1.323962060638308, 52.091321509419622],
              [1.324984281959861, 52.090856680577929],
              [1.325058447554939, 52.090401275848478],
              [1.324526609551467, 52.090965839553796],
              [1.322357317585236, 52.091895900803216],
              [1.321944793643929, 52.091630514338867],
              [1.324343603801048, 52.090409763556885],
              [1.323874546511593, 52.090189152058144],
              [1.322018696577431, 52.090758685321511],
              [1.321083675994684, 52.090496340540959],
              [1.323808959926113, 52.089935566124772],
              [1.324755610727992, 52.090040161382831],
              [1.323932384399958, 52.08976928655715],
              [1.321867877866053, 52.090261959406547],
              [1.32073032861985, 52.090123149048253],
              [1.320565394129386, 52.090477675944015],
              [1.31955207217515, 52.08988833725855],
              [1.319045101926188, 52.090375723446996],
              [1.318356600615441, 52.090208044534187],
              [1.318673395077297, 52.089656764798669],
              [1.317316490519144, 52.089021324371039],
              [1.316052972174028, 52.086042936494415],
              [1.316147003480539, 52.085696708498858],
              [1.317373704184752, 52.085091024917965],
              [1.317349372072935, 52.084271434629891],
              [1.31475206984557, 52.083030510604942],
              [1.312181075681516, 52.080566473046872],
              [1.312292146787114, 52.079602763665136],
              [1.313726653866571, 52.077666418205638],
              [1.312941642019337, 52.077532905385794],
              [1.310896040007806, 52.078491660594395],
              [1.304086526242519, 52.078100091982073],
              [1.308871881152852, 52.078676272156791],
              [1.310474348022056, 52.078612346370022],
              [1.307533823124291, 52.079433400777397],
              [1.299476249803026, 52.078351677630224],
              [1.296667981365591, 52.078513974033342],
              [1.296609997097268, 52.077886002453809],
              [1.299762953156447, 52.078102599186487],
              [1.30037383736607, 52.077795866038628],
              [1.302374488116049, 52.078261442971545],
              [1.303810945314701, 52.078143800799815],
              [1.300294968038225, 52.077637079891943],
              [1.299723483190166, 52.077943608079131],
              [1.297394975944709, 52.07772281570174],
              [1.295320926043902, 52.077904093661047],
              [1.294757658273686, 52.07760955426447],
              [1.295088488855548, 52.077265713027153],
              [1.294489392681859, 52.076365965304689],
              [1.29459950563885, 52.075522825487447],
              [1.297245171076814, 52.075810343182482],
              [1.295894072162957, 52.076768274434642],
              [1.2976798525138, 52.075667755038964],
              [1.301952694027871, 52.076681293025523],
              [1.303022263775048, 52.076379671612081],
              [1.303001707942025, 52.076713934028952],
              [1.303587518204806, 52.076917859750132],
              [1.305291638301856, 52.07686734176329],
              [1.305503060612854, 52.076489046021813],
              [1.305716448010276, 52.076788859898841],
              [1.308039313316129, 52.076716433746689],
              [1.308655758376176, 52.07617025367734],
              [1.310675917457794, 52.077234158131361],
              [1.313854903252063, 52.075950303717129],
              [1.317394027157861, 52.076080007343684],
              [1.31899871203268, 52.072901187644561],
              [1.319701378544174, 52.072929049691695],
              [1.323129953030174, 52.070395791027394],
              [1.324194628619537, 52.068206211819657],
              [1.324075862814794, 52.067040307836315],
              [1.324002477777366, 52.0683699357592],
              [1.322972702027123, 52.070361553456507],
              [1.321557852431174, 52.071335978011717],
              [1.322669217530839, 52.070343134754282],
              [1.323344792330382, 52.068867895481993],
              [1.322352302640946, 52.068729509331554],
              [1.322521754284975, 52.067454735714612],
              [1.321592302780292, 52.066769511415586],
              [1.320040102991767, 52.066364474010996],
              [1.320096699965834, 52.065809730158513],
              [1.321067320956925, 52.064986363556336],
              [1.323437452829165, 52.06430158086112],
              [1.324288889790673, 52.063230609894497],
              [1.324493808622423, 52.062748126782488],
              [1.32611032755995, 52.062292332667923],
              [1.325063915542198, 52.063644948619732],
              [1.324536453839649, 52.064499909538384],
              [1.324039744723525, 52.06557256075579],
              [1.324073325940704, 52.066770550924367],
              [1.324490837141215, 52.064723571403285],
              [1.3247089193477, 52.064313112307197],
              [1.325244145294259, 52.063733399390394],
              [1.326199712369699, 52.062768303986729],
              [1.326537107661128, 52.063007914024631],
              [1.326795331557149, 52.062810838573618],
              [1.326450127099178, 52.06246621665764],
              [1.327107298320194, 52.06229654410685],
              [1.326425353175364, 52.062329303872126],
              [1.326408539087092, 52.062005084875757],
              [1.326743771236355, 52.061862497854499],
              [1.326608023353298, 52.062195524510322],
              [1.327286757939967, 52.06221771959693],
              [1.327729378426254, 52.061812159772408],
              [1.327429741404533, 52.061511224288871],
              [1.327913205887268, 52.061635172967655],
              [1.327111666756245, 52.061354717840771],
              [1.32811348705578, 52.061620517575001],
              [1.327749728036005, 52.06216416108871],
              [1.328355473792686, 52.061715312767703],
              [1.326988750883849, 52.061036195327652],
              [1.327795509835078, 52.061210370976937],
              [1.327913089555253, 52.060947112409622],
              [1.32821105454642, 52.061323646053062],
              [1.328610678698737, 52.061281768379111],
              [1.328493368309309, 52.061646655641773],
              [1.329193921700554, 52.061060208044815],
              [1.328078099610593, 52.060948744285554],
              [1.329485841143886, 52.060904446828445],
              [1.333773541955436, 52.058601997979075],
              [1.333804501079773, 52.057646824525712],
              [1.333141039722899, 52.056908287973798],
              [1.331862574290105, 52.056531636175457],
              [1.332695731946341, 52.05809914907492],
              [1.33151121016391, 52.056437245164403],
              [1.331846435246832, 52.055550813381082],
              [1.331562161436324, 52.054966132732574],
              [1.332088414218139, 52.054862196137549],
              [1.331938808732557, 52.054538137721721],
              [1.331688258662236, 52.054817755964272],
              [1.331610230213528, 52.054631982890285],
              [1.332064844788736, 52.053859997079279],
              [1.331920195159119, 52.052524837259298],
              [1.335050220944475, 52.048735946260877],
              [1.339579519469292, 52.04621055757039],
              [1.341531639725524, 52.044730459136346],
              [1.34345700201711, 52.044163113797666],
              [1.343857588405143, 52.043726302965503],
              [1.345836532965319, 52.043349874984848],
              [1.34735935461195, 52.042257840852258],
              [1.347118162182958, 52.04203624221784],
              [1.344862738947168, 52.041893479534131],
              [1.344597627878941, 52.041292993676741],
              [1.344390759324996, 52.041243169907894],
              [1.344004638189296, 52.041223494423747],
              [1.342867288787344, 52.040876235935748],
              [1.344226572200222, 52.041203626241938],
              [1.34484352909025, 52.04114839320021],
              [1.344754560390094, 52.041558365493053],
              [1.345176466508128, 52.041758643811576],
              [1.34707383933197, 52.041853118051193],
              [1.347776496914828, 52.039605233323705],
              [1.346115406471271, 52.038494887838304],
              [1.344314129362289, 52.038493909260389],
              [1.343256143017504, 52.04057557463841],
              [1.342344455599791, 52.040828121423878],
              [1.343172559952426, 52.040413351758417],
              [1.343601330545085, 52.039534117967847],
              [1.342715997740921, 52.03978771821555],
              [1.342191925084259, 52.040582409148691],
              [1.341283701828242, 52.040725118221495],
              [1.342164298855467, 52.040192838634816],
              [1.341798394711053, 52.039433310179128],
              [1.342359462019531, 52.039563988831304],
              [1.343585805544087, 52.039150499936767],
              [1.343795306185107, 52.038460078527642],
              [1.344524093500521, 52.03808319775554],
              [1.343117735776284, 52.037081600580144],
              [1.34108216478777, 52.03812247687101],
              [1.34125123886805, 52.039176313107347],
              [1.339831919277513, 52.038880202005124],
              [1.341929973626053, 52.03984781212219],
              [1.341833166988043, 52.040250809030454],
              [1.340964111489072, 52.040605569842775],
              [1.341802902821138, 52.041838271583671],
              [1.340804682936771, 52.042710274651654],
              [1.339754159171872, 52.045208130831327],
              [1.338678448347326, 52.045668573024166],
              [1.33800358244198, 52.044853935563332],
              [1.338638972751735, 52.04357491087498],
              [1.338457466935924, 52.042940560758701],
              [1.337967940544692, 52.042948146351883],
              [1.338082985218761, 52.040774549658764],
              [1.33889003358389, 52.041504488216042],
              [1.339509932642563, 52.040663921553161],
              [1.338195950592621, 52.038729630287982],
              [1.338405587324942, 52.03717935599763],
              [1.338086393109887, 52.036321353036065],
              [1.337239124474065, 52.033308885340205],
              [1.337960928995862, 52.032056192966301],
              [1.33738474706894, 52.033266980382308],
              [1.338146480144557, 52.036319668868622],
              [1.339489772428788, 52.03859489432606],
              [1.34104460279717, 52.038928537414144],
              [1.34039964565991, 52.038122958633203],
              [1.342923900565002, 52.036868542565067],
              [1.341162934332349, 52.035530710089851],
              [1.339824322235731, 52.033245456673093],
              [1.339006689633351, 52.033114849446008],
              [1.339616776968286, 52.032750359764535],
              [1.339531853452292, 52.031181455438869],
              [1.340899301018994, 52.028967814867705],
              [1.343291524186424, 52.027201753970395],
              [1.342401395876875, 52.027094817044855],
              [1.342319851397332, 52.027311198528679],
              [1.338988478085469, 52.026592659495421],
              [1.336995671399684, 52.02520827881311],
              [1.337300589987311, 52.024604207146716],
              [1.337667674769002, 52.02416926451091],
              [1.339447712995477, 52.024284270115821],
              [1.340173227694902, 52.024530815824804],
              [1.339968594595312, 52.02526696509193],
              [1.344403101821182, 52.026447027529066],
              [1.34752338811921, 52.025491267727709],
              [1.349534065180739, 52.025199311531821],
              [1.353220893682328, 52.023828822263845],
              [1.356345708987789, 52.022140550481467],
              [1.358327188394324, 52.020139437145865],
              [1.359271178039927, 52.01838017360128],
              [1.359589972886035, 52.016721498354798],
              [1.359330245033412, 52.014696174395816],
              [1.358011875087478, 52.01266508353617],
              [1.356837390597587, 52.012358601006618],
              [1.356084527989847, 52.012523983505353],
              [1.355728222132363, 52.012846249817038],
              [1.356314878691732, 52.012952743856481],
              [1.355637116036211, 52.013011646398603],
              [1.355709710431275, 52.013280308211279],
              [1.357598929856781, 52.013884815358985],
              [1.35808213760937, 52.014828034232131],
              [1.357773819272793, 52.015712886992816],
              [1.358813737382374, 52.015837017264417],
              [1.355744634725115, 52.016101757689924],
              [1.354758939549138, 52.015319463107666],
              [1.355268882547049, 52.014790445938992],
              [1.354742458297806, 52.014534721283852],
              [1.354804841775241, 52.014121898637306],
              [1.35427127023663, 52.014296308294533],
              [1.354234579527306, 52.01368933220261],
              [1.35528818607364, 52.013588242728957],
              [1.35498850931974, 52.013070613288356],
              [1.356152567937222, 52.012206339338071],
              [1.357821655795757, 52.012246874051179],
              [1.358745498698345, 52.012709807634856],
              [1.359035493574584, 52.011386546944813],
              [1.358594195991486, 52.010010403630311],
              [1.359021177288753, 52.009056501319996],
              [1.361545337857352, 52.006177265915575],
              [1.36397351267912, 52.004469992572872],
              [1.365402822881766, 52.003727558738859],
              [1.369051101710971, 52.002784883224642],
              [1.368364723729435, 52.002668716061642],
              [1.368252596525199, 52.002301354278565],
              [1.369326706663512, 52.002496365457702],
              [1.373925012162162, 52.001651353770029],
              [1.380347178702239, 51.998461987492227],
              [1.381813662756263, 51.997368394991348],
              [1.379268147215502, 51.996461127576374],
              [1.379258785161359, 51.996665571120786],
              [1.378121174950026, 51.996380731065102],
              [1.378314044891987, 51.995884994793407],
              [1.379213399244462, 51.996218050012708],
              [1.379687583681604, 51.995975072047308],
              [1.379603606029746, 51.996446096481307],
              [1.381605990814673, 51.99706855087112],
              [1.382843957830187, 51.996616522369685],
              [1.384752605098942, 51.994703372822698],
              [1.386367029360605, 51.991539428204874],
              [1.38796400167345, 51.990210839506737],
              [1.387949653034594, 51.989654492776964],
              [1.385865184659791, 51.990199298739228],
              [1.38401451024841, 51.989827100284288],
              [1.383782215346627, 51.989528867609174],
              [1.38624776281766, 51.989824911012541],
              [1.388825180945575, 51.988940299938804],
              [1.389297512882167, 51.989272084049752],
              [1.388588015932316, 51.989214267401998],
              [1.388555241995315, 51.989455364631596],
              [1.389653311130207, 51.989719654959103],
              [1.389738246018612, 51.990168732428657],
              [1.391434731252213, 51.989310339133191],
              [1.390365940360796, 51.986831672162452],
              [1.390207633087573, 51.984313272347116],
              [1.388867968733697, 51.979441760972804],
              [1.384080495310143, 51.977050309467145],
              [1.382158225609902, 51.975662868195215],
              [1.375913683381708, 51.96970887677179],
              [1.371852977379692, 51.966074632907386],
              [1.368897637730575, 51.96402581249307],
              [1.352027799227752, 51.959695692937899],
              [1.347248578548915, 51.958159715822191],
              [1.344891562456827, 51.956909076363786],
              [1.342959405430569, 51.955360294155348],
              [1.335317282552285, 51.947547906271431],
              [1.325581075487093, 51.939384127686104],
              [1.322732734900017, 51.935776921685303],
              [1.31901829103238, 51.932374883561515],
              [1.317850984903945, 51.933591484681166],
              [1.317699501588225, 51.934909840817092],
              [1.318053076997342, 51.936860646465121],
              [1.319327651397604, 51.938434680020279],
              [1.31999002935555, 51.940466708761015],
              [1.318374540176868, 51.946533149037819],
              [1.317073882677561, 51.946796495046897],
              [1.316402479534075, 51.948328283801629],
              [1.31753691992472, 51.948868335974645],
              [1.320302212380895, 51.949455894772839],
              [1.319655218672755, 51.950529198777247],
              [1.318891647600999, 51.950399640183015],
              [1.317117266212724, 51.949295712185076],
              [1.316260110940837, 51.950875011071354],
              [1.313134650660601, 51.95018057518422],
              [1.313364874319477, 51.949828706095253],
              [1.312179589417586, 51.950908134260992],
              [1.313066065510217, 51.950257160335788],
              [1.316049171782031, 51.950935823685661],
              [1.315419812122435, 51.951480635413915],
              [1.313598680055558, 51.951991564303022],
              [1.313472842950097, 51.952357582551983],
              [1.296008305018496, 51.960116565129113],
              [1.292335679954494, 51.961812303267507],
              [1.292876560278149, 51.961881711221281],
              [1.292578211719033, 51.962836264706922],
              [1.293759232518207, 51.964069604568692],
              [1.293417122210525, 51.964923749392106],
              [1.292034468576957, 51.965467929483758],
              [1.289548239168356, 51.965299126523796],
              [1.287756101577108, 51.966177600503435],
              [1.285953786302165, 51.966540057552344],
              [1.285902249567479, 51.966930050923601],
              [1.284018302957604, 51.967630245063553],
              [1.282825955231906, 51.968887646207897],
              [1.28212685199595, 51.970279680824184],
              [1.283092693383342, 51.970503672956504],
              [1.282080706172921, 51.970504926024951],
              [1.281783556872957, 51.970842406297869],
              [1.280990265418927, 51.973715721069375],
              [1.280772109351106, 51.981089919679775],
              [1.283313860399104, 51.989807328754601],
              [1.282321305233843, 51.990916131144544],
              [1.279521295095659, 51.991675067600447],
              [1.280285638901081, 51.993101839296443],
              [1.279599075966803, 51.992479685228552],
              [1.276772823088974, 51.993459645152477],
              [1.276908989659242, 51.992830752516063],
              [1.276476345700515, 51.992751957851581],
              [1.275567844844685, 51.993113122130474],
              [1.272187469359887, 51.99427704098246],
              [1.268696521904978, 51.994834625490405],
              [1.270709476204223, 51.994785889360998],
              [1.272943746283445, 51.994156231017612],
              [1.273888735009838, 51.993780598996302],
              [1.275406230468093, 51.99330071765047],
              [1.275167167002938, 51.993658147511461],
              [1.275551047351799, 51.99406659466603],
              [1.271469294877277, 51.995834183631217],
              [1.268724714264627, 51.995501214727994],
              [1.269009175946742, 51.996685049096598],
              [1.268512162194138, 51.996563041856554],
              [1.267736398391873, 51.99543512326445],
              [1.268260726416092, 51.995072485179023],
              [1.266498632205065, 51.995304893686885],
              [1.266284991256719, 51.995672391897422],
              [1.264126185243072, 51.995799749552845],
              [1.263546079654934, 51.996337504123382],
              [1.263376898530394, 51.997856823025565],
              [1.262796266712307, 51.998427866589068],
              [1.260941410436625, 51.998508960730199],
              [1.25786829987039, 51.999792105781573],
              [1.258099137562029, 52.001685291745154],
              [1.257575755707519, 52.001662004887443],
              [1.258339183977359, 52.002963018433896],
              [1.257855491889921, 52.002964716959085],
              [1.256731424466625, 52.00205503539955],
              [1.255276527561001, 51.999257617932592],
              [1.256600533763201, 51.997845771823656],
              [1.257006715540009, 51.997700516963818],
              [1.256899080195574, 51.998324995695469],
              [1.258176228945738, 51.99737223302678],
              [1.257383752456156, 51.997414858319537],
              [1.258120392052321, 51.997085064785374],
              [1.257642148717926, 51.996920219366032],
              [1.256605542336971, 51.997072131347714],
              [1.256804861871561, 51.997429081873378],
              [1.256148048094642, 51.997112673049251],
              [1.257240345577231, 51.996925045666664],
              [1.256859361858752, 51.996534448618299],
              [1.254899184921382, 51.99721108102041],
              [1.253081084154148, 51.997436740902295],
              [1.252399029330809, 51.998097779119782],
              [1.248529858195799, 51.998814455989425],
              [1.246119598796365, 51.99957711498174],
              [1.229975862473986, 52.006334299403598],
              [1.226812217774867, 52.006561390368056],
              [1.224954875093504, 52.006066355939844],
              [1.219351515369374, 52.005866296318146],
              [1.215419907081669, 52.006500831652161],
              [1.210293972979578, 52.011481386065967],
              [1.207406212026858, 52.012535075551263],
              [1.202975315933038, 52.013515571785916],
              [1.200357839891988, 52.014827054146792],
              [1.199849819047839, 52.014734736339648],
              [1.197589356645169, 52.015372687131986],
              [1.192901223172129, 52.015266109244628],
              [1.191241675122538, 52.01478496388129],
              [1.191074043295491, 52.015030549238091],
              [1.190739040795515, 52.014768010861253],
              [1.188301289358209, 52.014985169292636],
              [1.184931271705796, 52.016790669986591],
              [1.182424686761276, 52.019529693880642],
              [1.178766068240614, 52.021508302915663],
              [1.180956852919873, 52.022591360915683],
              [1.18281844692088, 52.022692139214989],
              [1.183073600697423, 52.024457062249205],
              [1.184779358090173, 52.024312889701577],
              [1.186580159135585, 52.021979670562871],
              [1.190055235341672, 52.023016994067461],
              [1.190663273109246, 52.022486055163242],
              [1.191487271787858, 52.023104092688882],
              [1.193827668109724, 52.023703475615598],
              [1.195403525139619, 52.024518740034239],
              [1.201648093376035, 52.024316680912435],
              [1.204201112952036, 52.023442244267059],
              [1.209446647585979, 52.020754912698344],
              [1.212126903854819, 52.022676689666397],
              [1.211280287488696, 52.023150393269212],
              [1.215002227616121, 52.025606713719363],
              [1.218312021075258, 52.023782206499114],
              [1.219338399443284, 52.02286912021269],
              [1.223579992102786, 52.029420366874554],
              [1.212033754224817, 52.034166469610852],
              [1.204649603286321, 52.035824752131546],
              [1.201913510698292, 52.037391314555897],
              [1.203265822765281, 52.038069553317293],
              [1.202070531250293, 52.038710068195599],
              [1.203402253801464, 52.039691065793008],
              [1.202895462226083, 52.039905421930222],
              [1.203427050372607, 52.040465678048136],
              [1.204009624786752, 52.040270842959252],
              [1.204401401967778, 52.040813314027474],
              [1.205716514493656, 52.04068487243547],
              [1.205924856459659, 52.041190960753895],
              [1.209182313820366, 52.048462945592441],
              [1.207018199749267, 52.048675712012482],
              [1.203827464468442, 52.04946411601631],
              [1.205675029914871, 52.052203778088952],
              [1.206166352926791, 52.053726582134068],
              [1.204864894610714, 52.058410131341496],
              [1.205188505117855, 52.062194105595644],
              [1.206027158227929, 52.063003222175873],
              [1.205505848118255, 52.063649694942697],
              [1.206424314244792, 52.064004236510449],
              [1.20351958783994, 52.067375108679435],
              [1.202263858796045, 52.067936313006108],
              [1.201809760451419, 52.067484569583662],
              [1.200993137948474, 52.067737916923988],
              [1.200183749032177, 52.068520809194595],
              [1.199484633113809, 52.069152298320745],
              [1.200085160794517, 52.069488545730358],
              [1.197335191656586, 52.071136294853318],
              [1.196616261659956, 52.072002147839839],
              [1.196584580140016, 52.073151541543304],
              [1.190963648687056, 52.074089157820715],
              [1.192122768930132, 52.075114537938028],
              [1.186795689739446, 52.077271663229077],
              [1.188208801374219, 52.078706629244124],
              [1.189972802540871, 52.079309126285771],
              [1.189863397526666, 52.08043543738389],
              [1.171331163794889, 52.081232725474919],
              [1.170951482911202, 52.080798648256966],
              [1.165803097812578, 52.080616968117404],
              [1.164620820553324, 52.079422870637444],
              [1.164226753508848, 52.079485623593833],
              [1.164554517888577, 52.080404090447331],
              [1.165555496023944, 52.080995971980215],
              [1.164965286355805, 52.081051407586777],
              [1.165680837503204, 52.08167344299514],
              [1.165263850031906, 52.081866327781306],
              [1.16565869726729, 52.08232969510901],
              [1.162303643570814, 52.082757998400581],
              [1.163559936715155, 52.084213652644117],
              [1.16336972177928, 52.085053397129663],
              [1.164101957668182, 52.085869262743685],
              [1.159583166336999, 52.087084230949976],
              [1.159063768488726, 52.087171913188662],
              [1.158701896213621, 52.086782289318784],
              [1.158145762143494, 52.086990571629123],
              [1.159523074026827, 52.087969044371732],
              [1.157807811925241, 52.088737254407278],
              [1.160479821009608, 52.091485174834396],
              [1.162731286448389, 52.092268369023351],
              [1.161202395981653, 52.092555845099369],
              [1.160262880045848, 52.093063129574894],
              [1.161478218452793, 52.094493823122775],
              [1.162652013345732, 52.094634995117481],
              [1.164603959905602, 52.097144932373446],
              [1.166390239301432, 52.098365069053784],
              [1.167199493496925, 52.099789532969247],
              [1.165194476040079, 52.103563303825418],
              [1.165057741334766, 52.106003424634281],
              [1.164141908689029, 52.108765768681963],
              [1.165482765212883, 52.113298643204544],
              [1.169061423312563, 52.11318983900027],
              [1.170540833753702, 52.113615917003045],
              [1.170338857940338, 52.114289599277093],
              [1.171860369793483, 52.115533872540908],
              [1.171194943450568, 52.117272319084798],
              [1.172307748845534, 52.117430332326357],
              [1.172239201922212, 52.118151689990036],
              [1.176577718938341, 52.119764318370336],
              [1.177668780277576, 52.120477792128078],
              [1.177766247820653, 52.122113853091626],
              [1.176449659665085, 52.125972664561623],
              [1.176601664252111, 52.126938108726513],
              [1.177921829928972, 52.127870251806115],
              [1.175604081917123, 52.129142422558168],
              [1.173276025502869, 52.128277875673476],
              [1.172421746660154, 52.127546293254049],
              [1.171040343138621, 52.12776605599565],
              [1.170172761730329, 52.127607726157756],
              [1.170097804932812, 52.129247529056627],
              [1.170456640859773, 52.129299931704871],
              [1.169438055424722, 52.131234038247136],
              [1.171736958173443, 52.13224244912859],
              [1.175322154690198, 52.132450779049748],
              [1.176748883152912, 52.135719375355897],
              [1.176671706015735, 52.136914045703115],
              [1.186989196781556, 52.139592636860016],
              [1.191372014767619, 52.13996868075904],
              [1.192154181688395, 52.144137759720131],
              [1.193650315381793, 52.146943795286766],
              [1.193943301037996, 52.14736036738578],
              [1.195451432159977, 52.147270015189477],
              [1.196601416671456, 52.148567227834945],
              [1.196637684909536, 52.154987899666708],
              [1.201042394716447, 52.155422365913708],
              [1.20221690164959, 52.15579338571542],
              [1.205173637778057, 52.158133274198192],
              [1.211216767477767, 52.160031976006849],
              [1.211691554691299, 52.16053259220746],
              [1.21539500973621, 52.161554953334097],
              [1.215012758960434, 52.164608932025445],
              [1.213935375234893, 52.166522546803748],
              [1.216035652133829, 52.166400499127761],
              [1.219280773619575, 52.166739117527136],
              [1.220487028655849, 52.167239504481373],
              [1.219782764681072, 52.167735425393595],
              [1.220853775688791, 52.169278304713508],
              [1.224035121555876, 52.168974570066105],
              [1.224073818933538, 52.169388132935374],
              [1.22696320803539, 52.169738970942234],
              [1.228750095051592, 52.170417646017953],
              [1.237104700487274, 52.169644346505407],
              [1.239812476733582, 52.170412682411431],
              [1.241446003395113, 52.175856801235945],
              [1.243226643766274, 52.179999917706979],
              [1.244202116520631, 52.181320373352712],
              [1.247590409351831, 52.183463876011317],
              [1.247472067963673, 52.184750579159093],
              [1.251226720540557, 52.186053741320229],
              [1.254142895325418, 52.188114745833296],
              [1.253985750356178, 52.190029409856095],
              [1.255127379853226, 52.189971808463973],
              [1.25542617812149, 52.191136375327666],
              [1.255154771289412, 52.194530111411638],
              [1.25692659708947, 52.19490299216649],
              [1.257210017692792, 52.197056420325467],
              [1.256700003361189, 52.198347658484508],
              [1.254522021891347, 52.198227025174091],
              [1.253904233995158, 52.19790500483191],
              [1.252569227705244, 52.198143310219315],
              [1.250558459279963, 52.199269952985119],
              [1.250645918554234, 52.199746021440866],
              [1.249221140231876, 52.200319540505902],
              [1.247717493489437, 52.20005337837366],
              [1.24707157510859, 52.200509176904077],
              [1.245653897873969, 52.200137189975699],
              [1.243022366674371, 52.200361605578834],
              [1.242233532825257, 52.200323042569707],
              [1.241954251181022, 52.201090712730746],
              [1.24165390425429, 52.204362879764382],
              [1.242654786402225, 52.205100741986527],
              [1.243008148352459, 52.207237880951411],
              [1.242705855262115, 52.208192359811882],
              [1.243954973860985, 52.210626839942599],
              [1.241591404316906, 52.212126381031332],
              [1.244305978356629, 52.21315167915747],
              [1.243496931179662, 52.214385423316941],
              [1.248317159878508, 52.215810481643544],
              [1.243514401810072, 52.217677628088786],
              [1.245369675100332, 52.21928326497936],
              [1.24502214979746, 52.220340624652614],
              [1.246051027309099, 52.221335817158618],
              [1.246052149000573, 52.22231432807488],
              [1.24884472262872, 52.225820611722874],
              [1.246227795556256, 52.226807366030265],
              [1.247421954183433, 52.228879969495104],
              [1.254132125095041, 52.230467701500636],
              [1.256289518349496, 52.230645589445594],
              [1.257808024908435, 52.230359912406087],
              [1.258049701854921, 52.230859595959075],
              [1.260232085182071, 52.231357806426836],
              [1.268094900382082, 52.234107281273126],
              [1.269702175336409, 52.235007996883446],
              [1.270432335631039, 52.235833185301551],
              [1.274059997347324, 52.237232725781524],
              [1.277367752574741, 52.238264198808075],
              [1.279766070315586, 52.238285665560241],
              [1.281990741005558, 52.239645728280458],
              [1.286578974013091, 52.2404740160922],
              [1.288521903365689, 52.24192275601326],
              [1.293485297250601, 52.24759079868835],
              [1.293337451305429, 52.250340804035844],
              [1.291821456096606, 52.253079533188163],
              [1.292433252074055, 52.254828882489512],
              [1.295249751204316, 52.256089439030411],
              [1.297269596871066, 52.256369368413573],
              [1.300986769320687, 52.258997784988445],
              [1.301480564187315, 52.258798687564671],
              [1.302056484065902, 52.259172007981554],
              [1.303523805977433, 52.259410636081959],
              [1.309403697365833, 52.259639749167746],
              [1.311047286494101, 52.258958642782225],
              [1.313911823281955, 52.258966338519429],
              [1.316692896820566, 52.258602158180395],
              [1.322177336651967, 52.260109018633088],
              [1.324591945322057, 52.263327424246995],
              [1.326138495673103, 52.263798286011777],
              [1.326421629773473, 52.264623154341365],
              [1.328916439582189, 52.265403614288097],
              [1.333668998712805, 52.265424092659551],
              [1.338502650964027, 52.26626954267639],
              [1.339736112030971, 52.265907240079059],
              [1.338397985761338, 52.270200233461573],
              [1.336999378273805, 52.270473653598081],
              [1.338137149112478, 52.271878720343629],
              [1.338219562412707, 52.272740721857019],
              [1.337281831469247, 52.274074997285041],
              [1.337718041996416, 52.274377449587419],
              [1.336830218583466, 52.276649292563732],
              [1.337441221455945, 52.276994470531832],
              [1.338966628510158, 52.276688677665526],
              [1.340615861877763, 52.280405120355162],
              [1.341237066268684, 52.284429478295365],
              [1.339470044986411, 52.287393596333025],
              [1.336454142563761, 52.287445645954911],
              [1.333861122031996, 52.287875113075998],
              [1.332655875207383, 52.289910346548623],
              [1.332578581149251, 52.290931558604584],
              [1.330370814018957, 52.290947129227263],
              [1.330045797458603, 52.29292870224451],
              [1.32896252001868, 52.294501753556496],
              [1.328231691897478, 52.294516974664909],
              [1.328270189303364, 52.296633090541334],
              [1.327606069282184, 52.29747927536296],
              [1.327559360430984, 52.29846004537243],
              [1.331708975009374, 52.298394213530827],
              [1.33610493734301, 52.297488418967859],
              [1.336653837918387, 52.297062767994476],
              [1.338410094225425, 52.296813421095813],
              [1.339896658239244, 52.296060818069854],
              [1.344229357527589, 52.294963133251443],
              [1.347264157812534, 52.293385853300087],
              [1.347642662416742, 52.292278732719232],
              [1.346523282376039, 52.290934389913083],
              [1.346998115324956, 52.288810003090077],
              [1.344977875716808, 52.28603955063425],
              [1.344924928901929, 52.283411174066003],
              [1.344400075179398, 52.282070641492645],
              [1.34611387624124, 52.280080215242187],
              [1.349327856263602, 52.279822619379694],
              [1.349337306318235, 52.279287200622875],
              [1.35000197006757, 52.279039877191956],
              [1.350542025278555, 52.278054972831484],
              [1.350418885721169, 52.27663739761433],
              [1.352718614873977, 52.276470426185973],
              [1.353516341333566, 52.2754233190253],
              [1.355625721870281, 52.274058284446824],
              [1.356304424629507, 52.275433068678637],
              [1.355669992458355, 52.27631185712147],
              [1.356200855060774, 52.277185376463144],
              [1.355448573780876, 52.278755567740617],
              [1.359648325907918, 52.278931045455089],
              [1.363005025096699, 52.277810967203528],
              [1.36719402245429, 52.277130236939456],
              [1.37104124950538, 52.276063379399382],
              [1.371936338991016, 52.27539651218693],
              [1.375971380947526, 52.276069894176096],
              [1.376279467589634, 52.275435980455264],
              [1.381469438938834, 52.279797015037005],
              [1.384146876077021, 52.280294068103949],
              [1.384678433270427, 52.280745617503307],
              [1.386685189095562, 52.280715906020355],
              [1.388663960465114, 52.281318358105374],
              [1.387975269362374, 52.281652036790518],
              [1.390758043003563, 52.28304533828905],
              [1.393827816490857, 52.28333752146925],
              [1.401614166493938, 52.285678421167397],
              [1.398032837263599, 52.287709993576811],
              [1.391238289216212, 52.29072968307117],
              [1.387124168182452, 52.291634861661514],
              [1.384109131930167, 52.294085955598703],
              [1.382235501170066, 52.299332024709678],
              [1.383269481136517, 52.300001228582367],
              [1.380693539754522, 52.303526128856873],
              [1.38225860985516, 52.305482481322379],
              [1.384239891870245, 52.309352531171371],
              [1.383990913048696, 52.310646738136498],
              [1.381955922827287, 52.310401076131711],
              [1.380215697748811, 52.311249620614724],
              [1.380516856533214, 52.312062160762963],
              [1.379276193681899, 52.313247150194101],
              [1.376993494948917, 52.3135652330512],
              [1.375004832622814, 52.313100465718072],
              [1.372989690851116, 52.313174272135143],
              [1.370986261494296, 52.313710907716377],
              [1.367292570202115, 52.317845735199633],
              [1.365028415392172, 52.318412190439076],
              [1.360344044929596, 52.320373272612827],
              [1.357445297984157, 52.322136773854595],
              [1.357978453691482, 52.323012920377764],
              [1.359598271117012, 52.330379758526306],
              [1.3608316815753, 52.333265926277257],
              [1.362130778939725, 52.334345994723613],
              [1.357374009009253, 52.337254275191988],
              [1.35988140935037, 52.339802905319011],
              [1.361947073719281, 52.341287474245078],
              [1.365212636321534, 52.342289000304561],
              [1.367383078074015, 52.344703178153509],
              [1.369474974481614, 52.346313688424374],
              [1.374644891465265, 52.347725580185738],
              [1.37740672171321, 52.347696958637492],
              [1.380078196677272, 52.348600852488005],
              [1.383792047186576, 52.352168554455126],
              [1.387679980176473, 52.354221932887945],
              [1.393758751749699, 52.355378466094962],
              [1.397696283200427, 52.355465771346758],
              [1.400384949294557, 52.357499286476063],
              [1.406987395090305, 52.360886807260378],
              [1.409478481287035, 52.363244218454184],
              [1.417006562149891, 52.365716731564511],
              [1.421025412431731, 52.367849795342508],
              [1.422182116699016, 52.368822723908146],
              [1.426174056213033, 52.367679804610866],
              [1.431418640350645, 52.367193800798461],
              [1.436771282640311, 52.366227721662554],
              [1.441199305771887, 52.364068730509857],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000206",
        LAD13CDO: "42UH",
        LAD13NM: "Waveney",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [1.672872481616878, 52.313897780745791],
              [1.66716020785839, 52.311643261220382],
              [1.667127049726807, 52.312962939346136],
              [1.666020151581241, 52.312994641215916],
              [1.667165605187151, 52.313198305904962],
              [1.666799381346573, 52.313366199358462],
              [1.667539743110575, 52.314473841319767],
              [1.668583285714595, 52.315112414373104],
              [1.670330531381564, 52.315103045833638],
              [1.672872481616878, 52.313897780745791],
            ],
          ],
          [
            [
              [1.666622726788496, 52.316126816237272],
              [1.666826010681423, 52.315816472119678],
              [1.668381624007358, 52.315725838318663],
              [1.668591722003028, 52.315249773527526],
              [1.66761803989816, 52.314887869652779],
              [1.666409955386572, 52.313537511139494],
              [1.666741258415232, 52.31443749516334],
              [1.665402070398924, 52.315511718259344],
              [1.666622726788496, 52.316126816237272],
            ],
          ],
          [
            [
              [1.666622726788496, 52.316126816237272],
              [1.659314326104293, 52.32168226054479],
              [1.658481456423398, 52.323666263792909],
              [1.659145153491314, 52.322139947464656],
              [1.663872270314505, 52.318719127506448],
              [1.666622726788496, 52.316126816237272],
            ],
          ],
          [
            [
              [1.682348777746201, 52.482763621300009],
              [1.681218378625269, 52.474314991744322],
              [1.685515926432799, 52.473303130798676],
              [1.686929837054803, 52.473266214751291],
              [1.688520976490167, 52.472812687836679],
              [1.688982179663714, 52.472146167608074],
              [1.688881993977507, 52.471354157151083],
              [1.691075520906452, 52.470074953133363],
              [1.696156678117685, 52.470932517253161],
              [1.696054608759386, 52.472051065212128],
              [1.698591248624391, 52.472071528284786],
              [1.69895431740961, 52.471343276315075],
              [1.701769755014207, 52.470948376282273],
              [1.702978878202966, 52.470859216422113],
              [1.703100339944198, 52.47118282090247],
              [1.703397634442482, 52.470952230109475],
              [1.703426547256021, 52.471243655402304],
              [1.703921909420363, 52.471161565522607],
              [1.703564139455233, 52.471618923778827],
              [1.703937134106859, 52.471675591856048],
              [1.704318703536952, 52.471186004383291],
              [1.704231138397458, 52.471721243052848],
              [1.70461021579619, 52.47120204982469],
              [1.704565184924651, 52.471770135432187],
              [1.704797918713216, 52.471244736252245],
              [1.704893237038344, 52.471765245919897],
              [1.705268042458174, 52.471177823794093],
              [1.706354765317078, 52.471244480367538],
              [1.705901316445563, 52.472070037241849],
              [1.70659482496591, 52.472032092017677],
              [1.705713329609482, 52.472199162530416],
              [1.705837517944407, 52.473307926912469],
              [1.705382805363272, 52.473278113454505],
              [1.70514343402838, 52.473759647164421],
              [1.706974880175358, 52.474536020906655],
              [1.709700629578551, 52.474123958629086],
              [1.710311060490465, 52.474865753869707],
              [1.71095972679884, 52.47476712730267],
              [1.711407498153359, 52.474399565816846],
              [1.710887148503073, 52.473450764097329],
              [1.711648218374132, 52.473427757606338],
              [1.712265941383374, 52.474168414520967],
              [1.712705886489607, 52.473760616982489],
              [1.713585212453272, 52.473637637204469],
              [1.713628788036107, 52.473001231720005],
              [1.715640430206964, 52.47310079298088],
              [1.716172379246991, 52.473469941949276],
              [1.716020789826054, 52.473034861636769],
              [1.716795319238041, 52.472874676743835],
              [1.717285693347072, 52.473065231602824],
              [1.716887587709476, 52.473706496285956],
              [1.719748438422898, 52.474774101241209],
              [1.719300645554663, 52.475367466783283],
              [1.721190376219883, 52.475522043167594],
              [1.721315631822735, 52.476603777848737],
              [1.721780560019575, 52.476788819760465],
              [1.723673780362809, 52.476792133568594],
              [1.724434804448644, 52.475792203984888],
              [1.723263886142729, 52.475689687520031],
              [1.723327318329732, 52.47539445598634],
              [1.725642044499595, 52.475575145125745],
              [1.72584682285391, 52.474739360507357],
              [1.728126870726649, 52.474928290033446],
              [1.728356192612446, 52.474435330339134],
              [1.729612501737386, 52.474450548625533],
              [1.730093951886016, 52.474047670434587],
              [1.73141757296356, 52.474231647141139],
              [1.738283242660932, 52.473015282343944],
              [1.739101666564049, 52.472626889311229],
              [1.739092746227676, 52.470890254280555],
              [1.739702945881344, 52.470843056891603],
              [1.740263408334697, 52.4712336842871],
              [1.739997038348236, 52.471913137232846],
              [1.740645204844499, 52.471931295656937],
              [1.74049117310654, 52.472250994337408],
              [1.742354767374282, 52.472098432093517],
              [1.744867573872568, 52.472634963815182],
              [1.752819783110456, 52.472526557366891],
              [1.750511173718526, 52.472555790176948],
              [1.750743912006621, 52.471725364638381],
              [1.752438363109138, 52.471645474002344],
              [1.752768924489837, 52.472206153727427],
              [1.753241983838052, 52.471603756531934],
              [1.756172554230856, 52.471527712507104],
              [1.751491570767831, 52.471303150592938],
              [1.750314267229119, 52.469931020324687],
              [1.749796517816173, 52.469870097873127],
              [1.747947936938566, 52.468258395897763],
              [1.745208286966629, 52.465258215863642],
              [1.741345912776573, 52.459175896160573],
              [1.735166382221672, 52.449486030503358],
              [1.733396915461766, 52.444809743273353],
              [1.729983909962045, 52.434634582706757],
              [1.729180917151589, 52.428895095200019],
              [1.728845332464661, 52.424592625250988],
              [1.729136626351941, 52.421318268511136],
              [1.731238968516884, 52.418445441531368],
              [1.732252316276368, 52.413517471428122],
              [1.73091802514832, 52.407336035099597],
              [1.728506283912163, 52.400162290820703],
              [1.724796955948549, 52.393230934472662],
              [1.711962013557736, 52.377067434331956],
              [1.70467977419049, 52.365968910967844],
              [1.701233045073729, 52.362339657656761],
              [1.699962642607073, 52.359904090212169],
              [1.697057461416778, 52.356011313484586],
              [1.691951625129233, 52.349064247355535],
              [1.689369735459122, 52.342724434501861],
              [1.686376780673608, 52.332742786504127],
              [1.684334954359431, 52.328725573564867],
              [1.677450614478863, 52.317953982422786],
              [1.675497514134425, 52.313395069756496],
              [1.673417748302738, 52.314084088875617],
              [1.673309988529183, 52.314466128511043],
              [1.667146941266533, 52.317087365820448],
              [1.661742319946154, 52.320901737974339],
              [1.660282367625154, 52.322170336589821],
              [1.659141100621873, 52.324099148545365],
              [1.659612344238992, 52.324863486337776],
              [1.658798410143094, 52.324197924866311],
              [1.658504983693405, 52.324288876300692],
              [1.658814411009868, 52.324978180317508],
              [1.656310462373944, 52.324610553986467],
              [1.657474503818002, 52.324378390087254],
              [1.651780462782402, 52.324916345004191],
              [1.65010158747948, 52.325510679640026],
              [1.644971294537188, 52.329066605645728],
              [1.640623837816968, 52.329688335171397],
              [1.639038835328277, 52.330356966544187],
              [1.639195123566158, 52.330668762866438],
              [1.639312777467258, 52.331028523388589],
              [1.638731155349376, 52.33118677844994],
              [1.638418995023028, 52.331193705471478],
              [1.638127263917331, 52.331199102332128],
              [1.637452902348463, 52.33172989542058],
              [1.636267926283359, 52.331406625842824],
              [1.636054757359441, 52.331546317113464],
              [1.635532629033702, 52.332125477040485],
              [1.634538065479071, 52.332494317378362],
              [1.633207957133605, 52.331314688299351],
              [1.630835920048407, 52.332109976757067],
              [1.631702123047519, 52.332646393288499],
              [1.630324512327215, 52.333805931226067],
              [1.630438707247789, 52.334697396030592],
              [1.630033401631327, 52.335216269013792],
              [1.629203280471903, 52.335371326028984],
              [1.628603236275378, 52.334553266767173],
              [1.626485886340786, 52.334337718687351],
              [1.625953850933265, 52.334600523573975],
              [1.625640815177028, 52.334148711506295],
              [1.621326970280767, 52.335675354972558],
              [1.620870957188527, 52.33528818294684],
              [1.621210968359651, 52.333269226543308],
              [1.618972965430214, 52.333175077914454],
              [1.613519908744339, 52.331520755622918],
              [1.610946615884469, 52.331490690792869],
              [1.607256498967157, 52.332512015247879],
              [1.606781810520851, 52.333011338852508],
              [1.601488261277872, 52.333059680253513],
              [1.596157761661751, 52.334388850869026],
              [1.59478931834574, 52.33523916454692],
              [1.59080675551001, 52.335650040773025],
              [1.589919312886651, 52.336802271017568],
              [1.590057026630402, 52.338754417741761],
              [1.58370738171403, 52.338952802820941],
              [1.568668488222115, 52.337962189695865],
              [1.567425062698263, 52.336544458072822],
              [1.565553197571841, 52.335672786004139],
              [1.564609783399598, 52.333711682962374],
              [1.563739603117934, 52.333863888414456],
              [1.562557619191008, 52.331746272858318],
              [1.557630973549712, 52.332521821272799],
              [1.562502161965328, 52.331640911468789],
              [1.561241075075309, 52.329943919839401],
              [1.560270119703535, 52.329919250018833],
              [1.560574965158417, 52.330935434793282],
              [1.559451284930671, 52.331750962243795],
              [1.556747153946721, 52.331900853190405],
              [1.555799448561831, 52.332147980720428],
              [1.555953555734602, 52.332622747671557],
              [1.555136955762659, 52.332948669019849],
              [1.554162433229992, 52.332807126241384],
              [1.552660256076796, 52.333628588592234],
              [1.551546898554318, 52.334758536501042],
              [1.550733192995139, 52.334956616615948],
              [1.550416649689889, 52.335477030759279],
              [1.54831065163757, 52.335636611834246],
              [1.546451254745054, 52.336253762551465],
              [1.545489263552963, 52.337534706952447],
              [1.544178892790385, 52.338206381895787],
              [1.542224200444337, 52.337781161200141],
              [1.540320965069088, 52.337833773082224],
              [1.538848531710336, 52.337452493439663],
              [1.539173504482786, 52.337145929162375],
              [1.535215054606775, 52.336579189405853],
              [1.53417926913867, 52.337050043602844],
              [1.53273720886884, 52.33691962514358],
              [1.531025495140601, 52.337642740594781],
              [1.528632648349425, 52.337724202593378],
              [1.527664907400176, 52.338442111280486],
              [1.527452591820906, 52.338062593585597],
              [1.525739115069461, 52.337843060876395],
              [1.52510568233966, 52.338629212313435],
              [1.524452299181627, 52.338371696113377],
              [1.524454716852119, 52.337830155887886],
              [1.523510585475314, 52.337572322558174],
              [1.521902684533365, 52.33808892866027],
              [1.519619253210516, 52.337773883418286],
              [1.518051742846923, 52.336969742995635],
              [1.517497897187629, 52.337040218264868],
              [1.517279078854091, 52.337631380309759],
              [1.516102959997159, 52.337706001432814],
              [1.51649053237561, 52.337316686430995],
              [1.515531821846269, 52.337577305391484],
              [1.513031008950361, 52.336557146763617],
              [1.512082435176611, 52.336612362285976],
              [1.509981084617193, 52.335109842299651],
              [1.509683709278581, 52.33534084920575],
              [1.509419539480007, 52.33497183745191],
              [1.507895850662165, 52.334698733335124],
              [1.507805147796766, 52.334258003027188],
              [1.50722660110743, 52.334312974429302],
              [1.506908817796224, 52.333896980598531],
              [1.505635474058736, 52.333632598439614],
              [1.505357829059232, 52.333964645428374],
              [1.504529281117798, 52.333921793982967],
              [1.503893005097084, 52.333339858098668],
              [1.502638468847164, 52.333238584296268],
              [1.502382556128519, 52.332991636807755],
              [1.503070692837744, 52.332948726666714],
              [1.502875458440173, 52.331872490772312],
              [1.501592288476525, 52.33181702841658],
              [1.501820023025579, 52.331392029818922],
              [1.501005018033237, 52.330999768270253],
              [1.501480455863772, 52.330588108823946],
              [1.500644999128242, 52.33043840206718],
              [1.50070734717619, 52.330056987453297],
              [1.499819618676846, 52.329765814072722],
              [1.499752185164142, 52.329395444353544],
              [1.498893232258384, 52.329728523808477],
              [1.498468545977341, 52.328626704434782],
              [1.498264108989915, 52.328900798864986],
              [1.497462144043682, 52.328784256034893],
              [1.497850768780326, 52.329002095806416],
              [1.496516858504461, 52.329251195307407],
              [1.495947888158174, 52.328757168347998],
              [1.495299490785178, 52.32885642491074],
              [1.495271041536267, 52.32927730706912],
              [1.494186950999477, 52.328803924135741],
              [1.493673005515298, 52.329193308157436],
              [1.492757424696375, 52.329013538176191],
              [1.49223451347269, 52.329382493934069],
              [1.491112068823377, 52.329018151000774],
              [1.491198493686882, 52.328382387601096],
              [1.489454627283195, 52.32827296448437],
              [1.489429871957021, 52.328610986811093],
              [1.488572063899686, 52.328493336667343],
              [1.486995494775348, 52.330956727193993],
              [1.484658681904001, 52.33258627751308],
              [1.484915220209895, 52.332935779913655],
              [1.484527261663959, 52.33347250780232],
              [1.486249815150443, 52.334054841480018],
              [1.483635206940636, 52.335869763722286],
              [1.484466748314564, 52.336547677804759],
              [1.485551738609685, 52.336844834154903],
              [1.484837366029121, 52.338066680707001],
              [1.487309815780124, 52.338473052284655],
              [1.490015556695305, 52.339491288911795],
              [1.488576334133291, 52.341595353991664],
              [1.489477993613766, 52.342098469545377],
              [1.488687172801449, 52.343710257512285],
              [1.487859336750658, 52.347779500351884],
              [1.487377120657905, 52.347661531317499],
              [1.487242016107817, 52.348537978342968],
              [1.486811359464462, 52.348627153312016],
              [1.486724857952435, 52.347473030862098],
              [1.481205332061994, 52.346462184449301],
              [1.48101421488484, 52.347337576711709],
              [1.47837255089659, 52.347643012002472],
              [1.472915593156988, 52.349598970438969],
              [1.4681024443536, 52.353647625639937],
              [1.455085979360451, 52.356292301108653],
              [1.446735114370096, 52.359369625106588],
              [1.450196230205031, 52.361215780799419],
              [1.443906680596516, 52.362685613228216],
              [1.44312872641009, 52.362581486421291],
              [1.441199305771887, 52.364068730509857],
              [1.436771282640311, 52.366227721662554],
              [1.431418640350645, 52.367193800798461],
              [1.426174056213033, 52.367679804610866],
              [1.422182116699016, 52.368822723908146],
              [1.421025412431731, 52.367849795342508],
              [1.417006562149891, 52.365716731564511],
              [1.409478481287035, 52.363244218454184],
              [1.403716851622437, 52.369438363300048],
              [1.400391589419213, 52.371636115492628],
              [1.399961072450734, 52.372783575728441],
              [1.398685586132712, 52.373969750528175],
              [1.396475261796528, 52.375058687094977],
              [1.393145873466231, 52.378093696530016],
              [1.391399130093557, 52.378581894250836],
              [1.388489002315125, 52.380114396357413],
              [1.382571725167636, 52.384647095156978],
              [1.379690560066493, 52.386240600396036],
              [1.378365021639311, 52.388341774166975],
              [1.375700453801918, 52.389619583696636],
              [1.375888458621096, 52.3933323596832],
              [1.374828093019922, 52.394656014586069],
              [1.371503059590878, 52.39470242067469],
              [1.370895477028913, 52.395584094199904],
              [1.368387584021596, 52.397247599534154],
              [1.367363936767656, 52.398864241100362],
              [1.36476682430583, 52.398162977496746],
              [1.364215679837285, 52.398455695367332],
              [1.3609353507793, 52.403501207075216],
              [1.359468769908233, 52.403320780306927],
              [1.358984370308472, 52.4038930867491],
              [1.357380960109652, 52.404060096893396],
              [1.352947279471816, 52.403516894844785],
              [1.351466260424904, 52.403862928100047],
              [1.34983847706112, 52.403320900334414],
              [1.347072552007486, 52.40438955666302],
              [1.346607485573744, 52.404889310381229],
              [1.34716418568971, 52.405676698423456],
              [1.346071322207131, 52.406392107414788],
              [1.346599152305181, 52.407788309670039],
              [1.346281783319538, 52.408976413488844],
              [1.348372385956815, 52.408850609758517],
              [1.350254393869909, 52.409484382730668],
              [1.352352197979447, 52.410674126596362],
              [1.353894868379914, 52.410865974638803],
              [1.354919635921032, 52.410667784112761],
              [1.355611437798254, 52.411120317365203],
              [1.356578984925691, 52.410653018336696],
              [1.357252761357057, 52.411526074703488],
              [1.361227328958818, 52.413175863057866],
              [1.360206176488805, 52.414620575616048],
              [1.360753794717469, 52.415496302582824],
              [1.360634389766156, 52.416382012657593],
              [1.359756878748793, 52.416835090563282],
              [1.358568626055392, 52.416974112685232],
              [1.358890271994248, 52.417393081532119],
              [1.358549596193473, 52.418135780128466],
              [1.356794536937614, 52.419217280763853],
              [1.356343306700947, 52.421157517000822],
              [1.357412464393096, 52.422139858088308],
              [1.357474029398979, 52.423781311947906],
              [1.357912202956618, 52.424223054154034],
              [1.36348171913104, 52.426939988636917],
              [1.363731394449084, 52.427494105701278],
              [1.364274993876778, 52.427078392216245],
              [1.368116933226567, 52.427025576341364],
              [1.368520318592704, 52.42731807230669],
              [1.367751904838004, 52.427621494074941],
              [1.368419997921325, 52.427892952644214],
              [1.368247164600938, 52.428828761741215],
              [1.370469062429758, 52.428483875989848],
              [1.371483512252234, 52.42926349092042],
              [1.371258583419535, 52.430017313645173],
              [1.372571941586324, 52.43043312508668],
              [1.373716749508822, 52.430048770089023],
              [1.374969232051547, 52.430322389081304],
              [1.375707968906815, 52.429842585889133],
              [1.377693245720272, 52.430943371785524],
              [1.378556309082121, 52.431050004860673],
              [1.379940819651973, 52.432028526401176],
              [1.382367823613144, 52.431690140660777],
              [1.383022350055285, 52.43247366809269],
              [1.385214201366479, 52.431985461063604],
              [1.386258283597678, 52.432053572865534],
              [1.392043895914269, 52.434022781947462],
              [1.391952564367835, 52.434308717527635],
              [1.392990672952223, 52.434604492034758],
              [1.393154799674049, 52.43505487812061],
              [1.392092219733892, 52.435551285711213],
              [1.392454984284914, 52.43600136546538],
              [1.3909375572624, 52.436611556205222],
              [1.3909495330983, 52.436920608306792],
              [1.392026339338512, 52.437776510902822],
              [1.393138846265737, 52.437947829482567],
              [1.393239151605391, 52.438945988428848],
              [1.395269660207227, 52.439628762628345],
              [1.39669248176635, 52.439356715986328],
              [1.397294544044667, 52.44071549835229],
              [1.39798099889007, 52.441048324678363],
              [1.401863111293597, 52.442073457888384],
              [1.402356108949474, 52.441557391319485],
              [1.404796141202867, 52.4414403275347],
              [1.405779281789789, 52.442279020922321],
              [1.407353311829921, 52.442096925012883],
              [1.406264676718957, 52.443628532209821],
              [1.407886368013493, 52.444386737762166],
              [1.41026429502054, 52.444721059167215],
              [1.411821105731273, 52.444201223080128],
              [1.412385401306287, 52.444912549376205],
              [1.414188056444868, 52.445477466221917],
              [1.416205654763759, 52.444920870652368],
              [1.418666102365197, 52.445616000228164],
              [1.419482336272283, 52.445208329896452],
              [1.420763277090948, 52.445668624208501],
              [1.421859665018106, 52.445210552976249],
              [1.422096729479582, 52.445439332895397],
              [1.421531342391689, 52.44612037412643],
              [1.423841094136078, 52.446559830994289],
              [1.425162688449028, 52.44625349922881],
              [1.426213479107142, 52.445398268623549],
              [1.427738673159242, 52.445685912278904],
              [1.430051425970155, 52.445141200886098],
              [1.432192784982886, 52.445156362152176],
              [1.432661360401213, 52.445578965416843],
              [1.432077976315139, 52.44608339713885],
              [1.433118135594682, 52.446587429449472],
              [1.433397382957689, 52.447376197059519],
              [1.434302501920572, 52.447430838001956],
              [1.43493350802888, 52.448010605927315],
              [1.434836805665779, 52.449944463252386],
              [1.434086526968422, 52.450234298658003],
              [1.434288123227551, 52.450857130528817],
              [1.433706809402185, 52.451124063564549],
              [1.433428570412216, 52.452750197437119],
              [1.432769077789079, 52.453204676696103],
              [1.433021165372535, 52.454627422232505],
              [1.433829778153607, 52.455407102768859],
              [1.433240894356213, 52.455936881755612],
              [1.430979403961143, 52.456863310705181],
              [1.427883338240238, 52.457472132532516],
              [1.425757591705249, 52.457248668950037],
              [1.421146640526395, 52.458050525809341],
              [1.420269447066612, 52.45870011183456],
              [1.417365045528317, 52.459011697174006],
              [1.414613553803776, 52.459815266170914],
              [1.410990553070129, 52.461573919254633],
              [1.410826976541315, 52.462181244889614],
              [1.411580960746306, 52.462886201314575],
              [1.410796066706955, 52.463932386379341],
              [1.414035827741106, 52.465246404875501],
              [1.416404755282181, 52.468375341388885],
              [1.417439823068455, 52.469037058710015],
              [1.417930208363709, 52.470138144343395],
              [1.420122635014584, 52.471023590469407],
              [1.422587089634725, 52.471200477763901],
              [1.426150485380484, 52.470641218873659],
              [1.427178893077901, 52.469983608803417],
              [1.426602769601387, 52.469525426296762],
              [1.427190895559163, 52.469153103432134],
              [1.427773242853673, 52.467892325799838],
              [1.430964823086554, 52.466363341680278],
              [1.432467740217918, 52.466109232925248],
              [1.432669985267121, 52.465661745956467],
              [1.434868360707637, 52.464581526407983],
              [1.434704269066714, 52.463666195217996],
              [1.433979680198154, 52.463438116805179],
              [1.434753359080872, 52.462708690725798],
              [1.434241409073666, 52.462280159460143],
              [1.434521009705801, 52.461578584379929],
              [1.436129678951708, 52.46035987813665],
              [1.436922019365863, 52.459228754584501],
              [1.438401132678574, 52.458314188497461],
              [1.438453515989877, 52.457909725833012],
              [1.439199081094872, 52.457935696356373],
              [1.441071764036972, 52.456688547594418],
              [1.446487393927396, 52.455997349604651],
              [1.450587851073878, 52.456619692715265],
              [1.45233442833388, 52.457546342254538],
              [1.453943088511536, 52.457632443274491],
              [1.455884181437292, 52.458240357132205],
              [1.458744168694639, 52.458117098695084],
              [1.459444049684487, 52.459080575943496],
              [1.460663996801373, 52.459484668886176],
              [1.462377177615342, 52.460656794112296],
              [1.463995603430627, 52.463451531710142],
              [1.46408933260326, 52.464325719993219],
              [1.46626988543357, 52.465295210543857],
              [1.469312775701145, 52.46586248883758],
              [1.470276169242841, 52.466745315090634],
              [1.47180360432567, 52.467440672950524],
              [1.473492511843502, 52.469231260075325],
              [1.47475069950367, 52.469610702765813],
              [1.477543022589136, 52.471028800998383],
              [1.480050686751055, 52.471842710199397],
              [1.481037880273493, 52.471698507434539],
              [1.479898046630474, 52.471532406608162],
              [1.479612383325229, 52.471216126216],
              [1.483202707425116, 52.471732753900568],
              [1.484283433071253, 52.470741205948542],
              [1.488088273730601, 52.468478426701246],
              [1.490507668087029, 52.468716388063527],
              [1.494332591642525, 52.46758608790369],
              [1.496164492527639, 52.467547191477621],
              [1.494839242126248, 52.466379361045213],
              [1.494916967618392, 52.466003799166138],
              [1.496392297313101, 52.465629163908496],
              [1.497238407839558, 52.466214837341205],
              [1.497953116352084, 52.466276429646392],
              [1.497614099082181, 52.465084826980437],
              [1.499489958698211, 52.464154140468914],
              [1.501212218348459, 52.463923234525794],
              [1.501747549897466, 52.463154547168678],
              [1.5067831467707, 52.462370206003406],
              [1.508072783355313, 52.461644757520894],
              [1.509015510929876, 52.462573760745954],
              [1.509149012653516, 52.462585985766211],
              [1.51023647278355, 52.46242866324075],
              [1.514135354373113, 52.463236489066652],
              [1.515545768607756, 52.463185911561247],
              [1.514625974187712, 52.463350515618565],
              [1.514747450086097, 52.463562766438322],
              [1.51635872295428, 52.463843119983913],
              [1.51652661408628, 52.463215714751364],
              [1.517362684654294, 52.462860736683538],
              [1.51564374752464, 52.463168376952389],
              [1.518896636266057, 52.461822086765103],
              [1.5218142216808, 52.461718070495522],
              [1.523631630585141, 52.463988029492647],
              [1.523842223806262, 52.465056575525665],
              [1.522765534760939, 52.465791139984965],
              [1.524146663720471, 52.467225030994832],
              [1.5254356274597, 52.467910627180856],
              [1.529385874331463, 52.46830178472262],
              [1.532678848241642, 52.467382193930696],
              [1.533561016559399, 52.466794566688264],
              [1.535506332056081, 52.466205707317876],
              [1.535957475018315, 52.465404298835942],
              [1.538093916615379, 52.464152185268979],
              [1.538371525644136, 52.463060946413393],
              [1.540969663784759, 52.461729291681557],
              [1.542501175854994, 52.461620422807712],
              [1.543397626298419, 52.462587424703358],
              [1.54403479060414, 52.462783281300126],
              [1.545223162125458, 52.461611635225047],
              [1.547569973683495, 52.461131049432744],
              [1.547901723468138, 52.459526376111874],
              [1.548844359064897, 52.45881628850929],
              [1.549902334267447, 52.458606415838716],
              [1.55117711203858, 52.459004337448484],
              [1.552859873095679, 52.459001418083567],
              [1.554924092638462, 52.458871868982577],
              [1.556954361956626, 52.457555138561823],
              [1.558444970766853, 52.457726119932211],
              [1.559327669933514, 52.457297478720193],
              [1.558563067538249, 52.457784624723928],
              [1.559689924213143, 52.458173418551453],
              [1.562935717172085, 52.461387343758368],
              [1.563039369529896, 52.462724393135467],
              [1.562356422199041, 52.464240765010921],
              [1.563340275759905, 52.464388288758961],
              [1.562332767769161, 52.464385389546173],
              [1.562092238874438, 52.4649988676939],
              [1.566464498037537, 52.463961231054405],
              [1.562107216801808, 52.465566865924501],
              [1.563152224883449, 52.46685304546768],
              [1.566158468270001, 52.468478495393647],
              [1.566494805145713, 52.469904760161683],
              [1.56579235216374, 52.471944317849591],
              [1.568875703235749, 52.474697980856178],
              [1.573574092653132, 52.475020100447026],
              [1.576133523848116, 52.476482526037529],
              [1.582647328499454, 52.476609918148363],
              [1.583866491600248, 52.477001986397859],
              [1.585693331113395, 52.478326323785566],
              [1.586556863004586, 52.478456622155278],
              [1.591139699910384, 52.47710137932706],
              [1.593869868652545, 52.475627822477961],
              [1.59610635769914, 52.475110053809551],
              [1.597604163791791, 52.473014605537564],
              [1.600348028989458, 52.472038773292326],
              [1.603920594277102, 52.471310137780257],
              [1.604918545256377, 52.468169375539247],
              [1.606878955270512, 52.466389785355382],
              [1.611081170188549, 52.465893550559407],
              [1.61683222722411, 52.463311586036845],
              [1.627732307755029, 52.462146299574385],
              [1.634762577100022, 52.462737942411607],
              [1.635532103626989, 52.463640714301903],
              [1.635468426086916, 52.465421814772981],
              [1.636182987143307, 52.466532251610175],
              [1.638738930633698, 52.467469059076763],
              [1.642715644390805, 52.468202812222621],
              [1.643545520440649, 52.468834733546373],
              [1.64453775482718, 52.470877401551498],
              [1.646544225898976, 52.471754537493538],
              [1.648299958374326, 52.471648169590793],
              [1.650657853794865, 52.47073883580439],
              [1.652875978585164, 52.468428804714456],
              [1.652622843904318, 52.468271128328716],
              [1.653927835792586, 52.467792736121133],
              [1.657264205663803, 52.467728185520073],
              [1.658642803979788, 52.468083969229319],
              [1.659863842718986, 52.468794511850241],
              [1.661151285269906, 52.470445630049383],
              [1.662666434718811, 52.473948883077767],
              [1.663768610240872, 52.474768310342107],
              [1.669182935638834, 52.475735435467541],
              [1.669486362850019, 52.47522230812995],
              [1.669379890336286, 52.475783287586047],
              [1.670854305409899, 52.47601182989208],
              [1.67178868327303, 52.477802431388405],
              [1.670963790017853, 52.481126427370008],
              [1.671322382989668, 52.481911319603235],
              [1.672161925094926, 52.482615604177219],
              [1.674463236706218, 52.483251950515651],
              [1.676180686224345, 52.484129487652439],
              [1.677126099464804, 52.486876746843137],
              [1.681468959224099, 52.489334713610063],
              [1.681371522157773, 52.486988321878499],
              [1.682348777746201, 52.482763621300009],
            ],
          ],
          [
            [
              [1.686090062902142, 52.549354600104138],
              [1.689681647345201, 52.547072154166948],
              [1.689681913328282, 52.54607373267271],
              [1.690401244037102, 52.544941334827385],
              [1.69312055693197, 52.545048913312847],
              [1.696096915045926, 52.544107703593589],
              [1.69815326081285, 52.544226894252454],
              [1.70050004227946, 52.544921617710159],
              [1.702552572911555, 52.544399526144787],
              [1.70397791779158, 52.543508475133251],
              [1.704113318674111, 52.542121739316414],
              [1.705570633247181, 52.539948811921171],
              [1.707971158198904, 52.539428307016081],
              [1.710210327139924, 52.537853231871388],
              [1.716733276073385, 52.535443718195815],
              [1.724378781936522, 52.533530967009071],
              [1.725979965459944, 52.53061656024731],
              [1.74043370329299, 52.532085008754493],
              [1.748897961340163, 52.516612822474926],
              [1.750272104706949, 52.513274397152941],
              [1.750861579130799, 52.510245096101826],
              [1.757613482745463, 52.49715797571087],
              [1.757637117322075, 52.495777400575307],
              [1.760285270390414, 52.48996068397544],
              [1.762915947570176, 52.481153626112814],
              [1.762041885697787, 52.480493344444497],
              [1.761111855449719, 52.476226958034808],
              [1.759727502653719, 52.473970106812537],
              [1.760139708369123, 52.473742908620231],
              [1.759536261515987, 52.473834971442137],
              [1.758080817333789, 52.472504147140711],
              [1.756570876513678, 52.471932414104394],
              [1.756453507436011, 52.472611667177382],
              [1.755758699362334, 52.472685927161088],
              [1.759059616324175, 52.474636297312536],
              [1.759543545715402, 52.474541743781813],
              [1.760495168429639, 52.475836417213792],
              [1.760524880187218, 52.476184476161329],
              [1.757619265453322, 52.476902070550246],
              [1.756777247250326, 52.476131893678428],
              [1.758623062052036, 52.4753724813704],
              [1.7581274085292, 52.474984374609207],
              [1.755686951907988, 52.475732100054223],
              [1.754427571523052, 52.474143140428502],
              [1.75520610163334, 52.473944795800797],
              [1.755622557048922, 52.473335193099778],
              [1.755068369021618, 52.473331220188655],
              [1.754237805492152, 52.472745958310632],
              [1.753417326936585, 52.472849383508361],
              [1.754345445210672, 52.47288555762232],
              [1.75478746312816, 52.473353640269416],
              [1.753354647914218, 52.473488217046373],
              [1.750751334697078, 52.473560097029079],
              [1.750721101166649, 52.473049244697457],
              [1.752914483692625, 52.472842869881056],
              [1.748505656204831, 52.472909973430994],
              [1.744969819824575, 52.47338729668941],
              [1.743075501940504, 52.47321345790553],
              [1.739564544517888, 52.473811261110242],
              [1.730571978457906, 52.475344032229195],
              [1.727061542323453, 52.476265257738746],
              [1.724971539422061, 52.477366476158679],
              [1.721632494926822, 52.477599423064817],
              [1.717706270260543, 52.475780137221584],
              [1.717537178255016, 52.475347408348298],
              [1.716739498343288, 52.475460653092803],
              [1.71541960376445, 52.474901309755396],
              [1.7136376066835, 52.475080561942733],
              [1.712943848211631, 52.474661618054135],
              [1.712465852852967, 52.474950081101781],
              [1.711451488460853, 52.474799353649807],
              [1.710167997392355, 52.47507352951439],
              [1.709994419593722, 52.475776079328512],
              [1.711274009348386, 52.47751866981212],
              [1.70969333486511, 52.477571006404482],
              [1.709557668833124, 52.476694672074927],
              [1.705274548518175, 52.475511324177283],
              [1.703930031485436, 52.475253053099827],
              [1.70364050441508, 52.475418638661488],
              [1.702797601471936, 52.474748858549837],
              [1.700828421370344, 52.474085572186681],
              [1.698845981867422, 52.474193523374694],
              [1.698570271016296, 52.475436241021896],
              [1.697750997171008, 52.47532786111006],
              [1.69669215986657, 52.475945655146383],
              [1.69572914060481, 52.475669058321756],
              [1.693572534718451, 52.475756282414146],
              [1.693654429618948, 52.476047851890179],
              [1.692714402119806, 52.476256230310717],
              [1.692078561719202, 52.475875832473179],
              [1.693076067722688, 52.474964968649473],
              [1.692355997978024, 52.475319380613456],
              [1.691463346223408, 52.474282290268775],
              [1.692425763743555, 52.474007559669623],
              [1.691287888445369, 52.473840731196347],
              [1.690348549045235, 52.475024103925222],
              [1.690536766453678, 52.474088164879646],
              [1.691132500536039, 52.473832093441132],
              [1.688799009402903, 52.474163118632376],
              [1.687690904132843, 52.474527816736206],
              [1.682113113757595, 52.477071889139829],
              [1.682888607222788, 52.483017539521569],
              [1.68183520639694, 52.488287108585141],
              [1.682783687364654, 52.491400317501224],
              [1.683314321523847, 52.491542986653585],
              [1.682807223398491, 52.491752178022715],
              [1.682840252555768, 52.495089097940749],
              [1.683560664307109, 52.495209661714981],
              [1.68288651782325, 52.495236968865804],
              [1.680598640448755, 52.496744704554374],
              [1.676176122627711, 52.497859182522596],
              [1.674745436963599, 52.502100611277761],
              [1.673828130065635, 52.503118547411759],
              [1.674096432284327, 52.503348566917012],
              [1.673614184631158, 52.503292499219107],
              [1.671838708717571, 52.504113121737014],
              [1.670607688232159, 52.504181042352542],
              [1.667816798763651, 52.503678533103077],
              [1.663176632923224, 52.502030700630662],
              [1.659052412299341, 52.501142058257926],
              [1.656546089870113, 52.501027140749436],
              [1.65412740791238, 52.501665911530097],
              [1.651721375854175, 52.504874013243821],
              [1.646867867030987, 52.507191577032266],
              [1.64531213530264, 52.508839713235375],
              [1.645481380204636, 52.509903955106012],
              [1.647349592283441, 52.51270121780221],
              [1.647973365306425, 52.512738634552349],
              [1.647375166584192, 52.512920797161371],
              [1.647570193368685, 52.513494932859089],
              [1.650718797544769, 52.513577621782552],
              [1.649151583180381, 52.513790617215264],
              [1.648508657743041, 52.514642467576387],
              [1.647639408288558, 52.51452538078302],
              [1.648065955649135, 52.513677515191581],
              [1.647536713423585, 52.51362458925027],
              [1.646875469272854, 52.515127307382329],
              [1.644946584756056, 52.51620769197725],
              [1.639693200001076, 52.517097230175409],
              [1.631441668668964, 52.520553984508389],
              [1.630137390282057, 52.521437736137685],
              [1.628673385698568, 52.523138585793902],
              [1.628839048256787, 52.526093614888794],
              [1.630550247207261, 52.526671595136477],
              [1.630936753853143, 52.526155128888135],
              [1.633516028334565, 52.526601164830261],
              [1.636512129196612, 52.527913994851453],
              [1.631238940288008, 52.53227924858389],
              [1.638268303741113, 52.533756815729681],
              [1.637841742137544, 52.535660604334218],
              [1.638088029484993, 52.536515606839458],
              [1.636715018627847, 52.537410534949181],
              [1.63772069099742, 52.537281555581984],
              [1.637837516139998, 52.537570285061207],
              [1.638865013300742, 52.537598029712861],
              [1.642912479614936, 52.539570928315158],
              [1.646116974976199, 52.539753680418741],
              [1.648855903017141, 52.539481196277812],
              [1.651282174717241, 52.540097977913177],
              [1.654266481240586, 52.541605921499311],
              [1.659130330764686, 52.542225433833153],
              [1.658843803968841, 52.544170845983025],
              [1.659153330554402, 52.544858351385066],
              [1.662323533302301, 52.54782827037959],
              [1.665653402035457, 52.549629251824378],
              [1.667951569033291, 52.550221788284738],
              [1.674412797499949, 52.549548958587955],
              [1.676319995341296, 52.54910020604995],
              [1.678223539572081, 52.548079474082897],
              [1.680248925226786, 52.547739413539354],
              [1.682657893023141, 52.548191496895399],
              [1.684356485066714, 52.549884450851287],
              [1.686090062902142, 52.549354600104138],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000207",
        LAD13CDO: "43UB",
        LAD13NM: "Elmbridge",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.351639498372405, 51.408040663811768],
            [-0.343808951003041, 51.403980789560798],
            [-0.340657894561664, 51.403094523835264],
            [-0.338357170824749, 51.400844387395402],
            [-0.335828766524903, 51.398456983203324],
            [-0.332285506974727, 51.395918398494416],
            [-0.330808142731576, 51.394091677075288],
            [-0.328693944915686, 51.392309809730172],
            [-0.327609176236813, 51.391745792442556],
            [-0.325990175681558, 51.391445707154567],
            [-0.324612536594319, 51.39150335408263],
            [-0.319855217304939, 51.392594371715425],
            [-0.317720142672215, 51.393668016936353],
            [-0.31413411826016, 51.390218220161039],
            [-0.316759919310306, 51.388773106429063],
            [-0.315981087523024, 51.387633358495229],
            [-0.313158585183755, 51.388800453090617],
            [-0.310868951406257, 51.386187522872632],
            [-0.311787703596548, 51.385786209110435],
            [-0.310631022842123, 51.384765076183392],
            [-0.309330968957377, 51.381864227546245],
            [-0.309276779667282, 51.380448924070386],
            [-0.30851753259259, 51.380501812861205],
            [-0.308326023524929, 51.380083594090941],
            [-0.307388543110839, 51.378384858348596],
            [-0.307892196022698, 51.378365155435318],
            [-0.308457260488264, 51.375860805823365],
            [-0.30869512399023, 51.375449684605911],
            [-0.312674937124656, 51.374752593929273],
            [-0.314341507570123, 51.374638997160851],
            [-0.316694380928462, 51.373167477785849],
            [-0.317505491860361, 51.371970534575944],
            [-0.317915230643954, 51.369846091207705],
            [-0.318523371858323, 51.366186772774626],
            [-0.318304733097254, 51.363684612724612],
            [-0.320259445267751, 51.359099489601988],
            [-0.32353507722447, 51.354670829038092],
            [-0.324453936372623, 51.353891732962182],
            [-0.327947014590891, 51.352186300395729],
            [-0.330533862397632, 51.348421152739618],
            [-0.328177391085327, 51.344041451559093],
            [-0.327480811710163, 51.344007227869568],
            [-0.32790680577888, 51.343743533255946],
            [-0.328979269266763, 51.340232860887582],
            [-0.329718710944251, 51.33276610126773],
            [-0.329116323029064, 51.330263872972537],
            [-0.330679062415786, 51.329011011297645],
            [-0.332286841180647, 51.327503381544147],
            [-0.33579651087524, 51.325315055196427],
            [-0.338193517138604, 51.324359009625418],
            [-0.345359300292738, 51.322414658798834],
            [-0.352522260862443, 51.319129941839968],
            [-0.357064061204679, 51.31539978618742],
            [-0.358220900125207, 51.313181359677067],
            [-0.359869553605586, 51.312417612045536],
            [-0.360339081779574, 51.311804599806891],
            [-0.363978767637962, 51.311899566218024],
            [-0.363744884186468, 51.312948427149408],
            [-0.36524320366136, 51.312980142904088],
            [-0.365643654234614, 51.313865203326003],
            [-0.367323920868783, 51.314876021705075],
            [-0.368101825074823, 51.314345512772789],
            [-0.368674404772919, 51.312928171608434],
            [-0.371103241008753, 51.311399974437904],
            [-0.371946407122711, 51.309914440228773],
            [-0.373471275059988, 51.309273777779907],
            [-0.373755955657771, 51.309622145907603],
            [-0.377475934398338, 51.310365271319341],
            [-0.378545578384953, 51.311606668452512],
            [-0.378849702366, 51.312911199648831],
            [-0.380447914158747, 51.313569983833254],
            [-0.384383723323647, 51.312919331789416],
            [-0.386662046102723, 51.310840193431041],
            [-0.386597893776419, 51.310010198180862],
            [-0.387504344584656, 51.309733123215231],
            [-0.390018449389409, 51.310460138104219],
            [-0.391266161165647, 51.310229990000742],
            [-0.392161870465409, 51.310752167168133],
            [-0.391386341296355, 51.311347613441072],
            [-0.3924104952947, 51.312218664148311],
            [-0.396949539006889, 51.312818636123779],
            [-0.397614778561385, 51.312010320176583],
            [-0.397221052865301, 51.311120967610165],
            [-0.396145595655378, 51.310320297500702],
            [-0.395077737290774, 51.310207649502871],
            [-0.392800479023174, 51.30918814012901],
            [-0.392024262074092, 51.308408623526276],
            [-0.392962796245954, 51.306718321310399],
            [-0.392761234030478, 51.301563731782622],
            [-0.393113844435469, 51.300996645411765],
            [-0.39399389734987, 51.300853147441998],
            [-0.394101785830866, 51.298665842805256],
            [-0.394765069497582, 51.297950138357542],
            [-0.397616603148175, 51.298075521983016],
            [-0.399902956876155, 51.297459323490884],
            [-0.402314273064461, 51.29654713266914],
            [-0.403952787656044, 51.294878880329229],
            [-0.405349268471837, 51.294894309332783],
            [-0.405420888161524, 51.297008530298235],
            [-0.408600409735599, 51.302332195538256],
            [-0.409416177273954, 51.303093255008669],
            [-0.411817020128816, 51.303568272088661],
            [-0.415429724076671, 51.303888786053832],
            [-0.416078850526838, 51.304493771302582],
            [-0.422144122828181, 51.304207856849473],
            [-0.426408346991319, 51.304483752160884],
            [-0.428582946119673, 51.303903261139062],
            [-0.431450130885307, 51.304382332574647],
            [-0.437448282673501, 51.303999406119637],
            [-0.44041599826532, 51.304901346536141],
            [-0.442979971237681, 51.307944349620222],
            [-0.443850912866445, 51.313074444196978],
            [-0.445954330070672, 51.317494295996163],
            [-0.445721019820749, 51.324142923602984],
            [-0.445665171274687, 51.324393971246678],
            [-0.443669919810465, 51.324105795392086],
            [-0.442209587093755, 51.324423598995772],
            [-0.440110976823459, 51.325067405108811],
            [-0.4390214260777, 51.326130186142464],
            [-0.436963257165376, 51.326045186576948],
            [-0.436624307980338, 51.326305041251437],
            [-0.437185395900716, 51.32744827690852],
            [-0.443809063930252, 51.326737038491309],
            [-0.446347949154227, 51.326826496477516],
            [-0.45129723679758, 51.328647367500807],
            [-0.449942986480316, 51.330371290216874],
            [-0.45129560721646, 51.330613094100521],
            [-0.451474067316168, 51.330940080691576],
            [-0.456171795068932, 51.331038061747329],
            [-0.462365765821458, 51.329962209647796],
            [-0.461956669200167, 51.331335378411246],
            [-0.46285961512173, 51.332793213920979],
            [-0.462510572644152, 51.33395854780737],
            [-0.461002665095017, 51.335091574887798],
            [-0.462092506410178, 51.336800061470335],
            [-0.461191274198481, 51.337090371145251],
            [-0.461907182835467, 51.337533208397701],
            [-0.461953838587755, 51.338026605725986],
            [-0.462683209139228, 51.338067653618566],
            [-0.463592531351788, 51.338609237404462],
            [-0.463185165160666, 51.339414110629818],
            [-0.463746560615109, 51.339670564435124],
            [-0.463674706596142, 51.340617422826107],
            [-0.46551925855055, 51.34131603084348],
            [-0.46609252908902, 51.342852248902489],
            [-0.472842031793204, 51.342166187694964],
            [-0.476789871670297, 51.343265202414315],
            [-0.479227119475216, 51.345240125034415],
            [-0.479675269719151, 51.347752121944048],
            [-0.480702750288809, 51.347728582956755],
            [-0.481175820171545, 51.349359641873534],
            [-0.478430302429649, 51.35078707657199],
            [-0.474530800251229, 51.352233597908935],
            [-0.472510587813746, 51.353777346321394],
            [-0.466991436132193, 51.356576526968496],
            [-0.468454301773977, 51.357543457308793],
            [-0.470127275856658, 51.357120192205549],
            [-0.470696284952037, 51.3572849878515],
            [-0.470698998105149, 51.35763482706939],
            [-0.469946328203913, 51.357852507164488],
            [-0.470100800261057, 51.358087427210833],
            [-0.472212158169591, 51.357827222839944],
            [-0.473505534148772, 51.358193885489584],
            [-0.474226223106884, 51.358030619621317],
            [-0.474577150253864, 51.359054025992414],
            [-0.475570857376171, 51.359146992073221],
            [-0.476106194768913, 51.359548721819095],
            [-0.475152228405661, 51.360291669495588],
            [-0.475836645323882, 51.360616207614804],
            [-0.476155877225582, 51.361817245258372],
            [-0.474873339735514, 51.36306668353437],
            [-0.474193060118946, 51.362919343713571],
            [-0.475487825330243, 51.364114199005712],
            [-0.474140942839854, 51.363968974253766],
            [-0.47332045688786, 51.364063496738794],
            [-0.472838664569495, 51.363562637260898],
            [-0.472391768558027, 51.363869746023376],
            [-0.473064387391614, 51.3648092245135],
            [-0.474003087009281, 51.364961735243917],
            [-0.473749159732281, 51.365254276765896],
            [-0.472101644619755, 51.365034072388696],
            [-0.471514262442243, 51.36438255709615],
            [-0.469312012067031, 51.365808772997767],
            [-0.467576452720686, 51.365687170079958],
            [-0.467670202547587, 51.366020214900274],
            [-0.470041110779107, 51.367293944351914],
            [-0.468268032943365, 51.370146538661686],
            [-0.46686479005406, 51.370737868681744],
            [-0.4661752838658, 51.370477058276961],
            [-0.465657422706536, 51.370840763705772],
            [-0.465528579086198, 51.371385812572669],
            [-0.466236844361005, 51.37241841750496],
            [-0.467507784849508, 51.373640024159755],
            [-0.467625059095854, 51.374646005039651],
            [-0.465028612379981, 51.376240537740365],
            [-0.461661073715669, 51.376913966039432],
            [-0.460160436229728, 51.379114470446396],
            [-0.459036308583619, 51.379256159950543],
            [-0.458069322571404, 51.379809958482426],
            [-0.458010795509476, 51.380568143904931],
            [-0.459452805239572, 51.380974681672477],
            [-0.459606140503346, 51.381500952152201],
            [-0.457898746263022, 51.381849879622692],
            [-0.455186719467595, 51.383226858039471],
            [-0.453839185898642, 51.383256745115681],
            [-0.451998531661007, 51.382824190471993],
            [-0.450543282357089, 51.383661030390627],
            [-0.450275107452303, 51.384920911518059],
            [-0.45197558395678, 51.38691181585618],
            [-0.451910975094833, 51.387633948114122],
            [-0.450239310206465, 51.388022803339261],
            [-0.447602986010825, 51.386847676865465],
            [-0.445024301321768, 51.386901612547909],
            [-0.443803428262637, 51.387558034916864],
            [-0.443442645258528, 51.389253699738894],
            [-0.442797490708371, 51.389799058245963],
            [-0.441610625641281, 51.389705047319829],
            [-0.439996908564133, 51.388723191423637],
            [-0.438157449256973, 51.386902013617416],
            [-0.437533169586026, 51.385187837814229],
            [-0.435635887555473, 51.384268682221212],
            [-0.435778585502333, 51.383918985088549],
            [-0.432109791519148, 51.384042607435752],
            [-0.428875350295191, 51.385372422906165],
            [-0.427909099628508, 51.386267694722513],
            [-0.430219056686493, 51.387010858326335],
            [-0.430072648439549, 51.387173457535035],
            [-0.427687012979818, 51.386415787115553],
            [-0.426566433189131, 51.3887504561215],
            [-0.426680891823279, 51.389938987201077],
            [-0.42615573934913, 51.390415725485084],
            [-0.42271805954223, 51.391702177173698],
            [-0.42161990990201, 51.392413076739999],
            [-0.421098197128452, 51.392286450063359],
            [-0.420688470600991, 51.392706268685203],
            [-0.421339169767909, 51.393094514635536],
            [-0.42072807767988, 51.394102419980086],
            [-0.420163812011766, 51.394501270046597],
            [-0.419540311437341, 51.394449699559487],
            [-0.419528377605755, 51.395087997608513],
            [-0.418706296634523, 51.39554271314951],
            [-0.418985262078969, 51.396373775853867],
            [-0.419663657237628, 51.396710248552694],
            [-0.418968051628666, 51.397164872920996],
            [-0.417218766772215, 51.397660120667581],
            [-0.417160976957298, 51.398834643893125],
            [-0.415857728216159, 51.399149752737969],
            [-0.414652830559859, 51.400694536374793],
            [-0.412954042107981, 51.401470067038218],
            [-0.411556639285026, 51.403255006193568],
            [-0.410655925226043, 51.404105163735629],
            [-0.408179124293843, 51.40517041781186],
            [-0.406065865814698, 51.405659657110718],
            [-0.402788837200304, 51.405917187183768],
            [-0.40045299127628, 51.406602023612791],
            [-0.399351021073938, 51.407072567426631],
            [-0.399940608844179, 51.407811698703235],
            [-0.397214347407654, 51.408596351247112],
            [-0.397067569340361, 51.407982861894496],
            [-0.39048575224034, 51.409643477257376],
            [-0.385717773167306, 51.409150764188659],
            [-0.383371172651401, 51.408538410298362],
            [-0.379449128811833, 51.407834094999586],
            [-0.37317164997508, 51.408599607089599],
            [-0.366195135050612, 51.41122096620748],
            [-0.36312366284973, 51.411905595813884],
            [-0.360711298360739, 51.412050828657357],
            [-0.359065131887705, 51.411881216319912],
            [-0.355378410002059, 51.410752249557241],
            [-0.355130485284052, 51.409807263850254],
            [-0.351639498372405, 51.408040663811768],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000208",
        LAD13CDO: "43UC",
        LAD13NM: "Epsom and Ewell",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.245054294678756, 51.380035169219461],
            [-0.244703787511017, 51.369062562344389],
            [-0.245429225111489, 51.366846862809034],
            [-0.241174879430627, 51.366263298102851],
            [-0.24091533752637, 51.367038160489543],
            [-0.238942043034275, 51.366835856857989],
            [-0.237849368496374, 51.367008271445243],
            [-0.236804414033077, 51.366798309157844],
            [-0.233974085819122, 51.365386103834666],
            [-0.233215952311575, 51.366011349632856],
            [-0.226966958855556, 51.362625644098884],
            [-0.224082867811672, 51.359275370204223],
            [-0.222761373438006, 51.357087205878763],
            [-0.221999922828743, 51.353531653410229],
            [-0.222116909494779, 51.351325735942638],
            [-0.22128705478489, 51.351171953392011],
            [-0.220445912042543, 51.35142086385671],
            [-0.220210610259773, 51.350080982049981],
            [-0.217802599913431, 51.345632575628322],
            [-0.217289471780013, 51.343388293685948],
            [-0.221811021955465, 51.341346447102445],
            [-0.222725468375038, 51.340830658662291],
            [-0.222301827818819, 51.340461825715352],
            [-0.223286193415304, 51.339882346990201],
            [-0.224115609014211, 51.340042404914733],
            [-0.226851938541204, 51.337889653126169],
            [-0.229847683575384, 51.336524019157586],
            [-0.220967653952227, 51.329864396308807],
            [-0.233915485956021, 51.327316695727831],
            [-0.236068463659043, 51.326307791817172],
            [-0.242993610793352, 51.321281577475496],
            [-0.241165181109297, 51.317305452124515],
            [-0.241339652985913, 51.316157909949155],
            [-0.243668641558995, 51.31431699155295],
            [-0.244420252106812, 51.31300903688976],
            [-0.244218802015717, 51.311858551680537],
            [-0.244096491100018, 51.311341437562284],
            [-0.241464005944806, 51.311554629383757],
            [-0.240762398191106, 51.31057827765634],
            [-0.241054719450452, 51.309800305799122],
            [-0.242882532423252, 51.310291768631629],
            [-0.242804474043597, 51.310006428746185],
            [-0.244052116794306, 51.309421735253927],
            [-0.243245534708843, 51.306910572102929],
            [-0.246403674697828, 51.302234053451684],
            [-0.247158767451844, 51.298680658422384],
            [-0.247151396338055, 51.298045664234245],
            [-0.249919337400422, 51.298662599361727],
            [-0.251625556349182, 51.299626924585766],
            [-0.253308398644642, 51.299206002912598],
            [-0.253549838863381, 51.299420932278629],
            [-0.260479071588125, 51.299249800770397],
            [-0.264424099711526, 51.299547546230173],
            [-0.267480337318662, 51.297582945127999],
            [-0.269873226515056, 51.294103092181032],
            [-0.27225895633586, 51.292332591745236],
            [-0.272926969646399, 51.293373906317846],
            [-0.275338934955768, 51.298324848201304],
            [-0.27823139575766, 51.302141632488897],
            [-0.278806481915392, 51.306326272776595],
            [-0.280775334332046, 51.310421667139217],
            [-0.281521809983979, 51.311295017652505],
            [-0.284229841677097, 51.312858976772972],
            [-0.285557419091465, 51.31708517043797],
            [-0.287684634527788, 51.321118007453627],
            [-0.287687436317153, 51.32111984694118],
            [-0.288565410969859, 51.321668640149468],
            [-0.289272963607924, 51.32313398223345],
            [-0.291067480997716, 51.324819317179376],
            [-0.292302534865543, 51.324915567637923],
            [-0.292385760077769, 51.325263895653315],
            [-0.293594774095008, 51.325211374771925],
            [-0.297453124281705, 51.325861038377603],
            [-0.305031753625752, 51.332977085718916],
            [-0.306215856327961, 51.335085888831628],
            [-0.304949994878418, 51.336750986840087],
            [-0.305437971249957, 51.339480099445531],
            [-0.304048567768433, 51.343048925342636],
            [-0.301337976648807, 51.345294660368722],
            [-0.299949117278058, 51.347671030791595],
            [-0.297795047764908, 51.349262907193321],
            [-0.292930448472884, 51.352375486652392],
            [-0.292684698043797, 51.353616476228432],
            [-0.291224010890806, 51.355202149832017],
            [-0.291108817259515, 51.356561044213244],
            [-0.288996387811108, 51.358711805761843],
            [-0.286996708095148, 51.359375010120914],
            [-0.285101065127397, 51.36121233364269],
            [-0.288301978525112, 51.36208196465801],
            [-0.287044793510966, 51.362667889291103],
            [-0.285726158702563, 51.364052339720082],
            [-0.28409488184692, 51.365151629002284],
            [-0.282185554106219, 51.366001322352929],
            [-0.279843103424855, 51.367826613647345],
            [-0.276583902898574, 51.368809262798933],
            [-0.275856202533262, 51.370015249258962],
            [-0.27404629336714, 51.370656747498529],
            [-0.273612515550048, 51.371532526532683],
            [-0.272569172467904, 51.372265328968119],
            [-0.264702434796865, 51.375623804391331],
            [-0.262492592285146, 51.377254678228319],
            [-0.261124862273045, 51.379600330588929],
            [-0.25844436944285, 51.378970606829633],
            [-0.253971458955405, 51.378772745161292],
            [-0.251661790264767, 51.379049446463519],
            [-0.247917362499541, 51.380170641026552],
            [-0.245054294678756, 51.380035169219461],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000209",
        LAD13CDO: "43UD",
        LAD13NM: "Guildford",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.483238290382817, 51.330643505236125],
            [-0.481933730589868, 51.330114936821282],
            [-0.47891693012697, 51.330276330455],
            [-0.478430585634483, 51.329618063474513],
            [-0.477087275920313, 51.329265188639901],
            [-0.476836937015238, 51.328979571527178],
            [-0.477301671241726, 51.328512612613842],
            [-0.476824066218965, 51.328588233832065],
            [-0.476699714839035, 51.328269184205261],
            [-0.476044577030632, 51.328461193247357],
            [-0.475310280190976, 51.328269990279061],
            [-0.475151152848112, 51.328696857398278],
            [-0.474644304589222, 51.328701948144619],
            [-0.474637556446409, 51.327996854234954],
            [-0.473970653355314, 51.327462110526795],
            [-0.474511474879967, 51.327081581841568],
            [-0.474000305233818, 51.327001185386806],
            [-0.468266814597185, 51.327200616386627],
            [-0.467528622821693, 51.328548817609871],
            [-0.464681005734404, 51.328889208497017],
            [-0.462365765821458, 51.329962209647796],
            [-0.456171795068932, 51.331038061747329],
            [-0.451474067316168, 51.330940080691576],
            [-0.45129560721646, 51.330613094100521],
            [-0.449942986480316, 51.330371290216874],
            [-0.45129723679758, 51.328647367500807],
            [-0.446347949154227, 51.326826496477516],
            [-0.443809063930252, 51.326737038491309],
            [-0.437185395900716, 51.32744827690852],
            [-0.436624307980338, 51.326305041251437],
            [-0.436963257165376, 51.326045186576948],
            [-0.4390214260777, 51.326130186142464],
            [-0.440110976823459, 51.325067405108811],
            [-0.442209587093755, 51.324423598995772],
            [-0.443669919810465, 51.324105795392086],
            [-0.445665171274687, 51.324393971246678],
            [-0.445721019820749, 51.324142923602984],
            [-0.445954330070672, 51.317494295996163],
            [-0.443850912866445, 51.313074444196978],
            [-0.442979971237681, 51.307944349620222],
            [-0.44041599826532, 51.304901346536141],
            [-0.437448282673501, 51.303999406119637],
            [-0.431450130885307, 51.304382332574647],
            [-0.428582946119673, 51.303903261139062],
            [-0.426408346991319, 51.304483752160884],
            [-0.422144122828181, 51.304207856849473],
            [-0.416078850526838, 51.304493771302582],
            [-0.415429724076671, 51.303888786053832],
            [-0.411817020128816, 51.303568272088661],
            [-0.409416177273954, 51.303093255008669],
            [-0.408600409735599, 51.302332195538256],
            [-0.405420888161524, 51.297008530298235],
            [-0.405349268471837, 51.294894309332783],
            [-0.403952787656044, 51.294878880329229],
            [-0.402314273064461, 51.29654713266914],
            [-0.399902956876155, 51.297459323490884],
            [-0.397616603148175, 51.298075521983016],
            [-0.394765069497582, 51.297950138357542],
            [-0.396131606324455, 51.296440131332375],
            [-0.395790427770009, 51.29606406570371],
            [-0.396127995896623, 51.295144256325948],
            [-0.394583209443926, 51.291855194951573],
            [-0.3945296306929, 51.290677335319451],
            [-0.395532153204305, 51.286849457613165],
            [-0.397072287848686, 51.28577975494678],
            [-0.397399127835502, 51.279644108154983],
            [-0.397237579076329, 51.278806487372172],
            [-0.396064403021252, 51.27865103666165],
            [-0.394833303785133, 51.276455264912585],
            [-0.394688320810332, 51.276912797531494],
            [-0.393373878922648, 51.276945125788785],
            [-0.392638082780271, 51.276460218531845],
            [-0.392968769418127, 51.276351451597108],
            [-0.39207074958835, 51.275215949074479],
            [-0.393578303885927, 51.274537021552646],
            [-0.392875390492259, 51.273727035283379],
            [-0.393454570806116, 51.272535374380318],
            [-0.392134440970933, 51.271255592423621],
            [-0.390251589666831, 51.27147701465627],
            [-0.390396680447958, 51.270401697903246],
            [-0.389161785286388, 51.269148232359726],
            [-0.388597638001414, 51.267080266197695],
            [-0.387165269337897, 51.265083969322966],
            [-0.389973039276941, 51.264680182467274],
            [-0.390660490137212, 51.262603352949796],
            [-0.390085157298546, 51.257409409523099],
            [-0.389297002257013, 51.255930974222061],
            [-0.391592810086481, 51.256465227905984],
            [-0.392199615449558, 51.255092299790988],
            [-0.394371322479831, 51.255321743766181],
            [-0.394482500904017, 51.255092158777806],
            [-0.394887482347546, 51.252186806335608],
            [-0.395962640904278, 51.250568486830431],
            [-0.395516051726358, 51.246972515769492],
            [-0.395754031242522, 51.243797787143677],
            [-0.396797413734655, 51.24366370470144],
            [-0.396595736713372, 51.242129499206399],
            [-0.397365376187366, 51.241007868958306],
            [-0.396986612475245, 51.237891234530068],
            [-0.39737669410513, 51.237672660029816],
            [-0.402038634431484, 51.236004422113155],
            [-0.410996287844585, 51.235161469438857],
            [-0.415966897413074, 51.236620902960667],
            [-0.418650278281843, 51.238491679959274],
            [-0.42114156004538, 51.23957744652926],
            [-0.425572680259034, 51.241146991610918],
            [-0.427151459051654, 51.241041421803352],
            [-0.429361860731738, 51.240414644417868],
            [-0.432621747957396, 51.240857613812764],
            [-0.433835642406028, 51.240717391670955],
            [-0.434671095270214, 51.239908443597976],
            [-0.43402095829996, 51.238698337306388],
            [-0.430184948456652, 51.235130846701189],
            [-0.427292376348613, 51.233253926768818],
            [-0.426093926375546, 51.231771122680598],
            [-0.425699212478332, 51.230326097419599],
            [-0.42656752012716, 51.229476282027683],
            [-0.430635625080655, 51.227104696828071],
            [-0.434364323745677, 51.217755558024741],
            [-0.435278613412497, 51.216720146075708],
            [-0.436799772503045, 51.212899726605606],
            [-0.438328896424211, 51.211671966683873],
            [-0.438460606064316, 51.209682756948091],
            [-0.436891051023051, 51.208766135981037],
            [-0.433876054341942, 51.208554963427581],
            [-0.430860340366107, 51.207944430340412],
            [-0.428147366516994, 51.205718311382469],
            [-0.422359307821728, 51.203550526182177],
            [-0.419547593229245, 51.201803079509816],
            [-0.41897663192041, 51.201798064039963],
            [-0.418034912856906, 51.200874379760428],
            [-0.417743501824952, 51.199478375804311],
            [-0.414329717273749, 51.195602186244578],
            [-0.411658350187774, 51.194415790215764],
            [-0.41026161419036, 51.193414820878964],
            [-0.409902562552696, 51.192703117405713],
            [-0.411387632288906, 51.189761102136657],
            [-0.409350102731468, 51.184723593549819],
            [-0.409919469191379, 51.181400440187396],
            [-0.408091017286289, 51.177742524676383],
            [-0.408361259355935, 51.175711153141933],
            [-0.408855759879863, 51.175475976178049],
            [-0.412252914238419, 51.175324292238528],
            [-0.415514541054105, 51.17403399708077],
            [-0.416518855779753, 51.174157306360499],
            [-0.416808314147496, 51.173600079623611],
            [-0.418589215843148, 51.173185323744839],
            [-0.419258213163379, 51.173534289293826],
            [-0.422504369702234, 51.173568215180985],
            [-0.425493860861278, 51.174711891789407],
            [-0.425934256563748, 51.174508288506438],
            [-0.427917752797901, 51.175874869987467],
            [-0.429542530285214, 51.174075673639663],
            [-0.431276769869839, 51.176002692698482],
            [-0.431509064023245, 51.177799847116745],
            [-0.432907974854813, 51.17911623728007],
            [-0.434147424436713, 51.179651710368709],
            [-0.43719896904527, 51.179868769592254],
            [-0.437386753286483, 51.179561930003629],
            [-0.442014012487637, 51.182778269553495],
            [-0.443941000846907, 51.18359526881909],
            [-0.447621761386059, 51.181872611820026],
            [-0.447418393245266, 51.18064331439637],
            [-0.456429632114581, 51.175748247181488],
            [-0.457089869809168, 51.174954811319367],
            [-0.4576236112853, 51.176365603368829],
            [-0.459060390987578, 51.177940266111527],
            [-0.459470937159516, 51.177426796703756],
            [-0.460826249843736, 51.177197337502285],
            [-0.464372644744787, 51.177116245618549],
            [-0.466046328708453, 51.177897181946499],
            [-0.467623324475499, 51.179524833968806],
            [-0.475090204184656, 51.178571156988582],
            [-0.475608823336075, 51.178462809847254],
            [-0.47578879044439, 51.178038004303417],
            [-0.479412741842719, 51.177426900980649],
            [-0.481588584288914, 51.178608016534433],
            [-0.483102970199866, 51.178891145442414],
            [-0.485072375554436, 51.178998496350737],
            [-0.487358244315162, 51.178223232999386],
            [-0.488523073346233, 51.178127678298317],
            [-0.489385895343588, 51.17965587815295],
            [-0.490162091523242, 51.179983337287638],
            [-0.490371518206494, 51.18171891803086],
            [-0.491829962793011, 51.182186459188863],
            [-0.491589161185027, 51.183602389961692],
            [-0.49406714724075, 51.184399582773878],
            [-0.494732854073694, 51.184476493248013],
            [-0.495111083799566, 51.183231385517935],
            [-0.498057014283554, 51.183851961603395],
            [-0.501050160945299, 51.183548627877052],
            [-0.505365112298557, 51.18360652772072],
            [-0.504443281865684, 51.187641419157416],
            [-0.504777022846785, 51.189400140553438],
            [-0.50446188171419, 51.190382600716397],
            [-0.50498481098602, 51.192575383076374],
            [-0.507178636558219, 51.196253484566903],
            [-0.505625538342972, 51.197941351763667],
            [-0.503482024102928, 51.198675629052239],
            [-0.502837927006506, 51.199294172854273],
            [-0.503814548820652, 51.200163657611832],
            [-0.506525420626893, 51.20096355688505],
            [-0.505659793054284, 51.20502254575517],
            [-0.508590705754843, 51.205534744813235],
            [-0.51172064668929, 51.206624925791736],
            [-0.516740758446558, 51.206768698199461],
            [-0.519845845144929, 51.208017513525739],
            [-0.524292443012075, 51.208418086558559],
            [-0.524678789775605, 51.209454403574327],
            [-0.526857609239876, 51.208964780156549],
            [-0.527733667316057, 51.21139210247118],
            [-0.529288918503477, 51.211693124934243],
            [-0.53068764593947, 51.213359027707767],
            [-0.532467239525895, 51.213451499251832],
            [-0.533681316940648, 51.213137590673426],
            [-0.535076144542236, 51.213173044198761],
            [-0.537073603986833, 51.212580243720389],
            [-0.542659180489564, 51.212023154799724],
            [-0.543917887907276, 51.211607181291278],
            [-0.544295258574241, 51.210354720130944],
            [-0.547621630630086, 51.20960650613668],
            [-0.550316103915782, 51.206182287652425],
            [-0.5520605461528, 51.201407266657682],
            [-0.552870313723518, 51.200460477527855],
            [-0.553461192612696, 51.200834678928587],
            [-0.555580125357192, 51.199261980247194],
            [-0.55742142300759, 51.199654287627361],
            [-0.558151563279286, 51.200153379731908],
            [-0.559134683939329, 51.200114231889991],
            [-0.560032710347276, 51.200830300750695],
            [-0.560811625678275, 51.200684312343725],
            [-0.562367978471473, 51.201680931512932],
            [-0.565861983696068, 51.199975657538317],
            [-0.569522530242629, 51.200463794716377],
            [-0.57078115293277, 51.199384783604906],
            [-0.573593227326465, 51.198426321217894],
            [-0.574922999336289, 51.19684545091647],
            [-0.574396300299908, 51.196335453745533],
            [-0.574395721861981, 51.192968643515918],
            [-0.577524929440385, 51.192664110955405],
            [-0.57819048185474, 51.190847615508204],
            [-0.578305019196602, 51.189776198047582],
            [-0.576148326089396, 51.189734686222607],
            [-0.576261453799746, 51.189035544247275],
            [-0.577197639856954, 51.188142282779559],
            [-0.581891382140432, 51.188835935758803],
            [-0.581713399511944, 51.189739326440176],
            [-0.582708246715596, 51.189729798906271],
            [-0.583651817189964, 51.190786156123032],
            [-0.58473840382372, 51.190819087279856],
            [-0.584934861434689, 51.190432086903392],
            [-0.587217875082949, 51.190742939044753],
            [-0.586254335179128, 51.189868917957106],
            [-0.586797007255993, 51.189531059695966],
            [-0.58834410311389, 51.190181010210019],
            [-0.590925826341547, 51.190457624731444],
            [-0.593717652887568, 51.191260971298476],
            [-0.593372348874527, 51.193718972007332],
            [-0.592033711441912, 51.195004089393009],
            [-0.589625968241603, 51.19623944120179],
            [-0.589207298233055, 51.196217308462245],
            [-0.588739505997536, 51.195507553553355],
            [-0.587526620626948, 51.195997399356735],
            [-0.58710306306209, 51.196275550146346],
            [-0.587352436213666, 51.197060010598037],
            [-0.589030119468431, 51.197191759265571],
            [-0.586057613163668, 51.197840209587973],
            [-0.584894511347695, 51.198711013236867],
            [-0.586881170608564, 51.199879768261411],
            [-0.588335770142557, 51.199867649795102],
            [-0.588729010947709, 51.200207813680123],
            [-0.590137666683437, 51.200481979683033],
            [-0.591433274557247, 51.201225076017202],
            [-0.592520555616624, 51.202887383845606],
            [-0.594410754508642, 51.203286888881173],
            [-0.600206397512137, 51.204475025322445],
            [-0.603490650830594, 51.20436498914647],
            [-0.610142537212727, 51.20689013694728],
            [-0.61337243599073, 51.207498575546445],
            [-0.614134417148596, 51.208057941329159],
            [-0.616515773400036, 51.207033098819153],
            [-0.617697680600818, 51.20576024190877],
            [-0.61747269424762, 51.205529175191941],
            [-0.618742075285303, 51.204966847666704],
            [-0.618386180082257, 51.204613738245435],
            [-0.621279275630821, 51.202884434142568],
            [-0.623652161416987, 51.202321560859012],
            [-0.625901334299738, 51.203846139143067],
            [-0.628202709945059, 51.203570093095372],
            [-0.628717155306876, 51.202104953853564],
            [-0.629242324398967, 51.20235120574177],
            [-0.633172127211038, 51.20252396585628],
            [-0.635520604321562, 51.201618853506965],
            [-0.635428984277657, 51.201086330187579],
            [-0.634717555113923, 51.198472912687009],
            [-0.63706448342521, 51.198000289650707],
            [-0.638735608491371, 51.195154739172921],
            [-0.637852641561397, 51.19486120113033],
            [-0.637737133068177, 51.194507351330529],
            [-0.632468433189785, 51.193609594947389],
            [-0.629753559838231, 51.192790093263724],
            [-0.630103149916845, 51.192245644760447],
            [-0.629334416681568, 51.191578392328346],
            [-0.629937023266233, 51.191043202508084],
            [-0.631174961266134, 51.184132571226641],
            [-0.633290495813623, 51.183574587419422],
            [-0.632797273700664, 51.182883596772569],
            [-0.638386412011037, 51.180391306327266],
            [-0.640934509217081, 51.17888321482743],
            [-0.648139633884137, 51.175840894747843],
            [-0.650575541472944, 51.179136886919615],
            [-0.651526582909872, 51.181208930059945],
            [-0.652928244475866, 51.181077599044549],
            [-0.653184842754553, 51.181649777309595],
            [-0.650418357084636, 51.183148421289758],
            [-0.652296074972685, 51.183425450910562],
            [-0.656769057238517, 51.187354442087262],
            [-0.658566865301861, 51.190982845271961],
            [-0.660659487927496, 51.193189287183721],
            [-0.660900850509704, 51.195823243349437],
            [-0.663137267831125, 51.198759671927924],
            [-0.66342621897176, 51.200249424707984],
            [-0.666119811335803, 51.199264012037332],
            [-0.667400290126307, 51.199183280754802],
            [-0.669298925019701, 51.196872245265908],
            [-0.670979215486329, 51.196025367978201],
            [-0.676206269438353, 51.195215047046375],
            [-0.679559161905142, 51.195577556564267],
            [-0.679550652258203, 51.195274415170928],
            [-0.681404317305233, 51.195314201594478],
            [-0.682580770930425, 51.194560396921972],
            [-0.683009051558372, 51.194647948312934],
            [-0.684876571754792, 51.192390266580475],
            [-0.684721765915949, 51.191695209116688],
            [-0.685260674501816, 51.191565479401483],
            [-0.685397706656069, 51.190018519676229],
            [-0.688282354028718, 51.188717312127096],
            [-0.68874014805569, 51.188822259278723],
            [-0.688284255778291, 51.18910400866848],
            [-0.688457445979483, 51.189707544649252],
            [-0.691297479091086, 51.190179972252295],
            [-0.691323539380508, 51.191028251012952],
            [-0.6931724626564, 51.190976974917909],
            [-0.693733888976192, 51.191315064613967],
            [-0.693756514760064, 51.191780226283186],
            [-0.694414468315519, 51.191944037563665],
            [-0.694826189724186, 51.191857806926656],
            [-0.694420972192757, 51.191561931506989],
            [-0.694687386858774, 51.191198911423996],
            [-0.695614234704692, 51.191215541493634],
            [-0.69610946435162, 51.192581616499723],
            [-0.69619553197466, 51.192067308918418],
            [-0.696931788321994, 51.191941522734183],
            [-0.698891180296029, 51.191983109065632],
            [-0.699634309841059, 51.192731446852243],
            [-0.700362936133818, 51.192012053722031],
            [-0.700533144763182, 51.19267668629827],
            [-0.701951244618954, 51.192374992789027],
            [-0.702540271501094, 51.192650398969938],
            [-0.703758996137653, 51.194761839066743],
            [-0.703111994357361, 51.195887716039081],
            [-0.703240763696969, 51.19655188478395],
            [-0.706578998237454, 51.202713565074923],
            [-0.708933042898615, 51.203042603427079],
            [-0.713184456438029, 51.202087674459058],
            [-0.718589796904789, 51.200755861304593],
            [-0.718727353150058, 51.199615331707911],
            [-0.719986881264895, 51.200390772070293],
            [-0.731660280752594, 51.19686426040392],
            [-0.742230602354071, 51.205665894759143],
            [-0.745906145492388, 51.206844673588954],
            [-0.747219404709356, 51.208935078203858],
            [-0.74674428675205, 51.20919256869098],
            [-0.748323135473669, 51.210678818475742],
            [-0.747671266345818, 51.214391088320369],
            [-0.746597745391808, 51.214169175265809],
            [-0.746275087946546, 51.216455175429935],
            [-0.741876747456625, 51.219599368335309],
            [-0.742763840603137, 51.221570134095764],
            [-0.746142265363981, 51.221292541222155],
            [-0.746543791907187, 51.221956880784809],
            [-0.747387925719095, 51.221878691809685],
            [-0.747425300228679, 51.223139819698524],
            [-0.748252292480003, 51.223113596625026],
            [-0.748353572630767, 51.223497753877268],
            [-0.744400703387817, 51.223619077955654],
            [-0.741618649461621, 51.224181797250395],
            [-0.742386672787302, 51.225479555240796],
            [-0.741601017182137, 51.225563732495381],
            [-0.742125584564031, 51.22754499050896],
            [-0.742296500611134, 51.228367828573546],
            [-0.745669290350999, 51.227683733157605],
            [-0.746321466408451, 51.229087231345844],
            [-0.745558055075641, 51.230458477968718],
            [-0.743863663666346, 51.230617456413292],
            [-0.741775882657279, 51.231336002452807],
            [-0.739206426461124, 51.233482703947018],
            [-0.738866255142589, 51.235499618740718],
            [-0.735804100196389, 51.237682301140175],
            [-0.735917800114335, 51.238865122273261],
            [-0.736545410672328, 51.238963619677449],
            [-0.738201094625762, 51.240127101428655],
            [-0.73851633029609, 51.241119655530383],
            [-0.738103860312169, 51.241743774010175],
            [-0.737405188955142, 51.242407967734408],
            [-0.736546557840772, 51.245092805893101],
            [-0.735221985184408, 51.245590166318614],
            [-0.734700338457694, 51.246587173912665],
            [-0.735884273682119, 51.248503639360031],
            [-0.735759260137396, 51.249405118620203],
            [-0.734482954479639, 51.25059270315046],
            [-0.732832858458486, 51.250884190257864],
            [-0.730714130470917, 51.251958301709472],
            [-0.731173868078848, 51.253476692804107],
            [-0.730508411877115, 51.254901955267655],
            [-0.730553214532809, 51.255356553114758],
            [-0.729575776794308, 51.255724527310683],
            [-0.729414646274739, 51.256423280237378],
            [-0.730305984548035, 51.256944612922275],
            [-0.730417234272608, 51.25738014816951],
            [-0.732455941012542, 51.257662115846138],
            [-0.733319347594821, 51.259723505549779],
            [-0.733723828069547, 51.260128937846538],
            [-0.733249680619761, 51.261163319527],
            [-0.732009004814021, 51.26213544642507],
            [-0.732600376310245, 51.262729046983821],
            [-0.732687392968205, 51.263630118547795],
            [-0.731580281364506, 51.264277265813774],
            [-0.731263925773668, 51.265980577185296],
            [-0.73165152630902, 51.26679228513764],
            [-0.731503789272647, 51.268305887005567],
            [-0.730918788206638, 51.268519859105936],
            [-0.730156241321207, 51.269974638367934],
            [-0.730376305519507, 51.271508412593604],
            [-0.731172149479912, 51.272436950803595],
            [-0.730431417478381, 51.273462136044365],
            [-0.731570295222111, 51.274481615107788],
            [-0.731422652446071, 51.274949413102668],
            [-0.732234595647092, 51.275660504346625],
            [-0.732330376278549, 51.276451963212509],
            [-0.733087430997553, 51.276762297355695],
            [-0.732962083785836, 51.277358028732522],
            [-0.733400794278724, 51.277358278828302],
            [-0.733497860311388, 51.277790958284427],
            [-0.732842363372846, 51.27816603868105],
            [-0.732958147786496, 51.279169031711412],
            [-0.73231686958945, 51.279287083796824],
            [-0.732276971289537, 51.279642746170055],
            [-0.731111532408601, 51.280362994037318],
            [-0.729834812383925, 51.280021833442575],
            [-0.72834531081051, 51.280481359114901],
            [-0.72387645006668, 51.280055067455756],
            [-0.720252702089586, 51.279248477870993],
            [-0.719290635753464, 51.280486089403631],
            [-0.718995017983982, 51.280392033728425],
            [-0.715692495374975, 51.282053603435031],
            [-0.709883214451828, 51.282385406063469],
            [-0.706577662797451, 51.283321008407839],
            [-0.703844480973238, 51.284039845640152],
            [-0.697741156526429, 51.288774017109198],
            [-0.700480798609976, 51.291447284838888],
            [-0.699924698547189, 51.297833726573103],
            [-0.68751361665989, 51.313813049435602],
            [-0.675312558403115, 51.315522706257035],
            [-0.672734594309528, 51.316447602111403],
            [-0.671379294009966, 51.317366533668903],
            [-0.659007585155547, 51.314539551022698],
            [-0.656582167866931, 51.314211414757857],
            [-0.646809667818994, 51.311903898278011],
            [-0.649979331551899, 51.308572859850628],
            [-0.648646523169058, 51.307204147597297],
            [-0.647908134314602, 51.304356754015039],
            [-0.642063740719748, 51.300028575624403],
            [-0.641165607274139, 51.299597317546613],
            [-0.640827307271157, 51.299735470512474],
            [-0.633543404737321, 51.294368613048981],
            [-0.624917392382943, 51.28722663429069],
            [-0.622245465245275, 51.28584727360478],
            [-0.618621303711834, 51.283042122636466],
            [-0.618190986716802, 51.282712421011666],
            [-0.61646872949923, 51.283337736414104],
            [-0.61541643472351, 51.282806438849782],
            [-0.614930871759728, 51.283313261312365],
            [-0.61384149039364, 51.282728455845032],
            [-0.612561608564773, 51.282756461154904],
            [-0.611669130190417, 51.282157781873003],
            [-0.60908202129731, 51.283642294082412],
            [-0.607222233879321, 51.283973588173062],
            [-0.60701515262741, 51.283756207184908],
            [-0.605627270586499, 51.28379813900527],
            [-0.604985991882406, 51.284187064649082],
            [-0.603162173632643, 51.28399266894516],
            [-0.603243719620185, 51.284755295880011],
            [-0.602833039427926, 51.285010276110029],
            [-0.60359683860779, 51.285310739970981],
            [-0.60355813751897, 51.285641197796046],
            [-0.602373256453147, 51.286221457459497],
            [-0.601906904735818, 51.2859479176268],
            [-0.601539607198848, 51.286237582327828],
            [-0.594482593661461, 51.28394366185664],
            [-0.589971033623115, 51.281989330135026],
            [-0.582223969147606, 51.276999422319278],
            [-0.568191210514379, 51.273153462474092],
            [-0.566022750025072, 51.272167432926189],
            [-0.563750905078713, 51.270591989657689],
            [-0.561522077005504, 51.270214821671573],
            [-0.559704179989922, 51.269533327228714],
            [-0.559818280389531, 51.270285598964016],
            [-0.558254077622429, 51.270064017936392],
            [-0.557724323909535, 51.270821850187886],
            [-0.558245633580806, 51.271250015902076],
            [-0.554995832072142, 51.270882627870698],
            [-0.5543849740293, 51.270522583636236],
            [-0.554374251833074, 51.269493716396298],
            [-0.553543727384596, 51.269145342986782],
            [-0.553319370122959, 51.268392601448291],
            [-0.550996762130757, 51.26886116129964],
            [-0.550121101713139, 51.268353937773362],
            [-0.550189599371758, 51.26809040816854],
            [-0.551529102669967, 51.267795849332217],
            [-0.550752783447323, 51.266956239672346],
            [-0.547731297967803, 51.265677841254487],
            [-0.544391196126975, 51.265506870095614],
            [-0.541526457470561, 51.266303918944907],
            [-0.537467349344746, 51.268417766615883],
            [-0.536064765182577, 51.268844464539036],
            [-0.537857266315823, 51.26918519683062],
            [-0.539225293764222, 51.270119503459114],
            [-0.538109579811906, 51.271488616554095],
            [-0.538337438589024, 51.271942882337363],
            [-0.537692415673935, 51.273159599454864],
            [-0.534209743115402, 51.273848935361535],
            [-0.53514957024804, 51.27481569544836],
            [-0.535564230429527, 51.274309212323175],
            [-0.536068273918765, 51.274328105036943],
            [-0.535931262369341, 51.275075462665477],
            [-0.53705066330253, 51.275807051808975],
            [-0.536551024348875, 51.276596639166335],
            [-0.537675010027367, 51.276778841066445],
            [-0.539562941865417, 51.276516445199761],
            [-0.54177034038844, 51.277438705323],
            [-0.541929241290519, 51.279258055552845],
            [-0.543443643940889, 51.279882994166726],
            [-0.543589596774656, 51.28070851644874],
            [-0.544060311771368, 51.281012919134405],
            [-0.546088308656633, 51.281314185900271],
            [-0.546320538000068, 51.2819519346438],
            [-0.545519047962918, 51.282858309789717],
            [-0.546527624448277, 51.283522784487111],
            [-0.546929809450814, 51.283046681398496],
            [-0.547374959671816, 51.283071988027245],
            [-0.547708049574987, 51.28374065992309],
            [-0.547311136088367, 51.284367903728551],
            [-0.547789866441269, 51.284465564692503],
            [-0.548497658391813, 51.284029215788827],
            [-0.549211870953479, 51.284663040343574],
            [-0.547791385993991, 51.285917859559298],
            [-0.546852140037906, 51.286007821647274],
            [-0.545980901403491, 51.286577916751988],
            [-0.547299396878007, 51.287239937267294],
            [-0.546238236309114, 51.287829259734721],
            [-0.54503755789024, 51.287704639566428],
            [-0.545873058316932, 51.28881209299179],
            [-0.544391780372336, 51.289487903693335],
            [-0.545313746959522, 51.293396673065971],
            [-0.54622952915046, 51.294599539996305],
            [-0.545073574662496, 51.295640892569637],
            [-0.545018370470271, 51.296116804316661],
            [-0.543168031339795, 51.296617158052911],
            [-0.542478847562307, 51.296503371095781],
            [-0.542251423284075, 51.297751384329672],
            [-0.541442374195576, 51.298115394556092],
            [-0.541457349204172, 51.298819686572955],
            [-0.542270108984097, 51.298701215120126],
            [-0.542279858385985, 51.299072722852586],
            [-0.539513230760083, 51.299648836294345],
            [-0.539840770785036, 51.300718520858901],
            [-0.540316517879099, 51.300915090707392],
            [-0.539516029661497, 51.301278295213898],
            [-0.537979211158534, 51.301248328311409],
            [-0.536218988503055, 51.30030461802891],
            [-0.535416830738159, 51.300494220259942],
            [-0.534966318542945, 51.299911272404373],
            [-0.53558570711561, 51.299379474775527],
            [-0.53531491219723, 51.299054159848723],
            [-0.534518709709343, 51.298787015801182],
            [-0.533699774325874, 51.299143655154971],
            [-0.531200106133481, 51.297553944125056],
            [-0.529889213014794, 51.297550084169941],
            [-0.529322301237098, 51.29787478616867],
            [-0.529350284690443, 51.298525289448598],
            [-0.528246892298905, 51.29858066913625],
            [-0.525875089835547, 51.299633524324527],
            [-0.527225596488297, 51.300418469019235],
            [-0.525880653688536, 51.30143027949191],
            [-0.526100484532752, 51.302270240368173],
            [-0.525362966197013, 51.302768128104539],
            [-0.523797159173061, 51.302656678414436],
            [-0.523510872659179, 51.303039743248327],
            [-0.522926288461852, 51.30310700855933],
            [-0.522463030869962, 51.302254978165394],
            [-0.521380732127769, 51.303213398514686],
            [-0.521028510893038, 51.304352786388222],
            [-0.520105981822317, 51.303463472267971],
            [-0.518645337157696, 51.303739057415619],
            [-0.518653783323208, 51.302986498670244],
            [-0.516785667488031, 51.303175074656991],
            [-0.515802068317592, 51.303782190970757],
            [-0.51615308951614, 51.304596856289571],
            [-0.512255523284788, 51.305344136170767],
            [-0.511657257068518, 51.305966004081775],
            [-0.51078788010004, 51.30614289382077],
            [-0.510279798348992, 51.307168762038096],
            [-0.510760691886535, 51.309199072025265],
            [-0.511387965305738, 51.309323048991985],
            [-0.513061545028095, 51.308902781716782],
            [-0.51365448420039, 51.309467838245851],
            [-0.512400733156549, 51.309949200422103],
            [-0.512673921247158, 51.310552464260056],
            [-0.513385385721769, 51.310513837604304],
            [-0.51255021792431, 51.311138997151289],
            [-0.513036103296719, 51.311554321998187],
            [-0.512930423878315, 51.311982817558039],
            [-0.510833806310033, 51.312569460161299],
            [-0.510319419947283, 51.313123145949454],
            [-0.509733786337226, 51.313041058512198],
            [-0.509838846231099, 51.311702525437028],
            [-0.50791864985312, 51.31233452416344],
            [-0.506716116572296, 51.311808428304793],
            [-0.507639320747635, 51.311347194651397],
            [-0.507442807610985, 51.311124376149834],
            [-0.5048666072649, 51.311279457726783],
            [-0.505237341478849, 51.310114275955044],
            [-0.504770056114243, 51.309968029180951],
            [-0.503465622432643, 51.310416276296785],
            [-0.50201390894965, 51.308865407493826],
            [-0.503184962074953, 51.307091786572514],
            [-0.500869291548798, 51.306566684095074],
            [-0.49990652614876, 51.306878083477812],
            [-0.498382983116641, 51.308458312113373],
            [-0.493939696368022, 51.30968447673088],
            [-0.491899090453698, 51.311052943807006],
            [-0.491967592176374, 51.312070869979578],
            [-0.491089923205443, 51.313456989354094],
            [-0.491445729794914, 51.314639581143787],
            [-0.490899214068297, 51.314940081507629],
            [-0.488812900216505, 51.315025585756743],
            [-0.487905515832144, 51.315739563399923],
            [-0.488231463298031, 51.316125949396898],
            [-0.487471855462845, 51.316447063433834],
            [-0.484716110954499, 51.316160553596703],
            [-0.484049450111549, 51.316403714632422],
            [-0.483395889001984, 51.316162349382786],
            [-0.481996226396988, 51.316353740279503],
            [-0.48248808933529, 51.315406916258901],
            [-0.481553068574376, 51.315042293954392],
            [-0.477970428806079, 51.315551522878209],
            [-0.475341822588204, 51.316454345446338],
            [-0.474800349592534, 51.318892339508366],
            [-0.476338200954494, 51.320423984082453],
            [-0.476287254270419, 51.321008728714489],
            [-0.477344589436053, 51.32119243619173],
            [-0.478126799307508, 51.322100946363229],
            [-0.479089182656493, 51.322075678998601],
            [-0.478469096649005, 51.322555914220374],
            [-0.480134394545832, 51.322583830284216],
            [-0.481110770651457, 51.323091079449092],
            [-0.481095286844103, 51.322256381508332],
            [-0.482415259669842, 51.322354423553193],
            [-0.482520702667008, 51.32333416551738],
            [-0.483236069891524, 51.323662662575586],
            [-0.483463043043473, 51.32448301276073],
            [-0.4852502344911, 51.324211187818541],
            [-0.486054861307163, 51.324575892198361],
            [-0.487082388672213, 51.325756384818973],
            [-0.488045609351053, 51.325533221182475],
            [-0.489075986780784, 51.3257119776788],
            [-0.489371087833224, 51.326122241120515],
            [-0.487935283637255, 51.326574917143724],
            [-0.488331753595245, 51.327437910860297],
            [-0.489226506801179, 51.328076226056929],
            [-0.48920181325441, 51.329744898818539],
            [-0.487823915160518, 51.329873694628319],
            [-0.48724841732175, 51.329172948713257],
            [-0.486843461274048, 51.329178508934589],
            [-0.486593499172776, 51.329664466747666],
            [-0.487244578270081, 51.330336517036194],
            [-0.486523243981078, 51.330708477088095],
            [-0.485918531041132, 51.330502829032191],
            [-0.483818392686907, 51.331507992378498],
            [-0.483252944400015, 51.331243488890408],
            [-0.483238290382817, 51.330643505236125],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000210",
        LAD13CDO: "43UE",
        LAD13NM: "Mole Valley",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.305031753625752, 51.332977085718916],
            [-0.297453124281705, 51.325861038377603],
            [-0.293594774095008, 51.325211374771925],
            [-0.292385760077769, 51.325263895653315],
            [-0.292302534865543, 51.324915567637923],
            [-0.291067480997716, 51.324819317179376],
            [-0.289272963607924, 51.32313398223345],
            [-0.288565410969859, 51.321668640149468],
            [-0.287687436317153, 51.32111984694118],
            [-0.287684634527788, 51.321118007453627],
            [-0.285557419091465, 51.31708517043797],
            [-0.284229841677097, 51.312858976772972],
            [-0.281521809983979, 51.311295017652505],
            [-0.280775334332046, 51.310421667139217],
            [-0.278806481915392, 51.306326272776595],
            [-0.27823139575766, 51.302141632488897],
            [-0.275338934955768, 51.298324848201304],
            [-0.272926969646399, 51.293373906317846],
            [-0.27225895633586, 51.292332591745236],
            [-0.27023989099514, 51.289078906632412],
            [-0.2702712222797, 51.287367163371449],
            [-0.269504612000763, 51.286549191700388],
            [-0.268897783872628, 51.284113994704157],
            [-0.266191748502519, 51.278632474095019],
            [-0.266911856859725, 51.276718699041346],
            [-0.267079081251533, 51.274408251905726],
            [-0.266150004761854, 51.273152603252342],
            [-0.266898735946214, 51.268646653157596],
            [-0.265799425679541, 51.265747309192854],
            [-0.266488154416029, 51.262566894243982],
            [-0.265768525429219, 51.261388980001762],
            [-0.264146507203561, 51.260996236684896],
            [-0.263505306509535, 51.260477738309987],
            [-0.261358942953977, 51.25935865736664],
            [-0.260859052631854, 51.257831459923452],
            [-0.257957181361667, 51.25786115048205],
            [-0.25445093166989, 51.259090381875893],
            [-0.253372293828789, 51.260550000170632],
            [-0.251496534301159, 51.260653290507122],
            [-0.248903884148608, 51.260235041119245],
            [-0.248721323489926, 51.259494905178904],
            [-0.247661397375375, 51.259138217756679],
            [-0.243771812425136, 51.259509778551624],
            [-0.243970348247091, 51.260091889963725],
            [-0.243510696698901, 51.260156036230811],
            [-0.24093766504356, 51.259864701886684],
            [-0.240711366101933, 51.26064906576439],
            [-0.233260450406818, 51.25846401462347],
            [-0.229364376556691, 51.25717493455177],
            [-0.226769142664623, 51.255902739386109],
            [-0.226004972415066, 51.253788650201123],
            [-0.227397992728539, 51.253450942885195],
            [-0.227314285644483, 51.253168201394374],
            [-0.227980983498543, 51.252823981302704],
            [-0.227932234838194, 51.251370910287676],
            [-0.231542817494965, 51.25078703125061],
            [-0.231565602906587, 51.250043673229357],
            [-0.22659118008004, 51.249204922500383],
            [-0.22636328111801, 51.248871434241941],
            [-0.226568664072543, 51.24755530539251],
            [-0.229550839476716, 51.247582467456141],
            [-0.229497363855535, 51.246512417084659],
            [-0.229278028751578, 51.245060361923215],
            [-0.230017416444834, 51.245021186233274],
            [-0.231826282679723, 51.242238282943802],
            [-0.230641025806439, 51.240382245069142],
            [-0.231063408379781, 51.239753739063573],
            [-0.233761487875088, 51.238021110330379],
            [-0.23729323341364, 51.237071660978216],
            [-0.239053572871433, 51.237303196440799],
            [-0.244881603934565, 51.236312507836921],
            [-0.245567858292104, 51.235745460984852],
            [-0.245678167146908, 51.235105030149143],
            [-0.247206057717593, 51.234139616870166],
            [-0.246825798218363, 51.233629426150337],
            [-0.247545004640032, 51.23324901197693],
            [-0.247886143630711, 51.232526603864684],
            [-0.248668095448012, 51.232454675308901],
            [-0.24938631935784, 51.231872796950185],
            [-0.249959262685626, 51.230929030598155],
            [-0.248807529604654, 51.229121338947238],
            [-0.250193437979733, 51.228644768434116],
            [-0.250115154923804, 51.228178670974117],
            [-0.249373777774439, 51.227824957801687],
            [-0.25075265297997, 51.227079391317261],
            [-0.25007416759665, 51.226729320353954],
            [-0.250032327487086, 51.225529056863344],
            [-0.249125633148433, 51.2253437324892],
            [-0.249157255579486, 51.224246185934],
            [-0.247627745061593, 51.224359083327329],
            [-0.246572736665054, 51.223366662835879],
            [-0.246460497713872, 51.22398368528718],
            [-0.245547167114241, 51.224463700261786],
            [-0.245017529349498, 51.224569967043998],
            [-0.244753233797176, 51.224101075623437],
            [-0.244344420366416, 51.22411832431343],
            [-0.244373758876098, 51.22466462716045],
            [-0.242926289443626, 51.224766104062496],
            [-0.242567440142213, 51.224524204924002],
            [-0.243041776558073, 51.224177910536312],
            [-0.242591108996452, 51.223413949815097],
            [-0.242202045726246, 51.223739940597447],
            [-0.240991984192932, 51.223888124754922],
            [-0.240228770388399, 51.223359564058235],
            [-0.239093448840387, 51.223500758951637],
            [-0.237624618835839, 51.222812280480781],
            [-0.235413343320679, 51.222366187248319],
            [-0.236073200763603, 51.221775416006345],
            [-0.234871655441318, 51.220915571124976],
            [-0.236030437014906, 51.219897072818419],
            [-0.235654825663467, 51.219234035632496],
            [-0.234584420394258, 51.219028143274343],
            [-0.235246829401202, 51.21833219916234],
            [-0.23452015213443, 51.218237604596638],
            [-0.234200060873427, 51.217733673578223],
            [-0.233789590475012, 51.216336290009266],
            [-0.234372022102173, 51.215783927874078],
            [-0.233784951521921, 51.215297549438759],
            [-0.234861545395972, 51.214553002141066],
            [-0.233047638988939, 51.213424904069392],
            [-0.231551694450914, 51.213230541975868],
            [-0.227348476913573, 51.213925037109171],
            [-0.227034238282711, 51.213718836857105],
            [-0.227350527444105, 51.213498808113457],
            [-0.225936798146606, 51.213214791967772],
            [-0.225096210006072, 51.212324344066992],
            [-0.226484253297777, 51.210741968629883],
            [-0.228658380835394, 51.209826157911337],
            [-0.23042300296916, 51.210179298076802],
            [-0.232453460405812, 51.209722576520662],
            [-0.233577752821574, 51.209038114172138],
            [-0.230749818332758, 51.20725527488112],
            [-0.234894662026827, 51.206272908400031],
            [-0.234784315644331, 51.206088689236907],
            [-0.232697540633432, 51.204318873432875],
            [-0.231582095305004, 51.204775042955006],
            [-0.229574695858619, 51.206950628594001],
            [-0.228364953690674, 51.206988972583467],
            [-0.226528613305381, 51.205785789372065],
            [-0.226222166558551, 51.204185813985198],
            [-0.22307772013395, 51.203872812877236],
            [-0.223379016440828, 51.202032055961013],
            [-0.224769373380388, 51.202170066699786],
            [-0.225142524878961, 51.201288133779641],
            [-0.224169170630617, 51.199203210171973],
            [-0.223611121817817, 51.195960908119737],
            [-0.221015650933395, 51.196087839344358],
            [-0.221231626077987, 51.197280878331448],
            [-0.221690793554343, 51.197250987675424],
            [-0.221785643967299, 51.197538402173933],
            [-0.221110644241453, 51.1976720267918],
            [-0.221494449329591, 51.198298368817802],
            [-0.220652556208369, 51.198305352328852],
            [-0.219877848901601, 51.199280084149152],
            [-0.220460357699585, 51.199430133319289],
            [-0.220021554549986, 51.199748100451217],
            [-0.220726328280975, 51.200254326729322],
            [-0.220075701663057, 51.200608641127651],
            [-0.220678850605812, 51.201003608687948],
            [-0.21968585636795, 51.20125289311197],
            [-0.219387058366909, 51.201985760126121],
            [-0.218576292453739, 51.201852014952536],
            [-0.217699322916871, 51.200874625074796],
            [-0.21468443637755, 51.199476133289515],
            [-0.215792520460357, 51.198100042381149],
            [-0.215994248302805, 51.196805448434347],
            [-0.214887270691415, 51.195006177503281],
            [-0.212847085321074, 51.193322140783771],
            [-0.215845403608912, 51.192914663161098],
            [-0.21558639810194, 51.192283909967337],
            [-0.218092300140428, 51.191617063242475],
            [-0.218962781602129, 51.190939666047612],
            [-0.22093186903698, 51.190831136057795],
            [-0.221732498234108, 51.191372088864711],
            [-0.221842092464974, 51.191871060067996],
            [-0.224400689822833, 51.191915315732174],
            [-0.227191289655683, 51.19158263324131],
            [-0.227303103941914, 51.189228197966578],
            [-0.228495906592421, 51.18745488763178],
            [-0.232157296995588, 51.186720699859087],
            [-0.233473346104072, 51.186620075341075],
            [-0.234366905450802, 51.187801736984937],
            [-0.235196827465241, 51.187129003751956],
            [-0.236534133962959, 51.187258882545436],
            [-0.236817353153056, 51.186474477914722],
            [-0.237063935719593, 51.186912548961459],
            [-0.238477175507667, 51.187190133296525],
            [-0.238022423429569, 51.187294796520611],
            [-0.238387820473286, 51.187769726168256],
            [-0.238049757871147, 51.188154025719719],
            [-0.238560734775789, 51.188639240731803],
            [-0.237878951305257, 51.18869282286137],
            [-0.238205720017544, 51.18939289166098],
            [-0.239640299758808, 51.188999916166466],
            [-0.239863796152165, 51.189293747953009],
            [-0.241428777274433, 51.189121238644439],
            [-0.241629724521455, 51.189519045063427],
            [-0.242926582082797, 51.189510651737727],
            [-0.243715866256555, 51.188210446959445],
            [-0.244992398741902, 51.188133380160025],
            [-0.245164438072127, 51.186617968323198],
            [-0.243239376330363, 51.18388132880834],
            [-0.243078807209604, 51.182835746334177],
            [-0.24093820425998, 51.183438474169286],
            [-0.23911430000845, 51.183464092544952],
            [-0.23914709095715, 51.184068006886726],
            [-0.237267049354526, 51.184476746685426],
            [-0.236193660506365, 51.183432684105185],
            [-0.236700516661094, 51.182674133154215],
            [-0.234625107203338, 51.181268722467713],
            [-0.228969040629797, 51.180263259987456],
            [-0.229009303004287, 51.179212602542414],
            [-0.22776298310297, 51.178134366820125],
            [-0.225458840788762, 51.177109320402472],
            [-0.223788402620298, 51.174934668906161],
            [-0.221756316047244, 51.175025199455185],
            [-0.221030094333576, 51.175903554086268],
            [-0.21999739993062, 51.176269143024363],
            [-0.218062303997642, 51.173990559226738],
            [-0.21450528794988, 51.174339201490156],
            [-0.213942477542395, 51.17468762753839],
            [-0.212776314972439, 51.173620344818794],
            [-0.211119862487511, 51.172951132951894],
            [-0.210789619072667, 51.172391219992498],
            [-0.209728334907029, 51.172312930947164],
            [-0.206904648063186, 51.172566446574294],
            [-0.202616560621003, 51.174241663228024],
            [-0.201904575831125, 51.173641685418531],
            [-0.20071157868587, 51.173786112685399],
            [-0.200027684868443, 51.174265704852012],
            [-0.197957024672768, 51.174537784409146],
            [-0.196949336550937, 51.175358597324596],
            [-0.198104336442752, 51.176373708534278],
            [-0.199596685412572, 51.179013623056086],
            [-0.200761203590545, 51.180118782156001],
            [-0.200619399146188, 51.180637290097145],
            [-0.199653841646752, 51.181108950133776],
            [-0.198624228806401, 51.181388067068838],
            [-0.195945572868694, 51.180769443650938],
            [-0.192657492789703, 51.180735775824495],
            [-0.190584319096822, 51.181465426730462],
            [-0.189846478196953, 51.181417140846108],
            [-0.1896853682908, 51.181073816327221],
            [-0.187741651537316, 51.180398029162035],
            [-0.18803714199833, 51.179831556337199],
            [-0.189795964522049, 51.178871364360141],
            [-0.189819734317941, 51.178337552933478],
            [-0.188689073081096, 51.178311058131982],
            [-0.188717876763008, 51.177868153242464],
            [-0.187521592522799, 51.177508792193301],
            [-0.187365481456423, 51.176455100730962],
            [-0.18626010124277, 51.175749108862654],
            [-0.186622427862645, 51.175265511181884],
            [-0.185762124003422, 51.175351093576602],
            [-0.18281374267351, 51.17465603876348],
            [-0.182937890479673, 51.173281149663488],
            [-0.182076957513733, 51.172437725062565],
            [-0.180231793910667, 51.172577201865515],
            [-0.17917099728294, 51.171942879239005],
            [-0.178939894336184, 51.170982432949415],
            [-0.17674015370643, 51.170211643720307],
            [-0.176570081513038, 51.169808806663532],
            [-0.177384280465617, 51.168931194947483],
            [-0.177085662100078, 51.167906741589128],
            [-0.176733834520691, 51.166853579693026],
            [-0.177644391972538, 51.167303927636432],
            [-0.179184840570827, 51.167087806064515],
            [-0.185325618984629, 51.164823490025192],
            [-0.186798142847346, 51.163856203652614],
            [-0.18629960565867, 51.162126325788378],
            [-0.186893886884038, 51.161669705082808],
            [-0.188912679551883, 51.162416820102258],
            [-0.190537064119202, 51.162023789399257],
            [-0.194954058988067, 51.162450856282952],
            [-0.195949421994738, 51.162306138747304],
            [-0.204137375584255, 51.15571532400908],
            [-0.206545306346282, 51.155167731688657],
            [-0.212169681288164, 51.155458880046076],
            [-0.211216101822775, 51.152531490167824],
            [-0.211602424296201, 51.150416864835414],
            [-0.214334801882783, 51.150307533643343],
            [-0.21426210421471, 51.148468043567107],
            [-0.215911589529696, 51.148239841919725],
            [-0.217009215560211, 51.149364509810304],
            [-0.219339230695199, 51.149194070328491],
            [-0.222908500513887, 51.148102660787906],
            [-0.226658694529176, 51.148180268382674],
            [-0.229068505399342, 51.14566099530262],
            [-0.230175125593202, 51.145959225508449],
            [-0.236300030563973, 51.145883585859821],
            [-0.236093945190137, 51.146714120878507],
            [-0.2387251410426, 51.146814028223282],
            [-0.241952099933576, 51.146022643355465],
            [-0.242317257393011, 51.146233170213527],
            [-0.243280585035836, 51.145563283974525],
            [-0.243370611439244, 51.144886570977569],
            [-0.244222189903475, 51.144410144610028],
            [-0.246618989611505, 51.145938925338406],
            [-0.24854823626831, 51.14407302868829],
            [-0.250116633397997, 51.144260177341941],
            [-0.251211828322654, 51.143993282765592],
            [-0.251532915997282, 51.14359250377251],
            [-0.253307832307675, 51.143989533715512],
            [-0.252876090594666, 51.144405750998672],
            [-0.255194841221165, 51.144144501388297],
            [-0.255523257311817, 51.140514470664925],
            [-0.254640544389946, 51.139054341883273],
            [-0.260530565658841, 51.138089929617195],
            [-0.264698261919784, 51.135666209391928],
            [-0.26595586969347, 51.1339177592534],
            [-0.267216986406667, 51.132913060535721],
            [-0.275317959167158, 51.13031246565879],
            [-0.279423669733656, 51.129978158683038],
            [-0.282189093956893, 51.130134823080965],
            [-0.284668618972607, 51.130640649035705],
            [-0.286416905950286, 51.130496315105738],
            [-0.28784047057927, 51.129857088964172],
            [-0.289343027434534, 51.128142552890537],
            [-0.291624970063289, 51.126914210909334],
            [-0.30037378792558, 51.124739521134096],
            [-0.305620345833919, 51.124451449154122],
            [-0.308893771959301, 51.125051896313593],
            [-0.309211734497053, 51.124719261537571],
            [-0.309654672466809, 51.12487764468203],
            [-0.309653957108665, 51.124544898139213],
            [-0.312578196082836, 51.124023279525503],
            [-0.318242286638371, 51.124353080050795],
            [-0.319359351810618, 51.124640724572075],
            [-0.320636730008763, 51.125118611501719],
            [-0.326453388183944, 51.125818910984627],
            [-0.329709078115443, 51.124266530355492],
            [-0.330595770530052, 51.123057963033517],
            [-0.331645872342596, 51.122468630212524],
            [-0.336276411727575, 51.120977941836486],
            [-0.342077543267898, 51.119908352170043],
            [-0.344355876576357, 51.118929837271324],
            [-0.34664670437953, 51.118959552690271],
            [-0.350521789948578, 51.118276866777194],
            [-0.352730048804162, 51.118297204773754],
            [-0.353989679369017, 51.11875649111095],
            [-0.355352567330698, 51.120050851713906],
            [-0.358059754589055, 51.121034042575232],
            [-0.359690473127685, 51.120581208270416],
            [-0.35926594578623, 51.119778487781602],
            [-0.360284820909588, 51.11961472143873],
            [-0.360478295702601, 51.119194771334932],
            [-0.360044661555134, 51.119012431104395],
            [-0.361425367514075, 51.119000312009334],
            [-0.361485996021996, 51.119306018450736],
            [-0.361871495493085, 51.118910339428759],
            [-0.363655985722985, 51.118793238170738],
            [-0.36457133201926, 51.119496692744711],
            [-0.365491655375062, 51.118521249233012],
            [-0.365206337488458, 51.117982187528696],
            [-0.367365845812934, 51.117439515901737],
            [-0.370167213955356, 51.117413847249701],
            [-0.37598130302359, 51.116713264829528],
            [-0.379377862324069, 51.117112007769776],
            [-0.383126734029414, 51.11699753786462],
            [-0.3846569258975, 51.114745303717179],
            [-0.385725064320458, 51.114965990025844],
            [-0.390370774930846, 51.114734169932071],
            [-0.391550509969431, 51.116514792095685],
            [-0.395526467109966, 51.113884164375833],
            [-0.396529807037893, 51.112180301889012],
            [-0.398119871934745, 51.11234598075896],
            [-0.399676706616575, 51.111612802166746],
            [-0.399187284833098, 51.110107002214406],
            [-0.400948562777718, 51.109697648793443],
            [-0.400243981490172, 51.10842362251207],
            [-0.401956940096383, 51.107797765672984],
            [-0.403875907668843, 51.107700775369274],
            [-0.404040203995872, 51.108073521571747],
            [-0.404406681561619, 51.107636077200524],
            [-0.406872866860714, 51.107327978821296],
            [-0.40950717441095, 51.107531108068336],
            [-0.411204241197745, 51.10678079954306],
            [-0.419228744361827, 51.105208809044264],
            [-0.419822620921169, 51.106768992560305],
            [-0.419546967944831, 51.108024257693621],
            [-0.418943419462919, 51.109217534917001],
            [-0.417411692912738, 51.108934223902217],
            [-0.417312064903885, 51.109132515368131],
            [-0.418232335265751, 51.109375183597152],
            [-0.418472904937828, 51.110153616088141],
            [-0.418662510618854, 51.113848618074499],
            [-0.418323619407332, 51.114359320997345],
            [-0.418382482754985, 51.117029174354592],
            [-0.417885847697888, 51.118183423615093],
            [-0.417972782372567, 51.121411207350619],
            [-0.417029812312087, 51.12471048390649],
            [-0.413329700557797, 51.125430120258578],
            [-0.413261609575248, 51.126332969795904],
            [-0.413745496942324, 51.128143483667216],
            [-0.415845440257829, 51.131135064052991],
            [-0.415956764326742, 51.131473800486958],
            [-0.415133699998607, 51.13159394356321],
            [-0.415334762583845, 51.132191988876833],
            [-0.416349382496077, 51.132405371960651],
            [-0.415733459170323, 51.137539986453817],
            [-0.415974644694403, 51.139138567198238],
            [-0.415356718767389, 51.140110406017435],
            [-0.414861207916681, 51.142474179280434],
            [-0.415139250975927, 51.145041778338076],
            [-0.414585753386939, 51.150180820839203],
            [-0.414864031313544, 51.152324862824081],
            [-0.415994101247904, 51.154984034206151],
            [-0.415517806200894, 51.156365160751591],
            [-0.416363432109219, 51.157353222325241],
            [-0.415662248101584, 51.157492107015955],
            [-0.415236094973328, 51.15845215252817],
            [-0.415716148883282, 51.163224796003277],
            [-0.416648203036509, 51.167080900387575],
            [-0.416215324995062, 51.168610994921345],
            [-0.41846620166084, 51.172223240566012],
            [-0.418589215843148, 51.173185323744839],
            [-0.416808314147496, 51.173600079623611],
            [-0.416518855779753, 51.174157306360499],
            [-0.415514541054105, 51.17403399708077],
            [-0.412252914238419, 51.175324292238528],
            [-0.408855759879863, 51.175475976178049],
            [-0.408361259355935, 51.175711153141933],
            [-0.408091017286289, 51.177742524676383],
            [-0.409919469191379, 51.181400440187396],
            [-0.409350102731468, 51.184723593549819],
            [-0.411387632288906, 51.189761102136657],
            [-0.409902562552696, 51.192703117405713],
            [-0.41026161419036, 51.193414820878964],
            [-0.411658350187774, 51.194415790215764],
            [-0.414329717273749, 51.195602186244578],
            [-0.417743501824952, 51.199478375804311],
            [-0.418034912856906, 51.200874379760428],
            [-0.41897663192041, 51.201798064039963],
            [-0.419547593229245, 51.201803079509816],
            [-0.422359307821728, 51.203550526182177],
            [-0.428147366516994, 51.205718311382469],
            [-0.430860340366107, 51.207944430340412],
            [-0.433876054341942, 51.208554963427581],
            [-0.436891051023051, 51.208766135981037],
            [-0.438460606064316, 51.209682756948091],
            [-0.438328896424211, 51.211671966683873],
            [-0.436799772503045, 51.212899726605606],
            [-0.435278613412497, 51.216720146075708],
            [-0.434364323745677, 51.217755558024741],
            [-0.430635625080655, 51.227104696828071],
            [-0.42656752012716, 51.229476282027683],
            [-0.425699212478332, 51.230326097419599],
            [-0.426093926375546, 51.231771122680598],
            [-0.427292376348613, 51.233253926768818],
            [-0.430184948456652, 51.235130846701189],
            [-0.43402095829996, 51.238698337306388],
            [-0.434671095270214, 51.239908443597976],
            [-0.433835642406028, 51.240717391670955],
            [-0.432621747957396, 51.240857613812764],
            [-0.429361860731738, 51.240414644417868],
            [-0.427151459051654, 51.241041421803352],
            [-0.425572680259034, 51.241146991610918],
            [-0.42114156004538, 51.23957744652926],
            [-0.418650278281843, 51.238491679959274],
            [-0.415966897413074, 51.236620902960667],
            [-0.410996287844585, 51.235161469438857],
            [-0.402038634431484, 51.236004422113155],
            [-0.39737669410513, 51.237672660029816],
            [-0.396986612475245, 51.237891234530068],
            [-0.397365376187366, 51.241007868958306],
            [-0.396595736713372, 51.242129499206399],
            [-0.396797413734655, 51.24366370470144],
            [-0.395754031242522, 51.243797787143677],
            [-0.395516051726358, 51.246972515769492],
            [-0.395962640904278, 51.250568486830431],
            [-0.394887482347546, 51.252186806335608],
            [-0.394482500904017, 51.255092158777806],
            [-0.394371322479831, 51.255321743766181],
            [-0.392199615449558, 51.255092299790988],
            [-0.391592810086481, 51.256465227905984],
            [-0.389297002257013, 51.255930974222061],
            [-0.390085157298546, 51.257409409523099],
            [-0.390660490137212, 51.262603352949796],
            [-0.389973039276941, 51.264680182467274],
            [-0.387165269337897, 51.265083969322966],
            [-0.388597638001414, 51.267080266197695],
            [-0.389161785286388, 51.269148232359726],
            [-0.390396680447958, 51.270401697903246],
            [-0.390251589666831, 51.27147701465627],
            [-0.392134440970933, 51.271255592423621],
            [-0.393454570806116, 51.272535374380318],
            [-0.392875390492259, 51.273727035283379],
            [-0.393578303885927, 51.274537021552646],
            [-0.39207074958835, 51.275215949074479],
            [-0.392968769418127, 51.276351451597108],
            [-0.392638082780271, 51.276460218531845],
            [-0.393373878922648, 51.276945125788785],
            [-0.394688320810332, 51.276912797531494],
            [-0.394833303785133, 51.276455264912585],
            [-0.396064403021252, 51.27865103666165],
            [-0.397237579076329, 51.278806487372172],
            [-0.397399127835502, 51.279644108154983],
            [-0.397072287848686, 51.28577975494678],
            [-0.395532153204305, 51.286849457613165],
            [-0.3945296306929, 51.290677335319451],
            [-0.394583209443926, 51.291855194951573],
            [-0.396127995896623, 51.295144256325948],
            [-0.395790427770009, 51.29606406570371],
            [-0.396131606324455, 51.296440131332375],
            [-0.394765069497582, 51.297950138357542],
            [-0.394101785830866, 51.298665842805256],
            [-0.39399389734987, 51.300853147441998],
            [-0.393113844435469, 51.300996645411765],
            [-0.392761234030478, 51.301563731782622],
            [-0.392962796245954, 51.306718321310399],
            [-0.392024262074092, 51.308408623526276],
            [-0.392800479023174, 51.30918814012901],
            [-0.395077737290774, 51.310207649502871],
            [-0.396145595655378, 51.310320297500702],
            [-0.397221052865301, 51.311120967610165],
            [-0.397614778561385, 51.312010320176583],
            [-0.396949539006889, 51.312818636123779],
            [-0.3924104952947, 51.312218664148311],
            [-0.391386341296355, 51.311347613441072],
            [-0.392161870465409, 51.310752167168133],
            [-0.391266161165647, 51.310229990000742],
            [-0.390018449389409, 51.310460138104219],
            [-0.387504344584656, 51.309733123215231],
            [-0.386597893776419, 51.310010198180862],
            [-0.386662046102723, 51.310840193431041],
            [-0.384383723323647, 51.312919331789416],
            [-0.380447914158747, 51.313569983833254],
            [-0.378849702366, 51.312911199648831],
            [-0.378545578384953, 51.311606668452512],
            [-0.377475934398338, 51.310365271319341],
            [-0.373755955657771, 51.309622145907603],
            [-0.373471275059988, 51.309273777779907],
            [-0.371946407122711, 51.309914440228773],
            [-0.371103241008753, 51.311399974437904],
            [-0.368674404772919, 51.312928171608434],
            [-0.368101825074823, 51.314345512772789],
            [-0.367323920868783, 51.314876021705075],
            [-0.365643654234614, 51.313865203326003],
            [-0.36524320366136, 51.312980142904088],
            [-0.363744884186468, 51.312948427149408],
            [-0.363978767637962, 51.311899566218024],
            [-0.360339081779574, 51.311804599806891],
            [-0.359869553605586, 51.312417612045536],
            [-0.358220900125207, 51.313181359677067],
            [-0.357064061204679, 51.31539978618742],
            [-0.352522260862443, 51.319129941839968],
            [-0.345359300292738, 51.322414658798834],
            [-0.338193517138604, 51.324359009625418],
            [-0.33579651087524, 51.325315055196427],
            [-0.332286841180647, 51.327503381544147],
            [-0.330679062415786, 51.329011011297645],
            [-0.330139022901282, 51.32751864109391],
            [-0.328314308800709, 51.32646386173721],
            [-0.327597596711242, 51.326319640605995],
            [-0.327323035100057, 51.326686213537137],
            [-0.323970443291982, 51.326717420032395],
            [-0.318978993259164, 51.327957901766752],
            [-0.316765189540393, 51.329147298539773],
            [-0.313716436856299, 51.331416334323485],
            [-0.310953248076609, 51.332390882020469],
            [-0.307635971382265, 51.334609126935455],
            [-0.306215856327961, 51.335085888831628],
            [-0.305031753625752, 51.332977085718916],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000211",
        LAD13CDO: "43UF",
        LAD13NM: "Reigate and Banstead",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.196715478322556, 51.34315968441139],
            [-0.196292264268228, 51.343040759534276],
            [-0.196072653728369, 51.343331438167709],
            [-0.193254490924973, 51.342627039422673],
            [-0.187382866831064, 51.340783645598485],
            [-0.184632974073882, 51.339194313158238],
            [-0.182392672661727, 51.338047171474408],
            [-0.175045034980872, 51.333811555430621],
            [-0.174691516559311, 51.333170261302769],
            [-0.174164398950471, 51.333213303506419],
            [-0.172889002804801, 51.330015385869153],
            [-0.169919867708561, 51.33024780200919],
            [-0.170091259578355, 51.329476206232165],
            [-0.16310218469515, 51.330266068625711],
            [-0.162315971861777, 51.32889133517633],
            [-0.16399615372199, 51.328584956029466],
            [-0.164007644177431, 51.328189455770449],
            [-0.164387606184334, 51.328127966248637],
            [-0.163592735160636, 51.32621533941456],
            [-0.164212520663373, 51.326042503817305],
            [-0.160625693563951, 51.32324343664353],
            [-0.158660152298905, 51.323977831489216],
            [-0.156568877412008, 51.321510593442625],
            [-0.161905510204714, 51.319627762863675],
            [-0.16120315059232, 51.317525022256511],
            [-0.160507331833134, 51.316874709400217],
            [-0.159606881146681, 51.317106963015952],
            [-0.159340030812686, 51.315957990860156],
            [-0.157195636855283, 51.313391903011009],
            [-0.158356626805023, 51.310987516812595],
            [-0.1562542433988, 51.310422960412204],
            [-0.154341438367858, 51.310319985531201],
            [-0.157189238586363, 51.30671107411839],
            [-0.156441981724983, 51.306454708060151],
            [-0.154535411878273, 51.306450754076522],
            [-0.155049575902992, 51.30579339095452],
            [-0.157761229650816, 51.304429614890076],
            [-0.156710231200859, 51.30359113466357],
            [-0.155344025301304, 51.301276460102486],
            [-0.153386888391804, 51.301679961985499],
            [-0.150020000468255, 51.300287788118361],
            [-0.149522755410533, 51.300702591806626],
            [-0.148478285287727, 51.300705864370812],
            [-0.145651684834007, 51.299830198794012],
            [-0.144878696557934, 51.300714535981278],
            [-0.144449509547269, 51.300543167069591],
            [-0.143585597906881, 51.301262385992544],
            [-0.142859750859058, 51.299981991789366],
            [-0.140881639431117, 51.299554017400837],
            [-0.13734033321557, 51.300782806346945],
            [-0.136354736221868, 51.298269820652919],
            [-0.134458224083793, 51.298379906398758],
            [-0.131136347156092, 51.295475309193741],
            [-0.131485731858589, 51.294712898842135],
            [-0.130557909043012, 51.293118948638984],
            [-0.126305008933486, 51.289530271848392],
            [-0.126083380983476, 51.288386433509025],
            [-0.124319668186361, 51.286760163106521],
            [-0.127362974763244, 51.286439275740477],
            [-0.126823987337452, 51.284918058116396],
            [-0.131082817518305, 51.284818855696251],
            [-0.13040037175631, 51.283261191738823],
            [-0.13050523511256, 51.27947778066315],
            [-0.131520154503846, 51.276833901283212],
            [-0.130274233681655, 51.274360759948749],
            [-0.130011939826619, 51.272558000335252],
            [-0.132048158860792, 51.269684020660812],
            [-0.132062123580929, 51.269021470214746],
            [-0.129699174526743, 51.266525991829006],
            [-0.130119233750463, 51.265606438147174],
            [-0.128660428544309, 51.264148763842257],
            [-0.127788946842459, 51.264324577632514],
            [-0.126850561424109, 51.264100930392416],
            [-0.126827650731049, 51.261738137346775],
            [-0.127633732764998, 51.261653911330498],
            [-0.129650259546077, 51.259892073455163],
            [-0.131548242614985, 51.256560851631619],
            [-0.131818519921698, 51.254171263078035],
            [-0.135161268096145, 51.254718278458689],
            [-0.139473252203646, 51.253339048770087],
            [-0.142974795298015, 51.253324512322486],
            [-0.144225275437066, 51.251808364741073],
            [-0.145763800932653, 51.251215838312739],
            [-0.144923603681134, 51.250230395847353],
            [-0.144499737639316, 51.248799206785016],
            [-0.144398642897846, 51.247928892915233],
            [-0.142759703586462, 51.245787773229573],
            [-0.143101704685681, 51.243877717487962],
            [-0.145314073393716, 51.241593535611003],
            [-0.145354377695214, 51.240591468456209],
            [-0.146048843039521, 51.239567391448034],
            [-0.145333510874377, 51.23729884413271],
            [-0.148043474738483, 51.234205941736825],
            [-0.149303430683401, 51.234542423260621],
            [-0.150101666314348, 51.235819443036334],
            [-0.150690726656101, 51.235990624787689],
            [-0.151472553911139, 51.234926529141141],
            [-0.152273103474112, 51.235038992526135],
            [-0.153001902418062, 51.234331966109082],
            [-0.15434888948551, 51.231957514373512],
            [-0.154985023608005, 51.230664476931508],
            [-0.153381866230574, 51.227131065603402],
            [-0.152468361287318, 51.226766823292792],
            [-0.152927595305078, 51.226314536165731],
            [-0.14958463627055, 51.222069233923143],
            [-0.147470504694055, 51.220009682196263],
            [-0.145397981493433, 51.216456128250179],
            [-0.144760115818806, 51.21625896384726],
            [-0.144562869775666, 51.213222526005161],
            [-0.145529012714892, 51.210736012768649],
            [-0.143771842691992, 51.210746818958029],
            [-0.135990145651471, 51.212099746371265],
            [-0.135945880099491, 51.211139495663453],
            [-0.135681280846628, 51.210530055730707],
            [-0.136756801169693, 51.207578621108368],
            [-0.137347548554875, 51.206597905201072],
            [-0.137776464505694, 51.206611927076871],
            [-0.137930729637709, 51.203993840911572],
            [-0.137050993980956, 51.20057151187941],
            [-0.137803240613027, 51.195973703881847],
            [-0.139361729031727, 51.193029028805434],
            [-0.141582429815298, 51.191476171712146],
            [-0.141944942899823, 51.19061141224271],
            [-0.142982610656807, 51.190394971069878],
            [-0.143264673249295, 51.189715084565037],
            [-0.143535466075932, 51.1865376752977],
            [-0.141518594933208, 51.186446297668404],
            [-0.140351411224015, 51.185971806048272],
            [-0.141694193102997, 51.184855534963354],
            [-0.139446065138379, 51.182549077482641],
            [-0.136722877658682, 51.181207148796737],
            [-0.135912108790543, 51.179314700491666],
            [-0.136402048010559, 51.17795107839801],
            [-0.13515820203652, 51.17736199992153],
            [-0.135004897456193, 51.17655198637982],
            [-0.129007128973363, 51.174794301941226],
            [-0.127746835245121, 51.165043715236898],
            [-0.128281334104275, 51.162793227440694],
            [-0.128683465134889, 51.162610808931561],
            [-0.135888959715855, 51.160732123526401],
            [-0.140386578522359, 51.16041971954612],
            [-0.140490081278012, 51.160050853759437],
            [-0.14406449838882, 51.160289286458891],
            [-0.151122783893006, 51.16002876592416],
            [-0.153170876778066, 51.159847090889535],
            [-0.154541719487963, 51.160043192705025],
            [-0.155491658783696, 51.159640903273456],
            [-0.157038327828231, 51.159587945140963],
            [-0.160920241020676, 51.159827109822487],
            [-0.164193393678727, 51.160523326968708],
            [-0.166962612735823, 51.161595554818547],
            [-0.176733834520691, 51.166853579693026],
            [-0.177085662100078, 51.167906741589128],
            [-0.177384280465617, 51.168931194947483],
            [-0.176570081513038, 51.169808806663532],
            [-0.17674015370643, 51.170211643720307],
            [-0.178939894336184, 51.170982432949415],
            [-0.17917099728294, 51.171942879239005],
            [-0.180231793910667, 51.172577201865515],
            [-0.182076957513733, 51.172437725062565],
            [-0.182937890479673, 51.173281149663488],
            [-0.18281374267351, 51.17465603876348],
            [-0.185762124003422, 51.175351093576602],
            [-0.186622427862645, 51.175265511181884],
            [-0.18626010124277, 51.175749108862654],
            [-0.187365481456423, 51.176455100730962],
            [-0.187521592522799, 51.177508792193301],
            [-0.188717876763008, 51.177868153242464],
            [-0.188689073081096, 51.178311058131982],
            [-0.189819734317941, 51.178337552933478],
            [-0.189795964522049, 51.178871364360141],
            [-0.18803714199833, 51.179831556337199],
            [-0.187741651537316, 51.180398029162035],
            [-0.1896853682908, 51.181073816327221],
            [-0.189846478196953, 51.181417140846108],
            [-0.190584319096822, 51.181465426730462],
            [-0.192657492789703, 51.180735775824495],
            [-0.195945572868694, 51.180769443650938],
            [-0.198624228806401, 51.181388067068838],
            [-0.199653841646752, 51.181108950133776],
            [-0.200619399146188, 51.180637290097145],
            [-0.200761203590545, 51.180118782156001],
            [-0.199596685412572, 51.179013623056086],
            [-0.198104336442752, 51.176373708534278],
            [-0.196949336550937, 51.175358597324596],
            [-0.197957024672768, 51.174537784409146],
            [-0.200027684868443, 51.174265704852012],
            [-0.20071157868587, 51.173786112685399],
            [-0.201904575831125, 51.173641685418531],
            [-0.202616560621003, 51.174241663228024],
            [-0.206904648063186, 51.172566446574294],
            [-0.209728334907029, 51.172312930947164],
            [-0.210789619072667, 51.172391219992498],
            [-0.211119862487511, 51.172951132951894],
            [-0.212776314972439, 51.173620344818794],
            [-0.213942477542395, 51.17468762753839],
            [-0.21450528794988, 51.174339201490156],
            [-0.218062303997642, 51.173990559226738],
            [-0.21999739993062, 51.176269143024363],
            [-0.221030094333576, 51.175903554086268],
            [-0.221756316047244, 51.175025199455185],
            [-0.223788402620298, 51.174934668906161],
            [-0.225458840788762, 51.177109320402472],
            [-0.22776298310297, 51.178134366820125],
            [-0.229009303004287, 51.179212602542414],
            [-0.228969040629797, 51.180263259987456],
            [-0.234625107203338, 51.181268722467713],
            [-0.236700516661094, 51.182674133154215],
            [-0.236193660506365, 51.183432684105185],
            [-0.237267049354526, 51.184476746685426],
            [-0.23914709095715, 51.184068006886726],
            [-0.23911430000845, 51.183464092544952],
            [-0.24093820425998, 51.183438474169286],
            [-0.243078807209604, 51.182835746334177],
            [-0.243239376330363, 51.18388132880834],
            [-0.245164438072127, 51.186617968323198],
            [-0.244992398741902, 51.188133380160025],
            [-0.243715866256555, 51.188210446959445],
            [-0.242926582082797, 51.189510651737727],
            [-0.241629724521455, 51.189519045063427],
            [-0.241428777274433, 51.189121238644439],
            [-0.239863796152165, 51.189293747953009],
            [-0.239640299758808, 51.188999916166466],
            [-0.238205720017544, 51.18939289166098],
            [-0.237878951305257, 51.18869282286137],
            [-0.238560734775789, 51.188639240731803],
            [-0.238049757871147, 51.188154025719719],
            [-0.238387820473286, 51.187769726168256],
            [-0.238022423429569, 51.187294796520611],
            [-0.238477175507667, 51.187190133296525],
            [-0.237063935719593, 51.186912548961459],
            [-0.236817353153056, 51.186474477914722],
            [-0.236534133962959, 51.187258882545436],
            [-0.235196827465241, 51.187129003751956],
            [-0.234366905450802, 51.187801736984937],
            [-0.233473346104072, 51.186620075341075],
            [-0.232157296995588, 51.186720699859087],
            [-0.228495906592421, 51.18745488763178],
            [-0.227303103941914, 51.189228197966578],
            [-0.227191289655683, 51.19158263324131],
            [-0.224400689822833, 51.191915315732174],
            [-0.221842092464974, 51.191871060067996],
            [-0.221732498234108, 51.191372088864711],
            [-0.22093186903698, 51.190831136057795],
            [-0.218962781602129, 51.190939666047612],
            [-0.218092300140428, 51.191617063242475],
            [-0.21558639810194, 51.192283909967337],
            [-0.215845403608912, 51.192914663161098],
            [-0.212847085321074, 51.193322140783771],
            [-0.214887270691415, 51.195006177503281],
            [-0.215994248302805, 51.196805448434347],
            [-0.215792520460357, 51.198100042381149],
            [-0.21468443637755, 51.199476133289515],
            [-0.217699322916871, 51.200874625074796],
            [-0.218576292453739, 51.201852014952536],
            [-0.219387058366909, 51.201985760126121],
            [-0.21968585636795, 51.20125289311197],
            [-0.220678850605812, 51.201003608687948],
            [-0.220075701663057, 51.200608641127651],
            [-0.220726328280975, 51.200254326729322],
            [-0.220021554549986, 51.199748100451217],
            [-0.220460357699585, 51.199430133319289],
            [-0.219877848901601, 51.199280084149152],
            [-0.220652556208369, 51.198305352328852],
            [-0.221494449329591, 51.198298368817802],
            [-0.221110644241453, 51.1976720267918],
            [-0.221785643967299, 51.197538402173933],
            [-0.221690793554343, 51.197250987675424],
            [-0.221231626077987, 51.197280878331448],
            [-0.221015650933395, 51.196087839344358],
            [-0.223611121817817, 51.195960908119737],
            [-0.224169170630617, 51.199203210171973],
            [-0.225142524878961, 51.201288133779641],
            [-0.224769373380388, 51.202170066699786],
            [-0.223379016440828, 51.202032055961013],
            [-0.22307772013395, 51.203872812877236],
            [-0.226222166558551, 51.204185813985198],
            [-0.226528613305381, 51.205785789372065],
            [-0.228364953690674, 51.206988972583467],
            [-0.229574695858619, 51.206950628594001],
            [-0.231582095305004, 51.204775042955006],
            [-0.232697540633432, 51.204318873432875],
            [-0.234784315644331, 51.206088689236907],
            [-0.234894662026827, 51.206272908400031],
            [-0.230749818332758, 51.20725527488112],
            [-0.233577752821574, 51.209038114172138],
            [-0.232453460405812, 51.209722576520662],
            [-0.23042300296916, 51.210179298076802],
            [-0.228658380835394, 51.209826157911337],
            [-0.226484253297777, 51.210741968629883],
            [-0.225096210006072, 51.212324344066992],
            [-0.225936798146606, 51.213214791967772],
            [-0.227350527444105, 51.213498808113457],
            [-0.227034238282711, 51.213718836857105],
            [-0.227348476913573, 51.213925037109171],
            [-0.231551694450914, 51.213230541975868],
            [-0.233047638988939, 51.213424904069392],
            [-0.234861545395972, 51.214553002141066],
            [-0.233784951521921, 51.215297549438759],
            [-0.234372022102173, 51.215783927874078],
            [-0.233789590475012, 51.216336290009266],
            [-0.234200060873427, 51.217733673578223],
            [-0.23452015213443, 51.218237604596638],
            [-0.235246829401202, 51.21833219916234],
            [-0.234584420394258, 51.219028143274343],
            [-0.235654825663467, 51.219234035632496],
            [-0.236030437014906, 51.219897072818419],
            [-0.234871655441318, 51.220915571124976],
            [-0.236073200763603, 51.221775416006345],
            [-0.235413343320679, 51.222366187248319],
            [-0.237624618835839, 51.222812280480781],
            [-0.239093448840387, 51.223500758951637],
            [-0.240228770388399, 51.223359564058235],
            [-0.240991984192932, 51.223888124754922],
            [-0.242202045726246, 51.223739940597447],
            [-0.242591108996452, 51.223413949815097],
            [-0.243041776558073, 51.224177910536312],
            [-0.242567440142213, 51.224524204924002],
            [-0.242926289443626, 51.224766104062496],
            [-0.244373758876098, 51.22466462716045],
            [-0.244344420366416, 51.22411832431343],
            [-0.244753233797176, 51.224101075623437],
            [-0.245017529349498, 51.224569967043998],
            [-0.245547167114241, 51.224463700261786],
            [-0.246460497713872, 51.22398368528718],
            [-0.246572736665054, 51.223366662835879],
            [-0.247627745061593, 51.224359083327329],
            [-0.249157255579486, 51.224246185934],
            [-0.249125633148433, 51.2253437324892],
            [-0.250032327487086, 51.225529056863344],
            [-0.25007416759665, 51.226729320353954],
            [-0.25075265297997, 51.227079391317261],
            [-0.249373777774439, 51.227824957801687],
            [-0.250115154923804, 51.228178670974117],
            [-0.250193437979733, 51.228644768434116],
            [-0.248807529604654, 51.229121338947238],
            [-0.249959262685626, 51.230929030598155],
            [-0.24938631935784, 51.231872796950185],
            [-0.248668095448012, 51.232454675308901],
            [-0.247886143630711, 51.232526603864684],
            [-0.247545004640032, 51.23324901197693],
            [-0.246825798218363, 51.233629426150337],
            [-0.247206057717593, 51.234139616870166],
            [-0.245678167146908, 51.235105030149143],
            [-0.245567858292104, 51.235745460984852],
            [-0.244881603934565, 51.236312507836921],
            [-0.239053572871433, 51.237303196440799],
            [-0.23729323341364, 51.237071660978216],
            [-0.233761487875088, 51.238021110330379],
            [-0.231063408379781, 51.239753739063573],
            [-0.230641025806439, 51.240382245069142],
            [-0.231826282679723, 51.242238282943802],
            [-0.230017416444834, 51.245021186233274],
            [-0.229278028751578, 51.245060361923215],
            [-0.229497363855535, 51.246512417084659],
            [-0.229550839476716, 51.247582467456141],
            [-0.226568664072543, 51.24755530539251],
            [-0.22636328111801, 51.248871434241941],
            [-0.22659118008004, 51.249204922500383],
            [-0.231565602906587, 51.250043673229357],
            [-0.231542817494965, 51.25078703125061],
            [-0.227932234838194, 51.251370910287676],
            [-0.227980983498543, 51.252823981302704],
            [-0.227314285644483, 51.253168201394374],
            [-0.227397992728539, 51.253450942885195],
            [-0.226004972415066, 51.253788650201123],
            [-0.226769142664623, 51.255902739386109],
            [-0.229364376556691, 51.25717493455177],
            [-0.233260450406818, 51.25846401462347],
            [-0.240711366101933, 51.26064906576439],
            [-0.24093766504356, 51.259864701886684],
            [-0.243510696698901, 51.260156036230811],
            [-0.243970348247091, 51.260091889963725],
            [-0.243771812425136, 51.259509778551624],
            [-0.247661397375375, 51.259138217756679],
            [-0.248721323489926, 51.259494905178904],
            [-0.248903884148608, 51.260235041119245],
            [-0.251496534301159, 51.260653290507122],
            [-0.253372293828789, 51.260550000170632],
            [-0.25445093166989, 51.259090381875893],
            [-0.257957181361667, 51.25786115048205],
            [-0.260859052631854, 51.257831459923452],
            [-0.261358942953977, 51.25935865736664],
            [-0.263505306509535, 51.260477738309987],
            [-0.264146507203561, 51.260996236684896],
            [-0.265768525429219, 51.261388980001762],
            [-0.266488154416029, 51.262566894243982],
            [-0.265799425679541, 51.265747309192854],
            [-0.266898735946214, 51.268646653157596],
            [-0.266150004761854, 51.273152603252342],
            [-0.267079081251533, 51.274408251905726],
            [-0.266911856859725, 51.276718699041346],
            [-0.266191748502519, 51.278632474095019],
            [-0.268897783872628, 51.284113994704157],
            [-0.269504612000763, 51.286549191700388],
            [-0.2702712222797, 51.287367163371449],
            [-0.27023989099514, 51.289078906632412],
            [-0.27225895633586, 51.292332591745236],
            [-0.269873226515056, 51.294103092181032],
            [-0.267480337318662, 51.297582945127999],
            [-0.264424099711526, 51.299547546230173],
            [-0.260479071588125, 51.299249800770397],
            [-0.253549838863381, 51.299420932278629],
            [-0.253308398644642, 51.299206002912598],
            [-0.251625556349182, 51.299626924585766],
            [-0.249919337400422, 51.298662599361727],
            [-0.247151396338055, 51.298045664234245],
            [-0.247158767451844, 51.298680658422384],
            [-0.246403674697828, 51.302234053451684],
            [-0.243245534708843, 51.306910572102929],
            [-0.244052116794306, 51.309421735253927],
            [-0.242804474043597, 51.310006428746185],
            [-0.242882532423252, 51.310291768631629],
            [-0.241054719450452, 51.309800305799122],
            [-0.240762398191106, 51.31057827765634],
            [-0.241464005944806, 51.311554629383757],
            [-0.244096491100018, 51.311341437562284],
            [-0.244218802015717, 51.311858551680537],
            [-0.244420252106812, 51.31300903688976],
            [-0.243668641558995, 51.31431699155295],
            [-0.241339652985913, 51.316157909949155],
            [-0.241165181109297, 51.317305452124515],
            [-0.242993610793352, 51.321281577475496],
            [-0.236068463659043, 51.326307791817172],
            [-0.233915485956021, 51.327316695727831],
            [-0.220967653952227, 51.329864396308807],
            [-0.216108693052046, 51.329828257430663],
            [-0.214120375643281, 51.330351904647074],
            [-0.211931105101989, 51.333309466536413],
            [-0.2130558807236, 51.335542427782762],
            [-0.202107754457131, 51.340133808943364],
            [-0.202071460186147, 51.340144043041057],
            [-0.201224435075549, 51.339885537873727],
            [-0.199582545213153, 51.341885462742447],
            [-0.198582730800043, 51.342300839481275],
            [-0.19734447057784, 51.343596518643274],
            [-0.19656632549415, 51.343447851592217],
            [-0.196715478322556, 51.34315968441139],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000212",
        LAD13CDO: "43UG",
        LAD13NM: "Runnymede",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.55663837387807, 51.441999578167419],
            [-0.555585486570354, 51.441485746483039],
            [-0.55407087430872, 51.441514735227315],
            [-0.55287920801993, 51.442889325691731],
            [-0.551836833588521, 51.443499613742404],
            [-0.55046349711966, 51.443472751075596],
            [-0.548203316726947, 51.441872050865918],
            [-0.538382135807919, 51.439010997895871],
            [-0.534619045192484, 51.437411966893094],
            [-0.531424839952559, 51.436430512700994],
            [-0.527601447500774, 51.435691953068201],
            [-0.522784368367517, 51.43530044021518],
            [-0.517473731852201, 51.433758676485262],
            [-0.511222284946004, 51.430677843150782],
            [-0.510225124907535, 51.429358601965433],
            [-0.509652570985437, 51.426220223214287],
            [-0.509867614868489, 51.424163731176293],
            [-0.512653152411134, 51.41957169350966],
            [-0.512870240852105, 51.417757112289529],
            [-0.511560651050963, 51.416898814410168],
            [-0.507704809779395, 51.417392921201426],
            [-0.506390142920256, 51.417311432265421],
            [-0.504714717442191, 51.41682696950469],
            [-0.502148501818123, 51.415482232266875],
            [-0.501726751259123, 51.414130698497971],
            [-0.503579444572299, 51.411736342972944],
            [-0.50355768904489, 51.410905178711403],
            [-0.501187238967913, 51.410620409080373],
            [-0.500479597005723, 51.410801994348965],
            [-0.500136748463543, 51.411524183957169],
            [-0.500819975122734, 51.413276527713187],
            [-0.499791397125718, 51.414079866180217],
            [-0.49813490112177, 51.414076637437134],
            [-0.494588028778449, 51.412916093822453],
            [-0.493222670965494, 51.411887816800764],
            [-0.492536924993302, 51.410308945695789],
            [-0.493050046033263, 51.405766343241289],
            [-0.491175179573874, 51.40310208308852],
            [-0.49196619830335, 51.400470325328207],
            [-0.491782637757509, 51.399494995833436],
            [-0.491101669268399, 51.398738969790259],
            [-0.487882268382003, 51.396756036122156],
            [-0.486912272831776, 51.395526856463469],
            [-0.486504285988739, 51.394081918227187],
            [-0.486408671449484, 51.39305915588676],
            [-0.486894916403673, 51.392087969746065],
            [-0.48641292446076, 51.388617902339597],
            [-0.485347027260101, 51.387252583484425],
            [-0.483773694792802, 51.386545223226371],
            [-0.482090723952168, 51.386677204635312],
            [-0.480316890071611, 51.387903249881361],
            [-0.478877391385951, 51.389966298632579],
            [-0.477842064226614, 51.390370998830385],
            [-0.476780627616113, 51.390219624414343],
            [-0.472517383123169, 51.387810838129397],
            [-0.470155654571634, 51.385883544657311],
            [-0.469851823214567, 51.384953366069723],
            [-0.471039839696791, 51.382552629023245],
            [-0.46997399508705, 51.381893066439488],
            [-0.464795481745002, 51.381835227723506],
            [-0.462315463583005, 51.382255925055084],
            [-0.461667387065767, 51.382083758049376],
            [-0.461289731363223, 51.381475412294108],
            [-0.46183134015973, 51.380407935973075],
            [-0.463520762982831, 51.379091137849578],
            [-0.462815613225877, 51.378436237909874],
            [-0.461484353754337, 51.378585124973576],
            [-0.460752438498599, 51.379329072863868],
            [-0.458010795509476, 51.380568143904931],
            [-0.458069322571404, 51.379809958482426],
            [-0.459036308583619, 51.379256159950543],
            [-0.460160436229728, 51.379114470446396],
            [-0.461661073715669, 51.376913966039432],
            [-0.465028612379981, 51.376240537740365],
            [-0.467625059095854, 51.374646005039651],
            [-0.467507784849508, 51.373640024159755],
            [-0.466236844361005, 51.37241841750496],
            [-0.465528579086198, 51.371385812572669],
            [-0.465657422706536, 51.370840763705772],
            [-0.4661752838658, 51.370477058276961],
            [-0.46686479005406, 51.370737868681744],
            [-0.468268032943365, 51.370146538661686],
            [-0.470041110779107, 51.367293944351914],
            [-0.467670202547587, 51.366020214900274],
            [-0.467576452720686, 51.365687170079958],
            [-0.469312012067031, 51.365808772997767],
            [-0.471514262442243, 51.36438255709615],
            [-0.472101644619755, 51.365034072388696],
            [-0.473749159732281, 51.365254276765896],
            [-0.474003087009281, 51.364961735243917],
            [-0.473064387391614, 51.3648092245135],
            [-0.472391768558027, 51.363869746023376],
            [-0.472838664569495, 51.363562637260898],
            [-0.47332045688786, 51.364063496738794],
            [-0.474140942839854, 51.363968974253766],
            [-0.475487825330243, 51.364114199005712],
            [-0.474193060118946, 51.362919343713571],
            [-0.474873339735514, 51.36306668353437],
            [-0.476155877225582, 51.361817245258372],
            [-0.475836645323882, 51.360616207614804],
            [-0.475152228405661, 51.360291669495588],
            [-0.476106194768913, 51.359548721819095],
            [-0.475570857376171, 51.359146992073221],
            [-0.474577150253864, 51.359054025992414],
            [-0.474226223106884, 51.358030619621317],
            [-0.473505534148772, 51.358193885489584],
            [-0.472212158169591, 51.357827222839944],
            [-0.470100800261057, 51.358087427210833],
            [-0.469946328203913, 51.357852507164488],
            [-0.470698998105149, 51.35763482706939],
            [-0.470696284952037, 51.3572849878515],
            [-0.470127275856658, 51.357120192205549],
            [-0.468454301773977, 51.357543457308793],
            [-0.466991436132193, 51.356576526968496],
            [-0.472510587813746, 51.353777346321394],
            [-0.474530800251229, 51.352233597908935],
            [-0.478430302429649, 51.35078707657199],
            [-0.481175820171545, 51.349359641873534],
            [-0.481924593722838, 51.349558187231587],
            [-0.486431264802283, 51.347363906990147],
            [-0.49312534575634, 51.345444902280121],
            [-0.511720886515171, 51.339872650399975],
            [-0.515645780441496, 51.338859551210724],
            [-0.516100509974527, 51.33931583015201],
            [-0.51781553463029, 51.341026309218364],
            [-0.518301016555412, 51.340752788218374],
            [-0.519461364992323, 51.341635222328101],
            [-0.517924915129047, 51.342576178457151],
            [-0.518864067167119, 51.343774149013491],
            [-0.520222823701648, 51.343179839822817],
            [-0.520794495848158, 51.344867731722658],
            [-0.523160359411233, 51.343808606549608],
            [-0.524218590775066, 51.345059290732024],
            [-0.530372098169319, 51.345570978242463],
            [-0.533464979315961, 51.346560228145947],
            [-0.534068734750234, 51.347484104445407],
            [-0.534461570967698, 51.347683253991377],
            [-0.535020255911018, 51.347364718877117],
            [-0.535880838852031, 51.347395260457183],
            [-0.5358510061781, 51.346889517995848],
            [-0.537809705840752, 51.346434689576],
            [-0.541183878059884, 51.347254592112755],
            [-0.541970149569052, 51.347183449386392],
            [-0.542221363845666, 51.347457244962051],
            [-0.543724683255472, 51.347143225455923],
            [-0.544922358383119, 51.347864904656696],
            [-0.546192986392763, 51.347598320213166],
            [-0.548569004890749, 51.348021645471398],
            [-0.548511350989137, 51.34916475539535],
            [-0.551258117085876, 51.353271377394208],
            [-0.550044852966389, 51.354264407283466],
            [-0.550804557462345, 51.356540770020587],
            [-0.555253687709601, 51.361936249444632],
            [-0.560374770643486, 51.362015470381216],
            [-0.569644287417132, 51.360337733456632],
            [-0.571185036587853, 51.36233663522011],
            [-0.572801475102517, 51.365463170660767],
            [-0.573025789494409, 51.367998129144837],
            [-0.573973826811456, 51.370822455004117],
            [-0.579320887385721, 51.372174200415493],
            [-0.587503515882269, 51.375048133627629],
            [-0.594435284865726, 51.376526182466044],
            [-0.595075705111099, 51.378370079225526],
            [-0.598018986550754, 51.386301413162535],
            [-0.603273055243424, 51.387343404247233],
            [-0.604170112145089, 51.388090555331843],
            [-0.605992239370534, 51.388382010290265],
            [-0.607265799034709, 51.389701024145111],
            [-0.608928475057721, 51.38985025939683],
            [-0.616517625307711, 51.392407556733637],
            [-0.618513224781698, 51.39238793456348],
            [-0.619652837738309, 51.392823995976158],
            [-0.615437716500262, 51.39637565303223],
            [-0.615602115605743, 51.396739081567794],
            [-0.616604374490819, 51.397013488307628],
            [-0.615626441724827, 51.397647581207792],
            [-0.615035323034681, 51.397039019278644],
            [-0.614538742188891, 51.397393737475774],
            [-0.615810300574313, 51.398700041657506],
            [-0.616492638953272, 51.398287263287202],
            [-0.617230231604755, 51.398759065939863],
            [-0.618117814575724, 51.398642744303928],
            [-0.618386603804957, 51.399025383819833],
            [-0.614402510124136, 51.40309228103002],
            [-0.611313970345128, 51.408708220311162],
            [-0.608519234016734, 51.412010278642086],
            [-0.606701095870134, 51.414110836998233],
            [-0.605172851781576, 51.417359521706217],
            [-0.604974240722899, 51.421380258540502],
            [-0.605142220153093, 51.431311408718798],
            [-0.594159280732293, 51.43931363027923],
            [-0.593889243403153, 51.440563898662845],
            [-0.594488434091926, 51.440871421352171],
            [-0.594237047529821, 51.441467284976298],
            [-0.593039038437425, 51.441823391335703],
            [-0.591070047194855, 51.441141520278393],
            [-0.590114219600392, 51.441342244930411],
            [-0.588383321189233, 51.442367205584588],
            [-0.585396224331145, 51.442365372975658],
            [-0.584520327043672, 51.443655967215818],
            [-0.579693380653416, 51.444746791138435],
            [-0.571693073810149, 51.451007063205424],
            [-0.567837761586128, 51.450079702930495],
            [-0.564963050699289, 51.44803750204656],
            [-0.563159716176002, 51.445750287955434],
            [-0.559309945099921, 51.444346125958447],
            [-0.55663837387807, 51.441999578167419],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000213",
        LAD13CDO: "43UH",
        LAD13NM: "Spelthorne",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.515680371924963, 51.468267868912818],
            [-0.513869778038784, 51.467984163252822],
            [-0.513602327369914, 51.468416894532808],
            [-0.511201701174506, 51.469275768054999],
            [-0.509720591528107, 51.469175125642252],
            [-0.510173001012766, 51.468767233567654],
            [-0.510296188594514, 51.467504496623185],
            [-0.508800752971628, 51.467094328850223],
            [-0.507336632899738, 51.466916539101184],
            [-0.506298572136106, 51.467711719162232],
            [-0.503756255634119, 51.467705384988989],
            [-0.500368806023371, 51.467128875800718],
            [-0.498137182550238, 51.466430395979181],
            [-0.497005119509679, 51.465875459790134],
            [-0.493881228400807, 51.462722274369618],
            [-0.492229039743598, 51.462971706911759],
            [-0.489629514970375, 51.461784550501143],
            [-0.48588218546573, 51.461313587393448],
            [-0.477140731224681, 51.461336166764241],
            [-0.476138498485309, 51.460957162186446],
            [-0.475896911329118, 51.460085372560087],
            [-0.473580289327365, 51.458633565096569],
            [-0.469607610877856, 51.457983796807021],
            [-0.461299292550285, 51.457215029982336],
            [-0.458660978484459, 51.4563153225699],
            [-0.459314736592871, 51.452715322117015],
            [-0.459858947087989, 51.452239587722964],
            [-0.461340571126053, 51.452391227031718],
            [-0.461502308528438, 51.448992476542472],
            [-0.45748239905915, 51.449090727469269],
            [-0.457464601495208, 51.442999129012371],
            [-0.455412129383853, 51.442119638037603],
            [-0.456495977102343, 51.438224968450612],
            [-0.453681239853264, 51.438494512434367],
            [-0.446309671660516, 51.439996777336638],
            [-0.447737929161854, 51.435003393416274],
            [-0.439773473381041, 51.434619801440483],
            [-0.440004436302619, 51.430626694105015],
            [-0.432661010752551, 51.429018030495577],
            [-0.429555653037637, 51.428797521779565],
            [-0.429445983297476, 51.429394941097236],
            [-0.427852693902999, 51.429252187081062],
            [-0.427506875324966, 51.430819406518502],
            [-0.423520071910168, 51.430877363101843],
            [-0.423600946148416, 51.431160809691306],
            [-0.42117521202647, 51.432171273935417],
            [-0.419100018015259, 51.432359122156541],
            [-0.418605118406259, 51.432109654074615],
            [-0.418377584757405, 51.431866489217029],
            [-0.417315568586435, 51.431917799502287],
            [-0.417921753354334, 51.431027648475663],
            [-0.413827497987634, 51.430074885220364],
            [-0.414373791365661, 51.429295446618561],
            [-0.412474067014669, 51.428781450386495],
            [-0.413107459364795, 51.427854823446125],
            [-0.410889873519264, 51.427341892920275],
            [-0.40902802681491, 51.425450726218678],
            [-0.408462794202758, 51.423834322038395],
            [-0.407797119734948, 51.42357799219613],
            [-0.405382989162694, 51.422479581325625],
            [-0.401781221795077, 51.423311778033487],
            [-0.398133713987645, 51.423556038411476],
            [-0.394533201650686, 51.423237907157073],
            [-0.391363513016445, 51.422325787377467],
            [-0.386663108012453, 51.420080534696403],
            [-0.387715044593268, 51.419327959429481],
            [-0.386783045753646, 51.417654243559369],
            [-0.386615424600298, 51.415021664873557],
            [-0.386143952315178, 51.414280496240188],
            [-0.390313708108556, 51.414337836572578],
            [-0.389670964487608, 51.410693386834623],
            [-0.389198064866915, 51.410319999050046],
            [-0.386656901554412, 51.410411839310882],
            [-0.383798572165673, 51.41011887308315],
            [-0.383371172651401, 51.408538410298362],
            [-0.385717773167306, 51.409150764188659],
            [-0.39048575224034, 51.409643477257376],
            [-0.397067569340361, 51.407982861894496],
            [-0.397214347407654, 51.408596351247112],
            [-0.399940608844179, 51.407811698703235],
            [-0.399351021073938, 51.407072567426631],
            [-0.40045299127628, 51.406602023612791],
            [-0.402788837200304, 51.405917187183768],
            [-0.406065865814698, 51.405659657110718],
            [-0.408179124293843, 51.40517041781186],
            [-0.410655925226043, 51.404105163735629],
            [-0.411556639285026, 51.403255006193568],
            [-0.412954042107981, 51.401470067038218],
            [-0.414652830559859, 51.400694536374793],
            [-0.415857728216159, 51.399149752737969],
            [-0.417160976957298, 51.398834643893125],
            [-0.417218766772215, 51.397660120667581],
            [-0.418968051628666, 51.397164872920996],
            [-0.419663657237628, 51.396710248552694],
            [-0.418985262078969, 51.396373775853867],
            [-0.418706296634523, 51.39554271314951],
            [-0.419528377605755, 51.395087997608513],
            [-0.419540311437341, 51.394449699559487],
            [-0.420163812011766, 51.394501270046597],
            [-0.42072807767988, 51.394102419980086],
            [-0.421339169767909, 51.393094514635536],
            [-0.420688470600991, 51.392706268685203],
            [-0.421098197128452, 51.392286450063359],
            [-0.42161990990201, 51.392413076739999],
            [-0.42271805954223, 51.391702177173698],
            [-0.42615573934913, 51.390415725485084],
            [-0.426680891823279, 51.389938987201077],
            [-0.426566433189131, 51.3887504561215],
            [-0.427687012979818, 51.386415787115553],
            [-0.430072648439549, 51.387173457535035],
            [-0.430219056686493, 51.387010858326335],
            [-0.427909099628508, 51.386267694722513],
            [-0.428875350295191, 51.385372422906165],
            [-0.432109791519148, 51.384042607435752],
            [-0.435778585502333, 51.383918985088549],
            [-0.435635887555473, 51.384268682221212],
            [-0.437533169586026, 51.385187837814229],
            [-0.438157449256973, 51.386902013617416],
            [-0.439996908564133, 51.388723191423637],
            [-0.441610625641281, 51.389705047319829],
            [-0.442797490708371, 51.389799058245963],
            [-0.443442645258528, 51.389253699738894],
            [-0.443803428262637, 51.387558034916864],
            [-0.445024301321768, 51.386901612547909],
            [-0.447602986010825, 51.386847676865465],
            [-0.450239310206465, 51.388022803339261],
            [-0.451910975094833, 51.387633948114122],
            [-0.45197558395678, 51.38691181585618],
            [-0.450275107452303, 51.384920911518059],
            [-0.450543282357089, 51.383661030390627],
            [-0.451998531661007, 51.382824190471993],
            [-0.453839185898642, 51.383256745115681],
            [-0.455186719467595, 51.383226858039471],
            [-0.457898746263022, 51.381849879622692],
            [-0.459606140503346, 51.381500952152201],
            [-0.459452805239572, 51.380974681672477],
            [-0.458010795509476, 51.380568143904931],
            [-0.460752438498599, 51.379329072863868],
            [-0.461484353754337, 51.378585124973576],
            [-0.462815613225877, 51.378436237909874],
            [-0.463520762982831, 51.379091137849578],
            [-0.46183134015973, 51.380407935973075],
            [-0.461289731363223, 51.381475412294108],
            [-0.461667387065767, 51.382083758049376],
            [-0.462315463583005, 51.382255925055084],
            [-0.464795481745002, 51.381835227723506],
            [-0.46997399508705, 51.381893066439488],
            [-0.471039839696791, 51.382552629023245],
            [-0.469851823214567, 51.384953366069723],
            [-0.470155654571634, 51.385883544657311],
            [-0.472517383123169, 51.387810838129397],
            [-0.476780627616113, 51.390219624414343],
            [-0.477842064226614, 51.390370998830385],
            [-0.478877391385951, 51.389966298632579],
            [-0.480316890071611, 51.387903249881361],
            [-0.482090723952168, 51.386677204635312],
            [-0.483773694792802, 51.386545223226371],
            [-0.485347027260101, 51.387252583484425],
            [-0.48641292446076, 51.388617902339597],
            [-0.486894916403673, 51.392087969746065],
            [-0.486408671449484, 51.39305915588676],
            [-0.486504285988739, 51.394081918227187],
            [-0.486912272831776, 51.395526856463469],
            [-0.487882268382003, 51.396756036122156],
            [-0.491101669268399, 51.398738969790259],
            [-0.491782637757509, 51.399494995833436],
            [-0.49196619830335, 51.400470325328207],
            [-0.491175179573874, 51.40310208308852],
            [-0.493050046033263, 51.405766343241289],
            [-0.492536924993302, 51.410308945695789],
            [-0.493222670965494, 51.411887816800764],
            [-0.494588028778449, 51.412916093822453],
            [-0.49813490112177, 51.414076637437134],
            [-0.499791397125718, 51.414079866180217],
            [-0.500819975122734, 51.413276527713187],
            [-0.500136748463543, 51.411524183957169],
            [-0.500479597005723, 51.410801994348965],
            [-0.501187238967913, 51.410620409080373],
            [-0.50355768904489, 51.410905178711403],
            [-0.503579444572299, 51.411736342972944],
            [-0.501726751259123, 51.414130698497971],
            [-0.502148501818123, 51.415482232266875],
            [-0.504714717442191, 51.41682696950469],
            [-0.506390142920256, 51.417311432265421],
            [-0.507704809779395, 51.417392921201426],
            [-0.511560651050963, 51.416898814410168],
            [-0.512870240852105, 51.417757112289529],
            [-0.512653152411134, 51.41957169350966],
            [-0.509867614868489, 51.424163731176293],
            [-0.509652570985437, 51.426220223214287],
            [-0.510225124907535, 51.429358601965433],
            [-0.511222284946004, 51.430677843150782],
            [-0.517473731852201, 51.433758676485262],
            [-0.522784368367517, 51.43530044021518],
            [-0.523222722458828, 51.435805031442321],
            [-0.524550973555997, 51.43592876311326],
            [-0.525178661351728, 51.436898828989506],
            [-0.525250750572162, 51.437564261005662],
            [-0.524087810465683, 51.438827420828098],
            [-0.524558912412875, 51.439880044919065],
            [-0.526102419386524, 51.440159334840018],
            [-0.526510494377084, 51.440579005506386],
            [-0.527747995042483, 51.440889496832483],
            [-0.530149515933379, 51.440977179894119],
            [-0.531455428664762, 51.441712921851646],
            [-0.530659806546148, 51.44377295962876],
            [-0.531081564930739, 51.444889681212388],
            [-0.529424534495648, 51.445495665624208],
            [-0.526750808902865, 51.447509624288564],
            [-0.540595599007129, 51.457093994722257],
            [-0.540631893142866, 51.457851587864717],
            [-0.540496881214763, 51.458384042773893],
            [-0.536970090552959, 51.459607089456107],
            [-0.536619773501299, 51.459353634641374],
            [-0.535320313905016, 51.460511788712758],
            [-0.532090616128035, 51.464490006532124],
            [-0.529664563864147, 51.469292905406995],
            [-0.530396525978204, 51.469320065116925],
            [-0.529979125470944, 51.47016729116681],
            [-0.524377684203822, 51.471527594371558],
            [-0.521240040537421, 51.471446706402951],
            [-0.518747396237517, 51.470789391798519],
            [-0.517155544641816, 51.469883543803526],
            [-0.515680371924963, 51.468267868912818],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000214",
        LAD13CDO: "43UJ",
        LAD13NM: "Surrey Heath",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.598018986550754, 51.386301413162535],
            [-0.595075705111099, 51.378370079225526],
            [-0.594435284865726, 51.376526182466044],
            [-0.587503515882269, 51.375048133627629],
            [-0.579320887385721, 51.372174200415493],
            [-0.573973826811456, 51.370822455004117],
            [-0.573025789494409, 51.367998129144837],
            [-0.572801475102517, 51.365463170660767],
            [-0.571185036587853, 51.36233663522011],
            [-0.569644287417132, 51.360337733456632],
            [-0.560374770643486, 51.362015470381216],
            [-0.555253687709601, 51.361936249444632],
            [-0.550804557462345, 51.356540770020587],
            [-0.550044852966389, 51.354264407283466],
            [-0.551258117085876, 51.353271377394208],
            [-0.548511350989137, 51.34916475539535],
            [-0.548569004890749, 51.348021645471398],
            [-0.548976438011331, 51.347578874527464],
            [-0.549436848137911, 51.347822872886432],
            [-0.550013263574614, 51.347667247391037],
            [-0.551734928859783, 51.346073521439337],
            [-0.553546164127832, 51.345142711735996],
            [-0.557503629464171, 51.34476886828773],
            [-0.557987505415148, 51.344951974182379],
            [-0.559920771935174, 51.343832830431467],
            [-0.56083424196909, 51.344098538645405],
            [-0.561597156719192, 51.343807564818576],
            [-0.561955036107186, 51.343245441998086],
            [-0.563972246151129, 51.343064262378185],
            [-0.566238510556052, 51.343551528236716],
            [-0.567464675046333, 51.34318345568721],
            [-0.568809606255813, 51.343334778966522],
            [-0.56972562418248, 51.342828008644204],
            [-0.572834711758966, 51.34229941033405],
            [-0.573802341496481, 51.341515374476131],
            [-0.573504916071307, 51.341291441360561],
            [-0.574296604069684, 51.340994440960145],
            [-0.573827330950594, 51.338211900475265],
            [-0.576386644740474, 51.336464349590216],
            [-0.578257869272797, 51.336071629915338],
            [-0.583176113096947, 51.336770572041388],
            [-0.588050777593095, 51.338425563416877],
            [-0.589819770760022, 51.339866756878244],
            [-0.594034914982116, 51.339961483818385],
            [-0.599081194865347, 51.339637068449925],
            [-0.608164372326243, 51.339695880499143],
            [-0.615264883784928, 51.335943017251232],
            [-0.61339998691698, 51.33256773137952],
            [-0.613478357467793, 51.33215861027319],
            [-0.614020926186267, 51.332064317311328],
            [-0.616726496361733, 51.332119677517589],
            [-0.620418171103694, 51.333181140110185],
            [-0.622498873367753, 51.334451057783106],
            [-0.623859837967481, 51.333632569393828],
            [-0.624698483346458, 51.334821306972763],
            [-0.626041623702566, 51.3347903081889],
            [-0.625612413693574, 51.333541640350305],
            [-0.626244368399966, 51.330311829688462],
            [-0.625233904467835, 51.329110295013706],
            [-0.625432109594745, 51.328529920472626],
            [-0.624932215964523, 51.327583459424197],
            [-0.626930612737881, 51.324395752738681],
            [-0.625568955946407, 51.320782862713912],
            [-0.624396104736951, 51.320699853715034],
            [-0.623524335233979, 51.319907281624474],
            [-0.624355534173682, 51.319225538252958],
            [-0.627338017447999, 51.318776742394931],
            [-0.628008799440452, 51.316446601769947],
            [-0.633033072944073, 51.314401147128855],
            [-0.634522276324842, 51.315278182461135],
            [-0.63715207784945, 51.314468031178762],
            [-0.637001250515718, 51.314183018512743],
            [-0.638473362506832, 51.313991518888066],
            [-0.646809667818994, 51.311903898278011],
            [-0.656582167866931, 51.314211414757857],
            [-0.659007585155547, 51.314539551022698],
            [-0.671379294009966, 51.317366533668903],
            [-0.672734594309528, 51.316447602111403],
            [-0.675312558403115, 51.315522706257035],
            [-0.68751361665989, 51.313813049435602],
            [-0.699924698547189, 51.297833726573103],
            [-0.700480798609976, 51.291447284838888],
            [-0.697741156526429, 51.288774017109198],
            [-0.703844480973238, 51.284039845640152],
            [-0.706577662797451, 51.283321008407839],
            [-0.709883214451828, 51.282385406063469],
            [-0.715692495374975, 51.282053603435031],
            [-0.718995017983982, 51.280392033728425],
            [-0.719290635753464, 51.280486089403631],
            [-0.720252702089586, 51.279248477870993],
            [-0.72387645006668, 51.280055067455756],
            [-0.72834531081051, 51.280481359114901],
            [-0.729834812383925, 51.280021833442575],
            [-0.731111532408601, 51.280362994037318],
            [-0.731582412191678, 51.280601894832031],
            [-0.731230249652549, 51.281362423408964],
            [-0.731669929174651, 51.281537140540706],
            [-0.73231060048364, 51.281338154846139],
            [-0.733366330488392, 51.281791999124522],
            [-0.733277598795736, 51.282411506353959],
            [-0.733853177749, 51.282807096679832],
            [-0.733410949616129, 51.283402098997961],
            [-0.733890621617868, 51.283792155508742],
            [-0.73329747467454, 51.284034826871085],
            [-0.733778313822293, 51.284434787904452],
            [-0.734271737729988, 51.284377176307316],
            [-0.735146084059763, 51.285434223090327],
            [-0.735732378532945, 51.285441453493611],
            [-0.735555619715048, 51.285768662099791],
            [-0.736002476167301, 51.285945238553197],
            [-0.735639772863386, 51.286255153218647],
            [-0.736380674486323, 51.287526563731127],
            [-0.736107018062826, 51.288353596631396],
            [-0.737248136883084, 51.289675180259103],
            [-0.736705183675209, 51.292424552976826],
            [-0.738255493328081, 51.292897173649919],
            [-0.738488777142146, 51.29364784246993],
            [-0.739364748682213, 51.294026856692575],
            [-0.739324629011645, 51.295125278150742],
            [-0.739991372530278, 51.296127895674104],
            [-0.738964021753675, 51.297032249169149],
            [-0.739671338468907, 51.297287149931272],
            [-0.739828868469433, 51.298658364404822],
            [-0.73941809737259, 51.298939897235776],
            [-0.738853668520302, 51.299751215853938],
            [-0.739240764935899, 51.299915445528256],
            [-0.739410758804709, 51.301355135207309],
            [-0.740501176746721, 51.30192348643633],
            [-0.740304643107593, 51.302343108866864],
            [-0.741623463278923, 51.303688137529306],
            [-0.741750803281638, 51.304749690383218],
            [-0.742438970859267, 51.305027747677897],
            [-0.742338941945728, 51.305538331784454],
            [-0.743587942622621, 51.30571539550504],
            [-0.74363848094884, 51.306544122279014],
            [-0.74471606923518, 51.306905474167458],
            [-0.743607830833646, 51.307456505004801],
            [-0.743700075386365, 51.307861245624785],
            [-0.744441685992182, 51.308233383190448],
            [-0.744184589085287, 51.309295306748709],
            [-0.745670916290584, 51.310033309298461],
            [-0.746172496734533, 51.310001811588279],
            [-0.746406772764965, 51.31061848818414],
            [-0.747381693658484, 51.310433787246097],
            [-0.748141977954172, 51.311071371207753],
            [-0.747986876552032, 51.311340379278391],
            [-0.748656215750156, 51.311629887832247],
            [-0.749641141112077, 51.311447073389239],
            [-0.751285298693058, 51.312560764807465],
            [-0.752155210229144, 51.312439648512338],
            [-0.753590414652631, 51.312795734691129],
            [-0.754449700631293, 51.313490981370542],
            [-0.755601376228197, 51.31350951699217],
            [-0.75642836770892, 51.314020964686854],
            [-0.756465428971734, 51.314666998920785],
            [-0.75830827362106, 51.315925675342932],
            [-0.758529454907443, 51.31676879113386],
            [-0.759266443923037, 51.317162365558602],
            [-0.761088970728193, 51.316944262106894],
            [-0.761350833493227, 51.317344486887635],
            [-0.762329091874165, 51.31746543155144],
            [-0.763068633279817, 51.318352680624542],
            [-0.762920710151627, 51.318730589340525],
            [-0.763069269009241, 51.320410102443844],
            [-0.763683780742037, 51.320825734796621],
            [-0.763488834921463, 51.321404572970806],
            [-0.764091852546416, 51.321767926989509],
            [-0.764416246959812, 51.321551939609904],
            [-0.765081040516336, 51.322661392896656],
            [-0.766079193874521, 51.322901212324233],
            [-0.765920161651392, 51.323479533346287],
            [-0.764995117058079, 51.324097439251219],
            [-0.765008506263363, 51.324667685262675],
            [-0.766360478209957, 51.325950728597405],
            [-0.767110930461444, 51.326224797956137],
            [-0.768219758758709, 51.326676180540105],
            [-0.768616976145312, 51.327600257012442],
            [-0.769224172923149, 51.327865612920256],
            [-0.770668407053473, 51.327530981931716],
            [-0.772373818471876, 51.327895964464673],
            [-0.772238261490121, 51.328457454227895],
            [-0.773037147180556, 51.329017044839034],
            [-0.772731072118328, 51.329352843761434],
            [-0.773559356363823, 51.3294568353096],
            [-0.773617828052995, 51.33017052698824],
            [-0.774672286840089, 51.330621278391241],
            [-0.775483533362038, 51.331958804010299],
            [-0.77281189291433, 51.332777910487472],
            [-0.768985704892314, 51.335485775164969],
            [-0.764679251293713, 51.337030260593913],
            [-0.761448590786943, 51.340409578695855],
            [-0.76039299771233, 51.340263531871848],
            [-0.758209705148056, 51.340568624740897],
            [-0.759452218000377, 51.343470072518187],
            [-0.756990432354224, 51.344245181914935],
            [-0.75410267139728, 51.34627101716552],
            [-0.75237404283911, 51.348539329811075],
            [-0.749768636115655, 51.349934117353058],
            [-0.748070140176413, 51.352348361003372],
            [-0.744343190786711, 51.354569108812804],
            [-0.739628322987913, 51.359125170432677],
            [-0.737742395383516, 51.362316867667289],
            [-0.735352819459911, 51.365009430192771],
            [-0.735275843504218, 51.365040071935184],
            [-0.731870375732386, 51.366431220609584],
            [-0.72431116450181, 51.368460528247304],
            [-0.71050264138892, 51.370669830654393],
            [-0.707917976655945, 51.371339151873535],
            [-0.696527515750422, 51.374549998587717],
            [-0.681499358218212, 51.379640497912291],
            [-0.667652329068866, 51.384570761926668],
            [-0.664364808381709, 51.385738292406835],
            [-0.658186604680472, 51.386280984505682],
            [-0.655318575107424, 51.387245345031808],
            [-0.652394394903447, 51.386790023277122],
            [-0.650631749056334, 51.385641221904613],
            [-0.647054837285153, 51.385931794371587],
            [-0.646617583415031, 51.385262222636463],
            [-0.640479023694405, 51.387371779008213],
            [-0.639470202364558, 51.38664249450126],
            [-0.638539917947421, 51.386657768262062],
            [-0.636458237419147, 51.385375557671793],
            [-0.632525213327757, 51.384303719923395],
            [-0.628236184399967, 51.384234710019378],
            [-0.625948922875114, 51.382872570841229],
            [-0.625320067300024, 51.382805847172058],
            [-0.62094077152043, 51.385171501851922],
            [-0.61962629547348, 51.384821515828222],
            [-0.619267649455148, 51.38499173982521],
            [-0.61806305766109, 51.383379624122242],
            [-0.61636252884235, 51.38353940296367],
            [-0.617212157311829, 51.385817268662962],
            [-0.618859870492036, 51.38746249128679],
            [-0.620533913098496, 51.388474948189412],
            [-0.623512800990796, 51.389543178597975],
            [-0.621836469765697, 51.390038731140599],
            [-0.618474026069853, 51.391780498587977],
            [-0.618024480500543, 51.392055755312448],
            [-0.618513224781698, 51.39238793456348],
            [-0.616517625307711, 51.392407556733637],
            [-0.608928475057721, 51.38985025939683],
            [-0.607265799034709, 51.389701024145111],
            [-0.605992239370534, 51.388382010290265],
            [-0.604170112145089, 51.388090555331843],
            [-0.603273055243424, 51.387343404247233],
            [-0.598018986550754, 51.386301413162535],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000215",
        LAD13CDO: "43UK",
        LAD13NM: "Tandridge",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.022083832449432, 51.338099784731234],
            [-0.020584450968881, 51.337144588275905],
            [-0.017810030106008, 51.332933054345141],
            [-0.017057608446228, 51.332904129440934],
            [-0.015967674437049, 51.332014257437059],
            [-0.014356302238495, 51.329804369344579],
            [-0.013631635636529, 51.331525912034863],
            [-0.011914536569116, 51.332891574262355],
            [-0.010691491578879, 51.333089332939721],
            [-0.010902174947093, 51.33362798936367],
            [-0.00916890046872, 51.333905195562444],
            [-0.007146670722932, 51.333694540319634],
            [-0.007237539829199, 51.333386730513624],
            [-0.001705233449911, 51.330306813175568],
            [-0.000531494655283, 51.328777766571612],
            [0.002266081487411, 51.329138246307849],
            [0.004841868344107, 51.322076091780609],
            [0.005517297637795, 51.31844396740086],
            [0.006552354695073, 51.315656407403303],
            [0.010320365184879, 51.316140347199173],
            [0.008363167927088, 51.310361785913734],
            [0.012130939143441, 51.299599060340576],
            [0.014982112678468, 51.291787278516878],
            [0.019073952123889, 51.29168530433806],
            [0.020846747884853, 51.295572964911713],
            [0.020925547653484, 51.296779363721541],
            [0.02357164580737, 51.299704942709909],
            [0.024811342673592, 51.302155682686788],
            [0.027949900371255, 51.304685947600703],
            [0.0302537951084, 51.305558808573942],
            [0.032881406068898, 51.307521351071614],
            [0.036308882385116, 51.305863735650703],
            [0.040889741733115, 51.302721856818557],
            [0.04149127982004, 51.302990152786613],
            [0.042775225505347, 51.300985698095154],
            [0.043065360866001, 51.299055230942514],
            [0.044270838932082, 51.298581839467417],
            [0.043285506240134, 51.296582805521972],
            [0.042368994859629, 51.292674243173188],
            [0.042597501424943, 51.292038046861805],
            [0.044760773151959, 51.290722373387474],
            [0.046712800967339, 51.288593784775529],
            [0.048143830146792, 51.285654113015276],
            [0.046300867616777, 51.279553087957204],
            [0.045333715800994, 51.277923363980761],
            [0.045369450209969, 51.276661914181112],
            [0.048005061814309, 51.27012284392363],
            [0.049583706758376, 51.268034001361478],
            [0.04965311161014, 51.265695487681349],
            [0.05191577284993, 51.26268453114173],
            [0.053225229875512, 51.258166825353406],
            [0.054239802509836, 51.256587822297675],
            [0.053817024842101, 51.254079875340224],
            [0.055104199755772, 51.252381866600977],
            [0.057023209607555, 51.251293262551634],
            [0.057961907821785, 51.248919667940598],
            [0.058216250980611, 51.247780265405815],
            [0.05758116364052, 51.244916337469476],
            [0.057338252648623, 51.244064464333903],
            [0.056212638284555, 51.243177742413707],
            [0.055366669258127, 51.240560320890225],
            [0.053530910689415, 51.239327219274173],
            [0.053903762596809, 51.238145276106778],
            [0.052833278700174, 51.236529112439413],
            [0.048026801065196, 51.233847052762734],
            [0.046032353891519, 51.233320772818644],
            [0.045109192362655, 51.231949274116502],
            [0.043783083746886, 51.23088967326742],
            [0.043837278921449, 51.229785272058066],
            [0.043012763768798, 51.229085617853166],
            [0.042275465798309, 51.227379005816339],
            [0.043659160940879, 51.225829614592328],
            [0.042807508261638, 51.224612429459832],
            [0.042287842318891, 51.222579164056327],
            [0.042924855460098, 51.22179643383776],
            [0.043140424044115, 51.220164015404855],
            [0.041423342822759, 51.218253270992747],
            [0.041122257723903, 51.216626271804891],
            [0.036253213078242, 51.216584309795856],
            [0.033572534419562, 51.214340385776303],
            [0.034694622317031, 51.213484516960698],
            [0.033997796384227, 51.212033452305711],
            [0.034025065116169, 51.211742500351477],
            [0.034568502661497, 51.211662904871929],
            [0.033561430144263, 51.211326084468411],
            [0.033961455702243, 51.210883863551963],
            [0.033745160179687, 51.210556676647876],
            [0.035363502662471, 51.208488892164397],
            [0.036352952555709, 51.20816770645942],
            [0.036171927681328, 51.207958619299241],
            [0.037263419244345, 51.207062787161838],
            [0.036670848406629, 51.205183646993973],
            [0.03576781075152, 51.204216414702024],
            [0.039131873122055, 51.200875330448241],
            [0.038282796799372, 51.199767782821787],
            [0.038360457589824, 51.198835639121299],
            [0.038670497646103, 51.198466014070547],
            [0.039297279552266, 51.198462284049],
            [0.039655592867317, 51.197824718257785],
            [0.040418358203601, 51.197886059790008],
            [0.04065109994274, 51.197614004214252],
            [0.040393044631514, 51.196665231442324],
            [0.041372954232645, 51.195843248659415],
            [0.042507431076812, 51.194050894990923],
            [0.042038738850428, 51.193711941697565],
            [0.042487839183595, 51.193607874388064],
            [0.042748348625099, 51.192993589375561],
            [0.044024427575032, 51.19262506214482],
            [0.044518482160993, 51.191855606698255],
            [0.045990415366958, 51.192161719004083],
            [0.04693974063175, 51.191687358949714],
            [0.047796677665601, 51.191744305972691],
            [0.047298688306398, 51.190002052926822],
            [0.045140813056398, 51.188617980127624],
            [0.046228595131862, 51.187846228404361],
            [0.046832703476691, 51.187786196730627],
            [0.046230653763078, 51.187343474147156],
            [0.047332382012431, 51.186338544546516],
            [0.047333761994814, 51.18556240945059],
            [0.048887702149462, 51.184882296191539],
            [0.049410377155982, 51.185019728565528],
            [0.049784608116828, 51.182907867786042],
            [0.052259932585909, 51.181229502587733],
            [0.052573704842033, 51.180626849227117],
            [0.051658245119182, 51.17869948246755],
            [0.051750939521026, 51.177758068145934],
            [0.050876966120624, 51.177182543216439],
            [0.050472303664117, 51.176284922996039],
            [0.048670485005202, 51.175594343480881],
            [0.048204006034399, 51.174333574057727],
            [0.047262233141942, 51.174065877542319],
            [0.046666940289656, 51.173480946691363],
            [0.046149125999515, 51.171834360189955],
            [0.046535006888272, 51.171470579010652],
            [0.046224035915008, 51.169196245221869],
            [0.047001827554271, 51.168929026632576],
            [0.046657386758912, 51.168451220604531],
            [0.047059915439609, 51.167721122808814],
            [0.046365017641285, 51.166208936239336],
            [0.046918064097009, 51.164872761010322],
            [0.04859732301053, 51.164421577213581],
            [0.047486919608655, 51.161245736279639],
            [0.048874042767037, 51.158820255759473],
            [0.051467279198723, 51.157248645620697],
            [0.051407167280648, 51.154378164975505],
            [0.050814179634666, 51.153518021721318],
            [0.051196319083781, 51.15168749372679],
            [0.052387297403036, 51.150749288200288],
            [0.054655560245872, 51.150436961563145],
            [0.054778365154974, 51.149531883317728],
            [0.054222600811555, 51.148318567915545],
            [0.053049432664402, 51.147465028618853],
            [0.051824560144611, 51.144928851545394],
            [0.050890997267922, 51.144704210108095],
            [0.04986382776475, 51.143915527819416],
            [0.049989478251868, 51.14265337284764],
            [0.042917945304554, 51.141639488706303],
            [0.041681023673599, 51.141017177203594],
            [0.041178340643579, 51.141191428565605],
            [0.038340819024736, 51.140288543639208],
            [0.035208651531902, 51.140387168126736],
            [0.031568774347538, 51.139797556229716],
            [0.029301010161075, 51.140015000047995],
            [0.027334010769239, 51.139853967207252],
            [0.023262130984169, 51.139058447507701],
            [0.020952643295289, 51.139302531542839],
            [0.019890372136168, 51.139954907077737],
            [0.016904338634726, 51.139109827583347],
            [0.015606858070147, 51.139253608271645],
            [0.011601517752194, 51.138930478132238],
            [0.007008929882539, 51.138975220959587],
            [0.001042783789241, 51.139587384312222],
            [-0.001462921941708, 51.138932366152183],
            [-0.003041699643056, 51.138157147384923],
            [-0.004352131498939, 51.13796817985093],
            [-0.005368925292381, 51.138223848003499],
            [-0.006616356783684, 51.137572430514027],
            [-0.006290611713484, 51.138397846416737],
            [-0.006828058702683, 51.139162435369293],
            [-0.007679213810636, 51.13868411466953],
            [-0.009924648418062, 51.138226827503637],
            [-0.010223604404442, 51.138754418293423],
            [-0.011364186598034, 51.139091280200986],
            [-0.01154390787465, 51.138867708658935],
            [-0.012423122511645, 51.138899743493454],
            [-0.012564278495111, 51.139274459845559],
            [-0.014447720895836, 51.139690464348632],
            [-0.014383727700345, 51.140049104287549],
            [-0.016197368045854, 51.1403937482986],
            [-0.016720972372532, 51.141018661588987],
            [-0.017813840526305, 51.141203565493775],
            [-0.018384799751399, 51.141190759599638],
            [-0.018775430866094, 51.14078189478851],
            [-0.020383787327389, 51.141014177955533],
            [-0.020082905441596, 51.139292290172278],
            [-0.021253872570689, 51.139388552885336],
            [-0.024622028962132, 51.138581257886905],
            [-0.031256946184829, 51.138669772107342],
            [-0.032214004240399, 51.138955675930866],
            [-0.033268725329043, 51.139264798021443],
            [-0.037409360040901, 51.139214757543876],
            [-0.041491811790413, 51.139593467722996],
            [-0.044583911526679, 51.139390709706468],
            [-0.053044710310161, 51.137842978258824],
            [-0.056353324521066, 51.136769383248989],
            [-0.063231148998725, 51.141261348367728],
            [-0.067107359759927, 51.141110548100805],
            [-0.073990058784976, 51.141781682678953],
            [-0.076340245322885, 51.142299698832218],
            [-0.081093266469209, 51.142207820133507],
            [-0.082640732053792, 51.142641488775489],
            [-0.085416697018993, 51.142754418763445],
            [-0.093087498619468, 51.138710566351534],
            [-0.093718017930941, 51.138430370250632],
            [-0.109196797367057, 51.139371443835941],
            [-0.11179236400906, 51.14008336574517],
            [-0.111671651678248, 51.140379086650441],
            [-0.114992064442102, 51.140706045763508],
            [-0.114613997956402, 51.14110823541597],
            [-0.117279773114594, 51.141609829575678],
            [-0.117856383432646, 51.140578615966376],
            [-0.121009388298338, 51.140734539343853],
            [-0.124548602111922, 51.140321017497726],
            [-0.125160047239569, 51.14089558489259],
            [-0.127588276325082, 51.140345446020937],
            [-0.127429313990631, 51.139539822426485],
            [-0.131052439516743, 51.139487169407396],
            [-0.13177048799609, 51.14149509620411],
            [-0.137599181107207, 51.142163621805928],
            [-0.136358320424315, 51.14638676122533],
            [-0.135057814484816, 51.155984956607078],
            [-0.134219682609332, 51.157725225211109],
            [-0.132987227867335, 51.158887238649825],
            [-0.13316349042564, 51.159232685700786],
            [-0.136011849241049, 51.159741254891969],
            [-0.140490081278012, 51.160050853759437],
            [-0.140386578522359, 51.16041971954612],
            [-0.135888959715855, 51.160732123526401],
            [-0.128683465134889, 51.162610808931561],
            [-0.128281334104275, 51.162793227440694],
            [-0.127746835245121, 51.165043715236898],
            [-0.129007128973363, 51.174794301941226],
            [-0.135004897456193, 51.17655198637982],
            [-0.13515820203652, 51.17736199992153],
            [-0.136402048010559, 51.17795107839801],
            [-0.135912108790543, 51.179314700491666],
            [-0.136722877658682, 51.181207148796737],
            [-0.139446065138379, 51.182549077482641],
            [-0.141694193102997, 51.184855534963354],
            [-0.140351411224015, 51.185971806048272],
            [-0.141518594933208, 51.186446297668404],
            [-0.143535466075932, 51.1865376752977],
            [-0.143264673249295, 51.189715084565037],
            [-0.142982610656807, 51.190394971069878],
            [-0.141944942899823, 51.19061141224271],
            [-0.141582429815298, 51.191476171712146],
            [-0.139361729031727, 51.193029028805434],
            [-0.137803240613027, 51.195973703881847],
            [-0.137050993980956, 51.20057151187941],
            [-0.137930729637709, 51.203993840911572],
            [-0.137776464505694, 51.206611927076871],
            [-0.137347548554875, 51.206597905201072],
            [-0.136756801169693, 51.207578621108368],
            [-0.135681280846628, 51.210530055730707],
            [-0.135945880099491, 51.211139495663453],
            [-0.135990145651471, 51.212099746371265],
            [-0.143771842691992, 51.210746818958029],
            [-0.145529012714892, 51.210736012768649],
            [-0.144562869775666, 51.213222526005161],
            [-0.144760115818806, 51.21625896384726],
            [-0.145397981493433, 51.216456128250179],
            [-0.147470504694055, 51.220009682196263],
            [-0.14958463627055, 51.222069233923143],
            [-0.152927595305078, 51.226314536165731],
            [-0.152468361287318, 51.226766823292792],
            [-0.153381866230574, 51.227131065603402],
            [-0.154985023608005, 51.230664476931508],
            [-0.15434888948551, 51.231957514373512],
            [-0.153001902418062, 51.234331966109082],
            [-0.152273103474112, 51.235038992526135],
            [-0.151472553911139, 51.234926529141141],
            [-0.150690726656101, 51.235990624787689],
            [-0.150101666314348, 51.235819443036334],
            [-0.149303430683401, 51.234542423260621],
            [-0.148043474738483, 51.234205941736825],
            [-0.145333510874377, 51.23729884413271],
            [-0.146048843039521, 51.239567391448034],
            [-0.145354377695214, 51.240591468456209],
            [-0.145314073393716, 51.241593535611003],
            [-0.143101704685681, 51.243877717487962],
            [-0.142759703586462, 51.245787773229573],
            [-0.144398642897846, 51.247928892915233],
            [-0.144499737639316, 51.248799206785016],
            [-0.144923603681134, 51.250230395847353],
            [-0.145763800932653, 51.251215838312739],
            [-0.144225275437066, 51.251808364741073],
            [-0.142974795298015, 51.253324512322486],
            [-0.139473252203646, 51.253339048770087],
            [-0.135161268096145, 51.254718278458689],
            [-0.131818519921698, 51.254171263078035],
            [-0.131548242614985, 51.256560851631619],
            [-0.129650259546077, 51.259892073455163],
            [-0.127633732764998, 51.261653911330498],
            [-0.126827650731049, 51.261738137346775],
            [-0.126850561424109, 51.264100930392416],
            [-0.127788946842459, 51.264324577632514],
            [-0.128660428544309, 51.264148763842257],
            [-0.130119233750463, 51.265606438147174],
            [-0.129699174526743, 51.266525991829006],
            [-0.132062123580929, 51.269021470214746],
            [-0.132048158860792, 51.269684020660812],
            [-0.130011939826619, 51.272558000335252],
            [-0.130274233681655, 51.274360759948749],
            [-0.131520154503846, 51.276833901283212],
            [-0.13050523511256, 51.27947778066315],
            [-0.13040037175631, 51.283261191738823],
            [-0.131082817518305, 51.284818855696251],
            [-0.126823987337452, 51.284918058116396],
            [-0.127362974763244, 51.286439275740477],
            [-0.124319668186361, 51.286760163106521],
            [-0.117802280478234, 51.287096213862775],
            [-0.117007758774738, 51.289375713581713],
            [-0.115756225887715, 51.29072968427046],
            [-0.115143805777065, 51.291465332983663],
            [-0.114621784092438, 51.291534266156525],
            [-0.11485021143677, 51.292435429987371],
            [-0.111079759097666, 51.292254155017112],
            [-0.103029698944671, 51.295860529875441],
            [-0.101167434738563, 51.296288078488558],
            [-0.094351796710637, 51.299355419241088],
            [-0.095803306823098, 51.299602956860269],
            [-0.097272239462477, 51.300296804859521],
            [-0.097404791643354, 51.301115509538675],
            [-0.096058228120496, 51.301519881798399],
            [-0.09441441239047, 51.300614538518495],
            [-0.091195518307555, 51.301473981682143],
            [-0.090903858760797, 51.303178768062565],
            [-0.091199104919727, 51.303393115097094],
            [-0.090743967565288, 51.303954941975718],
            [-0.089733095360635, 51.303976224180197],
            [-0.088759380198797, 51.305106023719411],
            [-0.088852330334888, 51.307219961620106],
            [-0.08843040264378, 51.307602464606774],
            [-0.086604717597315, 51.308860421499176],
            [-0.081958888714383, 51.310648630973304],
            [-0.081920131223132, 51.31261023630033],
            [-0.084829346456001, 51.315868299817431],
            [-0.083618246185963, 51.316383560013108],
            [-0.083582830057881, 51.316682442074558],
            [-0.082235013001669, 51.316965233765551],
            [-0.081569988365938, 51.316704339567643],
            [-0.080220250871138, 51.317823411298789],
            [-0.078857243671393, 51.318090627484573],
            [-0.078950673588562, 51.319770225100925],
            [-0.075146836968067, 51.320198771259292],
            [-0.071043367701129, 51.321233772779571],
            [-0.070055154954906, 51.318893737132903],
            [-0.064021239009557, 51.318637660565138],
            [-0.062106014195122, 51.319201308793801],
            [-0.06068467594054, 51.320065374306637],
            [-0.058419470594696, 51.320383955912597],
            [-0.05627858990163, 51.321664995816882],
            [-0.05490939178455, 51.321258265770304],
            [-0.052508042038834, 51.322382931152951],
            [-0.05133410498527, 51.322448834330714],
            [-0.051293563429583, 51.323439174114597],
            [-0.049792223488202, 51.325267611757091],
            [-0.047895657692476, 51.325245904312936],
            [-0.047854872993378, 51.326510521444256],
            [-0.049096404716283, 51.328608563325048],
            [-0.048775914363454, 51.328719231852418],
            [-0.051107290175895, 51.330846195693582],
            [-0.051068375239253, 51.33159465335244],
            [-0.050098605472951, 51.33205333382061],
            [-0.050287606196995, 51.332640117097334],
            [-0.048499720726753, 51.332790195641714],
            [-0.04657961385431, 51.333483908360122],
            [-0.041993372133904, 51.33381110086129],
            [-0.039842139843115, 51.337227476720088],
            [-0.037992769255879, 51.338644361003396],
            [-0.034520745006775, 51.338083443141706],
            [-0.032796779659365, 51.337438486725844],
            [-0.030707395603291, 51.337515783597631],
            [-0.025984999902759, 51.338560391916523],
            [-0.022083832449432, 51.338099784731234],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000216",
        LAD13CDO: "43UL",
        LAD13NM: "Waverley",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.8034753010776, 51.241512973011091],
            [-0.801757384647833, 51.239040507225695],
            [-0.798507241080659, 51.239416343675479],
            [-0.797203973823501, 51.239623271485861],
            [-0.793064746423903, 51.241618322949265],
            [-0.79024878710165, 51.242417447189162],
            [-0.786918093265237, 51.241866823146275],
            [-0.784441730356825, 51.242006594267437],
            [-0.781946332304212, 51.241673120027095],
            [-0.777911168563508, 51.242141827898344],
            [-0.775874359452795, 51.241911925841805],
            [-0.771489408224024, 51.238997444756521],
            [-0.771380337703393, 51.238036820369892],
            [-0.769745366973606, 51.23644419555005],
            [-0.767712670335731, 51.236071215486533],
            [-0.761193768858385, 51.234894555233879],
            [-0.759679798124471, 51.23403953933348],
            [-0.758738648080511, 51.233882988362943],
            [-0.752117861279545, 51.231991647406353],
            [-0.750272313191903, 51.23101427420611],
            [-0.745558055075641, 51.230458477968718],
            [-0.746321466408451, 51.229087231345844],
            [-0.745669290350999, 51.227683733157605],
            [-0.742296500611134, 51.228367828573546],
            [-0.742125584564031, 51.22754499050896],
            [-0.741601017182137, 51.225563732495381],
            [-0.742386672787302, 51.225479555240796],
            [-0.741618649461621, 51.224181797250395],
            [-0.744400703387817, 51.223619077955654],
            [-0.748353572630767, 51.223497753877268],
            [-0.748252292480003, 51.223113596625026],
            [-0.747425300228679, 51.223139819698524],
            [-0.747387925719095, 51.221878691809685],
            [-0.746543791907187, 51.221956880784809],
            [-0.746142265363981, 51.221292541222155],
            [-0.742763840603137, 51.221570134095764],
            [-0.741876747456625, 51.219599368335309],
            [-0.746275087946546, 51.216455175429935],
            [-0.746597745391808, 51.214169175265809],
            [-0.747671266345818, 51.214391088320369],
            [-0.748323135473669, 51.210678818475742],
            [-0.74674428675205, 51.20919256869098],
            [-0.747219404709356, 51.208935078203858],
            [-0.745906145492388, 51.206844673588954],
            [-0.742230602354071, 51.205665894759143],
            [-0.731660280752594, 51.19686426040392],
            [-0.719986881264895, 51.200390772070293],
            [-0.718727353150058, 51.199615331707911],
            [-0.718589796904789, 51.200755861304593],
            [-0.713184456438029, 51.202087674459058],
            [-0.708933042898615, 51.203042603427079],
            [-0.706578998237454, 51.202713565074923],
            [-0.703240763696969, 51.19655188478395],
            [-0.703111994357361, 51.195887716039081],
            [-0.703758996137653, 51.194761839066743],
            [-0.702540271501094, 51.192650398969938],
            [-0.701951244618954, 51.192374992789027],
            [-0.700533144763182, 51.19267668629827],
            [-0.700362936133818, 51.192012053722031],
            [-0.699634309841059, 51.192731446852243],
            [-0.698891180296029, 51.191983109065632],
            [-0.696931788321994, 51.191941522734183],
            [-0.69619553197466, 51.192067308918418],
            [-0.69610946435162, 51.192581616499723],
            [-0.695614234704692, 51.191215541493634],
            [-0.694687386858774, 51.191198911423996],
            [-0.694420972192757, 51.191561931506989],
            [-0.694826189724186, 51.191857806926656],
            [-0.694414468315519, 51.191944037563665],
            [-0.693756514760064, 51.191780226283186],
            [-0.693733888976192, 51.191315064613967],
            [-0.6931724626564, 51.190976974917909],
            [-0.691323539380508, 51.191028251012952],
            [-0.691297479091086, 51.190179972252295],
            [-0.688457445979483, 51.189707544649252],
            [-0.688284255778291, 51.18910400866848],
            [-0.68874014805569, 51.188822259278723],
            [-0.688282354028718, 51.188717312127096],
            [-0.685397706656069, 51.190018519676229],
            [-0.685260674501816, 51.191565479401483],
            [-0.684721765915949, 51.191695209116688],
            [-0.684876571754792, 51.192390266580475],
            [-0.683009051558372, 51.194647948312934],
            [-0.682580770930425, 51.194560396921972],
            [-0.681404317305233, 51.195314201594478],
            [-0.679550652258203, 51.195274415170928],
            [-0.679559161905142, 51.195577556564267],
            [-0.676206269438353, 51.195215047046375],
            [-0.670979215486329, 51.196025367978201],
            [-0.669298925019701, 51.196872245265908],
            [-0.667400290126307, 51.199183280754802],
            [-0.666119811335803, 51.199264012037332],
            [-0.66342621897176, 51.200249424707984],
            [-0.663137267831125, 51.198759671927924],
            [-0.660900850509704, 51.195823243349437],
            [-0.660659487927496, 51.193189287183721],
            [-0.658566865301861, 51.190982845271961],
            [-0.656769057238517, 51.187354442087262],
            [-0.652296074972685, 51.183425450910562],
            [-0.650418357084636, 51.183148421289758],
            [-0.653184842754553, 51.181649777309595],
            [-0.652928244475866, 51.181077599044549],
            [-0.651526582909872, 51.181208930059945],
            [-0.650575541472944, 51.179136886919615],
            [-0.648139633884137, 51.175840894747843],
            [-0.640934509217081, 51.17888321482743],
            [-0.638386412011037, 51.180391306327266],
            [-0.632797273700664, 51.182883596772569],
            [-0.633290495813623, 51.183574587419422],
            [-0.631174961266134, 51.184132571226641],
            [-0.629937023266233, 51.191043202508084],
            [-0.629334416681568, 51.191578392328346],
            [-0.630103149916845, 51.192245644760447],
            [-0.629753559838231, 51.192790093263724],
            [-0.632468433189785, 51.193609594947389],
            [-0.637737133068177, 51.194507351330529],
            [-0.637852641561397, 51.19486120113033],
            [-0.638735608491371, 51.195154739172921],
            [-0.63706448342521, 51.198000289650707],
            [-0.634717555113923, 51.198472912687009],
            [-0.635428984277657, 51.201086330187579],
            [-0.635520604321562, 51.201618853506965],
            [-0.633172127211038, 51.20252396585628],
            [-0.629242324398967, 51.20235120574177],
            [-0.628717155306876, 51.202104953853564],
            [-0.628202709945059, 51.203570093095372],
            [-0.625901334299738, 51.203846139143067],
            [-0.623652161416987, 51.202321560859012],
            [-0.621279275630821, 51.202884434142568],
            [-0.618386180082257, 51.204613738245435],
            [-0.618742075285303, 51.204966847666704],
            [-0.61747269424762, 51.205529175191941],
            [-0.617697680600818, 51.20576024190877],
            [-0.616515773400036, 51.207033098819153],
            [-0.614134417148596, 51.208057941329159],
            [-0.61337243599073, 51.207498575546445],
            [-0.610142537212727, 51.20689013694728],
            [-0.603490650830594, 51.20436498914647],
            [-0.600206397512137, 51.204475025322445],
            [-0.594410754508642, 51.203286888881173],
            [-0.592520555616624, 51.202887383845606],
            [-0.591433274557247, 51.201225076017202],
            [-0.590137666683437, 51.200481979683033],
            [-0.588729010947709, 51.200207813680123],
            [-0.588335770142557, 51.199867649795102],
            [-0.586881170608564, 51.199879768261411],
            [-0.584894511347695, 51.198711013236867],
            [-0.586057613163668, 51.197840209587973],
            [-0.589030119468431, 51.197191759265571],
            [-0.587352436213666, 51.197060010598037],
            [-0.58710306306209, 51.196275550146346],
            [-0.587526620626948, 51.195997399356735],
            [-0.588739505997536, 51.195507553553355],
            [-0.589207298233055, 51.196217308462245],
            [-0.589625968241603, 51.19623944120179],
            [-0.592033711441912, 51.195004089393009],
            [-0.593372348874527, 51.193718972007332],
            [-0.593717652887568, 51.191260971298476],
            [-0.590925826341547, 51.190457624731444],
            [-0.58834410311389, 51.190181010210019],
            [-0.586797007255993, 51.189531059695966],
            [-0.586254335179128, 51.189868917957106],
            [-0.587217875082949, 51.190742939044753],
            [-0.584934861434689, 51.190432086903392],
            [-0.58473840382372, 51.190819087279856],
            [-0.583651817189964, 51.190786156123032],
            [-0.582708246715596, 51.189729798906271],
            [-0.581713399511944, 51.189739326440176],
            [-0.581891382140432, 51.188835935758803],
            [-0.577197639856954, 51.188142282779559],
            [-0.576261453799746, 51.189035544247275],
            [-0.576148326089396, 51.189734686222607],
            [-0.578305019196602, 51.189776198047582],
            [-0.57819048185474, 51.190847615508204],
            [-0.577524929440385, 51.192664110955405],
            [-0.574395721861981, 51.192968643515918],
            [-0.574396300299908, 51.196335453745533],
            [-0.574922999336289, 51.19684545091647],
            [-0.573593227326465, 51.198426321217894],
            [-0.57078115293277, 51.199384783604906],
            [-0.569522530242629, 51.200463794716377],
            [-0.565861983696068, 51.199975657538317],
            [-0.562367978471473, 51.201680931512932],
            [-0.560811625678275, 51.200684312343725],
            [-0.560032710347276, 51.200830300750695],
            [-0.559134683939329, 51.200114231889991],
            [-0.558151563279286, 51.200153379731908],
            [-0.55742142300759, 51.199654287627361],
            [-0.555580125357192, 51.199261980247194],
            [-0.553461192612696, 51.200834678928587],
            [-0.552870313723518, 51.200460477527855],
            [-0.5520605461528, 51.201407266657682],
            [-0.550316103915782, 51.206182287652425],
            [-0.547621630630086, 51.20960650613668],
            [-0.544295258574241, 51.210354720130944],
            [-0.543917887907276, 51.211607181291278],
            [-0.542659180489564, 51.212023154799724],
            [-0.537073603986833, 51.212580243720389],
            [-0.535076144542236, 51.213173044198761],
            [-0.533681316940648, 51.213137590673426],
            [-0.532467239525895, 51.213451499251832],
            [-0.53068764593947, 51.213359027707767],
            [-0.529288918503477, 51.211693124934243],
            [-0.527733667316057, 51.21139210247118],
            [-0.526857609239876, 51.208964780156549],
            [-0.524678789775605, 51.209454403574327],
            [-0.524292443012075, 51.208418086558559],
            [-0.519845845144929, 51.208017513525739],
            [-0.516740758446558, 51.206768698199461],
            [-0.51172064668929, 51.206624925791736],
            [-0.508590705754843, 51.205534744813235],
            [-0.505659793054284, 51.20502254575517],
            [-0.506525420626893, 51.20096355688505],
            [-0.503814548820652, 51.200163657611832],
            [-0.502837927006506, 51.199294172854273],
            [-0.503482024102928, 51.198675629052239],
            [-0.505625538342972, 51.197941351763667],
            [-0.507178636558219, 51.196253484566903],
            [-0.50498481098602, 51.192575383076374],
            [-0.50446188171419, 51.190382600716397],
            [-0.504777022846785, 51.189400140553438],
            [-0.504443281865684, 51.187641419157416],
            [-0.505365112298557, 51.18360652772072],
            [-0.501050160945299, 51.183548627877052],
            [-0.498057014283554, 51.183851961603395],
            [-0.495111083799566, 51.183231385517935],
            [-0.494732854073694, 51.184476493248013],
            [-0.49406714724075, 51.184399582773878],
            [-0.491589161185027, 51.183602389961692],
            [-0.491829962793011, 51.182186459188863],
            [-0.490371518206494, 51.18171891803086],
            [-0.490162091523242, 51.179983337287638],
            [-0.489385895343588, 51.17965587815295],
            [-0.488523073346233, 51.178127678298317],
            [-0.487358244315162, 51.178223232999386],
            [-0.485072375554436, 51.178998496350737],
            [-0.483102970199866, 51.178891145442414],
            [-0.481588584288914, 51.178608016534433],
            [-0.479412741842719, 51.177426900980649],
            [-0.47578879044439, 51.178038004303417],
            [-0.475608823336075, 51.178462809847254],
            [-0.475090204184656, 51.178571156988582],
            [-0.467623324475499, 51.179524833968806],
            [-0.466046328708453, 51.177897181946499],
            [-0.464372644744787, 51.177116245618549],
            [-0.460826249843736, 51.177197337502285],
            [-0.459470937159516, 51.177426796703756],
            [-0.459060390987578, 51.177940266111527],
            [-0.4576236112853, 51.176365603368829],
            [-0.457089869809168, 51.174954811319367],
            [-0.456429632114581, 51.175748247181488],
            [-0.447418393245266, 51.18064331439637],
            [-0.447621761386059, 51.181872611820026],
            [-0.443941000846907, 51.18359526881909],
            [-0.442014012487637, 51.182778269553495],
            [-0.437386753286483, 51.179561930003629],
            [-0.43719896904527, 51.179868769592254],
            [-0.434147424436713, 51.179651710368709],
            [-0.432907974854813, 51.17911623728007],
            [-0.431509064023245, 51.177799847116745],
            [-0.431276769869839, 51.176002692698482],
            [-0.429542530285214, 51.174075673639663],
            [-0.427917752797901, 51.175874869987467],
            [-0.425934256563748, 51.174508288506438],
            [-0.425493860861278, 51.174711891789407],
            [-0.422504369702234, 51.173568215180985],
            [-0.419258213163379, 51.173534289293826],
            [-0.418589215843148, 51.173185323744839],
            [-0.41846620166084, 51.172223240566012],
            [-0.416215324995062, 51.168610994921345],
            [-0.416648203036509, 51.167080900387575],
            [-0.415716148883282, 51.163224796003277],
            [-0.415236094973328, 51.15845215252817],
            [-0.415662248101584, 51.157492107015955],
            [-0.416363432109219, 51.157353222325241],
            [-0.415517806200894, 51.156365160751591],
            [-0.415994101247904, 51.154984034206151],
            [-0.414864031313544, 51.152324862824081],
            [-0.414585753386939, 51.150180820839203],
            [-0.415139250975927, 51.145041778338076],
            [-0.414861207916681, 51.142474179280434],
            [-0.415356718767389, 51.140110406017435],
            [-0.415974644694403, 51.139138567198238],
            [-0.415733459170323, 51.137539986453817],
            [-0.416349382496077, 51.132405371960651],
            [-0.415334762583845, 51.132191988876833],
            [-0.415133699998607, 51.13159394356321],
            [-0.415956764326742, 51.131473800486958],
            [-0.415845440257829, 51.131135064052991],
            [-0.413745496942324, 51.128143483667216],
            [-0.413261609575248, 51.126332969795904],
            [-0.413329700557797, 51.125430120258578],
            [-0.417029812312087, 51.12471048390649],
            [-0.417972782372567, 51.121411207350619],
            [-0.417885847697888, 51.118183423615093],
            [-0.418382482754985, 51.117029174354592],
            [-0.418323619407332, 51.114359320997345],
            [-0.418662510618854, 51.113848618074499],
            [-0.418472904937828, 51.110153616088141],
            [-0.418232335265751, 51.109375183597152],
            [-0.417312064903885, 51.109132515368131],
            [-0.417411692912738, 51.108934223902217],
            [-0.418943419462919, 51.109217534917001],
            [-0.419546967944831, 51.108024257693621],
            [-0.419822620921169, 51.106768992560305],
            [-0.419228744361827, 51.105208809044264],
            [-0.420983997606994, 51.104420477002016],
            [-0.421807138030005, 51.103486447727981],
            [-0.426557376971405, 51.102492017191388],
            [-0.42830156598507, 51.101391377209545],
            [-0.433074141702597, 51.100697342496865],
            [-0.43557325471484, 51.099693040304274],
            [-0.437268558544421, 51.0982112184453],
            [-0.438461178733502, 51.097912405322283],
            [-0.441238955319673, 51.097744436189011],
            [-0.441458145803957, 51.098021638173392],
            [-0.44252843929352, 51.098132123200514],
            [-0.442367488568551, 51.099096703236953],
            [-0.438852018113808, 51.100301612038571],
            [-0.439991062111314, 51.100832101648272],
            [-0.439561705993045, 51.101070076615599],
            [-0.439496240669789, 51.101996358648172],
            [-0.440090070878108, 51.102049246600167],
            [-0.44050056859658, 51.103051122410314],
            [-0.441469145026446, 51.103301446398021],
            [-0.44108314034594, 51.10174054983748],
            [-0.443818871068169, 51.101087247140022],
            [-0.444874396346099, 51.099596799209159],
            [-0.446376328388664, 51.100594281221717],
            [-0.449525350459335, 51.100561442241997],
            [-0.452044694150647, 51.099539072195398],
            [-0.454286735436882, 51.100361896227049],
            [-0.456799259064029, 51.100570443969829],
            [-0.460938674641557, 51.099056666857159],
            [-0.47034519126784, 51.098487666579928],
            [-0.474840097583338, 51.09695826569957],
            [-0.479032721880533, 51.097320433422766],
            [-0.48301430803662, 51.096190513201392],
            [-0.48854354819518, 51.097344848769147],
            [-0.4892355462922, 51.09618203776833],
            [-0.490379724774063, 51.096043040637653],
            [-0.490899255698631, 51.094429254845885],
            [-0.492151181907083, 51.094488572352354],
            [-0.495519834324509, 51.093394386066187],
            [-0.496285677159098, 51.092236980212689],
            [-0.500208368043281, 51.090296372687938],
            [-0.501664998673833, 51.090248503078335],
            [-0.502329705791624, 51.089386522314292],
            [-0.503362104680781, 51.088943810729127],
            [-0.503046298767642, 51.088553079622287],
            [-0.505401567307845, 51.088266668113135],
            [-0.50683283304265, 51.088294848414193],
            [-0.506966164950746, 51.088765972259182],
            [-0.508465677424879, 51.088714968625709],
            [-0.511645219738047, 51.089795048786144],
            [-0.512914122707081, 51.089856159003212],
            [-0.513333455653129, 51.089470307993331],
            [-0.515309840919934, 51.090268797555055],
            [-0.515255201744765, 51.090592741271116],
            [-0.517034999876348, 51.090753817870159],
            [-0.517234688365093, 51.091028830224211],
            [-0.518410066156624, 51.091023946785043],
            [-0.51853729958534, 51.090751280862051],
            [-0.520437734298727, 51.090891352282355],
            [-0.520951662707494, 51.091030047915929],
            [-0.520944647477352, 51.09138427252838],
            [-0.523195053245444, 51.091611458771631],
            [-0.524161791633224, 51.089906955081069],
            [-0.525685817186221, 51.089938772887706],
            [-0.526969965080637, 51.089610537766987],
            [-0.527312099860762, 51.090932281450286],
            [-0.526067339343047, 51.091368030332454],
            [-0.526412618933645, 51.091871478580494],
            [-0.528845386878978, 51.092023516693125],
            [-0.531287988178451, 51.092812311779156],
            [-0.531147940379096, 51.092274586668623],
            [-0.531480103304889, 51.092247284687986],
            [-0.532447464738397, 51.093175788425462],
            [-0.535003613081858, 51.093583740194738],
            [-0.535619623699737, 51.093069880020622],
            [-0.536605854761026, 51.09073423142106],
            [-0.535879051886094, 51.089783595004462],
            [-0.536153458618889, 51.088780746253718],
            [-0.53451228406773, 51.086944556460672],
            [-0.532642968325382, 51.086095578143194],
            [-0.535148968097037, 51.084100943599694],
            [-0.535547817398573, 51.08318058911297],
            [-0.537342097473878, 51.083554666470192],
            [-0.537573318542552, 51.082741919103476],
            [-0.538634885855459, 51.08307983069416],
            [-0.539206107390381, 51.082033022496852],
            [-0.539509721333252, 51.08227512292855],
            [-0.540517371775715, 51.082103355848055],
            [-0.541282158972585, 51.082254086564973],
            [-0.541366167068555, 51.083214657936786],
            [-0.547665306305676, 51.083488221212654],
            [-0.550015462842561, 51.08325660390053],
            [-0.553137490909286, 51.084047972755265],
            [-0.556159440992565, 51.084342523712643],
            [-0.556453588657135, 51.084616836413957],
            [-0.558498323557336, 51.083003594686481],
            [-0.561699956010739, 51.083534028128959],
            [-0.561736477470322, 51.0837835752587],
            [-0.563081109047754, 51.083701189613947],
            [-0.566533565251688, 51.084388347741516],
            [-0.56814827227682, 51.084900034895099],
            [-0.568594368543595, 51.08548102983238],
            [-0.569781053970146, 51.0857527436849],
            [-0.577616183763454, 51.086658569526989],
            [-0.588039091866592, 51.084321814657329],
            [-0.590544984181041, 51.082060716791347],
            [-0.594565183723054, 51.080134321783099],
            [-0.600666490631715, 51.079673344255845],
            [-0.604044698063503, 51.080131889452687],
            [-0.607558839763941, 51.082389589977751],
            [-0.610721271220608, 51.083006339431492],
            [-0.615215956861348, 51.08471970767993],
            [-0.62114625730306, 51.088198910877097],
            [-0.62468613920035, 51.0880481613897],
            [-0.627819996376083, 51.088879033042964],
            [-0.629392893983669, 51.088997300399846],
            [-0.631440901275822, 51.088257815469163],
            [-0.633756603341923, 51.086620360875948],
            [-0.638235384355083, 51.085268861151491],
            [-0.642663289566608, 51.084475941845596],
            [-0.645958068233076, 51.084394543920908],
            [-0.648030638511656, 51.083432936043955],
            [-0.650982891091793, 51.083638796655066],
            [-0.654066799632767, 51.083441430015121],
            [-0.657400316601194, 51.082314313370397],
            [-0.664113011300462, 51.081729348867775],
            [-0.665890670026794, 51.080679525054599],
            [-0.665634220880901, 51.080413114570987],
            [-0.666387849867894, 51.079624067769217],
            [-0.666782907847756, 51.079637566085715],
            [-0.669081369225639, 51.077397612727673],
            [-0.67256357458793, 51.077232170378039],
            [-0.675592149090563, 51.076454484447567],
            [-0.678828473902953, 51.076348119153415],
            [-0.682493096280694, 51.075334640279912],
            [-0.687445479989896, 51.075518939956318],
            [-0.690587289824462, 51.073851869105489],
            [-0.694371408823363, 51.07298863479496],
            [-0.694638130041487, 51.07243766826349],
            [-0.69595294024259, 51.07232914282536],
            [-0.696772797890734, 51.071496576482254],
            [-0.69724785729079, 51.071607084802501],
            [-0.696927410206345, 51.072750970760247],
            [-0.698647418893004, 51.073056094399838],
            [-0.69814883426168, 51.073783441077069],
            [-0.699997121630067, 51.074657406455664],
            [-0.700538105319524, 51.074505150880725],
            [-0.701920743647974, 51.076055548163048],
            [-0.704516355607836, 51.076908959932517],
            [-0.706471366978134, 51.078674271041258],
            [-0.70802531018106, 51.078698647422456],
            [-0.710593749330335, 51.077919467110739],
            [-0.710796420758621, 51.078280506583489],
            [-0.711849525125305, 51.077884749892476],
            [-0.712855791001243, 51.07794169473285],
            [-0.713009350160547, 51.077552207472223],
            [-0.717076697302607, 51.077541156071092],
            [-0.718744155332771, 51.077899361355023],
            [-0.720043810160096, 51.078705843732472],
            [-0.722334284927675, 51.079355881283313],
            [-0.722851164348504, 51.077796819151857],
            [-0.723486987892751, 51.077828042992302],
            [-0.727583870415978, 51.079030946587423],
            [-0.72989530834231, 51.080066845113308],
            [-0.729979140440649, 51.080550657079577],
            [-0.730627583567545, 51.080852655465293],
            [-0.730952420325441, 51.082442471597084],
            [-0.731849038844356, 51.083328975714245],
            [-0.73239518997431, 51.083718880860246],
            [-0.735127198546949, 51.083847386523871],
            [-0.735599192155748, 51.084706785426306],
            [-0.736361824756032, 51.084489316761498],
            [-0.737814575343822, 51.085144379717853],
            [-0.738426185257517, 51.084967532245834],
            [-0.739059802276287, 51.085241446275532],
            [-0.740941073598338, 51.085155607400765],
            [-0.743949699059493, 51.085512597972169],
            [-0.745011734764408, 51.085309979298266],
            [-0.745759414776047, 51.084482593571451],
            [-0.746880620514057, 51.085153769968528],
            [-0.749088613588595, 51.085552402858831],
            [-0.749187856234904, 51.085896080865844],
            [-0.753500292272691, 51.086461004689312],
            [-0.753608178253129, 51.086696860320906],
            [-0.754748351826077, 51.086614587070059],
            [-0.757402919809249, 51.087776788291663],
            [-0.757524332771866, 51.088840997192747],
            [-0.758593915516326, 51.090170664395465],
            [-0.758282186370863, 51.090347205191371],
            [-0.758585056855486, 51.090821630224639],
            [-0.758919804850625, 51.090852161354221],
            [-0.758572479882923, 51.092038187143899],
            [-0.755666356484236, 51.094333666471762],
            [-0.75517667356436, 51.09533921545998],
            [-0.754332392927428, 51.095881466852951],
            [-0.755871704758184, 51.098548853217203],
            [-0.756260303611618, 51.098330871586739],
            [-0.758015194207411, 51.100635424118813],
            [-0.758524899161654, 51.100588679937211],
            [-0.758642179693615, 51.102342567844779],
            [-0.759463238493125, 51.103577860735697],
            [-0.759834214817742, 51.103912720311619],
            [-0.761353324822545, 51.10382990315361],
            [-0.763746551158204, 51.102730264218501],
            [-0.76707196703251, 51.10239578009508],
            [-0.768905927078735, 51.103255002640005],
            [-0.767820332391907, 51.103418922916575],
            [-0.765774393690856, 51.102850602228344],
            [-0.764931417497903, 51.102914541443788],
            [-0.759884231269218, 51.104872752441487],
            [-0.756305395186179, 51.104582055479256],
            [-0.749292348748004, 51.103251003194103],
            [-0.744703216667581, 51.106413062397777],
            [-0.745734186892423, 51.106425923223497],
            [-0.747584481938129, 51.107782043042555],
            [-0.746869315156, 51.108450619334803],
            [-0.745545217777871, 51.112193498004252],
            [-0.748492756750677, 51.112392339099067],
            [-0.75022219907982, 51.113473748940891],
            [-0.753055793245898, 51.113185661285989],
            [-0.754120611208091, 51.113751847764341],
            [-0.756171351526243, 51.114207115646316],
            [-0.758307428577225, 51.115317907729811],
            [-0.760438705578857, 51.115383680713649],
            [-0.761856394327884, 51.114940080733064],
            [-0.764486342800848, 51.115270941549966],
            [-0.76929976384186, 51.117147169853887],
            [-0.769538328918326, 51.118869943653436],
            [-0.770679884810369, 51.121025757370454],
            [-0.772312348314142, 51.121754210294057],
            [-0.773753687669984, 51.123173057503152],
            [-0.774092892182525, 51.123098378827564],
            [-0.777781996063364, 51.126903936260192],
            [-0.779305718390838, 51.127595183419437],
            [-0.78066927730692, 51.127803643817394],
            [-0.780897247795262, 51.128100973155981],
            [-0.782365473284058, 51.128514633564969],
            [-0.78303886957589, 51.129319273671967],
            [-0.782601153714728, 51.130150118571962],
            [-0.78060071760479, 51.131275819616384],
            [-0.780919605693879, 51.132788978149676],
            [-0.780527333594086, 51.134442199526312],
            [-0.781041312079255, 51.135223606122345],
            [-0.78479584409767, 51.136104366343183],
            [-0.786936593509804, 51.137537502644818],
            [-0.790161274404654, 51.13857625564016],
            [-0.791301426654695, 51.139417146027782],
            [-0.79254374862179, 51.143686098480863],
            [-0.793774741629242, 51.144832744604869],
            [-0.793576336017168, 51.145479053080621],
            [-0.794578977829247, 51.148095395746068],
            [-0.794472501972335, 51.149493520363293],
            [-0.795015096779256, 51.149520692472862],
            [-0.796986653216011, 51.150897940803873],
            [-0.799162357475086, 51.151410381555259],
            [-0.798414061210978, 51.152531246760901],
            [-0.802314813007925, 51.152690846398556],
            [-0.803435347210115, 51.153040427749637],
            [-0.803400149288684, 51.154180305938375],
            [-0.804747888973981, 51.155367593276232],
            [-0.805459965587738, 51.155368571598459],
            [-0.805683239138984, 51.15475127404919],
            [-0.806915090735557, 51.154502164247639],
            [-0.806878743030657, 51.153915488319065],
            [-0.808312730798766, 51.154033517116979],
            [-0.80828993545661, 51.15375272168535],
            [-0.809456420352383, 51.153597339640626],
            [-0.810967118251499, 51.152733246101761],
            [-0.811047485208351, 51.152387855084385],
            [-0.813582073121122, 51.151849763041675],
            [-0.812583802674986, 51.151191281870659],
            [-0.815671415757227, 51.150158778680783],
            [-0.816813057555769, 51.149516582606154],
            [-0.81703919753905, 51.148947851436468],
            [-0.818014413499627, 51.148774263777483],
            [-0.817438406558513, 51.148487879167661],
            [-0.817862990999945, 51.148041650576808],
            [-0.817405673354537, 51.147754666581065],
            [-0.82335590173509, 51.146410052592614],
            [-0.824839856315437, 51.147593083435112],
            [-0.825986998985434, 51.145768351528829],
            [-0.828391375689587, 51.145269998009667],
            [-0.83234775412969, 51.147737505366337],
            [-0.833225388005616, 51.147971971398356],
            [-0.834221614489521, 51.147585338446163],
            [-0.833959322712997, 51.149257109492375],
            [-0.830939322878199, 51.155724875176759],
            [-0.831320157868689, 51.159102624919356],
            [-0.830475636337591, 51.161227179771998],
            [-0.831267303908441, 51.16125127718314],
            [-0.830041886631782, 51.16248807152131],
            [-0.830390606994426, 51.162723561678106],
            [-0.829174596468302, 51.167823566873331],
            [-0.827136328222318, 51.174594191870966],
            [-0.827746979180763, 51.17702735073658],
            [-0.827609593917198, 51.17759339237449],
            [-0.826825484550119, 51.17754236993553],
            [-0.826573424168106, 51.178567669480557],
            [-0.82544411448578, 51.181235163126473],
            [-0.827198557088562, 51.184659964076126],
            [-0.828103682081706, 51.18485247622673],
            [-0.828317621265619, 51.186008337166491],
            [-0.830206307035252, 51.188385026414068],
            [-0.83536367119008, 51.191668315210443],
            [-0.837180051171964, 51.194325627920108],
            [-0.838108913808976, 51.194721524062892],
            [-0.838124597649372, 51.195236041036793],
            [-0.839309109277726, 51.19633766538049],
            [-0.842649847876048, 51.199351706379268],
            [-0.843063645789624, 51.199402557894437],
            [-0.843333593519903, 51.1989106486322],
            [-0.843775768236445, 51.198914118806478],
            [-0.845548614334283, 51.200136591222844],
            [-0.845768457813921, 51.200789803363932],
            [-0.843828849559972, 51.201711254089268],
            [-0.845511155611336, 51.20438419077572],
            [-0.847044329149312, 51.2059855479052],
            [-0.84610825856238, 51.206553628834541],
            [-0.84707148334465, 51.208854360747502],
            [-0.848929027340678, 51.210717864327847],
            [-0.846823449772337, 51.212366108222987],
            [-0.843674536740307, 51.213592164130638],
            [-0.842674224229292, 51.213516633509755],
            [-0.84253232920729, 51.213922581500483],
            [-0.840126358421821, 51.215202646997746],
            [-0.839583672579075, 51.216856346776808],
            [-0.836154237472111, 51.219591024545551],
            [-0.834650250896755, 51.221992289634116],
            [-0.833707674501748, 51.22200538231403],
            [-0.83360021784544, 51.22291433151527],
            [-0.832120403785484, 51.224124326104558],
            [-0.828991924012311, 51.225457200773114],
            [-0.828527726838558, 51.228906494006118],
            [-0.828006205429728, 51.233087181412159],
            [-0.826650147898682, 51.23436848519539],
            [-0.823546807940332, 51.233951569220132],
            [-0.823706866611145, 51.234302977440137],
            [-0.820880589120185, 51.234479567410524],
            [-0.820795322888999, 51.239272492704629],
            [-0.816271899821398, 51.23936532179534],
            [-0.813675197736719, 51.240349768235987],
            [-0.813449940895473, 51.241532667627979],
            [-0.807586462700677, 51.242968506976716],
            [-0.806228847760935, 51.244774708278257],
            [-0.804767102029057, 51.245066430314324],
            [-0.8034753010776, 51.241512973011091],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000217",
        LAD13CDO: "43UM",
        LAD13NM: "Woking",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.481175820171545, 51.349359641873534],
            [-0.480702750288809, 51.347728582956755],
            [-0.479675269719151, 51.347752121944048],
            [-0.479227119475216, 51.345240125034415],
            [-0.476789871670297, 51.343265202414315],
            [-0.472842031793204, 51.342166187694964],
            [-0.46609252908902, 51.342852248902489],
            [-0.46551925855055, 51.34131603084348],
            [-0.463674706596142, 51.340617422826107],
            [-0.463746560615109, 51.339670564435124],
            [-0.463185165160666, 51.339414110629818],
            [-0.463592531351788, 51.338609237404462],
            [-0.462683209139228, 51.338067653618566],
            [-0.461953838587755, 51.338026605725986],
            [-0.461907182835467, 51.337533208397701],
            [-0.461191274198481, 51.337090371145251],
            [-0.462092506410178, 51.336800061470335],
            [-0.461002665095017, 51.335091574887798],
            [-0.462510572644152, 51.33395854780737],
            [-0.46285961512173, 51.332793213920979],
            [-0.461956669200167, 51.331335378411246],
            [-0.462365765821458, 51.329962209647796],
            [-0.464681005734404, 51.328889208497017],
            [-0.467528622821693, 51.328548817609871],
            [-0.468266814597185, 51.327200616386627],
            [-0.474000305233818, 51.327001185386806],
            [-0.474511474879967, 51.327081581841568],
            [-0.473970653355314, 51.327462110526795],
            [-0.474637556446409, 51.327996854234954],
            [-0.474644304589222, 51.328701948144619],
            [-0.475151152848112, 51.328696857398278],
            [-0.475310280190976, 51.328269990279061],
            [-0.476044577030632, 51.328461193247357],
            [-0.476699714839035, 51.328269184205261],
            [-0.476824066218965, 51.328588233832065],
            [-0.477301671241726, 51.328512612613842],
            [-0.476836937015238, 51.328979571527178],
            [-0.477087275920313, 51.329265188639901],
            [-0.478430585634483, 51.329618063474513],
            [-0.47891693012697, 51.330276330455],
            [-0.481933730589868, 51.330114936821282],
            [-0.483238290382817, 51.330643505236125],
            [-0.483252944400015, 51.331243488890408],
            [-0.483818392686907, 51.331507992378498],
            [-0.485918531041132, 51.330502829032191],
            [-0.486523243981078, 51.330708477088095],
            [-0.487244578270081, 51.330336517036194],
            [-0.486593499172776, 51.329664466747666],
            [-0.486843461274048, 51.329178508934589],
            [-0.48724841732175, 51.329172948713257],
            [-0.487823915160518, 51.329873694628319],
            [-0.48920181325441, 51.329744898818539],
            [-0.489226506801179, 51.328076226056929],
            [-0.488331753595245, 51.327437910860297],
            [-0.487935283637255, 51.326574917143724],
            [-0.489371087833224, 51.326122241120515],
            [-0.489075986780784, 51.3257119776788],
            [-0.488045609351053, 51.325533221182475],
            [-0.487082388672213, 51.325756384818973],
            [-0.486054861307163, 51.324575892198361],
            [-0.4852502344911, 51.324211187818541],
            [-0.483463043043473, 51.32448301276073],
            [-0.483236069891524, 51.323662662575586],
            [-0.482520702667008, 51.32333416551738],
            [-0.482415259669842, 51.322354423553193],
            [-0.481095286844103, 51.322256381508332],
            [-0.481110770651457, 51.323091079449092],
            [-0.480134394545832, 51.322583830284216],
            [-0.478469096649005, 51.322555914220374],
            [-0.479089182656493, 51.322075678998601],
            [-0.478126799307508, 51.322100946363229],
            [-0.477344589436053, 51.32119243619173],
            [-0.476287254270419, 51.321008728714489],
            [-0.476338200954494, 51.320423984082453],
            [-0.474800349592534, 51.318892339508366],
            [-0.475341822588204, 51.316454345446338],
            [-0.477970428806079, 51.315551522878209],
            [-0.481553068574376, 51.315042293954392],
            [-0.48248808933529, 51.315406916258901],
            [-0.481996226396988, 51.316353740279503],
            [-0.483395889001984, 51.316162349382786],
            [-0.484049450111549, 51.316403714632422],
            [-0.484716110954499, 51.316160553596703],
            [-0.487471855462845, 51.316447063433834],
            [-0.488231463298031, 51.316125949396898],
            [-0.487905515832144, 51.315739563399923],
            [-0.488812900216505, 51.315025585756743],
            [-0.490899214068297, 51.314940081507629],
            [-0.491445729794914, 51.314639581143787],
            [-0.491089923205443, 51.313456989354094],
            [-0.491967592176374, 51.312070869979578],
            [-0.491899090453698, 51.311052943807006],
            [-0.493939696368022, 51.30968447673088],
            [-0.498382983116641, 51.308458312113373],
            [-0.49990652614876, 51.306878083477812],
            [-0.500869291548798, 51.306566684095074],
            [-0.503184962074953, 51.307091786572514],
            [-0.50201390894965, 51.308865407493826],
            [-0.503465622432643, 51.310416276296785],
            [-0.504770056114243, 51.309968029180951],
            [-0.505237341478849, 51.310114275955044],
            [-0.5048666072649, 51.311279457726783],
            [-0.507442807610985, 51.311124376149834],
            [-0.507639320747635, 51.311347194651397],
            [-0.506716116572296, 51.311808428304793],
            [-0.50791864985312, 51.31233452416344],
            [-0.509838846231099, 51.311702525437028],
            [-0.509733786337226, 51.313041058512198],
            [-0.510319419947283, 51.313123145949454],
            [-0.510833806310033, 51.312569460161299],
            [-0.512930423878315, 51.311982817558039],
            [-0.513036103296719, 51.311554321998187],
            [-0.51255021792431, 51.311138997151289],
            [-0.513385385721769, 51.310513837604304],
            [-0.512673921247158, 51.310552464260056],
            [-0.512400733156549, 51.309949200422103],
            [-0.51365448420039, 51.309467838245851],
            [-0.513061545028095, 51.308902781716782],
            [-0.511387965305738, 51.309323048991985],
            [-0.510760691886535, 51.309199072025265],
            [-0.510279798348992, 51.307168762038096],
            [-0.51078788010004, 51.30614289382077],
            [-0.511657257068518, 51.305966004081775],
            [-0.512255523284788, 51.305344136170767],
            [-0.51615308951614, 51.304596856289571],
            [-0.515802068317592, 51.303782190970757],
            [-0.516785667488031, 51.303175074656991],
            [-0.518653783323208, 51.302986498670244],
            [-0.518645337157696, 51.303739057415619],
            [-0.520105981822317, 51.303463472267971],
            [-0.521028510893038, 51.304352786388222],
            [-0.521380732127769, 51.303213398514686],
            [-0.522463030869962, 51.302254978165394],
            [-0.522926288461852, 51.30310700855933],
            [-0.523510872659179, 51.303039743248327],
            [-0.523797159173061, 51.302656678414436],
            [-0.525362966197013, 51.302768128104539],
            [-0.526100484532752, 51.302270240368173],
            [-0.525880653688536, 51.30143027949191],
            [-0.527225596488297, 51.300418469019235],
            [-0.525875089835547, 51.299633524324527],
            [-0.528246892298905, 51.29858066913625],
            [-0.529350284690443, 51.298525289448598],
            [-0.529322301237098, 51.29787478616867],
            [-0.529889213014794, 51.297550084169941],
            [-0.531200106133481, 51.297553944125056],
            [-0.533699774325874, 51.299143655154971],
            [-0.534518709709343, 51.298787015801182],
            [-0.53531491219723, 51.299054159848723],
            [-0.53558570711561, 51.299379474775527],
            [-0.534966318542945, 51.299911272404373],
            [-0.535416830738159, 51.300494220259942],
            [-0.536218988503055, 51.30030461802891],
            [-0.537979211158534, 51.301248328311409],
            [-0.539516029661497, 51.301278295213898],
            [-0.540316517879099, 51.300915090707392],
            [-0.539840770785036, 51.300718520858901],
            [-0.539513230760083, 51.299648836294345],
            [-0.542279858385985, 51.299072722852586],
            [-0.542270108984097, 51.298701215120126],
            [-0.541457349204172, 51.298819686572955],
            [-0.541442374195576, 51.298115394556092],
            [-0.542251423284075, 51.297751384329672],
            [-0.542478847562307, 51.296503371095781],
            [-0.543168031339795, 51.296617158052911],
            [-0.545018370470271, 51.296116804316661],
            [-0.545073574662496, 51.295640892569637],
            [-0.54622952915046, 51.294599539996305],
            [-0.545313746959522, 51.293396673065971],
            [-0.544391780372336, 51.289487903693335],
            [-0.545873058316932, 51.28881209299179],
            [-0.54503755789024, 51.287704639566428],
            [-0.546238236309114, 51.287829259734721],
            [-0.547299396878007, 51.287239937267294],
            [-0.545980901403491, 51.286577916751988],
            [-0.546852140037906, 51.286007821647274],
            [-0.547791385993991, 51.285917859559298],
            [-0.549211870953479, 51.284663040343574],
            [-0.548497658391813, 51.284029215788827],
            [-0.547789866441269, 51.284465564692503],
            [-0.547311136088367, 51.284367903728551],
            [-0.547708049574987, 51.28374065992309],
            [-0.547374959671816, 51.283071988027245],
            [-0.546929809450814, 51.283046681398496],
            [-0.546527624448277, 51.283522784487111],
            [-0.545519047962918, 51.282858309789717],
            [-0.546320538000068, 51.2819519346438],
            [-0.546088308656633, 51.281314185900271],
            [-0.544060311771368, 51.281012919134405],
            [-0.543589596774656, 51.28070851644874],
            [-0.543443643940889, 51.279882994166726],
            [-0.541929241290519, 51.279258055552845],
            [-0.54177034038844, 51.277438705323],
            [-0.539562941865417, 51.276516445199761],
            [-0.537675010027367, 51.276778841066445],
            [-0.536551024348875, 51.276596639166335],
            [-0.53705066330253, 51.275807051808975],
            [-0.535931262369341, 51.275075462665477],
            [-0.536068273918765, 51.274328105036943],
            [-0.535564230429527, 51.274309212323175],
            [-0.53514957024804, 51.27481569544836],
            [-0.534209743115402, 51.273848935361535],
            [-0.537692415673935, 51.273159599454864],
            [-0.538337438589024, 51.271942882337363],
            [-0.538109579811906, 51.271488616554095],
            [-0.539225293764222, 51.270119503459114],
            [-0.537857266315823, 51.26918519683062],
            [-0.536064765182577, 51.268844464539036],
            [-0.537467349344746, 51.268417766615883],
            [-0.541526457470561, 51.266303918944907],
            [-0.544391196126975, 51.265506870095614],
            [-0.547731297967803, 51.265677841254487],
            [-0.550752783447323, 51.266956239672346],
            [-0.551529102669967, 51.267795849332217],
            [-0.550189599371758, 51.26809040816854],
            [-0.550121101713139, 51.268353937773362],
            [-0.550996762130757, 51.26886116129964],
            [-0.553319370122959, 51.268392601448291],
            [-0.553543727384596, 51.269145342986782],
            [-0.554374251833074, 51.269493716396298],
            [-0.5543849740293, 51.270522583636236],
            [-0.554995832072142, 51.270882627870698],
            [-0.558245633580806, 51.271250015902076],
            [-0.557724323909535, 51.270821850187886],
            [-0.558254077622429, 51.270064017936392],
            [-0.559818280389531, 51.270285598964016],
            [-0.559704179989922, 51.269533327228714],
            [-0.561522077005504, 51.270214821671573],
            [-0.563750905078713, 51.270591989657689],
            [-0.566022750025072, 51.272167432926189],
            [-0.568191210514379, 51.273153462474092],
            [-0.582223969147606, 51.276999422319278],
            [-0.589971033623115, 51.281989330135026],
            [-0.594482593661461, 51.28394366185664],
            [-0.601539607198848, 51.286237582327828],
            [-0.601906904735818, 51.2859479176268],
            [-0.602373256453147, 51.286221457459497],
            [-0.60355813751897, 51.285641197796046],
            [-0.60359683860779, 51.285310739970981],
            [-0.602833039427926, 51.285010276110029],
            [-0.603243719620185, 51.284755295880011],
            [-0.603162173632643, 51.28399266894516],
            [-0.604985991882406, 51.284187064649082],
            [-0.605627270586499, 51.28379813900527],
            [-0.60701515262741, 51.283756207184908],
            [-0.607222233879321, 51.283973588173062],
            [-0.60908202129731, 51.283642294082412],
            [-0.611669130190417, 51.282157781873003],
            [-0.612561608564773, 51.282756461154904],
            [-0.61384149039364, 51.282728455845032],
            [-0.614930871759728, 51.283313261312365],
            [-0.61541643472351, 51.282806438849782],
            [-0.61646872949923, 51.283337736414104],
            [-0.618190986716802, 51.282712421011666],
            [-0.618621303711834, 51.283042122636466],
            [-0.622245465245275, 51.28584727360478],
            [-0.624917392382943, 51.28722663429069],
            [-0.633543404737321, 51.294368613048981],
            [-0.640827307271157, 51.299735470512474],
            [-0.641165607274139, 51.299597317546613],
            [-0.642063740719748, 51.300028575624403],
            [-0.647908134314602, 51.304356754015039],
            [-0.648646523169058, 51.307204147597297],
            [-0.649979331551899, 51.308572859850628],
            [-0.646809667818994, 51.311903898278011],
            [-0.638473362506832, 51.313991518888066],
            [-0.637001250515718, 51.314183018512743],
            [-0.63715207784945, 51.314468031178762],
            [-0.634522276324842, 51.315278182461135],
            [-0.633033072944073, 51.314401147128855],
            [-0.628008799440452, 51.316446601769947],
            [-0.627338017447999, 51.318776742394931],
            [-0.624355534173682, 51.319225538252958],
            [-0.623524335233979, 51.319907281624474],
            [-0.624396104736951, 51.320699853715034],
            [-0.625568955946407, 51.320782862713912],
            [-0.626930612737881, 51.324395752738681],
            [-0.624932215964523, 51.327583459424197],
            [-0.625432109594745, 51.328529920472626],
            [-0.625233904467835, 51.329110295013706],
            [-0.626244368399966, 51.330311829688462],
            [-0.625612413693574, 51.333541640350305],
            [-0.626041623702566, 51.3347903081889],
            [-0.624698483346458, 51.334821306972763],
            [-0.623859837967481, 51.333632569393828],
            [-0.622498873367753, 51.334451057783106],
            [-0.620418171103694, 51.333181140110185],
            [-0.616726496361733, 51.332119677517589],
            [-0.614020926186267, 51.332064317311328],
            [-0.613478357467793, 51.33215861027319],
            [-0.61339998691698, 51.33256773137952],
            [-0.615264883784928, 51.335943017251232],
            [-0.608164372326243, 51.339695880499143],
            [-0.599081194865347, 51.339637068449925],
            [-0.594034914982116, 51.339961483818385],
            [-0.589819770760022, 51.339866756878244],
            [-0.588050777593095, 51.338425563416877],
            [-0.583176113096947, 51.336770572041388],
            [-0.578257869272797, 51.336071629915338],
            [-0.576386644740474, 51.336464349590216],
            [-0.573827330950594, 51.338211900475265],
            [-0.574296604069684, 51.340994440960145],
            [-0.573504916071307, 51.341291441360561],
            [-0.573802341496481, 51.341515374476131],
            [-0.572834711758966, 51.34229941033405],
            [-0.56972562418248, 51.342828008644204],
            [-0.568809606255813, 51.343334778966522],
            [-0.567464675046333, 51.34318345568721],
            [-0.566238510556052, 51.343551528236716],
            [-0.563972246151129, 51.343064262378185],
            [-0.561955036107186, 51.343245441998086],
            [-0.561597156719192, 51.343807564818576],
            [-0.56083424196909, 51.344098538645405],
            [-0.559920771935174, 51.343832830431467],
            [-0.557987505415148, 51.344951974182379],
            [-0.557503629464171, 51.34476886828773],
            [-0.553546164127832, 51.345142711735996],
            [-0.551734928859783, 51.346073521439337],
            [-0.550013263574614, 51.347667247391037],
            [-0.549436848137911, 51.347822872886432],
            [-0.548976438011331, 51.347578874527464],
            [-0.548569004890749, 51.348021645471398],
            [-0.546192986392763, 51.347598320213166],
            [-0.544922358383119, 51.347864904656696],
            [-0.543724683255472, 51.347143225455923],
            [-0.542221363845666, 51.347457244962051],
            [-0.541970149569052, 51.347183449386392],
            [-0.541183878059884, 51.347254592112755],
            [-0.537809705840752, 51.346434689576],
            [-0.5358510061781, 51.346889517995848],
            [-0.535880838852031, 51.347395260457183],
            [-0.535020255911018, 51.347364718877117],
            [-0.534461570967698, 51.347683253991377],
            [-0.534068734750234, 51.347484104445407],
            [-0.533464979315961, 51.346560228145947],
            [-0.530372098169319, 51.345570978242463],
            [-0.524218590775066, 51.345059290732024],
            [-0.523160359411233, 51.343808606549608],
            [-0.520794495848158, 51.344867731722658],
            [-0.520222823701648, 51.343179839822817],
            [-0.518864067167119, 51.343774149013491],
            [-0.517924915129047, 51.342576178457151],
            [-0.519461364992323, 51.341635222328101],
            [-0.518301016555412, 51.340752788218374],
            [-0.51781553463029, 51.341026309218364],
            [-0.516100509974527, 51.33931583015201],
            [-0.515645780441496, 51.338859551210724],
            [-0.511720886515171, 51.339872650399975],
            [-0.49312534575634, 51.345444902280121],
            [-0.486431264802283, 51.347363906990147],
            [-0.481924593722838, 51.349558187231587],
            [-0.481175820171545, 51.349359641873534],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000218",
        LAD13CDO: "44UB",
        LAD13NM: "North Warwickshire",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.577614525408376, 52.67856140334176],
            [-1.576869695094272, 52.678342995387894],
            [-1.570315801528305, 52.679709245177563],
            [-1.569916965677437, 52.678751293488048],
            [-1.569112374241338, 52.679650042299009],
            [-1.566480739843944, 52.677584503689609],
            [-1.569164484261763, 52.676757339096667],
            [-1.570433744593398, 52.675826112419074],
            [-1.566046298298986, 52.674407743731727],
            [-1.560027082209747, 52.671303887603074],
            [-1.554797620211555, 52.669437880496837],
            [-1.552812873234826, 52.668445137827078],
            [-1.551777697938005, 52.667437974736593],
            [-1.55218855304893, 52.666881263706145],
            [-1.546105012483276, 52.659045142658329],
            [-1.547295993420781, 52.655956318735726],
            [-1.542428701049652, 52.65360928785914],
            [-1.544667534699585, 52.651542168339475],
            [-1.544964642435481, 52.650545444294288],
            [-1.544299036908065, 52.649449731810279],
            [-1.542801215602466, 52.648221356590042],
            [-1.542810618040984, 52.647602896237061],
            [-1.544294033871341, 52.645663218797765],
            [-1.544830323839451, 52.644117238583775],
            [-1.546999958441602, 52.642252976078026],
            [-1.550638336793195, 52.64111073502324],
            [-1.553313794548953, 52.640762152369675],
            [-1.556053866849819, 52.63825889984345],
            [-1.559021027306554, 52.637241541272196],
            [-1.565702602162252, 52.636253055236516],
            [-1.57100740018101, 52.636102461075446],
            [-1.571367268221765, 52.635113088218077],
            [-1.571026273929889, 52.634020494742757],
            [-1.566517097064487, 52.632332891866575],
            [-1.56461764514404, 52.631224681986303],
            [-1.565888499732152, 52.630306989229915],
            [-1.566490063638245, 52.629235808750131],
            [-1.566018746553815, 52.62595011056294],
            [-1.567590866855118, 52.622624233759197],
            [-1.566936909119993, 52.620632401995998],
            [-1.564500387461521, 52.619960926889632],
            [-1.563472435054168, 52.619949055176015],
            [-1.563073506309877, 52.620256835017138],
            [-1.561196383944371, 52.619783330230156],
            [-1.560667021781965, 52.617034081985267],
            [-1.556465954047531, 52.615176423366528],
            [-1.554467852252186, 52.614718540058639],
            [-1.554283999267295, 52.613479051153647],
            [-1.55708093967292, 52.609261619506441],
            [-1.557076604160608, 52.601343355446211],
            [-1.560765219820328, 52.596138477505562],
            [-1.558041463483285, 52.596134644540697],
            [-1.55700277068549, 52.595632728861617],
            [-1.557090532649921, 52.59483475804825],
            [-1.556283378141919, 52.594067601301781],
            [-1.553704772228757, 52.593593143370498],
            [-1.553371762428706, 52.59382292812662],
            [-1.552683302509157, 52.593730433031261],
            [-1.550780594245813, 52.592993259754195],
            [-1.550315998127067, 52.593097578093776],
            [-1.549994563789649, 52.592627986416836],
            [-1.549256663591077, 52.592896675890366],
            [-1.547515848634863, 52.591929026162155],
            [-1.546596094056837, 52.591366341528719],
            [-1.546160069466748, 52.590562776875444],
            [-1.544166370187415, 52.589656138611971],
            [-1.542635220053394, 52.589414704692253],
            [-1.537477842466527, 52.589548414570977],
            [-1.5375407986112, 52.589036237852277],
            [-1.536107920979578, 52.588020176201354],
            [-1.535317765578661, 52.587925383753301],
            [-1.534741328529095, 52.587162578932968],
            [-1.531682085993566, 52.585727431376867],
            [-1.531396066114013, 52.584728424517991],
            [-1.53215801982662, 52.584415890573936],
            [-1.531273321116234, 52.583366871675793],
            [-1.528927410586879, 52.58329733677548],
            [-1.527909630758523, 52.58269366133127],
            [-1.527164033603463, 52.582720355111206],
            [-1.527325780900847, 52.58236410216292],
            [-1.525615525889523, 52.581246118202003],
            [-1.525204508760964, 52.580358069438418],
            [-1.525729463575878, 52.580123737779935],
            [-1.525374833589758, 52.579617985261841],
            [-1.526330772268955, 52.579239740319593],
            [-1.525927566828393, 52.578996299881538],
            [-1.525928881408841, 52.577642428080154],
            [-1.525317995778914, 52.576680761176142],
            [-1.526686657133612, 52.57661341820905],
            [-1.526709684370314, 52.575296491181831],
            [-1.525996978171882, 52.574062925818723],
            [-1.524756116858392, 52.573781066489154],
            [-1.524604623392466, 52.574008802077515],
            [-1.522998509262011, 52.573528579536593],
            [-1.521522449579903, 52.571811850429889],
            [-1.521588623272444, 52.571289804498441],
            [-1.522877006086386, 52.570582999714681],
            [-1.502714854387658, 52.564453475560121],
            [-1.483043997675528, 52.558666062503299],
            [-1.464057277694009, 52.5530762425416],
            [-1.46000360563633, 52.551572692460248],
            [-1.462001790531426, 52.550502990692344],
            [-1.46462126112023, 52.550239766733995],
            [-1.473140539441304, 52.547447929499121],
            [-1.479970421914603, 52.5438992114507],
            [-1.480932061392134, 52.543755095318041],
            [-1.482508956045257, 52.542952000288516],
            [-1.485207709472517, 52.540903262381377],
            [-1.486332866271296, 52.540891066433822],
            [-1.486661825228347, 52.540371974022698],
            [-1.487718496450592, 52.541302514660828],
            [-1.492703734614822, 52.540403399943607],
            [-1.495617859537236, 52.541250113212726],
            [-1.49706051503563, 52.542357517049389],
            [-1.498508894999627, 52.541285762943858],
            [-1.498740029794462, 52.539762944079563],
            [-1.501722772245314, 52.538721906941348],
            [-1.503831665137222, 52.538675921370029],
            [-1.511986419048971, 52.537485369952037],
            [-1.518338069263989, 52.537335166941119],
            [-1.524063054747127, 52.536096120616712],
            [-1.527519475200447, 52.535759350960753],
            [-1.532013845977652, 52.534301943166881],
            [-1.533671390479698, 52.534393885379721],
            [-1.535229456561106, 52.535112013606273],
            [-1.539297813575281, 52.535593587232178],
            [-1.540779607034181, 52.535297279311735],
            [-1.541255524581784, 52.534049520663466],
            [-1.542446050854369, 52.534679826172578],
            [-1.544944551732228, 52.533857881425554],
            [-1.549891072255208, 52.534385613433926],
            [-1.552556201351196, 52.533979476858136],
            [-1.553931022097757, 52.533459649627474],
            [-1.554333244276096, 52.532754553911552],
            [-1.555897003542434, 52.53205831262779],
            [-1.556196262828987, 52.531618926859515],
            [-1.554447599839871, 52.528007391008138],
            [-1.551687714338903, 52.526753665452844],
            [-1.554890162861539, 52.524908418942061],
            [-1.55601222481867, 52.522088883886532],
            [-1.556089698920767, 52.521713393833103],
            [-1.553070203489706, 52.519644234239529],
            [-1.553900358738974, 52.518582052948936],
            [-1.554669163177522, 52.517462099207648],
            [-1.555231554923006, 52.517370716996211],
            [-1.553398863768656, 52.513389352283255],
            [-1.551055895078886, 52.512815023463773],
            [-1.549520545386923, 52.510790942150493],
            [-1.547585600462968, 52.509959184027373],
            [-1.54650823492648, 52.509844486211499],
            [-1.546087259966029, 52.508086229974715],
            [-1.542991417382545, 52.508400658909935],
            [-1.541403874302228, 52.508186852554921],
            [-1.542298254906822, 52.506053392271788],
            [-1.536284387737191, 52.503801373890539],
            [-1.532522932059328, 52.501331414214008],
            [-1.531940159036854, 52.500582042667865],
            [-1.531666901975551, 52.497876768425648],
            [-1.530437284349488, 52.497151798597457],
            [-1.529419324124312, 52.495920620640817],
            [-1.524373074024476, 52.493726670832736],
            [-1.521284987429994, 52.493404968190099],
            [-1.523309514526219, 52.488287925735882],
            [-1.517338168192664, 52.486227490017832],
            [-1.51582714971742, 52.48529714466131],
            [-1.514534508730392, 52.483529804245578],
            [-1.514418098835344, 52.480593180532118],
            [-1.513781468467255, 52.479839899014493],
            [-1.513816686386949, 52.477846052192703],
            [-1.514831014271977, 52.476249985788698],
            [-1.521773118054987, 52.476093939160727],
            [-1.531865545384256, 52.474579903151273],
            [-1.538047910674443, 52.474258074298241],
            [-1.541042787621228, 52.473798645553181],
            [-1.545184292351742, 52.472639631895611],
            [-1.54010595366794, 52.472292773467565],
            [-1.538886235335112, 52.47166142231962],
            [-1.537371710860987, 52.471350750322088],
            [-1.537245268776349, 52.471054483557147],
            [-1.536615140787606, 52.471340598995397],
            [-1.535576084557131, 52.471314950775515],
            [-1.535588529681717, 52.470971579802885],
            [-1.533489068359959, 52.470992092416893],
            [-1.532491260269236, 52.470547643370438],
            [-1.531830180507438, 52.470973855894805],
            [-1.531338942140587, 52.470931456751387],
            [-1.53034549760219, 52.470355752112219],
            [-1.530821459347634, 52.469618657476893],
            [-1.533789985645227, 52.468425719731314],
            [-1.53688580660161, 52.465887394590965],
            [-1.539800013410851, 52.46478219594276],
            [-1.545270208805351, 52.461440157404063],
            [-1.547570624658067, 52.459573646490014],
            [-1.5492395882284, 52.459284241284401],
            [-1.551430362977696, 52.457027967715028],
            [-1.554004723837536, 52.456839015955254],
            [-1.554298782271211, 52.456428378757984],
            [-1.558531940003756, 52.456649217221432],
            [-1.561201116032695, 52.455206348835318],
            [-1.5629683574964, 52.45596445667384],
            [-1.568429950144324, 52.456072599663088],
            [-1.57405297839606, 52.457908061359063],
            [-1.577530063381526, 52.458422168038226],
            [-1.578713105004306, 52.458135106943125],
            [-1.585653785015249, 52.460154498909276],
            [-1.589871857840976, 52.461896176840483],
            [-1.589994167086058, 52.46108749649418],
            [-1.59306589097088, 52.461555689618628],
            [-1.594013280568447, 52.461444769190742],
            [-1.594929923684859, 52.460673866708333],
            [-1.596525220767453, 52.458430005597826],
            [-1.595751580691554, 52.457574210378446],
            [-1.595659173019148, 52.456575999179762],
            [-1.594618341217332, 52.455976398665953],
            [-1.595496089481402, 52.45590388512251],
            [-1.596703750776263, 52.455640102502819],
            [-1.59855529322131, 52.454315868850635],
            [-1.60016812946017, 52.454803194796128],
            [-1.602356255460215, 52.456450351172727],
            [-1.603836105811705, 52.456682761570349],
            [-1.606023920323775, 52.458225563691784],
            [-1.610722789653933, 52.458587226521303],
            [-1.612209411826711, 52.461078751549373],
            [-1.614220892427727, 52.462984019908518],
            [-1.616610136303695, 52.463443081854606],
            [-1.618326351968679, 52.464121981224253],
            [-1.621736896108493, 52.463413725583735],
            [-1.624460387534433, 52.463523085113799],
            [-1.626888188561797, 52.462221813197843],
            [-1.626659141867496, 52.461285228454813],
            [-1.625792629682936, 52.460546208512348],
            [-1.625951917506567, 52.459289904683857],
            [-1.624670249180434, 52.457014064604451],
            [-1.626927563881683, 52.456166247407424],
            [-1.629723233038245, 52.453757602576836],
            [-1.633163159360207, 52.45205031520787],
            [-1.633652374446044, 52.450725798152561],
            [-1.634607525201973, 52.450169569386354],
            [-1.634965695925165, 52.448670234940728],
            [-1.63740096458881, 52.447576438674403],
            [-1.638309259998195, 52.445360472104319],
            [-1.641405101005477, 52.443328249761564],
            [-1.643265488750863, 52.44304708859918],
            [-1.645259340168062, 52.442081255746594],
            [-1.647004121387984, 52.442081977140859],
            [-1.64822897683113, 52.441604655568554],
            [-1.650710597171823, 52.441255998578114],
            [-1.651144195176233, 52.440931837178894],
            [-1.652801342238613, 52.440658916958697],
            [-1.654683445921926, 52.440963791880129],
            [-1.654876441294737, 52.440739603293721],
            [-1.65832705533791, 52.441959676126835],
            [-1.662294635262247, 52.444284203390751],
            [-1.664896306859351, 52.442659902755764],
            [-1.664551488248425, 52.440020344525372],
            [-1.666593937653831, 52.435456467105929],
            [-1.67075128450663, 52.436772562947816],
            [-1.677161997163562, 52.436341617875904],
            [-1.676603447786151, 52.437893575471783],
            [-1.678152497583719, 52.438691619734996],
            [-1.682040541626928, 52.439701821883283],
            [-1.683557241676465, 52.440520385155573],
            [-1.684425463187617, 52.44151250776541],
            [-1.684216786960994, 52.442510745902553],
            [-1.684704617364152, 52.442800626878402],
            [-1.685353673607934, 52.445036380743211],
            [-1.6864863378038, 52.446308780284888],
            [-1.690030474829233, 52.448645635203903],
            [-1.697455778800064, 52.454765480994993],
            [-1.700415003866086, 52.458688162013914],
            [-1.704135583494328, 52.460649251157449],
            [-1.706612987247991, 52.462581077350414],
            [-1.708524375228353, 52.464444932733834],
            [-1.708484381914582, 52.465598252627274],
            [-1.70906726858779, 52.466268542918627],
            [-1.709865577331049, 52.466415240833634],
            [-1.710824831859908, 52.471656961679535],
            [-1.710719332763337, 52.473828687583925],
            [-1.711419963665509, 52.473850175072954],
            [-1.711263005200211, 52.474889035966946],
            [-1.711664557463115, 52.474945752350109],
            [-1.712690964262964, 52.47649092993835],
            [-1.715280189070735, 52.478402162435387],
            [-1.714808962648261, 52.478641061249753],
            [-1.719036320849987, 52.480462648371308],
            [-1.722534221794499, 52.482665349985062],
            [-1.729995853501352, 52.48884971868884],
            [-1.733087487278294, 52.491946577757233],
            [-1.733220381044776, 52.492133868411798],
            [-1.743249539427165, 52.506942703858883],
            [-1.744501338613773, 52.508303789928213],
            [-1.74725308146278, 52.510189488023464],
            [-1.753530630736764, 52.512951834703429],
            [-1.75394134680619, 52.513814822494773],
            [-1.753264867311147, 52.514100194346213],
            [-1.754098476031765, 52.515761467678402],
            [-1.754929836418372, 52.515717341559785],
            [-1.757084500057021, 52.517811935586799],
            [-1.75704653666483, 52.518282929582078],
            [-1.754326582952007, 52.518460715564373],
            [-1.753817610421096, 52.521197988261953],
            [-1.751348056887618, 52.520583315181604],
            [-1.748769470520219, 52.520752280195083],
            [-1.746911923255215, 52.520084870997941],
            [-1.74583036233267, 52.520054687043434],
            [-1.74387579618444, 52.520625832835165],
            [-1.74164398658888, 52.520714482481772],
            [-1.738767789384561, 52.521606268776267],
            [-1.738137003891717, 52.52210201879214],
            [-1.736332211012973, 52.522488178613123],
            [-1.734833651069563, 52.523413492682089],
            [-1.733792774389779, 52.523467795073742],
            [-1.731844781037187, 52.522907825382653],
            [-1.730512458118438, 52.523143035276121],
            [-1.72964341077078, 52.524258502064434],
            [-1.728858110447872, 52.524451788694748],
            [-1.730426051207351, 52.525944995327201],
            [-1.730828922561404, 52.525865901029157],
            [-1.731578300290592, 52.527701543087574],
            [-1.736923768192373, 52.529063816408112],
            [-1.738830458580596, 52.528690458269075],
            [-1.739762557646357, 52.529104246818974],
            [-1.738677858907947, 52.529069495902689],
            [-1.739429130995271, 52.531895778421635],
            [-1.742354435863179, 52.531289962726078],
            [-1.743534506398118, 52.532677866525567],
            [-1.746860790724825, 52.531299675202817],
            [-1.749093485149428, 52.531406908616617],
            [-1.750419032027943, 52.533956546351718],
            [-1.750921973444129, 52.536262613746658],
            [-1.748368582943432, 52.536505340459478],
            [-1.749000589721873, 52.541246150824108],
            [-1.74643537792997, 52.541697376221499],
            [-1.752113409010317, 52.543825607544825],
            [-1.753584206879283, 52.545286833400191],
            [-1.756005692531865, 52.555366792161081],
            [-1.756407074929159, 52.555863858185454],
            [-1.757820298357096, 52.555555704844593],
            [-1.759959909467053, 52.555652652734587],
            [-1.763431244558286, 52.556603567145679],
            [-1.76534693081565, 52.556661314102435],
            [-1.766300723771496, 52.55787053645868],
            [-1.767738440137903, 52.558790327652396],
            [-1.766926038298184, 52.559838748232238],
            [-1.766921126259587, 52.561602547861234],
            [-1.77041227588657, 52.563681521198362],
            [-1.774237157359889, 52.564612124345274],
            [-1.77391480278342, 52.565620170723037],
            [-1.771259901317136, 52.56749215729792],
            [-1.769157051565596, 52.568147935693034],
            [-1.767909362139591, 52.568059197122736],
            [-1.766799385993087, 52.568711479364119],
            [-1.76511843254896, 52.56908034025269],
            [-1.763570431989909, 52.570236952520034],
            [-1.763553250858722, 52.570963297256284],
            [-1.765914269327707, 52.570909548772015],
            [-1.767023691441943, 52.571211095775951],
            [-1.768206589916808, 52.572972725383678],
            [-1.767730844564763, 52.573370942929536],
            [-1.7681406828674, 52.574279718336122],
            [-1.769018599728579, 52.574288623870039],
            [-1.769695287596192, 52.574966874650663],
            [-1.77093691875212, 52.575123905151081],
            [-1.771939679261779, 52.574679043677797],
            [-1.77338033219913, 52.574871491576673],
            [-1.774178632397127, 52.574582643228844],
            [-1.776656279212176, 52.574553175087694],
            [-1.778006387535421, 52.575147241508674],
            [-1.777954772095238, 52.575726089869299],
            [-1.778839777288681, 52.575798763626764],
            [-1.779158517924065, 52.576385494545391],
            [-1.778691315993024, 52.576564419967632],
            [-1.77950263905348, 52.578682136557319],
            [-1.780275108323428, 52.578843589429781],
            [-1.781366035880906, 52.580254311234391],
            [-1.783615076584238, 52.581161014840944],
            [-1.78385658081014, 52.581568694132727],
            [-1.7848431306771, 52.581726012210481],
            [-1.78828433130189, 52.584571190196542],
            [-1.78846797669872, 52.586895384355579],
            [-1.788088491828848, 52.587846727659063],
            [-1.783930321198972, 52.589091504363424],
            [-1.781027992985976, 52.591243734064712],
            [-1.776324559896638, 52.592204056504173],
            [-1.774044355271191, 52.592110734464988],
            [-1.773090449355778, 52.591024742404656],
            [-1.770706222533911, 52.589378617065996],
            [-1.768478813668068, 52.58910009970473],
            [-1.762655414826432, 52.590526056708399],
            [-1.755755724700146, 52.591648356607813],
            [-1.751053160869099, 52.593392487864904],
            [-1.745053421851101, 52.593718654659895],
            [-1.74241490604111, 52.594308976050279],
            [-1.741285605482297, 52.594304719297888],
            [-1.73918365278217, 52.593521596745362],
            [-1.740369398095643, 52.590213251896039],
            [-1.739008043402978, 52.58928341304668],
            [-1.737544118714393, 52.588979022538261],
            [-1.733539034612423, 52.589065380666135],
            [-1.733177549260338, 52.588553946062312],
            [-1.730058791046102, 52.588291575041943],
            [-1.729317173253754, 52.587916806617251],
            [-1.727516928815142, 52.588310931553643],
            [-1.724305835373751, 52.588423072190984],
            [-1.723239514465194, 52.587817370726178],
            [-1.71759139431911, 52.589098584409349],
            [-1.71755733403932, 52.5886562041334],
            [-1.715679607649249, 52.58891331933458],
            [-1.713885446057825, 52.58880112487266],
            [-1.707670002559066, 52.587432992449536],
            [-1.705444056420004, 52.587671106559796],
            [-1.704792873139258, 52.587489690221233],
            [-1.703949217430782, 52.587616140749262],
            [-1.704204632975649, 52.588484296349634],
            [-1.703301903249654, 52.588612392812621],
            [-1.704264698861005, 52.589209924893765],
            [-1.703116752137088, 52.590238188044232],
            [-1.704244073171191, 52.590081886106752],
            [-1.704110500285693, 52.590628133857287],
            [-1.700399186995382, 52.590895698777913],
            [-1.699258446743016, 52.590203290996847],
            [-1.69877579321766, 52.590844837750652],
            [-1.697808062983652, 52.590970929205724],
            [-1.694632050160899, 52.590243604591116],
            [-1.693876826489788, 52.589317502863764],
            [-1.691572460712639, 52.589347488885764],
            [-1.691499263568652, 52.589050634195274],
            [-1.688664981144569, 52.588666550329442],
            [-1.685707497353034, 52.589418386042958],
            [-1.685906860771042, 52.593331285305297],
            [-1.682888377086341, 52.593280097870881],
            [-1.682726941689929, 52.593762419245849],
            [-1.681438494801697, 52.593721206521252],
            [-1.680327116473216, 52.593488075253667],
            [-1.6792301894, 52.592692209798088],
            [-1.677671692075503, 52.5932471491762],
            [-1.673652499200279, 52.592579895096506],
            [-1.669072786181277, 52.592657096377366],
            [-1.666670881031589, 52.592092098288262],
            [-1.66564680900881, 52.592234849289966],
            [-1.665304860061818, 52.594298846966474],
            [-1.665715326406099, 52.595648478505233],
            [-1.660789006257595, 52.594492776147696],
            [-1.659881676847658, 52.595564460078663],
            [-1.655106652147957, 52.595654944846274],
            [-1.655517105807987, 52.596591079943479],
            [-1.653016642890705, 52.597284993041661],
            [-1.651586075637065, 52.598400928534559],
            [-1.64887242161431, 52.598593394343645],
            [-1.649146097244923, 52.600744569352209],
            [-1.64518929081547, 52.603107901458557],
            [-1.643786191872388, 52.603337428687858],
            [-1.643715917938414, 52.603627588500558],
            [-1.642921543168599, 52.605624532680856],
            [-1.642202010466736, 52.606400880748261],
            [-1.640986704289195, 52.606945584845356],
            [-1.639786257878268, 52.60837581776201],
            [-1.639095380785147, 52.610684995672216],
            [-1.639849785215013, 52.614676963776844],
            [-1.639058402835554, 52.61572456539831],
            [-1.637071136348957, 52.61706606896059],
            [-1.636023614863847, 52.619599777403458],
            [-1.638687892444903, 52.620726256720559],
            [-1.640164555206634, 52.620062806272401],
            [-1.641538692997117, 52.620191926949843],
            [-1.644126314609042, 52.620774169694911],
            [-1.643094868942093, 52.623186624830609],
            [-1.638189140001238, 52.625503705913829],
            [-1.639788861640101, 52.627147413764916],
            [-1.640989400886742, 52.629201623175646],
            [-1.642101243129404, 52.630006872345497],
            [-1.642516882746252, 52.631399743084593],
            [-1.64019087518926, 52.634156163171959],
            [-1.638196603758563, 52.637683972127718],
            [-1.638958210007043, 52.638510654705641],
            [-1.641056840312216, 52.639564332943358],
            [-1.64243043756832, 52.640923239428751],
            [-1.634255369290313, 52.643026182801385],
            [-1.632950373461961, 52.643558839158821],
            [-1.634520736737994, 52.643991601762735],
            [-1.635975689322824, 52.645334651282454],
            [-1.639552976808284, 52.647418614714468],
            [-1.64027215005438, 52.648216391070768],
            [-1.640603938569108, 52.649573949704752],
            [-1.64260841826354, 52.651004881608756],
            [-1.642065292496985, 52.651989418311096],
            [-1.64288804178537, 52.65442362294624],
            [-1.64495045711317, 52.65749980927869],
            [-1.639406344743204, 52.658783897188002],
            [-1.639204003821403, 52.659298393421331],
            [-1.631258708824231, 52.662485089133561],
            [-1.627984212848882, 52.663474521411025],
            [-1.628147003293658, 52.66380585384907],
            [-1.625558737356176, 52.665623524297736],
            [-1.620036849730501, 52.668082632392824],
            [-1.615596731102863, 52.668698505078062],
            [-1.615550358859613, 52.671962522173757],
            [-1.606089550144733, 52.673172061600219],
            [-1.604271605538977, 52.673704494465106],
            [-1.603936031178328, 52.674179829467946],
            [-1.605071844111514, 52.676150566434025],
            [-1.606211779198126, 52.677015570852959],
            [-1.603917436100772, 52.677873641180526],
            [-1.604289029809403, 52.678981514830291],
            [-1.602110582467838, 52.679603507296505],
            [-1.58965137710357, 52.68724366444782],
            [-1.584465894797515, 52.685086962664982],
            [-1.581436503630865, 52.682430640453433],
            [-1.579432624083267, 52.67960887295272],
            [-1.577614525408376, 52.67856140334176],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000219",
        LAD13CDO: "44UC",
        LAD13NM: "Nuneaton and Bedworth",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.42892306119511, 52.540898765899385],
            [-1.427191066825922, 52.54029346708289],
            [-1.426986179374964, 52.540628701287872],
            [-1.424093861156934, 52.539570935251696],
            [-1.418967728404369, 52.537750596931332],
            [-1.418347577013738, 52.536480863156967],
            [-1.417385839308951, 52.536071586156325],
            [-1.417354379201754, 52.53443794955043],
            [-1.418381393664658, 52.534032155103851],
            [-1.418495496490414, 52.533429486738584],
            [-1.419751169401475, 52.533344846811893],
            [-1.420173551097651, 52.532727504876675],
            [-1.420711297128065, 52.532864986463608],
            [-1.42095445443403, 52.532422968830176],
            [-1.422675908936976, 52.531553050787487],
            [-1.422582904696589, 52.53043873661543],
            [-1.421389659348062, 52.529593246503033],
            [-1.421629551485451, 52.528948936308304],
            [-1.421270923121122, 52.52864512016712],
            [-1.422167563866855, 52.527939288521139],
            [-1.421473854547497, 52.527447744354916],
            [-1.422030669946238, 52.526576634899065],
            [-1.422796175037813, 52.526650490671344],
            [-1.423324341376896, 52.526388757777454],
            [-1.424411232657953, 52.524765955760039],
            [-1.424964591065432, 52.52178126431388],
            [-1.424618677121029, 52.520732247625524],
            [-1.425658537358369, 52.51965040329457],
            [-1.425410724739935, 52.51898933399211],
            [-1.426905588273311, 52.518271084191554],
            [-1.426845483427802, 52.517453600818016],
            [-1.428248140620698, 52.516794221777516],
            [-1.428878071112743, 52.516173354604959],
            [-1.427961916788456, 52.515117099921234],
            [-1.42696784780486, 52.515183314429521],
            [-1.424151332161361, 52.514428872521258],
            [-1.422276353214159, 52.514923180242477],
            [-1.419712073247237, 52.516394893553354],
            [-1.416767189456489, 52.516769692641134],
            [-1.413855213505444, 52.516660918208196],
            [-1.407555685376266, 52.515319719347637],
            [-1.405472249753698, 52.513116608024227],
            [-1.407396099989663, 52.510968644658554],
            [-1.406687579547824, 52.509639066040606],
            [-1.406932977438474, 52.50881231307789],
            [-1.405422839379234, 52.506839516219358],
            [-1.405805892714826, 52.505747351147448],
            [-1.407515871548075, 52.503702594801922],
            [-1.403896588245713, 52.502363788924995],
            [-1.40465552501272, 52.50169065710395],
            [-1.402821488631437, 52.500817476148782],
            [-1.405714061283944, 52.497040027128826],
            [-1.406877804310746, 52.493639530271977],
            [-1.407655508082561, 52.493638030214697],
            [-1.407667702954845, 52.492181701241059],
            [-1.408402006755388, 52.492119745742208],
            [-1.40964832930199, 52.491326751101653],
            [-1.409670912713374, 52.49018962061357],
            [-1.411783158296643, 52.488621479777926],
            [-1.41061349783468, 52.486220707534144],
            [-1.408849594361446, 52.486416885384692],
            [-1.408462679056445, 52.485179716594459],
            [-1.405885931864567, 52.484828784925959],
            [-1.40608443436312, 52.483319448344346],
            [-1.404800613165584, 52.483083752824626],
            [-1.405712300560946, 52.480664608256099],
            [-1.406208615107826, 52.480329072049273],
            [-1.40463890058506, 52.478262457879104],
            [-1.405032469830291, 52.47637292268719],
            [-1.406955524577816, 52.476466181528977],
            [-1.408569783065271, 52.47378442430211],
            [-1.41041985683086, 52.466548537023428],
            [-1.417010233990916, 52.464517956570774],
            [-1.42105287154459, 52.464459587756792],
            [-1.42121904993636, 52.463011196396636],
            [-1.428136965480849, 52.467089470194551],
            [-1.437631150956197, 52.468029477325786],
            [-1.449369434519165, 52.460803597523594],
            [-1.450156448962538, 52.46036224695564],
            [-1.450647938584271, 52.460605463897039],
            [-1.451432227915098, 52.460145213010485],
            [-1.45906134622153, 52.455140482530439],
            [-1.459784402412888, 52.455580704113423],
            [-1.460333372102759, 52.455214614775798],
            [-1.460579589133939, 52.455779416412746],
            [-1.461340636799713, 52.455761306638472],
            [-1.462021887175072, 52.457824036195611],
            [-1.463929300069645, 52.458300176296767],
            [-1.464248534204011, 52.458067879493342],
            [-1.466312691705802, 52.458485355947587],
            [-1.470823094546901, 52.456920656639973],
            [-1.472835625575979, 52.456362363637687],
            [-1.477305525086224, 52.456938676526654],
            [-1.478904575465179, 52.456743451106753],
            [-1.479213960593815, 52.457089133333795],
            [-1.483024387394921, 52.458069576814246],
            [-1.484705796059451, 52.457249821784472],
            [-1.486849985338647, 52.457632226727767],
            [-1.486844855461164, 52.458200377789844],
            [-1.488204422496872, 52.458478664441579],
            [-1.489129304968237, 52.458422426837537],
            [-1.489675591917687, 52.458015734918142],
            [-1.491990098807202, 52.458567794302439],
            [-1.492814426801042, 52.45842479145076],
            [-1.49200595033011, 52.457573559289486],
            [-1.491917009217816, 52.456605844523196],
            [-1.491303507597116, 52.456462963797129],
            [-1.491758656128023, 52.455915625097482],
            [-1.491719072308026, 52.455007456422372],
            [-1.492862436325448, 52.454884702222721],
            [-1.492627794375251, 52.454429697126059],
            [-1.494772507950082, 52.452959104026142],
            [-1.494306408538453, 52.452669430436551],
            [-1.495408523638635, 52.452029545286948],
            [-1.495861130198455, 52.452212175023639],
            [-1.498723726627352, 52.450998086638144],
            [-1.500033645627771, 52.451021608074925],
            [-1.502862061506418, 52.450814165268476],
            [-1.505042230750711, 52.450072631178401],
            [-1.519663732261293, 52.453325258155282],
            [-1.519059758477448, 52.455141498144194],
            [-1.517577766685441, 52.456767165360624],
            [-1.521578295910888, 52.456305132482235],
            [-1.525923607184466, 52.456344194036951],
            [-1.529686190648728, 52.457071219199747],
            [-1.535042890764199, 52.459460367740078],
            [-1.537023836371898, 52.462409681401347],
            [-1.539800013410851, 52.46478219594276],
            [-1.53688580660161, 52.465887394590965],
            [-1.533789985645227, 52.468425719731314],
            [-1.530821459347634, 52.469618657476893],
            [-1.53034549760219, 52.470355752112219],
            [-1.531338942140587, 52.470931456751387],
            [-1.531830180507438, 52.470973855894805],
            [-1.532491260269236, 52.470547643370438],
            [-1.533489068359959, 52.470992092416893],
            [-1.535588529681717, 52.470971579802885],
            [-1.535576084557131, 52.471314950775515],
            [-1.536615140787606, 52.471340598995397],
            [-1.537245268776349, 52.471054483557147],
            [-1.537371710860987, 52.471350750322088],
            [-1.538886235335112, 52.47166142231962],
            [-1.54010595366794, 52.472292773467565],
            [-1.545184292351742, 52.472639631895611],
            [-1.541042787621228, 52.473798645553181],
            [-1.538047910674443, 52.474258074298241],
            [-1.531865545384256, 52.474579903151273],
            [-1.521773118054987, 52.476093939160727],
            [-1.514831014271977, 52.476249985788698],
            [-1.513816686386949, 52.477846052192703],
            [-1.513781468467255, 52.479839899014493],
            [-1.514418098835344, 52.480593180532118],
            [-1.514534508730392, 52.483529804245578],
            [-1.51582714971742, 52.48529714466131],
            [-1.517338168192664, 52.486227490017832],
            [-1.523309514526219, 52.488287925735882],
            [-1.521284987429994, 52.493404968190099],
            [-1.524373074024476, 52.493726670832736],
            [-1.529419324124312, 52.495920620640817],
            [-1.530437284349488, 52.497151798597457],
            [-1.531666901975551, 52.497876768425648],
            [-1.531940159036854, 52.500582042667865],
            [-1.532522932059328, 52.501331414214008],
            [-1.536284387737191, 52.503801373890539],
            [-1.542298254906822, 52.506053392271788],
            [-1.541403874302228, 52.508186852554921],
            [-1.542991417382545, 52.508400658909935],
            [-1.546087259966029, 52.508086229974715],
            [-1.54650823492648, 52.509844486211499],
            [-1.547585600462968, 52.509959184027373],
            [-1.549520545386923, 52.510790942150493],
            [-1.551055895078886, 52.512815023463773],
            [-1.553398863768656, 52.513389352283255],
            [-1.555231554923006, 52.517370716996211],
            [-1.554669163177522, 52.517462099207648],
            [-1.553900358738974, 52.518582052948936],
            [-1.553070203489706, 52.519644234239529],
            [-1.556089698920767, 52.521713393833103],
            [-1.55601222481867, 52.522088883886532],
            [-1.554890162861539, 52.524908418942061],
            [-1.551687714338903, 52.526753665452844],
            [-1.554447599839871, 52.528007391008138],
            [-1.556196262828987, 52.531618926859515],
            [-1.555897003542434, 52.53205831262779],
            [-1.554333244276096, 52.532754553911552],
            [-1.553931022097757, 52.533459649627474],
            [-1.552556201351196, 52.533979476858136],
            [-1.549891072255208, 52.534385613433926],
            [-1.544944551732228, 52.533857881425554],
            [-1.542446050854369, 52.534679826172578],
            [-1.541255524581784, 52.534049520663466],
            [-1.540779607034181, 52.535297279311735],
            [-1.539297813575281, 52.535593587232178],
            [-1.535229456561106, 52.535112013606273],
            [-1.533671390479698, 52.534393885379721],
            [-1.532013845977652, 52.534301943166881],
            [-1.527519475200447, 52.535759350960753],
            [-1.524063054747127, 52.536096120616712],
            [-1.518338069263989, 52.537335166941119],
            [-1.511986419048971, 52.537485369952037],
            [-1.503831665137222, 52.538675921370029],
            [-1.501722772245314, 52.538721906941348],
            [-1.498740029794462, 52.539762944079563],
            [-1.498508894999627, 52.541285762943858],
            [-1.49706051503563, 52.542357517049389],
            [-1.495617859537236, 52.541250113212726],
            [-1.492703734614822, 52.540403399943607],
            [-1.487718496450592, 52.541302514660828],
            [-1.486661825228347, 52.540371974022698],
            [-1.486332866271296, 52.540891066433822],
            [-1.485207709472517, 52.540903262381377],
            [-1.482508956045257, 52.542952000288516],
            [-1.480932061392134, 52.543755095318041],
            [-1.479970421914603, 52.5438992114507],
            [-1.473140539441304, 52.547447929499121],
            [-1.46462126112023, 52.550239766733995],
            [-1.462001790531426, 52.550502990692344],
            [-1.46000360563633, 52.551572692460248],
            [-1.459230451175478, 52.551961127180988],
            [-1.42892306119511, 52.540898765899385],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000220",
        LAD13CDO: "44UD",
        LAD13NM: "Rugby",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.416068464341916, 52.534465778027389],
            [-1.415839363742976, 52.534176968314569],
            [-1.415216134675188, 52.534370773401157],
            [-1.395137465847395, 52.52702847037056],
            [-1.380428360748267, 52.521475699597367],
            [-1.373260942006946, 52.519109560143178],
            [-1.373552924924267, 52.518060169904437],
            [-1.368246535436349, 52.517208488432054],
            [-1.368163299772168, 52.516435799629193],
            [-1.365285434805756, 52.515707496736383],
            [-1.364965481639372, 52.515924239232668],
            [-1.341801268916202, 52.507350670150927],
            [-1.338453381753187, 52.505760095835242],
            [-1.325098995222379, 52.501024708792706],
            [-1.307837185184511, 52.494522289497901],
            [-1.305958076012181, 52.493377634390534],
            [-1.293604006601591, 52.484243411868341],
            [-1.257842181594099, 52.453875143933878],
            [-1.242930104485847, 52.439662445704023],
            [-1.241585689466759, 52.439738342896796],
            [-1.240755352760128, 52.440117798471817],
            [-1.236765826168908, 52.436022252721727],
            [-1.236456430814918, 52.435710093313517],
            [-1.224398587759515, 52.422988671988435],
            [-1.201626595613241, 52.396714983963435],
            [-1.202598282908014, 52.396783573184649],
            [-1.202199583646556, 52.395991535095256],
            [-1.187096848356657, 52.378582296168702],
            [-1.174820593113554, 52.364349125354401],
            [-1.172140387580708, 52.361284447832865],
            [-1.174706723108287, 52.360528320672067],
            [-1.17498712753419, 52.356273333185101],
            [-1.178206980439725, 52.355096444520392],
            [-1.179248421356457, 52.354360175452754],
            [-1.183832170744929, 52.354609480112124],
            [-1.184256519188018, 52.353651337557395],
            [-1.186629858588957, 52.354255662880604],
            [-1.187839378244364, 52.353071854884512],
            [-1.188271745197784, 52.353193497763087],
            [-1.18897976689571, 52.352453055126006],
            [-1.189378777449348, 52.352551989158876],
            [-1.191509073648113, 52.35175115141098],
            [-1.192033177280154, 52.351028312215924],
            [-1.19263820022915, 52.351423527735307],
            [-1.192423849760077, 52.351745717171667],
            [-1.195654799751036, 52.353068654257186],
            [-1.196748409188076, 52.352999672503493],
            [-1.196994200544223, 52.352723539864662],
            [-1.198234307208723, 52.352915361316832],
            [-1.198485473305805, 52.352666233178944],
            [-1.199758200727829, 52.352596639920073],
            [-1.199999186130922, 52.352260233022811],
            [-1.200379655321185, 52.352492960938349],
            [-1.20232020092989, 52.352353235627483],
            [-1.202492920788808, 52.35181138247389],
            [-1.203460982041156, 52.351781948265426],
            [-1.204219234563009, 52.351253926197508],
            [-1.206195532652083, 52.350835676506698],
            [-1.206412823944327, 52.350425376402704],
            [-1.207788397291083, 52.350182873048297],
            [-1.208152194269748, 52.349624311335198],
            [-1.209330648059801, 52.349566570158778],
            [-1.209447739842978, 52.349092661582155],
            [-1.209658786134249, 52.349277476510593],
            [-1.210676178402902, 52.349112558526627],
            [-1.210792625933794, 52.34875641822299],
            [-1.211335049710024, 52.349129542917815],
            [-1.212122401455627, 52.348938803424375],
            [-1.212625398452397, 52.349135448047711],
            [-1.213146812606724, 52.349535395513094],
            [-1.212896959656657, 52.349716236596244],
            [-1.213673868749403, 52.349948863713479],
            [-1.214100811391068, 52.349633443933101],
            [-1.21460724407086, 52.349968554597147],
            [-1.215009435691838, 52.349723991021385],
            [-1.216581532741315, 52.349904338724293],
            [-1.216907674966498, 52.349559471562536],
            [-1.220275652187769, 52.349718389558362],
            [-1.222513684665854, 52.349180223666622],
            [-1.222600064295239, 52.348858936643005],
            [-1.223795185955241, 52.348601574766818],
            [-1.223475763617563, 52.348147261304781],
            [-1.223959823869996, 52.347918488786306],
            [-1.224206595238914, 52.348078339128726],
            [-1.224423208323356, 52.347780381348706],
            [-1.223932337298554, 52.347475982192186],
            [-1.225912505717299, 52.346647467989598],
            [-1.227549221181549, 52.346485560155983],
            [-1.229835548933131, 52.347039901169033],
            [-1.230298310739423, 52.346682401741973],
            [-1.230313725863235, 52.346978285176476],
            [-1.231135403024118, 52.346663575688964],
            [-1.231496280576293, 52.34684752790313],
            [-1.233403380444405, 52.346499397995643],
            [-1.238151882136893, 52.344135964601492],
            [-1.238280996546537, 52.343797859904484],
            [-1.239298863067614, 52.343933876246126],
            [-1.239605506431797, 52.343257975683116],
            [-1.24087084595761, 52.342999994163236],
            [-1.241346079724166, 52.342592185610791],
            [-1.242672268970108, 52.342551243224349],
            [-1.243558400008624, 52.341976139873402],
            [-1.244955391051843, 52.341655125572011],
            [-1.245586080598555, 52.341856041718223],
            [-1.24858977560189, 52.34096175383246],
            [-1.249154632179273, 52.340630002765913],
            [-1.249267923144139, 52.339920483445788],
            [-1.250756284753544, 52.339136977128341],
            [-1.252995259745998, 52.338837384981083],
            [-1.253289402744151, 52.338279143761234],
            [-1.255045983439986, 52.338296521342507],
            [-1.258028429390841, 52.337242730647965],
            [-1.262456453212385, 52.334066278182611],
            [-1.263086950411911, 52.332863703373398],
            [-1.262465921762425, 52.332172965270843],
            [-1.264209985172723, 52.330630299555381],
            [-1.264434114924791, 52.329061076298395],
            [-1.265279213043569, 52.328360588516304],
            [-1.2609060967622, 52.327847840816489],
            [-1.257291641285515, 52.325498470611691],
            [-1.255140526298155, 52.324714456308094],
            [-1.254373825767223, 52.324760866722229],
            [-1.253408850909138, 52.324033745417751],
            [-1.252376993366394, 52.324055991472008],
            [-1.251992914963421, 52.323600445463747],
            [-1.251360520263505, 52.323688143048749],
            [-1.250738056242819, 52.32310072185377],
            [-1.250058265884469, 52.323388597691967],
            [-1.24928483533043, 52.323140047478994],
            [-1.248793819824313, 52.32328347073036],
            [-1.247648692869477, 52.321939314574863],
            [-1.246446522823576, 52.321971212805984],
            [-1.246397141988705, 52.321595998418601],
            [-1.244741766747743, 52.321446978495388],
            [-1.243721560302038, 52.321907954656687],
            [-1.242557764527554, 52.32166854887997],
            [-1.242121216493748, 52.3218869138393],
            [-1.237048495429721, 52.320303428702353],
            [-1.235091677613226, 52.320538914124242],
            [-1.234229942250103, 52.320144048719847],
            [-1.231324501169065, 52.320004714220069],
            [-1.225904450274525, 52.318939033461241],
            [-1.225908495866877, 52.318707107359742],
            [-1.223112295115978, 52.318535920567356],
            [-1.216266062149317, 52.317088208198506],
            [-1.215675076632545, 52.316577227474951],
            [-1.215174471362002, 52.316929025316341],
            [-1.21300588301052, 52.315706289961334],
            [-1.212026906225075, 52.316064777106533],
            [-1.209247902293662, 52.315187627768502],
            [-1.209275747074687, 52.31469513876656],
            [-1.210504728334664, 52.313558874982917],
            [-1.212555649424679, 52.310407030126754],
            [-1.215422950483802, 52.308930994784625],
            [-1.217129145759865, 52.30724761508413],
            [-1.217550651530013, 52.305967472151728],
            [-1.220920419013686, 52.305836002591988],
            [-1.220915813658347, 52.303340227461902],
            [-1.222980112511569, 52.303125463840587],
            [-1.223185000254452, 52.302814842927276],
            [-1.223896147597049, 52.302738602355056],
            [-1.224808452041882, 52.301472444569413],
            [-1.226429418108067, 52.300603803683416],
            [-1.226505560734323, 52.298168792021833],
            [-1.2289701551427, 52.297940361384626],
            [-1.228873353131537, 52.295154490419584],
            [-1.231844872686218, 52.294261317690946],
            [-1.233797163843315, 52.290311905032553],
            [-1.232466187722232, 52.289786314609586],
            [-1.231697936706687, 52.290210165485121],
            [-1.231600740572987, 52.289811256362491],
            [-1.230762870145697, 52.289513614100706],
            [-1.231850278119928, 52.289200629340257],
            [-1.231117473792485, 52.289101462602702],
            [-1.230965081089072, 52.28875793442856],
            [-1.229605919038348, 52.289270525404703],
            [-1.229818289240505, 52.288860147307631],
            [-1.228713041174694, 52.288680320017846],
            [-1.228224585024992, 52.28894774275485],
            [-1.227604241937801, 52.288620931241951],
            [-1.227686574779569, 52.289282267945019],
            [-1.225430941764493, 52.288148188869606],
            [-1.224211985680819, 52.286841968279738],
            [-1.223581931408099, 52.286822545345792],
            [-1.224245202476122, 52.286282979769659],
            [-1.223496689802956, 52.28624749464565],
            [-1.223848626861783, 52.285402905891594],
            [-1.222831240593646, 52.284648210710529],
            [-1.222434753435941, 52.284686957820625],
            [-1.222850368401698, 52.283386974313068],
            [-1.222557034700465, 52.282222576853776],
            [-1.223384877845729, 52.281657127400891],
            [-1.222893605661287, 52.280998491486507],
            [-1.223527582392392, 52.280788687146242],
            [-1.222311451503832, 52.280508275993668],
            [-1.221187713905301, 52.279389650381738],
            [-1.221787751982771, 52.278689650818563],
            [-1.220665380191666, 52.277747242060215],
            [-1.221579712982382, 52.27742871461156],
            [-1.221705574633963, 52.276435197266807],
            [-1.220038751040314, 52.276036713686594],
            [-1.219972147492369, 52.275739588097487],
            [-1.22060836299732, 52.275565776106788],
            [-1.220897867980153, 52.275020165741886],
            [-1.220489539324477, 52.274736069828641],
            [-1.220880250435384, 52.274018510037948],
            [-1.220161713326712, 52.273957128462897],
            [-1.2209751245483, 52.273539044070191],
            [-1.21975430267565, 52.273454567258398],
            [-1.22014688564319, 52.272964481328032],
            [-1.21874878339037, 52.27221083051559],
            [-1.219577890653449, 52.27214978173582],
            [-1.2187886988292, 52.271107062864765],
            [-1.21935018694241, 52.270344785584591],
            [-1.218573735055163, 52.27016343788091],
            [-1.218707276004485, 52.269652762470926],
            [-1.217689267813512, 52.2692917991683],
            [-1.217883634702557, 52.26898920776857],
            [-1.217019486529168, 52.268796480418267],
            [-1.217056698713951, 52.268264489999176],
            [-1.217470281860399, 52.268411977652505],
            [-1.217727566937922, 52.268197910109791],
            [-1.217605101578955, 52.267745776278247],
            [-1.216917834925362, 52.26749578139821],
            [-1.216633026230061, 52.266693738889138],
            [-1.215744996595085, 52.266362389987115],
            [-1.215921754658397, 52.265728834497239],
            [-1.21657033941205, 52.265344748810293],
            [-1.215880328884868, 52.264504952835146],
            [-1.216330622325271, 52.264230134981041],
            [-1.215899144979258, 52.26401959083659],
            [-1.217095274065532, 52.262661868625408],
            [-1.218384324511193, 52.262098610377535],
            [-1.217912126744492, 52.261788009442007],
            [-1.219337196099061, 52.261320041668426],
            [-1.219875705422446, 52.261525886074786],
            [-1.22064446841452, 52.260632810317475],
            [-1.221725021855226, 52.260591386856824],
            [-1.221870175907779, 52.260334315883334],
            [-1.223722966788044, 52.259950926032104],
            [-1.224355132270736, 52.259239435445039],
            [-1.223715815013428, 52.25901766498054],
            [-1.224211179757028, 52.258924721467444],
            [-1.223955461291273, 52.258718058042852],
            [-1.22469394849232, 52.258041428028335],
            [-1.225362767459418, 52.25833531077479],
            [-1.225785657596115, 52.258196932792281],
            [-1.226559334856566, 52.257685048021145],
            [-1.226448967812966, 52.256870684660377],
            [-1.227033586761661, 52.257035442304691],
            [-1.228026318060643, 52.255886655577775],
            [-1.230179781776612, 52.255384656918082],
            [-1.230156157284583, 52.254802817278716],
            [-1.231158492393156, 52.255124913366558],
            [-1.231096425237491, 52.254817034051491],
            [-1.232094519871274, 52.254791162068869],
            [-1.231507789342755, 52.254579665032828],
            [-1.232472929991082, 52.254086069191644],
            [-1.232394111243735, 52.253475101617632],
            [-1.232822078538849, 52.253548008377322],
            [-1.233013374233159, 52.253243573670034],
            [-1.239227755817868, 52.252719164626598],
            [-1.245048166403722, 52.252957901663848],
            [-1.247565050062175, 52.253381233283321],
            [-1.251291822389264, 52.255034894577577],
            [-1.253600608722976, 52.255647375965623],
            [-1.259492026345916, 52.256488211000573],
            [-1.259618157450469, 52.257183966380353],
            [-1.264987502850287, 52.259226875866041],
            [-1.2673357826131, 52.260668255006216],
            [-1.271361775730819, 52.261400710494669],
            [-1.27294093085196, 52.262495591748873],
            [-1.272521223741168, 52.263072892872813],
            [-1.27650007482371, 52.265190312672253],
            [-1.276530017833626, 52.266592109517084],
            [-1.273683481711043, 52.266820979112595],
            [-1.275478600609271, 52.267897373554121],
            [-1.28151367456739, 52.270039802141788],
            [-1.287816625771242, 52.273610319594361],
            [-1.288005441675683, 52.271063564474353],
            [-1.289767726695309, 52.270466418990281],
            [-1.290252575976354, 52.269103683528449],
            [-1.292504373248682, 52.267022416432518],
            [-1.293352414317406, 52.265388532694409],
            [-1.295743075413208, 52.264417456575039],
            [-1.298164174907731, 52.264574815350208],
            [-1.299492661349496, 52.264236568925043],
            [-1.305482515204948, 52.260121945588274],
            [-1.306233103229534, 52.258873086584174],
            [-1.308302287521067, 52.25780726610121],
            [-1.308902826668371, 52.257816175780022],
            [-1.309028523551875, 52.257458191780827],
            [-1.311642400520635, 52.259905373470659],
            [-1.309442559856534, 52.260703471799808],
            [-1.312557220666336, 52.262806526660356],
            [-1.31334597200803, 52.262776950825661],
            [-1.314748295603418, 52.265528080570554],
            [-1.315746710537638, 52.265684008880378],
            [-1.315752817702433, 52.267001144462533],
            [-1.321407027565823, 52.266659759772246],
            [-1.321882927038307, 52.267354756742172],
            [-1.322737498454208, 52.267722873306369],
            [-1.324798153303131, 52.267991796614425],
            [-1.32815750210539, 52.269403569439618],
            [-1.333845017611884, 52.271156548626493],
            [-1.335203991399327, 52.271224440252787],
            [-1.335712608043157, 52.271807184120021],
            [-1.338481728690474, 52.27313622462124],
            [-1.341037398065335, 52.274836212468095],
            [-1.346512430193498, 52.276269131043087],
            [-1.346573017046125, 52.277431926844955],
            [-1.354818033074135, 52.279121588985639],
            [-1.355186222769489, 52.280415520633468],
            [-1.357031425902373, 52.280128894007561],
            [-1.357007854920986, 52.280542324359963],
            [-1.360679512706017, 52.280964121619171],
            [-1.359269220809572, 52.2829073986167],
            [-1.362362555090553, 52.283397029214264],
            [-1.362530892863591, 52.285151065003078],
            [-1.363977161668015, 52.286424707075376],
            [-1.364573605376998, 52.287977859879923],
            [-1.36637601374397, 52.28803698689407],
            [-1.366234905874879, 52.288576552446862],
            [-1.368241179846372, 52.289147398299875],
            [-1.367822151638922, 52.289643224421241],
            [-1.370543761125541, 52.291366827169902],
            [-1.375673138641176, 52.293611069547914],
            [-1.379116531314283, 52.294508472501569],
            [-1.378958901452138, 52.294771062058722],
            [-1.383131906208332, 52.297096254537756],
            [-1.389773400956152, 52.300271973053633],
            [-1.389281047128844, 52.300884370092319],
            [-1.392246158990714, 52.301750145397193],
            [-1.398315558625731, 52.302435703205148],
            [-1.405763080264542, 52.303935220177962],
            [-1.408504082470244, 52.304158446931048],
            [-1.409031421866601, 52.30288355684921],
            [-1.414804362320841, 52.303115468057307],
            [-1.419419538409094, 52.303737888686051],
            [-1.419244278939229, 52.304915661429476],
            [-1.418654660755738, 52.305366774570885],
            [-1.419455096170205, 52.305841803377341],
            [-1.419513700718043, 52.30685620155937],
            [-1.419030461692421, 52.307021946203271],
            [-1.420870418711995, 52.308090039092455],
            [-1.420524307218783, 52.308649339425301],
            [-1.421143670872516, 52.309068627320521],
            [-1.418973948162977, 52.311676863907749],
            [-1.419998820469899, 52.312160183764966],
            [-1.418825315367006, 52.313170326012099],
            [-1.415815328171242, 52.313499815370889],
            [-1.415470974903081, 52.313249080550158],
            [-1.415859143131947, 52.312957015979578],
            [-1.415658325922085, 52.312500213422993],
            [-1.413536207984665, 52.312245164597584],
            [-1.412742579339916, 52.311806091458145],
            [-1.412505522668738, 52.312427943361882],
            [-1.410434473279032, 52.313189000316378],
            [-1.410428553144709, 52.313524310186111],
            [-1.411263211875077, 52.313746037111443],
            [-1.410843213082962, 52.314112547688296],
            [-1.410251942972549, 52.314009805962421],
            [-1.410376479821738, 52.31468380315021],
            [-1.408996735351773, 52.31485581903933],
            [-1.409056244889574, 52.315344291559136],
            [-1.406646961081783, 52.315274681933403],
            [-1.406089355447364, 52.316602449991478],
            [-1.406650333000803, 52.317656237108444],
            [-1.406984527151758, 52.319105356985588],
            [-1.407881730293452, 52.319374170910372],
            [-1.408028922261516, 52.319997937693103],
            [-1.409286935165266, 52.320717164563213],
            [-1.412654691847497, 52.320887683127026],
            [-1.412841890442168, 52.320600023978223],
            [-1.413567164829525, 52.320561370355009],
            [-1.416179005857024, 52.322096361734538],
            [-1.416233501968962, 52.324087985777048],
            [-1.417016474976474, 52.32502234771232],
            [-1.416711534645558, 52.325561160980719],
            [-1.418048675459724, 52.326311250792003],
            [-1.418661510182667, 52.326237848916357],
            [-1.420116684967469, 52.326830266183265],
            [-1.421622548618317, 52.326351268781259],
            [-1.423061310517886, 52.32651652977588],
            [-1.424207135676234, 52.326189475790805],
            [-1.425017332518939, 52.326624055555882],
            [-1.427517403880737, 52.325734463195673],
            [-1.427496810664484, 52.326079591227213],
            [-1.428592274656283, 52.326811308837343],
            [-1.431693999462227, 52.3268289599242],
            [-1.435986530124953, 52.328400347552474],
            [-1.439483691651559, 52.33150694770945],
            [-1.440340008329211, 52.332725593666645],
            [-1.44176113208115, 52.333381412547681],
            [-1.441750714465991, 52.334559091191117],
            [-1.443179555383845, 52.336710912573814],
            [-1.442918685187369, 52.338200272424274],
            [-1.444146664610667, 52.34105057734476],
            [-1.446533662970946, 52.340317382050898],
            [-1.447976380049366, 52.340562370173316],
            [-1.446028235964589, 52.343530836054526],
            [-1.443653199319793, 52.345519119286408],
            [-1.445787761475788, 52.34585908817369],
            [-1.450366271893513, 52.345510064180935],
            [-1.454340301821338, 52.346084071848217],
            [-1.45620954258102, 52.346760661267894],
            [-1.456512401229216, 52.349238871104411],
            [-1.455290560619745, 52.349868859343736],
            [-1.452372517436518, 52.35244007829202],
            [-1.449405458372705, 52.353492542349628],
            [-1.44806141217904, 52.353660686481803],
            [-1.451210134895816, 52.357031405246353],
            [-1.455094980978879, 52.356822818864259],
            [-1.461523082865295, 52.358897549941261],
            [-1.464011483687601, 52.35797027503969],
            [-1.46492371613495, 52.358060713181537],
            [-1.464996735992276, 52.359918427626496],
            [-1.464116055989736, 52.360494309986215],
            [-1.463621946426098, 52.359824993679439],
            [-1.462966843242746, 52.359961368790145],
            [-1.463206416317737, 52.360549519402824],
            [-1.462303446912393, 52.360780960868965],
            [-1.462233888185313, 52.361430638874324],
            [-1.461429657033315, 52.36151058765261],
            [-1.460578636702839, 52.36314203270107],
            [-1.462367071230968, 52.363646439094758],
            [-1.461955294556158, 52.364299055240181],
            [-1.462829482973288, 52.36487301070909],
            [-1.462436320253625, 52.365322533690922],
            [-1.463028876458096, 52.36659734395402],
            [-1.463725164736162, 52.366585220454624],
            [-1.464993481190438, 52.36712138936246],
            [-1.464799740903198, 52.367840629992003],
            [-1.463859643564878, 52.368701231248117],
            [-1.462808528570361, 52.36926644123163],
            [-1.461263592968044, 52.369493158574478],
            [-1.460382776158299, 52.370422327908813],
            [-1.46252192450583, 52.37243059710169],
            [-1.464064489180465, 52.372772034528488],
            [-1.464023452065119, 52.373250128302885],
            [-1.463760297401508, 52.373754185669185],
            [-1.462093729777143, 52.37406576944332],
            [-1.460411819693022, 52.374790809864969],
            [-1.462933725032324, 52.376385467591341],
            [-1.461469656017482, 52.377431563722055],
            [-1.463140913286886, 52.379652553073427],
            [-1.458454958663879, 52.381779848184756],
            [-1.44949936157595, 52.384230586072626],
            [-1.444973861031934, 52.386359892036346],
            [-1.435828785279523, 52.392247459645183],
            [-1.432667597028926, 52.395021098631069],
            [-1.432257109356586, 52.395859712536968],
            [-1.43088498613542, 52.39810606159071],
            [-1.431041042766901, 52.400257266827872],
            [-1.43265299431489, 52.402695058179667],
            [-1.437106715306194, 52.407658240227974],
            [-1.438063518323297, 52.410102723410638],
            [-1.43472155745968, 52.412666980302689],
            [-1.432407126573087, 52.415673898235831],
            [-1.424438305934178, 52.43068941846137],
            [-1.423946336137423, 52.432889610268653],
            [-1.424154203696485, 52.433982025759057],
            [-1.42521611877056, 52.434779220967805],
            [-1.428793946169813, 52.435761190019051],
            [-1.429938516451238, 52.436774504643914],
            [-1.431702259771183, 52.43677220101592],
            [-1.431873727052611, 52.437508417608548],
            [-1.440101677154808, 52.439668410446778],
            [-1.439825021019682, 52.440255954450095],
            [-1.446662972585746, 52.442584206843662],
            [-1.446467165325082, 52.443065161562409],
            [-1.445023115299739, 52.446409015139622],
            [-1.444348186917189, 52.446377079421794],
            [-1.443653170184991, 52.446772075979624],
            [-1.444382090469221, 52.447073972672243],
            [-1.443655510494643, 52.447404092176889],
            [-1.445482217766698, 52.448280212444374],
            [-1.446061061592079, 52.447528652915892],
            [-1.452173326856458, 52.449640116282914],
            [-1.460427880058443, 52.453622000167869],
            [-1.458754353446506, 52.454733625431366],
            [-1.45906134622153, 52.455140482530439],
            [-1.451432227915098, 52.460145213010485],
            [-1.450647938584271, 52.460605463897039],
            [-1.450156448962538, 52.46036224695564],
            [-1.449369434519165, 52.460803597523594],
            [-1.437631150956197, 52.468029477325786],
            [-1.428136965480849, 52.467089470194551],
            [-1.42121904993636, 52.463011196396636],
            [-1.42105287154459, 52.464459587756792],
            [-1.417010233990916, 52.464517956570774],
            [-1.41041985683086, 52.466548537023428],
            [-1.408569783065271, 52.47378442430211],
            [-1.406955524577816, 52.476466181528977],
            [-1.405032469830291, 52.47637292268719],
            [-1.40463890058506, 52.478262457879104],
            [-1.406208615107826, 52.480329072049273],
            [-1.405712300560946, 52.480664608256099],
            [-1.404800613165584, 52.483083752824626],
            [-1.40608443436312, 52.483319448344346],
            [-1.405885931864567, 52.484828784925959],
            [-1.408462679056445, 52.485179716594459],
            [-1.408849594361446, 52.486416885384692],
            [-1.41061349783468, 52.486220707534144],
            [-1.411783158296643, 52.488621479777926],
            [-1.409670912713374, 52.49018962061357],
            [-1.40964832930199, 52.491326751101653],
            [-1.408402006755388, 52.492119745742208],
            [-1.407667702954845, 52.492181701241059],
            [-1.407655508082561, 52.493638030214697],
            [-1.406877804310746, 52.493639530271977],
            [-1.405714061283944, 52.497040027128826],
            [-1.402821488631437, 52.500817476148782],
            [-1.40465552501272, 52.50169065710395],
            [-1.403896588245713, 52.502363788924995],
            [-1.407515871548075, 52.503702594801922],
            [-1.405805892714826, 52.505747351147448],
            [-1.405422839379234, 52.506839516219358],
            [-1.406932977438474, 52.50881231307789],
            [-1.406687579547824, 52.509639066040606],
            [-1.407396099989663, 52.510968644658554],
            [-1.405472249753698, 52.513116608024227],
            [-1.407555685376266, 52.515319719347637],
            [-1.413855213505444, 52.516660918208196],
            [-1.416767189456489, 52.516769692641134],
            [-1.419712073247237, 52.516394893553354],
            [-1.422276353214159, 52.514923180242477],
            [-1.424151332161361, 52.514428872521258],
            [-1.42696784780486, 52.515183314429521],
            [-1.427961916788456, 52.515117099921234],
            [-1.428878071112743, 52.516173354604959],
            [-1.428248140620698, 52.516794221777516],
            [-1.426845483427802, 52.517453600818016],
            [-1.426905588273311, 52.518271084191554],
            [-1.425410724739935, 52.51898933399211],
            [-1.425658537358369, 52.51965040329457],
            [-1.424618677121029, 52.520732247625524],
            [-1.424964591065432, 52.52178126431388],
            [-1.424411232657953, 52.524765955760039],
            [-1.423324341376896, 52.526388757777454],
            [-1.422796175037813, 52.526650490671344],
            [-1.422030669946238, 52.526576634899065],
            [-1.421473854547497, 52.527447744354916],
            [-1.422167563866855, 52.527939288521139],
            [-1.421270923121122, 52.52864512016712],
            [-1.421629551485451, 52.528948936308304],
            [-1.421389659348062, 52.529593246503033],
            [-1.422582904696589, 52.53043873661543],
            [-1.422675908936976, 52.531553050787487],
            [-1.42095445443403, 52.532422968830176],
            [-1.420711297128065, 52.532864986463608],
            [-1.420173551097651, 52.532727504876675],
            [-1.419751169401475, 52.533344846811893],
            [-1.418495496490414, 52.533429486738584],
            [-1.418381393664658, 52.534032155103851],
            [-1.417354379201754, 52.53443794955043],
            [-1.416068464341916, 52.534465778027389],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000221",
        LAD13CDO: "44UE",
        LAD13NM: "Stratford-on-Avon",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.863352013331857, 52.367017152995679],
            [-1.86317571809652, 52.366559351430887],
            [-1.849662311577919, 52.366701191683724],
            [-1.842944366263208, 52.365148863417616],
            [-1.84155344143826, 52.365196455795569],
            [-1.83811331102962, 52.366592465571863],
            [-1.836686946305865, 52.366678609829833],
            [-1.834988022737246, 52.367389172001801],
            [-1.833080554261102, 52.366131474130434],
            [-1.831842521525599, 52.366901975083806],
            [-1.831380467068668, 52.3667520814829],
            [-1.831494371526543, 52.366149904201819],
            [-1.830317154032153, 52.365988198063896],
            [-1.829573583246649, 52.367252042753719],
            [-1.827382167366312, 52.366542242319426],
            [-1.82690063761543, 52.366119002458035],
            [-1.824539447493605, 52.366400513510932],
            [-1.82429132578436, 52.365639579361094],
            [-1.819214612552532, 52.366296305912137],
            [-1.8132498466592, 52.365756626440678],
            [-1.812811657577597, 52.366581229464849],
            [-1.807918037033354, 52.366608450224753],
            [-1.806967862988192, 52.365925451344403],
            [-1.805499111361535, 52.363277246265262],
            [-1.804207406577822, 52.362808527540373],
            [-1.803308900934398, 52.36242675425094],
            [-1.799603461281827, 52.358617702285308],
            [-1.797806938836906, 52.358449227446712],
            [-1.791823720936213, 52.356802643738042],
            [-1.788016839564722, 52.355170463531458],
            [-1.785779803822197, 52.353831395035883],
            [-1.784902661022887, 52.353050356209899],
            [-1.783754580050583, 52.350932876543581],
            [-1.784530869143386, 52.350097307772216],
            [-1.77948792181427, 52.348334932326374],
            [-1.779415202522793, 52.347902369280078],
            [-1.777688233127395, 52.347724727396965],
            [-1.775761067549658, 52.347948541922975],
            [-1.77377485494701, 52.341034007048123],
            [-1.771965237878154, 52.338429670577462],
            [-1.767615132341323, 52.328584127431704],
            [-1.768087692624558, 52.323795969081381],
            [-1.769023562790168, 52.319324267531407],
            [-1.766575924294924, 52.315801587069977],
            [-1.76477534468856, 52.314533993366602],
            [-1.763547569214245, 52.312598645171377],
            [-1.76073642111611, 52.310269914334363],
            [-1.760778424550467, 52.308734466573199],
            [-1.761674181064151, 52.307990984283052],
            [-1.75481769397134, 52.305006590676534],
            [-1.753929730351206, 52.304862700325828],
            [-1.751861426999604, 52.305197310519986],
            [-1.749859072746769, 52.304753468641366],
            [-1.747737045152566, 52.304730081126422],
            [-1.746660400103739, 52.302701377053388],
            [-1.743728897922656, 52.30294139277369],
            [-1.741274431799686, 52.302349883178636],
            [-1.740912340873471, 52.301823160509521],
            [-1.739883536536148, 52.301707624514478],
            [-1.740168710729547, 52.301341449155537],
            [-1.738780169669238, 52.301055196636625],
            [-1.738916662564856, 52.300786689471039],
            [-1.737763951897746, 52.300053230685933],
            [-1.73814277633335, 52.299722329782448],
            [-1.738007898378379, 52.298970445427969],
            [-1.737365686267223, 52.298958232328062],
            [-1.738611600919718, 52.297801249835267],
            [-1.73854428387731, 52.297279665878591],
            [-1.737248999420416, 52.295876111592086],
            [-1.736884425048332, 52.296033529457802],
            [-1.735538429807331, 52.294542637593402],
            [-1.734498722383696, 52.293828278247453],
            [-1.733899240392221, 52.293793666503156],
            [-1.733837942864815, 52.29350134483132],
            [-1.733166686860387, 52.294188486220008],
            [-1.733689099216498, 52.294116842954459],
            [-1.733921762785816, 52.29444011736792],
            [-1.732382567864927, 52.295109115220654],
            [-1.73305241189774, 52.295393822899705],
            [-1.732057068056527, 52.295836589447532],
            [-1.731460906917989, 52.295248172564726],
            [-1.730003788681716, 52.296153769736179],
            [-1.728835397511809, 52.296111538571573],
            [-1.728654671669973, 52.296412297783611],
            [-1.727735728455575, 52.296580101146127],
            [-1.727822932133237, 52.296946205667602],
            [-1.727119671403118, 52.296612843846972],
            [-1.726655561788487, 52.296734938351378],
            [-1.726966811129334, 52.297149209680043],
            [-1.726476274064803, 52.297752221504751],
            [-1.725468880723392, 52.297752583679546],
            [-1.724708456017269, 52.298363052368565],
            [-1.723773858425684, 52.298445380963187],
            [-1.722728074125466, 52.297075513962476],
            [-1.71977238398593, 52.296306170635127],
            [-1.719144780077598, 52.295381380959832],
            [-1.718122764888479, 52.294921343965946],
            [-1.717700275160207, 52.293799249281982],
            [-1.716796632510491, 52.292948406436807],
            [-1.715118028455408, 52.292002192724134],
            [-1.713281112745697, 52.290152947367311],
            [-1.712015201449643, 52.289575391532281],
            [-1.711893183251137, 52.289852893796244],
            [-1.710293263474725, 52.289914612745676],
            [-1.703893792399031, 52.291223923193314],
            [-1.699980558832404, 52.291236532003815],
            [-1.697464004340767, 52.289392505072314],
            [-1.695245136193077, 52.286925265821672],
            [-1.693659196565099, 52.286033825084083],
            [-1.692629416600795, 52.28591787416881],
            [-1.690272911984646, 52.284333028163552],
            [-1.685431733170257, 52.282775711142399],
            [-1.683609824423653, 52.283988134002875],
            [-1.682246105681774, 52.284252386478414],
            [-1.681193344394335, 52.285306809920968],
            [-1.68031386550432, 52.285497725768082],
            [-1.67984571066568, 52.286179720705306],
            [-1.679338919857463, 52.286728552640383],
            [-1.677268802579301, 52.285938066226521],
            [-1.674245491115813, 52.286402661938716],
            [-1.669101411735675, 52.285801306729695],
            [-1.666920937213383, 52.285095737347874],
            [-1.665813917336632, 52.284151328605638],
            [-1.665837332013995, 52.283766610128851],
            [-1.66657336345468, 52.282671872535644],
            [-1.669351418841381, 52.2807674403645],
            [-1.673085162882627, 52.277331844029412],
            [-1.675186070042429, 52.273842205248783],
            [-1.675270061667966, 52.2717728664351],
            [-1.674123038690845, 52.269908714114187],
            [-1.676436996668208, 52.2701074648242],
            [-1.676095491551927, 52.268102587767032],
            [-1.675835505224999, 52.265371514640968],
            [-1.674012258762428, 52.263029014887486],
            [-1.66939918250561, 52.259950496840652],
            [-1.669024011043527, 52.259176278003082],
            [-1.669218929092111, 52.25798560450604],
            [-1.668528774847466, 52.258197640520734],
            [-1.666866393383366, 52.257940337187122],
            [-1.665975644015814, 52.25676907882],
            [-1.664211136202017, 52.256076316825926],
            [-1.661883477864838, 52.256241394727496],
            [-1.661801983696798, 52.255976845519363],
            [-1.660469307063929, 52.255916384514094],
            [-1.660359589997022, 52.255703897160679],
            [-1.65869615292278, 52.255793502657625],
            [-1.658541434660395, 52.256292019557101],
            [-1.657521817403162, 52.256479663905864],
            [-1.657370322494816, 52.257128327239073],
            [-1.655572488456935, 52.256971163684923],
            [-1.653416416025619, 52.256350818982632],
            [-1.653371378901818, 52.256114240891193],
            [-1.653051492250365, 52.257120220237333],
            [-1.651943572250465, 52.257166408782162],
            [-1.651589920246542, 52.257801882595238],
            [-1.649059209920781, 52.257503106368176],
            [-1.648986642634288, 52.258159185643194],
            [-1.645940012760608, 52.258256181881684],
            [-1.643464186400461, 52.256594514296566],
            [-1.644912662382716, 52.255545207850382],
            [-1.64589846721124, 52.25537645072248],
            [-1.642316312053461, 52.253956873784027],
            [-1.641121696491814, 52.253136028645393],
            [-1.640958249967961, 52.252332693841247],
            [-1.637996799450767, 52.249928627378452],
            [-1.63804514079059, 52.249207748984382],
            [-1.635926298561216, 52.247334840452886],
            [-1.629623661293286, 52.24680909284892],
            [-1.626422886997347, 52.247035461647819],
            [-1.626425843847762, 52.246508635752335],
            [-1.621932558809576, 52.244743918012148],
            [-1.621373012913654, 52.244406785341468],
            [-1.621727862813291, 52.244013244937086],
            [-1.624588025228247, 52.242125400558834],
            [-1.625167699471655, 52.242158707497836],
            [-1.625788886654661, 52.243003074383608],
            [-1.626204485817871, 52.242170982884012],
            [-1.627585570032065, 52.242342565516864],
            [-1.627760217855013, 52.241773126521053],
            [-1.626873079686301, 52.240681593986288],
            [-1.626246721085415, 52.24150133393718],
            [-1.62579960360347, 52.241553860755545],
            [-1.624726094688034, 52.239818909817167],
            [-1.6239511481143, 52.239328268213903],
            [-1.624077861391846, 52.239063455450541],
            [-1.625067794700034, 52.238892188168464],
            [-1.624951622583503, 52.238427916039178],
            [-1.618415895030813, 52.236897500990388],
            [-1.61236790834987, 52.237422626924094],
            [-1.610082017271292, 52.238760064678992],
            [-1.608118022590173, 52.238450589897603],
            [-1.606652484352291, 52.237583541490864],
            [-1.605686638356902, 52.236022288026831],
            [-1.605682805151206, 52.235297650205396],
            [-1.60593235765997, 52.234731189629549],
            [-1.609246779910337, 52.232311199365263],
            [-1.609043361510906, 52.230297577804393],
            [-1.609391018227841, 52.230055987373539],
            [-1.61034432940149, 52.229703116447212],
            [-1.6123186708041, 52.229622406076203],
            [-1.613508637119512, 52.230339243678863],
            [-1.614757641586019, 52.230328939147959],
            [-1.615299335766548, 52.22999446396409],
            [-1.615109043459808, 52.229650411115855],
            [-1.61652498542733, 52.22929450167112],
            [-1.618092760595184, 52.228303445506832],
            [-1.622403872458674, 52.226992121534117],
            [-1.625330181911644, 52.226853098323446],
            [-1.625907509821958, 52.226270552568494],
            [-1.62638280775115, 52.224759872459586],
            [-1.625577057108931, 52.222563663947],
            [-1.624293325420759, 52.221336893065221],
            [-1.623862092790707, 52.221438910208455],
            [-1.621252344358939, 52.220370602021092],
            [-1.620314279793661, 52.218109200218279],
            [-1.621238002758512, 52.213792273614388],
            [-1.620527439755331, 52.213704583784619],
            [-1.619674466016441, 52.214012009406069],
            [-1.619211222566221, 52.214612874490989],
            [-1.618936603430507, 52.214378238009566],
            [-1.618561420793305, 52.214607181499339],
            [-1.618462708448896, 52.215024916876658],
            [-1.618950164258583, 52.215017500157558],
            [-1.618926395893227, 52.215229597212804],
            [-1.61796924767585, 52.215903483131783],
            [-1.618058928828935, 52.216198658681186],
            [-1.617536386637377, 52.216199664832935],
            [-1.617496438692805, 52.216590618352456],
            [-1.611862170079865, 52.219002344762458],
            [-1.610081207399793, 52.22045652089934],
            [-1.60780765985592, 52.220982124481019],
            [-1.605910133706522, 52.222350441513427],
            [-1.604014890489324, 52.222790026764677],
            [-1.603098320875697, 52.224616497478735],
            [-1.602034289630541, 52.225242245339061],
            [-1.601041544643243, 52.224934121636267],
            [-1.597288944115665, 52.225307973963133],
            [-1.59318865106214, 52.223510226192225],
            [-1.589915558388503, 52.223802782970665],
            [-1.588787232763109, 52.223604667162647],
            [-1.587118346989542, 52.224553626238972],
            [-1.586668005807499, 52.224178050161917],
            [-1.586392883071959, 52.224482760889934],
            [-1.585614804609518, 52.224402716085358],
            [-1.586069412617756, 52.224003338000671],
            [-1.585175422204923, 52.223637888038603],
            [-1.585023629775071, 52.222960376978548],
            [-1.58435323425015, 52.222638860051866],
            [-1.584822666471936, 52.222218860882137],
            [-1.584505180297967, 52.221572232845695],
            [-1.583639991514746, 52.221420844163937],
            [-1.583210187250479, 52.220890691892848],
            [-1.582727933390028, 52.221272879711712],
            [-1.581234261280023, 52.220863923483648],
            [-1.58126388611532, 52.220359666258794],
            [-1.580371910969961, 52.219015130307533],
            [-1.579322269981051, 52.218407240100227],
            [-1.579403173178833, 52.218055103830501],
            [-1.577606389279854, 52.217982162153305],
            [-1.576736479919837, 52.217258915006859],
            [-1.57533533163365, 52.217297938144263],
            [-1.575119237152568, 52.216320801062643],
            [-1.574409215766408, 52.216181590078627],
            [-1.573766331076244, 52.216368969511642],
            [-1.573502259060843, 52.216891258764441],
            [-1.572798938234886, 52.216968731499826],
            [-1.57261193592973, 52.218000155343795],
            [-1.5690512522157, 52.218089706870998],
            [-1.568396492443828, 52.217684544897935],
            [-1.568020282062429, 52.217989742823605],
            [-1.567427740272395, 52.21946919543209],
            [-1.567362109717223, 52.221554732194626],
            [-1.564652368538075, 52.224265274609536],
            [-1.563701976895086, 52.22563999789903],
            [-1.563147262148659, 52.228014112912689],
            [-1.56007064853148, 52.231632120557393],
            [-1.554308777765182, 52.230929046192053],
            [-1.555032814056974, 52.228217571728685],
            [-1.548550021729201, 52.227625675066861],
            [-1.542442931612463, 52.227817044331566],
            [-1.542336783482854, 52.228173552528318],
            [-1.539836517438735, 52.227853668150381],
            [-1.539692530633697, 52.228327801008383],
            [-1.535256649317507, 52.226476383924108],
            [-1.528209874438053, 52.225135842394366],
            [-1.525266911578639, 52.226681190506369],
            [-1.523494982248831, 52.229323531673295],
            [-1.521742275084707, 52.230292806671763],
            [-1.520783488406008, 52.235441326775934],
            [-1.519711868080451, 52.235960215869042],
            [-1.519320128889014, 52.238200830131632],
            [-1.517684546212729, 52.23980794046782],
            [-1.51657862466707, 52.239966145661505],
            [-1.516328031437009, 52.240790438898564],
            [-1.514736789122184, 52.241132739891178],
            [-1.514606484936287, 52.241532277442779],
            [-1.513000349590131, 52.242161287706296],
            [-1.512502466280708, 52.241754665719483],
            [-1.510964091324415, 52.242211312216675],
            [-1.509855923103838, 52.2416349291816],
            [-1.508699103462496, 52.241621132671071],
            [-1.507650548421998, 52.240822913708385],
            [-1.50656343372776, 52.241260705186086],
            [-1.504577524396084, 52.241000660761671],
            [-1.502196686018488, 52.24148512294051],
            [-1.500034571572885, 52.239572721012564],
            [-1.500629625822248, 52.238876683383495],
            [-1.502092049162892, 52.238270610321194],
            [-1.502117945598914, 52.23791919435044],
            [-1.500115881566314, 52.237548424233346],
            [-1.498929732132783, 52.238979167111367],
            [-1.498874694518953, 52.241006271271338],
            [-1.497574652839481, 52.240059452397595],
            [-1.497349097235887, 52.240191550803594],
            [-1.495087318274656, 52.242054606213273],
            [-1.491063433639892, 52.246991949565981],
            [-1.488749152800525, 52.249419253369304],
            [-1.486635175377249, 52.25274642157423],
            [-1.484964889477973, 52.254418556573199],
            [-1.48347821856957, 52.255280537304195],
            [-1.477668645061625, 52.26172537528015],
            [-1.47583618453646, 52.261186825967066],
            [-1.474671690024933, 52.262772951580196],
            [-1.472296536841419, 52.263887062799128],
            [-1.47117334411746, 52.263954862320411],
            [-1.469601555004229, 52.264778527298972],
            [-1.465578165395903, 52.263657274421966],
            [-1.463044636696893, 52.265122906404876],
            [-1.458938194366419, 52.270236779819143],
            [-1.45475446316263, 52.270819900412135],
            [-1.453427979500779, 52.271910597225144],
            [-1.450999148492967, 52.271829210484654],
            [-1.448517880569294, 52.271368134922568],
            [-1.444147701346493, 52.27207497586231],
            [-1.44113055637294, 52.271461982071344],
            [-1.438221129077114, 52.271841062736499],
            [-1.43522696079403, 52.272772575364442],
            [-1.434874120339879, 52.273897383132031],
            [-1.43610778174915, 52.274503835370169],
            [-1.435105787959749, 52.276426586129311],
            [-1.43967723593282, 52.278750790637275],
            [-1.439234899957054, 52.281746081811733],
            [-1.440533580072047, 52.281634464631452],
            [-1.440841580937802, 52.282203214911391],
            [-1.441939177568564, 52.282113106369096],
            [-1.4427714740455, 52.284125482293241],
            [-1.445890210459418, 52.287712918669136],
            [-1.447945627145227, 52.291369027379062],
            [-1.448484064224343, 52.294056958961953],
            [-1.443994618605421, 52.295327832799721],
            [-1.440922502582761, 52.294530278257071],
            [-1.440485756126185, 52.294165000929837],
            [-1.439444619597501, 52.294288628499125],
            [-1.435652578800311, 52.293618778138629],
            [-1.428086402480978, 52.295128740557608],
            [-1.426932395159404, 52.294222316014064],
            [-1.425749706779446, 52.294063738219386],
            [-1.424745616883413, 52.294267430487949],
            [-1.425270102019716, 52.294867841306385],
            [-1.426191661742469, 52.294924464110686],
            [-1.42605628970111, 52.295188123066509],
            [-1.424576183361826, 52.296480929730357],
            [-1.423994849366891, 52.296645317951302],
            [-1.42366300603453, 52.29645400344365],
            [-1.421813677509854, 52.297940064655201],
            [-1.422938257156384, 52.299079245003682],
            [-1.424174885292703, 52.299611212882397],
            [-1.424995084930549, 52.299349092516472],
            [-1.42626359779365, 52.299458647278058],
            [-1.426505202261103, 52.300735550208032],
            [-1.424016353678813, 52.301771719510548],
            [-1.42283841771937, 52.301684160029204],
            [-1.421799510131575, 52.302632956875797],
            [-1.422330412519683, 52.30296999412559],
            [-1.421492493602837, 52.303572743252225],
            [-1.420114923053488, 52.303383487397497],
            [-1.419419538409094, 52.303737888686051],
            [-1.414804362320841, 52.303115468057307],
            [-1.409031421866601, 52.30288355684921],
            [-1.408504082470244, 52.304158446931048],
            [-1.405763080264542, 52.303935220177962],
            [-1.398315558625731, 52.302435703205148],
            [-1.392246158990714, 52.301750145397193],
            [-1.389281047128844, 52.300884370092319],
            [-1.389773400956152, 52.300271973053633],
            [-1.383131906208332, 52.297096254537756],
            [-1.378958901452138, 52.294771062058722],
            [-1.379116531314283, 52.294508472501569],
            [-1.375673138641176, 52.293611069547914],
            [-1.370543761125541, 52.291366827169902],
            [-1.367822151638922, 52.289643224421241],
            [-1.368241179846372, 52.289147398299875],
            [-1.366234905874879, 52.288576552446862],
            [-1.36637601374397, 52.28803698689407],
            [-1.364573605376998, 52.287977859879923],
            [-1.363977161668015, 52.286424707075376],
            [-1.362530892863591, 52.285151065003078],
            [-1.362362555090553, 52.283397029214264],
            [-1.359269220809572, 52.2829073986167],
            [-1.360679512706017, 52.280964121619171],
            [-1.357007854920986, 52.280542324359963],
            [-1.357031425902373, 52.280128894007561],
            [-1.355186222769489, 52.280415520633468],
            [-1.354818033074135, 52.279121588985639],
            [-1.346573017046125, 52.277431926844955],
            [-1.346512430193498, 52.276269131043087],
            [-1.341037398065335, 52.274836212468095],
            [-1.338481728690474, 52.27313622462124],
            [-1.335712608043157, 52.271807184120021],
            [-1.335203991399327, 52.271224440252787],
            [-1.333845017611884, 52.271156548626493],
            [-1.32815750210539, 52.269403569439618],
            [-1.324798153303131, 52.267991796614425],
            [-1.322737498454208, 52.267722873306369],
            [-1.321882927038307, 52.267354756742172],
            [-1.321407027565823, 52.266659759772246],
            [-1.315752817702433, 52.267001144462533],
            [-1.315746710537638, 52.265684008880378],
            [-1.314748295603418, 52.265528080570554],
            [-1.31334597200803, 52.262776950825661],
            [-1.312557220666336, 52.262806526660356],
            [-1.309442559856534, 52.260703471799808],
            [-1.311642400520635, 52.259905373470659],
            [-1.309028523551875, 52.257458191780827],
            [-1.308902826668371, 52.257816175780022],
            [-1.308302287521067, 52.25780726610121],
            [-1.306233103229534, 52.258873086584174],
            [-1.305482515204948, 52.260121945588274],
            [-1.299492661349496, 52.264236568925043],
            [-1.298164174907731, 52.264574815350208],
            [-1.295743075413208, 52.264417456575039],
            [-1.293352414317406, 52.265388532694409],
            [-1.292504373248682, 52.267022416432518],
            [-1.290252575976354, 52.269103683528449],
            [-1.289767726695309, 52.270466418990281],
            [-1.288005441675683, 52.271063564474353],
            [-1.287816625771242, 52.273610319594361],
            [-1.28151367456739, 52.270039802141788],
            [-1.275478600609271, 52.267897373554121],
            [-1.273683481711043, 52.266820979112595],
            [-1.276530017833626, 52.266592109517084],
            [-1.27650007482371, 52.265190312672253],
            [-1.272521223741168, 52.263072892872813],
            [-1.27294093085196, 52.262495591748873],
            [-1.271361775730819, 52.261400710494669],
            [-1.2673357826131, 52.260668255006216],
            [-1.264987502850287, 52.259226875866041],
            [-1.259618157450469, 52.257183966380353],
            [-1.259492026345916, 52.256488211000573],
            [-1.253600608722976, 52.255647375965623],
            [-1.251291822389264, 52.255034894577577],
            [-1.247565050062175, 52.253381233283321],
            [-1.245048166403722, 52.252957901663848],
            [-1.239227755817868, 52.252719164626598],
            [-1.233013374233159, 52.253243573670034],
            [-1.232440447068998, 52.252402835326606],
            [-1.231946477468534, 52.252674733408746],
            [-1.231714183621712, 52.252299217363401],
            [-1.232354498210051, 52.252035463977769],
            [-1.232072217953104, 52.251670412285975],
            [-1.232592128435144, 52.251507467385906],
            [-1.232383354311938, 52.251212120944373],
            [-1.234718562412233, 52.250432515194404],
            [-1.235173844963067, 52.249255909665756],
            [-1.237780854377277, 52.248419565106659],
            [-1.240582244746274, 52.247945831749966],
            [-1.246695863372886, 52.24908452594692],
            [-1.250497232456185, 52.248286980287169],
            [-1.250993386025302, 52.248658738732217],
            [-1.251760014294464, 52.248625838288724],
            [-1.254493680258485, 52.247552578557844],
            [-1.258083646164255, 52.244659565899553],
            [-1.264812942549058, 52.241866045249793],
            [-1.266165933364096, 52.240815378939054],
            [-1.266782946351812, 52.239454452384557],
            [-1.266079341460283, 52.238872890833484],
            [-1.267635004373332, 52.237423389132964],
            [-1.266367207835941, 52.236689985133204],
            [-1.266905284474608, 52.235227872938466],
            [-1.275386655526173, 52.238016933525238],
            [-1.279983994888397, 52.238617749383629],
            [-1.284657901683442, 52.238562543059388],
            [-1.283986022125906, 52.237087624797994],
            [-1.28272754595129, 52.236305005844223],
            [-1.280161122195932, 52.233370170677553],
            [-1.278632015024781, 52.230052329707362],
            [-1.27844613959121, 52.228692726624523],
            [-1.27260777776374, 52.227496228476099],
            [-1.273524745336218, 52.226750265893322],
            [-1.275689949601537, 52.226307748119837],
            [-1.276922153679865, 52.225167210346143],
            [-1.27637415198473, 52.223123902413064],
            [-1.275290108442328, 52.221999730719077],
            [-1.273795992147179, 52.221339635913324],
            [-1.273379644072299, 52.220659188118205],
            [-1.27225034974014, 52.220252153999901],
            [-1.270970313689197, 52.219218436915604],
            [-1.267753717699826, 52.217742963086991],
            [-1.264166873123734, 52.216542002501349],
            [-1.260931167462049, 52.215978762579716],
            [-1.259875830050937, 52.211839195039204],
            [-1.257322395584629, 52.208562284140534],
            [-1.257374691373369, 52.205600222631212],
            [-1.254794581742459, 52.198875199303629],
            [-1.2549966176751, 52.197817384560786],
            [-1.262553719939744, 52.195948046721384],
            [-1.268017594476703, 52.196516993965943],
            [-1.271437955640849, 52.196353847274324],
            [-1.274088672099355, 52.195385710154859],
            [-1.277587432275898, 52.195794667807995],
            [-1.281151906137, 52.195761585783238],
            [-1.28120915139147, 52.191939143548502],
            [-1.283657853858109, 52.191587213969569],
            [-1.290801461607985, 52.192119437079981],
            [-1.294907690904293, 52.191666637220472],
            [-1.300608569846626, 52.191760789578858],
            [-1.308872682007471, 52.189765011252327],
            [-1.313148166394612, 52.190453470529121],
            [-1.314400014928501, 52.188098028403026],
            [-1.316174693013207, 52.186645558147482],
            [-1.317460252094215, 52.184841399766],
            [-1.318208160270056, 52.18299186697719],
            [-1.318204656851419, 52.181403212816321],
            [-1.319959119541167, 52.181346804057341],
            [-1.322826355228648, 52.181786744791069],
            [-1.326522460895178, 52.178383383972026],
            [-1.326850440257287, 52.178454482401577],
            [-1.328652043474487, 52.177006472746129],
            [-1.331495765751834, 52.174123153348575],
            [-1.332346181915166, 52.17231007222302],
            [-1.331130033806272, 52.171402329975436],
            [-1.332305615325468, 52.170526111452141],
            [-1.331604403618779, 52.169507105421175],
            [-1.331910882299326, 52.168471327252981],
            [-1.333101949357881, 52.167335360275956],
            [-1.333961544179213, 52.165383862660946],
            [-1.334019250745968, 52.159067396852436],
            [-1.33468203324756, 52.159322872166683],
            [-1.335827100304321, 52.158997569289525],
            [-1.336443354941059, 52.159433483822149],
            [-1.337160708128982, 52.158870208576488],
            [-1.338250362119142, 52.158829572898732],
            [-1.342357603385749, 52.154891275627946],
            [-1.343472572042905, 52.153221635832473],
            [-1.341624741213661, 52.150666999060988],
            [-1.342824133555609, 52.148717328346159],
            [-1.34105444756872, 52.144843292930652],
            [-1.343387300615591, 52.144210771575771],
            [-1.346842998597326, 52.142336527345321],
            [-1.349950463237249, 52.141578696902066],
            [-1.350269367036314, 52.14085131422663],
            [-1.349128774838729, 52.138502071878989],
            [-1.348326803314436, 52.137787385811244],
            [-1.349394573137955, 52.135902549949712],
            [-1.348722213753723, 52.135119352979324],
            [-1.349763718202886, 52.13483290210781],
            [-1.352088781713983, 52.134793547502852],
            [-1.355745843898257, 52.136001235196829],
            [-1.357672885294109, 52.135296987343892],
            [-1.357792669704927, 52.135501726578447],
            [-1.359837319238501, 52.135048921927989],
            [-1.36002889011396, 52.135444649573266],
            [-1.362834744086181, 52.134784639812281],
            [-1.364062577416471, 52.136694578692705],
            [-1.369614940080988, 52.134710475968511],
            [-1.370179939203197, 52.133081695577445],
            [-1.371163677607222, 52.13325326935982],
            [-1.375212781031277, 52.13090125670319],
            [-1.382742059094028, 52.127529846039089],
            [-1.383778695592219, 52.128203268503299],
            [-1.384425911497106, 52.127985477844618],
            [-1.384683056052615, 52.128406681383218],
            [-1.385292292296081, 52.128188687893605],
            [-1.385476505379011, 52.128390138450996],
            [-1.386842486053196, 52.126039004703458],
            [-1.387569307217088, 52.125978047403315],
            [-1.390105724292147, 52.122723992771512],
            [-1.389187334485674, 52.121950542451557],
            [-1.391439665669482, 52.121515348075491],
            [-1.392151701058851, 52.120709862074385],
            [-1.392438371011674, 52.120143130800201],
            [-1.392092639762436, 52.119682827460238],
            [-1.393316282593204, 52.11895009642496],
            [-1.39276483520446, 52.11761394797233],
            [-1.39213337586601, 52.117009222278782],
            [-1.390620657483685, 52.116773055670748],
            [-1.385393286728278, 52.117445409083082],
            [-1.381623636210979, 52.116933022111731],
            [-1.37901299496614, 52.117518984362341],
            [-1.377703362806374, 52.118119850599584],
            [-1.374597246941913, 52.118305714100138],
            [-1.370856297569952, 52.11752161752058],
            [-1.371412044428448, 52.117155064570149],
            [-1.371069537031754, 52.116690220717473],
            [-1.370638438313539, 52.116709499111579],
            [-1.371455230149881, 52.116160929126671],
            [-1.37075772982975, 52.115497297057018],
            [-1.371060825270467, 52.115129397737775],
            [-1.370340565757825, 52.115357514178633],
            [-1.370163518542661, 52.115070666366194],
            [-1.370255660026762, 52.114231431535949],
            [-1.370910681701066, 52.114383272251651],
            [-1.370590187704121, 52.113808858178373],
            [-1.370160003066939, 52.113765205089209],
            [-1.370371317142234, 52.113173849183369],
            [-1.369830831596951, 52.112978563118752],
            [-1.370380832927884, 52.112705486951064],
            [-1.369535681333381, 52.112472610351219],
            [-1.369572365823173, 52.112045750326914],
            [-1.36910417579861, 52.112316563130847],
            [-1.36795435017887, 52.111634310611315],
            [-1.367244922246012, 52.111925400818848],
            [-1.367252142421662, 52.111621555371592],
            [-1.366323478770281, 52.111719962871071],
            [-1.365197880207909, 52.111289551845374],
            [-1.364831353378143, 52.110570124274417],
            [-1.365409114574545, 52.110604701193978],
            [-1.365072416527779, 52.110355646246973],
            [-1.365306566885857, 52.110010765965896],
            [-1.363020800475728, 52.10892046464997],
            [-1.363158925235707, 52.108039226426193],
            [-1.362392984846191, 52.107789643546269],
            [-1.362329329360641, 52.108055423098754],
            [-1.361376584241439, 52.107492847524718],
            [-1.361238801672246, 52.107223280583604],
            [-1.36175701288374, 52.107334872744154],
            [-1.361297264349537, 52.106912520123643],
            [-1.361624964560703, 52.106663454509942],
            [-1.361242065272311, 52.106585860033306],
            [-1.36138956010783, 52.106277379729882],
            [-1.360632517293757, 52.106123134501644],
            [-1.360993756050307, 52.105876949603889],
            [-1.359680952614097, 52.105686420668889],
            [-1.359081588241932, 52.105228237604855],
            [-1.359736494223363, 52.104968367705681],
            [-1.358917637758232, 52.104952232144136],
            [-1.35931641767292, 52.10463343194089],
            [-1.358419097459178, 52.104286009992883],
            [-1.35899163318348, 52.104067952298436],
            [-1.359476255126223, 52.104279168534909],
            [-1.358381118286775, 52.102964174584123],
            [-1.358529819676003, 52.102572090951021],
            [-1.35892501586357, 52.10260390877265],
            [-1.358534257688519, 52.102364430518818],
            [-1.35883973647471, 52.102238423878994],
            [-1.357784539633418, 52.101298552093908],
            [-1.359356877311992, 52.101299011143375],
            [-1.360591041525161, 52.100955970800847],
            [-1.362569039184528, 52.101071867942778],
            [-1.366169293204771, 52.099932371902746],
            [-1.367587443407014, 52.099993029193847],
            [-1.371567018298389, 52.099063086362314],
            [-1.374438211660408, 52.099713989753894],
            [-1.378321776901101, 52.096751417935032],
            [-1.377714138560385, 52.096043344668878],
            [-1.385414310827558, 52.094133642499152],
            [-1.385745250714661, 52.095226834365924],
            [-1.387160992586697, 52.096093707258483],
            [-1.387567735972289, 52.097412054110897],
            [-1.390912523918275, 52.0988849631077],
            [-1.395550523566448, 52.101689634001609],
            [-1.396730119823561, 52.101908751545288],
            [-1.397888723357095, 52.102712142844076],
            [-1.398023503354995, 52.103328690748512],
            [-1.398933087465189, 52.103436721784796],
            [-1.400352011703532, 52.104322330618274],
            [-1.400628495587306, 52.105044786217654],
            [-1.401992465280205, 52.105569570547047],
            [-1.402245815643493, 52.106277519812508],
            [-1.402646868775798, 52.106312816866591],
            [-1.402811108047907, 52.10888407468164],
            [-1.403907856387148, 52.110077286090124],
            [-1.411472242957368, 52.113036326315523],
            [-1.412919501587746, 52.114252778057164],
            [-1.413586130191258, 52.114310037516191],
            [-1.414430196674622, 52.115426371499147],
            [-1.417795875782312, 52.116923786369284],
            [-1.419642938858345, 52.117628761873412],
            [-1.424975661111163, 52.118264439481074],
            [-1.428465884272876, 52.117946948642761],
            [-1.43528444724541, 52.116704015570491],
            [-1.437083857729538, 52.116691037788883],
            [-1.443196187690018, 52.115248280247222],
            [-1.444756407199948, 52.115205286334685],
            [-1.44786025619755, 52.11610722789635],
            [-1.448753223162719, 52.116053863811544],
            [-1.453471204659444, 52.1129956290264],
            [-1.449234258157087, 52.108301698179595],
            [-1.448074382895187, 52.10514865701689],
            [-1.448341023486162, 52.100457690520606],
            [-1.446991512087509, 52.098850137959275],
            [-1.447200967118291, 52.097612208879049],
            [-1.453620335350231, 52.09732295092747],
            [-1.453547211550096, 52.098529156491558],
            [-1.458219392155707, 52.097987894500193],
            [-1.458531465195454, 52.097653077470291],
            [-1.459750567942487, 52.097518413375298],
            [-1.465094820091205, 52.097402515476496],
            [-1.466943441323706, 52.09772645548346],
            [-1.467571988327622, 52.097043308516596],
            [-1.470750992549153, 52.097941394638696],
            [-1.471788635319264, 52.099449279975346],
            [-1.474153031014913, 52.100989150361613],
            [-1.477116780243291, 52.101352959455269],
            [-1.47827643869166, 52.099434099173514],
            [-1.480160404735511, 52.093464542897316],
            [-1.484757924456453, 52.094135637356153],
            [-1.487816704519904, 52.093943972244155],
            [-1.488155133367995, 52.09281441904168],
            [-1.486832056289282, 52.091469964013704],
            [-1.484745428571317, 52.091290944286115],
            [-1.48561989883581, 52.08772008074272],
            [-1.487802820089107, 52.08776014855632],
            [-1.488917920093951, 52.086221291504252],
            [-1.489965800188927, 52.086220433058536],
            [-1.490412004807211, 52.084715528927056],
            [-1.490752023692424, 52.084716997492833],
            [-1.490572851556159, 52.083790186719106],
            [-1.494378039383314, 52.082181050819742],
            [-1.493700423373523, 52.081580265503327],
            [-1.493304168494904, 52.079587133619924],
            [-1.490854182084871, 52.077531207563304],
            [-1.490677899630392, 52.077122270386681],
            [-1.492506921276781, 52.075255601789365],
            [-1.493801418173313, 52.074712734539354],
            [-1.495697375655246, 52.074525759606381],
            [-1.496324848691218, 52.07412925556806],
            [-1.496821511427801, 52.07433636278671],
            [-1.499102052862378, 52.073939692747587],
            [-1.50082782431655, 52.072915780662484],
            [-1.501867979207593, 52.071613834957887],
            [-1.500082045867059, 52.069930417407321],
            [-1.502631961768344, 52.069163506127879],
            [-1.501847307555917, 52.066258005275657],
            [-1.499278347861285, 52.063522051146343],
            [-1.497251497722174, 52.060857579978155],
            [-1.497366336675364, 52.058942154408015],
            [-1.499648812498493, 52.052423720368083],
            [-1.501853444508575, 52.049569517591323],
            [-1.503474753294789, 52.04353640115869],
            [-1.504957530248292, 52.041428021236257],
            [-1.506912274710967, 52.037221365809685],
            [-1.507493088471557, 52.0353087705058],
            [-1.507413817276412, 52.033368241665201],
            [-1.510129449366427, 52.02670214385163],
            [-1.510222898139598, 52.025485186883657],
            [-1.510351255903359, 52.025475830286503],
            [-1.510960763442643, 52.021437923224831],
            [-1.513585111918805, 52.01837214948209],
            [-1.515593582620392, 52.014418198251825],
            [-1.52117796001389, 52.005874624426511],
            [-1.521624290161422, 52.001585145335433],
            [-1.522840128144793, 51.996833057418186],
            [-1.524942481832066, 51.993196689710423],
            [-1.525402410432765, 51.992811040156724],
            [-1.526877910446137, 51.992517579763543],
            [-1.527943370188648, 51.991744149772998],
            [-1.529369498833412, 51.992265924100003],
            [-1.529144416993925, 51.992620160810233],
            [-1.534769981164298, 51.994349849870069],
            [-1.536015088607455, 51.995350935791926],
            [-1.539913948424656, 51.996357003006942],
            [-1.541755764160268, 51.996421719298517],
            [-1.543943078662077, 51.996313323274606],
            [-1.546846234426128, 51.994690898456263],
            [-1.549689582527535, 51.993928590189626],
            [-1.550629123007505, 51.9933315927289],
            [-1.553574898481008, 51.992359197168433],
            [-1.554049993075137, 51.99188718212033],
            [-1.555594100888796, 51.991849858846052],
            [-1.556207542294828, 51.991523108097766],
            [-1.556331779857989, 51.991772620211016],
            [-1.557237408996481, 51.99164745785562],
            [-1.556699732907883, 51.989315028360686],
            [-1.554090560898102, 51.986626832769076],
            [-1.553366939251874, 51.985737601685386],
            [-1.553631896231374, 51.985597450248378],
            [-1.549685253459091, 51.980849686243737],
            [-1.563552882341245, 51.977030427149991],
            [-1.568654367006754, 51.97626161711166],
            [-1.580586501302438, 51.972986186827583],
            [-1.588420240419713, 51.974215875662814],
            [-1.600314582358589, 51.976931574213253],
            [-1.596701719865982, 51.974267883643776],
            [-1.595811634464341, 51.972553887243762],
            [-1.591647071901118, 51.970440180020034],
            [-1.594302780299281, 51.968117140483244],
            [-1.594733795094437, 51.968260678150997],
            [-1.59693009790913, 51.964384185852751],
            [-1.605801197397692, 51.959563657398377],
            [-1.612355456656021, 51.955393886443474],
            [-1.613349781407313, 51.955850290250787],
            [-1.613621913540317, 51.95567945841438],
            [-1.615284713380958, 51.956925630757944],
            [-1.615878035149717, 51.956632667872761],
            [-1.616659391951026, 51.956984954831221],
            [-1.617251694433092, 51.957494860845742],
            [-1.61622774111088, 51.957943768014296],
            [-1.617574876041645, 51.959192475178348],
            [-1.617996536586296, 51.959065274366473],
            [-1.619905162718862, 51.960699680645561],
            [-1.620298405947768, 51.960664086124858],
            [-1.62191547274996, 51.961854272991637],
            [-1.621051843276437, 51.962269571084029],
            [-1.622624541922418, 51.963379587017783],
            [-1.623581685953912, 51.964833762627258],
            [-1.626297264357921, 51.967501881553304],
            [-1.62973596588696, 51.969896193282125],
            [-1.632371997823982, 51.970446589935442],
            [-1.632836559547936, 51.970956017412924],
            [-1.633313161846568, 51.970882877780362],
            [-1.63297549086385, 51.971412282822399],
            [-1.634144072330783, 51.97181151065616],
            [-1.634315337397229, 51.972410828172904],
            [-1.634871552736207, 51.972215654626822],
            [-1.635023234386037, 51.972538893457006],
            [-1.636540343365984, 51.972869048116664],
            [-1.635924979818463, 51.973716777313399],
            [-1.638327011279961, 51.975161798109191],
            [-1.643105132793452, 51.977893375019647],
            [-1.656593180444142, 51.983531992848206],
            [-1.657346403777898, 51.984430563341931],
            [-1.665766266163563, 51.987478328601249],
            [-1.669290667285042, 51.989208199007265],
            [-1.669900069534416, 51.993079522948562],
            [-1.665363336735798, 51.997586375827332],
            [-1.661112753411494, 51.999377774749604],
            [-1.659057076656594, 51.999816888795884],
            [-1.657448723386159, 52.000996305741921],
            [-1.65536921212359, 52.003388072553648],
            [-1.653033835901449, 52.004550916088668],
            [-1.65155930972577, 52.005713563450321],
            [-1.64861571181911, 52.006754044303271],
            [-1.644501813792574, 52.008752036714526],
            [-1.642850558567889, 52.009192086714151],
            [-1.641948729525265, 52.010288017386273],
            [-1.642174471885334, 52.011395460302481],
            [-1.643314355795851, 52.012888676232457],
            [-1.642624189537832, 52.015300592989533],
            [-1.64020931103795, 52.018809515182781],
            [-1.638401782693212, 52.020688437656268],
            [-1.635275677576493, 52.021970773211777],
            [-1.62841793566263, 52.02719543470139],
            [-1.627046433846798, 52.027370019453194],
            [-1.624563724089424, 52.026393842104696],
            [-1.620970433874793, 52.027674309337563],
            [-1.620158375752946, 52.027700466336064],
            [-1.618746027806715, 52.029074182044305],
            [-1.618487152495071, 52.030549617001967],
            [-1.617543981955267, 52.031586784856543],
            [-1.617729285333318, 52.031907454437004],
            [-1.617548400487436, 52.0326072428253],
            [-1.618017477602105, 52.033155397387105],
            [-1.617519920223611, 52.033383047120076],
            [-1.618919964827947, 52.034164375762408],
            [-1.619280823330653, 52.034928850904052],
            [-1.620555107655962, 52.034387223562199],
            [-1.621185385958335, 52.034668860471477],
            [-1.620495455468013, 52.034890510130055],
            [-1.620622279327633, 52.035235261566818],
            [-1.621473733581184, 52.035220915599908],
            [-1.622575964173196, 52.036067775087254],
            [-1.624239645435779, 52.036200756067721],
            [-1.624193069168399, 52.036713975723046],
            [-1.625062719317731, 52.037142003413038],
            [-1.624741568701698, 52.037367547060747],
            [-1.625074328241031, 52.037671591542761],
            [-1.624114053420358, 52.038488484652902],
            [-1.624430755377468, 52.038969596113446],
            [-1.626695921664079, 52.038490392881876],
            [-1.626891945993597, 52.037884142293152],
            [-1.628506651744839, 52.036899366156774],
            [-1.629120214062028, 52.037273511760255],
            [-1.632224940208915, 52.035905863188276],
            [-1.63343160302814, 52.03648772130218],
            [-1.634018233683089, 52.036414920776437],
            [-1.634880999386556, 52.036983109178294],
            [-1.635546956672159, 52.036657009423578],
            [-1.636017107699209, 52.036925484779722],
            [-1.635701764443462, 52.037160965911617],
            [-1.6365935436718, 52.037031554515096],
            [-1.636655844607733, 52.037439024309862],
            [-1.637042240850003, 52.03743032449416],
            [-1.637019653088557, 52.036978922821611],
            [-1.637928714036208, 52.037055441100321],
            [-1.638516953524865, 52.036783030020821],
            [-1.639265608364004, 52.037227664044771],
            [-1.64338196503282, 52.035279316994639],
            [-1.644015096992116, 52.03522099243267],
            [-1.643706097429949, 52.035765793568359],
            [-1.644473180189796, 52.035545139864034],
            [-1.64423021505261, 52.034565321847914],
            [-1.645269402192423, 52.034588231994853],
            [-1.645387475003922, 52.034219969137034],
            [-1.64552608751037, 52.034757129625838],
            [-1.64613463398006, 52.034672646797965],
            [-1.646351537013906, 52.035080575363544],
            [-1.64810801386449, 52.03398087513937],
            [-1.649340151228435, 52.034307311009464],
            [-1.649365289407557, 52.034634646564662],
            [-1.650279854033094, 52.034562739746256],
            [-1.650233437918331, 52.034906945160337],
            [-1.650980542804196, 52.035186970756811],
            [-1.651450764279043, 52.034893467223171],
            [-1.6516725228504, 52.0352456580585],
            [-1.652206796390643, 52.034963129512853],
            [-1.652265093328167, 52.035340909983631],
            [-1.653562120105679, 52.035390579969238],
            [-1.655587239405677, 52.034784246678875],
            [-1.656452784521142, 52.032165987799594],
            [-1.657673074050952, 52.031754167016622],
            [-1.658264563471312, 52.031986943182176],
            [-1.659105545593974, 52.031614465819452],
            [-1.659965766538943, 52.031971182351235],
            [-1.660374369477075, 52.03171792353055],
            [-1.66009475962181, 52.031491451751144],
            [-1.66107782952504, 52.031602169490789],
            [-1.663275725056563, 52.031632740795793],
            [-1.663879029787005, 52.031264944888981],
            [-1.664851921458348, 52.031763100540125],
            [-1.667144981811401, 52.036060846001192],
            [-1.668890567242847, 52.036570136798211],
            [-1.670662183934521, 52.036716251159042],
            [-1.672104547458834, 52.036236572091155],
            [-1.673338827885879, 52.036301133996595],
            [-1.673359755979972, 52.036029673624014],
            [-1.675418988681728, 52.036518158326494],
            [-1.675317693082437, 52.036817268878899],
            [-1.675786726543181, 52.036668415024685],
            [-1.675977230874524, 52.036935062483771],
            [-1.676409029099464, 52.036693499924809],
            [-1.677826749046406, 52.036997669844972],
            [-1.677621655632088, 52.036730985511582],
            [-1.678215023604462, 52.036522224308399],
            [-1.678359112773213, 52.036961362144247],
            [-1.678631820083262, 52.036744531337582],
            [-1.679168140028557, 52.036969859297486],
            [-1.679737839288017, 52.036808676604466],
            [-1.680990522502565, 52.03736409890395],
            [-1.682823411233141, 52.037307905985465],
            [-1.68308284559576, 52.037522581358928],
            [-1.683314626271419, 52.037318216708151],
            [-1.683719526560055, 52.037785019929373],
            [-1.685710853206342, 52.037381267997574],
            [-1.685910135051721, 52.037652418025473],
            [-1.686206059519173, 52.037446420381606],
            [-1.687425319253256, 52.037791303549227],
            [-1.687461097906576, 52.037468633378218],
            [-1.688022967584048, 52.037800977606771],
            [-1.68870280923673, 52.037730848667287],
            [-1.689358074042627, 52.038262126723133],
            [-1.689777018915084, 52.038184111580428],
            [-1.691242115095626, 52.039461933549646],
            [-1.691868270550888, 52.039357481262869],
            [-1.691862680442004, 52.039746762427804],
            [-1.693992799149043, 52.039515853945694],
            [-1.695013838500754, 52.040512866014041],
            [-1.694807878800951, 52.042927224660353],
            [-1.693052868605382, 52.044994117850024],
            [-1.691175907308155, 52.048230348946177],
            [-1.690618636337462, 52.051435853019534],
            [-1.688648733521048, 52.051820863712528],
            [-1.690024799749264, 52.053168589409701],
            [-1.690191972072251, 52.054723510734547],
            [-1.692370343036029, 52.054366889176485],
            [-1.699573071925575, 52.054617412245399],
            [-1.702514261953761, 52.05621351210619],
            [-1.702998814258448, 52.05748151366241],
            [-1.705153025041201, 52.059694118019507],
            [-1.705451539164527, 52.061544236227419],
            [-1.704060372958546, 52.063257964256842],
            [-1.703372822204857, 52.067523186920077],
            [-1.70159076273218, 52.070279711385425],
            [-1.701743240585519, 52.07279656966881],
            [-1.708004281843783, 52.072488569613647],
            [-1.709893995647061, 52.073336550582589],
            [-1.712231034946229, 52.070654081329394],
            [-1.712682168692067, 52.068327510752162],
            [-1.711823660942896, 52.066623492934106],
            [-1.71254463919548, 52.066570409059707],
            [-1.714291329533233, 52.066715804719294],
            [-1.717082854330854, 52.068195194984646],
            [-1.720686955831284, 52.068771090321164],
            [-1.723003479375713, 52.069500296089316],
            [-1.724236000085826, 52.069537347622173],
            [-1.72713180666273, 52.070745225994642],
            [-1.727836995737349, 52.07137799554345],
            [-1.728792977367078, 52.071556412758632],
            [-1.728164315424447, 52.072735432812053],
            [-1.730682976089113, 52.073386734128768],
            [-1.728169903477389, 52.081432952068717],
            [-1.729078609331596, 52.081501572316249],
            [-1.729463036531043, 52.084053084982209],
            [-1.729140128059127, 52.08411797552386],
            [-1.72911175267218, 52.085431435292953],
            [-1.728082545596742, 52.088370787535943],
            [-1.728096552596821, 52.088707966651199],
            [-1.728859632034795, 52.088747482872215],
            [-1.728409816528698, 52.090960828421665],
            [-1.728694259388157, 52.091712195971205],
            [-1.729275114409602, 52.091964367036304],
            [-1.730176614418193, 52.091569048197456],
            [-1.730519666696686, 52.092773670248121],
            [-1.730201244304383, 52.095232763649399],
            [-1.729646495772431, 52.095242282786778],
            [-1.728554053443905, 52.09798549515147],
            [-1.728571843369245, 52.100107309696696],
            [-1.728773722723523, 52.099556652188774],
            [-1.729839900551585, 52.098992692000245],
            [-1.730229933061961, 52.097981246377813],
            [-1.732210973233458, 52.096990502242519],
            [-1.732898478112293, 52.095041106531262],
            [-1.738552775752942, 52.091395503928588],
            [-1.740282130457369, 52.091439780044503],
            [-1.742415875128555, 52.09197759762516],
            [-1.742408598922214, 52.092227519712559],
            [-1.746033118020132, 52.092947428530216],
            [-1.75018811646351, 52.094884773858112],
            [-1.751328565720706, 52.095859961706338],
            [-1.75180153620232, 52.096910156364316],
            [-1.753550618575012, 52.097654644083796],
            [-1.754848235833224, 52.099279243940636],
            [-1.754138512005909, 52.100917645867021],
            [-1.755052194920801, 52.10122342642368],
            [-1.755553370113536, 52.101947306266339],
            [-1.755991496626152, 52.101920342084796],
            [-1.757694423309217, 52.103149262984459],
            [-1.758877364089397, 52.106062826315032],
            [-1.762538284786122, 52.107337915126251],
            [-1.763722174993379, 52.108492878849248],
            [-1.766232094904769, 52.109624391214254],
            [-1.767659411536878, 52.112579702797383],
            [-1.759217923560355, 52.114950673661653],
            [-1.757409065628995, 52.115931434004082],
            [-1.757669761300756, 52.11606323126081],
            [-1.759420752167489, 52.115523784344532],
            [-1.760296073573768, 52.116237615329467],
            [-1.762470168844302, 52.115219786000644],
            [-1.763233783294787, 52.116353228309578],
            [-1.764642680122351, 52.115872354847596],
            [-1.769710237679734, 52.115301560447755],
            [-1.770599829876203, 52.11611154139046],
            [-1.773102481317893, 52.116778980044423],
            [-1.775115135904484, 52.118191647080877],
            [-1.778432931359736, 52.118267147244417],
            [-1.780709108322175, 52.119003225865413],
            [-1.78063785924422, 52.120130505498523],
            [-1.787779905712466, 52.11949803905344],
            [-1.791014654571534, 52.120180795268546],
            [-1.793328111480222, 52.121765401084559],
            [-1.792880965540288, 52.12212603653348],
            [-1.793001770240729, 52.12284908618112],
            [-1.793898095235736, 52.122955843155637],
            [-1.793923170134631, 52.123854039713848],
            [-1.79482224593901, 52.12432131417124],
            [-1.795775997186678, 52.124334655218249],
            [-1.793328610093183, 52.125451514430459],
            [-1.793135204845958, 52.126527340364184],
            [-1.792247297307829, 52.127109265851423],
            [-1.792780140624178, 52.127811462090882],
            [-1.791689840229326, 52.128559352385906],
            [-1.790282774500695, 52.128938059460616],
            [-1.790255820220962, 52.129390234246415],
            [-1.789218013259986, 52.129831618414748],
            [-1.789935195873726, 52.1304730206421],
            [-1.786592917990479, 52.13135439072083],
            [-1.786280925876246, 52.13183212142814],
            [-1.785558213452901, 52.132045684283547],
            [-1.799679511567259, 52.136263653228632],
            [-1.801530448664669, 52.136970734235561],
            [-1.802355991343983, 52.137623929647738],
            [-1.808443557409764, 52.139862703357778],
            [-1.810898134878224, 52.13990711601199],
            [-1.81732977446869, 52.138583049147762],
            [-1.823226868368803, 52.136369571903799],
            [-1.824863455583043, 52.137436484280649],
            [-1.82450745672699, 52.137679598267809],
            [-1.825096859922972, 52.138278340194127],
            [-1.82462175770081, 52.138711876493566],
            [-1.827854308140063, 52.141232170349042],
            [-1.825452779661867, 52.141799542128567],
            [-1.825313377200349, 52.142697485569279],
            [-1.823357198378566, 52.145912270300187],
            [-1.828683733034384, 52.146332777981563],
            [-1.831478962061258, 52.145661613440865],
            [-1.831013068168238, 52.148294258835726],
            [-1.833552231286077, 52.148124347342254],
            [-1.837529220129962, 52.15042425218185],
            [-1.839327819436488, 52.151790566133279],
            [-1.839681175623064, 52.152700881544021],
            [-1.848239314807942, 52.14996020066431],
            [-1.847571588312188, 52.148621558907891],
            [-1.851912850210752, 52.1479572890348],
            [-1.850979604709504, 52.146458302621781],
            [-1.846917429914229, 52.147173246823144],
            [-1.845819461007445, 52.142641516731985],
            [-1.847303793382176, 52.141875657094239],
            [-1.84834649652095, 52.140334232845198],
            [-1.849845981279502, 52.13937146875346],
            [-1.854197185328157, 52.138508437067756],
            [-1.859716924560545, 52.140496622158075],
            [-1.863275084422857, 52.142833822704162],
            [-1.866971138713063, 52.144627146231855],
            [-1.865733804539055, 52.145951842327719],
            [-1.863886289601501, 52.147025888824125],
            [-1.866810304990738, 52.150055412965443],
            [-1.866115383168989, 52.150291976199938],
            [-1.866936016054319, 52.152995437281284],
            [-1.869084103814813, 52.15315337107657],
            [-1.876162402128761, 52.154826931242035],
            [-1.878908266658295, 52.155045546819196],
            [-1.880648195869012, 52.154840535580448],
            [-1.880907070627685, 52.155328978639787],
            [-1.882133178116111, 52.155419212927697],
            [-1.882665262297089, 52.155963664975395],
            [-1.883835047817295, 52.155798496615191],
            [-1.884300256092087, 52.156212513872561],
            [-1.885485206987954, 52.156404265188399],
            [-1.886535480813059, 52.15610229928506],
            [-1.889111877197873, 52.156336697328165],
            [-1.891874830482821, 52.154992485298578],
            [-1.892075668008933, 52.154142171219341],
            [-1.89386114492386, 52.151948316307696],
            [-1.893515366659541, 52.151076829396644],
            [-1.895053461789642, 52.150838158976129],
            [-1.894862196835518, 52.149510996172239],
            [-1.893847208421526, 52.147965526794643],
            [-1.893338129169647, 52.148175445697142],
            [-1.891412574356714, 52.147918363738569],
            [-1.890483770912813, 52.148839929337136],
            [-1.889737999966729, 52.148419381308173],
            [-1.888385737094777, 52.148588931451187],
            [-1.887065314132115, 52.148299083492745],
            [-1.886300356730566, 52.148543790164524],
            [-1.885016998657664, 52.147485269625662],
            [-1.885126798280536, 52.146840759504762],
            [-1.888261054344301, 52.143505599864852],
            [-1.890586011489363, 52.141692596596272],
            [-1.892912385625828, 52.138597505302158],
            [-1.900224447563516, 52.1357134598084],
            [-1.901268310644004, 52.134739766880742],
            [-1.902612054966817, 52.134241009833772],
            [-1.903246400421323, 52.133435982483213],
            [-1.903561837106315, 52.133496476423751],
            [-1.902436094343734, 52.135877133896741],
            [-1.902196861915597, 52.137710995620353],
            [-1.904192192568861, 52.140007000279113],
            [-1.904365747116724, 52.141527431020641],
            [-1.905108433440401, 52.142724661892295],
            [-1.907331012985889, 52.142724625964007],
            [-1.910894686719242, 52.144366327776474],
            [-1.913440590208099, 52.144971478691609],
            [-1.916886127185938, 52.146703716003984],
            [-1.918388017066125, 52.149340766777584],
            [-1.921895040130344, 52.152994160532138],
            [-1.933617745188552, 52.153515568016054],
            [-1.940084579005893, 52.15302993445399],
            [-1.942134916528305, 52.1543840175231],
            [-1.944310139942165, 52.155320966204009],
            [-1.941989419306494, 52.157257296006577],
            [-1.939234747351899, 52.15889487482692],
            [-1.938969297740615, 52.159530363242297],
            [-1.939534871824609, 52.159639437664623],
            [-1.939700624778066, 52.160303916341775],
            [-1.937743305082892, 52.161298146618648],
            [-1.937306193984783, 52.163396286160022],
            [-1.934126481881282, 52.165381448494458],
            [-1.934964803328987, 52.165979776047983],
            [-1.934561531083513, 52.167812704453034],
            [-1.935350445612945, 52.168235688186414],
            [-1.935490383632018, 52.169544771933118],
            [-1.943482166277361, 52.170922591432841],
            [-1.945553323908721, 52.170343677882201],
            [-1.947223029227881, 52.167934996305853],
            [-1.951376933533723, 52.169178351304595],
            [-1.96041480458106, 52.168659365450928],
            [-1.960455005809657, 52.169497287355604],
            [-1.96197312910044, 52.170724078114908],
            [-1.961676239381933, 52.172516674606285],
            [-1.960682070452034, 52.174012356972412],
            [-1.956115226328372, 52.174138420978373],
            [-1.949416359484183, 52.172942724293357],
            [-1.946693950734021, 52.173916993259851],
            [-1.946188893268614, 52.174391459683136],
            [-1.944582840672417, 52.17475393308515],
            [-1.941358836231962, 52.177806429061064],
            [-1.939782981095873, 52.18169309602434],
            [-1.939122889952938, 52.185278135603156],
            [-1.937716133703179, 52.18820288346415],
            [-1.935860456372549, 52.189857027080478],
            [-1.935763404947914, 52.191228909944897],
            [-1.934848181953583, 52.192879946766411],
            [-1.936590344149375, 52.197121661560708],
            [-1.935673295918273, 52.199979212378778],
            [-1.936484323921216, 52.20069348663398],
            [-1.936573942583824, 52.202499703107122],
            [-1.93597626161194, 52.202979468209989],
            [-1.935641019229138, 52.204108478677469],
            [-1.933662648303692, 52.20609785784611],
            [-1.932099286862697, 52.206436808541362],
            [-1.930346661644128, 52.208014501339491],
            [-1.926783264666073, 52.208914986159471],
            [-1.923183347171671, 52.208841682054796],
            [-1.921839010881887, 52.210197449874393],
            [-1.920125187892617, 52.210250248510008],
            [-1.919778847584695, 52.214045748707349],
            [-1.917300675775726, 52.218949186297692],
            [-1.917528828021691, 52.223811330075094],
            [-1.920952386117192, 52.229915413725919],
            [-1.923872042910147, 52.234095137053004],
            [-1.925204534523837, 52.237583336292133],
            [-1.925451111036296, 52.239001266733169],
            [-1.923929050149743, 52.240169940832821],
            [-1.925203625721869, 52.241622690038767],
            [-1.925439314318087, 52.24255783230398],
            [-1.926222275717589, 52.242900853757313],
            [-1.92670438374876, 52.24273573103892],
            [-1.928189007283624, 52.244821046115014],
            [-1.93191816164082, 52.247539671611513],
            [-1.931248287824589, 52.247867431422648],
            [-1.931925898601775, 52.248229233205926],
            [-1.933676830098811, 52.249808025820322],
            [-1.93461839636405, 52.252003982618156],
            [-1.935335498183147, 52.254492892781265],
            [-1.934418471476431, 52.254478903575318],
            [-1.934786849435836, 52.256019145772562],
            [-1.934217342691131, 52.256761430044833],
            [-1.93542291591219, 52.25684929878755],
            [-1.934121772151329, 52.259973610698694],
            [-1.936581097397115, 52.260394798540759],
            [-1.935822032871846, 52.261503792464467],
            [-1.936422449951805, 52.262742978290895],
            [-1.934772404766141, 52.262897614058716],
            [-1.934394837928606, 52.26458038810118],
            [-1.935756194040759, 52.264493028741356],
            [-1.935844900316869, 52.26598186848009],
            [-1.935141231831462, 52.26620174767374],
            [-1.935218036261839, 52.266792451465882],
            [-1.936220626638112, 52.266582622314566],
            [-1.938081382616927, 52.268849160151404],
            [-1.938694607450595, 52.270483911855038],
            [-1.937408796306748, 52.276139022606088],
            [-1.935129810198779, 52.2770835742879],
            [-1.934266712941284, 52.276957235174123],
            [-1.930356865587844, 52.275655908680974],
            [-1.92859399725437, 52.275478650497263],
            [-1.927403932608227, 52.275465341191143],
            [-1.926176135739709, 52.276111882387639],
            [-1.923878062982275, 52.27607806358214],
            [-1.921113180373037, 52.27646193783351],
            [-1.915984636464864, 52.275019968365029],
            [-1.914387800113567, 52.275430579625343],
            [-1.913379584297893, 52.276116703522341],
            [-1.912003254783938, 52.2769041359075],
            [-1.905372935752549, 52.278797769169422],
            [-1.903307110832453, 52.279752664979007],
            [-1.902930919768503, 52.280191082255591],
            [-1.903625988957073, 52.280734662061505],
            [-1.900739403590271, 52.282243541200131],
            [-1.901161271067799, 52.283037734330328],
            [-1.900807019246791, 52.283476162922106],
            [-1.897949791395977, 52.28356903173416],
            [-1.896562660630117, 52.283740441109082],
            [-1.895293988537164, 52.282779165910597],
            [-1.8936976460829, 52.283399869603407],
            [-1.891421012513321, 52.285851245776982],
            [-1.886400888978953, 52.2866826290932],
            [-1.886010705124437, 52.287344836016452],
            [-1.886592975830533, 52.288392760130783],
            [-1.885504024876689, 52.29054667580909],
            [-1.886610382597613, 52.290768001851944],
            [-1.885805951376854, 52.291726488431181],
            [-1.884281986084646, 52.291966846299502],
            [-1.883703129731053, 52.294104160183693],
            [-1.883191485472544, 52.294634980018444],
            [-1.882607800819581, 52.294678454847819],
            [-1.880436752965262, 52.298320027701834],
            [-1.880208975739974, 52.299046208844011],
            [-1.87967675595519, 52.299015102258181],
            [-1.879459434691113, 52.299664875472573],
            [-1.877704531914484, 52.300584574894351],
            [-1.876005012464158, 52.304231943237617],
            [-1.875237959061095, 52.304244622625774],
            [-1.875215463734856, 52.3054672703717],
            [-1.876297786796341, 52.305480992664037],
            [-1.877587406357018, 52.306377760111445],
            [-1.878763698686026, 52.308514149531675],
            [-1.88094266784228, 52.310918548562462],
            [-1.874896640217387, 52.31244784360603],
            [-1.870394467206923, 52.31259403570786],
            [-1.868577054397815, 52.314574375046597],
            [-1.867196406278619, 52.318105988379209],
            [-1.866678143982731, 52.318239359955662],
            [-1.867474188611723, 52.331219407833494],
            [-1.864838835072734, 52.333078296822514],
            [-1.865328219044098, 52.333332377863329],
            [-1.868264272299273, 52.333001246299851],
            [-1.871176105969962, 52.335946938426893],
            [-1.873087536056365, 52.337283145809778],
            [-1.874762786723336, 52.340178868317714],
            [-1.875168832781417, 52.342977039554256],
            [-1.876237578445544, 52.345558340406775],
            [-1.884785201333702, 52.353556521469137],
            [-1.884514044708917, 52.354508314842711],
            [-1.884918017183139, 52.355561455014318],
            [-1.885415697303906, 52.356155287548177],
            [-1.886412528986306, 52.35620928951753],
            [-1.887018413256627, 52.356991113082088],
            [-1.888981614445616, 52.361078991137589],
            [-1.887813266866945, 52.361445587359214],
            [-1.884326444747499, 52.361707443111328],
            [-1.880871527974055, 52.362650680917476],
            [-1.879666182148267, 52.362540681789582],
            [-1.876674417523619, 52.363750374635309],
            [-1.876133035935778, 52.363567309823353],
            [-1.873471431976441, 52.365328361809013],
            [-1.873695471531267, 52.366074782419346],
            [-1.872041858754703, 52.367584249386724],
            [-1.869937451503866, 52.368017978629652],
            [-1.868110349449402, 52.36803753431802],
            [-1.863352013331857, 52.367017152995679],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000222",
        LAD13CDO: "44UF",
        LAD13NM: "Warwick",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.601077394987723, 52.389283702241784],
            [-1.597703316557006, 52.388027139238389],
            [-1.597400285032117, 52.38822658871289],
            [-1.594727341590208, 52.387442509739209],
            [-1.593953410138765, 52.38787857525692],
            [-1.588007380067724, 52.386094145838683],
            [-1.580125866838905, 52.384877932046237],
            [-1.575799027044234, 52.384559525156362],
            [-1.576718227115631, 52.38277197679384],
            [-1.573028584618907, 52.382521374869206],
            [-1.571383915438605, 52.38334881519269],
            [-1.569572518040237, 52.38288194056517],
            [-1.568786331157921, 52.383654029646785],
            [-1.570548527915175, 52.38419715403289],
            [-1.567808779702687, 52.384761646667052],
            [-1.564828564467716, 52.382278422507511],
            [-1.563156416378255, 52.379174243821588],
            [-1.562645844406634, 52.37687536587849],
            [-1.559312347292227, 52.375582789790855],
            [-1.555003980105694, 52.374407822600539],
            [-1.554911405065037, 52.37368016797609],
            [-1.556771583899053, 52.372448305868403],
            [-1.555562406232852, 52.372041007438675],
            [-1.556917895658602, 52.369714936558545],
            [-1.558265182153246, 52.368639355169059],
            [-1.555853795644139, 52.367735798852884],
            [-1.557466009405648, 52.36571724997313],
            [-1.555566693117167, 52.365024174903049],
            [-1.555016809105499, 52.36508683651229],
            [-1.552423745745064, 52.363875959527569],
            [-1.54940154444834, 52.366322404159504],
            [-1.546819435825264, 52.365340696298418],
            [-1.543346287603039, 52.366327945281711],
            [-1.542888222637069, 52.36602859978948],
            [-1.541904030039993, 52.366617245837773],
            [-1.545688138380894, 52.369254285485134],
            [-1.535489933150761, 52.377592631231593],
            [-1.53267237004652, 52.376199738942944],
            [-1.529929957838557, 52.377846661904179],
            [-1.524661698599106, 52.379384504237073],
            [-1.522791325961073, 52.377498021934137],
            [-1.519821678227135, 52.375536927413087],
            [-1.521094352474843, 52.374533387722131],
            [-1.520011384843408, 52.37307707992241],
            [-1.518480308667438, 52.373530255064537],
            [-1.517988099343735, 52.371922599747286],
            [-1.514703218657997, 52.369964583609608],
            [-1.513923110418853, 52.369045277372315],
            [-1.509372231940636, 52.373091855850703],
            [-1.508180231438695, 52.37580554198329],
            [-1.504509140444621, 52.377790537580857],
            [-1.486614551459014, 52.376813390945266],
            [-1.48228793856115, 52.377101994372318],
            [-1.480419903540426, 52.3743014422489],
            [-1.475923368327893, 52.37469155419268],
            [-1.471018134197768, 52.376456069531024],
            [-1.466618551812128, 52.37718699064186],
            [-1.464705558077472, 52.375514257982061],
            [-1.466342203112943, 52.374519245830001],
            [-1.464023452065119, 52.373250128302885],
            [-1.464064489180465, 52.372772034528488],
            [-1.46252192450583, 52.37243059710169],
            [-1.460382776158299, 52.370422327908813],
            [-1.461263592968044, 52.369493158574478],
            [-1.462808528570361, 52.36926644123163],
            [-1.463859643564878, 52.368701231248117],
            [-1.464799740903198, 52.367840629992003],
            [-1.464993481190438, 52.36712138936246],
            [-1.463725164736162, 52.366585220454624],
            [-1.463028876458096, 52.36659734395402],
            [-1.462436320253625, 52.365322533690922],
            [-1.462829482973288, 52.36487301070909],
            [-1.461955294556158, 52.364299055240181],
            [-1.462367071230968, 52.363646439094758],
            [-1.460578636702839, 52.36314203270107],
            [-1.461429657033315, 52.36151058765261],
            [-1.462233888185313, 52.361430638874324],
            [-1.462303446912393, 52.360780960868965],
            [-1.463206416317737, 52.360549519402824],
            [-1.462966843242746, 52.359961368790145],
            [-1.463621946426098, 52.359824993679439],
            [-1.464116055989736, 52.360494309986215],
            [-1.464996735992276, 52.359918427626496],
            [-1.46492371613495, 52.358060713181537],
            [-1.464011483687601, 52.35797027503969],
            [-1.461523082865295, 52.358897549941261],
            [-1.455094980978879, 52.356822818864259],
            [-1.451210134895816, 52.357031405246353],
            [-1.44806141217904, 52.353660686481803],
            [-1.449405458372705, 52.353492542349628],
            [-1.452372517436518, 52.35244007829202],
            [-1.455290560619745, 52.349868859343736],
            [-1.456512401229216, 52.349238871104411],
            [-1.45620954258102, 52.346760661267894],
            [-1.454340301821338, 52.346084071848217],
            [-1.450366271893513, 52.345510064180935],
            [-1.445787761475788, 52.34585908817369],
            [-1.443653199319793, 52.345519119286408],
            [-1.446028235964589, 52.343530836054526],
            [-1.447976380049366, 52.340562370173316],
            [-1.446533662970946, 52.340317382050898],
            [-1.444146664610667, 52.34105057734476],
            [-1.442918685187369, 52.338200272424274],
            [-1.443179555383845, 52.336710912573814],
            [-1.441750714465991, 52.334559091191117],
            [-1.44176113208115, 52.333381412547681],
            [-1.440340008329211, 52.332725593666645],
            [-1.439483691651559, 52.33150694770945],
            [-1.435986530124953, 52.328400347552474],
            [-1.431693999462227, 52.3268289599242],
            [-1.428592274656283, 52.326811308837343],
            [-1.427496810664484, 52.326079591227213],
            [-1.427517403880737, 52.325734463195673],
            [-1.425017332518939, 52.326624055555882],
            [-1.424207135676234, 52.326189475790805],
            [-1.423061310517886, 52.32651652977588],
            [-1.421622548618317, 52.326351268781259],
            [-1.420116684967469, 52.326830266183265],
            [-1.418661510182667, 52.326237848916357],
            [-1.418048675459724, 52.326311250792003],
            [-1.416711534645558, 52.325561160980719],
            [-1.417016474976474, 52.32502234771232],
            [-1.416233501968962, 52.324087985777048],
            [-1.416179005857024, 52.322096361734538],
            [-1.413567164829525, 52.320561370355009],
            [-1.412841890442168, 52.320600023978223],
            [-1.412654691847497, 52.320887683127026],
            [-1.409286935165266, 52.320717164563213],
            [-1.408028922261516, 52.319997937693103],
            [-1.407881730293452, 52.319374170910372],
            [-1.406984527151758, 52.319105356985588],
            [-1.406650333000803, 52.317656237108444],
            [-1.406089355447364, 52.316602449991478],
            [-1.406646961081783, 52.315274681933403],
            [-1.409056244889574, 52.315344291559136],
            [-1.408996735351773, 52.31485581903933],
            [-1.410376479821738, 52.31468380315021],
            [-1.410251942972549, 52.314009805962421],
            [-1.410843213082962, 52.314112547688296],
            [-1.411263211875077, 52.313746037111443],
            [-1.410428553144709, 52.313524310186111],
            [-1.410434473279032, 52.313189000316378],
            [-1.412505522668738, 52.312427943361882],
            [-1.412742579339916, 52.311806091458145],
            [-1.413536207984665, 52.312245164597584],
            [-1.415658325922085, 52.312500213422993],
            [-1.415859143131947, 52.312957015979578],
            [-1.415470974903081, 52.313249080550158],
            [-1.415815328171242, 52.313499815370889],
            [-1.418825315367006, 52.313170326012099],
            [-1.419998820469899, 52.312160183764966],
            [-1.418973948162977, 52.311676863907749],
            [-1.421143670872516, 52.309068627320521],
            [-1.420524307218783, 52.308649339425301],
            [-1.420870418711995, 52.308090039092455],
            [-1.419030461692421, 52.307021946203271],
            [-1.419513700718043, 52.30685620155937],
            [-1.419455096170205, 52.305841803377341],
            [-1.418654660755738, 52.305366774570885],
            [-1.419244278939229, 52.304915661429476],
            [-1.419419538409094, 52.303737888686051],
            [-1.420114923053488, 52.303383487397497],
            [-1.421492493602837, 52.303572743252225],
            [-1.422330412519683, 52.30296999412559],
            [-1.421799510131575, 52.302632956875797],
            [-1.42283841771937, 52.301684160029204],
            [-1.424016353678813, 52.301771719510548],
            [-1.426505202261103, 52.300735550208032],
            [-1.42626359779365, 52.299458647278058],
            [-1.424995084930549, 52.299349092516472],
            [-1.424174885292703, 52.299611212882397],
            [-1.422938257156384, 52.299079245003682],
            [-1.421813677509854, 52.297940064655201],
            [-1.42366300603453, 52.29645400344365],
            [-1.423994849366891, 52.296645317951302],
            [-1.424576183361826, 52.296480929730357],
            [-1.42605628970111, 52.295188123066509],
            [-1.426191661742469, 52.294924464110686],
            [-1.425270102019716, 52.294867841306385],
            [-1.424745616883413, 52.294267430487949],
            [-1.425749706779446, 52.294063738219386],
            [-1.426932395159404, 52.294222316014064],
            [-1.428086402480978, 52.295128740557608],
            [-1.435652578800311, 52.293618778138629],
            [-1.439444619597501, 52.294288628499125],
            [-1.440485756126185, 52.294165000929837],
            [-1.440922502582761, 52.294530278257071],
            [-1.443994618605421, 52.295327832799721],
            [-1.448484064224343, 52.294056958961953],
            [-1.447945627145227, 52.291369027379062],
            [-1.445890210459418, 52.287712918669136],
            [-1.4427714740455, 52.284125482293241],
            [-1.441939177568564, 52.282113106369096],
            [-1.440841580937802, 52.282203214911391],
            [-1.440533580072047, 52.281634464631452],
            [-1.439234899957054, 52.281746081811733],
            [-1.43967723593282, 52.278750790637275],
            [-1.435105787959749, 52.276426586129311],
            [-1.43610778174915, 52.274503835370169],
            [-1.434874120339879, 52.273897383132031],
            [-1.43522696079403, 52.272772575364442],
            [-1.438221129077114, 52.271841062736499],
            [-1.44113055637294, 52.271461982071344],
            [-1.444147701346493, 52.27207497586231],
            [-1.448517880569294, 52.271368134922568],
            [-1.450999148492967, 52.271829210484654],
            [-1.453427979500779, 52.271910597225144],
            [-1.45475446316263, 52.270819900412135],
            [-1.458938194366419, 52.270236779819143],
            [-1.463044636696893, 52.265122906404876],
            [-1.465578165395903, 52.263657274421966],
            [-1.469601555004229, 52.264778527298972],
            [-1.47117334411746, 52.263954862320411],
            [-1.472296536841419, 52.263887062799128],
            [-1.474671690024933, 52.262772951580196],
            [-1.47583618453646, 52.261186825967066],
            [-1.477668645061625, 52.26172537528015],
            [-1.48347821856957, 52.255280537304195],
            [-1.484964889477973, 52.254418556573199],
            [-1.486635175377249, 52.25274642157423],
            [-1.488749152800525, 52.249419253369304],
            [-1.491063433639892, 52.246991949565981],
            [-1.495087318274656, 52.242054606213273],
            [-1.497349097235887, 52.240191550803594],
            [-1.497574652839481, 52.240059452397595],
            [-1.498874694518953, 52.241006271271338],
            [-1.498929732132783, 52.238979167111367],
            [-1.500115881566314, 52.237548424233346],
            [-1.502117945598914, 52.23791919435044],
            [-1.502092049162892, 52.238270610321194],
            [-1.500629625822248, 52.238876683383495],
            [-1.500034571572885, 52.239572721012564],
            [-1.502196686018488, 52.24148512294051],
            [-1.504577524396084, 52.241000660761671],
            [-1.50656343372776, 52.241260705186086],
            [-1.507650548421998, 52.240822913708385],
            [-1.508699103462496, 52.241621132671071],
            [-1.509855923103838, 52.2416349291816],
            [-1.510964091324415, 52.242211312216675],
            [-1.512502466280708, 52.241754665719483],
            [-1.513000349590131, 52.242161287706296],
            [-1.514606484936287, 52.241532277442779],
            [-1.514736789122184, 52.241132739891178],
            [-1.516328031437009, 52.240790438898564],
            [-1.51657862466707, 52.239966145661505],
            [-1.517684546212729, 52.23980794046782],
            [-1.519320128889014, 52.238200830131632],
            [-1.519711868080451, 52.235960215869042],
            [-1.520783488406008, 52.235441326775934],
            [-1.521742275084707, 52.230292806671763],
            [-1.523494982248831, 52.229323531673295],
            [-1.525266911578639, 52.226681190506369],
            [-1.528209874438053, 52.225135842394366],
            [-1.535256649317507, 52.226476383924108],
            [-1.539692530633697, 52.228327801008383],
            [-1.539836517438735, 52.227853668150381],
            [-1.542336783482854, 52.228173552528318],
            [-1.542442931612463, 52.227817044331566],
            [-1.548550021729201, 52.227625675066861],
            [-1.555032814056974, 52.228217571728685],
            [-1.554308777765182, 52.230929046192053],
            [-1.56007064853148, 52.231632120557393],
            [-1.563147262148659, 52.228014112912689],
            [-1.563701976895086, 52.22563999789903],
            [-1.564652368538075, 52.224265274609536],
            [-1.567362109717223, 52.221554732194626],
            [-1.567427740272395, 52.21946919543209],
            [-1.568020282062429, 52.217989742823605],
            [-1.568396492443828, 52.217684544897935],
            [-1.5690512522157, 52.218089706870998],
            [-1.57261193592973, 52.218000155343795],
            [-1.572798938234886, 52.216968731499826],
            [-1.573502259060843, 52.216891258764441],
            [-1.573766331076244, 52.216368969511642],
            [-1.574409215766408, 52.216181590078627],
            [-1.575119237152568, 52.216320801062643],
            [-1.57533533163365, 52.217297938144263],
            [-1.576736479919837, 52.217258915006859],
            [-1.577606389279854, 52.217982162153305],
            [-1.579403173178833, 52.218055103830501],
            [-1.579322269981051, 52.218407240100227],
            [-1.580371910969961, 52.219015130307533],
            [-1.58126388611532, 52.220359666258794],
            [-1.581234261280023, 52.220863923483648],
            [-1.582727933390028, 52.221272879711712],
            [-1.583210187250479, 52.220890691892848],
            [-1.583639991514746, 52.221420844163937],
            [-1.584505180297967, 52.221572232845695],
            [-1.584822666471936, 52.222218860882137],
            [-1.58435323425015, 52.222638860051866],
            [-1.585023629775071, 52.222960376978548],
            [-1.585175422204923, 52.223637888038603],
            [-1.586069412617756, 52.224003338000671],
            [-1.585614804609518, 52.224402716085358],
            [-1.586392883071959, 52.224482760889934],
            [-1.586668005807499, 52.224178050161917],
            [-1.587118346989542, 52.224553626238972],
            [-1.588787232763109, 52.223604667162647],
            [-1.589915558388503, 52.223802782970665],
            [-1.59318865106214, 52.223510226192225],
            [-1.597288944115665, 52.225307973963133],
            [-1.601041544643243, 52.224934121636267],
            [-1.602034289630541, 52.225242245339061],
            [-1.603098320875697, 52.224616497478735],
            [-1.604014890489324, 52.222790026764677],
            [-1.605910133706522, 52.222350441513427],
            [-1.60780765985592, 52.220982124481019],
            [-1.610081207399793, 52.22045652089934],
            [-1.611862170079865, 52.219002344762458],
            [-1.617496438692805, 52.216590618352456],
            [-1.617536386637377, 52.216199664832935],
            [-1.618058928828935, 52.216198658681186],
            [-1.61796924767585, 52.215903483131783],
            [-1.618926395893227, 52.215229597212804],
            [-1.618950164258583, 52.215017500157558],
            [-1.618462708448896, 52.215024916876658],
            [-1.618561420793305, 52.214607181499339],
            [-1.618936603430507, 52.214378238009566],
            [-1.619211222566221, 52.214612874490989],
            [-1.619674466016441, 52.214012009406069],
            [-1.620527439755331, 52.213704583784619],
            [-1.621238002758512, 52.213792273614388],
            [-1.620314279793661, 52.218109200218279],
            [-1.621252344358939, 52.220370602021092],
            [-1.623862092790707, 52.221438910208455],
            [-1.624293325420759, 52.221336893065221],
            [-1.625577057108931, 52.222563663947],
            [-1.62638280775115, 52.224759872459586],
            [-1.625907509821958, 52.226270552568494],
            [-1.625330181911644, 52.226853098323446],
            [-1.622403872458674, 52.226992121534117],
            [-1.618092760595184, 52.228303445506832],
            [-1.61652498542733, 52.22929450167112],
            [-1.615109043459808, 52.229650411115855],
            [-1.615299335766548, 52.22999446396409],
            [-1.614757641586019, 52.230328939147959],
            [-1.613508637119512, 52.230339243678863],
            [-1.6123186708041, 52.229622406076203],
            [-1.61034432940149, 52.229703116447212],
            [-1.609391018227841, 52.230055987373539],
            [-1.609043361510906, 52.230297577804393],
            [-1.609246779910337, 52.232311199365263],
            [-1.60593235765997, 52.234731189629549],
            [-1.605682805151206, 52.235297650205396],
            [-1.605686638356902, 52.236022288026831],
            [-1.606652484352291, 52.237583541490864],
            [-1.608118022590173, 52.238450589897603],
            [-1.610082017271292, 52.238760064678992],
            [-1.61236790834987, 52.237422626924094],
            [-1.618415895030813, 52.236897500990388],
            [-1.624951622583503, 52.238427916039178],
            [-1.625067794700034, 52.238892188168464],
            [-1.624077861391846, 52.239063455450541],
            [-1.6239511481143, 52.239328268213903],
            [-1.624726094688034, 52.239818909817167],
            [-1.62579960360347, 52.241553860755545],
            [-1.626246721085415, 52.24150133393718],
            [-1.626873079686301, 52.240681593986288],
            [-1.627760217855013, 52.241773126521053],
            [-1.627585570032065, 52.242342565516864],
            [-1.626204485817871, 52.242170982884012],
            [-1.625788886654661, 52.243003074383608],
            [-1.625167699471655, 52.242158707497836],
            [-1.624588025228247, 52.242125400558834],
            [-1.621727862813291, 52.244013244937086],
            [-1.621373012913654, 52.244406785341468],
            [-1.621932558809576, 52.244743918012148],
            [-1.626425843847762, 52.246508635752335],
            [-1.626422886997347, 52.247035461647819],
            [-1.629623661293286, 52.24680909284892],
            [-1.635926298561216, 52.247334840452886],
            [-1.63804514079059, 52.249207748984382],
            [-1.637996799450767, 52.249928627378452],
            [-1.640958249967961, 52.252332693841247],
            [-1.641121696491814, 52.253136028645393],
            [-1.642316312053461, 52.253956873784027],
            [-1.64589846721124, 52.25537645072248],
            [-1.644912662382716, 52.255545207850382],
            [-1.643464186400461, 52.256594514296566],
            [-1.645940012760608, 52.258256181881684],
            [-1.648986642634288, 52.258159185643194],
            [-1.649059209920781, 52.257503106368176],
            [-1.651589920246542, 52.257801882595238],
            [-1.651943572250465, 52.257166408782162],
            [-1.653051492250365, 52.257120220237333],
            [-1.653371378901818, 52.256114240891193],
            [-1.653416416025619, 52.256350818982632],
            [-1.655572488456935, 52.256971163684923],
            [-1.657370322494816, 52.257128327239073],
            [-1.657521817403162, 52.256479663905864],
            [-1.658541434660395, 52.256292019557101],
            [-1.65869615292278, 52.255793502657625],
            [-1.660359589997022, 52.255703897160679],
            [-1.660469307063929, 52.255916384514094],
            [-1.661801983696798, 52.255976845519363],
            [-1.661883477864838, 52.256241394727496],
            [-1.664211136202017, 52.256076316825926],
            [-1.665975644015814, 52.25676907882],
            [-1.666866393383366, 52.257940337187122],
            [-1.668528774847466, 52.258197640520734],
            [-1.669218929092111, 52.25798560450604],
            [-1.669024011043527, 52.259176278003082],
            [-1.66939918250561, 52.259950496840652],
            [-1.674012258762428, 52.263029014887486],
            [-1.675835505224999, 52.265371514640968],
            [-1.676095491551927, 52.268102587767032],
            [-1.676436996668208, 52.2701074648242],
            [-1.674123038690845, 52.269908714114187],
            [-1.675270061667966, 52.2717728664351],
            [-1.675186070042429, 52.273842205248783],
            [-1.673085162882627, 52.277331844029412],
            [-1.669351418841381, 52.2807674403645],
            [-1.66657336345468, 52.282671872535644],
            [-1.665837332013995, 52.283766610128851],
            [-1.665813917336632, 52.284151328605638],
            [-1.666920937213383, 52.285095737347874],
            [-1.669101411735675, 52.285801306729695],
            [-1.674245491115813, 52.286402661938716],
            [-1.677268802579301, 52.285938066226521],
            [-1.679338919857463, 52.286728552640383],
            [-1.67984571066568, 52.286179720705306],
            [-1.68031386550432, 52.285497725768082],
            [-1.681193344394335, 52.285306809920968],
            [-1.682246105681774, 52.284252386478414],
            [-1.683609824423653, 52.283988134002875],
            [-1.685431733170257, 52.282775711142399],
            [-1.690272911984646, 52.284333028163552],
            [-1.692629416600795, 52.28591787416881],
            [-1.693659196565099, 52.286033825084083],
            [-1.695245136193077, 52.286925265821672],
            [-1.697464004340767, 52.289392505072314],
            [-1.699980558832404, 52.291236532003815],
            [-1.703893792399031, 52.291223923193314],
            [-1.710293263474725, 52.289914612745676],
            [-1.711893183251137, 52.289852893796244],
            [-1.712015201449643, 52.289575391532281],
            [-1.713281112745697, 52.290152947367311],
            [-1.715118028455408, 52.292002192724134],
            [-1.716796632510491, 52.292948406436807],
            [-1.717700275160207, 52.293799249281982],
            [-1.718122764888479, 52.294921343965946],
            [-1.719144780077598, 52.295381380959832],
            [-1.71977238398593, 52.296306170635127],
            [-1.722728074125466, 52.297075513962476],
            [-1.723773858425684, 52.298445380963187],
            [-1.724708456017269, 52.298363052368565],
            [-1.725468880723392, 52.297752583679546],
            [-1.726476274064803, 52.297752221504751],
            [-1.726966811129334, 52.297149209680043],
            [-1.726655561788487, 52.296734938351378],
            [-1.727119671403118, 52.296612843846972],
            [-1.727822932133237, 52.296946205667602],
            [-1.727735728455575, 52.296580101146127],
            [-1.728654671669973, 52.296412297783611],
            [-1.728835397511809, 52.296111538571573],
            [-1.730003788681716, 52.296153769736179],
            [-1.731460906917989, 52.295248172564726],
            [-1.732057068056527, 52.295836589447532],
            [-1.73305241189774, 52.295393822899705],
            [-1.732382567864927, 52.295109115220654],
            [-1.733921762785816, 52.29444011736792],
            [-1.733689099216498, 52.294116842954459],
            [-1.733166686860387, 52.294188486220008],
            [-1.733837942864815, 52.29350134483132],
            [-1.733899240392221, 52.293793666503156],
            [-1.734498722383696, 52.293828278247453],
            [-1.735538429807331, 52.294542637593402],
            [-1.736884425048332, 52.296033529457802],
            [-1.737248999420416, 52.295876111592086],
            [-1.73854428387731, 52.297279665878591],
            [-1.738611600919718, 52.297801249835267],
            [-1.737365686267223, 52.298958232328062],
            [-1.738007898378379, 52.298970445427969],
            [-1.73814277633335, 52.299722329782448],
            [-1.737763951897746, 52.300053230685933],
            [-1.738916662564856, 52.300786689471039],
            [-1.738780169669238, 52.301055196636625],
            [-1.740168710729547, 52.301341449155537],
            [-1.739883536536148, 52.301707624514478],
            [-1.740912340873471, 52.301823160509521],
            [-1.741274431799686, 52.302349883178636],
            [-1.743728897922656, 52.30294139277369],
            [-1.746660400103739, 52.302701377053388],
            [-1.747737045152566, 52.304730081126422],
            [-1.749859072746769, 52.304753468641366],
            [-1.751861426999604, 52.305197310519986],
            [-1.753929730351206, 52.304862700325828],
            [-1.75481769397134, 52.305006590676534],
            [-1.761674181064151, 52.307990984283052],
            [-1.760778424550467, 52.308734466573199],
            [-1.76073642111611, 52.310269914334363],
            [-1.763547569214245, 52.312598645171377],
            [-1.76477534468856, 52.314533993366602],
            [-1.766575924294924, 52.315801587069977],
            [-1.769023562790168, 52.319324267531407],
            [-1.768087692624558, 52.323795969081381],
            [-1.767615132341323, 52.328584127431704],
            [-1.771965237878154, 52.338429670577462],
            [-1.77377485494701, 52.341034007048123],
            [-1.775761067549658, 52.347948541922975],
            [-1.776222410077748, 52.349618891106552],
            [-1.774694374545814, 52.350440390043232],
            [-1.771894828469872, 52.351272010737432],
            [-1.771756138213333, 52.351690685298443],
            [-1.773147678660648, 52.35260316697709],
            [-1.774788189169947, 52.354517610993653],
            [-1.773160656321872, 52.355520501491682],
            [-1.771941902115494, 52.355252049645088],
            [-1.771868291338176, 52.355861441007271],
            [-1.772876219006016, 52.356014414936134],
            [-1.77351756451318, 52.3566476529287],
            [-1.776563193995685, 52.355119721443138],
            [-1.77823425661765, 52.356551402535182],
            [-1.778203204479544, 52.357769511395063],
            [-1.778928453522372, 52.357792445053363],
            [-1.7787239250221, 52.360232891157935],
            [-1.779236858243197, 52.360142149655424],
            [-1.78017755111861, 52.363811884303352],
            [-1.779232663038251, 52.36452484227766],
            [-1.775748127946893, 52.365900975042159],
            [-1.772033901537913, 52.365348175494312],
            [-1.77008285746624, 52.364642269648208],
            [-1.770170904276284, 52.364088647270698],
            [-1.767069056217079, 52.364696609099653],
            [-1.762808112054224, 52.364260212820305],
            [-1.759614521249554, 52.362536647727275],
            [-1.75999534819867, 52.35919128326649],
            [-1.75963990758788, 52.358394932244821],
            [-1.753784997712324, 52.356109279301421],
            [-1.752166675521673, 52.355893732226058],
            [-1.75109512199638, 52.355056295823267],
            [-1.748782850830685, 52.35475563165619],
            [-1.743581757656226, 52.355360299301609],
            [-1.742339726871897, 52.356855363026803],
            [-1.74141733175071, 52.357157218162364],
            [-1.740936561724806, 52.360029423638252],
            [-1.73918623760635, 52.361271614521769],
            [-1.73906588405202, 52.361760413980576],
            [-1.738297612286962, 52.362052695281768],
            [-1.737908743975387, 52.362750369978919],
            [-1.738331872014366, 52.364956592680912],
            [-1.736554872080154, 52.368418353890526],
            [-1.735759051024994, 52.36887507691511],
            [-1.73524240615205, 52.370047136629942],
            [-1.734039969618662, 52.370702518700682],
            [-1.730548630985208, 52.370711700067943],
            [-1.729807604531002, 52.370113963066274],
            [-1.728856566885124, 52.369996713348264],
            [-1.728583363909922, 52.370478858026409],
            [-1.727578245314455, 52.369840045153573],
            [-1.726057086338442, 52.370240190514565],
            [-1.72564786355416, 52.370624020738184],
            [-1.723291135892036, 52.370754283387683],
            [-1.720871838641358, 52.371955078430091],
            [-1.720805180543621, 52.372510512397497],
            [-1.720267282030734, 52.372558687616184],
            [-1.718242337822689, 52.369463068119572],
            [-1.717738724248918, 52.367822962213019],
            [-1.718032304018744, 52.366000458182022],
            [-1.720097530709836, 52.363154582346858],
            [-1.720834127790254, 52.359052311775457],
            [-1.720218439130974, 52.357259116030363],
            [-1.718555762840691, 52.355722347088189],
            [-1.718765847687639, 52.357323996770397],
            [-1.718346121037504, 52.358676918035393],
            [-1.717149943344907, 52.358130158504842],
            [-1.715102842191403, 52.357933755212898],
            [-1.712846069141845, 52.356786546220505],
            [-1.71264406173732, 52.356237654961056],
            [-1.711382616189735, 52.355362536656401],
            [-1.710279341343358, 52.355005626513005],
            [-1.707224466210591, 52.355141947462208],
            [-1.704098984155336, 52.35419469617937],
            [-1.700026828273603, 52.354740032075242],
            [-1.698101176934527, 52.355716860426938],
            [-1.696241312655109, 52.354365369611948],
            [-1.693894823095474, 52.350997889991426],
            [-1.692635798257091, 52.351543026263521],
            [-1.691372621685064, 52.353102231221982],
            [-1.687553358534937, 52.354421851948317],
            [-1.684940459693878, 52.355995394865921],
            [-1.685414490733866, 52.35685521890948],
            [-1.685872368470463, 52.356888800489337],
            [-1.686186937048829, 52.358084430350416],
            [-1.685694159855858, 52.358208085400328],
            [-1.683700771250674, 52.360381091485721],
            [-1.684716397184978, 52.362520767298626],
            [-1.684566789498749, 52.36311461877245],
            [-1.684056329754708, 52.36323821979687],
            [-1.68061368956205, 52.363701857264317],
            [-1.679630646739225, 52.363579629530292],
            [-1.677949659379458, 52.364118071023981],
            [-1.675064959071781, 52.363778444346508],
            [-1.672542505075806, 52.363112508288303],
            [-1.669994560960855, 52.364894471376317],
            [-1.668114948673395, 52.364478361479598],
            [-1.666822122813692, 52.364933225690976],
            [-1.666073933179936, 52.364438454334724],
            [-1.664059790745464, 52.363953574645315],
            [-1.66089819541689, 52.365654483830781],
            [-1.653096264078147, 52.359922196182104],
            [-1.651320701070445, 52.356405408166111],
            [-1.648428519761421, 52.356727684985991],
            [-1.646478357957168, 52.359519618073968],
            [-1.644998220723142, 52.360984180706325],
            [-1.644853397195642, 52.361831520172132],
            [-1.644075066970895, 52.362201373994317],
            [-1.641764014065148, 52.362519841201419],
            [-1.638096868112226, 52.363992955879056],
            [-1.63646900225487, 52.365153090926235],
            [-1.633458697013218, 52.364226801557983],
            [-1.63158293244515, 52.365689069964127],
            [-1.629777126959861, 52.365090978240929],
            [-1.626505333446561, 52.366631497576549],
            [-1.623411396269357, 52.365535672836153],
            [-1.620977055485805, 52.367868040663581],
            [-1.626626331458455, 52.36917879694677],
            [-1.624847517448433, 52.370241201033402],
            [-1.623686017963637, 52.371763143736814],
            [-1.622243947967136, 52.371727081239911],
            [-1.619025676158177, 52.37054264207049],
            [-1.618541006719166, 52.371563262818164],
            [-1.617688068697389, 52.371852689557841],
            [-1.617051420795041, 52.372607602591394],
            [-1.617495327639886, 52.373421750573073],
            [-1.618247694168126, 52.373386422559996],
            [-1.618155528930268, 52.376240503823411],
            [-1.616370452041741, 52.37457694050029],
            [-1.615381597592444, 52.374439775558216],
            [-1.611701594225006, 52.374652505978752],
            [-1.608907729730562, 52.375816518284722],
            [-1.60891802310586, 52.376480925867106],
            [-1.608030545222384, 52.376339539559254],
            [-1.606925731914496, 52.380991880363112],
            [-1.61029299528009, 52.382490893860087],
            [-1.609404305914008, 52.382972532255856],
            [-1.609785436251322, 52.383403519761501],
            [-1.609056621714895, 52.383413699482361],
            [-1.608816552308002, 52.382981378168253],
            [-1.607519513005577, 52.384610593460557],
            [-1.605179604623403, 52.383884492849148],
            [-1.604592924139339, 52.38475907362281],
            [-1.605466083722786, 52.384876164146029],
            [-1.605042729527943, 52.385723422164297],
            [-1.604151947694905, 52.385603572534201],
            [-1.603780642247535, 52.386049140172602],
            [-1.603433123027288, 52.385970660276499],
            [-1.60200386097112, 52.387402486880845],
            [-1.603294335811607, 52.388178176897966],
            [-1.601077394987723, 52.389283702241784],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000223",
        LAD13CDO: "45UB",
        LAD13NM: "Adur",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-0.239780382757103, 50.861958795720696],
              [-0.239021048578609, 50.85872863370605],
              [-0.236409247405504, 50.857528155139185],
              [-0.235409998894595, 50.852418332084106],
              [-0.234360948438955, 50.851116417993602],
              [-0.230643562963141, 50.848324339207785],
              [-0.229352044631144, 50.845740747471488],
              [-0.228431092809567, 50.845640432916412],
              [-0.227672210936759, 50.844412109367646],
              [-0.225993735863937, 50.842937774700978],
              [-0.222933820830317, 50.839095118099571],
              [-0.21759850271291, 50.832923430066593],
              [-0.216693570206611, 50.832968958550126],
              [-0.216612897687877, 50.830688804473809],
              [-0.215322641884501, 50.830375891321751],
              [-0.21606545488359, 50.827578619122001],
              [-0.228939592471358, 50.828720859961628],
              [-0.237178491231511, 50.82875569966447],
              [-0.237969569604171, 50.828450183915507],
              [-0.237955777996086, 50.827949045565333],
              [-0.241017015702206, 50.828370260638927],
              [-0.241634519494678, 50.828072895213424],
              [-0.244964750248358, 50.82828941433057],
              [-0.246816442838518, 50.825558992483039],
              [-0.245148865106072, 50.828226532239299],
              [-0.246010972186776, 50.828399700653705],
              [-0.246946920202723, 50.827458550350968],
              [-0.246063543105882, 50.828410260435653],
              [-0.245691211497236, 50.828721228549242],
              [-0.243831534596755, 50.829199577189343],
              [-0.238902676176303, 50.829426561402968],
              [-0.238807226183222, 50.82920838074088],
              [-0.237339079313783, 50.829468601153138],
              [-0.237365822551937, 50.829738805887416],
              [-0.239198945057678, 50.829666658882502],
              [-0.238772627709096, 50.829976790379241],
              [-0.239782539867991, 50.830007319604725],
              [-0.238173401358403, 50.830164697841568],
              [-0.238576915492743, 50.830611464746298],
              [-0.241510561238103, 50.83076723428578],
              [-0.247129056148646, 50.830708784943695],
              [-0.248630400257806, 50.830206119397623],
              [-0.248739461153664, 50.829910076694894],
              [-0.249581246135494, 50.830525262738057],
              [-0.252122881024183, 50.831102067031615],
              [-0.259128328863163, 50.8317050332533],
              [-0.268425071893595, 50.831757038496988],
              [-0.268473429310557, 50.831985287239547],
              [-0.269552966493915, 50.831977920253564],
              [-0.269671175116782, 50.832199110416241],
              [-0.269739523272677, 50.831848485093261],
              [-0.270208467502633, 50.83180147840239],
              [-0.271042643133169, 50.831862406369524],
              [-0.271162010916727, 50.832244592611346],
              [-0.271273408179199, 50.831958456887918],
              [-0.272651453097674, 50.831827778473688],
              [-0.272637603038584, 50.831587451783641],
              [-0.275333659401036, 50.831258608668662],
              [-0.279222278302494, 50.832133515782658],
              [-0.281728009496375, 50.834048246605867],
              [-0.284542346722382, 50.837390199882947],
              [-0.285714470397277, 50.838115199062834],
              [-0.286515857993161, 50.839695401050108],
              [-0.287527540149262, 50.840537635977448],
              [-0.287003265699775, 50.844537351107157],
              [-0.287337948275521, 50.84622041108841],
              [-0.289077818977658, 50.84719832281899],
              [-0.292026442762348, 50.847987960236985],
              [-0.292031845103656, 50.848851394002416],
              [-0.29030245447207, 50.849759571384794],
              [-0.290382969270657, 50.850937970937117],
              [-0.293065947685463, 50.853212974003682],
              [-0.295383666985131, 50.85405624652352],
              [-0.295114585431731, 50.854734005321824],
              [-0.293385600212822, 50.85566561677215],
              [-0.292730299205129, 50.856530177262712],
              [-0.292683311996223, 50.858478330286601],
              [-0.29416371971312, 50.859689787383985],
              [-0.296329490216634, 50.860600964860716],
              [-0.29898689496449, 50.861197309926069],
              [-0.301474449250756, 50.860899891007776],
              [-0.301820356049367, 50.861704424854068],
              [-0.301230508234802, 50.864093440579921],
              [-0.300793890794304, 50.864533149465757],
              [-0.299918346767265, 50.864693968285003],
              [-0.299487118506931, 50.864203848983998],
              [-0.296384703970032, 50.864249434751017],
              [-0.292533120657554, 50.862901716280881],
              [-0.290962848949442, 50.862747430089208],
              [-0.290415152266274, 50.863851874362886],
              [-0.287976756912919, 50.864158772808828],
              [-0.280071943800856, 50.863255633856113],
              [-0.276089367269687, 50.863432496618366],
              [-0.26951767454389, 50.863722847524194],
              [-0.262247315434381, 50.862353958103718],
              [-0.259246196715654, 50.86216174172403],
              [-0.256606361827455, 50.862341772034036],
              [-0.256463037877431, 50.865879389783828],
              [-0.258961600646089, 50.871213733181548],
              [-0.25533017294363, 50.871557906844949],
              [-0.249748636934849, 50.871030068280639],
              [-0.249005163182043, 50.87065468817692],
              [-0.249634818739708, 50.867997622961411],
              [-0.248500207209405, 50.864143161694102],
              [-0.244998219391931, 50.863815356001815],
              [-0.244743697643995, 50.863441004148754],
              [-0.241531822565982, 50.862882731705938],
              [-0.239780382757103, 50.861958795720696],
            ],
          ],
          [
            [
              [-0.366499450899519, 50.874745049238989],
              [-0.366065931879732, 50.874379254374588],
              [-0.364623569342837, 50.874532622207255],
              [-0.359897479502073, 50.872549861431999],
              [-0.355863944947499, 50.869556837066],
              [-0.353009410502248, 50.868287221262833],
              [-0.351969978026119, 50.867375927175466],
              [-0.349141910484202, 50.865967194283101],
              [-0.348697355254243, 50.865236053256524],
              [-0.349147239240902, 50.864448317995318],
              [-0.344577032245939, 50.863968996024923],
              [-0.343813437573442, 50.862940131317387],
              [-0.343362482850561, 50.862913945934416],
              [-0.339751929890215, 50.862546977311396],
              [-0.331609372207519, 50.863070276937428],
              [-0.325491473500787, 50.863835215647583],
              [-0.320471768054014, 50.865433127878411],
              [-0.316133462557336, 50.864675438767989],
              [-0.313618910970255, 50.864536608687757],
              [-0.313521166002036, 50.864921905362365],
              [-0.311454404910288, 50.86477870668142],
              [-0.309542930011224, 50.865253757487103],
              [-0.307624374173628, 50.866511985499365],
              [-0.30288558913245, 50.866140999975499],
              [-0.298662777954053, 50.866276469619535],
              [-0.299299058112646, 50.865154392941932],
              [-0.301528522964448, 50.86445660861537],
              [-0.302264136270215, 50.861340359607915],
              [-0.301501142766592, 50.860595408274456],
              [-0.298935965240292, 50.860917776727526],
              [-0.296340070845831, 50.860310637119667],
              [-0.293212578591949, 50.85857600477766],
              [-0.29335406126191, 50.856490737356424],
              [-0.295439267469087, 50.855181216932166],
              [-0.29612565066628, 50.854241551769995],
              [-0.295518902928751, 50.853503337267767],
              [-0.293323295673297, 50.852818336095289],
              [-0.291323967439147, 50.850962545013445],
              [-0.293069739714883, 50.848983493041203],
              [-0.295176172290416, 50.84918695673715],
              [-0.293032975111724, 50.848784203851075],
              [-0.29252502379259, 50.847249713877666],
              [-0.288679360798568, 50.845625849883788],
              [-0.28880624526887, 50.844060180862499],
              [-0.289626599092131, 50.841126006804203],
              [-0.289360052679415, 50.839131883904301],
              [-0.285505775943728, 50.834934799524149],
              [-0.28526665972097, 50.833709095663657],
              [-0.285590744080115, 50.833390998324866],
              [-0.283216703720829, 50.832992761955175],
              [-0.281012241660794, 50.831883805904589],
              [-0.281255639264939, 50.831597806927697],
              [-0.2808649988209, 50.831717058906129],
              [-0.280268273247153, 50.831338640031746],
              [-0.284690916418047, 50.829618570255619],
              [-0.285630163150684, 50.830415692073935],
              [-0.286563332080514, 50.830254929488291],
              [-0.286717495828872, 50.829883971005977],
              [-0.284274004704465, 50.829331848962383],
              [-0.278490743719165, 50.828973265435259],
              [-0.275658896674382, 50.82925340886711],
              [-0.271267450042893, 50.830236147398416],
              [-0.270022759445767, 50.830102584211389],
              [-0.269869786936868, 50.830591351481118],
              [-0.269617678695839, 50.830227879836677],
              [-0.268342637278039, 50.830646038784593],
              [-0.264173820108465, 50.830442897193855],
              [-0.264258908617783, 50.830981964157694],
              [-0.256109538258797, 50.830744430682408],
              [-0.256150529823583, 50.830444665883995],
              [-0.254635899772063, 50.830125237939171],
              [-0.25167353474509, 50.83032010862599],
              [-0.251225028544759, 50.830200070970093],
              [-0.251127091201053, 50.829248905925873],
              [-0.250087145388373, 50.82804709079938],
              [-0.249005480604094, 50.827923841662439],
              [-0.24825733422641, 50.826211968795107],
              [-0.248518126458307, 50.82649018132274],
              [-0.249258095615961, 50.826485099407122],
              [-0.249428007156498, 50.825890490478621],
              [-0.248059941557445, 50.824733195769724],
              [-0.249555786737515, 50.825852836912709],
              [-0.249438365236359, 50.82652467707603],
              [-0.249992444648653, 50.826592345361725],
              [-0.273354455508809, 50.827015065014365],
              [-0.277901064114743, 50.826938368315268],
              [-0.290574375165276, 50.825332370326514],
              [-0.297855465322791, 50.823811033001341],
              [-0.302016875028972, 50.823422904321561],
              [-0.311074812782066, 50.821587543108585],
              [-0.323931895091656, 50.819612765551916],
              [-0.328331358688516, 50.819101213986038],
              [-0.332722946118129, 50.817452633290451],
              [-0.334209606627226, 50.819080069386736],
              [-0.337222771834955, 50.820981962102621],
              [-0.338761445305765, 50.822071382266216],
              [-0.340780662693507, 50.822383403357684],
              [-0.340941078164346, 50.822744515979345],
              [-0.342898480250455, 50.8223172739023],
              [-0.343918957234794, 50.822728366293092],
              [-0.344204578024983, 50.823363747338419],
              [-0.349452261028615, 50.822556762963913],
              [-0.350766977959895, 50.822879326685516],
              [-0.351819972887967, 50.824909595448972],
              [-0.353287725872973, 50.825243285203825],
              [-0.3538031017976, 50.826011387070686],
              [-0.354186345600389, 50.826014994998502],
              [-0.354330979865166, 50.826828226378559],
              [-0.355491315634117, 50.82710359208744],
              [-0.355734228308549, 50.827706865260119],
              [-0.353220558254366, 50.829049180594005],
              [-0.354198053009253, 50.829598970155821],
              [-0.358368782875096, 50.830646082300341],
              [-0.357143113801295, 50.834332247799402],
              [-0.358863412347545, 50.834196376534329],
              [-0.359165188903513, 50.835593672694593],
              [-0.359590879983931, 50.836657262218132],
              [-0.3593633217426, 50.837962578093361],
              [-0.359758220121725, 50.83824422191131],
              [-0.35939242393805, 50.839684291362246],
              [-0.359703851041868, 50.841455838537215],
              [-0.359501310399998, 50.842452138547387],
              [-0.3585469410896, 50.843541286929124],
              [-0.360274294873702, 50.846252746154967],
              [-0.362096559863149, 50.84654363729139],
              [-0.362119684751471, 50.846897395238507],
              [-0.365441040634514, 50.846933173230305],
              [-0.365942024564539, 50.847352977891809],
              [-0.366886023514124, 50.849039828881338],
              [-0.367259353253221, 50.851814960182232],
              [-0.369022016153955, 50.855288500316945],
              [-0.367144247404205, 50.856250594864939],
              [-0.368073864345205, 50.858314945732886],
              [-0.367479015571897, 50.860538736649005],
              [-0.366778270971085, 50.860911143009432],
              [-0.366788600286138, 50.864031924109554],
              [-0.368197777173297, 50.866529257389658],
              [-0.371207672215879, 50.869792657126496],
              [-0.371533654392814, 50.870674939586095],
              [-0.371285796978803, 50.871669723253412],
              [-0.369833693650053, 50.873205269679111],
              [-0.366499450899519, 50.874745049238989],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000224",
        LAD13CDO: "45UC",
        LAD13NM: "Arun",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-0.529146416920798, 50.865552396548516],
              [-0.531685974051032, 50.864417973754072],
              [-0.539428704819813, 50.865934221311811],
              [-0.537312027115926, 50.866410425927285],
              [-0.536101713524488, 50.867530173799743],
              [-0.53570943985891, 50.872475917924184],
              [-0.536868660040572, 50.873809731394935],
              [-0.5322493982449, 50.873446875239132],
              [-0.527909125810988, 50.870681717417796],
              [-0.526497173916886, 50.869395882963772],
              [-0.525829979163136, 50.868290307189184],
              [-0.525919425571162, 50.867121441794453],
              [-0.529146416920798, 50.865552396548516],
            ],
          ],
          [
            [
              [-0.54419204142955, 50.890025762244377],
              [-0.545448514491257, 50.889491052831673],
              [-0.547139751359006, 50.889924891322856],
              [-0.547277903305612, 50.892202737079721],
              [-0.548545614403488, 50.892954131838117],
              [-0.54624044715055, 50.895429097545318],
              [-0.543444455623723, 50.896398764248623],
              [-0.543332680564896, 50.895286735495631],
              [-0.54401131134234, 50.894819471831291],
              [-0.543151846460908, 50.893608185441181],
              [-0.542858907410937, 50.891649452535333],
              [-0.54419204142955, 50.890025762244377],
            ],
          ],
          [
            [
              [-0.54371262788934, 50.896599955453034],
              [-0.544321696359997, 50.896357546275219],
              [-0.544140907488755, 50.896911058553307],
              [-0.543734687018531, 50.896986929077514],
              [-0.54371262788934, 50.896599955453034],
            ],
          ],
          [
            [
              [-0.494464150274756, 50.890860901060144],
              [-0.493140202200436, 50.885270831079403],
              [-0.490788369585762, 50.885282716189607],
              [-0.492442832750955, 50.891328533288636],
              [-0.493274621793511, 50.891416612037176],
              [-0.492919501610824, 50.892595511656566],
              [-0.491538027490518, 50.893280924577375],
              [-0.491227333983991, 50.894051209915304],
              [-0.490496875050361, 50.894082235833217],
              [-0.489307929632081, 50.894791695095137],
              [-0.487997818536676, 50.895948325555288],
              [-0.488163487554802, 50.896189685819657],
              [-0.486191120877243, 50.89693662862674],
              [-0.48673232753197, 50.897437362715486],
              [-0.483853843566372, 50.898102371784788],
              [-0.483591658868093, 50.897943387029798],
              [-0.482865669086862, 50.899103948749001],
              [-0.480288729407769, 50.898451711497039],
              [-0.479651999161934, 50.896344445727678],
              [-0.482043375860866, 50.894389900728271],
              [-0.475303147710126, 50.894351526650858],
              [-0.476611464292798, 50.88506349410018],
              [-0.473258201298827, 50.884788563585474],
              [-0.47157208383745, 50.88500661362832],
              [-0.464945335664388, 50.883978983793384],
              [-0.461928208750989, 50.889172336525959],
              [-0.462197329415768, 50.890628262126093],
              [-0.461298152732387, 50.892156026235099],
              [-0.45915941280361, 50.893627859525942],
              [-0.457331395930586, 50.895762052432303],
              [-0.455626289096644, 50.896754824746992],
              [-0.455381551221345, 50.896888281411854],
              [-0.45152637166545, 50.895474760449332],
              [-0.452435439601523, 50.892759217461112],
              [-0.452507617124931, 50.890881521722285],
              [-0.449550193115259, 50.882186439303766],
              [-0.447958539419219, 50.879731757124553],
              [-0.446877183132878, 50.880488981443378],
              [-0.440008882451425, 50.877534889271594],
              [-0.437047982517023, 50.882507135708522],
              [-0.434171050570876, 50.885379645326644],
              [-0.431077595168378, 50.88952349578463],
              [-0.431101233011196, 50.892798197767696],
              [-0.43054056323933, 50.892980415464365],
              [-0.427105793496733, 50.893089767313491],
              [-0.427402188061063, 50.891044234684955],
              [-0.427079335474492, 50.889484978766198],
              [-0.42646495144404, 50.889236583048124],
              [-0.42655679406033, 50.887860977658555],
              [-0.424671334088128, 50.886223085170016],
              [-0.42451916508257, 50.886477334950811],
              [-0.42279664940581, 50.886417202466291],
              [-0.422179315716281, 50.886720920576522],
              [-0.420361349473295, 50.886119875548253],
              [-0.417040225641796, 50.886162119194324],
              [-0.413177693826823, 50.885694194723023],
              [-0.412619067125712, 50.886569723292894],
              [-0.411764730752798, 50.88656619621915],
              [-0.412326106264902, 50.885317494771094],
              [-0.413185849633631, 50.879595172579258],
              [-0.412255342816385, 50.879661658669541],
              [-0.412111840132063, 50.88066333913887],
              [-0.408644730389464, 50.8799110616809],
              [-0.407890476645263, 50.881896350875728],
              [-0.405988635743301, 50.884180728732886],
              [-0.401484470369998, 50.886891714869364],
              [-0.395980458781297, 50.891914415284916],
              [-0.394319454890159, 50.892045362559962],
              [-0.393078608050287, 50.892734248375319],
              [-0.392740723144956, 50.893379799458764],
              [-0.388743562692537, 50.892352540051569],
              [-0.38853676444856, 50.889486273332636],
              [-0.386659329175041, 50.887282203708423],
              [-0.385519829882484, 50.888741323315024],
              [-0.381895342122446, 50.887027407244474],
              [-0.381728053452514, 50.886603309888315],
              [-0.380089463935574, 50.885600329385959],
              [-0.371782089270739, 50.88728337775381],
              [-0.363921727801463, 50.888486472735323],
              [-0.366541428652225, 50.884480737491138],
              [-0.366732544093025, 50.882227028125747],
              [-0.366121868947889, 50.879283113176918],
              [-0.366251929412461, 50.876905341662095],
              [-0.364054737148693, 50.876854781770056],
              [-0.363553156870042, 50.87624071149903],
              [-0.364623569342837, 50.874532622207255],
              [-0.366065931879732, 50.874379254374588],
              [-0.366499450899519, 50.874745049238989],
              [-0.369833693650053, 50.873205269679111],
              [-0.371285796978803, 50.871669723253412],
              [-0.371533654392814, 50.870674939586095],
              [-0.371207672215879, 50.869792657126496],
              [-0.368197777173297, 50.866529257389658],
              [-0.366788600286138, 50.864031924109554],
              [-0.366778270971085, 50.860911143009432],
              [-0.367479015571897, 50.860538736649005],
              [-0.371281572329746, 50.860779823260692],
              [-0.375150035938232, 50.861456074061245],
              [-0.37788485369245, 50.861471634130289],
              [-0.378870365189307, 50.862637353667509],
              [-0.381292499629024, 50.863231247569146],
              [-0.382810940914408, 50.862715405234233],
              [-0.388262038601469, 50.859537981032254],
              [-0.392482129728956, 50.859661834764715],
              [-0.395371186261126, 50.8591134394484],
              [-0.396947879660939, 50.858091899655243],
              [-0.403349506975794, 50.85649163854071],
              [-0.405466148609434, 50.854627516173679],
              [-0.406922255717451, 50.853589805815872],
              [-0.407305890063366, 50.852837817498354],
              [-0.409305636817713, 50.852270643685927],
              [-0.411178872552835, 50.853836690385833],
              [-0.415611209171398, 50.855188366131841],
              [-0.416096134884603, 50.856349669540315],
              [-0.415409865140086, 50.856685690735915],
              [-0.416794520595962, 50.85708219524961],
              [-0.420073031796563, 50.860089866441513],
              [-0.422818791170695, 50.860242116523658],
              [-0.424322815594914, 50.856933170196804],
              [-0.424172367895976, 50.854862715005758],
              [-0.422908304023365, 50.851775377785955],
              [-0.421983056028868, 50.8508608569553],
              [-0.423047108513209, 50.848122434565262],
              [-0.422043616267756, 50.845150119624492],
              [-0.42232491802687, 50.840684318769874],
              [-0.425299864115174, 50.839859359037298],
              [-0.431897882634224, 50.839710789760289],
              [-0.438569219810383, 50.840683375905385],
              [-0.446031898093897, 50.839862093760082],
              [-0.443369359275069, 50.837199706343839],
              [-0.445480786888494, 50.83678538998425],
              [-0.445708396197896, 50.836218255792545],
              [-0.44655667083693, 50.836333872201578],
              [-0.44595942949789, 50.834774600169602],
              [-0.443612711621688, 50.83308588360466],
              [-0.443725091413208, 50.831585524067357],
              [-0.445136003875317, 50.827500751206145],
              [-0.443669660670751, 50.821423399020375],
              [-0.442970761780282, 50.819855556094502],
              [-0.442264959791371, 50.81999002602285],
              [-0.442706984621671, 50.819164057174561],
              [-0.44196139413457, 50.817337479648856],
              [-0.440372125588788, 50.812841233739256],
              [-0.440138671550729, 50.8124774855768],
              [-0.439290727465637, 50.812493125134097],
              [-0.43838440948215, 50.810640989177749],
              [-0.436013773311013, 50.803208723420809],
              [-0.442373257849287, 50.802565356705536],
              [-0.450415552766751, 50.802740806095358],
              [-0.452917273305734, 50.802448455104013],
              [-0.454535034217218, 50.802192906977297],
              [-0.462502428794703, 50.802141700751434],
              [-0.473311727842323, 50.803067861940171],
              [-0.476891633677275, 50.802739639162098],
              [-0.484418281009122, 50.803022021316018],
              [-0.495202021744767, 50.802597968073236],
              [-0.507398311370323, 50.802642005761896],
              [-0.512893176820628, 50.802243634365162],
              [-0.522738398750233, 50.802134892865091],
              [-0.538098789187538, 50.802330179760837],
              [-0.54192405713974, 50.801852851672308],
              [-0.541939931512982, 50.801433970345869],
              [-0.543406600607713, 50.806249222949624],
              [-0.544665652446172, 50.807751497434431],
              [-0.547449122920539, 50.808706180342618],
              [-0.549754271769565, 50.810535276936257],
              [-0.552329103988452, 50.811498040005993],
              [-0.556287801832472, 50.811454462636661],
              [-0.559441554495718, 50.812337896020168],
              [-0.560244423125245, 50.813232726744062],
              [-0.560726538294095, 50.815604757856519],
              [-0.560716141240316, 50.816682001914046],
              [-0.56017325677697, 50.816932506332449],
              [-0.5609022858508, 50.818339026300457],
              [-0.562115083044279, 50.819157060831088],
              [-0.563679605529871, 50.819525261917697],
              [-0.566087358905064, 50.819432569982773],
              [-0.56779521677163, 50.818550640163181],
              [-0.5699468024949, 50.816617437547166],
              [-0.571161693270753, 50.816486633308948],
              [-0.573627959410769, 50.819686868779982],
              [-0.573608586930268, 50.820928575597023],
              [-0.572387934007899, 50.82231566758383],
              [-0.572357222390144, 50.823183123302925],
              [-0.57280601029389, 50.824016875171921],
              [-0.57565368774104, 50.825944711060679],
              [-0.575725804138198, 50.826747772670124],
              [-0.572495232576861, 50.828621121124449],
              [-0.571283841282956, 50.828813135955393],
              [-0.568093979650514, 50.828649032959177],
              [-0.566541770374203, 50.829165962197941],
              [-0.566211346447128, 50.830171824119788],
              [-0.567631334447348, 50.832074209154158],
              [-0.569969197054925, 50.832802542432233],
              [-0.570622818675854, 50.833394199987993],
              [-0.570529283884513, 50.834968637844753],
              [-0.569584721964455, 50.836228681573061],
              [-0.564571541421797, 50.837061034177438],
              [-0.563279110416367, 50.837756480335472],
              [-0.563253033299274, 50.838744496236799],
              [-0.565232984451124, 50.840268897267869],
              [-0.564831331962423, 50.841694752957345],
              [-0.565350708887605, 50.843933213696495],
              [-0.563272183061119, 50.8476946150234],
              [-0.562787537656138, 50.847748901081587],
              [-0.563102754088798, 50.847892174668168],
              [-0.562542028246072, 50.848942851405482],
              [-0.560512989878466, 50.851358553932513],
              [-0.558720282278767, 50.852091844284139],
              [-0.551741223756506, 50.853677255786948],
              [-0.550218324052607, 50.853215890320406],
              [-0.548942847653708, 50.851466179595455],
              [-0.546742579231893, 50.851090771343905],
              [-0.541933497345185, 50.852602774826138],
              [-0.54092483357766, 50.853285332047875],
              [-0.539772615975186, 50.855879805951432],
              [-0.538780514021475, 50.855801739841418],
              [-0.539023064370117, 50.856223852460374],
              [-0.539275627971151, 50.855961718754216],
              [-0.539597096381069, 50.856086248579629],
              [-0.538733892789382, 50.857248137882074],
              [-0.539072179814911, 50.858244306006405],
              [-0.541187527169898, 50.859255503496243],
              [-0.545452650811207, 50.859723314413387],
              [-0.546369150730083, 50.860316582194272],
              [-0.544039562601896, 50.866179782307285],
              [-0.543158013735817, 50.866377420966259],
              [-0.541903762699639, 50.866222367340342],
              [-0.532559969148038, 50.864189750352452],
              [-0.532566638101862, 50.863886768611259],
              [-0.530457859083974, 50.864383632237889],
              [-0.525402179939042, 50.867210234551315],
              [-0.525503288331379, 50.868555072687201],
              [-0.526796852322462, 50.870213535878577],
              [-0.529806723692336, 50.871983544434563],
              [-0.531162079035808, 50.873311791956077],
              [-0.533460287760385, 50.873901854189015],
              [-0.535792255038077, 50.873869976863155],
              [-0.540408691305083, 50.874461979180431],
              [-0.541574025679979, 50.87770054243871],
              [-0.540830375795921, 50.878390905648573],
              [-0.539858170487167, 50.87870699387112],
              [-0.539106402510311, 50.880701231866809],
              [-0.540113290386539, 50.881687771896232],
              [-0.541002644266126, 50.881932709184461],
              [-0.542535719844822, 50.88195815534673],
              [-0.543853731897373, 50.881449411994282],
              [-0.54742374740897, 50.883582065889726],
              [-0.54727458626238, 50.884447135245722],
              [-0.5454101554081, 50.884924828749391],
              [-0.541430067665612, 50.885225894476314],
              [-0.537578349703358, 50.886283845562531],
              [-0.536293416000915, 50.886184106573204],
              [-0.535641102347985, 50.885813507445313],
              [-0.534050676325503, 50.882447245731328],
              [-0.532847258752204, 50.881158715598403],
              [-0.530781482106339, 50.881755050664665],
              [-0.529297776079317, 50.881426107889453],
              [-0.527196189966102, 50.882976088137767],
              [-0.524814512063016, 50.882991879231206],
              [-0.52398908056752, 50.883920316805998],
              [-0.523790786470111, 50.88596641955953],
              [-0.520758734791352, 50.887659194390849],
              [-0.519302562039058, 50.889821540589679],
              [-0.514949662304656, 50.888950581150077],
              [-0.50299576571752, 50.896964468852651],
              [-0.499941101602895, 50.899890258452835],
              [-0.498539723303227, 50.898228319726634],
              [-0.494464150274756, 50.890860901060144],
            ],
          ],
          [
            [
              [-0.617407051072449, 50.905569782596153],
              [-0.608140558747221, 50.902385903165779],
              [-0.607724254303772, 50.902706483573617],
              [-0.60697792392544, 50.902457470181695],
              [-0.604854333866065, 50.901467172401162],
              [-0.593719630330531, 50.902811127433957],
              [-0.593211710789329, 50.901732150811426],
              [-0.590413537243551, 50.901852180794208],
              [-0.588541299343926, 50.901309769697626],
              [-0.58628608087892, 50.901111617948509],
              [-0.579595715188015, 50.899261500986732],
              [-0.575098155948777, 50.899850592238721],
              [-0.569830497226994, 50.899716937741481],
              [-0.559755986327531, 50.900372782736518],
              [-0.558579783509669, 50.90102104897715],
              [-0.558910314628519, 50.90178503511563],
              [-0.555699098232774, 50.902703105602519],
              [-0.554780036120787, 50.902575723303755],
              [-0.553326812476787, 50.901465978696294],
              [-0.551979750731736, 50.899870112946516],
              [-0.550516417795326, 50.898815963309495],
              [-0.549763176672748, 50.898755349110331],
              [-0.547391957982059, 50.899506460849992],
              [-0.546328532948421, 50.899450957743205],
              [-0.54383324112409, 50.898067316732622],
              [-0.543653617073942, 50.897534490009463],
              [-0.544869543876984, 50.896345490667777],
              [-0.546638071419113, 50.895537467575679],
              [-0.549081802192847, 50.892904141103408],
              [-0.55921168582196, 50.890037632485779],
              [-0.561228133115297, 50.887765693052707],
              [-0.558731427446669, 50.884268097392884],
              [-0.55544047398224, 50.88178132435705],
              [-0.548807418624405, 50.879952611506013],
              [-0.545011730971522, 50.880554657988505],
              [-0.542129938106932, 50.881750745579112],
              [-0.540837170816053, 50.881677937272421],
              [-0.539988331926032, 50.881228464729858],
              [-0.539435795981973, 50.880577652909132],
              [-0.539595347774855, 50.879793659610876],
              [-0.541897599672981, 50.878081392106161],
              [-0.541925106785338, 50.876607780179128],
              [-0.54086509753669, 50.874229370220462],
              [-0.537691027687309, 50.873737300770934],
              [-0.536355776679102, 50.87275921093341],
              [-0.536507991559864, 50.867807757542501],
              [-0.536856730452618, 50.867108874233509],
              [-0.538353069265476, 50.866384797107635],
              [-0.539686748289761, 50.866226126477386],
              [-0.542792304748896, 50.866757757591948],
              [-0.543897320735886, 50.866640249726927],
              [-0.544980614957322, 50.866261662750929],
              [-0.545406187580979, 50.865463889418251],
              [-0.54707268364816, 50.860219224357678],
              [-0.54557024440774, 50.859414519968617],
              [-0.540621627370666, 50.858767301044928],
              [-0.539740001953126, 50.858334500096312],
              [-0.539264468367777, 50.857637883449499],
              [-0.541163831422515, 50.853684913317643],
              [-0.541843412772967, 50.85303151727318],
              [-0.546496727869411, 50.851459122736365],
              [-0.547951377137031, 50.85141607740286],
              [-0.549124862612005, 50.852193282189134],
              [-0.549294644982588, 50.853223298005652],
              [-0.549636403086689, 50.853106139449785],
              [-0.550588276215377, 50.853747477753572],
              [-0.552151431426187, 50.853964726895953],
              [-0.559171791161215, 50.852238609970094],
              [-0.561395815026602, 50.85110774193307],
              [-0.565666377914071, 50.844246454658254],
              [-0.565401226236425, 50.842146916022742],
              [-0.565776298731247, 50.840336728566463],
              [-0.563734658645436, 50.838369119280152],
              [-0.563653836340497, 50.837809655696539],
              [-0.564532104983581, 50.837281778295491],
              [-0.568621164611764, 50.836854474289559],
              [-0.570755606826658, 50.835881507170583],
              [-0.571433071798867, 50.834344795677247],
              [-0.571225411673385, 50.833285569127028],
              [-0.570580270909114, 50.832555525735273],
              [-0.569453423233398, 50.832068682086216],
              [-0.568090577255479, 50.831591522044803],
              [-0.567357560699797, 50.830951211931861],
              [-0.56688233215529, 50.830100927666365],
              [-0.567147283836681, 50.829424305523418],
              [-0.568112875558305, 50.829098918165776],
              [-0.571081528874479, 50.829254016787409],
              [-0.573554591672464, 50.828758180074615],
              [-0.576029626302684, 50.827262286656897],
              [-0.576421446626942, 50.825770621659821],
              [-0.5769597888239, 50.82557664281552],
              [-0.576908294002138, 50.825308920784117],
              [-0.576297890295993, 50.825631520323697],
              [-0.575844459542451, 50.825367886154034],
              [-0.573183235187126, 50.823099698710941],
              [-0.574491448030608, 50.82037549905332],
              [-0.573093752950979, 50.817417678813747],
              [-0.573953738168237, 50.818266347463116],
              [-0.573265156086492, 50.816730904897824],
              [-0.571671122574459, 50.815795007398577],
              [-0.570573141043105, 50.815537848655119],
              [-0.570515736897734, 50.815790750353173],
              [-0.571464616856241, 50.815863524621463],
              [-0.572576683619418, 50.816404129291861],
              [-0.573094256657678, 50.817075048636887],
              [-0.571639074600948, 50.816054515169405],
              [-0.57025174673487, 50.815928707207583],
              [-0.568852957311087, 50.816503303418372],
              [-0.566269558876002, 50.818582264693738],
              [-0.564924264486294, 50.818982106246835],
              [-0.562937938164011, 50.818804776802239],
              [-0.561592268157201, 50.81773690362607],
              [-0.560882997392369, 50.812592202662756],
              [-0.556851374493292, 50.810623277783151],
              [-0.556015962364689, 50.810511319423973],
              [-0.55620660414056, 50.810865309015327],
              [-0.553587196193681, 50.810930890259378],
              [-0.551877208297906, 50.810642583626624],
              [-0.549652108973054, 50.809342420083489],
              [-0.550119374715797, 50.808966020552226],
              [-0.549624180700875, 50.809190089347815],
              [-0.549199795037815, 50.809006749356747],
              [-0.548674969172474, 50.808492111237385],
              [-0.548933479097238, 50.808119414591253],
              [-0.548686366739075, 50.807986840115127],
              [-0.548479847160868, 50.808422235528944],
              [-0.548047051055556, 50.808098494078422],
              [-0.548541078461461, 50.807728729015217],
              [-0.547635068476219, 50.807332548870612],
              [-0.547139120853934, 50.807672608943065],
              [-0.546520201755729, 50.80740140183913],
              [-0.544627887780267, 50.805632247849189],
              [-0.54467344934364, 50.80430273373468],
              [-0.544307216113164, 50.803848508637927],
              [-0.543585832109834, 50.803771157745551],
              [-0.542032126102569, 50.800249828248376],
              [-0.544099776986925, 50.79997437987258],
              [-0.556452983372425, 50.80024569625985],
              [-0.560401400888925, 50.799766589147012],
              [-0.570408695379773, 50.798277181716294],
              [-0.574427199985258, 50.797349703565061],
              [-0.577933739058586, 50.795753055957178],
              [-0.580180904744964, 50.795299295245577],
              [-0.587889963365402, 50.794592476658089],
              [-0.58928548935771, 50.794237945172938],
              [-0.5898407783763, 50.793891229777493],
              [-0.589600591320878, 50.793480039435885],
              [-0.590732687919815, 50.793296771808421],
              [-0.591084754709186, 50.792865758182316],
              [-0.592414267255473, 50.792911484466025],
              [-0.593325697102829, 50.792279471278533],
              [-0.594987539036053, 50.792455977703234],
              [-0.599113395073433, 50.791558622003073],
              [-0.603778943472489, 50.791065975401686],
              [-0.617004040710239, 50.790665037476067],
              [-0.621085977233834, 50.78960091295378],
              [-0.62444010985237, 50.789255606850155],
              [-0.627393859176475, 50.788482819470595],
              [-0.628467507298075, 50.788008921511896],
              [-0.630131234510524, 50.787828823520321],
              [-0.642299232722515, 50.787393717549769],
              [-0.647143656881285, 50.787111858388123],
              [-0.654076042155105, 50.786147051605198],
              [-0.65587716133099, 50.784682163841573],
              [-0.659598377786316, 50.78397945567859],
              [-0.671895347632972, 50.781950077587545],
              [-0.682282044184349, 50.780020148756975],
              [-0.688261064411869, 50.779504784744489],
              [-0.694161526096055, 50.779106044109056],
              [-0.701434238469862, 50.777860755622328],
              [-0.702593995321959, 50.777109251615371],
              [-0.716514568784942, 50.774490692594647],
              [-0.728559259844454, 50.771328448882336],
              [-0.732357557193472, 50.769844569463835],
              [-0.737176856215337, 50.767351814957244],
              [-0.751243396590179, 50.758479647348395],
              [-0.752620388792694, 50.758201208584957],
              [-0.754310443696166, 50.759053823893233],
              [-0.753724748712057, 50.760126731174957],
              [-0.75311503312566, 50.760448460573798],
              [-0.752773994107796, 50.760210096253843],
              [-0.751113323087774, 50.760170743755232],
              [-0.750519899422329, 50.760465654985076],
              [-0.749683568992217, 50.761894678945474],
              [-0.750029479452918, 50.762109722574849],
              [-0.749885894330358, 50.761698101554579],
              [-0.750489487197354, 50.762033713969608],
              [-0.750403489583637, 50.762441076287445],
              [-0.750393629415227, 50.762598348681706],
              [-0.750261697158858, 50.763878441854018],
              [-0.749711614331247, 50.764194497292202],
              [-0.751272208985441, 50.764429745195166],
              [-0.750384449828628, 50.764167533875103],
              [-0.750922791360468, 50.763972755421314],
              [-0.752169832575432, 50.76528109839203],
              [-0.754315809924111, 50.76542094494345],
              [-0.756908320498547, 50.767653690682863],
              [-0.757006687700625, 50.769828350999134],
              [-0.75645192084502, 50.770159676226889],
              [-0.756185023441899, 50.769475160071678],
              [-0.756274891884194, 50.770257611324126],
              [-0.755892918761643, 50.77038303747846],
              [-0.757260881377988, 50.769975846906206],
              [-0.755807192123932, 50.770674396115943],
              [-0.755414338363387, 50.771958004809385],
              [-0.760590390130164, 50.77283147876242],
              [-0.763110816910128, 50.772930174803562],
              [-0.764586203971969, 50.773183224705079],
              [-0.764816225242077, 50.773657793259439],
              [-0.764537702486469, 50.775455242916038],
              [-0.764114208337684, 50.775971451770552],
              [-0.763186983632643, 50.776172960211028],
              [-0.763651926148835, 50.777689612037889],
              [-0.761287952040348, 50.777779644484802],
              [-0.759627685117669, 50.776896876300441],
              [-0.759011279896936, 50.776875032436159],
              [-0.758858024242152, 50.779235863451397],
              [-0.759794140761079, 50.780524623591283],
              [-0.758358686786161, 50.780737776420033],
              [-0.757691499232114, 50.781238777736512],
              [-0.75756172698521, 50.782228423958188],
              [-0.756815953712436, 50.782375158527742],
              [-0.756061057838894, 50.783774515376322],
              [-0.751157780382818, 50.781458591105562],
              [-0.749975935202997, 50.781414457815671],
              [-0.749600613450492, 50.78197789398871],
              [-0.750122884135695, 50.782606705715779],
              [-0.751713543265864, 50.783334183952135],
              [-0.751041038935575, 50.785148967385979],
              [-0.751664944164077, 50.785319317662051],
              [-0.752865706482769, 50.784976037596074],
              [-0.753856866609886, 50.78520605814191],
              [-0.754996848877752, 50.786349547959055],
              [-0.755111243309657, 50.790696180720374],
              [-0.749302578782786, 50.790651141384743],
              [-0.745216946488043, 50.79153624656113],
              [-0.743927285692124, 50.791270564990491],
              [-0.742694446196828, 50.79158463020805],
              [-0.7426769173508, 50.791338932601249],
              [-0.741992973440943, 50.791289292607637],
              [-0.741943014072957, 50.79156483877825],
              [-0.74059512520885, 50.791460366279836],
              [-0.739962820721969, 50.791917577384652],
              [-0.738530052389979, 50.792014506670917],
              [-0.738943729404056, 50.792986624975597],
              [-0.73794057831096, 50.793672734600882],
              [-0.736461127063992, 50.793920215935081],
              [-0.735446404658549, 50.793297698265548],
              [-0.734725221198541, 50.793733233831659],
              [-0.735639273514504, 50.795299830307286],
              [-0.737429225742194, 50.796169052049727],
              [-0.738509436062319, 50.795679827282228],
              [-0.738893950958419, 50.796102158181888],
              [-0.738322172888421, 50.796996174455771],
              [-0.73939537025427, 50.797137274061534],
              [-0.739279399670702, 50.797650419779501],
              [-0.736737696442764, 50.798240745355102],
              [-0.73615285173025, 50.798669672059475],
              [-0.73399163186937, 50.802243434858099],
              [-0.733930490891473, 50.803088111738873],
              [-0.735360046335251, 50.804174679196812],
              [-0.735499451189398, 50.805740068910481],
              [-0.73619994515208, 50.806551630410567],
              [-0.736080870706534, 50.807019773874742],
              [-0.735477106259196, 50.807041110307075],
              [-0.735865489301284, 50.807953609133719],
              [-0.734760534441803, 50.809452452015364],
              [-0.732080501123634, 50.808887378921142],
              [-0.730055388301011, 50.809191800316377],
              [-0.723955766783345, 50.807864437188222],
              [-0.722337797172123, 50.807343124284976],
              [-0.719438677410686, 50.805249250300001],
              [-0.718974677093807, 50.805298114783305],
              [-0.718395723047492, 50.806384404949604],
              [-0.71857682858356, 50.807338751380669],
              [-0.713320092058224, 50.808310598085853],
              [-0.713394234555706, 50.808815022984888],
              [-0.711121974070351, 50.807986871082029],
              [-0.709908823302314, 50.808180302661391],
              [-0.708017791197866, 50.809794304423761],
              [-0.70734415604597, 50.810905571316347],
              [-0.70607653059777, 50.811628946109408],
              [-0.706262877440807, 50.811819865174982],
              [-0.705642702533866, 50.811713165026781],
              [-0.704017237119202, 50.812183441662825],
              [-0.70094559236835, 50.813360640427653],
              [-0.689917159553793, 50.812824558650739],
              [-0.68903830455875, 50.816939774942483],
              [-0.68672798994497, 50.819545145309213],
              [-0.686628234119965, 50.821127688023338],
              [-0.686239400613118, 50.822791510108729],
              [-0.687722567724195, 50.825594225116305],
              [-0.688065543519956, 50.828706954251125],
              [-0.690235758026912, 50.832898675456867],
              [-0.69254373770453, 50.835426398910506],
              [-0.693020853562612, 50.837207853159285],
              [-0.692750039818745, 50.843088911085403],
              [-0.692325524120725, 50.84404819967996],
              [-0.69419044253193, 50.844813696571251],
              [-0.693962596451155, 50.84590289090962],
              [-0.69368523703293, 50.846526595182269],
              [-0.691062189469781, 50.846354218745141],
              [-0.690596951860061, 50.847395779181355],
              [-0.690959089637332, 50.849226308686532],
              [-0.692051916940416, 50.849668423255842],
              [-0.690706638347299, 50.849567007407188],
              [-0.689382785975667, 50.851996430760337],
              [-0.686134070211898, 50.854717140992364],
              [-0.681443201883647, 50.861859491927071],
              [-0.681145543004589, 50.863294098815807],
              [-0.671762372898546, 50.864859489312963],
              [-0.662528156331822, 50.865269341889523],
              [-0.656941445677874, 50.866278951698256],
              [-0.65055715010215, 50.866799752739652],
              [-0.650359583367538, 50.867145495633352],
              [-0.651196635074397, 50.868474436137859],
              [-0.65231460168, 50.872039560925444],
              [-0.652595767222728, 50.874660650608284],
              [-0.652140229432999, 50.875966561440549],
              [-0.652598479082911, 50.87890444640567],
              [-0.654034060733866, 50.879351768586872],
              [-0.652764934887757, 50.880871322123035],
              [-0.652812187932146, 50.881451011824588],
              [-0.650361934390059, 50.883550435817646],
              [-0.64981389790289, 50.88578873208737],
              [-0.658223781674335, 50.887545785212623],
              [-0.657787909576294, 50.902415932672],
              [-0.65525253245016, 50.903143960561145],
              [-0.654734112031485, 50.904896997879696],
              [-0.654965945236387, 50.90568654647894],
              [-0.651999496740813, 50.905869064972947],
              [-0.6485982860573, 50.905396290826516],
              [-0.648844596395252, 50.904011537929819],
              [-0.648313371469406, 50.902366883551281],
              [-0.649223217737003, 50.900019475250524],
              [-0.646748285048038, 50.90003578016227],
              [-0.644602297345024, 50.900771686851535],
              [-0.641727425694444, 50.900944214908932],
              [-0.638550073060809, 50.902466577782157],
              [-0.63712771124685, 50.901790803486001],
              [-0.636284561806085, 50.902952734213528],
              [-0.63393281089508, 50.90478047867586],
              [-0.630606576715895, 50.905202850561835],
              [-0.628122936798103, 50.904779805498997],
              [-0.62514370345636, 50.905008254498199],
              [-0.623190481795427, 50.904638111995787],
              [-0.617407051072449, 50.905569782596153],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000225",
        LAD13CDO: "45UD",
        LAD13NM: "Chichester",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-0.753724748712057, 50.760126731174957],
              [-0.754310443696166, 50.759053823893233],
              [-0.754578401955447, 50.759428097555748],
              [-0.753724748712057, 50.760126731174957],
            ],
          ],
          [
            [
              [-0.911602120257242, 50.801023659355174],
              [-0.911042511258393, 50.800129043157483],
              [-0.910305060117416, 50.800170719948916],
              [-0.910500858883976, 50.7998667906975],
              [-0.908835905480615, 50.799031981783557],
              [-0.908516028081149, 50.799301474056989],
              [-0.909048990624089, 50.800301969442181],
              [-0.90828624218536, 50.800637463891569],
              [-0.908402776163773, 50.80123927799989],
              [-0.907408083426339, 50.801540222261863],
              [-0.906908028793936, 50.801015750792352],
              [-0.90711357041112, 50.799504175493375],
              [-0.908019776438924, 50.798614271651068],
              [-0.909386264225559, 50.798919320600753],
              [-0.911568299959594, 50.800035926685531],
              [-0.911969193624449, 50.801777088049079],
              [-0.911515597968864, 50.801877174842147],
              [-0.911602120257242, 50.801023659355174],
            ],
          ],
          [
            [
              [-0.883287962119673, 50.812314660122674],
              [-0.888405293396246, 50.810864408579924],
              [-0.888653937590004, 50.81103314497981],
              [-0.883287962119673, 50.812314660122674],
            ],
          ],
          [
            [
              [-0.92331336787404, 50.830614440490606],
              [-0.923528991403635, 50.830438371695621],
              [-0.923059910244388, 50.830583324947412],
              [-0.923138711611062, 50.830244123507121],
              [-0.922870189207088, 50.831238049874464],
              [-0.922251941569373, 50.831138817021021],
              [-0.920282961885839, 50.82982295391654],
              [-0.919818328918819, 50.828851926040933],
              [-0.92026127078117, 50.828608722199711],
              [-0.917878851142811, 50.827802482059887],
              [-0.916332522244615, 50.827560615447702],
              [-0.91452834548635, 50.82778485670017],
              [-0.912081387457146, 50.827378977567214],
              [-0.910083040589818, 50.823379204276925],
              [-0.908320313906908, 50.822683768967352],
              [-0.908454650710483, 50.822097793100987],
              [-0.909561367352404, 50.821760116465974],
              [-0.909798462499971, 50.82121107050375],
              [-0.908641270577556, 50.819451146476155],
              [-0.907901152298843, 50.816550324520193],
              [-0.9082975940892, 50.815072012304775],
              [-0.909547093289362, 50.813411024560857],
              [-0.909425937775849, 50.808799255031168],
              [-0.910160250718273, 50.807190098566188],
              [-0.911187059688975, 50.806122339355916],
              [-0.910211701637118, 50.804124012547469],
              [-0.911091918463706, 50.803315677781619],
              [-0.917347501616527, 50.80478846444413],
              [-0.92089190860514, 50.806051519558494],
              [-0.922080409164596, 50.806950099367818],
              [-0.922341142771163, 50.808078412912842],
              [-0.92490039320087, 50.810168577050142],
              [-0.931677187504417, 50.813678671398527],
              [-0.934064645561597, 50.814304822399599],
              [-0.942592041254313, 50.815407585362237],
              [-0.94234549649697, 50.816267763482024],
              [-0.939619862078488, 50.816838354024569],
              [-0.938623902736508, 50.817815813359253],
              [-0.939024484015692, 50.818994818534236],
              [-0.941645460315907, 50.821050995817124],
              [-0.940131413315972, 50.821959019570762],
              [-0.939869747133501, 50.822981824480266],
              [-0.942405631772389, 50.824353755501164],
              [-0.941780008841829, 50.824708696293534],
              [-0.94112317136648, 50.826317848295758],
              [-0.94144645562936, 50.827530314423022],
              [-0.939500775376496, 50.830308515706513],
              [-0.938648811481305, 50.830871814505784],
              [-0.93644280393837, 50.829625562550042],
              [-0.932662958551257, 50.829220102288339],
              [-0.932077099155929, 50.828821756879869],
              [-0.92975766131694, 50.82885356767644],
              [-0.928751136017119, 50.829217535159124],
              [-0.931478502163223, 50.828919695335429],
              [-0.932282984875751, 50.829382094481133],
              [-0.929507808574579, 50.830093184172604],
              [-0.926069127795054, 50.831559796025132],
              [-0.924049755767462, 50.831689578342029],
              [-0.923142367499533, 50.831319698328748],
              [-0.92331336787404, 50.830614440490606],
            ],
          ],
          [
            [
              [-0.536423453729452, 50.913381435396886],
              [-0.542597437553853, 50.913374127977441],
              [-0.540326882472428, 50.914108368501971],
              [-0.539328083003485, 50.915477197353667],
              [-0.537521462842724, 50.915883547463103],
              [-0.536307876106394, 50.913800857553085],
              [-0.536423453729452, 50.913381435396886],
            ],
          ],
          [
            [
              [-0.490084733392735, 51.089709235428685],
              [-0.488748213415621, 51.086597559248453],
              [-0.48906540790048, 51.084969473082097],
              [-0.487171111391297, 51.080011544179818],
              [-0.487404653026185, 51.078891369075627],
              [-0.486503245574629, 51.070201671191541],
              [-0.485129720545975, 51.069340356208258],
              [-0.485119222553712, 51.068831228383885],
              [-0.484293980430741, 51.068620890960929],
              [-0.484528637772982, 51.06803490569969],
              [-0.482169996416911, 51.068146389441772],
              [-0.482343096861383, 51.067566803334657],
              [-0.481623226382231, 51.067365009101124],
              [-0.48115104962146, 51.067750061354168],
              [-0.480154683438168, 51.067841427310206],
              [-0.479737074756008, 51.067300925570194],
              [-0.4787802835531, 51.067317254985937],
              [-0.478980886797195, 51.066640010038974],
              [-0.47844754324762, 51.066539543809938],
              [-0.478938323289567, 51.065804924618526],
              [-0.47866430258607, 51.065500099849551],
              [-0.479347267034589, 51.064866900791507],
              [-0.479390396754503, 51.063594081443114],
              [-0.480630090242261, 51.06373969822431],
              [-0.481395417960486, 51.064513132205157],
              [-0.482212038962711, 51.064460789024359],
              [-0.483555081697994, 51.063715631143396],
              [-0.483001976515924, 51.063085253392458],
              [-0.483996121328116, 51.063058582899295],
              [-0.484561816906563, 51.062473292998476],
              [-0.483474837845816, 51.061410635135054],
              [-0.484361703943826, 51.061040843401166],
              [-0.484313856786965, 51.060670618889901],
              [-0.483150593838931, 51.06041542121342],
              [-0.483526155583627, 51.05979619383055],
              [-0.483207878061813, 51.058837028389881],
              [-0.484114781127166, 51.058900951728461],
              [-0.484312179985857, 51.058667900169389],
              [-0.483584807295373, 51.057345516361181],
              [-0.484175405581633, 51.057000659081773],
              [-0.483829705679815, 51.056488081762126],
              [-0.482674480248599, 51.056383162847247],
              [-0.482008166233586, 51.055901489439677],
              [-0.479639883109434, 51.057066751741239],
              [-0.4782386496385, 51.05719689829354],
              [-0.477909596254303, 51.057788837095792],
              [-0.477509553356224, 51.057281828144163],
              [-0.476389668954602, 51.057360760823791],
              [-0.475678790594286, 51.056412642493662],
              [-0.477146618488299, 51.054382313414457],
              [-0.477002890311106, 51.053065690872124],
              [-0.477778915871548, 51.052722382483999],
              [-0.47768080716041, 51.052059233282819],
              [-0.476524112352167, 51.051872399118658],
              [-0.478150564004897, 51.050612111181813],
              [-0.478173455809578, 51.050131293346681],
              [-0.477629837181689, 51.050001911439715],
              [-0.479240836303448, 51.048164967179623],
              [-0.480036159575394, 51.048275133310682],
              [-0.479644345766474, 51.047300610833545],
              [-0.478769868427499, 51.046994267410575],
              [-0.479005552031269, 51.045025208018593],
              [-0.480522823599356, 51.043778753920712],
              [-0.481491626194031, 51.043903755803491],
              [-0.481420286646203, 51.042682502119632],
              [-0.48366091843909, 51.04160187953736],
              [-0.483884462501638, 51.040390747762402],
              [-0.48277938145903, 51.03863179717105],
              [-0.481189580980948, 51.038133627447607],
              [-0.479012120504368, 51.038028869722872],
              [-0.4777391655267, 51.038309958656427],
              [-0.477276929982295, 51.038878579245704],
              [-0.476086513934976, 51.038773134332821],
              [-0.475404107565709, 51.038096065975175],
              [-0.475926600962947, 51.036299816294161],
              [-0.474459831328276, 51.035664668708982],
              [-0.474006620473153, 51.034004067892354],
              [-0.473219954228816, 51.033855303146254],
              [-0.474071293302265, 51.033033684734647],
              [-0.475523452522087, 51.0330247593959],
              [-0.476156157541621, 51.032475454779465],
              [-0.476304678373278, 51.030773246417361],
              [-0.475805053511328, 51.030092267102638],
              [-0.474893693406023, 51.029833972589053],
              [-0.476155087384226, 51.028463729980402],
              [-0.475195596469039, 51.028369379226461],
              [-0.474978361383415, 51.028079672392238],
              [-0.475489311009327, 51.027023383706769],
              [-0.476284183543281, 51.027049038697612],
              [-0.476593366937946, 51.026665477676922],
              [-0.475170919739523, 51.025470659424016],
              [-0.475873785128522, 51.025431267420466],
              [-0.475925334721536, 51.024815928922543],
              [-0.476696859577263, 51.024726168178269],
              [-0.476533403728495, 51.023492015483988],
              [-0.477631141553794, 51.023062973885786],
              [-0.477079495841811, 51.021273400474222],
              [-0.476061044093423, 51.02145976427181],
              [-0.475918620130699, 51.020889557708514],
              [-0.476868774371656, 51.020611477037903],
              [-0.477084401157531, 51.019861584679944],
              [-0.477932785517296, 51.019512024673887],
              [-0.478675084235517, 51.019397589766804],
              [-0.479362846997482, 51.019686220061601],
              [-0.480628712798957, 51.019475167077054],
              [-0.48162227820968, 51.018390053273087],
              [-0.483487652141206, 51.017937655798768],
              [-0.484186191309481, 51.018202116852635],
              [-0.485372818246704, 51.016482781045056],
              [-0.486594414133426, 51.016752208076056],
              [-0.486997071641656, 51.015857237040997],
              [-0.485663350688912, 51.014128617545282],
              [-0.484470220199154, 51.013469259066071],
              [-0.484329811157031, 51.012178759645337],
              [-0.483519534077055, 51.01162777461326],
              [-0.484315182248938, 51.01087820069823],
              [-0.485454525704366, 51.010648368514509],
              [-0.486246040642869, 51.010111859026011],
              [-0.489496700964263, 51.009568491797289],
              [-0.491168882128962, 51.010312224513541],
              [-0.492507711207694, 51.009819609997734],
              [-0.492543480885523, 51.010651012311762],
              [-0.491954975680142, 51.010863743453044],
              [-0.492466615678098, 51.011175203936197],
              [-0.493669443446564, 51.010832800742477],
              [-0.494569921723802, 51.011286854939065],
              [-0.494649323268991, 51.010727622552899],
              [-0.495972106435034, 51.010377748258087],
              [-0.496318977434836, 51.011024303074009],
              [-0.496921112339542, 51.011139065508871],
              [-0.498926221545726, 51.010362678803425],
              [-0.498787708571938, 51.009398663114261],
              [-0.499126486764059, 51.009104452274336],
              [-0.501122387858757, 51.009715508249336],
              [-0.501727438104394, 51.00925294178645],
              [-0.503267863889444, 51.00904696370241],
              [-0.503607050263793, 51.008429002289517],
              [-0.502728241339565, 51.008053533282151],
              [-0.504014868696515, 51.007582600693169],
              [-0.504392956952388, 51.006286174002639],
              [-0.505155806672139, 51.006055826917034],
              [-0.506641256476941, 51.006759178794638],
              [-0.507496765599362, 51.007638820081041],
              [-0.508397239848443, 51.007695283288641],
              [-0.509276489192491, 51.008147154372558],
              [-0.510561426567152, 51.007949511152724],
              [-0.511064977798996, 51.006735598703173],
              [-0.510804468881314, 51.005834791633681],
              [-0.511517492455857, 51.005155923798505],
              [-0.510938490542218, 51.003919222382066],
              [-0.512561383296184, 51.002420101003949],
              [-0.513160741244559, 51.002218196158488],
              [-0.513926706476738, 51.002422186870533],
              [-0.514769807030675, 51.00141581497293],
              [-0.516334455589606, 51.000753136333152],
              [-0.516693215413282, 50.999647972630399],
              [-0.517433965048811, 50.999211328746924],
              [-0.517669928072409, 50.998559642451369],
              [-0.517206041701814, 50.997240798000362],
              [-0.516730815253098, 50.997127750583495],
              [-0.516512030520125, 50.997464003754871],
              [-0.515909707003086, 50.997493224955718],
              [-0.515983345051324, 50.996703687484555],
              [-0.515238881829872, 50.996141167574656],
              [-0.513015021301757, 50.996299036826414],
              [-0.511968460001251, 50.995871140676435],
              [-0.511126316493239, 50.996314551609316],
              [-0.50883028966582, 50.996100015943682],
              [-0.507747563813453, 50.995068198289268],
              [-0.508078102854925, 50.994048131205439],
              [-0.507088012910024, 50.993772891501834],
              [-0.50741942655502, 50.993303201371901],
              [-0.506746751301387, 50.992995142641441],
              [-0.507350457267789, 50.990433591503638],
              [-0.509900870191378, 50.989859129881864],
              [-0.510386594007441, 50.99017557855948],
              [-0.515074250826591, 50.990181297812349],
              [-0.516526179578147, 50.989600818027682],
              [-0.517361571689763, 50.989811064756374],
              [-0.517987959127021, 50.990812725012709],
              [-0.518898947371973, 50.990663305368813],
              [-0.519270534976094, 50.990135637622117],
              [-0.521215373703108, 50.990318545906383],
              [-0.522160029641386, 50.988659624001009],
              [-0.523666518760428, 50.9887802907242],
              [-0.52369985834536, 50.987594552274665],
              [-0.524942128880855, 50.986880022862969],
              [-0.525181540069895, 50.984270615566132],
              [-0.524555891832166, 50.982344534546804],
              [-0.52329070010562, 50.982258403822449],
              [-0.525243710654736, 50.980464714871346],
              [-0.52885682647843, 50.979190117361249],
              [-0.529388798510171, 50.978945014888744],
              [-0.529839682403772, 50.977839170127631],
              [-0.52914957306827, 50.975064271808264],
              [-0.527072075036112, 50.974456254920085],
              [-0.526791706567383, 50.973998578452388],
              [-0.528359876307842, 50.973414922318469],
              [-0.528512410244576, 50.972148846416012],
              [-0.530991439513914, 50.967069387931765],
              [-0.53052138785366, 50.966304474947613],
              [-0.526918243351618, 50.963965000820487],
              [-0.527749191169926, 50.96340173012954],
              [-0.527738960486005, 50.961472622952407],
              [-0.529722029974037, 50.960226904528049],
              [-0.531451943617756, 50.959916829392704],
              [-0.532787181910854, 50.958573882424488],
              [-0.535500675408808, 50.957275188518608],
              [-0.535039345937114, 50.956595834401831],
              [-0.53555024545301, 50.955743418593521],
              [-0.535386463765312, 50.955155926532782],
              [-0.534499557966166, 50.954608824048393],
              [-0.534330270089207, 50.954015865488316],
              [-0.532379285292739, 50.953984172012355],
              [-0.532425314004323, 50.953155603674062],
              [-0.533037266607193, 50.952621018707887],
              [-0.533772425975866, 50.950251623729386],
              [-0.534948898897043, 50.949781669100524],
              [-0.535130441113405, 50.94935048758785],
              [-0.53752227439588, 50.948892147381123],
              [-0.537722704169772, 50.948221087501324],
              [-0.54021173589319, 50.94811103595724],
              [-0.542235594512288, 50.94858146411422],
              [-0.542903692273436, 50.948248969813626],
              [-0.544348988036886, 50.948146487421553],
              [-0.545361626853353, 50.950275135982409],
              [-0.54443232189227, 50.95154055096841],
              [-0.545242774768467, 50.952075834279817],
              [-0.544971190144421, 50.953067965846046],
              [-0.545644785424425, 50.95383805442367],
              [-0.546966910367331, 50.95449480469108],
              [-0.55023265518096, 50.953821350842396],
              [-0.551042960623019, 50.952808014177663],
              [-0.551621841657175, 50.952551702310394],
              [-0.552335991809275, 50.952661276747079],
              [-0.553445677340482, 50.951999659122571],
              [-0.55412335407419, 50.952275139534805],
              [-0.555634692150953, 50.952158046690847],
              [-0.557407677621766, 50.951912870039664],
              [-0.557567034317731, 50.951502064265554],
              [-0.559548182110719, 50.951476154656149],
              [-0.560106420950462, 50.950733929982121],
              [-0.561490990107866, 50.950017171088753],
              [-0.563699378489799, 50.949359990853324],
              [-0.564666330390394, 50.948147945190627],
              [-0.565805784530762, 50.94581569631476],
              [-0.568788369810188, 50.942420594470086],
              [-0.568540530740284, 50.940015552610596],
              [-0.568848443654348, 50.93986105122287],
              [-0.568932656295676, 50.935216346924456],
              [-0.568136068567436, 50.933952071277112],
              [-0.565092083686786, 50.931265395008992],
              [-0.561462504245963, 50.92990597456626],
              [-0.557643225407844, 50.929073780701898],
              [-0.556007411691454, 50.925835892975932],
              [-0.556654840764688, 50.924869962268666],
              [-0.556088742660529, 50.923484346345049],
              [-0.55250020725984, 50.92353524277879],
              [-0.552331128866243, 50.922977382917907],
              [-0.550576465474631, 50.923033854695092],
              [-0.550343347284429, 50.922614587524656],
              [-0.54537470224847, 50.920385496547304],
              [-0.542631041799499, 50.918765827200716],
              [-0.537774527911724, 50.917114256430594],
              [-0.537164359700533, 50.916474408401371],
              [-0.539667150501726, 50.915643311850936],
              [-0.540631101151618, 50.914342391133218],
              [-0.542667719117776, 50.913674470909349],
              [-0.54558095055448, 50.914362785081408],
              [-0.548535065714861, 50.914379760388599],
              [-0.54967410886159, 50.91411872970869],
              [-0.550651622975225, 50.913745069657161],
              [-0.551488761153695, 50.913493762888983],
              [-0.552163623969363, 50.912695464844404],
              [-0.553240663195275, 50.909063968733008],
              [-0.556127666117632, 50.905308273226439],
              [-0.555459155741084, 50.903287377996477],
              [-0.553326812476787, 50.901465978696294],
              [-0.554780036120787, 50.902575723303755],
              [-0.555699098232774, 50.902703105602519],
              [-0.558910314628519, 50.90178503511563],
              [-0.558579783509669, 50.90102104897715],
              [-0.559755986327531, 50.900372782736518],
              [-0.569830497226994, 50.899716937741481],
              [-0.575098155948777, 50.899850592238721],
              [-0.579595715188015, 50.899261500986732],
              [-0.58628608087892, 50.901111617948509],
              [-0.588541299343926, 50.901309769697626],
              [-0.590413537243551, 50.901852180794208],
              [-0.593211710789329, 50.901732150811426],
              [-0.593719630330531, 50.902811127433957],
              [-0.604854333866065, 50.901467172401162],
              [-0.60697792392544, 50.902457470181695],
              [-0.607724254303772, 50.902706483573617],
              [-0.608140558747221, 50.902385903165779],
              [-0.617407051072449, 50.905569782596153],
              [-0.623190481795427, 50.904638111995787],
              [-0.62514370345636, 50.905008254498199],
              [-0.628122936798103, 50.904779805498997],
              [-0.630606576715895, 50.905202850561835],
              [-0.63393281089508, 50.90478047867586],
              [-0.636284561806085, 50.902952734213528],
              [-0.63712771124685, 50.901790803486001],
              [-0.638550073060809, 50.902466577782157],
              [-0.641727425694444, 50.900944214908932],
              [-0.644602297345024, 50.900771686851535],
              [-0.646748285048038, 50.90003578016227],
              [-0.649223217737003, 50.900019475250524],
              [-0.648313371469406, 50.902366883551281],
              [-0.648844596395252, 50.904011537929819],
              [-0.6485982860573, 50.905396290826516],
              [-0.651999496740813, 50.905869064972947],
              [-0.654965945236387, 50.90568654647894],
              [-0.654734112031485, 50.904896997879696],
              [-0.65525253245016, 50.903143960561145],
              [-0.657787909576294, 50.902415932672],
              [-0.658223781674335, 50.887545785212623],
              [-0.64981389790289, 50.88578873208737],
              [-0.650361934390059, 50.883550435817646],
              [-0.652812187932146, 50.881451011824588],
              [-0.652764934887757, 50.880871322123035],
              [-0.654034060733866, 50.879351768586872],
              [-0.652598479082911, 50.87890444640567],
              [-0.652140229432999, 50.875966561440549],
              [-0.652595767222728, 50.874660650608284],
              [-0.65231460168, 50.872039560925444],
              [-0.651196635074397, 50.868474436137859],
              [-0.650359583367538, 50.867145495633352],
              [-0.65055715010215, 50.866799752739652],
              [-0.656941445677874, 50.866278951698256],
              [-0.662528156331822, 50.865269341889523],
              [-0.671762372898546, 50.864859489312963],
              [-0.681145543004589, 50.863294098815807],
              [-0.681443201883647, 50.861859491927071],
              [-0.686134070211898, 50.854717140992364],
              [-0.689382785975667, 50.851996430760337],
              [-0.690706638347299, 50.849567007407188],
              [-0.692051916940416, 50.849668423255842],
              [-0.690959089637332, 50.849226308686532],
              [-0.690596951860061, 50.847395779181355],
              [-0.691062189469781, 50.846354218745141],
              [-0.69368523703293, 50.846526595182269],
              [-0.693962596451155, 50.84590289090962],
              [-0.69419044253193, 50.844813696571251],
              [-0.692325524120725, 50.84404819967996],
              [-0.692750039818745, 50.843088911085403],
              [-0.693020853562612, 50.837207853159285],
              [-0.69254373770453, 50.835426398910506],
              [-0.690235758026912, 50.832898675456867],
              [-0.688065543519956, 50.828706954251125],
              [-0.687722567724195, 50.825594225116305],
              [-0.686239400613118, 50.822791510108729],
              [-0.686628234119965, 50.821127688023338],
              [-0.68672798994497, 50.819545145309213],
              [-0.68903830455875, 50.816939774942483],
              [-0.689917159553793, 50.812824558650739],
              [-0.70094559236835, 50.813360640427653],
              [-0.704017237119202, 50.812183441662825],
              [-0.705642702533866, 50.811713165026781],
              [-0.706262877440807, 50.811819865174982],
              [-0.70607653059777, 50.811628946109408],
              [-0.70734415604597, 50.810905571316347],
              [-0.708017791197866, 50.809794304423761],
              [-0.709908823302314, 50.808180302661391],
              [-0.711121974070351, 50.807986871082029],
              [-0.713394234555706, 50.808815022984888],
              [-0.713320092058224, 50.808310598085853],
              [-0.71857682858356, 50.807338751380669],
              [-0.718395723047492, 50.806384404949604],
              [-0.718974677093807, 50.805298114783305],
              [-0.719438677410686, 50.805249250300001],
              [-0.722337797172123, 50.807343124284976],
              [-0.723955766783345, 50.807864437188222],
              [-0.730055388301011, 50.809191800316377],
              [-0.732080501123634, 50.808887378921142],
              [-0.734760534441803, 50.809452452015364],
              [-0.735865489301284, 50.807953609133719],
              [-0.735477106259196, 50.807041110307075],
              [-0.736080870706534, 50.807019773874742],
              [-0.73619994515208, 50.806551630410567],
              [-0.735499451189398, 50.805740068910481],
              [-0.735360046335251, 50.804174679196812],
              [-0.733930490891473, 50.803088111738873],
              [-0.73399163186937, 50.802243434858099],
              [-0.73615285173025, 50.798669672059475],
              [-0.736737696442764, 50.798240745355102],
              [-0.739279399670702, 50.797650419779501],
              [-0.73939537025427, 50.797137274061534],
              [-0.738322172888421, 50.796996174455771],
              [-0.738893950958419, 50.796102158181888],
              [-0.738509436062319, 50.795679827282228],
              [-0.737429225742194, 50.796169052049727],
              [-0.735639273514504, 50.795299830307286],
              [-0.734725221198541, 50.793733233831659],
              [-0.735446404658549, 50.793297698265548],
              [-0.736461127063992, 50.793920215935081],
              [-0.73794057831096, 50.793672734600882],
              [-0.738943729404056, 50.792986624975597],
              [-0.738530052389979, 50.792014506670917],
              [-0.739962820721969, 50.791917577384652],
              [-0.74059512520885, 50.791460366279836],
              [-0.741943014072957, 50.79156483877825],
              [-0.741992973440943, 50.791289292607637],
              [-0.7426769173508, 50.791338932601249],
              [-0.742694446196828, 50.79158463020805],
              [-0.743927285692124, 50.791270564990491],
              [-0.745216946488043, 50.79153624656113],
              [-0.749302578782786, 50.790651141384743],
              [-0.755111243309657, 50.790696180720374],
              [-0.754996848877752, 50.786349547959055],
              [-0.753856866609886, 50.78520605814191],
              [-0.752865706482769, 50.784976037596074],
              [-0.751664944164077, 50.785319317662051],
              [-0.751041038935575, 50.785148967385979],
              [-0.751713543265864, 50.783334183952135],
              [-0.750122884135695, 50.782606705715779],
              [-0.749600613450492, 50.78197789398871],
              [-0.749975935202997, 50.781414457815671],
              [-0.751157780382818, 50.781458591105562],
              [-0.756061057838894, 50.783774515376322],
              [-0.756815953712436, 50.782375158527742],
              [-0.75756172698521, 50.782228423958188],
              [-0.757691499232114, 50.781238777736512],
              [-0.758358686786161, 50.780737776420033],
              [-0.759794140761079, 50.780524623591283],
              [-0.758858024242152, 50.779235863451397],
              [-0.759011279896936, 50.776875032436159],
              [-0.759627685117669, 50.776896876300441],
              [-0.761287952040348, 50.777779644484802],
              [-0.763651926148835, 50.777689612037889],
              [-0.763186983632643, 50.776172960211028],
              [-0.764114208337684, 50.775971451770552],
              [-0.764537702486469, 50.775455242916038],
              [-0.764816225242077, 50.773657793259439],
              [-0.764586203971969, 50.773183224705079],
              [-0.763110816910128, 50.772930174803562],
              [-0.765022584583408, 50.772338006434879],
              [-0.766834673133094, 50.772730386433537],
              [-0.767504615904985, 50.771846259990973],
              [-0.766231894852764, 50.77089843255694],
              [-0.764591203696849, 50.77121650720165],
              [-0.764170186292082, 50.770940458730529],
              [-0.764447388639089, 50.771292323878221],
              [-0.763343886558591, 50.772051331221412],
              [-0.763118667883834, 50.771771958753085],
              [-0.764243636094906, 50.770413347249018],
              [-0.765466889070819, 50.77016730100496],
              [-0.76431633376989, 50.770183896330956],
              [-0.763467854982516, 50.770791825649901],
              [-0.761954572486127, 50.769935827291818],
              [-0.760752599931659, 50.769644280691907],
              [-0.762956383075708, 50.769052550285757],
              [-0.7640611689944, 50.7700723776423],
              [-0.764650735955444, 50.768363655691523],
              [-0.763232235255837, 50.768916982955723],
              [-0.763293697342865, 50.768575900130081],
              [-0.762350388041845, 50.7682637300802],
              [-0.763310316156381, 50.767891708038484],
              [-0.766222882537291, 50.767682437076132],
              [-0.766237286886247, 50.76815921903458],
              [-0.766442265117278, 50.767914079064042],
              [-0.768538056998359, 50.768783364832736],
              [-0.77064857804452, 50.769039444731071],
              [-0.771681608251457, 50.768635749563529],
              [-0.774545184485083, 50.76829355765063],
              [-0.774928594272602, 50.768110530438271],
              [-0.774515470451689, 50.767911042747095],
              [-0.77507571113222, 50.768015850248766],
              [-0.774727654068091, 50.768365619888669],
              [-0.775299505303615, 50.768623429317856],
              [-0.776406463285969, 50.768319391627799],
              [-0.776260233543915, 50.768108320649496],
              [-0.775754902972548, 50.768398888061476],
              [-0.774796618703569, 50.76746254719891],
              [-0.775511953164213, 50.767329767586062],
              [-0.779992337345735, 50.768883041324379],
              [-0.781653424321618, 50.768482227919527],
              [-0.780986329477708, 50.767468042337427],
              [-0.778481042881115, 50.766906750532982],
              [-0.779607172083079, 50.766953610502497],
              [-0.779497472748866, 50.766700658635621],
              [-0.778133428758694, 50.766587455357921],
              [-0.777910649705833, 50.766810845995074],
              [-0.777708027364992, 50.766532637717148],
              [-0.778099163095543, 50.766160827950515],
              [-0.780715220748285, 50.765350054305095],
              [-0.785626396624948, 50.760175450740796],
              [-0.785803604227004, 50.760121538871275],
              [-0.785758448300946, 50.760332404538282],
              [-0.786287862122717, 50.760256078126531],
              [-0.786873811744534, 50.759912345646569],
              [-0.783997077612187, 50.760238514145534],
              [-0.780324421021264, 50.761728081367579],
              [-0.782631288434803, 50.760527332184871],
              [-0.785164499635001, 50.759944017105795],
              [-0.78281731081792, 50.760188438370925],
              [-0.778817249831374, 50.762053147096502],
              [-0.776612890962474, 50.76165414004943],
              [-0.776286567347727, 50.761933996944599],
              [-0.775499870834707, 50.761653252658384],
              [-0.77281867274438, 50.761652054050799],
              [-0.772701965605857, 50.761072575154017],
              [-0.768771353196953, 50.760164224208857],
              [-0.765941906976026, 50.757931931370315],
              [-0.763422142578163, 50.75455624011871],
              [-0.762825813816142, 50.754644343169808],
              [-0.76266850581369, 50.753996974643456],
              [-0.763130932160152, 50.754040549423912],
              [-0.763293861162885, 50.754474842013295],
              [-0.763534669968347, 50.75362125826971],
              [-0.761823913403127, 50.750867427771219],
              [-0.759647914952535, 50.753068285752839],
              [-0.761505556124136, 50.75390729158358],
              [-0.760735905972616, 50.753955772649334],
              [-0.759484344324797, 50.753357021267647],
              [-0.75826916378905, 50.753420531261305],
              [-0.755168129655056, 50.755529617361283],
              [-0.754332197212172, 50.7572194772915],
              [-0.753864003953297, 50.757445597168207],
              [-0.753527434237804, 50.75725314710229],
              [-0.753848280741011, 50.756222376261881],
              [-0.756567555899175, 50.75359755198992],
              [-0.75966390857066, 50.751658347340076],
              [-0.76110885468663, 50.750299568097674],
              [-0.76296682527857, 50.746335371076988],
              [-0.763016579813196, 50.745522927418037],
              [-0.762661697517223, 50.745435527599938],
              [-0.763160317090543, 50.743563967345544],
              [-0.764302190673714, 50.742129089696427],
              [-0.775537243037141, 50.730492769833717],
              [-0.779835778035477, 50.727892965850288],
              [-0.78320290753306, 50.72595327201654],
              [-0.785613567663545, 50.722912665862069],
              [-0.786522647523423, 50.722546219367253],
              [-0.789344922774216, 50.722538682891866],
              [-0.793602935266394, 50.723445246315805],
              [-0.798206276503655, 50.725590858530829],
              [-0.801569652014288, 50.727705564445152],
              [-0.801438073870994, 50.727975802212406],
              [-0.802739206468797, 50.728792246809888],
              [-0.804557884452962, 50.730765027750138],
              [-0.816667329227958, 50.738970629548362],
              [-0.817725616059965, 50.739547922809983],
              [-0.818876899911048, 50.740147731416819],
              [-0.819900970532052, 50.740678793730623],
              [-0.821555696394815, 50.741550761098765],
              [-0.829603163142843, 50.746434062001931],
              [-0.832092416427515, 50.748361034044507],
              [-0.842877206870959, 50.753570248793757],
              [-0.862990152960399, 50.76190038158007],
              [-0.875831449452451, 50.765768607400446],
              [-0.902057220189636, 50.77280535398247],
              [-0.906681886128536, 50.774575427089189],
              [-0.916841329497743, 50.780392380185582],
              [-0.915633599843499, 50.78422292599064],
              [-0.913157523260926, 50.785660331945486],
              [-0.912502196160237, 50.788898860076053],
              [-0.911374822868827, 50.788938706653283],
              [-0.910098247725655, 50.788500525536541],
              [-0.908957798443782, 50.787761442242008],
              [-0.908429916549613, 50.786548759156346],
              [-0.90921882804653, 50.78574947498582],
              [-0.910429758761796, 50.785834531754531],
              [-0.910342529408926, 50.784948816325524],
              [-0.912433127492786, 50.784965625272427],
              [-0.913932296168421, 50.784058718278324],
              [-0.9151509407822, 50.781908164344294],
              [-0.914834497055515, 50.781116544328924],
              [-0.915639921742478, 50.780453162981559],
              [-0.912744817708289, 50.780883956872678],
              [-0.909160515161157, 50.781975510203736],
              [-0.90682152833862, 50.78203185537209],
              [-0.906847747986792, 50.781637312677283],
              [-0.905291992966479, 50.78204898861172],
              [-0.904088297543919, 50.783540401740503],
              [-0.904183545128617, 50.785838082741819],
              [-0.903729201374143, 50.78712879152625],
              [-0.901286288558605, 50.78991339343446],
              [-0.898346266895646, 50.790775966615364],
              [-0.897466874363413, 50.790765861344987],
              [-0.897256767478112, 50.790409555678664],
              [-0.898065323357172, 50.790596153558596],
              [-0.899248131206637, 50.789949938195264],
              [-0.899947020708256, 50.790094121038123],
              [-0.899192213986192, 50.789854085955298],
              [-0.899572420908858, 50.78967331825524],
              [-0.899218088797105, 50.789598032596246],
              [-0.899289323019348, 50.789222801658262],
              [-0.899959385899901, 50.789325345205079],
              [-0.89930466000608, 50.789052980633421],
              [-0.898953613785344, 50.789683667399501],
              [-0.898032158697322, 50.789230718922511],
              [-0.894491891696139, 50.790217921276188],
              [-0.895233066236543, 50.790493829323765],
              [-0.893660286698222, 50.791489727717682],
              [-0.892943967856066, 50.792624131475932],
              [-0.891712667990371, 50.798074723536189],
              [-0.890772626086547, 50.799841885599008],
              [-0.88796949117538, 50.801180323863498],
              [-0.886330870460747, 50.803252843100957],
              [-0.884894768056481, 50.803786794140606],
              [-0.883968341032023, 50.803596280724967],
              [-0.885236600345913, 50.803257681781602],
              [-0.886112125476932, 50.802544813846268],
              [-0.884218442248921, 50.803165215612573],
              [-0.881731189027729, 50.805820384611913],
              [-0.877814006482308, 50.807722542716391],
              [-0.873856611313379, 50.807682618513383],
              [-0.872656194469645, 50.808319414000088],
              [-0.870569333871605, 50.808399054687882],
              [-0.869284397050308, 50.808007110804326],
              [-0.869179164873724, 50.807310041679976],
              [-0.8687248029944, 50.808141076625809],
              [-0.866180593542864, 50.807237777753194],
              [-0.864744897667885, 50.80574898448905],
              [-0.86171189792274, 50.804586331568402],
              [-0.861704352715077, 50.804137512484147],
              [-0.860706053985031, 50.803813017992475],
              [-0.860347572118393, 50.802856270058328],
              [-0.857267862135535, 50.802806364988719],
              [-0.856380936021654, 50.803037785893913],
              [-0.856282265711344, 50.802777823222456],
              [-0.855910159773426, 50.803022379309198],
              [-0.854262772270388, 50.802722938292206],
              [-0.850418172245726, 50.801516045709278],
              [-0.849446620454299, 50.800858980187598],
              [-0.845155928417955, 50.80014482120837],
              [-0.844584679955965, 50.799831608197685],
              [-0.844745279012769, 50.799303515840712],
              [-0.843602877894414, 50.799882134251163],
              [-0.839667713728294, 50.800920417856396],
              [-0.837316434413363, 50.802688400146437],
              [-0.836480824506528, 50.804049691199808],
              [-0.835001516470708, 50.804756162123205],
              [-0.833321598814856, 50.805082007092494],
              [-0.831478672964875, 50.80460222912302],
              [-0.830038172500182, 50.803353066863359],
              [-0.830212590906013, 50.803870109738931],
              [-0.829845047009743, 50.804096643006147],
              [-0.828489851007798, 50.803978726551648],
              [-0.828167825486255, 50.804541147354662],
              [-0.825424742617207, 50.804476695434154],
              [-0.825199342676968, 50.804862920289111],
              [-0.824947433293031, 50.805909857124405],
              [-0.825578732395746, 50.807505260941461],
              [-0.824817518798332, 50.810070469053784],
              [-0.824071012823095, 50.810576443568927],
              [-0.821986961972421, 50.810367470754066],
              [-0.819866887555065, 50.811307390520064],
              [-0.818608581419617, 50.811505091815313],
              [-0.816483924587665, 50.813408044735752],
              [-0.81451318698891, 50.814382655868656],
              [-0.814300370807359, 50.81667728255794],
              [-0.815062562647774, 50.818701234041484],
              [-0.815581864505677, 50.819217302921096],
              [-0.816849347771629, 50.819170807585891],
              [-0.81687984288944, 50.819424716597794],
              [-0.815790180618233, 50.819512585717462],
              [-0.815167951635281, 50.820707721336007],
              [-0.811912430246693, 50.8227861550358],
              [-0.810858195523947, 50.824382448207786],
              [-0.811369815246045, 50.826040554825298],
              [-0.810586960568849, 50.82650830267265],
              [-0.810181882542626, 50.826524857038869],
              [-0.810677950419218, 50.826224155234272],
              [-0.80991810520581, 50.82640076329325],
              [-0.809742786627054, 50.827209235150846],
              [-0.809640755617808, 50.828150649752629],
              [-0.810314382420677, 50.82840662197691],
              [-0.810870010476386, 50.829730642716626],
              [-0.807807564393979, 50.830551924085718],
              [-0.808743174046759, 50.83050212508175],
              [-0.810567218038443, 50.831979376363002],
              [-0.811496995799079, 50.830482540157142],
              [-0.810734442934675, 50.832048527224671],
              [-0.810765041036072, 50.833303346598889],
              [-0.81146471294834, 50.832928276593506],
              [-0.811476965200214, 50.831439179436153],
              [-0.812945454024831, 50.830535052370301],
              [-0.811539483303289, 50.831549529331348],
              [-0.811523769803588, 50.833566473527178],
              [-0.812198955123279, 50.833372806034809],
              [-0.812031297463271, 50.833935852621842],
              [-0.812732297368114, 50.833564385726525],
              [-0.812743711919336, 50.833002446622082],
              [-0.81297306398851, 50.833477805294244],
              [-0.813847372097568, 50.833887777250979],
              [-0.813140279290747, 50.833379684936631],
              [-0.812795897413384, 50.832568621186525],
              [-0.813152648517813, 50.832387895628294],
              [-0.812640635100633, 50.832419558335246],
              [-0.812453235520128, 50.832137972841409],
              [-0.816916561861195, 50.829545691738105],
              [-0.817604508167506, 50.828562547001425],
              [-0.817919082386779, 50.827744683109131],
              [-0.817364491832042, 50.827215678116588],
              [-0.816840763084756, 50.825072759190661],
              [-0.817314266646224, 50.824651295746051],
              [-0.817632412965435, 50.824815492367804],
              [-0.817369124954573, 50.825008869555475],
              [-0.817794830696204, 50.824849512254161],
              [-0.818095529189179, 50.824467662248495],
              [-0.818201721957728, 50.824929173817672],
              [-0.820131526372939, 50.824804814561617],
              [-0.818798935784383, 50.824724786545772],
              [-0.818432616154417, 50.82455650821457],
              [-0.819061513314341, 50.824559276924127],
              [-0.818969083918823, 50.824339814249299],
              [-0.817856792228061, 50.824363624413088],
              [-0.81766838581395, 50.824627905365979],
              [-0.817468904230934, 50.824374982414355],
              [-0.819297403219858, 50.823031073327833],
              [-0.820398067950391, 50.820931571737169],
              [-0.820810159988832, 50.820803541010072],
              [-0.820601891671065, 50.82106852533839],
              [-0.820902561725074, 50.821080556606745],
              [-0.821555316886765, 50.820246314414092],
              [-0.820901936533034, 50.82048522051668],
              [-0.820135613869945, 50.818445079751726],
              [-0.820695496443013, 50.818253794754114],
              [-0.821149958898147, 50.818528173687881],
              [-0.821463394596897, 50.818257955467857],
              [-0.819819277132256, 50.818154108059403],
              [-0.819361845076443, 50.817491200453638],
              [-0.819763392308771, 50.816879248572356],
              [-0.822884243467935, 50.814141856530561],
              [-0.826134357513702, 50.813934486461321],
              [-0.828182939910807, 50.813185284136679],
              [-0.828203878699767, 50.812915707296845],
              [-0.829301973583235, 50.812880865440007],
              [-0.829124765801134, 50.812417751487068],
              [-0.829865828238947, 50.81235593973372],
              [-0.829646337852144, 50.812562373877007],
              [-0.83101628100304, 50.811871961644002],
              [-0.830659845060538, 50.811537451451066],
              [-0.830359536171876, 50.812195420088521],
              [-0.829176500661936, 50.812335535875768],
              [-0.829998659900101, 50.811186395519492],
              [-0.830945331372975, 50.811188689423361],
              [-0.830558343429537, 50.810941103950093],
              [-0.830060141057998, 50.811054816253225],
              [-0.830379761895945, 50.810817010554182],
              [-0.833350215702868, 50.810372821154694],
              [-0.836526780643108, 50.809228265795284],
              [-0.84053585653898, 50.806955196509278],
              [-0.842610396461965, 50.804788717702657],
              [-0.844592562072589, 50.804864118190025],
              [-0.849445602628807, 50.805466035296128],
              [-0.850624185546059, 50.806134132413113],
              [-0.851859858493362, 50.806443063270429],
              [-0.851973281645831, 50.806158205088785],
              [-0.853421798189884, 50.806585221652838],
              [-0.853080226099809, 50.806740139762788],
              [-0.853707646166759, 50.806735513634301],
              [-0.854170675668721, 50.807185209794035],
              [-0.856210249828603, 50.807064931043527],
              [-0.856987344061209, 50.807557262774317],
              [-0.857083653716007, 50.808147240642974],
              [-0.856167134615451, 50.809005174788744],
              [-0.85607476597254, 50.80982352170377],
              [-0.855608600586275, 50.809907081144175],
              [-0.855869967841081, 50.810305331335449],
              [-0.852919368813924, 50.810759281603879],
              [-0.852147224242359, 50.810643773941067],
              [-0.852227123297072, 50.810163440136606],
              [-0.8516299087591, 50.810381487278441],
              [-0.8521089751643, 50.810061557540891],
              [-0.851533140250601, 50.810160209026783],
              [-0.851279987960255, 50.809891527526055],
              [-0.851099887594169, 50.810232383648462],
              [-0.848796173289217, 50.810756446668236],
              [-0.849767926630174, 50.811066392218358],
              [-0.850190913791748, 50.810718938847053],
              [-0.851412996692553, 50.810834392725006],
              [-0.852691526913783, 50.811948599631926],
              [-0.852714086426213, 50.812534258528224],
              [-0.852859463008563, 50.811814458050719],
              [-0.853533340516887, 50.811712266847003],
              [-0.854984956752098, 50.811671663257478],
              [-0.85651563179508, 50.812404305374493],
              [-0.855704352023107, 50.811681417980196],
              [-0.85647772335132, 50.811631444879382],
              [-0.857941163332858, 50.810756380419406],
              [-0.859855013297659, 50.811258913305736],
              [-0.86116662371022, 50.812072088140638],
              [-0.862082295694674, 50.8120657331282],
              [-0.861976910771607, 50.812255354536937],
              [-0.864218507856597, 50.812299676423478],
              [-0.864285082968885, 50.811954098436814],
              [-0.864800722495115, 50.812289154652682],
              [-0.864773340659572, 50.811720538125705],
              [-0.868144440584228, 50.81301138925415],
              [-0.868830830169574, 50.813859778252784],
              [-0.868479024468846, 50.814438206555096],
              [-0.869955797860746, 50.816475913485675],
              [-0.869854675864832, 50.817842749903136],
              [-0.86728850937973, 50.819702775053052],
              [-0.866070953894907, 50.820151385467284],
              [-0.865257912867984, 50.8215400749149],
              [-0.863149723475602, 50.823584318731399],
              [-0.861455886664714, 50.823937412465718],
              [-0.857902670155143, 50.82362751081763],
              [-0.857648439511337, 50.824038694444212],
              [-0.857953348077984, 50.824110923818189],
              [-0.855645447659637, 50.826872507303008],
              [-0.853909389284124, 50.827493065225788],
              [-0.851171934227233, 50.827837555068463],
              [-0.852668832186252, 50.829156246422812],
              [-0.853911716362369, 50.829369898872628],
              [-0.858137341086847, 50.828558796631697],
              [-0.859377347664032, 50.827958508762237],
              [-0.861262967834057, 50.828074945163934],
              [-0.860596731185693, 50.828204234641582],
              [-0.861399543484, 50.829698589482483],
              [-0.860685672483986, 50.835284278547597],
              [-0.860032937600403, 50.835787799346974],
              [-0.860328202601402, 50.835151291353625],
              [-0.860043843571063, 50.834990240063483],
              [-0.859779978241007, 50.835736766901093],
              [-0.85927720631625, 50.835913509465342],
              [-0.859153373416752, 50.83650762569458],
              [-0.859751456696029, 50.837548547214446],
              [-0.858905174383679, 50.838183264710963],
              [-0.858716524427396, 50.837998865333695],
              [-0.858080619455535, 50.839498047799445],
              [-0.859475781246487, 50.839944245488532],
              [-0.860705101072974, 50.83950301260964],
              [-0.862224804597956, 50.838683301416026],
              [-0.861669324540655, 50.838748928457711],
              [-0.862130441313035, 50.83847554606249],
              [-0.861739914296718, 50.838357528903437],
              [-0.862404916772724, 50.838226422054603],
              [-0.861916490497125, 50.837929392879225],
              [-0.866758705906772, 50.838542159571006],
              [-0.868132081951419, 50.840419702012603],
              [-0.867883806085964, 50.840995533469105],
              [-0.869546925602829, 50.840115067806742],
              [-0.870911424373074, 50.840483492897562],
              [-0.869536274878501, 50.840026834842448],
              [-0.869830747687594, 50.838242809663676],
              [-0.870702639443748, 50.8369526831126],
              [-0.870046978972058, 50.835463417369915],
              [-0.870414740303329, 50.835288919035861],
              [-0.869557223341699, 50.834867844795959],
              [-0.869834916337402, 50.834482942557202],
              [-0.869396969235889, 50.834741291928474],
              [-0.868960368955782, 50.834297311466472],
              [-0.870011093466409, 50.833302988467153],
              [-0.871081388019391, 50.833320540584154],
              [-0.871579298271896, 50.832753410760404],
              [-0.871983067570149, 50.832674579814039],
              [-0.871917450575427, 50.833217113508447],
              [-0.874084954419892, 50.832546494266239],
              [-0.872642361837028, 50.83124299392437],
              [-0.872563320697889, 50.831458958025856],
              [-0.871955995830244, 50.831264239621312],
              [-0.871959866655565, 50.831516076797399],
              [-0.870489881810698, 50.831645744883922],
              [-0.867403581403564, 50.830381115749994],
              [-0.866753594899661, 50.828606810769749],
              [-0.866034745914858, 50.828622310379529],
              [-0.866747261186854, 50.828340560721713],
              [-0.865779490616271, 50.826967841904619],
              [-0.869123368883824, 50.824729595893146],
              [-0.869833340909691, 50.824608775009068],
              [-0.870173403225885, 50.824225374787709],
              [-0.869908005824178, 50.824044746424612],
              [-0.871403931246434, 50.824007064317883],
              [-0.870102841085369, 50.823913538644938],
              [-0.869711615987913, 50.823416941322741],
              [-0.874485420104824, 50.818017002086677],
              [-0.874820723157768, 50.81729630973588],
              [-0.874478103079972, 50.817140126914332],
              [-0.874893548882505, 50.817160320309064],
              [-0.873964768388639, 50.813929216139329],
              [-0.874518468078678, 50.812979514617204],
              [-0.876894790284819, 50.811491607034696],
              [-0.878081702320366, 50.811597449814236],
              [-0.880997216797498, 50.812544516052171],
              [-0.88263871664785, 50.812457726816014],
              [-0.882303915404554, 50.812807041008725],
              [-0.885169466075029, 50.813713055784682],
              [-0.889297842536694, 50.815722759693749],
              [-0.890537734361923, 50.816534019689001],
              [-0.892328991184813, 50.818523169531417],
              [-0.89177324736935, 50.823170794896356],
              [-0.892597862586798, 50.824682226803176],
              [-0.894241109731329, 50.825680726069287],
              [-0.894719309042879, 50.827196053473337],
              [-0.895445892181402, 50.827633689546992],
              [-0.895268387316911, 50.828176076208187],
              [-0.896645883561213, 50.828408532681543],
              [-0.897050763889702, 50.828881785890474],
              [-0.896755275444252, 50.829189245830698],
              [-0.89611213372312, 50.828944854143366],
              [-0.895611469718013, 50.829402349282653],
              [-0.894781843052993, 50.829418776271119],
              [-0.895414288496937, 50.829514692229623],
              [-0.895255670552209, 50.830219128532818],
              [-0.895981491841248, 50.832256578804063],
              [-0.892885472167253, 50.832693079452866],
              [-0.892563705363895, 50.834769169563543],
              [-0.893750475952681, 50.838091583610847],
              [-0.895049621444985, 50.83858680473066],
              [-0.897954388133946, 50.838929916431375],
              [-0.8986857717077, 50.837965595277367],
              [-0.898490727143491, 50.837563574055608],
              [-0.904630210829389, 50.838349773483841],
              [-0.907577629249615, 50.838206534339726],
              [-0.908729972840129, 50.837755982846183],
              [-0.909621858188816, 50.839462161938457],
              [-0.913221808898723, 50.840088363957854],
              [-0.913625169636312, 50.839231507059154],
              [-0.915961695820954, 50.838300893063511],
              [-0.916599351018205, 50.836583790942569],
              [-0.917318336613051, 50.83662823256121],
              [-0.916593725057669, 50.836458738607639],
              [-0.916727760303054, 50.836005845613364],
              [-0.91639595093581, 50.836168233244685],
              [-0.913712159090604, 50.834015584528267],
              [-0.912944804760676, 50.832142429492301],
              [-0.914173692522286, 50.831627789003463],
              [-0.912415605900573, 50.827722021603677],
              [-0.912981366793933, 50.828305531450013],
              [-0.913803080737553, 50.828445376669798],
              [-0.916715141545562, 50.828145104215508],
              [-0.918327416309389, 50.82854495132456],
              [-0.919300047319118, 50.829160078199841],
              [-0.91945810753008, 50.829940320143415],
              [-0.91807003151206, 50.831264692987958],
              [-0.919640310614763, 50.830597583601289],
              [-0.919924193474063, 50.831402370676244],
              [-0.91992147111169, 50.830351084796028],
              [-0.921787184173155, 50.831465460031261],
              [-0.922787026315209, 50.83170490883932],
              [-0.921772435145007, 50.832537267613631],
              [-0.920519846012984, 50.832789178415275],
              [-0.920521609163184, 50.833636318026841],
              [-0.920997702901421, 50.833989641736338],
              [-0.92356233331165, 50.835101453926654],
              [-0.925965373772941, 50.835754921746563],
              [-0.92624992415432, 50.83599671446941],
              [-0.928583459831675, 50.836481288900181],
              [-0.929924077063847, 50.837288551820073],
              [-0.932627931029234, 50.837979676847596],
              [-0.933090159666167, 50.837327428471191],
              [-0.93081234978357, 50.837072771543525],
              [-0.925800083317673, 50.835614466162852],
              [-0.923647307601292, 50.834988028900611],
              [-0.921410855185685, 50.834116662166352],
              [-0.920750771955967, 50.833243652738886],
              [-0.923163965969681, 50.83204741651592],
              [-0.923845397602201, 50.832116651523506],
              [-0.923607865173526, 50.832565900413975],
              [-0.924513997641264, 50.832126411718377],
              [-0.925156176126193, 50.832173694578259],
              [-0.925239518350688, 50.832563850971439],
              [-0.925607359457093, 50.832128388394594],
              [-0.928079309278787, 50.83164482263988],
              [-0.931389601159641, 50.830388307384787],
              [-0.935123905423451, 50.83027136033165],
              [-0.938089769716917, 50.831604139622016],
              [-0.931394748763398, 50.840736376879939],
              [-0.932978288102612, 50.842919027276835],
              [-0.93182791841347, 50.841670197220573],
              [-0.931259237065641, 50.841632617381329],
              [-0.931933989002782, 50.842685555307121],
              [-0.931472755310393, 50.842855794023336],
              [-0.930989829982867, 50.841675114220081],
              [-0.930067613371207, 50.841756592110507],
              [-0.930351434305219, 50.842636890478808],
              [-0.93094772916148, 50.842771849817233],
              [-0.930632565853784, 50.842827415941571],
              [-0.930784569996702, 50.843323412234191],
              [-0.9315761958063, 50.843301884292977],
              [-0.931030655168442, 50.843431781218044],
              [-0.93092089492318, 50.843821962506645],
              [-0.931869137865711, 50.843970931473137],
              [-0.93249332198388, 50.846059372351561],
              [-0.932403594941064, 50.846754593617938],
              [-0.933581126695071, 50.847565715952769],
              [-0.93444204047241, 50.84968148711458],
              [-0.93374203399063, 50.851848650326822],
              [-0.932844704197128, 50.852076061009754],
              [-0.932788845428456, 50.853216734106269],
              [-0.932777063870117, 50.853919861681284],
              [-0.933461579886028, 50.854802913766903],
              [-0.932047903588228, 50.855677575796754],
              [-0.931244937703606, 50.857243063213673],
              [-0.929807332109422, 50.858537441520205],
              [-0.928263728121745, 50.861539455384438],
              [-0.9281394284977, 50.862684892134965],
              [-0.926465727963918, 50.864101161050399],
              [-0.927056686358198, 50.865037345310839],
              [-0.928672105560949, 50.865793185123302],
              [-0.928708234538082, 50.86632498893168],
              [-0.929238730408487, 50.866119425325735],
              [-0.929382823270526, 50.866777218467163],
              [-0.931946940240677, 50.868433790511197],
              [-0.933550669414079, 50.868900787368815],
              [-0.935085890541639, 50.868945376989515],
              [-0.936953086222469, 50.870077495729191],
              [-0.9387424339518, 50.872576673379605],
              [-0.938623857717626, 50.873730263990971],
              [-0.938707903810955, 50.873847034455295],
              [-0.93948604988663, 50.874254282739834],
              [-0.93951800726251, 50.875476686774206],
              [-0.941242836986453, 50.87763890835312],
              [-0.945346376304268, 50.880347790432914],
              [-0.947119362812908, 50.884114659283952],
              [-0.951464506212918, 50.886360575459818],
              [-0.957597322781623, 50.890638466852252],
              [-0.955621470416114, 50.891127999388139],
              [-0.951997530762393, 50.897010931404814],
              [-0.95020434872765, 50.89846872749883],
              [-0.950386327060439, 50.901317451880573],
              [-0.949653626163526, 50.904112987387116],
              [-0.949569937573721, 50.90498812283915],
              [-0.948656205042178, 50.905017669189661],
              [-0.947431126725634, 50.908448135722601],
              [-0.946144666949113, 50.910494059942074],
              [-0.942973455235287, 50.913100255509939],
              [-0.941816962979507, 50.915736328313784],
              [-0.939416953235607, 50.918810736420987],
              [-0.938038667297008, 50.919487050537903],
              [-0.936256108070308, 50.919797261944574],
              [-0.925996945696415, 50.923083714787573],
              [-0.924030377496637, 50.924206784385099],
              [-0.927655992096254, 50.927846192417967],
              [-0.928151554083277, 50.931111482736561],
              [-0.929017146541054, 50.932581630284105],
              [-0.931030163154606, 50.934471444020872],
              [-0.931202092133229, 50.935290450025057],
              [-0.934426158390954, 50.938999701365709],
              [-0.935335818682629, 50.941110477510612],
              [-0.937323877173471, 50.94212406722685],
              [-0.94115646208888, 50.942460125863668],
              [-0.942127148027073, 50.942901467011986],
              [-0.937810207559613, 50.944455786317661],
              [-0.931864234523964, 50.945587694867164],
              [-0.93020954936002, 50.948157936305925],
              [-0.928961444079706, 50.95235595208284],
              [-0.926540048176279, 50.952529765290372],
              [-0.928099179750188, 50.954811124864086],
              [-0.929560904617471, 50.956070906662809],
              [-0.929290599842379, 50.958354354877137],
              [-0.92487243406262, 50.962363154998926],
              [-0.920757433537961, 50.964563475369751],
              [-0.916994991051081, 50.968611305796614],
              [-0.916438194650746, 50.970017084254799],
              [-0.915542584496863, 50.970619372765732],
              [-0.915245508945499, 50.971450230083192],
              [-0.917709736222845, 50.978670765866617],
              [-0.916559134573028, 50.980307546186737],
              [-0.915171230252321, 50.981120188483438],
              [-0.913678596142921, 50.984709653559371],
              [-0.912408132811233, 50.985731982994892],
              [-0.911756721112955, 50.987147646608605],
              [-0.910667809876578, 50.987956724884484],
              [-0.91052159614097, 50.988725127398531],
              [-0.909646791288259, 50.988967860608263],
              [-0.907593033249337, 50.990633889108715],
              [-0.907942044269589, 50.994098397880308],
              [-0.906023891156072, 50.99574228482156],
              [-0.905639370893591, 50.996823189602736],
              [-0.903964818591509, 50.998583529489032],
              [-0.901934656916769, 50.999162476389493],
              [-0.901810093964376, 51.000213437550485],
              [-0.901086550010959, 51.000497095800029],
              [-0.90109249333313, 51.000788511216527],
              [-0.899726905421135, 51.001282842656522],
              [-0.897985911773963, 51.001078492179914],
              [-0.896902838337549, 51.001436968916487],
              [-0.896669624840213, 51.000814279170598],
              [-0.895852441679646, 51.000898284903798],
              [-0.894696236075756, 51.001934990022853],
              [-0.894701046482722, 51.002512359131465],
              [-0.893400754991339, 51.002713177099594],
              [-0.893406976543016, 51.003110708023378],
              [-0.893429097204742, 51.005356364065662],
              [-0.894379641803246, 51.006501123302229],
              [-0.894303448650709, 51.006948232549654],
              [-0.896867232980428, 51.008888778736356],
              [-0.89782396316334, 51.010320430002167],
              [-0.897105705574311, 51.016324281799541],
              [-0.897980787139865, 51.016816336208386],
              [-0.898525048916698, 51.017673061992816],
              [-0.897078257367804, 51.021929080964419],
              [-0.895297067333746, 51.023384338764217],
              [-0.894522906211637, 51.023501115985781],
              [-0.893102755186407, 51.023800615368565],
              [-0.891977776685885, 51.024756654500976],
              [-0.887895666397182, 51.026237624261718],
              [-0.885903374752452, 51.026605329907341],
              [-0.883838036321839, 51.026510087034069],
              [-0.882674424905272, 51.026867663474],
              [-0.878558015002081, 51.029572765430395],
              [-0.867047322437224, 51.038510058562714],
              [-0.860697234329472, 51.041888883924166],
              [-0.852828754889009, 51.044848708704194],
              [-0.851781904912545, 51.046167528070463],
              [-0.851075144317511, 51.048153330552324],
              [-0.849713816479611, 51.055655003564624],
              [-0.850130477639947, 51.059341542031142],
              [-0.846055290254183, 51.064213103174829],
              [-0.844029076634305, 51.066035652957538],
              [-0.842865998423848, 51.066891924942297],
              [-0.838449693296742, 51.068511746691769],
              [-0.837309009141131, 51.069208119273178],
              [-0.831437491075144, 51.068518304766172],
              [-0.82916125414678, 51.067774330604585],
              [-0.829700462281683, 51.06716284222712],
              [-0.829905061315638, 51.06357597236174],
              [-0.827350200692155, 51.062234765904194],
              [-0.826386974682001, 51.06133034282901],
              [-0.822983321671619, 51.061901321694862],
              [-0.819930239959354, 51.060691620997282],
              [-0.818680515179369, 51.060734752937769],
              [-0.817312155581165, 51.061233492546876],
              [-0.815585613284743, 51.062402129247296],
              [-0.815541584664546, 51.06546993761976],
              [-0.814522956218485, 51.066685295707792],
              [-0.813273721910881, 51.06558632305466],
              [-0.81156792661299, 51.062980042473271],
              [-0.808268667084925, 51.063419464425472],
              [-0.805851628842977, 51.064123196568026],
              [-0.802775474404303, 51.063985622455704],
              [-0.800229546159672, 51.063321950321225],
              [-0.798034058426947, 51.063511622219423],
              [-0.796953819609993, 51.063894384540411],
              [-0.795488785544976, 51.064916140394125],
              [-0.793037521326641, 51.067638982565775],
              [-0.788108959887893, 51.072025819625608],
              [-0.785632506610641, 51.073170952658451],
              [-0.784418582917487, 51.075102525860814],
              [-0.782722329501547, 51.075935572150698],
              [-0.782297178465727, 51.077286321202962],
              [-0.780806066211457, 51.079380431977292],
              [-0.777306074164421, 51.079797106875937],
              [-0.775837156972997, 51.08120075946465],
              [-0.774359331308802, 51.081533288566632],
              [-0.773361098219402, 51.082192764523249],
              [-0.77264758032155, 51.08196495941872],
              [-0.77222910154472, 51.082564864612934],
              [-0.771057516080037, 51.08254446282929],
              [-0.770593549962322, 51.083189746050863],
              [-0.769311039054978, 51.083636671879027],
              [-0.768805897571647, 51.083518947026995],
              [-0.768506047697914, 51.084004084276479],
              [-0.766620419204047, 51.084633470158288],
              [-0.765762505499634, 51.084681070191806],
              [-0.765254604751939, 51.084398737338653],
              [-0.764106672174265, 51.08515007612386],
              [-0.761574771918887, 51.085542338791839],
              [-0.760951930262416, 51.085287545881954],
              [-0.7591856929792, 51.085504420245812],
              [-0.757567150988176, 51.085057388924078],
              [-0.755837981015898, 51.085116337731634],
              [-0.755119406157309, 51.085291238014413],
              [-0.755045996043343, 51.085845296702402],
              [-0.753500292272691, 51.086461004689312],
              [-0.749187856234904, 51.085896080865844],
              [-0.749088613588595, 51.085552402858831],
              [-0.746880620514057, 51.085153769968528],
              [-0.745759414776047, 51.084482593571451],
              [-0.745011734764408, 51.085309979298266],
              [-0.743949699059493, 51.085512597972169],
              [-0.740941073598338, 51.085155607400765],
              [-0.739059802276287, 51.085241446275532],
              [-0.738426185257517, 51.084967532245834],
              [-0.737814575343822, 51.085144379717853],
              [-0.736361824756032, 51.084489316761498],
              [-0.735599192155748, 51.084706785426306],
              [-0.735127198546949, 51.083847386523871],
              [-0.73239518997431, 51.083718880860246],
              [-0.731849038844356, 51.083328975714245],
              [-0.730952420325441, 51.082442471597084],
              [-0.730627583567545, 51.080852655465293],
              [-0.729979140440649, 51.080550657079577],
              [-0.72989530834231, 51.080066845113308],
              [-0.727583870415978, 51.079030946587423],
              [-0.723486987892751, 51.077828042992302],
              [-0.722851164348504, 51.077796819151857],
              [-0.722334284927675, 51.079355881283313],
              [-0.720043810160096, 51.078705843732472],
              [-0.718744155332771, 51.077899361355023],
              [-0.717076697302607, 51.077541156071092],
              [-0.713009350160547, 51.077552207472223],
              [-0.712855791001243, 51.07794169473285],
              [-0.711849525125305, 51.077884749892476],
              [-0.710796420758621, 51.078280506583489],
              [-0.710593749330335, 51.077919467110739],
              [-0.70802531018106, 51.078698647422456],
              [-0.706471366978134, 51.078674271041258],
              [-0.704516355607836, 51.076908959932517],
              [-0.701920743647974, 51.076055548163048],
              [-0.700538105319524, 51.074505150880725],
              [-0.699997121630067, 51.074657406455664],
              [-0.69814883426168, 51.073783441077069],
              [-0.698647418893004, 51.073056094399838],
              [-0.696927410206345, 51.072750970760247],
              [-0.69724785729079, 51.071607084802501],
              [-0.696772797890734, 51.071496576482254],
              [-0.69595294024259, 51.07232914282536],
              [-0.694638130041487, 51.07243766826349],
              [-0.694371408823363, 51.07298863479496],
              [-0.690587289824462, 51.073851869105489],
              [-0.687445479989896, 51.075518939956318],
              [-0.682493096280694, 51.075334640279912],
              [-0.678828473902953, 51.076348119153415],
              [-0.675592149090563, 51.076454484447567],
              [-0.67256357458793, 51.077232170378039],
              [-0.669081369225639, 51.077397612727673],
              [-0.666782907847756, 51.079637566085715],
              [-0.666387849867894, 51.079624067769217],
              [-0.665634220880901, 51.080413114570987],
              [-0.665890670026794, 51.080679525054599],
              [-0.664113011300462, 51.081729348867775],
              [-0.657400316601194, 51.082314313370397],
              [-0.654066799632767, 51.083441430015121],
              [-0.650982891091793, 51.083638796655066],
              [-0.648030638511656, 51.083432936043955],
              [-0.645958068233076, 51.084394543920908],
              [-0.642663289566608, 51.084475941845596],
              [-0.638235384355083, 51.085268861151491],
              [-0.633756603341923, 51.086620360875948],
              [-0.631440901275822, 51.088257815469163],
              [-0.629392893983669, 51.088997300399846],
              [-0.627819996376083, 51.088879033042964],
              [-0.62468613920035, 51.0880481613897],
              [-0.62114625730306, 51.088198910877097],
              [-0.615215956861348, 51.08471970767993],
              [-0.610721271220608, 51.083006339431492],
              [-0.607558839763941, 51.082389589977751],
              [-0.604044698063503, 51.080131889452687],
              [-0.600666490631715, 51.079673344255845],
              [-0.594565183723054, 51.080134321783099],
              [-0.590544984181041, 51.082060716791347],
              [-0.588039091866592, 51.084321814657329],
              [-0.577616183763454, 51.086658569526989],
              [-0.569781053970146, 51.0857527436849],
              [-0.568594368543595, 51.08548102983238],
              [-0.56814827227682, 51.084900034895099],
              [-0.566533565251688, 51.084388347741516],
              [-0.563081109047754, 51.083701189613947],
              [-0.561736477470322, 51.0837835752587],
              [-0.561699956010739, 51.083534028128959],
              [-0.558498323557336, 51.083003594686481],
              [-0.556453588657135, 51.084616836413957],
              [-0.556159440992565, 51.084342523712643],
              [-0.553137490909286, 51.084047972755265],
              [-0.550015462842561, 51.08325660390053],
              [-0.547665306305676, 51.083488221212654],
              [-0.541366167068555, 51.083214657936786],
              [-0.541282158972585, 51.082254086564973],
              [-0.540517371775715, 51.082103355848055],
              [-0.539509721333252, 51.08227512292855],
              [-0.539206107390381, 51.082033022496852],
              [-0.538634885855459, 51.08307983069416],
              [-0.537573318542552, 51.082741919103476],
              [-0.537342097473878, 51.083554666470192],
              [-0.535547817398573, 51.08318058911297],
              [-0.535148968097037, 51.084100943599694],
              [-0.532642968325382, 51.086095578143194],
              [-0.53451228406773, 51.086944556460672],
              [-0.536153458618889, 51.088780746253718],
              [-0.535879051886094, 51.089783595004462],
              [-0.536605854761026, 51.09073423142106],
              [-0.535619623699737, 51.093069880020622],
              [-0.535003613081858, 51.093583740194738],
              [-0.532447464738397, 51.093175788425462],
              [-0.531480103304889, 51.092247284687986],
              [-0.531147940379096, 51.092274586668623],
              [-0.531287988178451, 51.092812311779156],
              [-0.528845386878978, 51.092023516693125],
              [-0.526412618933645, 51.091871478580494],
              [-0.526067339343047, 51.091368030332454],
              [-0.527312099860762, 51.090932281450286],
              [-0.526969965080637, 51.089610537766987],
              [-0.525685817186221, 51.089938772887706],
              [-0.524161791633224, 51.089906955081069],
              [-0.523195053245444, 51.091611458771631],
              [-0.520944647477352, 51.09138427252838],
              [-0.520951662707494, 51.091030047915929],
              [-0.520437734298727, 51.090891352282355],
              [-0.51853729958534, 51.090751280862051],
              [-0.518410066156624, 51.091023946785043],
              [-0.517234688365093, 51.091028830224211],
              [-0.517034999876348, 51.090753817870159],
              [-0.515255201744765, 51.090592741271116],
              [-0.515309840919934, 51.090268797555055],
              [-0.513333455653129, 51.089470307993331],
              [-0.512914122707081, 51.089856159003212],
              [-0.511645219738047, 51.089795048786144],
              [-0.508465677424879, 51.088714968625709],
              [-0.506966164950746, 51.088765972259182],
              [-0.50683283304265, 51.088294848414193],
              [-0.505401567307845, 51.088266668113135],
              [-0.503046298767642, 51.088553079622287],
              [-0.503362104680781, 51.088943810729127],
              [-0.502329705791624, 51.089386522314292],
              [-0.501664998673833, 51.090248503078335],
              [-0.500208368043281, 51.090296372687938],
              [-0.496285677159098, 51.092236980212689],
              [-0.495519834324509, 51.093394386066187],
              [-0.492151181907083, 51.094488572352354],
              [-0.491292449453211, 51.091167263903401],
              [-0.490084733392735, 51.089709235428685],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000226",
        LAD13CDO: "45UE",
        LAD13NM: "Crawley",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.166962612735823, 51.161595554818547],
            [-0.164193393678727, 51.160523326968708],
            [-0.160920241020676, 51.159827109822487],
            [-0.157038327828231, 51.159587945140963],
            [-0.155491658783696, 51.159640903273456],
            [-0.154541719487963, 51.160043192705025],
            [-0.153170876778066, 51.159847090889535],
            [-0.151122783893006, 51.16002876592416],
            [-0.14406449838882, 51.160289286458891],
            [-0.140490081278012, 51.160050853759437],
            [-0.136011849241049, 51.159741254891969],
            [-0.13316349042564, 51.159232685700786],
            [-0.132987227867335, 51.158887238649825],
            [-0.134219682609332, 51.157725225211109],
            [-0.135057814484816, 51.155984956607078],
            [-0.136358320424315, 51.14638676122533],
            [-0.137599181107207, 51.142163621805928],
            [-0.139905495155643, 51.136179498637013],
            [-0.14006474616463, 51.134244033677952],
            [-0.139496631634587, 51.132482253875523],
            [-0.141080148179172, 51.130065829094725],
            [-0.141454446567526, 51.127720099575527],
            [-0.141105633551586, 51.125408739103499],
            [-0.140900733182753, 51.124034042271646],
            [-0.138782521114904, 51.120558704058894],
            [-0.13796699146505, 51.120584389524367],
            [-0.137811853917171, 51.119076481582468],
            [-0.13683577344368, 51.116842344393866],
            [-0.137043063745876, 51.113002912235544],
            [-0.137394410576842, 51.111548935661958],
            [-0.139536279430334, 51.108055058870825],
            [-0.145245481306508, 51.103184297470619],
            [-0.145212783698414, 51.102645093855067],
            [-0.146414130199575, 51.102250466353468],
            [-0.147911173206783, 51.100818214832302],
            [-0.15064312664016, 51.099254402722096],
            [-0.153969781730022, 51.096488553545761],
            [-0.160896859793714, 51.093204651808925],
            [-0.161352805700665, 51.092942931310525],
            [-0.162155040590328, 51.092447438215942],
            [-0.164507344048013, 51.092007764088805],
            [-0.168860520609049, 51.090549911997286],
            [-0.17256900566334, 51.089899291354918],
            [-0.174070949858206, 51.089290549258699],
            [-0.191014122603617, 51.087745471040748],
            [-0.195608392819781, 51.086150966600044],
            [-0.196427345791711, 51.084809253246178],
            [-0.201079009356552, 51.084888125877526],
            [-0.207799090985194, 51.086347498017155],
            [-0.211936829930997, 51.086242693065067],
            [-0.212004897256775, 51.086588166964866],
            [-0.211330227014657, 51.089160641218314],
            [-0.21198659913271, 51.09195042390828],
            [-0.214899309722197, 51.091333051885186],
            [-0.214434702288435, 51.092754945924902],
            [-0.217717823348235, 51.091938120098426],
            [-0.219058880088714, 51.097399325199582],
            [-0.219839672270219, 51.097671114717144],
            [-0.219399670149172, 51.099570030113476],
            [-0.222396970445663, 51.098730731968516],
            [-0.232518605119502, 51.097355386545118],
            [-0.231307285205067, 51.099044834814755],
            [-0.230566824050608, 51.102203656815888],
            [-0.230609060180651, 51.102893158357851],
            [-0.231570747032477, 51.103931109829304],
            [-0.230902022188397, 51.104685395915553],
            [-0.231625632496465, 51.106834872538606],
            [-0.232410237666511, 51.107387214830993],
            [-0.231731742899521, 51.107872467887553],
            [-0.236026825904521, 51.10871164282495],
            [-0.23692032359388, 51.109445456355743],
            [-0.236189806965485, 51.109759084248722],
            [-0.236598394965601, 51.110021546876446],
            [-0.235900401387523, 51.111806912296416],
            [-0.235241659838211, 51.111922872822888],
            [-0.235011647033632, 51.112522828656211],
            [-0.23403144579883, 51.113125844426435],
            [-0.231295500708584, 51.112713978907209],
            [-0.226949418947006, 51.113311834670675],
            [-0.226363204234769, 51.115450464430175],
            [-0.22311473093997, 51.115175418911626],
            [-0.222814924988951, 51.114871396421535],
            [-0.222370897345046, 51.115756749252832],
            [-0.222150261926773, 51.119346085473545],
            [-0.221307198426486, 51.119867445999112],
            [-0.22116685538041, 51.120545179217139],
            [-0.221122205751324, 51.121670419086726],
            [-0.222047577473486, 51.124216911190203],
            [-0.221489820517893, 51.125530394022178],
            [-0.219181549413404, 51.126950323788691],
            [-0.219670185181938, 51.127139422108826],
            [-0.219550285736897, 51.127730232436313],
            [-0.219096689978996, 51.127893291953463],
            [-0.219281784263498, 51.128840371173908],
            [-0.217145956743127, 51.128819517557176],
            [-0.216314323325801, 51.129446234383749],
            [-0.214849192000189, 51.129525497127347],
            [-0.21385477755159, 51.129924887495932],
            [-0.213263453439748, 51.130878100436064],
            [-0.211753694997614, 51.131334347054327],
            [-0.21082579888567, 51.132377725707414],
            [-0.210979937635112, 51.133012288953438],
            [-0.210136217324015, 51.133171143497101],
            [-0.20975852214479, 51.13369774504072],
            [-0.207584801578314, 51.134132980150085],
            [-0.207149418195042, 51.135004018789267],
            [-0.206447592415612, 51.13496717714704],
            [-0.206423004577888, 51.135527960723195],
            [-0.205247628410154, 51.135317478089817],
            [-0.20356279061991, 51.136154032778471],
            [-0.203332255637516, 51.137158602023945],
            [-0.202694882951674, 51.137338559305405],
            [-0.201720319042552, 51.138360465370837],
            [-0.200027496298919, 51.138662664945734],
            [-0.20030223267153, 51.139096756874793],
            [-0.20302511157224, 51.139583780243498],
            [-0.206416580711911, 51.140821115933598],
            [-0.214375020647494, 51.14144830648813],
            [-0.216808080074075, 51.142043892549623],
            [-0.219319558120122, 51.141866329782879],
            [-0.226297426262095, 51.142461584633651],
            [-0.237571128813882, 51.142427885493049],
            [-0.240851543648944, 51.143118470541751],
            [-0.249056123198418, 51.141758665598267],
            [-0.252148637863732, 51.139819292219556],
            [-0.254640544389946, 51.139054341883273],
            [-0.255523257311817, 51.140514470664925],
            [-0.255194841221165, 51.144144501388297],
            [-0.252876090594666, 51.144405750998672],
            [-0.253307832307675, 51.143989533715512],
            [-0.251532915997282, 51.14359250377251],
            [-0.251211828322654, 51.143993282765592],
            [-0.250116633397997, 51.144260177341941],
            [-0.24854823626831, 51.14407302868829],
            [-0.246618989611505, 51.145938925338406],
            [-0.244222189903475, 51.144410144610028],
            [-0.243370611439244, 51.144886570977569],
            [-0.243280585035836, 51.145563283974525],
            [-0.242317257393011, 51.146233170213527],
            [-0.241952099933576, 51.146022643355465],
            [-0.2387251410426, 51.146814028223282],
            [-0.236093945190137, 51.146714120878507],
            [-0.236300030563973, 51.145883585859821],
            [-0.230175125593202, 51.145959225508449],
            [-0.229068505399342, 51.14566099530262],
            [-0.226658694529176, 51.148180268382674],
            [-0.222908500513887, 51.148102660787906],
            [-0.219339230695199, 51.149194070328491],
            [-0.217009215560211, 51.149364509810304],
            [-0.215911589529696, 51.148239841919725],
            [-0.21426210421471, 51.148468043567107],
            [-0.214334801882783, 51.150307533643343],
            [-0.211602424296201, 51.150416864835414],
            [-0.211216101822775, 51.152531490167824],
            [-0.212169681288164, 51.155458880046076],
            [-0.206545306346282, 51.155167731688657],
            [-0.204137375584255, 51.15571532400908],
            [-0.195949421994738, 51.162306138747304],
            [-0.194954058988067, 51.162450856282952],
            [-0.190537064119202, 51.162023789399257],
            [-0.188912679551883, 51.162416820102258],
            [-0.186893886884038, 51.161669705082808],
            [-0.18629960565867, 51.162126325788378],
            [-0.186798142847346, 51.163856203652614],
            [-0.185325618984629, 51.164823490025192],
            [-0.179184840570827, 51.167087806064515],
            [-0.177644391972538, 51.167303927636432],
            [-0.176733834520691, 51.166853579693026],
            [-0.166962612735823, 51.161595554818547],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000227",
        LAD13CDO: "45UF",
        LAD13NM: "Horsham",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-0.504923258388678, 50.956073024232829],
              [-0.503803751225684, 50.954931873557406],
              [-0.504452353485201, 50.954508519681347],
              [-0.504325046463689, 50.954216417602787],
              [-0.502962920291647, 50.953627015361874],
              [-0.503195338508472, 50.952729800972428],
              [-0.502274227071787, 50.95157678823184],
              [-0.503137773831654, 50.950089634741516],
              [-0.502444840093718, 50.948970122900207],
              [-0.503367662444653, 50.947940569098009],
              [-0.50340413946457, 50.94720361485804],
              [-0.502691522199504, 50.946564974373167],
              [-0.503031951150606, 50.945760872251604],
              [-0.506035830434689, 50.943532214486744],
              [-0.508476499687115, 50.943237866158618],
              [-0.509550356648912, 50.943709317027576],
              [-0.509861277031126, 50.943823897908011],
              [-0.512315244955304, 50.943246361257174],
              [-0.5139249849729, 50.941867553628335],
              [-0.513079781713237, 50.940876564058144],
              [-0.512973406718617, 50.939615297334392],
              [-0.511727601722203, 50.937754972769788],
              [-0.5117615736354, 50.936378584220869],
              [-0.512605018421692, 50.935770614639814],
              [-0.518553031739266, 50.935847121144512],
              [-0.522748557618829, 50.935446125647246],
              [-0.52472573970403, 50.936334442986492],
              [-0.5256767599474, 50.938215186953222],
              [-0.525426566663682, 50.938430556595911],
              [-0.526026188350224, 50.938462407248629],
              [-0.527436083775678, 50.938120476200758],
              [-0.52908891448585, 50.938468648894869],
              [-0.534041798278071, 50.936753922797159],
              [-0.533912005401636, 50.936400669558594],
              [-0.533723433035062, 50.93411499458815],
              [-0.534445452964393, 50.93332998131855],
              [-0.539719280003074, 50.93152191971955],
              [-0.542306886375615, 50.930779057445214],
              [-0.54665234091341, 50.929883565842331],
              [-0.547635362778013, 50.929344529016483],
              [-0.547661469933785, 50.926632583299451],
              [-0.548411782857609, 50.925247107536812],
              [-0.5478027685609, 50.923739512235798],
              [-0.546658170188015, 50.923029217933944],
              [-0.545188063076213, 50.921397576260397],
              [-0.54537470224847, 50.920385496547304],
              [-0.550343347284429, 50.922614587524656],
              [-0.550576465474631, 50.923033854695092],
              [-0.552331128866243, 50.922977382917907],
              [-0.55250020725984, 50.92353524277879],
              [-0.556088742660529, 50.923484346345049],
              [-0.556654840764688, 50.924869962268666],
              [-0.556007411691454, 50.925835892975932],
              [-0.557643225407844, 50.929073780701898],
              [-0.561462504245963, 50.92990597456626],
              [-0.565092083686786, 50.931265395008992],
              [-0.568136068567436, 50.933952071277112],
              [-0.568932656295676, 50.935216346924456],
              [-0.568848443654348, 50.93986105122287],
              [-0.568540530740284, 50.940015552610596],
              [-0.568788369810188, 50.942420594470086],
              [-0.565805784530762, 50.94581569631476],
              [-0.564666330390394, 50.948147945190627],
              [-0.563699378489799, 50.949359990853324],
              [-0.561490990107866, 50.950017171088753],
              [-0.560106420950462, 50.950733929982121],
              [-0.559548182110719, 50.951476154656149],
              [-0.557567034317731, 50.951502064265554],
              [-0.557407677621766, 50.951912870039664],
              [-0.555634692150953, 50.952158046690847],
              [-0.55412335407419, 50.952275139534805],
              [-0.553445677340482, 50.951999659122571],
              [-0.552335991809275, 50.952661276747079],
              [-0.551621841657175, 50.952551702310394],
              [-0.551042960623019, 50.952808014177663],
              [-0.55023265518096, 50.953821350842396],
              [-0.546966910367331, 50.95449480469108],
              [-0.545644785424425, 50.95383805442367],
              [-0.544971190144421, 50.953067965846046],
              [-0.545242774768467, 50.952075834279817],
              [-0.54443232189227, 50.95154055096841],
              [-0.545361626853353, 50.950275135982409],
              [-0.544348988036886, 50.948146487421553],
              [-0.542903692273436, 50.948248969813626],
              [-0.542235594512288, 50.94858146411422],
              [-0.54021173589319, 50.94811103595724],
              [-0.537722704169772, 50.948221087501324],
              [-0.53752227439588, 50.948892147381123],
              [-0.535130441113405, 50.94935048758785],
              [-0.534948898897043, 50.949781669100524],
              [-0.533772425975866, 50.950251623729386],
              [-0.533037266607193, 50.952621018707887],
              [-0.532425314004323, 50.953155603674062],
              [-0.530234872399257, 50.952862768366209],
              [-0.529098572323308, 50.952101151903641],
              [-0.528612915034072, 50.952467341407775],
              [-0.528819039894581, 50.951800864794713],
              [-0.528310809156679, 50.952476125146411],
              [-0.527237442197634, 50.952517451871657],
              [-0.524118425020618, 50.953405250070418],
              [-0.520727209919769, 50.955911841235945],
              [-0.519686431442842, 50.956269163157394],
              [-0.516987278329942, 50.95567376387023],
              [-0.516087023626594, 50.95595459956899],
              [-0.509226300190877, 50.956511125690994],
              [-0.504923258388678, 50.956073024232829],
            ],
          ],
          [
            [
              [-0.240851543648944, 51.143118470541751],
              [-0.237571128813882, 51.142427885493049],
              [-0.226297426262095, 51.142461584633651],
              [-0.219319558120122, 51.141866329782879],
              [-0.216808080074075, 51.142043892549623],
              [-0.214375020647494, 51.14144830648813],
              [-0.206416580711911, 51.140821115933598],
              [-0.20302511157224, 51.139583780243498],
              [-0.20030223267153, 51.139096756874793],
              [-0.200027496298919, 51.138662664945734],
              [-0.201720319042552, 51.138360465370837],
              [-0.202694882951674, 51.137338559305405],
              [-0.203332255637516, 51.137158602023945],
              [-0.20356279061991, 51.136154032778471],
              [-0.205247628410154, 51.135317478089817],
              [-0.206423004577888, 51.135527960723195],
              [-0.206447592415612, 51.13496717714704],
              [-0.207149418195042, 51.135004018789267],
              [-0.207584801578314, 51.134132980150085],
              [-0.20975852214479, 51.13369774504072],
              [-0.210136217324015, 51.133171143497101],
              [-0.210979937635112, 51.133012288953438],
              [-0.21082579888567, 51.132377725707414],
              [-0.211753694997614, 51.131334347054327],
              [-0.213263453439748, 51.130878100436064],
              [-0.21385477755159, 51.129924887495932],
              [-0.214849192000189, 51.129525497127347],
              [-0.216314323325801, 51.129446234383749],
              [-0.217145956743127, 51.128819517557176],
              [-0.219281784263498, 51.128840371173908],
              [-0.219096689978996, 51.127893291953463],
              [-0.219550285736897, 51.127730232436313],
              [-0.219670185181938, 51.127139422108826],
              [-0.219181549413404, 51.126950323788691],
              [-0.221489820517893, 51.125530394022178],
              [-0.222047577473486, 51.124216911190203],
              [-0.221122205751324, 51.121670419086726],
              [-0.22116685538041, 51.120545179217139],
              [-0.221307198426486, 51.119867445999112],
              [-0.222150261926773, 51.119346085473545],
              [-0.222370897345046, 51.115756749252832],
              [-0.222814924988951, 51.114871396421535],
              [-0.22311473093997, 51.115175418911626],
              [-0.226363204234769, 51.115450464430175],
              [-0.226949418947006, 51.113311834670675],
              [-0.231295500708584, 51.112713978907209],
              [-0.23403144579883, 51.113125844426435],
              [-0.235011647033632, 51.112522828656211],
              [-0.235241659838211, 51.111922872822888],
              [-0.235900401387523, 51.111806912296416],
              [-0.236598394965601, 51.110021546876446],
              [-0.236189806965485, 51.109759084248722],
              [-0.23692032359388, 51.109445456355743],
              [-0.236026825904521, 51.10871164282495],
              [-0.231731742899521, 51.107872467887553],
              [-0.232410237666511, 51.107387214830993],
              [-0.231625632496465, 51.106834872538606],
              [-0.230902022188397, 51.104685395915553],
              [-0.231570747032477, 51.103931109829304],
              [-0.230609060180651, 51.102893158357851],
              [-0.230566824050608, 51.102203656815888],
              [-0.231307285205067, 51.099044834814755],
              [-0.232518605119502, 51.097355386545118],
              [-0.222396970445663, 51.098730731968516],
              [-0.219399670149172, 51.099570030113476],
              [-0.219839672270219, 51.097671114717144],
              [-0.219058880088714, 51.097399325199582],
              [-0.217717823348235, 51.091938120098426],
              [-0.214434702288435, 51.092754945924902],
              [-0.214899309722197, 51.091333051885186],
              [-0.21198659913271, 51.09195042390828],
              [-0.211330227014657, 51.089160641218314],
              [-0.212004897256775, 51.086588166964866],
              [-0.212689680752351, 51.085899881264439],
              [-0.21276064183012, 51.084910834393234],
              [-0.2115142451655, 51.082835066177537],
              [-0.210891581951164, 51.078731010525097],
              [-0.211542075973365, 51.075152743770062],
              [-0.212593129431442, 51.075364869204392],
              [-0.212332346244407, 51.072204324167821],
              [-0.212996986903767, 51.07195908516362],
              [-0.211286621122086, 51.067857274842545],
              [-0.211847290786518, 51.064456588337372],
              [-0.212241390081288, 51.064118183235877],
              [-0.215521055976197, 51.064155712976067],
              [-0.217816513941325, 51.063621477546995],
              [-0.218563482654377, 51.062714675033597],
              [-0.218652135491065, 51.061892262948959],
              [-0.220169480560825, 51.060990894215358],
              [-0.222018363139013, 51.060746546062724],
              [-0.222655224649316, 51.060214851279525],
              [-0.223199180651708, 51.057263509542459],
              [-0.219432297215284, 51.051786063766897],
              [-0.221242860240058, 51.047988885274563],
              [-0.222354094039047, 51.047583119852128],
              [-0.223587116235873, 51.045634186473201],
              [-0.226023154652226, 51.044082116680073],
              [-0.226785204068012, 51.041572026075407],
              [-0.221771149180186, 51.041085750105921],
              [-0.221616108946959, 51.040403514156836],
              [-0.219173761844732, 51.03814053206407],
              [-0.219186273941472, 51.037925788068954],
              [-0.222465147028721, 51.037637571125337],
              [-0.22299701471707, 51.038052147649047],
              [-0.224085387078151, 51.037939193139877],
              [-0.225332893062136, 51.037244994683782],
              [-0.226549935060288, 51.034620406011449],
              [-0.228251638801432, 51.035326996299894],
              [-0.228154610875593, 51.03772128045064],
              [-0.229264205320951, 51.037911666118148],
              [-0.236123970854638, 51.036250973272935],
              [-0.235860143178445, 51.035799135736539],
              [-0.239336814622491, 51.034537684633655],
              [-0.239411690001591, 51.034255531171915],
              [-0.24078428367091, 51.035070291460976],
              [-0.241454773091828, 51.035029123292496],
              [-0.243974905205356, 51.034579598272913],
              [-0.247113209075496, 51.033432439317053],
              [-0.248087471588064, 51.031045902701187],
              [-0.247973863950507, 51.027969470212376],
              [-0.249108653546479, 51.024951319310013],
              [-0.242660666340252, 51.023774450329739],
              [-0.242206117049623, 51.023275691458451],
              [-0.237887266994519, 51.022177332533744],
              [-0.236676658670073, 51.021087991941052],
              [-0.236971760243734, 51.02026238306172],
              [-0.237800915947512, 51.020092331834235],
              [-0.238545920335766, 51.019471351678689],
              [-0.239978421858334, 51.017411938399249],
              [-0.241018736590569, 51.016972554637746],
              [-0.243075418016093, 51.016914468410299],
              [-0.244612218211985, 51.015710011411272],
              [-0.245525597198357, 51.014136450707753],
              [-0.245164266467752, 51.012264957678141],
              [-0.238068065372136, 51.012066426792096],
              [-0.232042697920636, 51.010851264966043],
              [-0.232789152197938, 51.009852632413541],
              [-0.232688665601369, 51.008370846542022],
              [-0.234715100148355, 51.006283606224962],
              [-0.235772295255105, 51.005732110654456],
              [-0.235031388646481, 51.004259538804966],
              [-0.234854303994417, 50.999574147045749],
              [-0.235023622848496, 50.998680091120036],
              [-0.236718148081464, 50.996311711528527],
              [-0.236286178933456, 50.992782582874987],
              [-0.237158876209272, 50.992503479236731],
              [-0.239195324572004, 50.992687975778331],
              [-0.239351896807123, 50.991977180830141],
              [-0.241098157538494, 50.991507067190568],
              [-0.242023795368357, 50.990730505371658],
              [-0.241847541853484, 50.986759184907491],
              [-0.236166619250003, 50.987178055857157],
              [-0.230990970720871, 50.989182600105494],
              [-0.229574686828482, 50.987009084206562],
              [-0.229783556964353, 50.986424997466521],
              [-0.232817157186175, 50.983605716175006],
              [-0.233961288524161, 50.981688594686567],
              [-0.238276430433912, 50.976686120098677],
              [-0.241103129186553, 50.971809468232664],
              [-0.241534515138512, 50.967854478721009],
              [-0.240863111102063, 50.96372280175234],
              [-0.24117046393928, 50.961655405322929],
              [-0.24047805402248, 50.9589874937435],
              [-0.240998988112311, 50.957447616086469],
              [-0.240924522433898, 50.952858175515075],
              [-0.240259674670295, 50.951273452754528],
              [-0.238773838268509, 50.950054060232127],
              [-0.22988775272517, 50.948614790248875],
              [-0.224839336148951, 50.948469807841292],
              [-0.225084943154212, 50.945310631167438],
              [-0.224483008111367, 50.942950655557183],
              [-0.227670564669609, 50.94237854794153],
              [-0.228282181528561, 50.938571110964546],
              [-0.231721999212556, 50.936329984070142],
              [-0.231970477415253, 50.935820234274487],
              [-0.231643761837807, 50.935390807072295],
              [-0.230148192610925, 50.935346563925101],
              [-0.229516774260121, 50.93349697921613],
              [-0.230298367621661, 50.932027644776063],
              [-0.230289169566212, 50.930020218321687],
              [-0.231532360326887, 50.928947277344406],
              [-0.230646530842908, 50.928628087304951],
              [-0.230016963747791, 50.927893691194463],
              [-0.230099086936994, 50.92689219131676],
              [-0.229379841254655, 50.925972070738112],
              [-0.2298447646384, 50.925307326175194],
              [-0.229718918397463, 50.924199250729636],
              [-0.229397536108886, 50.92265743383161],
              [-0.228687887346001, 50.922161034921523],
              [-0.228568958610348, 50.920909170658611],
              [-0.229260659333828, 50.918804453892825],
              [-0.227738199363013, 50.918246255041666],
              [-0.226649285661836, 50.916800693718436],
              [-0.225927839365354, 50.916580190420206],
              [-0.225804482155207, 50.916008144012707],
              [-0.226905662735826, 50.91421003602607],
              [-0.226527518269638, 50.913679086539169],
              [-0.226845382896686, 50.912689261319741],
              [-0.223811191039081, 50.911789677736209],
              [-0.224489058530196, 50.91139619200662],
              [-0.226306736110779, 50.911665740793417],
              [-0.233511464833039, 50.911298338062878],
              [-0.234040497113675, 50.909873717659977],
              [-0.236157295659456, 50.908213190168702],
              [-0.239610568552788, 50.90769424349439],
              [-0.241041362715782, 50.907660052196746],
              [-0.241518567203617, 50.90801887715098],
              [-0.2418840237486, 50.907719510547913],
              [-0.24418156905505, 50.907369178148649],
              [-0.241668078811097, 50.902162928256708],
              [-0.241673801514223, 50.901973256859435],
              [-0.243552608386267, 50.902122949932149],
              [-0.243837346441018, 50.901472525290799],
              [-0.2432618771412, 50.89926681515319],
              [-0.243971947106941, 50.895777324022646],
              [-0.243366340960392, 50.891728439248304],
              [-0.24386422499102, 50.888698005786431],
              [-0.243818137217585, 50.884894957693319],
              [-0.245087758990495, 50.880617966063994],
              [-0.24557866125947, 50.876290584881133],
              [-0.246890480455668, 50.876295007897596],
              [-0.248431306755523, 50.874968250190847],
              [-0.248686969762003, 50.873077201924929],
              [-0.244368987871586, 50.869028300235605],
              [-0.242432786807352, 50.868323761354482],
              [-0.239265578834035, 50.867892007377797],
              [-0.239825106618547, 50.866054122626053],
              [-0.240603019102145, 50.865208790906109],
              [-0.244998219391931, 50.863815356001815],
              [-0.248500207209405, 50.864143161694102],
              [-0.249634818739708, 50.867997622961411],
              [-0.249005163182043, 50.87065468817692],
              [-0.249748636934849, 50.871030068280639],
              [-0.25533017294363, 50.871557906844949],
              [-0.258961600646089, 50.871213733181548],
              [-0.256463037877431, 50.865879389783828],
              [-0.256606361827455, 50.862341772034036],
              [-0.259246196715654, 50.86216174172403],
              [-0.262247315434381, 50.862353958103718],
              [-0.26951767454389, 50.863722847524194],
              [-0.276089367269687, 50.863432496618366],
              [-0.280071943800856, 50.863255633856113],
              [-0.287976756912919, 50.864158772808828],
              [-0.290415152266274, 50.863851874362886],
              [-0.290962848949442, 50.862747430089208],
              [-0.292533120657554, 50.862901716280881],
              [-0.296384703970032, 50.864249434751017],
              [-0.299487118506931, 50.864203848983998],
              [-0.299918346767265, 50.864693968285003],
              [-0.298521142280396, 50.865521670021842],
              [-0.298142839795553, 50.867988397562463],
              [-0.299091506834267, 50.868905148734832],
              [-0.300830692640271, 50.869501552953608],
              [-0.301250658810099, 50.871163321560914],
              [-0.303511502321282, 50.872231327430555],
              [-0.302736239340733, 50.87404927962384],
              [-0.302679849125314, 50.875445108322772],
              [-0.304195516561746, 50.877469032010744],
              [-0.303777773338961, 50.878443223393752],
              [-0.303264984571809, 50.878511313238477],
              [-0.303622330771175, 50.878691875278825],
              [-0.303064773620723, 50.879757561454355],
              [-0.304317244043202, 50.880851353039709],
              [-0.306551698476492, 50.881995354689451],
              [-0.306677221413029, 50.883081757597004],
              [-0.308343492839859, 50.884923446780583],
              [-0.307512962880499, 50.887598578326639],
              [-0.30904919818044, 50.888167629818845],
              [-0.309509966896292, 50.888675225253209],
              [-0.308667193374189, 50.890549791131427],
              [-0.307034071656279, 50.891945244367214],
              [-0.306906598847349, 50.893500118684436],
              [-0.303509746742082, 50.895608270121173],
              [-0.304020052028108, 50.896514105872583],
              [-0.303435426198308, 50.899375339889929],
              [-0.301776235069061, 50.899402475928198],
              [-0.299513881087973, 50.898848836299933],
              [-0.297573284241227, 50.899105636176436],
              [-0.294129427048128, 50.89881884056409],
              [-0.293270131970476, 50.898944775839759],
              [-0.29257559058286, 50.899465218433342],
              [-0.291865264644606, 50.901232783415068],
              [-0.292589713915404, 50.90265621345975],
              [-0.291701322695177, 50.904274583190343],
              [-0.29264680935654, 50.907005259602791],
              [-0.29238857388872, 50.908037498621077],
              [-0.294639435949054, 50.909927493925728],
              [-0.295481008377579, 50.910564810659579],
              [-0.297720555370309, 50.910860987287329],
              [-0.299050058424103, 50.912045881563614],
              [-0.300062202195326, 50.914251361911518],
              [-0.300059796888567, 50.918221813432915],
              [-0.304978287736427, 50.921818643995664],
              [-0.306053195744673, 50.921956551083518],
              [-0.306918950131743, 50.922525785104213],
              [-0.307627197150838, 50.924377851718752],
              [-0.309760229855872, 50.926337780834743],
              [-0.310251113071726, 50.927791883628629],
              [-0.309371770009898, 50.929242339920854],
              [-0.309589978410084, 50.930995565352909],
              [-0.306631462868888, 50.933265734340971],
              [-0.306238111679999, 50.933947105438534],
              [-0.302894506477674, 50.934978633817607],
              [-0.302294185967616, 50.936276621062312],
              [-0.302183874141846, 50.936515124236266],
              [-0.302096986660347, 50.936565500397009],
              [-0.300734246501715, 50.937355580993483],
              [-0.300801135013285, 50.93864437243181],
              [-0.299001661233943, 50.939708142358526],
              [-0.298705789883279, 50.941613975267806],
              [-0.29782048788497, 50.942937454354968],
              [-0.294520721673484, 50.943526022647326],
              [-0.293246009221787, 50.944594669535299],
              [-0.293465110874405, 50.946619529350485],
              [-0.293121630531157, 50.947213452350731],
              [-0.290273261203109, 50.948759073616827],
              [-0.287629013595883, 50.948928970942532],
              [-0.2792320898191, 50.950439596903166],
              [-0.274955524331916, 50.952356794285379],
              [-0.274788681774693, 50.954363398541908],
              [-0.273941113330677, 50.95479693456015],
              [-0.27473868464617, 50.954483168077843],
              [-0.274649359323519, 50.955280439644277],
              [-0.275069229473766, 50.955284843356644],
              [-0.275086114947762, 50.95248282867145],
              [-0.279545028252466, 50.950485576135662],
              [-0.287717438389602, 50.949040883131914],
              [-0.290333069627155, 50.948875061400187],
              [-0.293293248169695, 50.947308589974206],
              [-0.293667899454605, 50.946563137927356],
              [-0.293323925192449, 50.944915964107665],
              [-0.293691283563306, 50.944369153631996],
              [-0.294745026634716, 50.943581458588291],
              [-0.297968909756442, 50.943043938159612],
              [-0.298958400772797, 50.94163474229488],
              [-0.299180487658637, 50.939839349091834],
              [-0.301051915213246, 50.938675900143053],
              [-0.301058420745302, 50.937364794584418],
              [-0.306344191028333, 50.940742960368496],
              [-0.307655586895409, 50.944886222502397],
              [-0.309215420568581, 50.946857627326906],
              [-0.31351461633321, 50.94795939864035],
              [-0.317654383462336, 50.946778959652534],
              [-0.319432075790976, 50.94694664716053],
              [-0.32113973085575, 50.947952357846795],
              [-0.319715078295387, 50.948770925184085],
              [-0.319582220486977, 50.949296909030451],
              [-0.321302418812343, 50.948059915955717],
              [-0.320827083097057, 50.947423564396935],
              [-0.318599778543222, 50.946617209696363],
              [-0.317364265131167, 50.94669204311932],
              [-0.31344540904672, 50.947826200337026],
              [-0.309411122077204, 50.946760636979711],
              [-0.308291876037495, 50.945435928810618],
              [-0.308747075746823, 50.945013550156531],
              [-0.307964895768267, 50.944835846566576],
              [-0.306661412248914, 50.940826700431394],
              [-0.301559873775527, 50.937352304475425],
              [-0.302238850170032, 50.936622925792911],
              [-0.303167063495784, 50.934997883191727],
              [-0.306298711322636, 50.934081082971147],
              [-0.306753161530673, 50.933402396330806],
              [-0.309445401372695, 50.931489893781318],
              [-0.309904927894756, 50.930551366554951],
              [-0.309571065422155, 50.929201158950988],
              [-0.310447002558544, 50.927648130006133],
              [-0.309875722163473, 50.926213548541973],
              [-0.307739976278533, 50.924249984908492],
              [-0.307042111204052, 50.92246461886775],
              [-0.306128411908716, 50.921844328496121],
              [-0.305161698585043, 50.921743965340013],
              [-0.300217747745541, 50.917986693375894],
              [-0.300241788767594, 50.914085804233594],
              [-0.299282801545514, 50.912060963404002],
              [-0.297813915568325, 50.910719356900643],
              [-0.2952720235268, 50.910327936384128],
              [-0.292618625793941, 50.907931146400287],
              [-0.292876755379219, 50.90705718555764],
              [-0.291936791842787, 50.904058594574472],
              [-0.292798801724341, 50.902655674076115],
              [-0.292197264631433, 50.901137816276588],
              [-0.293087795129761, 50.899225395829056],
              [-0.293903926802006, 50.898957637736764],
              [-0.297532660172843, 50.89932267919238],
              [-0.299492464368802, 50.899046374712171],
              [-0.302467365825922, 50.899676928018302],
              [-0.303820831653724, 50.899335974459277],
              [-0.304305357679887, 50.896567713180765],
              [-0.303828926468384, 50.895590425317565],
              [-0.307246449399895, 50.893539221571118],
              [-0.307280407902048, 50.89197489704749],
              [-0.308790448788531, 50.89075842060879],
              [-0.309743206756228, 50.88883058801823],
              [-0.309292542988487, 50.887965210319358],
              [-0.307798322994394, 50.887413858198144],
              [-0.308612992734197, 50.885136894053886],
              [-0.307649391908281, 50.88375505955841],
              [-0.30675987235056, 50.882841039108413],
              [-0.306553170462746, 50.881641043292589],
              [-0.304933593143669, 50.881024881434683],
              [-0.303388861210575, 50.879875587359983],
              [-0.304537356183409, 50.877175872694728],
              [-0.30291781611163, 50.875428783553481],
              [-0.303769907700526, 50.872082198222067],
              [-0.301604876304349, 50.871037175391159],
              [-0.301075313206648, 50.869262296592012],
              [-0.298710499960718, 50.868244887312507],
              [-0.298662777954053, 50.866276469619535],
              [-0.30288558913245, 50.866140999975499],
              [-0.307624374173628, 50.866511985499365],
              [-0.309542930011224, 50.865253757487103],
              [-0.311454404910288, 50.86477870668142],
              [-0.313521166002036, 50.864921905362365],
              [-0.313618910970255, 50.864536608687757],
              [-0.316133462557336, 50.864675438767989],
              [-0.320471768054014, 50.865433127878411],
              [-0.325491473500787, 50.863835215647583],
              [-0.331609372207519, 50.863070276937428],
              [-0.339751929890215, 50.862546977311396],
              [-0.343362482850561, 50.862913945934416],
              [-0.343813437573442, 50.862940131317387],
              [-0.344577032245939, 50.863968996024923],
              [-0.349147239240902, 50.864448317995318],
              [-0.348697355254243, 50.865236053256524],
              [-0.349141910484202, 50.865967194283101],
              [-0.351969978026119, 50.867375927175466],
              [-0.353009410502248, 50.868287221262833],
              [-0.355863944947499, 50.869556837066],
              [-0.359897479502073, 50.872549861431999],
              [-0.364623569342837, 50.874532622207255],
              [-0.363553156870042, 50.87624071149903],
              [-0.364054737148693, 50.876854781770056],
              [-0.366251929412461, 50.876905341662095],
              [-0.366121868947889, 50.879283113176918],
              [-0.366732544093025, 50.882227028125747],
              [-0.366541428652225, 50.884480737491138],
              [-0.363921727801463, 50.888486472735323],
              [-0.371782089270739, 50.88728337775381],
              [-0.380089463935574, 50.885600329385959],
              [-0.381728053452514, 50.886603309888315],
              [-0.381895342122446, 50.887027407244474],
              [-0.385519829882484, 50.888741323315024],
              [-0.386659329175041, 50.887282203708423],
              [-0.38853676444856, 50.889486273332636],
              [-0.388743562692537, 50.892352540051569],
              [-0.392740723144956, 50.893379799458764],
              [-0.393078608050287, 50.892734248375319],
              [-0.394319454890159, 50.892045362559962],
              [-0.395980458781297, 50.891914415284916],
              [-0.401484470369998, 50.886891714869364],
              [-0.405988635743301, 50.884180728732886],
              [-0.407890476645263, 50.881896350875728],
              [-0.408644730389464, 50.8799110616809],
              [-0.412111840132063, 50.88066333913887],
              [-0.412255342816385, 50.879661658669541],
              [-0.413185849633631, 50.879595172579258],
              [-0.412326106264902, 50.885317494771094],
              [-0.411764730752798, 50.88656619621915],
              [-0.412619067125712, 50.886569723292894],
              [-0.413177693826823, 50.885694194723023],
              [-0.417040225641796, 50.886162119194324],
              [-0.420361349473295, 50.886119875548253],
              [-0.422179315716281, 50.886720920576522],
              [-0.42279664940581, 50.886417202466291],
              [-0.42451916508257, 50.886477334950811],
              [-0.424671334088128, 50.886223085170016],
              [-0.42655679406033, 50.887860977658555],
              [-0.42646495144404, 50.889236583048124],
              [-0.427079335474492, 50.889484978766198],
              [-0.427402188061063, 50.891044234684955],
              [-0.427105793496733, 50.893089767313491],
              [-0.43054056323933, 50.892980415464365],
              [-0.431101233011196, 50.892798197767696],
              [-0.431077595168378, 50.88952349578463],
              [-0.434171050570876, 50.885379645326644],
              [-0.437047982517023, 50.882507135708522],
              [-0.440008882451425, 50.877534889271594],
              [-0.446877183132878, 50.880488981443378],
              [-0.447958539419219, 50.879731757124553],
              [-0.449550193115259, 50.882186439303766],
              [-0.452507617124931, 50.890881521722285],
              [-0.452435439601523, 50.892759217461112],
              [-0.45152637166545, 50.895474760449332],
              [-0.455381551221345, 50.896888281411854],
              [-0.455626289096644, 50.896754824746992],
              [-0.457331395930586, 50.895762052432303],
              [-0.45915941280361, 50.893627859525942],
              [-0.461298152732387, 50.892156026235099],
              [-0.462197329415768, 50.890628262126093],
              [-0.461928208750989, 50.889172336525959],
              [-0.464945335664388, 50.883978983793384],
              [-0.47157208383745, 50.88500661362832],
              [-0.473258201298827, 50.884788563585474],
              [-0.476611464292798, 50.88506349410018],
              [-0.475303147710126, 50.894351526650858],
              [-0.482043375860866, 50.894389900728271],
              [-0.479651999161934, 50.896344445727678],
              [-0.480288729407769, 50.898451711497039],
              [-0.482865669086862, 50.899103948749001],
              [-0.483591658868093, 50.897943387029798],
              [-0.483853843566372, 50.898102371784788],
              [-0.48673232753197, 50.897437362715486],
              [-0.486191120877243, 50.89693662862674],
              [-0.488163487554802, 50.896189685819657],
              [-0.487997818536676, 50.895948325555288],
              [-0.489307929632081, 50.894791695095137],
              [-0.490496875050361, 50.894082235833217],
              [-0.491227333983991, 50.894051209915304],
              [-0.491538027490518, 50.893280924577375],
              [-0.492919501610824, 50.892595511656566],
              [-0.493274621793511, 50.891416612037176],
              [-0.492442832750955, 50.891328533288636],
              [-0.490788369585762, 50.885282716189607],
              [-0.493140202200436, 50.885270831079403],
              [-0.494464150274756, 50.890860901060144],
              [-0.498539723303227, 50.898228319726634],
              [-0.499941101602895, 50.899890258452835],
              [-0.50299576571752, 50.896964468852651],
              [-0.514949662304656, 50.888950581150077],
              [-0.519302562039058, 50.889821540589679],
              [-0.520758734791352, 50.887659194390849],
              [-0.523790786470111, 50.88596641955953],
              [-0.52398908056752, 50.883920316805998],
              [-0.524814512063016, 50.882991879231206],
              [-0.527196189966102, 50.882976088137767],
              [-0.529297776079317, 50.881426107889453],
              [-0.530781482106339, 50.881755050664665],
              [-0.532847258752204, 50.881158715598403],
              [-0.534050676325503, 50.882447245731328],
              [-0.535641102347985, 50.885813507445313],
              [-0.536293416000915, 50.886184106573204],
              [-0.537578349703358, 50.886283845562531],
              [-0.541430067665612, 50.885225894476314],
              [-0.5454101554081, 50.884924828749391],
              [-0.54727458626238, 50.884447135245722],
              [-0.54742374740897, 50.883582065889726],
              [-0.543853731897373, 50.881449411994282],
              [-0.545665303583041, 50.880702194096919],
              [-0.548655133434143, 50.880233098676086],
              [-0.555218338715818, 50.882023184874058],
              [-0.558644092594437, 50.884610551445668],
              [-0.560767949451062, 50.887796891815086],
              [-0.558864238455503, 50.889916435080501],
              [-0.550511067939901, 50.892162887387599],
              [-0.548545614403488, 50.892954131838117],
              [-0.547277903305612, 50.892202737079721],
              [-0.547139751359006, 50.889924891322856],
              [-0.545448514491257, 50.889491052831673],
              [-0.54419204142955, 50.890025762244377],
              [-0.542858907410937, 50.891649452535333],
              [-0.543151846460908, 50.893608185441181],
              [-0.54401131134234, 50.894819471831291],
              [-0.543332680564896, 50.895286735495631],
              [-0.543444455623723, 50.896398764248623],
              [-0.543437970205668, 50.898110948075661],
              [-0.54588476068749, 50.899636082604552],
              [-0.547835244607067, 50.899656762407105],
              [-0.550403856075041, 50.89905198040308],
              [-0.554151820521634, 50.902930361951249],
              [-0.555031405113673, 50.903368417318141],
              [-0.555772076550149, 50.904665374636188],
              [-0.555642815401584, 50.905533395423049],
              [-0.552578000019904, 50.90896672539467],
              [-0.551317433900938, 50.913238935227149],
              [-0.548986369480392, 50.914048133721465],
              [-0.546466916189697, 50.914143597757274],
              [-0.545114971118159, 50.914069205036526],
              [-0.54383674154903, 50.913456138210705],
              [-0.542597437553853, 50.913374127977441],
              [-0.536423453729452, 50.913381435396886],
              [-0.536307876106394, 50.913800857553085],
              [-0.537521462842724, 50.915883547463103],
              [-0.536023809229073, 50.916779364056381],
              [-0.536670422185897, 50.916574336197371],
              [-0.537077129633018, 50.917169370938957],
              [-0.538316198476829, 50.917761337351116],
              [-0.540764866200627, 50.918753311851589],
              [-0.541010379066048, 50.918590909370216],
              [-0.542719612412574, 50.919118557215569],
              [-0.544659319367008, 50.920194924852666],
              [-0.544808267616602, 50.921440506421334],
              [-0.547450159691372, 50.923914086146809],
              [-0.547786322310409, 50.925456060687083],
              [-0.547225214733968, 50.92665953123948],
              [-0.546368644494399, 50.927388990567223],
              [-0.547128559465652, 50.927021643530843],
              [-0.54725860730239, 50.927277761709163],
              [-0.546922539451356, 50.928290681818964],
              [-0.547216331028686, 50.929274567505345],
              [-0.546317979988111, 50.929653681295527],
              [-0.543015579217247, 50.930095445432819],
              [-0.534347274470008, 50.933103026292493],
              [-0.533359380966588, 50.934060961792234],
              [-0.533401222383309, 50.936305225877852],
              [-0.529005064140296, 50.93823917208347],
              [-0.527785426517895, 50.937874876968344],
              [-0.526012373757373, 50.938089025797034],
              [-0.525128607029194, 50.936286473301465],
              [-0.523125074585862, 50.935286316999317],
              [-0.518367579600226, 50.935592066429102],
              [-0.513212359424557, 50.935335895196708],
              [-0.512154726765295, 50.935654265112291],
              [-0.511171193243371, 50.936504154685302],
              [-0.511267732591508, 50.937536877480412],
              [-0.512681393854308, 50.939756365190895],
              [-0.512715554051986, 50.940960956240431],
              [-0.513586992404504, 50.941932497896758],
              [-0.512131111782919, 50.943083941068004],
              [-0.510322110321307, 50.943479052092172],
              [-0.508577397361193, 50.943020626058193],
              [-0.506421340830916, 50.943095591551845],
              [-0.502677431114145, 50.945718557119058],
              [-0.502370505792903, 50.946675968019008],
              [-0.503010341589824, 50.947763324963802],
              [-0.502061568600278, 50.949068626608927],
              [-0.502729758852796, 50.95007181455091],
              [-0.502422310102506, 50.950690184361349],
              [-0.501931770942549, 50.951512143412195],
              [-0.501704346399892, 50.951766424233895],
              [-0.50068967081277, 50.951355015443816],
              [-0.49989808995048, 50.951443773287366],
              [-0.499248054575461, 50.952309536506689],
              [-0.498313439490539, 50.952683320569676],
              [-0.497503922204667, 50.952930107482572],
              [-0.497244709162878, 50.952977133020084],
              [-0.496047782375818, 50.952919459431719],
              [-0.494251661538295, 50.951886408801379],
              [-0.492572994482937, 50.951978065621795],
              [-0.492072331127247, 50.951743181582167],
              [-0.490761518621605, 50.952653423310757],
              [-0.489009144219619, 50.95290954684183],
              [-0.485675391672014, 50.951969771635483],
              [-0.485774083893838, 50.952262423002495],
              [-0.489052726700889, 50.953014428915282],
              [-0.490899701492467, 50.952738843318265],
              [-0.492155999285889, 50.951841385678428],
              [-0.492572293055041, 50.95208777055894],
              [-0.494310647824767, 50.951999581128021],
              [-0.496161261739048, 50.953064807785694],
              [-0.496559919900282, 50.95310860991674],
              [-0.496743187514761, 50.953120861151021],
              [-0.497105422096183, 50.953102141280432],
              [-0.49777914652233, 50.953004692061192],
              [-0.498333837965908, 50.952801390412695],
              [-0.499347567109929, 50.95240254319652],
              [-0.500015547309143, 50.951510031075181],
              [-0.50078172770191, 50.951502782167942],
              [-0.501983818410825, 50.951886018117705],
              [-0.502924907321397, 50.952860329667423],
              [-0.502615298551942, 50.953678315343438],
              [-0.504101834824969, 50.95433946005754],
              [-0.503520667819167, 50.95487968494249],
              [-0.503970121684873, 50.955648043806107],
              [-0.504821381335234, 50.956274960391667],
              [-0.506663044236187, 50.956521546728574],
              [-0.509166018113964, 50.956659638875941],
              [-0.516326420782384, 50.956082642529644],
              [-0.516617647620016, 50.956416381923788],
              [-0.51700621552368, 50.955973468690331],
              [-0.519821798930873, 50.95640037681553],
              [-0.520839636002829, 50.956048158903151],
              [-0.524194392675003, 50.953613946494762],
              [-0.527023154697436, 50.952720687411812],
              [-0.529046332830504, 50.952583413740633],
              [-0.530021287387428, 50.95313436435945],
              [-0.531781018281142, 50.953360638180477],
              [-0.532228880216749, 50.954148650880043],
              [-0.534064864674797, 50.954260739002102],
              [-0.535174968682838, 50.95527108135159],
              [-0.534965145631677, 50.957280163258645],
              [-0.532434219712073, 50.958387791337977],
              [-0.531372280523042, 50.95964334269469],
              [-0.529587011245841, 50.959993187844525],
              [-0.527441461532567, 50.961423908143345],
              [-0.527594250273942, 50.963257689192218],
              [-0.526828550761177, 50.963694082499117],
              [-0.52679258868644, 50.964245793236529],
              [-0.530067867697341, 50.96632484798787],
              [-0.53052697063888, 50.967255993670328],
              [-0.528318668371238, 50.971789387889793],
              [-0.528102245982371, 50.97327408512659],
              [-0.526946230712192, 50.973616530767671],
              [-0.526559228857541, 50.974053200784482],
              [-0.527166228493832, 50.974676868560081],
              [-0.528919876015428, 50.975030803569886],
              [-0.529650935082185, 50.977636253291514],
              [-0.529227172750819, 50.978875533475687],
              [-0.524817590617914, 50.980515090489241],
              [-0.523102251229308, 50.982001522985591],
              [-0.523073434270705, 50.982505659268931],
              [-0.52329070010562, 50.982258403822449],
              [-0.524555891832166, 50.982344534546804],
              [-0.525181540069895, 50.984270615566132],
              [-0.524942128880855, 50.986880022862969],
              [-0.52369985834536, 50.987594552274665],
              [-0.523666518760428, 50.9887802907242],
              [-0.522160029641386, 50.988659624001009],
              [-0.521215373703108, 50.990318545906383],
              [-0.519270534976094, 50.990135637622117],
              [-0.518898947371973, 50.990663305368813],
              [-0.517987959127021, 50.990812725012709],
              [-0.517361571689763, 50.989811064756374],
              [-0.516526179578147, 50.989600818027682],
              [-0.515074250826591, 50.990181297812349],
              [-0.510386594007441, 50.99017557855948],
              [-0.509900870191378, 50.989859129881864],
              [-0.507350457267789, 50.990433591503638],
              [-0.506746751301387, 50.992995142641441],
              [-0.50741942655502, 50.993303201371901],
              [-0.507088012910024, 50.993772891501834],
              [-0.508078102854925, 50.994048131205439],
              [-0.507747563813453, 50.995068198289268],
              [-0.50883028966582, 50.996100015943682],
              [-0.511126316493239, 50.996314551609316],
              [-0.511968460001251, 50.995871140676435],
              [-0.513015021301757, 50.996299036826414],
              [-0.515238881829872, 50.996141167574656],
              [-0.515983345051324, 50.996703687484555],
              [-0.515909707003086, 50.997493224955718],
              [-0.516512030520125, 50.997464003754871],
              [-0.516730815253098, 50.997127750583495],
              [-0.517206041701814, 50.997240798000362],
              [-0.517669928072409, 50.998559642451369],
              [-0.517433965048811, 50.999211328746924],
              [-0.516693215413282, 50.999647972630399],
              [-0.516334455589606, 51.000753136333152],
              [-0.514769807030675, 51.00141581497293],
              [-0.513926706476738, 51.002422186870533],
              [-0.513160741244559, 51.002218196158488],
              [-0.512561383296184, 51.002420101003949],
              [-0.510938490542218, 51.003919222382066],
              [-0.511517492455857, 51.005155923798505],
              [-0.510804468881314, 51.005834791633681],
              [-0.511064977798996, 51.006735598703173],
              [-0.510561426567152, 51.007949511152724],
              [-0.509276489192491, 51.008147154372558],
              [-0.508397239848443, 51.007695283288641],
              [-0.507496765599362, 51.007638820081041],
              [-0.506641256476941, 51.006759178794638],
              [-0.505155806672139, 51.006055826917034],
              [-0.504392956952388, 51.006286174002639],
              [-0.504014868696515, 51.007582600693169],
              [-0.502728241339565, 51.008053533282151],
              [-0.503607050263793, 51.008429002289517],
              [-0.503267863889444, 51.00904696370241],
              [-0.501727438104394, 51.00925294178645],
              [-0.501122387858757, 51.009715508249336],
              [-0.499126486764059, 51.009104452274336],
              [-0.498787708571938, 51.009398663114261],
              [-0.498926221545726, 51.010362678803425],
              [-0.496921112339542, 51.011139065508871],
              [-0.496318977434836, 51.011024303074009],
              [-0.495972106435034, 51.010377748258087],
              [-0.494649323268991, 51.010727622552899],
              [-0.494569921723802, 51.011286854939065],
              [-0.493669443446564, 51.010832800742477],
              [-0.492466615678098, 51.011175203936197],
              [-0.491954975680142, 51.010863743453044],
              [-0.492543480885523, 51.010651012311762],
              [-0.492507711207694, 51.009819609997734],
              [-0.491168882128962, 51.010312224513541],
              [-0.489496700964263, 51.009568491797289],
              [-0.486246040642869, 51.010111859026011],
              [-0.485454525704366, 51.010648368514509],
              [-0.484315182248938, 51.01087820069823],
              [-0.483519534077055, 51.01162777461326],
              [-0.484329811157031, 51.012178759645337],
              [-0.484470220199154, 51.013469259066071],
              [-0.485663350688912, 51.014128617545282],
              [-0.486997071641656, 51.015857237040997],
              [-0.486594414133426, 51.016752208076056],
              [-0.485372818246704, 51.016482781045056],
              [-0.484186191309481, 51.018202116852635],
              [-0.483487652141206, 51.017937655798768],
              [-0.48162227820968, 51.018390053273087],
              [-0.480628712798957, 51.019475167077054],
              [-0.479362846997482, 51.019686220061601],
              [-0.478675084235517, 51.019397589766804],
              [-0.477932785517296, 51.019512024673887],
              [-0.477084401157531, 51.019861584679944],
              [-0.476868774371656, 51.020611477037903],
              [-0.475918620130699, 51.020889557708514],
              [-0.476061044093423, 51.02145976427181],
              [-0.477079495841811, 51.021273400474222],
              [-0.477631141553794, 51.023062973885786],
              [-0.476533403728495, 51.023492015483988],
              [-0.476696859577263, 51.024726168178269],
              [-0.475925334721536, 51.024815928922543],
              [-0.475873785128522, 51.025431267420466],
              [-0.475170919739523, 51.025470659424016],
              [-0.476593366937946, 51.026665477676922],
              [-0.476284183543281, 51.027049038697612],
              [-0.475489311009327, 51.027023383706769],
              [-0.474978361383415, 51.028079672392238],
              [-0.475195596469039, 51.028369379226461],
              [-0.476155087384226, 51.028463729980402],
              [-0.474893693406023, 51.029833972589053],
              [-0.475805053511328, 51.030092267102638],
              [-0.476304678373278, 51.030773246417361],
              [-0.476156157541621, 51.032475454779465],
              [-0.475523452522087, 51.0330247593959],
              [-0.474071293302265, 51.033033684734647],
              [-0.473219954228816, 51.033855303146254],
              [-0.474006620473153, 51.034004067892354],
              [-0.474459831328276, 51.035664668708982],
              [-0.475926600962947, 51.036299816294161],
              [-0.475404107565709, 51.038096065975175],
              [-0.476086513934976, 51.038773134332821],
              [-0.477276929982295, 51.038878579245704],
              [-0.4777391655267, 51.038309958656427],
              [-0.479012120504368, 51.038028869722872],
              [-0.481189580980948, 51.038133627447607],
              [-0.48277938145903, 51.03863179717105],
              [-0.483884462501638, 51.040390747762402],
              [-0.48366091843909, 51.04160187953736],
              [-0.481420286646203, 51.042682502119632],
              [-0.481491626194031, 51.043903755803491],
              [-0.480522823599356, 51.043778753920712],
              [-0.479005552031269, 51.045025208018593],
              [-0.478769868427499, 51.046994267410575],
              [-0.479644345766474, 51.047300610833545],
              [-0.480036159575394, 51.048275133310682],
              [-0.479240836303448, 51.048164967179623],
              [-0.477629837181689, 51.050001911439715],
              [-0.478173455809578, 51.050131293346681],
              [-0.478150564004897, 51.050612111181813],
              [-0.476524112352167, 51.051872399118658],
              [-0.47768080716041, 51.052059233282819],
              [-0.477778915871548, 51.052722382483999],
              [-0.477002890311106, 51.053065690872124],
              [-0.477146618488299, 51.054382313414457],
              [-0.475678790594286, 51.056412642493662],
              [-0.476389668954602, 51.057360760823791],
              [-0.477509553356224, 51.057281828144163],
              [-0.477909596254303, 51.057788837095792],
              [-0.4782386496385, 51.05719689829354],
              [-0.479639883109434, 51.057066751741239],
              [-0.482008166233586, 51.055901489439677],
              [-0.482674480248599, 51.056383162847247],
              [-0.483829705679815, 51.056488081762126],
              [-0.484175405581633, 51.057000659081773],
              [-0.483584807295373, 51.057345516361181],
              [-0.484312179985857, 51.058667900169389],
              [-0.484114781127166, 51.058900951728461],
              [-0.483207878061813, 51.058837028389881],
              [-0.483526155583627, 51.05979619383055],
              [-0.483150593838931, 51.06041542121342],
              [-0.484313856786965, 51.060670618889901],
              [-0.484361703943826, 51.061040843401166],
              [-0.483474837845816, 51.061410635135054],
              [-0.484561816906563, 51.062473292998476],
              [-0.483996121328116, 51.063058582899295],
              [-0.483001976515924, 51.063085253392458],
              [-0.483555081697994, 51.063715631143396],
              [-0.482212038962711, 51.064460789024359],
              [-0.481395417960486, 51.064513132205157],
              [-0.480630090242261, 51.06373969822431],
              [-0.479390396754503, 51.063594081443114],
              [-0.479347267034589, 51.064866900791507],
              [-0.47866430258607, 51.065500099849551],
              [-0.478938323289567, 51.065804924618526],
              [-0.47844754324762, 51.066539543809938],
              [-0.478980886797195, 51.066640010038974],
              [-0.4787802835531, 51.067317254985937],
              [-0.479737074756008, 51.067300925570194],
              [-0.480154683438168, 51.067841427310206],
              [-0.48115104962146, 51.067750061354168],
              [-0.481623226382231, 51.067365009101124],
              [-0.482343096861383, 51.067566803334657],
              [-0.482169996416911, 51.068146389441772],
              [-0.484528637772982, 51.06803490569969],
              [-0.484293980430741, 51.068620890960929],
              [-0.485119222553712, 51.068831228383885],
              [-0.485129720545975, 51.069340356208258],
              [-0.486503245574629, 51.070201671191541],
              [-0.487404653026185, 51.078891369075627],
              [-0.487171111391297, 51.080011544179818],
              [-0.48906540790048, 51.084969473082097],
              [-0.488748213415621, 51.086597559248453],
              [-0.490084733392735, 51.089709235428685],
              [-0.491292449453211, 51.091167263903401],
              [-0.492151181907083, 51.094488572352354],
              [-0.490899255698631, 51.094429254845885],
              [-0.490379724774063, 51.096043040637653],
              [-0.4892355462922, 51.09618203776833],
              [-0.48854354819518, 51.097344848769147],
              [-0.48301430803662, 51.096190513201392],
              [-0.479032721880533, 51.097320433422766],
              [-0.474840097583338, 51.09695826569957],
              [-0.47034519126784, 51.098487666579928],
              [-0.460938674641557, 51.099056666857159],
              [-0.456799259064029, 51.100570443969829],
              [-0.454286735436882, 51.100361896227049],
              [-0.452044694150647, 51.099539072195398],
              [-0.449525350459335, 51.100561442241997],
              [-0.446376328388664, 51.100594281221717],
              [-0.444874396346099, 51.099596799209159],
              [-0.443818871068169, 51.101087247140022],
              [-0.44108314034594, 51.10174054983748],
              [-0.441469145026446, 51.103301446398021],
              [-0.44050056859658, 51.103051122410314],
              [-0.440090070878108, 51.102049246600167],
              [-0.439496240669789, 51.101996358648172],
              [-0.439561705993045, 51.101070076615599],
              [-0.439991062111314, 51.100832101648272],
              [-0.438852018113808, 51.100301612038571],
              [-0.442367488568551, 51.099096703236953],
              [-0.44252843929352, 51.098132123200514],
              [-0.441458145803957, 51.098021638173392],
              [-0.441238955319673, 51.097744436189011],
              [-0.438461178733502, 51.097912405322283],
              [-0.437268558544421, 51.0982112184453],
              [-0.43557325471484, 51.099693040304274],
              [-0.433074141702597, 51.100697342496865],
              [-0.42830156598507, 51.101391377209545],
              [-0.426557376971405, 51.102492017191388],
              [-0.421807138030005, 51.103486447727981],
              [-0.420983997606994, 51.104420477002016],
              [-0.419228744361827, 51.105208809044264],
              [-0.411204241197745, 51.10678079954306],
              [-0.40950717441095, 51.107531108068336],
              [-0.406872866860714, 51.107327978821296],
              [-0.404406681561619, 51.107636077200524],
              [-0.404040203995872, 51.108073521571747],
              [-0.403875907668843, 51.107700775369274],
              [-0.401956940096383, 51.107797765672984],
              [-0.400243981490172, 51.10842362251207],
              [-0.400948562777718, 51.109697648793443],
              [-0.399187284833098, 51.110107002214406],
              [-0.399676706616575, 51.111612802166746],
              [-0.398119871934745, 51.11234598075896],
              [-0.396529807037893, 51.112180301889012],
              [-0.395526467109966, 51.113884164375833],
              [-0.391550509969431, 51.116514792095685],
              [-0.390370774930846, 51.114734169932071],
              [-0.385725064320458, 51.114965990025844],
              [-0.3846569258975, 51.114745303717179],
              [-0.383126734029414, 51.11699753786462],
              [-0.379377862324069, 51.117112007769776],
              [-0.37598130302359, 51.116713264829528],
              [-0.370167213955356, 51.117413847249701],
              [-0.367365845812934, 51.117439515901737],
              [-0.365206337488458, 51.117982187528696],
              [-0.365491655375062, 51.118521249233012],
              [-0.36457133201926, 51.119496692744711],
              [-0.363655985722985, 51.118793238170738],
              [-0.361871495493085, 51.118910339428759],
              [-0.361485996021996, 51.119306018450736],
              [-0.361425367514075, 51.119000312009334],
              [-0.360044661555134, 51.119012431104395],
              [-0.360478295702601, 51.119194771334932],
              [-0.360284820909588, 51.11961472143873],
              [-0.35926594578623, 51.119778487781602],
              [-0.359690473127685, 51.120581208270416],
              [-0.358059754589055, 51.121034042575232],
              [-0.355352567330698, 51.120050851713906],
              [-0.353989679369017, 51.11875649111095],
              [-0.352730048804162, 51.118297204773754],
              [-0.350521789948578, 51.118276866777194],
              [-0.34664670437953, 51.118959552690271],
              [-0.344355876576357, 51.118929837271324],
              [-0.342077543267898, 51.119908352170043],
              [-0.336276411727575, 51.120977941836486],
              [-0.331645872342596, 51.122468630212524],
              [-0.330595770530052, 51.123057963033517],
              [-0.329709078115443, 51.124266530355492],
              [-0.326453388183944, 51.125818910984627],
              [-0.320636730008763, 51.125118611501719],
              [-0.319359351810618, 51.124640724572075],
              [-0.318242286638371, 51.124353080050795],
              [-0.312578196082836, 51.124023279525503],
              [-0.309653957108665, 51.124544898139213],
              [-0.309654672466809, 51.12487764468203],
              [-0.309211734497053, 51.124719261537571],
              [-0.308893771959301, 51.125051896313593],
              [-0.305620345833919, 51.124451449154122],
              [-0.30037378792558, 51.124739521134096],
              [-0.291624970063289, 51.126914210909334],
              [-0.289343027434534, 51.128142552890537],
              [-0.28784047057927, 51.129857088964172],
              [-0.286416905950286, 51.130496315105738],
              [-0.284668618972607, 51.130640649035705],
              [-0.282189093956893, 51.130134823080965],
              [-0.279423669733656, 51.129978158683038],
              [-0.275317959167158, 51.13031246565879],
              [-0.267216986406667, 51.132913060535721],
              [-0.26595586969347, 51.1339177592534],
              [-0.264698261919784, 51.135666209391928],
              [-0.260530565658841, 51.138089929617195],
              [-0.254640544389946, 51.139054341883273],
              [-0.252148637863732, 51.139819292219556],
              [-0.249056123198418, 51.141758665598267],
              [-0.240851543648944, 51.143118470541751],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000228",
        LAD13CDO: "45UG",
        LAD13NM: "Mid Sussex",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.082640732053792, 51.142641488775489],
            [-0.081093266469209, 51.142207820133507],
            [-0.076340245322885, 51.142299698832218],
            [-0.073990058784976, 51.141781682678953],
            [-0.067107359759927, 51.141110548100805],
            [-0.063231148998725, 51.141261348367728],
            [-0.056353324521066, 51.136769383248989],
            [-0.053044710310161, 51.137842978258824],
            [-0.044583911526679, 51.139390709706468],
            [-0.041491811790413, 51.139593467722996],
            [-0.037409360040901, 51.139214757543876],
            [-0.033268725329043, 51.139264798021443],
            [-0.032214004240399, 51.138955675930866],
            [-0.031256946184829, 51.138669772107342],
            [-0.024622028962132, 51.138581257886905],
            [-0.021253872570689, 51.139388552885336],
            [-0.020082905441596, 51.139292290172278],
            [-0.020383787327389, 51.141014177955533],
            [-0.018775430866094, 51.14078189478851],
            [-0.018384799751399, 51.141190759599638],
            [-0.017813840526305, 51.141203565493775],
            [-0.016720972372532, 51.141018661588987],
            [-0.016197368045854, 51.1403937482986],
            [-0.014383727700345, 51.140049104287549],
            [-0.014447720895836, 51.139690464348632],
            [-0.012564278495111, 51.139274459845559],
            [-0.012423122511645, 51.138899743493454],
            [-0.01154390787465, 51.138867708658935],
            [-0.011364186598034, 51.139091280200986],
            [-0.010223604404442, 51.138754418293423],
            [-0.009924648418062, 51.138226827503637],
            [-0.007679213810636, 51.13868411466953],
            [-0.006828058702683, 51.139162435369293],
            [-0.006290611713484, 51.138397846416737],
            [-0.006616356783684, 51.137572430514027],
            [-0.005368925292381, 51.138223848003499],
            [-0.004352131498939, 51.13796817985093],
            [-0.003041699643056, 51.138157147384923],
            [-0.001462921941708, 51.138932366152183],
            [0.001042783789241, 51.139587384312222],
            [0.007008929882539, 51.138975220959587],
            [0.011601517752194, 51.138930478132238],
            [0.015606858070147, 51.139253608271645],
            [0.016904338634726, 51.139109827583347],
            [0.019890372136168, 51.139954907077737],
            [0.020952643295289, 51.139302531542839],
            [0.023262130984169, 51.139058447507701],
            [0.027334010769239, 51.139853967207252],
            [0.02606217906374, 51.137024263052894],
            [0.027200165258007, 51.135558429947388],
            [0.027785083184351, 51.133183070559078],
            [0.026018754271748, 51.131917766601866],
            [0.025261991757768, 51.128211279662196],
            [0.025469099098656, 51.127326355797116],
            [0.025899246670411, 51.126770316413825],
            [0.026427660445662, 51.126696408021147],
            [0.026335348866943, 51.125436257678174],
            [0.025177778757026, 51.123443628789737],
            [0.02558820217212, 51.122633423157694],
            [0.026862271522355, 51.121802850871553],
            [0.028125464152961, 51.117723194787494],
            [0.03273214930606, 51.11658288530959],
            [0.034151315012373, 51.115939465732033],
            [0.034561866654653, 51.114974540088099],
            [0.036494640504972, 51.115095572124766],
            [0.037425618647846, 51.113197961325788],
            [0.036773927920392, 51.112251534718027],
            [0.040000705370992, 51.111781582132863],
            [0.041485745071224, 51.112217013422132],
            [0.041289611308836, 51.110846266956578],
            [0.044193653795517, 51.111127374740931],
            [0.044533929884821, 51.110647478737221],
            [0.041282113690087, 51.11025464076949],
            [0.039261376159618, 51.109429251945009],
            [0.038471252867973, 51.109946655083583],
            [0.038046097021263, 51.109829960725797],
            [0.03750647904978, 51.11042392913587],
            [0.036782776530993, 51.110470714550878],
            [0.036393853792639, 51.109943290074739],
            [0.035392249307824, 51.109842921304548],
            [0.034188734501499, 51.10862549696806],
            [0.032895414037144, 51.108615618636534],
            [0.033119280407229, 51.108345524925575],
            [0.032308602080388, 51.108102413738692],
            [0.031865339103935, 51.108647016517139],
            [0.027016467628475, 51.108577408053144],
            [0.027183517263419, 51.109032268095277],
            [0.025423066881187, 51.10902321174612],
            [0.023889488864315, 51.107895937538302],
            [0.02149901748856, 51.106968725144903],
            [0.018591374615652, 51.103579050488186],
            [0.015674433346535, 51.104562882137849],
            [0.013147684972621, 51.106080415129071],
            [0.00947783485874, 51.110395556636028],
            [0.008555754854775, 51.108833090235144],
            [0.006701942803605, 51.107723684980186],
            [0.00689123555115, 51.106750964996046],
            [0.005722404232877, 51.106249417122164],
            [0.005002540830737, 51.105261718218614],
            [0.006436395604688, 51.10465975140022],
            [0.007335551156478, 51.10483946851442],
            [0.009982793439556, 51.103758860174487],
            [0.011338462306295, 51.102608689464091],
            [0.01044593665092, 51.1021860716532],
            [0.007783889548712, 51.102560076945586],
            [0.002912999900224, 51.102295596280271],
            [0.001346584961198, 51.10144828183234],
            [-0.000690236132705, 51.100985809402921],
            [-0.004268882898494, 51.099180864833912],
            [-0.005292760444163, 51.098920449749194],
            [-0.007092546820353, 51.099149897483983],
            [-0.007190194364185, 51.098440197803448],
            [-0.008083297752984, 51.097765639086177],
            [-0.010892635546109, 51.096502264247746],
            [-0.01202905191523, 51.096346228298778],
            [-0.013871195318063, 51.097552057128837],
            [-0.01423843360313, 51.096814556627827],
            [-0.015847351069205, 51.096563986507427],
            [-0.017686556698023, 51.097006180388945],
            [-0.018331892859317, 51.096376805030175],
            [-0.019580870033832, 51.095992377535225],
            [-0.021360532423308, 51.096759059140176],
            [-0.021738891637254, 51.096594591758823],
            [-0.021606695633118, 51.095910668758975],
            [-0.022376342806003, 51.09537870329364],
            [-0.025402375956404, 51.09421217751251],
            [-0.026196872317667, 51.091753360937233],
            [-0.02701830440183, 51.091746544166732],
            [-0.028073218463036, 51.093133112077673],
            [-0.030861981054433, 51.094217040803109],
            [-0.035512442508245, 51.093836634238436],
            [-0.036223859127284, 51.093460980929351],
            [-0.036220518411964, 51.093000472815746],
            [-0.035154234984822, 51.092449257571452],
            [-0.032828866231303, 51.09239216091818],
            [-0.031071848415374, 51.092571221487525],
            [-0.031811004471291, 51.091340807857826],
            [-0.03156257302598, 51.087841861916196],
            [-0.028070965962342, 51.081871763970192],
            [-0.026879207689868, 51.075116619021273],
            [-0.024038280730279, 51.07582225731597],
            [-0.021868573380956, 51.075951031072634],
            [-0.019609168244527, 51.075672649394562],
            [-0.016875663818657, 51.074229648458505],
            [-0.013283652091448, 51.073970792830401],
            [-0.014018716381151, 51.070439043074913],
            [-0.01499717028176, 51.069355790095656],
            [-0.013170511385218, 51.066513456532149],
            [-0.012179755128265, 51.066351817289032],
            [-0.010440636634082, 51.065235836511107],
            [-0.010279958415396, 51.063790580216164],
            [-0.00979132029948, 51.063536743423647],
            [-0.011447760105762, 51.062683600026375],
            [-0.011166292391673, 51.060959296224631],
            [-0.010430093781368, 51.059256928079883],
            [-0.008501555692304, 51.057640361824376],
            [-0.008653731977451, 51.056588042904366],
            [-0.008237900759492, 51.056335439485643],
            [-0.006076134506093, 51.056830976591755],
            [-0.003803838986036, 51.056311941500681],
            [-0.00257838194771, 51.055508585216131],
            [0.001997224313497, 51.055658715645222],
            [0.001635102767636, 51.057519336576085],
            [0.004155139633755, 51.057748641662606],
            [0.004263108353875, 51.05806785065063],
            [0.006080525598862, 51.057524947216386],
            [0.007816994140231, 51.057981662578712],
            [0.008705893466962, 51.056923163448523],
            [0.010464917133943, 51.05730750535011],
            [0.013698747060552, 51.057016200298754],
            [0.011228506139144, 51.052996463466272],
            [0.007693787830692, 51.050910544215121],
            [0.004012496289928, 51.049883746798706],
            [0.00199553507286, 51.04893625106503],
            [-0.00183670752171, 51.048439763667623],
            [-0.003028439345755, 51.047568000608997],
            [-0.003535530936324, 51.04682392620149],
            [-0.002120664579041, 51.044592786236912],
            [-0.002400624147032, 51.044021101615662],
            [-0.00204345414866, 51.043071596794988],
            [-0.002736290084712, 51.042319909476554],
            [-0.005689047363646, 51.041494395729124],
            [-0.005724665205299, 51.041229701047293],
            [-0.007664827109638, 51.040961523138776],
            [-0.006645815903376, 51.04007538986783],
            [-0.007930474688791, 51.038992021346594],
            [-0.007542946970139, 51.038050109465424],
            [-0.004256032307849, 51.038292591182959],
            [-0.001915749497619, 51.03771749189206],
            [-0.002156357346812, 51.036998543449393],
            [-0.000665951723612, 51.036961358531272],
            [-0.001592077858661, 51.035758607438858],
            [-0.002592487946677, 51.035215430081692],
            [-0.005425109785284, 51.03488520142978],
            [-0.008070796136911, 51.034190186201045],
            [-0.008241273032196, 51.032476268323791],
            [-0.01014443554454, 51.03129819605185],
            [-0.011238255456617, 51.029434518565218],
            [-0.012100059129968, 51.028791773410262],
            [-0.013527734446567, 51.029122728136741],
            [-0.014994519471839, 51.028839187025703],
            [-0.017119115933789, 51.027778079995862],
            [-0.017678658847046, 51.027732715872077],
            [-0.017953597576354, 51.028007179814615],
            [-0.018508190942917, 51.027676639735979],
            [-0.019926176869052, 51.027699780653968],
            [-0.0207784257535, 51.027478596495769],
            [-0.02194030841062, 51.02651620411865],
            [-0.021003283610229, 51.026485047316164],
            [-0.020353923798105, 51.025994702675682],
            [-0.020036277016472, 51.024647517489456],
            [-0.020487697497191, 51.022586704266018],
            [-0.019661972228625, 51.021585244195251],
            [-0.021604347854924, 51.020741304294624],
            [-0.019748697558729, 51.019516451753262],
            [-0.020070712937053, 51.018618080646931],
            [-0.022198845924204, 51.016789812442063],
            [-0.021540403594623, 51.01711861102563],
            [-0.019505137251884, 51.016632660556475],
            [-0.01944126997956, 51.015722352431204],
            [-0.016034352892013, 51.01480657991457],
            [-0.016545145096442, 51.014530164796383],
            [-0.016676932536474, 51.013813834946355],
            [-0.017740138027625, 51.013621436526101],
            [-0.017603400995293, 51.013183838935547],
            [-0.018355728404076, 51.012524801145915],
            [-0.017956194364371, 51.012198759755698],
            [-0.018709306515896, 51.011186294975012],
            [-0.018454903490882, 51.010733212515419],
            [-0.019512772020427, 51.010128813038058],
            [-0.019913970513125, 51.009444925914153],
            [-0.019969890718947, 51.007997946532008],
            [-0.018603922958281, 51.006701328580192],
            [-0.019130110655181, 51.006529486307272],
            [-0.019149342690464, 51.00581034513111],
            [-0.018760886810855, 51.00572731395841],
            [-0.019178538515155, 51.004422268105905],
            [-0.02012271541521, 51.00347149040924],
            [-0.018512074573941, 51.003136607884684],
            [-0.018510575838804, 51.002636552400858],
            [-0.017398966661526, 51.003201359071987],
            [-0.017041186996265, 51.002967754403301],
            [-0.015804549552672, 51.003021399973832],
            [-0.015810289264448, 51.002586219350214],
            [-0.016436082116573, 51.002517706045474],
            [-0.017816073473344, 51.000170484441689],
            [-0.01763329212309, 50.998974864155564],
            [-0.018510352932255, 50.995917615828787],
            [-0.018172011401955, 50.995295830801936],
            [-0.01885805563995, 50.992337861884465],
            [-0.018800559533693, 50.988969771624859],
            [-0.019177799599869, 50.989078694238657],
            [-0.019370574287291, 50.98866736925239],
            [-0.019561205618279, 50.987268536024573],
            [-0.020256702726578, 50.986193028803214],
            [-0.020102027929823, 50.985407983919501],
            [-0.023558971462715, 50.982246008870383],
            [-0.02463041019205, 50.980029283005628],
            [-0.029344068798613, 50.979232958381289],
            [-0.03217850970351, 50.979886003367902],
            [-0.032051496595182, 50.979587981637636],
            [-0.034691557895103, 50.977456942509825],
            [-0.036326612181543, 50.977194858849749],
            [-0.037097672200232, 50.97738858715848],
            [-0.037895307252855, 50.978844524991068],
            [-0.036742154911705, 50.979062571042363],
            [-0.036743965091313, 50.979796459490949],
            [-0.039612121580951, 50.979314029193219],
            [-0.044480091672927, 50.979617773176912],
            [-0.045426160567493, 50.980496971153705],
            [-0.047011694365734, 50.981212384840262],
            [-0.048817390254398, 50.981129248770671],
            [-0.050160785066952, 50.980382753204225],
            [-0.051821015495814, 50.980030040074553],
            [-0.052303035165974, 50.979541647007231],
            [-0.054218845750288, 50.97941259934619],
            [-0.056543589592807, 50.979998101235935],
            [-0.058458131654604, 50.980002064184696],
            [-0.060005970531958, 50.979507070650513],
            [-0.061199238983077, 50.978391926746234],
            [-0.063470383224334, 50.979068132729061],
            [-0.064059103358513, 50.979641774338724],
            [-0.063598585611678, 50.97999297383506],
            [-0.063794858616444, 50.980822714527406],
            [-0.06506983528123, 50.9811676035076],
            [-0.065212352633497, 50.982125954776826],
            [-0.066262100421766, 50.982817838700235],
            [-0.065585407068973, 50.983673589298689],
            [-0.065722578325217, 50.9857892909802],
            [-0.06721492269393, 50.989068675732042],
            [-0.068553879483837, 50.989560275090554],
            [-0.070551513713169, 50.99134968324676],
            [-0.072038156803169, 50.991305881866239],
            [-0.073645734296258, 50.991679546587036],
            [-0.075489186811981, 50.991105584548222],
            [-0.076811265737357, 50.991457414014889],
            [-0.078791746407979, 50.990876662858028],
            [-0.083846710445375, 50.990723173865192],
            [-0.083185312489852, 50.989829180664117],
            [-0.082033110532211, 50.989677166031569],
            [-0.082417559802847, 50.989096216088797],
            [-0.085316993447319, 50.989656398093054],
            [-0.086693979766004, 50.988916330890717],
            [-0.086858619061994, 50.987857819345685],
            [-0.091881766385694, 50.983294053434356],
            [-0.092308862073512, 50.983301929344165],
            [-0.093166015963616, 50.980602649748221],
            [-0.092830751557112, 50.980196974068562],
            [-0.093093925554806, 50.979232693169592],
            [-0.094214993203675, 50.979374199462107],
            [-0.097367137911558, 50.97879426210843],
            [-0.09813372046255, 50.979383217435284],
            [-0.101838756598074, 50.979392228453541],
            [-0.102180560605991, 50.979534480878726],
            [-0.10169094505917, 50.980082309211973],
            [-0.102728191988864, 50.980848303681768],
            [-0.104437583382527, 50.98029329400778],
            [-0.108840165036513, 50.980246850864184],
            [-0.10999031258868, 50.978831939656786],
            [-0.110006439726712, 50.978224254938539],
            [-0.111668012958326, 50.976912031562975],
            [-0.111778805716931, 50.976358038093899],
            [-0.114088741572105, 50.976514071474405],
            [-0.114484323667879, 50.975926902047625],
            [-0.115241570755205, 50.976082117676654],
            [-0.116344880389524, 50.973458591381501],
            [-0.116133528486103, 50.970374084016072],
            [-0.115735926076723, 50.969851455252005],
            [-0.115150397313225, 50.969784449840979],
            [-0.116335341384821, 50.967181125290807],
            [-0.11269543319803, 50.967346292785749],
            [-0.11041199474621, 50.967812094431167],
            [-0.108581255339917, 50.966984753579816],
            [-0.109424673741959, 50.965715066774422],
            [-0.109121520911601, 50.965464641726861],
            [-0.109386277949992, 50.965047142464584],
            [-0.105544223775373, 50.964408409507911],
            [-0.106383454762119, 50.962927333388258],
            [-0.108048240610774, 50.960578284872426],
            [-0.108375354591734, 50.958765137264265],
            [-0.108258894351959, 50.958021302733499],
            [-0.10785614672994, 50.957910455332254],
            [-0.108148499791878, 50.956917833807275],
            [-0.106238543410877, 50.954688010291449],
            [-0.106328114196613, 50.95399068213203],
            [-0.105263374751513, 50.951743067539724],
            [-0.105012749134976, 50.949293715985675],
            [-0.104203395918909, 50.947281362003942],
            [-0.104715518905309, 50.945610620881986],
            [-0.105453301223744, 50.943115252946136],
            [-0.10894263020084, 50.943808544021358],
            [-0.111125740276239, 50.943681999959473],
            [-0.118085582745781, 50.945261189437844],
            [-0.120002846438294, 50.941703740275585],
            [-0.12004306109523, 50.941341956963569],
            [-0.117886019676606, 50.941496067336502],
            [-0.116895180325833, 50.941076291584451],
            [-0.117966408432942, 50.940598030813952],
            [-0.120091260842184, 50.940535132795503],
            [-0.120474126587893, 50.938700363340331],
            [-0.119900457148677, 50.932878739594493],
            [-0.120754755309224, 50.929903109050557],
            [-0.122505453675633, 50.928305279875765],
            [-0.125190386775874, 50.924491183701257],
            [-0.126723145426846, 50.92111900320824],
            [-0.128414095539132, 50.918940056841159],
            [-0.129630481939634, 50.914257830468856],
            [-0.129735640448944, 50.911386140058312],
            [-0.13032280382045, 50.911323598711846],
            [-0.130820742773396, 50.910327914635296],
            [-0.130797610333981, 50.909769057066754],
            [-0.13011922651844, 50.909517170884534],
            [-0.130601291352563, 50.907852127661194],
            [-0.130890204066702, 50.900283445129787],
            [-0.131903244856179, 50.896363265072523],
            [-0.133397609007204, 50.893985941755638],
            [-0.134006343209697, 50.888183256451477],
            [-0.135037641736283, 50.886640287432186],
            [-0.137168385542091, 50.884585151917797],
            [-0.142433093280746, 50.884295834978886],
            [-0.142122074774914, 50.885824255065351],
            [-0.144639331850179, 50.886985770425845],
            [-0.152553971618582, 50.885450239513055],
            [-0.153203804834336, 50.884908331001625],
            [-0.153000785266086, 50.879867933262709],
            [-0.155486176200386, 50.879783130144055],
            [-0.159464396155264, 50.880306393979318],
            [-0.161688589302548, 50.880970982995045],
            [-0.166456043928608, 50.880912874092573],
            [-0.16815395453922, 50.880754272328346],
            [-0.168493506272795, 50.881919743722712],
            [-0.167658023306012, 50.884807885336613],
            [-0.168204262580387, 50.885053883845494],
            [-0.168200161672792, 50.885593420362397],
            [-0.165892243625686, 50.892338158881408],
            [-0.168639438091537, 50.892374099136219],
            [-0.169363430087171, 50.889938368638326],
            [-0.177840830440832, 50.890478415724409],
            [-0.180809841951359, 50.888358234084237],
            [-0.182450263282844, 50.888330729143384],
            [-0.181329947008694, 50.883655631000465],
            [-0.181888890365286, 50.880910578855797],
            [-0.185100344006621, 50.876590696791943],
            [-0.186298436381451, 50.875680309108979],
            [-0.187753127038526, 50.87559228807153],
            [-0.189775664254082, 50.874422165605118],
            [-0.187762277679839, 50.868576720480476],
            [-0.189246772586946, 50.869034141693106],
            [-0.194399038840229, 50.869650871209863],
            [-0.197381559690133, 50.869557580037323],
            [-0.19901536071326, 50.870126900971798],
            [-0.20094847597841, 50.874920484644818],
            [-0.202664792093817, 50.875863348410917],
            [-0.205429345792222, 50.870093501594042],
            [-0.209284819228799, 50.870511556275851],
            [-0.213909748494344, 50.870470910610585],
            [-0.214174417363347, 50.875185661363624],
            [-0.224582302750833, 50.876298639454681],
            [-0.22467426201118, 50.877245234453341],
            [-0.225549671238248, 50.878074239356387],
            [-0.226746558159894, 50.878186859511523],
            [-0.232697874290679, 50.87594516489883],
            [-0.233847490104495, 50.875163962345432],
            [-0.237577765443626, 50.871796603601496],
            [-0.23878149129354, 50.86972563163895],
            [-0.239265578834035, 50.867892007377797],
            [-0.242432786807352, 50.868323761354482],
            [-0.244368987871586, 50.869028300235605],
            [-0.248686969762003, 50.873077201924929],
            [-0.248431306755523, 50.874968250190847],
            [-0.246890480455668, 50.876295007897596],
            [-0.24557866125947, 50.876290584881133],
            [-0.245087758990495, 50.880617966063994],
            [-0.243818137217585, 50.884894957693319],
            [-0.24386422499102, 50.888698005786431],
            [-0.243366340960392, 50.891728439248304],
            [-0.243971947106941, 50.895777324022646],
            [-0.2432618771412, 50.89926681515319],
            [-0.243837346441018, 50.901472525290799],
            [-0.243552608386267, 50.902122949932149],
            [-0.241673801514223, 50.901973256859435],
            [-0.241668078811097, 50.902162928256708],
            [-0.24418156905505, 50.907369178148649],
            [-0.2418840237486, 50.907719510547913],
            [-0.241518567203617, 50.90801887715098],
            [-0.241041362715782, 50.907660052196746],
            [-0.239610568552788, 50.90769424349439],
            [-0.236157295659456, 50.908213190168702],
            [-0.234040497113675, 50.909873717659977],
            [-0.233511464833039, 50.911298338062878],
            [-0.226306736110779, 50.911665740793417],
            [-0.224489058530196, 50.91139619200662],
            [-0.223811191039081, 50.911789677736209],
            [-0.226845382896686, 50.912689261319741],
            [-0.226527518269638, 50.913679086539169],
            [-0.226905662735826, 50.91421003602607],
            [-0.225804482155207, 50.916008144012707],
            [-0.225927839365354, 50.916580190420206],
            [-0.226649285661836, 50.916800693718436],
            [-0.227738199363013, 50.918246255041666],
            [-0.229260659333828, 50.918804453892825],
            [-0.228568958610348, 50.920909170658611],
            [-0.228687887346001, 50.922161034921523],
            [-0.229397536108886, 50.92265743383161],
            [-0.229718918397463, 50.924199250729636],
            [-0.2298447646384, 50.925307326175194],
            [-0.229379841254655, 50.925972070738112],
            [-0.230099086936994, 50.92689219131676],
            [-0.230016963747791, 50.927893691194463],
            [-0.230646530842908, 50.928628087304951],
            [-0.231532360326887, 50.928947277344406],
            [-0.230289169566212, 50.930020218321687],
            [-0.230298367621661, 50.932027644776063],
            [-0.229516774260121, 50.93349697921613],
            [-0.230148192610925, 50.935346563925101],
            [-0.231643761837807, 50.935390807072295],
            [-0.231970477415253, 50.935820234274487],
            [-0.231721999212556, 50.936329984070142],
            [-0.228282181528561, 50.938571110964546],
            [-0.227670564669609, 50.94237854794153],
            [-0.224483008111367, 50.942950655557183],
            [-0.225084943154212, 50.945310631167438],
            [-0.224839336148951, 50.948469807841292],
            [-0.22988775272517, 50.948614790248875],
            [-0.238773838268509, 50.950054060232127],
            [-0.240259674670295, 50.951273452754528],
            [-0.240924522433898, 50.952858175515075],
            [-0.240998988112311, 50.957447616086469],
            [-0.24047805402248, 50.9589874937435],
            [-0.24117046393928, 50.961655405322929],
            [-0.240863111102063, 50.96372280175234],
            [-0.241534515138512, 50.967854478721009],
            [-0.241103129186553, 50.971809468232664],
            [-0.238276430433912, 50.976686120098677],
            [-0.233961288524161, 50.981688594686567],
            [-0.232817157186175, 50.983605716175006],
            [-0.229783556964353, 50.986424997466521],
            [-0.229574686828482, 50.987009084206562],
            [-0.230990970720871, 50.989182600105494],
            [-0.236166619250003, 50.987178055857157],
            [-0.241847541853484, 50.986759184907491],
            [-0.242023795368357, 50.990730505371658],
            [-0.241098157538494, 50.991507067190568],
            [-0.239351896807123, 50.991977180830141],
            [-0.239195324572004, 50.992687975778331],
            [-0.237158876209272, 50.992503479236731],
            [-0.236286178933456, 50.992782582874987],
            [-0.236718148081464, 50.996311711528527],
            [-0.235023622848496, 50.998680091120036],
            [-0.234854303994417, 50.999574147045749],
            [-0.235031388646481, 51.004259538804966],
            [-0.235772295255105, 51.005732110654456],
            [-0.234715100148355, 51.006283606224962],
            [-0.232688665601369, 51.008370846542022],
            [-0.232789152197938, 51.009852632413541],
            [-0.232042697920636, 51.010851264966043],
            [-0.238068065372136, 51.012066426792096],
            [-0.245164266467752, 51.012264957678141],
            [-0.245525597198357, 51.014136450707753],
            [-0.244612218211985, 51.015710011411272],
            [-0.243075418016093, 51.016914468410299],
            [-0.241018736590569, 51.016972554637746],
            [-0.239978421858334, 51.017411938399249],
            [-0.238545920335766, 51.019471351678689],
            [-0.237800915947512, 51.020092331834235],
            [-0.236971760243734, 51.02026238306172],
            [-0.236676658670073, 51.021087991941052],
            [-0.237887266994519, 51.022177332533744],
            [-0.242206117049623, 51.023275691458451],
            [-0.242660666340252, 51.023774450329739],
            [-0.249108653546479, 51.024951319310013],
            [-0.247973863950507, 51.027969470212376],
            [-0.248087471588064, 51.031045902701187],
            [-0.247113209075496, 51.033432439317053],
            [-0.243974905205356, 51.034579598272913],
            [-0.241454773091828, 51.035029123292496],
            [-0.24078428367091, 51.035070291460976],
            [-0.239411690001591, 51.034255531171915],
            [-0.239336814622491, 51.034537684633655],
            [-0.235860143178445, 51.035799135736539],
            [-0.236123970854638, 51.036250973272935],
            [-0.229264205320951, 51.037911666118148],
            [-0.228154610875593, 51.03772128045064],
            [-0.228251638801432, 51.035326996299894],
            [-0.226549935060288, 51.034620406011449],
            [-0.225332893062136, 51.037244994683782],
            [-0.224085387078151, 51.037939193139877],
            [-0.22299701471707, 51.038052147649047],
            [-0.222465147028721, 51.037637571125337],
            [-0.219186273941472, 51.037925788068954],
            [-0.219173761844732, 51.03814053206407],
            [-0.221616108946959, 51.040403514156836],
            [-0.221771149180186, 51.041085750105921],
            [-0.226785204068012, 51.041572026075407],
            [-0.226023154652226, 51.044082116680073],
            [-0.223587116235873, 51.045634186473201],
            [-0.222354094039047, 51.047583119852128],
            [-0.221242860240058, 51.047988885274563],
            [-0.219432297215284, 51.051786063766897],
            [-0.223199180651708, 51.057263509542459],
            [-0.222655224649316, 51.060214851279525],
            [-0.222018363139013, 51.060746546062724],
            [-0.220169480560825, 51.060990894215358],
            [-0.218652135491065, 51.061892262948959],
            [-0.218563482654377, 51.062714675033597],
            [-0.217816513941325, 51.063621477546995],
            [-0.215521055976197, 51.064155712976067],
            [-0.212241390081288, 51.064118183235877],
            [-0.211847290786518, 51.064456588337372],
            [-0.211286621122086, 51.067857274842545],
            [-0.212996986903767, 51.07195908516362],
            [-0.212332346244407, 51.072204324167821],
            [-0.212593129431442, 51.075364869204392],
            [-0.211542075973365, 51.075152743770062],
            [-0.210891581951164, 51.078731010525097],
            [-0.2115142451655, 51.082835066177537],
            [-0.21276064183012, 51.084910834393234],
            [-0.212689680752351, 51.085899881264439],
            [-0.212004897256775, 51.086588166964866],
            [-0.211936829930997, 51.086242693065067],
            [-0.207799090985194, 51.086347498017155],
            [-0.201079009356552, 51.084888125877526],
            [-0.196427345791711, 51.084809253246178],
            [-0.195608392819781, 51.086150966600044],
            [-0.191014122603617, 51.087745471040748],
            [-0.174070949858206, 51.089290549258699],
            [-0.17256900566334, 51.089899291354918],
            [-0.168860520609049, 51.090549911997286],
            [-0.164507344048013, 51.092007764088805],
            [-0.162155040590328, 51.092447438215942],
            [-0.161352805700665, 51.092942931310525],
            [-0.160896859793714, 51.093204651808925],
            [-0.153969781730022, 51.096488553545761],
            [-0.15064312664016, 51.099254402722096],
            [-0.147911173206783, 51.100818214832302],
            [-0.146414130199575, 51.102250466353468],
            [-0.145212783698414, 51.102645093855067],
            [-0.145245481306508, 51.103184297470619],
            [-0.139536279430334, 51.108055058870825],
            [-0.137394410576842, 51.111548935661958],
            [-0.137043063745876, 51.113002912235544],
            [-0.13683577344368, 51.116842344393866],
            [-0.137811853917171, 51.119076481582468],
            [-0.13796699146505, 51.120584389524367],
            [-0.138782521114904, 51.120558704058894],
            [-0.140900733182753, 51.124034042271646],
            [-0.141105633551586, 51.125408739103499],
            [-0.141454446567526, 51.127720099575527],
            [-0.141080148179172, 51.130065829094725],
            [-0.139496631634587, 51.132482253875523],
            [-0.14006474616463, 51.134244033677952],
            [-0.139905495155643, 51.136179498637013],
            [-0.137599181107207, 51.142163621805928],
            [-0.13177048799609, 51.14149509620411],
            [-0.131052439516743, 51.139487169407396],
            [-0.127429313990631, 51.139539822426485],
            [-0.127588276325082, 51.140345446020937],
            [-0.125160047239569, 51.14089558489259],
            [-0.124548602111922, 51.140321017497726],
            [-0.121009388298338, 51.140734539343853],
            [-0.117856383432646, 51.140578615966376],
            [-0.117279773114594, 51.141609829575678],
            [-0.114613997956402, 51.14110823541597],
            [-0.114992064442102, 51.140706045763508],
            [-0.111671651678248, 51.140379086650441],
            [-0.11179236400906, 51.14008336574517],
            [-0.109196797367057, 51.139371443835941],
            [-0.093718017930941, 51.138430370250632],
            [-0.093087498619468, 51.138710566351534],
            [-0.085416697018993, 51.142754418763445],
            [-0.082640732053792, 51.142641488775489],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000229",
        LAD13CDO: "45UH",
        LAD13NM: "Worthing",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.378870365189307, 50.862637353667509],
            [-0.37788485369245, 50.861471634130289],
            [-0.375150035938232, 50.861456074061245],
            [-0.371281572329746, 50.860779823260692],
            [-0.367479015571897, 50.860538736649005],
            [-0.368073864345205, 50.858314945732886],
            [-0.367144247404205, 50.856250594864939],
            [-0.369022016153955, 50.855288500316945],
            [-0.367259353253221, 50.851814960182232],
            [-0.366886023514124, 50.849039828881338],
            [-0.365942024564539, 50.847352977891809],
            [-0.365441040634514, 50.846933173230305],
            [-0.362119684751471, 50.846897395238507],
            [-0.362096559863149, 50.84654363729139],
            [-0.360274294873702, 50.846252746154967],
            [-0.3585469410896, 50.843541286929124],
            [-0.359501310399998, 50.842452138547387],
            [-0.359703851041868, 50.841455838537215],
            [-0.35939242393805, 50.839684291362246],
            [-0.359758220121725, 50.83824422191131],
            [-0.3593633217426, 50.837962578093361],
            [-0.359590879983931, 50.836657262218132],
            [-0.359165188903513, 50.835593672694593],
            [-0.358863412347545, 50.834196376534329],
            [-0.357143113801295, 50.834332247799402],
            [-0.358368782875096, 50.830646082300341],
            [-0.354198053009253, 50.829598970155821],
            [-0.353220558254366, 50.829049180594005],
            [-0.355734228308549, 50.827706865260119],
            [-0.355491315634117, 50.82710359208744],
            [-0.354330979865166, 50.826828226378559],
            [-0.354186345600389, 50.826014994998502],
            [-0.3538031017976, 50.826011387070686],
            [-0.353287725872973, 50.825243285203825],
            [-0.351819972887967, 50.824909595448972],
            [-0.350766977959895, 50.822879326685516],
            [-0.349452261028615, 50.822556762963913],
            [-0.344204578024983, 50.823363747338419],
            [-0.343918957234794, 50.822728366293092],
            [-0.342898480250455, 50.8223172739023],
            [-0.340941078164346, 50.822744515979345],
            [-0.340780662693507, 50.822383403357684],
            [-0.338761445305765, 50.822071382266216],
            [-0.337222771834955, 50.820981962102621],
            [-0.334209606627226, 50.819080069386736],
            [-0.332722946118129, 50.817452633290451],
            [-0.333463356870801, 50.816991963899454],
            [-0.340496692660232, 50.815214422422926],
            [-0.350242621398154, 50.813343546980711],
            [-0.351878581817332, 50.812678684079856],
            [-0.356540338251455, 50.812269602576947],
            [-0.360064958459586, 50.811294867451117],
            [-0.363467937187405, 50.810628587704713],
            [-0.364046489620434, 50.810157360992228],
            [-0.368662264321048, 50.809550199108124],
            [-0.369353641558301, 50.808934833043196],
            [-0.370763521440726, 50.809044463623103],
            [-0.37220587149201, 50.808628424443867],
            [-0.378259997317033, 50.808079615175167],
            [-0.388416316610283, 50.806696885358704],
            [-0.40025633990458, 50.805770777741706],
            [-0.405753265045378, 50.805726415619233],
            [-0.420578090538409, 50.805246375624982],
            [-0.423095110538916, 50.804340633249332],
            [-0.436013773311013, 50.803208723420809],
            [-0.43838440948215, 50.810640989177749],
            [-0.439290727465637, 50.812493125134097],
            [-0.440138671550729, 50.8124774855768],
            [-0.440372125588788, 50.812841233739256],
            [-0.44196139413457, 50.817337479648856],
            [-0.442706984621671, 50.819164057174561],
            [-0.442264959791371, 50.81999002602285],
            [-0.442970761780282, 50.819855556094502],
            [-0.443669660670751, 50.821423399020375],
            [-0.445136003875317, 50.827500751206145],
            [-0.443725091413208, 50.831585524067357],
            [-0.443612711621688, 50.83308588360466],
            [-0.44595942949789, 50.834774600169602],
            [-0.44655667083693, 50.836333872201578],
            [-0.445708396197896, 50.836218255792545],
            [-0.445480786888494, 50.83678538998425],
            [-0.443369359275069, 50.837199706343839],
            [-0.446031898093897, 50.839862093760082],
            [-0.438569219810383, 50.840683375905385],
            [-0.431897882634224, 50.839710789760289],
            [-0.425299864115174, 50.839859359037298],
            [-0.42232491802687, 50.840684318769874],
            [-0.422043616267756, 50.845150119624492],
            [-0.423047108513209, 50.848122434565262],
            [-0.421983056028868, 50.8508608569553],
            [-0.422908304023365, 50.851775377785955],
            [-0.424172367895976, 50.854862715005758],
            [-0.424322815594914, 50.856933170196804],
            [-0.422818791170695, 50.860242116523658],
            [-0.420073031796563, 50.860089866441513],
            [-0.416794520595962, 50.85708219524961],
            [-0.415409865140086, 50.856685690735915],
            [-0.416096134884603, 50.856349669540315],
            [-0.415611209171398, 50.855188366131841],
            [-0.411178872552835, 50.853836690385833],
            [-0.409305636817713, 50.852270643685927],
            [-0.407305890063366, 50.852837817498354],
            [-0.406922255717451, 50.853589805815872],
            [-0.405466148609434, 50.854627516173679],
            [-0.403349506975794, 50.85649163854071],
            [-0.396947879660939, 50.858091899655243],
            [-0.395371186261126, 50.8591134394484],
            [-0.392482129728956, 50.859661834764715],
            [-0.388262038601469, 50.859537981032254],
            [-0.382810940914408, 50.862715405234233],
            [-0.381292499629024, 50.863231247569146],
            [-0.378870365189307, 50.862637353667509],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000234",
        LAD13CDO: "47UB",
        LAD13NM: "Bromsgrove",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.098571106989658, 52.445269061451455],
            [-2.095677829639533, 52.444902853148442],
            [-2.093286366919521, 52.445267965409542],
            [-2.092879782484853, 52.445021959904778],
            [-2.09425322672476, 52.444008594621643],
            [-2.094143807738314, 52.443751567546286],
            [-2.090269294957321, 52.442624551577573],
            [-2.090793872720035, 52.441606478759596],
            [-2.09047646654545, 52.441064624503539],
            [-2.088741578939011, 52.44011300058952],
            [-2.087227158494324, 52.436799508693809],
            [-2.087518348096454, 52.4345472908198],
            [-2.08696986837996, 52.434630404930822],
            [-2.086459009658336, 52.433648170292514],
            [-2.085511253792714, 52.433380958540106],
            [-2.086056022024499, 52.432910383493578],
            [-2.084618982811936, 52.432130189779542],
            [-2.08342161373072, 52.432063617874292],
            [-2.082670567968332, 52.43235092901098],
            [-2.082110473082641, 52.431716624090413],
            [-2.079897899437775, 52.432257546548151],
            [-2.078269606930125, 52.433030882825086],
            [-2.077941718357494, 52.43392381019973],
            [-2.07481768293736, 52.433983370977685],
            [-2.074739004327773, 52.432691553418081],
            [-2.07430950179934, 52.432680137987404],
            [-2.073290555941659, 52.433768569901012],
            [-2.071511019556861, 52.433900917732721],
            [-2.070976748048338, 52.433687278053803],
            [-2.069773111649402, 52.434319996083119],
            [-2.06860690793953, 52.434454632398044],
            [-2.068057916884734, 52.43518044613154],
            [-2.067632094442174, 52.434706916864464],
            [-2.068014753665612, 52.432949145675344],
            [-2.067552287470562, 52.432543961340642],
            [-2.062355705523265, 52.432440744688556],
            [-2.06383133742093, 52.430685099628576],
            [-2.065356136229631, 52.430425350380439],
            [-2.066712585506248, 52.429726064160846],
            [-2.066660316036645, 52.429201974611004],
            [-2.065877283056941, 52.428793368442449],
            [-2.065793845915685, 52.428072414009961],
            [-2.062473591129387, 52.427416151188403],
            [-2.060719244521599, 52.427523151349604],
            [-2.059522440988242, 52.428842601089706],
            [-2.058948517553245, 52.430777545561696],
            [-2.059165715247638, 52.432610504337894],
            [-2.058653665397465, 52.433562804808844],
            [-2.058704140466672, 52.435013770331238],
            [-2.059315064902085, 52.435382055275966],
            [-2.059473604720977, 52.436233330830504],
            [-2.060212228312436, 52.43638398787747],
            [-2.059457321807117, 52.437245615899499],
            [-2.059235894956432, 52.43885134565852],
            [-2.059636699739056, 52.439354584554366],
            [-2.05926976263978, 52.439967889245629],
            [-2.058497020458369, 52.44075220540892],
            [-2.055717332233589, 52.441299247894648],
            [-2.053601012727615, 52.44060260521853],
            [-2.052171007521285, 52.440518741663354],
            [-2.051490237067457, 52.440813914139277],
            [-2.049166079192518, 52.439665084821357],
            [-2.047097070369102, 52.440392325038651],
            [-2.046391942187547, 52.439941306318374],
            [-2.04468708670361, 52.440058836820832],
            [-2.044556773822436, 52.440663015565583],
            [-2.040401475398216, 52.441265050482947],
            [-2.039855180299906, 52.440703360921773],
            [-2.039745469200929, 52.441389336458599],
            [-2.03788757405616, 52.441542780649129],
            [-2.037115997358419, 52.440708753369186],
            [-2.037137314784846, 52.439824128749201],
            [-2.038637049199386, 52.438964199016688],
            [-2.038495425350579, 52.438503956413719],
            [-2.03984936834543, 52.437543370534158],
            [-2.03813703726758, 52.43405131381855],
            [-2.038447097067874, 52.433714086650291],
            [-2.037599315542746, 52.433058088947796],
            [-2.038162147847548, 52.43248164645015],
            [-2.03753848478014, 52.432443190312931],
            [-2.037667147824334, 52.431546842947213],
            [-2.036882733739592, 52.430975325845239],
            [-2.03749740257537, 52.430840280674722],
            [-2.036966195243859, 52.430532989943629],
            [-2.037874717901684, 52.430060723633183],
            [-2.03734934056965, 52.429683310427848],
            [-2.037534319854005, 52.429293982907204],
            [-2.03806813794382, 52.429232678838197],
            [-2.038117755796804, 52.428787655944809],
            [-2.037578826577745, 52.428026373472541],
            [-2.036950845155428, 52.428023875748742],
            [-2.036994550087151, 52.427531207892542],
            [-2.029713141451516, 52.427286950765122],
            [-2.028977367065439, 52.428832522452069],
            [-2.029331938622827, 52.429029316041763],
            [-2.027805459719728, 52.42921668095908],
            [-2.026197051514479, 52.430145722407268],
            [-2.026045245146124, 52.43207951234789],
            [-2.023690753525777, 52.432594242472],
            [-2.023745566999759, 52.433317029769512],
            [-2.024887353585614, 52.434027004741864],
            [-2.024773101532161, 52.434867596578975],
            [-2.016997037153926, 52.432668228130403],
            [-2.015811539751106, 52.432649516153859],
            [-2.015286280529079, 52.432159629711045],
            [-2.015465922395708, 52.428596855542402],
            [-2.013789627548033, 52.429537424379191],
            [-2.012577821313236, 52.429790182159422],
            [-2.013184905349231, 52.428705916390783],
            [-2.009747381084344, 52.426507294060421],
            [-2.007303468260959, 52.428078934435831],
            [-2.006565161728642, 52.427913562688467],
            [-2.004693048138726, 52.428395521974828],
            [-2.004323693322871, 52.426356596181655],
            [-2.003522194908832, 52.426246047068204],
            [-2.003398591000597, 52.425377613025013],
            [-2.001655838672818, 52.422984504651588],
            [-2.002826317692341, 52.422316519467564],
            [-2.001282289762434, 52.421857158366628],
            [-2.002049829985234, 52.420973424171429],
            [-2.000374984872062, 52.420229069051054],
            [-1.999025165713173, 52.418698962054947],
            [-1.997016691647843, 52.41792758644641],
            [-1.997068184739859, 52.417411558565206],
            [-1.991049072553713, 52.415398396228049],
            [-1.988703773625164, 52.416353843626126],
            [-1.988225768158984, 52.416974111843743],
            [-1.986043909822711, 52.416711369740931],
            [-1.988868998235553, 52.414107243686772],
            [-1.991081781080022, 52.413544648640908],
            [-1.993251787969952, 52.41355827263807],
            [-1.995615963766924, 52.412481369419147],
            [-1.996933363237596, 52.410339076019348],
            [-1.997799272905451, 52.409718778893811],
            [-1.998149140082599, 52.409973202951036],
            [-1.998623980731616, 52.409743062701409],
            [-2.00102020973333, 52.410294151932341],
            [-2.002084583079767, 52.410856913590287],
            [-2.002797571276453, 52.410663610906155],
            [-2.004208896668061, 52.410954844002063],
            [-2.004646795427447, 52.409208956035535],
            [-2.006449065124307, 52.409119865787304],
            [-2.008104242520845, 52.408605528409474],
            [-2.009245367235394, 52.410373788072881],
            [-2.010866667920497, 52.409543863557651],
            [-2.012807136551845, 52.409496017612369],
            [-2.012356925292088, 52.408190708831015],
            [-2.012685949141966, 52.40730245448853],
            [-2.01428221284607, 52.406866250507797],
            [-2.015078675940168, 52.40610918590896],
            [-2.017556791320667, 52.405568535070586],
            [-2.020485495976367, 52.406853637503417],
            [-2.022036292275856, 52.406803010784884],
            [-2.023167892123559, 52.4062777718243],
            [-2.024185144027158, 52.406356678469599],
            [-2.027542145869747, 52.408091020038363],
            [-2.027868348907413, 52.407870686199885],
            [-2.02727867904312, 52.407554374613454],
            [-2.027322495164174, 52.407099466891644],
            [-2.02806586914143, 52.40641784379082],
            [-2.033443328047852, 52.403019084271975],
            [-2.033648566015483, 52.402314203577312],
            [-2.032216386198165, 52.401534266961477],
            [-2.03096218541047, 52.400876531104494],
            [-2.028417708557942, 52.400418681709269],
            [-2.025791239759605, 52.400404006574618],
            [-2.025110884297682, 52.400653179762287],
            [-2.021365686061859, 52.400180147060077],
            [-2.01927985910464, 52.396288703293308],
            [-2.018083025211591, 52.394934987972889],
            [-2.016911505215278, 52.394247422198717],
            [-2.015083520879076, 52.394654924667378],
            [-2.014204577573263, 52.394221713223317],
            [-2.013448624111561, 52.392276347707245],
            [-2.01295968097611, 52.388686658521777],
            [-2.011361814093232, 52.386166901560102],
            [-2.008468944016462, 52.386948390437404],
            [-2.00614577461943, 52.38565666168806],
            [-2.00500984896164, 52.384140986394584],
            [-2.00417531283737, 52.383943238379082],
            [-2.002216866512659, 52.384186028533279],
            [-2.002194790947595, 52.3835153671629],
            [-2.001288297012227, 52.383551342965951],
            [-2.000126143633187, 52.381649045897419],
            [-1.997330410019696, 52.381052977854743],
            [-1.9947109670567, 52.381128412446344],
            [-1.992889134577686, 52.381937430732549],
            [-1.989285193996421, 52.382472078450625],
            [-1.988153685769014, 52.383436611417167],
            [-1.988198956658175, 52.384488458112017],
            [-1.985508317385077, 52.385976925942302],
            [-1.983355303197066, 52.387380001935249],
            [-1.978935345016206, 52.387857580133193],
            [-1.978600505900375, 52.387499714791261],
            [-1.977805504252813, 52.387689261078719],
            [-1.975585660122512, 52.387096380852441],
            [-1.972465674627458, 52.388111583533302],
            [-1.971628922171403, 52.389200987412252],
            [-1.97055450946665, 52.389642140828954],
            [-1.969724275805869, 52.389674297320568],
            [-1.968967864906093, 52.389197627743172],
            [-1.968835448743121, 52.389443022851509],
            [-1.966369798497099, 52.389450443733061],
            [-1.964934996972303, 52.392173129756472],
            [-1.960442588896531, 52.38907823020768],
            [-1.959550790537916, 52.388949372026985],
            [-1.959416265497446, 52.388227421328878],
            [-1.958919618003511, 52.388233543963324],
            [-1.953621664072736, 52.3933559502142],
            [-1.9529059809975, 52.393379043119282],
            [-1.945028404692392, 52.390556366823816],
            [-1.939679520379159, 52.389585537100515],
            [-1.936353758272066, 52.387890066133906],
            [-1.934322643826987, 52.387221894201133],
            [-1.934162023034738, 52.387535559740328],
            [-1.93464346362131, 52.387875651617428],
            [-1.932866286633619, 52.39031097638766],
            [-1.932079870611011, 52.39049033086652],
            [-1.928080626424499, 52.392876642627677],
            [-1.928280078759917, 52.393124889910638],
            [-1.924390091862522, 52.396678954307163],
            [-1.923143182065353, 52.396210668498256],
            [-1.922444010793326, 52.396841317616946],
            [-1.918585220600073, 52.398178254738546],
            [-1.918303877483402, 52.400119020394492],
            [-1.916475793867098, 52.401529189910832],
            [-1.915310092383926, 52.403149274520132],
            [-1.914656450245848, 52.406740341008025],
            [-1.913965746683982, 52.406646345221368],
            [-1.913681949535537, 52.407449851449101],
            [-1.910818487337282, 52.406676384511918],
            [-1.909673542116144, 52.404432494320183],
            [-1.906633075955411, 52.404079526194849],
            [-1.904594920200945, 52.403131248467766],
            [-1.90069680270415, 52.403821182424132],
            [-1.896139916786622, 52.403312934050177],
            [-1.892387629115695, 52.404418962371537],
            [-1.887121830047731, 52.404671180297761],
            [-1.886695516007623, 52.404687854622573],
            [-1.885945902524098, 52.404673650438127],
            [-1.886199574254802, 52.404343059440848],
            [-1.882704439684274, 52.39979876113177],
            [-1.878361126894531, 52.399883383827799],
            [-1.877869093996777, 52.402430667379456],
            [-1.8722977033048, 52.402179364112172],
            [-1.872284627601762, 52.40467679442407],
            [-1.868753785643754, 52.404723280010892],
            [-1.868653926700634, 52.404195451680735],
            [-1.866287835771699, 52.404541619860233],
            [-1.86503019934141, 52.404346007967391],
            [-1.864786280169763, 52.404800627838952],
            [-1.863799719799884, 52.404404833179669],
            [-1.863459535851098, 52.404612112412487],
            [-1.861773818374758, 52.40358708624715],
            [-1.856992118871978, 52.402746231222103],
            [-1.853445012924086, 52.401530032095309],
            [-1.851711025811982, 52.40053986335024],
            [-1.850652927618861, 52.401808833027538],
            [-1.849690860867403, 52.401615228155741],
            [-1.845481209914542, 52.400391653952987],
            [-1.84560514098148, 52.399412794927592],
            [-1.846488600286559, 52.397247331453634],
            [-1.848773718828101, 52.394289833258597],
            [-1.856202781703512, 52.388714435961198],
            [-1.858257036912059, 52.387312657834492],
            [-1.857213388644082, 52.386532860888529],
            [-1.858745970805036, 52.38603934374165],
            [-1.858803191807156, 52.385603391694772],
            [-1.861331390211954, 52.383856004317401],
            [-1.860948637576505, 52.383160619884741],
            [-1.86182904294587, 52.381158203331957],
            [-1.861368743719787, 52.379896801556271],
            [-1.862056413356431, 52.378906893473072],
            [-1.861931659815603, 52.378397907334538],
            [-1.863104118878499, 52.377389677852761],
            [-1.863480172901396, 52.37596068247673],
            [-1.865016805354988, 52.374475477240956],
            [-1.865372267298426, 52.373511241292078],
            [-1.866568770043206, 52.373230305381469],
            [-1.866967573477774, 52.372492664515477],
            [-1.867617417944485, 52.37228482170044],
            [-1.86769883334983, 52.371580985555035],
            [-1.86828101120125, 52.371401831873278],
            [-1.869061599791799, 52.370183635697757],
            [-1.870284368794132, 52.369922469393323],
            [-1.87006559468406, 52.369397205840663],
            [-1.870532009783917, 52.368610180928428],
            [-1.872041858754703, 52.367584249386724],
            [-1.873695471531267, 52.366074782419346],
            [-1.873471431976441, 52.365328361809013],
            [-1.876133035935778, 52.363567309823353],
            [-1.876674417523619, 52.363750374635309],
            [-1.879666182148267, 52.362540681789582],
            [-1.880871527974055, 52.362650680917476],
            [-1.884326444747499, 52.361707443111328],
            [-1.887813266866945, 52.361445587359214],
            [-1.888981614445616, 52.361078991137589],
            [-1.887018413256627, 52.356991113082088],
            [-1.886412528986306, 52.35620928951753],
            [-1.885415697303906, 52.356155287548177],
            [-1.884918017183139, 52.355561455014318],
            [-1.884514044708917, 52.354508314842711],
            [-1.884785201333702, 52.353556521469137],
            [-1.876237578445544, 52.345558340406775],
            [-1.875168832781417, 52.342977039554256],
            [-1.874762786723336, 52.340178868317714],
            [-1.873087536056365, 52.337283145809778],
            [-1.871176105969962, 52.335946938426893],
            [-1.868264272299273, 52.333001246299851],
            [-1.865328219044098, 52.333332377863329],
            [-1.864838835072734, 52.333078296822514],
            [-1.867474188611723, 52.331219407833494],
            [-1.866678143982731, 52.318239359955662],
            [-1.867196406278619, 52.318105988379209],
            [-1.868577054397815, 52.314574375046597],
            [-1.870394467206923, 52.31259403570786],
            [-1.874896640217387, 52.31244784360603],
            [-1.88094266784228, 52.310918548562462],
            [-1.890093559241768, 52.318219359944649],
            [-1.890993356785672, 52.320407509187731],
            [-1.891877050736206, 52.321410727179462],
            [-1.893036266877955, 52.32133806013465],
            [-1.895033338293482, 52.321834308137156],
            [-1.89755313817064, 52.321532645529793],
            [-1.89897655486989, 52.322052602833672],
            [-1.89901387889768, 52.321768544234544],
            [-1.908792202792148, 52.320458512182668],
            [-1.90939632568843, 52.323500361209362],
            [-1.912161625901399, 52.322954940141919],
            [-1.914158269935949, 52.323060689617549],
            [-1.915808007556825, 52.322738225863802],
            [-1.922073369320643, 52.32246201632011],
            [-1.926333478719302, 52.323691902227957],
            [-1.934707659244212, 52.324161601520828],
            [-1.93931826921192, 52.323744207040242],
            [-1.939353778574093, 52.323526662391245],
            [-1.940957842422328, 52.32322270358074],
            [-1.942582550464789, 52.321682580417402],
            [-1.942940077025988, 52.320894312128495],
            [-1.944881796912976, 52.321502068275976],
            [-1.948184058325804, 52.321741795716271],
            [-1.950074922705575, 52.322004220511815],
            [-1.951707568116623, 52.320929667431223],
            [-1.952605131754243, 52.319846709455405],
            [-1.955105374713293, 52.320983143058939],
            [-1.955769031806808, 52.318982172613978],
            [-1.957064288385173, 52.317620631347658],
            [-1.957832276339135, 52.318391366941086],
            [-1.958633359348736, 52.318306241411769],
            [-1.958345034057317, 52.31757613597722],
            [-1.960097875830269, 52.316202134284261],
            [-1.959907416972437, 52.315926969820474],
            [-1.960456298265392, 52.315657447302861],
            [-1.960119443257821, 52.31504869757358],
            [-1.960589156579585, 52.314713519281668],
            [-1.969578232335975, 52.315012832984188],
            [-1.968651073569063, 52.31300417923525],
            [-1.97400340270113, 52.308379100558248],
            [-1.977896787023251, 52.309861472704178],
            [-1.980670705594971, 52.309213758174323],
            [-1.982013138287948, 52.308291570620788],
            [-1.980722935686595, 52.307126237270133],
            [-1.979880083410116, 52.306049069832696],
            [-1.976499260837328, 52.303695710988343],
            [-1.979115434053972, 52.300884052695494],
            [-1.980610045974869, 52.3002172288897],
            [-1.981604659650746, 52.299317463336692],
            [-1.98499034783168, 52.300078508309142],
            [-1.98484701617167, 52.298948417885022],
            [-1.983420287051634, 52.298741454017531],
            [-1.984054954231125, 52.295382787058955],
            [-1.983722242231269, 52.295012344495696],
            [-1.985189045243432, 52.293379007211321],
            [-1.984158750342505, 52.292086078646406],
            [-1.986158973211216, 52.290891521745749],
            [-1.98843466534633, 52.289887550692534],
            [-1.99090314839, 52.292035531171372],
            [-1.992804994592397, 52.290599016654411],
            [-1.994869305477577, 52.290500227022854],
            [-2.002025309567038, 52.2881250810862],
            [-1.999182688169271, 52.285619518323557],
            [-1.997960160695222, 52.283584112082302],
            [-2.002467527516613, 52.279568143949241],
            [-2.00394644587817, 52.279234561646604],
            [-2.004770227252131, 52.279512328590883],
            [-2.00502521640152, 52.279071794451909],
            [-2.006316452738702, 52.278517030149629],
            [-2.007977145271412, 52.2786670633783],
            [-2.009161322032292, 52.278045747377767],
            [-2.010361900219644, 52.278728905711986],
            [-2.011700010668499, 52.278352085567207],
            [-2.015058022354325, 52.27850183507207],
            [-2.020027297202382, 52.279539461468914],
            [-2.020922480368775, 52.278712199830771],
            [-2.022908391385779, 52.278418744165229],
            [-2.023262919031725, 52.278085135163614],
            [-2.024826654003751, 52.280367441681946],
            [-2.027623765039434, 52.280995233685594],
            [-2.030474351872929, 52.282529163804917],
            [-2.033123234043414, 52.282636327778619],
            [-2.033324240960405, 52.282879906833863],
            [-2.033895732817389, 52.282624419522648],
            [-2.034407468375246, 52.282817560992186],
            [-2.033902736626947, 52.283137011558409],
            [-2.035240735067557, 52.283637225093543],
            [-2.035626803677906, 52.284304185572807],
            [-2.03598293938169, 52.284204284941865],
            [-2.038395591874284, 52.285615889198787],
            [-2.041532110430456, 52.286503058069435],
            [-2.043325876669037, 52.2873834545411],
            [-2.047019266615982, 52.287848627769542],
            [-2.051208152321875, 52.286957740372067],
            [-2.055550534465027, 52.284691122491722],
            [-2.057819250203472, 52.284278274672616],
            [-2.060896067383571, 52.284179626582429],
            [-2.061859938129939, 52.284728428293569],
            [-2.070828171783411, 52.287277502188701],
            [-2.074202254305214, 52.288621263970946],
            [-2.076748098491569, 52.28906464886218],
            [-2.08430235152109, 52.288846412455278],
            [-2.087591107053643, 52.28903460520678],
            [-2.0893439426104, 52.289471113627179],
            [-2.091696562124362, 52.288546905274565],
            [-2.08752080505615, 52.294261588177072],
            [-2.084886701077917, 52.296939012781948],
            [-2.08768427258242, 52.303505229074489],
            [-2.087966485701824, 52.303825070819165],
            [-2.089696143186813, 52.303389538056464],
            [-2.090314498795907, 52.303850264339793],
            [-2.090407586856831, 52.304908342938909],
            [-2.088713575375345, 52.306324692934318],
            [-2.087237810563947, 52.309880529767618],
            [-2.085284016759644, 52.312126818556848],
            [-2.085191230235938, 52.312693269380922],
            [-2.085866972592738, 52.313203422900074],
            [-2.085169285528054, 52.313485323273035],
            [-2.086335989251706, 52.314511156256252],
            [-2.095110035728617, 52.313351852858595],
            [-2.094118404955787, 52.315409610357449],
            [-2.095649456463895, 52.314529134322569],
            [-2.098805583278601, 52.31357176882026],
            [-2.100316115972746, 52.313414961643716],
            [-2.102173229838619, 52.313435841407085],
            [-2.107520912381485, 52.314416410869057],
            [-2.109966694170358, 52.314594857352049],
            [-2.112347033941844, 52.314411908940748],
            [-2.114489153408758, 52.313444298274071],
            [-2.114315324071399, 52.313158578084114],
            [-2.117133103993197, 52.3131306388334],
            [-2.124061988591314, 52.314488264891828],
            [-2.122200465563315, 52.316610101969019],
            [-2.11859816078447, 52.319025854950489],
            [-2.115754185894665, 52.32208265528601],
            [-2.118393886819055, 52.322733621374255],
            [-2.117869390224205, 52.323017338394649],
            [-2.118596981344255, 52.325156276554907],
            [-2.117304336229812, 52.325162065058883],
            [-2.117620561696648, 52.326553432698823],
            [-2.117041329369641, 52.327242658313175],
            [-2.116156113927813, 52.327085306443806],
            [-2.118481979758378, 52.332073447810764],
            [-2.117539658827199, 52.335316252391799],
            [-2.117239875856746, 52.335168212860133],
            [-2.116289807079056, 52.335523367112209],
            [-2.115236466901865, 52.33511444908153],
            [-2.11467491333138, 52.335320871893487],
            [-2.113520965801851, 52.336303716770601],
            [-2.11328977239013, 52.337147218218099],
            [-2.114173531211286, 52.337803547791786],
            [-2.114204702325057, 52.338507448653232],
            [-2.116471479664138, 52.338749763812217],
            [-2.116096526711332, 52.340166086630404],
            [-2.116780323866498, 52.340097984952898],
            [-2.117422838222888, 52.341619383499626],
            [-2.116985001006452, 52.342011790351826],
            [-2.118864345577093, 52.342209492469223],
            [-2.119289416935659, 52.341443100314379],
            [-2.121297226121889, 52.340319077869545],
            [-2.123862203029485, 52.34007637353163],
            [-2.124687570117676, 52.340249014040523],
            [-2.124758497581548, 52.341978648848823],
            [-2.122780947586189, 52.342365504087489],
            [-2.123100535140293, 52.342749051275156],
            [-2.125346759519625, 52.343932488603834],
            [-2.126392124866751, 52.345554098515017],
            [-2.12903756523807, 52.345626750850876],
            [-2.131477157858566, 52.347065180213988],
            [-2.129091896161995, 52.350662083064258],
            [-2.127330966829987, 52.351960378647817],
            [-2.127732532093743, 52.352223356056761],
            [-2.129728676417681, 52.352034181836025],
            [-2.13073663752125, 52.351313855959042],
            [-2.13201176338987, 52.350102359771157],
            [-2.133022822457237, 52.347998424261753],
            [-2.137174614294815, 52.349965211599738],
            [-2.135972860034729, 52.351118242977641],
            [-2.135861720410189, 52.351738692166023],
            [-2.135532358356083, 52.35157634919284],
            [-2.135347437145614, 52.353036563474568],
            [-2.134120731272874, 52.353267213837299],
            [-2.132471658042528, 52.354640975525946],
            [-2.132432693332705, 52.355357534659468],
            [-2.131251113672447, 52.356472735541843],
            [-2.130764967157595, 52.35791529588824],
            [-2.128550810528535, 52.358459836058245],
            [-2.125806966387032, 52.358647991993998],
            [-2.124319776388089, 52.359267192302333],
            [-2.122415378186238, 52.35877652438208],
            [-2.121430279415738, 52.358843170945832],
            [-2.119219222522349, 52.357874490938059],
            [-2.119428443825057, 52.359226397590604],
            [-2.120972523762205, 52.360591326003139],
            [-2.121218086114231, 52.361248253458989],
            [-2.120905712249714, 52.363007945187569],
            [-2.120191416056708, 52.363328724397874],
            [-2.120101740490578, 52.363834061687676],
            [-2.121872239430599, 52.366291048297619],
            [-2.122501318543664, 52.366473795336141],
            [-2.122095514162809, 52.366838316680017],
            [-2.123140678213746, 52.372979287958323],
            [-2.12435737525763, 52.374197072498745],
            [-2.12845213842706, 52.38210848451709],
            [-2.131123915465357, 52.385367157362694],
            [-2.13252656526053, 52.386181891865668],
            [-2.131466363405603, 52.387872319008771],
            [-2.129901252194635, 52.388782952226883],
            [-2.13225587443253, 52.389993100974372],
            [-2.132073158116321, 52.390314252278202],
            [-2.133404894664172, 52.391432021478593],
            [-2.133400336672868, 52.391868945456565],
            [-2.137706311141891, 52.393884972450387],
            [-2.136197292448295, 52.395379978704],
            [-2.137861284005225, 52.395511998686182],
            [-2.135187106361464, 52.398551945623332],
            [-2.132039358061598, 52.398176128336743],
            [-2.131356796599559, 52.399447189656172],
            [-2.130978842997436, 52.399368497056912],
            [-2.129241256104829, 52.400747694350891],
            [-2.126028159105901, 52.402230039003086],
            [-2.127238058244016, 52.402822085825385],
            [-2.126253133137531, 52.403289729096663],
            [-2.127738557362899, 52.404606075956288],
            [-2.127522231152357, 52.405027944645944],
            [-2.128501192685532, 52.406037367251436],
            [-2.13404109742666, 52.4102862061794],
            [-2.14450144907132, 52.411389529584703],
            [-2.144260234663378, 52.410005353704634],
            [-2.145740581364534, 52.409553132349629],
            [-2.154470633712375, 52.409784761866213],
            [-2.158268924578773, 52.409269992933446],
            [-2.160326664000953, 52.416462867699622],
            [-2.162020782728427, 52.416154892569466],
            [-2.162580431550253, 52.415612022227151],
            [-2.163246943495569, 52.416139718280021],
            [-2.163957826422778, 52.417129438202849],
            [-2.163933186469546, 52.419204378700982],
            [-2.164675459095311, 52.42149581061787],
            [-2.167711767598083, 52.42376602056197],
            [-2.163815846019509, 52.423243774271832],
            [-2.164175602154011, 52.423894154435381],
            [-2.164065161002937, 52.427808575788703],
            [-2.16485645636654, 52.430189835608132],
            [-2.146607882967476, 52.42635897594932],
            [-2.143550907437792, 52.426045384454767],
            [-2.136032307799542, 52.426253868067228],
            [-2.135484860577405, 52.42804621375727],
            [-2.133096613480256, 52.427603923936182],
            [-2.132279976868081, 52.429916181941785],
            [-2.130465670367751, 52.429617934102325],
            [-2.129275164508514, 52.429886248348716],
            [-2.129755587283448, 52.430712804793295],
            [-2.129026643815738, 52.430903293787054],
            [-2.129702590689924, 52.432196219321526],
            [-2.127788017410066, 52.432344845462197],
            [-2.127765487279167, 52.432690986249675],
            [-2.122887255291753, 52.433406379461516],
            [-2.119668132337544, 52.434705151568089],
            [-2.11752443349174, 52.433929668221175],
            [-2.117253427163425, 52.434345277342132],
            [-2.114648924945694, 52.435645997212134],
            [-2.11290301109142, 52.437400736348437],
            [-2.112466506581715, 52.437549488668608],
            [-2.111762645276999, 52.437263375599258],
            [-2.108783340791221, 52.441208285817893],
            [-2.107030686864295, 52.442191599005916],
            [-2.105255378402889, 52.444193475202255],
            [-2.102246168960168, 52.443947098105859],
            [-2.098571106989658, 52.445269061451455],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000235",
        LAD13CDO: "47UC",
        LAD13NM: "Malvern Hills",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.414926483101428, 52.368241033410357],
            [-2.415598618396604, 52.367559012043408],
            [-2.414928014964902, 52.367307846365108],
            [-2.415072969725897, 52.366477542447306],
            [-2.416158293524356, 52.364602864233504],
            [-2.41499896879562, 52.362814298329198],
            [-2.413355959578611, 52.36127014982192],
            [-2.413316881101264, 52.360702105986199],
            [-2.412698507395436, 52.360685388954465],
            [-2.412587889270224, 52.360160747755053],
            [-2.411970698951309, 52.360112557089749],
            [-2.412254468685145, 52.359993795295182],
            [-2.411792186482683, 52.359549494050817],
            [-2.412077558898365, 52.358972226791415],
            [-2.411684724702595, 52.358889088869645],
            [-2.411756542207582, 52.357769558245636],
            [-2.413998586327768, 52.355399993133716],
            [-2.414021932061878, 52.354756212363768],
            [-2.417583764064852, 52.352093357555994],
            [-2.419528196803484, 52.351379835326071],
            [-2.422071965404769, 52.349014435305811],
            [-2.422082950990433, 52.346932260701003],
            [-2.420964686183729, 52.345578728428762],
            [-2.420523905232949, 52.344145458804867],
            [-2.420728958189339, 52.343634083502209],
            [-2.421528878835724, 52.343317471871828],
            [-2.420695934587147, 52.34232072871297],
            [-2.422727353990287, 52.338621185381903],
            [-2.424952847337843, 52.336676700118247],
            [-2.423672117237354, 52.336118513223383],
            [-2.423298288671611, 52.335240608363719],
            [-2.421191235126225, 52.333587645265027],
            [-2.420995318027302, 52.333047131275805],
            [-2.426100084432055, 52.33235455201249],
            [-2.428566891129529, 52.3305250990947],
            [-2.43307165232938, 52.329891931588513],
            [-2.433657508864326, 52.328578103370589],
            [-2.433398848375544, 52.327486737043188],
            [-2.434907734931301, 52.326635205718311],
            [-2.436513585720648, 52.324014013089013],
            [-2.434087898693324, 52.322630364372564],
            [-2.431469504896583, 52.32283684881191],
            [-2.428477972258358, 52.32239013883266],
            [-2.427168607138483, 52.32186715496583],
            [-2.424077610892275, 52.322667642772586],
            [-2.419946067860748, 52.32282985058383],
            [-2.419615731399096, 52.323116915318082],
            [-2.41815378113091, 52.322896446621556],
            [-2.417484488490643, 52.322402552852232],
            [-2.415572070038569, 52.322486615966135],
            [-2.415090995684828, 52.323914163115532],
            [-2.414225536591875, 52.32456450105888],
            [-2.414159856515694, 52.325072680859996],
            [-2.413366759363957, 52.325462041136603],
            [-2.412238771979464, 52.324869032081473],
            [-2.40341573273508, 52.324021161022422],
            [-2.403013277026206, 52.326716913615464],
            [-2.397791254160109, 52.324939270415157],
            [-2.394556434932227, 52.32186377176],
            [-2.389540354497548, 52.321213352076647],
            [-2.387577181398024, 52.322372363601396],
            [-2.385242584118369, 52.322673985899876],
            [-2.381065767827077, 52.324241955258202],
            [-2.379443655743087, 52.324147388080512],
            [-2.375844579682887, 52.325192778697534],
            [-2.374800555343272, 52.325099003082073],
            [-2.371750395110454, 52.326533600173562],
            [-2.369820103999891, 52.326793187234536],
            [-2.36912694488373, 52.326547225862896],
            [-2.370208029321921, 52.325629533330734],
            [-2.370350469303266, 52.323711467749213],
            [-2.371327526397818, 52.322997270263699],
            [-2.371360926078453, 52.322255469877888],
            [-2.372344377264622, 52.321609569518223],
            [-2.371974188190228, 52.320854656582171],
            [-2.37224025532158, 52.320217308259402],
            [-2.373201386766213, 52.3197153148664],
            [-2.371241894255911, 52.31732738880914],
            [-2.369250835231606, 52.317288682133935],
            [-2.366840814239636, 52.318031598517585],
            [-2.36582412307601, 52.317849554285402],
            [-2.36328641972608, 52.314837564737616],
            [-2.359459098267109, 52.315368018918882],
            [-2.358197431829498, 52.316264586200298],
            [-2.352825586778233, 52.318504951926307],
            [-2.352130094220333, 52.319229844465816],
            [-2.34735492998687, 52.32018706853237],
            [-2.345489511072741, 52.320470341255088],
            [-2.339695206521664, 52.320000785036534],
            [-2.33646927319157, 52.31867677101144],
            [-2.33204446583633, 52.319242200338451],
            [-2.331261439857657, 52.31988360646799],
            [-2.329047173080117, 52.320222438369974],
            [-2.328407364233581, 52.320598214058201],
            [-2.324174838448376, 52.323221577005363],
            [-2.323892753156796, 52.323771654601344],
            [-2.325692332088419, 52.324274651446295],
            [-2.324664021501737, 52.325306865619261],
            [-2.324478705111489, 52.326649616485199],
            [-2.323332431500657, 52.327413334938875],
            [-2.321965838391836, 52.330172574057435],
            [-2.319684867208848, 52.331516517889497],
            [-2.32196089815292, 52.332118071479762],
            [-2.323408462813831, 52.333407807379743],
            [-2.32445541956179, 52.333689924605679],
            [-2.323881405365231, 52.335268384809517],
            [-2.320881817407415, 52.336916390740996],
            [-2.318220242569978, 52.337430642729302],
            [-2.315682022658803, 52.338581011694565],
            [-2.314402136121418, 52.33981608549837],
            [-2.312710842900561, 52.339961723614223],
            [-2.310935952341508, 52.340536390491273],
            [-2.310126270688147, 52.340402768074554],
            [-2.309089557138115, 52.339705149824397],
            [-2.306415997251754, 52.339167312721244],
            [-2.305267403555685, 52.337769614062573],
            [-2.304829403978181, 52.335758736984886],
            [-2.307084709603909, 52.336120591617195],
            [-2.308646281026564, 52.335279530625868],
            [-2.305334296854522, 52.335124521328105],
            [-2.30615407065723, 52.333549110890687],
            [-2.303880411430915, 52.332844758542812],
            [-2.305290748327451, 52.331999635474553],
            [-2.305255502242611, 52.331143857932638],
            [-2.304674410068718, 52.330934088991341],
            [-2.304529164308778, 52.329230817189696],
            [-2.302711405891576, 52.32842367559131],
            [-2.303296106696659, 52.328094930676187],
            [-2.299167745189875, 52.322346327057303],
            [-2.296579470464772, 52.323197041231651],
            [-2.294582764955983, 52.321896660028095],
            [-2.290278750241902, 52.32311381184352],
            [-2.29136438130578, 52.32466105200043],
            [-2.287069680882461, 52.325785463699638],
            [-2.279870750658605, 52.326882477870143],
            [-2.277914288225292, 52.327735777204282],
            [-2.276918712953195, 52.327387498600316],
            [-2.275535935382215, 52.328008362541645],
            [-2.27565773574502, 52.328717406215652],
            [-2.273893712556939, 52.328918398422907],
            [-2.271941751331162, 52.326952256322073],
            [-2.266419066145366, 52.323591721051734],
            [-2.265153333932844, 52.320973921218624],
            [-2.265291176025271, 52.319492022089065],
            [-2.273843228059952, 52.311251817393959],
            [-2.2748938799695, 52.30871682299918],
            [-2.274986594263921, 52.304982956581185],
            [-2.276050681717082, 52.301342116099754],
            [-2.275715487524354, 52.297905024239917],
            [-2.274821253073686, 52.295578631771981],
            [-2.274903331915873, 52.292733919655525],
            [-2.271033191247724, 52.28611524210649],
            [-2.270393074230363, 52.282831661631043],
            [-2.270930661924943, 52.280127049054165],
            [-2.274412383604147, 52.275702071284243],
            [-2.275115207518592, 52.272754313484846],
            [-2.274815856670776, 52.271508955320868],
            [-2.271526283181227, 52.269668166033561],
            [-2.267169929045442, 52.268271129719402],
            [-2.26515653213461, 52.268033833250257],
            [-2.26049352819453, 52.268256393666185],
            [-2.256129373063476, 52.267962976925446],
            [-2.251897759526787, 52.266742215467822],
            [-2.248141339766841, 52.26398475889237],
            [-2.244109934570127, 52.260160601068392],
            [-2.237922390309299, 52.257089566600989],
            [-2.238135585802862, 52.256139758601009],
            [-2.237333015523375, 52.255637019402627],
            [-2.236388909183378, 52.25386062982205],
            [-2.235618519326804, 52.252785130653443],
            [-2.234917829923648, 52.252694827155835],
            [-2.233882935103991, 52.251172124814495],
            [-2.232077282367094, 52.250141804332863],
            [-2.231324825054334, 52.248820805298656],
            [-2.231629676769844, 52.247732376851609],
            [-2.233375440002004, 52.245747465388398],
            [-2.235753799301654, 52.241564025152776],
            [-2.235356230731272, 52.24005174457033],
            [-2.233633233246264, 52.238507932510053],
            [-2.232642926522117, 52.237039069654351],
            [-2.233021158016083, 52.236561834321911],
            [-2.236001008673515, 52.235165999774772],
            [-2.23959172719013, 52.234399073714052],
            [-2.240834679097658, 52.233278141526284],
            [-2.240646376646013, 52.232574581092607],
            [-2.241800456422703, 52.232087639905252],
            [-2.242216912358944, 52.230052266902234],
            [-2.241743705918405, 52.227305783167367],
            [-2.240796423648065, 52.22540446101425],
            [-2.243275937570488, 52.221840982461387],
            [-2.243990315459253, 52.21997580157462],
            [-2.243541738793293, 52.217450432718358],
            [-2.237752873669127, 52.214188850568725],
            [-2.234890195402205, 52.211443530846608],
            [-2.238222701307915, 52.210637598305119],
            [-2.242295198507953, 52.208951691588716],
            [-2.242548078092246, 52.209166041976005],
            [-2.243026923249453, 52.208952882558414],
            [-2.245677051677652, 52.211310064641928],
            [-2.247986420609799, 52.210512277153704],
            [-2.248498128707212, 52.210944536555076],
            [-2.24886076129099, 52.210626410837541],
            [-2.248464503488041, 52.209902621017363],
            [-2.247759720678852, 52.20843507393846],
            [-2.248787942066516, 52.207287532164599],
            [-2.249439463710186, 52.2070829724898],
            [-2.248914615855844, 52.206649845619282],
            [-2.249189026268159, 52.20600195724527],
            [-2.249792631500642, 52.205867621943611],
            [-2.248803336443471, 52.204282908351793],
            [-2.247251235367072, 52.204099173493361],
            [-2.247026456457219, 52.203146662110562],
            [-2.24757049321646, 52.2025683369666],
            [-2.247099880689348, 52.201876164193109],
            [-2.250914182982675, 52.199751765604844],
            [-2.250248475560854, 52.197412976883186],
            [-2.251421825361525, 52.196631910436345],
            [-2.252945624133123, 52.196254649381821],
            [-2.255006442481932, 52.196154011958697],
            [-2.257081178624324, 52.196691627633598],
            [-2.26053621628016, 52.196330719052021],
            [-2.260022465755367, 52.195039031488115],
            [-2.260542070849283, 52.193831370088191],
            [-2.260441145830071, 52.192083855344109],
            [-2.257361925370288, 52.192109498121766],
            [-2.258103316920755, 52.191302335328324],
            [-2.259476502169997, 52.191478230092748],
            [-2.259286381655885, 52.191235907013528],
            [-2.259949790272476, 52.191111277130865],
            [-2.260018980449527, 52.190684978450399],
            [-2.261239039355301, 52.190693968490542],
            [-2.261840822103411, 52.189793590823449],
            [-2.262333136051449, 52.185461806551942],
            [-2.262947938836768, 52.18529591177991],
            [-2.263203000710504, 52.184648031332422],
            [-2.261455350559758, 52.184184418165074],
            [-2.260861544938895, 52.182439791232305],
            [-2.261882404963715, 52.18194035636224],
            [-2.2573216564923, 52.182610299163805],
            [-2.254096609363251, 52.183622426266545],
            [-2.252611272410978, 52.183482671031676],
            [-2.253809736206335, 52.182047021100516],
            [-2.253305356275586, 52.181310890324497],
            [-2.252745926925747, 52.181179931832801],
            [-2.252977800815578, 52.180547406609506],
            [-2.251254594123961, 52.180739891779268],
            [-2.251004024898851, 52.181173764734311],
            [-2.250333049523902, 52.180971108803284],
            [-2.249514754947282, 52.180315642956373],
            [-2.248998629975741, 52.178508757099131],
            [-2.246580334663934, 52.177027721322872],
            [-2.247066286289, 52.176052139562316],
            [-2.246332467371398, 52.175310165819461],
            [-2.246893844254263, 52.17448996152801],
            [-2.24638208848828, 52.174234804752508],
            [-2.247064924851041, 52.173960964730028],
            [-2.246344866714815, 52.173585772263095],
            [-2.246573505246805, 52.173151954529573],
            [-2.245567932187029, 52.172970648938559],
            [-2.245412875681471, 52.171898411304873],
            [-2.244300593212568, 52.171450300679574],
            [-2.245277451436372, 52.171203731386434],
            [-2.247314783251371, 52.169711553693219],
            [-2.246020712588847, 52.169169440725376],
            [-2.244184138170366, 52.168877474462668],
            [-2.243478644044421, 52.169007497644031],
            [-2.242374082295911, 52.170230678080259],
            [-2.238953979216791, 52.169121947272757],
            [-2.23694861059251, 52.169523374477833],
            [-2.235403227127386, 52.169265748489295],
            [-2.23276482013075, 52.169688146369474],
            [-2.231670310642623, 52.168967470832023],
            [-2.230073496789663, 52.168650539153255],
            [-2.228595540351357, 52.167557479204994],
            [-2.228376770872719, 52.164525422417292],
            [-2.227628176581493, 52.163654796333162],
            [-2.226582354200319, 52.163838419270732],
            [-2.225759896399428, 52.164827150274263],
            [-2.22407619614206, 52.16551004264165],
            [-2.225333723507835, 52.166416579707636],
            [-2.227366802004167, 52.166581694957607],
            [-2.227329372876726, 52.166982741473127],
            [-2.22573553947632, 52.16809972271885],
            [-2.222003893603531, 52.168317188802696],
            [-2.220481409281204, 52.167342785166952],
            [-2.219683901005958, 52.16542571168609],
            [-2.217773310918939, 52.163084546963361],
            [-2.217605660004001, 52.161983525323542],
            [-2.216354053222343, 52.161636101259433],
            [-2.214322157017869, 52.161661391904246],
            [-2.211039931572139, 52.162525006523168],
            [-2.211684224209947, 52.163361761480843],
            [-2.21155948029361, 52.164184612390763],
            [-2.207417468591081, 52.1644428105879],
            [-2.206447983426956, 52.165347155004525],
            [-2.205329360517821, 52.165623318601106],
            [-2.204694125239714, 52.166420978066405],
            [-2.203412418255025, 52.166851143350137],
            [-2.203376477732225, 52.167305223182538],
            [-2.20224367302015, 52.167371005319119],
            [-2.201694289478114, 52.167126507769105],
            [-2.199713725812769, 52.164635930083406],
            [-2.197243327166218, 52.162403266442553],
            [-2.196289648383162, 52.158636058280827],
            [-2.196872218263243, 52.155834556175776],
            [-2.19596802303059, 52.154972079791158],
            [-2.194614427700811, 52.151268451424734],
            [-2.189580423346646, 52.146008240788028],
            [-2.186034341373669, 52.145467273631077],
            [-2.180135281002058, 52.14557174247706],
            [-2.16946880896504, 52.144794613464427],
            [-2.166696711976031, 52.145588833182806],
            [-2.167885382069166, 52.144192721055902],
            [-2.164926985025518, 52.142817761402156],
            [-2.167749140908395, 52.141772676983422],
            [-2.16923870971653, 52.140769005530203],
            [-2.171744800771928, 52.140424637878048],
            [-2.172435001045836, 52.137914383504203],
            [-2.173555895685384, 52.136846464438698],
            [-2.172844501956424, 52.134999963973321],
            [-2.17448263342181, 52.135086554127383],
            [-2.177270421767409, 52.134395516645846],
            [-2.182532701803372, 52.132202786376553],
            [-2.18571263149143, 52.130330487141741],
            [-2.188095386020891, 52.130006643614401],
            [-2.19061904648857, 52.130530328664861],
            [-2.192154443896293, 52.130197882004126],
            [-2.193732347609989, 52.129230615579075],
            [-2.19530207570454, 52.126077748090147],
            [-2.196411082836998, 52.124823525180823],
            [-2.195430557586172, 52.1247298568993],
            [-2.190991419281937, 52.122989383461125],
            [-2.187631357759681, 52.122142483898379],
            [-2.183935816189963, 52.119956425582913],
            [-2.185820804150105, 52.117722018354819],
            [-2.184616919902487, 52.117253707528477],
            [-2.183057890886587, 52.116002862196517],
            [-2.182705860201434, 52.115270680962013],
            [-2.181502277944385, 52.115211406768402],
            [-2.180933785402352, 52.113308986641471],
            [-2.17642528910079, 52.112432059240646],
            [-2.176627346640565, 52.110723555045837],
            [-2.175922225532797, 52.110765068137617],
            [-2.173340682165199, 52.109292651344767],
            [-2.171843330416491, 52.108737433151816],
            [-2.169276323326999, 52.108336577904147],
            [-2.162379249823423, 52.109310078562309],
            [-2.159680414321055, 52.108778833479512],
            [-2.158764535221325, 52.107855845189292],
            [-2.156813798858437, 52.107436803854306],
            [-2.155233461764808, 52.107713109908239],
            [-2.151852379496197, 52.107446006081808],
            [-2.151712741180717, 52.105884529746731],
            [-2.148760447650562, 52.103239684802546],
            [-2.148801008311064, 52.09964071819752],
            [-2.149556039012873, 52.099726070456491],
            [-2.151206667736836, 52.096139428739029],
            [-2.151735275304116, 52.09490434525415],
            [-2.151658696173206, 52.093416506794874],
            [-2.155347130800239, 52.093386522578513],
            [-2.154829785856932, 52.090258489129646],
            [-2.156249778163057, 52.088964668419571],
            [-2.157112066131014, 52.085565984832321],
            [-2.158325489741122, 52.084518755243003],
            [-2.156797637509788, 52.082468252716261],
            [-2.155818156815762, 52.076526780387645],
            [-2.149763675906597, 52.076761204290939],
            [-2.149985318340232, 52.075415031501734],
            [-2.151298795311245, 52.07421670293084],
            [-2.151042582795875, 52.073523857753969],
            [-2.153362189346006, 52.07133794210619],
            [-2.153164418909849, 52.069823284741275],
            [-2.155043503615175, 52.063528303320091],
            [-2.158135013170608, 52.061216300535868],
            [-2.161364701394312, 52.060503455825568],
            [-2.165566767422973, 52.060870728761543],
            [-2.169959663326224, 52.059236266460765],
            [-2.175399132004088, 52.058641197759897],
            [-2.173511931360034, 52.055289596460298],
            [-2.169741322659395, 52.05550997157566],
            [-2.170067656891854, 52.05004859637733],
            [-2.17107383246052, 52.049665036196387],
            [-2.170791595835254, 52.049073862889664],
            [-2.171388047962505, 52.047563468980272],
            [-2.174114232590305, 52.04704250532636],
            [-2.175511802902071, 52.046470422984797],
            [-2.176987814778991, 52.045463958411126],
            [-2.178171980689993, 52.043682924521072],
            [-2.180416111538717, 52.042291350564085],
            [-2.180686440573075, 52.041718232001315],
            [-2.180556266026465, 52.035472630497239],
            [-2.179770952542587, 52.034851679937823],
            [-2.179164927638988, 52.033494116786841],
            [-2.1794036070631, 52.031943764312352],
            [-2.178109757630179, 52.029149635590166],
            [-2.178568022958852, 52.028567245255744],
            [-2.177599184315134, 52.027204828371978],
            [-2.177578720245019, 52.026455037963352],
            [-2.178320160181745, 52.025619582876004],
            [-2.177134429321888, 52.023936523249468],
            [-2.176913255612346, 52.022549595901886],
            [-2.183059060811784, 52.019743195552856],
            [-2.187690305884306, 52.019070588457197],
            [-2.184072572659564, 52.013891379250524],
            [-2.172141634607495, 52.008451197156532],
            [-2.16473079704679, 52.004185835845924],
            [-2.163556979026317, 52.001480374788898],
            [-2.163859420760587, 51.997348725510747],
            [-2.164304708647985, 51.996442740011311],
            [-2.166434209954789, 51.995294329320039],
            [-2.170272482813461, 51.994660384273416],
            [-2.171744131693091, 51.994829068003718],
            [-2.174914067211402, 51.996131645800027],
            [-2.176035447745716, 51.99721515139921],
            [-2.176632497391459, 51.997183687901376],
            [-2.1794605907777, 51.999249067737701],
            [-2.181113269682624, 51.999473999500132],
            [-2.181509413590275, 51.999102072730572],
            [-2.179852784960415, 51.996801198123912],
            [-2.17943273461311, 51.99483826840676],
            [-2.18170881004305, 51.991878627141197],
            [-2.183699760960869, 51.990842500994098],
            [-2.18509079704642, 51.990551718159701],
            [-2.190371219210047, 51.991547553798746],
            [-2.193666236121641, 51.993414042377765],
            [-2.194421036844231, 51.993167350706614],
            [-2.195208292256401, 51.994355518629497],
            [-2.197104485277503, 51.994337971568505],
            [-2.197207889111997, 51.994999514876497],
            [-2.198419244415355, 51.995564791958863],
            [-2.200469211137389, 51.995724947432223],
            [-2.200835003659594, 51.994798279932695],
            [-2.203052692153271, 51.99533661308854],
            [-2.202892715674549, 51.996353738834941],
            [-2.205649988927787, 51.996382216829637],
            [-2.205602934548078, 51.995332183663749],
            [-2.211695690644069, 51.996646609205385],
            [-2.213442446994716, 51.996732461221512],
            [-2.215681155988431, 51.99583649952018],
            [-2.214452598018084, 51.995375723493936],
            [-2.216957207875169, 51.994067477386977],
            [-2.220619809333197, 51.995487493167673],
            [-2.223308537534961, 51.992874213007752],
            [-2.224436727232166, 51.992773168317704],
            [-2.226404395978868, 51.990774356799946],
            [-2.228988223647534, 51.990838584829795],
            [-2.227811887408271, 51.988617461736347],
            [-2.233176921377307, 51.986701815148002],
            [-2.23404969086002, 51.985963741124735],
            [-2.237638563995771, 51.980911843890979],
            [-2.243316332472878, 51.975162343663165],
            [-2.243588958503849, 51.974697856699308],
            [-2.242866753994571, 51.974135629658711],
            [-2.243975128368218, 51.97289441037131],
            [-2.246242723281929, 51.971488026929322],
            [-2.248911776638402, 51.968216066682565],
            [-2.251115604317253, 51.968749929765735],
            [-2.251828764979148, 51.967697385062756],
            [-2.251365328297282, 51.966556549034337],
            [-2.25650498084173, 51.967097487889262],
            [-2.258696759043081, 51.96780745450517],
            [-2.263624058170406, 51.967345180006134],
            [-2.267770919765578, 51.968074855710022],
            [-2.267637482687055, 51.968398826921238],
            [-2.270560600064879, 51.968930687816339],
            [-2.273651027257372, 51.969436912996116],
            [-2.276974460097054, 51.96947678714367],
            [-2.280566465469335, 51.970565149729005],
            [-2.288295169892018, 51.970659749813358],
            [-2.293307740200973, 51.969656578515185],
            [-2.297246095705703, 51.967590505684768],
            [-2.300437001832662, 51.966766938033864],
            [-2.303707216206609, 51.96864750394753],
            [-2.305791910351788, 51.970825063800234],
            [-2.309469097898112, 51.970638331918231],
            [-2.309051564902701, 51.972153474837484],
            [-2.307930804007622, 51.972810041915054],
            [-2.309267917567374, 51.973764051817597],
            [-2.308628144286098, 51.974086701885717],
            [-2.308780449166528, 51.97442974953352],
            [-2.312623561303626, 51.976496468533611],
            [-2.323699137218672, 51.975612400745284],
            [-2.326568492978963, 51.975802269772444],
            [-2.326885726906809, 51.976383091529932],
            [-2.325328381241285, 51.976640946774154],
            [-2.324688369316254, 51.978932661636186],
            [-2.324120925608254, 51.978782282025414],
            [-2.323307582635906, 51.980471185861589],
            [-2.322600115306483, 51.980895692982081],
            [-2.322452889035708, 51.982492854781903],
            [-2.322925679468006, 51.984456937119226],
            [-2.32233283228474, 51.986454508972976],
            [-2.322332269663971, 51.988808288100607],
            [-2.321811106996757, 51.989441763671898],
            [-2.322039070940882, 51.98975401800179],
            [-2.32116898586757, 51.990472058758058],
            [-2.320660862530135, 51.992119648981998],
            [-2.32325985000496, 51.996277947782602],
            [-2.323498052082208, 51.99739753867572],
            [-2.322830015934475, 51.997468601525846],
            [-2.323517215763618, 51.999448271699322],
            [-2.325660839867902, 51.999581716480144],
            [-2.325797111368669, 52.000695290644252],
            [-2.32471371205145, 52.003558234204021],
            [-2.326112605893375, 52.005620434688616],
            [-2.329426891598179, 52.006819558593875],
            [-2.333048905331565, 52.007254406917291],
            [-2.333812398198491, 52.007659523834924],
            [-2.336340934932597, 52.007776398149083],
            [-2.340154284041095, 52.006719819769792],
            [-2.340880995119323, 52.006878651486502],
            [-2.3412880877407, 52.008301602060762],
            [-2.341906376813866, 52.00875833454343],
            [-2.343057753112324, 52.009194630901042],
            [-2.344004335561537, 52.008761213060474],
            [-2.345690318816352, 52.010317961868445],
            [-2.347577250738448, 52.011280705871577],
            [-2.349274491348975, 52.011449203252454],
            [-2.351065339616418, 52.013150314079219],
            [-2.352545738057833, 52.013526197256269],
            [-2.35185889975172, 52.015681527729505],
            [-2.350470635645403, 52.016475053112941],
            [-2.349379658830581, 52.017838589996174],
            [-2.350982717100497, 52.020089589699502],
            [-2.351381689505897, 52.021349795203392],
            [-2.35082641678791, 52.022461802728117],
            [-2.352053346258316, 52.024853262356864],
            [-2.35065185643066, 52.029951571129686],
            [-2.350270733685395, 52.03079513301666],
            [-2.348166126709881, 52.031944990500705],
            [-2.347536520205642, 52.033272976443911],
            [-2.347987714405896, 52.035810612110161],
            [-2.349921798548333, 52.036507052239607],
            [-2.348517555185793, 52.037956917869991],
            [-2.348240739216884, 52.041166508187068],
            [-2.348955983618311, 52.048009883671853],
            [-2.348598378716522, 52.048901018143169],
            [-2.347225608817575, 52.049387872251387],
            [-2.346954101584963, 52.049916424102506],
            [-2.347396643562576, 52.053012400037552],
            [-2.350085439428808, 52.05370930472219],
            [-2.35017478692522, 52.05469081821019],
            [-2.351524379234941, 52.05643547884732],
            [-2.350857836266059, 52.057361705067244],
            [-2.349690580871431, 52.057861460437913],
            [-2.347458910351704, 52.061596485124205],
            [-2.344380467344831, 52.061119133527448],
            [-2.343217524166178, 52.062583507086494],
            [-2.341467236005855, 52.063933593918236],
            [-2.339429299048137, 52.068914000682426],
            [-2.339264589666458, 52.06970205406401],
            [-2.339915132570242, 52.071606190620614],
            [-2.339198986063807, 52.074517619860785],
            [-2.340054197008052, 52.075888920152522],
            [-2.34050766130378, 52.078530850599137],
            [-2.338477090342768, 52.080537113862647],
            [-2.33873293846097, 52.082526898761699],
            [-2.338092741936389, 52.086444145302302],
            [-2.338508016798592, 52.087898530525621],
            [-2.337966895378367, 52.089874419036732],
            [-2.338505762739538, 52.092419007497433],
            [-2.3383023779527, 52.09543863114753],
            [-2.340374542289256, 52.096504340070346],
            [-2.34078408054282, 52.096418644608825],
            [-2.34046264869835, 52.095997915553042],
            [-2.341109878858376, 52.095689464311427],
            [-2.342991747720881, 52.095345055227469],
            [-2.349121918616532, 52.095635425194821],
            [-2.349589527355156, 52.099430762545559],
            [-2.350441461197306, 52.100096231723263],
            [-2.35150410410322, 52.10193343780405],
            [-2.351389801263211, 52.102621557995832],
            [-2.351818351579186, 52.102720973529813],
            [-2.352151327369715, 52.103475187216276],
            [-2.350606664450268, 52.103661403648523],
            [-2.350133042031174, 52.104145606017148],
            [-2.349993413553474, 52.106077196058585],
            [-2.351591616377372, 52.109714517372218],
            [-2.35366155121018, 52.112956613506107],
            [-2.353803174291808, 52.113506410728597],
            [-2.352673564930083, 52.113591611288889],
            [-2.352753716013437, 52.115049640193853],
            [-2.354571109233922, 52.120104069987441],
            [-2.35560587760521, 52.12184421954997],
            [-2.355396463211618, 52.123059474386167],
            [-2.356207042214957, 52.124149378357018],
            [-2.356093049136726, 52.127071649527906],
            [-2.355188500837596, 52.128497581849729],
            [-2.354001296515085, 52.128735807197891],
            [-2.35277386514014, 52.128506633024287],
            [-2.350539201475101, 52.129111172173729],
            [-2.344611670907292, 52.13258012072771],
            [-2.348366212894459, 52.136689440738081],
            [-2.348550047562047, 52.138152554443153],
            [-2.349568468826962, 52.13964735899291],
            [-2.349688845673843, 52.141210453200898],
            [-2.350175788211466, 52.140883550638698],
            [-2.351210544427939, 52.141839759939742],
            [-2.351837844457481, 52.142820552457657],
            [-2.35177481136038, 52.14446330768876],
            [-2.351404811963663, 52.144612754901637],
            [-2.356456668499814, 52.148458110226827],
            [-2.358724638666975, 52.151884704013575],
            [-2.362527370057902, 52.151132251920465],
            [-2.365232998929466, 52.150991738910115],
            [-2.366529311024955, 52.151516356867731],
            [-2.369697015871763, 52.152107030041911],
            [-2.371586634584796, 52.152083107307924],
            [-2.372674155709794, 52.152620902484912],
            [-2.375424973621553, 52.152806368325294],
            [-2.376932681938059, 52.153592721313771],
            [-2.3779296272121, 52.154641418304919],
            [-2.379323565501362, 52.155099951929884],
            [-2.381293631669526, 52.154213425488678],
            [-2.384491058299544, 52.153798466787421],
            [-2.386363228955036, 52.152596607661181],
            [-2.38805819456628, 52.152037224481752],
            [-2.388628844126816, 52.150600458709974],
            [-2.389888692086469, 52.150936139754393],
            [-2.390425583264657, 52.149992156819778],
            [-2.391745361355878, 52.149164246826302],
            [-2.392383710958113, 52.147632836518476],
            [-2.393974608609007, 52.14673746841909],
            [-2.395341572523654, 52.146964847032635],
            [-2.396960164770814, 52.147877339385623],
            [-2.400191788812645, 52.148245812087197],
            [-2.401859232687829, 52.1480756127307],
            [-2.406798357710789, 52.145930613931831],
            [-2.409648312685004, 52.145960302264875],
            [-2.41239750891411, 52.145365432640226],
            [-2.415086316137014, 52.145198660637625],
            [-2.417674425042242, 52.147545935784272],
            [-2.421319696317354, 52.149607077458697],
            [-2.422312888821952, 52.151393536587108],
            [-2.424322862132586, 52.153118792109737],
            [-2.42510140658856, 52.154293745151037],
            [-2.423220270449189, 52.155526820027248],
            [-2.424201357943117, 52.156018671194317],
            [-2.424920831422497, 52.157125509555136],
            [-2.42699515823612, 52.1583587054063],
            [-2.427281935440386, 52.158996891753212],
            [-2.427269405502555, 52.1608930356984],
            [-2.4262933852236, 52.160946016745868],
            [-2.426466464080675, 52.161465042125471],
            [-2.427848900248195, 52.162478657676026],
            [-2.427554059890987, 52.162527376982574],
            [-2.428170088148025, 52.163346873521348],
            [-2.429094897740444, 52.163588052953095],
            [-2.431075132971071, 52.165177542646489],
            [-2.431799271003027, 52.165223441461478],
            [-2.431722425711881, 52.166044555755995],
            [-2.432886165973784, 52.167245015830829],
            [-2.433541265786604, 52.167254296396543],
            [-2.435177834567582, 52.168204856359608],
            [-2.434623314197672, 52.169815301914312],
            [-2.435425835419934, 52.170991894011358],
            [-2.432998351002917, 52.172021254807007],
            [-2.432058093027967, 52.172167652892917],
            [-2.430665951445418, 52.171855381686903],
            [-2.429069335839003, 52.172325112108545],
            [-2.42801899439155, 52.172125744605566],
            [-2.426294390224303, 52.172518583730771],
            [-2.422013278177966, 52.17240092539781],
            [-2.422350319786688, 52.173557693482458],
            [-2.422903708712741, 52.173625835701799],
            [-2.422800144375637, 52.174732037003622],
            [-2.423562336118495, 52.174919899732245],
            [-2.42383031583773, 52.175728080447691],
            [-2.424459479722208, 52.176225688145564],
            [-2.424991335070501, 52.17617881990995],
            [-2.425069659875468, 52.176877997221347],
            [-2.425464256711591, 52.177005137941237],
            [-2.423248036192747, 52.179857702100385],
            [-2.420305085407486, 52.180432831053345],
            [-2.418957873697398, 52.181651339703478],
            [-2.416651792449057, 52.182158482108619],
            [-2.41507069320436, 52.183250107321818],
            [-2.416112632038829, 52.185190174557761],
            [-2.418130695991313, 52.185925648539339],
            [-2.417898265153401, 52.186563896607325],
            [-2.419148853321134, 52.187960172561283],
            [-2.417695559920111, 52.189437068159911],
            [-2.415988893863006, 52.189639093098968],
            [-2.414638111699816, 52.190202158056188],
            [-2.413739716024152, 52.191910803549391],
            [-2.41110303390591, 52.193275785620934],
            [-2.410344752154285, 52.195446024764323],
            [-2.408713050008283, 52.196352534275483],
            [-2.408902524909882, 52.19866422050508],
            [-2.408600155726705, 52.198877443035855],
            [-2.409063801992602, 52.199497075448541],
            [-2.408321702097882, 52.200582096577932],
            [-2.408545467100905, 52.201528015143857],
            [-2.407627248968658, 52.202240538199739],
            [-2.407865656134214, 52.202709014871623],
            [-2.406930683546936, 52.203193233134257],
            [-2.404907352893399, 52.202748877225396],
            [-2.404925221101502, 52.202461121845353],
            [-2.404154479085797, 52.202180566842706],
            [-2.403316424918531, 52.202383023584666],
            [-2.403221544726603, 52.201762108700621],
            [-2.40196944263885, 52.201320457296944],
            [-2.402005258225521, 52.201884035753309],
            [-2.400481908687206, 52.201704914667218],
            [-2.400386189568105, 52.202287820070012],
            [-2.402219000730431, 52.205297874643051],
            [-2.402661468088259, 52.207137605337103],
            [-2.399172286194108, 52.206921109453695],
            [-2.398041927911206, 52.206523057615847],
            [-2.397323950766262, 52.205763090079842],
            [-2.396096878998957, 52.205673717186421],
            [-2.395197399012442, 52.206880553339388],
            [-2.393478871208222, 52.207648687790375],
            [-2.392680953006278, 52.208602534362306],
            [-2.392444871209751, 52.209379193878327],
            [-2.392896562287498, 52.210315391243242],
            [-2.397012381983059, 52.212339737263882],
            [-2.399855037366555, 52.214505816057915],
            [-2.401581303310365, 52.215389103541696],
            [-2.401555331450164, 52.217376973060496],
            [-2.403561545318318, 52.219576373482589],
            [-2.40404355879976, 52.221235252262396],
            [-2.40297093237347, 52.221761264366002],
            [-2.40073053372399, 52.221359833455729],
            [-2.397650386629996, 52.221664243955892],
            [-2.397336999419472, 52.22230631605386],
            [-2.398223178771365, 52.223019862924907],
            [-2.398359170986415, 52.223820448806336],
            [-2.397488560314086, 52.224523738248692],
            [-2.396511207851721, 52.224751787741489],
            [-2.393774897777242, 52.224236813706071],
            [-2.389587999157798, 52.222061553606167],
            [-2.388055170142199, 52.221876009908172],
            [-2.38645816178968, 52.222221090444954],
            [-2.385303887843362, 52.222980959538894],
            [-2.384249052064908, 52.224580196665251],
            [-2.38440246188449, 52.225222510338199],
            [-2.385561590562565, 52.225858843515788],
            [-2.385913586493412, 52.226438472094266],
            [-2.387432268103558, 52.226327407010658],
            [-2.389399875107792, 52.227327852428644],
            [-2.388928458988312, 52.229337861933779],
            [-2.38941408911764, 52.229954798791262],
            [-2.390294479021219, 52.230339375063402],
            [-2.392070252538159, 52.23031280835955],
            [-2.39329000218231, 52.230988421635224],
            [-2.395243493599117, 52.231021450208239],
            [-2.396481716976084, 52.231462319382643],
            [-2.399045865891489, 52.231503120459344],
            [-2.399405981914781, 52.23182106065785],
            [-2.402094571615276, 52.231047743511198],
            [-2.404161431602718, 52.230987636957515],
            [-2.40531319982644, 52.231250699968811],
            [-2.406904791004417, 52.230774123995694],
            [-2.409374973028064, 52.230630721613871],
            [-2.409760600522413, 52.231008777482167],
            [-2.411345899990683, 52.231441092516455],
            [-2.414210886522402, 52.231710668945958],
            [-2.416732337777031, 52.233754295958292],
            [-2.420029410880593, 52.235258383319426],
            [-2.423067741403065, 52.237247888765943],
            [-2.426294569685608, 52.236110675806451],
            [-2.430277126170036, 52.236670703974845],
            [-2.431631048048562, 52.23660821968214],
            [-2.433234233482057, 52.237030286059834],
            [-2.434621586315926, 52.236941572565634],
            [-2.437146785238835, 52.237602027185936],
            [-2.441800837512302, 52.236004178754975],
            [-2.444085770554835, 52.235621599344498],
            [-2.445189993499114, 52.236365438875474],
            [-2.450533374934214, 52.236219288913155],
            [-2.450904350103763, 52.23670155518225],
            [-2.456013769038908, 52.235728934130826],
            [-2.462216686090752, 52.235027817810078],
            [-2.464860105164923, 52.234361132033719],
            [-2.466505091742231, 52.237628944736535],
            [-2.471221727031652, 52.240515898798591],
            [-2.473302926472823, 52.241219608057371],
            [-2.474704345908604, 52.241346136810897],
            [-2.473651553359629, 52.250712063969495],
            [-2.472592805682593, 52.252808371465555],
            [-2.468101645047942, 52.256025050060671],
            [-2.467683221406622, 52.255939502525727],
            [-2.465089644062342, 52.257707371406561],
            [-2.461836065282712, 52.258722576357798],
            [-2.459766829560485, 52.260652797289517],
            [-2.456929894014455, 52.261182560397032],
            [-2.455128348890797, 52.262672033245934],
            [-2.452902306464383, 52.263209230852475],
            [-2.449833760500729, 52.263318962739071],
            [-2.446517163374548, 52.263825129429549],
            [-2.446614814069505, 52.264064802429232],
            [-2.441673806148839, 52.26537531291482],
            [-2.440479993461098, 52.266303084203393],
            [-2.437879429530565, 52.266640910246004],
            [-2.436930151718935, 52.268157504505112],
            [-2.437068534061754, 52.268817782587739],
            [-2.434340157133181, 52.268990581224401],
            [-2.430769591417613, 52.270418748836576],
            [-2.427998645679455, 52.270596950301744],
            [-2.429840152903965, 52.271444339656668],
            [-2.430623714089087, 52.272754971718619],
            [-2.432158197264972, 52.273062226343768],
            [-2.433191467098889, 52.273819020729356],
            [-2.43438753378258, 52.275333092302539],
            [-2.434105982392627, 52.275767462449757],
            [-2.438183727116407, 52.276245962300735],
            [-2.43845143890932, 52.277085564370971],
            [-2.440191698590233, 52.277430608468293],
            [-2.440241344068844, 52.278742112957474],
            [-2.441765101936881, 52.278385796264601],
            [-2.44410313243868, 52.278551434172321],
            [-2.448156395853553, 52.280634456585808],
            [-2.451265373363751, 52.282798270694009],
            [-2.451621801401047, 52.283540407631911],
            [-2.451405738027493, 52.285069589847431],
            [-2.455839251240125, 52.284830489632114],
            [-2.463578673489664, 52.285178839024908],
            [-2.465311752636651, 52.284090482676348],
            [-2.467458471269745, 52.283313326703805],
            [-2.47221442687631, 52.285574343757233],
            [-2.473321761867455, 52.285761402566315],
            [-2.475963442029582, 52.285624016085031],
            [-2.483385106559104, 52.284362179532195],
            [-2.483608134425843, 52.283573712314436],
            [-2.485019139722135, 52.28230567889036],
            [-2.486423031814847, 52.281867466187542],
            [-2.487035654780252, 52.281323721248143],
            [-2.488323049327708, 52.281359757595276],
            [-2.489170092107162, 52.280941795262812],
            [-2.49200936757517, 52.278162764892116],
            [-2.493702995043898, 52.27835887465978],
            [-2.495670249846273, 52.277592742796436],
            [-2.497234640945595, 52.277373990298202],
            [-2.500776990128606, 52.277742002850992],
            [-2.503115734082994, 52.277436274115757],
            [-2.503564620459755, 52.275531101343276],
            [-2.504232927154834, 52.275141662687368],
            [-2.504469964966824, 52.274461877642615],
            [-2.504037962108566, 52.27423446915752],
            [-2.504069841352516, 52.272265443820103],
            [-2.500982708525447, 52.269212874167323],
            [-2.499338745589175, 52.26858332324263],
            [-2.498606983060191, 52.267492289327869],
            [-2.497811685425638, 52.267528011992816],
            [-2.496304329945802, 52.266656001593098],
            [-2.493600801294823, 52.26402147411946],
            [-2.493357800734774, 52.262986799298943],
            [-2.493873951275783, 52.261835670492061],
            [-2.494641204889428, 52.26139552477504],
            [-2.495778969663479, 52.256870387164788],
            [-2.501362550221798, 52.257423080447083],
            [-2.506765161773322, 52.256611546414717],
            [-2.510181140513321, 52.256445788954515],
            [-2.513640500349659, 52.255725935700788],
            [-2.515854699441309, 52.255904176505688],
            [-2.521000576381175, 52.252100208787873],
            [-2.522443342025491, 52.251715334103743],
            [-2.522806981599301, 52.250001950989912],
            [-2.523180147223726, 52.249971527789796],
            [-2.523397072866215, 52.249361016401828],
            [-2.52514125197109, 52.248833621676802],
            [-2.525822281197502, 52.248083486080134],
            [-2.527435042055244, 52.249201887362979],
            [-2.528870145818524, 52.24966296521675],
            [-2.52941868166218, 52.250706089638285],
            [-2.528996736194078, 52.251684339810815],
            [-2.531675802567732, 52.253263600795393],
            [-2.534130566418443, 52.251278222544435],
            [-2.534558617573266, 52.251426424018646],
            [-2.535816134855653, 52.250753632588875],
            [-2.539018694452936, 52.251515818553145],
            [-2.538980497680913, 52.252593942735494],
            [-2.539715668200452, 52.253059881779045],
            [-2.541090574998552, 52.253007734399482],
            [-2.54795221391961, 52.24968468315187],
            [-2.552503956216683, 52.251268248085879],
            [-2.552703438855599, 52.252349759513983],
            [-2.553182449244661, 52.252467087484568],
            [-2.554594426962786, 52.251983968257434],
            [-2.55965195820346, 52.251932220760715],
            [-2.567373667173094, 52.250127831395595],
            [-2.56916519241008, 52.250819557823945],
            [-2.569987485440009, 52.252802471367623],
            [-2.574208221135302, 52.252718177569221],
            [-2.574575309545556, 52.249611998379294],
            [-2.576143270481908, 52.247739739209287],
            [-2.5806574520116, 52.248292101133963],
            [-2.585441214891197, 52.24828464782145],
            [-2.587306101889323, 52.247750339916358],
            [-2.588680814289876, 52.246261866616386],
            [-2.589666136815671, 52.245789444411102],
            [-2.592834592249326, 52.245269205399069],
            [-2.596470751572335, 52.244244851393645],
            [-2.599844882377532, 52.243785417933545],
            [-2.601916631777207, 52.243844993716465],
            [-2.602643915220566, 52.24325870088061],
            [-2.604376265633422, 52.242809309018405],
            [-2.608530922151606, 52.243749035292751],
            [-2.610253916603765, 52.243687992700075],
            [-2.610728039426808, 52.243338508519223],
            [-2.610657662134642, 52.242586372224991],
            [-2.614909950092318, 52.241787514893467],
            [-2.616274372398153, 52.243334845881087],
            [-2.620474168943728, 52.242029899758016],
            [-2.621101238850751, 52.242676610609607],
            [-2.621596875197229, 52.241987130113024],
            [-2.628872807577684, 52.240336588906437],
            [-2.630516700048277, 52.243693843915906],
            [-2.630741076602959, 52.243921002378244],
            [-2.631462494091333, 52.243773296825665],
            [-2.634268020090568, 52.247218676391086],
            [-2.632844979129334, 52.247676738001182],
            [-2.633246947005793, 52.2496749566074],
            [-2.634234797303121, 52.250742211975272],
            [-2.631107609432769, 52.252924775057828],
            [-2.631462074830125, 52.253231251076109],
            [-2.630653576411588, 52.253653629890323],
            [-2.631361355553484, 52.254184776677363],
            [-2.630881646361751, 52.257440082315078],
            [-2.627879065901428, 52.260190984110395],
            [-2.627798898441044, 52.260738927704764],
            [-2.628298492236346, 52.261151626919577],
            [-2.623008103832659, 52.262221662452092],
            [-2.616265588575061, 52.264678181727049],
            [-2.614396863747401, 52.265702942446637],
            [-2.612495652907572, 52.267778821008292],
            [-2.609953455412915, 52.269291586402346],
            [-2.610614886984866, 52.270288798758827],
            [-2.610629303039047, 52.271230918737523],
            [-2.607816496433845, 52.273014748404712],
            [-2.606732829000423, 52.275651813617955],
            [-2.604388401229504, 52.275481336205942],
            [-2.603332074775274, 52.276158322816038],
            [-2.603615772957117, 52.276434675576752],
            [-2.602957486378203, 52.27642005929799],
            [-2.601880637608201, 52.276991949959466],
            [-2.601664030642917, 52.276474308562186],
            [-2.599108338348973, 52.2760728508363],
            [-2.596452038285538, 52.276579875895806],
            [-2.596272646396372, 52.277300912550878],
            [-2.596936633634718, 52.278175018555295],
            [-2.598255993286772, 52.278189915915995],
            [-2.598384375912426, 52.278906698220673],
            [-2.599556320469972, 52.2798348527336],
            [-2.597509449145633, 52.280332510535892],
            [-2.597934067670664, 52.281604298000246],
            [-2.599726803927332, 52.281496309155145],
            [-2.600442003887233, 52.281811832004955],
            [-2.598969989979261, 52.283528382324221],
            [-2.600528129678962, 52.284928359592698],
            [-2.600530844274504, 52.285670951159581],
            [-2.602001602093037, 52.285482753261597],
            [-2.604309692330397, 52.286607344197215],
            [-2.612609243691089, 52.28674707027082],
            [-2.614443452076203, 52.286445349982749],
            [-2.616613389777302, 52.285302145917328],
            [-2.616686237644696, 52.284108740954224],
            [-2.616096646311715, 52.283982357988741],
            [-2.616596426788947, 52.283236243326854],
            [-2.616299138474119, 52.283151488069016],
            [-2.616551020780292, 52.282816629278912],
            [-2.618451227212051, 52.28221512136966],
            [-2.620638267163614, 52.282841960731069],
            [-2.62258663785708, 52.284424798206196],
            [-2.623204116762524, 52.284551900626482],
            [-2.623438335791505, 52.287033809254218],
            [-2.624889881735474, 52.287567355789847],
            [-2.626258674687142, 52.288883487113246],
            [-2.629456724577426, 52.287265290222606],
            [-2.631690934610053, 52.287057365519985],
            [-2.632349835505174, 52.287309164964867],
            [-2.633698715479733, 52.286496392758885],
            [-2.635376970988647, 52.28606392431761],
            [-2.639878945786263, 52.286362362685423],
            [-2.642220774549581, 52.288127945857994],
            [-2.644161076095494, 52.290196852197901],
            [-2.644533322901123, 52.292205970639181],
            [-2.644222715804927, 52.293736930817779],
            [-2.648200366780534, 52.293918341226487],
            [-2.652420524583007, 52.295233763841331],
            [-2.655975080446125, 52.299119490879754],
            [-2.657209575977, 52.299202529319992],
            [-2.659441330055867, 52.300179027332],
            [-2.661054532817491, 52.30038217903936],
            [-2.661430202770009, 52.300792733647256],
            [-2.661112692813254, 52.301136146497846],
            [-2.661613760489823, 52.301399455036496],
            [-2.661196165839312, 52.302014039340243],
            [-2.661590791208083, 52.3022195065274],
            [-2.662104498603252, 52.302051203859925],
            [-2.663207453603383, 52.303924003028087],
            [-2.661608407271619, 52.304581180369425],
            [-2.661622225125104, 52.304819347931158],
            [-2.659402342526934, 52.305351410079147],
            [-2.65867865795148, 52.305308699523415],
            [-2.658373180781972, 52.304984052669028],
            [-2.658763991732953, 52.304339060964892],
            [-2.656435553977268, 52.305167456304353],
            [-2.654019902495895, 52.305259078038233],
            [-2.652910186809235, 52.305792954587204],
            [-2.647556589572606, 52.30526773165905],
            [-2.64538026666785, 52.306185876706706],
            [-2.64322803334501, 52.3081647130712],
            [-2.638762248804012, 52.307682799444073],
            [-2.63725948838349, 52.308134144471538],
            [-2.636327934145245, 52.308939310566807],
            [-2.633835689164916, 52.307382998793237],
            [-2.63334006719877, 52.306055983909566],
            [-2.632448098177767, 52.305627429663694],
            [-2.631658227678817, 52.305673013660702],
            [-2.629719914987825, 52.306644436984314],
            [-2.626931611033151, 52.306114459424876],
            [-2.62311714039432, 52.306449314451712],
            [-2.619076608529402, 52.305618283462522],
            [-2.618269711839794, 52.306021683345115],
            [-2.618037622686072, 52.306943510808857],
            [-2.617681795052079, 52.307616952265306],
            [-2.616058651244693, 52.307969762953753],
            [-2.614641521622066, 52.307631919052376],
            [-2.613031760446559, 52.30661988303283],
            [-2.610004108326907, 52.30626785871258],
            [-2.605814797445384, 52.3076253943144],
            [-2.602794976516179, 52.309361602413354],
            [-2.600528229388225, 52.310184082005449],
            [-2.595091921705026, 52.313872471610956],
            [-2.589806219668072, 52.313178866976443],
            [-2.587242094427925, 52.313510804140193],
            [-2.584503152685548, 52.313158487871142],
            [-2.582427486783049, 52.31505581474503],
            [-2.579993775295602, 52.316510741420714],
            [-2.578511131818295, 52.316885718989347],
            [-2.576486010603544, 52.316723009253927],
            [-2.57485531626492, 52.317562564134036],
            [-2.573383274028092, 52.317175046375688],
            [-2.570430007402005, 52.315109889119121],
            [-2.567634453380413, 52.31434300150746],
            [-2.56674416708682, 52.313320583228254],
            [-2.564841962780094, 52.312657222120947],
            [-2.563879930953635, 52.311521847253601],
            [-2.562647135835663, 52.311123163696045],
            [-2.562495034536112, 52.312315106415781],
            [-2.561923304662164, 52.312458077787234],
            [-2.563490293772645, 52.313411675420539],
            [-2.563469796084171, 52.313876572890095],
            [-2.562280244760218, 52.314463917390192],
            [-2.561944097045581, 52.315372640908663],
            [-2.562383565019034, 52.315670825228828],
            [-2.562288664078937, 52.316516367071898],
            [-2.562768382892342, 52.317210831210232],
            [-2.563930767500337, 52.317481288190976],
            [-2.564738849699286, 52.318843954081309],
            [-2.563994625716288, 52.319387828996867],
            [-2.564155434527595, 52.320265414377602],
            [-2.563383030086887, 52.32055679213552],
            [-2.562779202402566, 52.321415549107947],
            [-2.563952540800095, 52.322423159063348],
            [-2.563950968390796, 52.322876278124028],
            [-2.562703871464085, 52.323687758996485],
            [-2.562647392358198, 52.324555592630922],
            [-2.561618992954523, 52.325146656044545],
            [-2.561222572544135, 52.325937889368248],
            [-2.561907725481601, 52.327204960760966],
            [-2.560872590565749, 52.329820664438309],
            [-2.561683597801652, 52.331403596964613],
            [-2.562360016808772, 52.331858882803502],
            [-2.562221606785261, 52.333094808082052],
            [-2.560605833244355, 52.333442322337781],
            [-2.559357217991637, 52.334278046738206],
            [-2.557011196513096, 52.335029934976184],
            [-2.555507250221893, 52.334355555111728],
            [-2.554315735649613, 52.334720765300276],
            [-2.551221941872515, 52.337072720053179],
            [-2.549328641650507, 52.337191223246712],
            [-2.54838140871635, 52.338567542098332],
            [-2.546780733752368, 52.339806631712783],
            [-2.540968705846959, 52.341876885970038],
            [-2.538996505467611, 52.343130156371146],
            [-2.539152471199698, 52.344125566092302],
            [-2.53561255948983, 52.344303497616153],
            [-2.534147952069993, 52.34409076816204],
            [-2.533702416325836, 52.343662148646771],
            [-2.531469130283416, 52.343365651553611],
            [-2.529717860824271, 52.342621933206644],
            [-2.526818592409751, 52.342477571917115],
            [-2.525468057335326, 52.342097007175532],
            [-2.524994443204172, 52.342386801926153],
            [-2.523966334571489, 52.341838466440485],
            [-2.521909850648818, 52.341834086928145],
            [-2.517784243962257, 52.338608559259107],
            [-2.516394206048706, 52.337970043418167],
            [-2.513606527569804, 52.337546172163229],
            [-2.513499934711035, 52.337720147849943],
            [-2.512132176731471, 52.337096768503336],
            [-2.511345919119135, 52.336242504050354],
            [-2.508622307295645, 52.334737607601632],
            [-2.508412910167625, 52.33324342726484],
            [-2.509077104091649, 52.332424249079196],
            [-2.510631071006204, 52.332539807505889],
            [-2.511731217105487, 52.331988436547768],
            [-2.51259485425002, 52.332306542722051],
            [-2.513098477769757, 52.331188662324166],
            [-2.513913795211215, 52.330757179724898],
            [-2.513207528851625, 52.329200437927987],
            [-2.509984096888383, 52.329496697865835],
            [-2.507837887539668, 52.329039351890714],
            [-2.507001415802369, 52.328265287178809],
            [-2.505737119017045, 52.328059437184287],
            [-2.50377677285397, 52.328452600689559],
            [-2.50294788810808, 52.32898656034255],
            [-2.49804321289874, 52.329063088269599],
            [-2.497916164525739, 52.329379182681919],
            [-2.496223174163324, 52.329284719127173],
            [-2.495238421927179, 52.329668242746379],
            [-2.494732273503487, 52.329678455656413],
            [-2.494375875813686, 52.329300558787871],
            [-2.491066938205116, 52.329308072585668],
            [-2.490198252612527, 52.329442939898108],
            [-2.488054053789742, 52.330615162500806],
            [-2.482794759109557, 52.331160915061062],
            [-2.482879519932797, 52.331397911508418],
            [-2.482383229424555, 52.331506924083776],
            [-2.481798759992591, 52.331064292066877],
            [-2.481410696807285, 52.331679909905496],
            [-2.479860534749879, 52.332583447776656],
            [-2.480114550508252, 52.333814980232198],
            [-2.481014639051711, 52.33439928034668],
            [-2.481072860898846, 52.335841979854045],
            [-2.478384627001157, 52.337924255579246],
            [-2.47822400429454, 52.338408581817134],
            [-2.47966118090133, 52.340197207582797],
            [-2.482105726477212, 52.341610410107656],
            [-2.484357172619124, 52.344546403015109],
            [-2.48380360518571, 52.346413248821051],
            [-2.483297540647087, 52.346583438378175],
            [-2.482945265613489, 52.347529753887976],
            [-2.48341898633076, 52.347623112361838],
            [-2.48284580826034, 52.34891016244093],
            [-2.481633782424297, 52.34950487099389],
            [-2.481795075036117, 52.35110627333227],
            [-2.483483418519594, 52.352180899280995],
            [-2.483795202915548, 52.353170346332682],
            [-2.485882785658214, 52.353820760894472],
            [-2.486641350285329, 52.353774484745379],
            [-2.487726268609435, 52.354298635353722],
            [-2.488329938866004, 52.355383959104969],
            [-2.48515584755752, 52.356217847606231],
            [-2.486101242641565, 52.35834014939006],
            [-2.486745916838051, 52.358879605099816],
            [-2.483892676545579, 52.360478103055172],
            [-2.482517660020254, 52.360023428751845],
            [-2.479887323640783, 52.360259799227222],
            [-2.479175977327072, 52.360066700353059],
            [-2.478382145189271, 52.360800823033792],
            [-2.477930790711876, 52.360070846274262],
            [-2.475580521647714, 52.359725219070555],
            [-2.474183135307062, 52.360599292447944],
            [-2.473952841714078, 52.361034444291079],
            [-2.474515922037886, 52.361506867035644],
            [-2.473452047934868, 52.361991213757683],
            [-2.473427186028798, 52.362824706400318],
            [-2.476225379718232, 52.365615707549793],
            [-2.474773025495225, 52.367131909297257],
            [-2.473920763456365, 52.367091279701135],
            [-2.473655042084585, 52.366277832545975],
            [-2.472738112116609, 52.365956060169474],
            [-2.46954983654393, 52.366382326649045],
            [-2.469051000066547, 52.366013911950411],
            [-2.469828384107641, 52.365505572690743],
            [-2.468970320924675, 52.364775381404208],
            [-2.467751824966894, 52.364538376246841],
            [-2.463905841707771, 52.365120825896518],
            [-2.462860581352369, 52.366983202174161],
            [-2.460879338697008, 52.367545644689301],
            [-2.458368799631732, 52.367207492599803],
            [-2.458222949507916, 52.367731288297378],
            [-2.457655390134876, 52.367810804376781],
            [-2.456183827218868, 52.367389462092888],
            [-2.454990491183984, 52.367885828771342],
            [-2.451395574300984, 52.367496860330192],
            [-2.449102983509395, 52.367643149253389],
            [-2.446996476097679, 52.367165668813236],
            [-2.446605310387779, 52.367407186774955],
            [-2.445621059659647, 52.367091752005585],
            [-2.440692630790721, 52.366985278668928],
            [-2.437853925824724, 52.366413270796244],
            [-2.436856528321223, 52.366542826652257],
            [-2.435345507404424, 52.366266113296938],
            [-2.431187621221726, 52.367152514943392],
            [-2.429215089021043, 52.366837850257092],
            [-2.426339927553429, 52.365818885809638],
            [-2.425141297187132, 52.365947270927286],
            [-2.424487419327897, 52.366371262811121],
            [-2.422843003117844, 52.366265682740973],
            [-2.421926069539412, 52.367291146688004],
            [-2.420223688912604, 52.368044300453825],
            [-2.418130597320907, 52.368022959927686],
            [-2.416128967216367, 52.368512801903542],
            [-2.414926483101428, 52.368241033410357],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000236",
        LAD13CDO: "47UD",
        LAD13NM: "Redditch",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.926333478719302, 52.323691902227957],
            [-1.922073369320643, 52.32246201632011],
            [-1.915808007556825, 52.322738225863802],
            [-1.914158269935949, 52.323060689617549],
            [-1.912161625901399, 52.322954940141919],
            [-1.90939632568843, 52.323500361209362],
            [-1.908792202792148, 52.320458512182668],
            [-1.89901387889768, 52.321768544234544],
            [-1.89897655486989, 52.322052602833672],
            [-1.89755313817064, 52.321532645529793],
            [-1.895033338293482, 52.321834308137156],
            [-1.893036266877955, 52.32133806013465],
            [-1.891877050736206, 52.321410727179462],
            [-1.890993356785672, 52.320407509187731],
            [-1.890093559241768, 52.318219359944649],
            [-1.88094266784228, 52.310918548562462],
            [-1.878763698686026, 52.308514149531675],
            [-1.877587406357018, 52.306377760111445],
            [-1.876297786796341, 52.305480992664037],
            [-1.875215463734856, 52.3054672703717],
            [-1.875237959061095, 52.304244622625774],
            [-1.876005012464158, 52.304231943237617],
            [-1.877704531914484, 52.300584574894351],
            [-1.879459434691113, 52.299664875472573],
            [-1.87967675595519, 52.299015102258181],
            [-1.880208975739974, 52.299046208844011],
            [-1.880436752965262, 52.298320027701834],
            [-1.882607800819581, 52.294678454847819],
            [-1.883191485472544, 52.294634980018444],
            [-1.883703129731053, 52.294104160183693],
            [-1.884281986084646, 52.291966846299502],
            [-1.885805951376854, 52.291726488431181],
            [-1.886610382597613, 52.290768001851944],
            [-1.885504024876689, 52.29054667580909],
            [-1.886592975830533, 52.288392760130783],
            [-1.886010705124437, 52.287344836016452],
            [-1.886400888978953, 52.2866826290932],
            [-1.891421012513321, 52.285851245776982],
            [-1.8936976460829, 52.283399869603407],
            [-1.895293988537164, 52.282779165910597],
            [-1.896562660630117, 52.283740441109082],
            [-1.897949791395977, 52.28356903173416],
            [-1.900807019246791, 52.283476162922106],
            [-1.901161271067799, 52.283037734330328],
            [-1.900739403590271, 52.282243541200131],
            [-1.903625988957073, 52.280734662061505],
            [-1.902930919768503, 52.280191082255591],
            [-1.903307110832453, 52.279752664979007],
            [-1.905372935752549, 52.278797769169422],
            [-1.912003254783938, 52.2769041359075],
            [-1.913379584297893, 52.276116703522341],
            [-1.914387800113567, 52.275430579625343],
            [-1.915984636464864, 52.275019968365029],
            [-1.921113180373037, 52.27646193783351],
            [-1.923878062982275, 52.27607806358214],
            [-1.926176135739709, 52.276111882387639],
            [-1.927403932608227, 52.275465341191143],
            [-1.92859399725437, 52.275478650497263],
            [-1.930356865587844, 52.275655908680974],
            [-1.934266712941284, 52.276957235174123],
            [-1.935129810198779, 52.2770835742879],
            [-1.937408796306748, 52.276139022606088],
            [-1.938694607450595, 52.270483911855038],
            [-1.938081382616927, 52.268849160151404],
            [-1.936220626638112, 52.266582622314566],
            [-1.935218036261839, 52.266792451465882],
            [-1.935141231831462, 52.26620174767374],
            [-1.935844900316869, 52.26598186848009],
            [-1.935756194040759, 52.264493028741356],
            [-1.934394837928606, 52.26458038810118],
            [-1.934772404766141, 52.262897614058716],
            [-1.936422449951805, 52.262742978290895],
            [-1.935822032871846, 52.261503792464467],
            [-1.936581097397115, 52.260394798540759],
            [-1.934121772151329, 52.259973610698694],
            [-1.93542291591219, 52.25684929878755],
            [-1.934217342691131, 52.256761430044833],
            [-1.934786849435836, 52.256019145772562],
            [-1.934418471476431, 52.254478903575318],
            [-1.935335498183147, 52.254492892781265],
            [-1.93461839636405, 52.252003982618156],
            [-1.935968725071046, 52.252187222901874],
            [-1.935869525524771, 52.251901277478808],
            [-1.939104141677565, 52.251728570901392],
            [-1.939345480074009, 52.251993908690636],
            [-1.939517519824307, 52.251512116021267],
            [-1.942228636557613, 52.251736427324332],
            [-1.94606134488752, 52.251169145383699],
            [-1.946063299034881, 52.250763683311007],
            [-1.946675663865172, 52.250701027934795],
            [-1.946395215794032, 52.250038315683561],
            [-1.947285498591237, 52.249056073481732],
            [-1.947432891429468, 52.248275779698325],
            [-1.949781060484684, 52.248087100989629],
            [-1.951668626341174, 52.247156487988619],
            [-1.951269573119005, 52.245071470375379],
            [-1.952481850624341, 52.244096512196478],
            [-1.952831802935946, 52.24417666559583],
            [-1.953002230091615, 52.243667881063963],
            [-1.955037600258916, 52.24405525299337],
            [-1.957613528055714, 52.242806545585445],
            [-1.957888458680765, 52.241679256912228],
            [-1.958585631555019, 52.241607579393857],
            [-1.958636886489878, 52.240025300098999],
            [-1.959558910051252, 52.240671122852895],
            [-1.961393822548523, 52.240759838241864],
            [-1.962725295665573, 52.240445601798498],
            [-1.963212008488082, 52.239825420912645],
            [-1.965007207272561, 52.240101066637898],
            [-1.96621028884563, 52.239090002705396],
            [-1.967592742316152, 52.237011823207233],
            [-1.972934379502077, 52.237565163034311],
            [-1.974502936609781, 52.237190611980004],
            [-1.975087463917037, 52.236744815092528],
            [-1.981620792904456, 52.238188946292311],
            [-1.982427495253334, 52.238649372112732],
            [-1.98352908895977, 52.237667784631952],
            [-1.98524964141646, 52.237947607525086],
            [-1.986292386841885, 52.237609694509374],
            [-1.987103524802204, 52.238053906333647],
            [-1.990379291500304, 52.238406632271541],
            [-1.991330953625769, 52.239460370191217],
            [-1.994029860467746, 52.239847115501888],
            [-1.993088752432908, 52.236280602998413],
            [-1.993514971528017, 52.23557578422642],
            [-1.994663025774009, 52.235257581373453],
            [-1.997407094283107, 52.235759327935455],
            [-1.997494932203312, 52.236175581987396],
            [-2.005860557528725, 52.235574890198556],
            [-2.006431740428677, 52.236256326321616],
            [-2.007964969159328, 52.236657198151313],
            [-2.009077835836134, 52.236490794209459],
            [-2.009525834672942, 52.236087092038566],
            [-2.010958057411522, 52.236532884993672],
            [-2.011193770099134, 52.236353954765526],
            [-2.012050423527358, 52.236418598891653],
            [-2.012046104886722, 52.236687410230928],
            [-2.012953971123427, 52.236593812627504],
            [-2.013087292076234, 52.236813161688325],
            [-2.014481309717574, 52.236683535033087],
            [-2.014422869452238, 52.237088107179304],
            [-2.01513175141286, 52.237471004898147],
            [-2.01570706912896, 52.236969268063888],
            [-2.016746872479406, 52.237261308527877],
            [-2.018871590700388, 52.240493904907417],
            [-2.01891740480302, 52.241457660207537],
            [-2.017893148974681, 52.24365235968687],
            [-2.017282301225428, 52.247060681339534],
            [-2.015421087848112, 52.252358034289223],
            [-2.013732374622877, 52.253221315923639],
            [-2.013334253465116, 52.25429660285068],
            [-2.013117773288058, 52.255388949363194],
            [-2.013771482721407, 52.25645602219624],
            [-2.013341129551885, 52.257618518274761],
            [-2.011362507964467, 52.260065888414097],
            [-2.011248888560881, 52.262612849475886],
            [-2.011247490177505, 52.262870870951666],
            [-2.0120988189992, 52.263026316905261],
            [-2.012241334108964, 52.264410806416414],
            [-2.013239124192756, 52.264243477166218],
            [-2.013994185564705, 52.265665651839882],
            [-2.013247018843818, 52.266113455928959],
            [-2.015397031000399, 52.267236975915374],
            [-2.015537217416133, 52.269987983413159],
            [-2.012165380890674, 52.270509822659101],
            [-2.011551448075451, 52.270865900709929],
            [-2.009803146551939, 52.270890336454222],
            [-2.007169584492009, 52.270238736416488],
            [-2.005837536704867, 52.270578645298656],
            [-2.004215414750618, 52.272008172087773],
            [-2.003205804856544, 52.273617465313876],
            [-2.007051728088278, 52.275372190870335],
            [-2.006664851445593, 52.275673388281447],
            [-2.005411736703605, 52.27566266683592],
            [-2.002499601432636, 52.276791049768008],
            [-2.003917070353408, 52.278590859662948],
            [-2.002467527516613, 52.279568143949241],
            [-1.997960160695222, 52.283584112082302],
            [-1.999182688169271, 52.285619518323557],
            [-2.002025309567038, 52.2881250810862],
            [-1.994869305477577, 52.290500227022854],
            [-1.992804994592397, 52.290599016654411],
            [-1.99090314839, 52.292035531171372],
            [-1.98843466534633, 52.289887550692534],
            [-1.986158973211216, 52.290891521745749],
            [-1.984158750342505, 52.292086078646406],
            [-1.985189045243432, 52.293379007211321],
            [-1.983722242231269, 52.295012344495696],
            [-1.984054954231125, 52.295382787058955],
            [-1.983420287051634, 52.298741454017531],
            [-1.98484701617167, 52.298948417885022],
            [-1.98499034783168, 52.300078508309142],
            [-1.981604659650746, 52.299317463336692],
            [-1.980610045974869, 52.3002172288897],
            [-1.979115434053972, 52.300884052695494],
            [-1.976499260837328, 52.303695710988343],
            [-1.979880083410116, 52.306049069832696],
            [-1.980722935686595, 52.307126237270133],
            [-1.982013138287948, 52.308291570620788],
            [-1.980670705594971, 52.309213758174323],
            [-1.977896787023251, 52.309861472704178],
            [-1.97400340270113, 52.308379100558248],
            [-1.968651073569063, 52.31300417923525],
            [-1.969578232335975, 52.315012832984188],
            [-1.960589156579585, 52.314713519281668],
            [-1.960119443257821, 52.31504869757358],
            [-1.960456298265392, 52.315657447302861],
            [-1.959907416972437, 52.315926969820474],
            [-1.960097875830269, 52.316202134284261],
            [-1.958345034057317, 52.31757613597722],
            [-1.958633359348736, 52.318306241411769],
            [-1.957832276339135, 52.318391366941086],
            [-1.957064288385173, 52.317620631347658],
            [-1.955769031806808, 52.318982172613978],
            [-1.955105374713293, 52.320983143058939],
            [-1.952605131754243, 52.319846709455405],
            [-1.951707568116623, 52.320929667431223],
            [-1.950074922705575, 52.322004220511815],
            [-1.948184058325804, 52.321741795716271],
            [-1.944881796912976, 52.321502068275976],
            [-1.942940077025988, 52.320894312128495],
            [-1.942582550464789, 52.321682580417402],
            [-1.940957842422328, 52.32322270358074],
            [-1.939353778574093, 52.323526662391245],
            [-1.93931826921192, 52.323744207040242],
            [-1.934707659244212, 52.324161601520828],
            [-1.926333478719302, 52.323691902227957],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000237",
        LAD13CDO: "47UE",
        LAD13NM: "Worcester",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.211148741953147, 52.22556827085544],
            [-2.209161506054919, 52.224815723874421],
            [-2.196485821851939, 52.22365350227598],
            [-2.194252580474636, 52.223478295451308],
            [-2.188883465911147, 52.221188185167087],
            [-2.171136947645858, 52.217656902666157],
            [-2.168300085341346, 52.216838369405252],
            [-2.167638183200501, 52.216367318388883],
            [-2.162697449522783, 52.214143732131298],
            [-2.159705610936944, 52.213264970384728],
            [-2.158991156890013, 52.213610267945306],
            [-2.157793115079856, 52.213377229655997],
            [-2.157371847356084, 52.212619005391453],
            [-2.158435481097954, 52.212104229485369],
            [-2.162637593434269, 52.203381428087916],
            [-2.164885208064508, 52.200239757881157],
            [-2.164982775035982, 52.200114654799926],
            [-2.167416687020062, 52.196413470458708],
            [-2.168524651339509, 52.191898707399645],
            [-2.171157349372804, 52.184547945276179],
            [-2.179822885164894, 52.1840450597361],
            [-2.179682358843022, 52.183654190753465],
            [-2.182879400128972, 52.182890479966474],
            [-2.182721734426663, 52.181892787401139],
            [-2.184866410217137, 52.179919639137736],
            [-2.184951481764739, 52.178570941096638],
            [-2.187381766944298, 52.178155339231445],
            [-2.186062391587503, 52.176691090550086],
            [-2.188497017778286, 52.175600276931661],
            [-2.186995189382448, 52.17455438520097],
            [-2.186339862395228, 52.174503278963051],
            [-2.186357423803669, 52.174157119327141],
            [-2.186728103774625, 52.172585003601924],
            [-2.188623769785417, 52.171000565090921],
            [-2.189642108924801, 52.170467595071756],
            [-2.19100522465962, 52.170895136255403],
            [-2.191688172389899, 52.170240421766501],
            [-2.195272476205183, 52.17036309895871],
            [-2.196824136134548, 52.1700997941171],
            [-2.196755245126795, 52.169730401852],
            [-2.200029314117245, 52.169475853427159],
            [-2.200291365446524, 52.169223675717561],
            [-2.199827670162567, 52.168201350055064],
            [-2.203376477732225, 52.167305223182538],
            [-2.203412418255025, 52.166851143350137],
            [-2.204694125239714, 52.166420978066405],
            [-2.205329360517821, 52.165623318601106],
            [-2.206447983426956, 52.165347155004525],
            [-2.207417468591081, 52.1644428105879],
            [-2.21155948029361, 52.164184612390763],
            [-2.211684224209947, 52.163361761480843],
            [-2.211039931572139, 52.162525006523168],
            [-2.214322157017869, 52.161661391904246],
            [-2.216354053222343, 52.161636101259433],
            [-2.217605660004001, 52.161983525323542],
            [-2.217773310918939, 52.163084546963361],
            [-2.219683901005958, 52.16542571168609],
            [-2.220481409281204, 52.167342785166952],
            [-2.222003893603531, 52.168317188802696],
            [-2.22573553947632, 52.16809972271885],
            [-2.227329372876726, 52.166982741473127],
            [-2.227366802004167, 52.166581694957607],
            [-2.225333723507835, 52.166416579707636],
            [-2.22407619614206, 52.16551004264165],
            [-2.225759896399428, 52.164827150274263],
            [-2.226582354200319, 52.163838419270732],
            [-2.227628176581493, 52.163654796333162],
            [-2.228376770872719, 52.164525422417292],
            [-2.228595540351357, 52.167557479204994],
            [-2.230073496789663, 52.168650539153255],
            [-2.231670310642623, 52.168967470832023],
            [-2.23276482013075, 52.169688146369474],
            [-2.235403227127386, 52.169265748489295],
            [-2.23694861059251, 52.169523374477833],
            [-2.238953979216791, 52.169121947272757],
            [-2.242374082295911, 52.170230678080259],
            [-2.243478644044421, 52.169007497644031],
            [-2.244184138170366, 52.168877474462668],
            [-2.246020712588847, 52.169169440725376],
            [-2.247314783251371, 52.169711553693219],
            [-2.245277451436372, 52.171203731386434],
            [-2.244300593212568, 52.171450300679574],
            [-2.245412875681471, 52.171898411304873],
            [-2.245567932187029, 52.172970648938559],
            [-2.246573505246805, 52.173151954529573],
            [-2.246344866714815, 52.173585772263095],
            [-2.247064924851041, 52.173960964730028],
            [-2.24638208848828, 52.174234804752508],
            [-2.246893844254263, 52.17448996152801],
            [-2.246332467371398, 52.175310165819461],
            [-2.247066286289, 52.176052139562316],
            [-2.246580334663934, 52.177027721322872],
            [-2.248998629975741, 52.178508757099131],
            [-2.249514754947282, 52.180315642956373],
            [-2.250333049523902, 52.180971108803284],
            [-2.251004024898851, 52.181173764734311],
            [-2.251254594123961, 52.180739891779268],
            [-2.252977800815578, 52.180547406609506],
            [-2.252745926925747, 52.181179931832801],
            [-2.253305356275586, 52.181310890324497],
            [-2.253809736206335, 52.182047021100516],
            [-2.252611272410978, 52.183482671031676],
            [-2.254096609363251, 52.183622426266545],
            [-2.2573216564923, 52.182610299163805],
            [-2.261882404963715, 52.18194035636224],
            [-2.260861544938895, 52.182439791232305],
            [-2.261455350559758, 52.184184418165074],
            [-2.263203000710504, 52.184648031332422],
            [-2.262947938836768, 52.18529591177991],
            [-2.262333136051449, 52.185461806551942],
            [-2.261840822103411, 52.189793590823449],
            [-2.261239039355301, 52.190693968490542],
            [-2.260018980449527, 52.190684978450399],
            [-2.259949790272476, 52.191111277130865],
            [-2.259286381655885, 52.191235907013528],
            [-2.259476502169997, 52.191478230092748],
            [-2.258103316920755, 52.191302335328324],
            [-2.257361925370288, 52.192109498121766],
            [-2.260441145830071, 52.192083855344109],
            [-2.260542070849283, 52.193831370088191],
            [-2.260022465755367, 52.195039031488115],
            [-2.26053621628016, 52.196330719052021],
            [-2.257081178624324, 52.196691627633598],
            [-2.255006442481932, 52.196154011958697],
            [-2.252945624133123, 52.196254649381821],
            [-2.251421825361525, 52.196631910436345],
            [-2.250248475560854, 52.197412976883186],
            [-2.250914182982675, 52.199751765604844],
            [-2.247099880689348, 52.201876164193109],
            [-2.24757049321646, 52.2025683369666],
            [-2.247026456457219, 52.203146662110562],
            [-2.247251235367072, 52.204099173493361],
            [-2.248803336443471, 52.204282908351793],
            [-2.249792631500642, 52.205867621943611],
            [-2.249189026268159, 52.20600195724527],
            [-2.248914615855844, 52.206649845619282],
            [-2.249439463710186, 52.2070829724898],
            [-2.248787942066516, 52.207287532164599],
            [-2.247759720678852, 52.20843507393846],
            [-2.248464503488041, 52.209902621017363],
            [-2.24886076129099, 52.210626410837541],
            [-2.248498128707212, 52.210944536555076],
            [-2.247986420609799, 52.210512277153704],
            [-2.245677051677652, 52.211310064641928],
            [-2.243026923249453, 52.208952882558414],
            [-2.242548078092246, 52.209166041976005],
            [-2.242295198507953, 52.208951691588716],
            [-2.238222701307915, 52.210637598305119],
            [-2.234890195402205, 52.211443530846608],
            [-2.237752873669127, 52.214188850568725],
            [-2.243541738793293, 52.217450432718358],
            [-2.243990315459253, 52.21997580157462],
            [-2.243275937570488, 52.221840982461387],
            [-2.239959772109129, 52.221472879631385],
            [-2.239900534143132, 52.222702883137579],
            [-2.238019627460629, 52.222464854255733],
            [-2.23794053700709, 52.222183615257308],
            [-2.235862629702154, 52.222332535399474],
            [-2.235536419360191, 52.222100336909762],
            [-2.234961909609082, 52.223635240444139],
            [-2.235068868952321, 52.225314427996061],
            [-2.233483771977802, 52.226782108125271],
            [-2.228431461991744, 52.224793446799488],
            [-2.226734236643499, 52.227853446708529],
            [-2.222478249157764, 52.231369592325009],
            [-2.2215808226141, 52.231391960133415],
            [-2.221217811659115, 52.230824451316657],
            [-2.218572323863824, 52.22968221585171],
            [-2.211148741953147, 52.22556827085544],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000238",
        LAD13CDO: "47UF",
        LAD13NM: "Wychavon",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.230047752475667, 52.361069198359985],
            [-2.220083059738801, 52.359348601401322],
            [-2.21181574840506, 52.355927686926329],
            [-2.210200568696634, 52.354986607237677],
            [-2.210597333451846, 52.354440196950208],
            [-2.207866697327034, 52.353250244575285],
            [-2.20745340355531, 52.351528455532879],
            [-2.205315046266699, 52.350734766778004],
            [-2.204669959617457, 52.347751149958405],
            [-2.20502708664007, 52.345618959230137],
            [-2.202933271845805, 52.345183857548584],
            [-2.200938591920025, 52.344265777381047],
            [-2.19558511998322, 52.341265757891946],
            [-2.195208273922671, 52.340352979188751],
            [-2.19392089877774, 52.339985605251051],
            [-2.193345901244249, 52.339060559654627],
            [-2.193638768102525, 52.338905448207512],
            [-2.193052963303622, 52.338863255174338],
            [-2.193016656219559, 52.337941820349478],
            [-2.191948653992925, 52.337374483592491],
            [-2.192163803553851, 52.336898552389243],
            [-2.192777415937053, 52.336929017152919],
            [-2.192486386352046, 52.336491670011696],
            [-2.192804106266632, 52.335980508450582],
            [-2.193151008311015, 52.336104904761221],
            [-2.193465942122373, 52.3352943725437],
            [-2.192630825790297, 52.334607989112079],
            [-2.192489421492199, 52.333476354438581],
            [-2.190470974111782, 52.331977368308223],
            [-2.190469570636354, 52.32892160181742],
            [-2.187267411087181, 52.329194635803645],
            [-2.185009783593438, 52.330064852894303],
            [-2.182790673269029, 52.330333522741128],
            [-2.18123805848582, 52.331034456422103],
            [-2.179222660770369, 52.331255098009628],
            [-2.177945012893757, 52.331748795729098],
            [-2.17525208666464, 52.331354561105691],
            [-2.174286496370371, 52.331355991555668],
            [-2.173659878650135, 52.331728210697186],
            [-2.170806448169892, 52.331561562872018],
            [-2.172081668650004, 52.328536306151257],
            [-2.173160398817509, 52.327838886136945],
            [-2.174707454958688, 52.32460912198114],
            [-2.175312089498482, 52.31982273617605],
            [-2.17454398498765, 52.316636843634804],
            [-2.173054543072565, 52.317271949535595],
            [-2.169086151525917, 52.317234551881796],
            [-2.166804850943728, 52.318374163400122],
            [-2.167461349105008, 52.318567421805646],
            [-2.167907175979272, 52.319300389482294],
            [-2.167522303927293, 52.320332114128163],
            [-2.167646433877477, 52.324444059299502],
            [-2.164481396579907, 52.325971455738063],
            [-2.163289754210466, 52.327508637828743],
            [-2.159914360147879, 52.330213018700753],
            [-2.157611655315223, 52.330952415050724],
            [-2.156074058794619, 52.331037169390648],
            [-2.156511729219294, 52.332801364344363],
            [-2.156239303545392, 52.333368107552474],
            [-2.155039341997354, 52.333925284645154],
            [-2.154386498798947, 52.335250395738022],
            [-2.151931298194458, 52.336987790651158],
            [-2.152594998701963, 52.338783172554436],
            [-2.151961538601054, 52.341520601107931],
            [-2.150967804497329, 52.343663337067085],
            [-2.148864378344018, 52.346293837713269],
            [-2.148509418974084, 52.346379691754535],
            [-2.146264024858578, 52.345228159443941],
            [-2.143134858901711, 52.346731558569189],
            [-2.142049539713499, 52.347952836413981],
            [-2.139245406506605, 52.348350848493538],
            [-2.137174614294815, 52.349965211599738],
            [-2.133022822457237, 52.347998424261753],
            [-2.13201176338987, 52.350102359771157],
            [-2.13073663752125, 52.351313855959042],
            [-2.129728676417681, 52.352034181836025],
            [-2.127732532093743, 52.352223356056761],
            [-2.127330966829987, 52.351960378647817],
            [-2.129091896161995, 52.350662083064258],
            [-2.131477157858566, 52.347065180213988],
            [-2.12903756523807, 52.345626750850876],
            [-2.126392124866751, 52.345554098515017],
            [-2.125346759519625, 52.343932488603834],
            [-2.123100535140293, 52.342749051275156],
            [-2.122780947586189, 52.342365504087489],
            [-2.124758497581548, 52.341978648848823],
            [-2.124687570117676, 52.340249014040523],
            [-2.123862203029485, 52.34007637353163],
            [-2.121297226121889, 52.340319077869545],
            [-2.119289416935659, 52.341443100314379],
            [-2.118864345577093, 52.342209492469223],
            [-2.116985001006452, 52.342011790351826],
            [-2.117422838222888, 52.341619383499626],
            [-2.116780323866498, 52.340097984952898],
            [-2.116096526711332, 52.340166086630404],
            [-2.116471479664138, 52.338749763812217],
            [-2.114204702325057, 52.338507448653232],
            [-2.114173531211286, 52.337803547791786],
            [-2.11328977239013, 52.337147218218099],
            [-2.113520965801851, 52.336303716770601],
            [-2.11467491333138, 52.335320871893487],
            [-2.115236466901865, 52.33511444908153],
            [-2.116289807079056, 52.335523367112209],
            [-2.117239875856746, 52.335168212860133],
            [-2.117539658827199, 52.335316252391799],
            [-2.118481979758378, 52.332073447810764],
            [-2.116156113927813, 52.327085306443806],
            [-2.117041329369641, 52.327242658313175],
            [-2.117620561696648, 52.326553432698823],
            [-2.117304336229812, 52.325162065058883],
            [-2.118596981344255, 52.325156276554907],
            [-2.117869390224205, 52.323017338394649],
            [-2.118393886819055, 52.322733621374255],
            [-2.115754185894665, 52.32208265528601],
            [-2.11859816078447, 52.319025854950489],
            [-2.122200465563315, 52.316610101969019],
            [-2.124061988591314, 52.314488264891828],
            [-2.117133103993197, 52.3131306388334],
            [-2.114315324071399, 52.313158578084114],
            [-2.114489153408758, 52.313444298274071],
            [-2.112347033941844, 52.314411908940748],
            [-2.109966694170358, 52.314594857352049],
            [-2.107520912381485, 52.314416410869057],
            [-2.102173229838619, 52.313435841407085],
            [-2.100316115972746, 52.313414961643716],
            [-2.098805583278601, 52.31357176882026],
            [-2.095649456463895, 52.314529134322569],
            [-2.094118404955787, 52.315409610357449],
            [-2.095110035728617, 52.313351852858595],
            [-2.086335989251706, 52.314511156256252],
            [-2.085169285528054, 52.313485323273035],
            [-2.085866972592738, 52.313203422900074],
            [-2.085191230235938, 52.312693269380922],
            [-2.085284016759644, 52.312126818556848],
            [-2.087237810563947, 52.309880529767618],
            [-2.088713575375345, 52.306324692934318],
            [-2.090407586856831, 52.304908342938909],
            [-2.090314498795907, 52.303850264339793],
            [-2.089696143186813, 52.303389538056464],
            [-2.087966485701824, 52.303825070819165],
            [-2.08768427258242, 52.303505229074489],
            [-2.084886701077917, 52.296939012781948],
            [-2.08752080505615, 52.294261588177072],
            [-2.091696562124362, 52.288546905274565],
            [-2.0893439426104, 52.289471113627179],
            [-2.087591107053643, 52.28903460520678],
            [-2.08430235152109, 52.288846412455278],
            [-2.076748098491569, 52.28906464886218],
            [-2.074202254305214, 52.288621263970946],
            [-2.070828171783411, 52.287277502188701],
            [-2.061859938129939, 52.284728428293569],
            [-2.060896067383571, 52.284179626582429],
            [-2.057819250203472, 52.284278274672616],
            [-2.055550534465027, 52.284691122491722],
            [-2.051208152321875, 52.286957740372067],
            [-2.047019266615982, 52.287848627769542],
            [-2.043325876669037, 52.2873834545411],
            [-2.041532110430456, 52.286503058069435],
            [-2.038395591874284, 52.285615889198787],
            [-2.03598293938169, 52.284204284941865],
            [-2.035626803677906, 52.284304185572807],
            [-2.035240735067557, 52.283637225093543],
            [-2.033902736626947, 52.283137011558409],
            [-2.034407468375246, 52.282817560992186],
            [-2.033895732817389, 52.282624419522648],
            [-2.033324240960405, 52.282879906833863],
            [-2.033123234043414, 52.282636327778619],
            [-2.030474351872929, 52.282529163804917],
            [-2.027623765039434, 52.280995233685594],
            [-2.024826654003751, 52.280367441681946],
            [-2.023262919031725, 52.278085135163614],
            [-2.022908391385779, 52.278418744165229],
            [-2.020922480368775, 52.278712199830771],
            [-2.020027297202382, 52.279539461468914],
            [-2.015058022354325, 52.27850183507207],
            [-2.011700010668499, 52.278352085567207],
            [-2.010361900219644, 52.278728905711986],
            [-2.009161322032292, 52.278045747377767],
            [-2.007977145271412, 52.2786670633783],
            [-2.006316452738702, 52.278517030149629],
            [-2.00502521640152, 52.279071794451909],
            [-2.004770227252131, 52.279512328590883],
            [-2.00394644587817, 52.279234561646604],
            [-2.002467527516613, 52.279568143949241],
            [-2.003917070353408, 52.278590859662948],
            [-2.002499601432636, 52.276791049768008],
            [-2.005411736703605, 52.27566266683592],
            [-2.006664851445593, 52.275673388281447],
            [-2.007051728088278, 52.275372190870335],
            [-2.003205804856544, 52.273617465313876],
            [-2.004215414750618, 52.272008172087773],
            [-2.005837536704867, 52.270578645298656],
            [-2.007169584492009, 52.270238736416488],
            [-2.009803146551939, 52.270890336454222],
            [-2.011551448075451, 52.270865900709929],
            [-2.012165380890674, 52.270509822659101],
            [-2.015537217416133, 52.269987983413159],
            [-2.015397031000399, 52.267236975915374],
            [-2.013247018843818, 52.266113455928959],
            [-2.013994185564705, 52.265665651839882],
            [-2.013239124192756, 52.264243477166218],
            [-2.012241334108964, 52.264410806416414],
            [-2.0120988189992, 52.263026316905261],
            [-2.011247490177505, 52.262870870951666],
            [-2.011248888560881, 52.262612849475886],
            [-2.011362507964467, 52.260065888414097],
            [-2.013341129551885, 52.257618518274761],
            [-2.013771482721407, 52.25645602219624],
            [-2.013117773288058, 52.255388949363194],
            [-2.013334253465116, 52.25429660285068],
            [-2.013732374622877, 52.253221315923639],
            [-2.015421087848112, 52.252358034289223],
            [-2.017282301225428, 52.247060681339534],
            [-2.017893148974681, 52.24365235968687],
            [-2.01891740480302, 52.241457660207537],
            [-2.018871590700388, 52.240493904907417],
            [-2.016746872479406, 52.237261308527877],
            [-2.01570706912896, 52.236969268063888],
            [-2.01513175141286, 52.237471004898147],
            [-2.014422869452238, 52.237088107179304],
            [-2.014481309717574, 52.236683535033087],
            [-2.013087292076234, 52.236813161688325],
            [-2.012953971123427, 52.236593812627504],
            [-2.012046104886722, 52.236687410230928],
            [-2.012050423527358, 52.236418598891653],
            [-2.011193770099134, 52.236353954765526],
            [-2.010958057411522, 52.236532884993672],
            [-2.009525834672942, 52.236087092038566],
            [-2.009077835836134, 52.236490794209459],
            [-2.007964969159328, 52.236657198151313],
            [-2.006431740428677, 52.236256326321616],
            [-2.005860557528725, 52.235574890198556],
            [-1.997494932203312, 52.236175581987396],
            [-1.997407094283107, 52.235759327935455],
            [-1.994663025774009, 52.235257581373453],
            [-1.993514971528017, 52.23557578422642],
            [-1.993088752432908, 52.236280602998413],
            [-1.994029860467746, 52.239847115501888],
            [-1.991330953625769, 52.239460370191217],
            [-1.990379291500304, 52.238406632271541],
            [-1.987103524802204, 52.238053906333647],
            [-1.986292386841885, 52.237609694509374],
            [-1.98524964141646, 52.237947607525086],
            [-1.98352908895977, 52.237667784631952],
            [-1.982427495253334, 52.238649372112732],
            [-1.981620792904456, 52.238188946292311],
            [-1.975087463917037, 52.236744815092528],
            [-1.974502936609781, 52.237190611980004],
            [-1.972934379502077, 52.237565163034311],
            [-1.967592742316152, 52.237011823207233],
            [-1.96621028884563, 52.239090002705396],
            [-1.965007207272561, 52.240101066637898],
            [-1.963212008488082, 52.239825420912645],
            [-1.962725295665573, 52.240445601798498],
            [-1.961393822548523, 52.240759838241864],
            [-1.959558910051252, 52.240671122852895],
            [-1.958636886489878, 52.240025300098999],
            [-1.958585631555019, 52.241607579393857],
            [-1.957888458680765, 52.241679256912228],
            [-1.957613528055714, 52.242806545585445],
            [-1.955037600258916, 52.24405525299337],
            [-1.953002230091615, 52.243667881063963],
            [-1.952831802935946, 52.24417666559583],
            [-1.952481850624341, 52.244096512196478],
            [-1.951269573119005, 52.245071470375379],
            [-1.951668626341174, 52.247156487988619],
            [-1.949781060484684, 52.248087100989629],
            [-1.947432891429468, 52.248275779698325],
            [-1.947285498591237, 52.249056073481732],
            [-1.946395215794032, 52.250038315683561],
            [-1.946675663865172, 52.250701027934795],
            [-1.946063299034881, 52.250763683311007],
            [-1.94606134488752, 52.251169145383699],
            [-1.942228636557613, 52.251736427324332],
            [-1.939517519824307, 52.251512116021267],
            [-1.939345480074009, 52.251993908690636],
            [-1.939104141677565, 52.251728570901392],
            [-1.935869525524771, 52.251901277478808],
            [-1.935968725071046, 52.252187222901874],
            [-1.93461839636405, 52.252003982618156],
            [-1.933676830098811, 52.249808025820322],
            [-1.931925898601775, 52.248229233205926],
            [-1.931248287824589, 52.247867431422648],
            [-1.93191816164082, 52.247539671611513],
            [-1.928189007283624, 52.244821046115014],
            [-1.92670438374876, 52.24273573103892],
            [-1.926222275717589, 52.242900853757313],
            [-1.925439314318087, 52.24255783230398],
            [-1.925203625721869, 52.241622690038767],
            [-1.923929050149743, 52.240169940832821],
            [-1.925451111036296, 52.239001266733169],
            [-1.925204534523837, 52.237583336292133],
            [-1.923872042910147, 52.234095137053004],
            [-1.920952386117192, 52.229915413725919],
            [-1.917528828021691, 52.223811330075094],
            [-1.917300675775726, 52.218949186297692],
            [-1.919778847584695, 52.214045748707349],
            [-1.920125187892617, 52.210250248510008],
            [-1.921839010881887, 52.210197449874393],
            [-1.923183347171671, 52.208841682054796],
            [-1.926783264666073, 52.208914986159471],
            [-1.930346661644128, 52.208014501339491],
            [-1.932099286862697, 52.206436808541362],
            [-1.933662648303692, 52.20609785784611],
            [-1.935641019229138, 52.204108478677469],
            [-1.93597626161194, 52.202979468209989],
            [-1.936573942583824, 52.202499703107122],
            [-1.936484323921216, 52.20069348663398],
            [-1.935673295918273, 52.199979212378778],
            [-1.936590344149375, 52.197121661560708],
            [-1.934848181953583, 52.192879946766411],
            [-1.935763404947914, 52.191228909944897],
            [-1.935860456372549, 52.189857027080478],
            [-1.937716133703179, 52.18820288346415],
            [-1.939122889952938, 52.185278135603156],
            [-1.939782981095873, 52.18169309602434],
            [-1.941358836231962, 52.177806429061064],
            [-1.944582840672417, 52.17475393308515],
            [-1.946188893268614, 52.174391459683136],
            [-1.946693950734021, 52.173916993259851],
            [-1.949416359484183, 52.172942724293357],
            [-1.956115226328372, 52.174138420978373],
            [-1.960682070452034, 52.174012356972412],
            [-1.961676239381933, 52.172516674606285],
            [-1.96197312910044, 52.170724078114908],
            [-1.960455005809657, 52.169497287355604],
            [-1.96041480458106, 52.168659365450928],
            [-1.951376933533723, 52.169178351304595],
            [-1.947223029227881, 52.167934996305853],
            [-1.945553323908721, 52.170343677882201],
            [-1.943482166277361, 52.170922591432841],
            [-1.935490383632018, 52.169544771933118],
            [-1.935350445612945, 52.168235688186414],
            [-1.934561531083513, 52.167812704453034],
            [-1.934964803328987, 52.165979776047983],
            [-1.934126481881282, 52.165381448494458],
            [-1.937306193984783, 52.163396286160022],
            [-1.937743305082892, 52.161298146618648],
            [-1.939700624778066, 52.160303916341775],
            [-1.939534871824609, 52.159639437664623],
            [-1.938969297740615, 52.159530363242297],
            [-1.939234747351899, 52.15889487482692],
            [-1.941989419306494, 52.157257296006577],
            [-1.944310139942165, 52.155320966204009],
            [-1.942134916528305, 52.1543840175231],
            [-1.940084579005893, 52.15302993445399],
            [-1.933617745188552, 52.153515568016054],
            [-1.921895040130344, 52.152994160532138],
            [-1.918388017066125, 52.149340766777584],
            [-1.916886127185938, 52.146703716003984],
            [-1.913440590208099, 52.144971478691609],
            [-1.910894686719242, 52.144366327776474],
            [-1.907331012985889, 52.142724625964007],
            [-1.905108433440401, 52.142724661892295],
            [-1.904365747116724, 52.141527431020641],
            [-1.904192192568861, 52.140007000279113],
            [-1.902196861915597, 52.137710995620353],
            [-1.902436094343734, 52.135877133896741],
            [-1.903561837106315, 52.133496476423751],
            [-1.903246400421323, 52.133435982483213],
            [-1.902612054966817, 52.134241009833772],
            [-1.901268310644004, 52.134739766880742],
            [-1.900224447563516, 52.1357134598084],
            [-1.892912385625828, 52.138597505302158],
            [-1.890586011489363, 52.141692596596272],
            [-1.888261054344301, 52.143505599864852],
            [-1.885126798280536, 52.146840759504762],
            [-1.885016998657664, 52.147485269625662],
            [-1.886300356730566, 52.148543790164524],
            [-1.887065314132115, 52.148299083492745],
            [-1.888385737094777, 52.148588931451187],
            [-1.889737999966729, 52.148419381308173],
            [-1.890483770912813, 52.148839929337136],
            [-1.891412574356714, 52.147918363738569],
            [-1.893338129169647, 52.148175445697142],
            [-1.893847208421526, 52.147965526794643],
            [-1.894862196835518, 52.149510996172239],
            [-1.895053461789642, 52.150838158976129],
            [-1.893515366659541, 52.151076829396644],
            [-1.89386114492386, 52.151948316307696],
            [-1.892075668008933, 52.154142171219341],
            [-1.891874830482821, 52.154992485298578],
            [-1.889111877197873, 52.156336697328165],
            [-1.886535480813059, 52.15610229928506],
            [-1.885485206987954, 52.156404265188399],
            [-1.884300256092087, 52.156212513872561],
            [-1.883835047817295, 52.155798496615191],
            [-1.882665262297089, 52.155963664975395],
            [-1.882133178116111, 52.155419212927697],
            [-1.880907070627685, 52.155328978639787],
            [-1.880648195869012, 52.154840535580448],
            [-1.878908266658295, 52.155045546819196],
            [-1.876162402128761, 52.154826931242035],
            [-1.869084103814813, 52.15315337107657],
            [-1.866936016054319, 52.152995437281284],
            [-1.866115383168989, 52.150291976199938],
            [-1.866810304990738, 52.150055412965443],
            [-1.863886289601501, 52.147025888824125],
            [-1.865733804539055, 52.145951842327719],
            [-1.866971138713063, 52.144627146231855],
            [-1.863275084422857, 52.142833822704162],
            [-1.859716924560545, 52.140496622158075],
            [-1.854197185328157, 52.138508437067756],
            [-1.849845981279502, 52.13937146875346],
            [-1.84834649652095, 52.140334232845198],
            [-1.847303793382176, 52.141875657094239],
            [-1.845819461007445, 52.142641516731985],
            [-1.846917429914229, 52.147173246823144],
            [-1.850979604709504, 52.146458302621781],
            [-1.851912850210752, 52.1479572890348],
            [-1.847571588312188, 52.148621558907891],
            [-1.848239314807942, 52.14996020066431],
            [-1.839681175623064, 52.152700881544021],
            [-1.839327819436488, 52.151790566133279],
            [-1.837529220129962, 52.15042425218185],
            [-1.833552231286077, 52.148124347342254],
            [-1.831013068168238, 52.148294258835726],
            [-1.831478962061258, 52.145661613440865],
            [-1.828683733034384, 52.146332777981563],
            [-1.823357198378566, 52.145912270300187],
            [-1.825313377200349, 52.142697485569279],
            [-1.825452779661867, 52.141799542128567],
            [-1.827854308140063, 52.141232170349042],
            [-1.82462175770081, 52.138711876493566],
            [-1.825096859922972, 52.138278340194127],
            [-1.82450745672699, 52.137679598267809],
            [-1.824863455583043, 52.137436484280649],
            [-1.823226868368803, 52.136369571903799],
            [-1.81732977446869, 52.138583049147762],
            [-1.810898134878224, 52.13990711601199],
            [-1.808443557409764, 52.139862703357778],
            [-1.802355991343983, 52.137623929647738],
            [-1.801530448664669, 52.136970734235561],
            [-1.799679511567259, 52.136263653228632],
            [-1.785558213452901, 52.132045684283547],
            [-1.786280925876246, 52.13183212142814],
            [-1.786592917990479, 52.13135439072083],
            [-1.789935195873726, 52.1304730206421],
            [-1.789218013259986, 52.129831618414748],
            [-1.790255820220962, 52.129390234246415],
            [-1.790282774500695, 52.128938059460616],
            [-1.791689840229326, 52.128559352385906],
            [-1.792780140624178, 52.127811462090882],
            [-1.792247297307829, 52.127109265851423],
            [-1.793135204845958, 52.126527340364184],
            [-1.793328610093183, 52.125451514430459],
            [-1.795775997186678, 52.124334655218249],
            [-1.79482224593901, 52.12432131417124],
            [-1.793923170134631, 52.123854039713848],
            [-1.793898095235736, 52.122955843155637],
            [-1.793001770240729, 52.12284908618112],
            [-1.792880965540288, 52.12212603653348],
            [-1.793328111480222, 52.121765401084559],
            [-1.791014654571534, 52.120180795268546],
            [-1.787779905712466, 52.11949803905344],
            [-1.78063785924422, 52.120130505498523],
            [-1.780709108322175, 52.119003225865413],
            [-1.778432931359736, 52.118267147244417],
            [-1.775115135904484, 52.118191647080877],
            [-1.773102481317893, 52.116778980044423],
            [-1.770599829876203, 52.11611154139046],
            [-1.769710237679734, 52.115301560447755],
            [-1.764642680122351, 52.115872354847596],
            [-1.763233783294787, 52.116353228309578],
            [-1.762470168844302, 52.115219786000644],
            [-1.760296073573768, 52.116237615329467],
            [-1.759420752167489, 52.115523784344532],
            [-1.757669761300756, 52.11606323126081],
            [-1.757409065628995, 52.115931434004082],
            [-1.759217923560355, 52.114950673661653],
            [-1.767659411536878, 52.112579702797383],
            [-1.771023191655964, 52.111860738697509],
            [-1.773942675844078, 52.110618478294136],
            [-1.778383052963537, 52.109466218620369],
            [-1.785695623532252, 52.107025301146358],
            [-1.789427830021654, 52.106362221446162],
            [-1.789678151300052, 52.106224213262081],
            [-1.788269812700154, 52.103621629531276],
            [-1.790145556872185, 52.100578979841096],
            [-1.791734095450504, 52.099598226308302],
            [-1.79555360769611, 52.100760188879789],
            [-1.802299164798672, 52.096929114384885],
            [-1.806469204967129, 52.092656516928663],
            [-1.809802790304479, 52.087548099783596],
            [-1.809197117779234, 52.087204581652337],
            [-1.809370618924196, 52.085873358091312],
            [-1.813131434798029, 52.082741660020297],
            [-1.812292849425629, 52.081922186928551],
            [-1.81274688149302, 52.078732150422169],
            [-1.813953691698723, 52.078714279254697],
            [-1.816137354846585, 52.079600575788113],
            [-1.817827935700867, 52.084781769941728],
            [-1.818953562459486, 52.085392163332706],
            [-1.822475911467909, 52.081127887523976],
            [-1.82979591980052, 52.075257027615926],
            [-1.831809829921334, 52.073203766048302],
            [-1.83176335686699, 52.072757766516986],
            [-1.834475793314936, 52.073099646746371],
            [-1.837646337269552, 52.074263835184475],
            [-1.846629470852704, 52.079409468556811],
            [-1.849194643700301, 52.077267619682623],
            [-1.850893368466383, 52.074543831594745],
            [-1.853398390678233, 52.072355961727006],
            [-1.854374266622008, 52.072857945022619],
            [-1.855616635935079, 52.074429227125734],
            [-1.863188122692563, 52.073763947050864],
            [-1.869054564816997, 52.073845218591565],
            [-1.872014600027986, 52.071905597092396],
            [-1.870188680247819, 52.071668950129862],
            [-1.869591572082048, 52.071305072560158],
            [-1.872927037895508, 52.070150719987517],
            [-1.874415586701941, 52.069979692629062],
            [-1.871691771612012, 52.06541493722159],
            [-1.864997976618408, 52.066266976677646],
            [-1.86288708931858, 52.06342081787993],
            [-1.861412873896752, 52.058299845593034],
            [-1.861823779554065, 52.055822514335972],
            [-1.863458732464306, 52.053406839492894],
            [-1.861560135685555, 52.052878677655784],
            [-1.861280299198806, 52.052354196131134],
            [-1.860506843011356, 52.052531299203004],
            [-1.860252875622308, 52.052133613504516],
            [-1.859324520324272, 52.05241211940011],
            [-1.859179518808923, 52.052147622327901],
            [-1.857334480104374, 52.052236212700286],
            [-1.853851386560104, 52.049749641785297],
            [-1.847823795109398, 52.04652607883208],
            [-1.838898576305681, 52.043695672333918],
            [-1.834598744776232, 52.043722091680763],
            [-1.834849704628403, 52.043277406605441],
            [-1.832954121486796, 52.041753523196128],
            [-1.832416388350941, 52.040122757861234],
            [-1.829761179212405, 52.039060759266157],
            [-1.830136718455371, 52.037676741121253],
            [-1.829422708537578, 52.03720639960666],
            [-1.829096618033589, 52.036319450064347],
            [-1.830636122132964, 52.032821609256473],
            [-1.830570227575853, 52.032128335301067],
            [-1.829329583612977, 52.03179209531249],
            [-1.828961224935704, 52.032063079788458],
            [-1.825032921363325, 52.030922700309887],
            [-1.826248201940308, 52.029135355015477],
            [-1.835669387180585, 52.022492162843761],
            [-1.834131736341942, 52.017009297705378],
            [-1.834417473687131, 52.014601096217625],
            [-1.835677099499161, 52.011221461190836],
            [-1.835318982429557, 52.009152204859539],
            [-1.839060102189144, 52.006773035644997],
            [-1.840034311809618, 52.007297618574079],
            [-1.841317716048273, 52.006907357256345],
            [-1.843290560214887, 52.008077884352296],
            [-1.843895453423297, 52.007994173597922],
            [-1.844485832665753, 52.00831502162837],
            [-1.847263461062772, 52.007647044815954],
            [-1.851079418450181, 52.00839275456763],
            [-1.854811969527311, 52.010191946366511],
            [-1.856764316930186, 52.010634875404683],
            [-1.857115135434798, 52.011190025810663],
            [-1.859894621446521, 52.012727168154143],
            [-1.861553052234788, 52.012617640561544],
            [-1.862028714911063, 52.013347341661749],
            [-1.863803098576758, 52.014406706097205],
            [-1.862278333277922, 52.017475252670266],
            [-1.867305639434048, 52.017954819178108],
            [-1.873582817189991, 52.024318108884103],
            [-1.876442520485696, 52.025275045110583],
            [-1.879315027397697, 52.026936792375892],
            [-1.885023294411063, 52.029490439555325],
            [-1.888019541071049, 52.031451482228853],
            [-1.891342550215916, 52.032819366306349],
            [-1.896440245893229, 52.035895149480105],
            [-1.903248922972949, 52.03888131819528],
            [-1.909117990557367, 52.042650355989331],
            [-1.913454978512731, 52.044450837964533],
            [-1.91569827629597, 52.041686941265745],
            [-1.916693321895566, 52.042088628834613],
            [-1.919148555177714, 52.038846512759193],
            [-1.919744711936589, 52.038909853396532],
            [-1.920059436474909, 52.038189017554259],
            [-1.920537715894352, 52.038130001762525],
            [-1.922166130434872, 52.035604715346722],
            [-1.92320693034453, 52.03563146874567],
            [-1.927053784429958, 52.036650745661902],
            [-1.929630715044985, 52.032285551887036],
            [-1.93107985638749, 52.030232941296582],
            [-1.931739118389213, 52.02994921906825],
            [-1.933772604737768, 52.030832357601454],
            [-1.939923268139535, 52.0322678502353],
            [-1.942253957329713, 52.032350825116204],
            [-1.945821685363405, 52.031659332743288],
            [-1.94752977498237, 52.031884868038695],
            [-1.948012754744398, 52.033979002035196],
            [-1.94919944688849, 52.035171676011501],
            [-1.95043674804303, 52.035472487539074],
            [-1.950851673789957, 52.035964448390118],
            [-1.950533118277834, 52.036656594996181],
            [-1.951041862254563, 52.036682879171302],
            [-1.951396452559879, 52.037715150336972],
            [-1.954031399960828, 52.037088655303464],
            [-1.954229423555752, 52.037324286638615],
            [-1.956709360237092, 52.037136414846699],
            [-1.963596907839252, 52.036116492866128],
            [-1.984144449391184, 52.035869265081786],
            [-1.992587476804046, 52.029278139707408],
            [-1.994001498631314, 52.027810043867568],
            [-1.99394762554267, 52.027384783422967],
            [-1.995198244124856, 52.026553202874666],
            [-1.997566475294624, 52.02988790250938],
            [-1.998573669529942, 52.029657756154549],
            [-2.007694136349246, 52.023875604363155],
            [-2.013432544395624, 52.021337913008388],
            [-2.016985028971519, 52.020246879171971],
            [-2.019651134134975, 52.018683881269183],
            [-2.02366775151478, 52.016943439859219],
            [-2.021667892856187, 52.015644677463861],
            [-2.029279479579572, 52.012829833289011],
            [-2.028091690081708, 52.012334738988393],
            [-2.028191921507274, 52.011858209021511],
            [-2.027393270596551, 52.011505065652351],
            [-2.027005149605197, 52.010578217324898],
            [-2.030853853285777, 52.009760909988053],
            [-2.038260690309348, 52.009552834133324],
            [-2.03795626850612, 52.007930115399844],
            [-2.036790011630123, 52.007006245813379],
            [-2.037161184825066, 52.006622226283596],
            [-2.036844566862326, 52.006033436608597],
            [-2.038364230115114, 52.004590842055649],
            [-2.044962388762602, 52.003639077024879],
            [-2.049522111127875, 52.003786483647303],
            [-2.050097102266411, 52.006009636156477],
            [-2.048691024059689, 52.007105294233078],
            [-2.049307155102968, 52.007003441856881],
            [-2.050167115157322, 52.007382483703118],
            [-2.051564348801801, 52.008743066576777],
            [-2.053220698978628, 52.008627245512557],
            [-2.057202440342348, 52.009812140357255],
            [-2.059301412229286, 52.009464057535418],
            [-2.059718763441436, 52.009967323859989],
            [-2.060246052229195, 52.014198066535918],
            [-2.060791640114935, 52.014670694444],
            [-2.06240425420597, 52.014398329369797],
            [-2.062881451318364, 52.013883808051474],
            [-2.063875565850496, 52.014162880979136],
            [-2.066737717431997, 52.013499574397635],
            [-2.066865291752472, 52.013067949381664],
            [-2.068138805842765, 52.013111271158259],
            [-2.069478340991227, 52.012508110523143],
            [-2.070192484426665, 52.012638949469064],
            [-2.070992505305977, 52.011795142831424],
            [-2.071623996449105, 52.012177762031811],
            [-2.072486966062012, 52.011539793268007],
            [-2.074597100899945, 52.011792008419512],
            [-2.075728738488682, 52.011528756110827],
            [-2.07617372949449, 52.011887196273626],
            [-2.07676780652708, 52.011667436793516],
            [-2.076756514734792, 52.011030904152108],
            [-2.078134886836493, 52.011076746481372],
            [-2.078852794919409, 52.011688531745158],
            [-2.08077870716468, 52.010755788324971],
            [-2.083487798723878, 52.011079356151726],
            [-2.084590265991576, 52.010836717969518],
            [-2.084880960083049, 52.010468789429787],
            [-2.086155617767135, 52.011134969725369],
            [-2.086870244203053, 52.010750541329934],
            [-2.091366317350619, 52.011413339512053],
            [-2.092363709004927, 52.01110328017262],
            [-2.093058202248633, 52.011572045539893],
            [-2.093385402306122, 52.011274194418846],
            [-2.094155098926427, 52.011470475935567],
            [-2.093928482052017, 52.011790725506366],
            [-2.095542474335869, 52.011623995536461],
            [-2.095668538353385, 52.011980822894891],
            [-2.096799358395743, 52.011394603451706],
            [-2.096897573710337, 52.011670536342734],
            [-2.097385382408774, 52.011540667245313],
            [-2.097719541948124, 52.011774147452712],
            [-2.097681539604911, 52.013053552515977],
            [-2.098344929668234, 52.013253491218045],
            [-2.098567064138131, 52.012893678005092],
            [-2.099385827011251, 52.012854328397658],
            [-2.099743529734795, 52.013182185010017],
            [-2.102383692043266, 52.013822747640639],
            [-2.105359398762468, 52.013989142109132],
            [-2.107611977140397, 52.014576889389815],
            [-2.108731727067536, 52.014269276735632],
            [-2.111578687971627, 52.014788966710682],
            [-2.11166166905762, 52.015343612540185],
            [-2.113617616276585, 52.014969524188793],
            [-2.113899333523311, 52.014592542239811],
            [-2.115925436384488, 52.014321740281652],
            [-2.116352970833696, 52.014556873579153],
            [-2.117363165427759, 52.014167472100247],
            [-2.118061650613781, 52.014375356343407],
            [-2.118552979260716, 52.013936116831843],
            [-2.118279981718522, 52.013736798805809],
            [-2.119048820080993, 52.01355351193456],
            [-2.11914265696936, 52.013226156189006],
            [-2.119503286624992, 52.013503602077527],
            [-2.120077914918689, 52.013165866086439],
            [-2.120381373849179, 52.011687488010544],
            [-2.121124801360626, 52.011283042773265],
            [-2.123367476474194, 52.010895010384083],
            [-2.124063476681906, 52.010214582325496],
            [-2.123867652434552, 52.009999911489487],
            [-2.125863859656241, 52.009036687073667],
            [-2.127154033634095, 52.007271326005927],
            [-2.12787500106652, 52.007211205467648],
            [-2.129638025878203, 52.006288628062975],
            [-2.130716537816442, 52.006449266281805],
            [-2.130776747273327, 52.00611474566243],
            [-2.131530735538782, 52.00589722891285],
            [-2.132035977905145, 52.005312267674952],
            [-2.131933004672439, 52.003984458004091],
            [-2.132692114726493, 52.003051268703224],
            [-2.132357588671007, 52.002733375179943],
            [-2.133433405586503, 52.002503795437562],
            [-2.133254680058995, 52.001675055930875],
            [-2.135209373575345, 51.999694870468829],
            [-2.136854331660294, 51.999844010975025],
            [-2.13932774234643, 51.999405956022564],
            [-2.141864934276065, 51.999805705512998],
            [-2.139714852538839, 52.00022994389672],
            [-2.139150921202963, 52.00062800157832],
            [-2.139503336684443, 52.001534745455572],
            [-2.140825748814416, 52.002386386200151],
            [-2.139070894122819, 52.003940268922364],
            [-2.141735816891201, 52.004573627889357],
            [-2.14247880805185, 52.004129488742109],
            [-2.143743125009486, 52.004086592781249],
            [-2.145414706451689, 52.004749848869615],
            [-2.146295461415592, 52.00456984161039],
            [-2.150789176931402, 52.006618538554271],
            [-2.149514657076376, 52.011479632363105],
            [-2.145691183411023, 52.014059362391556],
            [-2.144653483216166, 52.016682324841831],
            [-2.142369103238889, 52.019568421278876],
            [-2.141179600907493, 52.024527308825888],
            [-2.139577766593318, 52.027752372525619],
            [-2.136546467999504, 52.029292434317895],
            [-2.129300887203208, 52.02978342244036],
            [-2.125893613380072, 52.030494684166001],
            [-2.117930217877857, 52.033061680113413],
            [-2.117163621655074, 52.033673810567272],
            [-2.115604755947243, 52.036269154689009],
            [-2.116249359403481, 52.036930230342293],
            [-2.116097245459525, 52.037860012910606],
            [-2.117217601521911, 52.039252450335958],
            [-2.118391446144548, 52.042098608622794],
            [-2.120451695103056, 52.042145065576349],
            [-2.1263216250622, 52.043213287049127],
            [-2.13372452383792, 52.046324851417054],
            [-2.14152906784445, 52.04562613814597],
            [-2.1472251778937, 52.046753764420366],
            [-2.149496048556403, 52.04692172244431],
            [-2.155261190461073, 52.048507394498444],
            [-2.155532375336729, 52.049335073023947],
            [-2.158141247737696, 52.050195594380675],
            [-2.162400408754003, 52.05049637294195],
            [-2.164635520061191, 52.04999428701143],
            [-2.166416782549127, 52.049021692518906],
            [-2.168321441416392, 52.048698916912919],
            [-2.170533087314177, 52.047054942406184],
            [-2.170800173544116, 52.047501389369437],
            [-2.171388047962505, 52.047563468980272],
            [-2.170791595835254, 52.049073862889664],
            [-2.17107383246052, 52.049665036196387],
            [-2.170067656891854, 52.05004859637733],
            [-2.169741322659395, 52.05550997157566],
            [-2.173511931360034, 52.055289596460298],
            [-2.175399132004088, 52.058641197759897],
            [-2.169959663326224, 52.059236266460765],
            [-2.165566767422973, 52.060870728761543],
            [-2.161364701394312, 52.060503455825568],
            [-2.158135013170608, 52.061216300535868],
            [-2.155043503615175, 52.063528303320091],
            [-2.153164418909849, 52.069823284741275],
            [-2.153362189346006, 52.07133794210619],
            [-2.151042582795875, 52.073523857753969],
            [-2.151298795311245, 52.07421670293084],
            [-2.149985318340232, 52.075415031501734],
            [-2.149763675906597, 52.076761204290939],
            [-2.155818156815762, 52.076526780387645],
            [-2.156797637509788, 52.082468252716261],
            [-2.158325489741122, 52.084518755243003],
            [-2.157112066131014, 52.085565984832321],
            [-2.156249778163057, 52.088964668419571],
            [-2.154829785856932, 52.090258489129646],
            [-2.155347130800239, 52.093386522578513],
            [-2.151658696173206, 52.093416506794874],
            [-2.151735275304116, 52.09490434525415],
            [-2.151206667736836, 52.096139428739029],
            [-2.149556039012873, 52.099726070456491],
            [-2.148801008311064, 52.09964071819752],
            [-2.148760447650562, 52.103239684802546],
            [-2.151712741180717, 52.105884529746731],
            [-2.151852379496197, 52.107446006081808],
            [-2.155233461764808, 52.107713109908239],
            [-2.156813798858437, 52.107436803854306],
            [-2.158764535221325, 52.107855845189292],
            [-2.159680414321055, 52.108778833479512],
            [-2.162379249823423, 52.109310078562309],
            [-2.169276323326999, 52.108336577904147],
            [-2.171843330416491, 52.108737433151816],
            [-2.173340682165199, 52.109292651344767],
            [-2.175922225532797, 52.110765068137617],
            [-2.176627346640565, 52.110723555045837],
            [-2.17642528910079, 52.112432059240646],
            [-2.180933785402352, 52.113308986641471],
            [-2.181502277944385, 52.115211406768402],
            [-2.182705860201434, 52.115270680962013],
            [-2.183057890886587, 52.116002862196517],
            [-2.184616919902487, 52.117253707528477],
            [-2.185820804150105, 52.117722018354819],
            [-2.183935816189963, 52.119956425582913],
            [-2.187631357759681, 52.122142483898379],
            [-2.190991419281937, 52.122989383461125],
            [-2.195430557586172, 52.1247298568993],
            [-2.196411082836998, 52.124823525180823],
            [-2.19530207570454, 52.126077748090147],
            [-2.193732347609989, 52.129230615579075],
            [-2.192154443896293, 52.130197882004126],
            [-2.19061904648857, 52.130530328664861],
            [-2.188095386020891, 52.130006643614401],
            [-2.18571263149143, 52.130330487141741],
            [-2.182532701803372, 52.132202786376553],
            [-2.177270421767409, 52.134395516645846],
            [-2.17448263342181, 52.135086554127383],
            [-2.172844501956424, 52.134999963973321],
            [-2.173555895685384, 52.136846464438698],
            [-2.172435001045836, 52.137914383504203],
            [-2.171744800771928, 52.140424637878048],
            [-2.16923870971653, 52.140769005530203],
            [-2.167749140908395, 52.141772676983422],
            [-2.164926985025518, 52.142817761402156],
            [-2.167885382069166, 52.144192721055902],
            [-2.166696711976031, 52.145588833182806],
            [-2.16946880896504, 52.144794613464427],
            [-2.180135281002058, 52.14557174247706],
            [-2.186034341373669, 52.145467273631077],
            [-2.189580423346646, 52.146008240788028],
            [-2.194614427700811, 52.151268451424734],
            [-2.19596802303059, 52.154972079791158],
            [-2.196872218263243, 52.155834556175776],
            [-2.196289648383162, 52.158636058280827],
            [-2.197243327166218, 52.162403266442553],
            [-2.199713725812769, 52.164635930083406],
            [-2.201694289478114, 52.167126507769105],
            [-2.20224367302015, 52.167371005319119],
            [-2.203376477732225, 52.167305223182538],
            [-2.199827670162567, 52.168201350055064],
            [-2.200291365446524, 52.169223675717561],
            [-2.200029314117245, 52.169475853427159],
            [-2.196755245126795, 52.169730401852],
            [-2.196824136134548, 52.1700997941171],
            [-2.195272476205183, 52.17036309895871],
            [-2.191688172389899, 52.170240421766501],
            [-2.19100522465962, 52.170895136255403],
            [-2.189642108924801, 52.170467595071756],
            [-2.188623769785417, 52.171000565090921],
            [-2.186728103774625, 52.172585003601924],
            [-2.186357423803669, 52.174157119327141],
            [-2.186339862395228, 52.174503278963051],
            [-2.186995189382448, 52.17455438520097],
            [-2.188497017778286, 52.175600276931661],
            [-2.186062391587503, 52.176691090550086],
            [-2.187381766944298, 52.178155339231445],
            [-2.184951481764739, 52.178570941096638],
            [-2.184866410217137, 52.179919639137736],
            [-2.182721734426663, 52.181892787401139],
            [-2.182879400128972, 52.182890479966474],
            [-2.179682358843022, 52.183654190753465],
            [-2.179822885164894, 52.1840450597361],
            [-2.171157349372804, 52.184547945276179],
            [-2.168524651339509, 52.191898707399645],
            [-2.167416687020062, 52.196413470458708],
            [-2.164982775035982, 52.200114654799926],
            [-2.164885208064508, 52.200239757881157],
            [-2.162637593434269, 52.203381428087916],
            [-2.158435481097954, 52.212104229485369],
            [-2.157371847356084, 52.212619005391453],
            [-2.157793115079856, 52.213377229655997],
            [-2.158991156890013, 52.213610267945306],
            [-2.159705610936944, 52.213264970384728],
            [-2.162697449522783, 52.214143732131298],
            [-2.167638183200501, 52.216367318388883],
            [-2.168300085341346, 52.216838369405252],
            [-2.171136947645858, 52.217656902666157],
            [-2.188883465911147, 52.221188185167087],
            [-2.194252580474636, 52.223478295451308],
            [-2.196485821851939, 52.22365350227598],
            [-2.209161506054919, 52.224815723874421],
            [-2.211148741953147, 52.22556827085544],
            [-2.218572323863824, 52.22968221585171],
            [-2.221217811659115, 52.230824451316657],
            [-2.2215808226141, 52.231391960133415],
            [-2.222478249157764, 52.231369592325009],
            [-2.226734236643499, 52.227853446708529],
            [-2.228431461991744, 52.224793446799488],
            [-2.233483771977802, 52.226782108125271],
            [-2.235068868952321, 52.225314427996061],
            [-2.234961909609082, 52.223635240444139],
            [-2.235536419360191, 52.222100336909762],
            [-2.235862629702154, 52.222332535399474],
            [-2.23794053700709, 52.222183615257308],
            [-2.238019627460629, 52.222464854255733],
            [-2.239900534143132, 52.222702883137579],
            [-2.239959772109129, 52.221472879631385],
            [-2.243275937570488, 52.221840982461387],
            [-2.240796423648065, 52.22540446101425],
            [-2.241743705918405, 52.227305783167367],
            [-2.242216912358944, 52.230052266902234],
            [-2.241800456422703, 52.232087639905252],
            [-2.240646376646013, 52.232574581092607],
            [-2.240834679097658, 52.233278141526284],
            [-2.23959172719013, 52.234399073714052],
            [-2.236001008673515, 52.235165999774772],
            [-2.233021158016083, 52.236561834321911],
            [-2.232642926522117, 52.237039069654351],
            [-2.233633233246264, 52.238507932510053],
            [-2.235356230731272, 52.24005174457033],
            [-2.235753799301654, 52.241564025152776],
            [-2.233375440002004, 52.245747465388398],
            [-2.231629676769844, 52.247732376851609],
            [-2.231324825054334, 52.248820805298656],
            [-2.232077282367094, 52.250141804332863],
            [-2.233882935103991, 52.251172124814495],
            [-2.234917829923648, 52.252694827155835],
            [-2.235618519326804, 52.252785130653443],
            [-2.236388909183378, 52.25386062982205],
            [-2.237333015523375, 52.255637019402627],
            [-2.238135585802862, 52.256139758601009],
            [-2.237922390309299, 52.257089566600989],
            [-2.244109934570127, 52.260160601068392],
            [-2.248141339766841, 52.26398475889237],
            [-2.251897759526787, 52.266742215467822],
            [-2.256129373063476, 52.267962976925446],
            [-2.26049352819453, 52.268256393666185],
            [-2.26515653213461, 52.268033833250257],
            [-2.267169929045442, 52.268271129719402],
            [-2.271526283181227, 52.269668166033561],
            [-2.274815856670776, 52.271508955320868],
            [-2.275115207518592, 52.272754313484846],
            [-2.274412383604147, 52.275702071284243],
            [-2.270930661924943, 52.280127049054165],
            [-2.270393074230363, 52.282831661631043],
            [-2.271033191247724, 52.28611524210649],
            [-2.274903331915873, 52.292733919655525],
            [-2.274821253073686, 52.295578631771981],
            [-2.275715487524354, 52.297905024239917],
            [-2.276050681717082, 52.301342116099754],
            [-2.274986594263921, 52.304982956581185],
            [-2.2748938799695, 52.30871682299918],
            [-2.273843228059952, 52.311251817393959],
            [-2.265291176025271, 52.319492022089065],
            [-2.265153333932844, 52.320973921218624],
            [-2.266419066145366, 52.323591721051734],
            [-2.265083329987337, 52.323251302169815],
            [-2.264645489835109, 52.323394330249471],
            [-2.264401637800033, 52.325557923739424],
            [-2.262881207219419, 52.325750114561288],
            [-2.26115649526545, 52.326386854704097],
            [-2.260372413840885, 52.328292714565272],
            [-2.261950784138483, 52.329445363502394],
            [-2.265552899174695, 52.330116974286916],
            [-2.269378688082185, 52.331838021613009],
            [-2.270345983378141, 52.332114506625672],
            [-2.270859605885713, 52.331634150942371],
            [-2.2717798850293, 52.332618261601098],
            [-2.26796819579512, 52.333739964112844],
            [-2.267833106636293, 52.333484948841352],
            [-2.266837546108727, 52.333634643014022],
            [-2.267305931768778, 52.334160409328106],
            [-2.267974643295077, 52.334077981234032],
            [-2.269718777742868, 52.33515193441302],
            [-2.269216308824533, 52.336268765767514],
            [-2.268478394124068, 52.336077157060927],
            [-2.268064944610878, 52.336631893192433],
            [-2.266333265034522, 52.335923789024477],
            [-2.264079056180416, 52.335934247827673],
            [-2.263308074790831, 52.336841283483452],
            [-2.263099969122422, 52.338127346115137],
            [-2.261797713549317, 52.338058319940203],
            [-2.260737148574003, 52.338405891000633],
            [-2.260847816210211, 52.339004393663046],
            [-2.261725044124644, 52.339669524503293],
            [-2.261227377291319, 52.340647861152299],
            [-2.261982806579454, 52.340815202638133],
            [-2.25701938606667, 52.341682879540059],
            [-2.254005634799315, 52.341114030823761],
            [-2.252017652779006, 52.344363750554137],
            [-2.249517768715489, 52.345658253992703],
            [-2.251685509740916, 52.345843344851808],
            [-2.249803643979224, 52.348974127721078],
            [-2.24944765421956, 52.348846320938229],
            [-2.245825618417353, 52.353012774896733],
            [-2.246766517072544, 52.353238263195017],
            [-2.245706944402105, 52.354909049182055],
            [-2.24826473519696, 52.35544940113018],
            [-2.245688024749888, 52.361022401167098],
            [-2.242965862756887, 52.360819463159132],
            [-2.2416107811603, 52.360335876527095],
            [-2.233491252076968, 52.361063336684389],
            [-2.230047752475667, 52.361069198359985],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000239",
        LAD13CDO: "47UG",
        LAD13NM: "Wyre Forest",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.269509188310204, 52.438601789428475],
            [-2.268536487441853, 52.438544669853037],
            [-2.267817041513752, 52.439011088188259],
            [-2.266681089283439, 52.439204246540271],
            [-2.26476686662215, 52.440366469258791],
            [-2.262735749686704, 52.440691048790384],
            [-2.262401716662172, 52.439438579092652],
            [-2.261931760543936, 52.439322751305546],
            [-2.261507137556886, 52.436926945655962],
            [-2.260228983389825, 52.437197671194014],
            [-2.256096622209044, 52.437135679416691],
            [-2.254281289806674, 52.437622365896758],
            [-2.248833468688866, 52.437758028180447],
            [-2.244004920114738, 52.437363547521535],
            [-2.242140653806568, 52.436732686125517],
            [-2.240555922185265, 52.436371826808347],
            [-2.226751856793331, 52.435111755256784],
            [-2.219213334598799, 52.435096318313789],
            [-2.217881957979963, 52.43535409880127],
            [-2.2166423718034, 52.434537385820775],
            [-2.217352500029065, 52.433876212002659],
            [-2.215999569026554, 52.433331200012567],
            [-2.215689229377572, 52.432129797892109],
            [-2.214677457719632, 52.431858343662746],
            [-2.212116384360727, 52.432693650979935],
            [-2.211356841638476, 52.430420530495226],
            [-2.210432376851967, 52.429938516488157],
            [-2.20859727051455, 52.429396974696296],
            [-2.206446003599906, 52.429483462992316],
            [-2.20370334220458, 52.428581129086169],
            [-2.202643512930025, 52.427738786438979],
            [-2.200842033312767, 52.427112560034118],
            [-2.197000561877112, 52.427741141963686],
            [-2.196331769521952, 52.427161498332268],
            [-2.196952876221438, 52.425624064010407],
            [-2.196350730927369, 52.424812365696752],
            [-2.192880827009054, 52.42327808730203],
            [-2.191391404947542, 52.423667083080524],
            [-2.189051125527065, 52.425229728454099],
            [-2.185814021115918, 52.426195903104151],
            [-2.184033343360382, 52.425901120889776],
            [-2.181705624646126, 52.424891546669954],
            [-2.180696376638203, 52.425144816881371],
            [-2.172047389888291, 52.425020187935552],
            [-2.167711767598083, 52.42376602056197],
            [-2.164675459095311, 52.42149581061787],
            [-2.163933186469546, 52.419204378700982],
            [-2.163957826422778, 52.417129438202849],
            [-2.163246943495569, 52.416139718280021],
            [-2.162580431550253, 52.415612022227151],
            [-2.162020782728427, 52.416154892569466],
            [-2.160326664000953, 52.416462867699622],
            [-2.158268924578773, 52.409269992933446],
            [-2.154470633712375, 52.409784761866213],
            [-2.145740581364534, 52.409553132349629],
            [-2.144260234663378, 52.410005353704634],
            [-2.14450144907132, 52.411389529584703],
            [-2.13404109742666, 52.4102862061794],
            [-2.128501192685532, 52.406037367251436],
            [-2.127522231152357, 52.405027944645944],
            [-2.127738557362899, 52.404606075956288],
            [-2.126253133137531, 52.403289729096663],
            [-2.127238058244016, 52.402822085825385],
            [-2.126028159105901, 52.402230039003086],
            [-2.129241256104829, 52.400747694350891],
            [-2.130978842997436, 52.399368497056912],
            [-2.131356796599559, 52.399447189656172],
            [-2.132039358061598, 52.398176128336743],
            [-2.135187106361464, 52.398551945623332],
            [-2.137861284005225, 52.395511998686182],
            [-2.136197292448295, 52.395379978704],
            [-2.137706311141891, 52.393884972450387],
            [-2.133400336672868, 52.391868945456565],
            [-2.133404894664172, 52.391432021478593],
            [-2.132073158116321, 52.390314252278202],
            [-2.13225587443253, 52.389993100974372],
            [-2.129901252194635, 52.388782952226883],
            [-2.131466363405603, 52.387872319008771],
            [-2.13252656526053, 52.386181891865668],
            [-2.131123915465357, 52.385367157362694],
            [-2.12845213842706, 52.38210848451709],
            [-2.12435737525763, 52.374197072498745],
            [-2.123140678213746, 52.372979287958323],
            [-2.122095514162809, 52.366838316680017],
            [-2.122501318543664, 52.366473795336141],
            [-2.121872239430599, 52.366291048297619],
            [-2.120101740490578, 52.363834061687676],
            [-2.120191416056708, 52.363328724397874],
            [-2.120905712249714, 52.363007945187569],
            [-2.121218086114231, 52.361248253458989],
            [-2.120972523762205, 52.360591326003139],
            [-2.119428443825057, 52.359226397590604],
            [-2.119219222522349, 52.357874490938059],
            [-2.121430279415738, 52.358843170945832],
            [-2.122415378186238, 52.35877652438208],
            [-2.124319776388089, 52.359267192302333],
            [-2.125806966387032, 52.358647991993998],
            [-2.128550810528535, 52.358459836058245],
            [-2.130764967157595, 52.35791529588824],
            [-2.131251113672447, 52.356472735541843],
            [-2.132432693332705, 52.355357534659468],
            [-2.132471658042528, 52.354640975525946],
            [-2.134120731272874, 52.353267213837299],
            [-2.135347437145614, 52.353036563474568],
            [-2.135532358356083, 52.35157634919284],
            [-2.135861720410189, 52.351738692166023],
            [-2.135972860034729, 52.351118242977641],
            [-2.137174614294815, 52.349965211599738],
            [-2.139245406506605, 52.348350848493538],
            [-2.142049539713499, 52.347952836413981],
            [-2.143134858901711, 52.346731558569189],
            [-2.146264024858578, 52.345228159443941],
            [-2.148509418974084, 52.346379691754535],
            [-2.148864378344018, 52.346293837713269],
            [-2.150967804497329, 52.343663337067085],
            [-2.151961538601054, 52.341520601107931],
            [-2.152594998701963, 52.338783172554436],
            [-2.151931298194458, 52.336987790651158],
            [-2.154386498798947, 52.335250395738022],
            [-2.155039341997354, 52.333925284645154],
            [-2.156239303545392, 52.333368107552474],
            [-2.156511729219294, 52.332801364344363],
            [-2.156074058794619, 52.331037169390648],
            [-2.157611655315223, 52.330952415050724],
            [-2.159914360147879, 52.330213018700753],
            [-2.163289754210466, 52.327508637828743],
            [-2.164481396579907, 52.325971455738063],
            [-2.167646433877477, 52.324444059299502],
            [-2.167522303927293, 52.320332114128163],
            [-2.167907175979272, 52.319300389482294],
            [-2.167461349105008, 52.318567421805646],
            [-2.166804850943728, 52.318374163400122],
            [-2.169086151525917, 52.317234551881796],
            [-2.173054543072565, 52.317271949535595],
            [-2.17454398498765, 52.316636843634804],
            [-2.175312089498482, 52.31982273617605],
            [-2.174707454958688, 52.32460912198114],
            [-2.173160398817509, 52.327838886136945],
            [-2.172081668650004, 52.328536306151257],
            [-2.170806448169892, 52.331561562872018],
            [-2.173659878650135, 52.331728210697186],
            [-2.174286496370371, 52.331355991555668],
            [-2.17525208666464, 52.331354561105691],
            [-2.177945012893757, 52.331748795729098],
            [-2.179222660770369, 52.331255098009628],
            [-2.18123805848582, 52.331034456422103],
            [-2.182790673269029, 52.330333522741128],
            [-2.185009783593438, 52.330064852894303],
            [-2.187267411087181, 52.329194635803645],
            [-2.190469570636354, 52.32892160181742],
            [-2.190470974111782, 52.331977368308223],
            [-2.192489421492199, 52.333476354438581],
            [-2.192630825790297, 52.334607989112079],
            [-2.193465942122373, 52.3352943725437],
            [-2.193151008311015, 52.336104904761221],
            [-2.192804106266632, 52.335980508450582],
            [-2.192486386352046, 52.336491670011696],
            [-2.192777415937053, 52.336929017152919],
            [-2.192163803553851, 52.336898552389243],
            [-2.191948653992925, 52.337374483592491],
            [-2.193016656219559, 52.337941820349478],
            [-2.193052963303622, 52.338863255174338],
            [-2.193638768102525, 52.338905448207512],
            [-2.193345901244249, 52.339060559654627],
            [-2.19392089877774, 52.339985605251051],
            [-2.195208273922671, 52.340352979188751],
            [-2.19558511998322, 52.341265757891946],
            [-2.200938591920025, 52.344265777381047],
            [-2.202933271845805, 52.345183857548584],
            [-2.20502708664007, 52.345618959230137],
            [-2.204669959617457, 52.347751149958405],
            [-2.205315046266699, 52.350734766778004],
            [-2.20745340355531, 52.351528455532879],
            [-2.207866697327034, 52.353250244575285],
            [-2.210597333451846, 52.354440196950208],
            [-2.210200568696634, 52.354986607237677],
            [-2.21181574840506, 52.355927686926329],
            [-2.220083059738801, 52.359348601401322],
            [-2.230047752475667, 52.361069198359985],
            [-2.233491252076968, 52.361063336684389],
            [-2.2416107811603, 52.360335876527095],
            [-2.242965862756887, 52.360819463159132],
            [-2.245688024749888, 52.361022401167098],
            [-2.24826473519696, 52.35544940113018],
            [-2.245706944402105, 52.354909049182055],
            [-2.246766517072544, 52.353238263195017],
            [-2.245825618417353, 52.353012774896733],
            [-2.24944765421956, 52.348846320938229],
            [-2.249803643979224, 52.348974127721078],
            [-2.251685509740916, 52.345843344851808],
            [-2.249517768715489, 52.345658253992703],
            [-2.252017652779006, 52.344363750554137],
            [-2.254005634799315, 52.341114030823761],
            [-2.25701938606667, 52.341682879540059],
            [-2.261982806579454, 52.340815202638133],
            [-2.261227377291319, 52.340647861152299],
            [-2.261725044124644, 52.339669524503293],
            [-2.260847816210211, 52.339004393663046],
            [-2.260737148574003, 52.338405891000633],
            [-2.261797713549317, 52.338058319940203],
            [-2.263099969122422, 52.338127346115137],
            [-2.263308074790831, 52.336841283483452],
            [-2.264079056180416, 52.335934247827673],
            [-2.266333265034522, 52.335923789024477],
            [-2.268064944610878, 52.336631893192433],
            [-2.268478394124068, 52.336077157060927],
            [-2.269216308824533, 52.336268765767514],
            [-2.269718777742868, 52.33515193441302],
            [-2.267974643295077, 52.334077981234032],
            [-2.267305931768778, 52.334160409328106],
            [-2.266837546108727, 52.333634643014022],
            [-2.267833106636293, 52.333484948841352],
            [-2.26796819579512, 52.333739964112844],
            [-2.2717798850293, 52.332618261601098],
            [-2.270859605885713, 52.331634150942371],
            [-2.270345983378141, 52.332114506625672],
            [-2.269378688082185, 52.331838021613009],
            [-2.265552899174695, 52.330116974286916],
            [-2.261950784138483, 52.329445363502394],
            [-2.260372413840885, 52.328292714565272],
            [-2.26115649526545, 52.326386854704097],
            [-2.262881207219419, 52.325750114561288],
            [-2.264401637800033, 52.325557923739424],
            [-2.264645489835109, 52.323394330249471],
            [-2.265083329987337, 52.323251302169815],
            [-2.266419066145366, 52.323591721051734],
            [-2.271941751331162, 52.326952256322073],
            [-2.273893712556939, 52.328918398422907],
            [-2.27565773574502, 52.328717406215652],
            [-2.275535935382215, 52.328008362541645],
            [-2.276918712953195, 52.327387498600316],
            [-2.277914288225292, 52.327735777204282],
            [-2.279870750658605, 52.326882477870143],
            [-2.287069680882461, 52.325785463699638],
            [-2.29136438130578, 52.32466105200043],
            [-2.290278750241902, 52.32311381184352],
            [-2.294582764955983, 52.321896660028095],
            [-2.296579470464772, 52.323197041231651],
            [-2.299167745189875, 52.322346327057303],
            [-2.303296106696659, 52.328094930676187],
            [-2.302711405891576, 52.32842367559131],
            [-2.304529164308778, 52.329230817189696],
            [-2.304674410068718, 52.330934088991341],
            [-2.305255502242611, 52.331143857932638],
            [-2.305290748327451, 52.331999635474553],
            [-2.303880411430915, 52.332844758542812],
            [-2.30615407065723, 52.333549110890687],
            [-2.305334296854522, 52.335124521328105],
            [-2.308646281026564, 52.335279530625868],
            [-2.307084709603909, 52.336120591617195],
            [-2.304829403978181, 52.335758736984886],
            [-2.305267403555685, 52.337769614062573],
            [-2.306415997251754, 52.339167312721244],
            [-2.309089557138115, 52.339705149824397],
            [-2.310126270688147, 52.340402768074554],
            [-2.310935952341508, 52.340536390491273],
            [-2.312710842900561, 52.339961723614223],
            [-2.314402136121418, 52.33981608549837],
            [-2.315682022658803, 52.338581011694565],
            [-2.318220242569978, 52.337430642729302],
            [-2.320881817407415, 52.336916390740996],
            [-2.323881405365231, 52.335268384809517],
            [-2.32445541956179, 52.333689924605679],
            [-2.323408462813831, 52.333407807379743],
            [-2.32196089815292, 52.332118071479762],
            [-2.319684867208848, 52.331516517889497],
            [-2.321965838391836, 52.330172574057435],
            [-2.323332431500657, 52.327413334938875],
            [-2.324478705111489, 52.326649616485199],
            [-2.324664021501737, 52.325306865619261],
            [-2.325692332088419, 52.324274651446295],
            [-2.323892753156796, 52.323771654601344],
            [-2.324174838448376, 52.323221577005363],
            [-2.328407364233581, 52.320598214058201],
            [-2.329047173080117, 52.320222438369974],
            [-2.331261439857657, 52.31988360646799],
            [-2.33204446583633, 52.319242200338451],
            [-2.33646927319157, 52.31867677101144],
            [-2.339695206521664, 52.320000785036534],
            [-2.345489511072741, 52.320470341255088],
            [-2.34735492998687, 52.32018706853237],
            [-2.352130094220333, 52.319229844465816],
            [-2.352825586778233, 52.318504951926307],
            [-2.358197431829498, 52.316264586200298],
            [-2.359459098267109, 52.315368018918882],
            [-2.36328641972608, 52.314837564737616],
            [-2.36582412307601, 52.317849554285402],
            [-2.366840814239636, 52.318031598517585],
            [-2.369250835231606, 52.317288682133935],
            [-2.371241894255911, 52.31732738880914],
            [-2.373201386766213, 52.3197153148664],
            [-2.37224025532158, 52.320217308259402],
            [-2.371974188190228, 52.320854656582171],
            [-2.372344377264622, 52.321609569518223],
            [-2.371360926078453, 52.322255469877888],
            [-2.371327526397818, 52.322997270263699],
            [-2.370350469303266, 52.323711467749213],
            [-2.370208029321921, 52.325629533330734],
            [-2.36912694488373, 52.326547225862896],
            [-2.369820103999891, 52.326793187234536],
            [-2.371750395110454, 52.326533600173562],
            [-2.374800555343272, 52.325099003082073],
            [-2.375844579682887, 52.325192778697534],
            [-2.379443655743087, 52.324147388080512],
            [-2.381065767827077, 52.324241955258202],
            [-2.385242584118369, 52.322673985899876],
            [-2.387577181398024, 52.322372363601396],
            [-2.389540354497548, 52.321213352076647],
            [-2.394556434932227, 52.32186377176],
            [-2.397791254160109, 52.324939270415157],
            [-2.403013277026206, 52.326716913615464],
            [-2.40341573273508, 52.324021161022422],
            [-2.412238771979464, 52.324869032081473],
            [-2.413366759363957, 52.325462041136603],
            [-2.414159856515694, 52.325072680859996],
            [-2.414225536591875, 52.32456450105888],
            [-2.415090995684828, 52.323914163115532],
            [-2.415572070038569, 52.322486615966135],
            [-2.417484488490643, 52.322402552852232],
            [-2.41815378113091, 52.322896446621556],
            [-2.419615731399096, 52.323116915318082],
            [-2.419946067860748, 52.32282985058383],
            [-2.424077610892275, 52.322667642772586],
            [-2.427168607138483, 52.32186715496583],
            [-2.428477972258358, 52.32239013883266],
            [-2.431469504896583, 52.32283684881191],
            [-2.434087898693324, 52.322630364372564],
            [-2.436513585720648, 52.324014013089013],
            [-2.434907734931301, 52.326635205718311],
            [-2.433398848375544, 52.327486737043188],
            [-2.433657508864326, 52.328578103370589],
            [-2.43307165232938, 52.329891931588513],
            [-2.428566891129529, 52.3305250990947],
            [-2.426100084432055, 52.33235455201249],
            [-2.420995318027302, 52.333047131275805],
            [-2.421191235126225, 52.333587645265027],
            [-2.423298288671611, 52.335240608363719],
            [-2.423672117237354, 52.336118513223383],
            [-2.424952847337843, 52.336676700118247],
            [-2.422727353990287, 52.338621185381903],
            [-2.420695934587147, 52.34232072871297],
            [-2.421528878835724, 52.343317471871828],
            [-2.420728958189339, 52.343634083502209],
            [-2.420523905232949, 52.344145458804867],
            [-2.420964686183729, 52.345578728428762],
            [-2.422082950990433, 52.346932260701003],
            [-2.422071965404769, 52.349014435305811],
            [-2.419528196803484, 52.351379835326071],
            [-2.417583764064852, 52.352093357555994],
            [-2.414021932061878, 52.354756212363768],
            [-2.413998586327768, 52.355399993133716],
            [-2.411756542207582, 52.357769558245636],
            [-2.411684724702595, 52.358889088869645],
            [-2.412077558898365, 52.358972226791415],
            [-2.411792186482683, 52.359549494050817],
            [-2.412254468685145, 52.359993795295182],
            [-2.411970698951309, 52.360112557089749],
            [-2.412587889270224, 52.360160747755053],
            [-2.412698507395436, 52.360685388954465],
            [-2.413316881101264, 52.360702105986199],
            [-2.413355959578611, 52.36127014982192],
            [-2.41499896879562, 52.362814298329198],
            [-2.416158293524356, 52.364602864233504],
            [-2.415072969725897, 52.366477542447306],
            [-2.414928014964902, 52.367307846365108],
            [-2.415598618396604, 52.367559012043408],
            [-2.414926483101428, 52.368241033410357],
            [-2.414025630165868, 52.368340389585136],
            [-2.413954352880201, 52.368728116511903],
            [-2.413351283320314, 52.36863223547202],
            [-2.412093680972637, 52.369537446515402],
            [-2.410400153090216, 52.370005438400518],
            [-2.409923953283539, 52.370604939257383],
            [-2.410447895088413, 52.371035547593209],
            [-2.410021919934863, 52.371349885408975],
            [-2.410400349201921, 52.371451958267123],
            [-2.409626600095573, 52.372596397157103],
            [-2.410678724704043, 52.373592451839706],
            [-2.410323429586366, 52.374086348387856],
            [-2.411658916592167, 52.375535413444936],
            [-2.412944860902163, 52.376073931601816],
            [-2.413076307232164, 52.37677920080499],
            [-2.414800905781565, 52.377877150856698],
            [-2.415162257044705, 52.378969985505812],
            [-2.414730956330279, 52.381228033360237],
            [-2.413377616381655, 52.381985253981291],
            [-2.412394186246954, 52.383658165025061],
            [-2.411487535910994, 52.384116228919105],
            [-2.411769358239344, 52.38440293214871],
            [-2.411003956484016, 52.385043000111772],
            [-2.411116720086547, 52.38610973987403],
            [-2.410550121955505, 52.387118608179577],
            [-2.408627182171559, 52.387003908088403],
            [-2.407248408357662, 52.386146512989747],
            [-2.407428248568206, 52.385253169994989],
            [-2.406841710884493, 52.383942627867505],
            [-2.405381951068419, 52.383051327002057],
            [-2.402878606602517, 52.382746134416749],
            [-2.402236255494365, 52.383034210577307],
            [-2.401378398896738, 52.38256514495982],
            [-2.393597429351133, 52.384356994356459],
            [-2.392464736277145, 52.383704481407214],
            [-2.392043424277505, 52.38258390848582],
            [-2.390676456950076, 52.381514113078865],
            [-2.389182984208134, 52.382088120177919],
            [-2.388853123109476, 52.38266907180477],
            [-2.389300025079872, 52.383200716272995],
            [-2.387445303250969, 52.384474426313758],
            [-2.382646663501232, 52.384122370455287],
            [-2.377914680984008, 52.383136999701897],
            [-2.375642046869845, 52.384718424612529],
            [-2.374965354243602, 52.384966005723662],
            [-2.374488773053973, 52.384728379510157],
            [-2.372837470758977, 52.385266719007916],
            [-2.372480705422977, 52.385821637800063],
            [-2.371671288190833, 52.385840369576492],
            [-2.370848868705911, 52.386586439706555],
            [-2.370050315903907, 52.386676148426886],
            [-2.367355251819855, 52.388049264637331],
            [-2.36305518283994, 52.388138975025328],
            [-2.361337538792995, 52.38706452521204],
            [-2.360036186155919, 52.38676103581389],
            [-2.359373051988132, 52.38688352308592],
            [-2.354536015674745, 52.388893951550507],
            [-2.352636334252354, 52.388923012233633],
            [-2.350640142264976, 52.387714386491368],
            [-2.350149681164552, 52.385711039400704],
            [-2.349232958059551, 52.385538445481629],
            [-2.346579999493062, 52.385983180356014],
            [-2.343527857337163, 52.385927367250567],
            [-2.342458054711819, 52.385526816289676],
            [-2.342073504586352, 52.384818608231789],
            [-2.339548997139397, 52.384391668342992],
            [-2.338596343314797, 52.385082149057752],
            [-2.337239042971344, 52.385505871748862],
            [-2.335536320661269, 52.385333615139956],
            [-2.333586330770062, 52.38821868001412],
            [-2.334229537347888, 52.38894686086352],
            [-2.342185095242324, 52.393163830059464],
            [-2.343434969496274, 52.39307479663951],
            [-2.345975585119017, 52.393801876761593],
            [-2.347394237127944, 52.39368353864635],
            [-2.351575469270961, 52.395949267860026],
            [-2.357157725350594, 52.39784831934066],
            [-2.357333089386124, 52.398270325045871],
            [-2.359507672993291, 52.39838149818722],
            [-2.361315813360745, 52.398971128428911],
            [-2.364269156592501, 52.399231762868375],
            [-2.369964156121473, 52.40149126517273],
            [-2.372948789398302, 52.401950266749502],
            [-2.368897078699459, 52.404132753625333],
            [-2.366710765297629, 52.406542169228324],
            [-2.365797513930491, 52.408447308715097],
            [-2.365938798774414, 52.409176868429981],
            [-2.367225773994583, 52.410496219929598],
            [-2.366674623421146, 52.412107161336685],
            [-2.36766304979017, 52.414285089812836],
            [-2.366496221333072, 52.416476007042412],
            [-2.36468370013616, 52.417766300444889],
            [-2.365931629242603, 52.419138826455487],
            [-2.365182830365218, 52.4217617558892],
            [-2.366204779314509, 52.422289907714045],
            [-2.366562381372948, 52.423031380586686],
            [-2.363930730652239, 52.424529172852473],
            [-2.363832142783121, 52.425949910343739],
            [-2.362768826654979, 52.425763488585972],
            [-2.364159360220324, 52.431924610216782],
            [-2.363300702247548, 52.437144192818167],
            [-2.363531198540515, 52.4394116781937],
            [-2.36094031383586, 52.439729776569713],
            [-2.359729618589298, 52.439549171575855],
            [-2.355355265232971, 52.439806935193118],
            [-2.352779251213912, 52.438682803743696],
            [-2.350784360333675, 52.438292279989746],
            [-2.345112752512396, 52.439318562647728],
            [-2.343942088069624, 52.438790662813688],
            [-2.342745545349847, 52.437578682606478],
            [-2.340620127195904, 52.437030140862063],
            [-2.338779402228162, 52.435811884274251],
            [-2.337071996541217, 52.436629468561556],
            [-2.335372229651886, 52.435943867707479],
            [-2.3338242277652, 52.435869138195429],
            [-2.333123011952588, 52.435340703153507],
            [-2.329118568818766, 52.437433123241384],
            [-2.325959289167145, 52.438286945409303],
            [-2.325189901359023, 52.437482656796661],
            [-2.321554584305306, 52.436205228099844],
            [-2.311754047700867, 52.437517068807118],
            [-2.310147249329157, 52.437869212167769],
            [-2.31017253665385, 52.44083945956919],
            [-2.309601218950645, 52.441812782243915],
            [-2.30681750880229, 52.443884157531159],
            [-2.306973534522512, 52.444952668729279],
            [-2.306188799241095, 52.445931922315772],
            [-2.304114773368696, 52.4464281341535],
            [-2.303332167697109, 52.447516142450439],
            [-2.300973634482831, 52.447949199608196],
            [-2.295939902445621, 52.450542951750919],
            [-2.293781297322591, 52.451203711024029],
            [-2.287386576601721, 52.455302705657736],
            [-2.286099684163765, 52.454737658525865],
            [-2.294968295367315, 52.448933467635953],
            [-2.2910909147334, 52.448442337144961],
            [-2.287144530549137, 52.446604539760884],
            [-2.286653834474017, 52.446035762676836],
            [-2.287191217460198, 52.445639795158542],
            [-2.285956927106032, 52.444520831483608],
            [-2.286071606900489, 52.443828320722353],
            [-2.279435188500868, 52.443395597755689],
            [-2.275235837962257, 52.440239160831624],
            [-2.272046186163686, 52.439708044052153],
            [-2.269509188310204, 52.438601789428475],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000240",
        LAD13CDO: "26UG",
        LAD13NM: "St Albans",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.329269577753634, 51.846910830703642],
            [-0.329229599333751, 51.846539799426658],
            [-0.327718306714337, 51.846813255509389],
            [-0.323255860711221, 51.844848854538036],
            [-0.323377843676836, 51.843128655763984],
            [-0.32232128927725, 51.839060066400506],
            [-0.320462113747592, 51.837882576031333],
            [-0.319480891702407, 51.83663487799766],
            [-0.315515483009373, 51.836322784110223],
            [-0.307392413485429, 51.835062399848404],
            [-0.305670157790889, 51.835085258918753],
            [-0.302562512018472, 51.835937838907441],
            [-0.300938996685935, 51.835688700785042],
            [-0.299586064341931, 51.834922822582016],
            [-0.298518440939045, 51.834829153109318],
            [-0.29836351642066, 51.835319666839766],
            [-0.295902602623357, 51.834838938477006],
            [-0.296169121840933, 51.834314976160805],
            [-0.292040276611731, 51.834802696150369],
            [-0.292048127091828, 51.835515866854628],
            [-0.290763093687549, 51.835682431927133],
            [-0.284565001017025, 51.834928592359837],
            [-0.280150975083977, 51.834705871666401],
            [-0.273563636975988, 51.835333209118282],
            [-0.266448219970349, 51.834475024169613],
            [-0.264088374565456, 51.83296104628392],
            [-0.261626758654682, 51.828440412899049],
            [-0.255188019608582, 51.822647768190606],
            [-0.252283052891113, 51.821709932429627],
            [-0.251572817944151, 51.820266057664128],
            [-0.25011656186793, 51.819653625524289],
            [-0.250007745396697, 51.819091807326828],
            [-0.248738208343768, 51.818342760809514],
            [-0.248438878225971, 51.817082125761651],
            [-0.24694586606301, 51.815140093259217],
            [-0.24356250670782, 51.812978290399158],
            [-0.243225792031399, 51.812161285129022],
            [-0.243750604918116, 51.809408588124761],
            [-0.244703982960899, 51.807981409854477],
            [-0.245892210406498, 51.807079262394709],
            [-0.249041620531004, 51.80643561945557],
            [-0.251044311836668, 51.805473610306343],
            [-0.253259127465015, 51.805025462898314],
            [-0.256485080010305, 51.803256064743564],
            [-0.26382928386141, 51.802443960489484],
            [-0.269816997822977, 51.800650205054012],
            [-0.27500698676435, 51.799795794789048],
            [-0.277538106920965, 51.797807913736989],
            [-0.274913438870923, 51.794722956934088],
            [-0.271544409263517, 51.79192553049522],
            [-0.271243965328348, 51.791022815095914],
            [-0.272573538366001, 51.788065115639085],
            [-0.273946659340604, 51.786582724338778],
            [-0.274114143909942, 51.78280225790698],
            [-0.277504139047619, 51.778070041834056],
            [-0.277342300556616, 51.776039078439538],
            [-0.275483996535606, 51.77284485239305],
            [-0.275783838054779, 51.772241392061211],
            [-0.273267885801828, 51.770602087135728],
            [-0.271842679655707, 51.769058796878994],
            [-0.268817737893374, 51.769746234680063],
            [-0.266988397712443, 51.767974828998746],
            [-0.262436289084273, 51.763631922748644],
            [-0.262561161499065, 51.763323543914424],
            [-0.258664245008167, 51.760240058995457],
            [-0.261276284015625, 51.758519887269486],
            [-0.259812194118786, 51.757631387698183],
            [-0.260419937478711, 51.757464140434578],
            [-0.259564318390834, 51.756428180207187],
            [-0.257224451145033, 51.755069880052616],
            [-0.258076499089592, 51.75469045841151],
            [-0.25574670987531, 51.753450072825785],
            [-0.257317441283499, 51.75186379797362],
            [-0.255104912735565, 51.74991566429884],
            [-0.255896596425675, 51.749258407294839],
            [-0.253194531531708, 51.747725596351117],
            [-0.250173923635421, 51.748892790127513],
            [-0.245613881717908, 51.751761613431583],
            [-0.242405168982712, 51.744998397960266],
            [-0.242631059731356, 51.74458993931033],
            [-0.242021527886117, 51.743201439226766],
            [-0.241603739278169, 51.742396692639197],
            [-0.244868256771733, 51.741134449564541],
            [-0.244916199573445, 51.739747688765618],
            [-0.245532619627361, 51.739650786859706],
            [-0.246479791293368, 51.738156054127607],
            [-0.245990924254429, 51.734323528613132],
            [-0.244450308196518, 51.731887047380035],
            [-0.246874445337203, 51.73147633212465],
            [-0.248329848316195, 51.732411632063879],
            [-0.250388421885435, 51.732542112739367],
            [-0.249173297989414, 51.731008848717813],
            [-0.24981630554327, 51.731120038118959],
            [-0.25239524700152, 51.730560448051754],
            [-0.255158693007234, 51.729532356701277],
            [-0.260160492881183, 51.733976666665988],
            [-0.262337455017807, 51.736837780355188],
            [-0.264459279132765, 51.738045297217347],
            [-0.266150874413869, 51.738197954816435],
            [-0.270439079198082, 51.737067900992791],
            [-0.273259659397758, 51.73541437919765],
            [-0.274440044579505, 51.735184436629396],
            [-0.275948977957935, 51.735301899864631],
            [-0.279404203171904, 51.734698819793216],
            [-0.283460480672143, 51.733480372312165],
            [-0.284318881460471, 51.73299295082716],
            [-0.285208692815025, 51.731675117012436],
            [-0.289114968617011, 51.728818635041023],
            [-0.289554127904086, 51.728002256624848],
            [-0.283695455199162, 51.724180633513747],
            [-0.284239501611597, 51.72285146063286],
            [-0.286555477241419, 51.721181261974216],
            [-0.286146584187716, 51.720814717154482],
            [-0.283997377496699, 51.721063001848897],
            [-0.283269294438359, 51.721403056607869],
            [-0.282190265334419, 51.721414262603602],
            [-0.28086314622454, 51.720828353169289],
            [-0.276977546098838, 51.721279489800509],
            [-0.278854738019388, 51.719236130029429],
            [-0.279798173093782, 51.717303163961716],
            [-0.283521885935663, 51.714809271404789],
            [-0.283869950133997, 51.714256847743286],
            [-0.286515150272073, 51.713132780681534],
            [-0.295783246394574, 51.711179624602352],
            [-0.293993029497772, 51.709786850209056],
            [-0.295245848216937, 51.709149514034834],
            [-0.301999624850559, 51.708061265404879],
            [-0.301141075301423, 51.704243414527291],
            [-0.299291725058433, 51.699374429346307],
            [-0.301053678502225, 51.698578947623972],
            [-0.300044111094707, 51.696519545784462],
            [-0.306350774572437, 51.697703174378887],
            [-0.309253696953708, 51.69789245931792],
            [-0.311346781523139, 51.6972913219265],
            [-0.31331948493582, 51.69619025903247],
            [-0.316198320102767, 51.696675764709923],
            [-0.318100783385061, 51.695552933637778],
            [-0.318331595878511, 51.695064372498166],
            [-0.322413851458494, 51.693526650060903],
            [-0.324730025824803, 51.69737502976129],
            [-0.325960328448712, 51.702188922796239],
            [-0.326762987989742, 51.70345834326136],
            [-0.329004996219789, 51.702586553779881],
            [-0.32942286870304, 51.702124910777563],
            [-0.330022395920833, 51.702310577462782],
            [-0.332375281397641, 51.702040967888635],
            [-0.333412435773953, 51.701383980638347],
            [-0.334096474267503, 51.698795884150407],
            [-0.334930972321693, 51.698216938055381],
            [-0.336858922604857, 51.697729903446749],
            [-0.342217377683953, 51.697607816026995],
            [-0.343399425105678, 51.697850190203845],
            [-0.343317629939465, 51.698188036132365],
            [-0.344135583755047, 51.697860568647833],
            [-0.346188003138392, 51.698798572948746],
            [-0.347977080422379, 51.698506326525504],
            [-0.349134559310227, 51.698908355087006],
            [-0.349772405693291, 51.699513487287376],
            [-0.35056411758661, 51.699389725362479],
            [-0.350961710949926, 51.698722704094585],
            [-0.351968041963934, 51.698426600821797],
            [-0.352478306190533, 51.697843881544742],
            [-0.353503544142233, 51.698501183973598],
            [-0.353490138004063, 51.699109755432374],
            [-0.354194040494073, 51.698893019886469],
            [-0.35449926479717, 51.698297528145325],
            [-0.35496946422738, 51.697940835775263],
            [-0.355472963434034, 51.69802251874075],
            [-0.356554089931614, 51.696099868920129],
            [-0.35589346298477, 51.695323606334455],
            [-0.35660217834557, 51.694972043707146],
            [-0.355963338759862, 51.694433475350358],
            [-0.356045811928999, 51.692995906535792],
            [-0.354939228294242, 51.69143198952829],
            [-0.355320551806947, 51.69057139702214],
            [-0.355762450063794, 51.691552318259397],
            [-0.358235191499201, 51.691469106915768],
            [-0.359068323242226, 51.690715526665933],
            [-0.362781558718863, 51.68913078959902],
            [-0.364167435131719, 51.689334432943774],
            [-0.366706418880504, 51.689175534185765],
            [-0.369749691561498, 51.687905873059478],
            [-0.371985973326852, 51.687552028710293],
            [-0.374230986707264, 51.689537983077742],
            [-0.375381928055411, 51.692506879560987],
            [-0.375581734578107, 51.692907088992428],
            [-0.375966956214714, 51.693826901326091],
            [-0.375746741884932, 51.69561326416629],
            [-0.376489525318005, 51.697014592943653],
            [-0.376198496320813, 51.698151654529745],
            [-0.3771875952259, 51.700031159242982],
            [-0.377236335636668, 51.701662079652507],
            [-0.378883755963619, 51.701686621601731],
            [-0.378755431412505, 51.704264650465426],
            [-0.380826418254803, 51.705196904149993],
            [-0.380764397186189, 51.707015126020679],
            [-0.383710077236876, 51.70761318831142],
            [-0.394105575366063, 51.708695395886217],
            [-0.397994599994375, 51.710335566653249],
            [-0.400557310292163, 51.711052070344927],
            [-0.403056690668368, 51.712212758133475],
            [-0.405026615350818, 51.712585705468207],
            [-0.404514779427618, 51.713474353743493],
            [-0.405946136402957, 51.713851660406299],
            [-0.402579846904977, 51.718064525340992],
            [-0.403477290204069, 51.719629629531227],
            [-0.402552773524109, 51.72117446726709],
            [-0.408949368165171, 51.72132869222051],
            [-0.409350112558454, 51.721708181755986],
            [-0.409708435569535, 51.722347861528661],
            [-0.409355310486865, 51.72370535816669],
            [-0.406630989716214, 51.724431863168327],
            [-0.407586159236492, 51.72563893813409],
            [-0.409295938263005, 51.725809562913248],
            [-0.410137686752062, 51.729963529486426],
            [-0.411910147195515, 51.732069122682702],
            [-0.41524995472788, 51.732014398424518],
            [-0.416186471233538, 51.732606102426743],
            [-0.418169422536474, 51.735330379841997],
            [-0.418033831869415, 51.737056796887011],
            [-0.422102366781775, 51.740830537528836],
            [-0.423792674330782, 51.742619224852589],
            [-0.426579222822236, 51.744336245665878],
            [-0.424589571893338, 51.745818429161027],
            [-0.424066455650842, 51.745699017584208],
            [-0.421046906638512, 51.748754393478869],
            [-0.42376848030978, 51.750681014757397],
            [-0.425724569318098, 51.752879665487512],
            [-0.42441290155128, 51.753810731641991],
            [-0.423484057933103, 51.756270139985439],
            [-0.422841792721673, 51.760149603554005],
            [-0.423379618498425, 51.762472220911278],
            [-0.422854443564166, 51.765982787500526],
            [-0.425912798107186, 51.766383454495276],
            [-0.429761512382501, 51.767897900325927],
            [-0.428465916114203, 51.770233750886682],
            [-0.430103259104178, 51.770203475581233],
            [-0.430845100854258, 51.771881365072211],
            [-0.430730174234477, 51.773272665478459],
            [-0.4314811002091, 51.77665552102728],
            [-0.430347392137968, 51.777537773612011],
            [-0.432982347666698, 51.779640144228026],
            [-0.434648063910561, 51.780182061606119],
            [-0.436919143137713, 51.782378405809574],
            [-0.43941404936581, 51.784522825449201],
            [-0.440607439037528, 51.786871133359824],
            [-0.434920152304608, 51.789034523192598],
            [-0.429973745929531, 51.790432441679989],
            [-0.427328626024473, 51.792068667710055],
            [-0.41974557940501, 51.790972500166994],
            [-0.416432573068282, 51.790841604169003],
            [-0.413391725550558, 51.79117826560514],
            [-0.41368385910849, 51.793307873519908],
            [-0.412184122181996, 51.793318202239242],
            [-0.41145164675887, 51.795304492096712],
            [-0.409386088017527, 51.797298827311963],
            [-0.412462950613026, 51.799128881476499],
            [-0.411992074818889, 51.800271676468952],
            [-0.415946639751268, 51.802062233810481],
            [-0.420975751408443, 51.802248566888245],
            [-0.42292000202583, 51.803265555074006],
            [-0.424853062106822, 51.804897399061822],
            [-0.425227593488979, 51.804846666306858],
            [-0.427968528611875, 51.806442516778063],
            [-0.428558673614142, 51.806364982730742],
            [-0.428852535205122, 51.806757354450767],
            [-0.427193901967661, 51.809736638920327],
            [-0.427729359637079, 51.810648372357157],
            [-0.427524264724629, 51.811623036364857],
            [-0.424313947344258, 51.813037632534673],
            [-0.42290975993756, 51.814572589068838],
            [-0.421608788547124, 51.815226820877001],
            [-0.421838644112403, 51.815790093470149],
            [-0.417505569900194, 51.817434009405019],
            [-0.416981824019855, 51.817889138034175],
            [-0.41551793463562, 51.818219204177474],
            [-0.414135565199225, 51.819078169053768],
            [-0.419750429545424, 51.820947609128552],
            [-0.419079490090472, 51.821793709492368],
            [-0.418999067453787, 51.822723276725384],
            [-0.419962386574681, 51.825879744585315],
            [-0.417960516156656, 51.828677146998125],
            [-0.4176123331508, 51.831986826232047],
            [-0.416300304989, 51.835752898834073],
            [-0.41271069830255, 51.838111605234985],
            [-0.408624312259175, 51.838781125310902],
            [-0.404883303035609, 51.840599839241413],
            [-0.398024143717712, 51.837175171299215],
            [-0.392764723671455, 51.835564029005681],
            [-0.383240799932549, 51.831946484421664],
            [-0.374079298644297, 51.829187390884634],
            [-0.37329059758408, 51.829246620988158],
            [-0.370790626292131, 51.830559889291294],
            [-0.368754717684436, 51.831075674284911],
            [-0.368563956147885, 51.832860600149438],
            [-0.367549862628166, 51.835097178899353],
            [-0.364974789417688, 51.837909115814789],
            [-0.36439520540999, 51.839883750535911],
            [-0.364082759576275, 51.841175124114784],
            [-0.363332224096164, 51.842097133050181],
            [-0.358990981062573, 51.841400926613176],
            [-0.357695977817926, 51.840008904007803],
            [-0.356055013709482, 51.840505707205303],
            [-0.352882909547272, 51.842146395435307],
            [-0.344329145912317, 51.845368562837777],
            [-0.342062942785168, 51.846633262947101],
            [-0.342300948108134, 51.847386534824217],
            [-0.341460558329881, 51.847736159542258],
            [-0.341479385772498, 51.848329884852248],
            [-0.339510470004621, 51.849597828406409],
            [-0.33713183453891, 51.848664146184262],
            [-0.333480819272369, 51.848063076615297],
            [-0.329269577753634, 51.846910830703642],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000241",
        LAD13CDO: "26UL",
        LAD13NM: "Welwyn Hatfield",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.183570399741082, 51.86043668141528],
            [-0.183576354248812, 51.860108566221022],
            [-0.18098481531626, 51.860126069351622],
            [-0.179427218034587, 51.859677551678629],
            [-0.178606455940836, 51.859036306432174],
            [-0.176099555092215, 51.858506500787307],
            [-0.176018255849859, 51.856746407144577],
            [-0.172809903775356, 51.856580617450021],
            [-0.170986471135346, 51.855494820207603],
            [-0.171662784612902, 51.854500928188735],
            [-0.169345155171718, 51.853823751528978],
            [-0.168415664223157, 51.851932650534366],
            [-0.166423640466306, 51.849464780192761],
            [-0.166754765978068, 51.847819008082489],
            [-0.167353186869275, 51.847205187123571],
            [-0.16718149271211, 51.844569649603123],
            [-0.166788628132759, 51.844198451009511],
            [-0.167452879382578, 51.843678273322979],
            [-0.168884052084259, 51.843469468553536],
            [-0.168846038068909, 51.840149023164948],
            [-0.170054272154354, 51.839134645219289],
            [-0.172074982211239, 51.840207344314003],
            [-0.172628700883592, 51.839796920843739],
            [-0.173402733557747, 51.840004071396073],
            [-0.176805215943396, 51.841875049504928],
            [-0.178569313881483, 51.841147952814971],
            [-0.175561289859102, 51.838711253138491],
            [-0.173495110525763, 51.839335600778035],
            [-0.171280184227633, 51.838578223777283],
            [-0.170182064556999, 51.838811122317139],
            [-0.169933811306782, 51.838095990681332],
            [-0.174170395961724, 51.836816631247764],
            [-0.171049029599885, 51.835255673460807],
            [-0.169795688448008, 51.834271331081382],
            [-0.170119041117092, 51.834100118926067],
            [-0.168348812870722, 51.832084423665037],
            [-0.168457620166693, 51.831155442744354],
            [-0.169493543756716, 51.828586361455912],
            [-0.171324802216794, 51.827015168341489],
            [-0.172413446613308, 51.824606939324021],
            [-0.173897103052154, 51.823658847540202],
            [-0.179714739593803, 51.819909444700571],
            [-0.181278358619894, 51.817075969134251],
            [-0.182372468752694, 51.817432785291899],
            [-0.183611841076806, 51.81507983665788],
            [-0.174001972973976, 51.810047414780087],
            [-0.166022074987867, 51.80911483275078],
            [-0.164596110382376, 51.808472129660423],
            [-0.163420354090659, 51.808312594918327],
            [-0.160085460893884, 51.807029441755994],
            [-0.161494426896555, 51.805057038566744],
            [-0.156633487306693, 51.804425218236602],
            [-0.156657377791563, 51.804017352098285],
            [-0.153878456992407, 51.803054759762993],
            [-0.153006326791943, 51.802248859214629],
            [-0.150003210778403, 51.801771821762067],
            [-0.150257660863109, 51.799027839894443],
            [-0.148093226510052, 51.798971286373856],
            [-0.156031719642145, 51.799017830496901],
            [-0.157394306250442, 51.798323422798738],
            [-0.157714173760096, 51.796275540399108],
            [-0.16008028867146, 51.796576061939668],
            [-0.16392659499917, 51.794925897724667],
            [-0.166770257960957, 51.792604455258591],
            [-0.164550787787624, 51.792267690847083],
            [-0.164809249515713, 51.790830293147614],
            [-0.164045671034328, 51.789351759989124],
            [-0.16452605312516, 51.789305308572935],
            [-0.162622905319702, 51.787540103968922],
            [-0.159132573566706, 51.786124095326223],
            [-0.152469373590802, 51.78036352187938],
            [-0.143294704649885, 51.774241892264527],
            [-0.141516460134115, 51.773775872622373],
            [-0.142100061352703, 51.773436203221834],
            [-0.142473695546709, 51.773797298019332],
            [-0.143496430351854, 51.773494234534283],
            [-0.142658427789594, 51.769297848915933],
            [-0.143478159964871, 51.767193147746134],
            [-0.142741551855613, 51.762965088520282],
            [-0.142954000371075, 51.758352768602911],
            [-0.143714878834808, 51.758172356289656],
            [-0.143768826326864, 51.757563539552308],
            [-0.142954471375441, 51.754986108476288],
            [-0.138202927803944, 51.751513687220474],
            [-0.138636602310356, 51.750726547228965],
            [-0.138554292910824, 51.747865729328964],
            [-0.139705790118006, 51.744414777639719],
            [-0.139024562861067, 51.742309710412847],
            [-0.139121436816816, 51.740057800803093],
            [-0.138376784079477, 51.73900470983083],
            [-0.138409317877871, 51.737261637928995],
            [-0.134910245323222, 51.738912872456602],
            [-0.128264129481937, 51.73921634335364],
            [-0.128426343369551, 51.741424715549755],
            [-0.125978805534445, 51.741276003674571],
            [-0.12369203025836, 51.741722394464134],
            [-0.120768312841334, 51.741704449946496],
            [-0.116331814029153, 51.742153168045547],
            [-0.1127215369795, 51.741543118310858],
            [-0.108389321869963, 51.741816963868438],
            [-0.105213655138257, 51.74155448347431],
            [-0.102423358127178, 51.74284660374861],
            [-0.101234260550198, 51.745158183436416],
            [-0.09676181914629, 51.744553492512516],
            [-0.092215269105633, 51.743269411968988],
            [-0.095804423795134, 51.740727069775005],
            [-0.097034702004157, 51.737714814064631],
            [-0.098631775801324, 51.735931426965458],
            [-0.101484371037549, 51.732972341788233],
            [-0.101824584670352, 51.731799853478606],
            [-0.102349219760827, 51.731721102785976],
            [-0.101831523048946, 51.730078848479621],
            [-0.103085348791811, 51.729219654855555],
            [-0.104590348855965, 51.729208872582824],
            [-0.105376476110508, 51.728901427218496],
            [-0.105498840341963, 51.727706531584808],
            [-0.10753452674784, 51.725354592130294],
            [-0.113897619782198, 51.720951793007544],
            [-0.114204106133845, 51.720336247372416],
            [-0.112971053897658, 51.718064788784595],
            [-0.108057324416797, 51.714149663239574],
            [-0.10560018447994, 51.71360919825532],
            [-0.107357167412484, 51.712785948479251],
            [-0.107626019115393, 51.711408167330966],
            [-0.107196747467095, 51.707994978058771],
            [-0.1075160773933, 51.705511059990229],
            [-0.106426139133406, 51.696744000342591],
            [-0.105692987987742, 51.694341127900394],
            [-0.106086250497245, 51.692615552608274],
            [-0.105804731996277, 51.691872745266288],
            [-0.109774640287118, 51.691672324155256],
            [-0.109646213541379, 51.691490411450488],
            [-0.112922347637826, 51.690819210631808],
            [-0.118272801771632, 51.6889348369345],
            [-0.122208363578012, 51.688542810405515],
            [-0.130274791227897, 51.68891248316362],
            [-0.135404204930975, 51.688722455007131],
            [-0.139611138082037, 51.687917811646528],
            [-0.144683178364267, 51.686331770379276],
            [-0.149395102465293, 51.685514971039822],
            [-0.153282972070261, 51.685854023095871],
            [-0.158016766723153, 51.687237625615431],
            [-0.163518007750547, 51.688111486896723],
            [-0.163142463396128, 51.699853963897105],
            [-0.164521447890516, 51.700393470325999],
            [-0.169746450039373, 51.701484819074892],
            [-0.172138784750814, 51.702737814198585],
            [-0.17247679705366, 51.702574019536094],
            [-0.173369836769986, 51.703002449627085],
            [-0.173744842513732, 51.70263870034632],
            [-0.177759866587125, 51.703959956956922],
            [-0.181445194640285, 51.706001641406743],
            [-0.183350904496857, 51.706517603557231],
            [-0.187656885596674, 51.706984262591376],
            [-0.189880524239181, 51.707805361044954],
            [-0.19048125566719, 51.707078155872026],
            [-0.194637400965175, 51.708293995981357],
            [-0.195827441526917, 51.709196208066373],
            [-0.199454622924731, 51.709853430367943],
            [-0.202645053392163, 51.709736827567198],
            [-0.204760190790554, 51.709908546025943],
            [-0.206509421924732, 51.709634025222513],
            [-0.206983750075762, 51.71026711949515],
            [-0.207774571667384, 51.710400580269443],
            [-0.210815325257349, 51.710262588438816],
            [-0.213132686779783, 51.710703402903498],
            [-0.21477490013098, 51.711271494080819],
            [-0.218416520454786, 51.713708791211815],
            [-0.221071203667814, 51.713564677796526],
            [-0.22228125018466, 51.713124394338323],
            [-0.223392700179217, 51.712835473915298],
            [-0.227059067780466, 51.712693031276196],
            [-0.229766686683467, 51.711672787953454],
            [-0.232256368958303, 51.711375767700162],
            [-0.234293937745372, 51.710756277263791],
            [-0.235220174919594, 51.710090389389521],
            [-0.237406524644651, 51.70590140737761],
            [-0.238995182328171, 51.706137445704471],
            [-0.239415698858344, 51.708055474187468],
            [-0.241041851450999, 51.710424077904428],
            [-0.240626621470705, 51.712843933876869],
            [-0.241133992029447, 51.715735301364106],
            [-0.250807216195723, 51.724620028171607],
            [-0.252275788498031, 51.726749625851056],
            [-0.255158693007234, 51.729532356701277],
            [-0.25239524700152, 51.730560448051754],
            [-0.24981630554327, 51.731120038118959],
            [-0.249173297989414, 51.731008848717813],
            [-0.250388421885435, 51.732542112739367],
            [-0.248329848316195, 51.732411632063879],
            [-0.246874445337203, 51.73147633212465],
            [-0.244450308196518, 51.731887047380035],
            [-0.245990924254429, 51.734323528613132],
            [-0.246479791293368, 51.738156054127607],
            [-0.245532619627361, 51.739650786859706],
            [-0.244916199573445, 51.739747688765618],
            [-0.244868256771733, 51.741134449564541],
            [-0.241603739278169, 51.742396692639197],
            [-0.242021527886117, 51.743201439226766],
            [-0.242631059731356, 51.74458993931033],
            [-0.242405168982712, 51.744998397960266],
            [-0.245613881717908, 51.751761613431583],
            [-0.250173923635421, 51.748892790127513],
            [-0.253194531531708, 51.747725596351117],
            [-0.255896596425675, 51.749258407294839],
            [-0.255104912735565, 51.74991566429884],
            [-0.257317441283499, 51.75186379797362],
            [-0.25574670987531, 51.753450072825785],
            [-0.258076499089592, 51.75469045841151],
            [-0.257224451145033, 51.755069880052616],
            [-0.259564318390834, 51.756428180207187],
            [-0.260419937478711, 51.757464140434578],
            [-0.259812194118786, 51.757631387698183],
            [-0.261276284015625, 51.758519887269486],
            [-0.258664245008167, 51.760240058995457],
            [-0.262561161499065, 51.763323543914424],
            [-0.262436289084273, 51.763631922748644],
            [-0.266988397712443, 51.767974828998746],
            [-0.268817737893374, 51.769746234680063],
            [-0.271842679655707, 51.769058796878994],
            [-0.273267885801828, 51.770602087135728],
            [-0.275783838054779, 51.772241392061211],
            [-0.275483996535606, 51.77284485239305],
            [-0.277342300556616, 51.776039078439538],
            [-0.277504139047619, 51.778070041834056],
            [-0.274114143909942, 51.78280225790698],
            [-0.273946659340604, 51.786582724338778],
            [-0.272573538366001, 51.788065115639085],
            [-0.271243965328348, 51.791022815095914],
            [-0.271544409263517, 51.79192553049522],
            [-0.274913438870923, 51.794722956934088],
            [-0.277538106920965, 51.797807913736989],
            [-0.27500698676435, 51.799795794789048],
            [-0.269816997822977, 51.800650205054012],
            [-0.26382928386141, 51.802443960489484],
            [-0.256485080010305, 51.803256064743564],
            [-0.253259127465015, 51.805025462898314],
            [-0.251044311836668, 51.805473610306343],
            [-0.249041620531004, 51.80643561945557],
            [-0.245892210406498, 51.807079262394709],
            [-0.244703982960899, 51.807981409854477],
            [-0.243750604918116, 51.809408588124761],
            [-0.243225792031399, 51.812161285129022],
            [-0.24356250670782, 51.812978290399158],
            [-0.24694586606301, 51.815140093259217],
            [-0.248438878225971, 51.817082125761651],
            [-0.248738208343768, 51.818342760809514],
            [-0.250007745396697, 51.819091807326828],
            [-0.25011656186793, 51.819653625524289],
            [-0.251572817944151, 51.820266057664128],
            [-0.252283052891113, 51.821709932429627],
            [-0.255188019608582, 51.822647768190606],
            [-0.261626758654682, 51.828440412899049],
            [-0.264088374565456, 51.83296104628392],
            [-0.266448219970349, 51.834475024169613],
            [-0.273563636975988, 51.835333209118282],
            [-0.272819454807926, 51.836498424519327],
            [-0.27304451314937, 51.839683065218722],
            [-0.272545988362238, 51.840458039931676],
            [-0.27135267775474, 51.840460289947039],
            [-0.26815330856296, 51.842200815737016],
            [-0.266417740208605, 51.8417688140172],
            [-0.259582974580419, 51.844752104068519],
            [-0.259343488480265, 51.84460019368673],
            [-0.257049184633196, 51.8454411315427],
            [-0.253968916066366, 51.844530424802024],
            [-0.247982814331276, 51.846227207438616],
            [-0.247349771520781, 51.846153933769017],
            [-0.246528435364557, 51.845210129154999],
            [-0.245298772433033, 51.84457584427269],
            [-0.245051563316826, 51.843503915039804],
            [-0.243035191399706, 51.842719389372448],
            [-0.243066701401071, 51.841798186958279],
            [-0.244977065416957, 51.839409676192425],
            [-0.244927593222946, 51.838815470266972],
            [-0.241737828766983, 51.836336407972048],
            [-0.242450881160624, 51.835879484699248],
            [-0.241647880566398, 51.835291997824605],
            [-0.240459320615932, 51.834952310554712],
            [-0.23903266470234, 51.834986708208895],
            [-0.237103384187635, 51.836444174690932],
            [-0.236012907135916, 51.836231799922182],
            [-0.233320475196724, 51.836378419608323],
            [-0.232129085244013, 51.836556541977892],
            [-0.231669733826676, 51.836944383151454],
            [-0.230750735658798, 51.836764211058032],
            [-0.23007511784212, 51.836040983666443],
            [-0.229152810583768, 51.836056773783994],
            [-0.22798768460413, 51.836711823449953],
            [-0.227954003965878, 51.837052111178409],
            [-0.226886860918466, 51.837096273989808],
            [-0.225704331233473, 51.837638639398683],
            [-0.225586589224888, 51.83719985387058],
            [-0.226948812752885, 51.835888688651032],
            [-0.226977948249642, 51.835294759303693],
            [-0.226129606952778, 51.834906103176394],
            [-0.2263954525995, 51.834456917630632],
            [-0.224934323311697, 51.833227253174059],
            [-0.221681162938798, 51.834089893781822],
            [-0.223426085807413, 51.835298708571557],
            [-0.219870665198244, 51.836680965340769],
            [-0.22021561226804, 51.837614156718267],
            [-0.218705183234481, 51.83840776083801],
            [-0.219605643259461, 51.838799956882376],
            [-0.220809198013114, 51.838757923546218],
            [-0.22180772834359, 51.839240606473894],
            [-0.222600954546587, 51.839002619961548],
            [-0.224218458501324, 51.840208596017959],
            [-0.223816640741288, 51.840459698583174],
            [-0.223446493103532, 51.841307444900529],
            [-0.226020095104145, 51.843712072534537],
            [-0.22578149476621, 51.844537531393676],
            [-0.223972876341153, 51.846633235014039],
            [-0.223048618548142, 51.846213738650441],
            [-0.222369603708225, 51.844691931441794],
            [-0.218283434118169, 51.846627218159746],
            [-0.218904332271425, 51.847043957317609],
            [-0.217194262190528, 51.847700539478424],
            [-0.216523827855264, 51.848941156682372],
            [-0.212949564675561, 51.851389368827029],
            [-0.210437821375855, 51.849847726532879],
            [-0.211324299698071, 51.849351366184791],
            [-0.20873936622306, 51.847572983642976],
            [-0.205987127690614, 51.846392654457958],
            [-0.208257141730705, 51.844690007759127],
            [-0.212204587808302, 51.842488595129325],
            [-0.212729112228084, 51.841560502957947],
            [-0.215267229953064, 51.840039837266055],
            [-0.214595265592268, 51.839596225801294],
            [-0.213425771550424, 51.840243872632755],
            [-0.21298373851432, 51.84015263278453],
            [-0.21201068330667, 51.840567660547471],
            [-0.210651787408643, 51.840425603077634],
            [-0.209969940735447, 51.840890903107926],
            [-0.208152202106609, 51.841958446650807],
            [-0.20543737604746, 51.844372764036642],
            [-0.20397219371494, 51.844892615975425],
            [-0.201485944779149, 51.843529207450985],
            [-0.199646220217441, 51.843757332895045],
            [-0.198753094573426, 51.844347020516253],
            [-0.199453494545173, 51.844585241686474],
            [-0.198567730585426, 51.845462783976565],
            [-0.198724419294626, 51.845938161771848],
            [-0.197578503124996, 51.846131019511098],
            [-0.197199972864704, 51.848919025873485],
            [-0.196438948938367, 51.84954219277806],
            [-0.197658923645876, 51.85022360125663],
            [-0.199087214638534, 51.849724842903179],
            [-0.202027222887351, 51.851513362624452],
            [-0.203668104439747, 51.853223526967369],
            [-0.203140397488942, 51.853968094266499],
            [-0.203546171917274, 51.854753895116325],
            [-0.205197033060739, 51.85329812001612],
            [-0.206714067371552, 51.854256421321757],
            [-0.204969033584577, 51.85526927525531],
            [-0.204243661226077, 51.855033404834479],
            [-0.20091026770491, 51.856619905562063],
            [-0.20012150456531, 51.856444185562012],
            [-0.19876618629616, 51.857765040757698],
            [-0.197623521663434, 51.857687292032637],
            [-0.196856167826601, 51.859373214647661],
            [-0.1918370495037, 51.857313442616416],
            [-0.190522757776445, 51.858220304946876],
            [-0.189688959523273, 51.857792046927642],
            [-0.186427394385942, 51.859416113005565],
            [-0.185808146029783, 51.85892549360134],
            [-0.185919406532406, 51.860239138569952],
            [-0.183570399741082, 51.86043668141528],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000242",
        LAD13CDO: "26UD",
        LAD13NM: "East Hertfordshire",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.013526418718516, 51.996365819219264],
            [-0.01003742004851, 51.993315272959457],
            [-0.003065085755967, 51.993436433037715],
            [-0.003037027484063, 51.992626675423082],
            [-0.001828910609082, 51.992640351705028],
            [-0.001274650353457, 51.989550277798422],
            [-0.002053259129192, 51.989433106479467],
            [-0.001532376820389, 51.986967645397542],
            [0.000219460360194, 51.983792487115998],
            [0.001986610473862, 51.984231839763474],
            [0.002440439674801, 51.983044367267709],
            [0.003592045271226, 51.983135379600242],
            [0.003445746671243, 51.983511937824929],
            [0.005761504528351, 51.983745871881673],
            [0.006981357852124, 51.984874269848923],
            [0.00877744133199, 51.984786094086537],
            [0.009170769573984, 51.983845109630757],
            [0.009548274234106, 51.983819784342771],
            [0.010060227746451, 51.982243731616805],
            [0.012876398998585, 51.982466284346657],
            [0.012692942576874, 51.983961202136662],
            [0.01593540612645, 51.984139527604029],
            [0.016181840115585, 51.983854756068801],
            [0.023241529515825, 51.984361309918221],
            [0.022618920248686, 51.988061449904336],
            [0.021541137177532, 51.988934203341586],
            [0.024925976746298, 51.987868934506047],
            [0.026189119954944, 51.987974893583456],
            [0.032240035135125, 51.987028961102396],
            [0.033859894605418, 51.988707689396378],
            [0.034418627987055, 51.988727711373947],
            [0.034996502488309, 51.989619631278394],
            [0.03831686605658, 51.988936354065139],
            [0.037808390489566, 51.988451489481918],
            [0.037726602132046, 51.986842423032357],
            [0.038564560429767, 51.98685399593986],
            [0.038511489528834, 51.986390923174184],
            [0.041677190013467, 51.98654019612497],
            [0.042295189808072, 51.987255140595884],
            [0.044543867947615, 51.986934665306293],
            [0.04414243954874, 51.986242949202286],
            [0.043500191035415, 51.986019403273588],
            [0.043277246025666, 51.985312896915339],
            [0.043684875353142, 51.984797769450083],
            [0.045821400175281, 51.984735490008141],
            [0.048355199652814, 51.984251725580371],
            [0.048551200244446, 51.984557644037011],
            [0.058398755166049, 51.981314200007482],
            [0.064710351097529, 51.980433965714312],
            [0.066878481480107, 51.979797949284766],
            [0.069032225144849, 51.984033183282961],
            [0.069416066626689, 51.982977056234837],
            [0.071893779657208, 51.98153790412087],
            [0.07681681865895, 51.980802838714887],
            [0.083970901990251, 51.981360736107291],
            [0.096433070098454, 51.984316436998874],
            [0.098068927978146, 51.982330603324598],
            [0.098260200252131, 51.98053415565289],
            [0.097390217758659, 51.978837546439308],
            [0.097728676405999, 51.977355001356081],
            [0.09988699331043, 51.976397526118362],
            [0.10149492124928, 51.97615752075194],
            [0.106984116132172, 51.971808804888575],
            [0.10552250911097, 51.970103955546762],
            [0.104474802974761, 51.967722670745822],
            [0.104463093997305, 51.965577340903913],
            [0.106068137824023, 51.96286266610521],
            [0.110577546209378, 51.960163372530047],
            [0.111216503822023, 51.958985624114689],
            [0.11373118305728, 51.95711957215395],
            [0.114708089154416, 51.955826928588991],
            [0.114896686396274, 51.954113218019096],
            [0.115990939235234, 51.953058546821566],
            [0.116749382391547, 51.95158007517464],
            [0.117984882405212, 51.948058969654738],
            [0.118415446905837, 51.947160089851224],
            [0.118089748624599, 51.945590509524692],
            [0.118168359976293, 51.940495883699796],
            [0.118758066995297, 51.937572677341656],
            [0.118604942570665, 51.933983025256708],
            [0.121098768265727, 51.930322315103155],
            [0.121802171187961, 51.928551645881527],
            [0.120321927522355, 51.927142258048299],
            [0.120242125815286, 51.926560097103554],
            [0.121111528534616, 51.925256736197298],
            [0.124422391953315, 51.924006436316766],
            [0.124831867816985, 51.923530545512932],
            [0.124230163144789, 51.921660221825221],
            [0.122576131611743, 51.919183916605682],
            [0.122893982553146, 51.917321274920752],
            [0.12231959822869, 51.915841612769299],
            [0.121736609675334, 51.911186922978864],
            [0.123482522421845, 51.903074942042281],
            [0.122742516466065, 51.90054526905093],
            [0.123841503451414, 51.897464451365458],
            [0.124757605186791, 51.892420302172972],
            [0.123799287889303, 51.889295685985104],
            [0.124734992077831, 51.8845775977573],
            [0.127210263333624, 51.884960001686757],
            [0.127593568399942, 51.883937834640314],
            [0.129201363031757, 51.883921335055263],
            [0.130985370164684, 51.884343145874873],
            [0.132341239213582, 51.884314980412725],
            [0.132283153009058, 51.88459209837113],
            [0.139556875296715, 51.885375456410692],
            [0.139357719595613, 51.887332218061047],
            [0.141229881186937, 51.887341320954803],
            [0.141476506754547, 51.888188408076019],
            [0.144289737965428, 51.887359325833437],
            [0.146136250588383, 51.887135016635128],
            [0.146955045167632, 51.886691140782972],
            [0.15040364353487, 51.888217105458757],
            [0.157078816465476, 51.890077719626767],
            [0.162178697033418, 51.89208112358169],
            [0.163203480645465, 51.891539827644273],
            [0.164615829000747, 51.891926600223535],
            [0.165713971709946, 51.891188797581826],
            [0.168962102292865, 51.891790798490263],
            [0.16771141305193, 51.894129389649976],
            [0.175360680234053, 51.894607776541186],
            [0.179321681345749, 51.895546114646393],
            [0.179842958205759, 51.895325135991769],
            [0.180547794534248, 51.893882282309633],
            [0.183637341271448, 51.893633439911412],
            [0.183874034340967, 51.893359274151322],
            [0.182833395936668, 51.892009942240314],
            [0.182342760787723, 51.891962392446757],
            [0.182195785310159, 51.890638738315751],
            [0.179622003690772, 51.889798900897844],
            [0.178122888688055, 51.888250297019034],
            [0.175957473168437, 51.887285917703728],
            [0.177109888275631, 51.886865333383192],
            [0.17727658854729, 51.886315509759505],
            [0.177982318853608, 51.885974223017769],
            [0.176499901325325, 51.885549341001457],
            [0.175414867641333, 51.88480685449121],
            [0.17698799793968, 51.884433343707848],
            [0.174949114675627, 51.882804763682131],
            [0.177114918164552, 51.882038113697952],
            [0.176679964760723, 51.881576758654454],
            [0.177851063393193, 51.880794324738211],
            [0.180011335004775, 51.880667083689509],
            [0.181798031839258, 51.879636698955245],
            [0.182874183265726, 51.878366786730567],
            [0.184989440127132, 51.87836258560597],
            [0.185927989860277, 51.87665457093604],
            [0.187544960735381, 51.875306220044131],
            [0.18979998172858, 51.874234626837854],
            [0.193382363247686, 51.873245255596039],
            [0.192830075026646, 51.872344617767034],
            [0.190823393552121, 51.871827176332125],
            [0.193590111243763, 51.871633531280295],
            [0.194235388015378, 51.86990843314009],
            [0.193892738596926, 51.869762852255455],
            [0.195566963443017, 51.868078798763925],
            [0.194222792246197, 51.865770338994253],
            [0.187063629499498, 51.864968463600157],
            [0.187510833863982, 51.864186796198531],
            [0.186344899823005, 51.863506164676963],
            [0.185452829613026, 51.865148578769201],
            [0.184987021314406, 51.864738184901604],
            [0.1827133839658, 51.865087941992499],
            [0.181966539032517, 51.864632393700042],
            [0.18278719359194, 51.863552460904963],
            [0.177886003307006, 51.863028234505819],
            [0.174321396330562, 51.862057357876068],
            [0.170441065011398, 51.861614661167067],
            [0.172380510279785, 51.858007958199735],
            [0.17118880769211, 51.857598326834164],
            [0.171655130312652, 51.857149087701252],
            [0.171165389081447, 51.857021444567906],
            [0.171510150840663, 51.856611318974629],
            [0.169369615586659, 51.855978187178984],
            [0.173037075052546, 51.854273856805655],
            [0.171172586498384, 51.85237491664072],
            [0.17350811706302, 51.851362390100512],
            [0.173371489973114, 51.849762460308305],
            [0.170813135078954, 51.849703601407761],
            [0.168336574798234, 51.850590982439108],
            [0.167428799588527, 51.850464736269011],
            [0.166777939910653, 51.850729417340204],
            [0.164807686590826, 51.8496434448804],
            [0.164747371841281, 51.847630248222544],
            [0.165139035395127, 51.846927022868954],
            [0.163139397536365, 51.844769664992896],
            [0.161792389874441, 51.840746035688731],
            [0.161553428230349, 51.835939467292924],
            [0.160879173210466, 51.834044560103401],
            [0.161888580026076, 51.833057518511886],
            [0.161671299217203, 51.832127196693037],
            [0.163354198102569, 51.83180848784248],
            [0.164426702898623, 51.832048633031427],
            [0.165194444909604, 51.831896014564457],
            [0.166096419966952, 51.830699590734525],
            [0.165378882590794, 51.829538390606352],
            [0.162986471911864, 51.828799180569895],
            [0.161547130356249, 51.827594580815905],
            [0.161182034799561, 51.825940383594002],
            [0.16154799344572, 51.825248430470303],
            [0.162208848628895, 51.825199410456513],
            [0.16340295631746, 51.822741384689998],
            [0.163526707693405, 51.822931546541504],
            [0.166224467374737, 51.822147188569772],
            [0.16464168895269, 51.819703407749444],
            [0.164704784589414, 51.819233737870682],
            [0.169277835702986, 51.818255598233613],
            [0.170131400456712, 51.819290168899634],
            [0.172994550768395, 51.818873989772229],
            [0.173278358782068, 51.817535157204816],
            [0.172555733806368, 51.816297656672077],
            [0.170359791553925, 51.816554933883069],
            [0.167977846698239, 51.814963147084981],
            [0.166981244838545, 51.813272947227674],
            [0.162233256106716, 51.81458783997477],
            [0.158281834435868, 51.813510307384355],
            [0.158671809524021, 51.81238088610673],
            [0.158160598878677, 51.810062117981957],
            [0.155297396768214, 51.807275717592283],
            [0.15548167456111, 51.806455821478075],
            [0.154047458957362, 51.8035136901565],
            [0.15342340643823, 51.803019745539338],
            [0.15109759300984, 51.802731403658647],
            [0.150647127056583, 51.802379047022818],
            [0.150427100278729, 51.800955964955641],
            [0.15152381355709, 51.799193153693068],
            [0.150739807518987, 51.797043008327257],
            [0.148146765148924, 51.795946578131584],
            [0.146114246375821, 51.796237291459207],
            [0.143975754351361, 51.797198942879952],
            [0.141425040469514, 51.797206768909867],
            [0.14043884383569, 51.796472059904083],
            [0.139319568656385, 51.796326074176292],
            [0.138903165053681, 51.79574014822699],
            [0.135830372132235, 51.795040651708327],
            [0.132425523741354, 51.795846143914609],
            [0.129140688734024, 51.795876910389012],
            [0.128881462939814, 51.795299793020114],
            [0.12730821104673, 51.794904729698608],
            [0.125582415195445, 51.795150868485017],
            [0.125406502253015, 51.794494002813828],
            [0.123853993530277, 51.793674973778472],
            [0.122016716673662, 51.791237027752196],
            [0.120493359213418, 51.790170136246836],
            [0.118680033474841, 51.790061652526425],
            [0.115347774389209, 51.788698161621973],
            [0.111126527441185, 51.788444928122843],
            [0.107293506354804, 51.787933694773749],
            [0.103544687729279, 51.785085493370765],
            [0.102133029174881, 51.784910216701789],
            [0.10097628688907, 51.783948923997656],
            [0.099227472856848, 51.783224805253639],
            [0.097712929332964, 51.783236557556307],
            [0.096895957591154, 51.783596444501825],
            [0.09569797878652, 51.783400193131627],
            [0.092533385296376, 51.78450599017323],
            [0.091955546000322, 51.784426351434412],
            [0.091840663224665, 51.784105567880445],
            [0.091318134519987, 51.784281225842363],
            [0.091248415929293, 51.783745618401355],
            [0.090939822134069, 51.784064044811217],
            [0.089721846379846, 51.78403085120145],
            [0.088987104685961, 51.784257931234897],
            [0.087682971640563, 51.784054492387057],
            [0.087169883457675, 51.783555532526186],
            [0.08657052372931, 51.783763107787088],
            [0.086195257551806, 51.783517081819802],
            [0.085966718903214, 51.783811593004877],
            [0.084590997154797, 51.783375587554566],
            [0.082467998993983, 51.78349235293495],
            [0.081743962244002, 51.783196738550053],
            [0.080999516149039, 51.783213517859053],
            [0.08066394675997, 51.783577356482979],
            [0.079788467198152, 51.783361745535927],
            [0.079663952854299, 51.782955693124975],
            [0.077027427661775, 51.782466364178397],
            [0.069804020166939, 51.783312277304006],
            [0.068227460922728, 51.78393352659419],
            [0.063656677357618, 51.783947319317789],
            [0.060841914984314, 51.783521006021132],
            [0.05970181664469, 51.782936701183388],
            [0.05763641373929, 51.779668173609871],
            [0.054879726937529, 51.778279416647287],
            [0.053314536285211, 51.776852701381735],
            [0.051721161072126, 51.776048730656406],
            [0.046747638404524, 51.775313540422928],
            [0.042296152447313, 51.775124799484985],
            [0.040804585098214, 51.775511301594541],
            [0.040388520482036, 51.77528562269228],
            [0.035760172517534, 51.775739960746492],
            [0.030806984173994, 51.775192584753185],
            [0.026772124246153, 51.774174155542191],
            [0.018109632342708, 51.769689455418863],
            [0.018271283463203, 51.768894453572969],
            [0.017879939887812, 51.769089113315523],
            [0.017420302311926, 51.768727418347602],
            [0.018016459363661, 51.767275709777394],
            [0.016622178628175, 51.766982211851854],
            [0.015972033154369, 51.765596853460437],
            [0.014001695792012, 51.764374403200783],
            [0.012054867061617, 51.764916716041228],
            [0.012022887395061, 51.766120439584704],
            [0.011103310986722, 51.766462600805383],
            [0.010450465950578, 51.767295670415045],
            [0.009168494022347, 51.767702464950411],
            [0.008643321630847, 51.768539636036643],
            [0.007282600536073, 51.769067353474362],
            [0.005202141488965, 51.77091751927302],
            [0.004132065296772, 51.772806174996404],
            [0.002778303770531, 51.774018935186341],
            [0.001977719385992, 51.775744712160098],
            [0.003414510051876, 51.777615812419768],
            [0.005546739439036, 51.780063129244141],
            [0.000277602967494, 51.77974915990503],
            [-0.012479889263966, 51.779088818481355],
            [-0.012691211944449, 51.780514972801932],
            [-0.018118116446733, 51.780535560740603],
            [-0.018168366262558, 51.779686636683465],
            [-0.019879011561592, 51.779719962591393],
            [-0.019832660248696, 51.778957534979462],
            [-0.021057845629178, 51.778720989107192],
            [-0.019496258224224, 51.778462686819722],
            [-0.019812871619739, 51.777191115949734],
            [-0.019280006314756, 51.777144370045519],
            [-0.018901158752154, 51.776593052432197],
            [-0.017739917569157, 51.776661598867427],
            [-0.020100323467671, 51.776187922414792],
            [-0.020052730610557, 51.775553164127324],
            [-0.021272114883859, 51.775049447714139],
            [-0.021514208593677, 51.773690291507165],
            [-0.023020933010782, 51.773542093012594],
            [-0.024182483262481, 51.774128138789919],
            [-0.026067275984601, 51.774351342773357],
            [-0.025482515001695, 51.772106937080046],
            [-0.028075116820942, 51.772151361419496],
            [-0.02812058370392, 51.771407562806488],
            [-0.030349451150036, 51.771549245583202],
            [-0.032561645977693, 51.773674304019529],
            [-0.033266110211669, 51.773740946641581],
            [-0.033940525482778, 51.773565190132587],
            [-0.034384927029918, 51.772878418960566],
            [-0.036995630367542, 51.772673862763597],
            [-0.038529278168211, 51.772103278634305],
            [-0.038896916625982, 51.772550034647566],
            [-0.044632818624871, 51.770228476272791],
            [-0.045710955072797, 51.770166376476922],
            [-0.047941931989947, 51.768780873850773],
            [-0.048878243527451, 51.764847915817839],
            [-0.048907970333598, 51.761335128138519],
            [-0.050279460792908, 51.750892671912403],
            [-0.049902102909894, 51.75087472056034],
            [-0.050763230233588, 51.74695847711164],
            [-0.050713524867277, 51.744209607293335],
            [-0.052426532108265, 51.742372109297207],
            [-0.05549195890249, 51.742531682333855],
            [-0.055744785646652, 51.740893872928837],
            [-0.05510187557633, 51.74078791529103],
            [-0.055104477602827, 51.736983316829722],
            [-0.057451533863684, 51.735378349342824],
            [-0.058382060956725, 51.734719306793551],
            [-0.062840936058681, 51.73445569093029],
            [-0.065382399263998, 51.734698091042482],
            [-0.068804342176731, 51.734229232805177],
            [-0.081464989066811, 51.734397069408381],
            [-0.090158396341319, 51.735437018031163],
            [-0.095319434654686, 51.73555496031468],
            [-0.098631775801324, 51.735931426965458],
            [-0.097034702004157, 51.737714814064631],
            [-0.095804423795134, 51.740727069775005],
            [-0.092215269105633, 51.743269411968988],
            [-0.09676181914629, 51.744553492512516],
            [-0.101234260550198, 51.745158183436416],
            [-0.102423358127178, 51.74284660374861],
            [-0.105213655138257, 51.74155448347431],
            [-0.108389321869963, 51.741816963868438],
            [-0.1127215369795, 51.741543118310858],
            [-0.116331814029153, 51.742153168045547],
            [-0.120768312841334, 51.741704449946496],
            [-0.12369203025836, 51.741722394464134],
            [-0.125978805534445, 51.741276003674571],
            [-0.128426343369551, 51.741424715549755],
            [-0.128264129481937, 51.73921634335364],
            [-0.134910245323222, 51.738912872456602],
            [-0.138409317877871, 51.737261637928995],
            [-0.138376784079477, 51.73900470983083],
            [-0.139121436816816, 51.740057800803093],
            [-0.139024562861067, 51.742309710412847],
            [-0.139705790118006, 51.744414777639719],
            [-0.138554292910824, 51.747865729328964],
            [-0.138636602310356, 51.750726547228965],
            [-0.138202927803944, 51.751513687220474],
            [-0.142954471375441, 51.754986108476288],
            [-0.143768826326864, 51.757563539552308],
            [-0.143714878834808, 51.758172356289656],
            [-0.142954000371075, 51.758352768602911],
            [-0.142741551855613, 51.762965088520282],
            [-0.143478159964871, 51.767193147746134],
            [-0.142658427789594, 51.769297848915933],
            [-0.143496430351854, 51.773494234534283],
            [-0.142473695546709, 51.773797298019332],
            [-0.142100061352703, 51.773436203221834],
            [-0.141516460134115, 51.773775872622373],
            [-0.143294704649885, 51.774241892264527],
            [-0.152469373590802, 51.78036352187938],
            [-0.159132573566706, 51.786124095326223],
            [-0.162622905319702, 51.787540103968922],
            [-0.16452605312516, 51.789305308572935],
            [-0.164045671034328, 51.789351759989124],
            [-0.164809249515713, 51.790830293147614],
            [-0.164550787787624, 51.792267690847083],
            [-0.166770257960957, 51.792604455258591],
            [-0.16392659499917, 51.794925897724667],
            [-0.16008028867146, 51.796576061939668],
            [-0.157714173760096, 51.796275540399108],
            [-0.157394306250442, 51.798323422798738],
            [-0.156031719642145, 51.799017830496901],
            [-0.148093226510052, 51.798971286373856],
            [-0.150257660863109, 51.799027839894443],
            [-0.150003210778403, 51.801771821762067],
            [-0.153006326791943, 51.802248859214629],
            [-0.153878456992407, 51.803054759762993],
            [-0.156657377791563, 51.804017352098285],
            [-0.156633487306693, 51.804425218236602],
            [-0.161494426896555, 51.805057038566744],
            [-0.160085460893884, 51.807029441755994],
            [-0.163420354090659, 51.808312594918327],
            [-0.164596110382376, 51.808472129660423],
            [-0.166022074987867, 51.80911483275078],
            [-0.174001972973976, 51.810047414780087],
            [-0.183611841076806, 51.81507983665788],
            [-0.182372468752694, 51.817432785291899],
            [-0.181278358619894, 51.817075969134251],
            [-0.179714739593803, 51.819909444700571],
            [-0.173897103052154, 51.823658847540202],
            [-0.172413446613308, 51.824606939324021],
            [-0.171324802216794, 51.827015168341489],
            [-0.169493543756716, 51.828586361455912],
            [-0.168457620166693, 51.831155442744354],
            [-0.168348812870722, 51.832084423665037],
            [-0.170119041117092, 51.834100118926067],
            [-0.169795688448008, 51.834271331081382],
            [-0.171049029599885, 51.835255673460807],
            [-0.174170395961724, 51.836816631247764],
            [-0.169933811306782, 51.838095990681332],
            [-0.170182064556999, 51.838811122317139],
            [-0.171280184227633, 51.838578223777283],
            [-0.173495110525763, 51.839335600778035],
            [-0.175561289859102, 51.838711253138491],
            [-0.178569313881483, 51.841147952814971],
            [-0.176805215943396, 51.841875049504928],
            [-0.173402733557747, 51.840004071396073],
            [-0.172628700883592, 51.839796920843739],
            [-0.172074982211239, 51.840207344314003],
            [-0.170054272154354, 51.839134645219289],
            [-0.168846038068909, 51.840149023164948],
            [-0.168884052084259, 51.843469468553536],
            [-0.167452879382578, 51.843678273322979],
            [-0.166788628132759, 51.844198451009511],
            [-0.16718149271211, 51.844569649603123],
            [-0.167353186869275, 51.847205187123571],
            [-0.166754765978068, 51.847819008082489],
            [-0.166423640466306, 51.849464780192761],
            [-0.168415664223157, 51.851932650534366],
            [-0.169345155171718, 51.853823751528978],
            [-0.171662784612902, 51.854500928188735],
            [-0.170986471135346, 51.855494820207603],
            [-0.172809903775356, 51.856580617450021],
            [-0.169785881628481, 51.857133371232237],
            [-0.169043748631451, 51.858101050459048],
            [-0.169643159772911, 51.858825242580856],
            [-0.169477785941895, 51.859321724939562],
            [-0.165998999921481, 51.860802472487173],
            [-0.164586095860312, 51.861904435304517],
            [-0.166621871458777, 51.863485501717065],
            [-0.167279024846631, 51.865791396699748],
            [-0.167342307014803, 51.868272372393868],
            [-0.170548883516376, 51.870327493398158],
            [-0.171188349123189, 51.87350620787268],
            [-0.173103303109152, 51.8746815371411],
            [-0.173579701239879, 51.875365129923111],
            [-0.1585364980258, 51.872127306634709],
            [-0.156314077073941, 51.871271529535562],
            [-0.14851231618984, 51.869319129816283],
            [-0.146796457787023, 51.870600449306906],
            [-0.147966079129767, 51.871795921686335],
            [-0.149607858987804, 51.875150594266962],
            [-0.151306116783816, 51.875646674873749],
            [-0.152151972599215, 51.876346951663606],
            [-0.153008967788705, 51.876173375567603],
            [-0.15614771400411, 51.877575903603827],
            [-0.155407250725426, 51.878842057528821],
            [-0.15495787765545, 51.882543304637387],
            [-0.151288799276873, 51.885347857411077],
            [-0.151829968193893, 51.885722332927919],
            [-0.152597353180454, 51.885505988265997],
            [-0.155106521409778, 51.887145930631327],
            [-0.157330393265527, 51.886527962695155],
            [-0.157118074114524, 51.885821465133688],
            [-0.159211666488505, 51.885046759498159],
            [-0.160106425764434, 51.884343195631693],
            [-0.161182979651284, 51.884916628530625],
            [-0.159324794899335, 51.885834427588591],
            [-0.15748372158851, 51.886722792066642],
            [-0.157995295169515, 51.888675764957519],
            [-0.155172541979599, 51.892347021755796],
            [-0.156167579343722, 51.892607199846964],
            [-0.156372952236584, 51.894193902578593],
            [-0.154318657442236, 51.896409688207932],
            [-0.153009938566861, 51.899012108212901],
            [-0.155113552231997, 51.898825704304755],
            [-0.155835405283251, 51.900400720497622],
            [-0.158588069553831, 51.900836776092248],
            [-0.15943789193046, 51.900565026650867],
            [-0.159250965535047, 51.901048567191822],
            [-0.160136352268799, 51.901080397684488],
            [-0.159853212141216, 51.901677531678949],
            [-0.159380566587951, 51.902572032006262],
            [-0.159448754157206, 51.903713277275202],
            [-0.156546919358616, 51.90322365227982],
            [-0.155456677503835, 51.903684939784952],
            [-0.157325860217402, 51.904756390476749],
            [-0.15797973708673, 51.905829475358182],
            [-0.156305514706221, 51.909083517947217],
            [-0.156291837534457, 51.914812966237385],
            [-0.15673537196086, 51.916515792195682],
            [-0.158853050940292, 51.918742071291256],
            [-0.162218355923473, 51.920334985088367],
            [-0.159950055577814, 51.920186245605954],
            [-0.160072392742547, 51.921284273339566],
            [-0.155267909122234, 51.923901248434767],
            [-0.154830028552788, 51.923975311774733],
            [-0.154813456419523, 51.924485793155789],
            [-0.155269789257066, 51.925522521279582],
            [-0.152291345258425, 51.926475703261985],
            [-0.154823662608823, 51.92959156445179],
            [-0.151681098211799, 51.930850581895889],
            [-0.150718426783225, 51.93183536224079],
            [-0.148524291660763, 51.933122681385193],
            [-0.145291839602431, 51.934046515870463],
            [-0.142795524361676, 51.932429072177811],
            [-0.138363837896263, 51.932979506730263],
            [-0.130051730834079, 51.931414432673705],
            [-0.124807722476241, 51.929292529243149],
            [-0.122390580732181, 51.929042683942498],
            [-0.119307993030097, 51.928289398345356],
            [-0.119169435059151, 51.930594520862726],
            [-0.120608821774269, 51.930957408775527],
            [-0.117575357926443, 51.934241352379495],
            [-0.116700440191302, 51.936167820494916],
            [-0.118452444461088, 51.937024896947818],
            [-0.120552645774796, 51.939820774856742],
            [-0.120831491188646, 51.940807146752704],
            [-0.120190695910535, 51.942179875819967],
            [-0.117031000969051, 51.942031363057936],
            [-0.115728776383368, 51.942484400735196],
            [-0.110180513605913, 51.941892807571307],
            [-0.107569110852906, 51.94391091070996],
            [-0.107288849029955, 51.946317147062778],
            [-0.106273124501684, 51.948771799223437],
            [-0.107038695894517, 51.953950883016674],
            [-0.104480028964094, 51.95423252392446],
            [-0.104356767173463, 51.953980563180032],
            [-0.098272558328278, 51.955139510854607],
            [-0.097490471888044, 51.954430897571953],
            [-0.094220679696739, 51.95600195732154],
            [-0.092901024121741, 51.956473346235072],
            [-0.092551896445851, 51.956229403287281],
            [-0.08839117355206, 51.959542892550239],
            [-0.088759148265249, 51.959993968625632],
            [-0.087640623123978, 51.961720244381979],
            [-0.085941471633401, 51.961663858047103],
            [-0.080264016108466, 51.966008051529911],
            [-0.080148090695907, 51.967221873769098],
            [-0.079798408277625, 51.967297098863305],
            [-0.079241142208338, 51.966473337683247],
            [-0.07721041941308, 51.967187422777066],
            [-0.077901178658092, 51.967646501171878],
            [-0.07817774851305, 51.968633836792598],
            [-0.077743565532024, 51.969084439513693],
            [-0.075347070727918, 51.969531754010262],
            [-0.074684945417076, 51.971155666603302],
            [-0.070751463805484, 51.971284607429638],
            [-0.069627998217212, 51.966327805388417],
            [-0.068471344151037, 51.964416924384423],
            [-0.067105711266171, 51.959685412576789],
            [-0.065567623242854, 51.956836666254887],
            [-0.063668559779999, 51.957168722605878],
            [-0.062662983996588, 51.957706085511582],
            [-0.058852501018164, 51.95774312881499],
            [-0.057052826639205, 51.958433699921265],
            [-0.051173966909317, 51.96260053661063],
            [-0.045943152974027, 51.964761834684083],
            [-0.042204590702335, 51.967676978073136],
            [-0.040218249354201, 51.967692487867495],
            [-0.038610180418219, 51.969113422771066],
            [-0.038563831959618, 51.971408309407963],
            [-0.040065288210283, 51.972999719127614],
            [-0.040904630988704, 51.97495236236194],
            [-0.04353457579513, 51.977349295586279],
            [-0.045330830091657, 51.978064317697076],
            [-0.047112213369561, 51.978118154594959],
            [-0.048229848881348, 51.979439623750963],
            [-0.047981427460895, 51.97972774505709],
            [-0.040628712577851, 51.981376145606276],
            [-0.038132049272275, 51.981659185241121],
            [-0.03276361236661, 51.983405783216242],
            [-0.030657801770901, 51.983804896771048],
            [-0.031558857119317, 51.984909792742457],
            [-0.029959605450657, 51.987336962932076],
            [-0.026515315415815, 51.987400681818833],
            [-0.02568095224018, 51.995144987005396],
            [-0.023124237521556, 51.994952828537912],
            [-0.023128320067791, 51.996149730982147],
            [-0.023762633909348, 51.997160286351075],
            [-0.013526418718516, 51.996365819219264],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E07000243",
        LAD13CDO: "26UH",
        LAD13NM: "Stevenage",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.171180220649369, 51.928252578191383],
            [-0.167960439629222, 51.927957027323117],
            [-0.166496023429952, 51.9267383012143],
            [-0.164632046730382, 51.926866608339552],
            [-0.163226181799635, 51.92229297181688],
            [-0.163523236703007, 51.921749100505011],
            [-0.162603669647087, 51.920763621256967],
            [-0.162218355923473, 51.920334985088367],
            [-0.158853050940292, 51.918742071291256],
            [-0.15673537196086, 51.916515792195682],
            [-0.156291837534457, 51.914812966237385],
            [-0.156305514706221, 51.909083517947217],
            [-0.15797973708673, 51.905829475358182],
            [-0.157325860217402, 51.904756390476749],
            [-0.155456677503835, 51.903684939784952],
            [-0.156546919358616, 51.90322365227982],
            [-0.159448754157206, 51.903713277275202],
            [-0.159380566587951, 51.902572032006262],
            [-0.159853212141216, 51.901677531678949],
            [-0.160136352268799, 51.901080397684488],
            [-0.159250965535047, 51.901048567191822],
            [-0.15943789193046, 51.900565026650867],
            [-0.158588069553831, 51.900836776092248],
            [-0.155835405283251, 51.900400720497622],
            [-0.155113552231997, 51.898825704304755],
            [-0.153009938566861, 51.899012108212901],
            [-0.154318657442236, 51.896409688207932],
            [-0.156372952236584, 51.894193902578593],
            [-0.156167579343722, 51.892607199846964],
            [-0.155172541979599, 51.892347021755796],
            [-0.157995295169515, 51.888675764957519],
            [-0.15748372158851, 51.886722792066642],
            [-0.159324794899335, 51.885834427588591],
            [-0.161182979651284, 51.884916628530625],
            [-0.160106425764434, 51.884343195631693],
            [-0.159211666488505, 51.885046759498159],
            [-0.157118074114524, 51.885821465133688],
            [-0.157330393265527, 51.886527962695155],
            [-0.155106521409778, 51.887145930631327],
            [-0.152597353180454, 51.885505988265997],
            [-0.151829968193893, 51.885722332927919],
            [-0.151288799276873, 51.885347857411077],
            [-0.15495787765545, 51.882543304637387],
            [-0.155407250725426, 51.878842057528821],
            [-0.15614771400411, 51.877575903603827],
            [-0.153008967788705, 51.876173375567603],
            [-0.152151972599215, 51.876346951663606],
            [-0.151306116783816, 51.875646674873749],
            [-0.149607858987804, 51.875150594266962],
            [-0.147966079129767, 51.871795921686335],
            [-0.146796457787023, 51.870600449306906],
            [-0.14851231618984, 51.869319129816283],
            [-0.156314077073941, 51.871271529535562],
            [-0.1585364980258, 51.872127306634709],
            [-0.173579701239879, 51.875365129923111],
            [-0.175470073196629, 51.875787410460461],
            [-0.180263620180802, 51.876881340297643],
            [-0.185469318878512, 51.878822177688207],
            [-0.191920868645607, 51.882893240532162],
            [-0.193247330192932, 51.882570125392348],
            [-0.196108965884832, 51.882915263869087],
            [-0.198130652528319, 51.882137868084584],
            [-0.20024967706683, 51.881941006962677],
            [-0.204181760824174, 51.880145155889281],
            [-0.204915596676678, 51.883497752497419],
            [-0.205934960359077, 51.883149127566945],
            [-0.207644891677711, 51.88538360268975],
            [-0.208893536262476, 51.886322489544753],
            [-0.210636373320507, 51.889674213976122],
            [-0.208576636831813, 51.890089770678784],
            [-0.210427911029084, 51.892324554036144],
            [-0.212742342770013, 51.894209363859638],
            [-0.21460563010325, 51.894060509835064],
            [-0.214648452381848, 51.893273469674938],
            [-0.215317731573853, 51.893547984191343],
            [-0.215845600593825, 51.893430102672944],
            [-0.216451819101871, 51.893976108811593],
            [-0.215492594288707, 51.894269987589517],
            [-0.216073854019444, 51.896106852400763],
            [-0.218623306885619, 51.895955746134824],
            [-0.219744261225616, 51.895799167003723],
            [-0.225298255093899, 51.893203481912401],
            [-0.224904704761965, 51.89555611603587],
            [-0.225956868281941, 51.897551095412496],
            [-0.227092191812624, 51.898063658231372],
            [-0.22776935021926, 51.89891729607335],
            [-0.229672493750864, 51.898053949201241],
            [-0.230506087636911, 51.898885646297664],
            [-0.229340986413298, 51.899424717507117],
            [-0.230077251091571, 51.901253949135949],
            [-0.229826908284142, 51.902630434834592],
            [-0.228891481015168, 51.903462494499095],
            [-0.232376406747756, 51.903486117753282],
            [-0.232685519161102, 51.903610352117227],
            [-0.232114533586594, 51.904232105047356],
            [-0.234382134961055, 51.905236369875333],
            [-0.233022920065153, 51.907199572550972],
            [-0.231477280805585, 51.908428021416348],
            [-0.230009864205459, 51.911362481946227],
            [-0.228774328775656, 51.913091909359935],
            [-0.228225061926481, 51.915964630056948],
            [-0.228129718607436, 51.916946003509906],
            [-0.22935240208887, 51.917209881899488],
            [-0.226016545200269, 51.920939820055501],
            [-0.225842274957116, 51.922298558121625],
            [-0.224681932329541, 51.922478882303338],
            [-0.225466536937533, 51.924156003034753],
            [-0.226222414545121, 51.924534263659645],
            [-0.226550030046908, 51.924007782827623],
            [-0.227830201911398, 51.924589054492969],
            [-0.227164187451728, 51.925989843732708],
            [-0.227699780259477, 51.927050853213636],
            [-0.226217754399618, 51.928193870209022],
            [-0.224978939821656, 51.929327048918772],
            [-0.226547492552674, 51.930086224042604],
            [-0.220068309632011, 51.930988318151684],
            [-0.216976472703163, 51.930094492612987],
            [-0.216755101725092, 51.930615363361248],
            [-0.210415805091732, 51.930041686040703],
            [-0.210108655391564, 51.928366330122635],
            [-0.208529828763656, 51.928896208966485],
            [-0.207473526082478, 51.929781110922711],
            [-0.205071647094109, 51.928553986810002],
            [-0.202396768070749, 51.930558813704529],
            [-0.200426554405104, 51.929232981196378],
            [-0.199690191871272, 51.929642539872695],
            [-0.198655828183239, 51.928776084412846],
            [-0.197328281956652, 51.930472307049939],
            [-0.194407964182559, 51.93042664683184],
            [-0.195170685441384, 51.929180382292728],
            [-0.193118551056672, 51.927728182298793],
            [-0.190330534441721, 51.928664564978668],
            [-0.188001449168399, 51.928459685465313],
            [-0.184564248959679, 51.932431485531104],
            [-0.175675851574256, 51.930622469323247],
            [-0.172981769577279, 51.928767017749415],
            [-0.171180220649369, 51.928252578191383],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000001",
        LAD13CDO: "00BL",
        LAD13NM: "Bolton",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.438726381244793, 53.646029406283013],
            [-2.432653427107585, 53.641365936531081],
            [-2.430655821238388, 53.638445663841196],
            [-2.426145657037688, 53.634994137160895],
            [-2.425884999020639, 53.628187342082704],
            [-2.426356379874579, 53.626499460536849],
            [-2.425855336717615, 53.625843302479154],
            [-2.424610838227678, 53.625530439544164],
            [-2.421698303757275, 53.623819484462423],
            [-2.419930125120618, 53.623593806546879],
            [-2.416244888620259, 53.623678592571828],
            [-2.413834000384925, 53.62467027569889],
            [-2.409823585595528, 53.625378876418004],
            [-2.405377439993249, 53.625127073647327],
            [-2.40444524885481, 53.625042143772589],
            [-2.403543933911883, 53.624389941843589],
            [-2.401211312001907, 53.624582946638796],
            [-2.40001389723487, 53.6282640616378],
            [-2.40025915749496, 53.628934665494249],
            [-2.40079551125469, 53.629047020853285],
            [-2.398730680924457, 53.631688383537913],
            [-2.397847752585748, 53.632191969669442],
            [-2.385950842603534, 53.632998540062253],
            [-2.385017468762245, 53.631802513027523],
            [-2.383833101667635, 53.631269719420807],
            [-2.379134003420554, 53.63085416635758],
            [-2.379331626300824, 53.629285087281154],
            [-2.379099466988208, 53.62700370151429],
            [-2.381125684449654, 53.627347805662687],
            [-2.383410834038769, 53.625561723935583],
            [-2.377974232892547, 53.623252860153904],
            [-2.376782898783239, 53.622071064864414],
            [-2.376294283255326, 53.622039346341808],
            [-2.377218084043378, 53.621875546454206],
            [-2.376797685919559, 53.621185672809176],
            [-2.379610896176061, 53.620813651362646],
            [-2.379363177040597, 53.620332665388787],
            [-2.377558241746617, 53.620519939489938],
            [-2.375964165317689, 53.618239240381953],
            [-2.375323413001044, 53.618434500786442],
            [-2.374919562151063, 53.618069045367598],
            [-2.376222113200706, 53.61749689585168],
            [-2.375019387580067, 53.617052157443467],
            [-2.374121266712002, 53.616177713330735],
            [-2.369434641909971, 53.615256600304306],
            [-2.36854082674709, 53.615552375872007],
            [-2.363008719572881, 53.610324628066039],
            [-2.36455456949145, 53.609055273193292],
            [-2.363656287564759, 53.607765491158766],
            [-2.36401559682248, 53.60666602818349],
            [-2.363721533100561, 53.606037742024824],
            [-2.365160075525946, 53.604262663129141],
            [-2.364660974813115, 53.602989644771391],
            [-2.366465770579083, 53.601819243511983],
            [-2.366897454089485, 53.600192833968414],
            [-2.368508646458435, 53.59798035083233],
            [-2.369416342310874, 53.597413084181866],
            [-2.368289506756585, 53.59606561634412],
            [-2.368742077816946, 53.59563727666113],
            [-2.373405719542717, 53.593859296708544],
            [-2.376902640740328, 53.593269477970246],
            [-2.376103363913841, 53.591224454586055],
            [-2.374803270564162, 53.590789007656014],
            [-2.374650440599301, 53.590420066440409],
            [-2.375084498907026, 53.589621441145951],
            [-2.376587856930117, 53.588795182740128],
            [-2.376626137857324, 53.587834209795048],
            [-2.376017781620937, 53.587712984330267],
            [-2.375947597253486, 53.586444052724161],
            [-2.375252374392581, 53.585893454195912],
            [-2.375691002605226, 53.585105597671216],
            [-2.375392959883059, 53.584201408519768],
            [-2.376044191511028, 53.582353157822354],
            [-2.376585499980474, 53.582077309801512],
            [-2.375258094740473, 53.580574138829306],
            [-2.374486229164035, 53.580226013653046],
            [-2.374773971655747, 53.577924096392891],
            [-2.373474016263343, 53.576428908318618],
            [-2.37316675364017, 53.5739517802837],
            [-2.372510504699556, 53.573855853860906],
            [-2.372569619811362, 53.572850772081125],
            [-2.37384391513015, 53.572139410772131],
            [-2.373984334106193, 53.571453160244893],
            [-2.373667991645528, 53.570342289995565],
            [-2.37098504268149, 53.57072815679286],
            [-2.369514905156024, 53.570454969806036],
            [-2.368326696989942, 53.56943396421412],
            [-2.366144860474953, 53.568397118635566],
            [-2.360978399470923, 53.568289690690008],
            [-2.357346376691504, 53.563206900933139],
            [-2.355996993185857, 53.560553068383904],
            [-2.357254125638169, 53.55781685406545],
            [-2.355917677903193, 53.556682014621671],
            [-2.356658618689502, 53.556115336289245],
            [-2.35707110121632, 53.554369461534606],
            [-2.358297600297517, 53.55321707673388],
            [-2.359712703346382, 53.552775991113194],
            [-2.360976452079746, 53.553352830259747],
            [-2.361477597860216, 53.55547886418934],
            [-2.362187835548457, 53.556099608441137],
            [-2.36392731590608, 53.555457049129117],
            [-2.366716572210415, 53.553330862388414],
            [-2.368205974343831, 53.553285835731955],
            [-2.371772570523766, 53.554301268312329],
            [-2.373590925374245, 53.553898312357923],
            [-2.367222759913244, 53.551650278847724],
            [-2.36232970126907, 53.551329046848856],
            [-2.362430778131891, 53.55044068832629],
            [-2.355861805445237, 53.549309016039238],
            [-2.351280353773955, 53.547754097002034],
            [-2.347547669161236, 53.547338061895353],
            [-2.344266215258, 53.545610105140526],
            [-2.343855840909526, 53.545251751222537],
            [-2.341542618263093, 53.545847123676729],
            [-2.341821009396331, 53.543127341271727],
            [-2.341411041286378, 53.541314656436235],
            [-2.338762493322519, 53.538069294967308],
            [-2.337896711947235, 53.537918943527451],
            [-2.337293344927466, 53.537182700946182],
            [-2.337752542829922, 53.5370573640703],
            [-2.337042779275629, 53.535841440451001],
            [-2.337296896730874, 53.534594032654333],
            [-2.337803480963478, 53.534549457314846],
            [-2.338238221971877, 53.533631410752683],
            [-2.34177859702281, 53.536368191672373],
            [-2.346285710576166, 53.537512921308426],
            [-2.348662992517257, 53.537248041758261],
            [-2.34883501286616, 53.536699247435237],
            [-2.348042970575187, 53.536158657610862],
            [-2.348742035557121, 53.535667650555673],
            [-2.351463242837985, 53.535772033014808],
            [-2.352286187811189, 53.535124242538075],
            [-2.353684211088776, 53.535051801047935],
            [-2.353788983941141, 53.534770153892325],
            [-2.352149123197357, 53.53424378307173],
            [-2.352669569010425, 53.533691259054962],
            [-2.353893646632129, 53.533933023595644],
            [-2.354679066630391, 53.532775684867346],
            [-2.352827063914195, 53.53231107390387],
            [-2.353271485722666, 53.531329125606618],
            [-2.352751975747694, 53.531264146150285],
            [-2.353604937966596, 53.530248631399019],
            [-2.350868167664594, 53.529679642971175],
            [-2.354422405882413, 53.526253556450165],
            [-2.368184278071253, 53.529238344160568],
            [-2.368607913478828, 53.528165618861642],
            [-2.370875442755882, 53.528881271286302],
            [-2.385202797317258, 53.531448878301184],
            [-2.389160427474907, 53.532980265693084],
            [-2.394900331950216, 53.537121274409024],
            [-2.398356026205195, 53.538293579534823],
            [-2.400113217925193, 53.538543879280077],
            [-2.402240653097257, 53.538692237472482],
            [-2.402285168035601, 53.538453894723723],
            [-2.408774851169627, 53.538292553601643],
            [-2.410344624448653, 53.538503794962736],
            [-2.414030651540277, 53.53910229464217],
            [-2.421383259457346, 53.541044161653723],
            [-2.421417409917021, 53.540684504651416],
            [-2.430489235518338, 53.54126967803419],
            [-2.435763573875215, 53.541897730821368],
            [-2.437203981081056, 53.542122574129017],
            [-2.438102776414675, 53.54002138574414],
            [-2.440983001873782, 53.538681405688287],
            [-2.441914799661098, 53.536152216027844],
            [-2.441251010236964, 53.535145267927454],
            [-2.441477978544458, 53.534338167476861],
            [-2.442010810969192, 53.533637796838136],
            [-2.442468113420078, 53.53365318268208],
            [-2.443110626617126, 53.532333996588946],
            [-2.444360375361442, 53.531523992490051],
            [-2.444608883158426, 53.530908259116345],
            [-2.447808850021035, 53.529352996358206],
            [-2.448128901249565, 53.528089819345979],
            [-2.451115538170886, 53.528634967365576],
            [-2.455994729942108, 53.529389456192654],
            [-2.461548446340604, 53.530699325461853],
            [-2.466066687682328, 53.530693475868603],
            [-2.469456254658984, 53.532030274058698],
            [-2.472418834956137, 53.531720183843994],
            [-2.478391923566144, 53.533488720664167],
            [-2.481984633577329, 53.533396086697181],
            [-2.487126926843126, 53.534105100711308],
            [-2.489742597893843, 53.534586079583448],
            [-2.490164176626561, 53.535555103857014],
            [-2.496470545933993, 53.536683191794985],
            [-2.496398479854056, 53.537100555124525],
            [-2.498069842004611, 53.53744864581045],
            [-2.499211376624828, 53.53803262686943],
            [-2.502180275909374, 53.537856600360918],
            [-2.50363091481373, 53.538543506257987],
            [-2.505246328514742, 53.538618483650005],
            [-2.505243558705353, 53.538892643058816],
            [-2.507012077843706, 53.538772798836924],
            [-2.507309573365701, 53.537783705814725],
            [-2.507972987692784, 53.53648295565246],
            [-2.509062941196611, 53.535651380616912],
            [-2.50926482412036, 53.534621342366613],
            [-2.510121607517528, 53.534103548912157],
            [-2.513063106127859, 53.536303916687253],
            [-2.51553130055823, 53.535788145165704],
            [-2.515282351336595, 53.53442207408424],
            [-2.515830338374918, 53.534447574599419],
            [-2.516150546674558, 53.533856549222662],
            [-2.51601800310272, 53.53213852754596],
            [-2.51538759185993, 53.531038363081343],
            [-2.515815340650451, 53.529243318721747],
            [-2.517204418775319, 53.528475093430075],
            [-2.518335786420376, 53.528471989773259],
            [-2.518814752012988, 53.527917120612322],
            [-2.519679209653888, 53.527923252196935],
            [-2.520560820546858, 53.527237190455494],
            [-2.52099160961389, 53.527184977871407],
            [-2.52120278875025, 53.527552584492284],
            [-2.521776244551988, 53.527079985135913],
            [-2.522735848156215, 53.527096465332299],
            [-2.523166083023726, 53.526632575197276],
            [-2.525389278458938, 53.526364853340269],
            [-2.526684249537179, 53.525449519651197],
            [-2.527797424647687, 53.525566852089618],
            [-2.527475777253796, 53.524689198894812],
            [-2.527956887903481, 53.524683479110926],
            [-2.528116491719895, 53.523933134674095],
            [-2.528701375048823, 53.523903583210689],
            [-2.528243330057114, 53.523701570144681],
            [-2.528529840439093, 53.523331775658995],
            [-2.528973463913325, 53.523345093344872],
            [-2.528982113205571, 53.523070906348536],
            [-2.531321332696938, 53.522931994145743],
            [-2.531226130128841, 53.523279372825485],
            [-2.531677306460034, 53.523293545755529],
            [-2.532285638757497, 53.52284950329804],
            [-2.532859725035381, 53.523282886480345],
            [-2.532900158176453, 53.523019343609661],
            [-2.533730973913465, 53.522996760267851],
            [-2.533760180167877, 53.523999744641934],
            [-2.535038670441793, 53.524189976779738],
            [-2.53457892190604, 53.524693591474822],
            [-2.535436328867293, 53.5258196054181],
            [-2.535148514144399, 53.525962912513563],
            [-2.535960993476761, 53.526156119451393],
            [-2.536089329618709, 53.526881813081339],
            [-2.536701087670064, 53.527066027846516],
            [-2.536265894419295, 53.527603694712461],
            [-2.537341173360738, 53.527819082879873],
            [-2.537204120712388, 53.528074971780171],
            [-2.53822999876518, 53.528437985056442],
            [-2.538624132439879, 53.52823217101583],
            [-2.538548645095658, 53.528583960703592],
            [-2.539792920936402, 53.528918112007446],
            [-2.53970786652577, 53.529465894426778],
            [-2.540707258158404, 53.529640248047606],
            [-2.541399362268466, 53.530677980628312],
            [-2.541626840509559, 53.530416284165987],
            [-2.542495793227541, 53.530533687651804],
            [-2.543763367796521, 53.532561120832064],
            [-2.545267055899041, 53.532993806891056],
            [-2.544996001916755, 53.533386041758305],
            [-2.549094387683924, 53.534489944669048],
            [-2.549300754595089, 53.534930330528496],
            [-2.54999540659042, 53.53486152029722],
            [-2.550426879240102, 53.53544018953216],
            [-2.550574860207835, 53.53510064253112],
            [-2.551059461819683, 53.535238629541261],
            [-2.550968254727194, 53.535534770781709],
            [-2.552081176112291, 53.536070741662016],
            [-2.553701454533454, 53.536172007456827],
            [-2.554486389025466, 53.537353049543128],
            [-2.555384117330317, 53.537349780583931],
            [-2.556468875915422, 53.537916402340123],
            [-2.556803518480339, 53.537776421496098],
            [-2.556891892476597, 53.538071730542107],
            [-2.560452640794005, 53.538055088211117],
            [-2.561934307141449, 53.539189668072389],
            [-2.561644938223065, 53.540469188740921],
            [-2.560703815458598, 53.540847527319912],
            [-2.560436915997694, 53.541661336563706],
            [-2.559151671726427, 53.542381039706584],
            [-2.559367848457367, 53.543783127835638],
            [-2.558020119895906, 53.544462662671954],
            [-2.559620657014874, 53.546358936920235],
            [-2.562967913905593, 53.547374196997453],
            [-2.566543381573082, 53.547384271068687],
            [-2.5676205860319, 53.54803262239237],
            [-2.569332319404841, 53.547602024806523],
            [-2.569539238653459, 53.54795069040491],
            [-2.567788186145695, 53.549165271464801],
            [-2.56819095986685, 53.549825807334841],
            [-2.566611968695939, 53.550145201097344],
            [-2.56694591622692, 53.550738653786816],
            [-2.564070180160861, 53.551158535225213],
            [-2.563696309011294, 53.551759828641039],
            [-2.562566223128503, 53.551906270548692],
            [-2.562646224013654, 53.552816424392894],
            [-2.561049301822594, 53.553275149613697],
            [-2.56118984643535, 53.554770168087728],
            [-2.559823494574717, 53.555549580505037],
            [-2.560189741756653, 53.556192336619418],
            [-2.561265310913305, 53.556593569891199],
            [-2.562281100960297, 53.55749393341484],
            [-2.562615897202063, 53.558947587576625],
            [-2.563379879052072, 53.559523744636884],
            [-2.563148142880281, 53.56026188864886],
            [-2.559633893016983, 53.561082854919206],
            [-2.560062302805189, 53.562084857465031],
            [-2.561390507635803, 53.562171209383443],
            [-2.562850495713759, 53.56308566025038],
            [-2.56395223697744, 53.563169454273073],
            [-2.564447946571652, 53.563660580360782],
            [-2.568471888567227, 53.564698556729134],
            [-2.567761678751214, 53.565443477272403],
            [-2.568064724219256, 53.565636188118873],
            [-2.56719853230242, 53.56758899273629],
            [-2.565680971374832, 53.568714342658822],
            [-2.565733387934309, 53.570038989152366],
            [-2.567853501106617, 53.571506637203633],
            [-2.568544888430099, 53.571602224588318],
            [-2.568609071340162, 53.572108866681184],
            [-2.572143046662521, 53.573700039416217],
            [-2.572208195778031, 53.57405117512284],
            [-2.573321929695502, 53.574217519479696],
            [-2.575062208556134, 53.576706147454175],
            [-2.578551062124231, 53.577491086515636],
            [-2.579084328579787, 53.578384651124431],
            [-2.577714683416837, 53.57939707992422],
            [-2.577965819438707, 53.579981012556338],
            [-2.577563855284565, 53.580852134605088],
            [-2.578189904612792, 53.581348865432872],
            [-2.582185544721369, 53.58281796269776],
            [-2.583557656893569, 53.584176615713446],
            [-2.585087842608957, 53.585052700642159],
            [-2.588817306724758, 53.586348503466361],
            [-2.595420185141947, 53.588068554290295],
            [-2.596093697805618, 53.58880224938747],
            [-2.601701763376065, 53.588828097736474],
            [-2.603257678238889, 53.588184778801065],
            [-2.606250215772968, 53.588282901313761],
            [-2.6077161275336, 53.589058353123598],
            [-2.61247425416027, 53.590479419859555],
            [-2.616310352662305, 53.590706895919809],
            [-2.618062529316868, 53.591099636788243],
            [-2.619916608218056, 53.590917463733838],
            [-2.620753343151172, 53.590386402363521],
            [-2.623749753546142, 53.589605898277291],
            [-2.62756580043867, 53.5893989769258],
            [-2.628617084610676, 53.590404654431545],
            [-2.628229078017079, 53.591541931412394],
            [-2.62590820613725, 53.593668176373207],
            [-2.623963758726673, 53.593876080998101],
            [-2.622788792921896, 53.593601765931552],
            [-2.621293923108403, 53.594485911034738],
            [-2.62085697474289, 53.594156507251512],
            [-2.619921480309793, 53.594242255220209],
            [-2.618801812769334, 53.595372503986773],
            [-2.617397146498522, 53.595821093870157],
            [-2.6179401326018, 53.596894203054653],
            [-2.617148362911693, 53.597100530124706],
            [-2.617139570401873, 53.597533816948861],
            [-2.616027937860855, 53.59829277598422],
            [-2.615304055383925, 53.599845204643223],
            [-2.615817803499781, 53.600570621809169],
            [-2.615218502998676, 53.601340416636084],
            [-2.615159752545034, 53.602808525145115],
            [-2.613502006215795, 53.603644870539064],
            [-2.612249391912409, 53.603867009852848],
            [-2.611273922549213, 53.604967684309919],
            [-2.610311933240072, 53.605225170161305],
            [-2.608648691282469, 53.606323037862872],
            [-2.604890866272233, 53.607015329169947],
            [-2.60264696419511, 53.608744335671297],
            [-2.601847996635002, 53.60835916077383],
            [-2.600736226974044, 53.608402500196071],
            [-2.597600150197777, 53.609049192345779],
            [-2.595927482742421, 53.610799485058529],
            [-2.594430595879969, 53.610547168133358],
            [-2.593479573556166, 53.60998652075881],
            [-2.591682099584983, 53.609744648747643],
            [-2.59118946664876, 53.609427097941477],
            [-2.591470486749707, 53.60898707580602],
            [-2.589901857038436, 53.608677531419026],
            [-2.589759395874035, 53.608107471575217],
            [-2.588654731222334, 53.608444583803582],
            [-2.588664901001713, 53.607871973830818],
            [-2.588036686568691, 53.60769170056961],
            [-2.587393971927857, 53.607881816557672],
            [-2.586796246297426, 53.607393085582963],
            [-2.587374513678065, 53.606915660987895],
            [-2.586733512798948, 53.606029855566192],
            [-2.585194215791747, 53.605317426153348],
            [-2.584491225719149, 53.605518607593758],
            [-2.584544143762095, 53.605192070670853],
            [-2.583066610587808, 53.604679753084604],
            [-2.582907211127466, 53.604188865291256],
            [-2.583424390357897, 53.603774675899523],
            [-2.58242056112075, 53.603527892899095],
            [-2.58195007238143, 53.603052000626946],
            [-2.582221271633394, 53.602772939771214],
            [-2.58142773126517, 53.602650962151905],
            [-2.581769791380294, 53.602359872943133],
            [-2.581074313816949, 53.602335389434302],
            [-2.58119066972509, 53.602004950331313],
            [-2.580611328168342, 53.601963720713016],
            [-2.580734865948201, 53.601495724840731],
            [-2.579954341709576, 53.601108516842693],
            [-2.580434221088038, 53.601060348414343],
            [-2.580886720221056, 53.60000830789852],
            [-2.580489686748082, 53.600036301695894],
            [-2.580358636848326, 53.599515610958335],
            [-2.579506704035829, 53.598986729869893],
            [-2.578928931071436, 53.59916749783762],
            [-2.579073291646855, 53.59889534963375],
            [-2.57850723706035, 53.598938536455186],
            [-2.578381362161462, 53.598352203229666],
            [-2.577874917059536, 53.598333977761065],
            [-2.57812584603484, 53.597898625865248],
            [-2.576742864265184, 53.597314764968331],
            [-2.576553960958388, 53.5968689525588],
            [-2.576905645143941, 53.596730633244945],
            [-2.576158715434676, 53.596582329681155],
            [-2.576261196419488, 53.596118933707977],
            [-2.575528575273354, 53.596355260536967],
            [-2.575954872027954, 53.596148273170051],
            [-2.57519748622477, 53.595896647441045],
            [-2.57535139739517, 53.595214586877994],
            [-2.573954104147542, 53.594791654879934],
            [-2.574004519110098, 53.594275479046757],
            [-2.569279408328528, 53.595581604556273],
            [-2.561712124018303, 53.598615907714787],
            [-2.559591319910012, 53.598897297700788],
            [-2.558981422151416, 53.600313122991544],
            [-2.558149253605939, 53.600461721937997],
            [-2.557803721128187, 53.600844441057028],
            [-2.557667585701983, 53.601636951926146],
            [-2.557261494102286, 53.601782658551102],
            [-2.557291557759924, 53.60303280158945],
            [-2.55661499474372, 53.603641768205158],
            [-2.555870233025567, 53.603892411757251],
            [-2.555787190948106, 53.604358395339531],
            [-2.552647261876313, 53.604970669996248],
            [-2.552218760754769, 53.607340185101151],
            [-2.551701478660685, 53.607889064588768],
            [-2.551434052445124, 53.607663790861224],
            [-2.550249658282736, 53.60770340061552],
            [-2.549812344250523, 53.608127864548017],
            [-2.5473930259565, 53.608742977805306],
            [-2.547286606841229, 53.609162322387213],
            [-2.546123052861545, 53.608946526395478],
            [-2.544933225725795, 53.609154190585997],
            [-2.542743869866606, 53.610032419431008],
            [-2.540518880711978, 53.610258214251992],
            [-2.53805037009095, 53.611303902336111],
            [-2.537153658659676, 53.612701126869077],
            [-2.534451474343331, 53.614531572819537],
            [-2.533496607483985, 53.615048170713841],
            [-2.53111506317764, 53.615471332700032],
            [-2.529649999359239, 53.616414413487384],
            [-2.529376256849145, 53.617007956886781],
            [-2.525159583757211, 53.618667812201679],
            [-2.511324724074385, 53.626979026613256],
            [-2.510412870873879, 53.626345648322086],
            [-2.507932225530618, 53.625388163419771],
            [-2.494828925453383, 53.622127285658905],
            [-2.479197211404846, 53.617019941012977],
            [-2.472994751690867, 53.616615883147169],
            [-2.470444526969013, 53.619875215785385],
            [-2.467085125874208, 53.620952595665564],
            [-2.466021286208361, 53.621683000043042],
            [-2.463468517423353, 53.62230412204886],
            [-2.463410624642759, 53.621987957890681],
            [-2.459701851983658, 53.62002663689919],
            [-2.457525985100464, 53.62096886365989],
            [-2.455730745797394, 53.621465578860068],
            [-2.455024878942404, 53.621340631858018],
            [-2.453782723533638, 53.621958352506596],
            [-2.454349847854458, 53.622396626835837],
            [-2.453180536848127, 53.622761493069],
            [-2.453558331429278, 53.623449464128242],
            [-2.454264379499231, 53.623447684039185],
            [-2.455266392996587, 53.624113503515666],
            [-2.456484551348427, 53.623929994354597],
            [-2.458291185343045, 53.624615186401449],
            [-2.45681829827164, 53.625712898184439],
            [-2.45622210821036, 53.626791072578669],
            [-2.454918508507777, 53.626911086847663],
            [-2.453405437766395, 53.627943294159998],
            [-2.45190212776411, 53.627773710263384],
            [-2.452117046388483, 53.628216920019],
            [-2.450645758446516, 53.627789235281718],
            [-2.449252073632903, 53.631103965781939],
            [-2.448393458115501, 53.631846024086812],
            [-2.44968136463412, 53.633362008476709],
            [-2.449243526192614, 53.635563985187737],
            [-2.447969213654102, 53.63661949317553],
            [-2.449172410763562, 53.637837388327483],
            [-2.448616171608456, 53.637869136510382],
            [-2.448879694869706, 53.639042017238765],
            [-2.450272272066156, 53.641415981347564],
            [-2.449698697584586, 53.641528694156598],
            [-2.449941511693942, 53.641888211098767],
            [-2.448848175461927, 53.642055005130509],
            [-2.446745142718072, 53.641431898814552],
            [-2.445865250178615, 53.641901672090484],
            [-2.445984813893164, 53.641621691252794],
            [-2.445621650087141, 53.641607764253024],
            [-2.44526580223864, 53.642000977623432],
            [-2.44307969997328, 53.642393790673566],
            [-2.443033556227364, 53.643042015746666],
            [-2.442287855468908, 53.643766532350035],
            [-2.438726381244793, 53.646029406283013],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000002",
        LAD13CDO: "00BM",
        LAD13NM: "Bury",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.353340267813498, 53.658466801811322],
            [-2.33340397275459, 53.655170509631994],
            [-2.32656394045187, 53.655621707164549],
            [-2.325329391473475, 53.65543991315225],
            [-2.320408742652995, 53.656014056031374],
            [-2.320158080947087, 53.655682163208951],
            [-2.318443019840919, 53.655180703902012],
            [-2.31556128421261, 53.655061605373746],
            [-2.315583382481039, 53.65579138980781],
            [-2.313968431292324, 53.656944331730003],
            [-2.313472355180546, 53.658604855446789],
            [-2.311893665646787, 53.659158160442445],
            [-2.311775812567665, 53.659796630066005],
            [-2.307737869420485, 53.660947686623985],
            [-2.305989463387594, 53.662952943764758],
            [-2.304012215677664, 53.663886464575896],
            [-2.304099908853125, 53.664295204600037],
            [-2.302983567050151, 53.665212135899594],
            [-2.301137499923132, 53.666101234315882],
            [-2.300105747935389, 53.667010734912154],
            [-2.298960696293375, 53.666674746596748],
            [-2.295693013714979, 53.664281221905206],
            [-2.295902865516603, 53.662697882181845],
            [-2.293826873279337, 53.657955432151347],
            [-2.294264936282106, 53.657196650050167],
            [-2.293865618252708, 53.656130731777168],
            [-2.295359006158016, 53.655694721612186],
            [-2.296420600636024, 53.654965849011759],
            [-2.295612021665263, 53.654877967594963],
            [-2.295599385513379, 53.653073166638201],
            [-2.297190405997028, 53.652554200453494],
            [-2.297619896390666, 53.651883511030562],
            [-2.297595495508649, 53.648421311580854],
            [-2.295739081960767, 53.641479817561702],
            [-2.295042768364972, 53.640103641731613],
            [-2.292319257861648, 53.637992701495008],
            [-2.291306466653996, 53.636316170518384],
            [-2.289995339274043, 53.635229083711181],
            [-2.288788222497042, 53.633640187726854],
            [-2.288900493398977, 53.632808504605975],
            [-2.286676805780483, 53.631593252310417],
            [-2.285837186492782, 53.63064790132799],
            [-2.28486880214496, 53.628092157908128],
            [-2.283514066323189, 53.626978138202958],
            [-2.284498086847927, 53.625358815740377],
            [-2.282459890939961, 53.623089619761089],
            [-2.282425210466686, 53.621745957911024],
            [-2.283166971979078, 53.620759989976747],
            [-2.282845953177801, 53.620231341421508],
            [-2.280729405298478, 53.620909549041343],
            [-2.278291510184554, 53.620322921188269],
            [-2.277747133759005, 53.618692818347171],
            [-2.276025086627026, 53.618203350325274],
            [-2.27581000064471, 53.618833923416872],
            [-2.272553031392821, 53.618496242089613],
            [-2.271789600099509, 53.614498204402281],
            [-2.26966986027481, 53.612762876012894],
            [-2.268811095159148, 53.611302418956164],
            [-2.266571329525588, 53.609183505756107],
            [-2.264350536591913, 53.607646049319207],
            [-2.263979563820934, 53.6077771988802],
            [-2.261648883854191, 53.606527559396966],
            [-2.259120312243936, 53.606009050034658],
            [-2.255728056792949, 53.606106236747351],
            [-2.250984329754383, 53.607161625147931],
            [-2.250491598329776, 53.604858964818632],
            [-2.248974422804547, 53.603338621774498],
            [-2.249078296598703, 53.602757762862943],
            [-2.248364728983836, 53.60219298609222],
            [-2.245231214568042, 53.601256584896539],
            [-2.244935156559731, 53.600755646004671],
            [-2.245582744581371, 53.599078003443594],
            [-2.247140584203625, 53.599329161278924],
            [-2.250114784592792, 53.599150400580072],
            [-2.255542005657469, 53.597075212704439],
            [-2.25825820499497, 53.595009260278637],
            [-2.259224377292321, 53.592165970316202],
            [-2.264480805039073, 53.592467248883075],
            [-2.265638026313836, 53.591495744842369],
            [-2.266517842424668, 53.591342783177851],
            [-2.269955531181296, 53.591925600596859],
            [-2.269320693341799, 53.589978369690357],
            [-2.270025288752559, 53.589125588256238],
            [-2.273964910400201, 53.590953832813859],
            [-2.275510975931974, 53.59150485765575],
            [-2.276588040094384, 53.591487090464057],
            [-2.27855248635298, 53.589488924422348],
            [-2.278796573440857, 53.588473576110651],
            [-2.278059292243592, 53.587312206811667],
            [-2.277888805849037, 53.586194458065286],
            [-2.278425121924703, 53.585514592958113],
            [-2.281540991219125, 53.586815093768699],
            [-2.282440589705783, 53.586706909685233],
            [-2.282335078737602, 53.584024148861488],
            [-2.281411707526167, 53.582820094812277],
            [-2.280722256001909, 53.582717451492414],
            [-2.280429987672282, 53.582148278493932],
            [-2.278800582215683, 53.581973222532476],
            [-2.278638829903357, 53.581493623771074],
            [-2.277566992891991, 53.580887608617736],
            [-2.277475569952255, 53.580074378394478],
            [-2.273642188272867, 53.579361453874789],
            [-2.272123456944022, 53.579465590224679],
            [-2.271010690593947, 53.57999303595939],
            [-2.269022342353615, 53.580356161336333],
            [-2.268382237374273, 53.580878922420233],
            [-2.267561613331636, 53.584387101613579],
            [-2.26527846023421, 53.585711673571986],
            [-2.262492514657242, 53.584906180009739],
            [-2.261029592767542, 53.584788940354507],
            [-2.25911561505787, 53.585260496535867],
            [-2.25487598591307, 53.582831983767591],
            [-2.254461430407476, 53.582466144137364],
            [-2.254523745634058, 53.581023388043938],
            [-2.250600970129259, 53.57842596612528],
            [-2.250467484943233, 53.576796664739334],
            [-2.244293421904553, 53.572317083622167],
            [-2.243267982925432, 53.571512923058208],
            [-2.243975823117242, 53.570137165601452],
            [-2.245056226243831, 53.570266184529103],
            [-2.245451453825441, 53.568634891342306],
            [-2.243180345220214, 53.567822495661346],
            [-2.245886900730724, 53.565354155139246],
            [-2.247104656162052, 53.563425443558636],
            [-2.2481693664516, 53.563222797279188],
            [-2.247226047952623, 53.56197807051668],
            [-2.246440290882344, 53.561597689308243],
            [-2.244063313273519, 53.561999852318003],
            [-2.242626383399544, 53.561529093297807],
            [-2.241580517717685, 53.561327177105653],
            [-2.24087678118146, 53.561554205035065],
            [-2.240694778999471, 53.561141108334176],
            [-2.23903283687061, 53.560913444264692],
            [-2.237218907989888, 53.559422298738198],
            [-2.236461674913376, 53.559259313101549],
            [-2.241654796840765, 53.557146550311799],
            [-2.237367851898761, 53.553673957908138],
            [-2.236263685585205, 53.55109828806922],
            [-2.235741992854972, 53.548210462053966],
            [-2.234103698523244, 53.543151440135198],
            [-2.234350668714856, 53.541693942225308],
            [-2.236394905534565, 53.540530415373468],
            [-2.237230167103414, 53.53878771273142],
            [-2.238476539280167, 53.538792421816723],
            [-2.239378708302797, 53.540102020431434],
            [-2.247939836531605, 53.544127526473609],
            [-2.251673024919675, 53.544588014517196],
            [-2.253104317660599, 53.543941424071079],
            [-2.258529432114651, 53.543196357363762],
            [-2.266989274883324, 53.53842110566918],
            [-2.268541271750506, 53.537128694051013],
            [-2.268547388036221, 53.535235727558415],
            [-2.267993521421789, 53.535450894725933],
            [-2.267825406725077, 53.535110612085859],
            [-2.268271181133143, 53.534981078075653],
            [-2.267471705641489, 53.534050775430771],
            [-2.266050784809957, 53.530250962453167],
            [-2.265909562825992, 53.526975907392284],
            [-2.264086678710902, 53.524691505970118],
            [-2.257091718115438, 53.527090487319633],
            [-2.246533011956399, 53.529276250451133],
            [-2.248537852532938, 53.5251527172599],
            [-2.248747092578673, 53.524822407706417],
            [-2.251707670639775, 53.525770773016788],
            [-2.251457473799367, 53.524023956357205],
            [-2.25347996241819, 53.524237200743194],
            [-2.252876803677105, 53.522498325762299],
            [-2.253479885581017, 53.522457498530969],
            [-2.253468412662378, 53.521799572167488],
            [-2.254392179427343, 53.521663681203165],
            [-2.254175060327842, 53.520669127617701],
            [-2.255633280783828, 53.520626466723613],
            [-2.256504306115493, 53.520254277302016],
            [-2.256927578904924, 53.517923573543356],
            [-2.257554790777689, 53.518142438650223],
            [-2.258552451128356, 53.518020737459473],
            [-2.258986268994621, 53.518427871188223],
            [-2.270839377087247, 53.517308592704353],
            [-2.271615580858756, 53.517468621561797],
            [-2.274668394105445, 53.516356965181849],
            [-2.279449508055686, 53.515966564574228],
            [-2.282597737680659, 53.515189749725408],
            [-2.285081280655609, 53.513584809081657],
            [-2.289447185632465, 53.512736588905149],
            [-2.2907978327579, 53.512004347520907],
            [-2.291765982521005, 53.513985730908935],
            [-2.294355963353599, 53.515394153716613],
            [-2.295982602810594, 53.515746075664573],
            [-2.29773716603563, 53.515626663674055],
            [-2.30226111099326, 53.517070527212177],
            [-2.302620866068362, 53.517820149117647],
            [-2.302072028801629, 53.520380539381456],
            [-2.302832414604878, 53.521892262556023],
            [-2.310474356136423, 53.527170414008388],
            [-2.312911361038028, 53.527484043428551],
            [-2.319397464527183, 53.527027347309371],
            [-2.322954841824812, 53.527078006883102],
            [-2.324758021555402, 53.527346366815614],
            [-2.326791152134886, 53.528480553175932],
            [-2.328362395906674, 53.52956653889904],
            [-2.335208639786142, 53.531329928872054],
            [-2.338238221971877, 53.533631410752683],
            [-2.337803480963478, 53.534549457314846],
            [-2.337296896730874, 53.534594032654333],
            [-2.337042779275629, 53.535841440451001],
            [-2.337752542829922, 53.5370573640703],
            [-2.337293344927466, 53.537182700946182],
            [-2.337896711947235, 53.537918943527451],
            [-2.338762493322519, 53.538069294967308],
            [-2.341411041286378, 53.541314656436235],
            [-2.341821009396331, 53.543127341271727],
            [-2.341542618263093, 53.545847123676729],
            [-2.343855840909526, 53.545251751222537],
            [-2.344266215258, 53.545610105140526],
            [-2.347547669161236, 53.547338061895353],
            [-2.351280353773955, 53.547754097002034],
            [-2.355861805445237, 53.549309016039238],
            [-2.362430778131891, 53.55044068832629],
            [-2.36232970126907, 53.551329046848856],
            [-2.367222759913244, 53.551650278847724],
            [-2.373590925374245, 53.553898312357923],
            [-2.371772570523766, 53.554301268312329],
            [-2.368205974343831, 53.553285835731955],
            [-2.366716572210415, 53.553330862388414],
            [-2.36392731590608, 53.555457049129117],
            [-2.362187835548457, 53.556099608441137],
            [-2.361477597860216, 53.55547886418934],
            [-2.360976452079746, 53.553352830259747],
            [-2.359712703346382, 53.552775991113194],
            [-2.358297600297517, 53.55321707673388],
            [-2.35707110121632, 53.554369461534606],
            [-2.356658618689502, 53.556115336289245],
            [-2.355917677903193, 53.556682014621671],
            [-2.357254125638169, 53.55781685406545],
            [-2.355996993185857, 53.560553068383904],
            [-2.357346376691504, 53.563206900933139],
            [-2.360978399470923, 53.568289690690008],
            [-2.366144860474953, 53.568397118635566],
            [-2.368326696989942, 53.56943396421412],
            [-2.369514905156024, 53.570454969806036],
            [-2.37098504268149, 53.57072815679286],
            [-2.373667991645528, 53.570342289995565],
            [-2.373984334106193, 53.571453160244893],
            [-2.37384391513015, 53.572139410772131],
            [-2.372569619811362, 53.572850772081125],
            [-2.372510504699556, 53.573855853860906],
            [-2.37316675364017, 53.5739517802837],
            [-2.373474016263343, 53.576428908318618],
            [-2.374773971655747, 53.577924096392891],
            [-2.374486229164035, 53.580226013653046],
            [-2.375258094740473, 53.580574138829306],
            [-2.376585499980474, 53.582077309801512],
            [-2.376044191511028, 53.582353157822354],
            [-2.375392959883059, 53.584201408519768],
            [-2.375691002605226, 53.585105597671216],
            [-2.375252374392581, 53.585893454195912],
            [-2.375947597253486, 53.586444052724161],
            [-2.376017781620937, 53.587712984330267],
            [-2.376626137857324, 53.587834209795048],
            [-2.376587856930117, 53.588795182740128],
            [-2.375084498907026, 53.589621441145951],
            [-2.374650440599301, 53.590420066440409],
            [-2.374803270564162, 53.590789007656014],
            [-2.376103363913841, 53.591224454586055],
            [-2.376902640740328, 53.593269477970246],
            [-2.373405719542717, 53.593859296708544],
            [-2.368742077816946, 53.59563727666113],
            [-2.368289506756585, 53.59606561634412],
            [-2.369416342310874, 53.597413084181866],
            [-2.368508646458435, 53.59798035083233],
            [-2.366897454089485, 53.600192833968414],
            [-2.366465770579083, 53.601819243511983],
            [-2.364660974813115, 53.602989644771391],
            [-2.365160075525946, 53.604262663129141],
            [-2.363721533100561, 53.606037742024824],
            [-2.36401559682248, 53.60666602818349],
            [-2.363656287564759, 53.607765491158766],
            [-2.36455456949145, 53.609055273193292],
            [-2.363008719572881, 53.610324628066039],
            [-2.36854082674709, 53.615552375872007],
            [-2.369434641909971, 53.615256600304306],
            [-2.374121266712002, 53.616177713330735],
            [-2.375019387580067, 53.617052157443467],
            [-2.376222113200706, 53.61749689585168],
            [-2.374919562151063, 53.618069045367598],
            [-2.375323413001044, 53.618434500786442],
            [-2.375964165317689, 53.618239240381953],
            [-2.377558241746617, 53.620519939489938],
            [-2.379363177040597, 53.620332665388787],
            [-2.379610896176061, 53.620813651362646],
            [-2.376797685919559, 53.621185672809176],
            [-2.377218084043378, 53.621875546454206],
            [-2.376294283255326, 53.622039346341808],
            [-2.376782898783239, 53.622071064864414],
            [-2.377974232892547, 53.623252860153904],
            [-2.383410834038769, 53.625561723935583],
            [-2.381125684449654, 53.627347805662687],
            [-2.379099466988208, 53.62700370151429],
            [-2.379331626300824, 53.629285087281154],
            [-2.379134003420554, 53.63085416635758],
            [-2.376645857349176, 53.631125382253238],
            [-2.376290423527622, 53.631797025292038],
            [-2.370922320028074, 53.631998948375951],
            [-2.371001526410304, 53.631374917374238],
            [-2.370329852246488, 53.631518114277512],
            [-2.370528600547972, 53.631764676052406],
            [-2.369835464716162, 53.632566776155045],
            [-2.370222507823532, 53.633246889079849],
            [-2.368561236468399, 53.635062253836274],
            [-2.368356143152675, 53.636864131777131],
            [-2.369002659211055, 53.637120102264639],
            [-2.368499161724533, 53.638002502943515],
            [-2.370107468378877, 53.638069444277249],
            [-2.372506172693218, 53.642012335419771],
            [-2.371517394796336, 53.643267472541694],
            [-2.370477137655461, 53.64469623364522],
            [-2.369209878308952, 53.645083948897629],
            [-2.367056987380064, 53.645045631902711],
            [-2.366680183737901, 53.646771630435566],
            [-2.36707006195745, 53.64759465632536],
            [-2.366141842825243, 53.650633725998112],
            [-2.365457847934846, 53.652012814078795],
            [-2.362971188587937, 53.654900211177385],
            [-2.362642990277198, 53.655972602576888],
            [-2.368974223923054, 53.663302914304495],
            [-2.371237973287006, 53.667064670267834],
            [-2.361608660409863, 53.665054729656582],
            [-2.355571482923806, 53.664491283158831],
            [-2.355401810511114, 53.659437715661966],
            [-2.353340267813498, 53.658466801811322],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000003",
        LAD13CDO: "00BN",
        LAD13NM: "Manchester",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.238476539280167, 53.538792421816723],
            [-2.237230167103414, 53.53878771273142],
            [-2.234098677623578, 53.539237016979818],
            [-2.233525822549983, 53.54015495166815],
            [-2.228842035162482, 53.540707812156576],
            [-2.22776169658697, 53.541564670271413],
            [-2.22082601449882, 53.542654498581889],
            [-2.219757726785594, 53.543256891092817],
            [-2.219502521257939, 53.543805650205833],
            [-2.218863136150699, 53.543894909291204],
            [-2.21781650135053, 53.543436618377193],
            [-2.217731276111379, 53.542415696110169],
            [-2.213002958708315, 53.542005363559511],
            [-2.210865896072044, 53.540730111574248],
            [-2.210555859879284, 53.539981927932715],
            [-2.20964566175517, 53.539614107242123],
            [-2.208530904644504, 53.538455660883074],
            [-2.207468701511067, 53.538465600177432],
            [-2.207037212090571, 53.538167036354466],
            [-2.20560449201808, 53.536757438998471],
            [-2.20687915685443, 53.535771011674179],
            [-2.206773409359876, 53.53419733127258],
            [-2.205430370729295, 53.532896335011671],
            [-2.200825086305443, 53.531775228101566],
            [-2.193920874458829, 53.531630241031145],
            [-2.185924303893586, 53.529041723542825],
            [-2.172063443687346, 53.52709764298428],
            [-2.171990715336519, 53.527761988742029],
            [-2.170161663328133, 53.527574954802986],
            [-2.170504517563225, 53.523930577189539],
            [-2.169269384349331, 53.52360695536283],
            [-2.164774597121527, 53.520140132392712],
            [-2.159136850749165, 53.522152190657273],
            [-2.156127344288587, 53.518754061314603],
            [-2.154753778267701, 53.518033183685759],
            [-2.156231081844378, 53.515800340943315],
            [-2.156406352955569, 53.516299865940624],
            [-2.158189945662775, 53.51536272280525],
            [-2.158654297122171, 53.514521691776146],
            [-2.160362217771674, 53.513944152233705],
            [-2.16070066797097, 53.512923514178496],
            [-2.163255228617973, 53.512938024453064],
            [-2.164388661870727, 53.513571049682199],
            [-2.168203048768515, 53.513359002751969],
            [-2.168626044180672, 53.513549858825009],
            [-2.1713989836936, 53.513111770072513],
            [-2.171570160480286, 53.512557838873335],
            [-2.172599414009908, 53.512390069799423],
            [-2.172937253909295, 53.512034539102984],
            [-2.173432928759386, 53.512296281258024],
            [-2.174050271181025, 53.51060286702058],
            [-2.173551795162417, 53.510389668803022],
            [-2.174298319052326, 53.510052414909602],
            [-2.173609211201642, 53.509681299214982],
            [-2.174117849317382, 53.5094333773214],
            [-2.174510667807703, 53.509622459023575],
            [-2.17430134133612, 53.508953128785997],
            [-2.175621436073958, 53.508039769732264],
            [-2.175888565241507, 53.507372436702099],
            [-2.176741626265035, 53.506928947103575],
            [-2.178346692619551, 53.50676297088949],
            [-2.178674395135374, 53.505812406055341],
            [-2.177896314824272, 53.505429763032417],
            [-2.17678231012998, 53.50547546120103],
            [-2.176561004071051, 53.505191754986171],
            [-2.177704342126684, 53.504951865544243],
            [-2.175463302958101, 53.502616395875158],
            [-2.170843049433106, 53.503130044605193],
            [-2.172643042011976, 53.502361641935529],
            [-2.168497816270375, 53.501011213854675],
            [-2.165726200666654, 53.498892030461626],
            [-2.164299409319121, 53.499484542148089],
            [-2.161834664513775, 53.49830143860931],
            [-2.161624621471898, 53.497376814916009],
            [-2.163385788531865, 53.49672365520874],
            [-2.163256892423974, 53.49613239346553],
            [-2.164066752558832, 53.494676057178793],
            [-2.163664498333676, 53.493555752893208],
            [-2.163924445393554, 53.492954969241744],
            [-2.163055858579602, 53.492850095713564],
            [-2.163956043249667, 53.49138015324737],
            [-2.166021697964619, 53.491579537145675],
            [-2.166639773423775, 53.490841624476303],
            [-2.16617111257988, 53.488935831581294],
            [-2.169016406101342, 53.488519266743793],
            [-2.169286153172766, 53.48813507825944],
            [-2.16888301751963, 53.48719096485722],
            [-2.169649953992615, 53.486774611302771],
            [-2.169609484221085, 53.486075368392115],
            [-2.169094329762348, 53.486137220814797],
            [-2.168954210717762, 53.485767095621618],
            [-2.169276558298677, 53.485728887379693],
            [-2.16895517352881, 53.484494331249955],
            [-2.167505675541461, 53.481107731756587],
            [-2.168000074478502, 53.480004154403474],
            [-2.165992690632658, 53.479536867883716],
            [-2.165573402135166, 53.478661076729004],
            [-2.166005860167507, 53.478665868946862],
            [-2.165456344654936, 53.477234772126536],
            [-2.162222452851644, 53.4763151997794],
            [-2.161498293551232, 53.474875333154557],
            [-2.158691477374556, 53.475284478557278],
            [-2.155904287686399, 53.475308826579806],
            [-2.155446218571433, 53.474471700444361],
            [-2.150486597194347, 53.472808903449739],
            [-2.150473700840347, 53.472570725638242],
            [-2.149895307086144, 53.470896904206064],
            [-2.146828909922139, 53.46764240085168],
            [-2.147926468397218, 53.466610068032935],
            [-2.147851239423017, 53.465769739792123],
            [-2.149147430031762, 53.465131742745228],
            [-2.149973820416624, 53.463694349407241],
            [-2.152784277948376, 53.463230573229104],
            [-2.152322069082255, 53.462445571964508],
            [-2.151975912322264, 53.462519718944506],
            [-2.150174367486622, 53.460773742683216],
            [-2.149096878848761, 53.460599819115117],
            [-2.149324610306799, 53.460258871070756],
            [-2.150741504567494, 53.460160013378214],
            [-2.14923429819293, 53.459416763979],
            [-2.150278795043488, 53.457935949308137],
            [-2.152068737387101, 53.457735035405484],
            [-2.158442091029547, 53.454879189283965],
            [-2.160438088892028, 53.454256316005271],
            [-2.160007006277174, 53.453350855673392],
            [-2.161790385157343, 53.453070705960101],
            [-2.161425767190051, 53.451828092073299],
            [-2.165970247417995, 53.453744493324798],
            [-2.167490516475544, 53.449372164744368],
            [-2.17280144869454, 53.447953396117498],
            [-2.172014049582224, 53.447225567027068],
            [-2.173389764265461, 53.445639801955814],
            [-2.173994424663523, 53.443656957025908],
            [-2.174755401340629, 53.437963423053617],
            [-2.175333982256786, 53.434442671830816],
            [-2.175970068863051, 53.434295222297266],
            [-2.176783682266583, 53.433041918631048],
            [-2.179092019598562, 53.433983168468444],
            [-2.1790404169571, 53.434239418694801],
            [-2.180141241284871, 53.434365398266792],
            [-2.180180336613601, 53.434711397140518],
            [-2.183799415573087, 53.435192154456857],
            [-2.185731580123108, 53.43610329389351],
            [-2.186583065824208, 53.435992305237043],
            [-2.187523887333631, 53.436343179836037],
            [-2.18746341988405, 53.436626413112847],
            [-2.188728647705923, 53.436806885040234],
            [-2.187228444590541, 53.435223674727339],
            [-2.188792131987969, 53.434831108604968],
            [-2.191353752369984, 53.43511196316048],
            [-2.19308459401265, 53.429439214121402],
            [-2.193184845645261, 53.427653031209942],
            [-2.196852909681863, 53.428084775895755],
            [-2.199389100846527, 53.426868010927947],
            [-2.201013275358599, 53.424395235999405],
            [-2.202155402036387, 53.423435127924172],
            [-2.204514712128475, 53.422135857910156],
            [-2.204959607837866, 53.422341830748117],
            [-2.206811213714143, 53.420946310020526],
            [-2.206677778498938, 53.42073620939378],
            [-2.207577566570024, 53.420421845759378],
            [-2.207757992829228, 53.42008536014022],
            [-2.206269852854763, 53.420084347253301],
            [-2.206506859704645, 53.419002615327628],
            [-2.207198309636695, 53.418861194994612],
            [-2.20845615946056, 53.419161917494087],
            [-2.209708039695006, 53.417941775262108],
            [-2.213488037962099, 53.418924709216235],
            [-2.214357292013658, 53.414655387035118],
            [-2.215413366778936, 53.411368167555246],
            [-2.214605436886698, 53.411054125473299],
            [-2.215528223366959, 53.410877184790877],
            [-2.215041153757745, 53.409753595069674],
            [-2.212678998805078, 53.406968679414355],
            [-2.218429213840012, 53.405417647555289],
            [-2.219454717896898, 53.40505981788526],
            [-2.219336737163817, 53.404056011737545],
            [-2.219886459300748, 53.402738173959975],
            [-2.222567604199133, 53.401179975602624],
            [-2.225176309263853, 53.401318896444877],
            [-2.229180520105431, 53.400851953723667],
            [-2.236203921831752, 53.401065663481205],
            [-2.238467355565029, 53.400777120907087],
            [-2.240604895792934, 53.399773298458129],
            [-2.242492316692219, 53.399498021315367],
            [-2.242468565852223, 53.399024371364618],
            [-2.242574083811268, 53.397745980386844],
            [-2.246873097223137, 53.396078766385443],
            [-2.244413650025273, 53.394127015687971],
            [-2.246028245108983, 53.393787523032827],
            [-2.246048913366305, 53.391376740015815],
            [-2.244492344372318, 53.391330503589295],
            [-2.243929158586933, 53.390400438331866],
            [-2.245800085753382, 53.390208735742817],
            [-2.246246509167981, 53.387839319487725],
            [-2.242587344468341, 53.387658956389217],
            [-2.242410736648906, 53.385686317709549],
            [-2.242977306292235, 53.384852820212686],
            [-2.242482694863662, 53.384063728588593],
            [-2.243090398139714, 53.383065655182818],
            [-2.242796797025829, 53.382196156152197],
            [-2.242024708935106, 53.382043121447857],
            [-2.24206788993918, 53.381705062169395],
            [-2.241162879687678, 53.381172972350996],
            [-2.241135293275703, 53.379484967243165],
            [-2.241285996718292, 53.378754787178153],
            [-2.242063988499379, 53.378105131603135],
            [-2.241316310743718, 53.376142634606275],
            [-2.241657571184112, 53.375620603805537],
            [-2.241318974016029, 53.374753887882456],
            [-2.240043960599828, 53.373615805480661],
            [-2.239903625079793, 53.372983289035879],
            [-2.240372223206535, 53.372655159597905],
            [-2.238984682603751, 53.3720727891805],
            [-2.238676863768406, 53.370782639475237],
            [-2.237034570765377, 53.37004884816475],
            [-2.240791273900709, 53.359557878676846],
            [-2.244144643101297, 53.359762292485556],
            [-2.244193957109719, 53.361027792065784],
            [-2.245205757243447, 53.361122792933095],
            [-2.24959318078502, 53.360575270158755],
            [-2.254099513270334, 53.360838108158184],
            [-2.256448512543652, 53.360660497452081],
            [-2.258938444041731, 53.359444346164089],
            [-2.258041831855069, 53.357027445091227],
            [-2.261104344457755, 53.356124609886812],
            [-2.264612645154362, 53.353962295931446],
            [-2.263921193407512, 53.353660011893709],
            [-2.264194812217255, 53.352962785174249],
            [-2.26684900310408, 53.351694867208764],
            [-2.267077743500219, 53.350557289702941],
            [-2.271809096384847, 53.350683225573626],
            [-2.27334532244295, 53.350359717381927],
            [-2.278364823411518, 53.348599809646714],
            [-2.28064123791436, 53.345952704036797],
            [-2.284637352611522, 53.346491540325495],
            [-2.2867066683986, 53.345511311749796],
            [-2.285868117063172, 53.345002768569671],
            [-2.287933930246564, 53.343072423810014],
            [-2.290055315608214, 53.342275379615572],
            [-2.291279868316798, 53.343004072700609],
            [-2.294039307818903, 53.34304943401218],
            [-2.294482106266623, 53.342790366184509],
            [-2.29581749885162, 53.343027957169177],
            [-2.296728338643917, 53.342039635905735],
            [-2.297861224730049, 53.341884902530971],
            [-2.301328660051678, 53.340106318653206],
            [-2.301662793661717, 53.340207944749686],
            [-2.302836707461392, 53.340977096830741],
            [-2.29745998320989, 53.343793301730159],
            [-2.298146317293258, 53.34442708699288],
            [-2.296455538561874, 53.344931971897616],
            [-2.296712511843574, 53.34603064756493],
            [-2.298318518915847, 53.346716071646163],
            [-2.296931432835086, 53.348567604545593],
            [-2.297753411109608, 53.349040156615203],
            [-2.298885807359977, 53.349004065557708],
            [-2.298652352388481, 53.349774979388009],
            [-2.299170805092257, 53.350016373819557],
            [-2.301451774136971, 53.349037156517433],
            [-2.301437328495294, 53.350395380892301],
            [-2.303583234912686, 53.350707237064611],
            [-2.304525554623916, 53.352239199809567],
            [-2.305622600396076, 53.353129866607098],
            [-2.30790592963195, 53.353516797618902],
            [-2.309234062565501, 53.354354700023663],
            [-2.312222221076786, 53.354756797215593],
            [-2.312745761142853, 53.356083949771879],
            [-2.314302425782562, 53.356715355754631],
            [-2.313999519326579, 53.357408280545911],
            [-2.305956221982382, 53.358384681065623],
            [-2.30217530206855, 53.359362399253143],
            [-2.298449010560423, 53.360871089933212],
            [-2.296356019382167, 53.363054259028345],
            [-2.292714575863262, 53.37021731175389],
            [-2.290829547284989, 53.372370206644497],
            [-2.286324167997785, 53.375823752217322],
            [-2.285642849908092, 53.37621279557726],
            [-2.287221941063182, 53.376330349889336],
            [-2.288622282921902, 53.376909432459016],
            [-2.29277685009426, 53.380823739668003],
            [-2.294322281615528, 53.38169992226635],
            [-2.296519279807842, 53.382222113086712],
            [-2.299943799086543, 53.384342055984163],
            [-2.300226580167656, 53.385002007869517],
            [-2.299176146328436, 53.386141706634],
            [-2.299619737121381, 53.387647983568677],
            [-2.300481088462665, 53.389542413741246],
            [-2.299897876757791, 53.389996907139853],
            [-2.300122178207916, 53.392169787980855],
            [-2.301840525584717, 53.39381396263223],
            [-2.302943468107328, 53.394333404813878],
            [-2.304982155744496, 53.397004116888667],
            [-2.307377746603819, 53.397409646863977],
            [-2.309395980174373, 53.400104604585316],
            [-2.319896145962538, 53.411637137959374],
            [-2.315827198352641, 53.410883947286152],
            [-2.312800431638203, 53.409861828659452],
            [-2.307214326247545, 53.409788253334987],
            [-2.305973925160887, 53.410090760135191],
            [-2.304259097634676, 53.409896497451768],
            [-2.303617699707488, 53.40960780012594],
            [-2.300610014006531, 53.409743053151097],
            [-2.299516592478809, 53.408918853630588],
            [-2.29768938579147, 53.408346359525027],
            [-2.297281471323688, 53.410896537049183],
            [-2.296544584945391, 53.411797228118708],
            [-2.293934870565982, 53.41360139970331],
            [-2.290904353251068, 53.414184094846895],
            [-2.288938354260023, 53.415345700292072],
            [-2.288099306807901, 53.415416042157595],
            [-2.287234057955743, 53.414948026066277],
            [-2.284169974279853, 53.416165223465782],
            [-2.28304502931236, 53.416007900780194],
            [-2.281591527570902, 53.415308434016289],
            [-2.280645311467132, 53.415327741546619],
            [-2.277796841626461, 53.41549530250056],
            [-2.278410588997394, 53.417321246824784],
            [-2.278246480839765, 53.418225878814603],
            [-2.276114714446368, 53.419197999670011],
            [-2.276425941978314, 53.419625134439052],
            [-2.275405910291981, 53.419413565396816],
            [-2.273907632253383, 53.421576065125016],
            [-2.273706342582291, 53.422566166702843],
            [-2.276211272432991, 53.423413408411612],
            [-2.280320785025828, 53.423853253109421],
            [-2.283651014875412, 53.424991424066242],
            [-2.285330371709741, 53.427226468251533],
            [-2.28593211823582, 53.429208800222817],
            [-2.286745578595208, 53.430214464302523],
            [-2.28789277734495, 53.431379314162541],
            [-2.289185514508223, 53.43202426260585],
            [-2.293276268888314, 53.43265965763635],
            [-2.296101873331861, 53.43251066185536],
            [-2.299653930390067, 53.433359303457067],
            [-2.300045536558836, 53.433823926075902],
            [-2.300201228703779, 53.436264821912339],
            [-2.299698296460459, 53.437308756090339],
            [-2.296080942300721, 53.435992874088562],
            [-2.29421632186949, 53.436048719765061],
            [-2.295397311063553, 53.43877651956155],
            [-2.29295323382491, 53.438865237918478],
            [-2.291408348412316, 53.439247436938466],
            [-2.289911064232154, 53.441317546046598],
            [-2.291522641757335, 53.443185930885221],
            [-2.293259903787608, 53.443609529043535],
            [-2.293297876429531, 53.444531658334235],
            [-2.292768663060678, 53.444420600990931],
            [-2.291736458699304, 53.445386697904475],
            [-2.289580291684013, 53.445140269706371],
            [-2.286167129860156, 53.449165470127447],
            [-2.284518585496683, 53.448972562460604],
            [-2.279751513283105, 53.449202250942264],
            [-2.279591842201679, 53.450108667135332],
            [-2.276717257432562, 53.450554903931497],
            [-2.273023657844856, 53.45056161180527],
            [-2.271477084338034, 53.450773672738123],
            [-2.258178935593026, 53.458848784370545],
            [-2.253603982663649, 53.459934514459349],
            [-2.253870664522864, 53.460456178922399],
            [-2.253221058953171, 53.460630137757079],
            [-2.253459317130547, 53.461184221933117],
            [-2.253039702999207, 53.461284884413601],
            [-2.253637906559594, 53.462591439929795],
            [-2.255900607310361, 53.462157859818831],
            [-2.257710440584822, 53.462557549438557],
            [-2.258464899166045, 53.463029611604711],
            [-2.258447949133372, 53.463710076805327],
            [-2.259949029006578, 53.465577321403934],
            [-2.262700304888078, 53.467409443603188],
            [-2.263529648820809, 53.467788727373772],
            [-2.264004545789815, 53.467608807321064],
            [-2.264529996646272, 53.467807188468775],
            [-2.265136351082591, 53.468417060108095],
            [-2.264505367821124, 53.468690810757387],
            [-2.265736510855418, 53.469719954216444],
            [-2.267933593955999, 53.469829197268517],
            [-2.265123831946238, 53.4724475215328],
            [-2.265346861617973, 53.472698703345465],
            [-2.263929734952441, 53.473755293695305],
            [-2.263333102179877, 53.475696323273588],
            [-2.262714261833415, 53.476734955918431],
            [-2.260196889424904, 53.477769648146008],
            [-2.255788645210446, 53.480596158200683],
            [-2.248940249862051, 53.483191220049285],
            [-2.245692892199476, 53.48502440052107],
            [-2.245138105305717, 53.486553577538345],
            [-2.24624343236394, 53.487188584107002],
            [-2.248647236982973, 53.488225363599959],
            [-2.249110192860125, 53.489300315746867],
            [-2.250490745455355, 53.490069535032639],
            [-2.250903818438468, 53.492384090891001],
            [-2.251583678613505, 53.492908484251302],
            [-2.253459035953833, 53.493467194739317],
            [-2.25253090894568, 53.495301903970351],
            [-2.25490663011569, 53.497333636021239],
            [-2.253982837943077, 53.497628627378447],
            [-2.255405469265291, 53.499068236795551],
            [-2.254313710332846, 53.499514595370741],
            [-2.254846399996425, 53.500358372321855],
            [-2.252456370051917, 53.502793021092302],
            [-2.252585174204576, 53.503156780001653],
            [-2.25153565366388, 53.503884361129963],
            [-2.247105041553302, 53.502396139477447],
            [-2.246569157851611, 53.503835395152102],
            [-2.245739704841961, 53.504834819823031],
            [-2.245270679414136, 53.504811515307999],
            [-2.245708800817447, 53.506528301923218],
            [-2.248771391986896, 53.507103512268387],
            [-2.248520271806991, 53.507734123271945],
            [-2.250266486335865, 53.508022600317879],
            [-2.249215266619859, 53.509515078129375],
            [-2.247997829072173, 53.509412447935333],
            [-2.247753981750067, 53.510001695250203],
            [-2.24657458429167, 53.510738489521515],
            [-2.246419357903504, 53.511532486760487],
            [-2.245184503009354, 53.512077928324238],
            [-2.246890889688769, 53.514306235167716],
            [-2.250236819947295, 53.516288406237827],
            [-2.256927578904924, 53.517923573543356],
            [-2.256504306115493, 53.520254277302016],
            [-2.255633280783828, 53.520626466723613],
            [-2.254175060327842, 53.520669127617701],
            [-2.254392179427343, 53.521663681203165],
            [-2.253468412662378, 53.521799572167488],
            [-2.253479885581017, 53.522457498530969],
            [-2.252876803677105, 53.522498325762299],
            [-2.25347996241819, 53.524237200743194],
            [-2.251457473799367, 53.524023956357205],
            [-2.251707670639775, 53.525770773016788],
            [-2.248747092578673, 53.524822407706417],
            [-2.248537852532938, 53.5251527172599],
            [-2.246533011956399, 53.529276250451133],
            [-2.257091718115438, 53.527090487319633],
            [-2.264086678710902, 53.524691505970118],
            [-2.265909562825992, 53.526975907392284],
            [-2.266050784809957, 53.530250962453167],
            [-2.267471705641489, 53.534050775430771],
            [-2.268271181133143, 53.534981078075653],
            [-2.267825406725077, 53.535110612085859],
            [-2.267993521421789, 53.535450894725933],
            [-2.268547388036221, 53.535235727558415],
            [-2.268541271750506, 53.537128694051013],
            [-2.266989274883324, 53.53842110566918],
            [-2.258529432114651, 53.543196357363762],
            [-2.253104317660599, 53.543941424071079],
            [-2.251673024919675, 53.544588014517196],
            [-2.247939836531605, 53.544127526473609],
            [-2.239378708302797, 53.540102020431434],
            [-2.238476539280167, 53.538792421816723],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000004",
        LAD13CDO: "00BP",
        LAD13NM: "Oldham",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.024587509500433, 53.617379933428062],
            [-2.023037170413346, 53.615824382783543],
            [-2.018653487887402, 53.615954586371608],
            [-2.014774400752078, 53.615334049835703],
            [-2.009471976706245, 53.616764625913341],
            [-2.00853735636196, 53.614796275850843],
            [-2.007675663917058, 53.614361305671956],
            [-2.007515097702121, 53.61251962710999],
            [-1.999608367983338, 53.607927788650656],
            [-1.995875584776423, 53.605100024785102],
            [-1.994765017642142, 53.603372442592608],
            [-1.990715861763888, 53.599460518605753],
            [-1.981647845433632, 53.589322528795535],
            [-1.978245557108488, 53.586772892051691],
            [-1.971164138947428, 53.584140542093728],
            [-1.966865434855699, 53.580001233475656],
            [-1.956582397195564, 53.577555848869743],
            [-1.953690395140891, 53.576015076702966],
            [-1.953708891594651, 53.575670832256854],
            [-1.950935499862425, 53.573621301070403],
            [-1.950321771481337, 53.574149560870964],
            [-1.94811100853965, 53.572832740893304],
            [-1.946401753498837, 53.57149003831033],
            [-1.944596377450037, 53.568670488767964],
            [-1.942727470037535, 53.561625475690313],
            [-1.928703425191178, 53.555568831556506],
            [-1.927523109953733, 53.554621654270889],
            [-1.925667502659633, 53.553173399107123],
            [-1.924450543479922, 53.552538063380204],
            [-1.921580393056374, 53.552983841122199],
            [-1.919371638972296, 53.550862929408773],
            [-1.912886037516444, 53.551627796997067],
            [-1.909622305962102, 53.538374770987254],
            [-1.911892394847331, 53.53801692596268],
            [-1.914254924371216, 53.53669195726907],
            [-1.91577721587828, 53.532930510080988],
            [-1.917391451976027, 53.532180205729013],
            [-1.922446452798824, 53.53079847345812],
            [-1.922606962811886, 53.526317877369578],
            [-1.923478875339157, 53.522941505824775],
            [-1.926782546234243, 53.520931968954358],
            [-1.922695316848965, 53.518359628034133],
            [-1.92186350187727, 53.517160039151641],
            [-1.916768273386618, 53.514478972375436],
            [-1.917500903072198, 53.513061111026062],
            [-1.921974752364896, 53.5099379423675],
            [-1.924401583051545, 53.509556590676148],
            [-1.926078384195104, 53.508646212392968],
            [-1.928779845880142, 53.508072589010524],
            [-1.935503585001491, 53.505769968049741],
            [-1.938249753081636, 53.506386218405488],
            [-1.942430432922216, 53.506593230953349],
            [-1.946891405109819, 53.504528853357833],
            [-1.951334911378731, 53.504181985086106],
            [-1.955675198826914, 53.504438934120522],
            [-1.957421702600727, 53.505327618861401],
            [-1.959476165923096, 53.505890103243786],
            [-1.961261570867098, 53.507264118092593],
            [-1.963388363143203, 53.509810296535406],
            [-1.966732367661381, 53.510362254792675],
            [-1.973058134708559, 53.512424871197652],
            [-1.977822238072897, 53.514415867859292],
            [-1.9819493495708, 53.515247978531804],
            [-1.985788463641323, 53.51643135905573],
            [-1.986930008519223, 53.516706531220294],
            [-1.988826777237213, 53.518368670602548],
            [-1.991891060961751, 53.520019176847029],
            [-1.996919340440731, 53.522034594422237],
            [-2.003035329283201, 53.52019466208764],
            [-2.009191698827018, 53.519209207807776],
            [-2.016762102835121, 53.521156150380975],
            [-2.018807410278223, 53.521385944023024],
            [-2.01753238614692, 53.523707836910248],
            [-2.018032077472033, 53.524697380915526],
            [-2.020302831108844, 53.525995830987078],
            [-2.023235037122847, 53.528376308476496],
            [-2.02470739877359, 53.528456006512599],
            [-2.026222451561864, 53.52925204941802],
            [-2.032492739590494, 53.530227531064817],
            [-2.033543694161407, 53.529562998721964],
            [-2.034549753304356, 53.529378448661589],
            [-2.035060394130244, 53.530278932658803],
            [-2.036063634154265, 53.530313686707217],
            [-2.03830198881356, 53.529884240998605],
            [-2.038486765432466, 53.529032084603898],
            [-2.039628901597736, 53.529212375118206],
            [-2.039890592083095, 53.529964613369536],
            [-2.044770735560657, 53.529871193834744],
            [-2.048673831439909, 53.530234587703902],
            [-2.050902176703035, 53.530371176947405],
            [-2.054940428994116, 53.531343719144417],
            [-2.057072588829143, 53.530576907605308],
            [-2.057310575933887, 53.529190787919816],
            [-2.055949675746044, 53.529047621596909],
            [-2.053220840779689, 53.526747851974037],
            [-2.053125808427577, 53.525548846289418],
            [-2.054441930701026, 53.523813496155256],
            [-2.054127701008665, 53.522252359335262],
            [-2.056308625087739, 53.522192024125644],
            [-2.058442454230259, 53.522982871154632],
            [-2.061845845727965, 53.522364549425092],
            [-2.062276306137195, 53.521743228442929],
            [-2.063693282249767, 53.52120497412767],
            [-2.064222787831157, 53.521273900217679],
            [-2.064689662714173, 53.522796278024927],
            [-2.068589160644279, 53.522166707745981],
            [-2.07017490105509, 53.521569854908265],
            [-2.070686443980222, 53.521711568788788],
            [-2.071139696064978, 53.521270868614323],
            [-2.074703668734196, 53.521235430245653],
            [-2.075141469152864, 53.520613159338282],
            [-2.077207114698058, 53.520257696782224],
            [-2.078271104577084, 53.520629121481612],
            [-2.092124263517827, 53.520598546850444],
            [-2.093185989944623, 53.520563565940776],
            [-2.092945452847755, 53.519545370403527],
            [-2.0936496502477, 53.518802379197382],
            [-2.09346409742449, 53.518097835724589],
            [-2.095413050582314, 53.517613615954062],
            [-2.094863729369608, 53.516779932316474],
            [-2.095233957722854, 53.515768443939322],
            [-2.09750697956593, 53.514613396881657],
            [-2.098074026620603, 53.513973857481147],
            [-2.099004409233782, 53.513948819986901],
            [-2.099589687577361, 53.513380266450909],
            [-2.099448537146884, 53.512351213608454],
            [-2.099964251111532, 53.511709910099739],
            [-2.102357077029843, 53.510979820423771],
            [-2.103361053744426, 53.509626201147093],
            [-2.110789839867992, 53.508315315101036],
            [-2.112793659512951, 53.507727393392884],
            [-2.115278071800645, 53.506463945640775],
            [-2.117932825661473, 53.505785422648344],
            [-2.119686091189284, 53.505710870328997],
            [-2.120694102352392, 53.501245313454099],
            [-2.121440633403397, 53.500820302829204],
            [-2.123318536169709, 53.500650292973049],
            [-2.124814873725953, 53.499406538242859],
            [-2.125989394550015, 53.498961275958251],
            [-2.127456145313448, 53.498451872482086],
            [-2.130502754732284, 53.498463858918555],
            [-2.131599129364853, 53.498123791322229],
            [-2.135256186545505, 53.495284754416311],
            [-2.136226560896596, 53.491887824322788],
            [-2.137918318405287, 53.491624317411265],
            [-2.138117878754451, 53.491811045800773],
            [-2.140965750295858, 53.491122796845936],
            [-2.145457200159993, 53.492001866896196],
            [-2.146967663053857, 53.493805790509022],
            [-2.14815993816931, 53.492944123841283],
            [-2.151059054355367, 53.493126549295269],
            [-2.15149671153772, 53.492858139377233],
            [-2.153980609096302, 53.492839679687144],
            [-2.154917697715931, 53.493548552412044],
            [-2.155662104806026, 53.49349634967475],
            [-2.156313389337739, 53.493943121888982],
            [-2.156650666651595, 53.494254577938143],
            [-2.157832819534574, 53.494368072109182],
            [-2.158325614616466, 53.493534192369403],
            [-2.159183585622936, 53.493219354949566],
            [-2.162004272523514, 53.493371058915947],
            [-2.163055858579602, 53.492850095713564],
            [-2.163924445393554, 53.492954969241744],
            [-2.163664498333676, 53.493555752893208],
            [-2.164066752558832, 53.494676057178793],
            [-2.163256892423974, 53.49613239346553],
            [-2.163385788531865, 53.49672365520874],
            [-2.161624621471898, 53.497376814916009],
            [-2.161834664513775, 53.49830143860931],
            [-2.164299409319121, 53.499484542148089],
            [-2.165726200666654, 53.498892030461626],
            [-2.168497816270375, 53.501011213854675],
            [-2.172643042011976, 53.502361641935529],
            [-2.170843049433106, 53.503130044605193],
            [-2.175463302958101, 53.502616395875158],
            [-2.177704342126684, 53.504951865544243],
            [-2.176561004071051, 53.505191754986171],
            [-2.17678231012998, 53.50547546120103],
            [-2.177896314824272, 53.505429763032417],
            [-2.178674395135374, 53.505812406055341],
            [-2.178346692619551, 53.50676297088949],
            [-2.176741626265035, 53.506928947103575],
            [-2.175888565241507, 53.507372436702099],
            [-2.175621436073958, 53.508039769732264],
            [-2.17430134133612, 53.508953128785997],
            [-2.174510667807703, 53.509622459023575],
            [-2.174117849317382, 53.5094333773214],
            [-2.173609211201642, 53.509681299214982],
            [-2.174298319052326, 53.510052414909602],
            [-2.173551795162417, 53.510389668803022],
            [-2.174050271181025, 53.51060286702058],
            [-2.173432928759386, 53.512296281258024],
            [-2.172937253909295, 53.512034539102984],
            [-2.172599414009908, 53.512390069799423],
            [-2.171570160480286, 53.512557838873335],
            [-2.1713989836936, 53.513111770072513],
            [-2.168626044180672, 53.513549858825009],
            [-2.168203048768515, 53.513359002751969],
            [-2.164388661870727, 53.513571049682199],
            [-2.163255228617973, 53.512938024453064],
            [-2.16070066797097, 53.512923514178496],
            [-2.160362217771674, 53.513944152233705],
            [-2.158654297122171, 53.514521691776146],
            [-2.158189945662775, 53.51536272280525],
            [-2.156406352955569, 53.516299865940624],
            [-2.156231081844378, 53.515800340943315],
            [-2.154753778267701, 53.518033183685759],
            [-2.156127344288587, 53.518754061314603],
            [-2.159136850749165, 53.522152190657273],
            [-2.164774597121527, 53.520140132392712],
            [-2.169269384349331, 53.52360695536283],
            [-2.170504517563225, 53.523930577189539],
            [-2.170161663328133, 53.527574954802986],
            [-2.171990715336519, 53.527761988742029],
            [-2.172063443687346, 53.52709764298428],
            [-2.185924303893586, 53.529041723542825],
            [-2.178968735084049, 53.535551841043059],
            [-2.178013496503041, 53.537304201993564],
            [-2.175547944015768, 53.538370276244081],
            [-2.175025598701382, 53.539765137414513],
            [-2.173492528040565, 53.541607291065617],
            [-2.171391544093626, 53.54372977857831],
            [-2.170733587441835, 53.543728923430251],
            [-2.17067811277236, 53.544567615815893],
            [-2.169453395077716, 53.546243889275829],
            [-2.170038736178926, 53.55145449857887],
            [-2.171719517287914, 53.551300190703465],
            [-2.172237988175499, 53.551860316455681],
            [-2.172048884319205, 53.555097288966238],
            [-2.171838041714195, 53.558192275075314],
            [-2.171187473965665, 53.558207590641643],
            [-2.17056596810431, 53.558692051925114],
            [-2.168828629803367, 53.558744855063637],
            [-2.168118989505877, 53.560233424178904],
            [-2.165888823641074, 53.561285480122166],
            [-2.163455716881001, 53.563374125570121],
            [-2.159548330947843, 53.564546093206992],
            [-2.157814075116839, 53.564278747928341],
            [-2.157394848942145, 53.564818600234254],
            [-2.155239387679881, 53.565382292490284],
            [-2.150954794581351, 53.567166572663055],
            [-2.151827159465068, 53.574221288339068],
            [-2.152639936264335, 53.574727192579751],
            [-2.152684450671907, 53.575763486780929],
            [-2.152097366231073, 53.576290950224994],
            [-2.152504419945755, 53.579027367683693],
            [-2.156618558098611, 53.582295580266063],
            [-2.146757345994629, 53.583730949766775],
            [-2.136996632124067, 53.583288636555118],
            [-2.136894688699312, 53.583059552136383],
            [-2.131939832111475, 53.584343263634686],
            [-2.125869330199959, 53.586455771510458],
            [-2.123967718503609, 53.589105707888095],
            [-2.122882848202399, 53.589036720736949],
            [-2.122462557319861, 53.589447017945311],
            [-2.123055592902091, 53.589736729554907],
            [-2.122844857681845, 53.58999940419482],
            [-2.11864861398756, 53.592337003498962],
            [-2.117977234467714, 53.592668437157258],
            [-2.112677560111581, 53.592759843856456],
            [-2.111702495756481, 53.597108384828438],
            [-2.112515555483073, 53.59887021957654],
            [-2.110671181496361, 53.599673696315868],
            [-2.108538794353116, 53.598975468560255],
            [-2.107302196528201, 53.599345105112931],
            [-2.106798259721807, 53.598447629794826],
            [-2.105442156229698, 53.597552705641476],
            [-2.101366493177, 53.597327035625128],
            [-2.100055868048755, 53.597707446154217],
            [-2.098032448430578, 53.596316843413774],
            [-2.097678215870695, 53.596025914111223],
            [-2.094221871339333, 53.596321710413129],
            [-2.092668368248491, 53.596892782768087],
            [-2.092397324822842, 53.596626940377199],
            [-2.090680933396575, 53.597352711002955],
            [-2.089660646542857, 53.597174615627843],
            [-2.089072607038732, 53.596329260581712],
            [-2.088189337858592, 53.596631023504727],
            [-2.086989172916625, 53.595669261617388],
            [-2.085474709896608, 53.595409696615853],
            [-2.083869549506117, 53.594358310106145],
            [-2.081107849143178, 53.595232081563537],
            [-2.069938812957439, 53.594952429451084],
            [-2.070068356791317, 53.594715962043828],
            [-2.069094920207771, 53.59445407288824],
            [-2.067615597353448, 53.593399698830261],
            [-2.067325496304179, 53.592439916158661],
            [-2.059936259623366, 53.592501386541777],
            [-2.055142229361652, 53.594616843377047],
            [-2.055946396468575, 53.597179920546047],
            [-2.056747147974046, 53.597133701624138],
            [-2.05682609145477, 53.597410502383234],
            [-2.055572102957204, 53.597503673106246],
            [-2.053660479664265, 53.598507639382248],
            [-2.048971671098747, 53.5997006042709],
            [-2.042236170303113, 53.600498646326827],
            [-2.035807067460258, 53.602947358971662],
            [-2.035508257378287, 53.603423825897615],
            [-2.036550006876828, 53.604042800343294],
            [-2.035548586914802, 53.604651608582145],
            [-2.034791307617603, 53.604470270183704],
            [-2.031372653134832, 53.610197632564535],
            [-2.031454700935228, 53.610771959298795],
            [-2.032811329252252, 53.611864561767106],
            [-2.03265222782516, 53.615244181623922],
            [-2.032141968618549, 53.616114381598337],
            [-2.030206649188083, 53.617639293533699],
            [-2.029507986744219, 53.621570918875328],
            [-2.026870018885786, 53.624229362482041],
            [-2.026173388674194, 53.622404909876238],
            [-2.026441481990002, 53.6207896669235],
            [-2.024587509500433, 53.617379933428062],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000005",
        LAD13CDO: "00BQ",
        LAD13NM: "Rochdale",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.126878614085084, 53.679585149995965],
            [-2.126717528790898, 53.676865508979844],
            [-2.126224780690654, 53.675602297123383],
            [-2.124968492329194, 53.675140727718663],
            [-2.125386857987515, 53.674823007953975],
            [-2.118085068412686, 53.671229792924997],
            [-2.114106983885796, 53.671120407126871],
            [-2.097039878333164, 53.672610444508237],
            [-2.096191381108366, 53.67157839253602],
            [-2.092906669739839, 53.670145585205525],
            [-2.090286885973189, 53.670954730523412],
            [-2.089691525971496, 53.670714296741437],
            [-2.087121600250877, 53.670794396316587],
            [-2.086264044735813, 53.671114997080117],
            [-2.086073458802136, 53.67191867514321],
            [-2.085124922553343, 53.672174618565016],
            [-2.084390834856389, 53.672946322721849],
            [-2.083760995810449, 53.675914651544637],
            [-2.084058133970607, 53.677649153924349],
            [-2.083167288758222, 53.678059636913837],
            [-2.080406517315277, 53.678328475844623],
            [-2.077531525632854, 53.677426170893298],
            [-2.075482969136989, 53.677241429440151],
            [-2.073398862387335, 53.677494397211426],
            [-2.071801415784114, 53.678180265152292],
            [-2.071246958366204, 53.678848416081166],
            [-2.061819758475818, 53.682828226016667],
            [-2.060579549828612, 53.682678760615232],
            [-2.056714718458915, 53.683035692531064],
            [-2.056224289872753, 53.683125806111399],
            [-2.055545366481711, 53.682647955836089],
            [-2.051242243237502, 53.683676327828799],
            [-2.048902007764923, 53.68021148784333],
            [-2.050734529631009, 53.67950065901524],
            [-2.053495353961304, 53.677876194302442],
            [-2.054857942075218, 53.675647415253103],
            [-2.054392835515503, 53.673016798957107],
            [-2.052918117460361, 53.670301244856709],
            [-2.05220262645856, 53.669432406362894],
            [-2.04961971669718, 53.668764791608922],
            [-2.048524175371804, 53.667674081257772],
            [-2.044834943294791, 53.665453655568122],
            [-2.037095625239917, 53.662327542082501],
            [-2.037429687954308, 53.661905892662588],
            [-2.036517966336978, 53.661072973567791],
            [-2.03570990329041, 53.65931243777549],
            [-2.03569482967183, 53.657602893082561],
            [-2.036587078865779, 53.655298055226083],
            [-2.039609986403551, 53.651922027589976],
            [-2.039541078551961, 53.651044804450095],
            [-2.040527348223572, 53.650902459323838],
            [-2.039679814589471, 53.648967590051321],
            [-2.040706432631899, 53.648362338588711],
            [-2.041577900740244, 53.645403127084542],
            [-2.041581050204702, 53.64247207775432],
            [-2.041143816829376, 53.640830087119838],
            [-2.036927583096529, 53.638182650000253],
            [-2.029997671360213, 53.636154168145701],
            [-2.029029925226058, 53.634209362092733],
            [-2.029747372064453, 53.630821529820153],
            [-2.030887885050253, 53.629224932543252],
            [-2.030062734800902, 53.627762762425235],
            [-2.026870018885786, 53.624229362482041],
            [-2.029507986744219, 53.621570918875328],
            [-2.030206649188083, 53.617639293533699],
            [-2.032141968618549, 53.616114381598337],
            [-2.03265222782516, 53.615244181623922],
            [-2.032811329252252, 53.611864561767106],
            [-2.031454700935228, 53.610771959298795],
            [-2.031372653134832, 53.610197632564535],
            [-2.034791307617603, 53.604470270183704],
            [-2.035548586914802, 53.604651608582145],
            [-2.036550006876828, 53.604042800343294],
            [-2.035508257378287, 53.603423825897615],
            [-2.035807067460258, 53.602947358971662],
            [-2.042236170303113, 53.600498646326827],
            [-2.048971671098747, 53.5997006042709],
            [-2.053660479664265, 53.598507639382248],
            [-2.055572102957204, 53.597503673106246],
            [-2.05682609145477, 53.597410502383234],
            [-2.056747147974046, 53.597133701624138],
            [-2.055946396468575, 53.597179920546047],
            [-2.055142229361652, 53.594616843377047],
            [-2.059936259623366, 53.592501386541777],
            [-2.067325496304179, 53.592439916158661],
            [-2.067615597353448, 53.593399698830261],
            [-2.069094920207771, 53.59445407288824],
            [-2.070068356791317, 53.594715962043828],
            [-2.069938812957439, 53.594952429451084],
            [-2.081107849143178, 53.595232081563537],
            [-2.083869549506117, 53.594358310106145],
            [-2.085474709896608, 53.595409696615853],
            [-2.086989172916625, 53.595669261617388],
            [-2.088189337858592, 53.596631023504727],
            [-2.089072607038732, 53.596329260581712],
            [-2.089660646542857, 53.597174615627843],
            [-2.090680933396575, 53.597352711002955],
            [-2.092397324822842, 53.596626940377199],
            [-2.092668368248491, 53.596892782768087],
            [-2.094221871339333, 53.596321710413129],
            [-2.097678215870695, 53.596025914111223],
            [-2.098032448430578, 53.596316843413774],
            [-2.100055868048755, 53.597707446154217],
            [-2.101366493177, 53.597327035625128],
            [-2.105442156229698, 53.597552705641476],
            [-2.106798259721807, 53.598447629794826],
            [-2.107302196528201, 53.599345105112931],
            [-2.108538794353116, 53.598975468560255],
            [-2.110671181496361, 53.599673696315868],
            [-2.112515555483073, 53.59887021957654],
            [-2.111702495756481, 53.597108384828438],
            [-2.112677560111581, 53.592759843856456],
            [-2.117977234467714, 53.592668437157258],
            [-2.11864861398756, 53.592337003498962],
            [-2.122844857681845, 53.58999940419482],
            [-2.123055592902091, 53.589736729554907],
            [-2.122462557319861, 53.589447017945311],
            [-2.122882848202399, 53.589036720736949],
            [-2.123967718503609, 53.589105707888095],
            [-2.125869330199959, 53.586455771510458],
            [-2.131939832111475, 53.584343263634686],
            [-2.136894688699312, 53.583059552136383],
            [-2.136996632124067, 53.583288636555118],
            [-2.146757345994629, 53.583730949766775],
            [-2.156618558098611, 53.582295580266063],
            [-2.152504419945755, 53.579027367683693],
            [-2.152097366231073, 53.576290950224994],
            [-2.152684450671907, 53.575763486780929],
            [-2.152639936264335, 53.574727192579751],
            [-2.151827159465068, 53.574221288339068],
            [-2.150954794581351, 53.567166572663055],
            [-2.155239387679881, 53.565382292490284],
            [-2.157394848942145, 53.564818600234254],
            [-2.157814075116839, 53.564278747928341],
            [-2.159548330947843, 53.564546093206992],
            [-2.163455716881001, 53.563374125570121],
            [-2.165888823641074, 53.561285480122166],
            [-2.168118989505877, 53.560233424178904],
            [-2.168828629803367, 53.558744855063637],
            [-2.17056596810431, 53.558692051925114],
            [-2.171187473965665, 53.558207590641643],
            [-2.171838041714195, 53.558192275075314],
            [-2.172048884319205, 53.555097288966238],
            [-2.172237988175499, 53.551860316455681],
            [-2.171719517287914, 53.551300190703465],
            [-2.170038736178926, 53.55145449857887],
            [-2.169453395077716, 53.546243889275829],
            [-2.17067811277236, 53.544567615815893],
            [-2.170733587441835, 53.543728923430251],
            [-2.171391544093626, 53.54372977857831],
            [-2.173492528040565, 53.541607291065617],
            [-2.175025598701382, 53.539765137414513],
            [-2.175547944015768, 53.538370276244081],
            [-2.178013496503041, 53.537304201993564],
            [-2.178968735084049, 53.535551841043059],
            [-2.185924303893586, 53.529041723542825],
            [-2.193920874458829, 53.531630241031145],
            [-2.200825086305443, 53.531775228101566],
            [-2.205430370729295, 53.532896335011671],
            [-2.206773409359876, 53.53419733127258],
            [-2.20687915685443, 53.535771011674179],
            [-2.20560449201808, 53.536757438998471],
            [-2.207037212090571, 53.538167036354466],
            [-2.207468701511067, 53.538465600177432],
            [-2.208530904644504, 53.538455660883074],
            [-2.20964566175517, 53.539614107242123],
            [-2.210555859879284, 53.539981927932715],
            [-2.210865896072044, 53.540730111574248],
            [-2.213002958708315, 53.542005363559511],
            [-2.217731276111379, 53.542415696110169],
            [-2.21781650135053, 53.543436618377193],
            [-2.218863136150699, 53.543894909291204],
            [-2.219502521257939, 53.543805650205833],
            [-2.219757726785594, 53.543256891092817],
            [-2.22082601449882, 53.542654498581889],
            [-2.22776169658697, 53.541564670271413],
            [-2.228842035162482, 53.540707812156576],
            [-2.233525822549983, 53.54015495166815],
            [-2.234098677623578, 53.539237016979818],
            [-2.237230167103414, 53.53878771273142],
            [-2.236394905534565, 53.540530415373468],
            [-2.234350668714856, 53.541693942225308],
            [-2.234103698523244, 53.543151440135198],
            [-2.235741992854972, 53.548210462053966],
            [-2.236263685585205, 53.55109828806922],
            [-2.237367851898761, 53.553673957908138],
            [-2.241654796840765, 53.557146550311799],
            [-2.236461674913376, 53.559259313101549],
            [-2.237218907989888, 53.559422298738198],
            [-2.23903283687061, 53.560913444264692],
            [-2.240694778999471, 53.561141108334176],
            [-2.24087678118146, 53.561554205035065],
            [-2.241580517717685, 53.561327177105653],
            [-2.242626383399544, 53.561529093297807],
            [-2.244063313273519, 53.561999852318003],
            [-2.246440290882344, 53.561597689308243],
            [-2.247226047952623, 53.56197807051668],
            [-2.2481693664516, 53.563222797279188],
            [-2.247104656162052, 53.563425443558636],
            [-2.245886900730724, 53.565354155139246],
            [-2.243180345220214, 53.567822495661346],
            [-2.245451453825441, 53.568634891342306],
            [-2.245056226243831, 53.570266184529103],
            [-2.243975823117242, 53.570137165601452],
            [-2.243267982925432, 53.571512923058208],
            [-2.244293421904553, 53.572317083622167],
            [-2.250467484943233, 53.576796664739334],
            [-2.250600970129259, 53.57842596612528],
            [-2.254523745634058, 53.581023388043938],
            [-2.254461430407476, 53.582466144137364],
            [-2.25487598591307, 53.582831983767591],
            [-2.25911561505787, 53.585260496535867],
            [-2.261029592767542, 53.584788940354507],
            [-2.262492514657242, 53.584906180009739],
            [-2.26527846023421, 53.585711673571986],
            [-2.267561613331636, 53.584387101613579],
            [-2.268382237374273, 53.580878922420233],
            [-2.269022342353615, 53.580356161336333],
            [-2.271010690593947, 53.57999303595939],
            [-2.272123456944022, 53.579465590224679],
            [-2.273642188272867, 53.579361453874789],
            [-2.277475569952255, 53.580074378394478],
            [-2.277566992891991, 53.580887608617736],
            [-2.278638829903357, 53.581493623771074],
            [-2.278800582215683, 53.581973222532476],
            [-2.280429987672282, 53.582148278493932],
            [-2.280722256001909, 53.582717451492414],
            [-2.281411707526167, 53.582820094812277],
            [-2.282335078737602, 53.584024148861488],
            [-2.282440589705783, 53.586706909685233],
            [-2.281540991219125, 53.586815093768699],
            [-2.278425121924703, 53.585514592958113],
            [-2.277888805849037, 53.586194458065286],
            [-2.278059292243592, 53.587312206811667],
            [-2.278796573440857, 53.588473576110651],
            [-2.27855248635298, 53.589488924422348],
            [-2.276588040094384, 53.591487090464057],
            [-2.275510975931974, 53.59150485765575],
            [-2.273964910400201, 53.590953832813859],
            [-2.270025288752559, 53.589125588256238],
            [-2.269320693341799, 53.589978369690357],
            [-2.269955531181296, 53.591925600596859],
            [-2.266517842424668, 53.591342783177851],
            [-2.265638026313836, 53.591495744842369],
            [-2.264480805039073, 53.592467248883075],
            [-2.259224377292321, 53.592165970316202],
            [-2.25825820499497, 53.595009260278637],
            [-2.255542005657469, 53.597075212704439],
            [-2.250114784592792, 53.599150400580072],
            [-2.247140584203625, 53.599329161278924],
            [-2.245582744581371, 53.599078003443594],
            [-2.244935156559731, 53.600755646004671],
            [-2.245231214568042, 53.601256584896539],
            [-2.248364728983836, 53.60219298609222],
            [-2.249078296598703, 53.602757762862943],
            [-2.248974422804547, 53.603338621774498],
            [-2.250491598329776, 53.604858964818632],
            [-2.250984329754383, 53.607161625147931],
            [-2.255728056792949, 53.606106236747351],
            [-2.259120312243936, 53.606009050034658],
            [-2.261648883854191, 53.606527559396966],
            [-2.263979563820934, 53.6077771988802],
            [-2.264350536591913, 53.607646049319207],
            [-2.266571329525588, 53.609183505756107],
            [-2.268811095159148, 53.611302418956164],
            [-2.26966986027481, 53.612762876012894],
            [-2.271789600099509, 53.614498204402281],
            [-2.269045991516963, 53.616150159528921],
            [-2.266858355964558, 53.61562745091485],
            [-2.267238515911711, 53.614787997232675],
            [-2.266479531098901, 53.61452273976164],
            [-2.260581159965056, 53.616217442650978],
            [-2.260611355949739, 53.6167018438802],
            [-2.257919043950521, 53.616449718589323],
            [-2.259121704683035, 53.618321169548658],
            [-2.259237698421293, 53.619240416407187],
            [-2.258380863593931, 53.619789655466398],
            [-2.257143491332219, 53.619917259481234],
            [-2.256771390882161, 53.62038185281159],
            [-2.259956657588434, 53.620611361544583],
            [-2.259629381482098, 53.621723919034615],
            [-2.260583275056053, 53.621955538611552],
            [-2.26020747361523, 53.622306898627379],
            [-2.260464850982613, 53.624327794902868],
            [-2.261241818172176, 53.624300034150792],
            [-2.262999998879349, 53.62499546576764],
            [-2.262511395467573, 53.626417580591323],
            [-2.263829466063191, 53.629984808777458],
            [-2.265449333136587, 53.639428753073041],
            [-2.26766892219832, 53.640983262971517],
            [-2.267210686562235, 53.642666882336293],
            [-2.269590968225117, 53.644002583233636],
            [-2.269711073844547, 53.644571266590191],
            [-2.269064592312467, 53.645431097146123],
            [-2.269867702378532, 53.64612048160695],
            [-2.266974689556417, 53.648404586475309],
            [-2.266374112300348, 53.649124982139213],
            [-2.266646434628207, 53.649606143372942],
            [-2.265624022987981, 53.650611502006392],
            [-2.262908931442401, 53.651669120247469],
            [-2.261442879737172, 53.65410723737309],
            [-2.260664490050521, 53.65423836614324],
            [-2.259588017040786, 53.655105371738664],
            [-2.257296837259958, 53.655282895191348],
            [-2.25351560301294, 53.656522351414345],
            [-2.252725682541294, 53.657519015958087],
            [-2.247505240863578, 53.661107230692892],
            [-2.245878844023864, 53.66350593585905],
            [-2.236576184494902, 53.666991435328796],
            [-2.23308115280777, 53.668830984753953],
            [-2.225824956656131, 53.670752192391816],
            [-2.224813716518044, 53.669871458964657],
            [-2.223639010546361, 53.669838607014327],
            [-2.221629006241184, 53.668977686032044],
            [-2.217730062743416, 53.668965072416171],
            [-2.215997983522497, 53.667076202787435],
            [-2.215338056382785, 53.667065708031579],
            [-2.215563059165033, 53.666078401422467],
            [-2.214842683833897, 53.665190767116556],
            [-2.207162899254415, 53.660718316247838],
            [-2.207899699943827, 53.659459591241003],
            [-2.206878494246707, 53.657031856060073],
            [-2.203604723609162, 53.655902266518261],
            [-2.204457341893349, 53.656059003975912],
            [-2.205229694999464, 53.654332847809052],
            [-2.200390200030481, 53.652874182595227],
            [-2.197632526327944, 53.652002423329506],
            [-2.196564248959676, 53.650671235204101],
            [-2.195418713606285, 53.650610195411268],
            [-2.19034554474145, 53.649162291381401],
            [-2.18958546457056, 53.648009414664315],
            [-2.18534269599858, 53.648146395528556],
            [-2.184475608073914, 53.648433561088822],
            [-2.182408289961821, 53.648241689054146],
            [-2.182531760670254, 53.647408294910775],
            [-2.181584443476178, 53.646981000444462],
            [-2.181075136598983, 53.645684775993089],
            [-2.182696367869074, 53.645595124084778],
            [-2.183528285900453, 53.645231625537306],
            [-2.18267459381447, 53.644408715001987],
            [-2.182707647693597, 53.64295886756944],
            [-2.181849730247055, 53.641842941886175],
            [-2.180925229049716, 53.641414708368615],
            [-2.180469209736599, 53.640533654667053],
            [-2.181835667450252, 53.640333843584067],
            [-2.185397376326131, 53.638208054909875],
            [-2.175685205807099, 53.6394666901679],
            [-2.174103904445976, 53.639282947745322],
            [-2.173977026423834, 53.639691197049146],
            [-2.161354108970397, 53.641358237606845],
            [-2.160599971698388, 53.64828016479661],
            [-2.163910795058564, 53.65238686861295],
            [-2.163853793342739, 53.654850607982112],
            [-2.15891766729745, 53.660310398692964],
            [-2.155029470128714, 53.664232548368467],
            [-2.153452761765762, 53.668867980640144],
            [-2.148602002866408, 53.674483615748336],
            [-2.147951758055837, 53.678553356505525],
            [-2.148137950549277, 53.681994685429515],
            [-2.14632797035525, 53.682216224803035],
            [-2.144640998674912, 53.679464311252531],
            [-2.143535524881899, 53.678482340673305],
            [-2.14225766474263, 53.677990418900215],
            [-2.141092203650713, 53.678958025455238],
            [-2.13971516574661, 53.68279398311833],
            [-2.137918497223136, 53.68386835387156],
            [-2.135985429808607, 53.684461987508314],
            [-2.134127097093437, 53.685718830208479],
            [-2.132854033337471, 53.68438191875714],
            [-2.129862745912068, 53.683088217868132],
            [-2.127677836870475, 53.681541915639428],
            [-2.126878614085084, 53.679585149995965],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000006",
        LAD13CDO: "00BR",
        LAD13NM: "Salford",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.430489235518338, 53.54126967803419],
            [-2.421417409917021, 53.540684504651416],
            [-2.421383259457346, 53.541044161653723],
            [-2.414030651540277, 53.53910229464217],
            [-2.410344624448653, 53.538503794962736],
            [-2.408774851169627, 53.538292553601643],
            [-2.402285168035601, 53.538453894723723],
            [-2.402240653097257, 53.538692237472482],
            [-2.400113217925193, 53.538543879280077],
            [-2.398356026205195, 53.538293579534823],
            [-2.394900331950216, 53.537121274409024],
            [-2.389160427474907, 53.532980265693084],
            [-2.385202797317258, 53.531448878301184],
            [-2.370875442755882, 53.528881271286302],
            [-2.368607913478828, 53.528165618861642],
            [-2.368184278071253, 53.529238344160568],
            [-2.354422405882413, 53.526253556450165],
            [-2.350868167664594, 53.529679642971175],
            [-2.353604937966596, 53.530248631399019],
            [-2.352751975747694, 53.531264146150285],
            [-2.353271485722666, 53.531329125606618],
            [-2.352827063914195, 53.53231107390387],
            [-2.354679066630391, 53.532775684867346],
            [-2.353893646632129, 53.533933023595644],
            [-2.352669569010425, 53.533691259054962],
            [-2.352149123197357, 53.53424378307173],
            [-2.353788983941141, 53.534770153892325],
            [-2.353684211088776, 53.535051801047935],
            [-2.352286187811189, 53.535124242538075],
            [-2.351463242837985, 53.535772033014808],
            [-2.348742035557121, 53.535667650555673],
            [-2.348042970575187, 53.536158657610862],
            [-2.34883501286616, 53.536699247435237],
            [-2.348662992517257, 53.537248041758261],
            [-2.346285710576166, 53.537512921308426],
            [-2.34177859702281, 53.536368191672373],
            [-2.338238221971877, 53.533631410752683],
            [-2.335208639786142, 53.531329928872054],
            [-2.328362395906674, 53.52956653889904],
            [-2.326791152134886, 53.528480553175932],
            [-2.324758021555402, 53.527346366815614],
            [-2.322954841824812, 53.527078006883102],
            [-2.319397464527183, 53.527027347309371],
            [-2.312911361038028, 53.527484043428551],
            [-2.310474356136423, 53.527170414008388],
            [-2.302832414604878, 53.521892262556023],
            [-2.302072028801629, 53.520380539381456],
            [-2.302620866068362, 53.517820149117647],
            [-2.30226111099326, 53.517070527212177],
            [-2.29773716603563, 53.515626663674055],
            [-2.295982602810594, 53.515746075664573],
            [-2.294355963353599, 53.515394153716613],
            [-2.291765982521005, 53.513985730908935],
            [-2.2907978327579, 53.512004347520907],
            [-2.289447185632465, 53.512736588905149],
            [-2.285081280655609, 53.513584809081657],
            [-2.282597737680659, 53.515189749725408],
            [-2.279449508055686, 53.515966564574228],
            [-2.274668394105445, 53.516356965181849],
            [-2.271615580858756, 53.517468621561797],
            [-2.270839377087247, 53.517308592704353],
            [-2.258986268994621, 53.518427871188223],
            [-2.258552451128356, 53.518020737459473],
            [-2.257554790777689, 53.518142438650223],
            [-2.256927578904924, 53.517923573543356],
            [-2.250236819947295, 53.516288406237827],
            [-2.246890889688769, 53.514306235167716],
            [-2.245184503009354, 53.512077928324238],
            [-2.246419357903504, 53.511532486760487],
            [-2.24657458429167, 53.510738489521515],
            [-2.247753981750067, 53.510001695250203],
            [-2.247997829072173, 53.509412447935333],
            [-2.249215266619859, 53.509515078129375],
            [-2.250266486335865, 53.508022600317879],
            [-2.248520271806991, 53.507734123271945],
            [-2.248771391986896, 53.507103512268387],
            [-2.245708800817447, 53.506528301923218],
            [-2.245270679414136, 53.504811515307999],
            [-2.245739704841961, 53.504834819823031],
            [-2.246569157851611, 53.503835395152102],
            [-2.247105041553302, 53.502396139477447],
            [-2.25153565366388, 53.503884361129963],
            [-2.252585174204576, 53.503156780001653],
            [-2.252456370051917, 53.502793021092302],
            [-2.254846399996425, 53.500358372321855],
            [-2.254313710332846, 53.499514595370741],
            [-2.255405469265291, 53.499068236795551],
            [-2.253982837943077, 53.497628627378447],
            [-2.25490663011569, 53.497333636021239],
            [-2.25253090894568, 53.495301903970351],
            [-2.253459035953833, 53.493467194739317],
            [-2.251583678613505, 53.492908484251302],
            [-2.250903818438468, 53.492384090891001],
            [-2.250490745455355, 53.490069535032639],
            [-2.249110192860125, 53.489300315746867],
            [-2.248647236982973, 53.488225363599959],
            [-2.24624343236394, 53.487188584107002],
            [-2.245138105305717, 53.486553577538345],
            [-2.245692892199476, 53.48502440052107],
            [-2.248940249862051, 53.483191220049285],
            [-2.255788645210446, 53.480596158200683],
            [-2.260196889424904, 53.477769648146008],
            [-2.262714261833415, 53.476734955918431],
            [-2.263333102179877, 53.475696323273588],
            [-2.263929734952441, 53.473755293695305],
            [-2.265346861617973, 53.472698703345465],
            [-2.272110435615576, 53.469980644142382],
            [-2.27958076256334, 53.465144657019195],
            [-2.281914948748474, 53.464834458103887],
            [-2.28389012557688, 53.465353806610331],
            [-2.300447532583505, 53.471359844341279],
            [-2.304403863904498, 53.473078309077003],
            [-2.318320648608549, 53.479401370701162],
            [-2.324008436910832, 53.480345144368208],
            [-2.329467819370466, 53.479977858919909],
            [-2.352207306860089, 53.474731994693947],
            [-2.369106440661605, 53.469275273646048],
            [-2.377153425708952, 53.466515825214216],
            [-2.381948322052964, 53.464529403570531],
            [-2.386857968507605, 53.463119479580605],
            [-2.395645932015907, 53.458823832562594],
            [-2.403235987700264, 53.453210269510087],
            [-2.407172640794391, 53.449152963666855],
            [-2.40956681307983, 53.445719238033085],
            [-2.410752921313426, 53.44209996559205],
            [-2.412794648838631, 53.439648034700149],
            [-2.413209376496402, 53.438484378500739],
            [-2.413989552405787, 53.438061909591966],
            [-2.413520608683385, 53.437516131055389],
            [-2.417279638263558, 53.431554413481848],
            [-2.419287209055177, 53.429543824684707],
            [-2.422388111586162, 53.427322600075591],
            [-2.426824853266169, 53.425084839479595],
            [-2.44937903006218, 53.415875034492352],
            [-2.449362564551695, 53.416738902102608],
            [-2.450751950721551, 53.417370057164909],
            [-2.451275653423486, 53.418657943945959],
            [-2.449188765599531, 53.420518367293802],
            [-2.448995253040528, 53.422858828915338],
            [-2.450315659044013, 53.424063720923897],
            [-2.451544392874111, 53.426692738980641],
            [-2.454068315594342, 53.428259765450257],
            [-2.455129528963516, 53.429968051648963],
            [-2.459260511672531, 53.433143181683761],
            [-2.461724642395712, 53.436352490773693],
            [-2.463321254795669, 53.436999775687951],
            [-2.466339588286266, 53.437019484408154],
            [-2.467700334566783, 53.437426737803982],
            [-2.470799439192129, 53.438894078821463],
            [-2.47410295984326, 53.439367289218801],
            [-2.478154091030088, 53.443401390492689],
            [-2.47826507677971, 53.444301601831114],
            [-2.476043398588744, 53.44744389811261],
            [-2.475987354109996, 53.448221633219958],
            [-2.478775171644644, 53.45003067392306],
            [-2.482826864108375, 53.453852479718073],
            [-2.486106748617884, 53.455174874559994],
            [-2.48689499147368, 53.458125305869309],
            [-2.488043664766246, 53.459005990526329],
            [-2.489608886498206, 53.459564964283992],
            [-2.489731317940363, 53.46012624738237],
            [-2.48803178637955, 53.46072734848412],
            [-2.486716033117657, 53.460385759684492],
            [-2.483519819942325, 53.460631546793515],
            [-2.482726883732218, 53.461232493620969],
            [-2.480968124823081, 53.461803172177206],
            [-2.479160271649693, 53.461763698862043],
            [-2.476746882841141, 53.462390871131618],
            [-2.465124689329551, 53.462216482126365],
            [-2.438456112958815, 53.464696638628283],
            [-2.434522341795673, 53.465051681904185],
            [-2.436201010867782, 53.471871451140224],
            [-2.438580113089803, 53.48212223150307],
            [-2.436730695574332, 53.484863311534205],
            [-2.436554576783306, 53.486496267499142],
            [-2.43570869900036, 53.487189672791672],
            [-2.435673141929499, 53.488278309800315],
            [-2.436346782328206, 53.490622747651138],
            [-2.438288553370573, 53.491840770960266],
            [-2.438494323788121, 53.49235505653315],
            [-2.435449031028577, 53.494498266075261],
            [-2.433623153805566, 53.497107074590261],
            [-2.431711383903997, 53.497961612507289],
            [-2.433107900515423, 53.499648190103926],
            [-2.433105827845556, 53.50107107397568],
            [-2.430123723772589, 53.501800925805824],
            [-2.430471933637911, 53.504771261326269],
            [-2.42050742521652, 53.506733876209445],
            [-2.422173779219128, 53.507519883483319],
            [-2.42132470677769, 53.508260833636783],
            [-2.422242156721509, 53.50908902769114],
            [-2.418715650784717, 53.510347239221957],
            [-2.419453927628363, 53.512118971413024],
            [-2.415349897475737, 53.51202814371922],
            [-2.415764669524733, 53.513422608243417],
            [-2.414583306833501, 53.514905313688331],
            [-2.418327601451034, 53.518098443495731],
            [-2.422831487203754, 53.520419587134462],
            [-2.425418139069176, 53.520871513461806],
            [-2.427144483187524, 53.521559262980972],
            [-2.43236843670506, 53.522372805603233],
            [-2.454114155306417, 53.526204819665807],
            [-2.451146295656158, 53.528264527368144],
            [-2.451115538170886, 53.528634967365576],
            [-2.448128901249565, 53.528089819345979],
            [-2.447808850021035, 53.529352996358206],
            [-2.444608883158426, 53.530908259116345],
            [-2.444360375361442, 53.531523992490051],
            [-2.443110626617126, 53.532333996588946],
            [-2.442468113420078, 53.53365318268208],
            [-2.442010810969192, 53.533637796838136],
            [-2.441477978544458, 53.534338167476861],
            [-2.441251010236964, 53.535145267927454],
            [-2.441914799661098, 53.536152216027844],
            [-2.440983001873782, 53.538681405688287],
            [-2.438102776414675, 53.54002138574414],
            [-2.437203981081056, 53.542122574129017],
            [-2.435763573875215, 53.541897730821368],
            [-2.430489235518338, 53.54126967803419],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000007",
        LAD13CDO: "00BS",
        LAD13NM: "Stockport",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.158442091029547, 53.454879189283965],
            [-2.154874724685035, 53.451200392786347],
            [-2.151950949706935, 53.450065311765329],
            [-2.1491960198519, 53.448515578494437],
            [-2.148350499750562, 53.447415542818057],
            [-2.148847458920728, 53.446582589034001],
            [-2.146923944127519, 53.445469501888283],
            [-2.145952989794835, 53.445512041215821],
            [-2.143448402706391, 53.444828359048344],
            [-2.142848183599241, 53.444528863728571],
            [-2.14455089442338, 53.443624366728123],
            [-2.143601732273796, 53.442529815342752],
            [-2.142716970067557, 53.442218077673317],
            [-2.141621586391669, 53.438330058001974],
            [-2.141201495158087, 53.439205137838123],
            [-2.140681160021377, 53.439362152853093],
            [-2.139434156472689, 53.438273311803734],
            [-2.137665048751438, 53.437701001862486],
            [-2.136447498149349, 53.439183707199824],
            [-2.135811296193709, 53.439360608242431],
            [-2.135266971518632, 53.437662399410179],
            [-2.134553945924825, 53.437341417648263],
            [-2.133527097753291, 53.43822434457531],
            [-2.130741412954258, 53.439422004151979],
            [-2.129141212142587, 53.439423748205051],
            [-2.122953734033469, 53.437718878993998],
            [-2.122479705579611, 53.438291035912293],
            [-2.123855992455142, 53.439983946861581],
            [-2.123332092326696, 53.441012774598732],
            [-2.122768925199551, 53.440964817994605],
            [-2.119374545709361, 53.438944953644935],
            [-2.120689001889559, 53.436924811784984],
            [-2.120418187574455, 53.43644150399107],
            [-2.119680550330579, 53.436420674467158],
            [-2.118705473327305, 53.437078709349805],
            [-2.117546541358959, 53.437128395806688],
            [-2.116197456627701, 53.438102274881103],
            [-2.115049897375686, 53.438471916753585],
            [-2.114694230642149, 53.438878539891718],
            [-2.115040173612448, 53.439328530493903],
            [-2.116945811426656, 53.439272745024134],
            [-2.117446427536638, 53.439575165350959],
            [-2.116434670513216, 53.440620621830632],
            [-2.115811567132498, 53.440670665621468],
            [-2.111237419203606, 53.437525451036343],
            [-2.110901945347158, 53.436451637246464],
            [-2.107553617870586, 53.433826466313498],
            [-2.102708533482987, 53.43260470812497],
            [-2.10196821546964, 53.432689835995362],
            [-2.100737485823915, 53.433521421312022],
            [-2.096787960308034, 53.433526493023834],
            [-2.096657821506234, 53.433882543658804],
            [-2.098128952625903, 53.436088019653099],
            [-2.09780411232249, 53.436869387881082],
            [-2.096525053870814, 53.437057391919033],
            [-2.093904718056935, 53.436540849460982],
            [-2.092794524075778, 53.43754663420124],
            [-2.091215591326028, 53.437606278976538],
            [-2.088874084911423, 53.437243115805451],
            [-2.087184411300737, 53.436150463639116],
            [-2.086501105604975, 53.434711003189214],
            [-2.084037350122914, 53.434080875149071],
            [-2.085263000587763, 53.4335478842276],
            [-2.084461282515464, 53.433058581776379],
            [-2.084798053467528, 53.432867786040617],
            [-2.085545177219735, 53.433153985163678],
            [-2.086299981272591, 53.43277053006755],
            [-2.085483531399757, 53.432438544026297],
            [-2.085170423564292, 53.431666655256052],
            [-2.085417217399618, 53.430149217511179],
            [-2.08606669786393, 53.429805388642151],
            [-2.084613610819726, 53.427925133570788],
            [-2.082962902924511, 53.427274627553167],
            [-2.083007179772339, 53.426828766171589],
            [-2.079127636705209, 53.426099742209885],
            [-2.077646935324077, 53.426163636813115],
            [-2.076465536409607, 53.42696977283525],
            [-2.074720983596932, 53.425920122842378],
            [-2.070679180016654, 53.42792703125383],
            [-2.069999108756204, 53.428030801154989],
            [-2.069486915114159, 53.427721896365753],
            [-2.069025799498197, 53.428278553894415],
            [-2.067184369645709, 53.42869397840893],
            [-2.065117260991693, 53.430160255479855],
            [-2.062221238085137, 53.42891150320478],
            [-2.061740979234168, 53.429832177280957],
            [-2.060304559990179, 53.430453121615294],
            [-2.059877937086118, 53.429961665532673],
            [-2.058272890658794, 53.430561097766201],
            [-2.058420997441107, 53.431005057569934],
            [-2.055779052196641, 53.431732597911811],
            [-2.054451481591959, 53.431672990344069],
            [-2.054247605255074, 53.431134671775979],
            [-2.053547972085815, 53.431313860353733],
            [-2.050413886165516, 53.43088647909569],
            [-2.049098983747345, 53.430040311890522],
            [-2.047416383020289, 53.43006616318182],
            [-2.045739018181533, 53.429367514635487],
            [-2.04190050420988, 53.428625581129253],
            [-2.039660336181521, 53.427825474189454],
            [-2.04001056121959, 53.427370537727782],
            [-2.039068218638491, 53.427100297392919],
            [-2.035449186873323, 53.427492434660117],
            [-2.034345502631414, 53.428656772199254],
            [-2.030527193462314, 53.428437598856682],
            [-2.026256942161317, 53.429848920935235],
            [-2.024147302033554, 53.427937513889589],
            [-2.023958925420482, 53.427486328778627],
            [-2.024435861159352, 53.427258821668183],
            [-2.022520240309586, 53.42756571078597],
            [-2.021654790374083, 53.427361833422125],
            [-2.020476088083902, 53.426676220109208],
            [-2.019725901425963, 53.425102458538419],
            [-2.021285156306333, 53.422559334852593],
            [-2.020997088604656, 53.42124346658175],
            [-2.02344103520078, 53.421735577709704],
            [-2.023711213748211, 53.420535555689412],
            [-2.016677751259937, 53.419287355138543],
            [-2.017020341823361, 53.418125988155843],
            [-2.014770840353561, 53.417835064127026],
            [-2.015226290591817, 53.416610768026793],
            [-2.013662997308259, 53.416444672695768],
            [-2.013698919688798, 53.415873896691075],
            [-2.010650945855092, 53.416711046458417],
            [-2.005094374966562, 53.415011693401425],
            [-2.000255890850147, 53.415254502850502],
            [-1.992331632997033, 53.415176973013097],
            [-1.993967308053685, 53.413166327860438],
            [-1.99330253799747, 53.411892618076926],
            [-1.993991917711226, 53.409169125330394],
            [-1.995150380721236, 53.407745390061088],
            [-1.996185561211244, 53.40465156729033],
            [-1.997388934802083, 53.403520837338618],
            [-2.001771543999377, 53.396474713358948],
            [-2.004482683380174, 53.393744809412986],
            [-2.005177243788586, 53.392378517544465],
            [-2.005053708669277, 53.390523282295959],
            [-2.004644233878951, 53.386311253850465],
            [-2.013063098785183, 53.384088634194747],
            [-2.015113984966611, 53.380551379963556],
            [-2.016981447002305, 53.381482342318606],
            [-2.01971556816992, 53.380579462091184],
            [-2.018593940982278, 53.380189539850875],
            [-2.017085189754591, 53.377816781173244],
            [-2.020945274234192, 53.377582454491147],
            [-2.020630405091526, 53.376145234133539],
            [-2.021996648432987, 53.37594184499887],
            [-2.021473102466511, 53.375043082048933],
            [-2.023418050445512, 53.374938444384718],
            [-2.023257030437347, 53.374595111875017],
            [-2.024068444108704, 53.374157204634727],
            [-2.026209967085728, 53.373602152538865],
            [-2.028037892523229, 53.373937906723057],
            [-2.030691683021908, 53.375172281174905],
            [-2.033776326800117, 53.375525592139567],
            [-2.03378348394569, 53.375076160360742],
            [-2.030835828510312, 53.372899926492082],
            [-2.028624912263295, 53.370718049908206],
            [-2.030373893957733, 53.370098299178402],
            [-2.031059334963302, 53.370246433238741],
            [-2.030958051462989, 53.371498571945594],
            [-2.032632761188847, 53.372017663296305],
            [-2.038422147685238, 53.371875705445824],
            [-2.038854507104505, 53.372970375945791],
            [-2.040564497433062, 53.374071805455849],
            [-2.041107260801029, 53.372683779086991],
            [-2.043022604598382, 53.371699747159312],
            [-2.043953326318987, 53.370613583016045],
            [-2.046669256600622, 53.36935324285011],
            [-2.0486306199727, 53.369427061046338],
            [-2.050113555678254, 53.369066900278881],
            [-2.051988599873609, 53.367383427218499],
            [-2.053909120824039, 53.36731156112603],
            [-2.055321206984857, 53.366874964475684],
            [-2.056254039718107, 53.364292997594383],
            [-2.056961043694809, 53.363713794100576],
            [-2.056927475251988, 53.363333591839577],
            [-2.056380372296024, 53.363238573402612],
            [-2.056337181154434, 53.362396360712729],
            [-2.057459183285965, 53.362042570832557],
            [-2.058260511613156, 53.361253878989508],
            [-2.059677166769814, 53.35889366357749],
            [-2.061163990865043, 53.358421903937909],
            [-2.061732366222864, 53.358715537995018],
            [-2.062302986932409, 53.358485131555369],
            [-2.063463426518626, 53.358819793214572],
            [-2.065040880790238, 53.357707946947194],
            [-2.065902057959365, 53.357871065610226],
            [-2.068078378659542, 53.357377262839762],
            [-2.06892981937074, 53.358019458834917],
            [-2.070392821944557, 53.357759729445],
            [-2.071012306777134, 53.358035311849285],
            [-2.071178269112546, 53.357548029272614],
            [-2.072119459089159, 53.357924085532332],
            [-2.072607166477881, 53.357570535898688],
            [-2.073483520254792, 53.357806398452986],
            [-2.075625983455601, 53.357757415813978],
            [-2.07802673050097, 53.358452483555716],
            [-2.078553521315159, 53.358132141907788],
            [-2.081368169971607, 53.359161245121662],
            [-2.083757943677597, 53.359537108945901],
            [-2.083867272557672, 53.36011859568729],
            [-2.0870001331738, 53.361589581154249],
            [-2.090792008173636, 53.364059517246119],
            [-2.092743436313321, 53.364477781033877],
            [-2.093680280500862, 53.365455008560289],
            [-2.094954377602317, 53.36602028164139],
            [-2.098941841598366, 53.366275007583461],
            [-2.101014691360247, 53.365854397593189],
            [-2.101464593406636, 53.366094909851995],
            [-2.103929084172481, 53.366065818352055],
            [-2.105623500016679, 53.365169061480294],
            [-2.106630314865716, 53.365158276671906],
            [-2.107439285842916, 53.364757556169103],
            [-2.110561622677569, 53.3646243632625],
            [-2.111331720363778, 53.364317134015302],
            [-2.11187804154601, 53.364634819020353],
            [-2.114404098227203, 53.364622531728358],
            [-2.115551102268599, 53.364777826312093],
            [-2.115927109187304, 53.364344209627234],
            [-2.116944978350781, 53.364536469775203],
            [-2.117238624241256, 53.364213489760481],
            [-2.119414484851618, 53.364187957706463],
            [-2.120705894195902, 53.363861268034213],
            [-2.123196301057098, 53.361929760603132],
            [-2.124825608180738, 53.362083567443854],
            [-2.124471705286853, 53.362330225982916],
            [-2.127160408430358, 53.363996572651537],
            [-2.12760210631819, 53.363963741307892],
            [-2.128147989746088, 53.364596852855378],
            [-2.129221997721691, 53.364946248724955],
            [-2.133377148218313, 53.364968635015558],
            [-2.1365137947544, 53.366977633266977],
            [-2.139026047384844, 53.367300116136995],
            [-2.139265845582724, 53.366640071779401],
            [-2.137714806544918, 53.366594234984447],
            [-2.13948157970348, 53.364598504578701],
            [-2.139290573094926, 53.363629754915543],
            [-2.139693857526342, 53.362884126134496],
            [-2.13922822753427, 53.361564242632923],
            [-2.139871178429347, 53.360137893791496],
            [-2.139169194007102, 53.357749538874735],
            [-2.13819725207545, 53.356874278959637],
            [-2.140327692577844, 53.355034512325929],
            [-2.142533329605919, 53.354172580554412],
            [-2.145733965989248, 53.351711215203437],
            [-2.14632644628021, 53.351881273119943],
            [-2.147166571405938, 53.351111709696525],
            [-2.146546727640389, 53.350845511148584],
            [-2.147092200657267, 53.350455630819567],
            [-2.149661074926979, 53.34961558904579],
            [-2.151191011788109, 53.348116149397903],
            [-2.147718619128799, 53.34481268341149],
            [-2.147177821025422, 53.344793577722527],
            [-2.146127355469518, 53.343757580686578],
            [-2.144099611935293, 53.342853994738377],
            [-2.146664711849206, 53.340594712214397],
            [-2.146738189166235, 53.340124515032237],
            [-2.148324732208713, 53.339424131548455],
            [-2.149044045909152, 53.338544142998217],
            [-2.15000878521135, 53.338280463227449],
            [-2.151034317309491, 53.336102113731897],
            [-2.151323338337336, 53.332908074213123],
            [-2.15176527063637, 53.332192014013408],
            [-2.151410447027037, 53.331218992644189],
            [-2.151968239483303, 53.330998059922663],
            [-2.152536037478602, 53.328536235677284],
            [-2.154165389598804, 53.327778190525592],
            [-2.155914007630972, 53.328037485107075],
            [-2.156021536021892, 53.328290824964071],
            [-2.156939359253014, 53.328409168692168],
            [-2.156874190483091, 53.32865284762002],
            [-2.158442707805322, 53.328918634246165],
            [-2.159858488927786, 53.328087088077879],
            [-2.160291986364077, 53.327975045991764],
            [-2.160112065025782, 53.327641808054736],
            [-2.160874899863729, 53.327670442966358],
            [-2.160648282671346, 53.327297718689962],
            [-2.161316553315183, 53.327334568072089],
            [-2.161246579959776, 53.327889264165655],
            [-2.163265422130038, 53.328126512149801],
            [-2.163502963606178, 53.327816077029766],
            [-2.164136673727684, 53.328624186470634],
            [-2.165988565999597, 53.329160941170464],
            [-2.166137662336165, 53.329662301395658],
            [-2.167279749768233, 53.329520479951441],
            [-2.167351975630554, 53.329942846215154],
            [-2.168890342671413, 53.330529433514918],
            [-2.169146632135571, 53.331168164578393],
            [-2.169924013696223, 53.330684366656335],
            [-2.169789358385287, 53.331183429975582],
            [-2.170291122460857, 53.331244735701134],
            [-2.170454045445621, 53.331809890199231],
            [-2.171052271012283, 53.331209488410799],
            [-2.172117161899362, 53.33128076307068],
            [-2.172246389785538, 53.330931815807048],
            [-2.173853411391329, 53.331390600666168],
            [-2.174277118393748, 53.331087962248688],
            [-2.174925204189878, 53.331311729361751],
            [-2.176051794225304, 53.331049398534468],
            [-2.178474600006188, 53.331241747147196],
            [-2.17968069853527, 53.330253876867879],
            [-2.179982841430339, 53.331046221965053],
            [-2.181472029981029, 53.331670473792954],
            [-2.181994780570923, 53.33136585933326],
            [-2.18216903139671, 53.331734128680409],
            [-2.183015302645547, 53.331940470229824],
            [-2.182681251868187, 53.332127947235598],
            [-2.183050680158598, 53.332486927193699],
            [-2.183912013645681, 53.332699531301493],
            [-2.184143067465968, 53.332306369475646],
            [-2.184269859738317, 53.332806842523837],
            [-2.184877440654083, 53.332674666788243],
            [-2.189108376020818, 53.335252270360002],
            [-2.190494530200504, 53.337627565137666],
            [-2.194163455419096, 53.338633767692755],
            [-2.192809711477409, 53.339829660097926],
            [-2.192699420948178, 53.341015442971205],
            [-2.190732318357637, 53.342110729564723],
            [-2.182753795505745, 53.343111984093113],
            [-2.180977835464517, 53.344027042906632],
            [-2.184829731171024, 53.346974802417989],
            [-2.185302709407544, 53.34693182129098],
            [-2.188015885764284, 53.348719908678177],
            [-2.185492949092764, 53.351993931348446],
            [-2.185441687683415, 53.352642991120163],
            [-2.189082364921845, 53.352773898391455],
            [-2.192573450124906, 53.354040201891394],
            [-2.201872027608455, 53.355410865048441],
            [-2.201938210975408, 53.354792334447389],
            [-2.208292717842902, 53.354980049190154],
            [-2.207410558125195, 53.356782012078348],
            [-2.210658271604404, 53.357269793502908],
            [-2.214540812680311, 53.356701978365273],
            [-2.214632569287048, 53.357917976155044],
            [-2.216218699520037, 53.358114657753909],
            [-2.216142792309141, 53.358850066005139],
            [-2.219553098179308, 53.358473502424786],
            [-2.220310701555342, 53.359411417512483],
            [-2.223047287624869, 53.35978385191104],
            [-2.224186086236923, 53.359756547774509],
            [-2.224625771607264, 53.359363815566773],
            [-2.228763658289429, 53.359339768181435],
            [-2.232982751493474, 53.358778796860413],
            [-2.232812005602714, 53.360255065012232],
            [-2.237593549054754, 53.359236208295073],
            [-2.239540737518649, 53.359217031509068],
            [-2.240791273900709, 53.359557878676846],
            [-2.237034570765377, 53.37004884816475],
            [-2.238676863768406, 53.370782639475237],
            [-2.238984682603751, 53.3720727891805],
            [-2.240372223206535, 53.372655159597905],
            [-2.239903625079793, 53.372983289035879],
            [-2.240043960599828, 53.373615805480661],
            [-2.241318974016029, 53.374753887882456],
            [-2.241657571184112, 53.375620603805537],
            [-2.241316310743718, 53.376142634606275],
            [-2.242063988499379, 53.378105131603135],
            [-2.241285996718292, 53.378754787178153],
            [-2.241135293275703, 53.379484967243165],
            [-2.241162879687678, 53.381172972350996],
            [-2.24206788993918, 53.381705062169395],
            [-2.242024708935106, 53.382043121447857],
            [-2.242796797025829, 53.382196156152197],
            [-2.243090398139714, 53.383065655182818],
            [-2.242482694863662, 53.384063728588593],
            [-2.242977306292235, 53.384852820212686],
            [-2.242410736648906, 53.385686317709549],
            [-2.242587344468341, 53.387658956389217],
            [-2.246246509167981, 53.387839319487725],
            [-2.245800085753382, 53.390208735742817],
            [-2.243929158586933, 53.390400438331866],
            [-2.244492344372318, 53.391330503589295],
            [-2.246048913366305, 53.391376740015815],
            [-2.246028245108983, 53.393787523032827],
            [-2.244413650025273, 53.394127015687971],
            [-2.246873097223137, 53.396078766385443],
            [-2.242574083811268, 53.397745980386844],
            [-2.242468565852223, 53.399024371364618],
            [-2.242492316692219, 53.399498021315367],
            [-2.240604895792934, 53.399773298458129],
            [-2.238467355565029, 53.400777120907087],
            [-2.236203921831752, 53.401065663481205],
            [-2.229180520105431, 53.400851953723667],
            [-2.225176309263853, 53.401318896444877],
            [-2.222567604199133, 53.401179975602624],
            [-2.219886459300748, 53.402738173959975],
            [-2.219336737163817, 53.404056011737545],
            [-2.219454717896898, 53.40505981788526],
            [-2.218429213840012, 53.405417647555289],
            [-2.212678998805078, 53.406968679414355],
            [-2.215041153757745, 53.409753595069674],
            [-2.215528223366959, 53.410877184790877],
            [-2.214605436886698, 53.411054125473299],
            [-2.215413366778936, 53.411368167555246],
            [-2.214357292013658, 53.414655387035118],
            [-2.213488037962099, 53.418924709216235],
            [-2.209708039695006, 53.417941775262108],
            [-2.20845615946056, 53.419161917494087],
            [-2.207198309636695, 53.418861194994612],
            [-2.206506859704645, 53.419002615327628],
            [-2.206269852854763, 53.420084347253301],
            [-2.207757992829228, 53.42008536014022],
            [-2.207577566570024, 53.420421845759378],
            [-2.206677778498938, 53.42073620939378],
            [-2.206811213714143, 53.420946310020526],
            [-2.204959607837866, 53.422341830748117],
            [-2.204514712128475, 53.422135857910156],
            [-2.202155402036387, 53.423435127924172],
            [-2.201013275358599, 53.424395235999405],
            [-2.199389100846527, 53.426868010927947],
            [-2.196852909681863, 53.428084775895755],
            [-2.193184845645261, 53.427653031209942],
            [-2.19308459401265, 53.429439214121402],
            [-2.191353752369984, 53.43511196316048],
            [-2.188792131987969, 53.434831108604968],
            [-2.187228444590541, 53.435223674727339],
            [-2.188728647705923, 53.436806885040234],
            [-2.18746341988405, 53.436626413112847],
            [-2.187523887333631, 53.436343179836037],
            [-2.186583065824208, 53.435992305237043],
            [-2.185731580123108, 53.43610329389351],
            [-2.183799415573087, 53.435192154456857],
            [-2.180180336613601, 53.434711397140518],
            [-2.180141241284871, 53.434365398266792],
            [-2.1790404169571, 53.434239418694801],
            [-2.179092019598562, 53.433983168468444],
            [-2.176783682266583, 53.433041918631048],
            [-2.175970068863051, 53.434295222297266],
            [-2.175333982256786, 53.434442671830816],
            [-2.174755401340629, 53.437963423053617],
            [-2.173994424663523, 53.443656957025908],
            [-2.173389764265461, 53.445639801955814],
            [-2.172014049582224, 53.447225567027068],
            [-2.17280144869454, 53.447953396117498],
            [-2.167490516475544, 53.449372164744368],
            [-2.165970247417995, 53.453744493324798],
            [-2.161425767190051, 53.451828092073299],
            [-2.161790385157343, 53.453070705960101],
            [-2.160007006277174, 53.453350855673392],
            [-2.160438088892028, 53.454256316005271],
            [-2.158442091029547, 53.454879189283965],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000008",
        LAD13CDO: "00BT",
        LAD13NM: "Tameside",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.048673831439909, 53.530234587703902],
            [-2.044770735560657, 53.529871193834744],
            [-2.039890592083095, 53.529964613369536],
            [-2.039628901597736, 53.529212375118206],
            [-2.038486765432466, 53.529032084603898],
            [-2.03830198881356, 53.529884240998605],
            [-2.036063634154265, 53.530313686707217],
            [-2.035060394130244, 53.530278932658803],
            [-2.034549753304356, 53.529378448661589],
            [-2.033543694161407, 53.529562998721964],
            [-2.032492739590494, 53.530227531064817],
            [-2.026222451561864, 53.52925204941802],
            [-2.02470739877359, 53.528456006512599],
            [-2.023235037122847, 53.528376308476496],
            [-2.020302831108844, 53.525995830987078],
            [-2.018032077472033, 53.524697380915526],
            [-2.01753238614692, 53.523707836910248],
            [-2.018807410278223, 53.521385944023024],
            [-2.016762102835121, 53.521156150380975],
            [-2.009191698827018, 53.519209207807776],
            [-2.003035329283201, 53.52019466208764],
            [-1.996919340440731, 53.522034594422237],
            [-1.991891060961751, 53.520019176847029],
            [-1.988826777237213, 53.518368670602548],
            [-1.986930008519223, 53.516706531220294],
            [-1.985788463641323, 53.51643135905573],
            [-1.9819493495708, 53.515247978531804],
            [-1.977822238072897, 53.514415867859292],
            [-1.973058134708559, 53.512424871197652],
            [-1.966732367661381, 53.510362254792675],
            [-1.963388363143203, 53.509810296535406],
            [-1.966939777039665, 53.507334122461771],
            [-1.967440898387935, 53.506622377982652],
            [-1.971321721791883, 53.504542549409067],
            [-1.972722160447416, 53.502469250107147],
            [-1.971977390257378, 53.500186024948199],
            [-1.972495209947738, 53.499030235024087],
            [-1.970779290622254, 53.49520706086561],
            [-1.971225010296449, 53.493635993214717],
            [-1.97220075644161, 53.492814680754073],
            [-1.972264845420559, 53.491601259139713],
            [-1.973433530596142, 53.490640660023473],
            [-1.974633346121841, 53.487918325042934],
            [-1.976649193970255, 53.486302612584844],
            [-1.98146761062219, 53.485245508334309],
            [-1.984670680739047, 53.483215468816702],
            [-1.984646759219724, 53.482682451822882],
            [-1.985707931024133, 53.481725313039483],
            [-1.987077695121982, 53.481641873542998],
            [-1.987822238008152, 53.481093655631497],
            [-1.989313905030857, 53.475378047055784],
            [-1.987375622363377, 53.472756831812703],
            [-1.988165240066648, 53.47206749697704],
            [-1.988533373678572, 53.470008278322751],
            [-1.987616070560023, 53.469572248921736],
            [-1.986733937390127, 53.467488632763406],
            [-1.984212540065937, 53.466807006901377],
            [-1.984010948577304, 53.466110375180875],
            [-1.981535267320627, 53.464488505903731],
            [-1.980597454464496, 53.463192223403389],
            [-1.980915414110861, 53.462853408908309],
            [-1.988163845055247, 53.460581148362905],
            [-1.988093317081743, 53.459632858363861],
            [-1.987432362975132, 53.458886749357383],
            [-1.987661857881728, 53.456861670650149],
            [-1.985564395518367, 53.455742375557506],
            [-1.986903614554063, 53.454299876893316],
            [-1.989450150826114, 53.454004402447204],
            [-1.993150370935841, 53.452278876255576],
            [-1.995035846899973, 53.450580142796312],
            [-1.994766745522357, 53.44689755040887],
            [-1.996392916730616, 53.445756069183517],
            [-1.998216187086344, 53.445756106303918],
            [-1.999378502469087, 53.445833416437196],
            [-2.002534112621623, 53.444102201300318],
            [-2.003765522860832, 53.44288512444583],
            [-2.006609312920843, 53.442395121876395],
            [-2.008307292197059, 53.441435939890674],
            [-2.006417809475126, 53.440428450222342],
            [-2.004693840475416, 53.437994449080236],
            [-2.005434340095838, 53.437127025813041],
            [-2.004005732814291, 53.436217449072387],
            [-2.004217940754484, 53.435905540273509],
            [-2.007174198618457, 53.435685174489429],
            [-2.008318256141568, 53.436077895345605],
            [-2.010045058183362, 53.437242668598316],
            [-2.012299260820653, 53.439900351821784],
            [-2.013657106019669, 53.439866943884873],
            [-2.014722807073131, 53.43955491411738],
            [-2.01656630498141, 53.438081453794638],
            [-2.017311650943749, 53.43859728630563],
            [-2.017850467764888, 53.43837608875603],
            [-2.01952017394814, 53.435770956375606],
            [-2.022451595670621, 53.434305308802159],
            [-2.02275648736276, 53.433081914769502],
            [-2.026735823519484, 53.432669408029113],
            [-2.027318127085824, 53.432389732405873],
            [-2.027323811144269, 53.431870195303119],
            [-2.025766441832646, 53.430076438172165],
            [-2.026256942161317, 53.429848920935235],
            [-2.030527193462314, 53.428437598856682],
            [-2.034345502631414, 53.428656772199254],
            [-2.035449186873323, 53.427492434660117],
            [-2.039068218638491, 53.427100297392919],
            [-2.04001056121959, 53.427370537727782],
            [-2.039660336181521, 53.427825474189454],
            [-2.04190050420988, 53.428625581129253],
            [-2.045739018181533, 53.429367514635487],
            [-2.047416383020289, 53.43006616318182],
            [-2.049098983747345, 53.430040311890522],
            [-2.050413886165516, 53.43088647909569],
            [-2.053547972085815, 53.431313860353733],
            [-2.054247605255074, 53.431134671775979],
            [-2.054451481591959, 53.431672990344069],
            [-2.055779052196641, 53.431732597911811],
            [-2.058420997441107, 53.431005057569934],
            [-2.058272890658794, 53.430561097766201],
            [-2.059877937086118, 53.429961665532673],
            [-2.060304559990179, 53.430453121615294],
            [-2.061740979234168, 53.429832177280957],
            [-2.062221238085137, 53.42891150320478],
            [-2.065117260991693, 53.430160255479855],
            [-2.067184369645709, 53.42869397840893],
            [-2.069025799498197, 53.428278553894415],
            [-2.069486915114159, 53.427721896365753],
            [-2.069999108756204, 53.428030801154989],
            [-2.070679180016654, 53.42792703125383],
            [-2.074720983596932, 53.425920122842378],
            [-2.076465536409607, 53.42696977283525],
            [-2.077646935324077, 53.426163636813115],
            [-2.079127636705209, 53.426099742209885],
            [-2.083007179772339, 53.426828766171589],
            [-2.082962902924511, 53.427274627553167],
            [-2.084613610819726, 53.427925133570788],
            [-2.08606669786393, 53.429805388642151],
            [-2.085417217399618, 53.430149217511179],
            [-2.085170423564292, 53.431666655256052],
            [-2.085483531399757, 53.432438544026297],
            [-2.086299981272591, 53.43277053006755],
            [-2.085545177219735, 53.433153985163678],
            [-2.084798053467528, 53.432867786040617],
            [-2.084461282515464, 53.433058581776379],
            [-2.085263000587763, 53.4335478842276],
            [-2.084037350122914, 53.434080875149071],
            [-2.086501105604975, 53.434711003189214],
            [-2.087184411300737, 53.436150463639116],
            [-2.088874084911423, 53.437243115805451],
            [-2.091215591326028, 53.437606278976538],
            [-2.092794524075778, 53.43754663420124],
            [-2.093904718056935, 53.436540849460982],
            [-2.096525053870814, 53.437057391919033],
            [-2.09780411232249, 53.436869387881082],
            [-2.098128952625903, 53.436088019653099],
            [-2.096657821506234, 53.433882543658804],
            [-2.096787960308034, 53.433526493023834],
            [-2.100737485823915, 53.433521421312022],
            [-2.10196821546964, 53.432689835995362],
            [-2.102708533482987, 53.43260470812497],
            [-2.107553617870586, 53.433826466313498],
            [-2.110901945347158, 53.436451637246464],
            [-2.111237419203606, 53.437525451036343],
            [-2.115811567132498, 53.440670665621468],
            [-2.116434670513216, 53.440620621830632],
            [-2.117446427536638, 53.439575165350959],
            [-2.116945811426656, 53.439272745024134],
            [-2.115040173612448, 53.439328530493903],
            [-2.114694230642149, 53.438878539891718],
            [-2.115049897375686, 53.438471916753585],
            [-2.116197456627701, 53.438102274881103],
            [-2.117546541358959, 53.437128395806688],
            [-2.118705473327305, 53.437078709349805],
            [-2.119680550330579, 53.436420674467158],
            [-2.120418187574455, 53.43644150399107],
            [-2.120689001889559, 53.436924811784984],
            [-2.119374545709361, 53.438944953644935],
            [-2.122768925199551, 53.440964817994605],
            [-2.123332092326696, 53.441012774598732],
            [-2.123855992455142, 53.439983946861581],
            [-2.122479705579611, 53.438291035912293],
            [-2.122953734033469, 53.437718878993998],
            [-2.129141212142587, 53.439423748205051],
            [-2.130741412954258, 53.439422004151979],
            [-2.133527097753291, 53.43822434457531],
            [-2.134553945924825, 53.437341417648263],
            [-2.135266971518632, 53.437662399410179],
            [-2.135811296193709, 53.439360608242431],
            [-2.136447498149349, 53.439183707199824],
            [-2.137665048751438, 53.437701001862486],
            [-2.139434156472689, 53.438273311803734],
            [-2.140681160021377, 53.439362152853093],
            [-2.141201495158087, 53.439205137838123],
            [-2.141621586391669, 53.438330058001974],
            [-2.142716970067557, 53.442218077673317],
            [-2.143601732273796, 53.442529815342752],
            [-2.14455089442338, 53.443624366728123],
            [-2.142848183599241, 53.444528863728571],
            [-2.143448402706391, 53.444828359048344],
            [-2.145952989794835, 53.445512041215821],
            [-2.146923944127519, 53.445469501888283],
            [-2.148847458920728, 53.446582589034001],
            [-2.148350499750562, 53.447415542818057],
            [-2.1491960198519, 53.448515578494437],
            [-2.151950949706935, 53.450065311765329],
            [-2.154874724685035, 53.451200392786347],
            [-2.158442091029547, 53.454879189283965],
            [-2.152068737387101, 53.457735035405484],
            [-2.150278795043488, 53.457935949308137],
            [-2.14923429819293, 53.459416763979],
            [-2.150741504567494, 53.460160013378214],
            [-2.149324610306799, 53.460258871070756],
            [-2.149096878848761, 53.460599819115117],
            [-2.150174367486622, 53.460773742683216],
            [-2.151975912322264, 53.462519718944506],
            [-2.152322069082255, 53.462445571964508],
            [-2.152784277948376, 53.463230573229104],
            [-2.149973820416624, 53.463694349407241],
            [-2.149147430031762, 53.465131742745228],
            [-2.147851239423017, 53.465769739792123],
            [-2.147926468397218, 53.466610068032935],
            [-2.146828909922139, 53.46764240085168],
            [-2.149895307086144, 53.470896904206064],
            [-2.150473700840347, 53.472570725638242],
            [-2.150486597194347, 53.472808903449739],
            [-2.155446218571433, 53.474471700444361],
            [-2.155904287686399, 53.475308826579806],
            [-2.158691477374556, 53.475284478557278],
            [-2.161498293551232, 53.474875333154557],
            [-2.162222452851644, 53.4763151997794],
            [-2.165456344654936, 53.477234772126536],
            [-2.166005860167507, 53.478665868946862],
            [-2.165573402135166, 53.478661076729004],
            [-2.165992690632658, 53.479536867883716],
            [-2.168000074478502, 53.480004154403474],
            [-2.167505675541461, 53.481107731756587],
            [-2.16895517352881, 53.484494331249955],
            [-2.169276558298677, 53.485728887379693],
            [-2.168954210717762, 53.485767095621618],
            [-2.169094329762348, 53.486137220814797],
            [-2.169609484221085, 53.486075368392115],
            [-2.169649953992615, 53.486774611302771],
            [-2.16888301751963, 53.48719096485722],
            [-2.169286153172766, 53.48813507825944],
            [-2.169016406101342, 53.488519266743793],
            [-2.16617111257988, 53.488935831581294],
            [-2.166639773423775, 53.490841624476303],
            [-2.166021697964619, 53.491579537145675],
            [-2.163956043249667, 53.49138015324737],
            [-2.163055858579602, 53.492850095713564],
            [-2.162004272523514, 53.493371058915947],
            [-2.159183585622936, 53.493219354949566],
            [-2.158325614616466, 53.493534192369403],
            [-2.157832819534574, 53.494368072109182],
            [-2.156650666651595, 53.494254577938143],
            [-2.156313389337739, 53.493943121888982],
            [-2.155662104806026, 53.49349634967475],
            [-2.154917697715931, 53.493548552412044],
            [-2.153980609096302, 53.492839679687144],
            [-2.15149671153772, 53.492858139377233],
            [-2.151059054355367, 53.493126549295269],
            [-2.14815993816931, 53.492944123841283],
            [-2.146967663053857, 53.493805790509022],
            [-2.145457200159993, 53.492001866896196],
            [-2.140965750295858, 53.491122796845936],
            [-2.138117878754451, 53.491811045800773],
            [-2.137918318405287, 53.491624317411265],
            [-2.136226560896596, 53.491887824322788],
            [-2.135256186545505, 53.495284754416311],
            [-2.131599129364853, 53.498123791322229],
            [-2.130502754732284, 53.498463858918555],
            [-2.127456145313448, 53.498451872482086],
            [-2.125989394550015, 53.498961275958251],
            [-2.124814873725953, 53.499406538242859],
            [-2.123318536169709, 53.500650292973049],
            [-2.121440633403397, 53.500820302829204],
            [-2.120694102352392, 53.501245313454099],
            [-2.119686091189284, 53.505710870328997],
            [-2.117932825661473, 53.505785422648344],
            [-2.115278071800645, 53.506463945640775],
            [-2.112793659512951, 53.507727393392884],
            [-2.110789839867992, 53.508315315101036],
            [-2.103361053744426, 53.509626201147093],
            [-2.102357077029843, 53.510979820423771],
            [-2.099964251111532, 53.511709910099739],
            [-2.099448537146884, 53.512351213608454],
            [-2.099589687577361, 53.513380266450909],
            [-2.099004409233782, 53.513948819986901],
            [-2.098074026620603, 53.513973857481147],
            [-2.09750697956593, 53.514613396881657],
            [-2.095233957722854, 53.515768443939322],
            [-2.094863729369608, 53.516779932316474],
            [-2.095413050582314, 53.517613615954062],
            [-2.09346409742449, 53.518097835724589],
            [-2.0936496502477, 53.518802379197382],
            [-2.092945452847755, 53.519545370403527],
            [-2.093185989944623, 53.520563565940776],
            [-2.092124263517827, 53.520598546850444],
            [-2.078271104577084, 53.520629121481612],
            [-2.077207114698058, 53.520257696782224],
            [-2.075141469152864, 53.520613159338282],
            [-2.074703668734196, 53.521235430245653],
            [-2.071139696064978, 53.521270868614323],
            [-2.070686443980222, 53.521711568788788],
            [-2.07017490105509, 53.521569854908265],
            [-2.068589160644279, 53.522166707745981],
            [-2.064689662714173, 53.522796278024927],
            [-2.064222787831157, 53.521273900217679],
            [-2.063693282249767, 53.52120497412767],
            [-2.062276306137195, 53.521743228442929],
            [-2.061845845727965, 53.522364549425092],
            [-2.058442454230259, 53.522982871154632],
            [-2.056308625087739, 53.522192024125644],
            [-2.054127701008665, 53.522252359335262],
            [-2.054441930701026, 53.523813496155256],
            [-2.053125808427577, 53.525548846289418],
            [-2.053220840779689, 53.526747851974037],
            [-2.055949675746044, 53.529047621596909],
            [-2.057310575933887, 53.529190787919816],
            [-2.057072588829143, 53.530576907605308],
            [-2.054940428994116, 53.531343719144417],
            [-2.050902176703035, 53.530371176947405],
            [-2.048673831439909, 53.530234587703902],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000009",
        LAD13CDO: "00BU",
        LAD13NM: "Trafford",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.28389012557688, 53.465353806610331],
            [-2.281914948748474, 53.464834458103887],
            [-2.27958076256334, 53.465144657019195],
            [-2.272110435615576, 53.469980644142382],
            [-2.265346861617973, 53.472698703345465],
            [-2.265123831946238, 53.4724475215328],
            [-2.267933593955999, 53.469829197268517],
            [-2.265736510855418, 53.469719954216444],
            [-2.264505367821124, 53.468690810757387],
            [-2.265136351082591, 53.468417060108095],
            [-2.264529996646272, 53.467807188468775],
            [-2.264004545789815, 53.467608807321064],
            [-2.263529648820809, 53.467788727373772],
            [-2.262700304888078, 53.467409443603188],
            [-2.259949029006578, 53.465577321403934],
            [-2.258447949133372, 53.463710076805327],
            [-2.258464899166045, 53.463029611604711],
            [-2.257710440584822, 53.462557549438557],
            [-2.255900607310361, 53.462157859818831],
            [-2.253637906559594, 53.462591439929795],
            [-2.253039702999207, 53.461284884413601],
            [-2.253459317130547, 53.461184221933117],
            [-2.253221058953171, 53.460630137757079],
            [-2.253870664522864, 53.460456178922399],
            [-2.253603982663649, 53.459934514459349],
            [-2.258178935593026, 53.458848784370545],
            [-2.271477084338034, 53.450773672738123],
            [-2.273023657844856, 53.45056161180527],
            [-2.276717257432562, 53.450554903931497],
            [-2.279591842201679, 53.450108667135332],
            [-2.279751513283105, 53.449202250942264],
            [-2.284518585496683, 53.448972562460604],
            [-2.286167129860156, 53.449165470127447],
            [-2.289580291684013, 53.445140269706371],
            [-2.291736458699304, 53.445386697904475],
            [-2.292768663060678, 53.444420600990931],
            [-2.293297876429531, 53.444531658334235],
            [-2.293259903787608, 53.443609529043535],
            [-2.291522641757335, 53.443185930885221],
            [-2.289911064232154, 53.441317546046598],
            [-2.291408348412316, 53.439247436938466],
            [-2.29295323382491, 53.438865237918478],
            [-2.295397311063553, 53.43877651956155],
            [-2.29421632186949, 53.436048719765061],
            [-2.296080942300721, 53.435992874088562],
            [-2.299698296460459, 53.437308756090339],
            [-2.300201228703779, 53.436264821912339],
            [-2.300045536558836, 53.433823926075902],
            [-2.299653930390067, 53.433359303457067],
            [-2.296101873331861, 53.43251066185536],
            [-2.293276268888314, 53.43265965763635],
            [-2.289185514508223, 53.43202426260585],
            [-2.28789277734495, 53.431379314162541],
            [-2.286745578595208, 53.430214464302523],
            [-2.28593211823582, 53.429208800222817],
            [-2.285330371709741, 53.427226468251533],
            [-2.283651014875412, 53.424991424066242],
            [-2.280320785025828, 53.423853253109421],
            [-2.276211272432991, 53.423413408411612],
            [-2.273706342582291, 53.422566166702843],
            [-2.273907632253383, 53.421576065125016],
            [-2.275405910291981, 53.419413565396816],
            [-2.276425941978314, 53.419625134439052],
            [-2.276114714446368, 53.419197999670011],
            [-2.278246480839765, 53.418225878814603],
            [-2.278410588997394, 53.417321246824784],
            [-2.277796841626461, 53.41549530250056],
            [-2.280645311467132, 53.415327741546619],
            [-2.281591527570902, 53.415308434016289],
            [-2.28304502931236, 53.416007900780194],
            [-2.284169974279853, 53.416165223465782],
            [-2.287234057955743, 53.414948026066277],
            [-2.288099306807901, 53.415416042157595],
            [-2.288938354260023, 53.415345700292072],
            [-2.290904353251068, 53.414184094846895],
            [-2.293934870565982, 53.41360139970331],
            [-2.296544584945391, 53.411797228118708],
            [-2.297281471323688, 53.410896537049183],
            [-2.29768938579147, 53.408346359525027],
            [-2.299516592478809, 53.408918853630588],
            [-2.300610014006531, 53.409743053151097],
            [-2.303617699707488, 53.40960780012594],
            [-2.304259097634676, 53.409896497451768],
            [-2.305973925160887, 53.410090760135191],
            [-2.307214326247545, 53.409788253334987],
            [-2.312800431638203, 53.409861828659452],
            [-2.315827198352641, 53.410883947286152],
            [-2.319896145962538, 53.411637137959374],
            [-2.309395980174373, 53.400104604585316],
            [-2.307377746603819, 53.397409646863977],
            [-2.304982155744496, 53.397004116888667],
            [-2.302943468107328, 53.394333404813878],
            [-2.301840525584717, 53.39381396263223],
            [-2.300122178207916, 53.392169787980855],
            [-2.299897876757791, 53.389996907139853],
            [-2.300481088462665, 53.389542413741246],
            [-2.299619737121381, 53.387647983568677],
            [-2.299176146328436, 53.386141706634],
            [-2.300226580167656, 53.385002007869517],
            [-2.299943799086543, 53.384342055984163],
            [-2.296519279807842, 53.382222113086712],
            [-2.294322281615528, 53.38169992226635],
            [-2.29277685009426, 53.380823739668003],
            [-2.288622282921902, 53.376909432459016],
            [-2.287221941063182, 53.376330349889336],
            [-2.285642849908092, 53.37621279557726],
            [-2.286324167997785, 53.375823752217322],
            [-2.290829547284989, 53.372370206644497],
            [-2.292714575863262, 53.37021731175389],
            [-2.296356019382167, 53.363054259028345],
            [-2.298449010560423, 53.360871089933212],
            [-2.30217530206855, 53.359362399253143],
            [-2.305956221982382, 53.358384681065623],
            [-2.313999519326579, 53.357408280545911],
            [-2.313941776174757, 53.358341455420025],
            [-2.315196994833769, 53.359035664493419],
            [-2.317953152796458, 53.359102954309137],
            [-2.319855973429863, 53.358581917200105],
            [-2.321267400667645, 53.359454517804551],
            [-2.32211691658974, 53.35953042774139],
            [-2.322722768679477, 53.359175535568866],
            [-2.322514910179779, 53.358511835853427],
            [-2.32342496015141, 53.358250497970893],
            [-2.32439970823903, 53.361363319900128],
            [-2.326539485766487, 53.361369165937219],
            [-2.327940312629233, 53.360441288370026],
            [-2.32904872401716, 53.360766321578176],
            [-2.330922157665962, 53.361889214925668],
            [-2.32959722691895, 53.363108150020182],
            [-2.329898041594404, 53.363921690508072],
            [-2.331756876710664, 53.363919231249312],
            [-2.332906570129763, 53.365475559324331],
            [-2.335145955221537, 53.365891754724188],
            [-2.337227013597329, 53.366805428345785],
            [-2.337761042692739, 53.366682570523942],
            [-2.337787006994652, 53.366165649468279],
            [-2.338592819983946, 53.366205611405015],
            [-2.340085247434601, 53.367356407417994],
            [-2.340953231246397, 53.367462692193854],
            [-2.343694486058068, 53.366539780129237],
            [-2.347764042354934, 53.367825946337859],
            [-2.34807546735257, 53.366208877889839],
            [-2.349007528396024, 53.365881663883748],
            [-2.3509767985074, 53.366505990485948],
            [-2.351168281174921, 53.367312608179994],
            [-2.352080941096881, 53.368094627365231],
            [-2.352756964263995, 53.368244538337777],
            [-2.353232303947482, 53.367933921966056],
            [-2.353967855743741, 53.36819421024213],
            [-2.356588662345237, 53.368531568758122],
            [-2.357338575575902, 53.368349551530727],
            [-2.357084291720196, 53.366877072263065],
            [-2.358266881739553, 53.366682966682937],
            [-2.36010926391784, 53.366853596585315],
            [-2.360617572632641, 53.366540154507],
            [-2.360417133671277, 53.365583468072487],
            [-2.359117892486057, 53.364768519749305],
            [-2.359276567595262, 53.36433838390812],
            [-2.361924719467769, 53.364024763696392],
            [-2.363308508477274, 53.364529315276336],
            [-2.363614818385603, 53.363971084812974],
            [-2.364555982556765, 53.363849561710531],
            [-2.364007293108362, 53.363297535676864],
            [-2.365028846280167, 53.363089472274794],
            [-2.366912786009988, 53.364438283848727],
            [-2.36758146135996, 53.36425914844979],
            [-2.36858412046228, 53.364472681250362],
            [-2.368895571419245, 53.363821837998124],
            [-2.369454697740074, 53.363661907004968],
            [-2.370386666969592, 53.364041933918514],
            [-2.370369326787892, 53.364468050483339],
            [-2.373354823521127, 53.365620977666481],
            [-2.375658818979455, 53.365307230623806],
            [-2.376915864236378, 53.365901011485875],
            [-2.377950546275648, 53.365987624311373],
            [-2.378172662976932, 53.366802192181026],
            [-2.379275926098065, 53.36750070406665],
            [-2.380031210800048, 53.367597175565471],
            [-2.37982330801581, 53.368046371975474],
            [-2.380268123644084, 53.368380231930033],
            [-2.379923140675426, 53.368793910705662],
            [-2.380966453999917, 53.369165410518747],
            [-2.380745192992102, 53.369801615204018],
            [-2.382240089763874, 53.370248065307223],
            [-2.38272731004966, 53.369440220332585],
            [-2.383414270750126, 53.36995666009075],
            [-2.384698405293105, 53.370027131790096],
            [-2.386382502099317, 53.37028325905316],
            [-2.387126120737192, 53.371074546932732],
            [-2.390280288923569, 53.370684948144699],
            [-2.390638108599163, 53.371846909656789],
            [-2.39167062183414, 53.372333406171684],
            [-2.39304854667113, 53.372460108734302],
            [-2.396166438307857, 53.37447134497117],
            [-2.397801597254435, 53.37428882730034],
            [-2.39793893620114, 53.374832183072925],
            [-2.400699112242192, 53.375204063537282],
            [-2.400865776200978, 53.375984617961514],
            [-2.39945895086737, 53.376478319526406],
            [-2.400096382647505, 53.377289656832382],
            [-2.407122253755362, 53.379807880086403],
            [-2.408486350547788, 53.382071054366698],
            [-2.407896465566105, 53.382640256233522],
            [-2.41059763799509, 53.383848953122026],
            [-2.412621040424375, 53.383983993551674],
            [-2.414434836490536, 53.384517026194466],
            [-2.415549598627927, 53.384435846616867],
            [-2.416429688895165, 53.384803110583697],
            [-2.41714735567009, 53.384702627441328],
            [-2.418564349583918, 53.386157421530868],
            [-2.419748384986919, 53.386236856589029],
            [-2.42034673913886, 53.386690474592065],
            [-2.422448153591691, 53.386336091391954],
            [-2.423274649297627, 53.38720146573651],
            [-2.424509278518423, 53.386620008533598],
            [-2.425154038414433, 53.387198379601031],
            [-2.426590877919129, 53.387444933542547],
            [-2.42783861897129, 53.388923592991105],
            [-2.428362719550041, 53.388409359838008],
            [-2.429778803877243, 53.388380897363682],
            [-2.431997188798017, 53.387698738024625],
            [-2.433340654599779, 53.388220601475922],
            [-2.433250718482577, 53.389135972429806],
            [-2.433871758986424, 53.389436633353746],
            [-2.436995760876186, 53.389240067465401],
            [-2.438787703920255, 53.390082020039088],
            [-2.438575787055398, 53.391553341700671],
            [-2.439860243577468, 53.391437154799888],
            [-2.440580033378698, 53.390954505690061],
            [-2.441655488021599, 53.390986484725964],
            [-2.44279328695278, 53.391953940925006],
            [-2.445482650082715, 53.392168647611626],
            [-2.445270165650995, 53.392844488007448],
            [-2.44674059857011, 53.393110449400943],
            [-2.446857655181956, 53.393661913973972],
            [-2.44819351639132, 53.394283412823036],
            [-2.448439378450439, 53.394783156673761],
            [-2.450828646045748, 53.394899096553885],
            [-2.451806099304329, 53.394613156462576],
            [-2.452009995686242, 53.395122040526701],
            [-2.452607177720858, 53.395137754385367],
            [-2.453032391780297, 53.394961761203305],
            [-2.452811365138913, 53.39454013372054],
            [-2.454496207192132, 53.393890141692907],
            [-2.455435452521475, 53.394255095385411],
            [-2.456043873005769, 53.393916596419935],
            [-2.456895174356444, 53.394212662108188],
            [-2.457522716129284, 53.393835431183689],
            [-2.458254040670329, 53.393881163817603],
            [-2.458503158559623, 53.392994824931023],
            [-2.459948797954679, 53.394299806982367],
            [-2.460863688087711, 53.393936731314106],
            [-2.461963441003769, 53.394537415264836],
            [-2.463258585338884, 53.394710369908786],
            [-2.464081119189792, 53.394153473210523],
            [-2.465906295373593, 53.394803431855898],
            [-2.46628161066287, 53.394057705688567],
            [-2.468189088848539, 53.393846195029923],
            [-2.467850891423164, 53.393311797661802],
            [-2.468172727572059, 53.393040875879294],
            [-2.471044448540978, 53.39232126912723],
            [-2.472647016364216, 53.392692456252981],
            [-2.473644883077715, 53.393720397582875],
            [-2.475713266921248, 53.394187670243127],
            [-2.478454712592616, 53.396201854566961],
            [-2.471732888142819, 53.39773421271336],
            [-2.46597709724819, 53.400731167465686],
            [-2.46210181645482, 53.403835630147206],
            [-2.456257719275636, 53.410807222952357],
            [-2.453682100704593, 53.413196328537076],
            [-2.450833344554396, 53.415251133781787],
            [-2.44937903006218, 53.415875034492352],
            [-2.426824853266169, 53.425084839479595],
            [-2.422388111586162, 53.427322600075591],
            [-2.419287209055177, 53.429543824684707],
            [-2.417279638263558, 53.431554413481848],
            [-2.413520608683385, 53.437516131055389],
            [-2.413989552405787, 53.438061909591966],
            [-2.413209376496402, 53.438484378500739],
            [-2.412794648838631, 53.439648034700149],
            [-2.410752921313426, 53.44209996559205],
            [-2.40956681307983, 53.445719238033085],
            [-2.407172640794391, 53.449152963666855],
            [-2.403235987700264, 53.453210269510087],
            [-2.395645932015907, 53.458823832562594],
            [-2.386857968507605, 53.463119479580605],
            [-2.381948322052964, 53.464529403570531],
            [-2.377153425708952, 53.466515825214216],
            [-2.369106440661605, 53.469275273646048],
            [-2.352207306860089, 53.474731994693947],
            [-2.329467819370466, 53.479977858919909],
            [-2.324008436910832, 53.480345144368208],
            [-2.318320648608549, 53.479401370701162],
            [-2.304403863904498, 53.473078309077003],
            [-2.300447532583505, 53.471359844341279],
            [-2.28389012557688, 53.465353806610331],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000010",
        LAD13CDO: "00BW",
        LAD13NM: "Wigan",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.633309879324666, 53.60749744096217],
            [-2.632098594656743, 53.607249475521655],
            [-2.632368858089745, 53.606523581698347],
            [-2.631493925075993, 53.606025751674146],
            [-2.631285174139549, 53.605202616569777],
            [-2.632206065695301, 53.603517819626489],
            [-2.631763484986252, 53.603228034512476],
            [-2.631379838887671, 53.599898954253085],
            [-2.629834924549121, 53.599149375419039],
            [-2.628048394994172, 53.597082444779318],
            [-2.62823350510692, 53.595301765674705],
            [-2.627496700805535, 53.595235523627927],
            [-2.62590820613725, 53.593668176373207],
            [-2.628229078017079, 53.591541931412394],
            [-2.628617084610676, 53.590404654431545],
            [-2.62756580043867, 53.5893989769258],
            [-2.623749753546142, 53.589605898277291],
            [-2.620753343151172, 53.590386402363521],
            [-2.619916608218056, 53.590917463733838],
            [-2.618062529316868, 53.591099636788243],
            [-2.616310352662305, 53.590706895919809],
            [-2.61247425416027, 53.590479419859555],
            [-2.6077161275336, 53.589058353123598],
            [-2.606250215772968, 53.588282901313761],
            [-2.603257678238889, 53.588184778801065],
            [-2.601701763376065, 53.588828097736474],
            [-2.596093697805618, 53.58880224938747],
            [-2.595420185141947, 53.588068554290295],
            [-2.588817306724758, 53.586348503466361],
            [-2.585087842608957, 53.585052700642159],
            [-2.583557656893569, 53.584176615713446],
            [-2.582185544721369, 53.58281796269776],
            [-2.578189904612792, 53.581348865432872],
            [-2.577563855284565, 53.580852134605088],
            [-2.577965819438707, 53.579981012556338],
            [-2.577714683416837, 53.57939707992422],
            [-2.579084328579787, 53.578384651124431],
            [-2.578551062124231, 53.577491086515636],
            [-2.575062208556134, 53.576706147454175],
            [-2.573321929695502, 53.574217519479696],
            [-2.572208195778031, 53.57405117512284],
            [-2.572143046662521, 53.573700039416217],
            [-2.568609071340162, 53.572108866681184],
            [-2.568544888430099, 53.571602224588318],
            [-2.567853501106617, 53.571506637203633],
            [-2.565733387934309, 53.570038989152366],
            [-2.565680971374832, 53.568714342658822],
            [-2.56719853230242, 53.56758899273629],
            [-2.568064724219256, 53.565636188118873],
            [-2.567761678751214, 53.565443477272403],
            [-2.568471888567227, 53.564698556729134],
            [-2.564447946571652, 53.563660580360782],
            [-2.56395223697744, 53.563169454273073],
            [-2.562850495713759, 53.56308566025038],
            [-2.561390507635803, 53.562171209383443],
            [-2.560062302805189, 53.562084857465031],
            [-2.559633893016983, 53.561082854919206],
            [-2.563148142880281, 53.56026188864886],
            [-2.563379879052072, 53.559523744636884],
            [-2.562615897202063, 53.558947587576625],
            [-2.562281100960297, 53.55749393341484],
            [-2.561265310913305, 53.556593569891199],
            [-2.560189741756653, 53.556192336619418],
            [-2.559823494574717, 53.555549580505037],
            [-2.56118984643535, 53.554770168087728],
            [-2.561049301822594, 53.553275149613697],
            [-2.562646224013654, 53.552816424392894],
            [-2.562566223128503, 53.551906270548692],
            [-2.563696309011294, 53.551759828641039],
            [-2.564070180160861, 53.551158535225213],
            [-2.56694591622692, 53.550738653786816],
            [-2.566611968695939, 53.550145201097344],
            [-2.56819095986685, 53.549825807334841],
            [-2.567788186145695, 53.549165271464801],
            [-2.569539238653459, 53.54795069040491],
            [-2.569332319404841, 53.547602024806523],
            [-2.5676205860319, 53.54803262239237],
            [-2.566543381573082, 53.547384271068687],
            [-2.562967913905593, 53.547374196997453],
            [-2.559620657014874, 53.546358936920235],
            [-2.558020119895906, 53.544462662671954],
            [-2.559367848457367, 53.543783127835638],
            [-2.559151671726427, 53.542381039706584],
            [-2.560436915997694, 53.541661336563706],
            [-2.560703815458598, 53.540847527319912],
            [-2.561644938223065, 53.540469188740921],
            [-2.561934307141449, 53.539189668072389],
            [-2.560452640794005, 53.538055088211117],
            [-2.556891892476597, 53.538071730542107],
            [-2.556803518480339, 53.537776421496098],
            [-2.556468875915422, 53.537916402340123],
            [-2.555384117330317, 53.537349780583931],
            [-2.554486389025466, 53.537353049543128],
            [-2.553701454533454, 53.536172007456827],
            [-2.552081176112291, 53.536070741662016],
            [-2.550968254727194, 53.535534770781709],
            [-2.551059461819683, 53.535238629541261],
            [-2.550574860207835, 53.53510064253112],
            [-2.550426879240102, 53.53544018953216],
            [-2.54999540659042, 53.53486152029722],
            [-2.549300754595089, 53.534930330528496],
            [-2.549094387683924, 53.534489944669048],
            [-2.544996001916755, 53.533386041758305],
            [-2.545267055899041, 53.532993806891056],
            [-2.543763367796521, 53.532561120832064],
            [-2.542495793227541, 53.530533687651804],
            [-2.541626840509559, 53.530416284165987],
            [-2.541399362268466, 53.530677980628312],
            [-2.540707258158404, 53.529640248047606],
            [-2.53970786652577, 53.529465894426778],
            [-2.539792920936402, 53.528918112007446],
            [-2.538548645095658, 53.528583960703592],
            [-2.538624132439879, 53.52823217101583],
            [-2.53822999876518, 53.528437985056442],
            [-2.537204120712388, 53.528074971780171],
            [-2.537341173360738, 53.527819082879873],
            [-2.536265894419295, 53.527603694712461],
            [-2.536701087670064, 53.527066027846516],
            [-2.536089329618709, 53.526881813081339],
            [-2.535960993476761, 53.526156119451393],
            [-2.535148514144399, 53.525962912513563],
            [-2.535436328867293, 53.5258196054181],
            [-2.53457892190604, 53.524693591474822],
            [-2.535038670441793, 53.524189976779738],
            [-2.533760180167877, 53.523999744641934],
            [-2.533730973913465, 53.522996760267851],
            [-2.532900158176453, 53.523019343609661],
            [-2.532859725035381, 53.523282886480345],
            [-2.532285638757497, 53.52284950329804],
            [-2.531677306460034, 53.523293545755529],
            [-2.531226130128841, 53.523279372825485],
            [-2.531321332696938, 53.522931994145743],
            [-2.528982113205571, 53.523070906348536],
            [-2.528973463913325, 53.523345093344872],
            [-2.528529840439093, 53.523331775658995],
            [-2.528243330057114, 53.523701570144681],
            [-2.528701375048823, 53.523903583210689],
            [-2.528116491719895, 53.523933134674095],
            [-2.527956887903481, 53.524683479110926],
            [-2.527475777253796, 53.524689198894812],
            [-2.527797424647687, 53.525566852089618],
            [-2.526684249537179, 53.525449519651197],
            [-2.525389278458938, 53.526364853340269],
            [-2.523166083023726, 53.526632575197276],
            [-2.522735848156215, 53.527096465332299],
            [-2.521776244551988, 53.527079985135913],
            [-2.52120278875025, 53.527552584492284],
            [-2.52099160961389, 53.527184977871407],
            [-2.520560820546858, 53.527237190455494],
            [-2.519679209653888, 53.527923252196935],
            [-2.518814752012988, 53.527917120612322],
            [-2.518335786420376, 53.528471989773259],
            [-2.517204418775319, 53.528475093430075],
            [-2.515815340650451, 53.529243318721747],
            [-2.51538759185993, 53.531038363081343],
            [-2.51601800310272, 53.53213852754596],
            [-2.516150546674558, 53.533856549222662],
            [-2.515830338374918, 53.534447574599419],
            [-2.515282351336595, 53.53442207408424],
            [-2.51553130055823, 53.535788145165704],
            [-2.513063106127859, 53.536303916687253],
            [-2.510121607517528, 53.534103548912157],
            [-2.50926482412036, 53.534621342366613],
            [-2.509062941196611, 53.535651380616912],
            [-2.507972987692784, 53.53648295565246],
            [-2.507309573365701, 53.537783705814725],
            [-2.507012077843706, 53.538772798836924],
            [-2.505243558705353, 53.538892643058816],
            [-2.505246328514742, 53.538618483650005],
            [-2.50363091481373, 53.538543506257987],
            [-2.502180275909374, 53.537856600360918],
            [-2.499211376624828, 53.53803262686943],
            [-2.498069842004611, 53.53744864581045],
            [-2.496398479854056, 53.537100555124525],
            [-2.496470545933993, 53.536683191794985],
            [-2.490164176626561, 53.535555103857014],
            [-2.489742597893843, 53.534586079583448],
            [-2.487126926843126, 53.534105100711308],
            [-2.481984633577329, 53.533396086697181],
            [-2.478391923566144, 53.533488720664167],
            [-2.472418834956137, 53.531720183843994],
            [-2.469456254658984, 53.532030274058698],
            [-2.466066687682328, 53.530693475868603],
            [-2.461548446340604, 53.530699325461853],
            [-2.455994729942108, 53.529389456192654],
            [-2.451115538170886, 53.528634967365576],
            [-2.451146295656158, 53.528264527368144],
            [-2.454114155306417, 53.526204819665807],
            [-2.43236843670506, 53.522372805603233],
            [-2.427144483187524, 53.521559262980972],
            [-2.425418139069176, 53.520871513461806],
            [-2.422831487203754, 53.520419587134462],
            [-2.418327601451034, 53.518098443495731],
            [-2.414583306833501, 53.514905313688331],
            [-2.415764669524733, 53.513422608243417],
            [-2.415349897475737, 53.51202814371922],
            [-2.419453927628363, 53.512118971413024],
            [-2.418715650784717, 53.510347239221957],
            [-2.422242156721509, 53.50908902769114],
            [-2.42132470677769, 53.508260833636783],
            [-2.422173779219128, 53.507519883483319],
            [-2.42050742521652, 53.506733876209445],
            [-2.430471933637911, 53.504771261326269],
            [-2.430123723772589, 53.501800925805824],
            [-2.433105827845556, 53.50107107397568],
            [-2.433107900515423, 53.499648190103926],
            [-2.431711383903997, 53.497961612507289],
            [-2.433623153805566, 53.497107074590261],
            [-2.435449031028577, 53.494498266075261],
            [-2.438494323788121, 53.49235505653315],
            [-2.438288553370573, 53.491840770960266],
            [-2.436346782328206, 53.490622747651138],
            [-2.435673141929499, 53.488278309800315],
            [-2.43570869900036, 53.487189672791672],
            [-2.436554576783306, 53.486496267499142],
            [-2.436730695574332, 53.484863311534205],
            [-2.438580113089803, 53.48212223150307],
            [-2.436201010867782, 53.471871451140224],
            [-2.434522341795673, 53.465051681904185],
            [-2.438456112958815, 53.464696638628283],
            [-2.465124689329551, 53.462216482126365],
            [-2.476746882841141, 53.462390871131618],
            [-2.479160271649693, 53.461763698862043],
            [-2.480968124823081, 53.461803172177206],
            [-2.482726883732218, 53.461232493620969],
            [-2.483519819942325, 53.460631546793515],
            [-2.486716033117657, 53.460385759684492],
            [-2.48803178637955, 53.46072734848412],
            [-2.489731317940363, 53.46012624738237],
            [-2.489863664493184, 53.462202061873093],
            [-2.492271405569271, 53.465138610539057],
            [-2.491125524452794, 53.467521699097112],
            [-2.491088476154899, 53.469009456376249],
            [-2.491678401098548, 53.469869929913614],
            [-2.491398728487728, 53.472131700083011],
            [-2.490440050269449, 53.47313157094186],
            [-2.489139033516649, 53.475808299891497],
            [-2.492743488643574, 53.479361034874636],
            [-2.496335740739638, 53.480913762705697],
            [-2.502706051579574, 53.479711423099751],
            [-2.536446754502982, 53.47148844483619],
            [-2.550485143538358, 53.468045766899458],
            [-2.55263908312996, 53.467797623060122],
            [-2.549076117633093, 53.461469916362972],
            [-2.570390193384721, 53.458459519159966],
            [-2.570551945393755, 53.456707767437813],
            [-2.573748465897974, 53.451182438267836],
            [-2.574631279703983, 53.447979146273205],
            [-2.576743094947111, 53.446040907135895],
            [-2.578931981924419, 53.447458606921302],
            [-2.58251800279021, 53.448192616690982],
            [-2.583319088768168, 53.448855661830073],
            [-2.58390954843062, 53.45172463904489],
            [-2.58473619487537, 53.452159238723048],
            [-2.583149781376211, 53.453312144964556],
            [-2.583978030039014, 53.453861796219975],
            [-2.586704919650319, 53.455150880666295],
            [-2.58835702303762, 53.455483421391158],
            [-2.588660788458828, 53.456104835197664],
            [-2.592931098323971, 53.455734053668749],
            [-2.596104988914679, 53.456437343061992],
            [-2.598817663891869, 53.458283516547972],
            [-2.599516593022873, 53.459574370085193],
            [-2.599225601663299, 53.460942098878178],
            [-2.5997225731319, 53.461044771181022],
            [-2.60085139538378, 53.46120179151125],
            [-2.603494855058398, 53.460893637363426],
            [-2.606824665002534, 53.46150686748058],
            [-2.609102180807268, 53.460993709861157],
            [-2.609117008829286, 53.461292955013874],
            [-2.610231174556418, 53.461580296432842],
            [-2.613383034910402, 53.461854483543107],
            [-2.613695621829319, 53.462641179127196],
            [-2.614844393917824, 53.463338180489949],
            [-2.61549129933717, 53.463804953018467],
            [-2.615157968826094, 53.465023728017762],
            [-2.616761485564136, 53.46622262890466],
            [-2.615888793535828, 53.468059009887618],
            [-2.615112841335516, 53.467840090942872],
            [-2.614487825068465, 53.468579474622977],
            [-2.614894296394086, 53.468665471173807],
            [-2.614549498834114, 53.47034541762325],
            [-2.611238104767441, 53.469916573896306],
            [-2.6106653362237, 53.470524436193884],
            [-2.608989918657385, 53.470300185088696],
            [-2.608305508451794, 53.470372884689034],
            [-2.607864358645754, 53.470811976296559],
            [-2.611872943783944, 53.474984689615269],
            [-2.612051711826202, 53.476727560488747],
            [-2.6091356778652, 53.476058433918432],
            [-2.609348186305286, 53.476693742205882],
            [-2.612171470180784, 53.477301317704608],
            [-2.612210207227966, 53.477689426224785],
            [-2.613188805482582, 53.478157206776835],
            [-2.612240511008389, 53.478433526004117],
            [-2.612139101785728, 53.481122531581484],
            [-2.613967627583439, 53.481170674977463],
            [-2.615918237468452, 53.481740397806746],
            [-2.618785591821897, 53.48182625237137],
            [-2.631094585951397, 53.48085760938114],
            [-2.633147697014796, 53.481010338114764],
            [-2.637413721553969, 53.480413281114743],
            [-2.64554046676998, 53.480236612100271],
            [-2.645532647046691, 53.480713949072147],
            [-2.648003802530504, 53.482190880511808],
            [-2.651912688592592, 53.485332702341353],
            [-2.652877443760724, 53.485447881296224],
            [-2.653026499737611, 53.486419632823747],
            [-2.65406375302302, 53.486445418852902],
            [-2.655915503731756, 53.490323732935373],
            [-2.656731482457436, 53.492507073464971],
            [-2.659919583700733, 53.492229737031806],
            [-2.662930715511147, 53.492784742279895],
            [-2.661373815957336, 53.493664364879542],
            [-2.663871991718891, 53.494664423473061],
            [-2.664880869738334, 53.495945979645754],
            [-2.664807163163037, 53.496726599157441],
            [-2.666321350284839, 53.499752711229434],
            [-2.668492440934078, 53.500151362979253],
            [-2.669615991332889, 53.499891593892833],
            [-2.669674646258872, 53.499500261731754],
            [-2.671603471285639, 53.499067871454166],
            [-2.673272918358111, 53.499107913715342],
            [-2.673841039425449, 53.499379762034543],
            [-2.67488729702092, 53.499002628573251],
            [-2.677760808272123, 53.498826367271498],
            [-2.680186401643403, 53.499204486949687],
            [-2.68018334031669, 53.499484049562618],
            [-2.681899389649202, 53.49998392199992],
            [-2.6819150709134, 53.5003973076916],
            [-2.682577965105698, 53.500276671164755],
            [-2.683272364184849, 53.500896510712316],
            [-2.685054515630505, 53.501095741039776],
            [-2.68590093987913, 53.500857182083386],
            [-2.688292616721482, 53.501456451682209],
            [-2.690274147682744, 53.501304799394397],
            [-2.691121092146479, 53.501841017218524],
            [-2.69295405076397, 53.502192642170471],
            [-2.694505601454244, 53.502020941019403],
            [-2.696544329015236, 53.502231092796457],
            [-2.696755169787135, 53.501849645979313],
            [-2.698892683259439, 53.502016036401855],
            [-2.699029255214386, 53.502986006013316],
            [-2.700718826050536, 53.504600349664592],
            [-2.703147734114675, 53.503962273621937],
            [-2.703822130140067, 53.505081877997675],
            [-2.705460418604551, 53.504772897325189],
            [-2.705994732724184, 53.505352202885213],
            [-2.705547592310864, 53.505573266615542],
            [-2.705752194645072, 53.505818345956797],
            [-2.706262775137423, 53.505604097426293],
            [-2.707072083874612, 53.506669853314669],
            [-2.708498897894497, 53.513384868582719],
            [-2.710006564506735, 53.514626233354662],
            [-2.714255216684812, 53.512590169631572],
            [-2.715579950563776, 53.510664079236712],
            [-2.717516964657642, 53.510981447680642],
            [-2.718260586614279, 53.511617867479245],
            [-2.719182554209645, 53.511831644463058],
            [-2.719656249784537, 53.511309252099402],
            [-2.720547423089739, 53.511574438997862],
            [-2.721139118283547, 53.511075598377595],
            [-2.722028354712858, 53.510960568290479],
            [-2.723026624583703, 53.509987359644406],
            [-2.723777716356877, 53.510350446801439],
            [-2.723278955448315, 53.511079745204341],
            [-2.723505839530385, 53.511739932625282],
            [-2.726757046943595, 53.514825765652354],
            [-2.727384808628377, 53.517842113631431],
            [-2.728459506049127, 53.518244548981272],
            [-2.730520750560796, 53.520584281769423],
            [-2.726233013192485, 53.523775292148557],
            [-2.724876913635462, 53.524390253296694],
            [-2.720943536976382, 53.525045041254842],
            [-2.719726693456382, 53.525695057804761],
            [-2.718596123660576, 53.527077111761031],
            [-2.71792532856646, 53.52719529752035],
            [-2.718088792798208, 53.528743046150673],
            [-2.717186730312086, 53.529639229010321],
            [-2.716731459198247, 53.532248643479008],
            [-2.717076470884614, 53.532845212533253],
            [-2.716296951717782, 53.533767618279114],
            [-2.71700174361734, 53.534226303728907],
            [-2.716251969592836, 53.53521414695205],
            [-2.716575396275922, 53.53524636511991],
            [-2.716381345821052, 53.537872186538443],
            [-2.714788955996299, 53.538021041270795],
            [-2.714559173061786, 53.538264207078484],
            [-2.715901278768664, 53.539377948480748],
            [-2.716048338923396, 53.540491647992987],
            [-2.716770344917406, 53.54062304826661],
            [-2.718607984810713, 53.543544080698375],
            [-2.717701888775308, 53.544398042504241],
            [-2.717915119598346, 53.544602599895114],
            [-2.716105433648617, 53.545571632275532],
            [-2.712568226412938, 53.548821450374604],
            [-2.710872500412404, 53.549673770731516],
            [-2.710146562167703, 53.5507576075806],
            [-2.710263447001009, 53.553049887151772],
            [-2.70918646548063, 53.554267933558833],
            [-2.707778200231249, 53.554634021670047],
            [-2.707080642154279, 53.557157631640116],
            [-2.705963746143244, 53.557984883182016],
            [-2.705644818209976, 53.558867640498228],
            [-2.704819650337989, 53.559176319296469],
            [-2.705319495955519, 53.561181405948737],
            [-2.704738712728393, 53.561844587210771],
            [-2.70421436330817, 53.562634171177507],
            [-2.705208091442877, 53.563468740284975],
            [-2.707934846776989, 53.564195078717738],
            [-2.709520183628445, 53.564015799522288],
            [-2.711270334079043, 53.564751448366323],
            [-2.711044374887829, 53.565682204169086],
            [-2.712594943835938, 53.567473372329744],
            [-2.7123915704578, 53.5683096163879],
            [-2.714307591672976, 53.568749409601715],
            [-2.715659830970651, 53.570227124632247],
            [-2.715773254556866, 53.571041703173172],
            [-2.717660589983182, 53.572542258063471],
            [-2.715607072036601, 53.575060557752892],
            [-2.7176800071424, 53.576006309636483],
            [-2.71922352315878, 53.576097708059542],
            [-2.717764651640156, 53.576990940945215],
            [-2.716996619504603, 53.578013944535627],
            [-2.716429983493902, 53.577551736306695],
            [-2.7148030325004, 53.578336279714136],
            [-2.714498244584186, 53.578980778203196],
            [-2.714944605153714, 53.580639183753],
            [-2.714375813951249, 53.581661877180743],
            [-2.711646068019065, 53.582808902562917],
            [-2.710408458371847, 53.585029224476436],
            [-2.70970257018387, 53.585543061972416],
            [-2.708200623847591, 53.585879145396845],
            [-2.707481329081555, 53.586677983688745],
            [-2.705995898342345, 53.586649009434041],
            [-2.704693297936436, 53.58707645301503],
            [-2.702762221360477, 53.587229832879508],
            [-2.702522766127785, 53.588822199033174],
            [-2.701736101291075, 53.589393989397045],
            [-2.702814172139085, 53.58981371297326],
            [-2.701405672438763, 53.590604876472923],
            [-2.699923049268534, 53.590753781645404],
            [-2.700070878899487, 53.591196946695256],
            [-2.698182857616005, 53.591316742674934],
            [-2.69676330110863, 53.590250898962822],
            [-2.694227340527453, 53.589365899688971],
            [-2.6933772375069, 53.589392403157007],
            [-2.693660005455741, 53.591805963471728],
            [-2.692589983233955, 53.596123926860265],
            [-2.689915226164122, 53.603796641614835],
            [-2.689314174703036, 53.604286381131317],
            [-2.688066766718517, 53.60415514048541],
            [-2.687780790928669, 53.6036687127555],
            [-2.687049839227613, 53.603605499781203],
            [-2.686631230808623, 53.603233085324959],
            [-2.685783778973134, 53.603254125759506],
            [-2.684074516427419, 53.603903889125611],
            [-2.683253745593216, 53.603706340005274],
            [-2.682787739502175, 53.603953486214941],
            [-2.680153298577488, 53.604026019752148],
            [-2.679020982158055, 53.604660743895074],
            [-2.678023621938839, 53.604665502071967],
            [-2.677594590324365, 53.604959158190425],
            [-2.676304455030359, 53.604801071705886],
            [-2.675401833113382, 53.605060543828806],
            [-2.675074576764333, 53.604819703401269],
            [-2.674004146058992, 53.604981239082157],
            [-2.673054468101239, 53.604753785670077],
            [-2.670546419992179, 53.605081566552762],
            [-2.669159948215059, 53.604372952420057],
            [-2.667995404684419, 53.60431744079019],
            [-2.666603472432286, 53.603449732429503],
            [-2.666005974082675, 53.603605863752286],
            [-2.664950918119774, 53.603207253837262],
            [-2.663895005019317, 53.603427043216435],
            [-2.661341002468387, 53.60332793607401],
            [-2.659372086582933, 53.6036318276524],
            [-2.658866442926463, 53.604156841118844],
            [-2.655142492060546, 53.604434362651759],
            [-2.652569531837385, 53.605852417096749],
            [-2.650098125021146, 53.606120245366931],
            [-2.647734538762612, 53.606720010064272],
            [-2.646948943522117, 53.607233904162662],
            [-2.643748475286579, 53.607172970283401],
            [-2.641734446504864, 53.607511868268809],
            [-2.64139057483113, 53.607260239296423],
            [-2.640343679637021, 53.607591228080132],
            [-2.639252282583069, 53.607378646785094],
            [-2.636609432039727, 53.607714525057062],
            [-2.636162937021931, 53.608275080266793],
            [-2.634593993869363, 53.608267234170086],
            [-2.633309879324666, 53.60749744096217],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000011",
        LAD13CDO: "00BX",
        LAD13NM: "Knowsley",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.88423517609249, 53.503080428189307],
            [-2.881502129765526, 53.502734779137214],
            [-2.873333667447051, 53.500023536024848],
            [-2.872026857142323, 53.49621647245344],
            [-2.87106843447115, 53.494981222517609],
            [-2.869683447364568, 53.493964799463157],
            [-2.866423917728606, 53.492528711516641],
            [-2.862157284348719, 53.491365872082213],
            [-2.861381031976839, 53.491067652077028],
            [-2.859180191920741, 53.492098323468895],
            [-2.850462398740899, 53.49324383030639],
            [-2.849837465449884, 53.493123333667938],
            [-2.8439384634387, 53.490195276378401],
            [-2.844448332336666, 53.489003367110662],
            [-2.843398078177911, 53.487876410415609],
            [-2.844422035614613, 53.484114593512388],
            [-2.825574978020074, 53.48276587288197],
            [-2.824965062427698, 53.485193439672955],
            [-2.822949612405606, 53.48267432331761],
            [-2.81675202811028, 53.477835987437594],
            [-2.818652923493123, 53.470133133799827],
            [-2.818712232307492, 53.466875215253303],
            [-2.804408134915738, 53.467220484841597],
            [-2.806273676619611, 53.463200737699474],
            [-2.807004183807503, 53.455815161929095],
            [-2.805107086215841, 53.455520550239108],
            [-2.805281283863289, 53.453664101634054],
            [-2.804188450527024, 53.451377539915441],
            [-2.800259579214834, 53.44912708320529],
            [-2.795070216715413, 53.443213894644899],
            [-2.796767998978536, 53.440614719639555],
            [-2.798952536216665, 53.441343493872829],
            [-2.803973368313692, 53.441426651495618],
            [-2.80515424604716, 53.440740943774799],
            [-2.805223394350608, 53.439223175948655],
            [-2.804802743771083, 53.438392754025109],
            [-2.80307957611813, 53.437540535407472],
            [-2.800472301661685, 53.437219165018838],
            [-2.799186794424302, 53.436098787619123],
            [-2.796809537979784, 53.435996921269165],
            [-2.797747212385248, 53.433740775062383],
            [-2.793962171280993, 53.433851394980223],
            [-2.793236994471121, 53.432818912060661],
            [-2.793790126749965, 53.432777483542083],
            [-2.793337552808263, 53.431025885672263],
            [-2.792641391056927, 53.43106376732915],
            [-2.787946005941619, 53.430362260002326],
            [-2.787075745913997, 53.430426426483862],
            [-2.785603757978609, 53.429201963910167],
            [-2.783572522865481, 53.42857890688051],
            [-2.781675513088827, 53.428541902556326],
            [-2.77620226793525, 53.426671093067256],
            [-2.780209279394851, 53.420553292952398],
            [-2.781621480359528, 53.420922483283093],
            [-2.781837510302279, 53.420558821504009],
            [-2.781384613760554, 53.420396393076665],
            [-2.782459923780581, 53.419299911908354],
            [-2.782119642427398, 53.419205961693244],
            [-2.782460306953487, 53.418665302206357],
            [-2.778250995097796, 53.416153487278557],
            [-2.778777923453169, 53.415677241791023],
            [-2.785674796372571, 53.414939018616515],
            [-2.785592947770267, 53.411315280706702],
            [-2.784124542434621, 53.407057957819461],
            [-2.78668676983119, 53.402192544612198],
            [-2.786776226016147, 53.401171726369235],
            [-2.772259777747126, 53.400131239322249],
            [-2.766389659549925, 53.400051319311444],
            [-2.754919623219234, 53.400962165626225],
            [-2.747422800024375, 53.402297434087686],
            [-2.745174885428848, 53.402079577790303],
            [-2.746264652375336, 53.398946473491485],
            [-2.74532084218515, 53.397178884316119],
            [-2.743395625650106, 53.395269088883317],
            [-2.744324602635545, 53.395246220739288],
            [-2.744123225240435, 53.394823205798296],
            [-2.745287017909528, 53.394555269940213],
            [-2.750647883927891, 53.393772010987362],
            [-2.750050232014573, 53.392367224851789],
            [-2.747106069125869, 53.392376701231669],
            [-2.747889083123309, 53.388750213196893],
            [-2.749460526425906, 53.38816057844808],
            [-2.75163980044249, 53.386912711846918],
            [-2.751491073713735, 53.386663759777527],
            [-2.752596376008588, 53.385908027924437],
            [-2.7602705804763, 53.384319605926834],
            [-2.758782231367038, 53.383732220987724],
            [-2.757774523839121, 53.382863113635452],
            [-2.757653810173087, 53.380721846457583],
            [-2.758658128632048, 53.380980636194202],
            [-2.760580908779184, 53.382330202723942],
            [-2.764524602833057, 53.382682534557617],
            [-2.7675929298785, 53.383762171368716],
            [-2.769053480879331, 53.38388490425065],
            [-2.770779125302407, 53.383118711385869],
            [-2.772140605674639, 53.382918448839028],
            [-2.773211245063063, 53.381022067286118],
            [-2.775621461519616, 53.380710695111915],
            [-2.776670820522372, 53.381042749237658],
            [-2.777051599278494, 53.379663182644521],
            [-2.778541837593013, 53.377381993259604],
            [-2.778888892350559, 53.375642187526601],
            [-2.779738083234615, 53.375554843587317],
            [-2.78131827264319, 53.371634364032779],
            [-2.78228907852095, 53.370396532450101],
            [-2.780246780321254, 53.369916416251556],
            [-2.781910916648223, 53.36747493984381],
            [-2.781893504599146, 53.367016622740898],
            [-2.78117598478689, 53.366816376502037],
            [-2.781305232049527, 53.366323840332385],
            [-2.780913661945498, 53.366196065292698],
            [-2.784563493282675, 53.361915002313438],
            [-2.783875089324877, 53.361741547464511],
            [-2.78482228850106, 53.360706995668188],
            [-2.787301044634, 53.356274437085716],
            [-2.800900112852794, 53.355881900154614],
            [-2.809064060219398, 53.355065460827369],
            [-2.811947625623978, 53.352873258507991],
            [-2.816219721416225, 53.350325420995723],
            [-2.816953603902706, 53.348794982389101],
            [-2.818806834459121, 53.347984962359348],
            [-2.822497834252758, 53.347757322599826],
            [-2.823200260932205, 53.348038324915798],
            [-2.828711773437323, 53.346952960073196],
            [-2.832169788484066, 53.346647536447179],
            [-2.840405652761844, 53.347315209367409],
            [-2.846338780087571, 53.353965512541265],
            [-2.853495934187021, 53.363498499413488],
            [-2.853615081032277, 53.366225777263132],
            [-2.85202512733955, 53.369390443641016],
            [-2.852404876838116, 53.372225523250769],
            [-2.853994686662332, 53.377012422087475],
            [-2.856189350957535, 53.378734247277542],
            [-2.853803923175179, 53.379799439935127],
            [-2.851847092398363, 53.380877705900737],
            [-2.847942061427377, 53.38113832663619],
            [-2.84659877328126, 53.381856185827786],
            [-2.844431554764034, 53.38204142919453],
            [-2.841722003404962, 53.381561687885544],
            [-2.83497962764575, 53.381190169325336],
            [-2.83244246972611, 53.381601605212381],
            [-2.830884593259706, 53.380964364740159],
            [-2.82906721917809, 53.380973408045776],
            [-2.827920783622606, 53.380308101313325],
            [-2.825112451778056, 53.380535193026518],
            [-2.824197017186258, 53.380073199284709],
            [-2.82301691901319, 53.380151456397179],
            [-2.821954681916985, 53.380651366218061],
            [-2.822704349503221, 53.382106889109103],
            [-2.825064056454676, 53.383707693557753],
            [-2.826153213708432, 53.384906462757954],
            [-2.828707220532044, 53.38589101298377],
            [-2.829937187723989, 53.387054612315872],
            [-2.831547065051067, 53.387571951191497],
            [-2.83364996364316, 53.388925384280213],
            [-2.832712646841604, 53.389898228412619],
            [-2.832870363696888, 53.390736686099558],
            [-2.832008652201381, 53.391965178650175],
            [-2.832446433136969, 53.393670006042981],
            [-2.833322485601063, 53.394867498675133],
            [-2.833553738504382, 53.396081174859589],
            [-2.83401975897388, 53.39661005873436],
            [-2.834583371493185, 53.396361622976436],
            [-2.835350941173787, 53.396928842672438],
            [-2.836670245378238, 53.398250849239403],
            [-2.837345391401992, 53.399852419904143],
            [-2.845014552935891, 53.398040158852581],
            [-2.848039665486014, 53.396939145034466],
            [-2.849741895258028, 53.397306368580807],
            [-2.856220016528323, 53.394944547972543],
            [-2.866403397171676, 53.398464811149459],
            [-2.870455766929064, 53.399308147204344],
            [-2.871865603582504, 53.400366633252894],
            [-2.872746849846564, 53.401683353097717],
            [-2.875354218088835, 53.400989198521366],
            [-2.879748793067561, 53.405640086760592],
            [-2.88141385404138, 53.407162201882628],
            [-2.881673535941071, 53.408007933083084],
            [-2.890542574894242, 53.406910180883351],
            [-2.891680491621626, 53.409891381126812],
            [-2.892411463190234, 53.410746152715269],
            [-2.892364588694424, 53.411812581259575],
            [-2.891110447626204, 53.412343302422897],
            [-2.889744369541063, 53.413495075191115],
            [-2.88928636283521, 53.413392419232352],
            [-2.888235450789495, 53.414510365311628],
            [-2.884097069631028, 53.416860203971382],
            [-2.881331408896302, 53.4161246903912],
            [-2.871163316009869, 53.412369174867237],
            [-2.868714276092063, 53.415553786854424],
            [-2.865927656941795, 53.418285960028193],
            [-2.866308063470786, 53.419160509790856],
            [-2.867682523476323, 53.419710532779227],
            [-2.867938875301509, 53.420550023481098],
            [-2.868885644086467, 53.423220008621072],
            [-2.868224118049084, 53.423914263281468],
            [-2.870098604514874, 53.425138378573706],
            [-2.869455454233864, 53.426069810662668],
            [-2.868347472319279, 53.426422145162142],
            [-2.868145232686637, 53.426910809530668],
            [-2.866177954433152, 53.428007349771377],
            [-2.867477542774037, 53.428709829257379],
            [-2.868597125748463, 53.430841926138662],
            [-2.869304016769101, 53.431259256055405],
            [-2.868908772912993, 53.43151022390478],
            [-2.869386318429156, 53.432710349355986],
            [-2.868789066323223, 53.432946607424007],
            [-2.872661481127991, 53.435202426440043],
            [-2.871955742500751, 53.435507807856531],
            [-2.873416706922486, 53.436502978356607],
            [-2.87084376189011, 53.437957280863628],
            [-2.870123489942059, 53.441679398715195],
            [-2.87059216426546, 53.442511940823422],
            [-2.870054711430058, 53.443617883516225],
            [-2.868245644495198, 53.444681838963312],
            [-2.868352242142156, 53.446364663783712],
            [-2.867628395826047, 53.448599142981962],
            [-2.86843254872907, 53.449931727983923],
            [-2.869642052422082, 53.450538658726238],
            [-2.872089457049904, 53.453206660807616],
            [-2.874157621861728, 53.453893565577424],
            [-2.875122267601994, 53.454740435532685],
            [-2.878426241053023, 53.455902700853024],
            [-2.882021380804536, 53.457734187218712],
            [-2.883406777477205, 53.458087994391683],
            [-2.887799704416274, 53.460527338168731],
            [-2.889539319138549, 53.461031250507844],
            [-2.890102985630495, 53.461480085969413],
            [-2.889979059157423, 53.462396066982471],
            [-2.891170491862358, 53.462683813747994],
            [-2.891441294617445, 53.463099771247457],
            [-2.891818912977286, 53.463221896549499],
            [-2.893037935812155, 53.463387171401621],
            [-2.894176040449667, 53.464360231868341],
            [-2.893996970971342, 53.464655505194749],
            [-2.894696829396945, 53.464914535371783],
            [-2.895305067338746, 53.465901440219277],
            [-2.894889422749926, 53.465978262698286],
            [-2.895150831637521, 53.4671619234784],
            [-2.900316036528615, 53.469142883995829],
            [-2.905929790611076, 53.470162916783551],
            [-2.903940689145767, 53.467516407570869],
            [-2.90492543210343, 53.465229396688208],
            [-2.905963793569431, 53.464969840045661],
            [-2.912504107821206, 53.465243653992644],
            [-2.913768643743106, 53.464856458330623],
            [-2.914378547584514, 53.464989319758701],
            [-2.915758307874298, 53.46883583960436],
            [-2.918658637266869, 53.468295816447252],
            [-2.921087384382875, 53.472624990796078],
            [-2.920041741977676, 53.473248780015808],
            [-2.922616177957533, 53.474967356235851],
            [-2.918963045298137, 53.476875965270033],
            [-2.919081897921139, 53.478052580651685],
            [-2.917920266863978, 53.478336567900172],
            [-2.916844424392607, 53.479245504010159],
            [-2.917161670189051, 53.480121271959739],
            [-2.919560274836782, 53.482056993251469],
            [-2.914268809692939, 53.482518276878245],
            [-2.912326058904243, 53.483627958973671],
            [-2.911525063832695, 53.485348229240614],
            [-2.908907310660499, 53.488800977091998],
            [-2.907392285625163, 53.489774288464687],
            [-2.905250392811597, 53.491960418545368],
            [-2.90386781403394, 53.491658076800121],
            [-2.899441930082112, 53.49177506991505],
            [-2.899807878206165, 53.492400627642411],
            [-2.899322374115356, 53.493675290408362],
            [-2.896751200381681, 53.495406069364918],
            [-2.896379806435013, 53.496736491723667],
            [-2.897842950585445, 53.49806392911438],
            [-2.8986685534586, 53.49996423252464],
            [-2.897745780138422, 53.501384196331941],
            [-2.895381702227962, 53.502379007320172],
            [-2.892364045855754, 53.503199777788844],
            [-2.889648941734425, 53.50285058530887],
            [-2.8879955519441, 53.503812990021842],
            [-2.886223948884351, 53.503738051838063],
            [-2.88423517609249, 53.503080428189307],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000012",
        LAD13CDO: "00BY",
        LAD13NM: "Liverpool",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.922616177957533, 53.474967356235851],
            [-2.920041741977676, 53.473248780015808],
            [-2.921087384382875, 53.472624990796078],
            [-2.918658637266869, 53.468295816447252],
            [-2.915758307874298, 53.46883583960436],
            [-2.914378547584514, 53.464989319758701],
            [-2.913768643743106, 53.464856458330623],
            [-2.912504107821206, 53.465243653992644],
            [-2.905963793569431, 53.464969840045661],
            [-2.90492543210343, 53.465229396688208],
            [-2.903940689145767, 53.467516407570869],
            [-2.905929790611076, 53.470162916783551],
            [-2.900316036528615, 53.469142883995829],
            [-2.895150831637521, 53.4671619234784],
            [-2.894889422749926, 53.465978262698286],
            [-2.895305067338746, 53.465901440219277],
            [-2.894696829396945, 53.464914535371783],
            [-2.893996970971342, 53.464655505194749],
            [-2.894176040449667, 53.464360231868341],
            [-2.893037935812155, 53.463387171401621],
            [-2.891818912977286, 53.463221896549499],
            [-2.891441294617445, 53.463099771247457],
            [-2.891170491862358, 53.462683813747994],
            [-2.889979059157423, 53.462396066982471],
            [-2.890102985630495, 53.461480085969413],
            [-2.889539319138549, 53.461031250507844],
            [-2.887799704416274, 53.460527338168731],
            [-2.883406777477205, 53.458087994391683],
            [-2.882021380804536, 53.457734187218712],
            [-2.878426241053023, 53.455902700853024],
            [-2.875122267601994, 53.454740435532685],
            [-2.874157621861728, 53.453893565577424],
            [-2.872089457049904, 53.453206660807616],
            [-2.869642052422082, 53.450538658726238],
            [-2.86843254872907, 53.449931727983923],
            [-2.867628395826047, 53.448599142981962],
            [-2.868352242142156, 53.446364663783712],
            [-2.868245644495198, 53.444681838963312],
            [-2.870054711430058, 53.443617883516225],
            [-2.87059216426546, 53.442511940823422],
            [-2.870123489942059, 53.441679398715195],
            [-2.87084376189011, 53.437957280863628],
            [-2.873416706922486, 53.436502978356607],
            [-2.871955742500751, 53.435507807856531],
            [-2.872661481127991, 53.435202426440043],
            [-2.868789066323223, 53.432946607424007],
            [-2.869386318429156, 53.432710349355986],
            [-2.868908772912993, 53.43151022390478],
            [-2.869304016769101, 53.431259256055405],
            [-2.868597125748463, 53.430841926138662],
            [-2.867477542774037, 53.428709829257379],
            [-2.866177954433152, 53.428007349771377],
            [-2.868145232686637, 53.426910809530668],
            [-2.868347472319279, 53.426422145162142],
            [-2.869455454233864, 53.426069810662668],
            [-2.870098604514874, 53.425138378573706],
            [-2.868224118049084, 53.423914263281468],
            [-2.868885644086467, 53.423220008621072],
            [-2.867938875301509, 53.420550023481098],
            [-2.867682523476323, 53.419710532779227],
            [-2.866308063470786, 53.419160509790856],
            [-2.865927656941795, 53.418285960028193],
            [-2.868714276092063, 53.415553786854424],
            [-2.871163316009869, 53.412369174867237],
            [-2.881331408896302, 53.4161246903912],
            [-2.884097069631028, 53.416860203971382],
            [-2.888235450789495, 53.414510365311628],
            [-2.88928636283521, 53.413392419232352],
            [-2.889744369541063, 53.413495075191115],
            [-2.891110447626204, 53.412343302422897],
            [-2.892364588694424, 53.411812581259575],
            [-2.892411463190234, 53.410746152715269],
            [-2.891680491621626, 53.409891381126812],
            [-2.890542574894242, 53.406910180883351],
            [-2.881673535941071, 53.408007933083084],
            [-2.88141385404138, 53.407162201882628],
            [-2.879748793067561, 53.405640086760592],
            [-2.875354218088835, 53.400989198521366],
            [-2.872746849846564, 53.401683353097717],
            [-2.871865603582504, 53.400366633252894],
            [-2.870455766929064, 53.399308147204344],
            [-2.866403397171676, 53.398464811149459],
            [-2.856220016528323, 53.394944547972543],
            [-2.849741895258028, 53.397306368580807],
            [-2.848039665486014, 53.396939145034466],
            [-2.845014552935891, 53.398040158852581],
            [-2.837345391401992, 53.399852419904143],
            [-2.836670245378238, 53.398250849239403],
            [-2.835350941173787, 53.396928842672438],
            [-2.834583371493185, 53.396361622976436],
            [-2.83401975897388, 53.39661005873436],
            [-2.833553738504382, 53.396081174859589],
            [-2.833322485601063, 53.394867498675133],
            [-2.832446433136969, 53.393670006042981],
            [-2.832008652201381, 53.391965178650175],
            [-2.832870363696888, 53.390736686099558],
            [-2.832712646841604, 53.389898228412619],
            [-2.83364996364316, 53.388925384280213],
            [-2.831547065051067, 53.387571951191497],
            [-2.829937187723989, 53.387054612315872],
            [-2.828707220532044, 53.38589101298377],
            [-2.826153213708432, 53.384906462757954],
            [-2.825064056454676, 53.383707693557753],
            [-2.822704349503221, 53.382106889109103],
            [-2.821954681916985, 53.380651366218061],
            [-2.82301691901319, 53.380151456397179],
            [-2.824197017186258, 53.380073199284709],
            [-2.825112451778056, 53.380535193026518],
            [-2.827920783622606, 53.380308101313325],
            [-2.82906721917809, 53.380973408045776],
            [-2.830884593259706, 53.380964364740159],
            [-2.83244246972611, 53.381601605212381],
            [-2.83497962764575, 53.381190169325336],
            [-2.841722003404962, 53.381561687885544],
            [-2.844431554764034, 53.38204142919453],
            [-2.84659877328126, 53.381856185827786],
            [-2.847942061427377, 53.38113832663619],
            [-2.851847092398363, 53.380877705900737],
            [-2.853803923175179, 53.379799439935127],
            [-2.856189350957535, 53.378734247277542],
            [-2.853994686662332, 53.377012422087475],
            [-2.852404876838116, 53.372225523250769],
            [-2.85202512733955, 53.369390443641016],
            [-2.853615081032277, 53.366225777263132],
            [-2.853495934187021, 53.363498499413488],
            [-2.846338780087571, 53.353965512541265],
            [-2.840405652761844, 53.347315209367409],
            [-2.832169788484066, 53.346647536447179],
            [-2.828711773437323, 53.346952960073196],
            [-2.823200260932205, 53.348038324915798],
            [-2.822497834252758, 53.347757322599826],
            [-2.818806834459121, 53.347984962359348],
            [-2.818797122678649, 53.34779176768501],
            [-2.818977196076949, 53.345274538237234],
            [-2.819868537731775, 53.343662098642149],
            [-2.818000190326181, 53.342965699600683],
            [-2.819840531535915, 53.341184948122205],
            [-2.818709561752471, 53.340714505315205],
            [-2.818779805393874, 53.339755805367233],
            [-2.820377044499553, 53.339871575433783],
            [-2.820209797612338, 53.340391385669449],
            [-2.822328515273322, 53.340677927969622],
            [-2.823482870337711, 53.337371932045166],
            [-2.827326392589967, 53.33785680688019],
            [-2.831065502400532, 53.337756207909713],
            [-2.831082865836747, 53.337258999533901],
            [-2.832457310222015, 53.337273686016822],
            [-2.831530664329614, 53.335111121483358],
            [-2.826660993647305, 53.33164108472149],
            [-2.828829649611067, 53.330960854731273],
            [-2.83303112638839, 53.328496484094131],
            [-2.836410307016036, 53.327002245622829],
            [-2.840520096834689, 53.326789082110999],
            [-2.854972840335867, 53.327455814932101],
            [-2.864927631033387, 53.330129272009849],
            [-2.871870377167096, 53.332460801002654],
            [-2.878158621068302, 53.334182801752732],
            [-2.903226617236565, 53.344838117107578],
            [-2.904896541322121, 53.345670424716246],
            [-2.907375550347111, 53.347462871405597],
            [-2.907041589284961, 53.347723394040479],
            [-2.908243967367354, 53.348106165208684],
            [-2.909869503484362, 53.349523922662371],
            [-2.91444106296787, 53.352129927225157],
            [-2.915918556579279, 53.353703347168526],
            [-2.918920868783228, 53.354914445490493],
            [-2.922446261779629, 53.357240550855671],
            [-2.923650627430189, 53.357348989036723],
            [-2.930141341283106, 53.360581334534551],
            [-2.945736045135922, 53.367173505545352],
            [-2.963775616469632, 53.373453585486452],
            [-2.967647973589107, 53.375591286301471],
            [-2.969007651463442, 53.375957790302749],
            [-2.96929990801597, 53.375787323297409],
            [-2.972563776941154, 53.377588225553808],
            [-2.972922765247727, 53.3781030637792],
            [-2.973664531226836, 53.378197690760942],
            [-2.973573974652676, 53.37870900262422],
            [-2.974651503161933, 53.378760433649447],
            [-2.978630726222272, 53.381186351860016],
            [-2.98306701586772, 53.384702330861316],
            [-2.983431826164569, 53.386048568400696],
            [-2.984146383182734, 53.386129871464163],
            [-2.983999962189427, 53.386756710265743],
            [-2.984502222986249, 53.386368739826018],
            [-2.98497254203004, 53.386486210384568],
            [-2.986504464837422, 53.388232697737692],
            [-2.986265626736512, 53.388568161888806],
            [-2.986948184833057, 53.388824098517802],
            [-2.987950653502518, 53.389972683897241],
            [-2.986685284528979, 53.390069444845658],
            [-2.986805109045427, 53.390374078744578],
            [-2.988135970476945, 53.390248009727273],
            [-2.988072478369285, 53.391077317095629],
            [-2.988910239255569, 53.391669043540681],
            [-2.995061107455651, 53.400908906894536],
            [-2.995010495092789, 53.40170485048764],
            [-2.995887430887197, 53.402286313698426],
            [-2.999601985477455, 53.407712454930305],
            [-2.999952934748799, 53.407667267825509],
            [-3.001845760961082, 53.410308528895762],
            [-3.001763472676732, 53.411031929982194],
            [-3.001751831389373, 53.411496755684375],
            [-3.002465073357997, 53.411502454450719],
            [-3.002612551517077, 53.412655395397977],
            [-3.003130886148262, 53.412561151954215],
            [-3.005164845006981, 53.419318104115931],
            [-3.005425308468326, 53.420727172533297],
            [-3.004846595733386, 53.42094508188157],
            [-3.004738783623706, 53.421414312133173],
            [-3.005134044535928, 53.421520650235379],
            [-3.004741900363176, 53.421801708519219],
            [-3.005674775264104, 53.422307122294143],
            [-3.005933176848634, 53.425602980766968],
            [-3.006499287788393, 53.425677312642684],
            [-3.006060814680836, 53.426100790378328],
            [-3.00656812871327, 53.425852016260414],
            [-3.006350692373109, 53.426267339283406],
            [-3.00693584615936, 53.426382857647127],
            [-3.006349402419155, 53.427295682308824],
            [-3.007155118003736, 53.433237751648797],
            [-3.008756199611254, 53.438364992529564],
            [-3.007547054360946, 53.43835182904958],
            [-3.005449182025692, 53.439144353451077],
            [-3.005091170943949, 53.438964892497879],
            [-2.999976926777208, 53.439975930202721],
            [-3.000215338172626, 53.44038382715226],
            [-2.993992436893048, 53.441509956369764],
            [-2.992695125801693, 53.440301854195233],
            [-2.991663050282615, 53.440718524224167],
            [-2.991057705433759, 53.440195002887215],
            [-2.990455241603822, 53.440571242011607],
            [-2.985195449322222, 53.441561278229059],
            [-2.979830947244431, 53.442525873168996],
            [-2.97908735245577, 53.443317599146653],
            [-2.977608224647858, 53.442829935029373],
            [-2.975307893110693, 53.443230770121531],
            [-2.974915196614642, 53.443307685198732],
            [-2.976263993041339, 53.445268824575166],
            [-2.974973049923481, 53.446491069298531],
            [-2.974859528981177, 53.447385488963718],
            [-2.973378873676989, 53.447295991498478],
            [-2.973731946379978, 53.449697633417365],
            [-2.973012397189495, 53.44990484803639],
            [-2.971585690737209, 53.452248171465143],
            [-2.971649308686975, 53.45622162445229],
            [-2.972566246682013, 53.45838586698251],
            [-2.973407499254072, 53.460134535454053],
            [-2.973824112572467, 53.462740600172495],
            [-2.969552726793866, 53.465729975783631],
            [-2.969842703924133, 53.465961331456334],
            [-2.967283331579355, 53.467747489066724],
            [-2.961919243909679, 53.468294177286396],
            [-2.960220767433545, 53.467995929866973],
            [-2.960137485953471, 53.467643338602365],
            [-2.959628385796801, 53.467646530524611],
            [-2.958609819139351, 53.468772917679772],
            [-2.958078748009317, 53.468737627337902],
            [-2.956307573210448, 53.472977460597612],
            [-2.955628743965554, 53.472738397045227],
            [-2.955628459689098, 53.47239053234982],
            [-2.955119039737831, 53.472382021953862],
            [-2.954845979827954, 53.472634093697366],
            [-2.947880608013878, 53.473023946791535],
            [-2.947159797740058, 53.472115502460127],
            [-2.946125157281876, 53.472945277742937],
            [-2.93982958308022, 53.473311376936635],
            [-2.939835899553496, 53.4735971710003],
            [-2.933397472644521, 53.473845402408188],
            [-2.933359154819214, 53.473129295025068],
            [-2.927186505595452, 53.473956689674615],
            [-2.928255248081408, 53.473428838249902],
            [-2.927624757861847, 53.473326769004018],
            [-2.922616177957533, 53.474967356235851],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000013",
        LAD13CDO: "00BZ",
        LAD13NM: "St. Helens",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.768872688401035, 53.513555514817213],
            [-2.759831623859413, 53.512521220936172],
            [-2.758701076697048, 53.514065456710149],
            [-2.756015810345866, 53.514605610648729],
            [-2.752828701463131, 53.516019862409919],
            [-2.748292910944169, 53.516055538346563],
            [-2.746984584540412, 53.516696520453678],
            [-2.741035291604152, 53.518443187553835],
            [-2.735923634694731, 53.519640592220917],
            [-2.731399780723032, 53.519691732049559],
            [-2.730520750560796, 53.520584281769423],
            [-2.728459506049127, 53.518244548981272],
            [-2.727384808628377, 53.517842113631431],
            [-2.726757046943595, 53.514825765652354],
            [-2.723505839530385, 53.511739932625282],
            [-2.723278955448315, 53.511079745204341],
            [-2.723777716356877, 53.510350446801439],
            [-2.723026624583703, 53.509987359644406],
            [-2.722028354712858, 53.510960568290479],
            [-2.721139118283547, 53.511075598377595],
            [-2.720547423089739, 53.511574438997862],
            [-2.719656249784537, 53.511309252099402],
            [-2.719182554209645, 53.511831644463058],
            [-2.718260586614279, 53.511617867479245],
            [-2.717516964657642, 53.510981447680642],
            [-2.715579950563776, 53.510664079236712],
            [-2.714255216684812, 53.512590169631572],
            [-2.710006564506735, 53.514626233354662],
            [-2.708498897894497, 53.513384868582719],
            [-2.707072083874612, 53.506669853314669],
            [-2.706262775137423, 53.505604097426293],
            [-2.705752194645072, 53.505818345956797],
            [-2.705547592310864, 53.505573266615542],
            [-2.705994732724184, 53.505352202885213],
            [-2.705460418604551, 53.504772897325189],
            [-2.703822130140067, 53.505081877997675],
            [-2.703147734114675, 53.503962273621937],
            [-2.700718826050536, 53.504600349664592],
            [-2.699029255214386, 53.502986006013316],
            [-2.698892683259439, 53.502016036401855],
            [-2.696755169787135, 53.501849645979313],
            [-2.696544329015236, 53.502231092796457],
            [-2.694505601454244, 53.502020941019403],
            [-2.69295405076397, 53.502192642170471],
            [-2.691121092146479, 53.501841017218524],
            [-2.690274147682744, 53.501304799394397],
            [-2.688292616721482, 53.501456451682209],
            [-2.68590093987913, 53.500857182083386],
            [-2.685054515630505, 53.501095741039776],
            [-2.683272364184849, 53.500896510712316],
            [-2.682577965105698, 53.500276671164755],
            [-2.6819150709134, 53.5003973076916],
            [-2.681899389649202, 53.49998392199992],
            [-2.68018334031669, 53.499484049562618],
            [-2.680186401643403, 53.499204486949687],
            [-2.677760808272123, 53.498826367271498],
            [-2.67488729702092, 53.499002628573251],
            [-2.673841039425449, 53.499379762034543],
            [-2.673272918358111, 53.499107913715342],
            [-2.671603471285639, 53.499067871454166],
            [-2.669674646258872, 53.499500261731754],
            [-2.669615991332889, 53.499891593892833],
            [-2.668492440934078, 53.500151362979253],
            [-2.666321350284839, 53.499752711229434],
            [-2.664807163163037, 53.496726599157441],
            [-2.664880869738334, 53.495945979645754],
            [-2.663871991718891, 53.494664423473061],
            [-2.661373815957336, 53.493664364879542],
            [-2.662930715511147, 53.492784742279895],
            [-2.659919583700733, 53.492229737031806],
            [-2.656731482457436, 53.492507073464971],
            [-2.655915503731756, 53.490323732935373],
            [-2.65406375302302, 53.486445418852902],
            [-2.653026499737611, 53.486419632823747],
            [-2.652877443760724, 53.485447881296224],
            [-2.651912688592592, 53.485332702341353],
            [-2.648003802530504, 53.482190880511808],
            [-2.645532647046691, 53.480713949072147],
            [-2.64554046676998, 53.480236612100271],
            [-2.637413721553969, 53.480413281114743],
            [-2.633147697014796, 53.481010338114764],
            [-2.631094585951397, 53.48085760938114],
            [-2.618785591821897, 53.48182625237137],
            [-2.615918237468452, 53.481740397806746],
            [-2.613967627583439, 53.481170674977463],
            [-2.612139101785728, 53.481122531581484],
            [-2.612240511008389, 53.478433526004117],
            [-2.613188805482582, 53.478157206776835],
            [-2.612210207227966, 53.477689426224785],
            [-2.612171470180784, 53.477301317704608],
            [-2.609348186305286, 53.476693742205882],
            [-2.6091356778652, 53.476058433918432],
            [-2.612051711826202, 53.476727560488747],
            [-2.611872943783944, 53.474984689615269],
            [-2.607864358645754, 53.470811976296559],
            [-2.608305508451794, 53.470372884689034],
            [-2.608989918657385, 53.470300185088696],
            [-2.6106653362237, 53.470524436193884],
            [-2.611238104767441, 53.469916573896306],
            [-2.614549498834114, 53.47034541762325],
            [-2.614894296394086, 53.468665471173807],
            [-2.614487825068465, 53.468579474622977],
            [-2.615112841335516, 53.467840090942872],
            [-2.615888793535828, 53.468059009887618],
            [-2.616761485564136, 53.46622262890466],
            [-2.615157968826094, 53.465023728017762],
            [-2.61549129933717, 53.463804953018467],
            [-2.614844393917824, 53.463338180489949],
            [-2.613695621829319, 53.462641179127196],
            [-2.613383034910402, 53.461854483543107],
            [-2.610231174556418, 53.461580296432842],
            [-2.609117008829286, 53.461292955013874],
            [-2.609102180807268, 53.460993709861157],
            [-2.606824665002534, 53.46150686748058],
            [-2.603494855058398, 53.460893637363426],
            [-2.60085139538378, 53.46120179151125],
            [-2.5997225731319, 53.461044771181022],
            [-2.599225601663299, 53.460942098878178],
            [-2.599516593022873, 53.459574370085193],
            [-2.598817663891869, 53.458283516547972],
            [-2.596104988914679, 53.456437343061992],
            [-2.592931098323971, 53.455734053668749],
            [-2.588660788458828, 53.456104835197664],
            [-2.58835702303762, 53.455483421391158],
            [-2.586704919650319, 53.455150880666295],
            [-2.583978030039014, 53.453861796219975],
            [-2.583149781376211, 53.453312144964556],
            [-2.58473619487537, 53.452159238723048],
            [-2.58390954843062, 53.45172463904489],
            [-2.583319088768168, 53.448855661830073],
            [-2.58251800279021, 53.448192616690982],
            [-2.578931981924419, 53.447458606921302],
            [-2.576743094947111, 53.446040907135895],
            [-2.582314642576487, 53.441142931979151],
            [-2.584596921237921, 53.440376735367231],
            [-2.585296414821866, 53.439790845397475],
            [-2.591596871921571, 53.446531830688471],
            [-2.598685608928198, 53.450397566026538],
            [-2.596659112869379, 53.448162349849305],
            [-2.595890474563136, 53.445314096385907],
            [-2.596231060570808, 53.442649963750078],
            [-2.597051596508686, 53.442545191257651],
            [-2.598859482092192, 53.443385564213884],
            [-2.600676964178087, 53.443513961797478],
            [-2.602486111634723, 53.442414526158778],
            [-2.604126806883635, 53.442393652796106],
            [-2.606344251246008, 53.44280577867157],
            [-2.611187606620471, 53.442290307593943],
            [-2.612296241294597, 53.441598794760615],
            [-2.615944914355691, 53.440726108846036],
            [-2.617787795011797, 53.438770546400299],
            [-2.619575318358709, 53.438815216780618],
            [-2.619303569886014, 53.438244049442027],
            [-2.620934073042238, 53.43784547430247],
            [-2.620980233388988, 53.437088389666734],
            [-2.625433471110743, 53.436509648449963],
            [-2.626716974656906, 53.435145631336397],
            [-2.626323910984826, 53.434416016852254],
            [-2.627463072677716, 53.4343965530391],
            [-2.630043313543118, 53.43512902500688],
            [-2.632089780873359, 53.437923570022441],
            [-2.631410535349773, 53.440133878701666],
            [-2.632408481078057, 53.441644084707143],
            [-2.639540750499377, 53.44440606531203],
            [-2.641891981209893, 53.443875703693834],
            [-2.645657607855124, 53.444288656464643],
            [-2.646057538705177, 53.445343559270299],
            [-2.647983343992385, 53.446638277674722],
            [-2.649379135316432, 53.446345755555001],
            [-2.651096886393677, 53.447711666552749],
            [-2.652360822684251, 53.448147909423632],
            [-2.654743639396275, 53.448101611243047],
            [-2.657916308864777, 53.447429800581304],
            [-2.658787512502179, 53.447683871134416],
            [-2.659947178108374, 53.448964643916376],
            [-2.663874563131219, 53.449475004886253],
            [-2.665132750033814, 53.449146210159647],
            [-2.666062918415449, 53.449603043035886],
            [-2.666988874333577, 53.449405517892558],
            [-2.66787719580548, 53.449592910631921],
            [-2.668496196677418, 53.449984948315674],
            [-2.669507447196858, 53.449753668142655],
            [-2.670661816922562, 53.449909885861899],
            [-2.672517410269152, 53.450893596810488],
            [-2.673651004615527, 53.452398201530372],
            [-2.673510141933335, 53.452727081990993],
            [-2.674463065739654, 53.45289878085314],
            [-2.67716805152312, 53.4527369591152],
            [-2.678613628736525, 53.450561587651926],
            [-2.677504933088017, 53.449399356112821],
            [-2.677789189734364, 53.449000444198987],
            [-2.67735545557969, 53.448049207617544],
            [-2.678223667980916, 53.446312163764659],
            [-2.678477474675914, 53.444571414276389],
            [-2.678690762507741, 53.44425649763447],
            [-2.680278733825501, 53.444702289099574],
            [-2.681339011438724, 53.44256053531528],
            [-2.67982946123845, 53.442213186983018],
            [-2.682169186685436, 53.439218299119034],
            [-2.682067278039358, 53.438871019243379],
            [-2.678879746983232, 53.439680187262489],
            [-2.678845013978231, 53.439106906934057],
            [-2.670292579782717, 53.441284637036105],
            [-2.668772547326778, 53.441027993676791],
            [-2.668329123674716, 53.440400370275249],
            [-2.668846000657954, 53.439379059573668],
            [-2.671837212188811, 53.437852170142463],
            [-2.6716833566505, 53.435924064965846],
            [-2.674182347727957, 53.43527358299572],
            [-2.676046972369019, 53.43543921888579],
            [-2.675268404300645, 53.43294926400862],
            [-2.672725052685833, 53.432020707043115],
            [-2.670319321928504, 53.432274234743936],
            [-2.66644259358618, 53.431826726040057],
            [-2.664819144759395, 53.431285668748259],
            [-2.663852964080725, 53.429650609360785],
            [-2.663784502458723, 53.428444708125532],
            [-2.665674358072804, 53.427839135429458],
            [-2.666567479422992, 53.426342928722271],
            [-2.670063711856057, 53.424159779421664],
            [-2.672320154428422, 53.422008682467251],
            [-2.671799833844501, 53.418880845601578],
            [-2.667093815524986, 53.418630381977643],
            [-2.66685640617015, 53.416247903371087],
            [-2.670744906790877, 53.415239158985152],
            [-2.671876817701115, 53.415270546595728],
            [-2.672018424340526, 53.413469310192831],
            [-2.672136388893598, 53.411791351601046],
            [-2.673025304001044, 53.410064104757886],
            [-2.672996494195683, 53.40881123938469],
            [-2.67292727333843, 53.407857027203384],
            [-2.672355400458214, 53.407746092802533],
            [-2.671376938302105, 53.400157921662725],
            [-2.673108750687173, 53.399725697633734],
            [-2.673794231059148, 53.398371721715044],
            [-2.674815503321836, 53.397997413920024],
            [-2.67652527953586, 53.39381426155672],
            [-2.676033891090818, 53.389852108389036],
            [-2.676317005783245, 53.387603315816634],
            [-2.690632446708981, 53.385372120814836],
            [-2.701075608309462, 53.386830333362873],
            [-2.705041795873091, 53.386729665670011],
            [-2.705080950570527, 53.387915952278505],
            [-2.710343542858512, 53.389273511772437],
            [-2.710222071969729, 53.389925021735237],
            [-2.712803260875676, 53.390609859380994],
            [-2.716120133503641, 53.396864164721094],
            [-2.716589629681946, 53.399028537688707],
            [-2.715226864104526, 53.39901873364586],
            [-2.719614144580204, 53.39992090354994],
            [-2.728935944328053, 53.399654903067059],
            [-2.734448145940126, 53.400530777472831],
            [-2.740372639994638, 53.401403841386042],
            [-2.743863787338201, 53.402537193898176],
            [-2.745174885428848, 53.402079577790303],
            [-2.747422800024375, 53.402297434087686],
            [-2.754919623219234, 53.400962165626225],
            [-2.766389659549925, 53.400051319311444],
            [-2.772259777747126, 53.400131239322249],
            [-2.786776226016147, 53.401171726369235],
            [-2.78668676983119, 53.402192544612198],
            [-2.784124542434621, 53.407057957819461],
            [-2.785592947770267, 53.411315280706702],
            [-2.785674796372571, 53.414939018616515],
            [-2.778777923453169, 53.415677241791023],
            [-2.778250995097796, 53.416153487278557],
            [-2.782460306953487, 53.418665302206357],
            [-2.782119642427398, 53.419205961693244],
            [-2.782459923780581, 53.419299911908354],
            [-2.781384613760554, 53.420396393076665],
            [-2.781837510302279, 53.420558821504009],
            [-2.781621480359528, 53.420922483283093],
            [-2.780209279394851, 53.420553292952398],
            [-2.77620226793525, 53.426671093067256],
            [-2.781675513088827, 53.428541902556326],
            [-2.783572522865481, 53.42857890688051],
            [-2.785603757978609, 53.429201963910167],
            [-2.787075745913997, 53.430426426483862],
            [-2.787946005941619, 53.430362260002326],
            [-2.792641391056927, 53.43106376732915],
            [-2.793337552808263, 53.431025885672263],
            [-2.793790126749965, 53.432777483542083],
            [-2.793236994471121, 53.432818912060661],
            [-2.793962171280993, 53.433851394980223],
            [-2.797747212385248, 53.433740775062383],
            [-2.796809537979784, 53.435996921269165],
            [-2.799186794424302, 53.436098787619123],
            [-2.800472301661685, 53.437219165018838],
            [-2.80307957611813, 53.437540535407472],
            [-2.804802743771083, 53.438392754025109],
            [-2.805223394350608, 53.439223175948655],
            [-2.80515424604716, 53.440740943774799],
            [-2.803973368313692, 53.441426651495618],
            [-2.798952536216665, 53.441343493872829],
            [-2.796767998978536, 53.440614719639555],
            [-2.795070216715413, 53.443213894644899],
            [-2.800259579214834, 53.44912708320529],
            [-2.804188450527024, 53.451377539915441],
            [-2.805281283863289, 53.453664101634054],
            [-2.805107086215841, 53.455520550239108],
            [-2.807004183807503, 53.455815161929095],
            [-2.806273676619611, 53.463200737699474],
            [-2.804408134915738, 53.467220484841597],
            [-2.818712232307492, 53.466875215253303],
            [-2.818652923493123, 53.470133133799827],
            [-2.81675202811028, 53.477835987437594],
            [-2.822949612405606, 53.48267432331761],
            [-2.824965062427698, 53.485193439672955],
            [-2.824040326731826, 53.489576416956609],
            [-2.820387402852666, 53.494364659077334],
            [-2.819916315237515, 53.496510794019258],
            [-2.822591076026737, 53.505104459759472],
            [-2.821508264227404, 53.505091234381851],
            [-2.816741007052236, 53.512158435792109],
            [-2.815675868187544, 53.512838960253504],
            [-2.81045161594329, 53.513839882435995],
            [-2.810821829243925, 53.514431520931502],
            [-2.810476807326053, 53.516186646951255],
            [-2.808881705496133, 53.517241933317308],
            [-2.805613538092683, 53.518515225523203],
            [-2.800543947560972, 53.519369052520815],
            [-2.794592101013521, 53.521285594893989],
            [-2.793594521496817, 53.522141647697879],
            [-2.791574262630558, 53.525426003320625],
            [-2.788704159801265, 53.526994613282909],
            [-2.782477047850902, 53.531413890093262],
            [-2.774843663599823, 53.524003971768828],
            [-2.772520727827105, 53.520026266911941],
            [-2.770894714115353, 53.518193200757075],
            [-2.769901786105335, 53.515752000633256],
            [-2.770015177814404, 53.514104554927904],
            [-2.768872688401035, 53.513555514817213],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000014",
        LAD13CDO: "00CA",
        LAD13NM: "Sefton",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-2.950328963294587, 53.691549917584155],
              [-2.946787408545762, 53.688002352028654],
              [-2.949205928130146, 53.686800319728022],
              [-2.95237212116208, 53.682771703192813],
              [-2.952289637955864, 53.681733288673136],
              [-2.950147609926615, 53.680269899693087],
              [-2.947803078788006, 53.679220647972194],
              [-2.946194412829255, 53.679016272143102],
              [-2.944400990838646, 53.679278990320213],
              [-2.944445291750653, 53.679026064089228],
              [-2.946590696223792, 53.678964179464195],
              [-2.947575763176495, 53.679008520874888],
              [-2.950311716383817, 53.680229047911034],
              [-2.951870182063037, 53.680522277505482],
              [-2.953852499456675, 53.682217912760514],
              [-2.953447512826613, 53.684061982932839],
              [-2.950883691980878, 53.686598249915491],
              [-2.950752538822697, 53.688505750195688],
              [-2.951455490014523, 53.690064163840646],
              [-2.951407352871267, 53.690414198457361],
              [-2.950424143505931, 53.690800421393078],
              [-2.951410236825994, 53.690609225706446],
              [-2.952548367388784, 53.69138397376409],
              [-2.950328963294587, 53.691549917584155],
            ],
          ],
          [
            [
              [-2.955215690269436, 53.696487061792702],
              [-2.950485697904065, 53.691717657139407],
              [-2.952885556270967, 53.691561959677102],
              [-2.95407990621973, 53.692476466267863],
              [-2.954859380264131, 53.692589800331206],
              [-2.95732900610318, 53.691035747521624],
              [-2.958625100642467, 53.692158827550244],
              [-2.962285436980185, 53.693816609627135],
              [-2.962671590178409, 53.694743814727815],
              [-2.962235963403138, 53.695830428677304],
              [-2.96102152453847, 53.695708049859306],
              [-2.960801294391856, 53.695215450214405],
              [-2.960472311033807, 53.695866159113201],
              [-2.959745251499562, 53.695663455301357],
              [-2.958223650371719, 53.695953384336534],
              [-2.957734001439984, 53.696934349383802],
              [-2.956427669614998, 53.696500758496143],
              [-2.955215690269436, 53.696487061792702],
            ],
          ],
          [
            [
              [-2.958316536060534, 53.69797504966899],
              [-2.957024315555637, 53.697430793985028],
              [-2.956217984313666, 53.697473188911388],
              [-2.955463456620306, 53.696726875393665],
              [-2.956384020821068, 53.696713235256922],
              [-2.958179903679708, 53.697427845573927],
              [-2.958316536060534, 53.69797504966899],
            ],
          ],
          [
            [
              [-2.965069739179812, 53.695964025302722],
              [-2.964594537222072, 53.693463660313839],
              [-2.965005727216834, 53.693298554609285],
              [-2.965508800453028, 53.693574940576944],
              [-2.965537666158884, 53.693181910671001],
              [-2.966636494601186, 53.693020241509956],
              [-2.969769584413668, 53.693005696244732],
              [-2.971489943132901, 53.692147731944523],
              [-2.969258320975888, 53.692901074660739],
              [-2.966048265358628, 53.692794882820557],
              [-2.964127867644698, 53.693123159145316],
              [-2.962949793056094, 53.692865679930712],
              [-2.961324144511851, 53.692239659456291],
              [-2.962209491551479, 53.691615938196271],
              [-2.960433799664127, 53.691913330593216],
              [-2.957541201089425, 53.690243063699853],
              [-2.958154367165107, 53.689836373412135],
              [-2.957811711302783, 53.68895105154089],
              [-2.95830427617536, 53.687569174285116],
              [-2.957520836587427, 53.68887877331651],
              [-2.957887629250578, 53.689760307533859],
              [-2.956379652346171, 53.690442003380404],
              [-2.954791736227501, 53.691809240177328],
              [-2.953751875456645, 53.691632363914131],
              [-2.952056380390818, 53.690654425796957],
              [-2.951153472527194, 53.688489982297163],
              [-2.951147032192101, 53.686995247464345],
              [-2.95133575509241, 53.686298937782453],
              [-2.952283747211718, 53.685900402856262],
              [-2.953569512348725, 53.684368418043874],
              [-2.95413251264431, 53.684758528195715],
              [-2.953743182944224, 53.684214230395121],
              [-2.954086549530566, 53.682056052707878],
              [-2.952025732486647, 53.680303519051215],
              [-2.947070337024386, 53.678749156748765],
              [-2.944843652780276, 53.678764052399366],
              [-2.94510835869734, 53.678550733491946],
              [-2.94422139487341, 53.677747867714352],
              [-2.943749703235226, 53.676287359891518],
              [-2.94397339661769, 53.672954456724121],
              [-2.947439351880958, 53.667333526973302],
              [-2.944959367702876, 53.666147767797426],
              [-2.941986281890512, 53.663136675105299],
              [-2.940144774575731, 53.658594869811608],
              [-2.94137216309919, 53.657855355467547],
              [-2.942355278527566, 53.656274627502185],
              [-2.948161868690016, 53.653742545130029],
              [-2.949968211054456, 53.652318819878509],
              [-2.95877803896063, 53.642501387080586],
              [-2.957913191131393, 53.640783406801077],
              [-2.96035301990309, 53.638720752412972],
              [-2.96234002194711, 53.637306169586537],
              [-2.966512156866187, 53.631848838657326],
              [-2.967770174556472, 53.631047676132908],
              [-2.981824923665581, 53.62218907503469],
              [-2.992925277419429, 53.6272668319722],
              [-2.992507903198783, 53.627587592432832],
              [-2.993722706910057, 53.628255251977663],
              [-2.997335811599557, 53.626869710476207],
              [-3.000735217314419, 53.624961809391152],
              [-3.000399170639684, 53.624242832914732],
              [-3.00175391619147, 53.623476454708126],
              [-3.00176646077867, 53.622474117904645],
              [-3.005370407824857, 53.620416955499891],
              [-3.006837785750214, 53.61696017189194],
              [-3.008083852795872, 53.616659346960802],
              [-3.008937878475423, 53.615894406084678],
              [-3.011349456499479, 53.614899677245681],
              [-3.011888554969651, 53.613927940830557],
              [-3.011784726262875, 53.612936472151496],
              [-3.014101155739747, 53.611261151197581],
              [-3.020028037706243, 53.608946524754224],
              [-3.020631087583053, 53.607128368414919],
              [-3.021818535971597, 53.606231051094788],
              [-3.024686087146509, 53.605252827179768],
              [-3.026004521530007, 53.603841991137784],
              [-3.028080313348226, 53.603066425367274],
              [-3.030111948550804, 53.600728071856835],
              [-3.030243883968851, 53.599443351809988],
              [-3.030890361861073, 53.598749249321479],
              [-3.032150916902999, 53.598513662685065],
              [-3.031978759055538, 53.597402348454239],
              [-3.032375150128405, 53.596927022076542],
              [-3.031046819932129, 53.596301180281678],
              [-3.030988827275642, 53.595719213036396],
              [-3.031423721888048, 53.593289416101911],
              [-3.031972825668689, 53.592944008690914],
              [-3.032648162541598, 53.590774601259156],
              [-3.032282107350016, 53.590198888646469],
              [-3.032803459736466, 53.589460910356323],
              [-3.032374597853909, 53.589283040792452],
              [-3.03369665044401, 53.588050957333429],
              [-3.03598879608256, 53.587202368049553],
              [-3.035425542662021, 53.58677938275887],
              [-3.033026154072837, 53.586575411472793],
              [-3.033347857453577, 53.5850867995062],
              [-3.031557678991754, 53.584896410680919],
              [-3.031325815102154, 53.585486271478054],
              [-3.029550993602003, 53.585306506634687],
              [-3.029553518144399, 53.584664691415398],
              [-3.020711946186865, 53.583659129344277],
              [-3.020771260824458, 53.582798404593433],
              [-3.021583181536165, 53.573999617886471],
              [-3.022895362846409, 53.573616269219798],
              [-3.022622802905999, 53.569581766774554],
              [-3.032177398485636, 53.568981032998913],
              [-3.039756980104605, 53.56568661804458],
              [-3.040591380197305, 53.564986328205663],
              [-3.04098975133578, 53.562456124782805],
              [-3.039281616411032, 53.559170314316624],
              [-3.040165728904272, 53.556132518185109],
              [-3.038702053166634, 53.553138502834635],
              [-3.038811821376009, 53.551889010511431],
              [-3.040621597753087, 53.548812598985535],
              [-3.045727685368682, 53.543428716553095],
              [-3.04670113490424, 53.542941994947732],
              [-3.045069172820961, 53.542733349876357],
              [-3.044272040812015, 53.542229752587176],
              [-3.044087636748938, 53.541665968936485],
              [-3.044616302232628, 53.54082809081617],
              [-3.04388887692576, 53.54033466841485],
              [-3.042996304671118, 53.540365829585113],
              [-3.042139049195977, 53.541343194703877],
              [-3.041191028806059, 53.541698421624801],
              [-3.040590857537326, 53.541716231300164],
              [-3.039632473923298, 53.541218501100758],
              [-3.038708716161501, 53.540100236804996],
              [-3.039268990492331, 53.538701207962646],
              [-3.038686852955936, 53.538282895651996],
              [-3.036750876520759, 53.538529813205123],
              [-3.035489901160969, 53.539107034880892],
              [-3.034315189590768, 53.537963045071869],
              [-3.031869794997439, 53.537242603244096],
              [-3.031069737578106, 53.538222091658376],
              [-3.029354099072794, 53.538969461093544],
              [-3.02813838662859, 53.538990709028361],
              [-3.027074163648663, 53.537976934628361],
              [-3.02509327053434, 53.538989892189939],
              [-3.02392954137139, 53.537914021897841],
              [-3.019636947507112, 53.536482845290912],
              [-3.018557646084341, 53.53545833778923],
              [-3.01650202710203, 53.53574909303137],
              [-3.01558795438516, 53.534815738179248],
              [-3.014075431334864, 53.534597566968351],
              [-3.013852057475062, 53.532384629451542],
              [-3.012333934230704, 53.531864461387819],
              [-3.010803774972884, 53.532484151539201],
              [-3.010211272272265, 53.531931856608196],
              [-3.00914946413735, 53.531691836194781],
              [-3.009318130703554, 53.530537153440868],
              [-3.008203228691689, 53.529394201858075],
              [-3.005554763001319, 53.529040779810039],
              [-3.004670304171112, 53.529214508855091],
              [-3.001203703160926, 53.528263819018839],
              [-3.000890773103468, 53.527340596868868],
              [-2.999561559955402, 53.5265643034801],
              [-2.996608920832425, 53.525902215978888],
              [-2.99630633936207, 53.525157772058783],
              [-2.994980393915486, 53.524964771619473],
              [-2.994771508844376, 53.523968757309426],
              [-2.992923432986113, 53.523958055932638],
              [-2.991524793985064, 53.522975508827244],
              [-2.990325219463738, 53.522764330023698],
              [-2.989597388446768, 53.521630582803951],
              [-2.988167445654967, 53.521370053588697],
              [-2.985956446262238, 53.521574379579661],
              [-2.984486755220046, 53.521289864640828],
              [-2.983444589412687, 53.52070788235568],
              [-2.982533450602499, 53.519529757989083],
              [-2.980585482791961, 53.518777216022855],
              [-2.977112127229727, 53.516423639790894],
              [-2.975995324103325, 53.515275012132207],
              [-2.972944942571239, 53.515430219778239],
              [-2.972455052847851, 53.516033757017333],
              [-2.971184930056445, 53.516285882039114],
              [-2.968144894741303, 53.517890768880157],
              [-2.964144414346836, 53.518590071593344],
              [-2.964171392786889, 53.51964513305299],
              [-2.962999101034857, 53.522886936410934],
              [-2.963249642635379, 53.524821092420169],
              [-2.966824083294632, 53.527377395818512],
              [-2.970475474314249, 53.534136999277628],
              [-2.972820533258577, 53.536128717911097],
              [-2.972784139159665, 53.537107886717045],
              [-2.974365734084635, 53.539616344689613],
              [-2.976446087938522, 53.540642063528153],
              [-2.978379198131444, 53.5428689463251],
              [-2.978462178272666, 53.543980172384423],
              [-2.977283527692724, 53.546206431315824],
              [-2.97516559778065, 53.547234955299693],
              [-2.972549738099006, 53.547944797791736],
              [-2.970727268017591, 53.547713317792279],
              [-2.970201019863222, 53.547074896416547],
              [-2.965886434425824, 53.54788555595178],
              [-2.964406594196884, 53.547056158869495],
              [-2.963565138451058, 53.547020696428902],
              [-2.963201360235118, 53.546353968970188],
              [-2.960514924012515, 53.545953112322586],
              [-2.959804565673185, 53.545574100512951],
              [-2.95772425298231, 53.545612355955555],
              [-2.956521741766936, 53.546094788482243],
              [-2.956079570803108, 53.545895180547561],
              [-2.955014747977636, 53.546059195494649],
              [-2.950878723894366, 53.544273747875238],
              [-2.947850989512456, 53.544323857587969],
              [-2.945452477467136, 53.542812972692211],
              [-2.944700295779525, 53.541225222185325],
              [-2.943900302845187, 53.5409448014993],
              [-2.943643416394067, 53.541200310784738],
              [-2.941988071268367, 53.540999434192123],
              [-2.941425470566958, 53.540331511064259],
              [-2.941542528176994, 53.539690593733646],
              [-2.9412374486291, 53.539882657650253],
              [-2.939625916718562, 53.539000060110965],
              [-2.939103696494544, 53.539130904162668],
              [-2.938718695349289, 53.538507415465688],
              [-2.937893257374754, 53.538846476015635],
              [-2.937629986810809, 53.538541127629628],
              [-2.936747805874227, 53.538702649026852],
              [-2.936056100923202, 53.538401552398561],
              [-2.936385806473651, 53.537068642761263],
              [-2.935379529940133, 53.53714393575477],
              [-2.934750437507452, 53.536669758652991],
              [-2.935289889693497, 53.536568461398403],
              [-2.935222224077853, 53.536031466437223],
              [-2.934605479927574, 53.535843032661347],
              [-2.934551813481528, 53.535050648695176],
              [-2.933585894021113, 53.534420897011451],
              [-2.933788216112347, 53.533399098908021],
              [-2.933068398048444, 53.532432141682754],
              [-2.932663075804823, 53.532455978743307],
              [-2.931234592189107, 53.531231168414642],
              [-2.930493138626165, 53.531268402670754],
              [-2.929193648004316, 53.530555819005038],
              [-2.928098447384021, 53.530490619832044],
              [-2.926938035996867, 53.529995357397617],
              [-2.925874974589601, 53.528774840154931],
              [-2.925616707928673, 53.52689370627369],
              [-2.923010947947254, 53.525204203693484],
              [-2.922458121097763, 53.525517682861398],
              [-2.921815584671606, 53.525451628985302],
              [-2.919060162591971, 53.524362741618617],
              [-2.916987881363278, 53.523957985917576],
              [-2.917139040851865, 53.523484020221282],
              [-2.916744657929109, 53.523242552835057],
              [-2.915956914294447, 53.523994654554443],
              [-2.914443810632892, 53.523287143574166],
              [-2.914351821444368, 53.523568294885223],
              [-2.913451586152428, 53.523371134387773],
              [-2.913278768233819, 53.52361155448294],
              [-2.912630319063465, 53.523549092028205],
              [-2.912059472809209, 53.523166934974846],
              [-2.911396736024516, 53.523281652323682],
              [-2.911711526240931, 53.522908917073714],
              [-2.911401708604956, 53.522740494472309],
              [-2.910966080042232, 53.522964937456607],
              [-2.90875082777064, 53.52235708540146],
              [-2.907762753148748, 53.522423020095154],
              [-2.906006328306825, 53.521683992308546],
              [-2.901540327258833, 53.521621582014831],
              [-2.899772671281056, 53.52205377497323],
              [-2.898519507488768, 53.521567924845002],
              [-2.897853532890442, 53.522100565767808],
              [-2.897493021983149, 53.521671815938909],
              [-2.896674605223321, 53.521986270652548],
              [-2.896427792175165, 53.521512620036219],
              [-2.895979140008299, 53.52183777927349],
              [-2.895113395041065, 53.521339101261063],
              [-2.891663992154962, 53.522135214484827],
              [-2.890402736529322, 53.521906416483191],
              [-2.889623057639366, 53.522272668532395],
              [-2.889087469965809, 53.522194856585436],
              [-2.886303555569887, 53.521063182361566],
              [-2.88171773164064, 53.520528115479195],
              [-2.881208746787365, 53.51998625590376],
              [-2.884057726270974, 53.519544546725754],
              [-2.884581594574614, 53.519134382001262],
              [-2.88772080799358, 53.51454485035692],
              [-2.892390451639808, 53.511130322017799],
              [-2.8879955519441, 53.503812990021842],
              [-2.889648941734425, 53.50285058530887],
              [-2.892364045855754, 53.503199777788844],
              [-2.895381702227962, 53.502379007320172],
              [-2.897745780138422, 53.501384196331941],
              [-2.8986685534586, 53.49996423252464],
              [-2.897842950585445, 53.49806392911438],
              [-2.896379806435013, 53.496736491723667],
              [-2.896751200381681, 53.495406069364918],
              [-2.899322374115356, 53.493675290408362],
              [-2.899807878206165, 53.492400627642411],
              [-2.899441930082112, 53.49177506991505],
              [-2.90386781403394, 53.491658076800121],
              [-2.905250392811597, 53.491960418545368],
              [-2.907392285625163, 53.489774288464687],
              [-2.908907310660499, 53.488800977091998],
              [-2.911525063832695, 53.485348229240614],
              [-2.912326058904243, 53.483627958973671],
              [-2.914268809692939, 53.482518276878245],
              [-2.919560274836782, 53.482056993251469],
              [-2.917161670189051, 53.480121271959739],
              [-2.916844424392607, 53.479245504010159],
              [-2.917920266863978, 53.478336567900172],
              [-2.919081897921139, 53.478052580651685],
              [-2.918963045298137, 53.476875965270033],
              [-2.922616177957533, 53.474967356235851],
              [-2.927624757861847, 53.473326769004018],
              [-2.928255248081408, 53.473428838249902],
              [-2.927186505595452, 53.473956689674615],
              [-2.933359154819214, 53.473129295025068],
              [-2.933397472644521, 53.473845402408188],
              [-2.939835899553496, 53.4735971710003],
              [-2.93982958308022, 53.473311376936635],
              [-2.946125157281876, 53.472945277742937],
              [-2.947159797740058, 53.472115502460127],
              [-2.947880608013878, 53.473023946791535],
              [-2.954845979827954, 53.472634093697366],
              [-2.955119039737831, 53.472382021953862],
              [-2.955628459689098, 53.47239053234982],
              [-2.955628743965554, 53.472738397045227],
              [-2.956307573210448, 53.472977460597612],
              [-2.958078748009317, 53.468737627337902],
              [-2.958609819139351, 53.468772917679772],
              [-2.959628385796801, 53.467646530524611],
              [-2.960137485953471, 53.467643338602365],
              [-2.960220767433545, 53.467995929866973],
              [-2.961919243909679, 53.468294177286396],
              [-2.967283331579355, 53.467747489066724],
              [-2.969842703924133, 53.465961331456334],
              [-2.969552726793866, 53.465729975783631],
              [-2.973824112572467, 53.462740600172495],
              [-2.973407499254072, 53.460134535454053],
              [-2.972566246682013, 53.45838586698251],
              [-2.971649308686975, 53.45622162445229],
              [-2.971585690737209, 53.452248171465143],
              [-2.973012397189495, 53.44990484803639],
              [-2.973731946379978, 53.449697633417365],
              [-2.973378873676989, 53.447295991498478],
              [-2.974859528981177, 53.447385488963718],
              [-2.974973049923481, 53.446491069298531],
              [-2.976263993041339, 53.445268824575166],
              [-2.974915196614642, 53.443307685198732],
              [-2.975307893110693, 53.443230770121531],
              [-2.977608224647858, 53.442829935029373],
              [-2.97908735245577, 53.443317599146653],
              [-2.979830947244431, 53.442525873168996],
              [-2.985195449322222, 53.441561278229059],
              [-2.990455241603822, 53.440571242011607],
              [-2.991057705433759, 53.440195002887215],
              [-2.991663050282615, 53.440718524224167],
              [-2.992695125801693, 53.440301854195233],
              [-2.993992436893048, 53.441509956369764],
              [-3.000215338172626, 53.44038382715226],
              [-2.999976926777208, 53.439975930202721],
              [-3.005091170943949, 53.438964892497879],
              [-3.005449182025692, 53.439144353451077],
              [-3.007547054360946, 53.43835182904958],
              [-3.008756199611254, 53.438364992529564],
              [-3.008754163392844, 53.439168617545938],
              [-3.009597365834054, 53.439112053058949],
              [-3.010028590720098, 53.440663486561959],
              [-3.01715526653745, 53.449537066597443],
              [-3.017864805004261, 53.44936113167595],
              [-3.018160887942804, 53.449589622543705],
              [-3.018923392348099, 53.451437528447691],
              [-3.021271229799883, 53.454269651019189],
              [-3.023555882688195, 53.456215065612774],
              [-3.02395770463324, 53.456453422659052],
              [-3.025341904019339, 53.456209634912938],
              [-3.028115837985892, 53.455018133120838],
              [-3.036395015878375, 53.461417687661637],
              [-3.041479883057228, 53.465596442618946],
              [-3.040993669102231, 53.466410579536138],
              [-3.038084022959913, 53.467735692639828],
              [-3.037584743281383, 53.46845015200303],
              [-3.040263368137544, 53.474306482372491],
              [-3.043389115054993, 53.477843312856791],
              [-3.044482319049945, 53.478733546641678],
              [-3.046081274163472, 53.48113037255861],
              [-3.046817916563948, 53.481610218406068],
              [-3.046294935261181, 53.480811194499275],
              [-3.048671413482816, 53.482647459809257],
              [-3.048901567996988, 53.483363650175271],
              [-3.057672420373529, 53.493846451942687],
              [-3.061135540064277, 53.49912546797821],
              [-3.061751337436838, 53.503798696447468],
              [-3.062612097823817, 53.505178924241804],
              [-3.062883590932687, 53.505423703246798],
              [-3.063587702054389, 53.505362606872431],
              [-3.064692656718567, 53.50463096312459],
              [-3.065064296897308, 53.504780462026851],
              [-3.063436824457747, 53.506261935541104],
              [-3.062800315264859, 53.507639295777267],
              [-3.063274169982556, 53.509883190767091],
              [-3.064123997514131, 53.51051922816599],
              [-3.063436004565521, 53.510986479518323],
              [-3.065485604969175, 53.515624443379131],
              [-3.063932342682218, 53.517134022103569],
              [-3.063521023703635, 53.518655896349266],
              [-3.063844096472123, 53.5198719064676],
              [-3.065925843500467, 53.522647087375539],
              [-3.065162495520835, 53.52354468481186],
              [-3.063240090267895, 53.523879105830368],
              [-3.063598697663807, 53.525368059759508],
              [-3.06269498133349, 53.527350940917955],
              [-3.062228927785581, 53.529162733996856],
              [-3.063752009876478, 53.530762681958343],
              [-3.06409169810159, 53.530591567869926],
              [-3.062647848007205, 53.52926417978432],
              [-3.063357647936359, 53.526464143671063],
              [-3.063911967581408, 53.525468643307178],
              [-3.063706655952956, 53.523897426806592],
              [-3.064695141821092, 53.524036042837714],
              [-3.066124780155946, 53.52372127556248],
              [-3.066799576831019, 53.524282449800651],
              [-3.067370307382082, 53.523943869317456],
              [-3.068487258567318, 53.524031868276445],
              [-3.06749435352495, 53.523718040915355],
              [-3.06790615514668, 53.523538181510567],
              [-3.066515889052484, 53.523378008910107],
              [-3.068383780991655, 53.523276832888698],
              [-3.067829065603269, 53.522752347853697],
              [-3.068456862117553, 53.522704490201463],
              [-3.068983911089817, 53.522010334601823],
              [-3.070931006077923, 53.521523688119039],
              [-3.072331183492055, 53.521719675656009],
              [-3.073753666938488, 53.522677699529041],
              [-3.076121185798816, 53.523365627174378],
              [-3.07777655846138, 53.524400608333856],
              [-3.077318480511495, 53.524420018826632],
              [-3.07763659722536, 53.525126370189724],
              [-3.078808277536563, 53.525585018301733],
              [-3.079138882062631, 53.526128555100641],
              [-3.08083021044381, 53.528035086321161],
              [-3.082289089174191, 53.530818311505207],
              [-3.08540506042066, 53.533729406471494],
              [-3.094756790316189, 53.537247753517647],
              [-3.098520093273565, 53.539056841279383],
              [-3.099851937925988, 53.540314717605121],
              [-3.099997099286293, 53.539685961698915],
              [-3.101537781010988, 53.541893814763796],
              [-3.100778909190859, 53.540505738094765],
              [-3.101481990562283, 53.540791399378755],
              [-3.102436741395652, 53.542573174032086],
              [-3.102864366955271, 53.542710354663711],
              [-3.104770825754771, 53.54718896431141],
              [-3.10547179111095, 53.55149891730423],
              [-3.104454550209469, 53.558804536980574],
              [-3.103194610587381, 53.563528097915011],
              [-3.100521566492586, 53.569377465445577],
              [-3.097501886591666, 53.574408371809504],
              [-3.097041161840345, 53.574871026518665],
              [-3.096619177495582, 53.574788604317042],
              [-3.096919097906923, 53.574997989005006],
              [-3.093300782072305, 53.579690000295777],
              [-3.090755787125706, 53.582625596218563],
              [-3.088425266654971, 53.58439783656452],
              [-3.086089395626248, 53.587382661143664],
              [-3.08017018797763, 53.593471300951997],
              [-3.072397267572999, 53.600061657450119],
              [-3.067608070875929, 53.604899970799686],
              [-3.065415467303095, 53.606473664103717],
              [-3.062117826973387, 53.610154194283439],
              [-3.058794789108479, 53.614654621997246],
              [-3.049634794735175, 53.625405780110341],
              [-3.048955844362016, 53.626928120691289],
              [-3.049129621775864, 53.628325232514555],
              [-3.04847098776859, 53.628535047534967],
              [-3.044662813397127, 53.632368754057381],
              [-3.04442928844922, 53.63393481682872],
              [-3.044033322509628, 53.633762094449679],
              [-3.044270673170769, 53.634125861416358],
              [-3.042726070391167, 53.636313682069307],
              [-3.04058534137772, 53.637332750153938],
              [-3.038395503342293, 53.639435335734476],
              [-3.037501831910388, 53.641170700914287],
              [-3.036220018861844, 53.64200875944222],
              [-3.03582351838619, 53.641939383794323],
              [-3.036125992167609, 53.642183952832994],
              [-3.031153790962718, 53.647634447171797],
              [-3.028849324427417, 53.648922571195889],
              [-3.029547766662283, 53.647846021912713],
              [-3.029376511136717, 53.647460984534433],
              [-3.028571047268503, 53.648180705915792],
              [-3.027071671688256, 53.648735594491875],
              [-3.02649715527508, 53.650121173076251],
              [-3.026110856938581, 53.650225157716186],
              [-3.025932260685372, 53.6494123208956],
              [-3.024542698317399, 53.650443531000931],
              [-3.02233205197999, 53.651309159113453],
              [-3.02116716295934, 53.652627842871304],
              [-3.019840858844693, 53.652841393907103],
              [-3.017771605468626, 53.654183925030544],
              [-3.015556222895322, 53.657320882773739],
              [-3.013904344857709, 53.658918674257272],
              [-3.011174908335923, 53.66039161919219],
              [-3.010670387131897, 53.660240377409089],
              [-3.004316077178779, 53.666095079300455],
              [-3.001566976235616, 53.666644840155556],
              [-2.9999225768907, 53.670960509637851],
              [-2.999042633182745, 53.671962888488757],
              [-2.996688801679412, 53.672603615866862],
              [-2.995685368374628, 53.675691440941229],
              [-2.99417209331211, 53.676486918076897],
              [-2.993288331592109, 53.677980957549238],
              [-2.990088121265801, 53.679733262265962],
              [-2.987941851867257, 53.680080869818887],
              [-2.985964080539285, 53.680902544516897],
              [-2.984992318836729, 53.681756362953813],
              [-2.983822477566581, 53.681908898311072],
              [-2.983858431122579, 53.682411960270024],
              [-2.985205706705679, 53.682854802881302],
              [-2.985225093235816, 53.684139103412747],
              [-2.981968263307123, 53.68498920496156],
              [-2.982318135373187, 53.685383627756316],
              [-2.979682698464202, 53.687438421159342],
              [-2.978892398030303, 53.688750016325244],
              [-2.976490745199968, 53.689471627735571],
              [-2.976111662233808, 53.690366378326978],
              [-2.973847425007556, 53.691011312387744],
              [-2.975645045084682, 53.691471272894994],
              [-2.977195027912531, 53.692418606836895],
              [-2.977839589415504, 53.694353064326407],
              [-2.977286247934809, 53.694525666322981],
              [-2.977147667231795, 53.696519547552448],
              [-2.975402261237283, 53.697148589637699],
              [-2.9693494998952, 53.698318600407951],
              [-2.9671437497824, 53.698432610772386],
              [-2.965278129223493, 53.698186996537316],
              [-2.96445073421045, 53.697374811593477],
              [-2.965069739179812, 53.695964025302722],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000015",
        LAD13CDO: "00CB",
        LAD13NM: "Wirral",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-3.117162553704678, 53.321023838831927],
              [-3.115022512751289, 53.319787179283352],
              [-3.113660641126447, 53.318393997902803],
              [-3.111754587334942, 53.3173142032974],
              [-3.106348038271313, 53.313042488146991],
              [-3.103067848324367, 53.309064602526036],
              [-3.099585085377991, 53.307107462573654],
              [-3.099569841978771, 53.306572747487131],
              [-3.098622503957712, 53.306559005669008],
              [-3.095908086793995, 53.30470254825525],
              [-3.109013953035339, 53.297241714908381],
              [-3.109448873273005, 53.297002152261186],
              [-3.110717370403323, 53.296274803228769],
              [-3.110431568585611, 53.296593884770715],
              [-3.111201701232663, 53.296841104650824],
              [-3.111823182624028, 53.297892440798009],
              [-3.111034803450765, 53.298908376479567],
              [-3.11122409754719, 53.299552935487085],
              [-3.110557840439154, 53.299898933768709],
              [-3.112202795718083, 53.299970797729159],
              [-3.112299253272896, 53.300217999706589],
              [-3.111448850278612, 53.300522572338771],
              [-3.112133795865158, 53.301126552241143],
              [-3.114302849136555, 53.302191298528825],
              [-3.115250796356923, 53.302231877299803],
              [-3.116183414901037, 53.302662722918697],
              [-3.116401144861141, 53.303243184290231],
              [-3.114841976286354, 53.303819595159673],
              [-3.10916492534259, 53.301793335096001],
              [-3.107911873532824, 53.301675539339044],
              [-3.108269657820762, 53.302047962615205],
              [-3.106976615183654, 53.302124695047461],
              [-3.108308562883965, 53.30221749662428],
              [-3.108990156105082, 53.301882155662661],
              [-3.110098848174708, 53.302450743391226],
              [-3.111119697109229, 53.302524835240014],
              [-3.112585576146374, 53.303323790165891],
              [-3.111868938744125, 53.303696334032153],
              [-3.110616670179173, 53.303379898182563],
              [-3.110770522076322, 53.303930401142296],
              [-3.110955219192338, 53.303587990515332],
              [-3.111867421268116, 53.303868940541818],
              [-3.113300210831, 53.303624550755245],
              [-3.113948221820446, 53.30459382602043],
              [-3.112535094497428, 53.305133784163907],
              [-3.114781450635217, 53.30449165328784],
              [-3.115836228876883, 53.304828770988074],
              [-3.115587020060861, 53.305746201763441],
              [-3.114413358484548, 53.306146407082792],
              [-3.115090034660982, 53.306140081657666],
              [-3.114946690563487, 53.306513574163759],
              [-3.118525379008053, 53.308440603363834],
              [-3.118560722313914, 53.3086443263512],
              [-3.117580646576211, 53.30847103450175],
              [-3.120015265967707, 53.309856799392847],
              [-3.119733226875747, 53.310263062169845],
              [-3.118204151320685, 53.310103917886174],
              [-3.120071515795223, 53.311670421160017],
              [-3.119260572275927, 53.311988927257062],
              [-3.117481634582487, 53.311805150672797],
              [-3.122245165232748, 53.312987444007703],
              [-3.121751772457219, 53.313404689164912],
              [-3.120377949166091, 53.313332205915927],
              [-3.120336531201623, 53.313928577532693],
              [-3.119100408784246, 53.314587403780315],
              [-3.120558399510172, 53.317240802253728],
              [-3.123220653341576, 53.319383049541919],
              [-3.122863901841804, 53.322147881822822],
              [-3.122139088612999, 53.321702548028192],
              [-3.122242194210547, 53.320826032884696],
              [-3.121694804972312, 53.320962423961468],
              [-3.122340854569185, 53.32252585521362],
              [-3.117162553704678, 53.321023838831927],
            ],
          ],
          [
            [
              [-3.205073331077196, 53.371882933115444],
              [-3.203951922130203, 53.370919825955617],
              [-3.204342695311446, 53.370173377209625],
              [-3.205656088111468, 53.370696768549109],
              [-3.205357691238735, 53.371094407626579],
              [-3.206233983133977, 53.370988470202484],
              [-3.207249785297263, 53.371776436764904],
              [-3.206631718398579, 53.371712572260485],
              [-3.207107790346209, 53.372336100792367],
              [-3.206534750059508, 53.371844794614837],
              [-3.206150430470424, 53.37244645955829],
              [-3.205073331077196, 53.371882933115444],
            ],
          ],
          [
            [
              [-3.220865851239484, 53.379069269565406],
              [-3.21733706341484, 53.376260242382386],
              [-3.219414008837628, 53.377241331734872],
              [-3.219809035253452, 53.377282240666645],
              [-3.2191083530423, 53.37669791250655],
              [-3.220099454409081, 53.377239719526671],
              [-3.222209461171363, 53.379213726696435],
              [-3.221494719602173, 53.379138343811547],
              [-3.220865851239484, 53.379069269565406],
            ],
          ],
          [
            [
              [-3.224398578729664, 53.380008404150274],
              [-3.223741571143577, 53.379482983450025],
              [-3.226243837682045, 53.380500943170588],
              [-3.228278244493818, 53.3822356097225],
              [-3.228702844031264, 53.382883853526444],
              [-3.228440683614298, 53.383752209921369],
              [-3.229017418051412, 53.384566085228137],
              [-3.228740327087811, 53.384707371543172],
              [-3.226321927199422, 53.382276402302175],
              [-3.223927032738673, 53.380288309755748],
              [-3.224398578729664, 53.380008404150274],
            ],
          ],
          [
            [
              [-3.026721798608728, 53.421576971172655],
              [-3.025281711697647, 53.419826614479497],
              [-3.024257575086571, 53.419287977946894],
              [-3.023709244527055, 53.417822089480417],
              [-3.016855835810735, 53.411658508606713],
              [-3.014495610721709, 53.405854647208379],
              [-3.016228227509927, 53.404770224781807],
              [-3.015031413882884, 53.404603324486096],
              [-3.015134847125869, 53.403830293520414],
              [-3.013811663666719, 53.40340827034008],
              [-3.012811578443196, 53.401516493703298],
              [-3.011190400949272, 53.398672646888549],
              [-3.011536702098298, 53.39830835652311],
              [-3.013302915611231, 53.398204385780708],
              [-3.013330251393242, 53.397962350566523],
              [-3.010867181432519, 53.397853793259983],
              [-3.010262986906732, 53.397553283570929],
              [-3.009347852491646, 53.395934022753821],
              [-3.011169287449976, 53.395245333358922],
              [-3.009254829010497, 53.395816154974533],
              [-3.008683003942236, 53.395420080616297],
              [-3.008107930775896, 53.393931871563112],
              [-3.008849790095051, 53.393761105634844],
              [-3.008249964101865, 53.391797589622605],
              [-3.008733707364775, 53.391460011606704],
              [-3.008139987302675, 53.391343676642634],
              [-3.007834948347881, 53.388361913682374],
              [-3.008341464944013, 53.388353141143803],
              [-3.008379230179345, 53.387978880699762],
              [-3.007965622028556, 53.387910462543601],
              [-3.008283901651842, 53.387635408255257],
              [-3.007674952379049, 53.387572234721581],
              [-3.006540322154659, 53.385366924712343],
              [-3.006290834835111, 53.384861151168579],
              [-3.007064402520666, 53.384575069225342],
              [-3.006896963553005, 53.38429692464188],
              [-3.005933557812618, 53.382844339094376],
              [-3.005052400880607, 53.38290210093583],
              [-3.006726566087402, 53.382491576788794],
              [-3.005042516567209, 53.379291309498292],
              [-3.003392257395839, 53.377517286102275],
              [-3.001546071980801, 53.377893252230201],
              [-2.999265115119534, 53.375624675799422],
              [-2.999423057745869, 53.375372560458068],
              [-3.002520696017976, 53.374715551695211],
              [-3.001346280624736, 53.37482429059601],
              [-3.000913151879525, 53.374494432565974],
              [-2.999871310251137, 53.374744071001153],
              [-3.001651560909381, 53.374189802642803],
              [-3.001367142945342, 53.373727458687434],
              [-2.996574077171187, 53.369558935696936],
              [-2.995348619538953, 53.367988898339306],
              [-2.994663576658408, 53.367807639292536],
              [-2.989700863708978, 53.363135073499052],
              [-2.987042211947298, 53.361691888781962],
              [-2.987698549782054, 53.361482407451419],
              [-2.987745323280197, 53.361100886711291],
              [-2.987467914304186, 53.361451057033975],
              [-2.986491129561669, 53.361449248652605],
              [-2.98377736648164, 53.362170098620552],
              [-2.978752673720177, 53.35785894875859],
              [-2.978604184130738, 53.357214756691185],
              [-2.977944429486293, 53.357339719658555],
              [-2.973181853202434, 53.353008209310964],
              [-2.967815489010088, 53.347312383594094],
              [-2.968889090580684, 53.34649735571714],
              [-2.966784717612449, 53.345242482952173],
              [-2.965462609170313, 53.343503029957439],
              [-2.963630588774252, 53.341992407368529],
              [-2.96001098091836, 53.336385480476942],
              [-2.958264176816184, 53.332625037593296],
              [-2.95773370175825, 53.331401397876377],
              [-2.95259613917121, 53.326863530191986],
              [-2.951491851020232, 53.324874983231808],
              [-2.950921017857643, 53.325063810543199],
              [-2.949581295552874, 53.323284769047831],
              [-2.948849403158417, 53.322913054576425],
              [-2.948797004355358, 53.323190333406096],
              [-2.948226691038593, 53.323063628996579],
              [-2.94825390465795, 53.323342971328358],
              [-2.947610621279189, 53.322633456351021],
              [-2.947454303228321, 53.322896278813417],
              [-2.946940987863901, 53.322360114853133],
              [-2.946659977729587, 53.322483697385884],
              [-2.939519414289277, 53.31570310558984],
              [-2.932663535369177, 53.310517211894137],
              [-2.9285720624631, 53.308232666093723],
              [-2.931602739665276, 53.306052559412912],
              [-2.939551511846886, 53.310399322815023],
              [-2.946587530409784, 53.305510272769055],
              [-2.949382067908451, 53.303152701491477],
              [-2.951623236722734, 53.303860254525368],
              [-2.954685487122882, 53.304237589019429],
              [-2.95827656598326, 53.304102714736921],
              [-2.960026444740986, 53.30330479381535],
              [-2.961498967415861, 53.303790025251352],
              [-2.96532976026149, 53.302975229669158],
              [-2.967970216247345, 53.302018063401754],
              [-2.967239521011602, 53.301557459677824],
              [-2.968033626199104, 53.301239095044302],
              [-2.969073256338395, 53.301823929665112],
              [-2.97126831613102, 53.302009224152812],
              [-2.974134406712379, 53.303193977107405],
              [-2.979528056197957, 53.30425904698005],
              [-2.98016413348827, 53.304643047537176],
              [-2.984738547238289, 53.305309205987292],
              [-2.992738194777204, 53.307092832809516],
              [-2.995334437624571, 53.30560507201978],
              [-3.00218107438194, 53.302544473672008],
              [-3.005374446034029, 53.301397551432501],
              [-3.011238473053078, 53.300118257628171],
              [-3.01283751631574, 53.298954979384838],
              [-3.018553540109922, 53.29999127747606],
              [-3.021268215557675, 53.29899453738863],
              [-3.022001851086194, 53.29842463418391],
              [-3.023984075079766, 53.299369464358293],
              [-3.02614007428947, 53.297732888822026],
              [-3.029760567647982, 53.298976335587845],
              [-3.030509021870894, 53.299213475043125],
              [-3.031354953163119, 53.29851399885009],
              [-3.036597073806617, 53.300969319150667],
              [-3.036577331011335, 53.302384379108439],
              [-3.038474827882509, 53.305134722155792],
              [-3.03924577500996, 53.305426444912712],
              [-3.04165751789968, 53.305183371537581],
              [-3.042067278060812, 53.30586386395273],
              [-3.041621472583241, 53.306106869386454],
              [-3.042315628462625, 53.306509809328638],
              [-3.045861225563562, 53.307340823995027],
              [-3.048164652315214, 53.309595748209105],
              [-3.048391326162295, 53.310944820831999],
              [-3.051210467558391, 53.310656623446533],
              [-3.059659761094912, 53.308988074271582],
              [-3.064227843706483, 53.312165507215461],
              [-3.068272181761396, 53.314178871467895],
              [-3.07265028365223, 53.315838521875307],
              [-3.074166100832532, 53.316365126275855],
              [-3.076391633748861, 53.314432172679709],
              [-3.078140958162535, 53.315051903861388],
              [-3.080340519973589, 53.313289004791322],
              [-3.08097647402709, 53.311904307260086],
              [-3.082080833310038, 53.311178755990923],
              [-3.089133341014436, 53.30833511020834],
              [-3.095523173213636, 53.304908341278022],
              [-3.101695809293, 53.308996382822919],
              [-3.106159151251039, 53.313632131728177],
              [-3.115612462666883, 53.321166892778059],
              [-3.123487215103228, 53.325014949867601],
              [-3.130877909791141, 53.329979939435582],
              [-3.133027098309691, 53.330675081959704],
              [-3.137884717589031, 53.333716621381029],
              [-3.148088322483186, 53.341798995618305],
              [-3.154438393544498, 53.345340572721305],
              [-3.156278762537862, 53.346015814562207],
              [-3.157481508223089, 53.34599785913678],
              [-3.162356966013247, 53.348240897488353],
              [-3.168543325531362, 53.353407666376825],
              [-3.16951795752207, 53.353830495668809],
              [-3.174669409768819, 53.358677186592331],
              [-3.180044604082333, 53.362441845831654],
              [-3.181517569199334, 53.363863737961147],
              [-3.186277613269677, 53.364429571795768],
              [-3.19057183241023, 53.36822607622608],
              [-3.193106847884432, 53.370252983345814],
              [-3.194084186292909, 53.372308018235579],
              [-3.196229097545181, 53.373219611796927],
              [-3.198519705144361, 53.375729774497124],
              [-3.19960417351473, 53.381902518694162],
              [-3.199682493797858, 53.38329774650748],
              [-3.199074537117246, 53.383652634984813],
              [-3.199634544943319, 53.38597699440006],
              [-3.200363794872543, 53.38672564954291],
              [-3.19997458237161, 53.387268013019849],
              [-3.200368981770506, 53.387497764891961],
              [-3.197225612541233, 53.387309102737397],
              [-3.193098139795333, 53.389189610389074],
              [-3.179906641993622, 53.397518817507105],
              [-3.178759078689428, 53.399041224255065],
              [-3.17760740265933, 53.399709694902377],
              [-3.175842590167266, 53.400071374564952],
              [-3.170478875789065, 53.402310236325647],
              [-3.154949236907246, 53.407387509809233],
              [-3.152835989087305, 53.40773334006397],
              [-3.140959479604556, 53.411843972321144],
              [-3.124749084687776, 53.414878778239292],
              [-3.118878929031474, 53.416396450150977],
              [-3.106036713694335, 53.4206222904889],
              [-3.104183413343633, 53.420412921061235],
              [-3.10018450065174, 53.421666947004567],
              [-3.096373872459949, 53.423170801006172],
              [-3.096554480049833, 53.423405553119352],
              [-3.089872622205218, 53.426927491555645],
              [-3.075604062006408, 53.433140609362319],
              [-3.075374625000701, 53.434188092310769],
              [-3.0666920607321, 53.437363559582494],
              [-3.056715558792164, 53.440076128149627],
              [-3.052041883748179, 53.44083920332104],
              [-3.041334596214493, 53.441937110159081],
              [-3.041239533606826, 53.442913237150307],
              [-3.040321853170624, 53.442880784444156],
              [-3.040711273522587, 53.442366820793261],
              [-3.040397345567582, 53.442027078101866],
              [-3.038883876578062, 53.440591239117659],
              [-3.036536049546771, 53.439507800529093],
              [-3.035135984368505, 53.437464177539361],
              [-3.032645626524723, 53.430802470331422],
              [-3.030096853950579, 53.427106661678827],
              [-3.029637535299493, 53.425662505549532],
              [-3.026721798608728, 53.421576971172655],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000016",
        LAD13CDO: "00CC",
        LAD13NM: "Barnsley",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.446807267553472, 53.612799444217636],
            [-1.444747229355057, 53.610594052861366],
            [-1.442437858305174, 53.609778863028389],
            [-1.439254466035415, 53.607062093682856],
            [-1.438433962633556, 53.607164311017527],
            [-1.438061659188269, 53.606747301557547],
            [-1.436619139963448, 53.606573339058372],
            [-1.435568643697275, 53.608056865879455],
            [-1.434250203964181, 53.608095585344522],
            [-1.433892268808369, 53.608641285338514],
            [-1.433440188205853, 53.60854117381686],
            [-1.432872664169332, 53.60893577306571],
            [-1.432007049084229, 53.608453488618494],
            [-1.43239197986808, 53.608150608216796],
            [-1.43190468251572, 53.608197733216969],
            [-1.429196703589125, 53.606596608518437],
            [-1.429445087005851, 53.605547950040801],
            [-1.428725174279943, 53.605027685553537],
            [-1.42492475500285, 53.604439628779048],
            [-1.423228285905616, 53.603502968497089],
            [-1.421565364886479, 53.603213609635233],
            [-1.418798670498814, 53.603299982076102],
            [-1.418969552159122, 53.603732253956458],
            [-1.41756473675315, 53.603247246114314],
            [-1.415165917926346, 53.603176229175673],
            [-1.412807213484464, 53.60130228988546],
            [-1.407220941785348, 53.601380824309707],
            [-1.406867419487261, 53.601050997027762],
            [-1.405983065918067, 53.601075375345076],
            [-1.405301713041382, 53.600417637604949],
            [-1.401869488915122, 53.600238748214863],
            [-1.401428090465876, 53.599394329193181],
            [-1.400208816252345, 53.598635897769654],
            [-1.396103242893744, 53.599806221746285],
            [-1.395635929278799, 53.600362942148237],
            [-1.394213014426574, 53.600641581812575],
            [-1.392766489147925, 53.601406357330951],
            [-1.383965535754048, 53.603799049872052],
            [-1.381596475937537, 53.605417332552577],
            [-1.378946401257346, 53.60660446547881],
            [-1.376788632513749, 53.606473707436692],
            [-1.375935043203262, 53.60593984435684],
            [-1.369432461010725, 53.604175505046356],
            [-1.368433562718507, 53.603463750836447],
            [-1.366035486786198, 53.602842560859848],
            [-1.363856912727748, 53.601697565739258],
            [-1.360225370138652, 53.598611365474632],
            [-1.359902499743719, 53.596778697861339],
            [-1.356157263133519, 53.595354621333918],
            [-1.354634963156492, 53.592034187222758],
            [-1.356420801216791, 53.589145930309407],
            [-1.354612168229479, 53.586190680591919],
            [-1.353860394531103, 53.583383128784661],
            [-1.351432254507781, 53.581063560137302],
            [-1.348633440161755, 53.583324237535606],
            [-1.347618035481939, 53.583248597550494],
            [-1.347227498927226, 53.58232155665393],
            [-1.347463845091621, 53.579724283307904],
            [-1.346441144052255, 53.579539832963533],
            [-1.344897924302646, 53.578147173646649],
            [-1.344763061085293, 53.57778509930597],
            [-1.345628328612666, 53.576913458275435],
            [-1.344808782462232, 53.576390340397168],
            [-1.338412535454155, 53.568764390129552],
            [-1.337381127810165, 53.568093536423682],
            [-1.33719890381542, 53.567289856113888],
            [-1.335099968558642, 53.565275578858284],
            [-1.332594034583797, 53.563616736200771],
            [-1.332051115587422, 53.562811034946279],
            [-1.330730280966126, 53.561553355924133],
            [-1.328957555345169, 53.560809068179687],
            [-1.3277391510687, 53.55986113396478],
            [-1.326676344827798, 53.558624633046449],
            [-1.326723836447025, 53.558105365029633],
            [-1.322638942205136, 53.557342557433685],
            [-1.321568403328149, 53.557924340612757],
            [-1.320169957130989, 53.558147408158234],
            [-1.317447291961324, 53.557636641991721],
            [-1.315131335298831, 53.556155585608522],
            [-1.304171939469509, 53.554783627736441],
            [-1.301605113499852, 53.5531750086393],
            [-1.297387038420573, 53.551304070684338],
            [-1.293649819757243, 53.546953189522085],
            [-1.288510441434717, 53.54374259157396],
            [-1.287813084730186, 53.54105356410745],
            [-1.289372029701703, 53.540684416643586],
            [-1.287009392670775, 53.537693351497623],
            [-1.293857536911335, 53.535829296634383],
            [-1.289593598481027, 53.534027014039594],
            [-1.287819141675638, 53.532147741790155],
            [-1.286446795227726, 53.532922467116869],
            [-1.285318605793596, 53.532813262558037],
            [-1.284573739634514, 53.532339607196803],
            [-1.284984717676904, 53.53140725400921],
            [-1.283776264176645, 53.530513752190622],
            [-1.282390758160953, 53.530637580363717],
            [-1.281310191472963, 53.531462535351658],
            [-1.280312167248984, 53.531420580740189],
            [-1.279752680602609, 53.531140365511128],
            [-1.279892941754487, 53.530876047260548],
            [-1.276165073469157, 53.53034569343766],
            [-1.275787145749759, 53.529771732903711],
            [-1.275918531184406, 53.528082676874618],
            [-1.281395352032076, 53.528926473564319],
            [-1.283133355393414, 53.527220984286707],
            [-1.285317901637653, 53.527835391694289],
            [-1.285994348827154, 53.526901024913606],
            [-1.286138084889051, 53.526696044591198],
            [-1.279310460420328, 53.522617434232608],
            [-1.276530551237295, 53.520581816466198],
            [-1.278549316520259, 53.51929785671711],
            [-1.2811857078855, 53.516534459816434],
            [-1.282778863355777, 53.516500878959882],
            [-1.283159223266934, 53.51567800705395],
            [-1.286342137441378, 53.514551895994344],
            [-1.288787940283599, 53.515739475503345],
            [-1.29371357157012, 53.515185305496374],
            [-1.295369377771999, 53.515280463672816],
            [-1.296553869609688, 53.514968341644789],
            [-1.299350082967624, 53.515136662162959],
            [-1.303728576970604, 53.513815743273568],
            [-1.307645186795472, 53.513800728292686],
            [-1.310340197917946, 53.512769130068691],
            [-1.31137965717024, 53.512834444110858],
            [-1.311517635882151, 53.513718815353933],
            [-1.312567243633241, 53.513717661971974],
            [-1.315277965831818, 53.512826264790384],
            [-1.31845189869438, 53.513039445784663],
            [-1.319942556223734, 53.513840722928187],
            [-1.320709156151091, 53.513902606222366],
            [-1.322557266683398, 53.513097825361378],
            [-1.324544698646624, 53.513105470638259],
            [-1.326037731665912, 53.51233817996593],
            [-1.330327765182407, 53.5146453731751],
            [-1.334583404179446, 53.514363514174484],
            [-1.341177737189268, 53.514776640582205],
            [-1.34358300768419, 53.51429459603613],
            [-1.345037466821398, 53.513955611392227],
            [-1.350276316368128, 53.513959915997958],
            [-1.359983360282617, 53.512970480510646],
            [-1.366521467972441, 53.514829962587889],
            [-1.368933179784716, 53.515214837136327],
            [-1.369359193693133, 53.514352386366035],
            [-1.370706535848603, 53.514715429056452],
            [-1.379248225389125, 53.514403219708882],
            [-1.38086963851667, 53.513867817207924],
            [-1.389647412877911, 53.509385406186297],
            [-1.396993564346984, 53.505678942812374],
            [-1.396401480739643, 53.505222034698228],
            [-1.405605927705208, 53.504804325859403],
            [-1.405486554946963, 53.504284194942393],
            [-1.407112688056666, 53.504007330647717],
            [-1.407569736092648, 53.503450508366598],
            [-1.408506833089697, 53.503281667868599],
            [-1.411957916023639, 53.499243955283362],
            [-1.409578230172419, 53.498435845253788],
            [-1.410703255354321, 53.496880982454663],
            [-1.411610748014118, 53.496225691419447],
            [-1.416266418407679, 53.49451911075689],
            [-1.416686490946189, 53.49356477880719],
            [-1.415546400959576, 53.49327697167864],
            [-1.41503444478163, 53.492471790715804],
            [-1.416949191848015, 53.492214181779268],
            [-1.419036906416078, 53.491098975880092],
            [-1.419710440045424, 53.491223591676935],
            [-1.41985885996439, 53.48984546767511],
            [-1.421997973277054, 53.489813580220968],
            [-1.422709301709583, 53.490261949759585],
            [-1.424019059483139, 53.489704683347277],
            [-1.425432872812582, 53.48869398067],
            [-1.425982699058027, 53.487374404883504],
            [-1.42786165842643, 53.485833782792568],
            [-1.432856772136375, 53.487920451693434],
            [-1.435577590754617, 53.488451065607677],
            [-1.440948470065575, 53.491127017714241],
            [-1.442418409833307, 53.490176600739247],
            [-1.443692573191159, 53.488402803146371],
            [-1.452162431572875, 53.48502176472708],
            [-1.450739243384227, 53.482031936128728],
            [-1.450331883201747, 53.477638255731712],
            [-1.451798821806214, 53.476166372346569],
            [-1.456718124929886, 53.473572255716064],
            [-1.455203245640875, 53.471747874885395],
            [-1.455982158269119, 53.471862879797307],
            [-1.456493420445605, 53.471938012392592],
            [-1.460238717893838, 53.475735567909943],
            [-1.461541901385157, 53.476218736171262],
            [-1.463352927757022, 53.477772909619965],
            [-1.465153129529863, 53.478280739173925],
            [-1.466858207809054, 53.478086111547611],
            [-1.474095136731819, 53.479123080195002],
            [-1.477977636812427, 53.48008478921728],
            [-1.479659443555923, 53.480931649483836],
            [-1.484565880801192, 53.484777521938703],
            [-1.489046109991782, 53.486412887118277],
            [-1.492517275704512, 53.486629011232814],
            [-1.494716883452481, 53.486306648838401],
            [-1.495282349882972, 53.485136033830443],
            [-1.494498125029054, 53.480999794002344],
            [-1.506428641764332, 53.470152827527308],
            [-1.507658968628523, 53.468238844986402],
            [-1.507372134782142, 53.466599949502019],
            [-1.511717127176529, 53.465751276887048],
            [-1.514813967109131, 53.463648877861999],
            [-1.514944990682118, 53.461799564847752],
            [-1.516330661475757, 53.460607004040334],
            [-1.515030049666488, 53.459621083411001],
            [-1.517362393773634, 53.458345157802725],
            [-1.516683370225283, 53.457928940787419],
            [-1.518132575394399, 53.457228289843201],
            [-1.514826592924419, 53.453816341965336],
            [-1.513130728391189, 53.451465227960753],
            [-1.518453800962577, 53.449330430568075],
            [-1.518790380899665, 53.447659910974274],
            [-1.520546282881853, 53.445761388230224],
            [-1.520793713814843, 53.444511170156026],
            [-1.523292424737126, 53.443077595178956],
            [-1.523865912911005, 53.441336095888261],
            [-1.524643253613135, 53.440478982266669],
            [-1.525486937557162, 53.440285484515812],
            [-1.526485685812604, 53.439006774051229],
            [-1.527817283565559, 53.438522163832431],
            [-1.531878753354059, 53.438655889676653],
            [-1.531896437623888, 53.438279337211178],
            [-1.532942190979076, 53.438321180134537],
            [-1.534001644507736, 53.438624635119531],
            [-1.535080197556254, 53.439938471645881],
            [-1.53742642830666, 53.440833848384045],
            [-1.540800214450035, 53.44096280948969],
            [-1.545905904622204, 53.441739152556124],
            [-1.548283952467302, 53.44491843205266],
            [-1.551141106823414, 53.446102202604663],
            [-1.551241743315805, 53.446553806470398],
            [-1.550410241771117, 53.447589760315488],
            [-1.547361198739682, 53.44986314727219],
            [-1.548743176423122, 53.451170814392562],
            [-1.552520843787239, 53.452783179784184],
            [-1.552551482833961, 53.453450245297759],
            [-1.551383072202435, 53.455437730121055],
            [-1.540417200557937, 53.456095387733711],
            [-1.540526451061304, 53.456996460864147],
            [-1.539313143308991, 53.457782784420772],
            [-1.540210042848106, 53.461055373020521],
            [-1.539899034413006, 53.461542254716768],
            [-1.539099234834738, 53.461683889782655],
            [-1.539447539342287, 53.462484313811728],
            [-1.541831560865336, 53.464330731807287],
            [-1.5441581148045, 53.467765156874698],
            [-1.544387746541823, 53.468829375889612],
            [-1.545487856953797, 53.469918478762892],
            [-1.548119886759486, 53.47131448610579],
            [-1.544485822571606, 53.472193258399258],
            [-1.543675067520066, 53.472636897022738],
            [-1.546566190406377, 53.474612787304721],
            [-1.549633232004003, 53.478499329710871],
            [-1.557366976706314, 53.480282763982139],
            [-1.557276155807567, 53.481046451355319],
            [-1.558105958418895, 53.482688125948677],
            [-1.559370090503943, 53.483577259549016],
            [-1.561072203486848, 53.483940362542398],
            [-1.560863693788509, 53.484285654569291],
            [-1.56225619936884, 53.484279074618556],
            [-1.564072178993448, 53.484859174436416],
            [-1.565087621111606, 53.484885342756641],
            [-1.565267013372972, 53.485469348842813],
            [-1.566332186459497, 53.485644896460222],
            [-1.566012977729424, 53.486060805016201],
            [-1.567326272105845, 53.486291174855197],
            [-1.569180112147781, 53.486421908606587],
            [-1.574835891425397, 53.485984633977026],
            [-1.576362476950314, 53.486139260279188],
            [-1.582346316499164, 53.48758048862603],
            [-1.587244417399387, 53.488193429829359],
            [-1.589508151174289, 53.489592638854411],
            [-1.592812069036658, 53.490512668507058],
            [-1.602172515731695, 53.49187536293892],
            [-1.602312720081394, 53.491549547327558],
            [-1.606617212610949, 53.492868010046351],
            [-1.609339538256186, 53.491697644021251],
            [-1.609650784399533, 53.492272125082273],
            [-1.611956296078917, 53.493326784296151],
            [-1.614483050729532, 53.493100355555733],
            [-1.614975916112365, 53.490604045371612],
            [-1.622298990876767, 53.492171613879151],
            [-1.624325804060996, 53.492220242498114],
            [-1.633832184282182, 53.492373762013756],
            [-1.64171426422203, 53.494083866784671],
            [-1.64805568838864, 53.494792107274684],
            [-1.655104543315187, 53.496768526475158],
            [-1.659510425187668, 53.498496144909438],
            [-1.669268245472973, 53.500629512522863],
            [-1.680315064185119, 53.500487861151967],
            [-1.682147166498344, 53.499419522151001],
            [-1.682570168005157, 53.499500642382316],
            [-1.684056912661105, 53.498844825810487],
            [-1.684462524387447, 53.499440931402901],
            [-1.688803737813765, 53.499081079205098],
            [-1.688986554843146, 53.499643332389233],
            [-1.690536427777915, 53.499828917841683],
            [-1.690689993306973, 53.499441913010827],
            [-1.693446483603714, 53.500169880664735],
            [-1.698463116541957, 53.502566363178595],
            [-1.701133689336312, 53.503124953654044],
            [-1.70297153085791, 53.502903021527459],
            [-1.703303031212558, 53.501847702670062],
            [-1.704409007655415, 53.501715613483327],
            [-1.705306940913712, 53.501147062612887],
            [-1.706101908659079, 53.501731468697578],
            [-1.707491559164323, 53.501342079304457],
            [-1.711181608471493, 53.501189251469313],
            [-1.711038039931583, 53.500797008976683],
            [-1.711903944370554, 53.499822053813894],
            [-1.712821867585958, 53.499621121756917],
            [-1.712491312297979, 53.498566882794911],
            [-1.714027344912411, 53.497905419291477],
            [-1.713695203874307, 53.496636355796198],
            [-1.715658017477361, 53.495481527289215],
            [-1.716050022498956, 53.494112619181003],
            [-1.717287100785506, 53.493949262442484],
            [-1.719536698301307, 53.492928076295968],
            [-1.719805021372126, 53.492465799923494],
            [-1.721567423824368, 53.492630807502806],
            [-1.721427107701375, 53.491961740359315],
            [-1.722836091322224, 53.491541656723413],
            [-1.730412574296251, 53.491350436003053],
            [-1.731813312872087, 53.491025505582527],
            [-1.733612947763262, 53.488129852089905],
            [-1.735409759928171, 53.486618386090498],
            [-1.736223315157615, 53.483740281353072],
            [-1.737701405826114, 53.481574615877506],
            [-1.736600405468893, 53.480760538870527],
            [-1.736404322490724, 53.479331840200281],
            [-1.738708112856394, 53.477135618831888],
            [-1.739694574340419, 53.477206081650031],
            [-1.741642525911738, 53.478235888161869],
            [-1.742296182424106, 53.47902738396138],
            [-1.743331062028409, 53.479322632553419],
            [-1.744799287557787, 53.478968032613835],
            [-1.74631202845314, 53.478977541793775],
            [-1.748987484679842, 53.479840684235654],
            [-1.751105690303079, 53.479901736841533],
            [-1.754143711482715, 53.480631589234953],
            [-1.757460964285332, 53.481033851888014],
            [-1.76051694410698, 53.480747882778914],
            [-1.761899008806257, 53.481233321182479],
            [-1.762900347176286, 53.481091494326805],
            [-1.763980560928301, 53.481398338651935],
            [-1.765992572286706, 53.481058931518326],
            [-1.771054321072335, 53.48241159873929],
            [-1.773703037113454, 53.482486747233672],
            [-1.780415663703467, 53.484353570707654],
            [-1.783648925825299, 53.484782815830101],
            [-1.785520689904253, 53.484762815220101],
            [-1.787261735147895, 53.484363240539082],
            [-1.790453631310101, 53.482494780779895],
            [-1.7919736208548, 53.481973406065187],
            [-1.801471587657896, 53.480975642183076],
            [-1.801857108453119, 53.486530241610765],
            [-1.800250821297075, 53.488090661320484],
            [-1.800296625576823, 53.492783600041541],
            [-1.799873599168578, 53.492995020472499],
            [-1.800263874043621, 53.493339030473273],
            [-1.799709789478345, 53.49353315351415],
            [-1.797953068556799, 53.493370206843707],
            [-1.797477438679579, 53.493862867872664],
            [-1.795847605374012, 53.494269971436026],
            [-1.795856471816619, 53.495249725416784],
            [-1.795001676457003, 53.495902622041463],
            [-1.795347562370056, 53.496697790955189],
            [-1.795082684308502, 53.498175034995178],
            [-1.795866342576203, 53.499476998975723],
            [-1.795660276138385, 53.500950748519493],
            [-1.796082218122069, 53.501301117632224],
            [-1.796289010372976, 53.503136904809345],
            [-1.796746362590981, 53.503023529373358],
            [-1.797103518043979, 53.503687480444476],
            [-1.799034789244636, 53.50464980226252],
            [-1.80395096281778, 53.505057040181114],
            [-1.807760790528821, 53.50608340357509],
            [-1.810399753609545, 53.506324007338257],
            [-1.800779402335119, 53.511362565820086],
            [-1.806871172467372, 53.512074542308362],
            [-1.809933177846951, 53.511877203555485],
            [-1.813601089441714, 53.512081613944424],
            [-1.819524676235043, 53.513411984093061],
            [-1.820794116597681, 53.518491434568162],
            [-1.822590077523388, 53.520002360552461],
            [-1.822229652954476, 53.521075039415791],
            [-1.804283451045887, 53.536997823943494],
            [-1.803750780548475, 53.536356081738596],
            [-1.799847237149564, 53.534489927507387],
            [-1.797034862802943, 53.533875782056207],
            [-1.794019648357078, 53.534351510995705],
            [-1.791614053904149, 53.535411571611732],
            [-1.788595874070387, 53.535539308760256],
            [-1.785443339795179, 53.534781374578834],
            [-1.776811066632549, 53.535491854935771],
            [-1.771783630250766, 53.533842898174647],
            [-1.765567000917944, 53.535613281242],
            [-1.755853319117342, 53.536690447713021],
            [-1.742500909804959, 53.541065876164424],
            [-1.741577314538623, 53.541082761622427],
            [-1.740155893580177, 53.542063911334289],
            [-1.733147076103726, 53.545100943238573],
            [-1.730162108900471, 53.546752605822327],
            [-1.729213991975786, 53.547518071314158],
            [-1.729557858401153, 53.548028488733969],
            [-1.728840345076324, 53.548127535413087],
            [-1.727462900209752, 53.55016925971595],
            [-1.724474823389905, 53.552210867166025],
            [-1.726396623738639, 53.55449562001094],
            [-1.725328605309634, 53.555324597196311],
            [-1.723217007756247, 53.559928959592632],
            [-1.704125396627159, 53.560233848275978],
            [-1.699035201186484, 53.553794486651753],
            [-1.670600919686174, 53.554137582392507],
            [-1.669533350163275, 53.553218725299381],
            [-1.668702303975782, 53.553717080298519],
            [-1.667399078931261, 53.553406963030532],
            [-1.661908692300597, 53.553676513319189],
            [-1.662248349894975, 53.554430697510334],
            [-1.661600469595405, 53.554660767654731],
            [-1.66174524124307, 53.555814384396683],
            [-1.66392833800629, 53.557485178421686],
            [-1.660866849242998, 53.559575327186302],
            [-1.660463347872193, 53.559251501232609],
            [-1.65927551338851, 53.559785630312369],
            [-1.658523491748607, 53.558874764095414],
            [-1.657509645185046, 53.559172977929641],
            [-1.658046951997975, 53.559749769144766],
            [-1.656352649428687, 53.560368709696519],
            [-1.656558072074602, 53.560541875748967],
            [-1.651710430615449, 53.560736400056228],
            [-1.650931794636199, 53.561610497395449],
            [-1.649691577920229, 53.561517889819655],
            [-1.648923825556531, 53.561982136647188],
            [-1.646759171872161, 53.562310135844548],
            [-1.637776333013794, 53.562300370095024],
            [-1.633593581241314, 53.562915927169691],
            [-1.633381633705354, 53.563157964074456],
            [-1.627985866209902, 53.563314792839591],
            [-1.627014304435887, 53.563575128685585],
            [-1.62227885695397, 53.5628142457889],
            [-1.619401521217997, 53.563122421399846],
            [-1.615695912569684, 53.5630269903494],
            [-1.616410196620538, 53.564511463251485],
            [-1.614451987693376, 53.565007622027593],
            [-1.613043957951011, 53.566426835984544],
            [-1.612126319786295, 53.567707404630518],
            [-1.612287704791645, 53.568552833515021],
            [-1.610733199793674, 53.570116257832986],
            [-1.609665698490372, 53.572076738342204],
            [-1.607160909246816, 53.571540029690311],
            [-1.606257456927886, 53.572888011877694],
            [-1.598324415319972, 53.576599016048974],
            [-1.596195963804243, 53.576970265540574],
            [-1.596384034658761, 53.577203698119128],
            [-1.600241053463064, 53.577385620384518],
            [-1.599531621292081, 53.579102721036477],
            [-1.598406622483454, 53.579240968580216],
            [-1.598045302805411, 53.579755687322532],
            [-1.599202307836232, 53.580706938176263],
            [-1.600792366058092, 53.581229080562743],
            [-1.602324868897997, 53.583060612316395],
            [-1.602483849187665, 53.584953186217192],
            [-1.600507448127679, 53.586300252058408],
            [-1.60174160487879, 53.587746991711299],
            [-1.599329120823212, 53.588864282701479],
            [-1.599568452723819, 53.589275850259504],
            [-1.598012399841427, 53.589438718092957],
            [-1.597734443244798, 53.590233252195695],
            [-1.591092342714332, 53.592364358008851],
            [-1.589631707963696, 53.593891871636522],
            [-1.589096952317981, 53.5935709519676],
            [-1.587994600388371, 53.593512335165123],
            [-1.586522486846027, 53.596974958515531],
            [-1.586440234624376, 53.599983965773546],
            [-1.585203338797752, 53.600223271170776],
            [-1.585836215363381, 53.602260417699576],
            [-1.586935155291253, 53.603776947398103],
            [-1.587621430523659, 53.603447643725545],
            [-1.587852722866065, 53.603594949556154],
            [-1.591123931172991, 53.606784431008272],
            [-1.589976826141868, 53.607097796423375],
            [-1.58822192949789, 53.606500344195382],
            [-1.586450298958973, 53.607157576829977],
            [-1.584540259343684, 53.605895298268436],
            [-1.583551289233742, 53.605647380996402],
            [-1.581893595978057, 53.605781822534773],
            [-1.577050416964638, 53.607548097005044],
            [-1.571926961646734, 53.608592311256736],
            [-1.566162477724009, 53.606678627019953],
            [-1.564652790846042, 53.606960774435422],
            [-1.563443981608459, 53.607669147576026],
            [-1.560467767002815, 53.607557591628137],
            [-1.559810992796716, 53.607202837041932],
            [-1.560038146070143, 53.60672279660362],
            [-1.558721838018615, 53.606862665260508],
            [-1.557596909980884, 53.605897662871193],
            [-1.557174973210332, 53.605202204645423],
            [-1.550473041097943, 53.60429637725656],
            [-1.549164555546503, 53.603137356411125],
            [-1.548112827041242, 53.603414726816169],
            [-1.547356604706233, 53.603189858826354],
            [-1.547650914542249, 53.602375729238346],
            [-1.546724894307701, 53.602345262456019],
            [-1.546389851211053, 53.601175510243962],
            [-1.544721585494569, 53.599486562083364],
            [-1.543012961969647, 53.599310173628155],
            [-1.542411673354871, 53.59846027679032],
            [-1.541520268340631, 53.5987301124269],
            [-1.54182141563945, 53.598269264432496],
            [-1.541072195841966, 53.597688444764529],
            [-1.53987021305709, 53.597477998662519],
            [-1.539780674067066, 53.596540170267119],
            [-1.539224061918419, 53.596454437924407],
            [-1.537871574857862, 53.595224113837986],
            [-1.536612594545748, 53.595259695503145],
            [-1.536992019413908, 53.594827924986099],
            [-1.536259616336165, 53.594516790135692],
            [-1.536289875734584, 53.593684586158467],
            [-1.535372461684489, 53.593851808177781],
            [-1.532138831027354, 53.593749332424878],
            [-1.53078738289773, 53.593133734640688],
            [-1.53026708521372, 53.593321348722931],
            [-1.531074693457044, 53.593793706501728],
            [-1.529884285016851, 53.594052397001434],
            [-1.528380297832569, 53.596963201885686],
            [-1.524410309440883, 53.597694430317276],
            [-1.518685048730784, 53.599695728434071],
            [-1.516318704199451, 53.597710553305745],
            [-1.515875015780822, 53.597927177063191],
            [-1.512880645413945, 53.596967659690591],
            [-1.510867138525894, 53.597175171380435],
            [-1.510404129797901, 53.596838912148677],
            [-1.509801002350757, 53.596991044054136],
            [-1.510395306998347, 53.59747165626068],
            [-1.508051505681497, 53.5982736974399],
            [-1.50721054174843, 53.597695883276494],
            [-1.501479494621787, 53.599862620456967],
            [-1.496560490873172, 53.597355858591577],
            [-1.495070815173938, 53.603174040459642],
            [-1.491535420388841, 53.602728537255985],
            [-1.492862964688147, 53.598755021936832],
            [-1.491967836344711, 53.596788168973234],
            [-1.489388387002481, 53.596179469698313],
            [-1.485104494681298, 53.596953896831813],
            [-1.484264712396406, 53.594941382211623],
            [-1.483828138045766, 53.594810967101466],
            [-1.482816370870749, 53.595138271561737],
            [-1.481256272983947, 53.59631708794381],
            [-1.479289652498068, 53.596879312238322],
            [-1.478512557481539, 53.598266430407584],
            [-1.474999446207769, 53.599971450404453],
            [-1.474098648624031, 53.601325638998837],
            [-1.473017231408626, 53.600795064816261],
            [-1.463819980356368, 53.604423281393053],
            [-1.460466128963427, 53.606115105726985],
            [-1.459095424846299, 53.606229365477184],
            [-1.456070913543517, 53.607435384907753],
            [-1.448290021751389, 53.611529039382624],
            [-1.447627532177349, 53.612722335684545],
            [-1.447064718600654, 53.61229908291962],
            [-1.446807267553472, 53.612799444217636],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000017",
        LAD13CDO: "00CE",
        LAD13NM: "Doncaster",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.992556592159486, 53.655592547297417],
            [-0.992184063388533, 53.654272585587819],
            [-0.990501009862133, 53.652900235220386],
            [-0.991418645682356, 53.650776772387381],
            [-0.990738319464571, 53.64936162968818],
            [-0.98917990203993, 53.647658629852209],
            [-0.989238679872289, 53.645136925291105],
            [-0.984560860194958, 53.63765747838049],
            [-0.984484190756189, 53.633417795875893],
            [-0.983783156074953, 53.631815468647986],
            [-0.983665723470734, 53.629653606250287],
            [-0.982518363053284, 53.627750855886767],
            [-0.98281820018628, 53.625783095420971],
            [-0.981888332794321, 53.624084429261508],
            [-0.983259066493424, 53.620776573913211],
            [-0.98683953077817, 53.617037092628181],
            [-0.991091008949998, 53.614228093109119],
            [-0.995616427627047, 53.612144828028164],
            [-1.017942619711231, 53.606984530873873],
            [-1.021101168768505, 53.605556940777255],
            [-1.028695420315635, 53.604313656133201],
            [-1.030124346254707, 53.603752667102739],
            [-1.033153001106674, 53.601763716422049],
            [-1.035128767040662, 53.601536966446417],
            [-1.040135896580386, 53.602112047297773],
            [-1.052192531796272, 53.601629256607914],
            [-1.064719593742655, 53.596254631164946],
            [-1.071592180418986, 53.594642547958472],
            [-1.072757556073692, 53.594760344069741],
            [-1.075640581485945, 53.595861277036825],
            [-1.080543569651545, 53.595519722811602],
            [-1.087173701444103, 53.594748014661789],
            [-1.091180944872594, 53.592131367312895],
            [-1.091063629546005, 53.590768703549898],
            [-1.088327795080212, 53.588231085895906],
            [-1.086338017579022, 53.587118403106466],
            [-1.085357368365148, 53.585169372424524],
            [-1.082970253078805, 53.583441470080146],
            [-1.081347967457785, 53.571872349724096],
            [-1.081545280199733, 53.570920170031783],
            [-1.083245758942597, 53.568959305390599],
            [-1.081669841517527, 53.570607422607551],
            [-1.082763422459328, 53.567174060409002],
            [-1.085154641652546, 53.565729915065234],
            [-1.088327154110203, 53.565439527205143],
            [-1.091445800292639, 53.563573836655657],
            [-1.091447620095722, 53.561656573090453],
            [-1.090476578092768, 53.560120226857698],
            [-1.09034701202917, 53.558488701118343],
            [-1.09294219573398, 53.553542174690556],
            [-1.092289161228593, 53.553939014620546],
            [-1.091731356617478, 53.555668692416411],
            [-1.090136766535584, 53.558085309790386],
            [-1.090097255145629, 53.560067905146916],
            [-1.091082556271926, 53.561713125437556],
            [-1.091190647160284, 53.56343257495466],
            [-1.087957984687707, 53.565334243293336],
            [-1.084881342156232, 53.565590298909157],
            [-1.082719124464672, 53.566709008019856],
            [-1.080948543002725, 53.571628387143143],
            [-1.082651906391004, 53.583493860843511],
            [-1.084849009373632, 53.58499650023829],
            [-1.086059614420855, 53.587208860079684],
            [-1.090352966219945, 53.590162865771426],
            [-1.091061143556041, 53.591518333945245],
            [-1.090854822910207, 53.592050689713247],
            [-1.087287795853974, 53.594361476176758],
            [-1.08622611246248, 53.594688650173147],
            [-1.080461664557658, 53.595255727555923],
            [-1.075737460368013, 53.595506975282674],
            [-1.071988064594771, 53.594296860055842],
            [-1.063538975751728, 53.596346971154624],
            [-1.052059119614061, 53.601378316644499],
            [-1.040627030213331, 53.601778911402732],
            [-1.035177517993811, 53.601320733495378],
            [-1.033116584442963, 53.601505448612876],
            [-1.029622505702116, 53.60369107258856],
            [-1.028117875471799, 53.604192116049646],
            [-1.020802768837803, 53.605324391115055],
            [-1.018045395263824, 53.606723804797618],
            [-0.995833041490709, 53.611806874570817],
            [-0.990867091171934, 53.61398261347459],
            [-0.98641724777774, 53.616931946339527],
            [-0.982811813037209, 53.620828504224342],
            [-0.981426109608706, 53.623877354445398],
            [-0.982410346298916, 53.626017828396733],
            [-0.982020519447523, 53.627765500139908],
            [-0.98303639009405, 53.629349840005901],
            [-0.983315547318459, 53.632206099565174],
            [-0.98394819968739, 53.633443810049009],
            [-0.984154068393222, 53.637969528345117],
            [-0.988496582885808, 53.644729767668913],
            [-0.98867015688038, 53.647844884352054],
            [-0.99073967012955, 53.650445665256917],
            [-0.989894600264093, 53.652887032348808],
            [-0.991596220147556, 53.654495048571206],
            [-0.991918655748749, 53.655694143488383],
            [-0.990274794990034, 53.65787261071155],
            [-0.989979379724998, 53.6589631311194],
            [-0.957791732944798, 53.658505792224929],
            [-0.947620894635693, 53.658642467596422],
            [-0.946840219279835, 53.659121889388409],
            [-0.939871492659419, 53.657560206394386],
            [-0.922168920287228, 53.652500690776435],
            [-0.865333161626515, 53.637688111132221],
            [-0.886991460647041, 53.609666818514498],
            [-0.887050411177116, 53.600812552702962],
            [-0.897922328415393, 53.583256542044573],
            [-0.896233861313169, 53.582895811028045],
            [-0.901113867657122, 53.568703400843354],
            [-0.890991354048371, 53.566538084366293],
            [-0.891856046001851, 53.565286765608846],
            [-0.893870009461708, 53.56581776397131],
            [-0.894305617120869, 53.565557519884322],
            [-0.900299074995032, 53.555637888934143],
            [-0.900500700460483, 53.549606452339169],
            [-0.901262158592806, 53.54788759622673],
            [-0.899829746605755, 53.54445688075046],
            [-0.895590014447671, 53.542039365835436],
            [-0.892264354468802, 53.537573520429177],
            [-0.897309785554628, 53.532943249890522],
            [-0.898071000279669, 53.532738129107365],
            [-0.900432696764564, 53.532602560645778],
            [-0.908190982254374, 53.533647127481522],
            [-0.925256709310374, 53.532580118293239],
            [-0.934325068294885, 53.530023946170978],
            [-0.943712110075872, 53.530622279598525],
            [-0.946539800034683, 53.527321362032751],
            [-0.946054837555885, 53.527167874985437],
            [-0.948469846513685, 53.520352243099069],
            [-0.947913394095183, 53.519495208927253],
            [-0.950005404423241, 53.513636704698321],
            [-0.93209067814997, 53.510651063397475],
            [-0.935770889265864, 53.502793464809805],
            [-0.935357044271985, 53.50270258856095],
            [-0.935561236081607, 53.502495865213191],
            [-0.953256108132435, 53.484402922279479],
            [-0.976867651229441, 53.475156463320261],
            [-0.981623242795326, 53.474697294743166],
            [-0.98597629170892, 53.471652923711886],
            [-0.983295492383538, 53.468021122224791],
            [-0.985545857168204, 53.461333649693493],
            [-0.993644408786845, 53.452938196220899],
            [-0.991176872545394, 53.451702993022145],
            [-0.994307142890975, 53.448915857945849],
            [-0.994820231026403, 53.447081948115979],
            [-0.997516780214951, 53.443634890800539],
            [-0.997130504904685, 53.442455902602021],
            [-0.998482058287122, 53.438824040419135],
            [-0.995917558620934, 53.437835311665218],
            [-0.99566848814796, 53.436906462754301],
            [-1.000375781952632, 53.434978268712612],
            [-1.002962197503883, 53.434705943111659],
            [-1.005094225616715, 53.433778982610043],
            [-1.008677324039433, 53.433880676974788],
            [-1.009891459179718, 53.432804886198817],
            [-1.012456990739458, 53.43277578154305],
            [-1.012813040011143, 53.432220513689622],
            [-1.012532189963906, 53.430699968418395],
            [-1.014295990334349, 53.427260100189358],
            [-1.01434585547577, 53.426275328099216],
            [-1.018174338229985, 53.426739218968699],
            [-1.022430102744682, 53.426294111011401],
            [-1.023168152935695, 53.424494278774077],
            [-1.027114600888122, 53.424995702358821],
            [-1.027824717371732, 53.425400589914595],
            [-1.030514644896499, 53.425175254508929],
            [-1.028839715380656, 53.429267775007133],
            [-1.030708167373908, 53.429900484781946],
            [-1.030948980917933, 53.430959530818434],
            [-1.03652512904651, 53.430509340980549],
            [-1.040263169398218, 53.429736720307289],
            [-1.044588548979175, 53.428068894969634],
            [-1.055766147765677, 53.42529390452146],
            [-1.057198800137231, 53.425363646626579],
            [-1.067856717229567, 53.427702583494977],
            [-1.067942669137258, 53.427350890806764],
            [-1.069357146869194, 53.427774505921739],
            [-1.073267465763287, 53.428037712783784],
            [-1.074669865389656, 53.427288125622503],
            [-1.080438971602, 53.426848169457067],
            [-1.08003455644193, 53.425155151861752],
            [-1.078979822106538, 53.423543413596995],
            [-1.079122519000878, 53.422789449618278],
            [-1.081199505565146, 53.422565438860666],
            [-1.083329201092454, 53.422968319665173],
            [-1.084146839087494, 53.423504932883858],
            [-1.084866113130414, 53.424838996925402],
            [-1.087045585828722, 53.425524441897743],
            [-1.092921649496864, 53.421526016697719],
            [-1.098736892138477, 53.418715170662892],
            [-1.105040789848494, 53.40964696795654],
            [-1.108226447934016, 53.405862213449886],
            [-1.111632685745604, 53.406047596825452],
            [-1.115956387222502, 53.40732912583745],
            [-1.116197403605057, 53.408910250775286],
            [-1.115704201952426, 53.409697619581017],
            [-1.116024908611763, 53.410119772604361],
            [-1.118028388980527, 53.410937285954908],
            [-1.11889312599539, 53.410223660769219],
            [-1.121000444618969, 53.410093566678377],
            [-1.122029395135535, 53.410315068275395],
            [-1.133354920377251, 53.408736634475559],
            [-1.13372816480641, 53.409913284178579],
            [-1.13422728009519, 53.409855778567909],
            [-1.136052792105026, 53.410860463984797],
            [-1.142950574503973, 53.412054429873976],
            [-1.145873635514938, 53.412283906327154],
            [-1.144783543219472, 53.416002873731692],
            [-1.143524821475821, 53.418069374840158],
            [-1.135619564929308, 53.424069105728023],
            [-1.136752374999981, 53.425127189683352],
            [-1.140819486747657, 53.42523470953428],
            [-1.145057557258057, 53.425973429654825],
            [-1.145845739776298, 53.426369181279128],
            [-1.15330764304671, 53.424836669806815],
            [-1.158150332060694, 53.424230888133643],
            [-1.160399846618041, 53.424321324670309],
            [-1.162839492791759, 53.427910591081123],
            [-1.164866320483585, 53.429851971670047],
            [-1.166656028354863, 53.430920649929647],
            [-1.168702772999261, 53.434249071039922],
            [-1.170118403341218, 53.435217115031307],
            [-1.174208739325895, 53.435422532183907],
            [-1.182248898169328, 53.433905691209375],
            [-1.185805581009422, 53.433755597271436],
            [-1.1859185836275, 53.435246704765383],
            [-1.193053397948896, 53.435651055686513],
            [-1.192919722607775, 53.436271276208515],
            [-1.195101711230834, 53.437104869811776],
            [-1.196417856695467, 53.435642268438308],
            [-1.195660913420805, 53.432919873149444],
            [-1.197343282617263, 53.43158377068643],
            [-1.19973584643855, 53.431855998620101],
            [-1.206988357873256, 53.431735363476164],
            [-1.209726783063492, 53.432272157611486],
            [-1.216394591944895, 53.435056740667271],
            [-1.220306340354731, 53.434585251471752],
            [-1.223242864785821, 53.43360482809134],
            [-1.225222070328759, 53.433694980826438],
            [-1.228579111894139, 53.433338274620489],
            [-1.232765145758342, 53.433500056955488],
            [-1.238057133769356, 53.432758190765455],
            [-1.240204563046436, 53.434556128471932],
            [-1.237336368637613, 53.441156258419035],
            [-1.250755846031499, 53.440742274440197],
            [-1.250692086768808, 53.44154276934426],
            [-1.251950301736719, 53.441477843384696],
            [-1.253732086594642, 53.44306470494422],
            [-1.253362859630299, 53.444288458931858],
            [-1.253808209658482, 53.445422919023464],
            [-1.251778048549365, 53.44871357571369],
            [-1.255310354071019, 53.450702365808269],
            [-1.255717997948775, 53.45158849422473],
            [-1.256949763566376, 53.452451882593103],
            [-1.258227740452858, 53.455333506341482],
            [-1.258027715092551, 53.455750238912877],
            [-1.256903669410787, 53.456042579140821],
            [-1.252142277061588, 53.45446772488225],
            [-1.253140418548605, 53.455858225239126],
            [-1.255491829080701, 53.457031537768863],
            [-1.256424458367312, 53.457181162499197],
            [-1.257159081569461, 53.459012231817347],
            [-1.258044554652115, 53.459018630530181],
            [-1.258801729754332, 53.459473661409746],
            [-1.258520443117727, 53.459799104910111],
            [-1.259320087781281, 53.464220201618097],
            [-1.259149585118079, 53.46518902320831],
            [-1.257721244016468, 53.46659138417067],
            [-1.257524348861959, 53.468638680021741],
            [-1.25528615328377, 53.471286302734427],
            [-1.255280044831544, 53.472151873469016],
            [-1.2546451697794, 53.47227285915946],
            [-1.25154013487689, 53.474414334648287],
            [-1.249938679325674, 53.476886966907209],
            [-1.254717951957991, 53.481191862875185],
            [-1.255306939745062, 53.480773066306774],
            [-1.256494140691662, 53.480957531909056],
            [-1.261125082592102, 53.483194753233938],
            [-1.263980566464428, 53.483728314563599],
            [-1.268134256995965, 53.485364577820661],
            [-1.269299864262076, 53.485413054912328],
            [-1.269732047296203, 53.485871419454114],
            [-1.273572066545921, 53.483926300417153],
            [-1.273073749642097, 53.483632938578729],
            [-1.27391892615888, 53.482675390914139],
            [-1.275292485065142, 53.482808666843276],
            [-1.276443334185612, 53.481772056088289],
            [-1.277545628794347, 53.481550410620045],
            [-1.280011037467632, 53.480296087109323],
            [-1.280179692511009, 53.48003553320374],
            [-1.279299942878194, 53.479754281800538],
            [-1.279362448404284, 53.47953353796953],
            [-1.280906443564338, 53.478403973713718],
            [-1.282099324493268, 53.47887945107383],
            [-1.281539541544362, 53.479638325194543],
            [-1.295037309208503, 53.476568160680621],
            [-1.297010463153197, 53.475147889460516],
            [-1.298670707671613, 53.475702352158706],
            [-1.298472007636333, 53.476513759148894],
            [-1.296692703946331, 53.477326664102833],
            [-1.296529086212112, 53.478109510507025],
            [-1.297088697763264, 53.479076383635864],
            [-1.295987716273487, 53.480503592425812],
            [-1.29852298628786, 53.481646567596442],
            [-1.298646909061724, 53.483087272792098],
            [-1.299287665594909, 53.483160242251252],
            [-1.300874091504512, 53.48254212328807],
            [-1.301329297663721, 53.483452635408867],
            [-1.299675672680877, 53.484580920657933],
            [-1.295466609990438, 53.485127868904101],
            [-1.293188450572056, 53.485735536977707],
            [-1.29278786684782, 53.486443269622384],
            [-1.293933555671167, 53.487694965149466],
            [-1.292243677887092, 53.488625183961553],
            [-1.292674465523986, 53.489727940377662],
            [-1.291013014815383, 53.49057381689844],
            [-1.289223867608448, 53.490398702953485],
            [-1.28906273450068, 53.491021555268965],
            [-1.290714394779828, 53.491127542543289],
            [-1.29156354527783, 53.491273697986919],
            [-1.291534590193639, 53.491654643841329],
            [-1.295866560275502, 53.492192583696202],
            [-1.299342630745399, 53.493088493872122],
            [-1.305934677100312, 53.496005090762438],
            [-1.309901511475989, 53.497809590231249],
            [-1.312956622038641, 53.501386665597508],
            [-1.313486730764153, 53.503008555086176],
            [-1.312051183140162, 53.507962004819895],
            [-1.311099715694576, 53.511777573106365],
            [-1.312611303229361, 53.513138152201115],
            [-1.312567243633241, 53.513717661971974],
            [-1.311517635882151, 53.513718815353933],
            [-1.31137965717024, 53.512834444110858],
            [-1.310340197917946, 53.512769130068691],
            [-1.307645186795472, 53.513800728292686],
            [-1.303728576970604, 53.513815743273568],
            [-1.299350082967624, 53.515136662162959],
            [-1.296553869609688, 53.514968341644789],
            [-1.295369377771999, 53.515280463672816],
            [-1.29371357157012, 53.515185305496374],
            [-1.288787940283599, 53.515739475503345],
            [-1.286342137441378, 53.514551895994344],
            [-1.283159223266934, 53.51567800705395],
            [-1.282778863355777, 53.516500878959882],
            [-1.2811857078855, 53.516534459816434],
            [-1.278549316520259, 53.51929785671711],
            [-1.276530551237295, 53.520581816466198],
            [-1.279310460420328, 53.522617434232608],
            [-1.286138084889051, 53.526696044591198],
            [-1.285994348827154, 53.526901024913606],
            [-1.285317901637653, 53.527835391694289],
            [-1.283133355393414, 53.527220984286707],
            [-1.281395352032076, 53.528926473564319],
            [-1.275918531184406, 53.528082676874618],
            [-1.275787145749759, 53.529771732903711],
            [-1.276165073469157, 53.53034569343766],
            [-1.279892941754487, 53.530876047260548],
            [-1.279752680602609, 53.531140365511128],
            [-1.280312167248984, 53.531420580740189],
            [-1.281310191472963, 53.531462535351658],
            [-1.282390758160953, 53.530637580363717],
            [-1.283776264176645, 53.530513752190622],
            [-1.284984717676904, 53.53140725400921],
            [-1.284573739634514, 53.532339607196803],
            [-1.285318605793596, 53.532813262558037],
            [-1.286446795227726, 53.532922467116869],
            [-1.287819141675638, 53.532147741790155],
            [-1.289593598481027, 53.534027014039594],
            [-1.293857536911335, 53.535829296634383],
            [-1.287009392670775, 53.537693351497623],
            [-1.289372029701703, 53.540684416643586],
            [-1.287813084730186, 53.54105356410745],
            [-1.288510441434717, 53.54374259157396],
            [-1.293649819757243, 53.546953189522085],
            [-1.297387038420573, 53.551304070684338],
            [-1.301605113499852, 53.5531750086393],
            [-1.304171939469509, 53.554783627736441],
            [-1.315131335298831, 53.556155585608522],
            [-1.317447291961324, 53.557636641991721],
            [-1.320169957130989, 53.558147408158234],
            [-1.321568403328149, 53.557924340612757],
            [-1.322638942205136, 53.557342557433685],
            [-1.326723836447025, 53.558105365029633],
            [-1.326676344827798, 53.558624633046449],
            [-1.3277391510687, 53.55986113396478],
            [-1.328957555345169, 53.560809068179687],
            [-1.330730280966126, 53.561553355924133],
            [-1.332051115587422, 53.562811034946279],
            [-1.332594034583797, 53.563616736200771],
            [-1.335099968558642, 53.565275578858284],
            [-1.33719890381542, 53.567289856113888],
            [-1.337381127810165, 53.568093536423682],
            [-1.338412535454155, 53.568764390129552],
            [-1.344808782462232, 53.576390340397168],
            [-1.345628328612666, 53.576913458275435],
            [-1.344763061085293, 53.57778509930597],
            [-1.344897924302646, 53.578147173646649],
            [-1.346441144052255, 53.579539832963533],
            [-1.347463845091621, 53.579724283307904],
            [-1.347227498927226, 53.58232155665393],
            [-1.347618035481939, 53.583248597550494],
            [-1.348633440161755, 53.583324237535606],
            [-1.347645814523155, 53.584191637358551],
            [-1.346362121470465, 53.583978798665179],
            [-1.342185362089988, 53.582820675327547],
            [-1.340277660500636, 53.581847512027522],
            [-1.336250162239663, 53.581019879249055],
            [-1.33500407206351, 53.580056590171388],
            [-1.33435811980136, 53.580025135249336],
            [-1.333670918647988, 53.579543125191563],
            [-1.328277220127692, 53.579077035006279],
            [-1.326231175258238, 53.579331593063024],
            [-1.324854191056101, 53.579871231223173],
            [-1.324188269862022, 53.579577145437653],
            [-1.322123475797551, 53.579584344562356],
            [-1.320818612650994, 53.579106849997331],
            [-1.318449432559573, 53.57747455805022],
            [-1.31492636593311, 53.576791992948678],
            [-1.311802359744016, 53.576652725595416],
            [-1.309783450874505, 53.575705398460777],
            [-1.307937123625792, 53.575385532332255],
            [-1.29858289932402, 53.577478424522241],
            [-1.296798237758718, 53.577993778374669],
            [-1.295915922021635, 53.578465879658737],
            [-1.296068145826482, 53.578758002017906],
            [-1.294743507815635, 53.578943456137893],
            [-1.294527621186036, 53.579300824382067],
            [-1.291067623280566, 53.579827778434122],
            [-1.288236881901695, 53.580743982532518],
            [-1.28599202960747, 53.582379998854726],
            [-1.282263298908638, 53.583056102110461],
            [-1.280110873588147, 53.584298866932357],
            [-1.279309661810955, 53.585401426740297],
            [-1.278339869693947, 53.585489962539334],
            [-1.277807748760321, 53.586044937963067],
            [-1.279474210610126, 53.587539878815761],
            [-1.276691161168498, 53.589953578685062],
            [-1.275416675066506, 53.589742730701623],
            [-1.274865057344839, 53.589930844855004],
            [-1.273862189771541, 53.58947444221581],
            [-1.272884507372114, 53.589752543141103],
            [-1.272891840892606, 53.590117520069967],
            [-1.271754869265512, 53.590169033164159],
            [-1.270537887268077, 53.589865901634084],
            [-1.26950607962925, 53.590039378739313],
            [-1.268294275542778, 53.589525026519482],
            [-1.267009215326243, 53.590103213248213],
            [-1.266059985114758, 53.589781900752293],
            [-1.265488666984517, 53.590668256360267],
            [-1.264223512201499, 53.59131394904955],
            [-1.262904670313673, 53.590788098080246],
            [-1.262777465654443, 53.5912394355611],
            [-1.260898256565567, 53.591486715077068],
            [-1.260557801430652, 53.591863027669646],
            [-1.258082596373246, 53.591977145544028],
            [-1.256969122898405, 53.592838526720556],
            [-1.258078322827527, 53.594895680407006],
            [-1.253005998437777, 53.597131928955037],
            [-1.253638163156254, 53.597528668895833],
            [-1.254956979830458, 53.596944543507625],
            [-1.257410614406021, 53.598077956792608],
            [-1.255497134257832, 53.598880427585598],
            [-1.256094326455505, 53.599034247863109],
            [-1.257892297664502, 53.598355991374888],
            [-1.258390245430973, 53.598741087637272],
            [-1.257657959029356, 53.599052943145011],
            [-1.257695689766423, 53.59983157923935],
            [-1.250260643426076, 53.60113621241976],
            [-1.249855103224089, 53.600655484713634],
            [-1.250997771261761, 53.600470286238028],
            [-1.250569866606348, 53.599801561724675],
            [-1.24721658818083, 53.600744985650451],
            [-1.248926703146517, 53.601965576783812],
            [-1.25053605825177, 53.603847063967272],
            [-1.252196988543642, 53.607095098141357],
            [-1.248440475194527, 53.607820314063261],
            [-1.244800305972453, 53.608116497367092],
            [-1.247094262200618, 53.612895732408035],
            [-1.248176994316992, 53.616275006768142],
            [-1.244127887621685, 53.617081843770279],
            [-1.236553612352522, 53.617029292254792],
            [-1.233331436228855, 53.616650961875578],
            [-1.231295339555204, 53.61689138139279],
            [-1.229060072337055, 53.616860829966242],
            [-1.227889095432338, 53.617325178854742],
            [-1.228389329075158, 53.61749918463007],
            [-1.229222951758226, 53.618874396445818],
            [-1.230037428569538, 53.619061203923785],
            [-1.23109428904346, 53.618888226383078],
            [-1.232841229507838, 53.621094414734756],
            [-1.227910037106011, 53.622144030099882],
            [-1.226663291199411, 53.621947226480387],
            [-1.221429937469398, 53.623463821336387],
            [-1.218841445583474, 53.62354763282908],
            [-1.21746548104004, 53.624206491137279],
            [-1.217361631056252, 53.629085648181849],
            [-1.218367118065039, 53.631382474270765],
            [-1.218467420591067, 53.633156550628946],
            [-1.218951732545489, 53.633299930308752],
            [-1.219001113131446, 53.634393247880389],
            [-1.219633347788906, 53.634471074848015],
            [-1.21938408484973, 53.636668923240052],
            [-1.21859742031015, 53.636598177111843],
            [-1.217678076085417, 53.640184855823883],
            [-1.216297593816535, 53.639996060989823],
            [-1.215643981810437, 53.641963845971588],
            [-1.210889330139511, 53.640937590773532],
            [-1.209824065934514, 53.643145319309838],
            [-1.208124535315091, 53.642704446290047],
            [-1.205319234684034, 53.642589687989222],
            [-1.198464739841632, 53.640234874828323],
            [-1.197624223422332, 53.639732184747054],
            [-1.197226144846885, 53.638950217816834],
            [-1.196111228867539, 53.638960715736246],
            [-1.194517048040476, 53.638087111701743],
            [-1.193692155386244, 53.636372854036587],
            [-1.190310398443223, 53.6358988153398],
            [-1.18856680886766, 53.635967010764887],
            [-1.17969591086099, 53.637873249973765],
            [-1.174448186373764, 53.638155379957382],
            [-1.17239108496296, 53.639398668359682],
            [-1.170369890278306, 53.639345133714073],
            [-1.167770249424071, 53.640785925044014],
            [-1.165399147023939, 53.640831444272756],
            [-1.163129928371776, 53.641842990830753],
            [-1.161380045114543, 53.641756145340288],
            [-1.158789289862418, 53.643411629298427],
            [-1.155544283652066, 53.6432009324406],
            [-1.153293772700258, 53.642655620660939],
            [-1.152294884390165, 53.642758213614869],
            [-1.149656183586659, 53.638803448598154],
            [-1.145739757914597, 53.63853108744253],
            [-1.1448378777154, 53.637206038426818],
            [-1.143512579889851, 53.636329176949104],
            [-1.140121435587425, 53.635600175707424],
            [-1.138896361010555, 53.633447614654102],
            [-1.135404385157525, 53.632709663605084],
            [-1.133439131587423, 53.633486446185124],
            [-1.130567304804764, 53.633851235944874],
            [-1.128869349798258, 53.634933695894503],
            [-1.127072401852753, 53.638536686302508],
            [-1.128476258362053, 53.64079943017407],
            [-1.128992711625325, 53.64297031456509],
            [-1.128033828727557, 53.643550285431402],
            [-1.124996940490462, 53.643961380465882],
            [-1.123116784326603, 53.645997904707166],
            [-1.12174071675647, 53.646699710125795],
            [-1.119343339047436, 53.646825920971722],
            [-1.116995903830621, 53.645863944733449],
            [-1.116047614100183, 53.645781445651252],
            [-1.11382776929451, 53.646893996060477],
            [-1.10159749328283, 53.648569114607461],
            [-1.095118177776374, 53.649970180985513],
            [-1.090459492797776, 53.649849500251769],
            [-1.087010136352823, 53.651317145626493],
            [-1.084813942165047, 53.651707563173446],
            [-1.08256005927132, 53.6515474013908],
            [-1.071169097496098, 53.648922138780001],
            [-1.066068789511373, 53.648871687115111],
            [-1.053274146242629, 53.651517225462349],
            [-1.051713085292876, 53.652514288138228],
            [-1.050478680484779, 53.654929615551119],
            [-1.048659204673737, 53.656037845119847],
            [-1.046342808667636, 53.656698956630223],
            [-1.044486750382953, 53.656739892364122],
            [-1.041283188992499, 53.656014982989205],
            [-1.038782528463508, 53.65479317105838],
            [-1.037219166651754, 53.654475901855676],
            [-1.033335405614244, 53.655189768583725],
            [-1.029358416147326, 53.654949967603379],
            [-1.023447319472923, 53.656954901478365],
            [-1.018994991855491, 53.657957593015198],
            [-1.009971140991192, 53.658757927530409],
            [-0.998805536474678, 53.658669604909441],
            [-0.990998142020821, 53.661166728559508],
            [-0.990609582167634, 53.658554070521092],
            [-0.992556592159486, 53.655592547297417],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000018",
        LAD13CDO: "00CF",
        LAD13NM: "Rotherham",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.366521467972441, 53.514829962587889],
            [-1.359983360282617, 53.512970480510646],
            [-1.350276316368128, 53.513959915997958],
            [-1.345037466821398, 53.513955611392227],
            [-1.34358300768419, 53.51429459603613],
            [-1.341177737189268, 53.514776640582205],
            [-1.334583404179446, 53.514363514174484],
            [-1.330327765182407, 53.5146453731751],
            [-1.326037731665912, 53.51233817996593],
            [-1.324544698646624, 53.513105470638259],
            [-1.322557266683398, 53.513097825361378],
            [-1.320709156151091, 53.513902606222366],
            [-1.319942556223734, 53.513840722928187],
            [-1.31845189869438, 53.513039445784663],
            [-1.315277965831818, 53.512826264790384],
            [-1.312567243633241, 53.513717661971974],
            [-1.312611303229361, 53.513138152201115],
            [-1.311099715694576, 53.511777573106365],
            [-1.312051183140162, 53.507962004819895],
            [-1.313486730764153, 53.503008555086176],
            [-1.312956622038641, 53.501386665597508],
            [-1.309901511475989, 53.497809590231249],
            [-1.305934677100312, 53.496005090762438],
            [-1.299342630745399, 53.493088493872122],
            [-1.295866560275502, 53.492192583696202],
            [-1.291534590193639, 53.491654643841329],
            [-1.29156354527783, 53.491273697986919],
            [-1.290714394779828, 53.491127542543289],
            [-1.28906273450068, 53.491021555268965],
            [-1.289223867608448, 53.490398702953485],
            [-1.291013014815383, 53.49057381689844],
            [-1.292674465523986, 53.489727940377662],
            [-1.292243677887092, 53.488625183961553],
            [-1.293933555671167, 53.487694965149466],
            [-1.29278786684782, 53.486443269622384],
            [-1.293188450572056, 53.485735536977707],
            [-1.295466609990438, 53.485127868904101],
            [-1.299675672680877, 53.484580920657933],
            [-1.301329297663721, 53.483452635408867],
            [-1.300874091504512, 53.48254212328807],
            [-1.299287665594909, 53.483160242251252],
            [-1.298646909061724, 53.483087272792098],
            [-1.29852298628786, 53.481646567596442],
            [-1.295987716273487, 53.480503592425812],
            [-1.297088697763264, 53.479076383635864],
            [-1.296529086212112, 53.478109510507025],
            [-1.296692703946331, 53.477326664102833],
            [-1.298472007636333, 53.476513759148894],
            [-1.298670707671613, 53.475702352158706],
            [-1.297010463153197, 53.475147889460516],
            [-1.295037309208503, 53.476568160680621],
            [-1.281539541544362, 53.479638325194543],
            [-1.282099324493268, 53.47887945107383],
            [-1.280906443564338, 53.478403973713718],
            [-1.279362448404284, 53.47953353796953],
            [-1.279299942878194, 53.479754281800538],
            [-1.280179692511009, 53.48003553320374],
            [-1.280011037467632, 53.480296087109323],
            [-1.277545628794347, 53.481550410620045],
            [-1.276443334185612, 53.481772056088289],
            [-1.275292485065142, 53.482808666843276],
            [-1.27391892615888, 53.482675390914139],
            [-1.273073749642097, 53.483632938578729],
            [-1.273572066545921, 53.483926300417153],
            [-1.269732047296203, 53.485871419454114],
            [-1.269299864262076, 53.485413054912328],
            [-1.268134256995965, 53.485364577820661],
            [-1.263980566464428, 53.483728314563599],
            [-1.261125082592102, 53.483194753233938],
            [-1.256494140691662, 53.480957531909056],
            [-1.255306939745062, 53.480773066306774],
            [-1.254717951957991, 53.481191862875185],
            [-1.249938679325674, 53.476886966907209],
            [-1.25154013487689, 53.474414334648287],
            [-1.2546451697794, 53.47227285915946],
            [-1.255280044831544, 53.472151873469016],
            [-1.25528615328377, 53.471286302734427],
            [-1.257524348861959, 53.468638680021741],
            [-1.257721244016468, 53.46659138417067],
            [-1.259149585118079, 53.46518902320831],
            [-1.259320087781281, 53.464220201618097],
            [-1.258520443117727, 53.459799104910111],
            [-1.258801729754332, 53.459473661409746],
            [-1.258044554652115, 53.459018630530181],
            [-1.257159081569461, 53.459012231817347],
            [-1.256424458367312, 53.457181162499197],
            [-1.255491829080701, 53.457031537768863],
            [-1.253140418548605, 53.455858225239126],
            [-1.252142277061588, 53.45446772488225],
            [-1.256903669410787, 53.456042579140821],
            [-1.258027715092551, 53.455750238912877],
            [-1.258227740452858, 53.455333506341482],
            [-1.256949763566376, 53.452451882593103],
            [-1.255717997948775, 53.45158849422473],
            [-1.255310354071019, 53.450702365808269],
            [-1.251778048549365, 53.44871357571369],
            [-1.253808209658482, 53.445422919023464],
            [-1.253362859630299, 53.444288458931858],
            [-1.253732086594642, 53.44306470494422],
            [-1.251950301736719, 53.441477843384696],
            [-1.250692086768808, 53.44154276934426],
            [-1.250755846031499, 53.440742274440197],
            [-1.237336368637613, 53.441156258419035],
            [-1.240204563046436, 53.434556128471932],
            [-1.238057133769356, 53.432758190765455],
            [-1.232765145758342, 53.433500056955488],
            [-1.228579111894139, 53.433338274620489],
            [-1.225222070328759, 53.433694980826438],
            [-1.223242864785821, 53.43360482809134],
            [-1.220306340354731, 53.434585251471752],
            [-1.216394591944895, 53.435056740667271],
            [-1.209726783063492, 53.432272157611486],
            [-1.206988357873256, 53.431735363476164],
            [-1.19973584643855, 53.431855998620101],
            [-1.197343282617263, 53.43158377068643],
            [-1.195660913420805, 53.432919873149444],
            [-1.196417856695467, 53.435642268438308],
            [-1.195101711230834, 53.437104869811776],
            [-1.192919722607775, 53.436271276208515],
            [-1.193053397948896, 53.435651055686513],
            [-1.1859185836275, 53.435246704765383],
            [-1.185805581009422, 53.433755597271436],
            [-1.182248898169328, 53.433905691209375],
            [-1.174208739325895, 53.435422532183907],
            [-1.170118403341218, 53.435217115031307],
            [-1.168702772999261, 53.434249071039922],
            [-1.166656028354863, 53.430920649929647],
            [-1.164866320483585, 53.429851971670047],
            [-1.162839492791759, 53.427910591081123],
            [-1.160399846618041, 53.424321324670309],
            [-1.158150332060694, 53.424230888133643],
            [-1.15330764304671, 53.424836669806815],
            [-1.145845739776298, 53.426369181279128],
            [-1.145057557258057, 53.425973429654825],
            [-1.140819486747657, 53.42523470953428],
            [-1.136752374999981, 53.425127189683352],
            [-1.135619564929308, 53.424069105728023],
            [-1.143524821475821, 53.418069374840158],
            [-1.144783543219472, 53.416002873731692],
            [-1.145873635514938, 53.412283906327154],
            [-1.142950574503973, 53.412054429873976],
            [-1.136052792105026, 53.410860463984797],
            [-1.13422728009519, 53.409855778567909],
            [-1.13372816480641, 53.409913284178579],
            [-1.133354920377251, 53.408736634475559],
            [-1.122029395135535, 53.410315068275395],
            [-1.121000444618969, 53.410093566678377],
            [-1.11889312599539, 53.410223660769219],
            [-1.118028388980527, 53.410937285954908],
            [-1.116024908611763, 53.410119772604361],
            [-1.115704201952426, 53.409697619581017],
            [-1.116197403605057, 53.408910250775286],
            [-1.115956387222502, 53.40732912583745],
            [-1.115140578646333, 53.401841679232447],
            [-1.115918103925852, 53.397999303612238],
            [-1.115578935082004, 53.397166221127016],
            [-1.119469887797932, 53.398026439939024],
            [-1.122810714311844, 53.39829282297729],
            [-1.129207081428401, 53.393576430179827],
            [-1.131511492364187, 53.393916808535387],
            [-1.133207255079339, 53.392388436407401],
            [-1.132899846149881, 53.392272046377677],
            [-1.133277790000928, 53.391358824367551],
            [-1.133492249982387, 53.391461055825957],
            [-1.134692306420638, 53.389751085383288],
            [-1.131310416610225, 53.389536869281031],
            [-1.130934453444693, 53.388210073327706],
            [-1.130946762795275, 53.385537770649307],
            [-1.132444731522303, 53.383961226547669],
            [-1.133046456135957, 53.381575453504617],
            [-1.13049926536421, 53.378867460101631],
            [-1.130994191623924, 53.377964084149212],
            [-1.129995533612562, 53.377546020285386],
            [-1.130536719386478, 53.375551731792953],
            [-1.13190067726884, 53.37559671202979],
            [-1.131957339106411, 53.374879810470603],
            [-1.133659554033751, 53.374847228013131],
            [-1.134193849746187, 53.374146374235266],
            [-1.13413624538356, 53.373206617393848],
            [-1.138218718508689, 53.372232990689426],
            [-1.140739424769114, 53.372003961987161],
            [-1.140455612431087, 53.371244155874919],
            [-1.141403127199265, 53.37069006603739],
            [-1.142803228657282, 53.37124844766975],
            [-1.144915302934834, 53.371231231219689],
            [-1.146032631789209, 53.369021668515046],
            [-1.144214498352302, 53.368250887869884],
            [-1.144224847735292, 53.367359263831808],
            [-1.145661965544176, 53.366589319359129],
            [-1.145875516388359, 53.365830386696743],
            [-1.146439897018408, 53.365713971471706],
            [-1.146416872346609, 53.363933106526737],
            [-1.145177983122859, 53.363363335850281],
            [-1.140553980104041, 53.36404026378829],
            [-1.140115544782043, 53.362603377042227],
            [-1.138975805137026, 53.361369975363054],
            [-1.139236533987522, 53.359840145558174],
            [-1.13853936557111, 53.355888086023107],
            [-1.144951412198925, 53.355312148747458],
            [-1.14527935936487, 53.354840781436394],
            [-1.149101154654356, 53.354332342730949],
            [-1.15169317421734, 53.354465846822755],
            [-1.161949153501065, 53.357889342535266],
            [-1.162622736115986, 53.356716523331642],
            [-1.161616897506711, 53.355678437575627],
            [-1.161954029887172, 53.354193141519119],
            [-1.162419702637277, 53.351590526400344],
            [-1.161820907235498, 53.350869009072824],
            [-1.159867963282391, 53.349950098344721],
            [-1.160102978407689, 53.348922521828818],
            [-1.158148951799774, 53.347683569336624],
            [-1.156876845628558, 53.345843549433091],
            [-1.156843401308462, 53.344949814757172],
            [-1.152670249147839, 53.344664980727622],
            [-1.149565060157704, 53.345289202788223],
            [-1.148179075113689, 53.345191229330169],
            [-1.144906837362977, 53.345675708863823],
            [-1.141551518956119, 53.346777939597146],
            [-1.140286126064645, 53.346508154317085],
            [-1.139359474826445, 53.344709084612028],
            [-1.138717900970279, 53.341363270657268],
            [-1.14090861877592, 53.340946689562394],
            [-1.14541619200844, 53.340709377528022],
            [-1.148620520325343, 53.338486839857723],
            [-1.159384959579287, 53.333488881163412],
            [-1.160511829547112, 53.332310272751265],
            [-1.160867521797955, 53.331095672028695],
            [-1.163882631249576, 53.329257020693184],
            [-1.165872374257758, 53.329092063080559],
            [-1.168904369172233, 53.328220612902498],
            [-1.169777829444825, 53.327390720860507],
            [-1.171075593104848, 53.32721007457485],
            [-1.171478153050983, 53.326503642696544],
            [-1.172189625734087, 53.326902294912728],
            [-1.172480611349441, 53.326529474182514],
            [-1.173458176840726, 53.326367256105691],
            [-1.173880543437657, 53.325721179207108],
            [-1.175111435012278, 53.325656882448548],
            [-1.175309582032828, 53.324490588956316],
            [-1.174052689250023, 53.32357041896099],
            [-1.172037401607416, 53.322742955959313],
            [-1.174704439724517, 53.322627493391991],
            [-1.174238754294878, 53.321786502901119],
            [-1.174482485412902, 53.321370203244378],
            [-1.180416036686342, 53.321025472918784],
            [-1.186610743882352, 53.319572993179257],
            [-1.190324293459456, 53.319911060058907],
            [-1.190113362699748, 53.319475463292449],
            [-1.191535649213715, 53.31920195524728],
            [-1.191524079902394, 53.318940298870658],
            [-1.192416735780735, 53.318968815051889],
            [-1.194373844074288, 53.314844435195624],
            [-1.197205374635534, 53.314652276581768],
            [-1.199744007805193, 53.311418926888443],
            [-1.203028385999607, 53.30720531719335],
            [-1.20210922360524, 53.306035106191921],
            [-1.203240364598348, 53.304201727664989],
            [-1.205709192231988, 53.304268525059754],
            [-1.209809476469717, 53.305298015398925],
            [-1.212502281074515, 53.305429976423028],
            [-1.218737666256116, 53.304030935499028],
            [-1.219879190132189, 53.304056381782075],
            [-1.221820202668669, 53.30517469505947],
            [-1.230688807347958, 53.308538328603568],
            [-1.23230573219003, 53.308255702603951],
            [-1.23682159658274, 53.306319689799636],
            [-1.238735585024946, 53.304430757451549],
            [-1.242103872117697, 53.302095301378394],
            [-1.243330600665582, 53.301627572297022],
            [-1.244944414069406, 53.301671952883432],
            [-1.246166654253662, 53.302394300280994],
            [-1.247883019377168, 53.302667607526445],
            [-1.251033213542568, 53.3018694295532],
            [-1.25429480127633, 53.301733449525905],
            [-1.255016015895274, 53.302368976734932],
            [-1.258176520839968, 53.303391829887929],
            [-1.261314180197669, 53.303920965864471],
            [-1.264147851045966, 53.305377598392909],
            [-1.267812858247381, 53.305796553082743],
            [-1.269448314451646, 53.307837170460353],
            [-1.269640302177272, 53.309075218773053],
            [-1.271548681933341, 53.309290032291834],
            [-1.273597655480398, 53.310418943248997],
            [-1.274109509455454, 53.310503856732254],
            [-1.276429502335227, 53.309192083371713],
            [-1.28201135491068, 53.309441530399091],
            [-1.285307317077106, 53.311589884367898],
            [-1.293664226297581, 53.313960568145646],
            [-1.295677685487299, 53.315016076709362],
            [-1.294734184873337, 53.315250507979641],
            [-1.29568635190889, 53.319688545493811],
            [-1.295158164885854, 53.321770845494108],
            [-1.293946790069773, 53.322763247907695],
            [-1.290108079103753, 53.323119808419548],
            [-1.290343346090759, 53.323689304050212],
            [-1.289310752951499, 53.324393280779042],
            [-1.288588466482112, 53.326207425847599],
            [-1.291230521292758, 53.327955297915736],
            [-1.291766332373131, 53.327877578592229],
            [-1.2918214009321, 53.328179032205277],
            [-1.293230819669057, 53.328661997830316],
            [-1.292828205782827, 53.328765681755677],
            [-1.293179317706187, 53.328961920792977],
            [-1.294666627051733, 53.32945881378177],
            [-1.29537578318242, 53.330343910150553],
            [-1.295733978312854, 53.330111414916622],
            [-1.29709634082776, 53.330631809987942],
            [-1.297391435907141, 53.331406588817735],
            [-1.299071620980026, 53.332512207851046],
            [-1.302208641546087, 53.332505418358167],
            [-1.305972561558411, 53.33374984482937],
            [-1.312298769548236, 53.33414511289493],
            [-1.311501342304652, 53.332094655426253],
            [-1.308788513774736, 53.332150888225577],
            [-1.309248999845904, 53.331717594749989],
            [-1.3105076214313, 53.331886670644231],
            [-1.311321601241499, 53.330854056687087],
            [-1.311438214799111, 53.330232701174161],
            [-1.310536753281286, 53.329334006109661],
            [-1.313005124065085, 53.329341945892232],
            [-1.313677517693046, 53.327300850597581],
            [-1.314957532918541, 53.3265019037255],
            [-1.315511083232648, 53.32643766299163],
            [-1.315610910496054, 53.326767227368073],
            [-1.318369447131728, 53.325987498891614],
            [-1.321611164290868, 53.32547678725642],
            [-1.32165807365874, 53.325668252976755],
            [-1.322297059792542, 53.327507405362475],
            [-1.323718449784727, 53.327063328063609],
            [-1.324026815728876, 53.327312268043961],
            [-1.324755808741236, 53.327071897606501],
            [-1.324668614798439, 53.328790970240405],
            [-1.324871322093542, 53.333833057936467],
            [-1.325510030073745, 53.336848826425651],
            [-1.325970642116999, 53.341259548830749],
            [-1.328597055560426, 53.348941809232628],
            [-1.330219625010828, 53.348834068257332],
            [-1.331101808017372, 53.349193173091649],
            [-1.330677544187214, 53.351056871923355],
            [-1.332076275670494, 53.352395946989184],
            [-1.33537844961423, 53.351909203562677],
            [-1.337751477648243, 53.353500822878921],
            [-1.340373536366356, 53.358437601179084],
            [-1.345279866191907, 53.361344611333351],
            [-1.346016222760083, 53.362224153944929],
            [-1.351946401403645, 53.366442557877235],
            [-1.361069876616858, 53.366552844729576],
            [-1.363646266798872, 53.370047051088939],
            [-1.365866645147485, 53.372036388952317],
            [-1.371034998801604, 53.375439895929354],
            [-1.373979727288537, 53.377587498506522],
            [-1.376639509689608, 53.378885002969341],
            [-1.379853141837217, 53.381081501677592],
            [-1.38369282752583, 53.382423619313734],
            [-1.385805235619786, 53.38293517303336],
            [-1.391402158801598, 53.383267641878916],
            [-1.390073100512641, 53.385901750264502],
            [-1.388444616772561, 53.387279484783363],
            [-1.385961826152559, 53.388668089186076],
            [-1.380067120003672, 53.390883024069964],
            [-1.379131471550491, 53.391734789644183],
            [-1.377030596332552, 53.39226317471639],
            [-1.375631008992532, 53.393676085189874],
            [-1.376273024232083, 53.394608872767414],
            [-1.379151218368942, 53.396786551428995],
            [-1.377008474099603, 53.398311566914437],
            [-1.377446011100681, 53.399246876170245],
            [-1.38176372855692, 53.401024794043877],
            [-1.383014544124885, 53.403454615121326],
            [-1.381746037684666, 53.403906480923702],
            [-1.382116867757195, 53.405096705660519],
            [-1.383120973068108, 53.405560318284692],
            [-1.384629144390356, 53.407266061023428],
            [-1.386886928411453, 53.410831797692254],
            [-1.390082818985517, 53.412263873380503],
            [-1.390727972018779, 53.413123786656541],
            [-1.39178303568477, 53.415097683454093],
            [-1.389292831631188, 53.417738439266387],
            [-1.389476296654676, 53.418586110178055],
            [-1.392765758103131, 53.418354785309262],
            [-1.393590977904653, 53.420000307979478],
            [-1.384446465101667, 53.420550396857507],
            [-1.382433656154183, 53.421034387859379],
            [-1.382407274040679, 53.421505258087691],
            [-1.379759590259469, 53.42238861364563],
            [-1.380184941652379, 53.423665414722187],
            [-1.381716553776578, 53.425347040064715],
            [-1.386649029241718, 53.425177405783543],
            [-1.396194180557621, 53.423821090681287],
            [-1.397233265879794, 53.424939133272289],
            [-1.399240147973629, 53.424556435219557],
            [-1.399529965644811, 53.424173177183015],
            [-1.403576940251263, 53.422959301907163],
            [-1.408544490022071, 53.420343124175666],
            [-1.409707857066099, 53.421414033544821],
            [-1.409562939084812, 53.422316678029603],
            [-1.411388732230948, 53.423262303997447],
            [-1.412338220236531, 53.424685387356334],
            [-1.415243113590405, 53.426733770045779],
            [-1.418183124367686, 53.429978640560392],
            [-1.425626266545144, 53.433951670433181],
            [-1.433331082595376, 53.438274230570727],
            [-1.436427363681105, 53.440453331529511],
            [-1.440231016479305, 53.44404777723831],
            [-1.443132639811664, 53.447585765374498],
            [-1.444293552182035, 53.450468420720043],
            [-1.444256848558833, 53.454550872075764],
            [-1.4436804972223, 53.455671765670054],
            [-1.444769323971375, 53.456825570283861],
            [-1.445188793548771, 53.458613550995402],
            [-1.447736195284028, 53.461931348754966],
            [-1.455203245640875, 53.471747874885395],
            [-1.456718124929886, 53.473572255716064],
            [-1.451798821806214, 53.476166372346569],
            [-1.450331883201747, 53.477638255731712],
            [-1.450739243384227, 53.482031936128728],
            [-1.452162431572875, 53.48502176472708],
            [-1.443692573191159, 53.488402803146371],
            [-1.442418409833307, 53.490176600739247],
            [-1.440948470065575, 53.491127017714241],
            [-1.435577590754617, 53.488451065607677],
            [-1.432856772136375, 53.487920451693434],
            [-1.42786165842643, 53.485833782792568],
            [-1.425982699058027, 53.487374404883504],
            [-1.425432872812582, 53.48869398067],
            [-1.424019059483139, 53.489704683347277],
            [-1.422709301709583, 53.490261949759585],
            [-1.421997973277054, 53.489813580220968],
            [-1.41985885996439, 53.48984546767511],
            [-1.419710440045424, 53.491223591676935],
            [-1.419036906416078, 53.491098975880092],
            [-1.416949191848015, 53.492214181779268],
            [-1.41503444478163, 53.492471790715804],
            [-1.415546400959576, 53.49327697167864],
            [-1.416686490946189, 53.49356477880719],
            [-1.416266418407679, 53.49451911075689],
            [-1.411610748014118, 53.496225691419447],
            [-1.410703255354321, 53.496880982454663],
            [-1.409578230172419, 53.498435845253788],
            [-1.411957916023639, 53.499243955283362],
            [-1.408506833089697, 53.503281667868599],
            [-1.407569736092648, 53.503450508366598],
            [-1.407112688056666, 53.504007330647717],
            [-1.405486554946963, 53.504284194942393],
            [-1.405605927705208, 53.504804325859403],
            [-1.396401480739643, 53.505222034698228],
            [-1.396993564346984, 53.505678942812374],
            [-1.389647412877911, 53.509385406186297],
            [-1.38086963851667, 53.513867817207924],
            [-1.379248225389125, 53.514403219708882],
            [-1.370706535848603, 53.514715429056452],
            [-1.369359193693133, 53.514352386366035],
            [-1.368933179784716, 53.515214837136327],
            [-1.366521467972441, 53.514829962587889],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000019",
        LAD13CDO: "00CG",
        LAD13NM: "Sheffield",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.698463116541957, 53.502566363178595],
            [-1.693446483603714, 53.500169880664735],
            [-1.690689993306973, 53.499441913010827],
            [-1.690536427777915, 53.499828917841683],
            [-1.688986554843146, 53.499643332389233],
            [-1.688803737813765, 53.499081079205098],
            [-1.684462524387447, 53.499440931402901],
            [-1.684056912661105, 53.498844825810487],
            [-1.682570168005157, 53.499500642382316],
            [-1.682147166498344, 53.499419522151001],
            [-1.680315064185119, 53.500487861151967],
            [-1.669268245472973, 53.500629512522863],
            [-1.659510425187668, 53.498496144909438],
            [-1.655104543315187, 53.496768526475158],
            [-1.64805568838864, 53.494792107274684],
            [-1.64171426422203, 53.494083866784671],
            [-1.633832184282182, 53.492373762013756],
            [-1.624325804060996, 53.492220242498114],
            [-1.622298990876767, 53.492171613879151],
            [-1.614975916112365, 53.490604045371612],
            [-1.614483050729532, 53.493100355555733],
            [-1.611956296078917, 53.493326784296151],
            [-1.609650784399533, 53.492272125082273],
            [-1.609339538256186, 53.491697644021251],
            [-1.606617212610949, 53.492868010046351],
            [-1.602312720081394, 53.491549547327558],
            [-1.602172515731695, 53.49187536293892],
            [-1.592812069036658, 53.490512668507058],
            [-1.589508151174289, 53.489592638854411],
            [-1.587244417399387, 53.488193429829359],
            [-1.582346316499164, 53.48758048862603],
            [-1.576362476950314, 53.486139260279188],
            [-1.574835891425397, 53.485984633977026],
            [-1.569180112147781, 53.486421908606587],
            [-1.567326272105845, 53.486291174855197],
            [-1.566012977729424, 53.486060805016201],
            [-1.566332186459497, 53.485644896460222],
            [-1.565267013372972, 53.485469348842813],
            [-1.565087621111606, 53.484885342756641],
            [-1.564072178993448, 53.484859174436416],
            [-1.56225619936884, 53.484279074618556],
            [-1.560863693788509, 53.484285654569291],
            [-1.561072203486848, 53.483940362542398],
            [-1.559370090503943, 53.483577259549016],
            [-1.558105958418895, 53.482688125948677],
            [-1.557276155807567, 53.481046451355319],
            [-1.557366976706314, 53.480282763982139],
            [-1.549633232004003, 53.478499329710871],
            [-1.546566190406377, 53.474612787304721],
            [-1.543675067520066, 53.472636897022738],
            [-1.544485822571606, 53.472193258399258],
            [-1.548119886759486, 53.47131448610579],
            [-1.545487856953797, 53.469918478762892],
            [-1.544387746541823, 53.468829375889612],
            [-1.5441581148045, 53.467765156874698],
            [-1.541831560865336, 53.464330731807287],
            [-1.539447539342287, 53.462484313811728],
            [-1.539099234834738, 53.461683889782655],
            [-1.539899034413006, 53.461542254716768],
            [-1.540210042848106, 53.461055373020521],
            [-1.539313143308991, 53.457782784420772],
            [-1.540526451061304, 53.456996460864147],
            [-1.540417200557937, 53.456095387733711],
            [-1.551383072202435, 53.455437730121055],
            [-1.552551482833961, 53.453450245297759],
            [-1.552520843787239, 53.452783179784184],
            [-1.548743176423122, 53.451170814392562],
            [-1.547361198739682, 53.44986314727219],
            [-1.550410241771117, 53.447589760315488],
            [-1.551241743315805, 53.446553806470398],
            [-1.551141106823414, 53.446102202604663],
            [-1.548283952467302, 53.44491843205266],
            [-1.545905904622204, 53.441739152556124],
            [-1.540800214450035, 53.44096280948969],
            [-1.53742642830666, 53.440833848384045],
            [-1.535080197556254, 53.439938471645881],
            [-1.534001644507736, 53.438624635119531],
            [-1.532942190979076, 53.438321180134537],
            [-1.531896437623888, 53.438279337211178],
            [-1.531878753354059, 53.438655889676653],
            [-1.527817283565559, 53.438522163832431],
            [-1.526485685812604, 53.439006774051229],
            [-1.525486937557162, 53.440285484515812],
            [-1.524643253613135, 53.440478982266669],
            [-1.523865912911005, 53.441336095888261],
            [-1.523292424737126, 53.443077595178956],
            [-1.520793713814843, 53.444511170156026],
            [-1.520546282881853, 53.445761388230224],
            [-1.518790380899665, 53.447659910974274],
            [-1.518453800962577, 53.449330430568075],
            [-1.513130728391189, 53.451465227960753],
            [-1.514826592924419, 53.453816341965336],
            [-1.518132575394399, 53.457228289843201],
            [-1.516683370225283, 53.457928940787419],
            [-1.517362393773634, 53.458345157802725],
            [-1.515030049666488, 53.459621083411001],
            [-1.516330661475757, 53.460607004040334],
            [-1.514944990682118, 53.461799564847752],
            [-1.514813967109131, 53.463648877861999],
            [-1.511717127176529, 53.465751276887048],
            [-1.507372134782142, 53.466599949502019],
            [-1.507658968628523, 53.468238844986402],
            [-1.506428641764332, 53.470152827527308],
            [-1.494498125029054, 53.480999794002344],
            [-1.495282349882972, 53.485136033830443],
            [-1.494716883452481, 53.486306648838401],
            [-1.492517275704512, 53.486629011232814],
            [-1.489046109991782, 53.486412887118277],
            [-1.484565880801192, 53.484777521938703],
            [-1.479659443555923, 53.480931649483836],
            [-1.477977636812427, 53.48008478921728],
            [-1.474095136731819, 53.479123080195002],
            [-1.466858207809054, 53.478086111547611],
            [-1.465153129529863, 53.478280739173925],
            [-1.463352927757022, 53.477772909619965],
            [-1.461541901385157, 53.476218736171262],
            [-1.460238717893838, 53.475735567909943],
            [-1.456493420445605, 53.471938012392592],
            [-1.455982158269119, 53.471862879797307],
            [-1.455203245640875, 53.471747874885395],
            [-1.447736195284028, 53.461931348754966],
            [-1.445188793548771, 53.458613550995402],
            [-1.444769323971375, 53.456825570283861],
            [-1.4436804972223, 53.455671765670054],
            [-1.444256848558833, 53.454550872075764],
            [-1.444293552182035, 53.450468420720043],
            [-1.443132639811664, 53.447585765374498],
            [-1.440231016479305, 53.44404777723831],
            [-1.436427363681105, 53.440453331529511],
            [-1.433331082595376, 53.438274230570727],
            [-1.425626266545144, 53.433951670433181],
            [-1.418183124367686, 53.429978640560392],
            [-1.415243113590405, 53.426733770045779],
            [-1.412338220236531, 53.424685387356334],
            [-1.411388732230948, 53.423262303997447],
            [-1.409562939084812, 53.422316678029603],
            [-1.409707857066099, 53.421414033544821],
            [-1.408544490022071, 53.420343124175666],
            [-1.403576940251263, 53.422959301907163],
            [-1.399529965644811, 53.424173177183015],
            [-1.399240147973629, 53.424556435219557],
            [-1.397233265879794, 53.424939133272289],
            [-1.396194180557621, 53.423821090681287],
            [-1.386649029241718, 53.425177405783543],
            [-1.381716553776578, 53.425347040064715],
            [-1.380184941652379, 53.423665414722187],
            [-1.379759590259469, 53.42238861364563],
            [-1.382407274040679, 53.421505258087691],
            [-1.382433656154183, 53.421034387859379],
            [-1.384446465101667, 53.420550396857507],
            [-1.393590977904653, 53.420000307979478],
            [-1.392765758103131, 53.418354785309262],
            [-1.389476296654676, 53.418586110178055],
            [-1.389292831631188, 53.417738439266387],
            [-1.39178303568477, 53.415097683454093],
            [-1.390727972018779, 53.413123786656541],
            [-1.390082818985517, 53.412263873380503],
            [-1.386886928411453, 53.410831797692254],
            [-1.384629144390356, 53.407266061023428],
            [-1.383120973068108, 53.405560318284692],
            [-1.382116867757195, 53.405096705660519],
            [-1.381746037684666, 53.403906480923702],
            [-1.383014544124885, 53.403454615121326],
            [-1.38176372855692, 53.401024794043877],
            [-1.377446011100681, 53.399246876170245],
            [-1.377008474099603, 53.398311566914437],
            [-1.379151218368942, 53.396786551428995],
            [-1.376273024232083, 53.394608872767414],
            [-1.375631008992532, 53.393676085189874],
            [-1.377030596332552, 53.39226317471639],
            [-1.379131471550491, 53.391734789644183],
            [-1.380067120003672, 53.390883024069964],
            [-1.385961826152559, 53.388668089186076],
            [-1.388444616772561, 53.387279484783363],
            [-1.390073100512641, 53.385901750264502],
            [-1.391402158801598, 53.383267641878916],
            [-1.385805235619786, 53.38293517303336],
            [-1.38369282752583, 53.382423619313734],
            [-1.379853141837217, 53.381081501677592],
            [-1.376639509689608, 53.378885002969341],
            [-1.373979727288537, 53.377587498506522],
            [-1.371034998801604, 53.375439895929354],
            [-1.365866645147485, 53.372036388952317],
            [-1.363646266798872, 53.370047051088939],
            [-1.361069876616858, 53.366552844729576],
            [-1.351946401403645, 53.366442557877235],
            [-1.346016222760083, 53.362224153944929],
            [-1.345279866191907, 53.361344611333351],
            [-1.340373536366356, 53.358437601179084],
            [-1.337751477648243, 53.353500822878921],
            [-1.33537844961423, 53.351909203562677],
            [-1.332076275670494, 53.352395946989184],
            [-1.330677544187214, 53.351056871923355],
            [-1.331101808017372, 53.349193173091649],
            [-1.330219625010828, 53.348834068257332],
            [-1.328597055560426, 53.348941809232628],
            [-1.325970642116999, 53.341259548830749],
            [-1.325510030073745, 53.336848826425651],
            [-1.324871322093542, 53.333833057936467],
            [-1.324668614798439, 53.328790970240405],
            [-1.327663597959126, 53.329109902328021],
            [-1.328299663132378, 53.32895527969977],
            [-1.328768669631909, 53.327677008051047],
            [-1.328125714202501, 53.327315634785272],
            [-1.328164039826766, 53.326787306488335],
            [-1.332416630426136, 53.323480799478496],
            [-1.332805328854507, 53.322912181055401],
            [-1.332215104644247, 53.322445055781998],
            [-1.33231002547619, 53.322038391953107],
            [-1.334561563347562, 53.320999270542629],
            [-1.334524165279061, 53.320315010544284],
            [-1.336580017239942, 53.317945308648397],
            [-1.336548577926798, 53.31658422137928],
            [-1.337667719658566, 53.31585065445033],
            [-1.340259187053456, 53.315515341129149],
            [-1.341628735835203, 53.316061336147193],
            [-1.340894257300846, 53.316968752008698],
            [-1.343052261183578, 53.317884928117124],
            [-1.347307058499127, 53.318532097546104],
            [-1.347237661867361, 53.318849923194698],
            [-1.353472811513297, 53.319089705244558],
            [-1.36177749325716, 53.318466530197675],
            [-1.362947068268161, 53.317546029075153],
            [-1.361923033815594, 53.315752678233075],
            [-1.362622971275232, 53.315016636316265],
            [-1.36416801605386, 53.315184878760789],
            [-1.365450417048595, 53.31585777687895],
            [-1.371646717624115, 53.316062245858582],
            [-1.374130370177389, 53.31723125981685],
            [-1.377039959233227, 53.31740468492869],
            [-1.378146603065005, 53.317071576731031],
            [-1.379612623757892, 53.317212240532491],
            [-1.380617286404965, 53.316974759849927],
            [-1.383659990535676, 53.317807595458468],
            [-1.384351041691672, 53.317561272461234],
            [-1.385077694212666, 53.317761873715732],
            [-1.386740050136579, 53.317613121998946],
            [-1.386860261741682, 53.318023630152858],
            [-1.387305361714109, 53.317865015777876],
            [-1.38786387268024, 53.318382941255983],
            [-1.389261219737746, 53.318712798294086],
            [-1.389933869740675, 53.318492416473347],
            [-1.392005199246385, 53.322629753465812],
            [-1.390429560954274, 53.326058141994224],
            [-1.39120583119991, 53.328270654728257],
            [-1.390341090605241, 53.331002434808696],
            [-1.390725679109445, 53.331621929303765],
            [-1.388730586810744, 53.333161400023378],
            [-1.38792002282202, 53.334502870693314],
            [-1.387226310762056, 53.334078635872864],
            [-1.386573810158207, 53.334865400585883],
            [-1.388916727747388, 53.336214051257542],
            [-1.388824206374634, 53.336428003640734],
            [-1.389459025938912, 53.335992105934764],
            [-1.390698401896056, 53.335961583879175],
            [-1.406396755284264, 53.339380008341649],
            [-1.407566062747724, 53.340077054139464],
            [-1.406786613036535, 53.3408327360737],
            [-1.408747737731587, 53.341946286808771],
            [-1.411034835869431, 53.34197377101659],
            [-1.412385387892726, 53.341077952205886],
            [-1.412113835584951, 53.340947176909083],
            [-1.413027486213532, 53.339598860604546],
            [-1.412591008023941, 53.338792217679341],
            [-1.413852554600243, 53.337915719442798],
            [-1.414510872503525, 53.338316254055066],
            [-1.41611634570067, 53.336994675489201],
            [-1.420868046565201, 53.334549499898422],
            [-1.421184443230273, 53.335027440039646],
            [-1.422270448815955, 53.335007531885786],
            [-1.422403308603941, 53.335288624871914],
            [-1.423097747199774, 53.335352207523748],
            [-1.423491024312392, 53.335922198502196],
            [-1.427793715342887, 53.336131663136285],
            [-1.433613615326073, 53.337672227037153],
            [-1.433911435840525, 53.337410268393121],
            [-1.436737539880861, 53.337430826251726],
            [-1.440874056946382, 53.33635274095893],
            [-1.442137329630508, 53.337366292179979],
            [-1.443032491378099, 53.337243729926499],
            [-1.450805230809949, 53.331552091116116],
            [-1.453937917089104, 53.330699044303543],
            [-1.459881426237216, 53.330671250998549],
            [-1.456638051733956, 53.329372036497254],
            [-1.457521213670548, 53.328639871939721],
            [-1.458277064314212, 53.328710720111829],
            [-1.45993740455707, 53.329686334322645],
            [-1.461702476392753, 53.328430484098774],
            [-1.460240200622897, 53.327227474896574],
            [-1.458037642770699, 53.326394123315254],
            [-1.456594687541624, 53.326284192621465],
            [-1.456371086712002, 53.325093958319371],
            [-1.455131320198009, 53.324948080928479],
            [-1.454308318426409, 53.324262072624627],
            [-1.455825910634552, 53.323455515058491],
            [-1.455224798295959, 53.32185097091358],
            [-1.457281263659479, 53.321751574020297],
            [-1.457263121786359, 53.32069620743966],
            [-1.459735970551241, 53.319715961433054],
            [-1.45997475716372, 53.320064908207804],
            [-1.461667969420059, 53.32019390500114],
            [-1.463224018821266, 53.319769450410988],
            [-1.467209235580809, 53.317992243087751],
            [-1.467866804814091, 53.317140342172067],
            [-1.469624204244897, 53.317169736830458],
            [-1.474387643741133, 53.318472608352756],
            [-1.476917854806903, 53.319265747204881],
            [-1.479599857476217, 53.319311627089263],
            [-1.479691326019916, 53.318702586173011],
            [-1.482500974806457, 53.318865811422732],
            [-1.484632501413723, 53.318499301946055],
            [-1.484716781207316, 53.318730677532251],
            [-1.491066349296622, 53.31736106096487],
            [-1.492331497766106, 53.317383526948291],
            [-1.493096000976856, 53.316711718282797],
            [-1.496127682691263, 53.316368598167976],
            [-1.500716034898715, 53.315884506322256],
            [-1.502132988050177, 53.317564135419921],
            [-1.510489114259837, 53.316028354954035],
            [-1.51526246669676, 53.313770077742419],
            [-1.515897190237793, 53.313387933832736],
            [-1.516131423971703, 53.312583488858337],
            [-1.51733961304412, 53.312188379570323],
            [-1.519115681892294, 53.312277345834282],
            [-1.521197175032108, 53.312837624275815],
            [-1.521547839139421, 53.312752738060219],
            [-1.521631449858546, 53.311717565803733],
            [-1.52418543584428, 53.312439684999951],
            [-1.525790848943492, 53.312083825852397],
            [-1.526317187484955, 53.311448611303206],
            [-1.526186367901263, 53.310385618369068],
            [-1.528198344428822, 53.310308194429076],
            [-1.528675546982884, 53.31085929405625],
            [-1.528899592374779, 53.310684897318758],
            [-1.529411940802475, 53.310907143370521],
            [-1.530031131704678, 53.310420593307512],
            [-1.530120143884472, 53.31065195534368],
            [-1.531029383170663, 53.310402946393623],
            [-1.531215961405789, 53.310633792007067],
            [-1.531458853017835, 53.309970474935724],
            [-1.532015165436794, 53.309878275135354],
            [-1.531843083003747, 53.308733328675949],
            [-1.53285482951322, 53.308437966161605],
            [-1.533408361327496, 53.307634736404722],
            [-1.533087343748767, 53.30762179569227],
            [-1.533410599723588, 53.307153845312136],
            [-1.532974141125972, 53.306855508553824],
            [-1.533374495000982, 53.306473253300126],
            [-1.533116275112734, 53.306207074426247],
            [-1.534581150831474, 53.305498187005085],
            [-1.534987148099839, 53.30569932041962],
            [-1.535043106873816, 53.305244705923322],
            [-1.536791840425393, 53.305062740676746],
            [-1.536769918020961, 53.304730969445544],
            [-1.539008033700775, 53.304808848120601],
            [-1.539236292613927, 53.304514897363276],
            [-1.540936377832816, 53.304667066367202],
            [-1.540929110853574, 53.304925016498792],
            [-1.541656836722905, 53.304652756412167],
            [-1.542604636071004, 53.305271224910484],
            [-1.546859626513781, 53.30663037789413],
            [-1.549077146293652, 53.306965068270742],
            [-1.550396704689589, 53.306209595308495],
            [-1.553539605796636, 53.305878020879796],
            [-1.554792143447298, 53.306073261273802],
            [-1.55685304130794, 53.305449019285639],
            [-1.558458736822501, 53.305304857173617],
            [-1.557935038259157, 53.305737975572228],
            [-1.561828125953387, 53.306584690518392],
            [-1.559689253407027, 53.308226259733686],
            [-1.559124559851188, 53.309723502358899],
            [-1.556554041263509, 53.311426341320207],
            [-1.555752987629062, 53.311531228057639],
            [-1.558490749559915, 53.313698689054512],
            [-1.560176523242894, 53.313678844599742],
            [-1.561366021729199, 53.315803670002509],
            [-1.562260893962832, 53.315778190679602],
            [-1.564091655512151, 53.313615003005054],
            [-1.56609006503506, 53.31355127239587],
            [-1.567500906891775, 53.314744706285559],
            [-1.570494853161926, 53.314348324896471],
            [-1.573907371053046, 53.313362801733057],
            [-1.573158464809135, 53.312754283901761],
            [-1.580535099223884, 53.311720657156094],
            [-1.581240905723613, 53.314289425297602],
            [-1.583005769865039, 53.315705039588977],
            [-1.581289219694635, 53.316409142855541],
            [-1.578925432126404, 53.316677682463848],
            [-1.584903227041882, 53.321570517805036],
            [-1.586903132865833, 53.321386893045421],
            [-1.587295487545576, 53.320864206267402],
            [-1.586774778715534, 53.319909598255492],
            [-1.590379661156507, 53.318280676215039],
            [-1.599094428818605, 53.311300832519393],
            [-1.599198649949176, 53.314597357637396],
            [-1.600674750221996, 53.315655785395975],
            [-1.60060713115865, 53.316950837494169],
            [-1.601526275255263, 53.318188063353809],
            [-1.603889410962317, 53.319119070956567],
            [-1.609202767000083, 53.322646682782533],
            [-1.619435523992255, 53.31824020141881],
            [-1.625495025617148, 53.316406783607455],
            [-1.628233144485566, 53.316382082746493],
            [-1.632728206295935, 53.320822047008093],
            [-1.626840044791706, 53.325352085923733],
            [-1.627919862168171, 53.325830959184081],
            [-1.627265778373877, 53.328530029979071],
            [-1.626431081490052, 53.32920427207268],
            [-1.623576317835169, 53.330447435246548],
            [-1.623705279260605, 53.331149860518671],
            [-1.622086596693599, 53.331659802054787],
            [-1.622114004491734, 53.333657181207975],
            [-1.619512985173558, 53.335363968195082],
            [-1.616799417743668, 53.336367420045079],
            [-1.615736802457605, 53.337803097850909],
            [-1.614373033719952, 53.338818021199224],
            [-1.612141602678265, 53.341601787813715],
            [-1.612304331308154, 53.343201406478421],
            [-1.608812940934948, 53.343286199969015],
            [-1.607645348523291, 53.342690912902803],
            [-1.604249110123591, 53.34239746092851],
            [-1.601576819460973, 53.34259171990729],
            [-1.591465779595186, 53.344930576414882],
            [-1.590549226896974, 53.345903610766022],
            [-1.615628740433287, 53.350831869376009],
            [-1.625605102167784, 53.352044672713824],
            [-1.628349720654329, 53.353781768271091],
            [-1.630593364444425, 53.356143762353476],
            [-1.657878126332849, 53.364158422666719],
            [-1.663951794482293, 53.36687585403935],
            [-1.663914584431054, 53.369314369978319],
            [-1.662709076391958, 53.373145528579563],
            [-1.663292717401755, 53.374231205000562],
            [-1.66219356447088, 53.375233032205536],
            [-1.661777962568089, 53.376276336195708],
            [-1.65602240158842, 53.381691736443926],
            [-1.653531430682, 53.38483505376022],
            [-1.653818503305987, 53.391893751522161],
            [-1.654717709439673, 53.393010042615458],
            [-1.659008642835764, 53.39347808682804],
            [-1.665113567254549, 53.3935178229736],
            [-1.668090092572683, 53.393054225294208],
            [-1.672980670382564, 53.39692561864247],
            [-1.68182949810261, 53.40176379788015],
            [-1.694522043957523, 53.401144341523086],
            [-1.704966122046464, 53.405039266543952],
            [-1.703974137087721, 53.40579724799381],
            [-1.704126978453693, 53.408268587257211],
            [-1.7030017850923, 53.410645972039575],
            [-1.703851610132499, 53.412616578194203],
            [-1.703234745455282, 53.413705362068526],
            [-1.703945647434736, 53.414891818342603],
            [-1.708532829993251, 53.417322812971818],
            [-1.710881830820495, 53.417495704397147],
            [-1.714019005752319, 53.419053777264956],
            [-1.716778531946102, 53.419281465240807],
            [-1.718319960164114, 53.419410048017774],
            [-1.723348851778339, 53.418899555819038],
            [-1.725153630480178, 53.417621950060862],
            [-1.729951734111717, 53.415969102000354],
            [-1.73239963378104, 53.41716738828147],
            [-1.735075762846046, 53.419675762529934],
            [-1.739762442467783, 53.420971418683827],
            [-1.74500926463361, 53.425273856218894],
            [-1.746719624796672, 53.42614848127473],
            [-1.746258530348028, 53.42776633998929],
            [-1.744722148030937, 53.428741921800558],
            [-1.744122786915984, 53.429556800022297],
            [-1.74427349097144, 53.431280224881277],
            [-1.744897408428193, 53.432402427785838],
            [-1.748561675502307, 53.435585836489942],
            [-1.749728664640724, 53.43910279778158],
            [-1.749313272574282, 53.439856064732744],
            [-1.747114734610171, 53.440984888387817],
            [-1.746819611174786, 53.44175547893289],
            [-1.745465251942783, 53.442672129464619],
            [-1.743674798119412, 53.444997229927345],
            [-1.743908913257836, 53.446375670605867],
            [-1.744660742179793, 53.447293207472377],
            [-1.744276001853159, 53.448430330830483],
            [-1.746570429528063, 53.451256706967882],
            [-1.746213326146367, 53.452054128926456],
            [-1.747127815177791, 53.454260945314921],
            [-1.746658227422175, 53.455485084031572],
            [-1.748166911898533, 53.457313833665964],
            [-1.747081992019481, 53.457921863339628],
            [-1.74702291068864, 53.459248440031658],
            [-1.746217283993832, 53.459986486356449],
            [-1.746487165124511, 53.460697149431191],
            [-1.74526408025133, 53.461975410746774],
            [-1.746825080339238, 53.463393512757058],
            [-1.747827369685918, 53.464046396255959],
            [-1.752154311109976, 53.464907547788805],
            [-1.753657989538038, 53.464835150504577],
            [-1.754198900764932, 53.464285269166382],
            [-1.755711842614715, 53.463906357801406],
            [-1.758188276425431, 53.463874536543003],
            [-1.759398141882837, 53.46354440215903],
            [-1.764690355186467, 53.463936040747683],
            [-1.768437965662989, 53.464750519036031],
            [-1.769951096901051, 53.465710711296452],
            [-1.770495908153138, 53.466916213639252],
            [-1.772724841215529, 53.46700136498594],
            [-1.776200640357104, 53.468309448428137],
            [-1.777040629307463, 53.469858831598522],
            [-1.778040906185232, 53.470437751222569],
            [-1.777970091826618, 53.472462721708993],
            [-1.779063840905037, 53.474204913203124],
            [-1.780809152737342, 53.47495326674764],
            [-1.785703425972936, 53.475702781420374],
            [-1.786446549805888, 53.475333785403301],
            [-1.790707159760973, 53.475674785221628],
            [-1.790901473929633, 53.475990619849441],
            [-1.791107485003625, 53.475765369331235],
            [-1.792331011685861, 53.476667243662824],
            [-1.792765782539782, 53.476493621305828],
            [-1.793421310521003, 53.477089790635446],
            [-1.79432007077696, 53.477243243708955],
            [-1.795319563310984, 53.478383794763275],
            [-1.796847898724236, 53.478912223616391],
            [-1.798328745097087, 53.478658557397971],
            [-1.801471587657896, 53.480975642183076],
            [-1.7919736208548, 53.481973406065187],
            [-1.790453631310101, 53.482494780779895],
            [-1.787261735147895, 53.484363240539082],
            [-1.785520689904253, 53.484762815220101],
            [-1.783648925825299, 53.484782815830101],
            [-1.780415663703467, 53.484353570707654],
            [-1.773703037113454, 53.482486747233672],
            [-1.771054321072335, 53.48241159873929],
            [-1.765992572286706, 53.481058931518326],
            [-1.763980560928301, 53.481398338651935],
            [-1.762900347176286, 53.481091494326805],
            [-1.761899008806257, 53.481233321182479],
            [-1.76051694410698, 53.480747882778914],
            [-1.757460964285332, 53.481033851888014],
            [-1.754143711482715, 53.480631589234953],
            [-1.751105690303079, 53.479901736841533],
            [-1.748987484679842, 53.479840684235654],
            [-1.74631202845314, 53.478977541793775],
            [-1.744799287557787, 53.478968032613835],
            [-1.743331062028409, 53.479322632553419],
            [-1.742296182424106, 53.47902738396138],
            [-1.741642525911738, 53.478235888161869],
            [-1.739694574340419, 53.477206081650031],
            [-1.738708112856394, 53.477135618831888],
            [-1.736404322490724, 53.479331840200281],
            [-1.736600405468893, 53.480760538870527],
            [-1.737701405826114, 53.481574615877506],
            [-1.736223315157615, 53.483740281353072],
            [-1.735409759928171, 53.486618386090498],
            [-1.733612947763262, 53.488129852089905],
            [-1.731813312872087, 53.491025505582527],
            [-1.730412574296251, 53.491350436003053],
            [-1.722836091322224, 53.491541656723413],
            [-1.721427107701375, 53.491961740359315],
            [-1.721567423824368, 53.492630807502806],
            [-1.719805021372126, 53.492465799923494],
            [-1.719536698301307, 53.492928076295968],
            [-1.717287100785506, 53.493949262442484],
            [-1.716050022498956, 53.494112619181003],
            [-1.715658017477361, 53.495481527289215],
            [-1.713695203874307, 53.496636355796198],
            [-1.714027344912411, 53.497905419291477],
            [-1.712491312297979, 53.498566882794911],
            [-1.712821867585958, 53.499621121756917],
            [-1.711903944370554, 53.499822053813894],
            [-1.711038039931583, 53.500797008976683],
            [-1.711181608471493, 53.501189251469313],
            [-1.707491559164323, 53.501342079304457],
            [-1.706101908659079, 53.501731468697578],
            [-1.705306940913712, 53.501147062612887],
            [-1.704409007655415, 53.501715613483327],
            [-1.703303031212558, 53.501847702670062],
            [-1.70297153085791, 53.502903021527459],
            [-1.701133689336312, 53.503124953654044],
            [-1.698463116541957, 53.502566363178595],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000021",
        LAD13CDO: "00CJ",
        LAD13NM: "Newcastle upon Tyne",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.647667336489286, 55.079382325384493],
            [-1.645559733449007, 55.076824163331295],
            [-1.641792415051735, 55.07074483313913],
            [-1.639742676980991, 55.069159033043107],
            [-1.637879748663222, 55.064761996878858],
            [-1.6393111438801, 55.064380737358036],
            [-1.639619478763422, 55.063521677874611],
            [-1.63785945461583, 55.062672663428557],
            [-1.636960522222352, 55.061659948400376],
            [-1.632225373400701, 55.048231245685031],
            [-1.63427973890716, 55.047956163288632],
            [-1.638775626531243, 55.045417510254175],
            [-1.639700063771537, 55.044075021172702],
            [-1.638494351155924, 55.041544543602605],
            [-1.626186410677124, 55.041418436431442],
            [-1.626055205125041, 55.040215685684977],
            [-1.624557959316451, 55.040019675330271],
            [-1.623826533057936, 55.039923962879492],
            [-1.623658396704264, 55.040333212510085],
            [-1.622417583216195, 55.040158635914786],
            [-1.622919491494464, 55.03903332582486],
            [-1.622382009078561, 55.03894898821919],
            [-1.619447819090998, 55.040465707897212],
            [-1.616574814539291, 55.041287020542484],
            [-1.610332795594732, 55.040466546978742],
            [-1.597549557480294, 55.039559617414319],
            [-1.593948071330447, 55.039342780882116],
            [-1.593722256653343, 55.038926866740297],
            [-1.592623738470976, 55.038933082745508],
            [-1.592089783513692, 55.034654774055184],
            [-1.590712553749718, 55.030853491436368],
            [-1.587320648168131, 55.025998489000955],
            [-1.587762947185247, 55.025425768992918],
            [-1.591790908204872, 55.024647652368074],
            [-1.594435018411518, 55.023500861265973],
            [-1.596733064051248, 55.020663462085217],
            [-1.599049084513578, 55.017559187685734],
            [-1.601011802442723, 55.012358127324838],
            [-1.600887654057481, 55.010844442499746],
            [-1.600251932937054, 55.009831408206573],
            [-1.587950738509251, 55.008631181305084],
            [-1.583906333115438, 55.008879825605547],
            [-1.579199865637037, 55.009784739496162],
            [-1.577121878852634, 55.005317676364804],
            [-1.574425849313746, 55.00567492108199],
            [-1.574686777942049, 55.006578050260444],
            [-1.572603459429547, 55.00679541098318],
            [-1.571966488502818, 55.003915782019916],
            [-1.567471025568871, 55.004709554742526],
            [-1.56380113208061, 55.005913198492053],
            [-1.561591737572866, 55.00621978135819],
            [-1.558489477701384, 55.00553640099254],
            [-1.558917523455278, 55.000006921173373],
            [-1.560588696621352, 54.995849635915711],
            [-1.562967061773744, 54.992310424896722],
            [-1.562556845410307, 54.992233466969026],
            [-1.56123713133899, 54.991428042105632],
            [-1.558619958679978, 54.992789883649685],
            [-1.556344215285868, 54.993363920484725],
            [-1.552275957736889, 54.989695212544937],
            [-1.550192716642378, 54.990532241193101],
            [-1.549937255372887, 54.990316525279496],
            [-1.549324511014161, 54.9904652274939],
            [-1.547903168328875, 54.992613964014296],
            [-1.546961825520598, 54.992098247471986],
            [-1.545768130972733, 54.989017800830695],
            [-1.54042905219142, 54.989883806559554],
            [-1.538372218562989, 54.987033666340153],
            [-1.539013347929237, 54.986725171075115],
            [-1.538819319230789, 54.985655968132605],
            [-1.539775168385028, 54.984470701757942],
            [-1.539972809110505, 54.983311320560198],
            [-1.537884644870169, 54.982950249688201],
            [-1.533686469029464, 54.985742450268418],
            [-1.530831344855545, 54.9840079102811],
            [-1.532405629158133, 54.982869116178151],
            [-1.533118034658641, 54.981532894905087],
            [-1.53363419483989, 54.981352452216065],
            [-1.533389786382067, 54.981139439181725],
            [-1.534072558788141, 54.981008159195291],
            [-1.533604525626086, 54.980811364253512],
            [-1.534242657320569, 54.980621501401309],
            [-1.533886464038412, 54.980346954779414],
            [-1.534922166766486, 54.978607577391791],
            [-1.535019511293123, 54.97751159889021],
            [-1.536914749009067, 54.965385550768687],
            [-1.537500262210125, 54.96445050582944],
            [-1.537820475574558, 54.964583821155237],
            [-1.540738967080848, 54.962995308341931],
            [-1.543403898277374, 54.962124678976991],
            [-1.544068192463549, 54.962352726097748],
            [-1.545671539455943, 54.961990284423742],
            [-1.551612197513667, 54.960051507812992],
            [-1.554017784179778, 54.959998342848209],
            [-1.560808370334604, 54.963115227736466],
            [-1.565460917424969, 54.964569737727878],
            [-1.567207389240711, 54.96512232815757],
            [-1.570199899026121, 54.965420495312088],
            [-1.577168481617583, 54.965178913182129],
            [-1.580646885588503, 54.966070706713722],
            [-1.588036289854985, 54.970808350449566],
            [-1.588490369296931, 54.972079647342817],
            [-1.589508986166578, 54.97237693830229],
            [-1.590159882453763, 54.973349649314862],
            [-1.591277254328072, 54.973772166995275],
            [-1.591652954454768, 54.974620833345206],
            [-1.590778245383796, 54.975067209019315],
            [-1.591106940681389, 54.975775533045876],
            [-1.592804632017569, 54.976290745847955],
            [-1.591024477887562, 54.975588341609246],
            [-1.590957963861617, 54.975061522744596],
            [-1.591813505490271, 54.974656418340643],
            [-1.591644982375918, 54.973712294678457],
            [-1.590249172925538, 54.973172021765983],
            [-1.589677459327089, 54.972247205431884],
            [-1.588731721439046, 54.972003181183879],
            [-1.588421369898791, 54.97057690873757],
            [-1.592467518197308, 54.971162051590035],
            [-1.596807652614122, 54.970948255594429],
            [-1.606488061119456, 54.968386501566528],
            [-1.608166203488738, 54.96800910070057],
            [-1.615584987313803, 54.965133787781994],
            [-1.61542844241777, 54.964843935156914],
            [-1.619614787840729, 54.962801930520193],
            [-1.622335733476812, 54.961602649055806],
            [-1.625937690633176, 54.960530927146998],
            [-1.634096444145771, 54.960139666377721],
            [-1.639441830516127, 54.960343433532721],
            [-1.644012660305367, 54.960901457468267],
            [-1.656270223225806, 54.963503171006963],
            [-1.659341689756299, 54.964130959973943],
            [-1.664263585834489, 54.964628096933744],
            [-1.683705156285452, 54.965876240603642],
            [-1.691327629861294, 54.968069581958368],
            [-1.692839675101489, 54.968883966016037],
            [-1.693478726956365, 54.96968085940545],
            [-1.69776240639476, 54.971067363760426],
            [-1.706783293082117, 54.973894928368352],
            [-1.708357425424512, 54.973975092437179],
            [-1.709795747881685, 54.974475468111052],
            [-1.711988782557665, 54.974305444382104],
            [-1.71258627131824, 54.9739860479309],
            [-1.710209580771909, 54.974280554017618],
            [-1.702611713577153, 54.972476666572717],
            [-1.703169482740933, 54.971618044969937],
            [-1.702562918089207, 54.971257114975835],
            [-1.705904446003541, 54.970806036584491],
            [-1.707630556143968, 54.969945718190047],
            [-1.709329890903625, 54.968239706144445],
            [-1.71099474331269, 54.96761912622506],
            [-1.714871866296842, 54.967575250106776],
            [-1.720223554817517, 54.968195136674638],
            [-1.723242514648538, 54.969159071423661],
            [-1.725426539117502, 54.970489486306221],
            [-1.725809061645933, 54.97120565252122],
            [-1.724917825858745, 54.973615546313837],
            [-1.726096481962158, 54.97534266063608],
            [-1.737403359710601, 54.979065401373269],
            [-1.73882674183653, 54.979543833580905],
            [-1.738797191389314, 54.980004763078007],
            [-1.739252151557139, 54.979719077886664],
            [-1.746886309925559, 54.982192915087687],
            [-1.750421370212567, 54.98285171173],
            [-1.750674239794862, 54.98314697735605],
            [-1.751028770972392, 54.982922148877421],
            [-1.752144018137171, 54.982990921816331],
            [-1.752442861101027, 54.983435448350917],
            [-1.752950600695308, 54.983451756002751],
            [-1.753314431711561, 54.982982514902005],
            [-1.75708284690605, 54.982546171563932],
            [-1.763420058042955, 54.981298781286803],
            [-1.766641198733866, 54.981155825199338],
            [-1.769835232600452, 54.981377572606192],
            [-1.772607890842429, 54.98760572556548],
            [-1.774252217184331, 54.988385191705362],
            [-1.775672133309261, 54.989623416840757],
            [-1.775007760134073, 54.994968974023898],
            [-1.773563120712692, 54.996151574837903],
            [-1.774161015093638, 55.002696420251525],
            [-1.773647995148195, 55.002740398972186],
            [-1.773829334883503, 55.004132693972139],
            [-1.757595983324101, 55.002951242459332],
            [-1.756549824653476, 55.00325648337499],
            [-1.756863118161158, 55.007811301877041],
            [-1.756330777379119, 55.015937325189221],
            [-1.751043887150858, 55.015607627592907],
            [-1.750231663352851, 55.024014309304413],
            [-1.742663878030084, 55.024072246779802],
            [-1.740018758434797, 55.024574348632427],
            [-1.739684630646141, 55.0252053614592],
            [-1.740076894678619, 55.025501843205213],
            [-1.739273675886625, 55.026827380930115],
            [-1.737968101899759, 55.026976445980338],
            [-1.739165118994732, 55.028370069673976],
            [-1.739307391909571, 55.029588895142346],
            [-1.735088504627907, 55.029551938402363],
            [-1.732315063907326, 55.029991589433749],
            [-1.731887656163424, 55.031682740205454],
            [-1.728680012274766, 55.032220199794139],
            [-1.727461747377463, 55.032612871059953],
            [-1.726908984702497, 55.03314630873043],
            [-1.724935017280917, 55.03307986405914],
            [-1.721513161130113, 55.033569024577687],
            [-1.721592644479665, 55.034065241161009],
            [-1.714433584400408, 55.034017220902747],
            [-1.716929488776136, 55.038619454616722],
            [-1.711144726464638, 55.039482925140568],
            [-1.711564042901816, 55.041232619979048],
            [-1.708605703715176, 55.041343299572503],
            [-1.708639781770889, 55.042254574830139],
            [-1.707337553801091, 55.042074427278266],
            [-1.703706988488854, 55.04229659674769],
            [-1.7007793094498, 55.04289960088326],
            [-1.700196487824094, 55.043619754666651],
            [-1.694901643112, 55.042886822577813],
            [-1.693971059314095, 55.046104219951523],
            [-1.695032926832044, 55.048681407678203],
            [-1.694619915144873, 55.049481037069988],
            [-1.695436465469266, 55.050573996024582],
            [-1.696704381841326, 55.051181924336142],
            [-1.697913167758829, 55.052372891927945],
            [-1.71052655930751, 55.052744079884917],
            [-1.713746405802325, 55.065473313972667],
            [-1.704093939249768, 55.065821388652971],
            [-1.704599701362858, 55.070926716593057],
            [-1.701085937824327, 55.070883104828425],
            [-1.701003576838953, 55.071218084007917],
            [-1.699282013511395, 55.071079057587497],
            [-1.696829835578015, 55.070848330886086],
            [-1.696708542006865, 55.070528124254402],
            [-1.691324659319986, 55.069720238145159],
            [-1.690612883041471, 55.070438219727379],
            [-1.691105060736497, 55.072819883937882],
            [-1.685820662086285, 55.073482131861283],
            [-1.679703625513866, 55.075036081653813],
            [-1.661833584053242, 55.078406123510483],
            [-1.654554786469517, 55.079163907998108],
            [-1.651003170111816, 55.079072915908533],
            [-1.647667336489286, 55.079382325384493],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000022",
        LAD13CDO: "00CK",
        LAD13NM: "North Tyneside",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.467358617443941, 55.07492830170618],
            [-1.466632636161214, 55.074403029185348],
            [-1.46405810359037, 55.07462177867977],
            [-1.462167362134988, 55.0741839083583],
            [-1.461758418422717, 55.074314199455536],
            [-1.460447818273578, 55.073712619270346],
            [-1.458782535530428, 55.073517422616753],
            [-1.459012065760633, 55.07275551498703],
            [-1.457826620711971, 55.071243262618069],
            [-1.455899262443732, 55.070173402227667],
            [-1.452951930630504, 55.06984029637561],
            [-1.451373653387531, 55.070053361557775],
            [-1.452100222620512, 55.068565821724363],
            [-1.451949969243516, 55.066840695195758],
            [-1.454247530231249, 55.065770867910182],
            [-1.455083994442425, 55.064132833755522],
            [-1.45488011089271, 55.062198091470762],
            [-1.453556302913708, 55.060418284384035],
            [-1.451818910493433, 55.05545817913471],
            [-1.450423888366087, 55.053646562954015],
            [-1.448954743836905, 55.051314292537448],
            [-1.445777182438331, 55.048252554348892],
            [-1.441517319095249, 55.044742938356904],
            [-1.438927211456215, 55.043850383342367],
            [-1.436604882774985, 55.042395574570179],
            [-1.431801681747005, 55.04088424077424],
            [-1.431375310116322, 55.040272983435791],
            [-1.432361328746742, 55.039041974165578],
            [-1.432238459964303, 55.038546259195186],
            [-1.431485737224279, 55.038220141454858],
            [-1.42940201703252, 55.038184336549399],
            [-1.428548933001295, 55.037875702805373],
            [-1.429149439530696, 55.03767992288973],
            [-1.429295443425043, 55.037205234482911],
            [-1.430734908945439, 55.037098744722876],
            [-1.431746052633951, 55.035746542972291],
            [-1.430586798793396, 55.034853286433645],
            [-1.431360896687126, 55.035432922226725],
            [-1.432418295067204, 55.034346025141112],
            [-1.431546799367982, 55.033794697375072],
            [-1.431722295328049, 55.033444153847306],
            [-1.428553811192238, 55.033023148311862],
            [-1.429632821383711, 55.031847412412432],
            [-1.429875756395948, 55.030493421989725],
            [-1.427081341190589, 55.026347523603505],
            [-1.423958759180168, 55.023639606092182],
            [-1.422407870316402, 55.022824393094773],
            [-1.422298752195445, 55.023016181746023],
            [-1.421254866048511, 55.023057953222811],
            [-1.420940100244918, 55.022758112324922],
            [-1.419857600798261, 55.022983008231293],
            [-1.420119897228713, 55.020197612440455],
            [-1.420663696981364, 55.01958014766798],
            [-1.419703357738111, 55.018824321209031],
            [-1.418857001218942, 55.018516548077017],
            [-1.417124386722681, 55.01882188728753],
            [-1.415550214558122, 55.018554637491711],
            [-1.415353761616882, 55.01679238192888],
            [-1.402784422850095, 55.01449288906651],
            [-1.416069851308452, 55.016696969250582],
            [-1.417250995278888, 55.016669379065441],
            [-1.417118904027296, 55.015640715301068],
            [-1.416670785042268, 55.015699675885131],
            [-1.416264465293932, 55.015249312569139],
            [-1.416709966015117, 55.014400445946997],
            [-1.417806205242612, 55.014075896108835],
            [-1.422593363829331, 55.013723066913926],
            [-1.425587158668771, 55.014001425355282],
            [-1.429826728561743, 55.013220675691123],
            [-1.432240273339306, 55.010516298890956],
            [-1.431836025440734, 55.009800002951884],
            [-1.432520089213992, 55.009857111221102],
            [-1.432826551440618, 55.009192655226506],
            [-1.435951939848559, 55.008207900178213],
            [-1.435009059075073, 55.008767867060484],
            [-1.435029675518597, 55.009414975600571],
            [-1.436639983084101, 55.008425858380484],
            [-1.441047852879008, 55.006952654752688],
            [-1.442052594427805, 55.00643246441539],
            [-1.442875509518056, 55.006508123473481],
            [-1.443539316038207, 55.004975402477754],
            [-1.444967752395944, 55.003429092249561],
            [-1.445902561826831, 55.001166108777745],
            [-1.445506957298616, 55.000945940428601],
            [-1.446381812510619, 54.997921539852605],
            [-1.446969864656426, 54.997558470497701],
            [-1.447480757082225, 54.997691091879588],
            [-1.447582865473194, 54.997199106048718],
            [-1.448667608032682, 54.996634292548414],
            [-1.45101993503107, 54.996302546239797],
            [-1.449664425476144, 54.996210156831175],
            [-1.449932135834438, 54.995950764164178],
            [-1.447414612044315, 54.995906112167063],
            [-1.448702498206464, 54.993061496251805],
            [-1.453288720944669, 54.989807571940545],
            [-1.453635702485964, 54.989920560220725],
            [-1.456496615794109, 54.988581828169139],
            [-1.458109348580103, 54.988935890518569],
            [-1.45867472939838, 54.988614000670736],
            [-1.460775951288963, 54.988553234581481],
            [-1.471801433959789, 54.989154238198289],
            [-1.470695400550156, 54.989833290896847],
            [-1.470552871635672, 54.990569547560661],
            [-1.469645671595253, 54.990862145930045],
            [-1.470260765611391, 54.991032868256958],
            [-1.47135923276972, 54.990813885176259],
            [-1.471395462849119, 54.990554338501347],
            [-1.474611274410891, 54.990120744191657],
            [-1.47448850143067, 54.989708641741331],
            [-1.473548309911034, 54.989659647360952],
            [-1.473241502138723, 54.988980752989931],
            [-1.4819872008484, 54.988238277431527],
            [-1.48391170467981, 54.988182650029046],
            [-1.484000154362412, 54.988963035464266],
            [-1.484583821297689, 54.989027513850225],
            [-1.485454263410902, 54.989031196645151],
            [-1.485483252285936, 54.9882306403481],
            [-1.497968753426937, 54.988573933754466],
            [-1.498045695378211, 54.989672375450766],
            [-1.498364087535406, 54.988578259562296],
            [-1.499376952748003, 54.988564457061173],
            [-1.499383686812467, 54.989028177202108],
            [-1.499620245196703, 54.988604997133059],
            [-1.499793280938932, 54.988891472294469],
            [-1.499868784644552, 54.988600626838057],
            [-1.501768590769023, 54.988519453876386],
            [-1.501877547821467, 54.98956500489998],
            [-1.504765330661179, 54.992513505316467],
            [-1.507606559934525, 54.993680666811485],
            [-1.514088059626425, 54.995642355051437],
            [-1.5144878264112, 54.995885344713443],
            [-1.517734107814674, 54.996344303725898],
            [-1.514620880919477, 54.995787362422718],
            [-1.514272224103119, 54.995677813881358],
            [-1.508731915646297, 54.993936829430794],
            [-1.504794896316579, 54.992270097414917],
            [-1.502656896525168, 54.988720785024903],
            [-1.505497671652524, 54.988626321027098],
            [-1.506357768768042, 54.988326075810463],
            [-1.517376940044863, 54.98806922539255],
            [-1.523337501400615, 54.987002677417173],
            [-1.523692777817274, 54.987221536136069],
            [-1.52353795840642, 54.986966618508276],
            [-1.528124804962401, 54.985348085524464],
            [-1.528313266540941, 54.985667828923503],
            [-1.530888931489954, 54.984832174107936],
            [-1.530831344855545, 54.9840079102811],
            [-1.533686469029464, 54.985742450268418],
            [-1.537884644870169, 54.982950249688201],
            [-1.539972809110505, 54.983311320560198],
            [-1.539775168385028, 54.984470701757942],
            [-1.538819319230789, 54.985655968132605],
            [-1.539013347929237, 54.986725171075115],
            [-1.538372218562989, 54.987033666340153],
            [-1.54042905219142, 54.989883806559554],
            [-1.545768130972733, 54.989017800830695],
            [-1.546961825520598, 54.992098247471986],
            [-1.547903168328875, 54.992613964014296],
            [-1.549324511014161, 54.9904652274939],
            [-1.549937255372887, 54.990316525279496],
            [-1.550192716642378, 54.990532241193101],
            [-1.552275957736889, 54.989695212544937],
            [-1.556344215285868, 54.993363920484725],
            [-1.558619958679978, 54.992789883649685],
            [-1.56123713133899, 54.991428042105632],
            [-1.562556845410307, 54.992233466969026],
            [-1.562967061773744, 54.992310424896722],
            [-1.560588696621352, 54.995849635915711],
            [-1.558917523455278, 55.000006921173373],
            [-1.558489477701384, 55.00553640099254],
            [-1.561591737572866, 55.00621978135819],
            [-1.56380113208061, 55.005913198492053],
            [-1.567471025568871, 55.004709554742526],
            [-1.571966488502818, 55.003915782019916],
            [-1.572603459429547, 55.00679541098318],
            [-1.574686777942049, 55.006578050260444],
            [-1.574425849313746, 55.00567492108199],
            [-1.577121878852634, 55.005317676364804],
            [-1.579199865637037, 55.009784739496162],
            [-1.583906333115438, 55.008879825605547],
            [-1.587950738509251, 55.008631181305084],
            [-1.600251932937054, 55.009831408206573],
            [-1.600887654057481, 55.010844442499746],
            [-1.601011802442723, 55.012358127324838],
            [-1.599049084513578, 55.017559187685734],
            [-1.596733064051248, 55.020663462085217],
            [-1.594435018411518, 55.023500861265973],
            [-1.591790908204872, 55.024647652368074],
            [-1.587762947185247, 55.025425768992918],
            [-1.587320648168131, 55.025998489000955],
            [-1.590712553749718, 55.030853491436368],
            [-1.592089783513692, 55.034654774055184],
            [-1.592623738470976, 55.038933082745508],
            [-1.593722256653343, 55.038926866740297],
            [-1.593948071330447, 55.039342780882116],
            [-1.597549557480294, 55.039559617414319],
            [-1.610332795594732, 55.040466546978742],
            [-1.616574814539291, 55.041287020542484],
            [-1.619447819090998, 55.040465707897212],
            [-1.622382009078561, 55.03894898821919],
            [-1.622919491494464, 55.03903332582486],
            [-1.622417583216195, 55.040158635914786],
            [-1.623658396704264, 55.040333212510085],
            [-1.623826533057936, 55.039923962879492],
            [-1.624557959316451, 55.040019675330271],
            [-1.626055205125041, 55.040215685684977],
            [-1.626186410677124, 55.041418436431442],
            [-1.638494351155924, 55.041544543602605],
            [-1.639700063771537, 55.044075021172702],
            [-1.638775626531243, 55.045417510254175],
            [-1.63427973890716, 55.047956163288632],
            [-1.632225373400701, 55.048231245685031],
            [-1.636960522222352, 55.061659948400376],
            [-1.63785945461583, 55.062672663428557],
            [-1.639619478763422, 55.063521677874611],
            [-1.6393111438801, 55.064380737358036],
            [-1.637879748663222, 55.064761996878858],
            [-1.636256794403546, 55.06519928052446],
            [-1.633946489424382, 55.065114183508236],
            [-1.632860174844615, 55.065934044192026],
            [-1.631217724809739, 55.06558491651721],
            [-1.631041678557186, 55.065147658162296],
            [-1.621254266199615, 55.066451172712775],
            [-1.61510607584073, 55.064735328446083],
            [-1.612618745662786, 55.064342840419613],
            [-1.612211564337193, 55.064514078997071],
            [-1.611818179835364, 55.064229763417927],
            [-1.609485209985206, 55.064553897468592],
            [-1.609166875059792, 55.064282394367055],
            [-1.60759569761183, 55.064358220132796],
            [-1.605834410885883, 55.064818915863384],
            [-1.605638597117482, 55.065147174694189],
            [-1.603890727248462, 55.065194523756148],
            [-1.603741083273185, 55.064809430809596],
            [-1.601350056695409, 55.065135916171521],
            [-1.600634475128187, 55.064823551486199],
            [-1.599814740065894, 55.064908027641131],
            [-1.598984736685946, 55.064296039135975],
            [-1.595707888030457, 55.063649891325142],
            [-1.594882060599893, 55.06402905932476],
            [-1.592100965884756, 55.064218375043012],
            [-1.589846762134403, 55.064020302164487],
            [-1.588511260760388, 55.063402944870319],
            [-1.584261073919188, 55.062427899297361],
            [-1.581416565228104, 55.062998665643256],
            [-1.57903687676335, 55.062983278599923],
            [-1.578525508411126, 55.063074068125502],
            [-1.577151941878237, 55.059252892479705],
            [-1.576840192460274, 55.056599098063202],
            [-1.575974507353873, 55.056450512636474],
            [-1.575949491738137, 55.056002915055345],
            [-1.575066031579569, 55.055612533661183],
            [-1.572332093217255, 55.05582941881768],
            [-1.570635264795099, 55.055236654660149],
            [-1.566622115600287, 55.054609589370095],
            [-1.559795012751206, 55.054516818065089],
            [-1.5561665598848, 55.054228676890617],
            [-1.551567423179091, 55.055782617115959],
            [-1.5475707248724, 55.056634107158423],
            [-1.544925022121289, 55.057885911259234],
            [-1.542921449564868, 55.058413087897215],
            [-1.542018478596803, 55.059070180087382],
            [-1.541840621796434, 55.059702137367488],
            [-1.541123223340245, 55.059734483558692],
            [-1.540568845103089, 55.061253751777876],
            [-1.539854270396694, 55.061583543158747],
            [-1.532325705686941, 55.054142185224826],
            [-1.526089607162718, 55.053855689561047],
            [-1.526267056242685, 55.054471034365882],
            [-1.519952233755405, 55.054745554567802],
            [-1.515071100686434, 55.054122351278622],
            [-1.515240734746246, 55.054982105529646],
            [-1.510270404344293, 55.055001765628276],
            [-1.508784529639141, 55.048119550262868],
            [-1.507371806779557, 55.048327716693699],
            [-1.503161781743038, 55.049832879816691],
            [-1.502858037222511, 55.050346549587672],
            [-1.501068756997788, 55.050492898174618],
            [-1.496495946034989, 55.05193254283364],
            [-1.492564039502216, 55.054406302630134],
            [-1.490258303438687, 55.054402966758985],
            [-1.487800165772907, 55.05358479708832],
            [-1.487251773972687, 55.055709522749993],
            [-1.490703005453653, 55.063399102682567],
            [-1.492272973569498, 55.065909204892229],
            [-1.491244358482894, 55.065899522397167],
            [-1.488447396177082, 55.065564308689424],
            [-1.486628184194494, 55.066034721678861],
            [-1.483615594629866, 55.066153188965231],
            [-1.482258477103844, 55.066967867895585],
            [-1.47963107080764, 55.067201997455278],
            [-1.477460871047855, 55.070321697277926],
            [-1.474250075704822, 55.071317931645432],
            [-1.474123795329653, 55.072349898472417],
            [-1.470703607422385, 55.074479191076406],
            [-1.467358617443941, 55.07492830170618],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000023",
        LAD13CDO: "00CL",
        LAD13NM: "South Tyneside",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.414485918203319, 55.004113037043076],
            [-1.41088080472268, 54.998875493847073],
            [-1.407435839773565, 54.99566862783005],
            [-1.403838808010514, 54.993479067717423],
            [-1.403073228654187, 54.993248859972326],
            [-1.40208708399953, 54.993767922684654],
            [-1.401452273743647, 54.993257973528344],
            [-1.400719365827415, 54.993248975344812],
            [-1.401316569551353, 54.992399111400225],
            [-1.399205193543066, 54.991654524102501],
            [-1.398002251998804, 54.991829205523501],
            [-1.395147124825101, 54.990973929104321],
            [-1.39402524191042, 54.991059110627944],
            [-1.394475199179777, 54.989927274037292],
            [-1.393618397992167, 54.989224769552862],
            [-1.39340769277537, 54.988169621594771],
            [-1.392104257665192, 54.988070558826884],
            [-1.388771949020336, 54.988922848532653],
            [-1.388102301709579, 54.988047808068927],
            [-1.386804256641732, 54.987903783829772],
            [-1.386354987795849, 54.987338075378261],
            [-1.385748819576919, 54.987428475520893],
            [-1.385580897657733, 54.986860589246213],
            [-1.385015475818509, 54.986841557959721],
            [-1.38553159787789, 54.986507176790766],
            [-1.385148068534264, 54.986141292562166],
            [-1.384068916436999, 54.986198740227536],
            [-1.382975096068191, 54.985887663513168],
            [-1.382306111928417, 54.986100841025909],
            [-1.381440598350147, 54.985882569832093],
            [-1.381409132052007, 54.985489706234546],
            [-1.380645285564869, 54.985672737450948],
            [-1.381230952240966, 54.985085313115619],
            [-1.380097336111417, 54.984520592746158],
            [-1.38059151076532, 54.984191512398652],
            [-1.380332954155646, 54.983527901625166],
            [-1.3817131698104, 54.982889701229901],
            [-1.382066061793413, 54.981990161881619],
            [-1.380898762293027, 54.980267834131759],
            [-1.378477554343779, 54.978708040295444],
            [-1.377645560837489, 54.977646991323269],
            [-1.372310165851596, 54.974731366874806],
            [-1.36842173947911, 54.9738305815358],
            [-1.367275523079167, 54.972906218705837],
            [-1.365313707810297, 54.973084715596187],
            [-1.36381965890264, 54.971835893814827],
            [-1.361997480132751, 54.971781421121698],
            [-1.361484773285452, 54.971111941838181],
            [-1.360726902096096, 54.971323635714981],
            [-1.36077596628647, 54.970889850973798],
            [-1.361723285251889, 54.970354742144742],
            [-1.360507857866477, 54.968886274632759],
            [-1.359897877049795, 54.968837236541404],
            [-1.360270729460867, 54.967883944061228],
            [-1.359545181129685, 54.967736344774551],
            [-1.359546483166237, 54.967359821748694],
            [-1.35853007782907, 54.966841344159455],
            [-1.357691472353709, 54.966732677742961],
            [-1.357418271131703, 54.966234287458576],
            [-1.358030957806677, 54.965816958424526],
            [-1.356616490043127, 54.965190324042318],
            [-1.356851109345094, 54.964778190165909],
            [-1.354981206476684, 54.961384910537454],
            [-1.355638460330533, 54.960912113922731],
            [-1.355112031960088, 54.958871207438413],
            [-1.353198192097892, 54.957559815025313],
            [-1.355474228335644, 54.955656890420947],
            [-1.355368121431178, 54.954480905236693],
            [-1.356920315335377, 54.953497918985363],
            [-1.356093880670943, 54.950495678205584],
            [-1.356669998086552, 54.949714211929233],
            [-1.358022561300418, 54.948214334817671],
            [-1.3598579431459, 54.947665052070001],
            [-1.360535002844572, 54.946516553890376],
            [-1.363156542421864, 54.945014297798238],
            [-1.363956043035359, 54.944110852213498],
            [-1.365701948270918, 54.94417119188747],
            [-1.366137893444374, 54.942855155957886],
            [-1.374335386079914, 54.943527547094206],
            [-1.375669690426887, 54.936901523625174],
            [-1.376978860430341, 54.937327903074021],
            [-1.37880782030911, 54.936943652618218],
            [-1.381072120531617, 54.938191728379351],
            [-1.384256752784252, 54.93867069315619],
            [-1.387519095360814, 54.93804643330067],
            [-1.389314147439325, 54.938144424977665],
            [-1.390853707334843, 54.938520588419102],
            [-1.391930914394405, 54.939438099854925],
            [-1.393379658663769, 54.939630454133656],
            [-1.398091339866503, 54.939572984203345],
            [-1.40266016405834, 54.938621381010563],
            [-1.40477872652221, 54.937849949386688],
            [-1.407034044626905, 54.936901216803619],
            [-1.410293621642849, 54.936431798269496],
            [-1.412458287056273, 54.935798847418923],
            [-1.416744259134484, 54.933955694957255],
            [-1.41831395125936, 54.93279048585164],
            [-1.419329232890517, 54.929912496248953],
            [-1.425938590278459, 54.930424646215087],
            [-1.426422810049434, 54.931161120506125],
            [-1.431786211176165, 54.929853612398617],
            [-1.432761407637329, 54.932858725461521],
            [-1.441447575266201, 54.932885446658197],
            [-1.442546084030605, 54.933576148636249],
            [-1.447897067124028, 54.933502604505883],
            [-1.449079962030158, 54.934080401549814],
            [-1.450054637703477, 54.933906881915945],
            [-1.454735011145201, 54.933453472914671],
            [-1.457373627549519, 54.93267626831679],
            [-1.460884394955049, 54.932025090484771],
            [-1.460626913307024, 54.930273399845923],
            [-1.466434007717819, 54.93013096884232],
            [-1.471954452084104, 54.929828855906145],
            [-1.480957241054797, 54.929818184098963],
            [-1.482009959054449, 54.928773961094841],
            [-1.483225523382781, 54.928412487576097],
            [-1.485129224733558, 54.928662295159903],
            [-1.488610874642789, 54.930613549071559],
            [-1.492441351396227, 54.930171288927831],
            [-1.496319148246627, 54.931056391837124],
            [-1.500260956426184, 54.930567617263378],
            [-1.505218732029306, 54.930956327453089],
            [-1.508312088703707, 54.930418007154273],
            [-1.510612099215049, 54.930822683380008],
            [-1.511204349826339, 54.931658101130452],
            [-1.510351634181451, 54.93209590199006],
            [-1.512353028874214, 54.936813694689704],
            [-1.516330272425165, 54.945371998742793],
            [-1.518049413556669, 54.947040396148751],
            [-1.518553324389649, 54.952167299246476],
            [-1.516280679013024, 54.954968310819105],
            [-1.515204283046125, 54.956050473877092],
            [-1.515316790573514, 54.957342257411071],
            [-1.516211485954127, 54.958130324385841],
            [-1.518969897592102, 54.959257362171869],
            [-1.519630567658825, 54.957547179023322],
            [-1.522399710497494, 54.957378353648259],
            [-1.526340750874977, 54.958076719787698],
            [-1.527768390305666, 54.957116239439301],
            [-1.53068639426247, 54.95797044632976],
            [-1.529333697490587, 54.96334712581708],
            [-1.531236394757594, 54.964386098846454],
            [-1.534159847452933, 54.965091068492249],
            [-1.532693259850282, 54.969666663237653],
            [-1.532631895150982, 54.973473922097519],
            [-1.531820812746541, 54.977664707320734],
            [-1.530067858513436, 54.980607251200354],
            [-1.528375326563022, 54.982105008674374],
            [-1.527849921656754, 54.982005021150314],
            [-1.527529453022829, 54.982550143661939],
            [-1.52619337746584, 54.982955623116496],
            [-1.526505928036523, 54.983218340265395],
            [-1.52491310869255, 54.984058641371014],
            [-1.523385189900984, 54.984163199208204],
            [-1.522879272363791, 54.984659057182142],
            [-1.519266227825051, 54.985461693816845],
            [-1.518484166517987, 54.985260904221114],
            [-1.518051507556529, 54.985635717136162],
            [-1.517037235260003, 54.985513077859565],
            [-1.516719559129923, 54.98581106019828],
            [-1.511025452546801, 54.986169336892772],
            [-1.510951318554955, 54.98596864486467],
            [-1.506395038592609, 54.986051797765548],
            [-1.50526598666292, 54.985861197600876],
            [-1.503934165223483, 54.98614783130256],
            [-1.493025346545263, 54.98623857511825],
            [-1.491978508266468, 54.985853191319869],
            [-1.481352374396476, 54.985838928106652],
            [-1.47932207743806, 54.985745788298232],
            [-1.475572488586747, 54.986518687325962],
            [-1.472291629605139, 54.986215147715129],
            [-1.47256575343248, 54.984376839156241],
            [-1.469469529463203, 54.980868605610837],
            [-1.470178335060406, 54.980080897758413],
            [-1.472921155585055, 54.979789968780295],
            [-1.476546623250603, 54.978665253236279],
            [-1.476970156502863, 54.977814272991139],
            [-1.47804546632638, 54.977422593165244],
            [-1.478809956890784, 54.975815521866018],
            [-1.480428750428122, 54.974948973957879],
            [-1.479361719121165, 54.975190638738994],
            [-1.478741987093162, 54.975755921061662],
            [-1.477924598908138, 54.977345691040433],
            [-1.476905079333881, 54.977652239624597],
            [-1.47647325573716, 54.978540027888606],
            [-1.472856515178493, 54.97959558443295],
            [-1.471380742927173, 54.979538859882894],
            [-1.470286041541025, 54.979851316948796],
            [-1.466927866526273, 54.979648839882223],
            [-1.463793961194088, 54.979033887679527],
            [-1.463004181510948, 54.979558800929063],
            [-1.459138674219805, 54.979210087887893],
            [-1.456900507296853, 54.9786870012801],
            [-1.456460039831345, 54.977172636385831],
            [-1.456651462826407, 54.978964465637176],
            [-1.463043136876511, 54.979683882642725],
            [-1.464312462517289, 54.97916826997362],
            [-1.467376916854351, 54.979837721360894],
            [-1.469716769763439, 54.980010591492103],
            [-1.469278331996363, 54.981149043604731],
            [-1.472229619675181, 54.984627897258285],
            [-1.471806727880542, 54.986131268973281],
            [-1.464749404429701, 54.983791854389132],
            [-1.460045676137847, 54.984509752054038],
            [-1.457370152243611, 54.98545759098927],
            [-1.451486181523404, 54.986409825113434],
            [-1.450692868572571, 54.98545369779302],
            [-1.450316293277097, 54.98602352731352],
            [-1.450266153095644, 54.984889227688008],
            [-1.449706973526132, 54.985327928848967],
            [-1.449590772062686, 54.984340705687458],
            [-1.44947104854265, 54.985325064635838],
            [-1.449081338904595, 54.985369131425415],
            [-1.448489075328756, 54.986168028540568],
            [-1.447524602768595, 54.98641616980494],
            [-1.449384574901757, 54.987191131270713],
            [-1.447613607748016, 54.989153805218741],
            [-1.446940956587951, 54.989427529011913],
            [-1.447094904148172, 54.989711297278426],
            [-1.444206319358078, 54.992911645997765],
            [-1.44355586014391, 54.993156696247553],
            [-1.443796303585566, 54.993401324168751],
            [-1.441075957571335, 54.995479976883871],
            [-1.439856822912641, 54.997608620362051],
            [-1.43982936162127, 55.000591047611927],
            [-1.440496207076085, 55.001875560598222],
            [-1.439790960662956, 55.003909510503107],
            [-1.43873376505197, 55.004717900524483],
            [-1.433248977792895, 55.006290252688153],
            [-1.43367654389629, 55.006460286173407],
            [-1.432966376600626, 55.006373407885249],
            [-1.429050549436883, 55.00731304488454],
            [-1.428276580424259, 55.00742443714676],
            [-1.428180097214265, 55.006842570625523],
            [-1.427494898616534, 55.006759372194104],
            [-1.426447953418136, 55.007703397136879],
            [-1.423816328095783, 55.008151967361165],
            [-1.423910420118053, 55.007914275775711],
            [-1.425249932150967, 55.007727401781331],
            [-1.424706340920077, 55.006269952490904],
            [-1.421404072044156, 55.004217107315597],
            [-1.418644483604542, 55.003255005830077],
            [-1.409555246448763, 55.008350528562389],
            [-1.403350019985582, 55.00978504244501],
            [-1.400921914972126, 55.011344823019627],
            [-1.401447730535591, 55.010476636358753],
            [-1.40253093832027, 55.009872685692386],
            [-1.409329594128267, 55.008171505081833],
            [-1.414114516234033, 55.005246220733646],
            [-1.414485918203319, 55.004113037043076],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000024",
        LAD13CDO: "00CM",
        LAD13NM: "Sunderland",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-1.35558267652454, 54.914501351536295],
              [-1.358226160798094, 54.913559172593637],
              [-1.358606427593487, 54.912992335390491],
              [-1.357299746631314, 54.911002119997939],
              [-1.354518342409166, 54.909458784983826],
              [-1.353968637441536, 54.909695804359401],
              [-1.35463043222979, 54.909276954450341],
              [-1.353540550361221, 54.907792887692366],
              [-1.350365418594244, 54.905831287151891],
              [-1.35374536349486, 54.907664571187532],
              [-1.354836576145066, 54.907602069452984],
              [-1.355198275700832, 54.906244333136804],
              [-1.355692723766318, 54.906143609965639],
              [-1.354204636969331, 54.904402220942011],
              [-1.351462520264275, 54.90530244849041],
              [-1.357245948690059, 54.902627331223172],
              [-1.35811056407026, 54.901506790879147],
              [-1.360372225112607, 54.900492454363686],
              [-1.360886958749723, 54.899893066633474],
              [-1.360766001400474, 54.898471666104328],
              [-1.361626511323504, 54.898289268921502],
              [-1.360266111998461, 54.894198652802224],
              [-1.360103254776423, 54.893947970875629],
              [-1.359572320302598, 54.893999992434459],
              [-1.360396854360979, 54.893819211819121],
              [-1.360732531237505, 54.892706651391322],
              [-1.359697460660146, 54.888959222356981],
              [-1.357977128386852, 54.885675462664807],
              [-1.356466313070903, 54.882955341799168],
              [-1.3540451797097, 54.879960769730296],
              [-1.35552551158946, 54.878614359280796],
              [-1.355566160067347, 54.875577128150091],
              [-1.354550265931222, 54.8734176648776],
              [-1.351247254804161, 54.868811549434518],
              [-1.347484965946871, 54.864810364103775],
              [-1.347297853890703, 54.863951143420479],
              [-1.348286543891563, 54.862250802401157],
              [-1.347434173989304, 54.860621458219214],
              [-1.349127735141806, 54.860417560668495],
              [-1.349426282905263, 54.860065089211851],
              [-1.35234316180263, 54.860610878962255],
              [-1.354063803837924, 54.86027046137135],
              [-1.355773183950318, 54.858972891700027],
              [-1.357329701030142, 54.858465306412931],
              [-1.359509390762701, 54.858245861646253],
              [-1.360167194669358, 54.857825162102955],
              [-1.360406559322835, 54.858055579503123],
              [-1.360893894038068, 54.857873919545554],
              [-1.363913655977306, 54.858216876672614],
              [-1.365324105796806, 54.857677870153395],
              [-1.366232396994687, 54.857868632919434],
              [-1.368851774534862, 54.857618063111616],
              [-1.37380291631816, 54.853955601396855],
              [-1.376719399533831, 54.851083218740165],
              [-1.378554614619483, 54.849986824396353],
              [-1.381649774841556, 54.847151632489805],
              [-1.38390534944382, 54.848138133363619],
              [-1.387143827502183, 54.846550413293436],
              [-1.392521891138143, 54.845676972028613],
              [-1.393398289341327, 54.846219645723679],
              [-1.396449774449602, 54.845857408799837],
              [-1.397280495204987, 54.846438469547074],
              [-1.39948517952562, 54.846364913799221],
              [-1.399990862858736, 54.846604657223814],
              [-1.404736733336375, 54.845496597414694],
              [-1.407496056815753, 54.845720371432485],
              [-1.409173361069133, 54.84517946232711],
              [-1.413566450742323, 54.844852968592249],
              [-1.411897390681755, 54.843117686290235],
              [-1.411993056905, 54.8418923842883],
              [-1.413762309809772, 54.841760744947642],
              [-1.413908970631836, 54.841418166475592],
              [-1.416690978336005, 54.84091662735996],
              [-1.417337494566921, 54.841002406218109],
              [-1.419167322713005, 54.839792593135051],
              [-1.421523126844301, 54.839466838649976],
              [-1.421594298365759, 54.838082350513524],
              [-1.420689168435452, 54.833659349473336],
              [-1.416482767302209, 54.830627819200167],
              [-1.416037843588131, 54.8294205827737],
              [-1.416808910277483, 54.828735916545284],
              [-1.415495434588476, 54.827420260089809],
              [-1.415652728304881, 54.826336338103701],
              [-1.414200689740937, 54.825891697549082],
              [-1.413111909939634, 54.824549239420733],
              [-1.412999323805152, 54.823296865524156],
              [-1.413246390381315, 54.822143284356606],
              [-1.414355360797145, 54.821224816797759],
              [-1.414360267344318, 54.819378997129235],
              [-1.415825786655827, 54.818441565659356],
              [-1.417835155296919, 54.818205880736002],
              [-1.419204563534843, 54.814195435046614],
              [-1.421712046777225, 54.813277289810046],
              [-1.421549682325768, 54.812116348226233],
              [-1.422899377267684, 54.808247752467679],
              [-1.423881141380881, 54.805400968086367],
              [-1.422667250616033, 54.804011266616179],
              [-1.422529954075982, 54.803165873407892],
              [-1.424317064407343, 54.803079101475269],
              [-1.429313287695186, 54.801015099507218],
              [-1.43021577530488, 54.8015495472129],
              [-1.43751798615471, 54.800517783568885],
              [-1.440695076335327, 54.799679631904667],
              [-1.441299410218925, 54.800524458091637],
              [-1.440246573812678, 54.801518918331766],
              [-1.442864675405818, 54.801966809048722],
              [-1.444009035966236, 54.802460924867553],
              [-1.448254007296486, 54.800997500680118],
              [-1.454589047216083, 54.800012434575471],
              [-1.455048762922255, 54.800528531476722],
              [-1.456408944864252, 54.800480708856888],
              [-1.456020576447902, 54.800905832435973],
              [-1.460161972571118, 54.801684580139501],
              [-1.468015646856259, 54.800297565081806],
              [-1.472336906367102, 54.799938984047188],
              [-1.477865154110388, 54.800063531314777],
              [-1.480037508771397, 54.799754728288079],
              [-1.481892915269367, 54.799049125124675],
              [-1.485079944805062, 54.799672870404883],
              [-1.490714058457527, 54.799294931929083],
              [-1.493495934160204, 54.799988648442536],
              [-1.494318181420216, 54.80116032930799],
              [-1.491086675465678, 54.804305607102414],
              [-1.485011600426323, 54.808478517887686],
              [-1.481843026214108, 54.809642279104445],
              [-1.482216802127297, 54.809979072029478],
              [-1.484317393073408, 54.81045800704284],
              [-1.485325063892871, 54.811972023259024],
              [-1.487491435768726, 54.812674048171928],
              [-1.494293169689084, 54.816003320224418],
              [-1.492287864285938, 54.817821918485706],
              [-1.491991483201359, 54.818374250242414],
              [-1.492380724519575, 54.818607730485056],
              [-1.490277239003115, 54.820172464381194],
              [-1.493726856325827, 54.823463383761869],
              [-1.497496080024683, 54.822619024530539],
              [-1.49779980664431, 54.82360430608567],
              [-1.504266873045482, 54.83122089029429],
              [-1.50287927589528, 54.83443598725583],
              [-1.503305497310552, 54.836621453163609],
              [-1.503556653110152, 54.8374699076507],
              [-1.506210270904886, 54.8462281831358],
              [-1.507041728968192, 54.847006195229191],
              [-1.507788976214833, 54.851380253450266],
              [-1.510257976947204, 54.860096331012954],
              [-1.51029792781685, 54.863100669130404],
              [-1.509576946154901, 54.866026451172885],
              [-1.508303009918252, 54.868927525748376],
              [-1.506657166400836, 54.871132423729172],
              [-1.513799859358051, 54.874787240481034],
              [-1.515215250356279, 54.875146061466815],
              [-1.516654267128303, 54.874964874343505],
              [-1.517140882196838, 54.875714480879111],
              [-1.519196784339953, 54.875948190576864],
              [-1.518179793530492, 54.878114389713076],
              [-1.512741811465101, 54.88072484133621],
              [-1.510478983323002, 54.881086889593618],
              [-1.507394504403634, 54.880442681683306],
              [-1.506322957170465, 54.880634238883722],
              [-1.504314318311852, 54.882251712068282],
              [-1.502500995246451, 54.884577184354029],
              [-1.500750613488782, 54.886838185279927],
              [-1.498214941760569, 54.887418153901073],
              [-1.496191805596748, 54.888269788081196],
              [-1.493706629250233, 54.890654348530425],
              [-1.486930892451227, 54.892273154329636],
              [-1.480743082174777, 54.895415499142651],
              [-1.476909891945945, 54.898478717332296],
              [-1.473838635799291, 54.902263129721739],
              [-1.472325405353304, 54.903260355554295],
              [-1.469199541114745, 54.903545997053925],
              [-1.466899826537783, 54.903149518869711],
              [-1.464766779410074, 54.901006768365981],
              [-1.464029094527309, 54.898781172969329],
              [-1.46314215459371, 54.898288397360375],
              [-1.461982984459316, 54.898099949706079],
              [-1.460781544657279, 54.898275254318534],
              [-1.459278082852234, 54.899345152192652],
              [-1.458455783464981, 54.90043064850142],
              [-1.458298259257394, 54.903570705997616],
              [-1.451791317565592, 54.907869406781622],
              [-1.449329066478978, 54.90909211708226],
              [-1.449038788440832, 54.908876026404677],
              [-1.449101669751046, 54.909182748586204],
              [-1.445780179364122, 54.910265795973764],
              [-1.439949412501394, 54.911527733328057],
              [-1.439262511736611, 54.911681830078003],
              [-1.438764959002405, 54.911118781189039],
              [-1.434985224898603, 54.911699772565854],
              [-1.433826514821128, 54.912905753890655],
              [-1.431703756838162, 54.914108120905738],
              [-1.427989019984103, 54.915028886210436],
              [-1.424915802740326, 54.9153648612483],
              [-1.423551978098353, 54.916158196008752],
              [-1.421455744308331, 54.916456475125848],
              [-1.416689085592304, 54.916298007341972],
              [-1.414433515064206, 54.915747081306961],
              [-1.410980959868673, 54.913631173048145],
              [-1.408667444639827, 54.913111311051118],
              [-1.40790461972116, 54.913326868085491],
              [-1.407468503620257, 54.912857448418485],
              [-1.405868079154322, 54.913280988179771],
              [-1.401857677131182, 54.913697168652114],
              [-1.398260393409057, 54.916426576526725],
              [-1.39682369487579, 54.916734882493294],
              [-1.395301538087547, 54.916602410645247],
              [-1.393650384693971, 54.915919305828702],
              [-1.393627123274334, 54.913522503275551],
              [-1.392024249079185, 54.911508722273325],
              [-1.387366294118505, 54.909680860265098],
              [-1.382870917225796, 54.909488281655165],
              [-1.38209656398617, 54.909331578812186],
              [-1.377435485971545, 54.910077042633461],
              [-1.372777791312499, 54.909489652006307],
              [-1.372058367065613, 54.909614445010533],
              [-1.370843905892297, 54.910077260345759],
              [-1.366561038860492, 54.913539077043531],
              [-1.36398336458223, 54.91318862804583],
              [-1.363688702028475, 54.913574403192541],
              [-1.364473239708529, 54.915858373382633],
              [-1.363274669087967, 54.915692143703744],
              [-1.363328953997402, 54.916601858383437],
              [-1.362634694639018, 54.916210005805191],
              [-1.362051504732391, 54.916983378121223],
              [-1.361170716182543, 54.917288337534096],
              [-1.361468578987858, 54.91704861547742],
              [-1.360904405032903, 54.916028383339359],
              [-1.360392761069199, 54.916124544151288],
              [-1.360406171453203, 54.915180136794611],
              [-1.359496626069283, 54.914006209028571],
              [-1.358622449057558, 54.91375087826799],
              [-1.35678678154329, 54.914218362912166],
              [-1.355331283304943, 54.914907106136923],
              [-1.353786589753113, 54.916291809595705],
              [-1.352742241612101, 54.919155634799075],
              [-1.353035483609886, 54.917116368100828],
              [-1.353839712037179, 54.915799633224246],
              [-1.35558267652454, 54.914501351536295],
            ],
          ],
          [
            [
              [-1.363956043035359, 54.944110852213498],
              [-1.364680473385911, 54.94192644340157],
              [-1.366601960209636, 54.939350170609913],
              [-1.367894369292597, 54.934672266565222],
              [-1.367547289257012, 54.933118504315374],
              [-1.365393543078777, 54.93113745696855],
              [-1.365379966858371, 54.929719325197318],
              [-1.367062349950389, 54.928517618697313],
              [-1.365607200281981, 54.926384741366945],
              [-1.366100169053422, 54.926094353783128],
              [-1.363281846633443, 54.922947850688132],
              [-1.360375931817092, 54.923188714342828],
              [-1.357446709477038, 54.923029487993901],
              [-1.354336149562498, 54.922308473012542],
              [-1.352250929119696, 54.921285507184002],
              [-1.35508335608839, 54.922317830070156],
              [-1.357668033541589, 54.922848232154386],
              [-1.360432111122278, 54.922991307754074],
              [-1.363397551560755, 54.922734578805603],
              [-1.363399443881873, 54.919846340787004],
              [-1.362908104947275, 54.918752810953102],
              [-1.362261643586296, 54.918493307915575],
              [-1.360695442710391, 54.918878690568512],
              [-1.359863953767747, 54.9187772622248],
              [-1.363223050650315, 54.917776733453657],
              [-1.364072538750955, 54.917923165319365],
              [-1.364450000073556, 54.918324137788396],
              [-1.365562807673102, 54.917897700271283],
              [-1.365877411504449, 54.917335890135767],
              [-1.366517817835764, 54.919471715536531],
              [-1.367485051345914, 54.919773303839371],
              [-1.368500145633248, 54.918513286394578],
              [-1.367235307284282, 54.918189488849819],
              [-1.367054935616646, 54.91775360533385],
              [-1.369043841162842, 54.916767343517051],
              [-1.369387572881279, 54.915935182945894],
              [-1.369128036896571, 54.913976584561723],
              [-1.371027918577312, 54.912581842736991],
              [-1.370979423410258, 54.91169013423108],
              [-1.373343154240981, 54.910848625569898],
              [-1.377229935655462, 54.911166946991109],
              [-1.383002512535895, 54.910164731664651],
              [-1.386992339866552, 54.910476973223915],
              [-1.39055059682907, 54.912000092950045],
              [-1.391606855084876, 54.91281146787869],
              [-1.391638434192521, 54.915269420888897],
              [-1.39212812867487, 54.91652098658524],
              [-1.393485265101072, 54.917356314081317],
              [-1.395807472107505, 54.917924135948134],
              [-1.399475309183991, 54.917077809486095],
              [-1.40264524334587, 54.914658096979196],
              [-1.405004129693557, 54.914217644510757],
              [-1.405867023292149, 54.914200296436952],
              [-1.406265184183182, 54.914492503473816],
              [-1.4097562184125, 54.91439267621584],
              [-1.413870819541284, 54.917433820457703],
              [-1.414995702282806, 54.917104942019698],
              [-1.41731613452496, 54.917707390793836],
              [-1.420756767376677, 54.91776696396122],
              [-1.421031151128214, 54.917997424693667],
              [-1.424717927299666, 54.917755214677982],
              [-1.426561513913954, 54.917124987481749],
              [-1.427163785308637, 54.917447743067953],
              [-1.426968686661015, 54.917783814912781],
              [-1.427610360760462, 54.917969261549281],
              [-1.427101271960053, 54.91701609995561],
              [-1.42857890738536, 54.916665392796595],
              [-1.432806072557585, 54.915589738194036],
              [-1.436653395537681, 54.913547933889994],
              [-1.44213928309147, 54.912551469307253],
              [-1.448884330708131, 54.910364377720001],
              [-1.45189620377307, 54.90887456245887],
              [-1.455210366530264, 54.906118042222715],
              [-1.458393880141004, 54.904580308369404],
              [-1.459617263926862, 54.903479522124627],
              [-1.459471148258209, 54.900415396867743],
              [-1.460593543240104, 54.899041862256801],
              [-1.461673826081504, 54.898599125825292],
              [-1.463027219373579, 54.899075101751755],
              [-1.464222632609026, 54.901705313442278],
              [-1.466696053738798, 54.903700391982852],
              [-1.470128798802131, 54.904037114750587],
              [-1.47345777091486, 54.903605850329157],
              [-1.474849492921737, 54.902837239588791],
              [-1.477217165949058, 54.899555714771424],
              [-1.481232598164303, 54.895906450967352],
              [-1.486206051849316, 54.893121108499521],
              [-1.49392126984549, 54.891067719624033],
              [-1.496916420109568, 54.888417470662127],
              [-1.501254948024493, 54.887150287600434],
              [-1.504539013113194, 54.882867300784312],
              [-1.506584705440805, 54.881025313333843],
              [-1.511560769644665, 54.881465975917351],
              [-1.513823260198324, 54.88087385328685],
              [-1.517682358169918, 54.878920298048087],
              [-1.51824191581346, 54.878917125218599],
              [-1.517946186866628, 54.878753298174288],
              [-1.519107959442763, 54.877912275834966],
              [-1.519948673175986, 54.87601856081983],
              [-1.522899666437489, 54.876209010112881],
              [-1.525627794388815, 54.877597309312783],
              [-1.527357140694072, 54.876981285773091],
              [-1.529009253388733, 54.877371419658189],
              [-1.530000332914553, 54.877113750516905],
              [-1.530776840954449, 54.877885989644049],
              [-1.531769321532754, 54.878180078729876],
              [-1.532840777331182, 54.87784541268077],
              [-1.532929459291981, 54.87704955514404],
              [-1.533361939486022, 54.876441935715775],
              [-1.536141601069914, 54.874730773544528],
              [-1.537468707522917, 54.874378170571241],
              [-1.53894634295485, 54.875445082858207],
              [-1.541555930817928, 54.873450977665591],
              [-1.55315194385131, 54.875950153339097],
              [-1.561797636653243, 54.875708441224432],
              [-1.56193092555529, 54.877357930904772],
              [-1.562416029521678, 54.877751486284183],
              [-1.560354185369316, 54.879895396352623],
              [-1.559398889411976, 54.882028908473153],
              [-1.558579957262606, 54.884369595319022],
              [-1.55611720429467, 54.884787482681084],
              [-1.556250817387053, 54.885253466831784],
              [-1.558143635413699, 54.885640486633761],
              [-1.558643314939795, 54.886417829254064],
              [-1.558516464036193, 54.888183198589232],
              [-1.557206986258516, 54.888182929451467],
              [-1.557050097201912, 54.89097533387006],
              [-1.558296118537376, 54.891084109521742],
              [-1.558508570569306, 54.891473093937194],
              [-1.5581836174093, 54.902651890669588],
              [-1.55969277899963, 54.902974584364053],
              [-1.561599974557038, 54.904558586281539],
              [-1.562773324318257, 54.905081325274047],
              [-1.560080459352607, 54.906324314818228],
              [-1.5586607409175, 54.906510580331741],
              [-1.557057953912288, 54.90788685830212],
              [-1.557068463311134, 54.909203401399786],
              [-1.560379765815227, 54.914500304316618],
              [-1.560078842464414, 54.914630417152452],
              [-1.563479291598357, 54.917532685948856],
              [-1.565530075655416, 54.920058008174145],
              [-1.567142459423217, 54.923186516596282],
              [-1.568879343452326, 54.924617022540531],
              [-1.556692199441975, 54.929970418058396],
              [-1.553293582422952, 54.92769791655455],
              [-1.551877007920003, 54.928654245700372],
              [-1.547626057692776, 54.930483408489245],
              [-1.543841556308673, 54.931465861310301],
              [-1.54311013341461, 54.931422677566573],
              [-1.535654758469748, 54.932143008624948],
              [-1.532453503482842, 54.931374094492185],
              [-1.530463736513095, 54.93258318266264],
              [-1.527400241303809, 54.933114993124178],
              [-1.524673410843208, 54.933147502419096],
              [-1.519010622131096, 54.931117688327369],
              [-1.51723102110663, 54.930772750640969],
              [-1.515648123917056, 54.931073791359104],
              [-1.514505966161877, 54.931707269748053],
              [-1.512075977565161, 54.932106425871773],
              [-1.511204349826339, 54.931658101130452],
              [-1.510612099215049, 54.930822683380008],
              [-1.508312088703707, 54.930418007154273],
              [-1.505218732029306, 54.930956327453089],
              [-1.500260956426184, 54.930567617263378],
              [-1.496319148246627, 54.931056391837124],
              [-1.492441351396227, 54.930171288927831],
              [-1.488610874642789, 54.930613549071559],
              [-1.485129224733558, 54.928662295159903],
              [-1.483225523382781, 54.928412487576097],
              [-1.482009959054449, 54.928773961094841],
              [-1.480957241054797, 54.929818184098963],
              [-1.471954452084104, 54.929828855906145],
              [-1.466434007717819, 54.93013096884232],
              [-1.460626913307024, 54.930273399845923],
              [-1.460884394955049, 54.932025090484771],
              [-1.457373627549519, 54.93267626831679],
              [-1.454735011145201, 54.933453472914671],
              [-1.450054637703477, 54.933906881915945],
              [-1.449079962030158, 54.934080401549814],
              [-1.447897067124028, 54.933502604505883],
              [-1.442546084030605, 54.933576148636249],
              [-1.441447575266201, 54.932885446658197],
              [-1.432761407637329, 54.932858725461521],
              [-1.431786211176165, 54.929853612398617],
              [-1.426422810049434, 54.931161120506125],
              [-1.425938590278459, 54.930424646215087],
              [-1.419329232890517, 54.929912496248953],
              [-1.41831395125936, 54.93279048585164],
              [-1.416744259134484, 54.933955694957255],
              [-1.412458287056273, 54.935798847418923],
              [-1.410293621642849, 54.936431798269496],
              [-1.407034044626905, 54.936901216803619],
              [-1.40477872652221, 54.937849949386688],
              [-1.40266016405834, 54.938621381010563],
              [-1.398091339866503, 54.939572984203345],
              [-1.393379658663769, 54.939630454133656],
              [-1.391930914394405, 54.939438099854925],
              [-1.390853707334843, 54.938520588419102],
              [-1.389314147439325, 54.938144424977665],
              [-1.387519095360814, 54.93804643330067],
              [-1.384256752784252, 54.93867069315619],
              [-1.381072120531617, 54.938191728379351],
              [-1.37880782030911, 54.936943652618218],
              [-1.376978860430341, 54.937327903074021],
              [-1.375669690426887, 54.936901523625174],
              [-1.374335386079914, 54.943527547094206],
              [-1.366137893444374, 54.942855155957886],
              [-1.365701948270918, 54.94417119188747],
              [-1.363956043035359, 54.944110852213498],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000025",
        LAD13CDO: "00CN",
        LAD13NM: "Birmingham",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.828066263264436, 52.608704799042087],
            [-1.819070255777701, 52.606144617618028],
            [-1.811824503850143, 52.604392924025724],
            [-1.803942861267078, 52.60133621813867],
            [-1.801816339137775, 52.601096252695527],
            [-1.797578956667969, 52.599153595240331],
            [-1.79653535529729, 52.599106860596308],
            [-1.79427877660589, 52.59608689237087],
            [-1.795321289602508, 52.595082737944431],
            [-1.792764708072306, 52.593763090710262],
            [-1.789729949028841, 52.591527379135691],
            [-1.788120673969842, 52.587907016883428],
            [-1.788088491828848, 52.587846727659063],
            [-1.78846797669872, 52.586895384355579],
            [-1.78828433130189, 52.584571190196542],
            [-1.7848431306771, 52.581726012210481],
            [-1.78385658081014, 52.581568694132727],
            [-1.783615076584238, 52.581161014840944],
            [-1.781366035880906, 52.580254311234391],
            [-1.780275108323428, 52.578843589429781],
            [-1.77950263905348, 52.578682136557319],
            [-1.778691315993024, 52.576564419967632],
            [-1.779158517924065, 52.576385494545391],
            [-1.778839777288681, 52.575798763626764],
            [-1.777954772095238, 52.575726089869299],
            [-1.778006387535421, 52.575147241508674],
            [-1.776656279212176, 52.574553175087694],
            [-1.774178632397127, 52.574582643228844],
            [-1.77338033219913, 52.574871491576673],
            [-1.771939679261779, 52.574679043677797],
            [-1.77093691875212, 52.575123905151081],
            [-1.769695287596192, 52.574966874650663],
            [-1.769018599728579, 52.574288623870039],
            [-1.7681406828674, 52.574279718336122],
            [-1.767730844564763, 52.573370942929536],
            [-1.768206589916808, 52.572972725383678],
            [-1.767023691441943, 52.571211095775951],
            [-1.765914269327707, 52.570909548772015],
            [-1.763553250858722, 52.570963297256284],
            [-1.763570431989909, 52.570236952520034],
            [-1.76511843254896, 52.56908034025269],
            [-1.766799385993087, 52.568711479364119],
            [-1.767909362139591, 52.568059197122736],
            [-1.769157051565596, 52.568147935693034],
            [-1.771259901317136, 52.56749215729792],
            [-1.77391480278342, 52.565620170723037],
            [-1.774237157359889, 52.564612124345274],
            [-1.77041227588657, 52.563681521198362],
            [-1.766921126259587, 52.561602547861234],
            [-1.766926038298184, 52.559838748232238],
            [-1.767738440137903, 52.558790327652396],
            [-1.766300723771496, 52.55787053645868],
            [-1.76534693081565, 52.556661314102435],
            [-1.763431244558286, 52.556603567145679],
            [-1.759959909467053, 52.555652652734587],
            [-1.757820298357096, 52.555555704844593],
            [-1.756407074929159, 52.555863858185454],
            [-1.756005692531865, 52.555366792161081],
            [-1.753584206879283, 52.545286833400191],
            [-1.752113409010317, 52.543825607544825],
            [-1.74643537792997, 52.541697376221499],
            [-1.749000589721873, 52.541246150824108],
            [-1.748368582943432, 52.536505340459478],
            [-1.750921973444129, 52.536262613746658],
            [-1.750419032027943, 52.533956546351718],
            [-1.749093485149428, 52.531406908616617],
            [-1.746860790724825, 52.531299675202817],
            [-1.743534506398118, 52.532677866525567],
            [-1.742354435863179, 52.531289962726078],
            [-1.739429130995271, 52.531895778421635],
            [-1.738677858907947, 52.529069495902689],
            [-1.739762557646357, 52.529104246818974],
            [-1.738830458580596, 52.528690458269075],
            [-1.736923768192373, 52.529063816408112],
            [-1.731578300290592, 52.527701543087574],
            [-1.730828922561404, 52.525865901029157],
            [-1.730426051207351, 52.525944995327201],
            [-1.728858110447872, 52.524451788694748],
            [-1.72964341077078, 52.524258502064434],
            [-1.730512458118438, 52.523143035276121],
            [-1.731844781037187, 52.522907825382653],
            [-1.733792774389779, 52.523467795073742],
            [-1.734833651069563, 52.523413492682089],
            [-1.736332211012973, 52.522488178613123],
            [-1.738137003891717, 52.52210201879214],
            [-1.738767789384561, 52.521606268776267],
            [-1.74164398658888, 52.520714482481772],
            [-1.74387579618444, 52.520625832835165],
            [-1.74583036233267, 52.520054687043434],
            [-1.746911923255215, 52.520084870997941],
            [-1.748769470520219, 52.520752280195083],
            [-1.751348056887618, 52.520583315181604],
            [-1.753817610421096, 52.521197988261953],
            [-1.754326582952007, 52.518460715564373],
            [-1.75704653666483, 52.518282929582078],
            [-1.757084500057021, 52.517811935586799],
            [-1.754929836418372, 52.515717341559785],
            [-1.754098476031765, 52.515761467678402],
            [-1.753264867311147, 52.514100194346213],
            [-1.75394134680619, 52.513814822494773],
            [-1.753530630736764, 52.512951834703429],
            [-1.7605018087572, 52.51431192330412],
            [-1.764408802036776, 52.514477978842152],
            [-1.768491884971602, 52.514228915064749],
            [-1.773610239788461, 52.513232832245627],
            [-1.782524364113482, 52.510384438506073],
            [-1.787169945210201, 52.50949657768551],
            [-1.791659525016752, 52.509155747011711],
            [-1.793927141239781, 52.50882438779454],
            [-1.794832808941605, 52.508289260532749],
            [-1.794032358007655, 52.507747577166867],
            [-1.794314842303555, 52.506568588855366],
            [-1.798373033193254, 52.505765570472413],
            [-1.799365769439348, 52.504848484258332],
            [-1.799301818670281, 52.503685078391499],
            [-1.798250745283987, 52.502867007308538],
            [-1.796946572238601, 52.502636435262666],
            [-1.794534165116309, 52.502814772526023],
            [-1.791503190604487, 52.502953317081051],
            [-1.782548468771238, 52.501608508481389],
            [-1.781986553805935, 52.501442060950581],
            [-1.78217202460218, 52.501172703819321],
            [-1.779402661246355, 52.501152297127035],
            [-1.772463343715483, 52.501617438544322],
            [-1.772030530392843, 52.501846749071845],
            [-1.771544163198412, 52.501606679743993],
            [-1.771406012639793, 52.501828464863927],
            [-1.770363894781944, 52.50166193362044],
            [-1.770278955776352, 52.501282393339757],
            [-1.76418140835553, 52.500784956266259],
            [-1.76419071863065, 52.499869798264704],
            [-1.761549967448314, 52.499781805024703],
            [-1.761499665511486, 52.500094553935966],
            [-1.758898586494452, 52.499753963864563],
            [-1.758739111345695, 52.500093459227173],
            [-1.755844944990932, 52.499480708325912],
            [-1.756966315743451, 52.496739281112788],
            [-1.758970616607014, 52.495197104004589],
            [-1.759907128050034, 52.494704558227269],
            [-1.760344181279097, 52.493693174132915],
            [-1.760846828942857, 52.493619573310006],
            [-1.761015547448199, 52.491565708252089],
            [-1.761602325853974, 52.49036313480017],
            [-1.762366668514559, 52.490093173713653],
            [-1.762332192979646, 52.488563911774236],
            [-1.761120331920626, 52.488260310310729],
            [-1.763662536880495, 52.487095815093831],
            [-1.763101103836516, 52.486612831387546],
            [-1.762072034574386, 52.486556829072391],
            [-1.761486007118216, 52.486814561594564],
            [-1.760868228465483, 52.486407868508941],
            [-1.761568315197087, 52.485208219908479],
            [-1.761102157217453, 52.485087714069294],
            [-1.760419143050086, 52.483953598225305],
            [-1.759481533940423, 52.483879778309799],
            [-1.759053572098694, 52.483239721477652],
            [-1.759813667984731, 52.482918525157359],
            [-1.758879207206916, 52.480385049451257],
            [-1.759862908946363, 52.480362774944041],
            [-1.759664619518241, 52.479461576949753],
            [-1.759103388122145, 52.47950718472638],
            [-1.758718934131453, 52.477649971232033],
            [-1.758607104793412, 52.476560157467773],
            [-1.759163779389539, 52.476533421752968],
            [-1.759169991020019, 52.475394403366693],
            [-1.75581054505311, 52.471887722269848],
            [-1.756698775393923, 52.471524556644489],
            [-1.759330311302352, 52.468939017339849],
            [-1.758736251530803, 52.467203638335832],
            [-1.759956307829751, 52.466973276553595],
            [-1.761723121245212, 52.467164735377487],
            [-1.763754242770841, 52.466104388639963],
            [-1.763385179151133, 52.463831878759692],
            [-1.754984449486165, 52.45626948155968],
            [-1.759607054636951, 52.451896322192219],
            [-1.761398759423457, 52.450908346579077],
            [-1.762773096140464, 52.451180808487315],
            [-1.766681833714527, 52.451074401164327],
            [-1.76666749395177, 52.450724661078091],
            [-1.769021365323292, 52.451087977616766],
            [-1.772484195162527, 52.450702716341958],
            [-1.773342030060211, 52.450992041996514],
            [-1.774389896731216, 52.45066500821973],
            [-1.777484565084801, 52.450616923380998],
            [-1.777957649769689, 52.450173704343243],
            [-1.779112883914218, 52.450139455161782],
            [-1.779136525616174, 52.45041414437803],
            [-1.781460183815492, 52.450655791719385],
            [-1.781645184762632, 52.451034612772361],
            [-1.782461582408543, 52.451086459326106],
            [-1.782709972144557, 52.450845083825129],
            [-1.78898676071366, 52.453081462914632],
            [-1.789426814265287, 52.452757706601837],
            [-1.790289632873174, 52.452958815850899],
            [-1.790508713993126, 52.452685008119936],
            [-1.791313837989803, 52.452951634937961],
            [-1.790912225631959, 52.45323950606624],
            [-1.793994597324662, 52.454285951030812],
            [-1.794831142936837, 52.455085716104286],
            [-1.795298385932525, 52.455241152741912],
            [-1.795689300588323, 52.455036855473175],
            [-1.79874947757582, 52.457455012906969],
            [-1.800323356896832, 52.458282959105553],
            [-1.80491885158696, 52.45441412561275],
            [-1.805394453774902, 52.454682810260103],
            [-1.805971012507715, 52.454079627428754],
            [-1.80512970690552, 52.453316792345291],
            [-1.806260211472925, 52.451552109283817],
            [-1.806072430186853, 52.449745707796104],
            [-1.806684879246328, 52.449666697817278],
            [-1.808281760898472, 52.446517392509072],
            [-1.809269495893413, 52.445379052329095],
            [-1.810016121125359, 52.445216633859204],
            [-1.809579645906119, 52.444761936723332],
            [-1.812276554480574, 52.44299790385476],
            [-1.811577649161041, 52.442329734035376],
            [-1.812422030227846, 52.441997544277292],
            [-1.811843494366617, 52.44139429468985],
            [-1.812804640956185, 52.440580421992479],
            [-1.811910663181445, 52.440474721314231],
            [-1.813404862075468, 52.438484893691736],
            [-1.814487763679186, 52.437729633957417],
            [-1.816115846413362, 52.437805891139668],
            [-1.817321052731554, 52.432352600978874],
            [-1.81924646962002, 52.430885683089585],
            [-1.82526970488458, 52.42929089828916],
            [-1.827036086067557, 52.427774173057607],
            [-1.828005061410254, 52.425554142852818],
            [-1.830731858431846, 52.422781043822326],
            [-1.832898535686763, 52.419499153489028],
            [-1.83441659551443, 52.418549238146817],
            [-1.833735889773195, 52.418138337694714],
            [-1.834650194604695, 52.417418614676066],
            [-1.839066496099982, 52.414335711537241],
            [-1.842371853412824, 52.412600578454573],
            [-1.843615646817769, 52.410509338697416],
            [-1.847824979676574, 52.409951144118786],
            [-1.84872595827083, 52.410003541663109],
            [-1.849440735155181, 52.410774901577298],
            [-1.850259790580341, 52.410278788870556],
            [-1.850842901605098, 52.410431456812383],
            [-1.853949549186178, 52.408544715674843],
            [-1.857817649270294, 52.408404681573487],
            [-1.862765854970763, 52.409322110720616],
            [-1.864138056439934, 52.410072565334524],
            [-1.864770357915661, 52.410981285894159],
            [-1.86665195082232, 52.411031066046498],
            [-1.865643828882323, 52.409937633503752],
            [-1.866258709663424, 52.409808872201218],
            [-1.867120340482398, 52.408772387413308],
            [-1.868793407452678, 52.40772421149898],
            [-1.869203327365062, 52.407795686347271],
            [-1.868753785643754, 52.404723280010892],
            [-1.872284627601762, 52.40467679442407],
            [-1.8722977033048, 52.402179364112172],
            [-1.877869093996777, 52.402430667379456],
            [-1.878361126894531, 52.399883383827799],
            [-1.882704439684274, 52.39979876113177],
            [-1.886199574254802, 52.404343059440848],
            [-1.885945902524098, 52.404673650438127],
            [-1.886695516007623, 52.404687854622573],
            [-1.887121830047731, 52.404671180297761],
            [-1.892387629115695, 52.404418962371537],
            [-1.896139916786622, 52.403312934050177],
            [-1.90069680270415, 52.403821182424132],
            [-1.904594920200945, 52.403131248467766],
            [-1.906633075955411, 52.404079526194849],
            [-1.909673542116144, 52.404432494320183],
            [-1.910818487337282, 52.406676384511918],
            [-1.913681949535537, 52.407449851449101],
            [-1.913965746683982, 52.406646345221368],
            [-1.914656450245848, 52.406740341008025],
            [-1.915310092383926, 52.403149274520132],
            [-1.916475793867098, 52.401529189910832],
            [-1.918303877483402, 52.400119020394492],
            [-1.918585220600073, 52.398178254738546],
            [-1.922444010793326, 52.396841317616946],
            [-1.923143182065353, 52.396210668498256],
            [-1.924390091862522, 52.396678954307163],
            [-1.928280078759917, 52.393124889910638],
            [-1.928080626424499, 52.392876642627677],
            [-1.932079870611011, 52.39049033086652],
            [-1.932866286633619, 52.39031097638766],
            [-1.93464346362131, 52.387875651617428],
            [-1.934162023034738, 52.387535559740328],
            [-1.934322643826987, 52.387221894201133],
            [-1.936353758272066, 52.387890066133906],
            [-1.939679520379159, 52.389585537100515],
            [-1.945028404692392, 52.390556366823816],
            [-1.9529059809975, 52.393379043119282],
            [-1.953621664072736, 52.3933559502142],
            [-1.958919618003511, 52.388233543963324],
            [-1.959416265497446, 52.388227421328878],
            [-1.959550790537916, 52.388949372026985],
            [-1.960442588896531, 52.38907823020768],
            [-1.964934996972303, 52.392173129756472],
            [-1.966369798497099, 52.389450443733061],
            [-1.968835448743121, 52.389443022851509],
            [-1.968967864906093, 52.389197627743172],
            [-1.969724275805869, 52.389674297320568],
            [-1.97055450946665, 52.389642140828954],
            [-1.971628922171403, 52.389200987412252],
            [-1.972465674627458, 52.388111583533302],
            [-1.975585660122512, 52.387096380852441],
            [-1.977805504252813, 52.387689261078719],
            [-1.978600505900375, 52.387499714791261],
            [-1.978935345016206, 52.387857580133193],
            [-1.983355303197066, 52.387380001935249],
            [-1.985508317385077, 52.385976925942302],
            [-1.988198956658175, 52.384488458112017],
            [-1.988153685769014, 52.383436611417167],
            [-1.989285193996421, 52.382472078450625],
            [-1.992889134577686, 52.381937430732549],
            [-1.9947109670567, 52.381128412446344],
            [-1.997330410019696, 52.381052977854743],
            [-2.000126143633187, 52.381649045897419],
            [-2.001288297012227, 52.383551342965951],
            [-2.002194790947595, 52.3835153671629],
            [-2.002216866512659, 52.384186028533279],
            [-2.00417531283737, 52.383943238379082],
            [-2.00500984896164, 52.384140986394584],
            [-2.00614577461943, 52.38565666168806],
            [-2.008468944016462, 52.386948390437404],
            [-2.011361814093232, 52.386166901560102],
            [-2.01295968097611, 52.388686658521777],
            [-2.013448624111561, 52.392276347707245],
            [-2.014204577573263, 52.394221713223317],
            [-2.015083520879076, 52.394654924667378],
            [-2.016911505215278, 52.394247422198717],
            [-2.018083025211591, 52.394934987972889],
            [-2.01927985910464, 52.396288703293308],
            [-2.021365686061859, 52.400180147060077],
            [-2.025110884297682, 52.400653179762287],
            [-2.025791239759605, 52.400404006574618],
            [-2.028417708557942, 52.400418681709269],
            [-2.03096218541047, 52.400876531104494],
            [-2.032216386198165, 52.401534266961477],
            [-2.033648566015483, 52.402314203577312],
            [-2.033443328047852, 52.403019084271975],
            [-2.02806586914143, 52.40641784379082],
            [-2.027322495164174, 52.407099466891644],
            [-2.02727867904312, 52.407554374613454],
            [-2.027868348907413, 52.407870686199885],
            [-2.027542145869747, 52.408091020038363],
            [-2.024185144027158, 52.406356678469599],
            [-2.023167892123559, 52.4062777718243],
            [-2.022036292275856, 52.406803010784884],
            [-2.020485495976367, 52.406853637503417],
            [-2.017556791320667, 52.405568535070586],
            [-2.015078675940168, 52.40610918590896],
            [-2.01428221284607, 52.406866250507797],
            [-2.012685949141966, 52.40730245448853],
            [-2.012356925292088, 52.408190708831015],
            [-2.012807136551845, 52.409496017612369],
            [-2.010866667920497, 52.409543863557651],
            [-2.009245367235394, 52.410373788072881],
            [-2.008104242520845, 52.408605528409474],
            [-2.006449065124307, 52.409119865787304],
            [-2.004646795427447, 52.409208956035535],
            [-2.004208896668061, 52.410954844002063],
            [-2.002797571276453, 52.410663610906155],
            [-2.002084583079767, 52.410856913590287],
            [-2.00102020973333, 52.410294151932341],
            [-1.998623980731616, 52.409743062701409],
            [-1.998149140082599, 52.409973202951036],
            [-1.997799272905451, 52.409718778893811],
            [-1.996933363237596, 52.410339076019348],
            [-1.995615963766924, 52.412481369419147],
            [-1.993251787969952, 52.41355827263807],
            [-1.991081781080022, 52.413544648640908],
            [-1.988868998235553, 52.414107243686772],
            [-1.986043909822711, 52.416711369740931],
            [-1.988225768158984, 52.416974111843743],
            [-1.988703773625164, 52.416353843626126],
            [-1.991049072553713, 52.415398396228049],
            [-1.997068184739859, 52.417411558565206],
            [-1.997016691647843, 52.41792758644641],
            [-1.999025165713173, 52.418698962054947],
            [-2.000374984872062, 52.420229069051054],
            [-2.002049829985234, 52.420973424171429],
            [-2.001282289762434, 52.421857158366628],
            [-2.002826317692341, 52.422316519467564],
            [-2.001655838672818, 52.422984504651588],
            [-2.003398591000597, 52.425377613025013],
            [-2.003522194908832, 52.426246047068204],
            [-2.004323693322871, 52.426356596181655],
            [-2.004693048138726, 52.428395521974828],
            [-2.006565161728642, 52.427913562688467],
            [-2.007303468260959, 52.428078934435831],
            [-2.009747381084344, 52.426507294060421],
            [-2.013184905349231, 52.428705916390783],
            [-2.012577821313236, 52.429790182159422],
            [-2.013789627548033, 52.429537424379191],
            [-2.015465922395708, 52.428596855542402],
            [-2.015286280529079, 52.432159629711045],
            [-2.015811539751106, 52.432649516153859],
            [-2.016997037153926, 52.432668228130403],
            [-2.017007515397276, 52.44065945914658],
            [-2.017441053187454, 52.44324132768083],
            [-2.016518470344143, 52.446717000636802],
            [-2.015274076838662, 52.447644040494616],
            [-2.013239439526485, 52.448341015811678],
            [-2.013517931998259, 52.449643635462685],
            [-2.013044357469456, 52.450358394995526],
            [-2.012126701291776, 52.452178071056629],
            [-2.011510996316775, 52.45534980525089],
            [-2.011763143807443, 52.457207112677352],
            [-2.013090026283892, 52.460268961060777],
            [-2.013249546036213, 52.462132567177889],
            [-2.013249559248828, 52.462175719105311],
            [-2.007911234488627, 52.461899316932936],
            [-2.004398105354349, 52.462577352425605],
            [-2.002093264888054, 52.46338292336317],
            [-1.997729226567943, 52.463097947356736],
            [-1.98796292791986, 52.465351167045718],
            [-1.981720132988686, 52.466276348963106],
            [-1.977247762028334, 52.46741641156482],
            [-1.968322607526938, 52.472224913262124],
            [-1.965741104061725, 52.473294006001076],
            [-1.965812968116219, 52.473649130372984],
            [-1.967136079865312, 52.474182607423401],
            [-1.965380322538454, 52.475307654382547],
            [-1.969030486923428, 52.476778517166252],
            [-1.968411443338363, 52.477731290899236],
            [-1.968698466850592, 52.477847337046335],
            [-1.967038333169886, 52.478938271119425],
            [-1.964695223528979, 52.48149883938968],
            [-1.961806679593618, 52.482719683569172],
            [-1.958790924710535, 52.482798688981312],
            [-1.956129138173412, 52.482293401467452],
            [-1.952492441730552, 52.48334562791041],
            [-1.951572699264777, 52.482811253833745],
            [-1.950782970552176, 52.483231659580788],
            [-1.949524657471681, 52.485212517760722],
            [-1.950725989746023, 52.485479123993137],
            [-1.949710975901796, 52.487127455873036],
            [-1.948434355981017, 52.486984868083006],
            [-1.948031593020151, 52.487608595136351],
            [-1.947096609587746, 52.487476030659707],
            [-1.945951979705026, 52.490196772360456],
            [-1.943943497257014, 52.489920750122224],
            [-1.943145265424033, 52.491072881736251],
            [-1.943423440365149, 52.49121415653363],
            [-1.942885368923633, 52.491605860693433],
            [-1.945689153397536, 52.493151646209419],
            [-1.945534040093472, 52.493529152676025],
            [-1.943830304661026, 52.49440218194556],
            [-1.944316174459551, 52.495720335092052],
            [-1.941419465964342, 52.49752771765403],
            [-1.938137872357633, 52.498409765257321],
            [-1.942591018386695, 52.499398194790935],
            [-1.942468515197746, 52.499582429119314],
            [-1.943007902795457, 52.499391203424274],
            [-1.945719108639177, 52.499957039566929],
            [-1.950824579809211, 52.501475860550222],
            [-1.952843990747767, 52.501707719049314],
            [-1.95552964306678, 52.501581095808803],
            [-1.955490298344915, 52.502620316401014],
            [-1.957300219207649, 52.503243081950515],
            [-1.963031022411902, 52.504882062865363],
            [-1.961155553683134, 52.506602136813584],
            [-1.96197536113455, 52.507558928385826],
            [-1.961267558260502, 52.50825092393989],
            [-1.962241385970265, 52.508320458815831],
            [-1.961982229184595, 52.509860348666166],
            [-1.959574871096358, 52.509651889246989],
            [-1.962271603969884, 52.512635626000936],
            [-1.962649442839896, 52.513646210617594],
            [-1.962114080367892, 52.515921386635711],
            [-1.960206214998514, 52.517050795562291],
            [-1.959907943945657, 52.517707857347183],
            [-1.958800574676704, 52.51839970185118],
            [-1.95804456329184, 52.519963680306148],
            [-1.959498512705255, 52.522274586656096],
            [-1.960113698701604, 52.524881862787915],
            [-1.96208464427115, 52.527946259738115],
            [-1.960484797667406, 52.530208493675104],
            [-1.960243510063921, 52.531335744463405],
            [-1.958089760375581, 52.531389844595637],
            [-1.957570993078214, 52.529719341032994],
            [-1.951247156729212, 52.529779848702411],
            [-1.949801774457497, 52.530475963665332],
            [-1.947980271270842, 52.532574318246112],
            [-1.947273824923631, 52.532842804626284],
            [-1.947473629816064, 52.533418245169621],
            [-1.945792498586983, 52.533865186891148],
            [-1.941516126430477, 52.533627626168197],
            [-1.937252105394264, 52.531991092349038],
            [-1.933783808204883, 52.53272548192281],
            [-1.932634343421254, 52.532469523363595],
            [-1.932486660687351, 52.53264384321028],
            [-1.929446144928835, 52.531283705959979],
            [-1.930352435906376, 52.533342024061959],
            [-1.929445434826775, 52.542806923887625],
            [-1.929947029240964, 52.543572257972691],
            [-1.933155202802092, 52.545797298195168],
            [-1.921195683083407, 52.54603539146099],
            [-1.919814909082458, 52.546327536804249],
            [-1.918511538994073, 52.547017070886454],
            [-1.918163013253447, 52.547291021915512],
            [-1.916805278458522, 52.548628668249513],
            [-1.911840723918621, 52.551928857670312],
            [-1.908162810667968, 52.553211618677295],
            [-1.901285832124437, 52.554399052979591],
            [-1.899799279443741, 52.554983945607127],
            [-1.898102320040836, 52.556607865201045],
            [-1.896688484952971, 52.558842417049192],
            [-1.893281056953587, 52.561307113464146],
            [-1.891639403212194, 52.562439244568012],
            [-1.888505624427755, 52.563810888037715],
            [-1.882752951310361, 52.567441731407783],
            [-1.879779293902805, 52.568835775664056],
            [-1.878526005509143, 52.569620210286359],
            [-1.875808222887305, 52.580638014898014],
            [-1.872862881137433, 52.584106759668707],
            [-1.872571225704478, 52.584929013916486],
            [-1.870952728100357, 52.586741407080844],
            [-1.867811532081694, 52.58831565964627],
            [-1.867990944994105, 52.588537008388712],
            [-1.863787753059594, 52.591209419766464],
            [-1.85396103631072, 52.601909064394427],
            [-1.84870877236545, 52.604592225699847],
            [-1.845732248314236, 52.60493539870194],
            [-1.838664113252075, 52.606856103303535],
            [-1.838141827482303, 52.606733131071181],
            [-1.836840123645328, 52.607338157904877],
            [-1.831950368756844, 52.607848244950347],
            [-1.828066263264436, 52.608704799042087],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000026",
        LAD13CDO: "00CQ",
        LAD13NM: "Coventry",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.525923607184466, 52.456344194036951],
            [-1.521578295910888, 52.456305132482235],
            [-1.517577766685441, 52.456767165360624],
            [-1.519059758477448, 52.455141498144194],
            [-1.519663732261293, 52.453325258155282],
            [-1.505042230750711, 52.450072631178401],
            [-1.502862061506418, 52.450814165268476],
            [-1.500033645627771, 52.451021608074925],
            [-1.498723726627352, 52.450998086638144],
            [-1.495861130198455, 52.452212175023639],
            [-1.495408523638635, 52.452029545286948],
            [-1.494306408538453, 52.452669430436551],
            [-1.494772507950082, 52.452959104026142],
            [-1.492627794375251, 52.454429697126059],
            [-1.492862436325448, 52.454884702222721],
            [-1.491719072308026, 52.455007456422372],
            [-1.491758656128023, 52.455915625097482],
            [-1.491303507597116, 52.456462963797129],
            [-1.491917009217816, 52.456605844523196],
            [-1.49200595033011, 52.457573559289486],
            [-1.492814426801042, 52.45842479145076],
            [-1.491990098807202, 52.458567794302439],
            [-1.489675591917687, 52.458015734918142],
            [-1.489129304968237, 52.458422426837537],
            [-1.488204422496872, 52.458478664441579],
            [-1.486844855461164, 52.458200377789844],
            [-1.486849985338647, 52.457632226727767],
            [-1.484705796059451, 52.457249821784472],
            [-1.483024387394921, 52.458069576814246],
            [-1.479213960593815, 52.457089133333795],
            [-1.478904575465179, 52.456743451106753],
            [-1.477305525086224, 52.456938676526654],
            [-1.472835625575979, 52.456362363637687],
            [-1.470823094546901, 52.456920656639973],
            [-1.466312691705802, 52.458485355947587],
            [-1.464248534204011, 52.458067879493342],
            [-1.463929300069645, 52.458300176296767],
            [-1.462021887175072, 52.457824036195611],
            [-1.461340636799713, 52.455761306638472],
            [-1.460579589133939, 52.455779416412746],
            [-1.460333372102759, 52.455214614775798],
            [-1.459784402412888, 52.455580704113423],
            [-1.45906134622153, 52.455140482530439],
            [-1.458754353446506, 52.454733625431366],
            [-1.460427880058443, 52.453622000167869],
            [-1.452173326856458, 52.449640116282914],
            [-1.446061061592079, 52.447528652915892],
            [-1.445482217766698, 52.448280212444374],
            [-1.443655510494643, 52.447404092176889],
            [-1.444382090469221, 52.447073972672243],
            [-1.443653170184991, 52.446772075979624],
            [-1.444348186917189, 52.446377079421794],
            [-1.445023115299739, 52.446409015139622],
            [-1.446467165325082, 52.443065161562409],
            [-1.446662972585746, 52.442584206843662],
            [-1.439825021019682, 52.440255954450095],
            [-1.440101677154808, 52.439668410446778],
            [-1.431873727052611, 52.437508417608548],
            [-1.431702259771183, 52.43677220101592],
            [-1.429938516451238, 52.436774504643914],
            [-1.428793946169813, 52.435761190019051],
            [-1.42521611877056, 52.434779220967805],
            [-1.424154203696485, 52.433982025759057],
            [-1.423946336137423, 52.432889610268653],
            [-1.424438305934178, 52.43068941846137],
            [-1.432407126573087, 52.415673898235831],
            [-1.43472155745968, 52.412666980302689],
            [-1.438063518323297, 52.410102723410638],
            [-1.437106715306194, 52.407658240227974],
            [-1.43265299431489, 52.402695058179667],
            [-1.431041042766901, 52.400257266827872],
            [-1.43088498613542, 52.39810606159071],
            [-1.432257109356586, 52.395859712536968],
            [-1.432667597028926, 52.395021098631069],
            [-1.435828785279523, 52.392247459645183],
            [-1.444973861031934, 52.386359892036346],
            [-1.44949936157595, 52.384230586072626],
            [-1.458454958663879, 52.381779848184756],
            [-1.463140913286886, 52.379652553073427],
            [-1.461469656017482, 52.377431563722055],
            [-1.462933725032324, 52.376385467591341],
            [-1.460411819693022, 52.374790809864969],
            [-1.462093729777143, 52.37406576944332],
            [-1.463760297401508, 52.373754185669185],
            [-1.464023452065119, 52.373250128302885],
            [-1.466342203112943, 52.374519245830001],
            [-1.464705558077472, 52.375514257982061],
            [-1.466618551812128, 52.37718699064186],
            [-1.471018134197768, 52.376456069531024],
            [-1.475923368327893, 52.37469155419268],
            [-1.480419903540426, 52.3743014422489],
            [-1.48228793856115, 52.377101994372318],
            [-1.486614551459014, 52.376813390945266],
            [-1.504509140444621, 52.377790537580857],
            [-1.508180231438695, 52.37580554198329],
            [-1.509372231940636, 52.373091855850703],
            [-1.513923110418853, 52.369045277372315],
            [-1.514703218657997, 52.369964583609608],
            [-1.517988099343735, 52.371922599747286],
            [-1.518480308667438, 52.373530255064537],
            [-1.520011384843408, 52.37307707992241],
            [-1.521094352474843, 52.374533387722131],
            [-1.519821678227135, 52.375536927413087],
            [-1.522791325961073, 52.377498021934137],
            [-1.524661698599106, 52.379384504237073],
            [-1.529929957838557, 52.377846661904179],
            [-1.53267237004652, 52.376199738942944],
            [-1.535489933150761, 52.377592631231593],
            [-1.545688138380894, 52.369254285485134],
            [-1.541904030039993, 52.366617245837773],
            [-1.542888222637069, 52.36602859978948],
            [-1.543346287603039, 52.366327945281711],
            [-1.546819435825264, 52.365340696298418],
            [-1.54940154444834, 52.366322404159504],
            [-1.552423745745064, 52.363875959527569],
            [-1.555016809105499, 52.36508683651229],
            [-1.555566693117167, 52.365024174903049],
            [-1.557466009405648, 52.36571724997313],
            [-1.555853795644139, 52.367735798852884],
            [-1.558265182153246, 52.368639355169059],
            [-1.556917895658602, 52.369714936558545],
            [-1.555562406232852, 52.372041007438675],
            [-1.556771583899053, 52.372448305868403],
            [-1.554911405065037, 52.37368016797609],
            [-1.555003980105694, 52.374407822600539],
            [-1.559312347292227, 52.375582789790855],
            [-1.562645844406634, 52.37687536587849],
            [-1.563156416378255, 52.379174243821588],
            [-1.564828564467716, 52.382278422507511],
            [-1.567808779702687, 52.384761646667052],
            [-1.570548527915175, 52.38419715403289],
            [-1.568786331157921, 52.383654029646785],
            [-1.569572518040237, 52.38288194056517],
            [-1.571383915438605, 52.38334881519269],
            [-1.573028584618907, 52.382521374869206],
            [-1.576718227115631, 52.38277197679384],
            [-1.575799027044234, 52.384559525156362],
            [-1.580125866838905, 52.384877932046237],
            [-1.588007380067724, 52.386094145838683],
            [-1.593953410138765, 52.38787857525692],
            [-1.594727341590208, 52.387442509739209],
            [-1.597400285032117, 52.38822658871289],
            [-1.597703316557006, 52.388027139238389],
            [-1.601077394987723, 52.389283702241784],
            [-1.602516257810927, 52.389738952395149],
            [-1.603152949894452, 52.390346127172599],
            [-1.601610266117373, 52.391464710451949],
            [-1.601049638972983, 52.392853596888635],
            [-1.600026853737001, 52.392853739216498],
            [-1.600780090092783, 52.395041787821242],
            [-1.60226094797543, 52.39493260114547],
            [-1.602701445528752, 52.395793538939941],
            [-1.60159807045715, 52.395922882315368],
            [-1.604283416584966, 52.399993639596445],
            [-1.604408568306407, 52.401777700600412],
            [-1.604317119365777, 52.403457650210989],
            [-1.601233887763602, 52.403722396141852],
            [-1.601340339705531, 52.404632555967929],
            [-1.603684865051103, 52.404466024432182],
            [-1.605807758518104, 52.405911536880907],
            [-1.605885325517738, 52.407270203064563],
            [-1.603404137879994, 52.407743782541914],
            [-1.604062168374185, 52.409117879087802],
            [-1.603695563379262, 52.40918048154461],
            [-1.604619012190894, 52.415897389359422],
            [-1.602208516068929, 52.415811995037572],
            [-1.602184401053539, 52.416041161793054],
            [-1.60324828813911, 52.416763942740729],
            [-1.604714854427165, 52.418663965894737],
            [-1.611103791150355, 52.42232522865158],
            [-1.611063432778203, 52.422734145489571],
            [-1.609391206845505, 52.424095132171594],
            [-1.606462271327668, 52.425371011455596],
            [-1.607250940177986, 52.425819540382122],
            [-1.610592821706458, 52.4262468261282],
            [-1.613296325527688, 52.427199651278706],
            [-1.614458936803798, 52.427952319255262],
            [-1.61140595421717, 52.432118228755222],
            [-1.60812196280985, 52.431082529922108],
            [-1.606554973057855, 52.430984732631352],
            [-1.606245186451779, 52.432904883547543],
            [-1.609754262141655, 52.433613238514575],
            [-1.609744488153464, 52.434887100765771],
            [-1.60895186193721, 52.435696288526088],
            [-1.609585742414186, 52.436539852676347],
            [-1.609573197242226, 52.437960243378505],
            [-1.608279862311687, 52.438825309898569],
            [-1.607337975067491, 52.439037950404916],
            [-1.605702089062028, 52.440035803757652],
            [-1.604410562203817, 52.440026099762342],
            [-1.598991555973517, 52.439487336888703],
            [-1.596691639506406, 52.438428581440149],
            [-1.595871196267316, 52.439672706384989],
            [-1.595237926929318, 52.439910577263888],
            [-1.592854752745059, 52.44534229567568],
            [-1.593681433209868, 52.448560883313178],
            [-1.594226047932247, 52.448698501681349],
            [-1.595314152692719, 52.450061524502239],
            [-1.595911264799837, 52.45137970936441],
            [-1.593658935802061, 52.451807117194399],
            [-1.59480425209469, 52.453035489454635],
            [-1.593549298031861, 52.453487881505481],
            [-1.595496089481402, 52.45590388512251],
            [-1.594618341217332, 52.455976398665953],
            [-1.595659173019148, 52.456575999179762],
            [-1.595751580691554, 52.457574210378446],
            [-1.596525220767453, 52.458430005597826],
            [-1.594929923684859, 52.460673866708333],
            [-1.594013280568447, 52.461444769190742],
            [-1.59306589097088, 52.461555689618628],
            [-1.589994167086058, 52.46108749649418],
            [-1.589871857840976, 52.461896176840483],
            [-1.585653785015249, 52.460154498909276],
            [-1.578713105004306, 52.458135106943125],
            [-1.577530063381526, 52.458422168038226],
            [-1.57405297839606, 52.457908061359063],
            [-1.568429950144324, 52.456072599663088],
            [-1.5629683574964, 52.45596445667384],
            [-1.561201116032695, 52.455206348835318],
            [-1.558531940003756, 52.456649217221432],
            [-1.554298782271211, 52.456428378757984],
            [-1.554004723837536, 52.456839015955254],
            [-1.551430362977696, 52.457027967715028],
            [-1.5492395882284, 52.459284241284401],
            [-1.547570624658067, 52.459573646490014],
            [-1.545270208805351, 52.461440157404063],
            [-1.539800013410851, 52.46478219594276],
            [-1.537023836371898, 52.462409681401347],
            [-1.535042890764199, 52.459460367740078],
            [-1.529686190648728, 52.457071219199747],
            [-1.525923607184466, 52.456344194036951],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000027",
        LAD13CDO: "00CR",
        LAD13NM: "Dudley",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.121533281431563, 52.556800101722246],
            [-2.11796188515381, 52.552784360415266],
            [-2.118481950982015, 52.552578871345403],
            [-2.117832451954526, 52.551817181489966],
            [-2.117237284514109, 52.552063196629511],
            [-2.114662337122233, 52.548710710244755],
            [-2.115512624631521, 52.548475246519487],
            [-2.114528845387611, 52.547285947175496],
            [-2.112206450956593, 52.547939940962188],
            [-2.111474292797403, 52.547077607765516],
            [-2.111990759798819, 52.546054073070785],
            [-2.109644218989144, 52.546476101240806],
            [-2.108250308361803, 52.543929660094747],
            [-2.107765615486432, 52.54410630470646],
            [-2.106110022320871, 52.545463475167644],
            [-2.105090436386301, 52.545813193502767],
            [-2.10232469910725, 52.546085318221486],
            [-2.097319661826102, 52.546006842711144],
            [-2.096202884748703, 52.54645545296988],
            [-2.09418766616945, 52.548111211740618],
            [-2.093702532965393, 52.547439156449173],
            [-2.09210507512319, 52.547953734409525],
            [-2.090057099171816, 52.548847108237716],
            [-2.090350558370301, 52.549558880507327],
            [-2.089158753141215, 52.550177389627045],
            [-2.089041900231516, 52.551459430774464],
            [-2.088576870928496, 52.550497866456048],
            [-2.086426954563793, 52.551396647404303],
            [-2.085012417865978, 52.552812677101983],
            [-2.082197853887875, 52.552515308862823],
            [-2.081464488724571, 52.553109147390465],
            [-2.081373965384061, 52.55519305633193],
            [-2.080816021304468, 52.556564390952126],
            [-2.079784355345476, 52.557011888105905],
            [-2.078049588673318, 52.55690966502867],
            [-2.075275981105149, 52.553102469658171],
            [-2.073947572642166, 52.549864266624702],
            [-2.072864330456836, 52.545847374032405],
            [-2.073000666484035, 52.541822528473347],
            [-2.073343364804364, 52.540429785608694],
            [-2.072363036765154, 52.540531976583857],
            [-2.073572066071358, 52.538770113442688],
            [-2.074659079079432, 52.539004066074384],
            [-2.075231898249843, 52.53772714053391],
            [-2.07412722480982, 52.537495002192195],
            [-2.07592149050187, 52.53408939557233],
            [-2.079082079661628, 52.535649759753923],
            [-2.079274812568731, 52.535430277460321],
            [-2.07855014166305, 52.533332524889971],
            [-2.077126465652302, 52.532718556488824],
            [-2.077680940472578, 52.531990911406155],
            [-2.079628457974059, 52.528021478398799],
            [-2.079596231982803, 52.524069544648],
            [-2.075787721868878, 52.524072052939218],
            [-2.07144348746547, 52.521831785907573],
            [-2.067840117562948, 52.519031762978599],
            [-2.06434208765916, 52.514395833008784],
            [-2.06322487020394, 52.513704664230872],
            [-2.060455487857317, 52.512729363873198],
            [-2.057263261045758, 52.512256291340961],
            [-2.057464426665679, 52.511720394749361],
            [-2.058033213065192, 52.511740792740383],
            [-2.057890623135796, 52.510879629404087],
            [-2.059072447371249, 52.509877566521261],
            [-2.059980862377002, 52.509381763337984],
            [-2.061391148893942, 52.509560835321381],
            [-2.062447882641583, 52.507706559529801],
            [-2.061393553919698, 52.507067930399515],
            [-2.061630327680608, 52.505713026040191],
            [-2.061013855315122, 52.505248567975066],
            [-2.06147868513351, 52.504735900883389],
            [-2.060909633149594, 52.504460205811071],
            [-2.061323347847051, 52.503218482907435],
            [-2.060929085052484, 52.502554332037292],
            [-2.06296430417221, 52.502071402163068],
            [-2.063546026131195, 52.500935662224926],
            [-2.063127690869201, 52.499930813106431],
            [-2.062750511766508, 52.49988067095142],
            [-2.064174392419023, 52.499482549308745],
            [-2.065009544608377, 52.498438360687338],
            [-2.065869213755148, 52.498053114313308],
            [-2.067698846914736, 52.498150966916128],
            [-2.067105125702306, 52.497124656125095],
            [-2.068852361328173, 52.496363100106841],
            [-2.068506208727384, 52.495413964623111],
            [-2.068835172685727, 52.494794366368183],
            [-2.069763928893924, 52.494387475223867],
            [-2.070284206057878, 52.491843013300581],
            [-2.069631502045549, 52.490755617628409],
            [-2.066865549250719, 52.489727870563542],
            [-2.067158397801209, 52.489580268969576],
            [-2.066591169583647, 52.48943045873542],
            [-2.064328674023875, 52.487153662951066],
            [-2.066987015913745, 52.485360484909002],
            [-2.06807957167263, 52.486242672037086],
            [-2.068612428827341, 52.486094927878057],
            [-2.06852135002046, 52.485293976245309],
            [-2.069404755051609, 52.485200862889535],
            [-2.069361581760564, 52.484904219892641],
            [-2.069148713424654, 52.484384725711784],
            [-2.068029508821369, 52.484344922207541],
            [-2.069017972764799, 52.48271177188137],
            [-2.070538728620193, 52.481584430103943],
            [-2.071606160916237, 52.48061736604614],
            [-2.071465423374731, 52.480090639848285],
            [-2.07290777277195, 52.478832061152659],
            [-2.073288172353517, 52.477382643307749],
            [-2.075525748431612, 52.477176259931504],
            [-2.076892576570866, 52.477472944275341],
            [-2.077279150455444, 52.477941967637335],
            [-2.078159552108866, 52.477909023478588],
            [-2.079139246062787, 52.47741751802625],
            [-2.078960641359444, 52.477165019764044],
            [-2.079938330868766, 52.476394820040582],
            [-2.081035676892904, 52.475861865084141],
            [-2.081809826761841, 52.475719289110188],
            [-2.081985431987203, 52.475936724352565],
            [-2.084044849162577, 52.475012005102052],
            [-2.083583477029002, 52.474717462022639],
            [-2.086488423471192, 52.474085170773925],
            [-2.089671014294327, 52.474643768370079],
            [-2.091334149850613, 52.474409652228132],
            [-2.092509070830462, 52.473750670948533],
            [-2.094256222760517, 52.473586569539151],
            [-2.09546878825636, 52.471988076535929],
            [-2.095345092063696, 52.470612710488048],
            [-2.09651203509549, 52.470416679195061],
            [-2.097041351314627, 52.469460610513821],
            [-2.097080186898772, 52.468380882193777],
            [-2.093641375735166, 52.468302751978783],
            [-2.090906754240357, 52.467779876250354],
            [-2.089181852397716, 52.467920537659509],
            [-2.085783603355166, 52.466726491867185],
            [-2.084639545562004, 52.465785168424844],
            [-2.083428159267797, 52.465794122610511],
            [-2.081772820204496, 52.463735676884106],
            [-2.080193719814424, 52.463045431524328],
            [-2.07922959643802, 52.46300922438239],
            [-2.077942079315213, 52.464034940873873],
            [-2.076646750260909, 52.463990840189986],
            [-2.076124799720096, 52.464317514803682],
            [-2.074345212019277, 52.463401672129258],
            [-2.073968016142139, 52.461413324367477],
            [-2.072215459889089, 52.460727576004558],
            [-2.066369355739685, 52.461540112406887],
            [-2.064505989282964, 52.461489005395443],
            [-2.063310473124268, 52.462218741877059],
            [-2.061890476083098, 52.462436155151664],
            [-2.059022723567828, 52.461960258868778],
            [-2.056792674834143, 52.462898112011992],
            [-2.055460297731058, 52.462611067605778],
            [-2.055091704193331, 52.463277398547504],
            [-2.052899969220959, 52.46437697982617],
            [-2.051078526334915, 52.463761071524324],
            [-2.049411095754202, 52.466550475685032],
            [-2.048903174220745, 52.466465283068928],
            [-2.04802554289606, 52.467495896109426],
            [-2.047502989601206, 52.467499704246073],
            [-2.047114163637624, 52.470036831774244],
            [-2.044732986827066, 52.470668857535578],
            [-2.042436836742702, 52.472524339664879],
            [-2.0417547263277, 52.473544945385562],
            [-2.04103742866154, 52.47318290223312],
            [-2.037467847095952, 52.473721694626491],
            [-2.034599409475338, 52.474836431583412],
            [-2.031298674149936, 52.476923928947031],
            [-2.030038276247563, 52.47677412609211],
            [-2.02346744779566, 52.479615555162781],
            [-2.022908301116969, 52.480308791761111],
            [-2.022755288137926, 52.480536267551138],
            [-2.022167438822676, 52.479846850704362],
            [-2.020982326363384, 52.477150081369267],
            [-2.01847086534941, 52.474408566571725],
            [-2.015578525740338, 52.471921464983957],
            [-2.014379643261149, 52.470233302795215],
            [-2.013020718661034, 52.464709122296703],
            [-2.0128905298774, 52.462489510047916],
            [-2.013249559248828, 52.462175719105311],
            [-2.013249546036213, 52.462132567177889],
            [-2.013090026283892, 52.460268961060777],
            [-2.011763143807443, 52.457207112677352],
            [-2.011510996316775, 52.45534980525089],
            [-2.012126701291776, 52.452178071056629],
            [-2.013044357469456, 52.450358394995526],
            [-2.013517931998259, 52.449643635462685],
            [-2.013239439526485, 52.448341015811678],
            [-2.015274076838662, 52.447644040494616],
            [-2.016518470344143, 52.446717000636802],
            [-2.017441053187454, 52.44324132768083],
            [-2.017007515397276, 52.44065945914658],
            [-2.016997037153926, 52.432668228130403],
            [-2.024773101532161, 52.434867596578975],
            [-2.024887353585614, 52.434027004741864],
            [-2.023745566999759, 52.433317029769512],
            [-2.023690753525777, 52.432594242472],
            [-2.026045245146124, 52.43207951234789],
            [-2.026197051514479, 52.430145722407268],
            [-2.027805459719728, 52.42921668095908],
            [-2.029331938622827, 52.429029316041763],
            [-2.028977367065439, 52.428832522452069],
            [-2.029713141451516, 52.427286950765122],
            [-2.036994550087151, 52.427531207892542],
            [-2.036950845155428, 52.428023875748742],
            [-2.037578826577745, 52.428026373472541],
            [-2.038117755796804, 52.428787655944809],
            [-2.03806813794382, 52.429232678838197],
            [-2.037534319854005, 52.429293982907204],
            [-2.03734934056965, 52.429683310427848],
            [-2.037874717901684, 52.430060723633183],
            [-2.036966195243859, 52.430532989943629],
            [-2.03749740257537, 52.430840280674722],
            [-2.036882733739592, 52.430975325845239],
            [-2.037667147824334, 52.431546842947213],
            [-2.03753848478014, 52.432443190312931],
            [-2.038162147847548, 52.43248164645015],
            [-2.037599315542746, 52.433058088947796],
            [-2.038447097067874, 52.433714086650291],
            [-2.03813703726758, 52.43405131381855],
            [-2.03984936834543, 52.437543370534158],
            [-2.038495425350579, 52.438503956413719],
            [-2.038637049199386, 52.438964199016688],
            [-2.037137314784846, 52.439824128749201],
            [-2.037115997358419, 52.440708753369186],
            [-2.03788757405616, 52.441542780649129],
            [-2.039745469200929, 52.441389336458599],
            [-2.039855180299906, 52.440703360921773],
            [-2.040401475398216, 52.441265050482947],
            [-2.044556773822436, 52.440663015565583],
            [-2.04468708670361, 52.440058836820832],
            [-2.046391942187547, 52.439941306318374],
            [-2.047097070369102, 52.440392325038651],
            [-2.049166079192518, 52.439665084821357],
            [-2.051490237067457, 52.440813914139277],
            [-2.052171007521285, 52.440518741663354],
            [-2.053601012727615, 52.44060260521853],
            [-2.055717332233589, 52.441299247894648],
            [-2.058497020458369, 52.44075220540892],
            [-2.05926976263978, 52.439967889245629],
            [-2.059636699739056, 52.439354584554366],
            [-2.059235894956432, 52.43885134565852],
            [-2.059457321807117, 52.437245615899499],
            [-2.060212228312436, 52.43638398787747],
            [-2.059473604720977, 52.436233330830504],
            [-2.059315064902085, 52.435382055275966],
            [-2.058704140466672, 52.435013770331238],
            [-2.058653665397465, 52.433562804808844],
            [-2.059165715247638, 52.432610504337894],
            [-2.058948517553245, 52.430777545561696],
            [-2.059522440988242, 52.428842601089706],
            [-2.060719244521599, 52.427523151349604],
            [-2.062473591129387, 52.427416151188403],
            [-2.065793845915685, 52.428072414009961],
            [-2.065877283056941, 52.428793368442449],
            [-2.066660316036645, 52.429201974611004],
            [-2.066712585506248, 52.429726064160846],
            [-2.065356136229631, 52.430425350380439],
            [-2.06383133742093, 52.430685099628576],
            [-2.062355705523265, 52.432440744688556],
            [-2.067552287470562, 52.432543961340642],
            [-2.068014753665612, 52.432949145675344],
            [-2.067632094442174, 52.434706916864464],
            [-2.068057916884734, 52.43518044613154],
            [-2.06860690793953, 52.434454632398044],
            [-2.069773111649402, 52.434319996083119],
            [-2.070976748048338, 52.433687278053803],
            [-2.071511019556861, 52.433900917732721],
            [-2.073290555941659, 52.433768569901012],
            [-2.07430950179934, 52.432680137987404],
            [-2.074739004327773, 52.432691553418081],
            [-2.07481768293736, 52.433983370977685],
            [-2.077941718357494, 52.43392381019973],
            [-2.078269606930125, 52.433030882825086],
            [-2.079897899437775, 52.432257546548151],
            [-2.082110473082641, 52.431716624090413],
            [-2.082670567968332, 52.43235092901098],
            [-2.08342161373072, 52.432063617874292],
            [-2.084618982811936, 52.432130189779542],
            [-2.086056022024499, 52.432910383493578],
            [-2.085511253792714, 52.433380958540106],
            [-2.086459009658336, 52.433648170292514],
            [-2.08696986837996, 52.434630404930822],
            [-2.087518348096454, 52.4345472908198],
            [-2.087227158494324, 52.436799508693809],
            [-2.088741578939011, 52.44011300058952],
            [-2.09047646654545, 52.441064624503539],
            [-2.090793872720035, 52.441606478759596],
            [-2.090269294957321, 52.442624551577573],
            [-2.094143807738314, 52.443751567546286],
            [-2.09425322672476, 52.444008594621643],
            [-2.092879782484853, 52.445021959904778],
            [-2.093286366919521, 52.445267965409542],
            [-2.095677829639533, 52.444902853148442],
            [-2.098571106989658, 52.445269061451455],
            [-2.102246168960168, 52.443947098105859],
            [-2.105255378402889, 52.444193475202255],
            [-2.107030686864295, 52.442191599005916],
            [-2.108783340791221, 52.441208285817893],
            [-2.111762645276999, 52.437263375599258],
            [-2.112466506581715, 52.437549488668608],
            [-2.11290301109142, 52.437400736348437],
            [-2.114648924945694, 52.435645997212134],
            [-2.117253427163425, 52.434345277342132],
            [-2.11752443349174, 52.433929668221175],
            [-2.119668132337544, 52.434705151568089],
            [-2.122887255291753, 52.433406379461516],
            [-2.127765487279167, 52.432690986249675],
            [-2.127788017410066, 52.432344845462197],
            [-2.129702590689924, 52.432196219321526],
            [-2.129026643815738, 52.430903293787054],
            [-2.129755587283448, 52.430712804793295],
            [-2.129275164508514, 52.429886248348716],
            [-2.130465670367751, 52.429617934102325],
            [-2.132279976868081, 52.429916181941785],
            [-2.133096613480256, 52.427603923936182],
            [-2.135484860577405, 52.42804621375727],
            [-2.136032307799542, 52.426253868067228],
            [-2.143550907437792, 52.426045384454767],
            [-2.146607882967476, 52.42635897594932],
            [-2.16485645636654, 52.430189835608132],
            [-2.17032365320295, 52.439058838703346],
            [-2.171268390707217, 52.441415556891023],
            [-2.171794426847165, 52.441632351463312],
            [-2.175374745882301, 52.452994967408976],
            [-2.176425693511771, 52.454537885760857],
            [-2.176354880582516, 52.455964705570508],
            [-2.174928905240864, 52.458564038091936],
            [-2.173894105318028, 52.461125018929906],
            [-2.174265265755346, 52.463798995535953],
            [-2.171559250448289, 52.465883240872522],
            [-2.169367476270112, 52.469315184981056],
            [-2.167518386873115, 52.471201223222977],
            [-2.167425408728762, 52.471526792358226],
            [-2.168084983987772, 52.471542936593551],
            [-2.169102471155876, 52.472373956517195],
            [-2.171810538093394, 52.472571424267521],
            [-2.173769098446051, 52.474211927491226],
            [-2.177878204469986, 52.479351672738836],
            [-2.178488017501535, 52.482681535972354],
            [-2.17763636824508, 52.485102018597928],
            [-2.179521552293614, 52.488782355196143],
            [-2.182253768161067, 52.493135602434876],
            [-2.184274861107203, 52.492865466255274],
            [-2.185181426530876, 52.494798685960639],
            [-2.188665011951696, 52.494492911110918],
            [-2.184614045700688, 52.499992165018277],
            [-2.1847892985084, 52.500332609785922],
            [-2.191754103552835, 52.503382587265826],
            [-2.189426440510306, 52.504747417125984],
            [-2.186624447174228, 52.504772552043825],
            [-2.187689213042298, 52.506063617919402],
            [-2.188006171430397, 52.50714550164173],
            [-2.187108920981134, 52.507492138872898],
            [-2.187987737471902, 52.508348383321746],
            [-2.185854700036328, 52.508788667466369],
            [-2.185651696003772, 52.508515692785807],
            [-2.180521125982517, 52.510234423925795],
            [-2.180260932346803, 52.510740953746947],
            [-2.178248886477595, 52.511634007915674],
            [-2.173822287308692, 52.512304057958126],
            [-2.172810572684594, 52.512825159075739],
            [-2.17038109080898, 52.513291667669073],
            [-2.169988319142253, 52.513080970268071],
            [-2.168391155524525, 52.513499490181701],
            [-2.167158832501802, 52.513380774954385],
            [-2.1646422919839, 52.514626714406774],
            [-2.16339296612728, 52.514696771870256],
            [-2.162077983627689, 52.514145703130218],
            [-2.15745365864054, 52.514880138804202],
            [-2.1569168930524, 52.515185610918934],
            [-2.156229639398688, 52.515027400165927],
            [-2.155181221743343, 52.515246337660898],
            [-2.152675360676228, 52.515027551426343],
            [-2.150422105297013, 52.514122460428659],
            [-2.148698787942023, 52.514776410659493],
            [-2.144129664704945, 52.517154510494407],
            [-2.145294231757241, 52.517715852269063],
            [-2.144522345057555, 52.519605578299995],
            [-2.144588568961903, 52.52136931630718],
            [-2.146048915361973, 52.522644985078912],
            [-2.145540744462079, 52.522736410141654],
            [-2.145750999191069, 52.523473322712221],
            [-2.146071801389237, 52.523322795222299],
            [-2.147573465387636, 52.524141706923807],
            [-2.148104085378821, 52.523710426860866],
            [-2.149852823148706, 52.524787908709122],
            [-2.15157442580373, 52.525237904615949],
            [-2.152215643520409, 52.525681181229622],
            [-2.151994817497792, 52.526184000664124],
            [-2.147030745859559, 52.528008937391242],
            [-2.145949566628451, 52.528678226762764],
            [-2.145980060622048, 52.529427046961935],
            [-2.144235070938056, 52.530417178759897],
            [-2.139392415243498, 52.532662371767053],
            [-2.137382098031152, 52.533278735464947],
            [-2.137402637223571, 52.533718316846254],
            [-2.137167685516411, 52.534013457985765],
            [-2.136520383690107, 52.533974652231343],
            [-2.13647765977351, 52.534458357218334],
            [-2.135569641596829, 52.534493564165139],
            [-2.135429188178844, 52.534841633631984],
            [-2.136754742067054, 52.534908429858461],
            [-2.135396000419615, 52.536031931725717],
            [-2.136608252134415, 52.536157293594592],
            [-2.136314539009036, 52.537476447864101],
            [-2.135656941664496, 52.537461921931659],
            [-2.13566220029632, 52.53773251117051],
            [-2.136591118773162, 52.537759309550431],
            [-2.136894240332687, 52.538042139829983],
            [-2.136491246227944, 52.540255014052249],
            [-2.13581885360278, 52.540714272346733],
            [-2.136104928750068, 52.541676758325842],
            [-2.135710157310932, 52.54180217159935],
            [-2.1354790583303, 52.543363976994158],
            [-2.136794544527674, 52.543429885356389],
            [-2.136967423440503, 52.544963356285002],
            [-2.136060723533764, 52.545493906847177],
            [-2.136833083232757, 52.546807332985239],
            [-2.139156637487677, 52.548049715346238],
            [-2.138157701489066, 52.548805136743582],
            [-2.138047599211504, 52.549433656559266],
            [-2.137478638380542, 52.549525117437177],
            [-2.134895751591523, 52.551722518176653],
            [-2.134571010655275, 52.551630292946228],
            [-2.133463003297035, 52.553430419244158],
            [-2.133492914547103, 52.554051584190418],
            [-2.13169830238914, 52.554160577199944],
            [-2.128387653715141, 52.55382170748824],
            [-2.128337359407082, 52.555284410673949],
            [-2.128074814078472, 52.555787228059955],
            [-2.127215965156857, 52.556147749773373],
            [-2.127332945134654, 52.557322596675405],
            [-2.128396000089886, 52.557683737067102],
            [-2.127603921217642, 52.558191622335464],
            [-2.121533281431563, 52.556800101722246],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000028",
        LAD13CDO: "00CS",
        LAD13NM: "Sandwell",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.016815953852471, 52.569069704905509],
            [-2.016150302111981, 52.568399158306903],
            [-2.012503192623068, 52.568405001582967],
            [-2.011778610257692, 52.567761406148023],
            [-2.010983727705788, 52.569049725909025],
            [-2.005782676677323, 52.566870073549694],
            [-1.999804688457292, 52.565207110055702],
            [-1.994412763163014, 52.563923242910477],
            [-1.992897693315293, 52.564055315599383],
            [-1.989056469201481, 52.563000524388137],
            [-1.986232037979713, 52.561200471529524],
            [-1.983729057630331, 52.560347923319334],
            [-1.980558379713024, 52.558550383928896],
            [-1.979249940525774, 52.558722769660619],
            [-1.975502129598058, 52.555545052430695],
            [-1.975246779260659, 52.555879422023303],
            [-1.974435902695743, 52.557843531683787],
            [-1.97454291446703, 52.559003243618911],
            [-1.967120102974612, 52.559465307194358],
            [-1.96697734329691, 52.56101331745726],
            [-1.967350520330711, 52.561052076399662],
            [-1.966850803131027, 52.562557735714613],
            [-1.964149232980662, 52.563211414339094],
            [-1.959938963524296, 52.56165213619844],
            [-1.955073811683732, 52.561782551626621],
            [-1.954074707438531, 52.56219660187211],
            [-1.951878970045747, 52.561482840990422],
            [-1.952758433127291, 52.557076374898024],
            [-1.951074282022269, 52.556817686281775],
            [-1.949331057130296, 52.556641655104748],
            [-1.949435388555758, 52.556984212425299],
            [-1.947772510050436, 52.557466248760981],
            [-1.944227967817988, 52.559892795528619],
            [-1.942851721670695, 52.559887647438394],
            [-1.942315145859459, 52.560745917797703],
            [-1.940357376125366, 52.560965203173581],
            [-1.940289967870004, 52.560634343403713],
            [-1.937960335192715, 52.559895085329167],
            [-1.936443235030868, 52.56039142206896],
            [-1.935573077446307, 52.560287570622329],
            [-1.93393317210759, 52.560018773489389],
            [-1.932608143819226, 52.559314125425331],
            [-1.934658316517013, 52.55743279759605],
            [-1.932576135086986, 52.556126309807617],
            [-1.933072258300379, 52.553825189924254],
            [-1.929384049772152, 52.553533582798444],
            [-1.925041594493367, 52.552053890804757],
            [-1.923099515089698, 52.551058372198753],
            [-1.918163013253447, 52.547291021915512],
            [-1.918511538994073, 52.547017070886454],
            [-1.919814909082458, 52.546327536804249],
            [-1.921195683083407, 52.54603539146099],
            [-1.933155202802092, 52.545797298195168],
            [-1.929947029240964, 52.543572257972691],
            [-1.929445434826775, 52.542806923887625],
            [-1.930352435906376, 52.533342024061959],
            [-1.929446144928835, 52.531283705959979],
            [-1.932486660687351, 52.53264384321028],
            [-1.932634343421254, 52.532469523363595],
            [-1.933783808204883, 52.53272548192281],
            [-1.937252105394264, 52.531991092349038],
            [-1.941516126430477, 52.533627626168197],
            [-1.945792498586983, 52.533865186891148],
            [-1.947473629816064, 52.533418245169621],
            [-1.947273824923631, 52.532842804626284],
            [-1.947980271270842, 52.532574318246112],
            [-1.949801774457497, 52.530475963665332],
            [-1.951247156729212, 52.529779848702411],
            [-1.957570993078214, 52.529719341032994],
            [-1.958089760375581, 52.531389844595637],
            [-1.960243510063921, 52.531335744463405],
            [-1.960484797667406, 52.530208493675104],
            [-1.96208464427115, 52.527946259738115],
            [-1.960113698701604, 52.524881862787915],
            [-1.959498512705255, 52.522274586656096],
            [-1.95804456329184, 52.519963680306148],
            [-1.958800574676704, 52.51839970185118],
            [-1.959907943945657, 52.517707857347183],
            [-1.960206214998514, 52.517050795562291],
            [-1.962114080367892, 52.515921386635711],
            [-1.962649442839896, 52.513646210617594],
            [-1.962271603969884, 52.512635626000936],
            [-1.959574871096358, 52.509651889246989],
            [-1.961982229184595, 52.509860348666166],
            [-1.962241385970265, 52.508320458815831],
            [-1.961267558260502, 52.50825092393989],
            [-1.96197536113455, 52.507558928385826],
            [-1.961155553683134, 52.506602136813584],
            [-1.963031022411902, 52.504882062865363],
            [-1.957300219207649, 52.503243081950515],
            [-1.955490298344915, 52.502620316401014],
            [-1.95552964306678, 52.501581095808803],
            [-1.952843990747767, 52.501707719049314],
            [-1.950824579809211, 52.501475860550222],
            [-1.945719108639177, 52.499957039566929],
            [-1.943007902795457, 52.499391203424274],
            [-1.942468515197746, 52.499582429119314],
            [-1.942591018386695, 52.499398194790935],
            [-1.938137872357633, 52.498409765257321],
            [-1.941419465964342, 52.49752771765403],
            [-1.944316174459551, 52.495720335092052],
            [-1.943830304661026, 52.49440218194556],
            [-1.945534040093472, 52.493529152676025],
            [-1.945689153397536, 52.493151646209419],
            [-1.942885368923633, 52.491605860693433],
            [-1.943423440365149, 52.49121415653363],
            [-1.943145265424033, 52.491072881736251],
            [-1.943943497257014, 52.489920750122224],
            [-1.945951979705026, 52.490196772360456],
            [-1.947096609587746, 52.487476030659707],
            [-1.948031593020151, 52.487608595136351],
            [-1.948434355981017, 52.486984868083006],
            [-1.949710975901796, 52.487127455873036],
            [-1.950725989746023, 52.485479123993137],
            [-1.949524657471681, 52.485212517760722],
            [-1.950782970552176, 52.483231659580788],
            [-1.951572699264777, 52.482811253833745],
            [-1.952492441730552, 52.48334562791041],
            [-1.956129138173412, 52.482293401467452],
            [-1.958790924710535, 52.482798688981312],
            [-1.961806679593618, 52.482719683569172],
            [-1.964695223528979, 52.48149883938968],
            [-1.967038333169886, 52.478938271119425],
            [-1.968698466850592, 52.477847337046335],
            [-1.968411443338363, 52.477731290899236],
            [-1.969030486923428, 52.476778517166252],
            [-1.965380322538454, 52.475307654382547],
            [-1.967136079865312, 52.474182607423401],
            [-1.965812968116219, 52.473649130372984],
            [-1.965741104061725, 52.473294006001076],
            [-1.968322607526938, 52.472224913262124],
            [-1.977247762028334, 52.46741641156482],
            [-1.981720132988686, 52.466276348963106],
            [-1.98796292791986, 52.465351167045718],
            [-1.997729226567943, 52.463097947356736],
            [-2.002093264888054, 52.46338292336317],
            [-2.004398105354349, 52.462577352425605],
            [-2.007911234488627, 52.461899316932936],
            [-2.013249559248828, 52.462175719105311],
            [-2.0128905298774, 52.462489510047916],
            [-2.013020718661034, 52.464709122296703],
            [-2.014379643261149, 52.470233302795215],
            [-2.015578525740338, 52.471921464983957],
            [-2.01847086534941, 52.474408566571725],
            [-2.020982326363384, 52.477150081369267],
            [-2.022167438822676, 52.479846850704362],
            [-2.022755288137926, 52.480536267551138],
            [-2.022908301116969, 52.480308791761111],
            [-2.02346744779566, 52.479615555162781],
            [-2.030038276247563, 52.47677412609211],
            [-2.031298674149936, 52.476923928947031],
            [-2.034599409475338, 52.474836431583412],
            [-2.037467847095952, 52.473721694626491],
            [-2.04103742866154, 52.47318290223312],
            [-2.0417547263277, 52.473544945385562],
            [-2.042436836742702, 52.472524339664879],
            [-2.044732986827066, 52.470668857535578],
            [-2.047114163637624, 52.470036831774244],
            [-2.047502989601206, 52.467499704246073],
            [-2.04802554289606, 52.467495896109426],
            [-2.048903174220745, 52.466465283068928],
            [-2.049411095754202, 52.466550475685032],
            [-2.051078526334915, 52.463761071524324],
            [-2.052899969220959, 52.46437697982617],
            [-2.055091704193331, 52.463277398547504],
            [-2.055460297731058, 52.462611067605778],
            [-2.056792674834143, 52.462898112011992],
            [-2.059022723567828, 52.461960258868778],
            [-2.061890476083098, 52.462436155151664],
            [-2.063310473124268, 52.462218741877059],
            [-2.064505989282964, 52.461489005395443],
            [-2.066369355739685, 52.461540112406887],
            [-2.072215459889089, 52.460727576004558],
            [-2.073968016142139, 52.461413324367477],
            [-2.074345212019277, 52.463401672129258],
            [-2.076124799720096, 52.464317514803682],
            [-2.076646750260909, 52.463990840189986],
            [-2.077942079315213, 52.464034940873873],
            [-2.07922959643802, 52.46300922438239],
            [-2.080193719814424, 52.463045431524328],
            [-2.081772820204496, 52.463735676884106],
            [-2.083428159267797, 52.465794122610511],
            [-2.084639545562004, 52.465785168424844],
            [-2.085783603355166, 52.466726491867185],
            [-2.089181852397716, 52.467920537659509],
            [-2.090906754240357, 52.467779876250354],
            [-2.093641375735166, 52.468302751978783],
            [-2.097080186898772, 52.468380882193777],
            [-2.097041351314627, 52.469460610513821],
            [-2.09651203509549, 52.470416679195061],
            [-2.095345092063696, 52.470612710488048],
            [-2.09546878825636, 52.471988076535929],
            [-2.094256222760517, 52.473586569539151],
            [-2.092509070830462, 52.473750670948533],
            [-2.091334149850613, 52.474409652228132],
            [-2.089671014294327, 52.474643768370079],
            [-2.086488423471192, 52.474085170773925],
            [-2.083583477029002, 52.474717462022639],
            [-2.084044849162577, 52.475012005102052],
            [-2.081985431987203, 52.475936724352565],
            [-2.081809826761841, 52.475719289110188],
            [-2.081035676892904, 52.475861865084141],
            [-2.079938330868766, 52.476394820040582],
            [-2.078960641359444, 52.477165019764044],
            [-2.079139246062787, 52.47741751802625],
            [-2.078159552108866, 52.477909023478588],
            [-2.077279150455444, 52.477941967637335],
            [-2.076892576570866, 52.477472944275341],
            [-2.075525748431612, 52.477176259931504],
            [-2.073288172353517, 52.477382643307749],
            [-2.07290777277195, 52.478832061152659],
            [-2.071465423374731, 52.480090639848285],
            [-2.071606160916237, 52.48061736604614],
            [-2.070538728620193, 52.481584430103943],
            [-2.069017972764799, 52.48271177188137],
            [-2.068029508821369, 52.484344922207541],
            [-2.069148713424654, 52.484384725711784],
            [-2.069361581760564, 52.484904219892641],
            [-2.069404755051609, 52.485200862889535],
            [-2.06852135002046, 52.485293976245309],
            [-2.068612428827341, 52.486094927878057],
            [-2.06807957167263, 52.486242672037086],
            [-2.066987015913745, 52.485360484909002],
            [-2.064328674023875, 52.487153662951066],
            [-2.066591169583647, 52.48943045873542],
            [-2.067158397801209, 52.489580268969576],
            [-2.066865549250719, 52.489727870563542],
            [-2.069631502045549, 52.490755617628409],
            [-2.070284206057878, 52.491843013300581],
            [-2.069763928893924, 52.494387475223867],
            [-2.068835172685727, 52.494794366368183],
            [-2.068506208727384, 52.495413964623111],
            [-2.068852361328173, 52.496363100106841],
            [-2.067105125702306, 52.497124656125095],
            [-2.067698846914736, 52.498150966916128],
            [-2.065869213755148, 52.498053114313308],
            [-2.065009544608377, 52.498438360687338],
            [-2.064174392419023, 52.499482549308745],
            [-2.062750511766508, 52.49988067095142],
            [-2.063127690869201, 52.499930813106431],
            [-2.063546026131195, 52.500935662224926],
            [-2.06296430417221, 52.502071402163068],
            [-2.060929085052484, 52.502554332037292],
            [-2.061323347847051, 52.503218482907435],
            [-2.060909633149594, 52.504460205811071],
            [-2.06147868513351, 52.504735900883389],
            [-2.061013855315122, 52.505248567975066],
            [-2.061630327680608, 52.505713026040191],
            [-2.061393553919698, 52.507067930399515],
            [-2.062447882641583, 52.507706559529801],
            [-2.061391148893942, 52.509560835321381],
            [-2.059980862377002, 52.509381763337984],
            [-2.059072447371249, 52.509877566521261],
            [-2.057890623135796, 52.510879629404087],
            [-2.058033213065192, 52.511740792740383],
            [-2.057464426665679, 52.511720394749361],
            [-2.057263261045758, 52.512256291340961],
            [-2.060455487857317, 52.512729363873198],
            [-2.06322487020394, 52.513704664230872],
            [-2.06434208765916, 52.514395833008784],
            [-2.067840117562948, 52.519031762978599],
            [-2.07144348746547, 52.521831785907573],
            [-2.075787721868878, 52.524072052939218],
            [-2.079596231982803, 52.524069544648],
            [-2.079628457974059, 52.528021478398799],
            [-2.077680940472578, 52.531990911406155],
            [-2.077126465652302, 52.532718556488824],
            [-2.07855014166305, 52.533332524889971],
            [-2.079274812568731, 52.535430277460321],
            [-2.079082079661628, 52.535649759753923],
            [-2.07592149050187, 52.53408939557233],
            [-2.07412722480982, 52.537495002192195],
            [-2.075231898249843, 52.53772714053391],
            [-2.074659079079432, 52.539004066074384],
            [-2.073572066071358, 52.538770113442688],
            [-2.072363036765154, 52.540531976583857],
            [-2.073343364804364, 52.540429785608694],
            [-2.073000666484035, 52.541822528473347],
            [-2.072864330456836, 52.545847374032405],
            [-2.073947572642166, 52.549864266624702],
            [-2.072129814669712, 52.550232178050344],
            [-2.070385066063635, 52.550164909157836],
            [-2.069016611814972, 52.547431903136349],
            [-2.066621606414655, 52.548304397427856],
            [-2.063373737715926, 52.548915699868623],
            [-2.061474435715536, 52.546907472859694],
            [-2.050771182897034, 52.550499522154567],
            [-2.050987603716203, 52.552713628539145],
            [-2.048467479254928, 52.551726708296584],
            [-2.041514414578524, 52.553976827726935],
            [-2.041066422207657, 52.554373437122273],
            [-2.040625199649167, 52.554128167554509],
            [-2.037513401479654, 52.557818632536524],
            [-2.034857416413562, 52.558463122609204],
            [-2.031377647983185, 52.560290838488562],
            [-2.025373680364036, 52.562264660228394],
            [-2.027053860719149, 52.564590852656202],
            [-2.016815953852471, 52.569069704905509],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000029",
        LAD13CDO: "00CT",
        LAD13NM: "Solihull",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.743249539427165, 52.506942703858883],
            [-1.733220381044776, 52.492133868411798],
            [-1.733087487278294, 52.491946577757233],
            [-1.729995853501352, 52.48884971868884],
            [-1.722534221794499, 52.482665349985062],
            [-1.719036320849987, 52.480462648371308],
            [-1.714808962648261, 52.478641061249753],
            [-1.715280189070735, 52.478402162435387],
            [-1.712690964262964, 52.47649092993835],
            [-1.711664557463115, 52.474945752350109],
            [-1.711263005200211, 52.474889035966946],
            [-1.711419963665509, 52.473850175072954],
            [-1.710719332763337, 52.473828687583925],
            [-1.710824831859908, 52.471656961679535],
            [-1.709865577331049, 52.466415240833634],
            [-1.70906726858779, 52.466268542918627],
            [-1.708484381914582, 52.465598252627274],
            [-1.708524375228353, 52.464444932733834],
            [-1.706612987247991, 52.462581077350414],
            [-1.704135583494328, 52.460649251157449],
            [-1.700415003866086, 52.458688162013914],
            [-1.697455778800064, 52.454765480994993],
            [-1.690030474829233, 52.448645635203903],
            [-1.6864863378038, 52.446308780284888],
            [-1.685353673607934, 52.445036380743211],
            [-1.684704617364152, 52.442800626878402],
            [-1.684216786960994, 52.442510745902553],
            [-1.684425463187617, 52.44151250776541],
            [-1.683557241676465, 52.440520385155573],
            [-1.682040541626928, 52.439701821883283],
            [-1.678152497583719, 52.438691619734996],
            [-1.676603447786151, 52.437893575471783],
            [-1.677161997163562, 52.436341617875904],
            [-1.67075128450663, 52.436772562947816],
            [-1.666593937653831, 52.435456467105929],
            [-1.664551488248425, 52.440020344525372],
            [-1.664896306859351, 52.442659902755764],
            [-1.662294635262247, 52.444284203390751],
            [-1.65832705533791, 52.441959676126835],
            [-1.654876441294737, 52.440739603293721],
            [-1.654683445921926, 52.440963791880129],
            [-1.652801342238613, 52.440658916958697],
            [-1.651144195176233, 52.440931837178894],
            [-1.650710597171823, 52.441255998578114],
            [-1.64822897683113, 52.441604655568554],
            [-1.647004121387984, 52.442081977140859],
            [-1.645259340168062, 52.442081255746594],
            [-1.643265488750863, 52.44304708859918],
            [-1.641405101005477, 52.443328249761564],
            [-1.638309259998195, 52.445360472104319],
            [-1.63740096458881, 52.447576438674403],
            [-1.634965695925165, 52.448670234940728],
            [-1.634607525201973, 52.450169569386354],
            [-1.633652374446044, 52.450725798152561],
            [-1.633163159360207, 52.45205031520787],
            [-1.629723233038245, 52.453757602576836],
            [-1.626927563881683, 52.456166247407424],
            [-1.624670249180434, 52.457014064604451],
            [-1.625951917506567, 52.459289904683857],
            [-1.625792629682936, 52.460546208512348],
            [-1.626659141867496, 52.461285228454813],
            [-1.626888188561797, 52.462221813197843],
            [-1.624460387534433, 52.463523085113799],
            [-1.621736896108493, 52.463413725583735],
            [-1.618326351968679, 52.464121981224253],
            [-1.616610136303695, 52.463443081854606],
            [-1.614220892427727, 52.462984019908518],
            [-1.612209411826711, 52.461078751549373],
            [-1.610722789653933, 52.458587226521303],
            [-1.606023920323775, 52.458225563691784],
            [-1.603836105811705, 52.456682761570349],
            [-1.602356255460215, 52.456450351172727],
            [-1.60016812946017, 52.454803194796128],
            [-1.59855529322131, 52.454315868850635],
            [-1.596703750776263, 52.455640102502819],
            [-1.595496089481402, 52.45590388512251],
            [-1.593549298031861, 52.453487881505481],
            [-1.59480425209469, 52.453035489454635],
            [-1.593658935802061, 52.451807117194399],
            [-1.595911264799837, 52.45137970936441],
            [-1.595314152692719, 52.450061524502239],
            [-1.594226047932247, 52.448698501681349],
            [-1.593681433209868, 52.448560883313178],
            [-1.592854752745059, 52.44534229567568],
            [-1.595237926929318, 52.439910577263888],
            [-1.595871196267316, 52.439672706384989],
            [-1.596691639506406, 52.438428581440149],
            [-1.598991555973517, 52.439487336888703],
            [-1.604410562203817, 52.440026099762342],
            [-1.605702089062028, 52.440035803757652],
            [-1.607337975067491, 52.439037950404916],
            [-1.608279862311687, 52.438825309898569],
            [-1.609573197242226, 52.437960243378505],
            [-1.609585742414186, 52.436539852676347],
            [-1.60895186193721, 52.435696288526088],
            [-1.609744488153464, 52.434887100765771],
            [-1.609754262141655, 52.433613238514575],
            [-1.606245186451779, 52.432904883547543],
            [-1.606554973057855, 52.430984732631352],
            [-1.60812196280985, 52.431082529922108],
            [-1.61140595421717, 52.432118228755222],
            [-1.614458936803798, 52.427952319255262],
            [-1.613296325527688, 52.427199651278706],
            [-1.610592821706458, 52.4262468261282],
            [-1.607250940177986, 52.425819540382122],
            [-1.606462271327668, 52.425371011455596],
            [-1.609391206845505, 52.424095132171594],
            [-1.611063432778203, 52.422734145489571],
            [-1.611103791150355, 52.42232522865158],
            [-1.604714854427165, 52.418663965894737],
            [-1.60324828813911, 52.416763942740729],
            [-1.602184401053539, 52.416041161793054],
            [-1.602208516068929, 52.415811995037572],
            [-1.604619012190894, 52.415897389359422],
            [-1.603695563379262, 52.40918048154461],
            [-1.604062168374185, 52.409117879087802],
            [-1.603404137879994, 52.407743782541914],
            [-1.605885325517738, 52.407270203064563],
            [-1.605807758518104, 52.405911536880907],
            [-1.603684865051103, 52.404466024432182],
            [-1.601340339705531, 52.404632555967929],
            [-1.601233887763602, 52.403722396141852],
            [-1.604317119365777, 52.403457650210989],
            [-1.604408568306407, 52.401777700600412],
            [-1.604283416584966, 52.399993639596445],
            [-1.60159807045715, 52.395922882315368],
            [-1.602701445528752, 52.395793538939941],
            [-1.60226094797543, 52.39493260114547],
            [-1.600780090092783, 52.395041787821242],
            [-1.600026853737001, 52.392853739216498],
            [-1.601049638972983, 52.392853596888635],
            [-1.601610266117373, 52.391464710451949],
            [-1.603152949894452, 52.390346127172599],
            [-1.602516257810927, 52.389738952395149],
            [-1.601077394987723, 52.389283702241784],
            [-1.603294335811607, 52.388178176897966],
            [-1.60200386097112, 52.387402486880845],
            [-1.603433123027288, 52.385970660276499],
            [-1.603780642247535, 52.386049140172602],
            [-1.604151947694905, 52.385603572534201],
            [-1.605042729527943, 52.385723422164297],
            [-1.605466083722786, 52.384876164146029],
            [-1.604592924139339, 52.38475907362281],
            [-1.605179604623403, 52.383884492849148],
            [-1.607519513005577, 52.384610593460557],
            [-1.608816552308002, 52.382981378168253],
            [-1.609056621714895, 52.383413699482361],
            [-1.609785436251322, 52.383403519761501],
            [-1.609404305914008, 52.382972532255856],
            [-1.61029299528009, 52.382490893860087],
            [-1.606925731914496, 52.380991880363112],
            [-1.608030545222384, 52.376339539559254],
            [-1.60891802310586, 52.376480925867106],
            [-1.608907729730562, 52.375816518284722],
            [-1.611701594225006, 52.374652505978752],
            [-1.615381597592444, 52.374439775558216],
            [-1.616370452041741, 52.37457694050029],
            [-1.618155528930268, 52.376240503823411],
            [-1.618247694168126, 52.373386422559996],
            [-1.617495327639886, 52.373421750573073],
            [-1.617051420795041, 52.372607602591394],
            [-1.617688068697389, 52.371852689557841],
            [-1.618541006719166, 52.371563262818164],
            [-1.619025676158177, 52.37054264207049],
            [-1.622243947967136, 52.371727081239911],
            [-1.623686017963637, 52.371763143736814],
            [-1.624847517448433, 52.370241201033402],
            [-1.626626331458455, 52.36917879694677],
            [-1.620977055485805, 52.367868040663581],
            [-1.623411396269357, 52.365535672836153],
            [-1.626505333446561, 52.366631497576549],
            [-1.629777126959861, 52.365090978240929],
            [-1.63158293244515, 52.365689069964127],
            [-1.633458697013218, 52.364226801557983],
            [-1.63646900225487, 52.365153090926235],
            [-1.638096868112226, 52.363992955879056],
            [-1.641764014065148, 52.362519841201419],
            [-1.644075066970895, 52.362201373994317],
            [-1.644853397195642, 52.361831520172132],
            [-1.644998220723142, 52.360984180706325],
            [-1.646478357957168, 52.359519618073968],
            [-1.648428519761421, 52.356727684985991],
            [-1.651320701070445, 52.356405408166111],
            [-1.653096264078147, 52.359922196182104],
            [-1.66089819541689, 52.365654483830781],
            [-1.664059790745464, 52.363953574645315],
            [-1.666073933179936, 52.364438454334724],
            [-1.666822122813692, 52.364933225690976],
            [-1.668114948673395, 52.364478361479598],
            [-1.669994560960855, 52.364894471376317],
            [-1.672542505075806, 52.363112508288303],
            [-1.675064959071781, 52.363778444346508],
            [-1.677949659379458, 52.364118071023981],
            [-1.679630646739225, 52.363579629530292],
            [-1.68061368956205, 52.363701857264317],
            [-1.684056329754708, 52.36323821979687],
            [-1.684566789498749, 52.36311461877245],
            [-1.684716397184978, 52.362520767298626],
            [-1.683700771250674, 52.360381091485721],
            [-1.685694159855858, 52.358208085400328],
            [-1.686186937048829, 52.358084430350416],
            [-1.685872368470463, 52.356888800489337],
            [-1.685414490733866, 52.35685521890948],
            [-1.684940459693878, 52.355995394865921],
            [-1.687553358534937, 52.354421851948317],
            [-1.691372621685064, 52.353102231221982],
            [-1.692635798257091, 52.351543026263521],
            [-1.693894823095474, 52.350997889991426],
            [-1.696241312655109, 52.354365369611948],
            [-1.698101176934527, 52.355716860426938],
            [-1.700026828273603, 52.354740032075242],
            [-1.704098984155336, 52.35419469617937],
            [-1.707224466210591, 52.355141947462208],
            [-1.710279341343358, 52.355005626513005],
            [-1.711382616189735, 52.355362536656401],
            [-1.71264406173732, 52.356237654961056],
            [-1.712846069141845, 52.356786546220505],
            [-1.715102842191403, 52.357933755212898],
            [-1.717149943344907, 52.358130158504842],
            [-1.718346121037504, 52.358676918035393],
            [-1.718765847687639, 52.357323996770397],
            [-1.718555762840691, 52.355722347088189],
            [-1.720218439130974, 52.357259116030363],
            [-1.720834127790254, 52.359052311775457],
            [-1.720097530709836, 52.363154582346858],
            [-1.718032304018744, 52.366000458182022],
            [-1.717738724248918, 52.367822962213019],
            [-1.718242337822689, 52.369463068119572],
            [-1.720267282030734, 52.372558687616184],
            [-1.720805180543621, 52.372510512397497],
            [-1.720871838641358, 52.371955078430091],
            [-1.723291135892036, 52.370754283387683],
            [-1.72564786355416, 52.370624020738184],
            [-1.726057086338442, 52.370240190514565],
            [-1.727578245314455, 52.369840045153573],
            [-1.728583363909922, 52.370478858026409],
            [-1.728856566885124, 52.369996713348264],
            [-1.729807604531002, 52.370113963066274],
            [-1.730548630985208, 52.370711700067943],
            [-1.734039969618662, 52.370702518700682],
            [-1.73524240615205, 52.370047136629942],
            [-1.735759051024994, 52.36887507691511],
            [-1.736554872080154, 52.368418353890526],
            [-1.738331872014366, 52.364956592680912],
            [-1.737908743975387, 52.362750369978919],
            [-1.738297612286962, 52.362052695281768],
            [-1.73906588405202, 52.361760413980576],
            [-1.73918623760635, 52.361271614521769],
            [-1.740936561724806, 52.360029423638252],
            [-1.74141733175071, 52.357157218162364],
            [-1.742339726871897, 52.356855363026803],
            [-1.743581757656226, 52.355360299301609],
            [-1.748782850830685, 52.35475563165619],
            [-1.75109512199638, 52.355056295823267],
            [-1.752166675521673, 52.355893732226058],
            [-1.753784997712324, 52.356109279301421],
            [-1.75963990758788, 52.358394932244821],
            [-1.75999534819867, 52.35919128326649],
            [-1.759614521249554, 52.362536647727275],
            [-1.762808112054224, 52.364260212820305],
            [-1.767069056217079, 52.364696609099653],
            [-1.770170904276284, 52.364088647270698],
            [-1.77008285746624, 52.364642269648208],
            [-1.772033901537913, 52.365348175494312],
            [-1.775748127946893, 52.365900975042159],
            [-1.779232663038251, 52.36452484227766],
            [-1.78017755111861, 52.363811884303352],
            [-1.779236858243197, 52.360142149655424],
            [-1.7787239250221, 52.360232891157935],
            [-1.778928453522372, 52.357792445053363],
            [-1.778203204479544, 52.357769511395063],
            [-1.77823425661765, 52.356551402535182],
            [-1.776563193995685, 52.355119721443138],
            [-1.77351756451318, 52.3566476529287],
            [-1.772876219006016, 52.356014414936134],
            [-1.771868291338176, 52.355861441007271],
            [-1.771941902115494, 52.355252049645088],
            [-1.773160656321872, 52.355520501491682],
            [-1.774788189169947, 52.354517610993653],
            [-1.773147678660648, 52.35260316697709],
            [-1.771756138213333, 52.351690685298443],
            [-1.771894828469872, 52.351272010737432],
            [-1.774694374545814, 52.350440390043232],
            [-1.776222410077748, 52.349618891106552],
            [-1.775761067549658, 52.347948541922975],
            [-1.777688233127395, 52.347724727396965],
            [-1.779415202522793, 52.347902369280078],
            [-1.77948792181427, 52.348334932326374],
            [-1.784530869143386, 52.350097307772216],
            [-1.783754580050583, 52.350932876543581],
            [-1.784902661022887, 52.353050356209899],
            [-1.785779803822197, 52.353831395035883],
            [-1.788016839564722, 52.355170463531458],
            [-1.791823720936213, 52.356802643738042],
            [-1.797806938836906, 52.358449227446712],
            [-1.799603461281827, 52.358617702285308],
            [-1.803308900934398, 52.36242675425094],
            [-1.804207406577822, 52.362808527540373],
            [-1.805499111361535, 52.363277246265262],
            [-1.806967862988192, 52.365925451344403],
            [-1.807918037033354, 52.366608450224753],
            [-1.812811657577597, 52.366581229464849],
            [-1.8132498466592, 52.365756626440678],
            [-1.819214612552532, 52.366296305912137],
            [-1.82429132578436, 52.365639579361094],
            [-1.824539447493605, 52.366400513510932],
            [-1.82690063761543, 52.366119002458035],
            [-1.827382167366312, 52.366542242319426],
            [-1.829573583246649, 52.367252042753719],
            [-1.830317154032153, 52.365988198063896],
            [-1.831494371526543, 52.366149904201819],
            [-1.831380467068668, 52.3667520814829],
            [-1.831842521525599, 52.366901975083806],
            [-1.833080554261102, 52.366131474130434],
            [-1.834988022737246, 52.367389172001801],
            [-1.836686946305865, 52.366678609829833],
            [-1.83811331102962, 52.366592465571863],
            [-1.84155344143826, 52.365196455795569],
            [-1.842944366263208, 52.365148863417616],
            [-1.849662311577919, 52.366701191683724],
            [-1.86317571809652, 52.366559351430887],
            [-1.863352013331857, 52.367017152995679],
            [-1.868110349449402, 52.36803753431802],
            [-1.869937451503866, 52.368017978629652],
            [-1.872041858754703, 52.367584249386724],
            [-1.870532009783917, 52.368610180928428],
            [-1.87006559468406, 52.369397205840663],
            [-1.870284368794132, 52.369922469393323],
            [-1.869061599791799, 52.370183635697757],
            [-1.86828101120125, 52.371401831873278],
            [-1.86769883334983, 52.371580985555035],
            [-1.867617417944485, 52.37228482170044],
            [-1.866967573477774, 52.372492664515477],
            [-1.866568770043206, 52.373230305381469],
            [-1.865372267298426, 52.373511241292078],
            [-1.865016805354988, 52.374475477240956],
            [-1.863480172901396, 52.37596068247673],
            [-1.863104118878499, 52.377389677852761],
            [-1.861931659815603, 52.378397907334538],
            [-1.862056413356431, 52.378906893473072],
            [-1.861368743719787, 52.379896801556271],
            [-1.86182904294587, 52.381158203331957],
            [-1.860948637576505, 52.383160619884741],
            [-1.861331390211954, 52.383856004317401],
            [-1.858803191807156, 52.385603391694772],
            [-1.858745970805036, 52.38603934374165],
            [-1.857213388644082, 52.386532860888529],
            [-1.858257036912059, 52.387312657834492],
            [-1.856202781703512, 52.388714435961198],
            [-1.848773718828101, 52.394289833258597],
            [-1.846488600286559, 52.397247331453634],
            [-1.84560514098148, 52.399412794927592],
            [-1.845481209914542, 52.400391653952987],
            [-1.849690860867403, 52.401615228155741],
            [-1.850652927618861, 52.401808833027538],
            [-1.851711025811982, 52.40053986335024],
            [-1.853445012924086, 52.401530032095309],
            [-1.856992118871978, 52.402746231222103],
            [-1.861773818374758, 52.40358708624715],
            [-1.863459535851098, 52.404612112412487],
            [-1.863799719799884, 52.404404833179669],
            [-1.864786280169763, 52.404800627838952],
            [-1.86503019934141, 52.404346007967391],
            [-1.866287835771699, 52.404541619860233],
            [-1.868653926700634, 52.404195451680735],
            [-1.868753785643754, 52.404723280010892],
            [-1.869203327365062, 52.407795686347271],
            [-1.868793407452678, 52.40772421149898],
            [-1.867120340482398, 52.408772387413308],
            [-1.866258709663424, 52.409808872201218],
            [-1.865643828882323, 52.409937633503752],
            [-1.86665195082232, 52.411031066046498],
            [-1.864770357915661, 52.410981285894159],
            [-1.864138056439934, 52.410072565334524],
            [-1.862765854970763, 52.409322110720616],
            [-1.857817649270294, 52.408404681573487],
            [-1.853949549186178, 52.408544715674843],
            [-1.850842901605098, 52.410431456812383],
            [-1.850259790580341, 52.410278788870556],
            [-1.849440735155181, 52.410774901577298],
            [-1.84872595827083, 52.410003541663109],
            [-1.847824979676574, 52.409951144118786],
            [-1.843615646817769, 52.410509338697416],
            [-1.842371853412824, 52.412600578454573],
            [-1.839066496099982, 52.414335711537241],
            [-1.834650194604695, 52.417418614676066],
            [-1.833735889773195, 52.418138337694714],
            [-1.83441659551443, 52.418549238146817],
            [-1.832898535686763, 52.419499153489028],
            [-1.830731858431846, 52.422781043822326],
            [-1.828005061410254, 52.425554142852818],
            [-1.827036086067557, 52.427774173057607],
            [-1.82526970488458, 52.42929089828916],
            [-1.81924646962002, 52.430885683089585],
            [-1.817321052731554, 52.432352600978874],
            [-1.816115846413362, 52.437805891139668],
            [-1.814487763679186, 52.437729633957417],
            [-1.813404862075468, 52.438484893691736],
            [-1.811910663181445, 52.440474721314231],
            [-1.812804640956185, 52.440580421992479],
            [-1.811843494366617, 52.44139429468985],
            [-1.812422030227846, 52.441997544277292],
            [-1.811577649161041, 52.442329734035376],
            [-1.812276554480574, 52.44299790385476],
            [-1.809579645906119, 52.444761936723332],
            [-1.810016121125359, 52.445216633859204],
            [-1.809269495893413, 52.445379052329095],
            [-1.808281760898472, 52.446517392509072],
            [-1.806684879246328, 52.449666697817278],
            [-1.806072430186853, 52.449745707796104],
            [-1.806260211472925, 52.451552109283817],
            [-1.80512970690552, 52.453316792345291],
            [-1.805971012507715, 52.454079627428754],
            [-1.805394453774902, 52.454682810260103],
            [-1.80491885158696, 52.45441412561275],
            [-1.800323356896832, 52.458282959105553],
            [-1.79874947757582, 52.457455012906969],
            [-1.795689300588323, 52.455036855473175],
            [-1.795298385932525, 52.455241152741912],
            [-1.794831142936837, 52.455085716104286],
            [-1.793994597324662, 52.454285951030812],
            [-1.790912225631959, 52.45323950606624],
            [-1.791313837989803, 52.452951634937961],
            [-1.790508713993126, 52.452685008119936],
            [-1.790289632873174, 52.452958815850899],
            [-1.789426814265287, 52.452757706601837],
            [-1.78898676071366, 52.453081462914632],
            [-1.782709972144557, 52.450845083825129],
            [-1.782461582408543, 52.451086459326106],
            [-1.781645184762632, 52.451034612772361],
            [-1.781460183815492, 52.450655791719385],
            [-1.779136525616174, 52.45041414437803],
            [-1.779112883914218, 52.450139455161782],
            [-1.777957649769689, 52.450173704343243],
            [-1.777484565084801, 52.450616923380998],
            [-1.774389896731216, 52.45066500821973],
            [-1.773342030060211, 52.450992041996514],
            [-1.772484195162527, 52.450702716341958],
            [-1.769021365323292, 52.451087977616766],
            [-1.76666749395177, 52.450724661078091],
            [-1.766681833714527, 52.451074401164327],
            [-1.762773096140464, 52.451180808487315],
            [-1.761398759423457, 52.450908346579077],
            [-1.759607054636951, 52.451896322192219],
            [-1.754984449486165, 52.45626948155968],
            [-1.763385179151133, 52.463831878759692],
            [-1.763754242770841, 52.466104388639963],
            [-1.761723121245212, 52.467164735377487],
            [-1.759956307829751, 52.466973276553595],
            [-1.758736251530803, 52.467203638335832],
            [-1.759330311302352, 52.468939017339849],
            [-1.756698775393923, 52.471524556644489],
            [-1.75581054505311, 52.471887722269848],
            [-1.759169991020019, 52.475394403366693],
            [-1.759163779389539, 52.476533421752968],
            [-1.758607104793412, 52.476560157467773],
            [-1.758718934131453, 52.477649971232033],
            [-1.759103388122145, 52.47950718472638],
            [-1.759664619518241, 52.479461576949753],
            [-1.759862908946363, 52.480362774944041],
            [-1.758879207206916, 52.480385049451257],
            [-1.759813667984731, 52.482918525157359],
            [-1.759053572098694, 52.483239721477652],
            [-1.759481533940423, 52.483879778309799],
            [-1.760419143050086, 52.483953598225305],
            [-1.761102157217453, 52.485087714069294],
            [-1.761568315197087, 52.485208219908479],
            [-1.760868228465483, 52.486407868508941],
            [-1.761486007118216, 52.486814561594564],
            [-1.762072034574386, 52.486556829072391],
            [-1.763101103836516, 52.486612831387546],
            [-1.763662536880495, 52.487095815093831],
            [-1.761120331920626, 52.488260310310729],
            [-1.762332192979646, 52.488563911774236],
            [-1.762366668514559, 52.490093173713653],
            [-1.761602325853974, 52.49036313480017],
            [-1.761015547448199, 52.491565708252089],
            [-1.760846828942857, 52.493619573310006],
            [-1.760344181279097, 52.493693174132915],
            [-1.759907128050034, 52.494704558227269],
            [-1.758970616607014, 52.495197104004589],
            [-1.756966315743451, 52.496739281112788],
            [-1.755844944990932, 52.499480708325912],
            [-1.758739111345695, 52.500093459227173],
            [-1.758898586494452, 52.499753963864563],
            [-1.761499665511486, 52.500094553935966],
            [-1.761549967448314, 52.499781805024703],
            [-1.76419071863065, 52.499869798264704],
            [-1.76418140835553, 52.500784956266259],
            [-1.770278955776352, 52.501282393339757],
            [-1.770363894781944, 52.50166193362044],
            [-1.771406012639793, 52.501828464863927],
            [-1.771544163198412, 52.501606679743993],
            [-1.772030530392843, 52.501846749071845],
            [-1.772463343715483, 52.501617438544322],
            [-1.779402661246355, 52.501152297127035],
            [-1.78217202460218, 52.501172703819321],
            [-1.781986553805935, 52.501442060950581],
            [-1.782548468771238, 52.501608508481389],
            [-1.791503190604487, 52.502953317081051],
            [-1.794534165116309, 52.502814772526023],
            [-1.796946572238601, 52.502636435262666],
            [-1.798250745283987, 52.502867007308538],
            [-1.799301818670281, 52.503685078391499],
            [-1.799365769439348, 52.504848484258332],
            [-1.798373033193254, 52.505765570472413],
            [-1.794314842303555, 52.506568588855366],
            [-1.794032358007655, 52.507747577166867],
            [-1.794832808941605, 52.508289260532749],
            [-1.793927141239781, 52.50882438779454],
            [-1.791659525016752, 52.509155747011711],
            [-1.787169945210201, 52.50949657768551],
            [-1.782524364113482, 52.510384438506073],
            [-1.773610239788461, 52.513232832245627],
            [-1.768491884971602, 52.514228915064749],
            [-1.764408802036776, 52.514477978842152],
            [-1.7605018087572, 52.51431192330412],
            [-1.753530630736764, 52.512951834703429],
            [-1.74725308146278, 52.510189488023464],
            [-1.744501338613773, 52.508303789928213],
            [-1.743249539427165, 52.506942703858883],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000030",
        LAD13CDO: "00CU",
        LAD13NM: "Walsall",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.953669878394293, 52.662717200677811],
            [-1.950223937524228, 52.659620663816625],
            [-1.949524282260372, 52.659929614595619],
            [-1.945731115998484, 52.657097993740798],
            [-1.945408367639228, 52.656282481458234],
            [-1.940365764588414, 52.656307930692918],
            [-1.940492083932068, 52.656912101014967],
            [-1.938642134449693, 52.657297718430634],
            [-1.936958646941284, 52.659107361593932],
            [-1.936405830969313, 52.659028857224015],
            [-1.936584750756709, 52.660033100491688],
            [-1.935480835597235, 52.659699888827944],
            [-1.933575222490302, 52.661593865622521],
            [-1.930278793618665, 52.65932657936353],
            [-1.928596069328831, 52.658583932168249],
            [-1.925812069315603, 52.656053428945356],
            [-1.923690144479361, 52.655511805960394],
            [-1.92246524670274, 52.656002749783184],
            [-1.918977522431548, 52.65700906777009],
            [-1.912848842926286, 52.658217442219851],
            [-1.908666402066408, 52.653983753335339],
            [-1.916590160691195, 52.652241980485393],
            [-1.91587474124645, 52.65145667579089],
            [-1.918021019361084, 52.649878684946586],
            [-1.915266587562639, 52.647947563587742],
            [-1.912353371466714, 52.646459453052998],
            [-1.909746263221689, 52.644263114238527],
            [-1.909053628774628, 52.644730950088402],
            [-1.90509017070289, 52.643191509321859],
            [-1.906478699331461, 52.642122834430076],
            [-1.905882411531384, 52.641766371009133],
            [-1.907535634029434, 52.640587319345514],
            [-1.907518086375242, 52.639796210682555],
            [-1.908017032204234, 52.64005460278652],
            [-1.913159794728176, 52.63701365423092],
            [-1.912743695135322, 52.636708597948378],
            [-1.913126150269019, 52.636092183221201],
            [-1.913673319221064, 52.63586783884854],
            [-1.914141162988819, 52.636158545745225],
            [-1.915991396747901, 52.635269885331176],
            [-1.909981734867446, 52.631753199613918],
            [-1.908536709418057, 52.630347001416396],
            [-1.904693569454474, 52.627752240926917],
            [-1.897492528453996, 52.624740077331175],
            [-1.894334738075023, 52.622646296179738],
            [-1.892790721591403, 52.621577829821589],
            [-1.885653076832466, 52.614768627087713],
            [-1.885021730365923, 52.610514962095387],
            [-1.886611868971874, 52.608361644681814],
            [-1.886913333718824, 52.607128536139186],
            [-1.892246691171602, 52.600044160652672],
            [-1.892272535886237, 52.599136216413321],
            [-1.890188071414223, 52.59845827546696],
            [-1.890395222807993, 52.598274176355993],
            [-1.890633481053544, 52.598047853560608],
            [-1.88757649836315, 52.596668662600294],
            [-1.88440538633381, 52.594411878064584],
            [-1.878194236812975, 52.587691190778017],
            [-1.875160828357445, 52.585677020710335],
            [-1.873242877978444, 52.584891976000932],
            [-1.872571225704478, 52.584929013916486],
            [-1.872862881137433, 52.584106759668707],
            [-1.875808222887305, 52.580638014898014],
            [-1.878526005509143, 52.569620210286359],
            [-1.879779293902805, 52.568835775664056],
            [-1.882752951310361, 52.567441731407783],
            [-1.888505624427755, 52.563810888037715],
            [-1.891639403212194, 52.562439244568012],
            [-1.893281056953587, 52.561307113464146],
            [-1.896688484952971, 52.558842417049192],
            [-1.898102320040836, 52.556607865201045],
            [-1.899799279443741, 52.554983945607127],
            [-1.901285832124437, 52.554399052979591],
            [-1.908162810667968, 52.553211618677295],
            [-1.911840723918621, 52.551928857670312],
            [-1.916805278458522, 52.548628668249513],
            [-1.918163013253447, 52.547291021915512],
            [-1.923099515089698, 52.551058372198753],
            [-1.925041594493367, 52.552053890804757],
            [-1.929384049772152, 52.553533582798444],
            [-1.933072258300379, 52.553825189924254],
            [-1.932576135086986, 52.556126309807617],
            [-1.934658316517013, 52.55743279759605],
            [-1.932608143819226, 52.559314125425331],
            [-1.93393317210759, 52.560018773489389],
            [-1.935573077446307, 52.560287570622329],
            [-1.936443235030868, 52.56039142206896],
            [-1.937960335192715, 52.559895085329167],
            [-1.940289967870004, 52.560634343403713],
            [-1.940357376125366, 52.560965203173581],
            [-1.942315145859459, 52.560745917797703],
            [-1.942851721670695, 52.559887647438394],
            [-1.944227967817988, 52.559892795528619],
            [-1.947772510050436, 52.557466248760981],
            [-1.949435388555758, 52.556984212425299],
            [-1.949331057130296, 52.556641655104748],
            [-1.951074282022269, 52.556817686281775],
            [-1.952758433127291, 52.557076374898024],
            [-1.951878970045747, 52.561482840990422],
            [-1.954074707438531, 52.56219660187211],
            [-1.955073811683732, 52.561782551626621],
            [-1.959938963524296, 52.56165213619844],
            [-1.964149232980662, 52.563211414339094],
            [-1.966850803131027, 52.562557735714613],
            [-1.967350520330711, 52.561052076399662],
            [-1.96697734329691, 52.56101331745726],
            [-1.967120102974612, 52.559465307194358],
            [-1.97454291446703, 52.559003243618911],
            [-1.974435902695743, 52.557843531683787],
            [-1.975246779260659, 52.555879422023303],
            [-1.975502129598058, 52.555545052430695],
            [-1.979249940525774, 52.558722769660619],
            [-1.980558379713024, 52.558550383928896],
            [-1.983729057630331, 52.560347923319334],
            [-1.986232037979713, 52.561200471529524],
            [-1.989056469201481, 52.563000524388137],
            [-1.992897693315293, 52.564055315599383],
            [-1.994412763163014, 52.563923242910477],
            [-1.999804688457292, 52.565207110055702],
            [-2.005782676677323, 52.566870073549694],
            [-2.010983727705788, 52.569049725909025],
            [-2.011778610257692, 52.567761406148023],
            [-2.012503192623068, 52.568405001582967],
            [-2.016150302111981, 52.568399158306903],
            [-2.016815953852471, 52.569069704905509],
            [-2.027053860719149, 52.564590852656202],
            [-2.025373680364036, 52.562264660228394],
            [-2.031377647983185, 52.560290838488562],
            [-2.034857416413562, 52.558463122609204],
            [-2.037513401479654, 52.557818632536524],
            [-2.040625199649167, 52.554128167554509],
            [-2.041066422207657, 52.554373437122273],
            [-2.041514414578524, 52.553976827726935],
            [-2.048467479254928, 52.551726708296584],
            [-2.050987603716203, 52.552713628539145],
            [-2.061957917047097, 52.558233529529168],
            [-2.06085409395956, 52.558933513919627],
            [-2.060169287723524, 52.558655180423003],
            [-2.058474800044346, 52.560010801703406],
            [-2.056617697619897, 52.560035980965601],
            [-2.056658152138608, 52.560520513696872],
            [-2.056118286138558, 52.560537852685471],
            [-2.056102573887405, 52.560938806829228],
            [-2.055356211000055, 52.560976018098621],
            [-2.056635004996041, 52.562011938400424],
            [-2.057955216377887, 52.564201219701999],
            [-2.057195218614921, 52.565134735236406],
            [-2.053645502825261, 52.566140568297065],
            [-2.05309385748535, 52.568666060052756],
            [-2.05002755312342, 52.572124886981207],
            [-2.050864505862123, 52.572423889508578],
            [-2.055440741236044, 52.572974699037438],
            [-2.055320381646588, 52.573475488365133],
            [-2.057101710600451, 52.573746131292602],
            [-2.056985613039616, 52.5741075780534],
            [-2.058169841076792, 52.574730892772543],
            [-2.057040568508436, 52.575515356349349],
            [-2.057708064435229, 52.575910583229238],
            [-2.057343882046719, 52.576136405232404],
            [-2.056803196583335, 52.57567009575456],
            [-2.05517081436718, 52.576559064550459],
            [-2.056292678065358, 52.576850702953408],
            [-2.056748000849397, 52.5774842662629],
            [-2.06305128021751, 52.580002705254053],
            [-2.065879630971269, 52.581513244626997],
            [-2.066879641571711, 52.582094322203432],
            [-2.068213838485616, 52.582125022589452],
            [-2.068006879385563, 52.58285421507928],
            [-2.068654099531433, 52.583361764023401],
            [-2.077799056677387, 52.586112361988683],
            [-2.075760879380518, 52.587039636930236],
            [-2.074398934631835, 52.58814265223841],
            [-2.07330375097631, 52.590806114075392],
            [-2.072285419822122, 52.59092271050946],
            [-2.073216981703913, 52.590998549448898],
            [-2.073330351056593, 52.591705076516071],
            [-2.071881205791557, 52.591970267866785],
            [-2.070864399953889, 52.593047859409943],
            [-2.070873880331964, 52.594344180829069],
            [-2.068563072111274, 52.593087875137421],
            [-2.066116039831507, 52.594398184583156],
            [-2.065721097173566, 52.596822949634884],
            [-2.066338130416475, 52.597750349596737],
            [-2.064902850767016, 52.597573150302324],
            [-2.065004245808769, 52.597254856268492],
            [-2.06449777877651, 52.597198498647209],
            [-2.064157524723464, 52.597729980173682],
            [-2.06291728878525, 52.597661426912467],
            [-2.062690216878937, 52.597861120914068],
            [-2.06196937238427, 52.597591808462838],
            [-2.061050158410384, 52.597986939154353],
            [-2.060711780810265, 52.597777651979584],
            [-2.059996074518774, 52.598015348413419],
            [-2.057875978649138, 52.597986741748265],
            [-2.057000851389227, 52.598259558349781],
            [-2.054863452790164, 52.600920647134828],
            [-2.054604961036473, 52.600831768390087],
            [-2.052402520992493, 52.604888053804324],
            [-2.05156550195592, 52.606316896926323],
            [-2.051187513249555, 52.606344031001861],
            [-2.051072765529856, 52.609237885623777],
            [-2.048027802268823, 52.616019239950468],
            [-2.052755644901799, 52.617295560847964],
            [-2.050724053156066, 52.620506691071405],
            [-2.048492155806437, 52.620534599818775],
            [-2.047649004764864, 52.62079564636516],
            [-2.047385261466216, 52.62140615627785],
            [-2.031894410615846, 52.61740734127072],
            [-2.031269282397292, 52.616919365965416],
            [-2.030129052298575, 52.616938542443783],
            [-2.032096250489813, 52.618717092468401],
            [-2.032014073385668, 52.61944888017586],
            [-2.035515189584006, 52.621783409885296],
            [-2.030534265910454, 52.623987295621902],
            [-2.030861247334186, 52.624709086094747],
            [-2.029366923231621, 52.625692946373384],
            [-2.028979737309623, 52.625490773521634],
            [-2.026508279747262, 52.625564174613309],
            [-2.026645512230149, 52.62531063297623],
            [-2.025351233654415, 52.625002571914635],
            [-2.024085360497526, 52.62526983399524],
            [-2.020230970822165, 52.628078057278486],
            [-2.019432196243237, 52.629158758394013],
            [-2.018889722606011, 52.628554737446301],
            [-2.016117288848304, 52.630238929532304],
            [-2.014377827573773, 52.633150929506577],
            [-2.012533938779133, 52.633453197719838],
            [-2.010023840510274, 52.634600530823064],
            [-2.009443067894103, 52.634248182729785],
            [-2.007575500054088, 52.63478860327762],
            [-2.003852166360919, 52.637136905828143],
            [-1.995742552509269, 52.638304674226667],
            [-1.993285127429994, 52.638154437668668],
            [-1.990861979367305, 52.636655695112069],
            [-1.988693625836802, 52.639121392122554],
            [-1.986556421696227, 52.640393218173074],
            [-1.984774465367116, 52.639636971692092],
            [-1.982836481910927, 52.641283626508525],
            [-1.981322334035939, 52.639813582897943],
            [-1.978733166394957, 52.640052278650003],
            [-1.97621227619409, 52.63968052985085],
            [-1.975171005175376, 52.641329930843497],
            [-1.973334521736218, 52.640585187749906],
            [-1.971052222610677, 52.641474638756456],
            [-1.969485677529926, 52.641546166894656],
            [-1.967449220210095, 52.641559114097582],
            [-1.967396438061744, 52.640990050955658],
            [-1.964573989854509, 52.640779786766451],
            [-1.963998324647959, 52.64176129107512],
            [-1.963021284441064, 52.643754010985624],
            [-1.960969321835857, 52.642798650398369],
            [-1.960286810363384, 52.644146881428313],
            [-1.95996447788826, 52.649196290210391],
            [-1.960777368501356, 52.649260387098572],
            [-1.96056351706605, 52.653694933771092],
            [-1.9554725699387, 52.653590659195224],
            [-1.955080297498289, 52.654120903910979],
            [-1.95630961319177, 52.65620876693449],
            [-1.955815897629538, 52.656164535522358],
            [-1.956331000961266, 52.657003463238048],
            [-1.959951359544571, 52.662020977524165],
            [-1.954576320093504, 52.662624955818764],
            [-1.953669878394293, 52.662717200677811],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000031",
        LAD13CDO: "00CW",
        LAD13NM: "Wolverhampton",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.113806844043876, 52.637894741872536],
            [-2.111640843887715, 52.635123477634217],
            [-2.110257112270101, 52.635437618789368],
            [-2.109375696845018, 52.634545757918275],
            [-2.106640629468464, 52.634528479709388],
            [-2.105000863557422, 52.635294973384589],
            [-2.101247116718812, 52.635037546639616],
            [-2.1008608639133, 52.633497037928024],
            [-2.099254531897127, 52.632745957781616],
            [-2.098577368216316, 52.631888904494872],
            [-2.098827843169789, 52.628962537714663],
            [-2.098928580593445, 52.628430261166642],
            [-2.097987902624488, 52.627297439333162],
            [-2.098459773466079, 52.626905993559028],
            [-2.09814729796498, 52.626568239401799],
            [-2.097636194776143, 52.626591137165065],
            [-2.097325426719142, 52.625688823861545],
            [-2.09738169416398, 52.623755983399626],
            [-2.094816536800327, 52.62071234459102],
            [-2.093181025805328, 52.619863215836759],
            [-2.088402515371101, 52.619815646893926],
            [-2.086274163258037, 52.616892855289379],
            [-2.084927180296852, 52.613869678482381],
            [-2.083118427404123, 52.612549471135274],
            [-2.081787978289949, 52.612653782312961],
            [-2.081008259717801, 52.61188929139103],
            [-2.071752935676121, 52.612771780733496],
            [-2.069884537330537, 52.612665024737801],
            [-2.067331598934021, 52.61206059929593],
            [-2.067362405218131, 52.613843250370429],
            [-2.068713071520479, 52.618100917015354],
            [-2.064527646520954, 52.619442752252247],
            [-2.064006644471728, 52.62073036754046],
            [-2.059347574056023, 52.621663241811412],
            [-2.052863496310099, 52.622200317512799],
            [-2.052111593201458, 52.622183571786138],
            [-2.05187165521077, 52.621648787973278],
            [-2.050597821120101, 52.621197157625744],
            [-2.050724053156066, 52.620506691071405],
            [-2.052755644901799, 52.617295560847964],
            [-2.048027802268823, 52.616019239950468],
            [-2.051072765529856, 52.609237885623777],
            [-2.051187513249555, 52.606344031001861],
            [-2.05156550195592, 52.606316896926323],
            [-2.052402520992493, 52.604888053804324],
            [-2.054604961036473, 52.600831768390087],
            [-2.054863452790164, 52.600920647134828],
            [-2.057000851389227, 52.598259558349781],
            [-2.057875978649138, 52.597986741748265],
            [-2.059996074518774, 52.598015348413419],
            [-2.060711780810265, 52.597777651979584],
            [-2.061050158410384, 52.597986939154353],
            [-2.06196937238427, 52.597591808462838],
            [-2.062690216878937, 52.597861120914068],
            [-2.06291728878525, 52.597661426912467],
            [-2.064157524723464, 52.597729980173682],
            [-2.06449777877651, 52.597198498647209],
            [-2.065004245808769, 52.597254856268492],
            [-2.064902850767016, 52.597573150302324],
            [-2.066338130416475, 52.597750349596737],
            [-2.065721097173566, 52.596822949634884],
            [-2.066116039831507, 52.594398184583156],
            [-2.068563072111274, 52.593087875137421],
            [-2.070873880331964, 52.594344180829069],
            [-2.070864399953889, 52.593047859409943],
            [-2.071881205791557, 52.591970267866785],
            [-2.073330351056593, 52.591705076516071],
            [-2.073216981703913, 52.590998549448898],
            [-2.072285419822122, 52.59092271050946],
            [-2.07330375097631, 52.590806114075392],
            [-2.074398934631835, 52.58814265223841],
            [-2.075760879380518, 52.587039636930236],
            [-2.077799056677387, 52.586112361988683],
            [-2.068654099531433, 52.583361764023401],
            [-2.068006879385563, 52.58285421507928],
            [-2.068213838485616, 52.582125022589452],
            [-2.066879641571711, 52.582094322203432],
            [-2.065879630971269, 52.581513244626997],
            [-2.06305128021751, 52.580002705254053],
            [-2.056748000849397, 52.5774842662629],
            [-2.056292678065358, 52.576850702953408],
            [-2.05517081436718, 52.576559064550459],
            [-2.056803196583335, 52.57567009575456],
            [-2.057343882046719, 52.576136405232404],
            [-2.057708064435229, 52.575910583229238],
            [-2.057040568508436, 52.575515356349349],
            [-2.058169841076792, 52.574730892772543],
            [-2.056985613039616, 52.5741075780534],
            [-2.057101710600451, 52.573746131292602],
            [-2.055320381646588, 52.573475488365133],
            [-2.055440741236044, 52.572974699037438],
            [-2.050864505862123, 52.572423889508578],
            [-2.05002755312342, 52.572124886981207],
            [-2.05309385748535, 52.568666060052756],
            [-2.053645502825261, 52.566140568297065],
            [-2.057195218614921, 52.565134735236406],
            [-2.057955216377887, 52.564201219701999],
            [-2.056635004996041, 52.562011938400424],
            [-2.055356211000055, 52.560976018098621],
            [-2.056102573887405, 52.560938806829228],
            [-2.056118286138558, 52.560537852685471],
            [-2.056658152138608, 52.560520513696872],
            [-2.056617697619897, 52.560035980965601],
            [-2.058474800044346, 52.560010801703406],
            [-2.060169287723524, 52.558655180423003],
            [-2.06085409395956, 52.558933513919627],
            [-2.061957917047097, 52.558233529529168],
            [-2.050987603716203, 52.552713628539145],
            [-2.050771182897034, 52.550499522154567],
            [-2.061474435715536, 52.546907472859694],
            [-2.063373737715926, 52.548915699868623],
            [-2.066621606414655, 52.548304397427856],
            [-2.069016611814972, 52.547431903136349],
            [-2.070385066063635, 52.550164909157836],
            [-2.072129814669712, 52.550232178050344],
            [-2.073947572642166, 52.549864266624702],
            [-2.075275981105149, 52.553102469658171],
            [-2.078049588673318, 52.55690966502867],
            [-2.079784355345476, 52.557011888105905],
            [-2.080816021304468, 52.556564390952126],
            [-2.081373965384061, 52.55519305633193],
            [-2.081464488724571, 52.553109147390465],
            [-2.082197853887875, 52.552515308862823],
            [-2.085012417865978, 52.552812677101983],
            [-2.086426954563793, 52.551396647404303],
            [-2.088576870928496, 52.550497866456048],
            [-2.089041900231516, 52.551459430774464],
            [-2.089158753141215, 52.550177389627045],
            [-2.090350558370301, 52.549558880507327],
            [-2.090057099171816, 52.548847108237716],
            [-2.09210507512319, 52.547953734409525],
            [-2.093702532965393, 52.547439156449173],
            [-2.09418766616945, 52.548111211740618],
            [-2.096202884748703, 52.54645545296988],
            [-2.097319661826102, 52.546006842711144],
            [-2.10232469910725, 52.546085318221486],
            [-2.105090436386301, 52.545813193502767],
            [-2.106110022320871, 52.545463475167644],
            [-2.107765615486432, 52.54410630470646],
            [-2.108250308361803, 52.543929660094747],
            [-2.109644218989144, 52.546476101240806],
            [-2.111990759798819, 52.546054073070785],
            [-2.111474292797403, 52.547077607765516],
            [-2.112206450956593, 52.547939940962188],
            [-2.114528845387611, 52.547285947175496],
            [-2.115512624631521, 52.548475246519487],
            [-2.114662337122233, 52.548710710244755],
            [-2.117237284514109, 52.552063196629511],
            [-2.117832451954526, 52.551817181489966],
            [-2.118481950982015, 52.552578871345403],
            [-2.11796188515381, 52.552784360415266],
            [-2.121533281431563, 52.556800101722246],
            [-2.127603921217642, 52.558191622335464],
            [-2.128396000089886, 52.557683737067102],
            [-2.127332945134654, 52.557322596675405],
            [-2.127215965156857, 52.556147749773373],
            [-2.128074814078472, 52.555787228059955],
            [-2.128337359407082, 52.555284410673949],
            [-2.128387653715141, 52.55382170748824],
            [-2.13169830238914, 52.554160577199944],
            [-2.133492914547103, 52.554051584190418],
            [-2.134927981969564, 52.554531810285731],
            [-2.139071832210978, 52.558057319565229],
            [-2.14096283606637, 52.558524349085054],
            [-2.141577167359777, 52.557831396650059],
            [-2.14224215489162, 52.557755982442202],
            [-2.143700507349636, 52.555820501986716],
            [-2.145279493944522, 52.554722708820584],
            [-2.146798852143462, 52.55566476570867],
            [-2.147883376328747, 52.554919953049613],
            [-2.148922615691456, 52.554749638947037],
            [-2.153254529203422, 52.554777363860978],
            [-2.153060371308317, 52.553660176757866],
            [-2.155347394243755, 52.553933181527171],
            [-2.15736670435256, 52.553575411115155],
            [-2.157276782036206, 52.554411587175395],
            [-2.157899516917852, 52.554502453437252],
            [-2.157726751275802, 52.554856884287361],
            [-2.160039394209756, 52.555254723172851],
            [-2.161961535093451, 52.554938359329043],
            [-2.16203640180368, 52.555242113737549],
            [-2.163690512283067, 52.555434015584162],
            [-2.164547752172259, 52.55590838858673],
            [-2.165574903089488, 52.555672319235576],
            [-2.165012008818893, 52.55385176205337],
            [-2.16708682871436, 52.553762544981709],
            [-2.169050068340832, 52.554191266806463],
            [-2.170148567533068, 52.556018226996301],
            [-2.173054642722681, 52.554625975335838],
            [-2.175540403776838, 52.554392170203499],
            [-2.176396806745954, 52.556467551666401],
            [-2.175941011632891, 52.559773798485331],
            [-2.174812269336687, 52.560067642848288],
            [-2.175532017081427, 52.564471599523166],
            [-2.179637048087789, 52.563974588647987],
            [-2.18020968720309, 52.565114527969236],
            [-2.179719322774254, 52.565333727415833],
            [-2.179933152604613, 52.565673218108238],
            [-2.180432855287761, 52.565571770427681],
            [-2.180964339109014, 52.566380942602542],
            [-2.176980338626505, 52.566943443862513],
            [-2.179211729532833, 52.571091587819993],
            [-2.178578607130038, 52.571054788403828],
            [-2.180342614793551, 52.574489821237279],
            [-2.180678374933675, 52.574685286874697],
            [-2.182594172806843, 52.574094409578755],
            [-2.183495531018088, 52.576514872967145],
            [-2.182968918247667, 52.576914837033648],
            [-2.183450039240708, 52.576928475358947],
            [-2.188282745188157, 52.574160104657594],
            [-2.188955295833783, 52.576488295558555],
            [-2.189510715702517, 52.576622254150877],
            [-2.189436714165684, 52.57725525621462],
            [-2.196410667822825, 52.577902833589256],
            [-2.196944768151252, 52.578538424750199],
            [-2.197488363067683, 52.578328954436166],
            [-2.198126080413951, 52.579029992860605],
            [-2.201808277928792, 52.578764860343362],
            [-2.203327678360637, 52.581185913453844],
            [-2.202193379537798, 52.581634651906626],
            [-2.203967263710894, 52.583847595714758],
            [-2.200061705335815, 52.583446128696849],
            [-2.199905536453055, 52.583830257793473],
            [-2.201257877847893, 52.58521059757971],
            [-2.20670091002063, 52.585164352704332],
            [-2.206881688861147, 52.586568245177901],
            [-2.204205702359792, 52.586561208515029],
            [-2.202478960365045, 52.586914779561042],
            [-2.20056771305904, 52.58856496860156],
            [-2.199421842759269, 52.588464422278861],
            [-2.199313292425316, 52.588938368012904],
            [-2.198436067791889, 52.588840056105347],
            [-2.198205782466446, 52.58916227725009],
            [-2.197621191025114, 52.589147073765169],
            [-2.197841027624009, 52.588143443942911],
            [-2.193481647229444, 52.588664872297812],
            [-2.189314446241885, 52.588593404529732],
            [-2.188502363164513, 52.590594932772646],
            [-2.188557465791511, 52.592081757916333],
            [-2.188429121036394, 52.594849920975257],
            [-2.189877288849817, 52.599273279575137],
            [-2.1909427671943, 52.602213022520225],
            [-2.191936973573895, 52.603343227236721],
            [-2.193190197693576, 52.602925859200155],
            [-2.193049915840597, 52.603594029077335],
            [-2.194606476988517, 52.605319314552546],
            [-2.196388857175897, 52.605014312389798],
            [-2.196701061120865, 52.606144707973179],
            [-2.196401866585769, 52.606600087867896],
            [-2.195426688969714, 52.606468654130822],
            [-2.195203352710923, 52.607050661484195],
            [-2.194670693899162, 52.606812411136872],
            [-2.194538780569748, 52.607034675639291],
            [-2.193795032138119, 52.606812050920993],
            [-2.192097301299229, 52.60691011160376],
            [-2.19195873389909, 52.607976523997159],
            [-2.188539175633068, 52.608088102810449],
            [-2.188492647654825, 52.608944003453672],
            [-2.187214012958161, 52.60899457870368],
            [-2.186621353425473, 52.606451408820831],
            [-2.184259520253736, 52.606642997727782],
            [-2.183907073880316, 52.606052018894516],
            [-2.180318484479604, 52.604886175802136],
            [-2.177692705254516, 52.607339863641251],
            [-2.173754501039652, 52.609227273752417],
            [-2.172459028849805, 52.610259397354099],
            [-2.171025044600199, 52.610629161017044],
            [-2.170719365101915, 52.609116624361526],
            [-2.169162202380768, 52.60932292946746],
            [-2.169557783826916, 52.610420013910911],
            [-2.168694556111212, 52.610599245652402],
            [-2.168629024572515, 52.61122322899179],
            [-2.167615239196657, 52.611427838732098],
            [-2.170968550593392, 52.614318642579484],
            [-2.169295117932188, 52.615060007982507],
            [-2.16586874141262, 52.615872142961614],
            [-2.166010418087955, 52.616232433566424],
            [-2.165369043382811, 52.616135343630674],
            [-2.165021840240224, 52.616497216868332],
            [-2.169528857410121, 52.618213280767506],
            [-2.168961852287201, 52.618259939794157],
            [-2.168361966933144, 52.618975480762728],
            [-2.168718762711885, 52.619188928568427],
            [-2.168467354482414, 52.619877902106545],
            [-2.167503312239646, 52.620396182013963],
            [-2.164616616887718, 52.61910840693691],
            [-2.16173634313564, 52.619114178383732],
            [-2.162791363040006, 52.621206444550275],
            [-2.160292628159345, 52.621772615548863],
            [-2.156057458465286, 52.623806366479997],
            [-2.151558992636969, 52.624575445786647],
            [-2.152432195313458, 52.627163369668331],
            [-2.153027831922438, 52.627662430130677],
            [-2.152987804504086, 52.629737313660776],
            [-2.140291300193156, 52.629907678798709],
            [-2.137243955967955, 52.629630774810693],
            [-2.135091700972651, 52.632619642489004],
            [-2.133928729072058, 52.633534321900434],
            [-2.131549502343725, 52.635382583696249],
            [-2.13164928426654, 52.637607429558216],
            [-2.128677645616419, 52.637610700452541],
            [-2.125978313331143, 52.637243230440887],
            [-2.118251238260957, 52.637692606189141],
            [-2.116996032953537, 52.637453829388683],
            [-2.116119200260361, 52.637799000185638],
            [-2.113806844043876, 52.637894741872536],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000032",
        LAD13CDO: "00CX",
        LAD13NM: "Bradford",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.879937841566176, 53.955888015125055],
            [-1.878878860670796, 53.954260176446823],
            [-1.877696088516647, 53.953307180614033],
            [-1.876269680621395, 53.951283484245835],
            [-1.876293507285327, 53.948890974442286],
            [-1.875012999248561, 53.946040988897344],
            [-1.874147482231088, 53.945073455851357],
            [-1.872010674171445, 53.943852465304815],
            [-1.866394914134408, 53.942497302941796],
            [-1.865100073470066, 53.942003330277984],
            [-1.864345354567668, 53.941258296661211],
            [-1.862103737761823, 53.940554705002825],
            [-1.86173223534393, 53.939110846274204],
            [-1.862490260483915, 53.937430106445632],
            [-1.862976848154522, 53.934428751284116],
            [-1.860828255834864, 53.932890277870406],
            [-1.859204612826757, 53.932451584089158],
            [-1.855865342248948, 53.93222203801389],
            [-1.854618940369264, 53.931502416454165],
            [-1.852226670037147, 53.930958439033326],
            [-1.84998019342408, 53.93139785712615],
            [-1.847150662439947, 53.932586073323563],
            [-1.843330993884711, 53.932948759845353],
            [-1.839889770386214, 53.931511580200876],
            [-1.83619685643139, 53.931405945976387],
            [-1.836331427466962, 53.932046057960314],
            [-1.836995814414375, 53.932349847596484],
            [-1.845495934903091, 53.935249729513586],
            [-1.848653731672984, 53.936012309502978],
            [-1.847735071597326, 53.938576255716981],
            [-1.847686008786821, 53.940758419590949],
            [-1.845020373687523, 53.93986972256991],
            [-1.841719718319167, 53.939363010804797],
            [-1.838434312811562, 53.939662430172078],
            [-1.835562094324279, 53.939468897487465],
            [-1.830121744854182, 53.940369102059357],
            [-1.830196842721344, 53.938002732396541],
            [-1.825936848688564, 53.938234819925107],
            [-1.824015729323371, 53.939026542629861],
            [-1.823389519185507, 53.938336264084583],
            [-1.820153844633098, 53.938039368279718],
            [-1.817050172382984, 53.93856945980199],
            [-1.815064850008741, 53.938335438718376],
            [-1.811308054357818, 53.938764611960032],
            [-1.806222325444319, 53.938651369982345],
            [-1.805085819508455, 53.93900814531419],
            [-1.803535661831222, 53.937915408214863],
            [-1.802859485179096, 53.935942386957684],
            [-1.799033952867275, 53.936747651024227],
            [-1.797492280462987, 53.936470039704936],
            [-1.797840591886662, 53.936258514423976],
            [-1.797172045993234, 53.935601282099974],
            [-1.79743277415226, 53.935231425580632],
            [-1.797040744837251, 53.934722057151653],
            [-1.797384434246257, 53.934204042502074],
            [-1.796269647162579, 53.933549647427526],
            [-1.796361756613517, 53.930894816176178],
            [-1.797197724697962, 53.930298541864907],
            [-1.798492972175906, 53.930149724400749],
            [-1.798006268949877, 53.929699519306901],
            [-1.794138774440335, 53.929223799676983],
            [-1.785083912076959, 53.929713071906988],
            [-1.782440025726528, 53.929426101722271],
            [-1.780975621879099, 53.927841594567234],
            [-1.779925457246421, 53.925403089754525],
            [-1.777532691795863, 53.922906373913854],
            [-1.775975008350788, 53.921473524734601],
            [-1.772276694319545, 53.920872484416023],
            [-1.768959691232787, 53.921551924945405],
            [-1.767823943328322, 53.92399621346491],
            [-1.765421626924798, 53.924707877388798],
            [-1.762425237943227, 53.924094419833523],
            [-1.757231543436933, 53.921394898196731],
            [-1.755502434341065, 53.921026489913437],
            [-1.754478851750232, 53.921089116123326],
            [-1.7497268619003, 53.922587469912223],
            [-1.745622273510134, 53.922988698874981],
            [-1.745038315716143, 53.922362810217898],
            [-1.746067452175236, 53.920889192266564],
            [-1.745993484193382, 53.918523447473014],
            [-1.7421597006523, 53.914565146305407],
            [-1.738697572529351, 53.912884140633757],
            [-1.733693940283003, 53.911875512762812],
            [-1.729013849531492, 53.910306566109128],
            [-1.727212312294888, 53.910182054424176],
            [-1.727354338257252, 53.908942057808289],
            [-1.728960247961512, 53.908238348375605],
            [-1.729376888721624, 53.906420152245744],
            [-1.731275523521971, 53.902771760201453],
            [-1.731163457605706, 53.902209770374228],
            [-1.730543720698101, 53.902019638199825],
            [-1.731426781591898, 53.900543116763821],
            [-1.732323893711843, 53.899954617604962],
            [-1.732108124686802, 53.89847294211441],
            [-1.731041379595241, 53.897041493937429],
            [-1.732069905863278, 53.895880765216226],
            [-1.731083436433721, 53.895704197940752],
            [-1.730138824227697, 53.89493631675672],
            [-1.72885237291249, 53.894796806048255],
            [-1.728921604174533, 53.894444638902698],
            [-1.728345192356497, 53.894162916744222],
            [-1.726837230620028, 53.894394980101886],
            [-1.723296063975069, 53.893737956765008],
            [-1.722622017905915, 53.89396109896397],
            [-1.72092331972419, 53.893811563165748],
            [-1.717289263687748, 53.892283224475918],
            [-1.717267147141908, 53.89105003983633],
            [-1.720617838227805, 53.891243924870999],
            [-1.722683925769588, 53.890812799856306],
            [-1.726749826986655, 53.888193168107797],
            [-1.72718183967375, 53.887737566867031],
            [-1.725454867006806, 53.885668225252751],
            [-1.729304979013336, 53.88573717846954],
            [-1.729644995101675, 53.886328445833371],
            [-1.735117890618417, 53.886974277335206],
            [-1.737739877958905, 53.886633995804168],
            [-1.7420696800172, 53.885410233822967],
            [-1.742486852737638, 53.884861970772953],
            [-1.749160437340309, 53.885705664230009],
            [-1.750221102789961, 53.885147930402702],
            [-1.756432584462889, 53.884648372348067],
            [-1.757952674795189, 53.885125103068269],
            [-1.763386003213469, 53.888788582724594],
            [-1.767292903902997, 53.890201908644769],
            [-1.767572328112629, 53.889749462238356],
            [-1.769448738209523, 53.890231229134031],
            [-1.77062984485272, 53.890157092227085],
            [-1.773461476560043, 53.889014715500039],
            [-1.779523851325237, 53.891176777660107],
            [-1.776676546903998, 53.893837312707667],
            [-1.787461789362708, 53.896885763416655],
            [-1.794959975574512, 53.894613181916881],
            [-1.80035928637186, 53.885899577996682],
            [-1.798403852264586, 53.884733288055529],
            [-1.794648624819245, 53.880505340818885],
            [-1.784554066462891, 53.880521824413201],
            [-1.781401866091744, 53.879418716023736],
            [-1.780676479513208, 53.878799929024481],
            [-1.778721487807439, 53.878581535831287],
            [-1.779252873813686, 53.87790393002043],
            [-1.77894731101502, 53.876716072661559],
            [-1.779412458865826, 53.876481445462879],
            [-1.778636918069127, 53.875582132527299],
            [-1.779419045002583, 53.874360322963447],
            [-1.778838702408931, 53.873946713594265],
            [-1.778248929499626, 53.871865835443359],
            [-1.775810206763331, 53.870590426522213],
            [-1.773790384577837, 53.870343070227314],
            [-1.77336698405917, 53.869894227276625],
            [-1.772734006203897, 53.869955497535749],
            [-1.771851398123155, 53.869217718657687],
            [-1.77212763996712, 53.868756266315053],
            [-1.769198106752153, 53.868032544453669],
            [-1.769354335056762, 53.867276065598269],
            [-1.767323980369386, 53.866769729201202],
            [-1.766627682187784, 53.867012849070186],
            [-1.766621223177554, 53.866807014219191],
            [-1.764316486408812, 53.866970589073667],
            [-1.764002947822085, 53.866479235745317],
            [-1.763313052051593, 53.866393392766113],
            [-1.763239874063426, 53.865887231211076],
            [-1.76108348376841, 53.864840369161776],
            [-1.760975176769697, 53.863832612562902],
            [-1.759613826749137, 53.863644746372735],
            [-1.757932214449635, 53.866523782775445],
            [-1.753966362463759, 53.86599892470889],
            [-1.75348515572835, 53.867409933808446],
            [-1.744666333985492, 53.865711682333782],
            [-1.74439368535206, 53.865536737731844],
            [-1.746292535527163, 53.864095511118705],
            [-1.746337951728552, 53.863373880045799],
            [-1.742941305473398, 53.863331607980307],
            [-1.738532556674441, 53.864417717207978],
            [-1.738195502550535, 53.863603579720632],
            [-1.736195851829447, 53.863864348199115],
            [-1.736207864855147, 53.86413041595177],
            [-1.73361794919908, 53.864189414204382],
            [-1.731243568597828, 53.864782719273066],
            [-1.728605700955878, 53.86496014220981],
            [-1.728173242164655, 53.865758187407174],
            [-1.725457059207257, 53.865575848611655],
            [-1.72483204220903, 53.865117834148549],
            [-1.722811623097221, 53.864815690520921],
            [-1.720304070092554, 53.864827854529317],
            [-1.71877161327625, 53.864100752009328],
            [-1.716598376236688, 53.865051057406106],
            [-1.715183868773453, 53.866221530215277],
            [-1.712756718750433, 53.865138103783607],
            [-1.710291138875412, 53.863472119950309],
            [-1.709351701157998, 53.864092711547357],
            [-1.70838647959307, 53.864262041094719],
            [-1.708169619732927, 53.862647289150431],
            [-1.706861428683915, 53.862071575197874],
            [-1.707073932722749, 53.861688310484489],
            [-1.706540175373891, 53.861045272035859],
            [-1.705237310349587, 53.861020511203108],
            [-1.705035766419855, 53.860274919861652],
            [-1.70216762986329, 53.860572532643488],
            [-1.702060827740281, 53.857839946352868],
            [-1.69975668750821, 53.857740734990031],
            [-1.699604172126606, 53.85717052064161],
            [-1.695087764916491, 53.857523150989032],
            [-1.695076628436985, 53.855288729428651],
            [-1.693023425728457, 53.852910691489207],
            [-1.693987029064257, 53.851267463665167],
            [-1.694310349474829, 53.850078287141649],
            [-1.695121693366252, 53.849501527638992],
            [-1.696458461870561, 53.847309165299954],
            [-1.700872165942305, 53.845428282030916],
            [-1.703744167449941, 53.842282437570994],
            [-1.703303888419906, 53.840065826875225],
            [-1.704982794809097, 53.837088666526739],
            [-1.703138741488885, 53.836339022660603],
            [-1.704697421840802, 53.834450006497441],
            [-1.703718773178965, 53.832523278515801],
            [-1.704883167770859, 53.831801717776507],
            [-1.704973643383048, 53.830817760581994],
            [-1.70629152406731, 53.82921214945948],
            [-1.706636361571732, 53.828565860430047],
            [-1.7055611006494, 53.827641964321892],
            [-1.706327130602066, 53.825638629122381],
            [-1.704969604952612, 53.823169007583992],
            [-1.70800971591284, 53.815675089180822],
            [-1.707890210847611, 53.81363812561041],
            [-1.706900015136795, 53.812544573125017],
            [-1.70829306955698, 53.810078970489869],
            [-1.70800812693481, 53.809779877146305],
            [-1.706128359009961, 53.806481197680711],
            [-1.706611385816209, 53.80406551140608],
            [-1.701288319350337, 53.802504653426226],
            [-1.701278010960524, 53.802245773802703],
            [-1.70368625087742, 53.8017924562959],
            [-1.708523995299531, 53.794287644025665],
            [-1.709909040969448, 53.792881681405404],
            [-1.709936544159382, 53.7928556826771],
            [-1.711159596204732, 53.791594919397767],
            [-1.70956497141278, 53.791047297638464],
            [-1.707972998964236, 53.789904655309506],
            [-1.707351783386537, 53.788870420754122],
            [-1.707329626779513, 53.787691140489351],
            [-1.708237215072239, 53.78638649392969],
            [-1.712073992743115, 53.783077386130486],
            [-1.71012023888859, 53.782936065532041],
            [-1.701262563157054, 53.783466209239904],
            [-1.69713825766139, 53.784213562215882],
            [-1.693378113407639, 53.784328049349682],
            [-1.691064029431828, 53.785101375844711],
            [-1.688764664882853, 53.785309349803612],
            [-1.688013022402318, 53.785768483366319],
            [-1.687335808187331, 53.785808964701793],
            [-1.685893456761276, 53.78526501883119],
            [-1.682374102086033, 53.786438579126639],
            [-1.681748348555316, 53.784890084009383],
            [-1.680821094963735, 53.784080497613139],
            [-1.680420155051768, 53.784116281507835],
            [-1.680260686299113, 53.783531635560607],
            [-1.679881852622116, 53.783654660329283],
            [-1.679943514376699, 53.783329458500056],
            [-1.679175133603387, 53.783188991370508],
            [-1.678881519873514, 53.782684876973931],
            [-1.678752401986013, 53.782899345105548],
            [-1.677641219993758, 53.782340007039167],
            [-1.677904390988031, 53.782060287381718],
            [-1.67689704361321, 53.781224389829553],
            [-1.675986304589664, 53.781052060712604],
            [-1.675832174371568, 53.780374846244371],
            [-1.674772954119579, 53.780371081556567],
            [-1.674162986824338, 53.77996766220781],
            [-1.673686453624882, 53.780362739323628],
            [-1.672927144044084, 53.779845658366817],
            [-1.67153014131233, 53.779957788266401],
            [-1.670611809635945, 53.779598446481607],
            [-1.669058931259575, 53.779656191477983],
            [-1.667851540826071, 53.779200757893378],
            [-1.664654701704184, 53.780084378236246],
            [-1.664176190709983, 53.779764863885525],
            [-1.660933825780884, 53.779879781207754],
            [-1.660640254102733, 53.779588637994927],
            [-1.660349393489682, 53.779902396148259],
            [-1.65819424770916, 53.779746176796969],
            [-1.657101569585143, 53.780125949585596],
            [-1.657025947250212, 53.779722170899241],
            [-1.656206199181587, 53.779942728344061],
            [-1.65141632103986, 53.779641292973217],
            [-1.651091855170255, 53.780156263054899],
            [-1.650520302173151, 53.779723174301544],
            [-1.650137469967061, 53.779955747597356],
            [-1.64993183742155, 53.779682810496936],
            [-1.649112345327191, 53.779868266912928],
            [-1.648542568435254, 53.77958886924803],
            [-1.64820752018984, 53.779913254281688],
            [-1.6476905167234, 53.779493793936417],
            [-1.647158482394994, 53.779782544217021],
            [-1.646941968758352, 53.779542825684892],
            [-1.645957611764597, 53.779662162730816],
            [-1.645684038685479, 53.780252767891817],
            [-1.644902908556669, 53.780204619954517],
            [-1.643967185820676, 53.779595155000308],
            [-1.642738144099199, 53.780113706943986],
            [-1.641710248746455, 53.77999649419899],
            [-1.641409574289668, 53.779663937560137],
            [-1.640525734898341, 53.77991655330068],
            [-1.640324212066805, 53.778281924358033],
            [-1.6406996431745, 53.777854319683044],
            [-1.640350312006839, 53.777535995394828],
            [-1.640879406996382, 53.777060315171717],
            [-1.640450790929345, 53.776964657204715],
            [-1.640388673413374, 53.776242731411941],
            [-1.640918417089695, 53.776222746568997],
            [-1.640594718102963, 53.775747208865823],
            [-1.641192924719602, 53.774993104826464],
            [-1.640773317830481, 53.77491095695536],
            [-1.641281099943882, 53.774082879681764],
            [-1.640761347108053, 53.773824266636289],
            [-1.641061016352501, 53.773379356434916],
            [-1.642368722735858, 53.77304620956545],
            [-1.642111074680374, 53.772476498060449],
            [-1.642632832307429, 53.772676688999248],
            [-1.643170562647427, 53.772245964349025],
            [-1.646454564212202, 53.771444963934286],
            [-1.647804879836783, 53.770358685003458],
            [-1.647583851811638, 53.770119852745239],
            [-1.649486957566318, 53.768958778686176],
            [-1.649560172166697, 53.768182425042504],
            [-1.65161066756309, 53.76734262092198],
            [-1.651907391578847, 53.767418082964113],
            [-1.651739309876096, 53.767013132904353],
            [-1.652349798735403, 53.766753351054902],
            [-1.654345623341279, 53.766459815265797],
            [-1.656482909149237, 53.767232634178818],
            [-1.657906143184208, 53.767019189687609],
            [-1.658125776880365, 53.767248112156352],
            [-1.659615591721232, 53.767242461009857],
            [-1.662838899185785, 53.766733850279302],
            [-1.663486892817848, 53.766137964142331],
            [-1.663741164719556, 53.765071795445245],
            [-1.665286470240256, 53.763991258474121],
            [-1.670067428098067, 53.762117908370861],
            [-1.673229168480399, 53.762048365006414],
            [-1.675510938634958, 53.760621861970314],
            [-1.67744706502254, 53.760141727675034],
            [-1.677895925158616, 53.759363669148641],
            [-1.681677848596784, 53.756478709489905],
            [-1.686657150851302, 53.758107869716021],
            [-1.687050024594244, 53.757694545738637],
            [-1.690719827691661, 53.75840782633977],
            [-1.689692418014088, 53.759514299361868],
            [-1.690005970334111, 53.759984286197003],
            [-1.693810621028191, 53.760752647030863],
            [-1.695850960307275, 53.761777081014124],
            [-1.695356521709014, 53.761978057756018],
            [-1.697853040893818, 53.762439165400849],
            [-1.701492643201577, 53.764073313360193],
            [-1.701994661879046, 53.763872330212749],
            [-1.702588980408709, 53.764348373373586],
            [-1.703059832065649, 53.764049338863153],
            [-1.704748765681769, 53.764835466031464],
            [-1.709135733556568, 53.762441878820098],
            [-1.71066833052678, 53.762806903324616],
            [-1.714393373039307, 53.762432936639819],
            [-1.713742974309599, 53.761711444615983],
            [-1.717912330666942, 53.760431534177499],
            [-1.718082490698746, 53.760617087782137],
            [-1.719559681065908, 53.759508728087248],
            [-1.720465402348081, 53.759479383274254],
            [-1.722214073446148, 53.758818328790419],
            [-1.725589617725439, 53.756144062363482],
            [-1.726499200010058, 53.755040608922343],
            [-1.72637366177225, 53.754287124033191],
            [-1.727283859297367, 53.75284481615013],
            [-1.728709980302704, 53.751579834560602],
            [-1.729436377583444, 53.751581474726905],
            [-1.729533640768638, 53.751312951156898],
            [-1.730390634542664, 53.751286118076564],
            [-1.731188058551391, 53.749899252533744],
            [-1.732330418393319, 53.749108160891538],
            [-1.733150201009784, 53.748963481541644],
            [-1.733914850000093, 53.748183218585154],
            [-1.733762198690926, 53.746902980194733],
            [-1.736302009563975, 53.74715486184013],
            [-1.740811633878663, 53.747894518394311],
            [-1.741134002347403, 53.747501537356186],
            [-1.742839343309607, 53.747097146516765],
            [-1.74321356393416, 53.747398149123114],
            [-1.745580603094606, 53.747146132948771],
            [-1.746493755302411, 53.74734670174341],
            [-1.747337242565198, 53.746775042725055],
            [-1.745945678658897, 53.743683806660876],
            [-1.74741490599606, 53.742670358542313],
            [-1.745928682503692, 53.742226805419442],
            [-1.747128105032668, 53.737906977074431],
            [-1.745992149106655, 53.734459448473991],
            [-1.749972674771017, 53.734238614005442],
            [-1.75478297244035, 53.734691654234965],
            [-1.760985421408161, 53.734532498397208],
            [-1.760476331681672, 53.734221394588637],
            [-1.761457238114766, 53.733930337575842],
            [-1.761809334359695, 53.732783259815001],
            [-1.762761799628507, 53.731342560484464],
            [-1.763584265118745, 53.730909160950823],
            [-1.763770271718959, 53.730174302419201],
            [-1.766848118727629, 53.728091492993251],
            [-1.769150461091018, 53.727251065274885],
            [-1.770083552506812, 53.726237203521961],
            [-1.770591485292175, 53.725641366968226],
            [-1.772432552849035, 53.725145135632495],
            [-1.773726687991223, 53.724325173426436],
            [-1.774342995891694, 53.726121254900811],
            [-1.776326073864676, 53.728279411632883],
            [-1.778463626652121, 53.729635184576104],
            [-1.779471611351497, 53.729628952173861],
            [-1.778724976328271, 53.730378980857196],
            [-1.779102230012313, 53.731568798450184],
            [-1.778561470083441, 53.732074729118459],
            [-1.77960905406613, 53.734074706445469],
            [-1.778927949664824, 53.734743963607364],
            [-1.779271752809704, 53.737120143060338],
            [-1.779844242849012, 53.737806984219716],
            [-1.781166439897002, 53.738053878106513],
            [-1.781478779666122, 53.738631481039761],
            [-1.782906346911139, 53.739326153048502],
            [-1.784093167180463, 53.739376829784995],
            [-1.785017368766008, 53.739791939895433],
            [-1.784728276339736, 53.740886167471047],
            [-1.785274900456004, 53.741022866500273],
            [-1.785483803707748, 53.741980468402019],
            [-1.78656816924798, 53.742218787769794],
            [-1.788768066492038, 53.742534565658978],
            [-1.789016112761813, 53.743262135755593],
            [-1.7915589392119, 53.744152801380068],
            [-1.792534627821453, 53.744925666969586],
            [-1.79409673434038, 53.745474830701518],
            [-1.795063997600974, 53.746749194199168],
            [-1.796035032078841, 53.747255976680641],
            [-1.795663177693577, 53.74795371551096],
            [-1.794026985234494, 53.74857918324129],
            [-1.793482668406351, 53.749184042222275],
            [-1.793620493385153, 53.750457885290274],
            [-1.79432991157764, 53.751449585403591],
            [-1.793880856018594, 53.752104942157366],
            [-1.794595155417728, 53.753035530109848],
            [-1.798394132305603, 53.754622068282423],
            [-1.799262447319451, 53.754451852043971],
            [-1.800996886109364, 53.754892457469857],
            [-1.802475141553147, 53.756608018537342],
            [-1.801719822213728, 53.757254810455876],
            [-1.802463158791762, 53.758841524711066],
            [-1.802204503088952, 53.759336339234686],
            [-1.806254622129543, 53.761580963007091],
            [-1.80726588109125, 53.763015281847835],
            [-1.808797223926512, 53.763848223783071],
            [-1.809495932467219, 53.764317610438965],
            [-1.810827507951367, 53.764388923758013],
            [-1.811996218544116, 53.764244254869297],
            [-1.812530435766321, 53.763847819727303],
            [-1.817583395712001, 53.763846615451875],
            [-1.818604392524074, 53.764178022690615],
            [-1.819395586294775, 53.763977883806938],
            [-1.820180408066153, 53.764202863482815],
            [-1.823811228998822, 53.763664471194438],
            [-1.825878614051136, 53.762979903570042],
            [-1.827847723770845, 53.763685606086696],
            [-1.834199855185136, 53.759266147018685],
            [-1.836876990924659, 53.759654501223814],
            [-1.837850039000229, 53.758268068143352],
            [-1.839090897976877, 53.75743115452962],
            [-1.840215557870277, 53.75562606272927],
            [-1.844414658515572, 53.75494129628661],
            [-1.84635299094866, 53.753253143469109],
            [-1.848303806569483, 53.753532455117742],
            [-1.849555989072785, 53.753250008613726],
            [-1.850750538194409, 53.753392611311483],
            [-1.852987867468546, 53.752411181610555],
            [-1.854181586086041, 53.751035670282505],
            [-1.852849315420156, 53.749524953930127],
            [-1.855443539419245, 53.74831112451951],
            [-1.856220527722467, 53.749915523158904],
            [-1.855553277574837, 53.750805436735469],
            [-1.859327864822983, 53.752147336819483],
            [-1.859928761811975, 53.752041081569359],
            [-1.861562244821303, 53.752445639407199],
            [-1.862354181785067, 53.75235217530394],
            [-1.863698712236512, 53.751140324312466],
            [-1.864001162995643, 53.751403117738072],
            [-1.864874856032896, 53.751343884051678],
            [-1.865245039951171, 53.75271857001804],
            [-1.865705507401723, 53.752418885520427],
            [-1.868226304529624, 53.753289022459356],
            [-1.869433481572593, 53.755252427242652],
            [-1.872569704637315, 53.754810887905201],
            [-1.87264079831621, 53.755375411358514],
            [-1.869407561204894, 53.758236423110603],
            [-1.87003742284044, 53.758594829475165],
            [-1.869389318564719, 53.759719426719485],
            [-1.870083334142, 53.760432030422379],
            [-1.870177995495224, 53.761216786731573],
            [-1.872032979161407, 53.76275842419728],
            [-1.871678214520153, 53.763193964484003],
            [-1.871865364977918, 53.76450461657619],
            [-1.872418472934909, 53.764698447493217],
            [-1.872145084654683, 53.765308442832776],
            [-1.872528010645341, 53.766099794659112],
            [-1.872182970764259, 53.76632233070324],
            [-1.872591168949681, 53.767790506055711],
            [-1.871902975734244, 53.770101489291122],
            [-1.872873837731244, 53.771150521633693],
            [-1.87186735876202, 53.775331568439022],
            [-1.873349164157599, 53.778580501519031],
            [-1.874242269611682, 53.778811532119512],
            [-1.878444104799652, 53.778841022456625],
            [-1.880688539179376, 53.779888574911233],
            [-1.88574031637209, 53.779912358750614],
            [-1.887519667714329, 53.779608445569501],
            [-1.888988336039661, 53.780869926783176],
            [-1.895483396074917, 53.780909903982895],
            [-1.914626096406802, 53.781573052689865],
            [-1.926976621337851, 53.787474599628126],
            [-1.92937454044748, 53.787595570607429],
            [-1.947086787264399, 53.786791243607141],
            [-1.950718227087912, 53.785997347345024],
            [-1.959714040453765, 53.784256121180121],
            [-1.980846826781645, 53.786338433210773],
            [-1.986763056510146, 53.796136101386914],
            [-1.995901766826061, 53.799606092539697],
            [-2.00965571482174, 53.807087310823661],
            [-2.018273501417263, 53.807683087921937],
            [-2.027115233777437, 53.809007116791015],
            [-2.031614835822059, 53.809088694418769],
            [-2.031943344088326, 53.809734839423172],
            [-2.035667873478164, 53.812582957196589],
            [-2.036153529898183, 53.813955268037674],
            [-2.036930132940102, 53.814541941661609],
            [-2.050291130438961, 53.81986060420185],
            [-2.061248290928265, 53.82562123657096],
            [-2.046922251089341, 53.829496123388459],
            [-2.045072742182771, 53.838882003014326],
            [-2.046127656227604, 53.850127242452096],
            [-2.04506936956049, 53.851235852047232],
            [-2.038348085352561, 53.855208145975617],
            [-2.031705651254165, 53.858579650373301],
            [-2.021627771654927, 53.871483077431449],
            [-2.013481586732708, 53.869723567667037],
            [-2.013034727165961, 53.87050556063209],
            [-1.999452429762402, 53.872051307308404],
            [-1.995478770322453, 53.868665510560419],
            [-1.987650294480227, 53.867723050923111],
            [-1.982356266146978, 53.868847679765125],
            [-1.980195761500995, 53.87110149939523],
            [-1.978720734019586, 53.873792210360598],
            [-1.977084860314793, 53.875425902049415],
            [-1.976976783314686, 53.875586763911564],
            [-1.976124695070213, 53.878882438686958],
            [-1.978094831065665, 53.884186528067332],
            [-1.9772486115819, 53.887723979492471],
            [-1.978619390994439, 53.890795365701415],
            [-1.981309479369184, 53.894237242186755],
            [-1.980774979048576, 53.895112571592101],
            [-1.98110436496684, 53.896960516343633],
            [-1.980707170579697, 53.896991911367856],
            [-1.979466731999102, 53.897406944974676],
            [-1.978903377342712, 53.898002740004593],
            [-1.979346964388619, 53.901165174284415],
            [-1.976972153660525, 53.900909943394311],
            [-1.974882424853627, 53.901164782526031],
            [-1.968424466131243, 53.903829052918965],
            [-1.964781180772286, 53.903334617562358],
            [-1.952563921766807, 53.903519183142315],
            [-1.953406842588826, 53.90651244690963],
            [-1.953223001180984, 53.911692038277053],
            [-1.956833333219861, 53.913723722550785],
            [-1.95745004545683, 53.91357384534696],
            [-1.959643432254278, 53.915522249581763],
            [-1.961702313102261, 53.918273178859863],
            [-1.97394300710639, 53.924041187124466],
            [-1.97686386833142, 53.926403763129372],
            [-1.971918381331598, 53.929966365949163],
            [-1.970214817999032, 53.929069879930871],
            [-1.970411729660264, 53.928422809576233],
            [-1.967237178910613, 53.927507937402019],
            [-1.965886094085099, 53.927873366045475],
            [-1.965912976071921, 53.930412415183724],
            [-1.968015150652964, 53.931810584628138],
            [-1.963073055084427, 53.933390123448845],
            [-1.967774050363642, 53.940386629111707],
            [-1.969957464299006, 53.941874661131401],
            [-1.970523158792224, 53.943319129428929],
            [-1.96892651095397, 53.943417595634521],
            [-1.969348167750786, 53.943885065301494],
            [-1.968825591615704, 53.943919984139406],
            [-1.962285978552467, 53.943193711865412],
            [-1.9623815911783, 53.945306758156903],
            [-1.963050000011472, 53.94578241522256],
            [-1.963511608466467, 53.947568417535862],
            [-1.963889070784298, 53.948025107431164],
            [-1.965045336402832, 53.948221379385686],
            [-1.966171674611011, 53.951547158162164],
            [-1.958201611159135, 53.953938996363377],
            [-1.949358396101037, 53.952998194339592],
            [-1.950679725761993, 53.956728644741823],
            [-1.943985803532579, 53.956173879258913],
            [-1.941609023381508, 53.952503965269685],
            [-1.934231806372153, 53.95189889074971],
            [-1.930859382543589, 53.9502540483169],
            [-1.927976209717753, 53.950444698134739],
            [-1.92276625816106, 53.955620199660657],
            [-1.920097867367376, 53.95564362617916],
            [-1.919117906077343, 53.954902384440203],
            [-1.916992440527778, 53.955502217966057],
            [-1.913160385584383, 53.955977664470979],
            [-1.912901718839999, 53.956519437293828],
            [-1.911956006906135, 53.956935780235568],
            [-1.908495321500803, 53.957543468104916],
            [-1.906270015613203, 53.958364133761826],
            [-1.903496574858734, 53.95826128183041],
            [-1.903888557723444, 53.957458991961495],
            [-1.903569071535821, 53.95722146089696],
            [-1.89883706646652, 53.955376895027996],
            [-1.895707775138424, 53.955064149862913],
            [-1.893134689939264, 53.955369274520926],
            [-1.89051000193263, 53.95732893662035],
            [-1.889701788307683, 53.959260558628422],
            [-1.88741338622459, 53.960136541033691],
            [-1.886375006699879, 53.960879748673115],
            [-1.885526276562142, 53.961926013626304],
            [-1.885454745940071, 53.963002673578742],
            [-1.882238413493386, 53.963122703125656],
            [-1.879509478963065, 53.962824307861005],
            [-1.878719732489597, 53.962377724492974],
            [-1.878136734007106, 53.958977981723287],
            [-1.879811810761934, 53.956792054261093],
            [-1.879937841566176, 53.955888015125055],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000033",
        LAD13CDO: "00CY",
        LAD13NM: "Calderdale",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.980846826781645, 53.786338433210773],
            [-1.959714040453765, 53.784256121180121],
            [-1.950718227087912, 53.785997347345024],
            [-1.947086787264399, 53.786791243607141],
            [-1.92937454044748, 53.787595570607429],
            [-1.926976621337851, 53.787474599628126],
            [-1.914626096406802, 53.781573052689865],
            [-1.895483396074917, 53.780909903982895],
            [-1.888988336039661, 53.780869926783176],
            [-1.887519667714329, 53.779608445569501],
            [-1.88574031637209, 53.779912358750614],
            [-1.880688539179376, 53.779888574911233],
            [-1.878444104799652, 53.778841022456625],
            [-1.874242269611682, 53.778811532119512],
            [-1.873349164157599, 53.778580501519031],
            [-1.87186735876202, 53.775331568439022],
            [-1.872873837731244, 53.771150521633693],
            [-1.871902975734244, 53.770101489291122],
            [-1.872591168949681, 53.767790506055711],
            [-1.872182970764259, 53.76632233070324],
            [-1.872528010645341, 53.766099794659112],
            [-1.872145084654683, 53.765308442832776],
            [-1.872418472934909, 53.764698447493217],
            [-1.871865364977918, 53.76450461657619],
            [-1.871678214520153, 53.763193964484003],
            [-1.872032979161407, 53.76275842419728],
            [-1.870177995495224, 53.761216786731573],
            [-1.870083334142, 53.760432030422379],
            [-1.869389318564719, 53.759719426719485],
            [-1.87003742284044, 53.758594829475165],
            [-1.869407561204894, 53.758236423110603],
            [-1.87264079831621, 53.755375411358514],
            [-1.872569704637315, 53.754810887905201],
            [-1.869433481572593, 53.755252427242652],
            [-1.868226304529624, 53.753289022459356],
            [-1.865705507401723, 53.752418885520427],
            [-1.865245039951171, 53.75271857001804],
            [-1.864874856032896, 53.751343884051678],
            [-1.864001162995643, 53.751403117738072],
            [-1.863698712236512, 53.751140324312466],
            [-1.862354181785067, 53.75235217530394],
            [-1.861562244821303, 53.752445639407199],
            [-1.859928761811975, 53.752041081569359],
            [-1.859327864822983, 53.752147336819483],
            [-1.855553277574837, 53.750805436735469],
            [-1.856220527722467, 53.749915523158904],
            [-1.855443539419245, 53.74831112451951],
            [-1.852849315420156, 53.749524953930127],
            [-1.854181586086041, 53.751035670282505],
            [-1.852987867468546, 53.752411181610555],
            [-1.850750538194409, 53.753392611311483],
            [-1.849555989072785, 53.753250008613726],
            [-1.848303806569483, 53.753532455117742],
            [-1.84635299094866, 53.753253143469109],
            [-1.844414658515572, 53.75494129628661],
            [-1.840215557870277, 53.75562606272927],
            [-1.839090897976877, 53.75743115452962],
            [-1.837850039000229, 53.758268068143352],
            [-1.836876990924659, 53.759654501223814],
            [-1.834199855185136, 53.759266147018685],
            [-1.827847723770845, 53.763685606086696],
            [-1.825878614051136, 53.762979903570042],
            [-1.823811228998822, 53.763664471194438],
            [-1.820180408066153, 53.764202863482815],
            [-1.819395586294775, 53.763977883806938],
            [-1.818604392524074, 53.764178022690615],
            [-1.817583395712001, 53.763846615451875],
            [-1.812530435766321, 53.763847819727303],
            [-1.811996218544116, 53.764244254869297],
            [-1.810827507951367, 53.764388923758013],
            [-1.809495932467219, 53.764317610438965],
            [-1.808797223926512, 53.763848223783071],
            [-1.80726588109125, 53.763015281847835],
            [-1.806254622129543, 53.761580963007091],
            [-1.802204503088952, 53.759336339234686],
            [-1.802463158791762, 53.758841524711066],
            [-1.801719822213728, 53.757254810455876],
            [-1.802475141553147, 53.756608018537342],
            [-1.800996886109364, 53.754892457469857],
            [-1.799262447319451, 53.754451852043971],
            [-1.798394132305603, 53.754622068282423],
            [-1.794595155417728, 53.753035530109848],
            [-1.793880856018594, 53.752104942157366],
            [-1.79432991157764, 53.751449585403591],
            [-1.793620493385153, 53.750457885290274],
            [-1.793482668406351, 53.749184042222275],
            [-1.794026985234494, 53.74857918324129],
            [-1.795663177693577, 53.74795371551096],
            [-1.796035032078841, 53.747255976680641],
            [-1.795063997600974, 53.746749194199168],
            [-1.79409673434038, 53.745474830701518],
            [-1.792534627821453, 53.744925666969586],
            [-1.7915589392119, 53.744152801380068],
            [-1.789016112761813, 53.743262135755593],
            [-1.788768066492038, 53.742534565658978],
            [-1.78656816924798, 53.742218787769794],
            [-1.785483803707748, 53.741980468402019],
            [-1.785274900456004, 53.741022866500273],
            [-1.784728276339736, 53.740886167471047],
            [-1.785017368766008, 53.739791939895433],
            [-1.784093167180463, 53.739376829784995],
            [-1.782906346911139, 53.739326153048502],
            [-1.781478779666122, 53.738631481039761],
            [-1.781166439897002, 53.738053878106513],
            [-1.779844242849012, 53.737806984219716],
            [-1.779271752809704, 53.737120143060338],
            [-1.778927949664824, 53.734743963607364],
            [-1.77960905406613, 53.734074706445469],
            [-1.778561470083441, 53.732074729118459],
            [-1.779102230012313, 53.731568798450184],
            [-1.778724976328271, 53.730378980857196],
            [-1.779471611351497, 53.729628952173861],
            [-1.778463626652121, 53.729635184576104],
            [-1.776326073864676, 53.728279411632883],
            [-1.774342995891694, 53.726121254900811],
            [-1.773726687991223, 53.724325173426436],
            [-1.772432552849035, 53.725145135632495],
            [-1.770591485292175, 53.725641366968226],
            [-1.770083552506812, 53.726237203521961],
            [-1.765829331246824, 53.726227177760343],
            [-1.759992691895127, 53.725175721560475],
            [-1.758787256701422, 53.72527486931569],
            [-1.756407164082481, 53.7261769582624],
            [-1.753681352453431, 53.725790297578875],
            [-1.754020222062087, 53.723037041868956],
            [-1.755393064836994, 53.723068610886216],
            [-1.755399127163513, 53.720715540160057],
            [-1.75093335429894, 53.7196709222628],
            [-1.748173909620205, 53.719412595419854],
            [-1.747879857861701, 53.718917632244931],
            [-1.745041109335189, 53.718252804259535],
            [-1.744541144210942, 53.715735071437635],
            [-1.743984564939478, 53.715566705995812],
            [-1.741229540632958, 53.716862271707576],
            [-1.736822103647946, 53.71411670218388],
            [-1.735868318311172, 53.71326792554936],
            [-1.737750490619131, 53.712962864651274],
            [-1.737647260449966, 53.712507840438867],
            [-1.738926682109457, 53.712153804120653],
            [-1.73609893287499, 53.710309546210105],
            [-1.735780981838778, 53.709066688449717],
            [-1.739654982664889, 53.704612558650126],
            [-1.739590270214868, 53.704052458028073],
            [-1.738684482454553, 53.70404239850717],
            [-1.738790555810913, 53.702322302281878],
            [-1.736955172753643, 53.702245486797224],
            [-1.739428340080812, 53.699854650700182],
            [-1.739493411673513, 53.699373028240231],
            [-1.738853400794662, 53.698528549442642],
            [-1.737395385150951, 53.69819729867033],
            [-1.73319730712871, 53.694783325720074],
            [-1.735526771418893, 53.689669738473306],
            [-1.734672936483236, 53.68867016777385],
            [-1.734871901141206, 53.688333552288334],
            [-1.733977104995789, 53.688074509368143],
            [-1.731151049553038, 53.685537142477102],
            [-1.732182274554521, 53.684566033151235],
            [-1.727222911238576, 53.682795870431285],
            [-1.730414316440849, 53.681638223274348],
            [-1.731403592353155, 53.68034704721024],
            [-1.732345807103065, 53.68050105425192],
            [-1.735985229188018, 53.682459554162079],
            [-1.736652118132748, 53.684773673281697],
            [-1.738836235036517, 53.685383353151821],
            [-1.740559685487899, 53.687049003735979],
            [-1.741513892405201, 53.691212576367143],
            [-1.744568554879802, 53.692675201004519],
            [-1.746317863804592, 53.694409131113012],
            [-1.747892676476859, 53.69444570826662],
            [-1.751961744775222, 53.692944186371335],
            [-1.755803563441384, 53.692719278197202],
            [-1.758592157567675, 53.692879520061652],
            [-1.759804304011745, 53.692502654575812],
            [-1.75975048947814, 53.691846412687546],
            [-1.760790196415136, 53.691427846174847],
            [-1.760797588535135, 53.69038973075557],
            [-1.761884577067615, 53.689634193682629],
            [-1.763473322553726, 53.68904591800441],
            [-1.769250338957233, 53.687915678871995],
            [-1.774870101161151, 53.685548996226117],
            [-1.778363878310506, 53.684555123426037],
            [-1.779975183036086, 53.683624222032215],
            [-1.787154541235699, 53.68248849355281],
            [-1.789298433506159, 53.682785292878883],
            [-1.795341840197024, 53.681349565009548],
            [-1.799716782927412, 53.680489594389613],
            [-1.803197465983329, 53.680141223257756],
            [-1.804254518240303, 53.679417609455307],
            [-1.811449717849267, 53.677816662300621],
            [-1.814829598579811, 53.676325143559069],
            [-1.816915299886285, 53.674889599057558],
            [-1.818229685634015, 53.673376195164039],
            [-1.820231319728158, 53.672183786980511],
            [-1.824294425723697, 53.670980001691099],
            [-1.825833847243928, 53.670225443831988],
            [-1.827044504504293, 53.670276630521272],
            [-1.827934754320219, 53.669840187494088],
            [-1.83001138133428, 53.670214359434972],
            [-1.831520611709488, 53.670167053874238],
            [-1.835570989519365, 53.668989832553216],
            [-1.838155245988634, 53.67079906821899],
            [-1.841088048728012, 53.671351266685257],
            [-1.8423438904038, 53.671882326045541],
            [-1.846128893304386, 53.67246787514236],
            [-1.851237985802968, 53.672761939761052],
            [-1.851639302323613, 53.672275278764239],
            [-1.852629651848015, 53.672155159208359],
            [-1.853662035283158, 53.672571675500542],
            [-1.853608110552958, 53.671970302248226],
            [-1.851320564314898, 53.670369395296916],
            [-1.85085618511908, 53.669427758838388],
            [-1.849266625261312, 53.669095008032016],
            [-1.847420414289024, 53.667749843101632],
            [-1.843598909630888, 53.666093796288934],
            [-1.847843634778196, 53.664538010996083],
            [-1.849700048015456, 53.66595778474106],
            [-1.851050970574166, 53.665689824685927],
            [-1.852874507152151, 53.664857973178151],
            [-1.852225908403222, 53.664239689063649],
            [-1.853488231517614, 53.663752281055466],
            [-1.860463715902445, 53.662697296447959],
            [-1.859975480016827, 53.662069353532871],
            [-1.862099885509947, 53.662147314639938],
            [-1.863453310078266, 53.661041520167764],
            [-1.864842531907142, 53.661024217952779],
            [-1.867009720408334, 53.6595121328956],
            [-1.867986769279217, 53.660150473139531],
            [-1.868846067655659, 53.659241812571729],
            [-1.869859265283064, 53.659449643683899],
            [-1.870528339049739, 53.658876023319074],
            [-1.87274755477348, 53.658073057037548],
            [-1.8733366429753, 53.657914589637599],
            [-1.874256851156286, 53.658350584981925],
            [-1.874989986361547, 53.658090693962336],
            [-1.875229881341433, 53.65781320909209],
            [-1.874776182755371, 53.657219517053946],
            [-1.87513709581494, 53.656951146731039],
            [-1.881942059827287, 53.654049460427679],
            [-1.892783088550407, 53.648524712347275],
            [-1.892237125546435, 53.64786718703219],
            [-1.894048610275376, 53.646987957388859],
            [-1.894662163767795, 53.645412867915169],
            [-1.897797729733631, 53.644637201308662],
            [-1.903168838854489, 53.645062301111224],
            [-1.90486786059774, 53.644936925910571],
            [-1.906975424977489, 53.645441017070972],
            [-1.910765933436877, 53.645585008303406],
            [-1.92674247737842, 53.647675680485378],
            [-1.929583796721789, 53.648388344252474],
            [-1.934241740899131, 53.648365815830154],
            [-1.936031555979598, 53.645238893108306],
            [-1.94208749398602, 53.640322725332496],
            [-1.94781750061579, 53.637357444832169],
            [-1.957970599351394, 53.633963877473782],
            [-1.963055950128981, 53.632747641958474],
            [-1.964429871508321, 53.631780025282289],
            [-1.966093677708713, 53.629400426869637],
            [-1.972744792613836, 53.625759188723336],
            [-1.97956063932654, 53.623014631164608],
            [-1.986451551712392, 53.623936886730455],
            [-1.99308889778896, 53.623954516989201],
            [-2.004543069155594, 53.622423911429003],
            [-2.004166309099318, 53.619517134841871],
            [-2.009471976706245, 53.616764625913341],
            [-2.014774400752078, 53.615334049835703],
            [-2.018653487887402, 53.615954586371608],
            [-2.023037170413346, 53.615824382783543],
            [-2.024587509500433, 53.617379933428062],
            [-2.026441481990002, 53.6207896669235],
            [-2.026173388674194, 53.622404909876238],
            [-2.026870018885786, 53.624229362482041],
            [-2.030062734800902, 53.627762762425235],
            [-2.030887885050253, 53.629224932543252],
            [-2.029747372064453, 53.630821529820153],
            [-2.029029925226058, 53.634209362092733],
            [-2.029997671360213, 53.636154168145701],
            [-2.036927583096529, 53.638182650000253],
            [-2.041143816829376, 53.640830087119838],
            [-2.041581050204702, 53.64247207775432],
            [-2.041577900740244, 53.645403127084542],
            [-2.040706432631899, 53.648362338588711],
            [-2.039679814589471, 53.648967590051321],
            [-2.040527348223572, 53.650902459323838],
            [-2.039541078551961, 53.651044804450095],
            [-2.039609986403551, 53.651922027589976],
            [-2.036587078865779, 53.655298055226083],
            [-2.03569482967183, 53.657602893082561],
            [-2.03570990329041, 53.65931243777549],
            [-2.036517966336978, 53.661072973567791],
            [-2.037429687954308, 53.661905892662588],
            [-2.037095625239917, 53.662327542082501],
            [-2.044834943294791, 53.665453655568122],
            [-2.048524175371804, 53.667674081257772],
            [-2.04961971669718, 53.668764791608922],
            [-2.05220262645856, 53.669432406362894],
            [-2.052918117460361, 53.670301244856709],
            [-2.054392835515503, 53.673016798957107],
            [-2.054857942075218, 53.675647415253103],
            [-2.053495353961304, 53.677876194302442],
            [-2.050734529631009, 53.67950065901524],
            [-2.048902007764923, 53.68021148784333],
            [-2.051242243237502, 53.683676327828799],
            [-2.055545366481711, 53.682647955836089],
            [-2.056224289872753, 53.683125806111399],
            [-2.056714718458915, 53.683035692531064],
            [-2.060579549828612, 53.682678760615232],
            [-2.061819758475818, 53.682828226016667],
            [-2.071246958366204, 53.678848416081166],
            [-2.071801415784114, 53.678180265152292],
            [-2.073398862387335, 53.677494397211426],
            [-2.075482969136989, 53.677241429440151],
            [-2.077531525632854, 53.677426170893298],
            [-2.080406517315277, 53.678328475844623],
            [-2.083167288758222, 53.678059636913837],
            [-2.084058133970607, 53.677649153924349],
            [-2.083760995810449, 53.675914651544637],
            [-2.084390834856389, 53.672946322721849],
            [-2.085124922553343, 53.672174618565016],
            [-2.086073458802136, 53.67191867514321],
            [-2.086264044735813, 53.671114997080117],
            [-2.087121600250877, 53.670794396316587],
            [-2.089691525971496, 53.670714296741437],
            [-2.090286885973189, 53.670954730523412],
            [-2.092906669739839, 53.670145585205525],
            [-2.096191381108366, 53.67157839253602],
            [-2.097039878333164, 53.672610444508237],
            [-2.114106983885796, 53.671120407126871],
            [-2.118085068412686, 53.671229792924997],
            [-2.125386857987515, 53.674823007953975],
            [-2.124968492329194, 53.675140727718663],
            [-2.126224780690654, 53.675602297123383],
            [-2.126717528790898, 53.676865508979844],
            [-2.126878614085084, 53.679585149995965],
            [-2.127677836870475, 53.681541915639428],
            [-2.129862745912068, 53.683088217868132],
            [-2.132854033337471, 53.68438191875714],
            [-2.134127097093437, 53.685718830208479],
            [-2.135985429808607, 53.684461987508314],
            [-2.137918497223136, 53.68386835387156],
            [-2.13971516574661, 53.68279398311833],
            [-2.141092203650713, 53.678958025455238],
            [-2.14225766474263, 53.677990418900215],
            [-2.143535524881899, 53.678482340673305],
            [-2.144640998674912, 53.679464311252531],
            [-2.14632797035525, 53.682216224803035],
            [-2.148176627584853, 53.686104912114843],
            [-2.147271640756871, 53.688840219542456],
            [-2.145254404973328, 53.692279746348881],
            [-2.150446294043097, 53.694572488735936],
            [-2.161106202413229, 53.697649618147288],
            [-2.162582854465896, 53.699206159371215],
            [-2.162046781248334, 53.704350782790989],
            [-2.162532055777033, 53.707237103731302],
            [-2.164055956871278, 53.709155780776243],
            [-2.16575677289552, 53.710181674758076],
            [-2.165912324614094, 53.710825006968214],
            [-2.16683194236641, 53.713498585399257],
            [-2.169275703661421, 53.715721503135597],
            [-2.171998323409171, 53.717150321591909],
            [-2.171130765784224, 53.718065654229939],
            [-2.17134144113459, 53.718822149647337],
            [-2.169734506802636, 53.719403272862372],
            [-2.173293701632529, 53.722996999262485],
            [-2.171394687118763, 53.725200016214011],
            [-2.170115662323899, 53.72560091208755],
            [-2.166691589739918, 53.727796124965792],
            [-2.166132870566166, 53.729088487887694],
            [-2.165020211185292, 53.72982615050406],
            [-2.163917759938489, 53.731248679420474],
            [-2.160249071065932, 53.732307050420296],
            [-2.159640425763925, 53.732893885825398],
            [-2.156981074240643, 53.733144576225115],
            [-2.156196221657471, 53.733648036101513],
            [-2.156863281409581, 53.734889313492268],
            [-2.156090215424143, 53.73531006690537],
            [-2.153067350898241, 53.734443928104668],
            [-2.15214579068951, 53.735309754917679],
            [-2.149614779467675, 53.734619068577892],
            [-2.149092214066355, 53.733881801172856],
            [-2.148458194208172, 53.73420166575977],
            [-2.147801404750045, 53.735378117140577],
            [-2.145407022835014, 53.736040774688448],
            [-2.145214278392829, 53.736855326725674],
            [-2.143768125771369, 53.737324452220832],
            [-2.144833477131748, 53.738971576327302],
            [-2.143090459406151, 53.739101304080592],
            [-2.140948198880502, 53.741793071757172],
            [-2.141139521560985, 53.743233630127996],
            [-2.141861942031367, 53.743892498237152],
            [-2.141645234962092, 53.745726315883786],
            [-2.138926018959193, 53.747410267915065],
            [-2.136657703935146, 53.748389880172951],
            [-2.13446973507636, 53.748920856001831],
            [-2.133434860339156, 53.749660831280359],
            [-2.131302000268549, 53.751361929589613],
            [-2.12952092409172, 53.754576193046653],
            [-2.131585306855332, 53.75805320478127],
            [-2.130073493001465, 53.763072868701215],
            [-2.130782285718817, 53.765147428293027],
            [-2.130068348764947, 53.768269742130379],
            [-2.13023095100159, 53.770314336479267],
            [-2.130463412916036, 53.772375032168242],
            [-2.131340633805217, 53.774394574773133],
            [-2.135031015720828, 53.77682891088871],
            [-2.136361758253893, 53.78009004315178],
            [-2.135460997995785, 53.781233439454866],
            [-2.132173165159304, 53.783399625156726],
            [-2.130483306889625, 53.785123577056474],
            [-2.127321827552707, 53.786097684233127],
            [-2.124814213723752, 53.788040830370988],
            [-2.125694782341581, 53.789638869530933],
            [-2.129214088091499, 53.7909060209711],
            [-2.129720237474396, 53.791617320834071],
            [-2.129518811087257, 53.792258381322767],
            [-2.129008520260176, 53.793657460516037],
            [-2.127854806515176, 53.79464827378677],
            [-2.127910659521594, 53.796536585643288],
            [-2.128346346345626, 53.797027760841331],
            [-2.127797467430598, 53.798250711045576],
            [-2.12835698656922, 53.799016785378754],
            [-2.126272317343011, 53.799468400855211],
            [-2.112277221786083, 53.805693923922284],
            [-2.102789612336218, 53.809290438235898],
            [-2.096393032599445, 53.812464011818165],
            [-2.092075874726615, 53.814078050819674],
            [-2.084857137704391, 53.818539606255051],
            [-2.061248290928265, 53.82562123657096],
            [-2.050291130438961, 53.81986060420185],
            [-2.036930132940102, 53.814541941661609],
            [-2.036153529898183, 53.813955268037674],
            [-2.035667873478164, 53.812582957196589],
            [-2.031943344088326, 53.809734839423172],
            [-2.031614835822059, 53.809088694418769],
            [-2.027115233777437, 53.809007116791015],
            [-2.018273501417263, 53.807683087921937],
            [-2.00965571482174, 53.807087310823661],
            [-1.995901766826061, 53.799606092539697],
            [-1.986763056510146, 53.796136101386914],
            [-1.980846826781645, 53.786338433210773],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000034",
        LAD13CDO: "00CZ",
        LAD13NM: "Kirklees",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.704748765681769, 53.764835466031464],
            [-1.703059832065649, 53.764049338863153],
            [-1.702588980408709, 53.764348373373586],
            [-1.701994661879046, 53.763872330212749],
            [-1.701492643201577, 53.764073313360193],
            [-1.697853040893818, 53.762439165400849],
            [-1.695356521709014, 53.761978057756018],
            [-1.695850960307275, 53.761777081014124],
            [-1.693810621028191, 53.760752647030863],
            [-1.690005970334111, 53.759984286197003],
            [-1.689692418014088, 53.759514299361868],
            [-1.690719827691661, 53.75840782633977],
            [-1.687050024594244, 53.757694545738637],
            [-1.686657150851302, 53.758107869716021],
            [-1.681677848596784, 53.756478709489905],
            [-1.6762811787183, 53.753753469900225],
            [-1.676509360736071, 53.753465568502719],
            [-1.675590051361573, 53.752711683842911],
            [-1.676369477980159, 53.752327302087572],
            [-1.676163609067118, 53.752080473520707],
            [-1.674472630995644, 53.752086682775314],
            [-1.666867798586726, 53.74856225571132],
            [-1.663355008077027, 53.74733366604142],
            [-1.658521354740437, 53.746710611959067],
            [-1.658573771665905, 53.746229898854367],
            [-1.658143715536449, 53.746158567002659],
            [-1.658364586146651, 53.745473405623265],
            [-1.63962307095966, 53.747795014024575],
            [-1.637831138618576, 53.747745575503977],
            [-1.639364755735691, 53.746793863850613],
            [-1.638573954135531, 53.746178496219777],
            [-1.638811762422451, 53.745146481283093],
            [-1.635822957409244, 53.742538987119715],
            [-1.636779004895458, 53.741032785088422],
            [-1.636666478996597, 53.740015890645445],
            [-1.636252785754344, 53.739465464026516],
            [-1.634827130462145, 53.739878179686535],
            [-1.634197752366077, 53.739551791510848],
            [-1.636292206262262, 53.738586547572474],
            [-1.635804906566335, 53.737082258558736],
            [-1.634510566233736, 53.736171421757426],
            [-1.633664578407705, 53.73391282641429],
            [-1.632657030126104, 53.732288289926956],
            [-1.632039445387259, 53.732014955790369],
            [-1.631840448372833, 53.731022057651465],
            [-1.632197285648717, 53.730775979472732],
            [-1.631825023886135, 53.728795654662839],
            [-1.629955815534183, 53.727109123772138],
            [-1.630480750005232, 53.72687615251558],
            [-1.629749693789943, 53.726763343829703],
            [-1.629095335322017, 53.726029688867065],
            [-1.626698808605705, 53.724701897664062],
            [-1.626787377416564, 53.723770104958724],
            [-1.625227540179694, 53.72330055419642],
            [-1.624903539184685, 53.721390463068197],
            [-1.623022879591361, 53.721052903602434],
            [-1.62286885840236, 53.720143719367115],
            [-1.62346625801904, 53.719596421934604],
            [-1.622967688363161, 53.719428574914765],
            [-1.623127947645584, 53.718452068717774],
            [-1.62167067636017, 53.71856881953439],
            [-1.619548678765584, 53.71930093019477],
            [-1.619011171296171, 53.718916329111877],
            [-1.618170003545784, 53.718933427423877],
            [-1.616679868957797, 53.719494925552858],
            [-1.613808753894084, 53.719937816145411],
            [-1.610643839339511, 53.721010651354874],
            [-1.608239120732563, 53.721147527960873],
            [-1.605984035553482, 53.720838137818824],
            [-1.605539980687487, 53.721166542407211],
            [-1.601561561929872, 53.71976921576173],
            [-1.596581455959166, 53.719197995729907],
            [-1.589847800678241, 53.717893442095473],
            [-1.587720117206082, 53.716518149138828],
            [-1.585770983710699, 53.71657075292817],
            [-1.58367397176597, 53.716099701361799],
            [-1.583001579754136, 53.714835428384909],
            [-1.583347922365997, 53.714593053543773],
            [-1.582089926165972, 53.713867825447707],
            [-1.580702161947443, 53.712940795557223],
            [-1.580904115674528, 53.712743762250362],
            [-1.580168169084649, 53.712703437197376],
            [-1.579485981939267, 53.712133893777789],
            [-1.578538937211983, 53.710779648193252],
            [-1.578494165482275, 53.709801578999446],
            [-1.575059181247712, 53.708642563550839],
            [-1.573701317000628, 53.708537075474787],
            [-1.573689209574462, 53.708235928914235],
            [-1.572914661735713, 53.708126212996973],
            [-1.572881260628195, 53.707833978631193],
            [-1.571074399679975, 53.707042860735086],
            [-1.571129250564608, 53.706415682856623],
            [-1.576461630645464, 53.704608244473661],
            [-1.584215807211029, 53.703093019064738],
            [-1.586462842495995, 53.703028885997149],
            [-1.58965964728768, 53.702339692559988],
            [-1.594035947148524, 53.700271134755162],
            [-1.595111005737287, 53.699992542004118],
            [-1.595886702979075, 53.700605456052244],
            [-1.598316915061378, 53.699739973568079],
            [-1.601419647876228, 53.696454367584323],
            [-1.602197782760625, 53.696168431943015],
            [-1.602638971329147, 53.693383560893551],
            [-1.602020269031065, 53.692666948237139],
            [-1.602608208000451, 53.69214668559659],
            [-1.595531242111775, 53.691226893519385],
            [-1.592218282974393, 53.69149610021082],
            [-1.591961947815596, 53.689344355187828],
            [-1.593048033676158, 53.688993912895846],
            [-1.595913291977083, 53.686750267766058],
            [-1.598834907145417, 53.683642078247459],
            [-1.602241860600399, 53.683932965536975],
            [-1.605097440924683, 53.679396179237344],
            [-1.607627302661613, 53.679059341251175],
            [-1.610741834683222, 53.677863280849685],
            [-1.613741616873714, 53.677774115717448],
            [-1.613886292778788, 53.677183158481249],
            [-1.61518302687862, 53.677252043964408],
            [-1.61467430107683, 53.674604283535331],
            [-1.613944309757608, 53.673650083696351],
            [-1.61076102245239, 53.672658275453109],
            [-1.608021942349825, 53.672421946522839],
            [-1.607523651316116, 53.671964613616126],
            [-1.608435455640764, 53.670921368716286],
            [-1.606659265955803, 53.669209589791635],
            [-1.602884541920468, 53.669200736301882],
            [-1.59945626181842, 53.667611895095263],
            [-1.595062128691197, 53.664657080649391],
            [-1.591247587574522, 53.662860865939805],
            [-1.591411590119372, 53.662332917496776],
            [-1.590557778108592, 53.66173947656678],
            [-1.590351239915487, 53.661034093484908],
            [-1.590833565299581, 53.660450607631454],
            [-1.591862490861861, 53.660772299971313],
            [-1.59255680434503, 53.659073190720441],
            [-1.595293190721981, 53.65980781422293],
            [-1.596658372358057, 53.658829104837643],
            [-1.598475368339423, 53.658386694455096],
            [-1.599474964164292, 53.658291169293747],
            [-1.599531030072066, 53.658602349469284],
            [-1.600248099728808, 53.6586209226422],
            [-1.600233588056786, 53.658235279137244],
            [-1.603236516273951, 53.657987297733264],
            [-1.606125607914932, 53.657285860139417],
            [-1.606288388223282, 53.657029331703527],
            [-1.610303913663357, 53.656896848893204],
            [-1.611033494028965, 53.656210720981555],
            [-1.616763597703327, 53.655706069482257],
            [-1.617815220770707, 53.655366976702567],
            [-1.621262160424277, 53.655345563483706],
            [-1.621625794665978, 53.654613272788623],
            [-1.623124568076119, 53.654345652742741],
            [-1.623263386687344, 53.654048578871404],
            [-1.624202852649789, 53.654061415963255],
            [-1.624841100746793, 53.653577152705438],
            [-1.623978702540998, 53.653065715666266],
            [-1.623378115006076, 53.651359660544209],
            [-1.62250067178193, 53.651015346456859],
            [-1.621637940523571, 53.650042794662731],
            [-1.62174832206529, 53.649372620697257],
            [-1.620869949260306, 53.647787914531897],
            [-1.619244471292419, 53.646358123773531],
            [-1.620728396747479, 53.643666357029069],
            [-1.622237187756175, 53.642911616896647],
            [-1.622781050500536, 53.642139442361142],
            [-1.622698447572889, 53.641394955277342],
            [-1.623696745086987, 53.639353268000477],
            [-1.623204291428205, 53.63809516245513],
            [-1.622117337610909, 53.637362791486154],
            [-1.619436654556837, 53.639120498157936],
            [-1.617280165960021, 53.637749211445431],
            [-1.615852702078193, 53.634720992448159],
            [-1.613574783639664, 53.633767202299033],
            [-1.612185877414009, 53.632680527008127],
            [-1.615592934966967, 53.631852004303568],
            [-1.615672568523318, 53.630243360043764],
            [-1.610296287186054, 53.628004089995351],
            [-1.611899964832597, 53.626754533462609],
            [-1.614509762733975, 53.625921658069949],
            [-1.613689782348543, 53.624648074196685],
            [-1.611872831932198, 53.623942013794284],
            [-1.611683918687293, 53.623438956713699],
            [-1.609982439150281, 53.622844696318246],
            [-1.608245454664436, 53.621517750425852],
            [-1.607581109803816, 53.619629833523469],
            [-1.606040076797229, 53.619524105971102],
            [-1.60540400359181, 53.618671720099137],
            [-1.604204015939362, 53.618635402910208],
            [-1.603092338540705, 53.618048384185897],
            [-1.602517107852385, 53.61813096652709],
            [-1.602307724804824, 53.617407612879639],
            [-1.601755512810596, 53.617615205240362],
            [-1.60178190840205, 53.618023361421656],
            [-1.600695244707083, 53.618155466784927],
            [-1.598412879411651, 53.617162719130604],
            [-1.59575366136756, 53.616861653817473],
            [-1.59585018613144, 53.61640986833428],
            [-1.59421618763272, 53.616082562449066],
            [-1.594654957178011, 53.615568119998358],
            [-1.591827021304665, 53.614592273632688],
            [-1.591614688546548, 53.614038769366225],
            [-1.58951797589951, 53.612806495973715],
            [-1.588422781611831, 53.611182146003337],
            [-1.586715702677209, 53.610316086266813],
            [-1.586880194785534, 53.60894773444052],
            [-1.58617811693601, 53.60840960720973],
            [-1.586450298958973, 53.607157576829977],
            [-1.58822192949789, 53.606500344195382],
            [-1.589976826141868, 53.607097796423375],
            [-1.591123931172991, 53.606784431008272],
            [-1.587852722866065, 53.603594949556154],
            [-1.587621430523659, 53.603447643725545],
            [-1.586935155291253, 53.603776947398103],
            [-1.585836215363381, 53.602260417699576],
            [-1.585203338797752, 53.600223271170776],
            [-1.586440234624376, 53.599983965773546],
            [-1.586522486846027, 53.596974958515531],
            [-1.587994600388371, 53.593512335165123],
            [-1.589096952317981, 53.5935709519676],
            [-1.589631707963696, 53.593891871636522],
            [-1.591092342714332, 53.592364358008851],
            [-1.597734443244798, 53.590233252195695],
            [-1.598012399841427, 53.589438718092957],
            [-1.599568452723819, 53.589275850259504],
            [-1.599329120823212, 53.588864282701479],
            [-1.60174160487879, 53.587746991711299],
            [-1.600507448127679, 53.586300252058408],
            [-1.602483849187665, 53.584953186217192],
            [-1.602324868897997, 53.583060612316395],
            [-1.600792366058092, 53.581229080562743],
            [-1.599202307836232, 53.580706938176263],
            [-1.598045302805411, 53.579755687322532],
            [-1.598406622483454, 53.579240968580216],
            [-1.599531621292081, 53.579102721036477],
            [-1.600241053463064, 53.577385620384518],
            [-1.596384034658761, 53.577203698119128],
            [-1.596195963804243, 53.576970265540574],
            [-1.598324415319972, 53.576599016048974],
            [-1.606257456927886, 53.572888011877694],
            [-1.607160909246816, 53.571540029690311],
            [-1.609665698490372, 53.572076738342204],
            [-1.610733199793674, 53.570116257832986],
            [-1.612287704791645, 53.568552833515021],
            [-1.612126319786295, 53.567707404630518],
            [-1.613043957951011, 53.566426835984544],
            [-1.614451987693376, 53.565007622027593],
            [-1.616410196620538, 53.564511463251485],
            [-1.615695912569684, 53.5630269903494],
            [-1.619401521217997, 53.563122421399846],
            [-1.62227885695397, 53.5628142457889],
            [-1.627014304435887, 53.563575128685585],
            [-1.627985866209902, 53.563314792839591],
            [-1.633381633705354, 53.563157964074456],
            [-1.633593581241314, 53.562915927169691],
            [-1.637776333013794, 53.562300370095024],
            [-1.646759171872161, 53.562310135844548],
            [-1.648923825556531, 53.561982136647188],
            [-1.649691577920229, 53.561517889819655],
            [-1.650931794636199, 53.561610497395449],
            [-1.651710430615449, 53.560736400056228],
            [-1.656558072074602, 53.560541875748967],
            [-1.656352649428687, 53.560368709696519],
            [-1.658046951997975, 53.559749769144766],
            [-1.657509645185046, 53.559172977929641],
            [-1.658523491748607, 53.558874764095414],
            [-1.65927551338851, 53.559785630312369],
            [-1.660463347872193, 53.559251501232609],
            [-1.660866849242998, 53.559575327186302],
            [-1.66392833800629, 53.557485178421686],
            [-1.66174524124307, 53.555814384396683],
            [-1.661600469595405, 53.554660767654731],
            [-1.662248349894975, 53.554430697510334],
            [-1.661908692300597, 53.553676513319189],
            [-1.667399078931261, 53.553406963030532],
            [-1.668702303975782, 53.553717080298519],
            [-1.669533350163275, 53.553218725299381],
            [-1.670600919686174, 53.554137582392507],
            [-1.699035201186484, 53.553794486651753],
            [-1.704125396627159, 53.560233848275978],
            [-1.723217007756247, 53.559928959592632],
            [-1.725328605309634, 53.555324597196311],
            [-1.726396623738639, 53.55449562001094],
            [-1.724474823389905, 53.552210867166025],
            [-1.727462900209752, 53.55016925971595],
            [-1.728840345076324, 53.548127535413087],
            [-1.729557858401153, 53.548028488733969],
            [-1.729213991975786, 53.547518071314158],
            [-1.730162108900471, 53.546752605822327],
            [-1.733147076103726, 53.545100943238573],
            [-1.740155893580177, 53.542063911334289],
            [-1.741577314538623, 53.541082761622427],
            [-1.742500909804959, 53.541065876164424],
            [-1.755853319117342, 53.536690447713021],
            [-1.765567000917944, 53.535613281242],
            [-1.771783630250766, 53.533842898174647],
            [-1.776811066632549, 53.535491854935771],
            [-1.785443339795179, 53.534781374578834],
            [-1.788595874070387, 53.535539308760256],
            [-1.791614053904149, 53.535411571611732],
            [-1.794019648357078, 53.534351510995705],
            [-1.797034862802943, 53.533875782056207],
            [-1.799847237149564, 53.534489927507387],
            [-1.803750780548475, 53.536356081738596],
            [-1.804283451045887, 53.536997823943494],
            [-1.822229652954476, 53.521075039415791],
            [-1.822748084285629, 53.521897346354002],
            [-1.827151846037582, 53.523492030981899],
            [-1.828929985410303, 53.523167407138224],
            [-1.835265973793269, 53.520578647399695],
            [-1.839535707133565, 53.519806949006203],
            [-1.841821844500266, 53.519892683336991],
            [-1.844244722295209, 53.521767240159832],
            [-1.843991493085108, 53.522545304428981],
            [-1.845685013763195, 53.524711001146379],
            [-1.847037033759675, 53.525410233395178],
            [-1.850625784783398, 53.526203492366726],
            [-1.85237986788625, 53.527140907117321],
            [-1.856648256819952, 53.530004392691716],
            [-1.859537263554004, 53.533634620036068],
            [-1.863430034575103, 53.53494872465042],
            [-1.870648372974562, 53.539262156782648],
            [-1.873493763507431, 53.540413905554693],
            [-1.875855325731308, 53.540393904205168],
            [-1.882688293221163, 53.53913522712012],
            [-1.887047174067328, 53.535853271702244],
            [-1.894110892471911, 53.53359555209083],
            [-1.898356956395099, 53.537779711240475],
            [-1.899323383474788, 53.538096017749673],
            [-1.902115067991861, 53.538628641879683],
            [-1.904276160333565, 53.538464101937493],
            [-1.908891276575149, 53.538717572894399],
            [-1.909622305962102, 53.538374770987254],
            [-1.912886037516444, 53.551627796997067],
            [-1.919371638972296, 53.550862929408773],
            [-1.921580393056374, 53.552983841122199],
            [-1.924450543479922, 53.552538063380204],
            [-1.925667502659633, 53.553173399107123],
            [-1.927523109953733, 53.554621654270889],
            [-1.928703425191178, 53.555568831556506],
            [-1.942727470037535, 53.561625475690313],
            [-1.944596377450037, 53.568670488767964],
            [-1.946401753498837, 53.57149003831033],
            [-1.94811100853965, 53.572832740893304],
            [-1.950321771481337, 53.574149560870964],
            [-1.950935499862425, 53.573621301070403],
            [-1.953708891594651, 53.575670832256854],
            [-1.953690395140891, 53.576015076702966],
            [-1.956582397195564, 53.577555848869743],
            [-1.966865434855699, 53.580001233475656],
            [-1.971164138947428, 53.584140542093728],
            [-1.978245557108488, 53.586772892051691],
            [-1.981647845433632, 53.589322528795535],
            [-1.990715861763888, 53.599460518605753],
            [-1.994765017642142, 53.603372442592608],
            [-1.995875584776423, 53.605100024785102],
            [-1.999608367983338, 53.607927788650656],
            [-2.007515097702121, 53.61251962710999],
            [-2.007675663917058, 53.614361305671956],
            [-2.00853735636196, 53.614796275850843],
            [-2.009471976706245, 53.616764625913341],
            [-2.004166309099318, 53.619517134841871],
            [-2.004543069155594, 53.622423911429003],
            [-1.99308889778896, 53.623954516989201],
            [-1.986451551712392, 53.623936886730455],
            [-1.97956063932654, 53.623014631164608],
            [-1.972744792613836, 53.625759188723336],
            [-1.966093677708713, 53.629400426869637],
            [-1.964429871508321, 53.631780025282289],
            [-1.963055950128981, 53.632747641958474],
            [-1.957970599351394, 53.633963877473782],
            [-1.94781750061579, 53.637357444832169],
            [-1.94208749398602, 53.640322725332496],
            [-1.936031555979598, 53.645238893108306],
            [-1.934241740899131, 53.648365815830154],
            [-1.929583796721789, 53.648388344252474],
            [-1.92674247737842, 53.647675680485378],
            [-1.910765933436877, 53.645585008303406],
            [-1.906975424977489, 53.645441017070972],
            [-1.90486786059774, 53.644936925910571],
            [-1.903168838854489, 53.645062301111224],
            [-1.897797729733631, 53.644637201308662],
            [-1.894662163767795, 53.645412867915169],
            [-1.894048610275376, 53.646987957388859],
            [-1.892237125546435, 53.64786718703219],
            [-1.892783088550407, 53.648524712347275],
            [-1.881942059827287, 53.654049460427679],
            [-1.87513709581494, 53.656951146731039],
            [-1.874776182755371, 53.657219517053946],
            [-1.875229881341433, 53.65781320909209],
            [-1.874989986361547, 53.658090693962336],
            [-1.874256851156286, 53.658350584981925],
            [-1.8733366429753, 53.657914589637599],
            [-1.87274755477348, 53.658073057037548],
            [-1.870528339049739, 53.658876023319074],
            [-1.869859265283064, 53.659449643683899],
            [-1.868846067655659, 53.659241812571729],
            [-1.867986769279217, 53.660150473139531],
            [-1.867009720408334, 53.6595121328956],
            [-1.864842531907142, 53.661024217952779],
            [-1.863453310078266, 53.661041520167764],
            [-1.862099885509947, 53.662147314639938],
            [-1.859975480016827, 53.662069353532871],
            [-1.860463715902445, 53.662697296447959],
            [-1.853488231517614, 53.663752281055466],
            [-1.852225908403222, 53.664239689063649],
            [-1.852874507152151, 53.664857973178151],
            [-1.851050970574166, 53.665689824685927],
            [-1.849700048015456, 53.66595778474106],
            [-1.847843634778196, 53.664538010996083],
            [-1.843598909630888, 53.666093796288934],
            [-1.847420414289024, 53.667749843101632],
            [-1.849266625261312, 53.669095008032016],
            [-1.85085618511908, 53.669427758838388],
            [-1.851320564314898, 53.670369395296916],
            [-1.853608110552958, 53.671970302248226],
            [-1.853662035283158, 53.672571675500542],
            [-1.852629651848015, 53.672155159208359],
            [-1.851639302323613, 53.672275278764239],
            [-1.851237985802968, 53.672761939761052],
            [-1.846128893304386, 53.67246787514236],
            [-1.8423438904038, 53.671882326045541],
            [-1.841088048728012, 53.671351266685257],
            [-1.838155245988634, 53.67079906821899],
            [-1.835570989519365, 53.668989832553216],
            [-1.831520611709488, 53.670167053874238],
            [-1.83001138133428, 53.670214359434972],
            [-1.827934754320219, 53.669840187494088],
            [-1.827044504504293, 53.670276630521272],
            [-1.825833847243928, 53.670225443831988],
            [-1.824294425723697, 53.670980001691099],
            [-1.820231319728158, 53.672183786980511],
            [-1.818229685634015, 53.673376195164039],
            [-1.816915299886285, 53.674889599057558],
            [-1.814829598579811, 53.676325143559069],
            [-1.811449717849267, 53.677816662300621],
            [-1.804254518240303, 53.679417609455307],
            [-1.803197465983329, 53.680141223257756],
            [-1.799716782927412, 53.680489594389613],
            [-1.795341840197024, 53.681349565009548],
            [-1.789298433506159, 53.682785292878883],
            [-1.787154541235699, 53.68248849355281],
            [-1.779975183036086, 53.683624222032215],
            [-1.778363878310506, 53.684555123426037],
            [-1.774870101161151, 53.685548996226117],
            [-1.769250338957233, 53.687915678871995],
            [-1.763473322553726, 53.68904591800441],
            [-1.761884577067615, 53.689634193682629],
            [-1.760797588535135, 53.69038973075557],
            [-1.760790196415136, 53.691427846174847],
            [-1.75975048947814, 53.691846412687546],
            [-1.759804304011745, 53.692502654575812],
            [-1.758592157567675, 53.692879520061652],
            [-1.755803563441384, 53.692719278197202],
            [-1.751961744775222, 53.692944186371335],
            [-1.747892676476859, 53.69444570826662],
            [-1.746317863804592, 53.694409131113012],
            [-1.744568554879802, 53.692675201004519],
            [-1.741513892405201, 53.691212576367143],
            [-1.740559685487899, 53.687049003735979],
            [-1.738836235036517, 53.685383353151821],
            [-1.736652118132748, 53.684773673281697],
            [-1.735985229188018, 53.682459554162079],
            [-1.732345807103065, 53.68050105425192],
            [-1.731403592353155, 53.68034704721024],
            [-1.730414316440849, 53.681638223274348],
            [-1.727222911238576, 53.682795870431285],
            [-1.732182274554521, 53.684566033151235],
            [-1.731151049553038, 53.685537142477102],
            [-1.733977104995789, 53.688074509368143],
            [-1.734871901141206, 53.688333552288334],
            [-1.734672936483236, 53.68867016777385],
            [-1.735526771418893, 53.689669738473306],
            [-1.73319730712871, 53.694783325720074],
            [-1.737395385150951, 53.69819729867033],
            [-1.738853400794662, 53.698528549442642],
            [-1.739493411673513, 53.699373028240231],
            [-1.739428340080812, 53.699854650700182],
            [-1.736955172753643, 53.702245486797224],
            [-1.738790555810913, 53.702322302281878],
            [-1.738684482454553, 53.70404239850717],
            [-1.739590270214868, 53.704052458028073],
            [-1.739654982664889, 53.704612558650126],
            [-1.735780981838778, 53.709066688449717],
            [-1.73609893287499, 53.710309546210105],
            [-1.738926682109457, 53.712153804120653],
            [-1.737647260449966, 53.712507840438867],
            [-1.737750490619131, 53.712962864651274],
            [-1.735868318311172, 53.71326792554936],
            [-1.736822103647946, 53.71411670218388],
            [-1.741229540632958, 53.716862271707576],
            [-1.743984564939478, 53.715566705995812],
            [-1.744541144210942, 53.715735071437635],
            [-1.745041109335189, 53.718252804259535],
            [-1.747879857861701, 53.718917632244931],
            [-1.748173909620205, 53.719412595419854],
            [-1.75093335429894, 53.7196709222628],
            [-1.755399127163513, 53.720715540160057],
            [-1.755393064836994, 53.723068610886216],
            [-1.754020222062087, 53.723037041868956],
            [-1.753681352453431, 53.725790297578875],
            [-1.756407164082481, 53.7261769582624],
            [-1.758787256701422, 53.72527486931569],
            [-1.759992691895127, 53.725175721560475],
            [-1.765829331246824, 53.726227177760343],
            [-1.770083552506812, 53.726237203521961],
            [-1.769150461091018, 53.727251065274885],
            [-1.766848118727629, 53.728091492993251],
            [-1.763770271718959, 53.730174302419201],
            [-1.763584265118745, 53.730909160950823],
            [-1.762761799628507, 53.731342560484464],
            [-1.761809334359695, 53.732783259815001],
            [-1.761457238114766, 53.733930337575842],
            [-1.760476331681672, 53.734221394588637],
            [-1.760985421408161, 53.734532498397208],
            [-1.75478297244035, 53.734691654234965],
            [-1.749972674771017, 53.734238614005442],
            [-1.745992149106655, 53.734459448473991],
            [-1.747128105032668, 53.737906977074431],
            [-1.745928682503692, 53.742226805419442],
            [-1.74741490599606, 53.742670358542313],
            [-1.745945678658897, 53.743683806660876],
            [-1.747337242565198, 53.746775042725055],
            [-1.746493755302411, 53.74734670174341],
            [-1.745580603094606, 53.747146132948771],
            [-1.74321356393416, 53.747398149123114],
            [-1.742839343309607, 53.747097146516765],
            [-1.741134002347403, 53.747501537356186],
            [-1.740811633878663, 53.747894518394311],
            [-1.736302009563975, 53.74715486184013],
            [-1.733762198690926, 53.746902980194733],
            [-1.733914850000093, 53.748183218585154],
            [-1.733150201009784, 53.748963481541644],
            [-1.732330418393319, 53.749108160891538],
            [-1.731188058551391, 53.749899252533744],
            [-1.730390634542664, 53.751286118076564],
            [-1.729533640768638, 53.751312951156898],
            [-1.729436377583444, 53.751581474726905],
            [-1.728709980302704, 53.751579834560602],
            [-1.727283859297367, 53.75284481615013],
            [-1.72637366177225, 53.754287124033191],
            [-1.726499200010058, 53.755040608922343],
            [-1.725589617725439, 53.756144062363482],
            [-1.722214073446148, 53.758818328790419],
            [-1.720465402348081, 53.759479383274254],
            [-1.719559681065908, 53.759508728087248],
            [-1.718082490698746, 53.760617087782137],
            [-1.717912330666942, 53.760431534177499],
            [-1.713742974309599, 53.761711444615983],
            [-1.714393373039307, 53.762432936639819],
            [-1.71066833052678, 53.762806903324616],
            [-1.709135733556568, 53.762441878820098],
            [-1.704748765681769, 53.764835466031464],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000035",
        LAD13CDO: "00DA",
        LAD13NM: "Leeds",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.342852418521751, 53.943255059815201],
            [-1.343928715517707, 53.939906649210506],
            [-1.344706140738073, 53.939484863617956],
            [-1.342045254006915, 53.94052550613322],
            [-1.339533548453959, 53.940793049792518],
            [-1.337522748805635, 53.940115077480733],
            [-1.336267507246684, 53.938482235053485],
            [-1.335028815508937, 53.939455969756459],
            [-1.332452954796292, 53.93973559342249],
            [-1.332060610335156, 53.939025164491973],
            [-1.331097638762463, 53.938467949131201],
            [-1.329205985981875, 53.937974752473814],
            [-1.325561712030387, 53.9379157058451],
            [-1.322809941807501, 53.936548444032944],
            [-1.321101147368465, 53.937000781867305],
            [-1.320576117673991, 53.93603250972415],
            [-1.31637360886739, 53.935470296511085],
            [-1.315391499060778, 53.935076425842183],
            [-1.312328654364425, 53.935244986872583],
            [-1.308614824591287, 53.934341058853811],
            [-1.306956537890471, 53.934407004539118],
            [-1.308986849705943, 53.924080749706135],
            [-1.30659133172801, 53.924275468813711],
            [-1.305271276887114, 53.927107140871186],
            [-1.303813860787224, 53.927016015645179],
            [-1.302217763701052, 53.927387846316471],
            [-1.300414518561741, 53.929399651871542],
            [-1.296755349128484, 53.928642176239066],
            [-1.294174963480686, 53.927046960701986],
            [-1.294254807121517, 53.926556686616358],
            [-1.297083791162652, 53.926406094558267],
            [-1.304238121401986, 53.924946747418446],
            [-1.305784061234686, 53.923644345728491],
            [-1.305760374079442, 53.923144477870039],
            [-1.307304624928467, 53.921477135222005],
            [-1.307373175829644, 53.921107226097647],
            [-1.305303328033107, 53.921139316385791],
            [-1.300929810708205, 53.922347996139656],
            [-1.301415246465693, 53.921180585764247],
            [-1.300326335024197, 53.920645756209801],
            [-1.298446490816517, 53.921970406637811],
            [-1.297198435403714, 53.921664711489569],
            [-1.300076771689073, 53.91908848389761],
            [-1.300107988803001, 53.917130185241859],
            [-1.301189695870342, 53.914533562895272],
            [-1.302009145365058, 53.909670433476272],
            [-1.301467692242748, 53.909459663537561],
            [-1.301658307204642, 53.908709375775686],
            [-1.300697338873221, 53.908553685838498],
            [-1.300583034735065, 53.907468169571722],
            [-1.302726284301793, 53.907375470145411],
            [-1.306105857276669, 53.906436021361046],
            [-1.310841889631163, 53.905933893685322],
            [-1.313884933604617, 53.904985104895843],
            [-1.313717857314877, 53.90468485037028],
            [-1.319478722757017, 53.903947355450903],
            [-1.321048199765771, 53.903462796400234],
            [-1.321777178714497, 53.902903367787921],
            [-1.321781188679111, 53.901151631188426],
            [-1.322266030294349, 53.900439822503458],
            [-1.318140332826528, 53.897526831778357],
            [-1.317726076212059, 53.896331771760998],
            [-1.31781774351742, 53.894066417051327],
            [-1.316772419332577, 53.892008513371508],
            [-1.317925638637439, 53.891831712835376],
            [-1.317542548149091, 53.890128106542058],
            [-1.317948686815829, 53.888732778024412],
            [-1.320015437756635, 53.888510809313615],
            [-1.319991679394009, 53.885380149733578],
            [-1.320799749044004, 53.883770474820842],
            [-1.315602321534421, 53.88166473405569],
            [-1.315755223573399, 53.879473426007394],
            [-1.319688468846616, 53.878940313270618],
            [-1.319424343167425, 53.878625134534779],
            [-1.31707678647734, 53.8784662007713],
            [-1.318402892447191, 53.877456290363519],
            [-1.314792686120657, 53.868654432115306],
            [-1.3117791133634, 53.866857563429775],
            [-1.312598459167607, 53.865299235430712],
            [-1.315408910284134, 53.863324439990009],
            [-1.318888391416899, 53.862269258538667],
            [-1.323257678892186, 53.861654915717757],
            [-1.331549712922527, 53.86151711346448],
            [-1.341966086948539, 53.858939361419473],
            [-1.349921604699956, 53.857617417932204],
            [-1.352965325609722, 53.856733258575808],
            [-1.349430530645633, 53.854969576953458],
            [-1.347010472771726, 53.854174478345122],
            [-1.342745509324296, 53.851500628357407],
            [-1.338424735759027, 53.85172676285125],
            [-1.340456107773451, 53.849086463652604],
            [-1.342133542854102, 53.845155302284539],
            [-1.3382207209144, 53.844226898951909],
            [-1.332339356643445, 53.840941547258645],
            [-1.328153110805254, 53.841277712349871],
            [-1.327958916478076, 53.840687007396014],
            [-1.3265530944575, 53.840405890681886],
            [-1.325418140743407, 53.838117443817033],
            [-1.325996647417987, 53.835875470248297],
            [-1.325204610002701, 53.83532903836408],
            [-1.325338410283016, 53.835040374112459],
            [-1.325586457484342, 53.835207148929499],
            [-1.326023660303488, 53.834949849013711],
            [-1.326203846015784, 53.835179158080322],
            [-1.32737452240576, 53.834855861485075],
            [-1.327696576036473, 53.835146183416278],
            [-1.328436367685386, 53.834970563224203],
            [-1.328745965728978, 53.835470235004429],
            [-1.329735898317939, 53.835807427470201],
            [-1.331247318405906, 53.835460828838308],
            [-1.331181688101825, 53.835097344557127],
            [-1.331795249835706, 53.834832018203748],
            [-1.33349463141974, 53.83479832411021],
            [-1.333535618616743, 53.834513629456367],
            [-1.334371362314918, 53.834417600629529],
            [-1.33401658535148, 53.833792758381541],
            [-1.336092671775997, 53.833572367475959],
            [-1.332768548441877, 53.832773768398468],
            [-1.328299784788598, 53.83011443293605],
            [-1.322309571801183, 53.827436459932962],
            [-1.315365491537347, 53.823582494994312],
            [-1.315022179211263, 53.823674911119511],
            [-1.314025305288541, 53.823136222127822],
            [-1.309669682192183, 53.822877555150612],
            [-1.310394555000653, 53.819900364525679],
            [-1.30708762968211, 53.817601029050579],
            [-1.303619647457239, 53.816518557394446],
            [-1.305102334065274, 53.815033325771786],
            [-1.307289191129288, 53.814495896399301],
            [-1.307598367571441, 53.813614147741745],
            [-1.309387741612366, 53.813690971654765],
            [-1.310632389320469, 53.814199666031456],
            [-1.312431086762242, 53.814358289958818],
            [-1.31361986552123, 53.809642732980713],
            [-1.314868333103267, 53.809540212872434],
            [-1.314598105645778, 53.807765310992117],
            [-1.313937926864506, 53.806810593360375],
            [-1.314164436024983, 53.804840789777394],
            [-1.313620461435142, 53.80086402156978],
            [-1.311724979486233, 53.795429713065147],
            [-1.312823298559623, 53.789346532025171],
            [-1.311639928658141, 53.788661141210767],
            [-1.31126503456556, 53.788010942737095],
            [-1.311917461371177, 53.788118949459594],
            [-1.313632623534078, 53.786073181687442],
            [-1.313806757518719, 53.781538747197416],
            [-1.311600172952868, 53.779681730303324],
            [-1.312594641536959, 53.779281167864582],
            [-1.303460098526573, 53.774910547201536],
            [-1.30678383402187, 53.773027003312826],
            [-1.2986472523707, 53.772617476333011],
            [-1.298122445828852, 53.772788777350939],
            [-1.295578648076276, 53.771295305679168],
            [-1.291957971259778, 53.767177162342634],
            [-1.29039463536731, 53.764249900107274],
            [-1.290544269920803, 53.762752000741777],
            [-1.292224844083142, 53.760507686408602],
            [-1.291964145730834, 53.761397778608114],
            [-1.292461975860689, 53.761470824861419],
            [-1.292695343156074, 53.760130260739693],
            [-1.296745546507768, 53.758936177185831],
            [-1.294692615374209, 53.756260910960471],
            [-1.294938325760645, 53.755445326444551],
            [-1.297528627149418, 53.75582005726681],
            [-1.298269455640861, 53.755596992741168],
            [-1.301496475793837, 53.755717400069827],
            [-1.305559472296869, 53.754718138505631],
            [-1.305958881580517, 53.755238172168511],
            [-1.307732176945024, 53.755127081389212],
            [-1.308504729161769, 53.756477973349476],
            [-1.312235136828986, 53.755871150121173],
            [-1.312612136395841, 53.754145777038467],
            [-1.312084890506723, 53.752162649414522],
            [-1.312829177017939, 53.751161135234966],
            [-1.31279968281074, 53.749529604173112],
            [-1.315353209946025, 53.743663215708317],
            [-1.311563397534612, 53.744541237713619],
            [-1.310032286847658, 53.744520752208309],
            [-1.307221862426248, 53.742722180257203],
            [-1.303827361284008, 53.741877398731404],
            [-1.302345195288186, 53.742478186217127],
            [-1.302039565337176, 53.741707014216097],
            [-1.308200363683907, 53.739087593903228],
            [-1.309757251468272, 53.73955856251775],
            [-1.311603308324568, 53.740984823236751],
            [-1.313785847121297, 53.740936214402282],
            [-1.315913093246609, 53.739716982332801],
            [-1.320249193336552, 53.735673568719172],
            [-1.323082817730792, 53.734806057239496],
            [-1.324390620298772, 53.73492848351114],
            [-1.326487733467494, 53.736125830947827],
            [-1.328058705184861, 53.736575069146667],
            [-1.329293887797666, 53.736597265304049],
            [-1.333362655109763, 53.735649228643915],
            [-1.338273495340605, 53.736477284634518],
            [-1.340079676148301, 53.736339832829209],
            [-1.356477573253072, 53.731507916458128],
            [-1.357800538651887, 53.731430521014424],
            [-1.359660734722282, 53.731909656096178],
            [-1.361020291795484, 53.731918707457289],
            [-1.367946040670879, 53.729596910424419],
            [-1.369733218464159, 53.729386107720956],
            [-1.371995109098903, 53.729561562997752],
            [-1.374116673854267, 53.729797362922895],
            [-1.376070602434725, 53.729481275208322],
            [-1.381708872170472, 53.726605493665851],
            [-1.394484076254924, 53.723759415892275],
            [-1.396155087950864, 53.722744086418729],
            [-1.397351205316319, 53.720171385257466],
            [-1.398947727165061, 53.719370460716469],
            [-1.401534948508875, 53.719656646664355],
            [-1.406782940425756, 53.721687103153045],
            [-1.411975073922767, 53.723086081720531],
            [-1.416390604924995, 53.722256475003192],
            [-1.419675446111005, 53.72208726396989],
            [-1.421191527606767, 53.722704892514592],
            [-1.421969550456291, 53.723436690898509],
            [-1.423089985691783, 53.723513094662685],
            [-1.425188824614978, 53.7228571503871],
            [-1.428254223750911, 53.721324939010543],
            [-1.430997382647001, 53.723105969495641],
            [-1.430783938997991, 53.723312583689513],
            [-1.431326553484351, 53.723307968212509],
            [-1.434671685895418, 53.725651731848949],
            [-1.439667379611454, 53.727453947401955],
            [-1.443460794934337, 53.728163706735408],
            [-1.454616129369154, 53.72805318230354],
            [-1.456621928899504, 53.727774667741279],
            [-1.457397880234938, 53.727191253645529],
            [-1.459398182669344, 53.727809688296581],
            [-1.461887619285299, 53.727991662543097],
            [-1.480992154775557, 53.727789160932083],
            [-1.487949844740284, 53.727724701126249],
            [-1.489275671619419, 53.723000780237065],
            [-1.490379127969134, 53.723111536147556],
            [-1.490387545039186, 53.722414988385211],
            [-1.491863963266147, 53.722134532142],
            [-1.495806524098696, 53.722317458117473],
            [-1.498791239024761, 53.723401362729071],
            [-1.501320465837281, 53.723670768478904],
            [-1.504381678401623, 53.724971466085485],
            [-1.501925806318585, 53.725797188590491],
            [-1.503238049997003, 53.727234447125738],
            [-1.503783127971503, 53.726895154020504],
            [-1.505963679843039, 53.727062350216471],
            [-1.505581957687434, 53.729111874993919],
            [-1.510539376236728, 53.729675098849029],
            [-1.513236429897943, 53.727525325207509],
            [-1.517363157766959, 53.724646921328862],
            [-1.532503398386125, 53.716316461101499],
            [-1.533994462453361, 53.714802366795922],
            [-1.535748406823669, 53.714586264926169],
            [-1.540640086709914, 53.713134643078376],
            [-1.547120364856255, 53.710166237302118],
            [-1.552097326515614, 53.705447269054716],
            [-1.553951755540115, 53.702414381527355],
            [-1.554495880227234, 53.702813682492263],
            [-1.555022388173767, 53.703160784112903],
            [-1.555635950587373, 53.702721740471503],
            [-1.557599912038848, 53.700330059142374],
            [-1.559261928447112, 53.698984358717922],
            [-1.560681226253707, 53.699412013293127],
            [-1.560450716358922, 53.699730248753937],
            [-1.561957325735791, 53.700797267843356],
            [-1.562481659135414, 53.700773116977018],
            [-1.562890237198444, 53.701395690687292],
            [-1.564260223295027, 53.701622686310053],
            [-1.564493521708478, 53.702206866813434],
            [-1.565030062811702, 53.702175558580166],
            [-1.564716427519956, 53.702771235071857],
            [-1.565473268389073, 53.702970821859466],
            [-1.566357068439424, 53.704083162845038],
            [-1.566760453895454, 53.704039680469734],
            [-1.571129250564608, 53.706415682856623],
            [-1.571074399679975, 53.707042860735086],
            [-1.572881260628195, 53.707833978631193],
            [-1.572914661735713, 53.708126212996973],
            [-1.573689209574462, 53.708235928914235],
            [-1.573701317000628, 53.708537075474787],
            [-1.575059181247712, 53.708642563550839],
            [-1.578494165482275, 53.709801578999446],
            [-1.578538937211983, 53.710779648193252],
            [-1.579485981939267, 53.712133893777789],
            [-1.580168169084649, 53.712703437197376],
            [-1.580904115674528, 53.712743762250362],
            [-1.580702161947443, 53.712940795557223],
            [-1.582089926165972, 53.713867825447707],
            [-1.583347922365997, 53.714593053543773],
            [-1.583001579754136, 53.714835428384909],
            [-1.58367397176597, 53.716099701361799],
            [-1.585770983710699, 53.71657075292817],
            [-1.587720117206082, 53.716518149138828],
            [-1.589847800678241, 53.717893442095473],
            [-1.596581455959166, 53.719197995729907],
            [-1.601561561929872, 53.71976921576173],
            [-1.605539980687487, 53.721166542407211],
            [-1.605984035553482, 53.720838137818824],
            [-1.608239120732563, 53.721147527960873],
            [-1.610643839339511, 53.721010651354874],
            [-1.613808753894084, 53.719937816145411],
            [-1.616679868957797, 53.719494925552858],
            [-1.618170003545784, 53.718933427423877],
            [-1.619011171296171, 53.718916329111877],
            [-1.619548678765584, 53.71930093019477],
            [-1.62167067636017, 53.71856881953439],
            [-1.623127947645584, 53.718452068717774],
            [-1.622967688363161, 53.719428574914765],
            [-1.62346625801904, 53.719596421934604],
            [-1.62286885840236, 53.720143719367115],
            [-1.623022879591361, 53.721052903602434],
            [-1.624903539184685, 53.721390463068197],
            [-1.625227540179694, 53.72330055419642],
            [-1.626787377416564, 53.723770104958724],
            [-1.626698808605705, 53.724701897664062],
            [-1.629095335322017, 53.726029688867065],
            [-1.629749693789943, 53.726763343829703],
            [-1.630480750005232, 53.72687615251558],
            [-1.629955815534183, 53.727109123772138],
            [-1.631825023886135, 53.728795654662839],
            [-1.632197285648717, 53.730775979472732],
            [-1.631840448372833, 53.731022057651465],
            [-1.632039445387259, 53.732014955790369],
            [-1.632657030126104, 53.732288289926956],
            [-1.633664578407705, 53.73391282641429],
            [-1.634510566233736, 53.736171421757426],
            [-1.635804906566335, 53.737082258558736],
            [-1.636292206262262, 53.738586547572474],
            [-1.634197752366077, 53.739551791510848],
            [-1.634827130462145, 53.739878179686535],
            [-1.636252785754344, 53.739465464026516],
            [-1.636666478996597, 53.740015890645445],
            [-1.636779004895458, 53.741032785088422],
            [-1.635822957409244, 53.742538987119715],
            [-1.638811762422451, 53.745146481283093],
            [-1.638573954135531, 53.746178496219777],
            [-1.639364755735691, 53.746793863850613],
            [-1.637831138618576, 53.747745575503977],
            [-1.63962307095966, 53.747795014024575],
            [-1.658364586146651, 53.745473405623265],
            [-1.658143715536449, 53.746158567002659],
            [-1.658573771665905, 53.746229898854367],
            [-1.658521354740437, 53.746710611959067],
            [-1.663355008077027, 53.74733366604142],
            [-1.666867798586726, 53.74856225571132],
            [-1.674472630995644, 53.752086682775314],
            [-1.676163609067118, 53.752080473520707],
            [-1.676369477980159, 53.752327302087572],
            [-1.675590051361573, 53.752711683842911],
            [-1.676509360736071, 53.753465568502719],
            [-1.6762811787183, 53.753753469900225],
            [-1.681677848596784, 53.756478709489905],
            [-1.677895925158616, 53.759363669148641],
            [-1.67744706502254, 53.760141727675034],
            [-1.675510938634958, 53.760621861970314],
            [-1.673229168480399, 53.762048365006414],
            [-1.670067428098067, 53.762117908370861],
            [-1.665286470240256, 53.763991258474121],
            [-1.663741164719556, 53.765071795445245],
            [-1.663486892817848, 53.766137964142331],
            [-1.662838899185785, 53.766733850279302],
            [-1.659615591721232, 53.767242461009857],
            [-1.658125776880365, 53.767248112156352],
            [-1.657906143184208, 53.767019189687609],
            [-1.656482909149237, 53.767232634178818],
            [-1.654345623341279, 53.766459815265797],
            [-1.652349798735403, 53.766753351054902],
            [-1.651739309876096, 53.767013132904353],
            [-1.651907391578847, 53.767418082964113],
            [-1.65161066756309, 53.76734262092198],
            [-1.649560172166697, 53.768182425042504],
            [-1.649486957566318, 53.768958778686176],
            [-1.647583851811638, 53.770119852745239],
            [-1.647804879836783, 53.770358685003458],
            [-1.646454564212202, 53.771444963934286],
            [-1.643170562647427, 53.772245964349025],
            [-1.642632832307429, 53.772676688999248],
            [-1.642111074680374, 53.772476498060449],
            [-1.642368722735858, 53.77304620956545],
            [-1.641061016352501, 53.773379356434916],
            [-1.640761347108053, 53.773824266636289],
            [-1.641281099943882, 53.774082879681764],
            [-1.640773317830481, 53.77491095695536],
            [-1.641192924719602, 53.774993104826464],
            [-1.640594718102963, 53.775747208865823],
            [-1.640918417089695, 53.776222746568997],
            [-1.640388673413374, 53.776242731411941],
            [-1.640450790929345, 53.776964657204715],
            [-1.640879406996382, 53.777060315171717],
            [-1.640350312006839, 53.777535995394828],
            [-1.6406996431745, 53.777854319683044],
            [-1.640324212066805, 53.778281924358033],
            [-1.640525734898341, 53.77991655330068],
            [-1.641409574289668, 53.779663937560137],
            [-1.641710248746455, 53.77999649419899],
            [-1.642738144099199, 53.780113706943986],
            [-1.643967185820676, 53.779595155000308],
            [-1.644902908556669, 53.780204619954517],
            [-1.645684038685479, 53.780252767891817],
            [-1.645957611764597, 53.779662162730816],
            [-1.646941968758352, 53.779542825684892],
            [-1.647158482394994, 53.779782544217021],
            [-1.6476905167234, 53.779493793936417],
            [-1.64820752018984, 53.779913254281688],
            [-1.648542568435254, 53.77958886924803],
            [-1.649112345327191, 53.779868266912928],
            [-1.64993183742155, 53.779682810496936],
            [-1.650137469967061, 53.779955747597356],
            [-1.650520302173151, 53.779723174301544],
            [-1.651091855170255, 53.780156263054899],
            [-1.65141632103986, 53.779641292973217],
            [-1.656206199181587, 53.779942728344061],
            [-1.657025947250212, 53.779722170899241],
            [-1.657101569585143, 53.780125949585596],
            [-1.65819424770916, 53.779746176796969],
            [-1.660349393489682, 53.779902396148259],
            [-1.660640254102733, 53.779588637994927],
            [-1.660933825780884, 53.779879781207754],
            [-1.664176190709983, 53.779764863885525],
            [-1.664654701704184, 53.780084378236246],
            [-1.667851540826071, 53.779200757893378],
            [-1.669058931259575, 53.779656191477983],
            [-1.670611809635945, 53.779598446481607],
            [-1.67153014131233, 53.779957788266401],
            [-1.672927144044084, 53.779845658366817],
            [-1.673686453624882, 53.780362739323628],
            [-1.674162986824338, 53.77996766220781],
            [-1.674772954119579, 53.780371081556567],
            [-1.675832174371568, 53.780374846244371],
            [-1.675986304589664, 53.781052060712604],
            [-1.67689704361321, 53.781224389829553],
            [-1.677904390988031, 53.782060287381718],
            [-1.677641219993758, 53.782340007039167],
            [-1.678752401986013, 53.782899345105548],
            [-1.678881519873514, 53.782684876973931],
            [-1.679175133603387, 53.783188991370508],
            [-1.679943514376699, 53.783329458500056],
            [-1.679881852622116, 53.783654660329283],
            [-1.680260686299113, 53.783531635560607],
            [-1.680420155051768, 53.784116281507835],
            [-1.680821094963735, 53.784080497613139],
            [-1.681748348555316, 53.784890084009383],
            [-1.682374102086033, 53.786438579126639],
            [-1.685893456761276, 53.78526501883119],
            [-1.687335808187331, 53.785808964701793],
            [-1.688013022402318, 53.785768483366319],
            [-1.688764664882853, 53.785309349803612],
            [-1.691064029431828, 53.785101375844711],
            [-1.693378113407639, 53.784328049349682],
            [-1.69713825766139, 53.784213562215882],
            [-1.701262563157054, 53.783466209239904],
            [-1.71012023888859, 53.782936065532041],
            [-1.712073992743115, 53.783077386130486],
            [-1.708237215072239, 53.78638649392969],
            [-1.707329626779513, 53.787691140489351],
            [-1.707351783386537, 53.788870420754122],
            [-1.707972998964236, 53.789904655309506],
            [-1.70956497141278, 53.791047297638464],
            [-1.711159596204732, 53.791594919397767],
            [-1.709936544159382, 53.7928556826771],
            [-1.709909040969448, 53.792881681405404],
            [-1.708523995299531, 53.794287644025665],
            [-1.70368625087742, 53.8017924562959],
            [-1.701278010960524, 53.802245773802703],
            [-1.701288319350337, 53.802504653426226],
            [-1.706611385816209, 53.80406551140608],
            [-1.706128359009961, 53.806481197680711],
            [-1.70800812693481, 53.809779877146305],
            [-1.70829306955698, 53.810078970489869],
            [-1.706900015136795, 53.812544573125017],
            [-1.707890210847611, 53.81363812561041],
            [-1.70800971591284, 53.815675089180822],
            [-1.704969604952612, 53.823169007583992],
            [-1.706327130602066, 53.825638629122381],
            [-1.7055611006494, 53.827641964321892],
            [-1.706636361571732, 53.828565860430047],
            [-1.70629152406731, 53.82921214945948],
            [-1.704973643383048, 53.830817760581994],
            [-1.704883167770859, 53.831801717776507],
            [-1.703718773178965, 53.832523278515801],
            [-1.704697421840802, 53.834450006497441],
            [-1.703138741488885, 53.836339022660603],
            [-1.704982794809097, 53.837088666526739],
            [-1.703303888419906, 53.840065826875225],
            [-1.703744167449941, 53.842282437570994],
            [-1.700872165942305, 53.845428282030916],
            [-1.696458461870561, 53.847309165299954],
            [-1.695121693366252, 53.849501527638992],
            [-1.694310349474829, 53.850078287141649],
            [-1.693987029064257, 53.851267463665167],
            [-1.693023425728457, 53.852910691489207],
            [-1.695076628436985, 53.855288729428651],
            [-1.695087764916491, 53.857523150989032],
            [-1.699604172126606, 53.85717052064161],
            [-1.69975668750821, 53.857740734990031],
            [-1.702060827740281, 53.857839946352868],
            [-1.70216762986329, 53.860572532643488],
            [-1.705035766419855, 53.860274919861652],
            [-1.705237310349587, 53.861020511203108],
            [-1.706540175373891, 53.861045272035859],
            [-1.707073932722749, 53.861688310484489],
            [-1.706861428683915, 53.862071575197874],
            [-1.708169619732927, 53.862647289150431],
            [-1.70838647959307, 53.864262041094719],
            [-1.709351701157998, 53.864092711547357],
            [-1.710291138875412, 53.863472119950309],
            [-1.712756718750433, 53.865138103783607],
            [-1.715183868773453, 53.866221530215277],
            [-1.716598376236688, 53.865051057406106],
            [-1.71877161327625, 53.864100752009328],
            [-1.720304070092554, 53.864827854529317],
            [-1.722811623097221, 53.864815690520921],
            [-1.72483204220903, 53.865117834148549],
            [-1.725457059207257, 53.865575848611655],
            [-1.728173242164655, 53.865758187407174],
            [-1.728605700955878, 53.86496014220981],
            [-1.731243568597828, 53.864782719273066],
            [-1.73361794919908, 53.864189414204382],
            [-1.736207864855147, 53.86413041595177],
            [-1.736195851829447, 53.863864348199115],
            [-1.738195502550535, 53.863603579720632],
            [-1.738532556674441, 53.864417717207978],
            [-1.742941305473398, 53.863331607980307],
            [-1.746337951728552, 53.863373880045799],
            [-1.746292535527163, 53.864095511118705],
            [-1.74439368535206, 53.865536737731844],
            [-1.744666333985492, 53.865711682333782],
            [-1.75348515572835, 53.867409933808446],
            [-1.753966362463759, 53.86599892470889],
            [-1.757932214449635, 53.866523782775445],
            [-1.759613826749137, 53.863644746372735],
            [-1.760975176769697, 53.863832612562902],
            [-1.76108348376841, 53.864840369161776],
            [-1.763239874063426, 53.865887231211076],
            [-1.763313052051593, 53.866393392766113],
            [-1.764002947822085, 53.866479235745317],
            [-1.764316486408812, 53.866970589073667],
            [-1.766621223177554, 53.866807014219191],
            [-1.766627682187784, 53.867012849070186],
            [-1.767323980369386, 53.866769729201202],
            [-1.769354335056762, 53.867276065598269],
            [-1.769198106752153, 53.868032544453669],
            [-1.77212763996712, 53.868756266315053],
            [-1.771851398123155, 53.869217718657687],
            [-1.772734006203897, 53.869955497535749],
            [-1.77336698405917, 53.869894227276625],
            [-1.773790384577837, 53.870343070227314],
            [-1.775810206763331, 53.870590426522213],
            [-1.778248929499626, 53.871865835443359],
            [-1.778838702408931, 53.873946713594265],
            [-1.779419045002583, 53.874360322963447],
            [-1.778636918069127, 53.875582132527299],
            [-1.779412458865826, 53.876481445462879],
            [-1.77894731101502, 53.876716072661559],
            [-1.779252873813686, 53.87790393002043],
            [-1.778721487807439, 53.878581535831287],
            [-1.780676479513208, 53.878799929024481],
            [-1.781401866091744, 53.879418716023736],
            [-1.784554066462891, 53.880521824413201],
            [-1.794648624819245, 53.880505340818885],
            [-1.798403852264586, 53.884733288055529],
            [-1.80035928637186, 53.885899577996682],
            [-1.794959975574512, 53.894613181916881],
            [-1.787461789362708, 53.896885763416655],
            [-1.776676546903998, 53.893837312707667],
            [-1.779523851325237, 53.891176777660107],
            [-1.773461476560043, 53.889014715500039],
            [-1.77062984485272, 53.890157092227085],
            [-1.769448738209523, 53.890231229134031],
            [-1.767572328112629, 53.889749462238356],
            [-1.767292903902997, 53.890201908644769],
            [-1.763386003213469, 53.888788582724594],
            [-1.757952674795189, 53.885125103068269],
            [-1.756432584462889, 53.884648372348067],
            [-1.750221102789961, 53.885147930402702],
            [-1.749160437340309, 53.885705664230009],
            [-1.742486852737638, 53.884861970772953],
            [-1.7420696800172, 53.885410233822967],
            [-1.737739877958905, 53.886633995804168],
            [-1.735117890618417, 53.886974277335206],
            [-1.729644995101675, 53.886328445833371],
            [-1.729304979013336, 53.88573717846954],
            [-1.725454867006806, 53.885668225252751],
            [-1.72718183967375, 53.887737566867031],
            [-1.726749826986655, 53.888193168107797],
            [-1.722683925769588, 53.890812799856306],
            [-1.720617838227805, 53.891243924870999],
            [-1.717267147141908, 53.89105003983633],
            [-1.717289263687748, 53.892283224475918],
            [-1.72092331972419, 53.893811563165748],
            [-1.722622017905915, 53.89396109896397],
            [-1.723296063975069, 53.893737956765008],
            [-1.726837230620028, 53.894394980101886],
            [-1.728345192356497, 53.894162916744222],
            [-1.728921604174533, 53.894444638902698],
            [-1.72885237291249, 53.894796806048255],
            [-1.730138824227697, 53.89493631675672],
            [-1.731083436433721, 53.895704197940752],
            [-1.732069905863278, 53.895880765216226],
            [-1.731041379595241, 53.897041493937429],
            [-1.732108124686802, 53.89847294211441],
            [-1.732323893711843, 53.899954617604962],
            [-1.731426781591898, 53.900543116763821],
            [-1.730543720698101, 53.902019638199825],
            [-1.731163457605706, 53.902209770374228],
            [-1.731275523521971, 53.902771760201453],
            [-1.729376888721624, 53.906420152245744],
            [-1.728960247961512, 53.908238348375605],
            [-1.727354338257252, 53.908942057808289],
            [-1.727212312294888, 53.910182054424176],
            [-1.725648101564059, 53.910546096084964],
            [-1.723571611377766, 53.911718742587084],
            [-1.722309655273041, 53.91194322555986],
            [-1.721561430149405, 53.911600856454804],
            [-1.720544778927095, 53.90996630947518],
            [-1.717954474939141, 53.908527600616381],
            [-1.718698037081724, 53.910459028055477],
            [-1.718202234740411, 53.910617849861389],
            [-1.717579277218026, 53.911799184542048],
            [-1.717532199393046, 53.913362953813404],
            [-1.716084648801879, 53.917149703650495],
            [-1.71642859796892, 53.917619679139342],
            [-1.713192646358945, 53.917929271478648],
            [-1.712484276293404, 53.917542901018301],
            [-1.708953291031365, 53.918019745151128],
            [-1.708030204786669, 53.916989301059942],
            [-1.706108348136374, 53.917055621240181],
            [-1.707078996175025, 53.919116198961241],
            [-1.704771253947406, 53.918183910029278],
            [-1.703711227893363, 53.918662149674837],
            [-1.702239008384087, 53.918434715305452],
            [-1.700774330543361, 53.917366921238454],
            [-1.698608975114005, 53.916779999780118],
            [-1.697677007653086, 53.916808217881993],
            [-1.696912565013611, 53.91767092038701],
            [-1.696439648915896, 53.917589735444068],
            [-1.696342545997534, 53.917123022653143],
            [-1.690319068860442, 53.917390770663964],
            [-1.690161242316663, 53.91670369482069],
            [-1.687015203256625, 53.916373779952785],
            [-1.687628483920059, 53.914369294225367],
            [-1.689166832628231, 53.912630543934199],
            [-1.688194689870762, 53.911727445958668],
            [-1.68456672525262, 53.911970536986601],
            [-1.684622914486066, 53.91057307755144],
            [-1.680016972135271, 53.910317332700629],
            [-1.670504708992721, 53.911245238835413],
            [-1.669393097134271, 53.911481262327669],
            [-1.666635838422294, 53.912867658086306],
            [-1.665190873442861, 53.913090134624873],
            [-1.66267859914582, 53.91313793534465],
            [-1.655078398691481, 53.912469239146262],
            [-1.653208945773195, 53.91173674346242],
            [-1.652257554344627, 53.910996991885739],
            [-1.652407357808971, 53.910371871914549],
            [-1.653947496195668, 53.909313055651943],
            [-1.654412380583545, 53.908316743438547],
            [-1.653613113178555, 53.90685841225352],
            [-1.652024430713905, 53.905707872581843],
            [-1.648601131098407, 53.9059037325285],
            [-1.645787127334894, 53.907122311471809],
            [-1.642255345295796, 53.907349128299536],
            [-1.641076127686657, 53.907119118296336],
            [-1.637922311478006, 53.905526897200019],
            [-1.631155429405927, 53.905146802836391],
            [-1.627921084705898, 53.905312094459632],
            [-1.626071097254604, 53.904874934913707],
            [-1.623064861961767, 53.903539833761322],
            [-1.620532159614618, 53.903379967286426],
            [-1.612030274816704, 53.906169611100637],
            [-1.608624092968524, 53.908419010313473],
            [-1.607181939096169, 53.908974247688811],
            [-1.599988068000086, 53.909761219543917],
            [-1.599059609720253, 53.909431866097904],
            [-1.598889886052046, 53.908874951474132],
            [-1.601313293644518, 53.907909631921591],
            [-1.601770998832534, 53.906837999665257],
            [-1.601080667629569, 53.905323952019991],
            [-1.599242800451253, 53.903966960726642],
            [-1.598298134190021, 53.903437117457209],
            [-1.594469874990457, 53.902692645439409],
            [-1.592933567790521, 53.901973813731409],
            [-1.586853007636996, 53.901236725782631],
            [-1.585652809691461, 53.901483354327482],
            [-1.585178668815964, 53.902488359685549],
            [-1.58624108960417, 53.904483734420239],
            [-1.585473538427165, 53.907143294155169],
            [-1.584312244944943, 53.908973704645469],
            [-1.582894418328266, 53.909344483852415],
            [-1.573062559743481, 53.910221321354477],
            [-1.568927364818574, 53.910885140891615],
            [-1.567733410023316, 53.910784681458303],
            [-1.566205948978209, 53.909962179873489],
            [-1.562877484343646, 53.90391476218705],
            [-1.561557850740177, 53.903918042914711],
            [-1.55930569271312, 53.904888582853182],
            [-1.557041195412522, 53.904856887027137],
            [-1.555213053535242, 53.904440285975411],
            [-1.552571018941841, 53.90299241538559],
            [-1.551221939124717, 53.903047602513283],
            [-1.550230455672114, 53.904101768400011],
            [-1.550478658683424, 53.906928485867354],
            [-1.549099318077713, 53.908651685566845],
            [-1.549410817726729, 53.910684113061521],
            [-1.548787420881858, 53.910898381317587],
            [-1.543508773937697, 53.910002125019609],
            [-1.536288233238368, 53.910184791484347],
            [-1.532557198795537, 53.908955172919626],
            [-1.528590320816271, 53.908683519608026],
            [-1.526466477271661, 53.909793260168009],
            [-1.521442479672327, 53.911310282116709],
            [-1.5163679518392, 53.911766320913571],
            [-1.510278742383158, 53.911370456576975],
            [-1.507719801947742, 53.911754567377336],
            [-1.506325130418452, 53.912161388904067],
            [-1.504354904340906, 53.913475395220736],
            [-1.500957145497616, 53.914864339471869],
            [-1.498774959776043, 53.915291168492921],
            [-1.493430027142284, 53.915133934586244],
            [-1.49186419125525, 53.914444243314037],
            [-1.489238272101101, 53.911927285424689],
            [-1.486020552129121, 53.910896133730901],
            [-1.481610808303278, 53.910746863458655],
            [-1.4754262718069, 53.911557691912058],
            [-1.473453110644372, 53.911213811872884],
            [-1.470971774311353, 53.911333236033627],
            [-1.468289152459658, 53.910998728029831],
            [-1.466637328889215, 53.910411686498051],
            [-1.465508941885677, 53.909606748233003],
            [-1.464954958560254, 53.909002092616092],
            [-1.465242709567101, 53.906971207674609],
            [-1.463093733693517, 53.90599811876217],
            [-1.46055029859885, 53.906824398284634],
            [-1.457050674343741, 53.907311057278328],
            [-1.454896864589094, 53.907424438404817],
            [-1.450921958106431, 53.90711692615136],
            [-1.447221430147253, 53.909090779448533],
            [-1.445510560730627, 53.909657223011109],
            [-1.442870441790784, 53.912902228687457],
            [-1.440439771293014, 53.913886793129315],
            [-1.438056872040079, 53.91402576945643],
            [-1.436429593730352, 53.913334167042322],
            [-1.434401990483881, 53.910162689552322],
            [-1.432428965566283, 53.911186096495484],
            [-1.431394624757182, 53.913255616938578],
            [-1.430129675307966, 53.913804177989888],
            [-1.431277176096325, 53.916074569682792],
            [-1.430902079130832, 53.916228284414956],
            [-1.431502182020023, 53.91710744676012],
            [-1.432111216743471, 53.920809749622435],
            [-1.431464758230372, 53.923227134751919],
            [-1.433147640583842, 53.924895150770027],
            [-1.433546875930427, 53.926338692152832],
            [-1.432338091211058, 53.927434903644979],
            [-1.429778182834349, 53.928882417042253],
            [-1.4281077291484, 53.929423638780918],
            [-1.418829854313354, 53.928104640324406],
            [-1.413613905634238, 53.928318377793126],
            [-1.406703643776961, 53.927833263877346],
            [-1.408800103889346, 53.929599833484296],
            [-1.41062312526943, 53.930139075155104],
            [-1.410577734861437, 53.931307279540199],
            [-1.408464259452699, 53.934935195040254],
            [-1.409191167972511, 53.935449283460557],
            [-1.407842029137755, 53.935635882640447],
            [-1.4076640510643, 53.936157202128385],
            [-1.402868789824638, 53.936490289426224],
            [-1.400651968241011, 53.937484100268755],
            [-1.400441107116966, 53.940080549572784],
            [-1.399730112899116, 53.940157891315906],
            [-1.399896544632807, 53.941405342935084],
            [-1.397176453754733, 53.942518810796315],
            [-1.396650979518771, 53.941877134844155],
            [-1.391435030990978, 53.942488076571408],
            [-1.391436299586589, 53.942819736211021],
            [-1.390106727216549, 53.943000843048182],
            [-1.389675470027316, 53.942800021258172],
            [-1.386466157537911, 53.943173754462826],
            [-1.38167670883898, 53.940470814160427],
            [-1.374963656963776, 53.940950194393942],
            [-1.374187389944458, 53.941516886289008],
            [-1.373586978722203, 53.941325910955818],
            [-1.373515151321773, 53.941747967970493],
            [-1.371794489141659, 53.942810343653939],
            [-1.370374415235377, 53.94302671077822],
            [-1.366272253442067, 53.941885255739699],
            [-1.361947534409509, 53.941466903908555],
            [-1.358554610454201, 53.941684324751634],
            [-1.354865220381651, 53.943638320663652],
            [-1.352383407770974, 53.94423165597258],
            [-1.348202504016662, 53.944940668822284],
            [-1.34278654578861, 53.9449264519046],
            [-1.340399491629117, 53.945871485751994],
            [-1.339834788893655, 53.94554751646028],
            [-1.34155910738018, 53.943924770802916],
            [-1.342852418521751, 53.943255059815201],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000036",
        LAD13CDO: "00DB",
        LAD13NM: "Wakefield",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.293664356327787, 53.739344392796269],
            [-1.292170264310917, 53.738846620598764],
            [-1.289211730432313, 53.736638680285928],
            [-1.286652992163486, 53.735368722499487],
            [-1.283760285908421, 53.73199435991198],
            [-1.279707761918451, 53.729285283933343],
            [-1.277680484317034, 53.726497506940873],
            [-1.276114785440231, 53.725882252628175],
            [-1.2730693578491, 53.725650801453021],
            [-1.271957636548697, 53.724906116824251],
            [-1.270956614940364, 53.723371125956781],
            [-1.27081103168165, 53.721098001557316],
            [-1.269633629981951, 53.718379964753304],
            [-1.269544866055039, 53.715893261437053],
            [-1.269025995540399, 53.715144069523909],
            [-1.263587549850617, 53.714032183290215],
            [-1.257697290886401, 53.711834160570767],
            [-1.256339683001141, 53.711752040639858],
            [-1.25424916947457, 53.712675633570264],
            [-1.252937699570123, 53.712810380534535],
            [-1.248181547927478, 53.711711031630891],
            [-1.242801444327846, 53.712299154444963],
            [-1.239013029129282, 53.71233178720064],
            [-1.229569504376614, 53.714887058323121],
            [-1.228752717535149, 53.714560023336801],
            [-1.227589829300083, 53.712861830710452],
            [-1.228005442529455, 53.710435862002214],
            [-1.227229918447421, 53.709929315247514],
            [-1.226072067019037, 53.70986611643238],
            [-1.224788830557555, 53.710113091803962],
            [-1.223938023038054, 53.710654076306326],
            [-1.224923794195309, 53.711837926711077],
            [-1.224631365445143, 53.712333089914431],
            [-1.221725075391702, 53.712840070417847],
            [-1.21980833092205, 53.71440056661455],
            [-1.217135242134485, 53.711230927876386],
            [-1.217831967839052, 53.709526793938601],
            [-1.218792298866309, 53.709292169990569],
            [-1.218439433619733, 53.707076940107889],
            [-1.21563806631768, 53.706926509995682],
            [-1.215660777970536, 53.706112314650056],
            [-1.214944720801109, 53.706004260217078],
            [-1.215127330444079, 53.705471548546157],
            [-1.203982242280578, 53.702102009458152],
            [-1.201207994380212, 53.701819291857447],
            [-1.198814457174408, 53.700713924027397],
            [-1.199674907023891, 53.698712571780533],
            [-1.198988833333416, 53.694878942423777],
            [-1.209764335231178, 53.694486688220806],
            [-1.234726811274977, 53.69269913322163],
            [-1.244413700295657, 53.692410959165478],
            [-1.242429137540346, 53.680465485990162],
            [-1.243954540263153, 53.673231393570546],
            [-1.245159046780671, 53.673339658608818],
            [-1.245650957064926, 53.672834014926984],
            [-1.24643913498436, 53.672945037977236],
            [-1.24654697619831, 53.672245521821694],
            [-1.247759699097881, 53.672146180993771],
            [-1.247782061140997, 53.671825436403928],
            [-1.248777484578565, 53.671602478813519],
            [-1.250215758081375, 53.66936888911443],
            [-1.251763377321762, 53.667960199166636],
            [-1.252316532503766, 53.666636968004319],
            [-1.251188475949939, 53.662566273600639],
            [-1.252501495174534, 53.66242706459505],
            [-1.252293979821094, 53.661753438133957],
            [-1.247967654640442, 53.656211975590374],
            [-1.246957286508356, 53.655595315513771],
            [-1.251157141118486, 53.651609223726723],
            [-1.248773429686262, 53.649611454433852],
            [-1.250072198308645, 53.648803445971886],
            [-1.251680254235302, 53.648382060135546],
            [-1.252686195712788, 53.64838744209478],
            [-1.252613791740019, 53.648717763693099],
            [-1.253016375834954, 53.648793980109168],
            [-1.254328725278055, 53.648496551861633],
            [-1.255213430052134, 53.648943387268105],
            [-1.255365471044448, 53.644777306645985],
            [-1.254856844883191, 53.644454155793291],
            [-1.254949572730308, 53.643312305419556],
            [-1.253659088607037, 53.641180307749664],
            [-1.250638670849198, 53.638778616351239],
            [-1.245300788768179, 53.635319606585547],
            [-1.243551895818239, 53.634253330060758],
            [-1.242884856129622, 53.634596967058989],
            [-1.242687150997217, 53.633560249254437],
            [-1.238055089080165, 53.626835374730526],
            [-1.232841229507838, 53.621094414734756],
            [-1.23109428904346, 53.618888226383078],
            [-1.230037428569538, 53.619061203923785],
            [-1.229222951758226, 53.618874396445818],
            [-1.228389329075158, 53.61749918463007],
            [-1.227889095432338, 53.617325178854742],
            [-1.229060072337055, 53.616860829966242],
            [-1.231295339555204, 53.61689138139279],
            [-1.233331436228855, 53.616650961875578],
            [-1.236553612352522, 53.617029292254792],
            [-1.244127887621685, 53.617081843770279],
            [-1.248176994316992, 53.616275006768142],
            [-1.247094262200618, 53.612895732408035],
            [-1.244800305972453, 53.608116497367092],
            [-1.248440475194527, 53.607820314063261],
            [-1.252196988543642, 53.607095098141357],
            [-1.25053605825177, 53.603847063967272],
            [-1.248926703146517, 53.601965576783812],
            [-1.24721658818083, 53.600744985650451],
            [-1.250569866606348, 53.599801561724675],
            [-1.250997771261761, 53.600470286238028],
            [-1.249855103224089, 53.600655484713634],
            [-1.250260643426076, 53.60113621241976],
            [-1.257695689766423, 53.59983157923935],
            [-1.257657959029356, 53.599052943145011],
            [-1.258390245430973, 53.598741087637272],
            [-1.257892297664502, 53.598355991374888],
            [-1.256094326455505, 53.599034247863109],
            [-1.255497134257832, 53.598880427585598],
            [-1.257410614406021, 53.598077956792608],
            [-1.254956979830458, 53.596944543507625],
            [-1.253638163156254, 53.597528668895833],
            [-1.253005998437777, 53.597131928955037],
            [-1.258078322827527, 53.594895680407006],
            [-1.256969122898405, 53.592838526720556],
            [-1.258082596373246, 53.591977145544028],
            [-1.260557801430652, 53.591863027669646],
            [-1.260898256565567, 53.591486715077068],
            [-1.262777465654443, 53.5912394355611],
            [-1.262904670313673, 53.590788098080246],
            [-1.264223512201499, 53.59131394904955],
            [-1.265488666984517, 53.590668256360267],
            [-1.266059985114758, 53.589781900752293],
            [-1.267009215326243, 53.590103213248213],
            [-1.268294275542778, 53.589525026519482],
            [-1.26950607962925, 53.590039378739313],
            [-1.270537887268077, 53.589865901634084],
            [-1.271754869265512, 53.590169033164159],
            [-1.272891840892606, 53.590117520069967],
            [-1.272884507372114, 53.589752543141103],
            [-1.273862189771541, 53.58947444221581],
            [-1.274865057344839, 53.589930844855004],
            [-1.275416675066506, 53.589742730701623],
            [-1.276691161168498, 53.589953578685062],
            [-1.279474210610126, 53.587539878815761],
            [-1.277807748760321, 53.586044937963067],
            [-1.278339869693947, 53.585489962539334],
            [-1.279309661810955, 53.585401426740297],
            [-1.280110873588147, 53.584298866932357],
            [-1.282263298908638, 53.583056102110461],
            [-1.28599202960747, 53.582379998854726],
            [-1.288236881901695, 53.580743982532518],
            [-1.291067623280566, 53.579827778434122],
            [-1.294527621186036, 53.579300824382067],
            [-1.294743507815635, 53.578943456137893],
            [-1.296068145826482, 53.578758002017906],
            [-1.295915922021635, 53.578465879658737],
            [-1.296798237758718, 53.577993778374669],
            [-1.29858289932402, 53.577478424522241],
            [-1.307937123625792, 53.575385532332255],
            [-1.309783450874505, 53.575705398460777],
            [-1.311802359744016, 53.576652725595416],
            [-1.31492636593311, 53.576791992948678],
            [-1.318449432559573, 53.57747455805022],
            [-1.320818612650994, 53.579106849997331],
            [-1.322123475797551, 53.579584344562356],
            [-1.324188269862022, 53.579577145437653],
            [-1.324854191056101, 53.579871231223173],
            [-1.326231175258238, 53.579331593063024],
            [-1.328277220127692, 53.579077035006279],
            [-1.333670918647988, 53.579543125191563],
            [-1.33435811980136, 53.580025135249336],
            [-1.33500407206351, 53.580056590171388],
            [-1.336250162239663, 53.581019879249055],
            [-1.340277660500636, 53.581847512027522],
            [-1.342185362089988, 53.582820675327547],
            [-1.346362121470465, 53.583978798665179],
            [-1.347645814523155, 53.584191637358551],
            [-1.348633440161755, 53.583324237535606],
            [-1.351432254507781, 53.581063560137302],
            [-1.353860394531103, 53.583383128784661],
            [-1.354612168229479, 53.586190680591919],
            [-1.356420801216791, 53.589145930309407],
            [-1.354634963156492, 53.592034187222758],
            [-1.356157263133519, 53.595354621333918],
            [-1.359902499743719, 53.596778697861339],
            [-1.360225370138652, 53.598611365474632],
            [-1.363856912727748, 53.601697565739258],
            [-1.366035486786198, 53.602842560859848],
            [-1.368433562718507, 53.603463750836447],
            [-1.369432461010725, 53.604175505046356],
            [-1.375935043203262, 53.60593984435684],
            [-1.376788632513749, 53.606473707436692],
            [-1.378946401257346, 53.60660446547881],
            [-1.381596475937537, 53.605417332552577],
            [-1.383965535754048, 53.603799049872052],
            [-1.392766489147925, 53.601406357330951],
            [-1.394213014426574, 53.600641581812575],
            [-1.395635929278799, 53.600362942148237],
            [-1.396103242893744, 53.599806221746285],
            [-1.400208816252345, 53.598635897769654],
            [-1.401428090465876, 53.599394329193181],
            [-1.401869488915122, 53.600238748214863],
            [-1.405301713041382, 53.600417637604949],
            [-1.405983065918067, 53.601075375345076],
            [-1.406867419487261, 53.601050997027762],
            [-1.407220941785348, 53.601380824309707],
            [-1.412807213484464, 53.60130228988546],
            [-1.415165917926346, 53.603176229175673],
            [-1.41756473675315, 53.603247246114314],
            [-1.418969552159122, 53.603732253956458],
            [-1.418798670498814, 53.603299982076102],
            [-1.421565364886479, 53.603213609635233],
            [-1.423228285905616, 53.603502968497089],
            [-1.42492475500285, 53.604439628779048],
            [-1.428725174279943, 53.605027685553537],
            [-1.429445087005851, 53.605547950040801],
            [-1.429196703589125, 53.606596608518437],
            [-1.43190468251572, 53.608197733216969],
            [-1.43239197986808, 53.608150608216796],
            [-1.432007049084229, 53.608453488618494],
            [-1.432872664169332, 53.60893577306571],
            [-1.433440188205853, 53.60854117381686],
            [-1.433892268808369, 53.608641285338514],
            [-1.434250203964181, 53.608095585344522],
            [-1.435568643697275, 53.608056865879455],
            [-1.436619139963448, 53.606573339058372],
            [-1.438061659188269, 53.606747301557547],
            [-1.438433962633556, 53.607164311017527],
            [-1.439254466035415, 53.607062093682856],
            [-1.442437858305174, 53.609778863028389],
            [-1.444747229355057, 53.610594052861366],
            [-1.446807267553472, 53.612799444217636],
            [-1.447064718600654, 53.61229908291962],
            [-1.447627532177349, 53.612722335684545],
            [-1.448290021751389, 53.611529039382624],
            [-1.456070913543517, 53.607435384907753],
            [-1.459095424846299, 53.606229365477184],
            [-1.460466128963427, 53.606115105726985],
            [-1.463819980356368, 53.604423281393053],
            [-1.473017231408626, 53.600795064816261],
            [-1.474098648624031, 53.601325638998837],
            [-1.474999446207769, 53.599971450404453],
            [-1.478512557481539, 53.598266430407584],
            [-1.479289652498068, 53.596879312238322],
            [-1.481256272983947, 53.59631708794381],
            [-1.482816370870749, 53.595138271561737],
            [-1.483828138045766, 53.594810967101466],
            [-1.484264712396406, 53.594941382211623],
            [-1.485104494681298, 53.596953896831813],
            [-1.489388387002481, 53.596179469698313],
            [-1.491967836344711, 53.596788168973234],
            [-1.492862964688147, 53.598755021936832],
            [-1.491535420388841, 53.602728537255985],
            [-1.495070815173938, 53.603174040459642],
            [-1.496560490873172, 53.597355858591577],
            [-1.501479494621787, 53.599862620456967],
            [-1.50721054174843, 53.597695883276494],
            [-1.508051505681497, 53.5982736974399],
            [-1.510395306998347, 53.59747165626068],
            [-1.509801002350757, 53.596991044054136],
            [-1.510404129797901, 53.596838912148677],
            [-1.510867138525894, 53.597175171380435],
            [-1.512880645413945, 53.596967659690591],
            [-1.515875015780822, 53.597927177063191],
            [-1.516318704199451, 53.597710553305745],
            [-1.518685048730784, 53.599695728434071],
            [-1.524410309440883, 53.597694430317276],
            [-1.528380297832569, 53.596963201885686],
            [-1.529884285016851, 53.594052397001434],
            [-1.531074693457044, 53.593793706501728],
            [-1.53026708521372, 53.593321348722931],
            [-1.53078738289773, 53.593133734640688],
            [-1.532138831027354, 53.593749332424878],
            [-1.535372461684489, 53.593851808177781],
            [-1.536289875734584, 53.593684586158467],
            [-1.536259616336165, 53.594516790135692],
            [-1.536992019413908, 53.594827924986099],
            [-1.536612594545748, 53.595259695503145],
            [-1.537871574857862, 53.595224113837986],
            [-1.539224061918419, 53.596454437924407],
            [-1.539780674067066, 53.596540170267119],
            [-1.53987021305709, 53.597477998662519],
            [-1.541072195841966, 53.597688444764529],
            [-1.54182141563945, 53.598269264432496],
            [-1.541520268340631, 53.5987301124269],
            [-1.542411673354871, 53.59846027679032],
            [-1.543012961969647, 53.599310173628155],
            [-1.544721585494569, 53.599486562083364],
            [-1.546389851211053, 53.601175510243962],
            [-1.546724894307701, 53.602345262456019],
            [-1.547650914542249, 53.602375729238346],
            [-1.547356604706233, 53.603189858826354],
            [-1.548112827041242, 53.603414726816169],
            [-1.549164555546503, 53.603137356411125],
            [-1.550473041097943, 53.60429637725656],
            [-1.557174973210332, 53.605202204645423],
            [-1.557596909980884, 53.605897662871193],
            [-1.558721838018615, 53.606862665260508],
            [-1.560038146070143, 53.60672279660362],
            [-1.559810992796716, 53.607202837041932],
            [-1.560467767002815, 53.607557591628137],
            [-1.563443981608459, 53.607669147576026],
            [-1.564652790846042, 53.606960774435422],
            [-1.566162477724009, 53.606678627019953],
            [-1.571926961646734, 53.608592311256736],
            [-1.577050416964638, 53.607548097005044],
            [-1.581893595978057, 53.605781822534773],
            [-1.583551289233742, 53.605647380996402],
            [-1.584540259343684, 53.605895298268436],
            [-1.586450298958973, 53.607157576829977],
            [-1.58617811693601, 53.60840960720973],
            [-1.586880194785534, 53.60894773444052],
            [-1.586715702677209, 53.610316086266813],
            [-1.588422781611831, 53.611182146003337],
            [-1.58951797589951, 53.612806495973715],
            [-1.591614688546548, 53.614038769366225],
            [-1.591827021304665, 53.614592273632688],
            [-1.594654957178011, 53.615568119998358],
            [-1.59421618763272, 53.616082562449066],
            [-1.59585018613144, 53.61640986833428],
            [-1.59575366136756, 53.616861653817473],
            [-1.598412879411651, 53.617162719130604],
            [-1.600695244707083, 53.618155466784927],
            [-1.60178190840205, 53.618023361421656],
            [-1.601755512810596, 53.617615205240362],
            [-1.602307724804824, 53.617407612879639],
            [-1.602517107852385, 53.61813096652709],
            [-1.603092338540705, 53.618048384185897],
            [-1.604204015939362, 53.618635402910208],
            [-1.60540400359181, 53.618671720099137],
            [-1.606040076797229, 53.619524105971102],
            [-1.607581109803816, 53.619629833523469],
            [-1.608245454664436, 53.621517750425852],
            [-1.609982439150281, 53.622844696318246],
            [-1.611683918687293, 53.623438956713699],
            [-1.611872831932198, 53.623942013794284],
            [-1.613689782348543, 53.624648074196685],
            [-1.614509762733975, 53.625921658069949],
            [-1.611899964832597, 53.626754533462609],
            [-1.610296287186054, 53.628004089995351],
            [-1.615672568523318, 53.630243360043764],
            [-1.615592934966967, 53.631852004303568],
            [-1.612185877414009, 53.632680527008127],
            [-1.613574783639664, 53.633767202299033],
            [-1.615852702078193, 53.634720992448159],
            [-1.617280165960021, 53.637749211445431],
            [-1.619436654556837, 53.639120498157936],
            [-1.622117337610909, 53.637362791486154],
            [-1.623204291428205, 53.63809516245513],
            [-1.623696745086987, 53.639353268000477],
            [-1.622698447572889, 53.641394955277342],
            [-1.622781050500536, 53.642139442361142],
            [-1.622237187756175, 53.642911616896647],
            [-1.620728396747479, 53.643666357029069],
            [-1.619244471292419, 53.646358123773531],
            [-1.620869949260306, 53.647787914531897],
            [-1.62174832206529, 53.649372620697257],
            [-1.621637940523571, 53.650042794662731],
            [-1.62250067178193, 53.651015346456859],
            [-1.623378115006076, 53.651359660544209],
            [-1.623978702540998, 53.653065715666266],
            [-1.624841100746793, 53.653577152705438],
            [-1.624202852649789, 53.654061415963255],
            [-1.623263386687344, 53.654048578871404],
            [-1.623124568076119, 53.654345652742741],
            [-1.621625794665978, 53.654613272788623],
            [-1.621262160424277, 53.655345563483706],
            [-1.617815220770707, 53.655366976702567],
            [-1.616763597703327, 53.655706069482257],
            [-1.611033494028965, 53.656210720981555],
            [-1.610303913663357, 53.656896848893204],
            [-1.606288388223282, 53.657029331703527],
            [-1.606125607914932, 53.657285860139417],
            [-1.603236516273951, 53.657987297733264],
            [-1.600233588056786, 53.658235279137244],
            [-1.600248099728808, 53.6586209226422],
            [-1.599531030072066, 53.658602349469284],
            [-1.599474964164292, 53.658291169293747],
            [-1.598475368339423, 53.658386694455096],
            [-1.596658372358057, 53.658829104837643],
            [-1.595293190721981, 53.65980781422293],
            [-1.59255680434503, 53.659073190720441],
            [-1.591862490861861, 53.660772299971313],
            [-1.590833565299581, 53.660450607631454],
            [-1.590351239915487, 53.661034093484908],
            [-1.590557778108592, 53.66173947656678],
            [-1.591411590119372, 53.662332917496776],
            [-1.591247587574522, 53.662860865939805],
            [-1.595062128691197, 53.664657080649391],
            [-1.59945626181842, 53.667611895095263],
            [-1.602884541920468, 53.669200736301882],
            [-1.606659265955803, 53.669209589791635],
            [-1.608435455640764, 53.670921368716286],
            [-1.607523651316116, 53.671964613616126],
            [-1.608021942349825, 53.672421946522839],
            [-1.61076102245239, 53.672658275453109],
            [-1.613944309757608, 53.673650083696351],
            [-1.61467430107683, 53.674604283535331],
            [-1.61518302687862, 53.677252043964408],
            [-1.613886292778788, 53.677183158481249],
            [-1.613741616873714, 53.677774115717448],
            [-1.610741834683222, 53.677863280849685],
            [-1.607627302661613, 53.679059341251175],
            [-1.605097440924683, 53.679396179237344],
            [-1.602241860600399, 53.683932965536975],
            [-1.598834907145417, 53.683642078247459],
            [-1.595913291977083, 53.686750267766058],
            [-1.593048033676158, 53.688993912895846],
            [-1.591961947815596, 53.689344355187828],
            [-1.592218282974393, 53.69149610021082],
            [-1.595531242111775, 53.691226893519385],
            [-1.602608208000451, 53.69214668559659],
            [-1.602020269031065, 53.692666948237139],
            [-1.602638971329147, 53.693383560893551],
            [-1.602197782760625, 53.696168431943015],
            [-1.601419647876228, 53.696454367584323],
            [-1.598316915061378, 53.699739973568079],
            [-1.595886702979075, 53.700605456052244],
            [-1.595111005737287, 53.699992542004118],
            [-1.594035947148524, 53.700271134755162],
            [-1.58965964728768, 53.702339692559988],
            [-1.586462842495995, 53.703028885997149],
            [-1.584215807211029, 53.703093019064738],
            [-1.576461630645464, 53.704608244473661],
            [-1.571129250564608, 53.706415682856623],
            [-1.566760453895454, 53.704039680469734],
            [-1.566357068439424, 53.704083162845038],
            [-1.565473268389073, 53.702970821859466],
            [-1.564716427519956, 53.702771235071857],
            [-1.565030062811702, 53.702175558580166],
            [-1.564493521708478, 53.702206866813434],
            [-1.564260223295027, 53.701622686310053],
            [-1.562890237198444, 53.701395690687292],
            [-1.562481659135414, 53.700773116977018],
            [-1.561957325735791, 53.700797267843356],
            [-1.560450716358922, 53.699730248753937],
            [-1.560681226253707, 53.699412013293127],
            [-1.559261928447112, 53.698984358717922],
            [-1.557599912038848, 53.700330059142374],
            [-1.555635950587373, 53.702721740471503],
            [-1.555022388173767, 53.703160784112903],
            [-1.554495880227234, 53.702813682492263],
            [-1.553951755540115, 53.702414381527355],
            [-1.552097326515614, 53.705447269054716],
            [-1.547120364856255, 53.710166237302118],
            [-1.540640086709914, 53.713134643078376],
            [-1.535748406823669, 53.714586264926169],
            [-1.533994462453361, 53.714802366795922],
            [-1.532503398386125, 53.716316461101499],
            [-1.517363157766959, 53.724646921328862],
            [-1.513236429897943, 53.727525325207509],
            [-1.510539376236728, 53.729675098849029],
            [-1.505581957687434, 53.729111874993919],
            [-1.505963679843039, 53.727062350216471],
            [-1.503783127971503, 53.726895154020504],
            [-1.503238049997003, 53.727234447125738],
            [-1.501925806318585, 53.725797188590491],
            [-1.504381678401623, 53.724971466085485],
            [-1.501320465837281, 53.723670768478904],
            [-1.498791239024761, 53.723401362729071],
            [-1.495806524098696, 53.722317458117473],
            [-1.491863963266147, 53.722134532142],
            [-1.490387545039186, 53.722414988385211],
            [-1.490379127969134, 53.723111536147556],
            [-1.489275671619419, 53.723000780237065],
            [-1.487949844740284, 53.727724701126249],
            [-1.480992154775557, 53.727789160932083],
            [-1.461887619285299, 53.727991662543097],
            [-1.459398182669344, 53.727809688296581],
            [-1.457397880234938, 53.727191253645529],
            [-1.456621928899504, 53.727774667741279],
            [-1.454616129369154, 53.72805318230354],
            [-1.443460794934337, 53.728163706735408],
            [-1.439667379611454, 53.727453947401955],
            [-1.434671685895418, 53.725651731848949],
            [-1.431326553484351, 53.723307968212509],
            [-1.430783938997991, 53.723312583689513],
            [-1.430997382647001, 53.723105969495641],
            [-1.428254223750911, 53.721324939010543],
            [-1.425188824614978, 53.7228571503871],
            [-1.423089985691783, 53.723513094662685],
            [-1.421969550456291, 53.723436690898509],
            [-1.421191527606767, 53.722704892514592],
            [-1.419675446111005, 53.72208726396989],
            [-1.416390604924995, 53.722256475003192],
            [-1.411975073922767, 53.723086081720531],
            [-1.406782940425756, 53.721687103153045],
            [-1.401534948508875, 53.719656646664355],
            [-1.398947727165061, 53.719370460716469],
            [-1.397351205316319, 53.720171385257466],
            [-1.396155087950864, 53.722744086418729],
            [-1.394484076254924, 53.723759415892275],
            [-1.381708872170472, 53.726605493665851],
            [-1.376070602434725, 53.729481275208322],
            [-1.374116673854267, 53.729797362922895],
            [-1.371995109098903, 53.729561562997752],
            [-1.369733218464159, 53.729386107720956],
            [-1.367946040670879, 53.729596910424419],
            [-1.361020291795484, 53.731918707457289],
            [-1.359660734722282, 53.731909656096178],
            [-1.357800538651887, 53.731430521014424],
            [-1.356477573253072, 53.731507916458128],
            [-1.340079676148301, 53.736339832829209],
            [-1.338273495340605, 53.736477284634518],
            [-1.333362655109763, 53.735649228643915],
            [-1.329293887797666, 53.736597265304049],
            [-1.328058705184861, 53.736575069146667],
            [-1.326487733467494, 53.736125830947827],
            [-1.324390620298772, 53.73492848351114],
            [-1.323082817730792, 53.734806057239496],
            [-1.320249193336552, 53.735673568719172],
            [-1.315913093246609, 53.739716982332801],
            [-1.313785847121297, 53.740936214402282],
            [-1.311603308324568, 53.740984823236751],
            [-1.309757251468272, 53.73955856251775],
            [-1.308200363683907, 53.739087593903228],
            [-1.302039565337176, 53.741707014216097],
            [-1.299737783049039, 53.741628877524761],
            [-1.296466671747108, 53.739582878962182],
            [-1.293664356327787, 53.739344392796269],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E08000037",
        LAD13CDO: "00CH",
        LAD13NM: "Gateshead",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-1.772530730692322, 54.980515468100869],
            [-1.763982783257283, 54.980193670873263],
            [-1.753387833210021, 54.981968120619804],
            [-1.750474604247341, 54.98182559570013],
            [-1.746729450564995, 54.981046846990445],
            [-1.728479835309194, 54.974978665161892],
            [-1.727364727342058, 54.973773816112185],
            [-1.728017420663907, 54.971274408260527],
            [-1.726861518216276, 54.96965250134064],
            [-1.723221342652651, 54.967920719885306],
            [-1.718888517965213, 54.966784816149477],
            [-1.709750768229039, 54.966190948523554],
            [-1.707698585806563, 54.967028049164291],
            [-1.70573853886728, 54.969781204464184],
            [-1.703218465102861, 54.970270228634085],
            [-1.701457905782934, 54.970283896288635],
            [-1.697407258291885, 54.969476815466621],
            [-1.690292395543253, 54.966193319818217],
            [-1.683342424242759, 54.964134660326074],
            [-1.679874857858743, 54.96358102281976],
            [-1.682790053282091, 54.961489465462201],
            [-1.684210560465153, 54.959980771856117],
            [-1.686739531882916, 54.959097667315511],
            [-1.686447426026934, 54.958505619168868],
            [-1.685190548273657, 54.958276819544089],
            [-1.684983740865754, 54.956764794936305],
            [-1.68604582523457, 54.956334401314045],
            [-1.689438273779226, 54.956433866279639],
            [-1.691817427431452, 54.955699445667442],
            [-1.694181240012732, 54.953888385385845],
            [-1.696488316070012, 54.952741222593815],
            [-1.698854875421498, 54.950301937656974],
            [-1.699335010984551, 54.94872693278478],
            [-1.698932272376271, 54.948718748127284],
            [-1.69850853938976, 54.948803967362984],
            [-1.69852357006882, 54.95034335288878],
            [-1.697514608343994, 54.95101571824452],
            [-1.696465330189918, 54.952476070378893],
            [-1.694155125443299, 54.953625920784361],
            [-1.691887607100159, 54.955302430460115],
            [-1.689226087275979, 54.95613857520501],
            [-1.685797960080428, 54.956080349000032],
            [-1.684023030412507, 54.957009426834517],
            [-1.68356473818903, 54.958100067224393],
            [-1.686280318682036, 54.958908671689194],
            [-1.684199765890487, 54.959564679993314],
            [-1.681644366775284, 54.961805486393473],
            [-1.679099379732923, 54.963291420585186],
            [-1.67630467739186, 54.963953496533854],
            [-1.670720372689496, 54.963473029197729],
            [-1.661436568609588, 54.962218238252952],
            [-1.660655083376394, 54.961733499475649],
            [-1.659552332571242, 54.961937103916043],
            [-1.648993570936261, 54.958859866015551],
            [-1.648328609034108, 54.959362974853747],
            [-1.642730030639365, 54.958487580411074],
            [-1.641604967487281, 54.958214684104348],
            [-1.64233322503664, 54.958153024042602],
            [-1.641725915579528, 54.957959829985917],
            [-1.637381181108683, 54.957826545528128],
            [-1.638278665982477, 54.956830840907585],
            [-1.63827770588506, 54.95606790290185],
            [-1.636903214312102, 54.955413202849762],
            [-1.635014214500392, 54.955367113671564],
            [-1.635223597010456, 54.953626199702953],
            [-1.634706637094427, 54.953123214235383],
            [-1.63319277894084, 54.95287333478948],
            [-1.633559728391759, 54.95235593157814],
            [-1.635633998554138, 54.952790806051702],
            [-1.635905725350183, 54.951921746820737],
            [-1.634539122363512, 54.951277826475746],
            [-1.633709388368592, 54.951522454635366],
            [-1.6335211838006, 54.950412979846014],
            [-1.632346176271456, 54.949873037369791],
            [-1.627921106354095, 54.947839384344299],
            [-1.629430703277094, 54.948868428733356],
            [-1.632876704563224, 54.950169643812437],
            [-1.633380318492392, 54.95045119656627],
            [-1.633633571653422, 54.951618379681413],
            [-1.634589659566783, 54.95138671233628],
            [-1.63567155920384, 54.951917451443521],
            [-1.635585464965786, 54.952632502082935],
            [-1.633321644383385, 54.952267148778141],
            [-1.632985198342271, 54.953035360686954],
            [-1.634606494876768, 54.953318814610917],
            [-1.635042872764592, 54.953926698372044],
            [-1.63453535221958, 54.955309961548451],
            [-1.634998118710934, 54.955592621081138],
            [-1.636443075175871, 54.95553044797586],
            [-1.637623489922442, 54.955887129574123],
            [-1.638019144506847, 54.956343012885327],
            [-1.637713125397473, 54.957033147147229],
            [-1.636670425235059, 54.957678847908049],
            [-1.634923531578629, 54.956931353238623],
            [-1.63196577053547, 54.956949403017887],
            [-1.62965885068893, 54.957852714709773],
            [-1.61954631938914, 54.9606252398615],
            [-1.61519073231607, 54.962259629065919],
            [-1.614311873166201, 54.963195014450676],
            [-1.609994995427707, 54.965620132679859],
            [-1.604544720240173, 54.96792999029379],
            [-1.598130254044719, 54.969433051022428],
            [-1.594076507564387, 54.969921032290138],
            [-1.591021873897026, 54.969845206033625],
            [-1.588041991155666, 54.969181853470069],
            [-1.582737158977464, 54.965426385828565],
            [-1.580404475843083, 54.964151298678786],
            [-1.579021570800898, 54.963767301177981],
            [-1.576433752255304, 54.963309006905106],
            [-1.574794703684323, 54.963376079892598],
            [-1.574685721093938, 54.96378727124123],
            [-1.572057269846912, 54.964071009569821],
            [-1.568242314659249, 54.963867024643285],
            [-1.567201771686997, 54.96360991484886],
            [-1.567378976300682, 54.963397569751649],
            [-1.566338721005415, 54.963405549283664],
            [-1.562837573607326, 54.962213121058447],
            [-1.558554944855948, 54.959670711975086],
            [-1.556005166992465, 54.958497708383597],
            [-1.554005192081506, 54.958173177642841],
            [-1.552787317942309, 54.958297211883405],
            [-1.552420183893854, 54.958029867233385],
            [-1.549522259274531, 54.958969924749702],
            [-1.547826836183656, 54.959897313809805],
            [-1.544704235587684, 54.960382612965311],
            [-1.542125212664884, 54.960302842495089],
            [-1.538213883922789, 54.960648412770844],
            [-1.536527736802923, 54.961283624758977],
            [-1.535309021598731, 54.962280051804242],
            [-1.533917746762794, 54.964551861627712],
            [-1.534335518084242, 54.964621756989672],
            [-1.534159847452933, 54.965091068492249],
            [-1.531236394757594, 54.964386098846454],
            [-1.529333697490587, 54.96334712581708],
            [-1.53068639426247, 54.95797044632976],
            [-1.527768390305666, 54.957116239439301],
            [-1.526340750874977, 54.958076719787698],
            [-1.522399710497494, 54.957378353648259],
            [-1.519630567658825, 54.957547179023322],
            [-1.518969897592102, 54.959257362171869],
            [-1.516211485954127, 54.958130324385841],
            [-1.515316790573514, 54.957342257411071],
            [-1.515204283046125, 54.956050473877092],
            [-1.516280679013024, 54.954968310819105],
            [-1.518553324389649, 54.952167299246476],
            [-1.518049413556669, 54.947040396148751],
            [-1.516330272425165, 54.945371998742793],
            [-1.512353028874214, 54.936813694689704],
            [-1.510351634181451, 54.93209590199006],
            [-1.511204349826339, 54.931658101130452],
            [-1.512075977565161, 54.932106425871773],
            [-1.514505966161877, 54.931707269748053],
            [-1.515648123917056, 54.931073791359104],
            [-1.51723102110663, 54.930772750640969],
            [-1.519010622131096, 54.931117688327369],
            [-1.524673410843208, 54.933147502419096],
            [-1.527400241303809, 54.933114993124178],
            [-1.530463736513095, 54.93258318266264],
            [-1.532453503482842, 54.931374094492185],
            [-1.535654758469748, 54.932143008624948],
            [-1.54311013341461, 54.931422677566573],
            [-1.543841556308673, 54.931465861310301],
            [-1.547626057692776, 54.930483408489245],
            [-1.551877007920003, 54.928654245700372],
            [-1.553293582422952, 54.92769791655455],
            [-1.556692199441975, 54.929970418058396],
            [-1.568879343452326, 54.924617022540531],
            [-1.567142459423217, 54.923186516596282],
            [-1.565530075655416, 54.920058008174145],
            [-1.563479291598357, 54.917532685948856],
            [-1.560078842464414, 54.914630417152452],
            [-1.560379765815227, 54.914500304316618],
            [-1.557068463311134, 54.909203401399786],
            [-1.557057953912288, 54.90788685830212],
            [-1.5586607409175, 54.906510580331741],
            [-1.560080459352607, 54.906324314818228],
            [-1.562773324318257, 54.905081325274047],
            [-1.561599974557038, 54.904558586281539],
            [-1.55969277899963, 54.902974584364053],
            [-1.5581836174093, 54.902651890669588],
            [-1.558508570569306, 54.891473093937194],
            [-1.558296118537376, 54.891084109521742],
            [-1.557050097201912, 54.89097533387006],
            [-1.557206986258516, 54.888182929451467],
            [-1.558516464036193, 54.888183198589232],
            [-1.558643314939795, 54.886417829254064],
            [-1.558143635413699, 54.885640486633761],
            [-1.556250817387053, 54.885253466831784],
            [-1.55611720429467, 54.884787482681084],
            [-1.558579957262606, 54.884369595319022],
            [-1.559398889411976, 54.882028908473153],
            [-1.561999257869345, 54.880831429475997],
            [-1.568058429135413, 54.880463998315939],
            [-1.571467861429148, 54.8793635465303],
            [-1.575900124328783, 54.878945945127903],
            [-1.575864929487614, 54.878289813837888],
            [-1.579897638389008, 54.877761936410074],
            [-1.580700439256541, 54.881050141392556],
            [-1.582704019044229, 54.888826681957902],
            [-1.584029738089361, 54.891642170138176],
            [-1.583958437380439, 54.892812853378338],
            [-1.586281752821036, 54.894155260330912],
            [-1.587550632216004, 54.894333008589875],
            [-1.588182458052471, 54.894449277376438],
            [-1.58852454156458, 54.895458708343043],
            [-1.589695457822662, 54.896229204049135],
            [-1.591809667073895, 54.89978594124581],
            [-1.59363741948697, 54.900851557176672],
            [-1.593757922212406, 54.901738915259365],
            [-1.594152844221641, 54.902010723901149],
            [-1.597690870350486, 54.901574959219616],
            [-1.600736855018223, 54.899795811123766],
            [-1.600280419609187, 54.89896217300408],
            [-1.602334864275013, 54.898403665917016],
            [-1.603901432723532, 54.898298246610345],
            [-1.604781250063493, 54.898737847343959],
            [-1.605144747287595, 54.898241182942357],
            [-1.60731599345362, 54.898328192856411],
            [-1.606882294942151, 54.897068698615975],
            [-1.608002018128441, 54.896888991389467],
            [-1.608397626936676, 54.896290874629024],
            [-1.610249814520132, 54.895337978896038],
            [-1.611903105860448, 54.895279463724663],
            [-1.616478729873194, 54.891068585407076],
            [-1.618307001270614, 54.890126274945352],
            [-1.618973600714221, 54.888706719506999],
            [-1.618508934171276, 54.887727543457387],
            [-1.618732006350772, 54.887050669461324],
            [-1.620989662379248, 54.887337204873674],
            [-1.622265807720194, 54.886731000511965],
            [-1.623797204177495, 54.887335140648574],
            [-1.627605707443275, 54.886299951059321],
            [-1.628406952632883, 54.885112604749516],
            [-1.629516598503734, 54.884795177027577],
            [-1.631104711464641, 54.884825167817127],
            [-1.631570103466337, 54.884557885966025],
            [-1.631338138280523, 54.884013506280937],
            [-1.632297027093955, 54.883299296167223],
            [-1.633240372829887, 54.883263504046646],
            [-1.634902027329355, 54.882565769535468],
            [-1.636586919486482, 54.881010780012879],
            [-1.639183400643568, 54.880682422461547],
            [-1.639961291180891, 54.879791481192441],
            [-1.643677920795509, 54.878795954850894],
            [-1.645418747042507, 54.878802841759629],
            [-1.648998445533052, 54.879551011971067],
            [-1.650485874971733, 54.879306374113582],
            [-1.650970657137277, 54.879659134632675],
            [-1.650643403646346, 54.880735662728924],
            [-1.64760853209328, 54.880943476256903],
            [-1.646334372655907, 54.88138729868745],
            [-1.646966804229548, 54.882143993275569],
            [-1.646707704731971, 54.882720166691243],
            [-1.647741088886053, 54.884153797545629],
            [-1.647968047771062, 54.885477250425879],
            [-1.646581354926403, 54.88558286149339],
            [-1.646609825370563, 54.888387593443703],
            [-1.647337595320931, 54.888593697198829],
            [-1.648468601422548, 54.891727825207333],
            [-1.650000345256571, 54.893618482896834],
            [-1.653498075710793, 54.89299765689865],
            [-1.655842490909331, 54.892135332845051],
            [-1.656485627928839, 54.890756844912026],
            [-1.659939651116144, 54.890656919704938],
            [-1.659296499703107, 54.893160518957792],
            [-1.661368610814425, 54.894267136632322],
            [-1.663024559809529, 54.895931517840026],
            [-1.665547134228403, 54.895203397354187],
            [-1.669220057423697, 54.895642991372199],
            [-1.675243483027257, 54.897934571379366],
            [-1.676578282103308, 54.899095570792177],
            [-1.678122334005002, 54.899837446624581],
            [-1.677961927585197, 54.901767289904043],
            [-1.676524114640316, 54.90332799736337],
            [-1.676832080404087, 54.903429463132504],
            [-1.674826134860383, 54.909294894047122],
            [-1.67476634924416, 54.909552642097424],
            [-1.67634121528941, 54.909414858805718],
            [-1.681939369775747, 54.907789619790599],
            [-1.683465136774515, 54.908531378286135],
            [-1.687643661587818, 54.907909542443186],
            [-1.689132642373126, 54.90696080534488],
            [-1.689538492365745, 54.906509829152526],
            [-1.689146611342045, 54.90575397582527],
            [-1.690757080069393, 54.904606930946542],
            [-1.690561474775647, 54.902658194553879],
            [-1.691678566107565, 54.902590039369422],
            [-1.693379868789397, 54.903214397818275],
            [-1.693985950377269, 54.904110963754242],
            [-1.695645361347592, 54.904719908024092],
            [-1.697558547701087, 54.906226297187715],
            [-1.696688338224226, 54.906635704509348],
            [-1.698475933975513, 54.908059989727064],
            [-1.698753794522649, 54.908864056300381],
            [-1.69840190284747, 54.908992587490502],
            [-1.70056620902331, 54.909278308014791],
            [-1.710377864273494, 54.909129527070384],
            [-1.713285780628763, 54.908821894263035],
            [-1.71640494215757, 54.907743657630299],
            [-1.717006844713744, 54.90842891894215],
            [-1.719194924706188, 54.908001747194881],
            [-1.721192828514476, 54.908231000866706],
            [-1.721016914188091, 54.908634982244486],
            [-1.722220344556006, 54.908955852123263],
            [-1.723048324263473, 54.910125063481701],
            [-1.724896337224385, 54.908792990275728],
            [-1.725850907018074, 54.909706358485067],
            [-1.727624375059251, 54.910391505402927],
            [-1.728020906668996, 54.911496811411567],
            [-1.727701882499032, 54.912775749621183],
            [-1.728753217022586, 54.914169180087541],
            [-1.73013365286076, 54.915114015809245],
            [-1.730074239732379, 54.915600942216429],
            [-1.730588567633706, 54.915669479590498],
            [-1.730911104966577, 54.91642773954662],
            [-1.732310749432909, 54.916612350629983],
            [-1.73308264001405, 54.917602540661477],
            [-1.733747571739066, 54.917548282477],
            [-1.734149138022719, 54.918162026871883],
            [-1.737579369072039, 54.918692476788543],
            [-1.739614790136381, 54.91683129224571],
            [-1.742391293785817, 54.91736739476783],
            [-1.746206321708096, 54.916500145946699],
            [-1.747353566287399, 54.917393076619454],
            [-1.748300379086875, 54.91766283085137],
            [-1.750560856696743, 54.917412274858314],
            [-1.751850164424457, 54.916532455889275],
            [-1.755421102969152, 54.912649513081753],
            [-1.757074799003268, 54.911568178169084],
            [-1.759863702789112, 54.910783817585703],
            [-1.76020927182853, 54.909062721217211],
            [-1.760814624943962, 54.9085040652744],
            [-1.762058914022109, 54.908296225251242],
            [-1.765348363734629, 54.908547041617361],
            [-1.766098206580408, 54.90887918185566],
            [-1.766320668317046, 54.909517637650794],
            [-1.767176281306141, 54.909634302726403],
            [-1.768242700274179, 54.908894069137141],
            [-1.768096544347899, 54.906913206587618],
            [-1.769240917690512, 54.906707798473512],
            [-1.770846227698186, 54.907174526482258],
            [-1.772692159411524, 54.907847469603347],
            [-1.776408014014684, 54.909964340979776],
            [-1.781746295297383, 54.911535853693678],
            [-1.782366011979238, 54.91115774144378],
            [-1.782453199046108, 54.910029216923455],
            [-1.783164387718383, 54.90974831618874],
            [-1.785802144106465, 54.909111365445021],
            [-1.791241566742882, 54.908666111398112],
            [-1.791613610502095, 54.907596479449246],
            [-1.793939936148143, 54.905280175001685],
            [-1.794090237935787, 54.903941468388005],
            [-1.794915696013796, 54.903539376801348],
            [-1.796210856869944, 54.903692522913254],
            [-1.798814785475881, 54.905656771217203],
            [-1.800061841511134, 54.906092865471358],
            [-1.802759535749883, 54.904574986470642],
            [-1.804606143854553, 54.904548310688611],
            [-1.805559846465957, 54.905023416982083],
            [-1.806276441879421, 54.906490227298086],
            [-1.807452026098814, 54.907219085470842],
            [-1.810485092980743, 54.90664153366555],
            [-1.814806521838057, 54.905332590574417],
            [-1.816638962121885, 54.905340756988195],
            [-1.81924554042427, 54.905912590962011],
            [-1.821002767559196, 54.905654585076199],
            [-1.822102232611748, 54.906015649463299],
            [-1.823695251512059, 54.908003042682836],
            [-1.824412428124015, 54.9080642876566],
            [-1.824991056333987, 54.908785822648646],
            [-1.826857510812502, 54.909628711330114],
            [-1.826710330181072, 54.910124545761462],
            [-1.831127136083608, 54.910588156298189],
            [-1.833975877607823, 54.911575176173287],
            [-1.834413995667588, 54.912000824819771],
            [-1.835672998576993, 54.911943221298102],
            [-1.839683268554172, 54.913123077502092],
            [-1.842293769683736, 54.913301719935042],
            [-1.84583528094109, 54.914364841371643],
            [-1.84901990405224, 54.914552152516656],
            [-1.850360588262655, 54.915199921961708],
            [-1.852711168659948, 54.917406226098343],
            [-1.851941943397129, 54.917443035596264],
            [-1.851412698514604, 54.918399430423833],
            [-1.852076872909433, 54.918511669038494],
            [-1.851882572585421, 54.91874417763453],
            [-1.848521786900717, 54.920928203217763],
            [-1.846730214005471, 54.922707940834087],
            [-1.844355749961389, 54.92589505749914],
            [-1.843986214313203, 54.927037638750647],
            [-1.842591341158345, 54.927807764472689],
            [-1.841593541034826, 54.927983500088757],
            [-1.841138873371769, 54.929718156827569],
            [-1.833486119905037, 54.930492433405988],
            [-1.827635678654806, 54.930132029907966],
            [-1.826940880463566, 54.93021821113539],
            [-1.827001259980847, 54.930694569335763],
            [-1.827273982662858, 54.9311460673376],
            [-1.825605850908647, 54.93365175702904],
            [-1.826558661986523, 54.934188699517605],
            [-1.827197776320641, 54.936553894355136],
            [-1.824654000898065, 54.939007101418156],
            [-1.824846569866286, 54.940311284641368],
            [-1.825152945359395, 54.940930878050615],
            [-1.82712271439437, 54.941689434610915],
            [-1.829465317861507, 54.943631072630438],
            [-1.831067169025693, 54.944322550758748],
            [-1.831238225048697, 54.944853875297014],
            [-1.829841657463349, 54.945821548168354],
            [-1.830122425600212, 54.946247888303297],
            [-1.829674467140235, 54.946596827617924],
            [-1.829883126270991, 54.947094058719657],
            [-1.830531799656949, 54.947278282548112],
            [-1.830327899212207, 54.947503553405191],
            [-1.831370344196804, 54.947979475061132],
            [-1.832253826823684, 54.949144413747156],
            [-1.832030581733652, 54.9495161364963],
            [-1.833829652744138, 54.951291589787125],
            [-1.833391635699833, 54.951495877634613],
            [-1.833691842829015, 54.953285447900299],
            [-1.832934503304927, 54.953298789389464],
            [-1.831298341256426, 54.954745115261282],
            [-1.82879254693103, 54.955395816460523],
            [-1.827206780431642, 54.955230027492064],
            [-1.826500919332837, 54.955609141705615],
            [-1.82610537362327, 54.955359658884511],
            [-1.825318484989568, 54.95569641521481],
            [-1.824413705370455, 54.95513257582833],
            [-1.82298694071989, 54.95574606752961],
            [-1.822619361577665, 54.956598326318314],
            [-1.823500131846693, 54.957297836227845],
            [-1.823059311945138, 54.95775819028313],
            [-1.824116031220026, 54.959299062172384],
            [-1.822900037871697, 54.959534536508208],
            [-1.823694853593001, 54.959906820899562],
            [-1.822838534421453, 54.960381848393133],
            [-1.82361508714407, 54.961005721026993],
            [-1.824114803912874, 54.961012734050072],
            [-1.823922236088855, 54.961479739576994],
            [-1.82455950738364, 54.961459990870857],
            [-1.824679836814605, 54.961801640708622],
            [-1.824442552961143, 54.96249593410225],
            [-1.823405800583215, 54.96279277798368],
            [-1.822548649044045, 54.963797090589836],
            [-1.822581609055349, 54.96482516239319],
            [-1.821266150137824, 54.964909506867166],
            [-1.822023202026756, 54.965719375289247],
            [-1.821728896843822, 54.966223970434342],
            [-1.821078408864412, 54.966037001887955],
            [-1.820306992597029, 54.966350383939201],
            [-1.818742086489533, 54.966320207879214],
            [-1.817694627469787, 54.966881181900874],
            [-1.817446405854356, 54.967543094417366],
            [-1.816732673303668, 54.967180776601737],
            [-1.815498210599071, 54.967294835092268],
            [-1.815075224079619, 54.967909749559773],
            [-1.815986246655516, 54.968851087640132],
            [-1.814042590367505, 54.969300142349439],
            [-1.814228281190382, 54.969680542633022],
            [-1.813448946542837, 54.970328156203713],
            [-1.813783458294893, 54.970955007202235],
            [-1.812428736955533, 54.97103739889922],
            [-1.812586527118322, 54.972041401769722],
            [-1.811689769884014, 54.972729261092965],
            [-1.8132422274346, 54.973138726675259],
            [-1.811969125150297, 54.974127048345863],
            [-1.813078983871002, 54.974653551156784],
            [-1.812801000467087, 54.976314673923632],
            [-1.804854777568505, 54.977997899485779],
            [-1.802718225384327, 54.978797824395862],
            [-1.799720726172577, 54.979590008801885],
            [-1.792098597487931, 54.983639000177149],
            [-1.784959123420746, 54.984517136947524],
            [-1.784758022961933, 54.983842816784239],
            [-1.776192746072649, 54.981152185898743],
            [-1.772530730692322, 54.980515468100869],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000001",
        LAD13CDO: "00AA",
        LAD13NM: "City of London",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.096786317502036, 51.523321274956338],
            [-0.096469839081942, 51.522821549498829],
            [-0.09508866726008, 51.523137246696528],
            [-0.094344703917476, 51.521483122963083],
            [-0.092516805496073, 51.521485784313022],
            [-0.092374509863949, 51.52102754779542],
            [-0.089693501449045, 51.520715036569399],
            [-0.090004309824143, 51.519970119053006],
            [-0.086227451482666, 51.518808765269455],
            [-0.085217906072325, 51.520334513123139],
            [-0.083325513387581, 51.519814409299038],
            [-0.081762308163553, 51.520757353671321],
            [-0.08105040328821, 51.521953410232598],
            [-0.078471498381361, 51.521510121899681],
            [-0.07942976737096, 51.518845132043808],
            [-0.078082665299105, 51.518967846623347],
            [-0.078146910966955, 51.518468912344851],
            [-0.076876920431666, 51.516658573690059],
            [-0.073969103917084, 51.51445353927113],
            [-0.073063209678798, 51.511808328254958],
            [-0.072764024995697, 51.510804335791015],
            [-0.072990978121172, 51.510104844491586],
            [-0.07558409134128, 51.509749949830159],
            [-0.076285613880824, 51.510543813617751],
            [-0.077789496372133, 51.510114345792225],
            [-0.078882588397736, 51.509411950165749],
            [-0.079099246490528, 51.509057594085412],
            [-0.078721275746713, 51.50882748749185],
            [-0.079379467386995, 51.507842788942007],
            [-0.080402117896056, 51.508025894582715],
            [-0.081531029935684, 51.508389681897796],
            [-0.085480962114542, 51.508596310760012],
            [-0.088680539499951, 51.508930864392674],
            [-0.091988265267066, 51.509402877635338],
            [-0.095259944467686, 51.510118809404716],
            [-0.095240100571608, 51.510560021973347],
            [-0.096149570852431, 51.510211489333315],
            [-0.09990281876628, 51.510751658759311],
            [-0.108472246857883, 51.510810143583122],
            [-0.111574406096813, 51.510754920448136],
            [-0.111530488528424, 51.511642676546018],
            [-0.112414907460231, 51.512769290922279],
            [-0.111738659689328, 51.513195443028046],
            [-0.111980720190731, 51.513684936952295],
            [-0.111101526426128, 51.513825452621518],
            [-0.111606814356538, 51.515338040748837],
            [-0.113821071242378, 51.518257575299678],
            [-0.10782664550493, 51.517765353480101],
            [-0.105349922689427, 51.518540995513298],
            [-0.10182079708464, 51.519665555451709],
            [-0.100301027326701, 51.520128346608146],
            [-0.097670170070134, 51.520722379476389],
            [-0.097624119100265, 51.521031875416014],
            [-0.097403251532925, 51.521593024828761],
            [-0.09797245268005, 51.522877398970415],
            [-0.096786317502036, 51.523321274956338],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000002",
        LAD13CDO: "00AB",
        LAD13NM: "Barking and Dagenham",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [0.071028987459007, 51.537152492415267],
              [0.072919773360255, 51.534525588980472],
              [0.072886526397391, 51.533508198674795],
              [0.075161810047767, 51.531574095725752],
              [0.07548734609635, 51.52982374337703],
              [0.0754590958661, 51.529868307716555],
              [0.073165676725558, 51.529370201346325],
              [0.072712875431019, 51.529272988737766],
              [0.072566831154866, 51.530787249407304],
              [0.07191677457608, 51.532950696450435],
              [0.069457013866559, 51.536289956221616],
              [0.068705194389152, 51.536214189819788],
              [0.067931530850096, 51.53619725644662],
              [0.068255909347036, 51.536990990850967],
              [0.068887807295215, 51.537188478674423],
              [0.066965702558576, 51.539609033062511],
              [0.06664900817895, 51.540862802709832],
              [0.068269421321698, 51.544254174518905],
              [0.068327153038802, 51.544414126264442],
              [0.06936703009492, 51.544128703045814],
              [0.068592878506076, 51.542132484232596],
              [0.069095059444933, 51.541376332326649],
              [0.068503241603816, 51.540919148208502],
              [0.069415356683766, 51.54029874959275],
              [0.069125790716993, 51.538811065306405],
              [0.070450424086333, 51.53851161536528],
              [0.071028987459007, 51.537152492415267],
            ],
          ],
          [
            [
              [0.148152759685322, 51.59676712742737],
              [0.148059595501196, 51.596353367606191],
              [0.149814830335198, 51.59707571822274],
              [0.151130787585308, 51.595437403744654],
              [0.150572209079581, 51.591284892940003],
              [0.15119848013302, 51.589124143080504],
              [0.150928253019761, 51.583755017509567],
              [0.148383183721536, 51.580752193839324],
              [0.147448285678205, 51.580791789485787],
              [0.146983553526446, 51.575678908358327],
              [0.148110980829351, 51.575623201143564],
              [0.147896130828547, 51.573117249063877],
              [0.148960850037276, 51.573232644792682],
              [0.149290272034471, 51.570273379588151],
              [0.149752256635097, 51.569696571377811],
              [0.146642230549089, 51.569415385660278],
              [0.146760425496123, 51.568769339519868],
              [0.148749101059931, 51.568620525187548],
              [0.150359302399351, 51.567821241196278],
              [0.151436540558821, 51.567990341054106],
              [0.154275567311339, 51.566142384141656],
              [0.161869542106036, 51.561606111226681],
              [0.166340496810903, 51.563270043911167],
              [0.168321004851744, 51.563532018244345],
              [0.17338798581357, 51.565098336205736],
              [0.176272756973122, 51.564713923635743],
              [0.178632594325635, 51.564939910361396],
              [0.183480519262556, 51.564633956222671],
              [0.185153048566346, 51.565511093519035],
              [0.185121207402277, 51.564786863352651],
              [0.183983425379602, 51.563867397957736],
              [0.182618724541129, 51.561311848749114],
              [0.184766351825869, 51.55985280188581],
              [0.18440153411778, 51.557960305169537],
              [0.185019130565745, 51.55681930129083],
              [0.184742050756103, 51.556426078300149],
              [0.185870276952739, 51.556337615071406],
              [0.18689100265931, 51.555782617076702],
              [0.18757376011203, 51.555129595620706],
              [0.187531815799184, 51.554526057533714],
              [0.188038549192308, 51.554353836341114],
              [0.187883979796211, 51.554078839800958],
              [0.190179643485943, 51.552678978406277],
              [0.189581250536317, 51.551452730552548],
              [0.187461160774958, 51.550197311053694],
              [0.186012165286049, 51.547563333058925],
              [0.182564615351919, 51.545838848574014],
              [0.180364574596956, 51.544108168548597],
              [0.179199830204626, 51.540287161186747],
              [0.178117153831574, 51.539569855577604],
              [0.177256673422249, 51.539488708066472],
              [0.175007140495793, 51.538493565166569],
              [0.173781456552521, 51.537405667884357],
              [0.170762207933664, 51.532625221489695],
              [0.167974860849159, 51.530739712576782],
              [0.165983902311641, 51.528799865218467],
              [0.164443688979418, 51.527058503090579],
              [0.162939381363453, 51.52388209651329],
              [0.162238025283493, 51.52131047834407],
              [0.15949128192647, 51.515477051234527],
              [0.158692832711478, 51.512185969917695],
              [0.158262884191755, 51.511962766555712],
              [0.151367721100757, 51.512811590373438],
              [0.145143328451318, 51.514598283988398],
              [0.145796906645989, 51.514581830651728],
              [0.145508176580165, 51.515154562583859],
              [0.144325948088593, 51.515454064445244],
              [0.143882978928337, 51.514829965440832],
              [0.143178307534614, 51.51532396307244],
              [0.139492462574816, 51.517438035434743],
              [0.136317587870863, 51.518465340171019],
              [0.133133053914622, 51.519042192427378],
              [0.132329195412674, 51.51877443569041],
              [0.126317979416231, 51.519445627430258],
              [0.122442341186, 51.518989749862996],
              [0.119161390118313, 51.518146201412591],
              [0.11434213440666, 51.516016454984104],
              [0.114448249194485, 51.515693498328133],
              [0.109047173031308, 51.514249385345032],
              [0.106680569897673, 51.513936705773006],
              [0.100481898246857, 51.514568574807264],
              [0.099345737229323, 51.514810141345571],
              [0.095568372210977, 51.516982910542907],
              [0.095255962596525, 51.518875179166564],
              [0.095669504638948, 51.5205674268907],
              [0.092804641593806, 51.522628469066234],
              [0.093090062692025, 51.525111677066747],
              [0.092030979774099, 51.526663840306611],
              [0.091432875245859, 51.526227566213286],
              [0.090482238536901, 51.526088938782991],
              [0.088832762164662, 51.526855733552125],
              [0.085839660005164, 51.526047493568825],
              [0.080921541477118, 51.527803909201396],
              [0.078090337120917, 51.529710208987147],
              [0.076339535218408, 51.529921070157009],
              [0.07545606416841, 51.531988850553368],
              [0.073942821546665, 51.533272801792094],
              [0.074373115159721, 51.533825440544071],
              [0.073459059303827, 51.534208505653517],
              [0.071346220328965, 51.537247609554278],
              [0.070427358218328, 51.539115434516418],
              [0.069497555228235, 51.538868355380032],
              [0.069906035151879, 51.540910590191558],
              [0.068941915851792, 51.542069674522722],
              [0.069714477337629, 51.544062322109276],
              [0.071787607146097, 51.543568895902808],
              [0.071938695822582, 51.54381532579557],
              [0.075541779463226, 51.544015117283848],
              [0.076794787468913, 51.544610741092242],
              [0.077034231440022, 51.544389776980786],
              [0.077473910788813, 51.544575334845781],
              [0.077909336659556, 51.544034353860305],
              [0.080110274709066, 51.544795699914204],
              [0.081399108402493, 51.545131648981908],
              [0.081652286495928, 51.545591181558528],
              [0.085989057733236, 51.547385532712035],
              [0.088473141843546, 51.547740611944086],
              [0.091490957729694, 51.548284863754262],
              [0.091685964749975, 51.549108719086064],
              [0.092596553071258, 51.549652727235156],
              [0.092238125506365, 51.548631249946936],
              [0.093502230637455, 51.545847924546351],
              [0.099739138261355, 51.548427084515197],
              [0.104225107561773, 51.549807127167782],
              [0.106182252496524, 51.550958149792542],
              [0.107429142913719, 51.552001398609519],
              [0.108269809121482, 51.552057336410293],
              [0.10840189043964, 51.552698844158449],
              [0.111105195991799, 51.552482063174935],
              [0.112353830306682, 51.552999152402684],
              [0.112327139184332, 51.553481645080218],
              [0.114727941876975, 51.555325973142359],
              [0.112724683228467, 51.556519435078528],
              [0.113328649701682, 51.55731790511269],
              [0.117152122517858, 51.556892853809472],
              [0.117133272585634, 51.557387795987886],
              [0.118937622499503, 51.557348026034383],
              [0.120487712604618, 51.558541236275993],
              [0.11747973911214, 51.559163009317132],
              [0.119751027044602, 51.562492480130423],
              [0.118873318081655, 51.562730456322441],
              [0.119033145029336, 51.563064798126923],
              [0.124989502767089, 51.564341051550535],
              [0.127621623053363, 51.566140462525063],
              [0.129316578110982, 51.566531473917898],
              [0.1296770920529, 51.568101362436437],
              [0.130457717922576, 51.57032279155532],
              [0.130026164179943, 51.57075328679425],
              [0.129902702601341, 51.571538797650483],
              [0.131558523030486, 51.571828869982241],
              [0.130886803616091, 51.573357250885238],
              [0.130074234505997, 51.573077942120854],
              [0.129418436324206, 51.573960344875061],
              [0.12917233783549, 51.576321812216385],
              [0.1316111545376, 51.577086879705938],
              [0.130799630729632, 51.579361498750188],
              [0.133532554999978, 51.579961114951743],
              [0.132534968170243, 51.581312874905947],
              [0.12775463849051, 51.58162717219848],
              [0.128057412294435, 51.582855486089151],
              [0.127630810454499, 51.583889293967815],
              [0.127220083603091, 51.583934510022843],
              [0.126374574524308, 51.586724993745314],
              [0.1310037973541, 51.58751960815971],
              [0.129555924362548, 51.59005216885118],
              [0.137560244905989, 51.593876035262227],
              [0.141898947269127, 51.596577482040999],
              [0.146563279682496, 51.599436483013427],
              [0.148179718673996, 51.598959965848444],
              [0.147657544517277, 51.596798673188047],
              [0.148152759685322, 51.59676712742737],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000003",
        LAD13CDO: "00AC",
        LAD13NM: "Barnet",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.199029938314097, 51.668203161376525],
            [-0.196639013960932, 51.668143092547197],
            [-0.196184172713469, 51.668526370133776],
            [-0.194877116637175, 51.668302163977707],
            [-0.196689871010208, 51.665478576588804],
            [-0.192555927543839, 51.664746003689338],
            [-0.191091827594294, 51.663893477538572],
            [-0.187815400981271, 51.667875984661244],
            [-0.185829309693543, 51.668506251026756],
            [-0.182110443559578, 51.668600714230706],
            [-0.185891083694433, 51.662833099570832],
            [-0.175553950446671, 51.660567871946192],
            [-0.165041715584015, 51.658224355187564],
            [-0.163504294906036, 51.658166150889009],
            [-0.160515318559911, 51.656750742566409],
            [-0.154787657877713, 51.655530549703244],
            [-0.154722782882244, 51.655027760265817],
            [-0.15378195658296, 51.654664071042383],
            [-0.153490292144939, 51.653303446637466],
            [-0.154230786078533, 51.65297517971468],
            [-0.154050991247479, 51.652561405409074],
            [-0.153501978809634, 51.652695751035566],
            [-0.152564578322234, 51.651467046092158],
            [-0.152270564327932, 51.649845603869181],
            [-0.151019481032727, 51.650075896449586],
            [-0.149919641031998, 51.648792455795828],
            [-0.152273975516642, 51.647984250307701],
            [-0.152383382731451, 51.647639768078591],
            [-0.151068338491476, 51.647205422056281],
            [-0.152168178443864, 51.645822632952083],
            [-0.151330207764412, 51.645280694712959],
            [-0.149174486680699, 51.645261121958782],
            [-0.149048033393841, 51.644178252359772],
            [-0.147920827555277, 51.642081453560849],
            [-0.145102578174885, 51.642646644315484],
            [-0.142319301536081, 51.639313247800892],
            [-0.13843793825733, 51.636514505120807],
            [-0.134534190303682, 51.634695440122094],
            [-0.134436718896719, 51.633971805937428],
            [-0.134003985877411, 51.634040914368519],
            [-0.133295269934678, 51.63326484889992],
            [-0.1313568598273, 51.632577570900175],
            [-0.129147276768588, 51.632266316191007],
            [-0.129803551555446, 51.630960291408748],
            [-0.130205651533001, 51.631020650246441],
            [-0.131147218407707, 51.629662509519882],
            [-0.130466835497026, 51.629377409916316],
            [-0.133536774220952, 51.626360762387392],
            [-0.13420620086432, 51.623989327457359],
            [-0.135070035623235, 51.622335869659963],
            [-0.135571868128686, 51.622393298732312],
            [-0.13546204149233, 51.621689250948208],
            [-0.140639590538747, 51.61773200300027],
            [-0.141291431209031, 51.617546292353254],
            [-0.142057370223235, 51.616158306775745],
            [-0.144536107594742, 51.615484418220369],
            [-0.138785614410406, 51.610192195820304],
            [-0.1434506524551, 51.608846176695572],
            [-0.143103506600652, 51.6063462012401],
            [-0.142090599884761, 51.604591047196195],
            [-0.1421409036762, 51.603287948408706],
            [-0.144199385657771, 51.601110185784385],
            [-0.144495064796318, 51.600126597708204],
            [-0.147000702709219, 51.599195004701244],
            [-0.15138599066662, 51.59745039271867],
            [-0.153030520778325, 51.599230695213691],
            [-0.153463600875163, 51.600870524056894],
            [-0.153312285502066, 51.602678307429734],
            [-0.154643781047763, 51.604136228508729],
            [-0.156141367790357, 51.605096767546605],
            [-0.155987250998171, 51.605443250099498],
            [-0.156367676220716, 51.605356604633336],
            [-0.161786563503232, 51.597476159813134],
            [-0.159675463877847, 51.59226527005336],
            [-0.158864004452629, 51.592374839685739],
            [-0.158689306344716, 51.592053768912628],
            [-0.159480845130241, 51.591579696883926],
            [-0.160273159858916, 51.587977183290633],
            [-0.158640577407262, 51.587974960250712],
            [-0.15870503195671, 51.58663071095841],
            [-0.157430033741605, 51.586568436053973],
            [-0.157182315697613, 51.586197657225874],
            [-0.157922075223614, 51.586216464716081],
            [-0.1579661538608, 51.585733366198873],
            [-0.1596429706729, 51.584460273286041],
            [-0.161350967906813, 51.585272979177901],
            [-0.16574670591534, 51.583221361093251],
            [-0.166215675085277, 51.582041695657331],
            [-0.166896098828117, 51.581955208122238],
            [-0.167219823495958, 51.581299324219074],
            [-0.166838420584901, 51.578654102359081],
            [-0.168088487178118, 51.576926402329413],
            [-0.169345647486203, 51.577426213828005],
            [-0.169753480053378, 51.576399350136484],
            [-0.1694840471559, 51.573981590781514],
            [-0.171181543111701, 51.573612392592992],
            [-0.171285290056077, 51.572429711799224],
            [-0.173461368606816, 51.571099457902946],
            [-0.173730961508166, 51.569917557750003],
            [-0.174406903537118, 51.569253646415497],
            [-0.178078357792813, 51.570431159071873],
            [-0.178284595510799, 51.570036897559014],
            [-0.180974606398256, 51.569668574886265],
            [-0.180833813033346, 51.569436187294578],
            [-0.183567514128142, 51.568006482544966],
            [-0.183811407946478, 51.5674985911444],
            [-0.18616758887062, 51.566920855921261],
            [-0.189643377828476, 51.565253390573282],
            [-0.190371225981506, 51.562765635346629],
            [-0.19114033983406, 51.561635461859559],
            [-0.1923200038639, 51.561538538948163],
            [-0.194852004564886, 51.560481348072543],
            [-0.196919620713664, 51.560621047226157],
            [-0.196817790318361, 51.559183399596932],
            [-0.197623270476517, 51.558879243497543],
            [-0.197984673709569, 51.559115898530706],
            [-0.198479165491724, 51.558759300485107],
            [-0.198533270598432, 51.558156742600431],
            [-0.199660261843726, 51.556979852292763],
            [-0.199410043371803, 51.556192776449116],
            [-0.205815267344318, 51.55530533172621],
            [-0.205966149078882, 51.556419995442226],
            [-0.208845146654708, 51.555731101896221],
            [-0.210031157439332, 51.55675364858272],
            [-0.213501362550834, 51.555186112122335],
            [-0.218650256071725, 51.559417965567121],
            [-0.2206627302808, 51.561673208181368],
            [-0.222389883071192, 51.563295527480477],
            [-0.226720623083191, 51.56679342957186],
            [-0.228819097623936, 51.567968047765753],
            [-0.232235584755792, 51.570952861912303],
            [-0.233442530669313, 51.571979975795962],
            [-0.234586319134685, 51.571770582058761],
            [-0.235645024993498, 51.572267593490963],
            [-0.23540110033361, 51.572566966964665],
            [-0.235921980665297, 51.572307726441487],
            [-0.237664629281435, 51.572471492380686],
            [-0.237721589891249, 51.572118050547708],
            [-0.238175417106331, 51.572099686415179],
            [-0.238015035581118, 51.571769058912629],
            [-0.238866517192598, 51.572252138685066],
            [-0.239995642861699, 51.572085635355727],
            [-0.239969522948227, 51.572388284566358],
            [-0.241108475731707, 51.572116707866371],
            [-0.241374804606561, 51.572656641158389],
            [-0.242015584686237, 51.572804723723031],
            [-0.243645996259659, 51.572324668291472],
            [-0.244289016353002, 51.57256449349628],
            [-0.245241945959271, 51.571914215043442],
            [-0.246042471420845, 51.572186060334353],
            [-0.24671704831828, 51.571568477812967],
            [-0.246008960120933, 51.570957210246839],
            [-0.246559002746476, 51.569862973245655],
            [-0.246057793111482, 51.569838397638563],
            [-0.246554899794157, 51.569032022499634],
            [-0.247382626803308, 51.568970650768733],
            [-0.247083529142907, 51.568681127059932],
            [-0.247309859173543, 51.568461498421428],
            [-0.248552529330033, 51.568528610718801],
            [-0.249428362510507, 51.568904968763398],
            [-0.252663492019137, 51.571688643408194],
            [-0.25310129671253, 51.572577305979586],
            [-0.251355407452078, 51.573135803141163],
            [-0.252623144921135, 51.574923471440542],
            [-0.253597935907802, 51.577315544089849],
            [-0.254613030941617, 51.57948699634273],
            [-0.254334933097693, 51.58158435729662],
            [-0.251667912557268, 51.583457315920143],
            [-0.248284356722674, 51.584363642033487],
            [-0.252536530243034, 51.588544596165079],
            [-0.258039635496796, 51.592576705285097],
            [-0.259770205979661, 51.594174224092015],
            [-0.267155911513881, 51.6003703486532],
            [-0.274932627182354, 51.606970992941832],
            [-0.279367943663357, 51.610743580903986],
            [-0.282199745334011, 51.613355926824802],
            [-0.287565635562117, 51.6171516987757],
            [-0.303953962195683, 51.631464151845492],
            [-0.305462313046658, 51.63336798943039],
            [-0.305597677157911, 51.634117191135864],
            [-0.304482780394745, 51.636347337196732],
            [-0.303569689566782, 51.636458240164195],
            [-0.302746593192066, 51.636132518974748],
            [-0.302436414616259, 51.636351040396661],
            [-0.29987767300298, 51.635758303697038],
            [-0.29723276934667, 51.635731666757387],
            [-0.29615110457172, 51.635443512837789],
            [-0.294406538211774, 51.635646584912429],
            [-0.291891362340547, 51.636475068613365],
            [-0.287810611378942, 51.636509151489015],
            [-0.285523941741269, 51.636945175807064],
            [-0.283898038367505, 51.637791866038626],
            [-0.281414720238532, 51.638342733990783],
            [-0.278636569387813, 51.63832002286096],
            [-0.276498771328069, 51.638865061508433],
            [-0.273737222534417, 51.638670727545438],
            [-0.274200187947379, 51.639304288252411],
            [-0.273145811759153, 51.641190632828256],
            [-0.272890291736135, 51.642095984114178],
            [-0.270060920048074, 51.642084007646417],
            [-0.268270403644632, 51.642383131601179],
            [-0.268369906638191, 51.643830542074816],
            [-0.266791169655346, 51.64410758863346],
            [-0.265235110495673, 51.644017168722222],
            [-0.264382920242884, 51.643624206081256],
            [-0.263296213990688, 51.644830176573578],
            [-0.261628651700807, 51.643989010430651],
            [-0.260736767033487, 51.642670136982467],
            [-0.258406979886174, 51.641990864020123],
            [-0.257356709107825, 51.641828708670772],
            [-0.256196081140704, 51.643488522571452],
            [-0.255482697844717, 51.643284593808858],
            [-0.254069555168071, 51.644178096786561],
            [-0.251581594667769, 51.647949272694476],
            [-0.24988100803052, 51.654611432318347],
            [-0.250816087553238, 51.654806107308701],
            [-0.251275188845432, 51.655535916531193],
            [-0.250607575244082, 51.656053814233125],
            [-0.249372918270114, 51.656054298719866],
            [-0.247890488740447, 51.655244474434447],
            [-0.243649515307314, 51.656685515392397],
            [-0.241390114393385, 51.657201124696996],
            [-0.241018380582897, 51.656832272644493],
            [-0.239224049156006, 51.657396169665091],
            [-0.2379959392345, 51.657785994932496],
            [-0.238300488773912, 51.658246468355593],
            [-0.236702742615759, 51.658771912011346],
            [-0.234732016720667, 51.658997683247328],
            [-0.23425217370442, 51.65824951214671],
            [-0.228944026720489, 51.659972515460353],
            [-0.228513559616748, 51.659962425887969],
            [-0.228082807563121, 51.658588212964595],
            [-0.226487518443282, 51.657158649276539],
            [-0.22606892793393, 51.657622619395347],
            [-0.219825732446319, 51.660657435001831],
            [-0.219272297449528, 51.660796516638761],
            [-0.218919533997528, 51.660058301624773],
            [-0.213418068629271, 51.662374757903727],
            [-0.212159957462204, 51.661350281333128],
            [-0.207888601397595, 51.662857916686697],
            [-0.211158575635625, 51.666951570842222],
            [-0.209863519276418, 51.667559494279175],
            [-0.207984221181829, 51.666591147025102],
            [-0.205659210973254, 51.668597779840098],
            [-0.202254731348437, 51.667802058748769],
            [-0.202633160191303, 51.669311350207458],
            [-0.203377623597464, 51.670123048584671],
            [-0.199896403871687, 51.670166858479107],
            [-0.199029938314097, 51.668203161376525],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000004",
        LAD13CDO: "00AD",
        LAD13NM: "Bexley",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [0.199272347063116, 51.45840132257954],
              [0.198881205638673, 51.458170346088288],
              [0.198696759976231, 51.458376148549846],
              [0.199272347063116, 51.45840132257954],
            ],
          ],
          [
            [
              [0.143901001493783, 51.507724377262065],
              [0.147426027368414, 51.506623900454436],
              [0.148025605406949, 51.506809863079837],
              [0.148052576645086, 51.506402891228937],
              [0.149558600044459, 51.505854607076728],
              [0.149937405158833, 51.506371048002556],
              [0.15126761273588, 51.50628190384559],
              [0.15259142134942, 51.506393403197869],
              [0.159801512394714, 51.505643017397233],
              [0.164402396673964, 51.504541989736701],
              [0.166699754277363, 51.503335882086326],
              [0.171133007784109, 51.49856497966794],
              [0.172026173429998, 51.496501650786243],
              [0.172589252899723, 51.488770794693252],
              [0.173506416409224, 51.487338308911447],
              [0.174154275615357, 51.48681639785223],
              [0.174465519872296, 51.48690954811358],
              [0.17520662941416, 51.486077443934278],
              [0.174851431060812, 51.485938347740614],
              [0.175170378113326, 51.485255262887733],
              [0.1759608353959, 51.485248688897137],
              [0.178018724174188, 51.483517107120583],
              [0.177834131259753, 51.483028622233952],
              [0.178260084325945, 51.48332287201255],
              [0.183534646059226, 51.48106374993575],
              [0.18657523699705, 51.480436027981924],
              [0.190329744428297, 51.479740932932955],
              [0.190414670388564, 51.479048686595505],
              [0.190764342477779, 51.479733711919714],
              [0.192646462253744, 51.479758779437326],
              [0.193011568927162, 51.479413814858816],
              [0.194179148628475, 51.479284952261438],
              [0.195001503041203, 51.479614888473144],
              [0.196161297148349, 51.479443884920073],
              [0.198570703209552, 51.479652327512362],
              [0.198622916931911, 51.479300622296023],
              [0.199556310826078, 51.479962083719251],
              [0.200272593866947, 51.47949359831474],
              [0.20082526199079, 51.480173885370967],
              [0.201472941364326, 51.480194999138547],
              [0.201996107678801, 51.479066447366392],
              [0.200817035365585, 51.478656924378839],
              [0.202139444663314, 51.479022387339896],
              [0.20178879148877, 51.480234032611975],
              [0.202231250154908, 51.480569253944729],
              [0.205089221934211, 51.480878850576133],
              [0.205537638534422, 51.480441450320818],
              [0.206299145409205, 51.480731984124525],
              [0.206343518524506, 51.481293210532478],
              [0.209224977149074, 51.481818995705247],
              [0.209623996670191, 51.481641508518102],
              [0.21036103257828, 51.482497237001809],
              [0.211656959413571, 51.482728198062262],
              [0.217615816059958, 51.480448323316985],
              [0.21669365155953, 51.480202268196521],
              [0.215934947108402, 51.479465695857975],
              [0.215962062394823, 51.477083836873689],
              [0.211824129049525, 51.475795043728901],
              [0.211165662287092, 51.474894675875341],
              [0.211306361015694, 51.473188747906377],
              [0.208853698921271, 51.471721464972482],
              [0.208107818831696, 51.470797546840323],
              [0.211016034666687, 51.468286741976385],
              [0.209982344257338, 51.465727039655157],
              [0.210248136615855, 51.462557387838572],
              [0.207514095493881, 51.461600791217656],
              [0.204418189531576, 51.459973763702337],
              [0.2025781729532, 51.458324861839685],
              [0.198599783350591, 51.458484984282279],
              [0.197283200682074, 51.457538427376882],
              [0.198366605314972, 51.458215970407068],
              [0.198872335131802, 51.457925902788567],
              [0.198362532942677, 51.457292465328237],
              [0.199110865302991, 51.457916931441474],
              [0.199629519399927, 51.458374836013178],
              [0.203080761253307, 51.457970085242913],
              [0.202580930516259, 51.456229438749212],
              [0.203777852078037, 51.456035174738005],
              [0.203873634864088, 51.455662861711751],
              [0.203235697318931, 51.454940125370392],
              [0.203352909470171, 51.454355174776069],
              [0.202693450767847, 51.453932307459709],
              [0.202000749372851, 51.454104499200191],
              [0.201359500417851, 51.453850351009827],
              [0.200206672039557, 51.45259588812668],
              [0.199694196497908, 51.452714322265436],
              [0.199109466171535, 51.452138050715455],
              [0.197988371005708, 51.451841626024581],
              [0.19595899519189, 51.451783438986361],
              [0.195863394084512, 51.452370674838512],
              [0.195482191182894, 51.452015397572985],
              [0.195027446815022, 51.45213722638988],
              [0.194921333358528, 51.451815465307043],
              [0.194154076299719, 51.451663460029785],
              [0.193819382337968, 51.45194760878735],
              [0.192992916264309, 51.451701378262257],
              [0.191720486588528, 51.450678392668607],
              [0.190985140730037, 51.448787422373492],
              [0.188823959214035, 51.44912996644473],
              [0.188407911693713, 51.448616141109504],
              [0.187617342802113, 51.44869744743653],
              [0.187438086723817, 51.446874314294718],
              [0.184250886971899, 51.444654927567449],
              [0.183629115086563, 51.444804106289126],
              [0.181058000994432, 51.444182001772027],
              [0.180861099584807, 51.443522881760828],
              [0.179549507501522, 51.443117414466897],
              [0.178698861380589, 51.443476762673484],
              [0.175991104276773, 51.442005451223345],
              [0.173779518417868, 51.442979063859305],
              [0.172770333552477, 51.443031050920979],
              [0.172827974620008, 51.443244914764456],
              [0.170876387639799, 51.441207295777083],
              [0.168688076896451, 51.437479746189418],
              [0.166663759695217, 51.435051309584807],
              [0.16681898483828, 51.431946756314616],
              [0.166069435379829, 51.431430025785573],
              [0.166667330972772, 51.431295769381919],
              [0.166352551380091, 51.430541680417633],
              [0.166158475372924, 51.430270981434496],
              [0.165563664139482, 51.430348522470595],
              [0.164302056322368, 51.428584920356506],
              [0.163805457709149, 51.428609378679674],
              [0.163896963496594, 51.428932336237281],
              [0.160669218039191, 51.42967445905429],
              [0.159811131842397, 51.429202856411692],
              [0.159059444499924, 51.430224821391889],
              [0.158605978363848, 51.430127057551537],
              [0.155848857350841, 51.430876564133733],
              [0.155274538312038, 51.428975219505283],
              [0.153737435432989, 51.427523233274805],
              [0.153292739898136, 51.425316432867795],
              [0.153491298211769, 51.423604116548958],
              [0.152977694200229, 51.421980426822145],
              [0.151132824040425, 51.420430641665938],
              [0.154610567345156, 51.418470126464904],
              [0.154068143729819, 51.417762457534657],
              [0.151029425753371, 51.41761593461392],
              [0.151128205706357, 51.415063707756602],
              [0.149133881620537, 51.412435677056891],
              [0.149725869995418, 51.411658614327678],
              [0.153744036058547, 51.411265575581389],
              [0.15290633792244, 51.4087089701314],
              [0.15156900018033, 51.408677777829865],
              [0.149285683510007, 51.409141454529717],
              [0.148963755291263, 51.408623966395062],
              [0.148876641671875, 51.408483474589666],
              [0.139139666520879, 51.411614037882288],
              [0.134319807775743, 51.413817077358608],
              [0.129778079925931, 51.414804406273113],
              [0.125402432802783, 51.414787628518987],
              [0.118855075381733, 51.413275777855311],
              [0.113098909424401, 51.41291388573562],
              [0.108870660271314, 51.413783235550369],
              [0.104475611508213, 51.415220183686628],
              [0.095660122212071, 51.419789198402064],
              [0.09065120151221, 51.422924565376455],
              [0.08725014879325, 51.425624607356696],
              [0.082906152282118, 51.430058052418637],
              [0.075343134505704, 51.431990799177981],
              [0.074652648862433, 51.432151406614572],
              [0.076080097458324, 51.433299699810419],
              [0.075654701073476, 51.433627380865858],
              [0.076388260203566, 51.434003778575537],
              [0.075889535507875, 51.434459559049735],
              [0.076503124295412, 51.434855167419968],
              [0.074960335604015, 51.435861815164834],
              [0.076544581721961, 51.436879628045361],
              [0.077819941367092, 51.436447849254392],
              [0.078418535984803, 51.437114399095286],
              [0.079036148469574, 51.437977552517914],
              [0.078790005728356, 51.438133898032518],
              [0.08085158223747, 51.440405785825014],
              [0.081785427071668, 51.439962041372851],
              [0.084415551940393, 51.442010636564035],
              [0.082825826439457, 51.442841061530615],
              [0.082810200367667, 51.443288284943449],
              [0.083216079583927, 51.443542764341615],
              [0.084712009318193, 51.442611483822503],
              [0.086320548260864, 51.44323664488244],
              [0.087380134352111, 51.443246556677501],
              [0.086294251947708, 51.445311769158046],
              [0.087281158558908, 51.445746540170056],
              [0.088039273283605, 51.447506432057303],
              [0.086796259590326, 51.450451251009937],
              [0.086021506521551, 51.455619750249461],
              [0.085683940666769, 51.45664554706994],
              [0.081419789641846, 51.457774402675511],
              [0.078826699569034, 51.459124379664161],
              [0.078637153853385, 51.459293209205285],
              [0.08287824192376, 51.459834838672187],
              [0.082266455974642, 51.461606506957857],
              [0.082629923152526, 51.463613548336525],
              [0.082175724891833, 51.464736729036744],
              [0.082295538180795, 51.46664108319213],
              [0.083218251460345, 51.466479006080135],
              [0.08479669876853, 51.468731530834248],
              [0.085763144468056, 51.469432868240958],
              [0.089047866201841, 51.470418451403901],
              [0.091264175700761, 51.472062397819307],
              [0.093171070528436, 51.472951932294563],
              [0.094471725003846, 51.473274028252575],
              [0.096548994790285, 51.473228805329242],
              [0.098223957681474, 51.475462340290214],
              [0.100199332967626, 51.475234523396523],
              [0.10200182299538, 51.474472899508164],
              [0.102470416606134, 51.474924031861384],
              [0.103377063602434, 51.474598414938455],
              [0.104891410143974, 51.475524479001557],
              [0.105191067702634, 51.475245715738765],
              [0.105636156683848, 51.475467040537367],
              [0.106162520350378, 51.475014237793523],
              [0.108225549718904, 51.476126445321363],
              [0.111772425591692, 51.473194785508369],
              [0.112560112554904, 51.473475560393155],
              [0.114024751423045, 51.475191977170198],
              [0.115489650369874, 51.476164660956094],
              [0.118454273914695, 51.47891960273234],
              [0.120730976314132, 51.478511533209478],
              [0.124160770534975, 51.476823529986603],
              [0.122097970839714, 51.482426575512534],
              [0.121082301220085, 51.496293058463486],
              [0.120981909825738, 51.500771513592262],
              [0.120535196424074, 51.511043116959669],
              [0.120210839735287, 51.51144466607095],
              [0.125145008355613, 51.51304420963676],
              [0.128592581132518, 51.513293735961881],
              [0.13077120917264, 51.512849494347002],
              [0.143901001493783, 51.507724377262065],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000005",
        LAD13CDO: "00AE",
        LAD13NM: "Brent",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.267155911513881, 51.6003703486532],
            [-0.259770205979661, 51.594174224092015],
            [-0.258039635496796, 51.592576705285097],
            [-0.252536530243034, 51.588544596165079],
            [-0.248284356722674, 51.584363642033487],
            [-0.251667912557268, 51.583457315920143],
            [-0.254334933097693, 51.58158435729662],
            [-0.254613030941617, 51.57948699634273],
            [-0.253597935907802, 51.577315544089849],
            [-0.252623144921135, 51.574923471440542],
            [-0.251355407452078, 51.573135803141163],
            [-0.25310129671253, 51.572577305979586],
            [-0.252663492019137, 51.571688643408194],
            [-0.249428362510507, 51.568904968763398],
            [-0.248552529330033, 51.568528610718801],
            [-0.247309859173543, 51.568461498421428],
            [-0.247083529142907, 51.568681127059932],
            [-0.247382626803308, 51.568970650768733],
            [-0.246554899794157, 51.569032022499634],
            [-0.246057793111482, 51.569838397638563],
            [-0.246559002746476, 51.569862973245655],
            [-0.246008960120933, 51.570957210246839],
            [-0.24671704831828, 51.571568477812967],
            [-0.246042471420845, 51.572186060334353],
            [-0.245241945959271, 51.571914215043442],
            [-0.244289016353002, 51.57256449349628],
            [-0.243645996259659, 51.572324668291472],
            [-0.242015584686237, 51.572804723723031],
            [-0.241374804606561, 51.572656641158389],
            [-0.241108475731707, 51.572116707866371],
            [-0.239969522948227, 51.572388284566358],
            [-0.239995642861699, 51.572085635355727],
            [-0.238866517192598, 51.572252138685066],
            [-0.238015035581118, 51.571769058912629],
            [-0.238175417106331, 51.572099686415179],
            [-0.237721589891249, 51.572118050547708],
            [-0.237664629281435, 51.572471492380686],
            [-0.235921980665297, 51.572307726441487],
            [-0.23540110033361, 51.572566966964665],
            [-0.235645024993498, 51.572267593490963],
            [-0.234586319134685, 51.571770582058761],
            [-0.233442530669313, 51.571979975795962],
            [-0.232235584755792, 51.570952861912303],
            [-0.228819097623936, 51.567968047765753],
            [-0.226720623083191, 51.56679342957186],
            [-0.222389883071192, 51.563295527480477],
            [-0.2206627302808, 51.561673208181368],
            [-0.218650256071725, 51.559417965567121],
            [-0.213501362550834, 51.555186112122335],
            [-0.20373655240752, 51.546957371217133],
            [-0.203589428734054, 51.546811240405859],
            [-0.201931837468742, 51.545285917503151],
            [-0.191483521552663, 51.536289379589128],
            [-0.1930353002745, 51.533988751977148],
            [-0.192382380267819, 51.533254803336114],
            [-0.192558561810077, 51.532628947769638],
            [-0.196568745728934, 51.527654011384215],
            [-0.197021491747425, 51.527944232259749],
            [-0.197798833422846, 51.527900424353106],
            [-0.198298116388534, 51.530444855394094],
            [-0.201108059631871, 51.531907880900164],
            [-0.201689361333834, 51.532559750868366],
            [-0.202992228068829, 51.532902543436052],
            [-0.20307320406065, 51.532896589900758],
            [-0.207648200660883, 51.532820002724058],
            [-0.211051455190376, 51.531959221792704],
            [-0.215207327498344, 51.531389459273306],
            [-0.215104503863569, 51.530258449601654],
            [-0.215883692876897, 51.529725363296713],
            [-0.216028912103472, 51.52792819255199],
            [-0.217979054678885, 51.528640356700237],
            [-0.223633771449066, 51.529924807029523],
            [-0.225446606209828, 51.530168960660731],
            [-0.227685265613103, 51.529917745173947],
            [-0.227588053489856, 51.53015637314477],
            [-0.228503133284014, 51.530352740801568],
            [-0.231394108305529, 51.531088775187769],
            [-0.234423879760615, 51.532637039710444],
            [-0.237350120914966, 51.53201830603426],
            [-0.243230249785268, 51.531779187466917],
            [-0.246311399977157, 51.532752400438],
            [-0.247158601036984, 51.533443085991031],
            [-0.25077371675389, 51.534183162233916],
            [-0.251140878075618, 51.533895485490326],
            [-0.252341111409308, 51.534142676813232],
            [-0.255669132009226, 51.533985383763756],
            [-0.256049597106446, 51.533952373120705],
            [-0.256451780471354, 51.534935819381751],
            [-0.257809111634768, 51.535337259710545],
            [-0.260433657986825, 51.534534522625556],
            [-0.263377961139448, 51.532677163078311],
            [-0.26485434347013, 51.532184647062287],
            [-0.264876560647277, 51.531638241229722],
            [-0.266610493588597, 51.530512854505623],
            [-0.269021605713225, 51.529738246223019],
            [-0.26967621574123, 51.530002386698534],
            [-0.270417629895669, 51.529764232284265],
            [-0.270593016396035, 51.530043782025977],
            [-0.271461060912511, 51.529945070538574],
            [-0.277196392085164, 51.529232717439953],
            [-0.280478540185958, 51.528405918632288],
            [-0.282980615611926, 51.528844524103981],
            [-0.28575191558325, 51.529179102430554],
            [-0.289388283777546, 51.531667303444259],
            [-0.284038968906202, 51.533518033379529],
            [-0.283539024689224, 51.532818310960018],
            [-0.281508920264996, 51.533240904302595],
            [-0.280951465097939, 51.532576297424669],
            [-0.2790100325301, 51.533208768851722],
            [-0.278473010871174, 51.534564126145369],
            [-0.277797826977885, 51.535071275066414],
            [-0.2824856072251, 51.538664985912781],
            [-0.286752039006545, 51.536755352930918],
            [-0.293294433225463, 51.534971319122],
            [-0.295173274327604, 51.534891628555755],
            [-0.295825489829068, 51.535303060622255],
            [-0.29658913749489, 51.535240414087525],
            [-0.299557066726257, 51.533252108572924],
            [-0.302731567541226, 51.531982504811403],
            [-0.307501126729888, 51.533065742997749],
            [-0.306256884015841, 51.534230272936583],
            [-0.305967276733508, 51.539429031765827],
            [-0.304725501285908, 51.539861608738114],
            [-0.308575924259692, 51.543238932149137],
            [-0.307999528980379, 51.544431989819053],
            [-0.308890513846433, 51.54521727218593],
            [-0.307124203648407, 51.545323986648469],
            [-0.308601157774942, 51.546447740804815],
            [-0.311494959135004, 51.547558602805772],
            [-0.31259837970047, 51.549154418643802],
            [-0.317779210533699, 51.551616257107391],
            [-0.327022967910274, 51.555010807089594],
            [-0.335584461591767, 51.556583009164001],
            [-0.334908480952714, 51.557831432228333],
            [-0.333978147790924, 51.557709420967846],
            [-0.333922607902454, 51.558874025786359],
            [-0.331329560360932, 51.561572617218168],
            [-0.32968801722394, 51.562392734017969],
            [-0.328249654519491, 51.562509832546887],
            [-0.328669141637084, 51.565942746815836],
            [-0.329646140855182, 51.566212935991665],
            [-0.329745424949139, 51.567086595031547],
            [-0.325073663168265, 51.56825733914841],
            [-0.325175153362495, 51.568440430652501],
            [-0.322153724330603, 51.569654383993964],
            [-0.324741461511433, 51.57317314272084],
            [-0.325434128828264, 51.576039856399042],
            [-0.326586026182602, 51.578233301901072],
            [-0.326671587126436, 51.578772256130449],
            [-0.320706868900921, 51.580254403419382],
            [-0.317089503291599, 51.582103543145109],
            [-0.313075009746977, 51.584261592639685],
            [-0.30684119613252, 51.586543975586359],
            [-0.304846077648926, 51.587053811059775],
            [-0.302061817933132, 51.587278835538939],
            [-0.294315875028936, 51.586585622879191],
            [-0.282470776699423, 51.585047858094633],
            [-0.282271526511133, 51.585738244490592],
            [-0.283364121798757, 51.588881733131068],
            [-0.284653970265129, 51.591008372396303],
            [-0.286604711630876, 51.59226430585484],
            [-0.289092509396662, 51.592908471011164],
            [-0.290421934009064, 51.593568088742046],
            [-0.286282953768237, 51.594944689259307],
            [-0.285796597130665, 51.594747851180514],
            [-0.285612023079472, 51.595201960807408],
            [-0.28406933235504, 51.595604750690704],
            [-0.279351995058963, 51.59622899390299],
            [-0.276515651786621, 51.596514698370527],
            [-0.27697635643723, 51.596892841654842],
            [-0.273001730737778, 51.598371203079758],
            [-0.271208635622124, 51.598289954585297],
            [-0.267155911513881, 51.6003703486532],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000006",
        LAD13CDO: "00AF",
        LAD13NM: "Bromley",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.045182865934482, 51.436602835103926],
            [0.04514703681638, 51.436219466311286],
            [0.047784875450584, 51.433753435119748],
            [0.051049044855556, 51.431529980708525],
            [0.054826985000658, 51.427522235902373],
            [0.058199720451853, 51.424882999721021],
            [0.058596555547171, 51.424590955804973],
            [0.060899906063883, 51.42507925348567],
            [0.061708231666141, 51.423734990379316],
            [0.063202270589673, 51.424203304876386],
            [0.064437183178735, 51.425049369701654],
            [0.064194156623064, 51.425370199637896],
            [0.066579480727601, 51.426015216905846],
            [0.066404142006933, 51.426337555693856],
            [0.066935041380777, 51.426722081396427],
            [0.071942791906871, 51.429653462164772],
            [0.073471383919731, 51.431259534469476],
            [0.073035112300187, 51.431378763812155],
            [0.07332778167106, 51.431773769599495],
            [0.074304751062589, 51.43149029314003],
            [0.074835946746498, 51.430830705209551],
            [0.075343134505704, 51.431990799177981],
            [0.082906152282118, 51.430058052418637],
            [0.08725014879325, 51.425624607356696],
            [0.09065120151221, 51.422924565376455],
            [0.095660122212071, 51.419789198402064],
            [0.104475611508213, 51.415220183686628],
            [0.108870660271314, 51.413783235550369],
            [0.113098909424401, 51.41291388573562],
            [0.118855075381733, 51.413275777855311],
            [0.125402432802783, 51.414787628518987],
            [0.129778079925931, 51.414804406273113],
            [0.134319807775743, 51.413817077358608],
            [0.139139666520879, 51.411614037882288],
            [0.148876641671875, 51.408483474589666],
            [0.148666001457494, 51.408131214494098],
            [0.151027499142613, 51.40666878974919],
            [0.152796265945422, 51.406273886924552],
            [0.155223030920241, 51.403972027267649],
            [0.15494091551275, 51.40365976273295],
            [0.155487656520912, 51.403575963081863],
            [0.156408725853617, 51.402494241301802],
            [0.157334670329728, 51.400783809671118],
            [0.157702284750458, 51.398147479285953],
            [0.15895418720739, 51.395821301733108],
            [0.162357953748243, 51.392490459036011],
            [0.161287113318842, 51.392369927161489],
            [0.158247952465449, 51.392239797745006],
            [0.147714720379626, 51.392801180144886],
            [0.147507723408661, 51.392156577655726],
            [0.149809249351476, 51.39087243075064],
            [0.148627923705321, 51.388668327732418],
            [0.149390397471064, 51.387854860893071],
            [0.148875523207952, 51.387572032577062],
            [0.150432574627488, 51.386018243458388],
            [0.149965765345046, 51.385840655471199],
            [0.149773682052291, 51.384870234740859],
            [0.149760583661605, 51.383670804037244],
            [0.150439939273109, 51.383446999067758],
            [0.150346812464746, 51.381092532056996],
            [0.149582131061656, 51.381089479279616],
            [0.149587003327425, 51.380089363701188],
            [0.15110697945487, 51.379895990767615],
            [0.150948243734437, 51.379085034958102],
            [0.152326518258417, 51.37869010549754],
            [0.152163333981809, 51.37833607963853],
            [0.153209781014079, 51.37803626656774],
            [0.152510494791998, 51.375229796771343],
            [0.151709745248199, 51.375436060076964],
            [0.149573842439353, 51.373582237031755],
            [0.150807253746483, 51.37292019077298],
            [0.15117949664148, 51.372186716620135],
            [0.150100173416957, 51.370157015724978],
            [0.152030993614946, 51.369693489016335],
            [0.151592923790006, 51.368378657456923],
            [0.150998352399738, 51.368322129402898],
            [0.149411360008319, 51.369162435009699],
            [0.148849791460367, 51.368123248673022],
            [0.14725344759924, 51.367014002089221],
            [0.148320969139466, 51.366371208877048],
            [0.145021694053068, 51.362850638819381],
            [0.145364561778273, 51.362502623058411],
            [0.144474098272935, 51.361061154593642],
            [0.144535785228854, 51.360690410366402],
            [0.145362479037724, 51.360337132080389],
            [0.14482113720903, 51.358435121066769],
            [0.145868471647119, 51.358224385169301],
            [0.145626939226504, 51.3572053990992],
            [0.145087028599637, 51.355763807500566],
            [0.144019375736582, 51.354861567026731],
            [0.143604380437612, 51.353017490231331],
            [0.143113225266923, 51.35304896124255],
            [0.142849433679087, 51.352658992140405],
            [0.142593049376822, 51.350734669068068],
            [0.140957112293769, 51.350145862554157],
            [0.141260255301355, 51.349778799159033],
            [0.138686589447705, 51.347524498532579],
            [0.139465950636091, 51.34729982592129],
            [0.139413829042557, 51.346982423299089],
            [0.138241048202658, 51.347084778909242],
            [0.137169312734812, 51.345871391274862],
            [0.136172879572529, 51.345936334445852],
            [0.137082175896194, 51.34480100719297],
            [0.136930961557784, 51.344174251349287],
            [0.131750143620975, 51.345506115528195],
            [0.131642288733902, 51.345193321411159],
            [0.12518574473221, 51.345867331500806],
            [0.122211878286937, 51.344656834562549],
            [0.118429038730673, 51.344147068652816],
            [0.117559296502436, 51.343128597186052],
            [0.117204771966067, 51.343162885373189],
            [0.116653808596291, 51.341302290162474],
            [0.117255655841466, 51.339780578023166],
            [0.118443701015532, 51.339981221542899],
            [0.118986891087981, 51.338683588604418],
            [0.118271921755193, 51.338404248840682],
            [0.119983658600939, 51.334739178007943],
            [0.121656932768726, 51.333636001433923],
            [0.120218191399938, 51.332136835136652],
            [0.117864350568799, 51.330620930893652],
            [0.117876189794242, 51.329662955068336],
            [0.117335250602446, 51.329481184883257],
            [0.116614278467648, 51.330003226391227],
            [0.115715775887933, 51.329506858762606],
            [0.114183278693762, 51.327397784855414],
            [0.110125943353177, 51.328035732767354],
            [0.109730877412081, 51.326683999644629],
            [0.10721006106662, 51.327523476462623],
            [0.104873867826206, 51.327412611212708],
            [0.103212844217316, 51.325995474209343],
            [0.100764019792728, 51.325585283134927],
            [0.100307996919827, 51.324275081934417],
            [0.099503635151417, 51.323508010359603],
            [0.096151698395388, 51.32129282004616],
            [0.093797129076916, 51.320235096550221],
            [0.087756667586107, 51.31831232348479],
            [0.086793117272677, 51.317192778815489],
            [0.08500075644682, 51.316023237844895],
            [0.085613319665181, 51.314784777523414],
            [0.085042087476937, 51.312598848664109],
            [0.083543471444392, 51.310528346862199],
            [0.084720177906431, 51.308346370433711],
            [0.084058200018264, 51.308049693670533],
            [0.08484975938291, 51.306045437761341],
            [0.082788071327502, 51.305634277835679],
            [0.085404444884815, 51.302520171446901],
            [0.086310854396904, 51.300173019920855],
            [0.091156843090318, 51.29682837091574],
            [0.089234027836609, 51.296087497623503],
            [0.085665366541323, 51.293085389857623],
            [0.081553070363804, 51.291836899125713],
            [0.073340901906363, 51.291606420089458],
            [0.067535944870675, 51.290384407987325],
            [0.058482828173708, 51.289355064999292],
            [0.056022763995799, 51.29239657718],
            [0.054362504155093, 51.292090283204935],
            [0.050762349912617, 51.293987983140269],
            [0.048468503392217, 51.293723278739456],
            [0.04570414525076, 51.294005434505863],
            [0.044739652081475, 51.293087908669399],
            [0.042368994859629, 51.292674243173188],
            [0.043285506240134, 51.296582805521972],
            [0.044270838932082, 51.298581839467417],
            [0.043065360866001, 51.299055230942514],
            [0.042775225505347, 51.300985698095154],
            [0.04149127982004, 51.302990152786613],
            [0.040889741733115, 51.302721856818557],
            [0.036308882385116, 51.305863735650703],
            [0.032881406068898, 51.307521351071614],
            [0.0302537951084, 51.305558808573942],
            [0.027949900371255, 51.304685947600703],
            [0.024811342673592, 51.302155682686788],
            [0.02357164580737, 51.299704942709909],
            [0.020925547653484, 51.296779363721541],
            [0.020846747884853, 51.295572964911713],
            [0.019073952123889, 51.29168530433806],
            [0.014982112678468, 51.291787278516878],
            [0.012130939143441, 51.299599060340576],
            [0.008363167927088, 51.310361785913734],
            [0.010320365184879, 51.316140347199173],
            [0.006552354695073, 51.315656407403303],
            [0.005517297637795, 51.31844396740086],
            [0.004841868344107, 51.322076091780609],
            [0.002266081487411, 51.329138246307849],
            [0.002181718765601, 51.32977189137538],
            [0.002886920153771, 51.33034077542375],
            [0.00328180718332, 51.332155088261224],
            [0.002269979826151, 51.332489842432565],
            [0.000840765904431, 51.333591621254385],
            [-0.00292021902549, 51.345521956594737],
            [-0.004632813456889, 51.349566472664144],
            [-0.005339315851509, 51.352576735199285],
            [-0.008685456256457, 51.355826142929082],
            [-0.011882756641648, 51.357282443981227],
            [-0.013131102045507, 51.35721189830555],
            [-0.015650308638004, 51.358876016655074],
            [-0.018288334217944, 51.361252525304536],
            [-0.018934449375953, 51.36279044134713],
            [-0.019811733793197, 51.363632617275812],
            [-0.022270439695983, 51.365880999546015],
            [-0.023587191471836, 51.36529440738812],
            [-0.024495775768804, 51.366780962763947],
            [-0.023119744957797, 51.367008649640539],
            [-0.023816371490419, 51.371316283629675],
            [-0.02359355195042, 51.372219901920758],
            [-0.026152960107368, 51.37645101496323],
            [-0.026821835413914, 51.379375058691167],
            [-0.028041124816702, 51.379358717572636],
            [-0.028272068174079, 51.378198034107591],
            [-0.029828325735842, 51.377327631561215],
            [-0.0369519252886, 51.377010161715319],
            [-0.036352186749457, 51.379003709730092],
            [-0.036929112368785, 51.378954025636887],
            [-0.037898636717933, 51.383231958649226],
            [-0.036525088326928, 51.388461643508606],
            [-0.04177463669125, 51.389290505435198],
            [-0.04371172493248, 51.389873223345532],
            [-0.046235803143769, 51.391996270269949],
            [-0.046831533567704, 51.391846130964041],
            [-0.047764019974655, 51.393037028032417],
            [-0.051718287181335, 51.393581261607402],
            [-0.051725068013047, 51.3939968405153],
            [-0.053509973458979, 51.394921286526596],
            [-0.050978250606057, 51.397126494948765],
            [-0.050472246747444, 51.398038936821749],
            [-0.050859662429935, 51.397718045152104],
            [-0.052130532324503, 51.398292233902687],
            [-0.050654655009298, 51.399564447253915],
            [-0.052990743225192, 51.401462091556049],
            [-0.056854208040915, 51.400503746201508],
            [-0.059413007545363, 51.399252992514626],
            [-0.060585818445775, 51.399124029495162],
            [-0.060178282472441, 51.399548036548573],
            [-0.06218612594058, 51.401533585270577],
            [-0.063779234486572, 51.401564416612608],
            [-0.06463960855573, 51.401719816198856],
            [-0.064499558205217, 51.40204034263953],
            [-0.066407577315363, 51.402581731497719],
            [-0.068597393407742, 51.403840858331918],
            [-0.069770458072091, 51.405756755627301],
            [-0.072319642275301, 51.407912909346578],
            [-0.073292066302002, 51.409237343625151],
            [-0.073490144456531, 51.411058929357893],
            [-0.072757053450049, 51.412693443241054],
            [-0.075176253020367, 51.413539850384062],
            [-0.076527775922045, 51.414437032478766],
            [-0.080475514860821, 51.415566497757084],
            [-0.080993694631571, 51.417101046706534],
            [-0.078548643992427, 51.419847824736017],
            [-0.078306723189438, 51.420609137481627],
            [-0.074965986969211, 51.425699038675567],
            [-0.073935648723001, 51.426153328520165],
            [-0.070876997763939, 51.426232518476994],
            [-0.06888189241702, 51.42561693474839],
            [-0.068690381502924, 51.425996868526688],
            [-0.067915136671355, 51.425926537696839],
            [-0.066062574748082, 51.425070452096463],
            [-0.065841666796843, 51.425328494648063],
            [-0.064604329846581, 51.425062567231571],
            [-0.064429146214868, 51.425327657130829],
            [-0.062603499266066, 51.425197673103888],
            [-0.058125143730244, 51.42317303532095],
            [-0.056415034687148, 51.422966631739641],
            [-0.055154772315996, 51.422938530844149],
            [-0.054780384878001, 51.423727274780788],
            [-0.051809088195716, 51.423581698287208],
            [-0.051773807398039, 51.422517271386091],
            [-0.047231239283551, 51.422859832320185],
            [-0.046015450276107, 51.422800899454103],
            [-0.046288563939972, 51.422366606895338],
            [-0.041607744505232, 51.423654480516554],
            [-0.041068931916304, 51.424246191025382],
            [-0.039920856211149, 51.424251276092733],
            [-0.038476286472146, 51.42395822432195],
            [-0.037485484723019, 51.424992889570419],
            [-0.034227084810177, 51.425101050531133],
            [-0.034239057377374, 51.424755030386564],
            [-0.033373635349472, 51.424642490501164],
            [-0.031820068382722, 51.424710834731066],
            [-0.031997476588678, 51.425536650967295],
            [-0.030106948198925, 51.42564607626143],
            [-0.02599980858657, 51.423617448002297],
            [-0.024427450198297, 51.423154804327659],
            [-0.022606122804704, 51.421562943311606],
            [-0.022263283002336, 51.421774783225516],
            [-0.020947822795443, 51.420927939333481],
            [-0.021674748933451, 51.420448307975917],
            [-0.020777617764411, 51.419940356157575],
            [-0.02138438351474, 51.418412837387464],
            [-0.022555326144167, 51.417541417178327],
            [-0.021736286587265, 51.417291084032279],
            [-0.022185540905973, 51.416745611103167],
            [-0.021040467460402, 51.416019447644388],
            [-0.017846743008069, 51.415743350346034],
            [-0.017987213578037, 51.415055979620561],
            [-0.014152643348072, 51.415100774514954],
            [-0.014558881798717, 51.413923307405859],
            [-0.014262647188211, 51.413657497126884],
            [-0.01054547769936, 51.413551284994512],
            [-0.007692066477833, 51.414483023423834],
            [-0.006627347980809, 51.414660060970867],
            [-0.006565100550283, 51.415991734862438],
            [-0.005534172859589, 51.415788042791959],
            [-0.004993813068833, 51.414589996738165],
            [-0.003283970904318, 51.415050084207536],
            [-0.003404043702143, 51.415660942742797],
            [-0.000754049654404, 51.415912525930018],
            [-0.000077252872264, 51.417645581789778],
            [0.001728747678104, 51.418558092456102],
            [0.003493381870314, 51.417404735853253],
            [0.005671974766633, 51.418514952428239],
            [0.005228085658549, 51.419315714266311],
            [0.006297787495831, 51.421015032216047],
            [0.00673587371653, 51.420935589049307],
            [0.006848109385548, 51.421494818284799],
            [0.007349340238142, 51.421379219577872],
            [0.008076715670459, 51.423043017622881],
            [0.009966669160178, 51.422483640220086],
            [0.011813367485276, 51.424051774224267],
            [0.013344810656539, 51.424695439291291],
            [0.013512995084405, 51.425085535669659],
            [0.014395466349803, 51.425232241436618],
            [0.014107520195143, 51.426175140417165],
            [0.016048065276034, 51.426208321595965],
            [0.017435699293917, 51.426849911112143],
            [0.018775175755778, 51.427050766778116],
            [0.020905460960159, 51.426818908264423],
            [0.025410480818806, 51.428989343842019],
            [0.034565951549168, 51.423394594450663],
            [0.036185884693948, 51.422365556772363],
            [0.039028625616491, 51.42467135597964],
            [0.038463803349644, 51.427591264523073],
            [0.037857209228264, 51.427601813735535],
            [0.038332189584116, 51.428027907680871],
            [0.037521772298542, 51.431379240248326],
            [0.037698507637781, 51.433346496578821],
            [0.033700520649509, 51.43169920765866],
            [0.033223669603176, 51.432235359923162],
            [0.032451710320051, 51.431892633570882],
            [0.03158768426563, 51.432555097123746],
            [0.031147580986985, 51.43210409116876],
            [0.031381588364816, 51.432579352910459],
            [0.028584319755872, 51.434030682044188],
            [0.02906568594928, 51.43466623512186],
            [0.028673967773433, 51.436411326973015],
            [0.027240267877236, 51.43655933509352],
            [0.025375932978386, 51.435689587693055],
            [0.024790793351299, 51.43823746805942],
            [0.028813712210221, 51.438785708220877],
            [0.028576811179677, 51.44045347821141],
            [0.029358966392502, 51.441705226683411],
            [0.030585718770561, 51.444317074332581],
            [0.033470476499515, 51.443591703727563],
            [0.035543553585559, 51.442446007324392],
            [0.039748478254516, 51.440996106479197],
            [0.042184666396002, 51.439480660514597],
            [0.043311579187625, 51.437745188593304],
            [0.045182865934482, 51.436602835103926],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000007",
        LAD13CDO: "00AG",
        LAD13NM: "Camden",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.167373031226188, 51.572970269819827],
            [-0.1643008249503, 51.572501406455764],
            [-0.160455031665567, 51.572787389732667],
            [-0.158862786701175, 51.572193199552167],
            [-0.150350754255611, 51.571573748271312],
            [-0.143855430145825, 51.569912799110497],
            [-0.142415880417378, 51.569120267654064],
            [-0.140681644226308, 51.564552521987665],
            [-0.141031589763899, 51.560429638390119],
            [-0.139451092871752, 51.558256292398049],
            [-0.138394433693291, 51.556859191284026],
            [-0.137178948938767, 51.555396611253165],
            [-0.135255967592321, 51.554587317864957],
            [-0.134379544509464, 51.553620184759552],
            [-0.131027114570507, 51.551511152635058],
            [-0.130162846970763, 51.549617956152304],
            [-0.129137591530651, 51.548838150931715],
            [-0.127204105025978, 51.547662560997317],
            [-0.126361949582864, 51.545061079694996],
            [-0.125086251139046, 51.541115480243334],
            [-0.12307551991704, 51.538497986702595],
            [-0.122400724190549, 51.536884726018947],
            [-0.122556112454186, 51.530725552727588],
            [-0.119895987298358, 51.530937435416568],
            [-0.116016687479488, 51.529842855163075],
            [-0.115432420816161, 51.527722027598386],
            [-0.112949388385477, 51.526408767953853],
            [-0.114253421202477, 51.525516998530762],
            [-0.112563047621472, 51.523793821304878],
            [-0.108991266860745, 51.523019588492673],
            [-0.108274440412082, 51.522089892452094],
            [-0.107020548548463, 51.522189263792413],
            [-0.105349922689427, 51.518540995513298],
            [-0.10782664550493, 51.517765353480101],
            [-0.113821071242378, 51.518257575299678],
            [-0.111606814356538, 51.515338040748837],
            [-0.113859293263093, 51.514820344661267],
            [-0.114718365482976, 51.516040055140522],
            [-0.119493175687399, 51.514505245747998],
            [-0.121843397072707, 51.51486663460345],
            [-0.122098228895455, 51.514627916770785],
            [-0.122894191852907, 51.51514423954292],
            [-0.127352810081589, 51.512665231819824],
            [-0.128997401329675, 51.513434260533799],
            [-0.129361462909319, 51.513483231644372],
            [-0.129852547466754, 51.514477542169779],
            [-0.130372899792216, 51.516447105467378],
            [-0.130831814321433, 51.517101880622356],
            [-0.132474774895153, 51.516717992951513],
            [-0.132568929828069, 51.517164621332206],
            [-0.133622566605303, 51.517728136326269],
            [-0.134070420519287, 51.517561706398673],
            [-0.135258286209963, 51.518933070245993],
            [-0.135851795854771, 51.518694311279432],
            [-0.136321946545697, 51.519146911379543],
            [-0.137033156781376, 51.518884837532106],
            [-0.139179886788072, 51.520817230742601],
            [-0.142901143447612, 51.523877900552336],
            [-0.143521002198687, 51.523948861564527],
            [-0.143496470220476, 51.524622909888826],
            [-0.144179002617573, 51.524641803598762],
            [-0.14415481503136, 51.523995760346004],
            [-0.145198551734178, 51.523994284197464],
            [-0.145815598291375, 51.525270181528931],
            [-0.147195842151387, 51.525041100870993],
            [-0.152732435938544, 51.537519143947023],
            [-0.159438160954746, 51.53636110464538],
            [-0.160486834634699, 51.537281294456577],
            [-0.164961074593286, 51.535779487421507],
            [-0.169542886437788, 51.538597359908344],
            [-0.17089024375321, 51.537792869226145],
            [-0.171981256608953, 51.538382692993075],
            [-0.173510971216226, 51.537632270740851],
            [-0.174141477648115, 51.538199616490886],
            [-0.173921648274941, 51.539476717379159],
            [-0.178353347252415, 51.539792907093052],
            [-0.180180989350078, 51.539561390817838],
            [-0.18387352261961, 51.538378556730891],
            [-0.187751220546842, 51.535033991943102],
            [-0.18877358460174, 51.534523724393992],
            [-0.191483521552663, 51.536289379589128],
            [-0.201931837468742, 51.545285917503151],
            [-0.203589428734054, 51.546811240405859],
            [-0.20373655240752, 51.546957371217133],
            [-0.213501362550834, 51.555186112122335],
            [-0.210031157439332, 51.55675364858272],
            [-0.208845146654708, 51.555731101896221],
            [-0.205966149078882, 51.556419995442226],
            [-0.205815267344318, 51.55530533172621],
            [-0.199410043371803, 51.556192776449116],
            [-0.199660261843726, 51.556979852292763],
            [-0.198533270598432, 51.558156742600431],
            [-0.198479165491724, 51.558759300485107],
            [-0.197984673709569, 51.559115898530706],
            [-0.197623270476517, 51.558879243497543],
            [-0.196817790318361, 51.559183399596932],
            [-0.196919620713664, 51.560621047226157],
            [-0.194852004564886, 51.560481348072543],
            [-0.1923200038639, 51.561538538948163],
            [-0.19114033983406, 51.561635461859559],
            [-0.190371225981506, 51.562765635346629],
            [-0.189643377828476, 51.565253390573282],
            [-0.18616758887062, 51.566920855921261],
            [-0.183811407946478, 51.5674985911444],
            [-0.183567514128142, 51.568006482544966],
            [-0.180833813033346, 51.569436187294578],
            [-0.180974606398256, 51.569668574886265],
            [-0.178284595510799, 51.570036897559014],
            [-0.178078357792813, 51.570431159071873],
            [-0.174406903537118, 51.569253646415497],
            [-0.173730961508166, 51.569917557750003],
            [-0.173461368606816, 51.571099457902946],
            [-0.171285290056077, 51.572429711799224],
            [-0.167373031226188, 51.572970269819827],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000008",
        LAD13CDO: "00AH",
        LAD13NM: "Croydon",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.109373710729991, 51.423157559171081],
            [-0.105987341539958, 51.422576811743276],
            [-0.096906133161734, 51.422667109533435],
            [-0.094346733695295, 51.422818864663064],
            [-0.092734433338077, 51.422539943278622],
            [-0.088190383828824, 51.419927269261336],
            [-0.086425687184976, 51.419305849976176],
            [-0.082346001337163, 51.419771533877956],
            [-0.078548643992427, 51.419847824736017],
            [-0.080993694631571, 51.417101046706534],
            [-0.080475514860821, 51.415566497757084],
            [-0.076527775922045, 51.414437032478766],
            [-0.075176253020367, 51.413539850384062],
            [-0.072757053450049, 51.412693443241054],
            [-0.073490144456531, 51.411058929357893],
            [-0.073292066302002, 51.409237343625151],
            [-0.072319642275301, 51.407912909346578],
            [-0.069770458072091, 51.405756755627301],
            [-0.068597393407742, 51.403840858331918],
            [-0.066407577315363, 51.402581731497719],
            [-0.064499558205217, 51.40204034263953],
            [-0.06463960855573, 51.401719816198856],
            [-0.063779234486572, 51.401564416612608],
            [-0.06218612594058, 51.401533585270577],
            [-0.060178282472441, 51.399548036548573],
            [-0.060585818445775, 51.399124029495162],
            [-0.059413007545363, 51.399252992514626],
            [-0.056854208040915, 51.400503746201508],
            [-0.052990743225192, 51.401462091556049],
            [-0.050654655009298, 51.399564447253915],
            [-0.052130532324503, 51.398292233902687],
            [-0.050859662429935, 51.397718045152104],
            [-0.050472246747444, 51.398038936821749],
            [-0.050978250606057, 51.397126494948765],
            [-0.053509973458979, 51.394921286526596],
            [-0.051725068013047, 51.3939968405153],
            [-0.051718287181335, 51.393581261607402],
            [-0.047764019974655, 51.393037028032417],
            [-0.046831533567704, 51.391846130964041],
            [-0.046235803143769, 51.391996270269949],
            [-0.04371172493248, 51.389873223345532],
            [-0.04177463669125, 51.389290505435198],
            [-0.036525088326928, 51.388461643508606],
            [-0.037898636717933, 51.383231958649226],
            [-0.036929112368785, 51.378954025636887],
            [-0.036352186749457, 51.379003709730092],
            [-0.0369519252886, 51.377010161715319],
            [-0.029828325735842, 51.377327631561215],
            [-0.028272068174079, 51.378198034107591],
            [-0.028041124816702, 51.379358717572636],
            [-0.026821835413914, 51.379375058691167],
            [-0.026152960107368, 51.37645101496323],
            [-0.02359355195042, 51.372219901920758],
            [-0.023816371490419, 51.371316283629675],
            [-0.023119744957797, 51.367008649640539],
            [-0.024495775768804, 51.366780962763947],
            [-0.023587191471836, 51.36529440738812],
            [-0.022270439695983, 51.365880999546015],
            [-0.019811733793197, 51.363632617275812],
            [-0.018934449375953, 51.36279044134713],
            [-0.018288334217944, 51.361252525304536],
            [-0.015650308638004, 51.358876016655074],
            [-0.013131102045507, 51.35721189830555],
            [-0.011882756641648, 51.357282443981227],
            [-0.008685456256457, 51.355826142929082],
            [-0.005339315851509, 51.352576735199285],
            [-0.004632813456889, 51.349566472664144],
            [-0.00292021902549, 51.345521956594737],
            [0.000840765904431, 51.333591621254385],
            [0.002269979826151, 51.332489842432565],
            [0.00328180718332, 51.332155088261224],
            [0.002886920153771, 51.33034077542375],
            [0.002181718765601, 51.32977189137538],
            [0.002266081487411, 51.329138246307849],
            [-0.000531494655283, 51.328777766571612],
            [-0.001705233449911, 51.330306813175568],
            [-0.007237539829199, 51.333386730513624],
            [-0.007146670722932, 51.333694540319634],
            [-0.00916890046872, 51.333905195562444],
            [-0.010902174947093, 51.33362798936367],
            [-0.010691491578879, 51.333089332939721],
            [-0.011914536569116, 51.332891574262355],
            [-0.013631635636529, 51.331525912034863],
            [-0.014356302238495, 51.329804369344579],
            [-0.015967674437049, 51.332014257437059],
            [-0.017057608446228, 51.332904129440934],
            [-0.017810030106008, 51.332933054345141],
            [-0.020584450968881, 51.337144588275905],
            [-0.022083832449432, 51.338099784731234],
            [-0.025984999902759, 51.338560391916523],
            [-0.030707395603291, 51.337515783597631],
            [-0.032796779659365, 51.337438486725844],
            [-0.034520745006775, 51.338083443141706],
            [-0.037992769255879, 51.338644361003396],
            [-0.039842139843115, 51.337227476720088],
            [-0.041993372133904, 51.33381110086129],
            [-0.04657961385431, 51.333483908360122],
            [-0.048499720726753, 51.332790195641714],
            [-0.050287606196995, 51.332640117097334],
            [-0.050098605472951, 51.33205333382061],
            [-0.051068375239253, 51.33159465335244],
            [-0.051107290175895, 51.330846195693582],
            [-0.048775914363454, 51.328719231852418],
            [-0.049096404716283, 51.328608563325048],
            [-0.047854872993378, 51.326510521444256],
            [-0.047895657692476, 51.325245904312936],
            [-0.049792223488202, 51.325267611757091],
            [-0.051293563429583, 51.323439174114597],
            [-0.05133410498527, 51.322448834330714],
            [-0.052508042038834, 51.322382931152951],
            [-0.05490939178455, 51.321258265770304],
            [-0.05627858990163, 51.321664995816882],
            [-0.058419470594696, 51.320383955912597],
            [-0.06068467594054, 51.320065374306637],
            [-0.062106014195122, 51.319201308793801],
            [-0.064021239009557, 51.318637660565138],
            [-0.070055154954906, 51.318893737132903],
            [-0.071043367701129, 51.321233772779571],
            [-0.075146836968067, 51.320198771259292],
            [-0.078950673588562, 51.319770225100925],
            [-0.078857243671393, 51.318090627484573],
            [-0.080220250871138, 51.317823411298789],
            [-0.081569988365938, 51.316704339567643],
            [-0.082235013001669, 51.316965233765551],
            [-0.083582830057881, 51.316682442074558],
            [-0.083618246185963, 51.316383560013108],
            [-0.084829346456001, 51.315868299817431],
            [-0.081920131223132, 51.31261023630033],
            [-0.081958888714383, 51.310648630973304],
            [-0.086604717597315, 51.308860421499176],
            [-0.08843040264378, 51.307602464606774],
            [-0.088852330334888, 51.307219961620106],
            [-0.088759380198797, 51.305106023719411],
            [-0.089733095360635, 51.303976224180197],
            [-0.090743967565288, 51.303954941975718],
            [-0.091199104919727, 51.303393115097094],
            [-0.090903858760797, 51.303178768062565],
            [-0.091195518307555, 51.301473981682143],
            [-0.09441441239047, 51.300614538518495],
            [-0.096058228120496, 51.301519881798399],
            [-0.097404791643354, 51.301115509538675],
            [-0.097272239462477, 51.300296804859521],
            [-0.095803306823098, 51.299602956860269],
            [-0.094351796710637, 51.299355419241088],
            [-0.101167434738563, 51.296288078488558],
            [-0.103029698944671, 51.295860529875441],
            [-0.111079759097666, 51.292254155017112],
            [-0.11485021143677, 51.292435429987371],
            [-0.114621784092438, 51.291534266156525],
            [-0.115143805777065, 51.291465332983663],
            [-0.115756225887715, 51.29072968427046],
            [-0.117007758774738, 51.289375713581713],
            [-0.117802280478234, 51.287096213862775],
            [-0.124319668186361, 51.286760163106521],
            [-0.126083380983476, 51.288386433509025],
            [-0.126305008933486, 51.289530271848392],
            [-0.130557909043012, 51.293118948638984],
            [-0.131485731858589, 51.294712898842135],
            [-0.131136347156092, 51.295475309193741],
            [-0.134458224083793, 51.298379906398758],
            [-0.136354736221868, 51.298269820652919],
            [-0.13734033321557, 51.300782806346945],
            [-0.140881639431117, 51.299554017400837],
            [-0.142859750859058, 51.299981991789366],
            [-0.143585597906881, 51.301262385992544],
            [-0.144449509547269, 51.300543167069591],
            [-0.144878696557934, 51.300714535981278],
            [-0.145651684834007, 51.299830198794012],
            [-0.148478285287727, 51.300705864370812],
            [-0.149522755410533, 51.300702591806626],
            [-0.150020000468255, 51.300287788118361],
            [-0.153386888391804, 51.301679961985499],
            [-0.155344025301304, 51.301276460102486],
            [-0.156710231200859, 51.30359113466357],
            [-0.157761229650816, 51.304429614890076],
            [-0.155049575902992, 51.30579339095452],
            [-0.154535411878273, 51.306450754076522],
            [-0.156441981724983, 51.306454708060151],
            [-0.157189238586363, 51.30671107411839],
            [-0.154341438367858, 51.310319985531201],
            [-0.1562542433988, 51.310422960412204],
            [-0.158356626805023, 51.310987516812595],
            [-0.157195636855283, 51.313391903011009],
            [-0.159340030812686, 51.315957990860156],
            [-0.159606881146681, 51.317106963015952],
            [-0.160507331833134, 51.316874709400217],
            [-0.16120315059232, 51.317525022256511],
            [-0.161905510204714, 51.319627762863675],
            [-0.156568877412008, 51.321510593442625],
            [-0.152731301654483, 51.323008566969605],
            [-0.15208937157746, 51.32226822758021],
            [-0.150594861800533, 51.322172693390328],
            [-0.149037281432254, 51.322788369899811],
            [-0.147956420800863, 51.322684950866929],
            [-0.145585543496559, 51.323500845340853],
            [-0.144272653560501, 51.325909893098626],
            [-0.144454847553246, 51.326481122454403],
            [-0.149330560475826, 51.328765993500973],
            [-0.144067553088038, 51.330670107053336],
            [-0.145172096412843, 51.331542816332153],
            [-0.145617146181277, 51.332431153574817],
            [-0.147547471723146, 51.338781802202057],
            [-0.144526640269978, 51.339494771161867],
            [-0.144271545611749, 51.339909791486384],
            [-0.144324172279447, 51.340206486499874],
            [-0.143464123246972, 51.340562458801926],
            [-0.144595485753942, 51.342092065965403],
            [-0.144214061175453, 51.342292856188585],
            [-0.141556918792261, 51.343414386288465],
            [-0.136731090987715, 51.344756771394763],
            [-0.136350542003863, 51.344792982947389],
            [-0.136272872323406, 51.344406857644799],
            [-0.132513753629315, 51.344299321205717],
            [-0.132892555725229, 51.345436647890182],
            [-0.131126344784128, 51.345472328633761],
            [-0.131431336523934, 51.34602305441026],
            [-0.128993077752533, 51.346587545006933],
            [-0.128868963478094, 51.347272608437514],
            [-0.128371993483241, 51.34745171760779],
            [-0.12755658634416, 51.347445879608102],
            [-0.127347390409131, 51.347188040383095],
            [-0.126856544602187, 51.344857363706097],
            [-0.125924861244987, 51.345239040809901],
            [-0.118930853991793, 51.345510018282681],
            [-0.118663537695072, 51.345796191149084],
            [-0.11731276496842, 51.345680063040049],
            [-0.116919130640419, 51.345754670105507],
            [-0.116991279528106, 51.346656904973933],
            [-0.11736519672618, 51.346643131506355],
            [-0.119115137361499, 51.353143332245956],
            [-0.120177611336543, 51.353045280705956],
            [-0.121429300576776, 51.357730798216821],
            [-0.124196415837998, 51.357430719693248],
            [-0.124715188713416, 51.358866173483946],
            [-0.123456762454164, 51.359151774013853],
            [-0.12333848635111, 51.358917866565541],
            [-0.121860239850248, 51.359064137775142],
            [-0.122928437763603, 51.359918483433773],
            [-0.121839749158275, 51.359986464379311],
            [-0.122053353815158, 51.360979989458784],
            [-0.121380486726929, 51.361065422946176],
            [-0.121276435211526, 51.363116796134989],
            [-0.120928045477791, 51.363310847027002],
            [-0.121302223623195, 51.3647332041322],
            [-0.122370771992226, 51.364528215239794],
            [-0.124108028233862, 51.365395068892298],
            [-0.122094286863262, 51.365808844802977],
            [-0.123359964914616, 51.370551207746658],
            [-0.123339618647996, 51.371189365137241],
            [-0.124773505999716, 51.372947021268175],
            [-0.124744312589688, 51.373661475047555],
            [-0.127849415012976, 51.374749794355878],
            [-0.126673318359406, 51.374909050221369],
            [-0.126865449427031, 51.375482260224203],
            [-0.125833103741463, 51.375604238056653],
            [-0.12695928921061, 51.377337158260971],
            [-0.128078996695885, 51.380498904019134],
            [-0.130302300230481, 51.38222143574977],
            [-0.128657193139887, 51.382494628461572],
            [-0.129534922281754, 51.38528738791026],
            [-0.128761225440115, 51.385651827891017],
            [-0.130663587694904, 51.386263122597171],
            [-0.12949347132605, 51.387185083998155],
            [-0.13326056143262, 51.389549088835558],
            [-0.132634752523855, 51.390128137824021],
            [-0.134379023506288, 51.390886129306502],
            [-0.134319555917893, 51.390934642197436],
            [-0.128756556479026, 51.393481689215982],
            [-0.129689724176393, 51.393651262156304],
            [-0.124151448519686, 51.397617533187905],
            [-0.124636481196836, 51.398095614248234],
            [-0.125724594325816, 51.39830996351801],
            [-0.12541746468812, 51.398824827585585],
            [-0.127989887803862, 51.401698645193655],
            [-0.130506204792157, 51.403276563755966],
            [-0.131533099793822, 51.404926010344795],
            [-0.132481137203481, 51.408406894896444],
            [-0.131955699129911, 51.408456972600767],
            [-0.132089107482163, 51.409170418568294],
            [-0.131316533626589, 51.409215654752138],
            [-0.131285981145888, 51.409506529764954],
            [-0.129478580808406, 51.409574815528579],
            [-0.128480915564511, 51.411012101250101],
            [-0.128193641283099, 51.410933773331045],
            [-0.127751755490985, 51.41231698000761],
            [-0.1262598739242, 51.412930715998193],
            [-0.125786039584686, 51.412631776832328],
            [-0.124467543059399, 51.413365167544931],
            [-0.124074336978944, 51.41460975340997],
            [-0.122480986171562, 51.415029374525716],
            [-0.119846669165159, 51.418807224841629],
            [-0.115715109592252, 51.420578971428746],
            [-0.112685586677911, 51.423244210692374],
            [-0.109373710729991, 51.423157559171081],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000009",
        LAD13CDO: "00AJ",
        LAD13NM: "Ealing",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.327022967910274, 51.555010807089594],
            [-0.317779210533699, 51.551616257107391],
            [-0.31259837970047, 51.549154418643802],
            [-0.311494959135004, 51.547558602805772],
            [-0.308601157774942, 51.546447740804815],
            [-0.307124203648407, 51.545323986648469],
            [-0.308890513846433, 51.54521727218593],
            [-0.307999528980379, 51.544431989819053],
            [-0.308575924259692, 51.543238932149137],
            [-0.304725501285908, 51.539861608738114],
            [-0.305967276733508, 51.539429031765827],
            [-0.306256884015841, 51.534230272936583],
            [-0.307501126729888, 51.533065742997749],
            [-0.302731567541226, 51.531982504811403],
            [-0.299557066726257, 51.533252108572924],
            [-0.29658913749489, 51.535240414087525],
            [-0.295825489829068, 51.535303060622255],
            [-0.295173274327604, 51.534891628555755],
            [-0.293294433225463, 51.534971319122],
            [-0.286752039006545, 51.536755352930918],
            [-0.2824856072251, 51.538664985912781],
            [-0.277797826977885, 51.535071275066414],
            [-0.278473010871174, 51.534564126145369],
            [-0.2790100325301, 51.533208768851722],
            [-0.280951465097939, 51.532576297424669],
            [-0.281508920264996, 51.533240904302595],
            [-0.283539024689224, 51.532818310960018],
            [-0.284038968906202, 51.533518033379529],
            [-0.289388283777546, 51.531667303444259],
            [-0.28575191558325, 51.529179102430554],
            [-0.282980615611926, 51.528844524103981],
            [-0.280478540185958, 51.528405918632288],
            [-0.277196392085164, 51.529232717439953],
            [-0.271461060912511, 51.529945070538574],
            [-0.270593016396035, 51.530043782025977],
            [-0.270417629895669, 51.529764232284265],
            [-0.26967621574123, 51.530002386698534],
            [-0.269021605713225, 51.529738246223019],
            [-0.266610493588597, 51.530512854505623],
            [-0.264876560647277, 51.531638241229722],
            [-0.26485434347013, 51.532184647062287],
            [-0.263377961139448, 51.532677163078311],
            [-0.260433657986825, 51.534534522625556],
            [-0.257809111634768, 51.535337259710545],
            [-0.256451780471354, 51.534935819381751],
            [-0.256049597106446, 51.533952373120705],
            [-0.255669132009226, 51.533985383763756],
            [-0.252341111409308, 51.534142676813232],
            [-0.251140878075618, 51.533895485490326],
            [-0.25077371675389, 51.534183162233916],
            [-0.247158601036984, 51.533443085991031],
            [-0.246311399977157, 51.532752400438],
            [-0.246994552928248, 51.531783342614752],
            [-0.245269072638192, 51.531212608574556],
            [-0.24695572825327, 51.530729756745046],
            [-0.24964460274261, 51.529074847079585],
            [-0.250468517645944, 51.528341670817234],
            [-0.250066101839043, 51.52819628689825],
            [-0.250621128989681, 51.527782823268517],
            [-0.250295361969, 51.52717997251262],
            [-0.25263913405477, 51.524777976964465],
            [-0.251923482478103, 51.522235063609614],
            [-0.250305077096738, 51.520157076484601],
            [-0.250721507081745, 51.519483463796348],
            [-0.250604863148626, 51.518540222464466],
            [-0.248996317765225, 51.513879749611263],
            [-0.246650281120857, 51.511245903393764],
            [-0.245086811047362, 51.506741610322749],
            [-0.246513769659736, 51.506731479054224],
            [-0.245702705970943, 51.504625020626285],
            [-0.248011191844397, 51.504358281606201],
            [-0.248080848552132, 51.504608411561591],
            [-0.249280405475039, 51.504439281536889],
            [-0.249431093183269, 51.504680728526814],
            [-0.249928993618254, 51.504434573355098],
            [-0.251046472703044, 51.504640082722133],
            [-0.252515381108166, 51.504510905455078],
            [-0.252543716465036, 51.504711858021324],
            [-0.253764449751569, 51.504553788566206],
            [-0.253659206529602, 51.504291442180161],
            [-0.255090373331911, 51.504319936874751],
            [-0.253365857246629, 51.502881562650032],
            [-0.253083914262199, 51.501399912688349],
            [-0.252786630230702, 51.499905443702296],
            [-0.255026699712879, 51.496971301870332],
            [-0.255118328274577, 51.4963449954586],
            [-0.255512844773796, 51.496275328279616],
            [-0.255434489428101, 51.494931596855409],
            [-0.268923163715445, 51.49401102067106],
            [-0.27142572299734, 51.494803293869687],
            [-0.272481269067441, 51.496147921591039],
            [-0.274260712677975, 51.497285577696317],
            [-0.277896080966695, 51.499488192383744],
            [-0.280783594519346, 51.502850537890829],
            [-0.285072792264004, 51.500975477619832],
            [-0.28681058593727, 51.50066276184797],
            [-0.294050054059212, 51.501562311359081],
            [-0.300718341203409, 51.499348080169298],
            [-0.299945607033527, 51.49781267798808],
            [-0.297553480130457, 51.494889633201588],
            [-0.301110564813813, 51.494417859389394],
            [-0.301355667389131, 51.495226225553701],
            [-0.305850991535, 51.496180591757351],
            [-0.305440815357069, 51.494538058008999],
            [-0.308403381089442, 51.493584474099407],
            [-0.311924673728756, 51.491969828620221],
            [-0.313936227549495, 51.493593108443591],
            [-0.314414080944087, 51.49344800673245],
            [-0.315103799502455, 51.49396418662409],
            [-0.314475427278835, 51.49436251056342],
            [-0.315732804163877, 51.495346352125686],
            [-0.315291491320451, 51.495554930540159],
            [-0.315788611348789, 51.495903779417844],
            [-0.319211421848077, 51.494222756908094],
            [-0.323434179149631, 51.495899121384589],
            [-0.32487080686535, 51.495929541082219],
            [-0.326158548432247, 51.495029809932795],
            [-0.327418222261741, 51.496036937463785],
            [-0.329975908372573, 51.495075237156165],
            [-0.331260891374223, 51.495549438512221],
            [-0.33544621253242, 51.496212299249628],
            [-0.342113923552105, 51.4960405699902],
            [-0.349788254748214, 51.499029036304982],
            [-0.350700245980526, 51.499108407501637],
            [-0.358027763066037, 51.497785041910731],
            [-0.361918189316551, 51.496080550561238],
            [-0.362998185020571, 51.495932869250666],
            [-0.363626717530335, 51.49547853740377],
            [-0.363732405682804, 51.494699481001902],
            [-0.365382019156917, 51.493325981089178],
            [-0.365800858296245, 51.493658238167363],
            [-0.366974631507994, 51.492778955416441],
            [-0.368377627299094, 51.492296712612365],
            [-0.367921686417798, 51.491793094840105],
            [-0.371872526288749, 51.490470389834286],
            [-0.37194798821698, 51.49098040103042],
            [-0.372420322087804, 51.490984259261666],
            [-0.373806849248039, 51.491933295526451],
            [-0.375839995462134, 51.495401916336114],
            [-0.377885476545658, 51.495447309929965],
            [-0.377853081755501, 51.496562803415394],
            [-0.383527505959271, 51.49481841465564],
            [-0.385712065175963, 51.494637184880247],
            [-0.387554300447632, 51.494848666889659],
            [-0.4069052387918, 51.499693760920678],
            [-0.403889411591448, 51.502921439772614],
            [-0.402782156656375, 51.503641932399638],
            [-0.399869945206838, 51.504430425228115],
            [-0.393586312903934, 51.506969371757073],
            [-0.392861317336739, 51.507793920775924],
            [-0.390442911589318, 51.513203765000796],
            [-0.389022661255376, 51.514735429761018],
            [-0.384988795102393, 51.516363308923218],
            [-0.38344258844633, 51.517495715543191],
            [-0.381776446109735, 51.518634540537477],
            [-0.37618124024741, 51.524914736764408],
            [-0.376377283873975, 51.528886622408471],
            [-0.380990606602968, 51.529414377814405],
            [-0.38663760668965, 51.530394092029994],
            [-0.387115583307842, 51.529556294848682],
            [-0.389278931109793, 51.530799066826646],
            [-0.396826539457457, 51.532023776991437],
            [-0.411084496998463, 51.534287746955343],
            [-0.41183268922805, 51.534083863332697],
            [-0.412269317783468, 51.53450521214851],
            [-0.41348118463255, 51.534691558391359],
            [-0.413851007818539, 51.535749543687835],
            [-0.414858243108749, 51.536016736108401],
            [-0.415339455704386, 51.53656726368817],
            [-0.417480241526036, 51.536662700954693],
            [-0.417554051656372, 51.537860555497026],
            [-0.419380273016092, 51.53825565015503],
            [-0.419590043964, 51.538693697257074],
            [-0.418161098819657, 51.539150126634276],
            [-0.419507605209392, 51.540241039082098],
            [-0.419266728439141, 51.540450908568324],
            [-0.418534017945101, 51.540247702999253],
            [-0.416150586618465, 51.540241629998704],
            [-0.404852554033425, 51.542506569283432],
            [-0.397673024014702, 51.542325113792799],
            [-0.395272786164929, 51.542547688402209],
            [-0.396242227260168, 51.545531963093204],
            [-0.396557352533091, 51.545962499623919],
            [-0.397886060458253, 51.545662326456721],
            [-0.39872071943311, 51.547891192773335],
            [-0.394888925723486, 51.54763741250872],
            [-0.395008170569931, 51.548019413152765],
            [-0.394986560701685, 51.548756476737715],
            [-0.393944433477066, 51.548697256130197],
            [-0.38526952296332, 51.551170644829121],
            [-0.377688830020779, 51.554688135389505],
            [-0.377808037500313, 51.554986525822798],
            [-0.375861458596554, 51.553061346365304],
            [-0.373908443946686, 51.553910137793807],
            [-0.373364874450727, 51.554024897666054],
            [-0.372855150060616, 51.553716591771064],
            [-0.369842519287116, 51.554586586274908],
            [-0.366993413004902, 51.554995681227446],
            [-0.366589405014035, 51.555320971130882],
            [-0.362540993992188, 51.5560252871608],
            [-0.362245852165509, 51.557294464588814],
            [-0.347554794028665, 51.559683654672668],
            [-0.343980827221363, 51.557792563548141],
            [-0.338324995364065, 51.557224329677176],
            [-0.335584461591767, 51.556583009164001],
            [-0.327022967910274, 51.555010807089594],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000010",
        LAD13CDO: "00AK",
        LAD13NM: "Enfield",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.099561479219148, 51.6911118636664],
            [-0.094802820528717, 51.690111263527328],
            [-0.083936198016322, 51.689962377771728],
            [-0.079020961860449, 51.68891182033645],
            [-0.066367746360464, 51.683842928716516],
            [-0.062043355948554, 51.683005505423786],
            [-0.056626642357636, 51.682986143290059],
            [-0.051720886483473, 51.683532535663097],
            [-0.049206796283422, 51.684269544602039],
            [-0.048955972992393, 51.684046863417464],
            [-0.048345748408971, 51.68407449295313],
            [-0.047186255927854, 51.68345273033438],
            [-0.044269305995783, 51.683432070678215],
            [-0.043655604668312, 51.683339119900296],
            [-0.043697982271299, 51.683094333567375],
            [-0.033418409645046, 51.682491923740407],
            [-0.028402879644094, 51.682329600760632],
            [-0.019797987240473, 51.681148906965966],
            [-0.011945056131974, 51.680875057638005],
            [-0.011091949664576, 51.680866908204273],
            [-0.010354822639989, 51.676662148980107],
            [-0.011377064532896, 51.671916167091034],
            [-0.011397077199181, 51.670699828166832],
            [-0.010584897871078, 51.669134874881728],
            [-0.01124488789096, 51.667603848780281],
            [-0.011520350825561, 51.663889235227572],
            [-0.009857831575289, 51.661768525887567],
            [-0.009111860166019, 51.661006808691745],
            [-0.008967245962211, 51.660215717764117],
            [-0.011806130916044, 51.65303387789271],
            [-0.011863612760221, 51.648846157711397],
            [-0.012286061781355, 51.646227507168227],
            [-0.013277603376042, 51.639847038376352],
            [-0.014793756996723, 51.636734307531434],
            [-0.016835442309214, 51.633527921327612],
            [-0.02294818620993, 51.625812953877904],
            [-0.024452940820482, 51.624133310232345],
            [-0.025247355614811, 51.62250734526534],
            [-0.026000645201378, 51.62176104854489],
            [-0.032420566483036, 51.616621713186774],
            [-0.035386996992459, 51.612988973753126],
            [-0.035574086613031, 51.612001132035509],
            [-0.034187270638145, 51.610301712581801],
            [-0.034020157858624, 51.60948958863802],
            [-0.035277539532746, 51.6082319088001],
            [-0.041447876199425, 51.605634584706131],
            [-0.050231105628109, 51.607355402376605],
            [-0.052915767800109, 51.608100482107545],
            [-0.052910011100518, 51.608505048188427],
            [-0.054379380499716, 51.608863034510108],
            [-0.065045327376432, 51.608587026238936],
            [-0.067140408321432, 51.608938978827297],
            [-0.067508267598703, 51.608642889128809],
            [-0.070869922130559, 51.608791708744462],
            [-0.07562715609164, 51.608452549080326],
            [-0.085851626457058, 51.608468658290697],
            [-0.089716216402944, 51.608816676963194],
            [-0.091443167419472, 51.608790815087268],
            [-0.09247162478446, 51.609097089443139],
            [-0.092486464337853, 51.608846441779235],
            [-0.093580504328668, 51.609000900820625],
            [-0.095157576258738, 51.608656915638001],
            [-0.095812432051321, 51.609297910081004],
            [-0.097681059563254, 51.608923549996838],
            [-0.097485581063302, 51.60852111809632],
            [-0.099099741330744, 51.60846364038521],
            [-0.101783459466395, 51.608489985906047],
            [-0.102538628745315, 51.608043582466472],
            [-0.10351481785545, 51.608427148786483],
            [-0.110424722239761, 51.608339856175505],
            [-0.110823564291747, 51.608612451420356],
            [-0.117289501486984, 51.608434877924765],
            [-0.117517285667444, 51.60820023259987],
            [-0.118736288769389, 51.60865231064539],
            [-0.119203139276032, 51.608363942053117],
            [-0.123543324143282, 51.608706774302199],
            [-0.124897571301291, 51.609944187123425],
            [-0.125355250500741, 51.610018041101391],
            [-0.126336519589241, 51.609301727865464],
            [-0.127902246381305, 51.609438215600136],
            [-0.135283227253725, 51.611214795735918],
            [-0.138785614410406, 51.610192195820304],
            [-0.144536107594742, 51.615484418220369],
            [-0.142057370223235, 51.616158306775745],
            [-0.141291431209031, 51.617546292353254],
            [-0.140639590538747, 51.61773200300027],
            [-0.13546204149233, 51.621689250948208],
            [-0.135571868128686, 51.622393298732312],
            [-0.135070035623235, 51.622335869659963],
            [-0.13420620086432, 51.623989327457359],
            [-0.133536774220952, 51.626360762387392],
            [-0.130466835497026, 51.629377409916316],
            [-0.131147218407707, 51.629662509519882],
            [-0.130205651533001, 51.631020650246441],
            [-0.129803551555446, 51.630960291408748],
            [-0.129147276768588, 51.632266316191007],
            [-0.1313568598273, 51.632577570900175],
            [-0.133295269934678, 51.63326484889992],
            [-0.134003985877411, 51.634040914368519],
            [-0.134436718896719, 51.633971805937428],
            [-0.134534190303682, 51.634695440122094],
            [-0.13843793825733, 51.636514505120807],
            [-0.142319301536081, 51.639313247800892],
            [-0.145102578174885, 51.642646644315484],
            [-0.147920827555277, 51.642081453560849],
            [-0.149048033393841, 51.644178252359772],
            [-0.149174486680699, 51.645261121958782],
            [-0.151330207764412, 51.645280694712959],
            [-0.152168178443864, 51.645822632952083],
            [-0.151068338491476, 51.647205422056281],
            [-0.152383382731451, 51.647639768078591],
            [-0.152273975516642, 51.647984250307701],
            [-0.149919641031998, 51.648792455795828],
            [-0.151019481032727, 51.650075896449586],
            [-0.152270564327932, 51.649845603869181],
            [-0.152564578322234, 51.651467046092158],
            [-0.153501978809634, 51.652695751035566],
            [-0.154050991247479, 51.652561405409074],
            [-0.154230786078533, 51.65297517971468],
            [-0.153490292144939, 51.653303446637466],
            [-0.15378195658296, 51.654664071042383],
            [-0.154722782882244, 51.655027760265817],
            [-0.154787657877713, 51.655530549703244],
            [-0.160515318559911, 51.656750742566409],
            [-0.163504294906036, 51.658166150889009],
            [-0.165041715584015, 51.658224355187564],
            [-0.175553950446671, 51.660567871946192],
            [-0.185891083694433, 51.662833099570832],
            [-0.182110443559578, 51.668600714230706],
            [-0.176736560579837, 51.67209632299835],
            [-0.177031442184659, 51.672676404132126],
            [-0.176410950670027, 51.672641593047643],
            [-0.1754657618335, 51.672589145753264],
            [-0.1756829052283, 51.673159929589701],
            [-0.17535466792694, 51.673446179278606],
            [-0.174983709155306, 51.673212012438512],
            [-0.172498221769114, 51.6730879372601],
            [-0.163631965330001, 51.682399597960185],
            [-0.163528600365344, 51.685919344462235],
            [-0.163518007750547, 51.688111486896723],
            [-0.158016766723153, 51.687237625615431],
            [-0.153282972070261, 51.685854023095871],
            [-0.149395102465293, 51.685514971039822],
            [-0.144683178364267, 51.686331770379276],
            [-0.139611138082037, 51.687917811646528],
            [-0.135404204930975, 51.688722455007131],
            [-0.130274791227897, 51.68891248316362],
            [-0.122208363578012, 51.688542810405515],
            [-0.118272801771632, 51.6889348369345],
            [-0.112922347637826, 51.690819210631808],
            [-0.109646213541379, 51.691490411450488],
            [-0.109774640287118, 51.691672324155256],
            [-0.105804731996277, 51.691872745266288],
            [-0.099561479219148, 51.6911118636664],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000011",
        LAD13CDO: "00AL",
        LAD13NM: "Greenwich",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-0.016521724268272, 51.481638623234716],
              [-0.017116566610447, 51.480409490775465],
              [-0.017875306971385, 51.480880048013894],
              [-0.018400910828536, 51.480848465039045],
              [-0.020418030273999, 51.480230567779856],
              [-0.020367433118661, 51.479935652431408],
              [-0.023070951654299, 51.4796287535559],
              [-0.023161272575439, 51.480737276575148],
              [-0.02640870425134, 51.481509599166294],
              [-0.025819137084824, 51.482188513702155],
              [-0.024847350880798, 51.48554620243776],
              [-0.022613800899026, 51.484537347439435],
              [-0.018818624211926, 51.483505670434312],
              [-0.018189860550005, 51.481995962898893],
              [-0.016521724268272, 51.481638623234716],
            ],
          ],
          [
            [
              [0.120535196424074, 51.511043116959669],
              [0.120981909825738, 51.500771513592262],
              [0.121082301220085, 51.496293058463486],
              [0.122097970839714, 51.482426575512534],
              [0.124160770534975, 51.476823529986603],
              [0.120730976314132, 51.478511533209478],
              [0.118454273914695, 51.47891960273234],
              [0.115489650369874, 51.476164660956094],
              [0.114024751423045, 51.475191977170198],
              [0.112560112554904, 51.473475560393155],
              [0.111772425591692, 51.473194785508369],
              [0.108225549718904, 51.476126445321363],
              [0.106162520350378, 51.475014237793523],
              [0.105636156683848, 51.475467040537367],
              [0.105191067702634, 51.475245715738765],
              [0.104891410143974, 51.475524479001557],
              [0.103377063602434, 51.474598414938455],
              [0.102470416606134, 51.474924031861384],
              [0.10200182299538, 51.474472899508164],
              [0.100199332967626, 51.475234523396523],
              [0.098223957681474, 51.475462340290214],
              [0.096548994790285, 51.473228805329242],
              [0.094471725003846, 51.473274028252575],
              [0.093171070528436, 51.472951932294563],
              [0.091264175700761, 51.472062397819307],
              [0.089047866201841, 51.470418451403901],
              [0.085763144468056, 51.469432868240958],
              [0.08479669876853, 51.468731530834248],
              [0.083218251460345, 51.466479006080135],
              [0.082295538180795, 51.46664108319213],
              [0.082175724891833, 51.464736729036744],
              [0.082629923152526, 51.463613548336525],
              [0.082266455974642, 51.461606506957857],
              [0.08287824192376, 51.459834838672187],
              [0.078637153853385, 51.459293209205285],
              [0.078826699569034, 51.459124379664161],
              [0.081419789641846, 51.457774402675511],
              [0.085683940666769, 51.45664554706994],
              [0.086021506521551, 51.455619750249461],
              [0.086796259590326, 51.450451251009937],
              [0.088039273283605, 51.447506432057303],
              [0.087281158558908, 51.445746540170056],
              [0.086294251947708, 51.445311769158046],
              [0.087380134352111, 51.443246556677501],
              [0.086320548260864, 51.44323664488244],
              [0.084712009318193, 51.442611483822503],
              [0.083216079583927, 51.443542764341615],
              [0.082810200367667, 51.443288284943449],
              [0.082825826439457, 51.442841061530615],
              [0.084415551940393, 51.442010636564035],
              [0.081785427071668, 51.439962041372851],
              [0.08085158223747, 51.440405785825014],
              [0.078790005728356, 51.438133898032518],
              [0.079036148469574, 51.437977552517914],
              [0.078418535984803, 51.437114399095286],
              [0.077819941367092, 51.436447849254392],
              [0.076544581721961, 51.436879628045361],
              [0.074960335604015, 51.435861815164834],
              [0.076503124295412, 51.434855167419968],
              [0.075889535507875, 51.434459559049735],
              [0.076388260203566, 51.434003778575537],
              [0.075654701073476, 51.433627380865858],
              [0.076080097458324, 51.433299699810419],
              [0.074652648862433, 51.432151406614572],
              [0.075343134505704, 51.431990799177981],
              [0.074835946746498, 51.430830705209551],
              [0.074304751062589, 51.43149029314003],
              [0.07332778167106, 51.431773769599495],
              [0.073035112300187, 51.431378763812155],
              [0.073471383919731, 51.431259534469476],
              [0.071942791906871, 51.429653462164772],
              [0.066935041380777, 51.426722081396427],
              [0.066404142006933, 51.426337555693856],
              [0.066579480727601, 51.426015216905846],
              [0.064194156623064, 51.425370199637896],
              [0.064437183178735, 51.425049369701654],
              [0.063202270589673, 51.424203304876386],
              [0.061708231666141, 51.423734990379316],
              [0.060899906063883, 51.42507925348567],
              [0.058596555547171, 51.424590955804973],
              [0.058199720451853, 51.424882999721021],
              [0.054826985000658, 51.427522235902373],
              [0.051049044855556, 51.431529980708525],
              [0.047784875450584, 51.433753435119748],
              [0.04514703681638, 51.436219466311286],
              [0.045182865934482, 51.436602835103926],
              [0.043311579187625, 51.437745188593304],
              [0.042184666396002, 51.439480660514597],
              [0.039748478254516, 51.440996106479197],
              [0.035543553585559, 51.442446007324392],
              [0.033470476499515, 51.443591703727563],
              [0.030585718770561, 51.444317074332581],
              [0.029358966392502, 51.441705226683411],
              [0.027767468160007, 51.441510648374503],
              [0.025416185767172, 51.441738352647342],
              [0.021482862909919, 51.442735210505639],
              [0.020596816441374, 51.444210917880419],
              [0.021496470554296, 51.44444450605787],
              [0.021210017414629, 51.446691348853307],
              [0.022942398165802, 51.44720462842821],
              [0.022401686561109, 51.448125827792111],
              [0.021927570330259, 51.448958645653349],
              [0.015063752057979, 51.450031873347989],
              [0.01487594923764, 51.450604344421826],
              [0.015122713230645, 51.45022690231697],
              [0.01987163050538, 51.450735094037249],
              [0.019535903098244, 51.451704904833875],
              [0.018331579773579, 51.451582662077584],
              [0.017513892739578, 51.455039173131006],
              [0.014030588174536, 51.455726766816291],
              [0.011484641303472, 51.456685044970804],
              [0.009947389584917, 51.459340895845777],
              [0.009795626010971, 51.462505351042893],
              [0.009279838921624, 51.464036665499194],
              [0.00909709466274, 51.465874318502678],
              [0.013245379895772, 51.466831925411313],
              [0.012691166913417, 51.467357625351362],
              [0.01306891228052, 51.468121818471154],
              [0.012601703449235, 51.469806987679696],
              [0.013481107574777, 51.469897103053974],
              [0.016330536739716, 51.468981230256709],
              [0.016532981693577, 51.469131524388594],
              [0.016694264845057, 51.469491158122331],
              [0.015357539554567, 51.47156988720446],
              [0.01498490181025, 51.473079879650086],
              [0.018123601606253, 51.473853226959037],
              [0.01208889858585, 51.473172781492039],
              [-0.000983579297989, 51.471811881445518],
              [-0.008143481207123, 51.472756575965292],
              [-0.012518847374704, 51.472340725310616],
              [-0.014069522161415, 51.472675451944887],
              [-0.014528637291091, 51.471917948076552],
              [-0.015184508601709, 51.47176898303119],
              [-0.013128976237678, 51.468936532526904],
              [-0.015090220217585, 51.468036309712367],
              [-0.016558988944711, 51.469227522554377],
              [-0.016949420381478, 51.469056071446659],
              [-0.017161713932206, 51.469340234729017],
              [-0.019118014741737, 51.469948838081045],
              [-0.019272685239432, 51.47050000649449],
              [-0.018671715122424, 51.47064542572631],
              [-0.019319216385597, 51.471985488543346],
              [-0.020245108257488, 51.472184577981523],
              [-0.020050683889048, 51.473083263018182],
              [-0.021772409622089, 51.474025984419718],
              [-0.022672716843668, 51.473985416812049],
              [-0.022803211105991, 51.474464230559946],
              [-0.022767619095859, 51.475353907430218],
              [-0.021154055937511, 51.475237657471467],
              [-0.020473608171187, 51.475687496550655],
              [-0.020640016422195, 51.476467275175658],
              [-0.019868809422607, 51.476884104129958],
              [-0.019554797390964, 51.47668635674323],
              [-0.018841982902116, 51.477317292291929],
              [-0.018980709806808, 51.478203618585013],
              [-0.018372493556883, 51.479377678947557],
              [-0.016886648853376, 51.480065678309614],
              [-0.015681590232037, 51.481682860668954],
              [-0.015025547463064, 51.481766179545438],
              [-0.017864486178172, 51.482524628932289],
              [-0.017884815773513, 51.483218309229578],
              [-0.016332861567059, 51.483236127990835],
              [-0.01632900216396, 51.4829599869744],
              [-0.015457982654156, 51.482819350160412],
              [-0.015266020664495, 51.48312724825908],
              [-0.01111256001597, 51.483054171318223],
              [-0.009914602379425, 51.483608478582319],
              [-0.007266547327842, 51.483791919021037],
              [-0.004629416402909, 51.484681413160288],
              [-0.003365119641871, 51.484872119016245],
              [-0.002045134312733, 51.485644589936364],
              [-0.002310480144434, 51.485900906815473],
              [-0.001687893113234, 51.486238315852795],
              [-0.001695587013418, 51.485897623326231],
              [0.000131465408718, 51.48686736032279],
              [0.001638844873004, 51.488495397346227],
              [0.001962844184011, 51.488460190117081],
              [0.002447061787192, 51.489944711661934],
              [0.002363544080954, 51.493002758676468],
              [0.001954827791569, 51.492913515309731],
              [0.001558198106454, 51.494020992912333],
              [0.000954398869858, 51.494411688882884],
              [0.001242196319749, 51.494798859333201],
              [0.000862139989585, 51.495495984159618],
              [-0.000286050149441, 51.495233199308494],
              [-0.00103917457631, 51.496393508045905],
              [-0.002267101523082, 51.500139206224155],
              [-0.002302871947653, 51.501298073775871],
              [-0.001872992145956, 51.501309634585851],
              [-0.001921861412268, 51.501904905444469],
              [-0.001541674233633, 51.502032396551371],
              [-0.002020731437062, 51.502148471657328],
              [-0.001701448962875, 51.502925395170543],
              [0.000825295689816, 51.504523477658559],
              [0.003297233823439, 51.504867962614576],
              [0.005145850336146, 51.504306699676327],
              [0.011487560744257, 51.49887711302911],
              [0.015699881933433, 51.495893841009021],
              [0.019198456408329, 51.494766213870577],
              [0.021702907487852, 51.494454179088564],
              [0.021576860195264, 51.494014809347199],
              [0.021980063641731, 51.493945807629473],
              [0.022303871923285, 51.494296335620014],
              [0.024379320634904, 51.493862145867183],
              [0.029019077606119, 51.493915945445409],
              [0.040248174807907, 51.495451309383007],
              [0.057707693311319, 51.494458280510699],
              [0.058028250544757, 51.494058771430261],
              [0.058090562724429, 51.49445066189476],
              [0.058831442641001, 51.494459238589066],
              [0.058939422120819, 51.494201947898041],
              [0.059165143842494, 51.494479458060276],
              [0.064056229228256, 51.494513068740119],
              [0.0641104748038, 51.494725242731192],
              [0.070326763383182, 51.495166910639163],
              [0.075925813459708, 51.496099393639632],
              [0.076207373204492, 51.495891171569539],
              [0.082403773642757, 51.498861327505473],
              [0.086630831627721, 51.503524490771113],
              [0.087590671101251, 51.503969637296024],
              [0.087926898572018, 51.505556275410989],
              [0.092024701801643, 51.509007521098205],
              [0.094590297550417, 51.5089536314348],
              [0.097317036337074, 51.509490328663773],
              [0.100243920917953, 51.509677155566081],
              [0.106878157055421, 51.509410671590842],
              [0.113488367706126, 51.510152339888982],
              [0.120210839735287, 51.51144466607095],
              [0.120535196424074, 51.511043116959669],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000012",
        LAD13CDO: "00AM",
        LAD13NM: "Hackney",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.061184180251801, 51.57778428264232],
            [-0.058430878387859, 51.572524897864078],
            [-0.056399220150706, 51.570794374202485],
            [-0.054291935034098, 51.570196518020147],
            [-0.053159240545741, 51.568573462471178],
            [-0.051175523887205, 51.567721312070162],
            [-0.046727308934074, 51.564379456969888],
            [-0.047219394407922, 51.562660179456941],
            [-0.045726367079731, 51.562250439813319],
            [-0.045104007928001, 51.561758071269921],
            [-0.042389683025856, 51.561366601680263],
            [-0.040076565646787, 51.561778512340759],
            [-0.038837848376431, 51.561160707756251],
            [-0.03641656402598, 51.561504193331125],
            [-0.033308023662903, 51.560621215380088],
            [-0.030117979566761, 51.561359945077264],
            [-0.029170502736534, 51.561283783437361],
            [-0.025890929095482, 51.559922926653272],
            [-0.024377599862194, 51.558656477911647],
            [-0.023968712696787, 51.557372645211707],
            [-0.023033992814248, 51.557270569718696],
            [-0.018471160634414, 51.555175657523158],
            [-0.01750429918859, 51.553392278418265],
            [-0.017167868347094, 51.551575483401528],
            [-0.018082406479976, 51.5515747499892],
            [-0.018995734541442, 51.551601866064701],
            [-0.018941963059451, 51.550482278770282],
            [-0.017458833413727, 51.547537326476821],
            [-0.01657157600578, 51.54336414642146],
            [-0.019228170796985, 51.543594280365255],
            [-0.02561272437174, 51.54336108861407],
            [-0.029006979652003, 51.54227430746441],
            [-0.02947478006328, 51.543029451145436],
            [-0.033173172999772, 51.544685902718449],
            [-0.037762679485618, 51.541794306046249],
            [-0.03715672858164, 51.541431659295284],
            [-0.037468373494889, 51.541260618600944],
            [-0.040734849676767, 51.538102167849253],
            [-0.041437234283656, 51.53746103498937],
            [-0.044860706491293, 51.535807753659249],
            [-0.04757481078155, 51.535529225530595],
            [-0.049551425403169, 51.536311189788435],
            [-0.050956871418904, 51.53645684887136],
            [-0.052394593363143, 51.535914195409489],
            [-0.052836066973056, 51.534928743566439],
            [-0.054703633094872, 51.535158464772024],
            [-0.057299968061625, 51.534426331746623],
            [-0.059909720829997, 51.534156581795955],
            [-0.062470432863333, 51.535516316589366],
            [-0.06283805958626, 51.534634821660831],
            [-0.06223804376763, 51.533247249819013],
            [-0.064980731190869, 51.53290044882398],
            [-0.065140294033647, 51.533217820706618],
            [-0.06631328124558, 51.531226424582357],
            [-0.072265447913035, 51.530839694124751],
            [-0.074649662830356, 51.529791651523965],
            [-0.075635326833566, 51.528435561131687],
            [-0.077253316418381, 51.527296658174592],
            [-0.07631536326382, 51.527146391149657],
            [-0.076443082259817, 51.524287051754307],
            [-0.075915456469649, 51.524321562234313],
            [-0.075718647177881, 51.523587237933931],
            [-0.076163420279799, 51.522200685722098],
            [-0.078236501329835, 51.521853384982229],
            [-0.078471498381361, 51.521510121899681],
            [-0.08105040328821, 51.521953410232598],
            [-0.081762308163553, 51.520757353671321],
            [-0.083325513387581, 51.519814409299038],
            [-0.085217906072325, 51.520334513123139],
            [-0.084223551192296, 51.523900912035259],
            [-0.08513937277321, 51.523924859018102],
            [-0.085443433824156, 51.524511640782372],
            [-0.084751022308772, 51.52541577905793],
            [-0.085020710590177, 51.526203433705739],
            [-0.086795419156157, 51.525789060963731],
            [-0.087439954240208, 51.525957840894534],
            [-0.088780463453632, 51.527442778032416],
            [-0.095405360753156, 51.529193470372732],
            [-0.095112899162361, 51.530052004902871],
            [-0.097033649990862, 51.532982372770647],
            [-0.08969060078762, 51.5360077110685],
            [-0.086570904166927, 51.537401967197724],
            [-0.083660670751907, 51.545064661050091],
            [-0.083838313779307, 51.546603486431387],
            [-0.076839429057005, 51.546079803417193],
            [-0.076357679896338, 51.546422614067374],
            [-0.076635743851724, 51.547934321924785],
            [-0.079461339020132, 51.551664850293697],
            [-0.085504438537441, 51.552161987223734],
            [-0.087552389015126, 51.552823065607797],
            [-0.088588530153753, 51.553947828202006],
            [-0.089299699427906, 51.553740894136887],
            [-0.090403381449438, 51.554150037168192],
            [-0.090393381400696, 51.554423246661798],
            [-0.09042032839195, 51.557671778407247],
            [-0.091702809133225, 51.560222234763678],
            [-0.096032221856618, 51.561226889250001],
            [-0.098587663744929, 51.560837588650124],
            [-0.104510745725379, 51.564705770205393],
            [-0.104391151200697, 51.564771281754247],
            [-0.100700386539105, 51.567125179713216],
            [-0.096115593089725, 51.570698228697871],
            [-0.097664741454138, 51.573652202818593],
            [-0.096613119806407, 51.573814999174459],
            [-0.094710426117311, 51.573588984354046],
            [-0.093583094884078, 51.573974436989872],
            [-0.091389755908102, 51.574003528643573],
            [-0.089981089851706, 51.574386169114625],
            [-0.086872159324062, 51.574292359937871],
            [-0.087231661186229, 51.574701083833361],
            [-0.080876831268559, 51.575640454304398],
            [-0.07624670556308, 51.574931567845375],
            [-0.075784300045867, 51.575492312047857],
            [-0.07532378910585, 51.57532469326474],
            [-0.074448564162932, 51.575681724845381],
            [-0.070934018749196, 51.575690545460205],
            [-0.066737794350639, 51.57635707454186],
            [-0.066782943895751, 51.576619500299955],
            [-0.061184180251801, 51.57778428264232],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000013",
        LAD13CDO: "00AN",
        LAD13NM: "Hammersmith and Fulham",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.234423879760615, 51.532637039710444],
            [-0.231394108305529, 51.531088775187769],
            [-0.228503133284014, 51.530352740801568],
            [-0.228727736321715, 51.529877737477733],
            [-0.227412162019452, 51.527892130073745],
            [-0.227375003252322, 51.525842202803581],
            [-0.227195199780102, 51.524792771097651],
            [-0.226619903856768, 51.524795765413188],
            [-0.22672942517806, 51.5219765064707],
            [-0.228301583860741, 51.521097425944262],
            [-0.226563156073996, 51.51957751828364],
            [-0.222604056150626, 51.515642765189718],
            [-0.220268979122356, 51.511728928760725],
            [-0.219286532632631, 51.50957472021603],
            [-0.217888689571522, 51.509759415992406],
            [-0.218053329134683, 51.510230422735226],
            [-0.217209743581386, 51.510392056395453],
            [-0.216841808840275, 51.509444057052853],
            [-0.215817107546801, 51.509114638177117],
            [-0.215202937867884, 51.507646721796924],
            [-0.21614458613873, 51.507518967009041],
            [-0.21596345039068, 51.506401150512431],
            [-0.217990767656138, 51.506146011256838],
            [-0.217040565086282, 51.504500343145914],
            [-0.217008881975458, 51.504462992518057],
            [-0.214966554906713, 51.502301618317738],
            [-0.214133101679672, 51.500770107014731],
            [-0.209922221366654, 51.497777070552424],
            [-0.208001441663969, 51.496022084907679],
            [-0.207388528621719, 51.496148503918519],
            [-0.203027937001033, 51.493321083291299],
            [-0.201835357892801, 51.49185141924572],
            [-0.202387074993054, 51.491668338094804],
            [-0.198222043763706, 51.488064082492677],
            [-0.196448206809708, 51.487205909524391],
            [-0.195987919087366, 51.487372384287127],
            [-0.193621050122526, 51.485813521324836],
            [-0.190794816586318, 51.483164826944495],
            [-0.188281088601267, 51.4809606233949],
            [-0.186549978904703, 51.479871849943493],
            [-0.185374786497862, 51.478602812114232],
            [-0.183880909250943, 51.477418751456035],
            [-0.18410846902827, 51.477164190823601],
            [-0.182424950059123, 51.477094043572293],
            [-0.180532342190561, 51.477682476661307],
            [-0.179424118307526, 51.477594234284197],
            [-0.181173771464527, 51.472309509291293],
            [-0.184493533584378, 51.467647081661845],
            [-0.186381234292648, 51.466444321344966],
            [-0.190521148318403, 51.465225054306877],
            [-0.194307114506667, 51.464833797382504],
            [-0.197172528452658, 51.464967817730219],
            [-0.197399141946426, 51.465208704292706],
            [-0.199880987960917, 51.464993246650309],
            [-0.202391141297405, 51.465302433561149],
            [-0.210445171706202, 51.467130617453527],
            [-0.210554378392555, 51.467724889427515],
            [-0.211006709261068, 51.467313641180255],
            [-0.21668781505934, 51.46956917369257],
            [-0.221948382631507, 51.473878225780297],
            [-0.222671110455346, 51.474918820642706],
            [-0.2236359971854, 51.477622189166858],
            [-0.224063276805693, 51.481711238087492],
            [-0.223685542735192, 51.481906947761445],
            [-0.224088009725375, 51.482446296361381],
            [-0.225021043102289, 51.484269704789135],
            [-0.227621391998238, 51.487447400124644],
            [-0.22863042989356, 51.488802520273104],
            [-0.233138020978877, 51.490316504874357],
            [-0.234396996583411, 51.490428088455225],
            [-0.240558740823576, 51.490106144777364],
            [-0.243089876995872, 51.489462466590076],
            [-0.244562393969254, 51.48870217708221],
            [-0.245434316492458, 51.489477783290425],
            [-0.245609219581596, 51.490212383171425],
            [-0.244420592393099, 51.49794336161419],
            [-0.245004510902418, 51.49789454953001],
            [-0.245292565799815, 51.498384449899213],
            [-0.24923459217952, 51.49694972422607],
            [-0.252142481023012, 51.500661998200798],
            [-0.253083914262199, 51.501399912688349],
            [-0.253365857246629, 51.502881562650032],
            [-0.255090373331911, 51.504319936874751],
            [-0.253659206529602, 51.504291442180161],
            [-0.253764449751569, 51.504553788566206],
            [-0.252543716465036, 51.504711858021324],
            [-0.252515381108166, 51.504510905455078],
            [-0.251046472703044, 51.504640082722133],
            [-0.249928993618254, 51.504434573355098],
            [-0.249431093183269, 51.504680728526814],
            [-0.249280405475039, 51.504439281536889],
            [-0.248080848552132, 51.504608411561591],
            [-0.248011191844397, 51.504358281606201],
            [-0.245702705970943, 51.504625020626285],
            [-0.246513769659736, 51.506731479054224],
            [-0.245086811047362, 51.506741610322749],
            [-0.246650281120857, 51.511245903393764],
            [-0.248996317765225, 51.513879749611263],
            [-0.250604863148626, 51.518540222464466],
            [-0.250721507081745, 51.519483463796348],
            [-0.250305077096738, 51.520157076484601],
            [-0.251923482478103, 51.522235063609614],
            [-0.25263913405477, 51.524777976964465],
            [-0.250295361969, 51.52717997251262],
            [-0.250621128989681, 51.527782823268517],
            [-0.250066101839043, 51.52819628689825],
            [-0.250468517645944, 51.528341670817234],
            [-0.24964460274261, 51.529074847079585],
            [-0.24695572825327, 51.530729756745046],
            [-0.245269072638192, 51.531212608574556],
            [-0.246994552928248, 51.531783342614752],
            [-0.246311399977157, 51.532752400438],
            [-0.243230249785268, 51.531779187466917],
            [-0.237350120914966, 51.53201830603426],
            [-0.234423879760615, 51.532637039710444],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000014",
        LAD13CDO: "00AP",
        LAD13NM: "Haringey",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.127902246381305, 51.609438215600136],
            [-0.126336519589241, 51.609301727865464],
            [-0.125355250500741, 51.610018041101391],
            [-0.124897571301291, 51.609944187123425],
            [-0.123543324143282, 51.608706774302199],
            [-0.119203139276032, 51.608363942053117],
            [-0.118736288769389, 51.60865231064539],
            [-0.117517285667444, 51.60820023259987],
            [-0.117289501486984, 51.608434877924765],
            [-0.110823564291747, 51.608612451420356],
            [-0.110424722239761, 51.608339856175505],
            [-0.10351481785545, 51.608427148786483],
            [-0.102538628745315, 51.608043582466472],
            [-0.101783459466395, 51.608489985906047],
            [-0.099099741330744, 51.60846364038521],
            [-0.097485581063302, 51.60852111809632],
            [-0.097681059563254, 51.608923549996838],
            [-0.095812432051321, 51.609297910081004],
            [-0.095157576258738, 51.608656915638001],
            [-0.093580504328668, 51.609000900820625],
            [-0.092486464337853, 51.608846441779235],
            [-0.09247162478446, 51.609097089443139],
            [-0.091443167419472, 51.608790815087268],
            [-0.089716216402944, 51.608816676963194],
            [-0.085851626457058, 51.608468658290697],
            [-0.07562715609164, 51.608452549080326],
            [-0.070869922130559, 51.608791708744462],
            [-0.067508267598703, 51.608642889128809],
            [-0.067140408321432, 51.608938978827297],
            [-0.065045327376432, 51.608587026238936],
            [-0.054379380499716, 51.608863034510108],
            [-0.052910011100518, 51.608505048188427],
            [-0.052915767800109, 51.608100482107545],
            [-0.050231105628109, 51.607355402376605],
            [-0.041447876199425, 51.605634584706131],
            [-0.043540415434819, 51.602813466603742],
            [-0.046468827285956, 51.599788608839603],
            [-0.050298427065463, 51.596850593305476],
            [-0.051251567125791, 51.59495731593843],
            [-0.052252134577114, 51.594045899688652],
            [-0.052532944977356, 51.592442700623025],
            [-0.05340731759462, 51.590834060094039],
            [-0.053377524395734, 51.589606089179988],
            [-0.052165629423949, 51.587833341442391],
            [-0.053159902531475, 51.586929906322453],
            [-0.054727740136651, 51.585912774392305],
            [-0.05602794529841, 51.583994635634447],
            [-0.057996645085517, 51.582407680543938],
            [-0.05976124876479, 51.581508842424959],
            [-0.061357106189947, 51.580605391941447],
            [-0.062237584643148, 51.579586695371098],
            [-0.061184180251801, 51.57778428264232],
            [-0.066782943895751, 51.576619500299955],
            [-0.066737794350639, 51.57635707454186],
            [-0.070934018749196, 51.575690545460205],
            [-0.074448564162932, 51.575681724845381],
            [-0.07532378910585, 51.57532469326474],
            [-0.075784300045867, 51.575492312047857],
            [-0.07624670556308, 51.574931567845375],
            [-0.080876831268559, 51.575640454304398],
            [-0.087231661186229, 51.574701083833361],
            [-0.086872159324062, 51.574292359937871],
            [-0.089981089851706, 51.574386169114625],
            [-0.091389755908102, 51.574003528643573],
            [-0.093583094884078, 51.573974436989872],
            [-0.094710426117311, 51.573588984354046],
            [-0.096613119806407, 51.573814999174459],
            [-0.097664741454138, 51.573652202818593],
            [-0.096115593089725, 51.570698228697871],
            [-0.100700386539105, 51.567125179713216],
            [-0.104391151200697, 51.564771281754247],
            [-0.104871505418489, 51.56463516143306],
            [-0.106204650274396, 51.565538847636731],
            [-0.110707657010046, 51.568605930174854],
            [-0.115166078033205, 51.570672157493597],
            [-0.115405684924706, 51.571891785971829],
            [-0.116127865036919, 51.5721902415671],
            [-0.115585326459636, 51.572572701998148],
            [-0.117184433994835, 51.573825840445345],
            [-0.117585304759842, 51.574147906716462],
            [-0.119567057173802, 51.574384708172474],
            [-0.118890252276713, 51.574733558545134],
            [-0.119650680395106, 51.575505607650513],
            [-0.123775363554822, 51.574662492346462],
            [-0.124880979959835, 51.574097454383214],
            [-0.127496512591569, 51.574153607624162],
            [-0.131389743249347, 51.573563715982395],
            [-0.13342714118819, 51.57286595614773],
            [-0.13834394530272, 51.570600635992463],
            [-0.140706207729624, 51.569397134397697],
            [-0.142415880417378, 51.569120267654064],
            [-0.143855430145825, 51.569912799110497],
            [-0.150350754255611, 51.571573748271312],
            [-0.158862786701175, 51.572193199552167],
            [-0.160455031665567, 51.572787389732667],
            [-0.1643008249503, 51.572501406455764],
            [-0.167373031226188, 51.572970269819827],
            [-0.171285290056077, 51.572429711799224],
            [-0.171181543111701, 51.573612392592992],
            [-0.1694840471559, 51.573981590781514],
            [-0.169753480053378, 51.576399350136484],
            [-0.169345647486203, 51.577426213828005],
            [-0.168088487178118, 51.576926402329413],
            [-0.166838420584901, 51.578654102359081],
            [-0.167219823495958, 51.581299324219074],
            [-0.166896098828117, 51.581955208122238],
            [-0.166215675085277, 51.582041695657331],
            [-0.16574670591534, 51.583221361093251],
            [-0.161350967906813, 51.585272979177901],
            [-0.1596429706729, 51.584460273286041],
            [-0.1579661538608, 51.585733366198873],
            [-0.157922075223614, 51.586216464716081],
            [-0.157182315697613, 51.586197657225874],
            [-0.157430033741605, 51.586568436053973],
            [-0.15870503195671, 51.58663071095841],
            [-0.158640577407262, 51.587974960250712],
            [-0.160273159858916, 51.587977183290633],
            [-0.159480845130241, 51.591579696883926],
            [-0.158689306344716, 51.592053768912628],
            [-0.158864004452629, 51.592374839685739],
            [-0.159675463877847, 51.59226527005336],
            [-0.161786563503232, 51.597476159813134],
            [-0.156367676220716, 51.605356604633336],
            [-0.155987250998171, 51.605443250099498],
            [-0.156141367790357, 51.605096767546605],
            [-0.154643781047763, 51.604136228508729],
            [-0.153312285502066, 51.602678307429734],
            [-0.153463600875163, 51.600870524056894],
            [-0.153030520778325, 51.599230695213691],
            [-0.15138599066662, 51.59745039271867],
            [-0.147000702709219, 51.599195004701244],
            [-0.144495064796318, 51.600126597708204],
            [-0.144199385657771, 51.601110185784385],
            [-0.1421409036762, 51.603287948408706],
            [-0.142090599884761, 51.604591047196195],
            [-0.143103506600652, 51.6063462012401],
            [-0.1434506524551, 51.608846176695572],
            [-0.138785614410406, 51.610192195820304],
            [-0.135283227253725, 51.611214795735918],
            [-0.127902246381305, 51.609438215600136],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000015",
        LAD13CDO: "00AQ",
        LAD13NM: "Harrow",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.305597677157911, 51.634117191135864],
            [-0.305462313046658, 51.63336798943039],
            [-0.303953962195683, 51.631464151845492],
            [-0.287565635562117, 51.6171516987757],
            [-0.282199745334011, 51.613355926824802],
            [-0.279367943663357, 51.610743580903986],
            [-0.274932627182354, 51.606970992941832],
            [-0.267155911513881, 51.6003703486532],
            [-0.271208635622124, 51.598289954585297],
            [-0.273001730737778, 51.598371203079758],
            [-0.27697635643723, 51.596892841654842],
            [-0.276515651786621, 51.596514698370527],
            [-0.279351995058963, 51.59622899390299],
            [-0.28406933235504, 51.595604750690704],
            [-0.285612023079472, 51.595201960807408],
            [-0.285796597130665, 51.594747851180514],
            [-0.286282953768237, 51.594944689259307],
            [-0.290421934009064, 51.593568088742046],
            [-0.289092509396662, 51.592908471011164],
            [-0.286604711630876, 51.59226430585484],
            [-0.284653970265129, 51.591008372396303],
            [-0.283364121798757, 51.588881733131068],
            [-0.282271526511133, 51.585738244490592],
            [-0.282470776699423, 51.585047858094633],
            [-0.294315875028936, 51.586585622879191],
            [-0.302061817933132, 51.587278835538939],
            [-0.304846077648926, 51.587053811059775],
            [-0.30684119613252, 51.586543975586359],
            [-0.313075009746977, 51.584261592639685],
            [-0.317089503291599, 51.582103543145109],
            [-0.320706868900921, 51.580254403419382],
            [-0.326671587126436, 51.578772256130449],
            [-0.326586026182602, 51.578233301901072],
            [-0.325434128828264, 51.576039856399042],
            [-0.324741461511433, 51.57317314272084],
            [-0.322153724330603, 51.569654383993964],
            [-0.325175153362495, 51.568440430652501],
            [-0.325073663168265, 51.56825733914841],
            [-0.329745424949139, 51.567086595031547],
            [-0.329646140855182, 51.566212935991665],
            [-0.328669141637084, 51.565942746815836],
            [-0.328249654519491, 51.562509832546887],
            [-0.32968801722394, 51.562392734017969],
            [-0.331329560360932, 51.561572617218168],
            [-0.333922607902454, 51.558874025786359],
            [-0.333978147790924, 51.557709420967846],
            [-0.334908480952714, 51.557831432228333],
            [-0.335584461591767, 51.556583009164001],
            [-0.338324995364065, 51.557224329677176],
            [-0.343980827221363, 51.557792563548141],
            [-0.347554794028665, 51.559683654672668],
            [-0.362245852165509, 51.557294464588814],
            [-0.362540993992188, 51.5560252871608],
            [-0.366589405014035, 51.555320971130882],
            [-0.366993413004902, 51.554995681227446],
            [-0.369842519287116, 51.554586586274908],
            [-0.372855150060616, 51.553716591771064],
            [-0.373364874450727, 51.554024897666054],
            [-0.373908443946686, 51.553910137793807],
            [-0.375861458596554, 51.553061346365304],
            [-0.377808037500313, 51.554986525822798],
            [-0.380292700099819, 51.559108697376018],
            [-0.380109892504554, 51.559584558163344],
            [-0.380309142136361, 51.560676261898294],
            [-0.382381349337002, 51.562897138626141],
            [-0.381969727838047, 51.563072206592842],
            [-0.382503582365481, 51.563721607647132],
            [-0.382399280979515, 51.564709309229826],
            [-0.383297501886442, 51.567249386658496],
            [-0.385934841184102, 51.570963485361276],
            [-0.386460814870126, 51.571554309404803],
            [-0.385708236374468, 51.572146434636153],
            [-0.388075927679157, 51.574970144263482],
            [-0.387513412615218, 51.575287932898632],
            [-0.388413644811804, 51.577254292843861],
            [-0.391572379305191, 51.581558102874794],
            [-0.39104844630449, 51.581721772313621],
            [-0.391034201537957, 51.583065002860813],
            [-0.392033415864405, 51.588328304880392],
            [-0.394471862372761, 51.590448757790377],
            [-0.395489733012057, 51.590199207029663],
            [-0.395488823402258, 51.591618151788722],
            [-0.396364702640183, 51.59359759754598],
            [-0.396507160843815, 51.595086840081571],
            [-0.39770094634771, 51.596856604205009],
            [-0.400333411272124, 51.597420345271594],
            [-0.399967481178113, 51.598219252531713],
            [-0.401281959924963, 51.599683093283922],
            [-0.402359430766071, 51.601936796512554],
            [-0.402529801458532, 51.605187960540597],
            [-0.402372988590945, 51.606117407687321],
            [-0.401782155780174, 51.606616519560035],
            [-0.403237496197955, 51.609095661255637],
            [-0.404071972353939, 51.613180423856996],
            [-0.399487049288232, 51.613403954918297],
            [-0.392700990946294, 51.615178901345494],
            [-0.387132163654871, 51.615565620703158],
            [-0.384599078548453, 51.616164736385933],
            [-0.382013401475182, 51.617428488313408],
            [-0.378532859402003, 51.617776121774114],
            [-0.375409998964205, 51.618299452331556],
            [-0.36819716795753, 51.620648872043226],
            [-0.365559452244296, 51.621330650513464],
            [-0.363454727814076, 51.622534149551271],
            [-0.362618442911358, 51.623533202798185],
            [-0.361815488048044, 51.623726122809408],
            [-0.359701579004706, 51.625258538307257],
            [-0.349245621668119, 51.62690592166534],
            [-0.345129773688049, 51.628327185759574],
            [-0.339913334357077, 51.630720085754298],
            [-0.33830753788097, 51.629469046036064],
            [-0.333112954618927, 51.6333807257583],
            [-0.332995252374475, 51.633832257962453],
            [-0.332479725008396, 51.633823139210648],
            [-0.330006839537504, 51.63533194211918],
            [-0.326570190707164, 51.636715459528787],
            [-0.31981673671474, 51.638216918107098],
            [-0.318087174809531, 51.639928537926735],
            [-0.316696245502833, 51.640531761236971],
            [-0.312342465849337, 51.638957687548455],
            [-0.305401092762215, 51.63710424520233],
            [-0.304482780394745, 51.636347337196732],
            [-0.305597677157911, 51.634117191135864],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000016",
        LAD13CDO: "00AR",
        LAD13NM: "Havering",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.224060102665074, 51.631734464913642],
            [0.237324076158979, 51.624070507470414],
            [0.252767745721984, 51.617426149243379],
            [0.257665561075275, 51.614180033487798],
            [0.259022989658566, 51.612281595152915],
            [0.263231329206801, 51.609189695441714],
            [0.264535513467253, 51.608318305681038],
            [0.264298903442432, 51.607830060298653],
            [0.263089093624244, 51.607317419623122],
            [0.26165039430385, 51.607458464944102],
            [0.258053073341663, 51.606505244146689],
            [0.257972450490163, 51.604909657838618],
            [0.257129714818035, 51.604923171679644],
            [0.256599278656525, 51.603604223205096],
            [0.255139031618287, 51.602667356134667],
            [0.254669880878659, 51.602729428820219],
            [0.25389030233245, 51.601917957478761],
            [0.253982665170995, 51.601596036574506],
            [0.254789971938049, 51.601335021810087],
            [0.257887311521207, 51.60127277066141],
            [0.258224962018516, 51.600918248474756],
            [0.260848450426015, 51.600387513310693],
            [0.26437135086122, 51.59976652480961],
            [0.26985186624805, 51.599572513964951],
            [0.270312192811005, 51.597308185087421],
            [0.269308243584922, 51.595272722088311],
            [0.270104196782504, 51.59151626984147],
            [0.270900377597453, 51.590285020000429],
            [0.272071496927619, 51.587744327872272],
            [0.273813118993403, 51.586859858855064],
            [0.282001656571905, 51.578829356012612],
            [0.285065264810989, 51.574126659962339],
            [0.286724800825904, 51.573462119855677],
            [0.287137867260392, 51.572548475428462],
            [0.286677043439061, 51.571504380613987],
            [0.29017356590092, 51.564495391260856],
            [0.290261744473168, 51.564297622612614],
            [0.31300694726663, 51.565816343565736],
            [0.31526651413674, 51.557833623196693],
            [0.316472223516777, 51.557683917282056],
            [0.318407016345853, 51.556778765048982],
            [0.318868615908104, 51.555189563405484],
            [0.32202502501832, 51.55419724286854],
            [0.322277427973077, 51.552780337163696],
            [0.32512901392156, 51.553056590541949],
            [0.327787589117016, 51.550993013541181],
            [0.327950406235863, 51.548559856393588],
            [0.329439355079814, 51.546562612448589],
            [0.328871435337901, 51.545835558848715],
            [0.33141679671763, 51.54594147210431],
            [0.333873969482338, 51.542492321916654],
            [0.333304739332969, 51.541260803378108],
            [0.333813176573862, 51.54116345463617],
            [0.333995675456271, 51.540502428644004],
            [0.331400870422155, 51.540044128234129],
            [0.33156186145412, 51.540795447518427],
            [0.322412943712313, 51.540516515983086],
            [0.310498634788642, 51.53965017516245],
            [0.310481765845061, 51.539345642996878],
            [0.310011753463539, 51.539291050274429],
            [0.300694571099539, 51.538754740668963],
            [0.300830509627718, 51.538133352907067],
            [0.297176997712912, 51.537660888420234],
            [0.297726621649182, 51.535317328180696],
            [0.290411521818511, 51.534659130990313],
            [0.290496451842224, 51.534483906906473],
            [0.28804710786326, 51.534552399535286],
            [0.281733872037584, 51.533940661581042],
            [0.276312067719493, 51.53292728507914],
            [0.276196233597353, 51.533177740328718],
            [0.274931469961636, 51.532942381486428],
            [0.270678083455984, 51.533092253880532],
            [0.26531923949042, 51.532149096150931],
            [0.26535855425361, 51.524954834016683],
            [0.265642711159276, 51.524609409097856],
            [0.266723436229318, 51.524512085179232],
            [0.266610053994949, 51.522954888543957],
            [0.264981214031776, 51.522900025493307],
            [0.2640073993406, 51.521083355988836],
            [0.263654809876295, 51.517868865441258],
            [0.26227980971173, 51.517929572347541],
            [0.260274973815821, 51.518370218343136],
            [0.25890003964057, 51.517762703739692],
            [0.253806308799203, 51.51788499130177],
            [0.252304833516344, 51.52478898203448],
            [0.251508192795692, 51.525969769930391],
            [0.250824011889098, 51.528817491301702],
            [0.248936812479415, 51.528672935716166],
            [0.248854300589776, 51.527408306285359],
            [0.248262965898683, 51.527550041892376],
            [0.246296738788239, 51.526531043063052],
            [0.247062015575696, 51.52509099548935],
            [0.245809514938548, 51.523515135584702],
            [0.242828091149623, 51.524391451213155],
            [0.241010413342411, 51.522605121083259],
            [0.24069667554376, 51.521206412842027],
            [0.244271542893294, 51.520470776021611],
            [0.244637719330641, 51.52014901345698],
            [0.244200507809394, 51.519758991760469],
            [0.244877816199149, 51.518758598571033],
            [0.243908587862597, 51.518336491701184],
            [0.240167358960483, 51.519350481984517],
            [0.237148080481766, 51.519333476178836],
            [0.241114385555141, 51.513742348690407],
            [0.239946235225123, 51.513052422984529],
            [0.240278840356879, 51.510754655615564],
            [0.240573624822056, 51.510506212769101],
            [0.241474207169496, 51.510620299541635],
            [0.241891212664021, 51.507959394306354],
            [0.239970773499867, 51.507706520409187],
            [0.23981860238309, 51.507277763392757],
            [0.233413474684843, 51.506669702661874],
            [0.228928192334248, 51.506803607801324],
            [0.226604616453897, 51.506585156778755],
            [0.226367935205407, 51.50592776757626],
            [0.225992333974502, 51.505879141853292],
            [0.226585963660038, 51.504488348274556],
            [0.229936884096718, 51.499365215966336],
            [0.227879186519702, 51.49885935595173],
            [0.224269537714279, 51.497312745131204],
            [0.2235780235917, 51.497905908581281],
            [0.221625399387386, 51.497809825517486],
            [0.216997101484581, 51.496582654291586],
            [0.214993946638832, 51.496482920107169],
            [0.214128145987508, 51.49603883231709],
            [0.213515097063884, 51.495012617994092],
            [0.213045858251874, 51.495021475541229],
            [0.21190386205651, 51.491043848362871],
            [0.210525360356444, 51.490242492215749],
            [0.20957821892557, 51.489751342986651],
            [0.208723142932158, 51.490002170948337],
            [0.208376928176838, 51.489664261593397],
            [0.207216517957545, 51.489528735564697],
            [0.201081956355771, 51.489153045025304],
            [0.196321127584178, 51.488135290668794],
            [0.191723211730782, 51.488107121087232],
            [0.189147752945729, 51.48768850270033],
            [0.187656366142756, 51.487858428208959],
            [0.187388137156718, 51.488604451668721],
            [0.184721725875454, 51.490682075382587],
            [0.183761323764027, 51.492349271909355],
            [0.183366626328059, 51.495032920118227],
            [0.182439742605005, 51.496499836579062],
            [0.183522286930402, 51.497023754706554],
            [0.182994639011376, 51.497167574065088],
            [0.183292786768027, 51.497738469783492],
            [0.182858871729483, 51.497823887658754],
            [0.1828878008101, 51.49861016206895],
            [0.182278652810563, 51.498918335573144],
            [0.182402039751622, 51.499450219021988],
            [0.180216150734369, 51.501288567873807],
            [0.180231404339114, 51.502211857250288],
            [0.178550750824607, 51.505281800186303],
            [0.173756413326809, 51.50818645161808],
            [0.172099269845382, 51.508370936916705],
            [0.166223114815497, 51.510664005715327],
            [0.16522571179383, 51.511117681594705],
            [0.164850935454417, 51.511693850540986],
            [0.163770825400669, 51.511649935344046],
            [0.163546977108919, 51.511913959787869],
            [0.162469730128996, 51.511626272593588],
            [0.15989781238199, 51.51176180098259],
            [0.15942616647105, 51.511936855139638],
            [0.159534355591541, 51.512335046031502],
            [0.158692832711478, 51.512185969917695],
            [0.15949128192647, 51.515477051234527],
            [0.162238025283493, 51.52131047834407],
            [0.162939381363453, 51.52388209651329],
            [0.164443688979418, 51.527058503090579],
            [0.165983902311641, 51.528799865218467],
            [0.167974860849159, 51.530739712576782],
            [0.170762207933664, 51.532625221489695],
            [0.173781456552521, 51.537405667884357],
            [0.175007140495793, 51.538493565166569],
            [0.177256673422249, 51.539488708066472],
            [0.178117153831574, 51.539569855577604],
            [0.179199830204626, 51.540287161186747],
            [0.180364574596956, 51.544108168548597],
            [0.182564615351919, 51.545838848574014],
            [0.186012165286049, 51.547563333058925],
            [0.187461160774958, 51.550197311053694],
            [0.189581250536317, 51.551452730552548],
            [0.190179643485943, 51.552678978406277],
            [0.187883979796211, 51.554078839800958],
            [0.188038549192308, 51.554353836341114],
            [0.187531815799184, 51.554526057533714],
            [0.18757376011203, 51.555129595620706],
            [0.18689100265931, 51.555782617076702],
            [0.185870276952739, 51.556337615071406],
            [0.184742050756103, 51.556426078300149],
            [0.185019130565745, 51.55681930129083],
            [0.18440153411778, 51.557960305169537],
            [0.184766351825869, 51.55985280188581],
            [0.182618724541129, 51.561311848749114],
            [0.183983425379602, 51.563867397957736],
            [0.185121207402277, 51.564786863352651],
            [0.185153048566346, 51.565511093519035],
            [0.183480519262556, 51.564633956222671],
            [0.178632594325635, 51.564939910361396],
            [0.176272756973122, 51.564713923635743],
            [0.17338798581357, 51.565098336205736],
            [0.168321004851744, 51.563532018244345],
            [0.166340496810903, 51.563270043911167],
            [0.161869542106036, 51.561606111226681],
            [0.154275567311339, 51.566142384141656],
            [0.151436540558821, 51.567990341054106],
            [0.150359302399351, 51.567821241196278],
            [0.148749101059931, 51.568620525187548],
            [0.146760425496123, 51.568769339519868],
            [0.146642230549089, 51.569415385660278],
            [0.149752256635097, 51.569696571377811],
            [0.149290272034471, 51.570273379588151],
            [0.148960850037276, 51.573232644792682],
            [0.147896130828547, 51.573117249063877],
            [0.148110980829351, 51.575623201143564],
            [0.146983553526446, 51.575678908358327],
            [0.147448285678205, 51.580791789485787],
            [0.148383183721536, 51.580752193839324],
            [0.150928253019761, 51.583755017509567],
            [0.15119848013302, 51.589124143080504],
            [0.150572209079581, 51.591284892940003],
            [0.151130787585308, 51.595437403744654],
            [0.149814830335198, 51.59707571822274],
            [0.148059595501196, 51.596353367606191],
            [0.148152759685322, 51.59676712742737],
            [0.147657544517277, 51.596798673188047],
            [0.148179718673996, 51.598959965848444],
            [0.148954599181678, 51.60154828061021],
            [0.1494850011781, 51.602902766821536],
            [0.148586795112627, 51.604493843833552],
            [0.148680520749221, 51.60868094613253],
            [0.147468566188391, 51.612348781949436],
            [0.145475265476092, 51.61455338241317],
            [0.14447052286024, 51.616680537644839],
            [0.138156906432108, 51.623542949725994],
            [0.151045901248467, 51.62324450989729],
            [0.15551748525551, 51.623337837983257],
            [0.162502680470513, 51.622574467912898],
            [0.162774416135268, 51.622789783348985],
            [0.165813250932475, 51.622376739226155],
            [0.168873652552486, 51.621417357707053],
            [0.175276190407524, 51.623584835012551],
            [0.180369508956571, 51.624469645111326],
            [0.180584670170579, 51.625732727856452],
            [0.182470784717506, 51.625805587045718],
            [0.185904071328404, 51.62437030385756],
            [0.186240989324643, 51.624556474235966],
            [0.18596941660459, 51.625519261721287],
            [0.188343802484066, 51.625410271132758],
            [0.189372869000191, 51.624487307802482],
            [0.189786736460049, 51.625100987984382],
            [0.194747361716966, 51.625711573807223],
            [0.196959546275582, 51.625564973950652],
            [0.200283844292244, 51.624932563652074],
            [0.20358663574063, 51.625170065046916],
            [0.208435084922605, 51.626679660150828],
            [0.21222350835632, 51.626976126772092],
            [0.212081900898745, 51.627183831368882],
            [0.217519513128998, 51.629090222811634],
            [0.22204934178643, 51.63012326467009],
            [0.224060102665074, 51.631734464913642],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000017",
        LAD13CDO: "00AS",
        LAD13NM: "Hillingdon",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.494169216726391, 51.631048940636838],
            [-0.493568623658009, 51.630554771284103],
            [-0.491557673027208, 51.630763645303389],
            [-0.489047855054977, 51.627591377178476],
            [-0.487879231876784, 51.626759861235101],
            [-0.476712374568089, 51.621898318110361],
            [-0.468926119606649, 51.616702167140126],
            [-0.463261612966334, 51.614334295148112],
            [-0.457151951867813, 51.612291203518268],
            [-0.455368876874098, 51.612698475732579],
            [-0.449191456834567, 51.615160885184331],
            [-0.448131415657314, 51.61591929402649],
            [-0.445724621528757, 51.619525633756865],
            [-0.443616026905427, 51.619513886190987],
            [-0.440596701206257, 51.620069994378973],
            [-0.438303626500947, 51.619919022054745],
            [-0.432751615247183, 51.618345166910373],
            [-0.428417106352282, 51.617748601348765],
            [-0.426502955808749, 51.617003598366495],
            [-0.420924202791033, 51.616929595994165],
            [-0.416973106683096, 51.616488829154797],
            [-0.412006890786743, 51.614179137918413],
            [-0.411022495738901, 51.613788151362073],
            [-0.404071972353939, 51.613180423856996],
            [-0.403237496197955, 51.609095661255637],
            [-0.401782155780174, 51.606616519560035],
            [-0.402372988590945, 51.606117407687321],
            [-0.402529801458532, 51.605187960540597],
            [-0.402359430766071, 51.601936796512554],
            [-0.401281959924963, 51.599683093283922],
            [-0.399967481178113, 51.598219252531713],
            [-0.400333411272124, 51.597420345271594],
            [-0.39770094634771, 51.596856604205009],
            [-0.396507160843815, 51.595086840081571],
            [-0.396364702640183, 51.59359759754598],
            [-0.395488823402258, 51.591618151788722],
            [-0.395489733012057, 51.590199207029663],
            [-0.394471862372761, 51.590448757790377],
            [-0.392033415864405, 51.588328304880392],
            [-0.391034201537957, 51.583065002860813],
            [-0.39104844630449, 51.581721772313621],
            [-0.391572379305191, 51.581558102874794],
            [-0.388413644811804, 51.577254292843861],
            [-0.387513412615218, 51.575287932898632],
            [-0.388075927679157, 51.574970144263482],
            [-0.385708236374468, 51.572146434636153],
            [-0.386460814870126, 51.571554309404803],
            [-0.385934841184102, 51.570963485361276],
            [-0.383297501886442, 51.567249386658496],
            [-0.382399280979515, 51.564709309229826],
            [-0.382503582365481, 51.563721607647132],
            [-0.381969727838047, 51.563072206592842],
            [-0.382381349337002, 51.562897138626141],
            [-0.380309142136361, 51.560676261898294],
            [-0.380109892504554, 51.559584558163344],
            [-0.380292700099819, 51.559108697376018],
            [-0.377808037500313, 51.554986525822798],
            [-0.377688830020779, 51.554688135389505],
            [-0.38526952296332, 51.551170644829121],
            [-0.393944433477066, 51.548697256130197],
            [-0.394986560701685, 51.548756476737715],
            [-0.395008170569931, 51.548019413152765],
            [-0.394888925723486, 51.54763741250872],
            [-0.39872071943311, 51.547891192773335],
            [-0.397886060458253, 51.545662326456721],
            [-0.396557352533091, 51.545962499623919],
            [-0.396242227260168, 51.545531963093204],
            [-0.395272786164929, 51.542547688402209],
            [-0.397673024014702, 51.542325113792799],
            [-0.404852554033425, 51.542506569283432],
            [-0.416150586618465, 51.540241629998704],
            [-0.418534017945101, 51.540247702999253],
            [-0.419266728439141, 51.540450908568324],
            [-0.419507605209392, 51.540241039082098],
            [-0.418161098819657, 51.539150126634276],
            [-0.419590043964, 51.538693697257074],
            [-0.419380273016092, 51.53825565015503],
            [-0.417554051656372, 51.537860555497026],
            [-0.417480241526036, 51.536662700954693],
            [-0.415339455704386, 51.53656726368817],
            [-0.414858243108749, 51.536016736108401],
            [-0.413851007818539, 51.535749543687835],
            [-0.41348118463255, 51.534691558391359],
            [-0.412269317783468, 51.53450521214851],
            [-0.41183268922805, 51.534083863332697],
            [-0.411084496998463, 51.534287746955343],
            [-0.396826539457457, 51.532023776991437],
            [-0.389278931109793, 51.530799066826646],
            [-0.387115583307842, 51.529556294848682],
            [-0.38663760668965, 51.530394092029994],
            [-0.380990606602968, 51.529414377814405],
            [-0.376377283873975, 51.528886622408471],
            [-0.37618124024741, 51.524914736764408],
            [-0.381776446109735, 51.518634540537477],
            [-0.38344258844633, 51.517495715543191],
            [-0.384988795102393, 51.516363308923218],
            [-0.389022661255376, 51.514735429761018],
            [-0.390442911589318, 51.513203765000796],
            [-0.392861317336739, 51.507793920775924],
            [-0.393586312903934, 51.506969371757073],
            [-0.399869945206838, 51.504430425228115],
            [-0.402782156656375, 51.503641932399638],
            [-0.403889411591448, 51.502921439772614],
            [-0.4069052387918, 51.499693760920678],
            [-0.409285305911931, 51.500315056001618],
            [-0.410659880671356, 51.498199831781761],
            [-0.410566003941959, 51.496218467805399],
            [-0.411408290651821, 51.49498894955989],
            [-0.411889996435885, 51.492444372844048],
            [-0.411951005552668, 51.492098098158635],
            [-0.412637842556699, 51.488580635570507],
            [-0.413365183008292, 51.48725422899296],
            [-0.413985043120019, 51.487197869136992],
            [-0.414535526356137, 51.486525497984616],
            [-0.414633786798186, 51.486225585808597],
            [-0.414025681123878, 51.486068092324622],
            [-0.414686722454632, 51.485654393988156],
            [-0.41684141751172, 51.482534413067157],
            [-0.416476764238144, 51.482085272224062],
            [-0.416828646733157, 51.480865277448338],
            [-0.415031555019249, 51.477203631987585],
            [-0.414950465700795, 51.476053324155387],
            [-0.415844864961022, 51.4740115756846],
            [-0.414747538792972, 51.473391566661576],
            [-0.412978549940363, 51.471422609808158],
            [-0.411333236643038, 51.470457040611294],
            [-0.411154524936736, 51.469881810999134],
            [-0.419679350604007, 51.46730292383014],
            [-0.420290454076113, 51.466783311575355],
            [-0.422097913239073, 51.466555864073783],
            [-0.433598901910844, 51.461739138635629],
            [-0.436633773874706, 51.457684561967007],
            [-0.434747628480663, 51.457110868937008],
            [-0.435061552637492, 51.456854283293048],
            [-0.441479880449679, 51.456807586842459],
            [-0.444004029040628, 51.453266683731073],
            [-0.449421975958371, 51.45384472532475],
            [-0.453783446240112, 51.454853705138326],
            [-0.453988913062306, 51.455460694494839],
            [-0.455432759031336, 51.455625398265852],
            [-0.455370792326768, 51.455286472989307],
            [-0.455779274485108, 51.455252287635581],
            [-0.458660978484459, 51.4563153225699],
            [-0.461299292550285, 51.457215029982336],
            [-0.469607610877856, 51.457983796807021],
            [-0.473580289327365, 51.458633565096569],
            [-0.475896911329118, 51.460085372560087],
            [-0.476138498485309, 51.460957162186446],
            [-0.477140731224681, 51.461336166764241],
            [-0.48588218546573, 51.461313587393448],
            [-0.489629514970375, 51.461784550501143],
            [-0.492229039743598, 51.462971706911759],
            [-0.493881228400807, 51.462722274369618],
            [-0.497005119509679, 51.465875459790134],
            [-0.498137182550238, 51.466430395979181],
            [-0.500368806023371, 51.467128875800718],
            [-0.503756255634119, 51.467705384988989],
            [-0.506298572136106, 51.467711719162232],
            [-0.507336632899738, 51.466916539101184],
            [-0.508800752971628, 51.467094328850223],
            [-0.510296188594514, 51.467504496623185],
            [-0.510173001012766, 51.468767233567654],
            [-0.509720591528107, 51.469175125642252],
            [-0.50875889651583, 51.469349937429989],
            [-0.50769008040393, 51.470115963527896],
            [-0.505961504371417, 51.472477782665102],
            [-0.503514128545482, 51.480518860655209],
            [-0.501974373020993, 51.482856951810668],
            [-0.498718898820966, 51.486078584624337],
            [-0.499016026357253, 51.486723529728664],
            [-0.499695141570975, 51.486832931509809],
            [-0.499043627265007, 51.487856896765535],
            [-0.499188409038387, 51.488579921719854],
            [-0.498326892527847, 51.488453794380092],
            [-0.497997409222034, 51.488881199522702],
            [-0.498648980040023, 51.489260019557314],
            [-0.497401337169944, 51.489881586776882],
            [-0.496670015951168, 51.489825455820181],
            [-0.496780809851071, 51.490486002037677],
            [-0.496005467946672, 51.490541704909099],
            [-0.49496158836088, 51.491316028377881],
            [-0.495368282369306, 51.492045116162664],
            [-0.494849752733743, 51.492656225005156],
            [-0.491464148596695, 51.493062337034083],
            [-0.49013636118066, 51.49457751772308],
            [-0.490044351706669, 51.494746285269009],
            [-0.489663034486554, 51.495474237522416],
            [-0.488693038141097, 51.495968002045977],
            [-0.48860607154548, 51.496768083740385],
            [-0.489488454960528, 51.497574358339833],
            [-0.488867925905293, 51.49856449587552],
            [-0.489592205534406, 51.498619684920222],
            [-0.489763746341165, 51.498965394480379],
            [-0.488764681600654, 51.500205135036914],
            [-0.487477678808658, 51.500348606528433],
            [-0.48731274688137, 51.500978628764948],
            [-0.486186931306266, 51.500909252081918],
            [-0.486260366132195, 51.501823803740059],
            [-0.485168266774756, 51.502605511248419],
            [-0.484679290232015, 51.503577542686585],
            [-0.485179244769793, 51.503925702535881],
            [-0.48506482068844, 51.505073422968032],
            [-0.484667723220249, 51.505620412335979],
            [-0.483941400445326, 51.505623616063879],
            [-0.483680267932309, 51.506458310037978],
            [-0.483194196890283, 51.506646256295639],
            [-0.483232231699707, 51.507236633776451],
            [-0.483996522993064, 51.507609797241948],
            [-0.483532162230843, 51.508098363568003],
            [-0.485837505072917, 51.509800685001125],
            [-0.485556225589911, 51.510026353769184],
            [-0.489806740747066, 51.511525291980121],
            [-0.489941767752198, 51.513412683109259],
            [-0.489581154456575, 51.514076156749397],
            [-0.491219717764587, 51.515255430784279],
            [-0.492350064705551, 51.517075584712792],
            [-0.491651465013639, 51.51889560475874],
            [-0.490387157762457, 51.520167914897172],
            [-0.49119367144302, 51.521278028302497],
            [-0.490542750180736, 51.522480894906096],
            [-0.49027718681033, 51.524583434308539],
            [-0.490878565235983, 51.526232234684294],
            [-0.488998009059434, 51.528607128095508],
            [-0.489686574826442, 51.530061028130262],
            [-0.490134327435878, 51.532040561250035],
            [-0.49120955519413, 51.533271026743677],
            [-0.490502656311523, 51.534155750155719],
            [-0.491228111112085, 51.534323342635673],
            [-0.491616570397725, 51.534915522316282],
            [-0.493349564251457, 51.535835201709951],
            [-0.495510512897011, 51.538427479459479],
            [-0.493605735916332, 51.541003555859554],
            [-0.491827694968013, 51.541571495945774],
            [-0.492118906083411, 51.542446573286639],
            [-0.49105119927979, 51.544556781131867],
            [-0.49081493059022, 51.545072586530601],
            [-0.491595620621935, 51.545363178550396],
            [-0.491375834290895, 51.546121983471046],
            [-0.489394585773585, 51.546330288218101],
            [-0.48951107656295, 51.547211214879596],
            [-0.488169817826551, 51.547428635109441],
            [-0.487060269174181, 51.549660559747643],
            [-0.48540829961247, 51.55071919909912],
            [-0.484248789287957, 51.551453268546162],
            [-0.482685932338003, 51.551520293605336],
            [-0.47890315857393, 51.55483436339437],
            [-0.477805380016945, 51.554836314072233],
            [-0.477350943806913, 51.555270131796938],
            [-0.476616866245978, 51.558029270169428],
            [-0.477007933747338, 51.559623248663954],
            [-0.477539957792146, 51.560280280155965],
            [-0.48044211216688, 51.560812460143687],
            [-0.481061751280864, 51.560703584957345],
            [-0.48077345586816, 51.561778903114956],
            [-0.482814840153159, 51.562819619381109],
            [-0.482266270502218, 51.563913156538931],
            [-0.483242658098865, 51.565137911896606],
            [-0.482599865299645, 51.565723978652073],
            [-0.484783724851264, 51.56686901065342],
            [-0.484033903159006, 51.57119350477231],
            [-0.485082346639099, 51.572296881070805],
            [-0.485554919687896, 51.573838824309341],
            [-0.484562842677293, 51.574293605808663],
            [-0.48401516720918, 51.575053555696236],
            [-0.48426963404382, 51.57534278982817],
            [-0.485189734259831, 51.575360963343229],
            [-0.487739430309924, 51.576239097097613],
            [-0.489231749050908, 51.577759089621452],
            [-0.489478124052097, 51.578380913838323],
            [-0.488574100604928, 51.579616473201298],
            [-0.489624107401419, 51.581770097169226],
            [-0.491058568154336, 51.582821733191508],
            [-0.494723204734525, 51.584613243498751],
            [-0.495133854593104, 51.585421497965207],
            [-0.494962521181099, 51.586544204769588],
            [-0.495762334249934, 51.588113675796635],
            [-0.497230459662088, 51.589336513614292],
            [-0.497933382092105, 51.590958680215266],
            [-0.498843038722936, 51.591215799276497],
            [-0.49864149801675, 51.591899313067273],
            [-0.499563254752249, 51.592140395521398],
            [-0.49880344289446, 51.59307394394245],
            [-0.498589442458536, 51.594441590851254],
            [-0.499454571030912, 51.595395016512967],
            [-0.500616930158387, 51.5996873697501],
            [-0.500902622382996, 51.600175687738989],
            [-0.500353254251854, 51.60124861120611],
            [-0.499124606296576, 51.601375882122959],
            [-0.497658150977947, 51.600876950741984],
            [-0.496707443260982, 51.601376425476175],
            [-0.496327635962367, 51.602427223211393],
            [-0.497185215654531, 51.603218711058481],
            [-0.497284203231005, 51.604900584273594],
            [-0.498591747442714, 51.608294710067931],
            [-0.49925613957319, 51.608861608169356],
            [-0.498337633283375, 51.609474806990669],
            [-0.49899715687576, 51.610145951271235],
            [-0.498966999364596, 51.611104112444899],
            [-0.497914574024441, 51.613235242688972],
            [-0.497907242578903, 51.614244949624123],
            [-0.497064759810825, 51.614913962331826],
            [-0.495430319318606, 51.618915145206856],
            [-0.496829747101164, 51.620497675543547],
            [-0.499281279116958, 51.621106321257834],
            [-0.500204919730076, 51.622184572014461],
            [-0.500045980800648, 51.623675209861531],
            [-0.498362576649622, 51.624781286319411],
            [-0.497654440820193, 51.626669534519884],
            [-0.500328150649726, 51.628919335492682],
            [-0.499732262366761, 51.62933254744339],
            [-0.499282728309085, 51.630476875568554],
            [-0.497247039315678, 51.631653965697289],
            [-0.496154613393375, 51.631700226013301],
            [-0.495555476787039, 51.631161125829259],
            [-0.494169216726391, 51.631048940636838],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000018",
        LAD13CDO: "00AT",
        LAD13NM: "Hounslow",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-0.32036311364311, 51.46739077827695],
              [-0.32108537795902, 51.465952450615823],
              [-0.321287168036286, 51.469476753453876],
              [-0.320474003851081, 51.470487541495338],
              [-0.31972401642182, 51.470679125033037],
              [-0.32036311364311, 51.46739077827695],
            ],
          ],
          [
            [
              [-0.297624184724515, 51.485218469690331],
              [-0.298676368211654, 51.484114188752308],
              [-0.299030837022316, 51.48469034652215],
              [-0.297798265467033, 51.485223694357934],
              [-0.297624184724515, 51.485218469690331],
            ],
          ],
          [
            [
              [-0.280740588822522, 51.484963165981419],
              [-0.280572436135571, 51.484685533398938],
              [-0.281842481376729, 51.485344406530807],
              [-0.280740588822522, 51.484963165981419],
            ],
          ],
          [
            [
              [-0.244828284429129, 51.488004747759412],
              [-0.247218315977178, 51.48682921536561],
              [-0.245679300023742, 51.487898781773303],
              [-0.24448726839673, 51.488295494104563],
              [-0.244828284429129, 51.488004747759412],
            ],
          ],
          [
            [
              [-0.280783594519346, 51.502850537890829],
              [-0.277896080966695, 51.499488192383744],
              [-0.274260712677975, 51.497285577696317],
              [-0.272481269067441, 51.496147921591039],
              [-0.27142572299734, 51.494803293869687],
              [-0.268923163715445, 51.49401102067106],
              [-0.255434489428101, 51.494931596855409],
              [-0.255512844773796, 51.496275328279616],
              [-0.255118328274577, 51.4963449954586],
              [-0.255026699712879, 51.496971301870332],
              [-0.252786630230702, 51.499905443702296],
              [-0.253083914262199, 51.501399912688349],
              [-0.252142481023012, 51.500661998200798],
              [-0.24923459217952, 51.49694972422607],
              [-0.245292565799815, 51.498384449899213],
              [-0.245004510902418, 51.49789454953001],
              [-0.244420592393099, 51.49794336161419],
              [-0.245609219581596, 51.490212383171425],
              [-0.245434316492458, 51.489477783290425],
              [-0.244562393969254, 51.48870217708221],
              [-0.247698510467717, 51.487303098512228],
              [-0.249733523717763, 51.485691472754404],
              [-0.251466220036477, 51.48192611491401],
              [-0.251553671319259, 51.477379946891375],
              [-0.253532909467646, 51.474008503889991],
              [-0.255234855703358, 51.472576167195662],
              [-0.258811404391651, 51.471483695721304],
              [-0.262439474012009, 51.471342379273239],
              [-0.265656467954138, 51.471864806807488],
              [-0.269148829670946, 51.473559326170481],
              [-0.272885470629186, 51.476723101141722],
              [-0.275493320213561, 51.481496883554748],
              [-0.277107234261662, 51.483498035288925],
              [-0.281588180749965, 51.486247113278807],
              [-0.287196283408102, 51.487837179653582],
              [-0.287469939627916, 51.487683809533692],
              [-0.292754525591638, 51.4874560201619],
              [-0.298986769335788, 51.485255327560537],
              [-0.300462415912411, 51.483955743290224],
              [-0.29999299133665, 51.484146771338835],
              [-0.299781574449797, 51.483286733107889],
              [-0.300534812956108, 51.48379043360142],
              [-0.301192360703017, 51.483536484479245],
              [-0.300517692543855, 51.483592353287101],
              [-0.300229674170947, 51.483204204550198],
              [-0.30427069178485, 51.482395843888007],
              [-0.304159392947574, 51.482212588519943],
              [-0.3005612239991, 51.482847516386158],
              [-0.300108294133764, 51.482711461477024],
              [-0.300769831439441, 51.481846091100167],
              [-0.303565226424596, 51.479905543936752],
              [-0.304144904231094, 51.479613583196368],
              [-0.304482680676757, 51.479825292159049],
              [-0.304503425724953, 51.479149365534091],
              [-0.305250285894394, 51.478309483150255],
              [-0.305624138032465, 51.478324777254976],
              [-0.306066708946632, 51.477145976163207],
              [-0.306981049448417, 51.476253648571031],
              [-0.31208761275753, 51.473787819418078],
              [-0.319108604926668, 51.471518285286557],
              [-0.32020301404784, 51.471319050890727],
              [-0.321501168152552, 51.470511241915474],
              [-0.322142081258121, 51.470506026848902],
              [-0.321579034062062, 51.470429626715067],
              [-0.322384488495838, 51.468961009155521],
              [-0.321879886444434, 51.467959232184135],
              [-0.321734532879195, 51.465406914302278],
              [-0.323845944230976, 51.46514756207182],
              [-0.32666973296135, 51.461745614049235],
              [-0.326883599750311, 51.461172254708956],
              [-0.326362733717028, 51.460811420409847],
              [-0.326723010062243, 51.459973976676643],
              [-0.326237602213601, 51.459785402169516],
              [-0.326463995566475, 51.459341712540962],
              [-0.325570286144462, 51.458116779522605],
              [-0.326910815828023, 51.457042442795483],
              [-0.327268659058218, 51.45697920659395],
              [-0.328411978032699, 51.457952302143696],
              [-0.330367840854726, 51.457882153962423],
              [-0.331415740865301, 51.457290086375117],
              [-0.333030241191789, 51.455407567298245],
              [-0.333632073463344, 51.455244368196631],
              [-0.333709553280006, 51.455488263610398],
              [-0.336293163161112, 51.455686816763432],
              [-0.336983559383684, 51.454631015051824],
              [-0.337561682892738, 51.454842441646029],
              [-0.337737028942705, 51.455483386438551],
              [-0.338555150035817, 51.456101967978412],
              [-0.338973053884957, 51.455890273855935],
              [-0.340630536284479, 51.457258103429595],
              [-0.340134045783847, 51.459239288148829],
              [-0.341723905316311, 51.459021688807255],
              [-0.342679722019385, 51.45810719136022],
              [-0.345054372057243, 51.457919521720157],
              [-0.346040928411547, 51.457267108835687],
              [-0.348704666489501, 51.456765113655685],
              [-0.352839418007777, 51.456861942091422],
              [-0.353461211608018, 51.458182660586139],
              [-0.356431372370306, 51.458405979424903],
              [-0.359817851653492, 51.458219600985068],
              [-0.362867498251364, 51.457670526717621],
              [-0.365063635442249, 51.457802784542153],
              [-0.36782898817432, 51.457433060691095],
              [-0.368132000668605, 51.457170205939327],
              [-0.368460877129481, 51.456868144019971],
              [-0.369591542082349, 51.456968400297981],
              [-0.372463306178848, 51.457246596237411],
              [-0.372693973508384, 51.456993516360399],
              [-0.37304540319213, 51.457473188807867],
              [-0.375275527055726, 51.456283853560237],
              [-0.383786805579874, 51.449509765111813],
              [-0.384567604336726, 51.449895506603504],
              [-0.387693296166034, 51.449369318452078],
              [-0.38631832365941, 51.448202080310629],
              [-0.38626653200025, 51.446732019519594],
              [-0.384167444145987, 51.445739128569549],
              [-0.382870802129382, 51.445048622915095],
              [-0.37979021945303, 51.444388315169491],
              [-0.379629800420611, 51.443228782716801],
              [-0.37824431343319, 51.442491135867925],
              [-0.377146849927627, 51.442478646582131],
              [-0.376092620868992, 51.443072830565121],
              [-0.373953184009272, 51.443024291850747],
              [-0.372893788057314, 51.443640856498561],
              [-0.367100170785001, 51.441713271493121],
              [-0.377891871908277, 51.436802191143478],
              [-0.377933765071119, 51.436309989436154],
              [-0.376212970554174, 51.435500233706215],
              [-0.383314113737513, 51.432127330577693],
              [-0.387910554486826, 51.42873217040448],
              [-0.387147504065216, 51.428178535234878],
              [-0.388088757963468, 51.427878548092757],
              [-0.388381807509354, 51.428053432398301],
              [-0.384112345918089, 51.422672070883976],
              [-0.384929772281862, 51.421271533968714],
              [-0.385517765957435, 51.420748184982642],
              [-0.391129894868287, 51.423162468154089],
              [-0.392770395841846, 51.423467334017147],
              [-0.391363513016445, 51.422325787377467],
              [-0.394533201650686, 51.423237907157073],
              [-0.398133713987645, 51.423556038411476],
              [-0.401781221795077, 51.423311778033487],
              [-0.405382989162694, 51.422479581325625],
              [-0.407797119734948, 51.42357799219613],
              [-0.408462794202758, 51.423834322038395],
              [-0.40902802681491, 51.425450726218678],
              [-0.410889873519264, 51.427341892920275],
              [-0.413107459364795, 51.427854823446125],
              [-0.412474067014669, 51.428781450386495],
              [-0.414373791365661, 51.429295446618561],
              [-0.413827497987634, 51.430074885220364],
              [-0.417921753354334, 51.431027648475663],
              [-0.417315568586435, 51.431917799502287],
              [-0.418377584757405, 51.431866489217029],
              [-0.418605118406259, 51.432109654074615],
              [-0.419100018015259, 51.432359122156541],
              [-0.42117521202647, 51.432171273935417],
              [-0.423600946148416, 51.431160809691306],
              [-0.423520071910168, 51.430877363101843],
              [-0.427506875324966, 51.430819406518502],
              [-0.427852693902999, 51.429252187081062],
              [-0.429445983297476, 51.429394941097236],
              [-0.429555653037637, 51.428797521779565],
              [-0.432661010752551, 51.429018030495577],
              [-0.440004436302619, 51.430626694105015],
              [-0.439773473381041, 51.434619801440483],
              [-0.447737929161854, 51.435003393416274],
              [-0.446309671660516, 51.439996777336638],
              [-0.453681239853264, 51.438494512434367],
              [-0.456495977102343, 51.438224968450612],
              [-0.455412129383853, 51.442119638037603],
              [-0.457464601495208, 51.442999129012371],
              [-0.45748239905915, 51.449090727469269],
              [-0.461502308528438, 51.448992476542472],
              [-0.461340571126053, 51.452391227031718],
              [-0.459858947087989, 51.452239587722964],
              [-0.459314736592871, 51.452715322117015],
              [-0.458660978484459, 51.4563153225699],
              [-0.455779274485108, 51.455252287635581],
              [-0.455370792326768, 51.455286472989307],
              [-0.455432759031336, 51.455625398265852],
              [-0.453988913062306, 51.455460694494839],
              [-0.453783446240112, 51.454853705138326],
              [-0.449421975958371, 51.45384472532475],
              [-0.444004029040628, 51.453266683731073],
              [-0.441479880449679, 51.456807586842459],
              [-0.435061552637492, 51.456854283293048],
              [-0.434747628480663, 51.457110868937008],
              [-0.436633773874706, 51.457684561967007],
              [-0.433598901910844, 51.461739138635629],
              [-0.422097913239073, 51.466555864073783],
              [-0.420290454076113, 51.466783311575355],
              [-0.419679350604007, 51.46730292383014],
              [-0.411154524936736, 51.469881810999134],
              [-0.411333236643038, 51.470457040611294],
              [-0.412978549940363, 51.471422609808158],
              [-0.414747538792972, 51.473391566661576],
              [-0.415844864961022, 51.4740115756846],
              [-0.414950465700795, 51.476053324155387],
              [-0.415031555019249, 51.477203631987585],
              [-0.416828646733157, 51.480865277448338],
              [-0.416476764238144, 51.482085272224062],
              [-0.41684141751172, 51.482534413067157],
              [-0.414686722454632, 51.485654393988156],
              [-0.414025681123878, 51.486068092324622],
              [-0.414633786798186, 51.486225585808597],
              [-0.414535526356137, 51.486525497984616],
              [-0.413985043120019, 51.487197869136992],
              [-0.413365183008292, 51.48725422899296],
              [-0.412637842556699, 51.488580635570507],
              [-0.411951005552668, 51.492098098158635],
              [-0.411889996435885, 51.492444372844048],
              [-0.411408290651821, 51.49498894955989],
              [-0.410566003941959, 51.496218467805399],
              [-0.410659880671356, 51.498199831781761],
              [-0.409285305911931, 51.500315056001618],
              [-0.4069052387918, 51.499693760920678],
              [-0.387554300447632, 51.494848666889659],
              [-0.385712065175963, 51.494637184880247],
              [-0.383527505959271, 51.49481841465564],
              [-0.377853081755501, 51.496562803415394],
              [-0.377885476545658, 51.495447309929965],
              [-0.375839995462134, 51.495401916336114],
              [-0.373806849248039, 51.491933295526451],
              [-0.372420322087804, 51.490984259261666],
              [-0.37194798821698, 51.49098040103042],
              [-0.371872526288749, 51.490470389834286],
              [-0.367921686417798, 51.491793094840105],
              [-0.368377627299094, 51.492296712612365],
              [-0.366974631507994, 51.492778955416441],
              [-0.365800858296245, 51.493658238167363],
              [-0.365382019156917, 51.493325981089178],
              [-0.363732405682804, 51.494699481001902],
              [-0.363626717530335, 51.49547853740377],
              [-0.362998185020571, 51.495932869250666],
              [-0.361918189316551, 51.496080550561238],
              [-0.358027763066037, 51.497785041910731],
              [-0.350700245980526, 51.499108407501637],
              [-0.349788254748214, 51.499029036304982],
              [-0.342113923552105, 51.4960405699902],
              [-0.33544621253242, 51.496212299249628],
              [-0.331260891374223, 51.495549438512221],
              [-0.329975908372573, 51.495075237156165],
              [-0.327418222261741, 51.496036937463785],
              [-0.326158548432247, 51.495029809932795],
              [-0.32487080686535, 51.495929541082219],
              [-0.323434179149631, 51.495899121384589],
              [-0.319211421848077, 51.494222756908094],
              [-0.315788611348789, 51.495903779417844],
              [-0.315291491320451, 51.495554930540159],
              [-0.315732804163877, 51.495346352125686],
              [-0.314475427278835, 51.49436251056342],
              [-0.315103799502455, 51.49396418662409],
              [-0.314414080944087, 51.49344800673245],
              [-0.313936227549495, 51.493593108443591],
              [-0.311924673728756, 51.491969828620221],
              [-0.308403381089442, 51.493584474099407],
              [-0.305440815357069, 51.494538058008999],
              [-0.305850991535, 51.496180591757351],
              [-0.301355667389131, 51.495226225553701],
              [-0.301110564813813, 51.494417859389394],
              [-0.297553480130457, 51.494889633201588],
              [-0.299945607033527, 51.49781267798808],
              [-0.300718341203409, 51.499348080169298],
              [-0.294050054059212, 51.501562311359081],
              [-0.28681058593727, 51.50066276184797],
              [-0.285072792264004, 51.500975477619832],
              [-0.280783594519346, 51.502850537890829],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000019",
        LAD13CDO: "00AU",
        LAD13NM: "Islington",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.117585304759842, 51.574147906716462],
            [-0.117184433994835, 51.573825840445345],
            [-0.115585326459636, 51.572572701998148],
            [-0.116127865036919, 51.5721902415671],
            [-0.115405684924706, 51.571891785971829],
            [-0.115166078033205, 51.570672157493597],
            [-0.110707657010046, 51.568605930174854],
            [-0.106204650274396, 51.565538847636731],
            [-0.104871505418489, 51.56463516143306],
            [-0.104391151200697, 51.564771281754247],
            [-0.104510745725379, 51.564705770205393],
            [-0.098587663744929, 51.560837588650124],
            [-0.096032221856618, 51.561226889250001],
            [-0.091702809133225, 51.560222234763678],
            [-0.09042032839195, 51.557671778407247],
            [-0.090393381400696, 51.554423246661798],
            [-0.090403381449438, 51.554150037168192],
            [-0.089299699427906, 51.553740894136887],
            [-0.088588530153753, 51.553947828202006],
            [-0.087552389015126, 51.552823065607797],
            [-0.085504438537441, 51.552161987223734],
            [-0.079461339020132, 51.551664850293697],
            [-0.076635743851724, 51.547934321924785],
            [-0.076357679896338, 51.546422614067374],
            [-0.076839429057005, 51.546079803417193],
            [-0.083838313779307, 51.546603486431387],
            [-0.083660670751907, 51.545064661050091],
            [-0.086570904166927, 51.537401967197724],
            [-0.08969060078762, 51.5360077110685],
            [-0.097033649990862, 51.532982372770647],
            [-0.095112899162361, 51.530052004902871],
            [-0.095405360753156, 51.529193470372732],
            [-0.088780463453632, 51.527442778032416],
            [-0.087439954240208, 51.525957840894534],
            [-0.086795419156157, 51.525789060963731],
            [-0.085020710590177, 51.526203433705739],
            [-0.084751022308772, 51.52541577905793],
            [-0.085443433824156, 51.524511640782372],
            [-0.08513937277321, 51.523924859018102],
            [-0.084223551192296, 51.523900912035259],
            [-0.085217906072325, 51.520334513123139],
            [-0.086227451482666, 51.518808765269455],
            [-0.090004309824143, 51.519970119053006],
            [-0.089693501449045, 51.520715036569399],
            [-0.092374509863949, 51.52102754779542],
            [-0.092516805496073, 51.521485784313022],
            [-0.094344703917476, 51.521483122963083],
            [-0.09508866726008, 51.523137246696528],
            [-0.096469839081942, 51.522821549498829],
            [-0.096786317502036, 51.523321274956338],
            [-0.09797245268005, 51.522877398970415],
            [-0.097403251532925, 51.521593024828761],
            [-0.097624119100265, 51.521031875416014],
            [-0.097670170070134, 51.520722379476389],
            [-0.100301027326701, 51.520128346608146],
            [-0.10182079708464, 51.519665555451709],
            [-0.105349922689427, 51.518540995513298],
            [-0.107020548548463, 51.522189263792413],
            [-0.108274440412082, 51.522089892452094],
            [-0.108991266860745, 51.523019588492673],
            [-0.112563047621472, 51.523793821304878],
            [-0.114253421202477, 51.525516998530762],
            [-0.112949388385477, 51.526408767953853],
            [-0.115432420816161, 51.527722027598386],
            [-0.116016687479488, 51.529842855163075],
            [-0.119895987298358, 51.530937435416568],
            [-0.122556112454186, 51.530725552727588],
            [-0.122400724190549, 51.536884726018947],
            [-0.12307551991704, 51.538497986702595],
            [-0.125086251139046, 51.541115480243334],
            [-0.126361949582864, 51.545061079694996],
            [-0.127204105025978, 51.547662560997317],
            [-0.129137591530651, 51.548838150931715],
            [-0.130162846970763, 51.549617956152304],
            [-0.131027114570507, 51.551511152635058],
            [-0.134379544509464, 51.553620184759552],
            [-0.135255967592321, 51.554587317864957],
            [-0.137178948938767, 51.555396611253165],
            [-0.138394433693291, 51.556859191284026],
            [-0.139451092871752, 51.558256292398049],
            [-0.141031589763899, 51.560429638390119],
            [-0.140681644226308, 51.564552521987665],
            [-0.142415880417378, 51.569120267654064],
            [-0.140706207729624, 51.569397134397697],
            [-0.13834394530272, 51.570600635992463],
            [-0.13342714118819, 51.57286595614773],
            [-0.131389743249347, 51.573563715982395],
            [-0.127496512591569, 51.574153607624162],
            [-0.124880979959835, 51.574097454383214],
            [-0.123775363554822, 51.574662492346462],
            [-0.119650680395106, 51.575505607650513],
            [-0.118890252276713, 51.574733558545134],
            [-0.119567057173802, 51.574384708172474],
            [-0.117585304759842, 51.574147906716462],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000020",
        LAD13CDO: "00AW",
        LAD13NM: "Kensington and Chelsea",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.227588053489856, 51.53015637314477],
            [-0.227685265613103, 51.529917745173947],
            [-0.225446606209828, 51.530168960660731],
            [-0.223633771449066, 51.529924807029523],
            [-0.217979054678885, 51.528640356700237],
            [-0.216028912103472, 51.52792819255199],
            [-0.215573016541876, 51.526719873509158],
            [-0.212698449195279, 51.526866749910099],
            [-0.207319914519514, 51.52623883797429],
            [-0.20590229335227, 51.525738768424816],
            [-0.203881525613413, 51.523458840393879],
            [-0.201639381896616, 51.52274196233693],
            [-0.200634014382906, 51.52071044240919],
            [-0.202669094390285, 51.520899010354242],
            [-0.203766808500966, 51.520622672028239],
            [-0.201488155883395, 51.517799203765605],
            [-0.200747521370249, 51.517976686282708],
            [-0.199944566212615, 51.516846607165981],
            [-0.19920778593522, 51.514551218936958],
            [-0.195072627438726, 51.515031701839412],
            [-0.194367325654607, 51.512712485792285],
            [-0.19406501704488, 51.511756430565548],
            [-0.192984779662186, 51.511813531046336],
            [-0.192143783005382, 51.509868093442364],
            [-0.187933557407209, 51.510179006836907],
            [-0.184259244509307, 51.501793359043269],
            [-0.180336124126152, 51.501460982287533],
            [-0.179570826214083, 51.497778374855109],
            [-0.173566987978205, 51.498220977982754],
            [-0.168414584262257, 51.499020987324556],
            [-0.167643870887854, 51.498300339342912],
            [-0.165803722728949, 51.499241864270623],
            [-0.165505762824421, 51.498696754689199],
            [-0.162599011220902, 51.500292380938888],
            [-0.161023877674389, 51.501589590019684],
            [-0.15847723443309, 51.502250145753941],
            [-0.157874184174106, 51.499017761532272],
            [-0.156047625632871, 51.497510687777996],
            [-0.155319440841398, 51.495064063389194],
            [-0.156013775505698, 51.494494066751415],
            [-0.15501526222633, 51.493856079632558],
            [-0.155989586322176, 51.493201461878584],
            [-0.154818002228408, 51.491962748664051],
            [-0.155821108312591, 51.491450667015094],
            [-0.155701852809399, 51.491050422903186],
            [-0.155196336239452, 51.490048797850761],
            [-0.155873929044345, 51.489606232462073],
            [-0.150366752639102, 51.486232727701676],
            [-0.150003832567287, 51.485484219393015],
            [-0.167189073788381, 51.483202905826396],
            [-0.171268853025793, 51.482530115542978],
            [-0.173906569317666, 51.481756500604597],
            [-0.175762609275294, 51.48162172635633],
            [-0.177838702982, 51.480265562750226],
            [-0.177549558455565, 51.48007672272449],
            [-0.179308567653273, 51.478000700172458],
            [-0.18082189547545, 51.477933364073948],
            [-0.182100359016098, 51.47739835399473],
            [-0.183880909250943, 51.477418751456035],
            [-0.185374786497862, 51.478602812114232],
            [-0.186549978904703, 51.479871849943493],
            [-0.188281088601267, 51.4809606233949],
            [-0.190794816586318, 51.483164826944495],
            [-0.193621050122526, 51.485813521324836],
            [-0.195987919087366, 51.487372384287127],
            [-0.196448206809708, 51.487205909524391],
            [-0.198222043763706, 51.488064082492677],
            [-0.202387074993054, 51.491668338094804],
            [-0.201835357892801, 51.49185141924572],
            [-0.203027937001033, 51.493321083291299],
            [-0.207388528621719, 51.496148503918519],
            [-0.208001441663969, 51.496022084907679],
            [-0.209922221366654, 51.497777070552424],
            [-0.214133101679672, 51.500770107014731],
            [-0.214966554906713, 51.502301618317738],
            [-0.217008881975458, 51.504462992518057],
            [-0.217040565086282, 51.504500343145914],
            [-0.217990767656138, 51.506146011256838],
            [-0.21596345039068, 51.506401150512431],
            [-0.21614458613873, 51.507518967009041],
            [-0.215202937867884, 51.507646721796924],
            [-0.215817107546801, 51.509114638177117],
            [-0.216841808840275, 51.509444057052853],
            [-0.217209743581386, 51.510392056395453],
            [-0.218053329134683, 51.510230422735226],
            [-0.217888689571522, 51.509759415992406],
            [-0.219286532632631, 51.50957472021603],
            [-0.220268979122356, 51.511728928760725],
            [-0.222604056150626, 51.515642765189718],
            [-0.226563156073996, 51.51957751828364],
            [-0.228301583860741, 51.521097425944262],
            [-0.22672942517806, 51.5219765064707],
            [-0.226619903856768, 51.524795765413188],
            [-0.227195199780102, 51.524792771097651],
            [-0.227375003252322, 51.525842202803581],
            [-0.227412162019452, 51.527892130073745],
            [-0.228727736321715, 51.529877737477733],
            [-0.228503133284014, 51.530352740801568],
            [-0.227588053489856, 51.53015637314477],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000021",
        LAD13CDO: "00AX",
        LAD13NM: "Kingston upon Thames",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.251123015852641, 51.422316212650642],
            [-0.249642402747401, 51.418528944627639],
            [-0.24953399486376, 51.414778343711603],
            [-0.247136980032716, 51.40958260472933],
            [-0.247507654106747, 51.408500051672497],
            [-0.247103941742253, 51.407889715613564],
            [-0.24581079867146, 51.407564620351692],
            [-0.245353717460021, 51.407072180655589],
            [-0.244703458271898, 51.403852105666523],
            [-0.245234301140845, 51.401702744080744],
            [-0.246846491246405, 51.400485003335561],
            [-0.246622024899361, 51.399211897347293],
            [-0.247424825933623, 51.397578270054467],
            [-0.244595215626591, 51.394662795694181],
            [-0.241745825515471, 51.393922253727446],
            [-0.240847892333406, 51.392148932498685],
            [-0.240108297932814, 51.391195406065613],
            [-0.239706922620339, 51.389294643019213],
            [-0.238744626809497, 51.386095013566965],
            [-0.241620253955485, 51.384524934963231],
            [-0.242931526644994, 51.382933136519974],
            [-0.244174765414224, 51.383492227029635],
            [-0.244984824113744, 51.380649220886255],
            [-0.245054294678756, 51.380035169219461],
            [-0.247917362499541, 51.380170641026552],
            [-0.251661790264767, 51.379049446463519],
            [-0.253971458955405, 51.378772745161292],
            [-0.25844436944285, 51.378970606829633],
            [-0.261124862273045, 51.379600330588929],
            [-0.262492592285146, 51.377254678228319],
            [-0.264702434796865, 51.375623804391331],
            [-0.272569172467904, 51.372265328968119],
            [-0.273612515550048, 51.371532526532683],
            [-0.27404629336714, 51.370656747498529],
            [-0.275856202533262, 51.370015249258962],
            [-0.276583902898574, 51.368809262798933],
            [-0.279843103424855, 51.367826613647345],
            [-0.282185554106219, 51.366001322352929],
            [-0.28409488184692, 51.365151629002284],
            [-0.285726158702563, 51.364052339720082],
            [-0.287044793510966, 51.362667889291103],
            [-0.288301978525112, 51.36208196465801],
            [-0.285101065127397, 51.36121233364269],
            [-0.286996708095148, 51.359375010120914],
            [-0.288996387811108, 51.358711805761843],
            [-0.291108817259515, 51.356561044213244],
            [-0.291224010890806, 51.355202149832017],
            [-0.292684698043797, 51.353616476228432],
            [-0.292930448472884, 51.352375486652392],
            [-0.297795047764908, 51.349262907193321],
            [-0.299949117278058, 51.347671030791595],
            [-0.301337976648807, 51.345294660368722],
            [-0.304048567768433, 51.343048925342636],
            [-0.305437971249957, 51.339480099445531],
            [-0.304949994878418, 51.336750986840087],
            [-0.306215856327961, 51.335085888831628],
            [-0.307635971382265, 51.334609126935455],
            [-0.310953248076609, 51.332390882020469],
            [-0.313716436856299, 51.331416334323485],
            [-0.316765189540393, 51.329147298539773],
            [-0.318978993259164, 51.327957901766752],
            [-0.323970443291982, 51.326717420032395],
            [-0.327323035100057, 51.326686213537137],
            [-0.327597596711242, 51.326319640605995],
            [-0.328314308800709, 51.32646386173721],
            [-0.330139022901282, 51.32751864109391],
            [-0.330679062415786, 51.329011011297645],
            [-0.329116323029064, 51.330263872972537],
            [-0.329718710944251, 51.33276610126773],
            [-0.328979269266763, 51.340232860887582],
            [-0.32790680577888, 51.343743533255946],
            [-0.327480811710163, 51.344007227869568],
            [-0.328177391085327, 51.344041451559093],
            [-0.330533862397632, 51.348421152739618],
            [-0.327947014590891, 51.352186300395729],
            [-0.324453936372623, 51.353891732962182],
            [-0.32353507722447, 51.354670829038092],
            [-0.320259445267751, 51.359099489601988],
            [-0.318304733097254, 51.363684612724612],
            [-0.318523371858323, 51.366186772774626],
            [-0.317915230643954, 51.369846091207705],
            [-0.317505491860361, 51.371970534575944],
            [-0.316694380928462, 51.373167477785849],
            [-0.314341507570123, 51.374638997160851],
            [-0.312674937124656, 51.374752593929273],
            [-0.30869512399023, 51.375449684605911],
            [-0.308457260488264, 51.375860805823365],
            [-0.307892196022698, 51.378365155435318],
            [-0.307388543110839, 51.378384858348596],
            [-0.308326023524929, 51.380083594090941],
            [-0.30851753259259, 51.380501812861205],
            [-0.309276779667282, 51.380448924070386],
            [-0.309330968957377, 51.381864227546245],
            [-0.310631022842123, 51.384765076183392],
            [-0.311787703596548, 51.385786209110435],
            [-0.310868951406257, 51.386187522872632],
            [-0.313158585183755, 51.388800453090617],
            [-0.315981087523024, 51.387633358495229],
            [-0.316759919310306, 51.388773106429063],
            [-0.31413411826016, 51.390218220161039],
            [-0.317720142672215, 51.393668016936353],
            [-0.312977987585243, 51.397110509061868],
            [-0.312147927588434, 51.398142583779389],
            [-0.309653762201768, 51.400014838714277],
            [-0.308896131586574, 51.401652229948432],
            [-0.309095605954949, 51.409302308372048],
            [-0.308498750176094, 51.413513860120247],
            [-0.307780053465381, 51.416542937655912],
            [-0.306030442002069, 51.421234207991539],
            [-0.306537022526544, 51.422704600456406],
            [-0.30926125788049, 51.425704242301215],
            [-0.314469247243867, 51.428259363098761],
            [-0.312823048573936, 51.430344406231541],
            [-0.310678360804468, 51.432210921193672],
            [-0.309783128646307, 51.432144061482319],
            [-0.308088896618773, 51.431080093887473],
            [-0.308567306938014, 51.430785751476236],
            [-0.306955008940479, 51.430153687083248],
            [-0.306430049767838, 51.430150599844424],
            [-0.305942168444627, 51.431362025765658],
            [-0.301780321922149, 51.430391768964526],
            [-0.30153173578671, 51.430539240361142],
            [-0.302070053948549, 51.431152230855297],
            [-0.301618112704061, 51.431429844823533],
            [-0.297493841778424, 51.428560773807128],
            [-0.295465111910777, 51.427644633684068],
            [-0.295435829245582, 51.42703991470831],
            [-0.294732201178123, 51.427007199092444],
            [-0.294789922318294, 51.426461297312621],
            [-0.293409995426661, 51.426512254638205],
            [-0.293021805477958, 51.4289390623792],
            [-0.290639126836261, 51.428990667606129],
            [-0.286618869669505, 51.420172405573695],
            [-0.280458939522883, 51.421856412143846],
            [-0.270962279394014, 51.429542863890283],
            [-0.264599776496545, 51.432917239410415],
            [-0.256343696817343, 51.436346751244635],
            [-0.256208294401136, 51.436653179327614],
            [-0.254090577024466, 51.437290697983272],
            [-0.253110500147291, 51.435298648159865],
            [-0.251254536836126, 51.434168494964638],
            [-0.251084098426208, 51.432566187162607],
            [-0.252394422328873, 51.431253941543922],
            [-0.25332787157117, 51.430844308273777],
            [-0.2542477771565, 51.429317597773007],
            [-0.2522610867171, 51.425737751805322],
            [-0.252954453025099, 51.423209504111945],
            [-0.251123015852641, 51.422316212650642],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000022",
        LAD13CDO: "00AY",
        LAD13NM: "Lambeth",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.108924883269284, 51.508440712246525],
            [-0.10817557946596, 51.506908886741478],
            [-0.107363629565433, 51.507063947320077],
            [-0.106465727755707, 51.504982065253536],
            [-0.106395616243444, 51.503371265271312],
            [-0.107458012260126, 51.503012525379845],
            [-0.106751586516134, 51.502222367670484],
            [-0.10613685539546, 51.502522685499002],
            [-0.105947705483125, 51.502045722819418],
            [-0.10835363144577, 51.500348091392638],
            [-0.110295108492697, 51.496777870564948],
            [-0.111442588914861, 51.496447443990967],
            [-0.110957394372561, 51.49549900705081],
            [-0.104896248247476, 51.493527161876479],
            [-0.103943130518433, 51.491951543015411],
            [-0.103156255328758, 51.491473901155963],
            [-0.108452540988762, 51.485848266229432],
            [-0.106357649118617, 51.484890016785876],
            [-0.107222608685199, 51.483654012664033],
            [-0.105635780159176, 51.482950346274414],
            [-0.106043784825064, 51.4826970513151],
            [-0.104046927054431, 51.480907628301374],
            [-0.10824595958184, 51.480294709951316],
            [-0.105554316991352, 51.479146962563661],
            [-0.099997740374872, 51.476872751361768],
            [-0.100682691841873, 51.474458543267055],
            [-0.099375135463536, 51.472623545691377],
            [-0.098917122789693, 51.471933581054394],
            [-0.095997499425672, 51.469880851313761],
            [-0.094105682458216, 51.470741282306626],
            [-0.093623738515316, 51.471767598165052],
            [-0.093031094336037, 51.472029537589982],
            [-0.092740162332642, 51.469821613398409],
            [-0.091146282569867, 51.467613164185977],
            [-0.09010605408122, 51.465733849824474],
            [-0.090667155604948, 51.463294295543307],
            [-0.092211837232236, 51.461120743272353],
            [-0.094729632306436, 51.45901244920362],
            [-0.095837161157032, 51.457408172173935],
            [-0.100938415379873, 51.454151954634533],
            [-0.101474537368782, 51.452157978619063],
            [-0.100766310365055, 51.450356971702305],
            [-0.099858781137901, 51.450237950281569],
            [-0.098892096762115, 51.448736692577363],
            [-0.095903520087962, 51.446508368744908],
            [-0.095096591144356, 51.445461103702904],
            [-0.09450741441142, 51.443401202587246],
            [-0.092874512572579, 51.441077921876499],
            [-0.090464786661594, 51.43609811936274],
            [-0.088497136481674, 51.429707325163626],
            [-0.087680756290129, 51.429000672170304],
            [-0.085890486945861, 51.428501130999017],
            [-0.086074058291397, 51.427826080248188],
            [-0.08525078118987, 51.42735490551695],
            [-0.083683165944882, 51.425008271123666],
            [-0.083476682791357, 51.422998624470559],
            [-0.081726561518059, 51.423367469499617],
            [-0.080896958522096, 51.421848509941974],
            [-0.0798562969174, 51.422140813591227],
            [-0.078930892055468, 51.421570798557319],
            [-0.0792228936188, 51.420849873365228],
            [-0.078306723189438, 51.420609137481627],
            [-0.078548643992427, 51.419847824736017],
            [-0.082346001337163, 51.419771533877956],
            [-0.086425687184976, 51.419305849976176],
            [-0.088190383828824, 51.419927269261336],
            [-0.092734433338077, 51.422539943278622],
            [-0.094346733695295, 51.422818864663064],
            [-0.096906133161734, 51.422667109533435],
            [-0.105987341539958, 51.422576811743276],
            [-0.109373710729991, 51.423157559171081],
            [-0.112685586677911, 51.423244210692374],
            [-0.115715109592252, 51.420578971428746],
            [-0.119846669165159, 51.418807224841629],
            [-0.122480986171562, 51.415029374525716],
            [-0.124074336978944, 51.41460975340997],
            [-0.124467543059399, 51.413365167544931],
            [-0.125786039584686, 51.412631776832328],
            [-0.1262598739242, 51.412930715998193],
            [-0.127751755490985, 51.41231698000761],
            [-0.127888157389107, 51.412005315223404],
            [-0.132714541541758, 51.412348498965905],
            [-0.133572399337791, 51.412576189712439],
            [-0.133577951961488, 51.412934185746849],
            [-0.134099982775509, 51.410991092625601],
            [-0.138102022862918, 51.411802033537136],
            [-0.142582259817905, 51.413342526041987],
            [-0.14486999351379, 51.412388672645093],
            [-0.148090403172057, 51.412870353937947],
            [-0.147083375273113, 51.413804958129155],
            [-0.144347519201571, 51.415347971921349],
            [-0.145009451901709, 51.415799989433829],
            [-0.143048789453556, 51.417125029150782],
            [-0.14326035227944, 51.417263270651951],
            [-0.140405494164252, 51.419249443624189],
            [-0.13785193044739, 51.421106345058661],
            [-0.138922158167865, 51.42457291869524],
            [-0.138387044423803, 51.430484267072863],
            [-0.135264181659318, 51.430334807480904],
            [-0.13652257364814, 51.433915000606781],
            [-0.137857582953008, 51.433776148714813],
            [-0.13975906042949, 51.435665115557541],
            [-0.139912604251632, 51.437020940599659],
            [-0.137683725450734, 51.438581738467853],
            [-0.137497636197338, 51.440149791114401],
            [-0.13701141803964, 51.440252673635179],
            [-0.135980062246629, 51.442011418952703],
            [-0.137885144368552, 51.441695482395573],
            [-0.143620745431506, 51.441838612696955],
            [-0.145696480092407, 51.445869575425164],
            [-0.144795220688118, 51.448542302258026],
            [-0.143360173222615, 51.449505170843075],
            [-0.142327935331495, 51.45096719988495],
            [-0.147729989229507, 51.452339517705035],
            [-0.147378014171984, 51.452963436671538],
            [-0.148153901320381, 51.456410860675234],
            [-0.149390467117798, 51.459361965838525],
            [-0.149693477122954, 51.461301949415194],
            [-0.150967264399445, 51.463836366894633],
            [-0.151231440191364, 51.465884542122936],
            [-0.150470384847331, 51.465977751839134],
            [-0.150618706999224, 51.467614938819885],
            [-0.143100404456814, 51.470519407080644],
            [-0.142676864970741, 51.470063969630822],
            [-0.14034263783979, 51.471592575331726],
            [-0.13974129172348, 51.471367214388742],
            [-0.139316230547667, 51.471516040996541],
            [-0.139502107132498, 51.471795961640275],
            [-0.138349190014795, 51.471962009135098],
            [-0.136918036303989, 51.473090325423826],
            [-0.135787169140427, 51.472714446463051],
            [-0.134721997808315, 51.473305403526645],
            [-0.134461784618302, 51.473965814410533],
            [-0.135230572110168, 51.474287388127259],
            [-0.133980314381718, 51.47563706518104],
            [-0.130661757110705, 51.480199189899807],
            [-0.129947543513704, 51.481182379034024],
            [-0.127496476700001, 51.482022735925966],
            [-0.126363701043512, 51.48446590586321],
            [-0.128171745833204, 51.484735782293463],
            [-0.128455634354978, 51.485048758452045],
            [-0.125550170680066, 51.486918664520104],
            [-0.12473338696709, 51.48783363755097],
            [-0.124206397189441, 51.487828805803183],
            [-0.121347662155465, 51.494370090965184],
            [-0.119375798856658, 51.503847184599351],
            [-0.118350300000246, 51.505725488010057],
            [-0.117013289462314, 51.506838888247941],
            [-0.115408134202808, 51.50753430796474],
            [-0.111457494792382, 51.508354726170019],
            [-0.110813654015933, 51.50810335664314],
            [-0.108924883269284, 51.508440712246525],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000023",
        LAD13CDO: "00AZ",
        LAD13NM: "Lewisham",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.032412871376477, 51.493055478030982],
            [-0.028851616113897, 51.488249337990617],
            [-0.024847350880798, 51.48554620243776],
            [-0.025819137084824, 51.482188513702155],
            [-0.02640870425134, 51.481509599166294],
            [-0.023161272575439, 51.480737276575148],
            [-0.023070951654299, 51.4796287535559],
            [-0.020367433118661, 51.479935652431408],
            [-0.020418030273999, 51.480230567779856],
            [-0.018400910828536, 51.480848465039045],
            [-0.017875306971385, 51.480880048013894],
            [-0.017116566610447, 51.480409490775465],
            [-0.019136212629098, 51.479766478734646],
            [-0.019442426039074, 51.477983903551028],
            [-0.019828949673622, 51.478003921123701],
            [-0.019375015526643, 51.477212989852362],
            [-0.020023401126182, 51.477138510408963],
            [-0.021092450746222, 51.476261785358361],
            [-0.022814113266507, 51.47587716718747],
            [-0.020922337526472, 51.476099743273068],
            [-0.020964731561117, 51.475685895449224],
            [-0.022767619095859, 51.475353907430218],
            [-0.022803211105991, 51.474464230559946],
            [-0.022672716843668, 51.473985416812049],
            [-0.021772409622089, 51.474025984419718],
            [-0.020050683889048, 51.473083263018182],
            [-0.020245108257488, 51.472184577981523],
            [-0.019319216385597, 51.471985488543346],
            [-0.018671715122424, 51.47064542572631],
            [-0.019272685239432, 51.47050000649449],
            [-0.019118014741737, 51.469948838081045],
            [-0.017161713932206, 51.469340234729017],
            [-0.016949420381478, 51.469056071446659],
            [-0.016558988944711, 51.469227522554377],
            [-0.015090220217585, 51.468036309712367],
            [-0.013128976237678, 51.468936532526904],
            [-0.015184508601709, 51.47176898303119],
            [-0.014528637291091, 51.471917948076552],
            [-0.014069522161415, 51.472675451944887],
            [-0.012518847374704, 51.472340725310616],
            [-0.008143481207123, 51.472756575965292],
            [-0.000983579297989, 51.471811881445518],
            [0.01208889858585, 51.473172781492039],
            [0.018123601606253, 51.473853226959037],
            [0.01498490181025, 51.473079879650086],
            [0.015357539554567, 51.47156988720446],
            [0.016694264845057, 51.469491158122331],
            [0.016532981693577, 51.469131524388594],
            [0.016330536739716, 51.468981230256709],
            [0.013481107574777, 51.469897103053974],
            [0.012601703449235, 51.469806987679696],
            [0.01306891228052, 51.468121818471154],
            [0.012691166913417, 51.467357625351362],
            [0.013245379895772, 51.466831925411313],
            [0.00909709466274, 51.465874318502678],
            [0.009279838921624, 51.464036665499194],
            [0.009795626010971, 51.462505351042893],
            [0.009947389584917, 51.459340895845777],
            [0.011484641303472, 51.456685044970804],
            [0.014030588174536, 51.455726766816291],
            [0.017513892739578, 51.455039173131006],
            [0.018331579773579, 51.451582662077584],
            [0.019535903098244, 51.451704904833875],
            [0.01987163050538, 51.450735094037249],
            [0.015122713230645, 51.45022690231697],
            [0.01487594923764, 51.450604344421826],
            [0.015063752057979, 51.450031873347989],
            [0.021927570330259, 51.448958645653349],
            [0.022401686561109, 51.448125827792111],
            [0.022942398165802, 51.44720462842821],
            [0.021210017414629, 51.446691348853307],
            [0.021496470554296, 51.44444450605787],
            [0.020596816441374, 51.444210917880419],
            [0.021482862909919, 51.442735210505639],
            [0.025416185767172, 51.441738352647342],
            [0.027767468160007, 51.441510648374503],
            [0.029358966392502, 51.441705226683411],
            [0.028576811179677, 51.44045347821141],
            [0.028813712210221, 51.438785708220877],
            [0.024790793351299, 51.43823746805942],
            [0.025375932978386, 51.435689587693055],
            [0.027240267877236, 51.43655933509352],
            [0.028673967773433, 51.436411326973015],
            [0.02906568594928, 51.43466623512186],
            [0.028584319755872, 51.434030682044188],
            [0.031381588364816, 51.432579352910459],
            [0.031147580986985, 51.43210409116876],
            [0.03158768426563, 51.432555097123746],
            [0.032451710320051, 51.431892633570882],
            [0.033223669603176, 51.432235359923162],
            [0.033700520649509, 51.43169920765866],
            [0.037698507637781, 51.433346496578821],
            [0.037521772298542, 51.431379240248326],
            [0.038332189584116, 51.428027907680871],
            [0.037857209228264, 51.427601813735535],
            [0.038463803349644, 51.427591264523073],
            [0.039028625616491, 51.42467135597964],
            [0.036185884693948, 51.422365556772363],
            [0.034565951549168, 51.423394594450663],
            [0.025410480818806, 51.428989343842019],
            [0.020905460960159, 51.426818908264423],
            [0.018775175755778, 51.427050766778116],
            [0.017435699293917, 51.426849911112143],
            [0.016048065276034, 51.426208321595965],
            [0.014107520195143, 51.426175140417165],
            [0.014395466349803, 51.425232241436618],
            [0.013512995084405, 51.425085535669659],
            [0.013344810656539, 51.424695439291291],
            [0.011813367485276, 51.424051774224267],
            [0.009966669160178, 51.422483640220086],
            [0.008076715670459, 51.423043017622881],
            [0.007349340238142, 51.421379219577872],
            [0.006848109385548, 51.421494818284799],
            [0.00673587371653, 51.420935589049307],
            [0.006297787495831, 51.421015032216047],
            [0.005228085658549, 51.419315714266311],
            [0.005671974766633, 51.418514952428239],
            [0.003493381870314, 51.417404735853253],
            [0.001728747678104, 51.418558092456102],
            [-0.000077252872264, 51.417645581789778],
            [-0.000754049654404, 51.415912525930018],
            [-0.003404043702143, 51.415660942742797],
            [-0.003283970904318, 51.415050084207536],
            [-0.004993813068833, 51.414589996738165],
            [-0.005534172859589, 51.415788042791959],
            [-0.006565100550283, 51.415991734862438],
            [-0.006627347980809, 51.414660060970867],
            [-0.007692066477833, 51.414483023423834],
            [-0.01054547769936, 51.413551284994512],
            [-0.014262647188211, 51.413657497126884],
            [-0.014558881798717, 51.413923307405859],
            [-0.014152643348072, 51.415100774514954],
            [-0.017987213578037, 51.415055979620561],
            [-0.017846743008069, 51.415743350346034],
            [-0.021040467460402, 51.416019447644388],
            [-0.022185540905973, 51.416745611103167],
            [-0.021736286587265, 51.417291084032279],
            [-0.022555326144167, 51.417541417178327],
            [-0.02138438351474, 51.418412837387464],
            [-0.020777617764411, 51.419940356157575],
            [-0.021674748933451, 51.420448307975917],
            [-0.020947822795443, 51.420927939333481],
            [-0.022263283002336, 51.421774783225516],
            [-0.022606122804704, 51.421562943311606],
            [-0.024427450198297, 51.423154804327659],
            [-0.02599980858657, 51.423617448002297],
            [-0.030106948198925, 51.42564607626143],
            [-0.031997476588678, 51.425536650967295],
            [-0.031820068382722, 51.424710834731066],
            [-0.033373635349472, 51.424642490501164],
            [-0.034239057377374, 51.424755030386564],
            [-0.034227084810177, 51.425101050531133],
            [-0.037485484723019, 51.424992889570419],
            [-0.038476286472146, 51.42395822432195],
            [-0.039920856211149, 51.424251276092733],
            [-0.041068931916304, 51.424246191025382],
            [-0.041607744505232, 51.423654480516554],
            [-0.046288563939972, 51.422366606895338],
            [-0.046015450276107, 51.422800899454103],
            [-0.047231239283551, 51.422859832320185],
            [-0.051773807398039, 51.422517271386091],
            [-0.051809088195716, 51.423581698287208],
            [-0.054780384878001, 51.423727274780788],
            [-0.055154772315996, 51.422938530844149],
            [-0.056415034687148, 51.422966631739641],
            [-0.058125143730244, 51.42317303532095],
            [-0.062603499266066, 51.425197673103888],
            [-0.064429146214868, 51.425327657130829],
            [-0.064604329846581, 51.425062567231571],
            [-0.065841666796843, 51.425328494648063],
            [-0.066062574748082, 51.425070452096463],
            [-0.067915136671355, 51.425926537696839],
            [-0.068690381502924, 51.425996868526688],
            [-0.06888189241702, 51.42561693474839],
            [-0.070876997763939, 51.426232518476994],
            [-0.073935648723001, 51.426153328520165],
            [-0.075048427135353, 51.429179668548826],
            [-0.072861745747858, 51.432087044722444],
            [-0.072215971484247, 51.432458614673919],
            [-0.069529171991758, 51.432682374936547],
            [-0.066708789112277, 51.433922743543874],
            [-0.065834332177944, 51.434631329855861],
            [-0.064420324738406, 51.437041412683492],
            [-0.063456779288316, 51.437540778913338],
            [-0.064053571420876, 51.440849158787557],
            [-0.065026210943825, 51.441157481569668],
            [-0.063870418640029, 51.442525968923],
            [-0.063640020651311, 51.444809004589359],
            [-0.062219938664891, 51.44735205114678],
            [-0.060972688511219, 51.448427635064405],
            [-0.057561325333382, 51.450338757248637],
            [-0.053787844199681, 51.44870964107551],
            [-0.04908666594878, 51.450112591986851],
            [-0.046150787508526, 51.44987304884755],
            [-0.041648675260069, 51.454990310250523],
            [-0.044632366825859, 51.456576968535039],
            [-0.046160393195838, 51.456963052912158],
            [-0.045177046561926, 51.459831515624096],
            [-0.045346376365294, 51.462606785183411],
            [-0.046363645681786, 51.464406091841845],
            [-0.048540197363974, 51.465626677763908],
            [-0.048206118538756, 51.465898090985085],
            [-0.050888480480536, 51.466637850358595],
            [-0.050575174890897, 51.466793610094626],
            [-0.051326609607859, 51.46737084230363],
            [-0.05088143769974, 51.467580165585431],
            [-0.052281872893448, 51.469214026934019],
            [-0.051687120133107, 51.469757194115999],
            [-0.052757040153795, 51.472933193568046],
            [-0.052699291641731, 51.478008591672548],
            [-0.054328561545719, 51.478605773783023],
            [-0.053358874898885, 51.480308175027261],
            [-0.052811469916068, 51.4826443694934],
            [-0.053097366792522, 51.485163461602085],
            [-0.053560176260811, 51.485795234721706],
            [-0.052949193405628, 51.486004511567515],
            [-0.052844364935641, 51.486470388460809],
            [-0.053977506639871, 51.487935216710909],
            [-0.052631185671463, 51.487552260578127],
            [-0.050308964482363, 51.488099092826786],
            [-0.050108764528892, 51.488334968307051],
            [-0.051958730451515, 51.489135486776362],
            [-0.049358348047122, 51.490021198268515],
            [-0.046567454769712, 51.490744509180757],
            [-0.046567389916409, 51.490341637474287],
            [-0.045831769344855, 51.490162117346152],
            [-0.044758156451631, 51.490441877303184],
            [-0.044498807016613, 51.490106623020765],
            [-0.042225880566799, 51.490035423754421],
            [-0.042508067824018, 51.490240670948886],
            [-0.040343776049782, 51.491062420776643],
            [-0.041055711539906, 51.491827900687049],
            [-0.040070408582848, 51.492163049200911],
            [-0.040553065759724, 51.492799701742292],
            [-0.039320224425089, 51.492960746340763],
            [-0.037315079734201, 51.492219480538104],
            [-0.032412871376477, 51.493055478030982],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000024",
        LAD13CDO: "00BA",
        LAD13NM: "Merton",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.189267469808219, 51.438117479715046],
            [-0.191062168624924, 51.433042812257945],
            [-0.189796812617996, 51.431351559992763],
            [-0.189088956346247, 51.431607705117038],
            [-0.189254217957595, 51.43183057608536],
            [-0.18741948382093, 51.432097174399587],
            [-0.18728933998995, 51.432986325072072],
            [-0.185761625845012, 51.433164122631197],
            [-0.184248349824731, 51.431272034476713],
            [-0.184347474354902, 51.426957134518389],
            [-0.18394580500514, 51.426203628434102],
            [-0.181939891464086, 51.424735516452259],
            [-0.17879882412344, 51.424327047799601],
            [-0.173347063134889, 51.423070473739585],
            [-0.16842503757318, 51.420910972976124],
            [-0.164833467163193, 51.420340413436016],
            [-0.165093647598839, 51.420092694105662],
            [-0.161671169914427, 51.419862806362396],
            [-0.160361824065581, 51.421917756373034],
            [-0.15997307172545, 51.421927840477451],
            [-0.159882854863525, 51.422634142528082],
            [-0.159592144441397, 51.42256662967106],
            [-0.151327639908783, 51.419852083214288],
            [-0.150284284143382, 51.420081119686948],
            [-0.149341252077632, 51.419855805018813],
            [-0.144480272169609, 51.417752006847095],
            [-0.140405494164252, 51.419249443624189],
            [-0.14326035227944, 51.417263270651951],
            [-0.143048789453556, 51.417125029150782],
            [-0.145009451901709, 51.415799989433829],
            [-0.144347519201571, 51.415347971921349],
            [-0.147083375273113, 51.413804958129155],
            [-0.148090403172057, 51.412870353937947],
            [-0.14486999351379, 51.412388672645093],
            [-0.142582259817905, 51.413342526041987],
            [-0.138102022862918, 51.411802033537136],
            [-0.134099982775509, 51.410991092625601],
            [-0.133577951961488, 51.412934185746849],
            [-0.133572399337791, 51.412576189712439],
            [-0.132714541541758, 51.412348498965905],
            [-0.127888157389107, 51.412005315223404],
            [-0.127751755490985, 51.41231698000761],
            [-0.128193641283099, 51.410933773331045],
            [-0.128480915564511, 51.411012101250101],
            [-0.129478580808406, 51.409574815528579],
            [-0.131285981145888, 51.409506529764954],
            [-0.131316533626589, 51.409215654752138],
            [-0.132089107482163, 51.409170418568294],
            [-0.131955699129911, 51.408456972600767],
            [-0.132481137203481, 51.408406894896444],
            [-0.131533099793822, 51.404926010344795],
            [-0.130506204792157, 51.403276563755966],
            [-0.127989887803862, 51.401698645193655],
            [-0.12541746468812, 51.398824827585585],
            [-0.125724594325816, 51.39830996351801],
            [-0.124636481196836, 51.398095614248234],
            [-0.124151448519686, 51.397617533187905],
            [-0.129689724176393, 51.393651262156304],
            [-0.128756556479026, 51.393481689215982],
            [-0.134319555917893, 51.390934642197436],
            [-0.134379023506288, 51.390886129306502],
            [-0.141903770299123, 51.389355541280722],
            [-0.143183731014795, 51.390401896037581],
            [-0.144378413009366, 51.390679810957025],
            [-0.152672588438534, 51.389770418442495],
            [-0.158055751807391, 51.389258952278787],
            [-0.158282461295624, 51.38883896173634],
            [-0.159703695423595, 51.388594210401713],
            [-0.160986592469052, 51.387820301071116],
            [-0.161770337177903, 51.388132054930821],
            [-0.165290546103297, 51.387979498453532],
            [-0.166455224660887, 51.389692844145642],
            [-0.167609062114149, 51.39006070797636],
            [-0.169971719782554, 51.391725300584696],
            [-0.171317408889161, 51.392510684158239],
            [-0.17287143451087, 51.392650032316268],
            [-0.174867588230977, 51.393418537527488],
            [-0.179264634607271, 51.389835035419701],
            [-0.18223569686668, 51.388308353251581],
            [-0.184530831363061, 51.387513916824538],
            [-0.18841153512698, 51.38543553357681],
            [-0.189657211460292, 51.386063587607872],
            [-0.190469477717412, 51.386202930779504],
            [-0.193200741706216, 51.387866449282456],
            [-0.193603743869093, 51.387669429660875],
            [-0.193950778213, 51.387871717643179],
            [-0.200316910003305, 51.384963438342702],
            [-0.203517971741341, 51.387225643673546],
            [-0.205027571163463, 51.386092329430561],
            [-0.208888691382255, 51.387856413249331],
            [-0.209793038604226, 51.388253318553609],
            [-0.210139782361228, 51.387986139823553],
            [-0.213371781690992, 51.38500675693053],
            [-0.212985621558078, 51.384811124132504],
            [-0.218136302210686, 51.380150464819323],
            [-0.227742306348417, 51.383954345342836],
            [-0.233928258996926, 51.387709526210962],
            [-0.234657283404015, 51.390538784967291],
            [-0.239706922620339, 51.389294643019213],
            [-0.240108297932814, 51.391195406065613],
            [-0.240847892333406, 51.392148932498685],
            [-0.241745825515471, 51.393922253727446],
            [-0.244595215626591, 51.394662795694181],
            [-0.247424825933623, 51.397578270054467],
            [-0.246622024899361, 51.399211897347293],
            [-0.246846491246405, 51.400485003335561],
            [-0.245234301140845, 51.401702744080744],
            [-0.244703458271898, 51.403852105666523],
            [-0.245353717460021, 51.407072180655589],
            [-0.24581079867146, 51.407564620351692],
            [-0.247103941742253, 51.407889715613564],
            [-0.247507654106747, 51.408500051672497],
            [-0.247136980032716, 51.40958260472933],
            [-0.24953399486376, 51.414778343711603],
            [-0.249642402747401, 51.418528944627639],
            [-0.251123015852641, 51.422316212650642],
            [-0.252954453025099, 51.423209504111945],
            [-0.2522610867171, 51.425737751805322],
            [-0.2542477771565, 51.429317597773007],
            [-0.25332787157117, 51.430844308273777],
            [-0.252394422328873, 51.431253941543922],
            [-0.251084098426208, 51.432566187162607],
            [-0.240711670830783, 51.435220227098945],
            [-0.2313234521774, 51.438139099399237],
            [-0.225505926684569, 51.438742702697795],
            [-0.224507850796707, 51.438581910888296],
            [-0.224276893949684, 51.438860777513248],
            [-0.220438226153947, 51.438449158875081],
            [-0.217846553863352, 51.438679561185417],
            [-0.216531507783253, 51.438345721601948],
            [-0.21573472971293, 51.437746384228085],
            [-0.212545867315941, 51.437831790384742],
            [-0.20912888681539, 51.438304800113251],
            [-0.204557872366545, 51.438961473017258],
            [-0.190039782512615, 51.441474637000205],
            [-0.189759173928865, 51.440055775578394],
            [-0.190667404420037, 51.438919650348026],
            [-0.189215934579555, 51.438547427036902],
            [-0.189267469808219, 51.438117479715046],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000025",
        LAD13CDO: "00BB",
        LAD13NM: "Newham",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.055705401067482, 51.55933724134988],
            [0.056337676461128, 51.558577969794925],
            [0.056877774979839, 51.558717780578206],
            [0.057122895398729, 51.558107375954137],
            [0.058261237694313, 51.557853600700071],
            [0.059978057526259, 51.556514132779739],
            [0.063588728585039, 51.557306782785147],
            [0.06658787836203, 51.554727042415493],
            [0.066946352115033, 51.553024703429024],
            [0.067796188729641, 51.553060985735797],
            [0.068716816789715, 51.547535820999883],
            [0.068327153038802, 51.544414126264442],
            [0.068269421321698, 51.544254174518905],
            [0.06664900817895, 51.540862802709832],
            [0.066965702558576, 51.539609033062511],
            [0.068887807295215, 51.537188478674423],
            [0.068255909347036, 51.536990990850967],
            [0.067931530850096, 51.53619725644662],
            [0.068705194389152, 51.536214189819788],
            [0.069457013866559, 51.536289956221616],
            [0.07191677457608, 51.532950696450435],
            [0.072566831154866, 51.530787249407304],
            [0.072712875431019, 51.529272988737766],
            [0.073165676725558, 51.529370201346325],
            [0.075185330452799, 51.529523333781334],
            [0.077636326446789, 51.529175089838716],
            [0.078471252472678, 51.528830263514983],
            [0.078018062036148, 51.528629661614652],
            [0.077545902595468, 51.528867340921309],
            [0.077990774492432, 51.528473671188564],
            [0.078826637281137, 51.528434579186268],
            [0.080111610597397, 51.527526011366042],
            [0.085197008553033, 51.52561287954105],
            [0.086366049171304, 51.525610075509775],
            [0.088805124709443, 51.526251911762934],
            [0.089983713751893, 51.52556005551471],
            [0.09204933786856, 51.525617653423495],
            [0.092104425577577, 51.523988978665372],
            [0.090781098608011, 51.523990094285253],
            [0.091808385628986, 51.523505951244523],
            [0.091734859125381, 51.522625071239368],
            [0.094410289497253, 51.520330029062102],
            [0.094008083779881, 51.518255381375319],
            [0.094792092162904, 51.516336708032462],
            [0.096762701370868, 51.515150415589723],
            [0.095714154286026, 51.515448833368119],
            [0.094468848159963, 51.515008850578887],
            [0.090301613398348, 51.514716294009141],
            [0.090502244341576, 51.514281963418924],
            [0.088231453696493, 51.513713607658659],
            [0.084553154330899, 51.512209805825954],
            [0.083369834747338, 51.511544684101487],
            [0.083717624666737, 51.510986348338058],
            [0.082572559415071, 51.509926655178411],
            [0.081078398098408, 51.508605161601615],
            [0.080243390087026, 51.508307027709002],
            [0.078054071669051, 51.505165096441701],
            [0.077036647184298, 51.504909740691296],
            [0.077780267766164, 51.504562935224797],
            [0.076908801434904, 51.503809489905713],
            [0.076285168658484, 51.503816036936179],
            [0.076750267937394, 51.503523629234095],
            [0.075341410801137, 51.502263502437877],
            [0.073988858198566, 51.502114770321413],
            [0.074729808885509, 51.501582779813234],
            [0.073705753544241, 51.500417443345754],
            [0.07085457463012, 51.499501105068255],
            [0.070448845571336, 51.499672837346651],
            [0.070595830987903, 51.499450818185721],
            [0.069942130590797, 51.499492035325176],
            [0.069981673380572, 51.499283604089591],
            [0.068946029012072, 51.499131018316433],
            [0.068581948886731, 51.499331685441142],
            [0.068634225974838, 51.499086159662177],
            [0.058854162426111, 51.498384183726905],
            [0.049465318577566, 51.499587315507171],
            [0.043345759980799, 51.499963890879137],
            [0.039080988807783, 51.499900537646965],
            [0.034999580702848, 51.499683668713452],
            [0.028712946154674, 51.498766526251288],
            [0.02455234701637, 51.498709835579],
            [0.024414895325253, 51.498306636714283],
            [0.023662376153823, 51.498394265067645],
            [0.023633740573637, 51.498983782563542],
            [0.020605443959465, 51.499314776413449],
            [0.017791065956588, 51.500575454698861],
            [0.014601996132637, 51.503014251359559],
            [0.01487327892787, 51.503219120681464],
            [0.014516571569702, 51.503102948091943],
            [0.013825291577514, 51.503699346905186],
            [0.013107663419657, 51.504417595250132],
            [0.01340306361093, 51.504908021850291],
            [0.012043074139229, 51.505241611273846],
            [0.009680637972365, 51.506798288192762],
            [0.010517813258843, 51.508564492298269],
            [0.009913291541126, 51.509137796871457],
            [0.00654409023942, 51.510025515677505],
            [0.008199145557268, 51.512596073365486],
            [0.008056333559492, 51.513412356564068],
            [0.007143304085746, 51.514379411443713],
            [0.005913645029047, 51.514504764325345],
            [0.004686310039302, 51.513764069632629],
            [0.004195596020156, 51.513223003730999],
            [0.003914473626242, 51.511283590092688],
            [0.00357807513867, 51.51094132082158],
            [0.002676925263915, 51.510865886483863],
            [0.004469626082553, 51.514704810240687],
            [0.003581710822699, 51.515589573979483],
            [-0.00264305287566, 51.518559912110419],
            [-0.002420812750915, 51.518203614780738],
            [-0.00402468305334, 51.51798182375672],
            [-0.005163358357122, 51.516926573233626],
            [-0.00641921889514, 51.518797716864867],
            [-0.008515447375453, 51.520010467324866],
            [-0.008635414371432, 51.52082903715624],
            [-0.007206701375625, 51.522881174876645],
            [-0.007099854833033, 51.524436886590806],
            [-0.006471856822687, 51.525678890728358],
            [-0.006359097168286, 51.525917077919367],
            [-0.002086497665331, 51.527027811572168],
            [0.000670247222727, 51.528283858219808],
            [0.000865132618426, 51.529210374344892],
            [0.001789278216427, 51.530327680736811],
            [0.001112911000762, 51.531046943306684],
            [-0.000768666902002, 51.528150121836404],
            [-0.002324450872203, 51.527157761599966],
            [-0.003666778435717, 51.526891052160551],
            [-0.004062544948738, 51.527104618343678],
            [-0.004549650270831, 51.52654816775604],
            [-0.00595589437924, 51.526236657121856],
            [-0.006589317829482, 51.52635623492948],
            [-0.006964586672005, 51.527105404653163],
            [-0.007951600104679, 51.527216594321082],
            [-0.006888796701805, 51.526034891090795],
            [-0.007105246250094, 51.525534980632557],
            [-0.007857014614343, 51.523009126510225],
            [-0.008111295199405, 51.523821884327553],
            [-0.007789717630678, 51.525376647268843],
            [-0.00752458014343, 51.526199467186395],
            [-0.008726494151562, 51.527596651861927],
            [-0.010353679272708, 51.528533420635789],
            [-0.010786606172409, 51.52931772741632],
            [-0.013647339204428, 51.529840118918294],
            [-0.017443967472838, 51.531487952559857],
            [-0.018232546428422, 51.532452698026866],
            [-0.017987957439674, 51.533270491399492],
            [-0.018963272944884, 51.534119683893678],
            [-0.019346297707899, 51.535194475274075],
            [-0.021170425674749, 51.536521996715884],
            [-0.021089975851115, 51.5373461616729],
            [-0.019427028249381, 51.538283903346958],
            [-0.018916366943585, 51.539350797153709],
            [-0.017879575560722, 51.54017948833642],
            [-0.017751706371162, 51.540931808066588],
            [-0.016515324149734, 51.541507119867404],
            [-0.01609258594968, 51.5425395176227],
            [-0.01657157600578, 51.54336414642146],
            [-0.017458833413727, 51.547537326476821],
            [-0.018941963059451, 51.550482278770282],
            [-0.018995734541442, 51.551601866064701],
            [-0.018082406479976, 51.5515747499892],
            [-0.017167868347094, 51.551575483401528],
            [-0.016970500376781, 51.551403985835165],
            [-0.010889431789572, 51.552622054076018],
            [-0.009471904888413, 51.552215825930311],
            [-0.008676164654441, 51.549925392789362],
            [-0.000351331653374, 51.551719903534391],
            [0.00308097094102, 51.55209749299987],
            [0.003842748776024, 51.551805712575522],
            [0.004983209004323, 51.551892340427266],
            [0.005175530646116, 51.552132753660096],
            [0.005231241155999, 51.551825152853063],
            [0.005734813429155, 51.551861507695186],
            [0.005503083815926, 51.550659557721417],
            [0.009508180300403, 51.551237593349178],
            [0.010821168967345, 51.552260040123564],
            [0.009622268289578, 51.55343343849372],
            [0.011110204075314, 51.554398030183684],
            [0.01162128210523, 51.55391715489921],
            [0.014573798927439, 51.554954601192534],
            [0.016254228803649, 51.553222527763971],
            [0.016720384838764, 51.553390771146503],
            [0.016944869408588, 51.553182779076444],
            [0.021532166254598, 51.554878073063406],
            [0.020341593033503, 51.556267261788413],
            [0.022476417664203, 51.556164833060038],
            [0.022575095175279, 51.556698194204635],
            [0.023291899228805, 51.556824321305861],
            [0.023762370025719, 51.555528459988437],
            [0.025276505162464, 51.555548194027715],
            [0.025341772950035, 51.554062380970954],
            [0.032177736452395, 51.55457635219512],
            [0.035413285590593, 51.555253163777394],
            [0.046300812918632, 51.554813770091236],
            [0.044060519381503, 51.557773643925159],
            [0.042851026846646, 51.558372938844684],
            [0.041928547657158, 51.561450999475248],
            [0.050318412186429, 51.563988993497532],
            [0.051365970942363, 51.562323225206555],
            [0.052554824325654, 51.56171431188907],
            [0.054097522789305, 51.560858187956548],
            [0.054464323872253, 51.559649444342398],
            [0.055705401067482, 51.55933724134988],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000026",
        LAD13CDO: "00BC",
        LAD13NM: "Redbridge",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.032755330213064, 51.622242052524996],
            [0.037451330277155, 51.618324404703081],
            [0.037518939303153, 51.617898781426469],
            [0.040188111167759, 51.616409109078965],
            [0.039987730979364, 51.616096052797033],
            [0.040761332572131, 51.615727398199127],
            [0.04367878331255, 51.616940097094037],
            [0.04783412362604, 51.617409029057313],
            [0.053071514732924, 51.618421829930277],
            [0.053684743476106, 51.618278011962161],
            [0.054364798447976, 51.617598860705357],
            [0.054184941170699, 51.61671893496402],
            [0.053403668480903, 51.616339628422288],
            [0.051974508829856, 51.61680795656806],
            [0.050149988889679, 51.616389309032371],
            [0.048457124366729, 51.614980049405673],
            [0.048067560769485, 51.613321418333648],
            [0.048894828011061, 51.613569564403349],
            [0.052604679058358, 51.612013772782376],
            [0.060671163151509, 51.608275382380796],
            [0.062518865773622, 51.607075684710729],
            [0.062972074814149, 51.606910348593068],
            [0.063984226626999, 51.607223482362031],
            [0.065940540795626, 51.606319475785547],
            [0.072805474674718, 51.604685873376205],
            [0.077882461970067, 51.606728254666933],
            [0.080009903212025, 51.606983731144183],
            [0.084448170137849, 51.606357338277007],
            [0.087118373482815, 51.604465489920706],
            [0.088895075721724, 51.604852039722516],
            [0.088900152817654, 51.605182879348156],
            [0.089602896257472, 51.605516587095757],
            [0.092554211275719, 51.606195107610688],
            [0.091687476595844, 51.607478523363234],
            [0.091846893345649, 51.608213080514751],
            [0.092624181609221, 51.609475283106086],
            [0.093689855188715, 51.609753035724005],
            [0.094209339785468, 51.610652925935604],
            [0.094665678645763, 51.61074550049215],
            [0.093614496049257, 51.612478256943525],
            [0.09225798342374, 51.613572571985912],
            [0.098041444197624, 51.615419843160609],
            [0.100141460688446, 51.612723155799983],
            [0.103387503710872, 51.613451887736652],
            [0.103810880451421, 51.612549528938523],
            [0.109604762377142, 51.613959900804396],
            [0.116683030987011, 51.616919653734456],
            [0.118579520485394, 51.615364774551125],
            [0.119280179178439, 51.615366513887075],
            [0.124171356766201, 51.617503788753048],
            [0.126071179724223, 51.619641127136326],
            [0.128852341237433, 51.620695920589625],
            [0.129160273156842, 51.621441225279511],
            [0.135783262642079, 51.623544796873546],
            [0.138156906432108, 51.623542949725994],
            [0.14447052286024, 51.616680537644839],
            [0.145475265476092, 51.61455338241317],
            [0.147468566188391, 51.612348781949436],
            [0.148680520749221, 51.60868094613253],
            [0.148586795112627, 51.604493843833552],
            [0.1494850011781, 51.602902766821536],
            [0.148954599181678, 51.60154828061021],
            [0.148179718673996, 51.598959965848444],
            [0.146563279682496, 51.599436483013427],
            [0.141898947269127, 51.596577482040999],
            [0.137560244905989, 51.593876035262227],
            [0.129555924362548, 51.59005216885118],
            [0.1310037973541, 51.58751960815971],
            [0.126374574524308, 51.586724993745314],
            [0.127220083603091, 51.583934510022843],
            [0.127630810454499, 51.583889293967815],
            [0.128057412294435, 51.582855486089151],
            [0.12775463849051, 51.58162717219848],
            [0.132534968170243, 51.581312874905947],
            [0.133532554999978, 51.579961114951743],
            [0.130799630729632, 51.579361498750188],
            [0.1316111545376, 51.577086879705938],
            [0.12917233783549, 51.576321812216385],
            [0.129418436324206, 51.573960344875061],
            [0.130074234505997, 51.573077942120854],
            [0.130886803616091, 51.573357250885238],
            [0.131558523030486, 51.571828869982241],
            [0.129902702601341, 51.571538797650483],
            [0.130026164179943, 51.57075328679425],
            [0.130457717922576, 51.57032279155532],
            [0.1296770920529, 51.568101362436437],
            [0.129316578110982, 51.566531473917898],
            [0.127621623053363, 51.566140462525063],
            [0.124989502767089, 51.564341051550535],
            [0.119033145029336, 51.563064798126923],
            [0.118873318081655, 51.562730456322441],
            [0.119751027044602, 51.562492480130423],
            [0.11747973911214, 51.559163009317132],
            [0.120487712604618, 51.558541236275993],
            [0.118937622499503, 51.557348026034383],
            [0.117133272585634, 51.557387795987886],
            [0.117152122517858, 51.556892853809472],
            [0.113328649701682, 51.55731790511269],
            [0.112724683228467, 51.556519435078528],
            [0.114727941876975, 51.555325973142359],
            [0.112327139184332, 51.553481645080218],
            [0.112353830306682, 51.552999152402684],
            [0.111105195991799, 51.552482063174935],
            [0.10840189043964, 51.552698844158449],
            [0.108269809121482, 51.552057336410293],
            [0.107429142913719, 51.552001398609519],
            [0.106182252496524, 51.550958149792542],
            [0.104225107561773, 51.549807127167782],
            [0.099739138261355, 51.548427084515197],
            [0.093502230637455, 51.545847924546351],
            [0.092238125506365, 51.548631249946936],
            [0.092596553071258, 51.549652727235156],
            [0.091685964749975, 51.549108719086064],
            [0.091490957729694, 51.548284863754262],
            [0.088473141843546, 51.547740611944086],
            [0.085989057733236, 51.547385532712035],
            [0.081652286495928, 51.545591181558528],
            [0.081399108402493, 51.545131648981908],
            [0.080110274709066, 51.544795699914204],
            [0.077909336659556, 51.544034353860305],
            [0.077473910788813, 51.544575334845781],
            [0.077034231440022, 51.544389776980786],
            [0.076794787468913, 51.544610741092242],
            [0.075541779463226, 51.544015117283848],
            [0.071938695822582, 51.54381532579557],
            [0.071787607146097, 51.543568895902808],
            [0.069714477337629, 51.544062322109276],
            [0.069660360109673, 51.544405733164247],
            [0.069400445454789, 51.546055014707939],
            [0.068932808367081, 51.549022104416863],
            [0.069417328240773, 51.55035437162303],
            [0.068561118539289, 51.551384749045503],
            [0.067746752001858, 51.554738097108903],
            [0.066247501607737, 51.556670065289893],
            [0.067780871673303, 51.554090920368118],
            [0.068371400415323, 51.55130446195497],
            [0.069203450297879, 51.550345554480238],
            [0.068714347550876, 51.548784952363718],
            [0.06936703009492, 51.544128703045814],
            [0.068327153038802, 51.544414126264442],
            [0.068716816789715, 51.547535820999883],
            [0.067796188729641, 51.553060985735797],
            [0.066946352115033, 51.553024703429024],
            [0.06658787836203, 51.554727042415493],
            [0.063588728585039, 51.557306782785147],
            [0.059978057526259, 51.556514132779739],
            [0.058261237694313, 51.557853600700071],
            [0.057122895398729, 51.558107375954137],
            [0.056877774979839, 51.558717780578206],
            [0.056337676461128, 51.558577969794925],
            [0.055705401067482, 51.55933724134988],
            [0.054464323872253, 51.559649444342398],
            [0.054097522789305, 51.560858187956548],
            [0.052554824325654, 51.56171431188907],
            [0.051365970942363, 51.562323225206555],
            [0.050318412186429, 51.563988993497532],
            [0.041928547657158, 51.561450999475248],
            [0.042851026846646, 51.558372938844684],
            [0.044060519381503, 51.557773643925159],
            [0.046300812918632, 51.554813770091236],
            [0.035413285590593, 51.555253163777394],
            [0.032177736452395, 51.55457635219512],
            [0.025341772950035, 51.554062380970954],
            [0.025276505162464, 51.555548194027715],
            [0.023762370025719, 51.555528459988437],
            [0.023291899228805, 51.556824321305861],
            [0.022575095175279, 51.556698194204635],
            [0.022476417664203, 51.556164833060038],
            [0.020341593033503, 51.556267261788413],
            [0.017131202115928, 51.558796376555996],
            [0.016075445625064, 51.559292032009907],
            [0.014754619060201, 51.559437920388007],
            [0.01419020565674, 51.561324372748018],
            [0.014273430385153, 51.563762643008225],
            [0.014431181451465, 51.56560162362458],
            [0.017750468726861, 51.565681276124664],
            [0.018957262773765, 51.566507621805215],
            [0.017667607906251, 51.570001857240463],
            [0.01824762571266, 51.570130368245351],
            [0.019129600264359, 51.569843617136669],
            [0.018898610340148, 51.572251318880383],
            [0.018015294158553, 51.572020116230227],
            [0.017472054985317, 51.572592395605717],
            [0.016459190447525, 51.5728903757715],
            [0.017382306964999, 51.574411345895697],
            [0.01741320872341, 51.577455712477963],
            [0.015232848265027, 51.581706212657387],
            [0.015669892187267, 51.581862371630812],
            [0.015246757016896, 51.582509912173741],
            [0.01550191591525, 51.583383207558171],
            [0.014865915773635, 51.583395927954633],
            [0.013368968888224, 51.583785821135201],
            [0.012090000562983, 51.592148382515347],
            [0.012347277823355, 51.594343556396979],
            [0.012536633192985, 51.595360967044471],
            [0.012904958077165, 51.599054195996722],
            [0.017456248012944, 51.605373365856863],
            [0.019118775424237, 51.607788957695952],
            [0.019552755220141, 51.607609733117826],
            [0.01924201922719, 51.608222977099281],
            [0.02137494007395, 51.611143919481933],
            [0.021047871982405, 51.612462466364704],
            [0.016252058873943, 51.616851480871915],
            [0.008678413675404, 51.619074793366885],
            [0.014499672659714, 51.61890219466197],
            [0.014709217384943, 51.619751991129199],
            [0.014314674259579, 51.620302811300704],
            [0.015815147684027, 51.620911028078901],
            [0.016190131025597, 51.62177686506854],
            [0.017029789120355, 51.621979160607658],
            [0.017014328940822, 51.622347221445793],
            [0.017570239368397, 51.622307992614985],
            [0.018461731324626, 51.622798947145078],
            [0.018829264069796, 51.623235060196457],
            [0.018461110091135, 51.623533648984974],
            [0.019055687158271, 51.623681692581201],
            [0.018584028436446, 51.624154718854065],
            [0.019000207262896, 51.624286946009541],
            [0.020068900760432, 51.626424073674393],
            [0.019921997656807, 51.627020109492918],
            [0.020563391991097, 51.627342694728632],
            [0.020637525536505, 51.62826315448465],
            [0.021093107445589, 51.62830566997966],
            [0.020961409179042, 51.628918531033065],
            [0.021792686490706, 51.628831377599369],
            [0.024561812409684, 51.627516603142304],
            [0.02680757318824, 51.625905951902723],
            [0.029056374181222, 51.625111728082373],
            [0.02893707714305, 51.624672255446683],
            [0.030701035389917, 51.623634586716122],
            [0.030497879205082, 51.623290988087305],
            [0.031444277421763, 51.623099258795307],
            [0.032755330213064, 51.622242052524996],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000027",
        LAD13CDO: "00BD",
        LAD13NM: "Richmond upon Thames",
        LAD13NMW: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-0.321031550601001, 51.44599936325023],
              [-0.324931027683741, 51.444789913995237],
              [-0.32539214228927, 51.445008722366907],
              [-0.325144392279418, 51.444739907267177],
              [-0.32805575289319, 51.443984737869187],
              [-0.325772358763594, 51.445708366281437],
              [-0.321672446342468, 51.446220760855184],
              [-0.321031550601001, 51.44599936325023],
            ],
          ],
          [
            [
              [-0.294815921399293, 51.485982486870398],
              [-0.297441029447367, 51.484567460537207],
              [-0.296431356555204, 51.485612099165678],
              [-0.294815921399293, 51.485982486870398],
            ],
          ],
          [
            [
              [-0.293972269920946, 51.486204018406326],
              [-0.294552680647655, 51.486089265114572],
              [-0.294354646519181, 51.486301302747783],
              [-0.29190334787557, 51.487029976262889],
              [-0.28991025865203, 51.487174482195719],
              [-0.293972269920946, 51.486204018406326],
            ],
          ],
          [
            [
              [-0.22496547850048, 51.473793547277303],
              [-0.222942717365538, 51.471857404265364],
              [-0.2236860857581, 51.47162497221791],
              [-0.223425179648773, 51.471521202916129],
              [-0.223420438095647, 51.470827811445929],
              [-0.224337929773012, 51.470856996680602],
              [-0.22958273265413, 51.472173669450356],
              [-0.23110798022194, 51.472833355577137],
              [-0.232615399330358, 51.472614189485732],
              [-0.232946905126433, 51.471674078198632],
              [-0.233447513202833, 51.46814488562903],
              [-0.232830643116649, 51.466633847836746],
              [-0.233649665276303, 51.466041895389864],
              [-0.23356800691596, 51.46532306613571],
              [-0.233314239204051, 51.463991054692002],
              [-0.243976097341195, 51.465230319179518],
              [-0.252915542011611, 51.465216388009075],
              [-0.256020357037706, 51.463040577087554],
              [-0.258043450822765, 51.460268605094868],
              [-0.256809898857353, 51.459850106941254],
              [-0.258340880027644, 51.457333391145987],
              [-0.259113080337583, 51.454932184188024],
              [-0.247326351612366, 51.449180336580184],
              [-0.2416292997201, 51.443133005608054],
              [-0.242729250946625, 51.441918427297459],
              [-0.254090577024466, 51.437290697983272],
              [-0.256208294401136, 51.436653179327614],
              [-0.256343696817343, 51.436346751244635],
              [-0.264599776496545, 51.432917239410415],
              [-0.270962279394014, 51.429542863890283],
              [-0.280458939522883, 51.421856412143846],
              [-0.286618869669505, 51.420172405573695],
              [-0.290639126836261, 51.428990667606129],
              [-0.293021805477958, 51.4289390623792],
              [-0.293409995426661, 51.426512254638205],
              [-0.294789922318294, 51.426461297312621],
              [-0.294732201178123, 51.427007199092444],
              [-0.295435829245582, 51.42703991470831],
              [-0.295465111910777, 51.427644633684068],
              [-0.297493841778424, 51.428560773807128],
              [-0.301618112704061, 51.431429844823533],
              [-0.302070053948549, 51.431152230855297],
              [-0.30153173578671, 51.430539240361142],
              [-0.301780321922149, 51.430391768964526],
              [-0.305942168444627, 51.431362025765658],
              [-0.306430049767838, 51.430150599844424],
              [-0.306955008940479, 51.430153687083248],
              [-0.308567306938014, 51.430785751476236],
              [-0.308088896618773, 51.431080093887473],
              [-0.309783128646307, 51.432144061482319],
              [-0.310678360804468, 51.432210921193672],
              [-0.312823048573936, 51.430344406231541],
              [-0.314469247243867, 51.428259363098761],
              [-0.30926125788049, 51.425704242301215],
              [-0.306537022526544, 51.422704600456406],
              [-0.306030442002069, 51.421234207991539],
              [-0.307780053465381, 51.416542937655912],
              [-0.308498750176094, 51.413513860120247],
              [-0.309095605954949, 51.409302308372048],
              [-0.308896131586574, 51.401652229948432],
              [-0.309653762201768, 51.400014838714277],
              [-0.312147927588434, 51.398142583779389],
              [-0.312977987585243, 51.397110509061868],
              [-0.317720142672215, 51.393668016936353],
              [-0.319855217304939, 51.392594371715425],
              [-0.324612536594319, 51.39150335408263],
              [-0.325990175681558, 51.391445707154567],
              [-0.327609176236813, 51.391745792442556],
              [-0.328693944915686, 51.392309809730172],
              [-0.330808142731576, 51.394091677075288],
              [-0.332285506974727, 51.395918398494416],
              [-0.335828766524903, 51.398456983203324],
              [-0.338357170824749, 51.400844387395402],
              [-0.340657894561664, 51.403094523835264],
              [-0.343808951003041, 51.403980789560798],
              [-0.351639498372405, 51.408040663811768],
              [-0.355130485284052, 51.409807263850254],
              [-0.355378410002059, 51.410752249557241],
              [-0.359065131887705, 51.411881216319912],
              [-0.360711298360739, 51.412050828657357],
              [-0.36312366284973, 51.411905595813884],
              [-0.366195135050612, 51.41122096620748],
              [-0.37317164997508, 51.408599607089599],
              [-0.379449128811833, 51.407834094999586],
              [-0.383371172651401, 51.408538410298362],
              [-0.383798572165673, 51.41011887308315],
              [-0.386656901554412, 51.410411839310882],
              [-0.389198064866915, 51.410319999050046],
              [-0.389670964487608, 51.410693386834623],
              [-0.390313708108556, 51.414337836572578],
              [-0.386143952315178, 51.414280496240188],
              [-0.386615424600298, 51.415021664873557],
              [-0.386783045753646, 51.417654243559369],
              [-0.387715044593268, 51.419327959429481],
              [-0.386663108012453, 51.420080534696403],
              [-0.391363513016445, 51.422325787377467],
              [-0.392770395841846, 51.423467334017147],
              [-0.391129894868287, 51.423162468154089],
              [-0.385517765957435, 51.420748184982642],
              [-0.384929772281862, 51.421271533968714],
              [-0.384112345918089, 51.422672070883976],
              [-0.388381807509354, 51.428053432398301],
              [-0.388088757963468, 51.427878548092757],
              [-0.387147504065216, 51.428178535234878],
              [-0.387910554486826, 51.42873217040448],
              [-0.383314113737513, 51.432127330577693],
              [-0.376212970554174, 51.435500233706215],
              [-0.377933765071119, 51.436309989436154],
              [-0.377891871908277, 51.436802191143478],
              [-0.367100170785001, 51.441713271493121],
              [-0.372893788057314, 51.443640856498561],
              [-0.373953184009272, 51.443024291850747],
              [-0.376092620868992, 51.443072830565121],
              [-0.377146849927627, 51.442478646582131],
              [-0.37824431343319, 51.442491135867925],
              [-0.379629800420611, 51.443228782716801],
              [-0.37979021945303, 51.444388315169491],
              [-0.382870802129382, 51.445048622915095],
              [-0.384167444145987, 51.445739128569549],
              [-0.38626653200025, 51.446732019519594],
              [-0.38631832365941, 51.448202080310629],
              [-0.387693296166034, 51.449369318452078],
              [-0.384567604336726, 51.449895506603504],
              [-0.383786805579874, 51.449509765111813],
              [-0.375275527055726, 51.456283853560237],
              [-0.37304540319213, 51.457473188807867],
              [-0.372693973508384, 51.456993516360399],
              [-0.372463306178848, 51.457246596237411],
              [-0.369591542082349, 51.456968400297981],
              [-0.368460877129481, 51.456868144019971],
              [-0.368132000668605, 51.457170205939327],
              [-0.36782898817432, 51.457433060691095],
              [-0.365063635442249, 51.457802784542153],
              [-0.362867498251364, 51.457670526717621],
              [-0.359817851653492, 51.458219600985068],
              [-0.356431372370306, 51.458405979424903],
              [-0.353461211608018, 51.458182660586139],
              [-0.352839418007777, 51.456861942091422],
              [-0.348704666489501, 51.456765113655685],
              [-0.346040928411547, 51.457267108835687],
              [-0.345054372057243, 51.457919521720157],
              [-0.342679722019385, 51.45810719136022],
              [-0.341723905316311, 51.459021688807255],
              [-0.340134045783847, 51.459239288148829],
              [-0.340630536284479, 51.457258103429595],
              [-0.338973053884957, 51.455890273855935],
              [-0.338555150035817, 51.456101967978412],
              [-0.337737028942705, 51.455483386438551],
              [-0.337561682892738, 51.454842441646029],
              [-0.336983559383684, 51.454631015051824],
              [-0.336293163161112, 51.455686816763432],
              [-0.333709553280006, 51.455488263610398],
              [-0.333632073463344, 51.455244368196631],
              [-0.333030241191789, 51.455407567298245],
              [-0.331415740865301, 51.457290086375117],
              [-0.330367840854726, 51.457882153962423],
              [-0.328411978032699, 51.457952302143696],
              [-0.327268659058218, 51.45697920659395],
              [-0.326910815828023, 51.457042442795483],
              [-0.325570286144462, 51.458116779522605],
              [-0.326463995566475, 51.459341712540962],
              [-0.326237602213601, 51.459785402169516],
              [-0.326723010062243, 51.459973976676643],
              [-0.326362733717028, 51.460811420409847],
              [-0.326883599750311, 51.461172254708956],
              [-0.32666973296135, 51.461745614049235],
              [-0.323845944230976, 51.46514756207182],
              [-0.321734532879195, 51.465406914302278],
              [-0.319384128499974, 51.463001058611852],
              [-0.313971834369877, 51.459743646440103],
              [-0.307379153002925, 51.457093016254532],
              [-0.307958917650637, 51.456673346385116],
              [-0.307202349154939, 51.457008632768314],
              [-0.305915107129964, 51.45616453726668],
              [-0.303811862180847, 51.45314415130192],
              [-0.304301830771987, 51.45161263712712],
              [-0.3057497376643, 51.450367438456894],
              [-0.308992456459293, 51.448608579315909],
              [-0.31579242023691, 51.446886401414069],
              [-0.315820823555576, 51.446661999230344],
              [-0.319291892841913, 51.44646542111191],
              [-0.321484829303886, 51.446824162774199],
              [-0.325097455002054, 51.446534119964362],
              [-0.327130711769049, 51.445632437776581],
              [-0.33041219352175, 51.442896969174924],
              [-0.331385801236782, 51.441570064310781],
              [-0.331212645096915, 51.439806887227462],
              [-0.331859220058549, 51.439120976915589],
              [-0.330135091578964, 51.438066799522147],
              [-0.329139432160261, 51.43676579867337],
              [-0.329360718145854, 51.436260881392982],
              [-0.328870507675096, 51.436131597308268],
              [-0.328487240240655, 51.433905265934719],
              [-0.326256766995625, 51.431670937492214],
              [-0.32647295867593, 51.431541835640097],
              [-0.32570583665328, 51.431354629653875],
              [-0.325988166132666, 51.431069105977514],
              [-0.325539235175903, 51.431229053740687],
              [-0.322086771065898, 51.429732803850349],
              [-0.319962702212096, 51.429401133373872],
              [-0.318566753439183, 51.429103251355237],
              [-0.318462288523885, 51.429558568301488],
              [-0.324213203330532, 51.431298229895681],
              [-0.32695958749054, 51.433382336133626],
              [-0.327650403904517, 51.434438910836249],
              [-0.3277115163999, 51.43591723618362],
              [-0.329060766644966, 51.438054188816324],
              [-0.328514574301893, 51.437999641629737],
              [-0.329205105060637, 51.438238791991793],
              [-0.330066689270008, 51.439587340183756],
              [-0.330014561518949, 51.441687223005573],
              [-0.329094653122199, 51.442724427508423],
              [-0.327604616522305, 51.443492713299214],
              [-0.3219054823495, 51.445320360150902],
              [-0.314176103191033, 51.446228305745969],
              [-0.309192475811846, 51.44736241789581],
              [-0.305876002715514, 51.448511430050402],
              [-0.302831953256557, 51.451313502376621],
              [-0.302570627154936, 51.452344746091434],
              [-0.302768741837148, 51.453367354050975],
              [-0.30504474154846, 51.45646309504437],
              [-0.308039996679606, 51.458879453348722],
              [-0.313877322442771, 51.460745838256223],
              [-0.317346145901223, 51.462753314223221],
              [-0.319791876475916, 51.464716354022578],
              [-0.319267805285575, 51.468361546734421],
              [-0.318649980627394, 51.470128683159153],
              [-0.317752118423558, 51.471013244082194],
              [-0.314127536161769, 51.472003403824225],
              [-0.31014323652645, 51.473527811331373],
              [-0.306852342836823, 51.474946097002146],
              [-0.302410547448011, 51.479432021160328],
              [-0.295408859979975, 51.484759151574359],
              [-0.293882371727274, 51.485448250140273],
              [-0.290565836987568, 51.486244336619556],
              [-0.287294565203016, 51.486521231128464],
              [-0.284455102266332, 51.48592219917456],
              [-0.2805942428536, 51.483914305825081],
              [-0.278118138629755, 51.4822017916104],
              [-0.27689329968948, 51.480843932505742],
              [-0.27401975517781, 51.475667007730273],
              [-0.272358004834824, 51.473875526564477],
              [-0.270549194187592, 51.472663661269166],
              [-0.267848420555955, 51.471244357813347],
              [-0.26405516890458, 51.470237760708407],
              [-0.260238160285851, 51.470314275660527],
              [-0.258610470291819, 51.470445682496447],
              [-0.2554770263269, 51.471285758781626],
              [-0.253118008433945, 51.47243044537295],
              [-0.251503882033808, 51.473635653324344],
              [-0.249671420194462, 51.47606594739333],
              [-0.24945575324477, 51.481924895269408],
              [-0.24854392735106, 51.483734045163096],
              [-0.241047076979016, 51.488346458202912],
              [-0.238614990658933, 51.488925035970695],
              [-0.235528662056255, 51.488949679299424],
              [-0.233230639189371, 51.488591352497416],
              [-0.231360635079626, 51.487893227570837],
              [-0.229558813121089, 51.486700618155737],
              [-0.228034676282297, 51.485109314954897],
              [-0.226893907895899, 51.481100322205307],
              [-0.226645080402893, 51.476228042416381],
              [-0.22496547850048, 51.473793547277303],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000028",
        LAD13CDO: "00BE",
        LAD13NM: "Southwark",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.0955439405934, 51.508319512926462],
            [-0.092062752647479, 51.50724047717928],
            [-0.090372043211043, 51.507043000529279],
            [-0.090411265422608, 51.506621887465847],
            [-0.090161146975097, 51.507020681982176],
            [-0.088125798900241, 51.506829250400436],
            [-0.081063986731131, 51.505889296781262],
            [-0.071870586615533, 51.503190908058528],
            [-0.070860200410416, 51.502516033388311],
            [-0.073540361761536, 51.500463016331707],
            [-0.070818350839696, 51.502210495728896],
            [-0.063796881652674, 51.500615496018732],
            [-0.059201187145681, 51.500704993611336],
            [-0.056173711158997, 51.50098308185661],
            [-0.050770299536424, 51.502880764461821],
            [-0.048605399227451, 51.504515591396398],
            [-0.047974773941095, 51.504132801659999],
            [-0.047546893654922, 51.505108573043358],
            [-0.047040642351692, 51.505355534283289],
            [-0.04615514432203, 51.50510337961417],
            [-0.046372823914329, 51.5055728241446],
            [-0.04504934180826, 51.505992307693447],
            [-0.04519371193142, 51.506423662561573],
            [-0.044413026883882, 51.506920529288543],
            [-0.043019844429937, 51.507216528159191],
            [-0.041059150997494, 51.50747245759851],
            [-0.038396605694641, 51.507393781603433],
            [-0.035649175416439, 51.506876581070649],
            [-0.033896515943251, 51.506081937623556],
            [-0.032862046010712, 51.50477324669496],
            [-0.032973558461397, 51.504358757657798],
            [-0.032354315258591, 51.50431689525702],
            [-0.032359539043525, 51.500114717977361],
            [-0.032774532879006, 51.500080314110377],
            [-0.032479124355809, 51.499311882017949],
            [-0.032968483882159, 51.496947829270106],
            [-0.03265653994793, 51.496931805133869],
            [-0.032545433985487, 51.496032473960859],
            [-0.032934513515107, 51.495829472263779],
            [-0.032567398030077, 51.495622777280254],
            [-0.032712580664935, 51.494392319520969],
            [-0.033249698709008, 51.494230468727807],
            [-0.032630614976733, 51.494121164607137],
            [-0.032412871376477, 51.493055478030982],
            [-0.037315079734201, 51.492219480538104],
            [-0.039320224425089, 51.492960746340763],
            [-0.040553065759724, 51.492799701742292],
            [-0.040070408582848, 51.492163049200911],
            [-0.041055711539906, 51.491827900687049],
            [-0.040343776049782, 51.491062420776643],
            [-0.042508067824018, 51.490240670948886],
            [-0.042225880566799, 51.490035423754421],
            [-0.044498807016613, 51.490106623020765],
            [-0.044758156451631, 51.490441877303184],
            [-0.045831769344855, 51.490162117346152],
            [-0.046567389916409, 51.490341637474287],
            [-0.046567454769712, 51.490744509180757],
            [-0.049358348047122, 51.490021198268515],
            [-0.051958730451515, 51.489135486776362],
            [-0.050108764528892, 51.488334968307051],
            [-0.050308964482363, 51.488099092826786],
            [-0.052631185671463, 51.487552260578127],
            [-0.053977506639871, 51.487935216710909],
            [-0.052844364935641, 51.486470388460809],
            [-0.052949193405628, 51.486004511567515],
            [-0.053560176260811, 51.485795234721706],
            [-0.053097366792522, 51.485163461602085],
            [-0.052811469916068, 51.4826443694934],
            [-0.053358874898885, 51.480308175027261],
            [-0.054328561545719, 51.478605773783023],
            [-0.052699291641731, 51.478008591672548],
            [-0.052757040153795, 51.472933193568046],
            [-0.051687120133107, 51.469757194115999],
            [-0.052281872893448, 51.469214026934019],
            [-0.05088143769974, 51.467580165585431],
            [-0.051326609607859, 51.46737084230363],
            [-0.050575174890897, 51.466793610094626],
            [-0.050888480480536, 51.466637850358595],
            [-0.048206118538756, 51.465898090985085],
            [-0.048540197363974, 51.465626677763908],
            [-0.046363645681786, 51.464406091841845],
            [-0.045346376365294, 51.462606785183411],
            [-0.045177046561926, 51.459831515624096],
            [-0.046160393195838, 51.456963052912158],
            [-0.044632366825859, 51.456576968535039],
            [-0.041648675260069, 51.454990310250523],
            [-0.046150787508526, 51.44987304884755],
            [-0.04908666594878, 51.450112591986851],
            [-0.053787844199681, 51.44870964107551],
            [-0.057561325333382, 51.450338757248637],
            [-0.060972688511219, 51.448427635064405],
            [-0.062219938664891, 51.44735205114678],
            [-0.063640020651311, 51.444809004589359],
            [-0.063870418640029, 51.442525968923],
            [-0.065026210943825, 51.441157481569668],
            [-0.064053571420876, 51.440849158787557],
            [-0.063456779288316, 51.437540778913338],
            [-0.064420324738406, 51.437041412683492],
            [-0.065834332177944, 51.434631329855861],
            [-0.066708789112277, 51.433922743543874],
            [-0.069529171991758, 51.432682374936547],
            [-0.072215971484247, 51.432458614673919],
            [-0.072861745747858, 51.432087044722444],
            [-0.075048427135353, 51.429179668548826],
            [-0.073935648723001, 51.426153328520165],
            [-0.074965986969211, 51.425699038675567],
            [-0.078306723189438, 51.420609137481627],
            [-0.0792228936188, 51.420849873365228],
            [-0.078930892055468, 51.421570798557319],
            [-0.0798562969174, 51.422140813591227],
            [-0.080896958522096, 51.421848509941974],
            [-0.081726561518059, 51.423367469499617],
            [-0.083476682791357, 51.422998624470559],
            [-0.083683165944882, 51.425008271123666],
            [-0.08525078118987, 51.42735490551695],
            [-0.086074058291397, 51.427826080248188],
            [-0.085890486945861, 51.428501130999017],
            [-0.087680756290129, 51.429000672170304],
            [-0.088497136481674, 51.429707325163626],
            [-0.090464786661594, 51.43609811936274],
            [-0.092874512572579, 51.441077921876499],
            [-0.09450741441142, 51.443401202587246],
            [-0.095096591144356, 51.445461103702904],
            [-0.095903520087962, 51.446508368744908],
            [-0.098892096762115, 51.448736692577363],
            [-0.099858781137901, 51.450237950281569],
            [-0.100766310365055, 51.450356971702305],
            [-0.101474537368782, 51.452157978619063],
            [-0.100938415379873, 51.454151954634533],
            [-0.095837161157032, 51.457408172173935],
            [-0.094729632306436, 51.45901244920362],
            [-0.092211837232236, 51.461120743272353],
            [-0.090667155604948, 51.463294295543307],
            [-0.09010605408122, 51.465733849824474],
            [-0.091146282569867, 51.467613164185977],
            [-0.092740162332642, 51.469821613398409],
            [-0.093031094336037, 51.472029537589982],
            [-0.093623738515316, 51.471767598165052],
            [-0.094105682458216, 51.470741282306626],
            [-0.095997499425672, 51.469880851313761],
            [-0.098917122789693, 51.471933581054394],
            [-0.099375135463536, 51.472623545691377],
            [-0.100682691841873, 51.474458543267055],
            [-0.099997740374872, 51.476872751361768],
            [-0.105554316991352, 51.479146962563661],
            [-0.10824595958184, 51.480294709951316],
            [-0.104046927054431, 51.480907628301374],
            [-0.106043784825064, 51.4826970513151],
            [-0.105635780159176, 51.482950346274414],
            [-0.107222608685199, 51.483654012664033],
            [-0.106357649118617, 51.484890016785876],
            [-0.108452540988762, 51.485848266229432],
            [-0.103156255328758, 51.491473901155963],
            [-0.103943130518433, 51.491951543015411],
            [-0.104896248247476, 51.493527161876479],
            [-0.110957394372561, 51.49549900705081],
            [-0.111442588914861, 51.496447443990967],
            [-0.110295108492697, 51.496777870564948],
            [-0.10835363144577, 51.500348091392638],
            [-0.105947705483125, 51.502045722819418],
            [-0.10613685539546, 51.502522685499002],
            [-0.106751586516134, 51.502222367670484],
            [-0.107458012260126, 51.503012525379845],
            [-0.106395616243444, 51.503371265271312],
            [-0.106465727755707, 51.504982065253536],
            [-0.107363629565433, 51.507063947320077],
            [-0.10817557946596, 51.506908886741478],
            [-0.108924883269284, 51.508440712246525],
            [-0.104702205125722, 51.508623441318861],
            [-0.104709133180941, 51.508387049093841],
            [-0.101511457826581, 51.508594316269821],
            [-0.0955439405934, 51.508319512926462],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000029",
        LAD13CDO: "00BF",
        LAD13NM: "Sutton",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.166455224660887, 51.389692844145642],
            [-0.165290546103297, 51.387979498453532],
            [-0.161770337177903, 51.388132054930821],
            [-0.160986592469052, 51.387820301071116],
            [-0.159703695423595, 51.388594210401713],
            [-0.158282461295624, 51.38883896173634],
            [-0.158055751807391, 51.389258952278787],
            [-0.152672588438534, 51.389770418442495],
            [-0.144378413009366, 51.390679810957025],
            [-0.143183731014795, 51.390401896037581],
            [-0.141903770299123, 51.389355541280722],
            [-0.134379023506288, 51.390886129306502],
            [-0.132634752523855, 51.390128137824021],
            [-0.13326056143262, 51.389549088835558],
            [-0.12949347132605, 51.387185083998155],
            [-0.130663587694904, 51.386263122597171],
            [-0.128761225440115, 51.385651827891017],
            [-0.129534922281754, 51.38528738791026],
            [-0.128657193139887, 51.382494628461572],
            [-0.130302300230481, 51.38222143574977],
            [-0.128078996695885, 51.380498904019134],
            [-0.12695928921061, 51.377337158260971],
            [-0.125833103741463, 51.375604238056653],
            [-0.126865449427031, 51.375482260224203],
            [-0.126673318359406, 51.374909050221369],
            [-0.127849415012976, 51.374749794355878],
            [-0.124744312589688, 51.373661475047555],
            [-0.124773505999716, 51.372947021268175],
            [-0.123339618647996, 51.371189365137241],
            [-0.123359964914616, 51.370551207746658],
            [-0.122094286863262, 51.365808844802977],
            [-0.124108028233862, 51.365395068892298],
            [-0.122370771992226, 51.364528215239794],
            [-0.121302223623195, 51.3647332041322],
            [-0.120928045477791, 51.363310847027002],
            [-0.121276435211526, 51.363116796134989],
            [-0.121380486726929, 51.361065422946176],
            [-0.122053353815158, 51.360979989458784],
            [-0.121839749158275, 51.359986464379311],
            [-0.122928437763603, 51.359918483433773],
            [-0.121860239850248, 51.359064137775142],
            [-0.12333848635111, 51.358917866565541],
            [-0.123456762454164, 51.359151774013853],
            [-0.124715188713416, 51.358866173483946],
            [-0.124196415837998, 51.357430719693248],
            [-0.121429300576776, 51.357730798216821],
            [-0.120177611336543, 51.353045280705956],
            [-0.119115137361499, 51.353143332245956],
            [-0.11736519672618, 51.346643131506355],
            [-0.116991279528106, 51.346656904973933],
            [-0.116919130640419, 51.345754670105507],
            [-0.11731276496842, 51.345680063040049],
            [-0.118663537695072, 51.345796191149084],
            [-0.118930853991793, 51.345510018282681],
            [-0.125924861244987, 51.345239040809901],
            [-0.126856544602187, 51.344857363706097],
            [-0.127347390409131, 51.347188040383095],
            [-0.12755658634416, 51.347445879608102],
            [-0.128371993483241, 51.34745171760779],
            [-0.128868963478094, 51.347272608437514],
            [-0.128993077752533, 51.346587545006933],
            [-0.131431336523934, 51.34602305441026],
            [-0.131126344784128, 51.345472328633761],
            [-0.132892555725229, 51.345436647890182],
            [-0.132513753629315, 51.344299321205717],
            [-0.136272872323406, 51.344406857644799],
            [-0.136350542003863, 51.344792982947389],
            [-0.136731090987715, 51.344756771394763],
            [-0.141556918792261, 51.343414386288465],
            [-0.144214061175453, 51.342292856188585],
            [-0.144595485753942, 51.342092065965403],
            [-0.143464123246972, 51.340562458801926],
            [-0.144324172279447, 51.340206486499874],
            [-0.144271545611749, 51.339909791486384],
            [-0.144526640269978, 51.339494771161867],
            [-0.147547471723146, 51.338781802202057],
            [-0.145617146181277, 51.332431153574817],
            [-0.145172096412843, 51.331542816332153],
            [-0.144067553088038, 51.330670107053336],
            [-0.149330560475826, 51.328765993500973],
            [-0.144454847553246, 51.326481122454403],
            [-0.144272653560501, 51.325909893098626],
            [-0.145585543496559, 51.323500845340853],
            [-0.147956420800863, 51.322684950866929],
            [-0.149037281432254, 51.322788369899811],
            [-0.150594861800533, 51.322172693390328],
            [-0.15208937157746, 51.32226822758021],
            [-0.152731301654483, 51.323008566969605],
            [-0.156568877412008, 51.321510593442625],
            [-0.158660152298905, 51.323977831489216],
            [-0.160625693563951, 51.32324343664353],
            [-0.164212520663373, 51.326042503817305],
            [-0.163592735160636, 51.32621533941456],
            [-0.164387606184334, 51.328127966248637],
            [-0.164007644177431, 51.328189455770449],
            [-0.16399615372199, 51.328584956029466],
            [-0.162315971861777, 51.32889133517633],
            [-0.16310218469515, 51.330266068625711],
            [-0.170091259578355, 51.329476206232165],
            [-0.169919867708561, 51.33024780200919],
            [-0.172889002804801, 51.330015385869153],
            [-0.174164398950471, 51.333213303506419],
            [-0.174691516559311, 51.333170261302769],
            [-0.175045034980872, 51.333811555430621],
            [-0.182392672661727, 51.338047171474408],
            [-0.184632974073882, 51.339194313158238],
            [-0.187382866831064, 51.340783645598485],
            [-0.193254490924973, 51.342627039422673],
            [-0.196072653728369, 51.343331438167709],
            [-0.196292264268228, 51.343040759534276],
            [-0.196715478322556, 51.34315968441139],
            [-0.19656632549415, 51.343447851592217],
            [-0.19734447057784, 51.343596518643274],
            [-0.198582730800043, 51.342300839481275],
            [-0.199582545213153, 51.341885462742447],
            [-0.201224435075549, 51.339885537873727],
            [-0.202071460186147, 51.340144043041057],
            [-0.202107754457131, 51.340133808943364],
            [-0.2130558807236, 51.335542427782762],
            [-0.211931105101989, 51.333309466536413],
            [-0.214120375643281, 51.330351904647074],
            [-0.216108693052046, 51.329828257430663],
            [-0.220967653952227, 51.329864396308807],
            [-0.229847683575384, 51.336524019157586],
            [-0.226851938541204, 51.337889653126169],
            [-0.224115609014211, 51.340042404914733],
            [-0.223286193415304, 51.339882346990201],
            [-0.222301827818819, 51.340461825715352],
            [-0.222725468375038, 51.340830658662291],
            [-0.221811021955465, 51.341346447102445],
            [-0.217289471780013, 51.343388293685948],
            [-0.217802599913431, 51.345632575628322],
            [-0.220210610259773, 51.350080982049981],
            [-0.220445912042543, 51.35142086385671],
            [-0.22128705478489, 51.351171953392011],
            [-0.222116909494779, 51.351325735942638],
            [-0.221999922828743, 51.353531653410229],
            [-0.222761373438006, 51.357087205878763],
            [-0.224082867811672, 51.359275370204223],
            [-0.226966958855556, 51.362625644098884],
            [-0.233215952311575, 51.366011349632856],
            [-0.233974085819122, 51.365386103834666],
            [-0.236804414033077, 51.366798309157844],
            [-0.237849368496374, 51.367008271445243],
            [-0.238942043034275, 51.366835856857989],
            [-0.24091533752637, 51.367038160489543],
            [-0.241174879430627, 51.366263298102851],
            [-0.245429225111489, 51.366846862809034],
            [-0.244703787511017, 51.369062562344389],
            [-0.245054294678756, 51.380035169219461],
            [-0.244984824113744, 51.380649220886255],
            [-0.244174765414224, 51.383492227029635],
            [-0.242931526644994, 51.382933136519974],
            [-0.241620253955485, 51.384524934963231],
            [-0.238744626809497, 51.386095013566965],
            [-0.239706922620339, 51.389294643019213],
            [-0.234657283404015, 51.390538784967291],
            [-0.233928258996926, 51.387709526210962],
            [-0.227742306348417, 51.383954345342836],
            [-0.218136302210686, 51.380150464819323],
            [-0.212985621558078, 51.384811124132504],
            [-0.213371781690992, 51.38500675693053],
            [-0.210139782361228, 51.387986139823553],
            [-0.209793038604226, 51.388253318553609],
            [-0.208888691382255, 51.387856413249331],
            [-0.205027571163463, 51.386092329430561],
            [-0.203517971741341, 51.387225643673546],
            [-0.200316910003305, 51.384963438342702],
            [-0.193950778213, 51.387871717643179],
            [-0.193603743869093, 51.387669429660875],
            [-0.193200741706216, 51.387866449282456],
            [-0.190469477717412, 51.386202930779504],
            [-0.189657211460292, 51.386063587607872],
            [-0.18841153512698, 51.38543553357681],
            [-0.184530831363061, 51.387513916824538],
            [-0.18223569686668, 51.388308353251581],
            [-0.179264634607271, 51.389835035419701],
            [-0.174867588230977, 51.393418537527488],
            [-0.17287143451087, 51.392650032316268],
            [-0.171317408889161, 51.392510684158239],
            [-0.169971719782554, 51.391725300584696],
            [-0.167609062114149, 51.39006070797636],
            [-0.166455224660887, 51.389692844145642],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000030",
        LAD13CDO: "00BG",
        LAD13NM: "Tower Hamlets",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.02947478006328, 51.543029451145436],
            [-0.029006979652003, 51.54227430746441],
            [-0.02561272437174, 51.54336108861407],
            [-0.019228170796985, 51.543594280365255],
            [-0.01657157600578, 51.54336414642146],
            [-0.01609258594968, 51.5425395176227],
            [-0.016515324149734, 51.541507119867404],
            [-0.017751706371162, 51.540931808066588],
            [-0.017879575560722, 51.54017948833642],
            [-0.018916366943585, 51.539350797153709],
            [-0.019427028249381, 51.538283903346958],
            [-0.021089975851115, 51.5373461616729],
            [-0.021170425674749, 51.536521996715884],
            [-0.019346297707899, 51.535194475274075],
            [-0.018963272944884, 51.534119683893678],
            [-0.017987957439674, 51.533270491399492],
            [-0.018232546428422, 51.532452698026866],
            [-0.017443967472838, 51.531487952559857],
            [-0.013647339204428, 51.529840118918294],
            [-0.010786606172409, 51.52931772741632],
            [-0.010353679272708, 51.528533420635789],
            [-0.008726494151562, 51.527596651861927],
            [-0.00752458014343, 51.526199467186395],
            [-0.007789717630678, 51.525376647268843],
            [-0.008111295199405, 51.523821884327553],
            [-0.007857014614343, 51.523009126510225],
            [-0.008339800863996, 51.522780818780063],
            [-0.008585913275943, 51.521798503862911],
            [-0.009316800352371, 51.521797422061901],
            [-0.009196675645342, 51.519858365951308],
            [-0.006666063540082, 51.518228180756083],
            [-0.006029914929943, 51.516457503673223],
            [-0.005318434124158, 51.516214294064163],
            [-0.004315468705584, 51.516407660394194],
            [-0.003382300684941, 51.517608485550532],
            [-0.002591664330119, 51.517757790620934],
            [0.002743544675977, 51.515473495877188],
            [0.003626385505437, 51.514538466263978],
            [0.001384101542705, 51.510994968826765],
            [0.002488042025563, 51.510436563833942],
            [0.004306503485971, 51.510561972685281],
            [0.00549708058192, 51.51348672279498],
            [0.006046659118891, 51.513890987032134],
            [0.006738195681475, 51.513852178488428],
            [0.007357166519484, 51.512654551827424],
            [0.005599456399533, 51.509911280045863],
            [0.006380634071242, 51.509355659261885],
            [0.008886062624859, 51.508882019508746],
            [0.009062254717815, 51.507744123618146],
            [0.008678456590042, 51.507307358192662],
            [0.005834073412231, 51.508198657106739],
            [0.003700119909307, 51.508369126931491],
            [0.003429492892607, 51.507981669321644],
            [0.00305958398654, 51.508221797719813],
            [-0.001743072457876, 51.507441330272094],
            [-0.002794553452074, 51.507023996128005],
            [-0.005358544126649, 51.505035288138373],
            [-0.005749204437322, 51.505097686633427],
            [-0.005520238022611, 51.504830307332476],
            [-0.006706926916502, 51.503854994118583],
            [-0.007681873426993, 51.501489400833989],
            [-0.00898414685674, 51.501230946550741],
            [-0.00780119918464, 51.500801689425899],
            [-0.007293019811426, 51.498281398813219],
            [-0.005657055648596, 51.495855237508664],
            [-0.006216017760026, 51.495624637753501],
            [-0.005395003013096, 51.4946637451608],
            [-0.004772254064837, 51.494540742113799],
            [-0.00327041256743, 51.492150099496747],
            [-0.003253640820347, 51.489830596618198],
            [-0.004308816717361, 51.488395339516565],
            [-0.005195307161955, 51.488546213808036],
            [-0.004668731711841, 51.48810290693384],
            [-0.006526391695, 51.487123723991431],
            [-0.009838915040584, 51.486339176191002],
            [-0.013202829146039, 51.485993350383531],
            [-0.016541042459778, 51.486172166682614],
            [-0.021397965984026, 51.487470034816617],
            [-0.023872421641724, 51.488939800028177],
            [-0.025698988213746, 51.490605436507074],
            [-0.027424955537971, 51.493770227268847],
            [-0.025514982340761, 51.493930525795655],
            [-0.025569001224822, 51.494113986522933],
            [-0.027710559759688, 51.494262433763282],
            [-0.027884990100895, 51.494893954690845],
            [-0.027394826360572, 51.502176059846612],
            [-0.026997071759115, 51.503111798862783],
            [-0.025796083410186, 51.503195002727026],
            [-0.027221402290508, 51.503656031291591],
            [-0.02809038052492, 51.505355868920823],
            [-0.027766628231086, 51.505645383687352],
            [-0.028143728216175, 51.505588776284249],
            [-0.028871351439108, 51.506291643629865],
            [-0.028014748604905, 51.506707091623596],
            [-0.029079589865489, 51.506474996389819],
            [-0.03002344149809, 51.507444075225607],
            [-0.030414419398062, 51.507233020087384],
            [-0.031566770775533, 51.5078458818288],
            [-0.029913234537429, 51.508863061704112],
            [-0.031598017156569, 51.508190824470255],
            [-0.032663053924643, 51.508222184284215],
            [-0.033158666358478, 51.508592900522082],
            [-0.037480799370894, 51.509557375378904],
            [-0.041749181929835, 51.509998320500998],
            [-0.041599828429545, 51.509784499870783],
            [-0.045045457807131, 51.509483180462595],
            [-0.049727277677181, 51.507904688465615],
            [-0.049398733196173, 51.507600669481825],
            [-0.054010007835325, 51.505471385711601],
            [-0.056437987872384, 51.503678053933406],
            [-0.05849404184624, 51.502969320212493],
            [-0.060393259452318, 51.502830786468635],
            [-0.062633738497743, 51.503071053534121],
            [-0.066887969081399, 51.504143931403156],
            [-0.068006376104596, 51.504444729047947],
            [-0.067976032276907, 51.504957706793405],
            [-0.068458009722646, 51.504566374393491],
            [-0.070017500645518, 51.505068656677238],
            [-0.071283722862991, 51.505623647929134],
            [-0.071402498612742, 51.506018577731787],
            [-0.072653891205117, 51.505901563101247],
            [-0.079379467386995, 51.507842788942007],
            [-0.078721275746713, 51.50882748749185],
            [-0.079099246490528, 51.509057594085412],
            [-0.078882588397736, 51.509411950165749],
            [-0.077789496372133, 51.510114345792225],
            [-0.076285613880824, 51.510543813617751],
            [-0.07558409134128, 51.509749949830159],
            [-0.072990978121172, 51.510104844491586],
            [-0.072764024995697, 51.510804335791015],
            [-0.073063209678798, 51.511808328254958],
            [-0.073969103917084, 51.51445353927113],
            [-0.076876920431666, 51.516658573690059],
            [-0.078146910966955, 51.518468912344851],
            [-0.078082665299105, 51.518967846623347],
            [-0.07942976737096, 51.518845132043808],
            [-0.078471498381361, 51.521510121899681],
            [-0.078236501329835, 51.521853384982229],
            [-0.076163420279799, 51.522200685722098],
            [-0.075718647177881, 51.523587237933931],
            [-0.075915456469649, 51.524321562234313],
            [-0.076443082259817, 51.524287051754307],
            [-0.07631536326382, 51.527146391149657],
            [-0.077253316418381, 51.527296658174592],
            [-0.075635326833566, 51.528435561131687],
            [-0.074649662830356, 51.529791651523965],
            [-0.072265447913035, 51.530839694124751],
            [-0.06631328124558, 51.531226424582357],
            [-0.065140294033647, 51.533217820706618],
            [-0.064980731190869, 51.53290044882398],
            [-0.06223804376763, 51.533247249819013],
            [-0.06283805958626, 51.534634821660831],
            [-0.062470432863333, 51.535516316589366],
            [-0.059909720829997, 51.534156581795955],
            [-0.057299968061625, 51.534426331746623],
            [-0.054703633094872, 51.535158464772024],
            [-0.052836066973056, 51.534928743566439],
            [-0.052394593363143, 51.535914195409489],
            [-0.050956871418904, 51.53645684887136],
            [-0.049551425403169, 51.536311189788435],
            [-0.04757481078155, 51.535529225530595],
            [-0.044860706491293, 51.535807753659249],
            [-0.041437234283656, 51.53746103498937],
            [-0.040734849676767, 51.538102167849253],
            [-0.037468373494889, 51.541260618600944],
            [-0.03715672858164, 51.541431659295284],
            [-0.037762679485618, 51.541794306046249],
            [-0.033173172999772, 51.544685902718449],
            [-0.02947478006328, 51.543029451145436],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000031",
        LAD13CDO: "00BH",
        LAD13NM: "Waltham Forest",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.0082177840334, 51.64360739434634],
            [-0.006683314186573, 51.643332260624938],
            [-0.004585800750462, 51.643536730282484],
            [-0.004010466504147, 51.642715827881545],
            [-0.000521073108705, 51.643709475399461],
            [0.001040860750637, 51.64176116774636],
            [0.009872303338654, 51.641448448204002],
            [0.017394201794702, 51.640256521997259],
            [0.020053209021486, 51.640445505781337],
            [0.022693114012789, 51.64111406035483],
            [0.023056419597807, 51.637716716862023],
            [0.025132829406305, 51.637287943655728],
            [0.024686058104351, 51.636050183121114],
            [0.025198033535913, 51.635248210356501],
            [0.025712348457367, 51.634563997072846],
            [0.024303315041836, 51.632387826941979],
            [0.023863960743601, 51.630795628684623],
            [0.021792686490706, 51.628831377599369],
            [0.020961409179042, 51.628918531033065],
            [0.021093107445589, 51.62830566997966],
            [0.020637525536505, 51.62826315448465],
            [0.020563391991097, 51.627342694728632],
            [0.019921997656807, 51.627020109492918],
            [0.020068900760432, 51.626424073674393],
            [0.019000207262896, 51.624286946009541],
            [0.018584028436446, 51.624154718854065],
            [0.019055687158271, 51.623681692581201],
            [0.018461110091135, 51.623533648984974],
            [0.018829264069796, 51.623235060196457],
            [0.018461731324626, 51.622798947145078],
            [0.017570239368397, 51.622307992614985],
            [0.017014328940822, 51.622347221445793],
            [0.017029789120355, 51.621979160607658],
            [0.016190131025597, 51.62177686506854],
            [0.015815147684027, 51.620911028078901],
            [0.014314674259579, 51.620302811300704],
            [0.014709217384943, 51.619751991129199],
            [0.014499672659714, 51.61890219466197],
            [0.008678413675404, 51.619074793366885],
            [0.016252058873943, 51.616851480871915],
            [0.021047871982405, 51.612462466364704],
            [0.02137494007395, 51.611143919481933],
            [0.01924201922719, 51.608222977099281],
            [0.019552755220141, 51.607609733117826],
            [0.019118775424237, 51.607788957695952],
            [0.017456248012944, 51.605373365856863],
            [0.012904958077165, 51.599054195996722],
            [0.012536633192985, 51.595360967044471],
            [0.012347277823355, 51.594343556396979],
            [0.012090000562983, 51.592148382515347],
            [0.013368968888224, 51.583785821135201],
            [0.014865915773635, 51.583395927954633],
            [0.01550191591525, 51.583383207558171],
            [0.015246757016896, 51.582509912173741],
            [0.015669892187267, 51.581862371630812],
            [0.015232848265027, 51.581706212657387],
            [0.01741320872341, 51.577455712477963],
            [0.017382306964999, 51.574411345895697],
            [0.016459190447525, 51.5728903757715],
            [0.017472054985317, 51.572592395605717],
            [0.018015294158553, 51.572020116230227],
            [0.018898610340148, 51.572251318880383],
            [0.019129600264359, 51.569843617136669],
            [0.01824762571266, 51.570130368245351],
            [0.017667607906251, 51.570001857240463],
            [0.018957262773765, 51.566507621805215],
            [0.017750468726861, 51.565681276124664],
            [0.014431181451465, 51.56560162362458],
            [0.014273430385153, 51.563762643008225],
            [0.01419020565674, 51.561324372748018],
            [0.014754619060201, 51.559437920388007],
            [0.016075445625064, 51.559292032009907],
            [0.017131202115928, 51.558796376555996],
            [0.020341593033503, 51.556267261788413],
            [0.021532166254598, 51.554878073063406],
            [0.016944869408588, 51.553182779076444],
            [0.016720384838764, 51.553390771146503],
            [0.016254228803649, 51.553222527763971],
            [0.014573798927439, 51.554954601192534],
            [0.01162128210523, 51.55391715489921],
            [0.011110204075314, 51.554398030183684],
            [0.009622268289578, 51.55343343849372],
            [0.010821168967345, 51.552260040123564],
            [0.009508180300403, 51.551237593349178],
            [0.005503083815926, 51.550659557721417],
            [0.005734813429155, 51.551861507695186],
            [0.005231241155999, 51.551825152853063],
            [0.005175530646116, 51.552132753660096],
            [0.004983209004323, 51.551892340427266],
            [0.003842748776024, 51.551805712575522],
            [0.00308097094102, 51.55209749299987],
            [-0.000351331653374, 51.551719903534391],
            [-0.008676164654441, 51.549925392789362],
            [-0.009471904888413, 51.552215825930311],
            [-0.010889431789572, 51.552622054076018],
            [-0.016970500376781, 51.551403985835165],
            [-0.017167868347094, 51.551575483401528],
            [-0.01750429918859, 51.553392278418265],
            [-0.018471160634414, 51.555175657523158],
            [-0.023033992814248, 51.557270569718696],
            [-0.023968712696787, 51.557372645211707],
            [-0.024377599862194, 51.558656477911647],
            [-0.025890929095482, 51.559922926653272],
            [-0.029170502736534, 51.561283783437361],
            [-0.030117979566761, 51.561359945077264],
            [-0.033308023662903, 51.560621215380088],
            [-0.03641656402598, 51.561504193331125],
            [-0.038837848376431, 51.561160707756251],
            [-0.040076565646787, 51.561778512340759],
            [-0.042389683025856, 51.561366601680263],
            [-0.045104007928001, 51.561758071269921],
            [-0.045726367079731, 51.562250439813319],
            [-0.047219394407922, 51.562660179456941],
            [-0.046727308934074, 51.564379456969888],
            [-0.051175523887205, 51.567721312070162],
            [-0.053159240545741, 51.568573462471178],
            [-0.054291935034098, 51.570196518020147],
            [-0.056399220150706, 51.570794374202485],
            [-0.058430878387859, 51.572524897864078],
            [-0.061184180251801, 51.57778428264232],
            [-0.062237584643148, 51.579586695371098],
            [-0.061357106189947, 51.580605391941447],
            [-0.05976124876479, 51.581508842424959],
            [-0.057996645085517, 51.582407680543938],
            [-0.05602794529841, 51.583994635634447],
            [-0.054727740136651, 51.585912774392305],
            [-0.053159902531475, 51.586929906322453],
            [-0.052165629423949, 51.587833341442391],
            [-0.053377524395734, 51.589606089179988],
            [-0.05340731759462, 51.590834060094039],
            [-0.052532944977356, 51.592442700623025],
            [-0.052252134577114, 51.594045899688652],
            [-0.051251567125791, 51.59495731593843],
            [-0.050298427065463, 51.596850593305476],
            [-0.046468827285956, 51.599788608839603],
            [-0.043540415434819, 51.602813466603742],
            [-0.041447876199425, 51.605634584706131],
            [-0.035277539532746, 51.6082319088001],
            [-0.034020157858624, 51.60948958863802],
            [-0.034187270638145, 51.610301712581801],
            [-0.035574086613031, 51.612001132035509],
            [-0.035386996992459, 51.612988973753126],
            [-0.032420566483036, 51.616621713186774],
            [-0.026000645201378, 51.62176104854489],
            [-0.025247355614811, 51.62250734526534],
            [-0.024452940820482, 51.624133310232345],
            [-0.02294818620993, 51.625812953877904],
            [-0.016835442309214, 51.633527921327612],
            [-0.014793756996723, 51.636734307531434],
            [-0.013277603376042, 51.639847038376352],
            [-0.012286061781355, 51.646227507168227],
            [-0.009240415469788, 51.646526612836922],
            [-0.007370156912229, 51.646160366316266],
            [-0.008472227557521, 51.644496571204591],
            [-0.0082177840334, 51.64360739434634],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000032",
        LAD13CDO: "00BJ",
        LAD13NM: "Wandsworth",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.126363701043512, 51.48446590586321],
            [-0.127496476700001, 51.482022735925966],
            [-0.129947543513704, 51.481182379034024],
            [-0.130661757110705, 51.480199189899807],
            [-0.133980314381718, 51.47563706518104],
            [-0.135230572110168, 51.474287388127259],
            [-0.134461784618302, 51.473965814410533],
            [-0.134721997808315, 51.473305403526645],
            [-0.135787169140427, 51.472714446463051],
            [-0.136918036303989, 51.473090325423826],
            [-0.138349190014795, 51.471962009135098],
            [-0.139502107132498, 51.471795961640275],
            [-0.139316230547667, 51.471516040996541],
            [-0.13974129172348, 51.471367214388742],
            [-0.14034263783979, 51.471592575331726],
            [-0.142676864970741, 51.470063969630822],
            [-0.143100404456814, 51.470519407080644],
            [-0.150618706999224, 51.467614938819885],
            [-0.150470384847331, 51.465977751839134],
            [-0.151231440191364, 51.465884542122936],
            [-0.150967264399445, 51.463836366894633],
            [-0.149693477122954, 51.461301949415194],
            [-0.149390467117798, 51.459361965838525],
            [-0.148153901320381, 51.456410860675234],
            [-0.147378014171984, 51.452963436671538],
            [-0.147729989229507, 51.452339517705035],
            [-0.142327935331495, 51.45096719988495],
            [-0.143360173222615, 51.449505170843075],
            [-0.144795220688118, 51.448542302258026],
            [-0.145696480092407, 51.445869575425164],
            [-0.143620745431506, 51.441838612696955],
            [-0.137885144368552, 51.441695482395573],
            [-0.135980062246629, 51.442011418952703],
            [-0.13701141803964, 51.440252673635179],
            [-0.137497636197338, 51.440149791114401],
            [-0.137683725450734, 51.438581738467853],
            [-0.139912604251632, 51.437020940599659],
            [-0.13975906042949, 51.435665115557541],
            [-0.137857582953008, 51.433776148714813],
            [-0.13652257364814, 51.433915000606781],
            [-0.135264181659318, 51.430334807480904],
            [-0.138387044423803, 51.430484267072863],
            [-0.138922158167865, 51.42457291869524],
            [-0.13785193044739, 51.421106345058661],
            [-0.140405494164252, 51.419249443624189],
            [-0.144480272169609, 51.417752006847095],
            [-0.149341252077632, 51.419855805018813],
            [-0.150284284143382, 51.420081119686948],
            [-0.151327639908783, 51.419852083214288],
            [-0.159592144441397, 51.42256662967106],
            [-0.159882854863525, 51.422634142528082],
            [-0.15997307172545, 51.421927840477451],
            [-0.160361824065581, 51.421917756373034],
            [-0.161671169914427, 51.419862806362396],
            [-0.165093647598839, 51.420092694105662],
            [-0.164833467163193, 51.420340413436016],
            [-0.16842503757318, 51.420910972976124],
            [-0.173347063134889, 51.423070473739585],
            [-0.17879882412344, 51.424327047799601],
            [-0.181939891464086, 51.424735516452259],
            [-0.18394580500514, 51.426203628434102],
            [-0.184347474354902, 51.426957134518389],
            [-0.184248349824731, 51.431272034476713],
            [-0.185761625845012, 51.433164122631197],
            [-0.18728933998995, 51.432986325072072],
            [-0.18741948382093, 51.432097174399587],
            [-0.189254217957595, 51.43183057608536],
            [-0.189088956346247, 51.431607705117038],
            [-0.189796812617996, 51.431351559992763],
            [-0.191062168624924, 51.433042812257945],
            [-0.189267469808219, 51.438117479715046],
            [-0.189215934579555, 51.438547427036902],
            [-0.190667404420037, 51.438919650348026],
            [-0.189759173928865, 51.440055775578394],
            [-0.190039782512615, 51.441474637000205],
            [-0.204557872366545, 51.438961473017258],
            [-0.20912888681539, 51.438304800113251],
            [-0.212545867315941, 51.437831790384742],
            [-0.21573472971293, 51.437746384228085],
            [-0.216531507783253, 51.438345721601948],
            [-0.217846553863352, 51.438679561185417],
            [-0.220438226153947, 51.438449158875081],
            [-0.224276893949684, 51.438860777513248],
            [-0.224507850796707, 51.438581910888296],
            [-0.225505926684569, 51.438742702697795],
            [-0.2313234521774, 51.438139099399237],
            [-0.240711670830783, 51.435220227098945],
            [-0.251084098426208, 51.432566187162607],
            [-0.251254536836126, 51.434168494964638],
            [-0.253110500147291, 51.435298648159865],
            [-0.254090577024466, 51.437290697983272],
            [-0.242729250946625, 51.441918427297459],
            [-0.2416292997201, 51.443133005608054],
            [-0.247326351612366, 51.449180336580184],
            [-0.259113080337583, 51.454932184188024],
            [-0.258340880027644, 51.457333391145987],
            [-0.256809898857353, 51.459850106941254],
            [-0.258043450822765, 51.460268605094868],
            [-0.256020357037706, 51.463040577087554],
            [-0.252915542011611, 51.465216388009075],
            [-0.243976097341195, 51.465230319179518],
            [-0.233314239204051, 51.463991054692002],
            [-0.23356800691596, 51.46532306613571],
            [-0.233649665276303, 51.466041895389864],
            [-0.232830643116649, 51.466633847836746],
            [-0.233447513202833, 51.46814488562903],
            [-0.232946905126433, 51.471674078198632],
            [-0.232615399330358, 51.472614189485732],
            [-0.23110798022194, 51.472833355577137],
            [-0.22958273265413, 51.472173669450356],
            [-0.224337929773012, 51.470856996680602],
            [-0.223420438095647, 51.470827811445929],
            [-0.223425179648773, 51.471521202916129],
            [-0.222759163076814, 51.471726929116336],
            [-0.217465525953547, 51.467864334736156],
            [-0.21280142454205, 51.465561397595806],
            [-0.206987544722895, 51.463845888803647],
            [-0.203438975364316, 51.463182774529336],
            [-0.199670636876523, 51.462729300177614],
            [-0.195540457278647, 51.462874432723289],
            [-0.195093666765837, 51.462121176641134],
            [-0.195126109480868, 51.460605537692281],
            [-0.195170216985242, 51.459998322444854],
            [-0.194134210503917, 51.459841186743965],
            [-0.194744713299998, 51.460202196310448],
            [-0.194851350986814, 51.461731667060384],
            [-0.194418869308044, 51.461382392521585],
            [-0.193882866468491, 51.461467658775732],
            [-0.194839263144913, 51.462877127502274],
            [-0.194217561703076, 51.462689501867267],
            [-0.194128037253655, 51.462951603697547],
            [-0.190328867255291, 51.463389411382671],
            [-0.187112678298653, 51.464190417473901],
            [-0.184015034486002, 51.465411324382096],
            [-0.182214544079963, 51.466484108171606],
            [-0.17909597220352, 51.470067797178821],
            [-0.178493512842512, 51.470191528658589],
            [-0.178561352673664, 51.470693466077286],
            [-0.177974313394594, 51.470610606443088],
            [-0.178479818655961, 51.470788419434641],
            [-0.177629760856149, 51.472825505407585],
            [-0.177240870645511, 51.472864423091082],
            [-0.177564379151101, 51.472948585986785],
            [-0.177111785980503, 51.474332693348359],
            [-0.175754775201189, 51.476151458041826],
            [-0.175805080323685, 51.476984048539457],
            [-0.174180181603498, 51.478908335802906],
            [-0.172021243484393, 51.480138147707734],
            [-0.166266227985246, 51.481327026265312],
            [-0.149108182839756, 51.48366438438854],
            [-0.146163443590247, 51.483578299154296],
            [-0.140276896454397, 51.482768381303323],
            [-0.137354361985239, 51.482739978769352],
            [-0.13730714143489, 51.482449668473983],
            [-0.136914809277578, 51.482464118369613],
            [-0.136733454950133, 51.482814644091803],
            [-0.131998724894409, 51.483676337283981],
            [-0.130721324478922, 51.484448225105567],
            [-0.128455634354978, 51.485048758452045],
            [-0.128171745833204, 51.484735782293463],
            [-0.126363701043512, 51.48446590586321],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        LAD13CD: "E09000033",
        LAD13CDO: "00BK",
        LAD13NM: "Westminster",
        LAD13NMW: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.174141477648115, 51.538199616490886],
            [-0.173510971216226, 51.537632270740851],
            [-0.171981256608953, 51.538382692993075],
            [-0.17089024375321, 51.537792869226145],
            [-0.169542886437788, 51.538597359908344],
            [-0.164961074593286, 51.535779487421507],
            [-0.160486834634699, 51.537281294456577],
            [-0.159438160954746, 51.53636110464538],
            [-0.152732435938544, 51.537519143947023],
            [-0.147195842151387, 51.525041100870993],
            [-0.145815598291375, 51.525270181528931],
            [-0.145198551734178, 51.523994284197464],
            [-0.14415481503136, 51.523995760346004],
            [-0.144179002617573, 51.524641803598762],
            [-0.143496470220476, 51.524622909888826],
            [-0.143521002198687, 51.523948861564527],
            [-0.142901143447612, 51.523877900552336],
            [-0.139179886788072, 51.520817230742601],
            [-0.137033156781376, 51.518884837532106],
            [-0.136321946545697, 51.519146911379543],
            [-0.135851795854771, 51.518694311279432],
            [-0.135258286209963, 51.518933070245993],
            [-0.134070420519287, 51.517561706398673],
            [-0.133622566605303, 51.517728136326269],
            [-0.132568929828069, 51.517164621332206],
            [-0.132474774895153, 51.516717992951513],
            [-0.130831814321433, 51.517101880622356],
            [-0.130372899792216, 51.516447105467378],
            [-0.129852547466754, 51.514477542169779],
            [-0.129361462909319, 51.513483231644372],
            [-0.128997401329675, 51.513434260533799],
            [-0.127352810081589, 51.512665231819824],
            [-0.122894191852907, 51.51514423954292],
            [-0.122098228895455, 51.514627916770785],
            [-0.121843397072707, 51.51486663460345],
            [-0.119493175687399, 51.514505245747998],
            [-0.114718365482976, 51.516040055140522],
            [-0.113859293263093, 51.514820344661267],
            [-0.111606814356538, 51.515338040748837],
            [-0.111101526426128, 51.513825452621518],
            [-0.111980720190731, 51.513684936952295],
            [-0.111738659689328, 51.513195443028046],
            [-0.112414907460231, 51.512769290922279],
            [-0.111530488528424, 51.511642676546018],
            [-0.111574406096813, 51.510754920448136],
            [-0.115125203157181, 51.510444243990364],
            [-0.118375246094207, 51.509562105722097],
            [-0.119949972825805, 51.508766340514839],
            [-0.121374826036231, 51.507408818047679],
            [-0.123089271944218, 51.504016405748779],
            [-0.124681826489161, 51.49470672209852],
            [-0.125376513930708, 51.491644174962751],
            [-0.128490239005031, 51.488213791602526],
            [-0.132663652926231, 51.485697663307661],
            [-0.13813405456433, 51.484813453953144],
            [-0.140339290415299, 51.48484575005687],
            [-0.145514145034158, 51.485630921001068],
            [-0.148986633080623, 51.485563487947402],
            [-0.149125381915661, 51.486227528590895],
            [-0.149675525449952, 51.486086934682994],
            [-0.149264491404649, 51.485531004281157],
            [-0.150003832567287, 51.485484219393015],
            [-0.150366752639102, 51.486232727701676],
            [-0.155873929044345, 51.489606232462073],
            [-0.155196336239452, 51.490048797850761],
            [-0.155701852809399, 51.491050422903186],
            [-0.155821108312591, 51.491450667015094],
            [-0.154818002228408, 51.491962748664051],
            [-0.155989586322176, 51.493201461878584],
            [-0.15501526222633, 51.493856079632558],
            [-0.156013775505698, 51.494494066751415],
            [-0.155319440841398, 51.495064063389194],
            [-0.156047625632871, 51.497510687777996],
            [-0.157874184174106, 51.499017761532272],
            [-0.15847723443309, 51.502250145753941],
            [-0.161023877674389, 51.501589590019684],
            [-0.162599011220902, 51.500292380938888],
            [-0.165505762824421, 51.498696754689199],
            [-0.165803722728949, 51.499241864270623],
            [-0.167643870887854, 51.498300339342912],
            [-0.168414584262257, 51.499020987324556],
            [-0.173566987978205, 51.498220977982754],
            [-0.179570826214083, 51.497778374855109],
            [-0.180336124126152, 51.501460982287533],
            [-0.184259244509307, 51.501793359043269],
            [-0.187933557407209, 51.510179006836907],
            [-0.192143783005382, 51.509868093442364],
            [-0.192984779662186, 51.511813531046336],
            [-0.19406501704488, 51.511756430565548],
            [-0.194367325654607, 51.512712485792285],
            [-0.195072627438726, 51.515031701839412],
            [-0.19920778593522, 51.514551218936958],
            [-0.199944566212615, 51.516846607165981],
            [-0.200747521370249, 51.517976686282708],
            [-0.201488155883395, 51.517799203765605],
            [-0.203766808500966, 51.520622672028239],
            [-0.202669094390285, 51.520899010354242],
            [-0.200634014382906, 51.52071044240919],
            [-0.201639381896616, 51.52274196233693],
            [-0.203881525613413, 51.523458840393879],
            [-0.20590229335227, 51.525738768424816],
            [-0.207319914519514, 51.52623883797429],
            [-0.212698449195279, 51.526866749910099],
            [-0.215573016541876, 51.526719873509158],
            [-0.216028912103472, 51.52792819255199],
            [-0.215883692876897, 51.529725363296713],
            [-0.215104503863569, 51.530258449601654],
            [-0.215207327498344, 51.531389459273306],
            [-0.211051455190376, 51.531959221792704],
            [-0.207648200660883, 51.532820002724058],
            [-0.20307320406065, 51.532896589900758],
            [-0.202992228068829, 51.532902543436052],
            [-0.201689361333834, 51.532559750868366],
            [-0.201108059631871, 51.531907880900164],
            [-0.198298116388534, 51.530444855394094],
            [-0.197798833422846, 51.527900424353106],
            [-0.197021491747425, 51.527944232259749],
            [-0.196568745728934, 51.527654011384215],
            [-0.192558561810077, 51.532628947769638],
            [-0.192382380267819, 51.533254803336114],
            [-0.1930353002745, 51.533988751977148],
            [-0.191483521552663, 51.536289379589128],
            [-0.18877358460174, 51.534523724393992],
            [-0.187751220546842, 51.535033991943102],
            [-0.18387352261961, 51.538378556730891],
            [-0.180180989350078, 51.539561390817838],
            [-0.178353347252415, 51.539792907093052],
            [-0.173921648274941, 51.539476717379159],
            [-0.174141477648115, 51.538199616490886],
          ],
        ],
      },
    },
  ],
};
